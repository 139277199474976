export const months = [
    {
      id: 1,
      text: "January",
    },
    {
      id: 2,
      text: "February",
    },
    {
      id: 3,
      text: "March",
    },
    {
      id: 4,
      text: "April",
    },
    {
      id: 5,
      text: "May",
    },
    {
      id: 6,
      text: "June",
    },
    {
      id: 7,
      text: "July",
    },
    {
      id: 8,
      text: "August",
    },
    {
      id: 9,
      text: "September",
    },
    {
      id: 10,
      text: "October",
    },
    {
      id: 11,
      text: "November",
    },
    {
      id: 12,
      text: "December",
    }
    
  ];
  
  export const days = ['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19',
       '20','21','22','23','24','25','26','27','28','29','30','31'
  ]

  export const countries = [ 
    {name: 'Afghanistan' , code: 'AF'}, 
    {name: 'Åland Islands', code: 'AX'}, 
    {name: 'Albania', code: 'AL'}, 
    {name: 'Algeria', code: 'DZ'}, 
    {name: 'American Samoa', code: 'AS'}, 
    {name: 'AndorrA', code: 'AD'}, 
    {name: 'Angola', code: 'AO'}, 
    {name: 'Anguilla', code: 'AI'}, 
    {name: 'Antarctica', code: 'AQ'}, 
    {name: 'Antigua and Barbuda', code: 'AG'}, 
    {name: 'Argentina', code: 'AR'}, 
    {name: 'Armenia', code: 'AM'}, 
    {name: 'Aruba', code: 'AW'}, 
    {name: 'Australia', code: 'AU'}, 
    {name: 'Austria', code: 'AT'}, 
    {name: 'Azerbaijan', code: 'AZ'}, 
    {name: 'Bahamas', code: 'BS'}, 
    {name: 'Bahrain', code: 'BH'}, 
    {name: 'Bangladesh', code: 'BD'}, 
    {name: 'Barbados', code: 'BB'}, 
    {name: 'Belarus', code: 'BY'}, 
    {name: 'Belgium', code: 'BE'}, 
    {name: 'Belize', code: 'BZ'}, 
    {name: 'Benin', code: 'BJ'}, 
    {name: 'Bermuda', code: 'BM'}, 
    {name: 'Bhutan', code: 'BT'}, 
    {name: 'Bolivia', code: 'BO'}, 
    {name: 'Bosnia and Herzegovina', code: 'BA'}, 
    {name: 'Botswana', code: 'BW'}, 
    {name: 'Bouvet Island', code: 'BV'}, 
    {name: 'Brazil', code: 'BR'}, 
    {name: 'British Indian Ocean Territory', code: 'IO'}, 
    {name: 'Brunei Darussalam', code: 'BN'}, 
    {name: 'Bulgaria', code: 'BG'}, 
    {name: 'Burkina Faso', code: 'BF'}, 
    {name: 'Burundi', code: 'BI'}, 
    {name: 'Cambodia', code: 'KH'}, 
    {name: 'Cameroon', code: 'CM'}, 
    {name: 'Canada', code: 'CA'}, 
    {name: 'Cape Verde', code: 'CV'}, 
    {name: 'Cayman Islands', code: 'KY'}, 
    {name: 'Central African Republic', code: 'CF'}, 
    {name: 'Chad', code: 'TD'}, 
    {name: 'Chile', code: 'CL'}, 
    {name: 'China', code: 'CN'}, 
    {name: 'Christmas Island', code: 'CX'}, 
    {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
    {name: 'Colombia', code: 'CO'}, 
    {name: 'Comoros', code: 'KM'}, 
    {name: 'Congo', code: 'CG'}, 
    {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
    {name: 'Cook Islands', code: 'CK'}, 
    {name: 'Costa Rica', code: 'CR'}, 
    {name: 'Cote D\'Ivoire', code: 'CI'}, 
    {name: 'Croatia', code: 'HR'}, 
    {name: 'Cuba', code: 'CU'}, 
    {name: 'Cyprus', code: 'CY'}, 
    {name: 'Czech Republic', code: 'CZ'}, 
    {name: 'Denmark', code: 'DK'}, 
    {name: 'Djibouti', code: 'DJ'}, 
    {name: 'Dominica', code: 'DM'}, 
    {name: 'Dominican Republic', code: 'DO'}, 
    {name: 'Ecuador', code: 'EC'}, 
    {name: 'Egypt', code: 'EG'}, 
    {name: 'El Salvador', code: 'SV'}, 
    {name: 'Equatorial Guinea', code: 'GQ'}, 
    {name: 'Eritrea', code: 'ER'}, 
    {name: 'Estonia', code: 'EE'}, 
    {name: 'Ethiopia', code: 'ET'}, 
    {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
    {name: 'Faroe Islands', code: 'FO'}, 
    {name: 'Fiji', code: 'FJ'}, 
    {name: 'Finland', code: 'FI'}, 
    {name: 'France', code: 'FR'}, 
    {name: 'French Guiana', code: 'GF'}, 
    {name: 'French Polynesia', code: 'PF'}, 
    {name: 'French Southern Territories', code: 'TF'}, 
    {name: 'Gabon', code: 'GA'}, 
    {name: 'Gambia', code: 'GM'}, 
    {name: 'Georgia', code: 'GE'}, 
    {name: 'Germany', code: 'DE'}, 
    {name: 'Ghana', code: 'GH'}, 
    {name: 'Gibraltar', code: 'GI'}, 
    {name: 'Greece', code: 'GR'}, 
    {name: 'Greenland', code: 'GL'}, 
    {name: 'Grenada', code: 'GD'}, 
    {name: 'Guadeloupe', code: 'GP'}, 
    {name: 'Guam', code: 'GU'}, 
    {name: 'Guatemala', code: 'GT'}, 
    {name: 'Guernsey', code: 'GG'}, 
    {name: 'Guinea', code: 'GN'}, 
    {name: 'Guinea-Bissau', code: 'GW'}, 
    {name: 'Guyana', code: 'GY'}, 
    {name: 'Haiti', code: 'HT'}, 
    {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
    {name: 'Holy See (Vatican City State)', code: 'VA'}, 
    {name: 'Honduras', code: 'HN'}, 
    {name: 'Hong Kong', code: 'HK'}, 
    {name: 'Hungary', code: 'HU'}, 
    {name: 'Iceland', code: 'IS'}, 
    {name: 'India', code: 'IN'}, 
    {name: 'Indonesia', code: 'ID'}, 
    {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
    {name: 'Iraq', code: 'IQ'}, 
    {name: 'Ireland', code: 'IE'}, 
    {name: 'Isle of Man', code: 'IM'}, 
    {name: 'Israel', code: 'IL'}, 
    {name: 'Italy', code: 'IT'}, 
    {name: 'Jamaica', code: 'JM'}, 
    {name: 'Japan', code: 'JP'}, 
    {name: 'Jersey', code: 'JE'}, 
    {name: 'Jordan', code: 'JO'}, 
    {name: 'Kazakhstan', code: 'KZ'}, 
    {name: 'Kenya', code: 'KE'}, 
    {name: 'Kiribati', code: 'KI'}, 
    {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
    {name: 'Korea, Republic of', code: 'KR'}, 
    {name: 'Kuwait', code: 'KW'}, 
    {name: 'Kyrgyzstan', code: 'KG'}, 
    {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
    {name: 'Latvia', code: 'LV'}, 
    {name: 'Lebanon', code: 'LB'}, 
    {name: 'Lesotho', code: 'LS'}, 
    {name: 'Liberia', code: 'LR'}, 
    {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
    {name: 'Liechtenstein', code: 'LI'}, 
    {name: 'Lithuania', code: 'LT'}, 
    {name: 'Luxembourg', code: 'LU'}, 
    {name: 'Macao', code: 'MO'}, 
    {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
    {name: 'Madagascar', code: 'MG'}, 
    {name: 'Malawi', code: 'MW'}, 
    {name: 'Malaysia', code: 'MY'}, 
    {name: 'Maldives', code: 'MV'}, 
    {name: 'Mali', code: 'ML'}, 
    {name: 'Malta', code: 'MT'}, 
    {name: 'Marshall Islands', code: 'MH'}, 
    {name: 'Martinique', code: 'MQ'}, 
    {name: 'Mauritania', code: 'MR'}, 
    {name: 'Mauritius', code: 'MU'}, 
    {name: 'Mayotte', code: 'YT'}, 
    {name: 'Mexico', code: 'MX'}, 
    {name: 'Micronesia, Federated States of', code: 'FM'}, 
    {name: 'Moldova, Republic of', code: 'MD'}, 
    {name: 'Monaco', code: 'MC'}, 
    {name: 'Mongolia', code: 'MN'}, 
    {name: 'Montserrat', code: 'MS'}, 
    {name: 'Morocco', code: 'MA'}, 
    {name: 'Mozambique', code: 'MZ'}, 
    {name: 'Myanmar', code: 'MM'}, 
    {name: 'Namibia', code: 'NA'}, 
    {name: 'Nauru', code: 'NR'}, 
    {name: 'Nepal', code: 'NP'}, 
    {name: 'Netherlands', code: 'NL'}, 
    {name: 'Netherlands Antilles', code: 'AN'}, 
    {name: 'New Caledonia', code: 'NC'}, 
    {name: 'New Zealand', code: 'NZ'}, 
    {name: 'Nicaragua', code: 'NI'}, 
    {name: 'Niger', code: 'NE'}, 
    {name: 'Nigeria', code: 'NG'}, 
    {name: 'Niue', code: 'NU'}, 
    {name: 'Norfolk Island', code: 'NF'}, 
    {name: 'Northern Mariana Islands', code: 'MP'}, 
    {name: 'Norway', code: 'NO'}, 
    {name: 'Oman', code: 'OM'}, 
    {name: 'Pakistan', code: 'PK'}, 
    {name: 'Palau', code: 'PW'}, 
    {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
    {name: 'Panama', code: 'PA'}, 
    {name: 'Papua New Guinea', code: 'PG'}, 
    {name: 'Paraguay', code: 'PY'}, 
    {name: 'Peru', code: 'PE'}, 
    {name: 'Philippines', code: 'PH'}, 
    {name: 'Pitcairn', code: 'PN'}, 
    {name: 'Poland', code: 'PL'}, 
    {name: 'Portugal', code: 'PT'}, 
    {name: 'Puerto Rico', code: 'PR'}, 
    {name: 'Qatar', code: 'QA'}, 
    {name: 'Reunion', code: 'RE'}, 
    {name: 'Romania', code: 'RO'}, 
    {name: 'Russian Federation', code: 'RU'}, 
    {name: 'RWANDA', code: 'RW'}, 
    {name: 'Saint Helena', code: 'SH'}, 
    {name: 'Saint Kitts and Nevis', code: 'KN'}, 
    {name: 'Saint Lucia', code: 'LC'}, 
    {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
    {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
    {name: 'Samoa', code: 'WS'}, 
    {name: 'San Marino', code: 'SM'}, 
    {name: 'Sao Tome and Principe', code: 'ST'}, 
    {name: 'Saudi Arabia', code: 'SA'}, 
    {name: 'Senegal', code: 'SN'}, 
    {name: 'Serbia and Montenegro', code: 'CS'}, 
    {name: 'Seychelles', code: 'SC'}, 
    {name: 'Sierra Leone', code: 'SL'}, 
    {name: 'Singapore', code: 'SG'}, 
    {name: 'Slovakia', code: 'SK'}, 
    {name: 'Slovenia', code: 'SI'}, 
    {name: 'Solomon Islands', code: 'SB'}, 
    {name: 'Somalia', code: 'SO'}, 
    {name: 'South Africa', code: 'ZA'}, 
    {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
    {name: 'Spain', code: 'ES'}, 
    {name: 'Sri Lanka', code: 'LK'}, 
    {name: 'Sudan', code: 'SD'}, 
    {name: 'Suriname', code: 'SR'}, 
    {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
    {name: 'Swaziland', code: 'SZ'}, 
    {name: 'Sweden', code: 'SE'}, 
    {name: 'Switzerland', code: 'CH'}, 
    {name: 'Syrian Arab Republic', code: 'SY'}, 
    {name: 'Taiwan, Province of China', code: 'TW'}, 
    {name: 'Tajikistan', code: 'TJ'}, 
    {name: 'Tanzania, United Republic of', code: 'TZ'}, 
    {name: 'Thailand', code: 'TH'}, 
    {name: 'Timor-Leste', code: 'TL'}, 
    {name: 'Togo', code: 'TG'}, 
    {name: 'Tokelau', code: 'TK'}, 
    {name: 'Tonga', code: 'TO'}, 
    {name: 'Trinidad and Tobago', code: 'TT'}, 
    {name: 'Tunisia', code: 'TN'}, 
    {name: 'Turkey', code: 'TR'}, 
    {name: 'Turkmenistan', code: 'TM'}, 
    {name: 'Turks and Caicos Islands', code: 'TC'}, 
    {name: 'Tuvalu', code: 'TV'}, 
    {name: 'Uganda', code: 'UG'}, 
    {name: 'Ukraine', code: 'UA'}, 
    {name: 'United Arab Emirates', code: 'AE'}, 
    {name: 'United Kingdom', code: 'GB'}, 
    {name: 'United States', code: 'US'}, 
    {name: 'United States Minor Outlying Islands', code: 'UM'}, 
    {name: 'Uruguay', code: 'UY'}, 
    {name: 'Uzbekistan', code: 'UZ'}, 
    {name: 'Vanuatu', code: 'VU'}, 
    {name: 'Venezuela', code: 'VE'}, 
    {name: 'Viet Nam', code: 'VN'}, 
    {name: 'Virgin Islands, British', code: 'VG'}, 
    {name: 'Virgin Islands, U.S.', code: 'VI'}, 
    {name: 'Wallis and Futuna', code: 'WF'}, 
    {name: 'Western Sahara', code: 'EH'}, 
    {name: 'Yemen', code: 'YE'}, 
    {name: 'Zambia', code: 'ZM'}, 
    {name: 'Zimbabwe', code: 'ZW'} 
  ]


  export const newCountries = [
    {
        "name": "Sri Lanka",
        "phonecode": "94",
        "cities": [
    {
        "name": "Aandankulam",
        "countryCode": "LK"
    },
    {
        "name": " Adampan",
        "countryCode": "LK"
    },
    {
        "name": " Addalaichenai",
        "countryCode": "LK"
    },
    {
        "name": " Adippala",
        "countryCode": "LK"
    },
    {
        "name": " Agalawatta",
        "countryCode": "LK"
    },
    {
        "name": " Agaliya",
        "countryCode": "LK"
    },
    {
        "name": " Agarapathana",
        "countryCode": "LK"
    },
    {
        "name": " Agbopura",
        "countryCode": "LK"
    },
    {
        "name": " Ahangama",
        "countryCode": "LK"
    },
    {
        "name": " Ahungalla",
        "countryCode": "LK"
    },
    {
        "name": " Akaragama",
        "countryCode": "LK"
    },
    {
        "name": " Akarawita",
        "countryCode": "LK"
    },
    {
        "name": " Akarella",
        "countryCode": "LK"
    },
    {
        "name": " Akkaraipattu",
        "countryCode": "LK"
    },
    {
        "name": " Akkarasiyaya",
        "countryCode": "LK"
    },
    {
        "name": " Akkarayankulam",
        "countryCode": "LK"
    },
    {
        "name": " Akmeemana",
        "countryCode": "LK"
    },
    {
        "name": " Akuramboda",
        "countryCode": "LK"
    },
    {
        "name": " Akurana",
        "countryCode": "LK"
    },
    {
        "name": " Akuressa",
        "countryCode": "LK"
    },
    {
        "name": " Alagalla",
        "countryCode": "LK"
    },
    {
        "name": " Alahengama",
        "countryCode": "LK"
    },
    {
        "name": " Alahitiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Alampil",
        "countryCode": "LK"
    },
    {
        "name": " Alapaladeniya",
        "countryCode": "LK"
    },
    {
        "name": " Alawala",
        "countryCode": "LK"
    },
    {
        "name": " Alawatugoda",
        "countryCode": "LK"
    },
    {
        "name": " Alawatura",
        "countryCode": "LK"
    },
    {
        "name": " Alawatuwala",
        "countryCode": "LK"
    },
    {
        "name": " Alawwa",
        "countryCode": "LK"
    },
    {
        "name": " Algama",
        "countryCode": "LK"
    },
    {
        "name": " Aliyawalai",
        "countryCode": "LK"
    },
    {
        "name": " Allaipiddi",
        "countryCode": "LK"
    },
    {
        "name": " Allaveddi",
        "countryCode": "LK"
    },
    {
        "name": " Alubomulla",
        "countryCode": "LK"
    },
    {
        "name": " Aludeniya",
        "countryCode": "LK"
    },
    {
        "name": " Aluketiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Alutgama",
        "countryCode": "LK"
    },
    {
        "name": " Aluth Mawatha Road",
        "countryCode": "LK"
    },
    {
        "name": " Aluthwala",
        "countryCode": "LK"
    },
    {
        "name": " Alutnuwara",
        "countryCode": "LK"
    },
    {
        "name": " Aluttaramma",
        "countryCode": "LK"
    },
    {
        "name": " Alutwewa",
        "countryCode": "LK"
    },
    {
        "name": " Alvai",
        "countryCode": "LK"
    },
    {
        "name": " Alwatta",
        "countryCode": "LK"
    },
    {
        "name": " Ambadandegama",
        "countryCode": "LK"
    },
    {
        "name": " Ambagahapelessa",
        "countryCode": "LK"
    },
    {
        "name": " Ambagahawatta",
        "countryCode": "LK"
    },
    {
        "name": " Ambagamuwa Udabulathgama",
        "countryCode": "LK"
    },
    {
        "name": " Ambagasdowa",
        "countryCode": "LK"
    },
    {
        "name": " Ambagaspitiya",
        "countryCode": "LK"
    },
    {
        "name": " Ambakandawila",
        "countryCode": "LK"
    },
    {
        "name": " Ambakote",
        "countryCode": "LK"
    },
    {
        "name": " Ambalakanda",
        "countryCode": "LK"
    },
    {
        "name": " Ambalangoda",
        "countryCode": "LK"
    },
    {
        "name": " Ambalantota",
        "countryCode": "LK"
    },
    {
        "name": " Ambalnagar",
        "countryCode": "LK"
    },
    {
        "name": " Ambana",
        "countryCode": "LK"
    },
    {
        "name": " Ambanpola",
        "countryCode": "LK"
    },
    {
        "name": " Ambatalawa",
        "countryCode": "LK"
    },
    {
        "name": " Ambatenna",
        "countryCode": "LK"
    },
    {
        "name": " Ambepussa",
        "countryCode": "LK"
    },
    {
        "name": " Ambewela",
        "countryCode": "LK"
    },
    {
        "name": " Ambulugala",
        "countryCode": "LK"
    },
    {
        "name": " Amitirigala",
        "countryCode": "LK"
    },
    {
        "name": " Ampagala",
        "countryCode": "LK"
    },
    {
        "name": " Ampalavanpokkanai",
        "countryCode": "LK"
    },
    {
        "name": " Ampara",
        "countryCode": "LK"
    },
    {
        "name": " Ampegama",
        "countryCode": "LK"
    },
    {
        "name": " Ampilanthurai",
        "countryCode": "LK"
    },
    {
        "name": " Ampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Amugoda",
        "countryCode": "LK"
    },
    {
        "name": " Amunumulla",
        "countryCode": "LK"
    },
    {
        "name": " Anaikoddai",
        "countryCode": "LK"
    },
    {
        "name": " Analaitivu",
        "countryCode": "LK"
    },
    {
        "name": " Anamaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Anangoda",
        "countryCode": "LK"
    },
    {
        "name": " Ananthapuram",
        "countryCode": "LK"
    },
    {
        "name": " Anderson Plats",
        "countryCode": "LK"
    },
    {
        "name": " Andiambalama",
        "countryCode": "LK"
    },
    {
        "name": " Andigama",
        "countryCode": "LK"
    },
    {
        "name": " Andiyagala",
        "countryCode": "LK"
    },
    {
        "name": " Andiyapuliyankulam",
        "countryCode": "LK"
    },
    {
        "name": " Angampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Angamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Angulana",
        "countryCode": "LK"
    },
    {
        "name": " Angulugaha",
        "countryCode": "LK"
    },
    {
        "name": " Angunakolapelessa",
        "countryCode": "LK"
    },
    {
        "name": " Angunakolawewa",
        "countryCode": "LK"
    },
    {
        "name": " Angunawila",
        "countryCode": "LK"
    },
    {
        "name": " Anguruwatota",
        "countryCode": "LK"
    },
    {
        "name": " Anhandiya",
        "countryCode": "LK"
    },
    {
        "name": " Anhettigama",
        "countryCode": "LK"
    },
    {
        "name": " Ankokkawala",
        "countryCode": "LK"
    },
    {
        "name": " Ankumbura",
        "countryCode": "LK"
    },
    {
        "name": " Anukkane",
        "countryCode": "LK"
    },
    {
        "name": " Anuradhapura",
        "countryCode": "LK"
    },
    {
        "name": " Aparekka",
        "countryCode": "LK"
    },
    {
        "name": " Aragoda",
        "countryCode": "LK"
    },
    {
        "name": " Araipattai",
        "countryCode": "LK"
    },
    {
        "name": " Aralaganwila",
        "countryCode": "LK"
    },
    {
        "name": " Araly",
        "countryCode": "LK"
    },
    {
        "name": " Aranayaka",
        "countryCode": "LK"
    },
    {
        "name": " Arawa",
        "countryCode": "LK"
    },
    {
        "name": " Arawakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Arawatta",
        "countryCode": "LK"
    },
    {
        "name": " Arawwala West",
        "countryCode": "LK"
    },
    {
        "name": " Arippu",
        "countryCode": "LK"
    },
    {
        "name": " Armour Street",
        "countryCode": "LK"
    },
    {
        "name": " Aruggammana",
        "countryCode": "LK"
    },
    {
        "name": " Arukwathupura",
        "countryCode": "LK"
    },
    {
        "name": " Aselapura",
        "countryCode": "LK"
    },
    {
        "name": " Asikulam",
        "countryCode": "LK"
    },
    {
        "name": " Atabage",
        "countryCode": "LK"
    },
    {
        "name": " Atakalanpanna",
        "countryCode": "LK"
    },
    {
        "name": " Atakiriya",
        "countryCode": "LK"
    },
    {
        "name": " Atale",
        "countryCode": "LK"
    },
    {
        "name": " Ataragalla",
        "countryCode": "LK"
    },
    {
        "name": " Ataragallewa",
        "countryCode": "LK"
    },
    {
        "name": " Atchuveli",
        "countryCode": "LK"
    },
    {
        "name": " Athimottai",
        "countryCode": "LK"
    },
    {
        "name": " Athuraliya",
        "countryCode": "LK"
    },
    {
        "name": " Athurugiriya",
        "countryCode": "LK"
    },
    {
        "name": " Attanagalla",
        "countryCode": "LK"
    },
    {
        "name": " Attanakadawala",
        "countryCode": "LK"
    },
    {
        "name": " Attawilluwa",
        "countryCode": "LK"
    },
    {
        "name": " Avissawella",
        "countryCode": "LK"
    },
    {
        "name": " Awukana",
        "countryCode": "LK"
    },
    {
        "name": " Awulegama",
        "countryCode": "LK"
    },
    {
        "name": " Ayagama",
        "countryCode": "LK"
    },
    {
        "name": " Ayithiyamalai",
        "countryCode": "LK"
    },
    {
        "name": " Ayiwela",
        "countryCode": "LK"
    },
    {
        "name": " Badalgama",
        "countryCode": "LK"
    },
    {
        "name": " Badalkumbura",
        "countryCode": "LK"
    },
    {
        "name": " Baddegama",
        "countryCode": "LK"
    },
    {
        "name": " Badulla",
        "countryCode": "LK"
    },
    {
        "name": " Baduluoya",
        "countryCode": "LK"
    },
    {
        "name": " Baduluwela",
        "countryCode": "LK"
    },
    {
        "name": " Baduraliya",
        "countryCode": "LK"
    },
    {
        "name": " Bakamuna",
        "countryCode": "LK"
    },
    {
        "name": " Bakiella",
        "countryCode": "LK"
    },
    {
        "name": " Bakinigahawela",
        "countryCode": "LK"
    },
    {
        "name": " Bakmitiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Balaharuwa",
        "countryCode": "LK"
    },
    {
        "name": " Balalla",
        "countryCode": "LK"
    },
    {
        "name": " Balana",
        "countryCode": "LK"
    },
    {
        "name": " Balangoda",
        "countryCode": "LK"
    },
    {
        "name": " Balapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Ballaketuwa",
        "countryCode": "LK"
    },
    {
        "name": " Bambaragahaela",
        "countryCode": "LK"
    },
    {
        "name": " Bambaragaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Bambarapana",
        "countryCode": "LK"
    },
    {
        "name": " Bamunukotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Banagala",
        "countryCode": "LK"
    },
    {
        "name": " Bandagiriya",
        "countryCode": "LK"
    },
    {
        "name": " Bandara Koswatta",
        "countryCode": "LK"
    },
    {
        "name": " Bandaragama",
        "countryCode": "LK"
    },
    {
        "name": " Bandarawela",
        "countryCode": "LK"
    },
    {
        "name": " Banduragoda",
        "countryCode": "LK"
    },
    {
        "name": " Bangadeniya",
        "countryCode": "LK"
    },
    {
        "name": " Baranankattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Barawakumbuka",
        "countryCode": "LK"
    },
    {
        "name": " Barawardhana Oya",
        "countryCode": "LK"
    },
    {
        "name": " Baseline Road",
        "countryCode": "LK"
    },
    {
        "name": " Batagolladeniya",
        "countryCode": "LK"
    },
    {
        "name": " Batapola",
        "countryCode": "LK"
    },
    {
        "name": " Batatota",
        "countryCode": "LK"
    },
    {
        "name": " Batawala",
        "countryCode": "LK"
    },
    {
        "name": " Battaramulla",
        "countryCode": "LK"
    },
    {
        "name": " Batticaloa",
        "countryCode": "LK"
    },
    {
        "name": " Battuluoya",
        "countryCode": "LK"
    },
    {
        "name": " Batugampola",
        "countryCode": "LK"
    },
    {
        "name": " Batugoda",
        "countryCode": "LK"
    },
    {
        "name": " Batumulla",
        "countryCode": "LK"
    },
    {
        "name": " Batuwatta",
        "countryCode": "LK"
    },
    {
        "name": " Batuwita",
        "countryCode": "LK"
    },
    {
        "name": " Bawlana",
        "countryCode": "LK"
    },
    {
        "name": " Beddagana",
        "countryCode": "LK"
    },
    {
        "name": " Beliatta",
        "countryCode": "LK"
    },
    {
        "name": " Beligala(Sab)",
        "countryCode": "LK"
    },
    {
        "name": " Beligamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Belihuloya",
        "countryCode": "LK"
    },
    {
        "name": " Bellana",
        "countryCode": "LK"
    },
    {
        "name": " Bemmulla",
        "countryCode": "LK"
    },
    {
        "name": " Bengamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Bentota",
        "countryCode": "LK"
    },
    {
        "name": " Beragama",
        "countryCode": "LK"
    },
    {
        "name": " Beralapanathara",
        "countryCode": "LK"
    },
    {
        "name": " Beralihela",
        "countryCode": "LK"
    },
    {
        "name": " Beramada",
        "countryCode": "LK"
    },
    {
        "name": " Berannawa",
        "countryCode": "LK"
    },
    {
        "name": " Beruwala",
        "countryCode": "LK"
    },
    {
        "name": " Bibile",
        "countryCode": "LK"
    },
    {
        "name": " Bibilegama",
        "countryCode": "LK"
    },
    {
        "name": " Bingiriya",
        "countryCode": "LK"
    },
    {
        "name": " Biyagama IPZ",
        "countryCode": "LK"
    },
    {
        "name": " Biyagama",
        "countryCode": "LK"
    },
    {
        "name": " Bogahakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Bogahawewa",
        "countryCode": "LK"
    },
    {
        "name": " Bogamulla",
        "countryCode": "LK"
    },
    {
        "name": " Bogawantalawa",
        "countryCode": "LK"
    },
    {
        "name": " Bokalagama",
        "countryCode": "LK"
    },
    {
        "name": " Bokundara",
        "countryCode": "LK"
    },
    {
        "name": " Bollete (WP)",
        "countryCode": "LK"
    },
    {
        "name": " Bolossagama",
        "countryCode": "LK"
    },
    {
        "name": " Bolthumbe",
        "countryCode": "LK"
    },
    {
        "name": " Bombuwala",
        "countryCode": "LK"
    },
    {
        "name": " Bomluwageaina",
        "countryCode": "LK"
    },
    {
        "name": " Boossa",
        "countryCode": "LK"
    },
    {
        "name": " Bopagama",
        "countryCode": "LK"
    },
    {
        "name": " Bopagoda",
        "countryCode": "LK"
    },
    {
        "name": " Bopana",
        "countryCode": "LK"
    },
    {
        "name": " Bopattalawa",
        "countryCode": "LK"
    },
    {
        "name": " Bope",
        "countryCode": "LK"
    },
    {
        "name": " Bopitiya (SAB)",
        "countryCode": "LK"
    },
    {
        "name": " Bopitiya",
        "countryCode": "LK"
    },
    {
        "name": " Boragas",
        "countryCode": "LK"
    },
    {
        "name": " Boralanda",
        "countryCode": "LK"
    },
    {
        "name": " Boralankada",
        "countryCode": "LK"
    },
    {
        "name": " Boralesgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Boralugoda",
        "countryCode": "LK"
    },
    {
        "name": " Boraluwewa",
        "countryCode": "LK"
    },
    {
        "name": " Bossella",
        "countryCode": "LK"
    },
    {
        "name": " Bowalagama",
        "countryCode": "LK"
    },
    {
        "name": " Bowela",
        "countryCode": "LK"
    },
    {
        "name": " Boyagane",
        "countryCode": "LK"
    },
    {
        "name": " Buckmigama",
        "countryCode": "LK"
    },
    {
        "name": " Buddama",
        "countryCode": "LK"
    },
    {
        "name": " Bujjampola",
        "countryCode": "LK"
    },
    {
        "name": " Bujjomuwa",
        "countryCode": "LK"
    },
    {
        "name": " Bulathkohupitiya",
        "countryCode": "LK"
    },
    {
        "name": " Bulathsinhala",
        "countryCode": "LK"
    },
    {
        "name": " Bulnewa",
        "countryCode": "LK"
    },
    {
        "name": " Bulutota",
        "countryCode": "LK"
    },
    {
        "name": " Buluwala",
        "countryCode": "LK"
    },
    {
        "name": " Bundala",
        "countryCode": "LK"
    },
    {
        "name": " Buthpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Buttala",
        "countryCode": "LK"
    },
    {
        "name": " Central Camp",
        "countryCode": "LK"
    },
    {
        "name": " Chankanai",
        "countryCode": "LK"
    },
    {
        "name": " Chavakachcheri",
        "countryCode": "LK"
    },
    {
        "name": " Cheddikulam",
        "countryCode": "LK"
    },
    {
        "name": " Cheddipalayam",
        "countryCode": "LK"
    },
    {
        "name": " Cheddiyakurichchi",
        "countryCode": "LK"
    },
    {
        "name": " Chemamadukulam",
        "countryCode": "LK"
    },
    {
        "name": " Chempiyanpattu",
        "countryCode": "LK"
    },
    {
        "name": " Chenkaladi",
        "countryCode": "LK"
    },
    {
        "name": " Chilavathurai",
        "countryCode": "LK"
    },
    {
        "name": " Chilaw",
        "countryCode": "LK"
    },
    {
        "name": " China Bay",
        "countryCode": "LK"
    },
    {
        "name": " Chullipuram",
        "countryCode": "LK"
    },
    {
        "name": " Chundikulam",
        "countryCode": "LK"
    },
    {
        "name": " Chundikuli",
        "countryCode": "LK"
    },
    {
        "name": " Chunnakam",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 1",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 10",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 11",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 12",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 13",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 14",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 15",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 2",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 3",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 4",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 5",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 6",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 7",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 8",
        "countryCode": "LK"
    },
    {
        "name": " Colombo 9",
        "countryCode": "LK"
    },
    {
        "name": " Colombo General Hospital",
        "countryCode": "LK"
    },
    {
        "name": " Colombo Kachcheri",
        "countryCode": "LK"
    },
    {
        "name": " Colombo Labour Sec",
        "countryCode": "LK"
    },
    {
        "name": " Colombo Secretariant",
        "countryCode": "LK"
    },
    {
        "name": " Dadayamtalawa",
        "countryCode": "LK"
    },
    {
        "name": " Dagampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Dagonna",
        "countryCode": "LK"
    },
    {
        "name": " Dalukana",
        "countryCode": "LK"
    },
    {
        "name": " Damana",
        "countryCode": "LK"
    },
    {
        "name": " Damanewela",
        "countryCode": "LK"
    },
    {
        "name": " Dambadeniya",
        "countryCode": "LK"
    },
    {
        "name": " Dambagalla",
        "countryCode": "LK"
    },
    {
        "name": " Dambana",
        "countryCode": "LK"
    },
    {
        "name": " Dambulla",
        "countryCode": "LK"
    },
    {
        "name": " Dambuluwana",
        "countryCode": "LK"
    },
    {
        "name": " Damminna",
        "countryCode": "LK"
    },
    {
        "name": " Dampahala",
        "countryCode": "LK"
    },
    {
        "name": " Dampe",
        "countryCode": "LK"
    },
    {
        "name": " Damunupola",
        "countryCode": "LK"
    },
    {
        "name": " Danawala Thiniyawala",
        "countryCode": "LK"
    },
    {
        "name": " Dankanda",
        "countryCode": "LK"
    },
    {
        "name": " Dankotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Danowita",
        "countryCode": "LK"
    },
    {
        "name": " Danture",
        "countryCode": "LK"
    },
    {
        "name": " Daraluwa",
        "countryCode": "LK"
    },
    {
        "name": " Daugala",
        "countryCode": "LK"
    },
    {
        "name": " Dayagama Bazaar",
        "countryCode": "LK"
    },
    {
        "name": " Debahera",
        "countryCode": "LK"
    },
    {
        "name": " Debathgama",
        "countryCode": "LK"
    },
    {
        "name": " Dedigamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Dedugala",
        "countryCode": "LK"
    },
    {
        "name": " Dedunupitiya",
        "countryCode": "LK"
    },
    {
        "name": " Deegala Lenama",
        "countryCode": "LK"
    },
    {
        "name": " Deegalla",
        "countryCode": "LK"
    },
    {
        "name": " Deegawapiya",
        "countryCode": "LK"
    },
    {
        "name": " Deewala Pallegama",
        "countryCode": "LK"
    },
    {
        "name": " Dehiattakandiya",
        "countryCode": "LK"
    },
    {
        "name": " Dehiowita",
        "countryCode": "LK"
    },
    {
        "name": " Dehiwala",
        "countryCode": "LK"
    },
    {
        "name": " Dehiwatte",
        "countryCode": "LK"
    },
    {
        "name": " Deiyandara",
        "countryCode": "LK"
    },
    {
        "name": " Dekatana",
        "countryCode": "LK"
    },
    {
        "name": " Dekinda",
        "countryCode": "LK"
    },
    {
        "name": " Dela",
        "countryCode": "LK"
    },
    {
        "name": " Deldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Delft West",
        "countryCode": "LK"
    },
    {
        "name": " Delft",
        "countryCode": "LK"
    },
    {
        "name": " Delgoda",
        "countryCode": "LK"
    },
    {
        "name": " Dellawa",
        "countryCode": "LK"
    },
    {
        "name": " Delmella",
        "countryCode": "LK"
    },
    {
        "name": " Deloluwa",
        "countryCode": "LK"
    },
    {
        "name": " Deltara",
        "countryCode": "LK"
    },
    {
        "name": " Deltota",
        "countryCode": "LK"
    },
    {
        "name": " Delwagura",
        "countryCode": "LK"
    },
    {
        "name": " Delwala",
        "countryCode": "LK"
    },
    {
        "name": " Delwite",
        "countryCode": "LK"
    },
    {
        "name": " Demalagama",
        "countryCode": "LK"
    },
    {
        "name": " Demanhandiya",
        "countryCode": "LK"
    },
    {
        "name": " Demataluwa",
        "countryCode": "LK"
    },
    {
        "name": " Dematawewa",
        "countryCode": "LK"
    },
    {
        "name": " Demodara",
        "countryCode": "LK"
    },
    {
        "name": " Demuwatha",
        "countryCode": "LK"
    },
    {
        "name": " Denagama",
        "countryCode": "LK"
    },
    {
        "name": " Denipitiya",
        "countryCode": "LK"
    },
    {
        "name": " Deniyaya",
        "countryCode": "LK"
    },
    {
        "name": " Derangala",
        "countryCode": "LK"
    },
    {
        "name": " Deraniyagala",
        "countryCode": "LK"
    },
    {
        "name": " Devagiriya",
        "countryCode": "LK"
    },
    {
        "name": " Devalahinda",
        "countryCode": "LK"
    },
    {
        "name": " Devinuwara (Dondra)",
        "countryCode": "LK"
    },
    {
        "name": " Dewagala",
        "countryCode": "LK"
    },
    {
        "name": " Dewahuwa",
        "countryCode": "LK"
    },
    {
        "name": " Dewalapola",
        "countryCode": "LK"
    },
    {
        "name": " Dewalegama",
        "countryCode": "LK"
    },
    {
        "name": " Dewanagala",
        "countryCode": "LK"
    },
    {
        "name": " Dharga Town",
        "countryCode": "LK"
    },
    {
        "name": " Diddeniya",
        "countryCode": "LK"
    },
    {
        "name": " Digamadulla",
        "countryCode": "LK"
    },
    {
        "name": " Diganatenna",
        "countryCode": "LK"
    },
    {
        "name": " Digannewa",
        "countryCode": "LK"
    },
    {
        "name": " Dikkapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Dikkumbura",
        "countryCode": "LK"
    },
    {
        "name": " Dikoya",
        "countryCode": "LK"
    },
    {
        "name": " Dikwella",
        "countryCode": "LK"
    },
    {
        "name": " Dimbulagala",
        "countryCode": "LK"
    },
    {
        "name": " Dimbulana",
        "countryCode": "LK"
    },
    {
        "name": " Divulankadawala",
        "countryCode": "LK"
    },
    {
        "name": " Divulapelessa",
        "countryCode": "LK"
    },
    {
        "name": " Divulapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Divuldamana",
        "countryCode": "LK"
    },
    {
        "name": " Divuldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Divullegoda",
        "countryCode": "LK"
    },
    {
        "name": " Diwalakada",
        "countryCode": "LK"
    },
    {
        "name": " Diyabeduma",
        "countryCode": "LK"
    },
    {
        "name": " Diyagaha",
        "countryCode": "LK"
    },
    {
        "name": " Diyakobala",
        "countryCode": "LK"
    },
    {
        "name": " Diyalape",
        "countryCode": "LK"
    },
    {
        "name": " Diyasenpura",
        "countryCode": "LK"
    },
    {
        "name": " Diyatalawa",
        "countryCode": "LK"
    },
    {
        "name": " Dodampe",
        "countryCode": "LK"
    },
    {
        "name": " Dodanduwa",
        "countryCode": "LK"
    },
    {
        "name": " Dodangaslanda",
        "countryCode": "LK"
    },
    {
        "name": " Dodangoda",
        "countryCode": "LK"
    },
    {
        "name": " Dolapihilla",
        "countryCode": "LK"
    },
    {
        "name": " Dolosbage",
        "countryCode": "LK"
    },
    {
        "name": " Doloswalakanda",
        "countryCode": "LK"
    },
    {
        "name": " Doluwa",
        "countryCode": "LK"
    },
    {
        "name": " Dombagahawela",
        "countryCode": "LK"
    },
    {
        "name": " Dombagoda",
        "countryCode": "LK"
    },
    {
        "name": " Dombemada",
        "countryCode": "LK"
    },
    {
        "name": " Dompe",
        "countryCode": "LK"
    },
    {
        "name": " Doragala",
        "countryCode": "LK"
    },
    {
        "name": " Doragamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Dorakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Doratiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Dorawaka",
        "countryCode": "LK"
    },
    {
        "name": " Dulgolla",
        "countryCode": "LK"
    },
    {
        "name": " Dullewa",
        "countryCode": "LK"
    },
    {
        "name": " Dumbara Manana",
        "countryCode": "LK"
    },
    {
        "name": " Dummalasuriya",
        "countryCode": "LK"
    },
    {
        "name": " Dunagaha",
        "countryCode": "LK"
    },
    {
        "name": " Dunkannawa",
        "countryCode": "LK"
    },
    {
        "name": " Dunkolawatta",
        "countryCode": "LK"
    },
    {
        "name": " Dunukedeniya",
        "countryCode": "LK"
    },
    {
        "name": " Dunumadalawa",
        "countryCode": "LK"
    },
    {
        "name": " Dunumala",
        "countryCode": "LK"
    },
    {
        "name": " Dunuwila",
        "countryCode": "LK"
    },
    {
        "name": " Dunuwilapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Dutuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Echchilampattai",
        "countryCode": "LK"
    },
    {
        "name": " Egodauyana North",
        "countryCode": "LK"
    },
    {
        "name": " Egodauyana South",
        "countryCode": "LK"
    },
    {
        "name": " Egodawela",
        "countryCode": "LK"
    },
    {
        "name": " Eheliyagoda",
        "countryCode": "LK"
    },
    {
        "name": " Ehetuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Ekala",
        "countryCode": "LK"
    },
    {
        "name": " Ekamutugama",
        "countryCode": "LK"
    },
    {
        "name": " Ekiriya",
        "countryCode": "LK"
    },
    {
        "name": " Ekiriyankumbura",
        "countryCode": "LK"
    },
    {
        "name": " Elahera",
        "countryCode": "LK"
    },
    {
        "name": " Elamulla",
        "countryCode": "LK"
    },
    {
        "name": " Elapatha",
        "countryCode": "LK"
    },
    {
        "name": " Elayapattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Elephant Pass",
        "countryCode": "LK"
    },
    {
        "name": " Elibichchiya",
        "countryCode": "LK"
    },
    {
        "name": " Elkaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Ella Tanabaddegama",
        "countryCode": "LK"
    },
    {
        "name": " Ella",
        "countryCode": "LK"
    },
    {
        "name": " Ellagala",
        "countryCode": "LK"
    },
    {
        "name": " Ellagawa",
        "countryCode": "LK"
    },
    {
        "name": " Ellakkala",
        "countryCode": "LK"
    },
    {
        "name": " Ellaulla",
        "countryCode": "LK"
    },
    {
        "name": " Ellawala",
        "countryCode": "LK"
    },
    {
        "name": " Ellewewa",
        "countryCode": "LK"
    },
    {
        "name": " Elpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Eluthumadduval",
        "countryCode": "LK"
    },
    {
        "name": " Eluvaitivu",
        "countryCode": "LK"
    },
    {
        "name": " Eluwankulama",
        "countryCode": "LK"
    },
    {
        "name": " Embilipitiya",
        "countryCode": "LK"
    },
    {
        "name": " Embogama",
        "countryCode": "LK"
    },
    {
        "name": " Eppawala",
        "countryCode": "LK"
    },
    {
        "name": " Eratna",
        "countryCode": "LK"
    },
    {
        "name": " Eravur",
        "countryCode": "LK"
    },
    {
        "name": " Erawula Junction",
        "countryCode": "LK"
    },
    {
        "name": " Erepola",
        "countryCode": "LK"
    },
    {
        "name": " Erialai",
        "countryCode": "LK"
    },
    {
        "name": " Erukkalampiddy",
        "countryCode": "LK"
    },
    {
        "name": " Essella",
        "countryCode": "LK"
    },
    {
        "name": " Etanawala",
        "countryCode": "LK"
    },
    {
        "name": " Etawatunuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Etaweeragollewa",
        "countryCode": "LK"
    },
    {
        "name": " Ethawetunuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Ethimalewewa",
        "countryCode": "LK"
    },
    {
        "name": " Ethkandura",
        "countryCode": "LK"
    },
    {
        "name": " Ettale",
        "countryCode": "LK"
    },
    {
        "name": " Ettampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Ettiliwewa",
        "countryCode": "LK"
    },
    {
        "name": " Etulgama",
        "countryCode": "LK"
    },
    {
        "name": " Etungahakotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Gabbela",
        "countryCode": "LK"
    },
    {
        "name": " Galabedda",
        "countryCode": "LK"
    },
    {
        "name": " Galaboda",
        "countryCode": "LK"
    },
    {
        "name": " Galadivulwewa",
        "countryCode": "LK"
    },
    {
        "name": " Galagedara",
        "countryCode": "LK"
    },
    {
        "name": " Galaha",
        "countryCode": "LK"
    },
    {
        "name": " Galamuna",
        "countryCode": "LK"
    },
    {
        "name": " Galapitagala",
        "countryCode": "LK"
    },
    {
        "name": " Galapitamada",
        "countryCode": "LK"
    },
    {
        "name": " Galatara",
        "countryCode": "LK"
    },
    {
        "name": " Galauda",
        "countryCode": "LK"
    },
    {
        "name": " Galawilawaththa",
        "countryCode": "LK"
    },
    {
        "name": " Galedanda",
        "countryCode": "LK"
    },
    {
        "name": " Galenbindunuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Galewela",
        "countryCode": "LK"
    },
    {
        "name": " Galgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Galhinna",
        "countryCode": "LK"
    },
    {
        "name": " Galigamuwa Town",
        "countryCode": "LK"
    },
    {
        "name": " Galkadawala",
        "countryCode": "LK"
    },
    {
        "name": " Galkiriyagama",
        "countryCode": "LK"
    },
    {
        "name": " Galkulama",
        "countryCode": "LK"
    },
    {
        "name": " Galle",
        "countryCode": "LK"
    },
    {
        "name": " Gallella",
        "countryCode": "LK"
    },
    {
        "name": " Gallellagama",
        "countryCode": "LK"
    },
    {
        "name": " Gallewa",
        "countryCode": "LK"
    },
    {
        "name": " Galmetiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Galmuruwa",
        "countryCode": "LK"
    },
    {
        "name": " Galnewa",
        "countryCode": "LK"
    },
    {
        "name": " Galoya Junction",
        "countryCode": "LK"
    },
    {
        "name": " Galpatha(Sab)",
        "countryCode": "LK"
    },
    {
        "name": " Galpatha",
        "countryCode": "LK"
    },
    {
        "name": " Galporuyaya",
        "countryCode": "LK"
    },
    {
        "name": " Gamagoda",
        "countryCode": "LK"
    },
    {
        "name": " Gambirigaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Gamewela",
        "countryCode": "LK"
    },
    {
        "name": " Gammaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Gampaha",
        "countryCode": "LK"
    },
    {
        "name": " Gampola",
        "countryCode": "LK"
    },
    {
        "name": " Gandara",
        "countryCode": "LK"
    },
    {
        "name": " Ganegoda",
        "countryCode": "LK"
    },
    {
        "name": " Ganemulla",
        "countryCode": "LK"
    },
    {
        "name": " Ganewalpola",
        "countryCode": "LK"
    },
    {
        "name": " Gangala Puwakpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Gangeyaya",
        "countryCode": "LK"
    },
    {
        "name": " Gangulandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Gangulandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Gantuna",
        "countryCode": "LK"
    },
    {
        "name": " Gawaragiriya",
        "countryCode": "LK"
    },
    {
        "name": " Gawarawela",
        "countryCode": "LK"
    },
    {
        "name": " Gelioya",
        "countryCode": "LK"
    },
    {
        "name": " Gem & Jewelry",
        "countryCode": "LK"
    },
    {
        "name": " Gemunupura",
        "countryCode": "LK"
    },
    {
        "name": " Getahetta",
        "countryCode": "LK"
    },
    {
        "name": " Getalawa",
        "countryCode": "LK"
    },
    {
        "name": " Getamanna",
        "countryCode": "LK"
    },
    {
        "name": " Gillimale",
        "countryCode": "LK"
    },
    {
        "name": " Ginigathena",
        "countryCode": "LK"
    },
    {
        "name": " Ginimellagaha",
        "countryCode": "LK"
    },
    {
        "name": " Gintota",
        "countryCode": "LK"
    },
    {
        "name": " Girandurukotte",
        "countryCode": "LK"
    },
    {
        "name": " Girathalana",
        "countryCode": "LK"
    },
    {
        "name": " Giritale",
        "countryCode": "LK"
    },
    {
        "name": " Giriulla",
        "countryCode": "LK"
    },
    {
        "name": " Gnanikulama",
        "countryCode": "LK"
    },
    {
        "name": " Goda Koggalla",
        "countryCode": "LK"
    },
    {
        "name": " Godagama Junction",
        "countryCode": "LK"
    },
    {
        "name": " Godagampola",
        "countryCode": "LK"
    },
    {
        "name": " Godahena",
        "countryCode": "LK"
    },
    {
        "name": " Godakawela",
        "countryCode": "LK"
    },
    {
        "name": " Godamunna",
        "countryCode": "LK"
    },
    {
        "name": " Godapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Godunna",
        "countryCode": "LK"
    },
    {
        "name": " Gokaralla",
        "countryCode": "LK"
    },
    {
        "name": " Gomagoda",
        "countryCode": "LK"
    },
    {
        "name": " Gomarankadawala",
        "countryCode": "LK"
    },
    {
        "name": " Gomilamawarala",
        "countryCode": "LK"
    },
    {
        "name": " Gonagala",
        "countryCode": "LK"
    },
    {
        "name": " Gonagalpura",
        "countryCode": "LK"
    },
    {
        "name": " Gonagamuwa Uduwila",
        "countryCode": "LK"
    },
    {
        "name": " Gonagantenna",
        "countryCode": "LK"
    },
    {
        "name": " Gonagolla",
        "countryCode": "LK"
    },
    {
        "name": " Gonahaddenawa",
        "countryCode": "LK"
    },
    {
        "name": " Gonakele",
        "countryCode": "LK"
    },
    {
        "name": " Gonamulla Junction",
        "countryCode": "LK"
    },
    {
        "name": " Gonapinuwala",
        "countryCode": "LK"
    },
    {
        "name": " Gonapola Junction",
        "countryCode": "LK"
    },
    {
        "name": " Gonawala",
        "countryCode": "LK"
    },
    {
        "name": " Gonawalapatana",
        "countryCode": "LK"
    },
    {
        "name": " Gonawila",
        "countryCode": "LK"
    },
    {
        "name": " Gonnoruwa",
        "countryCode": "LK"
    },
    {
        "name": " Gorakapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Gothami Road",
        "countryCode": "LK"
    },
    {
        "name": " Govinna",
        "countryCode": "LK"
    },
    {
        "name": " Gunnepana",
        "countryCode": "LK"
    },
    {
        "name": " Gurubewilagama",
        "countryCode": "LK"
    },
    {
        "name": " Gurudeniya",
        "countryCode": "LK"
    },
    {
        "name": " Gurulana",
        "countryCode": "LK"
    },
    {
        "name": " Gurulubadda",
        "countryCode": "LK"
    },
    {
        "name": " Gurunagar",
        "countryCode": "LK"
    },
    {
        "name": " Gurutalawa",
        "countryCode": "LK"
    },
    {
        "name": " Habaraduwa",
        "countryCode": "LK"
    },
    {
        "name": " Habarakada",
        "countryCode": "LK"
    },
    {
        "name": " Habarana",
        "countryCode": "LK"
    },
    {
        "name": " Haburugala",
        "countryCode": "LK"
    },
    {
        "name": " Haggala",
        "countryCode": "LK"
    },
    {
        "name": " Hakahinna",
        "countryCode": "LK"
    },
    {
        "name": " Hakbellawaka",
        "countryCode": "LK"
    },
    {
        "name": " Hakmana",
        "countryCode": "LK"
    },
    {
        "name": " Hakuruwela",
        "countryCode": "LK"
    },
    {
        "name": " Haldummulla",
        "countryCode": "LK"
    },
    {
        "name": " Halgranoya",
        "countryCode": "LK"
    },
    {
        "name": " Hali Ela",
        "countryCode": "LK"
    },
    {
        "name": " Halkandawila",
        "countryCode": "LK"
    },
    {
        "name": " Halloluwa",
        "countryCode": "LK"
    },
    {
        "name": " Halmillawa Dambulla",
        "countryCode": "LK"
    },
    {
        "name": " Halmillawetiya",
        "countryCode": "LK"
    },
    {
        "name": " Halmillawewa",
        "countryCode": "LK"
    },
    {
        "name": " Halpe",
        "countryCode": "LK"
    },
    {
        "name": " Haltota",
        "countryCode": "LK"
    },
    {
        "name": " Halvitigala Colony",
        "countryCode": "LK"
    },
    {
        "name": " Halwala",
        "countryCode": "LK"
    },
    {
        "name": " Halwatura",
        "countryCode": "LK"
    },
    {
        "name": " Halwinna",
        "countryCode": "LK"
    },
    {
        "name": " Hambantota",
        "countryCode": "LK"
    },
    {
        "name": " Hambegamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Handaganawa",
        "countryCode": "LK"
    },
    {
        "name": " Handagiriya",
        "countryCode": "LK"
    },
    {
        "name": " Handapangoda",
        "countryCode": "LK"
    },
    {
        "name": " Handawalapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Handessa",
        "countryCode": "LK"
    },
    {
        "name": " Handugala",
        "countryCode": "LK"
    },
    {
        "name": " Handungamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Hangarapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Hangunnawa",
        "countryCode": "LK"
    },
    {
        "name": " Hanguranketha",
        "countryCode": "LK"
    },
    {
        "name": " Hansayapalama",
        "countryCode": "LK"
    },
    {
        "name": " Hanwella",
        "countryCode": "LK"
    },
    {
        "name": " Hapugastenna",
        "countryCode": "LK"
    },
    {
        "name": " Hapugasthalawa",
        "countryCode": "LK"
    },
    {
        "name": " Haputale",
        "countryCode": "LK"
    },
    {
        "name": " Harangalagama",
        "countryCode": "LK"
    },
    {
        "name": " Harankahawa",
        "countryCode": "LK"
    },
    {
        "name": " Harasbedda",
        "countryCode": "LK"
    },
    {
        "name": " Harispattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Hasalaka",
        "countryCode": "LK"
    },
    {
        "name": " Hatangala",
        "countryCode": "LK"
    },
    {
        "name": " Hatarabage",
        "countryCode": "LK"
    },
    {
        "name": " Hataraliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Hatton",
        "countryCode": "LK"
    },
    {
        "name": " Hattota Amuna",
        "countryCode": "LK"
    },
    {
        "name": " Hawpe",
        "countryCode": "LK"
    },
    {
        "name": " Hebarawa",
        "countryCode": "LK"
    },
    {
        "name": " Hedigalla Colony",
        "countryCode": "LK"
    },
    {
        "name": " Hedunuwewa",
        "countryCode": "LK"
    },
    {
        "name": " Heeloya",
        "countryCode": "LK"
    },
    {
        "name": " Heiyanthuduwa",
        "countryCode": "LK"
    },
    {
        "name": " Helahalpe",
        "countryCode": "LK"
    },
    {
        "name": " Helamada",
        "countryCode": "LK"
    },
    {
        "name": " Helapupula",
        "countryCode": "LK"
    },
    {
        "name": " Hemmatagama",
        "countryCode": "LK"
    },
    {
        "name": " Henegama",
        "countryCode": "LK"
    },
    {
        "name": " Hengamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Hettimulla",
        "countryCode": "LK"
    },
    {
        "name": " Hettipola",
        "countryCode": "LK"
    },
    {
        "name": " Hewadiwela",
        "countryCode": "LK"
    },
    {
        "name": " Hewaheta",
        "countryCode": "LK"
    },
    {
        "name": " Hewainna",
        "countryCode": "LK"
    },
    {
        "name": " Hewanakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Hidellana",
        "countryCode": "LK"
    },
    {
        "name": " Hidogama",
        "countryCode": "LK"
    },
    {
        "name": " Hikkaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Hilogama",
        "countryCode": "LK"
    },
    {
        "name": " Hinatiyana Madawala",
        "countryCode": "LK"
    },
    {
        "name": " Hindagala",
        "countryCode": "LK"
    },
    {
        "name": " Hindagolla",
        "countryCode": "LK"
    },
    {
        "name": " Hingula",
        "countryCode": "LK"
    },
    {
        "name": " Hingurakdamana",
        "countryCode": "LK"
    },
    {
        "name": " Hingurakgoda",
        "countryCode": "LK"
    },
    {
        "name": " Hinguralakanda",
        "countryCode": "LK"
    },
    {
        "name": " Hingurana",
        "countryCode": "LK"
    },
    {
        "name": " Hingurukaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Hiniduma",
        "countryCode": "LK"
    },
    {
        "name": " Hiramadagama",
        "countryCode": "LK"
    },
    {
        "name": " Hiripitya",
        "countryCode": "LK"
    },
    {
        "name": " Hiriwadunna",
        "countryCode": "LK"
    },
    {
        "name": " Hiriyala Lenawa",
        "countryCode": "LK"
    },
    {
        "name": " Hiruwalpola",
        "countryCode": "LK"
    },
    {
        "name": " Hiswella",
        "countryCode": "LK"
    },
    {
        "name": " Hitigegama",
        "countryCode": "LK"
    },
    {
        "name": " Hiyare",
        "countryCode": "LK"
    },
    {
        "name": " Hokandara",
        "countryCode": "LK"
    },
    {
        "name": " Homagama Town",
        "countryCode": "LK"
    },
    {
        "name": " Homagama",
        "countryCode": "LK"
    },
    {
        "name": " Hondiyadeniya",
        "countryCode": "LK"
    },
    {
        "name": " Hopton",
        "countryCode": "LK"
    },
    {
        "name": " Horagala",
        "countryCode": "LK"
    },
    {
        "name": " Horambawa",
        "countryCode": "LK"
    },
    {
        "name": " Horampella",
        "countryCode": "LK"
    },
    {
        "name": " Horana",
        "countryCode": "LK"
    },
    {
        "name": " Horapawita",
        "countryCode": "LK"
    },
    {
        "name": " Horawpatana",
        "countryCode": "LK"
    },
    {
        "name": " Horewelagoda",
        "countryCode": "LK"
    },
    {
        "name": " Horiwila",
        "countryCode": "LK"
    },
    {
        "name": " Hulandawa",
        "countryCode": "LK"
    },
    {
        "name": " Hulannuge",
        "countryCode": "LK"
    },
    {
        "name": " Hulogedara",
        "countryCode": "LK"
    },
    {
        "name": " Hulugalla",
        "countryCode": "LK"
    },
    {
        "name": " Hungama",
        "countryCode": "LK"
    },
    {
        "name": " Hunnasgiriya",
        "countryCode": "LK"
    },
    {
        "name": " Hunumulla",
        "countryCode": "LK"
    },
    {
        "name": " Hunupola",
        "countryCode": "LK"
    },
    {
        "name": " Hurigaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Hurulunikawewa",
        "countryCode": "LK"
    },
    {
        "name": " Ibbagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Idalgashinna",
        "countryCode": "LK"
    },
    {
        "name": " Idalgashinna",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Beligalla",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Gomugomuwa",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Halmillewa",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Kadigamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Katugampala",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Kobbekaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Kottaramulla",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Madampella",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Puliyankulama",
        "countryCode": "LK"
    },
    {
        "name": " Ihala Walpola",
        "countryCode": "LK"
    },
    {
        "name": " Ihalagama",
        "countryCode": "LK"
    },
    {
        "name": " Ihalagama",
        "countryCode": "LK"
    },
    {
        "name": " Ihalahewessa",
        "countryCode": "LK"
    },
    {
        "name": " Ilavalai",
        "countryCode": "LK"
    },
    {
        "name": " Ilippadeniya",
        "countryCode": "LK"
    },
    {
        "name": " Illagolla",
        "countryCode": "LK"
    },
    {
        "name": " Illukkumbura",
        "countryCode": "LK"
    },
    {
        "name": " Illuppaikadavai",
        "countryCode": "LK"
    },
    {
        "name": " Ilukhena",
        "countryCode": "LK"
    },
    {
        "name": " Imaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Imbulana",
        "countryCode": "LK"
    },
    {
        "name": " Imbulgasdeniya",
        "countryCode": "LK"
    },
    {
        "name": " Imbulgoda",
        "countryCode": "LK"
    },
    {
        "name": " Imbulgolla",
        "countryCode": "LK"
    },
    {
        "name": " Imbulpe",
        "countryCode": "LK"
    },
    {
        "name": " Inamaluwa",
        "countryCode": "LK"
    },
    {
        "name": " Indibedda",
        "countryCode": "LK"
    },
    {
        "name": " Indulgodakanda",
        "countryCode": "LK"
    },
    {
        "name": " Induruwa",
        "countryCode": "LK"
    },
    {
        "name": " Inginimitiya",
        "countryCode": "LK"
    },
    {
        "name": " Inginiyagala",
        "countryCode": "LK"
    },
    {
        "name": " Ingiriya",
        "countryCode": "LK"
    },
    {
        "name": " Inguruwatta",
        "countryCode": "LK"
    },
    {
        "name": " Ipologama",
        "countryCode": "LK"
    },
    {
        "name": " Irakkamam",
        "countryCode": "LK"
    },
    {
        "name": " Iranai lluppaikulam",
        "countryCode": "LK"
    },
    {
        "name": " Iranaitiv",
        "countryCode": "LK"
    },
    {
        "name": " Iratta Periyakulama",
        "countryCode": "LK"
    },
    {
        "name": " Iriyagolla",
        "countryCode": "LK"
    },
    {
        "name": " Ismailpuram",
        "countryCode": "LK"
    },
    {
        "name": " Isurupaya",
        "countryCode": "LK"
    },
    {
        "name": " Ithanawatta",
        "countryCode": "LK"
    },
    {
        "name": " Iththa Demaliya",
        "countryCode": "LK"
    },
    {
        "name": " Ittakanda",
        "countryCode": "LK"
    },
    {
        "name": " Ittapana",
        "countryCode": "LK"
    },
    {
        "name": " Iyyakachchi",
        "countryCode": "LK"
    },
    {
        "name": " Ja-Ela",
        "countryCode": "LK"
    },
    {
        "name": " Jaffna",
        "countryCode": "LK"
    },
    {
        "name": " Jambugahapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Jangulla",
        "countryCode": "LK"
    },
    {
        "name": " Jayanthipura",
        "countryCode": "LK"
    },
    {
        "name": " Jayasiripura",
        "countryCode": "LK"
    },
    {
        "name": " Jayawardhenegama",
        "countryCode": "LK"
    },
    {
        "name": " Julampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kabagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kadawatha",
        "countryCode": "LK"
    },
    {
        "name": " Kaddaiparichchan",
        "countryCode": "LK"
    },
    {
        "name": " Kadigawa",
        "countryCode": "LK"
    },
    {
        "name": " Kadugannawa",
        "countryCode": "LK"
    },
    {
        "name": " Kaduwela",
        "countryCode": "LK"
    },
    {
        "name": " Kagama",
        "countryCode": "LK"
    },
    {
        "name": " Kahaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Kahandamodara",
        "countryCode": "LK"
    },
    {
        "name": " Kahangama",
        "countryCode": "LK"
    },
    {
        "name": " Kahapathwala",
        "countryCode": "LK"
    },
    {
        "name": " Kahatagasdigiliya",
        "countryCode": "LK"
    },
    {
        "name": " Kahatagollewa",
        "countryCode": "LK"
    },
    {
        "name": " Kahataliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Kahataruppa",
        "countryCode": "LK"
    },
    {
        "name": " Kahathuduwa",
        "countryCode": "LK"
    },
    {
        "name": " Kahatowita",
        "countryCode": "LK"
    },
    {
        "name": " Kahawa",
        "countryCode": "LK"
    },
    {
        "name": " Kahawala",
        "countryCode": "LK"
    },
    {
        "name": " Kahawatta",
        "countryCode": "LK"
    },
    {
        "name": " Kaikawala",
        "countryCode": "LK"
    },
    {
        "name": " Kaitadi",
        "countryCode": "LK"
    },
    {
        "name": " Kakkapalliya",
        "countryCode": "LK"
    },
    {
        "name": " Kalaganwatta",
        "countryCode": "LK"
    },
    {
        "name": " Kalagedihena",
        "countryCode": "LK"
    },
    {
        "name": " Kalakarambewa",
        "countryCode": "LK"
    },
    {
        "name": " Kalankuttiya",
        "countryCode": "LK"
    },
    {
        "name": " Kalaoya",
        "countryCode": "LK"
    },
    {
        "name": " Kalapaluwawa",
        "countryCode": "LK"
    },
    {
        "name": " Kalatuwawa",
        "countryCode": "LK"
    },
    {
        "name": " Kalawana",
        "countryCode": "LK"
    },
    {
        "name": " Kalawedi Ulpotha",
        "countryCode": "LK"
    },
    {
        "name": " Kalawila Kiranthidiya",
        "countryCode": "LK"
    },
    {
        "name": " Kaldemulla",
        "countryCode": "LK"
    },
    {
        "name": " Kaleliya",
        "countryCode": "LK"
    },
    {
        "name": " Kalingaela",
        "countryCode": "LK"
    },
    {
        "name": " Kalkudah",
        "countryCode": "LK"
    },
    {
        "name": " Kalladiya",
        "countryCode": "LK"
    },
    {
        "name": " Kallanchiya",
        "countryCode": "LK"
    },
    {
        "name": " Kallar",
        "countryCode": "LK"
    },
    {
        "name": " Kalmadhu",
        "countryCode": "LK"
    },
    {
        "name": " Kalmadunagar",
        "countryCode": "LK"
    },
    {
        "name": " Kalmunai",
        "countryCode": "LK"
    },
    {
        "name": " Kalpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kaltota",
        "countryCode": "LK"
    },
    {
        "name": " Kaluaggala",
        "countryCode": "LK"
    },
    {
        "name": " Kalubowitiyana",
        "countryCode": "LK"
    },
    {
        "name": " Kalubululanda",
        "countryCode": "LK"
    },
    {
        "name": " Kalugahakandura",
        "countryCode": "LK"
    },
    {
        "name": " Kalugala",
        "countryCode": "LK"
    },
    {
        "name": " Kalugamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kalukele Badanagala",
        "countryCode": "LK"
    },
    {
        "name": " Kalundawa",
        "countryCode": "LK"
    },
    {
        "name": " Kalupahana",
        "countryCode": "LK"
    },
    {
        "name": " Kalutara",
        "countryCode": "LK"
    },
    {
        "name": " Kaluwanchikudi",
        "countryCode": "LK"
    },
    {
        "name": " Kaluwankemy",
        "countryCode": "LK"
    },
    {
        "name": " Kamburugamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kamburupitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kanadeniyawala",
        "countryCode": "LK"
    },
    {
        "name": " Kanagarayankulam",
        "countryCode": "LK"
    },
    {
        "name": " Kananke Bazaar",
        "countryCode": "LK"
    },
    {
        "name": " Kananwila",
        "countryCode": "LK"
    },
    {
        "name": " Kanattewewa",
        "countryCode": "LK"
    },
    {
        "name": " Kandaketya",
        "countryCode": "LK"
    },
    {
        "name": " Kandakuliya",
        "countryCode": "LK"
    },
    {
        "name": " Kandalama",
        "countryCode": "LK"
    },
    {
        "name": " Kandana",
        "countryCode": "LK"
    },
    {
        "name": " Kandanagama",
        "countryCode": "LK"
    },
    {
        "name": " Kandanapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kandapola",
        "countryCode": "LK"
    },
    {
        "name": " Kandaudapanguwa",
        "countryCode": "LK"
    },
    {
        "name": " Kandavalai",
        "countryCode": "LK"
    },
    {
        "name": " Kandawinna",
        "countryCode": "LK"
    },
    {
        "name": " Kandegedara",
        "countryCode": "LK"
    },
    {
        "name": " Kandepuhulpola",
        "countryCode": "LK"
    },
    {
        "name": " Kandy",
        "countryCode": "LK"
    },
    {
        "name": " Kankesanthurai",
        "countryCode": "LK"
    },
    {
        "name": " Kannaddi",
        "countryCode": "LK"
    },
    {
        "name": " Kannakipuram",
        "countryCode": "LK"
    },
    {
        "name": " Kannankudah",
        "countryCode": "LK"
    },
    {
        "name": " Kannattota",
        "countryCode": "LK"
    },
    {
        "name": " Kanniya",
        "countryCode": "LK"
    },
    {
        "name": " Kantalai Sugar Factory",
        "countryCode": "LK"
    },
    {
        "name": " Kantalai",
        "countryCode": "LK"
    },
    {
        "name": " Kapugallawa",
        "countryCode": "LK"
    },
    {
        "name": " Kapugoda",
        "countryCode": "LK"
    },
    {
        "name": " Kapuliyadde",
        "countryCode": "LK"
    },
    {
        "name": " Karachchi",
        "countryCode": "LK"
    },
    {
        "name": " Karadipokku",
        "countryCode": "LK"
    },
    {
        "name": " Karadiyanaru",
        "countryCode": "LK"
    },
    {
        "name": " Karagahagedara",
        "countryCode": "LK"
    },
    {
        "name": " Karagahawewa",
        "countryCode": "LK"
    },
    {
        "name": " Karagahinna",
        "countryCode": "LK"
    },
    {
        "name": " Karagoda Uyangoda",
        "countryCode": "LK"
    },
    {
        "name": " Karagoda",
        "countryCode": "LK"
    },
    {
        "name": " Karainagar",
        "countryCode": "LK"
    },
    {
        "name": " Karambe",
        "countryCode": "LK"
    },
    {
        "name": " Karandagolla",
        "countryCode": "LK"
    },
    {
        "name": " Karandana",
        "countryCode": "LK"
    },
    {
        "name": " Karandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Karangoda",
        "countryCode": "LK"
    },
    {
        "name": " Karaputugala",
        "countryCode": "LK"
    },
    {
        "name": " Karathivu",
        "countryCode": "LK"
    },
    {
        "name": " Karativu",
        "countryCode": "LK"
    },
    {
        "name": " Karatota",
        "countryCode": "LK"
    },
    {
        "name": " Karatota",
        "countryCode": "LK"
    },
    {
        "name": " Karaveddi",
        "countryCode": "LK"
    },
    {
        "name": " Karawitagara",
        "countryCode": "LK"
    },
    {
        "name": " Karisal",
        "countryCode": "LK"
    },
    {
        "name": " Kariyamaditta",
        "countryCode": "LK"
    },
    {
        "name": " Karuppaddamurippu",
        "countryCode": "LK"
    },
    {
        "name": " Karuwalagaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Kashyapapura",
        "countryCode": "LK"
    },
    {
        "name": " Katana",
        "countryCode": "LK"
    },
    {
        "name": " Kataragama",
        "countryCode": "LK"
    },
    {
        "name": " Kathiraveli",
        "countryCode": "LK"
    },
    {
        "name": " Katiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Kattankudi",
        "countryCode": "LK"
    },
    {
        "name": " Katubedda",
        "countryCode": "LK"
    },
    {
        "name": " Katudeniya",
        "countryCode": "LK"
    },
    {
        "name": " Katugastota",
        "countryCode": "LK"
    },
    {
        "name": " Katukitula",
        "countryCode": "LK"
    },
    {
        "name": " Katunayake Air Force Camp",
        "countryCode": "LK"
    },
    {
        "name": " Katunayake(FTZ)",
        "countryCode": "LK"
    },
    {
        "name": " Katunayake",
        "countryCode": "LK"
    },
    {
        "name": " Katuneriya",
        "countryCode": "LK"
    },
    {
        "name": " Katupota",
        "countryCode": "LK"
    },
    {
        "name": " Katuwana",
        "countryCode": "LK"
    },
    {
        "name": " Katuwawala",
        "countryCode": "LK"
    },
    {
        "name": " Katuwellegama",
        "countryCode": "LK"
    },
    {
        "name": " Kavudupelella",
        "countryCode": "LK"
    },
    {
        "name": " Kavutharimunai",
        "countryCode": "LK"
    },
    {
        "name": " Kawantissapura",
        "countryCode": "LK"
    },
    {
        "name": " Kawudulla",
        "countryCode": "LK"
    },
    {
        "name": " Kawuduluwewa Stagell",
        "countryCode": "LK"
    },
    {
        "name": " Kayts",
        "countryCode": "LK"
    },
    {
        "name": " Kebillawela",
        "countryCode": "LK"
    },
    {
        "name": " Kebithigollewa",
        "countryCode": "LK"
    },
    {
        "name": " Kebithigollewa",
        "countryCode": "LK"
    },
    {
        "name": " Keerthi Bandarapura",
        "countryCode": "LK"
    },
    {
        "name": " Kegalle",
        "countryCode": "LK"
    },
    {
        "name": " Kehelpannala",
        "countryCode": "LK"
    },
    {
        "name": " Kehelwatta",
        "countryCode": "LK"
    },
    {
        "name": " Kekanadura",
        "countryCode": "LK"
    },
    {
        "name": " Kekirawa",
        "countryCode": "LK"
    },
    {
        "name": " Kekirihena",
        "countryCode": "LK"
    },
    {
        "name": " Kekunagolla",
        "countryCode": "LK"
    },
    {
        "name": " Kela Bogaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Kelanigama",
        "countryCode": "LK"
    },
    {
        "name": " Kelaniya",
        "countryCode": "LK"
    },
    {
        "name": " Kelinkanda",
        "countryCode": "LK"
    },
    {
        "name": " Kella Junction",
        "countryCode": "LK"
    },
    {
        "name": " Kendagolla",
        "countryCode": "LK"
    },
    {
        "name": " Kendewa",
        "countryCode": "LK"
    },
    {
        "name": " Kengalla",
        "countryCode": "LK"
    },
    {
        "name": " Keppetipola Mawatha",
        "countryCode": "LK"
    },
    {
        "name": " Keppetipola",
        "countryCode": "LK"
    },
    {
        "name": " Keppitiwalana",
        "countryCode": "LK"
    },
    {
        "name": " Kesbewa",
        "countryCode": "LK"
    },
    {
        "name": " Keselpotha",
        "countryCode": "LK"
    },
    {
        "name": " Ketaboola",
        "countryCode": "LK"
    },
    {
        "name": " Ketakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Ketawala Leula",
        "countryCode": "LK"
    },
    {
        "name": " Ketawatta",
        "countryCode": "LK"
    },
    {
        "name": " Kibissa",
        "countryCode": "LK"
    },
    {
        "name": " Kilay",
        "countryCode": "LK"
    },
    {
        "name": " Kilinochchi",
        "countryCode": "LK"
    },
    {
        "name": " Kiliveddy",
        "countryCode": "LK"
    },
    {
        "name": " Kimbulapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kimbulwanaoya",
        "countryCode": "LK"
    },
    {
        "name": " Kinniya",
        "countryCode": "LK"
    },
    {
        "name": " Kiralogama",
        "countryCode": "LK"
    },
    {
        "name": " Kirama",
        "countryCode": "LK"
    },
    {
        "name": " Kiran",
        "countryCode": "LK"
    },
    {
        "name": " Kirankulam",
        "countryCode": "LK"
    },
    {
        "name": " Kiribathkumbura",
        "countryCode": "LK"
    },
    {
        "name": " Kiriella",
        "countryCode": "LK"
    },
    {
        "name": " Kirigalwewa",
        "countryCode": "LK"
    },
    {
        "name": " Kiriibbanwewa",
        "countryCode": "LK"
    },
    {
        "name": " Kirimetiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Kirimundalama",
        "countryCode": "LK"
    },
    {
        "name": " Kirinda",
        "countryCode": "LK"
    },
    {
        "name": " Kirindawa",
        "countryCode": "LK"
    },
    {
        "name": " Kirindigalla",
        "countryCode": "LK"
    },
    {
        "name": " Kirindiwela",
        "countryCode": "LK"
    },
    {
        "name": " Kiriwanagama",
        "countryCode": "LK"
    },
    {
        "name": " Kiriwattuduwa",
        "countryCode": "LK"
    },
    {
        "name": " Kiriweldola",
        "countryCode": "LK"
    },
    {
        "name": " Kiriwelkele",
        "countryCode": "LK"
    },
    {
        "name": " Kirulapana",
        "countryCode": "LK"
    },
    {
        "name": " Kitalawalana",
        "countryCode": "LK"
    },
    {
        "name": " Kithalawa",
        "countryCode": "LK"
    },
    {
        "name": " Kitulgala",
        "countryCode": "LK"
    },
    {
        "name": " Kitulgoda",
        "countryCode": "LK"
    },
    {
        "name": " Kitulhitiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Kitulwala",
        "countryCode": "LK"
    },
    {
        "name": " Kiwula",
        "countryCode": "LK"
    },
    {
        "name": " Kobeigane",
        "countryCode": "LK"
    },
    {
        "name": " Kobonila",
        "countryCode": "LK"
    },
    {
        "name": " Kochchikade",
        "countryCode": "LK"
    },
    {
        "name": " Koddaikallar",
        "countryCode": "LK"
    },
    {
        "name": " Kodikamam",
        "countryCode": "LK"
    },
    {
        "name": " Kohilagedara",
        "countryCode": "LK"
    },
    {
        "name": " Koholana",
        "countryCode": "LK"
    },
    {
        "name": " Kohuwala",
        "countryCode": "LK"
    },
    {
        "name": " Kokkaddicholai",
        "countryCode": "LK"
    },
    {
        "name": " Kokkilai",
        "countryCode": "LK"
    },
    {
        "name": " Kokkupadayan",
        "countryCode": "LK"
    },
    {
        "name": " Kokkuthuoduvai",
        "countryCode": "LK"
    },
    {
        "name": " Koknahara",
        "countryCode": "LK"
    },
    {
        "name": " Kokuvil",
        "countryCode": "LK"
    },
    {
        "name": " Kolabissa",
        "countryCode": "LK"
    },
    {
        "name": " Kolamanthalawa",
        "countryCode": "LK"
    },
    {
        "name": " Kolambage Ara",
        "countryCode": "LK"
    },
    {
        "name": " Kolawenigama",
        "countryCode": "LK"
    },
    {
        "name": " Kolombugama",
        "countryCode": "LK"
    },
    {
        "name": " Kolongoda",
        "countryCode": "LK"
    },
    {
        "name": " Kolonna",
        "countryCode": "LK"
    },
    {
        "name": " Kolonnawa",
        "countryCode": "LK"
    },
    {
        "name": " Komari",
        "countryCode": "LK"
    },
    {
        "name": " Konavil",
        "countryCode": "LK"
    },
    {
        "name": " Kondavil",
        "countryCode": "LK"
    },
    {
        "name": " Kondeniya",
        "countryCode": "LK"
    },
    {
        "name": " Kongahawela",
        "countryCode": "LK"
    },
    {
        "name": " Konwewa",
        "countryCode": "LK"
    },
    {
        "name": " Kopay",
        "countryCode": "LK"
    },
    {
        "name": " Kopiyawatta",
        "countryCode": "LK"
    },
    {
        "name": " Koralawella",
        "countryCode": "LK"
    },
    {
        "name": " Kosdeniya",
        "countryCode": "LK"
    },
    {
        "name": " Kosgama",
        "countryCode": "LK"
    },
    {
        "name": " Kosgoda",
        "countryCode": "LK"
    },
    {
        "name": " Kosgolla",
        "countryCode": "LK"
    },
    {
        "name": " Koslanda",
        "countryCode": "LK"
    },
    {
        "name": " Koswatta",
        "countryCode": "LK"
    },
    {
        "name": " Kotadeniyawa",
        "countryCode": "LK"
    },
    {
        "name": " Kotagala",
        "countryCode": "LK"
    },
    {
        "name": " Kotagama",
        "countryCode": "LK"
    },
    {
        "name": " Kotamuduna",
        "countryCode": "LK"
    },
    {
        "name": " Kotapola",
        "countryCode": "LK"
    },
    {
        "name": " Kotawehera Mankada",
        "countryCode": "LK"
    },
    {
        "name": " Kotawehera",
        "countryCode": "LK"
    },
    {
        "name": " Kotiyagala",
        "countryCode": "LK"
    },
    {
        "name": " Kotiyakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Kotmale",
        "countryCode": "LK"
    },
    {
        "name": " Kottantivu",
        "countryCode": "LK"
    },
    {
        "name": " Kottapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kottawa North",
        "countryCode": "LK"
    },
    {
        "name": " Kottawa",
        "countryCode": "LK"
    },
    {
        "name": " Kottawagama",
        "countryCode": "LK"
    },
    {
        "name": " Kottegoda",
        "countryCode": "LK"
    },
    {
        "name": " Kottellena",
        "countryCode": "LK"
    },
    {
        "name": " Kottukachchiya",
        "countryCode": "LK"
    },
    {
        "name": " Kotugoda",
        "countryCode": "LK"
    },
    {
        "name": " Kovilkulam",
        "countryCode": "LK"
    },
    {
        "name": " Kuchchaveli",
        "countryCode": "LK"
    },
    {
        "name": " Kuda Uduwa",
        "countryCode": "LK"
    },
    {
        "name": " Kudagalgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kudakatnoruwa",
        "countryCode": "LK"
    },
    {
        "name": " Kudatanai",
        "countryCode": "LK"
    },
    {
        "name": " Kudawa",
        "countryCode": "LK"
    },
    {
        "name": " Kudawewa",
        "countryCode": "LK"
    },
    {
        "name": " Kuleegoda",
        "countryCode": "LK"
    },
    {
        "name": " Kuliyapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kulugammana",
        "countryCode": "LK"
    },
    {
        "name": " Kumaragama",
        "countryCode": "LK"
    },
    {
        "name": " Kumarakattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kumbalgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Kumbaloluwa",
        "countryCode": "LK"
    },
    {
        "name": " Kumbukgeta",
        "countryCode": "LK"
    },
    {
        "name": " Kumbukkana",
        "countryCode": "LK"
    },
    {
        "name": " Kumbukkandura",
        "countryCode": "LK"
    },
    {
        "name": " Kumbukwela",
        "countryCode": "LK"
    },
    {
        "name": " Kumbukwewa",
        "countryCode": "LK"
    },
    {
        "name": " Kumburegama",
        "countryCode": "LK"
    },
    {
        "name": " Kumburupiddy",
        "countryCode": "LK"
    },
    {
        "name": " Kumulamunai",
        "countryCode": "LK"
    },
    {
        "name": " Kunchuparanthan",
        "countryCode": "LK"
    },
    {
        "name": " Kundasale",
        "countryCode": "LK"
    },
    {
        "name": " Kuratihena",
        "countryCode": "LK"
    },
    {
        "name": " Kurinchakerny",
        "countryCode": "LK"
    },
    {
        "name": " Kurinjanpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Kuruketiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Kurukkalmadam",
        "countryCode": "LK"
    },
    {
        "name": " Kurundankulama",
        "countryCode": "LK"
    },
    {
        "name": " Kurunegala",
        "countryCode": "LK"
    },
    {
        "name": " Kurupanawela",
        "countryCode": "LK"
    },
    {
        "name": " Kuruwita",
        "countryCode": "LK"
    },
    {
        "name": " Kuruwitenna",
        "countryCode": "LK"
    },
    {
        "name": " Kuttiyagolla",
        "countryCode": "LK"
    },
    {
        "name": " Labbala",
        "countryCode": "LK"
    },
    {
        "name": " Labukele",
        "countryCode": "LK"
    },
    {
        "name": " Labunoruwa",
        "countryCode": "LK"
    },
    {
        "name": " Laggala Pallegama",
        "countryCode": "LK"
    },
    {
        "name": " Lahugala",
        "countryCode": "LK"
    },
    {
        "name": " Laksapatiya",
        "countryCode": "LK"
    },
    {
        "name": " Lakshauyana",
        "countryCode": "LK"
    },
    {
        "name": " Landewela",
        "countryCode": "LK"
    },
    {
        "name": " Lankagama",
        "countryCode": "LK"
    },
    {
        "name": " Lankapatuna",
        "countryCode": "LK"
    },
    {
        "name": " Laxapana",
        "countryCode": "LK"
    },
    {
        "name": " Leemagahakotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Leliambe",
        "countryCode": "LK"
    },
    {
        "name": " Lellopitiya",
        "countryCode": "LK"
    },
    {
        "name": " Lenadora",
        "countryCode": "LK"
    },
    {
        "name": " Lewangama",
        "countryCode": "LK"
    },
    {
        "name": " Lihiriyagama",
        "countryCode": "LK"
    },
    {
        "name": " Lindula",
        "countryCode": "LK"
    },
    {
        "name": " Liyangahawela",
        "countryCode": "LK"
    },
    {
        "name": " Liyanwala",
        "countryCode": "LK"
    },
    {
        "name": " Loluwagoda",
        "countryCode": "LK"
    },
    {
        "name": " Lonahettiya",
        "countryCode": "LK"
    },
    {
        "name": " Lunama",
        "countryCode": "LK"
    },
    {
        "name": " Lunawa",
        "countryCode": "LK"
    },
    {
        "name": " Lunugala",
        "countryCode": "LK"
    },
    {
        "name": " Lunugama",
        "countryCode": "LK"
    },
    {
        "name": " Lunugamwehera",
        "countryCode": "LK"
    },
    {
        "name": " Lunuketiya Maditta",
        "countryCode": "LK"
    },
    {
        "name": " Lunuwatta",
        "countryCode": "LK"
    },
    {
        "name": " Lunuwila",
        "countryCode": "LK"
    },
    {
        "name": " Mabodale",
        "countryCode": "LK"
    },
    {
        "name": " Mabola",
        "countryCode": "LK"
    },
    {
        "name": " Madahapola",
        "countryCode": "LK"
    },
    {
        "name": " Madakumburumulla",
        "countryCode": "LK"
    },
    {
        "name": " Madalagama",
        "countryCode": "LK"
    },
    {
        "name": " Madampe",
        "countryCode": "LK"
    },
    {
        "name": " Madapatha",
        "countryCode": "LK"
    },
    {
        "name": " Madatugama",
        "countryCode": "LK"
    },
    {
        "name": " Madawala Bazaar",
        "countryCode": "LK"
    },
    {
        "name": " Madawala Ulpotha",
        "countryCode": "LK"
    },
    {
        "name": " Madawalalanda",
        "countryCode": "LK"
    },
    {
        "name": " Madelgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Madhu Church",
        "countryCode": "LK"
    },
    {
        "name": " Madhu Road",
        "countryCode": "LK"
    },
    {
        "name": " Madipola",
        "countryCode": "LK"
    },
    {
        "name": " Madipola",
        "countryCode": "LK"
    },
    {
        "name": " Madiwela",
        "countryCode": "LK"
    },
    {
        "name": " Madugalla",
        "countryCode": "LK"
    },
    {
        "name": " Madukanda",
        "countryCode": "LK"
    },
    {
        "name": " Madulkele",
        "countryCode": "LK"
    },
    {
        "name": " Madulla",
        "countryCode": "LK"
    },
    {
        "name": " Madulsima",
        "countryCode": "LK"
    },
    {
        "name": " Maduragoda",
        "countryCode": "LK"
    },
    {
        "name": " Madurankuliya",
        "countryCode": "LK"
    },
    {
        "name": " Maeliya",
        "countryCode": "LK"
    },
    {
        "name": " Magama",
        "countryCode": "LK"
    },
    {
        "name": " Magammana-Dolekade",
        "countryCode": "LK"
    },
    {
        "name": " Magedara",
        "countryCode": "LK"
    },
    {
        "name": " Maggona",
        "countryCode": "LK"
    },
    {
        "name": " Magulagama",
        "countryCode": "LK"
    },
    {
        "name": " Maha Ambagaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Maha Elagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Mahabage",
        "countryCode": "LK"
    },
    {
        "name": " Mahabulankulama",
        "countryCode": "LK"
    },
    {
        "name": " Mahadivulwewa",
        "countryCode": "LK"
    },
    {
        "name": " Mahadoraliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Mahagalkadawala",
        "countryCode": "LK"
    },
    {
        "name": " Mahagalwewa",
        "countryCode": "LK"
    },
    {
        "name": " Mahagama Colony",
        "countryCode": "LK"
    },
    {
        "name": " Mahagama",
        "countryCode": "LK"
    },
    {
        "name": " Mahagirilla",
        "countryCode": "LK"
    },
    {
        "name": " Mahailluppallama",
        "countryCode": "LK"
    },
    {
        "name": " Mahakachchakodiya",
        "countryCode": "LK"
    },
    {
        "name": " Mahakalupahana",
        "countryCode": "LK"
    },
    {
        "name": " Mahakanadarawa",
        "countryCode": "LK"
    },
    {
        "name": " Mahakumbukkadawala",
        "countryCode": "LK"
    },
    {
        "name": " Mahamedagama",
        "countryCode": "LK"
    },
    {
        "name": " Mahamukalanyaya",
        "countryCode": "LK"
    },
    {
        "name": " Mahanagapura",
        "countryCode": "LK"
    },
    {
        "name": " Mahananneriya",
        "countryCode": "LK"
    },
    {
        "name": " Mahaoya",
        "countryCode": "LK"
    },
    {
        "name": " Mahapallegama",
        "countryCode": "LK"
    },
    {
        "name": " Mahapothana",
        "countryCode": "LK"
    },
    {
        "name": " Maharachchimulla",
        "countryCode": "LK"
    },
    {
        "name": " Maharagama",
        "countryCode": "LK"
    },
    {
        "name": " Maharangalla",
        "countryCode": "LK"
    },
    {
        "name": " Maharugiramam",
        "countryCode": "LK"
    },
    {
        "name": " Mahasenpura",
        "countryCode": "LK"
    },
    {
        "name": " Mahatalakolawewa",
        "countryCode": "LK"
    },
    {
        "name": " Mahauswewa",
        "countryCode": "LK"
    },
    {
        "name": " Mahawalatenna",
        "countryCode": "LK"
    },
    {
        "name": " Mahawela Sinhapura",
        "countryCode": "LK"
    },
    {
        "name": " Mahawela",
        "countryCode": "LK"
    },
    {
        "name": " Mahawewa",
        "countryCode": "LK"
    },
    {
        "name": " Mahawilachchiya",
        "countryCode": "LK"
    },
    {
        "name": " Mahiyampathy",
        "countryCode": "LK"
    },
    {
        "name": " Mahiyanganaya",
        "countryCode": "LK"
    },
    {
        "name": " Maho",
        "countryCode": "LK"
    },
    {
        "name": " Mailagaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Mailapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Makandana",
        "countryCode": "LK"
    },
    {
        "name": " Makandura",
        "countryCode": "LK"
    },
    {
        "name": " Makandura",
        "countryCode": "LK"
    },
    {
        "name": " Makehelwala",
        "countryCode": "LK"
    },
    {
        "name": " Makewita",
        "countryCode": "LK"
    },
    {
        "name": " Makkanigama",
        "countryCode": "LK"
    },
    {
        "name": " Makola",
        "countryCode": "LK"
    },
    {
        "name": " Makuldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Makulella",
        "countryCode": "LK"
    },
    {
        "name": " Makulewa",
        "countryCode": "LK"
    },
    {
        "name": " Makulpotha",
        "countryCode": "LK"
    },
    {
        "name": " Makulwewa",
        "countryCode": "LK"
    },
    {
        "name": " Malabe",
        "countryCode": "LK"
    },
    {
        "name": " Malabe",
        "countryCode": "LK"
    },
    {
        "name": " Malagala",
        "countryCode": "LK"
    },
    {
        "name": " Malagane",
        "countryCode": "LK"
    },
    {
        "name": " Malalpola",
        "countryCode": "LK"
    },
    {
        "name": " Malapalla",
        "countryCode": "LK"
    },
    {
        "name": " Maldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Malgalla Talangalla",
        "countryCode": "LK"
    },
    {
        "name": " Malgoda",
        "countryCode": "LK"
    },
    {
        "name": " Maliboda",
        "countryCode": "LK"
    },
    {
        "name": " Maliduwa",
        "countryCode": "LK"
    },
    {
        "name": " Maligakanda",
        "countryCode": "LK"
    },
    {
        "name": " Maligawatta",
        "countryCode": "LK"
    },
    {
        "name": " Maliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Mallakam",
        "countryCode": "LK"
    },
    {
        "name": " Mallikativu",
        "countryCode": "LK"
    },
    {
        "name": " Malmaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Malwala Junction",
        "countryCode": "LK"
    },
    {
        "name": " Malwana",
        "countryCode": "LK"
    },
    {
        "name": " Malwanagama",
        "countryCode": "LK"
    },
    {
        "name": " Malwatta",
        "countryCode": "LK"
    },
    {
        "name": " Mamadala",
        "countryCode": "LK"
    },
    {
        "name": " Mamaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Mampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Mampuri",
        "countryCode": "LK"
    },
    {
        "name": " Mananwatta",
        "countryCode": "LK"
    },
    {
        "name": " Mandaitivu",
        "countryCode": "LK"
    },
    {
        "name": " Mandapola",
        "countryCode": "LK"
    },
    {
        "name": " Mandaram Nuwara",
        "countryCode": "LK"
    },
    {
        "name": " Mandawala",
        "countryCode": "LK"
    },
    {
        "name": " Mandur",
        "countryCode": "LK"
    },
    {
        "name": " Maneruwa",
        "countryCode": "LK"
    },
    {
        "name": " Mangalaeliya",
        "countryCode": "LK"
    },
    {
        "name": " Mangalagama",
        "countryCode": "LK"
    },
    {
        "name": " Manipay",
        "countryCode": "LK"
    },
    {
        "name": " Mankemi",
        "countryCode": "LK"
    },
    {
        "name": " Mankulam",
        "countryCode": "LK"
    },
    {
        "name": " Mannar",
        "countryCode": "LK"
    },
    {
        "name": " Mapakadawewa",
        "countryCode": "LK"
    },
    {
        "name": " Mapakanda",
        "countryCode": "LK"
    },
    {
        "name": " Mapalagama Central",
        "countryCode": "LK"
    },
    {
        "name": " Mapalagama",
        "countryCode": "LK"
    },
    {
        "name": " Maradammaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Maradankadawala",
        "countryCode": "LK"
    },
    {
        "name": " Maradankalla",
        "countryCode": "LK"
    },
    {
        "name": " Maraiyadithakulam",
        "countryCode": "LK"
    },
    {
        "name": " Maraka",
        "countryCode": "LK"
    },
    {
        "name": " Maramba",
        "countryCode": "LK"
    },
    {
        "name": " Marandagahamula",
        "countryCode": "LK"
    },
    {
        "name": " Marapana",
        "countryCode": "LK"
    },
    {
        "name": " Marassana",
        "countryCode": "LK"
    },
    {
        "name": " Marathamune",
        "countryCode": "LK"
    },
    {
        "name": " Marawa",
        "countryCode": "LK"
    },
    {
        "name": " Marawila",
        "countryCode": "LK"
    },
    {
        "name": " Mariarawa",
        "countryCode": "LK"
    },
    {
        "name": " Marichchikaddi",
        "countryCode": "LK"
    },
    {
        "name": " Maruthodai",
        "countryCode": "LK"
    },
    {
        "name": " Marymount",
        "countryCode": "LK"
    },
    {
        "name": " Maskeliya",
        "countryCode": "LK"
    },
    {
        "name": " Maspanna",
        "countryCode": "LK"
    },
    {
        "name": " Maspotha",
        "countryCode": "LK"
    },
    {
        "name": " Maswela",
        "countryCode": "LK"
    },
    {
        "name": " Matale",
        "countryCode": "LK"
    },
    {
        "name": " Matara",
        "countryCode": "LK"
    },
    {
        "name": " Mathagal",
        "countryCode": "LK"
    },
    {
        "name": " Mathavuvaithakulam",
        "countryCode": "LK"
    },
    {
        "name": " Mattaka",
        "countryCode": "LK"
    },
    {
        "name": " Mattegoda",
        "countryCode": "LK"
    },
    {
        "name": " Matugama",
        "countryCode": "LK"
    },
    {
        "name": " Maturata",
        "countryCode": "LK"
    },
    {
        "name": " Matuwagalagama",
        "countryCode": "LK"
    },
    {
        "name": " Maussagolla",
        "countryCode": "LK"
    },
    {
        "name": " Mawadichenai",
        "countryCode": "LK"
    },
    {
        "name": " Mawanagama",
        "countryCode": "LK"
    },
    {
        "name": " Mawanella",
        "countryCode": "LK"
    },
    {
        "name": " Mawathagama",
        "countryCode": "LK"
    },
    {
        "name": " Mawatura",
        "countryCode": "LK"
    },
    {
        "name": " Medagalature",
        "countryCode": "LK"
    },
    {
        "name": " Medagana",
        "countryCode": "LK"
    },
    {
        "name": " Meda-Keembiya",
        "countryCode": "LK"
    },
    {
        "name": " Medamahanuwara",
        "countryCode": "LK"
    },
    {
        "name": " Medamulana",
        "countryCode": "LK"
    },
    {
        "name": " Medawachchiya",
        "countryCode": "LK"
    },
    {
        "name": " Medawala Harispattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Medawela Udukinda",
        "countryCode": "LK"
    },
    {
        "name": " Medawelagama",
        "countryCode": "LK"
    },
    {
        "name": " Meddekanda",
        "countryCode": "LK"
    },
    {
        "name": " Medirigiriya",
        "countryCode": "LK"
    },
    {
        "name": " Mediripitiya",
        "countryCode": "LK"
    },
    {
        "name": " Medivawa",
        "countryCode": "LK"
    },
    {
        "name": " Meegahajandura",
        "countryCode": "LK"
    },
    {
        "name": " Meegahakiula",
        "countryCode": "LK"
    },
    {
        "name": " Meegahatenna",
        "countryCode": "LK"
    },
    {
        "name": " Meegalawa",
        "countryCode": "LK"
    },
    {
        "name": " Meegama",
        "countryCode": "LK"
    },
    {
        "name": " Meegaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Meegoda",
        "countryCode": "LK"
    },
    {
        "name": " Meepe Junction",
        "countryCode": "LK"
    },
    {
        "name": " Meesalai",
        "countryCode": "LK"
    },
    {
        "name": " Meetalawa",
        "countryCode": "LK"
    },
    {
        "name": " Meetanwala",
        "countryCode": "LK"
    },
    {
        "name": " Meetiyagoda",
        "countryCode": "LK"
    },
    {
        "name": " Meewellawa",
        "countryCode": "LK"
    },
    {
        "name": " Megoda Kalugamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Megodawewa",
        "countryCode": "LK"
    },
    {
        "name": " Melipitiya",
        "countryCode": "LK"
    },
    {
        "name": " Mellawagedara",
        "countryCode": "LK"
    },
    {
        "name": " Melle Street",
        "countryCode": "LK"
    },
    {
        "name": " Melsiripura",
        "countryCode": "LK"
    },
    {
        "name": " Menikdiwela",
        "countryCode": "LK"
    },
    {
        "name": " Menikhinna",
        "countryCode": "LK"
    },
    {
        "name": " Metigahatenna",
        "countryCode": "LK"
    },
    {
        "name": " Metihakka",
        "countryCode": "LK"
    },
    {
        "name": " Metikumbura",
        "countryCode": "LK"
    },
    {
        "name": " Metiyagane",
        "countryCode": "LK"
    },
    {
        "name": " Middeniya",
        "countryCode": "LK"
    },
    {
        "name": " Miella",
        "countryCode": "LK"
    },
    {
        "name": " Migastenna Sabara",
        "countryCode": "LK"
    },
    {
        "name": " Mihintale",
        "countryCode": "LK"
    },
    {
        "name": " Millaniya",
        "countryCode": "LK"
    },
    {
        "name": " Millawana",
        "countryCode": "LK"
    },
    {
        "name": " Millewa",
        "countryCode": "LK"
    },
    {
        "name": " Mimure",
        "countryCode": "LK"
    },
    {
        "name": " Minhettiya",
        "countryCode": "LK"
    },
    {
        "name": " Minigamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Minipe",
        "countryCode": "LK"
    },
    {
        "name": " Minipura Dumbara",
        "countryCode": "LK"
    },
    {
        "name": " Minneriya",
        "countryCode": "LK"
    },
    {
        "name": " Minuwangete",
        "countryCode": "LK"
    },
    {
        "name": " Minuwangoda",
        "countryCode": "LK"
    },
    {
        "name": " Mipanawa",
        "countryCode": "LK"
    },
    {
        "name": " Mipilimana",
        "countryCode": "LK"
    },
    {
        "name": " Mirahawatta",
        "countryCode": "LK"
    },
    {
        "name": " Miraniya Street",
        "countryCode": "LK"
    },
    {
        "name": " Miravodai",
        "countryCode": "LK"
    },
    {
        "name": " Mirigama",
        "countryCode": "LK"
    },
    {
        "name": " Mirihana North",
        "countryCode": "LK"
    },
    {
        "name": " Mirihana",
        "countryCode": "LK"
    },
    {
        "name": " Mirihanagama",
        "countryCode": "LK"
    },
    {
        "name": " Mirissa",
        "countryCode": "LK"
    },
    {
        "name": " Miriswatta",
        "countryCode": "LK"
    },
    {
        "name": " Miriyabedda",
        "countryCode": "LK"
    },
    {
        "name": " Mirusuvil",
        "countryCode": "LK"
    },
    {
        "name": " Mithirigala",
        "countryCode": "LK"
    },
    {
        "name": " Mitipola",
        "countryCode": "LK"
    },
    {
        "name": " Miwanapalana",
        "countryCode": "LK"
    },
    {
        "name": " Miyanakandura",
        "countryCode": "LK"
    },
    {
        "name": " Miyanawita",
        "countryCode": "LK"
    },
    {
        "name": " Modarawana",
        "countryCode": "LK"
    },
    {
        "name": " Molagoda",
        "countryCode": "LK"
    },
    {
        "name": " Molkawa",
        "countryCode": "LK"
    },
    {
        "name": " Monaragala",
        "countryCode": "LK"
    },
    {
        "name": " Monnekulama",
        "countryCode": "LK"
    },
    {
        "name": " Moragahapallama",
        "countryCode": "LK"
    },
    {
        "name": " Moragala Kirillapone",
        "countryCode": "LK"
    },
    {
        "name": " Moragane",
        "countryCode": "LK"
    },
    {
        "name": " Moragollagama",
        "countryCode": "LK"
    },
    {
        "name": " Morahela",
        "countryCode": "LK"
    },
    {
        "name": " Morahenagama",
        "countryCode": "LK"
    },
    {
        "name": " Morakewa",
        "countryCode": "LK"
    },
    {
        "name": " Morapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Morathiha",
        "countryCode": "LK"
    },
    {
        "name": " Moratumulla",
        "countryCode": "LK"
    },
    {
        "name": " Moratuwa",
        "countryCode": "LK"
    },
    {
        "name": " Morawaka",
        "countryCode": "LK"
    },
    {
        "name": " Moretuwegama",
        "countryCode": "LK"
    },
    {
        "name": " Morontota",
        "countryCode": "LK"
    },
    {
        "name": " Morontuduwa",
        "countryCode": "LK"
    },
    {
        "name": " Mount Lavinia",
        "countryCode": "LK"
    },
    {
        "name": " Mudalakkuliya",
        "countryCode": "LK"
    },
    {
        "name": " Muddaragama",
        "countryCode": "LK"
    },
    {
        "name": " Mudungoda",
        "countryCode": "LK"
    },
    {
        "name": " Mugunuwatawana",
        "countryCode": "LK"
    },
    {
        "name": " Muhamalai",
        "countryCode": "LK"
    },
    {
        "name": " Mukkutoduwawa",
        "countryCode": "LK"
    },
    {
        "name": " Mulatiyana Junction",
        "countryCode": "LK"
    },
    {
        "name": " Mulendiyawala",
        "countryCode": "LK"
    },
    {
        "name": " Mulgama",
        "countryCode": "LK"
    },
    {
        "name": " Mulkirigala",
        "countryCode": "LK"
    },
    {
        "name": " Mulkiriyawa",
        "countryCode": "LK"
    },
    {
        "name": " Mullathivu",
        "countryCode": "LK"
    },
    {
        "name": " Mullativu",
        "countryCode": "LK"
    },
    {
        "name": " Mullegama",
        "countryCode": "LK"
    },
    {
        "name": " Mulleriyawa New Town",
        "countryCode": "LK"
    },
    {
        "name": " Mullikulam",
        "countryCode": "LK"
    },
    {
        "name": " Mullipothana",
        "countryCode": "LK"
    },
    {
        "name": " Mullivaikkal",
        "countryCode": "LK"
    },
    {
        "name": " Mulliyan",
        "countryCode": "LK"
    },
    {
        "name": " Mulliyawalai",
        "countryCode": "LK"
    },
    {
        "name": " Munamaldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Mundel",
        "countryCode": "LK"
    },
    {
        "name": " Munwatta",
        "countryCode": "LK"
    },
    {
        "name": " Murakottanchanai",
        "countryCode": "LK"
    },
    {
        "name": " Murasumoddai",
        "countryCode": "LK"
    },
    {
        "name": " Muriyakadawala",
        "countryCode": "LK"
    },
    {
        "name": " Murukandy",
        "countryCode": "LK"
    },
    {
        "name": " Murunkan",
        "countryCode": "LK"
    },
    {
        "name": " Murutalawa",
        "countryCode": "LK"
    },
    {
        "name": " Muruthagahamulla",
        "countryCode": "LK"
    },
    {
        "name": " Muruthenge",
        "countryCode": "LK"
    },
    {
        "name": " Muththaiyankaddukulam",
        "countryCode": "LK"
    },
    {
        "name": " Muttibendiwila",
        "countryCode": "LK"
    },
    {
        "name": " Mutugala",
        "countryCode": "LK"
    },
    {
        "name": " Mutur",
        "countryCode": "LK"
    },
    {
        "name": " Mutwal South",
        "countryCode": "LK"
    },
    {
        "name": " Muwandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Nabadewa",
        "countryCode": "LK"
    },
    {
        "name": " Nachchaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Naddankandal",
        "countryCode": "LK"
    },
    {
        "name": " Nadugala",
        "countryCode": "LK"
    },
    {
        "name": " Nagalagam Street",
        "countryCode": "LK"
    },
    {
        "name": " Nagarkovil",
        "countryCode": "LK"
    },
    {
        "name": " Nagendramadam",
        "countryCode": "LK"
    },
    {
        "name": " Nagoda",
        "countryCode": "LK"
    },
    {
        "name": " Nagollagama",
        "countryCode": "LK"
    },
    {
        "name": " Nagollagoda",
        "countryCode": "LK"
    },
    {
        "name": " Naimana",
        "countryCode": "LK"
    },
    {
        "name": " Nainamadama",
        "countryCode": "LK"
    },
    {
        "name": " Nainamadu",
        "countryCode": "LK"
    },
    {
        "name": " Nainathivu",
        "countryCode": "LK"
    },
    {
        "name": " Nakiyadeniya",
        "countryCode": "LK"
    },
    {
        "name": " Nakkala",
        "countryCode": "LK"
    },
    {
        "name": " Nakkawatta",
        "countryCode": "LK"
    },
    {
        "name": " Nakulugamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Nalanda",
        "countryCode": "LK"
    },
    {
        "name": " Nalladarankattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Nallur",
        "countryCode": "LK"
    },
    {
        "name": " Namaloya",
        "countryCode": "LK"
    },
    {
        "name": " Namalpura",
        "countryCode": "LK"
    },
    {
        "name": " Namunukula",
        "countryCode": "LK"
    },
    {
        "name": " Nanattan",
        "countryCode": "LK"
    },
    {
        "name": " Nannapurawa",
        "countryCode": "LK"
    },
    {
        "name": " Nanuoya",
        "countryCode": "LK"
    },
    {
        "name": " Napawela",
        "countryCode": "LK"
    },
    {
        "name": " Narahenpita",
        "countryCode": "LK"
    },
    {
        "name": " Narammala",
        "countryCode": "LK"
    },
    {
        "name": " Narangala",
        "countryCode": "LK"
    },
    {
        "name": " Narangoda",
        "countryCode": "LK"
    },
    {
        "name": " Naranpanawa",
        "countryCode": "LK"
    },
    {
        "name": " Naranwala",
        "countryCode": "LK"
    },
    {
        "name": " Narawelpita",
        "countryCode": "LK"
    },
    {
        "name": " National Museum of Colombo",
        "countryCode": "LK"
    },
    {
        "name": " Nattandiya",
        "countryCode": "LK"
    },
    {
        "name": " Nattarampotha",
        "countryCode": "LK"
    },
    {
        "name": " Naula",
        "countryCode": "LK"
    },
    {
        "name": " Navagirinagar",
        "countryCode": "LK"
    },
    {
        "name": " Navatkadu",
        "countryCode": "LK"
    },
    {
        "name": " Navithanveli",
        "countryCode": "LK"
    },
    {
        "name": " Nawagattegama",
        "countryCode": "LK"
    },
    {
        "name": " Nawala",
        "countryCode": "LK"
    },
    {
        "name": " Nawalakanda",
        "countryCode": "LK"
    },
    {
        "name": " Nawala-Koswatte",
        "countryCode": "LK"
    },
    {
        "name": " Nawalapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Nawamedagama",
        "countryCode": "LK"
    },
    {
        "name": " Nawana",
        "countryCode": "LK"
    },
    {
        "name": " Nawandagala",
        "countryCode": "LK"
    },
    {
        "name": " Nawasenapura",
        "countryCode": "LK"
    },
    {
        "name": " Nawatalwatta",
        "countryCode": "LK"
    },
    {
        "name": " Nawathispane",
        "countryCode": "LK"
    },
    {
        "name": " Nawattuduwa",
        "countryCode": "LK"
    },
    {
        "name": " Nawinna",
        "countryCode": "LK"
    },
    {
        "name": " Nawinnapinnakanda",
        "countryCode": "LK"
    },
    {
        "name": " Nayapana Janapadaya",
        "countryCode": "LK"
    },
    {
        "name": " Neboda",
        "countryCode": "LK"
    },
    {
        "name": " Nedungamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Nedunkerny",
        "countryCode": "LK"
    },
    {
        "name": " Neelapola",
        "countryCode": "LK"
    },
    {
        "name": " Neervely",
        "countryCode": "LK"
    },
    {
        "name": " Negampaha",
        "countryCode": "LK"
    },
    {
        "name": " Negombo",
        "countryCode": "LK"
    },
    {
        "name": " Nelliya",
        "countryCode": "LK"
    },
    {
        "name": " Nelliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Nelukkulam",
        "countryCode": "LK"
    },
    {
        "name": " Nelumgama",
        "countryCode": "LK"
    },
    {
        "name": " Nelumwewa",
        "countryCode": "LK"
    },
    {
        "name": " Nelundeniya",
        "countryCode": "LK"
    },
    {
        "name": " Neluwa",
        "countryCode": "LK"
    },
    {
        "name": " Neriyakulam",
        "countryCode": "LK"
    },
    {
        "name": " Netolpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Nihiluwa",
        "countryCode": "LK"
    },
    {
        "name": " Nikadalupotha",
        "countryCode": "LK"
    },
    {
        "name": " Nikahetikanda",
        "countryCode": "LK"
    },
    {
        "name": " Nikapotha",
        "countryCode": "LK"
    },
    {
        "name": " Nikaweratiya",
        "countryCode": "LK"
    },
    {
        "name": " Nilaveli",
        "countryCode": "LK"
    },
    {
        "name": " Nildandahinna",
        "countryCode": "LK"
    },
    {
        "name": " Nilgala",
        "countryCode": "LK"
    },
    {
        "name": " Nillambe",
        "countryCode": "LK"
    },
    {
        "name": " Nindana",
        "countryCode": "LK"
    },
    {
        "name": " Nintavur",
        "countryCode": "LK"
    },
    {
        "name": " Niralagama",
        "countryCode": "LK"
    },
    {
        "name": " Nissanka Uyana",
        "countryCode": "LK"
    },
    {
        "name": " Nittambuwa",
        "countryCode": "LK"
    },
    {
        "name": " Nivitigala",
        "countryCode": "LK"
    },
    {
        "name": " Niwandama",
        "countryCode": "LK"
    },
    {
        "name": " Niyadurupola",
        "countryCode": "LK"
    },
    {
        "name": " Nochchimoddai",
        "countryCode": "LK"
    },
    {
        "name": " Nochchiyagama",
        "countryCode": "LK"
    },
    {
        "name": " Noori",
        "countryCode": "LK"
    },
    {
        "name": " Norachcholai",
        "countryCode": "LK"
    },
    {
        "name": " Norwood",
        "countryCode": "LK"
    },
    {
        "name": " Nugagolla",
        "countryCode": "LK"
    },
    {
        "name": " Nugaliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Nugatalawa",
        "countryCode": "LK"
    },
    {
        "name": " Nugawela",
        "countryCode": "LK"
    },
    {
        "name": " Nugegoda",
        "countryCode": "LK"
    },
    {
        "name": " Nuwara Eliya",
        "countryCode": "LK"
    },
    {
        "name": " Nuwaragala",
        "countryCode": "LK"
    },
    {
        "name": " Obbegoda",
        "countryCode": "LK"
    },
    {
        "name": " Obeysekarapura",
        "countryCode": "LK"
    },
    {
        "name": " Oddamavadi",
        "countryCode": "LK"
    },
    {
        "name": " Oddusudan",
        "countryCode": "LK"
    },
    {
        "name": " Ohiya",
        "countryCode": "LK"
    },
    {
        "name": " Okkampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Oluvil",
        "countryCode": "LK"
    },
    {
        "name": " Omalpe",
        "countryCode": "LK"
    },
    {
        "name": " Omanthai",
        "countryCode": "LK"
    },
    {
        "name": " Onegama",
        "countryCode": "LK"
    },
    {
        "name": " Opalgala",
        "countryCode": "LK"
    },
    {
        "name": " Opanayaka",
        "countryCode": "LK"
    },
    {
        "name": " Opatha",
        "countryCode": "LK"
    },
    {
        "name": " Orubendi Siyambalawa",
        "countryCode": "LK"
    },
    {
        "name": " Oruwala",
        "countryCode": "LK"
    },
    {
        "name": " Ovilikanda",
        "countryCode": "LK"
    },
    {
        "name": " Pachchilaipalli",
        "countryCode": "LK"
    },
    {
        "name": " Padagoda",
        "countryCode": "LK"
    },
    {
        "name": " Padalangala",
        "countryCode": "LK"
    },
    {
        "name": " Padavi Maithripura",
        "countryCode": "LK"
    },
    {
        "name": " Padavi Parakramapura",
        "countryCode": "LK"
    },
    {
        "name": " Padavi Sripura",
        "countryCode": "LK"
    },
    {
        "name": " Padavi Sritissapura",
        "countryCode": "LK"
    },
    {
        "name": " Padaviya",
        "countryCode": "LK"
    },
    {
        "name": " Padawkema",
        "countryCode": "LK"
    },
    {
        "name": " Padeniya",
        "countryCode": "LK"
    },
    {
        "name": " Padikaramaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Padiwela",
        "countryCode": "LK"
    },
    {
        "name": " Padiyapelella",
        "countryCode": "LK"
    },
    {
        "name": " Padiyatalawa",
        "countryCode": "LK"
    },
    {
        "name": " Padukka",
        "countryCode": "LK"
    },
    {
        "name": " Pahala Andarawewa",
        "countryCode": "LK"
    },
    {
        "name": " Pahala Halmillewa",
        "countryCode": "LK"
    },
    {
        "name": " Pahala Maragahawe",
        "countryCode": "LK"
    },
    {
        "name": " Pahala Millawa",
        "countryCode": "LK"
    },
    {
        "name": " Pahalagama",
        "countryCode": "LK"
    },
    {
        "name": " Pahalagiribawa",
        "countryCode": "LK"
    },
    {
        "name": " Pahalahewessa",
        "countryCode": "LK"
    },
    {
        "name": " Pahalalanda",
        "countryCode": "LK"
    },
    {
        "name": " Pahalarathkinda",
        "countryCode": "LK"
    },
    {
        "name": " Pahamune",
        "countryCode": "LK"
    },
    {
        "name": " Paiyagala",
        "countryCode": "LK"
    },
    {
        "name": " Palagala",
        "countryCode": "LK"
    },
    {
        "name": " Palamoddai",
        "countryCode": "LK"
    },
    {
        "name": " Palampiddy",
        "countryCode": "LK"
    },
    {
        "name": " Palamunai",
        "countryCode": "LK"
    },
    {
        "name": " Palapathwela",
        "countryCode": "LK"
    },
    {
        "name": " Palatuwa",
        "countryCode": "LK"
    },
    {
        "name": " Palaviya",
        "countryCode": "LK"
    },
    {
        "name": " Pallai",
        "countryCode": "LK"
    },
    {
        "name": " Pallama",
        "countryCode": "LK"
    },
    {
        "name": " Pallavarayankaddu",
        "countryCode": "LK"
    },
    {
        "name": " Pallebedda",
        "countryCode": "LK"
    },
    {
        "name": " Pallebowala",
        "countryCode": "LK"
    },
    {
        "name": " Pallekanda",
        "countryCode": "LK"
    },
    {
        "name": " Pallekiruwa",
        "countryCode": "LK"
    },
    {
        "name": " Pallekotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Pallepola",
        "countryCode": "LK"
    },
    {
        "name": " Pallewela",
        "countryCode": "LK"
    },
    {
        "name": " Pallimunai",
        "countryCode": "LK"
    },
    {
        "name": " Palliwasalturai",
        "countryCode": "LK"
    },
    {
        "name": " Palugasdamana",
        "countryCode": "LK"
    },
    {
        "name": " Palugaswewa",
        "countryCode": "LK"
    },
    {
        "name": " Palukadawala",
        "countryCode": "LK"
    },
    {
        "name": " Pambagolla",
        "countryCode": "LK"
    },
    {
        "name": " Pampaimadu",
        "countryCode": "LK"
    },
    {
        "name": " Pamunugama",
        "countryCode": "LK"
    },
    {
        "name": " Pamunuwa-Patiragoda",
        "countryCode": "LK"
    },
    {
        "name": " Pamunuwatta",
        "countryCode": "LK"
    },
    {
        "name": " Panadaragama",
        "countryCode": "LK"
    },
    {
        "name": " Panadura",
        "countryCode": "LK"
    },
    {
        "name": " Panagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Panagoda Army Camp",
        "countryCode": "LK"
    },
    {
        "name": " Panagoda",
        "countryCode": "LK"
    },
    {
        "name": " Panaliya",
        "countryCode": "LK"
    },
    {
        "name": " Panama",
        "countryCode": "LK"
    },
    {
        "name": " Panamura",
        "countryCode": "LK"
    },
    {
        "name": " Panangala",
        "countryCode": "LK"
    },
    {
        "name": " Panapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Panapola",
        "countryCode": "LK"
    },
    {
        "name": " Panawala",
        "countryCode": "LK"
    },
    {
        "name": " Panchikawatte",
        "countryCode": "LK"
    },
    {
        "name": " Pandaraveli",
        "countryCode": "LK"
    },
    {
        "name": " Pandaterippu",
        "countryCode": "LK"
    },
    {
        "name": " Pandukabayapura",
        "countryCode": "LK"
    },
    {
        "name": " Pandulagama",
        "countryCode": "LK"
    },
    {
        "name": " Pangura",
        "countryCode": "LK"
    },
    {
        "name": " Panichankemi",
        "countryCode": "LK"
    },
    {
        "name": " Panirendawa",
        "countryCode": "LK"
    },
    {
        "name": " Pankudavely",
        "countryCode": "LK"
    },
    {
        "name": " Pankulam",
        "countryCode": "LK"
    },
    {
        "name": " Panliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Pannala",
        "countryCode": "LK"
    },
    {
        "name": " Pannalagama",
        "countryCode": "LK"
    },
    {
        "name": " Pannila",
        "countryCode": "LK"
    },
    {
        "name": " Pannimulla Panagoda",
        "countryCode": "LK"
    },
    {
        "name": " Pannipitiya",
        "countryCode": "LK"
    },
    {
        "name": " Pansiyagama",
        "countryCode": "LK"
    },
    {
        "name": " Panvila",
        "countryCode": "LK"
    },
    {
        "name": " Panwilatenna",
        "countryCode": "LK"
    },
    {
        "name": " Pappamoddai",
        "countryCode": "LK"
    },
    {
        "name": " Paradeka",
        "countryCode": "LK"
    },
    {
        "name": " Paragahakele",
        "countryCode": "LK"
    },
    {
        "name": " Paragala",
        "countryCode": "LK"
    },
    {
        "name": " Paragastota",
        "countryCode": "LK"
    },
    {
        "name": " Paragoda",
        "countryCode": "LK"
    },
    {
        "name": " Paraigama",
        "countryCode": "LK"
    },
    {
        "name": " Parakaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Parakramasamudraya",
        "countryCode": "LK"
    },
    {
        "name": " Parakumpura",
        "countryCode": "LK"
    },
    {
        "name": " Parana Thanayamgoda",
        "countryCode": "LK"
    },
    {
        "name": " Parangiyawadiya",
        "countryCode": "LK"
    },
    {
        "name": " Paranthan",
        "countryCode": "LK"
    },
    {
        "name": " Parapamulla",
        "countryCode": "LK"
    },
    {
        "name": " Parape",
        "countryCode": "LK"
    },
    {
        "name": " Parappankandal",
        "countryCode": "LK"
    },
    {
        "name": " Parappukadanthan",
        "countryCode": "LK"
    },
    {
        "name": " Parasangahawewa",
        "countryCode": "LK"
    },
    {
        "name": " Parliament of Sri Lanka",
        "countryCode": "LK"
    },
    {
        "name": " Pasbage",
        "countryCode": "LK"
    },
    {
        "name": " Pasgoda",
        "countryCode": "LK"
    },
    {
        "name": " Passara",
        "countryCode": "LK"
    },
    {
        "name": " Pasyala",
        "countryCode": "LK"
    },
    {
        "name": " Patana",
        "countryCode": "LK"
    },
    {
        "name": " Pathanewatta",
        "countryCode": "LK"
    },
    {
        "name": " Pattampitiya",
        "countryCode": "LK"
    },
    {
        "name": " Pattitalawa",
        "countryCode": "LK"
    },
    {
        "name": " Pattiya Watta",
        "countryCode": "LK"
    },
    {
        "name": " Pattiyagedara",
        "countryCode": "LK"
    },
    {
        "name": " Pavakkulama Unit 1",
        "countryCode": "LK"
    },
    {
        "name": " Pavakkulama Unit 2",
        "countryCode": "LK"
    },
    {
        "name": " Pebotuwa",
        "countryCode": "LK"
    },
    {
        "name": " Pelagahatenna",
        "countryCode": "LK"
    },
    {
        "name": " Pelanda",
        "countryCode": "LK"
    },
    {
        "name": " Pelatiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Pelawatta",
        "countryCode": "LK"
    },
    {
        "name": " Pelenwatta",
        "countryCode": "LK"
    },
    {
        "name": " Peliyagoda",
        "countryCode": "LK"
    },
    {
        "name": " Pelmadulla",
        "countryCode": "LK"
    },
    {
        "name": " Pemaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Penetiyana",
        "countryCode": "LK"
    },
    {
        "name": " Pepiliyana",
        "countryCode": "LK"
    },
    {
        "name": " Pepiliyawala",
        "countryCode": "LK"
    },
    {
        "name": " Peradeniya",
        "countryCode": "LK"
    },
    {
        "name": " Perakanatta",
        "countryCode": "LK"
    },
    {
        "name": " Perawella",
        "countryCode": "LK"
    },
    {
        "name": " Perimiyankulama",
        "countryCode": "LK"
    },
    {
        "name": " Periya Ulukkulama",
        "countryCode": "LK"
    },
    {
        "name": " Periyakadneluwa",
        "countryCode": "LK"
    },
    {
        "name": " Periyakunchikulam",
        "countryCode": "LK"
    },
    {
        "name": " Periyamadhu",
        "countryCode": "LK"
    },
    {
        "name": " Periyaneelavanai",
        "countryCode": "LK"
    },
    {
        "name": " Periyapandivirichchan",
        "countryCode": "LK"
    },
    {
        "name": " Periyaporativu",
        "countryCode": "LK"
    },
    {
        "name": " Periyapullumalai",
        "countryCode": "LK"
    },
    {
        "name": " Periyathambanai",
        "countryCode": "LK"
    },
    {
        "name": " Pesalai",
        "countryCode": "LK"
    },
    {
        "name": " Pethiyagoda",
        "countryCode": "LK"
    },
    {
        "name": " Pettah Bus Stand",
        "countryCode": "LK"
    },
    {
        "name": " Pihimbiya Ratmale",
        "countryCode": "LK"
    },
    {
        "name": " Pihimbiyagolewa",
        "countryCode": "LK"
    },
    {
        "name": " Pihimbuwa",
        "countryCode": "LK"
    },
    {
        "name": " Pilawala",
        "countryCode": "LK"
    },
    {
        "name": " Pilessa",
        "countryCode": "LK"
    },
    {
        "name": " Pilimatalawa",
        "countryCode": "LK"
    },
    {
        "name": " Piliyandala",
        "countryCode": "LK"
    },
    {
        "name": " Pillaiyaradi",
        "countryCode": "LK"
    },
    {
        "name": " Pimbura",
        "countryCode": "LK"
    },
    {
        "name": " Pimburattewa",
        "countryCode": "LK"
    },
    {
        "name": " Pinnawala",
        "countryCode": "LK"
    },
    {
        "name": " Pinnawala-Waga",
        "countryCode": "LK"
    },
    {
        "name": " Pitabeddara",
        "countryCode": "LK"
    },
    {
        "name": " Pitagaldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Pitakumbura",
        "countryCode": "LK"
    },
    {
        "name": " Pitamaruwa",
        "countryCode": "LK"
    },
    {
        "name": " Pitapola",
        "countryCode": "LK"
    },
    {
        "name": " Pitawala",
        "countryCode": "LK"
    },
    {
        "name": " Pitigala",
        "countryCode": "LK"
    },
    {
        "name": " Pitipana Homagama",
        "countryCode": "LK"
    },
    {
        "name": " Poddala",
        "countryCode": "LK"
    },
    {
        "name": " Poholiyadda",
        "countryCode": "LK"
    },
    {
        "name": " Point Pedro",
        "countryCode": "LK"
    },
    {
        "name": " Pokunuwita",
        "countryCode": "LK"
    },
    {
        "name": " Polgahawela",
        "countryCode": "LK"
    },
    {
        "name": " Polgampola",
        "countryCode": "LK"
    },
    {
        "name": " Polgasowita",
        "countryCode": "LK"
    },
    {
        "name": " Polgolla",
        "countryCode": "LK"
    },
    {
        "name": " Polhengoda",
        "countryCode": "LK"
    },
    {
        "name": " Polonnaruwa",
        "countryCode": "LK"
    },
    {
        "name": " Polpithigama",
        "countryCode": "LK"
    },
    {
        "name": " Polpithimukulana",
        "countryCode": "LK"
    },
    {
        "name": " Polwaga Janapadaya",
        "countryCode": "LK"
    },
    {
        "name": " Poonakary",
        "countryCode": "LK"
    },
    {
        "name": " Poovarasankulam",
        "countryCode": "LK"
    },
    {
        "name": " Porawagama",
        "countryCode": "LK"
    },
    {
        "name": " Poregedara",
        "countryCode": "LK"
    },
    {
        "name": " Poruwedanda",
        "countryCode": "LK"
    },
    {
        "name": " Pothdeniya",
        "countryCode": "LK"
    },
    {
        "name": " Pothuhera",
        "countryCode": "LK"
    },
    {
        "name": " Pothukoladeniya",
        "countryCode": "LK"
    },
    {
        "name": " Pothupitiya",
        "countryCode": "LK"
    },
    {
        "name": " Pothuwatawana",
        "countryCode": "LK"
    },
    {
        "name": " Potkerny",
        "countryCode": "LK"
    },
    {
        "name": " Pottuvil",
        "countryCode": "LK"
    },
    {
        "name": " Pubbiliya",
        "countryCode": "LK"
    },
    {
        "name": " Pubbogama",
        "countryCode": "LK"
    },
    {
        "name": " Pugoda",
        "countryCode": "LK"
    },
    {
        "name": " Puhulpola",
        "countryCode": "LK"
    },
    {
        "name": " Puhulwella",
        "countryCode": "LK"
    },
    {
        "name": " Pujapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Pulastigama",
        "countryCode": "LK"
    },
    {
        "name": " Puliyampokkanai",
        "countryCode": "LK"
    },
    {
        "name": " Puliyankulam",
        "countryCode": "LK"
    },
    {
        "name": " Pulmoddai",
        "countryCode": "LK"
    },
    {
        "name": " Puloly",
        "countryCode": "LK"
    },
    {
        "name": " Punakari-Nallur",
        "countryCode": "LK"
    },
    {
        "name": " Punanai",
        "countryCode": "LK"
    },
    {
        "name": " Pundaluoya",
        "countryCode": "LK"
    },
    {
        "name": " Punewa",
        "countryCode": "LK"
    },
    {
        "name": " Pungudutivu",
        "countryCode": "LK"
    },
    {
        "name": " Pupuressa",
        "countryCode": "LK"
    },
    {
        "name": " Pussellawa",
        "countryCode": "LK"
    },
    {
        "name": " Puswelitenna",
        "countryCode": "LK"
    },
    {
        "name": " Puthukkudiyiruppu",
        "countryCode": "LK"
    },
    {
        "name": " Puthukudiyiruppu",
        "countryCode": "LK"
    },
    {
        "name": " Puthuvedduvan",
        "countryCode": "LK"
    },
    {
        "name": " Puthuveli",
        "countryCode": "LK"
    },
    {
        "name": " Puttalam Cement Factory",
        "countryCode": "LK"
    },
    {
        "name": " Puttalam",
        "countryCode": "LK"
    },
    {
        "name": " Puttur",
        "countryCode": "LK"
    },
    {
        "name": " Putuhapuwa",
        "countryCode": "LK"
    },
    {
        "name": " Puwakpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Radawadunna",
        "countryCode": "LK"
    },
    {
        "name": " Radawana",
        "countryCode": "LK"
    },
    {
        "name": " Radawela",
        "countryCode": "LK"
    },
    {
        "name": " Raddolugama",
        "countryCode": "LK"
    },
    {
        "name": " Ragama",
        "countryCode": "LK"
    },
    {
        "name": " Rajagalatenna",
        "countryCode": "LK"
    },
    {
        "name": " Rajagiriya",
        "countryCode": "LK"
    },
    {
        "name": " Rajakadaluwa",
        "countryCode": "LK"
    },
    {
        "name": " Rajanganaya",
        "countryCode": "LK"
    },
    {
        "name": " Rajawaka",
        "countryCode": "LK"
    },
    {
        "name": " Rajawella",
        "countryCode": "LK"
    },
    {
        "name": " Rakwana",
        "countryCode": "LK"
    },
    {
        "name": " Ramanathapuram",
        "countryCode": "LK"
    },
    {
        "name": " Rambewa",
        "countryCode": "LK"
    },
    {
        "name": " Ramboda",
        "countryCode": "LK"
    },
    {
        "name": " Rambukkana",
        "countryCode": "LK"
    },
    {
        "name": " Rambukpitiya",
        "countryCode": "LK"
    },
    {
        "name": " Rambukwella",
        "countryCode": "LK"
    },
    {
        "name": " Rammalawarapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Rampathwila",
        "countryCode": "LK"
    },
    {
        "name": " Ranakeliya",
        "countryCode": "LK"
    },
    {
        "name": " Ranala",
        "countryCode": "LK"
    },
    {
        "name": " Ranamuregama",
        "countryCode": "LK"
    },
    {
        "name": " Randeniya",
        "countryCode": "LK"
    },
    {
        "name": " Rangala",
        "countryCode": "LK"
    },
    {
        "name": " Ranmuduwewa",
        "countryCode": "LK"
    },
    {
        "name": " Ranna",
        "countryCode": "LK"
    },
    {
        "name": " Ranorawa",
        "countryCode": "LK"
    },
    {
        "name": " Ransegoda",
        "countryCode": "LK"
    },
    {
        "name": " Rantembe",
        "countryCode": "LK"
    },
    {
        "name": " Rantotuwila",
        "countryCode": "LK"
    },
    {
        "name": " Ranwala",
        "countryCode": "LK"
    },
    {
        "name": " Rassagala",
        "countryCode": "LK"
    },
    {
        "name": " Rathgama",
        "countryCode": "LK"
    },
    {
        "name": " Rathkarawwa",
        "countryCode": "LK"
    },
    {
        "name": " Rathmalgahawewa",
        "countryCode": "LK"
    },
    {
        "name": " Rathukohodigala",
        "countryCode": "LK"
    },
    {
        "name": " Ratmalana North",
        "countryCode": "LK"
    },
    {
        "name": " Ratmalana",
        "countryCode": "LK"
    },
    {
        "name": " Ratmale",
        "countryCode": "LK"
    },
    {
        "name": " Ratmalwala",
        "countryCode": "LK"
    },
    {
        "name": " Ratna Hangamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Ratnapura",
        "countryCode": "LK"
    },
    {
        "name": " Rattota",
        "countryCode": "LK"
    },
    {
        "name": " Rawathawatta",
        "countryCode": "LK"
    },
    {
        "name": " Remunagoda",
        "countryCode": "LK"
    },
    {
        "name": " Ridibendiella",
        "countryCode": "LK"
    },
    {
        "name": " Ridigama",
        "countryCode": "LK"
    },
    {
        "name": " Ridimaliyadda",
        "countryCode": "LK"
    },
    {
        "name": " Rifel Street",
        "countryCode": "LK"
    },
    {
        "name": " Rikillagaskada",
        "countryCode": "LK"
    },
    {
        "name": " Rilpola",
        "countryCode": "LK"
    },
    {
        "name": " Rottawewa",
        "countryCode": "LK"
    },
    {
        "name": " Rotumba",
        "countryCode": "LK"
    },
    {
        "name": " Rozella",
        "countryCode": "LK"
    },
    {
        "name": " Ruggahawila",
        "countryCode": "LK"
    },
    {
        "name": " Ruhunu Ridiyagama",
        "countryCode": "LK"
    },
    {
        "name": " Rukmale",
        "countryCode": "LK"
    },
    {
        "name": " Rukmale-Pannipitiya",
        "countryCode": "LK"
    },
    {
        "name": " Rupaha",
        "countryCode": "LK"
    },
    {
        "name": " Ruwalwela",
        "countryCode": "LK"
    },
    {
        "name": " Ruwaneliya",
        "countryCode": "LK"
    },
    {
        "name": " Ruwanwella",
        "countryCode": "LK"
    },
    {
        "name": " Sainthamaruthu",
        "countryCode": "LK"
    },
    {
        "name": " Saliya Asokapura",
        "countryCode": "LK"
    },
    {
        "name": " Saliyapura",
        "countryCode": "LK"
    },
    {
        "name": " Saliyawewa Junction",
        "countryCode": "LK"
    },
    {
        "name": " Samanalawewa",
        "countryCode": "LK"
    },
    {
        "name": " Samanthurai",
        "countryCode": "LK"
    },
    {
        "name": " Sampaltivu",
        "countryCode": "LK"
    },
    {
        "name": " Sampoor",
        "countryCode": "LK"
    },
    {
        "name": " Sandalankawa",
        "countryCode": "LK"
    },
    {
        "name": " Sandilipay",
        "countryCode": "LK"
    },
    {
        "name": " Sangarajapura",
        "countryCode": "LK"
    },
    {
        "name": " Sangarathai",
        "countryCode": "LK"
    },
    {
        "name": " Santhipura",
        "countryCode": "LK"
    },
    {
        "name": " Sarasavipaya",
        "countryCode": "LK"
    },
    {
        "name": " Sathirikulankulam",
        "countryCode": "LK"
    },
    {
        "name": " Seaforth Colony",
        "countryCode": "LK"
    },
    {
        "name": " Seeduwa",
        "countryCode": "LK"
    },
    {
        "name": " Seeppukulama",
        "countryCode": "LK"
    },
    {
        "name": " Selagama",
        "countryCode": "LK"
    },
    {
        "name": " Sella Kataragama",
        "countryCode": "LK"
    },
    {
        "name": " Senapura",
        "countryCode": "LK"
    },
    {
        "name": " Senarathwela",
        "countryCode": "LK"
    },
    {
        "name": " Serankada",
        "countryCode": "LK"
    },
    {
        "name": " Serukele",
        "countryCode": "LK"
    },
    {
        "name": " Serunuwara",
        "countryCode": "LK"
    },
    {
        "name": " Seruwila",
        "countryCode": "LK"
    },
    {
        "name": " Sethsiripaya",
        "countryCode": "LK"
    },
    {
        "name": " Sevanapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Sewanagala",
        "countryCode": "LK"
    },
    {
        "name": " Siddamulla",
        "countryCode": "LK"
    },
    {
        "name": " Sigiriya",
        "countryCode": "LK"
    },
    {
        "name": " Silmiyapura",
        "countryCode": "LK"
    },
    {
        "name": " Sinhagama",
        "countryCode": "LK"
    },
    {
        "name": " Sinnasippikulam",
        "countryCode": "LK"
    },
    {
        "name": " Sirajnagar",
        "countryCode": "LK"
    },
    {
        "name": " Sirambiadiya",
        "countryCode": "LK"
    },
    {
        "name": " Sirimal Uyana",
        "countryCode": "LK"
    },
    {
        "name": " Sirimalgoda",
        "countryCode": "LK"
    },
    {
        "name": " Siripura",
        "countryCode": "LK"
    },
    {
        "name": " Sirisetagama",
        "countryCode": "LK"
    },
    {
        "name": " Sithankerny",
        "countryCode": "LK"
    },
    {
        "name": " Sivalakulama",
        "countryCode": "LK"
    },
    {
        "name": " Sivanagar",
        "countryCode": "LK"
    },
    {
        "name": " Sivankovilady",
        "countryCode": "LK"
    },
    {
        "name": " Sivapuram",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalagashene",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalagoda",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalagune",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalanduwa",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalangamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalape",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalawewa",
        "countryCode": "LK"
    },
    {
        "name": " Siyambalewa",
        "countryCode": "LK"
    },
    {
        "name": " Skanthapuram",
        "countryCode": "LK"
    },
    {
        "name": " Solepura",
        "countryCode": "LK"
    },
    {
        "name": " Solewewa",
        "countryCode": "LK"
    },
    {
        "name": " Somapura",
        "countryCode": "LK"
    },
    {
        "name": " Sooriyakaddaikadhu",
        "countryCode": "LK"
    },
    {
        "name": " Sooriyawewa Town",
        "countryCode": "LK"
    },
    {
        "name": " Sorabora",
        "countryCode": "LK"
    },
    {
        "name": " Soragune",
        "countryCode": "LK"
    },
    {
        "name": " Soranathota",
        "countryCode": "LK"
    },
    {
        "name": " Soranpattu",
        "countryCode": "LK"
    },
    {
        "name": " Spring Valley",
        "countryCode": "LK"
    },
    {
        "name": " Sravasthipura",
        "countryCode": "LK"
    },
    {
        "name": " Sri Jayawardenepura",
        "countryCode": "LK"
    },
    {
        "name": " Sri Palabaddala",
        "countryCode": "LK"
    },
    {
        "name": " Sri Perakumpura",
        "countryCode": "LK"
    },
    {
        "name": " St. Anthony's",
        "countryCode": "LK"
    },
    {
        "name": " Sudagala",
        "countryCode": "LK"
    },
    {
        "name": " Sudanapura",
        "countryCode": "LK"
    },
    {
        "name": " Sultanagoda",
        "countryCode": "LK"
    },
    {
        "name": " Sunandapura",
        "countryCode": "LK"
    },
    {
        "name": " Sungavila",
        "countryCode": "LK"
    },
    {
        "name": " Suriara",
        "countryCode": "LK"
    },
    {
        "name": " Suwarapola",
        "countryCode": "LK"
    },
    {
        "name": " Tabbowa",
        "countryCode": "LK"
    },
    {
        "name": " Talagampola",
        "countryCode": "LK"
    },
    {
        "name": " Talagoda Junction",
        "countryCode": "LK"
    },
    {
        "name": " Talahena",
        "countryCode": "LK"
    },
    {
        "name": " Talakiriyagama",
        "countryCode": "LK"
    },
    {
        "name": " Talangama North",
        "countryCode": "LK"
    },
    {
        "name": " Talangama South",
        "countryCode": "LK"
    },
    {
        "name": " Talatuoya",
        "countryCode": "LK"
    },
    {
        "name": " Talawa",
        "countryCode": "LK"
    },
    {
        "name": " Talawakele",
        "countryCode": "LK"
    },
    {
        "name": " Talawattegedara",
        "countryCode": "LK"
    },
    {
        "name": " Talawatugoda",
        "countryCode": "LK"
    },
    {
        "name": " Talawila Church",
        "countryCode": "LK"
    },
    {
        "name": " Taldena",
        "countryCode": "LK"
    },
    {
        "name": " Talgaspe",
        "countryCode": "LK"
    },
    {
        "name": " Talgaspitiya",
        "countryCode": "LK"
    },
    {
        "name": " Talgaswela",
        "countryCode": "LK"
    },
    {
        "name": " Talpe",
        "countryCode": "LK"
    },
    {
        "name": " Talpotha",
        "countryCode": "LK"
    },
    {
        "name": " Tamankaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Tambala",
        "countryCode": "LK"
    },
    {
        "name": " Tambutta",
        "countryCode": "LK"
    },
    {
        "name": " Tambuttegama",
        "countryCode": "LK"
    },
    {
        "name": " Tammennawa",
        "countryCode": "LK"
    },
    {
        "name": " Tampalakamam",
        "countryCode": "LK"
    },
    {
        "name": " Tangalla",
        "countryCode": "LK"
    },
    {
        "name": " Tantirimale",
        "countryCode": "LK"
    },
    {
        "name": " Tawalama",
        "countryCode": "LK"
    },
    {
        "name": " Tawalantenna",
        "countryCode": "LK"
    },
    {
        "name": " Tebuwana",
        "countryCode": "LK"
    },
    {
        "name": " Teldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Telhiriyawa",
        "countryCode": "LK"
    },
    {
        "name": " Teligama",
        "countryCode": "LK"
    },
    {
        "name": " Telijjawila",
        "countryCode": "LK"
    },
    {
        "name": " Tempitiya",
        "countryCode": "LK"
    },
    {
        "name": " Tennekumbura",
        "countryCode": "LK"
    },
    {
        "name": " Tennepanguwa",
        "countryCode": "LK"
    },
    {
        "name": " Teripeha",
        "countryCode": "LK"
    },
    {
        "name": " Thalahitimulla",
        "countryCode": "LK"
    },
    {
        "name": " Thalaimannar Pier",
        "countryCode": "LK"
    },
    {
        "name": " Thalaimannar West",
        "countryCode": "LK"
    },
    {
        "name": " Thalaimannar",
        "countryCode": "LK"
    },
    {
        "name": " Thalaiyadi",
        "countryCode": "LK"
    },
    {
        "name": " Thalakolahinna",
        "countryCode": "LK"
    },
    {
        "name": " Thalakolawewa",
        "countryCode": "LK"
    },
    {
        "name": " Thalvupadu",
        "countryCode": "LK"
    },
    {
        "name": " Thalwita",
        "countryCode": "LK"
    },
    {
        "name": " Thambagalla",
        "countryCode": "LK"
    },
    {
        "name": " Thambiluvil",
        "countryCode": "LK"
    },
    {
        "name": " Thanamalwila",
        "countryCode": "LK"
    },
    {
        "name": " Thandikulam",
        "countryCode": "LK"
    },
    {
        "name": " Thanjantenna",
        "countryCode": "LK"
    },
    {
        "name": " Thannamunai",
        "countryCode": "LK"
    },
    {
        "name": " Tharana Udawela",
        "countryCode": "LK"
    },
    {
        "name": " Tharapuram",
        "countryCode": "LK"
    },
    {
        "name": " Tharmapuram",
        "countryCode": "LK"
    },
    {
        "name": " Thellippallai",
        "countryCode": "LK"
    },
    {
        "name": " Theppanawa",
        "countryCode": "LK"
    },
    {
        "name": " Thettativu",
        "countryCode": "LK"
    },
    {
        "name": " Thihagoda",
        "countryCode": "LK"
    },
    {
        "name": " Thikkodai",
        "countryCode": "LK"
    },
    {
        "name": " Thimbirigaskatuwa",
        "countryCode": "LK"
    },
    {
        "name": " Thimbiriyawa",
        "countryCode": "LK"
    },
    {
        "name": " Thiruketheeswaram",
        "countryCode": "LK"
    },
    {
        "name": " Thirunagar",
        "countryCode": "LK"
    },
    {
        "name": " Thirunelvely",
        "countryCode": "LK"
    },
    {
        "name": " Thirupalugamam",
        "countryCode": "LK"
    },
    {
        "name": " Thiruvaiaru",
        "countryCode": "LK"
    },
    {
        "name": " Tholangamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Thondamanaru",
        "countryCode": "LK"
    },
    {
        "name": " Thorayaya",
        "countryCode": "LK"
    },
    {
        "name": " Thotawella",
        "countryCode": "LK"
    },
    {
        "name": " Thunkama",
        "countryCode": "LK"
    },
    {
        "name": " Thunukkai",
        "countryCode": "LK"
    },
    {
        "name": " Thuraineelavanai",
        "countryCode": "LK"
    },
    {
        "name": " Timbirigaspitiya",
        "countryCode": "LK"
    },
    {
        "name": " Tiranagama",
        "countryCode": "LK"
    },
    {
        "name": " Tirappane",
        "countryCode": "LK"
    },
    {
        "name": " Tiriyayi",
        "countryCode": "LK"
    },
    {
        "name": " Tirukovil",
        "countryCode": "LK"
    },
    {
        "name": " Tisogama",
        "countryCode": "LK"
    },
    {
        "name": " Tissamaharama",
        "countryCode": "LK"
    },
    {
        "name": " Tittagonewa",
        "countryCode": "LK"
    },
    {
        "name": " Tittapattara",
        "countryCode": "LK"
    },
    {
        "name": " Toduwawa",
        "countryCode": "LK"
    },
    {
        "name": " Toppur",
        "countryCode": "LK"
    },
    {
        "name": " Torington Square",
        "countryCode": "LK"
    },
    {
        "name": " Trincomalee",
        "countryCode": "LK"
    },
    {
        "name": " Tulhiriya",
        "countryCode": "LK"
    },
    {
        "name": " Tummodara",
        "countryCode": "LK"
    },
    {
        "name": " Tuntota",
        "countryCode": "LK"
    },
    {
        "name": " Tuttiripitigama",
        "countryCode": "LK"
    },
    {
        "name": " Uda Gomadiya",
        "countryCode": "LK"
    },
    {
        "name": " Uda Peradeniya",
        "countryCode": "LK"
    },
    {
        "name": " Udagaldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Udaha Hawupe",
        "countryCode": "LK"
    },
    {
        "name": " Udahamulla",
        "countryCode": "LK"
    },
    {
        "name": " Udahentenna",
        "countryCode": "LK"
    },
    {
        "name": " Udahingulwala",
        "countryCode": "LK"
    },
    {
        "name": " Udakarawita",
        "countryCode": "LK"
    },
    {
        "name": " Udalamatta",
        "countryCode": "LK"
    },
    {
        "name": " Udamadura",
        "countryCode": "LK"
    },
    {
        "name": " Udamattala",
        "countryCode": "LK"
    },
    {
        "name": " Udaniriella",
        "countryCode": "LK"
    },
    {
        "name": " Udapotha",
        "countryCode": "LK"
    },
    {
        "name": " Udappuwa",
        "countryCode": "LK"
    },
    {
        "name": " Udapussallawa",
        "countryCode": "LK"
    },
    {
        "name": " Udasgiriya",
        "countryCode": "LK"
    },
    {
        "name": " Udatalawinna",
        "countryCode": "LK"
    },
    {
        "name": " Udatenna",
        "countryCode": "LK"
    },
    {
        "name": " Udathuthiripitiya",
        "countryCode": "LK"
    },
    {
        "name": " Udawalawe",
        "countryCode": "LK"
    },
    {
        "name": " Udawatta",
        "countryCode": "LK"
    },
    {
        "name": " Udayarkaddu",
        "countryCode": "LK"
    },
    {
        "name": " Udispattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Udubaddawa",
        "countryCode": "LK"
    },
    {
        "name": " Ududumbara",
        "countryCode": "LK"
    },
    {
        "name": " Udugama",
        "countryCode": "LK"
    },
    {
        "name": " Udugamkanda",
        "countryCode": "LK"
    },
    {
        "name": " Udugampola",
        "countryCode": "LK"
    },
    {
        "name": " Uduhawara",
        "countryCode": "LK"
    },
    {
        "name": " Udumulla",
        "countryCode": "LK"
    },
    {
        "name": " Udunuwara Colony",
        "countryCode": "LK"
    },
    {
        "name": " Uduwa",
        "countryCode": "LK"
    },
    {
        "name": " Uduwahinna",
        "countryCode": "LK"
    },
    {
        "name": " Uduwara",
        "countryCode": "LK"
    },
    {
        "name": " Uduwela",
        "countryCode": "LK"
    },
    {
        "name": " Uggalboda",
        "countryCode": "LK"
    },
    {
        "name": " Uhana",
        "countryCode": "LK"
    },
    {
        "name": " Uhumiya",
        "countryCode": "LK"
    },
    {
        "name": " Ukuwela",
        "countryCode": "LK"
    },
    {
        "name": " Ulapane",
        "countryCode": "LK"
    },
    {
        "name": " Ullinduwawa",
        "countryCode": "LK"
    },
    {
        "name": " Ulpotha Pallekele",
        "countryCode": "LK"
    },
    {
        "name": " Ulpothagama",
        "countryCode": "LK"
    },
    {
        "name": " Uluvitike",
        "countryCode": "LK"
    },
    {
        "name": " Unagalavehera",
        "countryCode": "LK"
    },
    {
        "name": " Unawatuna",
        "countryCode": "LK"
    },
    {
        "name": " Unawitiya",
        "countryCode": "LK"
    },
    {
        "name": " Undugoda",
        "countryCode": "LK"
    },
    {
        "name": " University of Colombo",
        "countryCode": "LK"
    },
    {
        "name": " Unnichchai",
        "countryCode": "LK"
    },
    {
        "name": " Unuwinna",
        "countryCode": "LK"
    },
    {
        "name": " Upuldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Uragaha",
        "countryCode": "LK"
    },
    {
        "name": " Uragasmanhandiya",
        "countryCode": "LK"
    },
    {
        "name": " Uraniya",
        "countryCode": "LK"
    },
    {
        "name": " Urapola",
        "countryCode": "LK"
    },
    {
        "name": " Uridyawa",
        "countryCode": "LK"
    },
    {
        "name": " Urubokka",
        "countryCode": "LK"
    },
    {
        "name": " Urugamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Urumpirai",
        "countryCode": "LK"
    },
    {
        "name": " Urumutta",
        "countryCode": "LK"
    },
    {
        "name": " Uruthirapuram",
        "countryCode": "LK"
    },
    {
        "name": " Usgala Siyabmalangamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Ussapitiya",
        "countryCode": "LK"
    },
    {
        "name": " Uswetakeiyawa",
        "countryCode": "LK"
    },
    {
        "name": " Uswewa",
        "countryCode": "LK"
    },
    {
        "name": " Uttimaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Utumgama",
        "countryCode": "LK"
    },
    {
        "name": " Uva Deegalla",
        "countryCode": "LK"
    },
    {
        "name": " Uva Gangodagama",
        "countryCode": "LK"
    },
    {
        "name": " Uva Karandagolla",
        "countryCode": "LK"
    },
    {
        "name": " Uva Kudaoya",
        "countryCode": "LK"
    },
    {
        "name": " Uva Mawelagama",
        "countryCode": "LK"
    },
    {
        "name": " Uva Pelwatta",
        "countryCode": "LK"
    },
    {
        "name": " Uva Tenna",
        "countryCode": "LK"
    },
    {
        "name": " Uva Tissapura",
        "countryCode": "LK"
    },
    {
        "name": " Uva Uduwara",
        "countryCode": "LK"
    },
    {
        "name": " Uvaparanagama",
        "countryCode": "LK"
    },
    {
        "name": " Uyilankulam",
        "countryCode": "LK"
    },
    {
        "name": " Uyirtharasankulam",
        "countryCode": "LK"
    },
    {
        "name": " Vaarikkuttiyoor",
        "countryCode": "LK"
    },
    {
        "name": " Vaddakachchi",
        "countryCode": "LK"
    },
    {
        "name": " Vaddakandal",
        "countryCode": "LK"
    },
    {
        "name": " Vaddukoddai",
        "countryCode": "LK"
    },
    {
        "name": " Vakaneri",
        "countryCode": "LK"
    },
    {
        "name": " Vakarai",
        "countryCode": "LK"
    },
    {
        "name": " Valaichenai",
        "countryCode": "LK"
    },
    {
        "name": " Valvettithurai",
        "countryCode": "LK"
    },
    {
        "name": " Vanathawilluwa",
        "countryCode": "LK"
    },
    {
        "name": " Vankalai",
        "countryCode": "LK"
    },
    {
        "name": " Vannarponnai",
        "countryCode": "LK"
    },
    {
        "name": " Vannerikkulam",
        "countryCode": "LK"
    },
    {
        "name": " Vantharumoolai",
        "countryCode": "LK"
    },
    {
        "name": " Varany",
        "countryCode": "LK"
    },
    {
        "name": " Vasavilan",
        "countryCode": "LK"
    },
    {
        "name": " Vavunakkulam",
        "countryCode": "LK"
    },
    {
        "name": " Vavuniya",
        "countryCode": "LK"
    },
    {
        "name": " Veddagala",
        "countryCode": "LK"
    },
    {
        "name": " Velamboda",
        "countryCode": "LK"
    },
    {
        "name": " Velanai",
        "countryCode": "LK"
    },
    {
        "name": " Vellamanal",
        "countryCode": "LK"
    },
    {
        "name": " Vellankulam",
        "countryCode": "LK"
    },
    {
        "name": " Vellavely",
        "countryCode": "LK"
    },
    {
        "name": " Veppankulam",
        "countryCode": "LK"
    },
    {
        "name": " Veravil",
        "countryCode": "LK"
    },
    {
        "name": " Veyangalla",
        "countryCode": "LK"
    },
    {
        "name": " Veyangoda",
        "countryCode": "LK"
    },
    {
        "name": " Vidataltivu",
        "countryCode": "LK"
    },
    {
        "name": " Vidyodaya University",
        "countryCode": "LK"
    },
    {
        "name": " Viharahena",
        "countryCode": "LK"
    },
    {
        "name": " Viharapalugama",
        "countryCode": "LK"
    },
    {
        "name": " Vijeriya",
        "countryCode": "LK"
    },
    {
        "name": " Vijithapura",
        "countryCode": "LK"
    },
    {
        "name": " Vinayagapuram",
        "countryCode": "LK"
    },
    {
        "name": " Visvamadukulam",
        "countryCode": "LK"
    },
    {
        "name": " Vitharandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Wadakada",
        "countryCode": "LK"
    },
    {
        "name": " Wadduwa",
        "countryCode": "LK"
    },
    {
        "name": " Wadinagala",
        "countryCode": "LK"
    },
    {
        "name": " Wadumunnegedara",
        "countryCode": "LK"
    },
    {
        "name": " Waga",
        "countryCode": "LK"
    },
    {
        "name": " Wahacotte",
        "countryCode": "LK"
    },
    {
        "name": " Wahakula",
        "countryCode": "LK"
    },
    {
        "name": " Wahalkada",
        "countryCode": "LK"
    },
    {
        "name": " Wahamalgollewa",
        "countryCode": "LK"
    },
    {
        "name": " Waharaka",
        "countryCode": "LK"
    },
    {
        "name": " Waikkal",
        "countryCode": "LK"
    },
    {
        "name": " Wakwella",
        "countryCode": "LK"
    },
    {
        "name": " Walagambahuwa",
        "countryCode": "LK"
    },
    {
        "name": " Walagedara",
        "countryCode": "LK"
    },
    {
        "name": " Walahanduwa",
        "countryCode": "LK"
    },
    {
        "name": " Walahaviddawewa",
        "countryCode": "LK"
    },
    {
        "name": " Walakanda",
        "countryCode": "LK"
    },
    {
        "name": " Walakumburumulla",
        "countryCode": "LK"
    },
    {
        "name": " Walallawita",
        "countryCode": "LK"
    },
    {
        "name": " Walapane",
        "countryCode": "LK"
    },
    {
        "name": " Walasgala",
        "countryCode": "LK"
    },
    {
        "name": " Walasmulla",
        "countryCode": "LK"
    },
    {
        "name": " Walawela",
        "countryCode": "LK"
    },
    {
        "name": " Waleboda",
        "countryCode": "LK"
    },
    {
        "name": " Walgammulla",
        "countryCode": "LK"
    },
    {
        "name": " Walpita",
        "countryCode": "LK"
    },
    {
        "name": " Walpola (WP)",
        "countryCode": "LK"
    },
    {
        "name": " Wanagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Wanaluwewa",
        "countryCode": "LK"
    },
    {
        "name": " Wanathamulla",
        "countryCode": "LK"
    },
    {
        "name": " Wanchawela",
        "countryCode": "LK"
    },
    {
        "name": " Wanduramba",
        "countryCode": "LK"
    },
    {
        "name": " Wanela",
        "countryCode": "LK"
    },
    {
        "name": " Wannigama",
        "countryCode": "LK"
    },
    {
        "name": " Wannikudawewa",
        "countryCode": "LK"
    },
    {
        "name": " Wannilhalagama",
        "countryCode": "LK"
    },
    {
        "name": " Wannirasnayakapura",
        "countryCode": "LK"
    },
    {
        "name": " Warakapola",
        "countryCode": "LK"
    },
    {
        "name": " Waralla",
        "countryCode": "LK"
    },
    {
        "name": " Warawewa",
        "countryCode": "LK"
    },
    {
        "name": " Wariyapola",
        "countryCode": "LK"
    },
    {
        "name": " Warukandeniya",
        "countryCode": "LK"
    },
    {
        "name": " Warunagama",
        "countryCode": "LK"
    },
    {
        "name": " Wasala Road",
        "countryCode": "LK"
    },
    {
        "name": " Waskaduwa",
        "countryCode": "LK"
    },
    {
        "name": " Watagoda Harispattuwa",
        "countryCode": "LK"
    },
    {
        "name": " Watagoda",
        "countryCode": "LK"
    },
    {
        "name": " Watapotha",
        "countryCode": "LK"
    },
    {
        "name": " Watareka",
        "countryCode": "LK"
    },
    {
        "name": " Watawala",
        "countryCode": "LK"
    },
    {
        "name": " Wathurugama",
        "countryCode": "LK"
    },
    {
        "name": " Watinapaha",
        "countryCode": "LK"
    },
    {
        "name": " Wattala",
        "countryCode": "LK"
    },
    {
        "name": " Wattala",
        "countryCode": "LK"
    },
    {
        "name": " Wattappola",
        "countryCode": "LK"
    },
    {
        "name": " Wattegama",
        "countryCode": "LK"
    },
    {
        "name": " Watugahamulla",
        "countryCode": "LK"
    },
    {
        "name": " Watugedara",
        "countryCode": "LK"
    },
    {
        "name": " Watura",
        "countryCode": "LK"
    },
    {
        "name": " Waturawa",
        "countryCode": "LK"
    },
    {
        "name": " Watuwatta",
        "countryCode": "LK"
    },
    {
        "name": " Weboda",
        "countryCode": "LK"
    },
    {
        "name": " Wedikumbura",
        "countryCode": "LK"
    },
    {
        "name": " Weeoya",
        "countryCode": "LK"
    },
    {
        "name": " Weeragoda",
        "countryCode": "LK"
    },
    {
        "name": " Weeraketiya",
        "countryCode": "LK"
    },
    {
        "name": " Weerapokuna",
        "countryCode": "LK"
    },
    {
        "name": " Weerawila NewTown",
        "countryCode": "LK"
    },
    {
        "name": " Weerawila",
        "countryCode": "LK"
    },
    {
        "name": " Wegalla",
        "countryCode": "LK"
    },
    {
        "name": " Wegowwa",
        "countryCode": "LK"
    },
    {
        "name": " Weherayaya Handapanagala",
        "countryCode": "LK"
    },
    {
        "name": " Wehigala",
        "countryCode": "LK"
    },
    {
        "name": " Weihena",
        "countryCode": "LK"
    },
    {
        "name": " Wekandawela",
        "countryCode": "LK"
    },
    {
        "name": " Welangahawatte",
        "countryCode": "LK"
    },
    {
        "name": " Welawa Juncton",
        "countryCode": "LK"
    },
    {
        "name": " Weligalla",
        "countryCode": "LK"
    },
    {
        "name": " Weligama",
        "countryCode": "LK"
    },
    {
        "name": " Weligampola",
        "countryCode": "LK"
    },
    {
        "name": " Weligatta",
        "countryCode": "LK"
    },
    {
        "name": " Weligepola",
        "countryCode": "LK"
    },
    {
        "name": " Welihelatenna",
        "countryCode": "LK"
    },
    {
        "name": " Welikanda",
        "countryCode": "LK"
    },
    {
        "name": " Welimada",
        "countryCode": "LK"
    },
    {
        "name": " Welimuwapotana",
        "countryCode": "LK"
    },
    {
        "name": " Welioya Project",
        "countryCode": "LK"
    },
    {
        "name": " Welioya",
        "countryCode": "LK"
    },
    {
        "name": " Welipathayaya",
        "countryCode": "LK"
    },
    {
        "name": " Welipenna",
        "countryCode": "LK"
    },
    {
        "name": " Welipennagahamulla",
        "countryCode": "LK"
    },
    {
        "name": " Weliveriya",
        "countryCode": "LK"
    },
    {
        "name": " Wellagala",
        "countryCode": "LK"
    },
    {
        "name": " Wellarawa",
        "countryCode": "LK"
    },
    {
        "name": " Wellawa",
        "countryCode": "LK"
    },
    {
        "name": " Wellawaya",
        "countryCode": "LK"
    },
    {
        "name": " Welmilla Junction",
        "countryCode": "LK"
    },
    {
        "name": " Welpalla",
        "countryCode": "LK"
    },
    {
        "name": " Wendaruwa",
        "countryCode": "LK"
    },
    {
        "name": " Wennappuwa",
        "countryCode": "LK"
    },
    {
        "name": " Wennoruwa",
        "countryCode": "LK"
    },
    {
        "name": " Weragala",
        "countryCode": "LK"
    },
    {
        "name": " Weragantota",
        "countryCode": "LK"
    },
    {
        "name": " Weranketagoda",
        "countryCode": "LK"
    },
    {
        "name": " Werapitya",
        "countryCode": "LK"
    },
    {
        "name": " Werellagama",
        "countryCode": "LK"
    },
    {
        "name": " Wettawa",
        "countryCode": "LK"
    },
    {
        "name": " Weuda",
        "countryCode": "LK"
    },
    {
        "name": " Wewagama",
        "countryCode": "LK"
    },
    {
        "name": " Wewalawewa",
        "countryCode": "LK"
    },
    {
        "name": " Wewatta",
        "countryCode": "LK"
    },
    {
        "name": " Weweldeniya",
        "countryCode": "LK"
    },
    {
        "name": " Wewelwatta",
        "countryCode": "LK"
    },
    {
        "name": " Widulipura",
        "countryCode": "LK"
    },
    {
        "name": " Wijayabapura",
        "countryCode": "LK"
    },
    {
        "name": " Wijebahukanda",
        "countryCode": "LK"
    },
    {
        "name": " Wijeyakatupotha",
        "countryCode": "LK"
    },
    {
        "name": " Wikiliya",
        "countryCode": "LK"
    },
    {
        "name": " Wilanagama",
        "countryCode": "LK"
    },
    {
        "name": " Wilaoya",
        "countryCode": "LK"
    },
    {
        "name": " Wilgamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Willorawatta",
        "countryCode": "LK"
    },
    {
        "name": " Wilpita",
        "countryCode": "LK"
    },
    {
        "name": " Wilpotha",
        "countryCode": "LK"
    },
    {
        "name": " Wineethagama",
        "countryCode": "LK"
    },
    {
        "name": " Wolfendal Street",
        "countryCode": "LK"
    },
    {
        "name": " Yagirala",
        "countryCode": "LK"
    },
    {
        "name": " Yahalatenna",
        "countryCode": "LK"
    },
    {
        "name": " Yakkala",
        "countryCode": "LK"
    },
    {
        "name": " Yakkalamulla",
        "countryCode": "LK"
    },
    {
        "name": " Yakwila",
        "countryCode": "LK"
    },
    {
        "name": " Yalagamuwa",
        "countryCode": "LK"
    },
    {
        "name": " Yalwela",
        "countryCode": "LK"
    },
    {
        "name": " Yatadolawatta",
        "countryCode": "LK"
    },
    {
        "name": " Yatagama",
        "countryCode": "LK"
    },
    {
        "name": " Yatalamatta",
        "countryCode": "LK"
    },
    {
        "name": " Yatapana",
        "countryCode": "LK"
    },
    {
        "name": " Yatawara Junction",
        "countryCode": "LK"
    },
    {
        "name": " Yatawatta",
        "countryCode": "LK"
    },
    {
        "name": " Yatigala",
        "countryCode": "LK"
    },
    {
        "name": " Yatigaloluwa",
        "countryCode": "LK"
    },
    {
        "name": " Yatihalagala",
        "countryCode": "LK"
    },
    {
        "name": " Yatiyana",
        "countryCode": "LK"
    },
    {
        "name": " Yatiyana",
        "countryCode": "LK"
    },
    {
        "name": " Yatiyantota",
        "countryCode": "LK"
    },
    {
        "name": " Yattogoda",
        "countryCode": "LK"
    },
    {
        "name": " Yodaela",
        "countryCode": "LK"
    },
    {
        "name": " Yogapuram",
        "countryCode": "LK"
    },
    {
        "name": " Yogiyana",
        "countryCode": "LK"
    },
    {
        "name": " Yudaganawa",
        "countryCode": "LK"
    },
    {
        "name": "",
        "countryCode": "LK"
    }
]
    },
    {
        "name": "Afghanistan",
        "phonecode": "93",
        "cities": [
            {
                "name": "Balkh",
                "countryCode": "AF"
            },
            {
                "name": "Dowlatābād",
                "countryCode": "AF"
            },
            {
                "name": "Khulm",
                "countryCode": "AF"
            },
            {
                "name": "Lab-Sar",
                "countryCode": "AF"
            },
            {
                "name": "Mazār-e Sharīf",
                "countryCode": "AF"
            },
            {
                "name": "Qarchī Gak",
                "countryCode": "AF"
            },
            {
                "name": "Bāmyān",
                "countryCode": "AF"
            },
            {
                "name": "Panjāb",
                "countryCode": "AF"
            },
            {
                "name": "Ghormach",
                "countryCode": "AF"
            },
            {
                "name": "Qala i Naw",
                "countryCode": "AF"
            },
            {
                "name": "Ashkāsham",
                "countryCode": "AF"
            },
            {
                "name": "Fayzabad",
                "countryCode": "AF"
            },
            {
                "name": "Jurm",
                "countryCode": "AF"
            },
            {
                "name": "Khandūd",
                "countryCode": "AF"
            },
            {
                "name": "Rāghistān",
                "countryCode": "AF"
            },
            {
                "name": "Wākhān",
                "countryCode": "AF"
            },
            {
                "name": "Baghlān",
                "countryCode": "AF"
            },
            {
                "name": "Nahrīn",
                "countryCode": "AF"
            },
            {
                "name": "Pul-e Khumrī",
                "countryCode": "AF"
            },
            {
                "name": "Ḩukūmatī Dahanah-ye Ghōrī",
                "countryCode": "AF"
            },
            {
                "name": "Nīlī",
                "countryCode": "AF"
            },
            {
                "name": "Farah",
                "countryCode": "AF"
            },
            {
                "name": "Andkhoy",
                "countryCode": "AF"
            },
            {
                "name": "Maymana",
                "countryCode": "AF"
            },
            {
                "name": "Ghazni",
                "countryCode": "AF"
            },
            {
                "name": "Fayrōz Kōh",
                "countryCode": "AF"
            },
            {
                "name": "Shahrak",
                "countryCode": "AF"
            },
            {
                "name": "Gereshk",
                "countryCode": "AF"
            },
            {
                "name": "Lashkar Gāh",
                "countryCode": "AF"
            },
            {
                "name": "Markaz-e Ḩukūmat-e Darwēshān",
                "countryCode": "AF"
            },
            {
                "name": "Sangīn",
                "countryCode": "AF"
            },
            {
                "name": "‘Alāqahdārī Dīshū",
                "countryCode": "AF"
            },
            {
                "name": "Chahār Burj",
                "countryCode": "AF"
            },
            {
                "name": "Ghōriyān",
                "countryCode": "AF"
            },
            {
                "name": "Herāt",
                "countryCode": "AF"
            },
            {
                "name": "Kafir Qala",
                "countryCode": "AF"
            },
            {
                "name": "Karukh",
                "countryCode": "AF"
            },
            {
                "name": "Kuhsān",
                "countryCode": "AF"
            },
            {
                "name": "Kushk",
                "countryCode": "AF"
            },
            {
                "name": "Qarah Bāgh",
                "countryCode": "AF"
            },
            {
                "name": "Shīnḏanḏ",
                "countryCode": "AF"
            },
            {
                "name": "Tīr Pul",
                "countryCode": "AF"
            },
            {
                "name": "Zindah Jān",
                "countryCode": "AF"
            },
            {
                "name": "Darzāb",
                "countryCode": "AF"
            },
            {
                "name": "Qarqīn",
                "countryCode": "AF"
            },
            {
                "name": "Shibirghān",
                "countryCode": "AF"
            },
            {
                "name": "Āqchah",
                "countryCode": "AF"
            },
            {
                "name": "Kabul",
                "countryCode": "AF"
            },
            {
                "name": "Mīr Bachah Kōṯ",
                "countryCode": "AF"
            },
            {
                "name": "Paghmān",
                "countryCode": "AF"
            },
            {
                "name": "Kandahār",
                "countryCode": "AF"
            },
            {
                "name": "Sidqābād",
                "countryCode": "AF"
            },
            {
                "name": "Dasht-e Archī",
                "countryCode": "AF"
            },
            {
                "name": "Imām Şāḩib",
                "countryCode": "AF"
            },
            {
                "name": "Khanabad",
                "countryCode": "AF"
            },
            {
                "name": "Kunduz",
                "countryCode": "AF"
            },
            {
                "name": "Qarāwul",
                "countryCode": "AF"
            },
            {
                "name": "Khōst",
                "countryCode": "AF"
            },
            {
                "name": "Asadabad",
                "countryCode": "AF"
            },
            {
                "name": "Āsmār",
                "countryCode": "AF"
            },
            {
                "name": "Mehtar Lām",
                "countryCode": "AF"
            },
            {
                "name": "Baraki Barak",
                "countryCode": "AF"
            },
            {
                "name": "Pul-e ‘Alam",
                "countryCode": "AF"
            },
            {
                "name": "Ḩukūmatī Azrah",
                "countryCode": "AF"
            },
            {
                "name": "Bāsawul",
                "countryCode": "AF"
            },
            {
                "name": "Jalālābād",
                "countryCode": "AF"
            },
            {
                "name": "Markaz-e Woluswalī-ye Āchīn",
                "countryCode": "AF"
            },
            {
                "name": "Khāsh",
                "countryCode": "AF"
            },
            {
                "name": "Mīrābād",
                "countryCode": "AF"
            },
            {
                "name": "Rūdbār",
                "countryCode": "AF"
            },
            {
                "name": "Zaranj",
                "countryCode": "AF"
            },
            {
                "name": "Pārūn",
                "countryCode": "AF"
            },
            {
                "name": "Bāzārak",
                "countryCode": "AF"
            },
            {
                "name": "Charikar",
                "countryCode": "AF"
            },
            {
                "name": "Jabal os Saraj",
                "countryCode": "AF"
            },
            {
                "name": "Gardez",
                "countryCode": "AF"
            },
            {
                "name": "Saṟōbī",
                "countryCode": "AF"
            },
            {
                "name": "Zarghūn Shahr",
                "countryCode": "AF"
            },
            {
                "name": "Zaṟah Sharan",
                "countryCode": "AF"
            },
            {
                "name": "Aībak",
                "countryCode": "AF"
            },
            {
                "name": "Chīras",
                "countryCode": "AF"
            },
            {
                "name": "Larkird",
                "countryCode": "AF"
            },
            {
                "name": "Qal‘ah-ye Shahr",
                "countryCode": "AF"
            },
            {
                "name": "Sang-e Chārak",
                "countryCode": "AF"
            },
            {
                "name": "Sar-e Pul",
                "countryCode": "AF"
            },
            {
                "name": "Tagāw-Bāy",
                "countryCode": "AF"
            },
            {
                "name": "Tukzār",
                "countryCode": "AF"
            },
            {
                "name": "Taloqan",
                "countryCode": "AF"
            },
            {
                "name": "Ārt Khwājah",
                "countryCode": "AF"
            },
            {
                "name": "Tarinkot",
                "countryCode": "AF"
            },
            {
                "name": "Uruzgān",
                "countryCode": "AF"
            },
            {
                "name": "Qalāt",
                "countryCode": "AF"
            }
        ]
    },
    {
        "name": "Aland Islands",
        "phonecode": "+358-18",
        "cities": []
    },
    {
        "name": "Albania",
        "phonecode": "355",
        "cities": [
            {
                "name": "Ballsh",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Divjakë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Fier",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Mallakastër",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Patos",
                "countryCode": "AL"
            },
            {
                "name": "Divjakë",
                "countryCode": "AL"
            },
            {
                "name": "Fier",
                "countryCode": "AL"
            },
            {
                "name": "Fier-Çifçi",
                "countryCode": "AL"
            },
            {
                "name": "Lushnjë",
                "countryCode": "AL"
            },
            {
                "name": "Patos",
                "countryCode": "AL"
            },
            {
                "name": "Patos Fshat",
                "countryCode": "AL"
            },
            {
                "name": "Roskovec",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Mallakastrës",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Devoll",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Kolonjë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Maliq",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Pustec",
                "countryCode": "AL"
            },
            {
                "name": "Bilisht",
                "countryCode": "AL"
            },
            {
                "name": "Ersekë",
                "countryCode": "AL"
            },
            {
                "name": "Korçë",
                "countryCode": "AL"
            },
            {
                "name": "Leskovik",
                "countryCode": "AL"
            },
            {
                "name": "Libonik",
                "countryCode": "AL"
            },
            {
                "name": "Maliq",
                "countryCode": "AL"
            },
            {
                "name": "Mborje",
                "countryCode": "AL"
            },
            {
                "name": "Pogradec",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Devollit",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Kolonjës",
                "countryCode": "AL"
            },
            {
                "name": "Velçan",
                "countryCode": "AL"
            },
            {
                "name": "Voskopojë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Kurbin",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Lezhë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Mirditë",
                "countryCode": "AL"
            },
            {
                "name": "Kurbnesh",
                "countryCode": "AL"
            },
            {
                "name": "Laç",
                "countryCode": "AL"
            },
            {
                "name": "Lezhë",
                "countryCode": "AL"
            },
            {
                "name": "Mamurras",
                "countryCode": "AL"
            },
            {
                "name": "Milot",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Kurbinit",
                "countryCode": "AL"
            },
            {
                "name": "Rrëshen",
                "countryCode": "AL"
            },
            {
                "name": "Rubik",
                "countryCode": "AL"
            },
            {
                "name": "Shëngjin",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Bulqizë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Klos",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Mat",
                "countryCode": "AL"
            },
            {
                "name": "Bulqizë",
                "countryCode": "AL"
            },
            {
                "name": "Burrel",
                "countryCode": "AL"
            },
            {
                "name": "Klos",
                "countryCode": "AL"
            },
            {
                "name": "Peshkopi",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Bulqizës",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Dibrës",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Matit",
                "countryCode": "AL"
            },
            {
                "name": "Ulëz",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Finiq",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Himarë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Konispol",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Selenicë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Vlorë",
                "countryCode": "AL"
            },
            {
                "name": "Delvinë",
                "countryCode": "AL"
            },
            {
                "name": "Himarë",
                "countryCode": "AL"
            },
            {
                "name": "Konispol",
                "countryCode": "AL"
            },
            {
                "name": "Ksamil",
                "countryCode": "AL"
            },
            {
                "name": "Orikum",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Delvinës",
                "countryCode": "AL"
            },
            {
                "name": "Sarandë",
                "countryCode": "AL"
            },
            {
                "name": "Selenicë",
                "countryCode": "AL"
            },
            {
                "name": "Vlorë",
                "countryCode": "AL"
            },
            {
                "name": "Banaj",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Berat",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Kuçovë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Poliçan",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Skrapar",
                "countryCode": "AL"
            },
            {
                "name": "Berat",
                "countryCode": "AL"
            },
            {
                "name": "Kuçovë",
                "countryCode": "AL"
            },
            {
                "name": "Poliçan",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Beratit",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Kuçovës",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Skraparit",
                "countryCode": "AL"
            },
            {
                "name": "Ura Vajgurore",
                "countryCode": "AL"
            },
            {
                "name": "Çorovodë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Durrës",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Krujë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Shijak",
                "countryCode": "AL"
            },
            {
                "name": "Durrës",
                "countryCode": "AL"
            },
            {
                "name": "Durrës District",
                "countryCode": "AL"
            },
            {
                "name": "Fushë-Krujë",
                "countryCode": "AL"
            },
            {
                "name": "Krujë",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Krujës",
                "countryCode": "AL"
            },
            {
                "name": "Shijak",
                "countryCode": "AL"
            },
            {
                "name": "Sukth",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Dropull",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Kelcyrë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Libohovë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Memaliaj",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Përmet",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Tepelenë",
                "countryCode": "AL"
            },
            {
                "name": "Gjinkar",
                "countryCode": "AL"
            },
            {
                "name": "Gjirokastër",
                "countryCode": "AL"
            },
            {
                "name": "Këlcyrë",
                "countryCode": "AL"
            },
            {
                "name": "Lazarat",
                "countryCode": "AL"
            },
            {
                "name": "Libohovë",
                "countryCode": "AL"
            },
            {
                "name": "Memaliaj",
                "countryCode": "AL"
            },
            {
                "name": "Përmet",
                "countryCode": "AL"
            },
            {
                "name": "Tepelenë",
                "countryCode": "AL"
            },
            {
                "name": "Bajram Curri",
                "countryCode": "AL"
            },
            {
                "name": "Krumë",
                "countryCode": "AL"
            },
            {
                "name": "Kukës",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Hasit",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Kukësit",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Malësi e Madhe",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Pukë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Vau i Dejës",
                "countryCode": "AL"
            },
            {
                "name": "Fushë-Arrëz",
                "countryCode": "AL"
            },
            {
                "name": "Koplik",
                "countryCode": "AL"
            },
            {
                "name": "Pukë",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Malësia e Madhe",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Shkodrës",
                "countryCode": "AL"
            },
            {
                "name": "Shkodër",
                "countryCode": "AL"
            },
            {
                "name": "Vau i Dejës",
                "countryCode": "AL"
            },
            {
                "name": "Vukatanë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Kavajë",
                "countryCode": "AL"
            },
            {
                "name": "Bashkia Vorë",
                "countryCode": "AL"
            },
            {
                "name": "Kamëz",
                "countryCode": "AL"
            },
            {
                "name": "Kavajë",
                "countryCode": "AL"
            },
            {
                "name": "Krrabë",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Kavajës",
                "countryCode": "AL"
            },
            {
                "name": "Rrethi i Tiranës",
                "countryCode": "AL"
            },
            {
                "name": "Rrogozhinë",
                "countryCode": "AL"
            },
            {
                "name": "Sinaballaj",
                "countryCode": "AL"
            },
            {
                "name": "Tirana",
                "countryCode": "AL"
            },
            {
                "name": "Vorë",
                "countryCode": "AL"
            }
        ]
    },
    {
        "name": "Algeria",
        "phonecode": "213",
        "cities": [
            {
                "name": "Adrar",
                "countryCode": "DZ"
            },
            {
                "name": "Aoulef",
                "countryCode": "DZ"
            },
            {
                "name": "Reggane",
                "countryCode": "DZ"
            },
            {
                "name": "Timimoun",
                "countryCode": "DZ"
            },
            {
                "name": "Abou el Hassan",
                "countryCode": "DZ"
            },
            {
                "name": "Boukadir",
                "countryCode": "DZ"
            },
            {
                "name": "Chlef",
                "countryCode": "DZ"
            },
            {
                "name": "Ech Chettia",
                "countryCode": "DZ"
            },
            {
                "name": "Oued Fodda",
                "countryCode": "DZ"
            },
            {
                "name": "Oued Sly",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Akkacha",
                "countryCode": "DZ"
            },
            {
                "name": "Aflou",
                "countryCode": "DZ"
            },
            {
                "name": "Laghouat",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Beïda",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Fakroun",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Kercha",
                "countryCode": "DZ"
            },
            {
                "name": "El Aouinet",
                "countryCode": "DZ"
            },
            {
                "name": "Meskiana",
                "countryCode": "DZ"
            },
            {
                "name": "Oum el Bouaghi",
                "countryCode": "DZ"
            },
            {
                "name": "Arris",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Touta",
                "countryCode": "DZ"
            },
            {
                "name": "Barika",
                "countryCode": "DZ"
            },
            {
                "name": "Batna",
                "countryCode": "DZ"
            },
            {
                "name": "Boumagueur",
                "countryCode": "DZ"
            },
            {
                "name": "Merouana",
                "countryCode": "DZ"
            },
            {
                "name": "Râs el Aïoun",
                "countryCode": "DZ"
            },
            {
                "name": "Tazoult-Lambese",
                "countryCode": "DZ"
            },
            {
                "name": "Akbou",
                "countryCode": "DZ"
            },
            {
                "name": "Amizour",
                "countryCode": "DZ"
            },
            {
                "name": "Barbacha",
                "countryCode": "DZ"
            },
            {
                "name": "Bejaïa",
                "countryCode": "DZ"
            },
            {
                "name": "El Kseur",
                "countryCode": "DZ"
            },
            {
                "name": "Feraoun",
                "countryCode": "DZ"
            },
            {
                "name": "Seddouk",
                "countryCode": "DZ"
            },
            {
                "name": "el hed",
                "countryCode": "DZ"
            },
            {
                "name": "Biskra",
                "countryCode": "DZ"
            },
            {
                "name": "Oumache",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Khaled",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Okba",
                "countryCode": "DZ"
            },
            {
                "name": "Tolga",
                "countryCode": "DZ"
            },
            {
                "name": "Zeribet el Oued",
                "countryCode": "DZ"
            },
            {
                "name": "Béchar",
                "countryCode": "DZ"
            },
            {
                "name": "Beni Mered",
                "countryCode": "DZ"
            },
            {
                "name": "Blida",
                "countryCode": "DZ"
            },
            {
                "name": "Boufarik",
                "countryCode": "DZ"
            },
            {
                "name": "Bougara",
                "countryCode": "DZ"
            },
            {
                "name": "Bouinan",
                "countryCode": "DZ"
            },
            {
                "name": "Boû Arfa",
                "countryCode": "DZ"
            },
            {
                "name": "Chebli",
                "countryCode": "DZ"
            },
            {
                "name": "Chiffa",
                "countryCode": "DZ"
            },
            {
                "name": "Larbaâ",
                "countryCode": "DZ"
            },
            {
                "name": "Meftah",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Moussa",
                "countryCode": "DZ"
            },
            {
                "name": "Souma",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Bessem",
                "countryCode": "DZ"
            },
            {
                "name": "Bouïra",
                "countryCode": "DZ"
            },
            {
                "name": "Chorfa",
                "countryCode": "DZ"
            },
            {
                "name": "Draa el Mizan",
                "countryCode": "DZ"
            },
            {
                "name": "Lakhdaria",
                "countryCode": "DZ"
            },
            {
                "name": "Sour el Ghozlane",
                "countryCode": "DZ"
            },
            {
                "name": "I-n-Salah",
                "countryCode": "DZ"
            },
            {
                "name": "Tamanrasset",
                "countryCode": "DZ"
            },
            {
                "name": "Bir el Ater",
                "countryCode": "DZ"
            },
            {
                "name": "Cheria",
                "countryCode": "DZ"
            },
            {
                "name": "Hammamet",
                "countryCode": "DZ"
            },
            {
                "name": "Tébessa",
                "countryCode": "DZ"
            },
            {
                "name": "Beni Mester",
                "countryCode": "DZ"
            },
            {
                "name": "Bensekrane",
                "countryCode": "DZ"
            },
            {
                "name": "Chetouane",
                "countryCode": "DZ"
            },
            {
                "name": "Hennaya",
                "countryCode": "DZ"
            },
            {
                "name": "Mansoûra",
                "countryCode": "DZ"
            },
            {
                "name": "Nedroma",
                "countryCode": "DZ"
            },
            {
                "name": "Ouled Mimoun",
                "countryCode": "DZ"
            },
            {
                "name": "Remchi",
                "countryCode": "DZ"
            },
            {
                "name": "Sebdou",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Abdelli",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Senoussi سيدي سنوسي",
                "countryCode": "DZ"
            },
            {
                "name": "Tlemcen",
                "countryCode": "DZ"
            },
            {
                "name": "Djebilet Rosfa",
                "countryCode": "DZ"
            },
            {
                "name": "Frenda",
                "countryCode": "DZ"
            },
            {
                "name": "Ksar Chellala",
                "countryCode": "DZ"
            },
            {
                "name": "Mehdia daira de meghila",
                "countryCode": "DZ"
            },
            {
                "name": "Sougueur",
                "countryCode": "DZ"
            },
            {
                "name": "Tiaret",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn Deheb",
                "countryCode": "DZ"
            },
            {
                "name": "Arhribs",
                "countryCode": "DZ"
            },
            {
                "name": "Azazga",
                "countryCode": "DZ"
            },
            {
                "name": "Beni Douala",
                "countryCode": "DZ"
            },
            {
                "name": "Boghni",
                "countryCode": "DZ"
            },
            {
                "name": "Boudjima",
                "countryCode": "DZ"
            },
            {
                "name": "Chemini",
                "countryCode": "DZ"
            },
            {
                "name": "Draa Ben Khedda",
                "countryCode": "DZ"
            },
            {
                "name": "Freha",
                "countryCode": "DZ"
            },
            {
                "name": "Ighram",
                "countryCode": "DZ"
            },
            {
                "name": "L’Arbaa Naït Irathen",
                "countryCode": "DZ"
            },
            {
                "name": "Mekla",
                "countryCode": "DZ"
            },
            {
                "name": "Timizart",
                "countryCode": "DZ"
            },
            {
                "name": "Tirmitine",
                "countryCode": "DZ"
            },
            {
                "name": "Tizi Ouzou",
                "countryCode": "DZ"
            },
            {
                "name": "Tizi Rached",
                "countryCode": "DZ"
            },
            {
                "name": "Tizi-n-Tleta",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn el Hammam",
                "countryCode": "DZ"
            },
            {
                "name": "Algiers",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Taya",
                "countryCode": "DZ"
            },
            {
                "name": "Bab Ezzouar",
                "countryCode": "DZ"
            },
            {
                "name": "Birkhadem",
                "countryCode": "DZ"
            },
            {
                "name": "Bordj el Kiffan",
                "countryCode": "DZ"
            },
            {
                "name": "Dar el Beïda",
                "countryCode": "DZ"
            },
            {
                "name": "Rouiba",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Oussera",
                "countryCode": "DZ"
            },
            {
                "name": "Birine",
                "countryCode": "DZ"
            },
            {
                "name": "Charef",
                "countryCode": "DZ"
            },
            {
                "name": "Dar Chioukh",
                "countryCode": "DZ"
            },
            {
                "name": "Djelfa",
                "countryCode": "DZ"
            },
            {
                "name": "El Idrissia",
                "countryCode": "DZ"
            },
            {
                "name": "Messaad",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn el Bell",
                "countryCode": "DZ"
            },
            {
                "name": "Jijel",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Arnat",
                "countryCode": "DZ"
            },
            {
                "name": "BABOR - VILLE",
                "countryCode": "DZ"
            },
            {
                "name": "Bougaa",
                "countryCode": "DZ"
            },
            {
                "name": "El Eulma",
                "countryCode": "DZ"
            },
            {
                "name": "Salah Bey",
                "countryCode": "DZ"
            },
            {
                "name": "Sétif",
                "countryCode": "DZ"
            },
            {
                "name": "Saïda",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn el Hadjar",
                "countryCode": "DZ"
            },
            {
                "name": "Azzaba",
                "countryCode": "DZ"
            },
            {
                "name": "Karkira",
                "countryCode": "DZ"
            },
            {
                "name": "Skikda",
                "countryCode": "DZ"
            },
            {
                "name": "Tamalous",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn El Berd District",
                "countryCode": "DZ"
            },
            {
                "name": "Balidat Ameur",
                "countryCode": "DZ"
            },
            {
                "name": "Belarbi",
                "countryCode": "DZ"
            },
            {
                "name": "Ben Badis, Sid Bel Abbés",
                "countryCode": "DZ"
            },
            {
                "name": "Djamaa",
                "countryCode": "DZ"
            },
            {
                "name": "El Bour",
                "countryCode": "DZ"
            },
            {
                "name": "El Hadjira",
                "countryCode": "DZ"
            },
            {
                "name": "Haoud El Hamra",
                "countryCode": "DZ"
            },
            {
                "name": "Hassi Messaoud",
                "countryCode": "DZ"
            },
            {
                "name": "Lamtar",
                "countryCode": "DZ"
            },
            {
                "name": "Marhoum",
                "countryCode": "DZ"
            },
            {
                "name": "Megarine",
                "countryCode": "DZ"
            },
            {
                "name": "Merine",
                "countryCode": "DZ"
            },
            {
                "name": "Mezaourou",
                "countryCode": "DZ"
            },
            {
                "name": "Moggar",
                "countryCode": "DZ"
            },
            {
                "name": "Moulay Slissen",
                "countryCode": "DZ"
            },
            {
                "name": "N'Goussa",
                "countryCode": "DZ"
            },
            {
                "name": "Ouargla",
                "countryCode": "DZ"
            },
            {
                "name": "Rouissat",
                "countryCode": "DZ"
            },
            {
                "name": "Sfissef",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Ali Boussidi",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Amrane",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Bel Abbès",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Brahim",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Hamadouche",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Slimane",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Yacoub",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Yahia",
                "countryCode": "DZ"
            },
            {
                "name": "Tabia, Sid Bel Abbés",
                "countryCode": "DZ"
            },
            {
                "name": "Taibet",
                "countryCode": "DZ"
            },
            {
                "name": "Tamellaht",
                "countryCode": "DZ"
            },
            {
                "name": "Tamerna Djedida",
                "countryCode": "DZ"
            },
            {
                "name": "Tebesbest",
                "countryCode": "DZ"
            },
            {
                "name": "Teghalimet",
                "countryCode": "DZ"
            },
            {
                "name": "Telagh",
                "countryCode": "DZ"
            },
            {
                "name": "Tenezara",
                "countryCode": "DZ"
            },
            {
                "name": "Tenira",
                "countryCode": "DZ"
            },
            {
                "name": "Tessala",
                "countryCode": "DZ"
            },
            {
                "name": "Touggourt",
                "countryCode": "DZ"
            },
            {
                "name": "Zerouala",
                "countryCode": "DZ"
            },
            {
                "name": "Annaba",
                "countryCode": "DZ"
            },
            {
                "name": "Berrahal",
                "countryCode": "DZ"
            },
            {
                "name": "Drean",
                "countryCode": "DZ"
            },
            {
                "name": "El Hadjar",
                "countryCode": "DZ"
            },
            {
                "name": "Boumahra Ahmed",
                "countryCode": "DZ"
            },
            {
                "name": "Guelma",
                "countryCode": "DZ"
            },
            {
                "name": "Héliopolis",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Smara",
                "countryCode": "DZ"
            },
            {
                "name": "Constantine",
                "countryCode": "DZ"
            },
            {
                "name": "Didouche Mourad",
                "countryCode": "DZ"
            },
            {
                "name": "El Khroub",
                "countryCode": "DZ"
            },
            {
                "name": "Hamma Bouziane",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn Abid",
                "countryCode": "DZ"
            },
            {
                "name": "Berrouaghia",
                "countryCode": "DZ"
            },
            {
                "name": "Ksar el Boukhari",
                "countryCode": "DZ"
            },
            {
                "name": "Médéa",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn Boucif",
                "countryCode": "DZ"
            },
            {
                "name": "Mostaganem",
                "countryCode": "DZ"
            },
            {
                "name": "M’Sila",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Aïssa",
                "countryCode": "DZ"
            },
            {
                "name": "‘Aïn el Hadjel",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn el Melh",
                "countryCode": "DZ"
            },
            {
                "name": "Bou Hanifia el Hamamat",
                "countryCode": "DZ"
            },
            {
                "name": "Mascara",
                "countryCode": "DZ"
            },
            {
                "name": "Oued el Abtal",
                "countryCode": "DZ"
            },
            {
                "name": "Sig",
                "countryCode": "DZ"
            },
            {
                "name": "Djamaa",
                "countryCode": "DZ"
            },
            {
                "name": "El Hadjira",
                "countryCode": "DZ"
            },
            {
                "name": "Hassi Messaoud",
                "countryCode": "DZ"
            },
            {
                "name": "Megarine",
                "countryCode": "DZ"
            },
            {
                "name": "Ouargla",
                "countryCode": "DZ"
            },
            {
                "name": "Rouissat",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Amrane",
                "countryCode": "DZ"
            },
            {
                "name": "Tebesbest",
                "countryCode": "DZ"
            },
            {
                "name": "Touggourt",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn el Bya",
                "countryCode": "DZ"
            },
            {
                "name": "Bir el Djir",
                "countryCode": "DZ"
            },
            {
                "name": "Bou Tlelis",
                "countryCode": "DZ"
            },
            {
                "name": "Es Senia",
                "countryCode": "DZ"
            },
            {
                "name": "Mers el Kebir",
                "countryCode": "DZ"
            },
            {
                "name": "Oran",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi ech Chahmi",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn el Turk",
                "countryCode": "DZ"
            },
            {
                "name": "Brezina",
                "countryCode": "DZ"
            },
            {
                "name": "El Abiodh Sidi Cheikh",
                "countryCode": "DZ"
            },
            {
                "name": "El Bayadh",
                "countryCode": "DZ"
            },
            {
                "name": "Illizi",
                "countryCode": "DZ"
            },
            {
                "name": "Bordj Bou Arreridj",
                "countryCode": "DZ"
            },
            {
                "name": "Bordj Ghdir",
                "countryCode": "DZ"
            },
            {
                "name": "Bordj Zemoura",
                "countryCode": "DZ"
            },
            {
                "name": "El Achir",
                "countryCode": "DZ"
            },
            {
                "name": "Mansourah",
                "countryCode": "DZ"
            },
            {
                "name": "Melouza",
                "countryCode": "DZ"
            },
            {
                "name": "Râs el Oued",
                "countryCode": "DZ"
            },
            {
                "name": "Arbatache",
                "countryCode": "DZ"
            },
            {
                "name": "Beni Amrane",
                "countryCode": "DZ"
            },
            {
                "name": "Boudouaou",
                "countryCode": "DZ"
            },
            {
                "name": "Boumerdas",
                "countryCode": "DZ"
            },
            {
                "name": "Chabet el Ameur",
                "countryCode": "DZ"
            },
            {
                "name": "Dellys",
                "countryCode": "DZ"
            },
            {
                "name": "Khemis el Khechna",
                "countryCode": "DZ"
            },
            {
                "name": "Makouda",
                "countryCode": "DZ"
            },
            {
                "name": "Naciria",
                "countryCode": "DZ"
            },
            {
                "name": "Ouled Moussa",
                "countryCode": "DZ"
            },
            {
                "name": "Reghaïa",
                "countryCode": "DZ"
            },
            {
                "name": "Tadmaït",
                "countryCode": "DZ"
            },
            {
                "name": "Thenia",
                "countryCode": "DZ"
            },
            {
                "name": "Tizi Gheniff",
                "countryCode": "DZ"
            },
            {
                "name": "Ben Mehidi",
                "countryCode": "DZ"
            },
            {
                "name": "Besbes",
                "countryCode": "DZ"
            },
            {
                "name": "El Kala",
                "countryCode": "DZ"
            },
            {
                "name": "El Tarf",
                "countryCode": "DZ"
            },
            {
                "name": "Tindouf",
                "countryCode": "DZ"
            },
            {
                "name": "Lardjem",
                "countryCode": "DZ"
            },
            {
                "name": "Tissemsilt",
                "countryCode": "DZ"
            },
            {
                "name": "Debila",
                "countryCode": "DZ"
            },
            {
                "name": "El Oued",
                "countryCode": "DZ"
            },
            {
                "name": "Reguiba",
                "countryCode": "DZ"
            },
            {
                "name": "Robbah",
                "countryCode": "DZ"
            },
            {
                "name": "Khenchela",
                "countryCode": "DZ"
            },
            {
                "name": "Sedrata",
                "countryCode": "DZ"
            },
            {
                "name": "Souk Ahras",
                "countryCode": "DZ"
            },
            {
                "name": "Baraki",
                "countryCode": "DZ"
            },
            {
                "name": "Bou Ismaïl",
                "countryCode": "DZ"
            },
            {
                "name": "Cheraga",
                "countryCode": "DZ"
            },
            {
                "name": "Douera",
                "countryCode": "DZ"
            },
            {
                "name": "El Affroun",
                "countryCode": "DZ"
            },
            {
                "name": "Hadjout",
                "countryCode": "DZ"
            },
            {
                "name": "Kolea",
                "countryCode": "DZ"
            },
            {
                "name": "Mouzaïa",
                "countryCode": "DZ"
            },
            {
                "name": "Oued el Alleug",
                "countryCode": "DZ"
            },
            {
                "name": "Saoula",
                "countryCode": "DZ"
            },
            {
                "name": "Tipasa",
                "countryCode": "DZ"
            },
            {
                "name": "Zeralda",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn Benian",
                "countryCode": "DZ"
            },
            {
                "name": "Chelghoum el Aïd",
                "countryCode": "DZ"
            },
            {
                "name": "Mila",
                "countryCode": "DZ"
            },
            {
                "name": "Rouached",
                "countryCode": "DZ"
            },
            {
                "name": "Sidi Mérouane",
                "countryCode": "DZ"
            },
            {
                "name": "Telerghma",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Defla",
                "countryCode": "DZ"
            },
            {
                "name": "El Abadia",
                "countryCode": "DZ"
            },
            {
                "name": "El Attaf",
                "countryCode": "DZ"
            },
            {
                "name": "Khemis Miliana",
                "countryCode": "DZ"
            },
            {
                "name": "Theniet el Had",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Sefra",
                "countryCode": "DZ"
            },
            {
                "name": "Naama",
                "countryCode": "DZ"
            },
            {
                "name": "Aïn Temouchent",
                "countryCode": "DZ"
            },
            {
                "name": "Beni Saf",
                "countryCode": "DZ"
            },
            {
                "name": "El Amria",
                "countryCode": "DZ"
            },
            {
                "name": "El Malah",
                "countryCode": "DZ"
            },
            {
                "name": "Hammam Bou Hadjar",
                "countryCode": "DZ"
            },
            {
                "name": "Berriane",
                "countryCode": "DZ"
            },
            {
                "name": "Ghardaïa",
                "countryCode": "DZ"
            },
            {
                "name": "Metlili Chaamba",
                "countryCode": "DZ"
            },
            {
                "name": "Ammi Moussa",
                "countryCode": "DZ"
            },
            {
                "name": "Djidiouia",
                "countryCode": "DZ"
            },
            {
                "name": "Mazouna",
                "countryCode": "DZ"
            },
            {
                "name": "Oued Rhiou",
                "countryCode": "DZ"
            },
            {
                "name": "Relizane",
                "countryCode": "DZ"
            },
            {
                "name": "Smala",
                "countryCode": "DZ"
            },
            {
                "name": "Zemoura",
                "countryCode": "DZ"
            },
            {
                "name": "’Aïn Merane",
                "countryCode": "DZ"
            }
        ]
    },
    {
        "name": "American Samoa",
        "phonecode": "+1-684",
        "cities": []
    },
    {
        "name": "Andorra",
        "phonecode": "376",
        "cities": [
            {
                "name": "Canillo",
                "countryCode": "AD"
            },
            {
                "name": "El Tarter",
                "countryCode": "AD"
            },
            {
                "name": "Encamp",
                "countryCode": "AD"
            },
            {
                "name": "Pas de la Casa",
                "countryCode": "AD"
            },
            {
                "name": "Arinsal",
                "countryCode": "AD"
            },
            {
                "name": "la Massana",
                "countryCode": "AD"
            },
            {
                "name": "Ordino",
                "countryCode": "AD"
            },
            {
                "name": "Sant Julià de Lòria",
                "countryCode": "AD"
            },
            {
                "name": "Andorra la Vella",
                "countryCode": "AD"
            },
            {
                "name": "les Escaldes",
                "countryCode": "AD"
            }
        ]
    },
    {
        "name": "Angola",
        "phonecode": "244",
        "cities": [
            {
                "name": "Caxito",
                "countryCode": "AO"
            },
            {
                "name": "Benguela",
                "countryCode": "AO"
            },
            {
                "name": "Catumbela",
                "countryCode": "AO"
            },
            {
                "name": "Lobito",
                "countryCode": "AO"
            },
            {
                "name": "Camacupa",
                "countryCode": "AO"
            },
            {
                "name": "Catabola",
                "countryCode": "AO"
            },
            {
                "name": "Chissamba",
                "countryCode": "AO"
            },
            {
                "name": "Cuito",
                "countryCode": "AO"
            },
            {
                "name": "Cabinda",
                "countryCode": "AO"
            },
            {
                "name": "Menongue",
                "countryCode": "AO"
            },
            {
                "name": "Ondjiva",
                "countryCode": "AO"
            },
            {
                "name": "Camabatela",
                "countryCode": "AO"
            },
            {
                "name": "N’dalatando",
                "countryCode": "AO"
            },
            {
                "name": "Quibala",
                "countryCode": "AO"
            },
            {
                "name": "Sumbe",
                "countryCode": "AO"
            },
            {
                "name": "Uacu Cungo",
                "countryCode": "AO"
            },
            {
                "name": "Caála",
                "countryCode": "AO"
            },
            {
                "name": "Chela",
                "countryCode": "AO"
            },
            {
                "name": "Huambo",
                "countryCode": "AO"
            },
            {
                "name": "Longonjo",
                "countryCode": "AO"
            },
            {
                "name": "Caconda",
                "countryCode": "AO"
            },
            {
                "name": "Caluquembe",
                "countryCode": "AO"
            },
            {
                "name": "Chibia",
                "countryCode": "AO"
            },
            {
                "name": "Chicomba",
                "countryCode": "AO"
            },
            {
                "name": "Chipindo",
                "countryCode": "AO"
            },
            {
                "name": "Cuvango",
                "countryCode": "AO"
            },
            {
                "name": "Gambos",
                "countryCode": "AO"
            },
            {
                "name": "Humpata",
                "countryCode": "AO"
            },
            {
                "name": "Jamba",
                "countryCode": "AO"
            },
            {
                "name": "Lubango",
                "countryCode": "AO"
            },
            {
                "name": "Matala",
                "countryCode": "AO"
            },
            {
                "name": "Quilengues",
                "countryCode": "AO"
            },
            {
                "name": "Quipungo",
                "countryCode": "AO"
            },
            {
                "name": "Lucapa",
                "countryCode": "AO"
            },
            {
                "name": "Cazaji",
                "countryCode": "AO"
            },
            {
                "name": "Saurimo",
                "countryCode": "AO"
            },
            {
                "name": "Belas",
                "countryCode": "AO"
            },
            {
                "name": "Icolo e Bengo",
                "countryCode": "AO"
            },
            {
                "name": "Luanda",
                "countryCode": "AO"
            },
            {
                "name": "Malanje",
                "countryCode": "AO"
            },
            {
                "name": "Luau",
                "countryCode": "AO"
            },
            {
                "name": "Luena",
                "countryCode": "AO"
            },
            {
                "name": "Lumeje",
                "countryCode": "AO"
            },
            {
                "name": "Léua",
                "countryCode": "AO"
            },
            {
                "name": "Uíge",
                "countryCode": "AO"
            },
            {
                "name": "Mbanza Congo",
                "countryCode": "AO"
            },
            {
                "name": "N'zeto",
                "countryCode": "AO"
            },
            {
                "name": "Soio",
                "countryCode": "AO"
            }
        ]
    },
    {
        "name": "Anguilla",
        "phonecode": "+1-264",
        "cities": []
    },
    {
        "name": "Antarctica",
        "phonecode": "672",
        "cities": []
    },
    {
        "name": "Antigua And Barbuda",
        "phonecode": "+1-268",
        "cities": [
            {
                "name": "Piggotts",
                "countryCode": "AG"
            },
            {
                "name": "Potters Village",
                "countryCode": "AG"
            },
            {
                "name": "Saint John’s",
                "countryCode": "AG"
            },
            {
                "name": "Bolands",
                "countryCode": "AG"
            },
            {
                "name": "Falmouth",
                "countryCode": "AG"
            },
            {
                "name": "Liberta",
                "countryCode": "AG"
            },
            {
                "name": "All Saints",
                "countryCode": "AG"
            },
            {
                "name": "Parham",
                "countryCode": "AG"
            },
            {
                "name": "Codrington",
                "countryCode": "AG"
            }
        ]
    },
    {
        "name": "Argentina",
        "phonecode": "54",
        "cities": [
            {
                "name": "Apolinario Saravia",
                "countryCode": "AR"
            },
            {
                "name": "Cachí",
                "countryCode": "AR"
            },
            {
                "name": "Cafayate",
                "countryCode": "AR"
            },
            {
                "name": "Campo Quijano",
                "countryCode": "AR"
            },
            {
                "name": "Chicoana",
                "countryCode": "AR"
            },
            {
                "name": "Departamento Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Anta",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cerrillos",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Chicoana",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Güemes",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Guachipas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Iruya",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Poma",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Viña",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Los Andes",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Metán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rivadavia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rosario de Lerma",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rosario de la Frontera",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Carlos",
                "countryCode": "AR"
            },
            {
                "name": "El Carril",
                "countryCode": "AR"
            },
            {
                "name": "El Galpón",
                "countryCode": "AR"
            },
            {
                "name": "El Quebrachal",
                "countryCode": "AR"
            },
            {
                "name": "Embarcación",
                "countryCode": "AR"
            },
            {
                "name": "General Enrique Mosconi",
                "countryCode": "AR"
            },
            {
                "name": "Joaquín V. González",
                "countryCode": "AR"
            },
            {
                "name": "La Caldera",
                "countryCode": "AR"
            },
            {
                "name": "Las Lajitas",
                "countryCode": "AR"
            },
            {
                "name": "Salta",
                "countryCode": "AR"
            },
            {
                "name": "San Antonio de los Cobres",
                "countryCode": "AR"
            },
            {
                "name": "San Ramón de la Nueva Orán",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa de Tastil",
                "countryCode": "AR"
            },
            {
                "name": "Tartagal",
                "countryCode": "AR"
            },
            {
                "name": "Balvanera",
                "countryCode": "AR"
            },
            {
                "name": "Barracas",
                "countryCode": "AR"
            },
            {
                "name": "Belgrano",
                "countryCode": "AR"
            },
            {
                "name": "Boedo",
                "countryCode": "AR"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "AR"
            },
            {
                "name": "Colegiales",
                "countryCode": "AR"
            },
            {
                "name": "Retiro",
                "countryCode": "AR"
            },
            {
                "name": "Villa Lugano",
                "countryCode": "AR"
            },
            {
                "name": "Villa Ortúzar",
                "countryCode": "AR"
            },
            {
                "name": "Villa Santa Rita",
                "countryCode": "AR"
            },
            {
                "name": "Buena Esperanza",
                "countryCode": "AR"
            },
            {
                "name": "Candelaria",
                "countryCode": "AR"
            },
            {
                "name": "Concarán",
                "countryCode": "AR"
            },
            {
                "name": "Juan Martín de Pueyrredón",
                "countryCode": "AR"
            },
            {
                "name": "Justo Daract",
                "countryCode": "AR"
            },
            {
                "name": "La Punta",
                "countryCode": "AR"
            },
            {
                "name": "La Toma",
                "countryCode": "AR"
            },
            {
                "name": "Luján",
                "countryCode": "AR"
            },
            {
                "name": "Merlo",
                "countryCode": "AR"
            },
            {
                "name": "Naschel",
                "countryCode": "AR"
            },
            {
                "name": "San Francisco del Monte de Oro",
                "countryCode": "AR"
            },
            {
                "name": "San Luis",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa del Conlara",
                "countryCode": "AR"
            },
            {
                "name": "Tilisarao",
                "countryCode": "AR"
            },
            {
                "name": "Unión",
                "countryCode": "AR"
            },
            {
                "name": "Villa General Roca",
                "countryCode": "AR"
            },
            {
                "name": "Villa Mercedes",
                "countryCode": "AR"
            },
            {
                "name": "Aldea San Antonio",
                "countryCode": "AR"
            },
            {
                "name": "Aranguren",
                "countryCode": "AR"
            },
            {
                "name": "Bovril",
                "countryCode": "AR"
            },
            {
                "name": "Caseros",
                "countryCode": "AR"
            },
            {
                "name": "Ceibas",
                "countryCode": "AR"
            },
            {
                "name": "Chajarí",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Elía",
                "countryCode": "AR"
            },
            {
                "name": "Colón",
                "countryCode": "AR"
            },
            {
                "name": "Concepción del Uruguay",
                "countryCode": "AR"
            },
            {
                "name": "Concordia",
                "countryCode": "AR"
            },
            {
                "name": "Conscripto Bernardi",
                "countryCode": "AR"
            },
            {
                "name": "Crespo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Gualeguaychú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Paraná",
                "countryCode": "AR"
            },
            {
                "name": "Diamante",
                "countryCode": "AR"
            },
            {
                "name": "Domínguez",
                "countryCode": "AR"
            },
            {
                "name": "Federación",
                "countryCode": "AR"
            },
            {
                "name": "Federal",
                "countryCode": "AR"
            },
            {
                "name": "General Campos",
                "countryCode": "AR"
            },
            {
                "name": "General Galarza",
                "countryCode": "AR"
            },
            {
                "name": "General Ramírez",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Mansilla",
                "countryCode": "AR"
            },
            {
                "name": "Gualeguay",
                "countryCode": "AR"
            },
            {
                "name": "Gualeguaychú",
                "countryCode": "AR"
            },
            {
                "name": "Hasenkamp",
                "countryCode": "AR"
            },
            {
                "name": "Hernández",
                "countryCode": "AR"
            },
            {
                "name": "Herrera",
                "countryCode": "AR"
            },
            {
                "name": "La Criolla",
                "countryCode": "AR"
            },
            {
                "name": "La Paz",
                "countryCode": "AR"
            },
            {
                "name": "Larroque",
                "countryCode": "AR"
            },
            {
                "name": "Los Charrúas",
                "countryCode": "AR"
            },
            {
                "name": "Los Conquistadores",
                "countryCode": "AR"
            },
            {
                "name": "Lucas González",
                "countryCode": "AR"
            },
            {
                "name": "Maciá",
                "countryCode": "AR"
            },
            {
                "name": "Nogoyá",
                "countryCode": "AR"
            },
            {
                "name": "Oro Verde",
                "countryCode": "AR"
            },
            {
                "name": "Paraná",
                "countryCode": "AR"
            },
            {
                "name": "Piedras Blancas",
                "countryCode": "AR"
            },
            {
                "name": "Pronunciamiento",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Ibicuy",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Yeruá",
                "countryCode": "AR"
            },
            {
                "name": "Rosario del Tala",
                "countryCode": "AR"
            },
            {
                "name": "San Benito",
                "countryCode": "AR"
            },
            {
                "name": "San Gustavo",
                "countryCode": "AR"
            },
            {
                "name": "San José de Feliciano",
                "countryCode": "AR"
            },
            {
                "name": "San Justo",
                "countryCode": "AR"
            },
            {
                "name": "San Salvador",
                "countryCode": "AR"
            },
            {
                "name": "Santa Ana",
                "countryCode": "AR"
            },
            {
                "name": "Santa Anita",
                "countryCode": "AR"
            },
            {
                "name": "Santa Elena",
                "countryCode": "AR"
            },
            {
                "name": "Sauce de Luna",
                "countryCode": "AR"
            },
            {
                "name": "Seguí",
                "countryCode": "AR"
            },
            {
                "name": "Tabossi",
                "countryCode": "AR"
            },
            {
                "name": "Ubajay",
                "countryCode": "AR"
            },
            {
                "name": "Urdinarrain",
                "countryCode": "AR"
            },
            {
                "name": "Viale",
                "countryCode": "AR"
            },
            {
                "name": "Victoria",
                "countryCode": "AR"
            },
            {
                "name": "Villa Elisa",
                "countryCode": "AR"
            },
            {
                "name": "Villa Hernandarias",
                "countryCode": "AR"
            },
            {
                "name": "Villa Mantero",
                "countryCode": "AR"
            },
            {
                "name": "Villa María Grande",
                "countryCode": "AR"
            },
            {
                "name": "Villa Paranacito",
                "countryCode": "AR"
            },
            {
                "name": "Villa Urquiza",
                "countryCode": "AR"
            },
            {
                "name": "Villa del Rosario",
                "countryCode": "AR"
            },
            {
                "name": "Villaguay",
                "countryCode": "AR"
            },
            {
                "name": "Arauco",
                "countryCode": "AR"
            },
            {
                "name": "Castro Barros",
                "countryCode": "AR"
            },
            {
                "name": "Chamical",
                "countryCode": "AR"
            },
            {
                "name": "Chilecito",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Arauco",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Lamadrid",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Independencia",
                "countryCode": "AR"
            },
            {
                "name": "La Rioja",
                "countryCode": "AR"
            },
            {
                "name": "Villa Bustos",
                "countryCode": "AR"
            },
            {
                "name": "Vinchina",
                "countryCode": "AR"
            },
            {
                "name": "Añatuya",
                "countryCode": "AR"
            },
            {
                "name": "Beltrán",
                "countryCode": "AR"
            },
            {
                "name": "Campo Gallo",
                "countryCode": "AR"
            },
            {
                "name": "Clodomira",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Dora",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Aguirre",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Banda",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Choya",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Guasayán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Loreto",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Moreno",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Robles",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Hondo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sarmiento",
                "countryCode": "AR"
            },
            {
                "name": "El Hoyo",
                "countryCode": "AR"
            },
            {
                "name": "La Banda",
                "countryCode": "AR"
            },
            {
                "name": "Los Juríes",
                "countryCode": "AR"
            },
            {
                "name": "Los Telares",
                "countryCode": "AR"
            },
            {
                "name": "Pampa de los Guanacos",
                "countryCode": "AR"
            },
            {
                "name": "Quimilí",
                "countryCode": "AR"
            },
            {
                "name": "San Pedro",
                "countryCode": "AR"
            },
            {
                "name": "Santiago del Estero",
                "countryCode": "AR"
            },
            {
                "name": "Sumampa",
                "countryCode": "AR"
            },
            {
                "name": "Suncho Corral",
                "countryCode": "AR"
            },
            {
                "name": "Termas de Río Hondo",
                "countryCode": "AR"
            },
            {
                "name": "Tintina",
                "countryCode": "AR"
            },
            {
                "name": "Villa Atamisqui",
                "countryCode": "AR"
            },
            {
                "name": "Villa General Mitre",
                "countryCode": "AR"
            },
            {
                "name": "Villa Ojo de Agua",
                "countryCode": "AR"
            },
            {
                "name": "Aviá Terai",
                "countryCode": "AR"
            },
            {
                "name": "Barranqueras",
                "countryCode": "AR"
            },
            {
                "name": "Basail",
                "countryCode": "AR"
            },
            {
                "name": "Campo Largo",
                "countryCode": "AR"
            },
            {
                "name": "Capitán Solari",
                "countryCode": "AR"
            },
            {
                "name": "Castelli",
                "countryCode": "AR"
            },
            {
                "name": "Charadai",
                "countryCode": "AR"
            },
            {
                "name": "Charata",
                "countryCode": "AR"
            },
            {
                "name": "Chorotis",
                "countryCode": "AR"
            },
            {
                "name": "Ciervo Petiso",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Benítez",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Elisa",
                "countryCode": "AR"
            },
            {
                "name": "Colonias Unidas",
                "countryCode": "AR"
            },
            {
                "name": "Concepción del Bermejo",
                "countryCode": "AR"
            },
            {
                "name": "Coronel Du Graty",
                "countryCode": "AR"
            },
            {
                "name": "Corzuela",
                "countryCode": "AR"
            },
            {
                "name": "Coté-Lai",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Almirante Brown",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Bermejo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Comandante Fernández",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Doce de Octubre",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Dos de Abril",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Donovan",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Güemes",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Independencia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Libertad",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Maipú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Nueve de Julio",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de O’Higgins",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Presidencia de la Plaza",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Quitilipi",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Fernando",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Lorenzo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sargento Cabral",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tapenagá",
                "countryCode": "AR"
            },
            {
                "name": "Fontana",
                "countryCode": "AR"
            },
            {
                "name": "Gancedo",
                "countryCode": "AR"
            },
            {
                "name": "General José de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "General Pinedo",
                "countryCode": "AR"
            },
            {
                "name": "General Vedia",
                "countryCode": "AR"
            },
            {
                "name": "Hermoso Campo",
                "countryCode": "AR"
            },
            {
                "name": "La Clotilde",
                "countryCode": "AR"
            },
            {
                "name": "La Eduvigis",
                "countryCode": "AR"
            },
            {
                "name": "La Escondida",
                "countryCode": "AR"
            },
            {
                "name": "La Leonesa",
                "countryCode": "AR"
            },
            {
                "name": "La Tigra",
                "countryCode": "AR"
            },
            {
                "name": "La Verde",
                "countryCode": "AR"
            },
            {
                "name": "Laguna Limpia",
                "countryCode": "AR"
            },
            {
                "name": "Lapachito",
                "countryCode": "AR"
            },
            {
                "name": "Las Breñas",
                "countryCode": "AR"
            },
            {
                "name": "Las Garcitas",
                "countryCode": "AR"
            },
            {
                "name": "Los Frentones",
                "countryCode": "AR"
            },
            {
                "name": "Machagai",
                "countryCode": "AR"
            },
            {
                "name": "Makallé",
                "countryCode": "AR"
            },
            {
                "name": "Margarita Belén",
                "countryCode": "AR"
            },
            {
                "name": "Napenay",
                "countryCode": "AR"
            },
            {
                "name": "Pampa Almirón",
                "countryCode": "AR"
            },
            {
                "name": "Pampa del Indio",
                "countryCode": "AR"
            },
            {
                "name": "Pampa del Infierno",
                "countryCode": "AR"
            },
            {
                "name": "Presidencia Roca",
                "countryCode": "AR"
            },
            {
                "name": "Presidencia Roque Sáenz Peña",
                "countryCode": "AR"
            },
            {
                "name": "Presidencia de la Plaza",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Bermejo",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Tirol",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Vilelas",
                "countryCode": "AR"
            },
            {
                "name": "Quitilipi",
                "countryCode": "AR"
            },
            {
                "name": "Resistencia",
                "countryCode": "AR"
            },
            {
                "name": "Samuhú",
                "countryCode": "AR"
            },
            {
                "name": "San Bernardo",
                "countryCode": "AR"
            },
            {
                "name": "Santa Sylvina",
                "countryCode": "AR"
            },
            {
                "name": "Taco Pozo",
                "countryCode": "AR"
            },
            {
                "name": "Tres Isletas",
                "countryCode": "AR"
            },
            {
                "name": "Villa Berthet",
                "countryCode": "AR"
            },
            {
                "name": "Villa Ángela",
                "countryCode": "AR"
            },
            {
                "name": "Albardón",
                "countryCode": "AR"
            },
            {
                "name": "Calingasta",
                "countryCode": "AR"
            },
            {
                "name": "Caucete",
                "countryCode": "AR"
            },
            {
                "name": "Chimbas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Albardón",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Angaco",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Calingasta",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Caucete",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Chimbas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Iglesia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Jáchal",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Nueve de Julio",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rawson",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rivadavia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Santa Lucía",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sarmiento",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Ullúm",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Zonda",
                "countryCode": "AR"
            },
            {
                "name": "Nueve de Julio",
                "countryCode": "AR"
            },
            {
                "name": "Pocito",
                "countryCode": "AR"
            },
            {
                "name": "San Agustín de Valle Fértil",
                "countryCode": "AR"
            },
            {
                "name": "San José de Jáchal",
                "countryCode": "AR"
            },
            {
                "name": "San Juan",
                "countryCode": "AR"
            },
            {
                "name": "San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "AR"
            },
            {
                "name": "Villa Basilio Nievas",
                "countryCode": "AR"
            },
            {
                "name": "Villa Paula de Sarmiento",
                "countryCode": "AR"
            },
            {
                "name": "Ancasti",
                "countryCode": "AR"
            },
            {
                "name": "Andalgalá",
                "countryCode": "AR"
            },
            {
                "name": "Antofagasta de la Sierra",
                "countryCode": "AR"
            },
            {
                "name": "Capayán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Ambato",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Ancasti",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Andalgalá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Antofagasta de la Sierra",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capayán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de El Alto",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Fray Mamerto Esquiú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Paz",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Pomán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Santa María",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Santa Rosa",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tinogasta",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Valle Viejo",
                "countryCode": "AR"
            },
            {
                "name": "El Rodeo",
                "countryCode": "AR"
            },
            {
                "name": "Fiambalá",
                "countryCode": "AR"
            },
            {
                "name": "Hualfín",
                "countryCode": "AR"
            },
            {
                "name": "Huillapima",
                "countryCode": "AR"
            },
            {
                "name": "Icaño",
                "countryCode": "AR"
            },
            {
                "name": "La Puerta de San José",
                "countryCode": "AR"
            },
            {
                "name": "Londres",
                "countryCode": "AR"
            },
            {
                "name": "Los Altos",
                "countryCode": "AR"
            },
            {
                "name": "Los Varela",
                "countryCode": "AR"
            },
            {
                "name": "Mutquín",
                "countryCode": "AR"
            },
            {
                "name": "Pomán",
                "countryCode": "AR"
            },
            {
                "name": "Puerta de Corral Quemado",
                "countryCode": "AR"
            },
            {
                "name": "Recreo",
                "countryCode": "AR"
            },
            {
                "name": "San Antonio",
                "countryCode": "AR"
            },
            {
                "name": "San Fernando del Valle de Catamarca",
                "countryCode": "AR"
            },
            {
                "name": "Santa María",
                "countryCode": "AR"
            },
            {
                "name": "Tinogasta",
                "countryCode": "AR"
            },
            {
                "name": "Alpachiri",
                "countryCode": "AR"
            },
            {
                "name": "Alta Italia",
                "countryCode": "AR"
            },
            {
                "name": "Anguil",
                "countryCode": "AR"
            },
            {
                "name": "Arata",
                "countryCode": "AR"
            },
            {
                "name": "Bernardo Larroudé",
                "countryCode": "AR"
            },
            {
                "name": "Bernasconi",
                "countryCode": "AR"
            },
            {
                "name": "Caleufú",
                "countryCode": "AR"
            },
            {
                "name": "Catriló",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Barón",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Caleu-Caleu",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Toay",
                "countryCode": "AR"
            },
            {
                "name": "Doblas",
                "countryCode": "AR"
            },
            {
                "name": "Eduardo Castex",
                "countryCode": "AR"
            },
            {
                "name": "Embajador Martini",
                "countryCode": "AR"
            },
            {
                "name": "General Acha",
                "countryCode": "AR"
            },
            {
                "name": "General Manuel J. Campos",
                "countryCode": "AR"
            },
            {
                "name": "General Pico",
                "countryCode": "AR"
            },
            {
                "name": "General San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Guatraché",
                "countryCode": "AR"
            },
            {
                "name": "Ingeniero Luiggi",
                "countryCode": "AR"
            },
            {
                "name": "Intendente Alvear",
                "countryCode": "AR"
            },
            {
                "name": "Jacinto Arauz",
                "countryCode": "AR"
            },
            {
                "name": "La Adela",
                "countryCode": "AR"
            },
            {
                "name": "La Maruja",
                "countryCode": "AR"
            },
            {
                "name": "Lonquimay",
                "countryCode": "AR"
            },
            {
                "name": "Macachín",
                "countryCode": "AR"
            },
            {
                "name": "Miguel Riglos",
                "countryCode": "AR"
            },
            {
                "name": "Parera",
                "countryCode": "AR"
            },
            {
                "name": "Quemú Quemú",
                "countryCode": "AR"
            },
            {
                "name": "Rancul",
                "countryCode": "AR"
            },
            {
                "name": "Realicó",
                "countryCode": "AR"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "AR"
            },
            {
                "name": "Telén",
                "countryCode": "AR"
            },
            {
                "name": "Trenel",
                "countryCode": "AR"
            },
            {
                "name": "Uriburu",
                "countryCode": "AR"
            },
            {
                "name": "Veinticinco de Mayo",
                "countryCode": "AR"
            },
            {
                "name": "Victorica",
                "countryCode": "AR"
            },
            {
                "name": "Winifreda",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Alvear",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Godoy Cruz",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Guaymallén",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Paz",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Las Heras",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Lavalle",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Luján",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Maipú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Malargüe",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rivadavia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Carlos",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Rafael",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Santa Rosa",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tunuyán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tupungato",
                "countryCode": "AR"
            },
            {
                "name": "Godoy Cruz",
                "countryCode": "AR"
            },
            {
                "name": "Las Heras",
                "countryCode": "AR"
            },
            {
                "name": "Mendoza",
                "countryCode": "AR"
            },
            {
                "name": "San Martín",
                "countryCode": "AR"
            },
            {
                "name": "San Rafael",
                "countryCode": "AR"
            },
            {
                "name": "Alba Posse",
                "countryCode": "AR"
            },
            {
                "name": "Almafuerte",
                "countryCode": "AR"
            },
            {
                "name": "Aristóbulo del Valle",
                "countryCode": "AR"
            },
            {
                "name": "Arroyo del Medio",
                "countryCode": "AR"
            },
            {
                "name": "Azara",
                "countryCode": "AR"
            },
            {
                "name": "Bernardo de Irigoyen",
                "countryCode": "AR"
            },
            {
                "name": "Bonpland",
                "countryCode": "AR"
            },
            {
                "name": "Campo Grande",
                "countryCode": "AR"
            },
            {
                "name": "Campo Ramón",
                "countryCode": "AR"
            },
            {
                "name": "Campo Viera",
                "countryCode": "AR"
            },
            {
                "name": "Candelaria",
                "countryCode": "AR"
            },
            {
                "name": "Capioví",
                "countryCode": "AR"
            },
            {
                "name": "Caraguatay",
                "countryCode": "AR"
            },
            {
                "name": "Cerro Azul",
                "countryCode": "AR"
            },
            {
                "name": "Cerro Corá",
                "countryCode": "AR"
            },
            {
                "name": "Colonia Aurora",
                "countryCode": "AR"
            },
            {
                "name": "Concepción de la Sierra",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Apóstoles",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cainguás",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Candelaria",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Concepción de la Sierra",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Eldorado",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Manuel Belgrano",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Guaraní",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Iguazú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Leandro N. Alem",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Libertador General San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Montecarlo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Oberá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Ignacio",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Javier",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Pedro",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Veinticinco de Mayo",
                "countryCode": "AR"
            },
            {
                "name": "Dos Arroyos",
                "countryCode": "AR"
            },
            {
                "name": "Dos de Mayo",
                "countryCode": "AR"
            },
            {
                "name": "El Alcázar",
                "countryCode": "AR"
            },
            {
                "name": "El Soberbio",
                "countryCode": "AR"
            },
            {
                "name": "Florentino Ameghino",
                "countryCode": "AR"
            },
            {
                "name": "Garuhapé",
                "countryCode": "AR"
            },
            {
                "name": "Garupá",
                "countryCode": "AR"
            },
            {
                "name": "General Alvear",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Roca",
                "countryCode": "AR"
            },
            {
                "name": "Guaraní",
                "countryCode": "AR"
            },
            {
                "name": "Jardín América",
                "countryCode": "AR"
            },
            {
                "name": "Loreto",
                "countryCode": "AR"
            },
            {
                "name": "Los Helechos",
                "countryCode": "AR"
            },
            {
                "name": "Mojón Grande",
                "countryCode": "AR"
            },
            {
                "name": "Montecarlo",
                "countryCode": "AR"
            },
            {
                "name": "Mártires",
                "countryCode": "AR"
            },
            {
                "name": "Oberá",
                "countryCode": "AR"
            },
            {
                "name": "Panambí",
                "countryCode": "AR"
            },
            {
                "name": "Picada Gobernador López",
                "countryCode": "AR"
            },
            {
                "name": "Posadas",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Eldorado",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Esperanza",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Iguazú",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Leoni",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Libertad",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Piray",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Rico",
                "countryCode": "AR"
            },
            {
                "name": "Ruiz de Montoya",
                "countryCode": "AR"
            },
            {
                "name": "San José",
                "countryCode": "AR"
            },
            {
                "name": "San Pedro",
                "countryCode": "AR"
            },
            {
                "name": "San Vicente",
                "countryCode": "AR"
            },
            {
                "name": "Santa María",
                "countryCode": "AR"
            },
            {
                "name": "Santo Pipó",
                "countryCode": "AR"
            },
            {
                "name": "Tres Capones",
                "countryCode": "AR"
            },
            {
                "name": "Veinticinco de Mayo",
                "countryCode": "AR"
            },
            {
                "name": "Wanda",
                "countryCode": "AR"
            },
            {
                "name": "Clorinda",
                "countryCode": "AR"
            },
            {
                "name": "Comandante Fontana",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Pilcomayo",
                "countryCode": "AR"
            },
            {
                "name": "El Colorado",
                "countryCode": "AR"
            },
            {
                "name": "Estanislao del Campo",
                "countryCode": "AR"
            },
            {
                "name": "Formosa",
                "countryCode": "AR"
            },
            {
                "name": "General Enrique Mosconi",
                "countryCode": "AR"
            },
            {
                "name": "Herradura",
                "countryCode": "AR"
            },
            {
                "name": "Ibarreta",
                "countryCode": "AR"
            },
            {
                "name": "Ingeniero Guillermo N. Juárez",
                "countryCode": "AR"
            },
            {
                "name": "Laguna Naick-Neck",
                "countryCode": "AR"
            },
            {
                "name": "Laguna Yema",
                "countryCode": "AR"
            },
            {
                "name": "Las Lomitas",
                "countryCode": "AR"
            },
            {
                "name": "Palo Santo",
                "countryCode": "AR"
            },
            {
                "name": "Pirané",
                "countryCode": "AR"
            },
            {
                "name": "Pozo del Tigre",
                "countryCode": "AR"
            },
            {
                "name": "Riacho Eh-Eh",
                "countryCode": "AR"
            },
            {
                "name": "San Francisco de Laishí",
                "countryCode": "AR"
            },
            {
                "name": "Villa Escolar",
                "countryCode": "AR"
            },
            {
                "name": "Villa General Guemes",
                "countryCode": "AR"
            },
            {
                "name": "Aluminé",
                "countryCode": "AR"
            },
            {
                "name": "Andacollo",
                "countryCode": "AR"
            },
            {
                "name": "Añelo",
                "countryCode": "AR"
            },
            {
                "name": "Barrancas",
                "countryCode": "AR"
            },
            {
                "name": "Buta Ranquil",
                "countryCode": "AR"
            },
            {
                "name": "Centenario",
                "countryCode": "AR"
            },
            {
                "name": "Chos Malal",
                "countryCode": "AR"
            },
            {
                "name": "Cutral-Có",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Aluminé",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Añelo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Catán-Lil",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Chos-Malal",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Collón-Curá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Confluencia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Lácar",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Minas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Zapala",
                "countryCode": "AR"
            },
            {
                "name": "El Huecú",
                "countryCode": "AR"
            },
            {
                "name": "Junín de los Andes",
                "countryCode": "AR"
            },
            {
                "name": "Las Coloradas",
                "countryCode": "AR"
            },
            {
                "name": "Las Lajas",
                "countryCode": "AR"
            },
            {
                "name": "Las Ovejas",
                "countryCode": "AR"
            },
            {
                "name": "Loncopué",
                "countryCode": "AR"
            },
            {
                "name": "Mariano Moreno",
                "countryCode": "AR"
            },
            {
                "name": "Neuquén",
                "countryCode": "AR"
            },
            {
                "name": "Picún Leufú",
                "countryCode": "AR"
            },
            {
                "name": "Piedra del Águila",
                "countryCode": "AR"
            },
            {
                "name": "Plaza Huincul",
                "countryCode": "AR"
            },
            {
                "name": "Plottier",
                "countryCode": "AR"
            },
            {
                "name": "San Martín de los Andes",
                "countryCode": "AR"
            },
            {
                "name": "Senillosa",
                "countryCode": "AR"
            },
            {
                "name": "Villa La Angostura",
                "countryCode": "AR"
            },
            {
                "name": "Vista Alegre",
                "countryCode": "AR"
            },
            {
                "name": "Zapala",
                "countryCode": "AR"
            },
            {
                "name": "Allen",
                "countryCode": "AR"
            },
            {
                "name": "Catriel",
                "countryCode": "AR"
            },
            {
                "name": "Cervantes",
                "countryCode": "AR"
            },
            {
                "name": "Chichinales",
                "countryCode": "AR"
            },
            {
                "name": "Chimpay",
                "countryCode": "AR"
            },
            {
                "name": "Choele Choel",
                "countryCode": "AR"
            },
            {
                "name": "Cinco Saltos",
                "countryCode": "AR"
            },
            {
                "name": "Cipolletti",
                "countryCode": "AR"
            },
            {
                "name": "Comallo",
                "countryCode": "AR"
            },
            {
                "name": "Contraalmirante Cordero",
                "countryCode": "AR"
            },
            {
                "name": "Coronel Belisle",
                "countryCode": "AR"
            },
            {
                "name": "Darwin",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Avellaneda",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Veinticinco de Mayo",
                "countryCode": "AR"
            },
            {
                "name": "El Bolsón",
                "countryCode": "AR"
            },
            {
                "name": "El Cuy",
                "countryCode": "AR"
            },
            {
                "name": "Fray Luis Beltrán",
                "countryCode": "AR"
            },
            {
                "name": "General Conesa",
                "countryCode": "AR"
            },
            {
                "name": "General Enrique Godoy",
                "countryCode": "AR"
            },
            {
                "name": "General Fernández Oro",
                "countryCode": "AR"
            },
            {
                "name": "General Roca",
                "countryCode": "AR"
            },
            {
                "name": "Ingeniero Jacobacci",
                "countryCode": "AR"
            },
            {
                "name": "Ingeniero Luis A. Huergo",
                "countryCode": "AR"
            },
            {
                "name": "Lamarque",
                "countryCode": "AR"
            },
            {
                "name": "Los Menucos",
                "countryCode": "AR"
            },
            {
                "name": "Mainque",
                "countryCode": "AR"
            },
            {
                "name": "Maquinchao",
                "countryCode": "AR"
            },
            {
                "name": "Pilcaniyeu",
                "countryCode": "AR"
            },
            {
                "name": "Río Colorado",
                "countryCode": "AR"
            },
            {
                "name": "San Antonio Oeste",
                "countryCode": "AR"
            },
            {
                "name": "San Carlos de Bariloche",
                "countryCode": "AR"
            },
            {
                "name": "Sierra Colorada",
                "countryCode": "AR"
            },
            {
                "name": "Sierra Grande",
                "countryCode": "AR"
            },
            {
                "name": "Valcheta",
                "countryCode": "AR"
            },
            {
                "name": "Viedma",
                "countryCode": "AR"
            },
            {
                "name": "Villa Regina",
                "countryCode": "AR"
            },
            {
                "name": "Ñorquinco",
                "countryCode": "AR"
            },
            {
                "name": "Armstrong",
                "countryCode": "AR"
            },
            {
                "name": "Arroyo Seco",
                "countryCode": "AR"
            },
            {
                "name": "Arrufó",
                "countryCode": "AR"
            },
            {
                "name": "Avellaneda",
                "countryCode": "AR"
            },
            {
                "name": "Bella Italia",
                "countryCode": "AR"
            },
            {
                "name": "Calchaquí",
                "countryCode": "AR"
            },
            {
                "name": "Capitán Bermúdez",
                "countryCode": "AR"
            },
            {
                "name": "Carcarañá",
                "countryCode": "AR"
            },
            {
                "name": "Casilda",
                "countryCode": "AR"
            },
            {
                "name": "Cañada de Gómez",
                "countryCode": "AR"
            },
            {
                "name": "Ceres",
                "countryCode": "AR"
            },
            {
                "name": "Chañar Ladeado",
                "countryCode": "AR"
            },
            {
                "name": "Coronda",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Belgrano",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Caseros",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Castellanos",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Constitución",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Nueve de Julio",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Cristóbal",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Javier",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Justo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Lorenzo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Vera",
                "countryCode": "AR"
            },
            {
                "name": "El Trébol",
                "countryCode": "AR"
            },
            {
                "name": "Esperanza",
                "countryCode": "AR"
            },
            {
                "name": "Firmat",
                "countryCode": "AR"
            },
            {
                "name": "Fray Luis A. Beltrán",
                "countryCode": "AR"
            },
            {
                "name": "Funes",
                "countryCode": "AR"
            },
            {
                "name": "Gato Colorado",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Gálvez",
                "countryCode": "AR"
            },
            {
                "name": "Granadero Baigorria",
                "countryCode": "AR"
            },
            {
                "name": "Gálvez",
                "countryCode": "AR"
            },
            {
                "name": "Helvecia",
                "countryCode": "AR"
            },
            {
                "name": "Hersilia",
                "countryCode": "AR"
            },
            {
                "name": "Iriondo Department",
                "countryCode": "AR"
            },
            {
                "name": "Laguna Paiva",
                "countryCode": "AR"
            },
            {
                "name": "Las Parejas",
                "countryCode": "AR"
            },
            {
                "name": "Las Rosas",
                "countryCode": "AR"
            },
            {
                "name": "Las Toscas",
                "countryCode": "AR"
            },
            {
                "name": "Los Laureles",
                "countryCode": "AR"
            },
            {
                "name": "Malabrigo",
                "countryCode": "AR"
            },
            {
                "name": "Melincué",
                "countryCode": "AR"
            },
            {
                "name": "Pérez",
                "countryCode": "AR"
            },
            {
                "name": "Rafaela",
                "countryCode": "AR"
            },
            {
                "name": "Reconquista",
                "countryCode": "AR"
            },
            {
                "name": "Recreo",
                "countryCode": "AR"
            },
            {
                "name": "Roldán",
                "countryCode": "AR"
            },
            {
                "name": "Rosario",
                "countryCode": "AR"
            },
            {
                "name": "Rufino",
                "countryCode": "AR"
            },
            {
                "name": "San Carlos Centro",
                "countryCode": "AR"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "AR"
            },
            {
                "name": "San Javier",
                "countryCode": "AR"
            },
            {
                "name": "San Jorge",
                "countryCode": "AR"
            },
            {
                "name": "San Justo",
                "countryCode": "AR"
            },
            {
                "name": "Santa Fe",
                "countryCode": "AR"
            },
            {
                "name": "Santo Tomé",
                "countryCode": "AR"
            },
            {
                "name": "Sastre",
                "countryCode": "AR"
            },
            {
                "name": "Sunchales",
                "countryCode": "AR"
            },
            {
                "name": "Tacuarendí",
                "countryCode": "AR"
            },
            {
                "name": "Tostado",
                "countryCode": "AR"
            },
            {
                "name": "Totoras",
                "countryCode": "AR"
            },
            {
                "name": "Venado Tuerto",
                "countryCode": "AR"
            },
            {
                "name": "Vera",
                "countryCode": "AR"
            },
            {
                "name": "Villa Cañás",
                "countryCode": "AR"
            },
            {
                "name": "Villa Constitución",
                "countryCode": "AR"
            },
            {
                "name": "Villa Mugueta",
                "countryCode": "AR"
            },
            {
                "name": "Villa Ocampo",
                "countryCode": "AR"
            },
            {
                "name": "Villa Trinidad",
                "countryCode": "AR"
            },
            {
                "name": "Aguilares",
                "countryCode": "AR"
            },
            {
                "name": "Alderetes",
                "countryCode": "AR"
            },
            {
                "name": "Bella Vista",
                "countryCode": "AR"
            },
            {
                "name": "Burruyacú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Burruyacú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cruz Alta",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Famaillá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Graneros",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de La Cocha",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Lules",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Monteros",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Chico",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Simoca",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Trancas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Yerba Buena",
                "countryCode": "AR"
            },
            {
                "name": "Famaillá",
                "countryCode": "AR"
            },
            {
                "name": "Graneros",
                "countryCode": "AR"
            },
            {
                "name": "La Cocha",
                "countryCode": "AR"
            },
            {
                "name": "Monteros",
                "countryCode": "AR"
            },
            {
                "name": "San Miguel de Tucumán",
                "countryCode": "AR"
            },
            {
                "name": "Simoca",
                "countryCode": "AR"
            },
            {
                "name": "Tafí Viejo",
                "countryCode": "AR"
            },
            {
                "name": "Tafí del Valle",
                "countryCode": "AR"
            },
            {
                "name": "Trancas",
                "countryCode": "AR"
            },
            {
                "name": "Yerba Buena",
                "countryCode": "AR"
            },
            {
                "name": "Alto Río Senguer",
                "countryCode": "AR"
            },
            {
                "name": "Camarones",
                "countryCode": "AR"
            },
            {
                "name": "Comodoro Rivadavia",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Biedma",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cushamen",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Escalante",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Florentino Ameghino",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Futaleufú",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Gaimán",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Gastre",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Languiñeo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Mártires",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Paso de Indios",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rawson",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Senguerr",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sarmiento",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tehuelches",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Telsen",
                "countryCode": "AR"
            },
            {
                "name": "Dolavón",
                "countryCode": "AR"
            },
            {
                "name": "El Maitén",
                "countryCode": "AR"
            },
            {
                "name": "Esquel",
                "countryCode": "AR"
            },
            {
                "name": "Gaimán",
                "countryCode": "AR"
            },
            {
                "name": "Gastre",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Costa",
                "countryCode": "AR"
            },
            {
                "name": "Hoyo de Epuyén",
                "countryCode": "AR"
            },
            {
                "name": "José de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Lago Puelo",
                "countryCode": "AR"
            },
            {
                "name": "Las Plumas",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Madryn",
                "countryCode": "AR"
            },
            {
                "name": "Rada Tilly",
                "countryCode": "AR"
            },
            {
                "name": "Rawson",
                "countryCode": "AR"
            },
            {
                "name": "Río Mayo",
                "countryCode": "AR"
            },
            {
                "name": "Río Pico",
                "countryCode": "AR"
            },
            {
                "name": "Sarmiento",
                "countryCode": "AR"
            },
            {
                "name": "Tecka",
                "countryCode": "AR"
            },
            {
                "name": "Trelew",
                "countryCode": "AR"
            },
            {
                "name": "Trevelin",
                "countryCode": "AR"
            },
            {
                "name": "Río Grande",
                "countryCode": "AR"
            },
            {
                "name": "Tolhuin",
                "countryCode": "AR"
            },
            {
                "name": "Ushuaia",
                "countryCode": "AR"
            },
            {
                "name": "Alvear",
                "countryCode": "AR"
            },
            {
                "name": "Berón de Astrada",
                "countryCode": "AR"
            },
            {
                "name": "Bonpland",
                "countryCode": "AR"
            },
            {
                "name": "Chavarría",
                "countryCode": "AR"
            },
            {
                "name": "Concepción",
                "countryCode": "AR"
            },
            {
                "name": "Corrientes",
                "countryCode": "AR"
            },
            {
                "name": "Cruz de los Milagros",
                "countryCode": "AR"
            },
            {
                "name": "Curuzú Cuatiá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Bella Vista",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Berón de Astrada",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Capital",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Concepción",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Curuzú Cuatiá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Empedrado",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Esquina",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Alvear",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Paz",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Goya",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Itatí",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Ituzaingó",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Lavalle",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Mburucuyá",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Mercedes",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Monte Caseros",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Paso de los Libres",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Saladas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Cosme",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Luis del Palmar",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Miguel",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Roque",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Santo Tomé",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sauce",
                "countryCode": "AR"
            },
            {
                "name": "Empedrado",
                "countryCode": "AR"
            },
            {
                "name": "Esquina",
                "countryCode": "AR"
            },
            {
                "name": "Felipe Yofré",
                "countryCode": "AR"
            },
            {
                "name": "Garruchos",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Juan E. Martínez",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Virasora",
                "countryCode": "AR"
            },
            {
                "name": "Goya",
                "countryCode": "AR"
            },
            {
                "name": "Herlitzka",
                "countryCode": "AR"
            },
            {
                "name": "Itatí",
                "countryCode": "AR"
            },
            {
                "name": "Ituzaingó",
                "countryCode": "AR"
            },
            {
                "name": "Itá Ibaté",
                "countryCode": "AR"
            },
            {
                "name": "Juan Pujol",
                "countryCode": "AR"
            },
            {
                "name": "La Cruz",
                "countryCode": "AR"
            },
            {
                "name": "Libertad",
                "countryCode": "AR"
            },
            {
                "name": "Lomas de Vallejos",
                "countryCode": "AR"
            },
            {
                "name": "Loreto",
                "countryCode": "AR"
            },
            {
                "name": "Mariano I. Loza",
                "countryCode": "AR"
            },
            {
                "name": "Mburucuyá",
                "countryCode": "AR"
            },
            {
                "name": "Mercedes",
                "countryCode": "AR"
            },
            {
                "name": "Mocoretá",
                "countryCode": "AR"
            },
            {
                "name": "Monte Caseros",
                "countryCode": "AR"
            },
            {
                "name": "Nuestra Señora del Rosario de Caa Catí",
                "countryCode": "AR"
            },
            {
                "name": "Nueve de Julio",
                "countryCode": "AR"
            },
            {
                "name": "Palmar Grande",
                "countryCode": "AR"
            },
            {
                "name": "Paso de la Patria",
                "countryCode": "AR"
            },
            {
                "name": "Paso de los Libres",
                "countryCode": "AR"
            },
            {
                "name": "Pedro R. Fernández",
                "countryCode": "AR"
            },
            {
                "name": "Perugorría",
                "countryCode": "AR"
            },
            {
                "name": "Pueblo Libertador",
                "countryCode": "AR"
            },
            {
                "name": "Riachuelo",
                "countryCode": "AR"
            },
            {
                "name": "Saladas",
                "countryCode": "AR"
            },
            {
                "name": "San Carlos",
                "countryCode": "AR"
            },
            {
                "name": "San Cosme",
                "countryCode": "AR"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "AR"
            },
            {
                "name": "San Luis del Palmar",
                "countryCode": "AR"
            },
            {
                "name": "San Miguel",
                "countryCode": "AR"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "AR"
            },
            {
                "name": "Santo Tomé",
                "countryCode": "AR"
            },
            {
                "name": "Yapeyú",
                "countryCode": "AR"
            },
            {
                "name": "Yataity Calle",
                "countryCode": "AR"
            },
            {
                "name": "Achiras",
                "countryCode": "AR"
            },
            {
                "name": "Adelia María",
                "countryCode": "AR"
            },
            {
                "name": "Agua de Oro",
                "countryCode": "AR"
            },
            {
                "name": "Alejandro Roca",
                "countryCode": "AR"
            },
            {
                "name": "Alejo Ledesma",
                "countryCode": "AR"
            },
            {
                "name": "Almafuerte",
                "countryCode": "AR"
            },
            {
                "name": "Alta Gracia",
                "countryCode": "AR"
            },
            {
                "name": "Altos de Chipión",
                "countryCode": "AR"
            },
            {
                "name": "Arias",
                "countryCode": "AR"
            },
            {
                "name": "Arroyito",
                "countryCode": "AR"
            },
            {
                "name": "Arroyo Cabral",
                "countryCode": "AR"
            },
            {
                "name": "Balnearia",
                "countryCode": "AR"
            },
            {
                "name": "Bell Ville",
                "countryCode": "AR"
            },
            {
                "name": "Berrotarán",
                "countryCode": "AR"
            },
            {
                "name": "Brinkmann",
                "countryCode": "AR"
            },
            {
                "name": "Buchardo",
                "countryCode": "AR"
            },
            {
                "name": "Camilo Aldao",
                "countryCode": "AR"
            },
            {
                "name": "Canals",
                "countryCode": "AR"
            },
            {
                "name": "Capilla del Monte",
                "countryCode": "AR"
            },
            {
                "name": "Carnerillo",
                "countryCode": "AR"
            },
            {
                "name": "Carrilobo",
                "countryCode": "AR"
            },
            {
                "name": "Cavanagh",
                "countryCode": "AR"
            },
            {
                "name": "Cañada de Luque",
                "countryCode": "AR"
            },
            {
                "name": "Charras",
                "countryCode": "AR"
            },
            {
                "name": "Chazón",
                "countryCode": "AR"
            },
            {
                "name": "Cintra",
                "countryCode": "AR"
            },
            {
                "name": "Colonia La Tordilla",
                "countryCode": "AR"
            },
            {
                "name": "Colonia San Bartolomé",
                "countryCode": "AR"
            },
            {
                "name": "Coronel Baigorria",
                "countryCode": "AR"
            },
            {
                "name": "Coronel Moldes",
                "countryCode": "AR"
            },
            {
                "name": "Corral de Bustos",
                "countryCode": "AR"
            },
            {
                "name": "Corralito",
                "countryCode": "AR"
            },
            {
                "name": "Cosquín",
                "countryCode": "AR"
            },
            {
                "name": "Costa Sacate",
                "countryCode": "AR"
            },
            {
                "name": "Cruz Alta",
                "countryCode": "AR"
            },
            {
                "name": "Cruz del Eje",
                "countryCode": "AR"
            },
            {
                "name": "Cuesta Blanca",
                "countryCode": "AR"
            },
            {
                "name": "Córdoba",
                "countryCode": "AR"
            },
            {
                "name": "Dalmacio Vélez Sársfield",
                "countryCode": "AR"
            },
            {
                "name": "Del Campillo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Calamuchita",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Colón",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cruz del Eje",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General Roca",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de General San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Juárez Celman",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Marcos Juárez",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Minas",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Presidente Roque Sáenz Peña",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Cuarto",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Primero",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Seco",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Segundo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Alberto",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Javier",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de San Justo",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Sobremonte",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Totoral",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tulumba",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Unión",
                "countryCode": "AR"
            },
            {
                "name": "Despeñaderos",
                "countryCode": "AR"
            },
            {
                "name": "Devoto",
                "countryCode": "AR"
            },
            {
                "name": "Deán Funes",
                "countryCode": "AR"
            },
            {
                "name": "El Arañado",
                "countryCode": "AR"
            },
            {
                "name": "El Tío",
                "countryCode": "AR"
            },
            {
                "name": "Elena",
                "countryCode": "AR"
            },
            {
                "name": "Embalse",
                "countryCode": "AR"
            },
            {
                "name": "Etruria",
                "countryCode": "AR"
            },
            {
                "name": "General Baldissera",
                "countryCode": "AR"
            },
            {
                "name": "General Cabrera",
                "countryCode": "AR"
            },
            {
                "name": "General Levalle",
                "countryCode": "AR"
            },
            {
                "name": "General Roca",
                "countryCode": "AR"
            },
            {
                "name": "Guatimozín",
                "countryCode": "AR"
            },
            {
                "name": "Hernando",
                "countryCode": "AR"
            },
            {
                "name": "Huanchillas",
                "countryCode": "AR"
            },
            {
                "name": "Huerta Grande",
                "countryCode": "AR"
            },
            {
                "name": "Huinca Renancó",
                "countryCode": "AR"
            },
            {
                "name": "Idiazábal",
                "countryCode": "AR"
            },
            {
                "name": "Inriville",
                "countryCode": "AR"
            },
            {
                "name": "Isla Verde",
                "countryCode": "AR"
            },
            {
                "name": "Italó",
                "countryCode": "AR"
            },
            {
                "name": "James Craik",
                "countryCode": "AR"
            },
            {
                "name": "Jesús María",
                "countryCode": "AR"
            },
            {
                "name": "Justiniano Posse",
                "countryCode": "AR"
            },
            {
                "name": "La Calera",
                "countryCode": "AR"
            },
            {
                "name": "La Carlota",
                "countryCode": "AR"
            },
            {
                "name": "La Cesira",
                "countryCode": "AR"
            },
            {
                "name": "La Cumbre",
                "countryCode": "AR"
            },
            {
                "name": "La Falda",
                "countryCode": "AR"
            },
            {
                "name": "La Francia",
                "countryCode": "AR"
            },
            {
                "name": "La Granja",
                "countryCode": "AR"
            },
            {
                "name": "La Para",
                "countryCode": "AR"
            },
            {
                "name": "La Playosa",
                "countryCode": "AR"
            },
            {
                "name": "Laborde",
                "countryCode": "AR"
            },
            {
                "name": "Laboulaye",
                "countryCode": "AR"
            },
            {
                "name": "Laguna Larga",
                "countryCode": "AR"
            },
            {
                "name": "Las Acequias",
                "countryCode": "AR"
            },
            {
                "name": "Las Higueras",
                "countryCode": "AR"
            },
            {
                "name": "Las Junturas",
                "countryCode": "AR"
            },
            {
                "name": "Las Perdices",
                "countryCode": "AR"
            },
            {
                "name": "Las Varas",
                "countryCode": "AR"
            },
            {
                "name": "Las Varillas",
                "countryCode": "AR"
            },
            {
                "name": "Leones",
                "countryCode": "AR"
            },
            {
                "name": "Los Cocos",
                "countryCode": "AR"
            },
            {
                "name": "Los Cóndores",
                "countryCode": "AR"
            },
            {
                "name": "Los Surgentes",
                "countryCode": "AR"
            },
            {
                "name": "Malagueño",
                "countryCode": "AR"
            },
            {
                "name": "Malvinas Argentinas",
                "countryCode": "AR"
            },
            {
                "name": "Marcos Juárez",
                "countryCode": "AR"
            },
            {
                "name": "Marull",
                "countryCode": "AR"
            },
            {
                "name": "Mattaldi",
                "countryCode": "AR"
            },
            {
                "name": "Mendiolaza",
                "countryCode": "AR"
            },
            {
                "name": "Mina Clavero",
                "countryCode": "AR"
            },
            {
                "name": "Miramar",
                "countryCode": "AR"
            },
            {
                "name": "Monte Buey",
                "countryCode": "AR"
            },
            {
                "name": "Monte Cristo",
                "countryCode": "AR"
            },
            {
                "name": "Monte Maíz",
                "countryCode": "AR"
            },
            {
                "name": "Morrison",
                "countryCode": "AR"
            },
            {
                "name": "Morteros",
                "countryCode": "AR"
            },
            {
                "name": "Noetinger",
                "countryCode": "AR"
            },
            {
                "name": "Obispo Trejo",
                "countryCode": "AR"
            },
            {
                "name": "Oliva",
                "countryCode": "AR"
            },
            {
                "name": "Oncativo",
                "countryCode": "AR"
            },
            {
                "name": "Ordóñez",
                "countryCode": "AR"
            },
            {
                "name": "Pascanas",
                "countryCode": "AR"
            },
            {
                "name": "Pasco",
                "countryCode": "AR"
            },
            {
                "name": "Pilar",
                "countryCode": "AR"
            },
            {
                "name": "Piquillín",
                "countryCode": "AR"
            },
            {
                "name": "Porteña",
                "countryCode": "AR"
            },
            {
                "name": "Pozo del Molle",
                "countryCode": "AR"
            },
            {
                "name": "Quilino",
                "countryCode": "AR"
            },
            {
                "name": "Río Ceballos",
                "countryCode": "AR"
            },
            {
                "name": "Río Cuarto",
                "countryCode": "AR"
            },
            {
                "name": "Río Segundo",
                "countryCode": "AR"
            },
            {
                "name": "Río Tercero",
                "countryCode": "AR"
            },
            {
                "name": "Sacanta",
                "countryCode": "AR"
            },
            {
                "name": "Saldán",
                "countryCode": "AR"
            },
            {
                "name": "Salsacate",
                "countryCode": "AR"
            },
            {
                "name": "Salsipuedes",
                "countryCode": "AR"
            },
            {
                "name": "Sampacho",
                "countryCode": "AR"
            },
            {
                "name": "San Agustín",
                "countryCode": "AR"
            },
            {
                "name": "San Antonio de Litín",
                "countryCode": "AR"
            },
            {
                "name": "San Basilio",
                "countryCode": "AR"
            },
            {
                "name": "San Carlos",
                "countryCode": "AR"
            },
            {
                "name": "San Francisco",
                "countryCode": "AR"
            },
            {
                "name": "San Francisco del Chañar",
                "countryCode": "AR"
            },
            {
                "name": "San José de la Dormida",
                "countryCode": "AR"
            },
            {
                "name": "Santa Eufemia",
                "countryCode": "AR"
            },
            {
                "name": "Santa Magdalena",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa de Calamuchita",
                "countryCode": "AR"
            },
            {
                "name": "Santa Rosa de Río Primero",
                "countryCode": "AR"
            },
            {
                "name": "Santiago Temple",
                "countryCode": "AR"
            },
            {
                "name": "Saturnino M. Laspiur",
                "countryCode": "AR"
            },
            {
                "name": "Sebastián Elcano",
                "countryCode": "AR"
            },
            {
                "name": "Serrano",
                "countryCode": "AR"
            },
            {
                "name": "Serrezuela",
                "countryCode": "AR"
            },
            {
                "name": "Tancacha",
                "countryCode": "AR"
            },
            {
                "name": "Ticino",
                "countryCode": "AR"
            },
            {
                "name": "Toledo",
                "countryCode": "AR"
            },
            {
                "name": "Tío Pujio",
                "countryCode": "AR"
            },
            {
                "name": "Ucacha",
                "countryCode": "AR"
            },
            {
                "name": "Unquillo",
                "countryCode": "AR"
            },
            {
                "name": "Valle Hermoso",
                "countryCode": "AR"
            },
            {
                "name": "Viamonte",
                "countryCode": "AR"
            },
            {
                "name": "Vicuña Mackenna",
                "countryCode": "AR"
            },
            {
                "name": "Villa Allende",
                "countryCode": "AR"
            },
            {
                "name": "Villa Ascasubi",
                "countryCode": "AR"
            },
            {
                "name": "Villa Berna",
                "countryCode": "AR"
            },
            {
                "name": "Villa Carlos Paz",
                "countryCode": "AR"
            },
            {
                "name": "Villa Concepción del Tío",
                "countryCode": "AR"
            },
            {
                "name": "Villa Cura Brochero",
                "countryCode": "AR"
            },
            {
                "name": "Villa Dolores",
                "countryCode": "AR"
            },
            {
                "name": "Villa General Belgrano",
                "countryCode": "AR"
            },
            {
                "name": "Villa Giardino",
                "countryCode": "AR"
            },
            {
                "name": "Villa Huidobro",
                "countryCode": "AR"
            },
            {
                "name": "Villa Las Rosas",
                "countryCode": "AR"
            },
            {
                "name": "Villa María",
                "countryCode": "AR"
            },
            {
                "name": "Villa Nueva",
                "countryCode": "AR"
            },
            {
                "name": "Villa Reducción",
                "countryCode": "AR"
            },
            {
                "name": "Villa Rumipal",
                "countryCode": "AR"
            },
            {
                "name": "Villa Tulumba",
                "countryCode": "AR"
            },
            {
                "name": "Villa Valeria",
                "countryCode": "AR"
            },
            {
                "name": "Villa de Soto",
                "countryCode": "AR"
            },
            {
                "name": "Villa del Dique",
                "countryCode": "AR"
            },
            {
                "name": "Villa del Rosario",
                "countryCode": "AR"
            },
            {
                "name": "Villa del Totoral",
                "countryCode": "AR"
            },
            {
                "name": "Wenceslao Escalante",
                "countryCode": "AR"
            },
            {
                "name": "Abra Pampa",
                "countryCode": "AR"
            },
            {
                "name": "Caimancito",
                "countryCode": "AR"
            },
            {
                "name": "Calilegua",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Cochinoca",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Rinconada",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Tumbaya",
                "countryCode": "AR"
            },
            {
                "name": "El Aguilar",
                "countryCode": "AR"
            },
            {
                "name": "Fraile Pintado",
                "countryCode": "AR"
            },
            {
                "name": "Humahuaca",
                "countryCode": "AR"
            },
            {
                "name": "Ingenio La Esperanza",
                "countryCode": "AR"
            },
            {
                "name": "La Mendieta",
                "countryCode": "AR"
            },
            {
                "name": "La Quiaca",
                "countryCode": "AR"
            },
            {
                "name": "Libertador General San Martín",
                "countryCode": "AR"
            },
            {
                "name": "Maimará",
                "countryCode": "AR"
            },
            {
                "name": "Palma Sola",
                "countryCode": "AR"
            },
            {
                "name": "Palpalá",
                "countryCode": "AR"
            },
            {
                "name": "San Pedro de Jujuy",
                "countryCode": "AR"
            },
            {
                "name": "San Salvador de Jujuy",
                "countryCode": "AR"
            },
            {
                "name": "Santa Clara",
                "countryCode": "AR"
            },
            {
                "name": "Tilcara",
                "countryCode": "AR"
            },
            {
                "name": "Yuto",
                "countryCode": "AR"
            },
            {
                "name": "28 de Noviembre",
                "countryCode": "AR"
            },
            {
                "name": "Caleta Olivia",
                "countryCode": "AR"
            },
            {
                "name": "Comandante Luis Piedra Buena",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Deseado",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Güer Aike",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Lago Argentino",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Magallanes",
                "countryCode": "AR"
            },
            {
                "name": "Departamento de Río Chico",
                "countryCode": "AR"
            },
            {
                "name": "El Calafate",
                "countryCode": "AR"
            },
            {
                "name": "Gobernador Gregores",
                "countryCode": "AR"
            },
            {
                "name": "Las Heras",
                "countryCode": "AR"
            },
            {
                "name": "Los Antiguos",
                "countryCode": "AR"
            },
            {
                "name": "Perito Moreno",
                "countryCode": "AR"
            },
            {
                "name": "Pico Truncado",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Deseado",
                "countryCode": "AR"
            },
            {
                "name": "Puerto Santa Cruz",
                "countryCode": "AR"
            },
            {
                "name": "Río Gallegos",
                "countryCode": "AR"
            },
            {
                "name": "Río Turbio",
                "countryCode": "AR"
            },
            {
                "name": "San Julián",
                "countryCode": "AR"
            },
            {
                "name": "Yacimiento Río Turbio",
                "countryCode": "AR"
            }
        ]
    },
    {
        "name": "Armenia",
        "phonecode": "374",
        "cities": [
            {
                "name": "Agarakavan",
                "countryCode": "AM"
            },
            {
                "name": "Aparan",
                "countryCode": "AM"
            },
            {
                "name": "Aragats",
                "countryCode": "AM"
            },
            {
                "name": "Arteni",
                "countryCode": "AM"
            },
            {
                "name": "Ashnak",
                "countryCode": "AM"
            },
            {
                "name": "Ashtarak",
                "countryCode": "AM"
            },
            {
                "name": "Byurakan",
                "countryCode": "AM"
            },
            {
                "name": "Hnaberd",
                "countryCode": "AM"
            },
            {
                "name": "Karbi",
                "countryCode": "AM"
            },
            {
                "name": "Kasakh",
                "countryCode": "AM"
            },
            {
                "name": "Kosh",
                "countryCode": "AM"
            },
            {
                "name": "Nor Yerznka",
                "countryCode": "AM"
            },
            {
                "name": "Oshakan",
                "countryCode": "AM"
            },
            {
                "name": "Sasunik",
                "countryCode": "AM"
            },
            {
                "name": "Shenavan",
                "countryCode": "AM"
            },
            {
                "name": "Tsaghkahovit",
                "countryCode": "AM"
            },
            {
                "name": "T’alin",
                "countryCode": "AM"
            },
            {
                "name": "Ushi",
                "countryCode": "AM"
            },
            {
                "name": "Voskevaz",
                "countryCode": "AM"
            },
            {
                "name": "Zovuni",
                "countryCode": "AM"
            },
            {
                "name": "Abovyan",
                "countryCode": "AM"
            },
            {
                "name": "Aralez",
                "countryCode": "AM"
            },
            {
                "name": "Ararat",
                "countryCode": "AM"
            },
            {
                "name": "Arevabuyr",
                "countryCode": "AM"
            },
            {
                "name": "Arevshat",
                "countryCode": "AM"
            },
            {
                "name": "Armash",
                "countryCode": "AM"
            },
            {
                "name": "Artashat",
                "countryCode": "AM"
            },
            {
                "name": "Avshar",
                "countryCode": "AM"
            },
            {
                "name": "Aygavan",
                "countryCode": "AM"
            },
            {
                "name": "Aygepat",
                "countryCode": "AM"
            },
            {
                "name": "Aygestan",
                "countryCode": "AM"
            },
            {
                "name": "Aygezard",
                "countryCode": "AM"
            },
            {
                "name": "Bardzrashen",
                "countryCode": "AM"
            },
            {
                "name": "Berk’anush",
                "countryCode": "AM"
            },
            {
                "name": "Burastan",
                "countryCode": "AM"
            },
            {
                "name": "Byuravan",
                "countryCode": "AM"
            },
            {
                "name": "Dalar",
                "countryCode": "AM"
            },
            {
                "name": "Darakert",
                "countryCode": "AM"
            },
            {
                "name": "Dashtavan",
                "countryCode": "AM"
            },
            {
                "name": "Dimitrov",
                "countryCode": "AM"
            },
            {
                "name": "Dvin",
                "countryCode": "AM"
            },
            {
                "name": "Getazat",
                "countryCode": "AM"
            },
            {
                "name": "Ghukasavan",
                "countryCode": "AM"
            },
            {
                "name": "Goravan",
                "countryCode": "AM"
            },
            {
                "name": "Hayanist",
                "countryCode": "AM"
            },
            {
                "name": "Hovtashat",
                "countryCode": "AM"
            },
            {
                "name": "Hovtashen",
                "countryCode": "AM"
            },
            {
                "name": "Jrahovit",
                "countryCode": "AM"
            },
            {
                "name": "Lusarrat",
                "countryCode": "AM"
            },
            {
                "name": "Marmarashen",
                "countryCode": "AM"
            },
            {
                "name": "Masis",
                "countryCode": "AM"
            },
            {
                "name": "Mrganush",
                "countryCode": "AM"
            },
            {
                "name": "Mrgavan",
                "countryCode": "AM"
            },
            {
                "name": "Mrgavet",
                "countryCode": "AM"
            },
            {
                "name": "Nizami",
                "countryCode": "AM"
            },
            {
                "name": "Norabats’",
                "countryCode": "AM"
            },
            {
                "name": "Noramarg",
                "countryCode": "AM"
            },
            {
                "name": "Norashen",
                "countryCode": "AM"
            },
            {
                "name": "Noyakert",
                "countryCode": "AM"
            },
            {
                "name": "Nshavan",
                "countryCode": "AM"
            },
            {
                "name": "Sayat’-Nova",
                "countryCode": "AM"
            },
            {
                "name": "Shahumyan",
                "countryCode": "AM"
            },
            {
                "name": "Sis",
                "countryCode": "AM"
            },
            {
                "name": "Sisavan",
                "countryCode": "AM"
            },
            {
                "name": "Surenavan",
                "countryCode": "AM"
            },
            {
                "name": "Vedi",
                "countryCode": "AM"
            },
            {
                "name": "Verin Artashat",
                "countryCode": "AM"
            },
            {
                "name": "Verin Dvin",
                "countryCode": "AM"
            },
            {
                "name": "Vosketap’",
                "countryCode": "AM"
            },
            {
                "name": "Vostan",
                "countryCode": "AM"
            },
            {
                "name": "Yeghegnavan",
                "countryCode": "AM"
            },
            {
                "name": "Zangakatun",
                "countryCode": "AM"
            },
            {
                "name": "Zorak",
                "countryCode": "AM"
            },
            {
                "name": "Aghavnatun",
                "countryCode": "AM"
            },
            {
                "name": "Aknalich",
                "countryCode": "AM"
            },
            {
                "name": "Aknashen",
                "countryCode": "AM"
            },
            {
                "name": "Alashkert",
                "countryCode": "AM"
            },
            {
                "name": "Apaga",
                "countryCode": "AM"
            },
            {
                "name": "Arak’s",
                "countryCode": "AM"
            },
            {
                "name": "Arazap’",
                "countryCode": "AM"
            },
            {
                "name": "Arbat’",
                "countryCode": "AM"
            },
            {
                "name": "Arevashat",
                "countryCode": "AM"
            },
            {
                "name": "Arevik",
                "countryCode": "AM"
            },
            {
                "name": "Argavand",
                "countryCode": "AM"
            },
            {
                "name": "Armavir",
                "countryCode": "AM"
            },
            {
                "name": "Arshaluys",
                "countryCode": "AM"
            },
            {
                "name": "Artimet",
                "countryCode": "AM"
            },
            {
                "name": "Aygek",
                "countryCode": "AM"
            },
            {
                "name": "Aygeshat",
                "countryCode": "AM"
            },
            {
                "name": "Baghramyan",
                "countryCode": "AM"
            },
            {
                "name": "Bambakashat",
                "countryCode": "AM"
            },
            {
                "name": "Dalarik",
                "countryCode": "AM"
            },
            {
                "name": "Doghs",
                "countryCode": "AM"
            },
            {
                "name": "Gay",
                "countryCode": "AM"
            },
            {
                "name": "Geghakert",
                "countryCode": "AM"
            },
            {
                "name": "Geghanist",
                "countryCode": "AM"
            },
            {
                "name": "Getashen",
                "countryCode": "AM"
            },
            {
                "name": "Gmbet’",
                "countryCode": "AM"
            },
            {
                "name": "Griboyedov",
                "countryCode": "AM"
            },
            {
                "name": "Haykashen",
                "countryCode": "AM"
            },
            {
                "name": "Hovtamej",
                "countryCode": "AM"
            },
            {
                "name": "Janfida",
                "countryCode": "AM"
            },
            {
                "name": "Khoronk’",
                "countryCode": "AM"
            },
            {
                "name": "Lenughi",
                "countryCode": "AM"
            },
            {
                "name": "Lukashin",
                "countryCode": "AM"
            },
            {
                "name": "Margara",
                "countryCode": "AM"
            },
            {
                "name": "Mayisyan",
                "countryCode": "AM"
            },
            {
                "name": "Merdzavan",
                "countryCode": "AM"
            },
            {
                "name": "Metsamor",
                "countryCode": "AM"
            },
            {
                "name": "Mrgashat",
                "countryCode": "AM"
            },
            {
                "name": "Musalerr",
                "countryCode": "AM"
            },
            {
                "name": "Myasnikyan",
                "countryCode": "AM"
            },
            {
                "name": "Nalbandyan",
                "countryCode": "AM"
            },
            {
                "name": "Nor Armavir",
                "countryCode": "AM"
            },
            {
                "name": "Norakert",
                "countryCode": "AM"
            },
            {
                "name": "Ptghunk’",
                "countryCode": "AM"
            },
            {
                "name": "P’shatavan",
                "countryCode": "AM"
            },
            {
                "name": "Sardarapat",
                "countryCode": "AM"
            },
            {
                "name": "Shenavan",
                "countryCode": "AM"
            },
            {
                "name": "Tandzut",
                "countryCode": "AM"
            },
            {
                "name": "Taronik",
                "countryCode": "AM"
            },
            {
                "name": "Tsaghkunk’",
                "countryCode": "AM"
            },
            {
                "name": "Tsiatsan",
                "countryCode": "AM"
            },
            {
                "name": "Vagharshapat",
                "countryCode": "AM"
            },
            {
                "name": "Voskehat",
                "countryCode": "AM"
            },
            {
                "name": "Yeghegnut",
                "countryCode": "AM"
            },
            {
                "name": "Yeraskhahun",
                "countryCode": "AM"
            },
            {
                "name": "Arabkir",
                "countryCode": "AM"
            },
            {
                "name": "Argavand",
                "countryCode": "AM"
            },
            {
                "name": "Jrashen",
                "countryCode": "AM"
            },
            {
                "name": "K’anak’erravan",
                "countryCode": "AM"
            },
            {
                "name": "Vardadzor",
                "countryCode": "AM"
            },
            {
                "name": "Yerevan",
                "countryCode": "AM"
            },
            {
                "name": "Akunk’",
                "countryCode": "AM"
            },
            {
                "name": "Astghadzor",
                "countryCode": "AM"
            },
            {
                "name": "Chambarak",
                "countryCode": "AM"
            },
            {
                "name": "Ddmashen",
                "countryCode": "AM"
            },
            {
                "name": "Drakhtik",
                "countryCode": "AM"
            },
            {
                "name": "Dzoragyugh",
                "countryCode": "AM"
            },
            {
                "name": "Gagarin",
                "countryCode": "AM"
            },
            {
                "name": "Gandzak",
                "countryCode": "AM"
            },
            {
                "name": "Gavarr",
                "countryCode": "AM"
            },
            {
                "name": "Geghamasar",
                "countryCode": "AM"
            },
            {
                "name": "Geghamavan",
                "countryCode": "AM"
            },
            {
                "name": "Karanlukh",
                "countryCode": "AM"
            },
            {
                "name": "Karchaghbyur",
                "countryCode": "AM"
            },
            {
                "name": "Lanjaghbyur",
                "countryCode": "AM"
            },
            {
                "name": "Lchap’",
                "countryCode": "AM"
            },
            {
                "name": "Lchashen",
                "countryCode": "AM"
            },
            {
                "name": "Lichk’",
                "countryCode": "AM"
            },
            {
                "name": "Madina",
                "countryCode": "AM"
            },
            {
                "name": "Martuni",
                "countryCode": "AM"
            },
            {
                "name": "Mets Masrik",
                "countryCode": "AM"
            },
            {
                "name": "Nerk’in Getashen",
                "countryCode": "AM"
            },
            {
                "name": "Noratus",
                "countryCode": "AM"
            },
            {
                "name": "Sarukhan",
                "countryCode": "AM"
            },
            {
                "name": "Sevan",
                "countryCode": "AM"
            },
            {
                "name": "Tsovagyugh",
                "countryCode": "AM"
            },
            {
                "name": "Tsovak",
                "countryCode": "AM"
            },
            {
                "name": "Tsovasar",
                "countryCode": "AM"
            },
            {
                "name": "Tsovazard",
                "countryCode": "AM"
            },
            {
                "name": "Tsovinar",
                "countryCode": "AM"
            },
            {
                "name": "Vaghashen",
                "countryCode": "AM"
            },
            {
                "name": "Vahan",
                "countryCode": "AM"
            },
            {
                "name": "Vardenik",
                "countryCode": "AM"
            },
            {
                "name": "Vardenis",
                "countryCode": "AM"
            },
            {
                "name": "Varser",
                "countryCode": "AM"
            },
            {
                "name": "Verin Getashen",
                "countryCode": "AM"
            },
            {
                "name": "Yeranos",
                "countryCode": "AM"
            },
            {
                "name": "Abovyan",
                "countryCode": "AM"
            },
            {
                "name": "Aghavnadzor",
                "countryCode": "AM"
            },
            {
                "name": "Akunk’",
                "countryCode": "AM"
            },
            {
                "name": "Aramus",
                "countryCode": "AM"
            },
            {
                "name": "Argel",
                "countryCode": "AM"
            },
            {
                "name": "Arzakan",
                "countryCode": "AM"
            },
            {
                "name": "Arzni",
                "countryCode": "AM"
            },
            {
                "name": "Balahovit",
                "countryCode": "AM"
            },
            {
                "name": "Bjni",
                "countryCode": "AM"
            },
            {
                "name": "Buzhakan",
                "countryCode": "AM"
            },
            {
                "name": "Byureghavan",
                "countryCode": "AM"
            },
            {
                "name": "Dzoraghbyur",
                "countryCode": "AM"
            },
            {
                "name": "Fantan",
                "countryCode": "AM"
            },
            {
                "name": "Garrni",
                "countryCode": "AM"
            },
            {
                "name": "Goght’",
                "countryCode": "AM"
            },
            {
                "name": "Hrazdan",
                "countryCode": "AM"
            },
            {
                "name": "Kaputan",
                "countryCode": "AM"
            },
            {
                "name": "Kotayk’",
                "countryCode": "AM"
            },
            {
                "name": "Lerrnanist",
                "countryCode": "AM"
            },
            {
                "name": "Mayakovski",
                "countryCode": "AM"
            },
            {
                "name": "Meghradzor",
                "countryCode": "AM"
            },
            {
                "name": "Mrgashen",
                "countryCode": "AM"
            },
            {
                "name": "Nor Geghi",
                "countryCode": "AM"
            },
            {
                "name": "Nor Gyugh",
                "countryCode": "AM"
            },
            {
                "name": "Prroshyan",
                "countryCode": "AM"
            },
            {
                "name": "Ptghni",
                "countryCode": "AM"
            },
            {
                "name": "Solak",
                "countryCode": "AM"
            },
            {
                "name": "Tsaghkadzor",
                "countryCode": "AM"
            },
            {
                "name": "Yeghvard",
                "countryCode": "AM"
            },
            {
                "name": "Zarr",
                "countryCode": "AM"
            },
            {
                "name": "Zoravan",
                "countryCode": "AM"
            },
            {
                "name": "Zovaber",
                "countryCode": "AM"
            },
            {
                "name": "Agarak",
                "countryCode": "AM"
            },
            {
                "name": "Akht’ala",
                "countryCode": "AM"
            },
            {
                "name": "Alaverdi",
                "countryCode": "AM"
            },
            {
                "name": "Arevashogh",
                "countryCode": "AM"
            },
            {
                "name": "Bazum",
                "countryCode": "AM"
            },
            {
                "name": "Chochkan",
                "countryCode": "AM"
            },
            {
                "name": "Darpas",
                "countryCode": "AM"
            },
            {
                "name": "Dsegh",
                "countryCode": "AM"
            },
            {
                "name": "Fioletovo",
                "countryCode": "AM"
            },
            {
                "name": "Gogaran",
                "countryCode": "AM"
            },
            {
                "name": "Gugark’",
                "countryCode": "AM"
            },
            {
                "name": "Gyulagarak",
                "countryCode": "AM"
            },
            {
                "name": "Jrashen",
                "countryCode": "AM"
            },
            {
                "name": "Lerrnants’k’",
                "countryCode": "AM"
            },
            {
                "name": "Lerrnapat",
                "countryCode": "AM"
            },
            {
                "name": "Lerrnavan",
                "countryCode": "AM"
            },
            {
                "name": "Lorut",
                "countryCode": "AM"
            },
            {
                "name": "Margahovit",
                "countryCode": "AM"
            },
            {
                "name": "Mets Parni",
                "countryCode": "AM"
            },
            {
                "name": "Metsavan",
                "countryCode": "AM"
            },
            {
                "name": "Norashen",
                "countryCode": "AM"
            },
            {
                "name": "Odzun",
                "countryCode": "AM"
            },
            {
                "name": "Sarahart’",
                "countryCode": "AM"
            },
            {
                "name": "Saramej",
                "countryCode": "AM"
            },
            {
                "name": "Shahumyan",
                "countryCode": "AM"
            },
            {
                "name": "Shirakamut",
                "countryCode": "AM"
            },
            {
                "name": "Shnogh",
                "countryCode": "AM"
            },
            {
                "name": "Spitak",
                "countryCode": "AM"
            },
            {
                "name": "Step’anavan",
                "countryCode": "AM"
            },
            {
                "name": "Tashir",
                "countryCode": "AM"
            },
            {
                "name": "Tsaghkaber",
                "countryCode": "AM"
            },
            {
                "name": "Urrut",
                "countryCode": "AM"
            },
            {
                "name": "Vahagni",
                "countryCode": "AM"
            },
            {
                "name": "Vanadzor",
                "countryCode": "AM"
            },
            {
                "name": "Vardablur",
                "countryCode": "AM"
            },
            {
                "name": "Yeghegnut",
                "countryCode": "AM"
            },
            {
                "name": "Akhuryan",
                "countryCode": "AM"
            },
            {
                "name": "Amasia",
                "countryCode": "AM"
            },
            {
                "name": "Anushavan",
                "countryCode": "AM"
            },
            {
                "name": "Arevik",
                "countryCode": "AM"
            },
            {
                "name": "Arevshat",
                "countryCode": "AM"
            },
            {
                "name": "Arrap’i",
                "countryCode": "AM"
            },
            {
                "name": "Azatan",
                "countryCode": "AM"
            },
            {
                "name": "Basen",
                "countryCode": "AM"
            },
            {
                "name": "Dzit’hank’ov",
                "countryCode": "AM"
            },
            {
                "name": "Gyumri",
                "countryCode": "AM"
            },
            {
                "name": "Haykavan",
                "countryCode": "AM"
            },
            {
                "name": "Horrom",
                "countryCode": "AM"
            },
            {
                "name": "Kamo",
                "countryCode": "AM"
            },
            {
                "name": "Lerrnakert",
                "countryCode": "AM"
            },
            {
                "name": "Maralik",
                "countryCode": "AM"
            },
            {
                "name": "Marmashen",
                "countryCode": "AM"
            },
            {
                "name": "Mayisyan",
                "countryCode": "AM"
            },
            {
                "name": "Meghrashen",
                "countryCode": "AM"
            },
            {
                "name": "Mets Mant’ash",
                "countryCode": "AM"
            },
            {
                "name": "Pemzashen",
                "countryCode": "AM"
            },
            {
                "name": "P’ok’r Mant’ash",
                "countryCode": "AM"
            },
            {
                "name": "Saratak",
                "countryCode": "AM"
            },
            {
                "name": "Shirak",
                "countryCode": "AM"
            },
            {
                "name": "Spandaryan",
                "countryCode": "AM"
            },
            {
                "name": "Voskehask",
                "countryCode": "AM"
            },
            {
                "name": "Yerazgavors",
                "countryCode": "AM"
            },
            {
                "name": "Agarak",
                "countryCode": "AM"
            },
            {
                "name": "Akner",
                "countryCode": "AM"
            },
            {
                "name": "Angeghakot’",
                "countryCode": "AM"
            },
            {
                "name": "Brrnakot’",
                "countryCode": "AM"
            },
            {
                "name": "Dzorastan",
                "countryCode": "AM"
            },
            {
                "name": "Goris",
                "countryCode": "AM"
            },
            {
                "name": "Hats’avan",
                "countryCode": "AM"
            },
            {
                "name": "Kapan",
                "countryCode": "AM"
            },
            {
                "name": "Khndzoresk",
                "countryCode": "AM"
            },
            {
                "name": "Meghri",
                "countryCode": "AM"
            },
            {
                "name": "Shaghat",
                "countryCode": "AM"
            },
            {
                "name": "Shinuhayr",
                "countryCode": "AM"
            },
            {
                "name": "Tegh",
                "countryCode": "AM"
            },
            {
                "name": "Verishen",
                "countryCode": "AM"
            },
            {
                "name": "Archis",
                "countryCode": "AM"
            },
            {
                "name": "Artsvaberd",
                "countryCode": "AM"
            },
            {
                "name": "Aygehovit",
                "countryCode": "AM"
            },
            {
                "name": "Azatamut",
                "countryCode": "AM"
            },
            {
                "name": "Bagratashen",
                "countryCode": "AM"
            },
            {
                "name": "Berd",
                "countryCode": "AM"
            },
            {
                "name": "Berdavan",
                "countryCode": "AM"
            },
            {
                "name": "Dilijan",
                "countryCode": "AM"
            },
            {
                "name": "Haghartsin",
                "countryCode": "AM"
            },
            {
                "name": "Ijevan",
                "countryCode": "AM"
            },
            {
                "name": "Khasht’arrak",
                "countryCode": "AM"
            },
            {
                "name": "Mosesgegh",
                "countryCode": "AM"
            },
            {
                "name": "Navur",
                "countryCode": "AM"
            },
            {
                "name": "Noyemberyan",
                "countryCode": "AM"
            },
            {
                "name": "Parravak’ar",
                "countryCode": "AM"
            },
            {
                "name": "Sarigyugh",
                "countryCode": "AM"
            },
            {
                "name": "Voskevan",
                "countryCode": "AM"
            },
            {
                "name": "Agarakadzor",
                "countryCode": "AM"
            },
            {
                "name": "Aghavnadzor",
                "countryCode": "AM"
            },
            {
                "name": "Areni",
                "countryCode": "AM"
            },
            {
                "name": "Getap’",
                "countryCode": "AM"
            },
            {
                "name": "Gladzor",
                "countryCode": "AM"
            },
            {
                "name": "Jermuk",
                "countryCode": "AM"
            },
            {
                "name": "Malishka",
                "countryCode": "AM"
            },
            {
                "name": "Rrind",
                "countryCode": "AM"
            },
            {
                "name": "Shatin",
                "countryCode": "AM"
            },
            {
                "name": "Vayk’",
                "countryCode": "AM"
            },
            {
                "name": "Vernashen",
                "countryCode": "AM"
            },
            {
                "name": "Yeghegis",
                "countryCode": "AM"
            },
            {
                "name": "Yeghegnadzor",
                "countryCode": "AM"
            },
            {
                "name": "Zarrit’ap’",
                "countryCode": "AM"
            }
        ]
    },
    {
        "name": "Aruba",
        "phonecode": "297",
        "cities": []
    },
    {
        "name": "Australia",
        "phonecode": "61",
        "cities": [
            {
                "name": "Acton",
                "countryCode": "AU"
            },
            {
                "name": "Ainslie",
                "countryCode": "AU"
            },
            {
                "name": "Amaroo",
                "countryCode": "AU"
            },
            {
                "name": "Aranda",
                "countryCode": "AU"
            },
            {
                "name": "Banks",
                "countryCode": "AU"
            },
            {
                "name": "Barton",
                "countryCode": "AU"
            },
            {
                "name": "Belconnen",
                "countryCode": "AU"
            },
            {
                "name": "Bonner",
                "countryCode": "AU"
            },
            {
                "name": "Bonython",
                "countryCode": "AU"
            },
            {
                "name": "Braddon",
                "countryCode": "AU"
            },
            {
                "name": "Bruce",
                "countryCode": "AU"
            },
            {
                "name": "Calwell",
                "countryCode": "AU"
            },
            {
                "name": "Campbell",
                "countryCode": "AU"
            },
            {
                "name": "Canberra",
                "countryCode": "AU"
            },
            {
                "name": "Casey",
                "countryCode": "AU"
            },
            {
                "name": "Chapman",
                "countryCode": "AU"
            },
            {
                "name": "Charnwood",
                "countryCode": "AU"
            },
            {
                "name": "Chifley",
                "countryCode": "AU"
            },
            {
                "name": "Chisholm",
                "countryCode": "AU"
            },
            {
                "name": "City",
                "countryCode": "AU"
            },
            {
                "name": "Conder",
                "countryCode": "AU"
            },
            {
                "name": "Cook",
                "countryCode": "AU"
            },
            {
                "name": "Coombs",
                "countryCode": "AU"
            },
            {
                "name": "Crace",
                "countryCode": "AU"
            },
            {
                "name": "Curtin",
                "countryCode": "AU"
            },
            {
                "name": "Deakin",
                "countryCode": "AU"
            },
            {
                "name": "Dickson",
                "countryCode": "AU"
            },
            {
                "name": "Downer",
                "countryCode": "AU"
            },
            {
                "name": "Duffy",
                "countryCode": "AU"
            },
            {
                "name": "Dunlop",
                "countryCode": "AU"
            },
            {
                "name": "Evatt",
                "countryCode": "AU"
            },
            {
                "name": "Fadden",
                "countryCode": "AU"
            },
            {
                "name": "Farrer",
                "countryCode": "AU"
            },
            {
                "name": "Fisher",
                "countryCode": "AU"
            },
            {
                "name": "Florey",
                "countryCode": "AU"
            },
            {
                "name": "Flynn",
                "countryCode": "AU"
            },
            {
                "name": "Forde",
                "countryCode": "AU"
            },
            {
                "name": "Forrest",
                "countryCode": "AU"
            },
            {
                "name": "Franklin",
                "countryCode": "AU"
            },
            {
                "name": "Fraser",
                "countryCode": "AU"
            },
            {
                "name": "Garran",
                "countryCode": "AU"
            },
            {
                "name": "Gilmore",
                "countryCode": "AU"
            },
            {
                "name": "Giralang",
                "countryCode": "AU"
            },
            {
                "name": "Gordon",
                "countryCode": "AU"
            },
            {
                "name": "Gowrie",
                "countryCode": "AU"
            },
            {
                "name": "Greenway",
                "countryCode": "AU"
            },
            {
                "name": "Griffith",
                "countryCode": "AU"
            },
            {
                "name": "Gungahlin",
                "countryCode": "AU"
            },
            {
                "name": "Hackett",
                "countryCode": "AU"
            },
            {
                "name": "Harrison",
                "countryCode": "AU"
            },
            {
                "name": "Hawker",
                "countryCode": "AU"
            },
            {
                "name": "Higgins",
                "countryCode": "AU"
            },
            {
                "name": "Holder",
                "countryCode": "AU"
            },
            {
                "name": "Holt",
                "countryCode": "AU"
            },
            {
                "name": "Hughes",
                "countryCode": "AU"
            },
            {
                "name": "Isaacs",
                "countryCode": "AU"
            },
            {
                "name": "Isabella Plains",
                "countryCode": "AU"
            },
            {
                "name": "Kaleen",
                "countryCode": "AU"
            },
            {
                "name": "Kambah",
                "countryCode": "AU"
            },
            {
                "name": "Kingston",
                "countryCode": "AU"
            },
            {
                "name": "Latham",
                "countryCode": "AU"
            },
            {
                "name": "Lyneham",
                "countryCode": "AU"
            },
            {
                "name": "Lyons",
                "countryCode": "AU"
            },
            {
                "name": "Macarthur",
                "countryCode": "AU"
            },
            {
                "name": "Macgregor",
                "countryCode": "AU"
            },
            {
                "name": "Macquarie",
                "countryCode": "AU"
            },
            {
                "name": "Mawson",
                "countryCode": "AU"
            },
            {
                "name": "McKellar",
                "countryCode": "AU"
            },
            {
                "name": "Melba",
                "countryCode": "AU"
            },
            {
                "name": "Monash",
                "countryCode": "AU"
            },
            {
                "name": "Narrabundah",
                "countryCode": "AU"
            },
            {
                "name": "Ngunnawal",
                "countryCode": "AU"
            },
            {
                "name": "Nicholls",
                "countryCode": "AU"
            },
            {
                "name": "O'Connor",
                "countryCode": "AU"
            },
            {
                "name": "Oxley",
                "countryCode": "AU"
            },
            {
                "name": "Page",
                "countryCode": "AU"
            },
            {
                "name": "Palmerston",
                "countryCode": "AU"
            },
            {
                "name": "Pearce",
                "countryCode": "AU"
            },
            {
                "name": "Phillip",
                "countryCode": "AU"
            },
            {
                "name": "Red Hill",
                "countryCode": "AU"
            },
            {
                "name": "Reid",
                "countryCode": "AU"
            },
            {
                "name": "Richardson",
                "countryCode": "AU"
            },
            {
                "name": "Rivett",
                "countryCode": "AU"
            },
            {
                "name": "Scullin",
                "countryCode": "AU"
            },
            {
                "name": "Spence",
                "countryCode": "AU"
            },
            {
                "name": "Stirling",
                "countryCode": "AU"
            },
            {
                "name": "Theodore",
                "countryCode": "AU"
            },
            {
                "name": "Torrens",
                "countryCode": "AU"
            },
            {
                "name": "Turner",
                "countryCode": "AU"
            },
            {
                "name": "Wanniassa",
                "countryCode": "AU"
            },
            {
                "name": "Waramanga",
                "countryCode": "AU"
            },
            {
                "name": "Watson",
                "countryCode": "AU"
            },
            {
                "name": "Weetangera",
                "countryCode": "AU"
            },
            {
                "name": "Weston",
                "countryCode": "AU"
            },
            {
                "name": "Wright",
                "countryCode": "AU"
            },
            {
                "name": "Yarralumla",
                "countryCode": "AU"
            },
            {
                "name": "Abbotsbury",
                "countryCode": "AU"
            },
            {
                "name": "Abbotsford",
                "countryCode": "AU"
            },
            {
                "name": "Abercrombie",
                "countryCode": "AU"
            },
            {
                "name": "Aberdare",
                "countryCode": "AU"
            },
            {
                "name": "Aberdeen",
                "countryCode": "AU"
            },
            {
                "name": "Aberglasslyn",
                "countryCode": "AU"
            },
            {
                "name": "Abermain",
                "countryCode": "AU"
            },
            {
                "name": "Acacia Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Adamstown",
                "countryCode": "AU"
            },
            {
                "name": "Adamstown Heights",
                "countryCode": "AU"
            },
            {
                "name": "Airds",
                "countryCode": "AU"
            },
            {
                "name": "Albion Park",
                "countryCode": "AU"
            },
            {
                "name": "Albion Park Rail",
                "countryCode": "AU"
            },
            {
                "name": "Albury",
                "countryCode": "AU"
            },
            {
                "name": "Albury Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Aldavilla",
                "countryCode": "AU"
            },
            {
                "name": "Alexandria",
                "countryCode": "AU"
            },
            {
                "name": "Alfords Point",
                "countryCode": "AU"
            },
            {
                "name": "Allambie Heights",
                "countryCode": "AU"
            },
            {
                "name": "Allawah",
                "countryCode": "AU"
            },
            {
                "name": "Alstonville",
                "countryCode": "AU"
            },
            {
                "name": "Ambarvale",
                "countryCode": "AU"
            },
            {
                "name": "Anna Bay",
                "countryCode": "AU"
            },
            {
                "name": "Annandale",
                "countryCode": "AU"
            },
            {
                "name": "Annangrove",
                "countryCode": "AU"
            },
            {
                "name": "Appin",
                "countryCode": "AU"
            },
            {
                "name": "Arcadia",
                "countryCode": "AU"
            },
            {
                "name": "Arcadia vale",
                "countryCode": "AU"
            },
            {
                "name": "Argenton",
                "countryCode": "AU"
            },
            {
                "name": "Armidale",
                "countryCode": "AU"
            },
            {
                "name": "Arncliffe",
                "countryCode": "AU"
            },
            {
                "name": "Artarmon",
                "countryCode": "AU"
            },
            {
                "name": "Ashbury",
                "countryCode": "AU"
            },
            {
                "name": "Ashcroft",
                "countryCode": "AU"
            },
            {
                "name": "Ashfield",
                "countryCode": "AU"
            },
            {
                "name": "Ashmont",
                "countryCode": "AU"
            },
            {
                "name": "Ashtonfield",
                "countryCode": "AU"
            },
            {
                "name": "Asquith",
                "countryCode": "AU"
            },
            {
                "name": "Auburn",
                "countryCode": "AU"
            },
            {
                "name": "Austinmer",
                "countryCode": "AU"
            },
            {
                "name": "Austral",
                "countryCode": "AU"
            },
            {
                "name": "Avoca Beach",
                "countryCode": "AU"
            },
            {
                "name": "Avondale",
                "countryCode": "AU"
            },
            {
                "name": "Balgowlah",
                "countryCode": "AU"
            },
            {
                "name": "Balgowlah Heights",
                "countryCode": "AU"
            },
            {
                "name": "Balgownie",
                "countryCode": "AU"
            },
            {
                "name": "Ballina",
                "countryCode": "AU"
            },
            {
                "name": "Balmain",
                "countryCode": "AU"
            },
            {
                "name": "Balmain East",
                "countryCode": "AU"
            },
            {
                "name": "Balranald",
                "countryCode": "AU"
            },
            {
                "name": "Bangalow",
                "countryCode": "AU"
            },
            {
                "name": "Bangor",
                "countryCode": "AU"
            },
            {
                "name": "Banksia",
                "countryCode": "AU"
            },
            {
                "name": "Banksmeadow",
                "countryCode": "AU"
            },
            {
                "name": "Bankstown",
                "countryCode": "AU"
            },
            {
                "name": "Banora Point",
                "countryCode": "AU"
            },
            {
                "name": "Bar Beach",
                "countryCode": "AU"
            },
            {
                "name": "Barden Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Bardia",
                "countryCode": "AU"
            },
            {
                "name": "Bardwell Park",
                "countryCode": "AU"
            },
            {
                "name": "Bardwell Valley",
                "countryCode": "AU"
            },
            {
                "name": "Bargo",
                "countryCode": "AU"
            },
            {
                "name": "Barham",
                "countryCode": "AU"
            },
            {
                "name": "Barnsley",
                "countryCode": "AU"
            },
            {
                "name": "Barooga",
                "countryCode": "AU"
            },
            {
                "name": "Barraba",
                "countryCode": "AU"
            },
            {
                "name": "Barrack Heights",
                "countryCode": "AU"
            },
            {
                "name": "Basin View",
                "countryCode": "AU"
            },
            {
                "name": "Bass Hill",
                "countryCode": "AU"
            },
            {
                "name": "Bateau Bay",
                "countryCode": "AU"
            },
            {
                "name": "Batehaven",
                "countryCode": "AU"
            },
            {
                "name": "Batemans Bay",
                "countryCode": "AU"
            },
            {
                "name": "Bathurst",
                "countryCode": "AU"
            },
            {
                "name": "Bathurst Regional",
                "countryCode": "AU"
            },
            {
                "name": "Bathurst city centre",
                "countryCode": "AU"
            },
            {
                "name": "Batlow",
                "countryCode": "AU"
            },
            {
                "name": "Baulkham Hills",
                "countryCode": "AU"
            },
            {
                "name": "Bay View",
                "countryCode": "AU"
            },
            {
                "name": "Beacon Hill",
                "countryCode": "AU"
            },
            {
                "name": "Beaumont Hills",
                "countryCode": "AU"
            },
            {
                "name": "Beecroft",
                "countryCode": "AU"
            },
            {
                "name": "Bega",
                "countryCode": "AU"
            },
            {
                "name": "Bega Valley",
                "countryCode": "AU"
            },
            {
                "name": "Belfield",
                "countryCode": "AU"
            },
            {
                "name": "Bella Vista",
                "countryCode": "AU"
            },
            {
                "name": "Bellambi",
                "countryCode": "AU"
            },
            {
                "name": "Bellbird",
                "countryCode": "AU"
            },
            {
                "name": "Bellevue Hill",
                "countryCode": "AU"
            },
            {
                "name": "Bellingen",
                "countryCode": "AU"
            },
            {
                "name": "Belmont",
                "countryCode": "AU"
            },
            {
                "name": "Belmont North",
                "countryCode": "AU"
            },
            {
                "name": "Belmont South",
                "countryCode": "AU"
            },
            {
                "name": "Belmore",
                "countryCode": "AU"
            },
            {
                "name": "Belrose",
                "countryCode": "AU"
            },
            {
                "name": "Bensville",
                "countryCode": "AU"
            },
            {
                "name": "Berala",
                "countryCode": "AU"
            },
            {
                "name": "Beresfield",
                "countryCode": "AU"
            },
            {
                "name": "Berkeley",
                "countryCode": "AU"
            },
            {
                "name": "Berkeley Vale",
                "countryCode": "AU"
            },
            {
                "name": "Berkshire Park",
                "countryCode": "AU"
            },
            {
                "name": "Bermagui",
                "countryCode": "AU"
            },
            {
                "name": "Berowra",
                "countryCode": "AU"
            },
            {
                "name": "Berowra Heights",
                "countryCode": "AU"
            },
            {
                "name": "Berridale",
                "countryCode": "AU"
            },
            {
                "name": "Berrigan",
                "countryCode": "AU"
            },
            {
                "name": "Berry",
                "countryCode": "AU"
            },
            {
                "name": "Beverley Park",
                "countryCode": "AU"
            },
            {
                "name": "Beverly Hills",
                "countryCode": "AU"
            },
            {
                "name": "Bexley",
                "countryCode": "AU"
            },
            {
                "name": "Bexley North",
                "countryCode": "AU"
            },
            {
                "name": "Bidwill",
                "countryCode": "AU"
            },
            {
                "name": "Bilambil Heights",
                "countryCode": "AU"
            },
            {
                "name": "Bilgola Plateau",
                "countryCode": "AU"
            },
            {
                "name": "Bingara",
                "countryCode": "AU"
            },
            {
                "name": "Birchgrove",
                "countryCode": "AU"
            },
            {
                "name": "Birmingham Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Birrong",
                "countryCode": "AU"
            },
            {
                "name": "Blackalls Park",
                "countryCode": "AU"
            },
            {
                "name": "Blackbutt",
                "countryCode": "AU"
            },
            {
                "name": "Blackett",
                "countryCode": "AU"
            },
            {
                "name": "Blackheath",
                "countryCode": "AU"
            },
            {
                "name": "Blacksmiths",
                "countryCode": "AU"
            },
            {
                "name": "Blacktown",
                "countryCode": "AU"
            },
            {
                "name": "Blackwall",
                "countryCode": "AU"
            },
            {
                "name": "Blair Athol",
                "countryCode": "AU"
            },
            {
                "name": "Blakehurst",
                "countryCode": "AU"
            },
            {
                "name": "Bland",
                "countryCode": "AU"
            },
            {
                "name": "Blaxland",
                "countryCode": "AU"
            },
            {
                "name": "Blayney",
                "countryCode": "AU"
            },
            {
                "name": "Bligh Park",
                "countryCode": "AU"
            },
            {
                "name": "Blue Bay",
                "countryCode": "AU"
            },
            {
                "name": "Blue Haven",
                "countryCode": "AU"
            },
            {
                "name": "Blue Mountains Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Boambee",
                "countryCode": "AU"
            },
            {
                "name": "Boambee East",
                "countryCode": "AU"
            },
            {
                "name": "Bogan",
                "countryCode": "AU"
            },
            {
                "name": "Bogangar",
                "countryCode": "AU"
            },
            {
                "name": "Boggabri",
                "countryCode": "AU"
            },
            {
                "name": "Bolton Point",
                "countryCode": "AU"
            },
            {
                "name": "Bolwarra Heights",
                "countryCode": "AU"
            },
            {
                "name": "Bomaderry",
                "countryCode": "AU"
            },
            {
                "name": "Bombala",
                "countryCode": "AU"
            },
            {
                "name": "Bondi",
                "countryCode": "AU"
            },
            {
                "name": "Bondi Beach",
                "countryCode": "AU"
            },
            {
                "name": "Bondi Junction",
                "countryCode": "AU"
            },
            {
                "name": "Bonnells Bay",
                "countryCode": "AU"
            },
            {
                "name": "Bonnet Bay",
                "countryCode": "AU"
            },
            {
                "name": "Bonny Hills",
                "countryCode": "AU"
            },
            {
                "name": "Bonnyrigg",
                "countryCode": "AU"
            },
            {
                "name": "Bonnyrigg Heights",
                "countryCode": "AU"
            },
            {
                "name": "Bonville",
                "countryCode": "AU"
            },
            {
                "name": "Booker Bay",
                "countryCode": "AU"
            },
            {
                "name": "Booragul",
                "countryCode": "AU"
            },
            {
                "name": "Boorowa",
                "countryCode": "AU"
            },
            {
                "name": "Bossley Park",
                "countryCode": "AU"
            },
            {
                "name": "Botany",
                "countryCode": "AU"
            },
            {
                "name": "Botany Bay",
                "countryCode": "AU"
            },
            {
                "name": "Bourke",
                "countryCode": "AU"
            },
            {
                "name": "Bourkelands",
                "countryCode": "AU"
            },
            {
                "name": "Bow Bowing",
                "countryCode": "AU"
            },
            {
                "name": "Bowen Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Bowenfels",
                "countryCode": "AU"
            },
            {
                "name": "Bowral",
                "countryCode": "AU"
            },
            {
                "name": "Bowraville",
                "countryCode": "AU"
            },
            {
                "name": "Bradbury",
                "countryCode": "AU"
            },
            {
                "name": "Braidwood",
                "countryCode": "AU"
            },
            {
                "name": "Branxton",
                "countryCode": "AU"
            },
            {
                "name": "Breakfast Point",
                "countryCode": "AU"
            },
            {
                "name": "Brewarrina",
                "countryCode": "AU"
            },
            {
                "name": "Brighton-Le-Sands",
                "countryCode": "AU"
            },
            {
                "name": "Bringelly",
                "countryCode": "AU"
            },
            {
                "name": "Broadmeadow",
                "countryCode": "AU"
            },
            {
                "name": "Broken Hill",
                "countryCode": "AU"
            },
            {
                "name": "Broken Hill Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Bronte",
                "countryCode": "AU"
            },
            {
                "name": "Brookvale",
                "countryCode": "AU"
            },
            {
                "name": "Broulee",
                "countryCode": "AU"
            },
            {
                "name": "Brunswick Heads",
                "countryCode": "AU"
            },
            {
                "name": "Budgewoi",
                "countryCode": "AU"
            },
            {
                "name": "Buff Point",
                "countryCode": "AU"
            },
            {
                "name": "Bulahdelah",
                "countryCode": "AU"
            },
            {
                "name": "Bullaburra",
                "countryCode": "AU"
            },
            {
                "name": "Bulli",
                "countryCode": "AU"
            },
            {
                "name": "Bundanoon",
                "countryCode": "AU"
            },
            {
                "name": "Bundeena",
                "countryCode": "AU"
            },
            {
                "name": "Bungarribee",
                "countryCode": "AU"
            },
            {
                "name": "Bungendore",
                "countryCode": "AU"
            },
            {
                "name": "Buronga",
                "countryCode": "AU"
            },
            {
                "name": "Burradoo",
                "countryCode": "AU"
            },
            {
                "name": "Burraneer",
                "countryCode": "AU"
            },
            {
                "name": "Burrill Lake",
                "countryCode": "AU"
            },
            {
                "name": "Burwood",
                "countryCode": "AU"
            },
            {
                "name": "Busby",
                "countryCode": "AU"
            },
            {
                "name": "Buttaba",
                "countryCode": "AU"
            },
            {
                "name": "Buxton",
                "countryCode": "AU"
            },
            {
                "name": "Byron Bay",
                "countryCode": "AU"
            },
            {
                "name": "Byron Shire",
                "countryCode": "AU"
            },
            {
                "name": "Bywong",
                "countryCode": "AU"
            },
            {
                "name": "Cabarita",
                "countryCode": "AU"
            },
            {
                "name": "Cabonne",
                "countryCode": "AU"
            },
            {
                "name": "Cabramatta",
                "countryCode": "AU"
            },
            {
                "name": "Cabramatta West",
                "countryCode": "AU"
            },
            {
                "name": "Caddens",
                "countryCode": "AU"
            },
            {
                "name": "Calala",
                "countryCode": "AU"
            },
            {
                "name": "Callaghan",
                "countryCode": "AU"
            },
            {
                "name": "Callala Bay",
                "countryCode": "AU"
            },
            {
                "name": "Cambewarra Village",
                "countryCode": "AU"
            },
            {
                "name": "Cambridge Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Cambridge Park",
                "countryCode": "AU"
            },
            {
                "name": "Camden",
                "countryCode": "AU"
            },
            {
                "name": "Camden Haven",
                "countryCode": "AU"
            },
            {
                "name": "Camden South",
                "countryCode": "AU"
            },
            {
                "name": "Cameron Park",
                "countryCode": "AU"
            },
            {
                "name": "Cammeray",
                "countryCode": "AU"
            },
            {
                "name": "Campbelltown",
                "countryCode": "AU"
            },
            {
                "name": "Campbelltown Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Camperdown",
                "countryCode": "AU"
            },
            {
                "name": "Campsie",
                "countryCode": "AU"
            },
            {
                "name": "Canada Bay",
                "countryCode": "AU"
            },
            {
                "name": "Canley Heights",
                "countryCode": "AU"
            },
            {
                "name": "Canley Vale",
                "countryCode": "AU"
            },
            {
                "name": "Canowindra",
                "countryCode": "AU"
            },
            {
                "name": "Canterbury",
                "countryCode": "AU"
            },
            {
                "name": "Canton Beach",
                "countryCode": "AU"
            },
            {
                "name": "Cardiff",
                "countryCode": "AU"
            },
            {
                "name": "Cardiff Heights",
                "countryCode": "AU"
            },
            {
                "name": "Cardiff South",
                "countryCode": "AU"
            },
            {
                "name": "Caringbah",
                "countryCode": "AU"
            },
            {
                "name": "Caringbah South",
                "countryCode": "AU"
            },
            {
                "name": "Carlingford",
                "countryCode": "AU"
            },
            {
                "name": "Carlton",
                "countryCode": "AU"
            },
            {
                "name": "Carnes Hill",
                "countryCode": "AU"
            },
            {
                "name": "Carramar",
                "countryCode": "AU"
            },
            {
                "name": "Carrathool",
                "countryCode": "AU"
            },
            {
                "name": "Carrington",
                "countryCode": "AU"
            },
            {
                "name": "Carss Park",
                "countryCode": "AU"
            },
            {
                "name": "Cartwright",
                "countryCode": "AU"
            },
            {
                "name": "Carwoola",
                "countryCode": "AU"
            },
            {
                "name": "Casino",
                "countryCode": "AU"
            },
            {
                "name": "Castle Cove",
                "countryCode": "AU"
            },
            {
                "name": "Castle Hill",
                "countryCode": "AU"
            },
            {
                "name": "Castlecrag",
                "countryCode": "AU"
            },
            {
                "name": "Castlereagh",
                "countryCode": "AU"
            },
            {
                "name": "Casuarina",
                "countryCode": "AU"
            },
            {
                "name": "Casula",
                "countryCode": "AU"
            },
            {
                "name": "Catalina",
                "countryCode": "AU"
            },
            {
                "name": "Catherine Field",
                "countryCode": "AU"
            },
            {
                "name": "Caves Beach",
                "countryCode": "AU"
            },
            {
                "name": "Cecil Hills",
                "countryCode": "AU"
            },
            {
                "name": "Centennial Park",
                "countryCode": "AU"
            },
            {
                "name": "Central Darling",
                "countryCode": "AU"
            },
            {
                "name": "Cessnock",
                "countryCode": "AU"
            },
            {
                "name": "Chain Valley Bay",
                "countryCode": "AU"
            },
            {
                "name": "Charlestown",
                "countryCode": "AU"
            },
            {
                "name": "Charmhaven",
                "countryCode": "AU"
            },
            {
                "name": "Chatswood",
                "countryCode": "AU"
            },
            {
                "name": "Chatswood West",
                "countryCode": "AU"
            },
            {
                "name": "Cherrybrook",
                "countryCode": "AU"
            },
            {
                "name": "Chester Hill",
                "countryCode": "AU"
            },
            {
                "name": "Chifley",
                "countryCode": "AU"
            },
            {
                "name": "Chinderah",
                "countryCode": "AU"
            },
            {
                "name": "Chippendale",
                "countryCode": "AU"
            },
            {
                "name": "Chipping Norton",
                "countryCode": "AU"
            },
            {
                "name": "Chiswick",
                "countryCode": "AU"
            },
            {
                "name": "Chittaway Bay",
                "countryCode": "AU"
            },
            {
                "name": "City of Sydney",
                "countryCode": "AU"
            },
            {
                "name": "Claremont Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Clarence Town",
                "countryCode": "AU"
            },
            {
                "name": "Clarence Valley",
                "countryCode": "AU"
            },
            {
                "name": "Claymore",
                "countryCode": "AU"
            },
            {
                "name": "Clemton Park",
                "countryCode": "AU"
            },
            {
                "name": "Clontarf",
                "countryCode": "AU"
            },
            {
                "name": "Clovelly",
                "countryCode": "AU"
            },
            {
                "name": "Coal Point",
                "countryCode": "AU"
            },
            {
                "name": "Cobar",
                "countryCode": "AU"
            },
            {
                "name": "Cobbitty",
                "countryCode": "AU"
            },
            {
                "name": "Coffs Harbour",
                "countryCode": "AU"
            },
            {
                "name": "Coleambally",
                "countryCode": "AU"
            },
            {
                "name": "Colebee",
                "countryCode": "AU"
            },
            {
                "name": "Coledale",
                "countryCode": "AU"
            },
            {
                "name": "Collaroy",
                "countryCode": "AU"
            },
            {
                "name": "Collaroy Plateau",
                "countryCode": "AU"
            },
            {
                "name": "Colo Vale",
                "countryCode": "AU"
            },
            {
                "name": "Colyton",
                "countryCode": "AU"
            },
            {
                "name": "Como",
                "countryCode": "AU"
            },
            {
                "name": "Concord",
                "countryCode": "AU"
            },
            {
                "name": "Concord West",
                "countryCode": "AU"
            },
            {
                "name": "Condell Park",
                "countryCode": "AU"
            },
            {
                "name": "Condobolin",
                "countryCode": "AU"
            },
            {
                "name": "Coniston",
                "countryCode": "AU"
            },
            {
                "name": "Connells Point",
                "countryCode": "AU"
            },
            {
                "name": "Constitution Hill",
                "countryCode": "AU"
            },
            {
                "name": "Coogee",
                "countryCode": "AU"
            },
            {
                "name": "Cooks Hill",
                "countryCode": "AU"
            },
            {
                "name": "Coolah",
                "countryCode": "AU"
            },
            {
                "name": "Coolamon",
                "countryCode": "AU"
            },
            {
                "name": "Cooma",
                "countryCode": "AU"
            },
            {
                "name": "Coonabarabran",
                "countryCode": "AU"
            },
            {
                "name": "Coonamble",
                "countryCode": "AU"
            },
            {
                "name": "Cooranbong",
                "countryCode": "AU"
            },
            {
                "name": "Cootamundra",
                "countryCode": "AU"
            },
            {
                "name": "Copacabana",
                "countryCode": "AU"
            },
            {
                "name": "Coraki",
                "countryCode": "AU"
            },
            {
                "name": "Cordeaux Heights",
                "countryCode": "AU"
            },
            {
                "name": "Corindi Beach",
                "countryCode": "AU"
            },
            {
                "name": "Corlette",
                "countryCode": "AU"
            },
            {
                "name": "Corowa",
                "countryCode": "AU"
            },
            {
                "name": "Corrimal",
                "countryCode": "AU"
            },
            {
                "name": "Coutts Crossing",
                "countryCode": "AU"
            },
            {
                "name": "Cowra",
                "countryCode": "AU"
            },
            {
                "name": "Cranebrook",
                "countryCode": "AU"
            },
            {
                "name": "Cremorne",
                "countryCode": "AU"
            },
            {
                "name": "Cremorne Point",
                "countryCode": "AU"
            },
            {
                "name": "Crescent Head",
                "countryCode": "AU"
            },
            {
                "name": "Crestwood",
                "countryCode": "AU"
            },
            {
                "name": "Cringila",
                "countryCode": "AU"
            },
            {
                "name": "Cromer",
                "countryCode": "AU"
            },
            {
                "name": "Cronulla",
                "countryCode": "AU"
            },
            {
                "name": "Crookwell",
                "countryCode": "AU"
            },
            {
                "name": "Crows Nest",
                "countryCode": "AU"
            },
            {
                "name": "Croydon",
                "countryCode": "AU"
            },
            {
                "name": "Culburra Beach",
                "countryCode": "AU"
            },
            {
                "name": "Culcairn",
                "countryCode": "AU"
            },
            {
                "name": "Cumbalum",
                "countryCode": "AU"
            },
            {
                "name": "Cundletown",
                "countryCode": "AU"
            },
            {
                "name": "Curl Curl",
                "countryCode": "AU"
            },
            {
                "name": "Currans Hill",
                "countryCode": "AU"
            },
            {
                "name": "Daceyville",
                "countryCode": "AU"
            },
            {
                "name": "Dalmeny",
                "countryCode": "AU"
            },
            {
                "name": "Dapto",
                "countryCode": "AU"
            },
            {
                "name": "Darling Point",
                "countryCode": "AU"
            },
            {
                "name": "Darlinghurst",
                "countryCode": "AU"
            },
            {
                "name": "Darlington",
                "countryCode": "AU"
            },
            {
                "name": "Darlington Point",
                "countryCode": "AU"
            },
            {
                "name": "Davidson",
                "countryCode": "AU"
            },
            {
                "name": "Davistown",
                "countryCode": "AU"
            },
            {
                "name": "Dean Park",
                "countryCode": "AU"
            },
            {
                "name": "Dee Why",
                "countryCode": "AU"
            },
            {
                "name": "Denham Court",
                "countryCode": "AU"
            },
            {
                "name": "Deniliquin",
                "countryCode": "AU"
            },
            {
                "name": "Denistone",
                "countryCode": "AU"
            },
            {
                "name": "Denistone East",
                "countryCode": "AU"
            },
            {
                "name": "Denman",
                "countryCode": "AU"
            },
            {
                "name": "Dharruk",
                "countryCode": "AU"
            },
            {
                "name": "Dolls Point",
                "countryCode": "AU"
            },
            {
                "name": "Doonside",
                "countryCode": "AU"
            },
            {
                "name": "Dora Creek",
                "countryCode": "AU"
            },
            {
                "name": "Dorrigo",
                "countryCode": "AU"
            },
            {
                "name": "Double Bay",
                "countryCode": "AU"
            },
            {
                "name": "Douglas Park",
                "countryCode": "AU"
            },
            {
                "name": "Dover Heights",
                "countryCode": "AU"
            },
            {
                "name": "Drummoyne",
                "countryCode": "AU"
            },
            {
                "name": "Dubbo",
                "countryCode": "AU"
            },
            {
                "name": "Dudley",
                "countryCode": "AU"
            },
            {
                "name": "Dulwich Hill",
                "countryCode": "AU"
            },
            {
                "name": "Dundas Valley",
                "countryCode": "AU"
            },
            {
                "name": "Dunedoo",
                "countryCode": "AU"
            },
            {
                "name": "Dungog",
                "countryCode": "AU"
            },
            {
                "name": "Dural",
                "countryCode": "AU"
            },
            {
                "name": "Eagle Vale",
                "countryCode": "AU"
            },
            {
                "name": "Earlwood",
                "countryCode": "AU"
            },
            {
                "name": "East Albury",
                "countryCode": "AU"
            },
            {
                "name": "East Ballina",
                "countryCode": "AU"
            },
            {
                "name": "East Branxton",
                "countryCode": "AU"
            },
            {
                "name": "East Corrimal",
                "countryCode": "AU"
            },
            {
                "name": "East Gosford",
                "countryCode": "AU"
            },
            {
                "name": "East Hills",
                "countryCode": "AU"
            },
            {
                "name": "East Jindabyne",
                "countryCode": "AU"
            },
            {
                "name": "East Kempsey",
                "countryCode": "AU"
            },
            {
                "name": "East Killara",
                "countryCode": "AU"
            },
            {
                "name": "East Kurrajong",
                "countryCode": "AU"
            },
            {
                "name": "East Lindfield",
                "countryCode": "AU"
            },
            {
                "name": "East Lismore",
                "countryCode": "AU"
            },
            {
                "name": "East Maitland",
                "countryCode": "AU"
            },
            {
                "name": "East Ryde",
                "countryCode": "AU"
            },
            {
                "name": "East Tamworth",
                "countryCode": "AU"
            },
            {
                "name": "Eastlakes",
                "countryCode": "AU"
            },
            {
                "name": "Eastwood",
                "countryCode": "AU"
            },
            {
                "name": "Eden",
                "countryCode": "AU"
            },
            {
                "name": "Edensor Park",
                "countryCode": "AU"
            },
            {
                "name": "Edgecliff",
                "countryCode": "AU"
            },
            {
                "name": "Edgeworth",
                "countryCode": "AU"
            },
            {
                "name": "Edmondson Park",
                "countryCode": "AU"
            },
            {
                "name": "Eglinton",
                "countryCode": "AU"
            },
            {
                "name": "Elanora Heights",
                "countryCode": "AU"
            },
            {
                "name": "Elderslie",
                "countryCode": "AU"
            },
            {
                "name": "Eleebana",
                "countryCode": "AU"
            },
            {
                "name": "Elermore Vale",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Bay",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Hills",
                "countryCode": "AU"
            },
            {
                "name": "Ellalong",
                "countryCode": "AU"
            },
            {
                "name": "Emerald Beach",
                "countryCode": "AU"
            },
            {
                "name": "Emerton",
                "countryCode": "AU"
            },
            {
                "name": "Empire Bay",
                "countryCode": "AU"
            },
            {
                "name": "Emu Heights",
                "countryCode": "AU"
            },
            {
                "name": "Emu Plains",
                "countryCode": "AU"
            },
            {
                "name": "Engadine",
                "countryCode": "AU"
            },
            {
                "name": "Enmore",
                "countryCode": "AU"
            },
            {
                "name": "Epping",
                "countryCode": "AU"
            },
            {
                "name": "Erina",
                "countryCode": "AU"
            },
            {
                "name": "Ermington",
                "countryCode": "AU"
            },
            {
                "name": "Erskine Park",
                "countryCode": "AU"
            },
            {
                "name": "Erskineville",
                "countryCode": "AU"
            },
            {
                "name": "Eschol Park",
                "countryCode": "AU"
            },
            {
                "name": "Estella",
                "countryCode": "AU"
            },
            {
                "name": "Ettalong",
                "countryCode": "AU"
            },
            {
                "name": "Ettalong Beach",
                "countryCode": "AU"
            },
            {
                "name": "Eulomogo",
                "countryCode": "AU"
            },
            {
                "name": "Eurobodalla",
                "countryCode": "AU"
            },
            {
                "name": "Evans Head",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield East",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield Heights",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield West",
                "countryCode": "AU"
            },
            {
                "name": "Fairlight",
                "countryCode": "AU"
            },
            {
                "name": "Fairy Meadow",
                "countryCode": "AU"
            },
            {
                "name": "Farmborough Heights",
                "countryCode": "AU"
            },
            {
                "name": "Faulconbridge",
                "countryCode": "AU"
            },
            {
                "name": "Fennell Bay",
                "countryCode": "AU"
            },
            {
                "name": "Fern Bay",
                "countryCode": "AU"
            },
            {
                "name": "Fern Hill",
                "countryCode": "AU"
            },
            {
                "name": "Figtree",
                "countryCode": "AU"
            },
            {
                "name": "Fingal Bay",
                "countryCode": "AU"
            },
            {
                "name": "Finley",
                "countryCode": "AU"
            },
            {
                "name": "Five Dock",
                "countryCode": "AU"
            },
            {
                "name": "Fletcher",
                "countryCode": "AU"
            },
            {
                "name": "Flinders",
                "countryCode": "AU"
            },
            {
                "name": "Floraville",
                "countryCode": "AU"
            },
            {
                "name": "Forbes",
                "countryCode": "AU"
            },
            {
                "name": "Forest Hill",
                "countryCode": "AU"
            },
            {
                "name": "Forest Lodge",
                "countryCode": "AU"
            },
            {
                "name": "Forestville",
                "countryCode": "AU"
            },
            {
                "name": "Forresters Beach",
                "countryCode": "AU"
            },
            {
                "name": "Forster",
                "countryCode": "AU"
            },
            {
                "name": "Frederickton",
                "countryCode": "AU"
            },
            {
                "name": "Freemans Reach",
                "countryCode": "AU"
            },
            {
                "name": "Frenchs Forest",
                "countryCode": "AU"
            },
            {
                "name": "Freshwater",
                "countryCode": "AU"
            },
            {
                "name": "Galston",
                "countryCode": "AU"
            },
            {
                "name": "Garden Suburb",
                "countryCode": "AU"
            },
            {
                "name": "Gateshead",
                "countryCode": "AU"
            },
            {
                "name": "Georges Hall",
                "countryCode": "AU"
            },
            {
                "name": "Georgetown",
                "countryCode": "AU"
            },
            {
                "name": "Gerringong",
                "countryCode": "AU"
            },
            {
                "name": "Gilgandra",
                "countryCode": "AU"
            },
            {
                "name": "Gillieston Heights",
                "countryCode": "AU"
            },
            {
                "name": "Girards Hill",
                "countryCode": "AU"
            },
            {
                "name": "Girraween",
                "countryCode": "AU"
            },
            {
                "name": "Gladesville",
                "countryCode": "AU"
            },
            {
                "name": "Glebe",
                "countryCode": "AU"
            },
            {
                "name": "Glen Alpine",
                "countryCode": "AU"
            },
            {
                "name": "Glen Innes",
                "countryCode": "AU"
            },
            {
                "name": "Glen Innes Severn",
                "countryCode": "AU"
            },
            {
                "name": "Glenbrook",
                "countryCode": "AU"
            },
            {
                "name": "Glendale",
                "countryCode": "AU"
            },
            {
                "name": "Glendenning",
                "countryCode": "AU"
            },
            {
                "name": "Glenfield",
                "countryCode": "AU"
            },
            {
                "name": "Glenfield Park",
                "countryCode": "AU"
            },
            {
                "name": "Glenhaven",
                "countryCode": "AU"
            },
            {
                "name": "Glenmore Park",
                "countryCode": "AU"
            },
            {
                "name": "Glenning Valley",
                "countryCode": "AU"
            },
            {
                "name": "Glenorie",
                "countryCode": "AU"
            },
            {
                "name": "Glenroy",
                "countryCode": "AU"
            },
            {
                "name": "Glenwood",
                "countryCode": "AU"
            },
            {
                "name": "Glossodia",
                "countryCode": "AU"
            },
            {
                "name": "Gloucester",
                "countryCode": "AU"
            },
            {
                "name": "Gol Gol",
                "countryCode": "AU"
            },
            {
                "name": "Googong",
                "countryCode": "AU"
            },
            {
                "name": "Goonellabah",
                "countryCode": "AU"
            },
            {
                "name": "Gordon",
                "countryCode": "AU"
            },
            {
                "name": "Gorokan",
                "countryCode": "AU"
            },
            {
                "name": "Gosford",
                "countryCode": "AU"
            },
            {
                "name": "Goulburn",
                "countryCode": "AU"
            },
            {
                "name": "Goulburn Mulwaree",
                "countryCode": "AU"
            },
            {
                "name": "Grafton",
                "countryCode": "AU"
            },
            {
                "name": "Granville",
                "countryCode": "AU"
            },
            {
                "name": "Grasmere",
                "countryCode": "AU"
            },
            {
                "name": "Grays Point",
                "countryCode": "AU"
            },
            {
                "name": "Greater Hume Shire",
                "countryCode": "AU"
            },
            {
                "name": "Green Valley",
                "countryCode": "AU"
            },
            {
                "name": "Greenacre",
                "countryCode": "AU"
            },
            {
                "name": "Greenfield Park",
                "countryCode": "AU"
            },
            {
                "name": "Greenwell Point",
                "countryCode": "AU"
            },
            {
                "name": "Greenwich",
                "countryCode": "AU"
            },
            {
                "name": "Gregory Hills",
                "countryCode": "AU"
            },
            {
                "name": "Grenfell",
                "countryCode": "AU"
            },
            {
                "name": "Greta",
                "countryCode": "AU"
            },
            {
                "name": "Greystanes",
                "countryCode": "AU"
            },
            {
                "name": "Griffith",
                "countryCode": "AU"
            },
            {
                "name": "Grose Vale",
                "countryCode": "AU"
            },
            {
                "name": "Guildford West",
                "countryCode": "AU"
            },
            {
                "name": "Gulgong",
                "countryCode": "AU"
            },
            {
                "name": "Gulmarrad",
                "countryCode": "AU"
            },
            {
                "name": "Gundagai",
                "countryCode": "AU"
            },
            {
                "name": "Gundaroo",
                "countryCode": "AU"
            },
            {
                "name": "Gunnedah",
                "countryCode": "AU"
            },
            {
                "name": "Guyra",
                "countryCode": "AU"
            },
            {
                "name": "Gwandalan",
                "countryCode": "AU"
            },
            {
                "name": "Gwydir",
                "countryCode": "AU"
            },
            {
                "name": "Gwynneville",
                "countryCode": "AU"
            },
            {
                "name": "Gymea",
                "countryCode": "AU"
            },
            {
                "name": "Gymea Bay",
                "countryCode": "AU"
            },
            {
                "name": "Haberfield",
                "countryCode": "AU"
            },
            {
                "name": "Halekulani",
                "countryCode": "AU"
            },
            {
                "name": "Hamilton",
                "countryCode": "AU"
            },
            {
                "name": "Hamlyn Terrace",
                "countryCode": "AU"
            },
            {
                "name": "Hammondville",
                "countryCode": "AU"
            },
            {
                "name": "Hanwood",
                "countryCode": "AU"
            },
            {
                "name": "Harrington",
                "countryCode": "AU"
            },
            {
                "name": "Harrington Park",
                "countryCode": "AU"
            },
            {
                "name": "Harris Park",
                "countryCode": "AU"
            },
            {
                "name": "Hassall Grove",
                "countryCode": "AU"
            },
            {
                "name": "Hawkesbury",
                "countryCode": "AU"
            },
            {
                "name": "Hawks Nest",
                "countryCode": "AU"
            },
            {
                "name": "Hay",
                "countryCode": "AU"
            },
            {
                "name": "Haymarket",
                "countryCode": "AU"
            },
            {
                "name": "Hazelbrook",
                "countryCode": "AU"
            },
            {
                "name": "Heathcote",
                "countryCode": "AU"
            },
            {
                "name": "Hebersham",
                "countryCode": "AU"
            },
            {
                "name": "Heckenberg",
                "countryCode": "AU"
            },
            {
                "name": "Heddon Greta",
                "countryCode": "AU"
            },
            {
                "name": "Helensburgh",
                "countryCode": "AU"
            },
            {
                "name": "Henty",
                "countryCode": "AU"
            },
            {
                "name": "Hill Top",
                "countryCode": "AU"
            },
            {
                "name": "Hillsdale",
                "countryCode": "AU"
            },
            {
                "name": "Hillston",
                "countryCode": "AU"
            },
            {
                "name": "Hillvue",
                "countryCode": "AU"
            },
            {
                "name": "Hinchinbrook",
                "countryCode": "AU"
            },
            {
                "name": "Hobartville",
                "countryCode": "AU"
            },
            {
                "name": "Holbrook",
                "countryCode": "AU"
            },
            {
                "name": "Holmesville",
                "countryCode": "AU"
            },
            {
                "name": "Holroyd",
                "countryCode": "AU"
            },
            {
                "name": "Holsworthy",
                "countryCode": "AU"
            },
            {
                "name": "Homebush",
                "countryCode": "AU"
            },
            {
                "name": "Homebush West",
                "countryCode": "AU"
            },
            {
                "name": "Horningsea Park",
                "countryCode": "AU"
            },
            {
                "name": "Hornsby",
                "countryCode": "AU"
            },
            {
                "name": "Hornsby Heights",
                "countryCode": "AU"
            },
            {
                "name": "Hornsby Shire",
                "countryCode": "AU"
            },
            {
                "name": "Horsley",
                "countryCode": "AU"
            },
            {
                "name": "Horsley Park",
                "countryCode": "AU"
            },
            {
                "name": "Howlong",
                "countryCode": "AU"
            },
            {
                "name": "Hoxton Park",
                "countryCode": "AU"
            },
            {
                "name": "Hunters Hill",
                "countryCode": "AU"
            },
            {
                "name": "Hunterview",
                "countryCode": "AU"
            },
            {
                "name": "Huntingwood",
                "countryCode": "AU"
            },
            {
                "name": "Hurlstone Park",
                "countryCode": "AU"
            },
            {
                "name": "Hurstville",
                "countryCode": "AU"
            },
            {
                "name": "Hurstville Grove",
                "countryCode": "AU"
            },
            {
                "name": "Illawong",
                "countryCode": "AU"
            },
            {
                "name": "Iluka",
                "countryCode": "AU"
            },
            {
                "name": "Ingleburn",
                "countryCode": "AU"
            },
            {
                "name": "Inverell",
                "countryCode": "AU"
            },
            {
                "name": "Islington",
                "countryCode": "AU"
            },
            {
                "name": "Jamberoo",
                "countryCode": "AU"
            },
            {
                "name": "Jamisontown",
                "countryCode": "AU"
            },
            {
                "name": "Jannali",
                "countryCode": "AU"
            },
            {
                "name": "Jerilderie",
                "countryCode": "AU"
            },
            {
                "name": "Jerrabomberra",
                "countryCode": "AU"
            },
            {
                "name": "Jesmond",
                "countryCode": "AU"
            },
            {
                "name": "Jewells",
                "countryCode": "AU"
            },
            {
                "name": "Jilliby",
                "countryCode": "AU"
            },
            {
                "name": "Jindabyne",
                "countryCode": "AU"
            },
            {
                "name": "Jindera",
                "countryCode": "AU"
            },
            {
                "name": "Jordan Springs",
                "countryCode": "AU"
            },
            {
                "name": "Junction Hill",
                "countryCode": "AU"
            },
            {
                "name": "Junee",
                "countryCode": "AU"
            },
            {
                "name": "Kahibah",
                "countryCode": "AU"
            },
            {
                "name": "Kanahooka",
                "countryCode": "AU"
            },
            {
                "name": "Kandos",
                "countryCode": "AU"
            },
            {
                "name": "Kanwal",
                "countryCode": "AU"
            },
            {
                "name": "Kapooka",
                "countryCode": "AU"
            },
            {
                "name": "Karabar",
                "countryCode": "AU"
            },
            {
                "name": "Kareela",
                "countryCode": "AU"
            },
            {
                "name": "Kariong",
                "countryCode": "AU"
            },
            {
                "name": "Karuah",
                "countryCode": "AU"
            },
            {
                "name": "Katoomba",
                "countryCode": "AU"
            },
            {
                "name": "Kearns",
                "countryCode": "AU"
            },
            {
                "name": "Keiraville",
                "countryCode": "AU"
            },
            {
                "name": "Kellyville",
                "countryCode": "AU"
            },
            {
                "name": "Kellyville Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Kelso",
                "countryCode": "AU"
            },
            {
                "name": "Kemps Creek",
                "countryCode": "AU"
            },
            {
                "name": "Kempsey",
                "countryCode": "AU"
            },
            {
                "name": "Kendall",
                "countryCode": "AU"
            },
            {
                "name": "Kensington",
                "countryCode": "AU"
            },
            {
                "name": "Kenthurst",
                "countryCode": "AU"
            },
            {
                "name": "Kew",
                "countryCode": "AU"
            },
            {
                "name": "Kiama",
                "countryCode": "AU"
            },
            {
                "name": "Kiama Downs",
                "countryCode": "AU"
            },
            {
                "name": "Kilaben Bay",
                "countryCode": "AU"
            },
            {
                "name": "Killara",
                "countryCode": "AU"
            },
            {
                "name": "Killarney Heights",
                "countryCode": "AU"
            },
            {
                "name": "Killarney Vale",
                "countryCode": "AU"
            },
            {
                "name": "King Creek",
                "countryCode": "AU"
            },
            {
                "name": "Kings Langley",
                "countryCode": "AU"
            },
            {
                "name": "Kings Park",
                "countryCode": "AU"
            },
            {
                "name": "Kingscliff",
                "countryCode": "AU"
            },
            {
                "name": "Kingsford",
                "countryCode": "AU"
            },
            {
                "name": "Kingsgrove",
                "countryCode": "AU"
            },
            {
                "name": "Kingswood Park",
                "countryCode": "AU"
            },
            {
                "name": "Kirrawee",
                "countryCode": "AU"
            },
            {
                "name": "Kirribilli",
                "countryCode": "AU"
            },
            {
                "name": "Kogarah",
                "countryCode": "AU"
            },
            {
                "name": "Kogarah Bay",
                "countryCode": "AU"
            },
            {
                "name": "Koonawarra",
                "countryCode": "AU"
            },
            {
                "name": "Kooringal",
                "countryCode": "AU"
            },
            {
                "name": "Kootingal",
                "countryCode": "AU"
            },
            {
                "name": "Korora",
                "countryCode": "AU"
            },
            {
                "name": "Kosciuszko National Park",
                "countryCode": "AU"
            },
            {
                "name": "Kotara",
                "countryCode": "AU"
            },
            {
                "name": "Kotara South",
                "countryCode": "AU"
            },
            {
                "name": "Ku-ring-gai",
                "countryCode": "AU"
            },
            {
                "name": "Kurnell",
                "countryCode": "AU"
            },
            {
                "name": "Kurraba Point",
                "countryCode": "AU"
            },
            {
                "name": "Kurrajong Heights",
                "countryCode": "AU"
            },
            {
                "name": "Kurri Kurri",
                "countryCode": "AU"
            },
            {
                "name": "Kyle Bay",
                "countryCode": "AU"
            },
            {
                "name": "Kyogle",
                "countryCode": "AU"
            },
            {
                "name": "Lachlan",
                "countryCode": "AU"
            },
            {
                "name": "Lake Albert",
                "countryCode": "AU"
            },
            {
                "name": "Lake Cargelligo",
                "countryCode": "AU"
            },
            {
                "name": "Lake Cathie",
                "countryCode": "AU"
            },
            {
                "name": "Lake Haven",
                "countryCode": "AU"
            },
            {
                "name": "Lake Heights",
                "countryCode": "AU"
            },
            {
                "name": "Lake Illawarra",
                "countryCode": "AU"
            },
            {
                "name": "Lake Macquarie Shire",
                "countryCode": "AU"
            },
            {
                "name": "Lake Munmorah",
                "countryCode": "AU"
            },
            {
                "name": "Lakelands",
                "countryCode": "AU"
            },
            {
                "name": "Lakemba",
                "countryCode": "AU"
            },
            {
                "name": "Lakewood",
                "countryCode": "AU"
            },
            {
                "name": "Lalor Park",
                "countryCode": "AU"
            },
            {
                "name": "Lambton",
                "countryCode": "AU"
            },
            {
                "name": "Lane Cove",
                "countryCode": "AU"
            },
            {
                "name": "Lane Cove North",
                "countryCode": "AU"
            },
            {
                "name": "Lane Cove West",
                "countryCode": "AU"
            },
            {
                "name": "Lansvale",
                "countryCode": "AU"
            },
            {
                "name": "Largs",
                "countryCode": "AU"
            },
            {
                "name": "Lavington",
                "countryCode": "AU"
            },
            {
                "name": "Lawrence",
                "countryCode": "AU"
            },
            {
                "name": "Lawson",
                "countryCode": "AU"
            },
            {
                "name": "Leeton",
                "countryCode": "AU"
            },
            {
                "name": "Leichhardt",
                "countryCode": "AU"
            },
            {
                "name": "Lemon Tree Passage",
                "countryCode": "AU"
            },
            {
                "name": "Lennox Head",
                "countryCode": "AU"
            },
            {
                "name": "Leonay",
                "countryCode": "AU"
            },
            {
                "name": "Leppington",
                "countryCode": "AU"
            },
            {
                "name": "Lethbridge Park",
                "countryCode": "AU"
            },
            {
                "name": "Leumeah",
                "countryCode": "AU"
            },
            {
                "name": "Leura",
                "countryCode": "AU"
            },
            {
                "name": "Lewisham",
                "countryCode": "AU"
            },
            {
                "name": "Liberty Grove",
                "countryCode": "AU"
            },
            {
                "name": "Lidcombe",
                "countryCode": "AU"
            },
            {
                "name": "Lightning Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Lilli Pilli",
                "countryCode": "AU"
            },
            {
                "name": "Lilyfield",
                "countryCode": "AU"
            },
            {
                "name": "Lindfield",
                "countryCode": "AU"
            },
            {
                "name": "Lisarow",
                "countryCode": "AU"
            },
            {
                "name": "Lismore",
                "countryCode": "AU"
            },
            {
                "name": "Lismore Heights",
                "countryCode": "AU"
            },
            {
                "name": "Lismore Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Lithgow",
                "countryCode": "AU"
            },
            {
                "name": "Little Bay",
                "countryCode": "AU"
            },
            {
                "name": "Liverpool",
                "countryCode": "AU"
            },
            {
                "name": "Liverpool Plains",
                "countryCode": "AU"
            },
            {
                "name": "Llanarth",
                "countryCode": "AU"
            },
            {
                "name": "Llandilo",
                "countryCode": "AU"
            },
            {
                "name": "Lockhart",
                "countryCode": "AU"
            },
            {
                "name": "Loftus",
                "countryCode": "AU"
            },
            {
                "name": "Londonderry",
                "countryCode": "AU"
            },
            {
                "name": "Long Beach",
                "countryCode": "AU"
            },
            {
                "name": "Long Jetty",
                "countryCode": "AU"
            },
            {
                "name": "Longueville",
                "countryCode": "AU"
            },
            {
                "name": "Lorn",
                "countryCode": "AU"
            },
            {
                "name": "Luddenham",
                "countryCode": "AU"
            },
            {
                "name": "Lugarno",
                "countryCode": "AU"
            },
            {
                "name": "Lurnea",
                "countryCode": "AU"
            },
            {
                "name": "Macksville",
                "countryCode": "AU"
            },
            {
                "name": "Maclean",
                "countryCode": "AU"
            },
            {
                "name": "Macmasters Beach",
                "countryCode": "AU"
            },
            {
                "name": "Macquarie Fields",
                "countryCode": "AU"
            },
            {
                "name": "Macquarie Hills",
                "countryCode": "AU"
            },
            {
                "name": "Macquarie Links",
                "countryCode": "AU"
            },
            {
                "name": "Macquarie Park",
                "countryCode": "AU"
            },
            {
                "name": "Maitland",
                "countryCode": "AU"
            },
            {
                "name": "Maitland Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Maitland city centre",
                "countryCode": "AU"
            },
            {
                "name": "Malabar",
                "countryCode": "AU"
            },
            {
                "name": "Malua Bay",
                "countryCode": "AU"
            },
            {
                "name": "Mangerton",
                "countryCode": "AU"
            },
            {
                "name": "Manilla",
                "countryCode": "AU"
            },
            {
                "name": "Manly",
                "countryCode": "AU"
            },
            {
                "name": "Manly Vale",
                "countryCode": "AU"
            },
            {
                "name": "Mannering Park",
                "countryCode": "AU"
            },
            {
                "name": "Maraylya",
                "countryCode": "AU"
            },
            {
                "name": "Marayong",
                "countryCode": "AU"
            },
            {
                "name": "Mardi",
                "countryCode": "AU"
            },
            {
                "name": "Marks Point",
                "countryCode": "AU"
            },
            {
                "name": "Maroubra",
                "countryCode": "AU"
            },
            {
                "name": "Marrickville",
                "countryCode": "AU"
            },
            {
                "name": "Marsfield",
                "countryCode": "AU"
            },
            {
                "name": "Marulan",
                "countryCode": "AU"
            },
            {
                "name": "Maryland",
                "countryCode": "AU"
            },
            {
                "name": "Maryville",
                "countryCode": "AU"
            },
            {
                "name": "Mascot",
                "countryCode": "AU"
            },
            {
                "name": "Matraville",
                "countryCode": "AU"
            },
            {
                "name": "Mayfield",
                "countryCode": "AU"
            },
            {
                "name": "Mayfield East",
                "countryCode": "AU"
            },
            {
                "name": "Mayfield West",
                "countryCode": "AU"
            },
            {
                "name": "McGraths Hill",
                "countryCode": "AU"
            },
            {
                "name": "McMahons Point",
                "countryCode": "AU"
            },
            {
                "name": "Meadowbank",
                "countryCode": "AU"
            },
            {
                "name": "Medowie",
                "countryCode": "AU"
            },
            {
                "name": "Melrose Park",
                "countryCode": "AU"
            },
            {
                "name": "Menai",
                "countryCode": "AU"
            },
            {
                "name": "Menangle",
                "countryCode": "AU"
            },
            {
                "name": "Merewether",
                "countryCode": "AU"
            },
            {
                "name": "Merewether Heights",
                "countryCode": "AU"
            },
            {
                "name": "Merimbula",
                "countryCode": "AU"
            },
            {
                "name": "Merriwa",
                "countryCode": "AU"
            },
            {
                "name": "Merrylands",
                "countryCode": "AU"
            },
            {
                "name": "Merrylands West",
                "countryCode": "AU"
            },
            {
                "name": "Metford",
                "countryCode": "AU"
            },
            {
                "name": "Mid-Western Regional",
                "countryCode": "AU"
            },
            {
                "name": "Middle Cove",
                "countryCode": "AU"
            },
            {
                "name": "Middleton Grange",
                "countryCode": "AU"
            },
            {
                "name": "Miller",
                "countryCode": "AU"
            },
            {
                "name": "Millers Point",
                "countryCode": "AU"
            },
            {
                "name": "Millthorpe",
                "countryCode": "AU"
            },
            {
                "name": "Milperra",
                "countryCode": "AU"
            },
            {
                "name": "Milsons Point",
                "countryCode": "AU"
            },
            {
                "name": "Milton",
                "countryCode": "AU"
            },
            {
                "name": "Minchinbury",
                "countryCode": "AU"
            },
            {
                "name": "Minto",
                "countryCode": "AU"
            },
            {
                "name": "Miranda",
                "countryCode": "AU"
            },
            {
                "name": "Mitchell",
                "countryCode": "AU"
            },
            {
                "name": "Mittagong",
                "countryCode": "AU"
            },
            {
                "name": "Moama",
                "countryCode": "AU"
            },
            {
                "name": "Mollymook",
                "countryCode": "AU"
            },
            {
                "name": "Mollymook Beach",
                "countryCode": "AU"
            },
            {
                "name": "Molong",
                "countryCode": "AU"
            },
            {
                "name": "Mona Vale",
                "countryCode": "AU"
            },
            {
                "name": "Monterey",
                "countryCode": "AU"
            },
            {
                "name": "Moonbi",
                "countryCode": "AU"
            },
            {
                "name": "Moonee Beach",
                "countryCode": "AU"
            },
            {
                "name": "Moorebank",
                "countryCode": "AU"
            },
            {
                "name": "Moree",
                "countryCode": "AU"
            },
            {
                "name": "Moree Plains",
                "countryCode": "AU"
            },
            {
                "name": "Morisset",
                "countryCode": "AU"
            },
            {
                "name": "Morpeth",
                "countryCode": "AU"
            },
            {
                "name": "Mortdale",
                "countryCode": "AU"
            },
            {
                "name": "Mortlake",
                "countryCode": "AU"
            },
            {
                "name": "Moruya",
                "countryCode": "AU"
            },
            {
                "name": "Mosman",
                "countryCode": "AU"
            },
            {
                "name": "Moss Vale",
                "countryCode": "AU"
            },
            {
                "name": "Mount Annan",
                "countryCode": "AU"
            },
            {
                "name": "Mount Austin",
                "countryCode": "AU"
            },
            {
                "name": "Mount Colah",
                "countryCode": "AU"
            },
            {
                "name": "Mount Druitt",
                "countryCode": "AU"
            },
            {
                "name": "Mount Hutton",
                "countryCode": "AU"
            },
            {
                "name": "Mount Keira",
                "countryCode": "AU"
            },
            {
                "name": "Mount Kembla",
                "countryCode": "AU"
            },
            {
                "name": "Mount Kuring-Gai",
                "countryCode": "AU"
            },
            {
                "name": "Mount Lewis",
                "countryCode": "AU"
            },
            {
                "name": "Mount Ousley",
                "countryCode": "AU"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "AU"
            },
            {
                "name": "Mount Pritchard",
                "countryCode": "AU"
            },
            {
                "name": "Mount Riverview",
                "countryCode": "AU"
            },
            {
                "name": "Mount Saint Thomas",
                "countryCode": "AU"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "AU"
            },
            {
                "name": "Mount Warrigal",
                "countryCode": "AU"
            },
            {
                "name": "Mudgee",
                "countryCode": "AU"
            },
            {
                "name": "Mulgoa",
                "countryCode": "AU"
            },
            {
                "name": "Mullumbimby",
                "countryCode": "AU"
            },
            {
                "name": "Mulwala",
                "countryCode": "AU"
            },
            {
                "name": "Murrumbateman",
                "countryCode": "AU"
            },
            {
                "name": "Murrurundi",
                "countryCode": "AU"
            },
            {
                "name": "Murwillumbah",
                "countryCode": "AU"
            },
            {
                "name": "Muswellbrook",
                "countryCode": "AU"
            },
            {
                "name": "Nabiac",
                "countryCode": "AU"
            },
            {
                "name": "Nambucca",
                "countryCode": "AU"
            },
            {
                "name": "Nambucca Heads",
                "countryCode": "AU"
            },
            {
                "name": "Nambucca Shire",
                "countryCode": "AU"
            },
            {
                "name": "Nana Glen",
                "countryCode": "AU"
            },
            {
                "name": "Narara",
                "countryCode": "AU"
            },
            {
                "name": "Narellan",
                "countryCode": "AU"
            },
            {
                "name": "Narellan Vale",
                "countryCode": "AU"
            },
            {
                "name": "Naremburn",
                "countryCode": "AU"
            },
            {
                "name": "Narooma",
                "countryCode": "AU"
            },
            {
                "name": "Narrabeen",
                "countryCode": "AU"
            },
            {
                "name": "Narrabri",
                "countryCode": "AU"
            },
            {
                "name": "Narrandera",
                "countryCode": "AU"
            },
            {
                "name": "Narrawallee",
                "countryCode": "AU"
            },
            {
                "name": "Narraweena",
                "countryCode": "AU"
            },
            {
                "name": "Narromine",
                "countryCode": "AU"
            },
            {
                "name": "Narwee",
                "countryCode": "AU"
            },
            {
                "name": "Nelson Bay",
                "countryCode": "AU"
            },
            {
                "name": "Neutral Bay",
                "countryCode": "AU"
            },
            {
                "name": "New Lambton",
                "countryCode": "AU"
            },
            {
                "name": "New Lambton Heights",
                "countryCode": "AU"
            },
            {
                "name": "Newcastle",
                "countryCode": "AU"
            },
            {
                "name": "Newcastle East",
                "countryCode": "AU"
            },
            {
                "name": "Newcastle city centre",
                "countryCode": "AU"
            },
            {
                "name": "Newington",
                "countryCode": "AU"
            },
            {
                "name": "Newport",
                "countryCode": "AU"
            },
            {
                "name": "Newtown",
                "countryCode": "AU"
            },
            {
                "name": "Niagara Park",
                "countryCode": "AU"
            },
            {
                "name": "Nimbin",
                "countryCode": "AU"
            },
            {
                "name": "Noraville",
                "countryCode": "AU"
            },
            {
                "name": "Normanhurst",
                "countryCode": "AU"
            },
            {
                "name": "North Albury",
                "countryCode": "AU"
            },
            {
                "name": "North Avoca",
                "countryCode": "AU"
            },
            {
                "name": "North Balgowlah",
                "countryCode": "AU"
            },
            {
                "name": "North Boambee Valley",
                "countryCode": "AU"
            },
            {
                "name": "North Bondi",
                "countryCode": "AU"
            },
            {
                "name": "North Curl Curl",
                "countryCode": "AU"
            },
            {
                "name": "North Epping",
                "countryCode": "AU"
            },
            {
                "name": "North Gosford",
                "countryCode": "AU"
            },
            {
                "name": "North Haven",
                "countryCode": "AU"
            },
            {
                "name": "North Lambton",
                "countryCode": "AU"
            },
            {
                "name": "North Manly",
                "countryCode": "AU"
            },
            {
                "name": "North Narrabeen",
                "countryCode": "AU"
            },
            {
                "name": "North Nowra",
                "countryCode": "AU"
            },
            {
                "name": "North Parramatta",
                "countryCode": "AU"
            },
            {
                "name": "North Richmond",
                "countryCode": "AU"
            },
            {
                "name": "North Rocks",
                "countryCode": "AU"
            },
            {
                "name": "North Ryde",
                "countryCode": "AU"
            },
            {
                "name": "North St Marys",
                "countryCode": "AU"
            },
            {
                "name": "North Strathfield",
                "countryCode": "AU"
            },
            {
                "name": "North Sydney",
                "countryCode": "AU"
            },
            {
                "name": "North Tamworth",
                "countryCode": "AU"
            },
            {
                "name": "North Turramurra",
                "countryCode": "AU"
            },
            {
                "name": "North Wahroonga",
                "countryCode": "AU"
            },
            {
                "name": "North Willoughby",
                "countryCode": "AU"
            },
            {
                "name": "North Wollongong",
                "countryCode": "AU"
            },
            {
                "name": "Northbridge",
                "countryCode": "AU"
            },
            {
                "name": "Northmead",
                "countryCode": "AU"
            },
            {
                "name": "Nowra",
                "countryCode": "AU"
            },
            {
                "name": "Nowra Hill",
                "countryCode": "AU"
            },
            {
                "name": "Nyngan",
                "countryCode": "AU"
            },
            {
                "name": "Oak Flats",
                "countryCode": "AU"
            },
            {
                "name": "Oakdale",
                "countryCode": "AU"
            },
            {
                "name": "Oakhurst",
                "countryCode": "AU"
            },
            {
                "name": "Oakville",
                "countryCode": "AU"
            },
            {
                "name": "Oatlands",
                "countryCode": "AU"
            },
            {
                "name": "Oatley",
                "countryCode": "AU"
            },
            {
                "name": "Oberon",
                "countryCode": "AU"
            },
            {
                "name": "Ocean Shores",
                "countryCode": "AU"
            },
            {
                "name": "Old Bar",
                "countryCode": "AU"
            },
            {
                "name": "Old Erowal Bay",
                "countryCode": "AU"
            },
            {
                "name": "Old Guildford",
                "countryCode": "AU"
            },
            {
                "name": "Old Toongabbie",
                "countryCode": "AU"
            },
            {
                "name": "Oran Park",
                "countryCode": "AU"
            },
            {
                "name": "Orange",
                "countryCode": "AU"
            },
            {
                "name": "Orange Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Orangeville",
                "countryCode": "AU"
            },
            {
                "name": "Orchard Hills",
                "countryCode": "AU"
            },
            {
                "name": "Ourimbah",
                "countryCode": "AU"
            },
            {
                "name": "Oxley Park",
                "countryCode": "AU"
            },
            {
                "name": "Oxley Vale",
                "countryCode": "AU"
            },
            {
                "name": "Oyster Bay",
                "countryCode": "AU"
            },
            {
                "name": "Paddington",
                "countryCode": "AU"
            },
            {
                "name": "Padstow",
                "countryCode": "AU"
            },
            {
                "name": "Padstow Heights",
                "countryCode": "AU"
            },
            {
                "name": "Pagewood",
                "countryCode": "AU"
            },
            {
                "name": "Palm Beach",
                "countryCode": "AU"
            },
            {
                "name": "Panania",
                "countryCode": "AU"
            },
            {
                "name": "Parkes",
                "countryCode": "AU"
            },
            {
                "name": "Parklea",
                "countryCode": "AU"
            },
            {
                "name": "Parramatta",
                "countryCode": "AU"
            },
            {
                "name": "Peak Hill",
                "countryCode": "AU"
            },
            {
                "name": "Peakhurst",
                "countryCode": "AU"
            },
            {
                "name": "Peakhurst Heights",
                "countryCode": "AU"
            },
            {
                "name": "Pemulwuy",
                "countryCode": "AU"
            },
            {
                "name": "Pendle Hill",
                "countryCode": "AU"
            },
            {
                "name": "Pennant Hills",
                "countryCode": "AU"
            },
            {
                "name": "Penrith",
                "countryCode": "AU"
            },
            {
                "name": "Penrith Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Penshurst",
                "countryCode": "AU"
            },
            {
                "name": "Petersham",
                "countryCode": "AU"
            },
            {
                "name": "Phillip",
                "countryCode": "AU"
            },
            {
                "name": "Picnic Point",
                "countryCode": "AU"
            },
            {
                "name": "Picton",
                "countryCode": "AU"
            },
            {
                "name": "Pitt Town",
                "countryCode": "AU"
            },
            {
                "name": "Plumpton",
                "countryCode": "AU"
            },
            {
                "name": "Point Clare",
                "countryCode": "AU"
            },
            {
                "name": "Point Frederick",
                "countryCode": "AU"
            },
            {
                "name": "Point Piper",
                "countryCode": "AU"
            },
            {
                "name": "Pokolbin",
                "countryCode": "AU"
            },
            {
                "name": "Port Hacking",
                "countryCode": "AU"
            },
            {
                "name": "Port Kembla",
                "countryCode": "AU"
            },
            {
                "name": "Port Macquarie",
                "countryCode": "AU"
            },
            {
                "name": "Port Macquarie-Hastings",
                "countryCode": "AU"
            },
            {
                "name": "Port Stephens Shire",
                "countryCode": "AU"
            },
            {
                "name": "Portland",
                "countryCode": "AU"
            },
            {
                "name": "Potts Point",
                "countryCode": "AU"
            },
            {
                "name": "Pottsville",
                "countryCode": "AU"
            },
            {
                "name": "Pottsville Beach",
                "countryCode": "AU"
            },
            {
                "name": "Prairiewood",
                "countryCode": "AU"
            },
            {
                "name": "Prestons",
                "countryCode": "AU"
            },
            {
                "name": "Primbee",
                "countryCode": "AU"
            },
            {
                "name": "Prospect",
                "countryCode": "AU"
            },
            {
                "name": "Punchbowl",
                "countryCode": "AU"
            },
            {
                "name": "Putney",
                "countryCode": "AU"
            },
            {
                "name": "Pymble",
                "countryCode": "AU"
            },
            {
                "name": "Pyrmont",
                "countryCode": "AU"
            },
            {
                "name": "Quakers Hill",
                "countryCode": "AU"
            },
            {
                "name": "Queanbeyan",
                "countryCode": "AU"
            },
            {
                "name": "Queanbeyan East",
                "countryCode": "AU"
            },
            {
                "name": "Queanbeyan West",
                "countryCode": "AU"
            },
            {
                "name": "Queens Park",
                "countryCode": "AU"
            },
            {
                "name": "Queenscliff",
                "countryCode": "AU"
            },
            {
                "name": "Quirindi",
                "countryCode": "AU"
            },
            {
                "name": "Raby",
                "countryCode": "AU"
            },
            {
                "name": "Raglan",
                "countryCode": "AU"
            },
            {
                "name": "Ramsgate",
                "countryCode": "AU"
            },
            {
                "name": "Ramsgate Beach",
                "countryCode": "AU"
            },
            {
                "name": "Randwick",
                "countryCode": "AU"
            },
            {
                "name": "Rankin Park",
                "countryCode": "AU"
            },
            {
                "name": "Rathmines",
                "countryCode": "AU"
            },
            {
                "name": "Raworth",
                "countryCode": "AU"
            },
            {
                "name": "Raymond Terrace",
                "countryCode": "AU"
            },
            {
                "name": "Razorback",
                "countryCode": "AU"
            },
            {
                "name": "Redfern",
                "countryCode": "AU"
            },
            {
                "name": "Redhead",
                "countryCode": "AU"
            },
            {
                "name": "Regents Park",
                "countryCode": "AU"
            },
            {
                "name": "Revesby",
                "countryCode": "AU"
            },
            {
                "name": "Revesby Heights",
                "countryCode": "AU"
            },
            {
                "name": "Rhodes",
                "countryCode": "AU"
            },
            {
                "name": "Richmond",
                "countryCode": "AU"
            },
            {
                "name": "Richmond Valley",
                "countryCode": "AU"
            },
            {
                "name": "Riverstone",
                "countryCode": "AU"
            },
            {
                "name": "Riverview",
                "countryCode": "AU"
            },
            {
                "name": "Riverwood",
                "countryCode": "AU"
            },
            {
                "name": "Robertson",
                "countryCode": "AU"
            },
            {
                "name": "Rockdale",
                "countryCode": "AU"
            },
            {
                "name": "Rodd Point",
                "countryCode": "AU"
            },
            {
                "name": "Rooty Hill",
                "countryCode": "AU"
            },
            {
                "name": "Ropes Crossing",
                "countryCode": "AU"
            },
            {
                "name": "Rose Bay",
                "countryCode": "AU"
            },
            {
                "name": "Rosebery",
                "countryCode": "AU"
            },
            {
                "name": "Rosehill",
                "countryCode": "AU"
            },
            {
                "name": "Roselands",
                "countryCode": "AU"
            },
            {
                "name": "Rosemeadow",
                "countryCode": "AU"
            },
            {
                "name": "Roseville",
                "countryCode": "AU"
            },
            {
                "name": "Roseville Chase",
                "countryCode": "AU"
            },
            {
                "name": "Rouse Hill",
                "countryCode": "AU"
            },
            {
                "name": "Rozelle",
                "countryCode": "AU"
            },
            {
                "name": "Ruse",
                "countryCode": "AU"
            },
            {
                "name": "Rushcutters Bay",
                "countryCode": "AU"
            },
            {
                "name": "Russell Lea",
                "countryCode": "AU"
            },
            {
                "name": "Russell Vale",
                "countryCode": "AU"
            },
            {
                "name": "Rutherford",
                "countryCode": "AU"
            },
            {
                "name": "Rydalmere",
                "countryCode": "AU"
            },
            {
                "name": "Ryde",
                "countryCode": "AU"
            },
            {
                "name": "Sadleir",
                "countryCode": "AU"
            },
            {
                "name": "Saint Ives",
                "countryCode": "AU"
            },
            {
                "name": "Saint Peters",
                "countryCode": "AU"
            },
            {
                "name": "Salamander Bay",
                "countryCode": "AU"
            },
            {
                "name": "Salt Ash",
                "countryCode": "AU"
            },
            {
                "name": "San Remo",
                "countryCode": "AU"
            },
            {
                "name": "Sanctuary Point",
                "countryCode": "AU"
            },
            {
                "name": "Sandringham",
                "countryCode": "AU"
            },
            {
                "name": "Sandy Beach",
                "countryCode": "AU"
            },
            {
                "name": "Sans Souci",
                "countryCode": "AU"
            },
            {
                "name": "Sapphire Beach",
                "countryCode": "AU"
            },
            {
                "name": "Saratoga",
                "countryCode": "AU"
            },
            {
                "name": "Sawtell",
                "countryCode": "AU"
            },
            {
                "name": "Schofields",
                "countryCode": "AU"
            },
            {
                "name": "Scone",
                "countryCode": "AU"
            },
            {
                "name": "Seaforth",
                "countryCode": "AU"
            },
            {
                "name": "Sefton",
                "countryCode": "AU"
            },
            {
                "name": "Seven Hills",
                "countryCode": "AU"
            },
            {
                "name": "Shalvey",
                "countryCode": "AU"
            },
            {
                "name": "Shell Cove",
                "countryCode": "AU"
            },
            {
                "name": "Shellharbour",
                "countryCode": "AU"
            },
            {
                "name": "Shelly Beach",
                "countryCode": "AU"
            },
            {
                "name": "Shoal Bay",
                "countryCode": "AU"
            },
            {
                "name": "Shoalhaven Heads",
                "countryCode": "AU"
            },
            {
                "name": "Shoalhaven Shire",
                "countryCode": "AU"
            },
            {
                "name": "Shortland",
                "countryCode": "AU"
            },
            {
                "name": "Silverdale",
                "countryCode": "AU"
            },
            {
                "name": "Silverwater",
                "countryCode": "AU"
            },
            {
                "name": "Singleton",
                "countryCode": "AU"
            },
            {
                "name": "Singleton Heights",
                "countryCode": "AU"
            },
            {
                "name": "Skennars Head",
                "countryCode": "AU"
            },
            {
                "name": "Smithfield",
                "countryCode": "AU"
            },
            {
                "name": "Smiths Lake",
                "countryCode": "AU"
            },
            {
                "name": "Soldiers Point",
                "countryCode": "AU"
            },
            {
                "name": "Somersby",
                "countryCode": "AU"
            },
            {
                "name": "South Albury",
                "countryCode": "AU"
            },
            {
                "name": "South Bathurst",
                "countryCode": "AU"
            },
            {
                "name": "South Bowenfels",
                "countryCode": "AU"
            },
            {
                "name": "South Coogee",
                "countryCode": "AU"
            },
            {
                "name": "South Grafton",
                "countryCode": "AU"
            },
            {
                "name": "South Granville",
                "countryCode": "AU"
            },
            {
                "name": "South Hurstville",
                "countryCode": "AU"
            },
            {
                "name": "South Kempsey",
                "countryCode": "AU"
            },
            {
                "name": "South Lismore",
                "countryCode": "AU"
            },
            {
                "name": "South Murwillumbah",
                "countryCode": "AU"
            },
            {
                "name": "South Nowra",
                "countryCode": "AU"
            },
            {
                "name": "South Penrith",
                "countryCode": "AU"
            },
            {
                "name": "South Tamworth",
                "countryCode": "AU"
            },
            {
                "name": "South Turramurra",
                "countryCode": "AU"
            },
            {
                "name": "South Wentworthville",
                "countryCode": "AU"
            },
            {
                "name": "South West Rocks",
                "countryCode": "AU"
            },
            {
                "name": "South Windsor",
                "countryCode": "AU"
            },
            {
                "name": "Speers Point",
                "countryCode": "AU"
            },
            {
                "name": "Spring Farm",
                "countryCode": "AU"
            },
            {
                "name": "Springdale Heights",
                "countryCode": "AU"
            },
            {
                "name": "Springvale",
                "countryCode": "AU"
            },
            {
                "name": "Springwood",
                "countryCode": "AU"
            },
            {
                "name": "St Andrews",
                "countryCode": "AU"
            },
            {
                "name": "St Clair",
                "countryCode": "AU"
            },
            {
                "name": "St Helens Park",
                "countryCode": "AU"
            },
            {
                "name": "St Huberts Island",
                "countryCode": "AU"
            },
            {
                "name": "St Ives Chase",
                "countryCode": "AU"
            },
            {
                "name": "St Johns Park",
                "countryCode": "AU"
            },
            {
                "name": "St Leonards",
                "countryCode": "AU"
            },
            {
                "name": "St. Georges Basin",
                "countryCode": "AU"
            },
            {
                "name": "Stanhope Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Stanmore",
                "countryCode": "AU"
            },
            {
                "name": "Stanwell Park",
                "countryCode": "AU"
            },
            {
                "name": "Stockton",
                "countryCode": "AU"
            },
            {
                "name": "Strathfield",
                "countryCode": "AU"
            },
            {
                "name": "Strathfield South",
                "countryCode": "AU"
            },
            {
                "name": "Suffolk Park",
                "countryCode": "AU"
            },
            {
                "name": "Summer Hill",
                "countryCode": "AU"
            },
            {
                "name": "Summerland Point",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine Bay",
                "countryCode": "AU"
            },
            {
                "name": "Surfside",
                "countryCode": "AU"
            },
            {
                "name": "Surry Hills",
                "countryCode": "AU"
            },
            {
                "name": "Sussex Inlet",
                "countryCode": "AU"
            },
            {
                "name": "Sutherland",
                "countryCode": "AU"
            },
            {
                "name": "Sutherland Shire",
                "countryCode": "AU"
            },
            {
                "name": "Sutton",
                "countryCode": "AU"
            },
            {
                "name": "Swansea",
                "countryCode": "AU"
            },
            {
                "name": "Sydenham",
                "countryCode": "AU"
            },
            {
                "name": "Sydney",
                "countryCode": "AU"
            },
            {
                "name": "Sydney Central Business District",
                "countryCode": "AU"
            },
            {
                "name": "Sydney Olympic Park",
                "countryCode": "AU"
            },
            {
                "name": "Sylvania",
                "countryCode": "AU"
            },
            {
                "name": "Sylvania Waters",
                "countryCode": "AU"
            },
            {
                "name": "Table Top",
                "countryCode": "AU"
            },
            {
                "name": "Tahmoor",
                "countryCode": "AU"
            },
            {
                "name": "Tamarama",
                "countryCode": "AU"
            },
            {
                "name": "Tamworth",
                "countryCode": "AU"
            },
            {
                "name": "Tamworth Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Tanilba Bay",
                "countryCode": "AU"
            },
            {
                "name": "Taree",
                "countryCode": "AU"
            },
            {
                "name": "Taren Point",
                "countryCode": "AU"
            },
            {
                "name": "Tarrawanna",
                "countryCode": "AU"
            },
            {
                "name": "Tarro",
                "countryCode": "AU"
            },
            {
                "name": "Tascott",
                "countryCode": "AU"
            },
            {
                "name": "Tathra",
                "countryCode": "AU"
            },
            {
                "name": "Tatton",
                "countryCode": "AU"
            },
            {
                "name": "Tea Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Telarah",
                "countryCode": "AU"
            },
            {
                "name": "Telopea",
                "countryCode": "AU"
            },
            {
                "name": "Temora",
                "countryCode": "AU"
            },
            {
                "name": "Temora Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Tempe",
                "countryCode": "AU"
            },
            {
                "name": "Tenambit",
                "countryCode": "AU"
            },
            {
                "name": "Tennyson Point",
                "countryCode": "AU"
            },
            {
                "name": "Tenterfield",
                "countryCode": "AU"
            },
            {
                "name": "Tenterfield Municipality",
                "countryCode": "AU"
            },
            {
                "name": "Teralba",
                "countryCode": "AU"
            },
            {
                "name": "Terranora",
                "countryCode": "AU"
            },
            {
                "name": "Terrigal",
                "countryCode": "AU"
            },
            {
                "name": "Terry Hills",
                "countryCode": "AU"
            },
            {
                "name": "The Entrance",
                "countryCode": "AU"
            },
            {
                "name": "The Entrance North",
                "countryCode": "AU"
            },
            {
                "name": "The Hill",
                "countryCode": "AU"
            },
            {
                "name": "The Hills Shire",
                "countryCode": "AU"
            },
            {
                "name": "The Junction",
                "countryCode": "AU"
            },
            {
                "name": "The Oaks",
                "countryCode": "AU"
            },
            {
                "name": "The Ponds",
                "countryCode": "AU"
            },
            {
                "name": "The Rock",
                "countryCode": "AU"
            },
            {
                "name": "The Rocks",
                "countryCode": "AU"
            },
            {
                "name": "Thirlmere",
                "countryCode": "AU"
            },
            {
                "name": "Thirroul",
                "countryCode": "AU"
            },
            {
                "name": "Thornleigh",
                "countryCode": "AU"
            },
            {
                "name": "Thornton",
                "countryCode": "AU"
            },
            {
                "name": "Thurgoona",
                "countryCode": "AU"
            },
            {
                "name": "Tighes Hill",
                "countryCode": "AU"
            },
            {
                "name": "Tingira Heights",
                "countryCode": "AU"
            },
            {
                "name": "Tinonee",
                "countryCode": "AU"
            },
            {
                "name": "Tocumwal",
                "countryCode": "AU"
            },
            {
                "name": "Tolland",
                "countryCode": "AU"
            },
            {
                "name": "Tomakin",
                "countryCode": "AU"
            },
            {
                "name": "Tomerong",
                "countryCode": "AU"
            },
            {
                "name": "Toongabbie",
                "countryCode": "AU"
            },
            {
                "name": "Toormina",
                "countryCode": "AU"
            },
            {
                "name": "Toronto",
                "countryCode": "AU"
            },
            {
                "name": "Toukley",
                "countryCode": "AU"
            },
            {
                "name": "Towradgi",
                "countryCode": "AU"
            },
            {
                "name": "Trangie",
                "countryCode": "AU"
            },
            {
                "name": "Tregear",
                "countryCode": "AU"
            },
            {
                "name": "Tuggerawong",
                "countryCode": "AU"
            },
            {
                "name": "Tumbarumba",
                "countryCode": "AU"
            },
            {
                "name": "Tumbi Vmbi",
                "countryCode": "AU"
            },
            {
                "name": "Tumut",
                "countryCode": "AU"
            },
            {
                "name": "Tuncurry",
                "countryCode": "AU"
            },
            {
                "name": "Tura Beach",
                "countryCode": "AU"
            },
            {
                "name": "Tuross Head",
                "countryCode": "AU"
            },
            {
                "name": "Turramurra",
                "countryCode": "AU"
            },
            {
                "name": "Turrella",
                "countryCode": "AU"
            },
            {
                "name": "Turvey Park",
                "countryCode": "AU"
            },
            {
                "name": "Tweed",
                "countryCode": "AU"
            },
            {
                "name": "Tweed Heads",
                "countryCode": "AU"
            },
            {
                "name": "Tweed Heads South",
                "countryCode": "AU"
            },
            {
                "name": "Tweed Heads West",
                "countryCode": "AU"
            },
            {
                "name": "Ulladulla",
                "countryCode": "AU"
            },
            {
                "name": "Ultimo",
                "countryCode": "AU"
            },
            {
                "name": "Umina Beach",
                "countryCode": "AU"
            },
            {
                "name": "Unanderra",
                "countryCode": "AU"
            },
            {
                "name": "Upper Hunter Shire",
                "countryCode": "AU"
            },
            {
                "name": "Upper Lachlan Shire",
                "countryCode": "AU"
            },
            {
                "name": "Uralla",
                "countryCode": "AU"
            },
            {
                "name": "Urunga",
                "countryCode": "AU"
            },
            {
                "name": "Valentine",
                "countryCode": "AU"
            },
            {
                "name": "Valla Beach",
                "countryCode": "AU"
            },
            {
                "name": "Valley Heights",
                "countryCode": "AU"
            },
            {
                "name": "Vaucluse",
                "countryCode": "AU"
            },
            {
                "name": "Villawood",
                "countryCode": "AU"
            },
            {
                "name": "Vincentia",
                "countryCode": "AU"
            },
            {
                "name": "Vineyard",
                "countryCode": "AU"
            },
            {
                "name": "Voyager Point",
                "countryCode": "AU"
            },
            {
                "name": "Wadalba",
                "countryCode": "AU"
            },
            {
                "name": "Wagga Wagga",
                "countryCode": "AU"
            },
            {
                "name": "Wahroonga",
                "countryCode": "AU"
            },
            {
                "name": "Waitara",
                "countryCode": "AU"
            },
            {
                "name": "Wakeley",
                "countryCode": "AU"
            },
            {
                "name": "Walcha",
                "countryCode": "AU"
            },
            {
                "name": "Walgett",
                "countryCode": "AU"
            },
            {
                "name": "Wallacia",
                "countryCode": "AU"
            },
            {
                "name": "Wallalong",
                "countryCode": "AU"
            },
            {
                "name": "Wallerawang",
                "countryCode": "AU"
            },
            {
                "name": "Wallsend",
                "countryCode": "AU"
            },
            {
                "name": "Wamberal",
                "countryCode": "AU"
            },
            {
                "name": "Wamboin",
                "countryCode": "AU"
            },
            {
                "name": "Wangi Wangi",
                "countryCode": "AU"
            },
            {
                "name": "Warabrook",
                "countryCode": "AU"
            },
            {
                "name": "Waratah",
                "countryCode": "AU"
            },
            {
                "name": "Waratah West",
                "countryCode": "AU"
            },
            {
                "name": "Wareemba",
                "countryCode": "AU"
            },
            {
                "name": "Warialda",
                "countryCode": "AU"
            },
            {
                "name": "Warilla",
                "countryCode": "AU"
            },
            {
                "name": "Warners Bay",
                "countryCode": "AU"
            },
            {
                "name": "Warragamba",
                "countryCode": "AU"
            },
            {
                "name": "Warrawee",
                "countryCode": "AU"
            },
            {
                "name": "Warrawong",
                "countryCode": "AU"
            },
            {
                "name": "Warren",
                "countryCode": "AU"
            },
            {
                "name": "Warren Shire",
                "countryCode": "AU"
            },
            {
                "name": "Warriewood",
                "countryCode": "AU"
            },
            {
                "name": "Warrimoo",
                "countryCode": "AU"
            },
            {
                "name": "Warrumbungle Shire",
                "countryCode": "AU"
            },
            {
                "name": "Warwick Farm",
                "countryCode": "AU"
            },
            {
                "name": "Watanobbi",
                "countryCode": "AU"
            },
            {
                "name": "Waterview Heights",
                "countryCode": "AU"
            },
            {
                "name": "Wattle Grove",
                "countryCode": "AU"
            },
            {
                "name": "Wattle Ponds",
                "countryCode": "AU"
            },
            {
                "name": "Wauchope",
                "countryCode": "AU"
            },
            {
                "name": "Waverley",
                "countryCode": "AU"
            },
            {
                "name": "Waverton",
                "countryCode": "AU"
            },
            {
                "name": "Weddin",
                "countryCode": "AU"
            },
            {
                "name": "Wee Waa",
                "countryCode": "AU"
            },
            {
                "name": "Wellington",
                "countryCode": "AU"
            },
            {
                "name": "Wentworth",
                "countryCode": "AU"
            },
            {
                "name": "Wentworth Falls",
                "countryCode": "AU"
            },
            {
                "name": "Wentworth Point",
                "countryCode": "AU"
            },
            {
                "name": "Wentworthville",
                "countryCode": "AU"
            },
            {
                "name": "Werrington",
                "countryCode": "AU"
            },
            {
                "name": "Werrington County",
                "countryCode": "AU"
            },
            {
                "name": "Werrington Downs",
                "countryCode": "AU"
            },
            {
                "name": "Werris Creek",
                "countryCode": "AU"
            },
            {
                "name": "West Albury",
                "countryCode": "AU"
            },
            {
                "name": "West Ballina",
                "countryCode": "AU"
            },
            {
                "name": "West Bathurst",
                "countryCode": "AU"
            },
            {
                "name": "West Gosford",
                "countryCode": "AU"
            },
            {
                "name": "West Haven",
                "countryCode": "AU"
            },
            {
                "name": "West Hoxton",
                "countryCode": "AU"
            },
            {
                "name": "West Kempsey",
                "countryCode": "AU"
            },
            {
                "name": "West Nowra",
                "countryCode": "AU"
            },
            {
                "name": "West Pennant Hills",
                "countryCode": "AU"
            },
            {
                "name": "West Pymble",
                "countryCode": "AU"
            },
            {
                "name": "West Ryde",
                "countryCode": "AU"
            },
            {
                "name": "West Tamworth",
                "countryCode": "AU"
            },
            {
                "name": "West Wallsend",
                "countryCode": "AU"
            },
            {
                "name": "West Wollongong",
                "countryCode": "AU"
            },
            {
                "name": "West Wyalong",
                "countryCode": "AU"
            },
            {
                "name": "Westdale",
                "countryCode": "AU"
            },
            {
                "name": "Westleigh",
                "countryCode": "AU"
            },
            {
                "name": "Westmead",
                "countryCode": "AU"
            },
            {
                "name": "Wetherill Park",
                "countryCode": "AU"
            },
            {
                "name": "Whalan",
                "countryCode": "AU"
            },
            {
                "name": "Whale Beach",
                "countryCode": "AU"
            },
            {
                "name": "Wheeler Heights",
                "countryCode": "AU"
            },
            {
                "name": "Whitebridge",
                "countryCode": "AU"
            },
            {
                "name": "Wickham",
                "countryCode": "AU"
            },
            {
                "name": "Wilberforce",
                "countryCode": "AU"
            },
            {
                "name": "Wiley Park",
                "countryCode": "AU"
            },
            {
                "name": "Williamtown",
                "countryCode": "AU"
            },
            {
                "name": "Willmot",
                "countryCode": "AU"
            },
            {
                "name": "Willoughby",
                "countryCode": "AU"
            },
            {
                "name": "Willoughby East",
                "countryCode": "AU"
            },
            {
                "name": "Wilton",
                "countryCode": "AU"
            },
            {
                "name": "Windale",
                "countryCode": "AU"
            },
            {
                "name": "Windang",
                "countryCode": "AU"
            },
            {
                "name": "Windradyne",
                "countryCode": "AU"
            },
            {
                "name": "Windsor",
                "countryCode": "AU"
            },
            {
                "name": "Windsor Downs",
                "countryCode": "AU"
            },
            {
                "name": "Wingecarribee",
                "countryCode": "AU"
            },
            {
                "name": "Wingham",
                "countryCode": "AU"
            },
            {
                "name": "Winmalee",
                "countryCode": "AU"
            },
            {
                "name": "Winston Hills",
                "countryCode": "AU"
            },
            {
                "name": "Wolli Creek",
                "countryCode": "AU"
            },
            {
                "name": "Wollondilly",
                "countryCode": "AU"
            },
            {
                "name": "Wollongbar",
                "countryCode": "AU"
            },
            {
                "name": "Wollongong",
                "countryCode": "AU"
            },
            {
                "name": "Wollongong city centre",
                "countryCode": "AU"
            },
            {
                "name": "Wollstonecraft",
                "countryCode": "AU"
            },
            {
                "name": "Woodberry",
                "countryCode": "AU"
            },
            {
                "name": "Woodbine",
                "countryCode": "AU"
            },
            {
                "name": "Woodcroft",
                "countryCode": "AU"
            },
            {
                "name": "Woodford",
                "countryCode": "AU"
            },
            {
                "name": "Woodpark",
                "countryCode": "AU"
            },
            {
                "name": "Woodrising",
                "countryCode": "AU"
            },
            {
                "name": "Woolgoolga",
                "countryCode": "AU"
            },
            {
                "name": "Woollahra",
                "countryCode": "AU"
            },
            {
                "name": "Woolloomooloo",
                "countryCode": "AU"
            },
            {
                "name": "Woolooware",
                "countryCode": "AU"
            },
            {
                "name": "Woongarrah",
                "countryCode": "AU"
            },
            {
                "name": "Woonona",
                "countryCode": "AU"
            },
            {
                "name": "Woronora",
                "countryCode": "AU"
            },
            {
                "name": "Woronora Heights",
                "countryCode": "AU"
            },
            {
                "name": "Worrigee",
                "countryCode": "AU"
            },
            {
                "name": "Woy Woy",
                "countryCode": "AU"
            },
            {
                "name": "Wyee",
                "countryCode": "AU"
            },
            {
                "name": "Wyee Point",
                "countryCode": "AU"
            },
            {
                "name": "Wyoming",
                "countryCode": "AU"
            },
            {
                "name": "Wyong",
                "countryCode": "AU"
            },
            {
                "name": "Wyongah",
                "countryCode": "AU"
            },
            {
                "name": "Yagoona",
                "countryCode": "AU"
            },
            {
                "name": "Yallah",
                "countryCode": "AU"
            },
            {
                "name": "Yamba",
                "countryCode": "AU"
            },
            {
                "name": "Yarravel",
                "countryCode": "AU"
            },
            {
                "name": "Yarrawarrah",
                "countryCode": "AU"
            },
            {
                "name": "Yass",
                "countryCode": "AU"
            },
            {
                "name": "Yass Valley",
                "countryCode": "AU"
            },
            {
                "name": "Yenda",
                "countryCode": "AU"
            },
            {
                "name": "Yennora",
                "countryCode": "AU"
            },
            {
                "name": "Yerrinbool",
                "countryCode": "AU"
            },
            {
                "name": "Yoogali",
                "countryCode": "AU"
            },
            {
                "name": "Young",
                "countryCode": "AU"
            },
            {
                "name": "Yowie Bay",
                "countryCode": "AU"
            },
            {
                "name": "Zetland",
                "countryCode": "AU"
            },
            {
                "name": "Alawa",
                "countryCode": "AU"
            },
            {
                "name": "Alice Springs",
                "countryCode": "AU"
            },
            {
                "name": "Alyangula",
                "countryCode": "AU"
            },
            {
                "name": "Anula",
                "countryCode": "AU"
            },
            {
                "name": "Araluen",
                "countryCode": "AU"
            },
            {
                "name": "Bakewell",
                "countryCode": "AU"
            },
            {
                "name": "Barkly",
                "countryCode": "AU"
            },
            {
                "name": "Bellamack",
                "countryCode": "AU"
            },
            {
                "name": "Belyuen",
                "countryCode": "AU"
            },
            {
                "name": "Berrimah",
                "countryCode": "AU"
            },
            {
                "name": "Braitling",
                "countryCode": "AU"
            },
            {
                "name": "Brinkin",
                "countryCode": "AU"
            },
            {
                "name": "Central Desert",
                "countryCode": "AU"
            },
            {
                "name": "Coconut Grove",
                "countryCode": "AU"
            },
            {
                "name": "Coomalie",
                "countryCode": "AU"
            },
            {
                "name": "Cossack",
                "countryCode": "AU"
            },
            {
                "name": "Darwin",
                "countryCode": "AU"
            },
            {
                "name": "Desert Springs",
                "countryCode": "AU"
            },
            {
                "name": "Driver",
                "countryCode": "AU"
            },
            {
                "name": "Durack",
                "countryCode": "AU"
            },
            {
                "name": "East Arnhem",
                "countryCode": "AU"
            },
            {
                "name": "East Side",
                "countryCode": "AU"
            },
            {
                "name": "Fannie Bay",
                "countryCode": "AU"
            },
            {
                "name": "Farrar",
                "countryCode": "AU"
            },
            {
                "name": "Galiwinku",
                "countryCode": "AU"
            },
            {
                "name": "Gillen",
                "countryCode": "AU"
            },
            {
                "name": "Girraween",
                "countryCode": "AU"
            },
            {
                "name": "Gray",
                "countryCode": "AU"
            },
            {
                "name": "Gunbalanya",
                "countryCode": "AU"
            },
            {
                "name": "Gunn",
                "countryCode": "AU"
            },
            {
                "name": "Herbert",
                "countryCode": "AU"
            },
            {
                "name": "Holtze",
                "countryCode": "AU"
            },
            {
                "name": "Howard Springs",
                "countryCode": "AU"
            },
            {
                "name": "Humpty Doo",
                "countryCode": "AU"
            },
            {
                "name": "Jabiru",
                "countryCode": "AU"
            },
            {
                "name": "Jingili",
                "countryCode": "AU"
            },
            {
                "name": "Johnston",
                "countryCode": "AU"
            },
            {
                "name": "Karama",
                "countryCode": "AU"
            },
            {
                "name": "Katherine",
                "countryCode": "AU"
            },
            {
                "name": "Katherine East",
                "countryCode": "AU"
            },
            {
                "name": "Katherine South",
                "countryCode": "AU"
            },
            {
                "name": "Larapinta",
                "countryCode": "AU"
            },
            {
                "name": "Larrakeyah",
                "countryCode": "AU"
            },
            {
                "name": "Leanyer",
                "countryCode": "AU"
            },
            {
                "name": "Litchfield",
                "countryCode": "AU"
            },
            {
                "name": "Ludmilla",
                "countryCode": "AU"
            },
            {
                "name": "Lyons",
                "countryCode": "AU"
            },
            {
                "name": "MacDonnell",
                "countryCode": "AU"
            },
            {
                "name": "Malak",
                "countryCode": "AU"
            },
            {
                "name": "Maningrida",
                "countryCode": "AU"
            },
            {
                "name": "Marrara",
                "countryCode": "AU"
            },
            {
                "name": "Milingimbi",
                "countryCode": "AU"
            },
            {
                "name": "Millner",
                "countryCode": "AU"
            },
            {
                "name": "Moil",
                "countryCode": "AU"
            },
            {
                "name": "Moulden",
                "countryCode": "AU"
            },
            {
                "name": "Muirhead",
                "countryCode": "AU"
            },
            {
                "name": "Nakara",
                "countryCode": "AU"
            },
            {
                "name": "Ngukurr",
                "countryCode": "AU"
            },
            {
                "name": "Nhulunbuy",
                "countryCode": "AU"
            },
            {
                "name": "Nightcliff",
                "countryCode": "AU"
            },
            {
                "name": "Palmerston",
                "countryCode": "AU"
            },
            {
                "name": "Parap",
                "countryCode": "AU"
            },
            {
                "name": "Rapid Creek",
                "countryCode": "AU"
            },
            {
                "name": "Roper Gulf",
                "countryCode": "AU"
            },
            {
                "name": "Rosebery",
                "countryCode": "AU"
            },
            {
                "name": "Ross",
                "countryCode": "AU"
            },
            {
                "name": "Sadadeen",
                "countryCode": "AU"
            },
            {
                "name": "Stuart Park",
                "countryCode": "AU"
            },
            {
                "name": "Tennant Creek",
                "countryCode": "AU"
            },
            {
                "name": "The Gap",
                "countryCode": "AU"
            },
            {
                "name": "Tiwi",
                "countryCode": "AU"
            },
            {
                "name": "Tiwi Islands",
                "countryCode": "AU"
            },
            {
                "name": "Victoria Daly",
                "countryCode": "AU"
            },
            {
                "name": "Virginia",
                "countryCode": "AU"
            },
            {
                "name": "Wadeye",
                "countryCode": "AU"
            },
            {
                "name": "Wagait",
                "countryCode": "AU"
            },
            {
                "name": "Wagaman",
                "countryCode": "AU"
            },
            {
                "name": "Wanguri",
                "countryCode": "AU"
            },
            {
                "name": "West Arnhem",
                "countryCode": "AU"
            },
            {
                "name": "Woodroffe",
                "countryCode": "AU"
            },
            {
                "name": "Wulagi",
                "countryCode": "AU"
            },
            {
                "name": "Wurrumiyanga",
                "countryCode": "AU"
            },
            {
                "name": "Yulara",
                "countryCode": "AU"
            },
            {
                "name": "Zuccoli",
                "countryCode": "AU"
            },
            {
                "name": "Acacia Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Agnes Water",
                "countryCode": "AU"
            },
            {
                "name": "Airlie Beach",
                "countryCode": "AU"
            },
            {
                "name": "Aitkenvale",
                "countryCode": "AU"
            },
            {
                "name": "Albany Creek",
                "countryCode": "AU"
            },
            {
                "name": "Alderley",
                "countryCode": "AU"
            },
            {
                "name": "Aldershot",
                "countryCode": "AU"
            },
            {
                "name": "Alexandra Headland",
                "countryCode": "AU"
            },
            {
                "name": "Alexandra Hills",
                "countryCode": "AU"
            },
            {
                "name": "Algester",
                "countryCode": "AU"
            },
            {
                "name": "Alice River",
                "countryCode": "AU"
            },
            {
                "name": "Allenstown",
                "countryCode": "AU"
            },
            {
                "name": "Alligator Creek",
                "countryCode": "AU"
            },
            {
                "name": "Allora",
                "countryCode": "AU"
            },
            {
                "name": "Alton Downs",
                "countryCode": "AU"
            },
            {
                "name": "Andergrove",
                "countryCode": "AU"
            },
            {
                "name": "Annandale",
                "countryCode": "AU"
            },
            {
                "name": "Annerley",
                "countryCode": "AU"
            },
            {
                "name": "Anstead",
                "countryCode": "AU"
            },
            {
                "name": "Arana Hills",
                "countryCode": "AU"
            },
            {
                "name": "Aroona",
                "countryCode": "AU"
            },
            {
                "name": "Arundel",
                "countryCode": "AU"
            },
            {
                "name": "Ascot",
                "countryCode": "AU"
            },
            {
                "name": "Ashgrove",
                "countryCode": "AU"
            },
            {
                "name": "Ashmore",
                "countryCode": "AU"
            },
            {
                "name": "Aspley",
                "countryCode": "AU"
            },
            {
                "name": "Atherton",
                "countryCode": "AU"
            },
            {
                "name": "Auchenflower",
                "countryCode": "AU"
            },
            {
                "name": "Augustine Heights",
                "countryCode": "AU"
            },
            {
                "name": "Aurukun",
                "countryCode": "AU"
            },
            {
                "name": "Avenell Heights",
                "countryCode": "AU"
            },
            {
                "name": "Avoca",
                "countryCode": "AU"
            },
            {
                "name": "Ayr",
                "countryCode": "AU"
            },
            {
                "name": "Babinda",
                "countryCode": "AU"
            },
            {
                "name": "Bahrs Scrub",
                "countryCode": "AU"
            },
            {
                "name": "Bakers Creek",
                "countryCode": "AU"
            },
            {
                "name": "Bald Hills",
                "countryCode": "AU"
            },
            {
                "name": "Balmoral",
                "countryCode": "AU"
            },
            {
                "name": "Balonne Shire",
                "countryCode": "AU"
            },
            {
                "name": "Bamaga",
                "countryCode": "AU"
            },
            {
                "name": "Banana",
                "countryCode": "AU"
            },
            {
                "name": "Banksia Beach",
                "countryCode": "AU"
            },
            {
                "name": "Banyo",
                "countryCode": "AU"
            },
            {
                "name": "Barcaldine",
                "countryCode": "AU"
            },
            {
                "name": "Barcoo",
                "countryCode": "AU"
            },
            {
                "name": "Bardon",
                "countryCode": "AU"
            },
            {
                "name": "Barellan Point",
                "countryCode": "AU"
            },
            {
                "name": "Bargara",
                "countryCode": "AU"
            },
            {
                "name": "Barney Point",
                "countryCode": "AU"
            },
            {
                "name": "Battery Hill",
                "countryCode": "AU"
            },
            {
                "name": "Bayview Heights",
                "countryCode": "AU"
            },
            {
                "name": "Beachmere",
                "countryCode": "AU"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "AU"
            },
            {
                "name": "Beaudesert",
                "countryCode": "AU"
            },
            {
                "name": "Beenleigh",
                "countryCode": "AU"
            },
            {
                "name": "Beerwah",
                "countryCode": "AU"
            },
            {
                "name": "Belgian Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Bellara",
                "countryCode": "AU"
            },
            {
                "name": "Bellbird Park",
                "countryCode": "AU"
            },
            {
                "name": "Bellbowrie",
                "countryCode": "AU"
            },
            {
                "name": "Bellmere",
                "countryCode": "AU"
            },
            {
                "name": "Belmont",
                "countryCode": "AU"
            },
            {
                "name": "Benaraby",
                "countryCode": "AU"
            },
            {
                "name": "Benowa",
                "countryCode": "AU"
            },
            {
                "name": "Bentley Park",
                "countryCode": "AU"
            },
            {
                "name": "Berrinba",
                "countryCode": "AU"
            },
            {
                "name": "Berserker",
                "countryCode": "AU"
            },
            {
                "name": "Biggera Waters",
                "countryCode": "AU"
            },
            {
                "name": "Bilinga",
                "countryCode": "AU"
            },
            {
                "name": "Biloela",
                "countryCode": "AU"
            },
            {
                "name": "Birkdale",
                "countryCode": "AU"
            },
            {
                "name": "Birtinya",
                "countryCode": "AU"
            },
            {
                "name": "Black Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Black River",
                "countryCode": "AU"
            },
            {
                "name": "Blackall",
                "countryCode": "AU"
            },
            {
                "name": "Blackall Tambo",
                "countryCode": "AU"
            },
            {
                "name": "Blacks Beach",
                "countryCode": "AU"
            },
            {
                "name": "Blackwater",
                "countryCode": "AU"
            },
            {
                "name": "Bli Bli",
                "countryCode": "AU"
            },
            {
                "name": "Bluewater",
                "countryCode": "AU"
            },
            {
                "name": "Bohle Plains",
                "countryCode": "AU"
            },
            {
                "name": "Bokarina",
                "countryCode": "AU"
            },
            {
                "name": "Bongaree",
                "countryCode": "AU"
            },
            {
                "name": "Bonogin",
                "countryCode": "AU"
            },
            {
                "name": "Booie",
                "countryCode": "AU"
            },
            {
                "name": "Boonah",
                "countryCode": "AU"
            },
            {
                "name": "Boondall",
                "countryCode": "AU"
            },
            {
                "name": "Booral",
                "countryCode": "AU"
            },
            {
                "name": "Booval",
                "countryCode": "AU"
            },
            {
                "name": "Boronia Heights",
                "countryCode": "AU"
            },
            {
                "name": "Bouldercombe",
                "countryCode": "AU"
            },
            {
                "name": "Boulia",
                "countryCode": "AU"
            },
            {
                "name": "Bowen",
                "countryCode": "AU"
            },
            {
                "name": "Bowen Hills",
                "countryCode": "AU"
            },
            {
                "name": "Boyne Island",
                "countryCode": "AU"
            },
            {
                "name": "Bracken Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Brandon",
                "countryCode": "AU"
            },
            {
                "name": "Branyan",
                "countryCode": "AU"
            },
            {
                "name": "Brassall",
                "countryCode": "AU"
            },
            {
                "name": "Bray Park",
                "countryCode": "AU"
            },
            {
                "name": "Brendale",
                "countryCode": "AU"
            },
            {
                "name": "Bridgeman Downs",
                "countryCode": "AU"
            },
            {
                "name": "Brighton",
                "countryCode": "AU"
            },
            {
                "name": "Brinsmead",
                "countryCode": "AU"
            },
            {
                "name": "Brisbane",
                "countryCode": "AU"
            },
            {
                "name": "Broadbeach",
                "countryCode": "AU"
            },
            {
                "name": "Broadbeach Waters",
                "countryCode": "AU"
            },
            {
                "name": "Brookfield",
                "countryCode": "AU"
            },
            {
                "name": "Brookwater",
                "countryCode": "AU"
            },
            {
                "name": "Bucasia",
                "countryCode": "AU"
            },
            {
                "name": "Bucca",
                "countryCode": "AU"
            },
            {
                "name": "Buccan",
                "countryCode": "AU"
            },
            {
                "name": "Buddina",
                "countryCode": "AU"
            },
            {
                "name": "Buderim",
                "countryCode": "AU"
            },
            {
                "name": "Bulimba",
                "countryCode": "AU"
            },
            {
                "name": "Bulloo",
                "countryCode": "AU"
            },
            {
                "name": "Bundaberg",
                "countryCode": "AU"
            },
            {
                "name": "Bundaberg East",
                "countryCode": "AU"
            },
            {
                "name": "Bundaberg North",
                "countryCode": "AU"
            },
            {
                "name": "Bundaberg South",
                "countryCode": "AU"
            },
            {
                "name": "Bundaberg West",
                "countryCode": "AU"
            },
            {
                "name": "Bundall",
                "countryCode": "AU"
            },
            {
                "name": "Bundamba",
                "countryCode": "AU"
            },
            {
                "name": "Bungalow",
                "countryCode": "AU"
            },
            {
                "name": "Bunya",
                "countryCode": "AU"
            },
            {
                "name": "Burbank",
                "countryCode": "AU"
            },
            {
                "name": "Burdekin",
                "countryCode": "AU"
            },
            {
                "name": "Burdell",
                "countryCode": "AU"
            },
            {
                "name": "Burke",
                "countryCode": "AU"
            },
            {
                "name": "Burleigh Heads",
                "countryCode": "AU"
            },
            {
                "name": "Burleigh Waters",
                "countryCode": "AU"
            },
            {
                "name": "Burnett Heads",
                "countryCode": "AU"
            },
            {
                "name": "Burnside",
                "countryCode": "AU"
            },
            {
                "name": "Burpengary",
                "countryCode": "AU"
            },
            {
                "name": "Burpengary East",
                "countryCode": "AU"
            },
            {
                "name": "Burrum Heads",
                "countryCode": "AU"
            },
            {
                "name": "Bushland Beach",
                "countryCode": "AU"
            },
            {
                "name": "Cabarlah",
                "countryCode": "AU"
            },
            {
                "name": "Caboolture",
                "countryCode": "AU"
            },
            {
                "name": "Caboolture South",
                "countryCode": "AU"
            },
            {
                "name": "Cairns",
                "countryCode": "AU"
            },
            {
                "name": "Cairns City",
                "countryCode": "AU"
            },
            {
                "name": "Cairns North",
                "countryCode": "AU"
            },
            {
                "name": "Calamvale",
                "countryCode": "AU"
            },
            {
                "name": "Calliope",
                "countryCode": "AU"
            },
            {
                "name": "Caloundra",
                "countryCode": "AU"
            },
            {
                "name": "Caloundra West",
                "countryCode": "AU"
            },
            {
                "name": "Cambooya",
                "countryCode": "AU"
            },
            {
                "name": "Camira",
                "countryCode": "AU"
            },
            {
                "name": "Camp Hill",
                "countryCode": "AU"
            },
            {
                "name": "Camp Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Cannon Hill",
                "countryCode": "AU"
            },
            {
                "name": "Cannonvale",
                "countryCode": "AU"
            },
            {
                "name": "Canungra",
                "countryCode": "AU"
            },
            {
                "name": "Capalaba",
                "countryCode": "AU"
            },
            {
                "name": "Capella",
                "countryCode": "AU"
            },
            {
                "name": "Caravonica",
                "countryCode": "AU"
            },
            {
                "name": "Carbrook",
                "countryCode": "AU"
            },
            {
                "name": "Cardwell",
                "countryCode": "AU"
            },
            {
                "name": "Carina Heights",
                "countryCode": "AU"
            },
            {
                "name": "Carindale",
                "countryCode": "AU"
            },
            {
                "name": "Carpentaria",
                "countryCode": "AU"
            },
            {
                "name": "Carrara",
                "countryCode": "AU"
            },
            {
                "name": "Carseldine",
                "countryCode": "AU"
            },
            {
                "name": "Cashmere",
                "countryCode": "AU"
            },
            {
                "name": "Cassowary Coast",
                "countryCode": "AU"
            },
            {
                "name": "Cedar Grove",
                "countryCode": "AU"
            },
            {
                "name": "Cedar Vale",
                "countryCode": "AU"
            },
            {
                "name": "Centenary Heights",
                "countryCode": "AU"
            },
            {
                "name": "Central Highlands",
                "countryCode": "AU"
            },
            {
                "name": "Chambers Flat",
                "countryCode": "AU"
            },
            {
                "name": "Chandler",
                "countryCode": "AU"
            },
            {
                "name": "Chapel Hill",
                "countryCode": "AU"
            },
            {
                "name": "Charleville",
                "countryCode": "AU"
            },
            {
                "name": "Charters Towers",
                "countryCode": "AU"
            },
            {
                "name": "Charters Towers City",
                "countryCode": "AU"
            },
            {
                "name": "Chatsworth",
                "countryCode": "AU"
            },
            {
                "name": "Chelmer",
                "countryCode": "AU"
            },
            {
                "name": "Cherbourg",
                "countryCode": "AU"
            },
            {
                "name": "Chermside",
                "countryCode": "AU"
            },
            {
                "name": "Chermside West",
                "countryCode": "AU"
            },
            {
                "name": "Childers",
                "countryCode": "AU"
            },
            {
                "name": "Chinchilla",
                "countryCode": "AU"
            },
            {
                "name": "Churchill",
                "countryCode": "AU"
            },
            {
                "name": "Chuwar",
                "countryCode": "AU"
            },
            {
                "name": "Clayfield",
                "countryCode": "AU"
            },
            {
                "name": "Clear Island Waters",
                "countryCode": "AU"
            },
            {
                "name": "Clermont",
                "countryCode": "AU"
            },
            {
                "name": "Cleveland",
                "countryCode": "AU"
            },
            {
                "name": "Clifton",
                "countryCode": "AU"
            },
            {
                "name": "Clifton Beach",
                "countryCode": "AU"
            },
            {
                "name": "Clinton",
                "countryCode": "AU"
            },
            {
                "name": "Cloncurry",
                "countryCode": "AU"
            },
            {
                "name": "Clontarf",
                "countryCode": "AU"
            },
            {
                "name": "Coes Creek",
                "countryCode": "AU"
            },
            {
                "name": "Collingwood Park",
                "countryCode": "AU"
            },
            {
                "name": "Collinsville",
                "countryCode": "AU"
            },
            {
                "name": "Condon",
                "countryCode": "AU"
            },
            {
                "name": "Cooee Bay",
                "countryCode": "AU"
            },
            {
                "name": "Cook Shire",
                "countryCode": "AU"
            },
            {
                "name": "Cooktown",
                "countryCode": "AU"
            },
            {
                "name": "Coolangatta",
                "countryCode": "AU"
            },
            {
                "name": "Cooloola Cove",
                "countryCode": "AU"
            },
            {
                "name": "Coolum Beach",
                "countryCode": "AU"
            },
            {
                "name": "Coombabah",
                "countryCode": "AU"
            },
            {
                "name": "Coomera",
                "countryCode": "AU"
            },
            {
                "name": "Coominya",
                "countryCode": "AU"
            },
            {
                "name": "Coopers Plains",
                "countryCode": "AU"
            },
            {
                "name": "Cooran",
                "countryCode": "AU"
            },
            {
                "name": "Cooroibah",
                "countryCode": "AU"
            },
            {
                "name": "Cooroy",
                "countryCode": "AU"
            },
            {
                "name": "Coorparoo",
                "countryCode": "AU"
            },
            {
                "name": "Coppabella",
                "countryCode": "AU"
            },
            {
                "name": "Coral Cove",
                "countryCode": "AU"
            },
            {
                "name": "Corinda",
                "countryCode": "AU"
            },
            {
                "name": "Cornubia",
                "countryCode": "AU"
            },
            {
                "name": "Cotswold Hills",
                "countryCode": "AU"
            },
            {
                "name": "Craiglie",
                "countryCode": "AU"
            },
            {
                "name": "Craignish",
                "countryCode": "AU"
            },
            {
                "name": "Cranbrook",
                "countryCode": "AU"
            },
            {
                "name": "Cranley",
                "countryCode": "AU"
            },
            {
                "name": "Crestmead",
                "countryCode": "AU"
            },
            {
                "name": "Crows Nest",
                "countryCode": "AU"
            },
            {
                "name": "Croydon",
                "countryCode": "AU"
            },
            {
                "name": "Cunnamulla",
                "countryCode": "AU"
            },
            {
                "name": "Curra",
                "countryCode": "AU"
            },
            {
                "name": "Currajong",
                "countryCode": "AU"
            },
            {
                "name": "Currimundi",
                "countryCode": "AU"
            },
            {
                "name": "Currumbin",
                "countryCode": "AU"
            },
            {
                "name": "Currumbin Valley",
                "countryCode": "AU"
            },
            {
                "name": "Currumbin Waters",
                "countryCode": "AU"
            },
            {
                "name": "Daisy Hill",
                "countryCode": "AU"
            },
            {
                "name": "Dakabin",
                "countryCode": "AU"
            },
            {
                "name": "Dalby",
                "countryCode": "AU"
            },
            {
                "name": "Darling Heights",
                "countryCode": "AU"
            },
            {
                "name": "Darra",
                "countryCode": "AU"
            },
            {
                "name": "Dayboro",
                "countryCode": "AU"
            },
            {
                "name": "Deagon",
                "countryCode": "AU"
            },
            {
                "name": "Deception Bay",
                "countryCode": "AU"
            },
            {
                "name": "Deebing Heights",
                "countryCode": "AU"
            },
            {
                "name": "Deeragun",
                "countryCode": "AU"
            },
            {
                "name": "Delaneys Creek",
                "countryCode": "AU"
            },
            {
                "name": "Depot Hill",
                "countryCode": "AU"
            },
            {
                "name": "Diamantina",
                "countryCode": "AU"
            },
            {
                "name": "Dicky Beach",
                "countryCode": "AU"
            },
            {
                "name": "Diddillibah",
                "countryCode": "AU"
            },
            {
                "name": "Dimbulah",
                "countryCode": "AU"
            },
            {
                "name": "Doolandella",
                "countryCode": "AU"
            },
            {
                "name": "Doomadgee",
                "countryCode": "AU"
            },
            {
                "name": "Doonan",
                "countryCode": "AU"
            },
            {
                "name": "Douglas",
                "countryCode": "AU"
            },
            {
                "name": "Drayton",
                "countryCode": "AU"
            },
            {
                "name": "Drewvale",
                "countryCode": "AU"
            },
            {
                "name": "Dundowran Beach",
                "countryCode": "AU"
            },
            {
                "name": "Durack",
                "countryCode": "AU"
            },
            {
                "name": "Dutton Park",
                "countryCode": "AU"
            },
            {
                "name": "Dysart",
                "countryCode": "AU"
            },
            {
                "name": "D’Aguilar",
                "countryCode": "AU"
            },
            {
                "name": "Eagle Farm",
                "countryCode": "AU"
            },
            {
                "name": "Eagleby",
                "countryCode": "AU"
            },
            {
                "name": "Earlville",
                "countryCode": "AU"
            },
            {
                "name": "East Brisbane",
                "countryCode": "AU"
            },
            {
                "name": "East Innisfail",
                "countryCode": "AU"
            },
            {
                "name": "East Ipswich",
                "countryCode": "AU"
            },
            {
                "name": "East Mackay",
                "countryCode": "AU"
            },
            {
                "name": "East Toowoomba",
                "countryCode": "AU"
            },
            {
                "name": "Eastern Heights",
                "countryCode": "AU"
            },
            {
                "name": "Eatons Hill",
                "countryCode": "AU"
            },
            {
                "name": "Edens Landing",
                "countryCode": "AU"
            },
            {
                "name": "Edge Hill",
                "countryCode": "AU"
            },
            {
                "name": "Edmonton",
                "countryCode": "AU"
            },
            {
                "name": "Eight Mile Plains",
                "countryCode": "AU"
            },
            {
                "name": "Eimeo",
                "countryCode": "AU"
            },
            {
                "name": "Elanora",
                "countryCode": "AU"
            },
            {
                "name": "Eli Waters",
                "countryCode": "AU"
            },
            {
                "name": "Elimbah",
                "countryCode": "AU"
            },
            {
                "name": "Ellen Grove",
                "countryCode": "AU"
            },
            {
                "name": "Elliott Heads",
                "countryCode": "AU"
            },
            {
                "name": "Emerald",
                "countryCode": "AU"
            },
            {
                "name": "Emu Park",
                "countryCode": "AU"
            },
            {
                "name": "Enoggera",
                "countryCode": "AU"
            },
            {
                "name": "Esk",
                "countryCode": "AU"
            },
            {
                "name": "Etheridge",
                "countryCode": "AU"
            },
            {
                "name": "Eudlo",
                "countryCode": "AU"
            },
            {
                "name": "Eumundi",
                "countryCode": "AU"
            },
            {
                "name": "Everton Hills",
                "countryCode": "AU"
            },
            {
                "name": "Everton Park",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield",
                "countryCode": "AU"
            },
            {
                "name": "Fernvale",
                "countryCode": "AU"
            },
            {
                "name": "Ferny Grove",
                "countryCode": "AU"
            },
            {
                "name": "Ferny Hills",
                "countryCode": "AU"
            },
            {
                "name": "Fig Tree Pocket",
                "countryCode": "AU"
            },
            {
                "name": "Fitzgibbon",
                "countryCode": "AU"
            },
            {
                "name": "Flinders",
                "countryCode": "AU"
            },
            {
                "name": "Flinders View",
                "countryCode": "AU"
            },
            {
                "name": "Forest Glen",
                "countryCode": "AU"
            },
            {
                "name": "Forest Lake",
                "countryCode": "AU"
            },
            {
                "name": "Forestdale",
                "countryCode": "AU"
            },
            {
                "name": "Fortitude Valley",
                "countryCode": "AU"
            },
            {
                "name": "Fraser Coast",
                "countryCode": "AU"
            },
            {
                "name": "Frenchville",
                "countryCode": "AU"
            },
            {
                "name": "Freshwater",
                "countryCode": "AU"
            },
            {
                "name": "Gailes",
                "countryCode": "AU"
            },
            {
                "name": "Garbutt",
                "countryCode": "AU"
            },
            {
                "name": "Gatton",
                "countryCode": "AU"
            },
            {
                "name": "Gaven",
                "countryCode": "AU"
            },
            {
                "name": "Gayndah",
                "countryCode": "AU"
            },
            {
                "name": "Gaythorne",
                "countryCode": "AU"
            },
            {
                "name": "Geebung",
                "countryCode": "AU"
            },
            {
                "name": "Gilston",
                "countryCode": "AU"
            },
            {
                "name": "Gin Gin",
                "countryCode": "AU"
            },
            {
                "name": "Gladstone",
                "countryCode": "AU"
            },
            {
                "name": "Gladstone Central",
                "countryCode": "AU"
            },
            {
                "name": "Glass House Mountains",
                "countryCode": "AU"
            },
            {
                "name": "Glen Eden",
                "countryCode": "AU"
            },
            {
                "name": "Gleneagle",
                "countryCode": "AU"
            },
            {
                "name": "Glenella",
                "countryCode": "AU"
            },
            {
                "name": "Glenvale",
                "countryCode": "AU"
            },
            {
                "name": "Glenview",
                "countryCode": "AU"
            },
            {
                "name": "Gold Coast",
                "countryCode": "AU"
            },
            {
                "name": "Golden Beach",
                "countryCode": "AU"
            },
            {
                "name": "Gooburrum",
                "countryCode": "AU"
            },
            {
                "name": "Goodna",
                "countryCode": "AU"
            },
            {
                "name": "Goondiwindi",
                "countryCode": "AU"
            },
            {
                "name": "Gordon Park",
                "countryCode": "AU"
            },
            {
                "name": "Gordonvale",
                "countryCode": "AU"
            },
            {
                "name": "Gowrie Junction",
                "countryCode": "AU"
            },
            {
                "name": "Gracemere",
                "countryCode": "AU"
            },
            {
                "name": "Graceville",
                "countryCode": "AU"
            },
            {
                "name": "Grange",
                "countryCode": "AU"
            },
            {
                "name": "Granville",
                "countryCode": "AU"
            },
            {
                "name": "Greenbank",
                "countryCode": "AU"
            },
            {
                "name": "Greenslopes",
                "countryCode": "AU"
            },
            {
                "name": "Griffin",
                "countryCode": "AU"
            },
            {
                "name": "Gulliver",
                "countryCode": "AU"
            },
            {
                "name": "Gumdale",
                "countryCode": "AU"
            },
            {
                "name": "Gympie",
                "countryCode": "AU"
            },
            {
                "name": "Gympie Regional Council",
                "countryCode": "AU"
            },
            {
                "name": "Hamilton",
                "countryCode": "AU"
            },
            {
                "name": "Harlaxton",
                "countryCode": "AU"
            },
            {
                "name": "Harristown",
                "countryCode": "AU"
            },
            {
                "name": "Hatton Vale",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorne",
                "countryCode": "AU"
            },
            {
                "name": "Hay Point",
                "countryCode": "AU"
            },
            {
                "name": "Healy",
                "countryCode": "AU"
            },
            {
                "name": "Heathwood",
                "countryCode": "AU"
            },
            {
                "name": "Heatley",
                "countryCode": "AU"
            },
            {
                "name": "Helensvale",
                "countryCode": "AU"
            },
            {
                "name": "Helidon",
                "countryCode": "AU"
            },
            {
                "name": "Hemmant",
                "countryCode": "AU"
            },
            {
                "name": "Hendra",
                "countryCode": "AU"
            },
            {
                "name": "Heritage Park",
                "countryCode": "AU"
            },
            {
                "name": "Hermit Park",
                "countryCode": "AU"
            },
            {
                "name": "Herston",
                "countryCode": "AU"
            },
            {
                "name": "Hervey Bay",
                "countryCode": "AU"
            },
            {
                "name": "Highfields",
                "countryCode": "AU"
            },
            {
                "name": "Highgate Hill",
                "countryCode": "AU"
            },
            {
                "name": "Highland Park",
                "countryCode": "AU"
            },
            {
                "name": "Highvale",
                "countryCode": "AU"
            },
            {
                "name": "Hillcrest",
                "countryCode": "AU"
            },
            {
                "name": "Hinchinbrook",
                "countryCode": "AU"
            },
            {
                "name": "Hodgson Vale",
                "countryCode": "AU"
            },
            {
                "name": "Holland Park",
                "countryCode": "AU"
            },
            {
                "name": "Holland Park West",
                "countryCode": "AU"
            },
            {
                "name": "Holloways Beach",
                "countryCode": "AU"
            },
            {
                "name": "Hollywell",
                "countryCode": "AU"
            },
            {
                "name": "Holmview",
                "countryCode": "AU"
            },
            {
                "name": "Home Hill",
                "countryCode": "AU"
            },
            {
                "name": "Hope Island",
                "countryCode": "AU"
            },
            {
                "name": "Hope Vale",
                "countryCode": "AU"
            },
            {
                "name": "Howard",
                "countryCode": "AU"
            },
            {
                "name": "Hughenden",
                "countryCode": "AU"
            },
            {
                "name": "Hyde Park",
                "countryCode": "AU"
            },
            {
                "name": "Idalia",
                "countryCode": "AU"
            },
            {
                "name": "Inala",
                "countryCode": "AU"
            },
            {
                "name": "Indooroopilly",
                "countryCode": "AU"
            },
            {
                "name": "Ingham",
                "countryCode": "AU"
            },
            {
                "name": "Innes Park",
                "countryCode": "AU"
            },
            {
                "name": "Innisfail",
                "countryCode": "AU"
            },
            {
                "name": "Innisfail Estate",
                "countryCode": "AU"
            },
            {
                "name": "Ipswich",
                "countryCode": "AU"
            },
            {
                "name": "Isaac",
                "countryCode": "AU"
            },
            {
                "name": "Jacobs Well",
                "countryCode": "AU"
            },
            {
                "name": "Jamboree Heights",
                "countryCode": "AU"
            },
            {
                "name": "Jandowae",
                "countryCode": "AU"
            },
            {
                "name": "Jensen",
                "countryCode": "AU"
            },
            {
                "name": "Jimboomba",
                "countryCode": "AU"
            },
            {
                "name": "Jindalee",
                "countryCode": "AU"
            },
            {
                "name": "Joyner",
                "countryCode": "AU"
            },
            {
                "name": "Jubilee Pocket",
                "countryCode": "AU"
            },
            {
                "name": "Julatten",
                "countryCode": "AU"
            },
            {
                "name": "Kalbar",
                "countryCode": "AU"
            },
            {
                "name": "Kalinga",
                "countryCode": "AU"
            },
            {
                "name": "Kalkie",
                "countryCode": "AU"
            },
            {
                "name": "Kallangur",
                "countryCode": "AU"
            },
            {
                "name": "Kamerunga",
                "countryCode": "AU"
            },
            {
                "name": "Kangaroo Point",
                "countryCode": "AU"
            },
            {
                "name": "Kanimbla",
                "countryCode": "AU"
            },
            {
                "name": "Karalee",
                "countryCode": "AU"
            },
            {
                "name": "Karana Downs",
                "countryCode": "AU"
            },
            {
                "name": "Karumba",
                "countryCode": "AU"
            },
            {
                "name": "Kawana",
                "countryCode": "AU"
            },
            {
                "name": "Kawungan",
                "countryCode": "AU"
            },
            {
                "name": "Kearneys Spring",
                "countryCode": "AU"
            },
            {
                "name": "Kedron",
                "countryCode": "AU"
            },
            {
                "name": "Kelso",
                "countryCode": "AU"
            },
            {
                "name": "Kelvin Grove",
                "countryCode": "AU"
            },
            {
                "name": "Kenmore",
                "countryCode": "AU"
            },
            {
                "name": "Kenmore Hills",
                "countryCode": "AU"
            },
            {
                "name": "Kensington Grove",
                "countryCode": "AU"
            },
            {
                "name": "Keperra",
                "countryCode": "AU"
            },
            {
                "name": "Kepnock",
                "countryCode": "AU"
            },
            {
                "name": "Kewarra Beach",
                "countryCode": "AU"
            },
            {
                "name": "Kilcoy",
                "countryCode": "AU"
            },
            {
                "name": "Kin Kora",
                "countryCode": "AU"
            },
            {
                "name": "Kingaroy",
                "countryCode": "AU"
            },
            {
                "name": "Kings Beach",
                "countryCode": "AU"
            },
            {
                "name": "Kingsthorpe",
                "countryCode": "AU"
            },
            {
                "name": "Kingston",
                "countryCode": "AU"
            },
            {
                "name": "Kippa-Ring",
                "countryCode": "AU"
            },
            {
                "name": "Kirkwood",
                "countryCode": "AU"
            },
            {
                "name": "Kirwan",
                "countryCode": "AU"
            },
            {
                "name": "Kleinton",
                "countryCode": "AU"
            },
            {
                "name": "Koongal",
                "countryCode": "AU"
            },
            {
                "name": "Kooralbyn",
                "countryCode": "AU"
            },
            {
                "name": "Kowanyama",
                "countryCode": "AU"
            },
            {
                "name": "Kuluin",
                "countryCode": "AU"
            },
            {
                "name": "Kuraby",
                "countryCode": "AU"
            },
            {
                "name": "Kuranda",
                "countryCode": "AU"
            },
            {
                "name": "Kurwongbah",
                "countryCode": "AU"
            },
            {
                "name": "Labrador",
                "countryCode": "AU"
            },
            {
                "name": "Laidley",
                "countryCode": "AU"
            },
            {
                "name": "Lake Macdonald",
                "countryCode": "AU"
            },
            {
                "name": "Lammermoor",
                "countryCode": "AU"
            },
            {
                "name": "Landsborough",
                "countryCode": "AU"
            },
            {
                "name": "Lawnton",
                "countryCode": "AU"
            },
            {
                "name": "Leichhardt",
                "countryCode": "AU"
            },
            {
                "name": "Little Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Lockhart River",
                "countryCode": "AU"
            },
            {
                "name": "Lockyer Valley",
                "countryCode": "AU"
            },
            {
                "name": "Logan",
                "countryCode": "AU"
            },
            {
                "name": "Logan Central",
                "countryCode": "AU"
            },
            {
                "name": "Logan City",
                "countryCode": "AU"
            },
            {
                "name": "Logan Reserve",
                "countryCode": "AU"
            },
            {
                "name": "Logan Village",
                "countryCode": "AU"
            },
            {
                "name": "Loganholme",
                "countryCode": "AU"
            },
            {
                "name": "Loganlea",
                "countryCode": "AU"
            },
            {
                "name": "Longreach",
                "countryCode": "AU"
            },
            {
                "name": "Lota",
                "countryCode": "AU"
            },
            {
                "name": "Lower Beechmont",
                "countryCode": "AU"
            },
            {
                "name": "Lowood",
                "countryCode": "AU"
            },
            {
                "name": "Lutwyche",
                "countryCode": "AU"
            },
            {
                "name": "Macgregor",
                "countryCode": "AU"
            },
            {
                "name": "Machans Beach",
                "countryCode": "AU"
            },
            {
                "name": "Mackay",
                "countryCode": "AU"
            },
            {
                "name": "Mackay City",
                "countryCode": "AU"
            },
            {
                "name": "Mackenzie",
                "countryCode": "AU"
            },
            {
                "name": "Macleay Island",
                "countryCode": "AU"
            },
            {
                "name": "Magnetic Island",
                "countryCode": "AU"
            },
            {
                "name": "Main Beach",
                "countryCode": "AU"
            },
            {
                "name": "Malanda",
                "countryCode": "AU"
            },
            {
                "name": "Maleny",
                "countryCode": "AU"
            },
            {
                "name": "Mango Hill",
                "countryCode": "AU"
            },
            {
                "name": "Manly West",
                "countryCode": "AU"
            },
            {
                "name": "Manoora",
                "countryCode": "AU"
            },
            {
                "name": "Mansfield",
                "countryCode": "AU"
            },
            {
                "name": "Manunda",
                "countryCode": "AU"
            },
            {
                "name": "Mapleton",
                "countryCode": "AU"
            },
            {
                "name": "Mapoon",
                "countryCode": "AU"
            },
            {
                "name": "Maranoa",
                "countryCode": "AU"
            },
            {
                "name": "Marcoola",
                "countryCode": "AU"
            },
            {
                "name": "Mareeba",
                "countryCode": "AU"
            },
            {
                "name": "Margate",
                "countryCode": "AU"
            },
            {
                "name": "Marian",
                "countryCode": "AU"
            },
            {
                "name": "Maroochy River",
                "countryCode": "AU"
            },
            {
                "name": "Maroochydore",
                "countryCode": "AU"
            },
            {
                "name": "Marsden",
                "countryCode": "AU"
            },
            {
                "name": "Maryborough",
                "countryCode": "AU"
            },
            {
                "name": "Maudsland",
                "countryCode": "AU"
            },
            {
                "name": "McDowall",
                "countryCode": "AU"
            },
            {
                "name": "McKinlay",
                "countryCode": "AU"
            },
            {
                "name": "Meadowbrook",
                "countryCode": "AU"
            },
            {
                "name": "Menzies",
                "countryCode": "AU"
            },
            {
                "name": "Meridan Plains",
                "countryCode": "AU"
            },
            {
                "name": "Meringandan West",
                "countryCode": "AU"
            },
            {
                "name": "Mermaid Beach",
                "countryCode": "AU"
            },
            {
                "name": "Mermaid Waters",
                "countryCode": "AU"
            },
            {
                "name": "Merrimac",
                "countryCode": "AU"
            },
            {
                "name": "Miami",
                "countryCode": "AU"
            },
            {
                "name": "Middle Park",
                "countryCode": "AU"
            },
            {
                "name": "Middle Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Middlemount",
                "countryCode": "AU"
            },
            {
                "name": "Miles",
                "countryCode": "AU"
            },
            {
                "name": "Millbank",
                "countryCode": "AU"
            },
            {
                "name": "Millmerran",
                "countryCode": "AU"
            },
            {
                "name": "Millstream",
                "countryCode": "AU"
            },
            {
                "name": "Milton",
                "countryCode": "AU"
            },
            {
                "name": "Minden",
                "countryCode": "AU"
            },
            {
                "name": "Minyama",
                "countryCode": "AU"
            },
            {
                "name": "Mirani",
                "countryCode": "AU"
            },
            {
                "name": "Mission Beach",
                "countryCode": "AU"
            },
            {
                "name": "Mission River",
                "countryCode": "AU"
            },
            {
                "name": "Mitchell",
                "countryCode": "AU"
            },
            {
                "name": "Mitchelton",
                "countryCode": "AU"
            },
            {
                "name": "Moffat Beach",
                "countryCode": "AU"
            },
            {
                "name": "Moggill",
                "countryCode": "AU"
            },
            {
                "name": "Molendinar",
                "countryCode": "AU"
            },
            {
                "name": "Monkland",
                "countryCode": "AU"
            },
            {
                "name": "Monto",
                "countryCode": "AU"
            },
            {
                "name": "Montville",
                "countryCode": "AU"
            },
            {
                "name": "Mooloolaba",
                "countryCode": "AU"
            },
            {
                "name": "Moore Park Beach",
                "countryCode": "AU"
            },
            {
                "name": "Mooroobool",
                "countryCode": "AU"
            },
            {
                "name": "Moorooka",
                "countryCode": "AU"
            },
            {
                "name": "Moranbah",
                "countryCode": "AU"
            },
            {
                "name": "Morayfield",
                "countryCode": "AU"
            },
            {
                "name": "Moreton Bay",
                "countryCode": "AU"
            },
            {
                "name": "Morningside",
                "countryCode": "AU"
            },
            {
                "name": "Mornington",
                "countryCode": "AU"
            },
            {
                "name": "Mossman",
                "countryCode": "AU"
            },
            {
                "name": "Mount Coolum",
                "countryCode": "AU"
            },
            {
                "name": "Mount Cotton",
                "countryCode": "AU"
            },
            {
                "name": "Mount Crosby",
                "countryCode": "AU"
            },
            {
                "name": "Mount Gravatt",
                "countryCode": "AU"
            },
            {
                "name": "Mount Gravatt East",
                "countryCode": "AU"
            },
            {
                "name": "Mount Isa",
                "countryCode": "AU"
            },
            {
                "name": "Mount Lofty",
                "countryCode": "AU"
            },
            {
                "name": "Mount Louisa",
                "countryCode": "AU"
            },
            {
                "name": "Mount Low",
                "countryCode": "AU"
            },
            {
                "name": "Mount Morgan",
                "countryCode": "AU"
            },
            {
                "name": "Mount Nathan",
                "countryCode": "AU"
            },
            {
                "name": "Mount Ommaney",
                "countryCode": "AU"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "AU"
            },
            {
                "name": "Mount Sheridan",
                "countryCode": "AU"
            },
            {
                "name": "Mount Warren Park",
                "countryCode": "AU"
            },
            {
                "name": "Mountain Creek",
                "countryCode": "AU"
            },
            {
                "name": "Moura",
                "countryCode": "AU"
            },
            {
                "name": "Mudgeeraba",
                "countryCode": "AU"
            },
            {
                "name": "Mudjimba",
                "countryCode": "AU"
            },
            {
                "name": "Mulambin",
                "countryCode": "AU"
            },
            {
                "name": "Mundingburra",
                "countryCode": "AU"
            },
            {
                "name": "Mundoolun",
                "countryCode": "AU"
            },
            {
                "name": "Mundubbera",
                "countryCode": "AU"
            },
            {
                "name": "Munruben",
                "countryCode": "AU"
            },
            {
                "name": "Murarrie",
                "countryCode": "AU"
            },
            {
                "name": "Murgon",
                "countryCode": "AU"
            },
            {
                "name": "Murray",
                "countryCode": "AU"
            },
            {
                "name": "Murrumba Downs",
                "countryCode": "AU"
            },
            {
                "name": "Murweh",
                "countryCode": "AU"
            },
            {
                "name": "Nambour",
                "countryCode": "AU"
            },
            {
                "name": "Nanango",
                "countryCode": "AU"
            },
            {
                "name": "Nanum",
                "countryCode": "AU"
            },
            {
                "name": "Napranum",
                "countryCode": "AU"
            },
            {
                "name": "Narangba",
                "countryCode": "AU"
            },
            {
                "name": "Nathan",
                "countryCode": "AU"
            },
            {
                "name": "Nebo",
                "countryCode": "AU"
            },
            {
                "name": "Nelly Bay",
                "countryCode": "AU"
            },
            {
                "name": "Nerang",
                "countryCode": "AU"
            },
            {
                "name": "New Auckland",
                "countryCode": "AU"
            },
            {
                "name": "New Beith",
                "countryCode": "AU"
            },
            {
                "name": "New Farm",
                "countryCode": "AU"
            },
            {
                "name": "Newmarket",
                "countryCode": "AU"
            },
            {
                "name": "Newport",
                "countryCode": "AU"
            },
            {
                "name": "Newstead",
                "countryCode": "AU"
            },
            {
                "name": "Newtown",
                "countryCode": "AU"
            },
            {
                "name": "Ninderry",
                "countryCode": "AU"
            },
            {
                "name": "Ningi",
                "countryCode": "AU"
            },
            {
                "name": "Nome",
                "countryCode": "AU"
            },
            {
                "name": "Noosa Heads",
                "countryCode": "AU"
            },
            {
                "name": "Noosaville",
                "countryCode": "AU"
            },
            {
                "name": "Norman Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Norman Park",
                "countryCode": "AU"
            },
            {
                "name": "Normanton",
                "countryCode": "AU"
            },
            {
                "name": "North Booval",
                "countryCode": "AU"
            },
            {
                "name": "North Burnett",
                "countryCode": "AU"
            },
            {
                "name": "North Ipswich",
                "countryCode": "AU"
            },
            {
                "name": "North Lakes",
                "countryCode": "AU"
            },
            {
                "name": "North Mackay",
                "countryCode": "AU"
            },
            {
                "name": "North Maclean",
                "countryCode": "AU"
            },
            {
                "name": "North Toowoomba",
                "countryCode": "AU"
            },
            {
                "name": "North Ward",
                "countryCode": "AU"
            },
            {
                "name": "Northern Peninsula Area",
                "countryCode": "AU"
            },
            {
                "name": "Norville",
                "countryCode": "AU"
            },
            {
                "name": "Nudgee",
                "countryCode": "AU"
            },
            {
                "name": "Nundah",
                "countryCode": "AU"
            },
            {
                "name": "Oakey",
                "countryCode": "AU"
            },
            {
                "name": "Oakhurst",
                "countryCode": "AU"
            },
            {
                "name": "One Mile",
                "countryCode": "AU"
            },
            {
                "name": "Oonoonba",
                "countryCode": "AU"
            },
            {
                "name": "Ooralea",
                "countryCode": "AU"
            },
            {
                "name": "Ormeau",
                "countryCode": "AU"
            },
            {
                "name": "Ormeau Hills",
                "countryCode": "AU"
            },
            {
                "name": "Ormiston",
                "countryCode": "AU"
            },
            {
                "name": "Oxenford",
                "countryCode": "AU"
            },
            {
                "name": "Oxley",
                "countryCode": "AU"
            },
            {
                "name": "Pacific Paradise",
                "countryCode": "AU"
            },
            {
                "name": "Pacific Pines",
                "countryCode": "AU"
            },
            {
                "name": "Paddington",
                "countryCode": "AU"
            },
            {
                "name": "Palm Beach",
                "countryCode": "AU"
            },
            {
                "name": "Palm Cove",
                "countryCode": "AU"
            },
            {
                "name": "Palm Island",
                "countryCode": "AU"
            },
            {
                "name": "Palmwoods",
                "countryCode": "AU"
            },
            {
                "name": "Paradise Point",
                "countryCode": "AU"
            },
            {
                "name": "Park Avenue",
                "countryCode": "AU"
            },
            {
                "name": "Park Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Park Ridge South",
                "countryCode": "AU"
            },
            {
                "name": "Parkhurst",
                "countryCode": "AU"
            },
            {
                "name": "Parkinson",
                "countryCode": "AU"
            },
            {
                "name": "Parkside",
                "countryCode": "AU"
            },
            {
                "name": "Parkwood",
                "countryCode": "AU"
            },
            {
                "name": "Paroo",
                "countryCode": "AU"
            },
            {
                "name": "Parramatta Park",
                "countryCode": "AU"
            },
            {
                "name": "Parrearra",
                "countryCode": "AU"
            },
            {
                "name": "Peachester",
                "countryCode": "AU"
            },
            {
                "name": "Pelican Waters",
                "countryCode": "AU"
            },
            {
                "name": "Peregian Beach",
                "countryCode": "AU"
            },
            {
                "name": "Peregian Springs",
                "countryCode": "AU"
            },
            {
                "name": "Petrie",
                "countryCode": "AU"
            },
            {
                "name": "Petrie Terrace",
                "countryCode": "AU"
            },
            {
                "name": "Pialba",
                "countryCode": "AU"
            },
            {
                "name": "Pie Creek",
                "countryCode": "AU"
            },
            {
                "name": "Pimlico",
                "countryCode": "AU"
            },
            {
                "name": "Pimpama",
                "countryCode": "AU"
            },
            {
                "name": "Pine Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Pioneer",
                "countryCode": "AU"
            },
            {
                "name": "Pittsworth",
                "countryCode": "AU"
            },
            {
                "name": "Plainland",
                "countryCode": "AU"
            },
            {
                "name": "Point Vernon",
                "countryCode": "AU"
            },
            {
                "name": "Pomona",
                "countryCode": "AU"
            },
            {
                "name": "Pormpuraaw",
                "countryCode": "AU"
            },
            {
                "name": "Port Douglas",
                "countryCode": "AU"
            },
            {
                "name": "Proserpine",
                "countryCode": "AU"
            },
            {
                "name": "Pullenvale",
                "countryCode": "AU"
            },
            {
                "name": "Queenton",
                "countryCode": "AU"
            },
            {
                "name": "Quilpie",
                "countryCode": "AU"
            },
            {
                "name": "Raceview",
                "countryCode": "AU"
            },
            {
                "name": "Railway Estate",
                "countryCode": "AU"
            },
            {
                "name": "Rainbow Beach",
                "countryCode": "AU"
            },
            {
                "name": "Rangeville",
                "countryCode": "AU"
            },
            {
                "name": "Rangewood",
                "countryCode": "AU"
            },
            {
                "name": "Rasmussen",
                "countryCode": "AU"
            },
            {
                "name": "Ravenshoe",
                "countryCode": "AU"
            },
            {
                "name": "Red Hill",
                "countryCode": "AU"
            },
            {
                "name": "Redbank",
                "countryCode": "AU"
            },
            {
                "name": "Redbank Plains",
                "countryCode": "AU"
            },
            {
                "name": "Redland",
                "countryCode": "AU"
            },
            {
                "name": "Redland Bay",
                "countryCode": "AU"
            },
            {
                "name": "Redlynch",
                "countryCode": "AU"
            },
            {
                "name": "Reedy Creek",
                "countryCode": "AU"
            },
            {
                "name": "Regency Downs",
                "countryCode": "AU"
            },
            {
                "name": "Regents Park",
                "countryCode": "AU"
            },
            {
                "name": "Richlands",
                "countryCode": "AU"
            },
            {
                "name": "Richmond",
                "countryCode": "AU"
            },
            {
                "name": "River Heads",
                "countryCode": "AU"
            },
            {
                "name": "Riverhills",
                "countryCode": "AU"
            },
            {
                "name": "Riverview",
                "countryCode": "AU"
            },
            {
                "name": "Robertson",
                "countryCode": "AU"
            },
            {
                "name": "Robina",
                "countryCode": "AU"
            },
            {
                "name": "Rochedale",
                "countryCode": "AU"
            },
            {
                "name": "Rochedale South",
                "countryCode": "AU"
            },
            {
                "name": "Rockhampton",
                "countryCode": "AU"
            },
            {
                "name": "Rocklea",
                "countryCode": "AU"
            },
            {
                "name": "Rockville",
                "countryCode": "AU"
            },
            {
                "name": "Roma",
                "countryCode": "AU"
            },
            {
                "name": "Rosemount",
                "countryCode": "AU"
            },
            {
                "name": "Rosenthal Heights",
                "countryCode": "AU"
            },
            {
                "name": "Rosewood",
                "countryCode": "AU"
            },
            {
                "name": "Rosslea",
                "countryCode": "AU"
            },
            {
                "name": "Rothwell",
                "countryCode": "AU"
            },
            {
                "name": "Runaway Bay",
                "countryCode": "AU"
            },
            {
                "name": "Runcorn",
                "countryCode": "AU"
            },
            {
                "name": "Rural View",
                "countryCode": "AU"
            },
            {
                "name": "Russell Island",
                "countryCode": "AU"
            },
            {
                "name": "Sadliers Crossing",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury",
                "countryCode": "AU"
            },
            {
                "name": "Samford Valley",
                "countryCode": "AU"
            },
            {
                "name": "Sandgate",
                "countryCode": "AU"
            },
            {
                "name": "Sandstone Point",
                "countryCode": "AU"
            },
            {
                "name": "Sarina",
                "countryCode": "AU"
            },
            {
                "name": "Scarborough",
                "countryCode": "AU"
            },
            {
                "name": "Scarness",
                "countryCode": "AU"
            },
            {
                "name": "Scenic Rim",
                "countryCode": "AU"
            },
            {
                "name": "Seven Hills",
                "countryCode": "AU"
            },
            {
                "name": "Seventeen Mile Rocks",
                "countryCode": "AU"
            },
            {
                "name": "Shailer Park",
                "countryCode": "AU"
            },
            {
                "name": "Sharon",
                "countryCode": "AU"
            },
            {
                "name": "Sheldon",
                "countryCode": "AU"
            },
            {
                "name": "Shorncliffe",
                "countryCode": "AU"
            },
            {
                "name": "Silkstone",
                "countryCode": "AU"
            },
            {
                "name": "Sinnamon Park",
                "countryCode": "AU"
            },
            {
                "name": "Sippy Downs",
                "countryCode": "AU"
            },
            {
                "name": "Slacks Creek",
                "countryCode": "AU"
            },
            {
                "name": "Slade Point",
                "countryCode": "AU"
            },
            {
                "name": "Soldiers Hill",
                "countryCode": "AU"
            },
            {
                "name": "Somerset",
                "countryCode": "AU"
            },
            {
                "name": "South Brisbane",
                "countryCode": "AU"
            },
            {
                "name": "South Burnett",
                "countryCode": "AU"
            },
            {
                "name": "South Gladstone",
                "countryCode": "AU"
            },
            {
                "name": "South Kolan",
                "countryCode": "AU"
            },
            {
                "name": "South Mackay",
                "countryCode": "AU"
            },
            {
                "name": "South Maclean",
                "countryCode": "AU"
            },
            {
                "name": "South Toowoomba",
                "countryCode": "AU"
            },
            {
                "name": "South Townsville",
                "countryCode": "AU"
            },
            {
                "name": "Southern Downs",
                "countryCode": "AU"
            },
            {
                "name": "Southport",
                "countryCode": "AU"
            },
            {
                "name": "Southside",
                "countryCode": "AU"
            },
            {
                "name": "Spring Hill",
                "countryCode": "AU"
            },
            {
                "name": "Springfield",
                "countryCode": "AU"
            },
            {
                "name": "Springfield Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Springsure",
                "countryCode": "AU"
            },
            {
                "name": "Springwood",
                "countryCode": "AU"
            },
            {
                "name": "St George",
                "countryCode": "AU"
            },
            {
                "name": "Stafford",
                "countryCode": "AU"
            },
            {
                "name": "Stafford Heights",
                "countryCode": "AU"
            },
            {
                "name": "Stanthorpe",
                "countryCode": "AU"
            },
            {
                "name": "Strathpine",
                "countryCode": "AU"
            },
            {
                "name": "Stretton",
                "countryCode": "AU"
            },
            {
                "name": "Stuart",
                "countryCode": "AU"
            },
            {
                "name": "Sun Valley",
                "countryCode": "AU"
            },
            {
                "name": "Sunnybank",
                "countryCode": "AU"
            },
            {
                "name": "Sunnybank Hills",
                "countryCode": "AU"
            },
            {
                "name": "Sunrise Beach",
                "countryCode": "AU"
            },
            {
                "name": "Sunset",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine Beach",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine Coast",
                "countryCode": "AU"
            },
            {
                "name": "Surfers Paradise",
                "countryCode": "AU"
            },
            {
                "name": "Svensson Heights",
                "countryCode": "AU"
            },
            {
                "name": "Taigum",
                "countryCode": "AU"
            },
            {
                "name": "Tallai",
                "countryCode": "AU"
            },
            {
                "name": "Tallebudgera",
                "countryCode": "AU"
            },
            {
                "name": "Tallebudgera Valley",
                "countryCode": "AU"
            },
            {
                "name": "Tamborine",
                "countryCode": "AU"
            },
            {
                "name": "Tamborine Mountain",
                "countryCode": "AU"
            },
            {
                "name": "Tanah Merah",
                "countryCode": "AU"
            },
            {
                "name": "Tanawha",
                "countryCode": "AU"
            },
            {
                "name": "Tannum Sands",
                "countryCode": "AU"
            },
            {
                "name": "Tara",
                "countryCode": "AU"
            },
            {
                "name": "Taranganba",
                "countryCode": "AU"
            },
            {
                "name": "Taringa",
                "countryCode": "AU"
            },
            {
                "name": "Tarragindi",
                "countryCode": "AU"
            },
            {
                "name": "Telina",
                "countryCode": "AU"
            },
            {
                "name": "Teneriffe",
                "countryCode": "AU"
            },
            {
                "name": "Tewantin",
                "countryCode": "AU"
            },
            {
                "name": "Thabeban",
                "countryCode": "AU"
            },
            {
                "name": "The Gap",
                "countryCode": "AU"
            },
            {
                "name": "The Gemfields",
                "countryCode": "AU"
            },
            {
                "name": "The Range",
                "countryCode": "AU"
            },
            {
                "name": "Thorneside",
                "countryCode": "AU"
            },
            {
                "name": "Thornlands",
                "countryCode": "AU"
            },
            {
                "name": "Thursday Island",
                "countryCode": "AU"
            },
            {
                "name": "Tieri",
                "countryCode": "AU"
            },
            {
                "name": "Tin Can Bay",
                "countryCode": "AU"
            },
            {
                "name": "Tinana",
                "countryCode": "AU"
            },
            {
                "name": "Tingalpa",
                "countryCode": "AU"
            },
            {
                "name": "Tivoli",
                "countryCode": "AU"
            },
            {
                "name": "Tolga",
                "countryCode": "AU"
            },
            {
                "name": "Toogoolawah",
                "countryCode": "AU"
            },
            {
                "name": "Toogoom",
                "countryCode": "AU"
            },
            {
                "name": "Toowong",
                "countryCode": "AU"
            },
            {
                "name": "Toowoomba",
                "countryCode": "AU"
            },
            {
                "name": "Torquay",
                "countryCode": "AU"
            },
            {
                "name": "Torres",
                "countryCode": "AU"
            },
            {
                "name": "Torres Strait Island Region",
                "countryCode": "AU"
            },
            {
                "name": "Townsville",
                "countryCode": "AU"
            },
            {
                "name": "Townview",
                "countryCode": "AU"
            },
            {
                "name": "Trinity Beach",
                "countryCode": "AU"
            },
            {
                "name": "Trinity Park",
                "countryCode": "AU"
            },
            {
                "name": "Trunding",
                "countryCode": "AU"
            },
            {
                "name": "Tugun",
                "countryCode": "AU"
            },
            {
                "name": "Tully",
                "countryCode": "AU"
            },
            {
                "name": "Twin Waters",
                "countryCode": "AU"
            },
            {
                "name": "Underwood",
                "countryCode": "AU"
            },
            {
                "name": "Upper Caboolture",
                "countryCode": "AU"
            },
            {
                "name": "Upper Coomera",
                "countryCode": "AU"
            },
            {
                "name": "Upper Kedron",
                "countryCode": "AU"
            },
            {
                "name": "Upper Mount Gravatt",
                "countryCode": "AU"
            },
            {
                "name": "Urangan",
                "countryCode": "AU"
            },
            {
                "name": "Urraween",
                "countryCode": "AU"
            },
            {
                "name": "Varsity Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Victoria Point",
                "countryCode": "AU"
            },
            {
                "name": "Vincent",
                "countryCode": "AU"
            },
            {
                "name": "Virginia",
                "countryCode": "AU"
            },
            {
                "name": "Wacol",
                "countryCode": "AU"
            },
            {
                "name": "Wakerley",
                "countryCode": "AU"
            },
            {
                "name": "Walkerston",
                "countryCode": "AU"
            },
            {
                "name": "Walkervale",
                "countryCode": "AU"
            },
            {
                "name": "Walloon",
                "countryCode": "AU"
            },
            {
                "name": "Wamuran",
                "countryCode": "AU"
            },
            {
                "name": "Wandal",
                "countryCode": "AU"
            },
            {
                "name": "Wandoan",
                "countryCode": "AU"
            },
            {
                "name": "Warana",
                "countryCode": "AU"
            },
            {
                "name": "Warner",
                "countryCode": "AU"
            },
            {
                "name": "Warwick",
                "countryCode": "AU"
            },
            {
                "name": "Waterford West",
                "countryCode": "AU"
            },
            {
                "name": "Wavell Heights",
                "countryCode": "AU"
            },
            {
                "name": "Weipa",
                "countryCode": "AU"
            },
            {
                "name": "Wellesley Islands",
                "countryCode": "AU"
            },
            {
                "name": "Wellington Point",
                "countryCode": "AU"
            },
            {
                "name": "West End",
                "countryCode": "AU"
            },
            {
                "name": "West Gladstone",
                "countryCode": "AU"
            },
            {
                "name": "West Mackay",
                "countryCode": "AU"
            },
            {
                "name": "West Rockhampton",
                "countryCode": "AU"
            },
            {
                "name": "West Woombye",
                "countryCode": "AU"
            },
            {
                "name": "Westbrook",
                "countryCode": "AU"
            },
            {
                "name": "Westcourt",
                "countryCode": "AU"
            },
            {
                "name": "Western Downs",
                "countryCode": "AU"
            },
            {
                "name": "Westlake",
                "countryCode": "AU"
            },
            {
                "name": "White Rock",
                "countryCode": "AU"
            },
            {
                "name": "Whitfield",
                "countryCode": "AU"
            },
            {
                "name": "Whitsunday",
                "countryCode": "AU"
            },
            {
                "name": "Whitsundays",
                "countryCode": "AU"
            },
            {
                "name": "Willow Vale",
                "countryCode": "AU"
            },
            {
                "name": "Willowbank",
                "countryCode": "AU"
            },
            {
                "name": "Wilsonton",
                "countryCode": "AU"
            },
            {
                "name": "Wilsonton Heights",
                "countryCode": "AU"
            },
            {
                "name": "Wilston",
                "countryCode": "AU"
            },
            {
                "name": "Windaroo",
                "countryCode": "AU"
            },
            {
                "name": "Windsor",
                "countryCode": "AU"
            },
            {
                "name": "Winston",
                "countryCode": "AU"
            },
            {
                "name": "Winton",
                "countryCode": "AU"
            },
            {
                "name": "Wishart",
                "countryCode": "AU"
            },
            {
                "name": "Withcott",
                "countryCode": "AU"
            },
            {
                "name": "Witta",
                "countryCode": "AU"
            },
            {
                "name": "Wondai",
                "countryCode": "AU"
            },
            {
                "name": "Wondunna",
                "countryCode": "AU"
            },
            {
                "name": "Wongaling Beach",
                "countryCode": "AU"
            },
            {
                "name": "Wongawallan",
                "countryCode": "AU"
            },
            {
                "name": "Woodend",
                "countryCode": "AU"
            },
            {
                "name": "Woodford",
                "countryCode": "AU"
            },
            {
                "name": "Woodgate",
                "countryCode": "AU"
            },
            {
                "name": "Woodridge",
                "countryCode": "AU"
            },
            {
                "name": "Woody Point",
                "countryCode": "AU"
            },
            {
                "name": "Woolloongabba",
                "countryCode": "AU"
            },
            {
                "name": "Wooloowin",
                "countryCode": "AU"
            },
            {
                "name": "Woombye",
                "countryCode": "AU"
            },
            {
                "name": "Woorabinda",
                "countryCode": "AU"
            },
            {
                "name": "Woorim",
                "countryCode": "AU"
            },
            {
                "name": "Woree",
                "countryCode": "AU"
            },
            {
                "name": "Worongary",
                "countryCode": "AU"
            },
            {
                "name": "Wujal Wujal",
                "countryCode": "AU"
            },
            {
                "name": "Wulguru",
                "countryCode": "AU"
            },
            {
                "name": "Wulkuraka",
                "countryCode": "AU"
            },
            {
                "name": "Wurtulla",
                "countryCode": "AU"
            },
            {
                "name": "Wynnum",
                "countryCode": "AU"
            },
            {
                "name": "Wynnum West",
                "countryCode": "AU"
            },
            {
                "name": "Wyreema",
                "countryCode": "AU"
            },
            {
                "name": "Yamanto",
                "countryCode": "AU"
            },
            {
                "name": "Yandina",
                "countryCode": "AU"
            },
            {
                "name": "Yaroomba",
                "countryCode": "AU"
            },
            {
                "name": "Yarrabah",
                "countryCode": "AU"
            },
            {
                "name": "Yarrabilba",
                "countryCode": "AU"
            },
            {
                "name": "Yarraman",
                "countryCode": "AU"
            },
            {
                "name": "Yatala",
                "countryCode": "AU"
            },
            {
                "name": "Yeerongpilly",
                "countryCode": "AU"
            },
            {
                "name": "Yeppoon",
                "countryCode": "AU"
            },
            {
                "name": "Yeppoon city centre",
                "countryCode": "AU"
            },
            {
                "name": "Yeronga",
                "countryCode": "AU"
            },
            {
                "name": "Yorkeys Knob",
                "countryCode": "AU"
            },
            {
                "name": "Yungaburra",
                "countryCode": "AU"
            },
            {
                "name": "Zillmere",
                "countryCode": "AU"
            },
            {
                "name": "Zilzie",
                "countryCode": "AU"
            },
            {
                "name": "Aberfoyle Park",
                "countryCode": "AU"
            },
            {
                "name": "Adelaide",
                "countryCode": "AU"
            },
            {
                "name": "Adelaide Hills",
                "countryCode": "AU"
            },
            {
                "name": "Adelaide city centre",
                "countryCode": "AU"
            },
            {
                "name": "Albert Park",
                "countryCode": "AU"
            },
            {
                "name": "Alberton",
                "countryCode": "AU"
            },
            {
                "name": "Aldgate",
                "countryCode": "AU"
            },
            {
                "name": "Aldinga Beach",
                "countryCode": "AU"
            },
            {
                "name": "Alexandrina",
                "countryCode": "AU"
            },
            {
                "name": "Allenby Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Anangu Pitjantjatjara",
                "countryCode": "AU"
            },
            {
                "name": "Andrews Farm",
                "countryCode": "AU"
            },
            {
                "name": "Angaston",
                "countryCode": "AU"
            },
            {
                "name": "Angle Park",
                "countryCode": "AU"
            },
            {
                "name": "Angle Vale",
                "countryCode": "AU"
            },
            {
                "name": "Ardrossan",
                "countryCode": "AU"
            },
            {
                "name": "Ascot Park",
                "countryCode": "AU"
            },
            {
                "name": "Ashford",
                "countryCode": "AU"
            },
            {
                "name": "Athelstone",
                "countryCode": "AU"
            },
            {
                "name": "Athol Park",
                "countryCode": "AU"
            },
            {
                "name": "Balaklava",
                "countryCode": "AU"
            },
            {
                "name": "Balhannah",
                "countryCode": "AU"
            },
            {
                "name": "Banksia Park",
                "countryCode": "AU"
            },
            {
                "name": "Barmera",
                "countryCode": "AU"
            },
            {
                "name": "Barossa",
                "countryCode": "AU"
            },
            {
                "name": "Barunga West",
                "countryCode": "AU"
            },
            {
                "name": "Beaumont",
                "countryCode": "AU"
            },
            {
                "name": "Bedford Park",
                "countryCode": "AU"
            },
            {
                "name": "Belair",
                "countryCode": "AU"
            },
            {
                "name": "Bellevue Heights",
                "countryCode": "AU"
            },
            {
                "name": "Berri",
                "countryCode": "AU"
            },
            {
                "name": "Berri and Barmera",
                "countryCode": "AU"
            },
            {
                "name": "Beulah Park",
                "countryCode": "AU"
            },
            {
                "name": "Beverley",
                "countryCode": "AU"
            },
            {
                "name": "Birdwood",
                "countryCode": "AU"
            },
            {
                "name": "Birkenhead",
                "countryCode": "AU"
            },
            {
                "name": "Black Forest",
                "countryCode": "AU"
            },
            {
                "name": "Blackwood",
                "countryCode": "AU"
            },
            {
                "name": "Blair Athol",
                "countryCode": "AU"
            },
            {
                "name": "Blakeview",
                "countryCode": "AU"
            },
            {
                "name": "Bordertown",
                "countryCode": "AU"
            },
            {
                "name": "Brahma Lodge",
                "countryCode": "AU"
            },
            {
                "name": "Bridgewater",
                "countryCode": "AU"
            },
            {
                "name": "Brighton",
                "countryCode": "AU"
            },
            {
                "name": "Broadview",
                "countryCode": "AU"
            },
            {
                "name": "Brompton",
                "countryCode": "AU"
            },
            {
                "name": "Brooklyn Park",
                "countryCode": "AU"
            },
            {
                "name": "Burnside",
                "countryCode": "AU"
            },
            {
                "name": "Burra",
                "countryCode": "AU"
            },
            {
                "name": "Burton",
                "countryCode": "AU"
            },
            {
                "name": "Camden Park",
                "countryCode": "AU"
            },
            {
                "name": "Campbelltown",
                "countryCode": "AU"
            },
            {
                "name": "Ceduna",
                "countryCode": "AU"
            },
            {
                "name": "Charles Sturt",
                "countryCode": "AU"
            },
            {
                "name": "Cheltenham",
                "countryCode": "AU"
            },
            {
                "name": "Christie Downs",
                "countryCode": "AU"
            },
            {
                "name": "Christies Beach",
                "countryCode": "AU"
            },
            {
                "name": "City of West Torrens",
                "countryCode": "AU"
            },
            {
                "name": "Clapham",
                "countryCode": "AU"
            },
            {
                "name": "Clare",
                "countryCode": "AU"
            },
            {
                "name": "Clare and Gilbert Valleys",
                "countryCode": "AU"
            },
            {
                "name": "Clarence Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Clarence Park",
                "countryCode": "AU"
            },
            {
                "name": "Clearview",
                "countryCode": "AU"
            },
            {
                "name": "Cleve",
                "countryCode": "AU"
            },
            {
                "name": "Clovelly Park",
                "countryCode": "AU"
            },
            {
                "name": "Collinswood",
                "countryCode": "AU"
            },
            {
                "name": "Colonel Light Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Coober Pedy",
                "countryCode": "AU"
            },
            {
                "name": "Copper Coast",
                "countryCode": "AU"
            },
            {
                "name": "Coromandel Valley",
                "countryCode": "AU"
            },
            {
                "name": "Cowandilla",
                "countryCode": "AU"
            },
            {
                "name": "Cowell",
                "countryCode": "AU"
            },
            {
                "name": "Crafers",
                "countryCode": "AU"
            },
            {
                "name": "Crafers West",
                "countryCode": "AU"
            },
            {
                "name": "Craigburn Farm",
                "countryCode": "AU"
            },
            {
                "name": "Craigmore",
                "countryCode": "AU"
            },
            {
                "name": "Croydon Park",
                "countryCode": "AU"
            },
            {
                "name": "Crystal Brook",
                "countryCode": "AU"
            },
            {
                "name": "Cumberland Park",
                "countryCode": "AU"
            },
            {
                "name": "Darlington",
                "countryCode": "AU"
            },
            {
                "name": "Davoren Park",
                "countryCode": "AU"
            },
            {
                "name": "Daw Park",
                "countryCode": "AU"
            },
            {
                "name": "Dernancourt",
                "countryCode": "AU"
            },
            {
                "name": "Dover Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Dulwich",
                "countryCode": "AU"
            },
            {
                "name": "Echunga",
                "countryCode": "AU"
            },
            {
                "name": "Eden Hills",
                "countryCode": "AU"
            },
            {
                "name": "Edwardstown",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Downs",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth East",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Grove",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth North",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Park",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth South",
                "countryCode": "AU"
            },
            {
                "name": "Elizabeth Vale",
                "countryCode": "AU"
            },
            {
                "name": "Elliston",
                "countryCode": "AU"
            },
            {
                "name": "Encounter Bay",
                "countryCode": "AU"
            },
            {
                "name": "Enfield",
                "countryCode": "AU"
            },
            {
                "name": "Erindale",
                "countryCode": "AU"
            },
            {
                "name": "Ethelton",
                "countryCode": "AU"
            },
            {
                "name": "Evandale",
                "countryCode": "AU"
            },
            {
                "name": "Evanston",
                "countryCode": "AU"
            },
            {
                "name": "Evanston Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Evanston Park",
                "countryCode": "AU"
            },
            {
                "name": "Everard Park",
                "countryCode": "AU"
            },
            {
                "name": "Exeter",
                "countryCode": "AU"
            },
            {
                "name": "Fairview Park",
                "countryCode": "AU"
            },
            {
                "name": "Felixstow",
                "countryCode": "AU"
            },
            {
                "name": "Ferryden Park",
                "countryCode": "AU"
            },
            {
                "name": "Findon",
                "countryCode": "AU"
            },
            {
                "name": "Firle",
                "countryCode": "AU"
            },
            {
                "name": "Flagstaff Hill",
                "countryCode": "AU"
            },
            {
                "name": "Flinders Park",
                "countryCode": "AU"
            },
            {
                "name": "Flinders Ranges",
                "countryCode": "AU"
            },
            {
                "name": "Forestville",
                "countryCode": "AU"
            },
            {
                "name": "Franklin Harbour",
                "countryCode": "AU"
            },
            {
                "name": "Freeling",
                "countryCode": "AU"
            },
            {
                "name": "Fulham",
                "countryCode": "AU"
            },
            {
                "name": "Fulham Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Fullarton",
                "countryCode": "AU"
            },
            {
                "name": "Gawler",
                "countryCode": "AU"
            },
            {
                "name": "Gawler East",
                "countryCode": "AU"
            },
            {
                "name": "Gawler South",
                "countryCode": "AU"
            },
            {
                "name": "Gilberton",
                "countryCode": "AU"
            },
            {
                "name": "Gilles Plains",
                "countryCode": "AU"
            },
            {
                "name": "Glandore",
                "countryCode": "AU"
            },
            {
                "name": "Glen Osmond",
                "countryCode": "AU"
            },
            {
                "name": "Glenalta",
                "countryCode": "AU"
            },
            {
                "name": "Glenelg",
                "countryCode": "AU"
            },
            {
                "name": "Glenelg East",
                "countryCode": "AU"
            },
            {
                "name": "Glenelg North",
                "countryCode": "AU"
            },
            {
                "name": "Glenelg South",
                "countryCode": "AU"
            },
            {
                "name": "Glengowrie",
                "countryCode": "AU"
            },
            {
                "name": "Glenside",
                "countryCode": "AU"
            },
            {
                "name": "Glenunga",
                "countryCode": "AU"
            },
            {
                "name": "Glynde",
                "countryCode": "AU"
            },
            {
                "name": "Golden Grove",
                "countryCode": "AU"
            },
            {
                "name": "Goodwood",
                "countryCode": "AU"
            },
            {
                "name": "Goolwa",
                "countryCode": "AU"
            },
            {
                "name": "Goolwa Beach",
                "countryCode": "AU"
            },
            {
                "name": "Goyder",
                "countryCode": "AU"
            },
            {
                "name": "Grange",
                "countryCode": "AU"
            },
            {
                "name": "Grant",
                "countryCode": "AU"
            },
            {
                "name": "Greenacres",
                "countryCode": "AU"
            },
            {
                "name": "Greenock",
                "countryCode": "AU"
            },
            {
                "name": "Greenwith",
                "countryCode": "AU"
            },
            {
                "name": "Gulfview Heights",
                "countryCode": "AU"
            },
            {
                "name": "Hackham",
                "countryCode": "AU"
            },
            {
                "name": "Hackham West",
                "countryCode": "AU"
            },
            {
                "name": "Hahndorf",
                "countryCode": "AU"
            },
            {
                "name": "Hallett Cove",
                "countryCode": "AU"
            },
            {
                "name": "Hampstead Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Happy Valley",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorn",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorndene",
                "countryCode": "AU"
            },
            {
                "name": "Hayborough",
                "countryCode": "AU"
            },
            {
                "name": "Hazelwood Park",
                "countryCode": "AU"
            },
            {
                "name": "Hectorville",
                "countryCode": "AU"
            },
            {
                "name": "Henley Beach",
                "countryCode": "AU"
            },
            {
                "name": "Henley Beach South",
                "countryCode": "AU"
            },
            {
                "name": "Hewett",
                "countryCode": "AU"
            },
            {
                "name": "Highbury",
                "countryCode": "AU"
            },
            {
                "name": "Highgate",
                "countryCode": "AU"
            },
            {
                "name": "Hillbank",
                "countryCode": "AU"
            },
            {
                "name": "Hillcrest",
                "countryCode": "AU"
            },
            {
                "name": "Hindmarsh Island",
                "countryCode": "AU"
            },
            {
                "name": "Holden Hill",
                "countryCode": "AU"
            },
            {
                "name": "Holdfast Bay",
                "countryCode": "AU"
            },
            {
                "name": "Hope Valley",
                "countryCode": "AU"
            },
            {
                "name": "Hove",
                "countryCode": "AU"
            },
            {
                "name": "Huntfield Heights",
                "countryCode": "AU"
            },
            {
                "name": "Hyde Park",
                "countryCode": "AU"
            },
            {
                "name": "Ingle Farm",
                "countryCode": "AU"
            },
            {
                "name": "Jamestown",
                "countryCode": "AU"
            },
            {
                "name": "Joslin",
                "countryCode": "AU"
            },
            {
                "name": "Kadina",
                "countryCode": "AU"
            },
            {
                "name": "Kangaroo Island",
                "countryCode": "AU"
            },
            {
                "name": "Kapunda",
                "countryCode": "AU"
            },
            {
                "name": "Karoonda East Murray",
                "countryCode": "AU"
            },
            {
                "name": "Keith",
                "countryCode": "AU"
            },
            {
                "name": "Kensington Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Kensington Park",
                "countryCode": "AU"
            },
            {
                "name": "Kent Town",
                "countryCode": "AU"
            },
            {
                "name": "Kersbrook",
                "countryCode": "AU"
            },
            {
                "name": "Kidman Park",
                "countryCode": "AU"
            },
            {
                "name": "Kilburn",
                "countryCode": "AU"
            },
            {
                "name": "Kilkenny",
                "countryCode": "AU"
            },
            {
                "name": "Kimba",
                "countryCode": "AU"
            },
            {
                "name": "Kingscote",
                "countryCode": "AU"
            },
            {
                "name": "Kingston",
                "countryCode": "AU"
            },
            {
                "name": "Kingston South East",
                "countryCode": "AU"
            },
            {
                "name": "Klemzig",
                "countryCode": "AU"
            },
            {
                "name": "Kurralta Park",
                "countryCode": "AU"
            },
            {
                "name": "Largs Bay",
                "countryCode": "AU"
            },
            {
                "name": "Largs North",
                "countryCode": "AU"
            },
            {
                "name": "Leabrook",
                "countryCode": "AU"
            },
            {
                "name": "Lewiston",
                "countryCode": "AU"
            },
            {
                "name": "Light",
                "countryCode": "AU"
            },
            {
                "name": "Linden Park",
                "countryCode": "AU"
            },
            {
                "name": "Little Hampton",
                "countryCode": "AU"
            },
            {
                "name": "Lobethal",
                "countryCode": "AU"
            },
            {
                "name": "Lockleys",
                "countryCode": "AU"
            },
            {
                "name": "Lower Eyre Peninsula",
                "countryCode": "AU"
            },
            {
                "name": "Lower Mitcham",
                "countryCode": "AU"
            },
            {
                "name": "Loxton",
                "countryCode": "AU"
            },
            {
                "name": "Loxton Waikerie",
                "countryCode": "AU"
            },
            {
                "name": "Lyndoch",
                "countryCode": "AU"
            },
            {
                "name": "Macclesfield",
                "countryCode": "AU"
            },
            {
                "name": "Magill",
                "countryCode": "AU"
            },
            {
                "name": "Maitland",
                "countryCode": "AU"
            },
            {
                "name": "Mallala",
                "countryCode": "AU"
            },
            {
                "name": "Malvern",
                "countryCode": "AU"
            },
            {
                "name": "Manningham",
                "countryCode": "AU"
            },
            {
                "name": "Mannum",
                "countryCode": "AU"
            },
            {
                "name": "Mansfield Park",
                "countryCode": "AU"
            },
            {
                "name": "Maralinga Tjarutja",
                "countryCode": "AU"
            },
            {
                "name": "Marden",
                "countryCode": "AU"
            },
            {
                "name": "Marino",
                "countryCode": "AU"
            },
            {
                "name": "Marion",
                "countryCode": "AU"
            },
            {
                "name": "Marleston",
                "countryCode": "AU"
            },
            {
                "name": "Maslin Beach",
                "countryCode": "AU"
            },
            {
                "name": "Mawson Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Maylands",
                "countryCode": "AU"
            },
            {
                "name": "McCracken",
                "countryCode": "AU"
            },
            {
                "name": "McLaren Flat",
                "countryCode": "AU"
            },
            {
                "name": "McLaren Vale",
                "countryCode": "AU"
            },
            {
                "name": "Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Medindie",
                "countryCode": "AU"
            },
            {
                "name": "Melrose Park",
                "countryCode": "AU"
            },
            {
                "name": "Meningie",
                "countryCode": "AU"
            },
            {
                "name": "Mid Murray",
                "countryCode": "AU"
            },
            {
                "name": "Middleton",
                "countryCode": "AU"
            },
            {
                "name": "Mile End",
                "countryCode": "AU"
            },
            {
                "name": "Millicent",
                "countryCode": "AU"
            },
            {
                "name": "Millswood",
                "countryCode": "AU"
            },
            {
                "name": "Minlaton",
                "countryCode": "AU"
            },
            {
                "name": "Mitcham",
                "countryCode": "AU"
            },
            {
                "name": "Mitchell Park",
                "countryCode": "AU"
            },
            {
                "name": "Moana",
                "countryCode": "AU"
            },
            {
                "name": "Modbury",
                "countryCode": "AU"
            },
            {
                "name": "Modbury Heights",
                "countryCode": "AU"
            },
            {
                "name": "Modbury North",
                "countryCode": "AU"
            },
            {
                "name": "Monash",
                "countryCode": "AU"
            },
            {
                "name": "Moonta Bay",
                "countryCode": "AU"
            },
            {
                "name": "Moorak",
                "countryCode": "AU"
            },
            {
                "name": "Morphett Vale",
                "countryCode": "AU"
            },
            {
                "name": "Morphettville",
                "countryCode": "AU"
            },
            {
                "name": "Mount Barker",
                "countryCode": "AU"
            },
            {
                "name": "Mount Compass",
                "countryCode": "AU"
            },
            {
                "name": "Mount Gambier",
                "countryCode": "AU"
            },
            {
                "name": "Mount Remarkable",
                "countryCode": "AU"
            },
            {
                "name": "Munno Para",
                "countryCode": "AU"
            },
            {
                "name": "Munno Para West",
                "countryCode": "AU"
            },
            {
                "name": "Murray Bridge",
                "countryCode": "AU"
            },
            {
                "name": "Mylor",
                "countryCode": "AU"
            },
            {
                "name": "Myrtle Bank",
                "countryCode": "AU"
            },
            {
                "name": "Nailsworth",
                "countryCode": "AU"
            },
            {
                "name": "Nairne",
                "countryCode": "AU"
            },
            {
                "name": "Naracoorte",
                "countryCode": "AU"
            },
            {
                "name": "Naracoorte and Lucindale",
                "countryCode": "AU"
            },
            {
                "name": "Netherby",
                "countryCode": "AU"
            },
            {
                "name": "Netley",
                "countryCode": "AU"
            },
            {
                "name": "Newton",
                "countryCode": "AU"
            },
            {
                "name": "Noarlunga Downs",
                "countryCode": "AU"
            },
            {
                "name": "Normanville",
                "countryCode": "AU"
            },
            {
                "name": "North Adelaide",
                "countryCode": "AU"
            },
            {
                "name": "North Brighton",
                "countryCode": "AU"
            },
            {
                "name": "North Haven",
                "countryCode": "AU"
            },
            {
                "name": "North Plympton",
                "countryCode": "AU"
            },
            {
                "name": "Northern Areas",
                "countryCode": "AU"
            },
            {
                "name": "Northfield",
                "countryCode": "AU"
            },
            {
                "name": "Northgate",
                "countryCode": "AU"
            },
            {
                "name": "Norwood",
                "countryCode": "AU"
            },
            {
                "name": "Norwood Payneham St Peters",
                "countryCode": "AU"
            },
            {
                "name": "Novar Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Nuriootpa",
                "countryCode": "AU"
            },
            {
                "name": "O'Sullivan Beach",
                "countryCode": "AU"
            },
            {
                "name": "Oakden",
                "countryCode": "AU"
            },
            {
                "name": "Oaklands Park",
                "countryCode": "AU"
            },
            {
                "name": "Old Noarlunga",
                "countryCode": "AU"
            },
            {
                "name": "Old Reynella",
                "countryCode": "AU"
            },
            {
                "name": "One Tree Hill",
                "countryCode": "AU"
            },
            {
                "name": "Onkaparinga",
                "countryCode": "AU"
            },
            {
                "name": "Onkaparinga Hills",
                "countryCode": "AU"
            },
            {
                "name": "Orroroo/Carrieton",
                "countryCode": "AU"
            },
            {
                "name": "Osborne",
                "countryCode": "AU"
            },
            {
                "name": "Ottoway",
                "countryCode": "AU"
            },
            {
                "name": "O’Halloran Hill",
                "countryCode": "AU"
            },
            {
                "name": "Panorama",
                "countryCode": "AU"
            },
            {
                "name": "Para Hills",
                "countryCode": "AU"
            },
            {
                "name": "Para Hills West",
                "countryCode": "AU"
            },
            {
                "name": "Para Vista",
                "countryCode": "AU"
            },
            {
                "name": "Paradise",
                "countryCode": "AU"
            },
            {
                "name": "Parafield Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Paralowie",
                "countryCode": "AU"
            },
            {
                "name": "Paringa",
                "countryCode": "AU"
            },
            {
                "name": "Park Holme",
                "countryCode": "AU"
            },
            {
                "name": "Parkside",
                "countryCode": "AU"
            },
            {
                "name": "Pasadena",
                "countryCode": "AU"
            },
            {
                "name": "Payneham",
                "countryCode": "AU"
            },
            {
                "name": "Payneham South",
                "countryCode": "AU"
            },
            {
                "name": "Pennington",
                "countryCode": "AU"
            },
            {
                "name": "Penola",
                "countryCode": "AU"
            },
            {
                "name": "Peterborough",
                "countryCode": "AU"
            },
            {
                "name": "Peterhead",
                "countryCode": "AU"
            },
            {
                "name": "Playford",
                "countryCode": "AU"
            },
            {
                "name": "Plympton",
                "countryCode": "AU"
            },
            {
                "name": "Plympton Park",
                "countryCode": "AU"
            },
            {
                "name": "Pooraka",
                "countryCode": "AU"
            },
            {
                "name": "Port Adelaide",
                "countryCode": "AU"
            },
            {
                "name": "Port Adelaide Enfield",
                "countryCode": "AU"
            },
            {
                "name": "Port Augusta",
                "countryCode": "AU"
            },
            {
                "name": "Port Augusta West",
                "countryCode": "AU"
            },
            {
                "name": "Port Broughton",
                "countryCode": "AU"
            },
            {
                "name": "Port Elliot",
                "countryCode": "AU"
            },
            {
                "name": "Port Lincoln",
                "countryCode": "AU"
            },
            {
                "name": "Port Noarlunga",
                "countryCode": "AU"
            },
            {
                "name": "Port Noarlunga South",
                "countryCode": "AU"
            },
            {
                "name": "Port Pirie",
                "countryCode": "AU"
            },
            {
                "name": "Port Pirie City and Dists",
                "countryCode": "AU"
            },
            {
                "name": "Port Pirie South",
                "countryCode": "AU"
            },
            {
                "name": "Port Pirie West",
                "countryCode": "AU"
            },
            {
                "name": "Port Willunga",
                "countryCode": "AU"
            },
            {
                "name": "Prospect",
                "countryCode": "AU"
            },
            {
                "name": "Queenstown",
                "countryCode": "AU"
            },
            {
                "name": "Quorn",
                "countryCode": "AU"
            },
            {
                "name": "Redwood Park",
                "countryCode": "AU"
            },
            {
                "name": "Renmark",
                "countryCode": "AU"
            },
            {
                "name": "Renmark Paringa",
                "countryCode": "AU"
            },
            {
                "name": "Renmark West",
                "countryCode": "AU"
            },
            {
                "name": "Renown Park",
                "countryCode": "AU"
            },
            {
                "name": "Reynella",
                "countryCode": "AU"
            },
            {
                "name": "Reynella East",
                "countryCode": "AU"
            },
            {
                "name": "Richmond",
                "countryCode": "AU"
            },
            {
                "name": "Ridgehaven",
                "countryCode": "AU"
            },
            {
                "name": "Ridleyton",
                "countryCode": "AU"
            },
            {
                "name": "Risdon Park",
                "countryCode": "AU"
            },
            {
                "name": "Risdon Park South",
                "countryCode": "AU"
            },
            {
                "name": "Robe",
                "countryCode": "AU"
            },
            {
                "name": "Rose Park",
                "countryCode": "AU"
            },
            {
                "name": "Rosewater",
                "countryCode": "AU"
            },
            {
                "name": "Rosslyn Park",
                "countryCode": "AU"
            },
            {
                "name": "Rostrevor",
                "countryCode": "AU"
            },
            {
                "name": "Roxby Downs",
                "countryCode": "AU"
            },
            {
                "name": "Royal Park",
                "countryCode": "AU"
            },
            {
                "name": "Royston Park",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury Downs",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury East",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury Heights",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury North",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury Park",
                "countryCode": "AU"
            },
            {
                "name": "Salisbury Plain",
                "countryCode": "AU"
            },
            {
                "name": "Seacliff",
                "countryCode": "AU"
            },
            {
                "name": "Seacliff Park",
                "countryCode": "AU"
            },
            {
                "name": "Seacombe Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Seacombe Heights",
                "countryCode": "AU"
            },
            {
                "name": "Seaford",
                "countryCode": "AU"
            },
            {
                "name": "Seaford Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Seaford Rise",
                "countryCode": "AU"
            },
            {
                "name": "Seaton",
                "countryCode": "AU"
            },
            {
                "name": "Seaview Downs",
                "countryCode": "AU"
            },
            {
                "name": "Sefton Park",
                "countryCode": "AU"
            },
            {
                "name": "Sellicks Beach",
                "countryCode": "AU"
            },
            {
                "name": "Semaphore",
                "countryCode": "AU"
            },
            {
                "name": "Semaphore Park",
                "countryCode": "AU"
            },
            {
                "name": "Semaphore South",
                "countryCode": "AU"
            },
            {
                "name": "Sheidow Park",
                "countryCode": "AU"
            },
            {
                "name": "Smithfield",
                "countryCode": "AU"
            },
            {
                "name": "Smithfield Plains",
                "countryCode": "AU"
            },
            {
                "name": "Solomontown",
                "countryCode": "AU"
            },
            {
                "name": "Somerton Park",
                "countryCode": "AU"
            },
            {
                "name": "South Brighton",
                "countryCode": "AU"
            },
            {
                "name": "South Plympton",
                "countryCode": "AU"
            },
            {
                "name": "Southern Mallee",
                "countryCode": "AU"
            },
            {
                "name": "St Agnes",
                "countryCode": "AU"
            },
            {
                "name": "St Georges",
                "countryCode": "AU"
            },
            {
                "name": "St Marys",
                "countryCode": "AU"
            },
            {
                "name": "St Morris",
                "countryCode": "AU"
            },
            {
                "name": "St Peters",
                "countryCode": "AU"
            },
            {
                "name": "Stirling",
                "countryCode": "AU"
            },
            {
                "name": "Stirling North",
                "countryCode": "AU"
            },
            {
                "name": "Stonyfell",
                "countryCode": "AU"
            },
            {
                "name": "Strathalbyn",
                "countryCode": "AU"
            },
            {
                "name": "Streaky Bay",
                "countryCode": "AU"
            },
            {
                "name": "Sturt",
                "countryCode": "AU"
            },
            {
                "name": "Surrey Downs",
                "countryCode": "AU"
            },
            {
                "name": "Tailem Bend",
                "countryCode": "AU"
            },
            {
                "name": "Tanunda",
                "countryCode": "AU"
            },
            {
                "name": "Taperoo",
                "countryCode": "AU"
            },
            {
                "name": "Tatiara",
                "countryCode": "AU"
            },
            {
                "name": "Tea Tree Gully",
                "countryCode": "AU"
            },
            {
                "name": "Tennyson",
                "countryCode": "AU"
            },
            {
                "name": "The Coorong",
                "countryCode": "AU"
            },
            {
                "name": "Thebarton",
                "countryCode": "AU"
            },
            {
                "name": "Toorak Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Torrens Park",
                "countryCode": "AU"
            },
            {
                "name": "Torrensville",
                "countryCode": "AU"
            },
            {
                "name": "Tranmere",
                "countryCode": "AU"
            },
            {
                "name": "Trinity Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Trott Park",
                "countryCode": "AU"
            },
            {
                "name": "Tumby Bay",
                "countryCode": "AU"
            },
            {
                "name": "Tusmore",
                "countryCode": "AU"
            },
            {
                "name": "Two Wells",
                "countryCode": "AU"
            },
            {
                "name": "Underdale",
                "countryCode": "AU"
            },
            {
                "name": "Unley",
                "countryCode": "AU"
            },
            {
                "name": "Unley Park",
                "countryCode": "AU"
            },
            {
                "name": "Vale Park",
                "countryCode": "AU"
            },
            {
                "name": "Valley View",
                "countryCode": "AU"
            },
            {
                "name": "Victor Harbor",
                "countryCode": "AU"
            },
            {
                "name": "Virginia",
                "countryCode": "AU"
            },
            {
                "name": "Waikerie",
                "countryCode": "AU"
            },
            {
                "name": "Wakefield",
                "countryCode": "AU"
            },
            {
                "name": "Walkerville",
                "countryCode": "AU"
            },
            {
                "name": "Walkley Heights",
                "countryCode": "AU"
            },
            {
                "name": "Wallaroo",
                "countryCode": "AU"
            },
            {
                "name": "Warradale",
                "countryCode": "AU"
            },
            {
                "name": "Waterloo Corner",
                "countryCode": "AU"
            },
            {
                "name": "Wattle Park",
                "countryCode": "AU"
            },
            {
                "name": "Wattle Range",
                "countryCode": "AU"
            },
            {
                "name": "Wayville",
                "countryCode": "AU"
            },
            {
                "name": "West Beach",
                "countryCode": "AU"
            },
            {
                "name": "West Croydon",
                "countryCode": "AU"
            },
            {
                "name": "West Hindmarsh",
                "countryCode": "AU"
            },
            {
                "name": "West Lakes",
                "countryCode": "AU"
            },
            {
                "name": "West Lakes Shore",
                "countryCode": "AU"
            },
            {
                "name": "Westbourne Park",
                "countryCode": "AU"
            },
            {
                "name": "Whyalla",
                "countryCode": "AU"
            },
            {
                "name": "Whyalla Jenkins",
                "countryCode": "AU"
            },
            {
                "name": "Whyalla Norrie",
                "countryCode": "AU"
            },
            {
                "name": "Whyalla Playford",
                "countryCode": "AU"
            },
            {
                "name": "Whyalla Stuart",
                "countryCode": "AU"
            },
            {
                "name": "Willaston",
                "countryCode": "AU"
            },
            {
                "name": "Williamstown",
                "countryCode": "AU"
            },
            {
                "name": "Willunga",
                "countryCode": "AU"
            },
            {
                "name": "Windsor Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Woodcroft",
                "countryCode": "AU"
            },
            {
                "name": "Woodside",
                "countryCode": "AU"
            },
            {
                "name": "Woodville",
                "countryCode": "AU"
            },
            {
                "name": "Woodville Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Woodville North",
                "countryCode": "AU"
            },
            {
                "name": "Woodville Park",
                "countryCode": "AU"
            },
            {
                "name": "Woodville South",
                "countryCode": "AU"
            },
            {
                "name": "Woodville West",
                "countryCode": "AU"
            },
            {
                "name": "Wudinna",
                "countryCode": "AU"
            },
            {
                "name": "Wynn Vale",
                "countryCode": "AU"
            },
            {
                "name": "Yankalilla",
                "countryCode": "AU"
            },
            {
                "name": "Yorke Peninsula",
                "countryCode": "AU"
            },
            {
                "name": "Acton Park",
                "countryCode": "AU"
            },
            {
                "name": "Austins Ferry",
                "countryCode": "AU"
            },
            {
                "name": "Bagdad",
                "countryCode": "AU"
            },
            {
                "name": "Battery Point",
                "countryCode": "AU"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "AU"
            },
            {
                "name": "Beauty Point",
                "countryCode": "AU"
            },
            {
                "name": "Bellerive",
                "countryCode": "AU"
            },
            {
                "name": "Berriedale",
                "countryCode": "AU"
            },
            {
                "name": "Blackmans Bay",
                "countryCode": "AU"
            },
            {
                "name": "Blackstone Heights",
                "countryCode": "AU"
            },
            {
                "name": "Break O'Day",
                "countryCode": "AU"
            },
            {
                "name": "Bridgewater",
                "countryCode": "AU"
            },
            {
                "name": "Bridport",
                "countryCode": "AU"
            },
            {
                "name": "Brighton",
                "countryCode": "AU"
            },
            {
                "name": "Burnie",
                "countryCode": "AU"
            },
            {
                "name": "Cambridge",
                "countryCode": "AU"
            },
            {
                "name": "Central Coast",
                "countryCode": "AU"
            },
            {
                "name": "Central Highlands",
                "countryCode": "AU"
            },
            {
                "name": "Chigwell",
                "countryCode": "AU"
            },
            {
                "name": "Circular Head",
                "countryCode": "AU"
            },
            {
                "name": "Claremont",
                "countryCode": "AU"
            },
            {
                "name": "Clarence",
                "countryCode": "AU"
            },
            {
                "name": "Clarendon Vale",
                "countryCode": "AU"
            },
            {
                "name": "Cressy",
                "countryCode": "AU"
            },
            {
                "name": "Currie",
                "countryCode": "AU"
            },
            {
                "name": "Cygnet",
                "countryCode": "AU"
            },
            {
                "name": "Deloraine",
                "countryCode": "AU"
            },
            {
                "name": "Derwent Valley",
                "countryCode": "AU"
            },
            {
                "name": "Devonport",
                "countryCode": "AU"
            },
            {
                "name": "Dodges Ferry",
                "countryCode": "AU"
            },
            {
                "name": "Dorset",
                "countryCode": "AU"
            },
            {
                "name": "Dynnyrne",
                "countryCode": "AU"
            },
            {
                "name": "East Devonport",
                "countryCode": "AU"
            },
            {
                "name": "East Launceston",
                "countryCode": "AU"
            },
            {
                "name": "Evandale",
                "countryCode": "AU"
            },
            {
                "name": "Flinders",
                "countryCode": "AU"
            },
            {
                "name": "Franklin",
                "countryCode": "AU"
            },
            {
                "name": "Gagebrook",
                "countryCode": "AU"
            },
            {
                "name": "Geeveston",
                "countryCode": "AU"
            },
            {
                "name": "Geilston Bay",
                "countryCode": "AU"
            },
            {
                "name": "George Town",
                "countryCode": "AU"
            },
            {
                "name": "Glamorgan/Spring Bay",
                "countryCode": "AU"
            },
            {
                "name": "Glenorchy",
                "countryCode": "AU"
            },
            {
                "name": "Goodwood",
                "countryCode": "AU"
            },
            {
                "name": "Granton",
                "countryCode": "AU"
            },
            {
                "name": "Hadspen",
                "countryCode": "AU"
            },
            {
                "name": "Herdsmans Cove",
                "countryCode": "AU"
            },
            {
                "name": "Hillcrest",
                "countryCode": "AU"
            },
            {
                "name": "Hobart",
                "countryCode": "AU"
            },
            {
                "name": "Hobart city centre",
                "countryCode": "AU"
            },
            {
                "name": "Howrah",
                "countryCode": "AU"
            },
            {
                "name": "Huon Valley",
                "countryCode": "AU"
            },
            {
                "name": "Huonville",
                "countryCode": "AU"
            },
            {
                "name": "Invermay",
                "countryCode": "AU"
            },
            {
                "name": "Kentish",
                "countryCode": "AU"
            },
            {
                "name": "King Island",
                "countryCode": "AU"
            },
            {
                "name": "Kingborough",
                "countryCode": "AU"
            },
            {
                "name": "Kings Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Kingston",
                "countryCode": "AU"
            },
            {
                "name": "Kingston Beach",
                "countryCode": "AU"
            },
            {
                "name": "Latrobe",
                "countryCode": "AU"
            },
            {
                "name": "Lauderdale",
                "countryCode": "AU"
            },
            {
                "name": "Launceston",
                "countryCode": "AU"
            },
            {
                "name": "Launceston city centre",
                "countryCode": "AU"
            },
            {
                "name": "Legana",
                "countryCode": "AU"
            },
            {
                "name": "Lenah Valley",
                "countryCode": "AU"
            },
            {
                "name": "Lindisfarne",
                "countryCode": "AU"
            },
            {
                "name": "Longford",
                "countryCode": "AU"
            },
            {
                "name": "Lutana",
                "countryCode": "AU"
            },
            {
                "name": "Margate",
                "countryCode": "AU"
            },
            {
                "name": "Mayfield",
                "countryCode": "AU"
            },
            {
                "name": "Meander Valley",
                "countryCode": "AU"
            },
            {
                "name": "Miandetta",
                "countryCode": "AU"
            },
            {
                "name": "Midway Point",
                "countryCode": "AU"
            },
            {
                "name": "Montello",
                "countryCode": "AU"
            },
            {
                "name": "Montrose",
                "countryCode": "AU"
            },
            {
                "name": "Moonah",
                "countryCode": "AU"
            },
            {
                "name": "Mornington",
                "countryCode": "AU"
            },
            {
                "name": "Mount Nelson",
                "countryCode": "AU"
            },
            {
                "name": "Mount Stuart",
                "countryCode": "AU"
            },
            {
                "name": "Mowbray",
                "countryCode": "AU"
            },
            {
                "name": "New Norfolk",
                "countryCode": "AU"
            },
            {
                "name": "New Town",
                "countryCode": "AU"
            },
            {
                "name": "Newnham",
                "countryCode": "AU"
            },
            {
                "name": "Newstead",
                "countryCode": "AU"
            },
            {
                "name": "North Hobart",
                "countryCode": "AU"
            },
            {
                "name": "Northern Midlands",
                "countryCode": "AU"
            },
            {
                "name": "Norwood",
                "countryCode": "AU"
            },
            {
                "name": "Oakdowns",
                "countryCode": "AU"
            },
            {
                "name": "Old Beach",
                "countryCode": "AU"
            },
            {
                "name": "Park Grove",
                "countryCode": "AU"
            },
            {
                "name": "Penguin",
                "countryCode": "AU"
            },
            {
                "name": "Perth",
                "countryCode": "AU"
            },
            {
                "name": "Port Sorell",
                "countryCode": "AU"
            },
            {
                "name": "Prospect Vale",
                "countryCode": "AU"
            },
            {
                "name": "Queenstown",
                "countryCode": "AU"
            },
            {
                "name": "Ranelagh",
                "countryCode": "AU"
            },
            {
                "name": "Ravenswood",
                "countryCode": "AU"
            },
            {
                "name": "Richmond",
                "countryCode": "AU"
            },
            {
                "name": "Risdon Vale",
                "countryCode": "AU"
            },
            {
                "name": "Riverside",
                "countryCode": "AU"
            },
            {
                "name": "Rocherlea",
                "countryCode": "AU"
            },
            {
                "name": "Rokeby",
                "countryCode": "AU"
            },
            {
                "name": "Romaine",
                "countryCode": "AU"
            },
            {
                "name": "Rosetta",
                "countryCode": "AU"
            },
            {
                "name": "Saint Leonards",
                "countryCode": "AU"
            },
            {
                "name": "Sandford",
                "countryCode": "AU"
            },
            {
                "name": "Sandy Bay",
                "countryCode": "AU"
            },
            {
                "name": "Scottsdale",
                "countryCode": "AU"
            },
            {
                "name": "Seven Mile Beach",
                "countryCode": "AU"
            },
            {
                "name": "Shearwater",
                "countryCode": "AU"
            },
            {
                "name": "Sheffield",
                "countryCode": "AU"
            },
            {
                "name": "Shorewell Park",
                "countryCode": "AU"
            },
            {
                "name": "Smithton",
                "countryCode": "AU"
            },
            {
                "name": "Snug",
                "countryCode": "AU"
            },
            {
                "name": "Somerset",
                "countryCode": "AU"
            },
            {
                "name": "Sorell",
                "countryCode": "AU"
            },
            {
                "name": "South Hobart",
                "countryCode": "AU"
            },
            {
                "name": "South Launceston",
                "countryCode": "AU"
            },
            {
                "name": "Southern Midlands",
                "countryCode": "AU"
            },
            {
                "name": "Spreyton",
                "countryCode": "AU"
            },
            {
                "name": "St Helens",
                "countryCode": "AU"
            },
            {
                "name": "Summerhill",
                "countryCode": "AU"
            },
            {
                "name": "Taroona",
                "countryCode": "AU"
            },
            {
                "name": "Tasman Peninsula",
                "countryCode": "AU"
            },
            {
                "name": "Tranmere",
                "countryCode": "AU"
            },
            {
                "name": "Trevallyn",
                "countryCode": "AU"
            },
            {
                "name": "Turners Beach",
                "countryCode": "AU"
            },
            {
                "name": "Ulverstone",
                "countryCode": "AU"
            },
            {
                "name": "Upper Burnie",
                "countryCode": "AU"
            },
            {
                "name": "Waratah/Wynyard",
                "countryCode": "AU"
            },
            {
                "name": "Warrane",
                "countryCode": "AU"
            },
            {
                "name": "Waverley",
                "countryCode": "AU"
            },
            {
                "name": "West Coast",
                "countryCode": "AU"
            },
            {
                "name": "West Hobart",
                "countryCode": "AU"
            },
            {
                "name": "West Launceston",
                "countryCode": "AU"
            },
            {
                "name": "West Moonah",
                "countryCode": "AU"
            },
            {
                "name": "West Tamar",
                "countryCode": "AU"
            },
            {
                "name": "West Ulverstone",
                "countryCode": "AU"
            },
            {
                "name": "Westbury",
                "countryCode": "AU"
            },
            {
                "name": "Wynyard",
                "countryCode": "AU"
            },
            {
                "name": "Youngtown",
                "countryCode": "AU"
            },
            {
                "name": "Abbotsford",
                "countryCode": "AU"
            },
            {
                "name": "Aberfeldie",
                "countryCode": "AU"
            },
            {
                "name": "Airport West",
                "countryCode": "AU"
            },
            {
                "name": "Albanvale",
                "countryCode": "AU"
            },
            {
                "name": "Albert Park",
                "countryCode": "AU"
            },
            {
                "name": "Albion",
                "countryCode": "AU"
            },
            {
                "name": "Alexandra",
                "countryCode": "AU"
            },
            {
                "name": "Alfredton",
                "countryCode": "AU"
            },
            {
                "name": "Allansford",
                "countryCode": "AU"
            },
            {
                "name": "Alphington",
                "countryCode": "AU"
            },
            {
                "name": "Alpine",
                "countryCode": "AU"
            },
            {
                "name": "Altona",
                "countryCode": "AU"
            },
            {
                "name": "Altona Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Altona North",
                "countryCode": "AU"
            },
            {
                "name": "Anglesea",
                "countryCode": "AU"
            },
            {
                "name": "Apollo Bay",
                "countryCode": "AU"
            },
            {
                "name": "Ararat",
                "countryCode": "AU"
            },
            {
                "name": "Ardeer",
                "countryCode": "AU"
            },
            {
                "name": "Armadale",
                "countryCode": "AU"
            },
            {
                "name": "Armstrong Creek",
                "countryCode": "AU"
            },
            {
                "name": "Ascot",
                "countryCode": "AU"
            },
            {
                "name": "Ascot Vale",
                "countryCode": "AU"
            },
            {
                "name": "Ashburton",
                "countryCode": "AU"
            },
            {
                "name": "Ashwood",
                "countryCode": "AU"
            },
            {
                "name": "Aspendale",
                "countryCode": "AU"
            },
            {
                "name": "Aspendale Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Attwood",
                "countryCode": "AU"
            },
            {
                "name": "Avenel",
                "countryCode": "AU"
            },
            {
                "name": "Avoca",
                "countryCode": "AU"
            },
            {
                "name": "Avondale Heights",
                "countryCode": "AU"
            },
            {
                "name": "Bacchus Marsh",
                "countryCode": "AU"
            },
            {
                "name": "Badger Creek",
                "countryCode": "AU"
            },
            {
                "name": "Bairnsdale",
                "countryCode": "AU"
            },
            {
                "name": "Balaclava",
                "countryCode": "AU"
            },
            {
                "name": "Ballan",
                "countryCode": "AU"
            },
            {
                "name": "Ballarat",
                "countryCode": "AU"
            },
            {
                "name": "Ballarat Central",
                "countryCode": "AU"
            },
            {
                "name": "Ballarat East",
                "countryCode": "AU"
            },
            {
                "name": "Ballarat North",
                "countryCode": "AU"
            },
            {
                "name": "Balnarring",
                "countryCode": "AU"
            },
            {
                "name": "Balwyn",
                "countryCode": "AU"
            },
            {
                "name": "Balwyn North",
                "countryCode": "AU"
            },
            {
                "name": "Bannockburn",
                "countryCode": "AU"
            },
            {
                "name": "Banyule",
                "countryCode": "AU"
            },
            {
                "name": "Baranduda",
                "countryCode": "AU"
            },
            {
                "name": "Barwon Heads",
                "countryCode": "AU"
            },
            {
                "name": "Bass Coast",
                "countryCode": "AU"
            },
            {
                "name": "Baw Baw",
                "countryCode": "AU"
            },
            {
                "name": "Baxter",
                "countryCode": "AU"
            },
            {
                "name": "Bayside",
                "countryCode": "AU"
            },
            {
                "name": "Bayswater",
                "countryCode": "AU"
            },
            {
                "name": "Bayswater North",
                "countryCode": "AU"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "AU"
            },
            {
                "name": "Beaconsfield Upper",
                "countryCode": "AU"
            },
            {
                "name": "Beaufort",
                "countryCode": "AU"
            },
            {
                "name": "Beaumaris",
                "countryCode": "AU"
            },
            {
                "name": "Beechworth",
                "countryCode": "AU"
            },
            {
                "name": "Belgrave",
                "countryCode": "AU"
            },
            {
                "name": "Belgrave Heights",
                "countryCode": "AU"
            },
            {
                "name": "Belgrave South",
                "countryCode": "AU"
            },
            {
                "name": "Bell Park",
                "countryCode": "AU"
            },
            {
                "name": "Bell Post Hill",
                "countryCode": "AU"
            },
            {
                "name": "Bellfield",
                "countryCode": "AU"
            },
            {
                "name": "Belmont",
                "countryCode": "AU"
            },
            {
                "name": "Benalla",
                "countryCode": "AU"
            },
            {
                "name": "Bendigo",
                "countryCode": "AU"
            },
            {
                "name": "Bendigo city centre",
                "countryCode": "AU"
            },
            {
                "name": "Bentleigh",
                "countryCode": "AU"
            },
            {
                "name": "Bentleigh East",
                "countryCode": "AU"
            },
            {
                "name": "Berwick",
                "countryCode": "AU"
            },
            {
                "name": "Beveridge",
                "countryCode": "AU"
            },
            {
                "name": "Bittern",
                "countryCode": "AU"
            },
            {
                "name": "Black Hill",
                "countryCode": "AU"
            },
            {
                "name": "Black Rock",
                "countryCode": "AU"
            },
            {
                "name": "Blackburn",
                "countryCode": "AU"
            },
            {
                "name": "Blackburn North",
                "countryCode": "AU"
            },
            {
                "name": "Blackburn South",
                "countryCode": "AU"
            },
            {
                "name": "Blairgowrie",
                "countryCode": "AU"
            },
            {
                "name": "Blind Bight",
                "countryCode": "AU"
            },
            {
                "name": "Bonbeach",
                "countryCode": "AU"
            },
            {
                "name": "Boronia",
                "countryCode": "AU"
            },
            {
                "name": "Boroondara",
                "countryCode": "AU"
            },
            {
                "name": "Botanic Ridge",
                "countryCode": "AU"
            },
            {
                "name": "Box Hill",
                "countryCode": "AU"
            },
            {
                "name": "Box Hill North",
                "countryCode": "AU"
            },
            {
                "name": "Box Hill South",
                "countryCode": "AU"
            },
            {
                "name": "Braybrook",
                "countryCode": "AU"
            },
            {
                "name": "Briagolong",
                "countryCode": "AU"
            },
            {
                "name": "Briar Hill",
                "countryCode": "AU"
            },
            {
                "name": "Bright",
                "countryCode": "AU"
            },
            {
                "name": "Brighton",
                "countryCode": "AU"
            },
            {
                "name": "Brighton East",
                "countryCode": "AU"
            },
            {
                "name": "Brimbank",
                "countryCode": "AU"
            },
            {
                "name": "Broadford",
                "countryCode": "AU"
            },
            {
                "name": "Broadmeadows",
                "countryCode": "AU"
            },
            {
                "name": "Brookfield",
                "countryCode": "AU"
            },
            {
                "name": "Brooklyn",
                "countryCode": "AU"
            },
            {
                "name": "Brown Hill",
                "countryCode": "AU"
            },
            {
                "name": "Brunswick",
                "countryCode": "AU"
            },
            {
                "name": "Brunswick East",
                "countryCode": "AU"
            },
            {
                "name": "Brunswick West",
                "countryCode": "AU"
            },
            {
                "name": "Bulleen",
                "countryCode": "AU"
            },
            {
                "name": "Buloke",
                "countryCode": "AU"
            },
            {
                "name": "Bundoora",
                "countryCode": "AU"
            },
            {
                "name": "Buninyong",
                "countryCode": "AU"
            },
            {
                "name": "Bunyip",
                "countryCode": "AU"
            },
            {
                "name": "Burnside",
                "countryCode": "AU"
            },
            {
                "name": "Burnside Heights",
                "countryCode": "AU"
            },
            {
                "name": "Burwood",
                "countryCode": "AU"
            },
            {
                "name": "Burwood East",
                "countryCode": "AU"
            },
            {
                "name": "Cairnlea",
                "countryCode": "AU"
            },
            {
                "name": "California Gully",
                "countryCode": "AU"
            },
            {
                "name": "Camberwell",
                "countryCode": "AU"
            },
            {
                "name": "Campaspe",
                "countryCode": "AU"
            },
            {
                "name": "Campbellfield",
                "countryCode": "AU"
            },
            {
                "name": "Campbells Creek",
                "countryCode": "AU"
            },
            {
                "name": "Camperdown",
                "countryCode": "AU"
            },
            {
                "name": "Canadian",
                "countryCode": "AU"
            },
            {
                "name": "Canterbury",
                "countryCode": "AU"
            },
            {
                "name": "Cape Woolamai",
                "countryCode": "AU"
            },
            {
                "name": "Cardinia",
                "countryCode": "AU"
            },
            {
                "name": "Carisbrook",
                "countryCode": "AU"
            },
            {
                "name": "Carlton",
                "countryCode": "AU"
            },
            {
                "name": "Carlton North",
                "countryCode": "AU"
            },
            {
                "name": "Carnegie",
                "countryCode": "AU"
            },
            {
                "name": "Caroline Springs",
                "countryCode": "AU"
            },
            {
                "name": "Carrum",
                "countryCode": "AU"
            },
            {
                "name": "Carrum Downs",
                "countryCode": "AU"
            },
            {
                "name": "Casey",
                "countryCode": "AU"
            },
            {
                "name": "Casterton",
                "countryCode": "AU"
            },
            {
                "name": "Castlemaine",
                "countryCode": "AU"
            },
            {
                "name": "Caulfield",
                "countryCode": "AU"
            },
            {
                "name": "Caulfield East",
                "countryCode": "AU"
            },
            {
                "name": "Caulfield North",
                "countryCode": "AU"
            },
            {
                "name": "Caulfield South",
                "countryCode": "AU"
            },
            {
                "name": "Central Goldfields",
                "countryCode": "AU"
            },
            {
                "name": "Chadstone",
                "countryCode": "AU"
            },
            {
                "name": "Charlton",
                "countryCode": "AU"
            },
            {
                "name": "Chelsea",
                "countryCode": "AU"
            },
            {
                "name": "Chelsea Heights",
                "countryCode": "AU"
            },
            {
                "name": "Cheltenham",
                "countryCode": "AU"
            },
            {
                "name": "Chewton",
                "countryCode": "AU"
            },
            {
                "name": "Chiltern",
                "countryCode": "AU"
            },
            {
                "name": "Chirnside Park",
                "countryCode": "AU"
            },
            {
                "name": "Churchill",
                "countryCode": "AU"
            },
            {
                "name": "Clarinda",
                "countryCode": "AU"
            },
            {
                "name": "Clayton",
                "countryCode": "AU"
            },
            {
                "name": "Clayton South",
                "countryCode": "AU"
            },
            {
                "name": "Clifton Hill",
                "countryCode": "AU"
            },
            {
                "name": "Clifton Springs",
                "countryCode": "AU"
            },
            {
                "name": "Clunes",
                "countryCode": "AU"
            },
            {
                "name": "Clyde",
                "countryCode": "AU"
            },
            {
                "name": "Clyde North",
                "countryCode": "AU"
            },
            {
                "name": "Cobden",
                "countryCode": "AU"
            },
            {
                "name": "Cobram",
                "countryCode": "AU"
            },
            {
                "name": "Coburg",
                "countryCode": "AU"
            },
            {
                "name": "Coburg North",
                "countryCode": "AU"
            },
            {
                "name": "Cockatoo",
                "countryCode": "AU"
            },
            {
                "name": "Cohuna",
                "countryCode": "AU"
            },
            {
                "name": "Colac",
                "countryCode": "AU"
            },
            {
                "name": "Colac-Otway",
                "countryCode": "AU"
            },
            {
                "name": "Coldstream",
                "countryCode": "AU"
            },
            {
                "name": "Collingwood",
                "countryCode": "AU"
            },
            {
                "name": "Coolaroo",
                "countryCode": "AU"
            },
            {
                "name": "Corangamite",
                "countryCode": "AU"
            },
            {
                "name": "Corio",
                "countryCode": "AU"
            },
            {
                "name": "Corryong",
                "countryCode": "AU"
            },
            {
                "name": "Cowes",
                "countryCode": "AU"
            },
            {
                "name": "Craigieburn",
                "countryCode": "AU"
            },
            {
                "name": "Cranbourne",
                "countryCode": "AU"
            },
            {
                "name": "Cranbourne East",
                "countryCode": "AU"
            },
            {
                "name": "Cranbourne North",
                "countryCode": "AU"
            },
            {
                "name": "Cranbourne South",
                "countryCode": "AU"
            },
            {
                "name": "Cranbourne West",
                "countryCode": "AU"
            },
            {
                "name": "Cremorne",
                "countryCode": "AU"
            },
            {
                "name": "Creswick",
                "countryCode": "AU"
            },
            {
                "name": "Crib Point",
                "countryCode": "AU"
            },
            {
                "name": "Croydon",
                "countryCode": "AU"
            },
            {
                "name": "Croydon Hills",
                "countryCode": "AU"
            },
            {
                "name": "Croydon North",
                "countryCode": "AU"
            },
            {
                "name": "Croydon South",
                "countryCode": "AU"
            },
            {
                "name": "Dallas",
                "countryCode": "AU"
            },
            {
                "name": "Dandenong",
                "countryCode": "AU"
            },
            {
                "name": "Dandenong North",
                "countryCode": "AU"
            },
            {
                "name": "Darebin",
                "countryCode": "AU"
            },
            {
                "name": "Darley",
                "countryCode": "AU"
            },
            {
                "name": "Daylesford",
                "countryCode": "AU"
            },
            {
                "name": "Deer Park",
                "countryCode": "AU"
            },
            {
                "name": "Delacombe",
                "countryCode": "AU"
            },
            {
                "name": "Delahey",
                "countryCode": "AU"
            },
            {
                "name": "Dennington",
                "countryCode": "AU"
            },
            {
                "name": "Derrimut",
                "countryCode": "AU"
            },
            {
                "name": "Devon Meadows",
                "countryCode": "AU"
            },
            {
                "name": "Diamond Creek",
                "countryCode": "AU"
            },
            {
                "name": "Diggers Rest",
                "countryCode": "AU"
            },
            {
                "name": "Dimboola",
                "countryCode": "AU"
            },
            {
                "name": "Dingley Village",
                "countryCode": "AU"
            },
            {
                "name": "Dinner Plain",
                "countryCode": "AU"
            },
            {
                "name": "Docklands",
                "countryCode": "AU"
            },
            {
                "name": "Donald",
                "countryCode": "AU"
            },
            {
                "name": "Doncaster",
                "countryCode": "AU"
            },
            {
                "name": "Doncaster East",
                "countryCode": "AU"
            },
            {
                "name": "Donvale",
                "countryCode": "AU"
            },
            {
                "name": "Doreen",
                "countryCode": "AU"
            },
            {
                "name": "Doveton",
                "countryCode": "AU"
            },
            {
                "name": "Dromana",
                "countryCode": "AU"
            },
            {
                "name": "Drouin",
                "countryCode": "AU"
            },
            {
                "name": "Drysdale",
                "countryCode": "AU"
            },
            {
                "name": "Eagle Point",
                "countryCode": "AU"
            },
            {
                "name": "Eaglehawk",
                "countryCode": "AU"
            },
            {
                "name": "Eaglemont",
                "countryCode": "AU"
            },
            {
                "name": "East Bairnsdale",
                "countryCode": "AU"
            },
            {
                "name": "East Bendigo",
                "countryCode": "AU"
            },
            {
                "name": "East Geelong",
                "countryCode": "AU"
            },
            {
                "name": "East Gippsland",
                "countryCode": "AU"
            },
            {
                "name": "East Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "Echuca",
                "countryCode": "AU"
            },
            {
                "name": "Eden Park",
                "countryCode": "AU"
            },
            {
                "name": "Edithvale",
                "countryCode": "AU"
            },
            {
                "name": "Elliminyt",
                "countryCode": "AU"
            },
            {
                "name": "Elsternwick",
                "countryCode": "AU"
            },
            {
                "name": "Eltham",
                "countryCode": "AU"
            },
            {
                "name": "Eltham North",
                "countryCode": "AU"
            },
            {
                "name": "Elwood",
                "countryCode": "AU"
            },
            {
                "name": "Emerald",
                "countryCode": "AU"
            },
            {
                "name": "Endeavour Hills",
                "countryCode": "AU"
            },
            {
                "name": "Epping",
                "countryCode": "AU"
            },
            {
                "name": "Epsom",
                "countryCode": "AU"
            },
            {
                "name": "Essendon",
                "countryCode": "AU"
            },
            {
                "name": "Essendon North",
                "countryCode": "AU"
            },
            {
                "name": "Essendon West",
                "countryCode": "AU"
            },
            {
                "name": "Eumemmerring",
                "countryCode": "AU"
            },
            {
                "name": "Euroa",
                "countryCode": "AU"
            },
            {
                "name": "Eynesbury",
                "countryCode": "AU"
            },
            {
                "name": "Fairfield",
                "countryCode": "AU"
            },
            {
                "name": "Falls Creek",
                "countryCode": "AU"
            },
            {
                "name": "Fawkner",
                "countryCode": "AU"
            },
            {
                "name": "Ferntree Gully",
                "countryCode": "AU"
            },
            {
                "name": "Ferny Creek",
                "countryCode": "AU"
            },
            {
                "name": "Fitzroy",
                "countryCode": "AU"
            },
            {
                "name": "Fitzroy North",
                "countryCode": "AU"
            },
            {
                "name": "Flemington",
                "countryCode": "AU"
            },
            {
                "name": "Flora Hill",
                "countryCode": "AU"
            },
            {
                "name": "Footscray",
                "countryCode": "AU"
            },
            {
                "name": "Forest Hill",
                "countryCode": "AU"
            },
            {
                "name": "Foster",
                "countryCode": "AU"
            },
            {
                "name": "Frankston",
                "countryCode": "AU"
            },
            {
                "name": "Frankston East",
                "countryCode": "AU"
            },
            {
                "name": "Frankston North",
                "countryCode": "AU"
            },
            {
                "name": "Frankston South",
                "countryCode": "AU"
            },
            {
                "name": "Gannawarra",
                "countryCode": "AU"
            },
            {
                "name": "Garfield",
                "countryCode": "AU"
            },
            {
                "name": "Geelong",
                "countryCode": "AU"
            },
            {
                "name": "Geelong West",
                "countryCode": "AU"
            },
            {
                "name": "Geelong city centre",
                "countryCode": "AU"
            },
            {
                "name": "Gembrook",
                "countryCode": "AU"
            },
            {
                "name": "Gisborne",
                "countryCode": "AU"
            },
            {
                "name": "Gladstone Park",
                "countryCode": "AU"
            },
            {
                "name": "Glen Eira",
                "countryCode": "AU"
            },
            {
                "name": "Glen Huntly",
                "countryCode": "AU"
            },
            {
                "name": "Glen Iris",
                "countryCode": "AU"
            },
            {
                "name": "Glen Waverley",
                "countryCode": "AU"
            },
            {
                "name": "Glenelg",
                "countryCode": "AU"
            },
            {
                "name": "Glenferrie",
                "countryCode": "AU"
            },
            {
                "name": "Glengarry",
                "countryCode": "AU"
            },
            {
                "name": "Glenroy",
                "countryCode": "AU"
            },
            {
                "name": "Golden Plains",
                "countryCode": "AU"
            },
            {
                "name": "Golden Point",
                "countryCode": "AU"
            },
            {
                "name": "Golden Square",
                "countryCode": "AU"
            },
            {
                "name": "Gordon",
                "countryCode": "AU"
            },
            {
                "name": "Gowanbrae",
                "countryCode": "AU"
            },
            {
                "name": "Greater Bendigo",
                "countryCode": "AU"
            },
            {
                "name": "Greater Dandenong",
                "countryCode": "AU"
            },
            {
                "name": "Greater Geelong",
                "countryCode": "AU"
            },
            {
                "name": "Greater Shepparton",
                "countryCode": "AU"
            },
            {
                "name": "Greensborough",
                "countryCode": "AU"
            },
            {
                "name": "Greenvale",
                "countryCode": "AU"
            },
            {
                "name": "Grovedale",
                "countryCode": "AU"
            },
            {
                "name": "Haddon",
                "countryCode": "AU"
            },
            {
                "name": "Hadfield",
                "countryCode": "AU"
            },
            {
                "name": "Hallam",
                "countryCode": "AU"
            },
            {
                "name": "Hamilton",
                "countryCode": "AU"
            },
            {
                "name": "Hamlyn Heights",
                "countryCode": "AU"
            },
            {
                "name": "Hampton",
                "countryCode": "AU"
            },
            {
                "name": "Hampton East",
                "countryCode": "AU"
            },
            {
                "name": "Hampton Park",
                "countryCode": "AU"
            },
            {
                "name": "Hastings",
                "countryCode": "AU"
            },
            {
                "name": "Haven",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorn",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorn East",
                "countryCode": "AU"
            },
            {
                "name": "Hawthorn South",
                "countryCode": "AU"
            },
            {
                "name": "Hazelwood North",
                "countryCode": "AU"
            },
            {
                "name": "Healesville",
                "countryCode": "AU"
            },
            {
                "name": "Heathcote",
                "countryCode": "AU"
            },
            {
                "name": "Heatherton",
                "countryCode": "AU"
            },
            {
                "name": "Heathmont",
                "countryCode": "AU"
            },
            {
                "name": "Heidelberg",
                "countryCode": "AU"
            },
            {
                "name": "Heidelberg Heights",
                "countryCode": "AU"
            },
            {
                "name": "Heidelberg West",
                "countryCode": "AU"
            },
            {
                "name": "Hepburn",
                "countryCode": "AU"
            },
            {
                "name": "Herne Hill",
                "countryCode": "AU"
            },
            {
                "name": "Heyfield",
                "countryCode": "AU"
            },
            {
                "name": "Heywood",
                "countryCode": "AU"
            },
            {
                "name": "Highett",
                "countryCode": "AU"
            },
            {
                "name": "Highton",
                "countryCode": "AU"
            },
            {
                "name": "Hillside",
                "countryCode": "AU"
            },
            {
                "name": "Hindmarsh",
                "countryCode": "AU"
            },
            {
                "name": "Hmas Cerberus",
                "countryCode": "AU"
            },
            {
                "name": "Hobsons Bay",
                "countryCode": "AU"
            },
            {
                "name": "Hoppers Crossing",
                "countryCode": "AU"
            },
            {
                "name": "Horsham",
                "countryCode": "AU"
            },
            {
                "name": "Hotham Heights",
                "countryCode": "AU"
            },
            {
                "name": "Hughesdale",
                "countryCode": "AU"
            },
            {
                "name": "Hume",
                "countryCode": "AU"
            },
            {
                "name": "Huntingdale",
                "countryCode": "AU"
            },
            {
                "name": "Huntly",
                "countryCode": "AU"
            },
            {
                "name": "Hurstbridge",
                "countryCode": "AU"
            },
            {
                "name": "Indented Head",
                "countryCode": "AU"
            },
            {
                "name": "Indigo",
                "countryCode": "AU"
            },
            {
                "name": "Inverleigh",
                "countryCode": "AU"
            },
            {
                "name": "Inverloch",
                "countryCode": "AU"
            },
            {
                "name": "Invermay Park",
                "countryCode": "AU"
            },
            {
                "name": "Ironbark",
                "countryCode": "AU"
            },
            {
                "name": "Irymple",
                "countryCode": "AU"
            },
            {
                "name": "Ivanhoe",
                "countryCode": "AU"
            },
            {
                "name": "Ivanhoe East",
                "countryCode": "AU"
            },
            {
                "name": "Jacana",
                "countryCode": "AU"
            },
            {
                "name": "Jackass Flat",
                "countryCode": "AU"
            },
            {
                "name": "Jan Juc",
                "countryCode": "AU"
            },
            {
                "name": "Junction Village",
                "countryCode": "AU"
            },
            {
                "name": "Junortoun",
                "countryCode": "AU"
            },
            {
                "name": "Kalimna",
                "countryCode": "AU"
            },
            {
                "name": "Kallista",
                "countryCode": "AU"
            },
            {
                "name": "Kalorama",
                "countryCode": "AU"
            },
            {
                "name": "Kangaroo Flat",
                "countryCode": "AU"
            },
            {
                "name": "Kangaroo Ground",
                "countryCode": "AU"
            },
            {
                "name": "Kealba",
                "countryCode": "AU"
            },
            {
                "name": "Keilor",
                "countryCode": "AU"
            },
            {
                "name": "Keilor Downs",
                "countryCode": "AU"
            },
            {
                "name": "Keilor East",
                "countryCode": "AU"
            },
            {
                "name": "Keilor Lodge",
                "countryCode": "AU"
            },
            {
                "name": "Keilor Park",
                "countryCode": "AU"
            },
            {
                "name": "Kennington",
                "countryCode": "AU"
            },
            {
                "name": "Kensington",
                "countryCode": "AU"
            },
            {
                "name": "Kerang",
                "countryCode": "AU"
            },
            {
                "name": "Kew",
                "countryCode": "AU"
            },
            {
                "name": "Kew East",
                "countryCode": "AU"
            },
            {
                "name": "Keysborough",
                "countryCode": "AU"
            },
            {
                "name": "Kialla",
                "countryCode": "AU"
            },
            {
                "name": "Kilmore",
                "countryCode": "AU"
            },
            {
                "name": "Kilsyth",
                "countryCode": "AU"
            },
            {
                "name": "Kilsyth South",
                "countryCode": "AU"
            },
            {
                "name": "Kinglake",
                "countryCode": "AU"
            },
            {
                "name": "Kinglake West",
                "countryCode": "AU"
            },
            {
                "name": "Kings Park",
                "countryCode": "AU"
            },
            {
                "name": "Kingsbury",
                "countryCode": "AU"
            },
            {
                "name": "Kingston",
                "countryCode": "AU"
            },
            {
                "name": "Kingsville",
                "countryCode": "AU"
            },
            {
                "name": "Knox",
                "countryCode": "AU"
            },
            {
                "name": "Knoxfield",
                "countryCode": "AU"
            },
            {
                "name": "Koo-Wee-Rup",
                "countryCode": "AU"
            },
            {
                "name": "Koroit",
                "countryCode": "AU"
            },
            {
                "name": "Korumburra",
                "countryCode": "AU"
            },
            {
                "name": "Kurunjang",
                "countryCode": "AU"
            },
            {
                "name": "Kyabram",
                "countryCode": "AU"
            },
            {
                "name": "Kyneton",
                "countryCode": "AU"
            },
            {
                "name": "Lake Gardens",
                "countryCode": "AU"
            },
            {
                "name": "Lake Wendouree",
                "countryCode": "AU"
            },
            {
                "name": "Lakes Entrance",
                "countryCode": "AU"
            },
            {
                "name": "Lalor",
                "countryCode": "AU"
            },
            {
                "name": "Lancefield",
                "countryCode": "AU"
            },
            {
                "name": "Lang Lang",
                "countryCode": "AU"
            },
            {
                "name": "Langwarrin",
                "countryCode": "AU"
            },
            {
                "name": "Langwarrin South",
                "countryCode": "AU"
            },
            {
                "name": "Lara",
                "countryCode": "AU"
            },
            {
                "name": "Latrobe",
                "countryCode": "AU"
            },
            {
                "name": "Launching Place",
                "countryCode": "AU"
            },
            {
                "name": "Laverton",
                "countryCode": "AU"
            },
            {
                "name": "Leongatha",
                "countryCode": "AU"
            },
            {
                "name": "Leopold",
                "countryCode": "AU"
            },
            {
                "name": "Lilydale",
                "countryCode": "AU"
            },
            {
                "name": "Little River",
                "countryCode": "AU"
            },
            {
                "name": "Loddon",
                "countryCode": "AU"
            },
            {
                "name": "Long Gully",
                "countryCode": "AU"
            },
            {
                "name": "Longford",
                "countryCode": "AU"
            },
            {
                "name": "Longwarry",
                "countryCode": "AU"
            },
            {
                "name": "Lorne",
                "countryCode": "AU"
            },
            {
                "name": "Lovely Banks",
                "countryCode": "AU"
            },
            {
                "name": "Lower Plenty",
                "countryCode": "AU"
            },
            {
                "name": "Lucknow",
                "countryCode": "AU"
            },
            {
                "name": "Lynbrook",
                "countryCode": "AU"
            },
            {
                "name": "Lysterfield",
                "countryCode": "AU"
            },
            {
                "name": "Macedon",
                "countryCode": "AU"
            },
            {
                "name": "Macedon Ranges",
                "countryCode": "AU"
            },
            {
                "name": "Macleod",
                "countryCode": "AU"
            },
            {
                "name": "Maddingley",
                "countryCode": "AU"
            },
            {
                "name": "Maffra",
                "countryCode": "AU"
            },
            {
                "name": "Maiden Gully",
                "countryCode": "AU"
            },
            {
                "name": "Maidstone",
                "countryCode": "AU"
            },
            {
                "name": "Maldon",
                "countryCode": "AU"
            },
            {
                "name": "Mallacoota",
                "countryCode": "AU"
            },
            {
                "name": "Malvern",
                "countryCode": "AU"
            },
            {
                "name": "Malvern East",
                "countryCode": "AU"
            },
            {
                "name": "Manifold Heights",
                "countryCode": "AU"
            },
            {
                "name": "Manningham",
                "countryCode": "AU"
            },
            {
                "name": "Mansfield",
                "countryCode": "AU"
            },
            {
                "name": "Maribyrnong",
                "countryCode": "AU"
            },
            {
                "name": "Marong",
                "countryCode": "AU"
            },
            {
                "name": "Maroondah",
                "countryCode": "AU"
            },
            {
                "name": "Maryborough",
                "countryCode": "AU"
            },
            {
                "name": "McCrae",
                "countryCode": "AU"
            },
            {
                "name": "McKinnon",
                "countryCode": "AU"
            },
            {
                "name": "Meadow Heights",
                "countryCode": "AU"
            },
            {
                "name": "Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "Melbourne City Centre",
                "countryCode": "AU"
            },
            {
                "name": "Melton",
                "countryCode": "AU"
            },
            {
                "name": "Melton South",
                "countryCode": "AU"
            },
            {
                "name": "Melton West",
                "countryCode": "AU"
            },
            {
                "name": "Mentone",
                "countryCode": "AU"
            },
            {
                "name": "Merbein",
                "countryCode": "AU"
            },
            {
                "name": "Mernda",
                "countryCode": "AU"
            },
            {
                "name": "Metung",
                "countryCode": "AU"
            },
            {
                "name": "Mickleham",
                "countryCode": "AU"
            },
            {
                "name": "Middle Park",
                "countryCode": "AU"
            },
            {
                "name": "Mildura",
                "countryCode": "AU"
            },
            {
                "name": "Mildura Shire",
                "countryCode": "AU"
            },
            {
                "name": "Mill Park",
                "countryCode": "AU"
            },
            {
                "name": "Millgrove",
                "countryCode": "AU"
            },
            {
                "name": "Miners Rest",
                "countryCode": "AU"
            },
            {
                "name": "Mirboo North",
                "countryCode": "AU"
            },
            {
                "name": "Mitcham",
                "countryCode": "AU"
            },
            {
                "name": "Mitchell",
                "countryCode": "AU"
            },
            {
                "name": "Moe",
                "countryCode": "AU"
            },
            {
                "name": "Moira",
                "countryCode": "AU"
            },
            {
                "name": "Monash",
                "countryCode": "AU"
            },
            {
                "name": "Monbulk",
                "countryCode": "AU"
            },
            {
                "name": "Mont Albert",
                "countryCode": "AU"
            },
            {
                "name": "Mont Albert North",
                "countryCode": "AU"
            },
            {
                "name": "Montmorency",
                "countryCode": "AU"
            },
            {
                "name": "Montrose",
                "countryCode": "AU"
            },
            {
                "name": "Moolap",
                "countryCode": "AU"
            },
            {
                "name": "Moonee Ponds",
                "countryCode": "AU"
            },
            {
                "name": "Moonee Valley",
                "countryCode": "AU"
            },
            {
                "name": "Moorabbin",
                "countryCode": "AU"
            },
            {
                "name": "Moorabool",
                "countryCode": "AU"
            },
            {
                "name": "Moorooduc",
                "countryCode": "AU"
            },
            {
                "name": "Mooroolbark",
                "countryCode": "AU"
            },
            {
                "name": "Mooroopna",
                "countryCode": "AU"
            },
            {
                "name": "Mordialloc",
                "countryCode": "AU"
            },
            {
                "name": "Moreland",
                "countryCode": "AU"
            },
            {
                "name": "Mornington",
                "countryCode": "AU"
            },
            {
                "name": "Mornington Peninsula",
                "countryCode": "AU"
            },
            {
                "name": "Mortlake",
                "countryCode": "AU"
            },
            {
                "name": "Morwell",
                "countryCode": "AU"
            },
            {
                "name": "Mount Alexander",
                "countryCode": "AU"
            },
            {
                "name": "Mount Buller",
                "countryCode": "AU"
            },
            {
                "name": "Mount Clear",
                "countryCode": "AU"
            },
            {
                "name": "Mount Dandenong",
                "countryCode": "AU"
            },
            {
                "name": "Mount Duneed",
                "countryCode": "AU"
            },
            {
                "name": "Mount Eliza",
                "countryCode": "AU"
            },
            {
                "name": "Mount Evelyn",
                "countryCode": "AU"
            },
            {
                "name": "Mount Helen",
                "countryCode": "AU"
            },
            {
                "name": "Mount Macedon",
                "countryCode": "AU"
            },
            {
                "name": "Mount Martha",
                "countryCode": "AU"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "AU"
            },
            {
                "name": "Mount Waverley",
                "countryCode": "AU"
            },
            {
                "name": "Moyne",
                "countryCode": "AU"
            },
            {
                "name": "Mulgrave",
                "countryCode": "AU"
            },
            {
                "name": "Murrindindi",
                "countryCode": "AU"
            },
            {
                "name": "Murrumbeena",
                "countryCode": "AU"
            },
            {
                "name": "Myrtleford",
                "countryCode": "AU"
            },
            {
                "name": "Nagambie",
                "countryCode": "AU"
            },
            {
                "name": "Narre Warren",
                "countryCode": "AU"
            },
            {
                "name": "Narre Warren North",
                "countryCode": "AU"
            },
            {
                "name": "Narre Warren South",
                "countryCode": "AU"
            },
            {
                "name": "Nathalia",
                "countryCode": "AU"
            },
            {
                "name": "Neerim South",
                "countryCode": "AU"
            },
            {
                "name": "New Gisborne",
                "countryCode": "AU"
            },
            {
                "name": "Newborough",
                "countryCode": "AU"
            },
            {
                "name": "Newcomb",
                "countryCode": "AU"
            },
            {
                "name": "Newington",
                "countryCode": "AU"
            },
            {
                "name": "Newport",
                "countryCode": "AU"
            },
            {
                "name": "Newtown",
                "countryCode": "AU"
            },
            {
                "name": "Nhill",
                "countryCode": "AU"
            },
            {
                "name": "Nichols Point",
                "countryCode": "AU"
            },
            {
                "name": "Nicholson",
                "countryCode": "AU"
            },
            {
                "name": "Niddrie",
                "countryCode": "AU"
            },
            {
                "name": "Nillumbik",
                "countryCode": "AU"
            },
            {
                "name": "Noble Park",
                "countryCode": "AU"
            },
            {
                "name": "Noble Park North",
                "countryCode": "AU"
            },
            {
                "name": "Norlane",
                "countryCode": "AU"
            },
            {
                "name": "North Bendigo",
                "countryCode": "AU"
            },
            {
                "name": "North Brighton",
                "countryCode": "AU"
            },
            {
                "name": "North Geelong",
                "countryCode": "AU"
            },
            {
                "name": "North Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "North Warrandyte",
                "countryCode": "AU"
            },
            {
                "name": "North Wonthaggi",
                "countryCode": "AU"
            },
            {
                "name": "Northcote",
                "countryCode": "AU"
            },
            {
                "name": "Northern Grampians",
                "countryCode": "AU"
            },
            {
                "name": "Notting Hill",
                "countryCode": "AU"
            },
            {
                "name": "Numurkah",
                "countryCode": "AU"
            },
            {
                "name": "Nunawading",
                "countryCode": "AU"
            },
            {
                "name": "Nyora",
                "countryCode": "AU"
            },
            {
                "name": "Oak Park",
                "countryCode": "AU"
            },
            {
                "name": "Oakleigh",
                "countryCode": "AU"
            },
            {
                "name": "Oakleigh East",
                "countryCode": "AU"
            },
            {
                "name": "Oakleigh South",
                "countryCode": "AU"
            },
            {
                "name": "Ocean Grove",
                "countryCode": "AU"
            },
            {
                "name": "Officer",
                "countryCode": "AU"
            },
            {
                "name": "Olinda",
                "countryCode": "AU"
            },
            {
                "name": "Orbost",
                "countryCode": "AU"
            },
            {
                "name": "Ormond",
                "countryCode": "AU"
            },
            {
                "name": "Ouyen",
                "countryCode": "AU"
            },
            {
                "name": "Pakenham",
                "countryCode": "AU"
            },
            {
                "name": "Pakenham Upper",
                "countryCode": "AU"
            },
            {
                "name": "Panton Hill",
                "countryCode": "AU"
            },
            {
                "name": "Park Orchards",
                "countryCode": "AU"
            },
            {
                "name": "Parkdale",
                "countryCode": "AU"
            },
            {
                "name": "Parkville",
                "countryCode": "AU"
            },
            {
                "name": "Pascoe Vale",
                "countryCode": "AU"
            },
            {
                "name": "Pascoe Vale South",
                "countryCode": "AU"
            },
            {
                "name": "Patterson Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Paynesville",
                "countryCode": "AU"
            },
            {
                "name": "Pearcedale",
                "countryCode": "AU"
            },
            {
                "name": "Phillip Island",
                "countryCode": "AU"
            },
            {
                "name": "Plenty",
                "countryCode": "AU"
            },
            {
                "name": "Plumpton",
                "countryCode": "AU"
            },
            {
                "name": "Point Cook",
                "countryCode": "AU"
            },
            {
                "name": "Point Lonsdale",
                "countryCode": "AU"
            },
            {
                "name": "Port Fairy",
                "countryCode": "AU"
            },
            {
                "name": "Port Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "Port Phillip",
                "countryCode": "AU"
            },
            {
                "name": "Portarlington",
                "countryCode": "AU"
            },
            {
                "name": "Portland",
                "countryCode": "AU"
            },
            {
                "name": "Prahran",
                "countryCode": "AU"
            },
            {
                "name": "Preston",
                "countryCode": "AU"
            },
            {
                "name": "Princes Hill",
                "countryCode": "AU"
            },
            {
                "name": "Puckapunyal",
                "countryCode": "AU"
            },
            {
                "name": "Pyrenees",
                "countryCode": "AU"
            },
            {
                "name": "Quarry Hill",
                "countryCode": "AU"
            },
            {
                "name": "Queenscliff",
                "countryCode": "AU"
            },
            {
                "name": "Queenscliffe",
                "countryCode": "AU"
            },
            {
                "name": "Ravenhall",
                "countryCode": "AU"
            },
            {
                "name": "Red Cliffs",
                "countryCode": "AU"
            },
            {
                "name": "Redan",
                "countryCode": "AU"
            },
            {
                "name": "Research",
                "countryCode": "AU"
            },
            {
                "name": "Reservoir",
                "countryCode": "AU"
            },
            {
                "name": "Richmond",
                "countryCode": "AU"
            },
            {
                "name": "Ringwood",
                "countryCode": "AU"
            },
            {
                "name": "Ringwood East",
                "countryCode": "AU"
            },
            {
                "name": "Ringwood North",
                "countryCode": "AU"
            },
            {
                "name": "Ripponlea",
                "countryCode": "AU"
            },
            {
                "name": "Robinvale",
                "countryCode": "AU"
            },
            {
                "name": "Rochester",
                "countryCode": "AU"
            },
            {
                "name": "Rockbank",
                "countryCode": "AU"
            },
            {
                "name": "Romsey",
                "countryCode": "AU"
            },
            {
                "name": "Rosanna",
                "countryCode": "AU"
            },
            {
                "name": "Rosebud",
                "countryCode": "AU"
            },
            {
                "name": "Rosebud West",
                "countryCode": "AU"
            },
            {
                "name": "Rosedale",
                "countryCode": "AU"
            },
            {
                "name": "Ross Creek",
                "countryCode": "AU"
            },
            {
                "name": "Rowville",
                "countryCode": "AU"
            },
            {
                "name": "Roxburgh Park",
                "countryCode": "AU"
            },
            {
                "name": "Rushworth",
                "countryCode": "AU"
            },
            {
                "name": "Rutherglen",
                "countryCode": "AU"
            },
            {
                "name": "Rye",
                "countryCode": "AU"
            },
            {
                "name": "Safety Beach",
                "countryCode": "AU"
            },
            {
                "name": "Saint Albans",
                "countryCode": "AU"
            },
            {
                "name": "Saint Andrews",
                "countryCode": "AU"
            },
            {
                "name": "Saint Andrews Beach",
                "countryCode": "AU"
            },
            {
                "name": "Saint Helena",
                "countryCode": "AU"
            },
            {
                "name": "Saint Kilda",
                "countryCode": "AU"
            },
            {
                "name": "Saint Leonards",
                "countryCode": "AU"
            },
            {
                "name": "Sale",
                "countryCode": "AU"
            },
            {
                "name": "San Remo",
                "countryCode": "AU"
            },
            {
                "name": "Sandhurst",
                "countryCode": "AU"
            },
            {
                "name": "Sandringham",
                "countryCode": "AU"
            },
            {
                "name": "Sassafras",
                "countryCode": "AU"
            },
            {
                "name": "Scoresby",
                "countryCode": "AU"
            },
            {
                "name": "Seabrook",
                "countryCode": "AU"
            },
            {
                "name": "Seaford",
                "countryCode": "AU"
            },
            {
                "name": "Seaholme",
                "countryCode": "AU"
            },
            {
                "name": "Sebastopol",
                "countryCode": "AU"
            },
            {
                "name": "Seddon",
                "countryCode": "AU"
            },
            {
                "name": "Selby",
                "countryCode": "AU"
            },
            {
                "name": "Seville",
                "countryCode": "AU"
            },
            {
                "name": "Seymour",
                "countryCode": "AU"
            },
            {
                "name": "Shepparton",
                "countryCode": "AU"
            },
            {
                "name": "Shepparton East",
                "countryCode": "AU"
            },
            {
                "name": "Silvan",
                "countryCode": "AU"
            },
            {
                "name": "Skye",
                "countryCode": "AU"
            },
            {
                "name": "Smythes Creek",
                "countryCode": "AU"
            },
            {
                "name": "Smythesdale",
                "countryCode": "AU"
            },
            {
                "name": "Soldiers Hill",
                "countryCode": "AU"
            },
            {
                "name": "Somers",
                "countryCode": "AU"
            },
            {
                "name": "Somerville",
                "countryCode": "AU"
            },
            {
                "name": "Sorrento",
                "countryCode": "AU"
            },
            {
                "name": "South Gippsland",
                "countryCode": "AU"
            },
            {
                "name": "South Kingsville",
                "countryCode": "AU"
            },
            {
                "name": "South Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "South Morang",
                "countryCode": "AU"
            },
            {
                "name": "South Yarra",
                "countryCode": "AU"
            },
            {
                "name": "Southbank",
                "countryCode": "AU"
            },
            {
                "name": "Southern Grampians",
                "countryCode": "AU"
            },
            {
                "name": "Spotswood",
                "countryCode": "AU"
            },
            {
                "name": "Springvale",
                "countryCode": "AU"
            },
            {
                "name": "Springvale South",
                "countryCode": "AU"
            },
            {
                "name": "St Albans Park",
                "countryCode": "AU"
            },
            {
                "name": "St Helena",
                "countryCode": "AU"
            },
            {
                "name": "St Kilda East",
                "countryCode": "AU"
            },
            {
                "name": "St Kilda West",
                "countryCode": "AU"
            },
            {
                "name": "Stawell",
                "countryCode": "AU"
            },
            {
                "name": "Stonnington",
                "countryCode": "AU"
            },
            {
                "name": "Stratford",
                "countryCode": "AU"
            },
            {
                "name": "Strathbogie",
                "countryCode": "AU"
            },
            {
                "name": "Strathdale",
                "countryCode": "AU"
            },
            {
                "name": "Strathfieldsaye",
                "countryCode": "AU"
            },
            {
                "name": "Strathmerton",
                "countryCode": "AU"
            },
            {
                "name": "Strathmore",
                "countryCode": "AU"
            },
            {
                "name": "Sunbury",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine North",
                "countryCode": "AU"
            },
            {
                "name": "Sunshine West",
                "countryCode": "AU"
            },
            {
                "name": "Surf Coast",
                "countryCode": "AU"
            },
            {
                "name": "Surrey Hills",
                "countryCode": "AU"
            },
            {
                "name": "Swan Hill",
                "countryCode": "AU"
            },
            {
                "name": "Sydenham",
                "countryCode": "AU"
            },
            {
                "name": "Tallangatta",
                "countryCode": "AU"
            },
            {
                "name": "Tarneit",
                "countryCode": "AU"
            },
            {
                "name": "Tatura",
                "countryCode": "AU"
            },
            {
                "name": "Taylors Hill",
                "countryCode": "AU"
            },
            {
                "name": "Taylors Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Tecoma",
                "countryCode": "AU"
            },
            {
                "name": "Teesdale",
                "countryCode": "AU"
            },
            {
                "name": "Templestowe",
                "countryCode": "AU"
            },
            {
                "name": "Templestowe Lower",
                "countryCode": "AU"
            },
            {
                "name": "Terang",
                "countryCode": "AU"
            },
            {
                "name": "The Basin",
                "countryCode": "AU"
            },
            {
                "name": "The Patch",
                "countryCode": "AU"
            },
            {
                "name": "Thomastown",
                "countryCode": "AU"
            },
            {
                "name": "Thomson",
                "countryCode": "AU"
            },
            {
                "name": "Thornbury",
                "countryCode": "AU"
            },
            {
                "name": "Timboon",
                "countryCode": "AU"
            },
            {
                "name": "Tongala",
                "countryCode": "AU"
            },
            {
                "name": "Tooradin",
                "countryCode": "AU"
            },
            {
                "name": "Toorak",
                "countryCode": "AU"
            },
            {
                "name": "Tootgarook",
                "countryCode": "AU"
            },
            {
                "name": "Torquay",
                "countryCode": "AU"
            },
            {
                "name": "Towong",
                "countryCode": "AU"
            },
            {
                "name": "Trafalgar",
                "countryCode": "AU"
            },
            {
                "name": "Traralgon",
                "countryCode": "AU"
            },
            {
                "name": "Travancore",
                "countryCode": "AU"
            },
            {
                "name": "Trentham",
                "countryCode": "AU"
            },
            {
                "name": "Truganina",
                "countryCode": "AU"
            },
            {
                "name": "Tullamarine",
                "countryCode": "AU"
            },
            {
                "name": "Tyabb",
                "countryCode": "AU"
            },
            {
                "name": "Upwey",
                "countryCode": "AU"
            },
            {
                "name": "Vermont",
                "countryCode": "AU"
            },
            {
                "name": "Vermont South",
                "countryCode": "AU"
            },
            {
                "name": "Viewbank",
                "countryCode": "AU"
            },
            {
                "name": "Wahgunyah",
                "countryCode": "AU"
            },
            {
                "name": "Wallan",
                "countryCode": "AU"
            },
            {
                "name": "Wallington",
                "countryCode": "AU"
            },
            {
                "name": "Wandana Heights",
                "countryCode": "AU"
            },
            {
                "name": "Wandin North",
                "countryCode": "AU"
            },
            {
                "name": "Wandong",
                "countryCode": "AU"
            },
            {
                "name": "Wangaratta",
                "countryCode": "AU"
            },
            {
                "name": "Wantirna",
                "countryCode": "AU"
            },
            {
                "name": "Wantirna South",
                "countryCode": "AU"
            },
            {
                "name": "Warburton",
                "countryCode": "AU"
            },
            {
                "name": "Warracknabeal",
                "countryCode": "AU"
            },
            {
                "name": "Warragul",
                "countryCode": "AU"
            },
            {
                "name": "Warrandyte",
                "countryCode": "AU"
            },
            {
                "name": "Warranwood",
                "countryCode": "AU"
            },
            {
                "name": "Warrnambool",
                "countryCode": "AU"
            },
            {
                "name": "Waterways",
                "countryCode": "AU"
            },
            {
                "name": "Watsonia",
                "countryCode": "AU"
            },
            {
                "name": "Watsonia North",
                "countryCode": "AU"
            },
            {
                "name": "Wattleglen",
                "countryCode": "AU"
            },
            {
                "name": "Waurn Ponds",
                "countryCode": "AU"
            },
            {
                "name": "Wellington",
                "countryCode": "AU"
            },
            {
                "name": "Wendouree",
                "countryCode": "AU"
            },
            {
                "name": "Werribee",
                "countryCode": "AU"
            },
            {
                "name": "Werribee South",
                "countryCode": "AU"
            },
            {
                "name": "Wesburn",
                "countryCode": "AU"
            },
            {
                "name": "West Footscray",
                "countryCode": "AU"
            },
            {
                "name": "West Melbourne",
                "countryCode": "AU"
            },
            {
                "name": "West Wimmera",
                "countryCode": "AU"
            },
            {
                "name": "West Wodonga",
                "countryCode": "AU"
            },
            {
                "name": "Westmeadows",
                "countryCode": "AU"
            },
            {
                "name": "Wheelers Hill",
                "countryCode": "AU"
            },
            {
                "name": "White Hills",
                "countryCode": "AU"
            },
            {
                "name": "Whitehorse",
                "countryCode": "AU"
            },
            {
                "name": "Whittington",
                "countryCode": "AU"
            },
            {
                "name": "Whittlesea",
                "countryCode": "AU"
            },
            {
                "name": "Williams Landing",
                "countryCode": "AU"
            },
            {
                "name": "Williamstown",
                "countryCode": "AU"
            },
            {
                "name": "Williamstown North",
                "countryCode": "AU"
            },
            {
                "name": "Winchelsea",
                "countryCode": "AU"
            },
            {
                "name": "Windsor",
                "countryCode": "AU"
            },
            {
                "name": "Wodonga",
                "countryCode": "AU"
            },
            {
                "name": "Wollert",
                "countryCode": "AU"
            },
            {
                "name": "Wonga Park",
                "countryCode": "AU"
            },
            {
                "name": "Wonthaggi",
                "countryCode": "AU"
            },
            {
                "name": "Woodend",
                "countryCode": "AU"
            },
            {
                "name": "Woori Yallock",
                "countryCode": "AU"
            },
            {
                "name": "Wurruk",
                "countryCode": "AU"
            },
            {
                "name": "Wy Yung",
                "countryCode": "AU"
            },
            {
                "name": "Wyndham",
                "countryCode": "AU"
            },
            {
                "name": "Wyndham Vale",
                "countryCode": "AU"
            },
            {
                "name": "Yackandandah",
                "countryCode": "AU"
            },
            {
                "name": "Yallambie",
                "countryCode": "AU"
            },
            {
                "name": "Yallourn North",
                "countryCode": "AU"
            },
            {
                "name": "Yarra",
                "countryCode": "AU"
            },
            {
                "name": "Yarra Glen",
                "countryCode": "AU"
            },
            {
                "name": "Yarra Junction",
                "countryCode": "AU"
            },
            {
                "name": "Yarra Ranges",
                "countryCode": "AU"
            },
            {
                "name": "Yarragon",
                "countryCode": "AU"
            },
            {
                "name": "Yarram",
                "countryCode": "AU"
            },
            {
                "name": "Yarrambat",
                "countryCode": "AU"
            },
            {
                "name": "Yarraville",
                "countryCode": "AU"
            },
            {
                "name": "Yarrawonga",
                "countryCode": "AU"
            },
            {
                "name": "Yarriambiack",
                "countryCode": "AU"
            },
            {
                "name": "Yea",
                "countryCode": "AU"
            },
            {
                "name": "Abbey",
                "countryCode": "AU"
            },
            {
                "name": "Albany",
                "countryCode": "AU"
            },
            {
                "name": "Albany city centre",
                "countryCode": "AU"
            },
            {
                "name": "Alexander Heights",
                "countryCode": "AU"
            },
            {
                "name": "Alfred Cove",
                "countryCode": "AU"
            },
            {
                "name": "Alkimos",
                "countryCode": "AU"
            },
            {
                "name": "Applecross",
                "countryCode": "AU"
            },
            {
                "name": "Ardross",
                "countryCode": "AU"
            },
            {
                "name": "Armadale",
                "countryCode": "AU"
            },
            {
                "name": "Ascot",
                "countryCode": "AU"
            },
            {
                "name": "Ashburton",
                "countryCode": "AU"
            },
            {
                "name": "Ashby",
                "countryCode": "AU"
            },
            {
                "name": "Ashfield",
                "countryCode": "AU"
            },
            {
                "name": "Attadale",
                "countryCode": "AU"
            },
            {
                "name": "Atwell",
                "countryCode": "AU"
            },
            {
                "name": "Aubin Grove",
                "countryCode": "AU"
            },
            {
                "name": "Augusta",
                "countryCode": "AU"
            },
            {
                "name": "Augusta-Margaret River Shire",
                "countryCode": "AU"
            },
            {
                "name": "Australind",
                "countryCode": "AU"
            },
            {
                "name": "Aveley",
                "countryCode": "AU"
            },
            {
                "name": "Badgingarra",
                "countryCode": "AU"
            },
            {
                "name": "Bakers Hill",
                "countryCode": "AU"
            },
            {
                "name": "Balcatta",
                "countryCode": "AU"
            },
            {
                "name": "Baldivis",
                "countryCode": "AU"
            },
            {
                "name": "Balga",
                "countryCode": "AU"
            },
            {
                "name": "Ballajura",
                "countryCode": "AU"
            },
            {
                "name": "Banjup",
                "countryCode": "AU"
            },
            {
                "name": "Banksia Grove",
                "countryCode": "AU"
            },
            {
                "name": "Bassendean",
                "countryCode": "AU"
            },
            {
                "name": "Bateman",
                "countryCode": "AU"
            },
            {
                "name": "Baynton",
                "countryCode": "AU"
            },
            {
                "name": "Bayonet Head",
                "countryCode": "AU"
            },
            {
                "name": "Bayswater",
                "countryCode": "AU"
            },
            {
                "name": "Beachlands",
                "countryCode": "AU"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "AU"
            },
            {
                "name": "Beckenham",
                "countryCode": "AU"
            },
            {
                "name": "Bedford",
                "countryCode": "AU"
            },
            {
                "name": "Bedfordale",
                "countryCode": "AU"
            },
            {
                "name": "Beechboro",
                "countryCode": "AU"
            },
            {
                "name": "Beeliar",
                "countryCode": "AU"
            },
            {
                "name": "Beldon",
                "countryCode": "AU"
            },
            {
                "name": "Bellevue",
                "countryCode": "AU"
            },
            {
                "name": "Belmont",
                "countryCode": "AU"
            },
            {
                "name": "Bennett Springs",
                "countryCode": "AU"
            },
            {
                "name": "Bentley",
                "countryCode": "AU"
            },
            {
                "name": "Beresford",
                "countryCode": "AU"
            },
            {
                "name": "Bertram",
                "countryCode": "AU"
            },
            {
                "name": "Beverley",
                "countryCode": "AU"
            },
            {
                "name": "Bibra Lake",
                "countryCode": "AU"
            },
            {
                "name": "Bicton",
                "countryCode": "AU"
            },
            {
                "name": "Bilingurr",
                "countryCode": "AU"
            },
            {
                "name": "Bindoon",
                "countryCode": "AU"
            },
            {
                "name": "Binningup",
                "countryCode": "AU"
            },
            {
                "name": "Bluff Point",
                "countryCode": "AU"
            },
            {
                "name": "Boddington",
                "countryCode": "AU"
            },
            {
                "name": "Booragoon",
                "countryCode": "AU"
            },
            {
                "name": "Boulder",
                "countryCode": "AU"
            },
            {
                "name": "Boyanup",
                "countryCode": "AU"
            },
            {
                "name": "Boyup Brook",
                "countryCode": "AU"
            },
            {
                "name": "Brabham",
                "countryCode": "AU"
            },
            {
                "name": "Brentwood",
                "countryCode": "AU"
            },
            {
                "name": "Bridgetown",
                "countryCode": "AU"
            },
            {
                "name": "Bridgetown-Greenbushes",
                "countryCode": "AU"
            },
            {
                "name": "Broadwater",
                "countryCode": "AU"
            },
            {
                "name": "Brockman",
                "countryCode": "AU"
            },
            {
                "name": "Brookdale",
                "countryCode": "AU"
            },
            {
                "name": "Brookton",
                "countryCode": "AU"
            },
            {
                "name": "Broome",
                "countryCode": "AU"
            },
            {
                "name": "Broomehill-Tambellup",
                "countryCode": "AU"
            },
            {
                "name": "Bruce Rock",
                "countryCode": "AU"
            },
            {
                "name": "Brunswick",
                "countryCode": "AU"
            },
            {
                "name": "Bulgarra",
                "countryCode": "AU"
            },
            {
                "name": "Bull Creek",
                "countryCode": "AU"
            },
            {
                "name": "Bullsbrook",
                "countryCode": "AU"
            },
            {
                "name": "Bunbury",
                "countryCode": "AU"
            },
            {
                "name": "Burns Beach",
                "countryCode": "AU"
            },
            {
                "name": "Burswood",
                "countryCode": "AU"
            },
            {
                "name": "Busselton",
                "countryCode": "AU"
            },
            {
                "name": "Busselton city cenre",
                "countryCode": "AU"
            },
            {
                "name": "Butler",
                "countryCode": "AU"
            },
            {
                "name": "Byford",
                "countryCode": "AU"
            },
            {
                "name": "Cable Beach",
                "countryCode": "AU"
            },
            {
                "name": "Calista",
                "countryCode": "AU"
            },
            {
                "name": "Cambridge",
                "countryCode": "AU"
            },
            {
                "name": "Camillo",
                "countryCode": "AU"
            },
            {
                "name": "Canning",
                "countryCode": "AU"
            },
            {
                "name": "Canning Vale",
                "countryCode": "AU"
            },
            {
                "name": "Cannington",
                "countryCode": "AU"
            },
            {
                "name": "Capel",
                "countryCode": "AU"
            },
            {
                "name": "Cardup",
                "countryCode": "AU"
            },
            {
                "name": "Carey Park",
                "countryCode": "AU"
            },
            {
                "name": "Carine",
                "countryCode": "AU"
            },
            {
                "name": "Carlisle",
                "countryCode": "AU"
            },
            {
                "name": "Carnamah",
                "countryCode": "AU"
            },
            {
                "name": "Carnarvon",
                "countryCode": "AU"
            },
            {
                "name": "Carramar",
                "countryCode": "AU"
            },
            {
                "name": "Castletown",
                "countryCode": "AU"
            },
            {
                "name": "Casuarina",
                "countryCode": "AU"
            },
            {
                "name": "Cataby",
                "countryCode": "AU"
            },
            {
                "name": "Caversham",
                "countryCode": "AU"
            },
            {
                "name": "Cervantes",
                "countryCode": "AU"
            },
            {
                "name": "Champion Lakes",
                "countryCode": "AU"
            },
            {
                "name": "Chapman Valley",
                "countryCode": "AU"
            },
            {
                "name": "Chidlow",
                "countryCode": "AU"
            },
            {
                "name": "Chittering",
                "countryCode": "AU"
            },
            {
                "name": "Churchlands",
                "countryCode": "AU"
            },
            {
                "name": "City Beach",
                "countryCode": "AU"
            },
            {
                "name": "City of Cockburn",
                "countryCode": "AU"
            },
            {
                "name": "City of Perth",
                "countryCode": "AU"
            },
            {
                "name": "Claremont",
                "countryCode": "AU"
            },
            {
                "name": "Clarkson",
                "countryCode": "AU"
            },
            {
                "name": "Cloverdale",
                "countryCode": "AU"
            },
            {
                "name": "Cockburn Central",
                "countryCode": "AU"
            },
            {
                "name": "College Grove",
                "countryCode": "AU"
            },
            {
                "name": "Collie",
                "countryCode": "AU"
            },
            {
                "name": "Como",
                "countryCode": "AU"
            },
            {
                "name": "Connolly",
                "countryCode": "AU"
            },
            {
                "name": "Coodanup",
                "countryCode": "AU"
            },
            {
                "name": "Coogee",
                "countryCode": "AU"
            },
            {
                "name": "Coolbellup",
                "countryCode": "AU"
            },
            {
                "name": "Coolbinia",
                "countryCode": "AU"
            },
            {
                "name": "Coolgardie",
                "countryCode": "AU"
            },
            {
                "name": "Cooloongup",
                "countryCode": "AU"
            },
            {
                "name": "Coorow",
                "countryCode": "AU"
            },
            {
                "name": "Corrigin",
                "countryCode": "AU"
            },
            {
                "name": "Cottesloe",
                "countryCode": "AU"
            },
            {
                "name": "Cowaramup",
                "countryCode": "AU"
            },
            {
                "name": "Craigie",
                "countryCode": "AU"
            },
            {
                "name": "Cranbrook",
                "countryCode": "AU"
            },
            {
                "name": "Crawley",
                "countryCode": "AU"
            },
            {
                "name": "Cuballing",
                "countryCode": "AU"
            },
            {
                "name": "Cue",
                "countryCode": "AU"
            },
            {
                "name": "Cunderdin",
                "countryCode": "AU"
            },
            {
                "name": "Currambine",
                "countryCode": "AU"
            },
            {
                "name": "Daglish",
                "countryCode": "AU"
            },
            {
                "name": "Dalkeith",
                "countryCode": "AU"
            },
            {
                "name": "Dalwallinu",
                "countryCode": "AU"
            },
            {
                "name": "Dalyellup",
                "countryCode": "AU"
            },
            {
                "name": "Dampier",
                "countryCode": "AU"
            },
            {
                "name": "Dampier Peninsula",
                "countryCode": "AU"
            },
            {
                "name": "Dandaragan",
                "countryCode": "AU"
            },
            {
                "name": "Darch",
                "countryCode": "AU"
            },
            {
                "name": "Dardanup",
                "countryCode": "AU"
            },
            {
                "name": "Darling Downs",
                "countryCode": "AU"
            },
            {
                "name": "Darlington",
                "countryCode": "AU"
            },
            {
                "name": "Dawesville",
                "countryCode": "AU"
            },
            {
                "name": "Dayton",
                "countryCode": "AU"
            },
            {
                "name": "Denham",
                "countryCode": "AU"
            },
            {
                "name": "Denmark",
                "countryCode": "AU"
            },
            {
                "name": "Derby",
                "countryCode": "AU"
            },
            {
                "name": "Derby-West Kimberley",
                "countryCode": "AU"
            },
            {
                "name": "Dianella",
                "countryCode": "AU"
            },
            {
                "name": "Djugun",
                "countryCode": "AU"
            },
            {
                "name": "Dongara",
                "countryCode": "AU"
            },
            {
                "name": "Donnybrook",
                "countryCode": "AU"
            },
            {
                "name": "Donnybrook-Balingup",
                "countryCode": "AU"
            },
            {
                "name": "Doubleview",
                "countryCode": "AU"
            },
            {
                "name": "Dowerin",
                "countryCode": "AU"
            },
            {
                "name": "Drummond Cove",
                "countryCode": "AU"
            },
            {
                "name": "Dudley Park",
                "countryCode": "AU"
            },
            {
                "name": "Dumbleyung Shire",
                "countryCode": "AU"
            },
            {
                "name": "Duncraig",
                "countryCode": "AU"
            },
            {
                "name": "Dundas",
                "countryCode": "AU"
            },
            {
                "name": "Dunsborough",
                "countryCode": "AU"
            },
            {
                "name": "East Bunbury",
                "countryCode": "AU"
            },
            {
                "name": "East Cannington",
                "countryCode": "AU"
            },
            {
                "name": "East Carnarvon",
                "countryCode": "AU"
            },
            {
                "name": "East Fremantle",
                "countryCode": "AU"
            },
            {
                "name": "East Perth",
                "countryCode": "AU"
            },
            {
                "name": "East Pilbara",
                "countryCode": "AU"
            },
            {
                "name": "East Victoria Park",
                "countryCode": "AU"
            },
            {
                "name": "Eaton",
                "countryCode": "AU"
            },
            {
                "name": "Eden Hill",
                "countryCode": "AU"
            },
            {
                "name": "Edgewater",
                "countryCode": "AU"
            },
            {
                "name": "Eglinton",
                "countryCode": "AU"
            },
            {
                "name": "Ellenbrook",
                "countryCode": "AU"
            },
            {
                "name": "Embleton",
                "countryCode": "AU"
            },
            {
                "name": "Erskine",
                "countryCode": "AU"
            },
            {
                "name": "Esperance",
                "countryCode": "AU"
            },
            {
                "name": "Esperance Shire",
                "countryCode": "AU"
            },
            {
                "name": "Exmouth",
                "countryCode": "AU"
            },
            {
                "name": "Falcon",
                "countryCode": "AU"
            },
            {
                "name": "Ferndale",
                "countryCode": "AU"
            },
            {
                "name": "Fitzroy Crossing",
                "countryCode": "AU"
            },
            {
                "name": "Floreat",
                "countryCode": "AU"
            },
            {
                "name": "Forrestdale",
                "countryCode": "AU"
            },
            {
                "name": "Forrestfield",
                "countryCode": "AU"
            },
            {
                "name": "Fremantle",
                "countryCode": "AU"
            },
            {
                "name": "Garden Island",
                "countryCode": "AU"
            },
            {
                "name": "Gelorup",
                "countryCode": "AU"
            },
            {
                "name": "Geographe",
                "countryCode": "AU"
            },
            {
                "name": "Geraldton",
                "countryCode": "AU"
            },
            {
                "name": "Geraldton city centre",
                "countryCode": "AU"
            },
            {
                "name": "Gidgegannup",
                "countryCode": "AU"
            },
            {
                "name": "Gingin",
                "countryCode": "AU"
            },
            {
                "name": "Girrawheen",
                "countryCode": "AU"
            },
            {
                "name": "Glen Forrest",
                "countryCode": "AU"
            },
            {
                "name": "Glen Iris",
                "countryCode": "AU"
            },
            {
                "name": "Glendalough",
                "countryCode": "AU"
            },
            {
                "name": "Gnangara",
                "countryCode": "AU"
            },
            {
                "name": "Gnowangerup",
                "countryCode": "AU"
            },
            {
                "name": "Golden Bay",
                "countryCode": "AU"
            },
            {
                "name": "Goomalling",
                "countryCode": "AU"
            },
            {
                "name": "Gooseberry Hill",
                "countryCode": "AU"
            },
            {
                "name": "Gosnells",
                "countryCode": "AU"
            },
            {
                "name": "Grasmere",
                "countryCode": "AU"
            },
            {
                "name": "Green Head",
                "countryCode": "AU"
            },
            {
                "name": "Greenfields",
                "countryCode": "AU"
            },
            {
                "name": "Greenmount",
                "countryCode": "AU"
            },
            {
                "name": "Greenwood",
                "countryCode": "AU"
            },
            {
                "name": "Guildford",
                "countryCode": "AU"
            },
            {
                "name": "Gwelup",
                "countryCode": "AU"
            },
            {
                "name": "Halls Creek",
                "countryCode": "AU"
            },
            {
                "name": "Halls Head",
                "countryCode": "AU"
            },
            {
                "name": "Hamersley",
                "countryCode": "AU"
            },
            {
                "name": "Hamilton Hill",
                "countryCode": "AU"
            },
            {
                "name": "Hammond Park",
                "countryCode": "AU"
            },
            {
                "name": "Hannans",
                "countryCode": "AU"
            },
            {
                "name": "Harrisdale",
                "countryCode": "AU"
            },
            {
                "name": "Harvey",
                "countryCode": "AU"
            },
            {
                "name": "Heathridge",
                "countryCode": "AU"
            },
            {
                "name": "Helena Valley",
                "countryCode": "AU"
            },
            {
                "name": "Henley Brook",
                "countryCode": "AU"
            },
            {
                "name": "Herne Hill",
                "countryCode": "AU"
            },
            {
                "name": "High Wycombe",
                "countryCode": "AU"
            },
            {
                "name": "Highgate",
                "countryCode": "AU"
            },
            {
                "name": "Hilbert",
                "countryCode": "AU"
            },
            {
                "name": "Hill River",
                "countryCode": "AU"
            },
            {
                "name": "Hillarys",
                "countryCode": "AU"
            },
            {
                "name": "Hillman",
                "countryCode": "AU"
            },
            {
                "name": "Hilton",
                "countryCode": "AU"
            },
            {
                "name": "Hocking",
                "countryCode": "AU"
            },
            {
                "name": "Huntingdale",
                "countryCode": "AU"
            },
            {
                "name": "Iluka",
                "countryCode": "AU"
            },
            {
                "name": "Inglewood",
                "countryCode": "AU"
            },
            {
                "name": "Innaloo",
                "countryCode": "AU"
            },
            {
                "name": "Irwin",
                "countryCode": "AU"
            },
            {
                "name": "Jandakot",
                "countryCode": "AU"
            },
            {
                "name": "Jane Brook",
                "countryCode": "AU"
            },
            {
                "name": "Jarrahdale",
                "countryCode": "AU"
            },
            {
                "name": "Jerramungup",
                "countryCode": "AU"
            },
            {
                "name": "Jindalee",
                "countryCode": "AU"
            },
            {
                "name": "Jolimont",
                "countryCode": "AU"
            },
            {
                "name": "Joondalup",
                "countryCode": "AU"
            },
            {
                "name": "Joondanna",
                "countryCode": "AU"
            },
            {
                "name": "Jurien Bay",
                "countryCode": "AU"
            },
            {
                "name": "Kalamunda",
                "countryCode": "AU"
            },
            {
                "name": "Kalbarri",
                "countryCode": "AU"
            },
            {
                "name": "Kalgoorlie",
                "countryCode": "AU"
            },
            {
                "name": "Kalgoorlie/Boulder",
                "countryCode": "AU"
            },
            {
                "name": "Kallaroo",
                "countryCode": "AU"
            },
            {
                "name": "Kambalda East",
                "countryCode": "AU"
            },
            {
                "name": "Kambalda West",
                "countryCode": "AU"
            },
            {
                "name": "Karawara",
                "countryCode": "AU"
            },
            {
                "name": "Kardinya",
                "countryCode": "AU"
            },
            {
                "name": "Karnup",
                "countryCode": "AU"
            },
            {
                "name": "Karratha",
                "countryCode": "AU"
            },
            {
                "name": "Karrinyup",
                "countryCode": "AU"
            },
            {
                "name": "Katanning",
                "countryCode": "AU"
            },
            {
                "name": "Kellerberrin",
                "countryCode": "AU"
            },
            {
                "name": "Kelmscott",
                "countryCode": "AU"
            },
            {
                "name": "Kent Shire",
                "countryCode": "AU"
            },
            {
                "name": "Kenwick",
                "countryCode": "AU"
            },
            {
                "name": "Kewdale",
                "countryCode": "AU"
            },
            {
                "name": "Kiara",
                "countryCode": "AU"
            },
            {
                "name": "Kingsley",
                "countryCode": "AU"
            },
            {
                "name": "Kinross",
                "countryCode": "AU"
            },
            {
                "name": "Kojonup",
                "countryCode": "AU"
            },
            {
                "name": "Kondinin",
                "countryCode": "AU"
            },
            {
                "name": "Koondoola",
                "countryCode": "AU"
            },
            {
                "name": "Koorda",
                "countryCode": "AU"
            },
            {
                "name": "Kulin",
                "countryCode": "AU"
            },
            {
                "name": "Kununurra",
                "countryCode": "AU"
            },
            {
                "name": "Kwinana",
                "countryCode": "AU"
            },
            {
                "name": "Lake Grace",
                "countryCode": "AU"
            },
            {
                "name": "Lakelands",
                "countryCode": "AU"
            },
            {
                "name": "Lamington",
                "countryCode": "AU"
            },
            {
                "name": "Lancelin",
                "countryCode": "AU"
            },
            {
                "name": "Landsdale",
                "countryCode": "AU"
            },
            {
                "name": "Langford",
                "countryCode": "AU"
            },
            {
                "name": "Lathlain",
                "countryCode": "AU"
            },
            {
                "name": "Laverton",
                "countryCode": "AU"
            },
            {
                "name": "Leda",
                "countryCode": "AU"
            },
            {
                "name": "Leederville",
                "countryCode": "AU"
            },
            {
                "name": "Leeman",
                "countryCode": "AU"
            },
            {
                "name": "Leeming",
                "countryCode": "AU"
            },
            {
                "name": "Leinster",
                "countryCode": "AU"
            },
            {
                "name": "Leonora",
                "countryCode": "AU"
            },
            {
                "name": "Leschenault",
                "countryCode": "AU"
            },
            {
                "name": "Lesmurdie",
                "countryCode": "AU"
            },
            {
                "name": "Little Grove",
                "countryCode": "AU"
            },
            {
                "name": "Lockridge",
                "countryCode": "AU"
            },
            {
                "name": "Lockyer",
                "countryCode": "AU"
            },
            {
                "name": "Lower Chittering",
                "countryCode": "AU"
            },
            {
                "name": "Lower King",
                "countryCode": "AU"
            },
            {
                "name": "Lynwood",
                "countryCode": "AU"
            },
            {
                "name": "Maddington",
                "countryCode": "AU"
            },
            {
                "name": "Madeley",
                "countryCode": "AU"
            },
            {
                "name": "Madora Bay",
                "countryCode": "AU"
            },
            {
                "name": "Maida Vale",
                "countryCode": "AU"
            },
            {
                "name": "Malaga",
                "countryCode": "AU"
            },
            {
                "name": "Mandurah",
                "countryCode": "AU"
            },
            {
                "name": "Mandurah city centre",
                "countryCode": "AU"
            },
            {
                "name": "Manjimup",
                "countryCode": "AU"
            },
            {
                "name": "Manning",
                "countryCode": "AU"
            },
            {
                "name": "Marangaroo",
                "countryCode": "AU"
            },
            {
                "name": "Marble Bar",
                "countryCode": "AU"
            },
            {
                "name": "Margaret River",
                "countryCode": "AU"
            },
            {
                "name": "Marmion",
                "countryCode": "AU"
            },
            {
                "name": "Martin",
                "countryCode": "AU"
            },
            {
                "name": "Maylands",
                "countryCode": "AU"
            },
            {
                "name": "McKail",
                "countryCode": "AU"
            },
            {
                "name": "Meadow Springs",
                "countryCode": "AU"
            },
            {
                "name": "Medina",
                "countryCode": "AU"
            },
            {
                "name": "Meekatharra",
                "countryCode": "AU"
            },
            {
                "name": "Melville",
                "countryCode": "AU"
            },
            {
                "name": "Menora",
                "countryCode": "AU"
            },
            {
                "name": "Menzies",
                "countryCode": "AU"
            },
            {
                "name": "Merredin",
                "countryCode": "AU"
            },
            {
                "name": "Merriwa",
                "countryCode": "AU"
            },
            {
                "name": "Middle Swan",
                "countryCode": "AU"
            },
            {
                "name": "Midland",
                "countryCode": "AU"
            },
            {
                "name": "Midvale",
                "countryCode": "AU"
            },
            {
                "name": "Millars Well",
                "countryCode": "AU"
            },
            {
                "name": "Millbridge",
                "countryCode": "AU"
            },
            {
                "name": "Mindarie",
                "countryCode": "AU"
            },
            {
                "name": "Mingenew",
                "countryCode": "AU"
            },
            {
                "name": "Mira Mar",
                "countryCode": "AU"
            },
            {
                "name": "Mirrabooka",
                "countryCode": "AU"
            },
            {
                "name": "Moora",
                "countryCode": "AU"
            },
            {
                "name": "Morawa",
                "countryCode": "AU"
            },
            {
                "name": "Morley",
                "countryCode": "AU"
            },
            {
                "name": "Mosman Park",
                "countryCode": "AU"
            },
            {
                "name": "Mount Barker",
                "countryCode": "AU"
            },
            {
                "name": "Mount Claremont",
                "countryCode": "AU"
            },
            {
                "name": "Mount Hawthorn",
                "countryCode": "AU"
            },
            {
                "name": "Mount Helena",
                "countryCode": "AU"
            },
            {
                "name": "Mount Lawley",
                "countryCode": "AU"
            },
            {
                "name": "Mount Magnet",
                "countryCode": "AU"
            },
            {
                "name": "Mount Marshall",
                "countryCode": "AU"
            },
            {
                "name": "Mount Melville",
                "countryCode": "AU"
            },
            {
                "name": "Mount Nasura",
                "countryCode": "AU"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "AU"
            },
            {
                "name": "Mount Richon",
                "countryCode": "AU"
            },
            {
                "name": "Mount Tarcoola",
                "countryCode": "AU"
            },
            {
                "name": "Muchea",
                "countryCode": "AU"
            },
            {
                "name": "Mukinbudin",
                "countryCode": "AU"
            },
            {
                "name": "Mullaloo",
                "countryCode": "AU"
            },
            {
                "name": "Mundaring",
                "countryCode": "AU"
            },
            {
                "name": "Mundijong",
                "countryCode": "AU"
            },
            {
                "name": "Munster",
                "countryCode": "AU"
            },
            {
                "name": "Murchison",
                "countryCode": "AU"
            },
            {
                "name": "Murdoch",
                "countryCode": "AU"
            },
            {
                "name": "Murray",
                "countryCode": "AU"
            },
            {
                "name": "Myaree",
                "countryCode": "AU"
            },
            {
                "name": "Nannup",
                "countryCode": "AU"
            },
            {
                "name": "Narembeen",
                "countryCode": "AU"
            },
            {
                "name": "Narrogin",
                "countryCode": "AU"
            },
            {
                "name": "Nedlands",
                "countryCode": "AU"
            },
            {
                "name": "Newman",
                "countryCode": "AU"
            },
            {
                "name": "Ngaanyatjarraku",
                "countryCode": "AU"
            },
            {
                "name": "Nickol",
                "countryCode": "AU"
            },
            {
                "name": "Nollamara",
                "countryCode": "AU"
            },
            {
                "name": "Noranda",
                "countryCode": "AU"
            },
            {
                "name": "North Beach",
                "countryCode": "AU"
            },
            {
                "name": "North Coogee",
                "countryCode": "AU"
            },
            {
                "name": "North Fremantle",
                "countryCode": "AU"
            },
            {
                "name": "North Lake",
                "countryCode": "AU"
            },
            {
                "name": "North Perth",
                "countryCode": "AU"
            },
            {
                "name": "Northam",
                "countryCode": "AU"
            },
            {
                "name": "Northampton Shire",
                "countryCode": "AU"
            },
            {
                "name": "Northbridge",
                "countryCode": "AU"
            },
            {
                "name": "Nullagine",
                "countryCode": "AU"
            },
            {
                "name": "Nulsen",
                "countryCode": "AU"
            },
            {
                "name": "Nungarin",
                "countryCode": "AU"
            },
            {
                "name": "Oakford",
                "countryCode": "AU"
            },
            {
                "name": "Ocean Reef",
                "countryCode": "AU"
            },
            {
                "name": "Onslow",
                "countryCode": "AU"
            },
            {
                "name": "Orana",
                "countryCode": "AU"
            },
            {
                "name": "Orelia",
                "countryCode": "AU"
            },
            {
                "name": "Osborne Park",
                "countryCode": "AU"
            },
            {
                "name": "Padbury",
                "countryCode": "AU"
            },
            {
                "name": "Palmyra",
                "countryCode": "AU"
            },
            {
                "name": "Paraburdoo",
                "countryCode": "AU"
            },
            {
                "name": "Parkerville",
                "countryCode": "AU"
            },
            {
                "name": "Parkwood",
                "countryCode": "AU"
            },
            {
                "name": "Parmelia",
                "countryCode": "AU"
            },
            {
                "name": "Pearce",
                "countryCode": "AU"
            },
            {
                "name": "Pearsall",
                "countryCode": "AU"
            },
            {
                "name": "Pegs Creek",
                "countryCode": "AU"
            },
            {
                "name": "Pemberton",
                "countryCode": "AU"
            },
            {
                "name": "Peppermint Grove",
                "countryCode": "AU"
            },
            {
                "name": "Perenjori",
                "countryCode": "AU"
            },
            {
                "name": "Perth",
                "countryCode": "AU"
            },
            {
                "name": "Perth city centre",
                "countryCode": "AU"
            },
            {
                "name": "Piara Waters",
                "countryCode": "AU"
            },
            {
                "name": "Piccadilly",
                "countryCode": "AU"
            },
            {
                "name": "Pingelly",
                "countryCode": "AU"
            },
            {
                "name": "Pinjarra",
                "countryCode": "AU"
            },
            {
                "name": "Plantagenet Shire",
                "countryCode": "AU"
            },
            {
                "name": "Port Denison",
                "countryCode": "AU"
            },
            {
                "name": "Port Hedland",
                "countryCode": "AU"
            },
            {
                "name": "Port Kennedy",
                "countryCode": "AU"
            },
            {
                "name": "Quairading",
                "countryCode": "AU"
            },
            {
                "name": "Queens Park",
                "countryCode": "AU"
            },
            {
                "name": "Quindalup",
                "countryCode": "AU"
            },
            {
                "name": "Quinns Rocks",
                "countryCode": "AU"
            },
            {
                "name": "Rangeway",
                "countryCode": "AU"
            },
            {
                "name": "Ravensthorpe",
                "countryCode": "AU"
            },
            {
                "name": "Ravenswood",
                "countryCode": "AU"
            },
            {
                "name": "Redcliffe",
                "countryCode": "AU"
            },
            {
                "name": "Regans Ford",
                "countryCode": "AU"
            },
            {
                "name": "Ridgewood",
                "countryCode": "AU"
            },
            {
                "name": "Riverton",
                "countryCode": "AU"
            },
            {
                "name": "Rivervale",
                "countryCode": "AU"
            },
            {
                "name": "Rockingham",
                "countryCode": "AU"
            },
            {
                "name": "Rockingham city centre",
                "countryCode": "AU"
            },
            {
                "name": "Roebuck",
                "countryCode": "AU"
            },
            {
                "name": "Roleystone",
                "countryCode": "AU"
            },
            {
                "name": "Rossmoyne",
                "countryCode": "AU"
            },
            {
                "name": "Safety Bay",
                "countryCode": "AU"
            },
            {
                "name": "Salter Point",
                "countryCode": "AU"
            },
            {
                "name": "Samson",
                "countryCode": "AU"
            },
            {
                "name": "Sandstone",
                "countryCode": "AU"
            },
            {
                "name": "Scarborough",
                "countryCode": "AU"
            },
            {
                "name": "Secret Harbour",
                "countryCode": "AU"
            },
            {
                "name": "Serpentine",
                "countryCode": "AU"
            },
            {
                "name": "Serpentine-Jarrahdale",
                "countryCode": "AU"
            },
            {
                "name": "Seville Grove",
                "countryCode": "AU"
            },
            {
                "name": "Shark Bay",
                "countryCode": "AU"
            },
            {
                "name": "Shelley",
                "countryCode": "AU"
            },
            {
                "name": "Shenton Park",
                "countryCode": "AU"
            },
            {
                "name": "Shoalwater",
                "countryCode": "AU"
            },
            {
                "name": "Silver Sands",
                "countryCode": "AU"
            },
            {
                "name": "Sinagra",
                "countryCode": "AU"
            },
            {
                "name": "Singleton",
                "countryCode": "AU"
            },
            {
                "name": "Somerville",
                "countryCode": "AU"
            },
            {
                "name": "Sorrento",
                "countryCode": "AU"
            },
            {
                "name": "South Bunbury",
                "countryCode": "AU"
            },
            {
                "name": "South Carnarvon",
                "countryCode": "AU"
            },
            {
                "name": "South Fremantle",
                "countryCode": "AU"
            },
            {
                "name": "South Guildford",
                "countryCode": "AU"
            },
            {
                "name": "South Hedland",
                "countryCode": "AU"
            },
            {
                "name": "South Kalgoorlie",
                "countryCode": "AU"
            },
            {
                "name": "South Lake",
                "countryCode": "AU"
            },
            {
                "name": "South Perth",
                "countryCode": "AU"
            },
            {
                "name": "South Yunderup",
                "countryCode": "AU"
            },
            {
                "name": "Southern River",
                "countryCode": "AU"
            },
            {
                "name": "Spalding",
                "countryCode": "AU"
            },
            {
                "name": "Spearwood",
                "countryCode": "AU"
            },
            {
                "name": "Spencer Park",
                "countryCode": "AU"
            },
            {
                "name": "St George Ranges",
                "countryCode": "AU"
            },
            {
                "name": "St James",
                "countryCode": "AU"
            },
            {
                "name": "Stirling",
                "countryCode": "AU"
            },
            {
                "name": "Stoneville",
                "countryCode": "AU"
            },
            {
                "name": "Strathalbyn",
                "countryCode": "AU"
            },
            {
                "name": "Stratton",
                "countryCode": "AU"
            },
            {
                "name": "Subiaco",
                "countryCode": "AU"
            },
            {
                "name": "Success",
                "countryCode": "AU"
            },
            {
                "name": "Sunset Beach",
                "countryCode": "AU"
            },
            {
                "name": "Swan",
                "countryCode": "AU"
            },
            {
                "name": "Swan View",
                "countryCode": "AU"
            },
            {
                "name": "Swanbourne",
                "countryCode": "AU"
            },
            {
                "name": "Tammin",
                "countryCode": "AU"
            },
            {
                "name": "Tapping",
                "countryCode": "AU"
            },
            {
                "name": "Tarcoola Beach",
                "countryCode": "AU"
            },
            {
                "name": "Telfer",
                "countryCode": "AU"
            },
            {
                "name": "The Vines",
                "countryCode": "AU"
            },
            {
                "name": "Thornlie",
                "countryCode": "AU"
            },
            {
                "name": "Three Springs",
                "countryCode": "AU"
            },
            {
                "name": "Tom Price",
                "countryCode": "AU"
            },
            {
                "name": "Toodyay",
                "countryCode": "AU"
            },
            {
                "name": "Trayning",
                "countryCode": "AU"
            },
            {
                "name": "Trigg",
                "countryCode": "AU"
            },
            {
                "name": "Tuart Hill",
                "countryCode": "AU"
            },
            {
                "name": "Two Rocks",
                "countryCode": "AU"
            },
            {
                "name": "Upper Gascoyne",
                "countryCode": "AU"
            },
            {
                "name": "Usher",
                "countryCode": "AU"
            },
            {
                "name": "Utakarra",
                "countryCode": "AU"
            },
            {
                "name": "Vasse",
                "countryCode": "AU"
            },
            {
                "name": "Victoria Park",
                "countryCode": "AU"
            },
            {
                "name": "Victoria Plains",
                "countryCode": "AU"
            },
            {
                "name": "Vincent",
                "countryCode": "AU"
            },
            {
                "name": "Viveash",
                "countryCode": "AU"
            },
            {
                "name": "Waggrakine",
                "countryCode": "AU"
            },
            {
                "name": "Wagin",
                "countryCode": "AU"
            },
            {
                "name": "Waikiki",
                "countryCode": "AU"
            },
            {
                "name": "Wandering",
                "countryCode": "AU"
            },
            {
                "name": "Wandi",
                "countryCode": "AU"
            },
            {
                "name": "Wandina",
                "countryCode": "AU"
            },
            {
                "name": "Wannanup",
                "countryCode": "AU"
            },
            {
                "name": "Wanneroo",
                "countryCode": "AU"
            },
            {
                "name": "Warnbro",
                "countryCode": "AU"
            },
            {
                "name": "Waroona",
                "countryCode": "AU"
            },
            {
                "name": "Warwick",
                "countryCode": "AU"
            },
            {
                "name": "Waterford",
                "countryCode": "AU"
            },
            {
                "name": "Watermans Bay",
                "countryCode": "AU"
            },
            {
                "name": "Wattle Grove",
                "countryCode": "AU"
            },
            {
                "name": "Wellard",
                "countryCode": "AU"
            },
            {
                "name": "Wembley",
                "countryCode": "AU"
            },
            {
                "name": "Wembley Downs",
                "countryCode": "AU"
            },
            {
                "name": "West Arthur",
                "countryCode": "AU"
            },
            {
                "name": "West Beach",
                "countryCode": "AU"
            },
            {
                "name": "West Busselton",
                "countryCode": "AU"
            },
            {
                "name": "West Lamington",
                "countryCode": "AU"
            },
            {
                "name": "West Leederville",
                "countryCode": "AU"
            },
            {
                "name": "West Perth",
                "countryCode": "AU"
            },
            {
                "name": "Westminster",
                "countryCode": "AU"
            },
            {
                "name": "Westonia",
                "countryCode": "AU"
            },
            {
                "name": "White Gum Valley",
                "countryCode": "AU"
            },
            {
                "name": "Wickepin",
                "countryCode": "AU"
            },
            {
                "name": "Wickham",
                "countryCode": "AU"
            },
            {
                "name": "Willagee",
                "countryCode": "AU"
            },
            {
                "name": "Willetton",
                "countryCode": "AU"
            },
            {
                "name": "Williams",
                "countryCode": "AU"
            },
            {
                "name": "Wilson",
                "countryCode": "AU"
            },
            {
                "name": "Wiluna",
                "countryCode": "AU"
            },
            {
                "name": "Winthrop",
                "countryCode": "AU"
            },
            {
                "name": "Withers",
                "countryCode": "AU"
            },
            {
                "name": "Wongan-Ballidu",
                "countryCode": "AU"
            },
            {
                "name": "Wonthella",
                "countryCode": "AU"
            },
            {
                "name": "Woodanilling",
                "countryCode": "AU"
            },
            {
                "name": "Woodbridge",
                "countryCode": "AU"
            },
            {
                "name": "Woodridge",
                "countryCode": "AU"
            },
            {
                "name": "Woodvale",
                "countryCode": "AU"
            },
            {
                "name": "Wooroloo",
                "countryCode": "AU"
            },
            {
                "name": "Woorree",
                "countryCode": "AU"
            },
            {
                "name": "Wundowie",
                "countryCode": "AU"
            },
            {
                "name": "Wyalkatchem",
                "countryCode": "AU"
            },
            {
                "name": "Wyndham-East Kimberley",
                "countryCode": "AU"
            },
            {
                "name": "Yakamia",
                "countryCode": "AU"
            },
            {
                "name": "Yalgoo",
                "countryCode": "AU"
            },
            {
                "name": "Yallingup",
                "countryCode": "AU"
            },
            {
                "name": "Yalyalup",
                "countryCode": "AU"
            },
            {
                "name": "Yanchep",
                "countryCode": "AU"
            },
            {
                "name": "Yangebup",
                "countryCode": "AU"
            },
            {
                "name": "Yilgarn",
                "countryCode": "AU"
            },
            {
                "name": "Yokine",
                "countryCode": "AU"
            },
            {
                "name": "York",
                "countryCode": "AU"
            }
        ]
    },
    {
        "name": "Austria",
        "phonecode": "43",
        "cities": [
            {
                "name": "Andau",
                "countryCode": "AT"
            },
            {
                "name": "Antau",
                "countryCode": "AT"
            },
            {
                "name": "Apetlon",
                "countryCode": "AT"
            },
            {
                "name": "Bad Sauerbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Bad Tatzmannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Badersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Bernstein",
                "countryCode": "AT"
            },
            {
                "name": "Bocksdorf",
                "countryCode": "AT"
            },
            {
                "name": "Breitenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Bruckneudorf",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch Jahrndorf",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch Kaltenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Deutschkreutz",
                "countryCode": "AT"
            },
            {
                "name": "Donnerskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Drassburg",
                "countryCode": "AT"
            },
            {
                "name": "Eberau",
                "countryCode": "AT"
            },
            {
                "name": "Edelstal",
                "countryCode": "AT"
            },
            {
                "name": "Eisenstadt",
                "countryCode": "AT"
            },
            {
                "name": "Eisenstadt Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Eisenstadt-Umgebung",
                "countryCode": "AT"
            },
            {
                "name": "Eltendorf",
                "countryCode": "AT"
            },
            {
                "name": "Forchtenstein",
                "countryCode": "AT"
            },
            {
                "name": "Frauenkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Gattendorf",
                "countryCode": "AT"
            },
            {
                "name": "Gols",
                "countryCode": "AT"
            },
            {
                "name": "Grafenschachen",
                "countryCode": "AT"
            },
            {
                "name": "Grosshöflein",
                "countryCode": "AT"
            },
            {
                "name": "Grosspetersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Güssing",
                "countryCode": "AT"
            },
            {
                "name": "Güttenbach",
                "countryCode": "AT"
            },
            {
                "name": "Hackerberg",
                "countryCode": "AT"
            },
            {
                "name": "Halbturn",
                "countryCode": "AT"
            },
            {
                "name": "Hannersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Heiligenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Heiligenkreuz im Lafnitztal",
                "countryCode": "AT"
            },
            {
                "name": "Heugraben",
                "countryCode": "AT"
            },
            {
                "name": "Hirm",
                "countryCode": "AT"
            },
            {
                "name": "Horitschon",
                "countryCode": "AT"
            },
            {
                "name": "Hornstein",
                "countryCode": "AT"
            },
            {
                "name": "Illmitz",
                "countryCode": "AT"
            },
            {
                "name": "Inzenhof",
                "countryCode": "AT"
            },
            {
                "name": "Jabing",
                "countryCode": "AT"
            },
            {
                "name": "Jennersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Jois",
                "countryCode": "AT"
            },
            {
                "name": "Kaisersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kemeten",
                "countryCode": "AT"
            },
            {
                "name": "Kittsee",
                "countryCode": "AT"
            },
            {
                "name": "Kleinhöflein im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Kleinmürbisch",
                "countryCode": "AT"
            },
            {
                "name": "Klingenbach",
                "countryCode": "AT"
            },
            {
                "name": "Kobersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kohfidisch",
                "countryCode": "AT"
            },
            {
                "name": "Krensdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kukmirn",
                "countryCode": "AT"
            },
            {
                "name": "Königsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lackenbach",
                "countryCode": "AT"
            },
            {
                "name": "Lackendorf",
                "countryCode": "AT"
            },
            {
                "name": "Leithaprodersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Litzelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lockenhaus",
                "countryCode": "AT"
            },
            {
                "name": "Loipersbach im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Loretto",
                "countryCode": "AT"
            },
            {
                "name": "Lutzmannsburg",
                "countryCode": "AT"
            },
            {
                "name": "Mannersdorf an der Rabnitz",
                "countryCode": "AT"
            },
            {
                "name": "Mariasdorf",
                "countryCode": "AT"
            },
            {
                "name": "Markt Allhau",
                "countryCode": "AT"
            },
            {
                "name": "Markt Neuhodis",
                "countryCode": "AT"
            },
            {
                "name": "Markt Sankt Martin",
                "countryCode": "AT"
            },
            {
                "name": "Marz",
                "countryCode": "AT"
            },
            {
                "name": "Mattersburg",
                "countryCode": "AT"
            },
            {
                "name": "Mischendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mitterpullendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mogersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Moschendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mönchhof",
                "countryCode": "AT"
            },
            {
                "name": "Mörbisch am See",
                "countryCode": "AT"
            },
            {
                "name": "Mühlgraben",
                "countryCode": "AT"
            },
            {
                "name": "Müllendorf",
                "countryCode": "AT"
            },
            {
                "name": "Neckenmarkt",
                "countryCode": "AT"
            },
            {
                "name": "Neuberg im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Neudörfl",
                "countryCode": "AT"
            },
            {
                "name": "Neufeld an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Neuhaus am Klausenbach",
                "countryCode": "AT"
            },
            {
                "name": "Neusiedl am See",
                "countryCode": "AT"
            },
            {
                "name": "Neustift an der Lafnitz",
                "countryCode": "AT"
            },
            {
                "name": "Neustift bei Güssing",
                "countryCode": "AT"
            },
            {
                "name": "Neutal",
                "countryCode": "AT"
            },
            {
                "name": "Nickelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Nikitsch",
                "countryCode": "AT"
            },
            {
                "name": "Oberdorf im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Oberloisdorf",
                "countryCode": "AT"
            },
            {
                "name": "Oberpullendorf",
                "countryCode": "AT"
            },
            {
                "name": "Oberschützen",
                "countryCode": "AT"
            },
            {
                "name": "Oberwart",
                "countryCode": "AT"
            },
            {
                "name": "Oggau",
                "countryCode": "AT"
            },
            {
                "name": "Olbendorf",
                "countryCode": "AT"
            },
            {
                "name": "Ollersdorf im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Oslip",
                "countryCode": "AT"
            },
            {
                "name": "Pama",
                "countryCode": "AT"
            },
            {
                "name": "Pamhagen",
                "countryCode": "AT"
            },
            {
                "name": "Parndorf",
                "countryCode": "AT"
            },
            {
                "name": "Pilgersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pinkafeld",
                "countryCode": "AT"
            },
            {
                "name": "Piringsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Podersdorf am See",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Güssing",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Jennersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Mattersburg",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Neusiedl am See",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Oberpullendorf",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Oberwart",
                "countryCode": "AT"
            },
            {
                "name": "Potzneusiedl",
                "countryCode": "AT"
            },
            {
                "name": "Purbach am Neusiedler See",
                "countryCode": "AT"
            },
            {
                "name": "Pöttelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pöttsching",
                "countryCode": "AT"
            },
            {
                "name": "Raiding",
                "countryCode": "AT"
            },
            {
                "name": "Rechnitz",
                "countryCode": "AT"
            },
            {
                "name": "Riedlingsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Ritzing",
                "countryCode": "AT"
            },
            {
                "name": "Rohr im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Rohrbach bei Mattersburg",
                "countryCode": "AT"
            },
            {
                "name": "Rotenturm an der Pinka",
                "countryCode": "AT"
            },
            {
                "name": "Rudersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Rust",
                "countryCode": "AT"
            },
            {
                "name": "Rust Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Andrä am Zicksee",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen am Leithagebirge",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margarethen im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Michael im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Schachendorf",
                "countryCode": "AT"
            },
            {
                "name": "Schandorf",
                "countryCode": "AT"
            },
            {
                "name": "Schattendorf",
                "countryCode": "AT"
            },
            {
                "name": "Schützen am Gebirge",
                "countryCode": "AT"
            },
            {
                "name": "Siegendorf im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Sieggraben",
                "countryCode": "AT"
            },
            {
                "name": "Sigless",
                "countryCode": "AT"
            },
            {
                "name": "Stadtschlaining",
                "countryCode": "AT"
            },
            {
                "name": "Stegersbach",
                "countryCode": "AT"
            },
            {
                "name": "Steinbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Stinatz",
                "countryCode": "AT"
            },
            {
                "name": "Stoob",
                "countryCode": "AT"
            },
            {
                "name": "Stotzing",
                "countryCode": "AT"
            },
            {
                "name": "Strem",
                "countryCode": "AT"
            },
            {
                "name": "Tadten",
                "countryCode": "AT"
            },
            {
                "name": "Tobaj",
                "countryCode": "AT"
            },
            {
                "name": "Trausdorf an der Wulka",
                "countryCode": "AT"
            },
            {
                "name": "Tschanigraben",
                "countryCode": "AT"
            },
            {
                "name": "Unterfrauenhaid",
                "countryCode": "AT"
            },
            {
                "name": "Unterkohlstätten",
                "countryCode": "AT"
            },
            {
                "name": "Unterwart",
                "countryCode": "AT"
            },
            {
                "name": "Wallern im Burgenland",
                "countryCode": "AT"
            },
            {
                "name": "Weichselbaum",
                "countryCode": "AT"
            },
            {
                "name": "Weiden am See",
                "countryCode": "AT"
            },
            {
                "name": "Weiden bei Rechnitz",
                "countryCode": "AT"
            },
            {
                "name": "Weingraben",
                "countryCode": "AT"
            },
            {
                "name": "Weppersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wiesen",
                "countryCode": "AT"
            },
            {
                "name": "Wiesfleck",
                "countryCode": "AT"
            },
            {
                "name": "Wimpassing an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Winden am See",
                "countryCode": "AT"
            },
            {
                "name": "Wolfau",
                "countryCode": "AT"
            },
            {
                "name": "Wulkaprodersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wörterberg",
                "countryCode": "AT"
            },
            {
                "name": "Zagersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Zillingtal",
                "countryCode": "AT"
            },
            {
                "name": "Zurndorf",
                "countryCode": "AT"
            },
            {
                "name": "Albeck",
                "countryCode": "AT"
            },
            {
                "name": "Althofen",
                "countryCode": "AT"
            },
            {
                "name": "Annabichl",
                "countryCode": "AT"
            },
            {
                "name": "Arnoldstein",
                "countryCode": "AT"
            },
            {
                "name": "Arriach",
                "countryCode": "AT"
            },
            {
                "name": "Auen",
                "countryCode": "AT"
            },
            {
                "name": "Bad Bleiberg",
                "countryCode": "AT"
            },
            {
                "name": "Bad Eisenkappel",
                "countryCode": "AT"
            },
            {
                "name": "Bad Kleinkirchheim",
                "countryCode": "AT"
            },
            {
                "name": "Bad Sankt Leonhard im Lavanttal",
                "countryCode": "AT"
            },
            {
                "name": "Baldramsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Berg im Drautal",
                "countryCode": "AT"
            },
            {
                "name": "Bleiburg",
                "countryCode": "AT"
            },
            {
                "name": "Bodensdorf",
                "countryCode": "AT"
            },
            {
                "name": "Brückl",
                "countryCode": "AT"
            },
            {
                "name": "Dellach",
                "countryCode": "AT"
            },
            {
                "name": "Dellach im Drautal",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch Griffen",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch-Griffen",
                "countryCode": "AT"
            },
            {
                "name": "Diex",
                "countryCode": "AT"
            },
            {
                "name": "Draschen",
                "countryCode": "AT"
            },
            {
                "name": "Döbriach",
                "countryCode": "AT"
            },
            {
                "name": "Ebenthal",
                "countryCode": "AT"
            },
            {
                "name": "Eberndorf",
                "countryCode": "AT"
            },
            {
                "name": "Eberstein",
                "countryCode": "AT"
            },
            {
                "name": "Ebriach",
                "countryCode": "AT"
            },
            {
                "name": "Eisenkappel-Vellach",
                "countryCode": "AT"
            },
            {
                "name": "Faak am See",
                "countryCode": "AT"
            },
            {
                "name": "Feffernitz",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz an der Drau",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz an der Gail",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz im Rosental",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz ob Bleiburg",
                "countryCode": "AT"
            },
            {
                "name": "Feld am See",
                "countryCode": "AT"
            },
            {
                "name": "Feldkirchen District",
                "countryCode": "AT"
            },
            {
                "name": "Feldkirchen in Kärnten",
                "countryCode": "AT"
            },
            {
                "name": "Ferlach",
                "countryCode": "AT"
            },
            {
                "name": "Ferndorf",
                "countryCode": "AT"
            },
            {
                "name": "Finkenstein",
                "countryCode": "AT"
            },
            {
                "name": "Finkenstein am Faaker See",
                "countryCode": "AT"
            },
            {
                "name": "Flattach",
                "countryCode": "AT"
            },
            {
                "name": "Frantschach-Sankt Gertraud",
                "countryCode": "AT"
            },
            {
                "name": "Frauenstein",
                "countryCode": "AT"
            },
            {
                "name": "Fresach",
                "countryCode": "AT"
            },
            {
                "name": "Fresen",
                "countryCode": "AT"
            },
            {
                "name": "Friesach",
                "countryCode": "AT"
            },
            {
                "name": "Föderlach I",
                "countryCode": "AT"
            },
            {
                "name": "Fürnitz",
                "countryCode": "AT"
            },
            {
                "name": "Gailitz",
                "countryCode": "AT"
            },
            {
                "name": "Gallizien",
                "countryCode": "AT"
            },
            {
                "name": "Gemmersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gerlamoos",
                "countryCode": "AT"
            },
            {
                "name": "Gitschtal",
                "countryCode": "AT"
            },
            {
                "name": "Glanegg",
                "countryCode": "AT"
            },
            {
                "name": "Globasnitz",
                "countryCode": "AT"
            },
            {
                "name": "Glödnitz",
                "countryCode": "AT"
            },
            {
                "name": "Gmünd",
                "countryCode": "AT"
            },
            {
                "name": "Gmünd in Kärnten",
                "countryCode": "AT"
            },
            {
                "name": "Gnesau",
                "countryCode": "AT"
            },
            {
                "name": "Grafenstein",
                "countryCode": "AT"
            },
            {
                "name": "Greifenburg",
                "countryCode": "AT"
            },
            {
                "name": "Gries",
                "countryCode": "AT"
            },
            {
                "name": "Griffen",
                "countryCode": "AT"
            },
            {
                "name": "Großkirchheim",
                "countryCode": "AT"
            },
            {
                "name": "Gurk",
                "countryCode": "AT"
            },
            {
                "name": "Guttaring",
                "countryCode": "AT"
            },
            {
                "name": "Görtschach",
                "countryCode": "AT"
            },
            {
                "name": "Heiligenblut",
                "countryCode": "AT"
            },
            {
                "name": "Hermagor",
                "countryCode": "AT"
            },
            {
                "name": "Hermagor District",
                "countryCode": "AT"
            },
            {
                "name": "Hermagor-Pressegger See",
                "countryCode": "AT"
            },
            {
                "name": "Himmelberg",
                "countryCode": "AT"
            },
            {
                "name": "Hohenthurn",
                "countryCode": "AT"
            },
            {
                "name": "Hörtendorf",
                "countryCode": "AT"
            },
            {
                "name": "Hüttenberg",
                "countryCode": "AT"
            },
            {
                "name": "Irschen",
                "countryCode": "AT"
            },
            {
                "name": "Kappel am Krappfeld",
                "countryCode": "AT"
            },
            {
                "name": "Keutschach am See",
                "countryCode": "AT"
            },
            {
                "name": "Kirchbach",
                "countryCode": "AT"
            },
            {
                "name": "Klagenfurt",
                "countryCode": "AT"
            },
            {
                "name": "Klagenfurt am Wörthersee",
                "countryCode": "AT"
            },
            {
                "name": "Klagenfurt-Land District",
                "countryCode": "AT"
            },
            {
                "name": "Kleblach-Lind",
                "countryCode": "AT"
            },
            {
                "name": "Klein Sankt Paul",
                "countryCode": "AT"
            },
            {
                "name": "Kleinedling",
                "countryCode": "AT"
            },
            {
                "name": "Krems",
                "countryCode": "AT"
            },
            {
                "name": "Krumpendorf",
                "countryCode": "AT"
            },
            {
                "name": "Kötschach",
                "countryCode": "AT"
            },
            {
                "name": "Kötschach-Mauthen",
                "countryCode": "AT"
            },
            {
                "name": "Köttmannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kühnsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lamnitz",
                "countryCode": "AT"
            },
            {
                "name": "Landskron",
                "countryCode": "AT"
            },
            {
                "name": "Launsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lavamünd",
                "countryCode": "AT"
            },
            {
                "name": "Ledenitzen",
                "countryCode": "AT"
            },
            {
                "name": "Lendorf",
                "countryCode": "AT"
            },
            {
                "name": "Leppen",
                "countryCode": "AT"
            },
            {
                "name": "Lesachtal",
                "countryCode": "AT"
            },
            {
                "name": "Liebenfels",
                "countryCode": "AT"
            },
            {
                "name": "Lind",
                "countryCode": "AT"
            },
            {
                "name": "Lobersberg",
                "countryCode": "AT"
            },
            {
                "name": "Ludmannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lurnfeld",
                "countryCode": "AT"
            },
            {
                "name": "Magdalensberg",
                "countryCode": "AT"
            },
            {
                "name": "Mallnitz",
                "countryCode": "AT"
            },
            {
                "name": "Malta",
                "countryCode": "AT"
            },
            {
                "name": "Maria Rain",
                "countryCode": "AT"
            },
            {
                "name": "Maria Saal",
                "countryCode": "AT"
            },
            {
                "name": "Maria Wörth",
                "countryCode": "AT"
            },
            {
                "name": "Mautbrücken",
                "countryCode": "AT"
            },
            {
                "name": "Metnitz",
                "countryCode": "AT"
            },
            {
                "name": "Micheldorf",
                "countryCode": "AT"
            },
            {
                "name": "Millstatt",
                "countryCode": "AT"
            },
            {
                "name": "Moosburg",
                "countryCode": "AT"
            },
            {
                "name": "Mölbling",
                "countryCode": "AT"
            },
            {
                "name": "Möllbrücke",
                "countryCode": "AT"
            },
            {
                "name": "Mörtschach",
                "countryCode": "AT"
            },
            {
                "name": "Mühldorf",
                "countryCode": "AT"
            },
            {
                "name": "Neu-Feffernitz",
                "countryCode": "AT"
            },
            {
                "name": "Neuhaus",
                "countryCode": "AT"
            },
            {
                "name": "Niederdorf",
                "countryCode": "AT"
            },
            {
                "name": "Nötsch im Gailtal",
                "countryCode": "AT"
            },
            {
                "name": "Oberdrauburg",
                "countryCode": "AT"
            },
            {
                "name": "Oberdörfl",
                "countryCode": "AT"
            },
            {
                "name": "Obermillstatt",
                "countryCode": "AT"
            },
            {
                "name": "Obervellach",
                "countryCode": "AT"
            },
            {
                "name": "Ossiach",
                "countryCode": "AT"
            },
            {
                "name": "Paternion",
                "countryCode": "AT"
            },
            {
                "name": "Pischeldorf",
                "countryCode": "AT"
            },
            {
                "name": "Poggersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Preitenegg",
                "countryCode": "AT"
            },
            {
                "name": "Priel",
                "countryCode": "AT"
            },
            {
                "name": "Pusarnitz",
                "countryCode": "AT"
            },
            {
                "name": "Pöckau",
                "countryCode": "AT"
            },
            {
                "name": "Pörtschach am Wörthersee",
                "countryCode": "AT"
            },
            {
                "name": "Radenthein",
                "countryCode": "AT"
            },
            {
                "name": "Rain",
                "countryCode": "AT"
            },
            {
                "name": "Rangersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Reding",
                "countryCode": "AT"
            },
            {
                "name": "Reichenau",
                "countryCode": "AT"
            },
            {
                "name": "Reichenfels",
                "countryCode": "AT"
            },
            {
                "name": "Reißeck",
                "countryCode": "AT"
            },
            {
                "name": "Rennweg am Katschberg",
                "countryCode": "AT"
            },
            {
                "name": "Rittersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Rosegg",
                "countryCode": "AT"
            },
            {
                "name": "Ruden",
                "countryCode": "AT"
            },
            {
                "name": "Sachsenburg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Andrä",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Egyden an der Drau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen am Längsee",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen im Lavanttal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Jakob",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Jakob im Rosental",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Kanzian am Klopeiner See",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Leonhard",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Magdalen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margareten im Rosental",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Paul im Lavanttal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ruprecht",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Stefan",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Stefan im Gailtal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Urban",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit an der Glan",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit an der Glan District",
                "countryCode": "AT"
            },
            {
                "name": "Schiefling am See",
                "countryCode": "AT"
            },
            {
                "name": "Schleben",
                "countryCode": "AT"
            },
            {
                "name": "Seebach",
                "countryCode": "AT"
            },
            {
                "name": "Seeboden",
                "countryCode": "AT"
            },
            {
                "name": "Seitenberg",
                "countryCode": "AT"
            },
            {
                "name": "Sirnitz",
                "countryCode": "AT"
            },
            {
                "name": "Sittersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Spittal an der Drau",
                "countryCode": "AT"
            },
            {
                "name": "Spittal an der Drau District",
                "countryCode": "AT"
            },
            {
                "name": "St. Agathen und Perau",
                "countryCode": "AT"
            },
            {
                "name": "St. Andrä",
                "countryCode": "AT"
            },
            {
                "name": "St. Margareten im Rosental",
                "countryCode": "AT"
            },
            {
                "name": "St. Paul im Lavanttal",
                "countryCode": "AT"
            },
            {
                "name": "St. Stefan im Gailtal",
                "countryCode": "AT"
            },
            {
                "name": "St. Urban",
                "countryCode": "AT"
            },
            {
                "name": "St. Veit an der Glan",
                "countryCode": "AT"
            },
            {
                "name": "St. Veiter Vorstadt",
                "countryCode": "AT"
            },
            {
                "name": "Stall",
                "countryCode": "AT"
            },
            {
                "name": "Steindorf am Ossiacher See",
                "countryCode": "AT"
            },
            {
                "name": "Steinfeld",
                "countryCode": "AT"
            },
            {
                "name": "Steuerberg",
                "countryCode": "AT"
            },
            {
                "name": "Stockenboi",
                "countryCode": "AT"
            },
            {
                "name": "Straßburg",
                "countryCode": "AT"
            },
            {
                "name": "Straßburg-Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Suetschach",
                "countryCode": "AT"
            },
            {
                "name": "Techelsberg",
                "countryCode": "AT"
            },
            {
                "name": "Timenitz",
                "countryCode": "AT"
            },
            {
                "name": "Trebesing",
                "countryCode": "AT"
            },
            {
                "name": "Treffen",
                "countryCode": "AT"
            },
            {
                "name": "Treibach",
                "countryCode": "AT"
            },
            {
                "name": "Tschachoritsch",
                "countryCode": "AT"
            },
            {
                "name": "Umberg",
                "countryCode": "AT"
            },
            {
                "name": "Untere Fellach",
                "countryCode": "AT"
            },
            {
                "name": "Velden am Wörther See",
                "countryCode": "AT"
            },
            {
                "name": "Velden am Wörthersee",
                "countryCode": "AT"
            },
            {
                "name": "Viktring",
                "countryCode": "AT"
            },
            {
                "name": "Viktringer Vorstadt",
                "countryCode": "AT"
            },
            {
                "name": "Villach",
                "countryCode": "AT"
            },
            {
                "name": "Villach-Innere Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Villach-Land District",
                "countryCode": "AT"
            },
            {
                "name": "Villacher Vorstadt",
                "countryCode": "AT"
            },
            {
                "name": "Völkendorf",
                "countryCode": "AT"
            },
            {
                "name": "Völkermarkt",
                "countryCode": "AT"
            },
            {
                "name": "Völkermarkt District",
                "countryCode": "AT"
            },
            {
                "name": "Völkermarkter Vorstadt",
                "countryCode": "AT"
            },
            {
                "name": "Waiern",
                "countryCode": "AT"
            },
            {
                "name": "Warmbad-Judendorf",
                "countryCode": "AT"
            },
            {
                "name": "Weitensfeld",
                "countryCode": "AT"
            },
            {
                "name": "Weitensfeld im Gurktal",
                "countryCode": "AT"
            },
            {
                "name": "Weißensee",
                "countryCode": "AT"
            },
            {
                "name": "Weißenstein",
                "countryCode": "AT"
            },
            {
                "name": "Wernberg",
                "countryCode": "AT"
            },
            {
                "name": "Winklern",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsberg",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsberg District",
                "countryCode": "AT"
            },
            {
                "name": "Wudmath",
                "countryCode": "AT"
            },
            {
                "name": "Wölfnitz",
                "countryCode": "AT"
            },
            {
                "name": "Zell",
                "countryCode": "AT"
            },
            {
                "name": "Absdorf",
                "countryCode": "AT"
            },
            {
                "name": "Achau",
                "countryCode": "AT"
            },
            {
                "name": "Aderklaa",
                "countryCode": "AT"
            },
            {
                "name": "Aggsbach",
                "countryCode": "AT"
            },
            {
                "name": "Alland",
                "countryCode": "AT"
            },
            {
                "name": "Allentsteig",
                "countryCode": "AT"
            },
            {
                "name": "Allhartsberg",
                "countryCode": "AT"
            },
            {
                "name": "Altenburg",
                "countryCode": "AT"
            },
            {
                "name": "Altendorf",
                "countryCode": "AT"
            },
            {
                "name": "Altenmarkt an der Triesting",
                "countryCode": "AT"
            },
            {
                "name": "Altlengbach",
                "countryCode": "AT"
            },
            {
                "name": "Altlichtenwarth",
                "countryCode": "AT"
            },
            {
                "name": "Altmelon",
                "countryCode": "AT"
            },
            {
                "name": "Amstetten",
                "countryCode": "AT"
            },
            {
                "name": "Andlersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Angern an der March",
                "countryCode": "AT"
            },
            {
                "name": "Arbesbach",
                "countryCode": "AT"
            },
            {
                "name": "Ardagger Stift",
                "countryCode": "AT"
            },
            {
                "name": "Aschbach Markt",
                "countryCode": "AT"
            },
            {
                "name": "Aspang Markt",
                "countryCode": "AT"
            },
            {
                "name": "Asparn an der Zaya",
                "countryCode": "AT"
            },
            {
                "name": "Asperhofen",
                "countryCode": "AT"
            },
            {
                "name": "Atzenbrugg",
                "countryCode": "AT"
            },
            {
                "name": "Au am Leithaberge",
                "countryCode": "AT"
            },
            {
                "name": "Auersthal",
                "countryCode": "AT"
            },
            {
                "name": "Bad Deutsch-Altenburg",
                "countryCode": "AT"
            },
            {
                "name": "Bad Erlach",
                "countryCode": "AT"
            },
            {
                "name": "Bad Fischau",
                "countryCode": "AT"
            },
            {
                "name": "Bad Pirawarth",
                "countryCode": "AT"
            },
            {
                "name": "Bad Schönau",
                "countryCode": "AT"
            },
            {
                "name": "Bad Traunstein",
                "countryCode": "AT"
            },
            {
                "name": "Bad Vöslau",
                "countryCode": "AT"
            },
            {
                "name": "Baden",
                "countryCode": "AT"
            },
            {
                "name": "Behamberg",
                "countryCode": "AT"
            },
            {
                "name": "Berg",
                "countryCode": "AT"
            },
            {
                "name": "Bergland",
                "countryCode": "AT"
            },
            {
                "name": "Berndorf",
                "countryCode": "AT"
            },
            {
                "name": "Bernhardsthal",
                "countryCode": "AT"
            },
            {
                "name": "Biberbach",
                "countryCode": "AT"
            },
            {
                "name": "Biedermannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Bisamberg",
                "countryCode": "AT"
            },
            {
                "name": "Bischofstetten",
                "countryCode": "AT"
            },
            {
                "name": "Blindenmarkt",
                "countryCode": "AT"
            },
            {
                "name": "Bockfließ",
                "countryCode": "AT"
            },
            {
                "name": "Breitenau",
                "countryCode": "AT"
            },
            {
                "name": "Breitenfurt bei Wien",
                "countryCode": "AT"
            },
            {
                "name": "Bromberg",
                "countryCode": "AT"
            },
            {
                "name": "Bruck an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Brunn am Gebirge",
                "countryCode": "AT"
            },
            {
                "name": "Brunn an der Schneebergbahn",
                "countryCode": "AT"
            },
            {
                "name": "Brunn an der Wild",
                "countryCode": "AT"
            },
            {
                "name": "Buchbach",
                "countryCode": "AT"
            },
            {
                "name": "Bärnkopf",
                "countryCode": "AT"
            },
            {
                "name": "Böheimkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch-Wagram",
                "countryCode": "AT"
            },
            {
                "name": "Dobersberg",
                "countryCode": "AT"
            },
            {
                "name": "Dorfstetten",
                "countryCode": "AT"
            },
            {
                "name": "Drasenhofen",
                "countryCode": "AT"
            },
            {
                "name": "Droß",
                "countryCode": "AT"
            },
            {
                "name": "Drösing",
                "countryCode": "AT"
            },
            {
                "name": "Dürnkrut",
                "countryCode": "AT"
            },
            {
                "name": "Dürnstein",
                "countryCode": "AT"
            },
            {
                "name": "Ebenfurth",
                "countryCode": "AT"
            },
            {
                "name": "Ebenthal",
                "countryCode": "AT"
            },
            {
                "name": "Ebergassing",
                "countryCode": "AT"
            },
            {
                "name": "Ebreichsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Echsenbach",
                "countryCode": "AT"
            },
            {
                "name": "Eckartsau",
                "countryCode": "AT"
            },
            {
                "name": "Edlitz",
                "countryCode": "AT"
            },
            {
                "name": "Eggenburg",
                "countryCode": "AT"
            },
            {
                "name": "Eggendorf",
                "countryCode": "AT"
            },
            {
                "name": "Eggern",
                "countryCode": "AT"
            },
            {
                "name": "Eichgraben",
                "countryCode": "AT"
            },
            {
                "name": "Eisgarn",
                "countryCode": "AT"
            },
            {
                "name": "Emmersdorf an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Engelhartstetten",
                "countryCode": "AT"
            },
            {
                "name": "Ennsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Enzenreith",
                "countryCode": "AT"
            },
            {
                "name": "Enzersdorf an der Fischa",
                "countryCode": "AT"
            },
            {
                "name": "Enzersfeld",
                "countryCode": "AT"
            },
            {
                "name": "Erlauf",
                "countryCode": "AT"
            },
            {
                "name": "Ernstbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Ernsthofen",
                "countryCode": "AT"
            },
            {
                "name": "Erpersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Ertl",
                "countryCode": "AT"
            },
            {
                "name": "Eschenau",
                "countryCode": "AT"
            },
            {
                "name": "Euratsfeld",
                "countryCode": "AT"
            },
            {
                "name": "Falkenstein",
                "countryCode": "AT"
            },
            {
                "name": "Fallbach",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz am Wechsel",
                "countryCode": "AT"
            },
            {
                "name": "Felixdorf",
                "countryCode": "AT"
            },
            {
                "name": "Fels am Wagram",
                "countryCode": "AT"
            },
            {
                "name": "Ferschnitz",
                "countryCode": "AT"
            },
            {
                "name": "Fischamend-Markt",
                "countryCode": "AT"
            },
            {
                "name": "Frankenfels",
                "countryCode": "AT"
            },
            {
                "name": "Frohsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Furth an der Triesting",
                "countryCode": "AT"
            },
            {
                "name": "Furth bei Göttweig",
                "countryCode": "AT"
            },
            {
                "name": "Gaaden",
                "countryCode": "AT"
            },
            {
                "name": "Gablitz",
                "countryCode": "AT"
            },
            {
                "name": "Gainfarn",
                "countryCode": "AT"
            },
            {
                "name": "Gaming",
                "countryCode": "AT"
            },
            {
                "name": "Gars am Kamp",
                "countryCode": "AT"
            },
            {
                "name": "Gastern",
                "countryCode": "AT"
            },
            {
                "name": "Gaubitsch",
                "countryCode": "AT"
            },
            {
                "name": "Gaweinstal",
                "countryCode": "AT"
            },
            {
                "name": "Gedersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Geras",
                "countryCode": "AT"
            },
            {
                "name": "Gerasdorf bei Wien",
                "countryCode": "AT"
            },
            {
                "name": "Gerersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gerolding",
                "countryCode": "AT"
            },
            {
                "name": "Gföhl",
                "countryCode": "AT"
            },
            {
                "name": "Gießhübl",
                "countryCode": "AT"
            },
            {
                "name": "Glinzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Gloggnitz",
                "countryCode": "AT"
            },
            {
                "name": "Gmünd",
                "countryCode": "AT"
            },
            {
                "name": "Gnadendorf",
                "countryCode": "AT"
            },
            {
                "name": "Gneixendorf",
                "countryCode": "AT"
            },
            {
                "name": "Grafenegg",
                "countryCode": "AT"
            },
            {
                "name": "Grafenschlag",
                "countryCode": "AT"
            },
            {
                "name": "Grafenwörth",
                "countryCode": "AT"
            },
            {
                "name": "Gramatneusiedl",
                "countryCode": "AT"
            },
            {
                "name": "Greinsfurth",
                "countryCode": "AT"
            },
            {
                "name": "Gresten",
                "countryCode": "AT"
            },
            {
                "name": "Grimmenstein",
                "countryCode": "AT"
            },
            {
                "name": "Groß-Engersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Groß-Enzersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Groß-Gerungs",
                "countryCode": "AT"
            },
            {
                "name": "Groß-Schweinbarth",
                "countryCode": "AT"
            },
            {
                "name": "Groß-Siegharts",
                "countryCode": "AT"
            },
            {
                "name": "Großebersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Großharras",
                "countryCode": "AT"
            },
            {
                "name": "Großhofen",
                "countryCode": "AT"
            },
            {
                "name": "Großkrut",
                "countryCode": "AT"
            },
            {
                "name": "Großmugl",
                "countryCode": "AT"
            },
            {
                "name": "Großriedenthal",
                "countryCode": "AT"
            },
            {
                "name": "Großrußbach",
                "countryCode": "AT"
            },
            {
                "name": "Großweikersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Grünbach am Schneeberg",
                "countryCode": "AT"
            },
            {
                "name": "Gugging",
                "countryCode": "AT"
            },
            {
                "name": "Gumpoldskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Guntersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Guntramsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gutenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Gutenstein",
                "countryCode": "AT"
            },
            {
                "name": "Gänserndorf",
                "countryCode": "AT"
            },
            {
                "name": "Göllersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Göpfritz an der Wild",
                "countryCode": "AT"
            },
            {
                "name": "Göstling an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Götzendorf an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Günselsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Haag",
                "countryCode": "AT"
            },
            {
                "name": "Hadersdorf am Kamp",
                "countryCode": "AT"
            },
            {
                "name": "Hadres",
                "countryCode": "AT"
            },
            {
                "name": "Hafnerbach",
                "countryCode": "AT"
            },
            {
                "name": "Hagenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Haidershofen",
                "countryCode": "AT"
            },
            {
                "name": "Hainburg an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Hainfeld",
                "countryCode": "AT"
            },
            {
                "name": "Hardegg",
                "countryCode": "AT"
            },
            {
                "name": "Haringsee",
                "countryCode": "AT"
            },
            {
                "name": "Harland",
                "countryCode": "AT"
            },
            {
                "name": "Harmannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hart",
                "countryCode": "AT"
            },
            {
                "name": "Haugschlag",
                "countryCode": "AT"
            },
            {
                "name": "Haugsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Haunoldstein",
                "countryCode": "AT"
            },
            {
                "name": "Hausbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Hauskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Hausleiten",
                "countryCode": "AT"
            },
            {
                "name": "Hausmening",
                "countryCode": "AT"
            },
            {
                "name": "Heidenreichstein",
                "countryCode": "AT"
            },
            {
                "name": "Heiligeneich",
                "countryCode": "AT"
            },
            {
                "name": "Heiligenkreuz",
                "countryCode": "AT"
            },
            {
                "name": "Hennersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hernstein",
                "countryCode": "AT"
            },
            {
                "name": "Herrnbaumgarten",
                "countryCode": "AT"
            },
            {
                "name": "Herzogenburg",
                "countryCode": "AT"
            },
            {
                "name": "Himberg",
                "countryCode": "AT"
            },
            {
                "name": "Hinterbrühl",
                "countryCode": "AT"
            },
            {
                "name": "Hinterleiten",
                "countryCode": "AT"
            },
            {
                "name": "Hirschbach",
                "countryCode": "AT"
            },
            {
                "name": "Hirtenberg",
                "countryCode": "AT"
            },
            {
                "name": "Hochleithen",
                "countryCode": "AT"
            },
            {
                "name": "Hochwolkersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hof am Leithaberge",
                "countryCode": "AT"
            },
            {
                "name": "Hofamt Priel",
                "countryCode": "AT"
            },
            {
                "name": "Hofstetten",
                "countryCode": "AT"
            },
            {
                "name": "Hohenau",
                "countryCode": "AT"
            },
            {
                "name": "Hohenberg",
                "countryCode": "AT"
            },
            {
                "name": "Hoheneich",
                "countryCode": "AT"
            },
            {
                "name": "Hohenruppersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hollabrunn",
                "countryCode": "AT"
            },
            {
                "name": "Hollenstein an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Hollenthon",
                "countryCode": "AT"
            },
            {
                "name": "Horn",
                "countryCode": "AT"
            },
            {
                "name": "Hundsheim",
                "countryCode": "AT"
            },
            {
                "name": "Hutten",
                "countryCode": "AT"
            },
            {
                "name": "Höflein",
                "countryCode": "AT"
            },
            {
                "name": "Höfling",
                "countryCode": "AT"
            },
            {
                "name": "Hürm",
                "countryCode": "AT"
            },
            {
                "name": "Jaidhof",
                "countryCode": "AT"
            },
            {
                "name": "Japons",
                "countryCode": "AT"
            },
            {
                "name": "Jedenspeigen",
                "countryCode": "AT"
            },
            {
                "name": "Kaltenleutgeben",
                "countryCode": "AT"
            },
            {
                "name": "Kapelln",
                "countryCode": "AT"
            },
            {
                "name": "Karlstetten",
                "countryCode": "AT"
            },
            {
                "name": "Kasten bei Böheimkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Katzelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kaumberg",
                "countryCode": "AT"
            },
            {
                "name": "Kautzen",
                "countryCode": "AT"
            },
            {
                "name": "Kematen an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Kierling",
                "countryCode": "AT"
            },
            {
                "name": "Kilb",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg am Wagram",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg am Walde",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg am Wechsel",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg an der Pielach",
                "countryCode": "AT"
            },
            {
                "name": "Kirchschlag",
                "countryCode": "AT"
            },
            {
                "name": "Kirchschlag in der Buckligen Welt",
                "countryCode": "AT"
            },
            {
                "name": "Kirchstetten",
                "countryCode": "AT"
            },
            {
                "name": "Kirnberg an der Mank",
                "countryCode": "AT"
            },
            {
                "name": "Klausen-Leopoldsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Klein-Neusiedl",
                "countryCode": "AT"
            },
            {
                "name": "Klein-Pöchlarn",
                "countryCode": "AT"
            },
            {
                "name": "Kleinzell",
                "countryCode": "AT"
            },
            {
                "name": "Klosterneuburg",
                "countryCode": "AT"
            },
            {
                "name": "Korneuburg",
                "countryCode": "AT"
            },
            {
                "name": "Kottingbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Krems an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Krems an der Donau Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Kritzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Krumau am Kamp",
                "countryCode": "AT"
            },
            {
                "name": "Krumbach Markt",
                "countryCode": "AT"
            },
            {
                "name": "Krumnussbaum",
                "countryCode": "AT"
            },
            {
                "name": "Königstetten",
                "countryCode": "AT"
            },
            {
                "name": "Laa an der Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Laab im Walde",
                "countryCode": "AT"
            },
            {
                "name": "Ladendorf",
                "countryCode": "AT"
            },
            {
                "name": "Landegg",
                "countryCode": "AT"
            },
            {
                "name": "Langau",
                "countryCode": "AT"
            },
            {
                "name": "Langenlebarn-Oberaigen",
                "countryCode": "AT"
            },
            {
                "name": "Langenlois",
                "countryCode": "AT"
            },
            {
                "name": "Langenrohr",
                "countryCode": "AT"
            },
            {
                "name": "Langenzersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Langschlag",
                "countryCode": "AT"
            },
            {
                "name": "Lanzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Lanzenkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Lassee",
                "countryCode": "AT"
            },
            {
                "name": "Laxenburg",
                "countryCode": "AT"
            },
            {
                "name": "Leiben",
                "countryCode": "AT"
            },
            {
                "name": "Leitzersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lengenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Leobendorf",
                "countryCode": "AT"
            },
            {
                "name": "Leobersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Leopoldsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Leopoldsdorf im Marchfelde",
                "countryCode": "AT"
            },
            {
                "name": "Lerchenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Lichtenegg",
                "countryCode": "AT"
            },
            {
                "name": "Lichtenwörth",
                "countryCode": "AT"
            },
            {
                "name": "Lilienfeld",
                "countryCode": "AT"
            },
            {
                "name": "Litschau",
                "countryCode": "AT"
            },
            {
                "name": "Loich",
                "countryCode": "AT"
            },
            {
                "name": "Loosdorf",
                "countryCode": "AT"
            },
            {
                "name": "Lunz am See",
                "countryCode": "AT"
            },
            {
                "name": "Mailberg",
                "countryCode": "AT"
            },
            {
                "name": "Maissau",
                "countryCode": "AT"
            },
            {
                "name": "Mank",
                "countryCode": "AT"
            },
            {
                "name": "Mannersdorf am Leithagebirge",
                "countryCode": "AT"
            },
            {
                "name": "Mannsdorf an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Mannswörth",
                "countryCode": "AT"
            },
            {
                "name": "Marbach an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Marchegg",
                "countryCode": "AT"
            },
            {
                "name": "Margarethen am Moos",
                "countryCode": "AT"
            },
            {
                "name": "Maria Ellend",
                "countryCode": "AT"
            },
            {
                "name": "Maria Enzersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Maria Laach am Jauerling",
                "countryCode": "AT"
            },
            {
                "name": "Maria Lanzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Maria Taferl",
                "countryCode": "AT"
            },
            {
                "name": "Maria-Anzbach",
                "countryCode": "AT"
            },
            {
                "name": "Markersdorf an der Pielach",
                "countryCode": "AT"
            },
            {
                "name": "Markgrafneusiedl",
                "countryCode": "AT"
            },
            {
                "name": "Markt Piesting",
                "countryCode": "AT"
            },
            {
                "name": "Martinsberg",
                "countryCode": "AT"
            },
            {
                "name": "Matzen",
                "countryCode": "AT"
            },
            {
                "name": "Matzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mauer bei Amstetten",
                "countryCode": "AT"
            },
            {
                "name": "Mauerbach",
                "countryCode": "AT"
            },
            {
                "name": "Mautern",
                "countryCode": "AT"
            },
            {
                "name": "Melk",
                "countryCode": "AT"
            },
            {
                "name": "Michelhausen",
                "countryCode": "AT"
            },
            {
                "name": "Miesenbach",
                "countryCode": "AT"
            },
            {
                "name": "Mistelbach",
                "countryCode": "AT"
            },
            {
                "name": "Mitterbach am Erlaufsee",
                "countryCode": "AT"
            },
            {
                "name": "Mitterndorf an der Fischa",
                "countryCode": "AT"
            },
            {
                "name": "Moosbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Muckendorf an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Muggendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mödling",
                "countryCode": "AT"
            },
            {
                "name": "Möllersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Mönichkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Mühldorf",
                "countryCode": "AT"
            },
            {
                "name": "Münchendorf",
                "countryCode": "AT"
            },
            {
                "name": "Neidling",
                "countryCode": "AT"
            },
            {
                "name": "Neu-Guntramsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Neudorf bei Staatz",
                "countryCode": "AT"
            },
            {
                "name": "Neufurth",
                "countryCode": "AT"
            },
            {
                "name": "Neuhofen an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Neulengbach",
                "countryCode": "AT"
            },
            {
                "name": "Neumarkt an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Neunkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Neusiedl",
                "countryCode": "AT"
            },
            {
                "name": "Neusiedl an der Zaya",
                "countryCode": "AT"
            },
            {
                "name": "Niederhollabrunn",
                "countryCode": "AT"
            },
            {
                "name": "Niederleis",
                "countryCode": "AT"
            },
            {
                "name": "Nöchling",
                "countryCode": "AT"
            },
            {
                "name": "Ober-Grafendorf",
                "countryCode": "AT"
            },
            {
                "name": "Oberhausen",
                "countryCode": "AT"
            },
            {
                "name": "Oberndorf an der Melk",
                "countryCode": "AT"
            },
            {
                "name": "Oberndorf in der Ebene",
                "countryCode": "AT"
            },
            {
                "name": "Obersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Obersiebenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Oberwagram",
                "countryCode": "AT"
            },
            {
                "name": "Oberwaltersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Oberwölbling",
                "countryCode": "AT"
            },
            {
                "name": "Oehling",
                "countryCode": "AT"
            },
            {
                "name": "Oeynhausen",
                "countryCode": "AT"
            },
            {
                "name": "Opponitz",
                "countryCode": "AT"
            },
            {
                "name": "Orth an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Ottenschlag",
                "countryCode": "AT"
            },
            {
                "name": "Ottenthal",
                "countryCode": "AT"
            },
            {
                "name": "Otterthal",
                "countryCode": "AT"
            },
            {
                "name": "Parbasdorf",
                "countryCode": "AT"
            },
            {
                "name": "Paudorf",
                "countryCode": "AT"
            },
            {
                "name": "Payerbach",
                "countryCode": "AT"
            },
            {
                "name": "Penz",
                "countryCode": "AT"
            },
            {
                "name": "Perchtoldsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pernegg",
                "countryCode": "AT"
            },
            {
                "name": "Pernersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pernitz",
                "countryCode": "AT"
            },
            {
                "name": "Persenbeug",
                "countryCode": "AT"
            },
            {
                "name": "Petronell-Carnuntum",
                "countryCode": "AT"
            },
            {
                "name": "Petzenkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Pfaffstätten",
                "countryCode": "AT"
            },
            {
                "name": "Pillichsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pitten",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Amstetten",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Baden",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Bruck an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Gmünd",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Gänserndorf",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Hollabrunn",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Horn",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Korneuburg",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Krems",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Lilienfeld",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Melk",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Mistelbach",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Mödling",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Neunkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Sankt Pölten",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Scheibbs",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Tulln",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Waidhofen an der Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Wiener Neustadt",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Zwettl",
                "countryCode": "AT"
            },
            {
                "name": "Pottenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Pottendorf",
                "countryCode": "AT"
            },
            {
                "name": "Pottenstein",
                "countryCode": "AT"
            },
            {
                "name": "Pottschach",
                "countryCode": "AT"
            },
            {
                "name": "Poysdorf",
                "countryCode": "AT"
            },
            {
                "name": "Prellenkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Pressbaum",
                "countryCode": "AT"
            },
            {
                "name": "Prigglitz",
                "countryCode": "AT"
            },
            {
                "name": "Prinzersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Prottes",
                "countryCode": "AT"
            },
            {
                "name": "Puchberg am Schneeberg",
                "countryCode": "AT"
            },
            {
                "name": "Puchenstuben",
                "countryCode": "AT"
            },
            {
                "name": "Pulkau",
                "countryCode": "AT"
            },
            {
                "name": "Purgstall",
                "countryCode": "AT"
            },
            {
                "name": "Purkersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pyhra",
                "countryCode": "AT"
            },
            {
                "name": "Pöchlarn",
                "countryCode": "AT"
            },
            {
                "name": "Pöggstall",
                "countryCode": "AT"
            },
            {
                "name": "Raabs an der Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Raach am Hochgebirge",
                "countryCode": "AT"
            },
            {
                "name": "Raasdorf",
                "countryCode": "AT"
            },
            {
                "name": "Rabensburg",
                "countryCode": "AT"
            },
            {
                "name": "Rabenstein an der Pielach",
                "countryCode": "AT"
            },
            {
                "name": "Ramsau",
                "countryCode": "AT"
            },
            {
                "name": "Randegg",
                "countryCode": "AT"
            },
            {
                "name": "Rannersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Rappottenstein",
                "countryCode": "AT"
            },
            {
                "name": "Rastenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Ratzersdorf an der Traisen",
                "countryCode": "AT"
            },
            {
                "name": "Rauchenwarth",
                "countryCode": "AT"
            },
            {
                "name": "Ravelsbach",
                "countryCode": "AT"
            },
            {
                "name": "Raxendorf",
                "countryCode": "AT"
            },
            {
                "name": "Rehberg",
                "countryCode": "AT"
            },
            {
                "name": "Reichenau an der Rax",
                "countryCode": "AT"
            },
            {
                "name": "Reingers",
                "countryCode": "AT"
            },
            {
                "name": "Reinsberg",
                "countryCode": "AT"
            },
            {
                "name": "Reisenberg",
                "countryCode": "AT"
            },
            {
                "name": "Retz",
                "countryCode": "AT"
            },
            {
                "name": "Rohr im Gebirge",
                "countryCode": "AT"
            },
            {
                "name": "Rohrau",
                "countryCode": "AT"
            },
            {
                "name": "Rohrbach an der Gölsen",
                "countryCode": "AT"
            },
            {
                "name": "Rohrendorf bei Krems",
                "countryCode": "AT"
            },
            {
                "name": "Rosenau am Sonntagberg",
                "countryCode": "AT"
            },
            {
                "name": "Ruprechtshofen",
                "countryCode": "AT"
            },
            {
                "name": "Röhrenbach",
                "countryCode": "AT"
            },
            {
                "name": "Röschitz",
                "countryCode": "AT"
            },
            {
                "name": "Sallingberg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Aegyd am Neuwalde",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Andrä vor dem Hagenthale",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Corona am Wechsel",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen am Ybbsfelde",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen an der Leys",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Leonhard am Forst",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Leonhard am Hornerwald",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margarethen an der Sierning",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin am Ybbsfelde",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Pantaleon",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter in der Au Markt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Pölten",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Pölten Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Valentin",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit an der Gölsen",
                "countryCode": "AT"
            },
            {
                "name": "Scharndorf",
                "countryCode": "AT"
            },
            {
                "name": "Scheibbs",
                "countryCode": "AT"
            },
            {
                "name": "Schleinbach",
                "countryCode": "AT"
            },
            {
                "name": "Schottwien",
                "countryCode": "AT"
            },
            {
                "name": "Schrattenbach",
                "countryCode": "AT"
            },
            {
                "name": "Schrattenberg",
                "countryCode": "AT"
            },
            {
                "name": "Schrattenthal",
                "countryCode": "AT"
            },
            {
                "name": "Schrems",
                "countryCode": "AT"
            },
            {
                "name": "Schwadorf",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzau am Steinfeld",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzau im Gebirge",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzenau",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzenbach",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzenbach an der Pielach",
                "countryCode": "AT"
            },
            {
                "name": "Schwechat",
                "countryCode": "AT"
            },
            {
                "name": "Schweiggers",
                "countryCode": "AT"
            },
            {
                "name": "Schönau an der Triesting",
                "countryCode": "AT"
            },
            {
                "name": "Schönbach",
                "countryCode": "AT"
            },
            {
                "name": "Schönkirchen-Reyersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Seebenstein",
                "countryCode": "AT"
            },
            {
                "name": "Seibersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Seitenstetten Markt",
                "countryCode": "AT"
            },
            {
                "name": "Semmering",
                "countryCode": "AT"
            },
            {
                "name": "Senftenberg",
                "countryCode": "AT"
            },
            {
                "name": "Seyring",
                "countryCode": "AT"
            },
            {
                "name": "Sieghartskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Sierndorf",
                "countryCode": "AT"
            },
            {
                "name": "Sigmundsherberg",
                "countryCode": "AT"
            },
            {
                "name": "Sitzendorf an der Schmida",
                "countryCode": "AT"
            },
            {
                "name": "Sollenau",
                "countryCode": "AT"
            },
            {
                "name": "Sommerein",
                "countryCode": "AT"
            },
            {
                "name": "Sonntagberg",
                "countryCode": "AT"
            },
            {
                "name": "Sooss",
                "countryCode": "AT"
            },
            {
                "name": "Spannberg",
                "countryCode": "AT"
            },
            {
                "name": "Spillern",
                "countryCode": "AT"
            },
            {
                "name": "Spitz",
                "countryCode": "AT"
            },
            {
                "name": "Spratzern",
                "countryCode": "AT"
            },
            {
                "name": "Staatz",
                "countryCode": "AT"
            },
            {
                "name": "Stattersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Statzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Stein an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Steinabrückl",
                "countryCode": "AT"
            },
            {
                "name": "Steinakirchen am Forst",
                "countryCode": "AT"
            },
            {
                "name": "Stephanshart",
                "countryCode": "AT"
            },
            {
                "name": "Stetteldorf am Wagram",
                "countryCode": "AT"
            },
            {
                "name": "Stetten",
                "countryCode": "AT"
            },
            {
                "name": "Stockerau",
                "countryCode": "AT"
            },
            {
                "name": "Stollhofen",
                "countryCode": "AT"
            },
            {
                "name": "Strass",
                "countryCode": "AT"
            },
            {
                "name": "Strasshof an der Nordbahn",
                "countryCode": "AT"
            },
            {
                "name": "Stratzing",
                "countryCode": "AT"
            },
            {
                "name": "Strengberg",
                "countryCode": "AT"
            },
            {
                "name": "Stronsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Stössing",
                "countryCode": "AT"
            },
            {
                "name": "Tattendorf",
                "countryCode": "AT"
            },
            {
                "name": "Teesdorf",
                "countryCode": "AT"
            },
            {
                "name": "Ternitz",
                "countryCode": "AT"
            },
            {
                "name": "Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Theresienfeld",
                "countryCode": "AT"
            },
            {
                "name": "Thomasberg",
                "countryCode": "AT"
            },
            {
                "name": "Tiefenbach",
                "countryCode": "AT"
            },
            {
                "name": "Traisen",
                "countryCode": "AT"
            },
            {
                "name": "Traiskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Traismauer",
                "countryCode": "AT"
            },
            {
                "name": "Trattenbach",
                "countryCode": "AT"
            },
            {
                "name": "Trautmannsdorf an der Leitha",
                "countryCode": "AT"
            },
            {
                "name": "Tribuswinkel",
                "countryCode": "AT"
            },
            {
                "name": "Trumau",
                "countryCode": "AT"
            },
            {
                "name": "Tulbing",
                "countryCode": "AT"
            },
            {
                "name": "Tulln",
                "countryCode": "AT"
            },
            {
                "name": "Tullnerbach-Lawies",
                "countryCode": "AT"
            },
            {
                "name": "Türnitz",
                "countryCode": "AT"
            },
            {
                "name": "Ulrichskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Untersiebenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Unterstinkenbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Unterwagram",
                "countryCode": "AT"
            },
            {
                "name": "Unterwaltersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Velm",
                "countryCode": "AT"
            },
            {
                "name": "Viehofen",
                "countryCode": "AT"
            },
            {
                "name": "Vitis",
                "countryCode": "AT"
            },
            {
                "name": "Vösendorf",
                "countryCode": "AT"
            },
            {
                "name": "Waidhofen an der Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Waidhofen an der Ybbs",
                "countryCode": "AT"
            },
            {
                "name": "Waidhofen an der Ybbs Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Waidmannsfeld",
                "countryCode": "AT"
            },
            {
                "name": "Waldegg",
                "countryCode": "AT"
            },
            {
                "name": "Waldenstein",
                "countryCode": "AT"
            },
            {
                "name": "Waldhausen",
                "countryCode": "AT"
            },
            {
                "name": "Waldkirchen an der Thaya",
                "countryCode": "AT"
            },
            {
                "name": "Wallsee",
                "countryCode": "AT"
            },
            {
                "name": "Walpersbach",
                "countryCode": "AT"
            },
            {
                "name": "Wang",
                "countryCode": "AT"
            },
            {
                "name": "Warth",
                "countryCode": "AT"
            },
            {
                "name": "Wartmannstetten",
                "countryCode": "AT"
            },
            {
                "name": "Weidling",
                "countryCode": "AT"
            },
            {
                "name": "Weigelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Weikendorf",
                "countryCode": "AT"
            },
            {
                "name": "Weikersdorf am Steinfelde",
                "countryCode": "AT"
            },
            {
                "name": "Weinburg",
                "countryCode": "AT"
            },
            {
                "name": "Weinzierl am Walde",
                "countryCode": "AT"
            },
            {
                "name": "Weinzierl bei Krems",
                "countryCode": "AT"
            },
            {
                "name": "Weissenbach an der Triesting",
                "countryCode": "AT"
            },
            {
                "name": "Weistrach",
                "countryCode": "AT"
            },
            {
                "name": "Weiten",
                "countryCode": "AT"
            },
            {
                "name": "Weitersfeld",
                "countryCode": "AT"
            },
            {
                "name": "Weitra",
                "countryCode": "AT"
            },
            {
                "name": "Weißenkirchen in der Wachau",
                "countryCode": "AT"
            },
            {
                "name": "Wiener Neudorf",
                "countryCode": "AT"
            },
            {
                "name": "Wiener Neustadt",
                "countryCode": "AT"
            },
            {
                "name": "Wiener Neustadt Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Wienersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wieselburg",
                "countryCode": "AT"
            },
            {
                "name": "Wiesmath",
                "countryCode": "AT"
            },
            {
                "name": "Wilfersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wilfleinsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wilhelmsburg",
                "countryCode": "AT"
            },
            {
                "name": "Willendorf am Steinfelde",
                "countryCode": "AT"
            },
            {
                "name": "Wimpassing im Schwarzatale",
                "countryCode": "AT"
            },
            {
                "name": "Windhag",
                "countryCode": "AT"
            },
            {
                "name": "Windigsteig",
                "countryCode": "AT"
            },
            {
                "name": "Winklarn",
                "countryCode": "AT"
            },
            {
                "name": "Winzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Wolfpassing",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsbach",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsgraben",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsthal",
                "countryCode": "AT"
            },
            {
                "name": "Wolkersdorf im Weinviertel",
                "countryCode": "AT"
            },
            {
                "name": "Wullersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wöllersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wördern",
                "countryCode": "AT"
            },
            {
                "name": "Würflach",
                "countryCode": "AT"
            },
            {
                "name": "Würmla",
                "countryCode": "AT"
            },
            {
                "name": "Ybbs an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Ybbsitz",
                "countryCode": "AT"
            },
            {
                "name": "Ysper",
                "countryCode": "AT"
            },
            {
                "name": "Zeillern",
                "countryCode": "AT"
            },
            {
                "name": "Zeiselmauer",
                "countryCode": "AT"
            },
            {
                "name": "Zell-Arzberg",
                "countryCode": "AT"
            },
            {
                "name": "Zell-Markt",
                "countryCode": "AT"
            },
            {
                "name": "Zellerndorf",
                "countryCode": "AT"
            },
            {
                "name": "Ziersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Zillingdorf",
                "countryCode": "AT"
            },
            {
                "name": "Zistersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Zwentendorf",
                "countryCode": "AT"
            },
            {
                "name": "Zwettl Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Zwölfaxing",
                "countryCode": "AT"
            },
            {
                "name": "Zöbern",
                "countryCode": "AT"
            },
            {
                "name": "Abwinden",
                "countryCode": "AT"
            },
            {
                "name": "Adlwang",
                "countryCode": "AT"
            },
            {
                "name": "Afiesl",
                "countryCode": "AT"
            },
            {
                "name": "Ahorn",
                "countryCode": "AT"
            },
            {
                "name": "Aichkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Aigen im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Aistersheim",
                "countryCode": "AT"
            },
            {
                "name": "Alberndorf in der Riedmark",
                "countryCode": "AT"
            },
            {
                "name": "Alkoven",
                "countryCode": "AT"
            },
            {
                "name": "Allerheiligen im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Allhaming",
                "countryCode": "AT"
            },
            {
                "name": "Altenberg bei Linz",
                "countryCode": "AT"
            },
            {
                "name": "Altenfelden",
                "countryCode": "AT"
            },
            {
                "name": "Altheim",
                "countryCode": "AT"
            },
            {
                "name": "Altlichtenberg",
                "countryCode": "AT"
            },
            {
                "name": "Altmünster",
                "countryCode": "AT"
            },
            {
                "name": "Ampflwang",
                "countryCode": "AT"
            },
            {
                "name": "Andorf",
                "countryCode": "AT"
            },
            {
                "name": "Ansfelden",
                "countryCode": "AT"
            },
            {
                "name": "Arnreit",
                "countryCode": "AT"
            },
            {
                "name": "Aschach an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Aschach an der Steyr",
                "countryCode": "AT"
            },
            {
                "name": "Asten",
                "countryCode": "AT"
            },
            {
                "name": "Attersee",
                "countryCode": "AT"
            },
            {
                "name": "Attnang",
                "countryCode": "AT"
            },
            {
                "name": "Attnang-Puchheim",
                "countryCode": "AT"
            },
            {
                "name": "Atzbach",
                "countryCode": "AT"
            },
            {
                "name": "Atzesberg",
                "countryCode": "AT"
            },
            {
                "name": "Au an der Traun",
                "countryCode": "AT"
            },
            {
                "name": "Audorf",
                "countryCode": "AT"
            },
            {
                "name": "Aurach am Hongar",
                "countryCode": "AT"
            },
            {
                "name": "Aurolzmünster",
                "countryCode": "AT"
            },
            {
                "name": "Bachmanning",
                "countryCode": "AT"
            },
            {
                "name": "Bad Goisern",
                "countryCode": "AT"
            },
            {
                "name": "Bad Hall",
                "countryCode": "AT"
            },
            {
                "name": "Bad Ischl",
                "countryCode": "AT"
            },
            {
                "name": "Bad Kreuzen",
                "countryCode": "AT"
            },
            {
                "name": "Bad Leonfelden",
                "countryCode": "AT"
            },
            {
                "name": "Bad Schallerbach",
                "countryCode": "AT"
            },
            {
                "name": "Bad Zell",
                "countryCode": "AT"
            },
            {
                "name": "Berg",
                "countryCode": "AT"
            },
            {
                "name": "Bergern",
                "countryCode": "AT"
            },
            {
                "name": "Bergham",
                "countryCode": "AT"
            },
            {
                "name": "Bernardin",
                "countryCode": "AT"
            },
            {
                "name": "Brandln",
                "countryCode": "AT"
            },
            {
                "name": "Braunau Neustadt",
                "countryCode": "AT"
            },
            {
                "name": "Braunau am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Buchkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Burgfried",
                "countryCode": "AT"
            },
            {
                "name": "Desselbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Dickerldorf",
                "countryCode": "AT"
            },
            {
                "name": "Diesseits",
                "countryCode": "AT"
            },
            {
                "name": "Dietach",
                "countryCode": "AT"
            },
            {
                "name": "Dietachdorf",
                "countryCode": "AT"
            },
            {
                "name": "Doppl",
                "countryCode": "AT"
            },
            {
                "name": "Dörfl",
                "countryCode": "AT"
            },
            {
                "name": "Dürnau",
                "countryCode": "AT"
            },
            {
                "name": "Ebensee",
                "countryCode": "AT"
            },
            {
                "name": "Eberschwang",
                "countryCode": "AT"
            },
            {
                "name": "Eberstalzell",
                "countryCode": "AT"
            },
            {
                "name": "Edt bei Lambach",
                "countryCode": "AT"
            },
            {
                "name": "Eferding",
                "countryCode": "AT"
            },
            {
                "name": "Eidenberg",
                "countryCode": "AT"
            },
            {
                "name": "Engerwitzdorf",
                "countryCode": "AT"
            },
            {
                "name": "Enns",
                "countryCode": "AT"
            },
            {
                "name": "Feldkirchen an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Fisching",
                "countryCode": "AT"
            },
            {
                "name": "Fornach",
                "countryCode": "AT"
            },
            {
                "name": "Frankenburg",
                "countryCode": "AT"
            },
            {
                "name": "Frankenmarkt",
                "countryCode": "AT"
            },
            {
                "name": "Freindorf",
                "countryCode": "AT"
            },
            {
                "name": "Freistadt",
                "countryCode": "AT"
            },
            {
                "name": "Gaflenz",
                "countryCode": "AT"
            },
            {
                "name": "Gallneukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Gallspach",
                "countryCode": "AT"
            },
            {
                "name": "Gampern",
                "countryCode": "AT"
            },
            {
                "name": "Garsten",
                "countryCode": "AT"
            },
            {
                "name": "Gaspoltshofen",
                "countryCode": "AT"
            },
            {
                "name": "Gaumberg",
                "countryCode": "AT"
            },
            {
                "name": "Geboltskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Gmunden",
                "countryCode": "AT"
            },
            {
                "name": "Goldwörth",
                "countryCode": "AT"
            },
            {
                "name": "Gosau",
                "countryCode": "AT"
            },
            {
                "name": "Gramastetten",
                "countryCode": "AT"
            },
            {
                "name": "Grein",
                "countryCode": "AT"
            },
            {
                "name": "Grieskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Grossraming",
                "countryCode": "AT"
            },
            {
                "name": "Grünau im Almtal",
                "countryCode": "AT"
            },
            {
                "name": "Grünburg",
                "countryCode": "AT"
            },
            {
                "name": "Gschwandt",
                "countryCode": "AT"
            },
            {
                "name": "Gunskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Gutau",
                "countryCode": "AT"
            },
            {
                "name": "Haag",
                "countryCode": "AT"
            },
            {
                "name": "Haag am Hausruck",
                "countryCode": "AT"
            },
            {
                "name": "Hagenberg im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Haibach im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Haid",
                "countryCode": "AT"
            },
            {
                "name": "Haiden",
                "countryCode": "AT"
            },
            {
                "name": "Haidl",
                "countryCode": "AT"
            },
            {
                "name": "Handenberg",
                "countryCode": "AT"
            },
            {
                "name": "Hart",
                "countryCode": "AT"
            },
            {
                "name": "Hartheim",
                "countryCode": "AT"
            },
            {
                "name": "Hartkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Haselbach",
                "countryCode": "AT"
            },
            {
                "name": "Haslach an der Mühl",
                "countryCode": "AT"
            },
            {
                "name": "Hellmonsödt",
                "countryCode": "AT"
            },
            {
                "name": "Herzogsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hochburg-Ach",
                "countryCode": "AT"
            },
            {
                "name": "Hochpoint",
                "countryCode": "AT"
            },
            {
                "name": "Hof",
                "countryCode": "AT"
            },
            {
                "name": "Hofkirchen im Traunkreis",
                "countryCode": "AT"
            },
            {
                "name": "Holzhausen",
                "countryCode": "AT"
            },
            {
                "name": "Hörsching",
                "countryCode": "AT"
            },
            {
                "name": "Innerschwand",
                "countryCode": "AT"
            },
            {
                "name": "Inzersdorf im Kremstal",
                "countryCode": "AT"
            },
            {
                "name": "Kaltenbach",
                "countryCode": "AT"
            },
            {
                "name": "Katsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kematen an der Krems",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg ob der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Kirchdorf an der Krems",
                "countryCode": "AT"
            },
            {
                "name": "Kirchschlag bei Linz",
                "countryCode": "AT"
            },
            {
                "name": "Klaffer am Hochficht",
                "countryCode": "AT"
            },
            {
                "name": "Kopfing im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Kremsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kremsmünster",
                "countryCode": "AT"
            },
            {
                "name": "Kronstorf",
                "countryCode": "AT"
            },
            {
                "name": "Königswiesen",
                "countryCode": "AT"
            },
            {
                "name": "Laab",
                "countryCode": "AT"
            },
            {
                "name": "Laahen",
                "countryCode": "AT"
            },
            {
                "name": "Laakirchen",
                "countryCode": "AT"
            },
            {
                "name": "Lambach",
                "countryCode": "AT"
            },
            {
                "name": "Langenstein",
                "countryCode": "AT"
            },
            {
                "name": "Langholzfeld",
                "countryCode": "AT"
            },
            {
                "name": "Laussa",
                "countryCode": "AT"
            },
            {
                "name": "Lembach im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Lenzing",
                "countryCode": "AT"
            },
            {
                "name": "Leonding",
                "countryCode": "AT"
            },
            {
                "name": "Leonstein",
                "countryCode": "AT"
            },
            {
                "name": "Lichtenegg",
                "countryCode": "AT"
            },
            {
                "name": "Linz",
                "countryCode": "AT"
            },
            {
                "name": "Linz Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Losenstein",
                "countryCode": "AT"
            },
            {
                "name": "Luftenberg an der Donau",
                "countryCode": "AT"
            },
            {
                "name": "Manning",
                "countryCode": "AT"
            },
            {
                "name": "Marchtrenk",
                "countryCode": "AT"
            },
            {
                "name": "Maria Neustift",
                "countryCode": "AT"
            },
            {
                "name": "Maria Schmolln",
                "countryCode": "AT"
            },
            {
                "name": "Mattighofen",
                "countryCode": "AT"
            },
            {
                "name": "Mauerkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Meggenhofen",
                "countryCode": "AT"
            },
            {
                "name": "Mehrnbach",
                "countryCode": "AT"
            },
            {
                "name": "Michaelnbach",
                "countryCode": "AT"
            },
            {
                "name": "Micheldorf in Oberösterreich",
                "countryCode": "AT"
            },
            {
                "name": "Mitterkirchen im Machland",
                "countryCode": "AT"
            },
            {
                "name": "Mittertreffling",
                "countryCode": "AT"
            },
            {
                "name": "Molln",
                "countryCode": "AT"
            },
            {
                "name": "Mondsee",
                "countryCode": "AT"
            },
            {
                "name": "Moosdorf",
                "countryCode": "AT"
            },
            {
                "name": "Munderfing",
                "countryCode": "AT"
            },
            {
                "name": "Mühldorf",
                "countryCode": "AT"
            },
            {
                "name": "Münzbach",
                "countryCode": "AT"
            },
            {
                "name": "Münzkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Naarn im Machlande",
                "countryCode": "AT"
            },
            {
                "name": "Natternbach",
                "countryCode": "AT"
            },
            {
                "name": "Nebelberg",
                "countryCode": "AT"
            },
            {
                "name": "Neubau",
                "countryCode": "AT"
            },
            {
                "name": "Neue Heimat",
                "countryCode": "AT"
            },
            {
                "name": "Neuhofen an der Krems",
                "countryCode": "AT"
            },
            {
                "name": "Neukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Neukirchen am Walde",
                "countryCode": "AT"
            },
            {
                "name": "Neukirchen an der Vöckla",
                "countryCode": "AT"
            },
            {
                "name": "Neukirchen bei Lambach",
                "countryCode": "AT"
            },
            {
                "name": "Neumarkt im Hausruckkreis",
                "countryCode": "AT"
            },
            {
                "name": "Neumarkt im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Neuzeug",
                "countryCode": "AT"
            },
            {
                "name": "Niederneukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Niederstraß",
                "countryCode": "AT"
            },
            {
                "name": "Niederthalheim",
                "countryCode": "AT"
            },
            {
                "name": "Nöstlbach",
                "countryCode": "AT"
            },
            {
                "name": "Oberhaid",
                "countryCode": "AT"
            },
            {
                "name": "Oberhart",
                "countryCode": "AT"
            },
            {
                "name": "Oberhofen am Irrsee",
                "countryCode": "AT"
            },
            {
                "name": "Obernberg am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Oberndorf bei Schwanenstadt",
                "countryCode": "AT"
            },
            {
                "name": "Oberneukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Oberwang",
                "countryCode": "AT"
            },
            {
                "name": "Oedt",
                "countryCode": "AT"
            },
            {
                "name": "Oepping",
                "countryCode": "AT"
            },
            {
                "name": "Offenhausen",
                "countryCode": "AT"
            },
            {
                "name": "Ohlsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Ort im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Ostermiething",
                "countryCode": "AT"
            },
            {
                "name": "Ottenschlag im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Ottensheim",
                "countryCode": "AT"
            },
            {
                "name": "Ottnang am Hausruck",
                "countryCode": "AT"
            },
            {
                "name": "Pabneukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Palting",
                "countryCode": "AT"
            },
            {
                "name": "Pasching",
                "countryCode": "AT"
            },
            {
                "name": "Pattigham",
                "countryCode": "AT"
            },
            {
                "name": "Pennewang",
                "countryCode": "AT"
            },
            {
                "name": "Perg",
                "countryCode": "AT"
            },
            {
                "name": "Perwang am Grabensee",
                "countryCode": "AT"
            },
            {
                "name": "Pettenbach",
                "countryCode": "AT"
            },
            {
                "name": "Peuerbach",
                "countryCode": "AT"
            },
            {
                "name": "Pfaffing",
                "countryCode": "AT"
            },
            {
                "name": "Pfarrkirchen bei Bad Hall",
                "countryCode": "AT"
            },
            {
                "name": "Pichl bei Wels",
                "countryCode": "AT"
            },
            {
                "name": "Pierbach",
                "countryCode": "AT"
            },
            {
                "name": "Pinsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pitzenberg",
                "countryCode": "AT"
            },
            {
                "name": "Plesching",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Braunau am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Eferding",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Freistadt",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Gmunden",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Grieskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Kirchdorf an der Krems",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Linz-Land",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Perg",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Ried im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Rohrbach",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Schärding",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Steyr-Land",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Urfahr-Umgebung",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Vöcklabruck",
                "countryCode": "AT"
            },
            {
                "name": "Pram",
                "countryCode": "AT"
            },
            {
                "name": "Prambachkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Pramet",
                "countryCode": "AT"
            },
            {
                "name": "Pregarten",
                "countryCode": "AT"
            },
            {
                "name": "Puchenau",
                "countryCode": "AT"
            },
            {
                "name": "Puchheim",
                "countryCode": "AT"
            },
            {
                "name": "Puchlkirchen am Trattberg",
                "countryCode": "AT"
            },
            {
                "name": "Pucking",
                "countryCode": "AT"
            },
            {
                "name": "Pötting",
                "countryCode": "AT"
            },
            {
                "name": "Pühret",
                "countryCode": "AT"
            },
            {
                "name": "Raab",
                "countryCode": "AT"
            },
            {
                "name": "Raffelstetten",
                "countryCode": "AT"
            },
            {
                "name": "Rainbach im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Ranshofen",
                "countryCode": "AT"
            },
            {
                "name": "Redleiten",
                "countryCode": "AT"
            },
            {
                "name": "Redlham",
                "countryCode": "AT"
            },
            {
                "name": "Regau",
                "countryCode": "AT"
            },
            {
                "name": "Reichenau im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Reichenthal",
                "countryCode": "AT"
            },
            {
                "name": "Reichraming",
                "countryCode": "AT"
            },
            {
                "name": "Reiterndorf",
                "countryCode": "AT"
            },
            {
                "name": "Ried im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Ried im Traunkreis",
                "countryCode": "AT"
            },
            {
                "name": "Ried in der Riedmark",
                "countryCode": "AT"
            },
            {
                "name": "Rohr im Kremstal",
                "countryCode": "AT"
            },
            {
                "name": "Rohrbach-Berg",
                "countryCode": "AT"
            },
            {
                "name": "Roitham",
                "countryCode": "AT"
            },
            {
                "name": "Rufling",
                "countryCode": "AT"
            },
            {
                "name": "Rutzenham",
                "countryCode": "AT"
            },
            {
                "name": "Rüstorf",
                "countryCode": "AT"
            },
            {
                "name": "Sammersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Aegidi",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Dionysen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Florian",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen am Fillmannsbach",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen an der Gusen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen bei Obernberg am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen im Attergau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Gotthard im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lorenz",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Marien",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Oswald bei Freistadt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter am Hart",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ulrich bei Steyr",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Wolfgang im Salzkammergut",
                "countryCode": "AT"
            },
            {
                "name": "Sarleinsbach",
                "countryCode": "AT"
            },
            {
                "name": "Sattledt",
                "countryCode": "AT"
            },
            {
                "name": "Schafwiesen",
                "countryCode": "AT"
            },
            {
                "name": "Schalchen",
                "countryCode": "AT"
            },
            {
                "name": "Schalchham",
                "countryCode": "AT"
            },
            {
                "name": "Schardenberg",
                "countryCode": "AT"
            },
            {
                "name": "Scharnstein",
                "countryCode": "AT"
            },
            {
                "name": "Schenkenfelden",
                "countryCode": "AT"
            },
            {
                "name": "Schildorn",
                "countryCode": "AT"
            },
            {
                "name": "Schlatt",
                "countryCode": "AT"
            },
            {
                "name": "Schlierbach",
                "countryCode": "AT"
            },
            {
                "name": "Schlüsslberg",
                "countryCode": "AT"
            },
            {
                "name": "Schwanenstadt",
                "countryCode": "AT"
            },
            {
                "name": "Schweinbach",
                "countryCode": "AT"
            },
            {
                "name": "Schwertberg",
                "countryCode": "AT"
            },
            {
                "name": "Schärding",
                "countryCode": "AT"
            },
            {
                "name": "Schärding Vorstadt",
                "countryCode": "AT"
            },
            {
                "name": "Schöndorf",
                "countryCode": "AT"
            },
            {
                "name": "Schönering",
                "countryCode": "AT"
            },
            {
                "name": "Schörfling",
                "countryCode": "AT"
            },
            {
                "name": "Seewalchen",
                "countryCode": "AT"
            },
            {
                "name": "Sierning",
                "countryCode": "AT"
            },
            {
                "name": "Sonnberg im Mühlkreis",
                "countryCode": "AT"
            },
            {
                "name": "Spital am Pyhrn",
                "countryCode": "AT"
            },
            {
                "name": "Stadl-Hausruck",
                "countryCode": "AT"
            },
            {
                "name": "Stadl-Paura",
                "countryCode": "AT"
            },
            {
                "name": "Stadl-Traun",
                "countryCode": "AT"
            },
            {
                "name": "Steinbach am Attersee",
                "countryCode": "AT"
            },
            {
                "name": "Steinerkirchen an der Traun",
                "countryCode": "AT"
            },
            {
                "name": "Steinfeld",
                "countryCode": "AT"
            },
            {
                "name": "Steinhaus",
                "countryCode": "AT"
            },
            {
                "name": "Steyr",
                "countryCode": "AT"
            },
            {
                "name": "Steyr Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Steyregg",
                "countryCode": "AT"
            },
            {
                "name": "Strassham",
                "countryCode": "AT"
            },
            {
                "name": "Taiskirchen im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Taufkirchen an der Trattnach",
                "countryCode": "AT"
            },
            {
                "name": "Ternberg",
                "countryCode": "AT"
            },
            {
                "name": "Thalheim bei Wels",
                "countryCode": "AT"
            },
            {
                "name": "Tiefgraben",
                "countryCode": "AT"
            },
            {
                "name": "Timelkam",
                "countryCode": "AT"
            },
            {
                "name": "Tragwein",
                "countryCode": "AT"
            },
            {
                "name": "Traun",
                "countryCode": "AT"
            },
            {
                "name": "Traunkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Ufer",
                "countryCode": "AT"
            },
            {
                "name": "Ulrichsberg",
                "countryCode": "AT"
            },
            {
                "name": "Ungenach",
                "countryCode": "AT"
            },
            {
                "name": "Untereisenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Unterjosefstal",
                "countryCode": "AT"
            },
            {
                "name": "Uttendorf",
                "countryCode": "AT"
            },
            {
                "name": "Utzenaich",
                "countryCode": "AT"
            },
            {
                "name": "Viechtwang",
                "countryCode": "AT"
            },
            {
                "name": "Vorchdorf",
                "countryCode": "AT"
            },
            {
                "name": "Vöcklabruck",
                "countryCode": "AT"
            },
            {
                "name": "Vöcklamarkt",
                "countryCode": "AT"
            },
            {
                "name": "Wagrain",
                "countryCode": "AT"
            },
            {
                "name": "Wagram",
                "countryCode": "AT"
            },
            {
                "name": "Waizenkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Walding",
                "countryCode": "AT"
            },
            {
                "name": "Waldkirchen am Wesen",
                "countryCode": "AT"
            },
            {
                "name": "Waldneukirchen",
                "countryCode": "AT"
            },
            {
                "name": "Waldzell",
                "countryCode": "AT"
            },
            {
                "name": "Wallern an der Trattnach",
                "countryCode": "AT"
            },
            {
                "name": "Wartberg an der Krems",
                "countryCode": "AT"
            },
            {
                "name": "Wartberg ob der Aist",
                "countryCode": "AT"
            },
            {
                "name": "Weibern",
                "countryCode": "AT"
            },
            {
                "name": "Weilbach",
                "countryCode": "AT"
            },
            {
                "name": "Weisskirchen an der Traun",
                "countryCode": "AT"
            },
            {
                "name": "Weitersfelden",
                "countryCode": "AT"
            },
            {
                "name": "Weißenkirchen im Attergau",
                "countryCode": "AT"
            },
            {
                "name": "Wels",
                "countryCode": "AT"
            },
            {
                "name": "Wels Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Wels-Land",
                "countryCode": "AT"
            },
            {
                "name": "Wendling",
                "countryCode": "AT"
            },
            {
                "name": "Weng im Innkreis",
                "countryCode": "AT"
            },
            {
                "name": "Wernstein am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Weyer",
                "countryCode": "AT"
            },
            {
                "name": "Weyregg",
                "countryCode": "AT"
            },
            {
                "name": "Wimpassing",
                "countryCode": "AT"
            },
            {
                "name": "Wimsbach",
                "countryCode": "AT"
            },
            {
                "name": "Windhaag bei Freistadt",
                "countryCode": "AT"
            },
            {
                "name": "Windhaag bei Perg",
                "countryCode": "AT"
            },
            {
                "name": "Windischgarsten",
                "countryCode": "AT"
            },
            {
                "name": "Wippenham",
                "countryCode": "AT"
            },
            {
                "name": "Wispl",
                "countryCode": "AT"
            },
            {
                "name": "Wolfern",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsegg am Hausruck",
                "countryCode": "AT"
            },
            {
                "name": "Zell am Moos",
                "countryCode": "AT"
            },
            {
                "name": "Zell am Pettenfirst",
                "countryCode": "AT"
            },
            {
                "name": "Zell an der Pram",
                "countryCode": "AT"
            },
            {
                "name": "Zwettl an der Rodl",
                "countryCode": "AT"
            },
            {
                "name": "Abtenau",
                "countryCode": "AT"
            },
            {
                "name": "Adnet",
                "countryCode": "AT"
            },
            {
                "name": "Altenmarkt im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Anger",
                "countryCode": "AT"
            },
            {
                "name": "Anif",
                "countryCode": "AT"
            },
            {
                "name": "Anthering",
                "countryCode": "AT"
            },
            {
                "name": "Au",
                "countryCode": "AT"
            },
            {
                "name": "Bad Gastein",
                "countryCode": "AT"
            },
            {
                "name": "Bad Hofgastein",
                "countryCode": "AT"
            },
            {
                "name": "Bergheim",
                "countryCode": "AT"
            },
            {
                "name": "Berndorf bei Salzburg",
                "countryCode": "AT"
            },
            {
                "name": "Bischofshofen",
                "countryCode": "AT"
            },
            {
                "name": "Bramberg am Wildkogel",
                "countryCode": "AT"
            },
            {
                "name": "Bruck an der Großglocknerstraße",
                "countryCode": "AT"
            },
            {
                "name": "Burgfried",
                "countryCode": "AT"
            },
            {
                "name": "Bürmoos",
                "countryCode": "AT"
            },
            {
                "name": "Dienten am Hochkönig",
                "countryCode": "AT"
            },
            {
                "name": "Dorfbeuern",
                "countryCode": "AT"
            },
            {
                "name": "Dorfgastein",
                "countryCode": "AT"
            },
            {
                "name": "Eben im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Ebenau",
                "countryCode": "AT"
            },
            {
                "name": "Eching",
                "countryCode": "AT"
            },
            {
                "name": "Elixhausen",
                "countryCode": "AT"
            },
            {
                "name": "Elsbethen",
                "countryCode": "AT"
            },
            {
                "name": "Esch",
                "countryCode": "AT"
            },
            {
                "name": "Eugendorf",
                "countryCode": "AT"
            },
            {
                "name": "Faistenau",
                "countryCode": "AT"
            },
            {
                "name": "Filzmoos",
                "countryCode": "AT"
            },
            {
                "name": "Flachau",
                "countryCode": "AT"
            },
            {
                "name": "Forstau",
                "countryCode": "AT"
            },
            {
                "name": "Fuschl am See",
                "countryCode": "AT"
            },
            {
                "name": "Georgenberg",
                "countryCode": "AT"
            },
            {
                "name": "Glanegg",
                "countryCode": "AT"
            },
            {
                "name": "Glasenbach",
                "countryCode": "AT"
            },
            {
                "name": "Golling an der Salzach",
                "countryCode": "AT"
            },
            {
                "name": "Grossarl",
                "countryCode": "AT"
            },
            {
                "name": "Grossgmain",
                "countryCode": "AT"
            },
            {
                "name": "Grödig",
                "countryCode": "AT"
            },
            {
                "name": "Habach",
                "countryCode": "AT"
            },
            {
                "name": "Hallein",
                "countryCode": "AT"
            },
            {
                "name": "Hallwang",
                "countryCode": "AT"
            },
            {
                "name": "Henndorf am Wallersee",
                "countryCode": "AT"
            },
            {
                "name": "Himmelreich",
                "countryCode": "AT"
            },
            {
                "name": "Hinterglemm",
                "countryCode": "AT"
            },
            {
                "name": "Hintersee",
                "countryCode": "AT"
            },
            {
                "name": "Hof",
                "countryCode": "AT"
            },
            {
                "name": "Hof bei Salzburg",
                "countryCode": "AT"
            },
            {
                "name": "Hollersbach im Pinzgau",
                "countryCode": "AT"
            },
            {
                "name": "Hüttau",
                "countryCode": "AT"
            },
            {
                "name": "Hüttschlag",
                "countryCode": "AT"
            },
            {
                "name": "Irrsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kaprun",
                "countryCode": "AT"
            },
            {
                "name": "Kleinarl",
                "countryCode": "AT"
            },
            {
                "name": "Koppl",
                "countryCode": "AT"
            },
            {
                "name": "Krimml",
                "countryCode": "AT"
            },
            {
                "name": "Krispl",
                "countryCode": "AT"
            },
            {
                "name": "Kuchl",
                "countryCode": "AT"
            },
            {
                "name": "Köstendorf",
                "countryCode": "AT"
            },
            {
                "name": "Lamprechtshausen",
                "countryCode": "AT"
            },
            {
                "name": "Lehen",
                "countryCode": "AT"
            },
            {
                "name": "Lend",
                "countryCode": "AT"
            },
            {
                "name": "Lengfelden",
                "countryCode": "AT"
            },
            {
                "name": "Leogang",
                "countryCode": "AT"
            },
            {
                "name": "Lofer",
                "countryCode": "AT"
            },
            {
                "name": "Maishofen",
                "countryCode": "AT"
            },
            {
                "name": "Maria Alm am Steinernen Meer",
                "countryCode": "AT"
            },
            {
                "name": "Mariapfarr",
                "countryCode": "AT"
            },
            {
                "name": "Mattsee",
                "countryCode": "AT"
            },
            {
                "name": "Mauterndorf",
                "countryCode": "AT"
            },
            {
                "name": "Mitterberghütten",
                "countryCode": "AT"
            },
            {
                "name": "Mitterhofen",
                "countryCode": "AT"
            },
            {
                "name": "Mittersill",
                "countryCode": "AT"
            },
            {
                "name": "Mühlbach am Hochkönig",
                "countryCode": "AT"
            },
            {
                "name": "Neualm",
                "countryCode": "AT"
            },
            {
                "name": "Neukirchen am Großvenediger",
                "countryCode": "AT"
            },
            {
                "name": "Neumarkt am Wallersee",
                "countryCode": "AT"
            },
            {
                "name": "Niederalm",
                "countryCode": "AT"
            },
            {
                "name": "Niedernsill",
                "countryCode": "AT"
            },
            {
                "name": "Nußdorf am Haunsberg",
                "countryCode": "AT"
            },
            {
                "name": "Oberalm",
                "countryCode": "AT"
            },
            {
                "name": "Obergäu",
                "countryCode": "AT"
            },
            {
                "name": "Oberndorf bei Salzburg",
                "countryCode": "AT"
            },
            {
                "name": "Obertrum am See",
                "countryCode": "AT"
            },
            {
                "name": "Pfarrwerfen",
                "countryCode": "AT"
            },
            {
                "name": "Pichl",
                "countryCode": "AT"
            },
            {
                "name": "Piesendorf",
                "countryCode": "AT"
            },
            {
                "name": "Plainfeld",
                "countryCode": "AT"
            },
            {
                "name": "Plankenau",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Hallein",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Salzburg-Umgebung",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Sankt Johann im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Tamsweg",
                "countryCode": "AT"
            },
            {
                "name": "Puch bei Hallein",
                "countryCode": "AT"
            },
            {
                "name": "Radstadt",
                "countryCode": "AT"
            },
            {
                "name": "Ramingstein",
                "countryCode": "AT"
            },
            {
                "name": "Rauris",
                "countryCode": "AT"
            },
            {
                "name": "Reinbach",
                "countryCode": "AT"
            },
            {
                "name": "Reitberg",
                "countryCode": "AT"
            },
            {
                "name": "Saalbach",
                "countryCode": "AT"
            },
            {
                "name": "Saalfelden am Steinernen Meer",
                "countryCode": "AT"
            },
            {
                "name": "Salzburg",
                "countryCode": "AT"
            },
            {
                "name": "Salzburg Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Andrä im Lungau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Gilgen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Leonhard",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margarethen im Lungau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin bei Lofer",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Michael im Lungau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Scheffau am Tennengebirge",
                "countryCode": "AT"
            },
            {
                "name": "Schleedorf",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzach im Pongau",
                "countryCode": "AT"
            },
            {
                "name": "Seeham",
                "countryCode": "AT"
            },
            {
                "name": "Seekirchen am Wallersee",
                "countryCode": "AT"
            },
            {
                "name": "Seewalchen",
                "countryCode": "AT"
            },
            {
                "name": "Siezenheim",
                "countryCode": "AT"
            },
            {
                "name": "St. Martin of Tennengebirge",
                "countryCode": "AT"
            },
            {
                "name": "Strasswalchen",
                "countryCode": "AT"
            },
            {
                "name": "Strobl",
                "countryCode": "AT"
            },
            {
                "name": "Stuhlfelden",
                "countryCode": "AT"
            },
            {
                "name": "Tamsweg",
                "countryCode": "AT"
            },
            {
                "name": "Taugl",
                "countryCode": "AT"
            },
            {
                "name": "Taxach",
                "countryCode": "AT"
            },
            {
                "name": "Taxenbach",
                "countryCode": "AT"
            },
            {
                "name": "Thalgau",
                "countryCode": "AT"
            },
            {
                "name": "Thomatal",
                "countryCode": "AT"
            },
            {
                "name": "Thumersbach",
                "countryCode": "AT"
            },
            {
                "name": "Tweng",
                "countryCode": "AT"
            },
            {
                "name": "Unken",
                "countryCode": "AT"
            },
            {
                "name": "Unternberg",
                "countryCode": "AT"
            },
            {
                "name": "Untertauern",
                "countryCode": "AT"
            },
            {
                "name": "Uttendorf",
                "countryCode": "AT"
            },
            {
                "name": "Viehhausen",
                "countryCode": "AT"
            },
            {
                "name": "Viehhofen",
                "countryCode": "AT"
            },
            {
                "name": "Vigaun",
                "countryCode": "AT"
            },
            {
                "name": "Wagnergraben",
                "countryCode": "AT"
            },
            {
                "name": "Wagrain",
                "countryCode": "AT"
            },
            {
                "name": "Walchen",
                "countryCode": "AT"
            },
            {
                "name": "Wald im Pinzgau",
                "countryCode": "AT"
            },
            {
                "name": "Waldprechting",
                "countryCode": "AT"
            },
            {
                "name": "Wals",
                "countryCode": "AT"
            },
            {
                "name": "Walserfeld",
                "countryCode": "AT"
            },
            {
                "name": "Werfen",
                "countryCode": "AT"
            },
            {
                "name": "Werfenweng",
                "countryCode": "AT"
            },
            {
                "name": "Zederhaus",
                "countryCode": "AT"
            },
            {
                "name": "Zell am See",
                "countryCode": "AT"
            },
            {
                "name": "Abtissendorf",
                "countryCode": "AT"
            },
            {
                "name": "Admont",
                "countryCode": "AT"
            },
            {
                "name": "Aflenz Kurort",
                "countryCode": "AT"
            },
            {
                "name": "Aibl",
                "countryCode": "AT"
            },
            {
                "name": "Aigen im Ennstal",
                "countryCode": "AT"
            },
            {
                "name": "Albersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Allerheiligen bei Wildon",
                "countryCode": "AT"
            },
            {
                "name": "Allerheiligen im Mürztal",
                "countryCode": "AT"
            },
            {
                "name": "Altaussee",
                "countryCode": "AT"
            },
            {
                "name": "Altenmarkt bei Fürstenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Altenmarkt bei Sankt Gallen",
                "countryCode": "AT"
            },
            {
                "name": "Andritz",
                "countryCode": "AT"
            },
            {
                "name": "Anger",
                "countryCode": "AT"
            },
            {
                "name": "Apfelberg",
                "countryCode": "AT"
            },
            {
                "name": "Ardning",
                "countryCode": "AT"
            },
            {
                "name": "Arnfels",
                "countryCode": "AT"
            },
            {
                "name": "Arzberg",
                "countryCode": "AT"
            },
            {
                "name": "Attendorf",
                "countryCode": "AT"
            },
            {
                "name": "Auersbach",
                "countryCode": "AT"
            },
            {
                "name": "Bad Aussee",
                "countryCode": "AT"
            },
            {
                "name": "Bad Blumau",
                "countryCode": "AT"
            },
            {
                "name": "Bad Gams",
                "countryCode": "AT"
            },
            {
                "name": "Bad Gleichenberg",
                "countryCode": "AT"
            },
            {
                "name": "Bad Radkersburg",
                "countryCode": "AT"
            },
            {
                "name": "Bad Waltersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Baierdorf-Umgebung",
                "countryCode": "AT"
            },
            {
                "name": "Bairisch Kölldorf",
                "countryCode": "AT"
            },
            {
                "name": "Baumgarten bei Gnas",
                "countryCode": "AT"
            },
            {
                "name": "Berndorf",
                "countryCode": "AT"
            },
            {
                "name": "Bierbaum am Auersbach",
                "countryCode": "AT"
            },
            {
                "name": "Birkfeld",
                "countryCode": "AT"
            },
            {
                "name": "Blaindorf",
                "countryCode": "AT"
            },
            {
                "name": "Breitenfeld am Tannenriegel",
                "countryCode": "AT"
            },
            {
                "name": "Breitenfeld an der Rittschein",
                "countryCode": "AT"
            },
            {
                "name": "Bretstein",
                "countryCode": "AT"
            },
            {
                "name": "Bruck an der Mur",
                "countryCode": "AT"
            },
            {
                "name": "Brunn",
                "countryCode": "AT"
            },
            {
                "name": "Burgau",
                "countryCode": "AT"
            },
            {
                "name": "Bärnbach",
                "countryCode": "AT"
            },
            {
                "name": "Dechantskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Deuchendorf",
                "countryCode": "AT"
            },
            {
                "name": "Deutsch Goritz",
                "countryCode": "AT"
            },
            {
                "name": "Deutschfeistritz",
                "countryCode": "AT"
            },
            {
                "name": "Deutschlandsberg",
                "countryCode": "AT"
            },
            {
                "name": "Diemlach",
                "countryCode": "AT"
            },
            {
                "name": "Dienersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Dietersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Dietersdorf am Gnasbach",
                "countryCode": "AT"
            },
            {
                "name": "Donawitz",
                "countryCode": "AT"
            },
            {
                "name": "Donnersbach",
                "countryCode": "AT"
            },
            {
                "name": "Dürnstein in der Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Ebersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Edelsbach bei Feldbach",
                "countryCode": "AT"
            },
            {
                "name": "Edelschrott",
                "countryCode": "AT"
            },
            {
                "name": "Edelsgrub",
                "countryCode": "AT"
            },
            {
                "name": "Edelstauden",
                "countryCode": "AT"
            },
            {
                "name": "Eggenberg",
                "countryCode": "AT"
            },
            {
                "name": "Eggersdorf bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Ehrenhausen",
                "countryCode": "AT"
            },
            {
                "name": "Eibiswald",
                "countryCode": "AT"
            },
            {
                "name": "Eichberg",
                "countryCode": "AT"
            },
            {
                "name": "Eichfeld",
                "countryCode": "AT"
            },
            {
                "name": "Eisbach",
                "countryCode": "AT"
            },
            {
                "name": "Eisenerz",
                "countryCode": "AT"
            },
            {
                "name": "Empersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Eppenstein",
                "countryCode": "AT"
            },
            {
                "name": "Falkenburg",
                "countryCode": "AT"
            },
            {
                "name": "Farrach",
                "countryCode": "AT"
            },
            {
                "name": "Fehring",
                "countryCode": "AT"
            },
            {
                "name": "Feistritz bei Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Feldbach",
                "countryCode": "AT"
            },
            {
                "name": "Feldkirchen bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Fernitz",
                "countryCode": "AT"
            },
            {
                "name": "Fischbach",
                "countryCode": "AT"
            },
            {
                "name": "Fladnitz im Raabtal",
                "countryCode": "AT"
            },
            {
                "name": "Flatschach",
                "countryCode": "AT"
            },
            {
                "name": "Floing",
                "countryCode": "AT"
            },
            {
                "name": "Fohnsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Frannach",
                "countryCode": "AT"
            },
            {
                "name": "Frauenberg",
                "countryCode": "AT"
            },
            {
                "name": "Freidorf an der Laßnitz",
                "countryCode": "AT"
            },
            {
                "name": "Freiland bei Deutschlandsberg",
                "countryCode": "AT"
            },
            {
                "name": "Friedberg",
                "countryCode": "AT"
            },
            {
                "name": "Frohnleiten",
                "countryCode": "AT"
            },
            {
                "name": "Fürstenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Gaal",
                "countryCode": "AT"
            },
            {
                "name": "Gabersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gallmannsegg",
                "countryCode": "AT"
            },
            {
                "name": "Gamlitz",
                "countryCode": "AT"
            },
            {
                "name": "Gams bei Hieflau",
                "countryCode": "AT"
            },
            {
                "name": "Ganz",
                "countryCode": "AT"
            },
            {
                "name": "Garanas",
                "countryCode": "AT"
            },
            {
                "name": "Gasen",
                "countryCode": "AT"
            },
            {
                "name": "Geidorf",
                "countryCode": "AT"
            },
            {
                "name": "Geistthal",
                "countryCode": "AT"
            },
            {
                "name": "Gersdorf an der Feistritz",
                "countryCode": "AT"
            },
            {
                "name": "Gleinstätten",
                "countryCode": "AT"
            },
            {
                "name": "Gleisdorf",
                "countryCode": "AT"
            },
            {
                "name": "Glojach",
                "countryCode": "AT"
            },
            {
                "name": "Gnas",
                "countryCode": "AT"
            },
            {
                "name": "Gniebing",
                "countryCode": "AT"
            },
            {
                "name": "Gosdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gossendorf",
                "countryCode": "AT"
            },
            {
                "name": "Grabersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Grafendorf bei Hartberg",
                "countryCode": "AT"
            },
            {
                "name": "Gralla",
                "countryCode": "AT"
            },
            {
                "name": "Grambach",
                "countryCode": "AT"
            },
            {
                "name": "Gratkorn",
                "countryCode": "AT"
            },
            {
                "name": "Gratwein",
                "countryCode": "AT"
            },
            {
                "name": "Graz",
                "countryCode": "AT"
            },
            {
                "name": "Graz Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Greisdorf",
                "countryCode": "AT"
            },
            {
                "name": "Gressenberg",
                "countryCode": "AT"
            },
            {
                "name": "Gries",
                "countryCode": "AT"
            },
            {
                "name": "Grosssulz",
                "countryCode": "AT"
            },
            {
                "name": "Groß Sankt Florian",
                "countryCode": "AT"
            },
            {
                "name": "Großklein",
                "countryCode": "AT"
            },
            {
                "name": "Großlobming",
                "countryCode": "AT"
            },
            {
                "name": "Großsölk",
                "countryCode": "AT"
            },
            {
                "name": "Gröbming",
                "countryCode": "AT"
            },
            {
                "name": "Gundersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Göss",
                "countryCode": "AT"
            },
            {
                "name": "Gössenberg",
                "countryCode": "AT"
            },
            {
                "name": "Gösting",
                "countryCode": "AT"
            },
            {
                "name": "Göttelsberg",
                "countryCode": "AT"
            },
            {
                "name": "Hafendorf",
                "countryCode": "AT"
            },
            {
                "name": "Hainersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hainsdorf im Schwarzautal",
                "countryCode": "AT"
            },
            {
                "name": "Halbenrain",
                "countryCode": "AT"
            },
            {
                "name": "Hall bei Admont",
                "countryCode": "AT"
            },
            {
                "name": "Hart bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Hartberg",
                "countryCode": "AT"
            },
            {
                "name": "Hartl",
                "countryCode": "AT"
            },
            {
                "name": "Hartmannsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hatzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Haus",
                "countryCode": "AT"
            },
            {
                "name": "Haus im Ennstal",
                "countryCode": "AT"
            },
            {
                "name": "Hausmannstätten",
                "countryCode": "AT"
            },
            {
                "name": "Heiligenkreuz am Waasen",
                "countryCode": "AT"
            },
            {
                "name": "Heimschuh",
                "countryCode": "AT"
            },
            {
                "name": "Hengsberg",
                "countryCode": "AT"
            },
            {
                "name": "Hetzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Hieflau",
                "countryCode": "AT"
            },
            {
                "name": "Hinterberg",
                "countryCode": "AT"
            },
            {
                "name": "Hirnsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hitzendorf",
                "countryCode": "AT"
            },
            {
                "name": "Hochtregist",
                "countryCode": "AT"
            },
            {
                "name": "Hof bei Straden",
                "countryCode": "AT"
            },
            {
                "name": "Hofstätten an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Hohenau an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Hohentauern",
                "countryCode": "AT"
            },
            {
                "name": "Hollenegg",
                "countryCode": "AT"
            },
            {
                "name": "Höf",
                "countryCode": "AT"
            },
            {
                "name": "Hönigsberg",
                "countryCode": "AT"
            },
            {
                "name": "Ilz",
                "countryCode": "AT"
            },
            {
                "name": "Innere Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Irdning",
                "countryCode": "AT"
            },
            {
                "name": "Jagerberg",
                "countryCode": "AT"
            },
            {
                "name": "Jakomini",
                "countryCode": "AT"
            },
            {
                "name": "Johnsbach",
                "countryCode": "AT"
            },
            {
                "name": "Judenburg",
                "countryCode": "AT"
            },
            {
                "name": "Judendorf",
                "countryCode": "AT"
            },
            {
                "name": "Kaibing",
                "countryCode": "AT"
            },
            {
                "name": "Kainach bei Voitsberg",
                "countryCode": "AT"
            },
            {
                "name": "Kainbach",
                "countryCode": "AT"
            },
            {
                "name": "Kaindorf",
                "countryCode": "AT"
            },
            {
                "name": "Kaindorf an der Sulm",
                "countryCode": "AT"
            },
            {
                "name": "Kalsdorf bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Kalwang",
                "countryCode": "AT"
            },
            {
                "name": "Kammern im Liesingtal",
                "countryCode": "AT"
            },
            {
                "name": "Kapellen",
                "countryCode": "AT"
            },
            {
                "name": "Kapfenberg",
                "countryCode": "AT"
            },
            {
                "name": "Kapfenstein",
                "countryCode": "AT"
            },
            {
                "name": "Kindberg",
                "countryCode": "AT"
            },
            {
                "name": "Kirchbach in Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Kirchenviertel",
                "countryCode": "AT"
            },
            {
                "name": "Kitzeck im Sausal",
                "countryCode": "AT"
            },
            {
                "name": "Kleinlobming",
                "countryCode": "AT"
            },
            {
                "name": "Kleinsöding",
                "countryCode": "AT"
            },
            {
                "name": "Kleinsölk",
                "countryCode": "AT"
            },
            {
                "name": "Kloster",
                "countryCode": "AT"
            },
            {
                "name": "Klöch",
                "countryCode": "AT"
            },
            {
                "name": "Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Kobenz",
                "countryCode": "AT"
            },
            {
                "name": "Kohlberg",
                "countryCode": "AT"
            },
            {
                "name": "Kohlschwarz",
                "countryCode": "AT"
            },
            {
                "name": "Krakaudorf",
                "countryCode": "AT"
            },
            {
                "name": "Krakauhintermühlen",
                "countryCode": "AT"
            },
            {
                "name": "Krakauschatten",
                "countryCode": "AT"
            },
            {
                "name": "Kraubath an der Mur",
                "countryCode": "AT"
            },
            {
                "name": "Krieglach",
                "countryCode": "AT"
            },
            {
                "name": "Krottendorf",
                "countryCode": "AT"
            },
            {
                "name": "Krottendorf bei Ligist",
                "countryCode": "AT"
            },
            {
                "name": "Krumegg",
                "countryCode": "AT"
            },
            {
                "name": "Krusdorf",
                "countryCode": "AT"
            },
            {
                "name": "Kulm am Zirbitz",
                "countryCode": "AT"
            },
            {
                "name": "Kumberg",
                "countryCode": "AT"
            },
            {
                "name": "Köflach",
                "countryCode": "AT"
            },
            {
                "name": "Labuch",
                "countryCode": "AT"
            },
            {
                "name": "Lafnitz",
                "countryCode": "AT"
            },
            {
                "name": "Landl",
                "countryCode": "AT"
            },
            {
                "name": "Lang",
                "countryCode": "AT"
            },
            {
                "name": "Langegg bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Langenwang",
                "countryCode": "AT"
            },
            {
                "name": "Lannach",
                "countryCode": "AT"
            },
            {
                "name": "Lassnitzhöhe",
                "countryCode": "AT"
            },
            {
                "name": "Leibnitz",
                "countryCode": "AT"
            },
            {
                "name": "Leitendorf",
                "countryCode": "AT"
            },
            {
                "name": "Leitersdorf im Raabtal",
                "countryCode": "AT"
            },
            {
                "name": "Leitring",
                "countryCode": "AT"
            },
            {
                "name": "Lend",
                "countryCode": "AT"
            },
            {
                "name": "Leoben",
                "countryCode": "AT"
            },
            {
                "name": "Leutschach",
                "countryCode": "AT"
            },
            {
                "name": "Liebenau",
                "countryCode": "AT"
            },
            {
                "name": "Lieboch",
                "countryCode": "AT"
            },
            {
                "name": "Liesingtal",
                "countryCode": "AT"
            },
            {
                "name": "Liezen",
                "countryCode": "AT"
            },
            {
                "name": "Ligist",
                "countryCode": "AT"
            },
            {
                "name": "Loipersdorf bei Fürstenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Lödersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Maierdorf",
                "countryCode": "AT"
            },
            {
                "name": "Maria Lankowitz",
                "countryCode": "AT"
            },
            {
                "name": "Mariahof",
                "countryCode": "AT"
            },
            {
                "name": "Mariatrost",
                "countryCode": "AT"
            },
            {
                "name": "Mariazell",
                "countryCode": "AT"
            },
            {
                "name": "Mautern in Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Mellach",
                "countryCode": "AT"
            },
            {
                "name": "Merkendorf",
                "countryCode": "AT"
            },
            {
                "name": "Mettersdorf am Saßbach",
                "countryCode": "AT"
            },
            {
                "name": "Michaelerberg",
                "countryCode": "AT"
            },
            {
                "name": "Mitterdorf an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Mitterdorf im Mürztal",
                "countryCode": "AT"
            },
            {
                "name": "Mitterlabill",
                "countryCode": "AT"
            },
            {
                "name": "Mitterndorf im Steirischen Salzkammergut",
                "countryCode": "AT"
            },
            {
                "name": "Modriach",
                "countryCode": "AT"
            },
            {
                "name": "Mooskirchen",
                "countryCode": "AT"
            },
            {
                "name": "Mortantsch",
                "countryCode": "AT"
            },
            {
                "name": "Murau",
                "countryCode": "AT"
            },
            {
                "name": "Mureck",
                "countryCode": "AT"
            },
            {
                "name": "Mönichwald",
                "countryCode": "AT"
            },
            {
                "name": "Mühldorf bei Feldbach",
                "countryCode": "AT"
            },
            {
                "name": "Mühlen",
                "countryCode": "AT"
            },
            {
                "name": "Mürzhofen",
                "countryCode": "AT"
            },
            {
                "name": "Mürzsteg",
                "countryCode": "AT"
            },
            {
                "name": "Mürzzuschlag",
                "countryCode": "AT"
            },
            {
                "name": "Naas",
                "countryCode": "AT"
            },
            {
                "name": "Nestelbach bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Nestelbach im Ilztal",
                "countryCode": "AT"
            },
            {
                "name": "Neuberg an der Mürz",
                "countryCode": "AT"
            },
            {
                "name": "Neudau",
                "countryCode": "AT"
            },
            {
                "name": "Neumarkt in Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Neutillmitsch",
                "countryCode": "AT"
            },
            {
                "name": "Niederschöckl",
                "countryCode": "AT"
            },
            {
                "name": "Niederwölz",
                "countryCode": "AT"
            },
            {
                "name": "Niederöblarn",
                "countryCode": "AT"
            },
            {
                "name": "Niklasdorf",
                "countryCode": "AT"
            },
            {
                "name": "Nitscha",
                "countryCode": "AT"
            },
            {
                "name": "Obdach",
                "countryCode": "AT"
            },
            {
                "name": "Oberaich",
                "countryCode": "AT"
            },
            {
                "name": "Oberdorf am Hochegg",
                "countryCode": "AT"
            },
            {
                "name": "Oberhaag",
                "countryCode": "AT"
            },
            {
                "name": "Oberkurzheim",
                "countryCode": "AT"
            },
            {
                "name": "Oberpremstätten",
                "countryCode": "AT"
            },
            {
                "name": "Oberrettenbach",
                "countryCode": "AT"
            },
            {
                "name": "Oberstorcha",
                "countryCode": "AT"
            },
            {
                "name": "Obervogau",
                "countryCode": "AT"
            },
            {
                "name": "Oberweg",
                "countryCode": "AT"
            },
            {
                "name": "Oberwölz Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Oberzeiring",
                "countryCode": "AT"
            },
            {
                "name": "Oppenberg",
                "countryCode": "AT"
            },
            {
                "name": "Ortgraben",
                "countryCode": "AT"
            },
            {
                "name": "Osterwitz",
                "countryCode": "AT"
            },
            {
                "name": "Pack",
                "countryCode": "AT"
            },
            {
                "name": "Paldau",
                "countryCode": "AT"
            },
            {
                "name": "Palfau",
                "countryCode": "AT"
            },
            {
                "name": "Parschlug",
                "countryCode": "AT"
            },
            {
                "name": "Passail",
                "countryCode": "AT"
            },
            {
                "name": "Peggau",
                "countryCode": "AT"
            },
            {
                "name": "Perchau am Sattel",
                "countryCode": "AT"
            },
            {
                "name": "Perlsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Pernegg an der Mur",
                "countryCode": "AT"
            },
            {
                "name": "Pertlstein",
                "countryCode": "AT"
            },
            {
                "name": "Piberegg",
                "countryCode": "AT"
            },
            {
                "name": "Pichling bei Köflach",
                "countryCode": "AT"
            },
            {
                "name": "Pinggau",
                "countryCode": "AT"
            },
            {
                "name": "Pirching am Traubenberg",
                "countryCode": "AT"
            },
            {
                "name": "Pirka",
                "countryCode": "AT"
            },
            {
                "name": "Pischelsdorf in der Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Pistorf",
                "countryCode": "AT"
            },
            {
                "name": "Pitschgau",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Bruck-Mürzzuschlag",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Deutschlandsberg",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Graz-Umgebung",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Hartberg-Fürstenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Leibnitz",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Leoben",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Liezen",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Murau",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Murtal",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Südoststeiermark",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Voitsberg",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Weiz",
                "countryCode": "AT"
            },
            {
                "name": "Poppendorf",
                "countryCode": "AT"
            },
            {
                "name": "Preding",
                "countryCode": "AT"
            },
            {
                "name": "Proleb",
                "countryCode": "AT"
            },
            {
                "name": "Pruggern",
                "countryCode": "AT"
            },
            {
                "name": "Puch bei Weiz",
                "countryCode": "AT"
            },
            {
                "name": "Puntigam",
                "countryCode": "AT"
            },
            {
                "name": "Pusterwald",
                "countryCode": "AT"
            },
            {
                "name": "Pöllau",
                "countryCode": "AT"
            },
            {
                "name": "Pöllauberg",
                "countryCode": "AT"
            },
            {
                "name": "Pöls",
                "countryCode": "AT"
            },
            {
                "name": "Raaba",
                "countryCode": "AT"
            },
            {
                "name": "Raabau",
                "countryCode": "AT"
            },
            {
                "name": "Rabenwald",
                "countryCode": "AT"
            },
            {
                "name": "Rachau",
                "countryCode": "AT"
            },
            {
                "name": "Ragnitz",
                "countryCode": "AT"
            },
            {
                "name": "Ramsau am Dachstein",
                "countryCode": "AT"
            },
            {
                "name": "Raning",
                "countryCode": "AT"
            },
            {
                "name": "Ranten",
                "countryCode": "AT"
            },
            {
                "name": "Rassach",
                "countryCode": "AT"
            },
            {
                "name": "Ratsch an der Weinstraße",
                "countryCode": "AT"
            },
            {
                "name": "Ratschendorf",
                "countryCode": "AT"
            },
            {
                "name": "Ratten",
                "countryCode": "AT"
            },
            {
                "name": "Reichendorf",
                "countryCode": "AT"
            },
            {
                "name": "Reifling",
                "countryCode": "AT"
            },
            {
                "name": "Rettenegg",
                "countryCode": "AT"
            },
            {
                "name": "Retznei",
                "countryCode": "AT"
            },
            {
                "name": "Riegersburg",
                "countryCode": "AT"
            },
            {
                "name": "Ries",
                "countryCode": "AT"
            },
            {
                "name": "Rohrbach an der Lafnitz",
                "countryCode": "AT"
            },
            {
                "name": "Rosental an der Kainach",
                "countryCode": "AT"
            },
            {
                "name": "Rottenmann",
                "countryCode": "AT"
            },
            {
                "name": "Röthelstein",
                "countryCode": "AT"
            },
            {
                "name": "Salla",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Anna am Aigen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Bartholomä",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Blasen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Gallen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen an der Stiefing",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen ob Judenburg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Georgen ob Murau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ilgen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Jakob im Walde",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Jakob-Breitenau",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann am Tauern",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann bei Herberstein",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann im Saggautal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann in der Haide",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Josef (Weststeiermark)",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Katharein an der Laming",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Kathrein am Hauenstein",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Kathrein am Offenegg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lambrecht",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Leonhard",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lorenzen am Wechsel",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lorenzen bei Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lorenzen bei Scheifling",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Lorenzen im Mürztal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Marein bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Marein bei Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Marein bei Neumarkt",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Marein im Mürztal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margarethen an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Margarethen bei Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin am Grimming",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Martin im Sulmtal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Michael in Obersteiermark",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Nikolai im Sausal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Nikolai im Sölktal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Nikolai ob Draßling",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Oswald bei Plankenwarth",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Oswald ob Eibiswald",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter am Kammersberg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter am Ottersbach",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter im Sulmtal",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter ob Judenburg",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Peter-Freienstein",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Radegund bei Graz",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ruprecht an der Raab",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Stefan im Rosental",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Stefan ob Leoben",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Stefan ob Stainz",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ulrich am Waasen",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit am Vogau",
                "countryCode": "AT"
            },
            {
                "name": "Schachen bei Vorau",
                "countryCode": "AT"
            },
            {
                "name": "Scheifling",
                "countryCode": "AT"
            },
            {
                "name": "Schladming",
                "countryCode": "AT"
            },
            {
                "name": "Schrems bei Frohnleiten",
                "countryCode": "AT"
            },
            {
                "name": "Schwanberg",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzau im Schwarzautal",
                "countryCode": "AT"
            },
            {
                "name": "Schäffern",
                "countryCode": "AT"
            },
            {
                "name": "Schöder",
                "countryCode": "AT"
            },
            {
                "name": "Sebersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Seckau",
                "countryCode": "AT"
            },
            {
                "name": "Seggauberg",
                "countryCode": "AT"
            },
            {
                "name": "Seiersberg",
                "countryCode": "AT"
            },
            {
                "name": "Selzthal",
                "countryCode": "AT"
            },
            {
                "name": "Semriach",
                "countryCode": "AT"
            },
            {
                "name": "Siegersdorf bei Herberstein",
                "countryCode": "AT"
            },
            {
                "name": "Sinabelkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Soboth",
                "countryCode": "AT"
            },
            {
                "name": "Spatenhof",
                "countryCode": "AT"
            },
            {
                "name": "Spielberg bei Knittelfeld",
                "countryCode": "AT"
            },
            {
                "name": "Spielfeld",
                "countryCode": "AT"
            },
            {
                "name": "Spital am Semmering",
                "countryCode": "AT"
            },
            {
                "name": "Stadl an der Mur",
                "countryCode": "AT"
            },
            {
                "name": "Stainach",
                "countryCode": "AT"
            },
            {
                "name": "Stainz",
                "countryCode": "AT"
            },
            {
                "name": "Stainz bei Straden",
                "countryCode": "AT"
            },
            {
                "name": "Stallhof",
                "countryCode": "AT"
            },
            {
                "name": "Stallhofen",
                "countryCode": "AT"
            },
            {
                "name": "Stambach",
                "countryCode": "AT"
            },
            {
                "name": "Stattegg",
                "countryCode": "AT"
            },
            {
                "name": "Stein",
                "countryCode": "AT"
            },
            {
                "name": "Stenzengreith",
                "countryCode": "AT"
            },
            {
                "name": "Stiwoll",
                "countryCode": "AT"
            },
            {
                "name": "Straden",
                "countryCode": "AT"
            },
            {
                "name": "Strallegg",
                "countryCode": "AT"
            },
            {
                "name": "Strassengel",
                "countryCode": "AT"
            },
            {
                "name": "Straß in Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Straßgang",
                "countryCode": "AT"
            },
            {
                "name": "Stubenberg",
                "countryCode": "AT"
            },
            {
                "name": "Studenzen",
                "countryCode": "AT"
            },
            {
                "name": "Sulztal an der Weinstraße",
                "countryCode": "AT"
            },
            {
                "name": "Söchau",
                "countryCode": "AT"
            },
            {
                "name": "Södingberg",
                "countryCode": "AT"
            },
            {
                "name": "Tauplitz",
                "countryCode": "AT"
            },
            {
                "name": "Teufenbach",
                "countryCode": "AT"
            },
            {
                "name": "Thal",
                "countryCode": "AT"
            },
            {
                "name": "Thörl",
                "countryCode": "AT"
            },
            {
                "name": "Tieschen",
                "countryCode": "AT"
            },
            {
                "name": "Tillmitsch",
                "countryCode": "AT"
            },
            {
                "name": "Tillmitsch Links der Laßnitz",
                "countryCode": "AT"
            },
            {
                "name": "Traboch",
                "countryCode": "AT"
            },
            {
                "name": "Trahütten",
                "countryCode": "AT"
            },
            {
                "name": "Trautmannsdorf in Oststeiermark",
                "countryCode": "AT"
            },
            {
                "name": "Treglwang",
                "countryCode": "AT"
            },
            {
                "name": "Trieben",
                "countryCode": "AT"
            },
            {
                "name": "Triebendorf",
                "countryCode": "AT"
            },
            {
                "name": "Trofaiach",
                "countryCode": "AT"
            },
            {
                "name": "Trössing",
                "countryCode": "AT"
            },
            {
                "name": "Turnau",
                "countryCode": "AT"
            },
            {
                "name": "Ungerdorf",
                "countryCode": "AT"
            },
            {
                "name": "Unterauersbach",
                "countryCode": "AT"
            },
            {
                "name": "Unterbergla",
                "countryCode": "AT"
            },
            {
                "name": "Unterfladnitz",
                "countryCode": "AT"
            },
            {
                "name": "Unterlamm",
                "countryCode": "AT"
            },
            {
                "name": "Unterpremstätten",
                "countryCode": "AT"
            },
            {
                "name": "Utschtal",
                "countryCode": "AT"
            },
            {
                "name": "Vasoldsberg",
                "countryCode": "AT"
            },
            {
                "name": "Veitsch",
                "countryCode": "AT"
            },
            {
                "name": "Vogau",
                "countryCode": "AT"
            },
            {
                "name": "Voitsberg",
                "countryCode": "AT"
            },
            {
                "name": "Vorau",
                "countryCode": "AT"
            },
            {
                "name": "Vordernberg",
                "countryCode": "AT"
            },
            {
                "name": "Wagna",
                "countryCode": "AT"
            },
            {
                "name": "Wagnitz",
                "countryCode": "AT"
            },
            {
                "name": "Wald am Schoberpaß",
                "countryCode": "AT"
            },
            {
                "name": "Waltendorf",
                "countryCode": "AT"
            },
            {
                "name": "Wartberg",
                "countryCode": "AT"
            },
            {
                "name": "Weinburg am Saßbach",
                "countryCode": "AT"
            },
            {
                "name": "Weiz",
                "countryCode": "AT"
            },
            {
                "name": "Weißenbach bei Liezen",
                "countryCode": "AT"
            },
            {
                "name": "Weißkirchen in Steiermark",
                "countryCode": "AT"
            },
            {
                "name": "Werndorf",
                "countryCode": "AT"
            },
            {
                "name": "Wernersdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wettmannstätten",
                "countryCode": "AT"
            },
            {
                "name": "Wetzelsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Wies",
                "countryCode": "AT"
            },
            {
                "name": "Wildalpen",
                "countryCode": "AT"
            },
            {
                "name": "Wildon",
                "countryCode": "AT"
            },
            {
                "name": "Winklern bei Oberwölz",
                "countryCode": "AT"
            },
            {
                "name": "Wolfsberg im Schwarzautal",
                "countryCode": "AT"
            },
            {
                "name": "Wundschuh",
                "countryCode": "AT"
            },
            {
                "name": "Wörschach",
                "countryCode": "AT"
            },
            {
                "name": "Wörth an der Lafnitz",
                "countryCode": "AT"
            },
            {
                "name": "Zeltweg",
                "countryCode": "AT"
            },
            {
                "name": "Zerlach",
                "countryCode": "AT"
            },
            {
                "name": "Zettling",
                "countryCode": "AT"
            },
            {
                "name": "Zeutschach",
                "countryCode": "AT"
            },
            {
                "name": "Öblarn",
                "countryCode": "AT"
            },
            {
                "name": "Übelbach",
                "countryCode": "AT"
            },
            {
                "name": "Übersbach",
                "countryCode": "AT"
            },
            {
                "name": "Abfaltersbach",
                "countryCode": "AT"
            },
            {
                "name": "Absam",
                "countryCode": "AT"
            },
            {
                "name": "Achenkirch",
                "countryCode": "AT"
            },
            {
                "name": "Ainet",
                "countryCode": "AT"
            },
            {
                "name": "Aldrans",
                "countryCode": "AT"
            },
            {
                "name": "Alpbach",
                "countryCode": "AT"
            },
            {
                "name": "Amlach",
                "countryCode": "AT"
            },
            {
                "name": "Ampass",
                "countryCode": "AT"
            },
            {
                "name": "Amras",
                "countryCode": "AT"
            },
            {
                "name": "Angath",
                "countryCode": "AT"
            },
            {
                "name": "Angerberg",
                "countryCode": "AT"
            },
            {
                "name": "Anras",
                "countryCode": "AT"
            },
            {
                "name": "Arzl",
                "countryCode": "AT"
            },
            {
                "name": "Arzl im Pitztal",
                "countryCode": "AT"
            },
            {
                "name": "Aschau",
                "countryCode": "AT"
            },
            {
                "name": "Aschau im Zillertal",
                "countryCode": "AT"
            },
            {
                "name": "Aurach",
                "countryCode": "AT"
            },
            {
                "name": "Außervillgraten",
                "countryCode": "AT"
            },
            {
                "name": "Axams",
                "countryCode": "AT"
            },
            {
                "name": "Bad Häring",
                "countryCode": "AT"
            },
            {
                "name": "Barwies",
                "countryCode": "AT"
            },
            {
                "name": "Baumkirchen",
                "countryCode": "AT"
            },
            {
                "name": "Berwang",
                "countryCode": "AT"
            },
            {
                "name": "Biberwier",
                "countryCode": "AT"
            },
            {
                "name": "Bichlbach",
                "countryCode": "AT"
            },
            {
                "name": "Birgitz",
                "countryCode": "AT"
            },
            {
                "name": "Brandenberg",
                "countryCode": "AT"
            },
            {
                "name": "Breitenbach am Inn",
                "countryCode": "AT"
            },
            {
                "name": "Breitenwang",
                "countryCode": "AT"
            },
            {
                "name": "Brixen im Thale",
                "countryCode": "AT"
            },
            {
                "name": "Brixlegg",
                "countryCode": "AT"
            },
            {
                "name": "Bruck am Ziller",
                "countryCode": "AT"
            },
            {
                "name": "Buch in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Debant",
                "countryCode": "AT"
            },
            {
                "name": "Dölsach",
                "countryCode": "AT"
            },
            {
                "name": "Ebbs",
                "countryCode": "AT"
            },
            {
                "name": "Eben am Achensee",
                "countryCode": "AT"
            },
            {
                "name": "Ehenbichl",
                "countryCode": "AT"
            },
            {
                "name": "Ehrwald",
                "countryCode": "AT"
            },
            {
                "name": "Elbigenalp",
                "countryCode": "AT"
            },
            {
                "name": "Ellbögen",
                "countryCode": "AT"
            },
            {
                "name": "Ellmau",
                "countryCode": "AT"
            },
            {
                "name": "Elmen",
                "countryCode": "AT"
            },
            {
                "name": "Endach",
                "countryCode": "AT"
            },
            {
                "name": "Erl",
                "countryCode": "AT"
            },
            {
                "name": "Erpfendorf",
                "countryCode": "AT"
            },
            {
                "name": "Fendels",
                "countryCode": "AT"
            },
            {
                "name": "Fieberbrunn",
                "countryCode": "AT"
            },
            {
                "name": "Fiecht",
                "countryCode": "AT"
            },
            {
                "name": "Finkenberg",
                "countryCode": "AT"
            },
            {
                "name": "Fiss",
                "countryCode": "AT"
            },
            {
                "name": "Flaurling",
                "countryCode": "AT"
            },
            {
                "name": "Fliess",
                "countryCode": "AT"
            },
            {
                "name": "Flirsch",
                "countryCode": "AT"
            },
            {
                "name": "Forchach",
                "countryCode": "AT"
            },
            {
                "name": "Fritzens",
                "countryCode": "AT"
            },
            {
                "name": "Fulpmes",
                "countryCode": "AT"
            },
            {
                "name": "Fügen",
                "countryCode": "AT"
            },
            {
                "name": "Fügenberg",
                "countryCode": "AT"
            },
            {
                "name": "Gallzein",
                "countryCode": "AT"
            },
            {
                "name": "Galtür",
                "countryCode": "AT"
            },
            {
                "name": "Gerlos",
                "countryCode": "AT"
            },
            {
                "name": "Gerlosberg",
                "countryCode": "AT"
            },
            {
                "name": "Gnadenwald",
                "countryCode": "AT"
            },
            {
                "name": "Going",
                "countryCode": "AT"
            },
            {
                "name": "Gramais",
                "countryCode": "AT"
            },
            {
                "name": "Gries am Brenner",
                "countryCode": "AT"
            },
            {
                "name": "Gries im Sellrain",
                "countryCode": "AT"
            },
            {
                "name": "Grins",
                "countryCode": "AT"
            },
            {
                "name": "Grinzens",
                "countryCode": "AT"
            },
            {
                "name": "Grän",
                "countryCode": "AT"
            },
            {
                "name": "Gschnitz",
                "countryCode": "AT"
            },
            {
                "name": "Götzens",
                "countryCode": "AT"
            },
            {
                "name": "Haiming",
                "countryCode": "AT"
            },
            {
                "name": "Hainzenberg",
                "countryCode": "AT"
            },
            {
                "name": "Hall in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Hart im Zillertal",
                "countryCode": "AT"
            },
            {
                "name": "Hatting",
                "countryCode": "AT"
            },
            {
                "name": "Heinfels",
                "countryCode": "AT"
            },
            {
                "name": "Heiterwang",
                "countryCode": "AT"
            },
            {
                "name": "Hippach",
                "countryCode": "AT"
            },
            {
                "name": "Hochfilzen",
                "countryCode": "AT"
            },
            {
                "name": "Holzgau",
                "countryCode": "AT"
            },
            {
                "name": "Hopfgarten im Brixental",
                "countryCode": "AT"
            },
            {
                "name": "Hopfgarten in Defereggen",
                "countryCode": "AT"
            },
            {
                "name": "Häselgehr",
                "countryCode": "AT"
            },
            {
                "name": "Höfen",
                "countryCode": "AT"
            },
            {
                "name": "Hötting",
                "countryCode": "AT"
            },
            {
                "name": "Igls",
                "countryCode": "AT"
            },
            {
                "name": "Imst",
                "countryCode": "AT"
            },
            {
                "name": "Imsterberg",
                "countryCode": "AT"
            },
            {
                "name": "Innervillgraten",
                "countryCode": "AT"
            },
            {
                "name": "Innsbruck",
                "countryCode": "AT"
            },
            {
                "name": "Innsbruck Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Inzing",
                "countryCode": "AT"
            },
            {
                "name": "Ischgl",
                "countryCode": "AT"
            },
            {
                "name": "Itter",
                "countryCode": "AT"
            },
            {
                "name": "Jenbach",
                "countryCode": "AT"
            },
            {
                "name": "Jerzens",
                "countryCode": "AT"
            },
            {
                "name": "Jochberg",
                "countryCode": "AT"
            },
            {
                "name": "Jungholz",
                "countryCode": "AT"
            },
            {
                "name": "Kaltenbach",
                "countryCode": "AT"
            },
            {
                "name": "Kapfing",
                "countryCode": "AT"
            },
            {
                "name": "Kappl",
                "countryCode": "AT"
            },
            {
                "name": "Karres",
                "countryCode": "AT"
            },
            {
                "name": "Karrösten",
                "countryCode": "AT"
            },
            {
                "name": "Kartitsch",
                "countryCode": "AT"
            },
            {
                "name": "Kauns",
                "countryCode": "AT"
            },
            {
                "name": "Kematen in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Kirchberg in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Kirchbichl",
                "countryCode": "AT"
            },
            {
                "name": "Kirchdorf in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Kitzbühel",
                "countryCode": "AT"
            },
            {
                "name": "Kolsass",
                "countryCode": "AT"
            },
            {
                "name": "Kolsassberg",
                "countryCode": "AT"
            },
            {
                "name": "Kramsach",
                "countryCode": "AT"
            },
            {
                "name": "Kufstein",
                "countryCode": "AT"
            },
            {
                "name": "Kundl",
                "countryCode": "AT"
            },
            {
                "name": "Kössen",
                "countryCode": "AT"
            },
            {
                "name": "Ladis",
                "countryCode": "AT"
            },
            {
                "name": "Landeck",
                "countryCode": "AT"
            },
            {
                "name": "Lans",
                "countryCode": "AT"
            },
            {
                "name": "Lavant",
                "countryCode": "AT"
            },
            {
                "name": "Lechaschau",
                "countryCode": "AT"
            },
            {
                "name": "Leisach",
                "countryCode": "AT"
            },
            {
                "name": "Lermoos",
                "countryCode": "AT"
            },
            {
                "name": "Leutasch",
                "countryCode": "AT"
            },
            {
                "name": "Lienz",
                "countryCode": "AT"
            },
            {
                "name": "Längenfeld",
                "countryCode": "AT"
            },
            {
                "name": "Mariastein",
                "countryCode": "AT"
            },
            {
                "name": "Mariatal",
                "countryCode": "AT"
            },
            {
                "name": "Matrei am Brenner",
                "countryCode": "AT"
            },
            {
                "name": "Matrei in Osttirol",
                "countryCode": "AT"
            },
            {
                "name": "Maurach",
                "countryCode": "AT"
            },
            {
                "name": "Mayrhofen",
                "countryCode": "AT"
            },
            {
                "name": "Medraz",
                "countryCode": "AT"
            },
            {
                "name": "Mieders",
                "countryCode": "AT"
            },
            {
                "name": "Mieming",
                "countryCode": "AT"
            },
            {
                "name": "Mils bei Imst",
                "countryCode": "AT"
            },
            {
                "name": "Mils bei Solbad Hall",
                "countryCode": "AT"
            },
            {
                "name": "Mitterndorf",
                "countryCode": "AT"
            },
            {
                "name": "Musau",
                "countryCode": "AT"
            },
            {
                "name": "Mutters",
                "countryCode": "AT"
            },
            {
                "name": "Mötz",
                "countryCode": "AT"
            },
            {
                "name": "Mühlau",
                "countryCode": "AT"
            },
            {
                "name": "Mühlbachl",
                "countryCode": "AT"
            },
            {
                "name": "Münster",
                "countryCode": "AT"
            },
            {
                "name": "Namlos",
                "countryCode": "AT"
            },
            {
                "name": "Nassereith",
                "countryCode": "AT"
            },
            {
                "name": "Natters",
                "countryCode": "AT"
            },
            {
                "name": "Nauders",
                "countryCode": "AT"
            },
            {
                "name": "Nesselwängle",
                "countryCode": "AT"
            },
            {
                "name": "Neustift im Stubaital",
                "countryCode": "AT"
            },
            {
                "name": "Niederau",
                "countryCode": "AT"
            },
            {
                "name": "Niederbreitenbach",
                "countryCode": "AT"
            },
            {
                "name": "Niederndorf",
                "countryCode": "AT"
            },
            {
                "name": "Nikolsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Oberau",
                "countryCode": "AT"
            },
            {
                "name": "Oberhofen im Inntal",
                "countryCode": "AT"
            },
            {
                "name": "Oberlienz",
                "countryCode": "AT"
            },
            {
                "name": "Obermieming",
                "countryCode": "AT"
            },
            {
                "name": "Obernberg am Brenner",
                "countryCode": "AT"
            },
            {
                "name": "Oberndorf in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Oberperfuss",
                "countryCode": "AT"
            },
            {
                "name": "Obertilliach",
                "countryCode": "AT"
            },
            {
                "name": "Obsteig",
                "countryCode": "AT"
            },
            {
                "name": "Oetz",
                "countryCode": "AT"
            },
            {
                "name": "Patsch",
                "countryCode": "AT"
            },
            {
                "name": "Pettnau",
                "countryCode": "AT"
            },
            {
                "name": "Pettneu",
                "countryCode": "AT"
            },
            {
                "name": "Pfaffenhofen",
                "countryCode": "AT"
            },
            {
                "name": "Pflach",
                "countryCode": "AT"
            },
            {
                "name": "Pfons",
                "countryCode": "AT"
            },
            {
                "name": "Pfunds",
                "countryCode": "AT"
            },
            {
                "name": "Pians",
                "countryCode": "AT"
            },
            {
                "name": "Pill",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Imst",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Innsbruck Land",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Kitzbühel",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Kufstein",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Landeck",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Lienz",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Reutte",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Schwaz",
                "countryCode": "AT"
            },
            {
                "name": "Polling in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Pradl",
                "countryCode": "AT"
            },
            {
                "name": "Prutz",
                "countryCode": "AT"
            },
            {
                "name": "Radfeld",
                "countryCode": "AT"
            },
            {
                "name": "Ramsau im Zillertal",
                "countryCode": "AT"
            },
            {
                "name": "Ranggen",
                "countryCode": "AT"
            },
            {
                "name": "Rattenberg",
                "countryCode": "AT"
            },
            {
                "name": "Reith bei Kitzbühel",
                "countryCode": "AT"
            },
            {
                "name": "Reith bei Seefeld",
                "countryCode": "AT"
            },
            {
                "name": "Reith im Alpbachtal",
                "countryCode": "AT"
            },
            {
                "name": "Rettenschöss",
                "countryCode": "AT"
            },
            {
                "name": "Reutte",
                "countryCode": "AT"
            },
            {
                "name": "Ried im Oberinntal",
                "countryCode": "AT"
            },
            {
                "name": "Ried im Zillertal",
                "countryCode": "AT"
            },
            {
                "name": "Rietz",
                "countryCode": "AT"
            },
            {
                "name": "Rinn",
                "countryCode": "AT"
            },
            {
                "name": "Rohrberg",
                "countryCode": "AT"
            },
            {
                "name": "Roppen",
                "countryCode": "AT"
            },
            {
                "name": "Rum",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Johann in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Ulrich am Pillersee",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Veit in Defereggen",
                "countryCode": "AT"
            },
            {
                "name": "Sautens",
                "countryCode": "AT"
            },
            {
                "name": "Scharnitz",
                "countryCode": "AT"
            },
            {
                "name": "Schattwald",
                "countryCode": "AT"
            },
            {
                "name": "Scheffau am Wilden Kaiser",
                "countryCode": "AT"
            },
            {
                "name": "Schlaiten",
                "countryCode": "AT"
            },
            {
                "name": "Schlitters",
                "countryCode": "AT"
            },
            {
                "name": "Schwaz",
                "countryCode": "AT"
            },
            {
                "name": "Schwendau",
                "countryCode": "AT"
            },
            {
                "name": "Schwendt",
                "countryCode": "AT"
            },
            {
                "name": "Schwoich",
                "countryCode": "AT"
            },
            {
                "name": "Schönberg im Stubaital",
                "countryCode": "AT"
            },
            {
                "name": "Schönwies",
                "countryCode": "AT"
            },
            {
                "name": "See",
                "countryCode": "AT"
            },
            {
                "name": "Seefeld in Tirol",
                "countryCode": "AT"
            },
            {
                "name": "Sellrain",
                "countryCode": "AT"
            },
            {
                "name": "Serfaus",
                "countryCode": "AT"
            },
            {
                "name": "Sillian",
                "countryCode": "AT"
            },
            {
                "name": "Silz",
                "countryCode": "AT"
            },
            {
                "name": "Sistrans",
                "countryCode": "AT"
            },
            {
                "name": "St Anton am Arlberg",
                "countryCode": "AT"
            },
            {
                "name": "Stams",
                "countryCode": "AT"
            },
            {
                "name": "Stans",
                "countryCode": "AT"
            },
            {
                "name": "Stanz bei Landeck",
                "countryCode": "AT"
            },
            {
                "name": "Stanzach",
                "countryCode": "AT"
            },
            {
                "name": "Steeg",
                "countryCode": "AT"
            },
            {
                "name": "Steinach am Brenner",
                "countryCode": "AT"
            },
            {
                "name": "Strass im Zillertal",
                "countryCode": "AT"
            },
            {
                "name": "Strassen",
                "countryCode": "AT"
            },
            {
                "name": "Strengen",
                "countryCode": "AT"
            },
            {
                "name": "Stumm",
                "countryCode": "AT"
            },
            {
                "name": "Stummerberg",
                "countryCode": "AT"
            },
            {
                "name": "Sölden",
                "countryCode": "AT"
            },
            {
                "name": "Söll",
                "countryCode": "AT"
            },
            {
                "name": "Tannheim",
                "countryCode": "AT"
            },
            {
                "name": "Tarrenz",
                "countryCode": "AT"
            },
            {
                "name": "Telfes im Stubai",
                "countryCode": "AT"
            },
            {
                "name": "Telfs",
                "countryCode": "AT"
            },
            {
                "name": "Terfens",
                "countryCode": "AT"
            },
            {
                "name": "Thaur",
                "countryCode": "AT"
            },
            {
                "name": "Thurn",
                "countryCode": "AT"
            },
            {
                "name": "Tobadill",
                "countryCode": "AT"
            },
            {
                "name": "Trins",
                "countryCode": "AT"
            },
            {
                "name": "Tristach",
                "countryCode": "AT"
            },
            {
                "name": "Tulfes",
                "countryCode": "AT"
            },
            {
                "name": "Tux",
                "countryCode": "AT"
            },
            {
                "name": "Uderns",
                "countryCode": "AT"
            },
            {
                "name": "Umhausen",
                "countryCode": "AT"
            },
            {
                "name": "Unterlangkampfen",
                "countryCode": "AT"
            },
            {
                "name": "Unterperfuss",
                "countryCode": "AT"
            },
            {
                "name": "Untertilliach",
                "countryCode": "AT"
            },
            {
                "name": "Vals",
                "countryCode": "AT"
            },
            {
                "name": "Vils",
                "countryCode": "AT"
            },
            {
                "name": "Virgen",
                "countryCode": "AT"
            },
            {
                "name": "Volders",
                "countryCode": "AT"
            },
            {
                "name": "Voldöpp",
                "countryCode": "AT"
            },
            {
                "name": "Vomp",
                "countryCode": "AT"
            },
            {
                "name": "Vorderhornbach",
                "countryCode": "AT"
            },
            {
                "name": "Vorderthiersee",
                "countryCode": "AT"
            },
            {
                "name": "Völs",
                "countryCode": "AT"
            },
            {
                "name": "Waidring",
                "countryCode": "AT"
            },
            {
                "name": "Walchsee",
                "countryCode": "AT"
            },
            {
                "name": "Wattenberg",
                "countryCode": "AT"
            },
            {
                "name": "Wattens",
                "countryCode": "AT"
            },
            {
                "name": "Weer",
                "countryCode": "AT"
            },
            {
                "name": "Weerberg",
                "countryCode": "AT"
            },
            {
                "name": "Weissach",
                "countryCode": "AT"
            },
            {
                "name": "Weissenbach am Lech",
                "countryCode": "AT"
            },
            {
                "name": "Wenns",
                "countryCode": "AT"
            },
            {
                "name": "Westendorf",
                "countryCode": "AT"
            },
            {
                "name": "Wiesing",
                "countryCode": "AT"
            },
            {
                "name": "Wildermieming",
                "countryCode": "AT"
            },
            {
                "name": "Wildschönau",
                "countryCode": "AT"
            },
            {
                "name": "Wilten",
                "countryCode": "AT"
            },
            {
                "name": "Wängle",
                "countryCode": "AT"
            },
            {
                "name": "Wörgl",
                "countryCode": "AT"
            },
            {
                "name": "Zams",
                "countryCode": "AT"
            },
            {
                "name": "Zell",
                "countryCode": "AT"
            },
            {
                "name": "Zell am Ziller",
                "countryCode": "AT"
            },
            {
                "name": "Zellberg",
                "countryCode": "AT"
            },
            {
                "name": "Zirl",
                "countryCode": "AT"
            },
            {
                "name": "Zöblen",
                "countryCode": "AT"
            },
            {
                "name": "Ötztal-Bahnhof",
                "countryCode": "AT"
            },
            {
                "name": "Alberschwende",
                "countryCode": "AT"
            },
            {
                "name": "Altach",
                "countryCode": "AT"
            },
            {
                "name": "Andelsbuch",
                "countryCode": "AT"
            },
            {
                "name": "Au",
                "countryCode": "AT"
            },
            {
                "name": "Ausserbraz",
                "countryCode": "AT"
            },
            {
                "name": "Bartholomäberg",
                "countryCode": "AT"
            },
            {
                "name": "Batschuns",
                "countryCode": "AT"
            },
            {
                "name": "Bezau",
                "countryCode": "AT"
            },
            {
                "name": "Bildstein",
                "countryCode": "AT"
            },
            {
                "name": "Bizau",
                "countryCode": "AT"
            },
            {
                "name": "Blons",
                "countryCode": "AT"
            },
            {
                "name": "Bludenz",
                "countryCode": "AT"
            },
            {
                "name": "Bludesch",
                "countryCode": "AT"
            },
            {
                "name": "Brand",
                "countryCode": "AT"
            },
            {
                "name": "Bregenz",
                "countryCode": "AT"
            },
            {
                "name": "Bürs",
                "countryCode": "AT"
            },
            {
                "name": "Bürserberg",
                "countryCode": "AT"
            },
            {
                "name": "Dalaas",
                "countryCode": "AT"
            },
            {
                "name": "Damüls",
                "countryCode": "AT"
            },
            {
                "name": "Doren",
                "countryCode": "AT"
            },
            {
                "name": "Dornbirn",
                "countryCode": "AT"
            },
            {
                "name": "Düns",
                "countryCode": "AT"
            },
            {
                "name": "Dünserberg",
                "countryCode": "AT"
            },
            {
                "name": "Egg",
                "countryCode": "AT"
            },
            {
                "name": "Eichenberg",
                "countryCode": "AT"
            },
            {
                "name": "Feldkirch",
                "countryCode": "AT"
            },
            {
                "name": "Frastanz",
                "countryCode": "AT"
            },
            {
                "name": "Fraxern",
                "countryCode": "AT"
            },
            {
                "name": "Fußach",
                "countryCode": "AT"
            },
            {
                "name": "Gaißau",
                "countryCode": "AT"
            },
            {
                "name": "Gaschurn",
                "countryCode": "AT"
            },
            {
                "name": "Großdorf",
                "countryCode": "AT"
            },
            {
                "name": "Göfis",
                "countryCode": "AT"
            },
            {
                "name": "Götzis",
                "countryCode": "AT"
            },
            {
                "name": "Hard",
                "countryCode": "AT"
            },
            {
                "name": "Hirschegg",
                "countryCode": "AT"
            },
            {
                "name": "Hittisau",
                "countryCode": "AT"
            },
            {
                "name": "Hohenems",
                "countryCode": "AT"
            },
            {
                "name": "Hohenweiler",
                "countryCode": "AT"
            },
            {
                "name": "Höchst",
                "countryCode": "AT"
            },
            {
                "name": "Hörbranz",
                "countryCode": "AT"
            },
            {
                "name": "Innerbraz",
                "countryCode": "AT"
            },
            {
                "name": "Kennelbach",
                "countryCode": "AT"
            },
            {
                "name": "Klaus",
                "countryCode": "AT"
            },
            {
                "name": "Klösterle",
                "countryCode": "AT"
            },
            {
                "name": "Koblach",
                "countryCode": "AT"
            },
            {
                "name": "Krumbach",
                "countryCode": "AT"
            },
            {
                "name": "Langen",
                "countryCode": "AT"
            },
            {
                "name": "Langenegg",
                "countryCode": "AT"
            },
            {
                "name": "Lauterach",
                "countryCode": "AT"
            },
            {
                "name": "Lech",
                "countryCode": "AT"
            },
            {
                "name": "Lingenau",
                "countryCode": "AT"
            },
            {
                "name": "Lochau",
                "countryCode": "AT"
            },
            {
                "name": "Lorüns",
                "countryCode": "AT"
            },
            {
                "name": "Ludesch",
                "countryCode": "AT"
            },
            {
                "name": "Lustenau",
                "countryCode": "AT"
            },
            {
                "name": "Meiningen",
                "countryCode": "AT"
            },
            {
                "name": "Mellau",
                "countryCode": "AT"
            },
            {
                "name": "Mittelberg",
                "countryCode": "AT"
            },
            {
                "name": "Muntlix",
                "countryCode": "AT"
            },
            {
                "name": "Mäder",
                "countryCode": "AT"
            },
            {
                "name": "Möggers",
                "countryCode": "AT"
            },
            {
                "name": "Nenzing",
                "countryCode": "AT"
            },
            {
                "name": "Nüziders",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Bludenz",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Bregenz",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Dornbirn",
                "countryCode": "AT"
            },
            {
                "name": "Politischer Bezirk Feldkirch",
                "countryCode": "AT"
            },
            {
                "name": "Raggal",
                "countryCode": "AT"
            },
            {
                "name": "Rankweil",
                "countryCode": "AT"
            },
            {
                "name": "Reuthe",
                "countryCode": "AT"
            },
            {
                "name": "Riefensberg",
                "countryCode": "AT"
            },
            {
                "name": "Riezlern",
                "countryCode": "AT"
            },
            {
                "name": "Röns",
                "countryCode": "AT"
            },
            {
                "name": "Röthis",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Anton im Montafon",
                "countryCode": "AT"
            },
            {
                "name": "Sankt Gallenkirch",
                "countryCode": "AT"
            },
            {
                "name": "Satteins",
                "countryCode": "AT"
            },
            {
                "name": "Schlins",
                "countryCode": "AT"
            },
            {
                "name": "Schnepfau",
                "countryCode": "AT"
            },
            {
                "name": "Schnifis",
                "countryCode": "AT"
            },
            {
                "name": "Schoppernau",
                "countryCode": "AT"
            },
            {
                "name": "Schruns",
                "countryCode": "AT"
            },
            {
                "name": "Schröcken",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzach",
                "countryCode": "AT"
            },
            {
                "name": "Schwarzenberg",
                "countryCode": "AT"
            },
            {
                "name": "Sibratsgfäll",
                "countryCode": "AT"
            },
            {
                "name": "Silbertal",
                "countryCode": "AT"
            },
            {
                "name": "Stallehr",
                "countryCode": "AT"
            },
            {
                "name": "Sulz",
                "countryCode": "AT"
            },
            {
                "name": "Sulzberg",
                "countryCode": "AT"
            },
            {
                "name": "Thüringen",
                "countryCode": "AT"
            },
            {
                "name": "Tschagguns",
                "countryCode": "AT"
            },
            {
                "name": "Vandans",
                "countryCode": "AT"
            },
            {
                "name": "Viktorsberg",
                "countryCode": "AT"
            },
            {
                "name": "Warth",
                "countryCode": "AT"
            },
            {
                "name": "Weiler",
                "countryCode": "AT"
            },
            {
                "name": "Wolfurt",
                "countryCode": "AT"
            },
            {
                "name": "Übersaxen",
                "countryCode": "AT"
            },
            {
                "name": "Donaustadt",
                "countryCode": "AT"
            },
            {
                "name": "Favoriten",
                "countryCode": "AT"
            },
            {
                "name": "Floridsdorf",
                "countryCode": "AT"
            },
            {
                "name": "Hernals",
                "countryCode": "AT"
            },
            {
                "name": "Hietzing",
                "countryCode": "AT"
            },
            {
                "name": "Innere Stadt",
                "countryCode": "AT"
            },
            {
                "name": "Meidling",
                "countryCode": "AT"
            },
            {
                "name": "Ottakring",
                "countryCode": "AT"
            },
            {
                "name": "Simmering",
                "countryCode": "AT"
            },
            {
                "name": "Vienna",
                "countryCode": "AT"
            }
        ]
    },
    {
        "name": "Azerbaijan",
        "phonecode": "994",
        "cities": [
            {
                "name": "Ceyranbatan",
                "countryCode": "AZ"
            },
            {
                "name": "Digah",
                "countryCode": "AZ"
            },
            {
                "name": "Gyuzdek",
                "countryCode": "AZ"
            },
            {
                "name": "Khirdalan",
                "countryCode": "AZ"
            },
            {
                "name": "Qobu",
                "countryCode": "AZ"
            },
            {
                "name": "Saray",
                "countryCode": "AZ"
            },
            {
                "name": "Aghstafa",
                "countryCode": "AZ"
            },
            {
                "name": "Saloğlu",
                "countryCode": "AZ"
            },
            {
                "name": "Vurğun",
                "countryCode": "AZ"
            },
            {
                "name": "Agdzhabedy",
                "countryCode": "AZ"
            },
            {
                "name": "Avşar",
                "countryCode": "AZ"
            },
            {
                "name": "Ağdam",
                "countryCode": "AZ"
            },
            {
                "name": "Ağdaş",
                "countryCode": "AZ"
            },
            {
                "name": "Aghsu",
                "countryCode": "AZ"
            },
            {
                "name": "Astara",
                "countryCode": "AZ"
            },
            {
                "name": "Kizhaba",
                "countryCode": "AZ"
            },
            {
                "name": "Amirdzhan",
                "countryCode": "AZ"
            },
            {
                "name": "Badamdar",
                "countryCode": "AZ"
            },
            {
                "name": "Baku",
                "countryCode": "AZ"
            },
            {
                "name": "Bakıxanov",
                "countryCode": "AZ"
            },
            {
                "name": "Balakhani",
                "countryCode": "AZ"
            },
            {
                "name": "Bilajari",
                "countryCode": "AZ"
            },
            {
                "name": "Bilajer",
                "countryCode": "AZ"
            },
            {
                "name": "Binagadi",
                "countryCode": "AZ"
            },
            {
                "name": "Biny Selo",
                "countryCode": "AZ"
            },
            {
                "name": "Buzovna",
                "countryCode": "AZ"
            },
            {
                "name": "Hövsan",
                "countryCode": "AZ"
            },
            {
                "name": "Khodzhi-Gasan",
                "countryCode": "AZ"
            },
            {
                "name": "Korgöz",
                "countryCode": "AZ"
            },
            {
                "name": "Lökbatan",
                "countryCode": "AZ"
            },
            {
                "name": "Mardakan",
                "countryCode": "AZ"
            },
            {
                "name": "Maştağa",
                "countryCode": "AZ"
            },
            {
                "name": "Nardaran",
                "countryCode": "AZ"
            },
            {
                "name": "Nizami Rayonu",
                "countryCode": "AZ"
            },
            {
                "name": "Pirallahı",
                "countryCode": "AZ"
            },
            {
                "name": "Puta",
                "countryCode": "AZ"
            },
            {
                "name": "Qala",
                "countryCode": "AZ"
            },
            {
                "name": "Qaraçuxur",
                "countryCode": "AZ"
            },
            {
                "name": "Qobustan",
                "countryCode": "AZ"
            },
            {
                "name": "Ramana",
                "countryCode": "AZ"
            },
            {
                "name": "Sabunçu",
                "countryCode": "AZ"
            },
            {
                "name": "Sanqaçal",
                "countryCode": "AZ"
            },
            {
                "name": "Türkan",
                "countryCode": "AZ"
            },
            {
                "name": "Yeni Suraxanı",
                "countryCode": "AZ"
            },
            {
                "name": "Zabrat",
                "countryCode": "AZ"
            },
            {
                "name": "Zyrya",
                "countryCode": "AZ"
            },
            {
                "name": "Belokany",
                "countryCode": "AZ"
            },
            {
                "name": "Qabaqçöl",
                "countryCode": "AZ"
            },
            {
                "name": "Barda",
                "countryCode": "AZ"
            },
            {
                "name": "Samuxlu",
                "countryCode": "AZ"
            },
            {
                "name": "Beylagan",
                "countryCode": "AZ"
            },
            {
                "name": "Birinci Aşıqlı",
                "countryCode": "AZ"
            },
            {
                "name": "Dünyamalılar",
                "countryCode": "AZ"
            },
            {
                "name": "Orjonikidze",
                "countryCode": "AZ"
            },
            {
                "name": "Yuxarı Aran",
                "countryCode": "AZ"
            },
            {
                "name": "Pushkino",
                "countryCode": "AZ"
            },
            {
                "name": "Jebrail",
                "countryCode": "AZ"
            },
            {
                "name": "Jalilabad",
                "countryCode": "AZ"
            },
            {
                "name": "Prishibinskoye",
                "countryCode": "AZ"
            },
            {
                "name": "Verkhniy Dashkesan",
                "countryCode": "AZ"
            },
            {
                "name": "Yukhary-Dashkesan",
                "countryCode": "AZ"
            },
            {
                "name": "Fizuli",
                "countryCode": "AZ"
            },
            {
                "name": "Horadiz",
                "countryCode": "AZ"
            },
            {
                "name": "Ganja",
                "countryCode": "AZ"
            },
            {
                "name": "Arıqdam",
                "countryCode": "AZ"
            },
            {
                "name": "Arıqıran",
                "countryCode": "AZ"
            },
            {
                "name": "Böyük Qaramurad",
                "countryCode": "AZ"
            },
            {
                "name": "Kyadabek",
                "countryCode": "AZ"
            },
            {
                "name": "Novosaratovka",
                "countryCode": "AZ"
            },
            {
                "name": "Goranboy",
                "countryCode": "AZ"
            },
            {
                "name": "Qızılhacılı",
                "countryCode": "AZ"
            },
            {
                "name": "Geoktschai",
                "countryCode": "AZ"
            },
            {
                "name": "Yelenendorf",
                "countryCode": "AZ"
            },
            {
                "name": "Hacıqabul",
                "countryCode": "AZ"
            },
            {
                "name": "Mughan",
                "countryCode": "AZ"
            },
            {
                "name": "Imishli",
                "countryCode": "AZ"
            },
            {
                "name": "Basqal",
                "countryCode": "AZ"
            },
            {
                "name": "İsmayıllı",
                "countryCode": "AZ"
            },
            {
                "name": "Kerbakhiar",
                "countryCode": "AZ"
            },
            {
                "name": "Vank",
                "countryCode": "AZ"
            },
            {
                "name": "Kyurdarmir",
                "countryCode": "AZ"
            },
            {
                "name": "Haftoni",
                "countryCode": "AZ"
            },
            {
                "name": "Lankaran",
                "countryCode": "AZ"
            },
            {
                "name": "Laçın",
                "countryCode": "AZ"
            },
            {
                "name": "Lerik",
                "countryCode": "AZ"
            },
            {
                "name": "Boradigah",
                "countryCode": "AZ"
            },
            {
                "name": "Masally",
                "countryCode": "AZ"
            },
            {
                "name": "Mingelchaur",
                "countryCode": "AZ"
            },
            {
                "name": "Neftçala",
                "countryCode": "AZ"
            },
            {
                "name": "Severo-Vostotchnyi Bank",
                "countryCode": "AZ"
            },
            {
                "name": "Sovetabad",
                "countryCode": "AZ"
            },
            {
                "name": "Xıllı",
                "countryCode": "AZ"
            },
            {
                "name": "Cahri",
                "countryCode": "AZ"
            },
            {
                "name": "Culfa",
                "countryCode": "AZ"
            },
            {
                "name": "Deste",
                "countryCode": "AZ"
            },
            {
                "name": "Heydarabad",
                "countryCode": "AZ"
            },
            {
                "name": "Julfa Rayon",
                "countryCode": "AZ"
            },
            {
                "name": "Nakhchivan",
                "countryCode": "AZ"
            },
            {
                "name": "Ordubad",
                "countryCode": "AZ"
            },
            {
                "name": "Ordubad Rayon",
                "countryCode": "AZ"
            },
            {
                "name": "Oğlanqala",
                "countryCode": "AZ"
            },
            {
                "name": "Qıvraq",
                "countryCode": "AZ"
            },
            {
                "name": "Sedarak",
                "countryCode": "AZ"
            },
            {
                "name": "Shahbuz Rayon",
                "countryCode": "AZ"
            },
            {
                "name": "Sharur City",
                "countryCode": "AZ"
            },
            {
                "name": "Sumbatan-diza",
                "countryCode": "AZ"
            },
            {
                "name": "Tazakend",
                "countryCode": "AZ"
            },
            {
                "name": "Yaycı",
                "countryCode": "AZ"
            },
            {
                "name": "Çalxanqala",
                "countryCode": "AZ"
            },
            {
                "name": "Şahbuz",
                "countryCode": "AZ"
            },
            {
                "name": "Oğuz",
                "countryCode": "AZ"
            },
            {
                "name": "Qutqashen",
                "countryCode": "AZ"
            },
            {
                "name": "Qax",
                "countryCode": "AZ"
            },
            {
                "name": "Qax İngiloy",
                "countryCode": "AZ"
            },
            {
                "name": "Qaxbaş",
                "countryCode": "AZ"
            },
            {
                "name": "Çinarlı",
                "countryCode": "AZ"
            },
            {
                "name": "Qazax",
                "countryCode": "AZ"
            },
            {
                "name": "Hacıhüseynli",
                "countryCode": "AZ"
            },
            {
                "name": "Quba",
                "countryCode": "AZ"
            },
            {
                "name": "Qubadlı",
                "countryCode": "AZ"
            },
            {
                "name": "Qobustan",
                "countryCode": "AZ"
            },
            {
                "name": "Qusar",
                "countryCode": "AZ"
            },
            {
                "name": "Samur",
                "countryCode": "AZ"
            },
            {
                "name": "Sheki",
                "countryCode": "AZ"
            },
            {
                "name": "Sabirabad",
                "countryCode": "AZ"
            },
            {
                "name": "Baş Göynük",
                "countryCode": "AZ"
            },
            {
                "name": "Qaraçala",
                "countryCode": "AZ"
            },
            {
                "name": "Salyan",
                "countryCode": "AZ"
            },
            {
                "name": "Saatlı",
                "countryCode": "AZ"
            },
            {
                "name": "Əhmədbəyli",
                "countryCode": "AZ"
            },
            {
                "name": "Divichibazar",
                "countryCode": "AZ"
            },
            {
                "name": "Gilgilçay",
                "countryCode": "AZ"
            },
            {
                "name": "Kyzyl-Burun",
                "countryCode": "AZ"
            },
            {
                "name": "Dolyar",
                "countryCode": "AZ"
            },
            {
                "name": "Dzagam",
                "countryCode": "AZ"
            },
            {
                "name": "Qasım İsmayılov",
                "countryCode": "AZ"
            },
            {
                "name": "Shamkhor",
                "countryCode": "AZ"
            },
            {
                "name": "Corat",
                "countryCode": "AZ"
            },
            {
                "name": "Hacı Zeynalabdin",
                "countryCode": "AZ"
            },
            {
                "name": "Sumqayıt",
                "countryCode": "AZ"
            },
            {
                "name": "Shamakhi",
                "countryCode": "AZ"
            },
            {
                "name": "Qarayeri",
                "countryCode": "AZ"
            },
            {
                "name": "Qırmızı Samux",
                "countryCode": "AZ"
            },
            {
                "name": "Samux",
                "countryCode": "AZ"
            },
            {
                "name": "Şirvan",
                "countryCode": "AZ"
            },
            {
                "name": "Shushi",
                "countryCode": "AZ"
            },
            {
                "name": "Martakert",
                "countryCode": "AZ"
            },
            {
                "name": "Terter",
                "countryCode": "AZ"
            },
            {
                "name": "Dondar Quşçu",
                "countryCode": "AZ"
            },
            {
                "name": "Qaraxanlı",
                "countryCode": "AZ"
            },
            {
                "name": "Tovuz",
                "countryCode": "AZ"
            },
            {
                "name": "Yanıqlı",
                "countryCode": "AZ"
            },
            {
                "name": "Çatax",
                "countryCode": "AZ"
            },
            {
                "name": "Çobansığnaq",
                "countryCode": "AZ"
            },
            {
                "name": "Ujar",
                "countryCode": "AZ"
            },
            {
                "name": "Xaçmaz",
                "countryCode": "AZ"
            },
            {
                "name": "Xudat",
                "countryCode": "AZ"
            },
            {
                "name": "Askyaran",
                "countryCode": "AZ"
            },
            {
                "name": "Xocalı",
                "countryCode": "AZ"
            },
            {
                "name": "Altıağac",
                "countryCode": "AZ"
            },
            {
                "name": "Khyzy",
                "countryCode": "AZ"
            },
            {
                "name": "Kilyazi",
                "countryCode": "AZ"
            },
            {
                "name": "Şuraabad",
                "countryCode": "AZ"
            },
            {
                "name": "Hadrut",
                "countryCode": "AZ"
            },
            {
                "name": "Novyy Karanlug",
                "countryCode": "AZ"
            },
            {
                "name": "Qırmızı Bazar",
                "countryCode": "AZ"
            },
            {
                "name": "Yardımlı",
                "countryCode": "AZ"
            },
            {
                "name": "Yevlakh",
                "countryCode": "AZ"
            },
            {
                "name": "Aran",
                "countryCode": "AZ"
            },
            {
                "name": "Qaramanlı",
                "countryCode": "AZ"
            },
            {
                "name": "Mincivan",
                "countryCode": "AZ"
            },
            {
                "name": "Zangilan",
                "countryCode": "AZ"
            },
            {
                "name": "Aliabad",
                "countryCode": "AZ"
            },
            {
                "name": "Faldarlı",
                "countryCode": "AZ"
            },
            {
                "name": "Mamrux",
                "countryCode": "AZ"
            },
            {
                "name": "Qandax",
                "countryCode": "AZ"
            },
            {
                "name": "Zaqatala",
                "countryCode": "AZ"
            },
            {
                "name": "Zardob",
                "countryCode": "AZ"
            }
        ]
    },
    {
        "name": "The Bahamas",
        "phonecode": "+1-242",
        "cities": [
            {
                "name": "Alice Town",
                "countryCode": "BS"
            },
            {
                "name": "Arthur’s Town",
                "countryCode": "BS"
            },
            {
                "name": "Colonel Hill",
                "countryCode": "BS"
            },
            {
                "name": "Marsh Harbour",
                "countryCode": "BS"
            },
            {
                "name": "High Rock",
                "countryCode": "BS"
            },
            {
                "name": "George Town",
                "countryCode": "BS"
            },
            {
                "name": "Freeport",
                "countryCode": "BS"
            },
            {
                "name": "Lucaya",
                "countryCode": "BS"
            },
            {
                "name": "Dunmore Town",
                "countryCode": "BS"
            },
            {
                "name": "Matthew Town",
                "countryCode": "BS"
            },
            {
                "name": "Clarence Town",
                "countryCode": "BS"
            },
            {
                "name": "Abraham’s Bay",
                "countryCode": "BS"
            },
            {
                "name": "Cooper’s Town",
                "countryCode": "BS"
            },
            {
                "name": "Nassau",
                "countryCode": "BS"
            },
            {
                "name": "Andros Town",
                "countryCode": "BS"
            },
            {
                "name": "San Andros",
                "countryCode": "BS"
            },
            {
                "name": "Port Nelson",
                "countryCode": "BS"
            },
            {
                "name": "Duncan Town",
                "countryCode": "BS"
            },
            {
                "name": "Cockburn Town",
                "countryCode": "BS"
            },
            {
                "name": "Spanish Wells",
                "countryCode": "BS"
            },
            {
                "name": "West End",
                "countryCode": "BS"
            }
        ]
    },
    {
        "name": "Bahrain",
        "phonecode": "973",
        "cities": [
            {
                "name": "Jidd Ḩafş",
                "countryCode": "BH"
            },
            {
                "name": "Manama",
                "countryCode": "BH"
            },
            {
                "name": "Sitrah",
                "countryCode": "BH"
            },
            {
                "name": "Ar Rifā‘",
                "countryCode": "BH"
            },
            {
                "name": "Dār Kulayb",
                "countryCode": "BH"
            },
            {
                "name": "Madīnat ‘Īsá",
                "countryCode": "BH"
            },
            {
                "name": "Al Muharraq",
                "countryCode": "BH"
            },
            {
                "name": "Al Ḩadd",
                "countryCode": "BH"
            },
            {
                "name": "Madīnat Ḩamad",
                "countryCode": "BH"
            }
        ]
    },
    {
        "name": "Bangladesh",
        "phonecode": "880",
        "cities": [
            {
                "name": "Barguna",
                "countryCode": "BD"
            },
            {
                "name": "Barisal",
                "countryCode": "BD"
            },
            {
                "name": "Bhola",
                "countryCode": "BD"
            },
            {
                "name": "Bhāndāria",
                "countryCode": "BD"
            },
            {
                "name": "Burhānuddin",
                "countryCode": "BD"
            },
            {
                "name": "Gaurnadi",
                "countryCode": "BD"
            },
            {
                "name": "Jhalokati",
                "countryCode": "BD"
            },
            {
                "name": "Lālmohan",
                "countryCode": "BD"
            },
            {
                "name": "Mathba",
                "countryCode": "BD"
            },
            {
                "name": "Mehendiganj",
                "countryCode": "BD"
            },
            {
                "name": "Nālchiti",
                "countryCode": "BD"
            },
            {
                "name": "Patuakhali",
                "countryCode": "BD"
            },
            {
                "name": "Pirojpur",
                "countryCode": "BD"
            },
            {
                "name": "Azimpur",
                "countryCode": "BD"
            },
            {
                "name": "Bhairab Bāzār",
                "countryCode": "BD"
            },
            {
                "name": "Bājitpur",
                "countryCode": "BD"
            },
            {
                "name": "Char Bhadrāsan",
                "countryCode": "BD"
            },
            {
                "name": "Char Golora",
                "countryCode": "BD"
            },
            {
                "name": "Dhaka",
                "countryCode": "BD"
            },
            {
                "name": "Dohār",
                "countryCode": "BD"
            },
            {
                "name": "Faridpur",
                "countryCode": "BD"
            },
            {
                "name": "Gazipur",
                "countryCode": "BD"
            },
            {
                "name": "Gopalganj",
                "countryCode": "BD"
            },
            {
                "name": "Joymontop",
                "countryCode": "BD"
            },
            {
                "name": "Khanbaniara",
                "countryCode": "BD"
            },
            {
                "name": "Kishoregonj",
                "countryCode": "BD"
            },
            {
                "name": "Kishorganj",
                "countryCode": "BD"
            },
            {
                "name": "Madaripur",
                "countryCode": "BD"
            },
            {
                "name": "Manikganj",
                "countryCode": "BD"
            },
            {
                "name": "Mirzāpur",
                "countryCode": "BD"
            },
            {
                "name": "Munshiganj",
                "countryCode": "BD"
            },
            {
                "name": "Narayanganj",
                "countryCode": "BD"
            },
            {
                "name": "Narsingdi",
                "countryCode": "BD"
            },
            {
                "name": "Nāgarpur",
                "countryCode": "BD"
            },
            {
                "name": "Paltan",
                "countryCode": "BD"
            },
            {
                "name": "Parvez Ali",
                "countryCode": "BD"
            },
            {
                "name": "Parvez Ali Hossain",
                "countryCode": "BD"
            },
            {
                "name": "Pālang",
                "countryCode": "BD"
            },
            {
                "name": "Rajbari",
                "countryCode": "BD"
            },
            {
                "name": "Ramnagar",
                "countryCode": "BD"
            },
            {
                "name": "Sakhipur",
                "countryCode": "BD"
            },
            {
                "name": "Sayani",
                "countryCode": "BD"
            },
            {
                "name": "Shariatpur",
                "countryCode": "BD"
            },
            {
                "name": "Sonārgaon",
                "countryCode": "BD"
            },
            {
                "name": "Tangail",
                "countryCode": "BD"
            },
            {
                "name": "Tungi",
                "countryCode": "BD"
            },
            {
                "name": "Tungipāra",
                "countryCode": "BD"
            },
            {
                "name": "Bagerhat",
                "countryCode": "BD"
            },
            {
                "name": "Bherāmāra",
                "countryCode": "BD"
            },
            {
                "name": "Bhātpāra Abhaynagar",
                "countryCode": "BD"
            },
            {
                "name": "Chuadanga",
                "countryCode": "BD"
            },
            {
                "name": "Jessore",
                "countryCode": "BD"
            },
            {
                "name": "Jhenaidah",
                "countryCode": "BD"
            },
            {
                "name": "Jhingergācha",
                "countryCode": "BD"
            },
            {
                "name": "Kesabpur",
                "countryCode": "BD"
            },
            {
                "name": "Khulna",
                "countryCode": "BD"
            },
            {
                "name": "Kushtia",
                "countryCode": "BD"
            },
            {
                "name": "Kālia",
                "countryCode": "BD"
            },
            {
                "name": "Kālīganj",
                "countryCode": "BD"
            },
            {
                "name": "Magura",
                "countryCode": "BD"
            },
            {
                "name": "Meherpur",
                "countryCode": "BD"
            },
            {
                "name": "Morrelgonj",
                "countryCode": "BD"
            },
            {
                "name": "Narail",
                "countryCode": "BD"
            },
            {
                "name": "Nowlamary",
                "countryCode": "BD"
            },
            {
                "name": "Phultala",
                "countryCode": "BD"
            },
            {
                "name": "Sarankhola",
                "countryCode": "BD"
            },
            {
                "name": "Satkhira",
                "countryCode": "BD"
            },
            {
                "name": "Ujalpur",
                "countryCode": "BD"
            },
            {
                "name": "Uttar Char Fasson",
                "countryCode": "BD"
            },
            {
                "name": "Gafargaon",
                "countryCode": "BD"
            },
            {
                "name": "Jamalpur",
                "countryCode": "BD"
            },
            {
                "name": "Muktāgācha",
                "countryCode": "BD"
            },
            {
                "name": "Mymensingh",
                "countryCode": "BD"
            },
            {
                "name": "Netrakona",
                "countryCode": "BD"
            },
            {
                "name": "Sarishābāri",
                "countryCode": "BD"
            },
            {
                "name": "Sherpur",
                "countryCode": "BD"
            },
            {
                "name": "Bera",
                "countryCode": "BD"
            },
            {
                "name": "Bogra",
                "countryCode": "BD"
            },
            {
                "name": "Chapai Nababganj",
                "countryCode": "BD"
            },
            {
                "name": "Ishurdi",
                "countryCode": "BD"
            },
            {
                "name": "Joypur Hāt",
                "countryCode": "BD"
            },
            {
                "name": "Joypurhat",
                "countryCode": "BD"
            },
            {
                "name": "Mahasthangarh",
                "countryCode": "BD"
            },
            {
                "name": "Naogaon",
                "countryCode": "BD"
            },
            {
                "name": "Natore",
                "countryCode": "BD"
            },
            {
                "name": "Nawābganj",
                "countryCode": "BD"
            },
            {
                "name": "Pabna",
                "countryCode": "BD"
            },
            {
                "name": "Puthia",
                "countryCode": "BD"
            },
            {
                "name": "Pār Naogaon",
                "countryCode": "BD"
            },
            {
                "name": "Rajshahi",
                "countryCode": "BD"
            },
            {
                "name": "Saidpur",
                "countryCode": "BD"
            },
            {
                "name": "Shibganj",
                "countryCode": "BD"
            },
            {
                "name": "Shāhzādpur",
                "countryCode": "BD"
            },
            {
                "name": "Sirajganj",
                "countryCode": "BD"
            },
            {
                "name": "Badarganj",
                "countryCode": "BD"
            },
            {
                "name": "Chilmāri",
                "countryCode": "BD"
            },
            {
                "name": "Dinajpur",
                "countryCode": "BD"
            },
            {
                "name": "Gaibandha",
                "countryCode": "BD"
            },
            {
                "name": "Kurigram",
                "countryCode": "BD"
            },
            {
                "name": "Lalmonirhat",
                "countryCode": "BD"
            },
            {
                "name": "Lalmonirhat District",
                "countryCode": "BD"
            },
            {
                "name": "Nageswari",
                "countryCode": "BD"
            },
            {
                "name": "Nilphamari Zila",
                "countryCode": "BD"
            },
            {
                "name": "Panchagarh",
                "countryCode": "BD"
            },
            {
                "name": "Parbatipur",
                "countryCode": "BD"
            },
            {
                "name": "Pīrgaaj",
                "countryCode": "BD"
            },
            {
                "name": "Rangpur",
                "countryCode": "BD"
            },
            {
                "name": "Thakurgaon",
                "countryCode": "BD"
            },
            {
                "name": "Baniachang",
                "countryCode": "BD"
            },
            {
                "name": "Chhātak",
                "countryCode": "BD"
            },
            {
                "name": "Habiganj",
                "countryCode": "BD"
            },
            {
                "name": "Jahedpur",
                "countryCode": "BD"
            },
            {
                "name": "Maulavi Bāzār",
                "countryCode": "BD"
            },
            {
                "name": "Maulvibazar",
                "countryCode": "BD"
            },
            {
                "name": "Sunamganj",
                "countryCode": "BD"
            },
            {
                "name": "Sylhet",
                "countryCode": "BD"
            },
            {
                "name": "Bandarban",
                "countryCode": "BD"
            },
            {
                "name": "Bibir Hat",
                "countryCode": "BD"
            },
            {
                "name": "Brahmanbaria",
                "countryCode": "BD"
            },
            {
                "name": "Chandpur",
                "countryCode": "BD"
            },
            {
                "name": "Chhāgalnāiya",
                "countryCode": "BD"
            },
            {
                "name": "Chittagong",
                "countryCode": "BD"
            },
            {
                "name": "Comilla",
                "countryCode": "BD"
            },
            {
                "name": "Cox's Bazar",
                "countryCode": "BD"
            },
            {
                "name": "Cox’s Bāzār",
                "countryCode": "BD"
            },
            {
                "name": "Feni",
                "countryCode": "BD"
            },
            {
                "name": "Hājīganj",
                "countryCode": "BD"
            },
            {
                "name": "Khagrachhari",
                "countryCode": "BD"
            },
            {
                "name": "Lakshmipur",
                "countryCode": "BD"
            },
            {
                "name": "Lākshām",
                "countryCode": "BD"
            },
            {
                "name": "Manikchari",
                "countryCode": "BD"
            },
            {
                "name": "Nabīnagar",
                "countryCode": "BD"
            },
            {
                "name": "Noakhali",
                "countryCode": "BD"
            },
            {
                "name": "Patiya",
                "countryCode": "BD"
            },
            {
                "name": "Rangamati",
                "countryCode": "BD"
            },
            {
                "name": "Raojān",
                "countryCode": "BD"
            },
            {
                "name": "Rāipur",
                "countryCode": "BD"
            },
            {
                "name": "Rāmganj",
                "countryCode": "BD"
            },
            {
                "name": "Sandwīp",
                "countryCode": "BD"
            },
            {
                "name": "Sātkania",
                "countryCode": "BD"
            },
            {
                "name": "Teknāf",
                "countryCode": "BD"
            }
        ]
    },
    {
        "name": "Barbados",
        "phonecode": "+1-246",
        "cities": [
            {
                "name": "Oistins",
                "countryCode": "BB"
            },
            {
                "name": "Greenland",
                "countryCode": "BB"
            },
            {
                "name": "Holetown",
                "countryCode": "BB"
            },
            {
                "name": "Bathsheba",
                "countryCode": "BB"
            },
            {
                "name": "Bridgetown",
                "countryCode": "BB"
            },
            {
                "name": "Speightstown",
                "countryCode": "BB"
            },
            {
                "name": "Crane",
                "countryCode": "BB"
            }
        ]
    },
    {
        "name": "Belarus",
        "phonecode": "375",
        "cities": [
            {
                "name": "Antopal’",
                "countryCode": "BY"
            },
            {
                "name": "Asnyezhytsy",
                "countryCode": "BY"
            },
            {
                "name": "Baranovichi",
                "countryCode": "BY"
            },
            {
                "name": "Baranovichskiy Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Brest",
                "countryCode": "BY"
            },
            {
                "name": "Brestski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Byaroza",
                "countryCode": "BY"
            },
            {
                "name": "Byarozawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Byelaazyorsk",
                "countryCode": "BY"
            },
            {
                "name": "Charnawchytsy",
                "countryCode": "BY"
            },
            {
                "name": "Damachava",
                "countryCode": "BY"
            },
            {
                "name": "Davyd-Haradok",
                "countryCode": "BY"
            },
            {
                "name": "Drahichyn",
                "countryCode": "BY"
            },
            {
                "name": "Drahichynski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Hantsavichy",
                "countryCode": "BY"
            },
            {
                "name": "Hantsavitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Haradzishcha",
                "countryCode": "BY"
            },
            {
                "name": "Horad Baranavichy",
                "countryCode": "BY"
            },
            {
                "name": "Horad Brest",
                "countryCode": "BY"
            },
            {
                "name": "Ivanava",
                "countryCode": "BY"
            },
            {
                "name": "Ivanawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Ivatsevichy",
                "countryCode": "BY"
            },
            {
                "name": "Kamyanyets",
                "countryCode": "BY"
            },
            {
                "name": "Kamyanyetski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Kamyanyuki",
                "countryCode": "BY"
            },
            {
                "name": "Kobryn",
                "countryCode": "BY"
            },
            {
                "name": "Kosava",
                "countryCode": "BY"
            },
            {
                "name": "Lahishyn",
                "countryCode": "BY"
            },
            {
                "name": "Luninyets",
                "countryCode": "BY"
            },
            {
                "name": "Lyakhavichy",
                "countryCode": "BY"
            },
            {
                "name": "Malaryta",
                "countryCode": "BY"
            },
            {
                "name": "Mikashevichy",
                "countryCode": "BY"
            },
            {
                "name": "Motal’",
                "countryCode": "BY"
            },
            {
                "name": "Nyakhachava",
                "countryCode": "BY"
            },
            {
                "name": "Pinsk",
                "countryCode": "BY"
            },
            {
                "name": "Pruzhanski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Pruzhany",
                "countryCode": "BY"
            },
            {
                "name": "Ruzhany",
                "countryCode": "BY"
            },
            {
                "name": "Stolin",
                "countryCode": "BY"
            },
            {
                "name": "Stolinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Tsyelyakhany",
                "countryCode": "BY"
            },
            {
                "name": "Vysokaye",
                "countryCode": "BY"
            },
            {
                "name": "Zhabinka",
                "countryCode": "BY"
            },
            {
                "name": "Zhabinkawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Znamenka",
                "countryCode": "BY"
            },
            {
                "name": "Frunzyenski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Kastrychnitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Lyeninski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Maskowski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Minsk",
                "countryCode": "BY"
            },
            {
                "name": "Partyzanski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Savyetski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Tsentral’ny Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Zavodski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Aktsyabrski",
                "countryCode": "BY"
            },
            {
                "name": "Brahin",
                "countryCode": "BY"
            },
            {
                "name": "Brahinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Buda-Kashalyova",
                "countryCode": "BY"
            },
            {
                "name": "Chachersk",
                "countryCode": "BY"
            },
            {
                "name": "Chacherski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Dobrush",
                "countryCode": "BY"
            },
            {
                "name": "Dowsk",
                "countryCode": "BY"
            },
            {
                "name": "Homyel'",
                "countryCode": "BY"
            },
            {
                "name": "Homyel’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Kalinkavichy",
                "countryCode": "BY"
            },
            {
                "name": "Karanyowka",
                "countryCode": "BY"
            },
            {
                "name": "Karma",
                "countryCode": "BY"
            },
            {
                "name": "Kastsyukowka",
                "countryCode": "BY"
            },
            {
                "name": "Khal’ch",
                "countryCode": "BY"
            },
            {
                "name": "Khoyniki",
                "countryCode": "BY"
            },
            {
                "name": "Loyew",
                "countryCode": "BY"
            },
            {
                "name": "Lyel’chytski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Lyel’chytsy",
                "countryCode": "BY"
            },
            {
                "name": "Mazyr",
                "countryCode": "BY"
            },
            {
                "name": "Mazyrski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Narowlya",
                "countryCode": "BY"
            },
            {
                "name": "Novaya Huta",
                "countryCode": "BY"
            },
            {
                "name": "Parychy",
                "countryCode": "BY"
            },
            {
                "name": "Peramoga",
                "countryCode": "BY"
            },
            {
                "name": "Pyetrykaw",
                "countryCode": "BY"
            },
            {
                "name": "Rahachow",
                "countryCode": "BY"
            },
            {
                "name": "Rahachowski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Rechytsa",
                "countryCode": "BY"
            },
            {
                "name": "Sasnovy Bor",
                "countryCode": "BY"
            },
            {
                "name": "Svyetlahorsk",
                "countryCode": "BY"
            },
            {
                "name": "Turaw",
                "countryCode": "BY"
            },
            {
                "name": "Vasilyevichy",
                "countryCode": "BY"
            },
            {
                "name": "Vyetka",
                "countryCode": "BY"
            },
            {
                "name": "Vyetkawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Yel’sk",
                "countryCode": "BY"
            },
            {
                "name": "Zhlobin",
                "countryCode": "BY"
            },
            {
                "name": "Zhlobinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Zhytkavichy",
                "countryCode": "BY"
            },
            {
                "name": "Ashmyanski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Ashmyany",
                "countryCode": "BY"
            },
            {
                "name": "Astravyets",
                "countryCode": "BY"
            },
            {
                "name": "Astravyetski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Baruny",
                "countryCode": "BY"
            },
            {
                "name": "Byarozawka",
                "countryCode": "BY"
            },
            {
                "name": "Dyatlovo",
                "countryCode": "BY"
            },
            {
                "name": "Grodnenskiy Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Hal’shany",
                "countryCode": "BY"
            },
            {
                "name": "Horad Hrodna",
                "countryCode": "BY"
            },
            {
                "name": "Hrodna",
                "countryCode": "BY"
            },
            {
                "name": "Hyeranyony",
                "countryCode": "BY"
            },
            {
                "name": "Indura",
                "countryCode": "BY"
            },
            {
                "name": "Iwye",
                "countryCode": "BY"
            },
            {
                "name": "Karelichy",
                "countryCode": "BY"
            },
            {
                "name": "Karelitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Krasnosel’skiy",
                "countryCode": "BY"
            },
            {
                "name": "Kreva",
                "countryCode": "BY"
            },
            {
                "name": "Lida",
                "countryCode": "BY"
            },
            {
                "name": "Lidski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Lyubcha",
                "countryCode": "BY"
            },
            {
                "name": "Mir",
                "countryCode": "BY"
            },
            {
                "name": "Mosty",
                "countryCode": "BY"
            },
            {
                "name": "Novogrudok",
                "countryCode": "BY"
            },
            {
                "name": "Ross’",
                "countryCode": "BY"
            },
            {
                "name": "Sapotskin",
                "countryCode": "BY"
            },
            {
                "name": "Shchuchyn",
                "countryCode": "BY"
            },
            {
                "name": "Shchuchynski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Skidel’",
                "countryCode": "BY"
            },
            {
                "name": "Slonim",
                "countryCode": "BY"
            },
            {
                "name": "Smarhon’",
                "countryCode": "BY"
            },
            {
                "name": "Soly",
                "countryCode": "BY"
            },
            {
                "name": "Svislach",
                "countryCode": "BY"
            },
            {
                "name": "Vishnyeva",
                "countryCode": "BY"
            },
            {
                "name": "Volkovysk",
                "countryCode": "BY"
            },
            {
                "name": "Voranava",
                "countryCode": "BY"
            },
            {
                "name": "Vyalikaya Byerastavitsa",
                "countryCode": "BY"
            },
            {
                "name": "Zel’va",
                "countryCode": "BY"
            },
            {
                "name": "Zhaludok",
                "countryCode": "BY"
            },
            {
                "name": "Zhirovichi",
                "countryCode": "BY"
            },
            {
                "name": "Asipovichy",
                "countryCode": "BY"
            },
            {
                "name": "Asipovitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Babruysk",
                "countryCode": "BY"
            },
            {
                "name": "Babruyski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Buynichy",
                "countryCode": "BY"
            },
            {
                "name": "Byalynichy",
                "countryCode": "BY"
            },
            {
                "name": "Byalynitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Bykhaw",
                "countryCode": "BY"
            },
            {
                "name": "Chavuski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Chavusy",
                "countryCode": "BY"
            },
            {
                "name": "Cherykaw",
                "countryCode": "BY"
            },
            {
                "name": "Cherykawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Dashkawka",
                "countryCode": "BY"
            },
            {
                "name": "Drybin",
                "countryCode": "BY"
            },
            {
                "name": "Drybinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Hlusha",
                "countryCode": "BY"
            },
            {
                "name": "Hlusk",
                "countryCode": "BY"
            },
            {
                "name": "Horatski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Horki",
                "countryCode": "BY"
            },
            {
                "name": "Kadino",
                "countryCode": "BY"
            },
            {
                "name": "Kamyennyya Lavy",
                "countryCode": "BY"
            },
            {
                "name": "Kastsyukovichy",
                "countryCode": "BY"
            },
            {
                "name": "Khodasy",
                "countryCode": "BY"
            },
            {
                "name": "Khotsimsk",
                "countryCode": "BY"
            },
            {
                "name": "Khotsimski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Kirawsk",
                "countryCode": "BY"
            },
            {
                "name": "Klichaw",
                "countryCode": "BY"
            },
            {
                "name": "Klimavichy",
                "countryCode": "BY"
            },
            {
                "name": "Krasnapollye",
                "countryCode": "BY"
            },
            {
                "name": "Krasnapol’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Krasnyy Bereg",
                "countryCode": "BY"
            },
            {
                "name": "Kruhlaye",
                "countryCode": "BY"
            },
            {
                "name": "Krychaw",
                "countryCode": "BY"
            },
            {
                "name": "Mahilyow",
                "countryCode": "BY"
            },
            {
                "name": "Mahilyowski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Mstsislaw",
                "countryCode": "BY"
            },
            {
                "name": "Myazhysyatki",
                "countryCode": "BY"
            },
            {
                "name": "Myshkavichy",
                "countryCode": "BY"
            },
            {
                "name": "Palykavichy Pyershyya",
                "countryCode": "BY"
            },
            {
                "name": "Posëlok Voskhod",
                "countryCode": "BY"
            },
            {
                "name": "Ramanavichy",
                "countryCode": "BY"
            },
            {
                "name": "Shklow",
                "countryCode": "BY"
            },
            {
                "name": "Shklowski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Slawharad",
                "countryCode": "BY"
            },
            {
                "name": "Veyno",
                "countryCode": "BY"
            },
            {
                "name": "Vishow",
                "countryCode": "BY"
            },
            {
                "name": "Yalizava",
                "countryCode": "BY"
            },
            {
                "name": "Astrashytski Haradok",
                "countryCode": "BY"
            },
            {
                "name": "Atolina",
                "countryCode": "BY"
            },
            {
                "name": "Azyartso",
                "countryCode": "BY"
            },
            {
                "name": "Bal’shavik",
                "countryCode": "BY"
            },
            {
                "name": "Barysaw",
                "countryCode": "BY"
            },
            {
                "name": "Barysawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Blon’",
                "countryCode": "BY"
            },
            {
                "name": "Bobr",
                "countryCode": "BY"
            },
            {
                "name": "Borovlyany",
                "countryCode": "BY"
            },
            {
                "name": "Byarezinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Byerazino",
                "countryCode": "BY"
            },
            {
                "name": "Chervyen’",
                "countryCode": "BY"
            },
            {
                "name": "Chervyen’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Chyrvonaya Slabada",
                "countryCode": "BY"
            },
            {
                "name": "Chyst’",
                "countryCode": "BY"
            },
            {
                "name": "Druzhny",
                "countryCode": "BY"
            },
            {
                "name": "Dukora",
                "countryCode": "BY"
            },
            {
                "name": "Dzyarzhynsk",
                "countryCode": "BY"
            },
            {
                "name": "Enyerhyetykaw",
                "countryCode": "BY"
            },
            {
                "name": "Fanipol",
                "countryCode": "BY"
            },
            {
                "name": "Haradzyeya",
                "countryCode": "BY"
            },
            {
                "name": "Hatava",
                "countryCode": "BY"
            },
            {
                "name": "Horad Smalyavichy",
                "countryCode": "BY"
            },
            {
                "name": "Horad Zhodzina",
                "countryCode": "BY"
            },
            {
                "name": "Hotsk",
                "countryCode": "BY"
            },
            {
                "name": "Il’ya",
                "countryCode": "BY"
            },
            {
                "name": "Ivyanyets",
                "countryCode": "BY"
            },
            {
                "name": "Kalodzishchy",
                "countryCode": "BY"
            },
            {
                "name": "Kapyl’",
                "countryCode": "BY"
            },
            {
                "name": "Kapyl’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Khatsyezhyna",
                "countryCode": "BY"
            },
            {
                "name": "Kholopenichi",
                "countryCode": "BY"
            },
            {
                "name": "Klyetsk",
                "countryCode": "BY"
            },
            {
                "name": "Klyetski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Korolëv Stan",
                "countryCode": "BY"
            },
            {
                "name": "Krasnaye",
                "countryCode": "BY"
            },
            {
                "name": "Krupki",
                "countryCode": "BY"
            },
            {
                "name": "Kryvichy",
                "countryCode": "BY"
            },
            {
                "name": "Lahoysk",
                "countryCode": "BY"
            },
            {
                "name": "Loshnitsa",
                "countryCode": "BY"
            },
            {
                "name": "Luhavaya Slabada",
                "countryCode": "BY"
            },
            {
                "name": "Lyasny",
                "countryCode": "BY"
            },
            {
                "name": "Lyeskawka",
                "countryCode": "BY"
            },
            {
                "name": "Lyuban’",
                "countryCode": "BY"
            },
            {
                "name": "Machulishchy",
                "countryCode": "BY"
            },
            {
                "name": "Maladzyechna",
                "countryCode": "BY"
            },
            {
                "name": "Mar’’ina Horka",
                "countryCode": "BY"
            },
            {
                "name": "Michanovichi",
                "countryCode": "BY"
            },
            {
                "name": "Myadzyel",
                "countryCode": "BY"
            },
            {
                "name": "Myadzyel’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Narach",
                "countryCode": "BY"
            },
            {
                "name": "Nasilava",
                "countryCode": "BY"
            },
            {
                "name": "Novosel’ye",
                "countryCode": "BY"
            },
            {
                "name": "Novy Svyerzhan’",
                "countryCode": "BY"
            },
            {
                "name": "Nyasvizh",
                "countryCode": "BY"
            },
            {
                "name": "Nyasvizhski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Plyeshchanitsy",
                "countryCode": "BY"
            },
            {
                "name": "Prawdzinski",
                "countryCode": "BY"
            },
            {
                "name": "Pryvol’ny",
                "countryCode": "BY"
            },
            {
                "name": "Pukhavichy",
                "countryCode": "BY"
            },
            {
                "name": "Pukhavichy Raion",
                "countryCode": "BY"
            },
            {
                "name": "Pyatryshki",
                "countryCode": "BY"
            },
            {
                "name": "Radashkovichy",
                "countryCode": "BY"
            },
            {
                "name": "Rakaw",
                "countryCode": "BY"
            },
            {
                "name": "Rudzyensk",
                "countryCode": "BY"
            },
            {
                "name": "Salihorsk",
                "countryCode": "BY"
            },
            {
                "name": "Samakhvalavichy",
                "countryCode": "BY"
            },
            {
                "name": "Sarachy",
                "countryCode": "BY"
            },
            {
                "name": "Schomyslitsa",
                "countryCode": "BY"
            },
            {
                "name": "Slabada",
                "countryCode": "BY"
            },
            {
                "name": "Slutsk",
                "countryCode": "BY"
            },
            {
                "name": "Slutski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Smalyavitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Smilavichy",
                "countryCode": "BY"
            },
            {
                "name": "Snow",
                "countryCode": "BY"
            },
            {
                "name": "Stan’kava",
                "countryCode": "BY"
            },
            {
                "name": "Staradarozhski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Starobin",
                "countryCode": "BY"
            },
            {
                "name": "Staryya Darohi",
                "countryCode": "BY"
            },
            {
                "name": "Stawbtsowski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Stowbtsy",
                "countryCode": "BY"
            },
            {
                "name": "Svir",
                "countryCode": "BY"
            },
            {
                "name": "Svislach",
                "countryCode": "BY"
            },
            {
                "name": "Syenitsa",
                "countryCode": "BY"
            },
            {
                "name": "Syomkava",
                "countryCode": "BY"
            },
            {
                "name": "Tsimkavichy",
                "countryCode": "BY"
            },
            {
                "name": "Turets-Bayary",
                "countryCode": "BY"
            },
            {
                "name": "Urechcha",
                "countryCode": "BY"
            },
            {
                "name": "Usiazh",
                "countryCode": "BY"
            },
            {
                "name": "Uzda",
                "countryCode": "BY"
            },
            {
                "name": "Uzdzyenski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Valozhyn",
                "countryCode": "BY"
            },
            {
                "name": "Vilyeyka",
                "countryCode": "BY"
            },
            {
                "name": "Vilyeyski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Vyaliki Trastsyanets",
                "countryCode": "BY"
            },
            {
                "name": "Yubilyeyny",
                "countryCode": "BY"
            },
            {
                "name": "Zamostochye",
                "countryCode": "BY"
            },
            {
                "name": "Zaslawye",
                "countryCode": "BY"
            },
            {
                "name": "Zhdanovichy",
                "countryCode": "BY"
            },
            {
                "name": "Zyembin",
                "countryCode": "BY"
            },
            {
                "name": "Октябрьский",
                "countryCode": "BY"
            },
            {
                "name": "Balbasava",
                "countryCode": "BY"
            },
            {
                "name": "Baran’",
                "countryCode": "BY"
            },
            {
                "name": "Braslaw",
                "countryCode": "BY"
            },
            {
                "name": "Braslawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Byahoml’",
                "countryCode": "BY"
            },
            {
                "name": "Byeshankovitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Chashniki",
                "countryCode": "BY"
            },
            {
                "name": "Chashnitski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Dokshytski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Dokshytsy",
                "countryCode": "BY"
            },
            {
                "name": "Druya",
                "countryCode": "BY"
            },
            {
                "name": "Dubrowna",
                "countryCode": "BY"
            },
            {
                "name": "Dzisna",
                "countryCode": "BY"
            },
            {
                "name": "Haradok",
                "countryCode": "BY"
            },
            {
                "name": "Haradotski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Hlybokaye",
                "countryCode": "BY"
            },
            {
                "name": "Hlybotski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Kokhanava",
                "countryCode": "BY"
            },
            {
                "name": "Konstantinovo",
                "countryCode": "BY"
            },
            {
                "name": "Lyepyel’",
                "countryCode": "BY"
            },
            {
                "name": "Lyepyel’ski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Lyntupy",
                "countryCode": "BY"
            },
            {
                "name": "Lyozna",
                "countryCode": "BY"
            },
            {
                "name": "Lyoznyenski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Mosar",
                "countryCode": "BY"
            },
            {
                "name": "Myorski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Myory",
                "countryCode": "BY"
            },
            {
                "name": "Navapolatsk",
                "countryCode": "BY"
            },
            {
                "name": "Novolukoml’",
                "countryCode": "BY"
            },
            {
                "name": "Orsha",
                "countryCode": "BY"
            },
            {
                "name": "Osveya",
                "countryCode": "BY"
            },
            {
                "name": "Pastavy",
                "countryCode": "BY"
            },
            {
                "name": "Pastawski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Polatsk",
                "countryCode": "BY"
            },
            {
                "name": "Polatski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Rasonski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Rasony",
                "countryCode": "BY"
            },
            {
                "name": "Sharkawshchyna",
                "countryCode": "BY"
            },
            {
                "name": "Sharkawshchynski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Shumilinski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Syanno",
                "countryCode": "BY"
            },
            {
                "name": "Syennyenski Rayon",
                "countryCode": "BY"
            },
            {
                "name": "Talachyn",
                "countryCode": "BY"
            },
            {
                "name": "Ushachy",
                "countryCode": "BY"
            },
            {
                "name": "Vidzy",
                "countryCode": "BY"
            },
            {
                "name": "Vitebsk",
                "countryCode": "BY"
            },
            {
                "name": "Vyerkhnyadzvinsk",
                "countryCode": "BY"
            },
            {
                "name": "Vyerkhnyadzvinski Rayon",
                "countryCode": "BY"
            }
        ]
    },
    {
        "name": "Belgium",
        "phonecode": "32",
        "cities": [
            {
                "name": "Brussels",
                "countryCode": "BE"
            },
            {
                "name": "Aalst",
                "countryCode": "BE"
            },
            {
                "name": "Aalter",
                "countryCode": "BE"
            },
            {
                "name": "Aarschot",
                "countryCode": "BE"
            },
            {
                "name": "Aartselaar",
                "countryCode": "BE"
            },
            {
                "name": "Alken",
                "countryCode": "BE"
            },
            {
                "name": "Alveringem",
                "countryCode": "BE"
            },
            {
                "name": "Antwerpen",
                "countryCode": "BE"
            },
            {
                "name": "Anzegem",
                "countryCode": "BE"
            },
            {
                "name": "Ardooie",
                "countryCode": "BE"
            },
            {
                "name": "Arendonk",
                "countryCode": "BE"
            },
            {
                "name": "As",
                "countryCode": "BE"
            },
            {
                "name": "Asse",
                "countryCode": "BE"
            },
            {
                "name": "Assenede",
                "countryCode": "BE"
            },
            {
                "name": "Avelgem",
                "countryCode": "BE"
            },
            {
                "name": "Baarle-Hertog",
                "countryCode": "BE"
            },
            {
                "name": "Balen",
                "countryCode": "BE"
            },
            {
                "name": "Beernem",
                "countryCode": "BE"
            },
            {
                "name": "Beerse",
                "countryCode": "BE"
            },
            {
                "name": "Beersel",
                "countryCode": "BE"
            },
            {
                "name": "Begijnendijk",
                "countryCode": "BE"
            },
            {
                "name": "Bekkevoort",
                "countryCode": "BE"
            },
            {
                "name": "Beringen",
                "countryCode": "BE"
            },
            {
                "name": "Berlaar",
                "countryCode": "BE"
            },
            {
                "name": "Berlare",
                "countryCode": "BE"
            },
            {
                "name": "Bertem",
                "countryCode": "BE"
            },
            {
                "name": "Bever",
                "countryCode": "BE"
            },
            {
                "name": "Beveren",
                "countryCode": "BE"
            },
            {
                "name": "Bierbeek",
                "countryCode": "BE"
            },
            {
                "name": "Bilzen",
                "countryCode": "BE"
            },
            {
                "name": "Blankenberge",
                "countryCode": "BE"
            },
            {
                "name": "Bocholt",
                "countryCode": "BE"
            },
            {
                "name": "Boechout",
                "countryCode": "BE"
            },
            {
                "name": "Bonheiden",
                "countryCode": "BE"
            },
            {
                "name": "Boom",
                "countryCode": "BE"
            },
            {
                "name": "Boortmeerbeek",
                "countryCode": "BE"
            },
            {
                "name": "Borgloon",
                "countryCode": "BE"
            },
            {
                "name": "Bornem",
                "countryCode": "BE"
            },
            {
                "name": "Borsbeek",
                "countryCode": "BE"
            },
            {
                "name": "Boutersem",
                "countryCode": "BE"
            },
            {
                "name": "Brasschaat",
                "countryCode": "BE"
            },
            {
                "name": "Brecht",
                "countryCode": "BE"
            },
            {
                "name": "Bredene",
                "countryCode": "BE"
            },
            {
                "name": "Bree",
                "countryCode": "BE"
            },
            {
                "name": "Brugge",
                "countryCode": "BE"
            },
            {
                "name": "Buggenhout",
                "countryCode": "BE"
            },
            {
                "name": "Damme",
                "countryCode": "BE"
            },
            {
                "name": "De Haan",
                "countryCode": "BE"
            },
            {
                "name": "De Panne",
                "countryCode": "BE"
            },
            {
                "name": "De Pinte",
                "countryCode": "BE"
            },
            {
                "name": "Deerlijk",
                "countryCode": "BE"
            },
            {
                "name": "Deinze",
                "countryCode": "BE"
            },
            {
                "name": "Denderleeuw",
                "countryCode": "BE"
            },
            {
                "name": "Dendermonde",
                "countryCode": "BE"
            },
            {
                "name": "Dentergem",
                "countryCode": "BE"
            },
            {
                "name": "Dessel",
                "countryCode": "BE"
            },
            {
                "name": "Destelbergen",
                "countryCode": "BE"
            },
            {
                "name": "Deurne",
                "countryCode": "BE"
            },
            {
                "name": "Diegem",
                "countryCode": "BE"
            },
            {
                "name": "Diepenbeek",
                "countryCode": "BE"
            },
            {
                "name": "Diest",
                "countryCode": "BE"
            },
            {
                "name": "Diksmuide",
                "countryCode": "BE"
            },
            {
                "name": "Dilbeek",
                "countryCode": "BE"
            },
            {
                "name": "Drogenbos",
                "countryCode": "BE"
            },
            {
                "name": "Duffel",
                "countryCode": "BE"
            },
            {
                "name": "Edegem",
                "countryCode": "BE"
            },
            {
                "name": "Eeklo",
                "countryCode": "BE"
            },
            {
                "name": "Essen",
                "countryCode": "BE"
            },
            {
                "name": "Evergem",
                "countryCode": "BE"
            },
            {
                "name": "Galmaarden",
                "countryCode": "BE"
            },
            {
                "name": "Gavere",
                "countryCode": "BE"
            },
            {
                "name": "Geel",
                "countryCode": "BE"
            },
            {
                "name": "Geetbets",
                "countryCode": "BE"
            },
            {
                "name": "Genk",
                "countryCode": "BE"
            },
            {
                "name": "Gent",
                "countryCode": "BE"
            },
            {
                "name": "Geraardsbergen",
                "countryCode": "BE"
            },
            {
                "name": "Gingelom",
                "countryCode": "BE"
            },
            {
                "name": "Gistel",
                "countryCode": "BE"
            },
            {
                "name": "Glabbeek",
                "countryCode": "BE"
            },
            {
                "name": "Gooik",
                "countryCode": "BE"
            },
            {
                "name": "Grimbergen",
                "countryCode": "BE"
            },
            {
                "name": "Grobbendonk",
                "countryCode": "BE"
            },
            {
                "name": "Haacht",
                "countryCode": "BE"
            },
            {
                "name": "Haaltert",
                "countryCode": "BE"
            },
            {
                "name": "Halen",
                "countryCode": "BE"
            },
            {
                "name": "Halle",
                "countryCode": "BE"
            },
            {
                "name": "Hamme",
                "countryCode": "BE"
            },
            {
                "name": "Harelbeke",
                "countryCode": "BE"
            },
            {
                "name": "Hasselt",
                "countryCode": "BE"
            },
            {
                "name": "Heers",
                "countryCode": "BE"
            },
            {
                "name": "Heist-op-den-Berg",
                "countryCode": "BE"
            },
            {
                "name": "Helchteren",
                "countryCode": "BE"
            },
            {
                "name": "Hemiksem",
                "countryCode": "BE"
            },
            {
                "name": "Herent",
                "countryCode": "BE"
            },
            {
                "name": "Herentals",
                "countryCode": "BE"
            },
            {
                "name": "Herenthout",
                "countryCode": "BE"
            },
            {
                "name": "Herk-de-Stad",
                "countryCode": "BE"
            },
            {
                "name": "Herne",
                "countryCode": "BE"
            },
            {
                "name": "Herselt",
                "countryCode": "BE"
            },
            {
                "name": "Herzele",
                "countryCode": "BE"
            },
            {
                "name": "Heusden",
                "countryCode": "BE"
            },
            {
                "name": "Hoboken",
                "countryCode": "BE"
            },
            {
                "name": "Hoegaarden",
                "countryCode": "BE"
            },
            {
                "name": "Hoeilaart",
                "countryCode": "BE"
            },
            {
                "name": "Hoeselt",
                "countryCode": "BE"
            },
            {
                "name": "Holsbeek",
                "countryCode": "BE"
            },
            {
                "name": "Hooglede",
                "countryCode": "BE"
            },
            {
                "name": "Hoogstraten",
                "countryCode": "BE"
            },
            {
                "name": "Houthalen",
                "countryCode": "BE"
            },
            {
                "name": "Houthulst",
                "countryCode": "BE"
            },
            {
                "name": "Hove",
                "countryCode": "BE"
            },
            {
                "name": "Huldenberg",
                "countryCode": "BE"
            },
            {
                "name": "Hulshout",
                "countryCode": "BE"
            },
            {
                "name": "Ichtegem",
                "countryCode": "BE"
            },
            {
                "name": "Ieper",
                "countryCode": "BE"
            },
            {
                "name": "Ingelmunster",
                "countryCode": "BE"
            },
            {
                "name": "Izegem",
                "countryCode": "BE"
            },
            {
                "name": "Jabbeke",
                "countryCode": "BE"
            },
            {
                "name": "Kalmthout",
                "countryCode": "BE"
            },
            {
                "name": "Kampenhout",
                "countryCode": "BE"
            },
            {
                "name": "Kapelle-op-den-Bos",
                "countryCode": "BE"
            },
            {
                "name": "Kapellen",
                "countryCode": "BE"
            },
            {
                "name": "Kaprijke",
                "countryCode": "BE"
            },
            {
                "name": "Kasterlee",
                "countryCode": "BE"
            },
            {
                "name": "Keerbergen",
                "countryCode": "BE"
            },
            {
                "name": "Kinrooi",
                "countryCode": "BE"
            },
            {
                "name": "Knesselare",
                "countryCode": "BE"
            },
            {
                "name": "Knokke-Heist",
                "countryCode": "BE"
            },
            {
                "name": "Koekelare",
                "countryCode": "BE"
            },
            {
                "name": "Koksijde",
                "countryCode": "BE"
            },
            {
                "name": "Kontich",
                "countryCode": "BE"
            },
            {
                "name": "Kortemark",
                "countryCode": "BE"
            },
            {
                "name": "Kortenaken",
                "countryCode": "BE"
            },
            {
                "name": "Kortenberg",
                "countryCode": "BE"
            },
            {
                "name": "Kortessem",
                "countryCode": "BE"
            },
            {
                "name": "Kortrijk",
                "countryCode": "BE"
            },
            {
                "name": "Kraainem",
                "countryCode": "BE"
            },
            {
                "name": "Kruibeke",
                "countryCode": "BE"
            },
            {
                "name": "Kruishoutem",
                "countryCode": "BE"
            },
            {
                "name": "Kuurne",
                "countryCode": "BE"
            },
            {
                "name": "Laarne",
                "countryCode": "BE"
            },
            {
                "name": "Lanaken",
                "countryCode": "BE"
            },
            {
                "name": "Landen",
                "countryCode": "BE"
            },
            {
                "name": "Lebbeke",
                "countryCode": "BE"
            },
            {
                "name": "Lede",
                "countryCode": "BE"
            },
            {
                "name": "Ledeberg",
                "countryCode": "BE"
            },
            {
                "name": "Ledegem",
                "countryCode": "BE"
            },
            {
                "name": "Lendelede",
                "countryCode": "BE"
            },
            {
                "name": "Leopoldsburg",
                "countryCode": "BE"
            },
            {
                "name": "Leuven",
                "countryCode": "BE"
            },
            {
                "name": "Lichtervelde",
                "countryCode": "BE"
            },
            {
                "name": "Liedekerke",
                "countryCode": "BE"
            },
            {
                "name": "Lier",
                "countryCode": "BE"
            },
            {
                "name": "Lille",
                "countryCode": "BE"
            },
            {
                "name": "Linkebeek",
                "countryCode": "BE"
            },
            {
                "name": "Lint",
                "countryCode": "BE"
            },
            {
                "name": "Lochristi",
                "countryCode": "BE"
            },
            {
                "name": "Lokeren",
                "countryCode": "BE"
            },
            {
                "name": "Lommel",
                "countryCode": "BE"
            },
            {
                "name": "Londerzeel",
                "countryCode": "BE"
            },
            {
                "name": "Lovendegem",
                "countryCode": "BE"
            },
            {
                "name": "Lubbeek",
                "countryCode": "BE"
            },
            {
                "name": "Lummen",
                "countryCode": "BE"
            },
            {
                "name": "Maaseik",
                "countryCode": "BE"
            },
            {
                "name": "Maasmechelen",
                "countryCode": "BE"
            },
            {
                "name": "Machelen",
                "countryCode": "BE"
            },
            {
                "name": "Maldegem",
                "countryCode": "BE"
            },
            {
                "name": "Mechelen",
                "countryCode": "BE"
            },
            {
                "name": "Meerhout",
                "countryCode": "BE"
            },
            {
                "name": "Meise",
                "countryCode": "BE"
            },
            {
                "name": "Melle",
                "countryCode": "BE"
            },
            {
                "name": "Menen",
                "countryCode": "BE"
            },
            {
                "name": "Merchtem",
                "countryCode": "BE"
            },
            {
                "name": "Merelbeke",
                "countryCode": "BE"
            },
            {
                "name": "Merksplas",
                "countryCode": "BE"
            },
            {
                "name": "Meulebeke",
                "countryCode": "BE"
            },
            {
                "name": "Middelkerke",
                "countryCode": "BE"
            },
            {
                "name": "Moerbeke",
                "countryCode": "BE"
            },
            {
                "name": "Mol",
                "countryCode": "BE"
            },
            {
                "name": "Moorslede",
                "countryCode": "BE"
            },
            {
                "name": "Mortsel",
                "countryCode": "BE"
            },
            {
                "name": "Nazareth",
                "countryCode": "BE"
            },
            {
                "name": "Neerpelt",
                "countryCode": "BE"
            },
            {
                "name": "Nevele",
                "countryCode": "BE"
            },
            {
                "name": "Niel",
                "countryCode": "BE"
            },
            {
                "name": "Nieuwerkerken",
                "countryCode": "BE"
            },
            {
                "name": "Nieuwpoort",
                "countryCode": "BE"
            },
            {
                "name": "Nijlen",
                "countryCode": "BE"
            },
            {
                "name": "Ninove",
                "countryCode": "BE"
            },
            {
                "name": "Olen",
                "countryCode": "BE"
            },
            {
                "name": "Oostduinkerke",
                "countryCode": "BE"
            },
            {
                "name": "Oosterzele",
                "countryCode": "BE"
            },
            {
                "name": "Oostkamp",
                "countryCode": "BE"
            },
            {
                "name": "Oostmalle",
                "countryCode": "BE"
            },
            {
                "name": "Oostrozebeke",
                "countryCode": "BE"
            },
            {
                "name": "Opglabbeek",
                "countryCode": "BE"
            },
            {
                "name": "Opwijk",
                "countryCode": "BE"
            },
            {
                "name": "Ostend",
                "countryCode": "BE"
            },
            {
                "name": "Oud-Heverlee",
                "countryCode": "BE"
            },
            {
                "name": "Oud-Turnhout",
                "countryCode": "BE"
            },
            {
                "name": "Oudenaarde",
                "countryCode": "BE"
            },
            {
                "name": "Oudenburg",
                "countryCode": "BE"
            },
            {
                "name": "Overijse",
                "countryCode": "BE"
            },
            {
                "name": "Overpelt",
                "countryCode": "BE"
            },
            {
                "name": "Peer",
                "countryCode": "BE"
            },
            {
                "name": "Pepingen",
                "countryCode": "BE"
            },
            {
                "name": "Perre",
                "countryCode": "BE"
            },
            {
                "name": "Pittem",
                "countryCode": "BE"
            },
            {
                "name": "Poperinge",
                "countryCode": "BE"
            },
            {
                "name": "Provincie Antwerpen",
                "countryCode": "BE"
            },
            {
                "name": "Provincie Limburg",
                "countryCode": "BE"
            },
            {
                "name": "Provincie Oost-Vlaanderen",
                "countryCode": "BE"
            },
            {
                "name": "Provincie Vlaams-Brabant",
                "countryCode": "BE"
            },
            {
                "name": "Provincie West-Vlaanderen",
                "countryCode": "BE"
            },
            {
                "name": "Putte",
                "countryCode": "BE"
            },
            {
                "name": "Puurs",
                "countryCode": "BE"
            },
            {
                "name": "Ranst",
                "countryCode": "BE"
            },
            {
                "name": "Ravels",
                "countryCode": "BE"
            },
            {
                "name": "Retie",
                "countryCode": "BE"
            },
            {
                "name": "Riemst",
                "countryCode": "BE"
            },
            {
                "name": "Rijkevorsel",
                "countryCode": "BE"
            },
            {
                "name": "Roeselare",
                "countryCode": "BE"
            },
            {
                "name": "Ronse",
                "countryCode": "BE"
            },
            {
                "name": "Rotselaar",
                "countryCode": "BE"
            },
            {
                "name": "Ruiselede",
                "countryCode": "BE"
            },
            {
                "name": "Rumst",
                "countryCode": "BE"
            },
            {
                "name": "Schelle",
                "countryCode": "BE"
            },
            {
                "name": "Schilde",
                "countryCode": "BE"
            },
            {
                "name": "Schoten",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Amands",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Genesius-Rode",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Gillis-Waas",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Joris",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Katelijne-Waver",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Kruis",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Laureins",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Lievens-Houtem",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Maria-Lierde",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Martens-Latem",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Martens-Lennik",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Niklaas",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Pieters-Leeuw",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Pieters-Voeren",
                "countryCode": "BE"
            },
            {
                "name": "Sint-Truiden",
                "countryCode": "BE"
            },
            {
                "name": "Stabroek",
                "countryCode": "BE"
            },
            {
                "name": "Staden",
                "countryCode": "BE"
            },
            {
                "name": "Steenokkerzeel",
                "countryCode": "BE"
            },
            {
                "name": "Stekene",
                "countryCode": "BE"
            },
            {
                "name": "Temse",
                "countryCode": "BE"
            },
            {
                "name": "Terkoest",
                "countryCode": "BE"
            },
            {
                "name": "Ternat",
                "countryCode": "BE"
            },
            {
                "name": "Tervuren",
                "countryCode": "BE"
            },
            {
                "name": "Tessenderlo",
                "countryCode": "BE"
            },
            {
                "name": "Tielt",
                "countryCode": "BE"
            },
            {
                "name": "Tienen",
                "countryCode": "BE"
            },
            {
                "name": "Tongeren",
                "countryCode": "BE"
            },
            {
                "name": "Torhout",
                "countryCode": "BE"
            },
            {
                "name": "Tremelo",
                "countryCode": "BE"
            },
            {
                "name": "Turnhout",
                "countryCode": "BE"
            },
            {
                "name": "Veurne",
                "countryCode": "BE"
            },
            {
                "name": "Vilvoorde",
                "countryCode": "BE"
            },
            {
                "name": "Vorselaar",
                "countryCode": "BE"
            },
            {
                "name": "Vosselaar",
                "countryCode": "BE"
            },
            {
                "name": "Waarschoot",
                "countryCode": "BE"
            },
            {
                "name": "Waasmunster",
                "countryCode": "BE"
            },
            {
                "name": "Wachtebeke",
                "countryCode": "BE"
            },
            {
                "name": "Waregem",
                "countryCode": "BE"
            },
            {
                "name": "Wellen",
                "countryCode": "BE"
            },
            {
                "name": "Wemmel",
                "countryCode": "BE"
            },
            {
                "name": "Wenduine",
                "countryCode": "BE"
            },
            {
                "name": "Wervik",
                "countryCode": "BE"
            },
            {
                "name": "Westerlo",
                "countryCode": "BE"
            },
            {
                "name": "Wetteren",
                "countryCode": "BE"
            },
            {
                "name": "Wevelgem",
                "countryCode": "BE"
            },
            {
                "name": "Wezembeek-Oppem",
                "countryCode": "BE"
            },
            {
                "name": "Wichelen",
                "countryCode": "BE"
            },
            {
                "name": "Wielsbeke",
                "countryCode": "BE"
            },
            {
                "name": "Wijnegem",
                "countryCode": "BE"
            },
            {
                "name": "Willebroek",
                "countryCode": "BE"
            },
            {
                "name": "Wingene",
                "countryCode": "BE"
            },
            {
                "name": "Wommelgem",
                "countryCode": "BE"
            },
            {
                "name": "Wuustwezel",
                "countryCode": "BE"
            },
            {
                "name": "Zandhoven",
                "countryCode": "BE"
            },
            {
                "name": "Zaventem",
                "countryCode": "BE"
            },
            {
                "name": "Zedelgem",
                "countryCode": "BE"
            },
            {
                "name": "Zeebrugge",
                "countryCode": "BE"
            },
            {
                "name": "Zele",
                "countryCode": "BE"
            },
            {
                "name": "Zelzate",
                "countryCode": "BE"
            },
            {
                "name": "Zemst",
                "countryCode": "BE"
            },
            {
                "name": "Zingem",
                "countryCode": "BE"
            },
            {
                "name": "Zoersel",
                "countryCode": "BE"
            },
            {
                "name": "Zomergem",
                "countryCode": "BE"
            },
            {
                "name": "Zonhoven",
                "countryCode": "BE"
            },
            {
                "name": "Zonnebeke",
                "countryCode": "BE"
            },
            {
                "name": "Zottegem",
                "countryCode": "BE"
            },
            {
                "name": "Zoutleeuw",
                "countryCode": "BE"
            },
            {
                "name": "Zuienkerke",
                "countryCode": "BE"
            },
            {
                "name": "Zulte",
                "countryCode": "BE"
            },
            {
                "name": "Zutendaal",
                "countryCode": "BE"
            },
            {
                "name": "Zwevegem",
                "countryCode": "BE"
            },
            {
                "name": "Zwijndrecht",
                "countryCode": "BE"
            },
            {
                "name": "Aiseau",
                "countryCode": "BE"
            },
            {
                "name": "Amay",
                "countryCode": "BE"
            },
            {
                "name": "Amblève",
                "countryCode": "BE"
            },
            {
                "name": "Andenne",
                "countryCode": "BE"
            },
            {
                "name": "Anderlues",
                "countryCode": "BE"
            },
            {
                "name": "Anhée",
                "countryCode": "BE"
            },
            {
                "name": "Ans",
                "countryCode": "BE"
            },
            {
                "name": "Anthisnes",
                "countryCode": "BE"
            },
            {
                "name": "Antoing",
                "countryCode": "BE"
            },
            {
                "name": "Arlon",
                "countryCode": "BE"
            },
            {
                "name": "Assesse",
                "countryCode": "BE"
            },
            {
                "name": "Ath",
                "countryCode": "BE"
            },
            {
                "name": "Attert",
                "countryCode": "BE"
            },
            {
                "name": "Aubange",
                "countryCode": "BE"
            },
            {
                "name": "Aubel",
                "countryCode": "BE"
            },
            {
                "name": "Awans",
                "countryCode": "BE"
            },
            {
                "name": "Aywaille",
                "countryCode": "BE"
            },
            {
                "name": "Baelen",
                "countryCode": "BE"
            },
            {
                "name": "Basse Lasne",
                "countryCode": "BE"
            },
            {
                "name": "Bassenge",
                "countryCode": "BE"
            },
            {
                "name": "Bastogne",
                "countryCode": "BE"
            },
            {
                "name": "Beaumont",
                "countryCode": "BE"
            },
            {
                "name": "Beauraing",
                "countryCode": "BE"
            },
            {
                "name": "Beauvechain",
                "countryCode": "BE"
            },
            {
                "name": "Beloeil",
                "countryCode": "BE"
            },
            {
                "name": "Berloz",
                "countryCode": "BE"
            },
            {
                "name": "Bernissart",
                "countryCode": "BE"
            },
            {
                "name": "Bertogne",
                "countryCode": "BE"
            },
            {
                "name": "Bertrix",
                "countryCode": "BE"
            },
            {
                "name": "Beyne-Heusay",
                "countryCode": "BE"
            },
            {
                "name": "Binche",
                "countryCode": "BE"
            },
            {
                "name": "Bièvre",
                "countryCode": "BE"
            },
            {
                "name": "Blégny",
                "countryCode": "BE"
            },
            {
                "name": "Bouillon",
                "countryCode": "BE"
            },
            {
                "name": "Boussu",
                "countryCode": "BE"
            },
            {
                "name": "Braine-l'Alleud",
                "countryCode": "BE"
            },
            {
                "name": "Braine-le-Château",
                "countryCode": "BE"
            },
            {
                "name": "Braine-le-Comte",
                "countryCode": "BE"
            },
            {
                "name": "Braives",
                "countryCode": "BE"
            },
            {
                "name": "Brugelette",
                "countryCode": "BE"
            },
            {
                "name": "Brunehault",
                "countryCode": "BE"
            },
            {
                "name": "Bullange",
                "countryCode": "BE"
            },
            {
                "name": "Burdinne",
                "countryCode": "BE"
            },
            {
                "name": "Butgenbach",
                "countryCode": "BE"
            },
            {
                "name": "Celles",
                "countryCode": "BE"
            },
            {
                "name": "Cerfontaine",
                "countryCode": "BE"
            },
            {
                "name": "Chapelle-lez-Herlaimont",
                "countryCode": "BE"
            },
            {
                "name": "Charleroi",
                "countryCode": "BE"
            },
            {
                "name": "Chastre-Villeroux-Blanmont",
                "countryCode": "BE"
            },
            {
                "name": "Chaudfontaine",
                "countryCode": "BE"
            },
            {
                "name": "Chaumont-Gistoux",
                "countryCode": "BE"
            },
            {
                "name": "Chimay",
                "countryCode": "BE"
            },
            {
                "name": "Chiny",
                "countryCode": "BE"
            },
            {
                "name": "Chièvres",
                "countryCode": "BE"
            },
            {
                "name": "Châtelet",
                "countryCode": "BE"
            },
            {
                "name": "Ciney",
                "countryCode": "BE"
            },
            {
                "name": "Clavier",
                "countryCode": "BE"
            },
            {
                "name": "Colfontaine",
                "countryCode": "BE"
            },
            {
                "name": "Comblain-au-Pont",
                "countryCode": "BE"
            },
            {
                "name": "Courcelles",
                "countryCode": "BE"
            },
            {
                "name": "Court-Saint-Étienne",
                "countryCode": "BE"
            },
            {
                "name": "Couvin",
                "countryCode": "BE"
            },
            {
                "name": "Crisnée",
                "countryCode": "BE"
            },
            {
                "name": "Dalhem",
                "countryCode": "BE"
            },
            {
                "name": "Daverdisse",
                "countryCode": "BE"
            },
            {
                "name": "Dinant",
                "countryCode": "BE"
            },
            {
                "name": "Dison",
                "countryCode": "BE"
            },
            {
                "name": "Doische",
                "countryCode": "BE"
            },
            {
                "name": "Donceel",
                "countryCode": "BE"
            },
            {
                "name": "Dour",
                "countryCode": "BE"
            },
            {
                "name": "Durbuy",
                "countryCode": "BE"
            },
            {
                "name": "Ellezelles",
                "countryCode": "BE"
            },
            {
                "name": "Enghien",
                "countryCode": "BE"
            },
            {
                "name": "Engis",
                "countryCode": "BE"
            },
            {
                "name": "Erquelinnes",
                "countryCode": "BE"
            },
            {
                "name": "Esneux",
                "countryCode": "BE"
            },
            {
                "name": "Estaimpuis",
                "countryCode": "BE"
            },
            {
                "name": "Estinnes-au-Val",
                "countryCode": "BE"
            },
            {
                "name": "Eupen",
                "countryCode": "BE"
            },
            {
                "name": "Faimes",
                "countryCode": "BE"
            },
            {
                "name": "Farciennes",
                "countryCode": "BE"
            },
            {
                "name": "Fauvillers",
                "countryCode": "BE"
            },
            {
                "name": "Ferrières",
                "countryCode": "BE"
            },
            {
                "name": "Fexhe-le-Haut-Clocher",
                "countryCode": "BE"
            },
            {
                "name": "Fleurus",
                "countryCode": "BE"
            },
            {
                "name": "Flobecq",
                "countryCode": "BE"
            },
            {
                "name": "Floreffe",
                "countryCode": "BE"
            },
            {
                "name": "Florennes",
                "countryCode": "BE"
            },
            {
                "name": "Florenville",
                "countryCode": "BE"
            },
            {
                "name": "Flémalle-Haute",
                "countryCode": "BE"
            },
            {
                "name": "Fléron",
                "countryCode": "BE"
            },
            {
                "name": "Forville",
                "countryCode": "BE"
            },
            {
                "name": "Fosses-la-Ville",
                "countryCode": "BE"
            },
            {
                "name": "Frameries",
                "countryCode": "BE"
            },
            {
                "name": "Frasnes-lez-Buissenal",
                "countryCode": "BE"
            },
            {
                "name": "Froidchapelle",
                "countryCode": "BE"
            },
            {
                "name": "Gedinne",
                "countryCode": "BE"
            },
            {
                "name": "Geer",
                "countryCode": "BE"
            },
            {
                "name": "Gembloux",
                "countryCode": "BE"
            },
            {
                "name": "Genappe",
                "countryCode": "BE"
            },
            {
                "name": "Gerpinnes",
                "countryCode": "BE"
            },
            {
                "name": "Gesves",
                "countryCode": "BE"
            },
            {
                "name": "Gouvy",
                "countryCode": "BE"
            },
            {
                "name": "Grez-Doiceau",
                "countryCode": "BE"
            },
            {
                "name": "Habay-la-Vieille",
                "countryCode": "BE"
            },
            {
                "name": "Hamoir",
                "countryCode": "BE"
            },
            {
                "name": "Hamois",
                "countryCode": "BE"
            },
            {
                "name": "Hannut",
                "countryCode": "BE"
            },
            {
                "name": "Hastière-Lavaux",
                "countryCode": "BE"
            },
            {
                "name": "Havelange",
                "countryCode": "BE"
            },
            {
                "name": "Hensies",
                "countryCode": "BE"
            },
            {
                "name": "Herbeumont",
                "countryCode": "BE"
            },
            {
                "name": "Herstal",
                "countryCode": "BE"
            },
            {
                "name": "Herve",
                "countryCode": "BE"
            },
            {
                "name": "Hotton",
                "countryCode": "BE"
            },
            {
                "name": "Houffalize",
                "countryCode": "BE"
            },
            {
                "name": "Houyet",
                "countryCode": "BE"
            },
            {
                "name": "Huy",
                "countryCode": "BE"
            },
            {
                "name": "Héron",
                "countryCode": "BE"
            },
            {
                "name": "Incourt",
                "countryCode": "BE"
            },
            {
                "name": "Ittre",
                "countryCode": "BE"
            },
            {
                "name": "Jalhay",
                "countryCode": "BE"
            },
            {
                "name": "Jodoigne",
                "countryCode": "BE"
            },
            {
                "name": "Juprelle",
                "countryCode": "BE"
            },
            {
                "name": "Jurbise",
                "countryCode": "BE"
            },
            {
                "name": "La Bruyère",
                "countryCode": "BE"
            },
            {
                "name": "La Calamine",
                "countryCode": "BE"
            },
            {
                "name": "La Hulpe",
                "countryCode": "BE"
            },
            {
                "name": "La Louvière",
                "countryCode": "BE"
            },
            {
                "name": "La Roche-en-Ardenne",
                "countryCode": "BE"
            },
            {
                "name": "Lens",
                "countryCode": "BE"
            },
            {
                "name": "Lessines",
                "countryCode": "BE"
            },
            {
                "name": "Libin",
                "countryCode": "BE"
            },
            {
                "name": "Lierneux",
                "countryCode": "BE"
            },
            {
                "name": "Limbourg",
                "countryCode": "BE"
            },
            {
                "name": "Lincent",
                "countryCode": "BE"
            },
            {
                "name": "Liège",
                "countryCode": "BE"
            },
            {
                "name": "Lobbes",
                "countryCode": "BE"
            },
            {
                "name": "Lontzen",
                "countryCode": "BE"
            },
            {
                "name": "Louvain-la-Neuve",
                "countryCode": "BE"
            },
            {
                "name": "Léglise",
                "countryCode": "BE"
            },
            {
                "name": "Malmédy",
                "countryCode": "BE"
            },
            {
                "name": "Manage",
                "countryCode": "BE"
            },
            {
                "name": "Manhay",
                "countryCode": "BE"
            },
            {
                "name": "Marche-en-Famenne",
                "countryCode": "BE"
            },
            {
                "name": "Marchin",
                "countryCode": "BE"
            },
            {
                "name": "Martelange",
                "countryCode": "BE"
            },
            {
                "name": "Meix-devant-Virton",
                "countryCode": "BE"
            },
            {
                "name": "Merbes-le-Château",
                "countryCode": "BE"
            },
            {
                "name": "Messancy",
                "countryCode": "BE"
            },
            {
                "name": "Mettet",
                "countryCode": "BE"
            },
            {
                "name": "Modave",
                "countryCode": "BE"
            },
            {
                "name": "Momignies",
                "countryCode": "BE"
            },
            {
                "name": "Mons",
                "countryCode": "BE"
            },
            {
                "name": "Mons-lez-Liège",
                "countryCode": "BE"
            },
            {
                "name": "Mont-Saint-Guibert",
                "countryCode": "BE"
            },
            {
                "name": "Morlanwelz-Mariemont",
                "countryCode": "BE"
            },
            {
                "name": "Mouscron",
                "countryCode": "BE"
            },
            {
                "name": "Musson",
                "countryCode": "BE"
            },
            {
                "name": "Namur",
                "countryCode": "BE"
            },
            {
                "name": "Nandrin",
                "countryCode": "BE"
            },
            {
                "name": "Nassogne",
                "countryCode": "BE"
            },
            {
                "name": "Neufchâteau",
                "countryCode": "BE"
            },
            {
                "name": "Nivelles",
                "countryCode": "BE"
            },
            {
                "name": "Noville-les-Bois",
                "countryCode": "BE"
            },
            {
                "name": "Ohey",
                "countryCode": "BE"
            },
            {
                "name": "Olne",
                "countryCode": "BE"
            },
            {
                "name": "Onhaye",
                "countryCode": "BE"
            },
            {
                "name": "Oreye",
                "countryCode": "BE"
            },
            {
                "name": "Ottignies",
                "countryCode": "BE"
            },
            {
                "name": "Ouffet",
                "countryCode": "BE"
            },
            {
                "name": "Oupeye",
                "countryCode": "BE"
            },
            {
                "name": "Paliseul",
                "countryCode": "BE"
            },
            {
                "name": "Pecq",
                "countryCode": "BE"
            },
            {
                "name": "Pepinster",
                "countryCode": "BE"
            },
            {
                "name": "Perwez",
                "countryCode": "BE"
            },
            {
                "name": "Philippeville",
                "countryCode": "BE"
            },
            {
                "name": "Plombières",
                "countryCode": "BE"
            },
            {
                "name": "Pont-à-Celles",
                "countryCode": "BE"
            },
            {
                "name": "Profondeville",
                "countryCode": "BE"
            },
            {
                "name": "Province de Liège",
                "countryCode": "BE"
            },
            {
                "name": "Province de Namur",
                "countryCode": "BE"
            },
            {
                "name": "Province du Brabant Wallon",
                "countryCode": "BE"
            },
            {
                "name": "Province du Hainaut",
                "countryCode": "BE"
            },
            {
                "name": "Province du Luxembourg",
                "countryCode": "BE"
            },
            {
                "name": "Péruwelz",
                "countryCode": "BE"
            },
            {
                "name": "Quaregnon",
                "countryCode": "BE"
            },
            {
                "name": "Quiévrain",
                "countryCode": "BE"
            },
            {
                "name": "Quévy-le-Petit",
                "countryCode": "BE"
            },
            {
                "name": "Raeren",
                "countryCode": "BE"
            },
            {
                "name": "Ramillies",
                "countryCode": "BE"
            },
            {
                "name": "Rebecq-Rognon",
                "countryCode": "BE"
            },
            {
                "name": "Remicourt",
                "countryCode": "BE"
            },
            {
                "name": "Rendeux",
                "countryCode": "BE"
            },
            {
                "name": "Rixensart",
                "countryCode": "BE"
            },
            {
                "name": "Rochefort",
                "countryCode": "BE"
            },
            {
                "name": "Roeulx",
                "countryCode": "BE"
            },
            {
                "name": "Rouvroy",
                "countryCode": "BE"
            },
            {
                "name": "Rumes",
                "countryCode": "BE"
            },
            {
                "name": "Saint-Ghislain",
                "countryCode": "BE"
            },
            {
                "name": "Saint-Hubert",
                "countryCode": "BE"
            },
            {
                "name": "Saint-Léger",
                "countryCode": "BE"
            },
            {
                "name": "Saint-Nicolas",
                "countryCode": "BE"
            },
            {
                "name": "Saint-Vith",
                "countryCode": "BE"
            },
            {
                "name": "Sainte-Ode",
                "countryCode": "BE"
            },
            {
                "name": "Seneffe",
                "countryCode": "BE"
            },
            {
                "name": "Seraing",
                "countryCode": "BE"
            },
            {
                "name": "Silly",
                "countryCode": "BE"
            },
            {
                "name": "Soignies",
                "countryCode": "BE"
            },
            {
                "name": "Sombreffe",
                "countryCode": "BE"
            },
            {
                "name": "Somme-Leuze",
                "countryCode": "BE"
            },
            {
                "name": "Soumagne",
                "countryCode": "BE"
            },
            {
                "name": "Spa",
                "countryCode": "BE"
            },
            {
                "name": "Sprimont",
                "countryCode": "BE"
            },
            {
                "name": "Stavelot",
                "countryCode": "BE"
            },
            {
                "name": "Stoumont",
                "countryCode": "BE"
            },
            {
                "name": "Tellin",
                "countryCode": "BE"
            },
            {
                "name": "Tenneville",
                "countryCode": "BE"
            },
            {
                "name": "Theux",
                "countryCode": "BE"
            },
            {
                "name": "Thuin",
                "countryCode": "BE"
            },
            {
                "name": "Tinlot",
                "countryCode": "BE"
            },
            {
                "name": "Tintigny",
                "countryCode": "BE"
            },
            {
                "name": "Tournai",
                "countryCode": "BE"
            },
            {
                "name": "Trois-Ponts",
                "countryCode": "BE"
            },
            {
                "name": "Trooz",
                "countryCode": "BE"
            },
            {
                "name": "Tubize",
                "countryCode": "BE"
            },
            {
                "name": "Vaux-sur-Sûre",
                "countryCode": "BE"
            },
            {
                "name": "Verlaine",
                "countryCode": "BE"
            },
            {
                "name": "Verviers",
                "countryCode": "BE"
            },
            {
                "name": "Vielsalm",
                "countryCode": "BE"
            },
            {
                "name": "Villers-la-Ville",
                "countryCode": "BE"
            },
            {
                "name": "Villers-le-Bouillet",
                "countryCode": "BE"
            },
            {
                "name": "Virton",
                "countryCode": "BE"
            },
            {
                "name": "Visé",
                "countryCode": "BE"
            },
            {
                "name": "Waimes",
                "countryCode": "BE"
            },
            {
                "name": "Walcourt",
                "countryCode": "BE"
            },
            {
                "name": "Walhain-Saint-Paul",
                "countryCode": "BE"
            },
            {
                "name": "Wanze",
                "countryCode": "BE"
            },
            {
                "name": "Waremme",
                "countryCode": "BE"
            },
            {
                "name": "Wasseiges",
                "countryCode": "BE"
            },
            {
                "name": "Waterloo",
                "countryCode": "BE"
            },
            {
                "name": "Wavre",
                "countryCode": "BE"
            },
            {
                "name": "Welkenraedt",
                "countryCode": "BE"
            },
            {
                "name": "Wellin",
                "countryCode": "BE"
            },
            {
                "name": "Yvoir",
                "countryCode": "BE"
            },
            {
                "name": "Écaussinnes-d’Enghien",
                "countryCode": "BE"
            },
            {
                "name": "Éghezée",
                "countryCode": "BE"
            },
            {
                "name": "Érezée",
                "countryCode": "BE"
            },
            {
                "name": "Étalle",
                "countryCode": "BE"
            }
        ]
    },
    {
        "name": "Belize",
        "phonecode": "501",
        "cities": [
            {
                "name": "Belize City",
                "countryCode": "BZ"
            },
            {
                "name": "San Pedro",
                "countryCode": "BZ"
            },
            {
                "name": "Belmopan",
                "countryCode": "BZ"
            },
            {
                "name": "Benque Viejo el Carmen",
                "countryCode": "BZ"
            },
            {
                "name": "San Ignacio",
                "countryCode": "BZ"
            },
            {
                "name": "Valley of Peace",
                "countryCode": "BZ"
            },
            {
                "name": "Corozal",
                "countryCode": "BZ"
            },
            {
                "name": "Hopelchén",
                "countryCode": "BZ"
            },
            {
                "name": "Orange Walk",
                "countryCode": "BZ"
            },
            {
                "name": "Shipyard",
                "countryCode": "BZ"
            },
            {
                "name": "Dangriga",
                "countryCode": "BZ"
            },
            {
                "name": "Placencia",
                "countryCode": "BZ"
            },
            {
                "name": "Punta Gorda",
                "countryCode": "BZ"
            }
        ]
    },
    {
        "name": "Benin",
        "phonecode": "229",
        "cities": [
            {
                "name": "Guilmaro",
                "countryCode": "BJ"
            },
            {
                "name": "Natitingou",
                "countryCode": "BJ"
            },
            {
                "name": "Tanguieta",
                "countryCode": "BJ"
            },
            {
                "name": "Banikoara",
                "countryCode": "BJ"
            },
            {
                "name": "Kandi",
                "countryCode": "BJ"
            },
            {
                "name": "Malanville",
                "countryCode": "BJ"
            },
            {
                "name": "Abomey-Calavi",
                "countryCode": "BJ"
            },
            {
                "name": "Allada",
                "countryCode": "BJ"
            },
            {
                "name": "Hinvi",
                "countryCode": "BJ"
            },
            {
                "name": "Hévié",
                "countryCode": "BJ"
            },
            {
                "name": "Ouidah",
                "countryCode": "BJ"
            },
            {
                "name": "Bembèrèkè",
                "countryCode": "BJ"
            },
            {
                "name": "Bétérou",
                "countryCode": "BJ"
            },
            {
                "name": "Nikki",
                "countryCode": "BJ"
            },
            {
                "name": "Parakou",
                "countryCode": "BJ"
            },
            {
                "name": "Tchaourou",
                "countryCode": "BJ"
            },
            {
                "name": "Comé",
                "countryCode": "BJ"
            },
            {
                "name": "Dassa-Zoumé",
                "countryCode": "BJ"
            },
            {
                "name": "Savalou",
                "countryCode": "BJ"
            },
            {
                "name": "Savé",
                "countryCode": "BJ"
            },
            {
                "name": "Bassila",
                "countryCode": "BJ"
            },
            {
                "name": "Commune of Djougou",
                "countryCode": "BJ"
            },
            {
                "name": "Djougou",
                "countryCode": "BJ"
            },
            {
                "name": "Djakotomey",
                "countryCode": "BJ"
            },
            {
                "name": "Dogbo",
                "countryCode": "BJ"
            },
            {
                "name": "Cotonou",
                "countryCode": "BJ"
            },
            {
                "name": "Commune of Athieme",
                "countryCode": "BJ"
            },
            {
                "name": "Lokossa",
                "countryCode": "BJ"
            },
            {
                "name": "Porto-Novo",
                "countryCode": "BJ"
            },
            {
                "name": "Kétou",
                "countryCode": "BJ"
            },
            {
                "name": "Pobé",
                "countryCode": "BJ"
            },
            {
                "name": "Sakété",
                "countryCode": "BJ"
            },
            {
                "name": "Abomey",
                "countryCode": "BJ"
            },
            {
                "name": "Bohicon",
                "countryCode": "BJ"
            },
            {
                "name": "Commune of Agbangnizoun",
                "countryCode": "BJ"
            },
            {
                "name": "Cové",
                "countryCode": "BJ"
            }
        ]
    },
    {
        "name": "Bermuda",
        "phonecode": "+1-441",
        "cities": []
    },
    {
        "name": "Bhutan",
        "phonecode": "975",
        "cities": [
            {
                "name": "Paro",
                "countryCode": "BT"
            },
            {
                "name": "Daphu",
                "countryCode": "BT"
            },
            {
                "name": "Phuntsholing",
                "countryCode": "BT"
            },
            {
                "name": "Tsimasham",
                "countryCode": "BT"
            },
            {
                "name": "Ha",
                "countryCode": "BT"
            },
            {
                "name": "Samtse",
                "countryCode": "BT"
            },
            {
                "name": "Thimphu",
                "countryCode": "BT"
            },
            {
                "name": "Trongsa",
                "countryCode": "BT"
            },
            {
                "name": "Tsirang",
                "countryCode": "BT"
            },
            {
                "name": "Daga",
                "countryCode": "BT"
            },
            {
                "name": "Wangdue Phodrang",
                "countryCode": "BT"
            },
            {
                "name": "Pajo",
                "countryCode": "BT"
            },
            {
                "name": "Punākha",
                "countryCode": "BT"
            },
            {
                "name": "Trashi Yangtse",
                "countryCode": "BT"
            },
            {
                "name": "Sarpang",
                "countryCode": "BT"
            },
            {
                "name": "Jakar",
                "countryCode": "BT"
            },
            {
                "name": "Panbang",
                "countryCode": "BT"
            },
            {
                "name": "Shemgang",
                "countryCode": "BT"
            },
            {
                "name": "Trashigang",
                "countryCode": "BT"
            },
            {
                "name": "Mongar",
                "countryCode": "BT"
            },
            {
                "name": "Pemagatshel",
                "countryCode": "BT"
            },
            {
                "name": "Lhuentse",
                "countryCode": "BT"
            },
            {
                "name": "Samdrup Jongkhar",
                "countryCode": "BT"
            },
            {
                "name": "Gasa",
                "countryCode": "BT"
            }
        ]
    },
    {
        "name": "Bolivia",
        "phonecode": "591",
        "cities": [
            {
                "name": "Guayaramerín",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Cercado",
                "countryCode": "BO"
            },
            {
                "name": "Provincia General José Ballivián",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Iténez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Mamoré",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Marbán",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Moxos",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Vaca Diez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Yacuma",
                "countryCode": "BO"
            },
            {
                "name": "Reyes",
                "countryCode": "BO"
            },
            {
                "name": "Riberalta",
                "countryCode": "BO"
            },
            {
                "name": "Rurrenabaque",
                "countryCode": "BO"
            },
            {
                "name": "San Borja",
                "countryCode": "BO"
            },
            {
                "name": "San Ramón",
                "countryCode": "BO"
            },
            {
                "name": "Santa Ana de Yacuma",
                "countryCode": "BO"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "BO"
            },
            {
                "name": "Trinidad",
                "countryCode": "BO"
            },
            {
                "name": "Aiquile",
                "countryCode": "BO"
            },
            {
                "name": "Arani",
                "countryCode": "BO"
            },
            {
                "name": "Bolivar",
                "countryCode": "BO"
            },
            {
                "name": "Capinota",
                "countryCode": "BO"
            },
            {
                "name": "Chimoré",
                "countryCode": "BO"
            },
            {
                "name": "Cliza",
                "countryCode": "BO"
            },
            {
                "name": "Cochabamba",
                "countryCode": "BO"
            },
            {
                "name": "Colchani",
                "countryCode": "BO"
            },
            {
                "name": "Colomi",
                "countryCode": "BO"
            },
            {
                "name": "Independencia",
                "countryCode": "BO"
            },
            {
                "name": "Irpa Irpa",
                "countryCode": "BO"
            },
            {
                "name": "Mizque",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Arani",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Arque",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ayopaya",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Campero",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Capinota",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Carrasco",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Cercado",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Chaparé",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Esteban Arce",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Germán Jordán",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Mizque",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Punata",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Quillacollo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Tapacarí",
                "countryCode": "BO"
            },
            {
                "name": "Punata",
                "countryCode": "BO"
            },
            {
                "name": "Quillacollo",
                "countryCode": "BO"
            },
            {
                "name": "Sacaba",
                "countryCode": "BO"
            },
            {
                "name": "Sipe Sipe",
                "countryCode": "BO"
            },
            {
                "name": "Tarata",
                "countryCode": "BO"
            },
            {
                "name": "Tiquipaya",
                "countryCode": "BO"
            },
            {
                "name": "Tiraque Province",
                "countryCode": "BO"
            },
            {
                "name": "Totora",
                "countryCode": "BO"
            },
            {
                "name": "Camargo",
                "countryCode": "BO"
            },
            {
                "name": "Monteagudo",
                "countryCode": "BO"
            },
            {
                "name": "Padilla",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Azurduy",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Belisario Boeto",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Hernando Siles",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Luis Calvo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Nor Cinti",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Oropeza",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sud Cinti",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Tomina",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Yamparáez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Zudáñez",
                "countryCode": "BO"
            },
            {
                "name": "Sucre",
                "countryCode": "BO"
            },
            {
                "name": "Tarabuco",
                "countryCode": "BO"
            },
            {
                "name": "Achacachi",
                "countryCode": "BO"
            },
            {
                "name": "Amarete",
                "countryCode": "BO"
            },
            {
                "name": "Batallas",
                "countryCode": "BO"
            },
            {
                "name": "Caranavi",
                "countryCode": "BO"
            },
            {
                "name": "Chulumani",
                "countryCode": "BO"
            },
            {
                "name": "Colquiri",
                "countryCode": "BO"
            },
            {
                "name": "Coripata",
                "countryCode": "BO"
            },
            {
                "name": "Coroico",
                "countryCode": "BO"
            },
            {
                "name": "Curahuara de Carangas",
                "countryCode": "BO"
            },
            {
                "name": "Eucaliptus",
                "countryCode": "BO"
            },
            {
                "name": "Guanay",
                "countryCode": "BO"
            },
            {
                "name": "Huarina",
                "countryCode": "BO"
            },
            {
                "name": "Huatajata",
                "countryCode": "BO"
            },
            {
                "name": "José Manuel Pando",
                "countryCode": "BO"
            },
            {
                "name": "La Paz",
                "countryCode": "BO"
            },
            {
                "name": "Lahuachaca",
                "countryCode": "BO"
            },
            {
                "name": "Mapiri",
                "countryCode": "BO"
            },
            {
                "name": "Patacamaya",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Aroma",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Bautista Saavedra",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Camacho",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Franz Tamayo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Gualberto Villarroel",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ingavi",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Inquisivi",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Iturralde",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Larecaja",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Loayza",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Los Andes",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Manco Kapac",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Murillo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Muñecas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Nor Yungas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Omasuyos",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Pacajes",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sud Yungas",
                "countryCode": "BO"
            },
            {
                "name": "Quime",
                "countryCode": "BO"
            },
            {
                "name": "San Pablo",
                "countryCode": "BO"
            },
            {
                "name": "San Pedro",
                "countryCode": "BO"
            },
            {
                "name": "Sorata",
                "countryCode": "BO"
            },
            {
                "name": "Tiahuanaco",
                "countryCode": "BO"
            },
            {
                "name": "Viloco",
                "countryCode": "BO"
            },
            {
                "name": "Yumani",
                "countryCode": "BO"
            },
            {
                "name": "Cobija",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Abuná",
                "countryCode": "BO"
            },
            {
                "name": "Provincia General Federico Román",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Madre de Dios",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Manuripi",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Nicolás Suárez",
                "countryCode": "BO"
            },
            {
                "name": "Challapata",
                "countryCode": "BO"
            },
            {
                "name": "Huanuni",
                "countryCode": "BO"
            },
            {
                "name": "Litoral de Atacama",
                "countryCode": "BO"
            },
            {
                "name": "Machacamarca",
                "countryCode": "BO"
            },
            {
                "name": "Nor Carangas Province",
                "countryCode": "BO"
            },
            {
                "name": "Oruro",
                "countryCode": "BO"
            },
            {
                "name": "Poopó",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Avaroa",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Carangas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Cercado",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ladislao Cabrera",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Pantaleón Dalence",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Poopó",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sabaya",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sajama",
                "countryCode": "BO"
            },
            {
                "name": "Provincia San Pedro de Totora",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Saucari",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Tomás Barron",
                "countryCode": "BO"
            },
            {
                "name": "Puerto de Mejillones",
                "countryCode": "BO"
            },
            {
                "name": "Sebastian Pagador Province",
                "countryCode": "BO"
            },
            {
                "name": "Sud Carangas Province",
                "countryCode": "BO"
            },
            {
                "name": "Totoral",
                "countryCode": "BO"
            },
            {
                "name": "Atocha",
                "countryCode": "BO"
            },
            {
                "name": "Betanzos",
                "countryCode": "BO"
            },
            {
                "name": "Colchani",
                "countryCode": "BO"
            },
            {
                "name": "Colquechaca",
                "countryCode": "BO"
            },
            {
                "name": "Enrique Baldivieso",
                "countryCode": "BO"
            },
            {
                "name": "Llallagua",
                "countryCode": "BO"
            },
            {
                "name": "Potosí",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Alonzo de Ibáñez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Charcas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Chayanta",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Daniel Campos",
                "countryCode": "BO"
            },
            {
                "name": "Provincia General Bilbao",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Linares",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Modesto Omiste",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Nor Chichas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Nor Lípez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Quijarro",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Rafael Bustillo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Saavedra",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sud Chichas",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sud Lípez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Tomás Frías",
                "countryCode": "BO"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "BO"
            },
            {
                "name": "Tupiza",
                "countryCode": "BO"
            },
            {
                "name": "Uyuni",
                "countryCode": "BO"
            },
            {
                "name": "Villazón",
                "countryCode": "BO"
            },
            {
                "name": "Abapó",
                "countryCode": "BO"
            },
            {
                "name": "Ascención de Guarayos",
                "countryCode": "BO"
            },
            {
                "name": "Ascensión",
                "countryCode": "BO"
            },
            {
                "name": "Boyuibe",
                "countryCode": "BO"
            },
            {
                "name": "Buena Vista",
                "countryCode": "BO"
            },
            {
                "name": "Camiri",
                "countryCode": "BO"
            },
            {
                "name": "Charagua",
                "countryCode": "BO"
            },
            {
                "name": "Comarapa",
                "countryCode": "BO"
            },
            {
                "name": "Concepción",
                "countryCode": "BO"
            },
            {
                "name": "Cotoca",
                "countryCode": "BO"
            },
            {
                "name": "German Busch",
                "countryCode": "BO"
            },
            {
                "name": "Guarayos",
                "countryCode": "BO"
            },
            {
                "name": "Jorochito",
                "countryCode": "BO"
            },
            {
                "name": "La Bélgica",
                "countryCode": "BO"
            },
            {
                "name": "Limoncito",
                "countryCode": "BO"
            },
            {
                "name": "Los Negros",
                "countryCode": "BO"
            },
            {
                "name": "Mairana",
                "countryCode": "BO"
            },
            {
                "name": "Mineros",
                "countryCode": "BO"
            },
            {
                "name": "Montero",
                "countryCode": "BO"
            },
            {
                "name": "Okinawa Número Uno",
                "countryCode": "BO"
            },
            {
                "name": "Pailón",
                "countryCode": "BO"
            },
            {
                "name": "Paurito",
                "countryCode": "BO"
            },
            {
                "name": "Portachuelo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Andrés Ibáñez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Chiquitos",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Cordillera",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Florida",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ichilo",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Manuel María Caballero",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Santiesteban",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Sara",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Vallegrande",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Velasco",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Warnes",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ángel Sandoval",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Ñuflo de Chávez",
                "countryCode": "BO"
            },
            {
                "name": "Puerto Quijarro",
                "countryCode": "BO"
            },
            {
                "name": "Puesto de Pailas",
                "countryCode": "BO"
            },
            {
                "name": "Roboré",
                "countryCode": "BO"
            },
            {
                "name": "Samaipata",
                "countryCode": "BO"
            },
            {
                "name": "San Carlos",
                "countryCode": "BO"
            },
            {
                "name": "San Ignacio de Velasco",
                "countryCode": "BO"
            },
            {
                "name": "San Juan del Surutú",
                "countryCode": "BO"
            },
            {
                "name": "San Julian",
                "countryCode": "BO"
            },
            {
                "name": "San Matías",
                "countryCode": "BO"
            },
            {
                "name": "San Pedro",
                "countryCode": "BO"
            },
            {
                "name": "Santa Cruz de la Sierra",
                "countryCode": "BO"
            },
            {
                "name": "Santa Rita",
                "countryCode": "BO"
            },
            {
                "name": "Santa Rosa del Sara",
                "countryCode": "BO"
            },
            {
                "name": "Santiago del Torno",
                "countryCode": "BO"
            },
            {
                "name": "Urubichá",
                "countryCode": "BO"
            },
            {
                "name": "Vallegrande",
                "countryCode": "BO"
            },
            {
                "name": "Villa Yapacaní",
                "countryCode": "BO"
            },
            {
                "name": "Warnes",
                "countryCode": "BO"
            },
            {
                "name": "Bermejo",
                "countryCode": "BO"
            },
            {
                "name": "Entre Ríos",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Arce",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Avilez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Cercado",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Gran Chaco",
                "countryCode": "BO"
            },
            {
                "name": "Provincia Méndez",
                "countryCode": "BO"
            },
            {
                "name": "Provincia O’Connor",
                "countryCode": "BO"
            },
            {
                "name": "Tarija",
                "countryCode": "BO"
            },
            {
                "name": "Villamontes",
                "countryCode": "BO"
            },
            {
                "name": "Yacuiba",
                "countryCode": "BO"
            }
        ]
    },
    {
        "name": "Bosnia and Herzegovina",
        "phonecode": "387",
        "cities": [
            {
                "name": "Banovići",
                "countryCode": "BA"
            },
            {
                "name": "Barice",
                "countryCode": "BA"
            },
            {
                "name": "Bihać",
                "countryCode": "BA"
            },
            {
                "name": "Bijela",
                "countryCode": "BA"
            },
            {
                "name": "Bila",
                "countryCode": "BA"
            },
            {
                "name": "Blagaj",
                "countryCode": "BA"
            },
            {
                "name": "Bosanska Krupa",
                "countryCode": "BA"
            },
            {
                "name": "Bosanski Petrovac",
                "countryCode": "BA"
            },
            {
                "name": "Bosansko Grahovo",
                "countryCode": "BA"
            },
            {
                "name": "Breza",
                "countryCode": "BA"
            },
            {
                "name": "Bugojno",
                "countryCode": "BA"
            },
            {
                "name": "Busovača",
                "countryCode": "BA"
            },
            {
                "name": "Bužim",
                "countryCode": "BA"
            },
            {
                "name": "Cazin",
                "countryCode": "BA"
            },
            {
                "name": "Cim",
                "countryCode": "BA"
            },
            {
                "name": "Crnići",
                "countryCode": "BA"
            },
            {
                "name": "Divičani",
                "countryCode": "BA"
            },
            {
                "name": "Dobrinje",
                "countryCode": "BA"
            },
            {
                "name": "Domaljevac",
                "countryCode": "BA"
            },
            {
                "name": "Donja Dubica",
                "countryCode": "BA"
            },
            {
                "name": "Donja Mahala",
                "countryCode": "BA"
            },
            {
                "name": "Donja Međiđa",
                "countryCode": "BA"
            },
            {
                "name": "Donji Vakuf",
                "countryCode": "BA"
            },
            {
                "name": "Drežnica",
                "countryCode": "BA"
            },
            {
                "name": "Drinovci",
                "countryCode": "BA"
            },
            {
                "name": "Drvar",
                "countryCode": "BA"
            },
            {
                "name": "Dubrave Donje",
                "countryCode": "BA"
            },
            {
                "name": "Dubrave Gornje",
                "countryCode": "BA"
            },
            {
                "name": "Dubravica",
                "countryCode": "BA"
            },
            {
                "name": "Fojnica",
                "countryCode": "BA"
            },
            {
                "name": "Glamoč",
                "countryCode": "BA"
            },
            {
                "name": "Gnojnica",
                "countryCode": "BA"
            },
            {
                "name": "Goražde",
                "countryCode": "BA"
            },
            {
                "name": "Gorica",
                "countryCode": "BA"
            },
            {
                "name": "Gornja Breza",
                "countryCode": "BA"
            },
            {
                "name": "Gornja Koprivna",
                "countryCode": "BA"
            },
            {
                "name": "Gornja Tuzla",
                "countryCode": "BA"
            },
            {
                "name": "Gornje Moštre",
                "countryCode": "BA"
            },
            {
                "name": "Gornje Živinice",
                "countryCode": "BA"
            },
            {
                "name": "Gornji Vakuf",
                "countryCode": "BA"
            },
            {
                "name": "Gostovići",
                "countryCode": "BA"
            },
            {
                "name": "Gradačac",
                "countryCode": "BA"
            },
            {
                "name": "Gračanica",
                "countryCode": "BA"
            },
            {
                "name": "Gromiljak",
                "countryCode": "BA"
            },
            {
                "name": "Grude",
                "countryCode": "BA"
            },
            {
                "name": "Hadžići",
                "countryCode": "BA"
            },
            {
                "name": "Hercegovačko-Neretvanski Kanton",
                "countryCode": "BA"
            },
            {
                "name": "Hotonj",
                "countryCode": "BA"
            },
            {
                "name": "Ilijaš",
                "countryCode": "BA"
            },
            {
                "name": "Ilići",
                "countryCode": "BA"
            },
            {
                "name": "Izačić",
                "countryCode": "BA"
            },
            {
                "name": "Jablanica",
                "countryCode": "BA"
            },
            {
                "name": "Jajce",
                "countryCode": "BA"
            },
            {
                "name": "Jelah",
                "countryCode": "BA"
            },
            {
                "name": "Jezerski",
                "countryCode": "BA"
            },
            {
                "name": "Kakanj",
                "countryCode": "BA"
            },
            {
                "name": "Kanton Sarajevo",
                "countryCode": "BA"
            },
            {
                "name": "Karadaglije",
                "countryCode": "BA"
            },
            {
                "name": "Kačuni",
                "countryCode": "BA"
            },
            {
                "name": "Kiseljak",
                "countryCode": "BA"
            },
            {
                "name": "Kladanj",
                "countryCode": "BA"
            },
            {
                "name": "Ključ",
                "countryCode": "BA"
            },
            {
                "name": "Kobilja Glava",
                "countryCode": "BA"
            },
            {
                "name": "Konjic",
                "countryCode": "BA"
            },
            {
                "name": "Kovači",
                "countryCode": "BA"
            },
            {
                "name": "Kočerin",
                "countryCode": "BA"
            },
            {
                "name": "Liješnica",
                "countryCode": "BA"
            },
            {
                "name": "Livno",
                "countryCode": "BA"
            },
            {
                "name": "Ljubuški",
                "countryCode": "BA"
            },
            {
                "name": "Lokvine",
                "countryCode": "BA"
            },
            {
                "name": "Lukavac",
                "countryCode": "BA"
            },
            {
                "name": "Lukavica",
                "countryCode": "BA"
            },
            {
                "name": "Maglaj",
                "countryCode": "BA"
            },
            {
                "name": "Mahala",
                "countryCode": "BA"
            },
            {
                "name": "Mala Kladuša",
                "countryCode": "BA"
            },
            {
                "name": "Malešići",
                "countryCode": "BA"
            },
            {
                "name": "Mionica",
                "countryCode": "BA"
            },
            {
                "name": "Mostar",
                "countryCode": "BA"
            },
            {
                "name": "Mramor",
                "countryCode": "BA"
            },
            {
                "name": "Neum",
                "countryCode": "BA"
            },
            {
                "name": "Novi Travnik",
                "countryCode": "BA"
            },
            {
                "name": "Novi Šeher",
                "countryCode": "BA"
            },
            {
                "name": "Odžak",
                "countryCode": "BA"
            },
            {
                "name": "Olovo",
                "countryCode": "BA"
            },
            {
                "name": "Omanjska",
                "countryCode": "BA"
            },
            {
                "name": "Orahovica Donja",
                "countryCode": "BA"
            },
            {
                "name": "Orašac",
                "countryCode": "BA"
            },
            {
                "name": "Orašje",
                "countryCode": "BA"
            },
            {
                "name": "Orguz",
                "countryCode": "BA"
            },
            {
                "name": "Ostrožac",
                "countryCode": "BA"
            },
            {
                "name": "Otoka",
                "countryCode": "BA"
            },
            {
                "name": "Pajić Polje",
                "countryCode": "BA"
            },
            {
                "name": "Pazarić",
                "countryCode": "BA"
            },
            {
                "name": "Peći",
                "countryCode": "BA"
            },
            {
                "name": "Pećigrad",
                "countryCode": "BA"
            },
            {
                "name": "Pjanići",
                "countryCode": "BA"
            },
            {
                "name": "Podhum",
                "countryCode": "BA"
            },
            {
                "name": "Podzvizd",
                "countryCode": "BA"
            },
            {
                "name": "Polje",
                "countryCode": "BA"
            },
            {
                "name": "Polje-Bijela",
                "countryCode": "BA"
            },
            {
                "name": "Potoci",
                "countryCode": "BA"
            },
            {
                "name": "Prozor",
                "countryCode": "BA"
            },
            {
                "name": "Puračić",
                "countryCode": "BA"
            },
            {
                "name": "Radišići",
                "countryCode": "BA"
            },
            {
                "name": "Rodoč",
                "countryCode": "BA"
            },
            {
                "name": "Rumboci",
                "countryCode": "BA"
            },
            {
                "name": "Sanica",
                "countryCode": "BA"
            },
            {
                "name": "Sanski Most",
                "countryCode": "BA"
            },
            {
                "name": "Sapna",
                "countryCode": "BA"
            },
            {
                "name": "Sarajevo",
                "countryCode": "BA"
            },
            {
                "name": "Skokovi",
                "countryCode": "BA"
            },
            {
                "name": "Sladna",
                "countryCode": "BA"
            },
            {
                "name": "Solina",
                "countryCode": "BA"
            },
            {
                "name": "Srebrenik",
                "countryCode": "BA"
            },
            {
                "name": "Stijena",
                "countryCode": "BA"
            },
            {
                "name": "Stjepan-Polje",
                "countryCode": "BA"
            },
            {
                "name": "Stolac",
                "countryCode": "BA"
            },
            {
                "name": "Tasovčići",
                "countryCode": "BA"
            },
            {
                "name": "Tešanj",
                "countryCode": "BA"
            },
            {
                "name": "Tešanjka",
                "countryCode": "BA"
            },
            {
                "name": "Todorovo",
                "countryCode": "BA"
            },
            {
                "name": "Tojšići",
                "countryCode": "BA"
            },
            {
                "name": "Tomislavgrad",
                "countryCode": "BA"
            },
            {
                "name": "Travnik",
                "countryCode": "BA"
            },
            {
                "name": "Tržačka Raštela",
                "countryCode": "BA"
            },
            {
                "name": "Turbe",
                "countryCode": "BA"
            },
            {
                "name": "Tuzla",
                "countryCode": "BA"
            },
            {
                "name": "Ustikolina",
                "countryCode": "BA"
            },
            {
                "name": "Vareš",
                "countryCode": "BA"
            },
            {
                "name": "Varoška Rijeka",
                "countryCode": "BA"
            },
            {
                "name": "Velagići",
                "countryCode": "BA"
            },
            {
                "name": "Velika Kladuša",
                "countryCode": "BA"
            },
            {
                "name": "Vidoši",
                "countryCode": "BA"
            },
            {
                "name": "Visoko",
                "countryCode": "BA"
            },
            {
                "name": "Vitez",
                "countryCode": "BA"
            },
            {
                "name": "Vitina",
                "countryCode": "BA"
            },
            {
                "name": "Vogošća",
                "countryCode": "BA"
            },
            {
                "name": "Voljevac",
                "countryCode": "BA"
            },
            {
                "name": "Vrnograč",
                "countryCode": "BA"
            },
            {
                "name": "Vukovije Donje",
                "countryCode": "BA"
            },
            {
                "name": "Zabrišće",
                "countryCode": "BA"
            },
            {
                "name": "Zavidovići",
                "countryCode": "BA"
            },
            {
                "name": "Zborište",
                "countryCode": "BA"
            },
            {
                "name": "Zenica",
                "countryCode": "BA"
            },
            {
                "name": "Ćoralići",
                "countryCode": "BA"
            },
            {
                "name": "Čapljina",
                "countryCode": "BA"
            },
            {
                "name": "Čelić",
                "countryCode": "BA"
            },
            {
                "name": "Čitluk",
                "countryCode": "BA"
            },
            {
                "name": "Šerići",
                "countryCode": "BA"
            },
            {
                "name": "Široki Brijeg",
                "countryCode": "BA"
            },
            {
                "name": "Šturlić",
                "countryCode": "BA"
            },
            {
                "name": "Šumatac",
                "countryCode": "BA"
            },
            {
                "name": "Željezno Polje",
                "countryCode": "BA"
            },
            {
                "name": "Žepče",
                "countryCode": "BA"
            },
            {
                "name": "Živinice",
                "countryCode": "BA"
            },
            {
                "name": "Brka",
                "countryCode": "BA"
            },
            {
                "name": "Brčko",
                "countryCode": "BA"
            },
            {
                "name": "Balatun",
                "countryCode": "BA"
            },
            {
                "name": "Banja Luka",
                "countryCode": "BA"
            },
            {
                "name": "Bijeljina",
                "countryCode": "BA"
            },
            {
                "name": "Bileća",
                "countryCode": "BA"
            },
            {
                "name": "Blatnica",
                "countryCode": "BA"
            },
            {
                "name": "Brod",
                "countryCode": "BA"
            },
            {
                "name": "Bronzani Majdan",
                "countryCode": "BA"
            },
            {
                "name": "Derventa",
                "countryCode": "BA"
            },
            {
                "name": "Doboj",
                "countryCode": "BA"
            },
            {
                "name": "Dobrljin",
                "countryCode": "BA"
            },
            {
                "name": "Dvorovi",
                "countryCode": "BA"
            },
            {
                "name": "Foča",
                "countryCode": "BA"
            },
            {
                "name": "Gacko",
                "countryCode": "BA"
            },
            {
                "name": "Gradiška",
                "countryCode": "BA"
            },
            {
                "name": "Hiseti",
                "countryCode": "BA"
            },
            {
                "name": "Istočni Mostar",
                "countryCode": "BA"
            },
            {
                "name": "Janja",
                "countryCode": "BA"
            },
            {
                "name": "Kalenderovci Donji",
                "countryCode": "BA"
            },
            {
                "name": "Kneževo",
                "countryCode": "BA"
            },
            {
                "name": "Knežica",
                "countryCode": "BA"
            },
            {
                "name": "Koran",
                "countryCode": "BA"
            },
            {
                "name": "Kostajnica",
                "countryCode": "BA"
            },
            {
                "name": "Kotor Varoš",
                "countryCode": "BA"
            },
            {
                "name": "Kozarska Dubica",
                "countryCode": "BA"
            },
            {
                "name": "Krupa na Vrbasu",
                "countryCode": "BA"
            },
            {
                "name": "Laktaši",
                "countryCode": "BA"
            },
            {
                "name": "Lamovita",
                "countryCode": "BA"
            },
            {
                "name": "Ljubinje",
                "countryCode": "BA"
            },
            {
                "name": "Lopare",
                "countryCode": "BA"
            },
            {
                "name": "Maglajani",
                "countryCode": "BA"
            },
            {
                "name": "Marićka",
                "countryCode": "BA"
            },
            {
                "name": "Maslovare",
                "countryCode": "BA"
            },
            {
                "name": "Mejdan - Obilićevo",
                "countryCode": "BA"
            },
            {
                "name": "Milići",
                "countryCode": "BA"
            },
            {
                "name": "Modriča",
                "countryCode": "BA"
            },
            {
                "name": "Mrkonjić Grad",
                "countryCode": "BA"
            },
            {
                "name": "Nevesinje",
                "countryCode": "BA"
            },
            {
                "name": "Novi Grad",
                "countryCode": "BA"
            },
            {
                "name": "Obudovac",
                "countryCode": "BA"
            },
            {
                "name": "Omarska",
                "countryCode": "BA"
            },
            {
                "name": "Opština Oštra Luka",
                "countryCode": "BA"
            },
            {
                "name": "Opština Višegrad",
                "countryCode": "BA"
            },
            {
                "name": "Oštra Luka",
                "countryCode": "BA"
            },
            {
                "name": "Pale",
                "countryCode": "BA"
            },
            {
                "name": "Pelagićevo",
                "countryCode": "BA"
            },
            {
                "name": "Petkovci",
                "countryCode": "BA"
            },
            {
                "name": "Piskavica",
                "countryCode": "BA"
            },
            {
                "name": "Podbrdo",
                "countryCode": "BA"
            },
            {
                "name": "Popovi",
                "countryCode": "BA"
            },
            {
                "name": "Pribinić",
                "countryCode": "BA"
            },
            {
                "name": "Priboj",
                "countryCode": "BA"
            },
            {
                "name": "Prijedor",
                "countryCode": "BA"
            },
            {
                "name": "Rogatica",
                "countryCode": "BA"
            },
            {
                "name": "Rudo",
                "countryCode": "BA"
            },
            {
                "name": "Sokolac",
                "countryCode": "BA"
            },
            {
                "name": "Srbac",
                "countryCode": "BA"
            },
            {
                "name": "Srebrenica",
                "countryCode": "BA"
            },
            {
                "name": "Stanari",
                "countryCode": "BA"
            },
            {
                "name": "Starcevica",
                "countryCode": "BA"
            },
            {
                "name": "Svodna",
                "countryCode": "BA"
            },
            {
                "name": "Teslić",
                "countryCode": "BA"
            },
            {
                "name": "Trebinje",
                "countryCode": "BA"
            },
            {
                "name": "Trn",
                "countryCode": "BA"
            },
            {
                "name": "Ugljevik",
                "countryCode": "BA"
            },
            {
                "name": "Velika Obarska",
                "countryCode": "BA"
            },
            {
                "name": "Višegrad",
                "countryCode": "BA"
            },
            {
                "name": "Vlasenica",
                "countryCode": "BA"
            },
            {
                "name": "Zvornik",
                "countryCode": "BA"
            },
            {
                "name": "Čajniče",
                "countryCode": "BA"
            },
            {
                "name": "Čelinac",
                "countryCode": "BA"
            },
            {
                "name": "Čečava",
                "countryCode": "BA"
            },
            {
                "name": "Šamac",
                "countryCode": "BA"
            },
            {
                "name": "Šekovići",
                "countryCode": "BA"
            },
            {
                "name": "Šipovo",
                "countryCode": "BA"
            },
            {
                "name": "Živinice",
                "countryCode": "BA"
            }
        ]
    },
    {
        "name": "Botswana",
        "phonecode": "267",
        "cities": [
            {
                "name": "Gobojango",
                "countryCode": "BW"
            },
            {
                "name": "Gweta",
                "countryCode": "BW"
            },
            {
                "name": "Kalamare",
                "countryCode": "BW"
            },
            {
                "name": "Letlhakane",
                "countryCode": "BW"
            },
            {
                "name": "Letsheng",
                "countryCode": "BW"
            },
            {
                "name": "Maapi",
                "countryCode": "BW"
            },
            {
                "name": "Machaneng",
                "countryCode": "BW"
            },
            {
                "name": "Mahalapye",
                "countryCode": "BW"
            },
            {
                "name": "Makobeng",
                "countryCode": "BW"
            },
            {
                "name": "Makwata",
                "countryCode": "BW"
            },
            {
                "name": "Mathakola",
                "countryCode": "BW"
            },
            {
                "name": "Mathambgwane",
                "countryCode": "BW"
            },
            {
                "name": "Mathathane",
                "countryCode": "BW"
            },
            {
                "name": "Maunatlala",
                "countryCode": "BW"
            },
            {
                "name": "Mogapi",
                "countryCode": "BW"
            },
            {
                "name": "Moijabana",
                "countryCode": "BW"
            },
            {
                "name": "Mookane",
                "countryCode": "BW"
            },
            {
                "name": "Mopipi",
                "countryCode": "BW"
            },
            {
                "name": "Mosetse",
                "countryCode": "BW"
            },
            {
                "name": "Nata",
                "countryCode": "BW"
            },
            {
                "name": "Orapa",
                "countryCode": "BW"
            },
            {
                "name": "Palapye",
                "countryCode": "BW"
            },
            {
                "name": "Pilikwe",
                "countryCode": "BW"
            },
            {
                "name": "Rakops",
                "countryCode": "BW"
            },
            {
                "name": "Ramokgonami",
                "countryCode": "BW"
            },
            {
                "name": "Ratholo",
                "countryCode": "BW"
            },
            {
                "name": "Sefophe",
                "countryCode": "BW"
            },
            {
                "name": "Serowe",
                "countryCode": "BW"
            },
            {
                "name": "Sua",
                "countryCode": "BW"
            },
            {
                "name": "Tamasane",
                "countryCode": "BW"
            },
            {
                "name": "Tobane",
                "countryCode": "BW"
            },
            {
                "name": "Tonota",
                "countryCode": "BW"
            },
            {
                "name": "Dekar",
                "countryCode": "BW"
            },
            {
                "name": "Ghanzi",
                "countryCode": "BW"
            },
            {
                "name": "Hukuntsi",
                "countryCode": "BW"
            },
            {
                "name": "Kang",
                "countryCode": "BW"
            },
            {
                "name": "Lehututu",
                "countryCode": "BW"
            },
            {
                "name": "Manyana",
                "countryCode": "BW"
            },
            {
                "name": "Tshabong",
                "countryCode": "BW"
            },
            {
                "name": "Werda",
                "countryCode": "BW"
            },
            {
                "name": "Bokaa",
                "countryCode": "BW"
            },
            {
                "name": "Mmathubudukwane",
                "countryCode": "BW"
            },
            {
                "name": "Mochudi",
                "countryCode": "BW"
            },
            {
                "name": "Pilane",
                "countryCode": "BW"
            },
            {
                "name": "Botlhapatlou",
                "countryCode": "BW"
            },
            {
                "name": "Dutlwe",
                "countryCode": "BW"
            },
            {
                "name": "Gabane",
                "countryCode": "BW"
            },
            {
                "name": "Gaphatshwe",
                "countryCode": "BW"
            },
            {
                "name": "Khudumelapye",
                "countryCode": "BW"
            },
            {
                "name": "Lenchwe Le Tau",
                "countryCode": "BW"
            },
            {
                "name": "Letlhakeng",
                "countryCode": "BW"
            },
            {
                "name": "Metsemotlhaba",
                "countryCode": "BW"
            },
            {
                "name": "Mmopone",
                "countryCode": "BW"
            },
            {
                "name": "Mogoditshane",
                "countryCode": "BW"
            },
            {
                "name": "Molepolole",
                "countryCode": "BW"
            },
            {
                "name": "Nkoyaphiri",
                "countryCode": "BW"
            },
            {
                "name": "Thamaga",
                "countryCode": "BW"
            },
            {
                "name": "Dukwe",
                "countryCode": "BW"
            },
            {
                "name": "Makaleng",
                "countryCode": "BW"
            },
            {
                "name": "Masunga",
                "countryCode": "BW"
            },
            {
                "name": "Sebina",
                "countryCode": "BW"
            },
            {
                "name": "Maun",
                "countryCode": "BW"
            },
            {
                "name": "Nokaneng",
                "countryCode": "BW"
            },
            {
                "name": "Pandamatenga",
                "countryCode": "BW"
            },
            {
                "name": "Sehithwa",
                "countryCode": "BW"
            },
            {
                "name": "Shakawe",
                "countryCode": "BW"
            },
            {
                "name": "Gaborone",
                "countryCode": "BW"
            },
            {
                "name": "Janeng",
                "countryCode": "BW"
            },
            {
                "name": "Kopong",
                "countryCode": "BW"
            },
            {
                "name": "Otse",
                "countryCode": "BW"
            },
            {
                "name": "Ramotswa",
                "countryCode": "BW"
            },
            {
                "name": "Kanye",
                "countryCode": "BW"
            },
            {
                "name": "Khakhea",
                "countryCode": "BW"
            },
            {
                "name": "Mosopa",
                "countryCode": "BW"
            },
            {
                "name": "Sekoma",
                "countryCode": "BW"
            }
        ]
    },
    {
        "name": "Bouvet Island",
        "phonecode": "0055",
        "cities": []
    },
    {
        "name": "Brazil",
        "phonecode": "55",
        "cities": [
            {
                "name": "Acrelândia",
                "countryCode": "BR"
            },
            {
                "name": "Assis Brasil",
                "countryCode": "BR"
            },
            {
                "name": "Brasiléia",
                "countryCode": "BR"
            },
            {
                "name": "Bujari",
                "countryCode": "BR"
            },
            {
                "name": "Capixaba",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Epitaciolândia",
                "countryCode": "BR"
            },
            {
                "name": "Feijó",
                "countryCode": "BR"
            },
            {
                "name": "Jordão",
                "countryCode": "BR"
            },
            {
                "name": "Manoel Urbano",
                "countryCode": "BR"
            },
            {
                "name": "Marechal Thaumaturgo",
                "countryCode": "BR"
            },
            {
                "name": "Mâncio Lima",
                "countryCode": "BR"
            },
            {
                "name": "Plácido de Castro",
                "countryCode": "BR"
            },
            {
                "name": "Porto Acre",
                "countryCode": "BR"
            },
            {
                "name": "Porto Walter",
                "countryCode": "BR"
            },
            {
                "name": "Rio Branco",
                "countryCode": "BR"
            },
            {
                "name": "Rodrigues Alves",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa do Purus",
                "countryCode": "BR"
            },
            {
                "name": "Sena Madureira",
                "countryCode": "BR"
            },
            {
                "name": "Senador Guiomard",
                "countryCode": "BR"
            },
            {
                "name": "Tarauacá",
                "countryCode": "BR"
            },
            {
                "name": "Xapuri",
                "countryCode": "BR"
            },
            {
                "name": "Anadia",
                "countryCode": "BR"
            },
            {
                "name": "Arapiraca",
                "countryCode": "BR"
            },
            {
                "name": "Atalaia",
                "countryCode": "BR"
            },
            {
                "name": "Barra de Santo Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Barra de São Miguel",
                "countryCode": "BR"
            },
            {
                "name": "Batalha",
                "countryCode": "BR"
            },
            {
                "name": "Belo Monte",
                "countryCode": "BR"
            },
            {
                "name": "Belém",
                "countryCode": "BR"
            },
            {
                "name": "Boca da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Branquinha",
                "countryCode": "BR"
            },
            {
                "name": "Cacimbinhas",
                "countryCode": "BR"
            },
            {
                "name": "Cajueiro",
                "countryCode": "BR"
            },
            {
                "name": "Campestre",
                "countryCode": "BR"
            },
            {
                "name": "Campo Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Campo Grande",
                "countryCode": "BR"
            },
            {
                "name": "Canapi",
                "countryCode": "BR"
            },
            {
                "name": "Capela",
                "countryCode": "BR"
            },
            {
                "name": "Carneiros",
                "countryCode": "BR"
            },
            {
                "name": "Chã Preta",
                "countryCode": "BR"
            },
            {
                "name": "Coité do Nóia",
                "countryCode": "BR"
            },
            {
                "name": "Colônia Leopoldina",
                "countryCode": "BR"
            },
            {
                "name": "Coqueiro Seco",
                "countryCode": "BR"
            },
            {
                "name": "Coruripe",
                "countryCode": "BR"
            },
            {
                "name": "Craíbas",
                "countryCode": "BR"
            },
            {
                "name": "Delmiro Gouveia",
                "countryCode": "BR"
            },
            {
                "name": "Dois Riachos",
                "countryCode": "BR"
            },
            {
                "name": "Estrela de Alagoas",
                "countryCode": "BR"
            },
            {
                "name": "Feira Grande",
                "countryCode": "BR"
            },
            {
                "name": "Feliz Deserto",
                "countryCode": "BR"
            },
            {
                "name": "Flexeiras",
                "countryCode": "BR"
            },
            {
                "name": "Girau do Ponciano",
                "countryCode": "BR"
            },
            {
                "name": "Ibateguara",
                "countryCode": "BR"
            },
            {
                "name": "Igaci",
                "countryCode": "BR"
            },
            {
                "name": "Igreja Nova",
                "countryCode": "BR"
            },
            {
                "name": "Inhapi",
                "countryCode": "BR"
            },
            {
                "name": "Jacaré dos Homens",
                "countryCode": "BR"
            },
            {
                "name": "Jacuípe",
                "countryCode": "BR"
            },
            {
                "name": "Japaratinga",
                "countryCode": "BR"
            },
            {
                "name": "Jaramataia",
                "countryCode": "BR"
            },
            {
                "name": "Jequiá da Praia",
                "countryCode": "BR"
            },
            {
                "name": "Joaquim Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Jundiá",
                "countryCode": "BR"
            },
            {
                "name": "Junqueiro",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa da Canoa",
                "countryCode": "BR"
            },
            {
                "name": "Limoeiro de Anadia",
                "countryCode": "BR"
            },
            {
                "name": "Maceió",
                "countryCode": "BR"
            },
            {
                "name": "Major Isidoro",
                "countryCode": "BR"
            },
            {
                "name": "Mar Vermelho",
                "countryCode": "BR"
            },
            {
                "name": "Maragogi",
                "countryCode": "BR"
            },
            {
                "name": "Maravilha",
                "countryCode": "BR"
            },
            {
                "name": "Marechal Deodoro",
                "countryCode": "BR"
            },
            {
                "name": "Maribondo",
                "countryCode": "BR"
            },
            {
                "name": "Mata Grande",
                "countryCode": "BR"
            },
            {
                "name": "Matriz de Camaragibe",
                "countryCode": "BR"
            },
            {
                "name": "Messias",
                "countryCode": "BR"
            },
            {
                "name": "Minador do Negrão",
                "countryCode": "BR"
            },
            {
                "name": "Monteirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Murici",
                "countryCode": "BR"
            },
            {
                "name": "Novo Lino",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água Grande",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água das Flores",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água do Casado",
                "countryCode": "BR"
            },
            {
                "name": "Olivença",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Branco",
                "countryCode": "BR"
            },
            {
                "name": "Palestina",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira dos Índios",
                "countryCode": "BR"
            },
            {
                "name": "Pariconha",
                "countryCode": "BR"
            },
            {
                "name": "Paripueira",
                "countryCode": "BR"
            },
            {
                "name": "Passo de Camaragibe",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Jacinto",
                "countryCode": "BR"
            },
            {
                "name": "Penedo",
                "countryCode": "BR"
            },
            {
                "name": "Piaçabuçu",
                "countryCode": "BR"
            },
            {
                "name": "Pilar",
                "countryCode": "BR"
            },
            {
                "name": "Pindoba",
                "countryCode": "BR"
            },
            {
                "name": "Piranhas",
                "countryCode": "BR"
            },
            {
                "name": "Porto Calvo",
                "countryCode": "BR"
            },
            {
                "name": "Porto Real do Colégio",
                "countryCode": "BR"
            },
            {
                "name": "Porto de Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Poço das Trincheiras",
                "countryCode": "BR"
            },
            {
                "name": "Pão de Açúcar",
                "countryCode": "BR"
            },
            {
                "name": "Quebrangulo",
                "countryCode": "BR"
            },
            {
                "name": "Rio Largo",
                "countryCode": "BR"
            },
            {
                "name": "Roteiro",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Ipanema",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Mundaú",
                "countryCode": "BR"
            },
            {
                "name": "Satuba",
                "countryCode": "BR"
            },
            {
                "name": "Senador Rui Palmeira",
                "countryCode": "BR"
            },
            {
                "name": "São Brás",
                "countryCode": "BR"
            },
            {
                "name": "São José da Laje",
                "countryCode": "BR"
            },
            {
                "name": "São José da Tapera",
                "countryCode": "BR"
            },
            {
                "name": "São Luís do Quitunde",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel dos Campos",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel dos Milagres",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião",
                "countryCode": "BR"
            },
            {
                "name": "Tanque d'Arca",
                "countryCode": "BR"
            },
            {
                "name": "Taquarana",
                "countryCode": "BR"
            },
            {
                "name": "Teotônio Vilela",
                "countryCode": "BR"
            },
            {
                "name": "Traipu",
                "countryCode": "BR"
            },
            {
                "name": "União dos Palmares",
                "countryCode": "BR"
            },
            {
                "name": "Viçosa",
                "countryCode": "BR"
            },
            {
                "name": "Água Branca",
                "countryCode": "BR"
            },
            {
                "name": "Alvarães",
                "countryCode": "BR"
            },
            {
                "name": "Amaturá",
                "countryCode": "BR"
            },
            {
                "name": "Anamã",
                "countryCode": "BR"
            },
            {
                "name": "Anori",
                "countryCode": "BR"
            },
            {
                "name": "Apuí",
                "countryCode": "BR"
            },
            {
                "name": "Atalaia do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Autazes",
                "countryCode": "BR"
            },
            {
                "name": "Barcelos",
                "countryCode": "BR"
            },
            {
                "name": "Barreirinha",
                "countryCode": "BR"
            },
            {
                "name": "Benjamin Constant",
                "countryCode": "BR"
            },
            {
                "name": "Beruri",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Boca do Acre",
                "countryCode": "BR"
            },
            {
                "name": "Borba",
                "countryCode": "BR"
            },
            {
                "name": "Caapiranga",
                "countryCode": "BR"
            },
            {
                "name": "Canutama",
                "countryCode": "BR"
            },
            {
                "name": "Carauari",
                "countryCode": "BR"
            },
            {
                "name": "Careiro",
                "countryCode": "BR"
            },
            {
                "name": "Careiro da Várzea",
                "countryCode": "BR"
            },
            {
                "name": "Coari",
                "countryCode": "BR"
            },
            {
                "name": "Codajás",
                "countryCode": "BR"
            },
            {
                "name": "Eirunepé",
                "countryCode": "BR"
            },
            {
                "name": "Envira",
                "countryCode": "BR"
            },
            {
                "name": "Fonte Boa",
                "countryCode": "BR"
            },
            {
                "name": "Guajará",
                "countryCode": "BR"
            },
            {
                "name": "Humaitá",
                "countryCode": "BR"
            },
            {
                "name": "Ipixuna",
                "countryCode": "BR"
            },
            {
                "name": "Iranduba",
                "countryCode": "BR"
            },
            {
                "name": "Itacoatiara",
                "countryCode": "BR"
            },
            {
                "name": "Itamarati",
                "countryCode": "BR"
            },
            {
                "name": "Itapiranga",
                "countryCode": "BR"
            },
            {
                "name": "Japurá",
                "countryCode": "BR"
            },
            {
                "name": "Juruá",
                "countryCode": "BR"
            },
            {
                "name": "Jutaí",
                "countryCode": "BR"
            },
            {
                "name": "Lábrea",
                "countryCode": "BR"
            },
            {
                "name": "Manacapuru",
                "countryCode": "BR"
            },
            {
                "name": "Manaquiri",
                "countryCode": "BR"
            },
            {
                "name": "Manaus",
                "countryCode": "BR"
            },
            {
                "name": "Manicoré",
                "countryCode": "BR"
            },
            {
                "name": "Maraã",
                "countryCode": "BR"
            },
            {
                "name": "Maués",
                "countryCode": "BR"
            },
            {
                "name": "Nhamundá",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olinda do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Novo Airão",
                "countryCode": "BR"
            },
            {
                "name": "Novo Aripuanã",
                "countryCode": "BR"
            },
            {
                "name": "Parintins",
                "countryCode": "BR"
            },
            {
                "name": "Pauini",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Figueiredo",
                "countryCode": "BR"
            },
            {
                "name": "Rio Preto da Eva",
                "countryCode": "BR"
            },
            {
                "name": "Santa Isabel do Rio Negro",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Içá",
                "countryCode": "BR"
            },
            {
                "name": "Silves",
                "countryCode": "BR"
            },
            {
                "name": "São Gabriel da Cachoeira",
                "countryCode": "BR"
            },
            {
                "name": "São Paulo de Olivença",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Uatumã",
                "countryCode": "BR"
            },
            {
                "name": "Tabatinga",
                "countryCode": "BR"
            },
            {
                "name": "Tapauá",
                "countryCode": "BR"
            },
            {
                "name": "Tefé",
                "countryCode": "BR"
            },
            {
                "name": "Tonantins",
                "countryCode": "BR"
            },
            {
                "name": "Uarini",
                "countryCode": "BR"
            },
            {
                "name": "Urucará",
                "countryCode": "BR"
            },
            {
                "name": "Urucurituba",
                "countryCode": "BR"
            },
            {
                "name": "Amapá",
                "countryCode": "BR"
            },
            {
                "name": "Calçoene",
                "countryCode": "BR"
            },
            {
                "name": "Cutias",
                "countryCode": "BR"
            },
            {
                "name": "Ferreira Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Itaubal",
                "countryCode": "BR"
            },
            {
                "name": "Laranjal do Jari",
                "countryCode": "BR"
            },
            {
                "name": "Macapá",
                "countryCode": "BR"
            },
            {
                "name": "Mazagão",
                "countryCode": "BR"
            },
            {
                "name": "Oiapoque",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Branca do Amapari",
                "countryCode": "BR"
            },
            {
                "name": "Porto Grande",
                "countryCode": "BR"
            },
            {
                "name": "Pracuúba",
                "countryCode": "BR"
            },
            {
                "name": "Santana",
                "countryCode": "BR"
            },
            {
                "name": "Serra do Navio",
                "countryCode": "BR"
            },
            {
                "name": "Tartarugalzinho",
                "countryCode": "BR"
            },
            {
                "name": "Vitória do Jari",
                "countryCode": "BR"
            },
            {
                "name": "Abaré",
                "countryCode": "BR"
            },
            {
                "name": "Abaíra",
                "countryCode": "BR"
            },
            {
                "name": "Acajutiba",
                "countryCode": "BR"
            },
            {
                "name": "Adustina",
                "countryCode": "BR"
            },
            {
                "name": "Aiquara",
                "countryCode": "BR"
            },
            {
                "name": "Alagoinhas",
                "countryCode": "BR"
            },
            {
                "name": "Alcobaça",
                "countryCode": "BR"
            },
            {
                "name": "Almadina",
                "countryCode": "BR"
            },
            {
                "name": "Amargosa",
                "countryCode": "BR"
            },
            {
                "name": "Amélia Rodrigues",
                "countryCode": "BR"
            },
            {
                "name": "América Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Anagé",
                "countryCode": "BR"
            },
            {
                "name": "Andaraí",
                "countryCode": "BR"
            },
            {
                "name": "Andorinha",
                "countryCode": "BR"
            },
            {
                "name": "Angical",
                "countryCode": "BR"
            },
            {
                "name": "Anguera",
                "countryCode": "BR"
            },
            {
                "name": "Antas",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Gonçalves",
                "countryCode": "BR"
            },
            {
                "name": "Aporá",
                "countryCode": "BR"
            },
            {
                "name": "Apuarema",
                "countryCode": "BR"
            },
            {
                "name": "Aracatu",
                "countryCode": "BR"
            },
            {
                "name": "Araci",
                "countryCode": "BR"
            },
            {
                "name": "Aramari",
                "countryCode": "BR"
            },
            {
                "name": "Arataca",
                "countryCode": "BR"
            },
            {
                "name": "Aratuípe",
                "countryCode": "BR"
            },
            {
                "name": "Araçás",
                "countryCode": "BR"
            },
            {
                "name": "Aurelino Leal",
                "countryCode": "BR"
            },
            {
                "name": "Baianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Baixa Grande",
                "countryCode": "BR"
            },
            {
                "name": "Banzaê",
                "countryCode": "BR"
            },
            {
                "name": "Barra",
                "countryCode": "BR"
            },
            {
                "name": "Barra da Estiva",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Choça",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Rocha",
                "countryCode": "BR"
            },
            {
                "name": "Barreiras",
                "countryCode": "BR"
            },
            {
                "name": "Barro Alto",
                "countryCode": "BR"
            },
            {
                "name": "Barro Preto",
                "countryCode": "BR"
            },
            {
                "name": "Barrocas",
                "countryCode": "BR"
            },
            {
                "name": "Beira Rio",
                "countryCode": "BR"
            },
            {
                "name": "Belmonte",
                "countryCode": "BR"
            },
            {
                "name": "Belo Campo",
                "countryCode": "BR"
            },
            {
                "name": "Biritinga",
                "countryCode": "BR"
            },
            {
                "name": "Boa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Tupim",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus da Lapa",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Boninal",
                "countryCode": "BR"
            },
            {
                "name": "Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Boquira",
                "countryCode": "BR"
            },
            {
                "name": "Botuporã",
                "countryCode": "BR"
            },
            {
                "name": "Brejolândia",
                "countryCode": "BR"
            },
            {
                "name": "Brejões",
                "countryCode": "BR"
            },
            {
                "name": "Brotas de Macaúbas",
                "countryCode": "BR"
            },
            {
                "name": "Brumado",
                "countryCode": "BR"
            },
            {
                "name": "Buerarema",
                "countryCode": "BR"
            },
            {
                "name": "Buritirama",
                "countryCode": "BR"
            },
            {
                "name": "Caatiba",
                "countryCode": "BR"
            },
            {
                "name": "Cabaceiras do Paraguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira",
                "countryCode": "BR"
            },
            {
                "name": "Caculé",
                "countryCode": "BR"
            },
            {
                "name": "Caetanos",
                "countryCode": "BR"
            },
            {
                "name": "Caetité",
                "countryCode": "BR"
            },
            {
                "name": "Cafarnaum",
                "countryCode": "BR"
            },
            {
                "name": "Cairu",
                "countryCode": "BR"
            },
            {
                "name": "Caldeirão Grande",
                "countryCode": "BR"
            },
            {
                "name": "Camacan",
                "countryCode": "BR"
            },
            {
                "name": "Camamu",
                "countryCode": "BR"
            },
            {
                "name": "Camaçari",
                "countryCode": "BR"
            },
            {
                "name": "Campo Alegre de Lourdes",
                "countryCode": "BR"
            },
            {
                "name": "Campo Formoso",
                "countryCode": "BR"
            },
            {
                "name": "Canarana",
                "countryCode": "BR"
            },
            {
                "name": "Canavieiras",
                "countryCode": "BR"
            },
            {
                "name": "Candeal",
                "countryCode": "BR"
            },
            {
                "name": "Candeias",
                "countryCode": "BR"
            },
            {
                "name": "Candiba",
                "countryCode": "BR"
            },
            {
                "name": "Cansanção",
                "countryCode": "BR"
            },
            {
                "name": "Canudos",
                "countryCode": "BR"
            },
            {
                "name": "Canápolis",
                "countryCode": "BR"
            },
            {
                "name": "Capela do Alto Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Capim Grosso",
                "countryCode": "BR"
            },
            {
                "name": "Caravelas",
                "countryCode": "BR"
            },
            {
                "name": "Caraíbas",
                "countryCode": "BR"
            },
            {
                "name": "Cardeal da Silva",
                "countryCode": "BR"
            },
            {
                "name": "Carinhanha",
                "countryCode": "BR"
            },
            {
                "name": "Casa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Castro Alves",
                "countryCode": "BR"
            },
            {
                "name": "Catolândia",
                "countryCode": "BR"
            },
            {
                "name": "Catu",
                "countryCode": "BR"
            },
            {
                "name": "Caturama",
                "countryCode": "BR"
            },
            {
                "name": "Caém",
                "countryCode": "BR"
            },
            {
                "name": "Central",
                "countryCode": "BR"
            },
            {
                "name": "Chorrochó",
                "countryCode": "BR"
            },
            {
                "name": "Cipó",
                "countryCode": "BR"
            },
            {
                "name": "Coaraci",
                "countryCode": "BR"
            },
            {
                "name": "Cocos",
                "countryCode": "BR"
            },
            {
                "name": "Conceição da Feira",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Coité",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Jacuípe",
                "countryCode": "BR"
            },
            {
                "name": "Conde",
                "countryCode": "BR"
            },
            {
                "name": "Condeúba",
                "countryCode": "BR"
            },
            {
                "name": "Contendas do Sincorá",
                "countryCode": "BR"
            },
            {
                "name": "Coração de Maria",
                "countryCode": "BR"
            },
            {
                "name": "Cordeiros",
                "countryCode": "BR"
            },
            {
                "name": "Coribe",
                "countryCode": "BR"
            },
            {
                "name": "Coronel João Sá",
                "countryCode": "BR"
            },
            {
                "name": "Correntina",
                "countryCode": "BR"
            },
            {
                "name": "Cotegipe",
                "countryCode": "BR"
            },
            {
                "name": "Cravolândia",
                "countryCode": "BR"
            },
            {
                "name": "Cristópolis",
                "countryCode": "BR"
            },
            {
                "name": "Crisópolis",
                "countryCode": "BR"
            },
            {
                "name": "Cruz das Almas",
                "countryCode": "BR"
            },
            {
                "name": "Curaçá",
                "countryCode": "BR"
            },
            {
                "name": "Cândido Sales",
                "countryCode": "BR"
            },
            {
                "name": "Cícero Dantas",
                "countryCode": "BR"
            },
            {
                "name": "Dias d'Ávila",
                "countryCode": "BR"
            },
            {
                "name": "Dom Basílio",
                "countryCode": "BR"
            },
            {
                "name": "Dom Macedo Costa",
                "countryCode": "BR"
            },
            {
                "name": "Dário Meira",
                "countryCode": "BR"
            },
            {
                "name": "Elísio Medrado",
                "countryCode": "BR"
            },
            {
                "name": "Encruzilhada",
                "countryCode": "BR"
            },
            {
                "name": "Entre Rios",
                "countryCode": "BR"
            },
            {
                "name": "Esplanada",
                "countryCode": "BR"
            },
            {
                "name": "Euclides da Cunha",
                "countryCode": "BR"
            },
            {
                "name": "Eunápolis",
                "countryCode": "BR"
            },
            {
                "name": "Feira da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Feira de Santana",
                "countryCode": "BR"
            },
            {
                "name": "Filadélfia",
                "countryCode": "BR"
            },
            {
                "name": "Firmino Alves",
                "countryCode": "BR"
            },
            {
                "name": "Floresta Azul",
                "countryCode": "BR"
            },
            {
                "name": "Formosa do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "Fátima",
                "countryCode": "BR"
            },
            {
                "name": "Gandu",
                "countryCode": "BR"
            },
            {
                "name": "Gavião",
                "countryCode": "BR"
            },
            {
                "name": "Gentio do Ouro",
                "countryCode": "BR"
            },
            {
                "name": "Glória",
                "countryCode": "BR"
            },
            {
                "name": "Gongogi",
                "countryCode": "BR"
            },
            {
                "name": "Governador Mangabeira",
                "countryCode": "BR"
            },
            {
                "name": "Guajeru",
                "countryCode": "BR"
            },
            {
                "name": "Guanambi",
                "countryCode": "BR"
            },
            {
                "name": "Guaratinga",
                "countryCode": "BR"
            },
            {
                "name": "Heliópolis",
                "countryCode": "BR"
            },
            {
                "name": "Iaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ibiassucê",
                "countryCode": "BR"
            },
            {
                "name": "Ibicaraí",
                "countryCode": "BR"
            },
            {
                "name": "Ibicoara",
                "countryCode": "BR"
            },
            {
                "name": "Ibicuí",
                "countryCode": "BR"
            },
            {
                "name": "Ibipeba",
                "countryCode": "BR"
            },
            {
                "name": "Ibipitanga",
                "countryCode": "BR"
            },
            {
                "name": "Ibiquera",
                "countryCode": "BR"
            },
            {
                "name": "Ibirapitanga",
                "countryCode": "BR"
            },
            {
                "name": "Ibirapuã",
                "countryCode": "BR"
            },
            {
                "name": "Ibirataia",
                "countryCode": "BR"
            },
            {
                "name": "Ibitiara",
                "countryCode": "BR"
            },
            {
                "name": "Ibititá",
                "countryCode": "BR"
            },
            {
                "name": "Ibotirama",
                "countryCode": "BR"
            },
            {
                "name": "Ichu",
                "countryCode": "BR"
            },
            {
                "name": "Igaporã",
                "countryCode": "BR"
            },
            {
                "name": "Igrapiúna",
                "countryCode": "BR"
            },
            {
                "name": "Iguaí",
                "countryCode": "BR"
            },
            {
                "name": "Ilhéus",
                "countryCode": "BR"
            },
            {
                "name": "Inhambupe",
                "countryCode": "BR"
            },
            {
                "name": "Ipecaetá",
                "countryCode": "BR"
            },
            {
                "name": "Ipiaú",
                "countryCode": "BR"
            },
            {
                "name": "Ipirá",
                "countryCode": "BR"
            },
            {
                "name": "Ipupiara",
                "countryCode": "BR"
            },
            {
                "name": "Irajuba",
                "countryCode": "BR"
            },
            {
                "name": "Iramaia",
                "countryCode": "BR"
            },
            {
                "name": "Iraquara",
                "countryCode": "BR"
            },
            {
                "name": "Irará",
                "countryCode": "BR"
            },
            {
                "name": "Irecê",
                "countryCode": "BR"
            },
            {
                "name": "Itabela",
                "countryCode": "BR"
            },
            {
                "name": "Itaberaba",
                "countryCode": "BR"
            },
            {
                "name": "Itabuna",
                "countryCode": "BR"
            },
            {
                "name": "Itacaré",
                "countryCode": "BR"
            },
            {
                "name": "Itaeté",
                "countryCode": "BR"
            },
            {
                "name": "Itagi",
                "countryCode": "BR"
            },
            {
                "name": "Itagibá",
                "countryCode": "BR"
            },
            {
                "name": "Itagimirim",
                "countryCode": "BR"
            },
            {
                "name": "Itaguaçu da Bahia",
                "countryCode": "BR"
            },
            {
                "name": "Itaju do Colônia",
                "countryCode": "BR"
            },
            {
                "name": "Itajuípe",
                "countryCode": "BR"
            },
            {
                "name": "Itamaraju",
                "countryCode": "BR"
            },
            {
                "name": "Itamari",
                "countryCode": "BR"
            },
            {
                "name": "Itambé",
                "countryCode": "BR"
            },
            {
                "name": "Itanagra",
                "countryCode": "BR"
            },
            {
                "name": "Itanhém",
                "countryCode": "BR"
            },
            {
                "name": "Itaparica",
                "countryCode": "BR"
            },
            {
                "name": "Itapebi",
                "countryCode": "BR"
            },
            {
                "name": "Itapetinga",
                "countryCode": "BR"
            },
            {
                "name": "Itapicuru",
                "countryCode": "BR"
            },
            {
                "name": "Itapitanga",
                "countryCode": "BR"
            },
            {
                "name": "Itapé",
                "countryCode": "BR"
            },
            {
                "name": "Itaquara",
                "countryCode": "BR"
            },
            {
                "name": "Itarantim",
                "countryCode": "BR"
            },
            {
                "name": "Itatim",
                "countryCode": "BR"
            },
            {
                "name": "Itiruçu",
                "countryCode": "BR"
            },
            {
                "name": "Itiúba",
                "countryCode": "BR"
            },
            {
                "name": "Itororó",
                "countryCode": "BR"
            },
            {
                "name": "Ituaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ituberá",
                "countryCode": "BR"
            },
            {
                "name": "Iuiu",
                "countryCode": "BR"
            },
            {
                "name": "Jaborandi",
                "countryCode": "BR"
            },
            {
                "name": "Jacaraci",
                "countryCode": "BR"
            },
            {
                "name": "Jacobina",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaquara",
                "countryCode": "BR"
            },
            {
                "name": "Jaguarari",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaripe",
                "countryCode": "BR"
            },
            {
                "name": "Jandaíra",
                "countryCode": "BR"
            },
            {
                "name": "Jequié",
                "countryCode": "BR"
            },
            {
                "name": "Jeremoabo",
                "countryCode": "BR"
            },
            {
                "name": "Jiquiriçá",
                "countryCode": "BR"
            },
            {
                "name": "Jitaúna",
                "countryCode": "BR"
            },
            {
                "name": "João Dourado",
                "countryCode": "BR"
            },
            {
                "name": "Juazeiro",
                "countryCode": "BR"
            },
            {
                "name": "Jucuruçu",
                "countryCode": "BR"
            },
            {
                "name": "Jussara",
                "countryCode": "BR"
            },
            {
                "name": "Jussari",
                "countryCode": "BR"
            },
            {
                "name": "Jussiape",
                "countryCode": "BR"
            },
            {
                "name": "Lafaiete Coutinho",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Real",
                "countryCode": "BR"
            },
            {
                "name": "Laje",
                "countryCode": "BR"
            },
            {
                "name": "Lajedinho",
                "countryCode": "BR"
            },
            {
                "name": "Lajedo do Tabocal",
                "countryCode": "BR"
            },
            {
                "name": "Lajedão",
                "countryCode": "BR"
            },
            {
                "name": "Lamarão",
                "countryCode": "BR"
            },
            {
                "name": "Lapão",
                "countryCode": "BR"
            },
            {
                "name": "Lauro de Freitas",
                "countryCode": "BR"
            },
            {
                "name": "Lençóis",
                "countryCode": "BR"
            },
            {
                "name": "Licínio de Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Livramento de Nossa Senhora",
                "countryCode": "BR"
            },
            {
                "name": "Livramento do Brumado",
                "countryCode": "BR"
            },
            {
                "name": "Luís Eduardo Magalhães",
                "countryCode": "BR"
            },
            {
                "name": "Macajuba",
                "countryCode": "BR"
            },
            {
                "name": "Macarani",
                "countryCode": "BR"
            },
            {
                "name": "Macaúbas",
                "countryCode": "BR"
            },
            {
                "name": "Macururé",
                "countryCode": "BR"
            },
            {
                "name": "Madre de Deus",
                "countryCode": "BR"
            },
            {
                "name": "Maetinga",
                "countryCode": "BR"
            },
            {
                "name": "Maiquinique",
                "countryCode": "BR"
            },
            {
                "name": "Mairi",
                "countryCode": "BR"
            },
            {
                "name": "Malhada",
                "countryCode": "BR"
            },
            {
                "name": "Malhada de Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Manoel Vitorino",
                "countryCode": "BR"
            },
            {
                "name": "Mansidão",
                "countryCode": "BR"
            },
            {
                "name": "Maracás",
                "countryCode": "BR"
            },
            {
                "name": "Maragogipe",
                "countryCode": "BR"
            },
            {
                "name": "Maraú",
                "countryCode": "BR"
            },
            {
                "name": "Marcionílio Souza",
                "countryCode": "BR"
            },
            {
                "name": "Mascote",
                "countryCode": "BR"
            },
            {
                "name": "Mata de São João",
                "countryCode": "BR"
            },
            {
                "name": "Matina",
                "countryCode": "BR"
            },
            {
                "name": "Medeiros Neto",
                "countryCode": "BR"
            },
            {
                "name": "Miguel Calmon",
                "countryCode": "BR"
            },
            {
                "name": "Milagres",
                "countryCode": "BR"
            },
            {
                "name": "Mirangaba",
                "countryCode": "BR"
            },
            {
                "name": "Mirante",
                "countryCode": "BR"
            },
            {
                "name": "Monte Santo",
                "countryCode": "BR"
            },
            {
                "name": "Morpará",
                "countryCode": "BR"
            },
            {
                "name": "Morro do Chapéu",
                "countryCode": "BR"
            },
            {
                "name": "Mortugaba",
                "countryCode": "BR"
            },
            {
                "name": "Mucugê",
                "countryCode": "BR"
            },
            {
                "name": "Mucuri",
                "countryCode": "BR"
            },
            {
                "name": "Mulungu do Morro",
                "countryCode": "BR"
            },
            {
                "name": "Mundo Novo",
                "countryCode": "BR"
            },
            {
                "name": "Muniz Ferreira",
                "countryCode": "BR"
            },
            {
                "name": "Muquém do São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Muritiba",
                "countryCode": "BR"
            },
            {
                "name": "Mutuípe",
                "countryCode": "BR"
            },
            {
                "name": "Nazaré",
                "countryCode": "BR"
            },
            {
                "name": "Nilo Peçanha",
                "countryCode": "BR"
            },
            {
                "name": "Nordestina",
                "countryCode": "BR"
            },
            {
                "name": "Nova Canaã",
                "countryCode": "BR"
            },
            {
                "name": "Nova Fátima",
                "countryCode": "BR"
            },
            {
                "name": "Nova Ibiá",
                "countryCode": "BR"
            },
            {
                "name": "Nova Itarana",
                "countryCode": "BR"
            },
            {
                "name": "Nova Redenção",
                "countryCode": "BR"
            },
            {
                "name": "Nova Soure",
                "countryCode": "BR"
            },
            {
                "name": "Nova Viçosa",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Novo Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "Olindina",
                "countryCode": "BR"
            },
            {
                "name": "Oliveira dos Brejinhos",
                "countryCode": "BR"
            },
            {
                "name": "Ouriçangas",
                "countryCode": "BR"
            },
            {
                "name": "Ourolândia",
                "countryCode": "BR"
            },
            {
                "name": "Palmas de Monte Alto",
                "countryCode": "BR"
            },
            {
                "name": "Palmeiras",
                "countryCode": "BR"
            },
            {
                "name": "Paramirim",
                "countryCode": "BR"
            },
            {
                "name": "Paratinga",
                "countryCode": "BR"
            },
            {
                "name": "Paripiranga",
                "countryCode": "BR"
            },
            {
                "name": "Pau Brasil",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Afonso",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Alexandre",
                "countryCode": "BR"
            },
            {
                "name": "Pedrão",
                "countryCode": "BR"
            },
            {
                "name": "Piatã",
                "countryCode": "BR"
            },
            {
                "name": "Pilão Arcado",
                "countryCode": "BR"
            },
            {
                "name": "Pindaí",
                "countryCode": "BR"
            },
            {
                "name": "Pindobaçu",
                "countryCode": "BR"
            },
            {
                "name": "Pintadas",
                "countryCode": "BR"
            },
            {
                "name": "Piraí do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Piripá",
                "countryCode": "BR"
            },
            {
                "name": "Piritiba",
                "countryCode": "BR"
            },
            {
                "name": "Planaltino",
                "countryCode": "BR"
            },
            {
                "name": "Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Pojuca",
                "countryCode": "BR"
            },
            {
                "name": "Ponto Novo",
                "countryCode": "BR"
            },
            {
                "name": "Porto Seguro",
                "countryCode": "BR"
            },
            {
                "name": "Posto da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Potiraguá",
                "countryCode": "BR"
            },
            {
                "name": "Poções",
                "countryCode": "BR"
            },
            {
                "name": "Prado",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Dutra",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Jânio Quadros",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Tancredo Neves",
                "countryCode": "BR"
            },
            {
                "name": "Pé de Serra",
                "countryCode": "BR"
            },
            {
                "name": "Queimadas",
                "countryCode": "BR"
            },
            {
                "name": "Quijingue",
                "countryCode": "BR"
            },
            {
                "name": "Quixabeira",
                "countryCode": "BR"
            },
            {
                "name": "Rafael Jambeiro",
                "countryCode": "BR"
            },
            {
                "name": "Remanso",
                "countryCode": "BR"
            },
            {
                "name": "Retirolândia",
                "countryCode": "BR"
            },
            {
                "name": "Riacho de Santana",
                "countryCode": "BR"
            },
            {
                "name": "Riachão das Neves",
                "countryCode": "BR"
            },
            {
                "name": "Riachão do Jacuípe",
                "countryCode": "BR"
            },
            {
                "name": "Ribeira do Amparo",
                "countryCode": "BR"
            },
            {
                "name": "Ribeira do Pombal",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão do Largo",
                "countryCode": "BR"
            },
            {
                "name": "Rio Real",
                "countryCode": "BR"
            },
            {
                "name": "Rio de Contas",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Pires",
                "countryCode": "BR"
            },
            {
                "name": "Rodelas",
                "countryCode": "BR"
            },
            {
                "name": "Ruy Barbosa",
                "countryCode": "BR"
            },
            {
                "name": "Salinas da Margarida",
                "countryCode": "BR"
            },
            {
                "name": "Salvador",
                "countryCode": "BR"
            },
            {
                "name": "Santa Brígida",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz Cabrália",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz da Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Santa Inês",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria da Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita de Cássia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha",
                "countryCode": "BR"
            },
            {
                "name": "Santaluz",
                "countryCode": "BR"
            },
            {
                "name": "Santana",
                "countryCode": "BR"
            },
            {
                "name": "Santanópolis",
                "countryCode": "BR"
            },
            {
                "name": "Santo Amaro",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio de Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Santo Estêvão",
                "countryCode": "BR"
            },
            {
                "name": "Sapeaçu",
                "countryCode": "BR"
            },
            {
                "name": "Saubara",
                "countryCode": "BR"
            },
            {
                "name": "Saúde",
                "countryCode": "BR"
            },
            {
                "name": "Seabra",
                "countryCode": "BR"
            },
            {
                "name": "Sebastião Laranjeiras",
                "countryCode": "BR"
            },
            {
                "name": "Senhor do Bonfim",
                "countryCode": "BR"
            },
            {
                "name": "Sento Sé",
                "countryCode": "BR"
            },
            {
                "name": "Serra Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Serra Preta",
                "countryCode": "BR"
            },
            {
                "name": "Serra do Ramalho",
                "countryCode": "BR"
            },
            {
                "name": "Serrinha",
                "countryCode": "BR"
            },
            {
                "name": "Serrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Simões Filho",
                "countryCode": "BR"
            },
            {
                "name": "Sobradinho",
                "countryCode": "BR"
            },
            {
                "name": "Souto Soares",
                "countryCode": "BR"
            },
            {
                "name": "Sátiro Dias",
                "countryCode": "BR"
            },
            {
                "name": "São Desidério",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "São Felipe",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Conde",
                "countryCode": "BR"
            },
            {
                "name": "São Félix",
                "countryCode": "BR"
            },
            {
                "name": "São Félix do Coribe",
                "countryCode": "BR"
            },
            {
                "name": "São Gabriel",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo dos Campos",
                "countryCode": "BR"
            },
            {
                "name": "São José da Vitória",
                "countryCode": "BR"
            },
            {
                "name": "São José do Jacuípe",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel das Matas",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Passé",
                "countryCode": "BR"
            },
            {
                "name": "Sítio do Mato",
                "countryCode": "BR"
            },
            {
                "name": "Sítio do Quinto",
                "countryCode": "BR"
            },
            {
                "name": "Tabocas do Brejo Velho",
                "countryCode": "BR"
            },
            {
                "name": "Tanhaçu",
                "countryCode": "BR"
            },
            {
                "name": "Tanque Novo",
                "countryCode": "BR"
            },
            {
                "name": "Tanquinho",
                "countryCode": "BR"
            },
            {
                "name": "Taperoá",
                "countryCode": "BR"
            },
            {
                "name": "Tapiramutá",
                "countryCode": "BR"
            },
            {
                "name": "Teixeira de Freitas",
                "countryCode": "BR"
            },
            {
                "name": "Teodoro Sampaio",
                "countryCode": "BR"
            },
            {
                "name": "Teofilândia",
                "countryCode": "BR"
            },
            {
                "name": "Teolândia",
                "countryCode": "BR"
            },
            {
                "name": "Terra Nova",
                "countryCode": "BR"
            },
            {
                "name": "Tremedal",
                "countryCode": "BR"
            },
            {
                "name": "Tucano",
                "countryCode": "BR"
            },
            {
                "name": "Uauá",
                "countryCode": "BR"
            },
            {
                "name": "Ubaitaba",
                "countryCode": "BR"
            },
            {
                "name": "Ubatã",
                "countryCode": "BR"
            },
            {
                "name": "Ubaíra",
                "countryCode": "BR"
            },
            {
                "name": "Uibaí",
                "countryCode": "BR"
            },
            {
                "name": "Umburanas",
                "countryCode": "BR"
            },
            {
                "name": "Una",
                "countryCode": "BR"
            },
            {
                "name": "Urandi",
                "countryCode": "BR"
            },
            {
                "name": "Uruçuca",
                "countryCode": "BR"
            },
            {
                "name": "Utinga",
                "countryCode": "BR"
            },
            {
                "name": "Valente",
                "countryCode": "BR"
            },
            {
                "name": "Valença",
                "countryCode": "BR"
            },
            {
                "name": "Varzedo",
                "countryCode": "BR"
            },
            {
                "name": "Vera Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Vereda",
                "countryCode": "BR"
            },
            {
                "name": "Vitória da Conquista",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Nova",
                "countryCode": "BR"
            },
            {
                "name": "Várzea da Roça",
                "countryCode": "BR"
            },
            {
                "name": "Várzea do Poço",
                "countryCode": "BR"
            },
            {
                "name": "Wagner",
                "countryCode": "BR"
            },
            {
                "name": "Wanderley",
                "countryCode": "BR"
            },
            {
                "name": "Wenceslau Guimarães",
                "countryCode": "BR"
            },
            {
                "name": "Xique Xique",
                "countryCode": "BR"
            },
            {
                "name": "Xique-Xique",
                "countryCode": "BR"
            },
            {
                "name": "Água Fria",
                "countryCode": "BR"
            },
            {
                "name": "Érico Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Abaiara",
                "countryCode": "BR"
            },
            {
                "name": "Acarape",
                "countryCode": "BR"
            },
            {
                "name": "Acaraú",
                "countryCode": "BR"
            },
            {
                "name": "Acopiara",
                "countryCode": "BR"
            },
            {
                "name": "Aiuaba",
                "countryCode": "BR"
            },
            {
                "name": "Alcântaras",
                "countryCode": "BR"
            },
            {
                "name": "Altaneira",
                "countryCode": "BR"
            },
            {
                "name": "Alto Santo",
                "countryCode": "BR"
            },
            {
                "name": "Amontada",
                "countryCode": "BR"
            },
            {
                "name": "Antonina do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Apuiarés",
                "countryCode": "BR"
            },
            {
                "name": "Aquiraz",
                "countryCode": "BR"
            },
            {
                "name": "Aracati",
                "countryCode": "BR"
            },
            {
                "name": "Aracoiaba",
                "countryCode": "BR"
            },
            {
                "name": "Ararendá",
                "countryCode": "BR"
            },
            {
                "name": "Araripe",
                "countryCode": "BR"
            },
            {
                "name": "Aratuba",
                "countryCode": "BR"
            },
            {
                "name": "Arneiroz",
                "countryCode": "BR"
            },
            {
                "name": "Assaré",
                "countryCode": "BR"
            },
            {
                "name": "Aurora",
                "countryCode": "BR"
            },
            {
                "name": "Baixio",
                "countryCode": "BR"
            },
            {
                "name": "Banabuiú",
                "countryCode": "BR"
            },
            {
                "name": "Barbalha",
                "countryCode": "BR"
            },
            {
                "name": "Barreira",
                "countryCode": "BR"
            },
            {
                "name": "Barro",
                "countryCode": "BR"
            },
            {
                "name": "Barroquinha",
                "countryCode": "BR"
            },
            {
                "name": "Baturité",
                "countryCode": "BR"
            },
            {
                "name": "Beberibe",
                "countryCode": "BR"
            },
            {
                "name": "Bela Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Boa Viagem",
                "countryCode": "BR"
            },
            {
                "name": "Brejo Santo",
                "countryCode": "BR"
            },
            {
                "name": "Camocim",
                "countryCode": "BR"
            },
            {
                "name": "Campos Sales",
                "countryCode": "BR"
            },
            {
                "name": "Canindé",
                "countryCode": "BR"
            },
            {
                "name": "Capistrano",
                "countryCode": "BR"
            },
            {
                "name": "Caridade",
                "countryCode": "BR"
            },
            {
                "name": "Caririaçu",
                "countryCode": "BR"
            },
            {
                "name": "Cariré",
                "countryCode": "BR"
            },
            {
                "name": "Cariús",
                "countryCode": "BR"
            },
            {
                "name": "Carnaubal",
                "countryCode": "BR"
            },
            {
                "name": "Cascavel",
                "countryCode": "BR"
            },
            {
                "name": "Catarina",
                "countryCode": "BR"
            },
            {
                "name": "Catunda",
                "countryCode": "BR"
            },
            {
                "name": "Caucaia",
                "countryCode": "BR"
            },
            {
                "name": "Cedro",
                "countryCode": "BR"
            },
            {
                "name": "Chaval",
                "countryCode": "BR"
            },
            {
                "name": "Chorozinho",
                "countryCode": "BR"
            },
            {
                "name": "Choró",
                "countryCode": "BR"
            },
            {
                "name": "Coreaú",
                "countryCode": "BR"
            },
            {
                "name": "Crateús",
                "countryCode": "BR"
            },
            {
                "name": "Crato",
                "countryCode": "BR"
            },
            {
                "name": "Croatá",
                "countryCode": "BR"
            },
            {
                "name": "Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Deputado Irapuan Pinheiro",
                "countryCode": "BR"
            },
            {
                "name": "Ererê",
                "countryCode": "BR"
            },
            {
                "name": "Eusébio",
                "countryCode": "BR"
            },
            {
                "name": "Farias Brito",
                "countryCode": "BR"
            },
            {
                "name": "Forquilha",
                "countryCode": "BR"
            },
            {
                "name": "Fortaleza",
                "countryCode": "BR"
            },
            {
                "name": "Fortim",
                "countryCode": "BR"
            },
            {
                "name": "Frecheirinha",
                "countryCode": "BR"
            },
            {
                "name": "General Sampaio",
                "countryCode": "BR"
            },
            {
                "name": "Granja",
                "countryCode": "BR"
            },
            {
                "name": "Granjeiro",
                "countryCode": "BR"
            },
            {
                "name": "Graça",
                "countryCode": "BR"
            },
            {
                "name": "Groaíras",
                "countryCode": "BR"
            },
            {
                "name": "Guaiúba",
                "countryCode": "BR"
            },
            {
                "name": "Guaraciaba do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Guaramiranga",
                "countryCode": "BR"
            },
            {
                "name": "Hidrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Ibaretama",
                "countryCode": "BR"
            },
            {
                "name": "Ibiapina",
                "countryCode": "BR"
            },
            {
                "name": "Ibicuitinga",
                "countryCode": "BR"
            },
            {
                "name": "Icapuí",
                "countryCode": "BR"
            },
            {
                "name": "Icó",
                "countryCode": "BR"
            },
            {
                "name": "Iguatu",
                "countryCode": "BR"
            },
            {
                "name": "Independência",
                "countryCode": "BR"
            },
            {
                "name": "Ipaporanga",
                "countryCode": "BR"
            },
            {
                "name": "Ipaumirim",
                "countryCode": "BR"
            },
            {
                "name": "Ipu",
                "countryCode": "BR"
            },
            {
                "name": "Ipueiras",
                "countryCode": "BR"
            },
            {
                "name": "Iracema",
                "countryCode": "BR"
            },
            {
                "name": "Irauçuba",
                "countryCode": "BR"
            },
            {
                "name": "Itaitinga",
                "countryCode": "BR"
            },
            {
                "name": "Itaiçaba",
                "countryCode": "BR"
            },
            {
                "name": "Itapagé",
                "countryCode": "BR"
            },
            {
                "name": "Itapajé",
                "countryCode": "BR"
            },
            {
                "name": "Itapipoca",
                "countryCode": "BR"
            },
            {
                "name": "Itapiúna",
                "countryCode": "BR"
            },
            {
                "name": "Itarema",
                "countryCode": "BR"
            },
            {
                "name": "Itatira",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaretama",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaribara",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaribe",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaruana",
                "countryCode": "BR"
            },
            {
                "name": "Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Jati",
                "countryCode": "BR"
            },
            {
                "name": "Jijoca de Jericoacoara",
                "countryCode": "BR"
            },
            {
                "name": "Juazeiro do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Jucás",
                "countryCode": "BR"
            },
            {
                "name": "Juá dos Vieiras",
                "countryCode": "BR"
            },
            {
                "name": "Lavras da Mangabeira",
                "countryCode": "BR"
            },
            {
                "name": "Limoeiro do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Madalena",
                "countryCode": "BR"
            },
            {
                "name": "Maracanaú",
                "countryCode": "BR"
            },
            {
                "name": "Maranguape",
                "countryCode": "BR"
            },
            {
                "name": "Marco",
                "countryCode": "BR"
            },
            {
                "name": "Martinópole",
                "countryCode": "BR"
            },
            {
                "name": "Massapê",
                "countryCode": "BR"
            },
            {
                "name": "Mauriti",
                "countryCode": "BR"
            },
            {
                "name": "Meruoca",
                "countryCode": "BR"
            },
            {
                "name": "Milagres",
                "countryCode": "BR"
            },
            {
                "name": "Milhã",
                "countryCode": "BR"
            },
            {
                "name": "Miraíma",
                "countryCode": "BR"
            },
            {
                "name": "Missão Velha",
                "countryCode": "BR"
            },
            {
                "name": "Mombaça",
                "countryCode": "BR"
            },
            {
                "name": "Monsenhor Tabosa",
                "countryCode": "BR"
            },
            {
                "name": "Morada Nova",
                "countryCode": "BR"
            },
            {
                "name": "Moraújo",
                "countryCode": "BR"
            },
            {
                "name": "Morrinhos",
                "countryCode": "BR"
            },
            {
                "name": "Mucambo",
                "countryCode": "BR"
            },
            {
                "name": "Mulungu",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olinda",
                "countryCode": "BR"
            },
            {
                "name": "Nova Russas",
                "countryCode": "BR"
            },
            {
                "name": "Novo Oriente",
                "countryCode": "BR"
            },
            {
                "name": "Ocara",
                "countryCode": "BR"
            },
            {
                "name": "Orós",
                "countryCode": "BR"
            },
            {
                "name": "Pacajus",
                "countryCode": "BR"
            },
            {
                "name": "Pacatuba",
                "countryCode": "BR"
            },
            {
                "name": "Pacoti",
                "countryCode": "BR"
            },
            {
                "name": "Pacujá",
                "countryCode": "BR"
            },
            {
                "name": "Palhano",
                "countryCode": "BR"
            },
            {
                "name": "Palmácia",
                "countryCode": "BR"
            },
            {
                "name": "Paracuru",
                "countryCode": "BR"
            },
            {
                "name": "Paraipaba",
                "countryCode": "BR"
            },
            {
                "name": "Parambu",
                "countryCode": "BR"
            },
            {
                "name": "Paramoti",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Branca",
                "countryCode": "BR"
            },
            {
                "name": "Penaforte",
                "countryCode": "BR"
            },
            {
                "name": "Pentecoste",
                "countryCode": "BR"
            },
            {
                "name": "Pereiro",
                "countryCode": "BR"
            },
            {
                "name": "Pindoretama",
                "countryCode": "BR"
            },
            {
                "name": "Piquet Carneiro",
                "countryCode": "BR"
            },
            {
                "name": "Pires Ferreira",
                "countryCode": "BR"
            },
            {
                "name": "Poranga",
                "countryCode": "BR"
            },
            {
                "name": "Porteiras",
                "countryCode": "BR"
            },
            {
                "name": "Potengi",
                "countryCode": "BR"
            },
            {
                "name": "Potiretama",
                "countryCode": "BR"
            },
            {
                "name": "Quiterianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Quixadá",
                "countryCode": "BR"
            },
            {
                "name": "Quixelô",
                "countryCode": "BR"
            },
            {
                "name": "Quixeramobim",
                "countryCode": "BR"
            },
            {
                "name": "Quixeré",
                "countryCode": "BR"
            },
            {
                "name": "Redenção",
                "countryCode": "BR"
            },
            {
                "name": "Reriutaba",
                "countryCode": "BR"
            },
            {
                "name": "Russas",
                "countryCode": "BR"
            },
            {
                "name": "Saboeiro",
                "countryCode": "BR"
            },
            {
                "name": "Salitre",
                "countryCode": "BR"
            },
            {
                "name": "Santa Quitéria",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Acaraú",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Cariri",
                "countryCode": "BR"
            },
            {
                "name": "Senador Pompeu",
                "countryCode": "BR"
            },
            {
                "name": "Senador Sá",
                "countryCode": "BR"
            },
            {
                "name": "Sobral",
                "countryCode": "BR"
            },
            {
                "name": "Solonópole",
                "countryCode": "BR"
            },
            {
                "name": "São Benedito",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Amarante",
                "countryCode": "BR"
            },
            {
                "name": "São João do Jaguaribe",
                "countryCode": "BR"
            },
            {
                "name": "São João dos Inhamuns",
                "countryCode": "BR"
            },
            {
                "name": "São Luís do Curu",
                "countryCode": "BR"
            },
            {
                "name": "Tabuleiro do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Tamboril",
                "countryCode": "BR"
            },
            {
                "name": "Tarrafas",
                "countryCode": "BR"
            },
            {
                "name": "Tauá",
                "countryCode": "BR"
            },
            {
                "name": "Tejuçuoca",
                "countryCode": "BR"
            },
            {
                "name": "Tianguá",
                "countryCode": "BR"
            },
            {
                "name": "Trairi",
                "countryCode": "BR"
            },
            {
                "name": "Tururu",
                "countryCode": "BR"
            },
            {
                "name": "Ubajara",
                "countryCode": "BR"
            },
            {
                "name": "Umari",
                "countryCode": "BR"
            },
            {
                "name": "Umirim",
                "countryCode": "BR"
            },
            {
                "name": "Uruburetama",
                "countryCode": "BR"
            },
            {
                "name": "Uruoca",
                "countryCode": "BR"
            },
            {
                "name": "Varjota",
                "countryCode": "BR"
            },
            {
                "name": "Viçosa do Ceará",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Brasília",
                "countryCode": "BR"
            },
            {
                "name": "Planaltina",
                "countryCode": "BR"
            },
            {
                "name": "Afonso Cláudio",
                "countryCode": "BR"
            },
            {
                "name": "Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Alfredo Chaves",
                "countryCode": "BR"
            },
            {
                "name": "Alto Rio Novo",
                "countryCode": "BR"
            },
            {
                "name": "Anchieta",
                "countryCode": "BR"
            },
            {
                "name": "Apiacá",
                "countryCode": "BR"
            },
            {
                "name": "Aracruz",
                "countryCode": "BR"
            },
            {
                "name": "Atílio Vivacqua",
                "countryCode": "BR"
            },
            {
                "name": "Baixo Guandu",
                "countryCode": "BR"
            },
            {
                "name": "Barra de São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Boa Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Brejetuba",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeiro de Itapemirim",
                "countryCode": "BR"
            },
            {
                "name": "Cariacica",
                "countryCode": "BR"
            },
            {
                "name": "Castelo",
                "countryCode": "BR"
            },
            {
                "name": "Colatina",
                "countryCode": "BR"
            },
            {
                "name": "Conceição da Barra",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Castelo",
                "countryCode": "BR"
            },
            {
                "name": "Divino de São Lourenço",
                "countryCode": "BR"
            },
            {
                "name": "Domingos Martins",
                "countryCode": "BR"
            },
            {
                "name": "Dores do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "Ecoporanga",
                "countryCode": "BR"
            },
            {
                "name": "Fundão",
                "countryCode": "BR"
            },
            {
                "name": "Governador Lindenberg",
                "countryCode": "BR"
            },
            {
                "name": "Guarapari",
                "countryCode": "BR"
            },
            {
                "name": "Guaçuí",
                "countryCode": "BR"
            },
            {
                "name": "Ibatiba",
                "countryCode": "BR"
            },
            {
                "name": "Ibiraçu",
                "countryCode": "BR"
            },
            {
                "name": "Ibitirama",
                "countryCode": "BR"
            },
            {
                "name": "Iconha",
                "countryCode": "BR"
            },
            {
                "name": "Irupi",
                "countryCode": "BR"
            },
            {
                "name": "Itaguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Itapemirim",
                "countryCode": "BR"
            },
            {
                "name": "Itarana",
                "countryCode": "BR"
            },
            {
                "name": "Iúna",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaré",
                "countryCode": "BR"
            },
            {
                "name": "Jerônimo Monteiro",
                "countryCode": "BR"
            },
            {
                "name": "Jetibá",
                "countryCode": "BR"
            },
            {
                "name": "João Neiva",
                "countryCode": "BR"
            },
            {
                "name": "Laranja da Terra",
                "countryCode": "BR"
            },
            {
                "name": "Linhares",
                "countryCode": "BR"
            },
            {
                "name": "Mantenópolis",
                "countryCode": "BR"
            },
            {
                "name": "Marataizes",
                "countryCode": "BR"
            },
            {
                "name": "Marechal Floriano",
                "countryCode": "BR"
            },
            {
                "name": "Marilândia",
                "countryCode": "BR"
            },
            {
                "name": "Mimoso do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Montanha",
                "countryCode": "BR"
            },
            {
                "name": "Mucurici",
                "countryCode": "BR"
            },
            {
                "name": "Muniz Freire",
                "countryCode": "BR"
            },
            {
                "name": "Muqui",
                "countryCode": "BR"
            },
            {
                "name": "Nova Venécia",
                "countryCode": "BR"
            },
            {
                "name": "Pancas",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Canário",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiros",
                "countryCode": "BR"
            },
            {
                "name": "Piúma",
                "countryCode": "BR"
            },
            {
                "name": "Ponto Belo",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Kennedy",
                "countryCode": "BR"
            },
            {
                "name": "Rio Bananal",
                "countryCode": "BR"
            },
            {
                "name": "Rio Novo do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Leopoldina",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria de Jetibá",
                "countryCode": "BR"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "BR"
            },
            {
                "name": "Serra",
                "countryCode": "BR"
            },
            {
                "name": "Sooretama",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Norte",
                "countryCode": "BR"
            },
            {
                "name": "São Gabriel da Palha",
                "countryCode": "BR"
            },
            {
                "name": "São José do Calçado",
                "countryCode": "BR"
            },
            {
                "name": "São Mateus",
                "countryCode": "BR"
            },
            {
                "name": "São Roque do Canaã",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Alta",
                "countryCode": "BR"
            },
            {
                "name": "Venda Nova do Imigrante",
                "countryCode": "BR"
            },
            {
                "name": "Viana",
                "countryCode": "BR"
            },
            {
                "name": "Vila Pavão",
                "countryCode": "BR"
            },
            {
                "name": "Vila Valério",
                "countryCode": "BR"
            },
            {
                "name": "Vila Velha",
                "countryCode": "BR"
            },
            {
                "name": "Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Água Doce do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Águia Branca",
                "countryCode": "BR"
            },
            {
                "name": "Abadia de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Abadiânia",
                "countryCode": "BR"
            },
            {
                "name": "Acreúna",
                "countryCode": "BR"
            },
            {
                "name": "Adelândia",
                "countryCode": "BR"
            },
            {
                "name": "Alexânia",
                "countryCode": "BR"
            },
            {
                "name": "Aloândia",
                "countryCode": "BR"
            },
            {
                "name": "Alto Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Alto Paraíso de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Amaralina",
                "countryCode": "BR"
            },
            {
                "name": "Americano do Brasil",
                "countryCode": "BR"
            },
            {
                "name": "Amorinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Anhanguera",
                "countryCode": "BR"
            },
            {
                "name": "Anicuns",
                "countryCode": "BR"
            },
            {
                "name": "Anápolis",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida de Goiânia",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida do Rio Doce",
                "countryCode": "BR"
            },
            {
                "name": "Aporé",
                "countryCode": "BR"
            },
            {
                "name": "Aragarças",
                "countryCode": "BR"
            },
            {
                "name": "Aragoiânia",
                "countryCode": "BR"
            },
            {
                "name": "Araguapaz",
                "countryCode": "BR"
            },
            {
                "name": "Araçu",
                "countryCode": "BR"
            },
            {
                "name": "Arenópolis",
                "countryCode": "BR"
            },
            {
                "name": "Aruanã",
                "countryCode": "BR"
            },
            {
                "name": "Aurilândia",
                "countryCode": "BR"
            },
            {
                "name": "Avelinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Baliza",
                "countryCode": "BR"
            },
            {
                "name": "Barro Alto",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Bonfinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Bonópolis",
                "countryCode": "BR"
            },
            {
                "name": "Brazabrantes",
                "countryCode": "BR"
            },
            {
                "name": "Britânia",
                "countryCode": "BR"
            },
            {
                "name": "Buriti Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Buriti de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Buritinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Cabeceiras",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira Alta",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Caiapônia",
                "countryCode": "BR"
            },
            {
                "name": "Caldas Novas",
                "countryCode": "BR"
            },
            {
                "name": "Caldazinha",
                "countryCode": "BR"
            },
            {
                "name": "Campestre de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Campinaçu",
                "countryCode": "BR"
            },
            {
                "name": "Campinorte",
                "countryCode": "BR"
            },
            {
                "name": "Campo Alegre de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Campo Limpo de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Campos Belos",
                "countryCode": "BR"
            },
            {
                "name": "Campos Verdes",
                "countryCode": "BR"
            },
            {
                "name": "Carmo do Rio Verde",
                "countryCode": "BR"
            },
            {
                "name": "Castelândia",
                "countryCode": "BR"
            },
            {
                "name": "Catalão",
                "countryCode": "BR"
            },
            {
                "name": "Caturaí",
                "countryCode": "BR"
            },
            {
                "name": "Cavalcante",
                "countryCode": "BR"
            },
            {
                "name": "Caçu",
                "countryCode": "BR"
            },
            {
                "name": "Ceres",
                "countryCode": "BR"
            },
            {
                "name": "Cezarina",
                "countryCode": "BR"
            },
            {
                "name": "Chapadão do Céu",
                "countryCode": "BR"
            },
            {
                "name": "Cidade Ocidental",
                "countryCode": "BR"
            },
            {
                "name": "Cocalzinho de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Colinas do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Corumbaíba",
                "countryCode": "BR"
            },
            {
                "name": "Corumbá de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Cristalina",
                "countryCode": "BR"
            },
            {
                "name": "Cristianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Crixás",
                "countryCode": "BR"
            },
            {
                "name": "Cromínia",
                "countryCode": "BR"
            },
            {
                "name": "Cumari",
                "countryCode": "BR"
            },
            {
                "name": "Córrego do Ouro",
                "countryCode": "BR"
            },
            {
                "name": "Damianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Damolândia",
                "countryCode": "BR"
            },
            {
                "name": "Davinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Diorama",
                "countryCode": "BR"
            },
            {
                "name": "Divinópolis de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Doverlândia",
                "countryCode": "BR"
            },
            {
                "name": "Edealina",
                "countryCode": "BR"
            },
            {
                "name": "Edéia",
                "countryCode": "BR"
            },
            {
                "name": "Estrela do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Faina",
                "countryCode": "BR"
            },
            {
                "name": "Fazenda Nova",
                "countryCode": "BR"
            },
            {
                "name": "Firminópolis",
                "countryCode": "BR"
            },
            {
                "name": "Flores de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Formosa",
                "countryCode": "BR"
            },
            {
                "name": "Formoso",
                "countryCode": "BR"
            },
            {
                "name": "Gameleira de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Goiandira",
                "countryCode": "BR"
            },
            {
                "name": "Goianira",
                "countryCode": "BR"
            },
            {
                "name": "Goianápolis",
                "countryCode": "BR"
            },
            {
                "name": "Goianésia",
                "countryCode": "BR"
            },
            {
                "name": "Goiatuba",
                "countryCode": "BR"
            },
            {
                "name": "Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Goiânia",
                "countryCode": "BR"
            },
            {
                "name": "Gouvelândia",
                "countryCode": "BR"
            },
            {
                "name": "Guapó",
                "countryCode": "BR"
            },
            {
                "name": "Guarani de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Guaraíta",
                "countryCode": "BR"
            },
            {
                "name": "Guarinos",
                "countryCode": "BR"
            },
            {
                "name": "Heitoraí",
                "countryCode": "BR"
            },
            {
                "name": "Hidrolina",
                "countryCode": "BR"
            },
            {
                "name": "Hidrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Iaciara",
                "countryCode": "BR"
            },
            {
                "name": "Inaciolândia",
                "countryCode": "BR"
            },
            {
                "name": "Indiara",
                "countryCode": "BR"
            },
            {
                "name": "Inhumas",
                "countryCode": "BR"
            },
            {
                "name": "Ipameri",
                "countryCode": "BR"
            },
            {
                "name": "Ipiranga de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Iporá",
                "countryCode": "BR"
            },
            {
                "name": "Israelândia",
                "countryCode": "BR"
            },
            {
                "name": "Itaberaí",
                "countryCode": "BR"
            },
            {
                "name": "Itaguari",
                "countryCode": "BR"
            },
            {
                "name": "Itaguaru",
                "countryCode": "BR"
            },
            {
                "name": "Itajá",
                "countryCode": "BR"
            },
            {
                "name": "Itapaci",
                "countryCode": "BR"
            },
            {
                "name": "Itapirapuã",
                "countryCode": "BR"
            },
            {
                "name": "Itapuranga",
                "countryCode": "BR"
            },
            {
                "name": "Itarumã",
                "countryCode": "BR"
            },
            {
                "name": "Itauçu",
                "countryCode": "BR"
            },
            {
                "name": "Itumbiara",
                "countryCode": "BR"
            },
            {
                "name": "Ivolândia",
                "countryCode": "BR"
            },
            {
                "name": "Jandaia",
                "countryCode": "BR"
            },
            {
                "name": "Jaraguá",
                "countryCode": "BR"
            },
            {
                "name": "Jataí",
                "countryCode": "BR"
            },
            {
                "name": "Jaupaci",
                "countryCode": "BR"
            },
            {
                "name": "Jesúpolis",
                "countryCode": "BR"
            },
            {
                "name": "Joviânia",
                "countryCode": "BR"
            },
            {
                "name": "Jussara",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Santa",
                "countryCode": "BR"
            },
            {
                "name": "Leopoldo de Bulhões",
                "countryCode": "BR"
            },
            {
                "name": "Luziânia",
                "countryCode": "BR"
            },
            {
                "name": "Mairipotaba",
                "countryCode": "BR"
            },
            {
                "name": "Mambaí",
                "countryCode": "BR"
            },
            {
                "name": "Mara Rosa",
                "countryCode": "BR"
            },
            {
                "name": "Marzagão",
                "countryCode": "BR"
            },
            {
                "name": "Matrinchã",
                "countryCode": "BR"
            },
            {
                "name": "Maurilândia",
                "countryCode": "BR"
            },
            {
                "name": "Mimoso de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Minaçu",
                "countryCode": "BR"
            },
            {
                "name": "Mineiros",
                "countryCode": "BR"
            },
            {
                "name": "Moiporá",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Montes Claros de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Montividiu",
                "countryCode": "BR"
            },
            {
                "name": "Montividiu do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Morrinhos",
                "countryCode": "BR"
            },
            {
                "name": "Morro Agudo de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Mossâmedes",
                "countryCode": "BR"
            },
            {
                "name": "Mozarlândia",
                "countryCode": "BR"
            },
            {
                "name": "Mundo Novo",
                "countryCode": "BR"
            },
            {
                "name": "Mutunópolis",
                "countryCode": "BR"
            },
            {
                "name": "Nazário",
                "countryCode": "BR"
            },
            {
                "name": "Nerópolis",
                "countryCode": "BR"
            },
            {
                "name": "Niquelândia",
                "countryCode": "BR"
            },
            {
                "name": "Nova América",
                "countryCode": "BR"
            },
            {
                "name": "Nova Aurora",
                "countryCode": "BR"
            },
            {
                "name": "Nova Crixás",
                "countryCode": "BR"
            },
            {
                "name": "Nova Glória",
                "countryCode": "BR"
            },
            {
                "name": "Nova Iguaçu de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Nova Roma",
                "countryCode": "BR"
            },
            {
                "name": "Nova Veneza",
                "countryCode": "BR"
            },
            {
                "name": "Novo Brasil",
                "countryCode": "BR"
            },
            {
                "name": "Novo Gama",
                "countryCode": "BR"
            },
            {
                "name": "Novo Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Orizona",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Verde de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Ouvidor",
                "countryCode": "BR"
            },
            {
                "name": "Padre Bernardo",
                "countryCode": "BR"
            },
            {
                "name": "Palestina de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Palmeiras de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Palmelo",
                "countryCode": "BR"
            },
            {
                "name": "Palminópolis",
                "countryCode": "BR"
            },
            {
                "name": "Panamá",
                "countryCode": "BR"
            },
            {
                "name": "Paranaiguara",
                "countryCode": "BR"
            },
            {
                "name": "Paraúna",
                "countryCode": "BR"
            },
            {
                "name": "Perolândia",
                "countryCode": "BR"
            },
            {
                "name": "Petrolina de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Pilar de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Piracanjuba",
                "countryCode": "BR"
            },
            {
                "name": "Piranhas",
                "countryCode": "BR"
            },
            {
                "name": "Pirenópolis",
                "countryCode": "BR"
            },
            {
                "name": "Pires do Rio",
                "countryCode": "BR"
            },
            {
                "name": "Planaltina",
                "countryCode": "BR"
            },
            {
                "name": "Pontalina",
                "countryCode": "BR"
            },
            {
                "name": "Porangatu",
                "countryCode": "BR"
            },
            {
                "name": "Porteirão",
                "countryCode": "BR"
            },
            {
                "name": "Portelândia",
                "countryCode": "BR"
            },
            {
                "name": "Posse",
                "countryCode": "BR"
            },
            {
                "name": "Professor Jamil",
                "countryCode": "BR"
            },
            {
                "name": "Quirinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Rialma",
                "countryCode": "BR"
            },
            {
                "name": "Rianápolis",
                "countryCode": "BR"
            },
            {
                "name": "Rio Quente",
                "countryCode": "BR"
            },
            {
                "name": "Rio Verde",
                "countryCode": "BR"
            },
            {
                "name": "Rubiataba",
                "countryCode": "BR"
            },
            {
                "name": "Sanclerlândia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Fé de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Novo Destino",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Tereza de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio da Barra",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Descoberto",
                "countryCode": "BR"
            },
            {
                "name": "Senador Canedo",
                "countryCode": "BR"
            },
            {
                "name": "Serranópolis",
                "countryCode": "BR"
            },
            {
                "name": "Silvânia",
                "countryCode": "BR"
            },
            {
                "name": "Simolândia",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "São João d'Aliança",
                "countryCode": "BR"
            },
            {
                "name": "São João da Paraúna",
                "countryCode": "BR"
            },
            {
                "name": "São Luiz do Norte",
                "countryCode": "BR"
            },
            {
                "name": "São Luís de Montes Belos",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Passa Quatro",
                "countryCode": "BR"
            },
            {
                "name": "São Patrício",
                "countryCode": "BR"
            },
            {
                "name": "São Simão",
                "countryCode": "BR"
            },
            {
                "name": "Sítio d'Abadia",
                "countryCode": "BR"
            },
            {
                "name": "Taquaral de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Teresina de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Terezópolis de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Trindade",
                "countryCode": "BR"
            },
            {
                "name": "Trombas",
                "countryCode": "BR"
            },
            {
                "name": "Três Ranchos",
                "countryCode": "BR"
            },
            {
                "name": "Turvelândia",
                "countryCode": "BR"
            },
            {
                "name": "Turvânia",
                "countryCode": "BR"
            },
            {
                "name": "Uirapuru",
                "countryCode": "BR"
            },
            {
                "name": "Uruana",
                "countryCode": "BR"
            },
            {
                "name": "Uruaçu",
                "countryCode": "BR"
            },
            {
                "name": "Urutaí",
                "countryCode": "BR"
            },
            {
                "name": "Valparaíso de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Varjão",
                "countryCode": "BR"
            },
            {
                "name": "Vianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Vicentinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Vila Boa",
                "countryCode": "BR"
            },
            {
                "name": "Vila Propício",
                "countryCode": "BR"
            },
            {
                "name": "Água Fria de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Água Limpa",
                "countryCode": "BR"
            },
            {
                "name": "Águas Lindas de Goiás",
                "countryCode": "BR"
            },
            {
                "name": "Afonso Cunha",
                "countryCode": "BR"
            },
            {
                "name": "Alcântara",
                "countryCode": "BR"
            },
            {
                "name": "Aldeias Altas",
                "countryCode": "BR"
            },
            {
                "name": "Altamira do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Alto Alegre do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Alto Alegre do Pindaré",
                "countryCode": "BR"
            },
            {
                "name": "Alto Parnaíba",
                "countryCode": "BR"
            },
            {
                "name": "Amapá do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Amarante do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Anajatuba",
                "countryCode": "BR"
            },
            {
                "name": "Anapurus",
                "countryCode": "BR"
            },
            {
                "name": "Apicum-Açu",
                "countryCode": "BR"
            },
            {
                "name": "Araguanã",
                "countryCode": "BR"
            },
            {
                "name": "Araioses",
                "countryCode": "BR"
            },
            {
                "name": "Arame",
                "countryCode": "BR"
            },
            {
                "name": "Arari",
                "countryCode": "BR"
            },
            {
                "name": "Atins",
                "countryCode": "BR"
            },
            {
                "name": "Axixá",
                "countryCode": "BR"
            },
            {
                "name": "Açailândia",
                "countryCode": "BR"
            },
            {
                "name": "Bacabal",
                "countryCode": "BR"
            },
            {
                "name": "Bacabeira",
                "countryCode": "BR"
            },
            {
                "name": "Bacuri",
                "countryCode": "BR"
            },
            {
                "name": "Bacurituba",
                "countryCode": "BR"
            },
            {
                "name": "Balsas",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Corda",
                "countryCode": "BR"
            },
            {
                "name": "Barreirinhas",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Grajaú",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Belágua",
                "countryCode": "BR"
            },
            {
                "name": "Benedito Leite",
                "countryCode": "BR"
            },
            {
                "name": "Bequimão",
                "countryCode": "BR"
            },
            {
                "name": "Bernardo do Mearim",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Gurupi",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus das Selvas",
                "countryCode": "BR"
            },
            {
                "name": "Bom Lugar",
                "countryCode": "BR"
            },
            {
                "name": "Brejo",
                "countryCode": "BR"
            },
            {
                "name": "Brejo de Areia",
                "countryCode": "BR"
            },
            {
                "name": "Buriti",
                "countryCode": "BR"
            },
            {
                "name": "Buriti Bravo",
                "countryCode": "BR"
            },
            {
                "name": "Buriticupu",
                "countryCode": "BR"
            },
            {
                "name": "Buritirana",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira Grande",
                "countryCode": "BR"
            },
            {
                "name": "Cajapió",
                "countryCode": "BR"
            },
            {
                "name": "Cajari",
                "countryCode": "BR"
            },
            {
                "name": "Campestre do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Cantanhede",
                "countryCode": "BR"
            },
            {
                "name": "Capinzal do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Carolina",
                "countryCode": "BR"
            },
            {
                "name": "Carutapera",
                "countryCode": "BR"
            },
            {
                "name": "Caxias",
                "countryCode": "BR"
            },
            {
                "name": "Cedral",
                "countryCode": "BR"
            },
            {
                "name": "Central do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Centro Novo do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Centro do Guilherme",
                "countryCode": "BR"
            },
            {
                "name": "Chapadinha",
                "countryCode": "BR"
            },
            {
                "name": "Cidelândia",
                "countryCode": "BR"
            },
            {
                "name": "Codó",
                "countryCode": "BR"
            },
            {
                "name": "Coelho Neto",
                "countryCode": "BR"
            },
            {
                "name": "Colinas",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Lago-Açu",
                "countryCode": "BR"
            },
            {
                "name": "Coroatá",
                "countryCode": "BR"
            },
            {
                "name": "Cururupu",
                "countryCode": "BR"
            },
            {
                "name": "Cândido Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Davinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Dom Pedro",
                "countryCode": "BR"
            },
            {
                "name": "Duque Bacelar",
                "countryCode": "BR"
            },
            {
                "name": "Esperantinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Estreito",
                "countryCode": "BR"
            },
            {
                "name": "Feira Nova do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Fernando Falcão",
                "countryCode": "BR"
            },
            {
                "name": "Formosa da Serra Negra",
                "countryCode": "BR"
            },
            {
                "name": "Fortaleza dos Nogueiras",
                "countryCode": "BR"
            },
            {
                "name": "Fortuna",
                "countryCode": "BR"
            },
            {
                "name": "Godofredo Viana",
                "countryCode": "BR"
            },
            {
                "name": "Gonçalves Dias",
                "countryCode": "BR"
            },
            {
                "name": "Governador Archer",
                "countryCode": "BR"
            },
            {
                "name": "Governador Edison Lobão",
                "countryCode": "BR"
            },
            {
                "name": "Governador Eugênio Barros",
                "countryCode": "BR"
            },
            {
                "name": "Governador Luiz Rocha",
                "countryCode": "BR"
            },
            {
                "name": "Governador Newton Bello",
                "countryCode": "BR"
            },
            {
                "name": "Governador Nunes Freire",
                "countryCode": "BR"
            },
            {
                "name": "Grajaú",
                "countryCode": "BR"
            },
            {
                "name": "Graça Aranha",
                "countryCode": "BR"
            },
            {
                "name": "Guimarães",
                "countryCode": "BR"
            },
            {
                "name": "Humberto de Campos",
                "countryCode": "BR"
            },
            {
                "name": "Icatu",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé Grande",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé do Meio",
                "countryCode": "BR"
            },
            {
                "name": "Imperatriz",
                "countryCode": "BR"
            },
            {
                "name": "Itaipava do Grajaú",
                "countryCode": "BR"
            },
            {
                "name": "Itapecuru Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Itinga do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Jatobá",
                "countryCode": "BR"
            },
            {
                "name": "Jenipapo dos Vieiras",
                "countryCode": "BR"
            },
            {
                "name": "Joselândia",
                "countryCode": "BR"
            },
            {
                "name": "João Lisboa",
                "countryCode": "BR"
            },
            {
                "name": "Junco do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Lago Verde",
                "countryCode": "BR"
            },
            {
                "name": "Lago da Pedra",
                "countryCode": "BR"
            },
            {
                "name": "Lago do Junco",
                "countryCode": "BR"
            },
            {
                "name": "Lago dos Rodrigues",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Grande do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Mato",
                "countryCode": "BR"
            },
            {
                "name": "Lajeado Novo",
                "countryCode": "BR"
            },
            {
                "name": "Lima Campos",
                "countryCode": "BR"
            },
            {
                "name": "Loreto",
                "countryCode": "BR"
            },
            {
                "name": "Luís Domingues",
                "countryCode": "BR"
            },
            {
                "name": "Magalhães de Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Maracaçumé",
                "countryCode": "BR"
            },
            {
                "name": "Marajá do Sena",
                "countryCode": "BR"
            },
            {
                "name": "Maranhãozinho",
                "countryCode": "BR"
            },
            {
                "name": "Mata Roma",
                "countryCode": "BR"
            },
            {
                "name": "Matinha",
                "countryCode": "BR"
            },
            {
                "name": "Matões",
                "countryCode": "BR"
            },
            {
                "name": "Matões do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Milagres do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Mirador",
                "countryCode": "BR"
            },
            {
                "name": "Miranda do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Mirinzal",
                "countryCode": "BR"
            },
            {
                "name": "Montes Altos",
                "countryCode": "BR"
            },
            {
                "name": "Monção",
                "countryCode": "BR"
            },
            {
                "name": "Morros",
                "countryCode": "BR"
            },
            {
                "name": "Nina Rodrigues",
                "countryCode": "BR"
            },
            {
                "name": "Nova Colinas",
                "countryCode": "BR"
            },
            {
                "name": "Nova Iorque",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olinda do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água das Cunhãs",
                "countryCode": "BR"
            },
            {
                "name": "Olinda Nova do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Palmeirândia",
                "countryCode": "BR"
            },
            {
                "name": "Paraibano",
                "countryCode": "BR"
            },
            {
                "name": "Parnarama",
                "countryCode": "BR"
            },
            {
                "name": "Passagem Franca",
                "countryCode": "BR"
            },
            {
                "name": "Pastos Bons",
                "countryCode": "BR"
            },
            {
                "name": "Paulino Neves",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Paço do Lumiar",
                "countryCode": "BR"
            },
            {
                "name": "Pedreiras",
                "countryCode": "BR"
            },
            {
                "name": "Pedro do Rosário",
                "countryCode": "BR"
            },
            {
                "name": "Penalva",
                "countryCode": "BR"
            },
            {
                "name": "Peri Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Peritoró",
                "countryCode": "BR"
            },
            {
                "name": "Pindaré Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Pindaré-Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiro",
                "countryCode": "BR"
            },
            {
                "name": "Pio XII",
                "countryCode": "BR"
            },
            {
                "name": "Pirapemas",
                "countryCode": "BR"
            },
            {
                "name": "Porto Franco",
                "countryCode": "BR"
            },
            {
                "name": "Porto Rico do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Poção de Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Dutra",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Juscelino",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Médici",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Sarney",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Vargas",
                "countryCode": "BR"
            },
            {
                "name": "Primeira Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Raposa",
                "countryCode": "BR"
            },
            {
                "name": "Riachão",
                "countryCode": "BR"
            },
            {
                "name": "Ribamar Fiquene",
                "countryCode": "BR"
            },
            {
                "name": "Rosário",
                "countryCode": "BR"
            },
            {
                "name": "Sambaíba",
                "countryCode": "BR"
            },
            {
                "name": "Santa Filomena do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Inês",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia do Paruá",
                "countryCode": "BR"
            },
            {
                "name": "Santa Quitéria do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Santo Amaro do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio dos Lopes",
                "countryCode": "BR"
            },
            {
                "name": "Satubinha",
                "countryCode": "BR"
            },
            {
                "name": "Senador Alexandre Costa",
                "countryCode": "BR"
            },
            {
                "name": "Senador La Rocque",
                "countryCode": "BR"
            },
            {
                "name": "Serrano do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Sucupira do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Sucupira do Riachão",
                "countryCode": "BR"
            },
            {
                "name": "São Benedito do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "São Bento",
                "countryCode": "BR"
            },
            {
                "name": "São Bernardo",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Azeitão",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Brejão",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "São Félix de Balsas",
                "countryCode": "BR"
            },
            {
                "name": "São José de Ribamar",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Basílios",
                "countryCode": "BR"
            },
            {
                "name": "São João Batista",
                "countryCode": "BR"
            },
            {
                "name": "São João do Carú",
                "countryCode": "BR"
            },
            {
                "name": "São João do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "São João do Soter",
                "countryCode": "BR"
            },
            {
                "name": "São João dos Patos",
                "countryCode": "BR"
            },
            {
                "name": "São Luís",
                "countryCode": "BR"
            },
            {
                "name": "São Luís Gonzaga do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "São Mateus do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro da Água Branca",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro dos Crentes",
                "countryCode": "BR"
            },
            {
                "name": "São Raimundo das Mangabeiras",
                "countryCode": "BR"
            },
            {
                "name": "São Raimundo do Doca Bezerra",
                "countryCode": "BR"
            },
            {
                "name": "São Roberto",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente Ferrer",
                "countryCode": "BR"
            },
            {
                "name": "Sítio Novo",
                "countryCode": "BR"
            },
            {
                "name": "Tasso Fragoso",
                "countryCode": "BR"
            },
            {
                "name": "Timbiras",
                "countryCode": "BR"
            },
            {
                "name": "Timon",
                "countryCode": "BR"
            },
            {
                "name": "Trizidela do Vale",
                "countryCode": "BR"
            },
            {
                "name": "Tufilândia",
                "countryCode": "BR"
            },
            {
                "name": "Tuntum",
                "countryCode": "BR"
            },
            {
                "name": "Turiaçu",
                "countryCode": "BR"
            },
            {
                "name": "Turilândia",
                "countryCode": "BR"
            },
            {
                "name": "Tutóia",
                "countryCode": "BR"
            },
            {
                "name": "Urbano Santos",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Grande",
                "countryCode": "BR"
            },
            {
                "name": "Viana",
                "countryCode": "BR"
            },
            {
                "name": "Vila Nova dos Martírios",
                "countryCode": "BR"
            },
            {
                "name": "Vitorino Freire",
                "countryCode": "BR"
            },
            {
                "name": "Vitória do Mearim",
                "countryCode": "BR"
            },
            {
                "name": "Zé Doca",
                "countryCode": "BR"
            },
            {
                "name": "Água Doce do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "Abadia dos Dourados",
                "countryCode": "BR"
            },
            {
                "name": "Abaeté",
                "countryCode": "BR"
            },
            {
                "name": "Abre Campo",
                "countryCode": "BR"
            },
            {
                "name": "Acaiaca",
                "countryCode": "BR"
            },
            {
                "name": "Aguanil",
                "countryCode": "BR"
            },
            {
                "name": "Aimorés",
                "countryCode": "BR"
            },
            {
                "name": "Aiuruoca",
                "countryCode": "BR"
            },
            {
                "name": "Alagoa",
                "countryCode": "BR"
            },
            {
                "name": "Albertina",
                "countryCode": "BR"
            },
            {
                "name": "Alfenas",
                "countryCode": "BR"
            },
            {
                "name": "Alfredo Vasconcelos",
                "countryCode": "BR"
            },
            {
                "name": "Almenara",
                "countryCode": "BR"
            },
            {
                "name": "Alpercata",
                "countryCode": "BR"
            },
            {
                "name": "Alpinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Alterosa",
                "countryCode": "BR"
            },
            {
                "name": "Alto Caparaó",
                "countryCode": "BR"
            },
            {
                "name": "Alto Jequitibá",
                "countryCode": "BR"
            },
            {
                "name": "Alto Rio Doce",
                "countryCode": "BR"
            },
            {
                "name": "Alvarenga",
                "countryCode": "BR"
            },
            {
                "name": "Alvinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Além Paraíba",
                "countryCode": "BR"
            },
            {
                "name": "Amparo da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Andradas",
                "countryCode": "BR"
            },
            {
                "name": "Andrelândia",
                "countryCode": "BR"
            },
            {
                "name": "Angelândia",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Carlos",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Dias",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Prado de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Aracitaba",
                "countryCode": "BR"
            },
            {
                "name": "Araguari",
                "countryCode": "BR"
            },
            {
                "name": "Arantina",
                "countryCode": "BR"
            },
            {
                "name": "Araponga",
                "countryCode": "BR"
            },
            {
                "name": "Araporã",
                "countryCode": "BR"
            },
            {
                "name": "Arapuá",
                "countryCode": "BR"
            },
            {
                "name": "Araxá",
                "countryCode": "BR"
            },
            {
                "name": "Araçaí",
                "countryCode": "BR"
            },
            {
                "name": "Araçuaí",
                "countryCode": "BR"
            },
            {
                "name": "Araújos",
                "countryCode": "BR"
            },
            {
                "name": "Arceburgo",
                "countryCode": "BR"
            },
            {
                "name": "Arcos",
                "countryCode": "BR"
            },
            {
                "name": "Areado",
                "countryCode": "BR"
            },
            {
                "name": "Argirita",
                "countryCode": "BR"
            },
            {
                "name": "Aricanduva",
                "countryCode": "BR"
            },
            {
                "name": "Arinos",
                "countryCode": "BR"
            },
            {
                "name": "Astolfo Dutra",
                "countryCode": "BR"
            },
            {
                "name": "Ataléia",
                "countryCode": "BR"
            },
            {
                "name": "Augusto de Lima",
                "countryCode": "BR"
            },
            {
                "name": "Açucena",
                "countryCode": "BR"
            },
            {
                "name": "Baependi",
                "countryCode": "BR"
            },
            {
                "name": "Baldim",
                "countryCode": "BR"
            },
            {
                "name": "Bambuí",
                "countryCode": "BR"
            },
            {
                "name": "Bandeira",
                "countryCode": "BR"
            },
            {
                "name": "Bandeira do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Barbacena",
                "countryCode": "BR"
            },
            {
                "name": "Barra Longa",
                "countryCode": "BR"
            },
            {
                "name": "Barreiro do Jaíba",
                "countryCode": "BR"
            },
            {
                "name": "Barroso",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Cocais",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Monte Alto",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Belmiro Braga",
                "countryCode": "BR"
            },
            {
                "name": "Belo Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Belo Oriente",
                "countryCode": "BR"
            },
            {
                "name": "Belo Vale",
                "countryCode": "BR"
            },
            {
                "name": "Berilo",
                "countryCode": "BR"
            },
            {
                "name": "Berizal",
                "countryCode": "BR"
            },
            {
                "name": "Bertópolis",
                "countryCode": "BR"
            },
            {
                "name": "Betim",
                "countryCode": "BR"
            },
            {
                "name": "Bias Fortes",
                "countryCode": "BR"
            },
            {
                "name": "Bicas",
                "countryCode": "BR"
            },
            {
                "name": "Biquinhas",
                "countryCode": "BR"
            },
            {
                "name": "Boa Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Bocaina de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Bocaiúva",
                "countryCode": "BR"
            },
            {
                "name": "Bom Despacho",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus da Penha",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Amparo",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Galho",
                "countryCode": "BR"
            },
            {
                "name": "Bom Repouso",
                "countryCode": "BR"
            },
            {
                "name": "Bom Sucesso",
                "countryCode": "BR"
            },
            {
                "name": "Bonfim",
                "countryCode": "BR"
            },
            {
                "name": "Bonfinópolis de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Bonito de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Borda da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Botelhos",
                "countryCode": "BR"
            },
            {
                "name": "Botumirim",
                "countryCode": "BR"
            },
            {
                "name": "Brasilândia de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Brasília de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Brazópolis",
                "countryCode": "BR"
            },
            {
                "name": "Braúnas",
                "countryCode": "BR"
            },
            {
                "name": "Brumadinho",
                "countryCode": "BR"
            },
            {
                "name": "Brás Pires",
                "countryCode": "BR"
            },
            {
                "name": "Bueno Brandão",
                "countryCode": "BR"
            },
            {
                "name": "Buenópolis",
                "countryCode": "BR"
            },
            {
                "name": "Bugre",
                "countryCode": "BR"
            },
            {
                "name": "Buritis",
                "countryCode": "BR"
            },
            {
                "name": "Buritizeiro",
                "countryCode": "BR"
            },
            {
                "name": "Cabeceira Grande",
                "countryCode": "BR"
            },
            {
                "name": "Cabo Verde",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira da Prata",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira de Pajeú",
                "countryCode": "BR"
            },
            {
                "name": "Caetanópolis",
                "countryCode": "BR"
            },
            {
                "name": "Caeté",
                "countryCode": "BR"
            },
            {
                "name": "Caiana",
                "countryCode": "BR"
            },
            {
                "name": "Cajuri",
                "countryCode": "BR"
            },
            {
                "name": "Caldas",
                "countryCode": "BR"
            },
            {
                "name": "Camacho",
                "countryCode": "BR"
            },
            {
                "name": "Camanducaia",
                "countryCode": "BR"
            },
            {
                "name": "Cambuquira",
                "countryCode": "BR"
            },
            {
                "name": "Cambuí",
                "countryCode": "BR"
            },
            {
                "name": "Campanha",
                "countryCode": "BR"
            },
            {
                "name": "Campanário",
                "countryCode": "BR"
            },
            {
                "name": "Campestre",
                "countryCode": "BR"
            },
            {
                "name": "Campina Verde",
                "countryCode": "BR"
            },
            {
                "name": "Campo Azul",
                "countryCode": "BR"
            },
            {
                "name": "Campo Belo",
                "countryCode": "BR"
            },
            {
                "name": "Campo Florido",
                "countryCode": "BR"
            },
            {
                "name": "Campo do Meio",
                "countryCode": "BR"
            },
            {
                "name": "Campos Altos",
                "countryCode": "BR"
            },
            {
                "name": "Campos Gerais",
                "countryCode": "BR"
            },
            {
                "name": "Cana Verde",
                "countryCode": "BR"
            },
            {
                "name": "Canaã",
                "countryCode": "BR"
            },
            {
                "name": "Candeias",
                "countryCode": "BR"
            },
            {
                "name": "Cantagalo",
                "countryCode": "BR"
            },
            {
                "name": "Canápolis",
                "countryCode": "BR"
            },
            {
                "name": "Caparaó",
                "countryCode": "BR"
            },
            {
                "name": "Capela Nova",
                "countryCode": "BR"
            },
            {
                "name": "Capelinha",
                "countryCode": "BR"
            },
            {
                "name": "Capetinga",
                "countryCode": "BR"
            },
            {
                "name": "Capim Branco",
                "countryCode": "BR"
            },
            {
                "name": "Capinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Capitão Andrade",
                "countryCode": "BR"
            },
            {
                "name": "Capitão Enéas",
                "countryCode": "BR"
            },
            {
                "name": "Capitólio",
                "countryCode": "BR"
            },
            {
                "name": "Caputira",
                "countryCode": "BR"
            },
            {
                "name": "Caranaíba",
                "countryCode": "BR"
            },
            {
                "name": "Carandaí",
                "countryCode": "BR"
            },
            {
                "name": "Carangola",
                "countryCode": "BR"
            },
            {
                "name": "Caratinga",
                "countryCode": "BR"
            },
            {
                "name": "Caraí",
                "countryCode": "BR"
            },
            {
                "name": "Carbonita",
                "countryCode": "BR"
            },
            {
                "name": "Careaçu",
                "countryCode": "BR"
            },
            {
                "name": "Carlos Chagas",
                "countryCode": "BR"
            },
            {
                "name": "Carmo da Cachoeira",
                "countryCode": "BR"
            },
            {
                "name": "Carmo da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Carmo de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Carmo do Cajuru",
                "countryCode": "BR"
            },
            {
                "name": "Carmo do Paranaíba",
                "countryCode": "BR"
            },
            {
                "name": "Carmo do Rio Claro",
                "countryCode": "BR"
            },
            {
                "name": "Carmésia",
                "countryCode": "BR"
            },
            {
                "name": "Carmópolis de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Carneirinho",
                "countryCode": "BR"
            },
            {
                "name": "Carrancas",
                "countryCode": "BR"
            },
            {
                "name": "Carvalhos",
                "countryCode": "BR"
            },
            {
                "name": "Carvalhópolis",
                "countryCode": "BR"
            },
            {
                "name": "Casa Grande",
                "countryCode": "BR"
            },
            {
                "name": "Cascalho Rico",
                "countryCode": "BR"
            },
            {
                "name": "Cataguases",
                "countryCode": "BR"
            },
            {
                "name": "Catas Altas",
                "countryCode": "BR"
            },
            {
                "name": "Catas Altas da Noruega",
                "countryCode": "BR"
            },
            {
                "name": "Catuji",
                "countryCode": "BR"
            },
            {
                "name": "Catuti",
                "countryCode": "BR"
            },
            {
                "name": "Caxambu",
                "countryCode": "BR"
            },
            {
                "name": "Cedro do Abaeté",
                "countryCode": "BR"
            },
            {
                "name": "Central de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Centralina",
                "countryCode": "BR"
            },
            {
                "name": "Chalé",
                "countryCode": "BR"
            },
            {
                "name": "Chapada Gaúcha",
                "countryCode": "BR"
            },
            {
                "name": "Chapada do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Chiador",
                "countryCode": "BR"
            },
            {
                "name": "Chácara",
                "countryCode": "BR"
            },
            {
                "name": "Cipotânea",
                "countryCode": "BR"
            },
            {
                "name": "Claraval",
                "countryCode": "BR"
            },
            {
                "name": "Claro dos Poções",
                "countryCode": "BR"
            },
            {
                "name": "Cláudio",
                "countryCode": "BR"
            },
            {
                "name": "Coimbra",
                "countryCode": "BR"
            },
            {
                "name": "Coluna",
                "countryCode": "BR"
            },
            {
                "name": "Comendador Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Comercinho",
                "countryCode": "BR"
            },
            {
                "name": "Conceição da Aparecida",
                "countryCode": "BR"
            },
            {
                "name": "Conceição da Barra de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Conceição das Alagoas",
                "countryCode": "BR"
            },
            {
                "name": "Conceição das Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Conceição de Ipanema",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Mato Dentro",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Rio Verde",
                "countryCode": "BR"
            },
            {
                "name": "Conceição dos Ouros",
                "countryCode": "BR"
            },
            {
                "name": "Confins",
                "countryCode": "BR"
            },
            {
                "name": "Congonhal",
                "countryCode": "BR"
            },
            {
                "name": "Congonhas",
                "countryCode": "BR"
            },
            {
                "name": "Congonhas do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Conquista",
                "countryCode": "BR"
            },
            {
                "name": "Conselheiro Lafaiete",
                "countryCode": "BR"
            },
            {
                "name": "Conselheiro Pena",
                "countryCode": "BR"
            },
            {
                "name": "Consolação",
                "countryCode": "BR"
            },
            {
                "name": "Contagem",
                "countryCode": "BR"
            },
            {
                "name": "Coqueiral",
                "countryCode": "BR"
            },
            {
                "name": "Coração de Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Cordisburgo",
                "countryCode": "BR"
            },
            {
                "name": "Cordislândia",
                "countryCode": "BR"
            },
            {
                "name": "Corinto",
                "countryCode": "BR"
            },
            {
                "name": "Coroaci",
                "countryCode": "BR"
            },
            {
                "name": "Coromandel",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Fabriciano",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Murta",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Pacheco",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Xavier Chaves",
                "countryCode": "BR"
            },
            {
                "name": "Couto de Magalhães de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Cristais",
                "countryCode": "BR"
            },
            {
                "name": "Cristiano Otoni",
                "countryCode": "BR"
            },
            {
                "name": "Cristina",
                "countryCode": "BR"
            },
            {
                "name": "Cristália",
                "countryCode": "BR"
            },
            {
                "name": "Crisólita",
                "countryCode": "BR"
            },
            {
                "name": "Crucilândia",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro da Fortaleza",
                "countryCode": "BR"
            },
            {
                "name": "Cruzília",
                "countryCode": "BR"
            },
            {
                "name": "Cuparaque",
                "countryCode": "BR"
            },
            {
                "name": "Curral de Dentro",
                "countryCode": "BR"
            },
            {
                "name": "Curvelo",
                "countryCode": "BR"
            },
            {
                "name": "Cássia",
                "countryCode": "BR"
            },
            {
                "name": "Córrego Danta",
                "countryCode": "BR"
            },
            {
                "name": "Córrego Fundo",
                "countryCode": "BR"
            },
            {
                "name": "Córrego Novo",
                "countryCode": "BR"
            },
            {
                "name": "Córrego do Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Cônego Marinho",
                "countryCode": "BR"
            },
            {
                "name": "Datas",
                "countryCode": "BR"
            },
            {
                "name": "Delfim Moreira",
                "countryCode": "BR"
            },
            {
                "name": "Delfinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Delta",
                "countryCode": "BR"
            },
            {
                "name": "Descoberto",
                "countryCode": "BR"
            },
            {
                "name": "Desterro de Entre Rios",
                "countryCode": "BR"
            },
            {
                "name": "Desterro do Melo",
                "countryCode": "BR"
            },
            {
                "name": "Diamantina",
                "countryCode": "BR"
            },
            {
                "name": "Diogo de Vasconcelos",
                "countryCode": "BR"
            },
            {
                "name": "Dionísio",
                "countryCode": "BR"
            },
            {
                "name": "Divino",
                "countryCode": "BR"
            },
            {
                "name": "Divino das Laranjeiras",
                "countryCode": "BR"
            },
            {
                "name": "Divinolândia de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Divinésia",
                "countryCode": "BR"
            },
            {
                "name": "Divinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Divisa Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Divisa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Divisópolis",
                "countryCode": "BR"
            },
            {
                "name": "Dom Bosco",
                "countryCode": "BR"
            },
            {
                "name": "Dom Cavati",
                "countryCode": "BR"
            },
            {
                "name": "Dom Joaquim",
                "countryCode": "BR"
            },
            {
                "name": "Dom Silvério",
                "countryCode": "BR"
            },
            {
                "name": "Dom Viçoso",
                "countryCode": "BR"
            },
            {
                "name": "Dona Eusébia",
                "countryCode": "BR"
            },
            {
                "name": "Dores de Campos",
                "countryCode": "BR"
            },
            {
                "name": "Dores de Guanhães",
                "countryCode": "BR"
            },
            {
                "name": "Dores do Indaiá",
                "countryCode": "BR"
            },
            {
                "name": "Dores do Turvo",
                "countryCode": "BR"
            },
            {
                "name": "Doresópolis",
                "countryCode": "BR"
            },
            {
                "name": "Douradoquara",
                "countryCode": "BR"
            },
            {
                "name": "Durandé",
                "countryCode": "BR"
            },
            {
                "name": "Elói Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Engenheiro Caldas",
                "countryCode": "BR"
            },
            {
                "name": "Engenheiro Navarro",
                "countryCode": "BR"
            },
            {
                "name": "Entre Folhas",
                "countryCode": "BR"
            },
            {
                "name": "Entre Rios de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Ervália",
                "countryCode": "BR"
            },
            {
                "name": "Esmeraldas",
                "countryCode": "BR"
            },
            {
                "name": "Espera Feliz",
                "countryCode": "BR"
            },
            {
                "name": "Espinosa",
                "countryCode": "BR"
            },
            {
                "name": "Espírito Santo do Dourado",
                "countryCode": "BR"
            },
            {
                "name": "Estiva",
                "countryCode": "BR"
            },
            {
                "name": "Estrela Dalva",
                "countryCode": "BR"
            },
            {
                "name": "Estrela do Indaiá",
                "countryCode": "BR"
            },
            {
                "name": "Estrela do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Eugenópolis",
                "countryCode": "BR"
            },
            {
                "name": "Ewbank da Câmara",
                "countryCode": "BR"
            },
            {
                "name": "Extrema",
                "countryCode": "BR"
            },
            {
                "name": "Fama",
                "countryCode": "BR"
            },
            {
                "name": "Faria Lemos",
                "countryCode": "BR"
            },
            {
                "name": "Felisburgo",
                "countryCode": "BR"
            },
            {
                "name": "Felixlândia",
                "countryCode": "BR"
            },
            {
                "name": "Felício dos Santos",
                "countryCode": "BR"
            },
            {
                "name": "Fernandes Tourinho",
                "countryCode": "BR"
            },
            {
                "name": "Ferros",
                "countryCode": "BR"
            },
            {
                "name": "Fervedouro",
                "countryCode": "BR"
            },
            {
                "name": "Florestal",
                "countryCode": "BR"
            },
            {
                "name": "Formiga",
                "countryCode": "BR"
            },
            {
                "name": "Formoso",
                "countryCode": "BR"
            },
            {
                "name": "Fortaleza de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Fortuna de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Badaró",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Dumont",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Sá",
                "countryCode": "BR"
            },
            {
                "name": "Franciscópolis",
                "countryCode": "BR"
            },
            {
                "name": "Frei Gaspar",
                "countryCode": "BR"
            },
            {
                "name": "Frei Inocêncio",
                "countryCode": "BR"
            },
            {
                "name": "Frei Lagonegro",
                "countryCode": "BR"
            },
            {
                "name": "Fronteira",
                "countryCode": "BR"
            },
            {
                "name": "Fronteira dos Vales",
                "countryCode": "BR"
            },
            {
                "name": "Fruta de Leite",
                "countryCode": "BR"
            },
            {
                "name": "Frutal",
                "countryCode": "BR"
            },
            {
                "name": "Funilândia",
                "countryCode": "BR"
            },
            {
                "name": "Galiléia",
                "countryCode": "BR"
            },
            {
                "name": "Gameleiras",
                "countryCode": "BR"
            },
            {
                "name": "Glaucilândia",
                "countryCode": "BR"
            },
            {
                "name": "Goiabeira",
                "countryCode": "BR"
            },
            {
                "name": "Goianá",
                "countryCode": "BR"
            },
            {
                "name": "Gonzaga",
                "countryCode": "BR"
            },
            {
                "name": "Gonçalves",
                "countryCode": "BR"
            },
            {
                "name": "Gouveia",
                "countryCode": "BR"
            },
            {
                "name": "Governador Valadares",
                "countryCode": "BR"
            },
            {
                "name": "Grupiara",
                "countryCode": "BR"
            },
            {
                "name": "Grão Mogol",
                "countryCode": "BR"
            },
            {
                "name": "Guanhães",
                "countryCode": "BR"
            },
            {
                "name": "Guapé",
                "countryCode": "BR"
            },
            {
                "name": "Guaraciaba",
                "countryCode": "BR"
            },
            {
                "name": "Guaraciama",
                "countryCode": "BR"
            },
            {
                "name": "Guarani",
                "countryCode": "BR"
            },
            {
                "name": "Guaranésia",
                "countryCode": "BR"
            },
            {
                "name": "Guarará",
                "countryCode": "BR"
            },
            {
                "name": "Guarda-Mor",
                "countryCode": "BR"
            },
            {
                "name": "Guaxupé",
                "countryCode": "BR"
            },
            {
                "name": "Guidoval",
                "countryCode": "BR"
            },
            {
                "name": "Guimarânia",
                "countryCode": "BR"
            },
            {
                "name": "Guiricema",
                "countryCode": "BR"
            },
            {
                "name": "Gurinhatã",
                "countryCode": "BR"
            },
            {
                "name": "Heliodora",
                "countryCode": "BR"
            },
            {
                "name": "Iapu",
                "countryCode": "BR"
            },
            {
                "name": "Ibertioga",
                "countryCode": "BR"
            },
            {
                "name": "Ibiaí",
                "countryCode": "BR"
            },
            {
                "name": "Ibiracatu",
                "countryCode": "BR"
            },
            {
                "name": "Ibiraci",
                "countryCode": "BR"
            },
            {
                "name": "Ibirité",
                "countryCode": "BR"
            },
            {
                "name": "Ibitiúra de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Ibituruna",
                "countryCode": "BR"
            },
            {
                "name": "Ibiá",
                "countryCode": "BR"
            },
            {
                "name": "Icaraí de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé",
                "countryCode": "BR"
            },
            {
                "name": "Igaratinga",
                "countryCode": "BR"
            },
            {
                "name": "Iguatama",
                "countryCode": "BR"
            },
            {
                "name": "Ijaci",
                "countryCode": "BR"
            },
            {
                "name": "Ilicínea",
                "countryCode": "BR"
            },
            {
                "name": "Imbé de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Inconfidentes",
                "countryCode": "BR"
            },
            {
                "name": "Indaiabira",
                "countryCode": "BR"
            },
            {
                "name": "Indianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Ingaí",
                "countryCode": "BR"
            },
            {
                "name": "Inhapim",
                "countryCode": "BR"
            },
            {
                "name": "Inhaúma",
                "countryCode": "BR"
            },
            {
                "name": "Inimutaba",
                "countryCode": "BR"
            },
            {
                "name": "Ipaba",
                "countryCode": "BR"
            },
            {
                "name": "Ipanema",
                "countryCode": "BR"
            },
            {
                "name": "Ipatinga",
                "countryCode": "BR"
            },
            {
                "name": "Ipiaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ipuiúna",
                "countryCode": "BR"
            },
            {
                "name": "Iraí de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Itabira",
                "countryCode": "BR"
            },
            {
                "name": "Itabirinha",
                "countryCode": "BR"
            },
            {
                "name": "Itabirito",
                "countryCode": "BR"
            },
            {
                "name": "Itacambira",
                "countryCode": "BR"
            },
            {
                "name": "Itacarambi",
                "countryCode": "BR"
            },
            {
                "name": "Itaguara",
                "countryCode": "BR"
            },
            {
                "name": "Itaipé",
                "countryCode": "BR"
            },
            {
                "name": "Itajubá",
                "countryCode": "BR"
            },
            {
                "name": "Itamarandiba",
                "countryCode": "BR"
            },
            {
                "name": "Itamarati de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Itambacuri",
                "countryCode": "BR"
            },
            {
                "name": "Itambé do Mato Dentro",
                "countryCode": "BR"
            },
            {
                "name": "Itamogi",
                "countryCode": "BR"
            },
            {
                "name": "Itamonte",
                "countryCode": "BR"
            },
            {
                "name": "Itanhandu",
                "countryCode": "BR"
            },
            {
                "name": "Itanhomi",
                "countryCode": "BR"
            },
            {
                "name": "Itaobim",
                "countryCode": "BR"
            },
            {
                "name": "Itapagipe",
                "countryCode": "BR"
            },
            {
                "name": "Itapecerica",
                "countryCode": "BR"
            },
            {
                "name": "Itapeva",
                "countryCode": "BR"
            },
            {
                "name": "Itatiaiuçu",
                "countryCode": "BR"
            },
            {
                "name": "Itaverava",
                "countryCode": "BR"
            },
            {
                "name": "Itaú de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Itaúna",
                "countryCode": "BR"
            },
            {
                "name": "Itinga",
                "countryCode": "BR"
            },
            {
                "name": "Itueta",
                "countryCode": "BR"
            },
            {
                "name": "Ituiutaba",
                "countryCode": "BR"
            },
            {
                "name": "Itumirim",
                "countryCode": "BR"
            },
            {
                "name": "Iturama",
                "countryCode": "BR"
            },
            {
                "name": "Itutinga",
                "countryCode": "BR"
            },
            {
                "name": "Jaboticatubas",
                "countryCode": "BR"
            },
            {
                "name": "Jacinto",
                "countryCode": "BR"
            },
            {
                "name": "Jacutinga",
                "countryCode": "BR"
            },
            {
                "name": "Jacuí",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaraçu",
                "countryCode": "BR"
            },
            {
                "name": "Jampruca",
                "countryCode": "BR"
            },
            {
                "name": "Janaúba",
                "countryCode": "BR"
            },
            {
                "name": "Januária",
                "countryCode": "BR"
            },
            {
                "name": "Japaraíba",
                "countryCode": "BR"
            },
            {
                "name": "Japonvar",
                "countryCode": "BR"
            },
            {
                "name": "Jaíba",
                "countryCode": "BR"
            },
            {
                "name": "Jeceaba",
                "countryCode": "BR"
            },
            {
                "name": "Jenipapo de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Jequeri",
                "countryCode": "BR"
            },
            {
                "name": "Jequitaí",
                "countryCode": "BR"
            },
            {
                "name": "Jequitibá",
                "countryCode": "BR"
            },
            {
                "name": "Jequitinhonha",
                "countryCode": "BR"
            },
            {
                "name": "Jesuânia",
                "countryCode": "BR"
            },
            {
                "name": "Joanésia",
                "countryCode": "BR"
            },
            {
                "name": "Joaquim Felício",
                "countryCode": "BR"
            },
            {
                "name": "Joaíma",
                "countryCode": "BR"
            },
            {
                "name": "Jordânia",
                "countryCode": "BR"
            },
            {
                "name": "Josenópolis",
                "countryCode": "BR"
            },
            {
                "name": "José Gonçalves de Minas",
                "countryCode": "BR"
            },
            {
                "name": "José Raydan",
                "countryCode": "BR"
            },
            {
                "name": "João Monlevade",
                "countryCode": "BR"
            },
            {
                "name": "João Pinheiro",
                "countryCode": "BR"
            },
            {
                "name": "Juatuba",
                "countryCode": "BR"
            },
            {
                "name": "Juiz de Fora",
                "countryCode": "BR"
            },
            {
                "name": "Juramento",
                "countryCode": "BR"
            },
            {
                "name": "Juruaia",
                "countryCode": "BR"
            },
            {
                "name": "Juvenília",
                "countryCode": "BR"
            },
            {
                "name": "Ladainha",
                "countryCode": "BR"
            },
            {
                "name": "Lagamar",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Formosa",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Grande",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Santa",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa da Prata",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa dos Patos",
                "countryCode": "BR"
            },
            {
                "name": "Lajinha",
                "countryCode": "BR"
            },
            {
                "name": "Lambari",
                "countryCode": "BR"
            },
            {
                "name": "Lamim",
                "countryCode": "BR"
            },
            {
                "name": "Laranjal",
                "countryCode": "BR"
            },
            {
                "name": "Lassance",
                "countryCode": "BR"
            },
            {
                "name": "Lavras",
                "countryCode": "BR"
            },
            {
                "name": "Leandro Ferreira",
                "countryCode": "BR"
            },
            {
                "name": "Leme do Prado",
                "countryCode": "BR"
            },
            {
                "name": "Leopoldina",
                "countryCode": "BR"
            },
            {
                "name": "Liberdade",
                "countryCode": "BR"
            },
            {
                "name": "Lima Duarte",
                "countryCode": "BR"
            },
            {
                "name": "Limeira do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Lontra",
                "countryCode": "BR"
            },
            {
                "name": "Luisburgo",
                "countryCode": "BR"
            },
            {
                "name": "Luislândia",
                "countryCode": "BR"
            },
            {
                "name": "Luminárias",
                "countryCode": "BR"
            },
            {
                "name": "Luz",
                "countryCode": "BR"
            },
            {
                "name": "Machacalis",
                "countryCode": "BR"
            },
            {
                "name": "Machado",
                "countryCode": "BR"
            },
            {
                "name": "Madre de Deus de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Malacacheta",
                "countryCode": "BR"
            },
            {
                "name": "Mamonas",
                "countryCode": "BR"
            },
            {
                "name": "Manga",
                "countryCode": "BR"
            },
            {
                "name": "Manhuaçu",
                "countryCode": "BR"
            },
            {
                "name": "Manhumirim",
                "countryCode": "BR"
            },
            {
                "name": "Mantena",
                "countryCode": "BR"
            },
            {
                "name": "Mar de Espanha",
                "countryCode": "BR"
            },
            {
                "name": "Maravilhas",
                "countryCode": "BR"
            },
            {
                "name": "Maria da Fé",
                "countryCode": "BR"
            },
            {
                "name": "Mariana",
                "countryCode": "BR"
            },
            {
                "name": "Marilac",
                "countryCode": "BR"
            },
            {
                "name": "Maripá de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Marliéria",
                "countryCode": "BR"
            },
            {
                "name": "Marmelópolis",
                "countryCode": "BR"
            },
            {
                "name": "Martinho Campos",
                "countryCode": "BR"
            },
            {
                "name": "Martins Soares",
                "countryCode": "BR"
            },
            {
                "name": "Mata Verde",
                "countryCode": "BR"
            },
            {
                "name": "Materlândia",
                "countryCode": "BR"
            },
            {
                "name": "Mateus Leme",
                "countryCode": "BR"
            },
            {
                "name": "Mathias Lobato",
                "countryCode": "BR"
            },
            {
                "name": "Matias Barbosa",
                "countryCode": "BR"
            },
            {
                "name": "Matias Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Matipó",
                "countryCode": "BR"
            },
            {
                "name": "Mato Verde",
                "countryCode": "BR"
            },
            {
                "name": "Matozinhos",
                "countryCode": "BR"
            },
            {
                "name": "Matutina",
                "countryCode": "BR"
            },
            {
                "name": "Medeiros",
                "countryCode": "BR"
            },
            {
                "name": "Medina",
                "countryCode": "BR"
            },
            {
                "name": "Mendes Pimentel",
                "countryCode": "BR"
            },
            {
                "name": "Mercês",
                "countryCode": "BR"
            },
            {
                "name": "Mesquita",
                "countryCode": "BR"
            },
            {
                "name": "Minas Novas",
                "countryCode": "BR"
            },
            {
                "name": "Minduri",
                "countryCode": "BR"
            },
            {
                "name": "Mirabela",
                "countryCode": "BR"
            },
            {
                "name": "Miradouro",
                "countryCode": "BR"
            },
            {
                "name": "Miravânia",
                "countryCode": "BR"
            },
            {
                "name": "Miraí",
                "countryCode": "BR"
            },
            {
                "name": "Moeda",
                "countryCode": "BR"
            },
            {
                "name": "Moema",
                "countryCode": "BR"
            },
            {
                "name": "Monjolos",
                "countryCode": "BR"
            },
            {
                "name": "Monsenhor Paulo",
                "countryCode": "BR"
            },
            {
                "name": "Montalvânia",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Monte Azul",
                "countryCode": "BR"
            },
            {
                "name": "Monte Belo",
                "countryCode": "BR"
            },
            {
                "name": "Monte Carmelo",
                "countryCode": "BR"
            },
            {
                "name": "Monte Formoso",
                "countryCode": "BR"
            },
            {
                "name": "Monte Santo de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Monte Sião",
                "countryCode": "BR"
            },
            {
                "name": "Monte Verde",
                "countryCode": "BR"
            },
            {
                "name": "Montes Claros",
                "countryCode": "BR"
            },
            {
                "name": "Montezuma",
                "countryCode": "BR"
            },
            {
                "name": "Morada Nova de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Morro da Garça",
                "countryCode": "BR"
            },
            {
                "name": "Morro do Pilar",
                "countryCode": "BR"
            },
            {
                "name": "Munhoz",
                "countryCode": "BR"
            },
            {
                "name": "Muriaé",
                "countryCode": "BR"
            },
            {
                "name": "Mutum",
                "countryCode": "BR"
            },
            {
                "name": "Muzambinho",
                "countryCode": "BR"
            },
            {
                "name": "Mário Campos",
                "countryCode": "BR"
            },
            {
                "name": "Nacip Raydan",
                "countryCode": "BR"
            },
            {
                "name": "Nanuque",
                "countryCode": "BR"
            },
            {
                "name": "Naque",
                "countryCode": "BR"
            },
            {
                "name": "Natalândia",
                "countryCode": "BR"
            },
            {
                "name": "Natércia",
                "countryCode": "BR"
            },
            {
                "name": "Nazareno",
                "countryCode": "BR"
            },
            {
                "name": "Nepomuceno",
                "countryCode": "BR"
            },
            {
                "name": "Ninheira",
                "countryCode": "BR"
            },
            {
                "name": "Nova Belém",
                "countryCode": "BR"
            },
            {
                "name": "Nova Era",
                "countryCode": "BR"
            },
            {
                "name": "Nova Lima",
                "countryCode": "BR"
            },
            {
                "name": "Nova Módica",
                "countryCode": "BR"
            },
            {
                "name": "Nova Ponte",
                "countryCode": "BR"
            },
            {
                "name": "Nova Porteirinha",
                "countryCode": "BR"
            },
            {
                "name": "Nova Resende",
                "countryCode": "BR"
            },
            {
                "name": "Nova Serrana",
                "countryCode": "BR"
            },
            {
                "name": "Nova União",
                "countryCode": "BR"
            },
            {
                "name": "Novo Cruzeiro",
                "countryCode": "BR"
            },
            {
                "name": "Novo Oriente de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Novorizonte",
                "countryCode": "BR"
            },
            {
                "name": "Olaria",
                "countryCode": "BR"
            },
            {
                "name": "Olhos-d'Água",
                "countryCode": "BR"
            },
            {
                "name": "Oliveira",
                "countryCode": "BR"
            },
            {
                "name": "Oliveira Fortes",
                "countryCode": "BR"
            },
            {
                "name": "Olímpio Noronha",
                "countryCode": "BR"
            },
            {
                "name": "Onça de Pitangui",
                "countryCode": "BR"
            },
            {
                "name": "Oratórios",
                "countryCode": "BR"
            },
            {
                "name": "Orizânia",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Branco",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Fino",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Preto",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Verde de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Padre Carvalho",
                "countryCode": "BR"
            },
            {
                "name": "Padre Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Pai Pedro",
                "countryCode": "BR"
            },
            {
                "name": "Paineiras",
                "countryCode": "BR"
            },
            {
                "name": "Pains",
                "countryCode": "BR"
            },
            {
                "name": "Paiva",
                "countryCode": "BR"
            },
            {
                "name": "Palma",
                "countryCode": "BR"
            },
            {
                "name": "Palmópolis",
                "countryCode": "BR"
            },
            {
                "name": "Papagaios",
                "countryCode": "BR"
            },
            {
                "name": "Paracatu",
                "countryCode": "BR"
            },
            {
                "name": "Paraguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Paraisópolis",
                "countryCode": "BR"
            },
            {
                "name": "Paraopeba",
                "countryCode": "BR"
            },
            {
                "name": "Pará de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Passa Quatro",
                "countryCode": "BR"
            },
            {
                "name": "Passa Tempo",
                "countryCode": "BR"
            },
            {
                "name": "Passa-Vinte",
                "countryCode": "BR"
            },
            {
                "name": "Passabém",
                "countryCode": "BR"
            },
            {
                "name": "Passos",
                "countryCode": "BR"
            },
            {
                "name": "Patis",
                "countryCode": "BR"
            },
            {
                "name": "Patos de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Patrocínio",
                "countryCode": "BR"
            },
            {
                "name": "Patrocínio do Muriaé",
                "countryCode": "BR"
            },
            {
                "name": "Paula Cândido",
                "countryCode": "BR"
            },
            {
                "name": "Paulistas",
                "countryCode": "BR"
            },
            {
                "name": "Pavão",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Azul",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Pedra do Anta",
                "countryCode": "BR"
            },
            {
                "name": "Pedra do Indaiá",
                "countryCode": "BR"
            },
            {
                "name": "Pedralva",
                "countryCode": "BR"
            },
            {
                "name": "Pedras de Maria da Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Pedrinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Leopoldo",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Teixeira",
                "countryCode": "BR"
            },
            {
                "name": "Pequeri",
                "countryCode": "BR"
            },
            {
                "name": "Pequi",
                "countryCode": "BR"
            },
            {
                "name": "Perdigão",
                "countryCode": "BR"
            },
            {
                "name": "Perdizes",
                "countryCode": "BR"
            },
            {
                "name": "Perdões",
                "countryCode": "BR"
            },
            {
                "name": "Periquito",
                "countryCode": "BR"
            },
            {
                "name": "Pescador",
                "countryCode": "BR"
            },
            {
                "name": "Peçanha",
                "countryCode": "BR"
            },
            {
                "name": "Piau",
                "countryCode": "BR"
            },
            {
                "name": "Piedade de Caratinga",
                "countryCode": "BR"
            },
            {
                "name": "Piedade de Ponte Nova",
                "countryCode": "BR"
            },
            {
                "name": "Piedade do Rio Grande",
                "countryCode": "BR"
            },
            {
                "name": "Piedade dos Gerais",
                "countryCode": "BR"
            },
            {
                "name": "Pimenta",
                "countryCode": "BR"
            },
            {
                "name": "Pingo-d'Água",
                "countryCode": "BR"
            },
            {
                "name": "Pintópolis",
                "countryCode": "BR"
            },
            {
                "name": "Piracema",
                "countryCode": "BR"
            },
            {
                "name": "Pirajuba",
                "countryCode": "BR"
            },
            {
                "name": "Piranga",
                "countryCode": "BR"
            },
            {
                "name": "Piranguinho",
                "countryCode": "BR"
            },
            {
                "name": "Piranguçu",
                "countryCode": "BR"
            },
            {
                "name": "Pirapetinga",
                "countryCode": "BR"
            },
            {
                "name": "Pirapora",
                "countryCode": "BR"
            },
            {
                "name": "Piraúba",
                "countryCode": "BR"
            },
            {
                "name": "Pitangui",
                "countryCode": "BR"
            },
            {
                "name": "Piumhi",
                "countryCode": "BR"
            },
            {
                "name": "Piuí",
                "countryCode": "BR"
            },
            {
                "name": "Planura",
                "countryCode": "BR"
            },
            {
                "name": "Pocrane",
                "countryCode": "BR"
            },
            {
                "name": "Pompéu",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Nova",
                "countryCode": "BR"
            },
            {
                "name": "Ponto Chique",
                "countryCode": "BR"
            },
            {
                "name": "Ponto dos Volantes",
                "countryCode": "BR"
            },
            {
                "name": "Porteirinha",
                "countryCode": "BR"
            },
            {
                "name": "Porto Firme",
                "countryCode": "BR"
            },
            {
                "name": "Poté",
                "countryCode": "BR"
            },
            {
                "name": "Pouso Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Pouso Alto",
                "countryCode": "BR"
            },
            {
                "name": "Poço Fundo",
                "countryCode": "BR"
            },
            {
                "name": "Poços de Caldas",
                "countryCode": "BR"
            },
            {
                "name": "Prados",
                "countryCode": "BR"
            },
            {
                "name": "Prata",
                "countryCode": "BR"
            },
            {
                "name": "Pratinha",
                "countryCode": "BR"
            },
            {
                "name": "Pratápolis",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Bernardes",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Juscelino",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Kubitschek",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Olegário",
                "countryCode": "BR"
            },
            {
                "name": "Prudente de Morais",
                "countryCode": "BR"
            },
            {
                "name": "Quartel Geral",
                "countryCode": "BR"
            },
            {
                "name": "Queluzito",
                "countryCode": "BR"
            },
            {
                "name": "Raposos",
                "countryCode": "BR"
            },
            {
                "name": "Raul Soares",
                "countryCode": "BR"
            },
            {
                "name": "Recreio",
                "countryCode": "BR"
            },
            {
                "name": "Reduto",
                "countryCode": "BR"
            },
            {
                "name": "Resende Costa",
                "countryCode": "BR"
            },
            {
                "name": "Resplendor",
                "countryCode": "BR"
            },
            {
                "name": "Ressaquinha",
                "countryCode": "BR"
            },
            {
                "name": "Riachinho",
                "countryCode": "BR"
            },
            {
                "name": "Riacho dos Machados",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Vermelho",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão das Neves",
                "countryCode": "BR"
            },
            {
                "name": "Rio Acima",
                "countryCode": "BR"
            },
            {
                "name": "Rio Casca",
                "countryCode": "BR"
            },
            {
                "name": "Rio Doce",
                "countryCode": "BR"
            },
            {
                "name": "Rio Espera",
                "countryCode": "BR"
            },
            {
                "name": "Rio Manso",
                "countryCode": "BR"
            },
            {
                "name": "Rio Novo",
                "countryCode": "BR"
            },
            {
                "name": "Rio Paranaíba",
                "countryCode": "BR"
            },
            {
                "name": "Rio Pardo de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Rio Piracicaba",
                "countryCode": "BR"
            },
            {
                "name": "Rio Pomba",
                "countryCode": "BR"
            },
            {
                "name": "Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "Rio Vermelho",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Prado",
                "countryCode": "BR"
            },
            {
                "name": "Ritápolis",
                "countryCode": "BR"
            },
            {
                "name": "Rochedo de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Rodeiro",
                "countryCode": "BR"
            },
            {
                "name": "Romaria",
                "countryCode": "BR"
            },
            {
                "name": "Rosário da Limeira",
                "countryCode": "BR"
            },
            {
                "name": "Rubelita",
                "countryCode": "BR"
            },
            {
                "name": "Rubim",
                "countryCode": "BR"
            },
            {
                "name": "Sabará",
                "countryCode": "BR"
            },
            {
                "name": "Sabinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Sacramento",
                "countryCode": "BR"
            },
            {
                "name": "Salinas",
                "countryCode": "BR"
            },
            {
                "name": "Salto da Divisa",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara do Leste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara do Monte Verde",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara do Tugúrio",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz de Salinas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Escalvado",
                "countryCode": "BR"
            },
            {
                "name": "Santa Efigênia de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Fé de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Juliana",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Margarida",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria de Itabira",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Salto",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Suaçuí",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita de Caldas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita de Ibitipoca",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita de Jacutinga",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Itueto",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Sapucaí",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Santa Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Santana da Vargem",
                "countryCode": "BR"
            },
            {
                "name": "Santana de Cataguases",
                "countryCode": "BR"
            },
            {
                "name": "Santana de Pirapama",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Deserto",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Garambéu",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Jacaré",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Manhuaçu",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Riacho",
                "countryCode": "BR"
            },
            {
                "name": "Santana dos Montes",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Amparo",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Aventureiro",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Grama",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Itambé",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Jacinto",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Monte",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Retiro",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Rio Abaixo",
                "countryCode": "BR"
            },
            {
                "name": "Santo Hipólito",
                "countryCode": "BR"
            },
            {
                "name": "Santos Dumont",
                "countryCode": "BR"
            },
            {
                "name": "Sapucaí-Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Sardoá",
                "countryCode": "BR"
            },
            {
                "name": "Sarzedo",
                "countryCode": "BR"
            },
            {
                "name": "Sem-Peixe",
                "countryCode": "BR"
            },
            {
                "name": "Senador Amaral",
                "countryCode": "BR"
            },
            {
                "name": "Senador Cortes",
                "countryCode": "BR"
            },
            {
                "name": "Senador Firmino",
                "countryCode": "BR"
            },
            {
                "name": "Senador José Bento",
                "countryCode": "BR"
            },
            {
                "name": "Senador Modestino Gonçalves",
                "countryCode": "BR"
            },
            {
                "name": "Senhora de Oliveira",
                "countryCode": "BR"
            },
            {
                "name": "Senhora do Porto",
                "countryCode": "BR"
            },
            {
                "name": "Senhora dos Remédios",
                "countryCode": "BR"
            },
            {
                "name": "Sericita",
                "countryCode": "BR"
            },
            {
                "name": "Seritinga",
                "countryCode": "BR"
            },
            {
                "name": "Serra Azul de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Serra da Saudade",
                "countryCode": "BR"
            },
            {
                "name": "Serra do Salitre",
                "countryCode": "BR"
            },
            {
                "name": "Serra dos Aimorés",
                "countryCode": "BR"
            },
            {
                "name": "Serrania",
                "countryCode": "BR"
            },
            {
                "name": "Serranos",
                "countryCode": "BR"
            },
            {
                "name": "Serranópolis de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Serro",
                "countryCode": "BR"
            },
            {
                "name": "Sete Lagoas",
                "countryCode": "BR"
            },
            {
                "name": "Setubinha",
                "countryCode": "BR"
            },
            {
                "name": "Silveirânia",
                "countryCode": "BR"
            },
            {
                "name": "Silvianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Simonésia",
                "countryCode": "BR"
            },
            {
                "name": "Simão Pereira",
                "countryCode": "BR"
            },
            {
                "name": "Sobrália",
                "countryCode": "BR"
            },
            {
                "name": "Soledade de Minas",
                "countryCode": "BR"
            },
            {
                "name": "São Bento Abade",
                "countryCode": "BR"
            },
            {
                "name": "São Brás do Suaçuí",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos das Dores",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Prata",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Paula",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Sales",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Glória",
                "countryCode": "BR"
            },
            {
                "name": "São Félix de Minas",
                "countryCode": "BR"
            },
            {
                "name": "São Geraldo",
                "countryCode": "BR"
            },
            {
                "name": "São Geraldo da Piedade",
                "countryCode": "BR"
            },
            {
                "name": "São Geraldo do Baixio",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Abaeté",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Pará",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Rio Abaixo",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Sapucaí",
                "countryCode": "BR"
            },
            {
                "name": "São Gotardo",
                "countryCode": "BR"
            },
            {
                "name": "São Joaquim de Bicas",
                "countryCode": "BR"
            },
            {
                "name": "São José da Barra",
                "countryCode": "BR"
            },
            {
                "name": "São José da Lapa",
                "countryCode": "BR"
            },
            {
                "name": "São José da Safira",
                "countryCode": "BR"
            },
            {
                "name": "São José da Varginha",
                "countryCode": "BR"
            },
            {
                "name": "São José do Alegre",
                "countryCode": "BR"
            },
            {
                "name": "São José do Divino",
                "countryCode": "BR"
            },
            {
                "name": "São José do Goiabal",
                "countryCode": "BR"
            },
            {
                "name": "São José do Jacuri",
                "countryCode": "BR"
            },
            {
                "name": "São José do Mantimento",
                "countryCode": "BR"
            },
            {
                "name": "São João Batista do Glória",
                "countryCode": "BR"
            },
            {
                "name": "São João Evangelista",
                "countryCode": "BR"
            },
            {
                "name": "São João Nepomuceno",
                "countryCode": "BR"
            },
            {
                "name": "São João da Lagoa",
                "countryCode": "BR"
            },
            {
                "name": "São João da Mata",
                "countryCode": "BR"
            },
            {
                "name": "São João da Ponte",
                "countryCode": "BR"
            },
            {
                "name": "São João das Missões",
                "countryCode": "BR"
            },
            {
                "name": "São João del Rei",
                "countryCode": "BR"
            },
            {
                "name": "São João do Manhuaçu",
                "countryCode": "BR"
            },
            {
                "name": "São João do Manteninha",
                "countryCode": "BR"
            },
            {
                "name": "São João do Oriente",
                "countryCode": "BR"
            },
            {
                "name": "São João do Pacuí",
                "countryCode": "BR"
            },
            {
                "name": "São João do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Anta",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro da União",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Suaçuí",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro dos Ferros",
                "countryCode": "BR"
            },
            {
                "name": "São Romão",
                "countryCode": "BR"
            },
            {
                "name": "São Roque de Minas",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião da Bela Vista",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião da Vargem Alegre",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Anta",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Maranhão",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Rio Verde",
                "countryCode": "BR"
            },
            {
                "name": "São Thomé das Letras",
                "countryCode": "BR"
            },
            {
                "name": "São Tiago",
                "countryCode": "BR"
            },
            {
                "name": "São Tomás de Aquino",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Tabuleiro",
                "countryCode": "BR"
            },
            {
                "name": "Taiobeiras",
                "countryCode": "BR"
            },
            {
                "name": "Taparuba",
                "countryCode": "BR"
            },
            {
                "name": "Tapira",
                "countryCode": "BR"
            },
            {
                "name": "Tapiraí",
                "countryCode": "BR"
            },
            {
                "name": "Taquaraçu de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Tarumirim",
                "countryCode": "BR"
            },
            {
                "name": "Teixeiras",
                "countryCode": "BR"
            },
            {
                "name": "Teófilo Otoni",
                "countryCode": "BR"
            },
            {
                "name": "Timóteo",
                "countryCode": "BR"
            },
            {
                "name": "Tiradentes",
                "countryCode": "BR"
            },
            {
                "name": "Tiros",
                "countryCode": "BR"
            },
            {
                "name": "Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Tocos do Moji",
                "countryCode": "BR"
            },
            {
                "name": "Toledo",
                "countryCode": "BR"
            },
            {
                "name": "Tombos",
                "countryCode": "BR"
            },
            {
                "name": "Três Corações",
                "countryCode": "BR"
            },
            {
                "name": "Três Marias",
                "countryCode": "BR"
            },
            {
                "name": "Três Pontas",
                "countryCode": "BR"
            },
            {
                "name": "Tumiritinga",
                "countryCode": "BR"
            },
            {
                "name": "Tupaciguara",
                "countryCode": "BR"
            },
            {
                "name": "Turmalina",
                "countryCode": "BR"
            },
            {
                "name": "Turvolândia",
                "countryCode": "BR"
            },
            {
                "name": "Ubaporanga",
                "countryCode": "BR"
            },
            {
                "name": "Ubaí",
                "countryCode": "BR"
            },
            {
                "name": "Uberaba",
                "countryCode": "BR"
            },
            {
                "name": "Uberlândia",
                "countryCode": "BR"
            },
            {
                "name": "Ubá",
                "countryCode": "BR"
            },
            {
                "name": "Umburatiba",
                "countryCode": "BR"
            },
            {
                "name": "Unaí",
                "countryCode": "BR"
            },
            {
                "name": "União de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Uruana de Minas",
                "countryCode": "BR"
            },
            {
                "name": "Urucuia",
                "countryCode": "BR"
            },
            {
                "name": "Urucânia",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Grande do Rio Pardo",
                "countryCode": "BR"
            },
            {
                "name": "Varginha",
                "countryCode": "BR"
            },
            {
                "name": "Varjão de MInas",
                "countryCode": "BR"
            },
            {
                "name": "Varzelândia",
                "countryCode": "BR"
            },
            {
                "name": "Vazante",
                "countryCode": "BR"
            },
            {
                "name": "Verdelândia",
                "countryCode": "BR"
            },
            {
                "name": "Veredinha",
                "countryCode": "BR"
            },
            {
                "name": "Vermelho Novo",
                "countryCode": "BR"
            },
            {
                "name": "Veríssimo",
                "countryCode": "BR"
            },
            {
                "name": "Vespasiano",
                "countryCode": "BR"
            },
            {
                "name": "Vieiras",
                "countryCode": "BR"
            },
            {
                "name": "Virgem da Lapa",
                "countryCode": "BR"
            },
            {
                "name": "Virginópolis",
                "countryCode": "BR"
            },
            {
                "name": "Virgolândia",
                "countryCode": "BR"
            },
            {
                "name": "Virgínia",
                "countryCode": "BR"
            },
            {
                "name": "Visconde do Rio Branco",
                "countryCode": "BR"
            },
            {
                "name": "Viçosa",
                "countryCode": "BR"
            },
            {
                "name": "Volta Grande",
                "countryCode": "BR"
            },
            {
                "name": "Várzea da Palma",
                "countryCode": "BR"
            },
            {
                "name": "Wenceslau Braz",
                "countryCode": "BR"
            },
            {
                "name": "Água Boa",
                "countryCode": "BR"
            },
            {
                "name": "Água Comprida",
                "countryCode": "BR"
            },
            {
                "name": "Águas Formosas",
                "countryCode": "BR"
            },
            {
                "name": "Águas Vermelhas",
                "countryCode": "BR"
            },
            {
                "name": "Alcinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Amambai",
                "countryCode": "BR"
            },
            {
                "name": "Anastácio",
                "countryCode": "BR"
            },
            {
                "name": "Anaurilândia",
                "countryCode": "BR"
            },
            {
                "name": "Angélica",
                "countryCode": "BR"
            },
            {
                "name": "Antônio João",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida do Taboado",
                "countryCode": "BR"
            },
            {
                "name": "Aquidauana",
                "countryCode": "BR"
            },
            {
                "name": "Aral Moreira",
                "countryCode": "BR"
            },
            {
                "name": "Bandeirantes",
                "countryCode": "BR"
            },
            {
                "name": "Bataguassu",
                "countryCode": "BR"
            },
            {
                "name": "Bataiporã",
                "countryCode": "BR"
            },
            {
                "name": "Batayporã",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista",
                "countryCode": "BR"
            },
            {
                "name": "Bodoquena",
                "countryCode": "BR"
            },
            {
                "name": "Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Brasilândia",
                "countryCode": "BR"
            },
            {
                "name": "Caarapó",
                "countryCode": "BR"
            },
            {
                "name": "Camapuã",
                "countryCode": "BR"
            },
            {
                "name": "Campo Grande",
                "countryCode": "BR"
            },
            {
                "name": "Campo Verde",
                "countryCode": "BR"
            },
            {
                "name": "Caracol",
                "countryCode": "BR"
            },
            {
                "name": "Cassilândia",
                "countryCode": "BR"
            },
            {
                "name": "Chapadão do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Corguinho",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Sapucaia",
                "countryCode": "BR"
            },
            {
                "name": "Corumbá",
                "countryCode": "BR"
            },
            {
                "name": "Costa Rica",
                "countryCode": "BR"
            },
            {
                "name": "Coxim",
                "countryCode": "BR"
            },
            {
                "name": "Deodápolis",
                "countryCode": "BR"
            },
            {
                "name": "Dois Irmãos do Buriti",
                "countryCode": "BR"
            },
            {
                "name": "Douradina",
                "countryCode": "BR"
            },
            {
                "name": "Dourados",
                "countryCode": "BR"
            },
            {
                "name": "Eldorado",
                "countryCode": "BR"
            },
            {
                "name": "Figueirão",
                "countryCode": "BR"
            },
            {
                "name": "Fátima do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Glória de Dourados",
                "countryCode": "BR"
            },
            {
                "name": "Guia Lopes da Laguna",
                "countryCode": "BR"
            },
            {
                "name": "Iguatemi",
                "countryCode": "BR"
            },
            {
                "name": "Inocência",
                "countryCode": "BR"
            },
            {
                "name": "Itaporã",
                "countryCode": "BR"
            },
            {
                "name": "Itaquiraí",
                "countryCode": "BR"
            },
            {
                "name": "Ivinhema",
                "countryCode": "BR"
            },
            {
                "name": "Japorã",
                "countryCode": "BR"
            },
            {
                "name": "Jaraguari",
                "countryCode": "BR"
            },
            {
                "name": "Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Jateí",
                "countryCode": "BR"
            },
            {
                "name": "Juti",
                "countryCode": "BR"
            },
            {
                "name": "Ladário",
                "countryCode": "BR"
            },
            {
                "name": "Laguna Carapã",
                "countryCode": "BR"
            },
            {
                "name": "Maracaju",
                "countryCode": "BR"
            },
            {
                "name": "Miranda",
                "countryCode": "BR"
            },
            {
                "name": "Mundo Novo",
                "countryCode": "BR"
            },
            {
                "name": "Naviraí",
                "countryCode": "BR"
            },
            {
                "name": "Nioaque",
                "countryCode": "BR"
            },
            {
                "name": "Nova Alvorada do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Nova Andradina",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Paranaíba",
                "countryCode": "BR"
            },
            {
                "name": "Paranhos",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso das Águas",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Ponta Porã",
                "countryCode": "BR"
            },
            {
                "name": "Porto Murtinho",
                "countryCode": "BR"
            },
            {
                "name": "Pôrto Barra do Ivinheima",
                "countryCode": "BR"
            },
            {
                "name": "Ribas do Rio Pardo",
                "countryCode": "BR"
            },
            {
                "name": "Rio Brilhante",
                "countryCode": "BR"
            },
            {
                "name": "Rio Negro",
                "countryCode": "BR"
            },
            {
                "name": "Rio Verde de Mato Grosso",
                "countryCode": "BR"
            },
            {
                "name": "Rochedo",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Pardo",
                "countryCode": "BR"
            },
            {
                "name": "Selvíria",
                "countryCode": "BR"
            },
            {
                "name": "Sete Quedas",
                "countryCode": "BR"
            },
            {
                "name": "Sidrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Sonora",
                "countryCode": "BR"
            },
            {
                "name": "São Gabriel do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Tacuru",
                "countryCode": "BR"
            },
            {
                "name": "Taquarussu",
                "countryCode": "BR"
            },
            {
                "name": "Terenos",
                "countryCode": "BR"
            },
            {
                "name": "Três Lagoas",
                "countryCode": "BR"
            },
            {
                "name": "Vicentina",
                "countryCode": "BR"
            },
            {
                "name": "Água Clara",
                "countryCode": "BR"
            },
            {
                "name": "Acorizal",
                "countryCode": "BR"
            },
            {
                "name": "Alta Floresta",
                "countryCode": "BR"
            },
            {
                "name": "Alto Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Alto Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Alto Garças",
                "countryCode": "BR"
            },
            {
                "name": "Alto Paraguai",
                "countryCode": "BR"
            },
            {
                "name": "Alto Taquari",
                "countryCode": "BR"
            },
            {
                "name": "Apiacás",
                "countryCode": "BR"
            },
            {
                "name": "Araguaiana",
                "countryCode": "BR"
            },
            {
                "name": "Araguainha",
                "countryCode": "BR"
            },
            {
                "name": "Araputanga",
                "countryCode": "BR"
            },
            {
                "name": "Arenápolis",
                "countryCode": "BR"
            },
            {
                "name": "Aripuanã",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Bugres",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Garças",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Melgaço",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Brasnorte",
                "countryCode": "BR"
            },
            {
                "name": "Campinápolis",
                "countryCode": "BR"
            },
            {
                "name": "Campo Novo do Parecis",
                "countryCode": "BR"
            },
            {
                "name": "Campo Verde",
                "countryCode": "BR"
            },
            {
                "name": "Campos de Júlio",
                "countryCode": "BR"
            },
            {
                "name": "CanaBrava do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Canarana",
                "countryCode": "BR"
            },
            {
                "name": "Carlinda",
                "countryCode": "BR"
            },
            {
                "name": "Castanheira",
                "countryCode": "BR"
            },
            {
                "name": "Chapada dos Guimarães",
                "countryCode": "BR"
            },
            {
                "name": "Cláudia",
                "countryCode": "BR"
            },
            {
                "name": "Cocalinho",
                "countryCode": "BR"
            },
            {
                "name": "Colniza",
                "countryCode": "BR"
            },
            {
                "name": "Colíder",
                "countryCode": "BR"
            },
            {
                "name": "Comodoro",
                "countryCode": "BR"
            },
            {
                "name": "Confresa",
                "countryCode": "BR"
            },
            {
                "name": "Conquista D'oeste",
                "countryCode": "BR"
            },
            {
                "name": "Cotriguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Cuiabá",
                "countryCode": "BR"
            },
            {
                "name": "Curvelândia",
                "countryCode": "BR"
            },
            {
                "name": "Cáceres",
                "countryCode": "BR"
            },
            {
                "name": "Denise",
                "countryCode": "BR"
            },
            {
                "name": "Diamantino",
                "countryCode": "BR"
            },
            {
                "name": "Dom Aquino",
                "countryCode": "BR"
            },
            {
                "name": "Feliz Natal",
                "countryCode": "BR"
            },
            {
                "name": "Figueirópolis d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Gaúcha do Norte",
                "countryCode": "BR"
            },
            {
                "name": "General Carneiro",
                "countryCode": "BR"
            },
            {
                "name": "Glória d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Guarantã do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Guiratinga",
                "countryCode": "BR"
            },
            {
                "name": "Indiavaí",
                "countryCode": "BR"
            },
            {
                "name": "Ipiranga do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Itanhangá",
                "countryCode": "BR"
            },
            {
                "name": "Itaúba",
                "countryCode": "BR"
            },
            {
                "name": "Itiquira",
                "countryCode": "BR"
            },
            {
                "name": "Jaciara",
                "countryCode": "BR"
            },
            {
                "name": "Jangada",
                "countryCode": "BR"
            },
            {
                "name": "Jauru",
                "countryCode": "BR"
            },
            {
                "name": "Juara",
                "countryCode": "BR"
            },
            {
                "name": "Juruena",
                "countryCode": "BR"
            },
            {
                "name": "Juscimeira",
                "countryCode": "BR"
            },
            {
                "name": "Juína",
                "countryCode": "BR"
            },
            {
                "name": "Lambari d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Lucas",
                "countryCode": "BR"
            },
            {
                "name": "Lucas do Rio Verde",
                "countryCode": "BR"
            },
            {
                "name": "Luciara",
                "countryCode": "BR"
            },
            {
                "name": "Marcelândia",
                "countryCode": "BR"
            },
            {
                "name": "Matupá",
                "countryCode": "BR"
            },
            {
                "name": "Mirassol d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Nobres",
                "countryCode": "BR"
            },
            {
                "name": "Nortelândia",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora do Livramento",
                "countryCode": "BR"
            },
            {
                "name": "Nova Bandeirantes",
                "countryCode": "BR"
            },
            {
                "name": "Nova Brasilândia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Canaã do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Nova Guarita",
                "countryCode": "BR"
            },
            {
                "name": "Nova Lacerda",
                "countryCode": "BR"
            },
            {
                "name": "Nova Marilândia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Maringá",
                "countryCode": "BR"
            },
            {
                "name": "Nova Monte Verde",
                "countryCode": "BR"
            },
            {
                "name": "Nova Mutum",
                "countryCode": "BR"
            },
            {
                "name": "Nova Nazaré",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olímpia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Santa Helena",
                "countryCode": "BR"
            },
            {
                "name": "Nova Ubiratã",
                "countryCode": "BR"
            },
            {
                "name": "Nova Xavantina",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Novo Mundo",
                "countryCode": "BR"
            },
            {
                "name": "Novo Santo Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Novo São Joaquim",
                "countryCode": "BR"
            },
            {
                "name": "Paranatinga",
                "countryCode": "BR"
            },
            {
                "name": "Paranaíta",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Preta",
                "countryCode": "BR"
            },
            {
                "name": "Peixoto de Azevedo",
                "countryCode": "BR"
            },
            {
                "name": "Planalto da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Poconé",
                "countryCode": "BR"
            },
            {
                "name": "Pontal do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Branca",
                "countryCode": "BR"
            },
            {
                "name": "Pontes e Lacerda",
                "countryCode": "BR"
            },
            {
                "name": "Porto Alegre do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Porto Esperidião",
                "countryCode": "BR"
            },
            {
                "name": "Porto Estrela",
                "countryCode": "BR"
            },
            {
                "name": "Porto dos Gaúchos",
                "countryCode": "BR"
            },
            {
                "name": "Poxoréo",
                "countryCode": "BR"
            },
            {
                "name": "Poxoréu",
                "countryCode": "BR"
            },
            {
                "name": "Primavera do Leste",
                "countryCode": "BR"
            },
            {
                "name": "Querência",
                "countryCode": "BR"
            },
            {
                "name": "Reserva do Cabaçal",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Cascalheira",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirãozinho",
                "countryCode": "BR"
            },
            {
                "name": "Rio Branco",
                "countryCode": "BR"
            },
            {
                "name": "Rondolândia",
                "countryCode": "BR"
            },
            {
                "name": "Rondonópolis",
                "countryCode": "BR"
            },
            {
                "name": "Rosário Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Salto do Céu",
                "countryCode": "BR"
            },
            {
                "name": "Santa Carmem",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Xingu",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Trivelato",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha",
                "countryCode": "BR"
            },
            {
                "name": "Santo Afonso",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Leste",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Leverger",
                "countryCode": "BR"
            },
            {
                "name": "Sapezal",
                "countryCode": "BR"
            },
            {
                "name": "Serra Nova Dourada",
                "countryCode": "BR"
            },
            {
                "name": "Sinop",
                "countryCode": "BR"
            },
            {
                "name": "Sorriso",
                "countryCode": "BR"
            },
            {
                "name": "São Félix do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "São José do Povo",
                "countryCode": "BR"
            },
            {
                "name": "São José do Rio Claro",
                "countryCode": "BR"
            },
            {
                "name": "São José do Xingu",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Quatro Marcos",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro da Cipa",
                "countryCode": "BR"
            },
            {
                "name": "Tabaporã",
                "countryCode": "BR"
            },
            {
                "name": "Tangará da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Tapurah",
                "countryCode": "BR"
            },
            {
                "name": "Terra Nova do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Tesouro",
                "countryCode": "BR"
            },
            {
                "name": "Torixoréu",
                "countryCode": "BR"
            },
            {
                "name": "União do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Vale de São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "Vera",
                "countryCode": "BR"
            },
            {
                "name": "Vila Bela da Santíssima Trindade",
                "countryCode": "BR"
            },
            {
                "name": "Vila Rica",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Grande",
                "countryCode": "BR"
            },
            {
                "name": "Água Boa",
                "countryCode": "BR"
            },
            {
                "name": "Abaetetuba",
                "countryCode": "BR"
            },
            {
                "name": "Abel Figueiredo",
                "countryCode": "BR"
            },
            {
                "name": "Acará",
                "countryCode": "BR"
            },
            {
                "name": "Afuá",
                "countryCode": "BR"
            },
            {
                "name": "Alenquer",
                "countryCode": "BR"
            },
            {
                "name": "Almeirim",
                "countryCode": "BR"
            },
            {
                "name": "Altamira",
                "countryCode": "BR"
            },
            {
                "name": "Anajás",
                "countryCode": "BR"
            },
            {
                "name": "Ananindeua",
                "countryCode": "BR"
            },
            {
                "name": "Anapu",
                "countryCode": "BR"
            },
            {
                "name": "Augusto Corrêa",
                "countryCode": "BR"
            },
            {
                "name": "Aurora do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Aveiro",
                "countryCode": "BR"
            },
            {
                "name": "Bagre",
                "countryCode": "BR"
            },
            {
                "name": "Baião",
                "countryCode": "BR"
            },
            {
                "name": "Bannach",
                "countryCode": "BR"
            },
            {
                "name": "Barcarena",
                "countryCode": "BR"
            },
            {
                "name": "Belterra",
                "countryCode": "BR"
            },
            {
                "name": "Belém",
                "countryCode": "BR"
            },
            {
                "name": "Benevides",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Bragança",
                "countryCode": "BR"
            },
            {
                "name": "Brasil Novo",
                "countryCode": "BR"
            },
            {
                "name": "Brejo Grande do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Breu Branco",
                "countryCode": "BR"
            },
            {
                "name": "Breves",
                "countryCode": "BR"
            },
            {
                "name": "Bujaru",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira do Arari",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira do Piriá",
                "countryCode": "BR"
            },
            {
                "name": "Cametá",
                "countryCode": "BR"
            },
            {
                "name": "Canaã dos Carajás",
                "countryCode": "BR"
            },
            {
                "name": "Capanema",
                "countryCode": "BR"
            },
            {
                "name": "Capitão Poço",
                "countryCode": "BR"
            },
            {
                "name": "Castanhal",
                "countryCode": "BR"
            },
            {
                "name": "Chaves",
                "countryCode": "BR"
            },
            {
                "name": "Colares",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Concórdia do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Cumaru do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Curionópolis",
                "countryCode": "BR"
            },
            {
                "name": "Curralinho",
                "countryCode": "BR"
            },
            {
                "name": "Curuá",
                "countryCode": "BR"
            },
            {
                "name": "Curuçá",
                "countryCode": "BR"
            },
            {
                "name": "Dom Eliseu",
                "countryCode": "BR"
            },
            {
                "name": "Eldorado do Carajás",
                "countryCode": "BR"
            },
            {
                "name": "Faro",
                "countryCode": "BR"
            },
            {
                "name": "Floresta do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Garrafão do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Goianésia do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Gurupá",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé Açu",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé Miri",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé-Açu",
                "countryCode": "BR"
            },
            {
                "name": "Igarapé-Miri",
                "countryCode": "BR"
            },
            {
                "name": "Inhangapi",
                "countryCode": "BR"
            },
            {
                "name": "Ipixuna do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Irituia",
                "countryCode": "BR"
            },
            {
                "name": "Itaituba",
                "countryCode": "BR"
            },
            {
                "name": "Itupiranga",
                "countryCode": "BR"
            },
            {
                "name": "Jacareacanga",
                "countryCode": "BR"
            },
            {
                "name": "Jacundá",
                "countryCode": "BR"
            },
            {
                "name": "Juruti",
                "countryCode": "BR"
            },
            {
                "name": "Limoeiro do Ajuru",
                "countryCode": "BR"
            },
            {
                "name": "Magalhães Barata",
                "countryCode": "BR"
            },
            {
                "name": "Marabá",
                "countryCode": "BR"
            },
            {
                "name": "Maracanã",
                "countryCode": "BR"
            },
            {
                "name": "Marapanim",
                "countryCode": "BR"
            },
            {
                "name": "Marituba",
                "countryCode": "BR"
            },
            {
                "name": "Medicilândia",
                "countryCode": "BR"
            },
            {
                "name": "Melgaço",
                "countryCode": "BR"
            },
            {
                "name": "Mocajuba",
                "countryCode": "BR"
            },
            {
                "name": "Moju",
                "countryCode": "BR"
            },
            {
                "name": "Mojuí Dos Campos",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Muaná",
                "countryCode": "BR"
            },
            {
                "name": "Mãe do Rio",
                "countryCode": "BR"
            },
            {
                "name": "Nova Esperança do Piriá",
                "countryCode": "BR"
            },
            {
                "name": "Nova Ipixuna",
                "countryCode": "BR"
            },
            {
                "name": "Nova Timboteua",
                "countryCode": "BR"
            },
            {
                "name": "Novo Progresso",
                "countryCode": "BR"
            },
            {
                "name": "Novo Repartimento",
                "countryCode": "BR"
            },
            {
                "name": "Oeiras do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Oriximiná",
                "countryCode": "BR"
            },
            {
                "name": "Ourilândia do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Ourém",
                "countryCode": "BR"
            },
            {
                "name": "Pacajá",
                "countryCode": "BR"
            },
            {
                "name": "Palestina do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Paragominas",
                "countryCode": "BR"
            },
            {
                "name": "Parauapebas",
                "countryCode": "BR"
            },
            {
                "name": "Pau d'Arco",
                "countryCode": "BR"
            },
            {
                "name": "Peixe-Boi",
                "countryCode": "BR"
            },
            {
                "name": "Piçarra",
                "countryCode": "BR"
            },
            {
                "name": "Placas",
                "countryCode": "BR"
            },
            {
                "name": "Ponta de Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Portel",
                "countryCode": "BR"
            },
            {
                "name": "Porto de Moz",
                "countryCode": "BR"
            },
            {
                "name": "Prainha",
                "countryCode": "BR"
            },
            {
                "name": "Primavera",
                "countryCode": "BR"
            },
            {
                "name": "Quatipuru",
                "countryCode": "BR"
            },
            {
                "name": "Redenção",
                "countryCode": "BR"
            },
            {
                "name": "Rio Maria",
                "countryCode": "BR"
            },
            {
                "name": "Rondon do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Rurópolis",
                "countryCode": "BR"
            },
            {
                "name": "Salinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Salvaterra",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Arari",
                "countryCode": "BR"
            },
            {
                "name": "Santa Izabel do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria das Barreiras",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Pará",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Santarém",
                "countryCode": "BR"
            },
            {
                "name": "Santarém Novo",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Tauá",
                "countryCode": "BR"
            },
            {
                "name": "Sapucaia",
                "countryCode": "BR"
            },
            {
                "name": "Senador José Porfírio",
                "countryCode": "BR"
            },
            {
                "name": "Soure",
                "countryCode": "BR"
            },
            {
                "name": "São Caetano de Odivelas",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Capim",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Pará",
                "countryCode": "BR"
            },
            {
                "name": "São Félix do Xingu",
                "countryCode": "BR"
            },
            {
                "name": "São Geraldo do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "São João da Ponta",
                "countryCode": "BR"
            },
            {
                "name": "São João de Pirabas",
                "countryCode": "BR"
            },
            {
                "name": "São João do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Guamá",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Tailândia",
                "countryCode": "BR"
            },
            {
                "name": "Terra Alta",
                "countryCode": "BR"
            },
            {
                "name": "Terra Santa",
                "countryCode": "BR"
            },
            {
                "name": "Tomé Açu",
                "countryCode": "BR"
            },
            {
                "name": "Tomé-Açu",
                "countryCode": "BR"
            },
            {
                "name": "Tracuateua",
                "countryCode": "BR"
            },
            {
                "name": "Trairão",
                "countryCode": "BR"
            },
            {
                "name": "Tucumã",
                "countryCode": "BR"
            },
            {
                "name": "Tucuruí",
                "countryCode": "BR"
            },
            {
                "name": "Ulianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Uruará",
                "countryCode": "BR"
            },
            {
                "name": "Vigia",
                "countryCode": "BR"
            },
            {
                "name": "Viseu",
                "countryCode": "BR"
            },
            {
                "name": "Vitória do Xingu",
                "countryCode": "BR"
            },
            {
                "name": "Xinguara",
                "countryCode": "BR"
            },
            {
                "name": "Água Azul do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Óbidos",
                "countryCode": "BR"
            },
            {
                "name": "Aguiar",
                "countryCode": "BR"
            },
            {
                "name": "Alagoa Grande",
                "countryCode": "BR"
            },
            {
                "name": "Alagoa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Alagoinha",
                "countryCode": "BR"
            },
            {
                "name": "Alcantil",
                "countryCode": "BR"
            },
            {
                "name": "Algodão de Jandaíra",
                "countryCode": "BR"
            },
            {
                "name": "Alhandra",
                "countryCode": "BR"
            },
            {
                "name": "Amparo",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida",
                "countryCode": "BR"
            },
            {
                "name": "Arara",
                "countryCode": "BR"
            },
            {
                "name": "Araruna",
                "countryCode": "BR"
            },
            {
                "name": "Araçagi",
                "countryCode": "BR"
            },
            {
                "name": "Areia",
                "countryCode": "BR"
            },
            {
                "name": "Areia de Baraúnas",
                "countryCode": "BR"
            },
            {
                "name": "Areial",
                "countryCode": "BR"
            },
            {
                "name": "Aroeiras",
                "countryCode": "BR"
            },
            {
                "name": "Assunção",
                "countryCode": "BR"
            },
            {
                "name": "Bananeiras",
                "countryCode": "BR"
            },
            {
                "name": "Baraúna",
                "countryCode": "BR"
            },
            {
                "name": "Barra de Santa Rosa",
                "countryCode": "BR"
            },
            {
                "name": "Barra de Santana",
                "countryCode": "BR"
            },
            {
                "name": "Barra de São Miguel",
                "countryCode": "BR"
            },
            {
                "name": "Bayeux",
                "countryCode": "BR"
            },
            {
                "name": "Baía da Traição",
                "countryCode": "BR"
            },
            {
                "name": "Belém",
                "countryCode": "BR"
            },
            {
                "name": "Belém do Brejo do Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Bernardino Batista",
                "countryCode": "BR"
            },
            {
                "name": "Boa Ventura",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Bom Sucesso",
                "countryCode": "BR"
            },
            {
                "name": "Bonito de Santa Fé",
                "countryCode": "BR"
            },
            {
                "name": "Boqueirão",
                "countryCode": "BR"
            },
            {
                "name": "Borborema",
                "countryCode": "BR"
            },
            {
                "name": "Brejo do Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Brejo dos Santos",
                "countryCode": "BR"
            },
            {
                "name": "Caaporã",
                "countryCode": "BR"
            },
            {
                "name": "Cabaceiras",
                "countryCode": "BR"
            },
            {
                "name": "Cabedelo",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira dos Índios",
                "countryCode": "BR"
            },
            {
                "name": "Cacimba de Areia",
                "countryCode": "BR"
            },
            {
                "name": "Cacimba de Dentro",
                "countryCode": "BR"
            },
            {
                "name": "Cacimbas",
                "countryCode": "BR"
            },
            {
                "name": "Caiçara",
                "countryCode": "BR"
            },
            {
                "name": "Cajazeiras",
                "countryCode": "BR"
            },
            {
                "name": "Cajazeirinhas",
                "countryCode": "BR"
            },
            {
                "name": "Caldas Brandão",
                "countryCode": "BR"
            },
            {
                "name": "Camalaú",
                "countryCode": "BR"
            },
            {
                "name": "Campina Grande",
                "countryCode": "BR"
            },
            {
                "name": "Capim",
                "countryCode": "BR"
            },
            {
                "name": "Caraúbas",
                "countryCode": "BR"
            },
            {
                "name": "Carrapateira",
                "countryCode": "BR"
            },
            {
                "name": "Casserengue",
                "countryCode": "BR"
            },
            {
                "name": "Catingueira",
                "countryCode": "BR"
            },
            {
                "name": "Catolé do Rocha",
                "countryCode": "BR"
            },
            {
                "name": "Caturité",
                "countryCode": "BR"
            },
            {
                "name": "Conceição",
                "countryCode": "BR"
            },
            {
                "name": "Condado",
                "countryCode": "BR"
            },
            {
                "name": "Conde",
                "countryCode": "BR"
            },
            {
                "name": "Congo",
                "countryCode": "BR"
            },
            {
                "name": "Coremas",
                "countryCode": "BR"
            },
            {
                "name": "Coxixola",
                "countryCode": "BR"
            },
            {
                "name": "Cruz do Espírito Santo",
                "countryCode": "BR"
            },
            {
                "name": "Cubati",
                "countryCode": "BR"
            },
            {
                "name": "Cuitegi",
                "countryCode": "BR"
            },
            {
                "name": "Cuité",
                "countryCode": "BR"
            },
            {
                "name": "Cuité de Mamanguape",
                "countryCode": "BR"
            },
            {
                "name": "Curral Velho",
                "countryCode": "BR"
            },
            {
                "name": "Curral de Cima",
                "countryCode": "BR"
            },
            {
                "name": "Damião",
                "countryCode": "BR"
            },
            {
                "name": "Desterro",
                "countryCode": "BR"
            },
            {
                "name": "Diamante",
                "countryCode": "BR"
            },
            {
                "name": "Dona Inês",
                "countryCode": "BR"
            },
            {
                "name": "Duas Estradas",
                "countryCode": "BR"
            },
            {
                "name": "Emas",
                "countryCode": "BR"
            },
            {
                "name": "Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Fagundes",
                "countryCode": "BR"
            },
            {
                "name": "Frei Martinho",
                "countryCode": "BR"
            },
            {
                "name": "Gado Bravo",
                "countryCode": "BR"
            },
            {
                "name": "Guarabira",
                "countryCode": "BR"
            },
            {
                "name": "Gurinhém",
                "countryCode": "BR"
            },
            {
                "name": "Gurjão",
                "countryCode": "BR"
            },
            {
                "name": "Ibiara",
                "countryCode": "BR"
            },
            {
                "name": "Igaracy",
                "countryCode": "BR"
            },
            {
                "name": "Imaculada",
                "countryCode": "BR"
            },
            {
                "name": "Ingá",
                "countryCode": "BR"
            },
            {
                "name": "Itabaiana",
                "countryCode": "BR"
            },
            {
                "name": "Itaporanga",
                "countryCode": "BR"
            },
            {
                "name": "Itapororoca",
                "countryCode": "BR"
            },
            {
                "name": "Itatuba",
                "countryCode": "BR"
            },
            {
                "name": "Jacaraú",
                "countryCode": "BR"
            },
            {
                "name": "Jericó",
                "countryCode": "BR"
            },
            {
                "name": "Joca Claudino",
                "countryCode": "BR"
            },
            {
                "name": "João Pessoa",
                "countryCode": "BR"
            },
            {
                "name": "Juarez Távora",
                "countryCode": "BR"
            },
            {
                "name": "Juazeirinho",
                "countryCode": "BR"
            },
            {
                "name": "Junco do Seridó",
                "countryCode": "BR"
            },
            {
                "name": "Juripiranga",
                "countryCode": "BR"
            },
            {
                "name": "Juru",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Seca",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa de Dentro",
                "countryCode": "BR"
            },
            {
                "name": "Lastro",
                "countryCode": "BR"
            },
            {
                "name": "Livramento",
                "countryCode": "BR"
            },
            {
                "name": "Logradouro",
                "countryCode": "BR"
            },
            {
                "name": "Lucena",
                "countryCode": "BR"
            },
            {
                "name": "Malta",
                "countryCode": "BR"
            },
            {
                "name": "Mamanguape",
                "countryCode": "BR"
            },
            {
                "name": "Manaíra",
                "countryCode": "BR"
            },
            {
                "name": "Marcação",
                "countryCode": "BR"
            },
            {
                "name": "Mari",
                "countryCode": "BR"
            },
            {
                "name": "Marizópolis",
                "countryCode": "BR"
            },
            {
                "name": "Massaranduba",
                "countryCode": "BR"
            },
            {
                "name": "Mataraca",
                "countryCode": "BR"
            },
            {
                "name": "Matinhas",
                "countryCode": "BR"
            },
            {
                "name": "Mato Grosso",
                "countryCode": "BR"
            },
            {
                "name": "Maturéia",
                "countryCode": "BR"
            },
            {
                "name": "Mogeiro",
                "countryCode": "BR"
            },
            {
                "name": "Montadas",
                "countryCode": "BR"
            },
            {
                "name": "Monte Horebe",
                "countryCode": "BR"
            },
            {
                "name": "Monteiro",
                "countryCode": "BR"
            },
            {
                "name": "Mulungu",
                "countryCode": "BR"
            },
            {
                "name": "Mãe d'Água",
                "countryCode": "BR"
            },
            {
                "name": "Natuba",
                "countryCode": "BR"
            },
            {
                "name": "Nazarezinho",
                "countryCode": "BR"
            },
            {
                "name": "Nova Floresta",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olinda",
                "countryCode": "BR"
            },
            {
                "name": "Nova Palmeira",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água",
                "countryCode": "BR"
            },
            {
                "name": "Olivedos",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Velho",
                "countryCode": "BR"
            },
            {
                "name": "Parari",
                "countryCode": "BR"
            },
            {
                "name": "Passagem",
                "countryCode": "BR"
            },
            {
                "name": "Patos",
                "countryCode": "BR"
            },
            {
                "name": "Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Branca",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Lavrada",
                "countryCode": "BR"
            },
            {
                "name": "Pedras de Fogo",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Régis",
                "countryCode": "BR"
            },
            {
                "name": "Piancó",
                "countryCode": "BR"
            },
            {
                "name": "Picuí",
                "countryCode": "BR"
            },
            {
                "name": "Pilar",
                "countryCode": "BR"
            },
            {
                "name": "Pilões",
                "countryCode": "BR"
            },
            {
                "name": "Pilõezinhos",
                "countryCode": "BR"
            },
            {
                "name": "Pirpirituba",
                "countryCode": "BR"
            },
            {
                "name": "Pitimbu",
                "countryCode": "BR"
            },
            {
                "name": "Pocinhos",
                "countryCode": "BR"
            },
            {
                "name": "Pombal",
                "countryCode": "BR"
            },
            {
                "name": "Poço Dantas",
                "countryCode": "BR"
            },
            {
                "name": "Poço de José de Moura",
                "countryCode": "BR"
            },
            {
                "name": "Prata",
                "countryCode": "BR"
            },
            {
                "name": "Princesa Isabel",
                "countryCode": "BR"
            },
            {
                "name": "Puxinanã",
                "countryCode": "BR"
            },
            {
                "name": "Queimadas",
                "countryCode": "BR"
            },
            {
                "name": "Quixaba",
                "countryCode": "BR"
            },
            {
                "name": "Remígio",
                "countryCode": "BR"
            },
            {
                "name": "Riacho de Santo Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Riacho dos Cavalos",
                "countryCode": "BR"
            },
            {
                "name": "Riachão",
                "countryCode": "BR"
            },
            {
                "name": "Riachão do Bacamarte",
                "countryCode": "BR"
            },
            {
                "name": "Riachão do Poço",
                "countryCode": "BR"
            },
            {
                "name": "Rio Tinto",
                "countryCode": "BR"
            },
            {
                "name": "Salgadinho",
                "countryCode": "BR"
            },
            {
                "name": "Salgado de São Félix",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cecília",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Inês",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita",
                "countryCode": "BR"
            },
            {
                "name": "Santa Teresinha",
                "countryCode": "BR"
            },
            {
                "name": "Santana de Mangueira",
                "countryCode": "BR"
            },
            {
                "name": "Santana dos Garrotes",
                "countryCode": "BR"
            },
            {
                "name": "Santo André",
                "countryCode": "BR"
            },
            {
                "name": "Sapé",
                "countryCode": "BR"
            },
            {
                "name": "Serra Branca",
                "countryCode": "BR"
            },
            {
                "name": "Serra Grande",
                "countryCode": "BR"
            },
            {
                "name": "Serra Redonda",
                "countryCode": "BR"
            },
            {
                "name": "Serra da Raiz",
                "countryCode": "BR"
            },
            {
                "name": "Serraria",
                "countryCode": "BR"
            },
            {
                "name": "Sertãozinho",
                "countryCode": "BR"
            },
            {
                "name": "Sobrado",
                "countryCode": "BR"
            },
            {
                "name": "Soledade",
                "countryCode": "BR"
            },
            {
                "name": "Solânea",
                "countryCode": "BR"
            },
            {
                "name": "Sossêgo",
                "countryCode": "BR"
            },
            {
                "name": "Sousa",
                "countryCode": "BR"
            },
            {
                "name": "Sumé",
                "countryCode": "BR"
            },
            {
                "name": "São Bentinho",
                "countryCode": "BR"
            },
            {
                "name": "São Bento",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Cariri",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "São José da Lagoa Tapada",
                "countryCode": "BR"
            },
            {
                "name": "São José de Caiana",
                "countryCode": "BR"
            },
            {
                "name": "São José de Espinharas",
                "countryCode": "BR"
            },
            {
                "name": "São José de Piranhas",
                "countryCode": "BR"
            },
            {
                "name": "São José de Princesa",
                "countryCode": "BR"
            },
            {
                "name": "São José do Bonfim",
                "countryCode": "BR"
            },
            {
                "name": "São José do Brejo do Cruz",
                "countryCode": "BR"
            },
            {
                "name": "São José do Sabugi",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Cordeiros",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Ramos",
                "countryCode": "BR"
            },
            {
                "name": "São João do Cariri",
                "countryCode": "BR"
            },
            {
                "name": "São João do Rio do Peixe",
                "countryCode": "BR"
            },
            {
                "name": "São João do Tigre",
                "countryCode": "BR"
            },
            {
                "name": "São Mamede",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel de Taipu",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião de Lagoa de Roça",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Umbuzeiro",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente do Seridó",
                "countryCode": "BR"
            },
            {
                "name": "Tacima",
                "countryCode": "BR"
            },
            {
                "name": "Taperoá",
                "countryCode": "BR"
            },
            {
                "name": "Tavares",
                "countryCode": "BR"
            },
            {
                "name": "Teixeira",
                "countryCode": "BR"
            },
            {
                "name": "Tenório",
                "countryCode": "BR"
            },
            {
                "name": "Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "Uiraúna",
                "countryCode": "BR"
            },
            {
                "name": "Umbuzeiro",
                "countryCode": "BR"
            },
            {
                "name": "Vieirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Vista Serrana",
                "countryCode": "BR"
            },
            {
                "name": "Várzea",
                "countryCode": "BR"
            },
            {
                "name": "Zabelê",
                "countryCode": "BR"
            },
            {
                "name": "Água Branca",
                "countryCode": "BR"
            },
            {
                "name": "Abreu e Lima",
                "countryCode": "BR"
            },
            {
                "name": "Afogados da Ingazeira",
                "countryCode": "BR"
            },
            {
                "name": "Afrânio",
                "countryCode": "BR"
            },
            {
                "name": "Agrestina",
                "countryCode": "BR"
            },
            {
                "name": "Alagoinha",
                "countryCode": "BR"
            },
            {
                "name": "Aliança",
                "countryCode": "BR"
            },
            {
                "name": "Altinho",
                "countryCode": "BR"
            },
            {
                "name": "Amaraji",
                "countryCode": "BR"
            },
            {
                "name": "Angelim",
                "countryCode": "BR"
            },
            {
                "name": "Araripina",
                "countryCode": "BR"
            },
            {
                "name": "Araçoiaba",
                "countryCode": "BR"
            },
            {
                "name": "Arcoverde",
                "countryCode": "BR"
            },
            {
                "name": "Barra de Guabiraba",
                "countryCode": "BR"
            },
            {
                "name": "Barreiros",
                "countryCode": "BR"
            },
            {
                "name": "Belo Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Belém de Maria",
                "countryCode": "BR"
            },
            {
                "name": "Belém de São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Belém do São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Betânia",
                "countryCode": "BR"
            },
            {
                "name": "Bezerros",
                "countryCode": "BR"
            },
            {
                "name": "Bodocó",
                "countryCode": "BR"
            },
            {
                "name": "Bom Conselho",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Brejinho",
                "countryCode": "BR"
            },
            {
                "name": "Brejo da Madre de Deus",
                "countryCode": "BR"
            },
            {
                "name": "Brejão",
                "countryCode": "BR"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "BR"
            },
            {
                "name": "Buíque",
                "countryCode": "BR"
            },
            {
                "name": "Cabo",
                "countryCode": "BR"
            },
            {
                "name": "Cabo de Santo Agostinho",
                "countryCode": "BR"
            },
            {
                "name": "Cabrobó",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeirinha",
                "countryCode": "BR"
            },
            {
                "name": "Caetés",
                "countryCode": "BR"
            },
            {
                "name": "Calumbi",
                "countryCode": "BR"
            },
            {
                "name": "Calçado",
                "countryCode": "BR"
            },
            {
                "name": "Camaragibe",
                "countryCode": "BR"
            },
            {
                "name": "Camocim de São Félix",
                "countryCode": "BR"
            },
            {
                "name": "Camutanga",
                "countryCode": "BR"
            },
            {
                "name": "Canhotinho",
                "countryCode": "BR"
            },
            {
                "name": "Capoeiras",
                "countryCode": "BR"
            },
            {
                "name": "Carnaubeira da Penha",
                "countryCode": "BR"
            },
            {
                "name": "Carnaíba",
                "countryCode": "BR"
            },
            {
                "name": "Carpina",
                "countryCode": "BR"
            },
            {
                "name": "Caruaru",
                "countryCode": "BR"
            },
            {
                "name": "Casinhas",
                "countryCode": "BR"
            },
            {
                "name": "Catende",
                "countryCode": "BR"
            },
            {
                "name": "Cedro",
                "countryCode": "BR"
            },
            {
                "name": "Chã Grande",
                "countryCode": "BR"
            },
            {
                "name": "Chã de Alegria",
                "countryCode": "BR"
            },
            {
                "name": "Colônia Leopoldina",
                "countryCode": "BR"
            },
            {
                "name": "Condado",
                "countryCode": "BR"
            },
            {
                "name": "Correntes",
                "countryCode": "BR"
            },
            {
                "name": "Cortês",
                "countryCode": "BR"
            },
            {
                "name": "Cumaru",
                "countryCode": "BR"
            },
            {
                "name": "Cupira",
                "countryCode": "BR"
            },
            {
                "name": "Custódia",
                "countryCode": "BR"
            },
            {
                "name": "Dormentes",
                "countryCode": "BR"
            },
            {
                "name": "Escada",
                "countryCode": "BR"
            },
            {
                "name": "Exu",
                "countryCode": "BR"
            },
            {
                "name": "Feira Nova",
                "countryCode": "BR"
            },
            {
                "name": "Fernando de Noronha",
                "countryCode": "BR"
            },
            {
                "name": "Fernando de Noronha (Distrito Estadual)",
                "countryCode": "BR"
            },
            {
                "name": "Ferreiros",
                "countryCode": "BR"
            },
            {
                "name": "Flores",
                "countryCode": "BR"
            },
            {
                "name": "Floresta",
                "countryCode": "BR"
            },
            {
                "name": "Frei Miguelinho",
                "countryCode": "BR"
            },
            {
                "name": "Gameleira",
                "countryCode": "BR"
            },
            {
                "name": "Garanhuns",
                "countryCode": "BR"
            },
            {
                "name": "Glória do Goitá",
                "countryCode": "BR"
            },
            {
                "name": "Goiana",
                "countryCode": "BR"
            },
            {
                "name": "Granito",
                "countryCode": "BR"
            },
            {
                "name": "Gravatá",
                "countryCode": "BR"
            },
            {
                "name": "Guabiraba",
                "countryCode": "BR"
            },
            {
                "name": "Iati",
                "countryCode": "BR"
            },
            {
                "name": "Ibimirim",
                "countryCode": "BR"
            },
            {
                "name": "Ibirajuba",
                "countryCode": "BR"
            },
            {
                "name": "Igarassu",
                "countryCode": "BR"
            },
            {
                "name": "Iguaracy",
                "countryCode": "BR"
            },
            {
                "name": "Ilha de Itamaracá",
                "countryCode": "BR"
            },
            {
                "name": "Inajá",
                "countryCode": "BR"
            },
            {
                "name": "Ingazeira",
                "countryCode": "BR"
            },
            {
                "name": "Ipojuca",
                "countryCode": "BR"
            },
            {
                "name": "Ipubi",
                "countryCode": "BR"
            },
            {
                "name": "Itacuruba",
                "countryCode": "BR"
            },
            {
                "name": "Itamaracá",
                "countryCode": "BR"
            },
            {
                "name": "Itambé",
                "countryCode": "BR"
            },
            {
                "name": "Itapetim",
                "countryCode": "BR"
            },
            {
                "name": "Itapissuma",
                "countryCode": "BR"
            },
            {
                "name": "Itaquitinga",
                "countryCode": "BR"
            },
            {
                "name": "Itaíba",
                "countryCode": "BR"
            },
            {
                "name": "Jaboatão",
                "countryCode": "BR"
            },
            {
                "name": "Jaboatão dos Guararapes",
                "countryCode": "BR"
            },
            {
                "name": "Jaqueira",
                "countryCode": "BR"
            },
            {
                "name": "Jataúba",
                "countryCode": "BR"
            },
            {
                "name": "Jatobá",
                "countryCode": "BR"
            },
            {
                "name": "Joaquim Nabuco",
                "countryCode": "BR"
            },
            {
                "name": "João Alfredo",
                "countryCode": "BR"
            },
            {
                "name": "Jucati",
                "countryCode": "BR"
            },
            {
                "name": "Jupi",
                "countryCode": "BR"
            },
            {
                "name": "Jurema",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Grande",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa de Itaenga",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Carro",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Itaenga",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Ouro",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa dos Gatos",
                "countryCode": "BR"
            },
            {
                "name": "Lajedo",
                "countryCode": "BR"
            },
            {
                "name": "Limoeiro",
                "countryCode": "BR"
            },
            {
                "name": "Macaparana",
                "countryCode": "BR"
            },
            {
                "name": "Machados",
                "countryCode": "BR"
            },
            {
                "name": "Manari",
                "countryCode": "BR"
            },
            {
                "name": "Maraial",
                "countryCode": "BR"
            },
            {
                "name": "Mirandiba",
                "countryCode": "BR"
            },
            {
                "name": "Moreilândia",
                "countryCode": "BR"
            },
            {
                "name": "Moreno",
                "countryCode": "BR"
            },
            {
                "name": "Nazaré da Mata",
                "countryCode": "BR"
            },
            {
                "name": "Olinda",
                "countryCode": "BR"
            },
            {
                "name": "Orobó",
                "countryCode": "BR"
            },
            {
                "name": "Orocó",
                "countryCode": "BR"
            },
            {
                "name": "Ouricuri",
                "countryCode": "BR"
            },
            {
                "name": "Palmares",
                "countryCode": "BR"
            },
            {
                "name": "Palmeirina",
                "countryCode": "BR"
            },
            {
                "name": "Panelas",
                "countryCode": "BR"
            },
            {
                "name": "Paranatama",
                "countryCode": "BR"
            },
            {
                "name": "Parnamirim",
                "countryCode": "BR"
            },
            {
                "name": "Passira",
                "countryCode": "BR"
            },
            {
                "name": "Paudalho",
                "countryCode": "BR"
            },
            {
                "name": "Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Pedra",
                "countryCode": "BR"
            },
            {
                "name": "Pesqueira",
                "countryCode": "BR"
            },
            {
                "name": "Petrolina",
                "countryCode": "BR"
            },
            {
                "name": "Petrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Pombos",
                "countryCode": "BR"
            },
            {
                "name": "Poção",
                "countryCode": "BR"
            },
            {
                "name": "Primavera",
                "countryCode": "BR"
            },
            {
                "name": "Quipapá",
                "countryCode": "BR"
            },
            {
                "name": "Quixaba",
                "countryCode": "BR"
            },
            {
                "name": "Recife",
                "countryCode": "BR"
            },
            {
                "name": "Riacho das Almas",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão",
                "countryCode": "BR"
            },
            {
                "name": "Rio Formoso",
                "countryCode": "BR"
            },
            {
                "name": "Sairé",
                "countryCode": "BR"
            },
            {
                "name": "Salgadinho",
                "countryCode": "BR"
            },
            {
                "name": "Salgueiro",
                "countryCode": "BR"
            },
            {
                "name": "Saloá",
                "countryCode": "BR"
            },
            {
                "name": "Sanharó",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz da Baixa Verde",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Capibaribe",
                "countryCode": "BR"
            },
            {
                "name": "Santa Filomena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Cambucá",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha",
                "countryCode": "BR"
            },
            {
                "name": "Serra Talhada",
                "countryCode": "BR"
            },
            {
                "name": "Serrita",
                "countryCode": "BR"
            },
            {
                "name": "Sertânia",
                "countryCode": "BR"
            },
            {
                "name": "Sirinhaém",
                "countryCode": "BR"
            },
            {
                "name": "Solidão",
                "countryCode": "BR"
            },
            {
                "name": "Surubim",
                "countryCode": "BR"
            },
            {
                "name": "São Benedito do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Una",
                "countryCode": "BR"
            },
            {
                "name": "São Caitano",
                "countryCode": "BR"
            },
            {
                "name": "São Joaquim do Monte",
                "countryCode": "BR"
            },
            {
                "name": "São José da Coroa Grande",
                "countryCode": "BR"
            },
            {
                "name": "São José do Belmonte",
                "countryCode": "BR"
            },
            {
                "name": "São José do Egito",
                "countryCode": "BR"
            },
            {
                "name": "São João",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço da Mata",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente Férrer",
                "countryCode": "BR"
            },
            {
                "name": "Tabira",
                "countryCode": "BR"
            },
            {
                "name": "Tacaimbó",
                "countryCode": "BR"
            },
            {
                "name": "Tacaratu",
                "countryCode": "BR"
            },
            {
                "name": "Tamandaré",
                "countryCode": "BR"
            },
            {
                "name": "Taquaritinga do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Terezinha",
                "countryCode": "BR"
            },
            {
                "name": "Terra Nova",
                "countryCode": "BR"
            },
            {
                "name": "Timbaúba",
                "countryCode": "BR"
            },
            {
                "name": "Toritama",
                "countryCode": "BR"
            },
            {
                "name": "Tracunhaém",
                "countryCode": "BR"
            },
            {
                "name": "Trindade",
                "countryCode": "BR"
            },
            {
                "name": "Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "Tupanatinga",
                "countryCode": "BR"
            },
            {
                "name": "Tuparetama",
                "countryCode": "BR"
            },
            {
                "name": "Venturosa",
                "countryCode": "BR"
            },
            {
                "name": "Verdejante",
                "countryCode": "BR"
            },
            {
                "name": "Vertente do Lério",
                "countryCode": "BR"
            },
            {
                "name": "Vertentes",
                "countryCode": "BR"
            },
            {
                "name": "Vicência",
                "countryCode": "BR"
            },
            {
                "name": "Vitória de Santo Antão",
                "countryCode": "BR"
            },
            {
                "name": "Xexéu",
                "countryCode": "BR"
            },
            {
                "name": "Água Preta",
                "countryCode": "BR"
            },
            {
                "name": "Águas Belas",
                "countryCode": "BR"
            },
            {
                "name": "Acauã",
                "countryCode": "BR"
            },
            {
                "name": "Agricolândia",
                "countryCode": "BR"
            },
            {
                "name": "Alagoinha do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Alegrete do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Alto Longá",
                "countryCode": "BR"
            },
            {
                "name": "Altos",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada do Gurguéia",
                "countryCode": "BR"
            },
            {
                "name": "Amarante",
                "countryCode": "BR"
            },
            {
                "name": "Angical do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Anísio de Abreu",
                "countryCode": "BR"
            },
            {
                "name": "Aroazes",
                "countryCode": "BR"
            },
            {
                "name": "Aroeiras do Itaim",
                "countryCode": "BR"
            },
            {
                "name": "Arraial",
                "countryCode": "BR"
            },
            {
                "name": "Assunção do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Avelino Lopes",
                "countryCode": "BR"
            },
            {
                "name": "Baixa Grande do Ribeiro",
                "countryCode": "BR"
            },
            {
                "name": "Barra d'Alcântara",
                "countryCode": "BR"
            },
            {
                "name": "Barras",
                "countryCode": "BR"
            },
            {
                "name": "Barreiras do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Barro Duro",
                "countryCode": "BR"
            },
            {
                "name": "Batalha",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Belém do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Beneditinos",
                "countryCode": "BR"
            },
            {
                "name": "Bertolínia",
                "countryCode": "BR"
            },
            {
                "name": "Betânia do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Boa Hora",
                "countryCode": "BR"
            },
            {
                "name": "Bocaina",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Bom Princípio do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Bonfim do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Boqueirão do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Brasileira",
                "countryCode": "BR"
            },
            {
                "name": "Brejo do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Buriti dos Lopes",
                "countryCode": "BR"
            },
            {
                "name": "Buriti dos Montes",
                "countryCode": "BR"
            },
            {
                "name": "Cabeceiras do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Cajazeiras do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Cajueiro da Praia",
                "countryCode": "BR"
            },
            {
                "name": "Caldeirão Grande do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Campinas do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Campo Alegre do Fidalgo",
                "countryCode": "BR"
            },
            {
                "name": "Campo Grande do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Campo Largo do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Campo Maior",
                "countryCode": "BR"
            },
            {
                "name": "Canavieira",
                "countryCode": "BR"
            },
            {
                "name": "Canto do Buriti",
                "countryCode": "BR"
            },
            {
                "name": "Capitão Gervásio Oliveira",
                "countryCode": "BR"
            },
            {
                "name": "Capitão de Campos",
                "countryCode": "BR"
            },
            {
                "name": "Caracol",
                "countryCode": "BR"
            },
            {
                "name": "Caraúbas do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Caridade do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Castelo do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Caxingó",
                "countryCode": "BR"
            },
            {
                "name": "Cocal",
                "countryCode": "BR"
            },
            {
                "name": "Cocal de Telha",
                "countryCode": "BR"
            },
            {
                "name": "Cocal dos Alves",
                "countryCode": "BR"
            },
            {
                "name": "Coivaras",
                "countryCode": "BR"
            },
            {
                "name": "Colônia do Gurguéia",
                "countryCode": "BR"
            },
            {
                "name": "Colônia do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Canindé",
                "countryCode": "BR"
            },
            {
                "name": "Coronel José Dias",
                "countryCode": "BR"
            },
            {
                "name": "Corrente",
                "countryCode": "BR"
            },
            {
                "name": "Cristalândia do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Cristino Castro",
                "countryCode": "BR"
            },
            {
                "name": "Curimatá",
                "countryCode": "BR"
            },
            {
                "name": "Currais",
                "countryCode": "BR"
            },
            {
                "name": "Curral Novo do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Curralinhos",
                "countryCode": "BR"
            },
            {
                "name": "Demerval Lobão",
                "countryCode": "BR"
            },
            {
                "name": "Dirceu Arcoverde",
                "countryCode": "BR"
            },
            {
                "name": "Dom Expedito Lopes",
                "countryCode": "BR"
            },
            {
                "name": "Dom Inocêncio",
                "countryCode": "BR"
            },
            {
                "name": "Domingos Mourão",
                "countryCode": "BR"
            },
            {
                "name": "Elesbão Veloso",
                "countryCode": "BR"
            },
            {
                "name": "Eliseu Martins",
                "countryCode": "BR"
            },
            {
                "name": "Esperantina",
                "countryCode": "BR"
            },
            {
                "name": "Fartura do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Flores do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Floresta do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Floriano",
                "countryCode": "BR"
            },
            {
                "name": "Francinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Ayres",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Macedo",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Santos",
                "countryCode": "BR"
            },
            {
                "name": "Fronteiras",
                "countryCode": "BR"
            },
            {
                "name": "Geminiano",
                "countryCode": "BR"
            },
            {
                "name": "Gilbués",
                "countryCode": "BR"
            },
            {
                "name": "Guadalupe",
                "countryCode": "BR"
            },
            {
                "name": "Guaribas",
                "countryCode": "BR"
            },
            {
                "name": "Hugo Napoleão",
                "countryCode": "BR"
            },
            {
                "name": "Ilha Grande",
                "countryCode": "BR"
            },
            {
                "name": "Inhuma",
                "countryCode": "BR"
            },
            {
                "name": "Ipiranga do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Ipueiras",
                "countryCode": "BR"
            },
            {
                "name": "Isaías Coelho",
                "countryCode": "BR"
            },
            {
                "name": "Itainópolis",
                "countryCode": "BR"
            },
            {
                "name": "Itaueira",
                "countryCode": "BR"
            },
            {
                "name": "Jacobina do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Jaicós",
                "countryCode": "BR"
            },
            {
                "name": "Jardim do Mulato",
                "countryCode": "BR"
            },
            {
                "name": "Jatobá do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Jerumenha",
                "countryCode": "BR"
            },
            {
                "name": "Joaquim Pires",
                "countryCode": "BR"
            },
            {
                "name": "Joca Marques",
                "countryCode": "BR"
            },
            {
                "name": "José de Freitas",
                "countryCode": "BR"
            },
            {
                "name": "João Costa",
                "countryCode": "BR"
            },
            {
                "name": "Juazeiro do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Jurema",
                "countryCode": "BR"
            },
            {
                "name": "Júlio Borges",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa de São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Barro do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Sítio",
                "countryCode": "BR"
            },
            {
                "name": "Lagoinha do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Landri Sales",
                "countryCode": "BR"
            },
            {
                "name": "Luzilândia",
                "countryCode": "BR"
            },
            {
                "name": "Luís Correia",
                "countryCode": "BR"
            },
            {
                "name": "Madeiro",
                "countryCode": "BR"
            },
            {
                "name": "Manoel Emídio",
                "countryCode": "BR"
            },
            {
                "name": "Marcolândia",
                "countryCode": "BR"
            },
            {
                "name": "Marcos Parente",
                "countryCode": "BR"
            },
            {
                "name": "Massapê do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Matias Olímpio",
                "countryCode": "BR"
            },
            {
                "name": "Miguel Alves",
                "countryCode": "BR"
            },
            {
                "name": "Miguel Leão",
                "countryCode": "BR"
            },
            {
                "name": "Milton Brandão",
                "countryCode": "BR"
            },
            {
                "name": "Monsenhor Gil",
                "countryCode": "BR"
            },
            {
                "name": "Monsenhor Hipólito",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Morro Cabeça no Tempo",
                "countryCode": "BR"
            },
            {
                "name": "Morro do Chapéu do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Murici dos Portelas",
                "countryCode": "BR"
            },
            {
                "name": "Nazaré do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Nazária",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora de Nazaré",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora dos Remédios",
                "countryCode": "BR"
            },
            {
                "name": "Nova Santa Rita",
                "countryCode": "BR"
            },
            {
                "name": "Novo Oriente do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Novo Santo Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Oeiras",
                "countryCode": "BR"
            },
            {
                "name": "Olho d'Água do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Padre Marcos",
                "countryCode": "BR"
            },
            {
                "name": "Paes Landim",
                "countryCode": "BR"
            },
            {
                "name": "Pajeú do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Palmeirais",
                "countryCode": "BR"
            },
            {
                "name": "Paquetá",
                "countryCode": "BR"
            },
            {
                "name": "Parnaguá",
                "countryCode": "BR"
            },
            {
                "name": "Parnaíba",
                "countryCode": "BR"
            },
            {
                "name": "Passagem Franca do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Patos do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Pau D'arco do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Paulistana",
                "countryCode": "BR"
            },
            {
                "name": "Pavussu",
                "countryCode": "BR"
            },
            {
                "name": "Pedro II",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Laurentino",
                "countryCode": "BR"
            },
            {
                "name": "Picos",
                "countryCode": "BR"
            },
            {
                "name": "Pimenteiras",
                "countryCode": "BR"
            },
            {
                "name": "Pio IX",
                "countryCode": "BR"
            },
            {
                "name": "Piracuruca",
                "countryCode": "BR"
            },
            {
                "name": "Piripiri",
                "countryCode": "BR"
            },
            {
                "name": "Porto",
                "countryCode": "BR"
            },
            {
                "name": "Porto Alegre do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Prata do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Queimada Nova",
                "countryCode": "BR"
            },
            {
                "name": "Redenção do Gurguéia",
                "countryCode": "BR"
            },
            {
                "name": "Regeneração",
                "countryCode": "BR"
            },
            {
                "name": "Riacho Frio",
                "countryCode": "BR"
            },
            {
                "name": "Ribeira do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Ribeiro Gonçalves",
                "countryCode": "BR"
            },
            {
                "name": "Rio Grande do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz dos Milagres",
                "countryCode": "BR"
            },
            {
                "name": "Santa Filomena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luz",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio de Lisboa",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio dos Milagres",
                "countryCode": "BR"
            },
            {
                "name": "Santo Inácio do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Sebastião Barros",
                "countryCode": "BR"
            },
            {
                "name": "Sebastião Leal",
                "countryCode": "BR"
            },
            {
                "name": "Sigefredo Pacheco",
                "countryCode": "BR"
            },
            {
                "name": "Simplício Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Simões",
                "countryCode": "BR"
            },
            {
                "name": "Socorro do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Sussuapara",
                "countryCode": "BR"
            },
            {
                "name": "São Braz do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Assis do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Félix do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Gurguéia",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São José do Divino",
                "countryCode": "BR"
            },
            {
                "name": "São José do Peixe",
                "countryCode": "BR"
            },
            {
                "name": "São José do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São João da Canabrava",
                "countryCode": "BR"
            },
            {
                "name": "São João da Fronteira",
                "countryCode": "BR"
            },
            {
                "name": "São João da Serra",
                "countryCode": "BR"
            },
            {
                "name": "São João da Varjota",
                "countryCode": "BR"
            },
            {
                "name": "São João do Arraial",
                "countryCode": "BR"
            },
            {
                "name": "São João do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Julião",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Luis do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel da Baixa Grande",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Fidalgo",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Tapuio",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "São Raimundo Nonato",
                "countryCode": "BR"
            },
            {
                "name": "Tamboril do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Tanque do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Teresina",
                "countryCode": "BR"
            },
            {
                "name": "União",
                "countryCode": "BR"
            },
            {
                "name": "Uruçuí",
                "countryCode": "BR"
            },
            {
                "name": "Valença do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Vera Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Vila Nova do Piauí",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Branca",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Grande",
                "countryCode": "BR"
            },
            {
                "name": "Wall Ferraz",
                "countryCode": "BR"
            },
            {
                "name": "Água Branca",
                "countryCode": "BR"
            },
            {
                "name": "Abatiá",
                "countryCode": "BR"
            },
            {
                "name": "Adrianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Agudos do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Almirante Tamandaré",
                "countryCode": "BR"
            },
            {
                "name": "Altamira do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Alto Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Alto Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Alto Piquiri",
                "countryCode": "BR"
            },
            {
                "name": "Altãnia",
                "countryCode": "BR"
            },
            {
                "name": "Altônia",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Amaporã",
                "countryCode": "BR"
            },
            {
                "name": "Ampére",
                "countryCode": "BR"
            },
            {
                "name": "Anahy",
                "countryCode": "BR"
            },
            {
                "name": "Andirá",
                "countryCode": "BR"
            },
            {
                "name": "Antonina",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Olinto",
                "countryCode": "BR"
            },
            {
                "name": "Apucarana",
                "countryCode": "BR"
            },
            {
                "name": "Arapongas",
                "countryCode": "BR"
            },
            {
                "name": "Arapoti",
                "countryCode": "BR"
            },
            {
                "name": "Arapuã",
                "countryCode": "BR"
            },
            {
                "name": "Araruna",
                "countryCode": "BR"
            },
            {
                "name": "Araucária",
                "countryCode": "BR"
            },
            {
                "name": "Ariranha do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Assaí",
                "countryCode": "BR"
            },
            {
                "name": "Assis Chateaubriand",
                "countryCode": "BR"
            },
            {
                "name": "Astorga",
                "countryCode": "BR"
            },
            {
                "name": "Atalaia",
                "countryCode": "BR"
            },
            {
                "name": "Balsa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Bandeirantes",
                "countryCode": "BR"
            },
            {
                "name": "Barbosa Ferraz",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Jacaré",
                "countryCode": "BR"
            },
            {
                "name": "Barracão",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista da Caroba",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Bituruna",
                "countryCode": "BR"
            },
            {
                "name": "Boa Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Boa Esperança do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Boa Ventura de São Roque",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista da Aparecida",
                "countryCode": "BR"
            },
            {
                "name": "Bocaiúva do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bom Sucesso",
                "countryCode": "BR"
            },
            {
                "name": "Bom Sucesso do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Borrazópolis",
                "countryCode": "BR"
            },
            {
                "name": "Braganey",
                "countryCode": "BR"
            },
            {
                "name": "Brasilândia do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cafeara",
                "countryCode": "BR"
            },
            {
                "name": "Cafelândia",
                "countryCode": "BR"
            },
            {
                "name": "Cafezal do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Califórnia",
                "countryCode": "BR"
            },
            {
                "name": "Cambará",
                "countryCode": "BR"
            },
            {
                "name": "Cambira",
                "countryCode": "BR"
            },
            {
                "name": "Cambé",
                "countryCode": "BR"
            },
            {
                "name": "Campina Grande do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Campina da Lagoa",
                "countryCode": "BR"
            },
            {
                "name": "Campina do Simão",
                "countryCode": "BR"
            },
            {
                "name": "Campo Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Campo Largo",
                "countryCode": "BR"
            },
            {
                "name": "Campo Magro",
                "countryCode": "BR"
            },
            {
                "name": "Campo Mourão",
                "countryCode": "BR"
            },
            {
                "name": "Campo do Tenente",
                "countryCode": "BR"
            },
            {
                "name": "Candói",
                "countryCode": "BR"
            },
            {
                "name": "Cantagalo",
                "countryCode": "BR"
            },
            {
                "name": "Capanema",
                "countryCode": "BR"
            },
            {
                "name": "Capitão Leônidas Marques",
                "countryCode": "BR"
            },
            {
                "name": "Carambeí",
                "countryCode": "BR"
            },
            {
                "name": "Carlópolis",
                "countryCode": "BR"
            },
            {
                "name": "Cascavel",
                "countryCode": "BR"
            },
            {
                "name": "Castro",
                "countryCode": "BR"
            },
            {
                "name": "Catanduvas",
                "countryCode": "BR"
            },
            {
                "name": "Centenário do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Azul",
                "countryCode": "BR"
            },
            {
                "name": "Chopinzinho",
                "countryCode": "BR"
            },
            {
                "name": "Cianorte",
                "countryCode": "BR"
            },
            {
                "name": "Cidade Gaúcha",
                "countryCode": "BR"
            },
            {
                "name": "Clevelândia",
                "countryCode": "BR"
            },
            {
                "name": "Colombo",
                "countryCode": "BR"
            },
            {
                "name": "Colorado",
                "countryCode": "BR"
            },
            {
                "name": "Congonhinhas",
                "countryCode": "BR"
            },
            {
                "name": "Conselheiro Mairinck",
                "countryCode": "BR"
            },
            {
                "name": "Contenda",
                "countryCode": "BR"
            },
            {
                "name": "Corbélia",
                "countryCode": "BR"
            },
            {
                "name": "Cornélio Procópio",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Domingos Soares",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Vivida",
                "countryCode": "BR"
            },
            {
                "name": "Corumbataí do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cruz Machado",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cruzmaltina",
                "countryCode": "BR"
            },
            {
                "name": "Curitiba",
                "countryCode": "BR"
            },
            {
                "name": "Curiúva",
                "countryCode": "BR"
            },
            {
                "name": "Cândido de Abreu",
                "countryCode": "BR"
            },
            {
                "name": "Céu Azul",
                "countryCode": "BR"
            },
            {
                "name": "Diamante d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Diamante do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Diamante do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Dois Vizinhos",
                "countryCode": "BR"
            },
            {
                "name": "Douradina",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Camargo",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Ulysses",
                "countryCode": "BR"
            },
            {
                "name": "Engenheiro Beltrão",
                "countryCode": "BR"
            },
            {
                "name": "Entre Rios do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Enéas Marques",
                "countryCode": "BR"
            },
            {
                "name": "Esperança Nova",
                "countryCode": "BR"
            },
            {
                "name": "Espigão Alto do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Farol",
                "countryCode": "BR"
            },
            {
                "name": "Faxinal",
                "countryCode": "BR"
            },
            {
                "name": "Fazenda Rio Grande",
                "countryCode": "BR"
            },
            {
                "name": "Fernandes Pinheiro",
                "countryCode": "BR"
            },
            {
                "name": "Figueira",
                "countryCode": "BR"
            },
            {
                "name": "Flor da Serra do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Floraí",
                "countryCode": "BR"
            },
            {
                "name": "Floresta",
                "countryCode": "BR"
            },
            {
                "name": "Florestópolis",
                "countryCode": "BR"
            },
            {
                "name": "Flórida",
                "countryCode": "BR"
            },
            {
                "name": "Formosa do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Foz do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Foz do Jordão",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Alves",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Beltrão",
                "countryCode": "BR"
            },
            {
                "name": "Fênix",
                "countryCode": "BR"
            },
            {
                "name": "General Carneiro",
                "countryCode": "BR"
            },
            {
                "name": "Godoy Moreira",
                "countryCode": "BR"
            },
            {
                "name": "Goioerê",
                "countryCode": "BR"
            },
            {
                "name": "Goioxim",
                "countryCode": "BR"
            },
            {
                "name": "Grandes Rios",
                "countryCode": "BR"
            },
            {
                "name": "Guairaçá",
                "countryCode": "BR"
            },
            {
                "name": "Guamiranga",
                "countryCode": "BR"
            },
            {
                "name": "Guapirama",
                "countryCode": "BR"
            },
            {
                "name": "Guaporema",
                "countryCode": "BR"
            },
            {
                "name": "Guaraci",
                "countryCode": "BR"
            },
            {
                "name": "Guaraniaçu",
                "countryCode": "BR"
            },
            {
                "name": "Guarapuava",
                "countryCode": "BR"
            },
            {
                "name": "Guaraqueçaba",
                "countryCode": "BR"
            },
            {
                "name": "Guaratuba",
                "countryCode": "BR"
            },
            {
                "name": "Guaíra",
                "countryCode": "BR"
            },
            {
                "name": "Honório Serpa",
                "countryCode": "BR"
            },
            {
                "name": "Ibaiti",
                "countryCode": "BR"
            },
            {
                "name": "Ibema",
                "countryCode": "BR"
            },
            {
                "name": "Ibiporã",
                "countryCode": "BR"
            },
            {
                "name": "Icaraíma",
                "countryCode": "BR"
            },
            {
                "name": "Iguaraçu",
                "countryCode": "BR"
            },
            {
                "name": "Iguatu",
                "countryCode": "BR"
            },
            {
                "name": "Imbaú",
                "countryCode": "BR"
            },
            {
                "name": "Imbituva",
                "countryCode": "BR"
            },
            {
                "name": "Inajá",
                "countryCode": "BR"
            },
            {
                "name": "Indianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Inácio Martins",
                "countryCode": "BR"
            },
            {
                "name": "Ipiranga",
                "countryCode": "BR"
            },
            {
                "name": "Iporã",
                "countryCode": "BR"
            },
            {
                "name": "Iracema do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Irati",
                "countryCode": "BR"
            },
            {
                "name": "Iretama",
                "countryCode": "BR"
            },
            {
                "name": "Itaguajé",
                "countryCode": "BR"
            },
            {
                "name": "Itaipulândia",
                "countryCode": "BR"
            },
            {
                "name": "Itambaracá",
                "countryCode": "BR"
            },
            {
                "name": "Itambé",
                "countryCode": "BR"
            },
            {
                "name": "Itapejara d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Itaperuçu",
                "countryCode": "BR"
            },
            {
                "name": "Itaúna do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Ivaiporã",
                "countryCode": "BR"
            },
            {
                "name": "Ivatuba",
                "countryCode": "BR"
            },
            {
                "name": "Ivaté",
                "countryCode": "BR"
            },
            {
                "name": "Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Jaboti",
                "countryCode": "BR"
            },
            {
                "name": "Jacarezinho",
                "countryCode": "BR"
            },
            {
                "name": "Jaguapitã",
                "countryCode": "BR"
            },
            {
                "name": "Jaguariaíva",
                "countryCode": "BR"
            },
            {
                "name": "Jandaia do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Janiópolis",
                "countryCode": "BR"
            },
            {
                "name": "Japira",
                "countryCode": "BR"
            },
            {
                "name": "Japurá",
                "countryCode": "BR"
            },
            {
                "name": "Jardim Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Jardim Olinda",
                "countryCode": "BR"
            },
            {
                "name": "Jataizinho",
                "countryCode": "BR"
            },
            {
                "name": "Jesuítas",
                "countryCode": "BR"
            },
            {
                "name": "Joaquim Távora",
                "countryCode": "BR"
            },
            {
                "name": "Jundiaí do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Juranda",
                "countryCode": "BR"
            },
            {
                "name": "Jussara",
                "countryCode": "BR"
            },
            {
                "name": "Kaloré",
                "countryCode": "BR"
            },
            {
                "name": "Lapa",
                "countryCode": "BR"
            },
            {
                "name": "Laranjal",
                "countryCode": "BR"
            },
            {
                "name": "Laranjeiras do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Leópolis",
                "countryCode": "BR"
            },
            {
                "name": "Lidianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Lindoeste",
                "countryCode": "BR"
            },
            {
                "name": "Loanda",
                "countryCode": "BR"
            },
            {
                "name": "Lobato",
                "countryCode": "BR"
            },
            {
                "name": "Londrina",
                "countryCode": "BR"
            },
            {
                "name": "Luiziana",
                "countryCode": "BR"
            },
            {
                "name": "Lunardelli",
                "countryCode": "BR"
            },
            {
                "name": "Lupionópolis",
                "countryCode": "BR"
            },
            {
                "name": "Mallet",
                "countryCode": "BR"
            },
            {
                "name": "Mamborê",
                "countryCode": "BR"
            },
            {
                "name": "Mandaguari",
                "countryCode": "BR"
            },
            {
                "name": "Mandaguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Mandirituba",
                "countryCode": "BR"
            },
            {
                "name": "Manfrinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Mangueirinha",
                "countryCode": "BR"
            },
            {
                "name": "Manoel Ribas",
                "countryCode": "BR"
            },
            {
                "name": "Marechal Cândido Rondon",
                "countryCode": "BR"
            },
            {
                "name": "Maria Helena",
                "countryCode": "BR"
            },
            {
                "name": "Marialva",
                "countryCode": "BR"
            },
            {
                "name": "Marilena",
                "countryCode": "BR"
            },
            {
                "name": "Mariluz",
                "countryCode": "BR"
            },
            {
                "name": "Marilândia do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Maringá",
                "countryCode": "BR"
            },
            {
                "name": "Maripá",
                "countryCode": "BR"
            },
            {
                "name": "Mariópolis",
                "countryCode": "BR"
            },
            {
                "name": "Marmeleiro",
                "countryCode": "BR"
            },
            {
                "name": "Marquinho",
                "countryCode": "BR"
            },
            {
                "name": "Marumbi",
                "countryCode": "BR"
            },
            {
                "name": "Matelândia",
                "countryCode": "BR"
            },
            {
                "name": "Matinhos",
                "countryCode": "BR"
            },
            {
                "name": "Mato Rico",
                "countryCode": "BR"
            },
            {
                "name": "Mauá da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Medianeira",
                "countryCode": "BR"
            },
            {
                "name": "Mercedes",
                "countryCode": "BR"
            },
            {
                "name": "Mirador",
                "countryCode": "BR"
            },
            {
                "name": "Miraselva",
                "countryCode": "BR"
            },
            {
                "name": "Missal",
                "countryCode": "BR"
            },
            {
                "name": "Moreira Sales",
                "countryCode": "BR"
            },
            {
                "name": "Morretes",
                "countryCode": "BR"
            },
            {
                "name": "Munhoz de Melo",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora das Graças",
                "countryCode": "BR"
            },
            {
                "name": "Nova Aliança do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Nova América da Colina",
                "countryCode": "BR"
            },
            {
                "name": "Nova Aurora",
                "countryCode": "BR"
            },
            {
                "name": "Nova Cantu",
                "countryCode": "BR"
            },
            {
                "name": "Nova Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Nova Esperança do Sudoeste",
                "countryCode": "BR"
            },
            {
                "name": "Nova Fátima",
                "countryCode": "BR"
            },
            {
                "name": "Nova Laranjeiras",
                "countryCode": "BR"
            },
            {
                "name": "Nova Londrina",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olímpia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Prata do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Nova Santa Bárbara",
                "countryCode": "BR"
            },
            {
                "name": "Nova Santa Rosa",
                "countryCode": "BR"
            },
            {
                "name": "Nova Tebas",
                "countryCode": "BR"
            },
            {
                "name": "Novo Itacolomi",
                "countryCode": "BR"
            },
            {
                "name": "Ortigueira",
                "countryCode": "BR"
            },
            {
                "name": "Ourizona",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Verde do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Paiçandu",
                "countryCode": "BR"
            },
            {
                "name": "Palmas",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira",
                "countryCode": "BR"
            },
            {
                "name": "Palmital",
                "countryCode": "BR"
            },
            {
                "name": "Palotina",
                "countryCode": "BR"
            },
            {
                "name": "Paranacity",
                "countryCode": "BR"
            },
            {
                "name": "Paranaguá",
                "countryCode": "BR"
            },
            {
                "name": "Paranapoema",
                "countryCode": "BR"
            },
            {
                "name": "Paranavaí",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Pato Bragado",
                "countryCode": "BR"
            },
            {
                "name": "Pato Branco",
                "countryCode": "BR"
            },
            {
                "name": "Paula Freitas",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Frontin",
                "countryCode": "BR"
            },
            {
                "name": "Peabiru",
                "countryCode": "BR"
            },
            {
                "name": "Perobal",
                "countryCode": "BR"
            },
            {
                "name": "Pinhais",
                "countryCode": "BR"
            },
            {
                "name": "Pinhal de São Bento",
                "countryCode": "BR"
            },
            {
                "name": "Pinhalão",
                "countryCode": "BR"
            },
            {
                "name": "Pinhão",
                "countryCode": "BR"
            },
            {
                "name": "Piraquara",
                "countryCode": "BR"
            },
            {
                "name": "Piraí do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Pitanga",
                "countryCode": "BR"
            },
            {
                "name": "Pitangueiras",
                "countryCode": "BR"
            },
            {
                "name": "Piên",
                "countryCode": "BR"
            },
            {
                "name": "Planaltina do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Ponta Grossa",
                "countryCode": "BR"
            },
            {
                "name": "Pontal do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Porecatu",
                "countryCode": "BR"
            },
            {
                "name": "Porto Amazonas",
                "countryCode": "BR"
            },
            {
                "name": "Porto Barreiro",
                "countryCode": "BR"
            },
            {
                "name": "Porto Rico",
                "countryCode": "BR"
            },
            {
                "name": "Porto Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Prado Ferreira",
                "countryCode": "BR"
            },
            {
                "name": "Pranchita",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Castelo Branco",
                "countryCode": "BR"
            },
            {
                "name": "Primeiro de Maio",
                "countryCode": "BR"
            },
            {
                "name": "Prudentópolis",
                "countryCode": "BR"
            },
            {
                "name": "Pérola",
                "countryCode": "BR"
            },
            {
                "name": "Pérola d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Quarto Centenário",
                "countryCode": "BR"
            },
            {
                "name": "Quatiguá",
                "countryCode": "BR"
            },
            {
                "name": "Quatro Barras",
                "countryCode": "BR"
            },
            {
                "name": "Quatro Pontes",
                "countryCode": "BR"
            },
            {
                "name": "Quedas do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Querência do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Quinta do Sol",
                "countryCode": "BR"
            },
            {
                "name": "Quitandinha",
                "countryCode": "BR"
            },
            {
                "name": "Ramilândia",
                "countryCode": "BR"
            },
            {
                "name": "Rancho Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Rancho Alegre d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Realeza",
                "countryCode": "BR"
            },
            {
                "name": "Rebouças",
                "countryCode": "BR"
            },
            {
                "name": "Renascença",
                "countryCode": "BR"
            },
            {
                "name": "Reserva",
                "countryCode": "BR"
            },
            {
                "name": "Reserva do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Claro",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão do Pinhal",
                "countryCode": "BR"
            },
            {
                "name": "Rio Azul",
                "countryCode": "BR"
            },
            {
                "name": "Rio Bom",
                "countryCode": "BR"
            },
            {
                "name": "Rio Bonito do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Rio Branco do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Rio Branco do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Rio Negro",
                "countryCode": "BR"
            },
            {
                "name": "Rolândia",
                "countryCode": "BR"
            },
            {
                "name": "Roncador",
                "countryCode": "BR"
            },
            {
                "name": "Rondon",
                "countryCode": "BR"
            },
            {
                "name": "Rosário do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Sabáudia",
                "countryCode": "BR"
            },
            {
                "name": "Salgado Filho",
                "countryCode": "BR"
            },
            {
                "name": "Salto do Itararé",
                "countryCode": "BR"
            },
            {
                "name": "Salto do Lontra",
                "countryCode": "BR"
            },
            {
                "name": "Santa Amélia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cecília do Pavão",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz de Monte Castelo",
                "countryCode": "BR"
            },
            {
                "name": "Santa Fé",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Inês",
                "countryCode": "BR"
            },
            {
                "name": "Santa Isabel do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "Santa Izabel do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Lúcia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Mariana",
                "countryCode": "BR"
            },
            {
                "name": "Santa Mônica",
                "countryCode": "BR"
            },
            {
                "name": "Santa Tereza do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha de Itaipu",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Itararé",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio da Platina",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Caiuá",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Sudoeste",
                "countryCode": "BR"
            },
            {
                "name": "Santo Inácio",
                "countryCode": "BR"
            },
            {
                "name": "Sapopema",
                "countryCode": "BR"
            },
            {
                "name": "Sarandi",
                "countryCode": "BR"
            },
            {
                "name": "Saudade do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Sengés",
                "countryCode": "BR"
            },
            {
                "name": "Serranópolis do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Sertaneja",
                "countryCode": "BR"
            },
            {
                "name": "Sertanópolis",
                "countryCode": "BR"
            },
            {
                "name": "Siqueira Campos",
                "countryCode": "BR"
            },
            {
                "name": "Sulina",
                "countryCode": "BR"
            },
            {
                "name": "São Carlos do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "São Jerônimo da Serra",
                "countryCode": "BR"
            },
            {
                "name": "São Jorge d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Jorge do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "São Jorge do Patrocínio",
                "countryCode": "BR"
            },
            {
                "name": "São José da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "São José das Palmeiras",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Pinhais",
                "countryCode": "BR"
            },
            {
                "name": "São João",
                "countryCode": "BR"
            },
            {
                "name": "São João do Caiuá",
                "countryCode": "BR"
            },
            {
                "name": "São João do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "São João do Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "São Manoel do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "São Mateus do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Ivaí",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião da Amoreira",
                "countryCode": "BR"
            },
            {
                "name": "São Tomé",
                "countryCode": "BR"
            },
            {
                "name": "Tamarana",
                "countryCode": "BR"
            },
            {
                "name": "Tamboara",
                "countryCode": "BR"
            },
            {
                "name": "Tapejara",
                "countryCode": "BR"
            },
            {
                "name": "Tapira",
                "countryCode": "BR"
            },
            {
                "name": "Teixeira Soares",
                "countryCode": "BR"
            },
            {
                "name": "Telêmaco Borba",
                "countryCode": "BR"
            },
            {
                "name": "Terra Boa",
                "countryCode": "BR"
            },
            {
                "name": "Terra Rica",
                "countryCode": "BR"
            },
            {
                "name": "Terra Roxa",
                "countryCode": "BR"
            },
            {
                "name": "Tibagi",
                "countryCode": "BR"
            },
            {
                "name": "Tijucas do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Toledo",
                "countryCode": "BR"
            },
            {
                "name": "Tomazina",
                "countryCode": "BR"
            },
            {
                "name": "Três Barras do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Tunas do Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Tuneiras do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Tupãssi",
                "countryCode": "BR"
            },
            {
                "name": "Turvo",
                "countryCode": "BR"
            },
            {
                "name": "Ubiratã",
                "countryCode": "BR"
            },
            {
                "name": "Umuarama",
                "countryCode": "BR"
            },
            {
                "name": "Uniflor",
                "countryCode": "BR"
            },
            {
                "name": "União da Vitória",
                "countryCode": "BR"
            },
            {
                "name": "Uraí",
                "countryCode": "BR"
            },
            {
                "name": "Ventania",
                "countryCode": "BR"
            },
            {
                "name": "Vera Cruz do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Verê",
                "countryCode": "BR"
            },
            {
                "name": "Virmond",
                "countryCode": "BR"
            },
            {
                "name": "Vitorino",
                "countryCode": "BR"
            },
            {
                "name": "Wenceslau Braz",
                "countryCode": "BR"
            },
            {
                "name": "Xambrê",
                "countryCode": "BR"
            },
            {
                "name": "Ângulo",
                "countryCode": "BR"
            },
            {
                "name": "Angra dos Reis",
                "countryCode": "BR"
            },
            {
                "name": "Aperibé",
                "countryCode": "BR"
            },
            {
                "name": "Araruama",
                "countryCode": "BR"
            },
            {
                "name": "Areal",
                "countryCode": "BR"
            },
            {
                "name": "Armação dos Búzios",
                "countryCode": "BR"
            },
            {
                "name": "Arraial do Cabo",
                "countryCode": "BR"
            },
            {
                "name": "Barra Mansa",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Piraí",
                "countryCode": "BR"
            },
            {
                "name": "Belford Roxo",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Itabapoana",
                "countryCode": "BR"
            },
            {
                "name": "Cabo Frio",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeiras de Macacu",
                "countryCode": "BR"
            },
            {
                "name": "Cambuci",
                "countryCode": "BR"
            },
            {
                "name": "Campos dos Goytacazes",
                "countryCode": "BR"
            },
            {
                "name": "Cantagalo",
                "countryCode": "BR"
            },
            {
                "name": "Carapebus",
                "countryCode": "BR"
            },
            {
                "name": "Cardoso Moreira",
                "countryCode": "BR"
            },
            {
                "name": "Carmo",
                "countryCode": "BR"
            },
            {
                "name": "Casimiro de Abreu",
                "countryCode": "BR"
            },
            {
                "name": "Comendador Levy Gasparian",
                "countryCode": "BR"
            },
            {
                "name": "Conceição de Macabu",
                "countryCode": "BR"
            },
            {
                "name": "Cordeiro",
                "countryCode": "BR"
            },
            {
                "name": "Duas Barras",
                "countryCode": "BR"
            },
            {
                "name": "Duque de Caxias",
                "countryCode": "BR"
            },
            {
                "name": "Engenheiro Paulo de Frontin",
                "countryCode": "BR"
            },
            {
                "name": "Guapimirim",
                "countryCode": "BR"
            },
            {
                "name": "Iguaba Grande",
                "countryCode": "BR"
            },
            {
                "name": "Ilha Grande",
                "countryCode": "BR"
            },
            {
                "name": "Itaboraí",
                "countryCode": "BR"
            },
            {
                "name": "Itaguaí",
                "countryCode": "BR"
            },
            {
                "name": "Italva",
                "countryCode": "BR"
            },
            {
                "name": "Itaocara",
                "countryCode": "BR"
            },
            {
                "name": "Itaperuna",
                "countryCode": "BR"
            },
            {
                "name": "Itatiaia",
                "countryCode": "BR"
            },
            {
                "name": "Japeri",
                "countryCode": "BR"
            },
            {
                "name": "Laje do Muriaé",
                "countryCode": "BR"
            },
            {
                "name": "Macaé",
                "countryCode": "BR"
            },
            {
                "name": "Macuco",
                "countryCode": "BR"
            },
            {
                "name": "Magé",
                "countryCode": "BR"
            },
            {
                "name": "Mangaratiba",
                "countryCode": "BR"
            },
            {
                "name": "Maricá",
                "countryCode": "BR"
            },
            {
                "name": "Mendes",
                "countryCode": "BR"
            },
            {
                "name": "Mesquita",
                "countryCode": "BR"
            },
            {
                "name": "Miguel Pereira",
                "countryCode": "BR"
            },
            {
                "name": "Miracema",
                "countryCode": "BR"
            },
            {
                "name": "Natividade",
                "countryCode": "BR"
            },
            {
                "name": "Nilópolis",
                "countryCode": "BR"
            },
            {
                "name": "Niterói",
                "countryCode": "BR"
            },
            {
                "name": "Nova Friburgo",
                "countryCode": "BR"
            },
            {
                "name": "Nova Iguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Paracambi",
                "countryCode": "BR"
            },
            {
                "name": "Paraty",
                "countryCode": "BR"
            },
            {
                "name": "Paraíba do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Paty do Alferes",
                "countryCode": "BR"
            },
            {
                "name": "Petrópolis",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiral",
                "countryCode": "BR"
            },
            {
                "name": "Piraí",
                "countryCode": "BR"
            },
            {
                "name": "Porciúncula",
                "countryCode": "BR"
            },
            {
                "name": "Porto Real",
                "countryCode": "BR"
            },
            {
                "name": "Quatis",
                "countryCode": "BR"
            },
            {
                "name": "Queimados",
                "countryCode": "BR"
            },
            {
                "name": "Quissamã",
                "countryCode": "BR"
            },
            {
                "name": "Resende",
                "countryCode": "BR"
            },
            {
                "name": "Rio Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Rio Claro",
                "countryCode": "BR"
            },
            {
                "name": "Rio das Flores",
                "countryCode": "BR"
            },
            {
                "name": "Rio das Ostras",
                "countryCode": "BR"
            },
            {
                "name": "Rio de Janeiro",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria Madalena",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio de Pádua",
                "countryCode": "BR"
            },
            {
                "name": "Sapucaia",
                "countryCode": "BR"
            },
            {
                "name": "Saquarema",
                "countryCode": "BR"
            },
            {
                "name": "Seropédica",
                "countryCode": "BR"
            },
            {
                "name": "Silva Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Sumidouro",
                "countryCode": "BR"
            },
            {
                "name": "São Fidélis",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Itabapoana",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo",
                "countryCode": "BR"
            },
            {
                "name": "São José de Ubá",
                "countryCode": "BR"
            },
            {
                "name": "São José do Vale do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "São João da Barra",
                "countryCode": "BR"
            },
            {
                "name": "São João de Meriti",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro da Aldeia",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Alto",
                "countryCode": "BR"
            },
            {
                "name": "Tanguá",
                "countryCode": "BR"
            },
            {
                "name": "Teresópolis",
                "countryCode": "BR"
            },
            {
                "name": "Trajano de Moraes",
                "countryCode": "BR"
            },
            {
                "name": "Três Rios",
                "countryCode": "BR"
            },
            {
                "name": "Valença",
                "countryCode": "BR"
            },
            {
                "name": "Varre-Sai",
                "countryCode": "BR"
            },
            {
                "name": "Vassouras",
                "countryCode": "BR"
            },
            {
                "name": "Volta Redonda",
                "countryCode": "BR"
            },
            {
                "name": "Acari",
                "countryCode": "BR"
            },
            {
                "name": "Afonso Bezerra",
                "countryCode": "BR"
            },
            {
                "name": "Alexandria",
                "countryCode": "BR"
            },
            {
                "name": "Almino Afonso",
                "countryCode": "BR"
            },
            {
                "name": "Alto do Rodrigues",
                "countryCode": "BR"
            },
            {
                "name": "Angicos",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Martins",
                "countryCode": "BR"
            },
            {
                "name": "Apodi",
                "countryCode": "BR"
            },
            {
                "name": "Areia Branca",
                "countryCode": "BR"
            },
            {
                "name": "Arês",
                "countryCode": "BR"
            },
            {
                "name": "Augusto Severo",
                "countryCode": "BR"
            },
            {
                "name": "Açu",
                "countryCode": "BR"
            },
            {
                "name": "Baraúna",
                "countryCode": "BR"
            },
            {
                "name": "Barcelona",
                "countryCode": "BR"
            },
            {
                "name": "Baía Formosa",
                "countryCode": "BR"
            },
            {
                "name": "Bento Fernandes",
                "countryCode": "BR"
            },
            {
                "name": "Bodó",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Brejinho",
                "countryCode": "BR"
            },
            {
                "name": "Caicó",
                "countryCode": "BR"
            },
            {
                "name": "Caiçara do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Caiçara do Rio do Vento",
                "countryCode": "BR"
            },
            {
                "name": "Campo Redondo",
                "countryCode": "BR"
            },
            {
                "name": "Canguaretama",
                "countryCode": "BR"
            },
            {
                "name": "Caraúbas",
                "countryCode": "BR"
            },
            {
                "name": "Carnaubais",
                "countryCode": "BR"
            },
            {
                "name": "Carnaúba dos Dantas",
                "countryCode": "BR"
            },
            {
                "name": "Ceará Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Ceará-Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Corá",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Ezequiel",
                "countryCode": "BR"
            },
            {
                "name": "Coronel João Pessoa",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeta",
                "countryCode": "BR"
            },
            {
                "name": "Currais Novos",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Severiano",
                "countryCode": "BR"
            },
            {
                "name": "Encanto",
                "countryCode": "BR"
            },
            {
                "name": "Equador",
                "countryCode": "BR"
            },
            {
                "name": "Espírito Santo",
                "countryCode": "BR"
            },
            {
                "name": "Extremoz",
                "countryCode": "BR"
            },
            {
                "name": "Felipe Guerra",
                "countryCode": "BR"
            },
            {
                "name": "Fernando Pedroza",
                "countryCode": "BR"
            },
            {
                "name": "Florânia",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Dantas",
                "countryCode": "BR"
            },
            {
                "name": "Frutuoso Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Galinhos",
                "countryCode": "BR"
            },
            {
                "name": "Goianinha",
                "countryCode": "BR"
            },
            {
                "name": "Governador Dix Sept Rosado",
                "countryCode": "BR"
            },
            {
                "name": "Governador Dix-Sept Rosado",
                "countryCode": "BR"
            },
            {
                "name": "Grossos",
                "countryCode": "BR"
            },
            {
                "name": "Guamaré",
                "countryCode": "BR"
            },
            {
                "name": "Ielmo Marinho",
                "countryCode": "BR"
            },
            {
                "name": "Ipanguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ipueira",
                "countryCode": "BR"
            },
            {
                "name": "Itajá",
                "countryCode": "BR"
            },
            {
                "name": "Itaú",
                "countryCode": "BR"
            },
            {
                "name": "Jandaíra",
                "countryCode": "BR"
            },
            {
                "name": "Janduís",
                "countryCode": "BR"
            },
            {
                "name": "Januário Cicco",
                "countryCode": "BR"
            },
            {
                "name": "Japi",
                "countryCode": "BR"
            },
            {
                "name": "Jardim de Angicos",
                "countryCode": "BR"
            },
            {
                "name": "Jardim de Piranhas",
                "countryCode": "BR"
            },
            {
                "name": "Jardim do Seridó",
                "countryCode": "BR"
            },
            {
                "name": "Jaçanã",
                "countryCode": "BR"
            },
            {
                "name": "José da Penha",
                "countryCode": "BR"
            },
            {
                "name": "João Câmara",
                "countryCode": "BR"
            },
            {
                "name": "João Dias",
                "countryCode": "BR"
            },
            {
                "name": "Jucurutu",
                "countryCode": "BR"
            },
            {
                "name": "Jundiá",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Nova",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Salgada",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa d'Anta",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa de Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa de Velhos",
                "countryCode": "BR"
            },
            {
                "name": "Lajes",
                "countryCode": "BR"
            },
            {
                "name": "Lajes Pintadas",
                "countryCode": "BR"
            },
            {
                "name": "Lucrécia",
                "countryCode": "BR"
            },
            {
                "name": "Luís Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Macau",
                "countryCode": "BR"
            },
            {
                "name": "Macaíba",
                "countryCode": "BR"
            },
            {
                "name": "Major Sales",
                "countryCode": "BR"
            },
            {
                "name": "Marcelino Vieira",
                "countryCode": "BR"
            },
            {
                "name": "Martins",
                "countryCode": "BR"
            },
            {
                "name": "Maxaranguape",
                "countryCode": "BR"
            },
            {
                "name": "Messias Targino",
                "countryCode": "BR"
            },
            {
                "name": "Montanhas",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Monte das Gameleiras",
                "countryCode": "BR"
            },
            {
                "name": "Mossoró",
                "countryCode": "BR"
            },
            {
                "name": "Natal",
                "countryCode": "BR"
            },
            {
                "name": "Nova Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Nísia Floresta",
                "countryCode": "BR"
            },
            {
                "name": "Olho-d'Água do Borges",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Branco",
                "countryCode": "BR"
            },
            {
                "name": "Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Parazinho",
                "countryCode": "BR"
            },
            {
                "name": "Paraú",
                "countryCode": "BR"
            },
            {
                "name": "Parelhas",
                "countryCode": "BR"
            },
            {
                "name": "Parnamirim",
                "countryCode": "BR"
            },
            {
                "name": "Passa e Fica",
                "countryCode": "BR"
            },
            {
                "name": "Passagem",
                "countryCode": "BR"
            },
            {
                "name": "Patu",
                "countryCode": "BR"
            },
            {
                "name": "Pau dos Ferros",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Grande",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Preta",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Avelino",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Velho",
                "countryCode": "BR"
            },
            {
                "name": "Pendências",
                "countryCode": "BR"
            },
            {
                "name": "Pilões",
                "countryCode": "BR"
            },
            {
                "name": "Portalegre",
                "countryCode": "BR"
            },
            {
                "name": "Porto do Mangue",
                "countryCode": "BR"
            },
            {
                "name": "Poço Branco",
                "countryCode": "BR"
            },
            {
                "name": "Pureza",
                "countryCode": "BR"
            },
            {
                "name": "Rafael Fernandes",
                "countryCode": "BR"
            },
            {
                "name": "Rafael Godeiro",
                "countryCode": "BR"
            },
            {
                "name": "Riacho da Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Riacho de Santana",
                "countryCode": "BR"
            },
            {
                "name": "Riachuelo",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Fogo",
                "countryCode": "BR"
            },
            {
                "name": "Rodolfo Fernandes",
                "countryCode": "BR"
            },
            {
                "name": "Ruy Barbosa",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Matos",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Seridó",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Senador Elói de Souza",
                "countryCode": "BR"
            },
            {
                "name": "Senador Georgino Avelino",
                "countryCode": "BR"
            },
            {
                "name": "Serra Caiada",
                "countryCode": "BR"
            },
            {
                "name": "Serra Negra do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Serra de São Bento",
                "countryCode": "BR"
            },
            {
                "name": "Serra do Mel",
                "countryCode": "BR"
            },
            {
                "name": "Serrinha",
                "countryCode": "BR"
            },
            {
                "name": "Serrinha dos Pintos",
                "countryCode": "BR"
            },
            {
                "name": "Severiano Melo",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Norte",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Trairí",
                "countryCode": "BR"
            },
            {
                "name": "São Fernando",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Gonçalo do Amarante",
                "countryCode": "BR"
            },
            {
                "name": "São José de Mipibu",
                "countryCode": "BR"
            },
            {
                "name": "São José do Campestre",
                "countryCode": "BR"
            },
            {
                "name": "São José do Seridó",
                "countryCode": "BR"
            },
            {
                "name": "São João do Sabugi",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Gostoso",
                "countryCode": "BR"
            },
            {
                "name": "São Paulo do Potengi",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro",
                "countryCode": "BR"
            },
            {
                "name": "São Rafael",
                "countryCode": "BR"
            },
            {
                "name": "São Tomé",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente",
                "countryCode": "BR"
            },
            {
                "name": "Sítio Novo",
                "countryCode": "BR"
            },
            {
                "name": "Taboleiro Grande",
                "countryCode": "BR"
            },
            {
                "name": "Taipu",
                "countryCode": "BR"
            },
            {
                "name": "Tangará",
                "countryCode": "BR"
            },
            {
                "name": "Tenente Ananias",
                "countryCode": "BR"
            },
            {
                "name": "Tenente Laurentino Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Tibau",
                "countryCode": "BR"
            },
            {
                "name": "Tibau do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Timbaúba dos Batistas",
                "countryCode": "BR"
            },
            {
                "name": "Touros",
                "countryCode": "BR"
            },
            {
                "name": "Triunfo Potiguar",
                "countryCode": "BR"
            },
            {
                "name": "Umarizal",
                "countryCode": "BR"
            },
            {
                "name": "Upanema",
                "countryCode": "BR"
            },
            {
                "name": "Venha-Ver",
                "countryCode": "BR"
            },
            {
                "name": "Vera Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Vila Flor",
                "countryCode": "BR"
            },
            {
                "name": "Viçosa",
                "countryCode": "BR"
            },
            {
                "name": "Várzea",
                "countryCode": "BR"
            },
            {
                "name": "Água Nova",
                "countryCode": "BR"
            },
            {
                "name": "Alta Floresta d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Alto Alegre dos Parecis",
                "countryCode": "BR"
            },
            {
                "name": "Alto Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Ariquemes",
                "countryCode": "BR"
            },
            {
                "name": "Buritis",
                "countryCode": "BR"
            },
            {
                "name": "Cabixi",
                "countryCode": "BR"
            },
            {
                "name": "Cacaulândia",
                "countryCode": "BR"
            },
            {
                "name": "Cacoal",
                "countryCode": "BR"
            },
            {
                "name": "Campo Novo de Rondônia",
                "countryCode": "BR"
            },
            {
                "name": "Candeias do Jamari",
                "countryCode": "BR"
            },
            {
                "name": "Castanheiras",
                "countryCode": "BR"
            },
            {
                "name": "Cerejeiras",
                "countryCode": "BR"
            },
            {
                "name": "Chupinguaia",
                "countryCode": "BR"
            },
            {
                "name": "Colorado do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Corumbiara",
                "countryCode": "BR"
            },
            {
                "name": "Costa Marques",
                "countryCode": "BR"
            },
            {
                "name": "Cujubim",
                "countryCode": "BR"
            },
            {
                "name": "Espigão d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Extrema",
                "countryCode": "BR"
            },
            {
                "name": "Governador Jorge Teixeira",
                "countryCode": "BR"
            },
            {
                "name": "Guajará Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Guajará-Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Itapuã do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Jaru",
                "countryCode": "BR"
            },
            {
                "name": "Ji Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Ji-Paraná",
                "countryCode": "BR"
            },
            {
                "name": "Machadinho d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Ministro Andreazza",
                "countryCode": "BR"
            },
            {
                "name": "Mirante da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Monte Negro",
                "countryCode": "BR"
            },
            {
                "name": "Nova Brasilândia d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Nova Mamoré",
                "countryCode": "BR"
            },
            {
                "name": "Nova União",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Preto do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Parecis",
                "countryCode": "BR"
            },
            {
                "name": "Pimenta Bueno",
                "countryCode": "BR"
            },
            {
                "name": "Pimenteiras do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Porto Velho",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Médici",
                "countryCode": "BR"
            },
            {
                "name": "Primavera de Rondônia",
                "countryCode": "BR"
            },
            {
                "name": "Pôsto Fiscal Rolim de Moura",
                "countryCode": "BR"
            },
            {
                "name": "Rio Crespo",
                "countryCode": "BR"
            },
            {
                "name": "Rolim de Moura",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Seringueiras",
                "countryCode": "BR"
            },
            {
                "name": "São Felipe d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Guaporé",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Guaporé",
                "countryCode": "BR"
            },
            {
                "name": "Teixeirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Theobroma",
                "countryCode": "BR"
            },
            {
                "name": "Urupá",
                "countryCode": "BR"
            },
            {
                "name": "Vale do Anari",
                "countryCode": "BR"
            },
            {
                "name": "Vale do Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Vilhena",
                "countryCode": "BR"
            },
            {
                "name": "Amajari",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Bonfim",
                "countryCode": "BR"
            },
            {
                "name": "Cantá",
                "countryCode": "BR"
            },
            {
                "name": "Caracaraí",
                "countryCode": "BR"
            },
            {
                "name": "Caroebe",
                "countryCode": "BR"
            },
            {
                "name": "Iracema",
                "countryCode": "BR"
            },
            {
                "name": "Mucajaí",
                "countryCode": "BR"
            },
            {
                "name": "Normandia",
                "countryCode": "BR"
            },
            {
                "name": "Pacaraima",
                "countryCode": "BR"
            },
            {
                "name": "Rorainópolis",
                "countryCode": "BR"
            },
            {
                "name": "São João da Baliza",
                "countryCode": "BR"
            },
            {
                "name": "São Luís",
                "countryCode": "BR"
            },
            {
                "name": "Uiramutã",
                "countryCode": "BR"
            },
            {
                "name": "Aceguá",
                "countryCode": "BR"
            },
            {
                "name": "Agudo",
                "countryCode": "BR"
            },
            {
                "name": "Ajuricaba",
                "countryCode": "BR"
            },
            {
                "name": "Alecrim",
                "countryCode": "BR"
            },
            {
                "name": "Alegrete",
                "countryCode": "BR"
            },
            {
                "name": "Alegria",
                "countryCode": "BR"
            },
            {
                "name": "Almirante Tamandaré do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Alpestre",
                "countryCode": "BR"
            },
            {
                "name": "Alto Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Alto Feliz",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada",
                "countryCode": "BR"
            },
            {
                "name": "Amaral Ferrador",
                "countryCode": "BR"
            },
            {
                "name": "Ametista do Sul",
                "countryCode": "BR"
            },
            {
                "name": "André da Rocha",
                "countryCode": "BR"
            },
            {
                "name": "Anta Gorda",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Prado",
                "countryCode": "BR"
            },
            {
                "name": "Arambaré",
                "countryCode": "BR"
            },
            {
                "name": "Araricá",
                "countryCode": "BR"
            },
            {
                "name": "Aratiba",
                "countryCode": "BR"
            },
            {
                "name": "Arroio Grande",
                "countryCode": "BR"
            },
            {
                "name": "Arroio do Meio",
                "countryCode": "BR"
            },
            {
                "name": "Arroio do Padre",
                "countryCode": "BR"
            },
            {
                "name": "Arroio do Sal",
                "countryCode": "BR"
            },
            {
                "name": "Arroio do Tigre",
                "countryCode": "BR"
            },
            {
                "name": "Arroio dos Ratos",
                "countryCode": "BR"
            },
            {
                "name": "Arvorezinha",
                "countryCode": "BR"
            },
            {
                "name": "Augusto Pestana",
                "countryCode": "BR"
            },
            {
                "name": "Bagé",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Pinhal",
                "countryCode": "BR"
            },
            {
                "name": "Barra Funda",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Guarita",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Quaraí",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Ribeiro",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Rio Azul",
                "countryCode": "BR"
            },
            {
                "name": "Barracão",
                "countryCode": "BR"
            },
            {
                "name": "Barros Cassal",
                "countryCode": "BR"
            },
            {
                "name": "Barão",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Cotegipe",
                "countryCode": "BR"
            },
            {
                "name": "Barão do Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "Benjamin Constant do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bento Gonçalves",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Buricá",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Cadeado",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Incra",
                "countryCode": "BR"
            },
            {
                "name": "Boa Vista do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Bom Princípio",
                "countryCode": "BR"
            },
            {
                "name": "Bom Progresso",
                "countryCode": "BR"
            },
            {
                "name": "Bom Retiro do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Boqueirão do Leão",
                "countryCode": "BR"
            },
            {
                "name": "Bossoroca",
                "countryCode": "BR"
            },
            {
                "name": "Bozano",
                "countryCode": "BR"
            },
            {
                "name": "Braga",
                "countryCode": "BR"
            },
            {
                "name": "Brochier",
                "countryCode": "BR"
            },
            {
                "name": "Butiá",
                "countryCode": "BR"
            },
            {
                "name": "Cacequi",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeirinha",
                "countryCode": "BR"
            },
            {
                "name": "Cacique Doble",
                "countryCode": "BR"
            },
            {
                "name": "Caibaté",
                "countryCode": "BR"
            },
            {
                "name": "Caiçara",
                "countryCode": "BR"
            },
            {
                "name": "Camaquã",
                "countryCode": "BR"
            },
            {
                "name": "Camargo",
                "countryCode": "BR"
            },
            {
                "name": "Cambará do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Campestre da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Campina das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Campinas do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Campo Bom",
                "countryCode": "BR"
            },
            {
                "name": "Campo Novo",
                "countryCode": "BR"
            },
            {
                "name": "Campos Borges",
                "countryCode": "BR"
            },
            {
                "name": "Candelária",
                "countryCode": "BR"
            },
            {
                "name": "Candiota",
                "countryCode": "BR"
            },
            {
                "name": "Canela",
                "countryCode": "BR"
            },
            {
                "name": "Canguçu",
                "countryCode": "BR"
            },
            {
                "name": "Canoas",
                "countryCode": "BR"
            },
            {
                "name": "Canudos do Vale",
                "countryCode": "BR"
            },
            {
                "name": "Capela de Santana",
                "countryCode": "BR"
            },
            {
                "name": "Capitão",
                "countryCode": "BR"
            },
            {
                "name": "Capivari do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Capão Bonito do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Capão da Canoa",
                "countryCode": "BR"
            },
            {
                "name": "Capão do Cipó",
                "countryCode": "BR"
            },
            {
                "name": "Capão do Leão",
                "countryCode": "BR"
            },
            {
                "name": "Carazinho",
                "countryCode": "BR"
            },
            {
                "name": "Caraá",
                "countryCode": "BR"
            },
            {
                "name": "Carlos Barbosa",
                "countryCode": "BR"
            },
            {
                "name": "Carlos Gomes",
                "countryCode": "BR"
            },
            {
                "name": "Casca",
                "countryCode": "BR"
            },
            {
                "name": "Caseiros",
                "countryCode": "BR"
            },
            {
                "name": "Catuípe",
                "countryCode": "BR"
            },
            {
                "name": "Caxias do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Caçapava do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Centenário",
                "countryCode": "BR"
            },
            {
                "name": "Cerrito",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Branco",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Grande",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Grande do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Largo",
                "countryCode": "BR"
            },
            {
                "name": "Chapada",
                "countryCode": "BR"
            },
            {
                "name": "Charqueadas",
                "countryCode": "BR"
            },
            {
                "name": "Charrua",
                "countryCode": "BR"
            },
            {
                "name": "Chiapetta",
                "countryCode": "BR"
            },
            {
                "name": "Chuvisca",
                "countryCode": "BR"
            },
            {
                "name": "Chuí",
                "countryCode": "BR"
            },
            {
                "name": "Cidreira",
                "countryCode": "BR"
            },
            {
                "name": "Ciríaco",
                "countryCode": "BR"
            },
            {
                "name": "Colinas",
                "countryCode": "BR"
            },
            {
                "name": "Colorado",
                "countryCode": "BR"
            },
            {
                "name": "Condor",
                "countryCode": "BR"
            },
            {
                "name": "Constantina",
                "countryCode": "BR"
            },
            {
                "name": "Coqueiro Baixo",
                "countryCode": "BR"
            },
            {
                "name": "Coqueiros do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Barros",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Bicaco",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Pilar",
                "countryCode": "BR"
            },
            {
                "name": "Cotiporã",
                "countryCode": "BR"
            },
            {
                "name": "Coxilha",
                "countryCode": "BR"
            },
            {
                "name": "Crissiumal",
                "countryCode": "BR"
            },
            {
                "name": "Cristal",
                "countryCode": "BR"
            },
            {
                "name": "Cristal do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cruz Alta",
                "countryCode": "BR"
            },
            {
                "name": "Cruzaltense",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Cândido Godói",
                "countryCode": "BR"
            },
            {
                "name": "David Canabarro",
                "countryCode": "BR"
            },
            {
                "name": "Derrubadas",
                "countryCode": "BR"
            },
            {
                "name": "Dezesseis de Novembro",
                "countryCode": "BR"
            },
            {
                "name": "Dilermando de Aguiar",
                "countryCode": "BR"
            },
            {
                "name": "Dois Irmãos",
                "countryCode": "BR"
            },
            {
                "name": "Dois Irmãos das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Dois Lajeados",
                "countryCode": "BR"
            },
            {
                "name": "Dom Feliciano",
                "countryCode": "BR"
            },
            {
                "name": "Dom Pedrito",
                "countryCode": "BR"
            },
            {
                "name": "Dom Pedro de Alcântara",
                "countryCode": "BR"
            },
            {
                "name": "Dona Francisca",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Maurício Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Ricardo",
                "countryCode": "BR"
            },
            {
                "name": "Eldorado do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Encantado",
                "countryCode": "BR"
            },
            {
                "name": "Encruzilhada do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Engenho Velho",
                "countryCode": "BR"
            },
            {
                "name": "Entre Rios do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Entre-Ijuís",
                "countryCode": "BR"
            },
            {
                "name": "Erebango",
                "countryCode": "BR"
            },
            {
                "name": "Erechim",
                "countryCode": "BR"
            },
            {
                "name": "Ernestina",
                "countryCode": "BR"
            },
            {
                "name": "Erval Grande",
                "countryCode": "BR"
            },
            {
                "name": "Erval Seco",
                "countryCode": "BR"
            },
            {
                "name": "Esmeralda",
                "countryCode": "BR"
            },
            {
                "name": "Esperança do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Espumoso",
                "countryCode": "BR"
            },
            {
                "name": "Estação",
                "countryCode": "BR"
            },
            {
                "name": "Esteio",
                "countryCode": "BR"
            },
            {
                "name": "Estrela",
                "countryCode": "BR"
            },
            {
                "name": "Estrela Velha",
                "countryCode": "BR"
            },
            {
                "name": "Estância Velha",
                "countryCode": "BR"
            },
            {
                "name": "Eugênio de Castro",
                "countryCode": "BR"
            },
            {
                "name": "Fagundes Varela",
                "countryCode": "BR"
            },
            {
                "name": "Farroupilha",
                "countryCode": "BR"
            },
            {
                "name": "Faxinal do Soturno",
                "countryCode": "BR"
            },
            {
                "name": "Faxinalzinho",
                "countryCode": "BR"
            },
            {
                "name": "Fazenda Vilanova",
                "countryCode": "BR"
            },
            {
                "name": "Feliz",
                "countryCode": "BR"
            },
            {
                "name": "Flores da Cunha",
                "countryCode": "BR"
            },
            {
                "name": "Floriano Peixoto",
                "countryCode": "BR"
            },
            {
                "name": "Fontoura Xavier",
                "countryCode": "BR"
            },
            {
                "name": "Formigueiro",
                "countryCode": "BR"
            },
            {
                "name": "Forquetinha",
                "countryCode": "BR"
            },
            {
                "name": "Fortaleza dos Valos",
                "countryCode": "BR"
            },
            {
                "name": "Frederico Westphalen",
                "countryCode": "BR"
            },
            {
                "name": "Garibaldi",
                "countryCode": "BR"
            },
            {
                "name": "Garruchos",
                "countryCode": "BR"
            },
            {
                "name": "Gaurama",
                "countryCode": "BR"
            },
            {
                "name": "General Câmara",
                "countryCode": "BR"
            },
            {
                "name": "Gentil",
                "countryCode": "BR"
            },
            {
                "name": "Getúlio Vargas",
                "countryCode": "BR"
            },
            {
                "name": "Giruá",
                "countryCode": "BR"
            },
            {
                "name": "Glorinha",
                "countryCode": "BR"
            },
            {
                "name": "Gramado",
                "countryCode": "BR"
            },
            {
                "name": "Gramado Xavier",
                "countryCode": "BR"
            },
            {
                "name": "Gramado dos Loureiros",
                "countryCode": "BR"
            },
            {
                "name": "Gravataí",
                "countryCode": "BR"
            },
            {
                "name": "Guabiju",
                "countryCode": "BR"
            },
            {
                "name": "Guaporé",
                "countryCode": "BR"
            },
            {
                "name": "Guarani das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Guaíba",
                "countryCode": "BR"
            },
            {
                "name": "Harmonia",
                "countryCode": "BR"
            },
            {
                "name": "Herval",
                "countryCode": "BR"
            },
            {
                "name": "Herveiras",
                "countryCode": "BR"
            },
            {
                "name": "Horizontina",
                "countryCode": "BR"
            },
            {
                "name": "Hulha Negra",
                "countryCode": "BR"
            },
            {
                "name": "Humaitá",
                "countryCode": "BR"
            },
            {
                "name": "Ibarama",
                "countryCode": "BR"
            },
            {
                "name": "Ibiaçá",
                "countryCode": "BR"
            },
            {
                "name": "Ibiraiaras",
                "countryCode": "BR"
            },
            {
                "name": "Ibirapuitã",
                "countryCode": "BR"
            },
            {
                "name": "Ibirubá",
                "countryCode": "BR"
            },
            {
                "name": "Igrejinha",
                "countryCode": "BR"
            },
            {
                "name": "Ijuí",
                "countryCode": "BR"
            },
            {
                "name": "Ilópolis",
                "countryCode": "BR"
            },
            {
                "name": "Imbé",
                "countryCode": "BR"
            },
            {
                "name": "Imigrante",
                "countryCode": "BR"
            },
            {
                "name": "Independência",
                "countryCode": "BR"
            },
            {
                "name": "Inhacorá",
                "countryCode": "BR"
            },
            {
                "name": "Ipiranga do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Ipê",
                "countryCode": "BR"
            },
            {
                "name": "Iraí",
                "countryCode": "BR"
            },
            {
                "name": "Itaara",
                "countryCode": "BR"
            },
            {
                "name": "Itacurubi",
                "countryCode": "BR"
            },
            {
                "name": "Itapuca",
                "countryCode": "BR"
            },
            {
                "name": "Itaqui",
                "countryCode": "BR"
            },
            {
                "name": "Itati",
                "countryCode": "BR"
            },
            {
                "name": "Itatiba do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Ivorá",
                "countryCode": "BR"
            },
            {
                "name": "Ivoti",
                "countryCode": "BR"
            },
            {
                "name": "Jaboticaba",
                "countryCode": "BR"
            },
            {
                "name": "Jacuizinho",
                "countryCode": "BR"
            },
            {
                "name": "Jacutinga",
                "countryCode": "BR"
            },
            {
                "name": "Jaguari",
                "countryCode": "BR"
            },
            {
                "name": "Jaguarão",
                "countryCode": "BR"
            },
            {
                "name": "Jaquirana",
                "countryCode": "BR"
            },
            {
                "name": "Jari",
                "countryCode": "BR"
            },
            {
                "name": "Jóia",
                "countryCode": "BR"
            },
            {
                "name": "Júlio de Castilhos",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Bonita do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa Vermelha",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa dos Três Cantos",
                "countryCode": "BR"
            },
            {
                "name": "Lagoão",
                "countryCode": "BR"
            },
            {
                "name": "Lajeado",
                "countryCode": "BR"
            },
            {
                "name": "Lajeado do Bugre",
                "countryCode": "BR"
            },
            {
                "name": "Lavras do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Liberato Salzano",
                "countryCode": "BR"
            },
            {
                "name": "Lindolfo Collor",
                "countryCode": "BR"
            },
            {
                "name": "Linha Nova",
                "countryCode": "BR"
            },
            {
                "name": "Machadinho",
                "countryCode": "BR"
            },
            {
                "name": "Mampituba",
                "countryCode": "BR"
            },
            {
                "name": "Manoel Viana",
                "countryCode": "BR"
            },
            {
                "name": "Maquiné",
                "countryCode": "BR"
            },
            {
                "name": "Maratá",
                "countryCode": "BR"
            },
            {
                "name": "Marau",
                "countryCode": "BR"
            },
            {
                "name": "Marcelino Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Mariana Pimentel",
                "countryCode": "BR"
            },
            {
                "name": "Mariano Moro",
                "countryCode": "BR"
            },
            {
                "name": "Marques de Souza",
                "countryCode": "BR"
            },
            {
                "name": "Mata",
                "countryCode": "BR"
            },
            {
                "name": "Mato Castelhano",
                "countryCode": "BR"
            },
            {
                "name": "Mato Leitão",
                "countryCode": "BR"
            },
            {
                "name": "Mato Queimado",
                "countryCode": "BR"
            },
            {
                "name": "Maximiliano de Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Maçambará",
                "countryCode": "BR"
            },
            {
                "name": "Minas do Leão",
                "countryCode": "BR"
            },
            {
                "name": "Miraguaí",
                "countryCode": "BR"
            },
            {
                "name": "Montauri",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre dos Campos",
                "countryCode": "BR"
            },
            {
                "name": "Monte Belo do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Montenegro",
                "countryCode": "BR"
            },
            {
                "name": "Mormaço",
                "countryCode": "BR"
            },
            {
                "name": "Morrinhos do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Morro Redondo",
                "countryCode": "BR"
            },
            {
                "name": "Morro Reuter",
                "countryCode": "BR"
            },
            {
                "name": "Mostardas",
                "countryCode": "BR"
            },
            {
                "name": "Muitos Capões",
                "countryCode": "BR"
            },
            {
                "name": "Muliterno",
                "countryCode": "BR"
            },
            {
                "name": "Muçum",
                "countryCode": "BR"
            },
            {
                "name": "Nicolau Vergueiro",
                "countryCode": "BR"
            },
            {
                "name": "Nonoai",
                "countryCode": "BR"
            },
            {
                "name": "Nova Alvorada",
                "countryCode": "BR"
            },
            {
                "name": "Nova Araçá",
                "countryCode": "BR"
            },
            {
                "name": "Nova Bassano",
                "countryCode": "BR"
            },
            {
                "name": "Nova Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Nova Bréscia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Candelária",
                "countryCode": "BR"
            },
            {
                "name": "Nova Esperança do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Nova Hartz",
                "countryCode": "BR"
            },
            {
                "name": "Nova Palma",
                "countryCode": "BR"
            },
            {
                "name": "Nova Petrópolis",
                "countryCode": "BR"
            },
            {
                "name": "Nova Prata",
                "countryCode": "BR"
            },
            {
                "name": "Nova Pádua",
                "countryCode": "BR"
            },
            {
                "name": "Nova Ramada",
                "countryCode": "BR"
            },
            {
                "name": "Nova Roma do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Nova Santa Rita",
                "countryCode": "BR"
            },
            {
                "name": "Novo Barreiro",
                "countryCode": "BR"
            },
            {
                "name": "Novo Cabrais",
                "countryCode": "BR"
            },
            {
                "name": "Novo Hamburgo",
                "countryCode": "BR"
            },
            {
                "name": "Novo Machado",
                "countryCode": "BR"
            },
            {
                "name": "Novo Tiradentes",
                "countryCode": "BR"
            },
            {
                "name": "Novo Xingu",
                "countryCode": "BR"
            },
            {
                "name": "Não Me Toque",
                "countryCode": "BR"
            },
            {
                "name": "Não-Me-Toque",
                "countryCode": "BR"
            },
            {
                "name": "Osório",
                "countryCode": "BR"
            },
            {
                "name": "Paim Filho",
                "countryCode": "BR"
            },
            {
                "name": "Palmares do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Palmitinho",
                "countryCode": "BR"
            },
            {
                "name": "Panambi",
                "countryCode": "BR"
            },
            {
                "name": "Pantano Grande",
                "countryCode": "BR"
            },
            {
                "name": "Paraí",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Pareci Novo",
                "countryCode": "BR"
            },
            {
                "name": "Parobé",
                "countryCode": "BR"
            },
            {
                "name": "Passa Sete",
                "countryCode": "BR"
            },
            {
                "name": "Passo Fundo",
                "countryCode": "BR"
            },
            {
                "name": "Passo do Sobrado",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Bento",
                "countryCode": "BR"
            },
            {
                "name": "Paverama",
                "countryCode": "BR"
            },
            {
                "name": "Pedras Altas",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Osório",
                "countryCode": "BR"
            },
            {
                "name": "Pejuçara",
                "countryCode": "BR"
            },
            {
                "name": "Pelotas",
                "countryCode": "BR"
            },
            {
                "name": "Picada Café",
                "countryCode": "BR"
            },
            {
                "name": "Pinhal",
                "countryCode": "BR"
            },
            {
                "name": "Pinhal Grande",
                "countryCode": "BR"
            },
            {
                "name": "Pinhal da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Pinheirinho do Vale",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiro Machado",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiros",
                "countryCode": "BR"
            },
            {
                "name": "Pinto Bandeira",
                "countryCode": "BR"
            },
            {
                "name": "Pirapó",
                "countryCode": "BR"
            },
            {
                "name": "Piratini",
                "countryCode": "BR"
            },
            {
                "name": "Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Preta",
                "countryCode": "BR"
            },
            {
                "name": "Pontão",
                "countryCode": "BR"
            },
            {
                "name": "Porto Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Porto Lucena",
                "countryCode": "BR"
            },
            {
                "name": "Porto Mauá",
                "countryCode": "BR"
            },
            {
                "name": "Porto Vera Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Porto Xavier",
                "countryCode": "BR"
            },
            {
                "name": "Portão",
                "countryCode": "BR"
            },
            {
                "name": "Pouso Novo",
                "countryCode": "BR"
            },
            {
                "name": "Poço das Antas",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Lucena",
                "countryCode": "BR"
            },
            {
                "name": "Progresso",
                "countryCode": "BR"
            },
            {
                "name": "Protásio Alves",
                "countryCode": "BR"
            },
            {
                "name": "Putinga",
                "countryCode": "BR"
            },
            {
                "name": "Quaraí",
                "countryCode": "BR"
            },
            {
                "name": "Quatro Irmãos",
                "countryCode": "BR"
            },
            {
                "name": "Quevedos",
                "countryCode": "BR"
            },
            {
                "name": "Quinze de Novembro",
                "countryCode": "BR"
            },
            {
                "name": "Redentora",
                "countryCode": "BR"
            },
            {
                "name": "Relvado",
                "countryCode": "BR"
            },
            {
                "name": "Restinga Sêca",
                "countryCode": "BR"
            },
            {
                "name": "Rio Grande",
                "countryCode": "BR"
            },
            {
                "name": "Rio Pardo",
                "countryCode": "BR"
            },
            {
                "name": "Rio dos Índios",
                "countryCode": "BR"
            },
            {
                "name": "Riozinho",
                "countryCode": "BR"
            },
            {
                "name": "Roca Sales",
                "countryCode": "BR"
            },
            {
                "name": "Rodeio Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Rolador",
                "countryCode": "BR"
            },
            {
                "name": "Rolante",
                "countryCode": "BR"
            },
            {
                "name": "Ronda Alta",
                "countryCode": "BR"
            },
            {
                "name": "Rondinha",
                "countryCode": "BR"
            },
            {
                "name": "Roque Gonzales",
                "countryCode": "BR"
            },
            {
                "name": "Rosário do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Sagrada Família",
                "countryCode": "BR"
            },
            {
                "name": "Saldanha Marinho",
                "countryCode": "BR"
            },
            {
                "name": "Salto do Jacuí",
                "countryCode": "BR"
            },
            {
                "name": "Salvador das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Salvador do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Sananduva",
                "countryCode": "BR"
            },
            {
                "name": "Sant'Ana do Livramento",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cecília do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Clara do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Margarida do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Herval",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "BR"
            },
            {
                "name": "Santa Tereza",
                "countryCode": "BR"
            },
            {
                "name": "Santa Vitória do Palmar",
                "countryCode": "BR"
            },
            {
                "name": "Santana da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "Santana do Livramento",
                "countryCode": "BR"
            },
            {
                "name": "Santiago",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio da Patrulha",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Palma",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Santo Augusto",
                "countryCode": "BR"
            },
            {
                "name": "Santo Cristo",
                "countryCode": "BR"
            },
            {
                "name": "Santo Expedito do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santo Ângelo",
                "countryCode": "BR"
            },
            {
                "name": "Sapiranga",
                "countryCode": "BR"
            },
            {
                "name": "Sapucaia",
                "countryCode": "BR"
            },
            {
                "name": "Sapucaia do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Sarandi",
                "countryCode": "BR"
            },
            {
                "name": "Seberi",
                "countryCode": "BR"
            },
            {
                "name": "Sede Nova",
                "countryCode": "BR"
            },
            {
                "name": "Segredo",
                "countryCode": "BR"
            },
            {
                "name": "Selbach",
                "countryCode": "BR"
            },
            {
                "name": "Senador Salgado Filho",
                "countryCode": "BR"
            },
            {
                "name": "Sentinela do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Serafina Corrêa",
                "countryCode": "BR"
            },
            {
                "name": "Sertão",
                "countryCode": "BR"
            },
            {
                "name": "Sertão Santana",
                "countryCode": "BR"
            },
            {
                "name": "Sete de Setembro",
                "countryCode": "BR"
            },
            {
                "name": "Severiano de Almeida",
                "countryCode": "BR"
            },
            {
                "name": "Silveira Martins",
                "countryCode": "BR"
            },
            {
                "name": "Sinimbu",
                "countryCode": "BR"
            },
            {
                "name": "Sobradinho",
                "countryCode": "BR"
            },
            {
                "name": "Soledade",
                "countryCode": "BR"
            },
            {
                "name": "São Borja",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Assis",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco de Paula",
                "countryCode": "BR"
            },
            {
                "name": "São Gabriel",
                "countryCode": "BR"
            },
            {
                "name": "São Jerônimo",
                "countryCode": "BR"
            },
            {
                "name": "São Jorge",
                "countryCode": "BR"
            },
            {
                "name": "São José das Missões",
                "countryCode": "BR"
            },
            {
                "name": "São José do Herval",
                "countryCode": "BR"
            },
            {
                "name": "São José do Hortêncio",
                "countryCode": "BR"
            },
            {
                "name": "São José do Inhacorá",
                "countryCode": "BR"
            },
            {
                "name": "São José do Norte",
                "countryCode": "BR"
            },
            {
                "name": "São José do Ouro",
                "countryCode": "BR"
            },
            {
                "name": "São José do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Ausentes",
                "countryCode": "BR"
            },
            {
                "name": "São João da Urtiga",
                "countryCode": "BR"
            },
            {
                "name": "São João do Polêsine",
                "countryCode": "BR"
            },
            {
                "name": "São Leopoldo",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Luiz Gonzaga",
                "countryCode": "BR"
            },
            {
                "name": "São Marcos",
                "countryCode": "BR"
            },
            {
                "name": "São Martinho",
                "countryCode": "BR"
            },
            {
                "name": "São Martinho da Serra",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel das Missões",
                "countryCode": "BR"
            },
            {
                "name": "São Nicolau",
                "countryCode": "BR"
            },
            {
                "name": "São Paulo das Missões",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro da Serra",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro das Missões",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Butiá",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Caí",
                "countryCode": "BR"
            },
            {
                "name": "São Sepé",
                "countryCode": "BR"
            },
            {
                "name": "São Valentim",
                "countryCode": "BR"
            },
            {
                "name": "São Valentim do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Valério do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Vendelino",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Sério",
                "countryCode": "BR"
            },
            {
                "name": "Tabaí",
                "countryCode": "BR"
            },
            {
                "name": "Tapejara",
                "countryCode": "BR"
            },
            {
                "name": "Tapera",
                "countryCode": "BR"
            },
            {
                "name": "Tapes",
                "countryCode": "BR"
            },
            {
                "name": "Taquara",
                "countryCode": "BR"
            },
            {
                "name": "Taquari",
                "countryCode": "BR"
            },
            {
                "name": "Taquaruçu do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Tavares",
                "countryCode": "BR"
            },
            {
                "name": "Tenente Portela",
                "countryCode": "BR"
            },
            {
                "name": "Terra de Areia",
                "countryCode": "BR"
            },
            {
                "name": "Teutônia",
                "countryCode": "BR"
            },
            {
                "name": "Tio Hugo",
                "countryCode": "BR"
            },
            {
                "name": "Tiradentes do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Toropi",
                "countryCode": "BR"
            },
            {
                "name": "Torres",
                "countryCode": "BR"
            },
            {
                "name": "Tramandaí",
                "countryCode": "BR"
            },
            {
                "name": "Travesseiro",
                "countryCode": "BR"
            },
            {
                "name": "Trindade do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Triunfo",
                "countryCode": "BR"
            },
            {
                "name": "Três Arroios",
                "countryCode": "BR"
            },
            {
                "name": "Três Cachoeiras",
                "countryCode": "BR"
            },
            {
                "name": "Três Coroas",
                "countryCode": "BR"
            },
            {
                "name": "Três Forquilhas",
                "countryCode": "BR"
            },
            {
                "name": "Três Palmeiras",
                "countryCode": "BR"
            },
            {
                "name": "Três Passos",
                "countryCode": "BR"
            },
            {
                "name": "Três de Maio",
                "countryCode": "BR"
            },
            {
                "name": "Tucunduva",
                "countryCode": "BR"
            },
            {
                "name": "Tunas",
                "countryCode": "BR"
            },
            {
                "name": "Tupanci do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Tupanciretã",
                "countryCode": "BR"
            },
            {
                "name": "Tupandi",
                "countryCode": "BR"
            },
            {
                "name": "Tuparendi",
                "countryCode": "BR"
            },
            {
                "name": "Turuçu",
                "countryCode": "BR"
            },
            {
                "name": "Ubiretama",
                "countryCode": "BR"
            },
            {
                "name": "Unistalda",
                "countryCode": "BR"
            },
            {
                "name": "União da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Uruguaiana",
                "countryCode": "BR"
            },
            {
                "name": "Vacaria",
                "countryCode": "BR"
            },
            {
                "name": "Vale Real",
                "countryCode": "BR"
            },
            {
                "name": "Vale Verde",
                "countryCode": "BR"
            },
            {
                "name": "Vale do Sol",
                "countryCode": "BR"
            },
            {
                "name": "Vanini",
                "countryCode": "BR"
            },
            {
                "name": "Venâncio Aires",
                "countryCode": "BR"
            },
            {
                "name": "Vera Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Veranópolis",
                "countryCode": "BR"
            },
            {
                "name": "Vespasiano Corrêa",
                "countryCode": "BR"
            },
            {
                "name": "Viadutos",
                "countryCode": "BR"
            },
            {
                "name": "Viamão",
                "countryCode": "BR"
            },
            {
                "name": "Vicente Dutra",
                "countryCode": "BR"
            },
            {
                "name": "Victor Graeff",
                "countryCode": "BR"
            },
            {
                "name": "Vila Flores",
                "countryCode": "BR"
            },
            {
                "name": "Vila Lângaro",
                "countryCode": "BR"
            },
            {
                "name": "Vila Maria",
                "countryCode": "BR"
            },
            {
                "name": "Vila Nova do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Vista Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Vista Alegre do Prata",
                "countryCode": "BR"
            },
            {
                "name": "Vista Gaúcha",
                "countryCode": "BR"
            },
            {
                "name": "Vitória das Missões",
                "countryCode": "BR"
            },
            {
                "name": "Westfália",
                "countryCode": "BR"
            },
            {
                "name": "Xangri-lá",
                "countryCode": "BR"
            },
            {
                "name": "Água Santa",
                "countryCode": "BR"
            },
            {
                "name": "Áurea",
                "countryCode": "BR"
            },
            {
                "name": "Abdon Batista",
                "countryCode": "BR"
            },
            {
                "name": "Abelardo Luz",
                "countryCode": "BR"
            },
            {
                "name": "Agrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Agronômica",
                "countryCode": "BR"
            },
            {
                "name": "Alfredo Wagner",
                "countryCode": "BR"
            },
            {
                "name": "Alto Bela Vista",
                "countryCode": "BR"
            },
            {
                "name": "Anchieta",
                "countryCode": "BR"
            },
            {
                "name": "Angelina",
                "countryCode": "BR"
            },
            {
                "name": "Anita Garibaldi",
                "countryCode": "BR"
            },
            {
                "name": "Anitápolis",
                "countryCode": "BR"
            },
            {
                "name": "Antônio Carlos",
                "countryCode": "BR"
            },
            {
                "name": "Apiúna",
                "countryCode": "BR"
            },
            {
                "name": "Arabutã",
                "countryCode": "BR"
            },
            {
                "name": "Araquari",
                "countryCode": "BR"
            },
            {
                "name": "Araranguá",
                "countryCode": "BR"
            },
            {
                "name": "Armazém",
                "countryCode": "BR"
            },
            {
                "name": "Armação",
                "countryCode": "BR"
            },
            {
                "name": "Arroio Trinta",
                "countryCode": "BR"
            },
            {
                "name": "Arvoredo",
                "countryCode": "BR"
            },
            {
                "name": "Ascurra",
                "countryCode": "BR"
            },
            {
                "name": "Atalanta",
                "countryCode": "BR"
            },
            {
                "name": "Aurora",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Arroio do Silva",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Barra do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Camboriú",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Gaivota",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Piçarras",
                "countryCode": "BR"
            },
            {
                "name": "Balneário Rincão",
                "countryCode": "BR"
            },
            {
                "name": "Bandeirante",
                "countryCode": "BR"
            },
            {
                "name": "Barra Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Barra Velha",
                "countryCode": "BR"
            },
            {
                "name": "Bela Vista do Toldo",
                "countryCode": "BR"
            },
            {
                "name": "Belmonte",
                "countryCode": "BR"
            },
            {
                "name": "Benedito Novo",
                "countryCode": "BR"
            },
            {
                "name": "Biguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Blumenau",
                "countryCode": "BR"
            },
            {
                "name": "Bocaina do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jardim da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Bom Retiro",
                "countryCode": "BR"
            },
            {
                "name": "Bombinhas",
                "countryCode": "BR"
            },
            {
                "name": "Botuverá",
                "countryCode": "BR"
            },
            {
                "name": "Braço do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Braço do Trombudo",
                "countryCode": "BR"
            },
            {
                "name": "Brunópolis",
                "countryCode": "BR"
            },
            {
                "name": "Brusque",
                "countryCode": "BR"
            },
            {
                "name": "Caibi",
                "countryCode": "BR"
            },
            {
                "name": "Calmon",
                "countryCode": "BR"
            },
            {
                "name": "Camboriú",
                "countryCode": "BR"
            },
            {
                "name": "Campinas",
                "countryCode": "BR"
            },
            {
                "name": "Campo Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Campo Belo do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Campo Erê",
                "countryCode": "BR"
            },
            {
                "name": "Campos Novos",
                "countryCode": "BR"
            },
            {
                "name": "Canelinha",
                "countryCode": "BR"
            },
            {
                "name": "Canoinhas",
                "countryCode": "BR"
            },
            {
                "name": "Capinzal",
                "countryCode": "BR"
            },
            {
                "name": "Capivari de Baixo",
                "countryCode": "BR"
            },
            {
                "name": "Capão Alto",
                "countryCode": "BR"
            },
            {
                "name": "Carianos",
                "countryCode": "BR"
            },
            {
                "name": "Carvoeira",
                "countryCode": "BR"
            },
            {
                "name": "Catanduvas",
                "countryCode": "BR"
            },
            {
                "name": "Caxambu do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Caçador",
                "countryCode": "BR"
            },
            {
                "name": "Celso Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Cerro Negro",
                "countryCode": "BR"
            },
            {
                "name": "Chapadão do Lageado",
                "countryCode": "BR"
            },
            {
                "name": "Chapecó",
                "countryCode": "BR"
            },
            {
                "name": "Cocal",
                "countryCode": "BR"
            },
            {
                "name": "Cocal do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Concórdia",
                "countryCode": "BR"
            },
            {
                "name": "Cordilheira Alta",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Freitas",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Martins",
                "countryCode": "BR"
            },
            {
                "name": "Corrego Grande",
                "countryCode": "BR"
            },
            {
                "name": "Correia Pinto",
                "countryCode": "BR"
            },
            {
                "name": "Corupá",
                "countryCode": "BR"
            },
            {
                "name": "Costeira do Pirajubae",
                "countryCode": "BR"
            },
            {
                "name": "Criciúma",
                "countryCode": "BR"
            },
            {
                "name": "Cunha Porã",
                "countryCode": "BR"
            },
            {
                "name": "Cunhataí",
                "countryCode": "BR"
            },
            {
                "name": "Curitibanos",
                "countryCode": "BR"
            },
            {
                "name": "Descanso",
                "countryCode": "BR"
            },
            {
                "name": "Dionísio Cerqueira",
                "countryCode": "BR"
            },
            {
                "name": "Dona Emma",
                "countryCode": "BR"
            },
            {
                "name": "Doutor Pedrinho",
                "countryCode": "BR"
            },
            {
                "name": "Entre Rios",
                "countryCode": "BR"
            },
            {
                "name": "Ermo",
                "countryCode": "BR"
            },
            {
                "name": "Erval Velho",
                "countryCode": "BR"
            },
            {
                "name": "Faxinal dos Guedes",
                "countryCode": "BR"
            },
            {
                "name": "Flor do Sertão",
                "countryCode": "BR"
            },
            {
                "name": "Florianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Formosa do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Forquilhinha",
                "countryCode": "BR"
            },
            {
                "name": "Fraiburgo",
                "countryCode": "BR"
            },
            {
                "name": "Freguesia do Ribeirao da Ilha",
                "countryCode": "BR"
            },
            {
                "name": "Frei Rogério",
                "countryCode": "BR"
            },
            {
                "name": "Galvão",
                "countryCode": "BR"
            },
            {
                "name": "Garopaba",
                "countryCode": "BR"
            },
            {
                "name": "Garuva",
                "countryCode": "BR"
            },
            {
                "name": "Gaspar",
                "countryCode": "BR"
            },
            {
                "name": "Governador Celso Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Gravatal",
                "countryCode": "BR"
            },
            {
                "name": "Grão Pará",
                "countryCode": "BR"
            },
            {
                "name": "Guabiruba",
                "countryCode": "BR"
            },
            {
                "name": "Guaraciaba",
                "countryCode": "BR"
            },
            {
                "name": "Guaramirim",
                "countryCode": "BR"
            },
            {
                "name": "Guarujá do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Guatambú",
                "countryCode": "BR"
            },
            {
                "name": "Herval d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Ibiam",
                "countryCode": "BR"
            },
            {
                "name": "Ibicaré",
                "countryCode": "BR"
            },
            {
                "name": "Ibirama",
                "countryCode": "BR"
            },
            {
                "name": "Ilhota",
                "countryCode": "BR"
            },
            {
                "name": "Imaruí",
                "countryCode": "BR"
            },
            {
                "name": "Imbituba",
                "countryCode": "BR"
            },
            {
                "name": "Imbuia",
                "countryCode": "BR"
            },
            {
                "name": "Indaial",
                "countryCode": "BR"
            },
            {
                "name": "Iomerê",
                "countryCode": "BR"
            },
            {
                "name": "Ipira",
                "countryCode": "BR"
            },
            {
                "name": "Iporã do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Ipuaçu",
                "countryCode": "BR"
            },
            {
                "name": "Ipumirim",
                "countryCode": "BR"
            },
            {
                "name": "Iraceminha",
                "countryCode": "BR"
            },
            {
                "name": "Irani",
                "countryCode": "BR"
            },
            {
                "name": "Irati",
                "countryCode": "BR"
            },
            {
                "name": "Irineópolis",
                "countryCode": "BR"
            },
            {
                "name": "Itacorubi",
                "countryCode": "BR"
            },
            {
                "name": "Itaiópolis",
                "countryCode": "BR"
            },
            {
                "name": "Itajaí",
                "countryCode": "BR"
            },
            {
                "name": "Itapema",
                "countryCode": "BR"
            },
            {
                "name": "Itapiranga",
                "countryCode": "BR"
            },
            {
                "name": "Itapoá",
                "countryCode": "BR"
            },
            {
                "name": "Ituporanga",
                "countryCode": "BR"
            },
            {
                "name": "Itá",
                "countryCode": "BR"
            },
            {
                "name": "Içara",
                "countryCode": "BR"
            },
            {
                "name": "Jaborá",
                "countryCode": "BR"
            },
            {
                "name": "Jacinto Machado",
                "countryCode": "BR"
            },
            {
                "name": "Jaguaruna",
                "countryCode": "BR"
            },
            {
                "name": "Jaraguá do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Jardinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Joaçaba",
                "countryCode": "BR"
            },
            {
                "name": "Joinville",
                "countryCode": "BR"
            },
            {
                "name": "José Boiteux",
                "countryCode": "BR"
            },
            {
                "name": "Jupiá",
                "countryCode": "BR"
            },
            {
                "name": "Lacerdópolis",
                "countryCode": "BR"
            },
            {
                "name": "Lages",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa",
                "countryCode": "BR"
            },
            {
                "name": "Laguna",
                "countryCode": "BR"
            },
            {
                "name": "Lajeado Grande",
                "countryCode": "BR"
            },
            {
                "name": "Laurentino",
                "countryCode": "BR"
            },
            {
                "name": "Lauro Muller",
                "countryCode": "BR"
            },
            {
                "name": "Lebon Régis",
                "countryCode": "BR"
            },
            {
                "name": "Leoberto Leal",
                "countryCode": "BR"
            },
            {
                "name": "Lindóia do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Lontras",
                "countryCode": "BR"
            },
            {
                "name": "Luiz Alves",
                "countryCode": "BR"
            },
            {
                "name": "Luzerna",
                "countryCode": "BR"
            },
            {
                "name": "Macieira",
                "countryCode": "BR"
            },
            {
                "name": "Mafra",
                "countryCode": "BR"
            },
            {
                "name": "Major Gercino",
                "countryCode": "BR"
            },
            {
                "name": "Major Vieira",
                "countryCode": "BR"
            },
            {
                "name": "Maracajá",
                "countryCode": "BR"
            },
            {
                "name": "Maravilha",
                "countryCode": "BR"
            },
            {
                "name": "Marema",
                "countryCode": "BR"
            },
            {
                "name": "Massaranduba",
                "countryCode": "BR"
            },
            {
                "name": "Matos Costa",
                "countryCode": "BR"
            },
            {
                "name": "Meleiro",
                "countryCode": "BR"
            },
            {
                "name": "Mirim Doce",
                "countryCode": "BR"
            },
            {
                "name": "Modelo",
                "countryCode": "BR"
            },
            {
                "name": "Mondaí",
                "countryCode": "BR"
            },
            {
                "name": "Monte Carlo",
                "countryCode": "BR"
            },
            {
                "name": "Monte Castelo",
                "countryCode": "BR"
            },
            {
                "name": "Morro Grande",
                "countryCode": "BR"
            },
            {
                "name": "Morro da Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Morro da Fumaça",
                "countryCode": "BR"
            },
            {
                "name": "Navegantes",
                "countryCode": "BR"
            },
            {
                "name": "Nova Erechim",
                "countryCode": "BR"
            },
            {
                "name": "Nova Itaberaba",
                "countryCode": "BR"
            },
            {
                "name": "Nova Trento",
                "countryCode": "BR"
            },
            {
                "name": "Nova Veneza",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Orleans",
                "countryCode": "BR"
            },
            {
                "name": "Otacílio Costa",
                "countryCode": "BR"
            },
            {
                "name": "Ouro",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Verde",
                "countryCode": "BR"
            },
            {
                "name": "Paial",
                "countryCode": "BR"
            },
            {
                "name": "Painel",
                "countryCode": "BR"
            },
            {
                "name": "Palhoça",
                "countryCode": "BR"
            },
            {
                "name": "Palma Sola",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira",
                "countryCode": "BR"
            },
            {
                "name": "Palmitos",
                "countryCode": "BR"
            },
            {
                "name": "Pantanal",
                "countryCode": "BR"
            },
            {
                "name": "Pantano do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Papanduva",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Passo de Torres",
                "countryCode": "BR"
            },
            {
                "name": "Passos Maia",
                "countryCode": "BR"
            },
            {
                "name": "Paulo Lopes",
                "countryCode": "BR"
            },
            {
                "name": "Pedras Grandes",
                "countryCode": "BR"
            },
            {
                "name": "Penha",
                "countryCode": "BR"
            },
            {
                "name": "Peritiba",
                "countryCode": "BR"
            },
            {
                "name": "Petrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Pinhalzinho",
                "countryCode": "BR"
            },
            {
                "name": "Pinheiro Preto",
                "countryCode": "BR"
            },
            {
                "name": "Piratuba",
                "countryCode": "BR"
            },
            {
                "name": "Planalto Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Pomerode",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Alta",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Alta do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Serrada",
                "countryCode": "BR"
            },
            {
                "name": "Porto Belo",
                "countryCode": "BR"
            },
            {
                "name": "Porto União",
                "countryCode": "BR"
            },
            {
                "name": "Pouso Redondo",
                "countryCode": "BR"
            },
            {
                "name": "Praia Grande",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Castello Branco",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Getúlio",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Nereu",
                "countryCode": "BR"
            },
            {
                "name": "Princesa",
                "countryCode": "BR"
            },
            {
                "name": "Quilombo",
                "countryCode": "BR"
            },
            {
                "name": "Rancho Queimado",
                "countryCode": "BR"
            },
            {
                "name": "Residencia Moacir PU5BHV",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão da Ilha",
                "countryCode": "BR"
            },
            {
                "name": "Rio Fortuna",
                "countryCode": "BR"
            },
            {
                "name": "Rio Negrinho",
                "countryCode": "BR"
            },
            {
                "name": "Rio Rufino",
                "countryCode": "BR"
            },
            {
                "name": "Rio Tavares",
                "countryCode": "BR"
            },
            {
                "name": "Rio das Antas",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Campo",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Rio do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Rio dos Cedros",
                "countryCode": "BR"
            },
            {
                "name": "Riqueza",
                "countryCode": "BR"
            },
            {
                "name": "Rodeio",
                "countryCode": "BR"
            },
            {
                "name": "Romelândia",
                "countryCode": "BR"
            },
            {
                "name": "Saco dos Limoes",
                "countryCode": "BR"
            },
            {
                "name": "Salete",
                "countryCode": "BR"
            },
            {
                "name": "Saltinho",
                "countryCode": "BR"
            },
            {
                "name": "Salto Veloso",
                "countryCode": "BR"
            },
            {
                "name": "Sangão",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cecília",
                "countryCode": "BR"
            },
            {
                "name": "Santa Helena",
                "countryCode": "BR"
            },
            {
                "name": "Santa Monica",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha do Progresso",
                "countryCode": "BR"
            },
            {
                "name": "Santiago do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santo Amaro da Imperatriz",
                "countryCode": "BR"
            },
            {
                "name": "Saudades",
                "countryCode": "BR"
            },
            {
                "name": "Schroeder",
                "countryCode": "BR"
            },
            {
                "name": "Seara",
                "countryCode": "BR"
            },
            {
                "name": "Serra Alta",
                "countryCode": "BR"
            },
            {
                "name": "Siderópolis",
                "countryCode": "BR"
            },
            {
                "name": "Sombrio",
                "countryCode": "BR"
            },
            {
                "name": "Sul Brasil",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Bernardino",
                "countryCode": "BR"
            },
            {
                "name": "São Bonifácio",
                "countryCode": "BR"
            },
            {
                "name": "São Carlos",
                "countryCode": "BR"
            },
            {
                "name": "São Cristóvão do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Joaquim",
                "countryCode": "BR"
            },
            {
                "name": "São José",
                "countryCode": "BR"
            },
            {
                "name": "São José do Cedro",
                "countryCode": "BR"
            },
            {
                "name": "São José do Cerrito",
                "countryCode": "BR"
            },
            {
                "name": "São João Batista",
                "countryCode": "BR"
            },
            {
                "name": "São João do Itaperiú",
                "countryCode": "BR"
            },
            {
                "name": "São João do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São João do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Ludgero",
                "countryCode": "BR"
            },
            {
                "name": "São Martinho",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro de Alcântara",
                "countryCode": "BR"
            },
            {
                "name": "Taió",
                "countryCode": "BR"
            },
            {
                "name": "Tangará",
                "countryCode": "BR"
            },
            {
                "name": "Tapera",
                "countryCode": "BR"
            },
            {
                "name": "Tigrinhos",
                "countryCode": "BR"
            },
            {
                "name": "Tijucas",
                "countryCode": "BR"
            },
            {
                "name": "Timbé do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Timbó",
                "countryCode": "BR"
            },
            {
                "name": "Timbó Grande",
                "countryCode": "BR"
            },
            {
                "name": "Treviso",
                "countryCode": "BR"
            },
            {
                "name": "Treze Tílias",
                "countryCode": "BR"
            },
            {
                "name": "Treze de Maio",
                "countryCode": "BR"
            },
            {
                "name": "Trindade",
                "countryCode": "BR"
            },
            {
                "name": "Trombudo Central",
                "countryCode": "BR"
            },
            {
                "name": "Três Barras",
                "countryCode": "BR"
            },
            {
                "name": "Tubarão",
                "countryCode": "BR"
            },
            {
                "name": "Tunápolis",
                "countryCode": "BR"
            },
            {
                "name": "Turvo",
                "countryCode": "BR"
            },
            {
                "name": "União do Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Urubici",
                "countryCode": "BR"
            },
            {
                "name": "Urupema",
                "countryCode": "BR"
            },
            {
                "name": "Urussanga",
                "countryCode": "BR"
            },
            {
                "name": "Vargem",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Vargeão",
                "countryCode": "BR"
            },
            {
                "name": "Vidal Ramos",
                "countryCode": "BR"
            },
            {
                "name": "Videira",
                "countryCode": "BR"
            },
            {
                "name": "Vitor Meireles",
                "countryCode": "BR"
            },
            {
                "name": "Witmarsum",
                "countryCode": "BR"
            },
            {
                "name": "Xanxerê",
                "countryCode": "BR"
            },
            {
                "name": "Xavantina",
                "countryCode": "BR"
            },
            {
                "name": "Xaxim",
                "countryCode": "BR"
            },
            {
                "name": "Zortéa",
                "countryCode": "BR"
            },
            {
                "name": "Água Doce",
                "countryCode": "BR"
            },
            {
                "name": "Águas Frias",
                "countryCode": "BR"
            },
            {
                "name": "Águas Mornas",
                "countryCode": "BR"
            },
            {
                "name": "Águas de Chapecó",
                "countryCode": "BR"
            },
            {
                "name": "Amparo de São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Aquidabã",
                "countryCode": "BR"
            },
            {
                "name": "Aracaju",
                "countryCode": "BR"
            },
            {
                "name": "Arauá",
                "countryCode": "BR"
            },
            {
                "name": "Areia Branca",
                "countryCode": "BR"
            },
            {
                "name": "Barra dos Coqueiros",
                "countryCode": "BR"
            },
            {
                "name": "Boquim",
                "countryCode": "BR"
            },
            {
                "name": "Brejo Grande",
                "countryCode": "BR"
            },
            {
                "name": "Campo do Brito",
                "countryCode": "BR"
            },
            {
                "name": "Canhoba",
                "countryCode": "BR"
            },
            {
                "name": "Canindé de São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Capela",
                "countryCode": "BR"
            },
            {
                "name": "Carira",
                "countryCode": "BR"
            },
            {
                "name": "Carmópolis",
                "countryCode": "BR"
            },
            {
                "name": "Cedro de São João",
                "countryCode": "BR"
            },
            {
                "name": "Cristinápolis",
                "countryCode": "BR"
            },
            {
                "name": "Cumbe",
                "countryCode": "BR"
            },
            {
                "name": "Divina Pastora",
                "countryCode": "BR"
            },
            {
                "name": "Estância",
                "countryCode": "BR"
            },
            {
                "name": "Feira Nova",
                "countryCode": "BR"
            },
            {
                "name": "Frei Paulo",
                "countryCode": "BR"
            },
            {
                "name": "Gararu",
                "countryCode": "BR"
            },
            {
                "name": "General Maynard",
                "countryCode": "BR"
            },
            {
                "name": "Gracho Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Ilha das Flores",
                "countryCode": "BR"
            },
            {
                "name": "Indiaroba",
                "countryCode": "BR"
            },
            {
                "name": "Itabaiana",
                "countryCode": "BR"
            },
            {
                "name": "Itabaianinha",
                "countryCode": "BR"
            },
            {
                "name": "Itabi",
                "countryCode": "BR"
            },
            {
                "name": "Itaporanga d'Ajuda",
                "countryCode": "BR"
            },
            {
                "name": "Japaratuba",
                "countryCode": "BR"
            },
            {
                "name": "Japoatã",
                "countryCode": "BR"
            },
            {
                "name": "Lagarto",
                "countryCode": "BR"
            },
            {
                "name": "Laranjeiras",
                "countryCode": "BR"
            },
            {
                "name": "Macambira",
                "countryCode": "BR"
            },
            {
                "name": "Malhada dos Bois",
                "countryCode": "BR"
            },
            {
                "name": "Malhador",
                "countryCode": "BR"
            },
            {
                "name": "Maruim",
                "countryCode": "BR"
            },
            {
                "name": "Moita Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre de Sergipe",
                "countryCode": "BR"
            },
            {
                "name": "Muribeca",
                "countryCode": "BR"
            },
            {
                "name": "Neópolis",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora Aparecida",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora da Glória",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora das Dores",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora de Lourdes",
                "countryCode": "BR"
            },
            {
                "name": "Nossa Senhora do Socorro",
                "countryCode": "BR"
            },
            {
                "name": "Pacatuba",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Mole",
                "countryCode": "BR"
            },
            {
                "name": "Pedrinhas",
                "countryCode": "BR"
            },
            {
                "name": "Pinhão",
                "countryCode": "BR"
            },
            {
                "name": "Pirambu",
                "countryCode": "BR"
            },
            {
                "name": "Porto da Folha",
                "countryCode": "BR"
            },
            {
                "name": "Poço Redondo",
                "countryCode": "BR"
            },
            {
                "name": "Poço Verde",
                "countryCode": "BR"
            },
            {
                "name": "Propriá",
                "countryCode": "BR"
            },
            {
                "name": "Riachuelo",
                "countryCode": "BR"
            },
            {
                "name": "Riachão do Dantas",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Rosário do Catete",
                "countryCode": "BR"
            },
            {
                "name": "Salgado",
                "countryCode": "BR"
            },
            {
                "name": "Santa Luzia do Itanhy",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "BR"
            },
            {
                "name": "Santana do São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "Santo Amaro das Brotas",
                "countryCode": "BR"
            },
            {
                "name": "Simão Dias",
                "countryCode": "BR"
            },
            {
                "name": "Siriri",
                "countryCode": "BR"
            },
            {
                "name": "São Cristóvão",
                "countryCode": "BR"
            },
            {
                "name": "São Domingos",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Aleixo",
                "countryCode": "BR"
            },
            {
                "name": "Telha",
                "countryCode": "BR"
            },
            {
                "name": "Tobias Barreto",
                "countryCode": "BR"
            },
            {
                "name": "Tomar do Geru",
                "countryCode": "BR"
            },
            {
                "name": "Umbaúba",
                "countryCode": "BR"
            },
            {
                "name": "Adamantina",
                "countryCode": "BR"
            },
            {
                "name": "Adolfo",
                "countryCode": "BR"
            },
            {
                "name": "Aguaí",
                "countryCode": "BR"
            },
            {
                "name": "Agudos",
                "countryCode": "BR"
            },
            {
                "name": "Alambari",
                "countryCode": "BR"
            },
            {
                "name": "Alfredo Marcondes",
                "countryCode": "BR"
            },
            {
                "name": "Altair",
                "countryCode": "BR"
            },
            {
                "name": "Altinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Alto Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Alumínio",
                "countryCode": "BR"
            },
            {
                "name": "Alvinlândia",
                "countryCode": "BR"
            },
            {
                "name": "Americana",
                "countryCode": "BR"
            },
            {
                "name": "Amparo",
                "countryCode": "BR"
            },
            {
                "name": "Américo Brasiliense",
                "countryCode": "BR"
            },
            {
                "name": "Américo de Campos",
                "countryCode": "BR"
            },
            {
                "name": "Analândia",
                "countryCode": "BR"
            },
            {
                "name": "Andradina",
                "countryCode": "BR"
            },
            {
                "name": "Angatuba",
                "countryCode": "BR"
            },
            {
                "name": "Anhembi",
                "countryCode": "BR"
            },
            {
                "name": "Anhumas",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Apiaí",
                "countryCode": "BR"
            },
            {
                "name": "Aramina",
                "countryCode": "BR"
            },
            {
                "name": "Arandu",
                "countryCode": "BR"
            },
            {
                "name": "Arapeí",
                "countryCode": "BR"
            },
            {
                "name": "Araraquara",
                "countryCode": "BR"
            },
            {
                "name": "Araras",
                "countryCode": "BR"
            },
            {
                "name": "Araçariguama",
                "countryCode": "BR"
            },
            {
                "name": "Araçatuba",
                "countryCode": "BR"
            },
            {
                "name": "Araçoiaba da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Arco-Íris",
                "countryCode": "BR"
            },
            {
                "name": "Arealva",
                "countryCode": "BR"
            },
            {
                "name": "Areias",
                "countryCode": "BR"
            },
            {
                "name": "Areiópolis",
                "countryCode": "BR"
            },
            {
                "name": "Ariranha",
                "countryCode": "BR"
            },
            {
                "name": "Artur Nogueira",
                "countryCode": "BR"
            },
            {
                "name": "Arujá",
                "countryCode": "BR"
            },
            {
                "name": "Aspásia",
                "countryCode": "BR"
            },
            {
                "name": "Assis",
                "countryCode": "BR"
            },
            {
                "name": "Atibaia",
                "countryCode": "BR"
            },
            {
                "name": "Auriflama",
                "countryCode": "BR"
            },
            {
                "name": "Avanhandava",
                "countryCode": "BR"
            },
            {
                "name": "Avaré",
                "countryCode": "BR"
            },
            {
                "name": "Avaí",
                "countryCode": "BR"
            },
            {
                "name": "Bady Bassitt",
                "countryCode": "BR"
            },
            {
                "name": "Balbinos",
                "countryCode": "BR"
            },
            {
                "name": "Bananal",
                "countryCode": "BR"
            },
            {
                "name": "Barbosa",
                "countryCode": "BR"
            },
            {
                "name": "Bariri",
                "countryCode": "BR"
            },
            {
                "name": "Barra Bonita",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Chapéu",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Turvo",
                "countryCode": "BR"
            },
            {
                "name": "Barretos",
                "countryCode": "BR"
            },
            {
                "name": "Barrinha",
                "countryCode": "BR"
            },
            {
                "name": "Barueri",
                "countryCode": "BR"
            },
            {
                "name": "Barão de Antonina",
                "countryCode": "BR"
            },
            {
                "name": "Bastos",
                "countryCode": "BR"
            },
            {
                "name": "Batatais",
                "countryCode": "BR"
            },
            {
                "name": "Bauru",
                "countryCode": "BR"
            },
            {
                "name": "Bebedouro",
                "countryCode": "BR"
            },
            {
                "name": "Bento de Abreu",
                "countryCode": "BR"
            },
            {
                "name": "Bernardino de Campos",
                "countryCode": "BR"
            },
            {
                "name": "Bertioga",
                "countryCode": "BR"
            },
            {
                "name": "Bilac",
                "countryCode": "BR"
            },
            {
                "name": "Birigui",
                "countryCode": "BR"
            },
            {
                "name": "Biritiba Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Biritiba-Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Boa Esperança do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Bocaina",
                "countryCode": "BR"
            },
            {
                "name": "Bofete",
                "countryCode": "BR"
            },
            {
                "name": "Boituva",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus dos Perdões",
                "countryCode": "BR"
            },
            {
                "name": "Bom Sucesso de Itararé",
                "countryCode": "BR"
            },
            {
                "name": "Boracéia",
                "countryCode": "BR"
            },
            {
                "name": "Borborema",
                "countryCode": "BR"
            },
            {
                "name": "Borebi",
                "countryCode": "BR"
            },
            {
                "name": "Borá",
                "countryCode": "BR"
            },
            {
                "name": "Botucatu",
                "countryCode": "BR"
            },
            {
                "name": "Bragança Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Braúna",
                "countryCode": "BR"
            },
            {
                "name": "Brejo Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Brodowski",
                "countryCode": "BR"
            },
            {
                "name": "Brodósqui",
                "countryCode": "BR"
            },
            {
                "name": "Brotas",
                "countryCode": "BR"
            },
            {
                "name": "Buri",
                "countryCode": "BR"
            },
            {
                "name": "Buritama",
                "countryCode": "BR"
            },
            {
                "name": "Buritizal",
                "countryCode": "BR"
            },
            {
                "name": "Bálsamo",
                "countryCode": "BR"
            },
            {
                "name": "Cabreúva",
                "countryCode": "BR"
            },
            {
                "name": "Cabrália Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeira Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Caconde",
                "countryCode": "BR"
            },
            {
                "name": "Cafelândia",
                "countryCode": "BR"
            },
            {
                "name": "Caiabu",
                "countryCode": "BR"
            },
            {
                "name": "Caieiras",
                "countryCode": "BR"
            },
            {
                "name": "Caiuá",
                "countryCode": "BR"
            },
            {
                "name": "Cajamar",
                "countryCode": "BR"
            },
            {
                "name": "Cajati",
                "countryCode": "BR"
            },
            {
                "name": "Cajobi",
                "countryCode": "BR"
            },
            {
                "name": "Cajuru",
                "countryCode": "BR"
            },
            {
                "name": "Campina do Monte Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Campinas",
                "countryCode": "BR"
            },
            {
                "name": "Campo Limpo Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Campos Novos Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Campos do Jordão",
                "countryCode": "BR"
            },
            {
                "name": "Cananéia",
                "countryCode": "BR"
            },
            {
                "name": "Canas",
                "countryCode": "BR"
            },
            {
                "name": "Canitar",
                "countryCode": "BR"
            },
            {
                "name": "Capela do Alto",
                "countryCode": "BR"
            },
            {
                "name": "Capivari",
                "countryCode": "BR"
            },
            {
                "name": "Capâo Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Caraguatatuba",
                "countryCode": "BR"
            },
            {
                "name": "Carapicuíba",
                "countryCode": "BR"
            },
            {
                "name": "Cardoso",
                "countryCode": "BR"
            },
            {
                "name": "Casa Branca",
                "countryCode": "BR"
            },
            {
                "name": "Castilho",
                "countryCode": "BR"
            },
            {
                "name": "Catanduva",
                "countryCode": "BR"
            },
            {
                "name": "Catiguá",
                "countryCode": "BR"
            },
            {
                "name": "Caçapava",
                "countryCode": "BR"
            },
            {
                "name": "Cedral",
                "countryCode": "BR"
            },
            {
                "name": "Cerqueira César",
                "countryCode": "BR"
            },
            {
                "name": "Cerquilho",
                "countryCode": "BR"
            },
            {
                "name": "Cesário Lange",
                "countryCode": "BR"
            },
            {
                "name": "Charqueada",
                "countryCode": "BR"
            },
            {
                "name": "Chavantes",
                "countryCode": "BR"
            },
            {
                "name": "Clementina",
                "countryCode": "BR"
            },
            {
                "name": "Colina",
                "countryCode": "BR"
            },
            {
                "name": "Colômbia",
                "countryCode": "BR"
            },
            {
                "name": "Conchal",
                "countryCode": "BR"
            },
            {
                "name": "Conchas",
                "countryCode": "BR"
            },
            {
                "name": "Cordeirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Coroados",
                "countryCode": "BR"
            },
            {
                "name": "Coronel Macedo",
                "countryCode": "BR"
            },
            {
                "name": "Corumbataí",
                "countryCode": "BR"
            },
            {
                "name": "Cosmorama",
                "countryCode": "BR"
            },
            {
                "name": "Cosmópolis",
                "countryCode": "BR"
            },
            {
                "name": "Cotia",
                "countryCode": "BR"
            },
            {
                "name": "Cravinhos",
                "countryCode": "BR"
            },
            {
                "name": "Cristais Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Cruzeiro",
                "countryCode": "BR"
            },
            {
                "name": "Cruzália",
                "countryCode": "BR"
            },
            {
                "name": "Cubatão",
                "countryCode": "BR"
            },
            {
                "name": "Cunha",
                "countryCode": "BR"
            },
            {
                "name": "Cássia dos Coqueiros",
                "countryCode": "BR"
            },
            {
                "name": "Cândido Mota",
                "countryCode": "BR"
            },
            {
                "name": "Cândido Rodrigues",
                "countryCode": "BR"
            },
            {
                "name": "Descalvado",
                "countryCode": "BR"
            },
            {
                "name": "Diadema",
                "countryCode": "BR"
            },
            {
                "name": "Dirce Reis",
                "countryCode": "BR"
            },
            {
                "name": "Divinolândia",
                "countryCode": "BR"
            },
            {
                "name": "Dobrada",
                "countryCode": "BR"
            },
            {
                "name": "Dois Córregos",
                "countryCode": "BR"
            },
            {
                "name": "Dolcinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Dourado",
                "countryCode": "BR"
            },
            {
                "name": "Dracena",
                "countryCode": "BR"
            },
            {
                "name": "Duartina",
                "countryCode": "BR"
            },
            {
                "name": "Dumont",
                "countryCode": "BR"
            },
            {
                "name": "Echaporã",
                "countryCode": "BR"
            },
            {
                "name": "Eldorado",
                "countryCode": "BR"
            },
            {
                "name": "Elias Fausto",
                "countryCode": "BR"
            },
            {
                "name": "Elisiário",
                "countryCode": "BR"
            },
            {
                "name": "Embaúba",
                "countryCode": "BR"
            },
            {
                "name": "Embu",
                "countryCode": "BR"
            },
            {
                "name": "Embu Guaçu",
                "countryCode": "BR"
            },
            {
                "name": "Embu das Artes",
                "countryCode": "BR"
            },
            {
                "name": "Embu-Guaçu",
                "countryCode": "BR"
            },
            {
                "name": "Emilianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Engenheiro Coelho",
                "countryCode": "BR"
            },
            {
                "name": "Espírito Santo do Pinhal",
                "countryCode": "BR"
            },
            {
                "name": "Espírito Santo do Turvo",
                "countryCode": "BR"
            },
            {
                "name": "Estiva Gerbi",
                "countryCode": "BR"
            },
            {
                "name": "Estrela d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Estrela do Norte",
                "countryCode": "BR"
            },
            {
                "name": "Euclides da Cunha Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Fartura",
                "countryCode": "BR"
            },
            {
                "name": "Fernando Prestes",
                "countryCode": "BR"
            },
            {
                "name": "Fernandópolis",
                "countryCode": "BR"
            },
            {
                "name": "Fernão",
                "countryCode": "BR"
            },
            {
                "name": "Ferraz de Vasconcelos",
                "countryCode": "BR"
            },
            {
                "name": "Flora Rica",
                "countryCode": "BR"
            },
            {
                "name": "Floreal",
                "countryCode": "BR"
            },
            {
                "name": "Florínea",
                "countryCode": "BR"
            },
            {
                "name": "Flórida Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Franca",
                "countryCode": "BR"
            },
            {
                "name": "Francisco Morato",
                "countryCode": "BR"
            },
            {
                "name": "Franco da Rocha",
                "countryCode": "BR"
            },
            {
                "name": "Gabriel Monteiro",
                "countryCode": "BR"
            },
            {
                "name": "Garça",
                "countryCode": "BR"
            },
            {
                "name": "Gastão Vidigal",
                "countryCode": "BR"
            },
            {
                "name": "Gavião Peixoto",
                "countryCode": "BR"
            },
            {
                "name": "General Salgado",
                "countryCode": "BR"
            },
            {
                "name": "Getulina",
                "countryCode": "BR"
            },
            {
                "name": "Glicério",
                "countryCode": "BR"
            },
            {
                "name": "Guaimbê",
                "countryCode": "BR"
            },
            {
                "name": "Guaiçara",
                "countryCode": "BR"
            },
            {
                "name": "Guapiara",
                "countryCode": "BR"
            },
            {
                "name": "Guapiaçu",
                "countryCode": "BR"
            },
            {
                "name": "Guaraci",
                "countryCode": "BR"
            },
            {
                "name": "Guarani d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Guarantã",
                "countryCode": "BR"
            },
            {
                "name": "Guararapes",
                "countryCode": "BR"
            },
            {
                "name": "Guararema",
                "countryCode": "BR"
            },
            {
                "name": "Guaratinguetá",
                "countryCode": "BR"
            },
            {
                "name": "Guaraçaí",
                "countryCode": "BR"
            },
            {
                "name": "Guareí",
                "countryCode": "BR"
            },
            {
                "name": "Guariba",
                "countryCode": "BR"
            },
            {
                "name": "Guarujá",
                "countryCode": "BR"
            },
            {
                "name": "Guarulhos",
                "countryCode": "BR"
            },
            {
                "name": "Guará",
                "countryCode": "BR"
            },
            {
                "name": "Guatapará",
                "countryCode": "BR"
            },
            {
                "name": "Guaíra",
                "countryCode": "BR"
            },
            {
                "name": "Guzolândia",
                "countryCode": "BR"
            },
            {
                "name": "Gália",
                "countryCode": "BR"
            },
            {
                "name": "Herculândia",
                "countryCode": "BR"
            },
            {
                "name": "Holambra",
                "countryCode": "BR"
            },
            {
                "name": "Hortolândia",
                "countryCode": "BR"
            },
            {
                "name": "Iacanga",
                "countryCode": "BR"
            },
            {
                "name": "Iacri",
                "countryCode": "BR"
            },
            {
                "name": "Iaras",
                "countryCode": "BR"
            },
            {
                "name": "Ibaté",
                "countryCode": "BR"
            },
            {
                "name": "Ibirarema",
                "countryCode": "BR"
            },
            {
                "name": "Ibirá",
                "countryCode": "BR"
            },
            {
                "name": "Ibitinga",
                "countryCode": "BR"
            },
            {
                "name": "Ibiúna",
                "countryCode": "BR"
            },
            {
                "name": "Icém",
                "countryCode": "BR"
            },
            {
                "name": "Iepê",
                "countryCode": "BR"
            },
            {
                "name": "Igarapava",
                "countryCode": "BR"
            },
            {
                "name": "Igaratá",
                "countryCode": "BR"
            },
            {
                "name": "Igaraçu do Tietê",
                "countryCode": "BR"
            },
            {
                "name": "Iguape",
                "countryCode": "BR"
            },
            {
                "name": "Ilha Comprida",
                "countryCode": "BR"
            },
            {
                "name": "Ilha Solteira",
                "countryCode": "BR"
            },
            {
                "name": "Ilhabela",
                "countryCode": "BR"
            },
            {
                "name": "Indaiatuba",
                "countryCode": "BR"
            },
            {
                "name": "Indiana",
                "countryCode": "BR"
            },
            {
                "name": "Indiaporã",
                "countryCode": "BR"
            },
            {
                "name": "Inúbia Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Ipaussu",
                "countryCode": "BR"
            },
            {
                "name": "Ipauçu",
                "countryCode": "BR"
            },
            {
                "name": "Iperó",
                "countryCode": "BR"
            },
            {
                "name": "Ipeúna",
                "countryCode": "BR"
            },
            {
                "name": "Ipiguá",
                "countryCode": "BR"
            },
            {
                "name": "Iporanga",
                "countryCode": "BR"
            },
            {
                "name": "Ipuã",
                "countryCode": "BR"
            },
            {
                "name": "Iracemápolis",
                "countryCode": "BR"
            },
            {
                "name": "Irapuru",
                "countryCode": "BR"
            },
            {
                "name": "Irapuã",
                "countryCode": "BR"
            },
            {
                "name": "Itaberá",
                "countryCode": "BR"
            },
            {
                "name": "Itajobi",
                "countryCode": "BR"
            },
            {
                "name": "Itaju",
                "countryCode": "BR"
            },
            {
                "name": "Itanhaém",
                "countryCode": "BR"
            },
            {
                "name": "Itaoca",
                "countryCode": "BR"
            },
            {
                "name": "Itapecerica da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Itapetininga",
                "countryCode": "BR"
            },
            {
                "name": "Itapeva",
                "countryCode": "BR"
            },
            {
                "name": "Itapevi",
                "countryCode": "BR"
            },
            {
                "name": "Itapira",
                "countryCode": "BR"
            },
            {
                "name": "Itapirapuã Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Itaporanga",
                "countryCode": "BR"
            },
            {
                "name": "Itapura",
                "countryCode": "BR"
            },
            {
                "name": "Itapuí",
                "countryCode": "BR"
            },
            {
                "name": "Itaquaquecetuba",
                "countryCode": "BR"
            },
            {
                "name": "Itararé",
                "countryCode": "BR"
            },
            {
                "name": "Itariri",
                "countryCode": "BR"
            },
            {
                "name": "Itatiba",
                "countryCode": "BR"
            },
            {
                "name": "Itatinga",
                "countryCode": "BR"
            },
            {
                "name": "Itaí",
                "countryCode": "BR"
            },
            {
                "name": "Itirapina",
                "countryCode": "BR"
            },
            {
                "name": "Itirapuã",
                "countryCode": "BR"
            },
            {
                "name": "Itobi",
                "countryCode": "BR"
            },
            {
                "name": "Itu",
                "countryCode": "BR"
            },
            {
                "name": "Itupeva",
                "countryCode": "BR"
            },
            {
                "name": "Ituverava",
                "countryCode": "BR"
            },
            {
                "name": "Itápolis",
                "countryCode": "BR"
            },
            {
                "name": "Jaborandi",
                "countryCode": "BR"
            },
            {
                "name": "Jaboticabal",
                "countryCode": "BR"
            },
            {
                "name": "Jacareí",
                "countryCode": "BR"
            },
            {
                "name": "Jaci",
                "countryCode": "BR"
            },
            {
                "name": "Jacupiranga",
                "countryCode": "BR"
            },
            {
                "name": "Jaguariúna",
                "countryCode": "BR"
            },
            {
                "name": "Jales",
                "countryCode": "BR"
            },
            {
                "name": "Jambeiro",
                "countryCode": "BR"
            },
            {
                "name": "Jandira",
                "countryCode": "BR"
            },
            {
                "name": "Jardim Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Jardinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Jarinu",
                "countryCode": "BR"
            },
            {
                "name": "Jaú",
                "countryCode": "BR"
            },
            {
                "name": "Jeriquara",
                "countryCode": "BR"
            },
            {
                "name": "Joanópolis",
                "countryCode": "BR"
            },
            {
                "name": "José Bonifácio",
                "countryCode": "BR"
            },
            {
                "name": "João Ramalho",
                "countryCode": "BR"
            },
            {
                "name": "Jumirim",
                "countryCode": "BR"
            },
            {
                "name": "Jundiaí",
                "countryCode": "BR"
            },
            {
                "name": "Junqueirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Juquitiba",
                "countryCode": "BR"
            },
            {
                "name": "Juquiá",
                "countryCode": "BR"
            },
            {
                "name": "Júlio Mesquita",
                "countryCode": "BR"
            },
            {
                "name": "Lagoinha",
                "countryCode": "BR"
            },
            {
                "name": "Laranjal Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Lavrinhas",
                "countryCode": "BR"
            },
            {
                "name": "Lavínia",
                "countryCode": "BR"
            },
            {
                "name": "Leme",
                "countryCode": "BR"
            },
            {
                "name": "Lençóis Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Limeira",
                "countryCode": "BR"
            },
            {
                "name": "Lindóia",
                "countryCode": "BR"
            },
            {
                "name": "Lins",
                "countryCode": "BR"
            },
            {
                "name": "Lorena",
                "countryCode": "BR"
            },
            {
                "name": "Lourdes",
                "countryCode": "BR"
            },
            {
                "name": "Louveira",
                "countryCode": "BR"
            },
            {
                "name": "Lucianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Lucélia",
                "countryCode": "BR"
            },
            {
                "name": "Luiziânia",
                "countryCode": "BR"
            },
            {
                "name": "Lupércio",
                "countryCode": "BR"
            },
            {
                "name": "Lutécia",
                "countryCode": "BR"
            },
            {
                "name": "Luís Antônio",
                "countryCode": "BR"
            },
            {
                "name": "Macatuba",
                "countryCode": "BR"
            },
            {
                "name": "Macaubal",
                "countryCode": "BR"
            },
            {
                "name": "Macedônia",
                "countryCode": "BR"
            },
            {
                "name": "Magda",
                "countryCode": "BR"
            },
            {
                "name": "Mairinque",
                "countryCode": "BR"
            },
            {
                "name": "Mairiporã",
                "countryCode": "BR"
            },
            {
                "name": "Manduri",
                "countryCode": "BR"
            },
            {
                "name": "Marabá Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Maracaí",
                "countryCode": "BR"
            },
            {
                "name": "Marapoama",
                "countryCode": "BR"
            },
            {
                "name": "Marinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Mariápolis",
                "countryCode": "BR"
            },
            {
                "name": "Martinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Marília",
                "countryCode": "BR"
            },
            {
                "name": "Matão",
                "countryCode": "BR"
            },
            {
                "name": "Mauá",
                "countryCode": "BR"
            },
            {
                "name": "Mendonça",
                "countryCode": "BR"
            },
            {
                "name": "Meridiano",
                "countryCode": "BR"
            },
            {
                "name": "Mesópolis",
                "countryCode": "BR"
            },
            {
                "name": "Miguelópolis",
                "countryCode": "BR"
            },
            {
                "name": "Mineiros do Tietê",
                "countryCode": "BR"
            },
            {
                "name": "Mira Estrela",
                "countryCode": "BR"
            },
            {
                "name": "Miracatu",
                "countryCode": "BR"
            },
            {
                "name": "Mirandopólis",
                "countryCode": "BR"
            },
            {
                "name": "Mirante do Paranapanema",
                "countryCode": "BR"
            },
            {
                "name": "Mirassol",
                "countryCode": "BR"
            },
            {
                "name": "Mirassolândia",
                "countryCode": "BR"
            },
            {
                "name": "Mococa",
                "countryCode": "BR"
            },
            {
                "name": "Mogi Guaçu",
                "countryCode": "BR"
            },
            {
                "name": "Mogi Mirim",
                "countryCode": "BR"
            },
            {
                "name": "Mogi das Cruzes",
                "countryCode": "BR"
            },
            {
                "name": "Mombuca",
                "countryCode": "BR"
            },
            {
                "name": "Mongaguá",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alegre do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Monte Alto",
                "countryCode": "BR"
            },
            {
                "name": "Monte Aprazível",
                "countryCode": "BR"
            },
            {
                "name": "Monte Azul Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Monte Castelo",
                "countryCode": "BR"
            },
            {
                "name": "Monte Mor",
                "countryCode": "BR"
            },
            {
                "name": "Monteiro Lobato",
                "countryCode": "BR"
            },
            {
                "name": "Monções",
                "countryCode": "BR"
            },
            {
                "name": "Morro Agudo",
                "countryCode": "BR"
            },
            {
                "name": "Morungaba",
                "countryCode": "BR"
            },
            {
                "name": "Motuca",
                "countryCode": "BR"
            },
            {
                "name": "Murutinga do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Nantes",
                "countryCode": "BR"
            },
            {
                "name": "Narandiba",
                "countryCode": "BR"
            },
            {
                "name": "Natividade da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Nazaré Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Neves Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Nhandeara",
                "countryCode": "BR"
            },
            {
                "name": "Nipoã",
                "countryCode": "BR"
            },
            {
                "name": "Nova Aliança",
                "countryCode": "BR"
            },
            {
                "name": "Nova Campina",
                "countryCode": "BR"
            },
            {
                "name": "Nova Canaã Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Nova Castilho",
                "countryCode": "BR"
            },
            {
                "name": "Nova Europa",
                "countryCode": "BR"
            },
            {
                "name": "Nova Granada",
                "countryCode": "BR"
            },
            {
                "name": "Nova Guataporanga",
                "countryCode": "BR"
            },
            {
                "name": "Nova Independência",
                "countryCode": "BR"
            },
            {
                "name": "Nova Luzitânia",
                "countryCode": "BR"
            },
            {
                "name": "Nova Odessa",
                "countryCode": "BR"
            },
            {
                "name": "Novais",
                "countryCode": "BR"
            },
            {
                "name": "Novo Horizonte",
                "countryCode": "BR"
            },
            {
                "name": "Nuporanga",
                "countryCode": "BR"
            },
            {
                "name": "Ocauçu",
                "countryCode": "BR"
            },
            {
                "name": "Olímpia",
                "countryCode": "BR"
            },
            {
                "name": "Onda Verde",
                "countryCode": "BR"
            },
            {
                "name": "Oriente",
                "countryCode": "BR"
            },
            {
                "name": "Orindiúva",
                "countryCode": "BR"
            },
            {
                "name": "Orlândia",
                "countryCode": "BR"
            },
            {
                "name": "Osasco",
                "countryCode": "BR"
            },
            {
                "name": "Oscar Bressane",
                "countryCode": "BR"
            },
            {
                "name": "Osvaldo Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Ourinhos",
                "countryCode": "BR"
            },
            {
                "name": "Ouro Verde",
                "countryCode": "BR"
            },
            {
                "name": "Ouroeste",
                "countryCode": "BR"
            },
            {
                "name": "Pacaembu",
                "countryCode": "BR"
            },
            {
                "name": "Palestina",
                "countryCode": "BR"
            },
            {
                "name": "Palmares Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Palmeira d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Palmital",
                "countryCode": "BR"
            },
            {
                "name": "Panorama",
                "countryCode": "BR"
            },
            {
                "name": "Paraguaçu Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Paraibuna",
                "countryCode": "BR"
            },
            {
                "name": "Paranapanema",
                "countryCode": "BR"
            },
            {
                "name": "Paranapuã",
                "countryCode": "BR"
            },
            {
                "name": "Parapuã",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso",
                "countryCode": "BR"
            },
            {
                "name": "Pardinho",
                "countryCode": "BR"
            },
            {
                "name": "Pariquera Açu",
                "countryCode": "BR"
            },
            {
                "name": "Pariquera-Açu",
                "countryCode": "BR"
            },
            {
                "name": "Parisi",
                "countryCode": "BR"
            },
            {
                "name": "Patrocínio Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Paulicéia",
                "countryCode": "BR"
            },
            {
                "name": "Paulista Flórida",
                "countryCode": "BR"
            },
            {
                "name": "Paulistânia",
                "countryCode": "BR"
            },
            {
                "name": "Paulo de Faria",
                "countryCode": "BR"
            },
            {
                "name": "Paulínia",
                "countryCode": "BR"
            },
            {
                "name": "Pederneiras",
                "countryCode": "BR"
            },
            {
                "name": "Pedra Bela",
                "countryCode": "BR"
            },
            {
                "name": "Pedranópolis",
                "countryCode": "BR"
            },
            {
                "name": "Pedregulho",
                "countryCode": "BR"
            },
            {
                "name": "Pedreira",
                "countryCode": "BR"
            },
            {
                "name": "Pedrinhas Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Pedro de Toledo",
                "countryCode": "BR"
            },
            {
                "name": "Penápolis",
                "countryCode": "BR"
            },
            {
                "name": "Pereira Barreto",
                "countryCode": "BR"
            },
            {
                "name": "Pereiras",
                "countryCode": "BR"
            },
            {
                "name": "Peruíbe",
                "countryCode": "BR"
            },
            {
                "name": "Piacatu",
                "countryCode": "BR"
            },
            {
                "name": "Piedade",
                "countryCode": "BR"
            },
            {
                "name": "Pilar do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Pindamonhangaba",
                "countryCode": "BR"
            },
            {
                "name": "Pindorama",
                "countryCode": "BR"
            },
            {
                "name": "Pinhalzinho",
                "countryCode": "BR"
            },
            {
                "name": "Piquerobi",
                "countryCode": "BR"
            },
            {
                "name": "Piquete",
                "countryCode": "BR"
            },
            {
                "name": "Piracaia",
                "countryCode": "BR"
            },
            {
                "name": "Piracicaba",
                "countryCode": "BR"
            },
            {
                "name": "Piraju",
                "countryCode": "BR"
            },
            {
                "name": "Pirajuí",
                "countryCode": "BR"
            },
            {
                "name": "Pirangi",
                "countryCode": "BR"
            },
            {
                "name": "Pirapora do Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Pirapozinho",
                "countryCode": "BR"
            },
            {
                "name": "Pirassununga",
                "countryCode": "BR"
            },
            {
                "name": "Piratininga",
                "countryCode": "BR"
            },
            {
                "name": "Pitangueiras",
                "countryCode": "BR"
            },
            {
                "name": "Planalto",
                "countryCode": "BR"
            },
            {
                "name": "Platina",
                "countryCode": "BR"
            },
            {
                "name": "Poloni",
                "countryCode": "BR"
            },
            {
                "name": "Pompéia",
                "countryCode": "BR"
            },
            {
                "name": "Pongaí",
                "countryCode": "BR"
            },
            {
                "name": "Pontal",
                "countryCode": "BR"
            },
            {
                "name": "Pontalinda",
                "countryCode": "BR"
            },
            {
                "name": "Pontes Gestal",
                "countryCode": "BR"
            },
            {
                "name": "Populina",
                "countryCode": "BR"
            },
            {
                "name": "Porangaba",
                "countryCode": "BR"
            },
            {
                "name": "Porto Feliz",
                "countryCode": "BR"
            },
            {
                "name": "Porto Ferreira",
                "countryCode": "BR"
            },
            {
                "name": "Potim",
                "countryCode": "BR"
            },
            {
                "name": "Potirendaba",
                "countryCode": "BR"
            },
            {
                "name": "Poá",
                "countryCode": "BR"
            },
            {
                "name": "Pracinha",
                "countryCode": "BR"
            },
            {
                "name": "Pradópolis",
                "countryCode": "BR"
            },
            {
                "name": "Praia Grande",
                "countryCode": "BR"
            },
            {
                "name": "Pratânia",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Alves",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Bernardes",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Epitácio",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Prudente",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Venceslau",
                "countryCode": "BR"
            },
            {
                "name": "Promissão",
                "countryCode": "BR"
            },
            {
                "name": "Quadra",
                "countryCode": "BR"
            },
            {
                "name": "Quatá",
                "countryCode": "BR"
            },
            {
                "name": "Queiroz",
                "countryCode": "BR"
            },
            {
                "name": "Queluz",
                "countryCode": "BR"
            },
            {
                "name": "Quintana",
                "countryCode": "BR"
            },
            {
                "name": "Rafard",
                "countryCode": "BR"
            },
            {
                "name": "Rancharia",
                "countryCode": "BR"
            },
            {
                "name": "Redenção da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Regente Feijó",
                "countryCode": "BR"
            },
            {
                "name": "Reginópolis",
                "countryCode": "BR"
            },
            {
                "name": "Registro",
                "countryCode": "BR"
            },
            {
                "name": "Restinga",
                "countryCode": "BR"
            },
            {
                "name": "Ribeira",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Branco",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Corrente",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Grande",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Pires",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão Preto",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Ribeirão dos Índios",
                "countryCode": "BR"
            },
            {
                "name": "Rifaina",
                "countryCode": "BR"
            },
            {
                "name": "Rincão",
                "countryCode": "BR"
            },
            {
                "name": "Rinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Rio Claro",
                "countryCode": "BR"
            },
            {
                "name": "Rio Grande da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Rio das Pedras",
                "countryCode": "BR"
            },
            {
                "name": "Riolândia",
                "countryCode": "BR"
            },
            {
                "name": "Riversul",
                "countryCode": "BR"
            },
            {
                "name": "Rosana",
                "countryCode": "BR"
            },
            {
                "name": "Roseira",
                "countryCode": "BR"
            },
            {
                "name": "Rubinéia",
                "countryCode": "BR"
            },
            {
                "name": "Rubiácea",
                "countryCode": "BR"
            },
            {
                "name": "Sabino",
                "countryCode": "BR"
            },
            {
                "name": "Sagres",
                "countryCode": "BR"
            },
            {
                "name": "Sales",
                "countryCode": "BR"
            },
            {
                "name": "Sales Oliveira",
                "countryCode": "BR"
            },
            {
                "name": "Salesópolis",
                "countryCode": "BR"
            },
            {
                "name": "Salmourão",
                "countryCode": "BR"
            },
            {
                "name": "Saltinho",
                "countryCode": "BR"
            },
            {
                "name": "Salto",
                "countryCode": "BR"
            },
            {
                "name": "Salto Grande",
                "countryCode": "BR"
            },
            {
                "name": "Salto de Pirapora",
                "countryCode": "BR"
            },
            {
                "name": "Sandovalina",
                "countryCode": "BR"
            },
            {
                "name": "Santa Adélia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Albertina",
                "countryCode": "BR"
            },
            {
                "name": "Santa Branca",
                "countryCode": "BR"
            },
            {
                "name": "Santa Bárbara d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Clara d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz da Conceição",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz da Esperança",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz das Palmeiras",
                "countryCode": "BR"
            },
            {
                "name": "Santa Cruz do Rio Pardo",
                "countryCode": "BR"
            },
            {
                "name": "Santa Ernestina",
                "countryCode": "BR"
            },
            {
                "name": "Santa Fé do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Santa Gertrudes",
                "countryCode": "BR"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "BR"
            },
            {
                "name": "Santa Lúcia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Santa Mercedes",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita d'Oeste",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Passa Quatro",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa de Viterbo",
                "countryCode": "BR"
            },
            {
                "name": "Santa Salete",
                "countryCode": "BR"
            },
            {
                "name": "Santana da Ponte Pensa",
                "countryCode": "BR"
            },
            {
                "name": "Santana de Parnaíba",
                "countryCode": "BR"
            },
            {
                "name": "Santo Anastácio",
                "countryCode": "BR"
            },
            {
                "name": "Santo André",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio da Alegria",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio de Posse",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Aracanguá",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Santo Antônio do Pinhal",
                "countryCode": "BR"
            },
            {
                "name": "Santo Expedito",
                "countryCode": "BR"
            },
            {
                "name": "Santos",
                "countryCode": "BR"
            },
            {
                "name": "Santópolis do Aguapeí",
                "countryCode": "BR"
            },
            {
                "name": "Sarapuí",
                "countryCode": "BR"
            },
            {
                "name": "Sarutaiá",
                "countryCode": "BR"
            },
            {
                "name": "Sebastianópolis do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Serra Azul",
                "countryCode": "BR"
            },
            {
                "name": "Serra Negra",
                "countryCode": "BR"
            },
            {
                "name": "Serrana",
                "countryCode": "BR"
            },
            {
                "name": "Sertãozinho",
                "countryCode": "BR"
            },
            {
                "name": "Sete Barras",
                "countryCode": "BR"
            },
            {
                "name": "Severínia",
                "countryCode": "BR"
            },
            {
                "name": "Silveiras",
                "countryCode": "BR"
            },
            {
                "name": "Socorro",
                "countryCode": "BR"
            },
            {
                "name": "Sorocaba",
                "countryCode": "BR"
            },
            {
                "name": "Sud Mennucci",
                "countryCode": "BR"
            },
            {
                "name": "Sumaré",
                "countryCode": "BR"
            },
            {
                "name": "Suzano",
                "countryCode": "BR"
            },
            {
                "name": "Suzanápolis",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Sapucaí",
                "countryCode": "BR"
            },
            {
                "name": "São Bernardo do Campo",
                "countryCode": "BR"
            },
            {
                "name": "São Caetano do Sul",
                "countryCode": "BR"
            },
            {
                "name": "São Carlos",
                "countryCode": "BR"
            },
            {
                "name": "São Francisco",
                "countryCode": "BR"
            },
            {
                "name": "São Joaquim da Barra",
                "countryCode": "BR"
            },
            {
                "name": "São José da Bela Vista",
                "countryCode": "BR"
            },
            {
                "name": "São José do Barreiro",
                "countryCode": "BR"
            },
            {
                "name": "São José do Rio Pardo",
                "countryCode": "BR"
            },
            {
                "name": "São José do Rio Preto",
                "countryCode": "BR"
            },
            {
                "name": "São José dos Campos",
                "countryCode": "BR"
            },
            {
                "name": "São João da Boa Vista",
                "countryCode": "BR"
            },
            {
                "name": "São João das Duas Pontes",
                "countryCode": "BR"
            },
            {
                "name": "São João de Iracema",
                "countryCode": "BR"
            },
            {
                "name": "São João do Pau d'Alho",
                "countryCode": "BR"
            },
            {
                "name": "São Lourenço da Serra",
                "countryCode": "BR"
            },
            {
                "name": "São Luiz do Paraitinga",
                "countryCode": "BR"
            },
            {
                "name": "São Manuel",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel Arcanjo",
                "countryCode": "BR"
            },
            {
                "name": "São Paulo",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro",
                "countryCode": "BR"
            },
            {
                "name": "São Pedro do Turvo",
                "countryCode": "BR"
            },
            {
                "name": "São Roque",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião da Grama",
                "countryCode": "BR"
            },
            {
                "name": "São Simão",
                "countryCode": "BR"
            },
            {
                "name": "São Vicente",
                "countryCode": "BR"
            },
            {
                "name": "Tabapuã",
                "countryCode": "BR"
            },
            {
                "name": "Tabatinga",
                "countryCode": "BR"
            },
            {
                "name": "Taboão da Serra",
                "countryCode": "BR"
            },
            {
                "name": "Taciba",
                "countryCode": "BR"
            },
            {
                "name": "Taguaí",
                "countryCode": "BR"
            },
            {
                "name": "Taiaçu",
                "countryCode": "BR"
            },
            {
                "name": "Taiúva",
                "countryCode": "BR"
            },
            {
                "name": "Tambaú",
                "countryCode": "BR"
            },
            {
                "name": "Tanabi",
                "countryCode": "BR"
            },
            {
                "name": "Tapiratiba",
                "countryCode": "BR"
            },
            {
                "name": "Tapiraí",
                "countryCode": "BR"
            },
            {
                "name": "Taquaral",
                "countryCode": "BR"
            },
            {
                "name": "Taquaritinga",
                "countryCode": "BR"
            },
            {
                "name": "Taquarituba",
                "countryCode": "BR"
            },
            {
                "name": "Taquarivaí",
                "countryCode": "BR"
            },
            {
                "name": "Tarabai",
                "countryCode": "BR"
            },
            {
                "name": "Tarumã",
                "countryCode": "BR"
            },
            {
                "name": "Tatuí",
                "countryCode": "BR"
            },
            {
                "name": "Taubaté",
                "countryCode": "BR"
            },
            {
                "name": "Tejupá",
                "countryCode": "BR"
            },
            {
                "name": "Teodoro Sampaio",
                "countryCode": "BR"
            },
            {
                "name": "Terra Roxa",
                "countryCode": "BR"
            },
            {
                "name": "Tietê",
                "countryCode": "BR"
            },
            {
                "name": "Timburi",
                "countryCode": "BR"
            },
            {
                "name": "Torre de Pedra",
                "countryCode": "BR"
            },
            {
                "name": "Torrinha",
                "countryCode": "BR"
            },
            {
                "name": "Trabiju",
                "countryCode": "BR"
            },
            {
                "name": "Tremembé",
                "countryCode": "BR"
            },
            {
                "name": "Três Fronteiras",
                "countryCode": "BR"
            },
            {
                "name": "Tuiuti",
                "countryCode": "BR"
            },
            {
                "name": "Tupi Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Tupã",
                "countryCode": "BR"
            },
            {
                "name": "Turiúba",
                "countryCode": "BR"
            },
            {
                "name": "Turmalina",
                "countryCode": "BR"
            },
            {
                "name": "Ubarana",
                "countryCode": "BR"
            },
            {
                "name": "Ubatuba",
                "countryCode": "BR"
            },
            {
                "name": "Ubirajara",
                "countryCode": "BR"
            },
            {
                "name": "Uchoa",
                "countryCode": "BR"
            },
            {
                "name": "União Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Uru",
                "countryCode": "BR"
            },
            {
                "name": "Urupês",
                "countryCode": "BR"
            },
            {
                "name": "Urânia",
                "countryCode": "BR"
            },
            {
                "name": "Valentim Gentil",
                "countryCode": "BR"
            },
            {
                "name": "Valinhos",
                "countryCode": "BR"
            },
            {
                "name": "Valparaíso",
                "countryCode": "BR"
            },
            {
                "name": "Vargem",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Grande Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Vargem Grande do Sul",
                "countryCode": "BR"
            },
            {
                "name": "Vera Cruz",
                "countryCode": "BR"
            },
            {
                "name": "Vinhedo",
                "countryCode": "BR"
            },
            {
                "name": "Viradouro",
                "countryCode": "BR"
            },
            {
                "name": "Vista Alegre do Alto",
                "countryCode": "BR"
            },
            {
                "name": "Vitória Brasil",
                "countryCode": "BR"
            },
            {
                "name": "Votorantim",
                "countryCode": "BR"
            },
            {
                "name": "Votuporanga",
                "countryCode": "BR"
            },
            {
                "name": "Várzea Paulista",
                "countryCode": "BR"
            },
            {
                "name": "Zacarias",
                "countryCode": "BR"
            },
            {
                "name": "Águas da Prata",
                "countryCode": "BR"
            },
            {
                "name": "Águas de Lindóia",
                "countryCode": "BR"
            },
            {
                "name": "Águas de Santa Bárbara",
                "countryCode": "BR"
            },
            {
                "name": "Águas de São Pedro",
                "countryCode": "BR"
            },
            {
                "name": "Álvares Florence",
                "countryCode": "BR"
            },
            {
                "name": "Álvares Machado",
                "countryCode": "BR"
            },
            {
                "name": "Álvaro de Carvalho",
                "countryCode": "BR"
            },
            {
                "name": "Óleo",
                "countryCode": "BR"
            },
            {
                "name": "Abreulândia",
                "countryCode": "BR"
            },
            {
                "name": "Aguiarnópolis",
                "countryCode": "BR"
            },
            {
                "name": "Aliança do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Almas",
                "countryCode": "BR"
            },
            {
                "name": "Alvorada",
                "countryCode": "BR"
            },
            {
                "name": "Ananás",
                "countryCode": "BR"
            },
            {
                "name": "Angico",
                "countryCode": "BR"
            },
            {
                "name": "Aparecida do Rio Negro",
                "countryCode": "BR"
            },
            {
                "name": "Aragominas",
                "countryCode": "BR"
            },
            {
                "name": "Araguacema",
                "countryCode": "BR"
            },
            {
                "name": "Araguanã",
                "countryCode": "BR"
            },
            {
                "name": "Araguatins",
                "countryCode": "BR"
            },
            {
                "name": "Araguaçu",
                "countryCode": "BR"
            },
            {
                "name": "Araguaína",
                "countryCode": "BR"
            },
            {
                "name": "Arapoema",
                "countryCode": "BR"
            },
            {
                "name": "Arraias",
                "countryCode": "BR"
            },
            {
                "name": "Augustinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Aurora do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Axixá do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Babaçulândia",
                "countryCode": "BR"
            },
            {
                "name": "Bandeirantes do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Barra do Ouro",
                "countryCode": "BR"
            },
            {
                "name": "Barrolândia",
                "countryCode": "BR"
            },
            {
                "name": "Bernardo Sayão",
                "countryCode": "BR"
            },
            {
                "name": "Bom Jesus do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Brasilândia do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Brejinho de Nazaré",
                "countryCode": "BR"
            },
            {
                "name": "Buriti do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Cachoeirinha",
                "countryCode": "BR"
            },
            {
                "name": "Campos Lindos",
                "countryCode": "BR"
            },
            {
                "name": "Cariri do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Carmolândia",
                "countryCode": "BR"
            },
            {
                "name": "Carrasco Bonito",
                "countryCode": "BR"
            },
            {
                "name": "Caseara",
                "countryCode": "BR"
            },
            {
                "name": "Centenário",
                "countryCode": "BR"
            },
            {
                "name": "Chapada da Natividade",
                "countryCode": "BR"
            },
            {
                "name": "Chapada de Areia",
                "countryCode": "BR"
            },
            {
                "name": "Colinas do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Colméia",
                "countryCode": "BR"
            },
            {
                "name": "Combinado",
                "countryCode": "BR"
            },
            {
                "name": "Conceição do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Couto Magalhães",
                "countryCode": "BR"
            },
            {
                "name": "Cristalândia",
                "countryCode": "BR"
            },
            {
                "name": "Crixás do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Darcinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Dianópolis",
                "countryCode": "BR"
            },
            {
                "name": "Divinópolis do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Dois Irmãos do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Dueré",
                "countryCode": "BR"
            },
            {
                "name": "Esperantina",
                "countryCode": "BR"
            },
            {
                "name": "Figueirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Filadélfia",
                "countryCode": "BR"
            },
            {
                "name": "Formoso do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Fortaleza do Tabocão",
                "countryCode": "BR"
            },
            {
                "name": "Fátima",
                "countryCode": "BR"
            },
            {
                "name": "Goianorte",
                "countryCode": "BR"
            },
            {
                "name": "Goiatins",
                "countryCode": "BR"
            },
            {
                "name": "Guaraí",
                "countryCode": "BR"
            },
            {
                "name": "Gurupi",
                "countryCode": "BR"
            },
            {
                "name": "Ipueiras",
                "countryCode": "BR"
            },
            {
                "name": "Itacajá",
                "countryCode": "BR"
            },
            {
                "name": "Itaguatins",
                "countryCode": "BR"
            },
            {
                "name": "Itapiratins",
                "countryCode": "BR"
            },
            {
                "name": "Itaporã do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Jaú do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Juarina",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa da Confusão",
                "countryCode": "BR"
            },
            {
                "name": "Lagoa do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Lajeado",
                "countryCode": "BR"
            },
            {
                "name": "Lavandeira",
                "countryCode": "BR"
            },
            {
                "name": "Lizarda",
                "countryCode": "BR"
            },
            {
                "name": "Luzinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Marianópolis do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Mateiros",
                "countryCode": "BR"
            },
            {
                "name": "Maurilândia do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Miracema do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Miranorte",
                "countryCode": "BR"
            },
            {
                "name": "Monte Santo do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Monte do Carmo",
                "countryCode": "BR"
            },
            {
                "name": "Muricilândia",
                "countryCode": "BR"
            },
            {
                "name": "Natividade",
                "countryCode": "BR"
            },
            {
                "name": "Nazaré",
                "countryCode": "BR"
            },
            {
                "name": "Nova Olinda",
                "countryCode": "BR"
            },
            {
                "name": "Nova Rosalândia",
                "countryCode": "BR"
            },
            {
                "name": "Novo Acordo",
                "countryCode": "BR"
            },
            {
                "name": "Novo Alegre",
                "countryCode": "BR"
            },
            {
                "name": "Novo Jardim",
                "countryCode": "BR"
            },
            {
                "name": "Oliveira de Fátima",
                "countryCode": "BR"
            },
            {
                "name": "Palmas",
                "countryCode": "BR"
            },
            {
                "name": "Palmeirante",
                "countryCode": "BR"
            },
            {
                "name": "Palmeiras do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Palmeirópolis",
                "countryCode": "BR"
            },
            {
                "name": "Paranã",
                "countryCode": "BR"
            },
            {
                "name": "Paraíso do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Pau d'Arco",
                "countryCode": "BR"
            },
            {
                "name": "Pedro Afonso",
                "countryCode": "BR"
            },
            {
                "name": "Peixe",
                "countryCode": "BR"
            },
            {
                "name": "Pequizeiro",
                "countryCode": "BR"
            },
            {
                "name": "Pindorama do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Piraquê",
                "countryCode": "BR"
            },
            {
                "name": "Pium",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Alta do Bom Jesus",
                "countryCode": "BR"
            },
            {
                "name": "Ponte Alta do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Porto Alegre do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Porto Nacional",
                "countryCode": "BR"
            },
            {
                "name": "Praia Norte",
                "countryCode": "BR"
            },
            {
                "name": "Presidente Kennedy",
                "countryCode": "BR"
            },
            {
                "name": "Pugmil",
                "countryCode": "BR"
            },
            {
                "name": "Recursolândia",
                "countryCode": "BR"
            },
            {
                "name": "Riachinho",
                "countryCode": "BR"
            },
            {
                "name": "Rio Sono",
                "countryCode": "BR"
            },
            {
                "name": "Rio da Conceição",
                "countryCode": "BR"
            },
            {
                "name": "Rio dos Bois",
                "countryCode": "BR"
            },
            {
                "name": "Sampaio",
                "countryCode": "BR"
            },
            {
                "name": "Sandolândia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Fé do Araguaia",
                "countryCode": "BR"
            },
            {
                "name": "Santa Maria do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rita do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Santa Rosa do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Santa Tereza do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Santa Terezinha do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Silvanópolis",
                "countryCode": "BR"
            },
            {
                "name": "Sucupira",
                "countryCode": "BR"
            },
            {
                "name": "São Bento do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "São Félix do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "São Miguel do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "São Salvador do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "São Sebastião do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "São Valério",
                "countryCode": "BR"
            },
            {
                "name": "Sítio Novo do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Taguatinga",
                "countryCode": "BR"
            },
            {
                "name": "Taipas do Tocantins",
                "countryCode": "BR"
            },
            {
                "name": "Talismã",
                "countryCode": "BR"
            },
            {
                "name": "Tocantinópolis",
                "countryCode": "BR"
            },
            {
                "name": "Tocantínia",
                "countryCode": "BR"
            },
            {
                "name": "Tupirama",
                "countryCode": "BR"
            },
            {
                "name": "Tupiratins",
                "countryCode": "BR"
            },
            {
                "name": "Wanderlândia",
                "countryCode": "BR"
            },
            {
                "name": "Xambioá",
                "countryCode": "BR"
            }
        ]
    },
    {
        "name": "British Indian Ocean Territory",
        "phonecode": "246",
        "cities": []
    },
    {
        "name": "Brunei",
        "phonecode": "673",
        "cities": [
            {
                "name": "Kuala Belait",
                "countryCode": "BN"
            },
            {
                "name": "Seria",
                "countryCode": "BN"
            },
            {
                "name": "Bandar Seri Begawan",
                "countryCode": "BN"
            },
            {
                "name": "Berakas A",
                "countryCode": "BN"
            },
            {
                "name": "Kapok",
                "countryCode": "BN"
            },
            {
                "name": "Mentiri",
                "countryCode": "BN"
            },
            {
                "name": "Serasa",
                "countryCode": "BN"
            },
            {
                "name": "Bangar",
                "countryCode": "BN"
            },
            {
                "name": "Tutong",
                "countryCode": "BN"
            }
        ]
    },
    {
        "name": "Bulgaria",
        "phonecode": "359",
        "cities": [
            {
                "name": "Bansko",
                "countryCode": "BG"
            },
            {
                "name": "Belitsa",
                "countryCode": "BG"
            },
            {
                "name": "Blagoevgrad",
                "countryCode": "BG"
            },
            {
                "name": "Garmen",
                "countryCode": "BG"
            },
            {
                "name": "Gotse Delchev",
                "countryCode": "BG"
            },
            {
                "name": "Hadzhidimovo",
                "countryCode": "BG"
            },
            {
                "name": "Kolarovo",
                "countryCode": "BG"
            },
            {
                "name": "Kresna",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bansko",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Belitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Blagoevgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Garmen",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gotse Delchev",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kresna",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Petrich",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Razlog",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sandanski",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Satovcha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Simitli",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Strumyani",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Yakoruda",
                "countryCode": "BG"
            },
            {
                "name": "Petrich",
                "countryCode": "BG"
            },
            {
                "name": "Razlog",
                "countryCode": "BG"
            },
            {
                "name": "Sandanski",
                "countryCode": "BG"
            },
            {
                "name": "Satovcha",
                "countryCode": "BG"
            },
            {
                "name": "Simitli",
                "countryCode": "BG"
            },
            {
                "name": "Stara Kresna",
                "countryCode": "BG"
            },
            {
                "name": "Strumyani",
                "countryCode": "BG"
            },
            {
                "name": "Yakoruda",
                "countryCode": "BG"
            },
            {
                "name": "Aheloy",
                "countryCode": "BG"
            },
            {
                "name": "Ahtopol",
                "countryCode": "BG"
            },
            {
                "name": "Aytos",
                "countryCode": "BG"
            },
            {
                "name": "Bata",
                "countryCode": "BG"
            },
            {
                "name": "Burgas",
                "countryCode": "BG"
            },
            {
                "name": "Chernomorets",
                "countryCode": "BG"
            },
            {
                "name": "Kameno",
                "countryCode": "BG"
            },
            {
                "name": "Karnobat",
                "countryCode": "BG"
            },
            {
                "name": "Kiten",
                "countryCode": "BG"
            },
            {
                "name": "Malko Tarnovo",
                "countryCode": "BG"
            },
            {
                "name": "Nesebar",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Aytos",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Burgas",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kameno",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Karnobat",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Malko Tarnovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nesebar",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pomorie",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Primorsko",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sozopol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sungurlare",
                "countryCode": "BG"
            },
            {
                "name": "Obzor",
                "countryCode": "BG"
            },
            {
                "name": "Pomorie",
                "countryCode": "BG"
            },
            {
                "name": "Primorsko",
                "countryCode": "BG"
            },
            {
                "name": "Ravda",
                "countryCode": "BG"
            },
            {
                "name": "Ruen",
                "countryCode": "BG"
            },
            {
                "name": "Sarafovo",
                "countryCode": "BG"
            },
            {
                "name": "Sozopol",
                "countryCode": "BG"
            },
            {
                "name": "Sredets",
                "countryCode": "BG"
            },
            {
                "name": "Sungurlare",
                "countryCode": "BG"
            },
            {
                "name": "Sveti Vlas",
                "countryCode": "BG"
            },
            {
                "name": "Tsarevo",
                "countryCode": "BG"
            },
            {
                "name": "Aksakovo",
                "countryCode": "BG"
            },
            {
                "name": "Asparuhovo",
                "countryCode": "BG"
            },
            {
                "name": "Balgarevo",
                "countryCode": "BG"
            },
            {
                "name": "Beloslav",
                "countryCode": "BG"
            },
            {
                "name": "Byala",
                "countryCode": "BG"
            },
            {
                "name": "Dalgopol",
                "countryCode": "BG"
            },
            {
                "name": "Devnya",
                "countryCode": "BG"
            },
            {
                "name": "Dolni Chiflik",
                "countryCode": "BG"
            },
            {
                "name": "Kiten",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Aksakovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Avren",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Beloslav",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Byala",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dalgopol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Devnya",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dolni Chiflik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Provadia",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Suvorovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Valchidol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Varna",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Vetrino",
                "countryCode": "BG"
            },
            {
                "name": "Provadia",
                "countryCode": "BG"
            },
            {
                "name": "Suvorovo",
                "countryCode": "BG"
            },
            {
                "name": "Valchidol",
                "countryCode": "BG"
            },
            {
                "name": "Varna",
                "countryCode": "BG"
            },
            {
                "name": "Vetrino",
                "countryCode": "BG"
            },
            {
                "name": "Zlatni Pyasatsi",
                "countryCode": "BG"
            },
            {
                "name": "Byala Cherkva",
                "countryCode": "BG"
            },
            {
                "name": "Debelets",
                "countryCode": "BG"
            },
            {
                "name": "Elena",
                "countryCode": "BG"
            },
            {
                "name": "Gorna Oryahovitsa",
                "countryCode": "BG"
            },
            {
                "name": "Kilifarevo",
                "countryCode": "BG"
            },
            {
                "name": "Lyaskovets",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Elena",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gorna Oryahovitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Lyaskovets",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pavlikeni",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Polski Trambesh",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Strazhitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Suhindol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Svishtov",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Veliko Tŭrnovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Zlataritsa",
                "countryCode": "BG"
            },
            {
                "name": "Parvomaytsi",
                "countryCode": "BG"
            },
            {
                "name": "Pavlikeni",
                "countryCode": "BG"
            },
            {
                "name": "Polski Trambesh",
                "countryCode": "BG"
            },
            {
                "name": "Strazhitsa",
                "countryCode": "BG"
            },
            {
                "name": "Suhindol",
                "countryCode": "BG"
            },
            {
                "name": "Svishtov",
                "countryCode": "BG"
            },
            {
                "name": "Veliko Tŭrnovo",
                "countryCode": "BG"
            },
            {
                "name": "Zlataritsa",
                "countryCode": "BG"
            },
            {
                "name": "Belogradchik",
                "countryCode": "BG"
            },
            {
                "name": "Boynitsa",
                "countryCode": "BG"
            },
            {
                "name": "Bregovo",
                "countryCode": "BG"
            },
            {
                "name": "Chuprene",
                "countryCode": "BG"
            },
            {
                "name": "Dimovo",
                "countryCode": "BG"
            },
            {
                "name": "Drenovets",
                "countryCode": "BG"
            },
            {
                "name": "Dunavtsi",
                "countryCode": "BG"
            },
            {
                "name": "Gramada",
                "countryCode": "BG"
            },
            {
                "name": "Kula",
                "countryCode": "BG"
            },
            {
                "name": "Makresh",
                "countryCode": "BG"
            },
            {
                "name": "Novo Selo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Belogradchik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Boynitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dimovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gramada",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kula",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ruzhintsi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Vidin",
                "countryCode": "BG"
            },
            {
                "name": "Ruzhintsi",
                "countryCode": "BG"
            },
            {
                "name": "Vidin",
                "countryCode": "BG"
            },
            {
                "name": "Borovan",
                "countryCode": "BG"
            },
            {
                "name": "Byala Slatina",
                "countryCode": "BG"
            },
            {
                "name": "Hayredin",
                "countryCode": "BG"
            },
            {
                "name": "Kozloduy",
                "countryCode": "BG"
            },
            {
                "name": "Krivodol",
                "countryCode": "BG"
            },
            {
                "name": "Mezdra",
                "countryCode": "BG"
            },
            {
                "name": "Mizia",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Borovan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Hayredin",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kozloduy",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Krivodol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Mezdra",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Mizia",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Oryahovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Roman",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Vratsa",
                "countryCode": "BG"
            },
            {
                "name": "Oryahovo",
                "countryCode": "BG"
            },
            {
                "name": "Roman",
                "countryCode": "BG"
            },
            {
                "name": "Vratsa",
                "countryCode": "BG"
            },
            {
                "name": "Dryanovo",
                "countryCode": "BG"
            },
            {
                "name": "Gabrovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dryanovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gabrovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sevlievo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tryavna",
                "countryCode": "BG"
            },
            {
                "name": "Sevlievo",
                "countryCode": "BG"
            },
            {
                "name": "Tryavna",
                "countryCode": "BG"
            },
            {
                "name": "Balchik",
                "countryCode": "BG"
            },
            {
                "name": "Dobrich",
                "countryCode": "BG"
            },
            {
                "name": "General Toshevo",
                "countryCode": "BG"
            },
            {
                "name": "Kavarna",
                "countryCode": "BG"
            },
            {
                "name": "Krushari",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Balchik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dobrich",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dobrich-Selska",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina General Toshevo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kavarna",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Krushari",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Shabla",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tervel",
                "countryCode": "BG"
            },
            {
                "name": "Shabla",
                "countryCode": "BG"
            },
            {
                "name": "Tervel",
                "countryCode": "BG"
            },
            {
                "name": "Ardino",
                "countryCode": "BG"
            },
            {
                "name": "Dzhebel",
                "countryCode": "BG"
            },
            {
                "name": "Kardzhali",
                "countryCode": "BG"
            },
            {
                "name": "Kirkovo",
                "countryCode": "BG"
            },
            {
                "name": "Krumovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ardino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chernoochene",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dzhebel",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kardzhali",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kirkovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Momchilgrad",
                "countryCode": "BG"
            },
            {
                "name": "Boboshevo",
                "countryCode": "BG"
            },
            {
                "name": "Bobov Dol",
                "countryCode": "BG"
            },
            {
                "name": "Dupnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Kocherinovo",
                "countryCode": "BG"
            },
            {
                "name": "Kyustendil",
                "countryCode": "BG"
            },
            {
                "name": "Nevestino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Boboshevo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bobov Dol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dupnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kocherinovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kyustendil",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nevestino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Rila",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sapareva Banya",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Treklyano",
                "countryCode": "BG"
            },
            {
                "name": "Rila",
                "countryCode": "BG"
            },
            {
                "name": "Sapareva Banya",
                "countryCode": "BG"
            },
            {
                "name": "Apriltsi",
                "countryCode": "BG"
            },
            {
                "name": "Letnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Lovech",
                "countryCode": "BG"
            },
            {
                "name": "Lukovit",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Lovech",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Teteven",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ugarchin",
                "countryCode": "BG"
            },
            {
                "name": "Teteven",
                "countryCode": "BG"
            },
            {
                "name": "Troyan",
                "countryCode": "BG"
            },
            {
                "name": "Ugarchin",
                "countryCode": "BG"
            },
            {
                "name": "Yablanitsa",
                "countryCode": "BG"
            },
            {
                "name": "Berkovitsa",
                "countryCode": "BG"
            },
            {
                "name": "Boychinovtsi",
                "countryCode": "BG"
            },
            {
                "name": "Brusartsi",
                "countryCode": "BG"
            },
            {
                "name": "Chiprovtsi",
                "countryCode": "BG"
            },
            {
                "name": "Lom",
                "countryCode": "BG"
            },
            {
                "name": "Medkovets",
                "countryCode": "BG"
            },
            {
                "name": "Montana",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Boychinovtsi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chiprovtsi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Georgi Damyanovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Lom",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Montana",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Valchedram",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Varshets",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Yakimovo",
                "countryCode": "BG"
            },
            {
                "name": "Valchedram",
                "countryCode": "BG"
            },
            {
                "name": "Varshets",
                "countryCode": "BG"
            },
            {
                "name": "Yakimovo",
                "countryCode": "BG"
            },
            {
                "name": "Batak",
                "countryCode": "BG"
            },
            {
                "name": "Belovo",
                "countryCode": "BG"
            },
            {
                "name": "Bratsigovo",
                "countryCode": "BG"
            },
            {
                "name": "Lesichovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Batak",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Belovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bratsigovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Lesichovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Panagyurishte",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pazardzhik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Peshtera",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Rakitovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Septemvri",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Strelcha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Velingrad",
                "countryCode": "BG"
            },
            {
                "name": "Panagyurishte",
                "countryCode": "BG"
            },
            {
                "name": "Pazardzhik",
                "countryCode": "BG"
            },
            {
                "name": "Peshtera",
                "countryCode": "BG"
            },
            {
                "name": "Rakitovo",
                "countryCode": "BG"
            },
            {
                "name": "Sarnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Sarnitsa Obshtina",
                "countryCode": "BG"
            },
            {
                "name": "Septemvri",
                "countryCode": "BG"
            },
            {
                "name": "Strelcha",
                "countryCode": "BG"
            },
            {
                "name": "Velingrad",
                "countryCode": "BG"
            },
            {
                "name": "Batanovtsi",
                "countryCode": "BG"
            },
            {
                "name": "Breznik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kovachevtsi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pernik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Radomir",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Zemen",
                "countryCode": "BG"
            },
            {
                "name": "Pernik",
                "countryCode": "BG"
            },
            {
                "name": "Radomir",
                "countryCode": "BG"
            },
            {
                "name": "Tran",
                "countryCode": "BG"
            },
            {
                "name": "Zemen",
                "countryCode": "BG"
            },
            {
                "name": "Belene",
                "countryCode": "BG"
            },
            {
                "name": "Cherven Bryag",
                "countryCode": "BG"
            },
            {
                "name": "Dolna Mitropolia",
                "countryCode": "BG"
            },
            {
                "name": "Dolni Dabnik",
                "countryCode": "BG"
            },
            {
                "name": "Gulyantsi",
                "countryCode": "BG"
            },
            {
                "name": "Iskar",
                "countryCode": "BG"
            },
            {
                "name": "Knezha",
                "countryCode": "BG"
            },
            {
                "name": "Koynare",
                "countryCode": "BG"
            },
            {
                "name": "Levski",
                "countryCode": "BG"
            },
            {
                "name": "Nikopol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Belene",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Cherven Bryag",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dolna Mitropolia",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dolni Dabnik",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gulyantsi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Iskar",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Knezha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Levski",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nikopol",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pleven",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pordim",
                "countryCode": "BG"
            },
            {
                "name": "Pleven",
                "countryCode": "BG"
            },
            {
                "name": "Pordim",
                "countryCode": "BG"
            },
            {
                "name": "Slavyanovo",
                "countryCode": "BG"
            },
            {
                "name": "Asenovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Brezovo",
                "countryCode": "BG"
            },
            {
                "name": "Hisarya",
                "countryCode": "BG"
            },
            {
                "name": "Kalofer",
                "countryCode": "BG"
            },
            {
                "name": "Kaloyanovo",
                "countryCode": "BG"
            },
            {
                "name": "Karlovo",
                "countryCode": "BG"
            },
            {
                "name": "Klisura",
                "countryCode": "BG"
            },
            {
                "name": "Krichim",
                "countryCode": "BG"
            },
            {
                "name": "Laki",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Asenovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Hisarya",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kaloyanovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Karlovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Krichim",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kuklen",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Laki",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Maritsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Parvomay",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Perushtitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Plovdiv",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Rakovski",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Rodopi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sadovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Saedinenie",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sopot",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Stamboliyski",
                "countryCode": "BG"
            },
            {
                "name": "Parvomay",
                "countryCode": "BG"
            },
            {
                "name": "Perushtitsa",
                "countryCode": "BG"
            },
            {
                "name": "Plovdiv",
                "countryCode": "BG"
            },
            {
                "name": "Rakovski",
                "countryCode": "BG"
            },
            {
                "name": "Sadovo",
                "countryCode": "BG"
            },
            {
                "name": "Saedinenie",
                "countryCode": "BG"
            },
            {
                "name": "Stamboliyski",
                "countryCode": "BG"
            },
            {
                "name": "Topolovo",
                "countryCode": "BG"
            },
            {
                "name": "Isperih",
                "countryCode": "BG"
            },
            {
                "name": "Kubrat",
                "countryCode": "BG"
            },
            {
                "name": "Loznitsa",
                "countryCode": "BG"
            },
            {
                "name": "Medovene",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Isperih",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kubrat",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Loznitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Razgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Samuil",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tsar Kaloyan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Zavet",
                "countryCode": "BG"
            },
            {
                "name": "Razgrad",
                "countryCode": "BG"
            },
            {
                "name": "Samuil",
                "countryCode": "BG"
            },
            {
                "name": "Tsar Kaloyan",
                "countryCode": "BG"
            },
            {
                "name": "Zavet",
                "countryCode": "BG"
            },
            {
                "name": "Borovo",
                "countryCode": "BG"
            },
            {
                "name": "Dve Mogili",
                "countryCode": "BG"
            },
            {
                "name": "Ivanovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Borovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Byala",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dve Mogili",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ivanovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ruse",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Slivo Pole",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tsenovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Vetovo",
                "countryCode": "BG"
            },
            {
                "name": "Ruse",
                "countryCode": "BG"
            },
            {
                "name": "Senovo",
                "countryCode": "BG"
            },
            {
                "name": "Slivo Pole",
                "countryCode": "BG"
            },
            {
                "name": "Tsenovo",
                "countryCode": "BG"
            },
            {
                "name": "Vetovo",
                "countryCode": "BG"
            },
            {
                "name": "Alfatar",
                "countryCode": "BG"
            },
            {
                "name": "Dulovo",
                "countryCode": "BG"
            },
            {
                "name": "Glavinitsa",
                "countryCode": "BG"
            },
            {
                "name": "Kaynardzha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Alfatar",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dulovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Glavinitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kaynardzha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Silistra",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sitovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tutrakan",
                "countryCode": "BG"
            },
            {
                "name": "Silistra",
                "countryCode": "BG"
            },
            {
                "name": "Sitovo",
                "countryCode": "BG"
            },
            {
                "name": "Tutrakan",
                "countryCode": "BG"
            },
            {
                "name": "Kermen",
                "countryCode": "BG"
            },
            {
                "name": "Kotel",
                "countryCode": "BG"
            },
            {
                "name": "Nova Zagora",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kotel",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nova Zagora",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Sliven",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tvarditsa",
                "countryCode": "BG"
            },
            {
                "name": "Sliven",
                "countryCode": "BG"
            },
            {
                "name": "Tvarditsa",
                "countryCode": "BG"
            },
            {
                "name": "Banite",
                "countryCode": "BG"
            },
            {
                "name": "Borino",
                "countryCode": "BG"
            },
            {
                "name": "Chepelare",
                "countryCode": "BG"
            },
            {
                "name": "Devin",
                "countryCode": "BG"
            },
            {
                "name": "Dospat",
                "countryCode": "BG"
            },
            {
                "name": "Gyovren",
                "countryCode": "BG"
            },
            {
                "name": "Madan",
                "countryCode": "BG"
            },
            {
                "name": "Nedelino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Banite",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Borino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chepelare",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Devin",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dospat",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Madan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nedelino",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Rudozem",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Smolyan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Zlatograd",
                "countryCode": "BG"
            },
            {
                "name": "Rudozem",
                "countryCode": "BG"
            },
            {
                "name": "Smolyan",
                "countryCode": "BG"
            },
            {
                "name": "Zlatograd",
                "countryCode": "BG"
            },
            {
                "name": "Buhovo",
                "countryCode": "BG"
            },
            {
                "name": "Sofia",
                "countryCode": "BG"
            },
            {
                "name": "Stolichna Obshtina",
                "countryCode": "BG"
            },
            {
                "name": "Anton",
                "countryCode": "BG"
            },
            {
                "name": "Botevgrad",
                "countryCode": "BG"
            },
            {
                "name": "Bozhurishte",
                "countryCode": "BG"
            },
            {
                "name": "Chavdar",
                "countryCode": "BG"
            },
            {
                "name": "Chelopech",
                "countryCode": "BG"
            },
            {
                "name": "Dolna Banya",
                "countryCode": "BG"
            },
            {
                "name": "Dragoman",
                "countryCode": "BG"
            },
            {
                "name": "Elin Pelin",
                "countryCode": "BG"
            },
            {
                "name": "Etropole",
                "countryCode": "BG"
            },
            {
                "name": "Godech",
                "countryCode": "BG"
            },
            {
                "name": "Gorna Malina",
                "countryCode": "BG"
            },
            {
                "name": "Ihtiman",
                "countryCode": "BG"
            },
            {
                "name": "Koprivshtitsa",
                "countryCode": "BG"
            },
            {
                "name": "Kostinbrod",
                "countryCode": "BG"
            },
            {
                "name": "Lakatnik",
                "countryCode": "BG"
            },
            {
                "name": "Mirkovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Anton",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Botevgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bozhurishte",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chavdar",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chelopech",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dolna Banya",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dragoman",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Elin Pelin",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Etropole",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gorna Malina",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Koprivshtitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kostenets",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kostinbrod",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Mirkovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pirdop",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pravets",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Samokov",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Slivnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Svoge",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Zlatitsa",
                "countryCode": "BG"
            },
            {
                "name": "Pirdop",
                "countryCode": "BG"
            },
            {
                "name": "Pravets",
                "countryCode": "BG"
            },
            {
                "name": "Samokov",
                "countryCode": "BG"
            },
            {
                "name": "Slivnitsa",
                "countryCode": "BG"
            },
            {
                "name": "Svoge",
                "countryCode": "BG"
            },
            {
                "name": "Zlatitsa",
                "countryCode": "BG"
            },
            {
                "name": "Asen",
                "countryCode": "BG"
            },
            {
                "name": "Chirpan",
                "countryCode": "BG"
            },
            {
                "name": "Gurkovo",
                "countryCode": "BG"
            },
            {
                "name": "Gŭlŭbovo",
                "countryCode": "BG"
            },
            {
                "name": "Kazanlak",
                "countryCode": "BG"
            },
            {
                "name": "Maglizh",
                "countryCode": "BG"
            },
            {
                "name": "Nikolaevo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bratya Daskalovi",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Chirpan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Galabovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Gurkovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Kazanlŭk",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Maglizh",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Nikolaevo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Opan",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Pavel Banya",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Radnevo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Stara Zagora",
                "countryCode": "BG"
            },
            {
                "name": "Pavel Banya",
                "countryCode": "BG"
            },
            {
                "name": "Radnevo",
                "countryCode": "BG"
            },
            {
                "name": "Shipka",
                "countryCode": "BG"
            },
            {
                "name": "Stara Zagora",
                "countryCode": "BG"
            },
            {
                "name": "Antonovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Antonovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Omurtag",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Opaka",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Popovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Targovishte",
                "countryCode": "BG"
            },
            {
                "name": "Omurtag",
                "countryCode": "BG"
            },
            {
                "name": "Opaka",
                "countryCode": "BG"
            },
            {
                "name": "Popovo",
                "countryCode": "BG"
            },
            {
                "name": "Targovishte",
                "countryCode": "BG"
            },
            {
                "name": "Dimitrovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Harmanli",
                "countryCode": "BG"
            },
            {
                "name": "Haskovo",
                "countryCode": "BG"
            },
            {
                "name": "Ivaylovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Lyubimets",
                "countryCode": "BG"
            },
            {
                "name": "Madzharovo",
                "countryCode": "BG"
            },
            {
                "name": "Mineralni Bani",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Dimitrovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Harmanli",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Haskovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Ivaylovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Madzharovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Mineralni Bani",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Stambolovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Svilengrad",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Topolovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Simeonovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Svilengrad",
                "countryCode": "BG"
            },
            {
                "name": "Topolovgrad",
                "countryCode": "BG"
            },
            {
                "name": "Shumen",
                "countryCode": "BG"
            },
            {
                "name": "Bolyarovo",
                "countryCode": "BG"
            },
            {
                "name": "Elhovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Bolyarovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Elhovo",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Straldzha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Tundzha",
                "countryCode": "BG"
            },
            {
                "name": "Obshtina Yambol",
                "countryCode": "BG"
            },
            {
                "name": "Straldzha",
                "countryCode": "BG"
            },
            {
                "name": "Yambol",
                "countryCode": "BG"
            }
        ]
    },
    {
        "name": "Burkina Faso",
        "phonecode": "226",
        "cities": [
            {
                "name": "Barani",
                "countryCode": "BF"
            },
            {
                "name": "Boromo",
                "countryCode": "BF"
            },
            {
                "name": "Dédougou",
                "countryCode": "BF"
            },
            {
                "name": "Nouna",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Kossi",
                "countryCode": "BF"
            },
            {
                "name": "Province des Balé",
                "countryCode": "BF"
            },
            {
                "name": "Province des Banwa",
                "countryCode": "BF"
            },
            {
                "name": "Province du Mouhoun",
                "countryCode": "BF"
            },
            {
                "name": "Province du Nayala",
                "countryCode": "BF"
            },
            {
                "name": "Province du Sourou",
                "countryCode": "BF"
            },
            {
                "name": "Salanso",
                "countryCode": "BF"
            },
            {
                "name": "Toma",
                "countryCode": "BF"
            },
            {
                "name": "Tougan",
                "countryCode": "BF"
            },
            {
                "name": "Banfora",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Comoé",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Léraba",
                "countryCode": "BF"
            },
            {
                "name": "Sindou",
                "countryCode": "BF"
            },
            {
                "name": "Kadiogo Province",
                "countryCode": "BF"
            },
            {
                "name": "Ouagadougou",
                "countryCode": "BF"
            },
            {
                "name": "Garango",
                "countryCode": "BF"
            },
            {
                "name": "Koupéla",
                "countryCode": "BF"
            },
            {
                "name": "Kouritenga Province",
                "countryCode": "BF"
            },
            {
                "name": "Ouargaye",
                "countryCode": "BF"
            },
            {
                "name": "Province du Boulgou",
                "countryCode": "BF"
            },
            {
                "name": "Province du Koulpélogo",
                "countryCode": "BF"
            },
            {
                "name": "Tenkodogo",
                "countryCode": "BF"
            },
            {
                "name": "Boulsa",
                "countryCode": "BF"
            },
            {
                "name": "Kaya",
                "countryCode": "BF"
            },
            {
                "name": "Kongoussi",
                "countryCode": "BF"
            },
            {
                "name": "Province du Bam",
                "countryCode": "BF"
            },
            {
                "name": "Province du Namentenga",
                "countryCode": "BF"
            },
            {
                "name": "Province du Sanmatenga",
                "countryCode": "BF"
            },
            {
                "name": "Goulouré",
                "countryCode": "BF"
            },
            {
                "name": "Kokologo",
                "countryCode": "BF"
            },
            {
                "name": "Koudougou",
                "countryCode": "BF"
            },
            {
                "name": "Léo",
                "countryCode": "BF"
            },
            {
                "name": "Pitmoaga",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Sissili",
                "countryCode": "BF"
            },
            {
                "name": "Province du Boulkiemdé",
                "countryCode": "BF"
            },
            {
                "name": "Province du Sanguié",
                "countryCode": "BF"
            },
            {
                "name": "Province du Ziro",
                "countryCode": "BF"
            },
            {
                "name": "Réo",
                "countryCode": "BF"
            },
            {
                "name": "Sapouy",
                "countryCode": "BF"
            },
            {
                "name": "Bazega Province",
                "countryCode": "BF"
            },
            {
                "name": "Kombissiri",
                "countryCode": "BF"
            },
            {
                "name": "Manga",
                "countryCode": "BF"
            },
            {
                "name": "Nahouri Province",
                "countryCode": "BF"
            },
            {
                "name": "Pô",
                "countryCode": "BF"
            },
            {
                "name": "Zoundweogo Province",
                "countryCode": "BF"
            },
            {
                "name": "Bogandé",
                "countryCode": "BF"
            },
            {
                "name": "Diapaga",
                "countryCode": "BF"
            },
            {
                "name": "Fada N'gourma",
                "countryCode": "BF"
            },
            {
                "name": "Gayéri",
                "countryCode": "BF"
            },
            {
                "name": "Gnagna Province",
                "countryCode": "BF"
            },
            {
                "name": "Pama",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Komandjoari",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Kompienga",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Tapoa",
                "countryCode": "BF"
            },
            {
                "name": "Province du Gourma",
                "countryCode": "BF"
            },
            {
                "name": "Bobo-Dioulasso",
                "countryCode": "BF"
            },
            {
                "name": "Houndé",
                "countryCode": "BF"
            },
            {
                "name": "Province du Houet",
                "countryCode": "BF"
            },
            {
                "name": "Province du Kénédougou",
                "countryCode": "BF"
            },
            {
                "name": "Province du Tuy",
                "countryCode": "BF"
            },
            {
                "name": "Gourcy",
                "countryCode": "BF"
            },
            {
                "name": "Ouahigouya",
                "countryCode": "BF"
            },
            {
                "name": "Province du Loroum",
                "countryCode": "BF"
            },
            {
                "name": "Province du Passoré",
                "countryCode": "BF"
            },
            {
                "name": "Province du Yatenga",
                "countryCode": "BF"
            },
            {
                "name": "Province du Zondoma",
                "countryCode": "BF"
            },
            {
                "name": "Titao",
                "countryCode": "BF"
            },
            {
                "name": "Yako",
                "countryCode": "BF"
            },
            {
                "name": "Boussé",
                "countryCode": "BF"
            },
            {
                "name": "Oubritenga",
                "countryCode": "BF"
            },
            {
                "name": "Province du Ganzourgou",
                "countryCode": "BF"
            },
            {
                "name": "Province du Kourwéogo",
                "countryCode": "BF"
            },
            {
                "name": "Ziniaré",
                "countryCode": "BF"
            },
            {
                "name": "Zorgo",
                "countryCode": "BF"
            },
            {
                "name": "Djibo",
                "countryCode": "BF"
            },
            {
                "name": "Dori",
                "countryCode": "BF"
            },
            {
                "name": "Gorom-Gorom",
                "countryCode": "BF"
            },
            {
                "name": "Province de l’Oudalan",
                "countryCode": "BF"
            },
            {
                "name": "Province du Soum",
                "countryCode": "BF"
            },
            {
                "name": "Province du Séno",
                "countryCode": "BF"
            },
            {
                "name": "Province du Yagha",
                "countryCode": "BF"
            },
            {
                "name": "Batié",
                "countryCode": "BF"
            },
            {
                "name": "Dano",
                "countryCode": "BF"
            },
            {
                "name": "Diébougou",
                "countryCode": "BF"
            },
            {
                "name": "Province de la Bougouriba",
                "countryCode": "BF"
            },
            {
                "name": "Province du Ioba",
                "countryCode": "BF"
            },
            {
                "name": "Province du Noumbièl",
                "countryCode": "BF"
            },
            {
                "name": "Province du Poni",
                "countryCode": "BF"
            }
        ]
    },
    {
        "name": "Burundi",
        "phonecode": "257",
        "cities": [
            {
                "name": "Bubanza",
                "countryCode": "BI"
            },
            {
                "name": "Bujumbura",
                "countryCode": "BI"
            },
            {
                "name": "Bururi",
                "countryCode": "BI"
            },
            {
                "name": "Cankuzo",
                "countryCode": "BI"
            },
            {
                "name": "Cibitoke",
                "countryCode": "BI"
            },
            {
                "name": "Gitega",
                "countryCode": "BI"
            },
            {
                "name": "Kirundo",
                "countryCode": "BI"
            },
            {
                "name": "Karuzi",
                "countryCode": "BI"
            },
            {
                "name": "Kayanza",
                "countryCode": "BI"
            },
            {
                "name": "Makamba",
                "countryCode": "BI"
            },
            {
                "name": "Muramvya",
                "countryCode": "BI"
            },
            {
                "name": "Mwaro",
                "countryCode": "BI"
            },
            {
                "name": "Muyinga",
                "countryCode": "BI"
            },
            {
                "name": "Ngozi",
                "countryCode": "BI"
            },
            {
                "name": "Rumonge",
                "countryCode": "BI"
            },
            {
                "name": "Rutana",
                "countryCode": "BI"
            },
            {
                "name": "Ruyigi",
                "countryCode": "BI"
            }
        ]
    },
    {
        "name": "Cambodia",
        "phonecode": "855",
        "cities": [
            {
                "name": "Mongkol Borei",
                "countryCode": "KH"
            },
            {
                "name": "Paoy Paet",
                "countryCode": "KH"
            },
            {
                "name": "Sisophon",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Malai",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Svay Chék",
                "countryCode": "KH"
            },
            {
                "name": "Kracheh",
                "countryCode": "KH"
            },
            {
                "name": "Kratié",
                "countryCode": "KH"
            },
            {
                "name": "Snuol",
                "countryCode": "KH"
            },
            {
                "name": "Krŏng Sênmônoŭrôm",
                "countryCode": "KH"
            },
            {
                "name": "Sen Monorom",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Kaev Seima",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Pech Chreada",
                "countryCode": "KH"
            },
            {
                "name": "Khan 7 Makara",
                "countryCode": "KH"
            },
            {
                "name": "Khan Châmkar Mon",
                "countryCode": "KH"
            },
            {
                "name": "Khan Duŏn Pénh",
                "countryCode": "KH"
            },
            {
                "name": "Khan Dângkaô",
                "countryCode": "KH"
            },
            {
                "name": "Khan Méan Chey",
                "countryCode": "KH"
            },
            {
                "name": "Khan Russey Keo",
                "countryCode": "KH"
            },
            {
                "name": "Khan Saen Sokh",
                "countryCode": "KH"
            },
            {
                "name": "Phnom Penh",
                "countryCode": "KH"
            },
            {
                "name": "Sangkom Thmei",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Chhêb",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ch’êh Sên",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Kulén",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Rôviĕng",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Tbêng Méanchey",
                "countryCode": "KH"
            },
            {
                "name": "Tbeng Meanchey",
                "countryCode": "KH"
            },
            {
                "name": "Prey Veng",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Kâmpóng Léav",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Mésang",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Preăh Sdéch",
                "countryCode": "KH"
            },
            {
                "name": "Bakan",
                "countryCode": "KH"
            },
            {
                "name": "Krakor",
                "countryCode": "KH"
            },
            {
                "name": "Pursat",
                "countryCode": "KH"
            },
            {
                "name": "Sampov Meas",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Kândiĕng",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Véal Vêng",
                "countryCode": "KH"
            },
            {
                "name": "Banlung",
                "countryCode": "KH"
            },
            {
                "name": "Lumphat",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ban Lŭng",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Bâ Kêv",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Koun Mom",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ou Chum",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ou Ya Dav",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ta Vêng",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ândong Méas",
                "countryCode": "KH"
            },
            {
                "name": "Siem Reap",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Prasat Bakong",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ângkôr Thum",
                "countryCode": "KH"
            },
            {
                "name": "Svay Leu",
                "countryCode": "KH"
            },
            {
                "name": "Varin",
                "countryCode": "KH"
            },
            {
                "name": "Sihanoukville",
                "countryCode": "KH"
            },
            {
                "name": "Srok Stueng Hav",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Srêsén",
                "countryCode": "KH"
            },
            {
                "name": "Stueng Traeng",
                "countryCode": "KH"
            },
            {
                "name": "Stung Treng",
                "countryCode": "KH"
            },
            {
                "name": "Battambang",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Banăn",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Bâvĭl",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Rotanak Mondol",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Âk Phnŭm",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Svay Chrŭm",
                "countryCode": "KH"
            },
            {
                "name": "Svay Rieng",
                "countryCode": "KH"
            },
            {
                "name": "Krŏng Doun Kaev",
                "countryCode": "KH"
            },
            {
                "name": "Phumĭ Véal Srê",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Borei Cholsar",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ângkôr Borei",
                "countryCode": "KH"
            },
            {
                "name": "Takeo",
                "countryCode": "KH"
            },
            {
                "name": "Samraong",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Bântéay Âmpĭl",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Sâmraông",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Trâpeăng Prasat",
                "countryCode": "KH"
            },
            {
                "name": "Krong Kep",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Dâmnăk Châng’aeur",
                "countryCode": "KH"
            },
            {
                "name": "Khan Sala Krau",
                "countryCode": "KH"
            },
            {
                "name": "Pailin",
                "countryCode": "KH"
            },
            {
                "name": "Cheung Prey",
                "countryCode": "KH"
            },
            {
                "name": "Kampong Cham",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Bathéay",
                "countryCode": "KH"
            },
            {
                "name": "Baribour",
                "countryCode": "KH"
            },
            {
                "name": "Kampong Chhnang",
                "countryCode": "KH"
            },
            {
                "name": "Rolea B'ier",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Chol Kiri",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Sameakki Mean Chey",
                "countryCode": "KH"
            },
            {
                "name": "Kampong Speu",
                "countryCode": "KH"
            },
            {
                "name": "Krŏng Chbar Mon",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Basedth",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Kông Pĭsei",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Ŏdŏngk",
                "countryCode": "KH"
            },
            {
                "name": "Angkor Chey",
                "countryCode": "KH"
            },
            {
                "name": "Banteay Meas",
                "countryCode": "KH"
            },
            {
                "name": "Chhouk District",
                "countryCode": "KH"
            },
            {
                "name": "Kampong Bay",
                "countryCode": "KH"
            },
            {
                "name": "Kampong Tranch",
                "countryCode": "KH"
            },
            {
                "name": "Kampot",
                "countryCode": "KH"
            },
            {
                "name": "Srok Tuek Chhou",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Chŭm Kiri",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Dângtóng",
                "countryCode": "KH"
            },
            {
                "name": "Krŏng Ta Khmau",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Khsăch Kândal",
                "countryCode": "KH"
            },
            {
                "name": "Ta Khmau",
                "countryCode": "KH"
            },
            {
                "name": "Koh Kong",
                "countryCode": "KH"
            },
            {
                "name": "Smach Mean Chey",
                "countryCode": "KH"
            },
            {
                "name": "Srae Ambel",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Batum Sakôr",
                "countryCode": "KH"
            },
            {
                "name": "Srŏk Môndôl Seima",
                "countryCode": "KH"
            }
        ]
    },
    {
        "name": "Cameroon",
        "phonecode": "237",
        "cities": [
            {
                "name": "Bankim",
                "countryCode": "CM"
            },
            {
                "name": "Banyo",
                "countryCode": "CM"
            },
            {
                "name": "Bélel",
                "countryCode": "CM"
            },
            {
                "name": "Djohong",
                "countryCode": "CM"
            },
            {
                "name": "Kontcha",
                "countryCode": "CM"
            },
            {
                "name": "Mayo-Banyo",
                "countryCode": "CM"
            },
            {
                "name": "Meïganga",
                "countryCode": "CM"
            },
            {
                "name": "Ngaoundéré",
                "countryCode": "CM"
            },
            {
                "name": "Somié",
                "countryCode": "CM"
            },
            {
                "name": "Tibati",
                "countryCode": "CM"
            },
            {
                "name": "Tignère",
                "countryCode": "CM"
            },
            {
                "name": "Vina",
                "countryCode": "CM"
            },
            {
                "name": "Akono",
                "countryCode": "CM"
            },
            {
                "name": "Akonolinga",
                "countryCode": "CM"
            },
            {
                "name": "Bafia",
                "countryCode": "CM"
            },
            {
                "name": "Essé",
                "countryCode": "CM"
            },
            {
                "name": "Eséka",
                "countryCode": "CM"
            },
            {
                "name": "Mbalmayo",
                "countryCode": "CM"
            },
            {
                "name": "Mbam-Et-Inoubou",
                "countryCode": "CM"
            },
            {
                "name": "Mbandjok",
                "countryCode": "CM"
            },
            {
                "name": "Mbankomo",
                "countryCode": "CM"
            },
            {
                "name": "Mefou-et-Akono",
                "countryCode": "CM"
            },
            {
                "name": "Mfoundi",
                "countryCode": "CM"
            },
            {
                "name": "Minta",
                "countryCode": "CM"
            },
            {
                "name": "Nanga Eboko",
                "countryCode": "CM"
            },
            {
                "name": "Ndikiniméki",
                "countryCode": "CM"
            },
            {
                "name": "Ngomedzap",
                "countryCode": "CM"
            },
            {
                "name": "Ngoro",
                "countryCode": "CM"
            },
            {
                "name": "Nkoteng",
                "countryCode": "CM"
            },
            {
                "name": "Ntui",
                "countryCode": "CM"
            },
            {
                "name": "Obala",
                "countryCode": "CM"
            },
            {
                "name": "Okoa",
                "countryCode": "CM"
            },
            {
                "name": "Okola",
                "countryCode": "CM"
            },
            {
                "name": "Ombésa",
                "countryCode": "CM"
            },
            {
                "name": "Saa",
                "countryCode": "CM"
            },
            {
                "name": "Yaoundé",
                "countryCode": "CM"
            },
            {
                "name": "Yoko",
                "countryCode": "CM"
            },
            {
                "name": "Évodoula",
                "countryCode": "CM"
            },
            {
                "name": "Bogo",
                "countryCode": "CM"
            },
            {
                "name": "Kaélé",
                "countryCode": "CM"
            },
            {
                "name": "Kousséri",
                "countryCode": "CM"
            },
            {
                "name": "Koza",
                "countryCode": "CM"
            },
            {
                "name": "Makary",
                "countryCode": "CM"
            },
            {
                "name": "Maroua",
                "countryCode": "CM"
            },
            {
                "name": "Mayo-Sava",
                "countryCode": "CM"
            },
            {
                "name": "Mayo-Tsanaga",
                "countryCode": "CM"
            },
            {
                "name": "Mindif",
                "countryCode": "CM"
            },
            {
                "name": "Mokolo",
                "countryCode": "CM"
            },
            {
                "name": "Mora",
                "countryCode": "CM"
            },
            {
                "name": "Yagoua",
                "countryCode": "CM"
            },
            {
                "name": "Abong Mbang",
                "countryCode": "CM"
            },
            {
                "name": "Batouri",
                "countryCode": "CM"
            },
            {
                "name": "Bertoua",
                "countryCode": "CM"
            },
            {
                "name": "Bélabo",
                "countryCode": "CM"
            },
            {
                "name": "Bétaré Oya",
                "countryCode": "CM"
            },
            {
                "name": "Dimako",
                "countryCode": "CM"
            },
            {
                "name": "Doumé",
                "countryCode": "CM"
            },
            {
                "name": "Garoua Boulaï",
                "countryCode": "CM"
            },
            {
                "name": "Mbang",
                "countryCode": "CM"
            },
            {
                "name": "Ndelele",
                "countryCode": "CM"
            },
            {
                "name": "Yokadouma",
                "countryCode": "CM"
            },
            {
                "name": "Bonabéri",
                "countryCode": "CM"
            },
            {
                "name": "Diang",
                "countryCode": "CM"
            },
            {
                "name": "Dibombari",
                "countryCode": "CM"
            },
            {
                "name": "Dizangué",
                "countryCode": "CM"
            },
            {
                "name": "Douala",
                "countryCode": "CM"
            },
            {
                "name": "Edéa",
                "countryCode": "CM"
            },
            {
                "name": "Loum",
                "countryCode": "CM"
            },
            {
                "name": "Manjo",
                "countryCode": "CM"
            },
            {
                "name": "Mbanga",
                "countryCode": "CM"
            },
            {
                "name": "Melong",
                "countryCode": "CM"
            },
            {
                "name": "Mouanko",
                "countryCode": "CM"
            },
            {
                "name": "Ndom",
                "countryCode": "CM"
            },
            {
                "name": "Ngambé",
                "countryCode": "CM"
            },
            {
                "name": "Nkongsamba",
                "countryCode": "CM"
            },
            {
                "name": "Penja",
                "countryCode": "CM"
            },
            {
                "name": "Yabassi",
                "countryCode": "CM"
            },
            {
                "name": "Faro Department",
                "countryCode": "CM"
            },
            {
                "name": "Garoua",
                "countryCode": "CM"
            },
            {
                "name": "Guider",
                "countryCode": "CM"
            },
            {
                "name": "Lagdo",
                "countryCode": "CM"
            },
            {
                "name": "Mayo-Louti",
                "countryCode": "CM"
            },
            {
                "name": "Mayo-Rey",
                "countryCode": "CM"
            },
            {
                "name": "Pitoa",
                "countryCode": "CM"
            },
            {
                "name": "Poli",
                "countryCode": "CM"
            },
            {
                "name": "Rey Bouba",
                "countryCode": "CM"
            },
            {
                "name": "Tcholliré",
                "countryCode": "CM"
            },
            {
                "name": "Babanki",
                "countryCode": "CM"
            },
            {
                "name": "Bali",
                "countryCode": "CM"
            },
            {
                "name": "Bamenda",
                "countryCode": "CM"
            },
            {
                "name": "Batibo",
                "countryCode": "CM"
            },
            {
                "name": "Belo",
                "countryCode": "CM"
            },
            {
                "name": "Boyo",
                "countryCode": "CM"
            },
            {
                "name": "Fundong",
                "countryCode": "CM"
            },
            {
                "name": "Jakiri",
                "countryCode": "CM"
            },
            {
                "name": "Kumbo",
                "countryCode": "CM"
            },
            {
                "name": "Mbengwi",
                "countryCode": "CM"
            },
            {
                "name": "Mme-Bafumen",
                "countryCode": "CM"
            },
            {
                "name": "Njinikom",
                "countryCode": "CM"
            },
            {
                "name": "Wum",
                "countryCode": "CM"
            },
            {
                "name": "Bafang",
                "countryCode": "CM"
            },
            {
                "name": "Bafoussam",
                "countryCode": "CM"
            },
            {
                "name": "Bamendjou",
                "countryCode": "CM"
            },
            {
                "name": "Bana",
                "countryCode": "CM"
            },
            {
                "name": "Bandjoun",
                "countryCode": "CM"
            },
            {
                "name": "Bangangté",
                "countryCode": "CM"
            },
            {
                "name": "Bansoa",
                "countryCode": "CM"
            },
            {
                "name": "Bazou",
                "countryCode": "CM"
            },
            {
                "name": "Dschang",
                "countryCode": "CM"
            },
            {
                "name": "Foumban",
                "countryCode": "CM"
            },
            {
                "name": "Foumbot",
                "countryCode": "CM"
            },
            {
                "name": "Hauts-Plateaux",
                "countryCode": "CM"
            },
            {
                "name": "Koung-Khi",
                "countryCode": "CM"
            },
            {
                "name": "Mbouda",
                "countryCode": "CM"
            },
            {
                "name": "Ngou",
                "countryCode": "CM"
            },
            {
                "name": "Noun",
                "countryCode": "CM"
            },
            {
                "name": "Tonga",
                "countryCode": "CM"
            },
            {
                "name": "Akom II",
                "countryCode": "CM"
            },
            {
                "name": "Ambam",
                "countryCode": "CM"
            },
            {
                "name": "Kribi",
                "countryCode": "CM"
            },
            {
                "name": "Lolodorf",
                "countryCode": "CM"
            },
            {
                "name": "Mvangué",
                "countryCode": "CM"
            },
            {
                "name": "Mvila",
                "countryCode": "CM"
            },
            {
                "name": "Sangmélima",
                "countryCode": "CM"
            },
            {
                "name": "Ébolowa",
                "countryCode": "CM"
            },
            {
                "name": "Bamusso",
                "countryCode": "CM"
            },
            {
                "name": "Bekondo",
                "countryCode": "CM"
            },
            {
                "name": "Buea",
                "countryCode": "CM"
            },
            {
                "name": "Fako Division",
                "countryCode": "CM"
            },
            {
                "name": "Fontem",
                "countryCode": "CM"
            },
            {
                "name": "Kumba",
                "countryCode": "CM"
            },
            {
                "name": "Lebialem",
                "countryCode": "CM"
            },
            {
                "name": "Limbe",
                "countryCode": "CM"
            },
            {
                "name": "Mamfe",
                "countryCode": "CM"
            },
            {
                "name": "Mundemba",
                "countryCode": "CM"
            },
            {
                "name": "Mutengene",
                "countryCode": "CM"
            },
            {
                "name": "Muyuka",
                "countryCode": "CM"
            },
            {
                "name": "Nguti",
                "countryCode": "CM"
            },
            {
                "name": "Tiko",
                "countryCode": "CM"
            }
        ]
    },
    {
        "name": "Canada",
        "phonecode": "1",
        "cities": [
            {
                "name": "Airdrie",
                "countryCode": "CA"
            },
            {
                "name": "Athabasca",
                "countryCode": "CA"
            },
            {
                "name": "Banff",
                "countryCode": "CA"
            },
            {
                "name": "Barrhead",
                "countryCode": "CA"
            },
            {
                "name": "Bassano",
                "countryCode": "CA"
            },
            {
                "name": "Beaumont",
                "countryCode": "CA"
            },
            {
                "name": "Beaverlodge",
                "countryCode": "CA"
            },
            {
                "name": "Black Diamond",
                "countryCode": "CA"
            },
            {
                "name": "Blackfalds",
                "countryCode": "CA"
            },
            {
                "name": "Bon Accord",
                "countryCode": "CA"
            },
            {
                "name": "Bonnyville",
                "countryCode": "CA"
            },
            {
                "name": "Bow Island",
                "countryCode": "CA"
            },
            {
                "name": "Brooks",
                "countryCode": "CA"
            },
            {
                "name": "Calgary",
                "countryCode": "CA"
            },
            {
                "name": "Calmar",
                "countryCode": "CA"
            },
            {
                "name": "Camrose",
                "countryCode": "CA"
            },
            {
                "name": "Canmore",
                "countryCode": "CA"
            },
            {
                "name": "Cardston",
                "countryCode": "CA"
            },
            {
                "name": "Carstairs",
                "countryCode": "CA"
            },
            {
                "name": "Chestermere",
                "countryCode": "CA"
            },
            {
                "name": "Claresholm",
                "countryCode": "CA"
            },
            {
                "name": "Coaldale",
                "countryCode": "CA"
            },
            {
                "name": "Coalhurst",
                "countryCode": "CA"
            },
            {
                "name": "Cochrane",
                "countryCode": "CA"
            },
            {
                "name": "Cold Lake",
                "countryCode": "CA"
            },
            {
                "name": "Crossfield",
                "countryCode": "CA"
            },
            {
                "name": "Devon",
                "countryCode": "CA"
            },
            {
                "name": "Didsbury",
                "countryCode": "CA"
            },
            {
                "name": "Drayton Valley",
                "countryCode": "CA"
            },
            {
                "name": "Edmonton",
                "countryCode": "CA"
            },
            {
                "name": "Edson",
                "countryCode": "CA"
            },
            {
                "name": "Elk Point",
                "countryCode": "CA"
            },
            {
                "name": "Fairview",
                "countryCode": "CA"
            },
            {
                "name": "Falher",
                "countryCode": "CA"
            },
            {
                "name": "Fort Macleod",
                "countryCode": "CA"
            },
            {
                "name": "Fort McMurray",
                "countryCode": "CA"
            },
            {
                "name": "Fort Saskatchewan",
                "countryCode": "CA"
            },
            {
                "name": "Fox Creek",
                "countryCode": "CA"
            },
            {
                "name": "Gibbons",
                "countryCode": "CA"
            },
            {
                "name": "Grand Centre",
                "countryCode": "CA"
            },
            {
                "name": "Grande Cache",
                "countryCode": "CA"
            },
            {
                "name": "Grande Prairie",
                "countryCode": "CA"
            },
            {
                "name": "Grimshaw",
                "countryCode": "CA"
            },
            {
                "name": "Hanna",
                "countryCode": "CA"
            },
            {
                "name": "Heritage Pointe",
                "countryCode": "CA"
            },
            {
                "name": "High Level",
                "countryCode": "CA"
            },
            {
                "name": "High Prairie",
                "countryCode": "CA"
            },
            {
                "name": "High River",
                "countryCode": "CA"
            },
            {
                "name": "Hinton",
                "countryCode": "CA"
            },
            {
                "name": "Irricana",
                "countryCode": "CA"
            },
            {
                "name": "Jasper Park Lodge",
                "countryCode": "CA"
            },
            {
                "name": "Killam",
                "countryCode": "CA"
            },
            {
                "name": "Lac La Biche",
                "countryCode": "CA"
            },
            {
                "name": "Lacombe",
                "countryCode": "CA"
            },
            {
                "name": "Lamont",
                "countryCode": "CA"
            },
            {
                "name": "Larkspur",
                "countryCode": "CA"
            },
            {
                "name": "Laurel",
                "countryCode": "CA"
            },
            {
                "name": "Leduc",
                "countryCode": "CA"
            },
            {
                "name": "Lethbridge",
                "countryCode": "CA"
            },
            {
                "name": "Lloydminster",
                "countryCode": "CA"
            },
            {
                "name": "Magrath",
                "countryCode": "CA"
            },
            {
                "name": "Manning",
                "countryCode": "CA"
            },
            {
                "name": "Mannville",
                "countryCode": "CA"
            },
            {
                "name": "Maple Ridge",
                "countryCode": "CA"
            },
            {
                "name": "Mayerthorpe",
                "countryCode": "CA"
            },
            {
                "name": "Medicine Hat",
                "countryCode": "CA"
            },
            {
                "name": "Mill Woods Town Centre",
                "countryCode": "CA"
            },
            {
                "name": "Millet",
                "countryCode": "CA"
            },
            {
                "name": "Morinville",
                "countryCode": "CA"
            },
            {
                "name": "Nanton",
                "countryCode": "CA"
            },
            {
                "name": "Okotoks",
                "countryCode": "CA"
            },
            {
                "name": "Olds",
                "countryCode": "CA"
            },
            {
                "name": "Peace River",
                "countryCode": "CA"
            },
            {
                "name": "Penhold",
                "countryCode": "CA"
            },
            {
                "name": "Picture Butte",
                "countryCode": "CA"
            },
            {
                "name": "Pincher Creek",
                "countryCode": "CA"
            },
            {
                "name": "Ponoka",
                "countryCode": "CA"
            },
            {
                "name": "Provost",
                "countryCode": "CA"
            },
            {
                "name": "Raymond",
                "countryCode": "CA"
            },
            {
                "name": "Red Deer",
                "countryCode": "CA"
            },
            {
                "name": "Rideau Park",
                "countryCode": "CA"
            },
            {
                "name": "Rimbey",
                "countryCode": "CA"
            },
            {
                "name": "Rocky Mountain House",
                "countryCode": "CA"
            },
            {
                "name": "Sexsmith",
                "countryCode": "CA"
            },
            {
                "name": "Sherwood Park",
                "countryCode": "CA"
            },
            {
                "name": "Silver Berry",
                "countryCode": "CA"
            },
            {
                "name": "Slave Lake",
                "countryCode": "CA"
            },
            {
                "name": "Smoky Lake",
                "countryCode": "CA"
            },
            {
                "name": "Spirit River",
                "countryCode": "CA"
            },
            {
                "name": "Springbrook",
                "countryCode": "CA"
            },
            {
                "name": "Spruce Grove",
                "countryCode": "CA"
            },
            {
                "name": "St. Albert",
                "countryCode": "CA"
            },
            {
                "name": "Stettler",
                "countryCode": "CA"
            },
            {
                "name": "Stony Plain",
                "countryCode": "CA"
            },
            {
                "name": "Strathmore",
                "countryCode": "CA"
            },
            {
                "name": "Sundre",
                "countryCode": "CA"
            },
            {
                "name": "Swan Hills",
                "countryCode": "CA"
            },
            {
                "name": "Sylvan Lake",
                "countryCode": "CA"
            },
            {
                "name": "Taber",
                "countryCode": "CA"
            },
            {
                "name": "Tamarack",
                "countryCode": "CA"
            },
            {
                "name": "Three Hills",
                "countryCode": "CA"
            },
            {
                "name": "Tofield",
                "countryCode": "CA"
            },
            {
                "name": "Two Hills",
                "countryCode": "CA"
            },
            {
                "name": "Valleyview",
                "countryCode": "CA"
            },
            {
                "name": "Vegreville",
                "countryCode": "CA"
            },
            {
                "name": "Vermilion",
                "countryCode": "CA"
            },
            {
                "name": "Viking",
                "countryCode": "CA"
            },
            {
                "name": "Vulcan",
                "countryCode": "CA"
            },
            {
                "name": "Wainwright",
                "countryCode": "CA"
            },
            {
                "name": "Wembley",
                "countryCode": "CA"
            },
            {
                "name": "Westlake",
                "countryCode": "CA"
            },
            {
                "name": "Westlock",
                "countryCode": "CA"
            },
            {
                "name": "Wetaskiwin",
                "countryCode": "CA"
            },
            {
                "name": "Whitecourt",
                "countryCode": "CA"
            },
            {
                "name": "Wild Rose",
                "countryCode": "CA"
            },
            {
                "name": "Abbotsford",
                "countryCode": "CA"
            },
            {
                "name": "Agassiz",
                "countryCode": "CA"
            },
            {
                "name": "Aldergrove",
                "countryCode": "CA"
            },
            {
                "name": "Aldergrove East",
                "countryCode": "CA"
            },
            {
                "name": "Anmore",
                "countryCode": "CA"
            },
            {
                "name": "Arbutus Ridge",
                "countryCode": "CA"
            },
            {
                "name": "Armstrong",
                "countryCode": "CA"
            },
            {
                "name": "Ashcroft",
                "countryCode": "CA"
            },
            {
                "name": "Barrière",
                "countryCode": "CA"
            },
            {
                "name": "Bowen Island",
                "countryCode": "CA"
            },
            {
                "name": "Burnaby",
                "countryCode": "CA"
            },
            {
                "name": "Burns Lake",
                "countryCode": "CA"
            },
            {
                "name": "Cache Creek",
                "countryCode": "CA"
            },
            {
                "name": "Campbell River",
                "countryCode": "CA"
            },
            {
                "name": "Castlegar",
                "countryCode": "CA"
            },
            {
                "name": "Cedar",
                "countryCode": "CA"
            },
            {
                "name": "Central Coast Regional District",
                "countryCode": "CA"
            },
            {
                "name": "Chase",
                "countryCode": "CA"
            },
            {
                "name": "Chemainus",
                "countryCode": "CA"
            },
            {
                "name": "Chetwynd",
                "countryCode": "CA"
            },
            {
                "name": "Chilliwack",
                "countryCode": "CA"
            },
            {
                "name": "Colwood",
                "countryCode": "CA"
            },
            {
                "name": "Coombs",
                "countryCode": "CA"
            },
            {
                "name": "Coquitlam",
                "countryCode": "CA"
            },
            {
                "name": "Courtenay",
                "countryCode": "CA"
            },
            {
                "name": "Cowichan Bay",
                "countryCode": "CA"
            },
            {
                "name": "Cranbrook",
                "countryCode": "CA"
            },
            {
                "name": "Creston",
                "countryCode": "CA"
            },
            {
                "name": "Cumberland",
                "countryCode": "CA"
            },
            {
                "name": "Dawson Creek",
                "countryCode": "CA"
            },
            {
                "name": "Delta",
                "countryCode": "CA"
            },
            {
                "name": "Denman Island",
                "countryCode": "CA"
            },
            {
                "name": "Denman Island Trust Area",
                "countryCode": "CA"
            },
            {
                "name": "Duck Lake",
                "countryCode": "CA"
            },
            {
                "name": "Duncan",
                "countryCode": "CA"
            },
            {
                "name": "East Wellington",
                "countryCode": "CA"
            },
            {
                "name": "Elkford",
                "countryCode": "CA"
            },
            {
                "name": "Ellison",
                "countryCode": "CA"
            },
            {
                "name": "Enderby",
                "countryCode": "CA"
            },
            {
                "name": "Fairwinds",
                "countryCode": "CA"
            },
            {
                "name": "Fernie",
                "countryCode": "CA"
            },
            {
                "name": "Fort Nelson",
                "countryCode": "CA"
            },
            {
                "name": "Fort St. John",
                "countryCode": "CA"
            },
            {
                "name": "Fraser Valley Regional District",
                "countryCode": "CA"
            },
            {
                "name": "French Creek",
                "countryCode": "CA"
            },
            {
                "name": "Fruitvale",
                "countryCode": "CA"
            },
            {
                "name": "Gibsons",
                "countryCode": "CA"
            },
            {
                "name": "Golden",
                "countryCode": "CA"
            },
            {
                "name": "Grand Forks",
                "countryCode": "CA"
            },
            {
                "name": "Hanceville",
                "countryCode": "CA"
            },
            {
                "name": "Hope",
                "countryCode": "CA"
            },
            {
                "name": "Hornby Island",
                "countryCode": "CA"
            },
            {
                "name": "Houston",
                "countryCode": "CA"
            },
            {
                "name": "Invermere",
                "countryCode": "CA"
            },
            {
                "name": "Kamloops",
                "countryCode": "CA"
            },
            {
                "name": "Kelowna",
                "countryCode": "CA"
            },
            {
                "name": "Kimberley",
                "countryCode": "CA"
            },
            {
                "name": "Kitimat",
                "countryCode": "CA"
            },
            {
                "name": "Ladner",
                "countryCode": "CA"
            },
            {
                "name": "Ladysmith",
                "countryCode": "CA"
            },
            {
                "name": "Lake Cowichan",
                "countryCode": "CA"
            },
            {
                "name": "Langford",
                "countryCode": "CA"
            },
            {
                "name": "Langley",
                "countryCode": "CA"
            },
            {
                "name": "Lillooet",
                "countryCode": "CA"
            },
            {
                "name": "Lions Bay",
                "countryCode": "CA"
            },
            {
                "name": "Logan Lake",
                "countryCode": "CA"
            },
            {
                "name": "Lumby",
                "countryCode": "CA"
            },
            {
                "name": "Mackenzie",
                "countryCode": "CA"
            },
            {
                "name": "Maple Ridge",
                "countryCode": "CA"
            },
            {
                "name": "Merritt",
                "countryCode": "CA"
            },
            {
                "name": "Metchosin",
                "countryCode": "CA"
            },
            {
                "name": "Metro Vancouver Regional District",
                "countryCode": "CA"
            },
            {
                "name": "Mission",
                "countryCode": "CA"
            },
            {
                "name": "Nakusp",
                "countryCode": "CA"
            },
            {
                "name": "Nanaimo",
                "countryCode": "CA"
            },
            {
                "name": "Nelson",
                "countryCode": "CA"
            },
            {
                "name": "New Westminster",
                "countryCode": "CA"
            },
            {
                "name": "North Cowichan",
                "countryCode": "CA"
            },
            {
                "name": "North Oyster/Yellow Point",
                "countryCode": "CA"
            },
            {
                "name": "North Saanich",
                "countryCode": "CA"
            },
            {
                "name": "North Vancouver",
                "countryCode": "CA"
            },
            {
                "name": "Oak Bay",
                "countryCode": "CA"
            },
            {
                "name": "Okanagan",
                "countryCode": "CA"
            },
            {
                "name": "Okanagan Falls",
                "countryCode": "CA"
            },
            {
                "name": "Oliver",
                "countryCode": "CA"
            },
            {
                "name": "Osoyoos",
                "countryCode": "CA"
            },
            {
                "name": "Parksville",
                "countryCode": "CA"
            },
            {
                "name": "Peace River Regional District",
                "countryCode": "CA"
            },
            {
                "name": "Peachland",
                "countryCode": "CA"
            },
            {
                "name": "Pemberton",
                "countryCode": "CA"
            },
            {
                "name": "Penticton",
                "countryCode": "CA"
            },
            {
                "name": "Pitt Meadows",
                "countryCode": "CA"
            },
            {
                "name": "Port Alberni",
                "countryCode": "CA"
            },
            {
                "name": "Port Coquitlam",
                "countryCode": "CA"
            },
            {
                "name": "Port McNeill",
                "countryCode": "CA"
            },
            {
                "name": "Port Moody",
                "countryCode": "CA"
            },
            {
                "name": "Powell River",
                "countryCode": "CA"
            },
            {
                "name": "Prince George",
                "countryCode": "CA"
            },
            {
                "name": "Prince Rupert",
                "countryCode": "CA"
            },
            {
                "name": "Princeton",
                "countryCode": "CA"
            },
            {
                "name": "Puntledge",
                "countryCode": "CA"
            },
            {
                "name": "Quesnel",
                "countryCode": "CA"
            },
            {
                "name": "Regional District of Alberni-Clayoquot",
                "countryCode": "CA"
            },
            {
                "name": "Regional District of Central Okanagan",
                "countryCode": "CA"
            },
            {
                "name": "Revelstoke",
                "countryCode": "CA"
            },
            {
                "name": "Richmond",
                "countryCode": "CA"
            },
            {
                "name": "Rossland",
                "countryCode": "CA"
            },
            {
                "name": "Royston",
                "countryCode": "CA"
            },
            {
                "name": "Salmo",
                "countryCode": "CA"
            },
            {
                "name": "Salmon Arm",
                "countryCode": "CA"
            },
            {
                "name": "Salt Spring Island",
                "countryCode": "CA"
            },
            {
                "name": "Saltair",
                "countryCode": "CA"
            },
            {
                "name": "Sechelt",
                "countryCode": "CA"
            },
            {
                "name": "Sicamous",
                "countryCode": "CA"
            },
            {
                "name": "Six Mile",
                "countryCode": "CA"
            },
            {
                "name": "Smithers",
                "countryCode": "CA"
            },
            {
                "name": "Sooke",
                "countryCode": "CA"
            },
            {
                "name": "South Pender Harbour",
                "countryCode": "CA"
            },
            {
                "name": "Sparwood",
                "countryCode": "CA"
            },
            {
                "name": "Summerland",
                "countryCode": "CA"
            },
            {
                "name": "Surrey",
                "countryCode": "CA"
            },
            {
                "name": "Terrace",
                "countryCode": "CA"
            },
            {
                "name": "Tofino",
                "countryCode": "CA"
            },
            {
                "name": "Trail",
                "countryCode": "CA"
            },
            {
                "name": "Tsawwassen",
                "countryCode": "CA"
            },
            {
                "name": "Tumbler Ridge",
                "countryCode": "CA"
            },
            {
                "name": "Ucluelet",
                "countryCode": "CA"
            },
            {
                "name": "Vancouver",
                "countryCode": "CA"
            },
            {
                "name": "Vanderhoof",
                "countryCode": "CA"
            },
            {
                "name": "Vernon",
                "countryCode": "CA"
            },
            {
                "name": "Victoria",
                "countryCode": "CA"
            },
            {
                "name": "Walnut Grove",
                "countryCode": "CA"
            },
            {
                "name": "Welcome Beach",
                "countryCode": "CA"
            },
            {
                "name": "West End",
                "countryCode": "CA"
            },
            {
                "name": "West Kelowna",
                "countryCode": "CA"
            },
            {
                "name": "West Vancouver",
                "countryCode": "CA"
            },
            {
                "name": "Whistler",
                "countryCode": "CA"
            },
            {
                "name": "White Rock",
                "countryCode": "CA"
            },
            {
                "name": "Williams Lake",
                "countryCode": "CA"
            },
            {
                "name": "Altona",
                "countryCode": "CA"
            },
            {
                "name": "Beausejour",
                "countryCode": "CA"
            },
            {
                "name": "Boissevain",
                "countryCode": "CA"
            },
            {
                "name": "Brandon",
                "countryCode": "CA"
            },
            {
                "name": "Carberry",
                "countryCode": "CA"
            },
            {
                "name": "Carman",
                "countryCode": "CA"
            },
            {
                "name": "Cross Lake 19A",
                "countryCode": "CA"
            },
            {
                "name": "Dauphin",
                "countryCode": "CA"
            },
            {
                "name": "De Salaberry",
                "countryCode": "CA"
            },
            {
                "name": "Deloraine",
                "countryCode": "CA"
            },
            {
                "name": "Flin Flon",
                "countryCode": "CA"
            },
            {
                "name": "Gimli",
                "countryCode": "CA"
            },
            {
                "name": "Grunthal",
                "countryCode": "CA"
            },
            {
                "name": "Headingley",
                "countryCode": "CA"
            },
            {
                "name": "Ile des Chênes",
                "countryCode": "CA"
            },
            {
                "name": "Killarney",
                "countryCode": "CA"
            },
            {
                "name": "La Broquerie",
                "countryCode": "CA"
            },
            {
                "name": "Lac du Bonnet",
                "countryCode": "CA"
            },
            {
                "name": "Landmark",
                "countryCode": "CA"
            },
            {
                "name": "Lorette",
                "countryCode": "CA"
            },
            {
                "name": "Melita",
                "countryCode": "CA"
            },
            {
                "name": "Minnedosa",
                "countryCode": "CA"
            },
            {
                "name": "Moose Lake",
                "countryCode": "CA"
            },
            {
                "name": "Morden",
                "countryCode": "CA"
            },
            {
                "name": "Morris",
                "countryCode": "CA"
            },
            {
                "name": "Neepawa",
                "countryCode": "CA"
            },
            {
                "name": "Niverville",
                "countryCode": "CA"
            },
            {
                "name": "Portage la Prairie",
                "countryCode": "CA"
            },
            {
                "name": "Rivers",
                "countryCode": "CA"
            },
            {
                "name": "Roblin",
                "countryCode": "CA"
            },
            {
                "name": "Selkirk",
                "countryCode": "CA"
            },
            {
                "name": "Shilo",
                "countryCode": "CA"
            },
            {
                "name": "Souris",
                "countryCode": "CA"
            },
            {
                "name": "St. Adolphe",
                "countryCode": "CA"
            },
            {
                "name": "Steinbach",
                "countryCode": "CA"
            },
            {
                "name": "Stonewall",
                "countryCode": "CA"
            },
            {
                "name": "Swan River",
                "countryCode": "CA"
            },
            {
                "name": "The Pas",
                "countryCode": "CA"
            },
            {
                "name": "Thompson",
                "countryCode": "CA"
            },
            {
                "name": "Virden",
                "countryCode": "CA"
            },
            {
                "name": "West St. Paul",
                "countryCode": "CA"
            },
            {
                "name": "Winkler",
                "countryCode": "CA"
            },
            {
                "name": "Winnipeg",
                "countryCode": "CA"
            },
            {
                "name": "Baie Ste. Anne",
                "countryCode": "CA"
            },
            {
                "name": "Bathurst",
                "countryCode": "CA"
            },
            {
                "name": "Bouctouche",
                "countryCode": "CA"
            },
            {
                "name": "Campbellton",
                "countryCode": "CA"
            },
            {
                "name": "Dieppe",
                "countryCode": "CA"
            },
            {
                "name": "Edmundston",
                "countryCode": "CA"
            },
            {
                "name": "Florenceville-Bristol",
                "countryCode": "CA"
            },
            {
                "name": "Fredericton",
                "countryCode": "CA"
            },
            {
                "name": "Fundy Bay",
                "countryCode": "CA"
            },
            {
                "name": "Grande-Digue",
                "countryCode": "CA"
            },
            {
                "name": "Greater Lakeburn",
                "countryCode": "CA"
            },
            {
                "name": "Hampton",
                "countryCode": "CA"
            },
            {
                "name": "Harrison Brook",
                "countryCode": "CA"
            },
            {
                "name": "Keswick Ridge",
                "countryCode": "CA"
            },
            {
                "name": "Lincoln",
                "countryCode": "CA"
            },
            {
                "name": "Lutes Mountain",
                "countryCode": "CA"
            },
            {
                "name": "McEwen",
                "countryCode": "CA"
            },
            {
                "name": "Miramichi",
                "countryCode": "CA"
            },
            {
                "name": "Moncton",
                "countryCode": "CA"
            },
            {
                "name": "Nackawic",
                "countryCode": "CA"
            },
            {
                "name": "New Maryland",
                "countryCode": "CA"
            },
            {
                "name": "Noonan",
                "countryCode": "CA"
            },
            {
                "name": "Oromocto",
                "countryCode": "CA"
            },
            {
                "name": "Richibucto",
                "countryCode": "CA"
            },
            {
                "name": "Sackville",
                "countryCode": "CA"
            },
            {
                "name": "Saint Andrews",
                "countryCode": "CA"
            },
            {
                "name": "Saint John",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Antoine",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "CA"
            },
            {
                "name": "Salisbury",
                "countryCode": "CA"
            },
            {
                "name": "Shediac",
                "countryCode": "CA"
            },
            {
                "name": "Shediac Bridge-Shediac River",
                "countryCode": "CA"
            },
            {
                "name": "Shippagan",
                "countryCode": "CA"
            },
            {
                "name": "Starlight Village",
                "countryCode": "CA"
            },
            {
                "name": "Sussex",
                "countryCode": "CA"
            },
            {
                "name": "Tracadie-Sheila",
                "countryCode": "CA"
            },
            {
                "name": "Wells",
                "countryCode": "CA"
            },
            {
                "name": "Bay Roberts",
                "countryCode": "CA"
            },
            {
                "name": "Bay St. George South",
                "countryCode": "CA"
            },
            {
                "name": "Bonavista",
                "countryCode": "CA"
            },
            {
                "name": "Botwood",
                "countryCode": "CA"
            },
            {
                "name": "Burgeo",
                "countryCode": "CA"
            },
            {
                "name": "Carbonear",
                "countryCode": "CA"
            },
            {
                "name": "Catalina",
                "countryCode": "CA"
            },
            {
                "name": "Channel-Port aux Basques",
                "countryCode": "CA"
            },
            {
                "name": "Clarenville-Shoal Harbour",
                "countryCode": "CA"
            },
            {
                "name": "Conception Bay South",
                "countryCode": "CA"
            },
            {
                "name": "Corner Brook",
                "countryCode": "CA"
            },
            {
                "name": "Deer Lake",
                "countryCode": "CA"
            },
            {
                "name": "Fogo Island",
                "countryCode": "CA"
            },
            {
                "name": "Gambo",
                "countryCode": "CA"
            },
            {
                "name": "Goulds",
                "countryCode": "CA"
            },
            {
                "name": "Grand Bank",
                "countryCode": "CA"
            },
            {
                "name": "Grand Falls-Windsor",
                "countryCode": "CA"
            },
            {
                "name": "Happy Valley-Goose Bay",
                "countryCode": "CA"
            },
            {
                "name": "Harbour Breton",
                "countryCode": "CA"
            },
            {
                "name": "Labrador City",
                "countryCode": "CA"
            },
            {
                "name": "Lewisporte",
                "countryCode": "CA"
            },
            {
                "name": "Marystown",
                "countryCode": "CA"
            },
            {
                "name": "Mount Pearl",
                "countryCode": "CA"
            },
            {
                "name": "Pasadena",
                "countryCode": "CA"
            },
            {
                "name": "Springdale",
                "countryCode": "CA"
            },
            {
                "name": "St. Anthony",
                "countryCode": "CA"
            },
            {
                "name": "St. John's",
                "countryCode": "CA"
            },
            {
                "name": "Stephenville",
                "countryCode": "CA"
            },
            {
                "name": "Stephenville Crossing",
                "countryCode": "CA"
            },
            {
                "name": "Torbay",
                "countryCode": "CA"
            },
            {
                "name": "Upper Island Cove",
                "countryCode": "CA"
            },
            {
                "name": "Wabana",
                "countryCode": "CA"
            },
            {
                "name": "Amherst",
                "countryCode": "CA"
            },
            {
                "name": "Annapolis County",
                "countryCode": "CA"
            },
            {
                "name": "Antigonish",
                "countryCode": "CA"
            },
            {
                "name": "Berwick",
                "countryCode": "CA"
            },
            {
                "name": "Bridgewater",
                "countryCode": "CA"
            },
            {
                "name": "Cape Breton County",
                "countryCode": "CA"
            },
            {
                "name": "Chester",
                "countryCode": "CA"
            },
            {
                "name": "Colchester",
                "countryCode": "CA"
            },
            {
                "name": "Cole Harbour",
                "countryCode": "CA"
            },
            {
                "name": "Cow Bay",
                "countryCode": "CA"
            },
            {
                "name": "Dartmouth",
                "countryCode": "CA"
            },
            {
                "name": "Digby",
                "countryCode": "CA"
            },
            {
                "name": "Digby County",
                "countryCode": "CA"
            },
            {
                "name": "English Corner",
                "countryCode": "CA"
            },
            {
                "name": "Eskasoni 3",
                "countryCode": "CA"
            },
            {
                "name": "Fall River",
                "countryCode": "CA"
            },
            {
                "name": "Glace Bay",
                "countryCode": "CA"
            },
            {
                "name": "Greenwood",
                "countryCode": "CA"
            },
            {
                "name": "Halifax",
                "countryCode": "CA"
            },
            {
                "name": "Hantsport",
                "countryCode": "CA"
            },
            {
                "name": "Hayes Subdivision",
                "countryCode": "CA"
            },
            {
                "name": "Kentville",
                "countryCode": "CA"
            },
            {
                "name": "Lake Echo",
                "countryCode": "CA"
            },
            {
                "name": "Lantz",
                "countryCode": "CA"
            },
            {
                "name": "Lower Sackville",
                "countryCode": "CA"
            },
            {
                "name": "Lunenburg",
                "countryCode": "CA"
            },
            {
                "name": "Middleton",
                "countryCode": "CA"
            },
            {
                "name": "New Glasgow",
                "countryCode": "CA"
            },
            {
                "name": "Oxford",
                "countryCode": "CA"
            },
            {
                "name": "Parrsboro",
                "countryCode": "CA"
            },
            {
                "name": "Pictou",
                "countryCode": "CA"
            },
            {
                "name": "Pictou County",
                "countryCode": "CA"
            },
            {
                "name": "Port Hawkesbury",
                "countryCode": "CA"
            },
            {
                "name": "Port Williams",
                "countryCode": "CA"
            },
            {
                "name": "Princeville",
                "countryCode": "CA"
            },
            {
                "name": "Shelburne",
                "countryCode": "CA"
            },
            {
                "name": "Springhill",
                "countryCode": "CA"
            },
            {
                "name": "Sydney",
                "countryCode": "CA"
            },
            {
                "name": "Sydney Mines",
                "countryCode": "CA"
            },
            {
                "name": "Truro",
                "countryCode": "CA"
            },
            {
                "name": "Windsor",
                "countryCode": "CA"
            },
            {
                "name": "Wolfville",
                "countryCode": "CA"
            },
            {
                "name": "Yarmouth",
                "countryCode": "CA"
            },
            {
                "name": "Behchokǫ̀",
                "countryCode": "CA"
            },
            {
                "name": "Fort McPherson",
                "countryCode": "CA"
            },
            {
                "name": "Fort Smith",
                "countryCode": "CA"
            },
            {
                "name": "Hay River",
                "countryCode": "CA"
            },
            {
                "name": "Inuvik",
                "countryCode": "CA"
            },
            {
                "name": "Norman Wells",
                "countryCode": "CA"
            },
            {
                "name": "Yellowknife",
                "countryCode": "CA"
            },
            {
                "name": "Clyde River",
                "countryCode": "CA"
            },
            {
                "name": "Gjoa Haven",
                "countryCode": "CA"
            },
            {
                "name": "Iqaluit",
                "countryCode": "CA"
            },
            {
                "name": "Kugluktuk",
                "countryCode": "CA"
            },
            {
                "name": "Pangnirtung",
                "countryCode": "CA"
            },
            {
                "name": "Rankin Inlet",
                "countryCode": "CA"
            },
            {
                "name": "Ajax",
                "countryCode": "CA"
            },
            {
                "name": "Algoma",
                "countryCode": "CA"
            },
            {
                "name": "Alliston",
                "countryCode": "CA"
            },
            {
                "name": "Amherstburg",
                "countryCode": "CA"
            },
            {
                "name": "Amigo Beach",
                "countryCode": "CA"
            },
            {
                "name": "Ancaster",
                "countryCode": "CA"
            },
            {
                "name": "Angus",
                "countryCode": "CA"
            },
            {
                "name": "Arnprior",
                "countryCode": "CA"
            },
            {
                "name": "Atikokan",
                "countryCode": "CA"
            },
            {
                "name": "Attawapiskat",
                "countryCode": "CA"
            },
            {
                "name": "Aurora",
                "countryCode": "CA"
            },
            {
                "name": "Aylmer",
                "countryCode": "CA"
            },
            {
                "name": "Azilda",
                "countryCode": "CA"
            },
            {
                "name": "Ballantrae",
                "countryCode": "CA"
            },
            {
                "name": "Bancroft",
                "countryCode": "CA"
            },
            {
                "name": "Barrie",
                "countryCode": "CA"
            },
            {
                "name": "Bath",
                "countryCode": "CA"
            },
            {
                "name": "Belleville",
                "countryCode": "CA"
            },
            {
                "name": "Bells Corners",
                "countryCode": "CA"
            },
            {
                "name": "Belmont",
                "countryCode": "CA"
            },
            {
                "name": "Binbrook",
                "countryCode": "CA"
            },
            {
                "name": "Bluewater",
                "countryCode": "CA"
            },
            {
                "name": "Bourget",
                "countryCode": "CA"
            },
            {
                "name": "Bracebridge",
                "countryCode": "CA"
            },
            {
                "name": "Brampton",
                "countryCode": "CA"
            },
            {
                "name": "Brant",
                "countryCode": "CA"
            },
            {
                "name": "Brantford",
                "countryCode": "CA"
            },
            {
                "name": "Brockville",
                "countryCode": "CA"
            },
            {
                "name": "Brussels",
                "countryCode": "CA"
            },
            {
                "name": "Burford",
                "countryCode": "CA"
            },
            {
                "name": "Burlington",
                "countryCode": "CA"
            },
            {
                "name": "Cambridge",
                "countryCode": "CA"
            },
            {
                "name": "Camlachie",
                "countryCode": "CA"
            },
            {
                "name": "Capreol",
                "countryCode": "CA"
            },
            {
                "name": "Carleton Place",
                "countryCode": "CA"
            },
            {
                "name": "Casselman",
                "countryCode": "CA"
            },
            {
                "name": "Chatham",
                "countryCode": "CA"
            },
            {
                "name": "Chatham-Kent",
                "countryCode": "CA"
            },
            {
                "name": "Clarence-Rockland",
                "countryCode": "CA"
            },
            {
                "name": "Cobourg",
                "countryCode": "CA"
            },
            {
                "name": "Cochrane District",
                "countryCode": "CA"
            },
            {
                "name": "Collingwood",
                "countryCode": "CA"
            },
            {
                "name": "Concord",
                "countryCode": "CA"
            },
            {
                "name": "Constance Bay",
                "countryCode": "CA"
            },
            {
                "name": "Cookstown",
                "countryCode": "CA"
            },
            {
                "name": "Cornwall",
                "countryCode": "CA"
            },
            {
                "name": "Corunna",
                "countryCode": "CA"
            },
            {
                "name": "Deep River",
                "countryCode": "CA"
            },
            {
                "name": "Delaware",
                "countryCode": "CA"
            },
            {
                "name": "Deseronto",
                "countryCode": "CA"
            },
            {
                "name": "Dorchester",
                "countryCode": "CA"
            },
            {
                "name": "Dowling",
                "countryCode": "CA"
            },
            {
                "name": "Dryden",
                "countryCode": "CA"
            },
            {
                "name": "Durham",
                "countryCode": "CA"
            },
            {
                "name": "Ear Falls",
                "countryCode": "CA"
            },
            {
                "name": "East Gwillimbury",
                "countryCode": "CA"
            },
            {
                "name": "East York",
                "countryCode": "CA"
            },
            {
                "name": "Elliot Lake",
                "countryCode": "CA"
            },
            {
                "name": "Elmvale",
                "countryCode": "CA"
            },
            {
                "name": "Englehart",
                "countryCode": "CA"
            },
            {
                "name": "Espanola",
                "countryCode": "CA"
            },
            {
                "name": "Essex",
                "countryCode": "CA"
            },
            {
                "name": "Etobicoke",
                "countryCode": "CA"
            },
            {
                "name": "Fort Erie",
                "countryCode": "CA"
            },
            {
                "name": "Fort Frances",
                "countryCode": "CA"
            },
            {
                "name": "Gananoque",
                "countryCode": "CA"
            },
            {
                "name": "Glencoe",
                "countryCode": "CA"
            },
            {
                "name": "Goderich",
                "countryCode": "CA"
            },
            {
                "name": "Golden",
                "countryCode": "CA"
            },
            {
                "name": "Gravenhurst",
                "countryCode": "CA"
            },
            {
                "name": "Greater Napanee",
                "countryCode": "CA"
            },
            {
                "name": "Greater Sudbury",
                "countryCode": "CA"
            },
            {
                "name": "Greenstone",
                "countryCode": "CA"
            },
            {
                "name": "Guelph",
                "countryCode": "CA"
            },
            {
                "name": "Haldimand County",
                "countryCode": "CA"
            },
            {
                "name": "Haliburton Village",
                "countryCode": "CA"
            },
            {
                "name": "Halton",
                "countryCode": "CA"
            },
            {
                "name": "Hamilton",
                "countryCode": "CA"
            },
            {
                "name": "Hanover",
                "countryCode": "CA"
            },
            {
                "name": "Harriston",
                "countryCode": "CA"
            },
            {
                "name": "Hawkesbury",
                "countryCode": "CA"
            },
            {
                "name": "Hearst",
                "countryCode": "CA"
            },
            {
                "name": "Hornepayne",
                "countryCode": "CA"
            },
            {
                "name": "Huntsville",
                "countryCode": "CA"
            },
            {
                "name": "Huron East",
                "countryCode": "CA"
            },
            {
                "name": "Ingersoll",
                "countryCode": "CA"
            },
            {
                "name": "Innisfil",
                "countryCode": "CA"
            },
            {
                "name": "Iroquois Falls",
                "countryCode": "CA"
            },
            {
                "name": "Jarvis",
                "countryCode": "CA"
            },
            {
                "name": "Kanata",
                "countryCode": "CA"
            },
            {
                "name": "Kapuskasing",
                "countryCode": "CA"
            },
            {
                "name": "Kawartha Lakes",
                "countryCode": "CA"
            },
            {
                "name": "Kenora",
                "countryCode": "CA"
            },
            {
                "name": "Keswick",
                "countryCode": "CA"
            },
            {
                "name": "Kincardine",
                "countryCode": "CA"
            },
            {
                "name": "King",
                "countryCode": "CA"
            },
            {
                "name": "Kingston",
                "countryCode": "CA"
            },
            {
                "name": "Kirkland Lake",
                "countryCode": "CA"
            },
            {
                "name": "Kitchener",
                "countryCode": "CA"
            },
            {
                "name": "L'Orignal",
                "countryCode": "CA"
            },
            {
                "name": "Lakefield",
                "countryCode": "CA"
            },
            {
                "name": "Lambton Shores",
                "countryCode": "CA"
            },
            {
                "name": "Lappe",
                "countryCode": "CA"
            },
            {
                "name": "Leamington",
                "countryCode": "CA"
            },
            {
                "name": "Limoges",
                "countryCode": "CA"
            },
            {
                "name": "Lindsay",
                "countryCode": "CA"
            },
            {
                "name": "Listowel",
                "countryCode": "CA"
            },
            {
                "name": "Little Current",
                "countryCode": "CA"
            },
            {
                "name": "Lively",
                "countryCode": "CA"
            },
            {
                "name": "London",
                "countryCode": "CA"
            },
            {
                "name": "Lucan",
                "countryCode": "CA"
            },
            {
                "name": "Madoc",
                "countryCode": "CA"
            },
            {
                "name": "Manitoulin District",
                "countryCode": "CA"
            },
            {
                "name": "Manitouwadge",
                "countryCode": "CA"
            },
            {
                "name": "Marathon",
                "countryCode": "CA"
            },
            {
                "name": "Markdale",
                "countryCode": "CA"
            },
            {
                "name": "Markham",
                "countryCode": "CA"
            },
            {
                "name": "Mattawa",
                "countryCode": "CA"
            },
            {
                "name": "Meaford",
                "countryCode": "CA"
            },
            {
                "name": "Metcalfe",
                "countryCode": "CA"
            },
            {
                "name": "Midland",
                "countryCode": "CA"
            },
            {
                "name": "Mildmay",
                "countryCode": "CA"
            },
            {
                "name": "Millbrook",
                "countryCode": "CA"
            },
            {
                "name": "Milton",
                "countryCode": "CA"
            },
            {
                "name": "Mississauga",
                "countryCode": "CA"
            },
            {
                "name": "Mississauga Beach",
                "countryCode": "CA"
            },
            {
                "name": "Moose Factory",
                "countryCode": "CA"
            },
            {
                "name": "Moosonee",
                "countryCode": "CA"
            },
            {
                "name": "Morrisburg",
                "countryCode": "CA"
            },
            {
                "name": "Mount Albert",
                "countryCode": "CA"
            },
            {
                "name": "Mount Brydges",
                "countryCode": "CA"
            },
            {
                "name": "Napanee",
                "countryCode": "CA"
            },
            {
                "name": "Napanee Downtown",
                "countryCode": "CA"
            },
            {
                "name": "Neebing",
                "countryCode": "CA"
            },
            {
                "name": "Nepean",
                "countryCode": "CA"
            },
            {
                "name": "New Hamburg",
                "countryCode": "CA"
            },
            {
                "name": "Newmarket",
                "countryCode": "CA"
            },
            {
                "name": "Niagara Falls",
                "countryCode": "CA"
            },
            {
                "name": "Nipissing District",
                "countryCode": "CA"
            },
            {
                "name": "Norfolk County",
                "countryCode": "CA"
            },
            {
                "name": "North Bay",
                "countryCode": "CA"
            },
            {
                "name": "North Perth",
                "countryCode": "CA"
            },
            {
                "name": "North York",
                "countryCode": "CA"
            },
            {
                "name": "Norwood",
                "countryCode": "CA"
            },
            {
                "name": "Oakville",
                "countryCode": "CA"
            },
            {
                "name": "Omemee",
                "countryCode": "CA"
            },
            {
                "name": "Orangeville",
                "countryCode": "CA"
            },
            {
                "name": "Orillia",
                "countryCode": "CA"
            },
            {
                "name": "Osgoode",
                "countryCode": "CA"
            },
            {
                "name": "Oshawa",
                "countryCode": "CA"
            },
            {
                "name": "Ottawa",
                "countryCode": "CA"
            },
            {
                "name": "Owen Sound",
                "countryCode": "CA"
            },
            {
                "name": "Paisley",
                "countryCode": "CA"
            },
            {
                "name": "Paris",
                "countryCode": "CA"
            },
            {
                "name": "Parkhill",
                "countryCode": "CA"
            },
            {
                "name": "Parry Sound",
                "countryCode": "CA"
            },
            {
                "name": "Parry Sound District",
                "countryCode": "CA"
            },
            {
                "name": "Peel",
                "countryCode": "CA"
            },
            {
                "name": "Pembroke",
                "countryCode": "CA"
            },
            {
                "name": "Perth",
                "countryCode": "CA"
            },
            {
                "name": "Petawawa",
                "countryCode": "CA"
            },
            {
                "name": "Peterborough",
                "countryCode": "CA"
            },
            {
                "name": "Petrolia",
                "countryCode": "CA"
            },
            {
                "name": "Pickering",
                "countryCode": "CA"
            },
            {
                "name": "Picton",
                "countryCode": "CA"
            },
            {
                "name": "Plantagenet",
                "countryCode": "CA"
            },
            {
                "name": "Plattsville",
                "countryCode": "CA"
            },
            {
                "name": "Port Colborne",
                "countryCode": "CA"
            },
            {
                "name": "Port Hope",
                "countryCode": "CA"
            },
            {
                "name": "Port Rowan",
                "countryCode": "CA"
            },
            {
                "name": "Port Stanley",
                "countryCode": "CA"
            },
            {
                "name": "Powassan",
                "countryCode": "CA"
            },
            {
                "name": "Prescott",
                "countryCode": "CA"
            },
            {
                "name": "Prince Edward",
                "countryCode": "CA"
            },
            {
                "name": "Queenswood Heights",
                "countryCode": "CA"
            },
            {
                "name": "Quinte West",
                "countryCode": "CA"
            },
            {
                "name": "Rainy River District",
                "countryCode": "CA"
            },
            {
                "name": "Rayside-Balfour",
                "countryCode": "CA"
            },
            {
                "name": "Red Lake",
                "countryCode": "CA"
            },
            {
                "name": "Regional Municipality of Waterloo",
                "countryCode": "CA"
            },
            {
                "name": "Renfrew",
                "countryCode": "CA"
            },
            {
                "name": "Richmond",
                "countryCode": "CA"
            },
            {
                "name": "Richmond Hill",
                "countryCode": "CA"
            },
            {
                "name": "Ridgetown",
                "countryCode": "CA"
            },
            {
                "name": "Rockwood",
                "countryCode": "CA"
            },
            {
                "name": "Russell",
                "countryCode": "CA"
            },
            {
                "name": "Sarnia",
                "countryCode": "CA"
            },
            {
                "name": "Sault Ste. Marie",
                "countryCode": "CA"
            },
            {
                "name": "Scarborough",
                "countryCode": "CA"
            },
            {
                "name": "Seaforth",
                "countryCode": "CA"
            },
            {
                "name": "Shelburne",
                "countryCode": "CA"
            },
            {
                "name": "Simcoe",
                "countryCode": "CA"
            },
            {
                "name": "Sioux Lookout",
                "countryCode": "CA"
            },
            {
                "name": "Skatepark",
                "countryCode": "CA"
            },
            {
                "name": "Smiths Falls",
                "countryCode": "CA"
            },
            {
                "name": "South Huron",
                "countryCode": "CA"
            },
            {
                "name": "South River",
                "countryCode": "CA"
            },
            {
                "name": "St. Catharines",
                "countryCode": "CA"
            },
            {
                "name": "St. George",
                "countryCode": "CA"
            },
            {
                "name": "St. Thomas",
                "countryCode": "CA"
            },
            {
                "name": "Stirling",
                "countryCode": "CA"
            },
            {
                "name": "Stoney Point",
                "countryCode": "CA"
            },
            {
                "name": "Stratford",
                "countryCode": "CA"
            },
            {
                "name": "Sudbury",
                "countryCode": "CA"
            },
            {
                "name": "Tavistock",
                "countryCode": "CA"
            },
            {
                "name": "Temiskaming Shores",
                "countryCode": "CA"
            },
            {
                "name": "Thessalon",
                "countryCode": "CA"
            },
            {
                "name": "Thorold",
                "countryCode": "CA"
            },
            {
                "name": "Thunder Bay",
                "countryCode": "CA"
            },
            {
                "name": "Thunder Bay District",
                "countryCode": "CA"
            },
            {
                "name": "Timiskaming District",
                "countryCode": "CA"
            },
            {
                "name": "Timmins",
                "countryCode": "CA"
            },
            {
                "name": "Tobermory",
                "countryCode": "CA"
            },
            {
                "name": "Toronto",
                "countryCode": "CA"
            },
            {
                "name": "Toronto county",
                "countryCode": "CA"
            },
            {
                "name": "Tottenham",
                "countryCode": "CA"
            },
            {
                "name": "Tweed",
                "countryCode": "CA"
            },
            {
                "name": "Uxbridge",
                "countryCode": "CA"
            },
            {
                "name": "Valley East",
                "countryCode": "CA"
            },
            {
                "name": "Vanier",
                "countryCode": "CA"
            },
            {
                "name": "Vaughan",
                "countryCode": "CA"
            },
            {
                "name": "Vineland",
                "countryCode": "CA"
            },
            {
                "name": "Virgil",
                "countryCode": "CA"
            },
            {
                "name": "Walpole Island",
                "countryCode": "CA"
            },
            {
                "name": "Wasaga Beach",
                "countryCode": "CA"
            },
            {
                "name": "Waterford",
                "countryCode": "CA"
            },
            {
                "name": "Waterloo",
                "countryCode": "CA"
            },
            {
                "name": "Watford",
                "countryCode": "CA"
            },
            {
                "name": "Wawa",
                "countryCode": "CA"
            },
            {
                "name": "Welland",
                "countryCode": "CA"
            },
            {
                "name": "Wellesley",
                "countryCode": "CA"
            },
            {
                "name": "Wendover",
                "countryCode": "CA"
            },
            {
                "name": "West Lorne",
                "countryCode": "CA"
            },
            {
                "name": "Willowdale",
                "countryCode": "CA"
            },
            {
                "name": "Winchester",
                "countryCode": "CA"
            },
            {
                "name": "Windsor",
                "countryCode": "CA"
            },
            {
                "name": "Wingham",
                "countryCode": "CA"
            },
            {
                "name": "Woodstock",
                "countryCode": "CA"
            },
            {
                "name": "York",
                "countryCode": "CA"
            },
            {
                "name": "Alberton",
                "countryCode": "CA"
            },
            {
                "name": "Belfast",
                "countryCode": "CA"
            },
            {
                "name": "Charlottetown",
                "countryCode": "CA"
            },
            {
                "name": "Cornwall",
                "countryCode": "CA"
            },
            {
                "name": "Fallingbrook",
                "countryCode": "CA"
            },
            {
                "name": "Kensington",
                "countryCode": "CA"
            },
            {
                "name": "Montague",
                "countryCode": "CA"
            },
            {
                "name": "Souris",
                "countryCode": "CA"
            },
            {
                "name": "Summerside",
                "countryCode": "CA"
            },
            {
                "name": "Abitibi-Témiscamingue",
                "countryCode": "CA"
            },
            {
                "name": "Acton Vale",
                "countryCode": "CA"
            },
            {
                "name": "Adstock",
                "countryCode": "CA"
            },
            {
                "name": "Albanel",
                "countryCode": "CA"
            },
            {
                "name": "Alma",
                "countryCode": "CA"
            },
            {
                "name": "Amos",
                "countryCode": "CA"
            },
            {
                "name": "Amqui",
                "countryCode": "CA"
            },
            {
                "name": "Ange-Gardien",
                "countryCode": "CA"
            },
            {
                "name": "Asbestos",
                "countryCode": "CA"
            },
            {
                "name": "Baie-Comeau",
                "countryCode": "CA"
            },
            {
                "name": "Baie-D'Urfé",
                "countryCode": "CA"
            },
            {
                "name": "Baie-Saint-Paul",
                "countryCode": "CA"
            },
            {
                "name": "Barraute",
                "countryCode": "CA"
            },
            {
                "name": "Bas-Saint-Laurent",
                "countryCode": "CA"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "CA"
            },
            {
                "name": "Beauceville",
                "countryCode": "CA"
            },
            {
                "name": "Beauharnois",
                "countryCode": "CA"
            },
            {
                "name": "Beaupré",
                "countryCode": "CA"
            },
            {
                "name": "Bedford",
                "countryCode": "CA"
            },
            {
                "name": "Beloeil",
                "countryCode": "CA"
            },
            {
                "name": "Berthierville",
                "countryCode": "CA"
            },
            {
                "name": "Blainville",
                "countryCode": "CA"
            },
            {
                "name": "Bois-des-Filion",
                "countryCode": "CA"
            },
            {
                "name": "Boisbriand",
                "countryCode": "CA"
            },
            {
                "name": "Bonaventure",
                "countryCode": "CA"
            },
            {
                "name": "Boucherville",
                "countryCode": "CA"
            },
            {
                "name": "Breakeyville",
                "countryCode": "CA"
            },
            {
                "name": "Bromont",
                "countryCode": "CA"
            },
            {
                "name": "Brossard",
                "countryCode": "CA"
            },
            {
                "name": "Brownsburg-Chatham",
                "countryCode": "CA"
            },
            {
                "name": "Buckingham",
                "countryCode": "CA"
            },
            {
                "name": "Bécancour",
                "countryCode": "CA"
            },
            {
                "name": "Cabano",
                "countryCode": "CA"
            },
            {
                "name": "Cacouna",
                "countryCode": "CA"
            },
            {
                "name": "Candiac",
                "countryCode": "CA"
            },
            {
                "name": "Cantley",
                "countryCode": "CA"
            },
            {
                "name": "Cap-Chat",
                "countryCode": "CA"
            },
            {
                "name": "Cap-Santé",
                "countryCode": "CA"
            },
            {
                "name": "Capitale-Nationale",
                "countryCode": "CA"
            },
            {
                "name": "Carignan",
                "countryCode": "CA"
            },
            {
                "name": "Carleton",
                "countryCode": "CA"
            },
            {
                "name": "Carleton-sur-Mer",
                "countryCode": "CA"
            },
            {
                "name": "Centre-du-Québec",
                "countryCode": "CA"
            },
            {
                "name": "Chambly",
                "countryCode": "CA"
            },
            {
                "name": "Chambord",
                "countryCode": "CA"
            },
            {
                "name": "Chandler",
                "countryCode": "CA"
            },
            {
                "name": "Chapais",
                "countryCode": "CA"
            },
            {
                "name": "Charlemagne",
                "countryCode": "CA"
            },
            {
                "name": "Chaudière-Appalaches",
                "countryCode": "CA"
            },
            {
                "name": "Chertsey",
                "countryCode": "CA"
            },
            {
                "name": "Chibougamau",
                "countryCode": "CA"
            },
            {
                "name": "Chute-aux-Outardes",
                "countryCode": "CA"
            },
            {
                "name": "Château-Richer",
                "countryCode": "CA"
            },
            {
                "name": "Châteauguay",
                "countryCode": "CA"
            },
            {
                "name": "Coaticook",
                "countryCode": "CA"
            },
            {
                "name": "Contrecoeur",
                "countryCode": "CA"
            },
            {
                "name": "Cookshire",
                "countryCode": "CA"
            },
            {
                "name": "Cookshire-Eaton",
                "countryCode": "CA"
            },
            {
                "name": "Coteau-du-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Cowansville",
                "countryCode": "CA"
            },
            {
                "name": "Crabtree",
                "countryCode": "CA"
            },
            {
                "name": "Côte-Nord",
                "countryCode": "CA"
            },
            {
                "name": "Côte-Saint-Luc",
                "countryCode": "CA"
            },
            {
                "name": "Danville",
                "countryCode": "CA"
            },
            {
                "name": "Daveluyville",
                "countryCode": "CA"
            },
            {
                "name": "Delson",
                "countryCode": "CA"
            },
            {
                "name": "Deux-Montagnes",
                "countryCode": "CA"
            },
            {
                "name": "Disraeli",
                "countryCode": "CA"
            },
            {
                "name": "Dolbeau-Mistassini",
                "countryCode": "CA"
            },
            {
                "name": "Dollard-Des Ormeaux",
                "countryCode": "CA"
            },
            {
                "name": "Donnacona",
                "countryCode": "CA"
            },
            {
                "name": "Dorval",
                "countryCode": "CA"
            },
            {
                "name": "Drummondville",
                "countryCode": "CA"
            },
            {
                "name": "Dunham",
                "countryCode": "CA"
            },
            {
                "name": "East Angus",
                "countryCode": "CA"
            },
            {
                "name": "East Broughton",
                "countryCode": "CA"
            },
            {
                "name": "Farnham",
                "countryCode": "CA"
            },
            {
                "name": "Ferme-Neuve",
                "countryCode": "CA"
            },
            {
                "name": "Fermont",
                "countryCode": "CA"
            },
            {
                "name": "Forestville",
                "countryCode": "CA"
            },
            {
                "name": "Fort-Coulonge",
                "countryCode": "CA"
            },
            {
                "name": "Fossambault-sur-le-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Franklin",
                "countryCode": "CA"
            },
            {
                "name": "Gaspé",
                "countryCode": "CA"
            },
            {
                "name": "Gaspésie-Îles-de-la-Madeleine",
                "countryCode": "CA"
            },
            {
                "name": "Gatineau",
                "countryCode": "CA"
            },
            {
                "name": "Godefroy",
                "countryCode": "CA"
            },
            {
                "name": "Granby",
                "countryCode": "CA"
            },
            {
                "name": "Hampstead",
                "countryCode": "CA"
            },
            {
                "name": "Hauterive",
                "countryCode": "CA"
            },
            {
                "name": "Havre-Saint-Pierre",
                "countryCode": "CA"
            },
            {
                "name": "Hudson",
                "countryCode": "CA"
            },
            {
                "name": "Huntingdon",
                "countryCode": "CA"
            },
            {
                "name": "Hérouxville",
                "countryCode": "CA"
            },
            {
                "name": "Joliette",
                "countryCode": "CA"
            },
            {
                "name": "Jonquière",
                "countryCode": "CA"
            },
            {
                "name": "Kingsey Falls",
                "countryCode": "CA"
            },
            {
                "name": "Kirkland",
                "countryCode": "CA"
            },
            {
                "name": "L'Ancienne-Lorette",
                "countryCode": "CA"
            },
            {
                "name": "L'Ange-Gardien",
                "countryCode": "CA"
            },
            {
                "name": "L'Ascension-de-Notre-Seigneur",
                "countryCode": "CA"
            },
            {
                "name": "L'Assomption",
                "countryCode": "CA"
            },
            {
                "name": "L'Épiphanie",
                "countryCode": "CA"
            },
            {
                "name": "L'Île-Perrot",
                "countryCode": "CA"
            },
            {
                "name": "La Conception",
                "countryCode": "CA"
            },
            {
                "name": "La Haute-Saint-Charles",
                "countryCode": "CA"
            },
            {
                "name": "La Malbaie",
                "countryCode": "CA"
            },
            {
                "name": "La Minerve",
                "countryCode": "CA"
            },
            {
                "name": "La Pocatière",
                "countryCode": "CA"
            },
            {
                "name": "La Prairie",
                "countryCode": "CA"
            },
            {
                "name": "La Sarre",
                "countryCode": "CA"
            },
            {
                "name": "La Tuque",
                "countryCode": "CA"
            },
            {
                "name": "Labelle",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Alouette",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Brome",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Connelly",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Lapierre",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Mégantic",
                "countryCode": "CA"
            },
            {
                "name": "Lac-Simon",
                "countryCode": "CA"
            },
            {
                "name": "Lachute",
                "countryCode": "CA"
            },
            {
                "name": "Lacolle",
                "countryCode": "CA"
            },
            {
                "name": "Lanoraie",
                "countryCode": "CA"
            },
            {
                "name": "Laval",
                "countryCode": "CA"
            },
            {
                "name": "Lavaltrie",
                "countryCode": "CA"
            },
            {
                "name": "Le Bic",
                "countryCode": "CA"
            },
            {
                "name": "Lebel-sur-Quévillon",
                "countryCode": "CA"
            },
            {
                "name": "Leblanc",
                "countryCode": "CA"
            },
            {
                "name": "Les Coteaux",
                "countryCode": "CA"
            },
            {
                "name": "Les Cèdres",
                "countryCode": "CA"
            },
            {
                "name": "Les Escoumins",
                "countryCode": "CA"
            },
            {
                "name": "Linière",
                "countryCode": "CA"
            },
            {
                "name": "Longueuil",
                "countryCode": "CA"
            },
            {
                "name": "Lorraine",
                "countryCode": "CA"
            },
            {
                "name": "Louiseville",
                "countryCode": "CA"
            },
            {
                "name": "Luceville",
                "countryCode": "CA"
            },
            {
                "name": "Lévis",
                "countryCode": "CA"
            },
            {
                "name": "Macamic",
                "countryCode": "CA"
            },
            {
                "name": "Magog",
                "countryCode": "CA"
            },
            {
                "name": "Malartic",
                "countryCode": "CA"
            },
            {
                "name": "Maliotenam",
                "countryCode": "CA"
            },
            {
                "name": "Manawan",
                "countryCode": "CA"
            },
            {
                "name": "Mandeville",
                "countryCode": "CA"
            },
            {
                "name": "Maniwaki",
                "countryCode": "CA"
            },
            {
                "name": "Maria",
                "countryCode": "CA"
            },
            {
                "name": "Marieville",
                "countryCode": "CA"
            },
            {
                "name": "Mascouche",
                "countryCode": "CA"
            },
            {
                "name": "Maskinongé",
                "countryCode": "CA"
            },
            {
                "name": "Matagami",
                "countryCode": "CA"
            },
            {
                "name": "Matane",
                "countryCode": "CA"
            },
            {
                "name": "Mauricie",
                "countryCode": "CA"
            },
            {
                "name": "Melocheville",
                "countryCode": "CA"
            },
            {
                "name": "Mercier",
                "countryCode": "CA"
            },
            {
                "name": "Metabetchouan-Lac-a-la-Croix",
                "countryCode": "CA"
            },
            {
                "name": "Mirabel",
                "countryCode": "CA"
            },
            {
                "name": "Mistissini",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Joli",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Laurier",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Royal",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Saint-Grégoire",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Saint-Hilaire",
                "countryCode": "CA"
            },
            {
                "name": "Mont-Tremblant",
                "countryCode": "CA"
            },
            {
                "name": "Montmagny",
                "countryCode": "CA"
            },
            {
                "name": "Montréal",
                "countryCode": "CA"
            },
            {
                "name": "Montréal-Est",
                "countryCode": "CA"
            },
            {
                "name": "Montréal-Ouest",
                "countryCode": "CA"
            },
            {
                "name": "Morin-Heights",
                "countryCode": "CA"
            },
            {
                "name": "Métabetchouan",
                "countryCode": "CA"
            },
            {
                "name": "Napierville",
                "countryCode": "CA"
            },
            {
                "name": "Neuville",
                "countryCode": "CA"
            },
            {
                "name": "New Carlisle",
                "countryCode": "CA"
            },
            {
                "name": "New-Richmond",
                "countryCode": "CA"
            },
            {
                "name": "Nicolet",
                "countryCode": "CA"
            },
            {
                "name": "Nord-du-Québec",
                "countryCode": "CA"
            },
            {
                "name": "Normandin",
                "countryCode": "CA"
            },
            {
                "name": "Notre-Dame-de-Grâce",
                "countryCode": "CA"
            },
            {
                "name": "Notre-Dame-de-l'Île-Perrot",
                "countryCode": "CA"
            },
            {
                "name": "Notre-Dame-des-Prairies",
                "countryCode": "CA"
            },
            {
                "name": "Notre-Dame-du-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Notre-Dame-du-Mont-Carmel",
                "countryCode": "CA"
            },
            {
                "name": "Oka",
                "countryCode": "CA"
            },
            {
                "name": "Ormstown",
                "countryCode": "CA"
            },
            {
                "name": "Otterburn Park",
                "countryCode": "CA"
            },
            {
                "name": "Outaouais",
                "countryCode": "CA"
            },
            {
                "name": "Papineauville",
                "countryCode": "CA"
            },
            {
                "name": "Parc-Boutin",
                "countryCode": "CA"
            },
            {
                "name": "Piedmont",
                "countryCode": "CA"
            },
            {
                "name": "Pierreville",
                "countryCode": "CA"
            },
            {
                "name": "Pincourt",
                "countryCode": "CA"
            },
            {
                "name": "Plessisville",
                "countryCode": "CA"
            },
            {
                "name": "Pohénégamook",
                "countryCode": "CA"
            },
            {
                "name": "Pointe-Calumet",
                "countryCode": "CA"
            },
            {
                "name": "Pointe-Claire",
                "countryCode": "CA"
            },
            {
                "name": "Pointe-du-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Pont Rouge",
                "countryCode": "CA"
            },
            {
                "name": "Pont-Rouge",
                "countryCode": "CA"
            },
            {
                "name": "Port-Cartier",
                "countryCode": "CA"
            },
            {
                "name": "Portneuf",
                "countryCode": "CA"
            },
            {
                "name": "Princeville",
                "countryCode": "CA"
            },
            {
                "name": "Prévost",
                "countryCode": "CA"
            },
            {
                "name": "Québec",
                "countryCode": "CA"
            },
            {
                "name": "Rawdon",
                "countryCode": "CA"
            },
            {
                "name": "Repentigny",
                "countryCode": "CA"
            },
            {
                "name": "Richelieu",
                "countryCode": "CA"
            },
            {
                "name": "Richmond",
                "countryCode": "CA"
            },
            {
                "name": "Rigaud",
                "countryCode": "CA"
            },
            {
                "name": "Rimouski",
                "countryCode": "CA"
            },
            {
                "name": "Rivière-Rouge",
                "countryCode": "CA"
            },
            {
                "name": "Rivière-du-Loup",
                "countryCode": "CA"
            },
            {
                "name": "Roberval",
                "countryCode": "CA"
            },
            {
                "name": "Rock Forest",
                "countryCode": "CA"
            },
            {
                "name": "Rosemère",
                "countryCode": "CA"
            },
            {
                "name": "Rougemont",
                "countryCode": "CA"
            },
            {
                "name": "Rouyn-Noranda",
                "countryCode": "CA"
            },
            {
                "name": "Sacré-Coeur",
                "countryCode": "CA"
            },
            {
                "name": "Saguenay",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Adolphe-d'Howard",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Alexandre",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Amable",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Ambroise",
                "countryCode": "CA"
            },
            {
                "name": "Saint-André-Avellin",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Anselme",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Antoine-de-Tilly",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Augustin",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Augustin-de-Desmaures",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Barnabé-Sud",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Basile-le-Grand",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Boniface",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Bruno",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Bruno-de-Guigues",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Bruno-de-Montarville",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Canut",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Charles",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Constant",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Cyrille-de-Wendover",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Césaire",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Côme-Linière",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Damase",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Denis-sur-Richelieu",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Donat-de-Montcalm",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Elzéar",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Eustache",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Félicien",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Félix-de-Valois",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Gabriel",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Georges",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Germain-de-Grantham",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Gédéon",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Henri",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Hippolyte",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Honoré",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Hyacinthe",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Isidore",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Jacques-le-Mineur",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Jean-Baptiste",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Jean-sur-Richelieu",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Joseph",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Joseph-de-Beauce",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Joseph-de-Coleraine",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Joseph-du-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Jérôme",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Lambert-de-Lauzon",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Laurent",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Lazare",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Liboire",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Lin-Laurentides",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Léonard-d'Aston",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Marc-des-Carrières",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Mathieu",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Michel",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Michel-des-Saints",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Nazaire",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Norbert",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Pacôme",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Pascal",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Philippe-de-La Prairie",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Pie",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Pierre-les-Becquets",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Prime",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Raphaël",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Raymond",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Rémi",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Rémi-de-Tingwick",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Sauveur-des-Monts",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Siméon",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Thomas",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Tite",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Victor",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Zotique",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Édouard",
                "countryCode": "CA"
            },
            {
                "name": "Saint-Éphrem-de-Beauce",
                "countryCode": "CA"
            },
            {
                "name": "Sainte Catherine de la Jacques Cartier",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Adèle",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Agathe-des-Monts",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Anne-de-Bellevue",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Anne-des-Monts",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Anne-des-Plaines",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Béatrix",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Catherine",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Croix",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Julie",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Julienne",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Madeleine",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Marie",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Marthe-sur-le-Lac",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Martine",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Sophie",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Thècle",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Thérèse",
                "countryCode": "CA"
            },
            {
                "name": "Sainte-Élisabeth",
                "countryCode": "CA"
            },
            {
                "name": "Salaberry-de-Valleyfield",
                "countryCode": "CA"
            },
            {
                "name": "Salluit",
                "countryCode": "CA"
            },
            {
                "name": "Senneterre",
                "countryCode": "CA"
            },
            {
                "name": "Sept-Îles",
                "countryCode": "CA"
            },
            {
                "name": "Shannon",
                "countryCode": "CA"
            },
            {
                "name": "Shawinigan",
                "countryCode": "CA"
            },
            {
                "name": "Shawville",
                "countryCode": "CA"
            },
            {
                "name": "Sherbrooke",
                "countryCode": "CA"
            },
            {
                "name": "Sorel-Tracy",
                "countryCode": "CA"
            },
            {
                "name": "St-Jean-Port-Joli",
                "countryCode": "CA"
            },
            {
                "name": "Sutton",
                "countryCode": "CA"
            },
            {
                "name": "Terrasse-des-Pins",
                "countryCode": "CA"
            },
            {
                "name": "Terrebonne",
                "countryCode": "CA"
            },
            {
                "name": "Thetford-Mines",
                "countryCode": "CA"
            },
            {
                "name": "Thurso",
                "countryCode": "CA"
            },
            {
                "name": "Trois-Rivières",
                "countryCode": "CA"
            },
            {
                "name": "Témiscaming",
                "countryCode": "CA"
            },
            {
                "name": "Val-David",
                "countryCode": "CA"
            },
            {
                "name": "Val-Morin",
                "countryCode": "CA"
            },
            {
                "name": "Val-d'Or",
                "countryCode": "CA"
            },
            {
                "name": "Val-des-Monts",
                "countryCode": "CA"
            },
            {
                "name": "Valcourt",
                "countryCode": "CA"
            },
            {
                "name": "Vallée-Jonction",
                "countryCode": "CA"
            },
            {
                "name": "Varennes",
                "countryCode": "CA"
            },
            {
                "name": "Vaudreuil-Dorion",
                "countryCode": "CA"
            },
            {
                "name": "Venise-en-Québec",
                "countryCode": "CA"
            },
            {
                "name": "Verchères",
                "countryCode": "CA"
            },
            {
                "name": "Victoriaville",
                "countryCode": "CA"
            },
            {
                "name": "Ville-Marie",
                "countryCode": "CA"
            },
            {
                "name": "Wakefield",
                "countryCode": "CA"
            },
            {
                "name": "Warwick",
                "countryCode": "CA"
            },
            {
                "name": "Waskaganish",
                "countryCode": "CA"
            },
            {
                "name": "Waswanipi",
                "countryCode": "CA"
            },
            {
                "name": "Waterloo",
                "countryCode": "CA"
            },
            {
                "name": "Weedon Centre",
                "countryCode": "CA"
            },
            {
                "name": "Westmount",
                "countryCode": "CA"
            },
            {
                "name": "Weymontachie",
                "countryCode": "CA"
            },
            {
                "name": "Windsor",
                "countryCode": "CA"
            },
            {
                "name": "Yamachiche",
                "countryCode": "CA"
            },
            {
                "name": "le Plateau",
                "countryCode": "CA"
            },
            {
                "name": "Assiniboia",
                "countryCode": "CA"
            },
            {
                "name": "Biggar",
                "countryCode": "CA"
            },
            {
                "name": "Canora",
                "countryCode": "CA"
            },
            {
                "name": "Carlyle",
                "countryCode": "CA"
            },
            {
                "name": "Dalmeny",
                "countryCode": "CA"
            },
            {
                "name": "Esterhazy",
                "countryCode": "CA"
            },
            {
                "name": "Estevan",
                "countryCode": "CA"
            },
            {
                "name": "Foam Lake",
                "countryCode": "CA"
            },
            {
                "name": "Gravelbourg",
                "countryCode": "CA"
            },
            {
                "name": "Hudson Bay",
                "countryCode": "CA"
            },
            {
                "name": "Humboldt",
                "countryCode": "CA"
            },
            {
                "name": "Indian Head",
                "countryCode": "CA"
            },
            {
                "name": "Kamsack",
                "countryCode": "CA"
            },
            {
                "name": "Kerrobert",
                "countryCode": "CA"
            },
            {
                "name": "Kindersley",
                "countryCode": "CA"
            },
            {
                "name": "La Ronge",
                "countryCode": "CA"
            },
            {
                "name": "Langenburg",
                "countryCode": "CA"
            },
            {
                "name": "Langham",
                "countryCode": "CA"
            },
            {
                "name": "Lanigan",
                "countryCode": "CA"
            },
            {
                "name": "Lumsden",
                "countryCode": "CA"
            },
            {
                "name": "Macklin",
                "countryCode": "CA"
            },
            {
                "name": "Maple Creek",
                "countryCode": "CA"
            },
            {
                "name": "Martensville",
                "countryCode": "CA"
            },
            {
                "name": "Meadow Lake",
                "countryCode": "CA"
            },
            {
                "name": "Melfort",
                "countryCode": "CA"
            },
            {
                "name": "Melville",
                "countryCode": "CA"
            },
            {
                "name": "Moose Jaw",
                "countryCode": "CA"
            },
            {
                "name": "Moosomin",
                "countryCode": "CA"
            },
            {
                "name": "Nipawin",
                "countryCode": "CA"
            },
            {
                "name": "North Battleford",
                "countryCode": "CA"
            },
            {
                "name": "Outlook",
                "countryCode": "CA"
            },
            {
                "name": "Oxbow",
                "countryCode": "CA"
            },
            {
                "name": "Pelican Narrows",
                "countryCode": "CA"
            },
            {
                "name": "Pilot Butte",
                "countryCode": "CA"
            },
            {
                "name": "Preeceville",
                "countryCode": "CA"
            },
            {
                "name": "Prince Albert",
                "countryCode": "CA"
            },
            {
                "name": "Regina",
                "countryCode": "CA"
            },
            {
                "name": "Regina Beach",
                "countryCode": "CA"
            },
            {
                "name": "Rosetown",
                "countryCode": "CA"
            },
            {
                "name": "Rosthern",
                "countryCode": "CA"
            },
            {
                "name": "Saskatoon",
                "countryCode": "CA"
            },
            {
                "name": "Shaunavon",
                "countryCode": "CA"
            },
            {
                "name": "Shellbrook",
                "countryCode": "CA"
            },
            {
                "name": "Swift Current",
                "countryCode": "CA"
            },
            {
                "name": "Tisdale",
                "countryCode": "CA"
            },
            {
                "name": "Unity",
                "countryCode": "CA"
            },
            {
                "name": "Wadena",
                "countryCode": "CA"
            },
            {
                "name": "Warman",
                "countryCode": "CA"
            },
            {
                "name": "Watrous",
                "countryCode": "CA"
            },
            {
                "name": "Weyburn",
                "countryCode": "CA"
            },
            {
                "name": "White City",
                "countryCode": "CA"
            },
            {
                "name": "Wilkie",
                "countryCode": "CA"
            },
            {
                "name": "Wynyard",
                "countryCode": "CA"
            },
            {
                "name": "Yorkton",
                "countryCode": "CA"
            },
            {
                "name": "Dawson City",
                "countryCode": "CA"
            },
            {
                "name": "Haines Junction",
                "countryCode": "CA"
            },
            {
                "name": "Watson Lake",
                "countryCode": "CA"
            },
            {
                "name": "Whitehorse",
                "countryCode": "CA"
            }
        ]
    },
    {
        "name": "Cape Verde",
        "phonecode": "238",
        "cities": [
            {
                "name": "Nova Sintra",
                "countryCode": "CV"
            },
            {
                "name": "Sal Rei",
                "countryCode": "CV"
            },
            {
                "name": "Assomada",
                "countryCode": "CV"
            },
            {
                "name": "Cova Figueira",
                "countryCode": "CV"
            },
            {
                "name": "Pedra Badejo",
                "countryCode": "CV"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "CV"
            },
            {
                "name": "Vila do Maio",
                "countryCode": "CV"
            },
            {
                "name": "Igreja",
                "countryCode": "CV"
            },
            {
                "name": "Pombas",
                "countryCode": "CV"
            },
            {
                "name": "Porto Novo",
                "countryCode": "CV"
            },
            {
                "name": "Praia",
                "countryCode": "CV"
            },
            {
                "name": "Ribeira Brava",
                "countryCode": "CV"
            },
            {
                "name": "Ponta do Sol",
                "countryCode": "CV"
            },
            {
                "name": "Ribeira Grande",
                "countryCode": "CV"
            },
            {
                "name": "Cidade Velha",
                "countryCode": "CV"
            },
            {
                "name": "São Domingos",
                "countryCode": "CV"
            },
            {
                "name": "São Filipe",
                "countryCode": "CV"
            },
            {
                "name": "Espargos",
                "countryCode": "CV"
            },
            {
                "name": "Santa Maria",
                "countryCode": "CV"
            },
            {
                "name": "Calheta",
                "countryCode": "CV"
            },
            {
                "name": "João Teves",
                "countryCode": "CV"
            },
            {
                "name": "Mindelo",
                "countryCode": "CV"
            },
            {
                "name": "Tarrafal",
                "countryCode": "CV"
            },
            {
                "name": "Tarrafal de São Nicolau",
                "countryCode": "CV"
            }
        ]
    },
    {
        "name": "Cayman Islands",
        "phonecode": "+1-345",
        "cities": []
    },
    {
        "name": "Central African Republic",
        "phonecode": "236",
        "cities": [
            {
                "name": "Batangafo",
                "countryCode": "CF"
            },
            {
                "name": "Bossangoa",
                "countryCode": "CF"
            },
            {
                "name": "Bouca",
                "countryCode": "CF"
            },
            {
                "name": "Kabo",
                "countryCode": "CF"
            },
            {
                "name": "Bamingui",
                "countryCode": "CF"
            },
            {
                "name": "Ndélé",
                "countryCode": "CF"
            },
            {
                "name": "Bangui",
                "countryCode": "CF"
            },
            {
                "name": "Alindao",
                "countryCode": "CF"
            },
            {
                "name": "Kembé",
                "countryCode": "CF"
            },
            {
                "name": "Mobaye",
                "countryCode": "CF"
            },
            {
                "name": "Bria",
                "countryCode": "CF"
            },
            {
                "name": "Ouadda",
                "countryCode": "CF"
            },
            {
                "name": "Obo",
                "countryCode": "CF"
            },
            {
                "name": "Zemio",
                "countryCode": "CF"
            },
            {
                "name": "Berberati",
                "countryCode": "CF"
            },
            {
                "name": "Carnot",
                "countryCode": "CF"
            },
            {
                "name": "Gamboula",
                "countryCode": "CF"
            },
            {
                "name": "Kaga Bandoro",
                "countryCode": "CF"
            },
            {
                "name": "Kaga-Bandoro",
                "countryCode": "CF"
            },
            {
                "name": "Sibut",
                "countryCode": "CF"
            },
            {
                "name": "Boda",
                "countryCode": "CF"
            },
            {
                "name": "Boganangone",
                "countryCode": "CF"
            },
            {
                "name": "Mbaiki",
                "countryCode": "CF"
            },
            {
                "name": "Mongoumba",
                "countryCode": "CF"
            },
            {
                "name": "Bangassou",
                "countryCode": "CF"
            },
            {
                "name": "Gambo",
                "countryCode": "CF"
            },
            {
                "name": "Ouango",
                "countryCode": "CF"
            },
            {
                "name": "Rafai",
                "countryCode": "CF"
            },
            {
                "name": "Bimbo",
                "countryCode": "CF"
            },
            {
                "name": "Boali",
                "countryCode": "CF"
            },
            {
                "name": "Damara",
                "countryCode": "CF"
            },
            {
                "name": "Baoro",
                "countryCode": "CF"
            },
            {
                "name": "Bouar",
                "countryCode": "CF"
            },
            {
                "name": "Bocaranga",
                "countryCode": "CF"
            },
            {
                "name": "Bozoum",
                "countryCode": "CF"
            },
            {
                "name": "Paoua",
                "countryCode": "CF"
            },
            {
                "name": "Nola",
                "countryCode": "CF"
            },
            {
                "name": "Bambari",
                "countryCode": "CF"
            },
            {
                "name": "Grimari",
                "countryCode": "CF"
            },
            {
                "name": "Ippy",
                "countryCode": "CF"
            },
            {
                "name": "Kouango",
                "countryCode": "CF"
            },
            {
                "name": "Birao",
                "countryCode": "CF"
            },
            {
                "name": "Ouanda-Djallé",
                "countryCode": "CF"
            }
        ]
    },
    {
        "name": "Chad",
        "phonecode": "235",
        "cities": [
            {
                "name": "Ati",
                "countryCode": "TD"
            },
            {
                "name": "Oum Hadjer",
                "countryCode": "TD"
            },
            {
                "name": "Moussoro",
                "countryCode": "TD"
            },
            {
                "name": "Faya-Largeau",
                "countryCode": "TD"
            },
            {
                "name": "Fada",
                "countryCode": "TD"
            },
            {
                "name": "Bitkine",
                "countryCode": "TD"
            },
            {
                "name": "Melfi",
                "countryCode": "TD"
            },
            {
                "name": "Mongo",
                "countryCode": "TD"
            },
            {
                "name": "Bokoro",
                "countryCode": "TD"
            },
            {
                "name": "Massaguet",
                "countryCode": "TD"
            },
            {
                "name": "Massakory",
                "countryCode": "TD"
            },
            {
                "name": "Mao",
                "countryCode": "TD"
            },
            {
                "name": "Bol",
                "countryCode": "TD"
            },
            {
                "name": "Benoy",
                "countryCode": "TD"
            },
            {
                "name": "Beïnamar",
                "countryCode": "TD"
            },
            {
                "name": "Lac Wey",
                "countryCode": "TD"
            },
            {
                "name": "Moundou",
                "countryCode": "TD"
            },
            {
                "name": "Béboto",
                "countryCode": "TD"
            },
            {
                "name": "Bébédja",
                "countryCode": "TD"
            },
            {
                "name": "Doba",
                "countryCode": "TD"
            },
            {
                "name": "Goundi",
                "countryCode": "TD"
            },
            {
                "name": "Koumra",
                "countryCode": "TD"
            },
            {
                "name": "Moïssala",
                "countryCode": "TD"
            },
            {
                "name": "Kyabé",
                "countryCode": "TD"
            },
            {
                "name": "Sarh",
                "countryCode": "TD"
            },
            {
                "name": "Bongor",
                "countryCode": "TD"
            },
            {
                "name": "Gounou Gaya",
                "countryCode": "TD"
            },
            {
                "name": "Guelendeng",
                "countryCode": "TD"
            },
            {
                "name": "Mboursou Léré",
                "countryCode": "TD"
            },
            {
                "name": "Pala",
                "countryCode": "TD"
            },
            {
                "name": "Abéché",
                "countryCode": "TD"
            },
            {
                "name": "Adré",
                "countryCode": "TD"
            },
            {
                "name": "Goz Béïda",
                "countryCode": "TD"
            },
            {
                "name": "Am Timan",
                "countryCode": "TD"
            },
            {
                "name": "Goz Beïda",
                "countryCode": "TD"
            },
            {
                "name": "Béré",
                "countryCode": "TD"
            },
            {
                "name": "Kelo",
                "countryCode": "TD"
            },
            {
                "name": "Laï",
                "countryCode": "TD"
            },
            {
                "name": "Aozou",
                "countryCode": "TD"
            },
            {
                "name": "Biltine",
                "countryCode": "TD"
            },
            {
                "name": "Iriba",
                "countryCode": "TD"
            }
        ]
    },
    {
        "name": "Chile",
        "phonecode": "56",
        "cities": [
            {
                "name": "Aysén",
                "countryCode": "CL"
            },
            {
                "name": "Chile Chico",
                "countryCode": "CL"
            },
            {
                "name": "Cisnes",
                "countryCode": "CL"
            },
            {
                "name": "Cochrane",
                "countryCode": "CL"
            },
            {
                "name": "Coyhaique",
                "countryCode": "CL"
            },
            {
                "name": "Guaitecas",
                "countryCode": "CL"
            },
            {
                "name": "Lago Verde",
                "countryCode": "CL"
            },
            {
                "name": "O'Higgins",
                "countryCode": "CL"
            },
            {
                "name": "Río Ibáñez",
                "countryCode": "CL"
            },
            {
                "name": "Tortel",
                "countryCode": "CL"
            },
            {
                "name": "Antofagasta",
                "countryCode": "CL"
            },
            {
                "name": "Calama",
                "countryCode": "CL"
            },
            {
                "name": "María Elena",
                "countryCode": "CL"
            },
            {
                "name": "Mejillones",
                "countryCode": "CL"
            },
            {
                "name": "Ollagüe",
                "countryCode": "CL"
            },
            {
                "name": "San Pedro de Atacama",
                "countryCode": "CL"
            },
            {
                "name": "Sierra Gorda",
                "countryCode": "CL"
            },
            {
                "name": "Taltal",
                "countryCode": "CL"
            },
            {
                "name": "Tocopilla",
                "countryCode": "CL"
            },
            {
                "name": "Arica",
                "countryCode": "CL"
            },
            {
                "name": "Camarones",
                "countryCode": "CL"
            },
            {
                "name": "General Lagos",
                "countryCode": "CL"
            },
            {
                "name": "Putre",
                "countryCode": "CL"
            },
            {
                "name": "Angol",
                "countryCode": "CL"
            },
            {
                "name": "Carahue",
                "countryCode": "CL"
            },
            {
                "name": "Cholchol",
                "countryCode": "CL"
            },
            {
                "name": "Collipulli",
                "countryCode": "CL"
            },
            {
                "name": "Cunco",
                "countryCode": "CL"
            },
            {
                "name": "Curacautín",
                "countryCode": "CL"
            },
            {
                "name": "Curarrehue",
                "countryCode": "CL"
            },
            {
                "name": "Ercilla",
                "countryCode": "CL"
            },
            {
                "name": "Freire",
                "countryCode": "CL"
            },
            {
                "name": "Galvarino",
                "countryCode": "CL"
            },
            {
                "name": "Gorbea",
                "countryCode": "CL"
            },
            {
                "name": "Lautaro",
                "countryCode": "CL"
            },
            {
                "name": "Loncoche",
                "countryCode": "CL"
            },
            {
                "name": "Lonquimay",
                "countryCode": "CL"
            },
            {
                "name": "Los Sauces",
                "countryCode": "CL"
            },
            {
                "name": "Lumaco",
                "countryCode": "CL"
            },
            {
                "name": "Melipeuco",
                "countryCode": "CL"
            },
            {
                "name": "Nueva Imperial",
                "countryCode": "CL"
            },
            {
                "name": "Padre Las Casas",
                "countryCode": "CL"
            },
            {
                "name": "Perquenco",
                "countryCode": "CL"
            },
            {
                "name": "Pitrufquén",
                "countryCode": "CL"
            },
            {
                "name": "Pucón",
                "countryCode": "CL"
            },
            {
                "name": "Purén",
                "countryCode": "CL"
            },
            {
                "name": "Renaico",
                "countryCode": "CL"
            },
            {
                "name": "Saavedra",
                "countryCode": "CL"
            },
            {
                "name": "Temuco",
                "countryCode": "CL"
            },
            {
                "name": "Teodoro Schmidt",
                "countryCode": "CL"
            },
            {
                "name": "Toltén",
                "countryCode": "CL"
            },
            {
                "name": "Traiguén",
                "countryCode": "CL"
            },
            {
                "name": "Victoria",
                "countryCode": "CL"
            },
            {
                "name": "Vilcún",
                "countryCode": "CL"
            },
            {
                "name": "Villarrica",
                "countryCode": "CL"
            },
            {
                "name": "Alto del Carmen",
                "countryCode": "CL"
            },
            {
                "name": "Caldera",
                "countryCode": "CL"
            },
            {
                "name": "Chañaral",
                "countryCode": "CL"
            },
            {
                "name": "Copiapó",
                "countryCode": "CL"
            },
            {
                "name": "Diego de Almagro",
                "countryCode": "CL"
            },
            {
                "name": "Freirina",
                "countryCode": "CL"
            },
            {
                "name": "Huasco",
                "countryCode": "CL"
            },
            {
                "name": "Tierra Amarilla",
                "countryCode": "CL"
            },
            {
                "name": "Vallenar",
                "countryCode": "CL"
            },
            {
                "name": "Alto Biobío",
                "countryCode": "CL"
            },
            {
                "name": "Antuco",
                "countryCode": "CL"
            },
            {
                "name": "Arauco",
                "countryCode": "CL"
            },
            {
                "name": "Cabrero",
                "countryCode": "CL"
            },
            {
                "name": "Cañete",
                "countryCode": "CL"
            },
            {
                "name": "Chiguayante",
                "countryCode": "CL"
            },
            {
                "name": "Concepción",
                "countryCode": "CL"
            },
            {
                "name": "Contulmo",
                "countryCode": "CL"
            },
            {
                "name": "Coronel",
                "countryCode": "CL"
            },
            {
                "name": "Curanilahue",
                "countryCode": "CL"
            },
            {
                "name": "Florida",
                "countryCode": "CL"
            },
            {
                "name": "Hualpén",
                "countryCode": "CL"
            },
            {
                "name": "Hualqui",
                "countryCode": "CL"
            },
            {
                "name": "Laja",
                "countryCode": "CL"
            },
            {
                "name": "Lebu",
                "countryCode": "CL"
            },
            {
                "name": "Los Álamos",
                "countryCode": "CL"
            },
            {
                "name": "Los Ángeles",
                "countryCode": "CL"
            },
            {
                "name": "Lota",
                "countryCode": "CL"
            },
            {
                "name": "Mulchén",
                "countryCode": "CL"
            },
            {
                "name": "Nacimiento",
                "countryCode": "CL"
            },
            {
                "name": "Negrete",
                "countryCode": "CL"
            },
            {
                "name": "Penco",
                "countryCode": "CL"
            },
            {
                "name": "Quilaco",
                "countryCode": "CL"
            },
            {
                "name": "Quilleco",
                "countryCode": "CL"
            },
            {
                "name": "San Pedro de la Paz",
                "countryCode": "CL"
            },
            {
                "name": "San Rosendo",
                "countryCode": "CL"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "CL"
            },
            {
                "name": "Santa Juana",
                "countryCode": "CL"
            },
            {
                "name": "Talcahuano",
                "countryCode": "CL"
            },
            {
                "name": "Tirúa",
                "countryCode": "CL"
            },
            {
                "name": "Tomé",
                "countryCode": "CL"
            },
            {
                "name": "Tucapel",
                "countryCode": "CL"
            },
            {
                "name": "Yumbel",
                "countryCode": "CL"
            },
            {
                "name": "Andacollo",
                "countryCode": "CL"
            },
            {
                "name": "Canela",
                "countryCode": "CL"
            },
            {
                "name": "Combarbalá",
                "countryCode": "CL"
            },
            {
                "name": "Coquimbo",
                "countryCode": "CL"
            },
            {
                "name": "Illapel",
                "countryCode": "CL"
            },
            {
                "name": "La Higuera",
                "countryCode": "CL"
            },
            {
                "name": "La Serena",
                "countryCode": "CL"
            },
            {
                "name": "Los Vilos",
                "countryCode": "CL"
            },
            {
                "name": "Monte Patria",
                "countryCode": "CL"
            },
            {
                "name": "Ovalle",
                "countryCode": "CL"
            },
            {
                "name": "Paihuano",
                "countryCode": "CL"
            },
            {
                "name": "Punitaqui",
                "countryCode": "CL"
            },
            {
                "name": "Río Hurtado",
                "countryCode": "CL"
            },
            {
                "name": "Salamanca",
                "countryCode": "CL"
            },
            {
                "name": "Vicuña",
                "countryCode": "CL"
            },
            {
                "name": "Chimbarongo",
                "countryCode": "CL"
            },
            {
                "name": "Chépica",
                "countryCode": "CL"
            },
            {
                "name": "Codegua",
                "countryCode": "CL"
            },
            {
                "name": "Coltauco",
                "countryCode": "CL"
            },
            {
                "name": "Coínco",
                "countryCode": "CL"
            },
            {
                "name": "Doñihue",
                "countryCode": "CL"
            },
            {
                "name": "Graneros",
                "countryCode": "CL"
            },
            {
                "name": "La Estrella",
                "countryCode": "CL"
            },
            {
                "name": "Las Cabras",
                "countryCode": "CL"
            },
            {
                "name": "Litueche",
                "countryCode": "CL"
            },
            {
                "name": "Lolol",
                "countryCode": "CL"
            },
            {
                "name": "Machalí",
                "countryCode": "CL"
            },
            {
                "name": "Malloa",
                "countryCode": "CL"
            },
            {
                "name": "Marchigüe",
                "countryCode": "CL"
            },
            {
                "name": "Mostazal",
                "countryCode": "CL"
            },
            {
                "name": "Nancagua",
                "countryCode": "CL"
            },
            {
                "name": "Navidad",
                "countryCode": "CL"
            },
            {
                "name": "Olivar",
                "countryCode": "CL"
            },
            {
                "name": "Palmilla",
                "countryCode": "CL"
            },
            {
                "name": "Paredones",
                "countryCode": "CL"
            },
            {
                "name": "Peralillo",
                "countryCode": "CL"
            },
            {
                "name": "Peumo",
                "countryCode": "CL"
            },
            {
                "name": "Pichidegua",
                "countryCode": "CL"
            },
            {
                "name": "Pichilemu",
                "countryCode": "CL"
            },
            {
                "name": "Placilla",
                "countryCode": "CL"
            },
            {
                "name": "Pumanque",
                "countryCode": "CL"
            },
            {
                "name": "Quinta de Tilcoco",
                "countryCode": "CL"
            },
            {
                "name": "Rancagua",
                "countryCode": "CL"
            },
            {
                "name": "Rengo",
                "countryCode": "CL"
            },
            {
                "name": "Requínoa",
                "countryCode": "CL"
            },
            {
                "name": "San Fernando",
                "countryCode": "CL"
            },
            {
                "name": "San Vicente",
                "countryCode": "CL"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "CL"
            },
            {
                "name": "Ancud",
                "countryCode": "CL"
            },
            {
                "name": "Calbuco",
                "countryCode": "CL"
            },
            {
                "name": "Castro",
                "countryCode": "CL"
            },
            {
                "name": "Chaitén",
                "countryCode": "CL"
            },
            {
                "name": "Chonchi",
                "countryCode": "CL"
            },
            {
                "name": "Cochamó",
                "countryCode": "CL"
            },
            {
                "name": "Curaco de Vélez",
                "countryCode": "CL"
            },
            {
                "name": "Dalcahue",
                "countryCode": "CL"
            },
            {
                "name": "Fresia",
                "countryCode": "CL"
            },
            {
                "name": "Frutillar",
                "countryCode": "CL"
            },
            {
                "name": "Futaleufú",
                "countryCode": "CL"
            },
            {
                "name": "Hualaihué",
                "countryCode": "CL"
            },
            {
                "name": "Llanquihue",
                "countryCode": "CL"
            },
            {
                "name": "Los Muermos",
                "countryCode": "CL"
            },
            {
                "name": "Maullín",
                "countryCode": "CL"
            },
            {
                "name": "Osorno",
                "countryCode": "CL"
            },
            {
                "name": "Palena",
                "countryCode": "CL"
            },
            {
                "name": "Puerto Montt",
                "countryCode": "CL"
            },
            {
                "name": "Puerto Octay",
                "countryCode": "CL"
            },
            {
                "name": "Puerto Varas",
                "countryCode": "CL"
            },
            {
                "name": "Puqueldón",
                "countryCode": "CL"
            },
            {
                "name": "Purranque",
                "countryCode": "CL"
            },
            {
                "name": "Puyehue",
                "countryCode": "CL"
            },
            {
                "name": "Queilén",
                "countryCode": "CL"
            },
            {
                "name": "Quellón",
                "countryCode": "CL"
            },
            {
                "name": "Quemchi",
                "countryCode": "CL"
            },
            {
                "name": "Quinchao",
                "countryCode": "CL"
            },
            {
                "name": "Río Negro",
                "countryCode": "CL"
            },
            {
                "name": "San Juan de la Costa",
                "countryCode": "CL"
            },
            {
                "name": "San Pablo",
                "countryCode": "CL"
            },
            {
                "name": "Corral",
                "countryCode": "CL"
            },
            {
                "name": "Futrono",
                "countryCode": "CL"
            },
            {
                "name": "La Unión",
                "countryCode": "CL"
            },
            {
                "name": "Lago Ranco",
                "countryCode": "CL"
            },
            {
                "name": "Lanco",
                "countryCode": "CL"
            },
            {
                "name": "Los Lagos",
                "countryCode": "CL"
            },
            {
                "name": "Mariquina",
                "countryCode": "CL"
            },
            {
                "name": "Máfil",
                "countryCode": "CL"
            },
            {
                "name": "Paillaco",
                "countryCode": "CL"
            },
            {
                "name": "Panguipulli",
                "countryCode": "CL"
            },
            {
                "name": "Río Bueno",
                "countryCode": "CL"
            },
            {
                "name": "Valdivia",
                "countryCode": "CL"
            },
            {
                "name": "Antártica",
                "countryCode": "CL"
            },
            {
                "name": "Cabo de Hornos",
                "countryCode": "CL"
            },
            {
                "name": "Laguna Blanca",
                "countryCode": "CL"
            },
            {
                "name": "Natales",
                "countryCode": "CL"
            },
            {
                "name": "Porvenir",
                "countryCode": "CL"
            },
            {
                "name": "Primavera",
                "countryCode": "CL"
            },
            {
                "name": "Punta Arenas",
                "countryCode": "CL"
            },
            {
                "name": "Río Verde",
                "countryCode": "CL"
            },
            {
                "name": "San Gregorio",
                "countryCode": "CL"
            },
            {
                "name": "Timaukel",
                "countryCode": "CL"
            },
            {
                "name": "Torres del Paine",
                "countryCode": "CL"
            },
            {
                "name": "Cauquenes",
                "countryCode": "CL"
            },
            {
                "name": "Chanco",
                "countryCode": "CL"
            },
            {
                "name": "Colbún",
                "countryCode": "CL"
            },
            {
                "name": "Constitución",
                "countryCode": "CL"
            },
            {
                "name": "Curepto",
                "countryCode": "CL"
            },
            {
                "name": "Curicó",
                "countryCode": "CL"
            },
            {
                "name": "Empedrado",
                "countryCode": "CL"
            },
            {
                "name": "Hualañé",
                "countryCode": "CL"
            },
            {
                "name": "Licantén",
                "countryCode": "CL"
            },
            {
                "name": "Linares",
                "countryCode": "CL"
            },
            {
                "name": "Longaví",
                "countryCode": "CL"
            },
            {
                "name": "Maule",
                "countryCode": "CL"
            },
            {
                "name": "Molina",
                "countryCode": "CL"
            },
            {
                "name": "Parral",
                "countryCode": "CL"
            },
            {
                "name": "Pelarco",
                "countryCode": "CL"
            },
            {
                "name": "Pelluhue",
                "countryCode": "CL"
            },
            {
                "name": "Pencahue",
                "countryCode": "CL"
            },
            {
                "name": "Rauco",
                "countryCode": "CL"
            },
            {
                "name": "Retiro",
                "countryCode": "CL"
            },
            {
                "name": "Romeral",
                "countryCode": "CL"
            },
            {
                "name": "Río Claro",
                "countryCode": "CL"
            },
            {
                "name": "Sagrada Familia",
                "countryCode": "CL"
            },
            {
                "name": "San Clemente",
                "countryCode": "CL"
            },
            {
                "name": "San Javier",
                "countryCode": "CL"
            },
            {
                "name": "San Rafael",
                "countryCode": "CL"
            },
            {
                "name": "Talca",
                "countryCode": "CL"
            },
            {
                "name": "Teno",
                "countryCode": "CL"
            },
            {
                "name": "Vichuquén",
                "countryCode": "CL"
            },
            {
                "name": "Villa Alegre",
                "countryCode": "CL"
            },
            {
                "name": "Yerbas Buenas",
                "countryCode": "CL"
            },
            {
                "name": "Bulnes",
                "countryCode": "CL"
            },
            {
                "name": "Chillán",
                "countryCode": "CL"
            },
            {
                "name": "Chillán Viejo",
                "countryCode": "CL"
            },
            {
                "name": "Cobquecura",
                "countryCode": "CL"
            },
            {
                "name": "Coelemu",
                "countryCode": "CL"
            },
            {
                "name": "Coihueco",
                "countryCode": "CL"
            },
            {
                "name": "El Carmen",
                "countryCode": "CL"
            },
            {
                "name": "Ninhue",
                "countryCode": "CL"
            },
            {
                "name": "Pemuco",
                "countryCode": "CL"
            },
            {
                "name": "Pinto",
                "countryCode": "CL"
            },
            {
                "name": "Portezuelo",
                "countryCode": "CL"
            },
            {
                "name": "Quillón",
                "countryCode": "CL"
            },
            {
                "name": "Quirihue",
                "countryCode": "CL"
            },
            {
                "name": "Ránquil",
                "countryCode": "CL"
            },
            {
                "name": "San Carlos",
                "countryCode": "CL"
            },
            {
                "name": "San Fabián",
                "countryCode": "CL"
            },
            {
                "name": "San Ignacio",
                "countryCode": "CL"
            },
            {
                "name": "San Nicolás",
                "countryCode": "CL"
            },
            {
                "name": "Treguaco",
                "countryCode": "CL"
            },
            {
                "name": "Yungay",
                "countryCode": "CL"
            },
            {
                "name": "Ñiquén",
                "countryCode": "CL"
            },
            {
                "name": "Alhué",
                "countryCode": "CL"
            },
            {
                "name": "Buin",
                "countryCode": "CL"
            },
            {
                "name": "Calera de Tango",
                "countryCode": "CL"
            },
            {
                "name": "Cerrillos",
                "countryCode": "CL"
            },
            {
                "name": "Cerro Navia",
                "countryCode": "CL"
            },
            {
                "name": "Colina",
                "countryCode": "CL"
            },
            {
                "name": "Conchalí",
                "countryCode": "CL"
            },
            {
                "name": "Curacaví",
                "countryCode": "CL"
            },
            {
                "name": "El Bosque",
                "countryCode": "CL"
            },
            {
                "name": "El Monte",
                "countryCode": "CL"
            },
            {
                "name": "Estación Central",
                "countryCode": "CL"
            },
            {
                "name": "Huechuraba",
                "countryCode": "CL"
            },
            {
                "name": "Independencia",
                "countryCode": "CL"
            },
            {
                "name": "Isla de Maipo",
                "countryCode": "CL"
            },
            {
                "name": "La Cisterna",
                "countryCode": "CL"
            },
            {
                "name": "La Florida",
                "countryCode": "CL"
            },
            {
                "name": "La Granja",
                "countryCode": "CL"
            },
            {
                "name": "La Pintana",
                "countryCode": "CL"
            },
            {
                "name": "La Reina",
                "countryCode": "CL"
            },
            {
                "name": "Lampa",
                "countryCode": "CL"
            },
            {
                "name": "Las Condes",
                "countryCode": "CL"
            },
            {
                "name": "Lo Barnechea",
                "countryCode": "CL"
            },
            {
                "name": "Lo Espejo",
                "countryCode": "CL"
            },
            {
                "name": "Lo Prado",
                "countryCode": "CL"
            },
            {
                "name": "Macul",
                "countryCode": "CL"
            },
            {
                "name": "Maipú",
                "countryCode": "CL"
            },
            {
                "name": "María Pinto",
                "countryCode": "CL"
            },
            {
                "name": "Melipilla",
                "countryCode": "CL"
            },
            {
                "name": "Padre Hurtado",
                "countryCode": "CL"
            },
            {
                "name": "Paine",
                "countryCode": "CL"
            },
            {
                "name": "Pedro Aguirre Cerda",
                "countryCode": "CL"
            },
            {
                "name": "Peñaflor",
                "countryCode": "CL"
            },
            {
                "name": "Peñalolén",
                "countryCode": "CL"
            },
            {
                "name": "Pirque",
                "countryCode": "CL"
            },
            {
                "name": "Providencia",
                "countryCode": "CL"
            },
            {
                "name": "Pudahuel",
                "countryCode": "CL"
            },
            {
                "name": "Puente Alto",
                "countryCode": "CL"
            },
            {
                "name": "Quilicura",
                "countryCode": "CL"
            },
            {
                "name": "Quinta Normal",
                "countryCode": "CL"
            },
            {
                "name": "Recoleta",
                "countryCode": "CL"
            },
            {
                "name": "Renca",
                "countryCode": "CL"
            },
            {
                "name": "San Bernardo",
                "countryCode": "CL"
            },
            {
                "name": "San Joaquín",
                "countryCode": "CL"
            },
            {
                "name": "San José de Maipo",
                "countryCode": "CL"
            },
            {
                "name": "San Miguel",
                "countryCode": "CL"
            },
            {
                "name": "San Pedro",
                "countryCode": "CL"
            },
            {
                "name": "San Ramón",
                "countryCode": "CL"
            },
            {
                "name": "Santiago",
                "countryCode": "CL"
            },
            {
                "name": "Talagante",
                "countryCode": "CL"
            },
            {
                "name": "Tiltil",
                "countryCode": "CL"
            },
            {
                "name": "Vitacura",
                "countryCode": "CL"
            },
            {
                "name": "Ñuñoa",
                "countryCode": "CL"
            },
            {
                "name": "Alto Hospicio",
                "countryCode": "CL"
            },
            {
                "name": "Camiña",
                "countryCode": "CL"
            },
            {
                "name": "Colchane",
                "countryCode": "CL"
            },
            {
                "name": "Huara",
                "countryCode": "CL"
            },
            {
                "name": "Iquique",
                "countryCode": "CL"
            },
            {
                "name": "Pica",
                "countryCode": "CL"
            },
            {
                "name": "Pozo Almonte",
                "countryCode": "CL"
            },
            {
                "name": "Algarrobo",
                "countryCode": "CL"
            },
            {
                "name": "Cabildo",
                "countryCode": "CL"
            },
            {
                "name": "Calle Larga",
                "countryCode": "CL"
            },
            {
                "name": "Cartagena",
                "countryCode": "CL"
            },
            {
                "name": "Casablanca",
                "countryCode": "CL"
            },
            {
                "name": "Catemu",
                "countryCode": "CL"
            },
            {
                "name": "Concón",
                "countryCode": "CL"
            },
            {
                "name": "El Quisco",
                "countryCode": "CL"
            },
            {
                "name": "El Tabo",
                "countryCode": "CL"
            },
            {
                "name": "Hijuelas",
                "countryCode": "CL"
            },
            {
                "name": "Isla de Pascua",
                "countryCode": "CL"
            },
            {
                "name": "Juan Fernández",
                "countryCode": "CL"
            },
            {
                "name": "La Calera",
                "countryCode": "CL"
            },
            {
                "name": "La Cruz",
                "countryCode": "CL"
            },
            {
                "name": "La Ligua",
                "countryCode": "CL"
            },
            {
                "name": "Limache",
                "countryCode": "CL"
            },
            {
                "name": "Llaillay",
                "countryCode": "CL"
            },
            {
                "name": "Los Andes",
                "countryCode": "CL"
            },
            {
                "name": "Nogales",
                "countryCode": "CL"
            },
            {
                "name": "Olmué",
                "countryCode": "CL"
            },
            {
                "name": "Panquehue",
                "countryCode": "CL"
            },
            {
                "name": "Papudo",
                "countryCode": "CL"
            },
            {
                "name": "Petorca",
                "countryCode": "CL"
            },
            {
                "name": "Puchuncaví",
                "countryCode": "CL"
            },
            {
                "name": "Putaendo",
                "countryCode": "CL"
            },
            {
                "name": "Quillota",
                "countryCode": "CL"
            },
            {
                "name": "Quilpué",
                "countryCode": "CL"
            },
            {
                "name": "Quintero",
                "countryCode": "CL"
            },
            {
                "name": "Rinconada",
                "countryCode": "CL"
            },
            {
                "name": "San Antonio",
                "countryCode": "CL"
            },
            {
                "name": "San Esteban",
                "countryCode": "CL"
            },
            {
                "name": "San Felipe",
                "countryCode": "CL"
            },
            {
                "name": "Santa María",
                "countryCode": "CL"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "CL"
            },
            {
                "name": "Valparaíso",
                "countryCode": "CL"
            },
            {
                "name": "Villa Alemana",
                "countryCode": "CL"
            },
            {
                "name": "Viña del Mar",
                "countryCode": "CL"
            },
            {
                "name": "Zapallar",
                "countryCode": "CL"
            }
        ]
    },
    {
        "name": "China",
        "phonecode": "86",
        "cities": [
            {
                "name": "Anqing",
                "countryCode": "CN"
            },
            {
                "name": "Anqing Shi",
                "countryCode": "CN"
            },
            {
                "name": "Bengbu",
                "countryCode": "CN"
            },
            {
                "name": "Bozhou",
                "countryCode": "CN"
            },
            {
                "name": "Chaohu",
                "countryCode": "CN"
            },
            {
                "name": "Chizhou",
                "countryCode": "CN"
            },
            {
                "name": "Chizhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Chuzhou",
                "countryCode": "CN"
            },
            {
                "name": "Chuzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Datong",
                "countryCode": "CN"
            },
            {
                "name": "Fuyang",
                "countryCode": "CN"
            },
            {
                "name": "Fuyang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Gushu",
                "countryCode": "CN"
            },
            {
                "name": "Hefei",
                "countryCode": "CN"
            },
            {
                "name": "Hefei Shi",
                "countryCode": "CN"
            },
            {
                "name": "Huaibei",
                "countryCode": "CN"
            },
            {
                "name": "Huainan",
                "countryCode": "CN"
            },
            {
                "name": "Huainan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Huaiyuan Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Huangshan",
                "countryCode": "CN"
            },
            {
                "name": "Huangshan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Huoqiu Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Jieshou",
                "countryCode": "CN"
            },
            {
                "name": "Lucheng",
                "countryCode": "CN"
            },
            {
                "name": "Lu’an",
                "countryCode": "CN"
            },
            {
                "name": "Mengcheng Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Mingguang",
                "countryCode": "CN"
            },
            {
                "name": "Suixi",
                "countryCode": "CN"
            },
            {
                "name": "Suzhou",
                "countryCode": "CN"
            },
            {
                "name": "Suzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Tangzhai",
                "countryCode": "CN"
            },
            {
                "name": "Wucheng",
                "countryCode": "CN"
            },
            {
                "name": "Wuhu",
                "countryCode": "CN"
            },
            {
                "name": "Wusong",
                "countryCode": "CN"
            },
            {
                "name": "Wuyang",
                "countryCode": "CN"
            },
            {
                "name": "Xuanzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yingshang Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Beijing",
                "countryCode": "CN"
            },
            {
                "name": "Changping",
                "countryCode": "CN"
            },
            {
                "name": "Daxing",
                "countryCode": "CN"
            },
            {
                "name": "Fangshan",
                "countryCode": "CN"
            },
            {
                "name": "Liangxiang",
                "countryCode": "CN"
            },
            {
                "name": "Mentougou",
                "countryCode": "CN"
            },
            {
                "name": "Shunyi",
                "countryCode": "CN"
            },
            {
                "name": "Tongzhou",
                "countryCode": "CN"
            },
            {
                "name": "Beibei",
                "countryCode": "CN"
            },
            {
                "name": "Caijia",
                "countryCode": "CN"
            },
            {
                "name": "Chongqing",
                "countryCode": "CN"
            },
            {
                "name": "Dongxi",
                "countryCode": "CN"
            },
            {
                "name": "Fuling",
                "countryCode": "CN"
            },
            {
                "name": "Ganshui",
                "countryCode": "CN"
            },
            {
                "name": "Guofuchang",
                "countryCode": "CN"
            },
            {
                "name": "Hechuan",
                "countryCode": "CN"
            },
            {
                "name": "Jijiang",
                "countryCode": "CN"
            },
            {
                "name": "Liangping District",
                "countryCode": "CN"
            },
            {
                "name": "Puhechang",
                "countryCode": "CN"
            },
            {
                "name": "Shapingba District",
                "countryCode": "CN"
            },
            {
                "name": "Shijiaochang",
                "countryCode": "CN"
            },
            {
                "name": "Wanxian",
                "countryCode": "CN"
            },
            {
                "name": "Wanzhou District",
                "countryCode": "CN"
            },
            {
                "name": "Yongchuan",
                "countryCode": "CN"
            },
            {
                "name": "Yudong",
                "countryCode": "CN"
            },
            {
                "name": "Yuzhong District",
                "countryCode": "CN"
            },
            {
                "name": "Badu",
                "countryCode": "CN"
            },
            {
                "name": "Baiqi",
                "countryCode": "CN"
            },
            {
                "name": "Baiyun",
                "countryCode": "CN"
            },
            {
                "name": "Beishancun",
                "countryCode": "CN"
            },
            {
                "name": "Changqiao",
                "countryCode": "CN"
            },
            {
                "name": "Chengmen",
                "countryCode": "CN"
            },
            {
                "name": "Chixi",
                "countryCode": "CN"
            },
            {
                "name": "Chongru",
                "countryCode": "CN"
            },
            {
                "name": "Dadeng",
                "countryCode": "CN"
            },
            {
                "name": "Daixi",
                "countryCode": "CN"
            },
            {
                "name": "Danyang",
                "countryCode": "CN"
            },
            {
                "name": "Daqiao",
                "countryCode": "CN"
            },
            {
                "name": "Dazuo",
                "countryCode": "CN"
            },
            {
                "name": "Dinghaicun",
                "countryCode": "CN"
            },
            {
                "name": "Dingtoucun",
                "countryCode": "CN"
            },
            {
                "name": "Dongchongcun",
                "countryCode": "CN"
            },
            {
                "name": "Dongdai",
                "countryCode": "CN"
            },
            {
                "name": "Donghu",
                "countryCode": "CN"
            },
            {
                "name": "Dongling",
                "countryCode": "CN"
            },
            {
                "name": "Dongyuan",
                "countryCode": "CN"
            },
            {
                "name": "Feiluan",
                "countryCode": "CN"
            },
            {
                "name": "Fengpu",
                "countryCode": "CN"
            },
            {
                "name": "Fengzhou",
                "countryCode": "CN"
            },
            {
                "name": "Fuding",
                "countryCode": "CN"
            },
            {
                "name": "Fuqing",
                "countryCode": "CN"
            },
            {
                "name": "Fuzhou",
                "countryCode": "CN"
            },
            {
                "name": "Fu’an",
                "countryCode": "CN"
            },
            {
                "name": "Gantang",
                "countryCode": "CN"
            },
            {
                "name": "Guantou",
                "countryCode": "CN"
            },
            {
                "name": "Gufeng",
                "countryCode": "CN"
            },
            {
                "name": "Hetang",
                "countryCode": "CN"
            },
            {
                "name": "Hongtang",
                "countryCode": "CN"
            },
            {
                "name": "Hongyang",
                "countryCode": "CN"
            },
            {
                "name": "Houyu",
                "countryCode": "CN"
            },
            {
                "name": "Huai’an",
                "countryCode": "CN"
            },
            {
                "name": "Huangtian",
                "countryCode": "CN"
            },
            {
                "name": "Huotong",
                "countryCode": "CN"
            },
            {
                "name": "Jiangkou",
                "countryCode": "CN"
            },
            {
                "name": "Jianjiang",
                "countryCode": "CN"
            },
            {
                "name": "Jian’ou",
                "countryCode": "CN"
            },
            {
                "name": "Jingfeng",
                "countryCode": "CN"
            },
            {
                "name": "Jinjiang",
                "countryCode": "CN"
            },
            {
                "name": "Jinjing",
                "countryCode": "CN"
            },
            {
                "name": "Jitoucun",
                "countryCode": "CN"
            },
            {
                "name": "Kengyuan",
                "countryCode": "CN"
            },
            {
                "name": "Kerencun",
                "countryCode": "CN"
            },
            {
                "name": "Kuai’an",
                "countryCode": "CN"
            },
            {
                "name": "Lianhecun",
                "countryCode": "CN"
            },
            {
                "name": "Liuwudiancun",
                "countryCode": "CN"
            },
            {
                "name": "Longmen",
                "countryCode": "CN"
            },
            {
                "name": "Longyan",
                "countryCode": "CN"
            },
            {
                "name": "Luoqiao",
                "countryCode": "CN"
            },
            {
                "name": "Luoyang",
                "countryCode": "CN"
            },
            {
                "name": "Luxia",
                "countryCode": "CN"
            },
            {
                "name": "Maping",
                "countryCode": "CN"
            },
            {
                "name": "Meipu",
                "countryCode": "CN"
            },
            {
                "name": "Min’an",
                "countryCode": "CN"
            },
            {
                "name": "Nanping",
                "countryCode": "CN"
            },
            {
                "name": "Neikeng",
                "countryCode": "CN"
            },
            {
                "name": "Ningde",
                "countryCode": "CN"
            },
            {
                "name": "Pandu",
                "countryCode": "CN"
            },
            {
                "name": "Pucheng",
                "countryCode": "CN"
            },
            {
                "name": "Putian",
                "countryCode": "CN"
            },
            {
                "name": "Qibu",
                "countryCode": "CN"
            },
            {
                "name": "Qidu",
                "countryCode": "CN"
            },
            {
                "name": "Quanzhou",
                "countryCode": "CN"
            },
            {
                "name": "Rong’an",
                "countryCode": "CN"
            },
            {
                "name": "Sanming",
                "countryCode": "CN"
            },
            {
                "name": "Shajiang",
                "countryCode": "CN"
            },
            {
                "name": "Shangjie",
                "countryCode": "CN"
            },
            {
                "name": "Shanxia",
                "countryCode": "CN"
            },
            {
                "name": "Shanyang",
                "countryCode": "CN"
            },
            {
                "name": "Shaowu",
                "countryCode": "CN"
            },
            {
                "name": "Shijing",
                "countryCode": "CN"
            },
            {
                "name": "Shima",
                "countryCode": "CN"
            },
            {
                "name": "Shoushan",
                "countryCode": "CN"
            },
            {
                "name": "Shuangxi",
                "countryCode": "CN"
            },
            {
                "name": "Shuangzhu",
                "countryCode": "CN"
            },
            {
                "name": "Shuikou",
                "countryCode": "CN"
            },
            {
                "name": "Tangkou",
                "countryCode": "CN"
            },
            {
                "name": "Tantou",
                "countryCode": "CN"
            },
            {
                "name": "Tatou",
                "countryCode": "CN"
            },
            {
                "name": "Tingjiang",
                "countryCode": "CN"
            },
            {
                "name": "Tuzhai",
                "countryCode": "CN"
            },
            {
                "name": "Wubao",
                "countryCode": "CN"
            },
            {
                "name": "Wuyishan",
                "countryCode": "CN"
            },
            {
                "name": "Wuyucun",
                "countryCode": "CN"
            },
            {
                "name": "Xiabaishi",
                "countryCode": "CN"
            },
            {
                "name": "Xiahu",
                "countryCode": "CN"
            },
            {
                "name": "Xiamen",
                "countryCode": "CN"
            },
            {
                "name": "Xiancun",
                "countryCode": "CN"
            },
            {
                "name": "Xiangyun",
                "countryCode": "CN"
            },
            {
                "name": "Xibing",
                "countryCode": "CN"
            },
            {
                "name": "Xiling",
                "countryCode": "CN"
            },
            {
                "name": "Ximei",
                "countryCode": "CN"
            },
            {
                "name": "Xinan",
                "countryCode": "CN"
            },
            {
                "name": "Xindian",
                "countryCode": "CN"
            },
            {
                "name": "Yakou",
                "countryCode": "CN"
            },
            {
                "name": "Yanghou",
                "countryCode": "CN"
            },
            {
                "name": "Yangzhong",
                "countryCode": "CN"
            },
            {
                "name": "Yantian",
                "countryCode": "CN"
            },
            {
                "name": "Yingdu",
                "countryCode": "CN"
            },
            {
                "name": "Yinglin",
                "countryCode": "CN"
            },
            {
                "name": "Yongning",
                "countryCode": "CN"
            },
            {
                "name": "Yushan",
                "countryCode": "CN"
            },
            {
                "name": "Zhangwan",
                "countryCode": "CN"
            },
            {
                "name": "Zhangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Zhenhaicun",
                "countryCode": "CN"
            },
            {
                "name": "Zhongfang",
                "countryCode": "CN"
            },
            {
                "name": "Zhuoyang",
                "countryCode": "CN"
            },
            {
                "name": "Zhuqi",
                "countryCode": "CN"
            },
            {
                "name": "Anbu",
                "countryCode": "CN"
            },
            {
                "name": "Chaozhou",
                "countryCode": "CN"
            },
            {
                "name": "Chenghua",
                "countryCode": "CN"
            },
            {
                "name": "Daliang",
                "countryCode": "CN"
            },
            {
                "name": "Danshui",
                "countryCode": "CN"
            },
            {
                "name": "Dasha",
                "countryCode": "CN"
            },
            {
                "name": "Dongguan",
                "countryCode": "CN"
            },
            {
                "name": "Donghai",
                "countryCode": "CN"
            },
            {
                "name": "Ducheng",
                "countryCode": "CN"
            },
            {
                "name": "Encheng",
                "countryCode": "CN"
            },
            {
                "name": "Foshan",
                "countryCode": "CN"
            },
            {
                "name": "Foshan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Gaoyao",
                "countryCode": "CN"
            },
            {
                "name": "Gaozhou",
                "countryCode": "CN"
            },
            {
                "name": "Guangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Guangzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Haikuotiankong",
                "countryCode": "CN"
            },
            {
                "name": "Haimen",
                "countryCode": "CN"
            },
            {
                "name": "Hepo",
                "countryCode": "CN"
            },
            {
                "name": "Heyuan",
                "countryCode": "CN"
            },
            {
                "name": "Huaicheng",
                "countryCode": "CN"
            },
            {
                "name": "Huanggang",
                "countryCode": "CN"
            },
            {
                "name": "Huazhou",
                "countryCode": "CN"
            },
            {
                "name": "Huicheng",
                "countryCode": "CN"
            },
            {
                "name": "Huizhou",
                "countryCode": "CN"
            },
            {
                "name": "Humen",
                "countryCode": "CN"
            },
            {
                "name": "Jiangmen",
                "countryCode": "CN"
            },
            {
                "name": "Jiazi",
                "countryCode": "CN"
            },
            {
                "name": "Jieshi",
                "countryCode": "CN"
            },
            {
                "name": "Jieyang",
                "countryCode": "CN"
            },
            {
                "name": "Lecheng",
                "countryCode": "CN"
            },
            {
                "name": "Lianjiang",
                "countryCode": "CN"
            },
            {
                "name": "Lianzhou",
                "countryCode": "CN"
            },
            {
                "name": "Licheng",
                "countryCode": "CN"
            },
            {
                "name": "Lubu",
                "countryCode": "CN"
            },
            {
                "name": "Luocheng",
                "countryCode": "CN"
            },
            {
                "name": "Luoyang",
                "countryCode": "CN"
            },
            {
                "name": "Maba",
                "countryCode": "CN"
            },
            {
                "name": "Maoming",
                "countryCode": "CN"
            },
            {
                "name": "Meizhou",
                "countryCode": "CN"
            },
            {
                "name": "Nanfeng",
                "countryCode": "CN"
            },
            {
                "name": "Pingshan",
                "countryCode": "CN"
            },
            {
                "name": "Puning",
                "countryCode": "CN"
            },
            {
                "name": "Qingyuan",
                "countryCode": "CN"
            },
            {
                "name": "Sanshui",
                "countryCode": "CN"
            },
            {
                "name": "Shantou",
                "countryCode": "CN"
            },
            {
                "name": "Shanwei",
                "countryCode": "CN"
            },
            {
                "name": "Shaoguan",
                "countryCode": "CN"
            },
            {
                "name": "Shaping",
                "countryCode": "CN"
            },
            {
                "name": "Shenzhen",
                "countryCode": "CN"
            },
            {
                "name": "Shilong",
                "countryCode": "CN"
            },
            {
                "name": "Shiqi",
                "countryCode": "CN"
            },
            {
                "name": "Shiqiao",
                "countryCode": "CN"
            },
            {
                "name": "Shiwan",
                "countryCode": "CN"
            },
            {
                "name": "Shixing",
                "countryCode": "CN"
            },
            {
                "name": "Taishan",
                "countryCode": "CN"
            },
            {
                "name": "Tangping",
                "countryCode": "CN"
            },
            {
                "name": "Wuchuan",
                "countryCode": "CN"
            },
            {
                "name": "Xingning",
                "countryCode": "CN"
            },
            {
                "name": "Xinhui",
                "countryCode": "CN"
            },
            {
                "name": "Xinyi",
                "countryCode": "CN"
            },
            {
                "name": "Xiongzhou",
                "countryCode": "CN"
            },
            {
                "name": "Xucheng",
                "countryCode": "CN"
            },
            {
                "name": "Yangchun",
                "countryCode": "CN"
            },
            {
                "name": "Yangjiang",
                "countryCode": "CN"
            },
            {
                "name": "Yingcheng",
                "countryCode": "CN"
            },
            {
                "name": "Yunfu",
                "countryCode": "CN"
            },
            {
                "name": "Zhanjiang",
                "countryCode": "CN"
            },
            {
                "name": "Zhaoqing",
                "countryCode": "CN"
            },
            {
                "name": "Zhongshan",
                "countryCode": "CN"
            },
            {
                "name": "Zhongshan Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Zhuhai",
                "countryCode": "CN"
            },
            {
                "name": "Baiyin",
                "countryCode": "CN"
            },
            {
                "name": "Beidao",
                "countryCode": "CN"
            },
            {
                "name": "Dingxi Shi",
                "countryCode": "CN"
            },
            {
                "name": "Hezuo",
                "countryCode": "CN"
            },
            {
                "name": "Jiayuguan",
                "countryCode": "CN"
            },
            {
                "name": "Jinchang",
                "countryCode": "CN"
            },
            {
                "name": "Jiuquan",
                "countryCode": "CN"
            },
            {
                "name": "Lanzhou",
                "countryCode": "CN"
            },
            {
                "name": "Laojunmiao",
                "countryCode": "CN"
            },
            {
                "name": "Linxia Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Linxia Huizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Longnan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Mawu",
                "countryCode": "CN"
            },
            {
                "name": "Pingliang",
                "countryCode": "CN"
            },
            {
                "name": "Qincheng",
                "countryCode": "CN"
            },
            {
                "name": "Qingyang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Tianshui",
                "countryCode": "CN"
            },
            {
                "name": "Wuwei",
                "countryCode": "CN"
            },
            {
                "name": "Zhangye",
                "countryCode": "CN"
            },
            {
                "name": "Zhangye Shi",
                "countryCode": "CN"
            },
            {
                "name": "Babu",
                "countryCode": "CN"
            },
            {
                "name": "Baihe",
                "countryCode": "CN"
            },
            {
                "name": "Baise City",
                "countryCode": "CN"
            },
            {
                "name": "Baise Shi",
                "countryCode": "CN"
            },
            {
                "name": "Beihai",
                "countryCode": "CN"
            },
            {
                "name": "Chongzuo Shi",
                "countryCode": "CN"
            },
            {
                "name": "Dazhai",
                "countryCode": "CN"
            },
            {
                "name": "Fangchenggang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Guigang",
                "countryCode": "CN"
            },
            {
                "name": "Guilin",
                "countryCode": "CN"
            },
            {
                "name": "Guilin Shi",
                "countryCode": "CN"
            },
            {
                "name": "Guiping",
                "countryCode": "CN"
            },
            {
                "name": "Hechi Shi",
                "countryCode": "CN"
            },
            {
                "name": "Jinji",
                "countryCode": "CN"
            },
            {
                "name": "Laibin",
                "countryCode": "CN"
            },
            {
                "name": "Lianzhou",
                "countryCode": "CN"
            },
            {
                "name": "Lingcheng",
                "countryCode": "CN"
            },
            {
                "name": "Liuzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Luorong",
                "countryCode": "CN"
            },
            {
                "name": "Nandu",
                "countryCode": "CN"
            },
            {
                "name": "Nanning",
                "countryCode": "CN"
            },
            {
                "name": "Pingnan",
                "countryCode": "CN"
            },
            {
                "name": "Pumiao",
                "countryCode": "CN"
            },
            {
                "name": "Qinzhou",
                "countryCode": "CN"
            },
            {
                "name": "Wuzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yangshuo",
                "countryCode": "CN"
            },
            {
                "name": "Yashan",
                "countryCode": "CN"
            },
            {
                "name": "Yulin",
                "countryCode": "CN"
            },
            {
                "name": "Anshun",
                "countryCode": "CN"
            },
            {
                "name": "Aoshi",
                "countryCode": "CN"
            },
            {
                "name": "Bahuang",
                "countryCode": "CN"
            },
            {
                "name": "Baishi",
                "countryCode": "CN"
            },
            {
                "name": "Bangdong",
                "countryCode": "CN"
            },
            {
                "name": "Benchu",
                "countryCode": "CN"
            },
            {
                "name": "Bijie",
                "countryCode": "CN"
            },
            {
                "name": "Chadian",
                "countryCode": "CN"
            },
            {
                "name": "Changsha",
                "countryCode": "CN"
            },
            {
                "name": "Chumi",
                "countryCode": "CN"
            },
            {
                "name": "Dabachang",
                "countryCode": "CN"
            },
            {
                "name": "Darong",
                "countryCode": "CN"
            },
            {
                "name": "Dundong",
                "countryCode": "CN"
            },
            {
                "name": "Duyun",
                "countryCode": "CN"
            },
            {
                "name": "Gaoniang",
                "countryCode": "CN"
            },
            {
                "name": "Gaowu",
                "countryCode": "CN"
            },
            {
                "name": "Gaozeng",
                "countryCode": "CN"
            },
            {
                "name": "Guandu",
                "countryCode": "CN"
            },
            {
                "name": "Guiyang",
                "countryCode": "CN"
            },
            {
                "name": "Hongzhou",
                "countryCode": "CN"
            },
            {
                "name": "Huaqiu",
                "countryCode": "CN"
            },
            {
                "name": "Lantian",
                "countryCode": "CN"
            },
            {
                "name": "Liangcunchang",
                "countryCode": "CN"
            },
            {
                "name": "Liupanshui",
                "countryCode": "CN"
            },
            {
                "name": "Longlisuo",
                "countryCode": "CN"
            },
            {
                "name": "Loushanguan",
                "countryCode": "CN"
            },
            {
                "name": "Maoping",
                "countryCode": "CN"
            },
            {
                "name": "Ouyang",
                "countryCode": "CN"
            },
            {
                "name": "Pingjiang",
                "countryCode": "CN"
            },
            {
                "name": "Qiandongnan Miao and Dong Autonomous Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Qianxinan Bouyeizu Miaozu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Qimeng",
                "countryCode": "CN"
            },
            {
                "name": "Qinglang",
                "countryCode": "CN"
            },
            {
                "name": "Runsong",
                "countryCode": "CN"
            },
            {
                "name": "Sanchahe",
                "countryCode": "CN"
            },
            {
                "name": "Sangmu",
                "countryCode": "CN"
            },
            {
                "name": "Shiqian",
                "countryCode": "CN"
            },
            {
                "name": "Songkan",
                "countryCode": "CN"
            },
            {
                "name": "Tingdong",
                "countryCode": "CN"
            },
            {
                "name": "Tonggu",
                "countryCode": "CN"
            },
            {
                "name": "Tongren",
                "countryCode": "CN"
            },
            {
                "name": "Tongren Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Weining",
                "countryCode": "CN"
            },
            {
                "name": "Wenshui",
                "countryCode": "CN"
            },
            {
                "name": "Xiajiang",
                "countryCode": "CN"
            },
            {
                "name": "Xiaoweizhai",
                "countryCode": "CN"
            },
            {
                "name": "Xinzhan",
                "countryCode": "CN"
            },
            {
                "name": "Xishan",
                "countryCode": "CN"
            },
            {
                "name": "Xujiaba",
                "countryCode": "CN"
            },
            {
                "name": "Yangtou",
                "countryCode": "CN"
            },
            {
                "name": "Youyupu",
                "countryCode": "CN"
            },
            {
                "name": "Zhongchao",
                "countryCode": "CN"
            },
            {
                "name": "Zhujiachang",
                "countryCode": "CN"
            },
            {
                "name": "Zunyi",
                "countryCode": "CN"
            },
            {
                "name": "Anyang",
                "countryCode": "CN"
            },
            {
                "name": "Anyang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Binhe",
                "countryCode": "CN"
            },
            {
                "name": "Chengguan",
                "countryCode": "CN"
            },
            {
                "name": "Chengjiao",
                "countryCode": "CN"
            },
            {
                "name": "Daokou",
                "countryCode": "CN"
            },
            {
                "name": "Dingcheng",
                "countryCode": "CN"
            },
            {
                "name": "Hancheng",
                "countryCode": "CN"
            },
            {
                "name": "Hebi",
                "countryCode": "CN"
            },
            {
                "name": "Huaidian",
                "countryCode": "CN"
            },
            {
                "name": "Huazhou",
                "countryCode": "CN"
            },
            {
                "name": "Huichang",
                "countryCode": "CN"
            },
            {
                "name": "Jianshe",
                "countryCode": "CN"
            },
            {
                "name": "Jiaozuo",
                "countryCode": "CN"
            },
            {
                "name": "Jishui",
                "countryCode": "CN"
            },
            {
                "name": "Jiyuan",
                "countryCode": "CN"
            },
            {
                "name": "Kaifeng",
                "countryCode": "CN"
            },
            {
                "name": "Kaiyuan",
                "countryCode": "CN"
            },
            {
                "name": "Lingbao Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Luohe",
                "countryCode": "CN"
            },
            {
                "name": "Luohe Shi",
                "countryCode": "CN"
            },
            {
                "name": "Luoyang",
                "countryCode": "CN"
            },
            {
                "name": "Minggang",
                "countryCode": "CN"
            },
            {
                "name": "Nanyang",
                "countryCode": "CN"
            },
            {
                "name": "Pingdingshan",
                "countryCode": "CN"
            },
            {
                "name": "Puyang Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Puyang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Qingping",
                "countryCode": "CN"
            },
            {
                "name": "Runing",
                "countryCode": "CN"
            },
            {
                "name": "Ruzhou",
                "countryCode": "CN"
            },
            {
                "name": "Shangqiu",
                "countryCode": "CN"
            },
            {
                "name": "Songyang",
                "countryCode": "CN"
            },
            {
                "name": "Suohe",
                "countryCode": "CN"
            },
            {
                "name": "Tanbei",
                "countryCode": "CN"
            },
            {
                "name": "Wacheng",
                "countryCode": "CN"
            },
            {
                "name": "Xiangcheng Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Xincheng",
                "countryCode": "CN"
            },
            {
                "name": "Xinhualu",
                "countryCode": "CN"
            },
            {
                "name": "Xinxiang",
                "countryCode": "CN"
            },
            {
                "name": "Xinxiang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Xinyang",
                "countryCode": "CN"
            },
            {
                "name": "Xixiang",
                "countryCode": "CN"
            },
            {
                "name": "Xuchang",
                "countryCode": "CN"
            },
            {
                "name": "Xuchang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Yakou",
                "countryCode": "CN"
            },
            {
                "name": "Yanshi Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Yigou",
                "countryCode": "CN"
            },
            {
                "name": "Yima",
                "countryCode": "CN"
            },
            {
                "name": "Yingchuan",
                "countryCode": "CN"
            },
            {
                "name": "Yunyang",
                "countryCode": "CN"
            },
            {
                "name": "Zhengzhou",
                "countryCode": "CN"
            },
            {
                "name": "Zhoukou",
                "countryCode": "CN"
            },
            {
                "name": "Zhumadian",
                "countryCode": "CN"
            },
            {
                "name": "Zhumadian Shi",
                "countryCode": "CN"
            },
            {
                "name": "Zijinglu",
                "countryCode": "CN"
            },
            {
                "name": "Anlu",
                "countryCode": "CN"
            },
            {
                "name": "Buhe",
                "countryCode": "CN"
            },
            {
                "name": "Caidian",
                "countryCode": "CN"
            },
            {
                "name": "Caohe",
                "countryCode": "CN"
            },
            {
                "name": "Chengzhong",
                "countryCode": "CN"
            },
            {
                "name": "Danjiangkou",
                "countryCode": "CN"
            },
            {
                "name": "Daye",
                "countryCode": "CN"
            },
            {
                "name": "Duobao",
                "countryCode": "CN"
            },
            {
                "name": "Enshi",
                "countryCode": "CN"
            },
            {
                "name": "Enshi Tujiazu Miaozu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Ezhou",
                "countryCode": "CN"
            },
            {
                "name": "Ezhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Fengkou",
                "countryCode": "CN"
            },
            {
                "name": "Guangshui",
                "countryCode": "CN"
            },
            {
                "name": "Gucheng Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Hanchuan",
                "countryCode": "CN"
            },
            {
                "name": "Huanggang",
                "countryCode": "CN"
            },
            {
                "name": "Huangmei",
                "countryCode": "CN"
            },
            {
                "name": "Huangpi",
                "countryCode": "CN"
            },
            {
                "name": "Huangshi",
                "countryCode": "CN"
            },
            {
                "name": "Huangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Jingling",
                "countryCode": "CN"
            },
            {
                "name": "Jingmen",
                "countryCode": "CN"
            },
            {
                "name": "Jingmen Shi",
                "countryCode": "CN"
            },
            {
                "name": "Jingzhou",
                "countryCode": "CN"
            },
            {
                "name": "Laohekou",
                "countryCode": "CN"
            },
            {
                "name": "Lichuan",
                "countryCode": "CN"
            },
            {
                "name": "Macheng",
                "countryCode": "CN"
            },
            {
                "name": "Nanzhang Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Puqi",
                "countryCode": "CN"
            },
            {
                "name": "Qianjiang",
                "countryCode": "CN"
            },
            {
                "name": "Qingquan",
                "countryCode": "CN"
            },
            {
                "name": "Shashi",
                "countryCode": "CN"
            },
            {
                "name": "Shennongjia",
                "countryCode": "CN"
            },
            {
                "name": "Shiyan",
                "countryCode": "CN"
            },
            {
                "name": "Suizhou",
                "countryCode": "CN"
            },
            {
                "name": "Wuhan",
                "countryCode": "CN"
            },
            {
                "name": "Wuxue",
                "countryCode": "CN"
            },
            {
                "name": "Xiangyang",
                "countryCode": "CN"
            },
            {
                "name": "Xianning",
                "countryCode": "CN"
            },
            {
                "name": "Xianning Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Xiantao",
                "countryCode": "CN"
            },
            {
                "name": "Xiaogan",
                "countryCode": "CN"
            },
            {
                "name": "Xihe",
                "countryCode": "CN"
            },
            {
                "name": "Xindi",
                "countryCode": "CN"
            },
            {
                "name": "Xinshi",
                "countryCode": "CN"
            },
            {
                "name": "Xinzhou",
                "countryCode": "CN"
            },
            {
                "name": "Xiulin",
                "countryCode": "CN"
            },
            {
                "name": "Yichang",
                "countryCode": "CN"
            },
            {
                "name": "Yicheng",
                "countryCode": "CN"
            },
            {
                "name": "Yunmeng Chengguanzhen",
                "countryCode": "CN"
            },
            {
                "name": "Zaoyang",
                "countryCode": "CN"
            },
            {
                "name": "Zhicheng",
                "countryCode": "CN"
            },
            {
                "name": "Zhijiang",
                "countryCode": "CN"
            },
            {
                "name": "Zhongxiang",
                "countryCode": "CN"
            },
            {
                "name": "Baoding",
                "countryCode": "CN"
            },
            {
                "name": "Beidaihehaibin",
                "countryCode": "CN"
            },
            {
                "name": "Botou",
                "countryCode": "CN"
            },
            {
                "name": "Cangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Cangzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Changli",
                "countryCode": "CN"
            },
            {
                "name": "Chengde",
                "countryCode": "CN"
            },
            {
                "name": "Chengde Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Dingzhou",
                "countryCode": "CN"
            },
            {
                "name": "Fengrun",
                "countryCode": "CN"
            },
            {
                "name": "Guye",
                "countryCode": "CN"
            },
            {
                "name": "Handan",
                "countryCode": "CN"
            },
            {
                "name": "Hecun",
                "countryCode": "CN"
            },
            {
                "name": "Hengshui",
                "countryCode": "CN"
            },
            {
                "name": "Langfang",
                "countryCode": "CN"
            },
            {
                "name": "Langfang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Linshui",
                "countryCode": "CN"
            },
            {
                "name": "Linxi",
                "countryCode": "CN"
            },
            {
                "name": "Luancheng",
                "countryCode": "CN"
            },
            {
                "name": "Nangong",
                "countryCode": "CN"
            },
            {
                "name": "Pengcheng",
                "countryCode": "CN"
            },
            {
                "name": "Qinhuangdao",
                "countryCode": "CN"
            },
            {
                "name": "Renqiu",
                "countryCode": "CN"
            },
            {
                "name": "Shahecheng",
                "countryCode": "CN"
            },
            {
                "name": "Shanhaiguan",
                "countryCode": "CN"
            },
            {
                "name": "Shijiazhuang",
                "countryCode": "CN"
            },
            {
                "name": "Shijiazhuang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Songling",
                "countryCode": "CN"
            },
            {
                "name": "Tangjiazhuang",
                "countryCode": "CN"
            },
            {
                "name": "Tangshan",
                "countryCode": "CN"
            },
            {
                "name": "Tangshan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Tianchang",
                "countryCode": "CN"
            },
            {
                "name": "Xingtai",
                "countryCode": "CN"
            },
            {
                "name": "Xinji",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiakou",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiakou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiakou Shi Xuanhua Qu",
                "countryCode": "CN"
            },
            {
                "name": "Zhaogezhuang",
                "countryCode": "CN"
            },
            {
                "name": "Zunhua",
                "countryCode": "CN"
            },
            {
                "name": "Basuo",
                "countryCode": "CN"
            },
            {
                "name": "Chongshan",
                "countryCode": "CN"
            },
            {
                "name": "Dadonghai",
                "countryCode": "CN"
            },
            {
                "name": "Haikou",
                "countryCode": "CN"
            },
            {
                "name": "Jinjiang",
                "countryCode": "CN"
            },
            {
                "name": "Lincheng",
                "countryCode": "CN"
            },
            {
                "name": "Nada",
                "countryCode": "CN"
            },
            {
                "name": "Qionghai",
                "countryCode": "CN"
            },
            {
                "name": "Qiongshan",
                "countryCode": "CN"
            },
            {
                "name": "Sansha",
                "countryCode": "CN"
            },
            {
                "name": "Sanya",
                "countryCode": "CN"
            },
            {
                "name": "Wanning",
                "countryCode": "CN"
            },
            {
                "name": "Wenchang",
                "countryCode": "CN"
            },
            {
                "name": "Xiuying",
                "countryCode": "CN"
            },
            {
                "name": "Acheng",
                "countryCode": "CN"
            },
            {
                "name": "Anda",
                "countryCode": "CN"
            },
            {
                "name": "Baiquan",
                "countryCode": "CN"
            },
            {
                "name": "Bamiantong",
                "countryCode": "CN"
            },
            {
                "name": "Baoqing",
                "countryCode": "CN"
            },
            {
                "name": "Baoshan",
                "countryCode": "CN"
            },
            {
                "name": "Bayan",
                "countryCode": "CN"
            },
            {
                "name": "Bei’an",
                "countryCode": "CN"
            },
            {
                "name": "Binzhou",
                "countryCode": "CN"
            },
            {
                "name": "Boli",
                "countryCode": "CN"
            },
            {
                "name": "Chaihe",
                "countryCode": "CN"
            },
            {
                "name": "Chengzihe",
                "countryCode": "CN"
            },
            {
                "name": "Daqing",
                "countryCode": "CN"
            },
            {
                "name": "Dongning",
                "countryCode": "CN"
            },
            {
                "name": "Dongxing",
                "countryCode": "CN"
            },
            {
                "name": "Fendou",
                "countryCode": "CN"
            },
            {
                "name": "Fengxiang",
                "countryCode": "CN"
            },
            {
                "name": "Fujin",
                "countryCode": "CN"
            },
            {
                "name": "Fuli",
                "countryCode": "CN"
            },
            {
                "name": "Fuyu",
                "countryCode": "CN"
            },
            {
                "name": "Fuyuan",
                "countryCode": "CN"
            },
            {
                "name": "Gannan",
                "countryCode": "CN"
            },
            {
                "name": "Hailin",
                "countryCode": "CN"
            },
            {
                "name": "Hailun",
                "countryCode": "CN"
            },
            {
                "name": "Harbin",
                "countryCode": "CN"
            },
            {
                "name": "Hegang",
                "countryCode": "CN"
            },
            {
                "name": "Heihe",
                "countryCode": "CN"
            },
            {
                "name": "Honggang",
                "countryCode": "CN"
            },
            {
                "name": "Huanan",
                "countryCode": "CN"
            },
            {
                "name": "Hulan",
                "countryCode": "CN"
            },
            {
                "name": "Hulan Ergi",
                "countryCode": "CN"
            },
            {
                "name": "Jiamusi",
                "countryCode": "CN"
            },
            {
                "name": "Jidong",
                "countryCode": "CN"
            },
            {
                "name": "Jixi",
                "countryCode": "CN"
            },
            {
                "name": "Langxiang",
                "countryCode": "CN"
            },
            {
                "name": "Lanxi",
                "countryCode": "CN"
            },
            {
                "name": "Lianhe",
                "countryCode": "CN"
            },
            {
                "name": "Lingdong",
                "countryCode": "CN"
            },
            {
                "name": "Linkou",
                "countryCode": "CN"
            },
            {
                "name": "Longfeng",
                "countryCode": "CN"
            },
            {
                "name": "Longjiang",
                "countryCode": "CN"
            },
            {
                "name": "Mingshui",
                "countryCode": "CN"
            },
            {
                "name": "Mishan",
                "countryCode": "CN"
            },
            {
                "name": "Mudanjiang",
                "countryCode": "CN"
            },
            {
                "name": "Nehe",
                "countryCode": "CN"
            },
            {
                "name": "Nenjiang",
                "countryCode": "CN"
            },
            {
                "name": "Nianzishan",
                "countryCode": "CN"
            },
            {
                "name": "Ning’an",
                "countryCode": "CN"
            },
            {
                "name": "Qinggang",
                "countryCode": "CN"
            },
            {
                "name": "Qiqihar",
                "countryCode": "CN"
            },
            {
                "name": "Shangzhi",
                "countryCode": "CN"
            },
            {
                "name": "Shanhecun",
                "countryCode": "CN"
            },
            {
                "name": "Shuangcheng",
                "countryCode": "CN"
            },
            {
                "name": "Shuangyashan",
                "countryCode": "CN"
            },
            {
                "name": "Suifenhe",
                "countryCode": "CN"
            },
            {
                "name": "Suihua",
                "countryCode": "CN"
            },
            {
                "name": "Suileng",
                "countryCode": "CN"
            },
            {
                "name": "Tahe",
                "countryCode": "CN"
            },
            {
                "name": "Taihecun",
                "countryCode": "CN"
            },
            {
                "name": "Taikang",
                "countryCode": "CN"
            },
            {
                "name": "Tailai",
                "countryCode": "CN"
            },
            {
                "name": "Tieli",
                "countryCode": "CN"
            },
            {
                "name": "Wangkui",
                "countryCode": "CN"
            },
            {
                "name": "Wuchang",
                "countryCode": "CN"
            },
            {
                "name": "Xinqing",
                "countryCode": "CN"
            },
            {
                "name": "Yichun",
                "countryCode": "CN"
            },
            {
                "name": "Yilan",
                "countryCode": "CN"
            },
            {
                "name": "Youhao",
                "countryCode": "CN"
            },
            {
                "name": "Zhaodong",
                "countryCode": "CN"
            },
            {
                "name": "Zhaoyuan",
                "countryCode": "CN"
            },
            {
                "name": "Zhaozhou",
                "countryCode": "CN"
            },
            {
                "name": "Anjiang",
                "countryCode": "CN"
            },
            {
                "name": "Anping",
                "countryCode": "CN"
            },
            {
                "name": "Anxiang",
                "countryCode": "CN"
            },
            {
                "name": "Baisha",
                "countryCode": "CN"
            },
            {
                "name": "Biyong",
                "countryCode": "CN"
            },
            {
                "name": "Bojia",
                "countryCode": "CN"
            },
            {
                "name": "Boyang",
                "countryCode": "CN"
            },
            {
                "name": "Bozhou",
                "countryCode": "CN"
            },
            {
                "name": "Changde",
                "countryCode": "CN"
            },
            {
                "name": "Changsha",
                "countryCode": "CN"
            },
            {
                "name": "Changsha Shi",
                "countryCode": "CN"
            },
            {
                "name": "Chatian",
                "countryCode": "CN"
            },
            {
                "name": "Chenzhou",
                "countryCode": "CN"
            },
            {
                "name": "Dabaozi",
                "countryCode": "CN"
            },
            {
                "name": "Dehang",
                "countryCode": "CN"
            },
            {
                "name": "Dengjiapu",
                "countryCode": "CN"
            },
            {
                "name": "Dengyuantai",
                "countryCode": "CN"
            },
            {
                "name": "Dongshan Dongzuxiang",
                "countryCode": "CN"
            },
            {
                "name": "Fenghuang",
                "countryCode": "CN"
            },
            {
                "name": "Gangdong",
                "countryCode": "CN"
            },
            {
                "name": "Gaoqiao",
                "countryCode": "CN"
            },
            {
                "name": "Gaoyi",
                "countryCode": "CN"
            },
            {
                "name": "Guankou",
                "countryCode": "CN"
            },
            {
                "name": "Hengbanqiao",
                "countryCode": "CN"
            },
            {
                "name": "Hengyang",
                "countryCode": "CN"
            },
            {
                "name": "Hexiangqiao",
                "countryCode": "CN"
            },
            {
                "name": "Hongjiang",
                "countryCode": "CN"
            },
            {
                "name": "Hongqiao",
                "countryCode": "CN"
            },
            {
                "name": "Huaihua",
                "countryCode": "CN"
            },
            {
                "name": "Huangjinjing",
                "countryCode": "CN"
            },
            {
                "name": "Huanglong",
                "countryCode": "CN"
            },
            {
                "name": "Huangmaoyuan",
                "countryCode": "CN"
            },
            {
                "name": "Huangqiao",
                "countryCode": "CN"
            },
            {
                "name": "Huangtukuang",
                "countryCode": "CN"
            },
            {
                "name": "Huangxikou",
                "countryCode": "CN"
            },
            {
                "name": "Huaqiao",
                "countryCode": "CN"
            },
            {
                "name": "Huayuan",
                "countryCode": "CN"
            },
            {
                "name": "Huomachong",
                "countryCode": "CN"
            },
            {
                "name": "Jiangfang",
                "countryCode": "CN"
            },
            {
                "name": "Jiangkouxu",
                "countryCode": "CN"
            },
            {
                "name": "Jiangshi",
                "countryCode": "CN"
            },
            {
                "name": "Jinhe",
                "countryCode": "CN"
            },
            {
                "name": "Jinshi",
                "countryCode": "CN"
            },
            {
                "name": "Jinshiqiao",
                "countryCode": "CN"
            },
            {
                "name": "Lanli",
                "countryCode": "CN"
            },
            {
                "name": "Leiyang",
                "countryCode": "CN"
            },
            {
                "name": "Lengshuijiang",
                "countryCode": "CN"
            },
            {
                "name": "Lengshuitan",
                "countryCode": "CN"
            },
            {
                "name": "Liangyaping",
                "countryCode": "CN"
            },
            {
                "name": "Lianyuan",
                "countryCode": "CN"
            },
            {
                "name": "Linkou",
                "countryCode": "CN"
            },
            {
                "name": "Liuduzhai",
                "countryCode": "CN"
            },
            {
                "name": "Lixiqiao",
                "countryCode": "CN"
            },
            {
                "name": "Longtan",
                "countryCode": "CN"
            },
            {
                "name": "Longtou’an",
                "countryCode": "CN"
            },
            {
                "name": "Loudi",
                "countryCode": "CN"
            },
            {
                "name": "Luojiu",
                "countryCode": "CN"
            },
            {
                "name": "Luyang",
                "countryCode": "CN"
            },
            {
                "name": "Malin",
                "countryCode": "CN"
            },
            {
                "name": "Maoping",
                "countryCode": "CN"
            },
            {
                "name": "Ma’an",
                "countryCode": "CN"
            },
            {
                "name": "Nanmuping",
                "countryCode": "CN"
            },
            {
                "name": "Nanzhou",
                "countryCode": "CN"
            },
            {
                "name": "Prefecture of Chenzhou",
                "countryCode": "CN"
            },
            {
                "name": "Pukou",
                "countryCode": "CN"
            },
            {
                "name": "Puzi",
                "countryCode": "CN"
            },
            {
                "name": "Qiancheng",
                "countryCode": "CN"
            },
            {
                "name": "Qianzhou",
                "countryCode": "CN"
            },
            {
                "name": "Qiaojiang",
                "countryCode": "CN"
            },
            {
                "name": "Qingjiangqiao",
                "countryCode": "CN"
            },
            {
                "name": "Qingxi",
                "countryCode": "CN"
            },
            {
                "name": "Qionghu",
                "countryCode": "CN"
            },
            {
                "name": "Ruoshui",
                "countryCode": "CN"
            },
            {
                "name": "Shangmei",
                "countryCode": "CN"
            },
            {
                "name": "Shanmen",
                "countryCode": "CN"
            },
            {
                "name": "Shijiang",
                "countryCode": "CN"
            },
            {
                "name": "Shuangjiang",
                "countryCode": "CN"
            },
            {
                "name": "Shuangxi",
                "countryCode": "CN"
            },
            {
                "name": "Shuiche",
                "countryCode": "CN"
            },
            {
                "name": "Shuidatian",
                "countryCode": "CN"
            },
            {
                "name": "Simenqian",
                "countryCode": "CN"
            },
            {
                "name": "Tangjiafang",
                "countryCode": "CN"
            },
            {
                "name": "Tanwan",
                "countryCode": "CN"
            },
            {
                "name": "Tongwan",
                "countryCode": "CN"
            },
            {
                "name": "Tuokou",
                "countryCode": "CN"
            },
            {
                "name": "Wantouqiao",
                "countryCode": "CN"
            },
            {
                "name": "Wenxing",
                "countryCode": "CN"
            },
            {
                "name": "Wulingyuan",
                "countryCode": "CN"
            },
            {
                "name": "Wuxi",
                "countryCode": "CN"
            },
            {
                "name": "Wuyang",
                "countryCode": "CN"
            },
            {
                "name": "Xiangtan",
                "countryCode": "CN"
            },
            {
                "name": "Xiangxi Tujiazu Miaozu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Xiangxiang",
                "countryCode": "CN"
            },
            {
                "name": "Xianrenwan",
                "countryCode": "CN"
            },
            {
                "name": "Xianxi",
                "countryCode": "CN"
            },
            {
                "name": "Xiaohenglong",
                "countryCode": "CN"
            },
            {
                "name": "Xiaolongmen",
                "countryCode": "CN"
            },
            {
                "name": "Xiaoshajiang",
                "countryCode": "CN"
            },
            {
                "name": "Xishan",
                "countryCode": "CN"
            },
            {
                "name": "Xixi",
                "countryCode": "CN"
            },
            {
                "name": "Xiyan",
                "countryCode": "CN"
            },
            {
                "name": "Yanmen",
                "countryCode": "CN"
            },
            {
                "name": "Yaoshi",
                "countryCode": "CN"
            },
            {
                "name": "Yatunpu",
                "countryCode": "CN"
            },
            {
                "name": "Yiyang",
                "countryCode": "CN"
            },
            {
                "name": "Yongfeng",
                "countryCode": "CN"
            },
            {
                "name": "Yongzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yueyang",
                "countryCode": "CN"
            },
            {
                "name": "Yueyang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Yutan",
                "countryCode": "CN"
            },
            {
                "name": "Zhaishi Miaozu Dongzuxiang",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiajie",
                "countryCode": "CN"
            },
            {
                "name": "Zhongfang",
                "countryCode": "CN"
            },
            {
                "name": "Zhongzhai",
                "countryCode": "CN"
            },
            {
                "name": "Zhushi",
                "countryCode": "CN"
            },
            {
                "name": "Zhuzhou",
                "countryCode": "CN"
            },
            {
                "name": "Zhuzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Zhuzhoujiang Miaozuxiang",
                "countryCode": "CN"
            },
            {
                "name": "Baicheng",
                "countryCode": "CN"
            },
            {
                "name": "Baishan",
                "countryCode": "CN"
            },
            {
                "name": "Baishishan",
                "countryCode": "CN"
            },
            {
                "name": "Changchun",
                "countryCode": "CN"
            },
            {
                "name": "Changling",
                "countryCode": "CN"
            },
            {
                "name": "Chaoyang",
                "countryCode": "CN"
            },
            {
                "name": "Dalai",
                "countryCode": "CN"
            },
            {
                "name": "Dashitou",
                "countryCode": "CN"
            },
            {
                "name": "Dehui",
                "countryCode": "CN"
            },
            {
                "name": "Dongfeng",
                "countryCode": "CN"
            },
            {
                "name": "Dunhua",
                "countryCode": "CN"
            },
            {
                "name": "Erdaojiang",
                "countryCode": "CN"
            },
            {
                "name": "Fuyu",
                "countryCode": "CN"
            },
            {
                "name": "Gongzhuling",
                "countryCode": "CN"
            },
            {
                "name": "Guangming",
                "countryCode": "CN"
            },
            {
                "name": "Helong",
                "countryCode": "CN"
            },
            {
                "name": "Hepingjie",
                "countryCode": "CN"
            },
            {
                "name": "Huadian",
                "countryCode": "CN"
            },
            {
                "name": "Huangnihe",
                "countryCode": "CN"
            },
            {
                "name": "Huinan",
                "countryCode": "CN"
            },
            {
                "name": "Hunchun",
                "countryCode": "CN"
            },
            {
                "name": "Jilin",
                "countryCode": "CN"
            },
            {
                "name": "Jishu",
                "countryCode": "CN"
            },
            {
                "name": "Jiutai",
                "countryCode": "CN"
            },
            {
                "name": "Ji’an",
                "countryCode": "CN"
            },
            {
                "name": "Kaitong",
                "countryCode": "CN"
            },
            {
                "name": "Liaoyuan",
                "countryCode": "CN"
            },
            {
                "name": "Linjiang",
                "countryCode": "CN"
            },
            {
                "name": "Lishu",
                "countryCode": "CN"
            },
            {
                "name": "Liuhe",
                "countryCode": "CN"
            },
            {
                "name": "Longjing",
                "countryCode": "CN"
            },
            {
                "name": "Meihekou",
                "countryCode": "CN"
            },
            {
                "name": "Mingyue",
                "countryCode": "CN"
            },
            {
                "name": "Minzhu",
                "countryCode": "CN"
            },
            {
                "name": "Panshi",
                "countryCode": "CN"
            },
            {
                "name": "Sanchazi",
                "countryCode": "CN"
            },
            {
                "name": "Shuangyang",
                "countryCode": "CN"
            },
            {
                "name": "Shulan",
                "countryCode": "CN"
            },
            {
                "name": "Siping",
                "countryCode": "CN"
            },
            {
                "name": "Songjianghe",
                "countryCode": "CN"
            },
            {
                "name": "Songyuan",
                "countryCode": "CN"
            },
            {
                "name": "Tonghua",
                "countryCode": "CN"
            },
            {
                "name": "Tonghua Shi",
                "countryCode": "CN"
            },
            {
                "name": "Tumen",
                "countryCode": "CN"
            },
            {
                "name": "Wangqing",
                "countryCode": "CN"
            },
            {
                "name": "Xinglongshan",
                "countryCode": "CN"
            },
            {
                "name": "Yanbian Chaoxianzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Yanji",
                "countryCode": "CN"
            },
            {
                "name": "Yantongshan",
                "countryCode": "CN"
            },
            {
                "name": "Yushu",
                "countryCode": "CN"
            },
            {
                "name": "Zhengjiatun",
                "countryCode": "CN"
            },
            {
                "name": "Zhenlai",
                "countryCode": "CN"
            },
            {
                "name": "ChangZhou",
                "countryCode": "CN"
            },
            {
                "name": "HuaiAn",
                "countryCode": "CN"
            },
            {
                "name": "LianYunGang",
                "countryCode": "CN"
            },
            {
                "name": "NanJing",
                "countryCode": "CN"
            },
            {
                "name": "NanTong",
                "countryCode": "CN"
            },
            {
                "name": "SuQian",
                "countryCode": "CN"
            },
            {
                "name": "SuZhou",
                "countryCode": "CN"
            },
            {
                "name": "TaiZhou",
                "countryCode": "CN"
            },
            {
                "name": "WuXi",
                "countryCode": "CN"
            },
            {
                "name": "XuZhou",
                "countryCode": "CN"
            },
            {
                "name": "YanCheng",
                "countryCode": "CN"
            },
            {
                "name": "YangZhou",
                "countryCode": "CN"
            },
            {
                "name": "ZhenJiang",
                "countryCode": "CN"
            },
            {
                "name": "Changleng",
                "countryCode": "CN"
            },
            {
                "name": "Fenyi",
                "countryCode": "CN"
            },
            {
                "name": "Ganzhou",
                "countryCode": "CN"
            },
            {
                "name": "Ganzhou Shi",
                "countryCode": "CN"
            },
            {
                "name": "Guixi",
                "countryCode": "CN"
            },
            {
                "name": "Jianguang",
                "countryCode": "CN"
            },
            {
                "name": "Jingdezhen",
                "countryCode": "CN"
            },
            {
                "name": "Jingdezhen Shi",
                "countryCode": "CN"
            },
            {
                "name": "Jiujiang",
                "countryCode": "CN"
            },
            {
                "name": "Ji’an",
                "countryCode": "CN"
            },
            {
                "name": "Nanchang",
                "countryCode": "CN"
            },
            {
                "name": "Pingxiang",
                "countryCode": "CN"
            },
            {
                "name": "Poyang",
                "countryCode": "CN"
            },
            {
                "name": "Shangrao",
                "countryCode": "CN"
            },
            {
                "name": "Xinyu",
                "countryCode": "CN"
            },
            {
                "name": "Yichun",
                "countryCode": "CN"
            },
            {
                "name": "Anshan",
                "countryCode": "CN"
            },
            {
                "name": "Beipiao",
                "countryCode": "CN"
            },
            {
                "name": "Benxi",
                "countryCode": "CN"
            },
            {
                "name": "Changtu",
                "countryCode": "CN"
            },
            {
                "name": "Chaoyang",
                "countryCode": "CN"
            },
            {
                "name": "Dalian",
                "countryCode": "CN"
            },
            {
                "name": "Dalianwan",
                "countryCode": "CN"
            },
            {
                "name": "Dandong",
                "countryCode": "CN"
            },
            {
                "name": "Dashiqiao",
                "countryCode": "CN"
            },
            {
                "name": "Dongling",
                "countryCode": "CN"
            },
            {
                "name": "Fengcheng",
                "countryCode": "CN"
            },
            {
                "name": "Fushun",
                "countryCode": "CN"
            },
            {
                "name": "Fuxin",
                "countryCode": "CN"
            },
            {
                "name": "Gaizhou",
                "countryCode": "CN"
            },
            {
                "name": "Gongchangling",
                "countryCode": "CN"
            },
            {
                "name": "Haicheng",
                "countryCode": "CN"
            },
            {
                "name": "Heishan",
                "countryCode": "CN"
            },
            {
                "name": "Huanren",
                "countryCode": "CN"
            },
            {
                "name": "Huludao",
                "countryCode": "CN"
            },
            {
                "name": "Huludao Shi",
                "countryCode": "CN"
            },
            {
                "name": "Hushitai",
                "countryCode": "CN"
            },
            {
                "name": "Jinzhou",
                "countryCode": "CN"
            },
            {
                "name": "Jiupu",
                "countryCode": "CN"
            },
            {
                "name": "Kaiyuan",
                "countryCode": "CN"
            },
            {
                "name": "Kuandian",
                "countryCode": "CN"
            },
            {
                "name": "Langtoucun",
                "countryCode": "CN"
            },
            {
                "name": "Lianshan",
                "countryCode": "CN"
            },
            {
                "name": "Liaoyang",
                "countryCode": "CN"
            },
            {
                "name": "Liaozhong",
                "countryCode": "CN"
            },
            {
                "name": "Linghai",
                "countryCode": "CN"
            },
            {
                "name": "Lingyuan",
                "countryCode": "CN"
            },
            {
                "name": "Lüshun",
                "countryCode": "CN"
            },
            {
                "name": "Nanpiao",
                "countryCode": "CN"
            },
            {
                "name": "Nantai",
                "countryCode": "CN"
            },
            {
                "name": "Panjin Shi",
                "countryCode": "CN"
            },
            {
                "name": "Panshan",
                "countryCode": "CN"
            },
            {
                "name": "Pulandian",
                "countryCode": "CN"
            },
            {
                "name": "Shenyang",
                "countryCode": "CN"
            },
            {
                "name": "Sujiatun",
                "countryCode": "CN"
            },
            {
                "name": "Tieling",
                "countryCode": "CN"
            },
            {
                "name": "Tieling Shi",
                "countryCode": "CN"
            },
            {
                "name": "Wafangdian",
                "countryCode": "CN"
            },
            {
                "name": "Xiaoshi",
                "countryCode": "CN"
            },
            {
                "name": "Xifeng",
                "countryCode": "CN"
            },
            {
                "name": "Xingcheng",
                "countryCode": "CN"
            },
            {
                "name": "Xinmin",
                "countryCode": "CN"
            },
            {
                "name": "Xinxing",
                "countryCode": "CN"
            },
            {
                "name": "Xiuyan",
                "countryCode": "CN"
            },
            {
                "name": "Yebaishou",
                "countryCode": "CN"
            },
            {
                "name": "Yingkou",
                "countryCode": "CN"
            },
            {
                "name": "Zhuanghe",
                "countryCode": "CN"
            },
            {
                "name": "Baotou",
                "countryCode": "CN"
            },
            {
                "name": "Bayan Nur",
                "countryCode": "CN"
            },
            {
                "name": "Bayannur Shi",
                "countryCode": "CN"
            },
            {
                "name": "Beichengqu",
                "countryCode": "CN"
            },
            {
                "name": "Chifeng",
                "countryCode": "CN"
            },
            {
                "name": "Dongsheng",
                "countryCode": "CN"
            },
            {
                "name": "Erenhot",
                "countryCode": "CN"
            },
            {
                "name": "E’erguna",
                "countryCode": "CN"
            },
            {
                "name": "Genhe",
                "countryCode": "CN"
            },
            {
                "name": "Hailar",
                "countryCode": "CN"
            },
            {
                "name": "Hohhot",
                "countryCode": "CN"
            },
            {
                "name": "Hulunbuir Region",
                "countryCode": "CN"
            },
            {
                "name": "Jalai Nur",
                "countryCode": "CN"
            },
            {
                "name": "Jiagedaqi",
                "countryCode": "CN"
            },
            {
                "name": "Jining",
                "countryCode": "CN"
            },
            {
                "name": "Manzhouli",
                "countryCode": "CN"
            },
            {
                "name": "Mositai",
                "countryCode": "CN"
            },
            {
                "name": "Mujiayingzi",
                "countryCode": "CN"
            },
            {
                "name": "Ordos",
                "countryCode": "CN"
            },
            {
                "name": "Ordos Shi",
                "countryCode": "CN"
            },
            {
                "name": "Oroqen Zizhiqi",
                "countryCode": "CN"
            },
            {
                "name": "Pingzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Salaqi",
                "countryCode": "CN"
            },
            {
                "name": "Shiguai",
                "countryCode": "CN"
            },
            {
                "name": "Tongliao",
                "countryCode": "CN"
            },
            {
                "name": "Ulanhot",
                "countryCode": "CN"
            },
            {
                "name": "Wenquan",
                "countryCode": "CN"
            },
            {
                "name": "Wuda",
                "countryCode": "CN"
            },
            {
                "name": "Wuhai",
                "countryCode": "CN"
            },
            {
                "name": "Xilin Gol Meng",
                "countryCode": "CN"
            },
            {
                "name": "Xilin Hot",
                "countryCode": "CN"
            },
            {
                "name": "Yakeshi",
                "countryCode": "CN"
            },
            {
                "name": "Zhalantun",
                "countryCode": "CN"
            },
            {
                "name": "Dawukou",
                "countryCode": "CN"
            },
            {
                "name": "Dongta",
                "countryCode": "CN"
            },
            {
                "name": "Shitanjing",
                "countryCode": "CN"
            },
            {
                "name": "Shizuishan",
                "countryCode": "CN"
            },
            {
                "name": "Wuzhong",
                "countryCode": "CN"
            },
            {
                "name": "Yinchuan",
                "countryCode": "CN"
            },
            {
                "name": "Zhongwei",
                "countryCode": "CN"
            },
            {
                "name": "Delingha",
                "countryCode": "CN"
            },
            {
                "name": "Golmud",
                "countryCode": "CN"
            },
            {
                "name": "Golog Tibetan Autonomous Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Haibei Tibetan Autonomous Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Huangnan Zangzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Xining",
                "countryCode": "CN"
            },
            {
                "name": "Xireg",
                "countryCode": "CN"
            },
            {
                "name": "Yushu Zangzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Aba Zangzu Qiangzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Barkam",
                "countryCode": "CN"
            },
            {
                "name": "Bazhong Shi",
                "countryCode": "CN"
            },
            {
                "name": "Changchi",
                "countryCode": "CN"
            },
            {
                "name": "Chengdu",
                "countryCode": "CN"
            },
            {
                "name": "Chonglong",
                "countryCode": "CN"
            },
            {
                "name": "Dadukou",
                "countryCode": "CN"
            },
            {
                "name": "Dazhou",
                "countryCode": "CN"
            },
            {
                "name": "Deyang",
                "countryCode": "CN"
            },
            {
                "name": "Dongxi",
                "countryCode": "CN"
            },
            {
                "name": "Fangting",
                "countryCode": "CN"
            },
            {
                "name": "Fubao",
                "countryCode": "CN"
            },
            {
                "name": "Gaoping",
                "countryCode": "CN"
            },
            {
                "name": "Garzê Zangzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Guangyuan",
                "countryCode": "CN"
            },
            {
                "name": "Guang’an",
                "countryCode": "CN"
            },
            {
                "name": "Jiancheng",
                "countryCode": "CN"
            },
            {
                "name": "Jiangyou",
                "countryCode": "CN"
            },
            {
                "name": "Jiannan",
                "countryCode": "CN"
            },
            {
                "name": "Kangding",
                "countryCode": "CN"
            },
            {
                "name": "Langzhong",
                "countryCode": "CN"
            },
            {
                "name": "Leshan",
                "countryCode": "CN"
            },
            {
                "name": "Liangshan Yizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Linqiong",
                "countryCode": "CN"
            },
            {
                "name": "Luocheng",
                "countryCode": "CN"
            },
            {
                "name": "Luzhou",
                "countryCode": "CN"
            },
            {
                "name": "Meishan Shi",
                "countryCode": "CN"
            },
            {
                "name": "Mianyang",
                "countryCode": "CN"
            },
            {
                "name": "Nanchong",
                "countryCode": "CN"
            },
            {
                "name": "Nanlong",
                "countryCode": "CN"
            },
            {
                "name": "Neijiang",
                "countryCode": "CN"
            },
            {
                "name": "Panzhihua",
                "countryCode": "CN"
            },
            {
                "name": "Puji",
                "countryCode": "CN"
            },
            {
                "name": "Shuanghejiedao",
                "countryCode": "CN"
            },
            {
                "name": "Suining",
                "countryCode": "CN"
            },
            {
                "name": "Taihe",
                "countryCode": "CN"
            },
            {
                "name": "Taiping",
                "countryCode": "CN"
            },
            {
                "name": "Tianpeng",
                "countryCode": "CN"
            },
            {
                "name": "Tongchuan",
                "countryCode": "CN"
            },
            {
                "name": "Xialiang",
                "countryCode": "CN"
            },
            {
                "name": "Xiantan",
                "countryCode": "CN"
            },
            {
                "name": "Xichang",
                "countryCode": "CN"
            },
            {
                "name": "Xunchang",
                "countryCode": "CN"
            },
            {
                "name": "Yanjiang",
                "countryCode": "CN"
            },
            {
                "name": "Yibin",
                "countryCode": "CN"
            },
            {
                "name": "Yucheng",
                "countryCode": "CN"
            },
            {
                "name": "Zengjia",
                "countryCode": "CN"
            },
            {
                "name": "Zhongba",
                "countryCode": "CN"
            },
            {
                "name": "Zigong",
                "countryCode": "CN"
            },
            {
                "name": "Anqiu",
                "countryCode": "CN"
            },
            {
                "name": "Beizhai",
                "countryCode": "CN"
            },
            {
                "name": "Bianzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Binzhou",
                "countryCode": "CN"
            },
            {
                "name": "Boshan",
                "countryCode": "CN"
            },
            {
                "name": "Changqing",
                "countryCode": "CN"
            },
            {
                "name": "Chengqu",
                "countryCode": "CN"
            },
            {
                "name": "Chengtangcun",
                "countryCode": "CN"
            },
            {
                "name": "Chengyang",
                "countryCode": "CN"
            },
            {
                "name": "Dengying",
                "countryCode": "CN"
            },
            {
                "name": "Dengzhou",
                "countryCode": "CN"
            },
            {
                "name": "Dezhou",
                "countryCode": "CN"
            },
            {
                "name": "Dingtao",
                "countryCode": "CN"
            },
            {
                "name": "Dongcun",
                "countryCode": "CN"
            },
            {
                "name": "Dongdu",
                "countryCode": "CN"
            },
            {
                "name": "Feicheng",
                "countryCode": "CN"
            },
            {
                "name": "Gaomi",
                "countryCode": "CN"
            },
            {
                "name": "Hanting",
                "countryCode": "CN"
            },
            {
                "name": "Heze",
                "countryCode": "CN"
            },
            {
                "name": "Jiamaying",
                "countryCode": "CN"
            },
            {
                "name": "Jiaozhou",
                "countryCode": "CN"
            },
            {
                "name": "Jiehu",
                "countryCode": "CN"
            },
            {
                "name": "Jimo",
                "countryCode": "CN"
            },
            {
                "name": "Jinan",
                "countryCode": "CN"
            },
            {
                "name": "Jining",
                "countryCode": "CN"
            },
            {
                "name": "Juye",
                "countryCode": "CN"
            },
            {
                "name": "Kuiju",
                "countryCode": "CN"
            },
            {
                "name": "Kutao",
                "countryCode": "CN"
            },
            {
                "name": "Laiwu",
                "countryCode": "CN"
            },
            {
                "name": "Laixi",
                "countryCode": "CN"
            },
            {
                "name": "Laiyang",
                "countryCode": "CN"
            },
            {
                "name": "Laizhou",
                "countryCode": "CN"
            },
            {
                "name": "Laocheng",
                "countryCode": "CN"
            },
            {
                "name": "Liaocheng",
                "countryCode": "CN"
            },
            {
                "name": "Linqu",
                "countryCode": "CN"
            },
            {
                "name": "Linyi",
                "countryCode": "CN"
            },
            {
                "name": "Longgang",
                "countryCode": "CN"
            },
            {
                "name": "Mengyin",
                "countryCode": "CN"
            },
            {
                "name": "Mingshui",
                "countryCode": "CN"
            },
            {
                "name": "Mizhou",
                "countryCode": "CN"
            },
            {
                "name": "Nanding",
                "countryCode": "CN"
            },
            {
                "name": "Nanma",
                "countryCode": "CN"
            },
            {
                "name": "Ninghai",
                "countryCode": "CN"
            },
            {
                "name": "Ningyang",
                "countryCode": "CN"
            },
            {
                "name": "Pingdu",
                "countryCode": "CN"
            },
            {
                "name": "Pingyi",
                "countryCode": "CN"
            },
            {
                "name": "Pingyin",
                "countryCode": "CN"
            },
            {
                "name": "Qingdao",
                "countryCode": "CN"
            },
            {
                "name": "Qingnian",
                "countryCode": "CN"
            },
            {
                "name": "Qingshancun",
                "countryCode": "CN"
            },
            {
                "name": "Qingyang",
                "countryCode": "CN"
            },
            {
                "name": "Qingzhou",
                "countryCode": "CN"
            },
            {
                "name": "Qufu",
                "countryCode": "CN"
            },
            {
                "name": "Rizhao",
                "countryCode": "CN"
            },
            {
                "name": "Shancheng",
                "countryCode": "CN"
            },
            {
                "name": "Shanting",
                "countryCode": "CN"
            },
            {
                "name": "Shazikou",
                "countryCode": "CN"
            },
            {
                "name": "Shengli",
                "countryCode": "CN"
            },
            {
                "name": "Shilaorencun",
                "countryCode": "CN"
            },
            {
                "name": "Shizilu",
                "countryCode": "CN"
            },
            {
                "name": "Shouguang",
                "countryCode": "CN"
            },
            {
                "name": "Sishui",
                "countryCode": "CN"
            },
            {
                "name": "Suozhen",
                "countryCode": "CN"
            },
            {
                "name": "Tai’an",
                "countryCode": "CN"
            },
            {
                "name": "Taozhuang",
                "countryCode": "CN"
            },
            {
                "name": "Tianfu",
                "countryCode": "CN"
            },
            {
                "name": "Weifang",
                "countryCode": "CN"
            },
            {
                "name": "Weihai",
                "countryCode": "CN"
            },
            {
                "name": "Wenshang",
                "countryCode": "CN"
            },
            {
                "name": "Xiazhen",
                "countryCode": "CN"
            },
            {
                "name": "Xiazhuang",
                "countryCode": "CN"
            },
            {
                "name": "Xiliguantun",
                "countryCode": "CN"
            },
            {
                "name": "Xindian",
                "countryCode": "CN"
            },
            {
                "name": "Xintai",
                "countryCode": "CN"
            },
            {
                "name": "Yanggu",
                "countryCode": "CN"
            },
            {
                "name": "Yanta",
                "countryCode": "CN"
            },
            {
                "name": "Yantai",
                "countryCode": "CN"
            },
            {
                "name": "Yanzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yatou",
                "countryCode": "CN"
            },
            {
                "name": "Yinzhu",
                "countryCode": "CN"
            },
            {
                "name": "Yishui",
                "countryCode": "CN"
            },
            {
                "name": "Yucheng",
                "countryCode": "CN"
            },
            {
                "name": "Zaozhuang",
                "countryCode": "CN"
            },
            {
                "name": "Zhaoyuan",
                "countryCode": "CN"
            },
            {
                "name": "Zhoucheng",
                "countryCode": "CN"
            },
            {
                "name": "Zhoucun",
                "countryCode": "CN"
            },
            {
                "name": "Zhu Cheng City",
                "countryCode": "CN"
            },
            {
                "name": "Zhuangyuan",
                "countryCode": "CN"
            },
            {
                "name": "Zibo",
                "countryCode": "CN"
            },
            {
                "name": "Zoucheng",
                "countryCode": "CN"
            },
            {
                "name": "Shanghai",
                "countryCode": "CN"
            },
            {
                "name": "Songjiang",
                "countryCode": "CN"
            },
            {
                "name": "Zhabei",
                "countryCode": "CN"
            },
            {
                "name": "Zhujiajiao",
                "countryCode": "CN"
            },
            {
                "name": "Ankang",
                "countryCode": "CN"
            },
            {
                "name": "Baoji Shi",
                "countryCode": "CN"
            },
            {
                "name": "Guozhen",
                "countryCode": "CN"
            },
            {
                "name": "Hancheng",
                "countryCode": "CN"
            },
            {
                "name": "Hanzhong",
                "countryCode": "CN"
            },
            {
                "name": "Huayin",
                "countryCode": "CN"
            },
            {
                "name": "Lintong",
                "countryCode": "CN"
            },
            {
                "name": "Tongchuanshi",
                "countryCode": "CN"
            },
            {
                "name": "Weinan",
                "countryCode": "CN"
            },
            {
                "name": "Xianyang",
                "countryCode": "CN"
            },
            {
                "name": "Xi’an",
                "countryCode": "CN"
            },
            {
                "name": "Yanliang",
                "countryCode": "CN"
            },
            {
                "name": "Yulinshi",
                "countryCode": "CN"
            },
            {
                "name": "Yuxia",
                "countryCode": "CN"
            },
            {
                "name": "Changzhi",
                "countryCode": "CN"
            },
            {
                "name": "Datong",
                "countryCode": "CN"
            },
            {
                "name": "Datong Shi",
                "countryCode": "CN"
            },
            {
                "name": "Gutao",
                "countryCode": "CN"
            },
            {
                "name": "Jiexiu",
                "countryCode": "CN"
            },
            {
                "name": "Jincheng",
                "countryCode": "CN"
            },
            {
                "name": "Jinzhong Shi",
                "countryCode": "CN"
            },
            {
                "name": "Linfen",
                "countryCode": "CN"
            },
            {
                "name": "Lüliang",
                "countryCode": "CN"
            },
            {
                "name": "Shuozhou",
                "countryCode": "CN"
            },
            {
                "name": "Taiyuan",
                "countryCode": "CN"
            },
            {
                "name": "Xintian",
                "countryCode": "CN"
            },
            {
                "name": "Xinzhi",
                "countryCode": "CN"
            },
            {
                "name": "Xinzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yangquan",
                "countryCode": "CN"
            },
            {
                "name": "Yuanping",
                "countryCode": "CN"
            },
            {
                "name": "Yuci",
                "countryCode": "CN"
            },
            {
                "name": "Yuncheng",
                "countryCode": "CN"
            },
            {
                "name": "Badaogu",
                "countryCode": "CN"
            },
            {
                "name": "Baijian",
                "countryCode": "CN"
            },
            {
                "name": "Bamencheng",
                "countryCode": "CN"
            },
            {
                "name": "Bangjun",
                "countryCode": "CN"
            },
            {
                "name": "Beicang",
                "countryCode": "CN"
            },
            {
                "name": "Beihuaidian",
                "countryCode": "CN"
            },
            {
                "name": "Beilizigu",
                "countryCode": "CN"
            },
            {
                "name": "Biaokou",
                "countryCode": "CN"
            },
            {
                "name": "Binhai New Area",
                "countryCode": "CN"
            },
            {
                "name": "Caijiapu",
                "countryCode": "CN"
            },
            {
                "name": "Caodian",
                "countryCode": "CN"
            },
            {
                "name": "Chabaihu",
                "countryCode": "CN"
            },
            {
                "name": "Changtun",
                "countryCode": "CN"
            },
            {
                "name": "Chengtougu",
                "countryCode": "CN"
            },
            {
                "name": "Chitu",
                "countryCode": "CN"
            },
            {
                "name": "Cuijiamatou",
                "countryCode": "CN"
            },
            {
                "name": "Dadunqiu",
                "countryCode": "CN"
            },
            {
                "name": "Dakoutun",
                "countryCode": "CN"
            },
            {
                "name": "Dashentang",
                "countryCode": "CN"
            },
            {
                "name": "Dawangtai",
                "countryCode": "CN"
            },
            {
                "name": "Daxinzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Dazhongzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Dongditou",
                "countryCode": "CN"
            },
            {
                "name": "Dongshigu",
                "countryCode": "CN"
            },
            {
                "name": "Erwangzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Fanzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Fengtai (Ninghe)",
                "countryCode": "CN"
            },
            {
                "name": "Fuzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Gaojingzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Hanjiashu",
                "countryCode": "CN"
            },
            {
                "name": "Hebeitun",
                "countryCode": "CN"
            },
            {
                "name": "Hexiwu",
                "countryCode": "CN"
            },
            {
                "name": "Huangcaotuo",
                "countryCode": "CN"
            },
            {
                "name": "Huantuo",
                "countryCode": "CN"
            },
            {
                "name": "Huogezhuang",
                "countryCode": "CN"
            },
            {
                "name": "Jiangwakou",
                "countryCode": "CN"
            },
            {
                "name": "Lianzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Lintingkou",
                "countryCode": "CN"
            },
            {
                "name": "Liujiading",
                "countryCode": "CN"
            },
            {
                "name": "Liukuaizhuang",
                "countryCode": "CN"
            },
            {
                "name": "Liuzikou",
                "countryCode": "CN"
            },
            {
                "name": "Luotuofangzi",
                "countryCode": "CN"
            },
            {
                "name": "Meichang",
                "countryCode": "CN"
            },
            {
                "name": "Mengquan",
                "countryCode": "CN"
            },
            {
                "name": "Panzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Qingguang",
                "countryCode": "CN"
            },
            {
                "name": "Sangzi",
                "countryCode": "CN"
            },
            {
                "name": "Shangcang",
                "countryCode": "CN"
            },
            {
                "name": "Shimianzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Shuangjiang",
                "countryCode": "CN"
            },
            {
                "name": "Sigaozhuang",
                "countryCode": "CN"
            },
            {
                "name": "Tianjin",
                "countryCode": "CN"
            },
            {
                "name": "Touying",
                "countryCode": "CN"
            },
            {
                "name": "Wangqinzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Weiwangzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Xiawuqi",
                "countryCode": "CN"
            },
            {
                "name": "Xiditou",
                "countryCode": "CN"
            },
            {
                "name": "Xinkaikou",
                "countryCode": "CN"
            },
            {
                "name": "Xitazhuang",
                "countryCode": "CN"
            },
            {
                "name": "Yangjinzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Yangliuqing",
                "countryCode": "CN"
            },
            {
                "name": "Yinliu",
                "countryCode": "CN"
            },
            {
                "name": "Yixingfu",
                "countryCode": "CN"
            },
            {
                "name": "Youguzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Yuelongzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Yuguzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Zaojiacheng",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiawo",
                "countryCode": "CN"
            },
            {
                "name": "Zhaoguli",
                "countryCode": "CN"
            },
            {
                "name": "Baoying",
                "countryCode": "CN"
            },
            {
                "name": "Changshu City",
                "countryCode": "CN"
            },
            {
                "name": "Changzhou",
                "countryCode": "CN"
            },
            {
                "name": "Chengxiang",
                "countryCode": "CN"
            },
            {
                "name": "Dazhong",
                "countryCode": "CN"
            },
            {
                "name": "Dongkan",
                "countryCode": "CN"
            },
            {
                "name": "Dongtai",
                "countryCode": "CN"
            },
            {
                "name": "Fengxian",
                "countryCode": "CN"
            },
            {
                "name": "Gaogou",
                "countryCode": "CN"
            },
            {
                "name": "Gaoyou",
                "countryCode": "CN"
            },
            {
                "name": "Guiren",
                "countryCode": "CN"
            },
            {
                "name": "Haizhou",
                "countryCode": "CN"
            },
            {
                "name": "Hede",
                "countryCode": "CN"
            },
            {
                "name": "Huai'an",
                "countryCode": "CN"
            },
            {
                "name": "Huai’an Shi",
                "countryCode": "CN"
            },
            {
                "name": "Huilong",
                "countryCode": "CN"
            },
            {
                "name": "Hutang",
                "countryCode": "CN"
            },
            {
                "name": "Jiangyan",
                "countryCode": "CN"
            },
            {
                "name": "Jiangyin",
                "countryCode": "CN"
            },
            {
                "name": "Jingjiang",
                "countryCode": "CN"
            },
            {
                "name": "Jinsha",
                "countryCode": "CN"
            },
            {
                "name": "Juegang",
                "countryCode": "CN"
            },
            {
                "name": "Kunshan",
                "countryCode": "CN"
            },
            {
                "name": "Lianyungang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Licheng",
                "countryCode": "CN"
            },
            {
                "name": "Mudu",
                "countryCode": "CN"
            },
            {
                "name": "Nanjing",
                "countryCode": "CN"
            },
            {
                "name": "Nantong",
                "countryCode": "CN"
            },
            {
                "name": "Pizhou",
                "countryCode": "CN"
            },
            {
                "name": "Qinnan",
                "countryCode": "CN"
            },
            {
                "name": "Rucheng",
                "countryCode": "CN"
            },
            {
                "name": "Sanmao",
                "countryCode": "CN"
            },
            {
                "name": "Songling",
                "countryCode": "CN"
            },
            {
                "name": "Suicheng",
                "countryCode": "CN"
            },
            {
                "name": "Suzhou",
                "countryCode": "CN"
            },
            {
                "name": "Taixing",
                "countryCode": "CN"
            },
            {
                "name": "Taizhou",
                "countryCode": "CN"
            },
            {
                "name": "Tongshan",
                "countryCode": "CN"
            },
            {
                "name": "Wuxi",
                "countryCode": "CN"
            },
            {
                "name": "Xiannü",
                "countryCode": "CN"
            },
            {
                "name": "Xiaolingwei",
                "countryCode": "CN"
            },
            {
                "name": "Xinghua",
                "countryCode": "CN"
            },
            {
                "name": "Xinpu",
                "countryCode": "CN"
            },
            {
                "name": "Yancheng",
                "countryCode": "CN"
            },
            {
                "name": "Yangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Yicheng",
                "countryCode": "CN"
            },
            {
                "name": "Yushan",
                "countryCode": "CN"
            },
            {
                "name": "Zhangjiagang",
                "countryCode": "CN"
            },
            {
                "name": "Zhenjiang",
                "countryCode": "CN"
            },
            {
                "name": "Zhenzhou",
                "countryCode": "CN"
            },
            {
                "name": "Zhongxing",
                "countryCode": "CN"
            },
            {
                "name": "Zhouzhuang",
                "countryCode": "CN"
            },
            {
                "name": "Ailan Mubage",
                "countryCode": "CN"
            },
            {
                "name": "Aksu",
                "countryCode": "CN"
            },
            {
                "name": "Aksu Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Altay",
                "countryCode": "CN"
            },
            {
                "name": "Altay Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Aral",
                "countryCode": "CN"
            },
            {
                "name": "Aykol",
                "countryCode": "CN"
            },
            {
                "name": "Baijiantan",
                "countryCode": "CN"
            },
            {
                "name": "Baluntaicun",
                "countryCode": "CN"
            },
            {
                "name": "Bayingolin Mongol Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Changji",
                "countryCode": "CN"
            },
            {
                "name": "Changji Huizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Fukang",
                "countryCode": "CN"
            },
            {
                "name": "Hami",
                "countryCode": "CN"
            },
            {
                "name": "Hotan",
                "countryCode": "CN"
            },
            {
                "name": "Hoxtolgay",
                "countryCode": "CN"
            },
            {
                "name": "Huocheng",
                "countryCode": "CN"
            },
            {
                "name": "Ili Kazak Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Karamay",
                "countryCode": "CN"
            },
            {
                "name": "Karamay Shi",
                "countryCode": "CN"
            },
            {
                "name": "Kashgar",
                "countryCode": "CN"
            },
            {
                "name": "Kaxgar Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Korla",
                "countryCode": "CN"
            },
            {
                "name": "Kuqa",
                "countryCode": "CN"
            },
            {
                "name": "Kuytun",
                "countryCode": "CN"
            },
            {
                "name": "Qapqal",
                "countryCode": "CN"
            },
            {
                "name": "Shache",
                "countryCode": "CN"
            },
            {
                "name": "Shihezi",
                "countryCode": "CN"
            },
            {
                "name": "Sishilichengzi",
                "countryCode": "CN"
            },
            {
                "name": "Tacheng",
                "countryCode": "CN"
            },
            {
                "name": "Tacheng Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Turpan",
                "countryCode": "CN"
            },
            {
                "name": "Turpan Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Urumqi Shi",
                "countryCode": "CN"
            },
            {
                "name": "Xinyuan",
                "countryCode": "CN"
            },
            {
                "name": "Zangguy",
                "countryCode": "CN"
            },
            {
                "name": "Ürümqi",
                "countryCode": "CN"
            },
            {
                "name": "Burang",
                "countryCode": "CN"
            },
            {
                "name": "Dêqên",
                "countryCode": "CN"
            },
            {
                "name": "Jiangzi",
                "countryCode": "CN"
            },
            {
                "name": "Lhasa",
                "countryCode": "CN"
            },
            {
                "name": "Nagqu",
                "countryCode": "CN"
            },
            {
                "name": "Nagqu Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Ngari Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Nyingchi Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Qamdo",
                "countryCode": "CN"
            },
            {
                "name": "Qamdo Shi",
                "countryCode": "CN"
            },
            {
                "name": "Rikaze",
                "countryCode": "CN"
            },
            {
                "name": "Saga",
                "countryCode": "CN"
            },
            {
                "name": "Shannan Diqu",
                "countryCode": "CN"
            },
            {
                "name": "Chuxiong Yizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Dali",
                "countryCode": "CN"
            },
            {
                "name": "Dali Baizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Dehong Daizu Jingpozu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Dêqên Tibetan Autonomous Prefecture",
                "countryCode": "CN"
            },
            {
                "name": "Gejiu",
                "countryCode": "CN"
            },
            {
                "name": "Haikou",
                "countryCode": "CN"
            },
            {
                "name": "Honghe Hanizu Yizu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Jinghong",
                "countryCode": "CN"
            },
            {
                "name": "Kaihua",
                "countryCode": "CN"
            },
            {
                "name": "Kaiyuan",
                "countryCode": "CN"
            },
            {
                "name": "Kunming",
                "countryCode": "CN"
            },
            {
                "name": "Lianran",
                "countryCode": "CN"
            },
            {
                "name": "Lijiang",
                "countryCode": "CN"
            },
            {
                "name": "Lincang Shi",
                "countryCode": "CN"
            },
            {
                "name": "Longquan",
                "countryCode": "CN"
            },
            {
                "name": "Mabai",
                "countryCode": "CN"
            },
            {
                "name": "Majie",
                "countryCode": "CN"
            },
            {
                "name": "Miyang",
                "countryCode": "CN"
            },
            {
                "name": "Nujiang Lisuzu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Qujing",
                "countryCode": "CN"
            },
            {
                "name": "Shangri-La",
                "countryCode": "CN"
            },
            {
                "name": "Shilin",
                "countryCode": "CN"
            },
            {
                "name": "Wenlan",
                "countryCode": "CN"
            },
            {
                "name": "Wenshan City",
                "countryCode": "CN"
            },
            {
                "name": "Wenshan Zhuangzu Miaozu Zizhizhou",
                "countryCode": "CN"
            },
            {
                "name": "Yuxi",
                "countryCode": "CN"
            },
            {
                "name": "Zhaotong",
                "countryCode": "CN"
            },
            {
                "name": "Zhongshu",
                "countryCode": "CN"
            },
            {
                "name": "Deqing",
                "countryCode": "CN"
            },
            {
                "name": "Dongyang",
                "countryCode": "CN"
            },
            {
                "name": "Fenghua",
                "countryCode": "CN"
            },
            {
                "name": "Fuyang",
                "countryCode": "CN"
            },
            {
                "name": "Guli",
                "countryCode": "CN"
            },
            {
                "name": "Haining",
                "countryCode": "CN"
            },
            {
                "name": "Hangzhou",
                "countryCode": "CN"
            },
            {
                "name": "Huangyan",
                "countryCode": "CN"
            },
            {
                "name": "Huzhou",
                "countryCode": "CN"
            },
            {
                "name": "Jiaojiang",
                "countryCode": "CN"
            },
            {
                "name": "Jiashan",
                "countryCode": "CN"
            },
            {
                "name": "Jiaxing",
                "countryCode": "CN"
            },
            {
                "name": "Jiaxing Shi",
                "countryCode": "CN"
            },
            {
                "name": "Jinhua",
                "countryCode": "CN"
            },
            {
                "name": "Jinxiang",
                "countryCode": "CN"
            },
            {
                "name": "Kunyang",
                "countryCode": "CN"
            },
            {
                "name": "Lanxi",
                "countryCode": "CN"
            },
            {
                "name": "Lianghu",
                "countryCode": "CN"
            },
            {
                "name": "Linhai",
                "countryCode": "CN"
            },
            {
                "name": "Linping",
                "countryCode": "CN"
            },
            {
                "name": "Lishui",
                "countryCode": "CN"
            },
            {
                "name": "Luqiao",
                "countryCode": "CN"
            },
            {
                "name": "Ningbo",
                "countryCode": "CN"
            },
            {
                "name": "Ninghai",
                "countryCode": "CN"
            },
            {
                "name": "Puyang",
                "countryCode": "CN"
            },
            {
                "name": "Quzhou",
                "countryCode": "CN"
            },
            {
                "name": "Shangyu",
                "countryCode": "CN"
            },
            {
                "name": "Shaoxing",
                "countryCode": "CN"
            },
            {
                "name": "Shenjiamen",
                "countryCode": "CN"
            },
            {
                "name": "Taizhou",
                "countryCode": "CN"
            },
            {
                "name": "Wenling",
                "countryCode": "CN"
            },
            {
                "name": "Wenzhou",
                "countryCode": "CN"
            },
            {
                "name": "Wuzhen",
                "countryCode": "CN"
            },
            {
                "name": "Xianju",
                "countryCode": "CN"
            },
            {
                "name": "Xiaoshan",
                "countryCode": "CN"
            },
            {
                "name": "Yiwu",
                "countryCode": "CN"
            },
            {
                "name": "Yuyao",
                "countryCode": "CN"
            },
            {
                "name": "Zhaobaoshan",
                "countryCode": "CN"
            },
            {
                "name": "Zhicheng",
                "countryCode": "CN"
            },
            {
                "name": "Zhoushan",
                "countryCode": "CN"
            },
            {
                "name": "Zhuji",
                "countryCode": "CN"
            }
        ]
    },
    {
        "name": "Christmas Island",
        "phonecode": "61",
        "cities": []
    },
    {
        "name": "Cocos (Keeling) Islands",
        "phonecode": "61",
        "cities": []
    },
    {
        "name": "Colombia",
        "phonecode": "57",
        "cities": [
            {
                "name": "El Encanto",
                "countryCode": "CO"
            },
            {
                "name": "La Chorrera",
                "countryCode": "CO"
            },
            {
                "name": "La Pedrera",
                "countryCode": "CO"
            },
            {
                "name": "La Victoria",
                "countryCode": "CO"
            },
            {
                "name": "Leticia",
                "countryCode": "CO"
            },
            {
                "name": "Miriti - Paraná",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Alegría",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Arica",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Nariño",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Santander",
                "countryCode": "CO"
            },
            {
                "name": "Tarapacá",
                "countryCode": "CO"
            },
            {
                "name": "Abejorral",
                "countryCode": "CO"
            },
            {
                "name": "Abriaquí",
                "countryCode": "CO"
            },
            {
                "name": "Alejandría",
                "countryCode": "CO"
            },
            {
                "name": "Amagá",
                "countryCode": "CO"
            },
            {
                "name": "Amalfi",
                "countryCode": "CO"
            },
            {
                "name": "Andes",
                "countryCode": "CO"
            },
            {
                "name": "Angelópolis",
                "countryCode": "CO"
            },
            {
                "name": "Angostura",
                "countryCode": "CO"
            },
            {
                "name": "Anorí",
                "countryCode": "CO"
            },
            {
                "name": "Anza",
                "countryCode": "CO"
            },
            {
                "name": "Apartadó",
                "countryCode": "CO"
            },
            {
                "name": "Arboletes",
                "countryCode": "CO"
            },
            {
                "name": "Argelia",
                "countryCode": "CO"
            },
            {
                "name": "Armenia",
                "countryCode": "CO"
            },
            {
                "name": "Barbosa",
                "countryCode": "CO"
            },
            {
                "name": "Bello",
                "countryCode": "CO"
            },
            {
                "name": "Belmira",
                "countryCode": "CO"
            },
            {
                "name": "Betania",
                "countryCode": "CO"
            },
            {
                "name": "Betulia",
                "countryCode": "CO"
            },
            {
                "name": "Briceño",
                "countryCode": "CO"
            },
            {
                "name": "Buriticá",
                "countryCode": "CO"
            },
            {
                "name": "Caicedo",
                "countryCode": "CO"
            },
            {
                "name": "Caldas",
                "countryCode": "CO"
            },
            {
                "name": "Campamento",
                "countryCode": "CO"
            },
            {
                "name": "Caracolí",
                "countryCode": "CO"
            },
            {
                "name": "Caramanta",
                "countryCode": "CO"
            },
            {
                "name": "Carepa",
                "countryCode": "CO"
            },
            {
                "name": "Carolina",
                "countryCode": "CO"
            },
            {
                "name": "Caucasia",
                "countryCode": "CO"
            },
            {
                "name": "Cañasgordas",
                "countryCode": "CO"
            },
            {
                "name": "Chigorodó",
                "countryCode": "CO"
            },
            {
                "name": "Cisneros",
                "countryCode": "CO"
            },
            {
                "name": "Ciudad Bolívar",
                "countryCode": "CO"
            },
            {
                "name": "Cocorná",
                "countryCode": "CO"
            },
            {
                "name": "Concepción",
                "countryCode": "CO"
            },
            {
                "name": "Concordia",
                "countryCode": "CO"
            },
            {
                "name": "Copacabana",
                "countryCode": "CO"
            },
            {
                "name": "Cáceres",
                "countryCode": "CO"
            },
            {
                "name": "Dabeiba",
                "countryCode": "CO"
            },
            {
                "name": "Donmatías",
                "countryCode": "CO"
            },
            {
                "name": "Ebéjico",
                "countryCode": "CO"
            },
            {
                "name": "El Bagre",
                "countryCode": "CO"
            },
            {
                "name": "El Carmen de Viboral",
                "countryCode": "CO"
            },
            {
                "name": "El Santuario",
                "countryCode": "CO"
            },
            {
                "name": "Entrerrios",
                "countryCode": "CO"
            },
            {
                "name": "Envigado",
                "countryCode": "CO"
            },
            {
                "name": "Fredonia",
                "countryCode": "CO"
            },
            {
                "name": "Frontino",
                "countryCode": "CO"
            },
            {
                "name": "Giraldo",
                "countryCode": "CO"
            },
            {
                "name": "Girardota",
                "countryCode": "CO"
            },
            {
                "name": "Granada",
                "countryCode": "CO"
            },
            {
                "name": "Guadalupe",
                "countryCode": "CO"
            },
            {
                "name": "Guarne",
                "countryCode": "CO"
            },
            {
                "name": "Guatapé",
                "countryCode": "CO"
            },
            {
                "name": "Gómez Plata",
                "countryCode": "CO"
            },
            {
                "name": "Heliconia",
                "countryCode": "CO"
            },
            {
                "name": "Hispania",
                "countryCode": "CO"
            },
            {
                "name": "Itagui",
                "countryCode": "CO"
            },
            {
                "name": "Ituango",
                "countryCode": "CO"
            },
            {
                "name": "Jardín",
                "countryCode": "CO"
            },
            {
                "name": "Jericó",
                "countryCode": "CO"
            },
            {
                "name": "La Ceja",
                "countryCode": "CO"
            },
            {
                "name": "La Estrella",
                "countryCode": "CO"
            },
            {
                "name": "La Pintada",
                "countryCode": "CO"
            },
            {
                "name": "La Unión",
                "countryCode": "CO"
            },
            {
                "name": "Liborina",
                "countryCode": "CO"
            },
            {
                "name": "Maceo",
                "countryCode": "CO"
            },
            {
                "name": "Marinilla",
                "countryCode": "CO"
            },
            {
                "name": "Medellín",
                "countryCode": "CO"
            },
            {
                "name": "Montebello",
                "countryCode": "CO"
            },
            {
                "name": "Murindó",
                "countryCode": "CO"
            },
            {
                "name": "Mutatá",
                "countryCode": "CO"
            },
            {
                "name": "Nariño",
                "countryCode": "CO"
            },
            {
                "name": "Nechí",
                "countryCode": "CO"
            },
            {
                "name": "Necoclí",
                "countryCode": "CO"
            },
            {
                "name": "Olaya",
                "countryCode": "CO"
            },
            {
                "name": "Peque",
                "countryCode": "CO"
            },
            {
                "name": "Peñol",
                "countryCode": "CO"
            },
            {
                "name": "Pueblorrico",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Berrío",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Nare",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Triunfo",
                "countryCode": "CO"
            },
            {
                "name": "Remedios",
                "countryCode": "CO"
            },
            {
                "name": "Retiro",
                "countryCode": "CO"
            },
            {
                "name": "Rionegro",
                "countryCode": "CO"
            },
            {
                "name": "Sabanalarga",
                "countryCode": "CO"
            },
            {
                "name": "Sabaneta",
                "countryCode": "CO"
            },
            {
                "name": "Salgar",
                "countryCode": "CO"
            },
            {
                "name": "San Andrés de Cuerquía",
                "countryCode": "CO"
            },
            {
                "name": "San Carlos",
                "countryCode": "CO"
            },
            {
                "name": "San Francisco",
                "countryCode": "CO"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "CO"
            },
            {
                "name": "San José de la Montaña",
                "countryCode": "CO"
            },
            {
                "name": "San Juan de Urabá",
                "countryCode": "CO"
            },
            {
                "name": "San Luis",
                "countryCode": "CO"
            },
            {
                "name": "San Pedro de Uraba",
                "countryCode": "CO"
            },
            {
                "name": "San Pedro de los Milagros",
                "countryCode": "CO"
            },
            {
                "name": "San Rafael",
                "countryCode": "CO"
            },
            {
                "name": "San Roque",
                "countryCode": "CO"
            },
            {
                "name": "San Vicente Ferrer",
                "countryCode": "CO"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "CO"
            },
            {
                "name": "Santa Fe de Antioquia",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa de Osos",
                "countryCode": "CO"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "CO"
            },
            {
                "name": "Segovia",
                "countryCode": "CO"
            },
            {
                "name": "Sonson",
                "countryCode": "CO"
            },
            {
                "name": "Sopetrán",
                "countryCode": "CO"
            },
            {
                "name": "Tarazá",
                "countryCode": "CO"
            },
            {
                "name": "Tarso",
                "countryCode": "CO"
            },
            {
                "name": "Titiribí",
                "countryCode": "CO"
            },
            {
                "name": "Toledo",
                "countryCode": "CO"
            },
            {
                "name": "Turbo",
                "countryCode": "CO"
            },
            {
                "name": "Támesis",
                "countryCode": "CO"
            },
            {
                "name": "Uramita",
                "countryCode": "CO"
            },
            {
                "name": "Urrao",
                "countryCode": "CO"
            },
            {
                "name": "Valdivia",
                "countryCode": "CO"
            },
            {
                "name": "Valparaíso",
                "countryCode": "CO"
            },
            {
                "name": "Vegachí",
                "countryCode": "CO"
            },
            {
                "name": "Venecia",
                "countryCode": "CO"
            },
            {
                "name": "Vigía del Fuerte",
                "countryCode": "CO"
            },
            {
                "name": "Yalí",
                "countryCode": "CO"
            },
            {
                "name": "Yarumal",
                "countryCode": "CO"
            },
            {
                "name": "Yolombó",
                "countryCode": "CO"
            },
            {
                "name": "Yondó",
                "countryCode": "CO"
            },
            {
                "name": "Zaragoza",
                "countryCode": "CO"
            },
            {
                "name": "Arauca",
                "countryCode": "CO"
            },
            {
                "name": "Arauquita",
                "countryCode": "CO"
            },
            {
                "name": "Cravo Norte",
                "countryCode": "CO"
            },
            {
                "name": "Fortul",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Rondón",
                "countryCode": "CO"
            },
            {
                "name": "Saravena",
                "countryCode": "CO"
            },
            {
                "name": "Tame",
                "countryCode": "CO"
            },
            {
                "name": "Baranoa",
                "countryCode": "CO"
            },
            {
                "name": "Barranquilla",
                "countryCode": "CO"
            },
            {
                "name": "Campo de la Cruz",
                "countryCode": "CO"
            },
            {
                "name": "Candelaria",
                "countryCode": "CO"
            },
            {
                "name": "Galapa",
                "countryCode": "CO"
            },
            {
                "name": "Juan de Acosta",
                "countryCode": "CO"
            },
            {
                "name": "Luruaco",
                "countryCode": "CO"
            },
            {
                "name": "Malambo",
                "countryCode": "CO"
            },
            {
                "name": "Manatí",
                "countryCode": "CO"
            },
            {
                "name": "Palmar de Varela",
                "countryCode": "CO"
            },
            {
                "name": "Piojó",
                "countryCode": "CO"
            },
            {
                "name": "Polonuevo",
                "countryCode": "CO"
            },
            {
                "name": "Ponedera",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Colombia",
                "countryCode": "CO"
            },
            {
                "name": "Repelón",
                "countryCode": "CO"
            },
            {
                "name": "Sabanagrande",
                "countryCode": "CO"
            },
            {
                "name": "Sabanalarga",
                "countryCode": "CO"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "CO"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "CO"
            },
            {
                "name": "Soledad",
                "countryCode": "CO"
            },
            {
                "name": "Suan",
                "countryCode": "CO"
            },
            {
                "name": "Tubará",
                "countryCode": "CO"
            },
            {
                "name": "Usiacurí",
                "countryCode": "CO"
            },
            {
                "name": "Achí",
                "countryCode": "CO"
            },
            {
                "name": "Altos del Rosario",
                "countryCode": "CO"
            },
            {
                "name": "Arenal",
                "countryCode": "CO"
            },
            {
                "name": "Arjona",
                "countryCode": "CO"
            },
            {
                "name": "Arroyohondo",
                "countryCode": "CO"
            },
            {
                "name": "Barranco de Loba",
                "countryCode": "CO"
            },
            {
                "name": "Calamar",
                "countryCode": "CO"
            },
            {
                "name": "Cantagallo",
                "countryCode": "CO"
            },
            {
                "name": "Cartagena de Indias",
                "countryCode": "CO"
            },
            {
                "name": "Cicuco",
                "countryCode": "CO"
            },
            {
                "name": "Clemencia",
                "countryCode": "CO"
            },
            {
                "name": "Córdoba",
                "countryCode": "CO"
            },
            {
                "name": "El Carmen de Bolívar",
                "countryCode": "CO"
            },
            {
                "name": "El Guamo",
                "countryCode": "CO"
            },
            {
                "name": "El Peñón",
                "countryCode": "CO"
            },
            {
                "name": "Hatillo de Loba",
                "countryCode": "CO"
            },
            {
                "name": "Magangué",
                "countryCode": "CO"
            },
            {
                "name": "Mahates",
                "countryCode": "CO"
            },
            {
                "name": "Margarita",
                "countryCode": "CO"
            },
            {
                "name": "María la Baja",
                "countryCode": "CO"
            },
            {
                "name": "Mompós",
                "countryCode": "CO"
            },
            {
                "name": "Montecristo",
                "countryCode": "CO"
            },
            {
                "name": "Morales",
                "countryCode": "CO"
            },
            {
                "name": "Norosí",
                "countryCode": "CO"
            },
            {
                "name": "Pinillos",
                "countryCode": "CO"
            },
            {
                "name": "Regidor",
                "countryCode": "CO"
            },
            {
                "name": "Río Viejo",
                "countryCode": "CO"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "CO"
            },
            {
                "name": "San Estanislao",
                "countryCode": "CO"
            },
            {
                "name": "San Fernando",
                "countryCode": "CO"
            },
            {
                "name": "San Jacinto",
                "countryCode": "CO"
            },
            {
                "name": "San Jacinto del Cauca",
                "countryCode": "CO"
            },
            {
                "name": "San Juan Nepomuceno",
                "countryCode": "CO"
            },
            {
                "name": "San Martín de Loba",
                "countryCode": "CO"
            },
            {
                "name": "San Pablo",
                "countryCode": "CO"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa del Sur",
                "countryCode": "CO"
            },
            {
                "name": "Simití",
                "countryCode": "CO"
            },
            {
                "name": "Soplaviento",
                "countryCode": "CO"
            },
            {
                "name": "Talaigua Nuevo",
                "countryCode": "CO"
            },
            {
                "name": "Tiquisio",
                "countryCode": "CO"
            },
            {
                "name": "Turbaco",
                "countryCode": "CO"
            },
            {
                "name": "Turbaná",
                "countryCode": "CO"
            },
            {
                "name": "Villanueva",
                "countryCode": "CO"
            },
            {
                "name": "Zambrano",
                "countryCode": "CO"
            },
            {
                "name": "Almeida",
                "countryCode": "CO"
            },
            {
                "name": "Aquitania",
                "countryCode": "CO"
            },
            {
                "name": "Arcabuco",
                "countryCode": "CO"
            },
            {
                "name": "Belén",
                "countryCode": "CO"
            },
            {
                "name": "Berbeo",
                "countryCode": "CO"
            },
            {
                "name": "Betéitiva",
                "countryCode": "CO"
            },
            {
                "name": "Boavita",
                "countryCode": "CO"
            },
            {
                "name": "Boyacá",
                "countryCode": "CO"
            },
            {
                "name": "Briceño",
                "countryCode": "CO"
            },
            {
                "name": "Buenavista",
                "countryCode": "CO"
            },
            {
                "name": "Busbanzá",
                "countryCode": "CO"
            },
            {
                "name": "Caldas",
                "countryCode": "CO"
            },
            {
                "name": "Campohermoso",
                "countryCode": "CO"
            },
            {
                "name": "Cerinza",
                "countryCode": "CO"
            },
            {
                "name": "Chinavita",
                "countryCode": "CO"
            },
            {
                "name": "Chiquinquirá",
                "countryCode": "CO"
            },
            {
                "name": "Chiscas",
                "countryCode": "CO"
            },
            {
                "name": "Chita",
                "countryCode": "CO"
            },
            {
                "name": "Chitaraque",
                "countryCode": "CO"
            },
            {
                "name": "Chivatá",
                "countryCode": "CO"
            },
            {
                "name": "Chivor",
                "countryCode": "CO"
            },
            {
                "name": "Chíquiza",
                "countryCode": "CO"
            },
            {
                "name": "Ciénega",
                "countryCode": "CO"
            },
            {
                "name": "Combita",
                "countryCode": "CO"
            },
            {
                "name": "Coper",
                "countryCode": "CO"
            },
            {
                "name": "Corrales",
                "countryCode": "CO"
            },
            {
                "name": "Covarachía",
                "countryCode": "CO"
            },
            {
                "name": "Cubará",
                "countryCode": "CO"
            },
            {
                "name": "Cucaita",
                "countryCode": "CO"
            },
            {
                "name": "Cuítiva",
                "countryCode": "CO"
            },
            {
                "name": "Duitama",
                "countryCode": "CO"
            },
            {
                "name": "El Cocuy",
                "countryCode": "CO"
            },
            {
                "name": "El Espino",
                "countryCode": "CO"
            },
            {
                "name": "Firavitoba",
                "countryCode": "CO"
            },
            {
                "name": "Floresta",
                "countryCode": "CO"
            },
            {
                "name": "Gachantivá",
                "countryCode": "CO"
            },
            {
                "name": "Gameza",
                "countryCode": "CO"
            },
            {
                "name": "Garagoa",
                "countryCode": "CO"
            },
            {
                "name": "Guacamayas",
                "countryCode": "CO"
            },
            {
                "name": "Guateque",
                "countryCode": "CO"
            },
            {
                "name": "Guayatá",
                "countryCode": "CO"
            },
            {
                "name": "Güicán",
                "countryCode": "CO"
            },
            {
                "name": "Iza",
                "countryCode": "CO"
            },
            {
                "name": "Jenesano",
                "countryCode": "CO"
            },
            {
                "name": "Jericó",
                "countryCode": "CO"
            },
            {
                "name": "La Capilla",
                "countryCode": "CO"
            },
            {
                "name": "La Uvita",
                "countryCode": "CO"
            },
            {
                "name": "La Victoria",
                "countryCode": "CO"
            },
            {
                "name": "Labranzagrande",
                "countryCode": "CO"
            },
            {
                "name": "Macanal",
                "countryCode": "CO"
            },
            {
                "name": "Maripí",
                "countryCode": "CO"
            },
            {
                "name": "Miraflores",
                "countryCode": "CO"
            },
            {
                "name": "Mongua",
                "countryCode": "CO"
            },
            {
                "name": "Monguí",
                "countryCode": "CO"
            },
            {
                "name": "Moniquirá",
                "countryCode": "CO"
            },
            {
                "name": "Motavita",
                "countryCode": "CO"
            },
            {
                "name": "Muzo",
                "countryCode": "CO"
            },
            {
                "name": "Nobsa",
                "countryCode": "CO"
            },
            {
                "name": "Nuevo Colón",
                "countryCode": "CO"
            },
            {
                "name": "Oicatá",
                "countryCode": "CO"
            },
            {
                "name": "Otanche",
                "countryCode": "CO"
            },
            {
                "name": "Pachavita",
                "countryCode": "CO"
            },
            {
                "name": "Paipa",
                "countryCode": "CO"
            },
            {
                "name": "Pajarito",
                "countryCode": "CO"
            },
            {
                "name": "Panqueba",
                "countryCode": "CO"
            },
            {
                "name": "Pauna",
                "countryCode": "CO"
            },
            {
                "name": "Paya",
                "countryCode": "CO"
            },
            {
                "name": "Paz de Río",
                "countryCode": "CO"
            },
            {
                "name": "Pesca",
                "countryCode": "CO"
            },
            {
                "name": "Pisba",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Boyacá",
                "countryCode": "CO"
            },
            {
                "name": "Páez",
                "countryCode": "CO"
            },
            {
                "name": "Quípama",
                "countryCode": "CO"
            },
            {
                "name": "Ramiriquí",
                "countryCode": "CO"
            },
            {
                "name": "Rondón",
                "countryCode": "CO"
            },
            {
                "name": "Ráquira",
                "countryCode": "CO"
            },
            {
                "name": "Saboyá",
                "countryCode": "CO"
            },
            {
                "name": "Samacá",
                "countryCode": "CO"
            },
            {
                "name": "San Eduardo",
                "countryCode": "CO"
            },
            {
                "name": "San José de Pare",
                "countryCode": "CO"
            },
            {
                "name": "San Luis de Gaceno",
                "countryCode": "CO"
            },
            {
                "name": "San Mateo",
                "countryCode": "CO"
            },
            {
                "name": "San Miguel de Sema",
                "countryCode": "CO"
            },
            {
                "name": "San Pablo de Borbur",
                "countryCode": "CO"
            },
            {
                "name": "Santa María",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa de Viterbo",
                "countryCode": "CO"
            },
            {
                "name": "Santa Sofía",
                "countryCode": "CO"
            },
            {
                "name": "Santana",
                "countryCode": "CO"
            },
            {
                "name": "Sativanorte",
                "countryCode": "CO"
            },
            {
                "name": "Sativasur",
                "countryCode": "CO"
            },
            {
                "name": "Siachoque",
                "countryCode": "CO"
            },
            {
                "name": "Soatá",
                "countryCode": "CO"
            },
            {
                "name": "Socha",
                "countryCode": "CO"
            },
            {
                "name": "Socotá",
                "countryCode": "CO"
            },
            {
                "name": "Sogamoso",
                "countryCode": "CO"
            },
            {
                "name": "Somondoco",
                "countryCode": "CO"
            },
            {
                "name": "Sora",
                "countryCode": "CO"
            },
            {
                "name": "Soracá",
                "countryCode": "CO"
            },
            {
                "name": "Sotaquirá",
                "countryCode": "CO"
            },
            {
                "name": "Susacón",
                "countryCode": "CO"
            },
            {
                "name": "Sutamarchán",
                "countryCode": "CO"
            },
            {
                "name": "Sutatenza",
                "countryCode": "CO"
            },
            {
                "name": "Sáchica",
                "countryCode": "CO"
            },
            {
                "name": "Tasco",
                "countryCode": "CO"
            },
            {
                "name": "Tenza",
                "countryCode": "CO"
            },
            {
                "name": "Tibaná",
                "countryCode": "CO"
            },
            {
                "name": "Tibasosa",
                "countryCode": "CO"
            },
            {
                "name": "Tinjacá",
                "countryCode": "CO"
            },
            {
                "name": "Tipacoque",
                "countryCode": "CO"
            },
            {
                "name": "Toca",
                "countryCode": "CO"
            },
            {
                "name": "Togüí",
                "countryCode": "CO"
            },
            {
                "name": "Tota",
                "countryCode": "CO"
            },
            {
                "name": "Tunja",
                "countryCode": "CO"
            },
            {
                "name": "Tununguá",
                "countryCode": "CO"
            },
            {
                "name": "Turmequé",
                "countryCode": "CO"
            },
            {
                "name": "Tuta",
                "countryCode": "CO"
            },
            {
                "name": "Tutazá",
                "countryCode": "CO"
            },
            {
                "name": "Tópaga",
                "countryCode": "CO"
            },
            {
                "name": "Umbita",
                "countryCode": "CO"
            },
            {
                "name": "Ventaquemada",
                "countryCode": "CO"
            },
            {
                "name": "Villa de Leyva",
                "countryCode": "CO"
            },
            {
                "name": "Viracachá",
                "countryCode": "CO"
            },
            {
                "name": "Zetaquira",
                "countryCode": "CO"
            },
            {
                "name": "Aguadas",
                "countryCode": "CO"
            },
            {
                "name": "Anserma",
                "countryCode": "CO"
            },
            {
                "name": "Aranzazu",
                "countryCode": "CO"
            },
            {
                "name": "Belalcázar",
                "countryCode": "CO"
            },
            {
                "name": "Chinchiná",
                "countryCode": "CO"
            },
            {
                "name": "Filadelfia",
                "countryCode": "CO"
            },
            {
                "name": "La Dorada",
                "countryCode": "CO"
            },
            {
                "name": "La Merced",
                "countryCode": "CO"
            },
            {
                "name": "Manizales",
                "countryCode": "CO"
            },
            {
                "name": "Manzanares",
                "countryCode": "CO"
            },
            {
                "name": "Marmato",
                "countryCode": "CO"
            },
            {
                "name": "Marquetalia",
                "countryCode": "CO"
            },
            {
                "name": "Marulanda",
                "countryCode": "CO"
            },
            {
                "name": "Neira",
                "countryCode": "CO"
            },
            {
                "name": "Norcasia",
                "countryCode": "CO"
            },
            {
                "name": "Palestina",
                "countryCode": "CO"
            },
            {
                "name": "Pensilvania",
                "countryCode": "CO"
            },
            {
                "name": "Pácora",
                "countryCode": "CO"
            },
            {
                "name": "Riosucio",
                "countryCode": "CO"
            },
            {
                "name": "Risaralda",
                "countryCode": "CO"
            },
            {
                "name": "Salamina",
                "countryCode": "CO"
            },
            {
                "name": "Samaná",
                "countryCode": "CO"
            },
            {
                "name": "San José",
                "countryCode": "CO"
            },
            {
                "name": "Supía",
                "countryCode": "CO"
            },
            {
                "name": "Victoria",
                "countryCode": "CO"
            },
            {
                "name": "Villamaría",
                "countryCode": "CO"
            },
            {
                "name": "Viterbo",
                "countryCode": "CO"
            },
            {
                "name": "Albania",
                "countryCode": "CO"
            },
            {
                "name": "Belén de Los Andaquies",
                "countryCode": "CO"
            },
            {
                "name": "Cartagena del Chairá",
                "countryCode": "CO"
            },
            {
                "name": "Curillo",
                "countryCode": "CO"
            },
            {
                "name": "El Doncello",
                "countryCode": "CO"
            },
            {
                "name": "El Paujil",
                "countryCode": "CO"
            },
            {
                "name": "Florencia",
                "countryCode": "CO"
            },
            {
                "name": "La Montañita",
                "countryCode": "CO"
            },
            {
                "name": "Milán",
                "countryCode": "CO"
            },
            {
                "name": "Morelia",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Rico",
                "countryCode": "CO"
            },
            {
                "name": "San José del Fragua",
                "countryCode": "CO"
            },
            {
                "name": "San Vicente del Caguán",
                "countryCode": "CO"
            },
            {
                "name": "Solano",
                "countryCode": "CO"
            },
            {
                "name": "Solita",
                "countryCode": "CO"
            },
            {
                "name": "Valparaíso",
                "countryCode": "CO"
            },
            {
                "name": "Aguazul",
                "countryCode": "CO"
            },
            {
                "name": "Chameza",
                "countryCode": "CO"
            },
            {
                "name": "Hato Corozal",
                "countryCode": "CO"
            },
            {
                "name": "La Salina",
                "countryCode": "CO"
            },
            {
                "name": "Maní",
                "countryCode": "CO"
            },
            {
                "name": "Monterrey",
                "countryCode": "CO"
            },
            {
                "name": "Nunchía",
                "countryCode": "CO"
            },
            {
                "name": "Orocué",
                "countryCode": "CO"
            },
            {
                "name": "Paz de Ariporo",
                "countryCode": "CO"
            },
            {
                "name": "Pore",
                "countryCode": "CO"
            },
            {
                "name": "Recetor",
                "countryCode": "CO"
            },
            {
                "name": "Sabanalarga",
                "countryCode": "CO"
            },
            {
                "name": "San Luis de Palenque",
                "countryCode": "CO"
            },
            {
                "name": "Sácama",
                "countryCode": "CO"
            },
            {
                "name": "Tauramena",
                "countryCode": "CO"
            },
            {
                "name": "Trinidad",
                "countryCode": "CO"
            },
            {
                "name": "Támara",
                "countryCode": "CO"
            },
            {
                "name": "Villanueva",
                "countryCode": "CO"
            },
            {
                "name": "Yopal",
                "countryCode": "CO"
            },
            {
                "name": "Almaguer",
                "countryCode": "CO"
            },
            {
                "name": "Argelia",
                "countryCode": "CO"
            },
            {
                "name": "Balboa",
                "countryCode": "CO"
            },
            {
                "name": "Bolívar",
                "countryCode": "CO"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "CO"
            },
            {
                "name": "Cajibío",
                "countryCode": "CO"
            },
            {
                "name": "Caldono",
                "countryCode": "CO"
            },
            {
                "name": "Caloto",
                "countryCode": "CO"
            },
            {
                "name": "Corinto",
                "countryCode": "CO"
            },
            {
                "name": "El Tambo",
                "countryCode": "CO"
            },
            {
                "name": "Florencia",
                "countryCode": "CO"
            },
            {
                "name": "Guachené",
                "countryCode": "CO"
            },
            {
                "name": "Guapi",
                "countryCode": "CO"
            },
            {
                "name": "Inzá",
                "countryCode": "CO"
            },
            {
                "name": "Jambaló",
                "countryCode": "CO"
            },
            {
                "name": "La Sierra",
                "countryCode": "CO"
            },
            {
                "name": "La Vega",
                "countryCode": "CO"
            },
            {
                "name": "López de Micay",
                "countryCode": "CO"
            },
            {
                "name": "Mercaderes",
                "countryCode": "CO"
            },
            {
                "name": "Miranda",
                "countryCode": "CO"
            },
            {
                "name": "Morales",
                "countryCode": "CO"
            },
            {
                "name": "Padilla",
                "countryCode": "CO"
            },
            {
                "name": "Patía",
                "countryCode": "CO"
            },
            {
                "name": "Piamonte",
                "countryCode": "CO"
            },
            {
                "name": "Piendamo",
                "countryCode": "CO"
            },
            {
                "name": "Popayán",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Tejada",
                "countryCode": "CO"
            },
            {
                "name": "Puracé",
                "countryCode": "CO"
            },
            {
                "name": "Páez",
                "countryCode": "CO"
            },
            {
                "name": "Rosas",
                "countryCode": "CO"
            },
            {
                "name": "San Sebastián",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "CO"
            },
            {
                "name": "Santander de Quilichao",
                "countryCode": "CO"
            },
            {
                "name": "Silvia",
                "countryCode": "CO"
            },
            {
                "name": "Sotará",
                "countryCode": "CO"
            },
            {
                "name": "Sucre",
                "countryCode": "CO"
            },
            {
                "name": "Suárez",
                "countryCode": "CO"
            },
            {
                "name": "Timbiquí",
                "countryCode": "CO"
            },
            {
                "name": "Timbío",
                "countryCode": "CO"
            },
            {
                "name": "Toribio",
                "countryCode": "CO"
            },
            {
                "name": "Totoró",
                "countryCode": "CO"
            },
            {
                "name": "Villa Rica",
                "countryCode": "CO"
            },
            {
                "name": "Aguachica",
                "countryCode": "CO"
            },
            {
                "name": "Agustín Codazzi",
                "countryCode": "CO"
            },
            {
                "name": "Astrea",
                "countryCode": "CO"
            },
            {
                "name": "Becerril",
                "countryCode": "CO"
            },
            {
                "name": "Bosconia",
                "countryCode": "CO"
            },
            {
                "name": "Chimichagua",
                "countryCode": "CO"
            },
            {
                "name": "Chiriguaná",
                "countryCode": "CO"
            },
            {
                "name": "Curumaní",
                "countryCode": "CO"
            },
            {
                "name": "El Copey",
                "countryCode": "CO"
            },
            {
                "name": "El Paso",
                "countryCode": "CO"
            },
            {
                "name": "Gamarra",
                "countryCode": "CO"
            },
            {
                "name": "González",
                "countryCode": "CO"
            },
            {
                "name": "La Gloria",
                "countryCode": "CO"
            },
            {
                "name": "La Jagua de Ibirico",
                "countryCode": "CO"
            },
            {
                "name": "La Paz",
                "countryCode": "CO"
            },
            {
                "name": "Manaure Balcón del Cesar",
                "countryCode": "CO"
            },
            {
                "name": "Pailitas",
                "countryCode": "CO"
            },
            {
                "name": "Pelaya",
                "countryCode": "CO"
            },
            {
                "name": "Pueblo Bello",
                "countryCode": "CO"
            },
            {
                "name": "Río de Oro",
                "countryCode": "CO"
            },
            {
                "name": "San Alberto",
                "countryCode": "CO"
            },
            {
                "name": "San Diego",
                "countryCode": "CO"
            },
            {
                "name": "San Martín",
                "countryCode": "CO"
            },
            {
                "name": "Tamalameque",
                "countryCode": "CO"
            },
            {
                "name": "Valledupar",
                "countryCode": "CO"
            },
            {
                "name": "Acandí",
                "countryCode": "CO"
            },
            {
                "name": "Alto Baudó",
                "countryCode": "CO"
            },
            {
                "name": "Atrato",
                "countryCode": "CO"
            },
            {
                "name": "Bagadó",
                "countryCode": "CO"
            },
            {
                "name": "Bahía Solano",
                "countryCode": "CO"
            },
            {
                "name": "Bajo Baudó",
                "countryCode": "CO"
            },
            {
                "name": "Bojayá",
                "countryCode": "CO"
            },
            {
                "name": "Carmen del Darien",
                "countryCode": "CO"
            },
            {
                "name": "Condoto",
                "countryCode": "CO"
            },
            {
                "name": "Cértegui",
                "countryCode": "CO"
            },
            {
                "name": "El Cantón de San Pablo",
                "countryCode": "CO"
            },
            {
                "name": "El Carmen de Atrato",
                "countryCode": "CO"
            },
            {
                "name": "Istmina",
                "countryCode": "CO"
            },
            {
                "name": "Juradó",
                "countryCode": "CO"
            },
            {
                "name": "Litoral del San Juan",
                "countryCode": "CO"
            },
            {
                "name": "Lloró",
                "countryCode": "CO"
            },
            {
                "name": "Medio Atrato",
                "countryCode": "CO"
            },
            {
                "name": "Medio Baudó",
                "countryCode": "CO"
            },
            {
                "name": "Medio San Juan",
                "countryCode": "CO"
            },
            {
                "name": "Nuquí",
                "countryCode": "CO"
            },
            {
                "name": "Nóvita",
                "countryCode": "CO"
            },
            {
                "name": "Quibdó",
                "countryCode": "CO"
            },
            {
                "name": "Riosucio",
                "countryCode": "CO"
            },
            {
                "name": "Río Iro",
                "countryCode": "CO"
            },
            {
                "name": "Río Quito",
                "countryCode": "CO"
            },
            {
                "name": "San José del Palmar",
                "countryCode": "CO"
            },
            {
                "name": "Sipí",
                "countryCode": "CO"
            },
            {
                "name": "Tadó",
                "countryCode": "CO"
            },
            {
                "name": "Unguía",
                "countryCode": "CO"
            },
            {
                "name": "Unión Panamericana",
                "countryCode": "CO"
            },
            {
                "name": "Ayapel",
                "countryCode": "CO"
            },
            {
                "name": "Buenavista",
                "countryCode": "CO"
            },
            {
                "name": "Canalete",
                "countryCode": "CO"
            },
            {
                "name": "Cereté",
                "countryCode": "CO"
            },
            {
                "name": "Chimá",
                "countryCode": "CO"
            },
            {
                "name": "Chinú",
                "countryCode": "CO"
            },
            {
                "name": "Ciénaga de Oro",
                "countryCode": "CO"
            },
            {
                "name": "Cotorra",
                "countryCode": "CO"
            },
            {
                "name": "La Apartada",
                "countryCode": "CO"
            },
            {
                "name": "Lorica",
                "countryCode": "CO"
            },
            {
                "name": "Los Córdobas",
                "countryCode": "CO"
            },
            {
                "name": "Momil",
                "countryCode": "CO"
            },
            {
                "name": "Montelíbano",
                "countryCode": "CO"
            },
            {
                "name": "Montería",
                "countryCode": "CO"
            },
            {
                "name": "Moñitos",
                "countryCode": "CO"
            },
            {
                "name": "Planeta Rica",
                "countryCode": "CO"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Escondido",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Libertador",
                "countryCode": "CO"
            },
            {
                "name": "Purísima",
                "countryCode": "CO"
            },
            {
                "name": "Sahagún",
                "countryCode": "CO"
            },
            {
                "name": "San Andrés de Sotavento",
                "countryCode": "CO"
            },
            {
                "name": "San Antero",
                "countryCode": "CO"
            },
            {
                "name": "San Bernardo del Viento",
                "countryCode": "CO"
            },
            {
                "name": "San Carlos",
                "countryCode": "CO"
            },
            {
                "name": "San José de Uré",
                "countryCode": "CO"
            },
            {
                "name": "San Pelayo",
                "countryCode": "CO"
            },
            {
                "name": "Tierralta",
                "countryCode": "CO"
            },
            {
                "name": "Tuchín",
                "countryCode": "CO"
            },
            {
                "name": "Valencia",
                "countryCode": "CO"
            },
            {
                "name": "Agua de Dios",
                "countryCode": "CO"
            },
            {
                "name": "Albán",
                "countryCode": "CO"
            },
            {
                "name": "Anapoima",
                "countryCode": "CO"
            },
            {
                "name": "Anolaima",
                "countryCode": "CO"
            },
            {
                "name": "Apulo",
                "countryCode": "CO"
            },
            {
                "name": "Arbeláez",
                "countryCode": "CO"
            },
            {
                "name": "Beltrán",
                "countryCode": "CO"
            },
            {
                "name": "Bituima",
                "countryCode": "CO"
            },
            {
                "name": "Bogotá D.C.",
                "countryCode": "CO"
            },
            {
                "name": "Bojacá",
                "countryCode": "CO"
            },
            {
                "name": "Cabrera",
                "countryCode": "CO"
            },
            {
                "name": "Cachipay",
                "countryCode": "CO"
            },
            {
                "name": "Cajicá",
                "countryCode": "CO"
            },
            {
                "name": "Caparrapí",
                "countryCode": "CO"
            },
            {
                "name": "Caqueza",
                "countryCode": "CO"
            },
            {
                "name": "Carmen de Carupa",
                "countryCode": "CO"
            },
            {
                "name": "Chaguaní",
                "countryCode": "CO"
            },
            {
                "name": "Chipaque",
                "countryCode": "CO"
            },
            {
                "name": "Choachí",
                "countryCode": "CO"
            },
            {
                "name": "Chocontá",
                "countryCode": "CO"
            },
            {
                "name": "Chía",
                "countryCode": "CO"
            },
            {
                "name": "Cogua",
                "countryCode": "CO"
            },
            {
                "name": "Cota",
                "countryCode": "CO"
            },
            {
                "name": "Cucunubá",
                "countryCode": "CO"
            },
            {
                "name": "El Colegio",
                "countryCode": "CO"
            },
            {
                "name": "El Peñón",
                "countryCode": "CO"
            },
            {
                "name": "El Rosal",
                "countryCode": "CO"
            },
            {
                "name": "Facatativá",
                "countryCode": "CO"
            },
            {
                "name": "Fomeque",
                "countryCode": "CO"
            },
            {
                "name": "Fosca",
                "countryCode": "CO"
            },
            {
                "name": "Funza",
                "countryCode": "CO"
            },
            {
                "name": "Fusagasugá",
                "countryCode": "CO"
            },
            {
                "name": "Fúquene",
                "countryCode": "CO"
            },
            {
                "name": "Gachala",
                "countryCode": "CO"
            },
            {
                "name": "Gachancipá",
                "countryCode": "CO"
            },
            {
                "name": "Gachetá",
                "countryCode": "CO"
            },
            {
                "name": "Gama",
                "countryCode": "CO"
            },
            {
                "name": "Girardot",
                "countryCode": "CO"
            },
            {
                "name": "Granada",
                "countryCode": "CO"
            },
            {
                "name": "Guachetá",
                "countryCode": "CO"
            },
            {
                "name": "Guaduas",
                "countryCode": "CO"
            },
            {
                "name": "Guasca",
                "countryCode": "CO"
            },
            {
                "name": "Guataquí",
                "countryCode": "CO"
            },
            {
                "name": "Guatavita",
                "countryCode": "CO"
            },
            {
                "name": "Guayabal de Siquima",
                "countryCode": "CO"
            },
            {
                "name": "Guayabetal",
                "countryCode": "CO"
            },
            {
                "name": "Gutiérrez",
                "countryCode": "CO"
            },
            {
                "name": "Jerusalén",
                "countryCode": "CO"
            },
            {
                "name": "Junín",
                "countryCode": "CO"
            },
            {
                "name": "La Calera",
                "countryCode": "CO"
            },
            {
                "name": "La Mesa",
                "countryCode": "CO"
            },
            {
                "name": "La Palma",
                "countryCode": "CO"
            },
            {
                "name": "La Peña",
                "countryCode": "CO"
            },
            {
                "name": "La Vega",
                "countryCode": "CO"
            },
            {
                "name": "Lenguazaque",
                "countryCode": "CO"
            },
            {
                "name": "Macheta",
                "countryCode": "CO"
            },
            {
                "name": "Madrid",
                "countryCode": "CO"
            },
            {
                "name": "Manta",
                "countryCode": "CO"
            },
            {
                "name": "Medina",
                "countryCode": "CO"
            },
            {
                "name": "Mosquera",
                "countryCode": "CO"
            },
            {
                "name": "Nariño",
                "countryCode": "CO"
            },
            {
                "name": "Nemocón",
                "countryCode": "CO"
            },
            {
                "name": "Nilo",
                "countryCode": "CO"
            },
            {
                "name": "Nimaima",
                "countryCode": "CO"
            },
            {
                "name": "Nocaima",
                "countryCode": "CO"
            },
            {
                "name": "Pacho",
                "countryCode": "CO"
            },
            {
                "name": "Paime",
                "countryCode": "CO"
            },
            {
                "name": "Pandi",
                "countryCode": "CO"
            },
            {
                "name": "Paratebueno",
                "countryCode": "CO"
            },
            {
                "name": "Pasca",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Salgar",
                "countryCode": "CO"
            },
            {
                "name": "Pulí",
                "countryCode": "CO"
            },
            {
                "name": "Quebradanegra",
                "countryCode": "CO"
            },
            {
                "name": "Quetame",
                "countryCode": "CO"
            },
            {
                "name": "Quipile",
                "countryCode": "CO"
            },
            {
                "name": "Ricaurte",
                "countryCode": "CO"
            },
            {
                "name": "San Antonio del Tequendama",
                "countryCode": "CO"
            },
            {
                "name": "San Bernardo",
                "countryCode": "CO"
            },
            {
                "name": "San Cayetano",
                "countryCode": "CO"
            },
            {
                "name": "San Francisco",
                "countryCode": "CO"
            },
            {
                "name": "San Juan de Río Seco",
                "countryCode": "CO"
            },
            {
                "name": "Sasaima",
                "countryCode": "CO"
            },
            {
                "name": "Sesquilé",
                "countryCode": "CO"
            },
            {
                "name": "Sibaté",
                "countryCode": "CO"
            },
            {
                "name": "Silvania",
                "countryCode": "CO"
            },
            {
                "name": "Simijaca",
                "countryCode": "CO"
            },
            {
                "name": "Soacha",
                "countryCode": "CO"
            },
            {
                "name": "Sopó",
                "countryCode": "CO"
            },
            {
                "name": "Subachoque",
                "countryCode": "CO"
            },
            {
                "name": "Suesca",
                "countryCode": "CO"
            },
            {
                "name": "Supatá",
                "countryCode": "CO"
            },
            {
                "name": "Susa",
                "countryCode": "CO"
            },
            {
                "name": "Sutatausa",
                "countryCode": "CO"
            },
            {
                "name": "Tabio",
                "countryCode": "CO"
            },
            {
                "name": "Tausa",
                "countryCode": "CO"
            },
            {
                "name": "Tena",
                "countryCode": "CO"
            },
            {
                "name": "Tenjo",
                "countryCode": "CO"
            },
            {
                "name": "Tibacuy",
                "countryCode": "CO"
            },
            {
                "name": "Tibirita",
                "countryCode": "CO"
            },
            {
                "name": "Tocaima",
                "countryCode": "CO"
            },
            {
                "name": "Tocancipá",
                "countryCode": "CO"
            },
            {
                "name": "Topaipí",
                "countryCode": "CO"
            },
            {
                "name": "Ubalá",
                "countryCode": "CO"
            },
            {
                "name": "Ubaque",
                "countryCode": "CO"
            },
            {
                "name": "Une",
                "countryCode": "CO"
            },
            {
                "name": "Venecia",
                "countryCode": "CO"
            },
            {
                "name": "Vergara",
                "countryCode": "CO"
            },
            {
                "name": "Vianí",
                "countryCode": "CO"
            },
            {
                "name": "Villa de San Diego de Ubate",
                "countryCode": "CO"
            },
            {
                "name": "Villagómez",
                "countryCode": "CO"
            },
            {
                "name": "Villapinzón",
                "countryCode": "CO"
            },
            {
                "name": "Villeta",
                "countryCode": "CO"
            },
            {
                "name": "Viotá",
                "countryCode": "CO"
            },
            {
                "name": "Yacopí",
                "countryCode": "CO"
            },
            {
                "name": "Zipacón",
                "countryCode": "CO"
            },
            {
                "name": "Zipaquirá",
                "countryCode": "CO"
            },
            {
                "name": "Útica",
                "countryCode": "CO"
            },
            {
                "name": "Bogotá D.C.",
                "countryCode": "CO"
            },
            {
                "name": "Barranco Minas",
                "countryCode": "CO"
            },
            {
                "name": "Cacahual",
                "countryCode": "CO"
            },
            {
                "name": "Inírida",
                "countryCode": "CO"
            },
            {
                "name": "La Guadalupe",
                "countryCode": "CO"
            },
            {
                "name": "Mapiripana",
                "countryCode": "CO"
            },
            {
                "name": "Morichal",
                "countryCode": "CO"
            },
            {
                "name": "Pana Pana",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Colombia",
                "countryCode": "CO"
            },
            {
                "name": "San Felipe",
                "countryCode": "CO"
            },
            {
                "name": "Calamar",
                "countryCode": "CO"
            },
            {
                "name": "El Retorno",
                "countryCode": "CO"
            },
            {
                "name": "Miraflores",
                "countryCode": "CO"
            },
            {
                "name": "San José del Guaviare",
                "countryCode": "CO"
            },
            {
                "name": "Acevedo",
                "countryCode": "CO"
            },
            {
                "name": "Aipe",
                "countryCode": "CO"
            },
            {
                "name": "Algeciras",
                "countryCode": "CO"
            },
            {
                "name": "Altamira",
                "countryCode": "CO"
            },
            {
                "name": "Baraya",
                "countryCode": "CO"
            },
            {
                "name": "Campoalegre",
                "countryCode": "CO"
            },
            {
                "name": "Colombia",
                "countryCode": "CO"
            },
            {
                "name": "El Agrado",
                "countryCode": "CO"
            },
            {
                "name": "Elias",
                "countryCode": "CO"
            },
            {
                "name": "Garzón",
                "countryCode": "CO"
            },
            {
                "name": "Gigante",
                "countryCode": "CO"
            },
            {
                "name": "Guadalupe",
                "countryCode": "CO"
            },
            {
                "name": "Hobo",
                "countryCode": "CO"
            },
            {
                "name": "Iquira",
                "countryCode": "CO"
            },
            {
                "name": "Isnos",
                "countryCode": "CO"
            },
            {
                "name": "La Argentina",
                "countryCode": "CO"
            },
            {
                "name": "La Plata",
                "countryCode": "CO"
            },
            {
                "name": "Nataga",
                "countryCode": "CO"
            },
            {
                "name": "Neiva",
                "countryCode": "CO"
            },
            {
                "name": "Oporapa",
                "countryCode": "CO"
            },
            {
                "name": "Paicol",
                "countryCode": "CO"
            },
            {
                "name": "Palermo",
                "countryCode": "CO"
            },
            {
                "name": "Palestina",
                "countryCode": "CO"
            },
            {
                "name": "Pital",
                "countryCode": "CO"
            },
            {
                "name": "Pitalito",
                "countryCode": "CO"
            },
            {
                "name": "Rivera",
                "countryCode": "CO"
            },
            {
                "name": "Saladoblanco",
                "countryCode": "CO"
            },
            {
                "name": "San Agustín",
                "countryCode": "CO"
            },
            {
                "name": "Santa María",
                "countryCode": "CO"
            },
            {
                "name": "Suaza",
                "countryCode": "CO"
            },
            {
                "name": "Tarqui",
                "countryCode": "CO"
            },
            {
                "name": "Tello",
                "countryCode": "CO"
            },
            {
                "name": "Teruel",
                "countryCode": "CO"
            },
            {
                "name": "Tesalia",
                "countryCode": "CO"
            },
            {
                "name": "Timana",
                "countryCode": "CO"
            },
            {
                "name": "Villavieja",
                "countryCode": "CO"
            },
            {
                "name": "Yaguará",
                "countryCode": "CO"
            },
            {
                "name": "Albania",
                "countryCode": "CO"
            },
            {
                "name": "Barrancas",
                "countryCode": "CO"
            },
            {
                "name": "Dibulla",
                "countryCode": "CO"
            },
            {
                "name": "Distracción",
                "countryCode": "CO"
            },
            {
                "name": "El Molino",
                "countryCode": "CO"
            },
            {
                "name": "Fonseca",
                "countryCode": "CO"
            },
            {
                "name": "Hatonuevo",
                "countryCode": "CO"
            },
            {
                "name": "La Jagua del Pilar",
                "countryCode": "CO"
            },
            {
                "name": "Maicao",
                "countryCode": "CO"
            },
            {
                "name": "Manaure",
                "countryCode": "CO"
            },
            {
                "name": "Riohacha",
                "countryCode": "CO"
            },
            {
                "name": "San Juan del Cesar",
                "countryCode": "CO"
            },
            {
                "name": "Uribia",
                "countryCode": "CO"
            },
            {
                "name": "Urumita",
                "countryCode": "CO"
            },
            {
                "name": "Villanueva",
                "countryCode": "CO"
            },
            {
                "name": "Algarrobo",
                "countryCode": "CO"
            },
            {
                "name": "Aracataca",
                "countryCode": "CO"
            },
            {
                "name": "Ariguaní",
                "countryCode": "CO"
            },
            {
                "name": "Cerro de San Antonio",
                "countryCode": "CO"
            },
            {
                "name": "Chivolo",
                "countryCode": "CO"
            },
            {
                "name": "Ciénaga",
                "countryCode": "CO"
            },
            {
                "name": "Concordia",
                "countryCode": "CO"
            },
            {
                "name": "El Banco",
                "countryCode": "CO"
            },
            {
                "name": "El Piñon",
                "countryCode": "CO"
            },
            {
                "name": "El Retén",
                "countryCode": "CO"
            },
            {
                "name": "Fundación",
                "countryCode": "CO"
            },
            {
                "name": "Guamal",
                "countryCode": "CO"
            },
            {
                "name": "Nueva Granada",
                "countryCode": "CO"
            },
            {
                "name": "Pedraza",
                "countryCode": "CO"
            },
            {
                "name": "Pijiño del Carmen",
                "countryCode": "CO"
            },
            {
                "name": "Pivijay",
                "countryCode": "CO"
            },
            {
                "name": "Plato",
                "countryCode": "CO"
            },
            {
                "name": "Puebloviejo",
                "countryCode": "CO"
            },
            {
                "name": "Remolino",
                "countryCode": "CO"
            },
            {
                "name": "Sabanas de San Angel",
                "countryCode": "CO"
            },
            {
                "name": "Salamina",
                "countryCode": "CO"
            },
            {
                "name": "San Sebastián de Buenavista",
                "countryCode": "CO"
            },
            {
                "name": "San Zenón",
                "countryCode": "CO"
            },
            {
                "name": "Santa Ana",
                "countryCode": "CO"
            },
            {
                "name": "Santa Bárbara de Pinto",
                "countryCode": "CO"
            },
            {
                "name": "Santa Marta",
                "countryCode": "CO"
            },
            {
                "name": "Sitionuevo",
                "countryCode": "CO"
            },
            {
                "name": "Tenerife",
                "countryCode": "CO"
            },
            {
                "name": "Zapayán",
                "countryCode": "CO"
            },
            {
                "name": "Zona Bananera",
                "countryCode": "CO"
            },
            {
                "name": "Acacías",
                "countryCode": "CO"
            },
            {
                "name": "Barranca de Upía",
                "countryCode": "CO"
            },
            {
                "name": "Cabuyaro",
                "countryCode": "CO"
            },
            {
                "name": "Castilla la Nueva",
                "countryCode": "CO"
            },
            {
                "name": "Cubarral",
                "countryCode": "CO"
            },
            {
                "name": "Cumaral",
                "countryCode": "CO"
            },
            {
                "name": "El Calvario",
                "countryCode": "CO"
            },
            {
                "name": "El Castillo",
                "countryCode": "CO"
            },
            {
                "name": "El Dorado",
                "countryCode": "CO"
            },
            {
                "name": "Fuente de Oro",
                "countryCode": "CO"
            },
            {
                "name": "Granada",
                "countryCode": "CO"
            },
            {
                "name": "Guamal",
                "countryCode": "CO"
            },
            {
                "name": "La Macarena",
                "countryCode": "CO"
            },
            {
                "name": "Lejanías",
                "countryCode": "CO"
            },
            {
                "name": "Mapiripán",
                "countryCode": "CO"
            },
            {
                "name": "Mesetas",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Concordia",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Gaitán",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Lleras",
                "countryCode": "CO"
            },
            {
                "name": "Puerto López",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Rico",
                "countryCode": "CO"
            },
            {
                "name": "Restrepo",
                "countryCode": "CO"
            },
            {
                "name": "San Carlos de Guaroa",
                "countryCode": "CO"
            },
            {
                "name": "San Juan de Arama",
                "countryCode": "CO"
            },
            {
                "name": "San Juanito",
                "countryCode": "CO"
            },
            {
                "name": "San Martín",
                "countryCode": "CO"
            },
            {
                "name": "Uribe",
                "countryCode": "CO"
            },
            {
                "name": "Villavicencio",
                "countryCode": "CO"
            },
            {
                "name": "Vistahermosa",
                "countryCode": "CO"
            },
            {
                "name": "Aldana",
                "countryCode": "CO"
            },
            {
                "name": "Ancuya",
                "countryCode": "CO"
            },
            {
                "name": "Arboleda",
                "countryCode": "CO"
            },
            {
                "name": "Barbacoas",
                "countryCode": "CO"
            },
            {
                "name": "Belén",
                "countryCode": "CO"
            },
            {
                "name": "Buesaco",
                "countryCode": "CO"
            },
            {
                "name": "Chachagüí",
                "countryCode": "CO"
            },
            {
                "name": "Colón",
                "countryCode": "CO"
            },
            {
                "name": "Consaca",
                "countryCode": "CO"
            },
            {
                "name": "Contadero",
                "countryCode": "CO"
            },
            {
                "name": "Cuaspud",
                "countryCode": "CO"
            },
            {
                "name": "Cumbal",
                "countryCode": "CO"
            },
            {
                "name": "Cumbitara",
                "countryCode": "CO"
            },
            {
                "name": "Córdoba",
                "countryCode": "CO"
            },
            {
                "name": "El Charco",
                "countryCode": "CO"
            },
            {
                "name": "El Peñol",
                "countryCode": "CO"
            },
            {
                "name": "El Rosario",
                "countryCode": "CO"
            },
            {
                "name": "El Tablón de Gómez",
                "countryCode": "CO"
            },
            {
                "name": "El Tambo",
                "countryCode": "CO"
            },
            {
                "name": "Francisco Pizarro",
                "countryCode": "CO"
            },
            {
                "name": "Funes",
                "countryCode": "CO"
            },
            {
                "name": "Guachucal",
                "countryCode": "CO"
            },
            {
                "name": "Guaitarilla",
                "countryCode": "CO"
            },
            {
                "name": "Gualmatán",
                "countryCode": "CO"
            },
            {
                "name": "Iles",
                "countryCode": "CO"
            },
            {
                "name": "Imués",
                "countryCode": "CO"
            },
            {
                "name": "Ipiales",
                "countryCode": "CO"
            },
            {
                "name": "La Cruz",
                "countryCode": "CO"
            },
            {
                "name": "La Florida",
                "countryCode": "CO"
            },
            {
                "name": "La Llanada",
                "countryCode": "CO"
            },
            {
                "name": "La Tola",
                "countryCode": "CO"
            },
            {
                "name": "La Unión",
                "countryCode": "CO"
            },
            {
                "name": "Leiva",
                "countryCode": "CO"
            },
            {
                "name": "Linares",
                "countryCode": "CO"
            },
            {
                "name": "Los Andes",
                "countryCode": "CO"
            },
            {
                "name": "Magüí Payán",
                "countryCode": "CO"
            },
            {
                "name": "Mallama",
                "countryCode": "CO"
            },
            {
                "name": "Mosquera",
                "countryCode": "CO"
            },
            {
                "name": "Nariño",
                "countryCode": "CO"
            },
            {
                "name": "Olaya Herrera",
                "countryCode": "CO"
            },
            {
                "name": "Ospina",
                "countryCode": "CO"
            },
            {
                "name": "Pasto",
                "countryCode": "CO"
            },
            {
                "name": "Policarpa",
                "countryCode": "CO"
            },
            {
                "name": "Potosí",
                "countryCode": "CO"
            },
            {
                "name": "Providencia",
                "countryCode": "CO"
            },
            {
                "name": "Puerres",
                "countryCode": "CO"
            },
            {
                "name": "Pupiales",
                "countryCode": "CO"
            },
            {
                "name": "Ricaurte",
                "countryCode": "CO"
            },
            {
                "name": "Roberto Payán",
                "countryCode": "CO"
            },
            {
                "name": "Samaniego",
                "countryCode": "CO"
            },
            {
                "name": "San Bernardo",
                "countryCode": "CO"
            },
            {
                "name": "San José de Albán",
                "countryCode": "CO"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "CO"
            },
            {
                "name": "San Pablo",
                "countryCode": "CO"
            },
            {
                "name": "San Pedro de Cartago",
                "countryCode": "CO"
            },
            {
                "name": "Sandoná",
                "countryCode": "CO"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "CO"
            },
            {
                "name": "Santacruz",
                "countryCode": "CO"
            },
            {
                "name": "Sapuyes",
                "countryCode": "CO"
            },
            {
                "name": "Taminango",
                "countryCode": "CO"
            },
            {
                "name": "Tangua",
                "countryCode": "CO"
            },
            {
                "name": "Tumaco",
                "countryCode": "CO"
            },
            {
                "name": "Túquerres",
                "countryCode": "CO"
            },
            {
                "name": "Yacuanquer",
                "countryCode": "CO"
            },
            {
                "name": "Abrego",
                "countryCode": "CO"
            },
            {
                "name": "Arboledas",
                "countryCode": "CO"
            },
            {
                "name": "Bochalema",
                "countryCode": "CO"
            },
            {
                "name": "Bucarasica",
                "countryCode": "CO"
            },
            {
                "name": "Cachirá",
                "countryCode": "CO"
            },
            {
                "name": "Chinácota",
                "countryCode": "CO"
            },
            {
                "name": "Chitagá",
                "countryCode": "CO"
            },
            {
                "name": "Convención",
                "countryCode": "CO"
            },
            {
                "name": "Cucutilla",
                "countryCode": "CO"
            },
            {
                "name": "Cácota",
                "countryCode": "CO"
            },
            {
                "name": "Cúcuta",
                "countryCode": "CO"
            },
            {
                "name": "Durania",
                "countryCode": "CO"
            },
            {
                "name": "El Carmen",
                "countryCode": "CO"
            },
            {
                "name": "El Tarra",
                "countryCode": "CO"
            },
            {
                "name": "El Zulia",
                "countryCode": "CO"
            },
            {
                "name": "Gramalote",
                "countryCode": "CO"
            },
            {
                "name": "Hacarí",
                "countryCode": "CO"
            },
            {
                "name": "Herrán",
                "countryCode": "CO"
            },
            {
                "name": "La Esperanza",
                "countryCode": "CO"
            },
            {
                "name": "La Playa",
                "countryCode": "CO"
            },
            {
                "name": "Labateca",
                "countryCode": "CO"
            },
            {
                "name": "Los Patios",
                "countryCode": "CO"
            },
            {
                "name": "Lourdes",
                "countryCode": "CO"
            },
            {
                "name": "Mutiscua",
                "countryCode": "CO"
            },
            {
                "name": "Ocaña",
                "countryCode": "CO"
            },
            {
                "name": "Pamplona",
                "countryCode": "CO"
            },
            {
                "name": "Pamplonita",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Santander",
                "countryCode": "CO"
            },
            {
                "name": "Ragonvalia",
                "countryCode": "CO"
            },
            {
                "name": "Salazar",
                "countryCode": "CO"
            },
            {
                "name": "San Calixto",
                "countryCode": "CO"
            },
            {
                "name": "San Cayetano",
                "countryCode": "CO"
            },
            {
                "name": "Santiago",
                "countryCode": "CO"
            },
            {
                "name": "Sardinata",
                "countryCode": "CO"
            },
            {
                "name": "Silos",
                "countryCode": "CO"
            },
            {
                "name": "Teorama",
                "countryCode": "CO"
            },
            {
                "name": "Tibú",
                "countryCode": "CO"
            },
            {
                "name": "Toledo",
                "countryCode": "CO"
            },
            {
                "name": "Villa Caro",
                "countryCode": "CO"
            },
            {
                "name": "Villa del Rosario",
                "countryCode": "CO"
            },
            {
                "name": "Colón",
                "countryCode": "CO"
            },
            {
                "name": "Mocoa",
                "countryCode": "CO"
            },
            {
                "name": "Orito",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Asís",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Caicedo",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Guzmán",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Leguízamo",
                "countryCode": "CO"
            },
            {
                "name": "San Francisco",
                "countryCode": "CO"
            },
            {
                "name": "San Miguel",
                "countryCode": "CO"
            },
            {
                "name": "Santiago",
                "countryCode": "CO"
            },
            {
                "name": "Sibundoy",
                "countryCode": "CO"
            },
            {
                "name": "Valle del Guamuez",
                "countryCode": "CO"
            },
            {
                "name": "Villagarzón",
                "countryCode": "CO"
            },
            {
                "name": "Armenia",
                "countryCode": "CO"
            },
            {
                "name": "Buenavista",
                "countryCode": "CO"
            },
            {
                "name": "Calarca",
                "countryCode": "CO"
            },
            {
                "name": "Circasia",
                "countryCode": "CO"
            },
            {
                "name": "Córdoba",
                "countryCode": "CO"
            },
            {
                "name": "Filandia",
                "countryCode": "CO"
            },
            {
                "name": "Génova",
                "countryCode": "CO"
            },
            {
                "name": "La Tebaida",
                "countryCode": "CO"
            },
            {
                "name": "Montenegro",
                "countryCode": "CO"
            },
            {
                "name": "Pijao",
                "countryCode": "CO"
            },
            {
                "name": "Quimbaya",
                "countryCode": "CO"
            },
            {
                "name": "Salento",
                "countryCode": "CO"
            },
            {
                "name": "Apía",
                "countryCode": "CO"
            },
            {
                "name": "Balboa",
                "countryCode": "CO"
            },
            {
                "name": "Belén de Umbría",
                "countryCode": "CO"
            },
            {
                "name": "Dosquebradas",
                "countryCode": "CO"
            },
            {
                "name": "Guática",
                "countryCode": "CO"
            },
            {
                "name": "La Celia",
                "countryCode": "CO"
            },
            {
                "name": "La Virginia",
                "countryCode": "CO"
            },
            {
                "name": "Marsella",
                "countryCode": "CO"
            },
            {
                "name": "Mistrató",
                "countryCode": "CO"
            },
            {
                "name": "Pereira",
                "countryCode": "CO"
            },
            {
                "name": "Pueblo Rico",
                "countryCode": "CO"
            },
            {
                "name": "Quinchía",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosa de Cabal",
                "countryCode": "CO"
            },
            {
                "name": "Santuario",
                "countryCode": "CO"
            },
            {
                "name": "Aguada",
                "countryCode": "CO"
            },
            {
                "name": "Albania",
                "countryCode": "CO"
            },
            {
                "name": "Aratoca",
                "countryCode": "CO"
            },
            {
                "name": "Barbosa",
                "countryCode": "CO"
            },
            {
                "name": "Barichara",
                "countryCode": "CO"
            },
            {
                "name": "Barrancabermeja",
                "countryCode": "CO"
            },
            {
                "name": "Betulia",
                "countryCode": "CO"
            },
            {
                "name": "Bolívar",
                "countryCode": "CO"
            },
            {
                "name": "Bucaramanga",
                "countryCode": "CO"
            },
            {
                "name": "Cabrera",
                "countryCode": "CO"
            },
            {
                "name": "California",
                "countryCode": "CO"
            },
            {
                "name": "Capitanejo",
                "countryCode": "CO"
            },
            {
                "name": "Carcasí",
                "countryCode": "CO"
            },
            {
                "name": "Cepitá",
                "countryCode": "CO"
            },
            {
                "name": "Cerrito",
                "countryCode": "CO"
            },
            {
                "name": "Charalá",
                "countryCode": "CO"
            },
            {
                "name": "Charta",
                "countryCode": "CO"
            },
            {
                "name": "Chima",
                "countryCode": "CO"
            },
            {
                "name": "Chipatá",
                "countryCode": "CO"
            },
            {
                "name": "Cimitarra",
                "countryCode": "CO"
            },
            {
                "name": "Concepción",
                "countryCode": "CO"
            },
            {
                "name": "Confines",
                "countryCode": "CO"
            },
            {
                "name": "Contratación",
                "countryCode": "CO"
            },
            {
                "name": "Coromoro",
                "countryCode": "CO"
            },
            {
                "name": "Curití",
                "countryCode": "CO"
            },
            {
                "name": "El Carmen de Chucurí",
                "countryCode": "CO"
            },
            {
                "name": "El Guacamayo",
                "countryCode": "CO"
            },
            {
                "name": "El Peñón",
                "countryCode": "CO"
            },
            {
                "name": "El Playón",
                "countryCode": "CO"
            },
            {
                "name": "Encino",
                "countryCode": "CO"
            },
            {
                "name": "Enciso",
                "countryCode": "CO"
            },
            {
                "name": "Floridablanca",
                "countryCode": "CO"
            },
            {
                "name": "Florián",
                "countryCode": "CO"
            },
            {
                "name": "Galán",
                "countryCode": "CO"
            },
            {
                "name": "Gambita",
                "countryCode": "CO"
            },
            {
                "name": "Girón",
                "countryCode": "CO"
            },
            {
                "name": "Guaca",
                "countryCode": "CO"
            },
            {
                "name": "Guadalupe",
                "countryCode": "CO"
            },
            {
                "name": "Guapotá",
                "countryCode": "CO"
            },
            {
                "name": "Guavatá",
                "countryCode": "CO"
            },
            {
                "name": "Güepsa",
                "countryCode": "CO"
            },
            {
                "name": "Hato",
                "countryCode": "CO"
            },
            {
                "name": "Jesús María",
                "countryCode": "CO"
            },
            {
                "name": "Jordán",
                "countryCode": "CO"
            },
            {
                "name": "La Belleza",
                "countryCode": "CO"
            },
            {
                "name": "La Paz",
                "countryCode": "CO"
            },
            {
                "name": "Landázuri",
                "countryCode": "CO"
            },
            {
                "name": "Lebrija",
                "countryCode": "CO"
            },
            {
                "name": "Los Santos",
                "countryCode": "CO"
            },
            {
                "name": "Macaravita",
                "countryCode": "CO"
            },
            {
                "name": "Matanza",
                "countryCode": "CO"
            },
            {
                "name": "Mogotes",
                "countryCode": "CO"
            },
            {
                "name": "Molagavita",
                "countryCode": "CO"
            },
            {
                "name": "Málaga",
                "countryCode": "CO"
            },
            {
                "name": "Ocamonte",
                "countryCode": "CO"
            },
            {
                "name": "Oiba",
                "countryCode": "CO"
            },
            {
                "name": "Onzaga",
                "countryCode": "CO"
            },
            {
                "name": "Palmar",
                "countryCode": "CO"
            },
            {
                "name": "Palmas del Socorro",
                "countryCode": "CO"
            },
            {
                "name": "Piedecuesta",
                "countryCode": "CO"
            },
            {
                "name": "Pinchote",
                "countryCode": "CO"
            },
            {
                "name": "Puente Nacional",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Parra",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Wilches",
                "countryCode": "CO"
            },
            {
                "name": "Páramo",
                "countryCode": "CO"
            },
            {
                "name": "Rionegro",
                "countryCode": "CO"
            },
            {
                "name": "Sabana de Torres",
                "countryCode": "CO"
            },
            {
                "name": "San Andrés",
                "countryCode": "CO"
            },
            {
                "name": "San Benito",
                "countryCode": "CO"
            },
            {
                "name": "San Gil",
                "countryCode": "CO"
            },
            {
                "name": "San Joaquín",
                "countryCode": "CO"
            },
            {
                "name": "San José de Miranda",
                "countryCode": "CO"
            },
            {
                "name": "San Miguel",
                "countryCode": "CO"
            },
            {
                "name": "San Vicente de Chucurí",
                "countryCode": "CO"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "CO"
            },
            {
                "name": "Santa Helena Del Opón",
                "countryCode": "CO"
            },
            {
                "name": "Simacota",
                "countryCode": "CO"
            },
            {
                "name": "Socorro",
                "countryCode": "CO"
            },
            {
                "name": "Suaita",
                "countryCode": "CO"
            },
            {
                "name": "Sucre",
                "countryCode": "CO"
            },
            {
                "name": "Suratá",
                "countryCode": "CO"
            },
            {
                "name": "Tona",
                "countryCode": "CO"
            },
            {
                "name": "Valle de San José",
                "countryCode": "CO"
            },
            {
                "name": "Vetas",
                "countryCode": "CO"
            },
            {
                "name": "Villanueva",
                "countryCode": "CO"
            },
            {
                "name": "Vélez",
                "countryCode": "CO"
            },
            {
                "name": "Zapatoca",
                "countryCode": "CO"
            },
            {
                "name": "Providencia",
                "countryCode": "CO"
            },
            {
                "name": "San Andrés",
                "countryCode": "CO"
            },
            {
                "name": "Buenavista",
                "countryCode": "CO"
            },
            {
                "name": "Caimito",
                "countryCode": "CO"
            },
            {
                "name": "Chalán",
                "countryCode": "CO"
            },
            {
                "name": "Coloso",
                "countryCode": "CO"
            },
            {
                "name": "Corozal",
                "countryCode": "CO"
            },
            {
                "name": "Coveñas",
                "countryCode": "CO"
            },
            {
                "name": "El Roble",
                "countryCode": "CO"
            },
            {
                "name": "Galeras",
                "countryCode": "CO"
            },
            {
                "name": "Guaranda",
                "countryCode": "CO"
            },
            {
                "name": "La Unión",
                "countryCode": "CO"
            },
            {
                "name": "Los Palmitos",
                "countryCode": "CO"
            },
            {
                "name": "Majagual",
                "countryCode": "CO"
            },
            {
                "name": "Morroa",
                "countryCode": "CO"
            },
            {
                "name": "Ovejas",
                "countryCode": "CO"
            },
            {
                "name": "Palmito",
                "countryCode": "CO"
            },
            {
                "name": "Sampués",
                "countryCode": "CO"
            },
            {
                "name": "San Benito Abad",
                "countryCode": "CO"
            },
            {
                "name": "San Juan de Betulia",
                "countryCode": "CO"
            },
            {
                "name": "San Luis de Sincé",
                "countryCode": "CO"
            },
            {
                "name": "San Marcos",
                "countryCode": "CO"
            },
            {
                "name": "San Onofre",
                "countryCode": "CO"
            },
            {
                "name": "San Pedro",
                "countryCode": "CO"
            },
            {
                "name": "Santiago de Tolú",
                "countryCode": "CO"
            },
            {
                "name": "Sincelejo",
                "countryCode": "CO"
            },
            {
                "name": "Sucre",
                "countryCode": "CO"
            },
            {
                "name": "Tolú Viejo",
                "countryCode": "CO"
            },
            {
                "name": "Alpujarra",
                "countryCode": "CO"
            },
            {
                "name": "Alvarado",
                "countryCode": "CO"
            },
            {
                "name": "Ambalema",
                "countryCode": "CO"
            },
            {
                "name": "Anzoátegui",
                "countryCode": "CO"
            },
            {
                "name": "Armero",
                "countryCode": "CO"
            },
            {
                "name": "Ataco",
                "countryCode": "CO"
            },
            {
                "name": "Cajamarca",
                "countryCode": "CO"
            },
            {
                "name": "Carmen de Apicalá",
                "countryCode": "CO"
            },
            {
                "name": "Casabianca",
                "countryCode": "CO"
            },
            {
                "name": "Chaparral",
                "countryCode": "CO"
            },
            {
                "name": "Coello",
                "countryCode": "CO"
            },
            {
                "name": "Coyaima",
                "countryCode": "CO"
            },
            {
                "name": "Cunday",
                "countryCode": "CO"
            },
            {
                "name": "Dolores",
                "countryCode": "CO"
            },
            {
                "name": "Espinal",
                "countryCode": "CO"
            },
            {
                "name": "Falan",
                "countryCode": "CO"
            },
            {
                "name": "Flandes",
                "countryCode": "CO"
            },
            {
                "name": "Fresno",
                "countryCode": "CO"
            },
            {
                "name": "Guamo",
                "countryCode": "CO"
            },
            {
                "name": "Herveo",
                "countryCode": "CO"
            },
            {
                "name": "Honda",
                "countryCode": "CO"
            },
            {
                "name": "Ibagué",
                "countryCode": "CO"
            },
            {
                "name": "Icononzo",
                "countryCode": "CO"
            },
            {
                "name": "Lérida",
                "countryCode": "CO"
            },
            {
                "name": "Líbano",
                "countryCode": "CO"
            },
            {
                "name": "Melgar",
                "countryCode": "CO"
            },
            {
                "name": "Murillo",
                "countryCode": "CO"
            },
            {
                "name": "Natagaima",
                "countryCode": "CO"
            },
            {
                "name": "Ortega",
                "countryCode": "CO"
            },
            {
                "name": "Palocabildo",
                "countryCode": "CO"
            },
            {
                "name": "Piedras",
                "countryCode": "CO"
            },
            {
                "name": "Planadas",
                "countryCode": "CO"
            },
            {
                "name": "Prado",
                "countryCode": "CO"
            },
            {
                "name": "Purificación",
                "countryCode": "CO"
            },
            {
                "name": "Rioblanco",
                "countryCode": "CO"
            },
            {
                "name": "Roncesvalles",
                "countryCode": "CO"
            },
            {
                "name": "Rovira",
                "countryCode": "CO"
            },
            {
                "name": "Saldaña",
                "countryCode": "CO"
            },
            {
                "name": "San Antonio",
                "countryCode": "CO"
            },
            {
                "name": "San Luis",
                "countryCode": "CO"
            },
            {
                "name": "San Sebastián de Mariquita",
                "countryCode": "CO"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "CO"
            },
            {
                "name": "Suárez",
                "countryCode": "CO"
            },
            {
                "name": "Valle de San Juan",
                "countryCode": "CO"
            },
            {
                "name": "Venadillo",
                "countryCode": "CO"
            },
            {
                "name": "Villahermosa",
                "countryCode": "CO"
            },
            {
                "name": "Villarrica",
                "countryCode": "CO"
            },
            {
                "name": "Alcalá",
                "countryCode": "CO"
            },
            {
                "name": "Andalucía",
                "countryCode": "CO"
            },
            {
                "name": "Ansermanuevo",
                "countryCode": "CO"
            },
            {
                "name": "Argelia",
                "countryCode": "CO"
            },
            {
                "name": "Bolívar",
                "countryCode": "CO"
            },
            {
                "name": "Buenaventura",
                "countryCode": "CO"
            },
            {
                "name": "Bugalagrande",
                "countryCode": "CO"
            },
            {
                "name": "Caicedonia",
                "countryCode": "CO"
            },
            {
                "name": "Cali",
                "countryCode": "CO"
            },
            {
                "name": "Calima",
                "countryCode": "CO"
            },
            {
                "name": "Candelaria",
                "countryCode": "CO"
            },
            {
                "name": "Cartago",
                "countryCode": "CO"
            },
            {
                "name": "Dagua",
                "countryCode": "CO"
            },
            {
                "name": "El Cairo",
                "countryCode": "CO"
            },
            {
                "name": "El Cerrito",
                "countryCode": "CO"
            },
            {
                "name": "El Dovio",
                "countryCode": "CO"
            },
            {
                "name": "El Águila",
                "countryCode": "CO"
            },
            {
                "name": "Florida",
                "countryCode": "CO"
            },
            {
                "name": "Ginebra",
                "countryCode": "CO"
            },
            {
                "name": "Guacarí",
                "countryCode": "CO"
            },
            {
                "name": "Guadalajara de Buga",
                "countryCode": "CO"
            },
            {
                "name": "Jamundí",
                "countryCode": "CO"
            },
            {
                "name": "La Cumbre",
                "countryCode": "CO"
            },
            {
                "name": "La Unión",
                "countryCode": "CO"
            },
            {
                "name": "La Victoria",
                "countryCode": "CO"
            },
            {
                "name": "Obando",
                "countryCode": "CO"
            },
            {
                "name": "Palmira",
                "countryCode": "CO"
            },
            {
                "name": "Pradera",
                "countryCode": "CO"
            },
            {
                "name": "Restrepo",
                "countryCode": "CO"
            },
            {
                "name": "Riofrío",
                "countryCode": "CO"
            },
            {
                "name": "Roldanillo",
                "countryCode": "CO"
            },
            {
                "name": "San Pedro",
                "countryCode": "CO"
            },
            {
                "name": "Sevilla",
                "countryCode": "CO"
            },
            {
                "name": "Toro",
                "countryCode": "CO"
            },
            {
                "name": "Trujillo",
                "countryCode": "CO"
            },
            {
                "name": "Tuluá",
                "countryCode": "CO"
            },
            {
                "name": "Ulloa",
                "countryCode": "CO"
            },
            {
                "name": "Versalles",
                "countryCode": "CO"
            },
            {
                "name": "Vijes",
                "countryCode": "CO"
            },
            {
                "name": "Yotoco",
                "countryCode": "CO"
            },
            {
                "name": "Yumbo",
                "countryCode": "CO"
            },
            {
                "name": "Zarzal",
                "countryCode": "CO"
            },
            {
                "name": "Caruru",
                "countryCode": "CO"
            },
            {
                "name": "Mitú",
                "countryCode": "CO"
            },
            {
                "name": "Pacoa",
                "countryCode": "CO"
            },
            {
                "name": "Papunaua",
                "countryCode": "CO"
            },
            {
                "name": "Taraira",
                "countryCode": "CO"
            },
            {
                "name": "Yavaraté",
                "countryCode": "CO"
            },
            {
                "name": "Cumaribo",
                "countryCode": "CO"
            },
            {
                "name": "La Primavera",
                "countryCode": "CO"
            },
            {
                "name": "Puerto Carreño",
                "countryCode": "CO"
            },
            {
                "name": "Santa Rosalia",
                "countryCode": "CO"
            }
        ]
    },
    {
        "name": "Comoros",
        "phonecode": "269",
        "cities": [
            {
                "name": "Adda-Douéni",
                "countryCode": "KM"
            },
            {
                "name": "Antsahé",
                "countryCode": "KM"
            },
            {
                "name": "Assimpao",
                "countryCode": "KM"
            },
            {
                "name": "Bambao",
                "countryCode": "KM"
            },
            {
                "name": "Bandajou",
                "countryCode": "KM"
            },
            {
                "name": "Barakani",
                "countryCode": "KM"
            },
            {
                "name": "Bimbini",
                "countryCode": "KM"
            },
            {
                "name": "Boungouéni",
                "countryCode": "KM"
            },
            {
                "name": "Chandra",
                "countryCode": "KM"
            },
            {
                "name": "Chironkamba",
                "countryCode": "KM"
            },
            {
                "name": "Chitrouni",
                "countryCode": "KM"
            },
            {
                "name": "Daji",
                "countryCode": "KM"
            },
            {
                "name": "Domoni",
                "countryCode": "KM"
            },
            {
                "name": "Dziani",
                "countryCode": "KM"
            },
            {
                "name": "Hajoho",
                "countryCode": "KM"
            },
            {
                "name": "Harembo",
                "countryCode": "KM"
            },
            {
                "name": "Kangani",
                "countryCode": "KM"
            },
            {
                "name": "Kavani",
                "countryCode": "KM"
            },
            {
                "name": "Koki",
                "countryCode": "KM"
            },
            {
                "name": "Koni-Djodjo",
                "countryCode": "KM"
            },
            {
                "name": "Koni-Ngani",
                "countryCode": "KM"
            },
            {
                "name": "Kyo",
                "countryCode": "KM"
            },
            {
                "name": "Limbi",
                "countryCode": "KM"
            },
            {
                "name": "Lingoni",
                "countryCode": "KM"
            },
            {
                "name": "Magnassini-Nindri",
                "countryCode": "KM"
            },
            {
                "name": "Maraharé",
                "countryCode": "KM"
            },
            {
                "name": "Mirontsi",
                "countryCode": "KM"
            },
            {
                "name": "Mjamaoué",
                "countryCode": "KM"
            },
            {
                "name": "Mjimandra",
                "countryCode": "KM"
            },
            {
                "name": "Moutsamoudou",
                "countryCode": "KM"
            },
            {
                "name": "Moya",
                "countryCode": "KM"
            },
            {
                "name": "Mramani",
                "countryCode": "KM"
            },
            {
                "name": "Mrémani",
                "countryCode": "KM"
            },
            {
                "name": "Ongoni",
                "countryCode": "KM"
            },
            {
                "name": "Ouani",
                "countryCode": "KM"
            },
            {
                "name": "Ouzini",
                "countryCode": "KM"
            },
            {
                "name": "Pajé",
                "countryCode": "KM"
            },
            {
                "name": "Patsi",
                "countryCode": "KM"
            },
            {
                "name": "Sima",
                "countryCode": "KM"
            },
            {
                "name": "Tsimbeo",
                "countryCode": "KM"
            },
            {
                "name": "Vouani",
                "countryCode": "KM"
            },
            {
                "name": "Bahani",
                "countryCode": "KM"
            },
            {
                "name": "Bambadjani",
                "countryCode": "KM"
            },
            {
                "name": "Bouni",
                "countryCode": "KM"
            },
            {
                "name": "Chezani",
                "countryCode": "KM"
            },
            {
                "name": "Chindini",
                "countryCode": "KM"
            },
            {
                "name": "Chouani",
                "countryCode": "KM"
            },
            {
                "name": "Dembéni",
                "countryCode": "KM"
            },
            {
                "name": "Douniani",
                "countryCode": "KM"
            },
            {
                "name": "Dzahadjou",
                "countryCode": "KM"
            },
            {
                "name": "Foumbouni",
                "countryCode": "KM"
            },
            {
                "name": "Hantsindzi",
                "countryCode": "KM"
            },
            {
                "name": "Héroumbili",
                "countryCode": "KM"
            },
            {
                "name": "Itsandra",
                "countryCode": "KM"
            },
            {
                "name": "Itsandzéni",
                "countryCode": "KM"
            },
            {
                "name": "Ivouani",
                "countryCode": "KM"
            },
            {
                "name": "Koua",
                "countryCode": "KM"
            },
            {
                "name": "Madjeouéni",
                "countryCode": "KM"
            },
            {
                "name": "Mandza",
                "countryCode": "KM"
            },
            {
                "name": "Mavingouni",
                "countryCode": "KM"
            },
            {
                "name": "Mbéni",
                "countryCode": "KM"
            },
            {
                "name": "Mitsamiouli",
                "countryCode": "KM"
            },
            {
                "name": "Mitsoudjé",
                "countryCode": "KM"
            },
            {
                "name": "Mnoungou",
                "countryCode": "KM"
            },
            {
                "name": "Mohoro",
                "countryCode": "KM"
            },
            {
                "name": "Moroni",
                "countryCode": "KM"
            },
            {
                "name": "Mtsamdou",
                "countryCode": "KM"
            },
            {
                "name": "Mvouni",
                "countryCode": "KM"
            },
            {
                "name": "Nioumamilima",
                "countryCode": "KM"
            },
            {
                "name": "Ntsaouéni",
                "countryCode": "KM"
            },
            {
                "name": "Ntsoudjini",
                "countryCode": "KM"
            },
            {
                "name": "Ouellah",
                "countryCode": "KM"
            },
            {
                "name": "Ouhozi",
                "countryCode": "KM"
            },
            {
                "name": "Ourovéni",
                "countryCode": "KM"
            },
            {
                "name": "Oussivo",
                "countryCode": "KM"
            },
            {
                "name": "Salimani",
                "countryCode": "KM"
            },
            {
                "name": "Singani",
                "countryCode": "KM"
            },
            {
                "name": "Séléa",
                "countryCode": "KM"
            },
            {
                "name": "Tsidjé",
                "countryCode": "KM"
            },
            {
                "name": "Vanadjou",
                "countryCode": "KM"
            },
            {
                "name": "Vanambouani",
                "countryCode": "KM"
            },
            {
                "name": "Djoyézi",
                "countryCode": "KM"
            },
            {
                "name": "Fomboni",
                "countryCode": "KM"
            },
            {
                "name": "Hoani",
                "countryCode": "KM"
            },
            {
                "name": "Mtakoudja",
                "countryCode": "KM"
            },
            {
                "name": "Nioumachoua",
                "countryCode": "KM"
            },
            {
                "name": "Ouanani",
                "countryCode": "KM"
            },
            {
                "name": "Ziroudani",
                "countryCode": "KM"
            }
        ]
    },
    {
        "name": "Congo",
        "phonecode": "242",
        "cities": [
            {
                "name": "Kayes",
                "countryCode": "CG"
            },
            {
                "name": "Madingou",
                "countryCode": "CG"
            },
            {
                "name": "Kinkala",
                "countryCode": "CG"
            },
            {
                "name": "Ouésso",
                "countryCode": "CG"
            },
            {
                "name": "Sémbé",
                "countryCode": "CG"
            },
            {
                "name": "Djambala",
                "countryCode": "CG"
            },
            {
                "name": "Gamboma",
                "countryCode": "CG"
            },
            {
                "name": "Ewo",
                "countryCode": "CG"
            },
            {
                "name": "Loandjili",
                "countryCode": "CG"
            },
            {
                "name": "Pointe-Noire",
                "countryCode": "CG"
            },
            {
                "name": "Sibiti",
                "countryCode": "CG"
            },
            {
                "name": "Impfondo",
                "countryCode": "CG"
            },
            {
                "name": "Makoua",
                "countryCode": "CG"
            },
            {
                "name": "Owando",
                "countryCode": "CG"
            },
            {
                "name": "Dolisie",
                "countryCode": "CG"
            },
            {
                "name": "Mossendjo",
                "countryCode": "CG"
            },
            {
                "name": "Brazzaville",
                "countryCode": "CG"
            }
        ]
    },
    {
        "name": "Democratic Republic of the Congo",
        "phonecode": "243",
        "cities": [
            {
                "name": "Boma",
                "countryCode": "CD"
            },
            {
                "name": "Kasangulu",
                "countryCode": "CD"
            },
            {
                "name": "Matadi",
                "countryCode": "CD"
            },
            {
                "name": "Mbanza-Ngungu",
                "countryCode": "CD"
            },
            {
                "name": "Moanda",
                "countryCode": "CD"
            },
            {
                "name": "Tshela",
                "countryCode": "CD"
            },
            {
                "name": "Aketi",
                "countryCode": "CD"
            },
            {
                "name": "Bondo",
                "countryCode": "CD"
            },
            {
                "name": "Buta",
                "countryCode": "CD"
            },
            {
                "name": "Gemena",
                "countryCode": "CD"
            },
            {
                "name": "Lisala",
                "countryCode": "CD"
            },
            {
                "name": "Lukolela",
                "countryCode": "CD"
            },
            {
                "name": "Mbandaka",
                "countryCode": "CD"
            },
            {
                "name": "Haut Katanga",
                "countryCode": "CD"
            },
            {
                "name": "Kambove",
                "countryCode": "CD"
            },
            {
                "name": "Kipushi",
                "countryCode": "CD"
            },
            {
                "name": "Likasi",
                "countryCode": "CD"
            },
            {
                "name": "Lubumbashi",
                "countryCode": "CD"
            },
            {
                "name": "Bukama",
                "countryCode": "CD"
            },
            {
                "name": "Kamina",
                "countryCode": "CD"
            },
            {
                "name": "Isiro",
                "countryCode": "CD"
            },
            {
                "name": "Wamba",
                "countryCode": "CD"
            },
            {
                "name": "Watsa",
                "countryCode": "CD"
            },
            {
                "name": "Bunia",
                "countryCode": "CD"
            },
            {
                "name": "Gandajika",
                "countryCode": "CD"
            },
            {
                "name": "Kabinda",
                "countryCode": "CD"
            },
            {
                "name": "Mbuji-Mayi",
                "countryCode": "CD"
            },
            {
                "name": "Kasongo-Lunda",
                "countryCode": "CD"
            },
            {
                "name": "Bandundu",
                "countryCode": "CD"
            },
            {
                "name": "Bulungu",
                "countryCode": "CD"
            },
            {
                "name": "Kikwit",
                "countryCode": "CD"
            },
            {
                "name": "Mangai",
                "countryCode": "CD"
            },
            {
                "name": "Kinshasa",
                "countryCode": "CD"
            },
            {
                "name": "Masina",
                "countryCode": "CD"
            },
            {
                "name": "Ilebo",
                "countryCode": "CD"
            },
            {
                "name": "Luebo",
                "countryCode": "CD"
            },
            {
                "name": "Mweka",
                "countryCode": "CD"
            },
            {
                "name": "Tshikapa",
                "countryCode": "CD"
            },
            {
                "name": "Lubao",
                "countryCode": "CD"
            },
            {
                "name": "Mwene-Ditu",
                "countryCode": "CD"
            },
            {
                "name": "Kampene",
                "countryCode": "CD"
            },
            {
                "name": "Kasongo",
                "countryCode": "CD"
            },
            {
                "name": "Kindu",
                "countryCode": "CD"
            },
            {
                "name": "Bolobo",
                "countryCode": "CD"
            },
            {
                "name": "Inongo",
                "countryCode": "CD"
            },
            {
                "name": "Mushie",
                "countryCode": "CD"
            },
            {
                "name": "Nioki",
                "countryCode": "CD"
            },
            {
                "name": "Bumba",
                "countryCode": "CD"
            },
            {
                "name": "Beni",
                "countryCode": "CD"
            },
            {
                "name": "Butembo",
                "countryCode": "CD"
            },
            {
                "name": "Goma",
                "countryCode": "CD"
            },
            {
                "name": "Sake",
                "countryCode": "CD"
            },
            {
                "name": "Bosobolo",
                "countryCode": "CD"
            },
            {
                "name": "Businga",
                "countryCode": "CD"
            },
            {
                "name": "Gbadolite",
                "countryCode": "CD"
            },
            {
                "name": "Lodja",
                "countryCode": "CD"
            },
            {
                "name": "Lusambo",
                "countryCode": "CD"
            },
            {
                "name": "Bukavu",
                "countryCode": "CD"
            },
            {
                "name": "Kabare",
                "countryCode": "CD"
            },
            {
                "name": "Uvira",
                "countryCode": "CD"
            },
            {
                "name": "Bongandanga",
                "countryCode": "CD"
            },
            {
                "name": "Libenge",
                "countryCode": "CD"
            },
            {
                "name": "Kabalo",
                "countryCode": "CD"
            },
            {
                "name": "Kalemie",
                "countryCode": "CD"
            },
            {
                "name": "Kongolo",
                "countryCode": "CD"
            },
            {
                "name": "Basoko",
                "countryCode": "CD"
            },
            {
                "name": "Kisangani",
                "countryCode": "CD"
            },
            {
                "name": "Yangambi",
                "countryCode": "CD"
            },
            {
                "name": "Boende",
                "countryCode": "CD"
            }
        ]
    },
    {
        "name": "Cook Islands",
        "phonecode": "682",
        "cities": []
    },
    {
        "name": "Costa Rica",
        "phonecode": "506",
        "cities": [
            {
                "name": "Alajuela",
                "countryCode": "CR"
            },
            {
                "name": "Atenas",
                "countryCode": "CR"
            },
            {
                "name": "Bijagua",
                "countryCode": "CR"
            },
            {
                "name": "Carrillos",
                "countryCode": "CR"
            },
            {
                "name": "Desamparados",
                "countryCode": "CR"
            },
            {
                "name": "Esquipulas",
                "countryCode": "CR"
            },
            {
                "name": "Grecia",
                "countryCode": "CR"
            },
            {
                "name": "Guatuso",
                "countryCode": "CR"
            },
            {
                "name": "La Fortuna",
                "countryCode": "CR"
            },
            {
                "name": "Los Chiles",
                "countryCode": "CR"
            },
            {
                "name": "Naranjo",
                "countryCode": "CR"
            },
            {
                "name": "Orotina",
                "countryCode": "CR"
            },
            {
                "name": "Palmares",
                "countryCode": "CR"
            },
            {
                "name": "Pital",
                "countryCode": "CR"
            },
            {
                "name": "Pocosol",
                "countryCode": "CR"
            },
            {
                "name": "Poás",
                "countryCode": "CR"
            },
            {
                "name": "Quesada",
                "countryCode": "CR"
            },
            {
                "name": "Río Segundo",
                "countryCode": "CR"
            },
            {
                "name": "Sabanilla",
                "countryCode": "CR"
            },
            {
                "name": "San Carlos",
                "countryCode": "CR"
            },
            {
                "name": "San José",
                "countryCode": "CR"
            },
            {
                "name": "San Juan",
                "countryCode": "CR"
            },
            {
                "name": "San Mateo",
                "countryCode": "CR"
            },
            {
                "name": "San Rafael",
                "countryCode": "CR"
            },
            {
                "name": "San Ramón",
                "countryCode": "CR"
            },
            {
                "name": "Santiago",
                "countryCode": "CR"
            },
            {
                "name": "Upala",
                "countryCode": "CR"
            },
            {
                "name": "Valverde Vega",
                "countryCode": "CR"
            },
            {
                "name": "Zarcero",
                "countryCode": "CR"
            },
            {
                "name": "Alvarado",
                "countryCode": "CR"
            },
            {
                "name": "Cartago",
                "countryCode": "CR"
            },
            {
                "name": "Concepción",
                "countryCode": "CR"
            },
            {
                "name": "Cot",
                "countryCode": "CR"
            },
            {
                "name": "El Guarco",
                "countryCode": "CR"
            },
            {
                "name": "Jiménez",
                "countryCode": "CR"
            },
            {
                "name": "La Suiza",
                "countryCode": "CR"
            },
            {
                "name": "La Unión",
                "countryCode": "CR"
            },
            {
                "name": "Oreamuno",
                "countryCode": "CR"
            },
            {
                "name": "Orosí",
                "countryCode": "CR"
            },
            {
                "name": "Pacayas",
                "countryCode": "CR"
            },
            {
                "name": "Paraíso",
                "countryCode": "CR"
            },
            {
                "name": "Pejibaye",
                "countryCode": "CR"
            },
            {
                "name": "San Diego",
                "countryCode": "CR"
            },
            {
                "name": "Tobosi",
                "countryCode": "CR"
            },
            {
                "name": "Tres Ríos",
                "countryCode": "CR"
            },
            {
                "name": "Tucurrique",
                "countryCode": "CR"
            },
            {
                "name": "Turrialba",
                "countryCode": "CR"
            },
            {
                "name": "Abangares",
                "countryCode": "CR"
            },
            {
                "name": "Bagaces",
                "countryCode": "CR"
            },
            {
                "name": "Belén",
                "countryCode": "CR"
            },
            {
                "name": "Carrillo",
                "countryCode": "CR"
            },
            {
                "name": "Cañas",
                "countryCode": "CR"
            },
            {
                "name": "Fortuna",
                "countryCode": "CR"
            },
            {
                "name": "Hojancha",
                "countryCode": "CR"
            },
            {
                "name": "Juntas",
                "countryCode": "CR"
            },
            {
                "name": "La Cruz",
                "countryCode": "CR"
            },
            {
                "name": "Liberia",
                "countryCode": "CR"
            },
            {
                "name": "Nandayure",
                "countryCode": "CR"
            },
            {
                "name": "Nicoya",
                "countryCode": "CR"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "CR"
            },
            {
                "name": "Sardinal",
                "countryCode": "CR"
            },
            {
                "name": "Sámara",
                "countryCode": "CR"
            },
            {
                "name": "Tilarán",
                "countryCode": "CR"
            },
            {
                "name": "Barva",
                "countryCode": "CR"
            },
            {
                "name": "Belén",
                "countryCode": "CR"
            },
            {
                "name": "Flores",
                "countryCode": "CR"
            },
            {
                "name": "Heredia",
                "countryCode": "CR"
            },
            {
                "name": "La Asunción",
                "countryCode": "CR"
            },
            {
                "name": "Llorente",
                "countryCode": "CR"
            },
            {
                "name": "Mercedes",
                "countryCode": "CR"
            },
            {
                "name": "San Antonio",
                "countryCode": "CR"
            },
            {
                "name": "San Francisco",
                "countryCode": "CR"
            },
            {
                "name": "San Isidro",
                "countryCode": "CR"
            },
            {
                "name": "San Josecito",
                "countryCode": "CR"
            },
            {
                "name": "San Pablo",
                "countryCode": "CR"
            },
            {
                "name": "San Rafael",
                "countryCode": "CR"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "CR"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "CR"
            },
            {
                "name": "Sarapiquí",
                "countryCode": "CR"
            },
            {
                "name": "Ángeles",
                "countryCode": "CR"
            },
            {
                "name": "Batán",
                "countryCode": "CR"
            },
            {
                "name": "Guácimo",
                "countryCode": "CR"
            },
            {
                "name": "Guápiles",
                "countryCode": "CR"
            },
            {
                "name": "Limón",
                "countryCode": "CR"
            },
            {
                "name": "Matina",
                "countryCode": "CR"
            },
            {
                "name": "Pococí",
                "countryCode": "CR"
            },
            {
                "name": "Pocora",
                "countryCode": "CR"
            },
            {
                "name": "Roxana",
                "countryCode": "CR"
            },
            {
                "name": "Siquirres",
                "countryCode": "CR"
            },
            {
                "name": "Sixaola",
                "countryCode": "CR"
            },
            {
                "name": "Talamanca",
                "countryCode": "CR"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "CR"
            },
            {
                "name": "Canoas",
                "countryCode": "CR"
            },
            {
                "name": "Chacarita",
                "countryCode": "CR"
            },
            {
                "name": "Ciudad Cortés",
                "countryCode": "CR"
            },
            {
                "name": "Corredor",
                "countryCode": "CR"
            },
            {
                "name": "Corredores",
                "countryCode": "CR"
            },
            {
                "name": "Coto Brus",
                "countryCode": "CR"
            },
            {
                "name": "Esparza",
                "countryCode": "CR"
            },
            {
                "name": "Garabito",
                "countryCode": "CR"
            },
            {
                "name": "Golfito",
                "countryCode": "CR"
            },
            {
                "name": "Jacó",
                "countryCode": "CR"
            },
            {
                "name": "Miramar",
                "countryCode": "CR"
            },
            {
                "name": "Montes de Oro",
                "countryCode": "CR"
            },
            {
                "name": "Osa",
                "countryCode": "CR"
            },
            {
                "name": "Paquera",
                "countryCode": "CR"
            },
            {
                "name": "Parrita",
                "countryCode": "CR"
            },
            {
                "name": "Puntarenas",
                "countryCode": "CR"
            },
            {
                "name": "Quepos",
                "countryCode": "CR"
            },
            {
                "name": "San Vito",
                "countryCode": "CR"
            },
            {
                "name": "Acosta",
                "countryCode": "CR"
            },
            {
                "name": "Alajuelita",
                "countryCode": "CR"
            },
            {
                "name": "Aserrí",
                "countryCode": "CR"
            },
            {
                "name": "Calle Blancos",
                "countryCode": "CR"
            },
            {
                "name": "Colima",
                "countryCode": "CR"
            },
            {
                "name": "Colón",
                "countryCode": "CR"
            },
            {
                "name": "Curridabat",
                "countryCode": "CR"
            },
            {
                "name": "Daniel Flores",
                "countryCode": "CR"
            },
            {
                "name": "Desamparados",
                "countryCode": "CR"
            },
            {
                "name": "Dota",
                "countryCode": "CR"
            },
            {
                "name": "Escazú",
                "countryCode": "CR"
            },
            {
                "name": "Goicoechea",
                "countryCode": "CR"
            },
            {
                "name": "Granadilla",
                "countryCode": "CR"
            },
            {
                "name": "Guadalupe",
                "countryCode": "CR"
            },
            {
                "name": "Ipís",
                "countryCode": "CR"
            },
            {
                "name": "León Cortés",
                "countryCode": "CR"
            },
            {
                "name": "Mercedes",
                "countryCode": "CR"
            },
            {
                "name": "Montes de Oca",
                "countryCode": "CR"
            },
            {
                "name": "Mora",
                "countryCode": "CR"
            },
            {
                "name": "Moravia",
                "countryCode": "CR"
            },
            {
                "name": "Palmichal",
                "countryCode": "CR"
            },
            {
                "name": "Patarrá",
                "countryCode": "CR"
            },
            {
                "name": "Puriscal",
                "countryCode": "CR"
            },
            {
                "name": "Purral",
                "countryCode": "CR"
            },
            {
                "name": "Pérez Zeledón",
                "countryCode": "CR"
            },
            {
                "name": "Sabanilla",
                "countryCode": "CR"
            },
            {
                "name": "Salitral",
                "countryCode": "CR"
            },
            {
                "name": "Salitrillos",
                "countryCode": "CR"
            },
            {
                "name": "San Felipe",
                "countryCode": "CR"
            },
            {
                "name": "San Ignacio",
                "countryCode": "CR"
            },
            {
                "name": "San Isidro",
                "countryCode": "CR"
            },
            {
                "name": "San José",
                "countryCode": "CR"
            },
            {
                "name": "San Juan",
                "countryCode": "CR"
            },
            {
                "name": "San Juan de Dios",
                "countryCode": "CR"
            },
            {
                "name": "San Marcos",
                "countryCode": "CR"
            },
            {
                "name": "San Miguel",
                "countryCode": "CR"
            },
            {
                "name": "San Pedro",
                "countryCode": "CR"
            },
            {
                "name": "San Rafael",
                "countryCode": "CR"
            },
            {
                "name": "San Rafael Abajo",
                "countryCode": "CR"
            },
            {
                "name": "San Rafael Arriba",
                "countryCode": "CR"
            },
            {
                "name": "San Vicente",
                "countryCode": "CR"
            },
            {
                "name": "San Vicente de Moravia",
                "countryCode": "CR"
            },
            {
                "name": "Santa Ana",
                "countryCode": "CR"
            },
            {
                "name": "Santiago",
                "countryCode": "CR"
            },
            {
                "name": "Tarrazú",
                "countryCode": "CR"
            },
            {
                "name": "Tejar",
                "countryCode": "CR"
            },
            {
                "name": "Tibás",
                "countryCode": "CR"
            },
            {
                "name": "Turrubares",
                "countryCode": "CR"
            },
            {
                "name": "Vázquez de Coronado",
                "countryCode": "CR"
            }
        ]
    },
    {
        "name": "Cote D'Ivoire (Ivory Coast)",
        "phonecode": "225",
        "cities": [
            {
                "name": "Adzopé",
                "countryCode": "CI"
            },
            {
                "name": "Affery",
                "countryCode": "CI"
            },
            {
                "name": "Agboville",
                "countryCode": "CI"
            },
            {
                "name": "Agnéby-Tiassa",
                "countryCode": "CI"
            },
            {
                "name": "Akoupé",
                "countryCode": "CI"
            },
            {
                "name": "Dabou",
                "countryCode": "CI"
            },
            {
                "name": "Grand-Lahou",
                "countryCode": "CI"
            },
            {
                "name": "Grands-Ponts",
                "countryCode": "CI"
            },
            {
                "name": "Tiassalé",
                "countryCode": "CI"
            },
            {
                "name": "Botro",
                "countryCode": "CI"
            },
            {
                "name": "Bouaké",
                "countryCode": "CI"
            },
            {
                "name": "Béoumi",
                "countryCode": "CI"
            },
            {
                "name": "Dabakala",
                "countryCode": "CI"
            },
            {
                "name": "Gbêkê",
                "countryCode": "CI"
            },
            {
                "name": "Hambol",
                "countryCode": "CI"
            },
            {
                "name": "Katiola",
                "countryCode": "CI"
            },
            {
                "name": "Sakassou",
                "countryCode": "CI"
            },
            {
                "name": "Bangolo",
                "countryCode": "CI"
            },
            {
                "name": "Biankouma",
                "countryCode": "CI"
            },
            {
                "name": "Cavally",
                "countryCode": "CI"
            },
            {
                "name": "Danané",
                "countryCode": "CI"
            },
            {
                "name": "Duekoué",
                "countryCode": "CI"
            },
            {
                "name": "Guiglo",
                "countryCode": "CI"
            },
            {
                "name": "Guémon",
                "countryCode": "CI"
            },
            {
                "name": "Man",
                "countryCode": "CI"
            },
            {
                "name": "Tonkpi",
                "countryCode": "CI"
            },
            {
                "name": "Toulépleu Gueré",
                "countryCode": "CI"
            },
            {
                "name": "Arrah",
                "countryCode": "CI"
            },
            {
                "name": "Bocanda",
                "countryCode": "CI"
            },
            {
                "name": "Bongouanou",
                "countryCode": "CI"
            },
            {
                "name": "Bélier",
                "countryCode": "CI"
            },
            {
                "name": "Daoukro",
                "countryCode": "CI"
            },
            {
                "name": "Dimbokro",
                "countryCode": "CI"
            },
            {
                "name": "Iffou",
                "countryCode": "CI"
            },
            {
                "name": "Moronou",
                "countryCode": "CI"
            },
            {
                "name": "N'Zi",
                "countryCode": "CI"
            },
            {
                "name": "Toumodi",
                "countryCode": "CI"
            },
            {
                "name": "Yamoussoukro",
                "countryCode": "CI"
            },
            {
                "name": "Abidjan",
                "countryCode": "CI"
            },
            {
                "name": "Abobo",
                "countryCode": "CI"
            },
            {
                "name": "Anyama",
                "countryCode": "CI"
            },
            {
                "name": "Bingerville",
                "countryCode": "CI"
            },
            {
                "name": "Gbôklé",
                "countryCode": "CI"
            },
            {
                "name": "Nawa",
                "countryCode": "CI"
            },
            {
                "name": "San-Pédro",
                "countryCode": "CI"
            },
            {
                "name": "Sassandra",
                "countryCode": "CI"
            },
            {
                "name": "Tabou",
                "countryCode": "CI"
            },
            {
                "name": "Abengourou",
                "countryCode": "CI"
            },
            {
                "name": "Aboisso",
                "countryCode": "CI"
            },
            {
                "name": "Adiaké",
                "countryCode": "CI"
            },
            {
                "name": "Agnibilékrou",
                "countryCode": "CI"
            },
            {
                "name": "Ayamé",
                "countryCode": "CI"
            },
            {
                "name": "Bonoua",
                "countryCode": "CI"
            },
            {
                "name": "Grand-Bassam",
                "countryCode": "CI"
            },
            {
                "name": "Indénié-Djuablin",
                "countryCode": "CI"
            },
            {
                "name": "Sud-Comoé",
                "countryCode": "CI"
            },
            {
                "name": "Folon",
                "countryCode": "CI"
            },
            {
                "name": "Kabadougou",
                "countryCode": "CI"
            },
            {
                "name": "Odienné",
                "countryCode": "CI"
            },
            {
                "name": "Divo",
                "countryCode": "CI"
            },
            {
                "name": "Gagnoa",
                "countryCode": "CI"
            },
            {
                "name": "Guibéroua",
                "countryCode": "CI"
            },
            {
                "name": "Gôh",
                "countryCode": "CI"
            },
            {
                "name": "Lakota",
                "countryCode": "CI"
            },
            {
                "name": "Lôh-Djiboua",
                "countryCode": "CI"
            },
            {
                "name": "Oumé",
                "countryCode": "CI"
            },
            {
                "name": "Bouaflé",
                "countryCode": "CI"
            },
            {
                "name": "Daloa",
                "countryCode": "CI"
            },
            {
                "name": "Haut-Sassandra",
                "countryCode": "CI"
            },
            {
                "name": "Issia",
                "countryCode": "CI"
            },
            {
                "name": "Marahoué",
                "countryCode": "CI"
            },
            {
                "name": "Vavoua",
                "countryCode": "CI"
            },
            {
                "name": "Zuénoula",
                "countryCode": "CI"
            },
            {
                "name": "Bafing",
                "countryCode": "CI"
            },
            {
                "name": "Béré",
                "countryCode": "CI"
            },
            {
                "name": "Mankono",
                "countryCode": "CI"
            },
            {
                "name": "Séguéla",
                "countryCode": "CI"
            },
            {
                "name": "Touba",
                "countryCode": "CI"
            },
            {
                "name": "Worodougou",
                "countryCode": "CI"
            },
            {
                "name": "Bondoukou",
                "countryCode": "CI"
            },
            {
                "name": "Bouna",
                "countryCode": "CI"
            },
            {
                "name": "Bounkani",
                "countryCode": "CI"
            },
            {
                "name": "Gontougo",
                "countryCode": "CI"
            },
            {
                "name": "Sinfra",
                "countryCode": "CI"
            },
            {
                "name": "Tanda",
                "countryCode": "CI"
            }
        ]
    },
    {
        "name": "Croatia",
        "phonecode": "385",
        "cities": [
            {
                "name": "Bestovje",
                "countryCode": "HR"
            },
            {
                "name": "Bistra",
                "countryCode": "HR"
            },
            {
                "name": "Brckovljani",
                "countryCode": "HR"
            },
            {
                "name": "Brdovec",
                "countryCode": "HR"
            },
            {
                "name": "Bregana",
                "countryCode": "HR"
            },
            {
                "name": "Donja Bistra",
                "countryCode": "HR"
            },
            {
                "name": "Donja Lomnica",
                "countryCode": "HR"
            },
            {
                "name": "Donja Zdenčina",
                "countryCode": "HR"
            },
            {
                "name": "Donji Stupnik",
                "countryCode": "HR"
            },
            {
                "name": "Farkaševac",
                "countryCode": "HR"
            },
            {
                "name": "Gornja Bistra",
                "countryCode": "HR"
            },
            {
                "name": "Grad Dugo Selo",
                "countryCode": "HR"
            },
            {
                "name": "Grad Jastrebarsko",
                "countryCode": "HR"
            },
            {
                "name": "Grad Samobor",
                "countryCode": "HR"
            },
            {
                "name": "Grad Sveti Ivan Zelina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Velika Gorica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vrbovec",
                "countryCode": "HR"
            },
            {
                "name": "Grad Zaprešić",
                "countryCode": "HR"
            },
            {
                "name": "Gradec",
                "countryCode": "HR"
            },
            {
                "name": "Gradići",
                "countryCode": "HR"
            },
            {
                "name": "Gračec",
                "countryCode": "HR"
            },
            {
                "name": "Jablanovec",
                "countryCode": "HR"
            },
            {
                "name": "Jakovlje",
                "countryCode": "HR"
            },
            {
                "name": "Jastrebarsko",
                "countryCode": "HR"
            },
            {
                "name": "Kerestinec",
                "countryCode": "HR"
            },
            {
                "name": "Križ",
                "countryCode": "HR"
            },
            {
                "name": "Kuče",
                "countryCode": "HR"
            },
            {
                "name": "Lonjica",
                "countryCode": "HR"
            },
            {
                "name": "Luka",
                "countryCode": "HR"
            },
            {
                "name": "Lukavec",
                "countryCode": "HR"
            },
            {
                "name": "Lupoglav",
                "countryCode": "HR"
            },
            {
                "name": "Mičevec",
                "countryCode": "HR"
            },
            {
                "name": "Mraclin",
                "countryCode": "HR"
            },
            {
                "name": "Novo Čiče",
                "countryCode": "HR"
            },
            {
                "name": "Novoselec",
                "countryCode": "HR"
            },
            {
                "name": "Općina Dubrava",
                "countryCode": "HR"
            },
            {
                "name": "Orešje",
                "countryCode": "HR"
            },
            {
                "name": "Pojatno",
                "countryCode": "HR"
            },
            {
                "name": "Preseka",
                "countryCode": "HR"
            },
            {
                "name": "Prigorje Brdovečko",
                "countryCode": "HR"
            },
            {
                "name": "Pušća",
                "countryCode": "HR"
            },
            {
                "name": "Rakitje",
                "countryCode": "HR"
            },
            {
                "name": "Rakov Potok",
                "countryCode": "HR"
            },
            {
                "name": "Rude",
                "countryCode": "HR"
            },
            {
                "name": "Samobor",
                "countryCode": "HR"
            },
            {
                "name": "Stupnik",
                "countryCode": "HR"
            },
            {
                "name": "Sveta Nedelja",
                "countryCode": "HR"
            },
            {
                "name": "Sveta Nedjelja",
                "countryCode": "HR"
            },
            {
                "name": "Velika Gorica",
                "countryCode": "HR"
            },
            {
                "name": "Velika Mlaka",
                "countryCode": "HR"
            },
            {
                "name": "Velika Ostrna",
                "countryCode": "HR"
            },
            {
                "name": "Vrbovec",
                "countryCode": "HR"
            },
            {
                "name": "Zaprešić",
                "countryCode": "HR"
            },
            {
                "name": "Zdenci Brdovečki",
                "countryCode": "HR"
            },
            {
                "name": "Bedekovčina",
                "countryCode": "HR"
            },
            {
                "name": "Budinščina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Donja Stubica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Klanjec",
                "countryCode": "HR"
            },
            {
                "name": "Grad Krapina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Zabok",
                "countryCode": "HR"
            },
            {
                "name": "Grad Zlatar",
                "countryCode": "HR"
            },
            {
                "name": "Jesenje",
                "countryCode": "HR"
            },
            {
                "name": "Klanjec",
                "countryCode": "HR"
            },
            {
                "name": "Konjščina",
                "countryCode": "HR"
            },
            {
                "name": "Krapina",
                "countryCode": "HR"
            },
            {
                "name": "Kumrovec",
                "countryCode": "HR"
            },
            {
                "name": "Marija Bistrica",
                "countryCode": "HR"
            },
            {
                "name": "Mače",
                "countryCode": "HR"
            },
            {
                "name": "Mihovljan",
                "countryCode": "HR"
            },
            {
                "name": "Oroslavje",
                "countryCode": "HR"
            },
            {
                "name": "Pregrada",
                "countryCode": "HR"
            },
            {
                "name": "Radoboj",
                "countryCode": "HR"
            },
            {
                "name": "Stubičke Toplice",
                "countryCode": "HR"
            },
            {
                "name": "Sveti Križ Začretje",
                "countryCode": "HR"
            },
            {
                "name": "Zabok",
                "countryCode": "HR"
            },
            {
                "name": "Zlatar",
                "countryCode": "HR"
            },
            {
                "name": "Zlatar Bistrica",
                "countryCode": "HR"
            },
            {
                "name": "Đurmanec",
                "countryCode": "HR"
            },
            {
                "name": "Budaševo",
                "countryCode": "HR"
            },
            {
                "name": "Dvor",
                "countryCode": "HR"
            },
            {
                "name": "Glina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Glina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Hrvatska Kostajnica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Kutina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Novska",
                "countryCode": "HR"
            },
            {
                "name": "Grad Petrinja",
                "countryCode": "HR"
            },
            {
                "name": "Grad Sisak",
                "countryCode": "HR"
            },
            {
                "name": "Gvozd",
                "countryCode": "HR"
            },
            {
                "name": "Hrvatska Kostajnica",
                "countryCode": "HR"
            },
            {
                "name": "Kutina",
                "countryCode": "HR"
            },
            {
                "name": "Lekenik",
                "countryCode": "HR"
            },
            {
                "name": "Lipovljani",
                "countryCode": "HR"
            },
            {
                "name": "Martinska Ves",
                "countryCode": "HR"
            },
            {
                "name": "Novska",
                "countryCode": "HR"
            },
            {
                "name": "Općina Dvor",
                "countryCode": "HR"
            },
            {
                "name": "Općina Gvozd",
                "countryCode": "HR"
            },
            {
                "name": "Petrinja",
                "countryCode": "HR"
            },
            {
                "name": "Popovača",
                "countryCode": "HR"
            },
            {
                "name": "Repušnica",
                "countryCode": "HR"
            },
            {
                "name": "Sisak",
                "countryCode": "HR"
            },
            {
                "name": "Sunja",
                "countryCode": "HR"
            },
            {
                "name": "Voloder",
                "countryCode": "HR"
            },
            {
                "name": "Beretinec",
                "countryCode": "HR"
            },
            {
                "name": "Breznica",
                "countryCode": "HR"
            },
            {
                "name": "Breznički Hum",
                "countryCode": "HR"
            },
            {
                "name": "Cestica",
                "countryCode": "HR"
            },
            {
                "name": "Donje Ladanje",
                "countryCode": "HR"
            },
            {
                "name": "Gornje Vratno",
                "countryCode": "HR"
            },
            {
                "name": "Gornji Kneginec",
                "countryCode": "HR"
            },
            {
                "name": "Grad Ivanec",
                "countryCode": "HR"
            },
            {
                "name": "Grad Ludbreg",
                "countryCode": "HR"
            },
            {
                "name": "Grad Novi Marof",
                "countryCode": "HR"
            },
            {
                "name": "Grad Varaždin",
                "countryCode": "HR"
            },
            {
                "name": "Hrašćica",
                "countryCode": "HR"
            },
            {
                "name": "Ivanec",
                "countryCode": "HR"
            },
            {
                "name": "Jalkovec",
                "countryCode": "HR"
            },
            {
                "name": "Jalžabet",
                "countryCode": "HR"
            },
            {
                "name": "Klenovnik",
                "countryCode": "HR"
            },
            {
                "name": "Kućan Marof",
                "countryCode": "HR"
            },
            {
                "name": "Lepoglava",
                "countryCode": "HR"
            },
            {
                "name": "Ljubešćica",
                "countryCode": "HR"
            },
            {
                "name": "Ludbreg",
                "countryCode": "HR"
            },
            {
                "name": "Nedeljanec",
                "countryCode": "HR"
            },
            {
                "name": "Petrijanec",
                "countryCode": "HR"
            },
            {
                "name": "Remetinec",
                "countryCode": "HR"
            },
            {
                "name": "Sračinec",
                "countryCode": "HR"
            },
            {
                "name": "Sveti Đurđ",
                "countryCode": "HR"
            },
            {
                "name": "Tužno",
                "countryCode": "HR"
            },
            {
                "name": "Varaždin",
                "countryCode": "HR"
            },
            {
                "name": "Vidovec",
                "countryCode": "HR"
            },
            {
                "name": "Vinica",
                "countryCode": "HR"
            },
            {
                "name": "Drnje",
                "countryCode": "HR"
            },
            {
                "name": "Ferdinandovac",
                "countryCode": "HR"
            },
            {
                "name": "Gola",
                "countryCode": "HR"
            },
            {
                "name": "Gornja Rijeka",
                "countryCode": "HR"
            },
            {
                "name": "Grad Koprivnica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Križevci",
                "countryCode": "HR"
            },
            {
                "name": "Hlebine",
                "countryCode": "HR"
            },
            {
                "name": "Kalinovac",
                "countryCode": "HR"
            },
            {
                "name": "Koprivnica",
                "countryCode": "HR"
            },
            {
                "name": "Koprivnički Ivanec",
                "countryCode": "HR"
            },
            {
                "name": "Križevci",
                "countryCode": "HR"
            },
            {
                "name": "Legrad",
                "countryCode": "HR"
            },
            {
                "name": "Molve",
                "countryCode": "HR"
            },
            {
                "name": "Novo Virje",
                "countryCode": "HR"
            },
            {
                "name": "Peteranec",
                "countryCode": "HR"
            },
            {
                "name": "Rasinja",
                "countryCode": "HR"
            },
            {
                "name": "Reka",
                "countryCode": "HR"
            },
            {
                "name": "Sigetec",
                "countryCode": "HR"
            },
            {
                "name": "Virje",
                "countryCode": "HR"
            },
            {
                "name": "Đelekovec",
                "countryCode": "HR"
            },
            {
                "name": "Đurđevac",
                "countryCode": "HR"
            },
            {
                "name": "Bjelovar",
                "countryCode": "HR"
            },
            {
                "name": "Brezovac",
                "countryCode": "HR"
            },
            {
                "name": "Daruvar",
                "countryCode": "HR"
            },
            {
                "name": "Dežanovac",
                "countryCode": "HR"
            },
            {
                "name": "Garešnica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Bjelovar",
                "countryCode": "HR"
            },
            {
                "name": "Grad Daruvar",
                "countryCode": "HR"
            },
            {
                "name": "Grad Garešnica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Grubišno Polje",
                "countryCode": "HR"
            },
            {
                "name": "Grad Čazma",
                "countryCode": "HR"
            },
            {
                "name": "Grubišno Polje",
                "countryCode": "HR"
            },
            {
                "name": "Gudovac",
                "countryCode": "HR"
            },
            {
                "name": "Hercegovac",
                "countryCode": "HR"
            },
            {
                "name": "Ivanska",
                "countryCode": "HR"
            },
            {
                "name": "Kapela",
                "countryCode": "HR"
            },
            {
                "name": "Končanica",
                "countryCode": "HR"
            },
            {
                "name": "Predavac",
                "countryCode": "HR"
            },
            {
                "name": "Rovišće",
                "countryCode": "HR"
            },
            {
                "name": "Severin",
                "countryCode": "HR"
            },
            {
                "name": "Sirač",
                "countryCode": "HR"
            },
            {
                "name": "Velika Pisanica",
                "countryCode": "HR"
            },
            {
                "name": "Veliki Grđevac",
                "countryCode": "HR"
            },
            {
                "name": "Zrinski Topolovac",
                "countryCode": "HR"
            },
            {
                "name": "Čazma",
                "countryCode": "HR"
            },
            {
                "name": "Đulovac",
                "countryCode": "HR"
            },
            {
                "name": "Šandrovac",
                "countryCode": "HR"
            },
            {
                "name": "Ždralovi",
                "countryCode": "HR"
            },
            {
                "name": "Bakar",
                "countryCode": "HR"
            },
            {
                "name": "Banjol",
                "countryCode": "HR"
            },
            {
                "name": "Baška",
                "countryCode": "HR"
            },
            {
                "name": "Bribir",
                "countryCode": "HR"
            },
            {
                "name": "Buzdohanj",
                "countryCode": "HR"
            },
            {
                "name": "Cernik",
                "countryCode": "HR"
            },
            {
                "name": "Cres",
                "countryCode": "HR"
            },
            {
                "name": "Crikvenica",
                "countryCode": "HR"
            },
            {
                "name": "Delnice",
                "countryCode": "HR"
            },
            {
                "name": "Dražice",
                "countryCode": "HR"
            },
            {
                "name": "Drenova",
                "countryCode": "HR"
            },
            {
                "name": "Fužine",
                "countryCode": "HR"
            },
            {
                "name": "Grad Crikvenica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Delnice",
                "countryCode": "HR"
            },
            {
                "name": "Grad Krk",
                "countryCode": "HR"
            },
            {
                "name": "Grad Opatija",
                "countryCode": "HR"
            },
            {
                "name": "Grad Rijeka",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vrbovsko",
                "countryCode": "HR"
            },
            {
                "name": "Grad Čabar",
                "countryCode": "HR"
            },
            {
                "name": "Hreljin",
                "countryCode": "HR"
            },
            {
                "name": "Jadranovo",
                "countryCode": "HR"
            },
            {
                "name": "Kampor",
                "countryCode": "HR"
            },
            {
                "name": "Kastav",
                "countryCode": "HR"
            },
            {
                "name": "Klana",
                "countryCode": "HR"
            },
            {
                "name": "Kraljevica",
                "countryCode": "HR"
            },
            {
                "name": "Krasica",
                "countryCode": "HR"
            },
            {
                "name": "Krk",
                "countryCode": "HR"
            },
            {
                "name": "Lopar",
                "countryCode": "HR"
            },
            {
                "name": "Lovran",
                "countryCode": "HR"
            },
            {
                "name": "Mali Lošinj",
                "countryCode": "HR"
            },
            {
                "name": "Malinska-Dubašnica",
                "countryCode": "HR"
            },
            {
                "name": "Marinići",
                "countryCode": "HR"
            },
            {
                "name": "Marčelji",
                "countryCode": "HR"
            },
            {
                "name": "Matulji",
                "countryCode": "HR"
            },
            {
                "name": "Mihotići",
                "countryCode": "HR"
            },
            {
                "name": "Mrkopalj",
                "countryCode": "HR"
            },
            {
                "name": "Njivice",
                "countryCode": "HR"
            },
            {
                "name": "Novi Vinodolski",
                "countryCode": "HR"
            },
            {
                "name": "Omišalj",
                "countryCode": "HR"
            },
            {
                "name": "Opatija",
                "countryCode": "HR"
            },
            {
                "name": "Podhum",
                "countryCode": "HR"
            },
            {
                "name": "Punat",
                "countryCode": "HR"
            },
            {
                "name": "Rab",
                "countryCode": "HR"
            },
            {
                "name": "Rijeka",
                "countryCode": "HR"
            },
            {
                "name": "Rubeši",
                "countryCode": "HR"
            },
            {
                "name": "Selce",
                "countryCode": "HR"
            },
            {
                "name": "Skrad",
                "countryCode": "HR"
            },
            {
                "name": "Supetarska Draga",
                "countryCode": "HR"
            },
            {
                "name": "Vinodolska općina",
                "countryCode": "HR"
            },
            {
                "name": "Viškovo",
                "countryCode": "HR"
            },
            {
                "name": "Vrbnik",
                "countryCode": "HR"
            },
            {
                "name": "Vrbovsko",
                "countryCode": "HR"
            },
            {
                "name": "Čavle",
                "countryCode": "HR"
            },
            {
                "name": "Škrljevo",
                "countryCode": "HR"
            },
            {
                "name": "Brinje",
                "countryCode": "HR"
            },
            {
                "name": "Gospić",
                "countryCode": "HR"
            },
            {
                "name": "Karlobag",
                "countryCode": "HR"
            },
            {
                "name": "Lički Osik",
                "countryCode": "HR"
            },
            {
                "name": "Novalja",
                "countryCode": "HR"
            },
            {
                "name": "Otočac",
                "countryCode": "HR"
            },
            {
                "name": "Perušić",
                "countryCode": "HR"
            },
            {
                "name": "Plitvička Jezera",
                "countryCode": "HR"
            },
            {
                "name": "Popovača",
                "countryCode": "HR"
            },
            {
                "name": "Senj",
                "countryCode": "HR"
            },
            {
                "name": "Crnac",
                "countryCode": "HR"
            },
            {
                "name": "Grad Orahovica",
                "countryCode": "HR"
            },
            {
                "name": "Grad Slatina",
                "countryCode": "HR"
            },
            {
                "name": "Grad Virovitica",
                "countryCode": "HR"
            },
            {
                "name": "Gradina",
                "countryCode": "HR"
            },
            {
                "name": "Mikleuš",
                "countryCode": "HR"
            },
            {
                "name": "Nova Bukovica",
                "countryCode": "HR"
            },
            {
                "name": "Orahovica",
                "countryCode": "HR"
            },
            {
                "name": "Pitomača",
                "countryCode": "HR"
            },
            {
                "name": "Rezovac",
                "countryCode": "HR"
            },
            {
                "name": "Slatina",
                "countryCode": "HR"
            },
            {
                "name": "Sopje",
                "countryCode": "HR"
            },
            {
                "name": "Suhopolje",
                "countryCode": "HR"
            },
            {
                "name": "Virovitica",
                "countryCode": "HR"
            },
            {
                "name": "Voćin",
                "countryCode": "HR"
            },
            {
                "name": "Zdenci",
                "countryCode": "HR"
            },
            {
                "name": "Čačinci",
                "countryCode": "HR"
            },
            {
                "name": "Čađavica",
                "countryCode": "HR"
            },
            {
                "name": "Špišić Bukovica",
                "countryCode": "HR"
            },
            {
                "name": "Brestovac",
                "countryCode": "HR"
            },
            {
                "name": "Grad Pakrac",
                "countryCode": "HR"
            },
            {
                "name": "Grad Požega",
                "countryCode": "HR"
            },
            {
                "name": "Jakšić",
                "countryCode": "HR"
            },
            {
                "name": "Kaptol",
                "countryCode": "HR"
            },
            {
                "name": "Kutjevo",
                "countryCode": "HR"
            },
            {
                "name": "Lipik",
                "countryCode": "HR"
            },
            {
                "name": "Pakrac",
                "countryCode": "HR"
            },
            {
                "name": "Pleternica",
                "countryCode": "HR"
            },
            {
                "name": "Požega",
                "countryCode": "HR"
            },
            {
                "name": "Velika",
                "countryCode": "HR"
            },
            {
                "name": "Vidovci",
                "countryCode": "HR"
            },
            {
                "name": "Batrina",
                "countryCode": "HR"
            },
            {
                "name": "Brodski Varoš",
                "countryCode": "HR"
            },
            {
                "name": "Bukovlje",
                "countryCode": "HR"
            },
            {
                "name": "Cernik",
                "countryCode": "HR"
            },
            {
                "name": "Davor",
                "countryCode": "HR"
            },
            {
                "name": "Donji Andrijevci",
                "countryCode": "HR"
            },
            {
                "name": "Garčin",
                "countryCode": "HR"
            },
            {
                "name": "Gornji Bogićevci",
                "countryCode": "HR"
            },
            {
                "name": "Grad Nova Gradiška",
                "countryCode": "HR"
            },
            {
                "name": "Grad Slavonski Brod",
                "countryCode": "HR"
            },
            {
                "name": "Gundinci",
                "countryCode": "HR"
            },
            {
                "name": "Korenica",
                "countryCode": "HR"
            },
            {
                "name": "Kruševica",
                "countryCode": "HR"
            },
            {
                "name": "Lužani",
                "countryCode": "HR"
            },
            {
                "name": "Nova Gradiška",
                "countryCode": "HR"
            },
            {
                "name": "Okučani",
                "countryCode": "HR"
            },
            {
                "name": "Oprisavci",
                "countryCode": "HR"
            },
            {
                "name": "Oriovac",
                "countryCode": "HR"
            },
            {
                "name": "Podvinje",
                "countryCode": "HR"
            },
            {
                "name": "Rešetari",
                "countryCode": "HR"
            },
            {
                "name": "Ruščica",
                "countryCode": "HR"
            },
            {
                "name": "Sibinj",
                "countryCode": "HR"
            },
            {
                "name": "Sikirevci",
                "countryCode": "HR"
            },
            {
                "name": "Slavonski Brod",
                "countryCode": "HR"
            },
            {
                "name": "Slobodnica",
                "countryCode": "HR"
            },
            {
                "name": "Stari Perkovci",
                "countryCode": "HR"
            },
            {
                "name": "Velika Kopanica",
                "countryCode": "HR"
            },
            {
                "name": "Vrpolje",
                "countryCode": "HR"
            },
            {
                "name": "Benkovac",
                "countryCode": "HR"
            },
            {
                "name": "Bibinje",
                "countryCode": "HR"
            },
            {
                "name": "Biograd na Moru",
                "countryCode": "HR"
            },
            {
                "name": "Galovac",
                "countryCode": "HR"
            },
            {
                "name": "Gornji Karin",
                "countryCode": "HR"
            },
            {
                "name": "Grad Biograd na Moru",
                "countryCode": "HR"
            },
            {
                "name": "Gračac",
                "countryCode": "HR"
            },
            {
                "name": "Jasenice",
                "countryCode": "HR"
            },
            {
                "name": "Kali",
                "countryCode": "HR"
            },
            {
                "name": "Kruševo",
                "countryCode": "HR"
            },
            {
                "name": "Nin",
                "countryCode": "HR"
            },
            {
                "name": "Novigrad Općina",
                "countryCode": "HR"
            },
            {
                "name": "Obrovac",
                "countryCode": "HR"
            },
            {
                "name": "Osljak",
                "countryCode": "HR"
            },
            {
                "name": "Pag",
                "countryCode": "HR"
            },
            {
                "name": "Pakoštane",
                "countryCode": "HR"
            },
            {
                "name": "Polača",
                "countryCode": "HR"
            },
            {
                "name": "Poličnik",
                "countryCode": "HR"
            },
            {
                "name": "Posedarje",
                "countryCode": "HR"
            },
            {
                "name": "Preko",
                "countryCode": "HR"
            },
            {
                "name": "Pridraga",
                "countryCode": "HR"
            },
            {
                "name": "Privlaka",
                "countryCode": "HR"
            },
            {
                "name": "Ražanac",
                "countryCode": "HR"
            },
            {
                "name": "Sali",
                "countryCode": "HR"
            },
            {
                "name": "Stari Grad",
                "countryCode": "HR"
            },
            {
                "name": "Starigrad",
                "countryCode": "HR"
            },
            {
                "name": "Sukošan",
                "countryCode": "HR"
            },
            {
                "name": "Sveti Filip i Jakov",
                "countryCode": "HR"
            },
            {
                "name": "Tkon",
                "countryCode": "HR"
            },
            {
                "name": "Turanj",
                "countryCode": "HR"
            },
            {
                "name": "Ugljan",
                "countryCode": "HR"
            },
            {
                "name": "Vir",
                "countryCode": "HR"
            },
            {
                "name": "Vrsi",
                "countryCode": "HR"
            },
            {
                "name": "Zadar",
                "countryCode": "HR"
            },
            {
                "name": "Škabrnja",
                "countryCode": "HR"
            },
            {
                "name": "Antunovac",
                "countryCode": "HR"
            },
            {
                "name": "Beli Manastir",
                "countryCode": "HR"
            },
            {
                "name": "Belišće",
                "countryCode": "HR"
            },
            {
                "name": "Bijelo Brdo",
                "countryCode": "HR"
            },
            {
                "name": "Bilje",
                "countryCode": "HR"
            },
            {
                "name": "Bistrinci",
                "countryCode": "HR"
            },
            {
                "name": "Bizovac",
                "countryCode": "HR"
            },
            {
                "name": "Brijest",
                "countryCode": "HR"
            },
            {
                "name": "Budrovci",
                "countryCode": "HR"
            },
            {
                "name": "Dalj",
                "countryCode": "HR"
            },
            {
                "name": "Darda",
                "countryCode": "HR"
            },
            {
                "name": "Donji Miholjac",
                "countryCode": "HR"
            },
            {
                "name": "Draž",
                "countryCode": "HR"
            },
            {
                "name": "Erdut",
                "countryCode": "HR"
            },
            {
                "name": "Ernestinovo",
                "countryCode": "HR"
            },
            {
                "name": "Feričanci",
                "countryCode": "HR"
            },
            {
                "name": "Gorjani",
                "countryCode": "HR"
            },
            {
                "name": "Grad Beli Manastir",
                "countryCode": "HR"
            },
            {
                "name": "Grad Donji Miholjac",
                "countryCode": "HR"
            },
            {
                "name": "Grad Našice",
                "countryCode": "HR"
            },
            {
                "name": "Grad Osijek",
                "countryCode": "HR"
            },
            {
                "name": "Grad Valpovo",
                "countryCode": "HR"
            },
            {
                "name": "Jagodnjak",
                "countryCode": "HR"
            },
            {
                "name": "Jelisavac",
                "countryCode": "HR"
            },
            {
                "name": "Josipovac",
                "countryCode": "HR"
            },
            {
                "name": "Karanac",
                "countryCode": "HR"
            },
            {
                "name": "Kneževi Vinogradi",
                "countryCode": "HR"
            },
            {
                "name": "Koška",
                "countryCode": "HR"
            },
            {
                "name": "Kuševac",
                "countryCode": "HR"
            },
            {
                "name": "Ladimirevci",
                "countryCode": "HR"
            },
            {
                "name": "Laslovo",
                "countryCode": "HR"
            },
            {
                "name": "Magadenovac",
                "countryCode": "HR"
            },
            {
                "name": "Marijanci",
                "countryCode": "HR"
            },
            {
                "name": "Marjanci",
                "countryCode": "HR"
            },
            {
                "name": "Markovac Našički",
                "countryCode": "HR"
            },
            {
                "name": "Martin",
                "countryCode": "HR"
            },
            {
                "name": "Našice",
                "countryCode": "HR"
            },
            {
                "name": "Osijek",
                "countryCode": "HR"
            },
            {
                "name": "Petlovac",
                "countryCode": "HR"
            },
            {
                "name": "Petrijevci",
                "countryCode": "HR"
            },
            {
                "name": "Piškorevci",
                "countryCode": "HR"
            },
            {
                "name": "Podgorač",
                "countryCode": "HR"
            },
            {
                "name": "Podravska Moslavina",
                "countryCode": "HR"
            },
            {
                "name": "Sarvaš",
                "countryCode": "HR"
            },
            {
                "name": "Satnica Đakovačka",
                "countryCode": "HR"
            },
            {
                "name": "Semeljci",
                "countryCode": "HR"
            },
            {
                "name": "Strizivojna",
                "countryCode": "HR"
            },
            {
                "name": "Tenja",
                "countryCode": "HR"
            },
            {
                "name": "Valpovo",
                "countryCode": "HR"
            },
            {
                "name": "Velimirovac",
                "countryCode": "HR"
            },
            {
                "name": "Viljevo",
                "countryCode": "HR"
            },
            {
                "name": "Viškovci",
                "countryCode": "HR"
            },
            {
                "name": "Višnjevac",
                "countryCode": "HR"
            },
            {
                "name": "Vladislavci",
                "countryCode": "HR"
            },
            {
                "name": "Vuka",
                "countryCode": "HR"
            },
            {
                "name": "Čeminac",
                "countryCode": "HR"
            },
            {
                "name": "Čepin",
                "countryCode": "HR"
            },
            {
                "name": "Đakovo",
                "countryCode": "HR"
            },
            {
                "name": "Đurđenovac",
                "countryCode": "HR"
            },
            {
                "name": "Široko Polje",
                "countryCode": "HR"
            },
            {
                "name": "Šodolovci",
                "countryCode": "HR"
            },
            {
                "name": "Brodarica",
                "countryCode": "HR"
            },
            {
                "name": "Drniš",
                "countryCode": "HR"
            },
            {
                "name": "Grad Drniš",
                "countryCode": "HR"
            },
            {
                "name": "Grad Šibenik",
                "countryCode": "HR"
            },
            {
                "name": "Kistanje",
                "countryCode": "HR"
            },
            {
                "name": "Knin",
                "countryCode": "HR"
            },
            {
                "name": "Murter",
                "countryCode": "HR"
            },
            {
                "name": "Murter-Kornati",
                "countryCode": "HR"
            },
            {
                "name": "Pirovac",
                "countryCode": "HR"
            },
            {
                "name": "Primošten",
                "countryCode": "HR"
            },
            {
                "name": "Promina",
                "countryCode": "HR"
            },
            {
                "name": "Rogoznica",
                "countryCode": "HR"
            },
            {
                "name": "Rogoznica Općina",
                "countryCode": "HR"
            },
            {
                "name": "Skradin",
                "countryCode": "HR"
            },
            {
                "name": "Tisno",
                "countryCode": "HR"
            },
            {
                "name": "Tribunj",
                "countryCode": "HR"
            },
            {
                "name": "Vodice",
                "countryCode": "HR"
            },
            {
                "name": "Šibenik",
                "countryCode": "HR"
            },
            {
                "name": "Andrijaševci",
                "countryCode": "HR"
            },
            {
                "name": "Babina Greda",
                "countryCode": "HR"
            },
            {
                "name": "Bobota",
                "countryCode": "HR"
            },
            {
                "name": "Bogdanovci",
                "countryCode": "HR"
            },
            {
                "name": "Borovo",
                "countryCode": "HR"
            },
            {
                "name": "Borovo Selo",
                "countryCode": "HR"
            },
            {
                "name": "Bošnjaci",
                "countryCode": "HR"
            },
            {
                "name": "Bršadin",
                "countryCode": "HR"
            },
            {
                "name": "Cerić",
                "countryCode": "HR"
            },
            {
                "name": "Cerna",
                "countryCode": "HR"
            },
            {
                "name": "Drenovci",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vinkovci",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vukovar",
                "countryCode": "HR"
            },
            {
                "name": "Grad Županja",
                "countryCode": "HR"
            },
            {
                "name": "Gradište",
                "countryCode": "HR"
            },
            {
                "name": "Gunja",
                "countryCode": "HR"
            },
            {
                "name": "Ilok",
                "countryCode": "HR"
            },
            {
                "name": "Ivankovo",
                "countryCode": "HR"
            },
            {
                "name": "Jarmina",
                "countryCode": "HR"
            },
            {
                "name": "Komletinci",
                "countryCode": "HR"
            },
            {
                "name": "Lovas",
                "countryCode": "HR"
            },
            {
                "name": "Markušica",
                "countryCode": "HR"
            },
            {
                "name": "Mirkovci",
                "countryCode": "HR"
            },
            {
                "name": "Negoslavci",
                "countryCode": "HR"
            },
            {
                "name": "Nijemci",
                "countryCode": "HR"
            },
            {
                "name": "Nuštar",
                "countryCode": "HR"
            },
            {
                "name": "Otok",
                "countryCode": "HR"
            },
            {
                "name": "Privlaka",
                "countryCode": "HR"
            },
            {
                "name": "Retkovci",
                "countryCode": "HR"
            },
            {
                "name": "Rokovci",
                "countryCode": "HR"
            },
            {
                "name": "Soljani",
                "countryCode": "HR"
            },
            {
                "name": "Stari Jankovci",
                "countryCode": "HR"
            },
            {
                "name": "Tordinci",
                "countryCode": "HR"
            },
            {
                "name": "Tovarnik",
                "countryCode": "HR"
            },
            {
                "name": "Trpinja",
                "countryCode": "HR"
            },
            {
                "name": "Vinkovci",
                "countryCode": "HR"
            },
            {
                "name": "Vođinci",
                "countryCode": "HR"
            },
            {
                "name": "Vrbanja",
                "countryCode": "HR"
            },
            {
                "name": "Vukovar",
                "countryCode": "HR"
            },
            {
                "name": "Štitar",
                "countryCode": "HR"
            },
            {
                "name": "Županja",
                "countryCode": "HR"
            },
            {
                "name": "Baška Voda",
                "countryCode": "HR"
            },
            {
                "name": "Bol",
                "countryCode": "HR"
            },
            {
                "name": "Brela",
                "countryCode": "HR"
            },
            {
                "name": "Brnaze",
                "countryCode": "HR"
            },
            {
                "name": "Dicmo",
                "countryCode": "HR"
            },
            {
                "name": "Donji Vinjani",
                "countryCode": "HR"
            },
            {
                "name": "Dugi Rat Općina",
                "countryCode": "HR"
            },
            {
                "name": "Dugopolje",
                "countryCode": "HR"
            },
            {
                "name": "Duće",
                "countryCode": "HR"
            },
            {
                "name": "Gala",
                "countryCode": "HR"
            },
            {
                "name": "Grabovci",
                "countryCode": "HR"
            },
            {
                "name": "Grad Hvar",
                "countryCode": "HR"
            },
            {
                "name": "Grad Imotski",
                "countryCode": "HR"
            },
            {
                "name": "Grad Makarska",
                "countryCode": "HR"
            },
            {
                "name": "Grad Omiš",
                "countryCode": "HR"
            },
            {
                "name": "Grad Sinj",
                "countryCode": "HR"
            },
            {
                "name": "Grad Split",
                "countryCode": "HR"
            },
            {
                "name": "Grad Supetar",
                "countryCode": "HR"
            },
            {
                "name": "Grad Trogir",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vis",
                "countryCode": "HR"
            },
            {
                "name": "Grad Vrgorac",
                "countryCode": "HR"
            },
            {
                "name": "Gradac",
                "countryCode": "HR"
            },
            {
                "name": "Greda",
                "countryCode": "HR"
            },
            {
                "name": "Grubine",
                "countryCode": "HR"
            },
            {
                "name": "Hrvace",
                "countryCode": "HR"
            },
            {
                "name": "Hvar",
                "countryCode": "HR"
            },
            {
                "name": "Imotski",
                "countryCode": "HR"
            },
            {
                "name": "Jelsa",
                "countryCode": "HR"
            },
            {
                "name": "Jesenice",
                "countryCode": "HR"
            },
            {
                "name": "Kamen",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Gomilica",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Kambelovac",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Lukšić",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Novi",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Stari",
                "countryCode": "HR"
            },
            {
                "name": "Kaštel Štafilić",
                "countryCode": "HR"
            },
            {
                "name": "Kaštela",
                "countryCode": "HR"
            },
            {
                "name": "Klis",
                "countryCode": "HR"
            },
            {
                "name": "Komiža",
                "countryCode": "HR"
            },
            {
                "name": "Košute",
                "countryCode": "HR"
            },
            {
                "name": "Lokvičići",
                "countryCode": "HR"
            },
            {
                "name": "Lovreć",
                "countryCode": "HR"
            },
            {
                "name": "Makarska",
                "countryCode": "HR"
            },
            {
                "name": "Milna",
                "countryCode": "HR"
            },
            {
                "name": "Mravince",
                "countryCode": "HR"
            },
            {
                "name": "Muć",
                "countryCode": "HR"
            },
            {
                "name": "Nerežišća",
                "countryCode": "HR"
            },
            {
                "name": "Okrug",
                "countryCode": "HR"
            },
            {
                "name": "Okrug Gornji",
                "countryCode": "HR"
            },
            {
                "name": "Omiš",
                "countryCode": "HR"
            },
            {
                "name": "Otok",
                "countryCode": "HR"
            },
            {
                "name": "Podstrana",
                "countryCode": "HR"
            },
            {
                "name": "Postira",
                "countryCode": "HR"
            },
            {
                "name": "Proložac",
                "countryCode": "HR"
            },
            {
                "name": "Pučišća",
                "countryCode": "HR"
            },
            {
                "name": "Runović",
                "countryCode": "HR"
            },
            {
                "name": "Runovići",
                "countryCode": "HR"
            },
            {
                "name": "Seget",
                "countryCode": "HR"
            },
            {
                "name": "Seget Vranjica",
                "countryCode": "HR"
            },
            {
                "name": "Selca",
                "countryCode": "HR"
            },
            {
                "name": "Sinj",
                "countryCode": "HR"
            },
            {
                "name": "Slatine",
                "countryCode": "HR"
            },
            {
                "name": "Solin",
                "countryCode": "HR"
            },
            {
                "name": "Split",
                "countryCode": "HR"
            },
            {
                "name": "Srinjine",
                "countryCode": "HR"
            },
            {
                "name": "Stari Grad",
                "countryCode": "HR"
            },
            {
                "name": "Stobreč",
                "countryCode": "HR"
            },
            {
                "name": "Supetar",
                "countryCode": "HR"
            },
            {
                "name": "Trilj",
                "countryCode": "HR"
            },
            {
                "name": "Trogir",
                "countryCode": "HR"
            },
            {
                "name": "Turjaci",
                "countryCode": "HR"
            },
            {
                "name": "Tučepi",
                "countryCode": "HR"
            },
            {
                "name": "Veliki Drvenik",
                "countryCode": "HR"
            },
            {
                "name": "Vis",
                "countryCode": "HR"
            },
            {
                "name": "Vranjic",
                "countryCode": "HR"
            },
            {
                "name": "Vrgorac",
                "countryCode": "HR"
            },
            {
                "name": "Vrlika",
                "countryCode": "HR"
            },
            {
                "name": "Zagvozd",
                "countryCode": "HR"
            },
            {
                "name": "Zmijavci",
                "countryCode": "HR"
            },
            {
                "name": "Šolta",
                "countryCode": "HR"
            },
            {
                "name": "Žrnovnica",
                "countryCode": "HR"
            },
            {
                "name": "Bale",
                "countryCode": "HR"
            },
            {
                "name": "Bale-Valle",
                "countryCode": "HR"
            },
            {
                "name": "Brtonigla",
                "countryCode": "HR"
            },
            {
                "name": "Brtonigla-Verteneglio",
                "countryCode": "HR"
            },
            {
                "name": "Buje",
                "countryCode": "HR"
            },
            {
                "name": "Buje-Buie",
                "countryCode": "HR"
            },
            {
                "name": "Buzet",
                "countryCode": "HR"
            },
            {
                "name": "Fažana",
                "countryCode": "HR"
            },
            {
                "name": "Fažana-Fasana",
                "countryCode": "HR"
            },
            {
                "name": "Funtana",
                "countryCode": "HR"
            },
            {
                "name": "Funtana-Fontane",
                "countryCode": "HR"
            },
            {
                "name": "Galižana",
                "countryCode": "HR"
            },
            {
                "name": "Grad Buzet",
                "countryCode": "HR"
            },
            {
                "name": "Grad Labin",
                "countryCode": "HR"
            },
            {
                "name": "Grad Pazin",
                "countryCode": "HR"
            },
            {
                "name": "Grožnjan",
                "countryCode": "HR"
            },
            {
                "name": "Grožnjan-Grisignana",
                "countryCode": "HR"
            },
            {
                "name": "Kanfanar",
                "countryCode": "HR"
            },
            {
                "name": "Karojba",
                "countryCode": "HR"
            },
            {
                "name": "Kaštelir-Labinci",
                "countryCode": "HR"
            },
            {
                "name": "Labin",
                "countryCode": "HR"
            },
            {
                "name": "Ližnjan",
                "countryCode": "HR"
            },
            {
                "name": "Ližnjan-Lisignano",
                "countryCode": "HR"
            },
            {
                "name": "Lupoglav",
                "countryCode": "HR"
            },
            {
                "name": "Marčana",
                "countryCode": "HR"
            },
            {
                "name": "Medulin",
                "countryCode": "HR"
            },
            {
                "name": "Motovun",
                "countryCode": "HR"
            },
            {
                "name": "Motovun-Montona",
                "countryCode": "HR"
            },
            {
                "name": "Novigrad",
                "countryCode": "HR"
            },
            {
                "name": "Novigrad-Cittanova",
                "countryCode": "HR"
            },
            {
                "name": "Oprtalj-Portole",
                "countryCode": "HR"
            },
            {
                "name": "Općina Lanišće",
                "countryCode": "HR"
            },
            {
                "name": "Pazin",
                "countryCode": "HR"
            },
            {
                "name": "Poreč",
                "countryCode": "HR"
            },
            {
                "name": "Poreč-Parenzo",
                "countryCode": "HR"
            },
            {
                "name": "Pula",
                "countryCode": "HR"
            },
            {
                "name": "Pula-Pola",
                "countryCode": "HR"
            },
            {
                "name": "Rabac",
                "countryCode": "HR"
            },
            {
                "name": "Raša",
                "countryCode": "HR"
            },
            {
                "name": "Rovinj",
                "countryCode": "HR"
            },
            {
                "name": "Rovinj-Rovigno",
                "countryCode": "HR"
            },
            {
                "name": "Sveta Nedelja",
                "countryCode": "HR"
            },
            {
                "name": "Sveti Lovreč",
                "countryCode": "HR"
            },
            {
                "name": "Tar",
                "countryCode": "HR"
            },
            {
                "name": "Tar-Vabriga-Torre Abrega",
                "countryCode": "HR"
            },
            {
                "name": "Umag",
                "countryCode": "HR"
            },
            {
                "name": "Umag-Umago",
                "countryCode": "HR"
            },
            {
                "name": "Valbandon",
                "countryCode": "HR"
            },
            {
                "name": "Vinež",
                "countryCode": "HR"
            },
            {
                "name": "Višnjan-Visignano",
                "countryCode": "HR"
            },
            {
                "name": "Vižinada-Visinada",
                "countryCode": "HR"
            },
            {
                "name": "Vodnjan",
                "countryCode": "HR"
            },
            {
                "name": "Vodnjan-Dignano",
                "countryCode": "HR"
            },
            {
                "name": "Vrsar",
                "countryCode": "HR"
            },
            {
                "name": "Vrsar-Orsera",
                "countryCode": "HR"
            },
            {
                "name": "Žminj",
                "countryCode": "HR"
            },
            {
                "name": "Cavtat",
                "countryCode": "HR"
            },
            {
                "name": "Dubrovačko primorje",
                "countryCode": "HR"
            },
            {
                "name": "Grad Dubrovnik",
                "countryCode": "HR"
            },
            {
                "name": "Grad Korčula",
                "countryCode": "HR"
            },
            {
                "name": "Grad Ploče",
                "countryCode": "HR"
            },
            {
                "name": "Komin",
                "countryCode": "HR"
            },
            {
                "name": "Konavle",
                "countryCode": "HR"
            },
            {
                "name": "Korčula",
                "countryCode": "HR"
            },
            {
                "name": "Lastovo",
                "countryCode": "HR"
            },
            {
                "name": "Lumbarda",
                "countryCode": "HR"
            },
            {
                "name": "Metković",
                "countryCode": "HR"
            },
            {
                "name": "Mljet",
                "countryCode": "HR"
            },
            {
                "name": "Mokošica",
                "countryCode": "HR"
            },
            {
                "name": "Opuzen",
                "countryCode": "HR"
            },
            {
                "name": "Općina Lastovo",
                "countryCode": "HR"
            },
            {
                "name": "Orebić",
                "countryCode": "HR"
            },
            {
                "name": "Podgora",
                "countryCode": "HR"
            },
            {
                "name": "Pojezerje",
                "countryCode": "HR"
            },
            {
                "name": "Slivno",
                "countryCode": "HR"
            },
            {
                "name": "Smokvica",
                "countryCode": "HR"
            },
            {
                "name": "Ston",
                "countryCode": "HR"
            },
            {
                "name": "Vela Luka",
                "countryCode": "HR"
            },
            {
                "name": "Zažablje",
                "countryCode": "HR"
            },
            {
                "name": "Čibača",
                "countryCode": "HR"
            },
            {
                "name": "Žrnovo",
                "countryCode": "HR"
            },
            {
                "name": "Župa dubrovačka",
                "countryCode": "HR"
            },
            {
                "name": "Belica",
                "countryCode": "HR"
            },
            {
                "name": "Dekanovec",
                "countryCode": "HR"
            },
            {
                "name": "Domašinec",
                "countryCode": "HR"
            },
            {
                "name": "Goričan",
                "countryCode": "HR"
            },
            {
                "name": "Grad Čakovec",
                "countryCode": "HR"
            },
            {
                "name": "Hodošan",
                "countryCode": "HR"
            },
            {
                "name": "Ivanovec",
                "countryCode": "HR"
            },
            {
                "name": "Kotoriba",
                "countryCode": "HR"
            },
            {
                "name": "Kuršanec",
                "countryCode": "HR"
            },
            {
                "name": "Lopatinec",
                "countryCode": "HR"
            },
            {
                "name": "Mala Subotica",
                "countryCode": "HR"
            },
            {
                "name": "Mačkovec",
                "countryCode": "HR"
            },
            {
                "name": "Mihovljan",
                "countryCode": "HR"
            },
            {
                "name": "Mursko Središće",
                "countryCode": "HR"
            },
            {
                "name": "Nedelišće",
                "countryCode": "HR"
            },
            {
                "name": "Novo Selo Rok",
                "countryCode": "HR"
            },
            {
                "name": "Orehovica",
                "countryCode": "HR"
            },
            {
                "name": "Peklenica",
                "countryCode": "HR"
            },
            {
                "name": "Podturen",
                "countryCode": "HR"
            },
            {
                "name": "Prelog",
                "countryCode": "HR"
            },
            {
                "name": "Pribislavec",
                "countryCode": "HR"
            },
            {
                "name": "Strahoninec",
                "countryCode": "HR"
            },
            {
                "name": "Sveti Juraj na Bregu",
                "countryCode": "HR"
            },
            {
                "name": "Vratišinec",
                "countryCode": "HR"
            },
            {
                "name": "Čakovec",
                "countryCode": "HR"
            },
            {
                "name": "Šenkovec",
                "countryCode": "HR"
            },
            {
                "name": "Brezovica",
                "countryCode": "HR"
            },
            {
                "name": "Centar",
                "countryCode": "HR"
            },
            {
                "name": "Dubrava",
                "countryCode": "HR"
            },
            {
                "name": "Gradska četvrt Donji grad",
                "countryCode": "HR"
            },
            {
                "name": "Gradska četvrt Gornji Grad - Medvescak",
                "countryCode": "HR"
            },
            {
                "name": "Gradska četvrt Podsljeme",
                "countryCode": "HR"
            },
            {
                "name": "Horvati",
                "countryCode": "HR"
            },
            {
                "name": "Jankomir",
                "countryCode": "HR"
            },
            {
                "name": "Ježdovec",
                "countryCode": "HR"
            },
            {
                "name": "Kašina",
                "countryCode": "HR"
            },
            {
                "name": "Lučko",
                "countryCode": "HR"
            },
            {
                "name": "Novi Zagreb",
                "countryCode": "HR"
            },
            {
                "name": "Odra",
                "countryCode": "HR"
            },
            {
                "name": "Sesvete",
                "countryCode": "HR"
            },
            {
                "name": "Stenjevec",
                "countryCode": "HR"
            },
            {
                "name": "Strmec",
                "countryCode": "HR"
            },
            {
                "name": "Zadvorsko",
                "countryCode": "HR"
            },
            {
                "name": "Zagreb",
                "countryCode": "HR"
            }
        ]
    },
    {
        "name": "Cuba",
        "phonecode": "53",
        "cities": [
            {
                "name": "Consolación del Sur",
                "countryCode": "CU"
            },
            {
                "name": "Guane",
                "countryCode": "CU"
            },
            {
                "name": "Los Palacios",
                "countryCode": "CU"
            },
            {
                "name": "Mantua",
                "countryCode": "CU"
            },
            {
                "name": "Minas de Matahambre",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Consolación del Sur",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Guane",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de La Palma",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Los Palacios",
                "countryCode": "CU"
            },
            {
                "name": "Pinar del Río",
                "countryCode": "CU"
            },
            {
                "name": "Puerto Esperanza",
                "countryCode": "CU"
            },
            {
                "name": "San Diego de Los Baños",
                "countryCode": "CU"
            },
            {
                "name": "San Luis",
                "countryCode": "CU"
            },
            {
                "name": "Viñales",
                "countryCode": "CU"
            },
            {
                "name": "Alamar",
                "countryCode": "CU"
            },
            {
                "name": "Arroyo Naranjo",
                "countryCode": "CU"
            },
            {
                "name": "Boyeros",
                "countryCode": "CU"
            },
            {
                "name": "Centro Habana",
                "countryCode": "CU"
            },
            {
                "name": "Cerro",
                "countryCode": "CU"
            },
            {
                "name": "Diez de Octubre",
                "countryCode": "CU"
            },
            {
                "name": "Guanabacoa",
                "countryCode": "CU"
            },
            {
                "name": "Habana del Este",
                "countryCode": "CU"
            },
            {
                "name": "Havana",
                "countryCode": "CU"
            },
            {
                "name": "La Habana Vieja",
                "countryCode": "CU"
            },
            {
                "name": "Regla",
                "countryCode": "CU"
            },
            {
                "name": "San Miguel del Padrón",
                "countryCode": "CU"
            },
            {
                "name": "Santiago de las Vegas",
                "countryCode": "CU"
            },
            {
                "name": "Alacranes",
                "countryCode": "CU"
            },
            {
                "name": "Bolondrón",
                "countryCode": "CU"
            },
            {
                "name": "Calimete",
                "countryCode": "CU"
            },
            {
                "name": "Colón",
                "countryCode": "CU"
            },
            {
                "name": "Cárdenas",
                "countryCode": "CU"
            },
            {
                "name": "Jagüey Grande",
                "countryCode": "CU"
            },
            {
                "name": "Jovellanos",
                "countryCode": "CU"
            },
            {
                "name": "Limonar",
                "countryCode": "CU"
            },
            {
                "name": "Los Arabos",
                "countryCode": "CU"
            },
            {
                "name": "Manguito",
                "countryCode": "CU"
            },
            {
                "name": "Martí",
                "countryCode": "CU"
            },
            {
                "name": "Matanzas",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Cárdenas",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Matanzas",
                "countryCode": "CU"
            },
            {
                "name": "Pedro Betancourt",
                "countryCode": "CU"
            },
            {
                "name": "Perico",
                "countryCode": "CU"
            },
            {
                "name": "Unión de Reyes",
                "countryCode": "CU"
            },
            {
                "name": "Varadero",
                "countryCode": "CU"
            },
            {
                "name": "Caibarién",
                "countryCode": "CU"
            },
            {
                "name": "Calabazar de Sagua",
                "countryCode": "CU"
            },
            {
                "name": "Camajuaní",
                "countryCode": "CU"
            },
            {
                "name": "Cifuentes",
                "countryCode": "CU"
            },
            {
                "name": "Corralillo",
                "countryCode": "CU"
            },
            {
                "name": "Encrucijada",
                "countryCode": "CU"
            },
            {
                "name": "Esperanza",
                "countryCode": "CU"
            },
            {
                "name": "Isabela de Sagua",
                "countryCode": "CU"
            },
            {
                "name": "Manicaragua",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Placetas",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Santa Clara",
                "countryCode": "CU"
            },
            {
                "name": "Placetas",
                "countryCode": "CU"
            },
            {
                "name": "Quemado de Güines",
                "countryCode": "CU"
            },
            {
                "name": "Rancho Veloz",
                "countryCode": "CU"
            },
            {
                "name": "Ranchuelo",
                "countryCode": "CU"
            },
            {
                "name": "Sagua la Grande",
                "countryCode": "CU"
            },
            {
                "name": "Santa Clara",
                "countryCode": "CU"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "CU"
            },
            {
                "name": "Abreus",
                "countryCode": "CU"
            },
            {
                "name": "Aguada de Pasajeros",
                "countryCode": "CU"
            },
            {
                "name": "Cienfuegos",
                "countryCode": "CU"
            },
            {
                "name": "Cruces",
                "countryCode": "CU"
            },
            {
                "name": "Cumanayagua",
                "countryCode": "CU"
            },
            {
                "name": "Lajas",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Abreus",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Cienfuegos",
                "countryCode": "CU"
            },
            {
                "name": "Palmira",
                "countryCode": "CU"
            },
            {
                "name": "Rodas",
                "countryCode": "CU"
            },
            {
                "name": "Cabaiguán",
                "countryCode": "CU"
            },
            {
                "name": "Condado",
                "countryCode": "CU"
            },
            {
                "name": "Fomento",
                "countryCode": "CU"
            },
            {
                "name": "Guayos",
                "countryCode": "CU"
            },
            {
                "name": "Jatibonico",
                "countryCode": "CU"
            },
            {
                "name": "La Sierpe",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Cabaiguán",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Jatibonico",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Sancti Spíritus",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Trinidad",
                "countryCode": "CU"
            },
            {
                "name": "Sancti Spíritus",
                "countryCode": "CU"
            },
            {
                "name": "Topes de Collantes",
                "countryCode": "CU"
            },
            {
                "name": "Trinidad",
                "countryCode": "CU"
            },
            {
                "name": "Yaguajay",
                "countryCode": "CU"
            },
            {
                "name": "Zaza del Medio",
                "countryCode": "CU"
            },
            {
                "name": "Baraguá",
                "countryCode": "CU"
            },
            {
                "name": "Chambas",
                "countryCode": "CU"
            },
            {
                "name": "Ciego de Ávila",
                "countryCode": "CU"
            },
            {
                "name": "Ciro Redondo",
                "countryCode": "CU"
            },
            {
                "name": "Florencia",
                "countryCode": "CU"
            },
            {
                "name": "Morón",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Ciego de Ávila",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Morón",
                "countryCode": "CU"
            },
            {
                "name": "Primero de Enero",
                "countryCode": "CU"
            },
            {
                "name": "Venezuela",
                "countryCode": "CU"
            },
            {
                "name": "Camagüey",
                "countryCode": "CU"
            },
            {
                "name": "El Caney",
                "countryCode": "CU"
            },
            {
                "name": "Esmeralda",
                "countryCode": "CU"
            },
            {
                "name": "Florida",
                "countryCode": "CU"
            },
            {
                "name": "Guáimaro",
                "countryCode": "CU"
            },
            {
                "name": "Jimaguayú",
                "countryCode": "CU"
            },
            {
                "name": "Minas",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Florida",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Nuevitas",
                "countryCode": "CU"
            },
            {
                "name": "Nuevitas",
                "countryCode": "CU"
            },
            {
                "name": "Santa Cruz del Sur",
                "countryCode": "CU"
            },
            {
                "name": "Sibanicú",
                "countryCode": "CU"
            },
            {
                "name": "Vertientes",
                "countryCode": "CU"
            },
            {
                "name": "Amancio",
                "countryCode": "CU"
            },
            {
                "name": "Colombia",
                "countryCode": "CU"
            },
            {
                "name": "Jesús Menéndez",
                "countryCode": "CU"
            },
            {
                "name": "Jobabo",
                "countryCode": "CU"
            },
            {
                "name": "Las Tunas",
                "countryCode": "CU"
            },
            {
                "name": "Manatí",
                "countryCode": "CU"
            },
            {
                "name": "Puerto Padre",
                "countryCode": "CU"
            },
            {
                "name": "Banes",
                "countryCode": "CU"
            },
            {
                "name": "Cacocum",
                "countryCode": "CU"
            },
            {
                "name": "Cueto",
                "countryCode": "CU"
            },
            {
                "name": "Gibara",
                "countryCode": "CU"
            },
            {
                "name": "Holguín",
                "countryCode": "CU"
            },
            {
                "name": "Jobabo",
                "countryCode": "CU"
            },
            {
                "name": "Moa",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Banes",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Holguín",
                "countryCode": "CU"
            },
            {
                "name": "Sagua de Tánamo",
                "countryCode": "CU"
            },
            {
                "name": "San Germán",
                "countryCode": "CU"
            },
            {
                "name": "Bartolomé Masó",
                "countryCode": "CU"
            },
            {
                "name": "Bayamo",
                "countryCode": "CU"
            },
            {
                "name": "Campechuela",
                "countryCode": "CU"
            },
            {
                "name": "Cauto Cristo",
                "countryCode": "CU"
            },
            {
                "name": "Guisa",
                "countryCode": "CU"
            },
            {
                "name": "Jiguaní",
                "countryCode": "CU"
            },
            {
                "name": "Manzanillo",
                "countryCode": "CU"
            },
            {
                "name": "Media Luna",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Bayamo",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Manzanillo",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Niquero",
                "countryCode": "CU"
            },
            {
                "name": "Niquero",
                "countryCode": "CU"
            },
            {
                "name": "Río Cauto",
                "countryCode": "CU"
            },
            {
                "name": "Yara",
                "countryCode": "CU"
            },
            {
                "name": "Contramaestre",
                "countryCode": "CU"
            },
            {
                "name": "El Cobre",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Palma Soriano",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Santiago de Cuba",
                "countryCode": "CU"
            },
            {
                "name": "Palma Soriano",
                "countryCode": "CU"
            },
            {
                "name": "San Luis",
                "countryCode": "CU"
            },
            {
                "name": "Santiago de Cuba",
                "countryCode": "CU"
            },
            {
                "name": "Baracoa",
                "countryCode": "CU"
            },
            {
                "name": "Guantánamo",
                "countryCode": "CU"
            },
            {
                "name": "Maisí",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Guantánamo",
                "countryCode": "CU"
            },
            {
                "name": "Río Guayabal de Yateras",
                "countryCode": "CU"
            },
            {
                "name": "San Antonio del Sur",
                "countryCode": "CU"
            },
            {
                "name": "Alquízar",
                "countryCode": "CU"
            },
            {
                "name": "Artemisa",
                "countryCode": "CU"
            },
            {
                "name": "Bahía Honda",
                "countryCode": "CU"
            },
            {
                "name": "Bauta",
                "countryCode": "CU"
            },
            {
                "name": "Cabañas",
                "countryCode": "CU"
            },
            {
                "name": "Candelaria",
                "countryCode": "CU"
            },
            {
                "name": "Guanajay",
                "countryCode": "CU"
            },
            {
                "name": "Güira de Melena",
                "countryCode": "CU"
            },
            {
                "name": "Mariel",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Artemisa",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Bauta",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Guanajay",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Mariel",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de San Cristóbal",
                "countryCode": "CU"
            },
            {
                "name": "San Antonio de los Baños",
                "countryCode": "CU"
            },
            {
                "name": "San Cristobal",
                "countryCode": "CU"
            },
            {
                "name": "Soroa",
                "countryCode": "CU"
            },
            {
                "name": "Batabanó",
                "countryCode": "CU"
            },
            {
                "name": "Bejucal",
                "countryCode": "CU"
            },
            {
                "name": "Güines",
                "countryCode": "CU"
            },
            {
                "name": "Jamaica",
                "countryCode": "CU"
            },
            {
                "name": "Jaruco",
                "countryCode": "CU"
            },
            {
                "name": "La Salud",
                "countryCode": "CU"
            },
            {
                "name": "Madruga",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Güines",
                "countryCode": "CU"
            },
            {
                "name": "Municipio de Melena del Sur",
                "countryCode": "CU"
            },
            {
                "name": "Quivicán",
                "countryCode": "CU"
            },
            {
                "name": "San José de las Lajas",
                "countryCode": "CU"
            },
            {
                "name": "San Nicolás de Bari",
                "countryCode": "CU"
            },
            {
                "name": "Santa Cruz del Norte",
                "countryCode": "CU"
            },
            {
                "name": "Nueva Gerona",
                "countryCode": "CU"
            }
        ]
    },
    {
        "name": "Cyprus",
        "phonecode": "357",
        "cities": [
            {
                "name": "Akáki",
                "countryCode": "CY"
            },
            {
                "name": "Alámpra",
                "countryCode": "CY"
            },
            {
                "name": "Aredioú",
                "countryCode": "CY"
            },
            {
                "name": "Astromerítis",
                "countryCode": "CY"
            },
            {
                "name": "Dáli",
                "countryCode": "CY"
            },
            {
                "name": "Ergátes",
                "countryCode": "CY"
            },
            {
                "name": "Géri",
                "countryCode": "CY"
            },
            {
                "name": "Kakopetriá",
                "countryCode": "CY"
            },
            {
                "name": "Klírou",
                "countryCode": "CY"
            },
            {
                "name": "Kokkinotrimithiá",
                "countryCode": "CY"
            },
            {
                "name": "Káto Defterá",
                "countryCode": "CY"
            },
            {
                "name": "Káto Pýrgos",
                "countryCode": "CY"
            },
            {
                "name": "Lythrodóntas",
                "countryCode": "CY"
            },
            {
                "name": "Léfka",
                "countryCode": "CY"
            },
            {
                "name": "Lýmpia",
                "countryCode": "CY"
            },
            {
                "name": "Mámmari",
                "countryCode": "CY"
            },
            {
                "name": "Méniko",
                "countryCode": "CY"
            },
            {
                "name": "Mórfou",
                "countryCode": "CY"
            },
            {
                "name": "Nicosia",
                "countryCode": "CY"
            },
            {
                "name": "Nicosia Municipality",
                "countryCode": "CY"
            },
            {
                "name": "Peristeróna",
                "countryCode": "CY"
            },
            {
                "name": "Psimolofou",
                "countryCode": "CY"
            },
            {
                "name": "Páno Defterá",
                "countryCode": "CY"
            },
            {
                "name": "Péra",
                "countryCode": "CY"
            },
            {
                "name": "Tséri",
                "countryCode": "CY"
            },
            {
                "name": "Erími",
                "countryCode": "CY"
            },
            {
                "name": "Germasógeia",
                "countryCode": "CY"
            },
            {
                "name": "Kyperoúnta",
                "countryCode": "CY"
            },
            {
                "name": "Lemesós",
                "countryCode": "CY"
            },
            {
                "name": "Limassol",
                "countryCode": "CY"
            },
            {
                "name": "Mouttagiáka",
                "countryCode": "CY"
            },
            {
                "name": "Parekklisha",
                "countryCode": "CY"
            },
            {
                "name": "Peléndri",
                "countryCode": "CY"
            },
            {
                "name": "Pissoúri",
                "countryCode": "CY"
            },
            {
                "name": "Pyrgos",
                "countryCode": "CY"
            },
            {
                "name": "Páchna",
                "countryCode": "CY"
            },
            {
                "name": "Páno Polemídia",
                "countryCode": "CY"
            },
            {
                "name": "Sotíra",
                "countryCode": "CY"
            },
            {
                "name": "Soúni-Zanakiá",
                "countryCode": "CY"
            },
            {
                "name": "Ágios Tomás",
                "countryCode": "CY"
            },
            {
                "name": "Ýpsonas",
                "countryCode": "CY"
            },
            {
                "name": "Aradíppou",
                "countryCode": "CY"
            },
            {
                "name": "Athíenou",
                "countryCode": "CY"
            },
            {
                "name": "Dhromolaxia",
                "countryCode": "CY"
            },
            {
                "name": "Kofínou",
                "countryCode": "CY"
            },
            {
                "name": "Kolossi",
                "countryCode": "CY"
            },
            {
                "name": "Kíti",
                "countryCode": "CY"
            },
            {
                "name": "Kórnos",
                "countryCode": "CY"
            },
            {
                "name": "Larnaca",
                "countryCode": "CY"
            },
            {
                "name": "Livádia",
                "countryCode": "CY"
            },
            {
                "name": "Meneou",
                "countryCode": "CY"
            },
            {
                "name": "Mosfilotí",
                "countryCode": "CY"
            },
            {
                "name": "Perivólia",
                "countryCode": "CY"
            },
            {
                "name": "Psevdás",
                "countryCode": "CY"
            },
            {
                "name": "Pérgamos",
                "countryCode": "CY"
            },
            {
                "name": "Pýla",
                "countryCode": "CY"
            },
            {
                "name": "Tersefánou",
                "countryCode": "CY"
            },
            {
                "name": "Troúlloi",
                "countryCode": "CY"
            },
            {
                "name": "Voróklini",
                "countryCode": "CY"
            },
            {
                "name": "Xylofágou",
                "countryCode": "CY"
            },
            {
                "name": "Xylotymbou",
                "countryCode": "CY"
            },
            {
                "name": "Ágios Týchon",
                "countryCode": "CY"
            },
            {
                "name": "Acherítou",
                "countryCode": "CY"
            },
            {
                "name": "Ammochostos Municipality",
                "countryCode": "CY"
            },
            {
                "name": "Avgórou",
                "countryCode": "CY"
            },
            {
                "name": "Ayia Napa",
                "countryCode": "CY"
            },
            {
                "name": "Derýneia",
                "countryCode": "CY"
            },
            {
                "name": "Famagusta",
                "countryCode": "CY"
            },
            {
                "name": "Frénaros",
                "countryCode": "CY"
            },
            {
                "name": "Lefkónoiko",
                "countryCode": "CY"
            },
            {
                "name": "Leonárisso",
                "countryCode": "CY"
            },
            {
                "name": "Liopétri",
                "countryCode": "CY"
            },
            {
                "name": "Paralímni",
                "countryCode": "CY"
            },
            {
                "name": "Protaras",
                "countryCode": "CY"
            },
            {
                "name": "Rizokárpaso",
                "countryCode": "CY"
            },
            {
                "name": "Tríkomo",
                "countryCode": "CY"
            },
            {
                "name": "Áchna",
                "countryCode": "CY"
            },
            {
                "name": "Argáka",
                "countryCode": "CY"
            },
            {
                "name": "Chlórakas",
                "countryCode": "CY"
            },
            {
                "name": "Emba",
                "countryCode": "CY"
            },
            {
                "name": "Geroskipou",
                "countryCode": "CY"
            },
            {
                "name": "Geroskípou (quarter)",
                "countryCode": "CY"
            },
            {
                "name": "Geroskípou Municipality",
                "countryCode": "CY"
            },
            {
                "name": "Kissonerga",
                "countryCode": "CY"
            },
            {
                "name": "Koloni",
                "countryCode": "CY"
            },
            {
                "name": "Konia",
                "countryCode": "CY"
            },
            {
                "name": "Mesógi",
                "countryCode": "CY"
            },
            {
                "name": "Paphos",
                "countryCode": "CY"
            },
            {
                "name": "Pégeia",
                "countryCode": "CY"
            },
            {
                "name": "Pólis",
                "countryCode": "CY"
            },
            {
                "name": "Tsáda",
                "countryCode": "CY"
            },
            {
                "name": "Tála",
                "countryCode": "CY"
            },
            {
                "name": "Kyrenia",
                "countryCode": "CY"
            },
            {
                "name": "Kyrenia Municipality",
                "countryCode": "CY"
            },
            {
                "name": "Lápithos",
                "countryCode": "CY"
            }
        ]
    },
    {
        "name": "Czech Republic",
        "phonecode": "420",
        "cities": [
            {
                "name": "Braník",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Počernice",
                "countryCode": "CZ"
            },
            {
                "name": "Hodkovičky",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Počernice",
                "countryCode": "CZ"
            },
            {
                "name": "Hostavice",
                "countryCode": "CZ"
            },
            {
                "name": "Karlín",
                "countryCode": "CZ"
            },
            {
                "name": "Kbely",
                "countryCode": "CZ"
            },
            {
                "name": "Letňany",
                "countryCode": "CZ"
            },
            {
                "name": "Libeň",
                "countryCode": "CZ"
            },
            {
                "name": "Malá Strana",
                "countryCode": "CZ"
            },
            {
                "name": "Modřany",
                "countryCode": "CZ"
            },
            {
                "name": "Prague",
                "countryCode": "CZ"
            },
            {
                "name": "Praha 1",
                "countryCode": "CZ"
            },
            {
                "name": "Praha 16",
                "countryCode": "CZ"
            },
            {
                "name": "Praha 20",
                "countryCode": "CZ"
            },
            {
                "name": "Praha 21",
                "countryCode": "CZ"
            },
            {
                "name": "Prosek",
                "countryCode": "CZ"
            },
            {
                "name": "Satalice",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Město",
                "countryCode": "CZ"
            },
            {
                "name": "Střížkov",
                "countryCode": "CZ"
            },
            {
                "name": "Vysehrad",
                "countryCode": "CZ"
            },
            {
                "name": "Vysočany",
                "countryCode": "CZ"
            },
            {
                "name": "Černý Most",
                "countryCode": "CZ"
            },
            {
                "name": "Bakov nad Jizerou",
                "countryCode": "CZ"
            },
            {
                "name": "Benešov",
                "countryCode": "CZ"
            },
            {
                "name": "Benátky nad Jizerou",
                "countryCode": "CZ"
            },
            {
                "name": "Beroun",
                "countryCode": "CZ"
            },
            {
                "name": "Bohutín",
                "countryCode": "CZ"
            },
            {
                "name": "Brandýs nad Labem-Stará Boleslav",
                "countryCode": "CZ"
            },
            {
                "name": "Brandýsek",
                "countryCode": "CZ"
            },
            {
                "name": "Buštěhrad",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřice",
                "countryCode": "CZ"
            },
            {
                "name": "Byšice",
                "countryCode": "CZ"
            },
            {
                "name": "Bělá pod Bezdězem",
                "countryCode": "CZ"
            },
            {
                "name": "Březnice",
                "countryCode": "CZ"
            },
            {
                "name": "Cerhenice",
                "countryCode": "CZ"
            },
            {
                "name": "Chyňava",
                "countryCode": "CZ"
            },
            {
                "name": "Církvice",
                "countryCode": "CZ"
            },
            {
                "name": "Cítov",
                "countryCode": "CZ"
            },
            {
                "name": "Davle",
                "countryCode": "CZ"
            },
            {
                "name": "Divišov",
                "countryCode": "CZ"
            },
            {
                "name": "Dobrovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dobřichovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dobříš",
                "countryCode": "CZ"
            },
            {
                "name": "Doksy",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Beřkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Bousov",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Břežany",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Počaply",
                "countryCode": "CZ"
            },
            {
                "name": "Horoměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Hostivice",
                "countryCode": "CZ"
            },
            {
                "name": "Hostomice",
                "countryCode": "CZ"
            },
            {
                "name": "Hovorčovice",
                "countryCode": "CZ"
            },
            {
                "name": "Hořovice",
                "countryCode": "CZ"
            },
            {
                "name": "Hradištko",
                "countryCode": "CZ"
            },
            {
                "name": "Hudlice",
                "countryCode": "CZ"
            },
            {
                "name": "Hýskov",
                "countryCode": "CZ"
            },
            {
                "name": "Hřebeč",
                "countryCode": "CZ"
            },
            {
                "name": "Jeneč",
                "countryCode": "CZ"
            },
            {
                "name": "Jesenice",
                "countryCode": "CZ"
            },
            {
                "name": "Jince",
                "countryCode": "CZ"
            },
            {
                "name": "Jirny",
                "countryCode": "CZ"
            },
            {
                "name": "Jílové u Prahy",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenice",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenné Žehrovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenný Přívoz",
                "countryCode": "CZ"
            },
            {
                "name": "Kačice",
                "countryCode": "CZ"
            },
            {
                "name": "Kladno",
                "countryCode": "CZ"
            },
            {
                "name": "Klecany",
                "countryCode": "CZ"
            },
            {
                "name": "Klobuky",
                "countryCode": "CZ"
            },
            {
                "name": "Kněžmost",
                "countryCode": "CZ"
            },
            {
                "name": "Kolín",
                "countryCode": "CZ"
            },
            {
                "name": "Komárov",
                "countryCode": "CZ"
            },
            {
                "name": "Kosmonosy",
                "countryCode": "CZ"
            },
            {
                "name": "Kosova Hora",
                "countryCode": "CZ"
            },
            {
                "name": "Kostelec nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Kostelec nad Černými Lesy",
                "countryCode": "CZ"
            },
            {
                "name": "Kouřim",
                "countryCode": "CZ"
            },
            {
                "name": "Kralupy nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Králův Dvůr",
                "countryCode": "CZ"
            },
            {
                "name": "Krásná Hora nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Kutná Hora",
                "countryCode": "CZ"
            },
            {
                "name": "Křinec",
                "countryCode": "CZ"
            },
            {
                "name": "Libice nad Cidlinou",
                "countryCode": "CZ"
            },
            {
                "name": "Libiš",
                "countryCode": "CZ"
            },
            {
                "name": "Libušín",
                "countryCode": "CZ"
            },
            {
                "name": "Libčice nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Lochovice",
                "countryCode": "CZ"
            },
            {
                "name": "Loděnice",
                "countryCode": "CZ"
            },
            {
                "name": "Loučeň",
                "countryCode": "CZ"
            },
            {
                "name": "Luštěnice",
                "countryCode": "CZ"
            },
            {
                "name": "Lužec nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Lužná",
                "countryCode": "CZ"
            },
            {
                "name": "Lysá nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Lány",
                "countryCode": "CZ"
            },
            {
                "name": "Líbeznice",
                "countryCode": "CZ"
            },
            {
                "name": "Milovice",
                "countryCode": "CZ"
            },
            {
                "name": "Milín",
                "countryCode": "CZ"
            },
            {
                "name": "Mladá Boleslav",
                "countryCode": "CZ"
            },
            {
                "name": "Mnichovice",
                "countryCode": "CZ"
            },
            {
                "name": "Mnichovo Hradiště",
                "countryCode": "CZ"
            },
            {
                "name": "Mníšek pod Brdy",
                "countryCode": "CZ"
            },
            {
                "name": "Mukařov",
                "countryCode": "CZ"
            },
            {
                "name": "Mělník",
                "countryCode": "CZ"
            },
            {
                "name": "Městec Králové",
                "countryCode": "CZ"
            },
            {
                "name": "Měšice",
                "countryCode": "CZ"
            },
            {
                "name": "Mšeno",
                "countryCode": "CZ"
            },
            {
                "name": "Načeradec",
                "countryCode": "CZ"
            },
            {
                "name": "Nehvizdy",
                "countryCode": "CZ"
            },
            {
                "name": "Nelahozeves",
                "countryCode": "CZ"
            },
            {
                "name": "Neratovice",
                "countryCode": "CZ"
            },
            {
                "name": "Netvořice",
                "countryCode": "CZ"
            },
            {
                "name": "Neveklov",
                "countryCode": "CZ"
            },
            {
                "name": "Nižbor",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Strašecí",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Knín",
                "countryCode": "CZ"
            },
            {
                "name": "Nymburk",
                "countryCode": "CZ"
            },
            {
                "name": "Obecnice",
                "countryCode": "CZ"
            },
            {
                "name": "Odolena Voda",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Benešov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Beroun",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Kladno",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Kolín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Kutná Hora",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Mladá Boleslav",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Mělník",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Nymburk",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Praha-východ",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Praha-západ",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Příbram",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Rakovník",
                "countryCode": "CZ"
            },
            {
                "name": "Ondřejov",
                "countryCode": "CZ"
            },
            {
                "name": "Pavlíkov",
                "countryCode": "CZ"
            },
            {
                "name": "Pchery",
                "countryCode": "CZ"
            },
            {
                "name": "Petrovice",
                "countryCode": "CZ"
            },
            {
                "name": "Pečky",
                "countryCode": "CZ"
            },
            {
                "name": "Plaňany",
                "countryCode": "CZ"
            },
            {
                "name": "Poděbrady",
                "countryCode": "CZ"
            },
            {
                "name": "Postupice",
                "countryCode": "CZ"
            },
            {
                "name": "Poříčany",
                "countryCode": "CZ"
            },
            {
                "name": "Poříčí nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Průhonice",
                "countryCode": "CZ"
            },
            {
                "name": "Psáry",
                "countryCode": "CZ"
            },
            {
                "name": "Pyšely",
                "countryCode": "CZ"
            },
            {
                "name": "Přerov nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Příbram",
                "countryCode": "CZ"
            },
            {
                "name": "Rakovník",
                "countryCode": "CZ"
            },
            {
                "name": "Roztoky",
                "countryCode": "CZ"
            },
            {
                "name": "Rožmitál pod Třemšínem",
                "countryCode": "CZ"
            },
            {
                "name": "Rožďalovice",
                "countryCode": "CZ"
            },
            {
                "name": "Rudná",
                "countryCode": "CZ"
            },
            {
                "name": "Sadská",
                "countryCode": "CZ"
            },
            {
                "name": "Sedlec",
                "countryCode": "CZ"
            },
            {
                "name": "Sedlčany",
                "countryCode": "CZ"
            },
            {
                "name": "Slaný",
                "countryCode": "CZ"
            },
            {
                "name": "Smečno",
                "countryCode": "CZ"
            },
            {
                "name": "Stará Huť",
                "countryCode": "CZ"
            },
            {
                "name": "Starý Kolín",
                "countryCode": "CZ"
            },
            {
                "name": "Stochov",
                "countryCode": "CZ"
            },
            {
                "name": "Strančice",
                "countryCode": "CZ"
            },
            {
                "name": "Suchdol",
                "countryCode": "CZ"
            },
            {
                "name": "Sázava",
                "countryCode": "CZ"
            },
            {
                "name": "Tišice",
                "countryCode": "CZ"
            },
            {
                "name": "Trhový Štěpánov",
                "countryCode": "CZ"
            },
            {
                "name": "Tuchlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Tuchoměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Týnec nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Týnec nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Třebotov",
                "countryCode": "CZ"
            },
            {
                "name": "Uhlířské Janovice",
                "countryCode": "CZ"
            },
            {
                "name": "Unhošť",
                "countryCode": "CZ"
            },
            {
                "name": "Velim",
                "countryCode": "CZ"
            },
            {
                "name": "Velká Dobrá",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Popovice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Přílepy",
                "countryCode": "CZ"
            },
            {
                "name": "Velký Osek",
                "countryCode": "CZ"
            },
            {
                "name": "Veltruby",
                "countryCode": "CZ"
            },
            {
                "name": "Veltrusy",
                "countryCode": "CZ"
            },
            {
                "name": "Velvary",
                "countryCode": "CZ"
            },
            {
                "name": "Vestec",
                "countryCode": "CZ"
            },
            {
                "name": "Vinařice",
                "countryCode": "CZ"
            },
            {
                "name": "Vlašim",
                "countryCode": "CZ"
            },
            {
                "name": "Votice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrané nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Vrdy",
                "countryCode": "CZ"
            },
            {
                "name": "Všenory",
                "countryCode": "CZ"
            },
            {
                "name": "Všetaty",
                "countryCode": "CZ"
            },
            {
                "name": "Zaječov",
                "countryCode": "CZ"
            },
            {
                "name": "Zbraslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Zdiby",
                "countryCode": "CZ"
            },
            {
                "name": "Zdice",
                "countryCode": "CZ"
            },
            {
                "name": "Zeleneč",
                "countryCode": "CZ"
            },
            {
                "name": "Zlonice",
                "countryCode": "CZ"
            },
            {
                "name": "Zruč nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Zvole",
                "countryCode": "CZ"
            },
            {
                "name": "Zásmuky",
                "countryCode": "CZ"
            },
            {
                "name": "Úvaly",
                "countryCode": "CZ"
            },
            {
                "name": "Čechtice",
                "countryCode": "CZ"
            },
            {
                "name": "Čelákovice",
                "countryCode": "CZ"
            },
            {
                "name": "Černošice",
                "countryCode": "CZ"
            },
            {
                "name": "Červené Pečky",
                "countryCode": "CZ"
            },
            {
                "name": "Čerčany",
                "countryCode": "CZ"
            },
            {
                "name": "Český Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Čáslav",
                "countryCode": "CZ"
            },
            {
                "name": "Řevnice",
                "countryCode": "CZ"
            },
            {
                "name": "Řevničov",
                "countryCode": "CZ"
            },
            {
                "name": "Řež",
                "countryCode": "CZ"
            },
            {
                "name": "Říčany",
                "countryCode": "CZ"
            },
            {
                "name": "Šestajovice",
                "countryCode": "CZ"
            },
            {
                "name": "Štěchovice",
                "countryCode": "CZ"
            },
            {
                "name": "Žebrák",
                "countryCode": "CZ"
            },
            {
                "name": "Žiželice",
                "countryCode": "CZ"
            },
            {
                "name": "Žleby",
                "countryCode": "CZ"
            },
            {
                "name": "Žďár",
                "countryCode": "CZ"
            },
            {
                "name": "Bavorov",
                "countryCode": "CZ"
            },
            {
                "name": "Bechyně",
                "countryCode": "CZ"
            },
            {
                "name": "Benešov nad Černou",
                "countryCode": "CZ"
            },
            {
                "name": "Bernartice",
                "countryCode": "CZ"
            },
            {
                "name": "Blatná",
                "countryCode": "CZ"
            },
            {
                "name": "Borek",
                "countryCode": "CZ"
            },
            {
                "name": "Borovany",
                "countryCode": "CZ"
            },
            {
                "name": "Borová Lada",
                "countryCode": "CZ"
            },
            {
                "name": "Brloh",
                "countryCode": "CZ"
            },
            {
                "name": "Chlum u Třeboně",
                "countryCode": "CZ"
            },
            {
                "name": "Chotoviny",
                "countryCode": "CZ"
            },
            {
                "name": "Chvalšiny",
                "countryCode": "CZ"
            },
            {
                "name": "Chyšky",
                "countryCode": "CZ"
            },
            {
                "name": "Chýnov",
                "countryCode": "CZ"
            },
            {
                "name": "Dačice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Bukovsko",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Dvořiště",
                "countryCode": "CZ"
            },
            {
                "name": "Dubné",
                "countryCode": "CZ"
            },
            {
                "name": "Dřiteň",
                "countryCode": "CZ"
            },
            {
                "name": "Frymburk",
                "countryCode": "CZ"
            },
            {
                "name": "Hluboká nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Planá",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Stropnice",
                "countryCode": "CZ"
            },
            {
                "name": "Hrdějovice",
                "countryCode": "CZ"
            },
            {
                "name": "Husinec",
                "countryCode": "CZ"
            },
            {
                "name": "Jarošov nad Nežárkou",
                "countryCode": "CZ"
            },
            {
                "name": "Jindřichův Hradec",
                "countryCode": "CZ"
            },
            {
                "name": "Jistebnice",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenný Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Kaplice",
                "countryCode": "CZ"
            },
            {
                "name": "Kardašova Řečice",
                "countryCode": "CZ"
            },
            {
                "name": "Katovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kovářov",
                "countryCode": "CZ"
            },
            {
                "name": "Kunžak",
                "countryCode": "CZ"
            },
            {
                "name": "Kájov",
                "countryCode": "CZ"
            },
            {
                "name": "Křemže",
                "countryCode": "CZ"
            },
            {
                "name": "Ledenice",
                "countryCode": "CZ"
            },
            {
                "name": "Lhenice",
                "countryCode": "CZ"
            },
            {
                "name": "Litvínovice",
                "countryCode": "CZ"
            },
            {
                "name": "Lišov",
                "countryCode": "CZ"
            },
            {
                "name": "Lomnice nad Lužnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Loučovice",
                "countryCode": "CZ"
            },
            {
                "name": "Malonty",
                "countryCode": "CZ"
            },
            {
                "name": "Malšice",
                "countryCode": "CZ"
            },
            {
                "name": "Milevsko",
                "countryCode": "CZ"
            },
            {
                "name": "Mirotice",
                "countryCode": "CZ"
            },
            {
                "name": "Mirovice",
                "countryCode": "CZ"
            },
            {
                "name": "Mladá Vožice",
                "countryCode": "CZ"
            },
            {
                "name": "Netolice",
                "countryCode": "CZ"
            },
            {
                "name": "Nová Bystřice",
                "countryCode": "CZ"
            },
            {
                "name": "Nová Včelnice",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Hrady",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Jindřichův Hradec",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Prachatice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Písek",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Strakonice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Tábor",
                "countryCode": "CZ"
            },
            {
                "name": "Okres České Budějovice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Český Krumlov",
                "countryCode": "CZ"
            },
            {
                "name": "Opařany",
                "countryCode": "CZ"
            },
            {
                "name": "Planá nad Lužnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Prachatice",
                "countryCode": "CZ"
            },
            {
                "name": "Protivín",
                "countryCode": "CZ"
            },
            {
                "name": "Písek",
                "countryCode": "CZ"
            },
            {
                "name": "Radomyšl",
                "countryCode": "CZ"
            },
            {
                "name": "Rudolfov",
                "countryCode": "CZ"
            },
            {
                "name": "Sedlice",
                "countryCode": "CZ"
            },
            {
                "name": "Sepekov",
                "countryCode": "CZ"
            },
            {
                "name": "Sezimovo Ústí",
                "countryCode": "CZ"
            },
            {
                "name": "Slavonice",
                "countryCode": "CZ"
            },
            {
                "name": "Soběslav",
                "countryCode": "CZ"
            },
            {
                "name": "Srubec",
                "countryCode": "CZ"
            },
            {
                "name": "Stachy",
                "countryCode": "CZ"
            },
            {
                "name": "Strakonice",
                "countryCode": "CZ"
            },
            {
                "name": "Strmilov",
                "countryCode": "CZ"
            },
            {
                "name": "Strunkovice nad Blanicí",
                "countryCode": "CZ"
            },
            {
                "name": "Studená",
                "countryCode": "CZ"
            },
            {
                "name": "Suchdol nad Lužnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Trhové Sviny",
                "countryCode": "CZ"
            },
            {
                "name": "Tábor",
                "countryCode": "CZ"
            },
            {
                "name": "Týn nad Vltavou",
                "countryCode": "CZ"
            },
            {
                "name": "Třeboň",
                "countryCode": "CZ"
            },
            {
                "name": "Vacov",
                "countryCode": "CZ"
            },
            {
                "name": "Velešín",
                "countryCode": "CZ"
            },
            {
                "name": "Veselí nad Lužnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Vimperk",
                "countryCode": "CZ"
            },
            {
                "name": "Vlachovo Březí",
                "countryCode": "CZ"
            },
            {
                "name": "Vodňany",
                "countryCode": "CZ"
            },
            {
                "name": "Volary",
                "countryCode": "CZ"
            },
            {
                "name": "Volyně",
                "countryCode": "CZ"
            },
            {
                "name": "Vyšší Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Včelná",
                "countryCode": "CZ"
            },
            {
                "name": "Větřní",
                "countryCode": "CZ"
            },
            {
                "name": "Zdíkov",
                "countryCode": "CZ"
            },
            {
                "name": "Zliv",
                "countryCode": "CZ"
            },
            {
                "name": "České Budějovice",
                "countryCode": "CZ"
            },
            {
                "name": "České Velenice",
                "countryCode": "CZ"
            },
            {
                "name": "Český Krumlov",
                "countryCode": "CZ"
            },
            {
                "name": "Český Rudolec",
                "countryCode": "CZ"
            },
            {
                "name": "Čimelice",
                "countryCode": "CZ"
            },
            {
                "name": "Čkyně",
                "countryCode": "CZ"
            },
            {
                "name": "Ševětín",
                "countryCode": "CZ"
            },
            {
                "name": "Bezdružice",
                "countryCode": "CZ"
            },
            {
                "name": "Blovice",
                "countryCode": "CZ"
            },
            {
                "name": "Blížejov",
                "countryCode": "CZ"
            },
            {
                "name": "Bor",
                "countryCode": "CZ"
            },
            {
                "name": "Bělá nad Radbuzou",
                "countryCode": "CZ"
            },
            {
                "name": "Břasy",
                "countryCode": "CZ"
            },
            {
                "name": "Chlumčany",
                "countryCode": "CZ"
            },
            {
                "name": "Chodová Planá",
                "countryCode": "CZ"
            },
            {
                "name": "Chotěšov",
                "countryCode": "CZ"
            },
            {
                "name": "Chrást",
                "countryCode": "CZ"
            },
            {
                "name": "Dobřany",
                "countryCode": "CZ"
            },
            {
                "name": "Dobřív",
                "countryCode": "CZ"
            },
            {
                "name": "Domažlice",
                "countryCode": "CZ"
            },
            {
                "name": "Dýšina",
                "countryCode": "CZ"
            },
            {
                "name": "Hartmanice",
                "countryCode": "CZ"
            },
            {
                "name": "Heřmanova Huť",
                "countryCode": "CZ"
            },
            {
                "name": "Holoubkov",
                "countryCode": "CZ"
            },
            {
                "name": "Holýšov",
                "countryCode": "CZ"
            },
            {
                "name": "Horažďovice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Bříza",
                "countryCode": "CZ"
            },
            {
                "name": "Horšovský Týn",
                "countryCode": "CZ"
            },
            {
                "name": "Hostouň",
                "countryCode": "CZ"
            },
            {
                "name": "Hromnice",
                "countryCode": "CZ"
            },
            {
                "name": "Hrádek",
                "countryCode": "CZ"
            },
            {
                "name": "Janovice nad Úhlavou",
                "countryCode": "CZ"
            },
            {
                "name": "Kasejovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kaznějov",
                "countryCode": "CZ"
            },
            {
                "name": "Kašperské Hory",
                "countryCode": "CZ"
            },
            {
                "name": "Kdyně",
                "countryCode": "CZ"
            },
            {
                "name": "Kladruby",
                "countryCode": "CZ"
            },
            {
                "name": "Klatovy",
                "countryCode": "CZ"
            },
            {
                "name": "Klenčí pod Čerchovem",
                "countryCode": "CZ"
            },
            {
                "name": "Kolinec",
                "countryCode": "CZ"
            },
            {
                "name": "Kout na Šumavě",
                "countryCode": "CZ"
            },
            {
                "name": "Kožlany",
                "countryCode": "CZ"
            },
            {
                "name": "Kralovice",
                "countryCode": "CZ"
            },
            {
                "name": "Líně",
                "countryCode": "CZ"
            },
            {
                "name": "Manětín",
                "countryCode": "CZ"
            },
            {
                "name": "Meclov",
                "countryCode": "CZ"
            },
            {
                "name": "Merklín",
                "countryCode": "CZ"
            },
            {
                "name": "Mirošov",
                "countryCode": "CZ"
            },
            {
                "name": "Mrákov",
                "countryCode": "CZ"
            },
            {
                "name": "Mýto",
                "countryCode": "CZ"
            },
            {
                "name": "Město Touškov",
                "countryCode": "CZ"
            },
            {
                "name": "Měčín",
                "countryCode": "CZ"
            },
            {
                "name": "Nepomuk",
                "countryCode": "CZ"
            },
            {
                "name": "Nezvěstice",
                "countryCode": "CZ"
            },
            {
                "name": "Nýrsko",
                "countryCode": "CZ"
            },
            {
                "name": "Nýřany",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Domažlice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Klatovy",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Plzeň-jih",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Plzeň-město",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Plzeň-sever",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Rokycany",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Tachov",
                "countryCode": "CZ"
            },
            {
                "name": "Osek",
                "countryCode": "CZ"
            },
            {
                "name": "Pilsen",
                "countryCode": "CZ"
            },
            {
                "name": "Planá",
                "countryCode": "CZ"
            },
            {
                "name": "Plasy",
                "countryCode": "CZ"
            },
            {
                "name": "Plánice",
                "countryCode": "CZ"
            },
            {
                "name": "Poběžovice",
                "countryCode": "CZ"
            },
            {
                "name": "Postřekov",
                "countryCode": "CZ"
            },
            {
                "name": "Přeštice",
                "countryCode": "CZ"
            },
            {
                "name": "Přimda",
                "countryCode": "CZ"
            },
            {
                "name": "Radnice",
                "countryCode": "CZ"
            },
            {
                "name": "Rokycany",
                "countryCode": "CZ"
            },
            {
                "name": "Spálené Poříčí",
                "countryCode": "CZ"
            },
            {
                "name": "Starý Plzenec",
                "countryCode": "CZ"
            },
            {
                "name": "Staňkov",
                "countryCode": "CZ"
            },
            {
                "name": "Stod",
                "countryCode": "CZ"
            },
            {
                "name": "Strašice",
                "countryCode": "CZ"
            },
            {
                "name": "Stráž",
                "countryCode": "CZ"
            },
            {
                "name": "Strážov",
                "countryCode": "CZ"
            },
            {
                "name": "Stříbro",
                "countryCode": "CZ"
            },
            {
                "name": "Sušice",
                "countryCode": "CZ"
            },
            {
                "name": "Tachov",
                "countryCode": "CZ"
            },
            {
                "name": "Tlučná",
                "countryCode": "CZ"
            },
            {
                "name": "Třemošná",
                "countryCode": "CZ"
            },
            {
                "name": "Vejprnice",
                "countryCode": "CZ"
            },
            {
                "name": "Všeruby",
                "countryCode": "CZ"
            },
            {
                "name": "Zbiroh",
                "countryCode": "CZ"
            },
            {
                "name": "Zbůch",
                "countryCode": "CZ"
            },
            {
                "name": "Černošín",
                "countryCode": "CZ"
            },
            {
                "name": "Štěnovice",
                "countryCode": "CZ"
            },
            {
                "name": "Švihov",
                "countryCode": "CZ"
            },
            {
                "name": "Šťáhlavy",
                "countryCode": "CZ"
            },
            {
                "name": "Železná Ruda",
                "countryCode": "CZ"
            },
            {
                "name": "Žihle",
                "countryCode": "CZ"
            },
            {
                "name": "Abertamy",
                "countryCode": "CZ"
            },
            {
                "name": "Aš",
                "countryCode": "CZ"
            },
            {
                "name": "Bochov",
                "countryCode": "CZ"
            },
            {
                "name": "Bukovany",
                "countryCode": "CZ"
            },
            {
                "name": "Březová",
                "countryCode": "CZ"
            },
            {
                "name": "Cheb",
                "countryCode": "CZ"
            },
            {
                "name": "Chodov",
                "countryCode": "CZ"
            },
            {
                "name": "Dalovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Rychnov",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Žandov",
                "countryCode": "CZ"
            },
            {
                "name": "Františkovy Lázně",
                "countryCode": "CZ"
            },
            {
                "name": "Habartov",
                "countryCode": "CZ"
            },
            {
                "name": "Hazlov",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Slavkov",
                "countryCode": "CZ"
            },
            {
                "name": "Hranice",
                "countryCode": "CZ"
            },
            {
                "name": "Hroznětín",
                "countryCode": "CZ"
            },
            {
                "name": "Jáchymov",
                "countryCode": "CZ"
            },
            {
                "name": "Karlovy Vary",
                "countryCode": "CZ"
            },
            {
                "name": "Klášter",
                "countryCode": "CZ"
            },
            {
                "name": "Kraslice",
                "countryCode": "CZ"
            },
            {
                "name": "Kynšperk nad Ohří",
                "countryCode": "CZ"
            },
            {
                "name": "Loket",
                "countryCode": "CZ"
            },
            {
                "name": "Lomnice",
                "countryCode": "CZ"
            },
            {
                "name": "Luby",
                "countryCode": "CZ"
            },
            {
                "name": "Lázně Kynžvart",
                "countryCode": "CZ"
            },
            {
                "name": "Mariánské Lázně",
                "countryCode": "CZ"
            },
            {
                "name": "Merklín",
                "countryCode": "CZ"
            },
            {
                "name": "Město",
                "countryCode": "CZ"
            },
            {
                "name": "Nejdek",
                "countryCode": "CZ"
            },
            {
                "name": "Nová Role",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Sedlo",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Cheb",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Karlovy Vary",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Sokolov",
                "countryCode": "CZ"
            },
            {
                "name": "Oloví",
                "countryCode": "CZ"
            },
            {
                "name": "Ostrov",
                "countryCode": "CZ"
            },
            {
                "name": "Plesná",
                "countryCode": "CZ"
            },
            {
                "name": "Rotava",
                "countryCode": "CZ"
            },
            {
                "name": "Sadov",
                "countryCode": "CZ"
            },
            {
                "name": "Skalná",
                "countryCode": "CZ"
            },
            {
                "name": "Sokolov",
                "countryCode": "CZ"
            },
            {
                "name": "Svatava",
                "countryCode": "CZ"
            },
            {
                "name": "Toužim",
                "countryCode": "CZ"
            },
            {
                "name": "Velká Hleďsebe",
                "countryCode": "CZ"
            },
            {
                "name": "Vintířov",
                "countryCode": "CZ"
            },
            {
                "name": "Žlutice",
                "countryCode": "CZ"
            },
            {
                "name": "Bechlín",
                "countryCode": "CZ"
            },
            {
                "name": "Benešov nad Ploučnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Bečov",
                "countryCode": "CZ"
            },
            {
                "name": "Bohušovice nad Ohří",
                "countryCode": "CZ"
            },
            {
                "name": "Braňany",
                "countryCode": "CZ"
            },
            {
                "name": "Budyně nad Ohří",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřany",
                "countryCode": "CZ"
            },
            {
                "name": "Bílina",
                "countryCode": "CZ"
            },
            {
                "name": "Bílina Kyselka",
                "countryCode": "CZ"
            },
            {
                "name": "Březno",
                "countryCode": "CZ"
            },
            {
                "name": "Chabařovice",
                "countryCode": "CZ"
            },
            {
                "name": "Chlumec",
                "countryCode": "CZ"
            },
            {
                "name": "Chomutov",
                "countryCode": "CZ"
            },
            {
                "name": "Chřibská",
                "countryCode": "CZ"
            },
            {
                "name": "Dobroměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Podluží",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Poustevna",
                "countryCode": "CZ"
            },
            {
                "name": "Dubí",
                "countryCode": "CZ"
            },
            {
                "name": "Duchcov",
                "countryCode": "CZ"
            },
            {
                "name": "Děčín",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Jiřetín",
                "countryCode": "CZ"
            },
            {
                "name": "Hostomice",
                "countryCode": "CZ"
            },
            {
                "name": "Hošťka",
                "countryCode": "CZ"
            },
            {
                "name": "Hrob",
                "countryCode": "CZ"
            },
            {
                "name": "Jirkov",
                "countryCode": "CZ"
            },
            {
                "name": "Jiříkov",
                "countryCode": "CZ"
            },
            {
                "name": "Jílové",
                "countryCode": "CZ"
            },
            {
                "name": "Kadaň",
                "countryCode": "CZ"
            },
            {
                "name": "Klášterec nad Ohří",
                "countryCode": "CZ"
            },
            {
                "name": "Kovářská",
                "countryCode": "CZ"
            },
            {
                "name": "Košťany",
                "countryCode": "CZ"
            },
            {
                "name": "Krupka",
                "countryCode": "CZ"
            },
            {
                "name": "Kryry",
                "countryCode": "CZ"
            },
            {
                "name": "Krásná Lípa",
                "countryCode": "CZ"
            },
            {
                "name": "Křešice",
                "countryCode": "CZ"
            },
            {
                "name": "Lenešice",
                "countryCode": "CZ"
            },
            {
                "name": "Libochovice",
                "countryCode": "CZ"
            },
            {
                "name": "Libouchec",
                "countryCode": "CZ"
            },
            {
                "name": "Liběšice",
                "countryCode": "CZ"
            },
            {
                "name": "Litoměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Litvínov",
                "countryCode": "CZ"
            },
            {
                "name": "Lom u Mostu",
                "countryCode": "CZ"
            },
            {
                "name": "Louny",
                "countryCode": "CZ"
            },
            {
                "name": "Lovosice",
                "countryCode": "CZ"
            },
            {
                "name": "Lubenec",
                "countryCode": "CZ"
            },
            {
                "name": "Meziboři",
                "countryCode": "CZ"
            },
            {
                "name": "Mikulášovice",
                "countryCode": "CZ"
            },
            {
                "name": "Most",
                "countryCode": "CZ"
            },
            {
                "name": "Měcholupy",
                "countryCode": "CZ"
            },
            {
                "name": "Novosedlice",
                "countryCode": "CZ"
            },
            {
                "name": "Obrnice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Chomutov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Děčín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Litoměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Louny",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Most",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Teplice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Ústí nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Osek",
                "countryCode": "CZ"
            },
            {
                "name": "Peruc",
                "countryCode": "CZ"
            },
            {
                "name": "Perštejn",
                "countryCode": "CZ"
            },
            {
                "name": "Podbořany",
                "countryCode": "CZ"
            },
            {
                "name": "Polepy",
                "countryCode": "CZ"
            },
            {
                "name": "Postoloprty",
                "countryCode": "CZ"
            },
            {
                "name": "Povrly",
                "countryCode": "CZ"
            },
            {
                "name": "Proboštov",
                "countryCode": "CZ"
            },
            {
                "name": "Radonice",
                "countryCode": "CZ"
            },
            {
                "name": "Roudnice nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Rumburk",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Křečany",
                "countryCode": "CZ"
            },
            {
                "name": "Teplice",
                "countryCode": "CZ"
            },
            {
                "name": "Terezín",
                "countryCode": "CZ"
            },
            {
                "name": "Trmice",
                "countryCode": "CZ"
            },
            {
                "name": "Třebenice",
                "countryCode": "CZ"
            },
            {
                "name": "Varnsdorf",
                "countryCode": "CZ"
            },
            {
                "name": "Vejprty",
                "countryCode": "CZ"
            },
            {
                "name": "Velemín",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Březno",
                "countryCode": "CZ"
            },
            {
                "name": "Velký Šenov",
                "countryCode": "CZ"
            },
            {
                "name": "Verneřice",
                "countryCode": "CZ"
            },
            {
                "name": "Vilémov",
                "countryCode": "CZ"
            },
            {
                "name": "Vroutek",
                "countryCode": "CZ"
            },
            {
                "name": "Zabrušany",
                "countryCode": "CZ"
            },
            {
                "name": "Údlice",
                "countryCode": "CZ"
            },
            {
                "name": "Úštěk",
                "countryCode": "CZ"
            },
            {
                "name": "Černčice",
                "countryCode": "CZ"
            },
            {
                "name": "Česká Kamenice",
                "countryCode": "CZ"
            },
            {
                "name": "Čížkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Řehlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Šluknov",
                "countryCode": "CZ"
            },
            {
                "name": "Štětí",
                "countryCode": "CZ"
            },
            {
                "name": "Žatec",
                "countryCode": "CZ"
            },
            {
                "name": "Žitenice",
                "countryCode": "CZ"
            },
            {
                "name": "Benecko",
                "countryCode": "CZ"
            },
            {
                "name": "Brniště",
                "countryCode": "CZ"
            },
            {
                "name": "Chrastava",
                "countryCode": "CZ"
            },
            {
                "name": "Cvikov",
                "countryCode": "CZ"
            },
            {
                "name": "Desná",
                "countryCode": "CZ"
            },
            {
                "name": "Doksy",
                "countryCode": "CZ"
            },
            {
                "name": "Dubá",
                "countryCode": "CZ"
            },
            {
                "name": "Frýdlant",
                "countryCode": "CZ"
            },
            {
                "name": "Harrachov",
                "countryCode": "CZ"
            },
            {
                "name": "Hejnice",
                "countryCode": "CZ"
            },
            {
                "name": "Hodkovice nad Mohelkou",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Branná",
                "countryCode": "CZ"
            },
            {
                "name": "Hrádek nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Jablonec nad Jizerou",
                "countryCode": "CZ"
            },
            {
                "name": "Jablonec nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Jablonné v Podještědí",
                "countryCode": "CZ"
            },
            {
                "name": "Janov nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Jilemnice",
                "countryCode": "CZ"
            },
            {
                "name": "Josefův Důl",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenický Šenov",
                "countryCode": "CZ"
            },
            {
                "name": "Kořenov",
                "countryCode": "CZ"
            },
            {
                "name": "Košťálov",
                "countryCode": "CZ"
            },
            {
                "name": "Liberec",
                "countryCode": "CZ"
            },
            {
                "name": "Lomnice nad Popelkou",
                "countryCode": "CZ"
            },
            {
                "name": "Lučany nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Malá Skála",
                "countryCode": "CZ"
            },
            {
                "name": "Mimoň",
                "countryCode": "CZ"
            },
            {
                "name": "Mníšek",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Město pod Smrkem",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Bor",
                "countryCode": "CZ"
            },
            {
                "name": "Ohrazenice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Jablonec nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Liberec",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Semily",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Česká Lípa",
                "countryCode": "CZ"
            },
            {
                "name": "Osečná",
                "countryCode": "CZ"
            },
            {
                "name": "Plavy",
                "countryCode": "CZ"
            },
            {
                "name": "Poniklá",
                "countryCode": "CZ"
            },
            {
                "name": "Pěnčín",
                "countryCode": "CZ"
            },
            {
                "name": "Příšovice",
                "countryCode": "CZ"
            },
            {
                "name": "Raspenava",
                "countryCode": "CZ"
            },
            {
                "name": "Rokytnice nad Jizerou",
                "countryCode": "CZ"
            },
            {
                "name": "Rovensko pod Troskami",
                "countryCode": "CZ"
            },
            {
                "name": "Semily",
                "countryCode": "CZ"
            },
            {
                "name": "Smržovka",
                "countryCode": "CZ"
            },
            {
                "name": "Stráž nad Nisou",
                "countryCode": "CZ"
            },
            {
                "name": "Stráž pod Ralskem",
                "countryCode": "CZ"
            },
            {
                "name": "Studenec",
                "countryCode": "CZ"
            },
            {
                "name": "Tanvald",
                "countryCode": "CZ"
            },
            {
                "name": "Turnov",
                "countryCode": "CZ"
            },
            {
                "name": "Valdice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Hamry",
                "countryCode": "CZ"
            },
            {
                "name": "Višňova",
                "countryCode": "CZ"
            },
            {
                "name": "Vysoké nad Jizerou",
                "countryCode": "CZ"
            },
            {
                "name": "Zákupy",
                "countryCode": "CZ"
            },
            {
                "name": "Česká Lípa",
                "countryCode": "CZ"
            },
            {
                "name": "Český Dub",
                "countryCode": "CZ"
            },
            {
                "name": "Žandov",
                "countryCode": "CZ"
            },
            {
                "name": "Železný Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Albrechtice nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Borohrádek",
                "countryCode": "CZ"
            },
            {
                "name": "Broumov",
                "countryCode": "CZ"
            },
            {
                "name": "Bílá Třemešná",
                "countryCode": "CZ"
            },
            {
                "name": "Chlumec nad Cidlinou",
                "countryCode": "CZ"
            },
            {
                "name": "Dobruška",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Černilov",
                "countryCode": "CZ"
            },
            {
                "name": "Doudleby nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Dvůr Králové nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Hostinné",
                "countryCode": "CZ"
            },
            {
                "name": "Hořice",
                "countryCode": "CZ"
            },
            {
                "name": "Hradec Králové",
                "countryCode": "CZ"
            },
            {
                "name": "Hronov",
                "countryCode": "CZ"
            },
            {
                "name": "Jaroměř",
                "countryCode": "CZ"
            },
            {
                "name": "Jičín",
                "countryCode": "CZ"
            },
            {
                "name": "Kopidlno",
                "countryCode": "CZ"
            },
            {
                "name": "Kostelec nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Kvasiny",
                "countryCode": "CZ"
            },
            {
                "name": "Libáň",
                "countryCode": "CZ"
            },
            {
                "name": "Lázně Bělohrad",
                "countryCode": "CZ"
            },
            {
                "name": "Machov",
                "countryCode": "CZ"
            },
            {
                "name": "Malé Svatoňovice",
                "countryCode": "CZ"
            },
            {
                "name": "Meziměstí",
                "countryCode": "CZ"
            },
            {
                "name": "Mladé Buky",
                "countryCode": "CZ"
            },
            {
                "name": "Mostek",
                "countryCode": "CZ"
            },
            {
                "name": "Nechanice",
                "countryCode": "CZ"
            },
            {
                "name": "Nová Paka",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Město nad Metují",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Bydžov",
                "countryCode": "CZ"
            },
            {
                "name": "Náchod",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Hradec Králové",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Jičín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Náchod",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Rychnov nad Kněžnou",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Trutnov",
                "countryCode": "CZ"
            },
            {
                "name": "Opočno",
                "countryCode": "CZ"
            },
            {
                "name": "Ostroměř",
                "countryCode": "CZ"
            },
            {
                "name": "Pecka",
                "countryCode": "CZ"
            },
            {
                "name": "Pilníkov",
                "countryCode": "CZ"
            },
            {
                "name": "Police nad Metují",
                "countryCode": "CZ"
            },
            {
                "name": "Provodov-Šonov",
                "countryCode": "CZ"
            },
            {
                "name": "Předměřice nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Radvanice",
                "countryCode": "CZ"
            },
            {
                "name": "Rokytnice v Orlických Horách",
                "countryCode": "CZ"
            },
            {
                "name": "Rtyně v Podkrkonoší",
                "countryCode": "CZ"
            },
            {
                "name": "Rudník",
                "countryCode": "CZ"
            },
            {
                "name": "Rychnov nad Kněžnou",
                "countryCode": "CZ"
            },
            {
                "name": "Skuhrov nad Bělou",
                "countryCode": "CZ"
            },
            {
                "name": "Smidary",
                "countryCode": "CZ"
            },
            {
                "name": "Smiřice",
                "countryCode": "CZ"
            },
            {
                "name": "Sobotka",
                "countryCode": "CZ"
            },
            {
                "name": "Solnice",
                "countryCode": "CZ"
            },
            {
                "name": "Stará Paka",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Nechanice",
                "countryCode": "CZ"
            },
            {
                "name": "Stěžery",
                "countryCode": "CZ"
            },
            {
                "name": "Svoboda nad Úpou",
                "countryCode": "CZ"
            },
            {
                "name": "Teplice nad Metují",
                "countryCode": "CZ"
            },
            {
                "name": "Trutnov",
                "countryCode": "CZ"
            },
            {
                "name": "Týniště nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Třebechovice pod Orebem",
                "countryCode": "CZ"
            },
            {
                "name": "Valdice",
                "countryCode": "CZ"
            },
            {
                "name": "Vamberk",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Poříčí",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Svatoňovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrchlabí",
                "countryCode": "CZ"
            },
            {
                "name": "Všestary",
                "countryCode": "CZ"
            },
            {
                "name": "Zadní Mostek",
                "countryCode": "CZ"
            },
            {
                "name": "Úpice",
                "countryCode": "CZ"
            },
            {
                "name": "Častolovice",
                "countryCode": "CZ"
            },
            {
                "name": "Černilov",
                "countryCode": "CZ"
            },
            {
                "name": "Černožice",
                "countryCode": "CZ"
            },
            {
                "name": "Červený Kostelec",
                "countryCode": "CZ"
            },
            {
                "name": "Česká Skalice",
                "countryCode": "CZ"
            },
            {
                "name": "České Meziříčí",
                "countryCode": "CZ"
            },
            {
                "name": "Špindlerův Mlýn",
                "countryCode": "CZ"
            },
            {
                "name": "Žacléř",
                "countryCode": "CZ"
            },
            {
                "name": "Železnice",
                "countryCode": "CZ"
            },
            {
                "name": "Brandýs nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Brněnec",
                "countryCode": "CZ"
            },
            {
                "name": "Bystré",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřec",
                "countryCode": "CZ"
            },
            {
                "name": "Býšť",
                "countryCode": "CZ"
            },
            {
                "name": "Březová nad Svitavou",
                "countryCode": "CZ"
            },
            {
                "name": "Choceň",
                "countryCode": "CZ"
            },
            {
                "name": "Chrast",
                "countryCode": "CZ"
            },
            {
                "name": "Chroustovice",
                "countryCode": "CZ"
            },
            {
                "name": "Chrudim",
                "countryCode": "CZ"
            },
            {
                "name": "Chvaletice",
                "countryCode": "CZ"
            },
            {
                "name": "Dašice",
                "countryCode": "CZ"
            },
            {
                "name": "Dlouhá Třebová",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Dobrouč",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Roveň",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Sloupnice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Čermná",
                "countryCode": "CZ"
            },
            {
                "name": "Heřmanův Městec",
                "countryCode": "CZ"
            },
            {
                "name": "Hlinsko",
                "countryCode": "CZ"
            },
            {
                "name": "Holice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Jelení",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Sloupnice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Čermná",
                "countryCode": "CZ"
            },
            {
                "name": "Hradec nad Svitavou",
                "countryCode": "CZ"
            },
            {
                "name": "Hrochův Týnec",
                "countryCode": "CZ"
            },
            {
                "name": "Jablonné nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Jaroměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Jedlová",
                "countryCode": "CZ"
            },
            {
                "name": "Jevíčko",
                "countryCode": "CZ"
            },
            {
                "name": "Krouna",
                "countryCode": "CZ"
            },
            {
                "name": "Králíky",
                "countryCode": "CZ"
            },
            {
                "name": "Kunvald",
                "countryCode": "CZ"
            },
            {
                "name": "Kunčina",
                "countryCode": "CZ"
            },
            {
                "name": "Lanškroun",
                "countryCode": "CZ"
            },
            {
                "name": "Letohrad",
                "countryCode": "CZ"
            },
            {
                "name": "Litomyšl",
                "countryCode": "CZ"
            },
            {
                "name": "Lukavice",
                "countryCode": "CZ"
            },
            {
                "name": "Luže",
                "countryCode": "CZ"
            },
            {
                "name": "Lázně Bohdaneč",
                "countryCode": "CZ"
            },
            {
                "name": "Miřetice",
                "countryCode": "CZ"
            },
            {
                "name": "Moravany",
                "countryCode": "CZ"
            },
            {
                "name": "Moravská Třebová",
                "countryCode": "CZ"
            },
            {
                "name": "Městečko Trnávka",
                "countryCode": "CZ"
            },
            {
                "name": "Nasavrky",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Chrudim",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Pardubice",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Svitavy",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Ústí nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Opatov",
                "countryCode": "CZ"
            },
            {
                "name": "Opatovice nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Osík",
                "countryCode": "CZ"
            },
            {
                "name": "Pardubice",
                "countryCode": "CZ"
            },
            {
                "name": "Polička",
                "countryCode": "CZ"
            },
            {
                "name": "Pomezí",
                "countryCode": "CZ"
            },
            {
                "name": "Prachovice",
                "countryCode": "CZ"
            },
            {
                "name": "Proseč",
                "countryCode": "CZ"
            },
            {
                "name": "Přelouč",
                "countryCode": "CZ"
            },
            {
                "name": "Radiměř",
                "countryCode": "CZ"
            },
            {
                "name": "Ronov nad Doubravou",
                "countryCode": "CZ"
            },
            {
                "name": "Rosice",
                "countryCode": "CZ"
            },
            {
                "name": "Rybitví",
                "countryCode": "CZ"
            },
            {
                "name": "Sezemice",
                "countryCode": "CZ"
            },
            {
                "name": "Seč",
                "countryCode": "CZ"
            },
            {
                "name": "Skuteč",
                "countryCode": "CZ"
            },
            {
                "name": "Slatiňany",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Hradiště",
                "countryCode": "CZ"
            },
            {
                "name": "Svitavy",
                "countryCode": "CZ"
            },
            {
                "name": "Třemošnice",
                "countryCode": "CZ"
            },
            {
                "name": "Vysoké Mýto",
                "countryCode": "CZ"
            },
            {
                "name": "Ústí nad Orlicí",
                "countryCode": "CZ"
            },
            {
                "name": "Červená Voda",
                "countryCode": "CZ"
            },
            {
                "name": "Česká Třebová",
                "countryCode": "CZ"
            },
            {
                "name": "Řečany nad Labem",
                "countryCode": "CZ"
            },
            {
                "name": "Žamberk",
                "countryCode": "CZ"
            },
            {
                "name": "Batelov",
                "countryCode": "CZ"
            },
            {
                "name": "Bohdalov",
                "countryCode": "CZ"
            },
            {
                "name": "Brtnice",
                "countryCode": "CZ"
            },
            {
                "name": "Budišov",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřice nad Pernštejnem",
                "countryCode": "CZ"
            },
            {
                "name": "Chotěboř",
                "countryCode": "CZ"
            },
            {
                "name": "Dobronín",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Cerekev",
                "countryCode": "CZ"
            },
            {
                "name": "Golčův Jeníkov",
                "countryCode": "CZ"
            },
            {
                "name": "Habry",
                "countryCode": "CZ"
            },
            {
                "name": "Havlíčkův Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Herálec",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Cerekev",
                "countryCode": "CZ"
            },
            {
                "name": "Hrotovice",
                "countryCode": "CZ"
            },
            {
                "name": "Humpolec",
                "countryCode": "CZ"
            },
            {
                "name": "Jaroměřice nad Rokytnou",
                "countryCode": "CZ"
            },
            {
                "name": "Jemnice",
                "countryCode": "CZ"
            },
            {
                "name": "Jihlava",
                "countryCode": "CZ"
            },
            {
                "name": "Jimramov",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenice",
                "countryCode": "CZ"
            },
            {
                "name": "Kamenice nad Lipou",
                "countryCode": "CZ"
            },
            {
                "name": "Kněžice",
                "countryCode": "CZ"
            },
            {
                "name": "Křižanov",
                "countryCode": "CZ"
            },
            {
                "name": "Křížová",
                "countryCode": "CZ"
            },
            {
                "name": "Ledeč nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Luka nad Jihlavou",
                "countryCode": "CZ"
            },
            {
                "name": "Lukavec",
                "countryCode": "CZ"
            },
            {
                "name": "Lípa",
                "countryCode": "CZ"
            },
            {
                "name": "Mohelno",
                "countryCode": "CZ"
            },
            {
                "name": "Moravské Budějovice",
                "countryCode": "CZ"
            },
            {
                "name": "Měřín",
                "countryCode": "CZ"
            },
            {
                "name": "Nová Cerekev",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Město na Moravě",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Syrovice",
                "countryCode": "CZ"
            },
            {
                "name": "Nové Veselí",
                "countryCode": "CZ"
            },
            {
                "name": "Náměšť nad Oslavou",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Havlíčkův Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Jihlava",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Pelhřimov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Třebíč",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Žďár nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Okrouhlice",
                "countryCode": "CZ"
            },
            {
                "name": "Okříšky",
                "countryCode": "CZ"
            },
            {
                "name": "Pacov",
                "countryCode": "CZ"
            },
            {
                "name": "Pelhřimov",
                "countryCode": "CZ"
            },
            {
                "name": "Polná",
                "countryCode": "CZ"
            },
            {
                "name": "Počátky",
                "countryCode": "CZ"
            },
            {
                "name": "Přibyslav",
                "countryCode": "CZ"
            },
            {
                "name": "Rouchovany",
                "countryCode": "CZ"
            },
            {
                "name": "Stařeč",
                "countryCode": "CZ"
            },
            {
                "name": "Svratka",
                "countryCode": "CZ"
            },
            {
                "name": "Světlá nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Telč",
                "countryCode": "CZ"
            },
            {
                "name": "Třebíč",
                "countryCode": "CZ"
            },
            {
                "name": "Třešť",
                "countryCode": "CZ"
            },
            {
                "name": "Velká Bíteš",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Meziříčí",
                "countryCode": "CZ"
            },
            {
                "name": "Velký Beranov",
                "countryCode": "CZ"
            },
            {
                "name": "Vilémov",
                "countryCode": "CZ"
            },
            {
                "name": "Vladislav",
                "countryCode": "CZ"
            },
            {
                "name": "Černovice",
                "countryCode": "CZ"
            },
            {
                "name": "Štoky",
                "countryCode": "CZ"
            },
            {
                "name": "Želetava",
                "countryCode": "CZ"
            },
            {
                "name": "Želiv",
                "countryCode": "CZ"
            },
            {
                "name": "Žirovnice",
                "countryCode": "CZ"
            },
            {
                "name": "Žďár nad Sázavou",
                "countryCode": "CZ"
            },
            {
                "name": "Žďár nad Sázavou Druhy",
                "countryCode": "CZ"
            },
            {
                "name": "Adamov",
                "countryCode": "CZ"
            },
            {
                "name": "Blansko",
                "countryCode": "CZ"
            },
            {
                "name": "Blažovice",
                "countryCode": "CZ"
            },
            {
                "name": "Blučina",
                "countryCode": "CZ"
            },
            {
                "name": "Blížkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Boskovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bořetice",
                "countryCode": "CZ"
            },
            {
                "name": "Bořitov",
                "countryCode": "CZ"
            },
            {
                "name": "Bošovice",
                "countryCode": "CZ"
            },
            {
                "name": "Božice",
                "countryCode": "CZ"
            },
            {
                "name": "Brno",
                "countryCode": "CZ"
            },
            {
                "name": "Bučovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bzenec",
                "countryCode": "CZ"
            },
            {
                "name": "Bílovice nad Svitavou",
                "countryCode": "CZ"
            },
            {
                "name": "Břeclav",
                "countryCode": "CZ"
            },
            {
                "name": "Březí",
                "countryCode": "CZ"
            },
            {
                "name": "Dambořice",
                "countryCode": "CZ"
            },
            {
                "name": "Dobšice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Bojanovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Dunajovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Kounice",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Loućky",
                "countryCode": "CZ"
            },
            {
                "name": "Domanín",
                "countryCode": "CZ"
            },
            {
                "name": "Doubravice nad Svitavou",
                "countryCode": "CZ"
            },
            {
                "name": "Drnholec",
                "countryCode": "CZ"
            },
            {
                "name": "Drnovice",
                "countryCode": "CZ"
            },
            {
                "name": "Drásov",
                "countryCode": "CZ"
            },
            {
                "name": "Dubňany",
                "countryCode": "CZ"
            },
            {
                "name": "Hevlín",
                "countryCode": "CZ"
            },
            {
                "name": "Hlohovec",
                "countryCode": "CZ"
            },
            {
                "name": "Hodonice",
                "countryCode": "CZ"
            },
            {
                "name": "Hodonín",
                "countryCode": "CZ"
            },
            {
                "name": "Hostěradice",
                "countryCode": "CZ"
            },
            {
                "name": "Hovorany",
                "countryCode": "CZ"
            },
            {
                "name": "Hroznová Lhota",
                "countryCode": "CZ"
            },
            {
                "name": "Hrušky",
                "countryCode": "CZ"
            },
            {
                "name": "Hrušovany nad Jevišovkou",
                "countryCode": "CZ"
            },
            {
                "name": "Hrušovany u Brna",
                "countryCode": "CZ"
            },
            {
                "name": "Hustopeče",
                "countryCode": "CZ"
            },
            {
                "name": "Ivanovice na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Ivančice",
                "countryCode": "CZ"
            },
            {
                "name": "Jaroslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Jedovnice",
                "countryCode": "CZ"
            },
            {
                "name": "Jevišovice",
                "countryCode": "CZ"
            },
            {
                "name": "Klobouky",
                "countryCode": "CZ"
            },
            {
                "name": "Kněždub",
                "countryCode": "CZ"
            },
            {
                "name": "Kobylí",
                "countryCode": "CZ"
            },
            {
                "name": "Kostice",
                "countryCode": "CZ"
            },
            {
                "name": "Krumvíř",
                "countryCode": "CZ"
            },
            {
                "name": "Kunštát",
                "countryCode": "CZ"
            },
            {
                "name": "Kuřim",
                "countryCode": "CZ"
            },
            {
                "name": "Kyjov",
                "countryCode": "CZ"
            },
            {
                "name": "Křenovice",
                "countryCode": "CZ"
            },
            {
                "name": "Křepice",
                "countryCode": "CZ"
            },
            {
                "name": "Lanžhot",
                "countryCode": "CZ"
            },
            {
                "name": "Lednice",
                "countryCode": "CZ"
            },
            {
                "name": "Lelekovice",
                "countryCode": "CZ"
            },
            {
                "name": "Letonice",
                "countryCode": "CZ"
            },
            {
                "name": "Letovice",
                "countryCode": "CZ"
            },
            {
                "name": "Lipov",
                "countryCode": "CZ"
            },
            {
                "name": "Lipovec",
                "countryCode": "CZ"
            },
            {
                "name": "Lipůvka",
                "countryCode": "CZ"
            },
            {
                "name": "Lomnice",
                "countryCode": "CZ"
            },
            {
                "name": "Louka",
                "countryCode": "CZ"
            },
            {
                "name": "Lužice",
                "countryCode": "CZ"
            },
            {
                "name": "Lysice",
                "countryCode": "CZ"
            },
            {
                "name": "Mikulov",
                "countryCode": "CZ"
            },
            {
                "name": "Mikulčice",
                "countryCode": "CZ"
            },
            {
                "name": "Milotice",
                "countryCode": "CZ"
            },
            {
                "name": "Miroslav",
                "countryCode": "CZ"
            },
            {
                "name": "Modřice",
                "countryCode": "CZ"
            },
            {
                "name": "Mokrá Hora",
                "countryCode": "CZ"
            },
            {
                "name": "Moravany",
                "countryCode": "CZ"
            },
            {
                "name": "Moravská Nová Ves",
                "countryCode": "CZ"
            },
            {
                "name": "Moravský Krumlov",
                "countryCode": "CZ"
            },
            {
                "name": "Moravský Písek",
                "countryCode": "CZ"
            },
            {
                "name": "Moravský Žižkov",
                "countryCode": "CZ"
            },
            {
                "name": "Moutnice",
                "countryCode": "CZ"
            },
            {
                "name": "Mutěnice",
                "countryCode": "CZ"
            },
            {
                "name": "Měnín",
                "countryCode": "CZ"
            },
            {
                "name": "Město Brno",
                "countryCode": "CZ"
            },
            {
                "name": "Nedvědice",
                "countryCode": "CZ"
            },
            {
                "name": "Nesovice",
                "countryCode": "CZ"
            },
            {
                "name": "Nosislav",
                "countryCode": "CZ"
            },
            {
                "name": "Novosedly",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Blansko",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Brno-venkov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Břeclav",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Hodonín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Vyškov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Znojmo",
                "countryCode": "CZ"
            },
            {
                "name": "Olbramovice",
                "countryCode": "CZ"
            },
            {
                "name": "Olešnice",
                "countryCode": "CZ"
            },
            {
                "name": "Oslavany",
                "countryCode": "CZ"
            },
            {
                "name": "Ostopovice",
                "countryCode": "CZ"
            },
            {
                "name": "Ostrov u Macochy",
                "countryCode": "CZ"
            },
            {
                "name": "Otnice",
                "countryCode": "CZ"
            },
            {
                "name": "Ořechov",
                "countryCode": "CZ"
            },
            {
                "name": "Petrov",
                "countryCode": "CZ"
            },
            {
                "name": "Podivín",
                "countryCode": "CZ"
            },
            {
                "name": "Podolí",
                "countryCode": "CZ"
            },
            {
                "name": "Pohořelice",
                "countryCode": "CZ"
            },
            {
                "name": "Pozořice",
                "countryCode": "CZ"
            },
            {
                "name": "Prušánky",
                "countryCode": "CZ"
            },
            {
                "name": "Pustiměř",
                "countryCode": "CZ"
            },
            {
                "name": "Předklášteří",
                "countryCode": "CZ"
            },
            {
                "name": "Rajhrad",
                "countryCode": "CZ"
            },
            {
                "name": "Rajhradice",
                "countryCode": "CZ"
            },
            {
                "name": "Rakvice",
                "countryCode": "CZ"
            },
            {
                "name": "Ratíškovice",
                "countryCode": "CZ"
            },
            {
                "name": "Rohatec",
                "countryCode": "CZ"
            },
            {
                "name": "Rosice",
                "countryCode": "CZ"
            },
            {
                "name": "Rousínov",
                "countryCode": "CZ"
            },
            {
                "name": "Rájec-Jestřebí",
                "countryCode": "CZ"
            },
            {
                "name": "Ráječko",
                "countryCode": "CZ"
            },
            {
                "name": "Slavkov u Brna",
                "countryCode": "CZ"
            },
            {
                "name": "Sokolnice",
                "countryCode": "CZ"
            },
            {
                "name": "Strážnice",
                "countryCode": "CZ"
            },
            {
                "name": "Střelice",
                "countryCode": "CZ"
            },
            {
                "name": "Suchohrdly",
                "countryCode": "CZ"
            },
            {
                "name": "Sudoměřice",
                "countryCode": "CZ"
            },
            {
                "name": "Svitávka",
                "countryCode": "CZ"
            },
            {
                "name": "Tasovice",
                "countryCode": "CZ"
            },
            {
                "name": "Telnice",
                "countryCode": "CZ"
            },
            {
                "name": "Tišnov",
                "countryCode": "CZ"
            },
            {
                "name": "Troubsko",
                "countryCode": "CZ"
            },
            {
                "name": "Tvarožná",
                "countryCode": "CZ"
            },
            {
                "name": "Tvrdonice",
                "countryCode": "CZ"
            },
            {
                "name": "Týnec",
                "countryCode": "CZ"
            },
            {
                "name": "Těšany",
                "countryCode": "CZ"
            },
            {
                "name": "Vacenovice",
                "countryCode": "CZ"
            },
            {
                "name": "Valtice",
                "countryCode": "CZ"
            },
            {
                "name": "Velká nad Veličkou",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Bílovice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Němčice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Opatovice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Pavlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Veselí nad Moravou",
                "countryCode": "CZ"
            },
            {
                "name": "Veverská Bítýška",
                "countryCode": "CZ"
            },
            {
                "name": "Viničné Šumice",
                "countryCode": "CZ"
            },
            {
                "name": "Višňové",
                "countryCode": "CZ"
            },
            {
                "name": "Vlkoš",
                "countryCode": "CZ"
            },
            {
                "name": "Vnorovy",
                "countryCode": "CZ"
            },
            {
                "name": "Vojkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vracov",
                "countryCode": "CZ"
            },
            {
                "name": "Vranovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrbice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrbovec",
                "countryCode": "CZ"
            },
            {
                "name": "Vyškov",
                "countryCode": "CZ"
            },
            {
                "name": "Zaječí",
                "countryCode": "CZ"
            },
            {
                "name": "Zastávka",
                "countryCode": "CZ"
            },
            {
                "name": "Zbraslav",
                "countryCode": "CZ"
            },
            {
                "name": "Zbýšov",
                "countryCode": "CZ"
            },
            {
                "name": "Znojmo",
                "countryCode": "CZ"
            },
            {
                "name": "Únanov",
                "countryCode": "CZ"
            },
            {
                "name": "Čebín",
                "countryCode": "CZ"
            },
            {
                "name": "Čejkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Čejč",
                "countryCode": "CZ"
            },
            {
                "name": "Černá Hora",
                "countryCode": "CZ"
            },
            {
                "name": "Říčany",
                "countryCode": "CZ"
            },
            {
                "name": "Šakvice",
                "countryCode": "CZ"
            },
            {
                "name": "Šanov",
                "countryCode": "CZ"
            },
            {
                "name": "Šardice",
                "countryCode": "CZ"
            },
            {
                "name": "Šatov",
                "countryCode": "CZ"
            },
            {
                "name": "Šitbořice",
                "countryCode": "CZ"
            },
            {
                "name": "Šlapanice",
                "countryCode": "CZ"
            },
            {
                "name": "Žabčice",
                "countryCode": "CZ"
            },
            {
                "name": "Ždánice",
                "countryCode": "CZ"
            },
            {
                "name": "Želešice",
                "countryCode": "CZ"
            },
            {
                "name": "Žeravice",
                "countryCode": "CZ"
            },
            {
                "name": "Židlochovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bedihošť",
                "countryCode": "CZ"
            },
            {
                "name": "Bludov",
                "countryCode": "CZ"
            },
            {
                "name": "Bohuňovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bouzov",
                "countryCode": "CZ"
            },
            {
                "name": "Brodek u Přerova",
                "countryCode": "CZ"
            },
            {
                "name": "Bílá Lhota",
                "countryCode": "CZ"
            },
            {
                "name": "Bělotín",
                "countryCode": "CZ"
            },
            {
                "name": "Dolany",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Bohdíkov",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Studénky",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Doloplazy",
                "countryCode": "CZ"
            },
            {
                "name": "Drahanovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dub nad Moravou",
                "countryCode": "CZ"
            },
            {
                "name": "Dubicko",
                "countryCode": "CZ"
            },
            {
                "name": "Dřevohostice",
                "countryCode": "CZ"
            },
            {
                "name": "Grygov",
                "countryCode": "CZ"
            },
            {
                "name": "Hanušovice",
                "countryCode": "CZ"
            },
            {
                "name": "Hlubočky",
                "countryCode": "CZ"
            },
            {
                "name": "Hněvotín",
                "countryCode": "CZ"
            },
            {
                "name": "Horka nad Moravou",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Moštěnice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Štěpánov",
                "countryCode": "CZ"
            },
            {
                "name": "Hranice",
                "countryCode": "CZ"
            },
            {
                "name": "Hustopeče Nad Bečvou",
                "countryCode": "CZ"
            },
            {
                "name": "Javorník",
                "countryCode": "CZ"
            },
            {
                "name": "Jeseník",
                "countryCode": "CZ"
            },
            {
                "name": "Kojetín",
                "countryCode": "CZ"
            },
            {
                "name": "Kokory",
                "countryCode": "CZ"
            },
            {
                "name": "Konice",
                "countryCode": "CZ"
            },
            {
                "name": "Kostelec na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Kralice na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Leština",
                "countryCode": "CZ"
            },
            {
                "name": "Lipník nad Bečvou",
                "countryCode": "CZ"
            },
            {
                "name": "Litovel",
                "countryCode": "CZ"
            },
            {
                "name": "Loučná nad Desnou",
                "countryCode": "CZ"
            },
            {
                "name": "Loštice",
                "countryCode": "CZ"
            },
            {
                "name": "Lutín",
                "countryCode": "CZ"
            },
            {
                "name": "Majetín",
                "countryCode": "CZ"
            },
            {
                "name": "Medlov",
                "countryCode": "CZ"
            },
            {
                "name": "Mikulovice",
                "countryCode": "CZ"
            },
            {
                "name": "Mohelnice",
                "countryCode": "CZ"
            },
            {
                "name": "Moravičany",
                "countryCode": "CZ"
            },
            {
                "name": "Moravský Beroun",
                "countryCode": "CZ"
            },
            {
                "name": "Mostkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Město Libavá",
                "countryCode": "CZ"
            },
            {
                "name": "Nezamyslice",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Malín",
                "countryCode": "CZ"
            },
            {
                "name": "Náklo",
                "countryCode": "CZ"
            },
            {
                "name": "Náměšť na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Němčice nad Hanou",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Jeseník",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Olomouc",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Prostějov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Přerov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Šumperk",
                "countryCode": "CZ"
            },
            {
                "name": "Olomouc",
                "countryCode": "CZ"
            },
            {
                "name": "Olšany",
                "countryCode": "CZ"
            },
            {
                "name": "Osek nad Bečvou",
                "countryCode": "CZ"
            },
            {
                "name": "Oskava",
                "countryCode": "CZ"
            },
            {
                "name": "Otaslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Paseka",
                "countryCode": "CZ"
            },
            {
                "name": "Plumlov",
                "countryCode": "CZ"
            },
            {
                "name": "Postřelmov",
                "countryCode": "CZ"
            },
            {
                "name": "Potštát",
                "countryCode": "CZ"
            },
            {
                "name": "Prostějov",
                "countryCode": "CZ"
            },
            {
                "name": "Protivanov",
                "countryCode": "CZ"
            },
            {
                "name": "Ptení",
                "countryCode": "CZ"
            },
            {
                "name": "Písečná",
                "countryCode": "CZ"
            },
            {
                "name": "Přemyslovice",
                "countryCode": "CZ"
            },
            {
                "name": "Přerov",
                "countryCode": "CZ"
            },
            {
                "name": "Přáslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Příkazy",
                "countryCode": "CZ"
            },
            {
                "name": "Radslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Rapotín",
                "countryCode": "CZ"
            },
            {
                "name": "Rokytnice",
                "countryCode": "CZ"
            },
            {
                "name": "Ruda nad Moravou",
                "countryCode": "CZ"
            },
            {
                "name": "Samotíšky",
                "countryCode": "CZ"
            },
            {
                "name": "Senice na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Skrbeň",
                "countryCode": "CZ"
            },
            {
                "name": "Slatinice",
                "countryCode": "CZ"
            },
            {
                "name": "Smržice",
                "countryCode": "CZ"
            },
            {
                "name": "Sobotín",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Město",
                "countryCode": "CZ"
            },
            {
                "name": "Strážná",
                "countryCode": "CZ"
            },
            {
                "name": "Sudkov",
                "countryCode": "CZ"
            },
            {
                "name": "Tovačov",
                "countryCode": "CZ"
            },
            {
                "name": "Troubelice",
                "countryCode": "CZ"
            },
            {
                "name": "Troubky",
                "countryCode": "CZ"
            },
            {
                "name": "Tršice",
                "countryCode": "CZ"
            },
            {
                "name": "Těšetice",
                "countryCode": "CZ"
            },
            {
                "name": "Uničov",
                "countryCode": "CZ"
            },
            {
                "name": "Určice",
                "countryCode": "CZ"
            },
            {
                "name": "Velká Bystřice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Losiny",
                "countryCode": "CZ"
            },
            {
                "name": "Velký Týnec",
                "countryCode": "CZ"
            },
            {
                "name": "Velký Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Vidnava",
                "countryCode": "CZ"
            },
            {
                "name": "Vikýřovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrbátky",
                "countryCode": "CZ"
            },
            {
                "name": "Vápenná",
                "countryCode": "CZ"
            },
            {
                "name": "Věrovany",
                "countryCode": "CZ"
            },
            {
                "name": "Zlaté Hory",
                "countryCode": "CZ"
            },
            {
                "name": "Zábřeh",
                "countryCode": "CZ"
            },
            {
                "name": "Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Úsov",
                "countryCode": "CZ"
            },
            {
                "name": "Čelechovice na Hané",
                "countryCode": "CZ"
            },
            {
                "name": "Červenka",
                "countryCode": "CZ"
            },
            {
                "name": "Česká Ves",
                "countryCode": "CZ"
            },
            {
                "name": "Šternberk",
                "countryCode": "CZ"
            },
            {
                "name": "Štíty",
                "countryCode": "CZ"
            },
            {
                "name": "Štěpánov",
                "countryCode": "CZ"
            },
            {
                "name": "Šumperk",
                "countryCode": "CZ"
            },
            {
                "name": "Žulová",
                "countryCode": "CZ"
            },
            {
                "name": "Babice",
                "countryCode": "CZ"
            },
            {
                "name": "Bojkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Boršice",
                "countryCode": "CZ"
            },
            {
                "name": "Buchlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřice pod Hostýnem",
                "countryCode": "CZ"
            },
            {
                "name": "Bánov",
                "countryCode": "CZ"
            },
            {
                "name": "Bílovice",
                "countryCode": "CZ"
            },
            {
                "name": "Březnice",
                "countryCode": "CZ"
            },
            {
                "name": "Březolupy",
                "countryCode": "CZ"
            },
            {
                "name": "Březová",
                "countryCode": "CZ"
            },
            {
                "name": "Chropyně",
                "countryCode": "CZ"
            },
            {
                "name": "Chvalčov",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Bečva",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Němčí",
                "countryCode": "CZ"
            },
            {
                "name": "Francova Lhota",
                "countryCode": "CZ"
            },
            {
                "name": "Fryšták",
                "countryCode": "CZ"
            },
            {
                "name": "Halenkov",
                "countryCode": "CZ"
            },
            {
                "name": "Halenkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Hluk",
                "countryCode": "CZ"
            },
            {
                "name": "Holešov",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Bečva",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Lideč",
                "countryCode": "CZ"
            },
            {
                "name": "Hovězí",
                "countryCode": "CZ"
            },
            {
                "name": "Hošťálková",
                "countryCode": "CZ"
            },
            {
                "name": "Hulín",
                "countryCode": "CZ"
            },
            {
                "name": "Hvozdná",
                "countryCode": "CZ"
            },
            {
                "name": "Jablůnka",
                "countryCode": "CZ"
            },
            {
                "name": "Jalubí",
                "countryCode": "CZ"
            },
            {
                "name": "Karolinka",
                "countryCode": "CZ"
            },
            {
                "name": "Kelč",
                "countryCode": "CZ"
            },
            {
                "name": "Kněžpole",
                "countryCode": "CZ"
            },
            {
                "name": "Koryčany",
                "countryCode": "CZ"
            },
            {
                "name": "Kroměříž",
                "countryCode": "CZ"
            },
            {
                "name": "Kunovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kvasice",
                "countryCode": "CZ"
            },
            {
                "name": "Lešná",
                "countryCode": "CZ"
            },
            {
                "name": "Lidečko",
                "countryCode": "CZ"
            },
            {
                "name": "Liptál",
                "countryCode": "CZ"
            },
            {
                "name": "Luhačovice",
                "countryCode": "CZ"
            },
            {
                "name": "Lukov",
                "countryCode": "CZ"
            },
            {
                "name": "Mistřice",
                "countryCode": "CZ"
            },
            {
                "name": "Napajedla",
                "countryCode": "CZ"
            },
            {
                "name": "Nedakonice",
                "countryCode": "CZ"
            },
            {
                "name": "Nedašov",
                "countryCode": "CZ"
            },
            {
                "name": "Nivnice",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Hrozenkov",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Kroměříž",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Uherské Hradiště",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Vsetín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Zlín",
                "countryCode": "CZ"
            },
            {
                "name": "Ostrožská Lhota",
                "countryCode": "CZ"
            },
            {
                "name": "Ostrožská Nová Ves",
                "countryCode": "CZ"
            },
            {
                "name": "Otrokovice",
                "countryCode": "CZ"
            },
            {
                "name": "Polešovice",
                "countryCode": "CZ"
            },
            {
                "name": "Popovice",
                "countryCode": "CZ"
            },
            {
                "name": "Pozlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Prostřední Bečva",
                "countryCode": "CZ"
            },
            {
                "name": "Prusinovice",
                "countryCode": "CZ"
            },
            {
                "name": "Rataje",
                "countryCode": "CZ"
            },
            {
                "name": "Ratiboř",
                "countryCode": "CZ"
            },
            {
                "name": "Rožnov pod Radhoštěm",
                "countryCode": "CZ"
            },
            {
                "name": "Slavičín",
                "countryCode": "CZ"
            },
            {
                "name": "Slušovice",
                "countryCode": "CZ"
            },
            {
                "name": "Spytihněv",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Město",
                "countryCode": "CZ"
            },
            {
                "name": "Strání",
                "countryCode": "CZ"
            },
            {
                "name": "Suchá Loz",
                "countryCode": "CZ"
            },
            {
                "name": "Tečovice",
                "countryCode": "CZ"
            },
            {
                "name": "Tlumačov",
                "countryCode": "CZ"
            },
            {
                "name": "Topolná",
                "countryCode": "CZ"
            },
            {
                "name": "Traplice",
                "countryCode": "CZ"
            },
            {
                "name": "Trnava",
                "countryCode": "CZ"
            },
            {
                "name": "Tupesy",
                "countryCode": "CZ"
            },
            {
                "name": "Uherské Hradiště",
                "countryCode": "CZ"
            },
            {
                "name": "Uherský Brod",
                "countryCode": "CZ"
            },
            {
                "name": "Uherský Ostroh",
                "countryCode": "CZ"
            },
            {
                "name": "Valašská Bystřice",
                "countryCode": "CZ"
            },
            {
                "name": "Valašská Polanka",
                "countryCode": "CZ"
            },
            {
                "name": "Valašské Klobouky",
                "countryCode": "CZ"
            },
            {
                "name": "Valašské Meziříčí",
                "countryCode": "CZ"
            },
            {
                "name": "Velehrad",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Karlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vidče",
                "countryCode": "CZ"
            },
            {
                "name": "Vizovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vlachovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vlčnov",
                "countryCode": "CZ"
            },
            {
                "name": "Vsetín",
                "countryCode": "CZ"
            },
            {
                "name": "Všemina",
                "countryCode": "CZ"
            },
            {
                "name": "Zašová",
                "countryCode": "CZ"
            },
            {
                "name": "Zborovice",
                "countryCode": "CZ"
            },
            {
                "name": "Zdounky",
                "countryCode": "CZ"
            },
            {
                "name": "Zlechov",
                "countryCode": "CZ"
            },
            {
                "name": "Zlín",
                "countryCode": "CZ"
            },
            {
                "name": "Zubří",
                "countryCode": "CZ"
            },
            {
                "name": "Záhorovice",
                "countryCode": "CZ"
            },
            {
                "name": "Újezd",
                "countryCode": "CZ"
            },
            {
                "name": "Štítná nad Vláří",
                "countryCode": "CZ"
            },
            {
                "name": "Šumice",
                "countryCode": "CZ"
            },
            {
                "name": "Žlutava",
                "countryCode": "CZ"
            },
            {
                "name": "Albrechtice",
                "countryCode": "CZ"
            },
            {
                "name": "Bartošovice",
                "countryCode": "CZ"
            },
            {
                "name": "Baška",
                "countryCode": "CZ"
            },
            {
                "name": "Bohumín",
                "countryCode": "CZ"
            },
            {
                "name": "Bohuslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Bolatice",
                "countryCode": "CZ"
            },
            {
                "name": "Brantice",
                "countryCode": "CZ"
            },
            {
                "name": "Brumovice",
                "countryCode": "CZ"
            },
            {
                "name": "Bruntál",
                "countryCode": "CZ"
            },
            {
                "name": "Brušperk",
                "countryCode": "CZ"
            },
            {
                "name": "Budišov nad Budišovkou",
                "countryCode": "CZ"
            },
            {
                "name": "Bukovec",
                "countryCode": "CZ"
            },
            {
                "name": "Bystřice",
                "countryCode": "CZ"
            },
            {
                "name": "Bílovec",
                "countryCode": "CZ"
            },
            {
                "name": "Březová",
                "countryCode": "CZ"
            },
            {
                "name": "Břidličná",
                "countryCode": "CZ"
            },
            {
                "name": "Chlebičov",
                "countryCode": "CZ"
            },
            {
                "name": "Chotěbuz",
                "countryCode": "CZ"
            },
            {
                "name": "Chuchelná",
                "countryCode": "CZ"
            },
            {
                "name": "Darkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Dobratice",
                "countryCode": "CZ"
            },
            {
                "name": "Dobrá",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Benešov",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Lhota",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Lutyně",
                "countryCode": "CZ"
            },
            {
                "name": "Dolní Životice",
                "countryCode": "CZ"
            },
            {
                "name": "Doubrava",
                "countryCode": "CZ"
            },
            {
                "name": "Dvorce",
                "countryCode": "CZ"
            },
            {
                "name": "Dětmarovice",
                "countryCode": "CZ"
            },
            {
                "name": "Frenštát pod Radhoštěm",
                "countryCode": "CZ"
            },
            {
                "name": "Fryčovice",
                "countryCode": "CZ"
            },
            {
                "name": "Frýdek-Místek",
                "countryCode": "CZ"
            },
            {
                "name": "Frýdlant nad Ostravicí",
                "countryCode": "CZ"
            },
            {
                "name": "Fulnek",
                "countryCode": "CZ"
            },
            {
                "name": "Havířov",
                "countryCode": "CZ"
            },
            {
                "name": "Hať",
                "countryCode": "CZ"
            },
            {
                "name": "Hlučín",
                "countryCode": "CZ"
            },
            {
                "name": "Hnojník",
                "countryCode": "CZ"
            },
            {
                "name": "Hněvošice",
                "countryCode": "CZ"
            },
            {
                "name": "Hodslavice",
                "countryCode": "CZ"
            },
            {
                "name": "Holasovice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Benešov",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Bludovice",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Město",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Suchá",
                "countryCode": "CZ"
            },
            {
                "name": "Horní Těrlicko",
                "countryCode": "CZ"
            },
            {
                "name": "Hrabyně",
                "countryCode": "CZ"
            },
            {
                "name": "Hradec nad Moravici",
                "countryCode": "CZ"
            },
            {
                "name": "Hrádek",
                "countryCode": "CZ"
            },
            {
                "name": "Hukvaldy",
                "countryCode": "CZ"
            },
            {
                "name": "Háj ve Slezsku",
                "countryCode": "CZ"
            },
            {
                "name": "Jablunkov",
                "countryCode": "CZ"
            },
            {
                "name": "Jakartovice",
                "countryCode": "CZ"
            },
            {
                "name": "Janovice",
                "countryCode": "CZ"
            },
            {
                "name": "Jeseník nad Odrou",
                "countryCode": "CZ"
            },
            {
                "name": "Jindřichov",
                "countryCode": "CZ"
            },
            {
                "name": "Jistebník",
                "countryCode": "CZ"
            },
            {
                "name": "Karlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Karviná",
                "countryCode": "CZ"
            },
            {
                "name": "Klimkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kobeřice",
                "countryCode": "CZ"
            },
            {
                "name": "Komorní Lhotka",
                "countryCode": "CZ"
            },
            {
                "name": "Kopřivnice",
                "countryCode": "CZ"
            },
            {
                "name": "Kozlovice",
                "countryCode": "CZ"
            },
            {
                "name": "Kozmice",
                "countryCode": "CZ"
            },
            {
                "name": "Kravaře",
                "countryCode": "CZ"
            },
            {
                "name": "Krmelín",
                "countryCode": "CZ"
            },
            {
                "name": "Krnov",
                "countryCode": "CZ"
            },
            {
                "name": "Kunín",
                "countryCode": "CZ"
            },
            {
                "name": "Kunčice pod Ondřejníkem",
                "countryCode": "CZ"
            },
            {
                "name": "Lichnov",
                "countryCode": "CZ"
            },
            {
                "name": "Lichnov (o. Nový Jičín)",
                "countryCode": "CZ"
            },
            {
                "name": "Ludgeřovice",
                "countryCode": "CZ"
            },
            {
                "name": "Lučina",
                "countryCode": "CZ"
            },
            {
                "name": "Markvartovice",
                "countryCode": "CZ"
            },
            {
                "name": "Metylovice",
                "countryCode": "CZ"
            },
            {
                "name": "Milíkov",
                "countryCode": "CZ"
            },
            {
                "name": "Mokré Lazce",
                "countryCode": "CZ"
            },
            {
                "name": "Morávka",
                "countryCode": "CZ"
            },
            {
                "name": "Mosty u Jablunkova",
                "countryCode": "CZ"
            },
            {
                "name": "Mořkov",
                "countryCode": "CZ"
            },
            {
                "name": "Město Albrechtice",
                "countryCode": "CZ"
            },
            {
                "name": "Nový Jičín",
                "countryCode": "CZ"
            },
            {
                "name": "Návsí u Jablunkova",
                "countryCode": "CZ"
            },
            {
                "name": "Nýdek",
                "countryCode": "CZ"
            },
            {
                "name": "Odry",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Bruntál",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Frýdek-Místek",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Karviná",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Nový Jičín",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Opava",
                "countryCode": "CZ"
            },
            {
                "name": "Okres Ostrava-město",
                "countryCode": "CZ"
            },
            {
                "name": "Oldřišov",
                "countryCode": "CZ"
            },
            {
                "name": "Opava",
                "countryCode": "CZ"
            },
            {
                "name": "Orlová",
                "countryCode": "CZ"
            },
            {
                "name": "Osoblaha",
                "countryCode": "CZ"
            },
            {
                "name": "Ostrava",
                "countryCode": "CZ"
            },
            {
                "name": "Ostravice",
                "countryCode": "CZ"
            },
            {
                "name": "Otice",
                "countryCode": "CZ"
            },
            {
                "name": "Palkovice",
                "countryCode": "CZ"
            },
            {
                "name": "Paskov",
                "countryCode": "CZ"
            },
            {
                "name": "Petrovice u Karviné",
                "countryCode": "CZ"
            },
            {
                "name": "Petřvald",
                "countryCode": "CZ"
            },
            {
                "name": "Pražmo",
                "countryCode": "CZ"
            },
            {
                "name": "Pustá Polom",
                "countryCode": "CZ"
            },
            {
                "name": "Písek",
                "countryCode": "CZ"
            },
            {
                "name": "Píšť",
                "countryCode": "CZ"
            },
            {
                "name": "Příbor",
                "countryCode": "CZ"
            },
            {
                "name": "Raškovice",
                "countryCode": "CZ"
            },
            {
                "name": "Ropice",
                "countryCode": "CZ"
            },
            {
                "name": "Rybí",
                "countryCode": "CZ"
            },
            {
                "name": "Rychvald",
                "countryCode": "CZ"
            },
            {
                "name": "Rýmařov",
                "countryCode": "CZ"
            },
            {
                "name": "Sedliště",
                "countryCode": "CZ"
            },
            {
                "name": "Sedlnice",
                "countryCode": "CZ"
            },
            {
                "name": "Slavkov",
                "countryCode": "CZ"
            },
            {
                "name": "Stará Ves nad Ondřejnicí",
                "countryCode": "CZ"
            },
            {
                "name": "Staré Město",
                "countryCode": "CZ"
            },
            {
                "name": "Starý Bohumín",
                "countryCode": "CZ"
            },
            {
                "name": "Starý Jičín",
                "countryCode": "CZ"
            },
            {
                "name": "Staříč",
                "countryCode": "CZ"
            },
            {
                "name": "Stonava",
                "countryCode": "CZ"
            },
            {
                "name": "Studénka",
                "countryCode": "CZ"
            },
            {
                "name": "Stěbořice",
                "countryCode": "CZ"
            },
            {
                "name": "Sviadnov",
                "countryCode": "CZ"
            },
            {
                "name": "Tichá",
                "countryCode": "CZ"
            },
            {
                "name": "Trojanovice",
                "countryCode": "CZ"
            },
            {
                "name": "Třinec",
                "countryCode": "CZ"
            },
            {
                "name": "Velká Polom",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Heraltice",
                "countryCode": "CZ"
            },
            {
                "name": "Velké Hoštice",
                "countryCode": "CZ"
            },
            {
                "name": "Vendryně",
                "countryCode": "CZ"
            },
            {
                "name": "Veřovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vlčnov",
                "countryCode": "CZ"
            },
            {
                "name": "Vratimov",
                "countryCode": "CZ"
            },
            {
                "name": "Vrbice",
                "countryCode": "CZ"
            },
            {
                "name": "Vrbno pod Pradědem",
                "countryCode": "CZ"
            },
            {
                "name": "Václavovice",
                "countryCode": "CZ"
            },
            {
                "name": "Vítkov",
                "countryCode": "CZ"
            },
            {
                "name": "Vřesina",
                "countryCode": "CZ"
            },
            {
                "name": "Zátor",
                "countryCode": "CZ"
            },
            {
                "name": "Čeladná",
                "countryCode": "CZ"
            },
            {
                "name": "Český Těšín",
                "countryCode": "CZ"
            },
            {
                "name": "Řepiště",
                "countryCode": "CZ"
            },
            {
                "name": "Šenov",
                "countryCode": "CZ"
            },
            {
                "name": "Šilheřovice",
                "countryCode": "CZ"
            },
            {
                "name": "Štramberk",
                "countryCode": "CZ"
            },
            {
                "name": "Štítina",
                "countryCode": "CZ"
            },
            {
                "name": "Štěpánkovice",
                "countryCode": "CZ"
            }
        ]
    },
    {
        "name": "Denmark",
        "phonecode": "45",
        "cities": [
            {
                "name": "Aalborg",
                "countryCode": "DK"
            },
            {
                "name": "Aars",
                "countryCode": "DK"
            },
            {
                "name": "Arden",
                "countryCode": "DK"
            },
            {
                "name": "Brovst",
                "countryCode": "DK"
            },
            {
                "name": "Brønderslev",
                "countryCode": "DK"
            },
            {
                "name": "Brønderslev Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Dronninglund",
                "countryCode": "DK"
            },
            {
                "name": "Farsø",
                "countryCode": "DK"
            },
            {
                "name": "Fjerritslev",
                "countryCode": "DK"
            },
            {
                "name": "Frederikshavn",
                "countryCode": "DK"
            },
            {
                "name": "Frederikshavn Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Frejlev",
                "countryCode": "DK"
            },
            {
                "name": "Gistrup",
                "countryCode": "DK"
            },
            {
                "name": "Hadsund",
                "countryCode": "DK"
            },
            {
                "name": "Hals",
                "countryCode": "DK"
            },
            {
                "name": "Hanstholm",
                "countryCode": "DK"
            },
            {
                "name": "Hirtshals",
                "countryCode": "DK"
            },
            {
                "name": "Hjallerup",
                "countryCode": "DK"
            },
            {
                "name": "Hjørring",
                "countryCode": "DK"
            },
            {
                "name": "Hjørring Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hobro",
                "countryCode": "DK"
            },
            {
                "name": "Hurup",
                "countryCode": "DK"
            },
            {
                "name": "Jammerbugt Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Klarup",
                "countryCode": "DK"
            },
            {
                "name": "Kås",
                "countryCode": "DK"
            },
            {
                "name": "Læso Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Løgstør",
                "countryCode": "DK"
            },
            {
                "name": "Løkken",
                "countryCode": "DK"
            },
            {
                "name": "Mariager",
                "countryCode": "DK"
            },
            {
                "name": "Mariagerfjord Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Morsø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Nibe",
                "countryCode": "DK"
            },
            {
                "name": "Nykøbing Mors",
                "countryCode": "DK"
            },
            {
                "name": "Nørresundby",
                "countryCode": "DK"
            },
            {
                "name": "Pandrup",
                "countryCode": "DK"
            },
            {
                "name": "Rebild Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Sindal",
                "countryCode": "DK"
            },
            {
                "name": "Skagen",
                "countryCode": "DK"
            },
            {
                "name": "Skørping",
                "countryCode": "DK"
            },
            {
                "name": "Storvorde",
                "countryCode": "DK"
            },
            {
                "name": "Strandby",
                "countryCode": "DK"
            },
            {
                "name": "Støvring",
                "countryCode": "DK"
            },
            {
                "name": "Svenstrup",
                "countryCode": "DK"
            },
            {
                "name": "Sæby",
                "countryCode": "DK"
            },
            {
                "name": "Thisted",
                "countryCode": "DK"
            },
            {
                "name": "Thisted Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Tårs",
                "countryCode": "DK"
            },
            {
                "name": "Vadum",
                "countryCode": "DK"
            },
            {
                "name": "Vestbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Vester Hassing",
                "countryCode": "DK"
            },
            {
                "name": "Vesthimmerland Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vodskov",
                "countryCode": "DK"
            },
            {
                "name": "Vrå",
                "countryCode": "DK"
            },
            {
                "name": "Åbybro",
                "countryCode": "DK"
            },
            {
                "name": "Ålborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ålestrup",
                "countryCode": "DK"
            },
            {
                "name": "Allingåbro",
                "countryCode": "DK"
            },
            {
                "name": "Assentoft",
                "countryCode": "DK"
            },
            {
                "name": "Auning",
                "countryCode": "DK"
            },
            {
                "name": "Avlum",
                "countryCode": "DK"
            },
            {
                "name": "Beder",
                "countryCode": "DK"
            },
            {
                "name": "Bjerringbro",
                "countryCode": "DK"
            },
            {
                "name": "Bording Kirkeby",
                "countryCode": "DK"
            },
            {
                "name": "Brande",
                "countryCode": "DK"
            },
            {
                "name": "Brædstrup",
                "countryCode": "DK"
            },
            {
                "name": "Ebeltoft",
                "countryCode": "DK"
            },
            {
                "name": "Favrskov Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Framlev",
                "countryCode": "DK"
            },
            {
                "name": "Galten",
                "countryCode": "DK"
            },
            {
                "name": "Gjellerup",
                "countryCode": "DK"
            },
            {
                "name": "Grenaa",
                "countryCode": "DK"
            },
            {
                "name": "Hadsten",
                "countryCode": "DK"
            },
            {
                "name": "Hammel",
                "countryCode": "DK"
            },
            {
                "name": "Hammerum",
                "countryCode": "DK"
            },
            {
                "name": "Harboøre",
                "countryCode": "DK"
            },
            {
                "name": "Hedensted",
                "countryCode": "DK"
            },
            {
                "name": "Hedensted Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Herning",
                "countryCode": "DK"
            },
            {
                "name": "Herning Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hinnerup",
                "countryCode": "DK"
            },
            {
                "name": "Hjortshøj",
                "countryCode": "DK"
            },
            {
                "name": "Holstebro",
                "countryCode": "DK"
            },
            {
                "name": "Holstebro Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hornslet",
                "countryCode": "DK"
            },
            {
                "name": "Hornsyld",
                "countryCode": "DK"
            },
            {
                "name": "Horsens",
                "countryCode": "DK"
            },
            {
                "name": "Horsens Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hvide Sande",
                "countryCode": "DK"
            },
            {
                "name": "Hørning",
                "countryCode": "DK"
            },
            {
                "name": "Ikast",
                "countryCode": "DK"
            },
            {
                "name": "Ikast-Brande Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Juelsminde",
                "countryCode": "DK"
            },
            {
                "name": "Karup",
                "countryCode": "DK"
            },
            {
                "name": "Kibæk",
                "countryCode": "DK"
            },
            {
                "name": "Kjellerup",
                "countryCode": "DK"
            },
            {
                "name": "Kolt",
                "countryCode": "DK"
            },
            {
                "name": "Langå",
                "countryCode": "DK"
            },
            {
                "name": "Lemvig",
                "countryCode": "DK"
            },
            {
                "name": "Lemvig Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Lind",
                "countryCode": "DK"
            },
            {
                "name": "Lystrup",
                "countryCode": "DK"
            },
            {
                "name": "Løgten",
                "countryCode": "DK"
            },
            {
                "name": "Malling",
                "countryCode": "DK"
            },
            {
                "name": "Mårslet",
                "countryCode": "DK"
            },
            {
                "name": "Norddjurs Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Odder",
                "countryCode": "DK"
            },
            {
                "name": "Odder Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Randers",
                "countryCode": "DK"
            },
            {
                "name": "Randers Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ringkøbing",
                "countryCode": "DK"
            },
            {
                "name": "Ringkøbing-Skjern Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ry",
                "countryCode": "DK"
            },
            {
                "name": "Ryomgård",
                "countryCode": "DK"
            },
            {
                "name": "Rønde",
                "countryCode": "DK"
            },
            {
                "name": "Sabro",
                "countryCode": "DK"
            },
            {
                "name": "Samsø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Silkeborg",
                "countryCode": "DK"
            },
            {
                "name": "Silkeborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Skanderborg",
                "countryCode": "DK"
            },
            {
                "name": "Skanderborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Skive",
                "countryCode": "DK"
            },
            {
                "name": "Skive Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Skjern",
                "countryCode": "DK"
            },
            {
                "name": "Skovby",
                "countryCode": "DK"
            },
            {
                "name": "Snejbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Solbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Spentrup",
                "countryCode": "DK"
            },
            {
                "name": "Stavtrup",
                "countryCode": "DK"
            },
            {
                "name": "Stilling",
                "countryCode": "DK"
            },
            {
                "name": "Stoholm",
                "countryCode": "DK"
            },
            {
                "name": "Struer",
                "countryCode": "DK"
            },
            {
                "name": "Struer Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Sunds",
                "countryCode": "DK"
            },
            {
                "name": "Svejbæk",
                "countryCode": "DK"
            },
            {
                "name": "Syddjurs Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Søften",
                "countryCode": "DK"
            },
            {
                "name": "Tarm",
                "countryCode": "DK"
            },
            {
                "name": "Thyborøn",
                "countryCode": "DK"
            },
            {
                "name": "Tranbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Trige",
                "countryCode": "DK"
            },
            {
                "name": "Tørring",
                "countryCode": "DK"
            },
            {
                "name": "Ulfborg",
                "countryCode": "DK"
            },
            {
                "name": "Ulstrup",
                "countryCode": "DK"
            },
            {
                "name": "Viborg",
                "countryCode": "DK"
            },
            {
                "name": "Viborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Videbæk",
                "countryCode": "DK"
            },
            {
                "name": "Vildbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Vinderup",
                "countryCode": "DK"
            },
            {
                "name": "Virklund",
                "countryCode": "DK"
            },
            {
                "name": "Århus",
                "countryCode": "DK"
            },
            {
                "name": "Århus Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Aabenraa",
                "countryCode": "DK"
            },
            {
                "name": "Aabenraa Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Assens",
                "countryCode": "DK"
            },
            {
                "name": "Assens Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Augustenborg",
                "countryCode": "DK"
            },
            {
                "name": "Bellinge",
                "countryCode": "DK"
            },
            {
                "name": "Billund",
                "countryCode": "DK"
            },
            {
                "name": "Billund Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Bogense",
                "countryCode": "DK"
            },
            {
                "name": "Bramming",
                "countryCode": "DK"
            },
            {
                "name": "Brejning",
                "countryCode": "DK"
            },
            {
                "name": "Brenderup",
                "countryCode": "DK"
            },
            {
                "name": "Broager",
                "countryCode": "DK"
            },
            {
                "name": "Brørup",
                "countryCode": "DK"
            },
            {
                "name": "Bullerup",
                "countryCode": "DK"
            },
            {
                "name": "Børkop",
                "countryCode": "DK"
            },
            {
                "name": "Christiansfeld",
                "countryCode": "DK"
            },
            {
                "name": "Dybbøl",
                "countryCode": "DK"
            },
            {
                "name": "Egtved",
                "countryCode": "DK"
            },
            {
                "name": "Ejby",
                "countryCode": "DK"
            },
            {
                "name": "Esbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Esbjerg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Faaborg",
                "countryCode": "DK"
            },
            {
                "name": "Faaborg-Midtfyn Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Fanø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Fredericia",
                "countryCode": "DK"
            },
            {
                "name": "Fredericia Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Give",
                "countryCode": "DK"
            },
            {
                "name": "Glamsbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Gram",
                "countryCode": "DK"
            },
            {
                "name": "Grindsted",
                "countryCode": "DK"
            },
            {
                "name": "Gråsten",
                "countryCode": "DK"
            },
            {
                "name": "Guderup",
                "countryCode": "DK"
            },
            {
                "name": "Haderslev",
                "countryCode": "DK"
            },
            {
                "name": "Haderslev Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Holsted",
                "countryCode": "DK"
            },
            {
                "name": "Hårby",
                "countryCode": "DK"
            },
            {
                "name": "Højby",
                "countryCode": "DK"
            },
            {
                "name": "Høruphav",
                "countryCode": "DK"
            },
            {
                "name": "Jelling",
                "countryCode": "DK"
            },
            {
                "name": "Kerteminde",
                "countryCode": "DK"
            },
            {
                "name": "Kerteminde Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Kolding",
                "countryCode": "DK"
            },
            {
                "name": "Kolding Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Kruså",
                "countryCode": "DK"
            },
            {
                "name": "Langeland Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Langeskov",
                "countryCode": "DK"
            },
            {
                "name": "Lunderskov",
                "countryCode": "DK"
            },
            {
                "name": "Løgumkloster",
                "countryCode": "DK"
            },
            {
                "name": "Løjt Kirkeby",
                "countryCode": "DK"
            },
            {
                "name": "Marstal",
                "countryCode": "DK"
            },
            {
                "name": "Middelfart",
                "countryCode": "DK"
            },
            {
                "name": "Middelfart Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Munkebo",
                "countryCode": "DK"
            },
            {
                "name": "Neder Holluf",
                "countryCode": "DK"
            },
            {
                "name": "Nordborg",
                "countryCode": "DK"
            },
            {
                "name": "Nordby",
                "countryCode": "DK"
            },
            {
                "name": "Nordfyns Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Nyborg",
                "countryCode": "DK"
            },
            {
                "name": "Nyborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Nørre Åby",
                "countryCode": "DK"
            },
            {
                "name": "Odense",
                "countryCode": "DK"
            },
            {
                "name": "Odense Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Oksbøl",
                "countryCode": "DK"
            },
            {
                "name": "Otterup",
                "countryCode": "DK"
            },
            {
                "name": "Padborg",
                "countryCode": "DK"
            },
            {
                "name": "Ribe",
                "countryCode": "DK"
            },
            {
                "name": "Ringe",
                "countryCode": "DK"
            },
            {
                "name": "Rudkøbing",
                "countryCode": "DK"
            },
            {
                "name": "Rødding",
                "countryCode": "DK"
            },
            {
                "name": "Rødekro",
                "countryCode": "DK"
            },
            {
                "name": "Seden",
                "countryCode": "DK"
            },
            {
                "name": "Skaerbaek",
                "countryCode": "DK"
            },
            {
                "name": "Snoghøj",
                "countryCode": "DK"
            },
            {
                "name": "Starup",
                "countryCode": "DK"
            },
            {
                "name": "Stige",
                "countryCode": "DK"
            },
            {
                "name": "Strib",
                "countryCode": "DK"
            },
            {
                "name": "Svendborg",
                "countryCode": "DK"
            },
            {
                "name": "Svendborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Sønder Bjert",
                "countryCode": "DK"
            },
            {
                "name": "Sønderborg",
                "countryCode": "DK"
            },
            {
                "name": "Sønderborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Søndersø",
                "countryCode": "DK"
            },
            {
                "name": "Taulov",
                "countryCode": "DK"
            },
            {
                "name": "Thurø By",
                "countryCode": "DK"
            },
            {
                "name": "Tinglev",
                "countryCode": "DK"
            },
            {
                "name": "Tjæreborg",
                "countryCode": "DK"
            },
            {
                "name": "Toftlund",
                "countryCode": "DK"
            },
            {
                "name": "Tommerup",
                "countryCode": "DK"
            },
            {
                "name": "Tommerup Stationsby",
                "countryCode": "DK"
            },
            {
                "name": "Tønder",
                "countryCode": "DK"
            },
            {
                "name": "Tønder Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ullerslev",
                "countryCode": "DK"
            },
            {
                "name": "Vamdrup",
                "countryCode": "DK"
            },
            {
                "name": "Varde",
                "countryCode": "DK"
            },
            {
                "name": "Varde Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vejen",
                "countryCode": "DK"
            },
            {
                "name": "Vejen Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vejle",
                "countryCode": "DK"
            },
            {
                "name": "Vejle Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vester-Skerninge",
                "countryCode": "DK"
            },
            {
                "name": "Vindeby",
                "countryCode": "DK"
            },
            {
                "name": "Vissenbjerg",
                "countryCode": "DK"
            },
            {
                "name": "Vojens",
                "countryCode": "DK"
            },
            {
                "name": "Årslev",
                "countryCode": "DK"
            },
            {
                "name": "Årup",
                "countryCode": "DK"
            },
            {
                "name": "Ærø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ærøskøbing",
                "countryCode": "DK"
            },
            {
                "name": "Ølgod",
                "countryCode": "DK"
            },
            {
                "name": "Albertslund",
                "countryCode": "DK"
            },
            {
                "name": "Albertslund Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Allerød Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ballerup",
                "countryCode": "DK"
            },
            {
                "name": "Ballerup Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Birkerød",
                "countryCode": "DK"
            },
            {
                "name": "Blovstrød",
                "countryCode": "DK"
            },
            {
                "name": "Bornholm Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Brøndby Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Charlottenlund",
                "countryCode": "DK"
            },
            {
                "name": "Christianshavn",
                "countryCode": "DK"
            },
            {
                "name": "Christiansø",
                "countryCode": "DK"
            },
            {
                "name": "Copenhagen",
                "countryCode": "DK"
            },
            {
                "name": "Dragør",
                "countryCode": "DK"
            },
            {
                "name": "Dragør Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Egedal Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Espergærde",
                "countryCode": "DK"
            },
            {
                "name": "Farum",
                "countryCode": "DK"
            },
            {
                "name": "Fløng",
                "countryCode": "DK"
            },
            {
                "name": "Fredensborg",
                "countryCode": "DK"
            },
            {
                "name": "Fredensborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Frederiksberg",
                "countryCode": "DK"
            },
            {
                "name": "Frederiksberg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Frederikssund",
                "countryCode": "DK"
            },
            {
                "name": "Frederikssund Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Frederiksværk",
                "countryCode": "DK"
            },
            {
                "name": "Furesø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ganløse",
                "countryCode": "DK"
            },
            {
                "name": "Gentofte Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Gilleleje",
                "countryCode": "DK"
            },
            {
                "name": "Gladsaxe Municipality",
                "countryCode": "DK"
            },
            {
                "name": "Glostrup",
                "countryCode": "DK"
            },
            {
                "name": "Glostrup Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Gribskov Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Græsted",
                "countryCode": "DK"
            },
            {
                "name": "Halsnæs Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hellebæk",
                "countryCode": "DK"
            },
            {
                "name": "Helsinge",
                "countryCode": "DK"
            },
            {
                "name": "Helsingør",
                "countryCode": "DK"
            },
            {
                "name": "Helsingør Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Herlev Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hillerød",
                "countryCode": "DK"
            },
            {
                "name": "Hillerød Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hornbæk",
                "countryCode": "DK"
            },
            {
                "name": "Humlebæk",
                "countryCode": "DK"
            },
            {
                "name": "Hundested",
                "countryCode": "DK"
            },
            {
                "name": "Hvidovre",
                "countryCode": "DK"
            },
            {
                "name": "Hvidovre Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Høje-Taastrup Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Hørsholm",
                "countryCode": "DK"
            },
            {
                "name": "Hørsholm Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ishøj",
                "countryCode": "DK"
            },
            {
                "name": "Ishøj Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Jægerspris",
                "countryCode": "DK"
            },
            {
                "name": "Kokkedal",
                "countryCode": "DK"
            },
            {
                "name": "Kongens Lyngby",
                "countryCode": "DK"
            },
            {
                "name": "København",
                "countryCode": "DK"
            },
            {
                "name": "Lillerød",
                "countryCode": "DK"
            },
            {
                "name": "Liseleje",
                "countryCode": "DK"
            },
            {
                "name": "Lyngby-Tårbæk Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Lynge",
                "countryCode": "DK"
            },
            {
                "name": "Måløv",
                "countryCode": "DK"
            },
            {
                "name": "Nexø",
                "countryCode": "DK"
            },
            {
                "name": "Nivå",
                "countryCode": "DK"
            },
            {
                "name": "Nødebo",
                "countryCode": "DK"
            },
            {
                "name": "Rudersdal Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Rødovre",
                "countryCode": "DK"
            },
            {
                "name": "Rødovre Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Rønne",
                "countryCode": "DK"
            },
            {
                "name": "Skibby",
                "countryCode": "DK"
            },
            {
                "name": "Skævinge",
                "countryCode": "DK"
            },
            {
                "name": "Slangerup",
                "countryCode": "DK"
            },
            {
                "name": "Smørumnedre",
                "countryCode": "DK"
            },
            {
                "name": "Stavnsholt",
                "countryCode": "DK"
            },
            {
                "name": "Stenløse",
                "countryCode": "DK"
            },
            {
                "name": "Taastrup",
                "countryCode": "DK"
            },
            {
                "name": "Trørød",
                "countryCode": "DK"
            },
            {
                "name": "Tårnby",
                "countryCode": "DK"
            },
            {
                "name": "Tårnby Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vallensbæk",
                "countryCode": "DK"
            },
            {
                "name": "Vallensbæk Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Vanløse",
                "countryCode": "DK"
            },
            {
                "name": "Veksø",
                "countryCode": "DK"
            },
            {
                "name": "Værløse",
                "countryCode": "DK"
            },
            {
                "name": "Åkirkeby",
                "countryCode": "DK"
            },
            {
                "name": "Ølstykke",
                "countryCode": "DK"
            },
            {
                "name": "Asnæs",
                "countryCode": "DK"
            },
            {
                "name": "Bjæverskov",
                "countryCode": "DK"
            },
            {
                "name": "Borup",
                "countryCode": "DK"
            },
            {
                "name": "Dianalund",
                "countryCode": "DK"
            },
            {
                "name": "Ejby",
                "countryCode": "DK"
            },
            {
                "name": "Faxe",
                "countryCode": "DK"
            },
            {
                "name": "Faxe Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Faxe Ladeplads",
                "countryCode": "DK"
            },
            {
                "name": "Fensmark",
                "countryCode": "DK"
            },
            {
                "name": "Forlev",
                "countryCode": "DK"
            },
            {
                "name": "Frederiksberg",
                "countryCode": "DK"
            },
            {
                "name": "Fuglebjerg",
                "countryCode": "DK"
            },
            {
                "name": "Greve",
                "countryCode": "DK"
            },
            {
                "name": "Greve Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Guldborgsund Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Gundsømagle",
                "countryCode": "DK"
            },
            {
                "name": "Gørlev",
                "countryCode": "DK"
            },
            {
                "name": "Haslev",
                "countryCode": "DK"
            },
            {
                "name": "Havdrup",
                "countryCode": "DK"
            },
            {
                "name": "Holbæk",
                "countryCode": "DK"
            },
            {
                "name": "Holbæk Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Holeby",
                "countryCode": "DK"
            },
            {
                "name": "Hårlev",
                "countryCode": "DK"
            },
            {
                "name": "Høng",
                "countryCode": "DK"
            },
            {
                "name": "Hørve",
                "countryCode": "DK"
            },
            {
                "name": "Jyderup",
                "countryCode": "DK"
            },
            {
                "name": "Jyllinge",
                "countryCode": "DK"
            },
            {
                "name": "Kalundborg",
                "countryCode": "DK"
            },
            {
                "name": "Kalundborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Kirke Hvalsø",
                "countryCode": "DK"
            },
            {
                "name": "Korsør",
                "countryCode": "DK"
            },
            {
                "name": "Køge",
                "countryCode": "DK"
            },
            {
                "name": "Køge Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Lejre",
                "countryCode": "DK"
            },
            {
                "name": "Lejre Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Lolland Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Maribo",
                "countryCode": "DK"
            },
            {
                "name": "Nakskov",
                "countryCode": "DK"
            },
            {
                "name": "Neder Vindinge",
                "countryCode": "DK"
            },
            {
                "name": "Nykøbing Falster",
                "countryCode": "DK"
            },
            {
                "name": "Nykøbing Sjælland",
                "countryCode": "DK"
            },
            {
                "name": "Nyråd",
                "countryCode": "DK"
            },
            {
                "name": "Næstved",
                "countryCode": "DK"
            },
            {
                "name": "Næstved Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Nørre Alslev",
                "countryCode": "DK"
            },
            {
                "name": "Odsherred Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Osted",
                "countryCode": "DK"
            },
            {
                "name": "Præstø",
                "countryCode": "DK"
            },
            {
                "name": "Ringsted",
                "countryCode": "DK"
            },
            {
                "name": "Ringsted Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Roskilde",
                "countryCode": "DK"
            },
            {
                "name": "Roskilde Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Rødby",
                "countryCode": "DK"
            },
            {
                "name": "Rødbyhavn",
                "countryCode": "DK"
            },
            {
                "name": "Rødvig",
                "countryCode": "DK"
            },
            {
                "name": "Rønnede",
                "countryCode": "DK"
            },
            {
                "name": "Sakskøbing",
                "countryCode": "DK"
            },
            {
                "name": "Skælskør",
                "countryCode": "DK"
            },
            {
                "name": "Slagelse",
                "countryCode": "DK"
            },
            {
                "name": "Slagelse Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Solrød",
                "countryCode": "DK"
            },
            {
                "name": "Solrød Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Solrød Strand",
                "countryCode": "DK"
            },
            {
                "name": "Sorø",
                "countryCode": "DK"
            },
            {
                "name": "Sorø Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Stege",
                "countryCode": "DK"
            },
            {
                "name": "Stenlille",
                "countryCode": "DK"
            },
            {
                "name": "Stevns Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Store Heddinge",
                "countryCode": "DK"
            },
            {
                "name": "Strøby Egede",
                "countryCode": "DK"
            },
            {
                "name": "Stubbekøbing",
                "countryCode": "DK"
            },
            {
                "name": "Sundby",
                "countryCode": "DK"
            },
            {
                "name": "Svebølle",
                "countryCode": "DK"
            },
            {
                "name": "Svinninge",
                "countryCode": "DK"
            },
            {
                "name": "Svogerslev",
                "countryCode": "DK"
            },
            {
                "name": "Tune",
                "countryCode": "DK"
            },
            {
                "name": "Tølløse",
                "countryCode": "DK"
            },
            {
                "name": "Viby",
                "countryCode": "DK"
            },
            {
                "name": "Vindinge",
                "countryCode": "DK"
            },
            {
                "name": "Vipperød",
                "countryCode": "DK"
            },
            {
                "name": "Vordingborg",
                "countryCode": "DK"
            },
            {
                "name": "Vordingborg Kommune",
                "countryCode": "DK"
            },
            {
                "name": "Ørslev",
                "countryCode": "DK"
            }
        ]
    },
    {
        "name": "Djibouti",
        "phonecode": "253",
        "cities": [
            {
                "name": "Arta",
                "countryCode": "DJ"
            },
            {
                "name": "'Ali Sabieh",
                "countryCode": "DJ"
            },
            {
                "name": "Goubétto",
                "countryCode": "DJ"
            },
            {
                "name": "Holhol",
                "countryCode": "DJ"
            },
            {
                "name": "Dikhil",
                "countryCode": "DJ"
            },
            {
                "name": "Gâlâfi",
                "countryCode": "DJ"
            },
            {
                "name": "Djibouti",
                "countryCode": "DJ"
            },
            {
                "name": "Loyada",
                "countryCode": "DJ"
            },
            {
                "name": "Alaïli Ḏaḏḏa‘",
                "countryCode": "DJ"
            },
            {
                "name": "Obock",
                "countryCode": "DJ"
            },
            {
                "name": "Dorra",
                "countryCode": "DJ"
            },
            {
                "name": "Tadjourah",
                "countryCode": "DJ"
            }
        ]
    },
    {
        "name": "Dominica",
        "phonecode": "+1-767",
        "cities": [
            {
                "name": "Calibishie",
                "countryCode": "DM"
            },
            {
                "name": "Marigot",
                "countryCode": "DM"
            },
            {
                "name": "Wesley",
                "countryCode": "DM"
            },
            {
                "name": "Woodford Hill",
                "countryCode": "DM"
            },
            {
                "name": "Castle Bruce",
                "countryCode": "DM"
            },
            {
                "name": "Rosalie",
                "countryCode": "DM"
            },
            {
                "name": "Roseau",
                "countryCode": "DM"
            },
            {
                "name": "Portsmouth",
                "countryCode": "DM"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "DM"
            },
            {
                "name": "Salisbury",
                "countryCode": "DM"
            },
            {
                "name": "Pointe Michel",
                "countryCode": "DM"
            },
            {
                "name": "Soufrière",
                "countryCode": "DM"
            },
            {
                "name": "Berekua",
                "countryCode": "DM"
            },
            {
                "name": "La Plaine",
                "countryCode": "DM"
            },
            {
                "name": "Mahaut",
                "countryCode": "DM"
            },
            {
                "name": "Pont Cassé",
                "countryCode": "DM"
            },
            {
                "name": "Colihaut",
                "countryCode": "DM"
            }
        ]
    },
    {
        "name": "Dominican Republic",
        "phonecode": "+1-809 and 1-829",
        "cities": [
            {
                "name": "Bella Vista",
                "countryCode": "DO"
            },
            {
                "name": "Ciudad Nueva",
                "countryCode": "DO"
            },
            {
                "name": "Cristo Rey",
                "countryCode": "DO"
            },
            {
                "name": "Ensanche Luperón",
                "countryCode": "DO"
            },
            {
                "name": "La Agustina",
                "countryCode": "DO"
            },
            {
                "name": "La Julia",
                "countryCode": "DO"
            },
            {
                "name": "San Carlos",
                "countryCode": "DO"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "DO"
            },
            {
                "name": "Villa Consuelo",
                "countryCode": "DO"
            },
            {
                "name": "Villa Francisca",
                "countryCode": "DO"
            },
            {
                "name": "Azua",
                "countryCode": "DO"
            },
            {
                "name": "El Guayabal",
                "countryCode": "DO"
            },
            {
                "name": "Estebanía",
                "countryCode": "DO"
            },
            {
                "name": "Las Charcas",
                "countryCode": "DO"
            },
            {
                "name": "Padre Las Casas",
                "countryCode": "DO"
            },
            {
                "name": "Palmar de Ocoa",
                "countryCode": "DO"
            },
            {
                "name": "Peralta",
                "countryCode": "DO"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "DO"
            },
            {
                "name": "Sabana Yegua",
                "countryCode": "DO"
            },
            {
                "name": "Tábara Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Villarpando",
                "countryCode": "DO"
            },
            {
                "name": "Yayas de Viajama",
                "countryCode": "DO"
            },
            {
                "name": "El Palmar",
                "countryCode": "DO"
            },
            {
                "name": "Galván",
                "countryCode": "DO"
            },
            {
                "name": "La Uvilla",
                "countryCode": "DO"
            },
            {
                "name": "Los Ríos",
                "countryCode": "DO"
            },
            {
                "name": "Neiba",
                "countryCode": "DO"
            },
            {
                "name": "Tamayo",
                "countryCode": "DO"
            },
            {
                "name": "Villa Jaragua",
                "countryCode": "DO"
            },
            {
                "name": "Cabral",
                "countryCode": "DO"
            },
            {
                "name": "Cachón",
                "countryCode": "DO"
            },
            {
                "name": "Canoa",
                "countryCode": "DO"
            },
            {
                "name": "El Peñón",
                "countryCode": "DO"
            },
            {
                "name": "Enriquillo",
                "countryCode": "DO"
            },
            {
                "name": "Fundación",
                "countryCode": "DO"
            },
            {
                "name": "Jaquimeyes",
                "countryCode": "DO"
            },
            {
                "name": "La Ciénaga",
                "countryCode": "DO"
            },
            {
                "name": "Las Salinas",
                "countryCode": "DO"
            },
            {
                "name": "Paraíso",
                "countryCode": "DO"
            },
            {
                "name": "Pescadería",
                "countryCode": "DO"
            },
            {
                "name": "Polo",
                "countryCode": "DO"
            },
            {
                "name": "Santa Cruz de Barahona",
                "countryCode": "DO"
            },
            {
                "name": "Vicente Noble",
                "countryCode": "DO"
            },
            {
                "name": "Dajabón",
                "countryCode": "DO"
            },
            {
                "name": "El Pino",
                "countryCode": "DO"
            },
            {
                "name": "Loma de Cabrera",
                "countryCode": "DO"
            },
            {
                "name": "Partido",
                "countryCode": "DO"
            },
            {
                "name": "Restauración",
                "countryCode": "DO"
            },
            {
                "name": "Agua Santa del Yuna",
                "countryCode": "DO"
            },
            {
                "name": "Arenoso",
                "countryCode": "DO"
            },
            {
                "name": "Castillo",
                "countryCode": "DO"
            },
            {
                "name": "Hostos",
                "countryCode": "DO"
            },
            {
                "name": "Las Guáranas",
                "countryCode": "DO"
            },
            {
                "name": "Pimentel",
                "countryCode": "DO"
            },
            {
                "name": "San Francisco de Macorís",
                "countryCode": "DO"
            },
            {
                "name": "Villa Riva",
                "countryCode": "DO"
            },
            {
                "name": "Miches",
                "countryCode": "DO"
            },
            {
                "name": "Pedro Sánchez",
                "countryCode": "DO"
            },
            {
                "name": "Santa Cruz de El Seibo",
                "countryCode": "DO"
            },
            {
                "name": "Cayetano Germosén",
                "countryCode": "DO"
            },
            {
                "name": "Gaspar Hernández",
                "countryCode": "DO"
            },
            {
                "name": "Jamao al Norte",
                "countryCode": "DO"
            },
            {
                "name": "Joba Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Juan López Abajo",
                "countryCode": "DO"
            },
            {
                "name": "Moca",
                "countryCode": "DO"
            },
            {
                "name": "San Víctor Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Veragua Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Cristóbal",
                "countryCode": "DO"
            },
            {
                "name": "Duvergé",
                "countryCode": "DO"
            },
            {
                "name": "Guayabal",
                "countryCode": "DO"
            },
            {
                "name": "Jimaní",
                "countryCode": "DO"
            },
            {
                "name": "La Descubierta",
                "countryCode": "DO"
            },
            {
                "name": "Mella",
                "countryCode": "DO"
            },
            {
                "name": "Postrer Río",
                "countryCode": "DO"
            },
            {
                "name": "Boca de Yuma",
                "countryCode": "DO"
            },
            {
                "name": "Higüey",
                "countryCode": "DO"
            },
            {
                "name": "Otra Banda",
                "countryCode": "DO"
            },
            {
                "name": "Punta Cana",
                "countryCode": "DO"
            },
            {
                "name": "Salvaleón de Higüey",
                "countryCode": "DO"
            },
            {
                "name": "San Rafael del Yuma",
                "countryCode": "DO"
            },
            {
                "name": "Guaymate",
                "countryCode": "DO"
            },
            {
                "name": "La Romana",
                "countryCode": "DO"
            },
            {
                "name": "Concepción de La Vega",
                "countryCode": "DO"
            },
            {
                "name": "Constanza",
                "countryCode": "DO"
            },
            {
                "name": "Jarabacoa",
                "countryCode": "DO"
            },
            {
                "name": "Jima Abajo",
                "countryCode": "DO"
            },
            {
                "name": "Rincón",
                "countryCode": "DO"
            },
            {
                "name": "Río Verde Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Tireo Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Arroyo Salado",
                "countryCode": "DO"
            },
            {
                "name": "Cabrera",
                "countryCode": "DO"
            },
            {
                "name": "El Factor",
                "countryCode": "DO"
            },
            {
                "name": "La Entrada",
                "countryCode": "DO"
            },
            {
                "name": "Nagua",
                "countryCode": "DO"
            },
            {
                "name": "Río San Juan",
                "countryCode": "DO"
            },
            {
                "name": "Cana Chapetón",
                "countryCode": "DO"
            },
            {
                "name": "Castañuelas",
                "countryCode": "DO"
            },
            {
                "name": "Guayubín",
                "countryCode": "DO"
            },
            {
                "name": "Hatillo Palma",
                "countryCode": "DO"
            },
            {
                "name": "Las Matas de Santa Cruz",
                "countryCode": "DO"
            },
            {
                "name": "Monte Cristi",
                "countryCode": "DO"
            },
            {
                "name": "Pepillo Salcedo",
                "countryCode": "DO"
            },
            {
                "name": "San Fernando de Monte Cristi",
                "countryCode": "DO"
            },
            {
                "name": "Villa Elisa",
                "countryCode": "DO"
            },
            {
                "name": "Villa Vásquez",
                "countryCode": "DO"
            },
            {
                "name": "Juancho",
                "countryCode": "DO"
            },
            {
                "name": "Oviedo",
                "countryCode": "DO"
            },
            {
                "name": "Pedernales",
                "countryCode": "DO"
            },
            {
                "name": "Baní",
                "countryCode": "DO"
            },
            {
                "name": "Matanzas",
                "countryCode": "DO"
            },
            {
                "name": "Nizao",
                "countryCode": "DO"
            },
            {
                "name": "Paya",
                "countryCode": "DO"
            },
            {
                "name": "Pizarrete",
                "countryCode": "DO"
            },
            {
                "name": "Sabana Buey",
                "countryCode": "DO"
            },
            {
                "name": "Altamira",
                "countryCode": "DO"
            },
            {
                "name": "Cabarete",
                "countryCode": "DO"
            },
            {
                "name": "Estero Hondo",
                "countryCode": "DO"
            },
            {
                "name": "Guananico",
                "countryCode": "DO"
            },
            {
                "name": "Imbert",
                "countryCode": "DO"
            },
            {
                "name": "Los Hidalgos",
                "countryCode": "DO"
            },
            {
                "name": "Luperón",
                "countryCode": "DO"
            },
            {
                "name": "Monte Llano",
                "countryCode": "DO"
            },
            {
                "name": "Puerto Plata",
                "countryCode": "DO"
            },
            {
                "name": "Río Grande",
                "countryCode": "DO"
            },
            {
                "name": "Sosúa",
                "countryCode": "DO"
            },
            {
                "name": "Villa Isabela",
                "countryCode": "DO"
            },
            {
                "name": "Salcedo",
                "countryCode": "DO"
            },
            {
                "name": "Salsipuedes",
                "countryCode": "DO"
            },
            {
                "name": "Tenares",
                "countryCode": "DO"
            },
            {
                "name": "Villa Tapia",
                "countryCode": "DO"
            },
            {
                "name": "Las Terrenas",
                "countryCode": "DO"
            },
            {
                "name": "Samaná",
                "countryCode": "DO"
            },
            {
                "name": "Sánchez",
                "countryCode": "DO"
            },
            {
                "name": "Bajos de Haina",
                "countryCode": "DO"
            },
            {
                "name": "Cambita Garabitos",
                "countryCode": "DO"
            },
            {
                "name": "El Cacao",
                "countryCode": "DO"
            },
            {
                "name": "El Carril",
                "countryCode": "DO"
            },
            {
                "name": "Sabana Grande de Palenque",
                "countryCode": "DO"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "DO"
            },
            {
                "name": "San Gregorio de Nigua",
                "countryCode": "DO"
            },
            {
                "name": "Villa Altagracia",
                "countryCode": "DO"
            },
            {
                "name": "Yaguate",
                "countryCode": "DO"
            },
            {
                "name": "Bohechío",
                "countryCode": "DO"
            },
            {
                "name": "Cercado Abajo",
                "countryCode": "DO"
            },
            {
                "name": "El Cercado",
                "countryCode": "DO"
            },
            {
                "name": "Juan de Herrera",
                "countryCode": "DO"
            },
            {
                "name": "Las Matas de Farfán",
                "countryCode": "DO"
            },
            {
                "name": "Matayaya",
                "countryCode": "DO"
            },
            {
                "name": "Pedro Corto",
                "countryCode": "DO"
            },
            {
                "name": "San Juan de la Maguana",
                "countryCode": "DO"
            },
            {
                "name": "Vallejuelo",
                "countryCode": "DO"
            },
            {
                "name": "El Puerto",
                "countryCode": "DO"
            },
            {
                "name": "Los Llanos",
                "countryCode": "DO"
            },
            {
                "name": "Quisqueya",
                "countryCode": "DO"
            },
            {
                "name": "Ramón Santana",
                "countryCode": "DO"
            },
            {
                "name": "San Pedro de Macorís",
                "countryCode": "DO"
            },
            {
                "name": "Cevicos",
                "countryCode": "DO"
            },
            {
                "name": "Cotuí",
                "countryCode": "DO"
            },
            {
                "name": "Fantino",
                "countryCode": "DO"
            },
            {
                "name": "Baitoa",
                "countryCode": "DO"
            },
            {
                "name": "Bisonó",
                "countryCode": "DO"
            },
            {
                "name": "Juncalito Abajo",
                "countryCode": "DO"
            },
            {
                "name": "La Canela",
                "countryCode": "DO"
            },
            {
                "name": "Licey al Medio",
                "countryCode": "DO"
            },
            {
                "name": "Palmar Arriba",
                "countryCode": "DO"
            },
            {
                "name": "Pedro García",
                "countryCode": "DO"
            },
            {
                "name": "Sabana Iglesia",
                "countryCode": "DO"
            },
            {
                "name": "San José de Las Matas",
                "countryCode": "DO"
            },
            {
                "name": "Santiago de los Caballeros",
                "countryCode": "DO"
            },
            {
                "name": "Santo Tomás de Jánico",
                "countryCode": "DO"
            },
            {
                "name": "Tamboril",
                "countryCode": "DO"
            },
            {
                "name": "Villa Bisonó",
                "countryCode": "DO"
            },
            {
                "name": "Villa González",
                "countryCode": "DO"
            },
            {
                "name": "Monción",
                "countryCode": "DO"
            },
            {
                "name": "Sabaneta",
                "countryCode": "DO"
            },
            {
                "name": "San Ignacio de Sabaneta",
                "countryCode": "DO"
            },
            {
                "name": "Amina",
                "countryCode": "DO"
            },
            {
                "name": "Esperanza",
                "countryCode": "DO"
            },
            {
                "name": "Guatapanal",
                "countryCode": "DO"
            },
            {
                "name": "Jaibón",
                "countryCode": "DO"
            },
            {
                "name": "Jicomé",
                "countryCode": "DO"
            },
            {
                "name": "La Caya",
                "countryCode": "DO"
            },
            {
                "name": "Laguna Salada",
                "countryCode": "DO"
            },
            {
                "name": "Maizal",
                "countryCode": "DO"
            },
            {
                "name": "Mao",
                "countryCode": "DO"
            },
            {
                "name": "Bonao",
                "countryCode": "DO"
            },
            {
                "name": "Juan Adrián",
                "countryCode": "DO"
            },
            {
                "name": "Maimón",
                "countryCode": "DO"
            },
            {
                "name": "Piedra Blanca",
                "countryCode": "DO"
            },
            {
                "name": "Sabana del Puerto",
                "countryCode": "DO"
            },
            {
                "name": "Bayaguana",
                "countryCode": "DO"
            },
            {
                "name": "Don Juan",
                "countryCode": "DO"
            },
            {
                "name": "Esperalvillo",
                "countryCode": "DO"
            },
            {
                "name": "Gonzalo",
                "countryCode": "DO"
            },
            {
                "name": "Los Botados",
                "countryCode": "DO"
            },
            {
                "name": "Majagual",
                "countryCode": "DO"
            },
            {
                "name": "Monte Plata",
                "countryCode": "DO"
            },
            {
                "name": "Sabana Grande de Boyá",
                "countryCode": "DO"
            },
            {
                "name": "Yamasá",
                "countryCode": "DO"
            },
            {
                "name": "El Valle",
                "countryCode": "DO"
            },
            {
                "name": "Guayabo Dulce",
                "countryCode": "DO"
            },
            {
                "name": "Hato Mayor del Rey",
                "countryCode": "DO"
            },
            {
                "name": "Sabana de la Mar",
                "countryCode": "DO"
            },
            {
                "name": "San José de Ocoa",
                "countryCode": "DO"
            },
            {
                "name": "Boca Chica",
                "countryCode": "DO"
            },
            {
                "name": "Santo Domingo Este",
                "countryCode": "DO"
            },
            {
                "name": "Santo Domingo Oeste",
                "countryCode": "DO"
            }
        ]
    },
    {
        "name": "East Timor",
        "phonecode": "670",
        "cities": [
            {
                "name": "Aileu",
                "countryCode": "TL"
            },
            {
                "name": "Lequidoe",
                "countryCode": "TL"
            },
            {
                "name": "Remexio",
                "countryCode": "TL"
            },
            {
                "name": "Ainaro",
                "countryCode": "TL"
            },
            {
                "name": "Hato-Udo",
                "countryCode": "TL"
            },
            {
                "name": "Baguia",
                "countryCode": "TL"
            },
            {
                "name": "Baucau",
                "countryCode": "TL"
            },
            {
                "name": "Baukau",
                "countryCode": "TL"
            },
            {
                "name": "Laga",
                "countryCode": "TL"
            },
            {
                "name": "Quelicai",
                "countryCode": "TL"
            },
            {
                "name": "Vemasse",
                "countryCode": "TL"
            },
            {
                "name": "Venilale",
                "countryCode": "TL"
            },
            {
                "name": "Maliana",
                "countryCode": "TL"
            },
            {
                "name": "Fatumean",
                "countryCode": "TL"
            },
            {
                "name": "Fohorem",
                "countryCode": "TL"
            },
            {
                "name": "Maucatar",
                "countryCode": "TL"
            },
            {
                "name": "Suai",
                "countryCode": "TL"
            },
            {
                "name": "Tilomar",
                "countryCode": "TL"
            },
            {
                "name": "Atauro Island",
                "countryCode": "TL"
            },
            {
                "name": "Cristo Rei",
                "countryCode": "TL"
            },
            {
                "name": "Dili",
                "countryCode": "TL"
            },
            {
                "name": "Metinaro",
                "countryCode": "TL"
            },
            {
                "name": "Ermera Villa",
                "countryCode": "TL"
            },
            {
                "name": "Gleno",
                "countryCode": "TL"
            },
            {
                "name": "Hatulia",
                "countryCode": "TL"
            },
            {
                "name": "Letefoho",
                "countryCode": "TL"
            },
            {
                "name": "Railaco",
                "countryCode": "TL"
            },
            {
                "name": "Iliomar",
                "countryCode": "TL"
            },
            {
                "name": "Lautem",
                "countryCode": "TL"
            },
            {
                "name": "Lospalos",
                "countryCode": "TL"
            },
            {
                "name": "Luro",
                "countryCode": "TL"
            },
            {
                "name": "Tutuala",
                "countryCode": "TL"
            },
            {
                "name": "Bazartete",
                "countryCode": "TL"
            },
            {
                "name": "Likisá",
                "countryCode": "TL"
            },
            {
                "name": "Maubara",
                "countryCode": "TL"
            },
            {
                "name": "Alas",
                "countryCode": "TL"
            },
            {
                "name": "Fatuberliu",
                "countryCode": "TL"
            },
            {
                "name": "Same",
                "countryCode": "TL"
            },
            {
                "name": "Turiscai",
                "countryCode": "TL"
            },
            {
                "name": "Barique",
                "countryCode": "TL"
            },
            {
                "name": "Laclo",
                "countryCode": "TL"
            },
            {
                "name": "Laclubar",
                "countryCode": "TL"
            },
            {
                "name": "Manatuto",
                "countryCode": "TL"
            },
            {
                "name": "Manatutu",
                "countryCode": "TL"
            },
            {
                "name": "Soibada",
                "countryCode": "TL"
            },
            {
                "name": "Lacluta",
                "countryCode": "TL"
            },
            {
                "name": "Ossu",
                "countryCode": "TL"
            },
            {
                "name": "Uatocarabau",
                "countryCode": "TL"
            },
            {
                "name": "Uatolari",
                "countryCode": "TL"
            },
            {
                "name": "Viqueque",
                "countryCode": "TL"
            }
        ]
    },
    {
        "name": "Ecuador",
        "phonecode": "593",
        "cities": [
            {
                "name": "Cantón San Fernando",
                "countryCode": "EC"
            },
            {
                "name": "Cuenca",
                "countryCode": "EC"
            },
            {
                "name": "Gualaceo",
                "countryCode": "EC"
            },
            {
                "name": "La Unión",
                "countryCode": "EC"
            },
            {
                "name": "Llacao",
                "countryCode": "EC"
            },
            {
                "name": "Nulti",
                "countryCode": "EC"
            },
            {
                "name": "Guaranda",
                "countryCode": "EC"
            },
            {
                "name": "San Miguel",
                "countryCode": "EC"
            },
            {
                "name": "El Ángel",
                "countryCode": "EC"
            },
            {
                "name": "San Gabriel",
                "countryCode": "EC"
            },
            {
                "name": "Tulcán",
                "countryCode": "EC"
            },
            {
                "name": "Boca Suno",
                "countryCode": "EC"
            },
            {
                "name": "Francisco de Orellana Canton",
                "countryCode": "EC"
            },
            {
                "name": "Loreto Canton",
                "countryCode": "EC"
            },
            {
                "name": "Puerto Francisco de Orellana",
                "countryCode": "EC"
            },
            {
                "name": "Esmeraldas",
                "countryCode": "EC"
            },
            {
                "name": "Muisne",
                "countryCode": "EC"
            },
            {
                "name": "Pampanal de Bolívar",
                "countryCode": "EC"
            },
            {
                "name": "Rio Verde",
                "countryCode": "EC"
            },
            {
                "name": "Rosa Zarate",
                "countryCode": "EC"
            },
            {
                "name": "San Lorenzo de Esmeraldas",
                "countryCode": "EC"
            },
            {
                "name": "Valdez",
                "countryCode": "EC"
            },
            {
                "name": "Azogues",
                "countryCode": "EC"
            },
            {
                "name": "Cañar",
                "countryCode": "EC"
            },
            {
                "name": "La Troncal",
                "countryCode": "EC"
            },
            {
                "name": "Alfredo Baquerizo Moreno",
                "countryCode": "EC"
            },
            {
                "name": "Balzar",
                "countryCode": "EC"
            },
            {
                "name": "Baláo",
                "countryCode": "EC"
            },
            {
                "name": "Colimes",
                "countryCode": "EC"
            },
            {
                "name": "Coronel Marcelino Maridueña",
                "countryCode": "EC"
            },
            {
                "name": "El Triunfo",
                "countryCode": "EC"
            },
            {
                "name": "Eloy Alfaro",
                "countryCode": "EC"
            },
            {
                "name": "Guayaquil",
                "countryCode": "EC"
            },
            {
                "name": "Lomas de Sargentillo",
                "countryCode": "EC"
            },
            {
                "name": "Milagro",
                "countryCode": "EC"
            },
            {
                "name": "Naranjal",
                "countryCode": "EC"
            },
            {
                "name": "Naranjito",
                "countryCode": "EC"
            },
            {
                "name": "Palestina",
                "countryCode": "EC"
            },
            {
                "name": "Pedro Carbo",
                "countryCode": "EC"
            },
            {
                "name": "Playas",
                "countryCode": "EC"
            },
            {
                "name": "Samborondón",
                "countryCode": "EC"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "EC"
            },
            {
                "name": "Velasco Ibarra",
                "countryCode": "EC"
            },
            {
                "name": "Yaguachi Nuevo",
                "countryCode": "EC"
            },
            {
                "name": "Alausí",
                "countryCode": "EC"
            },
            {
                "name": "Guano",
                "countryCode": "EC"
            },
            {
                "name": "Riobamba",
                "countryCode": "EC"
            },
            {
                "name": "Atuntaqui",
                "countryCode": "EC"
            },
            {
                "name": "Cotacachi",
                "countryCode": "EC"
            },
            {
                "name": "Ibarra",
                "countryCode": "EC"
            },
            {
                "name": "Otavalo",
                "countryCode": "EC"
            },
            {
                "name": "Pimampiro",
                "countryCode": "EC"
            },
            {
                "name": "Loja",
                "countryCode": "EC"
            },
            {
                "name": "Bahía de Caráquez",
                "countryCode": "EC"
            },
            {
                "name": "Calceta",
                "countryCode": "EC"
            },
            {
                "name": "Cantón Portoviejo",
                "countryCode": "EC"
            },
            {
                "name": "Chone",
                "countryCode": "EC"
            },
            {
                "name": "Jipijapa",
                "countryCode": "EC"
            },
            {
                "name": "Junín",
                "countryCode": "EC"
            },
            {
                "name": "Manta",
                "countryCode": "EC"
            },
            {
                "name": "Montecristi",
                "countryCode": "EC"
            },
            {
                "name": "Paján",
                "countryCode": "EC"
            },
            {
                "name": "Pedernales",
                "countryCode": "EC"
            },
            {
                "name": "Portoviejo",
                "countryCode": "EC"
            },
            {
                "name": "Rocafuerte",
                "countryCode": "EC"
            },
            {
                "name": "San Vicente",
                "countryCode": "EC"
            },
            {
                "name": "Santa Ana",
                "countryCode": "EC"
            },
            {
                "name": "Sucre",
                "countryCode": "EC"
            },
            {
                "name": "Tosagua",
                "countryCode": "EC"
            },
            {
                "name": "Archidona",
                "countryCode": "EC"
            },
            {
                "name": "Tena",
                "countryCode": "EC"
            },
            {
                "name": "Huaquillas",
                "countryCode": "EC"
            },
            {
                "name": "Machala",
                "countryCode": "EC"
            },
            {
                "name": "Pasaje",
                "countryCode": "EC"
            },
            {
                "name": "Piñas",
                "countryCode": "EC"
            },
            {
                "name": "Portovelo",
                "countryCode": "EC"
            },
            {
                "name": "Puerto Bolívar",
                "countryCode": "EC"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "EC"
            },
            {
                "name": "Zaruma",
                "countryCode": "EC"
            },
            {
                "name": "Cayambe",
                "countryCode": "EC"
            },
            {
                "name": "Machachi",
                "countryCode": "EC"
            },
            {
                "name": "Quito",
                "countryCode": "EC"
            },
            {
                "name": "Sangolquí",
                "countryCode": "EC"
            },
            {
                "name": "Tutamandahostel",
                "countryCode": "EC"
            },
            {
                "name": "Babahoyo",
                "countryCode": "EC"
            },
            {
                "name": "Catarama",
                "countryCode": "EC"
            },
            {
                "name": "Montalvo",
                "countryCode": "EC"
            },
            {
                "name": "Palenque",
                "countryCode": "EC"
            },
            {
                "name": "Quevedo",
                "countryCode": "EC"
            },
            {
                "name": "Ventanas",
                "countryCode": "EC"
            },
            {
                "name": "Vinces",
                "countryCode": "EC"
            },
            {
                "name": "Gualaquiza",
                "countryCode": "EC"
            },
            {
                "name": "Macas",
                "countryCode": "EC"
            },
            {
                "name": "Palora",
                "countryCode": "EC"
            },
            {
                "name": "Sucúa",
                "countryCode": "EC"
            },
            {
                "name": "Santo Domingo de los Colorados",
                "countryCode": "EC"
            },
            {
                "name": "La Libertad",
                "countryCode": "EC"
            },
            {
                "name": "Salinas",
                "countryCode": "EC"
            },
            {
                "name": "Santa Elena",
                "countryCode": "EC"
            },
            {
                "name": "Ambato",
                "countryCode": "EC"
            },
            {
                "name": "Baños",
                "countryCode": "EC"
            },
            {
                "name": "Pelileo",
                "countryCode": "EC"
            },
            {
                "name": "Píllaro",
                "countryCode": "EC"
            },
            {
                "name": "Nueva Loja",
                "countryCode": "EC"
            },
            {
                "name": "Puerto Ayora",
                "countryCode": "EC"
            },
            {
                "name": "Puerto Baquerizo Moreno",
                "countryCode": "EC"
            },
            {
                "name": "Puerto Villamil",
                "countryCode": "EC"
            },
            {
                "name": "La Maná",
                "countryCode": "EC"
            },
            {
                "name": "Latacunga",
                "countryCode": "EC"
            },
            {
                "name": "Pujilí",
                "countryCode": "EC"
            },
            {
                "name": "San Miguel de Salcedo",
                "countryCode": "EC"
            },
            {
                "name": "Saquisilí",
                "countryCode": "EC"
            },
            {
                "name": "Puyo",
                "countryCode": "EC"
            },
            {
                "name": "Yantzaza",
                "countryCode": "EC"
            },
            {
                "name": "Zamora",
                "countryCode": "EC"
            }
        ]
    },
    {
        "name": "Egypt",
        "phonecode": "20",
        "cities": [
            {
                "name": "Alexandria",
                "countryCode": "EG"
            },
            {
                "name": "Abu Simbel",
                "countryCode": "EG"
            },
            {
                "name": "Aswan",
                "countryCode": "EG"
            },
            {
                "name": "Idfū",
                "countryCode": "EG"
            },
            {
                "name": "Kawm Umbū",
                "countryCode": "EG"
            },
            {
                "name": "Abnūb",
                "countryCode": "EG"
            },
            {
                "name": "Abū Tīj",
                "countryCode": "EG"
            },
            {
                "name": "Al Badārī",
                "countryCode": "EG"
            },
            {
                "name": "Al Qūşīyah",
                "countryCode": "EG"
            },
            {
                "name": "Asyūţ",
                "countryCode": "EG"
            },
            {
                "name": "Dayrūţ",
                "countryCode": "EG"
            },
            {
                "name": "Manfalūţ",
                "countryCode": "EG"
            },
            {
                "name": "Al Quşayr",
                "countryCode": "EG"
            },
            {
                "name": "El Gouna",
                "countryCode": "EG"
            },
            {
                "name": "Hurghada",
                "countryCode": "EG"
            },
            {
                "name": "Makadi Bay",
                "countryCode": "EG"
            },
            {
                "name": "Marsa Alam",
                "countryCode": "EG"
            },
            {
                "name": "Ras Gharib",
                "countryCode": "EG"
            },
            {
                "name": "Safaga",
                "countryCode": "EG"
            },
            {
                "name": "Abū al Maţāmīr",
                "countryCode": "EG"
            },
            {
                "name": "Ad Dilinjāt",
                "countryCode": "EG"
            },
            {
                "name": "Damanhūr",
                "countryCode": "EG"
            },
            {
                "name": "Idkū",
                "countryCode": "EG"
            },
            {
                "name": "Kafr ad Dawwār",
                "countryCode": "EG"
            },
            {
                "name": "Kawm Ḩamādah",
                "countryCode": "EG"
            },
            {
                "name": "Rosetta",
                "countryCode": "EG"
            },
            {
                "name": "Ḩawsh ‘Īsá",
                "countryCode": "EG"
            },
            {
                "name": "Al Fashn",
                "countryCode": "EG"
            },
            {
                "name": "Banī Suwayf",
                "countryCode": "EG"
            },
            {
                "name": "Būsh",
                "countryCode": "EG"
            },
            {
                "name": "Sumusţā as Sulţānī",
                "countryCode": "EG"
            },
            {
                "name": "Cairo",
                "countryCode": "EG"
            },
            {
                "name": "New Cairo",
                "countryCode": "EG"
            },
            {
                "name": "Ḩalwān",
                "countryCode": "EG"
            },
            {
                "name": "Ajā",
                "countryCode": "EG"
            },
            {
                "name": "Al Jammālīyah",
                "countryCode": "EG"
            },
            {
                "name": "Al Manzalah",
                "countryCode": "EG"
            },
            {
                "name": "Al Manşūrah",
                "countryCode": "EG"
            },
            {
                "name": "Al Maţarīyah",
                "countryCode": "EG"
            },
            {
                "name": "Bilqās",
                "countryCode": "EG"
            },
            {
                "name": "Dikirnis",
                "countryCode": "EG"
            },
            {
                "name": "Minyat an Naşr",
                "countryCode": "EG"
            },
            {
                "name": "Shirbīn",
                "countryCode": "EG"
            },
            {
                "name": "Ţalkhā",
                "countryCode": "EG"
            },
            {
                "name": "‘Izbat al Burj",
                "countryCode": "EG"
            },
            {
                "name": "Az Zarqā",
                "countryCode": "EG"
            },
            {
                "name": "Damietta",
                "countryCode": "EG"
            },
            {
                "name": "Fāraskūr",
                "countryCode": "EG"
            },
            {
                "name": "Al Fayyūm",
                "countryCode": "EG"
            },
            {
                "name": "Al Wāsiţah",
                "countryCode": "EG"
            },
            {
                "name": "Ibshawāy",
                "countryCode": "EG"
            },
            {
                "name": "Iţsā",
                "countryCode": "EG"
            },
            {
                "name": "Ţāmiyah",
                "countryCode": "EG"
            },
            {
                "name": "Al Maḩallah al Kubrá",
                "countryCode": "EG"
            },
            {
                "name": "Basyūn",
                "countryCode": "EG"
            },
            {
                "name": "Kafr az Zayyāt",
                "countryCode": "EG"
            },
            {
                "name": "Quţūr",
                "countryCode": "EG"
            },
            {
                "name": "Samannūd",
                "countryCode": "EG"
            },
            {
                "name": "Tanda",
                "countryCode": "EG"
            },
            {
                "name": "Zefta",
                "countryCode": "EG"
            },
            {
                "name": "Al Bawīţī",
                "countryCode": "EG"
            },
            {
                "name": "Al Ḩawāmidīyah",
                "countryCode": "EG"
            },
            {
                "name": "Al ‘Ayyāţ",
                "countryCode": "EG"
            },
            {
                "name": "Awsīm",
                "countryCode": "EG"
            },
            {
                "name": "Aş Şaff",
                "countryCode": "EG"
            },
            {
                "name": "Giza",
                "countryCode": "EG"
            },
            {
                "name": "Madīnat Sittah Uktūbar",
                "countryCode": "EG"
            },
            {
                "name": "Ismailia",
                "countryCode": "EG"
            },
            {
                "name": "Dahab",
                "countryCode": "EG"
            },
            {
                "name": "El-Tor",
                "countryCode": "EG"
            },
            {
                "name": "Nuwaybi‘a",
                "countryCode": "EG"
            },
            {
                "name": "Saint Catherine",
                "countryCode": "EG"
            },
            {
                "name": "Sharm el-Sheikh",
                "countryCode": "EG"
            },
            {
                "name": "Al Khānkah",
                "countryCode": "EG"
            },
            {
                "name": "Al Qanāţir al Khayrīyah",
                "countryCode": "EG"
            },
            {
                "name": "Banhā",
                "countryCode": "EG"
            },
            {
                "name": "Qalyūb",
                "countryCode": "EG"
            },
            {
                "name": "Shibīn al Qanāṭir",
                "countryCode": "EG"
            },
            {
                "name": "Toukh",
                "countryCode": "EG"
            },
            {
                "name": "Al Ḩāmūl",
                "countryCode": "EG"
            },
            {
                "name": "Disūq",
                "countryCode": "EG"
            },
            {
                "name": "Fuwwah",
                "countryCode": "EG"
            },
            {
                "name": "Kafr ash Shaykh",
                "countryCode": "EG"
            },
            {
                "name": "Markaz Disūq",
                "countryCode": "EG"
            },
            {
                "name": "Munshāt ‘Alī Āghā",
                "countryCode": "EG"
            },
            {
                "name": "Sīdī Sālim",
                "countryCode": "EG"
            },
            {
                "name": "Dishnā",
                "countryCode": "EG"
            },
            {
                "name": "Farshūţ",
                "countryCode": "EG"
            },
            {
                "name": "Isnā",
                "countryCode": "EG"
            },
            {
                "name": "Kousa",
                "countryCode": "EG"
            },
            {
                "name": "Naja' Ḥammādī",
                "countryCode": "EG"
            },
            {
                "name": "Qinā",
                "countryCode": "EG"
            },
            {
                "name": "Luxor",
                "countryCode": "EG"
            },
            {
                "name": "Markaz al Uqşur",
                "countryCode": "EG"
            },
            {
                "name": "Abū Qurqāş",
                "countryCode": "EG"
            },
            {
                "name": "Al Minyā",
                "countryCode": "EG"
            },
            {
                "name": "Banī Mazār",
                "countryCode": "EG"
            },
            {
                "name": "Dayr Mawās",
                "countryCode": "EG"
            },
            {
                "name": "Mallawī",
                "countryCode": "EG"
            },
            {
                "name": "Maţāy",
                "countryCode": "EG"
            },
            {
                "name": "Samālūţ",
                "countryCode": "EG"
            },
            {
                "name": "Al Bājūr",
                "countryCode": "EG"
            },
            {
                "name": "Ash Shuhadā’",
                "countryCode": "EG"
            },
            {
                "name": "Ashmūn",
                "countryCode": "EG"
            },
            {
                "name": "Munūf",
                "countryCode": "EG"
            },
            {
                "name": "Quwaysinā",
                "countryCode": "EG"
            },
            {
                "name": "Shibīn al Kawm",
                "countryCode": "EG"
            },
            {
                "name": "Talā",
                "countryCode": "EG"
            },
            {
                "name": "Al ‘Alamayn",
                "countryCode": "EG"
            },
            {
                "name": "Mersa Matruh",
                "countryCode": "EG"
            },
            {
                "name": "Siwa Oasis",
                "countryCode": "EG"
            },
            {
                "name": "Port Said",
                "countryCode": "EG"
            },
            {
                "name": "Akhmīm",
                "countryCode": "EG"
            },
            {
                "name": "Al Balyanā",
                "countryCode": "EG"
            },
            {
                "name": "Al Manshāh",
                "countryCode": "EG"
            },
            {
                "name": "Jirjā",
                "countryCode": "EG"
            },
            {
                "name": "Juhaynah",
                "countryCode": "EG"
            },
            {
                "name": "Markaz Jirjā",
                "countryCode": "EG"
            },
            {
                "name": "Markaz Sūhāj",
                "countryCode": "EG"
            },
            {
                "name": "Sohag",
                "countryCode": "EG"
            },
            {
                "name": "Ţahţā",
                "countryCode": "EG"
            },
            {
                "name": "10th of Ramadan",
                "countryCode": "EG"
            },
            {
                "name": "Al Qurein",
                "countryCode": "EG"
            },
            {
                "name": "Awlad Saqr",
                "countryCode": "EG"
            },
            {
                "name": "Bilbeis",
                "countryCode": "EG"
            },
            {
                "name": "Diyarb Negm",
                "countryCode": "EG"
            },
            {
                "name": "El Husseiniya",
                "countryCode": "EG"
            },
            {
                "name": "Faqous",
                "countryCode": "EG"
            },
            {
                "name": "Hihya",
                "countryCode": "EG"
            },
            {
                "name": "Kafr Saqr",
                "countryCode": "EG"
            },
            {
                "name": "Markaz Abū Ḩammād",
                "countryCode": "EG"
            },
            {
                "name": "Mashtoul El Souk",
                "countryCode": "EG"
            },
            {
                "name": "Minya El Qamh",
                "countryCode": "EG"
            },
            {
                "name": "New Salhia",
                "countryCode": "EG"
            },
            {
                "name": "Zagazig",
                "countryCode": "EG"
            },
            {
                "name": "Arish",
                "countryCode": "EG"
            },
            {
                "name": "Ain Sukhna",
                "countryCode": "EG"
            },
            {
                "name": "Suez",
                "countryCode": "EG"
            },
            {
                "name": "Al Khārijah",
                "countryCode": "EG"
            },
            {
                "name": "Qaşr al Farāfirah",
                "countryCode": "EG"
            }
        ]
    },
    {
        "name": "El Salvador",
        "phonecode": "503",
        "cities": [
            {
                "name": "Ahuachapán",
                "countryCode": "SV"
            },
            {
                "name": "Atiquizaya",
                "countryCode": "SV"
            },
            {
                "name": "Concepción de Ataco",
                "countryCode": "SV"
            },
            {
                "name": "Guaymango",
                "countryCode": "SV"
            },
            {
                "name": "Jujutla",
                "countryCode": "SV"
            },
            {
                "name": "San Francisco Menéndez",
                "countryCode": "SV"
            },
            {
                "name": "Tacuba",
                "countryCode": "SV"
            },
            {
                "name": "Sensuntepeque",
                "countryCode": "SV"
            },
            {
                "name": "Victoria",
                "countryCode": "SV"
            },
            {
                "name": "Chalatenango",
                "countryCode": "SV"
            },
            {
                "name": "Nueva Concepción",
                "countryCode": "SV"
            },
            {
                "name": "Cojutepeque",
                "countryCode": "SV"
            },
            {
                "name": "San Martín",
                "countryCode": "SV"
            },
            {
                "name": "Suchitoto",
                "countryCode": "SV"
            },
            {
                "name": "Tecoluca",
                "countryCode": "SV"
            },
            {
                "name": "Tenancingo",
                "countryCode": "SV"
            },
            {
                "name": "Antiguo Cuscatlán",
                "countryCode": "SV"
            },
            {
                "name": "Ciudad Arce",
                "countryCode": "SV"
            },
            {
                "name": "La Libertad",
                "countryCode": "SV"
            },
            {
                "name": "Nuevo Cuscatlán",
                "countryCode": "SV"
            },
            {
                "name": "Quezaltepeque",
                "countryCode": "SV"
            },
            {
                "name": "San Juan Opico",
                "countryCode": "SV"
            },
            {
                "name": "San Pablo Tacachico",
                "countryCode": "SV"
            },
            {
                "name": "Santa Tecla",
                "countryCode": "SV"
            },
            {
                "name": "Zaragoza",
                "countryCode": "SV"
            },
            {
                "name": "Cacaopera",
                "countryCode": "SV"
            },
            {
                "name": "Corinto",
                "countryCode": "SV"
            },
            {
                "name": "Guatajiagua",
                "countryCode": "SV"
            },
            {
                "name": "Jocoro",
                "countryCode": "SV"
            },
            {
                "name": "San Francisco",
                "countryCode": "SV"
            },
            {
                "name": "Sociedad",
                "countryCode": "SV"
            },
            {
                "name": "El Rosario",
                "countryCode": "SV"
            },
            {
                "name": "Olocuilta",
                "countryCode": "SV"
            },
            {
                "name": "San Pedro Masahuat",
                "countryCode": "SV"
            },
            {
                "name": "Santiago Nonualco",
                "countryCode": "SV"
            },
            {
                "name": "Zacatecoluca",
                "countryCode": "SV"
            },
            {
                "name": "Candelaria de La Frontera",
                "countryCode": "SV"
            },
            {
                "name": "Chalchuapa",
                "countryCode": "SV"
            },
            {
                "name": "Coatepeque",
                "countryCode": "SV"
            },
            {
                "name": "El Congo",
                "countryCode": "SV"
            },
            {
                "name": "Metapán",
                "countryCode": "SV"
            },
            {
                "name": "Santa Ana",
                "countryCode": "SV"
            },
            {
                "name": "Texistepeque",
                "countryCode": "SV"
            },
            {
                "name": "Chapeltique",
                "countryCode": "SV"
            },
            {
                "name": "Chinameca",
                "countryCode": "SV"
            },
            {
                "name": "Chirilagua",
                "countryCode": "SV"
            },
            {
                "name": "Ciudad Barrios",
                "countryCode": "SV"
            },
            {
                "name": "El Tránsito",
                "countryCode": "SV"
            },
            {
                "name": "Lolotique",
                "countryCode": "SV"
            },
            {
                "name": "Moncagua",
                "countryCode": "SV"
            },
            {
                "name": "Nueva Guadalupe",
                "countryCode": "SV"
            },
            {
                "name": "San Miguel",
                "countryCode": "SV"
            },
            {
                "name": "San Rafael Oriente",
                "countryCode": "SV"
            },
            {
                "name": "Sesori",
                "countryCode": "SV"
            },
            {
                "name": "Acajutla",
                "countryCode": "SV"
            },
            {
                "name": "Armenia",
                "countryCode": "SV"
            },
            {
                "name": "Izalco",
                "countryCode": "SV"
            },
            {
                "name": "Juayúa",
                "countryCode": "SV"
            },
            {
                "name": "Nahuizalco",
                "countryCode": "SV"
            },
            {
                "name": "San Antonio del Monte",
                "countryCode": "SV"
            },
            {
                "name": "Sonsonate",
                "countryCode": "SV"
            },
            {
                "name": "Sonzacate",
                "countryCode": "SV"
            },
            {
                "name": "Aguilares",
                "countryCode": "SV"
            },
            {
                "name": "Apopa",
                "countryCode": "SV"
            },
            {
                "name": "Ayutuxtepeque",
                "countryCode": "SV"
            },
            {
                "name": "Cuscatancingo",
                "countryCode": "SV"
            },
            {
                "name": "Delgado",
                "countryCode": "SV"
            },
            {
                "name": "El Paisnal",
                "countryCode": "SV"
            },
            {
                "name": "Guazapa",
                "countryCode": "SV"
            },
            {
                "name": "Ilopango",
                "countryCode": "SV"
            },
            {
                "name": "Mejicanos",
                "countryCode": "SV"
            },
            {
                "name": "Panchimalco",
                "countryCode": "SV"
            },
            {
                "name": "Rosario de Mora",
                "countryCode": "SV"
            },
            {
                "name": "San Marcos",
                "countryCode": "SV"
            },
            {
                "name": "San Salvador",
                "countryCode": "SV"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "SV"
            },
            {
                "name": "Soyapango",
                "countryCode": "SV"
            },
            {
                "name": "Tonacatepeque",
                "countryCode": "SV"
            },
            {
                "name": "Apastepeque",
                "countryCode": "SV"
            },
            {
                "name": "San Sebastián",
                "countryCode": "SV"
            },
            {
                "name": "San Vicente",
                "countryCode": "SV"
            },
            {
                "name": "Anamorós",
                "countryCode": "SV"
            },
            {
                "name": "Conchagua",
                "countryCode": "SV"
            },
            {
                "name": "Intipucá",
                "countryCode": "SV"
            },
            {
                "name": "La Unión",
                "countryCode": "SV"
            },
            {
                "name": "Nueva Esparta",
                "countryCode": "SV"
            },
            {
                "name": "Pasaquina",
                "countryCode": "SV"
            },
            {
                "name": "San Alejo",
                "countryCode": "SV"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "SV"
            },
            {
                "name": "Berlín",
                "countryCode": "SV"
            },
            {
                "name": "Concepción Batres",
                "countryCode": "SV"
            },
            {
                "name": "Jiquilisco",
                "countryCode": "SV"
            },
            {
                "name": "Jucuapa",
                "countryCode": "SV"
            },
            {
                "name": "Jucuarán",
                "countryCode": "SV"
            },
            {
                "name": "Ozatlán",
                "countryCode": "SV"
            },
            {
                "name": "Puerto El Triunfo",
                "countryCode": "SV"
            },
            {
                "name": "San Agustín",
                "countryCode": "SV"
            },
            {
                "name": "Santa Elena",
                "countryCode": "SV"
            },
            {
                "name": "Santiago de María",
                "countryCode": "SV"
            },
            {
                "name": "Usulután",
                "countryCode": "SV"
            }
        ]
    },
    {
        "name": "Equatorial Guinea",
        "phonecode": "240",
        "cities": [
            {
                "name": "San Antonio de Palé",
                "countryCode": "GQ"
            },
            {
                "name": "Malabo",
                "countryCode": "GQ"
            },
            {
                "name": "Rebola",
                "countryCode": "GQ"
            },
            {
                "name": "Santiago de Baney",
                "countryCode": "GQ"
            },
            {
                "name": "Luba",
                "countryCode": "GQ"
            },
            {
                "name": "Acurenam",
                "countryCode": "GQ"
            },
            {
                "name": "Bicurga",
                "countryCode": "GQ"
            },
            {
                "name": "Evinayong",
                "countryCode": "GQ"
            },
            {
                "name": "Ebebiyin",
                "countryCode": "GQ"
            },
            {
                "name": "Mikomeseng",
                "countryCode": "GQ"
            },
            {
                "name": "Ncue",
                "countryCode": "GQ"
            },
            {
                "name": "Nsang",
                "countryCode": "GQ"
            },
            {
                "name": "Bata",
                "countryCode": "GQ"
            },
            {
                "name": "Bitica",
                "countryCode": "GQ"
            },
            {
                "name": "Cogo",
                "countryCode": "GQ"
            },
            {
                "name": "Machinda",
                "countryCode": "GQ"
            },
            {
                "name": "Mbini",
                "countryCode": "GQ"
            },
            {
                "name": "Río Campo",
                "countryCode": "GQ"
            },
            {
                "name": "Aconibe",
                "countryCode": "GQ"
            },
            {
                "name": "Ayene",
                "countryCode": "GQ"
            },
            {
                "name": "Añisoc",
                "countryCode": "GQ"
            },
            {
                "name": "Mengomeyén",
                "countryCode": "GQ"
            },
            {
                "name": "Mongomo",
                "countryCode": "GQ"
            },
            {
                "name": "Nsok",
                "countryCode": "GQ"
            }
        ]
    },
    {
        "name": "Eritrea",
        "phonecode": "291",
        "cities": [
            {
                "name": "Keren",
                "countryCode": "ER"
            },
            {
                "name": "Assab",
                "countryCode": "ER"
            },
            {
                "name": "Edd",
                "countryCode": "ER"
            },
            {
                "name": "Adi Keyh",
                "countryCode": "ER"
            },
            {
                "name": "Dek’emhāre",
                "countryCode": "ER"
            },
            {
                "name": "Mendefera",
                "countryCode": "ER"
            },
            {
                "name": "Ak’ordat",
                "countryCode": "ER"
            },
            {
                "name": "Barentu",
                "countryCode": "ER"
            },
            {
                "name": "Teseney",
                "countryCode": "ER"
            },
            {
                "name": "Asmara",
                "countryCode": "ER"
            },
            {
                "name": "Massawa",
                "countryCode": "ER"
            }
        ]
    },
    {
        "name": "Estonia",
        "phonecode": "372",
        "cities": [
            {
                "name": "Anija vald",
                "countryCode": "EE"
            },
            {
                "name": "Aruküla",
                "countryCode": "EE"
            },
            {
                "name": "Haabneeme",
                "countryCode": "EE"
            },
            {
                "name": "Harku",
                "countryCode": "EE"
            },
            {
                "name": "Harku vald",
                "countryCode": "EE"
            },
            {
                "name": "Jõelähtme vald",
                "countryCode": "EE"
            },
            {
                "name": "Jüri",
                "countryCode": "EE"
            },
            {
                "name": "Kehra",
                "countryCode": "EE"
            },
            {
                "name": "Keila",
                "countryCode": "EE"
            },
            {
                "name": "Kiili",
                "countryCode": "EE"
            },
            {
                "name": "Kiili vald",
                "countryCode": "EE"
            },
            {
                "name": "Kose",
                "countryCode": "EE"
            },
            {
                "name": "Kuusalu",
                "countryCode": "EE"
            },
            {
                "name": "Laagri",
                "countryCode": "EE"
            },
            {
                "name": "Loksa",
                "countryCode": "EE"
            },
            {
                "name": "Loksa linn",
                "countryCode": "EE"
            },
            {
                "name": "Loo",
                "countryCode": "EE"
            },
            {
                "name": "Maardu",
                "countryCode": "EE"
            },
            {
                "name": "Maardu linn",
                "countryCode": "EE"
            },
            {
                "name": "Paldiski",
                "countryCode": "EE"
            },
            {
                "name": "Pringi",
                "countryCode": "EE"
            },
            {
                "name": "Raasiku",
                "countryCode": "EE"
            },
            {
                "name": "Rae vald",
                "countryCode": "EE"
            },
            {
                "name": "Riisipere",
                "countryCode": "EE"
            },
            {
                "name": "Rummu",
                "countryCode": "EE"
            },
            {
                "name": "Saku",
                "countryCode": "EE"
            },
            {
                "name": "Saku vald",
                "countryCode": "EE"
            },
            {
                "name": "Saue",
                "countryCode": "EE"
            },
            {
                "name": "Saue vald",
                "countryCode": "EE"
            },
            {
                "name": "Tabasalu",
                "countryCode": "EE"
            },
            {
                "name": "Tallinn",
                "countryCode": "EE"
            },
            {
                "name": "Turba",
                "countryCode": "EE"
            },
            {
                "name": "Vaida",
                "countryCode": "EE"
            },
            {
                "name": "Viimsi",
                "countryCode": "EE"
            },
            {
                "name": "Viimsi vald",
                "countryCode": "EE"
            },
            {
                "name": "Kärdla",
                "countryCode": "EE"
            },
            {
                "name": "Iisaku",
                "countryCode": "EE"
            },
            {
                "name": "Jõhvi",
                "countryCode": "EE"
            },
            {
                "name": "Jõhvi vald",
                "countryCode": "EE"
            },
            {
                "name": "Kiviõli",
                "countryCode": "EE"
            },
            {
                "name": "Kohtla-Järve",
                "countryCode": "EE"
            },
            {
                "name": "Kohtla-Nõmme",
                "countryCode": "EE"
            },
            {
                "name": "Lüganuse vald",
                "countryCode": "EE"
            },
            {
                "name": "Narva",
                "countryCode": "EE"
            },
            {
                "name": "Narva-Jõesuu",
                "countryCode": "EE"
            },
            {
                "name": "Narva-Jõesuu linn",
                "countryCode": "EE"
            },
            {
                "name": "Püssi",
                "countryCode": "EE"
            },
            {
                "name": "Sillamäe",
                "countryCode": "EE"
            },
            {
                "name": "Toila",
                "countryCode": "EE"
            },
            {
                "name": "Voka",
                "countryCode": "EE"
            },
            {
                "name": "Jõgeva",
                "countryCode": "EE"
            },
            {
                "name": "Jõgeva vald",
                "countryCode": "EE"
            },
            {
                "name": "Mustvee",
                "countryCode": "EE"
            },
            {
                "name": "Põltsamaa",
                "countryCode": "EE"
            },
            {
                "name": "Põltsamaa vald",
                "countryCode": "EE"
            },
            {
                "name": "Järva-Jaani",
                "countryCode": "EE"
            },
            {
                "name": "Koeru",
                "countryCode": "EE"
            },
            {
                "name": "Paide",
                "countryCode": "EE"
            },
            {
                "name": "Paide linn",
                "countryCode": "EE"
            },
            {
                "name": "Särevere",
                "countryCode": "EE"
            },
            {
                "name": "Türi",
                "countryCode": "EE"
            },
            {
                "name": "Türi vald",
                "countryCode": "EE"
            },
            {
                "name": "Haapsalu",
                "countryCode": "EE"
            },
            {
                "name": "Haapsalu linn",
                "countryCode": "EE"
            },
            {
                "name": "Hullo",
                "countryCode": "EE"
            },
            {
                "name": "Lääne-Nigula vald",
                "countryCode": "EE"
            },
            {
                "name": "Taebla",
                "countryCode": "EE"
            },
            {
                "name": "Uuemõisa",
                "countryCode": "EE"
            },
            {
                "name": "Vormsi vald",
                "countryCode": "EE"
            },
            {
                "name": "Aseri",
                "countryCode": "EE"
            },
            {
                "name": "Haljala",
                "countryCode": "EE"
            },
            {
                "name": "Haljala vald",
                "countryCode": "EE"
            },
            {
                "name": "Kadrina",
                "countryCode": "EE"
            },
            {
                "name": "Kadrina vald",
                "countryCode": "EE"
            },
            {
                "name": "Kunda",
                "countryCode": "EE"
            },
            {
                "name": "Pajusti",
                "countryCode": "EE"
            },
            {
                "name": "Rakke",
                "countryCode": "EE"
            },
            {
                "name": "Rakvere",
                "countryCode": "EE"
            },
            {
                "name": "Rakvere vald",
                "countryCode": "EE"
            },
            {
                "name": "Sõmeru",
                "countryCode": "EE"
            },
            {
                "name": "Tamsalu",
                "countryCode": "EE"
            },
            {
                "name": "Tapa",
                "countryCode": "EE"
            },
            {
                "name": "Tapa vald",
                "countryCode": "EE"
            },
            {
                "name": "Vaiatu",
                "countryCode": "EE"
            },
            {
                "name": "Vinni",
                "countryCode": "EE"
            },
            {
                "name": "Vinni vald",
                "countryCode": "EE"
            },
            {
                "name": "Viru-Nigula vald",
                "countryCode": "EE"
            },
            {
                "name": "Väike-Maarja",
                "countryCode": "EE"
            },
            {
                "name": "Väike-Maarja vald",
                "countryCode": "EE"
            },
            {
                "name": "Kanepi",
                "countryCode": "EE"
            },
            {
                "name": "Kanepi vald",
                "countryCode": "EE"
            },
            {
                "name": "Põlva",
                "countryCode": "EE"
            },
            {
                "name": "Põlva vald",
                "countryCode": "EE"
            },
            {
                "name": "Räpina",
                "countryCode": "EE"
            },
            {
                "name": "Räpina vald",
                "countryCode": "EE"
            },
            {
                "name": "Audru",
                "countryCode": "EE"
            },
            {
                "name": "Kihnu vald",
                "countryCode": "EE"
            },
            {
                "name": "Kilingi-Nõmme",
                "countryCode": "EE"
            },
            {
                "name": "Lihula",
                "countryCode": "EE"
            },
            {
                "name": "Linaküla",
                "countryCode": "EE"
            },
            {
                "name": "Paikuse",
                "countryCode": "EE"
            },
            {
                "name": "Pärnu",
                "countryCode": "EE"
            },
            {
                "name": "Pärnu linn",
                "countryCode": "EE"
            },
            {
                "name": "Pärnu-Jaagupi",
                "countryCode": "EE"
            },
            {
                "name": "Saarde vald",
                "countryCode": "EE"
            },
            {
                "name": "Sauga",
                "countryCode": "EE"
            },
            {
                "name": "Sindi",
                "countryCode": "EE"
            },
            {
                "name": "Tootsi",
                "countryCode": "EE"
            },
            {
                "name": "Tori vald",
                "countryCode": "EE"
            },
            {
                "name": "Uulu",
                "countryCode": "EE"
            },
            {
                "name": "Vändra",
                "countryCode": "EE"
            },
            {
                "name": "Järvakandi",
                "countryCode": "EE"
            },
            {
                "name": "Kehtna",
                "countryCode": "EE"
            },
            {
                "name": "Kehtna vald",
                "countryCode": "EE"
            },
            {
                "name": "Kohila",
                "countryCode": "EE"
            },
            {
                "name": "Kohila vald",
                "countryCode": "EE"
            },
            {
                "name": "Märjamaa",
                "countryCode": "EE"
            },
            {
                "name": "Märjamaa vald",
                "countryCode": "EE"
            },
            {
                "name": "Rapla",
                "countryCode": "EE"
            },
            {
                "name": "Rapla vald",
                "countryCode": "EE"
            },
            {
                "name": "Kuressaare",
                "countryCode": "EE"
            },
            {
                "name": "Liiva",
                "countryCode": "EE"
            },
            {
                "name": "Muhu vald",
                "countryCode": "EE"
            },
            {
                "name": "Orissaare",
                "countryCode": "EE"
            },
            {
                "name": "Ruhnu",
                "countryCode": "EE"
            },
            {
                "name": "Ruhnu vald",
                "countryCode": "EE"
            },
            {
                "name": "Tehumardi",
                "countryCode": "EE"
            },
            {
                "name": "Alatskivi",
                "countryCode": "EE"
            },
            {
                "name": "Elva",
                "countryCode": "EE"
            },
            {
                "name": "Kallaste",
                "countryCode": "EE"
            },
            {
                "name": "Kambja vald",
                "countryCode": "EE"
            },
            {
                "name": "Kurepalu",
                "countryCode": "EE"
            },
            {
                "name": "Kõrveküla",
                "countryCode": "EE"
            },
            {
                "name": "Luunja",
                "countryCode": "EE"
            },
            {
                "name": "Luunja vald",
                "countryCode": "EE"
            },
            {
                "name": "Nõo",
                "countryCode": "EE"
            },
            {
                "name": "Nõo vald",
                "countryCode": "EE"
            },
            {
                "name": "Peipsiääre vald",
                "countryCode": "EE"
            },
            {
                "name": "Puhja",
                "countryCode": "EE"
            },
            {
                "name": "Tartu",
                "countryCode": "EE"
            },
            {
                "name": "Tartu linn",
                "countryCode": "EE"
            },
            {
                "name": "Tartu vald",
                "countryCode": "EE"
            },
            {
                "name": "Ülenurme",
                "countryCode": "EE"
            },
            {
                "name": "Otepää vald",
                "countryCode": "EE"
            },
            {
                "name": "Tõrva",
                "countryCode": "EE"
            },
            {
                "name": "Valga",
                "countryCode": "EE"
            },
            {
                "name": "Abja-Paluoja",
                "countryCode": "EE"
            },
            {
                "name": "Karksi-Nuia",
                "countryCode": "EE"
            },
            {
                "name": "Mõisaküla",
                "countryCode": "EE"
            },
            {
                "name": "Suure-Jaani",
                "countryCode": "EE"
            },
            {
                "name": "Viiratsi",
                "countryCode": "EE"
            },
            {
                "name": "Viljandi",
                "countryCode": "EE"
            },
            {
                "name": "Viljandi vald",
                "countryCode": "EE"
            },
            {
                "name": "Võhma",
                "countryCode": "EE"
            },
            {
                "name": "Antsla",
                "countryCode": "EE"
            },
            {
                "name": "Antsla vald",
                "countryCode": "EE"
            },
            {
                "name": "Rõuge",
                "countryCode": "EE"
            },
            {
                "name": "Rõuge vald",
                "countryCode": "EE"
            },
            {
                "name": "Vana-Antsla",
                "countryCode": "EE"
            },
            {
                "name": "Värska",
                "countryCode": "EE"
            },
            {
                "name": "Võru",
                "countryCode": "EE"
            },
            {
                "name": "Võru vald",
                "countryCode": "EE"
            }
        ]
    },
    {
        "name": "Ethiopia",
        "phonecode": "251",
        "cities": [
            {
                "name": "Addis Ababa",
                "countryCode": "ET"
            },
            {
                "name": "Administrative Zone 2",
                "countryCode": "ET"
            },
            {
                "name": "Administrative Zone 3",
                "countryCode": "ET"
            },
            {
                "name": "Asaita",
                "countryCode": "ET"
            },
            {
                "name": "Dubti",
                "countryCode": "ET"
            },
            {
                "name": "Gewanē",
                "countryCode": "ET"
            },
            {
                "name": "Semera",
                "countryCode": "ET"
            },
            {
                "name": "Āwash",
                "countryCode": "ET"
            },
            {
                "name": "Abomsa",
                "countryCode": "ET"
            },
            {
                "name": "Addiet Canna",
                "countryCode": "ET"
            },
            {
                "name": "Bahir Dar",
                "countryCode": "ET"
            },
            {
                "name": "Batī",
                "countryCode": "ET"
            },
            {
                "name": "Bichena",
                "countryCode": "ET"
            },
            {
                "name": "Burē",
                "countryCode": "ET"
            },
            {
                "name": "Dabat",
                "countryCode": "ET"
            },
            {
                "name": "Debark’",
                "countryCode": "ET"
            },
            {
                "name": "Debre Birhan",
                "countryCode": "ET"
            },
            {
                "name": "Debre Mark’os",
                "countryCode": "ET"
            },
            {
                "name": "Debre Sīna",
                "countryCode": "ET"
            },
            {
                "name": "Debre Tabor",
                "countryCode": "ET"
            },
            {
                "name": "Debre Werk’",
                "countryCode": "ET"
            },
            {
                "name": "Dejen",
                "countryCode": "ET"
            },
            {
                "name": "Desē",
                "countryCode": "ET"
            },
            {
                "name": "Finote Selam",
                "countryCode": "ET"
            },
            {
                "name": "Gondar",
                "countryCode": "ET"
            },
            {
                "name": "Kemisē",
                "countryCode": "ET"
            },
            {
                "name": "Kombolcha",
                "countryCode": "ET"
            },
            {
                "name": "Lalībela",
                "countryCode": "ET"
            },
            {
                "name": "North Shewa Zone",
                "countryCode": "ET"
            },
            {
                "name": "North Wollo Zone",
                "countryCode": "ET"
            },
            {
                "name": "Robīt",
                "countryCode": "ET"
            },
            {
                "name": "South Gondar Zone",
                "countryCode": "ET"
            },
            {
                "name": "South Wollo Zone",
                "countryCode": "ET"
            },
            {
                "name": "Wag Hemra Zone",
                "countryCode": "ET"
            },
            {
                "name": "Were Īlu",
                "countryCode": "ET"
            },
            {
                "name": "Werota",
                "countryCode": "ET"
            },
            {
                "name": "Ādīs Zemen",
                "countryCode": "ET"
            },
            {
                "name": "Asosa",
                "countryCode": "ET"
            },
            {
                "name": "Metekel",
                "countryCode": "ET"
            },
            {
                "name": "Dire Dawa",
                "countryCode": "ET"
            },
            {
                "name": "Administrative Zone 1",
                "countryCode": "ET"
            },
            {
                "name": "Gambēla",
                "countryCode": "ET"
            },
            {
                "name": "Harar",
                "countryCode": "ET"
            },
            {
                "name": "Arsi Zone",
                "countryCode": "ET"
            },
            {
                "name": "Bedelē",
                "countryCode": "ET"
            },
            {
                "name": "Bedēsa",
                "countryCode": "ET"
            },
            {
                "name": "Bishoftu",
                "countryCode": "ET"
            },
            {
                "name": "Deder",
                "countryCode": "ET"
            },
            {
                "name": "Dembī Dolo",
                "countryCode": "ET"
            },
            {
                "name": "Dodola",
                "countryCode": "ET"
            },
            {
                "name": "East Harerghe Zone",
                "countryCode": "ET"
            },
            {
                "name": "East Shewa Zone",
                "countryCode": "ET"
            },
            {
                "name": "East Wellega Zone",
                "countryCode": "ET"
            },
            {
                "name": "Fichē",
                "countryCode": "ET"
            },
            {
                "name": "Gebre Guracha",
                "countryCode": "ET"
            },
            {
                "name": "Gelemso",
                "countryCode": "ET"
            },
            {
                "name": "Genet",
                "countryCode": "ET"
            },
            {
                "name": "Gimbi",
                "countryCode": "ET"
            },
            {
                "name": "Ginir",
                "countryCode": "ET"
            },
            {
                "name": "Goba",
                "countryCode": "ET"
            },
            {
                "name": "Gorē",
                "countryCode": "ET"
            },
            {
                "name": "Guji Zone",
                "countryCode": "ET"
            },
            {
                "name": "Gēdo",
                "countryCode": "ET"
            },
            {
                "name": "Hagere Maryam",
                "countryCode": "ET"
            },
            {
                "name": "Huruta",
                "countryCode": "ET"
            },
            {
                "name": "Hāgere Hiywet",
                "countryCode": "ET"
            },
            {
                "name": "Hīrna",
                "countryCode": "ET"
            },
            {
                "name": "Illubabor Zone",
                "countryCode": "ET"
            },
            {
                "name": "Jimma",
                "countryCode": "ET"
            },
            {
                "name": "Jimma Zone",
                "countryCode": "ET"
            },
            {
                "name": "Kibre Mengist",
                "countryCode": "ET"
            },
            {
                "name": "Kofelē",
                "countryCode": "ET"
            },
            {
                "name": "Mendī",
                "countryCode": "ET"
            },
            {
                "name": "Metahāra",
                "countryCode": "ET"
            },
            {
                "name": "Metu",
                "countryCode": "ET"
            },
            {
                "name": "Mojo",
                "countryCode": "ET"
            },
            {
                "name": "Mēga",
                "countryCode": "ET"
            },
            {
                "name": "Nazrēt",
                "countryCode": "ET"
            },
            {
                "name": "Nejo",
                "countryCode": "ET"
            },
            {
                "name": "North Shewa Zone",
                "countryCode": "ET"
            },
            {
                "name": "Sebeta",
                "countryCode": "ET"
            },
            {
                "name": "Sendafa",
                "countryCode": "ET"
            },
            {
                "name": "Shakiso",
                "countryCode": "ET"
            },
            {
                "name": "Shambu",
                "countryCode": "ET"
            },
            {
                "name": "Shashemenē",
                "countryCode": "ET"
            },
            {
                "name": "Sirre",
                "countryCode": "ET"
            },
            {
                "name": "Tulu Bolo",
                "countryCode": "ET"
            },
            {
                "name": "Waliso",
                "countryCode": "ET"
            },
            {
                "name": "Wenjī",
                "countryCode": "ET"
            },
            {
                "name": "West Harerghe Zone",
                "countryCode": "ET"
            },
            {
                "name": "West Wellega Zone",
                "countryCode": "ET"
            },
            {
                "name": "Yabēlo",
                "countryCode": "ET"
            },
            {
                "name": "Ziway",
                "countryCode": "ET"
            },
            {
                "name": "Ādīs ‘Alem",
                "countryCode": "ET"
            },
            {
                "name": "Āgaro",
                "countryCode": "ET"
            },
            {
                "name": "Āsasa",
                "countryCode": "ET"
            },
            {
                "name": "Āsbe Teferī",
                "countryCode": "ET"
            },
            {
                "name": "Alaba Special Wereda",
                "countryCode": "ET"
            },
            {
                "name": "Arba Minch",
                "countryCode": "ET"
            },
            {
                "name": "Bako",
                "countryCode": "ET"
            },
            {
                "name": "Bench Maji Zone",
                "countryCode": "ET"
            },
            {
                "name": "Bodītī",
                "countryCode": "ET"
            },
            {
                "name": "Bonga",
                "countryCode": "ET"
            },
            {
                "name": "Butajīra",
                "countryCode": "ET"
            },
            {
                "name": "Dīla",
                "countryCode": "ET"
            },
            {
                "name": "Felege Neway",
                "countryCode": "ET"
            },
            {
                "name": "Gedeo Zone",
                "countryCode": "ET"
            },
            {
                "name": "Guraghe Zone",
                "countryCode": "ET"
            },
            {
                "name": "Gīdolē",
                "countryCode": "ET"
            },
            {
                "name": "Hadiya Zone",
                "countryCode": "ET"
            },
            {
                "name": "Hawassa",
                "countryCode": "ET"
            },
            {
                "name": "Hosa’ina",
                "countryCode": "ET"
            },
            {
                "name": "Hāgere Selam",
                "countryCode": "ET"
            },
            {
                "name": "Jinka",
                "countryCode": "ET"
            },
            {
                "name": "Kembata Alaba Tembaro Zone",
                "countryCode": "ET"
            },
            {
                "name": "Konso",
                "countryCode": "ET"
            },
            {
                "name": "K’olīto",
                "countryCode": "ET"
            },
            {
                "name": "Leku",
                "countryCode": "ET"
            },
            {
                "name": "Lobuni",
                "countryCode": "ET"
            },
            {
                "name": "Mīzan Teferī",
                "countryCode": "ET"
            },
            {
                "name": "Sheka Zone",
                "countryCode": "ET"
            },
            {
                "name": "Sidama Zone",
                "countryCode": "ET"
            },
            {
                "name": "Sodo",
                "countryCode": "ET"
            },
            {
                "name": "Tippi",
                "countryCode": "ET"
            },
            {
                "name": "Turmi",
                "countryCode": "ET"
            },
            {
                "name": "Wendo",
                "countryCode": "ET"
            },
            {
                "name": "Wolayita Zone",
                "countryCode": "ET"
            },
            {
                "name": "Yem",
                "countryCode": "ET"
            },
            {
                "name": "Yirga ‘Alem",
                "countryCode": "ET"
            },
            {
                "name": "Āreka",
                "countryCode": "ET"
            },
            {
                "name": "Afder Zone",
                "countryCode": "ET"
            },
            {
                "name": "Degehabur Zone",
                "countryCode": "ET"
            },
            {
                "name": "Gode Zone",
                "countryCode": "ET"
            },
            {
                "name": "Jijiga",
                "countryCode": "ET"
            },
            {
                "name": "Liben zone",
                "countryCode": "ET"
            },
            {
                "name": "Shinile Zone",
                "countryCode": "ET"
            },
            {
                "name": "Axum",
                "countryCode": "ET"
            },
            {
                "name": "Inda Silasē",
                "countryCode": "ET"
            },
            {
                "name": "Korem",
                "countryCode": "ET"
            },
            {
                "name": "Maych’ew",
                "countryCode": "ET"
            },
            {
                "name": "Mek'ele",
                "countryCode": "ET"
            },
            {
                "name": "Southeastern Tigray Zone",
                "countryCode": "ET"
            },
            {
                "name": "Southern Tigray Zone",
                "countryCode": "ET"
            },
            {
                "name": "Ādīgrat",
                "countryCode": "ET"
            }
        ]
    },
    {
        "name": "Falkland Islands",
        "phonecode": "500",
        "cities": []
    },
    {
        "name": "Faroe Islands",
        "phonecode": "298",
        "cities": []
    },
    {
        "name": "Fiji Islands",
        "phonecode": "679",
        "cities": [
            {
                "name": "Naitasiri Province",
                "countryCode": "FJ"
            },
            {
                "name": "Namosi Province",
                "countryCode": "FJ"
            },
            {
                "name": "Rewa Province",
                "countryCode": "FJ"
            },
            {
                "name": "Serua Province",
                "countryCode": "FJ"
            },
            {
                "name": "Suva",
                "countryCode": "FJ"
            },
            {
                "name": "Tailevu Province",
                "countryCode": "FJ"
            },
            {
                "name": "Kadavu Province",
                "countryCode": "FJ"
            },
            {
                "name": "Lau Province",
                "countryCode": "FJ"
            },
            {
                "name": "Levuka",
                "countryCode": "FJ"
            },
            {
                "name": "Lomaiviti Province",
                "countryCode": "FJ"
            },
            {
                "name": "Bua Province",
                "countryCode": "FJ"
            },
            {
                "name": "Cakaudrove Province",
                "countryCode": "FJ"
            },
            {
                "name": "Labasa",
                "countryCode": "FJ"
            },
            {
                "name": "Macuata Province",
                "countryCode": "FJ"
            },
            {
                "name": "Ba",
                "countryCode": "FJ"
            },
            {
                "name": "Ba Province",
                "countryCode": "FJ"
            },
            {
                "name": "Lautoka",
                "countryCode": "FJ"
            },
            {
                "name": "Nadi",
                "countryCode": "FJ"
            },
            {
                "name": "Nandronga and Navosa Province",
                "countryCode": "FJ"
            },
            {
                "name": "Ra Province",
                "countryCode": "FJ"
            }
        ]
    },
    {
        "name": "Finland",
        "phonecode": "358",
        "cities": [
            {
                "name": "Imatra",
                "countryCode": "FI"
            },
            {
                "name": "Joutseno",
                "countryCode": "FI"
            },
            {
                "name": "Lappeenranta",
                "countryCode": "FI"
            },
            {
                "name": "Lemi",
                "countryCode": "FI"
            },
            {
                "name": "Luumäki",
                "countryCode": "FI"
            },
            {
                "name": "Nuijamaa",
                "countryCode": "FI"
            },
            {
                "name": "Parikkala",
                "countryCode": "FI"
            },
            {
                "name": "Rautjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Ruokolahti",
                "countryCode": "FI"
            },
            {
                "name": "Saari",
                "countryCode": "FI"
            },
            {
                "name": "Savitaipale",
                "countryCode": "FI"
            },
            {
                "name": "Taipalsaari",
                "countryCode": "FI"
            },
            {
                "name": "Ylämaa",
                "countryCode": "FI"
            },
            {
                "name": "Alahärmä",
                "countryCode": "FI"
            },
            {
                "name": "Alajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Alavus",
                "countryCode": "FI"
            },
            {
                "name": "Evijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Ilmajoki",
                "countryCode": "FI"
            },
            {
                "name": "Isojoki",
                "countryCode": "FI"
            },
            {
                "name": "Jalasjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Jurva",
                "countryCode": "FI"
            },
            {
                "name": "Karijoki",
                "countryCode": "FI"
            },
            {
                "name": "Kauhajoki",
                "countryCode": "FI"
            },
            {
                "name": "Kauhava",
                "countryCode": "FI"
            },
            {
                "name": "Kortesjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Kuortane",
                "countryCode": "FI"
            },
            {
                "name": "Kurikka",
                "countryCode": "FI"
            },
            {
                "name": "Lappajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Lapua",
                "countryCode": "FI"
            },
            {
                "name": "Lehtimäki",
                "countryCode": "FI"
            },
            {
                "name": "Nurmo",
                "countryCode": "FI"
            },
            {
                "name": "Seinäjoki",
                "countryCode": "FI"
            },
            {
                "name": "Soini",
                "countryCode": "FI"
            },
            {
                "name": "Teuva",
                "countryCode": "FI"
            },
            {
                "name": "Töysä",
                "countryCode": "FI"
            },
            {
                "name": "Vimpeli",
                "countryCode": "FI"
            },
            {
                "name": "Ylihärmä",
                "countryCode": "FI"
            },
            {
                "name": "Ylistaro",
                "countryCode": "FI"
            },
            {
                "name": "Ähtäri",
                "countryCode": "FI"
            },
            {
                "name": "Enonkoski",
                "countryCode": "FI"
            },
            {
                "name": "Haukivuori",
                "countryCode": "FI"
            },
            {
                "name": "Heinävesi",
                "countryCode": "FI"
            },
            {
                "name": "Hirvensalmi",
                "countryCode": "FI"
            },
            {
                "name": "Joroinen",
                "countryCode": "FI"
            },
            {
                "name": "Juva",
                "countryCode": "FI"
            },
            {
                "name": "Jäppilä",
                "countryCode": "FI"
            },
            {
                "name": "Kangasniemi",
                "countryCode": "FI"
            },
            {
                "name": "Kerimäki",
                "countryCode": "FI"
            },
            {
                "name": "Mikkeli",
                "countryCode": "FI"
            },
            {
                "name": "Mäntyharju",
                "countryCode": "FI"
            },
            {
                "name": "Pertunmaa",
                "countryCode": "FI"
            },
            {
                "name": "Pieksämäki",
                "countryCode": "FI"
            },
            {
                "name": "Punkaharju",
                "countryCode": "FI"
            },
            {
                "name": "Puumala",
                "countryCode": "FI"
            },
            {
                "name": "Rantasalmi",
                "countryCode": "FI"
            },
            {
                "name": "Ristiina",
                "countryCode": "FI"
            },
            {
                "name": "Savonlinna",
                "countryCode": "FI"
            },
            {
                "name": "Savonranta",
                "countryCode": "FI"
            },
            {
                "name": "Sulkava",
                "countryCode": "FI"
            },
            {
                "name": "Virtasalmi",
                "countryCode": "FI"
            },
            {
                "name": "Hyrynsalmi",
                "countryCode": "FI"
            },
            {
                "name": "Kajaani",
                "countryCode": "FI"
            },
            {
                "name": "Kuhmo",
                "countryCode": "FI"
            },
            {
                "name": "Paltamo",
                "countryCode": "FI"
            },
            {
                "name": "Puolanka",
                "countryCode": "FI"
            },
            {
                "name": "Ristijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Sotkamo",
                "countryCode": "FI"
            },
            {
                "name": "Suomussalmi",
                "countryCode": "FI"
            },
            {
                "name": "Vaala",
                "countryCode": "FI"
            },
            {
                "name": "Vuokatti",
                "countryCode": "FI"
            },
            {
                "name": "Vuolijoki",
                "countryCode": "FI"
            },
            {
                "name": "Forssa",
                "countryCode": "FI"
            },
            {
                "name": "Hauho",
                "countryCode": "FI"
            },
            {
                "name": "Hausjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Humppila",
                "countryCode": "FI"
            },
            {
                "name": "Hämeenlinna",
                "countryCode": "FI"
            },
            {
                "name": "Janakkala",
                "countryCode": "FI"
            },
            {
                "name": "Jokioinen",
                "countryCode": "FI"
            },
            {
                "name": "Kalvola",
                "countryCode": "FI"
            },
            {
                "name": "Lammi",
                "countryCode": "FI"
            },
            {
                "name": "Loppi",
                "countryCode": "FI"
            },
            {
                "name": "Renko",
                "countryCode": "FI"
            },
            {
                "name": "Riihimäki",
                "countryCode": "FI"
            },
            {
                "name": "Tammela",
                "countryCode": "FI"
            },
            {
                "name": "Tervakoski",
                "countryCode": "FI"
            },
            {
                "name": "Tuulos",
                "countryCode": "FI"
            },
            {
                "name": "Ypäjä",
                "countryCode": "FI"
            },
            {
                "name": "Halsua",
                "countryCode": "FI"
            },
            {
                "name": "Kannus",
                "countryCode": "FI"
            },
            {
                "name": "Kaustinen",
                "countryCode": "FI"
            },
            {
                "name": "Kokkola",
                "countryCode": "FI"
            },
            {
                "name": "Kälviä",
                "countryCode": "FI"
            },
            {
                "name": "Lestijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Lohtaja",
                "countryCode": "FI"
            },
            {
                "name": "Perho",
                "countryCode": "FI"
            },
            {
                "name": "Toholampi",
                "countryCode": "FI"
            },
            {
                "name": "Ullava",
                "countryCode": "FI"
            },
            {
                "name": "Veteli",
                "countryCode": "FI"
            },
            {
                "name": "Hankasalmi",
                "countryCode": "FI"
            },
            {
                "name": "Joutsa",
                "countryCode": "FI"
            },
            {
                "name": "Jyväskylä",
                "countryCode": "FI"
            },
            {
                "name": "Jämsä",
                "countryCode": "FI"
            },
            {
                "name": "Jämsänkoski",
                "countryCode": "FI"
            },
            {
                "name": "Kannonkoski",
                "countryCode": "FI"
            },
            {
                "name": "Karstula",
                "countryCode": "FI"
            },
            {
                "name": "Keuruu",
                "countryCode": "FI"
            },
            {
                "name": "Kinnula",
                "countryCode": "FI"
            },
            {
                "name": "Kivijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Konnevesi",
                "countryCode": "FI"
            },
            {
                "name": "Korpilahti",
                "countryCode": "FI"
            },
            {
                "name": "Kuhmoinen",
                "countryCode": "FI"
            },
            {
                "name": "Kyyjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Laukaa",
                "countryCode": "FI"
            },
            {
                "name": "Leivonmäki",
                "countryCode": "FI"
            },
            {
                "name": "Luhanka",
                "countryCode": "FI"
            },
            {
                "name": "Multia",
                "countryCode": "FI"
            },
            {
                "name": "Muurame",
                "countryCode": "FI"
            },
            {
                "name": "Petäjävesi",
                "countryCode": "FI"
            },
            {
                "name": "Pihtipudas",
                "countryCode": "FI"
            },
            {
                "name": "Pylkönmäki",
                "countryCode": "FI"
            },
            {
                "name": "Saarijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Sumiainen",
                "countryCode": "FI"
            },
            {
                "name": "Suolahti",
                "countryCode": "FI"
            },
            {
                "name": "Säynätsalo",
                "countryCode": "FI"
            },
            {
                "name": "Toivakka",
                "countryCode": "FI"
            },
            {
                "name": "Uurainen",
                "countryCode": "FI"
            },
            {
                "name": "Viitasaari",
                "countryCode": "FI"
            },
            {
                "name": "Äänekoski",
                "countryCode": "FI"
            },
            {
                "name": "Anjala",
                "countryCode": "FI"
            },
            {
                "name": "Elimäki",
                "countryCode": "FI"
            },
            {
                "name": "Hamina",
                "countryCode": "FI"
            },
            {
                "name": "Iitti",
                "countryCode": "FI"
            },
            {
                "name": "Jaala",
                "countryCode": "FI"
            },
            {
                "name": "Karhula",
                "countryCode": "FI"
            },
            {
                "name": "Kotka",
                "countryCode": "FI"
            },
            {
                "name": "Kouvola",
                "countryCode": "FI"
            },
            {
                "name": "Miehikkälä",
                "countryCode": "FI"
            },
            {
                "name": "Pyhtää",
                "countryCode": "FI"
            },
            {
                "name": "Virojoki",
                "countryCode": "FI"
            },
            {
                "name": "Iisalmi",
                "countryCode": "FI"
            },
            {
                "name": "Juankoski",
                "countryCode": "FI"
            },
            {
                "name": "Kaavi",
                "countryCode": "FI"
            },
            {
                "name": "Kangaslampi",
                "countryCode": "FI"
            },
            {
                "name": "Karttula",
                "countryCode": "FI"
            },
            {
                "name": "Keitele",
                "countryCode": "FI"
            },
            {
                "name": "Kiuruvesi",
                "countryCode": "FI"
            },
            {
                "name": "Kuopio",
                "countryCode": "FI"
            },
            {
                "name": "Lapinlahti",
                "countryCode": "FI"
            },
            {
                "name": "Leppävirta",
                "countryCode": "FI"
            },
            {
                "name": "Maaninka",
                "countryCode": "FI"
            },
            {
                "name": "Nilsiä",
                "countryCode": "FI"
            },
            {
                "name": "Pielavesi",
                "countryCode": "FI"
            },
            {
                "name": "Rautalampi",
                "countryCode": "FI"
            },
            {
                "name": "Rautavaara",
                "countryCode": "FI"
            },
            {
                "name": "Siilinjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Sonkajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Suonenjoki",
                "countryCode": "FI"
            },
            {
                "name": "Tervo",
                "countryCode": "FI"
            },
            {
                "name": "Tuusniemi",
                "countryCode": "FI"
            },
            {
                "name": "Varkaus",
                "countryCode": "FI"
            },
            {
                "name": "Varpaisjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Vehmersalmi",
                "countryCode": "FI"
            },
            {
                "name": "Vesanto",
                "countryCode": "FI"
            },
            {
                "name": "Vieremä",
                "countryCode": "FI"
            },
            {
                "name": "Hietalahti",
                "countryCode": "FI"
            },
            {
                "name": "Isokyrö",
                "countryCode": "FI"
            },
            {
                "name": "Jakobstad",
                "countryCode": "FI"
            },
            {
                "name": "Kaskinen",
                "countryCode": "FI"
            },
            {
                "name": "Korsholm",
                "countryCode": "FI"
            },
            {
                "name": "Korsnäs",
                "countryCode": "FI"
            },
            {
                "name": "Kristinestad",
                "countryCode": "FI"
            },
            {
                "name": "Kronoby",
                "countryCode": "FI"
            },
            {
                "name": "Laihia",
                "countryCode": "FI"
            },
            {
                "name": "Larsmo",
                "countryCode": "FI"
            },
            {
                "name": "Malax",
                "countryCode": "FI"
            },
            {
                "name": "Nykarleby",
                "countryCode": "FI"
            },
            {
                "name": "Oravais",
                "countryCode": "FI"
            },
            {
                "name": "Pedersöre",
                "countryCode": "FI"
            },
            {
                "name": "Replot",
                "countryCode": "FI"
            },
            {
                "name": "Ristinummi",
                "countryCode": "FI"
            },
            {
                "name": "Teeriniemi",
                "countryCode": "FI"
            },
            {
                "name": "Vaasa",
                "countryCode": "FI"
            },
            {
                "name": "Vähäkyrö",
                "countryCode": "FI"
            },
            {
                "name": "Vörå",
                "countryCode": "FI"
            },
            {
                "name": "Eno",
                "countryCode": "FI"
            },
            {
                "name": "Ilomantsi",
                "countryCode": "FI"
            },
            {
                "name": "Joensuu",
                "countryCode": "FI"
            },
            {
                "name": "Juuka",
                "countryCode": "FI"
            },
            {
                "name": "Kesälahti",
                "countryCode": "FI"
            },
            {
                "name": "Kiihtelysvaara",
                "countryCode": "FI"
            },
            {
                "name": "Kitee",
                "countryCode": "FI"
            },
            {
                "name": "Kontiolahti",
                "countryCode": "FI"
            },
            {
                "name": "Lieksa",
                "countryCode": "FI"
            },
            {
                "name": "Liperi",
                "countryCode": "FI"
            },
            {
                "name": "Nurmes",
                "countryCode": "FI"
            },
            {
                "name": "Outokumpu",
                "countryCode": "FI"
            },
            {
                "name": "Polvijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäselkä",
                "countryCode": "FI"
            },
            {
                "name": "Rääkkylä",
                "countryCode": "FI"
            },
            {
                "name": "Tohmajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Tuupovaara",
                "countryCode": "FI"
            },
            {
                "name": "Valtimo",
                "countryCode": "FI"
            },
            {
                "name": "Alavieska",
                "countryCode": "FI"
            },
            {
                "name": "Haapajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Haapavesi",
                "countryCode": "FI"
            },
            {
                "name": "Hailuoto",
                "countryCode": "FI"
            },
            {
                "name": "Haukipudas",
                "countryCode": "FI"
            },
            {
                "name": "Himanka",
                "countryCode": "FI"
            },
            {
                "name": "Ii",
                "countryCode": "FI"
            },
            {
                "name": "Kalajoki",
                "countryCode": "FI"
            },
            {
                "name": "Kempele",
                "countryCode": "FI"
            },
            {
                "name": "Kestilä",
                "countryCode": "FI"
            },
            {
                "name": "Kiiminki",
                "countryCode": "FI"
            },
            {
                "name": "Kuivaniemi",
                "countryCode": "FI"
            },
            {
                "name": "Kuusamo",
                "countryCode": "FI"
            },
            {
                "name": "Kärsämäki",
                "countryCode": "FI"
            },
            {
                "name": "Liminka",
                "countryCode": "FI"
            },
            {
                "name": "Lumijoki",
                "countryCode": "FI"
            },
            {
                "name": "Merijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Muhos",
                "countryCode": "FI"
            },
            {
                "name": "Nivala",
                "countryCode": "FI"
            },
            {
                "name": "Oulainen",
                "countryCode": "FI"
            },
            {
                "name": "Oulu",
                "countryCode": "FI"
            },
            {
                "name": "Oulunsalo",
                "countryCode": "FI"
            },
            {
                "name": "Piippola",
                "countryCode": "FI"
            },
            {
                "name": "Pudasjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Pulkkila",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäjoki",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäntä",
                "countryCode": "FI"
            },
            {
                "name": "Raahe",
                "countryCode": "FI"
            },
            {
                "name": "Rantsila",
                "countryCode": "FI"
            },
            {
                "name": "Reisjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Ruukki",
                "countryCode": "FI"
            },
            {
                "name": "Sievi",
                "countryCode": "FI"
            },
            {
                "name": "Siikajoki",
                "countryCode": "FI"
            },
            {
                "name": "Taivalkoski",
                "countryCode": "FI"
            },
            {
                "name": "Tyrnävä",
                "countryCode": "FI"
            },
            {
                "name": "Utajärvi",
                "countryCode": "FI"
            },
            {
                "name": "Vihanti",
                "countryCode": "FI"
            },
            {
                "name": "Yli-Ii",
                "countryCode": "FI"
            },
            {
                "name": "Ylikiiminki",
                "countryCode": "FI"
            },
            {
                "name": "Ylivieska",
                "countryCode": "FI"
            },
            {
                "name": "Artjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Asikkala",
                "countryCode": "FI"
            },
            {
                "name": "Auttoinen",
                "countryCode": "FI"
            },
            {
                "name": "Hartola",
                "countryCode": "FI"
            },
            {
                "name": "Heinola",
                "countryCode": "FI"
            },
            {
                "name": "Hollola",
                "countryCode": "FI"
            },
            {
                "name": "Hämeenkoski",
                "countryCode": "FI"
            },
            {
                "name": "Lahti",
                "countryCode": "FI"
            },
            {
                "name": "Nastola",
                "countryCode": "FI"
            },
            {
                "name": "Orimattila",
                "countryCode": "FI"
            },
            {
                "name": "Padasjoki",
                "countryCode": "FI"
            },
            {
                "name": "Sysmä",
                "countryCode": "FI"
            },
            {
                "name": "Eura",
                "countryCode": "FI"
            },
            {
                "name": "Eurajoki",
                "countryCode": "FI"
            },
            {
                "name": "Harjavalta",
                "countryCode": "FI"
            },
            {
                "name": "Huittinen",
                "countryCode": "FI"
            },
            {
                "name": "Jämijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Kankaanpää",
                "countryCode": "FI"
            },
            {
                "name": "Karvia",
                "countryCode": "FI"
            },
            {
                "name": "Kiukainen",
                "countryCode": "FI"
            },
            {
                "name": "Kokemäki",
                "countryCode": "FI"
            },
            {
                "name": "Kullaa",
                "countryCode": "FI"
            },
            {
                "name": "Köyliö",
                "countryCode": "FI"
            },
            {
                "name": "Lappi",
                "countryCode": "FI"
            },
            {
                "name": "Lavia",
                "countryCode": "FI"
            },
            {
                "name": "Luvia",
                "countryCode": "FI"
            },
            {
                "name": "Längelmäki",
                "countryCode": "FI"
            },
            {
                "name": "Merikarvia",
                "countryCode": "FI"
            },
            {
                "name": "Nakkila",
                "countryCode": "FI"
            },
            {
                "name": "Noormarkku",
                "countryCode": "FI"
            },
            {
                "name": "Pomarkku",
                "countryCode": "FI"
            },
            {
                "name": "Pori",
                "countryCode": "FI"
            },
            {
                "name": "Rauma",
                "countryCode": "FI"
            },
            {
                "name": "Siikainen",
                "countryCode": "FI"
            },
            {
                "name": "Säkylä",
                "countryCode": "FI"
            },
            {
                "name": "Ulvila",
                "countryCode": "FI"
            },
            {
                "name": "Vampula",
                "countryCode": "FI"
            },
            {
                "name": "Askola",
                "countryCode": "FI"
            },
            {
                "name": "Ekenäs",
                "countryCode": "FI"
            },
            {
                "name": "Espoo",
                "countryCode": "FI"
            },
            {
                "name": "Gumböle",
                "countryCode": "FI"
            },
            {
                "name": "Hanko",
                "countryCode": "FI"
            },
            {
                "name": "Helsinki",
                "countryCode": "FI"
            },
            {
                "name": "Hyvinge",
                "countryCode": "FI"
            },
            {
                "name": "Ingå",
                "countryCode": "FI"
            },
            {
                "name": "Järvenpää",
                "countryCode": "FI"
            },
            {
                "name": "Kaarela",
                "countryCode": "FI"
            },
            {
                "name": "Kallio",
                "countryCode": "FI"
            },
            {
                "name": "Karis",
                "countryCode": "FI"
            },
            {
                "name": "Karjalohja",
                "countryCode": "FI"
            },
            {
                "name": "Karkkila",
                "countryCode": "FI"
            },
            {
                "name": "Kauniainen",
                "countryCode": "FI"
            },
            {
                "name": "Kellokoski",
                "countryCode": "FI"
            },
            {
                "name": "Kerava",
                "countryCode": "FI"
            },
            {
                "name": "Kilo",
                "countryCode": "FI"
            },
            {
                "name": "Kirkkonummi",
                "countryCode": "FI"
            },
            {
                "name": "Koukkuniemi",
                "countryCode": "FI"
            },
            {
                "name": "Kärkölä",
                "countryCode": "FI"
            },
            {
                "name": "Lapinjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Lauttasaari",
                "countryCode": "FI"
            },
            {
                "name": "Liljendal",
                "countryCode": "FI"
            },
            {
                "name": "Lohja",
                "countryCode": "FI"
            },
            {
                "name": "Lovisa",
                "countryCode": "FI"
            },
            {
                "name": "Mellunkylä",
                "countryCode": "FI"
            },
            {
                "name": "Munkkiniemi",
                "countryCode": "FI"
            },
            {
                "name": "Myrskylä",
                "countryCode": "FI"
            },
            {
                "name": "Mäntsälä",
                "countryCode": "FI"
            },
            {
                "name": "Nurmijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Otaniemi",
                "countryCode": "FI"
            },
            {
                "name": "Pernå",
                "countryCode": "FI"
            },
            {
                "name": "Pohja",
                "countryCode": "FI"
            },
            {
                "name": "Pornainen",
                "countryCode": "FI"
            },
            {
                "name": "Porvoo",
                "countryCode": "FI"
            },
            {
                "name": "Pukkila",
                "countryCode": "FI"
            },
            {
                "name": "Raaseporin",
                "countryCode": "FI"
            },
            {
                "name": "Ruotsinpyhtää",
                "countryCode": "FI"
            },
            {
                "name": "Sammatti",
                "countryCode": "FI"
            },
            {
                "name": "Sibbo",
                "countryCode": "FI"
            },
            {
                "name": "Siuntio",
                "countryCode": "FI"
            },
            {
                "name": "Tuusula",
                "countryCode": "FI"
            },
            {
                "name": "Vantaa",
                "countryCode": "FI"
            },
            {
                "name": "Vihti",
                "countryCode": "FI"
            },
            {
                "name": "Vuosaari",
                "countryCode": "FI"
            },
            {
                "name": "Alastaro",
                "countryCode": "FI"
            },
            {
                "name": "Askainen",
                "countryCode": "FI"
            },
            {
                "name": "Aura",
                "countryCode": "FI"
            },
            {
                "name": "Dragsfjärd",
                "countryCode": "FI"
            },
            {
                "name": "Halikko",
                "countryCode": "FI"
            },
            {
                "name": "Houtskär",
                "countryCode": "FI"
            },
            {
                "name": "Iniö",
                "countryCode": "FI"
            },
            {
                "name": "Kaarina",
                "countryCode": "FI"
            },
            {
                "name": "Karinainen",
                "countryCode": "FI"
            },
            {
                "name": "Kiikala",
                "countryCode": "FI"
            },
            {
                "name": "Kimito",
                "countryCode": "FI"
            },
            {
                "name": "Kisko",
                "countryCode": "FI"
            },
            {
                "name": "Kustavi",
                "countryCode": "FI"
            },
            {
                "name": "Kuusjoki",
                "countryCode": "FI"
            },
            {
                "name": "Laitila",
                "countryCode": "FI"
            },
            {
                "name": "Lemu",
                "countryCode": "FI"
            },
            {
                "name": "Lieto",
                "countryCode": "FI"
            },
            {
                "name": "Marttila",
                "countryCode": "FI"
            },
            {
                "name": "Masku",
                "countryCode": "FI"
            },
            {
                "name": "Mellilä",
                "countryCode": "FI"
            },
            {
                "name": "Merimasku",
                "countryCode": "FI"
            },
            {
                "name": "Mietoinen",
                "countryCode": "FI"
            },
            {
                "name": "Muurla",
                "countryCode": "FI"
            },
            {
                "name": "Mynämäki",
                "countryCode": "FI"
            },
            {
                "name": "Naantali",
                "countryCode": "FI"
            },
            {
                "name": "Nagu",
                "countryCode": "FI"
            },
            {
                "name": "Nousiainen",
                "countryCode": "FI"
            },
            {
                "name": "Oripää",
                "countryCode": "FI"
            },
            {
                "name": "Paimio",
                "countryCode": "FI"
            },
            {
                "name": "Pargas",
                "countryCode": "FI"
            },
            {
                "name": "Perniö",
                "countryCode": "FI"
            },
            {
                "name": "Pertteli",
                "countryCode": "FI"
            },
            {
                "name": "Piikkiö",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäranta",
                "countryCode": "FI"
            },
            {
                "name": "Pöytyä",
                "countryCode": "FI"
            },
            {
                "name": "Raisio",
                "countryCode": "FI"
            },
            {
                "name": "Rusko",
                "countryCode": "FI"
            },
            {
                "name": "Rymättylä",
                "countryCode": "FI"
            },
            {
                "name": "Salo",
                "countryCode": "FI"
            },
            {
                "name": "Sauvo",
                "countryCode": "FI"
            },
            {
                "name": "Somero",
                "countryCode": "FI"
            },
            {
                "name": "Suomusjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Särkisalo",
                "countryCode": "FI"
            },
            {
                "name": "Taivassalo",
                "countryCode": "FI"
            },
            {
                "name": "Tarvasjoki",
                "countryCode": "FI"
            },
            {
                "name": "Turku",
                "countryCode": "FI"
            },
            {
                "name": "Uusikaupunki",
                "countryCode": "FI"
            },
            {
                "name": "Vahto",
                "countryCode": "FI"
            },
            {
                "name": "Vehmaa",
                "countryCode": "FI"
            },
            {
                "name": "Velkua",
                "countryCode": "FI"
            },
            {
                "name": "Västanfjärd",
                "countryCode": "FI"
            },
            {
                "name": "Väståboland",
                "countryCode": "FI"
            },
            {
                "name": "Yläne",
                "countryCode": "FI"
            },
            {
                "name": "Enontekiö",
                "countryCode": "FI"
            },
            {
                "name": "Inari",
                "countryCode": "FI"
            },
            {
                "name": "Ivalo",
                "countryCode": "FI"
            },
            {
                "name": "Kemi",
                "countryCode": "FI"
            },
            {
                "name": "Kemijärvi",
                "countryCode": "FI"
            },
            {
                "name": "Keminmaa",
                "countryCode": "FI"
            },
            {
                "name": "Kittilä",
                "countryCode": "FI"
            },
            {
                "name": "Kolari",
                "countryCode": "FI"
            },
            {
                "name": "Muonio",
                "countryCode": "FI"
            },
            {
                "name": "Pelkosenniemi",
                "countryCode": "FI"
            },
            {
                "name": "Pello",
                "countryCode": "FI"
            },
            {
                "name": "Posio",
                "countryCode": "FI"
            },
            {
                "name": "Pyhäjärvi",
                "countryCode": "FI"
            },
            {
                "name": "Ranua",
                "countryCode": "FI"
            },
            {
                "name": "Rovaniemi",
                "countryCode": "FI"
            },
            {
                "name": "Salla",
                "countryCode": "FI"
            },
            {
                "name": "Savukoski",
                "countryCode": "FI"
            },
            {
                "name": "Simo",
                "countryCode": "FI"
            },
            {
                "name": "Sodankylä",
                "countryCode": "FI"
            },
            {
                "name": "Tervola",
                "countryCode": "FI"
            },
            {
                "name": "Tornio",
                "countryCode": "FI"
            },
            {
                "name": "Utsjoki",
                "countryCode": "FI"
            },
            {
                "name": "Ylitornio",
                "countryCode": "FI"
            }
        ]
    },
    {
        "name": "France",
        "phonecode": "33",
        "cities": [
            {
                "name": "Afa",
                "countryCode": "FR"
            },
            {
                "name": "Ajaccio",
                "countryCode": "FR"
            },
            {
                "name": "Alata",
                "countryCode": "FR"
            },
            {
                "name": "Albitreccia",
                "countryCode": "FR"
            },
            {
                "name": "Aléria",
                "countryCode": "FR"
            },
            {
                "name": "Appietto",
                "countryCode": "FR"
            },
            {
                "name": "Bastia",
                "countryCode": "FR"
            },
            {
                "name": "Biguglia",
                "countryCode": "FR"
            },
            {
                "name": "Bonifacio",
                "countryCode": "FR"
            },
            {
                "name": "Borgo",
                "countryCode": "FR"
            },
            {
                "name": "Brando",
                "countryCode": "FR"
            },
            {
                "name": "Calenzana",
                "countryCode": "FR"
            },
            {
                "name": "Calvi",
                "countryCode": "FR"
            },
            {
                "name": "Cargèse",
                "countryCode": "FR"
            },
            {
                "name": "Cauro",
                "countryCode": "FR"
            },
            {
                "name": "Cervione",
                "countryCode": "FR"
            },
            {
                "name": "Corte",
                "countryCode": "FR"
            },
            {
                "name": "Cuttoli-Corticchiato",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Corse-du-Sud",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Haute-Corse",
                "countryCode": "FR"
            },
            {
                "name": "Figari",
                "countryCode": "FR"
            },
            {
                "name": "Furiani",
                "countryCode": "FR"
            },
            {
                "name": "Ghisonaccia",
                "countryCode": "FR"
            },
            {
                "name": "Grosseto-Prugna",
                "countryCode": "FR"
            },
            {
                "name": "L'Île-Rousse",
                "countryCode": "FR"
            },
            {
                "name": "Linguizzetta",
                "countryCode": "FR"
            },
            {
                "name": "Lucciana",
                "countryCode": "FR"
            },
            {
                "name": "Lumio",
                "countryCode": "FR"
            },
            {
                "name": "Monticello",
                "countryCode": "FR"
            },
            {
                "name": "Morosaglia",
                "countryCode": "FR"
            },
            {
                "name": "Oletta",
                "countryCode": "FR"
            },
            {
                "name": "Olmeto",
                "countryCode": "FR"
            },
            {
                "name": "Penta-di-Casinca",
                "countryCode": "FR"
            },
            {
                "name": "Peri",
                "countryCode": "FR"
            },
            {
                "name": "Pietranera",
                "countryCode": "FR"
            },
            {
                "name": "Pietrosella",
                "countryCode": "FR"
            },
            {
                "name": "Porto-Vecchio",
                "countryCode": "FR"
            },
            {
                "name": "Propriano",
                "countryCode": "FR"
            },
            {
                "name": "Prunelli-di-Fiumorbo",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florent",
                "countryCode": "FR"
            },
            {
                "name": "San-Martino-di-Lota",
                "countryCode": "FR"
            },
            {
                "name": "San-Nicolao",
                "countryCode": "FR"
            },
            {
                "name": "Santa-Lucia-di-Moriani",
                "countryCode": "FR"
            },
            {
                "name": "Santa-Maria-di-Lota",
                "countryCode": "FR"
            },
            {
                "name": "Sari-Solenzara",
                "countryCode": "FR"
            },
            {
                "name": "Sarrola",
                "countryCode": "FR"
            },
            {
                "name": "Sartène",
                "countryCode": "FR"
            },
            {
                "name": "Sisco",
                "countryCode": "FR"
            },
            {
                "name": "Ventiseri",
                "countryCode": "FR"
            },
            {
                "name": "Vescovato",
                "countryCode": "FR"
            },
            {
                "name": "Zonza",
                "countryCode": "FR"
            },
            {
                "name": "Abondance",
                "countryCode": "FR"
            },
            {
                "name": "Abrest",
                "countryCode": "FR"
            },
            {
                "name": "Aigueblanche",
                "countryCode": "FR"
            },
            {
                "name": "Aigueperse",
                "countryCode": "FR"
            },
            {
                "name": "Aime",
                "countryCode": "FR"
            },
            {
                "name": "Ainay-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Aiton",
                "countryCode": "FR"
            },
            {
                "name": "Aix-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Alba-la-Romaine",
                "countryCode": "FR"
            },
            {
                "name": "Albens",
                "countryCode": "FR"
            },
            {
                "name": "Albertville",
                "countryCode": "FR"
            },
            {
                "name": "Albigny-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Alby-sur-Chéran",
                "countryCode": "FR"
            },
            {
                "name": "Alissas",
                "countryCode": "FR"
            },
            {
                "name": "Alixan",
                "countryCode": "FR"
            },
            {
                "name": "Allan",
                "countryCode": "FR"
            },
            {
                "name": "Allanche",
                "countryCode": "FR"
            },
            {
                "name": "Allevard",
                "countryCode": "FR"
            },
            {
                "name": "Allex",
                "countryCode": "FR"
            },
            {
                "name": "Allinges",
                "countryCode": "FR"
            },
            {
                "name": "Allières-et-Risset",
                "countryCode": "FR"
            },
            {
                "name": "Allonzier-la-Caille",
                "countryCode": "FR"
            },
            {
                "name": "Allègre",
                "countryCode": "FR"
            },
            {
                "name": "Amancy",
                "countryCode": "FR"
            },
            {
                "name": "Ambert",
                "countryCode": "FR"
            },
            {
                "name": "Ambierle",
                "countryCode": "FR"
            },
            {
                "name": "Ambronay",
                "countryCode": "FR"
            },
            {
                "name": "Ambérieu-en-Bugey",
                "countryCode": "FR"
            },
            {
                "name": "Ambérieux-en-Dombes",
                "countryCode": "FR"
            },
            {
                "name": "Amplepuis",
                "countryCode": "FR"
            },
            {
                "name": "Ampuis",
                "countryCode": "FR"
            },
            {
                "name": "Ancône",
                "countryCode": "FR"
            },
            {
                "name": "Andance",
                "countryCode": "FR"
            },
            {
                "name": "Andancette",
                "countryCode": "FR"
            },
            {
                "name": "Andrézieux-Bouthéon",
                "countryCode": "FR"
            },
            {
                "name": "Annecy",
                "countryCode": "FR"
            },
            {
                "name": "Annecy-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Annemasse",
                "countryCode": "FR"
            },
            {
                "name": "Anneyron",
                "countryCode": "FR"
            },
            {
                "name": "Annonay",
                "countryCode": "FR"
            },
            {
                "name": "Anse",
                "countryCode": "FR"
            },
            {
                "name": "Anthy-sur-Léman",
                "countryCode": "FR"
            },
            {
                "name": "Aoste",
                "countryCode": "FR"
            },
            {
                "name": "Aouste-sur-Sye",
                "countryCode": "FR"
            },
            {
                "name": "Apprieu",
                "countryCode": "FR"
            },
            {
                "name": "Arbent",
                "countryCode": "FR"
            },
            {
                "name": "Archamps",
                "countryCode": "FR"
            },
            {
                "name": "Arenthon",
                "countryCode": "FR"
            },
            {
                "name": "Arlanc",
                "countryCode": "FR"
            },
            {
                "name": "Armoy",
                "countryCode": "FR"
            },
            {
                "name": "Arnas",
                "countryCode": "FR"
            },
            {
                "name": "Arpajon-sur-Cère",
                "countryCode": "FR"
            },
            {
                "name": "Ars-sur-Formans",
                "countryCode": "FR"
            },
            {
                "name": "Artas",
                "countryCode": "FR"
            },
            {
                "name": "Artemare",
                "countryCode": "FR"
            },
            {
                "name": "Arthaz-Pont-Notre-Dame",
                "countryCode": "FR"
            },
            {
                "name": "Arâches-la-Frasse",
                "countryCode": "FR"
            },
            {
                "name": "Attignat",
                "countryCode": "FR"
            },
            {
                "name": "Aubenas",
                "countryCode": "FR"
            },
            {
                "name": "Auberives-sur-Varèze",
                "countryCode": "FR"
            },
            {
                "name": "Aubière",
                "countryCode": "FR"
            },
            {
                "name": "Aulnat",
                "countryCode": "FR"
            },
            {
                "name": "Aurec-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Aurillac",
                "countryCode": "FR"
            },
            {
                "name": "Autrans",
                "countryCode": "FR"
            },
            {
                "name": "Auzat-la-Combelle",
                "countryCode": "FR"
            },
            {
                "name": "Aveize",
                "countryCode": "FR"
            },
            {
                "name": "Aveizieux",
                "countryCode": "FR"
            },
            {
                "name": "Avermes",
                "countryCode": "FR"
            },
            {
                "name": "Aydat",
                "countryCode": "FR"
            },
            {
                "name": "Ayse",
                "countryCode": "FR"
            },
            {
                "name": "Bains",
                "countryCode": "FR"
            },
            {
                "name": "Balan",
                "countryCode": "FR"
            },
            {
                "name": "Balbigny",
                "countryCode": "FR"
            },
            {
                "name": "Ballaison",
                "countryCode": "FR"
            },
            {
                "name": "Barberaz",
                "countryCode": "FR"
            },
            {
                "name": "Barby",
                "countryCode": "FR"
            },
            {
                "name": "Barraux",
                "countryCode": "FR"
            },
            {
                "name": "Bas-en-Basset",
                "countryCode": "FR"
            },
            {
                "name": "Bassens",
                "countryCode": "FR"
            },
            {
                "name": "Beauchastel",
                "countryCode": "FR"
            },
            {
                "name": "Beaucroissant",
                "countryCode": "FR"
            },
            {
                "name": "Beaufort",
                "countryCode": "FR"
            },
            {
                "name": "Beaujeu",
                "countryCode": "FR"
            },
            {
                "name": "Beaulon",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-Monteux",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-lès-Valence",
                "countryCode": "FR"
            },
            {
                "name": "Beaurepaire",
                "countryCode": "FR"
            },
            {
                "name": "Beausemblant",
                "countryCode": "FR"
            },
            {
                "name": "Beauvallon",
                "countryCode": "FR"
            },
            {
                "name": "Beauvoir-de-Marc",
                "countryCode": "FR"
            },
            {
                "name": "Beauzac",
                "countryCode": "FR"
            },
            {
                "name": "Belle-Plagne",
                "countryCode": "FR"
            },
            {
                "name": "Bellegarde-en-Forez",
                "countryCode": "FR"
            },
            {
                "name": "Bellegarde-sur-Valserine",
                "countryCode": "FR"
            },
            {
                "name": "Bellenaves",
                "countryCode": "FR"
            },
            {
                "name": "Bellerive-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Bellevaux",
                "countryCode": "FR"
            },
            {
                "name": "Belleville",
                "countryCode": "FR"
            },
            {
                "name": "Belley",
                "countryCode": "FR"
            },
            {
                "name": "Bellignat",
                "countryCode": "FR"
            },
            {
                "name": "Belmont-de-la-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Bernin",
                "countryCode": "FR"
            },
            {
                "name": "Bessay-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Besse-et-Saint-Anastaise",
                "countryCode": "FR"
            },
            {
                "name": "Bessenay",
                "countryCode": "FR"
            },
            {
                "name": "Beynost",
                "countryCode": "FR"
            },
            {
                "name": "Bilieu",
                "countryCode": "FR"
            },
            {
                "name": "Billom",
                "countryCode": "FR"
            },
            {
                "name": "Biol",
                "countryCode": "FR"
            },
            {
                "name": "Biviers",
                "countryCode": "FR"
            },
            {
                "name": "Blacé",
                "countryCode": "FR"
            },
            {
                "name": "Blanzat",
                "countryCode": "FR"
            },
            {
                "name": "Blavozy",
                "countryCode": "FR"
            },
            {
                "name": "Bonne",
                "countryCode": "FR"
            },
            {
                "name": "Bonnefamille",
                "countryCode": "FR"
            },
            {
                "name": "Bonneville",
                "countryCode": "FR"
            },
            {
                "name": "Bons-en-Chablais",
                "countryCode": "FR"
            },
            {
                "name": "Bonson",
                "countryCode": "FR"
            },
            {
                "name": "Boulieu-lès-Annonay",
                "countryCode": "FR"
            },
            {
                "name": "Bourbon-l’Archambault",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-Argental",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-Saint-Andéol",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-Saint-Maurice",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-de-Péage",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-de-Thizy",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-en-Bresse",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-lès-Valence",
                "countryCode": "FR"
            },
            {
                "name": "Bourgoin-Jallieu",
                "countryCode": "FR"
            },
            {
                "name": "Bournoncle-Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Bouvesse-Quirieu",
                "countryCode": "FR"
            },
            {
                "name": "Bozel",
                "countryCode": "FR"
            },
            {
                "name": "Boëge",
                "countryCode": "FR"
            },
            {
                "name": "Boën-sur-Lignon",
                "countryCode": "FR"
            },
            {
                "name": "Brassac-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Briennon",
                "countryCode": "FR"
            },
            {
                "name": "Brignais",
                "countryCode": "FR"
            },
            {
                "name": "Brindas",
                "countryCode": "FR"
            },
            {
                "name": "Brioude",
                "countryCode": "FR"
            },
            {
                "name": "Brison-Saint-Innocent",
                "countryCode": "FR"
            },
            {
                "name": "Brives-Charensac",
                "countryCode": "FR"
            },
            {
                "name": "Brié-et-Angonnes",
                "countryCode": "FR"
            },
            {
                "name": "Bron",
                "countryCode": "FR"
            },
            {
                "name": "Broût-Vernet",
                "countryCode": "FR"
            },
            {
                "name": "Brugheas",
                "countryCode": "FR"
            },
            {
                "name": "Brézins",
                "countryCode": "FR"
            },
            {
                "name": "Buellas",
                "countryCode": "FR"
            },
            {
                "name": "Buis-les-Baronnies",
                "countryCode": "FR"
            },
            {
                "name": "Bully",
                "countryCode": "FR"
            },
            {
                "name": "Bussières",
                "countryCode": "FR"
            },
            {
                "name": "Buxières-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Bâgé-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Béligneux",
                "countryCode": "FR"
            },
            {
                "name": "Cailloux-sur-Fontaines",
                "countryCode": "FR"
            },
            {
                "name": "Caluire-et-Cuire",
                "countryCode": "FR"
            },
            {
                "name": "Cantal",
                "countryCode": "FR"
            },
            {
                "name": "Celles-sur-Durolle",
                "countryCode": "FR"
            },
            {
                "name": "Cellieu",
                "countryCode": "FR"
            },
            {
                "name": "Certines",
                "countryCode": "FR"
            },
            {
                "name": "Cessieu",
                "countryCode": "FR"
            },
            {
                "name": "Cessy",
                "countryCode": "FR"
            },
            {
                "name": "Ceyrat",
                "countryCode": "FR"
            },
            {
                "name": "Ceyzériat",
                "countryCode": "FR"
            },
            {
                "name": "Chabeuil",
                "countryCode": "FR"
            },
            {
                "name": "Chabreloche",
                "countryCode": "FR"
            },
            {
                "name": "Chadrac",
                "countryCode": "FR"
            },
            {
                "name": "Chalamont",
                "countryCode": "FR"
            },
            {
                "name": "Chaleins",
                "countryCode": "FR"
            },
            {
                "name": "Challes-les-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Challex",
                "countryCode": "FR"
            },
            {
                "name": "Chamagnieu",
                "countryCode": "FR"
            },
            {
                "name": "Chamalières",
                "countryCode": "FR"
            },
            {
                "name": "Chambéry",
                "countryCode": "FR"
            },
            {
                "name": "Chambœuf",
                "countryCode": "FR"
            },
            {
                "name": "Chamonix-Mont-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Champ-sur-Drac",
                "countryCode": "FR"
            },
            {
                "name": "Champagnac",
                "countryCode": "FR"
            },
            {
                "name": "Champagne-au-Mont-d’Or",
                "countryCode": "FR"
            },
            {
                "name": "Champagnier",
                "countryCode": "FR"
            },
            {
                "name": "Champdieu",
                "countryCode": "FR"
            },
            {
                "name": "Champeix",
                "countryCode": "FR"
            },
            {
                "name": "Champier",
                "countryCode": "FR"
            },
            {
                "name": "Champs-sur-Tarentaine-Marchal",
                "countryCode": "FR"
            },
            {
                "name": "Chanas",
                "countryCode": "FR"
            },
            {
                "name": "Chandon",
                "countryCode": "FR"
            },
            {
                "name": "Chanonat",
                "countryCode": "FR"
            },
            {
                "name": "Chanos-Curson",
                "countryCode": "FR"
            },
            {
                "name": "Chantelle",
                "countryCode": "FR"
            },
            {
                "name": "Chapareillan",
                "countryCode": "FR"
            },
            {
                "name": "Chapdes-Beaufort",
                "countryCode": "FR"
            },
            {
                "name": "Chaponnay",
                "countryCode": "FR"
            },
            {
                "name": "Chaponost",
                "countryCode": "FR"
            },
            {
                "name": "Chappes",
                "countryCode": "FR"
            },
            {
                "name": "Charantonnay",
                "countryCode": "FR"
            },
            {
                "name": "Charavines",
                "countryCode": "FR"
            },
            {
                "name": "Charbonnières-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Charbonnières-les-Varennes",
                "countryCode": "FR"
            },
            {
                "name": "Charentay",
                "countryCode": "FR"
            },
            {
                "name": "Charlieu",
                "countryCode": "FR"
            },
            {
                "name": "Charly",
                "countryCode": "FR"
            },
            {
                "name": "Charmes-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Charnay",
                "countryCode": "FR"
            },
            {
                "name": "Charnècles",
                "countryCode": "FR"
            },
            {
                "name": "Charvieu-Chavagneux",
                "countryCode": "FR"
            },
            {
                "name": "Chassagny",
                "countryCode": "FR"
            },
            {
                "name": "Chasse-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Chasselay",
                "countryCode": "FR"
            },
            {
                "name": "Chassieu",
                "countryCode": "FR"
            },
            {
                "name": "Chatte",
                "countryCode": "FR"
            },
            {
                "name": "Chatuzange-le-Goubet",
                "countryCode": "FR"
            },
            {
                "name": "Chaudes-Aigues",
                "countryCode": "FR"
            },
            {
                "name": "Chauriat",
                "countryCode": "FR"
            },
            {
                "name": "Chavanay",
                "countryCode": "FR"
            },
            {
                "name": "Chavanod",
                "countryCode": "FR"
            },
            {
                "name": "Chavanoz",
                "countryCode": "FR"
            },
            {
                "name": "Chazelles-sur-Lyon",
                "countryCode": "FR"
            },
            {
                "name": "Chazey-sur-Ain",
                "countryCode": "FR"
            },
            {
                "name": "Chens-sur-Léman",
                "countryCode": "FR"
            },
            {
                "name": "Chessy",
                "countryCode": "FR"
            },
            {
                "name": "Chevry",
                "countryCode": "FR"
            },
            {
                "name": "Chilly",
                "countryCode": "FR"
            },
            {
                "name": "Chimilin",
                "countryCode": "FR"
            },
            {
                "name": "Chindrieux",
                "countryCode": "FR"
            },
            {
                "name": "Chirens",
                "countryCode": "FR"
            },
            {
                "name": "Choisy",
                "countryCode": "FR"
            },
            {
                "name": "Chomérac",
                "countryCode": "FR"
            },
            {
                "name": "Chuzelles",
                "countryCode": "FR"
            },
            {
                "name": "Châbons",
                "countryCode": "FR"
            },
            {
                "name": "Château-Gaillard",
                "countryCode": "FR"
            },
            {
                "name": "Châteaugay",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-de-Galaure",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-du-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Châtel-Guyon",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-en-Michaille",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-la-Palud",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Chalaronne",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Cluses",
                "countryCode": "FR"
            },
            {
                "name": "Châtonnay",
                "countryCode": "FR"
            },
            {
                "name": "Civens",
                "countryCode": "FR"
            },
            {
                "name": "Civrieux",
                "countryCode": "FR"
            },
            {
                "name": "Claix",
                "countryCode": "FR"
            },
            {
                "name": "Clermont-Ferrand",
                "countryCode": "FR"
            },
            {
                "name": "Clonas-sur-Varèze",
                "countryCode": "FR"
            },
            {
                "name": "Cluses",
                "countryCode": "FR"
            },
            {
                "name": "Clérieux",
                "countryCode": "FR"
            },
            {
                "name": "Cognin",
                "countryCode": "FR"
            },
            {
                "name": "Cogny",
                "countryCode": "FR"
            },
            {
                "name": "Coise",
                "countryCode": "FR"
            },
            {
                "name": "Coligny",
                "countryCode": "FR"
            },
            {
                "name": "Collonges",
                "countryCode": "FR"
            },
            {
                "name": "Collonges-sous-Salève",
                "countryCode": "FR"
            },
            {
                "name": "Colombe",
                "countryCode": "FR"
            },
            {
                "name": "Combloux",
                "countryCode": "FR"
            },
            {
                "name": "Combronde",
                "countryCode": "FR"
            },
            {
                "name": "Commelle",
                "countryCode": "FR"
            },
            {
                "name": "Commentry",
                "countryCode": "FR"
            },
            {
                "name": "Communay",
                "countryCode": "FR"
            },
            {
                "name": "Condat",
                "countryCode": "FR"
            },
            {
                "name": "Condrieu",
                "countryCode": "FR"
            },
            {
                "name": "Contamine-sur-Arve",
                "countryCode": "FR"
            },
            {
                "name": "Corbas",
                "countryCode": "FR"
            },
            {
                "name": "Corbelin",
                "countryCode": "FR"
            },
            {
                "name": "Cordon",
                "countryCode": "FR"
            },
            {
                "name": "Corenc",
                "countryCode": "FR"
            },
            {
                "name": "Cornas",
                "countryCode": "FR"
            },
            {
                "name": "Cornier",
                "countryCode": "FR"
            },
            {
                "name": "Coublevie",
                "countryCode": "FR"
            },
            {
                "name": "Coubon",
                "countryCode": "FR"
            },
            {
                "name": "Courchevel",
                "countryCode": "FR"
            },
            {
                "name": "Cournon-d’Auvergne",
                "countryCode": "FR"
            },
            {
                "name": "Courpière",
                "countryCode": "FR"
            },
            {
                "name": "Cours-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Courzieu",
                "countryCode": "FR"
            },
            {
                "name": "Coutouvre",
                "countryCode": "FR"
            },
            {
                "name": "Coux",
                "countryCode": "FR"
            },
            {
                "name": "Couzon-au-Mont-d’Or",
                "countryCode": "FR"
            },
            {
                "name": "Cran-Gevrier",
                "countryCode": "FR"
            },
            {
                "name": "Cranves-Sales",
                "countryCode": "FR"
            },
            {
                "name": "Craponne",
                "countryCode": "FR"
            },
            {
                "name": "Craponne-sur-Arzon",
                "countryCode": "FR"
            },
            {
                "name": "Crest",
                "countryCode": "FR"
            },
            {
                "name": "Creuzier-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Creys-Mépieu",
                "countryCode": "FR"
            },
            {
                "name": "Crolles",
                "countryCode": "FR"
            },
            {
                "name": "Crottet",
                "countryCode": "FR"
            },
            {
                "name": "Cruas",
                "countryCode": "FR"
            },
            {
                "name": "Cruet",
                "countryCode": "FR"
            },
            {
                "name": "Cruseilles",
                "countryCode": "FR"
            },
            {
                "name": "Crémieu",
                "countryCode": "FR"
            },
            {
                "name": "Cublize",
                "countryCode": "FR"
            },
            {
                "name": "Cuisiat",
                "countryCode": "FR"
            },
            {
                "name": "Culhat",
                "countryCode": "FR"
            },
            {
                "name": "Culoz",
                "countryCode": "FR"
            },
            {
                "name": "Cunlhat",
                "countryCode": "FR"
            },
            {
                "name": "Curtin",
                "countryCode": "FR"
            },
            {
                "name": "Cussac-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Cusset",
                "countryCode": "FR"
            },
            {
                "name": "Cusy",
                "countryCode": "FR"
            },
            {
                "name": "Cuzieu",
                "countryCode": "FR"
            },
            {
                "name": "Cébazat",
                "countryCode": "FR"
            },
            {
                "name": "Cérilly",
                "countryCode": "FR"
            },
            {
                "name": "Dagneux",
                "countryCode": "FR"
            },
            {
                "name": "Dallet",
                "countryCode": "FR"
            },
            {
                "name": "Dardilly",
                "countryCode": "FR"
            },
            {
                "name": "Davézieux",
                "countryCode": "FR"
            },
            {
                "name": "Denicé",
                "countryCode": "FR"
            },
            {
                "name": "Die",
                "countryCode": "FR"
            },
            {
                "name": "Dieulefit",
                "countryCode": "FR"
            },
            {
                "name": "Dingy-Saint-Clair",
                "countryCode": "FR"
            },
            {
                "name": "Diou",
                "countryCode": "FR"
            },
            {
                "name": "Divonne-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Diémoz",
                "countryCode": "FR"
            },
            {
                "name": "Dolomieu",
                "countryCode": "FR"
            },
            {
                "name": "Domancy",
                "countryCode": "FR"
            },
            {
                "name": "Domarin",
                "countryCode": "FR"
            },
            {
                "name": "Domessin",
                "countryCode": "FR"
            },
            {
                "name": "Dompierre-sur-Besbre",
                "countryCode": "FR"
            },
            {
                "name": "Dompierre-sur-Veyle",
                "countryCode": "FR"
            },
            {
                "name": "Domène",
                "countryCode": "FR"
            },
            {
                "name": "Domérat",
                "countryCode": "FR"
            },
            {
                "name": "Donzère",
                "countryCode": "FR"
            },
            {
                "name": "Dortan",
                "countryCode": "FR"
            },
            {
                "name": "Doussard",
                "countryCode": "FR"
            },
            {
                "name": "Douvaine",
                "countryCode": "FR"
            },
            {
                "name": "Doyet",
                "countryCode": "FR"
            },
            {
                "name": "Drumettaz",
                "countryCode": "FR"
            },
            {
                "name": "Dunières",
                "countryCode": "FR"
            },
            {
                "name": "Durtol",
                "countryCode": "FR"
            },
            {
                "name": "Décines-Charpieu",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Ain",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Allier",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Ardèche",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Isère",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Drôme",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Loire",
                "countryCode": "FR"
            },
            {
                "name": "Département du Puy-de-Dôme",
                "countryCode": "FR"
            },
            {
                "name": "Département du Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Désaignes",
                "countryCode": "FR"
            },
            {
                "name": "Désertines",
                "countryCode": "FR"
            },
            {
                "name": "Ennezat",
                "countryCode": "FR"
            },
            {
                "name": "Entre-Deux-Guiers",
                "countryCode": "FR"
            },
            {
                "name": "Enval",
                "countryCode": "FR"
            },
            {
                "name": "Escoutoux",
                "countryCode": "FR"
            },
            {
                "name": "Espaly-Saint-Marcel",
                "countryCode": "FR"
            },
            {
                "name": "Estivareilles",
                "countryCode": "FR"
            },
            {
                "name": "Estrablin",
                "countryCode": "FR"
            },
            {
                "name": "Etaux",
                "countryCode": "FR"
            },
            {
                "name": "Eurre",
                "countryCode": "FR"
            },
            {
                "name": "Eybens",
                "countryCode": "FR"
            },
            {
                "name": "Eyzin-Pinet",
                "countryCode": "FR"
            },
            {
                "name": "Fareins",
                "countryCode": "FR"
            },
            {
                "name": "Farnay",
                "countryCode": "FR"
            },
            {
                "name": "Faverges",
                "countryCode": "FR"
            },
            {
                "name": "Faverges-de-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Feigères",
                "countryCode": "FR"
            },
            {
                "name": "Feillens",
                "countryCode": "FR"
            },
            {
                "name": "Ferney-Voltaire",
                "countryCode": "FR"
            },
            {
                "name": "Feurs",
                "countryCode": "FR"
            },
            {
                "name": "Feyzin",
                "countryCode": "FR"
            },
            {
                "name": "Fillinges",
                "countryCode": "FR"
            },
            {
                "name": "Firminy",
                "countryCode": "FR"
            },
            {
                "name": "Fitilieu",
                "countryCode": "FR"
            },
            {
                "name": "Flaviac",
                "countryCode": "FR"
            },
            {
                "name": "Fleurie",
                "countryCode": "FR"
            },
            {
                "name": "Fleurieu-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Foissiat",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine",
                "countryCode": "FR"
            },
            {
                "name": "Fontaines-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Fontaines-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Fontanil-Cornillon",
                "countryCode": "FR"
            },
            {
                "name": "Fontannes",
                "countryCode": "FR"
            },
            {
                "name": "Fraisses",
                "countryCode": "FR"
            },
            {
                "name": "Francheleins",
                "countryCode": "FR"
            },
            {
                "name": "Francheville",
                "countryCode": "FR"
            },
            {
                "name": "Frangy",
                "countryCode": "FR"
            },
            {
                "name": "Frans",
                "countryCode": "FR"
            },
            {
                "name": "Froges",
                "countryCode": "FR"
            },
            {
                "name": "Frontenex",
                "countryCode": "FR"
            },
            {
                "name": "Frontonas",
                "countryCode": "FR"
            },
            {
                "name": "Félines",
                "countryCode": "FR"
            },
            {
                "name": "Féternes",
                "countryCode": "FR"
            },
            {
                "name": "Gaillard",
                "countryCode": "FR"
            },
            {
                "name": "Gannat",
                "countryCode": "FR"
            },
            {
                "name": "Gelles",
                "countryCode": "FR"
            },
            {
                "name": "Genas",
                "countryCode": "FR"
            },
            {
                "name": "Genay",
                "countryCode": "FR"
            },
            {
                "name": "Gerzat",
                "countryCode": "FR"
            },
            {
                "name": "Gex",
                "countryCode": "FR"
            },
            {
                "name": "Gillonnay",
                "countryCode": "FR"
            },
            {
                "name": "Gilly-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Givors",
                "countryCode": "FR"
            },
            {
                "name": "Gières",
                "countryCode": "FR"
            },
            {
                "name": "Gleizé",
                "countryCode": "FR"
            },
            {
                "name": "Goncelin",
                "countryCode": "FR"
            },
            {
                "name": "Grandris",
                "countryCode": "FR"
            },
            {
                "name": "Grane",
                "countryCode": "FR"
            },
            {
                "name": "Grenay",
                "countryCode": "FR"
            },
            {
                "name": "Grenoble",
                "countryCode": "FR"
            },
            {
                "name": "Grignan",
                "countryCode": "FR"
            },
            {
                "name": "Grignon",
                "countryCode": "FR"
            },
            {
                "name": "Grigny",
                "countryCode": "FR"
            },
            {
                "name": "Grièges",
                "countryCode": "FR"
            },
            {
                "name": "Groissiat",
                "countryCode": "FR"
            },
            {
                "name": "Groisy",
                "countryCode": "FR"
            },
            {
                "name": "Gruffy",
                "countryCode": "FR"
            },
            {
                "name": "Grésy-sur-Aix",
                "countryCode": "FR"
            },
            {
                "name": "Grésy-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Grézieu-la-Varenne",
                "countryCode": "FR"
            },
            {
                "name": "Guilherand-Granges",
                "countryCode": "FR"
            },
            {
                "name": "Guéreins",
                "countryCode": "FR"
            },
            {
                "name": "Génissieux",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Rivoire",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Savoie",
                "countryCode": "FR"
            },
            {
                "name": "Hauterive",
                "countryCode": "FR"
            },
            {
                "name": "Hauterives",
                "countryCode": "FR"
            },
            {
                "name": "Hauteville-Lompnes",
                "countryCode": "FR"
            },
            {
                "name": "Herbeys",
                "countryCode": "FR"
            },
            {
                "name": "Heyrieux",
                "countryCode": "FR"
            },
            {
                "name": "Hières-sur-Amby",
                "countryCode": "FR"
            },
            {
                "name": "Huez",
                "countryCode": "FR"
            },
            {
                "name": "Huriel",
                "countryCode": "FR"
            },
            {
                "name": "Irigny",
                "countryCode": "FR"
            },
            {
                "name": "Issoire",
                "countryCode": "FR"
            },
            {
                "name": "Izeaux",
                "countryCode": "FR"
            },
            {
                "name": "Izernore",
                "countryCode": "FR"
            },
            {
                "name": "Jacob-Bellecombette",
                "countryCode": "FR"
            },
            {
                "name": "Janneyrias",
                "countryCode": "FR"
            },
            {
                "name": "Jardin",
                "countryCode": "FR"
            },
            {
                "name": "Jarrie",
                "countryCode": "FR"
            },
            {
                "name": "Jassans-Riottier",
                "countryCode": "FR"
            },
            {
                "name": "Jasseron",
                "countryCode": "FR"
            },
            {
                "name": "Jaujac",
                "countryCode": "FR"
            },
            {
                "name": "Job",
                "countryCode": "FR"
            },
            {
                "name": "Jonage",
                "countryCode": "FR"
            },
            {
                "name": "Jons",
                "countryCode": "FR"
            },
            {
                "name": "Jonzieux",
                "countryCode": "FR"
            },
            {
                "name": "Joyeuse",
                "countryCode": "FR"
            },
            {
                "name": "Jujurieux",
                "countryCode": "FR"
            },
            {
                "name": "Jussac",
                "countryCode": "FR"
            },
            {
                "name": "La Balme-de-Sillingy",
                "countryCode": "FR"
            },
            {
                "name": "La Biolle",
                "countryCode": "FR"
            },
            {
                "name": "La Boisse",
                "countryCode": "FR"
            },
            {
                "name": "La Bourboule",
                "countryCode": "FR"
            },
            {
                "name": "La Bridoire",
                "countryCode": "FR"
            },
            {
                "name": "La Buisse",
                "countryCode": "FR"
            },
            {
                "name": "La Bâthie",
                "countryCode": "FR"
            },
            {
                "name": "La Bâtie-Montgascon",
                "countryCode": "FR"
            },
            {
                "name": "La Bégude-de-Mazenc",
                "countryCode": "FR"
            },
            {
                "name": "La Chambre",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-de-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "La Clusaz",
                "countryCode": "FR"
            },
            {
                "name": "La Côte-Saint-André",
                "countryCode": "FR"
            },
            {
                "name": "La Fouillouse",
                "countryCode": "FR"
            },
            {
                "name": "La Garde-Adhémar",
                "countryCode": "FR"
            },
            {
                "name": "La Grand-Croix",
                "countryCode": "FR"
            },
            {
                "name": "La Monnerie-le-Montel",
                "countryCode": "FR"
            },
            {
                "name": "La Motte-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "La Motte-Servolex",
                "countryCode": "FR"
            },
            {
                "name": "La Motte-d’Aveillans",
                "countryCode": "FR"
            },
            {
                "name": "La Mulatière",
                "countryCode": "FR"
            },
            {
                "name": "La Mure",
                "countryCode": "FR"
            },
            {
                "name": "La Murette",
                "countryCode": "FR"
            },
            {
                "name": "La Pacaudière",
                "countryCode": "FR"
            },
            {
                "name": "La Ravoire",
                "countryCode": "FR"
            },
            {
                "name": "La Ricamarie",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Blanche",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-de-Glun",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-sur-Foron",
                "countryCode": "FR"
            },
            {
                "name": "La Rochette",
                "countryCode": "FR"
            },
            {
                "name": "La Séauve-sur-Semène",
                "countryCode": "FR"
            },
            {
                "name": "La Talaudière",
                "countryCode": "FR"
            },
            {
                "name": "La Terrasse",
                "countryCode": "FR"
            },
            {
                "name": "La Tour",
                "countryCode": "FR"
            },
            {
                "name": "La Tour-de-Salvagny",
                "countryCode": "FR"
            },
            {
                "name": "La Tour-du-Pin",
                "countryCode": "FR"
            },
            {
                "name": "La Tour-en-Jarez",
                "countryCode": "FR"
            },
            {
                "name": "La Tronche",
                "countryCode": "FR"
            },
            {
                "name": "La Verpillière",
                "countryCode": "FR"
            },
            {
                "name": "La Voulte-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Lablachère",
                "countryCode": "FR"
            },
            {
                "name": "Labégude",
                "countryCode": "FR"
            },
            {
                "name": "Lachapelle-sous-Aubenas",
                "countryCode": "FR"
            },
            {
                "name": "Lagnieu",
                "countryCode": "FR"
            },
            {
                "name": "Laiz",
                "countryCode": "FR"
            },
            {
                "name": "Lalevade-d’Ardèche",
                "countryCode": "FR"
            },
            {
                "name": "Lamastre",
                "countryCode": "FR"
            },
            {
                "name": "Lamure-sur-Azergues",
                "countryCode": "FR"
            },
            {
                "name": "Lancrans",
                "countryCode": "FR"
            },
            {
                "name": "Langeac",
                "countryCode": "FR"
            },
            {
                "name": "Lanobre",
                "countryCode": "FR"
            },
            {
                "name": "Lans-en-Vercors",
                "countryCode": "FR"
            },
            {
                "name": "Lantriac",
                "countryCode": "FR"
            },
            {
                "name": "Lapalisse",
                "countryCode": "FR"
            },
            {
                "name": "Lapte",
                "countryCode": "FR"
            },
            {
                "name": "Larajasse",
                "countryCode": "FR"
            },
            {
                "name": "Larequille",
                "countryCode": "FR"
            },
            {
                "name": "Largentière",
                "countryCode": "FR"
            },
            {
                "name": "Laroquebrou",
                "countryCode": "FR"
            },
            {
                "name": "Larringes",
                "countryCode": "FR"
            },
            {
                "name": "Laussonne",
                "countryCode": "FR"
            },
            {
                "name": "Lavault-Sainte-Anne",
                "countryCode": "FR"
            },
            {
                "name": "Lavilledieu",
                "countryCode": "FR"
            },
            {
                "name": "Le Bois-d'Oingt",
                "countryCode": "FR"
            },
            {
                "name": "Le Bourg-d’Oisans",
                "countryCode": "FR"
            },
            {
                "name": "Le Bourget-du-Lac",
                "countryCode": "FR"
            },
            {
                "name": "Le Breuil-sur-Couze",
                "countryCode": "FR"
            },
            {
                "name": "Le Cendre",
                "countryCode": "FR"
            },
            {
                "name": "Le Chambon-Feugerolles",
                "countryCode": "FR"
            },
            {
                "name": "Le Chambon-sur-Lignon",
                "countryCode": "FR"
            },
            {
                "name": "Le Champ-près-Froges",
                "countryCode": "FR"
            },
            {
                "name": "Le Cheylard",
                "countryCode": "FR"
            },
            {
                "name": "Le Cheylas",
                "countryCode": "FR"
            },
            {
                "name": "Le Coteau",
                "countryCode": "FR"
            },
            {
                "name": "Le Crest",
                "countryCode": "FR"
            },
            {
                "name": "Le Donjon",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Bornand",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Lemps",
                "countryCode": "FR"
            },
            {
                "name": "Le Gua",
                "countryCode": "FR"
            },
            {
                "name": "Le Mayet-de-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Le Monastier-sur-Gazeille",
                "countryCode": "FR"
            },
            {
                "name": "Le Perréon",
                "countryCode": "FR"
            },
            {
                "name": "Le Pin",
                "countryCode": "FR"
            },
            {
                "name": "Le Pont-de-Beauvoisin",
                "countryCode": "FR"
            },
            {
                "name": "Le Pont-de-Claix",
                "countryCode": "FR"
            },
            {
                "name": "Le Pouzin",
                "countryCode": "FR"
            },
            {
                "name": "Le Puy-en-Velay",
                "countryCode": "FR"
            },
            {
                "name": "Le Péage-de-Roussillon",
                "countryCode": "FR"
            },
            {
                "name": "Le Sappey-en-Chartreuse",
                "countryCode": "FR"
            },
            {
                "name": "Le Teil",
                "countryCode": "FR"
            },
            {
                "name": "Le Touvet",
                "countryCode": "FR"
            },
            {
                "name": "Le Versoud",
                "countryCode": "FR"
            },
            {
                "name": "Lempdes-sur-Allagnon",
                "countryCode": "FR"
            },
            {
                "name": "Lent",
                "countryCode": "FR"
            },
            {
                "name": "Lentigny",
                "countryCode": "FR"
            },
            {
                "name": "Lentilly",
                "countryCode": "FR"
            },
            {
                "name": "Les Abrets",
                "countryCode": "FR"
            },
            {
                "name": "Les Allues",
                "countryCode": "FR"
            },
            {
                "name": "Les Ancizes",
                "countryCode": "FR"
            },
            {
                "name": "Les Avenières",
                "countryCode": "FR"
            },
            {
                "name": "Les Chères",
                "countryCode": "FR"
            },
            {
                "name": "Les Contamines-Montjoie",
                "countryCode": "FR"
            },
            {
                "name": "Les Côtes-d'Arey",
                "countryCode": "FR"
            },
            {
                "name": "Les Deux Alpes",
                "countryCode": "FR"
            },
            {
                "name": "Les Gets",
                "countryCode": "FR"
            },
            {
                "name": "Les Houches",
                "countryCode": "FR"
            },
            {
                "name": "Les Marches",
                "countryCode": "FR"
            },
            {
                "name": "Les Martres-d'Artière",
                "countryCode": "FR"
            },
            {
                "name": "Les Roches-de-Condrieu",
                "countryCode": "FR"
            },
            {
                "name": "Les Vans",
                "countryCode": "FR"
            },
            {
                "name": "Les Échelles",
                "countryCode": "FR"
            },
            {
                "name": "Leyment",
                "countryCode": "FR"
            },
            {
                "name": "Lezoux",
                "countryCode": "FR"
            },
            {
                "name": "Liergues",
                "countryCode": "FR"
            },
            {
                "name": "Limas",
                "countryCode": "FR"
            },
            {
                "name": "Limonest",
                "countryCode": "FR"
            },
            {
                "name": "Lissieu",
                "countryCode": "FR"
            },
            {
                "name": "Livet-et-Gavet",
                "countryCode": "FR"
            },
            {
                "name": "Livron-sur-Drôme",
                "countryCode": "FR"
            },
            {
                "name": "Loire-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Loisin",
                "countryCode": "FR"
            },
            {
                "name": "Lorette",
                "countryCode": "FR"
            },
            {
                "name": "Loriol-sur-Drôme",
                "countryCode": "FR"
            },
            {
                "name": "Lovagny",
                "countryCode": "FR"
            },
            {
                "name": "Loyettes",
                "countryCode": "FR"
            },
            {
                "name": "Lozanne",
                "countryCode": "FR"
            },
            {
                "name": "Lucenay",
                "countryCode": "FR"
            },
            {
                "name": "Lucinges",
                "countryCode": "FR"
            },
            {
                "name": "Lugrin",
                "countryCode": "FR"
            },
            {
                "name": "Lumbin",
                "countryCode": "FR"
            },
            {
                "name": "Lurcy-Lévis",
                "countryCode": "FR"
            },
            {
                "name": "Lusigny",
                "countryCode": "FR"
            },
            {
                "name": "Luzinay",
                "countryCode": "FR"
            },
            {
                "name": "Lyaud",
                "countryCode": "FR"
            },
            {
                "name": "Lyon",
                "countryCode": "FR"
            },
            {
                "name": "Lézigneux",
                "countryCode": "FR"
            },
            {
                "name": "L’Isle-d’Abeau",
                "countryCode": "FR"
            },
            {
                "name": "L’Étrat",
                "countryCode": "FR"
            },
            {
                "name": "Mably",
                "countryCode": "FR"
            },
            {
                "name": "Maclas",
                "countryCode": "FR"
            },
            {
                "name": "Magland",
                "countryCode": "FR"
            },
            {
                "name": "Malataverne",
                "countryCode": "FR"
            },
            {
                "name": "Malintrat",
                "countryCode": "FR"
            },
            {
                "name": "Malissard",
                "countryCode": "FR"
            },
            {
                "name": "Manzat",
                "countryCode": "FR"
            },
            {
                "name": "Manziat",
                "countryCode": "FR"
            },
            {
                "name": "Marboz",
                "countryCode": "FR"
            },
            {
                "name": "Marcellaz-Albanais",
                "countryCode": "FR"
            },
            {
                "name": "Marcilly-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Marennes",
                "countryCode": "FR"
            },
            {
                "name": "Margencel",
                "countryCode": "FR"
            },
            {
                "name": "Marignier",
                "countryCode": "FR"
            },
            {
                "name": "Marin",
                "countryCode": "FR"
            },
            {
                "name": "Maringues",
                "countryCode": "FR"
            },
            {
                "name": "Marlhes",
                "countryCode": "FR"
            },
            {
                "name": "Marnaz",
                "countryCode": "FR"
            },
            {
                "name": "Marsac-en-Livradois",
                "countryCode": "FR"
            },
            {
                "name": "Marsanne",
                "countryCode": "FR"
            },
            {
                "name": "Marsat",
                "countryCode": "FR"
            },
            {
                "name": "Marthod",
                "countryCode": "FR"
            },
            {
                "name": "Martignat",
                "countryCode": "FR"
            },
            {
                "name": "Massiac",
                "countryCode": "FR"
            },
            {
                "name": "Massieux",
                "countryCode": "FR"
            },
            {
                "name": "Massongy",
                "countryCode": "FR"
            },
            {
                "name": "Maubec",
                "countryCode": "FR"
            },
            {
                "name": "Mauriac",
                "countryCode": "FR"
            },
            {
                "name": "Maurs",
                "countryCode": "FR"
            },
            {
                "name": "Mauves",
                "countryCode": "FR"
            },
            {
                "name": "Maxilly-sur-Léman",
                "countryCode": "FR"
            },
            {
                "name": "Mazet-Saint-Voy",
                "countryCode": "FR"
            },
            {
                "name": "Megève",
                "countryCode": "FR"
            },
            {
                "name": "Meillonnas",
                "countryCode": "FR"
            },
            {
                "name": "Mens",
                "countryCode": "FR"
            },
            {
                "name": "Menthon-Saint-Bernard",
                "countryCode": "FR"
            },
            {
                "name": "Mercurol-Veaunes",
                "countryCode": "FR"
            },
            {
                "name": "Mercury",
                "countryCode": "FR"
            },
            {
                "name": "Messeix",
                "countryCode": "FR"
            },
            {
                "name": "Messery",
                "countryCode": "FR"
            },
            {
                "name": "Messimy",
                "countryCode": "FR"
            },
            {
                "name": "Metz",
                "countryCode": "FR"
            },
            {
                "name": "Meximieux",
                "countryCode": "FR"
            },
            {
                "name": "Meylan",
                "countryCode": "FR"
            },
            {
                "name": "Meysse",
                "countryCode": "FR"
            },
            {
                "name": "Meythet",
                "countryCode": "FR"
            },
            {
                "name": "Meyzieu",
                "countryCode": "FR"
            },
            {
                "name": "Mezel",
                "countryCode": "FR"
            },
            {
                "name": "Mieussy",
                "countryCode": "FR"
            },
            {
                "name": "Millery",
                "countryCode": "FR"
            },
            {
                "name": "Mionnay",
                "countryCode": "FR"
            },
            {
                "name": "Mions",
                "countryCode": "FR"
            },
            {
                "name": "Mirabel-aux-Baronnies",
                "countryCode": "FR"
            },
            {
                "name": "Mirefleurs",
                "countryCode": "FR"
            },
            {
                "name": "Miribel",
                "countryCode": "FR"
            },
            {
                "name": "Miribel-les-Échelles",
                "countryCode": "FR"
            },
            {
                "name": "Misérieux",
                "countryCode": "FR"
            },
            {
                "name": "Modane",
                "countryCode": "FR"
            },
            {
                "name": "Moidieu",
                "countryCode": "FR"
            },
            {
                "name": "Moirans",
                "countryCode": "FR"
            },
            {
                "name": "Molinet",
                "countryCode": "FR"
            },
            {
                "name": "Monistrol-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Monnetier-Mornex",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Dore",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Saxonnex",
                "countryCode": "FR"
            },
            {
                "name": "Mont-de-Lans",
                "countryCode": "FR"
            },
            {
                "name": "Montagnat",
                "countryCode": "FR"
            },
            {
                "name": "Montagny",
                "countryCode": "FR"
            },
            {
                "name": "Montaigut",
                "countryCode": "FR"
            },
            {
                "name": "Montalieu",
                "countryCode": "FR"
            },
            {
                "name": "Montanay",
                "countryCode": "FR"
            },
            {
                "name": "Montbonnot-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Montboucher-sur-Jabron",
                "countryCode": "FR"
            },
            {
                "name": "Montbrison",
                "countryCode": "FR"
            },
            {
                "name": "Montfaucon-en-Velay",
                "countryCode": "FR"
            },
            {
                "name": "Montferrat",
                "countryCode": "FR"
            },
            {
                "name": "Montluel",
                "countryCode": "FR"
            },
            {
                "name": "Montluçon",
                "countryCode": "FR"
            },
            {
                "name": "Montmarault",
                "countryCode": "FR"
            },
            {
                "name": "Montmerle-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Montmeyran",
                "countryCode": "FR"
            },
            {
                "name": "Montmélian",
                "countryCode": "FR"
            },
            {
                "name": "Montoison",
                "countryCode": "FR"
            },
            {
                "name": "Montrevel-en-Bresse",
                "countryCode": "FR"
            },
            {
                "name": "Montrond-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Montrottier",
                "countryCode": "FR"
            },
            {
                "name": "Montréal-la-Cluse",
                "countryCode": "FR"
            },
            {
                "name": "Montségur-sur-Lauzon",
                "countryCode": "FR"
            },
            {
                "name": "Montélier",
                "countryCode": "FR"
            },
            {
                "name": "Montélimar",
                "countryCode": "FR"
            },
            {
                "name": "Montéléger",
                "countryCode": "FR"
            },
            {
                "name": "Morancé",
                "countryCode": "FR"
            },
            {
                "name": "Morestel",
                "countryCode": "FR"
            },
            {
                "name": "Mornant",
                "countryCode": "FR"
            },
            {
                "name": "Morzine",
                "countryCode": "FR"
            },
            {
                "name": "Moulins",
                "countryCode": "FR"
            },
            {
                "name": "Mours-Saint-Eusèbe",
                "countryCode": "FR"
            },
            {
                "name": "Mouxy",
                "countryCode": "FR"
            },
            {
                "name": "Moye",
                "countryCode": "FR"
            },
            {
                "name": "Mozac",
                "countryCode": "FR"
            },
            {
                "name": "Moûtiers",
                "countryCode": "FR"
            },
            {
                "name": "Murat",
                "countryCode": "FR"
            },
            {
                "name": "Mâcot-la-Plagne",
                "countryCode": "FR"
            },
            {
                "name": "Méaudre",
                "countryCode": "FR"
            },
            {
                "name": "Ménétrol",
                "countryCode": "FR"
            },
            {
                "name": "Méribel",
                "countryCode": "FR"
            },
            {
                "name": "Méry",
                "countryCode": "FR"
            },
            {
                "name": "Mézériat",
                "countryCode": "FR"
            },
            {
                "name": "Nantua",
                "countryCode": "FR"
            },
            {
                "name": "Naucelles",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-le-Réal",
                "countryCode": "FR"
            },
            {
                "name": "Neulise",
                "countryCode": "FR"
            },
            {
                "name": "Neussargues",
                "countryCode": "FR"
            },
            {
                "name": "Neuvecelle",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-les-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-sur-Ain",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy",
                "countryCode": "FR"
            },
            {
                "name": "Neuvéglise",
                "countryCode": "FR"
            },
            {
                "name": "Neydens",
                "countryCode": "FR"
            },
            {
                "name": "Nivolas-Vermelle",
                "countryCode": "FR"
            },
            {
                "name": "Niévroz",
                "countryCode": "FR"
            },
            {
                "name": "Nohanent",
                "countryCode": "FR"
            },
            {
                "name": "Noirétable",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Mésage",
                "countryCode": "FR"
            },
            {
                "name": "Novalaise",
                "countryCode": "FR"
            },
            {
                "name": "Noyarey",
                "countryCode": "FR"
            },
            {
                "name": "Nyons",
                "countryCode": "FR"
            },
            {
                "name": "Néris-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Orcet",
                "countryCode": "FR"
            },
            {
                "name": "Orcines",
                "countryCode": "FR"
            },
            {
                "name": "Orliénas",
                "countryCode": "FR"
            },
            {
                "name": "Orléat",
                "countryCode": "FR"
            },
            {
                "name": "Ornex",
                "countryCode": "FR"
            },
            {
                "name": "Ouches",
                "countryCode": "FR"
            },
            {
                "name": "Oullins",
                "countryCode": "FR"
            },
            {
                "name": "Oyonnax",
                "countryCode": "FR"
            },
            {
                "name": "Oytier-Saint-Oblas",
                "countryCode": "FR"
            },
            {
                "name": "Panissières",
                "countryCode": "FR"
            },
            {
                "name": "Paslières",
                "countryCode": "FR"
            },
            {
                "name": "Passy",
                "countryCode": "FR"
            },
            {
                "name": "Paulhaguet",
                "countryCode": "FR"
            },
            {
                "name": "Peaugres",
                "countryCode": "FR"
            },
            {
                "name": "Peillonnex",
                "countryCode": "FR"
            },
            {
                "name": "Perreux",
                "countryCode": "FR"
            },
            {
                "name": "Perrignier",
                "countryCode": "FR"
            },
            {
                "name": "Pers-Jussy",
                "countryCode": "FR"
            },
            {
                "name": "Peschadoires",
                "countryCode": "FR"
            },
            {
                "name": "Peyrins",
                "countryCode": "FR"
            },
            {
                "name": "Pierre-Bénite",
                "countryCode": "FR"
            },
            {
                "name": "Pierre-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefort",
                "countryCode": "FR"
            },
            {
                "name": "Pierrelatte",
                "countryCode": "FR"
            },
            {
                "name": "Pionsat",
                "countryCode": "FR"
            },
            {
                "name": "Plauzat",
                "countryCode": "FR"
            },
            {
                "name": "Pleaux",
                "countryCode": "FR"
            },
            {
                "name": "Poisat",
                "countryCode": "FR"
            },
            {
                "name": "Poisy",
                "countryCode": "FR"
            },
            {
                "name": "Polignac",
                "countryCode": "FR"
            },
            {
                "name": "Polliat",
                "countryCode": "FR"
            },
            {
                "name": "Pollionnay",
                "countryCode": "FR"
            },
            {
                "name": "Polminhac",
                "countryCode": "FR"
            },
            {
                "name": "Pommiers",
                "countryCode": "FR"
            },
            {
                "name": "Poncin",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Salomon",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Chéruy",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Vaux",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Veyle",
                "countryCode": "FR"
            },
            {
                "name": "Pont-du-Château",
                "countryCode": "FR"
            },
            {
                "name": "Pont-d’Ain",
                "countryCode": "FR"
            },
            {
                "name": "Pont-en-Royans",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Évêque",
                "countryCode": "FR"
            },
            {
                "name": "Pontcharra",
                "countryCode": "FR"
            },
            {
                "name": "Pontcharra-sur-Turdine",
                "countryCode": "FR"
            },
            {
                "name": "Porcieu",
                "countryCode": "FR"
            },
            {
                "name": "Portes-lès-Valence",
                "countryCode": "FR"
            },
            {
                "name": "Pouilly-les-Nonains",
                "countryCode": "FR"
            },
            {
                "name": "Pouilly-lès-Feurs",
                "countryCode": "FR"
            },
            {
                "name": "Pouilly-sous-Charlieu",
                "countryCode": "FR"
            },
            {
                "name": "Pragoulin",
                "countryCode": "FR"
            },
            {
                "name": "Praz-sur-Arly",
                "countryCode": "FR"
            },
            {
                "name": "Priay",
                "countryCode": "FR"
            },
            {
                "name": "Pringy",
                "countryCode": "FR"
            },
            {
                "name": "Privas",
                "countryCode": "FR"
            },
            {
                "name": "Précieux",
                "countryCode": "FR"
            },
            {
                "name": "Prémilhat",
                "countryCode": "FR"
            },
            {
                "name": "Publier",
                "countryCode": "FR"
            },
            {
                "name": "Pusignan",
                "countryCode": "FR"
            },
            {
                "name": "Puy-Guillaume",
                "countryCode": "FR"
            },
            {
                "name": "Pélussin",
                "countryCode": "FR"
            },
            {
                "name": "Pérignat-lès-Sarliève",
                "countryCode": "FR"
            },
            {
                "name": "Pérignat-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Périgneux",
                "countryCode": "FR"
            },
            {
                "name": "Péron",
                "countryCode": "FR"
            },
            {
                "name": "Péronnas",
                "countryCode": "FR"
            },
            {
                "name": "Pérouges",
                "countryCode": "FR"
            },
            {
                "name": "Quincieux",
                "countryCode": "FR"
            },
            {
                "name": "Quincié-en-Beaujolais",
                "countryCode": "FR"
            },
            {
                "name": "Quinssaines",
                "countryCode": "FR"
            },
            {
                "name": "Quintenas",
                "countryCode": "FR"
            },
            {
                "name": "Randan",
                "countryCode": "FR"
            },
            {
                "name": "Reignier-Ésery",
                "countryCode": "FR"
            },
            {
                "name": "Renage",
                "countryCode": "FR"
            },
            {
                "name": "Renaison",
                "countryCode": "FR"
            },
            {
                "name": "Replonges",
                "countryCode": "FR"
            },
            {
                "name": "Retournac",
                "countryCode": "FR"
            },
            {
                "name": "Revel",
                "countryCode": "FR"
            },
            {
                "name": "Reventin-Vaugris",
                "countryCode": "FR"
            },
            {
                "name": "Reyrieux",
                "countryCode": "FR"
            },
            {
                "name": "Rillieux-la-Pape",
                "countryCode": "FR"
            },
            {
                "name": "Riom",
                "countryCode": "FR"
            },
            {
                "name": "Riom-ès-Montagnes",
                "countryCode": "FR"
            },
            {
                "name": "Riorges",
                "countryCode": "FR"
            },
            {
                "name": "Riotord",
                "countryCode": "FR"
            },
            {
                "name": "Rive-de-Gier",
                "countryCode": "FR"
            },
            {
                "name": "Rives",
                "countryCode": "FR"
            },
            {
                "name": "Roanne",
                "countryCode": "FR"
            },
            {
                "name": "Roche",
                "countryCode": "FR"
            },
            {
                "name": "Roche-la-Molière",
                "countryCode": "FR"
            },
            {
                "name": "Rochegude",
                "countryCode": "FR"
            },
            {
                "name": "Rochemaure",
                "countryCode": "FR"
            },
            {
                "name": "Rochetaillée-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Roiffieux",
                "countryCode": "FR"
            },
            {
                "name": "Romagnat",
                "countryCode": "FR"
            },
            {
                "name": "Romagnieu",
                "countryCode": "FR"
            },
            {
                "name": "Romans-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Rosières",
                "countryCode": "FR"
            },
            {
                "name": "Roussillon",
                "countryCode": "FR"
            },
            {
                "name": "Royat",
                "countryCode": "FR"
            },
            {
                "name": "Roybon",
                "countryCode": "FR"
            },
            {
                "name": "Rozier-en-Donzy",
                "countryCode": "FR"
            },
            {
                "name": "Rumilly",
                "countryCode": "FR"
            },
            {
                "name": "Ruoms",
                "countryCode": "FR"
            },
            {
                "name": "Ruy",
                "countryCode": "FR"
            },
            {
                "name": "Régny",
                "countryCode": "FR"
            },
            {
                "name": "Sablons",
                "countryCode": "FR"
            },
            {
                "name": "Sail-sous-Couzan",
                "countryCode": "FR"
            },
            {
                "name": "Sain-Bel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Agrève",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alban-Leysse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alban-de-Roche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amant-Tallende",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-de-Corcy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-le-Gaz",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-le-Puy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-sur-Vieux-Jonc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Andéol-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Antoine-l'Abbaye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aupre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Baldoph",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Barthélemy-de-Vals",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Beauzire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bon-Tarentaise",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bonnet-de-Mure",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bonnet-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bonnet-les-Oules",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bonnet-près-Riom",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Béron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cassien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cergues",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cernin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chamond",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chef",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christo-en-Jarez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clair-de-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clair-du-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyprien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sur-Menthon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sur-le-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-de-Cabanne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-en-Bugey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-lès-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier-de-Formans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier-de-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier-en-Velay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier-sous-Riverie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier-sur-Chalaronne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Etienne-de-Cuines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Flour",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fons",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Forgeux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-François",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Félicien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Félix",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Galmier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genest-Lerpt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genest-Malifaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genis-Laval",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genis-Pouilly",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genis-les-Ollières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genix-sur-Guiers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genès-Champanelle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geoire-en-Valdaine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-Haute-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Commiers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Mons",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Reneins",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Laprade",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Laval",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Lembron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Lespinasse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Nuelles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-des-Fossés",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais-d’Auvergne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gérand-le-Puy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-la-Côte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-du-Rosier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Héand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ismier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-Bonnefonds",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Bournay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Gonville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Maurienne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Moirans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Muzols",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Niost",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Sixt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Soudain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-d’Aulps",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-en-Royans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-sur-Veyle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jeoire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jorioz",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Joseph",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Joseph-de-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-Chapteuil",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-Molin-Molette",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-en-Genevois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-en-Saint-Alban",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-Chaleyssin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-Malmont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-Saint-Rambert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-d'Ardèche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-de-Claix",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-en-Chevalet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-la-Pendue",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lattier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-Chamousset",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-Mure",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-du-Pape",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-du-Pont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-en-Royans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-sur-Roanne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mamet-la-Salvetat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel-d'Ardèche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel-lès-Annonay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel-lès-Sauzet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel-lès-Valence",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcellin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcellin-en-Forez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Bellevue",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Belleville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Valamas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Frêne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Mont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-d’Hères",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-en-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-la-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-le-Vinoux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-de-Beynost",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-de-Gourdans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-de-Lignon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-en-Gourgois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-sur-Dargoire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-de-Maurienne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nazaire-les-Eymes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nizier-sous-Charlieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ours",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pal-de-Mons",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-Trois-Châteaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-de-Varax",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-de-Varces",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-en-Chablais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-en-Jarez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-lès-Romans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paulien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-de-Bœuf",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-de-Chandieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-la-Palud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pourçain-sur-Sioule",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Priest",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Priest-en-Jarez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Privat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Péray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-Fallavier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rambert-en-Bugey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Restitut",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-de-Jalionas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-de-Popey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-en-Gal",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-la-Motte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-le-Puy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-de-Maurienne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-en-Rollat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-sur-Durolle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauves-d'Auvergne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-de-Montagut",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-en-Rue",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Savin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sernin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Simon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Siméon-de-Bressieux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sorlin-en-Bugey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sorlin-en-Valloire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Symphorien-de-Lay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Symphorien-sur-Coise",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Trivier-de-Courtes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Trivier-sur-Moignans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Uze",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vallier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Victor",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Victor-de-Cessieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-de-Mercuze",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vérand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yorre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Égrève",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Éloy-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Crossey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Fontbellon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Saint-Geoirs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-des-Oullières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-sur-Chalaronne",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Colombe",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Consorce",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Euphémie",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Florine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Foy-lès-Lyon",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Foy-l’Argentière",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Hélène-sur-Isère",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Sigolène",
                "countryCode": "FR"
            },
            {
                "name": "Salagnon",
                "countryCode": "FR"
            },
            {
                "name": "Salaise-sur-Sanne",
                "countryCode": "FR"
            },
            {
                "name": "Sales",
                "countryCode": "FR"
            },
            {
                "name": "Salins-les-Thermes",
                "countryCode": "FR"
            },
            {
                "name": "Sallanches",
                "countryCode": "FR"
            },
            {
                "name": "Samoëns",
                "countryCode": "FR"
            },
            {
                "name": "Sansac-de-Marmiesse",
                "countryCode": "FR"
            },
            {
                "name": "Sarras",
                "countryCode": "FR"
            },
            {
                "name": "Sassenage",
                "countryCode": "FR"
            },
            {
                "name": "Sathonay-Camp",
                "countryCode": "FR"
            },
            {
                "name": "Sathonay-Village",
                "countryCode": "FR"
            },
            {
                "name": "Satillieu",
                "countryCode": "FR"
            },
            {
                "name": "Satolas-et-Bonce",
                "countryCode": "FR"
            },
            {
                "name": "Saugues",
                "countryCode": "FR"
            },
            {
                "name": "Saulce-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Sault-Brénaz",
                "countryCode": "FR"
            },
            {
                "name": "Sauverny",
                "countryCode": "FR"
            },
            {
                "name": "Sauxillanges",
                "countryCode": "FR"
            },
            {
                "name": "Sauzet",
                "countryCode": "FR"
            },
            {
                "name": "Savasse",
                "countryCode": "FR"
            },
            {
                "name": "Savigneux",
                "countryCode": "FR"
            },
            {
                "name": "Savigny",
                "countryCode": "FR"
            },
            {
                "name": "Savoie",
                "countryCode": "FR"
            },
            {
                "name": "Sayat",
                "countryCode": "FR"
            },
            {
                "name": "Sciez",
                "countryCode": "FR"
            },
            {
                "name": "Scionzier",
                "countryCode": "FR"
            },
            {
                "name": "Septème",
                "countryCode": "FR"
            },
            {
                "name": "Sergy",
                "countryCode": "FR"
            },
            {
                "name": "Sermérieu",
                "countryCode": "FR"
            },
            {
                "name": "Serpaize",
                "countryCode": "FR"
            },
            {
                "name": "Serrières",
                "countryCode": "FR"
            },
            {
                "name": "Serrières-de-Briord",
                "countryCode": "FR"
            },
            {
                "name": "Servas",
                "countryCode": "FR"
            },
            {
                "name": "Sevrier",
                "countryCode": "FR"
            },
            {
                "name": "Seynod",
                "countryCode": "FR"
            },
            {
                "name": "Seyssinet-Pariset",
                "countryCode": "FR"
            },
            {
                "name": "Seyssins",
                "countryCode": "FR"
            },
            {
                "name": "Seyssuel",
                "countryCode": "FR"
            },
            {
                "name": "Sillans",
                "countryCode": "FR"
            },
            {
                "name": "Sillingy",
                "countryCode": "FR"
            },
            {
                "name": "Simandres",
                "countryCode": "FR"
            },
            {
                "name": "Solaize",
                "countryCode": "FR"
            },
            {
                "name": "Solignac-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Sonnay",
                "countryCode": "FR"
            },
            {
                "name": "Sonnaz",
                "countryCode": "FR"
            },
            {
                "name": "Sorbiers",
                "countryCode": "FR"
            },
            {
                "name": "Soucieu-en-Jarrest",
                "countryCode": "FR"
            },
            {
                "name": "Sourcieux-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Souvigny",
                "countryCode": "FR"
            },
            {
                "name": "Soyons",
                "countryCode": "FR"
            },
            {
                "name": "Sury-le-Comtal",
                "countryCode": "FR"
            },
            {
                "name": "Suze-la-Rousse",
                "countryCode": "FR"
            },
            {
                "name": "Séez",
                "countryCode": "FR"
            },
            {
                "name": "Ségny",
                "countryCode": "FR"
            },
            {
                "name": "Sérézin-du-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Tain-l’Hermitage",
                "countryCode": "FR"
            },
            {
                "name": "Tallende",
                "countryCode": "FR"
            },
            {
                "name": "Talloires",
                "countryCode": "FR"
            },
            {
                "name": "Taluyers",
                "countryCode": "FR"
            },
            {
                "name": "Taninges",
                "countryCode": "FR"
            },
            {
                "name": "Tarare",
                "countryCode": "FR"
            },
            {
                "name": "Tassin-la-Demi-Lune",
                "countryCode": "FR"
            },
            {
                "name": "Taulignan",
                "countryCode": "FR"
            },
            {
                "name": "Tenay",
                "countryCode": "FR"
            },
            {
                "name": "Tence",
                "countryCode": "FR"
            },
            {
                "name": "Tencin",
                "countryCode": "FR"
            },
            {
                "name": "Ternay",
                "countryCode": "FR"
            },
            {
                "name": "Theizé",
                "countryCode": "FR"
            },
            {
                "name": "Theys",
                "countryCode": "FR"
            },
            {
                "name": "Thiers",
                "countryCode": "FR"
            },
            {
                "name": "Thil",
                "countryCode": "FR"
            },
            {
                "name": "Thizy-les-Bourgs",
                "countryCode": "FR"
            },
            {
                "name": "Thoiry",
                "countryCode": "FR"
            },
            {
                "name": "Thoissey",
                "countryCode": "FR"
            },
            {
                "name": "Thonon-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Thorens-Glières",
                "countryCode": "FR"
            },
            {
                "name": "Thuellin",
                "countryCode": "FR"
            },
            {
                "name": "Thueyts",
                "countryCode": "FR"
            },
            {
                "name": "Thurins",
                "countryCode": "FR"
            },
            {
                "name": "Thyez",
                "countryCode": "FR"
            },
            {
                "name": "Thônes",
                "countryCode": "FR"
            },
            {
                "name": "Tignes",
                "countryCode": "FR"
            },
            {
                "name": "Tignieu",
                "countryCode": "FR"
            },
            {
                "name": "Tossiat",
                "countryCode": "FR"
            },
            {
                "name": "Toulaud",
                "countryCode": "FR"
            },
            {
                "name": "Toulon-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Tournon-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Toussieu",
                "countryCode": "FR"
            },
            {
                "name": "Tramoyes",
                "countryCode": "FR"
            },
            {
                "name": "Treffort-Cuisiat",
                "countryCode": "FR"
            },
            {
                "name": "Trept",
                "countryCode": "FR"
            },
            {
                "name": "Tresserve",
                "countryCode": "FR"
            },
            {
                "name": "Trévol",
                "countryCode": "FR"
            },
            {
                "name": "Trévoux",
                "countryCode": "FR"
            },
            {
                "name": "Tulette",
                "countryCode": "FR"
            },
            {
                "name": "Tullins",
                "countryCode": "FR"
            },
            {
                "name": "Ugine",
                "countryCode": "FR"
            },
            {
                "name": "Unieux",
                "countryCode": "FR"
            },
            {
                "name": "Upie",
                "countryCode": "FR"
            },
            {
                "name": "Usson-en-Forez",
                "countryCode": "FR"
            },
            {
                "name": "Val Thorens",
                "countryCode": "FR"
            },
            {
                "name": "Val-d’Isère",
                "countryCode": "FR"
            },
            {
                "name": "Valence",
                "countryCode": "FR"
            },
            {
                "name": "Valencin",
                "countryCode": "FR"
            },
            {
                "name": "Valleiry",
                "countryCode": "FR"
            },
            {
                "name": "Vallières",
                "countryCode": "FR"
            },
            {
                "name": "Valloire",
                "countryCode": "FR"
            },
            {
                "name": "Vallon-Pont-d’Arc",
                "countryCode": "FR"
            },
            {
                "name": "Vallon-en-Sully",
                "countryCode": "FR"
            },
            {
                "name": "Vals-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Vals-près-le-Puy",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-sur-Allier",
                "countryCode": "FR"
            },
            {
                "name": "Vaugneray",
                "countryCode": "FR"
            },
            {
                "name": "Vaulnaveys-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Vaulnaveys-le-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Vaulx-Milieu",
                "countryCode": "FR"
            },
            {
                "name": "Vaulx-en-Velin",
                "countryCode": "FR"
            },
            {
                "name": "Vaux-en-Bugey",
                "countryCode": "FR"
            },
            {
                "name": "Veauche",
                "countryCode": "FR"
            },
            {
                "name": "Veigy",
                "countryCode": "FR"
            },
            {
                "name": "Vendat",
                "countryCode": "FR"
            },
            {
                "name": "Vergongheon",
                "countryCode": "FR"
            },
            {
                "name": "Vernaison",
                "countryCode": "FR"
            },
            {
                "name": "Vernioz",
                "countryCode": "FR"
            },
            {
                "name": "Vernosc-lès-Annonay",
                "countryCode": "FR"
            },
            {
                "name": "Vernoux-en-Vivarais",
                "countryCode": "FR"
            },
            {
                "name": "Versonnex",
                "countryCode": "FR"
            },
            {
                "name": "Vertaizon",
                "countryCode": "FR"
            },
            {
                "name": "Vesseaux",
                "countryCode": "FR"
            },
            {
                "name": "Veurey-Voroize",
                "countryCode": "FR"
            },
            {
                "name": "Veyras",
                "countryCode": "FR"
            },
            {
                "name": "Veyre-Monton",
                "countryCode": "FR"
            },
            {
                "name": "Veyrier-du-Lac",
                "countryCode": "FR"
            },
            {
                "name": "Vic-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Vic-sur-Cère",
                "countryCode": "FR"
            },
            {
                "name": "Vichy",
                "countryCode": "FR"
            },
            {
                "name": "Vieille-Brioude",
                "countryCode": "FR"
            },
            {
                "name": "Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Vif",
                "countryCode": "FR"
            },
            {
                "name": "Villard-Bonnot",
                "countryCode": "FR"
            },
            {
                "name": "Villard-de-Lans",
                "countryCode": "FR"
            },
            {
                "name": "Villargondran",
                "countryCode": "FR"
            },
            {
                "name": "Villars",
                "countryCode": "FR"
            },
            {
                "name": "Villars-les-Dombes",
                "countryCode": "FR"
            },
            {
                "name": "Villaz",
                "countryCode": "FR"
            },
            {
                "name": "Ville-la-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Ville-sous-Anjou",
                "countryCode": "FR"
            },
            {
                "name": "Villebois",
                "countryCode": "FR"
            },
            {
                "name": "Villebret",
                "countryCode": "FR"
            },
            {
                "name": "Villefontaine",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-d’Allier",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Villemoirieu",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-de-Berg",
                "countryCode": "FR"
            },
            {
                "name": "Villerest",
                "countryCode": "FR"
            },
            {
                "name": "Villereversure",
                "countryCode": "FR"
            },
            {
                "name": "Villette-de-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Villeurbanne",
                "countryCode": "FR"
            },
            {
                "name": "Villevocance",
                "countryCode": "FR"
            },
            {
                "name": "Villié-Morgon",
                "countryCode": "FR"
            },
            {
                "name": "Vimines",
                "countryCode": "FR"
            },
            {
                "name": "Vinay",
                "countryCode": "FR"
            },
            {
                "name": "Vinsobres",
                "countryCode": "FR"
            },
            {
                "name": "Violay",
                "countryCode": "FR"
            },
            {
                "name": "Viriat",
                "countryCode": "FR"
            },
            {
                "name": "Virieu",
                "countryCode": "FR"
            },
            {
                "name": "Virieu-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Viriville",
                "countryCode": "FR"
            },
            {
                "name": "Viry",
                "countryCode": "FR"
            },
            {
                "name": "Viuz-en-Sallaz",
                "countryCode": "FR"
            },
            {
                "name": "Viuz-la-Chiésaz",
                "countryCode": "FR"
            },
            {
                "name": "Viviers",
                "countryCode": "FR"
            },
            {
                "name": "Viviers-du-Lac",
                "countryCode": "FR"
            },
            {
                "name": "Vizille",
                "countryCode": "FR"
            },
            {
                "name": "Voglans",
                "countryCode": "FR"
            },
            {
                "name": "Voiron",
                "countryCode": "FR"
            },
            {
                "name": "Volvic",
                "countryCode": "FR"
            },
            {
                "name": "Vonnas",
                "countryCode": "FR"
            },
            {
                "name": "Voreppe",
                "countryCode": "FR"
            },
            {
                "name": "Vorey",
                "countryCode": "FR"
            },
            {
                "name": "Vougy",
                "countryCode": "FR"
            },
            {
                "name": "Vourey",
                "countryCode": "FR"
            },
            {
                "name": "Vourles",
                "countryCode": "FR"
            },
            {
                "name": "Vénissieux",
                "countryCode": "FR"
            },
            {
                "name": "Vétraz-Monthoux",
                "countryCode": "FR"
            },
            {
                "name": "Ydes",
                "countryCode": "FR"
            },
            {
                "name": "Yenne",
                "countryCode": "FR"
            },
            {
                "name": "Youx",
                "countryCode": "FR"
            },
            {
                "name": "Yssingeaux",
                "countryCode": "FR"
            },
            {
                "name": "Ytrac",
                "countryCode": "FR"
            },
            {
                "name": "Yzeure",
                "countryCode": "FR"
            },
            {
                "name": "Ébreuil",
                "countryCode": "FR"
            },
            {
                "name": "Échalas",
                "countryCode": "FR"
            },
            {
                "name": "Échenevex",
                "countryCode": "FR"
            },
            {
                "name": "Échirolles",
                "countryCode": "FR"
            },
            {
                "name": "Écully",
                "countryCode": "FR"
            },
            {
                "name": "Épagny",
                "countryCode": "FR"
            },
            {
                "name": "Épinouze",
                "countryCode": "FR"
            },
            {
                "name": "Étoile-sur-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Étrembières",
                "countryCode": "FR"
            },
            {
                "name": "Évian-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Évires",
                "countryCode": "FR"
            },
            {
                "name": "Ahuy",
                "countryCode": "FR"
            },
            {
                "name": "Aillant-sur-Tholon",
                "countryCode": "FR"
            },
            {
                "name": "Aillevillers-et-Lyaumont",
                "countryCode": "FR"
            },
            {
                "name": "Aiserey",
                "countryCode": "FR"
            },
            {
                "name": "Ancy-le-Franc",
                "countryCode": "FR"
            },
            {
                "name": "Andelnans",
                "countryCode": "FR"
            },
            {
                "name": "Appoigny",
                "countryCode": "FR"
            },
            {
                "name": "Arbois",
                "countryCode": "FR"
            },
            {
                "name": "Arbouans",
                "countryCode": "FR"
            },
            {
                "name": "Arc-et-Senans",
                "countryCode": "FR"
            },
            {
                "name": "Arc-lès-Gray",
                "countryCode": "FR"
            },
            {
                "name": "Arc-sur-Tille",
                "countryCode": "FR"
            },
            {
                "name": "Arcey",
                "countryCode": "FR"
            },
            {
                "name": "Arinthod",
                "countryCode": "FR"
            },
            {
                "name": "Arnay-le-Duc",
                "countryCode": "FR"
            },
            {
                "name": "Audincourt",
                "countryCode": "FR"
            },
            {
                "name": "Augy",
                "countryCode": "FR"
            },
            {
                "name": "Autun",
                "countryCode": "FR"
            },
            {
                "name": "Auxerre",
                "countryCode": "FR"
            },
            {
                "name": "Auxon-Dessous",
                "countryCode": "FR"
            },
            {
                "name": "Auxonne",
                "countryCode": "FR"
            },
            {
                "name": "Auxy",
                "countryCode": "FR"
            },
            {
                "name": "Avallon",
                "countryCode": "FR"
            },
            {
                "name": "Avanne-Aveney",
                "countryCode": "FR"
            },
            {
                "name": "Bart",
                "countryCode": "FR"
            },
            {
                "name": "Baume-les-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Bavans",
                "countryCode": "FR"
            },
            {
                "name": "Bavilliers",
                "countryCode": "FR"
            },
            {
                "name": "Beaucourt",
                "countryCode": "FR"
            },
            {
                "name": "Beaune",
                "countryCode": "FR"
            },
            {
                "name": "Belfort",
                "countryCode": "FR"
            },
            {
                "name": "Belleneuve",
                "countryCode": "FR"
            },
            {
                "name": "Besançon",
                "countryCode": "FR"
            },
            {
                "name": "Bethoncourt",
                "countryCode": "FR"
            },
            {
                "name": "Beure",
                "countryCode": "FR"
            },
            {
                "name": "Blamont",
                "countryCode": "FR"
            },
            {
                "name": "Blanzy",
                "countryCode": "FR"
            },
            {
                "name": "Bletterans",
                "countryCode": "FR"
            },
            {
                "name": "Bligny-lès-Beaune",
                "countryCode": "FR"
            },
            {
                "name": "Bléneau",
                "countryCode": "FR"
            },
            {
                "name": "Bois-d’Amont",
                "countryCode": "FR"
            },
            {
                "name": "Bourbon-Lancy",
                "countryCode": "FR"
            },
            {
                "name": "Bourogne",
                "countryCode": "FR"
            },
            {
                "name": "Boussières",
                "countryCode": "FR"
            },
            {
                "name": "Branges",
                "countryCode": "FR"
            },
            {
                "name": "Brazey-en-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Brienon-sur-Armançon",
                "countryCode": "FR"
            },
            {
                "name": "Buxy",
                "countryCode": "FR"
            },
            {
                "name": "Cercy-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Chablis",
                "countryCode": "FR"
            },
            {
                "name": "Chagny",
                "countryCode": "FR"
            },
            {
                "name": "Challuy",
                "countryCode": "FR"
            },
            {
                "name": "Chalon-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Champagney",
                "countryCode": "FR"
            },
            {
                "name": "Champagnole",
                "countryCode": "FR"
            },
            {
                "name": "Champforgeuil",
                "countryCode": "FR"
            },
            {
                "name": "Champignelles",
                "countryCode": "FR"
            },
            {
                "name": "Champigny",
                "countryCode": "FR"
            },
            {
                "name": "Champlitte",
                "countryCode": "FR"
            },
            {
                "name": "Champlitte-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Champs-sur-Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Champvans",
                "countryCode": "FR"
            },
            {
                "name": "Chantenay-Saint-Imbert",
                "countryCode": "FR"
            },
            {
                "name": "Charbuy",
                "countryCode": "FR"
            },
            {
                "name": "Charnay-lès-Mâcon",
                "countryCode": "FR"
            },
            {
                "name": "Charny",
                "countryCode": "FR"
            },
            {
                "name": "Charolles",
                "countryCode": "FR"
            },
            {
                "name": "Charquemont",
                "countryCode": "FR"
            },
            {
                "name": "Chauffailles",
                "countryCode": "FR"
            },
            {
                "name": "Chaulgnes",
                "countryCode": "FR"
            },
            {
                "name": "Chaussin",
                "countryCode": "FR"
            },
            {
                "name": "Chemaudin",
                "countryCode": "FR"
            },
            {
                "name": "Cheny",
                "countryCode": "FR"
            },
            {
                "name": "Chenôve",
                "countryCode": "FR"
            },
            {
                "name": "Chevigny-Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Choisey",
                "countryCode": "FR"
            },
            {
                "name": "Châlonvillars",
                "countryCode": "FR"
            },
            {
                "name": "Château-Chinon(Ville)",
                "countryCode": "FR"
            },
            {
                "name": "Châtenois-les-Forges",
                "countryCode": "FR"
            },
            {
                "name": "Châtenoy-le-Royal",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-en-Bazois",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-le-Duc",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Chèvremont",
                "countryCode": "FR"
            },
            {
                "name": "Chéroy",
                "countryCode": "FR"
            },
            {
                "name": "Ciry-le-Noble",
                "countryCode": "FR"
            },
            {
                "name": "Clairvaux-les-Lacs",
                "countryCode": "FR"
            },
            {
                "name": "Clamecy",
                "countryCode": "FR"
            },
            {
                "name": "Clerval",
                "countryCode": "FR"
            },
            {
                "name": "Cluny",
                "countryCode": "FR"
            },
            {
                "name": "Colombier-Fontaine",
                "countryCode": "FR"
            },
            {
                "name": "Corbenay",
                "countryCode": "FR"
            },
            {
                "name": "Corbigny",
                "countryCode": "FR"
            },
            {
                "name": "Corpeau",
                "countryCode": "FR"
            },
            {
                "name": "Cosne-Cours-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Couches",
                "countryCode": "FR"
            },
            {
                "name": "Couchey",
                "countryCode": "FR"
            },
            {
                "name": "Coulanges-lès-Nevers",
                "countryCode": "FR"
            },
            {
                "name": "Courcelles-lès-Montbéliard",
                "countryCode": "FR"
            },
            {
                "name": "Courlon-sur-Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Cousance",
                "countryCode": "FR"
            },
            {
                "name": "Couternon",
                "countryCode": "FR"
            },
            {
                "name": "Cravanche",
                "countryCode": "FR"
            },
            {
                "name": "Crissey",
                "countryCode": "FR"
            },
            {
                "name": "Crêches-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Cuiseaux",
                "countryCode": "FR"
            },
            {
                "name": "Cuisery",
                "countryCode": "FR"
            },
            {
                "name": "Curgy",
                "countryCode": "FR"
            },
            {
                "name": "Cézy",
                "countryCode": "FR"
            },
            {
                "name": "Daix",
                "countryCode": "FR"
            },
            {
                "name": "Damparis",
                "countryCode": "FR"
            },
            {
                "name": "Dampierre",
                "countryCode": "FR"
            },
            {
                "name": "Dampierre-les-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Dampierre-sur-Salon",
                "countryCode": "FR"
            },
            {
                "name": "Damprichard",
                "countryCode": "FR"
            },
            {
                "name": "Danjoutin",
                "countryCode": "FR"
            },
            {
                "name": "Dannemarie-sur-Crête",
                "countryCode": "FR"
            },
            {
                "name": "Dasle",
                "countryCode": "FR"
            },
            {
                "name": "Decize",
                "countryCode": "FR"
            },
            {
                "name": "Delle",
                "countryCode": "FR"
            },
            {
                "name": "Demigny",
                "countryCode": "FR"
            },
            {
                "name": "Devecey",
                "countryCode": "FR"
            },
            {
                "name": "Diges",
                "countryCode": "FR"
            },
            {
                "name": "Digoin",
                "countryCode": "FR"
            },
            {
                "name": "Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Dole",
                "countryCode": "FR"
            },
            {
                "name": "Donzy",
                "countryCode": "FR"
            },
            {
                "name": "Dornes",
                "countryCode": "FR"
            },
            {
                "name": "Doubs",
                "countryCode": "FR"
            },
            {
                "name": "Dracy-le-Fort",
                "countryCode": "FR"
            },
            {
                "name": "Département de Saône-et-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Côte-d'Or",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Haute-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Nièvre",
                "countryCode": "FR"
            },
            {
                "name": "Essert",
                "countryCode": "FR"
            },
            {
                "name": "Exincourt",
                "countryCode": "FR"
            },
            {
                "name": "Faverney",
                "countryCode": "FR"
            },
            {
                "name": "Fesches-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Fleurey-sur-Ouche",
                "countryCode": "FR"
            },
            {
                "name": "Fleury-la-Vallée",
                "countryCode": "FR"
            },
            {
                "name": "Flogny-la-Chapelle",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-lès-Luxeuil",
                "countryCode": "FR"
            },
            {
                "name": "Fontaines",
                "countryCode": "FR"
            },
            {
                "name": "Foucherans",
                "countryCode": "FR"
            },
            {
                "name": "Fougerolles",
                "countryCode": "FR"
            },
            {
                "name": "Fourchambault",
                "countryCode": "FR"
            },
            {
                "name": "Frahier-et-Chatebier",
                "countryCode": "FR"
            },
            {
                "name": "Fraisans",
                "countryCode": "FR"
            },
            {
                "name": "Franois",
                "countryCode": "FR"
            },
            {
                "name": "Frasne",
                "countryCode": "FR"
            },
            {
                "name": "Froideconche",
                "countryCode": "FR"
            },
            {
                "name": "Frotey-lès-Vesoul",
                "countryCode": "FR"
            },
            {
                "name": "Fénay",
                "countryCode": "FR"
            },
            {
                "name": "Garchizy",
                "countryCode": "FR"
            },
            {
                "name": "Genlis",
                "countryCode": "FR"
            },
            {
                "name": "Gergy",
                "countryCode": "FR"
            },
            {
                "name": "Gevrey-Chambertin",
                "countryCode": "FR"
            },
            {
                "name": "Gilley",
                "countryCode": "FR"
            },
            {
                "name": "Giromagny",
                "countryCode": "FR"
            },
            {
                "name": "Givry",
                "countryCode": "FR"
            },
            {
                "name": "Grand-Charmont",
                "countryCode": "FR"
            },
            {
                "name": "Grandfontaine",
                "countryCode": "FR"
            },
            {
                "name": "Grandvillars",
                "countryCode": "FR"
            },
            {
                "name": "Gray",
                "countryCode": "FR"
            },
            {
                "name": "Gray-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Gron",
                "countryCode": "FR"
            },
            {
                "name": "Gueugnon",
                "countryCode": "FR"
            },
            {
                "name": "Gurgy",
                "countryCode": "FR"
            },
            {
                "name": "Guérigny",
                "countryCode": "FR"
            },
            {
                "name": "Gy",
                "countryCode": "FR"
            },
            {
                "name": "Génelard",
                "countryCode": "FR"
            },
            {
                "name": "Hauteville-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Hurigny",
                "countryCode": "FR"
            },
            {
                "name": "Héricourt",
                "countryCode": "FR"
            },
            {
                "name": "Hérimoncourt",
                "countryCode": "FR"
            },
            {
                "name": "Héry",
                "countryCode": "FR"
            },
            {
                "name": "Imphy",
                "countryCode": "FR"
            },
            {
                "name": "Is-sur-Tille",
                "countryCode": "FR"
            },
            {
                "name": "Joigny",
                "countryCode": "FR"
            },
            {
                "name": "Joncherey",
                "countryCode": "FR"
            },
            {
                "name": "Jougne",
                "countryCode": "FR"
            },
            {
                "name": "Joux-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Jura",
                "countryCode": "FR"
            },
            {
                "name": "Jussey",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-de-Guinchay",
                "countryCode": "FR"
            },
            {
                "name": "La Charité-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "La Clayette",
                "countryCode": "FR"
            },
            {
                "name": "La Cluse-et-Mijoux",
                "countryCode": "FR"
            },
            {
                "name": "La Machine",
                "countryCode": "FR"
            },
            {
                "name": "La Motte-Saint-Jean",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Vineuse",
                "countryCode": "FR"
            },
            {
                "name": "Ladoix-Serrigny",
                "countryCode": "FR"
            },
            {
                "name": "Lamarche-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Laroche-Saint-Cydroine",
                "countryCode": "FR"
            },
            {
                "name": "Lavans-lès-Saint-Claude",
                "countryCode": "FR"
            },
            {
                "name": "Le Breuil",
                "countryCode": "FR"
            },
            {
                "name": "Le Creusot",
                "countryCode": "FR"
            },
            {
                "name": "Le Russey",
                "countryCode": "FR"
            },
            {
                "name": "Lepuix",
                "countryCode": "FR"
            },
            {
                "name": "Les Fins",
                "countryCode": "FR"
            },
            {
                "name": "Les Fourgs",
                "countryCode": "FR"
            },
            {
                "name": "Les Rousses",
                "countryCode": "FR"
            },
            {
                "name": "Levier",
                "countryCode": "FR"
            },
            {
                "name": "Ligny-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Longchamp",
                "countryCode": "FR"
            },
            {
                "name": "Longchaumois",
                "countryCode": "FR"
            },
            {
                "name": "Longecourt-en-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Longvic",
                "countryCode": "FR"
            },
            {
                "name": "Lons-le-Saunier",
                "countryCode": "FR"
            },
            {
                "name": "Lormes",
                "countryCode": "FR"
            },
            {
                "name": "Losne",
                "countryCode": "FR"
            },
            {
                "name": "Louhans",
                "countryCode": "FR"
            },
            {
                "name": "Lucenay-lès-Aix",
                "countryCode": "FR"
            },
            {
                "name": "Lure",
                "countryCode": "FR"
            },
            {
                "name": "Luxeuil-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Luzy",
                "countryCode": "FR"
            },
            {
                "name": "Magny-Cours",
                "countryCode": "FR"
            },
            {
                "name": "Magny-Vernois",
                "countryCode": "FR"
            },
            {
                "name": "Maillot",
                "countryCode": "FR"
            },
            {
                "name": "Malay-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Mamirolle",
                "countryCode": "FR"
            },
            {
                "name": "Mandeure",
                "countryCode": "FR"
            },
            {
                "name": "Marcigny",
                "countryCode": "FR"
            },
            {
                "name": "Marcilly-sur-Tille",
                "countryCode": "FR"
            },
            {
                "name": "Marmagne",
                "countryCode": "FR"
            },
            {
                "name": "Marnay",
                "countryCode": "FR"
            },
            {
                "name": "Marsannay-la-Côte",
                "countryCode": "FR"
            },
            {
                "name": "Marzy",
                "countryCode": "FR"
            },
            {
                "name": "Mathay",
                "countryCode": "FR"
            },
            {
                "name": "Matour",
                "countryCode": "FR"
            },
            {
                "name": "Maîche",
                "countryCode": "FR"
            },
            {
                "name": "Mellecey",
                "countryCode": "FR"
            },
            {
                "name": "Mercurey",
                "countryCode": "FR"
            },
            {
                "name": "Mervans",
                "countryCode": "FR"
            },
            {
                "name": "Messigny-et-Vantoux",
                "countryCode": "FR"
            },
            {
                "name": "Meursault",
                "countryCode": "FR"
            },
            {
                "name": "Migennes",
                "countryCode": "FR"
            },
            {
                "name": "Mirebeau-sur-Bèze",
                "countryCode": "FR"
            },
            {
                "name": "Miserey-Salines",
                "countryCode": "FR"
            },
            {
                "name": "Moirans-en-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Mont-sous-Vaudrey",
                "countryCode": "FR"
            },
            {
                "name": "Montbard",
                "countryCode": "FR"
            },
            {
                "name": "Montbéliard",
                "countryCode": "FR"
            },
            {
                "name": "Montceau-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Montcenis",
                "countryCode": "FR"
            },
            {
                "name": "Montchanin",
                "countryCode": "FR"
            },
            {
                "name": "Montenois",
                "countryCode": "FR"
            },
            {
                "name": "Montfaucon",
                "countryCode": "FR"
            },
            {
                "name": "Montferrand-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Montlebon",
                "countryCode": "FR"
            },
            {
                "name": "Montmorot",
                "countryCode": "FR"
            },
            {
                "name": "Montpont-en-Bresse",
                "countryCode": "FR"
            },
            {
                "name": "Montreux-Château",
                "countryCode": "FR"
            },
            {
                "name": "Monéteau",
                "countryCode": "FR"
            },
            {
                "name": "Morbier",
                "countryCode": "FR"
            },
            {
                "name": "Morez",
                "countryCode": "FR"
            },
            {
                "name": "Morre",
                "countryCode": "FR"
            },
            {
                "name": "Morteau",
                "countryCode": "FR"
            },
            {
                "name": "Morvillars",
                "countryCode": "FR"
            },
            {
                "name": "Mouchard",
                "countryCode": "FR"
            },
            {
                "name": "Moulins-Engilbert",
                "countryCode": "FR"
            },
            {
                "name": "Mâcon",
                "countryCode": "FR"
            },
            {
                "name": "Mélisey",
                "countryCode": "FR"
            },
            {
                "name": "Méziré",
                "countryCode": "FR"
            },
            {
                "name": "Nancray",
                "countryCode": "FR"
            },
            {
                "name": "Navenne",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-Sautour",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Nevers",
                "countryCode": "FR"
            },
            {
                "name": "Noidans-lès-Vesoul",
                "countryCode": "FR"
            },
            {
                "name": "Nolay",
                "countryCode": "FR"
            },
            {
                "name": "Nommay",
                "countryCode": "FR"
            },
            {
                "name": "Novillars",
                "countryCode": "FR"
            },
            {
                "name": "Nuits-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Offemont",
                "countryCode": "FR"
            },
            {
                "name": "Orchamps",
                "countryCode": "FR"
            },
            {
                "name": "Orchamps-Vennes",
                "countryCode": "FR"
            },
            {
                "name": "Orgelet",
                "countryCode": "FR"
            },
            {
                "name": "Ornans",
                "countryCode": "FR"
            },
            {
                "name": "Ouges",
                "countryCode": "FR"
            },
            {
                "name": "Ouroux-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Palinges",
                "countryCode": "FR"
            },
            {
                "name": "Paray-le-Monial",
                "countryCode": "FR"
            },
            {
                "name": "Paron",
                "countryCode": "FR"
            },
            {
                "name": "Pelousey",
                "countryCode": "FR"
            },
            {
                "name": "Perrecy-les-Forges",
                "countryCode": "FR"
            },
            {
                "name": "Perrigny",
                "countryCode": "FR"
            },
            {
                "name": "Perrigny-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Pesmes",
                "countryCode": "FR"
            },
            {
                "name": "Petit-Noir",
                "countryCode": "FR"
            },
            {
                "name": "Pierre-de-Bresse",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefontaine-les-Varans",
                "countryCode": "FR"
            },
            {
                "name": "Pirey",
                "countryCode": "FR"
            },
            {
                "name": "Plancher-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Plancher-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Plombières-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Poligny",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Roide",
                "countryCode": "FR"
            },
            {
                "name": "Pont-sur-Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Pontailler-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Pontarlier",
                "countryCode": "FR"
            },
            {
                "name": "Port-sur-Saône",
                "countryCode": "FR"
            },
            {
                "name": "Pougues-les-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Pouilley-les-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Pouilly-en-Auxois",
                "countryCode": "FR"
            },
            {
                "name": "Pouilly-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Pourrain",
                "countryCode": "FR"
            },
            {
                "name": "Prissé",
                "countryCode": "FR"
            },
            {
                "name": "Prémery",
                "countryCode": "FR"
            },
            {
                "name": "Pusey",
                "countryCode": "FR"
            },
            {
                "name": "Quetigny",
                "countryCode": "FR"
            },
            {
                "name": "Quincey",
                "countryCode": "FR"
            },
            {
                "name": "Quingey",
                "countryCode": "FR"
            },
            {
                "name": "Ravières",
                "countryCode": "FR"
            },
            {
                "name": "Rioz",
                "countryCode": "FR"
            },
            {
                "name": "Roche-lez-Beaupré",
                "countryCode": "FR"
            },
            {
                "name": "Romanèche-Thorins",
                "countryCode": "FR"
            },
            {
                "name": "Romenay",
                "countryCode": "FR"
            },
            {
                "name": "Ronchamp",
                "countryCode": "FR"
            },
            {
                "name": "Rougemont",
                "countryCode": "FR"
            },
            {
                "name": "Rougemont-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Roulans",
                "countryCode": "FR"
            },
            {
                "name": "Roye",
                "countryCode": "FR"
            },
            {
                "name": "Ruffey-lès-Echirey",
                "countryCode": "FR"
            },
            {
                "name": "Rully",
                "countryCode": "FR"
            },
            {
                "name": "Sagy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand-en-Puisaye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amour",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Apollinaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Benin-d’Azy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Berain-sous-Sanvignes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bris-le-Vineux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Claude",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clément",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Eusèbe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fargeau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florentin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gengoux-le-National",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-sur-Baulche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-du-Plain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hippolyte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Losne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-du-Sault",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-en-Grandvaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Leu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Loup-sur-Semouse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lupicin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-des-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-sur-Dheune",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Belle-Roche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Tertre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-en-Bresse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Parize-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-le-Moûtier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sernin-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Usage",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Usuge",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vallier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Valérien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vit",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Éloi",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Suzanne",
                "countryCode": "FR"
            },
            {
                "name": "Salbert",
                "countryCode": "FR"
            },
            {
                "name": "Salins-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Sancey-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Sancé",
                "countryCode": "FR"
            },
            {
                "name": "Sanvignes-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Sassenay",
                "countryCode": "FR"
            },
            {
                "name": "Saulieu",
                "countryCode": "FR"
            },
            {
                "name": "Sauvigny-les-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-lès-Beaune",
                "countryCode": "FR"
            },
            {
                "name": "Saône",
                "countryCode": "FR"
            },
            {
                "name": "Scey-sur-Saône-et-Saint-Albin",
                "countryCode": "FR"
            },
            {
                "name": "Seignelay",
                "countryCode": "FR"
            },
            {
                "name": "Seloncourt",
                "countryCode": "FR"
            },
            {
                "name": "Selongey",
                "countryCode": "FR"
            },
            {
                "name": "Semur-en-Auxois",
                "countryCode": "FR"
            },
            {
                "name": "Sennecey-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Sennecey-lès-Dijon",
                "countryCode": "FR"
            },
            {
                "name": "Sens",
                "countryCode": "FR"
            },
            {
                "name": "Sergines",
                "countryCode": "FR"
            },
            {
                "name": "Sermoise-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Serre-les-Sapins",
                "countryCode": "FR"
            },
            {
                "name": "Seurre",
                "countryCode": "FR"
            },
            {
                "name": "Sevrey",
                "countryCode": "FR"
            },
            {
                "name": "Simandre",
                "countryCode": "FR"
            },
            {
                "name": "Sochaux",
                "countryCode": "FR"
            },
            {
                "name": "Sornay",
                "countryCode": "FR"
            },
            {
                "name": "Soucy",
                "countryCode": "FR"
            },
            {
                "name": "Taillecourt",
                "countryCode": "FR"
            },
            {
                "name": "Talant",
                "countryCode": "FR"
            },
            {
                "name": "Tanlay",
                "countryCode": "FR"
            },
            {
                "name": "Tavaux",
                "countryCode": "FR"
            },
            {
                "name": "Territoire de Belfort",
                "countryCode": "FR"
            },
            {
                "name": "Thise",
                "countryCode": "FR"
            },
            {
                "name": "Thorigny-sur-Oreuse",
                "countryCode": "FR"
            },
            {
                "name": "Tonnerre",
                "countryCode": "FR"
            },
            {
                "name": "Torcy",
                "countryCode": "FR"
            },
            {
                "name": "Toucy",
                "countryCode": "FR"
            },
            {
                "name": "Toulon-sur-Arroux",
                "countryCode": "FR"
            },
            {
                "name": "Tournus",
                "countryCode": "FR"
            },
            {
                "name": "Urzy",
                "countryCode": "FR"
            },
            {
                "name": "Vaivre-et-Montoille",
                "countryCode": "FR"
            },
            {
                "name": "Valdahon",
                "countryCode": "FR"
            },
            {
                "name": "Valdoie",
                "countryCode": "FR"
            },
            {
                "name": "Valentigney",
                "countryCode": "FR"
            },
            {
                "name": "Valfin-lès-Saint-Claude",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-Vauzelles",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Varois-et-Chaignot",
                "countryCode": "FR"
            },
            {
                "name": "Varzy",
                "countryCode": "FR"
            },
            {
                "name": "Velars-sur-Ouche",
                "countryCode": "FR"
            },
            {
                "name": "Venarey-les-Laumes",
                "countryCode": "FR"
            },
            {
                "name": "Venoy",
                "countryCode": "FR"
            },
            {
                "name": "Vercel-Villedieu-le-Camp",
                "countryCode": "FR"
            },
            {
                "name": "Verdun-sur-le-Doubs",
                "countryCode": "FR"
            },
            {
                "name": "Vergigny",
                "countryCode": "FR"
            },
            {
                "name": "Vermenton",
                "countryCode": "FR"
            },
            {
                "name": "Vesoul",
                "countryCode": "FR"
            },
            {
                "name": "Vieux-Charmont",
                "countryCode": "FR"
            },
            {
                "name": "Villeblevin",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-la-Guyard",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-sur-Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Villers-le-Lac",
                "countryCode": "FR"
            },
            {
                "name": "Villersexel",
                "countryCode": "FR"
            },
            {
                "name": "Vinneuf",
                "countryCode": "FR"
            },
            {
                "name": "Virey-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Vitteaux",
                "countryCode": "FR"
            },
            {
                "name": "Voujeaucourt",
                "countryCode": "FR"
            },
            {
                "name": "Véron",
                "countryCode": "FR"
            },
            {
                "name": "Échenoz-la-Méline",
                "countryCode": "FR"
            },
            {
                "name": "École-Valentin",
                "countryCode": "FR"
            },
            {
                "name": "Écuisses",
                "countryCode": "FR"
            },
            {
                "name": "Égriselles-le-Bocage",
                "countryCode": "FR"
            },
            {
                "name": "Épervans",
                "countryCode": "FR"
            },
            {
                "name": "Épinac",
                "countryCode": "FR"
            },
            {
                "name": "Étalans",
                "countryCode": "FR"
            },
            {
                "name": "Étang-sur-Arroux",
                "countryCode": "FR"
            },
            {
                "name": "Étupes",
                "countryCode": "FR"
            },
            {
                "name": "Acigné",
                "countryCode": "FR"
            },
            {
                "name": "Allaire",
                "countryCode": "FR"
            },
            {
                "name": "Amanlis",
                "countryCode": "FR"
            },
            {
                "name": "Ambon",
                "countryCode": "FR"
            },
            {
                "name": "Antrain",
                "countryCode": "FR"
            },
            {
                "name": "Argentré-du-Plessis",
                "countryCode": "FR"
            },
            {
                "name": "Arradon",
                "countryCode": "FR"
            },
            {
                "name": "Arzano",
                "countryCode": "FR"
            },
            {
                "name": "Arzon",
                "countryCode": "FR"
            },
            {
                "name": "Audierne",
                "countryCode": "FR"
            },
            {
                "name": "Augan",
                "countryCode": "FR"
            },
            {
                "name": "Auray",
                "countryCode": "FR"
            },
            {
                "name": "Baden",
                "countryCode": "FR"
            },
            {
                "name": "Baguer-Morvan",
                "countryCode": "FR"
            },
            {
                "name": "Baguer-Pican",
                "countryCode": "FR"
            },
            {
                "name": "Bain-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Bains-sur-Oust",
                "countryCode": "FR"
            },
            {
                "name": "Bais",
                "countryCode": "FR"
            },
            {
                "name": "Balazé",
                "countryCode": "FR"
            },
            {
                "name": "Bannalec",
                "countryCode": "FR"
            },
            {
                "name": "Baud",
                "countryCode": "FR"
            },
            {
                "name": "Baulon",
                "countryCode": "FR"
            },
            {
                "name": "Bazouges-la-Pérouse",
                "countryCode": "FR"
            },
            {
                "name": "Beaucé",
                "countryCode": "FR"
            },
            {
                "name": "Beignon",
                "countryCode": "FR"
            },
            {
                "name": "Belle-Isle-en-Terre",
                "countryCode": "FR"
            },
            {
                "name": "Belz",
                "countryCode": "FR"
            },
            {
                "name": "Berné",
                "countryCode": "FR"
            },
            {
                "name": "Berric",
                "countryCode": "FR"
            },
            {
                "name": "Berrien",
                "countryCode": "FR"
            },
            {
                "name": "Betton",
                "countryCode": "FR"
            },
            {
                "name": "Beuzec-Cap-Sizun",
                "countryCode": "FR"
            },
            {
                "name": "Bignan",
                "countryCode": "FR"
            },
            {
                "name": "Binic",
                "countryCode": "FR"
            },
            {
                "name": "Bodilis",
                "countryCode": "FR"
            },
            {
                "name": "Bohars",
                "countryCode": "FR"
            },
            {
                "name": "Boisgervilly",
                "countryCode": "FR"
            },
            {
                "name": "Bonnemain",
                "countryCode": "FR"
            },
            {
                "name": "Bourbriac",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-des-Comptes",
                "countryCode": "FR"
            },
            {
                "name": "Bourgbarré",
                "countryCode": "FR"
            },
            {
                "name": "Brandérion",
                "countryCode": "FR"
            },
            {
                "name": "Brasparts",
                "countryCode": "FR"
            },
            {
                "name": "Brech",
                "countryCode": "FR"
            },
            {
                "name": "Brest",
                "countryCode": "FR"
            },
            {
                "name": "Breteil",
                "countryCode": "FR"
            },
            {
                "name": "Briec",
                "countryCode": "FR"
            },
            {
                "name": "Broons",
                "countryCode": "FR"
            },
            {
                "name": "Bruz",
                "countryCode": "FR"
            },
            {
                "name": "Bréal-sous-Montfort",
                "countryCode": "FR"
            },
            {
                "name": "Brécé",
                "countryCode": "FR"
            },
            {
                "name": "Bréhand",
                "countryCode": "FR"
            },
            {
                "name": "Bubry",
                "countryCode": "FR"
            },
            {
                "name": "Bédée",
                "countryCode": "FR"
            },
            {
                "name": "Béganne",
                "countryCode": "FR"
            },
            {
                "name": "Bégard",
                "countryCode": "FR"
            },
            {
                "name": "Bénodet",
                "countryCode": "FR"
            },
            {
                "name": "Caden",
                "countryCode": "FR"
            },
            {
                "name": "Callac",
                "countryCode": "FR"
            },
            {
                "name": "Camaret-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Camors",
                "countryCode": "FR"
            },
            {
                "name": "Campénéac",
                "countryCode": "FR"
            },
            {
                "name": "Cancale",
                "countryCode": "FR"
            },
            {
                "name": "Carantec",
                "countryCode": "FR"
            },
            {
                "name": "Carentoir",
                "countryCode": "FR"
            },
            {
                "name": "Carhaix-Plouguer",
                "countryCode": "FR"
            },
            {
                "name": "Carnac",
                "countryCode": "FR"
            },
            {
                "name": "Caro",
                "countryCode": "FR"
            },
            {
                "name": "Cast",
                "countryCode": "FR"
            },
            {
                "name": "Caudan",
                "countryCode": "FR"
            },
            {
                "name": "Caulnes",
                "countryCode": "FR"
            },
            {
                "name": "Cavan",
                "countryCode": "FR"
            },
            {
                "name": "Cesson-Sévigné",
                "countryCode": "FR"
            },
            {
                "name": "Chanteloup",
                "countryCode": "FR"
            },
            {
                "name": "Chantepie",
                "countryCode": "FR"
            },
            {
                "name": "Chartres-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Chasné-sur-Illet",
                "countryCode": "FR"
            },
            {
                "name": "Chavagne",
                "countryCode": "FR"
            },
            {
                "name": "Cherrueix",
                "countryCode": "FR"
            },
            {
                "name": "Chevaigné",
                "countryCode": "FR"
            },
            {
                "name": "Châteaubourg",
                "countryCode": "FR"
            },
            {
                "name": "Châteaugiron",
                "countryCode": "FR"
            },
            {
                "name": "Châteaulin",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-du-Faou",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-d’Ille-et-Vilaine",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-en-Vendelais",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Seiche",
                "countryCode": "FR"
            },
            {
                "name": "Cintré",
                "countryCode": "FR"
            },
            {
                "name": "Clohars-Carnoët",
                "countryCode": "FR"
            },
            {
                "name": "Clohars-Fouesnant",
                "countryCode": "FR"
            },
            {
                "name": "Cléden-Poher",
                "countryCode": "FR"
            },
            {
                "name": "Cléder",
                "countryCode": "FR"
            },
            {
                "name": "Cléguer",
                "countryCode": "FR"
            },
            {
                "name": "Cléguérec",
                "countryCode": "FR"
            },
            {
                "name": "Colpo",
                "countryCode": "FR"
            },
            {
                "name": "Combourg",
                "countryCode": "FR"
            },
            {
                "name": "Combrit",
                "countryCode": "FR"
            },
            {
                "name": "Concarneau",
                "countryCode": "FR"
            },
            {
                "name": "Coray",
                "countryCode": "FR"
            },
            {
                "name": "Corlay",
                "countryCode": "FR"
            },
            {
                "name": "Corps-Nuds",
                "countryCode": "FR"
            },
            {
                "name": "Corseul",
                "countryCode": "FR"
            },
            {
                "name": "Coësmes",
                "countryCode": "FR"
            },
            {
                "name": "Coëtmieux",
                "countryCode": "FR"
            },
            {
                "name": "Crach",
                "countryCode": "FR"
            },
            {
                "name": "Crevin",
                "countryCode": "FR"
            },
            {
                "name": "Crozon",
                "countryCode": "FR"
            },
            {
                "name": "Crédin",
                "countryCode": "FR"
            },
            {
                "name": "Créhen",
                "countryCode": "FR"
            },
            {
                "name": "Damgan",
                "countryCode": "FR"
            },
            {
                "name": "Daoulas",
                "countryCode": "FR"
            },
            {
                "name": "Dinan",
                "countryCode": "FR"
            },
            {
                "name": "Dinard",
                "countryCode": "FR"
            },
            {
                "name": "Dingé",
                "countryCode": "FR"
            },
            {
                "name": "Dinéault",
                "countryCode": "FR"
            },
            {
                "name": "Dirinon",
                "countryCode": "FR"
            },
            {
                "name": "Dol-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Domagné",
                "countryCode": "FR"
            },
            {
                "name": "Domalain",
                "countryCode": "FR"
            },
            {
                "name": "Douarnenez",
                "countryCode": "FR"
            },
            {
                "name": "Département d'Ille-et-Vilaine",
                "countryCode": "FR"
            },
            {
                "name": "Département des Côtes-d’Armor",
                "countryCode": "FR"
            },
            {
                "name": "Département du Finistère",
                "countryCode": "FR"
            },
            {
                "name": "Edern",
                "countryCode": "FR"
            },
            {
                "name": "Elliant",
                "countryCode": "FR"
            },
            {
                "name": "Elven",
                "countryCode": "FR"
            },
            {
                "name": "Epiniac",
                "countryCode": "FR"
            },
            {
                "name": "Erbrée",
                "countryCode": "FR"
            },
            {
                "name": "Ercé-en-Lamée",
                "countryCode": "FR"
            },
            {
                "name": "Ercé-près-Liffré",
                "countryCode": "FR"
            },
            {
                "name": "Erdeven",
                "countryCode": "FR"
            },
            {
                "name": "Ergué-Gabéric",
                "countryCode": "FR"
            },
            {
                "name": "Erquy",
                "countryCode": "FR"
            },
            {
                "name": "Esquibien",
                "countryCode": "FR"
            },
            {
                "name": "Fouesnant",
                "countryCode": "FR"
            },
            {
                "name": "Fougères",
                "countryCode": "FR"
            },
            {
                "name": "Fouillard",
                "countryCode": "FR"
            },
            {
                "name": "Fréhel",
                "countryCode": "FR"
            },
            {
                "name": "Férel",
                "countryCode": "FR"
            },
            {
                "name": "Gaël",
                "countryCode": "FR"
            },
            {
                "name": "Gestel",
                "countryCode": "FR"
            },
            {
                "name": "Glomel",
                "countryCode": "FR"
            },
            {
                "name": "Gosné",
                "countryCode": "FR"
            },
            {
                "name": "Gouarec",
                "countryCode": "FR"
            },
            {
                "name": "Goudelin",
                "countryCode": "FR"
            },
            {
                "name": "Gouesnach",
                "countryCode": "FR"
            },
            {
                "name": "Gouesnou",
                "countryCode": "FR"
            },
            {
                "name": "Gourin",
                "countryCode": "FR"
            },
            {
                "name": "Gouézec",
                "countryCode": "FR"
            },
            {
                "name": "Goven",
                "countryCode": "FR"
            },
            {
                "name": "Grand-Champ",
                "countryCode": "FR"
            },
            {
                "name": "Grand-Fougeray",
                "countryCode": "FR"
            },
            {
                "name": "Groix",
                "countryCode": "FR"
            },
            {
                "name": "Grâces",
                "countryCode": "FR"
            },
            {
                "name": "Guengat",
                "countryCode": "FR"
            },
            {
                "name": "Guer",
                "countryCode": "FR"
            },
            {
                "name": "Guerlesquin",
                "countryCode": "FR"
            },
            {
                "name": "Guern",
                "countryCode": "FR"
            },
            {
                "name": "Guichen",
                "countryCode": "FR"
            },
            {
                "name": "Guiclan",
                "countryCode": "FR"
            },
            {
                "name": "Guidel-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Guignen",
                "countryCode": "FR"
            },
            {
                "name": "Guilers",
                "countryCode": "FR"
            },
            {
                "name": "Guillac",
                "countryCode": "FR"
            },
            {
                "name": "Guilliers",
                "countryCode": "FR"
            },
            {
                "name": "Guilvinec",
                "countryCode": "FR"
            },
            {
                "name": "Guingamp",
                "countryCode": "FR"
            },
            {
                "name": "Guipavas",
                "countryCode": "FR"
            },
            {
                "name": "Guipel",
                "countryCode": "FR"
            },
            {
                "name": "Guipry",
                "countryCode": "FR"
            },
            {
                "name": "Guiscriff",
                "countryCode": "FR"
            },
            {
                "name": "Guissény",
                "countryCode": "FR"
            },
            {
                "name": "Guégon",
                "countryCode": "FR"
            },
            {
                "name": "Guémené-sur-Scorff",
                "countryCode": "FR"
            },
            {
                "name": "Guénin",
                "countryCode": "FR"
            },
            {
                "name": "Gévezé",
                "countryCode": "FR"
            },
            {
                "name": "Hanvec",
                "countryCode": "FR"
            },
            {
                "name": "Hennebont",
                "countryCode": "FR"
            },
            {
                "name": "Henvic",
                "countryCode": "FR"
            },
            {
                "name": "Hillion",
                "countryCode": "FR"
            },
            {
                "name": "Hirel",
                "countryCode": "FR"
            },
            {
                "name": "Huelgoat",
                "countryCode": "FR"
            },
            {
                "name": "Hédé-Bazouges",
                "countryCode": "FR"
            },
            {
                "name": "Hénanbihen",
                "countryCode": "FR"
            },
            {
                "name": "Hénon",
                "countryCode": "FR"
            },
            {
                "name": "Hôpital-Camfrout",
                "countryCode": "FR"
            },
            {
                "name": "Iffendic",
                "countryCode": "FR"
            },
            {
                "name": "Inguiniel",
                "countryCode": "FR"
            },
            {
                "name": "Irodouër",
                "countryCode": "FR"
            },
            {
                "name": "Irvillac",
                "countryCode": "FR"
            },
            {
                "name": "Janzé",
                "countryCode": "FR"
            },
            {
                "name": "Javené",
                "countryCode": "FR"
            },
            {
                "name": "Josselin",
                "countryCode": "FR"
            },
            {
                "name": "Jugon-les-Lacs",
                "countryCode": "FR"
            },
            {
                "name": "Kerlouan",
                "countryCode": "FR"
            },
            {
                "name": "Kernilis",
                "countryCode": "FR"
            },
            {
                "name": "Kersaint-Plabennec",
                "countryCode": "FR"
            },
            {
                "name": "Kervignac",
                "countryCode": "FR"
            },
            {
                "name": "La Boussac",
                "countryCode": "FR"
            },
            {
                "name": "La Bouëxière",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Janson",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Thouarault",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-des-Fougeretz",
                "countryCode": "FR"
            },
            {
                "name": "La Dominelais",
                "countryCode": "FR"
            },
            {
                "name": "La Forest-Landerneau",
                "countryCode": "FR"
            },
            {
                "name": "La Forêt-Fouesnant",
                "countryCode": "FR"
            },
            {
                "name": "La Fresnais",
                "countryCode": "FR"
            },
            {
                "name": "La Gacilly",
                "countryCode": "FR"
            },
            {
                "name": "La Gouesnière",
                "countryCode": "FR"
            },
            {
                "name": "La Guerche-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "La Motte",
                "countryCode": "FR"
            },
            {
                "name": "La Méaugon",
                "countryCode": "FR"
            },
            {
                "name": "La Mézière",
                "countryCode": "FR"
            },
            {
                "name": "La Richardais",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Derrien",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Maurice",
                "countryCode": "FR"
            },
            {
                "name": "La Trinité-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Vraie-Croix",
                "countryCode": "FR"
            },
            {
                "name": "Laillé",
                "countryCode": "FR"
            },
            {
                "name": "Lamballe",
                "countryCode": "FR"
            },
            {
                "name": "Lampaul-Guimiliau",
                "countryCode": "FR"
            },
            {
                "name": "Lampaul-Plouarzel",
                "countryCode": "FR"
            },
            {
                "name": "Lancieux",
                "countryCode": "FR"
            },
            {
                "name": "Landaul",
                "countryCode": "FR"
            },
            {
                "name": "Landeleau",
                "countryCode": "FR"
            },
            {
                "name": "Landerneau",
                "countryCode": "FR"
            },
            {
                "name": "Landivisiau",
                "countryCode": "FR"
            },
            {
                "name": "Landrévarzec",
                "countryCode": "FR"
            },
            {
                "name": "Landudec",
                "countryCode": "FR"
            },
            {
                "name": "Landunvez",
                "countryCode": "FR"
            },
            {
                "name": "Landéan",
                "countryCode": "FR"
            },
            {
                "name": "Landéda",
                "countryCode": "FR"
            },
            {
                "name": "Landéhen",
                "countryCode": "FR"
            },
            {
                "name": "Landévant",
                "countryCode": "FR"
            },
            {
                "name": "Lanester",
                "countryCode": "FR"
            },
            {
                "name": "Langoat",
                "countryCode": "FR"
            },
            {
                "name": "Langon",
                "countryCode": "FR"
            },
            {
                "name": "Langonnet",
                "countryCode": "FR"
            },
            {
                "name": "Langueux",
                "countryCode": "FR"
            },
            {
                "name": "Languidic",
                "countryCode": "FR"
            },
            {
                "name": "Lanmeur",
                "countryCode": "FR"
            },
            {
                "name": "Lannilis",
                "countryCode": "FR"
            },
            {
                "name": "Lannion",
                "countryCode": "FR"
            },
            {
                "name": "Lanouée",
                "countryCode": "FR"
            },
            {
                "name": "Lanrivoaré",
                "countryCode": "FR"
            },
            {
                "name": "Lanrodec",
                "countryCode": "FR"
            },
            {
                "name": "Lantic",
                "countryCode": "FR"
            },
            {
                "name": "Lanvallay",
                "countryCode": "FR"
            },
            {
                "name": "Lanvollon",
                "countryCode": "FR"
            },
            {
                "name": "Lanvénégen",
                "countryCode": "FR"
            },
            {
                "name": "Lanvéoc",
                "countryCode": "FR"
            },
            {
                "name": "Larmor-Baden",
                "countryCode": "FR"
            },
            {
                "name": "Larmor-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Lassy",
                "countryCode": "FR"
            },
            {
                "name": "Le Bono",
                "countryCode": "FR"
            },
            {
                "name": "Le Conquet",
                "countryCode": "FR"
            },
            {
                "name": "Le Drennec",
                "countryCode": "FR"
            },
            {
                "name": "Le Faou",
                "countryCode": "FR"
            },
            {
                "name": "Le Faouët",
                "countryCode": "FR"
            },
            {
                "name": "Le Folgoët",
                "countryCode": "FR"
            },
            {
                "name": "Le Fœil",
                "countryCode": "FR"
            },
            {
                "name": "Le Minihic-sur-Rance",
                "countryCode": "FR"
            },
            {
                "name": "Le Palais",
                "countryCode": "FR"
            },
            {
                "name": "Le Pertre",
                "countryCode": "FR"
            },
            {
                "name": "Le Relecq-Kerhuon",
                "countryCode": "FR"
            },
            {
                "name": "Le Rheu",
                "countryCode": "FR"
            },
            {
                "name": "Le Sourn",
                "countryCode": "FR"
            },
            {
                "name": "Le Theil-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Le Trévoux",
                "countryCode": "FR"
            },
            {
                "name": "Le Vieux-Marché",
                "countryCode": "FR"
            },
            {
                "name": "Le Vivier-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Les Champs-Géraux",
                "countryCode": "FR"
            },
            {
                "name": "Lesneven",
                "countryCode": "FR"
            },
            {
                "name": "Liffré",
                "countryCode": "FR"
            },
            {
                "name": "Limerzel",
                "countryCode": "FR"
            },
            {
                "name": "Livré-sur-Changeon",
                "countryCode": "FR"
            },
            {
                "name": "Locmariaquer",
                "countryCode": "FR"
            },
            {
                "name": "Locminé",
                "countryCode": "FR"
            },
            {
                "name": "Locmiquélic",
                "countryCode": "FR"
            },
            {
                "name": "Locoal-Mendon",
                "countryCode": "FR"
            },
            {
                "name": "Locquirec",
                "countryCode": "FR"
            },
            {
                "name": "Locquémeau",
                "countryCode": "FR"
            },
            {
                "name": "Loctudy",
                "countryCode": "FR"
            },
            {
                "name": "Logonna-Daoulas",
                "countryCode": "FR"
            },
            {
                "name": "Loperhet",
                "countryCode": "FR"
            },
            {
                "name": "Lopérec",
                "countryCode": "FR"
            },
            {
                "name": "Lorient",
                "countryCode": "FR"
            },
            {
                "name": "Louannec",
                "countryCode": "FR"
            },
            {
                "name": "Louargat",
                "countryCode": "FR"
            },
            {
                "name": "Loudéac",
                "countryCode": "FR"
            },
            {
                "name": "Louvigné-de-Bais",
                "countryCode": "FR"
            },
            {
                "name": "Louvigné-du-Désert",
                "countryCode": "FR"
            },
            {
                "name": "Loyat",
                "countryCode": "FR"
            },
            {
                "name": "Luitré",
                "countryCode": "FR"
            },
            {
                "name": "Lécousse",
                "countryCode": "FR"
            },
            {
                "name": "Léhon",
                "countryCode": "FR"
            },
            {
                "name": "Lézardrieux",
                "countryCode": "FR"
            },
            {
                "name": "Malansac",
                "countryCode": "FR"
            },
            {
                "name": "Malestroit",
                "countryCode": "FR"
            },
            {
                "name": "Malguénac",
                "countryCode": "FR"
            },
            {
                "name": "Martigné-Ferchaud",
                "countryCode": "FR"
            },
            {
                "name": "Marzan",
                "countryCode": "FR"
            },
            {
                "name": "Matignon",
                "countryCode": "FR"
            },
            {
                "name": "Maure-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Mauron",
                "countryCode": "FR"
            },
            {
                "name": "Maxent",
                "countryCode": "FR"
            },
            {
                "name": "Maël-Carhaix",
                "countryCode": "FR"
            },
            {
                "name": "Meillac",
                "countryCode": "FR"
            },
            {
                "name": "Melesse",
                "countryCode": "FR"
            },
            {
                "name": "Melgven",
                "countryCode": "FR"
            },
            {
                "name": "Mellac",
                "countryCode": "FR"
            },
            {
                "name": "Melrand",
                "countryCode": "FR"
            },
            {
                "name": "Merdrignac",
                "countryCode": "FR"
            },
            {
                "name": "Merlevenez",
                "countryCode": "FR"
            },
            {
                "name": "Meslan",
                "countryCode": "FR"
            },
            {
                "name": "Messac",
                "countryCode": "FR"
            },
            {
                "name": "Meucon",
                "countryCode": "FR"
            },
            {
                "name": "Milizac",
                "countryCode": "FR"
            },
            {
                "name": "Miniac-Morvan",
                "countryCode": "FR"
            },
            {
                "name": "Minihy-Tréguier",
                "countryCode": "FR"
            },
            {
                "name": "Molac",
                "countryCode": "FR"
            },
            {
                "name": "Montauban-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Monterblanc",
                "countryCode": "FR"
            },
            {
                "name": "Monterfil",
                "countryCode": "FR"
            },
            {
                "name": "Montfort-sur-Meu",
                "countryCode": "FR"
            },
            {
                "name": "Montgermont",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil-le-Gast",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil-sous-Pérouse",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil-sur-Ille",
                "countryCode": "FR"
            },
            {
                "name": "Morbihan",
                "countryCode": "FR"
            },
            {
                "name": "Mordelles",
                "countryCode": "FR"
            },
            {
                "name": "Morlaix",
                "countryCode": "FR"
            },
            {
                "name": "Moréac",
                "countryCode": "FR"
            },
            {
                "name": "Moustoir-Ac",
                "countryCode": "FR"
            },
            {
                "name": "Moëlan-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Muzillac",
                "countryCode": "FR"
            },
            {
                "name": "Médréac",
                "countryCode": "FR"
            },
            {
                "name": "Ménéac",
                "countryCode": "FR"
            },
            {
                "name": "Mûr-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Naizin",
                "countryCode": "FR"
            },
            {
                "name": "Neulliac",
                "countryCode": "FR"
            },
            {
                "name": "Nivillac",
                "countryCode": "FR"
            },
            {
                "name": "Nostang",
                "countryCode": "FR"
            },
            {
                "name": "Nouvoitou",
                "countryCode": "FR"
            },
            {
                "name": "Noyal-Muzillac",
                "countryCode": "FR"
            },
            {
                "name": "Noyal-Pontivy",
                "countryCode": "FR"
            },
            {
                "name": "Noyal-sur-Vilaine",
                "countryCode": "FR"
            },
            {
                "name": "Névez",
                "countryCode": "FR"
            },
            {
                "name": "Orgères",
                "countryCode": "FR"
            },
            {
                "name": "Ossé",
                "countryCode": "FR"
            },
            {
                "name": "Pabu",
                "countryCode": "FR"
            },
            {
                "name": "Pacé",
                "countryCode": "FR"
            },
            {
                "name": "Paimpol",
                "countryCode": "FR"
            },
            {
                "name": "Paimpont",
                "countryCode": "FR"
            },
            {
                "name": "Pancé",
                "countryCode": "FR"
            },
            {
                "name": "Parigné",
                "countryCode": "FR"
            },
            {
                "name": "Peillac",
                "countryCode": "FR"
            },
            {
                "name": "Pencran",
                "countryCode": "FR"
            },
            {
                "name": "Penvénan",
                "countryCode": "FR"
            },
            {
                "name": "Perros-Guirec",
                "countryCode": "FR"
            },
            {
                "name": "Pipriac",
                "countryCode": "FR"
            },
            {
                "name": "Piré-sur-Seiche",
                "countryCode": "FR"
            },
            {
                "name": "Plabennec",
                "countryCode": "FR"
            },
            {
                "name": "Plaine-Haute",
                "countryCode": "FR"
            },
            {
                "name": "Plaintel",
                "countryCode": "FR"
            },
            {
                "name": "Plancoët",
                "countryCode": "FR"
            },
            {
                "name": "Planguenoual",
                "countryCode": "FR"
            },
            {
                "name": "Plaudren",
                "countryCode": "FR"
            },
            {
                "name": "Pleine-Fougères",
                "countryCode": "FR"
            },
            {
                "name": "Plerguer",
                "countryCode": "FR"
            },
            {
                "name": "Plescop",
                "countryCode": "FR"
            },
            {
                "name": "Pleslin-Trigavou",
                "countryCode": "FR"
            },
            {
                "name": "Plessala",
                "countryCode": "FR"
            },
            {
                "name": "Plestan",
                "countryCode": "FR"
            },
            {
                "name": "Plestin-les-Grèves",
                "countryCode": "FR"
            },
            {
                "name": "Pleubian",
                "countryCode": "FR"
            },
            {
                "name": "Pleucadeuc",
                "countryCode": "FR"
            },
            {
                "name": "Pleudaniel",
                "countryCode": "FR"
            },
            {
                "name": "Pleudihen-sur-Rance",
                "countryCode": "FR"
            },
            {
                "name": "Pleugriffet",
                "countryCode": "FR"
            },
            {
                "name": "Pleugueneuc",
                "countryCode": "FR"
            },
            {
                "name": "Pleumeleuc",
                "countryCode": "FR"
            },
            {
                "name": "Pleumeur-Bodou",
                "countryCode": "FR"
            },
            {
                "name": "Pleumeur-Gautier",
                "countryCode": "FR"
            },
            {
                "name": "Pleurtuit",
                "countryCode": "FR"
            },
            {
                "name": "Pleuven",
                "countryCode": "FR"
            },
            {
                "name": "Pleyben",
                "countryCode": "FR"
            },
            {
                "name": "Pleyber-Christ",
                "countryCode": "FR"
            },
            {
                "name": "Plobannalec-Lesconil",
                "countryCode": "FR"
            },
            {
                "name": "Ploemel",
                "countryCode": "FR"
            },
            {
                "name": "Ploemeur",
                "countryCode": "FR"
            },
            {
                "name": "Ploeren",
                "countryCode": "FR"
            },
            {
                "name": "Plogastel-Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Plogoff",
                "countryCode": "FR"
            },
            {
                "name": "Plogonnec",
                "countryCode": "FR"
            },
            {
                "name": "Plomelin",
                "countryCode": "FR"
            },
            {
                "name": "Plomeur",
                "countryCode": "FR"
            },
            {
                "name": "Plomodiern",
                "countryCode": "FR"
            },
            {
                "name": "Plonéis",
                "countryCode": "FR"
            },
            {
                "name": "Plonéour-Lanvern",
                "countryCode": "FR"
            },
            {
                "name": "Plonévez-Porzay",
                "countryCode": "FR"
            },
            {
                "name": "Plonévez-du-Faou",
                "countryCode": "FR"
            },
            {
                "name": "Plouagat",
                "countryCode": "FR"
            },
            {
                "name": "Plouaret",
                "countryCode": "FR"
            },
            {
                "name": "Plouarzel",
                "countryCode": "FR"
            },
            {
                "name": "Plouasne",
                "countryCode": "FR"
            },
            {
                "name": "Plouay",
                "countryCode": "FR"
            },
            {
                "name": "Ploubalay",
                "countryCode": "FR"
            },
            {
                "name": "Ploubazlanec",
                "countryCode": "FR"
            },
            {
                "name": "Ploubezre",
                "countryCode": "FR"
            },
            {
                "name": "Ploudalmézeau",
                "countryCode": "FR"
            },
            {
                "name": "Ploudaniel",
                "countryCode": "FR"
            },
            {
                "name": "Plouescat",
                "countryCode": "FR"
            },
            {
                "name": "Plouezoc'h",
                "countryCode": "FR"
            },
            {
                "name": "Ploufragan",
                "countryCode": "FR"
            },
            {
                "name": "Plougasnou",
                "countryCode": "FR"
            },
            {
                "name": "Plougastel-Daoulas",
                "countryCode": "FR"
            },
            {
                "name": "Plougonvelin",
                "countryCode": "FR"
            },
            {
                "name": "Plougonven",
                "countryCode": "FR"
            },
            {
                "name": "Plougoulm",
                "countryCode": "FR"
            },
            {
                "name": "Plougoumelen",
                "countryCode": "FR"
            },
            {
                "name": "Plougourvest",
                "countryCode": "FR"
            },
            {
                "name": "Plougrescant",
                "countryCode": "FR"
            },
            {
                "name": "Plouguenast",
                "countryCode": "FR"
            },
            {
                "name": "Plouguerneau",
                "countryCode": "FR"
            },
            {
                "name": "Plouguernével",
                "countryCode": "FR"
            },
            {
                "name": "Plouguiel",
                "countryCode": "FR"
            },
            {
                "name": "Plouguin",
                "countryCode": "FR"
            },
            {
                "name": "Plouha",
                "countryCode": "FR"
            },
            {
                "name": "Plouharnel",
                "countryCode": "FR"
            },
            {
                "name": "Plouhinec",
                "countryCode": "FR"
            },
            {
                "name": "Plouider",
                "countryCode": "FR"
            },
            {
                "name": "Plouigneau",
                "countryCode": "FR"
            },
            {
                "name": "Plouisy",
                "countryCode": "FR"
            },
            {
                "name": "Ploumagoar",
                "countryCode": "FR"
            },
            {
                "name": "Ploumilliau",
                "countryCode": "FR"
            },
            {
                "name": "Ploumoguer",
                "countryCode": "FR"
            },
            {
                "name": "Plounéour-Ménez",
                "countryCode": "FR"
            },
            {
                "name": "Plounéour-Trez",
                "countryCode": "FR"
            },
            {
                "name": "Plounéventer",
                "countryCode": "FR"
            },
            {
                "name": "Plounévez-Lochrist",
                "countryCode": "FR"
            },
            {
                "name": "Plounévez-Moëdec",
                "countryCode": "FR"
            },
            {
                "name": "Plounévez-Quintin",
                "countryCode": "FR"
            },
            {
                "name": "Plounévézel",
                "countryCode": "FR"
            },
            {
                "name": "Plouray",
                "countryCode": "FR"
            },
            {
                "name": "Plourin-lès-Morlaix",
                "countryCode": "FR"
            },
            {
                "name": "Plourivo",
                "countryCode": "FR"
            },
            {
                "name": "Plouvien",
                "countryCode": "FR"
            },
            {
                "name": "Plouvorn",
                "countryCode": "FR"
            },
            {
                "name": "Plouzané",
                "countryCode": "FR"
            },
            {
                "name": "Plouzévédé",
                "countryCode": "FR"
            },
            {
                "name": "Plouédern",
                "countryCode": "FR"
            },
            {
                "name": "Plouénan",
                "countryCode": "FR"
            },
            {
                "name": "Plouézec",
                "countryCode": "FR"
            },
            {
                "name": "Plouëc-du-Trieux",
                "countryCode": "FR"
            },
            {
                "name": "Plouër-sur-Rance",
                "countryCode": "FR"
            },
            {
                "name": "Plozévet",
                "countryCode": "FR"
            },
            {
                "name": "Ploërdut",
                "countryCode": "FR"
            },
            {
                "name": "Ploërmel",
                "countryCode": "FR"
            },
            {
                "name": "Ploëzal",
                "countryCode": "FR"
            },
            {
                "name": "Pluduno",
                "countryCode": "FR"
            },
            {
                "name": "Pluguffan",
                "countryCode": "FR"
            },
            {
                "name": "Pluherlin",
                "countryCode": "FR"
            },
            {
                "name": "Plumaugat",
                "countryCode": "FR"
            },
            {
                "name": "Plumelec",
                "countryCode": "FR"
            },
            {
                "name": "Plumelin",
                "countryCode": "FR"
            },
            {
                "name": "Plumergat",
                "countryCode": "FR"
            },
            {
                "name": "Plumieux",
                "countryCode": "FR"
            },
            {
                "name": "Pluméliau",
                "countryCode": "FR"
            },
            {
                "name": "Pluneret",
                "countryCode": "FR"
            },
            {
                "name": "Plurien",
                "countryCode": "FR"
            },
            {
                "name": "Pluvigner",
                "countryCode": "FR"
            },
            {
                "name": "Pluzunet",
                "countryCode": "FR"
            },
            {
                "name": "Pléchâtel",
                "countryCode": "FR"
            },
            {
                "name": "Plédran",
                "countryCode": "FR"
            },
            {
                "name": "Plédéliac",
                "countryCode": "FR"
            },
            {
                "name": "Pléguien",
                "countryCode": "FR"
            },
            {
                "name": "Pléhédel",
                "countryCode": "FR"
            },
            {
                "name": "Plélan-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Plélan-le-Petit",
                "countryCode": "FR"
            },
            {
                "name": "Plélo",
                "countryCode": "FR"
            },
            {
                "name": "Plémet",
                "countryCode": "FR"
            },
            {
                "name": "Plémy",
                "countryCode": "FR"
            },
            {
                "name": "Pléneuf-Val-André",
                "countryCode": "FR"
            },
            {
                "name": "Plénée-Jugon",
                "countryCode": "FR"
            },
            {
                "name": "Plérin",
                "countryCode": "FR"
            },
            {
                "name": "Plœuc-sur-Lié",
                "countryCode": "FR"
            },
            {
                "name": "Pocé-les-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Pommeret",
                "countryCode": "FR"
            },
            {
                "name": "Pommerit-Jaudy",
                "countryCode": "FR"
            },
            {
                "name": "Pommerit-le-Vicomte",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Aven",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Croix",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Scorff",
                "countryCode": "FR"
            },
            {
                "name": "Pont-l’Abbé",
                "countryCode": "FR"
            },
            {
                "name": "Pontivy",
                "countryCode": "FR"
            },
            {
                "name": "Pontrieux",
                "countryCode": "FR"
            },
            {
                "name": "Pordic",
                "countryCode": "FR"
            },
            {
                "name": "Porspoder",
                "countryCode": "FR"
            },
            {
                "name": "Port-Louis",
                "countryCode": "FR"
            },
            {
                "name": "Pouldergat",
                "countryCode": "FR"
            },
            {
                "name": "Pouldreuzic",
                "countryCode": "FR"
            },
            {
                "name": "Poullan-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Poullaouen",
                "countryCode": "FR"
            },
            {
                "name": "Prat",
                "countryCode": "FR"
            },
            {
                "name": "Priziac",
                "countryCode": "FR"
            },
            {
                "name": "Péaule",
                "countryCode": "FR"
            },
            {
                "name": "Pédernec",
                "countryCode": "FR"
            },
            {
                "name": "Pénestin",
                "countryCode": "FR"
            },
            {
                "name": "Quemper-Guézennec",
                "countryCode": "FR"
            },
            {
                "name": "Querrien",
                "countryCode": "FR"
            },
            {
                "name": "Quessoy",
                "countryCode": "FR"
            },
            {
                "name": "Questembert",
                "countryCode": "FR"
            },
            {
                "name": "Quiberon",
                "countryCode": "FR"
            },
            {
                "name": "Quimper",
                "countryCode": "FR"
            },
            {
                "name": "Quimperlé",
                "countryCode": "FR"
            },
            {
                "name": "Quintin",
                "countryCode": "FR"
            },
            {
                "name": "Quistinic",
                "countryCode": "FR"
            },
            {
                "name": "Québriac",
                "countryCode": "FR"
            },
            {
                "name": "Quédillac",
                "countryCode": "FR"
            },
            {
                "name": "Quéménéven",
                "countryCode": "FR"
            },
            {
                "name": "Quéven",
                "countryCode": "FR"
            },
            {
                "name": "Quévert",
                "countryCode": "FR"
            },
            {
                "name": "Rannée",
                "countryCode": "FR"
            },
            {
                "name": "Redon",
                "countryCode": "FR"
            },
            {
                "name": "Rennes",
                "countryCode": "FR"
            },
            {
                "name": "Retiers",
                "countryCode": "FR"
            },
            {
                "name": "Riantec",
                "countryCode": "FR"
            },
            {
                "name": "Riec-sur-Belon",
                "countryCode": "FR"
            },
            {
                "name": "Rieux",
                "countryCode": "FR"
            },
            {
                "name": "Rohan",
                "countryCode": "FR"
            },
            {
                "name": "Romagné",
                "countryCode": "FR"
            },
            {
                "name": "Romillé",
                "countryCode": "FR"
            },
            {
                "name": "Roscanvel",
                "countryCode": "FR"
            },
            {
                "name": "Roscoff",
                "countryCode": "FR"
            },
            {
                "name": "Rospez",
                "countryCode": "FR"
            },
            {
                "name": "Rosporden",
                "countryCode": "FR"
            },
            {
                "name": "Rostrenen",
                "countryCode": "FR"
            },
            {
                "name": "Roz-Landrieux",
                "countryCode": "FR"
            },
            {
                "name": "Roz-sur-Couesnon",
                "countryCode": "FR"
            },
            {
                "name": "Ruffiac",
                "countryCode": "FR"
            },
            {
                "name": "Rédené",
                "countryCode": "FR"
            },
            {
                "name": "Réguiny",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Agathon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alban",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Armel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-du-Cormier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Avé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Barnabé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brandan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Briac-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brice-en-Coglès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brieuc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Broladre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Caradec",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Carreuc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cast-le-Guildo",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Coulomb",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Dolay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Domineuc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Donan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Erblon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Reintembault",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-en-Coglès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gildas-de-Rhuys",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gilles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gonnery",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Grégoire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hélen",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jacques-de-la-Lande",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jacut-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-Brévelay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-la-Poterie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-sur-Couesnon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jouan-des-Guérets",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lunaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Malo",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marc-le-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-des-Champs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-sur-Oust",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Médard-sur-Ille",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Méen-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Méloir-des-Ondes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-du-Pélem",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nolff",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen-des-Alleux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pabu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Perreux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Philibert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-Quiberon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-de-Plesguen",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pol-de-Léon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Père",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quay-Perros",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quay-Portrieux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Renan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Samson-sur-Rance",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Senoux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thonan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thurial",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thuriau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thégonnec",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Urbain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-sur-Oust",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yvi",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-en-Coglès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Évarzec",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Anne-d'Auray",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Hélène",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie",
                "countryCode": "FR"
            },
            {
                "name": "Santec",
                "countryCode": "FR"
            },
            {
                "name": "Sarzeau",
                "countryCode": "FR"
            },
            {
                "name": "Scaër",
                "countryCode": "FR"
            },
            {
                "name": "Sens-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Servon-sur-Vilaine",
                "countryCode": "FR"
            },
            {
                "name": "Sibiril",
                "countryCode": "FR"
            },
            {
                "name": "Sixt-sur-Aff",
                "countryCode": "FR"
            },
            {
                "name": "Sizun",
                "countryCode": "FR"
            },
            {
                "name": "Spézet",
                "countryCode": "FR"
            },
            {
                "name": "Sulniac",
                "countryCode": "FR"
            },
            {
                "name": "Surzur",
                "countryCode": "FR"
            },
            {
                "name": "Séné",
                "countryCode": "FR"
            },
            {
                "name": "Sérent",
                "countryCode": "FR"
            },
            {
                "name": "Sévignac",
                "countryCode": "FR"
            },
            {
                "name": "Taden",
                "countryCode": "FR"
            },
            {
                "name": "Talensac",
                "countryCode": "FR"
            },
            {
                "name": "Taulé",
                "countryCode": "FR"
            },
            {
                "name": "Taupont",
                "countryCode": "FR"
            },
            {
                "name": "Telgruc-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Theix",
                "countryCode": "FR"
            },
            {
                "name": "Tinténiac",
                "countryCode": "FR"
            },
            {
                "name": "Tonquédec",
                "countryCode": "FR"
            },
            {
                "name": "Torcé",
                "countryCode": "FR"
            },
            {
                "name": "Treffiagat",
                "countryCode": "FR"
            },
            {
                "name": "Treffléan",
                "countryCode": "FR"
            },
            {
                "name": "Tremblay",
                "countryCode": "FR"
            },
            {
                "name": "Tresbœuf",
                "countryCode": "FR"
            },
            {
                "name": "Trébeurden",
                "countryCode": "FR"
            },
            {
                "name": "Trédarzec",
                "countryCode": "FR"
            },
            {
                "name": "Trédrez-Locquémeau",
                "countryCode": "FR"
            },
            {
                "name": "Trégastel",
                "countryCode": "FR"
            },
            {
                "name": "Tréguier",
                "countryCode": "FR"
            },
            {
                "name": "Trégunc",
                "countryCode": "FR"
            },
            {
                "name": "Trélivan",
                "countryCode": "FR"
            },
            {
                "name": "Trélévern",
                "countryCode": "FR"
            },
            {
                "name": "Trémuson",
                "countryCode": "FR"
            },
            {
                "name": "Tréméven",
                "countryCode": "FR"
            },
            {
                "name": "Trévou-Tréguignec",
                "countryCode": "FR"
            },
            {
                "name": "Trévé",
                "countryCode": "FR"
            },
            {
                "name": "Vannes",
                "countryCode": "FR"
            },
            {
                "name": "Vern-sur-Seiche",
                "countryCode": "FR"
            },
            {
                "name": "Vezin-le-Coquet",
                "countryCode": "FR"
            },
            {
                "name": "Vignoc",
                "countryCode": "FR"
            },
            {
                "name": "Vitré",
                "countryCode": "FR"
            },
            {
                "name": "Yffiniac",
                "countryCode": "FR"
            },
            {
                "name": "Yvignac-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Étables-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Étel",
                "countryCode": "FR"
            },
            {
                "name": "Étrelles",
                "countryCode": "FR"
            },
            {
                "name": "Évran",
                "countryCode": "FR"
            },
            {
                "name": "Abilly",
                "countryCode": "FR"
            },
            {
                "name": "Abondant",
                "countryCode": "FR"
            },
            {
                "name": "Aigurande",
                "countryCode": "FR"
            },
            {
                "name": "Ambillou",
                "countryCode": "FR"
            },
            {
                "name": "Amboise",
                "countryCode": "FR"
            },
            {
                "name": "Amilly",
                "countryCode": "FR"
            },
            {
                "name": "Anet",
                "countryCode": "FR"
            },
            {
                "name": "Ardentes",
                "countryCode": "FR"
            },
            {
                "name": "Argent-sur-Sauldre",
                "countryCode": "FR"
            },
            {
                "name": "Argenton-sur-Creuse",
                "countryCode": "FR"
            },
            {
                "name": "Armenonville-les-Gâtineaux",
                "countryCode": "FR"
            },
            {
                "name": "Arrou",
                "countryCode": "FR"
            },
            {
                "name": "Artannes-sur-Indre",
                "countryCode": "FR"
            },
            {
                "name": "Artenay",
                "countryCode": "FR"
            },
            {
                "name": "Arthon",
                "countryCode": "FR"
            },
            {
                "name": "Aschères-le-Marché",
                "countryCode": "FR"
            },
            {
                "name": "Athée-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Aubigny-sur-Nère",
                "countryCode": "FR"
            },
            {
                "name": "Aunay-sous-Auneau",
                "countryCode": "FR"
            },
            {
                "name": "Auneau",
                "countryCode": "FR"
            },
            {
                "name": "Authon-du-Perche",
                "countryCode": "FR"
            },
            {
                "name": "Autry-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Auzouer-en-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Avoine",
                "countryCode": "FR"
            },
            {
                "name": "Avord",
                "countryCode": "FR"
            },
            {
                "name": "Azay-le-Ferron",
                "countryCode": "FR"
            },
            {
                "name": "Azay-le-Rideau",
                "countryCode": "FR"
            },
            {
                "name": "Azay-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Azé",
                "countryCode": "FR"
            },
            {
                "name": "Bailleau-le-Pin",
                "countryCode": "FR"
            },
            {
                "name": "Bailleau-l’Évêque",
                "countryCode": "FR"
            },
            {
                "name": "Ballan-Miré",
                "countryCode": "FR"
            },
            {
                "name": "Barjouville",
                "countryCode": "FR"
            },
            {
                "name": "Baugy",
                "countryCode": "FR"
            },
            {
                "name": "Baule",
                "countryCode": "FR"
            },
            {
                "name": "Bazoches-les-Gallerandes",
                "countryCode": "FR"
            },
            {
                "name": "Beaugency",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu-lès-Loches",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-en-Véron",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-la-Ronce",
                "countryCode": "FR"
            },
            {
                "name": "Beaune-la-Rolande",
                "countryCode": "FR"
            },
            {
                "name": "Bellegarde",
                "countryCode": "FR"
            },
            {
                "name": "Belleville-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Blancafort",
                "countryCode": "FR"
            },
            {
                "name": "Blois",
                "countryCode": "FR"
            },
            {
                "name": "Bléré",
                "countryCode": "FR"
            },
            {
                "name": "Boigny-sur-Bionne",
                "countryCode": "FR"
            },
            {
                "name": "Boiscommun",
                "countryCode": "FR"
            },
            {
                "name": "Bonneval",
                "countryCode": "FR"
            },
            {
                "name": "Bonny-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Boulleret",
                "countryCode": "FR"
            },
            {
                "name": "Bourges",
                "countryCode": "FR"
            },
            {
                "name": "Bourgueil",
                "countryCode": "FR"
            },
            {
                "name": "Bouzy-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Boynes",
                "countryCode": "FR"
            },
            {
                "name": "Bracieux",
                "countryCode": "FR"
            },
            {
                "name": "Bray-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Brezolles",
                "countryCode": "FR"
            },
            {
                "name": "Briare",
                "countryCode": "FR"
            },
            {
                "name": "Bricy",
                "countryCode": "FR"
            },
            {
                "name": "Brinon-sur-Sauldre",
                "countryCode": "FR"
            },
            {
                "name": "Brou",
                "countryCode": "FR"
            },
            {
                "name": "Buzançais",
                "countryCode": "FR"
            },
            {
                "name": "Bélâbre",
                "countryCode": "FR"
            },
            {
                "name": "Béville-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Bû",
                "countryCode": "FR"
            },
            {
                "name": "Candé-sur-Beuvron",
                "countryCode": "FR"
            },
            {
                "name": "Cangey",
                "countryCode": "FR"
            },
            {
                "name": "Cellettes",
                "countryCode": "FR"
            },
            {
                "name": "Cepoy",
                "countryCode": "FR"
            },
            {
                "name": "Cerdon",
                "countryCode": "FR"
            },
            {
                "name": "Cerelles",
                "countryCode": "FR"
            },
            {
                "name": "Chabris",
                "countryCode": "FR"
            },
            {
                "name": "Chaillac",
                "countryCode": "FR"
            },
            {
                "name": "Chailles",
                "countryCode": "FR"
            },
            {
                "name": "Chaingy",
                "countryCode": "FR"
            },
            {
                "name": "Chambourg-sur-Indre",
                "countryCode": "FR"
            },
            {
                "name": "Chambray-lès-Tours",
                "countryCode": "FR"
            },
            {
                "name": "Champhol",
                "countryCode": "FR"
            },
            {
                "name": "Chanceaux-sur-Choisille",
                "countryCode": "FR"
            },
            {
                "name": "Changy-les-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Chanteau",
                "countryCode": "FR"
            },
            {
                "name": "Chantôme",
                "countryCode": "FR"
            },
            {
                "name": "Charentilly",
                "countryCode": "FR"
            },
            {
                "name": "Charenton-du-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Chargé",
                "countryCode": "FR"
            },
            {
                "name": "Chartres",
                "countryCode": "FR"
            },
            {
                "name": "Chaudon",
                "countryCode": "FR"
            },
            {
                "name": "Chaumont-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Chaumont-sur-Tharonne",
                "countryCode": "FR"
            },
            {
                "name": "Cheillé",
                "countryCode": "FR"
            },
            {
                "name": "Cher",
                "countryCode": "FR"
            },
            {
                "name": "Cherisy",
                "countryCode": "FR"
            },
            {
                "name": "Cheverny",
                "countryCode": "FR"
            },
            {
                "name": "Chevillon-sur-Huillard",
                "countryCode": "FR"
            },
            {
                "name": "Chevilly",
                "countryCode": "FR"
            },
            {
                "name": "Chilleurs-aux-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Chinon",
                "countryCode": "FR"
            },
            {
                "name": "Chissay-en-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Chitenay",
                "countryCode": "FR"
            },
            {
                "name": "Chouzy-sur-Cisse",
                "countryCode": "FR"
            },
            {
                "name": "Chouzé-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Châlette-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Chârost",
                "countryCode": "FR"
            },
            {
                "name": "Château-Renard",
                "countryCode": "FR"
            },
            {
                "name": "Château-Renault",
                "countryCode": "FR"
            },
            {
                "name": "Château-la-Vallière",
                "countryCode": "FR"
            },
            {
                "name": "Châteaudun",
                "countryCode": "FR"
            },
            {
                "name": "Châteaumeillant",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-en-Thymerais",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Châteauroux",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-Coligny",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Indre",
                "countryCode": "FR"
            },
            {
                "name": "Châtres-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Chécy",
                "countryCode": "FR"
            },
            {
                "name": "Cinq-Mars-la-Pile",
                "countryCode": "FR"
            },
            {
                "name": "Civray-de-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Clion",
                "countryCode": "FR"
            },
            {
                "name": "Cloyes-sur-le-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Cluis",
                "countryCode": "FR"
            },
            {
                "name": "Cléry-Saint-André",
                "countryCode": "FR"
            },
            {
                "name": "Cléré-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Contres",
                "countryCode": "FR"
            },
            {
                "name": "Corbeilles",
                "countryCode": "FR"
            },
            {
                "name": "Cormeray",
                "countryCode": "FR"
            },
            {
                "name": "Cormery",
                "countryCode": "FR"
            },
            {
                "name": "Corquilleroy",
                "countryCode": "FR"
            },
            {
                "name": "Coullons",
                "countryCode": "FR"
            },
            {
                "name": "Coulombs",
                "countryCode": "FR"
            },
            {
                "name": "Cour-Cheverny",
                "countryCode": "FR"
            },
            {
                "name": "Cours-les-Barres",
                "countryCode": "FR"
            },
            {
                "name": "Courtenay",
                "countryCode": "FR"
            },
            {
                "name": "Courville-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Cuffy",
                "countryCode": "FR"
            },
            {
                "name": "Dadonville",
                "countryCode": "FR"
            },
            {
                "name": "Dammarie",
                "countryCode": "FR"
            },
            {
                "name": "Dampierre-en-Burly",
                "countryCode": "FR"
            },
            {
                "name": "Darvoy",
                "countryCode": "FR"
            },
            {
                "name": "Descartes",
                "countryCode": "FR"
            },
            {
                "name": "Dhuizon",
                "countryCode": "FR"
            },
            {
                "name": "Donnery",
                "countryCode": "FR"
            },
            {
                "name": "Dordives",
                "countryCode": "FR"
            },
            {
                "name": "Douchy",
                "countryCode": "FR"
            },
            {
                "name": "Dreux",
                "countryCode": "FR"
            },
            {
                "name": "Droue-sur-Drouette",
                "countryCode": "FR"
            },
            {
                "name": "Droué",
                "countryCode": "FR"
            },
            {
                "name": "Dry",
                "countryCode": "FR"
            },
            {
                "name": "Dun-sur-Auron",
                "countryCode": "FR"
            },
            {
                "name": "Déols",
                "countryCode": "FR"
            },
            {
                "name": "Département d'Eure-et-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Département d'Indre-et-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Indre",
                "countryCode": "FR"
            },
            {
                "name": "Département du Loir-et-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Esvres",
                "countryCode": "FR"
            },
            {
                "name": "Faverolles-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Fay-aux-Loges",
                "countryCode": "FR"
            },
            {
                "name": "Fleury-les-Aubrais",
                "countryCode": "FR"
            },
            {
                "name": "Fondettes",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-la-Guyon",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Foëcy",
                "countryCode": "FR"
            },
            {
                "name": "Fussy",
                "countryCode": "FR"
            },
            {
                "name": "Férolles",
                "countryCode": "FR"
            },
            {
                "name": "Gallardon",
                "countryCode": "FR"
            },
            {
                "name": "Garnay",
                "countryCode": "FR"
            },
            {
                "name": "Gasville-Oisème",
                "countryCode": "FR"
            },
            {
                "name": "Genillé",
                "countryCode": "FR"
            },
            {
                "name": "Gidy",
                "countryCode": "FR"
            },
            {
                "name": "Gien",
                "countryCode": "FR"
            },
            {
                "name": "Gièvres",
                "countryCode": "FR"
            },
            {
                "name": "Graçay",
                "countryCode": "FR"
            },
            {
                "name": "Hanches",
                "countryCode": "FR"
            },
            {
                "name": "Henrichemont",
                "countryCode": "FR"
            },
            {
                "name": "Herbault",
                "countryCode": "FR"
            },
            {
                "name": "Herry",
                "countryCode": "FR"
            },
            {
                "name": "Huismes",
                "countryCode": "FR"
            },
            {
                "name": "Huisseau-sur-Cosson",
                "countryCode": "FR"
            },
            {
                "name": "Huisseau-sur-Mauves",
                "countryCode": "FR"
            },
            {
                "name": "Ingré",
                "countryCode": "FR"
            },
            {
                "name": "Issoudun",
                "countryCode": "FR"
            },
            {
                "name": "Janville",
                "countryCode": "FR"
            },
            {
                "name": "Jargeau",
                "countryCode": "FR"
            },
            {
                "name": "Jouet-sur-l'Aubois",
                "countryCode": "FR"
            },
            {
                "name": "Jouy",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-le-Potier",
                "countryCode": "FR"
            },
            {
                "name": "Joué-lès-Tours",
                "countryCode": "FR"
            },
            {
                "name": "L'Île-Bouchard",
                "countryCode": "FR"
            },
            {
                "name": "La Bazoche-Gouet",
                "countryCode": "FR"
            },
            {
                "name": "La Celle-Saint-Avant",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Mesmin",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Ursin",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-du-Noyer",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "La Chaussée-Saint-Victor",
                "countryCode": "FR"
            },
            {
                "name": "La Châtre",
                "countryCode": "FR"
            },
            {
                "name": "La Croix-en-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Imbault",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Saint-Aubin",
                "countryCode": "FR"
            },
            {
                "name": "La Loupe",
                "countryCode": "FR"
            },
            {
                "name": "La Membrolle-sur-Choisille",
                "countryCode": "FR"
            },
            {
                "name": "La Riche",
                "countryCode": "FR"
            },
            {
                "name": "La Ville-aux-Clercs",
                "countryCode": "FR"
            },
            {
                "name": "La Ville-aux-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Ladon",
                "countryCode": "FR"
            },
            {
                "name": "Lailly-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Lamotte-Beuvron",
                "countryCode": "FR"
            },
            {
                "name": "Langeais",
                "countryCode": "FR"
            },
            {
                "name": "Larçay",
                "countryCode": "FR"
            },
            {
                "name": "Le Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Le Châtelet",
                "countryCode": "FR"
            },
            {
                "name": "Le Coudray",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Pressigny",
                "countryCode": "FR"
            },
            {
                "name": "Le Poinçonnet",
                "countryCode": "FR"
            },
            {
                "name": "Le Pêchereau",
                "countryCode": "FR"
            },
            {
                "name": "Les Aix-d’Angillon",
                "countryCode": "FR"
            },
            {
                "name": "Les Montils",
                "countryCode": "FR"
            },
            {
                "name": "Levet",
                "countryCode": "FR"
            },
            {
                "name": "Levroux",
                "countryCode": "FR"
            },
            {
                "name": "Lignières",
                "countryCode": "FR"
            },
            {
                "name": "Ligny-le-Ribault",
                "countryCode": "FR"
            },
            {
                "name": "Ligré",
                "countryCode": "FR"
            },
            {
                "name": "Ligueil",
                "countryCode": "FR"
            },
            {
                "name": "Loches",
                "countryCode": "FR"
            },
            {
                "name": "Loiret",
                "countryCode": "FR"
            },
            {
                "name": "Lorris",
                "countryCode": "FR"
            },
            {
                "name": "Loury",
                "countryCode": "FR"
            },
            {
                "name": "Luant",
                "countryCode": "FR"
            },
            {
                "name": "Lucé",
                "countryCode": "FR"
            },
            {
                "name": "Luisant",
                "countryCode": "FR"
            },
            {
                "name": "Lunay",
                "countryCode": "FR"
            },
            {
                "name": "Lunery",
                "countryCode": "FR"
            },
            {
                "name": "Luray",
                "countryCode": "FR"
            },
            {
                "name": "Luynes",
                "countryCode": "FR"
            },
            {
                "name": "Luçay-le-Mâle",
                "countryCode": "FR"
            },
            {
                "name": "Lèves",
                "countryCode": "FR"
            },
            {
                "name": "Léré",
                "countryCode": "FR"
            },
            {
                "name": "Maintenon",
                "countryCode": "FR"
            },
            {
                "name": "Mainvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Malesherbes",
                "countryCode": "FR"
            },
            {
                "name": "Manthelan",
                "countryCode": "FR"
            },
            {
                "name": "Marboué",
                "countryCode": "FR"
            },
            {
                "name": "Marcilly-en-Villette",
                "countryCode": "FR"
            },
            {
                "name": "Mardié",
                "countryCode": "FR"
            },
            {
                "name": "Mareau-aux-Prés",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Margon",
                "countryCode": "FR"
            },
            {
                "name": "Marigny-les-Usages",
                "countryCode": "FR"
            },
            {
                "name": "Marmagne",
                "countryCode": "FR"
            },
            {
                "name": "Martizay",
                "countryCode": "FR"
            },
            {
                "name": "Massay",
                "countryCode": "FR"
            },
            {
                "name": "Mazières-de-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Mehun-sur-Yèvre",
                "countryCode": "FR"
            },
            {
                "name": "Menetou-Salon",
                "countryCode": "FR"
            },
            {
                "name": "Mer",
                "countryCode": "FR"
            },
            {
                "name": "Mettray",
                "countryCode": "FR"
            },
            {
                "name": "Meung-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Mondoubleau",
                "countryCode": "FR"
            },
            {
                "name": "Monnaie",
                "countryCode": "FR"
            },
            {
                "name": "Mont-près-Chambord",
                "countryCode": "FR"
            },
            {
                "name": "Montargis",
                "countryCode": "FR"
            },
            {
                "name": "Montbazon",
                "countryCode": "FR"
            },
            {
                "name": "Montcresson",
                "countryCode": "FR"
            },
            {
                "name": "Montgivray",
                "countryCode": "FR"
            },
            {
                "name": "Montierchaume",
                "countryCode": "FR"
            },
            {
                "name": "Montlivault",
                "countryCode": "FR"
            },
            {
                "name": "Montlouis-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Montoire-sur-le-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Montrichard",
                "countryCode": "FR"
            },
            {
                "name": "Monts",
                "countryCode": "FR"
            },
            {
                "name": "Morancez",
                "countryCode": "FR"
            },
            {
                "name": "Morée",
                "countryCode": "FR"
            },
            {
                "name": "Muides-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Mur-de-Sologne",
                "countryCode": "FR"
            },
            {
                "name": "Ménestreau-en-Villette",
                "countryCode": "FR"
            },
            {
                "name": "Méreau",
                "countryCode": "FR"
            },
            {
                "name": "Mézières-en-Brenne",
                "countryCode": "FR"
            },
            {
                "name": "Mézières-en-Drouais",
                "countryCode": "FR"
            },
            {
                "name": "Nargis",
                "countryCode": "FR"
            },
            {
                "name": "Naveil",
                "countryCode": "FR"
            },
            {
                "name": "Nazelles-Négron",
                "countryCode": "FR"
            },
            {
                "name": "Neuillé-Pont-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Neung-sur-Beuvron",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-aux-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-Pailloux",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-Saint-Sépulchre",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Neuvy-sur-Barangeon",
                "countryCode": "FR"
            },
            {
                "name": "Nevoy",
                "countryCode": "FR"
            },
            {
                "name": "Niherne",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-le-Phaye",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-le-Rotrou",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-sur-Vernisson",
                "countryCode": "FR"
            },
            {
                "name": "Noizay",
                "countryCode": "FR"
            },
            {
                "name": "Nouan-le-Fuzelier",
                "countryCode": "FR"
            },
            {
                "name": "Nouzilly",
                "countryCode": "FR"
            },
            {
                "name": "Noyers-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Nérondes",
                "countryCode": "FR"
            },
            {
                "name": "Olivet",
                "countryCode": "FR"
            },
            {
                "name": "Onzain",
                "countryCode": "FR"
            },
            {
                "name": "Orgères-en-Beauce",
                "countryCode": "FR"
            },
            {
                "name": "Orléans",
                "countryCode": "FR"
            },
            {
                "name": "Ormes",
                "countryCode": "FR"
            },
            {
                "name": "Orval",
                "countryCode": "FR"
            },
            {
                "name": "Oucques",
                "countryCode": "FR"
            },
            {
                "name": "Oulins",
                "countryCode": "FR"
            },
            {
                "name": "Outarville",
                "countryCode": "FR"
            },
            {
                "name": "Ouzouer-le-Marché",
                "countryCode": "FR"
            },
            {
                "name": "Ouzouer-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Ouzouer-sur-Trézée",
                "countryCode": "FR"
            },
            {
                "name": "Pannes",
                "countryCode": "FR"
            },
            {
                "name": "Parçay-Meslay",
                "countryCode": "FR"
            },
            {
                "name": "Patay",
                "countryCode": "FR"
            },
            {
                "name": "Perrusson",
                "countryCode": "FR"
            },
            {
                "name": "Pierres",
                "countryCode": "FR"
            },
            {
                "name": "Pithiviers",
                "countryCode": "FR"
            },
            {
                "name": "Pithiviers-le-Vieil",
                "countryCode": "FR"
            },
            {
                "name": "Plaimpied-Givaudins",
                "countryCode": "FR"
            },
            {
                "name": "Pocé-sur-Cisse",
                "countryCode": "FR"
            },
            {
                "name": "Poilly-lez-Gien",
                "countryCode": "FR"
            },
            {
                "name": "Pontlevoy",
                "countryCode": "FR"
            },
            {
                "name": "Pouligny-Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Preuilly-sur-Claise",
                "countryCode": "FR"
            },
            {
                "name": "Pruniers-en-Sologne",
                "countryCode": "FR"
            },
            {
                "name": "Puiseaux",
                "countryCode": "FR"
            },
            {
                "name": "Quiers-sur-Bézonde",
                "countryCode": "FR"
            },
            {
                "name": "Rebréchien",
                "countryCode": "FR"
            },
            {
                "name": "Reignac-sur-Indre",
                "countryCode": "FR"
            },
            {
                "name": "Restigné",
                "countryCode": "FR"
            },
            {
                "name": "Reugny",
                "countryCode": "FR"
            },
            {
                "name": "Reuilly",
                "countryCode": "FR"
            },
            {
                "name": "Rians",
                "countryCode": "FR"
            },
            {
                "name": "Richelieu",
                "countryCode": "FR"
            },
            {
                "name": "Rochecorbon",
                "countryCode": "FR"
            },
            {
                "name": "Romorantin-Lanthenay",
                "countryCode": "FR"
            },
            {
                "name": "Rouziers-de-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Saché",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aignan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand-Longpré",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand-Montrond",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Antoine-du-Rocher",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Avertin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Benoît-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Branchs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brisson-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christophe-sur-le-Nais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Claude-de-Diray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-les-Ponts",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Doulchard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Dyé-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florent-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gaultier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-des-Prés",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-du-Puy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-Saint-Mesmin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Braye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-la-Ruelle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-le-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-Nouan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lubin-des-Joncherets",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Nigelles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-d’Auxigny",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-le-Beau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-sur-Ocre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-sur-Fessard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-de-Bourgueil",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paterne-Racan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Piat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-des-Corps",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Prest",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pryvé-Saint-Mesmin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Père-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-sur-Avre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Satur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-de-Pommeray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Viâtre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Éloy-de-Gy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Épain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Chigny",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Geneviève-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Lizaigne",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Maure-de-Touraine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Solange",
                "countryCode": "FR"
            },
            {
                "name": "Salbris",
                "countryCode": "FR"
            },
            {
                "name": "Sancerre",
                "countryCode": "FR"
            },
            {
                "name": "Sancoins",
                "countryCode": "FR"
            },
            {
                "name": "Sandillon",
                "countryCode": "FR"
            },
            {
                "name": "Saran",
                "countryCode": "FR"
            },
            {
                "name": "Sargé-sur-Braye",
                "countryCode": "FR"
            },
            {
                "name": "Saussay",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-en-Sancerre",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-en-Véron",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-sur-Braye",
                "countryCode": "FR"
            },
            {
                "name": "Savigné-sur-Lathan",
                "countryCode": "FR"
            },
            {
                "name": "Savonnières",
                "countryCode": "FR"
            },
            {
                "name": "Seigy",
                "countryCode": "FR"
            },
            {
                "name": "Selles-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Selles-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Semblançay",
                "countryCode": "FR"
            },
            {
                "name": "Semoy",
                "countryCode": "FR"
            },
            {
                "name": "Senonches",
                "countryCode": "FR"
            },
            {
                "name": "Sermaises",
                "countryCode": "FR"
            },
            {
                "name": "Soings-en-Sologne",
                "countryCode": "FR"
            },
            {
                "name": "Sonzay",
                "countryCode": "FR"
            },
            {
                "name": "Sorel-Moussel",
                "countryCode": "FR"
            },
            {
                "name": "Sorigny",
                "countryCode": "FR"
            },
            {
                "name": "Souesmes",
                "countryCode": "FR"
            },
            {
                "name": "Sours",
                "countryCode": "FR"
            },
            {
                "name": "Sully-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Suèvres",
                "countryCode": "FR"
            },
            {
                "name": "Tauxigny",
                "countryCode": "FR"
            },
            {
                "name": "Tavers",
                "countryCode": "FR"
            },
            {
                "name": "Theillay",
                "countryCode": "FR"
            },
            {
                "name": "Thilouze",
                "countryCode": "FR"
            },
            {
                "name": "Thiron Gardais",
                "countryCode": "FR"
            },
            {
                "name": "Thésée",
                "countryCode": "FR"
            },
            {
                "name": "Tigy",
                "countryCode": "FR"
            },
            {
                "name": "Tournon-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Tours",
                "countryCode": "FR"
            },
            {
                "name": "Toury",
                "countryCode": "FR"
            },
            {
                "name": "Traînou",
                "countryCode": "FR"
            },
            {
                "name": "Triguères",
                "countryCode": "FR"
            },
            {
                "name": "Trouy",
                "countryCode": "FR"
            },
            {
                "name": "Truyes",
                "countryCode": "FR"
            },
            {
                "name": "Tréon",
                "countryCode": "FR"
            },
            {
                "name": "Unverre",
                "countryCode": "FR"
            },
            {
                "name": "Valençay",
                "countryCode": "FR"
            },
            {
                "name": "Vasselay",
                "countryCode": "FR"
            },
            {
                "name": "Vatan",
                "countryCode": "FR"
            },
            {
                "name": "Veigné",
                "countryCode": "FR"
            },
            {
                "name": "Vendôme",
                "countryCode": "FR"
            },
            {
                "name": "Vendœuvres",
                "countryCode": "FR"
            },
            {
                "name": "Vennecy",
                "countryCode": "FR"
            },
            {
                "name": "Vernou-sur-Brenne",
                "countryCode": "FR"
            },
            {
                "name": "Vernouillet",
                "countryCode": "FR"
            },
            {
                "name": "Vert-en-Drouais",
                "countryCode": "FR"
            },
            {
                "name": "Vienne-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Vierzon",
                "countryCode": "FR"
            },
            {
                "name": "Vignoux-sur-Barangeon",
                "countryCode": "FR"
            },
            {
                "name": "Villandry",
                "countryCode": "FR"
            },
            {
                "name": "Villebarou",
                "countryCode": "FR"
            },
            {
                "name": "Villedieu-sur-Indre",
                "countryCode": "FR"
            },
            {
                "name": "Villedômer",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-sur-Cher",
                "countryCode": "FR"
            },
            {
                "name": "Villemandeur",
                "countryCode": "FR"
            },
            {
                "name": "Villemeux-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-le-Morhier",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-sur-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Vimory",
                "countryCode": "FR"
            },
            {
                "name": "Vineuil",
                "countryCode": "FR"
            },
            {
                "name": "Vitry-aux-Loges",
                "countryCode": "FR"
            },
            {
                "name": "Vouvray",
                "countryCode": "FR"
            },
            {
                "name": "Vouzon",
                "countryCode": "FR"
            },
            {
                "name": "Voves",
                "countryCode": "FR"
            },
            {
                "name": "Véretz",
                "countryCode": "FR"
            },
            {
                "name": "Yzeures-sur-Creuse",
                "countryCode": "FR"
            },
            {
                "name": "Yèvres",
                "countryCode": "FR"
            },
            {
                "name": "Écueillé",
                "countryCode": "FR"
            },
            {
                "name": "Éguzon-Chantôme",
                "countryCode": "FR"
            },
            {
                "name": "Épernon",
                "countryCode": "FR"
            },
            {
                "name": "Épieds-en-Beauce",
                "countryCode": "FR"
            },
            {
                "name": "Abreschviller",
                "countryCode": "FR"
            },
            {
                "name": "Achenheim",
                "countryCode": "FR"
            },
            {
                "name": "Aiglemont",
                "countryCode": "FR"
            },
            {
                "name": "Aix-en-Othe",
                "countryCode": "FR"
            },
            {
                "name": "Algolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Algrange",
                "countryCode": "FR"
            },
            {
                "name": "Alsting",
                "countryCode": "FR"
            },
            {
                "name": "Altkirch",
                "countryCode": "FR"
            },
            {
                "name": "Altorf",
                "countryCode": "FR"
            },
            {
                "name": "Amanvillers",
                "countryCode": "FR"
            },
            {
                "name": "Ammerschwihr",
                "countryCode": "FR"
            },
            {
                "name": "Amnéville",
                "countryCode": "FR"
            },
            {
                "name": "Ancerville",
                "countryCode": "FR"
            },
            {
                "name": "Ancy-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Andlau",
                "countryCode": "FR"
            },
            {
                "name": "Andolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Angevillers",
                "countryCode": "FR"
            },
            {
                "name": "Anould",
                "countryCode": "FR"
            },
            {
                "name": "Arches",
                "countryCode": "FR"
            },
            {
                "name": "Archettes",
                "countryCode": "FR"
            },
            {
                "name": "Arcis-sur-Aube",
                "countryCode": "FR"
            },
            {
                "name": "Ardennes",
                "countryCode": "FR"
            },
            {
                "name": "Argancy",
                "countryCode": "FR"
            },
            {
                "name": "Ars-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Art-sur-Meurthe",
                "countryCode": "FR"
            },
            {
                "name": "Aspach",
                "countryCode": "FR"
            },
            {
                "name": "Aspach-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Aspach-le-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Attigny",
                "countryCode": "FR"
            },
            {
                "name": "Auboué",
                "countryCode": "FR"
            },
            {
                "name": "Audun-le-Roman",
                "countryCode": "FR"
            },
            {
                "name": "Audun-le-Tiche",
                "countryCode": "FR"
            },
            {
                "name": "Augny",
                "countryCode": "FR"
            },
            {
                "name": "Aumetz",
                "countryCode": "FR"
            },
            {
                "name": "Avize",
                "countryCode": "FR"
            },
            {
                "name": "Ay",
                "countryCode": "FR"
            },
            {
                "name": "Ay-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Aydoilles",
                "countryCode": "FR"
            },
            {
                "name": "Baccarat",
                "countryCode": "FR"
            },
            {
                "name": "Badonviller",
                "countryCode": "FR"
            },
            {
                "name": "Bains-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Bainville-sur-Madon",
                "countryCode": "FR"
            },
            {
                "name": "Balan",
                "countryCode": "FR"
            },
            {
                "name": "Baldersheim",
                "countryCode": "FR"
            },
            {
                "name": "Ban-de-Laveline",
                "countryCode": "FR"
            },
            {
                "name": "Bantzenheim",
                "countryCode": "FR"
            },
            {
                "name": "Bar-le-Duc",
                "countryCode": "FR"
            },
            {
                "name": "Bar-sur-Aube",
                "countryCode": "FR"
            },
            {
                "name": "Bar-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Barr",
                "countryCode": "FR"
            },
            {
                "name": "Bartenheim",
                "countryCode": "FR"
            },
            {
                "name": "Bas-Rhin",
                "countryCode": "FR"
            },
            {
                "name": "Basse-Ham",
                "countryCode": "FR"
            },
            {
                "name": "Batilly",
                "countryCode": "FR"
            },
            {
                "name": "Battenheim",
                "countryCode": "FR"
            },
            {
                "name": "Bayard-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Bayon",
                "countryCode": "FR"
            },
            {
                "name": "Bazancourt",
                "countryCode": "FR"
            },
            {
                "name": "Bazeilles",
                "countryCode": "FR"
            },
            {
                "name": "Behren-lès-Forbach",
                "countryCode": "FR"
            },
            {
                "name": "Belleville",
                "countryCode": "FR"
            },
            {
                "name": "Belleville-sur-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Benfeld",
                "countryCode": "FR"
            },
            {
                "name": "Bennwihr",
                "countryCode": "FR"
            },
            {
                "name": "Bergheim",
                "countryCode": "FR"
            },
            {
                "name": "Bergholtz",
                "countryCode": "FR"
            },
            {
                "name": "Bernardswiller",
                "countryCode": "FR"
            },
            {
                "name": "Berrwiller",
                "countryCode": "FR"
            },
            {
                "name": "Berstett",
                "countryCode": "FR"
            },
            {
                "name": "Bertrange",
                "countryCode": "FR"
            },
            {
                "name": "Betschdorf",
                "countryCode": "FR"
            },
            {
                "name": "Bettancourt-la-Ferrée",
                "countryCode": "FR"
            },
            {
                "name": "Bezannes",
                "countryCode": "FR"
            },
            {
                "name": "Bienville",
                "countryCode": "FR"
            },
            {
                "name": "Biesheim",
                "countryCode": "FR"
            },
            {
                "name": "Biesles",
                "countryCode": "FR"
            },
            {
                "name": "Bining",
                "countryCode": "FR"
            },
            {
                "name": "Bischheim",
                "countryCode": "FR"
            },
            {
                "name": "Bischoffsheim",
                "countryCode": "FR"
            },
            {
                "name": "Bischwiller",
                "countryCode": "FR"
            },
            {
                "name": "Bitche",
                "countryCode": "FR"
            },
            {
                "name": "Bitschwiller-lès-Thann",
                "countryCode": "FR"
            },
            {
                "name": "Blaesheim",
                "countryCode": "FR"
            },
            {
                "name": "Blagny",
                "countryCode": "FR"
            },
            {
                "name": "Bliesbruck",
                "countryCode": "FR"
            },
            {
                "name": "Blodelsheim",
                "countryCode": "FR"
            },
            {
                "name": "Blotzheim",
                "countryCode": "FR"
            },
            {
                "name": "Blénod-lès-Pont-à-Mousson",
                "countryCode": "FR"
            },
            {
                "name": "Blénod-lès-Toul",
                "countryCode": "FR"
            },
            {
                "name": "Bollwiller",
                "countryCode": "FR"
            },
            {
                "name": "Bologne",
                "countryCode": "FR"
            },
            {
                "name": "Boofzheim",
                "countryCode": "FR"
            },
            {
                "name": "Bouilly",
                "countryCode": "FR"
            },
            {
                "name": "Boulange",
                "countryCode": "FR"
            },
            {
                "name": "Boulay-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Bouligny",
                "countryCode": "FR"
            },
            {
                "name": "Boult-sur-Suippe",
                "countryCode": "FR"
            },
            {
                "name": "Bourbonne-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Bourgogne",
                "countryCode": "FR"
            },
            {
                "name": "Bousse",
                "countryCode": "FR"
            },
            {
                "name": "Bouxières-aux-Chênes",
                "countryCode": "FR"
            },
            {
                "name": "Bouxières-aux-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Bouxwiller",
                "countryCode": "FR"
            },
            {
                "name": "Bouzonville",
                "countryCode": "FR"
            },
            {
                "name": "Breuschwickersheim",
                "countryCode": "FR"
            },
            {
                "name": "Brienne-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Briey",
                "countryCode": "FR"
            },
            {
                "name": "Brumath",
                "countryCode": "FR"
            },
            {
                "name": "Brunstatt",
                "countryCode": "FR"
            },
            {
                "name": "Bruyères",
                "countryCode": "FR"
            },
            {
                "name": "Bréviandes",
                "countryCode": "FR"
            },
            {
                "name": "Buchères",
                "countryCode": "FR"
            },
            {
                "name": "Buhl",
                "countryCode": "FR"
            },
            {
                "name": "Bulgnéville",
                "countryCode": "FR"
            },
            {
                "name": "Burnhaupt-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Burnhaupt-le-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Bussang",
                "countryCode": "FR"
            },
            {
                "name": "Béning-lès-Saint-Avold",
                "countryCode": "FR"
            },
            {
                "name": "Bétheny",
                "countryCode": "FR"
            },
            {
                "name": "Bœrsch",
                "countryCode": "FR"
            },
            {
                "name": "Carignan",
                "countryCode": "FR"
            },
            {
                "name": "Carling",
                "countryCode": "FR"
            },
            {
                "name": "Carspach",
                "countryCode": "FR"
            },
            {
                "name": "Cattenom",
                "countryCode": "FR"
            },
            {
                "name": "Cernay",
                "countryCode": "FR"
            },
            {
                "name": "Cernay-lès-Reims",
                "countryCode": "FR"
            },
            {
                "name": "Chalampé",
                "countryCode": "FR"
            },
            {
                "name": "Chaligny",
                "countryCode": "FR"
            },
            {
                "name": "Chalindrey",
                "countryCode": "FR"
            },
            {
                "name": "Chamarandes-Choignes",
                "countryCode": "FR"
            },
            {
                "name": "Champenoux",
                "countryCode": "FR"
            },
            {
                "name": "Champigneulles",
                "countryCode": "FR"
            },
            {
                "name": "Chancenay",
                "countryCode": "FR"
            },
            {
                "name": "Chanteheux",
                "countryCode": "FR"
            },
            {
                "name": "Chantraine",
                "countryCode": "FR"
            },
            {
                "name": "Chaource",
                "countryCode": "FR"
            },
            {
                "name": "Charleville-Mézières",
                "countryCode": "FR"
            },
            {
                "name": "Charmes",
                "countryCode": "FR"
            },
            {
                "name": "Chaumont",
                "countryCode": "FR"
            },
            {
                "name": "Chavelot",
                "countryCode": "FR"
            },
            {
                "name": "Chavigny",
                "countryCode": "FR"
            },
            {
                "name": "Cheniménil",
                "countryCode": "FR"
            },
            {
                "name": "Chevillon",
                "countryCode": "FR"
            },
            {
                "name": "Châlons-en-Champagne",
                "countryCode": "FR"
            },
            {
                "name": "Château-Porcien",
                "countryCode": "FR"
            },
            {
                "name": "Château-Salins",
                "countryCode": "FR"
            },
            {
                "name": "Châteauvillain",
                "countryCode": "FR"
            },
            {
                "name": "Châtel-Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Châtel-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Châtenois",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Cirey-sur-Vezouze",
                "countryCode": "FR"
            },
            {
                "name": "Clermont-en-Argonne",
                "countryCode": "FR"
            },
            {
                "name": "Clouange",
                "countryCode": "FR"
            },
            {
                "name": "Cocheren",
                "countryCode": "FR"
            },
            {
                "name": "Colmar",
                "countryCode": "FR"
            },
            {
                "name": "Colombey-les-Belles",
                "countryCode": "FR"
            },
            {
                "name": "Commercy",
                "countryCode": "FR"
            },
            {
                "name": "Compertrix",
                "countryCode": "FR"
            },
            {
                "name": "Conflans-en-Jarnisy",
                "countryCode": "FR"
            },
            {
                "name": "Connantre",
                "countryCode": "FR"
            },
            {
                "name": "Contrexéville",
                "countryCode": "FR"
            },
            {
                "name": "Corcieux",
                "countryCode": "FR"
            },
            {
                "name": "Cormicy",
                "countryCode": "FR"
            },
            {
                "name": "Cormontreuil",
                "countryCode": "FR"
            },
            {
                "name": "Cornimont",
                "countryCode": "FR"
            },
            {
                "name": "Corny-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Cosnes",
                "countryCode": "FR"
            },
            {
                "name": "Courcelles-Chaussy",
                "countryCode": "FR"
            },
            {
                "name": "Courcy",
                "countryCode": "FR"
            },
            {
                "name": "Courtisols",
                "countryCode": "FR"
            },
            {
                "name": "Creney-près-Troyes",
                "countryCode": "FR"
            },
            {
                "name": "Creutzwald",
                "countryCode": "FR"
            },
            {
                "name": "Crusnes",
                "countryCode": "FR"
            },
            {
                "name": "Créhange",
                "countryCode": "FR"
            },
            {
                "name": "Custines",
                "countryCode": "FR"
            },
            {
                "name": "Dabo",
                "countryCode": "FR"
            },
            {
                "name": "Dachstein",
                "countryCode": "FR"
            },
            {
                "name": "Dambach-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Damelevières",
                "countryCode": "FR"
            },
            {
                "name": "Damery",
                "countryCode": "FR"
            },
            {
                "name": "Dannemarie",
                "countryCode": "FR"
            },
            {
                "name": "Darney",
                "countryCode": "FR"
            },
            {
                "name": "Darnieulles",
                "countryCode": "FR"
            },
            {
                "name": "Dauendorf",
                "countryCode": "FR"
            },
            {
                "name": "Dessenheim",
                "countryCode": "FR"
            },
            {
                "name": "Dettwiller",
                "countryCode": "FR"
            },
            {
                "name": "Deville",
                "countryCode": "FR"
            },
            {
                "name": "Deyvillers",
                "countryCode": "FR"
            },
            {
                "name": "Didenheim",
                "countryCode": "FR"
            },
            {
                "name": "Diebling",
                "countryCode": "FR"
            },
            {
                "name": "Diemeringen",
                "countryCode": "FR"
            },
            {
                "name": "Diesen",
                "countryCode": "FR"
            },
            {
                "name": "Dietwiller",
                "countryCode": "FR"
            },
            {
                "name": "Dieue-sur-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Dieulouard",
                "countryCode": "FR"
            },
            {
                "name": "Dieuze",
                "countryCode": "FR"
            },
            {
                "name": "Dingsheim",
                "countryCode": "FR"
            },
            {
                "name": "Dinsheim-sur-Bruche",
                "countryCode": "FR"
            },
            {
                "name": "Distroff",
                "countryCode": "FR"
            },
            {
                "name": "Dizy",
                "countryCode": "FR"
            },
            {
                "name": "Docelles",
                "countryCode": "FR"
            },
            {
                "name": "Dogneville",
                "countryCode": "FR"
            },
            {
                "name": "Dom-le-Mesnil",
                "countryCode": "FR"
            },
            {
                "name": "Dombasle-sur-Meurthe",
                "countryCode": "FR"
            },
            {
                "name": "Domgermain",
                "countryCode": "FR"
            },
            {
                "name": "Dommartin-lès-Remiremont",
                "countryCode": "FR"
            },
            {
                "name": "Dommartin-lès-Toul",
                "countryCode": "FR"
            },
            {
                "name": "Donchery",
                "countryCode": "FR"
            },
            {
                "name": "Doncourt-lès-Conflans",
                "countryCode": "FR"
            },
            {
                "name": "Dorlisheim",
                "countryCode": "FR"
            },
            {
                "name": "Dormans",
                "countryCode": "FR"
            },
            {
                "name": "Dossenheim-sur-Zinsel",
                "countryCode": "FR"
            },
            {
                "name": "Doulaincourt-Saucourt",
                "countryCode": "FR"
            },
            {
                "name": "Douzy",
                "countryCode": "FR"
            },
            {
                "name": "Drulingen",
                "countryCode": "FR"
            },
            {
                "name": "Drusenheim",
                "countryCode": "FR"
            },
            {
                "name": "Dugny-sur-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Duppigheim",
                "countryCode": "FR"
            },
            {
                "name": "Durrenbach",
                "countryCode": "FR"
            },
            {
                "name": "Duttlenheim",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Aube",
                "countryCode": "FR"
            },
            {
                "name": "Ebersheim",
                "countryCode": "FR"
            },
            {
                "name": "Eckbolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Eckwersheim",
                "countryCode": "FR"
            },
            {
                "name": "Eguisheim",
                "countryCode": "FR"
            },
            {
                "name": "Einville-au-Jard",
                "countryCode": "FR"
            },
            {
                "name": "Enchenberg",
                "countryCode": "FR"
            },
            {
                "name": "Ennery",
                "countryCode": "FR"
            },
            {
                "name": "Ensisheim",
                "countryCode": "FR"
            },
            {
                "name": "Entrange",
                "countryCode": "FR"
            },
            {
                "name": "Entzheim",
                "countryCode": "FR"
            },
            {
                "name": "Epfig",
                "countryCode": "FR"
            },
            {
                "name": "Ernolsheim-Bruche",
                "countryCode": "FR"
            },
            {
                "name": "Erstein",
                "countryCode": "FR"
            },
            {
                "name": "Ervy-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Eschau",
                "countryCode": "FR"
            },
            {
                "name": "Eschbach",
                "countryCode": "FR"
            },
            {
                "name": "Eschentzwiller",
                "countryCode": "FR"
            },
            {
                "name": "Essey-lès-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Esternay",
                "countryCode": "FR"
            },
            {
                "name": "Estissac",
                "countryCode": "FR"
            },
            {
                "name": "Etzling",
                "countryCode": "FR"
            },
            {
                "name": "Eulmont",
                "countryCode": "FR"
            },
            {
                "name": "Eurville-Bienville",
                "countryCode": "FR"
            },
            {
                "name": "Euville",
                "countryCode": "FR"
            },
            {
                "name": "Fagnières",
                "countryCode": "FR"
            },
            {
                "name": "Fains-Véel",
                "countryCode": "FR"
            },
            {
                "name": "Falck",
                "countryCode": "FR"
            },
            {
                "name": "Fameck",
                "countryCode": "FR"
            },
            {
                "name": "Farschviller",
                "countryCode": "FR"
            },
            {
                "name": "Farébersviller",
                "countryCode": "FR"
            },
            {
                "name": "Faulquemont",
                "countryCode": "FR"
            },
            {
                "name": "Faulx",
                "countryCode": "FR"
            },
            {
                "name": "Fayl-Billot",
                "countryCode": "FR"
            },
            {
                "name": "Fegersheim",
                "countryCode": "FR"
            },
            {
                "name": "Fellering",
                "countryCode": "FR"
            },
            {
                "name": "Ferrette",
                "countryCode": "FR"
            },
            {
                "name": "Fessenheim",
                "countryCode": "FR"
            },
            {
                "name": "Fismes",
                "countryCode": "FR"
            },
            {
                "name": "Flavigny-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Flaxlanden",
                "countryCode": "FR"
            },
            {
                "name": "Fleury",
                "countryCode": "FR"
            },
            {
                "name": "Flize",
                "countryCode": "FR"
            },
            {
                "name": "Floing",
                "countryCode": "FR"
            },
            {
                "name": "Florange",
                "countryCode": "FR"
            },
            {
                "name": "Fléville-devant-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Folkling",
                "countryCode": "FR"
            },
            {
                "name": "Folschviller",
                "countryCode": "FR"
            },
            {
                "name": "Fontoy",
                "countryCode": "FR"
            },
            {
                "name": "Forbach",
                "countryCode": "FR"
            },
            {
                "name": "Fortschwihr",
                "countryCode": "FR"
            },
            {
                "name": "Foug",
                "countryCode": "FR"
            },
            {
                "name": "Fraize",
                "countryCode": "FR"
            },
            {
                "name": "Fresse-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Freyming-Merlebach",
                "countryCode": "FR"
            },
            {
                "name": "Frignicourt",
                "countryCode": "FR"
            },
            {
                "name": "Fromelennes",
                "countryCode": "FR"
            },
            {
                "name": "Froncles",
                "countryCode": "FR"
            },
            {
                "name": "Frouard",
                "countryCode": "FR"
            },
            {
                "name": "Fréland",
                "countryCode": "FR"
            },
            {
                "name": "Fumay",
                "countryCode": "FR"
            },
            {
                "name": "Furdenheim",
                "countryCode": "FR"
            },
            {
                "name": "Fère-Champenoise",
                "countryCode": "FR"
            },
            {
                "name": "Gambsheim",
                "countryCode": "FR"
            },
            {
                "name": "Gandrange",
                "countryCode": "FR"
            },
            {
                "name": "Geispolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Gerbéviller",
                "countryCode": "FR"
            },
            {
                "name": "Gerstheim",
                "countryCode": "FR"
            },
            {
                "name": "Gespunsart",
                "countryCode": "FR"
            },
            {
                "name": "Geudertheim",
                "countryCode": "FR"
            },
            {
                "name": "Giraumont",
                "countryCode": "FR"
            },
            {
                "name": "Givet",
                "countryCode": "FR"
            },
            {
                "name": "Goetzenbruck",
                "countryCode": "FR"
            },
            {
                "name": "Golbey",
                "countryCode": "FR"
            },
            {
                "name": "Gondrecourt-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Gondreville",
                "countryCode": "FR"
            },
            {
                "name": "Gorcy",
                "countryCode": "FR"
            },
            {
                "name": "Gorze",
                "countryCode": "FR"
            },
            {
                "name": "Granges-sur-Vologne",
                "countryCode": "FR"
            },
            {
                "name": "Grendelbruch",
                "countryCode": "FR"
            },
            {
                "name": "Gresswiller",
                "countryCode": "FR"
            },
            {
                "name": "Gries",
                "countryCode": "FR"
            },
            {
                "name": "Griesheim-près-Molsheim",
                "countryCode": "FR"
            },
            {
                "name": "Griesheim-sur-Souffel",
                "countryCode": "FR"
            },
            {
                "name": "Gros-Réderching",
                "countryCode": "FR"
            },
            {
                "name": "Grosbliederstroff",
                "countryCode": "FR"
            },
            {
                "name": "Guebwiller",
                "countryCode": "FR"
            },
            {
                "name": "Gueux",
                "countryCode": "FR"
            },
            {
                "name": "Guewenheim",
                "countryCode": "FR"
            },
            {
                "name": "Gumbrechtshoffen",
                "countryCode": "FR"
            },
            {
                "name": "Gundershoffen",
                "countryCode": "FR"
            },
            {
                "name": "Guémar",
                "countryCode": "FR"
            },
            {
                "name": "Guénange",
                "countryCode": "FR"
            },
            {
                "name": "Gérardmer",
                "countryCode": "FR"
            },
            {
                "name": "Gœrsdorf",
                "countryCode": "FR"
            },
            {
                "name": "Habsheim",
                "countryCode": "FR"
            },
            {
                "name": "Hadol",
                "countryCode": "FR"
            },
            {
                "name": "Hagenthal-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Hagondange",
                "countryCode": "FR"
            },
            {
                "name": "Haguenau",
                "countryCode": "FR"
            },
            {
                "name": "Ham-sous-Varsberg",
                "countryCode": "FR"
            },
            {
                "name": "Hambach",
                "countryCode": "FR"
            },
            {
                "name": "Hangenbieten",
                "countryCode": "FR"
            },
            {
                "name": "Hargarten-aux-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Hatten",
                "countryCode": "FR"
            },
            {
                "name": "Haucourt",
                "countryCode": "FR"
            },
            {
                "name": "Haut-Rhin",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Hayange",
                "countryCode": "FR"
            },
            {
                "name": "Haybes",
                "countryCode": "FR"
            },
            {
                "name": "Heillecourt",
                "countryCode": "FR"
            },
            {
                "name": "Heimsbrunn",
                "countryCode": "FR"
            },
            {
                "name": "Herbitzheim",
                "countryCode": "FR"
            },
            {
                "name": "Hermonville",
                "countryCode": "FR"
            },
            {
                "name": "Herrlisheim",
                "countryCode": "FR"
            },
            {
                "name": "Herrlisheim-près-Colmar",
                "countryCode": "FR"
            },
            {
                "name": "Herserange",
                "countryCode": "FR"
            },
            {
                "name": "Hettange-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Hilsenheim",
                "countryCode": "FR"
            },
            {
                "name": "Hindisheim",
                "countryCode": "FR"
            },
            {
                "name": "Hirsingue",
                "countryCode": "FR"
            },
            {
                "name": "Hirtzbach",
                "countryCode": "FR"
            },
            {
                "name": "Hirtzfelden",
                "countryCode": "FR"
            },
            {
                "name": "Hochfelden",
                "countryCode": "FR"
            },
            {
                "name": "Hochstatt",
                "countryCode": "FR"
            },
            {
                "name": "Holtzheim",
                "countryCode": "FR"
            },
            {
                "name": "Holtzwihr",
                "countryCode": "FR"
            },
            {
                "name": "Holving",
                "countryCode": "FR"
            },
            {
                "name": "Hombourg-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Homécourt",
                "countryCode": "FR"
            },
            {
                "name": "Horbourg-Wihr",
                "countryCode": "FR"
            },
            {
                "name": "Houdemont",
                "countryCode": "FR"
            },
            {
                "name": "Houssen",
                "countryCode": "FR"
            },
            {
                "name": "Hundling",
                "countryCode": "FR"
            },
            {
                "name": "Huningue",
                "countryCode": "FR"
            },
            {
                "name": "Husseren-Wesserling",
                "countryCode": "FR"
            },
            {
                "name": "Hussigny-Godbrange",
                "countryCode": "FR"
            },
            {
                "name": "Huttenheim",
                "countryCode": "FR"
            },
            {
                "name": "Hégenheim",
                "countryCode": "FR"
            },
            {
                "name": "Hésingue",
                "countryCode": "FR"
            },
            {
                "name": "Hœnheim",
                "countryCode": "FR"
            },
            {
                "name": "Hœrdt",
                "countryCode": "FR"
            },
            {
                "name": "Igney",
                "countryCode": "FR"
            },
            {
                "name": "Illange",
                "countryCode": "FR"
            },
            {
                "name": "Illfurth",
                "countryCode": "FR"
            },
            {
                "name": "Illkirch-Graffenstaden",
                "countryCode": "FR"
            },
            {
                "name": "Illzach",
                "countryCode": "FR"
            },
            {
                "name": "Ingersheim",
                "countryCode": "FR"
            },
            {
                "name": "Ingwiller",
                "countryCode": "FR"
            },
            {
                "name": "Innenheim",
                "countryCode": "FR"
            },
            {
                "name": "Irmstett",
                "countryCode": "FR"
            },
            {
                "name": "Issenheim",
                "countryCode": "FR"
            },
            {
                "name": "Ittenheim",
                "countryCode": "FR"
            },
            {
                "name": "Jarny",
                "countryCode": "FR"
            },
            {
                "name": "Jarville-la-Malgrange",
                "countryCode": "FR"
            },
            {
                "name": "Jeanménil",
                "countryCode": "FR"
            },
            {
                "name": "Jebsheim",
                "countryCode": "FR"
            },
            {
                "name": "Joinville",
                "countryCode": "FR"
            },
            {
                "name": "Jonchery-sur-Vesle",
                "countryCode": "FR"
            },
            {
                "name": "Joudreville",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-aux-Arches",
                "countryCode": "FR"
            },
            {
                "name": "Jœuf",
                "countryCode": "FR"
            },
            {
                "name": "Kaltenhouse",
                "countryCode": "FR"
            },
            {
                "name": "Kaysersberg",
                "countryCode": "FR"
            },
            {
                "name": "Kembs",
                "countryCode": "FR"
            },
            {
                "name": "Kerbach",
                "countryCode": "FR"
            },
            {
                "name": "Kertzfeld",
                "countryCode": "FR"
            },
            {
                "name": "Keskastel",
                "countryCode": "FR"
            },
            {
                "name": "Kilstett",
                "countryCode": "FR"
            },
            {
                "name": "Kingersheim",
                "countryCode": "FR"
            },
            {
                "name": "Kintzheim",
                "countryCode": "FR"
            },
            {
                "name": "Knutange",
                "countryCode": "FR"
            },
            {
                "name": "Krautergersheim",
                "countryCode": "FR"
            },
            {
                "name": "Kruth",
                "countryCode": "FR"
            },
            {
                "name": "Kunheim",
                "countryCode": "FR"
            },
            {
                "name": "Kuntzig",
                "countryCode": "FR"
            },
            {
                "name": "Kédange-sur-Canner",
                "countryCode": "FR"
            },
            {
                "name": "Kœnigsmacker",
                "countryCode": "FR"
            },
            {
                "name": "La Bresse",
                "countryCode": "FR"
            },
            {
                "name": "La Broque",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Luc",
                "countryCode": "FR"
            },
            {
                "name": "La Francheville",
                "countryCode": "FR"
            },
            {
                "name": "La Rivière-de-Corps",
                "countryCode": "FR"
            },
            {
                "name": "La Wantzenau",
                "countryCode": "FR"
            },
            {
                "name": "Labaroche",
                "countryCode": "FR"
            },
            {
                "name": "Labry",
                "countryCode": "FR"
            },
            {
                "name": "Lamarche",
                "countryCode": "FR"
            },
            {
                "name": "Lampertheim",
                "countryCode": "FR"
            },
            {
                "name": "Landres",
                "countryCode": "FR"
            },
            {
                "name": "Landser",
                "countryCode": "FR"
            },
            {
                "name": "Laneuveville-devant-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Langres",
                "countryCode": "FR"
            },
            {
                "name": "Lapoutroie",
                "countryCode": "FR"
            },
            {
                "name": "Lautenbach",
                "countryCode": "FR"
            },
            {
                "name": "Lautenbachzell",
                "countryCode": "FR"
            },
            {
                "name": "Lauterbourg",
                "countryCode": "FR"
            },
            {
                "name": "Laxou",
                "countryCode": "FR"
            },
            {
                "name": "Lay-Saint-Christophe",
                "countryCode": "FR"
            },
            {
                "name": "Le Ban Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-sur-Oger",
                "countryCode": "FR"
            },
            {
                "name": "Le Ménil",
                "countryCode": "FR"
            },
            {
                "name": "Le Syndicat",
                "countryCode": "FR"
            },
            {
                "name": "Le Thillot",
                "countryCode": "FR"
            },
            {
                "name": "Le Tholy",
                "countryCode": "FR"
            },
            {
                "name": "Lembach",
                "countryCode": "FR"
            },
            {
                "name": "Lemberg",
                "countryCode": "FR"
            },
            {
                "name": "Les Forges",
                "countryCode": "FR"
            },
            {
                "name": "Les Hautes-Rivières",
                "countryCode": "FR"
            },
            {
                "name": "Les Noës-près-Troyes",
                "countryCode": "FR"
            },
            {
                "name": "Les Riceys",
                "countryCode": "FR"
            },
            {
                "name": "Lexy",
                "countryCode": "FR"
            },
            {
                "name": "Leymen",
                "countryCode": "FR"
            },
            {
                "name": "Liffol-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Ligny-en-Barrois",
                "countryCode": "FR"
            },
            {
                "name": "Lingolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Lipsheim",
                "countryCode": "FR"
            },
            {
                "name": "Liverdun",
                "countryCode": "FR"
            },
            {
                "name": "Lièpvre",
                "countryCode": "FR"
            },
            {
                "name": "Loivre",
                "countryCode": "FR"
            },
            {
                "name": "Longeville-en-Barrois",
                "countryCode": "FR"
            },
            {
                "name": "Longeville-lès-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Longeville-lès-Saint-Avold",
                "countryCode": "FR"
            },
            {
                "name": "Longlaville",
                "countryCode": "FR"
            },
            {
                "name": "Longuyon",
                "countryCode": "FR"
            },
            {
                "name": "Longwy",
                "countryCode": "FR"
            },
            {
                "name": "Lorquin",
                "countryCode": "FR"
            },
            {
                "name": "Ludres",
                "countryCode": "FR"
            },
            {
                "name": "Lumes",
                "countryCode": "FR"
            },
            {
                "name": "Lunéville",
                "countryCode": "FR"
            },
            {
                "name": "Lusigny-sur-Barse",
                "countryCode": "FR"
            },
            {
                "name": "Lutterbach",
                "countryCode": "FR"
            },
            {
                "name": "Lutzelhouse",
                "countryCode": "FR"
            },
            {
                "name": "Lérouville",
                "countryCode": "FR"
            },
            {
                "name": "Macheren",
                "countryCode": "FR"
            },
            {
                "name": "Magenta",
                "countryCode": "FR"
            },
            {
                "name": "Maidières",
                "countryCode": "FR"
            },
            {
                "name": "Mailly-le-Camp",
                "countryCode": "FR"
            },
            {
                "name": "Maizières-la-Grande-Paroisse",
                "countryCode": "FR"
            },
            {
                "name": "Maizières-lès-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Malzéville",
                "countryCode": "FR"
            },
            {
                "name": "Mancieulles",
                "countryCode": "FR"
            },
            {
                "name": "Manom",
                "countryCode": "FR"
            },
            {
                "name": "Marange-Silvange",
                "countryCode": "FR"
            },
            {
                "name": "Marbache",
                "countryCode": "FR"
            },
            {
                "name": "Marckolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Mardeuil",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-le-Port",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-sur-Ay",
                "countryCode": "FR"
            },
            {
                "name": "Marigny-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Marlenheim",
                "countryCode": "FR"
            },
            {
                "name": "Marly",
                "countryCode": "FR"
            },
            {
                "name": "Marmoutier",
                "countryCode": "FR"
            },
            {
                "name": "Marne",
                "countryCode": "FR"
            },
            {
                "name": "Masevaux",
                "countryCode": "FR"
            },
            {
                "name": "Matzenheim",
                "countryCode": "FR"
            },
            {
                "name": "Maxéville",
                "countryCode": "FR"
            },
            {
                "name": "Meistratzheim",
                "countryCode": "FR"
            },
            {
                "name": "Mercy-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Merten",
                "countryCode": "FR"
            },
            {
                "name": "Mertzwiller",
                "countryCode": "FR"
            },
            {
                "name": "Merxheim",
                "countryCode": "FR"
            },
            {
                "name": "Messein",
                "countryCode": "FR"
            },
            {
                "name": "Metz",
                "countryCode": "FR"
            },
            {
                "name": "Metzeral",
                "countryCode": "FR"
            },
            {
                "name": "Metzervisse",
                "countryCode": "FR"
            },
            {
                "name": "Meurthe-et-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Mexy",
                "countryCode": "FR"
            },
            {
                "name": "Meyenheim",
                "countryCode": "FR"
            },
            {
                "name": "Mirecourt",
                "countryCode": "FR"
            },
            {
                "name": "Mittelhausbergen",
                "countryCode": "FR"
            },
            {
                "name": "Molsheim",
                "countryCode": "FR"
            },
            {
                "name": "Mommenheim",
                "countryCode": "FR"
            },
            {
                "name": "Mondelange",
                "countryCode": "FR"
            },
            {
                "name": "Monswiller",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Montauville",
                "countryCode": "FR"
            },
            {
                "name": "Montbronn",
                "countryCode": "FR"
            },
            {
                "name": "Montcy-Notre-Dame",
                "countryCode": "FR"
            },
            {
                "name": "Monthermé",
                "countryCode": "FR"
            },
            {
                "name": "Montier-en-Der",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-lès-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Montmirail",
                "countryCode": "FR"
            },
            {
                "name": "Montmédy",
                "countryCode": "FR"
            },
            {
                "name": "Montois-la-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Moosch",
                "countryCode": "FR"
            },
            {
                "name": "Morhange",
                "countryCode": "FR"
            },
            {
                "name": "Morsbach",
                "countryCode": "FR"
            },
            {
                "name": "Morschwiller-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Moulins-lès-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Mourmelon-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Moutiers",
                "countryCode": "FR"
            },
            {
                "name": "Mouzon",
                "countryCode": "FR"
            },
            {
                "name": "Moyenmoutier",
                "countryCode": "FR"
            },
            {
                "name": "Moyeuvre-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Muizon",
                "countryCode": "FR"
            },
            {
                "name": "Mulhouse",
                "countryCode": "FR"
            },
            {
                "name": "Munchhouse",
                "countryCode": "FR"
            },
            {
                "name": "Mundolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Munster",
                "countryCode": "FR"
            },
            {
                "name": "Mussig",
                "countryCode": "FR"
            },
            {
                "name": "Mussy-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Muttersholtz",
                "countryCode": "FR"
            },
            {
                "name": "Mutzig",
                "countryCode": "FR"
            },
            {
                "name": "Mécleuves",
                "countryCode": "FR"
            },
            {
                "name": "Méry-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Méréville",
                "countryCode": "FR"
            },
            {
                "name": "Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Neuf-Brisach",
                "countryCode": "FR"
            },
            {
                "name": "Neufchef",
                "countryCode": "FR"
            },
            {
                "name": "Neufchâteau",
                "countryCode": "FR"
            },
            {
                "name": "Neufgrange",
                "countryCode": "FR"
            },
            {
                "name": "Neufmanil",
                "countryCode": "FR"
            },
            {
                "name": "Neuves-Maisons",
                "countryCode": "FR"
            },
            {
                "name": "Neuwiller-lès-Saverne",
                "countryCode": "FR"
            },
            {
                "name": "Niderviller",
                "countryCode": "FR"
            },
            {
                "name": "Niederbronn-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Niederhaslach",
                "countryCode": "FR"
            },
            {
                "name": "Niederhausbergen",
                "countryCode": "FR"
            },
            {
                "name": "Niedernai",
                "countryCode": "FR"
            },
            {
                "name": "Niederschaeffolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Nilvange",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-le-Bas",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Nomeny",
                "countryCode": "FR"
            },
            {
                "name": "Nomexy",
                "countryCode": "FR"
            },
            {
                "name": "Nordhouse",
                "countryCode": "FR"
            },
            {
                "name": "Norroy-lès-Pont-à-Mousson",
                "countryCode": "FR"
            },
            {
                "name": "Nouvion-sur-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Nouzonville",
                "countryCode": "FR"
            },
            {
                "name": "Novéant-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Obenheim",
                "countryCode": "FR"
            },
            {
                "name": "Oberbronn",
                "countryCode": "FR"
            },
            {
                "name": "Oberhaslach",
                "countryCode": "FR"
            },
            {
                "name": "Oberhausbergen",
                "countryCode": "FR"
            },
            {
                "name": "Oberhergheim",
                "countryCode": "FR"
            },
            {
                "name": "Oberhoffen-sur-Moder",
                "countryCode": "FR"
            },
            {
                "name": "Obermodern-Zutzendorf",
                "countryCode": "FR"
            },
            {
                "name": "Obernai",
                "countryCode": "FR"
            },
            {
                "name": "Oberschaeffolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Oderen",
                "countryCode": "FR"
            },
            {
                "name": "Oermingen",
                "countryCode": "FR"
            },
            {
                "name": "Offenheim",
                "countryCode": "FR"
            },
            {
                "name": "Ohlungen",
                "countryCode": "FR"
            },
            {
                "name": "Orbey",
                "countryCode": "FR"
            },
            {
                "name": "Ostheim",
                "countryCode": "FR"
            },
            {
                "name": "Ostwald",
                "countryCode": "FR"
            },
            {
                "name": "Ottange",
                "countryCode": "FR"
            },
            {
                "name": "Otterswiller",
                "countryCode": "FR"
            },
            {
                "name": "Ottmarsheim",
                "countryCode": "FR"
            },
            {
                "name": "Ottrott",
                "countryCode": "FR"
            },
            {
                "name": "Pagny-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Pargny-sur-Saulx",
                "countryCode": "FR"
            },
            {
                "name": "Payns",
                "countryCode": "FR"
            },
            {
                "name": "Peltre",
                "countryCode": "FR"
            },
            {
                "name": "Petit-Réderching",
                "countryCode": "FR"
            },
            {
                "name": "Petite-Rosselle",
                "countryCode": "FR"
            },
            {
                "name": "Pfaffenheim",
                "countryCode": "FR"
            },
            {
                "name": "Pfaffenhoffen",
                "countryCode": "FR"
            },
            {
                "name": "Pfastatt",
                "countryCode": "FR"
            },
            {
                "name": "Pfetterhouse",
                "countryCode": "FR"
            },
            {
                "name": "Pfulgriesheim",
                "countryCode": "FR"
            },
            {
                "name": "Phalsbourg",
                "countryCode": "FR"
            },
            {
                "name": "Piennes",
                "countryCode": "FR"
            },
            {
                "name": "Pierrepont",
                "countryCode": "FR"
            },
            {
                "name": "Pierrevillers",
                "countryCode": "FR"
            },
            {
                "name": "Pierry",
                "countryCode": "FR"
            },
            {
                "name": "Piney",
                "countryCode": "FR"
            },
            {
                "name": "Plainfaing",
                "countryCode": "FR"
            },
            {
                "name": "Plappeville",
                "countryCode": "FR"
            },
            {
                "name": "Plobsheim",
                "countryCode": "FR"
            },
            {
                "name": "Plombières-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Pompey",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Saint-Vincent",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Sainte-Marie",
                "countryCode": "FR"
            },
            {
                "name": "Pont-à-Mousson",
                "countryCode": "FR"
            },
            {
                "name": "Pontfaverger-Moronvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Porcelette",
                "countryCode": "FR"
            },
            {
                "name": "Port à Binson",
                "countryCode": "FR"
            },
            {
                "name": "Portieux",
                "countryCode": "FR"
            },
            {
                "name": "Pouru-Saint-Remy",
                "countryCode": "FR"
            },
            {
                "name": "Pouxeux",
                "countryCode": "FR"
            },
            {
                "name": "Prix-lès-Mézières",
                "countryCode": "FR"
            },
            {
                "name": "Pulligny",
                "countryCode": "FR"
            },
            {
                "name": "Pulnoy",
                "countryCode": "FR"
            },
            {
                "name": "Pulversheim",
                "countryCode": "FR"
            },
            {
                "name": "Raedersheim",
                "countryCode": "FR"
            },
            {
                "name": "Rambervillers",
                "countryCode": "FR"
            },
            {
                "name": "Ramonchamp",
                "countryCode": "FR"
            },
            {
                "name": "Raon-aux-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Reichshoffen",
                "countryCode": "FR"
            },
            {
                "name": "Reichstett",
                "countryCode": "FR"
            },
            {
                "name": "Reims",
                "countryCode": "FR"
            },
            {
                "name": "Reiningue",
                "countryCode": "FR"
            },
            {
                "name": "Remiremont",
                "countryCode": "FR"
            },
            {
                "name": "Renwez",
                "countryCode": "FR"
            },
            {
                "name": "Rethel",
                "countryCode": "FR"
            },
            {
                "name": "Retonfey",
                "countryCode": "FR"
            },
            {
                "name": "Revigny-sur-Ornain",
                "countryCode": "FR"
            },
            {
                "name": "Revin",
                "countryCode": "FR"
            },
            {
                "name": "Rhinau",
                "countryCode": "FR"
            },
            {
                "name": "Ribeauvillé",
                "countryCode": "FR"
            },
            {
                "name": "Richardménil",
                "countryCode": "FR"
            },
            {
                "name": "Richemont",
                "countryCode": "FR"
            },
            {
                "name": "Richwiller",
                "countryCode": "FR"
            },
            {
                "name": "Riedisheim",
                "countryCode": "FR"
            },
            {
                "name": "Rilly-la-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Rimogne",
                "countryCode": "FR"
            },
            {
                "name": "Riquewihr",
                "countryCode": "FR"
            },
            {
                "name": "Rixheim",
                "countryCode": "FR"
            },
            {
                "name": "Rocroi",
                "countryCode": "FR"
            },
            {
                "name": "Roeschwoog",
                "countryCode": "FR"
            },
            {
                "name": "Rohrbach-lès-Bitche",
                "countryCode": "FR"
            },
            {
                "name": "Rolampont",
                "countryCode": "FR"
            },
            {
                "name": "Romanswiller",
                "countryCode": "FR"
            },
            {
                "name": "Rombas",
                "countryCode": "FR"
            },
            {
                "name": "Romilly-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Rosenau",
                "countryCode": "FR"
            },
            {
                "name": "Rosheim",
                "countryCode": "FR"
            },
            {
                "name": "Rosières-aux-Salines",
                "countryCode": "FR"
            },
            {
                "name": "Rosières-près-Troyes",
                "countryCode": "FR"
            },
            {
                "name": "Rosselange",
                "countryCode": "FR"
            },
            {
                "name": "Rothau",
                "countryCode": "FR"
            },
            {
                "name": "Rouffach",
                "countryCode": "FR"
            },
            {
                "name": "Rouhling",
                "countryCode": "FR"
            },
            {
                "name": "Rozérieulles",
                "countryCode": "FR"
            },
            {
                "name": "Ruelisheim",
                "countryCode": "FR"
            },
            {
                "name": "Rumersheim-le-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Rupt-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Rurange-lès-Thionville",
                "countryCode": "FR"
            },
            {
                "name": "Russ",
                "countryCode": "FR"
            },
            {
                "name": "Russange",
                "countryCode": "FR"
            },
            {
                "name": "Réding",
                "countryCode": "FR"
            },
            {
                "name": "Réguisheim",
                "countryCode": "FR"
            },
            {
                "name": "Réhon",
                "countryCode": "FR"
            },
            {
                "name": "Rémelfing",
                "countryCode": "FR"
            },
            {
                "name": "Rémilly",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amarin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-les-Vergers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Avold",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brice-Courcelles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Dizier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Dié-des-Vosges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hippolyte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-les-Villas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-lès-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-Sauvage",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Louis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lyé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-sur-Moselle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Max",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Memmie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Menges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-sur-Meurthe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mihiel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nabord",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-de-Port",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Parres-aux-Tertres",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pouange",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Privat-la-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-lès-Remiremont",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Croix-aux-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Croix-en-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marguerite",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-aux-Chênes",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-aux-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Maure",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Menehould",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Savine",
                "countryCode": "FR"
            },
            {
                "name": "Saizerais",
                "countryCode": "FR"
            },
            {
                "name": "Sand",
                "countryCode": "FR"
            },
            {
                "name": "Sarralbe",
                "countryCode": "FR"
            },
            {
                "name": "Sarre-Union",
                "countryCode": "FR"
            },
            {
                "name": "Sarrebourg",
                "countryCode": "FR"
            },
            {
                "name": "Sarreguemines",
                "countryCode": "FR"
            },
            {
                "name": "Sarreinsming",
                "countryCode": "FR"
            },
            {
                "name": "Sarrewerden",
                "countryCode": "FR"
            },
            {
                "name": "Sarry",
                "countryCode": "FR"
            },
            {
                "name": "Saulcy-sur-Meurthe",
                "countryCode": "FR"
            },
            {
                "name": "Saulnes",
                "countryCode": "FR"
            },
            {
                "name": "Saulny",
                "countryCode": "FR"
            },
            {
                "name": "Sault-lès-Rethel",
                "countryCode": "FR"
            },
            {
                "name": "Saulxures-lès-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Saulxures-sur-Moselotte",
                "countryCode": "FR"
            },
            {
                "name": "Sausheim",
                "countryCode": "FR"
            },
            {
                "name": "Saverne",
                "countryCode": "FR"
            },
            {
                "name": "Scherwiller",
                "countryCode": "FR"
            },
            {
                "name": "Schiltigheim",
                "countryCode": "FR"
            },
            {
                "name": "Schirmeck",
                "countryCode": "FR"
            },
            {
                "name": "Schirrhein",
                "countryCode": "FR"
            },
            {
                "name": "Schnersheim",
                "countryCode": "FR"
            },
            {
                "name": "Schweighouse-sur-Moder",
                "countryCode": "FR"
            },
            {
                "name": "Schwindratzheim",
                "countryCode": "FR"
            },
            {
                "name": "Schœneck",
                "countryCode": "FR"
            },
            {
                "name": "Scy-Chazelles",
                "countryCode": "FR"
            },
            {
                "name": "Sedan",
                "countryCode": "FR"
            },
            {
                "name": "Seichamps",
                "countryCode": "FR"
            },
            {
                "name": "Seingbouse",
                "countryCode": "FR"
            },
            {
                "name": "Seltz",
                "countryCode": "FR"
            },
            {
                "name": "Senones",
                "countryCode": "FR"
            },
            {
                "name": "Sentheim",
                "countryCode": "FR"
            },
            {
                "name": "Sermaize-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Serémange-Erzange",
                "countryCode": "FR"
            },
            {
                "name": "Sessenheim",
                "countryCode": "FR"
            },
            {
                "name": "Sierck-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Sierentz",
                "countryCode": "FR"
            },
            {
                "name": "Signy-le-Petit",
                "countryCode": "FR"
            },
            {
                "name": "Sigolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Sillery",
                "countryCode": "FR"
            },
            {
                "name": "Solgne",
                "countryCode": "FR"
            },
            {
                "name": "Soucht",
                "countryCode": "FR"
            },
            {
                "name": "Souffelweyersheim",
                "countryCode": "FR"
            },
            {
                "name": "Soufflenheim",
                "countryCode": "FR"
            },
            {
                "name": "Soultz-Haut-Rhin",
                "countryCode": "FR"
            },
            {
                "name": "Soultz-sous-Forêts",
                "countryCode": "FR"
            },
            {
                "name": "Soultzeren",
                "countryCode": "FR"
            },
            {
                "name": "Soultzmatt",
                "countryCode": "FR"
            },
            {
                "name": "Spicheren",
                "countryCode": "FR"
            },
            {
                "name": "Staffelfelden",
                "countryCode": "FR"
            },
            {
                "name": "Steinbach",
                "countryCode": "FR"
            },
            {
                "name": "Steinbourg",
                "countryCode": "FR"
            },
            {
                "name": "Stenay",
                "countryCode": "FR"
            },
            {
                "name": "Still",
                "countryCode": "FR"
            },
            {
                "name": "Stiring-Wendel",
                "countryCode": "FR"
            },
            {
                "name": "Stosswihr",
                "countryCode": "FR"
            },
            {
                "name": "Stotzheim",
                "countryCode": "FR"
            },
            {
                "name": "Strasbourg",
                "countryCode": "FR"
            },
            {
                "name": "Suippes",
                "countryCode": "FR"
            },
            {
                "name": "Sundhoffen",
                "countryCode": "FR"
            },
            {
                "name": "Sundhouse",
                "countryCode": "FR"
            },
            {
                "name": "Surbourg",
                "countryCode": "FR"
            },
            {
                "name": "Sélestat",
                "countryCode": "FR"
            },
            {
                "name": "Sézanne",
                "countryCode": "FR"
            },
            {
                "name": "Taintrux",
                "countryCode": "FR"
            },
            {
                "name": "Taissy",
                "countryCode": "FR"
            },
            {
                "name": "Talange",
                "countryCode": "FR"
            },
            {
                "name": "Tenteling",
                "countryCode": "FR"
            },
            {
                "name": "Terville",
                "countryCode": "FR"
            },
            {
                "name": "Teting-sur-Nied",
                "countryCode": "FR"
            },
            {
                "name": "Thann",
                "countryCode": "FR"
            },
            {
                "name": "Thaon-les-Vosges",
                "countryCode": "FR"
            },
            {
                "name": "Thierville-sur-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Thil",
                "countryCode": "FR"
            },
            {
                "name": "Thilay",
                "countryCode": "FR"
            },
            {
                "name": "Thionville",
                "countryCode": "FR"
            },
            {
                "name": "Théding",
                "countryCode": "FR"
            },
            {
                "name": "Tinqueux",
                "countryCode": "FR"
            },
            {
                "name": "Tomblaine",
                "countryCode": "FR"
            },
            {
                "name": "Toul",
                "countryCode": "FR"
            },
            {
                "name": "Tournes",
                "countryCode": "FR"
            },
            {
                "name": "Tours-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Tressange",
                "countryCode": "FR"
            },
            {
                "name": "Trieux",
                "countryCode": "FR"
            },
            {
                "name": "Tronville-en-Barrois",
                "countryCode": "FR"
            },
            {
                "name": "Troyes",
                "countryCode": "FR"
            },
            {
                "name": "Truchtersheim",
                "countryCode": "FR"
            },
            {
                "name": "Trémery",
                "countryCode": "FR"
            },
            {
                "name": "Tucquegnieux",
                "countryCode": "FR"
            },
            {
                "name": "Turckheim",
                "countryCode": "FR"
            },
            {
                "name": "Uberach",
                "countryCode": "FR"
            },
            {
                "name": "Uckange",
                "countryCode": "FR"
            },
            {
                "name": "Uffholtz",
                "countryCode": "FR"
            },
            {
                "name": "Ungersheim",
                "countryCode": "FR"
            },
            {
                "name": "Uriménil",
                "countryCode": "FR"
            },
            {
                "name": "Urmatt",
                "countryCode": "FR"
            },
            {
                "name": "Uxegney",
                "countryCode": "FR"
            },
            {
                "name": "Uzemain",
                "countryCode": "FR"
            },
            {
                "name": "Vacon",
                "countryCode": "FR"
            },
            {
                "name": "Vagney",
                "countryCode": "FR"
            },
            {
                "name": "Val-de-Meuse",
                "countryCode": "FR"
            },
            {
                "name": "Valff",
                "countryCode": "FR"
            },
            {
                "name": "Valleroy",
                "countryCode": "FR"
            },
            {
                "name": "Valmont",
                "countryCode": "FR"
            },
            {
                "name": "Vandœuvre-lès-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Varangéville",
                "countryCode": "FR"
            },
            {
                "name": "Vaucouleurs",
                "countryCode": "FR"
            },
            {
                "name": "Vecoux",
                "countryCode": "FR"
            },
            {
                "name": "Velaine-en-Haye",
                "countryCode": "FR"
            },
            {
                "name": "Velaines",
                "countryCode": "FR"
            },
            {
                "name": "Vendenheim",
                "countryCode": "FR"
            },
            {
                "name": "Vendeuvre-sur-Barse",
                "countryCode": "FR"
            },
            {
                "name": "Verdun",
                "countryCode": "FR"
            },
            {
                "name": "Verny",
                "countryCode": "FR"
            },
            {
                "name": "Verrières",
                "countryCode": "FR"
            },
            {
                "name": "Vertus",
                "countryCode": "FR"
            },
            {
                "name": "Verzenay",
                "countryCode": "FR"
            },
            {
                "name": "Verzy",
                "countryCode": "FR"
            },
            {
                "name": "Vic-sur-Seille",
                "countryCode": "FR"
            },
            {
                "name": "Vieux-Thann",
                "countryCode": "FR"
            },
            {
                "name": "Vigneulles-lès-Hattonchâtel",
                "countryCode": "FR"
            },
            {
                "name": "Vignot",
                "countryCode": "FR"
            },
            {
                "name": "Vigy",
                "countryCode": "FR"
            },
            {
                "name": "Village-Neuf",
                "countryCode": "FR"
            },
            {
                "name": "Ville-sous-la-Ferté",
                "countryCode": "FR"
            },
            {
                "name": "Villenauxe-la-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Semeuse",
                "countryCode": "FR"
            },
            {
                "name": "Villers-la-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Villers-lès-Nancy",
                "countryCode": "FR"
            },
            {
                "name": "Villerupt",
                "countryCode": "FR"
            },
            {
                "name": "Villey-Saint-Étienne",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-en-Lieu",
                "countryCode": "FR"
            },
            {
                "name": "Villé",
                "countryCode": "FR"
            },
            {
                "name": "Vincey",
                "countryCode": "FR"
            },
            {
                "name": "Vireux-Molhain",
                "countryCode": "FR"
            },
            {
                "name": "Vireux-Wallerand",
                "countryCode": "FR"
            },
            {
                "name": "Vitry-le-François",
                "countryCode": "FR"
            },
            {
                "name": "Vitry-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Vittel",
                "countryCode": "FR"
            },
            {
                "name": "Vivier-au-Court",
                "countryCode": "FR"
            },
            {
                "name": "Void-Vacon",
                "countryCode": "FR"
            },
            {
                "name": "Volgelsheim",
                "countryCode": "FR"
            },
            {
                "name": "Volmerange-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Volstroff",
                "countryCode": "FR"
            },
            {
                "name": "Vosges",
                "countryCode": "FR"
            },
            {
                "name": "Vouziers",
                "countryCode": "FR"
            },
            {
                "name": "Vrigne-aux-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Vézelise",
                "countryCode": "FR"
            },
            {
                "name": "Walbourg",
                "countryCode": "FR"
            },
            {
                "name": "Waldighofen",
                "countryCode": "FR"
            },
            {
                "name": "Walscheid",
                "countryCode": "FR"
            },
            {
                "name": "Wangenbourg-Engenthal",
                "countryCode": "FR"
            },
            {
                "name": "Warcq",
                "countryCode": "FR"
            },
            {
                "name": "Warmeriville",
                "countryCode": "FR"
            },
            {
                "name": "Wasselonne",
                "countryCode": "FR"
            },
            {
                "name": "Wassy",
                "countryCode": "FR"
            },
            {
                "name": "Wattwiller",
                "countryCode": "FR"
            },
            {
                "name": "Weitbruch",
                "countryCode": "FR"
            },
            {
                "name": "Westhoffen",
                "countryCode": "FR"
            },
            {
                "name": "Wettolsheim",
                "countryCode": "FR"
            },
            {
                "name": "Weyersheim",
                "countryCode": "FR"
            },
            {
                "name": "Widensolen",
                "countryCode": "FR"
            },
            {
                "name": "Wiesviller",
                "countryCode": "FR"
            },
            {
                "name": "Wihr-au-Val",
                "countryCode": "FR"
            },
            {
                "name": "Willerwald",
                "countryCode": "FR"
            },
            {
                "name": "Wimmenau",
                "countryCode": "FR"
            },
            {
                "name": "Wingen-sur-Moder",
                "countryCode": "FR"
            },
            {
                "name": "Wingersheim",
                "countryCode": "FR"
            },
            {
                "name": "Wintzenheim",
                "countryCode": "FR"
            },
            {
                "name": "Wisches",
                "countryCode": "FR"
            },
            {
                "name": "Wissembourg",
                "countryCode": "FR"
            },
            {
                "name": "Witry-lès-Reims",
                "countryCode": "FR"
            },
            {
                "name": "Wittelsheim",
                "countryCode": "FR"
            },
            {
                "name": "Wittenheim",
                "countryCode": "FR"
            },
            {
                "name": "Wittisheim",
                "countryCode": "FR"
            },
            {
                "name": "Woerth",
                "countryCode": "FR"
            },
            {
                "name": "Woippy",
                "countryCode": "FR"
            },
            {
                "name": "Wolfgantzen",
                "countryCode": "FR"
            },
            {
                "name": "Wolfisheim",
                "countryCode": "FR"
            },
            {
                "name": "Woustviller",
                "countryCode": "FR"
            },
            {
                "name": "Xertigny",
                "countryCode": "FR"
            },
            {
                "name": "Xonrupt-Longemer",
                "countryCode": "FR"
            },
            {
                "name": "Yutz",
                "countryCode": "FR"
            },
            {
                "name": "Zillisheim",
                "countryCode": "FR"
            },
            {
                "name": "Zimmersheim",
                "countryCode": "FR"
            },
            {
                "name": "Écrouves",
                "countryCode": "FR"
            },
            {
                "name": "Éloyes",
                "countryCode": "FR"
            },
            {
                "name": "Épernay",
                "countryCode": "FR"
            },
            {
                "name": "Épinal",
                "countryCode": "FR"
            },
            {
                "name": "Étain",
                "countryCode": "FR"
            },
            {
                "name": "Étival-Clairefontaine",
                "countryCode": "FR"
            },
            {
                "name": "Œting",
                "countryCode": "FR"
            },
            {
                "name": "Abbeville",
                "countryCode": "FR"
            },
            {
                "name": "Ablain-Saint-Nazaire",
                "countryCode": "FR"
            },
            {
                "name": "Abscon",
                "countryCode": "FR"
            },
            {
                "name": "Achicourt",
                "countryCode": "FR"
            },
            {
                "name": "Achiet-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Agnetz",
                "countryCode": "FR"
            },
            {
                "name": "Agny",
                "countryCode": "FR"
            },
            {
                "name": "Ailly-sur-Noye",
                "countryCode": "FR"
            },
            {
                "name": "Ailly-sur-Somme",
                "countryCode": "FR"
            },
            {
                "name": "Airaines",
                "countryCode": "FR"
            },
            {
                "name": "Aire-sur-la-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Aix-Noulette",
                "countryCode": "FR"
            },
            {
                "name": "Albert",
                "countryCode": "FR"
            },
            {
                "name": "Allennes-les-Marais",
                "countryCode": "FR"
            },
            {
                "name": "Allonne",
                "countryCode": "FR"
            },
            {
                "name": "Allouagne",
                "countryCode": "FR"
            },
            {
                "name": "Amblainville",
                "countryCode": "FR"
            },
            {
                "name": "Ambleny",
                "countryCode": "FR"
            },
            {
                "name": "Ambleteuse",
                "countryCode": "FR"
            },
            {
                "name": "Amiens",
                "countryCode": "FR"
            },
            {
                "name": "Andeville",
                "countryCode": "FR"
            },
            {
                "name": "Andres",
                "countryCode": "FR"
            },
            {
                "name": "Angicourt",
                "countryCode": "FR"
            },
            {
                "name": "Angres",
                "countryCode": "FR"
            },
            {
                "name": "Angy",
                "countryCode": "FR"
            },
            {
                "name": "Anhiers",
                "countryCode": "FR"
            },
            {
                "name": "Aniche",
                "countryCode": "FR"
            },
            {
                "name": "Anizy-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Annay",
                "countryCode": "FR"
            },
            {
                "name": "Annequin",
                "countryCode": "FR"
            },
            {
                "name": "Annezin",
                "countryCode": "FR"
            },
            {
                "name": "Annoeullin",
                "countryCode": "FR"
            },
            {
                "name": "Anor",
                "countryCode": "FR"
            },
            {
                "name": "Ansauvillers",
                "countryCode": "FR"
            },
            {
                "name": "Anstaing",
                "countryCode": "FR"
            },
            {
                "name": "Anzin",
                "countryCode": "FR"
            },
            {
                "name": "Anzin-Saint-Aubin",
                "countryCode": "FR"
            },
            {
                "name": "Ardres",
                "countryCode": "FR"
            },
            {
                "name": "Arleux",
                "countryCode": "FR"
            },
            {
                "name": "Armbouts-Cappel",
                "countryCode": "FR"
            },
            {
                "name": "Armentières",
                "countryCode": "FR"
            },
            {
                "name": "Arnèke",
                "countryCode": "FR"
            },
            {
                "name": "Arques",
                "countryCode": "FR"
            },
            {
                "name": "Arras",
                "countryCode": "FR"
            },
            {
                "name": "Artres",
                "countryCode": "FR"
            },
            {
                "name": "Athies-sous-Laon",
                "countryCode": "FR"
            },
            {
                "name": "Attiches",
                "countryCode": "FR"
            },
            {
                "name": "Attichy",
                "countryCode": "FR"
            },
            {
                "name": "Auberchicourt",
                "countryCode": "FR"
            },
            {
                "name": "Aubers",
                "countryCode": "FR"
            },
            {
                "name": "Aubigny-au-Bac",
                "countryCode": "FR"
            },
            {
                "name": "Aubigny-en-Artois",
                "countryCode": "FR"
            },
            {
                "name": "Aubry-du-Hainaut",
                "countryCode": "FR"
            },
            {
                "name": "Auby",
                "countryCode": "FR"
            },
            {
                "name": "Auchel",
                "countryCode": "FR"
            },
            {
                "name": "Auchy-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Auchy-lès-Hesdin",
                "countryCode": "FR"
            },
            {
                "name": "Audruicq",
                "countryCode": "FR"
            },
            {
                "name": "Aulnois-sous-Laon",
                "countryCode": "FR"
            },
            {
                "name": "Aulnoy-lez-Valenciennes",
                "countryCode": "FR"
            },
            {
                "name": "Aulnoye-Aymeries",
                "countryCode": "FR"
            },
            {
                "name": "Ault",
                "countryCode": "FR"
            },
            {
                "name": "Auneuil",
                "countryCode": "FR"
            },
            {
                "name": "Auxi-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Avelin",
                "countryCode": "FR"
            },
            {
                "name": "Avesnelles",
                "countryCode": "FR"
            },
            {
                "name": "Avesnes-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Avesnes-le-Sec",
                "countryCode": "FR"
            },
            {
                "name": "Avesnes-les-Aubert",
                "countryCode": "FR"
            },
            {
                "name": "Avilly-Saint-Léonard",
                "countryCode": "FR"
            },
            {
                "name": "Avion",
                "countryCode": "FR"
            },
            {
                "name": "Avrechy",
                "countryCode": "FR"
            },
            {
                "name": "Bachant",
                "countryCode": "FR"
            },
            {
                "name": "Bachy",
                "countryCode": "FR"
            },
            {
                "name": "Bailleul",
                "countryCode": "FR"
            },
            {
                "name": "Bailleul-Sir-Berthoult",
                "countryCode": "FR"
            },
            {
                "name": "Bailleul-sur-Thérain",
                "countryCode": "FR"
            },
            {
                "name": "Bailleval",
                "countryCode": "FR"
            },
            {
                "name": "Baincthun",
                "countryCode": "FR"
            },
            {
                "name": "Baisieux",
                "countryCode": "FR"
            },
            {
                "name": "Balagny-sur-Thérain",
                "countryCode": "FR"
            },
            {
                "name": "Bapaume",
                "countryCode": "FR"
            },
            {
                "name": "Barlin",
                "countryCode": "FR"
            },
            {
                "name": "Bauvin",
                "countryCode": "FR"
            },
            {
                "name": "Bavay",
                "countryCode": "FR"
            },
            {
                "name": "Beaucamps-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Beauchamps",
                "countryCode": "FR"
            },
            {
                "name": "Beaumetz-lès-Loges",
                "countryCode": "FR"
            },
            {
                "name": "Beauquesne",
                "countryCode": "FR"
            },
            {
                "name": "Beaurains",
                "countryCode": "FR"
            },
            {
                "name": "Beaurainville",
                "countryCode": "FR"
            },
            {
                "name": "Beaurevoir",
                "countryCode": "FR"
            },
            {
                "name": "Beautor",
                "countryCode": "FR"
            },
            {
                "name": "Beauvais",
                "countryCode": "FR"
            },
            {
                "name": "Beauval",
                "countryCode": "FR"
            },
            {
                "name": "Beauvois-en-Cambrésis",
                "countryCode": "FR"
            },
            {
                "name": "Bellaing",
                "countryCode": "FR"
            },
            {
                "name": "Belleu",
                "countryCode": "FR"
            },
            {
                "name": "Berck",
                "countryCode": "FR"
            },
            {
                "name": "Berck-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Bergues",
                "countryCode": "FR"
            },
            {
                "name": "Berlaimont",
                "countryCode": "FR"
            },
            {
                "name": "Bernaville",
                "countryCode": "FR"
            },
            {
                "name": "Bersée",
                "countryCode": "FR"
            },
            {
                "name": "Berteaucourt-les-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Berthecourt",
                "countryCode": "FR"
            },
            {
                "name": "Bertry",
                "countryCode": "FR"
            },
            {
                "name": "Betz",
                "countryCode": "FR"
            },
            {
                "name": "Beuvrages",
                "countryCode": "FR"
            },
            {
                "name": "Beuvry",
                "countryCode": "FR"
            },
            {
                "name": "Biache-Saint-Vaast",
                "countryCode": "FR"
            },
            {
                "name": "Bierne",
                "countryCode": "FR"
            },
            {
                "name": "Billy-Berclau",
                "countryCode": "FR"
            },
            {
                "name": "Billy-Montigny",
                "countryCode": "FR"
            },
            {
                "name": "Billy-sur-Aisne",
                "countryCode": "FR"
            },
            {
                "name": "Blaincourt-lès-Précy",
                "countryCode": "FR"
            },
            {
                "name": "Blaringhem",
                "countryCode": "FR"
            },
            {
                "name": "Blendecques",
                "countryCode": "FR"
            },
            {
                "name": "Blérancourt",
                "countryCode": "FR"
            },
            {
                "name": "Boeschepe",
                "countryCode": "FR"
            },
            {
                "name": "Bohain-en-Vermandois",
                "countryCode": "FR"
            },
            {
                "name": "Bois-Grenier",
                "countryCode": "FR"
            },
            {
                "name": "Bollezeele",
                "countryCode": "FR"
            },
            {
                "name": "Bondues",
                "countryCode": "FR"
            },
            {
                "name": "Boran-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Bornel",
                "countryCode": "FR"
            },
            {
                "name": "Bouchain",
                "countryCode": "FR"
            },
            {
                "name": "Boulogne-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Bourbourg",
                "countryCode": "FR"
            },
            {
                "name": "Bourghelles",
                "countryCode": "FR"
            },
            {
                "name": "Bourlon",
                "countryCode": "FR"
            },
            {
                "name": "Bousbecque",
                "countryCode": "FR"
            },
            {
                "name": "Bousies",
                "countryCode": "FR"
            },
            {
                "name": "Boussois",
                "countryCode": "FR"
            },
            {
                "name": "Bouttencourt",
                "countryCode": "FR"
            },
            {
                "name": "Bouvignies",
                "countryCode": "FR"
            },
            {
                "name": "Bouvigny-Boyeffles",
                "countryCode": "FR"
            },
            {
                "name": "Boué",
                "countryCode": "FR"
            },
            {
                "name": "Boves",
                "countryCode": "FR"
            },
            {
                "name": "Braine",
                "countryCode": "FR"
            },
            {
                "name": "Brasles",
                "countryCode": "FR"
            },
            {
                "name": "Bray-Dunes",
                "countryCode": "FR"
            },
            {
                "name": "Bray-sur-Somme",
                "countryCode": "FR"
            },
            {
                "name": "Brebières",
                "countryCode": "FR"
            },
            {
                "name": "Brenouille",
                "countryCode": "FR"
            },
            {
                "name": "Bresles",
                "countryCode": "FR"
            },
            {
                "name": "Breteuil",
                "countryCode": "FR"
            },
            {
                "name": "Breuil-le-Sec",
                "countryCode": "FR"
            },
            {
                "name": "Breuil-le-Vert",
                "countryCode": "FR"
            },
            {
                "name": "Brouckerque",
                "countryCode": "FR"
            },
            {
                "name": "Bruay-la-Buissière",
                "countryCode": "FR"
            },
            {
                "name": "Bruay-sur-l’Escaut",
                "countryCode": "FR"
            },
            {
                "name": "Bruille-Saint-Amand",
                "countryCode": "FR"
            },
            {
                "name": "Bruille-lez-Marchiennes",
                "countryCode": "FR"
            },
            {
                "name": "Bruyères-et-Montbérault",
                "countryCode": "FR"
            },
            {
                "name": "Brêmes",
                "countryCode": "FR"
            },
            {
                "name": "Bucquoy",
                "countryCode": "FR"
            },
            {
                "name": "Bucy-le-Long",
                "countryCode": "FR"
            },
            {
                "name": "Buironfosse",
                "countryCode": "FR"
            },
            {
                "name": "Bully-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Burbure",
                "countryCode": "FR"
            },
            {
                "name": "Bury",
                "countryCode": "FR"
            },
            {
                "name": "Busigny",
                "countryCode": "FR"
            },
            {
                "name": "Busnes",
                "countryCode": "FR"
            },
            {
                "name": "Béthencourt-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Béthisy-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Béthisy-Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Béthune",
                "countryCode": "FR"
            },
            {
                "name": "Cagny",
                "countryCode": "FR"
            },
            {
                "name": "Calais",
                "countryCode": "FR"
            },
            {
                "name": "Calonne-Ricouart",
                "countryCode": "FR"
            },
            {
                "name": "Calonne-sur-la-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Camblain-Châtelain",
                "countryCode": "FR"
            },
            {
                "name": "Cambrai",
                "countryCode": "FR"
            },
            {
                "name": "Cambronne-lès-Clermont",
                "countryCode": "FR"
            },
            {
                "name": "Cambronne-lès-Ribécourt",
                "countryCode": "FR"
            },
            {
                "name": "Camiers",
                "countryCode": "FR"
            },
            {
                "name": "Camon",
                "countryCode": "FR"
            },
            {
                "name": "Campagne-lès-Hesdin",
                "countryCode": "FR"
            },
            {
                "name": "Camphin-en-Carembault",
                "countryCode": "FR"
            },
            {
                "name": "Camphin-en-Pévèle",
                "countryCode": "FR"
            },
            {
                "name": "Cantin",
                "countryCode": "FR"
            },
            {
                "name": "Capinghem",
                "countryCode": "FR"
            },
            {
                "name": "Cappelle-en-Pévèle",
                "countryCode": "FR"
            },
            {
                "name": "Cappelle-la-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Carlepont",
                "countryCode": "FR"
            },
            {
                "name": "Cartignies",
                "countryCode": "FR"
            },
            {
                "name": "Carvin",
                "countryCode": "FR"
            },
            {
                "name": "Cassel",
                "countryCode": "FR"
            },
            {
                "name": "Catenoy",
                "countryCode": "FR"
            },
            {
                "name": "Cauchy-à-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Caudry",
                "countryCode": "FR"
            },
            {
                "name": "Cauffry",
                "countryCode": "FR"
            },
            {
                "name": "Cauvigny",
                "countryCode": "FR"
            },
            {
                "name": "Cayeux-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Caëstre",
                "countryCode": "FR"
            },
            {
                "name": "Chamant",
                "countryCode": "FR"
            },
            {
                "name": "Chambly",
                "countryCode": "FR"
            },
            {
                "name": "Chantilly",
                "countryCode": "FR"
            },
            {
                "name": "Charly-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Charmes",
                "countryCode": "FR"
            },
            {
                "name": "Chaulnes",
                "countryCode": "FR"
            },
            {
                "name": "Chaumont-en-Vexin",
                "countryCode": "FR"
            },
            {
                "name": "Chauny",
                "countryCode": "FR"
            },
            {
                "name": "Chevrières",
                "countryCode": "FR"
            },
            {
                "name": "Chierry",
                "countryCode": "FR"
            },
            {
                "name": "Chiry-Ourscamp",
                "countryCode": "FR"
            },
            {
                "name": "Chocques",
                "countryCode": "FR"
            },
            {
                "name": "Choisy-au-Bac",
                "countryCode": "FR"
            },
            {
                "name": "Château-Thierry",
                "countryCode": "FR"
            },
            {
                "name": "Chépy",
                "countryCode": "FR"
            },
            {
                "name": "Chéreng",
                "countryCode": "FR"
            },
            {
                "name": "Chézy-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Cinqueux",
                "countryCode": "FR"
            },
            {
                "name": "Cires-lès-Mello",
                "countryCode": "FR"
            },
            {
                "name": "Clairoix",
                "countryCode": "FR"
            },
            {
                "name": "Clary",
                "countryCode": "FR"
            },
            {
                "name": "Clermont",
                "countryCode": "FR"
            },
            {
                "name": "Coincy",
                "countryCode": "FR"
            },
            {
                "name": "Colleret",
                "countryCode": "FR"
            },
            {
                "name": "Comines",
                "countryCode": "FR"
            },
            {
                "name": "Compiègne",
                "countryCode": "FR"
            },
            {
                "name": "Condette",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-l’Escaut",
                "countryCode": "FR"
            },
            {
                "name": "Conty",
                "countryCode": "FR"
            },
            {
                "name": "Coquelles",
                "countryCode": "FR"
            },
            {
                "name": "Corbehem",
                "countryCode": "FR"
            },
            {
                "name": "Corbie",
                "countryCode": "FR"
            },
            {
                "name": "Coucy-le-Château-Auffrique",
                "countryCode": "FR"
            },
            {
                "name": "Coudekerque-Branche",
                "countryCode": "FR"
            },
            {
                "name": "Coudekerque-Village",
                "countryCode": "FR"
            },
            {
                "name": "Coudun",
                "countryCode": "FR"
            },
            {
                "name": "Coulogne",
                "countryCode": "FR"
            },
            {
                "name": "Courcelles-lès-Lens",
                "countryCode": "FR"
            },
            {
                "name": "Courchelettes",
                "countryCode": "FR"
            },
            {
                "name": "Courmelles",
                "countryCode": "FR"
            },
            {
                "name": "Courrières",
                "countryCode": "FR"
            },
            {
                "name": "Cousolre",
                "countryCode": "FR"
            },
            {
                "name": "Coutiches",
                "countryCode": "FR"
            },
            {
                "name": "Couvron-et-Aumencourt",
                "countryCode": "FR"
            },
            {
                "name": "Coye-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Creil",
                "countryCode": "FR"
            },
            {
                "name": "Crespin",
                "countryCode": "FR"
            },
            {
                "name": "Crisolles",
                "countryCode": "FR"
            },
            {
                "name": "Croisilles",
                "countryCode": "FR"
            },
            {
                "name": "Croix",
                "countryCode": "FR"
            },
            {
                "name": "Crouy",
                "countryCode": "FR"
            },
            {
                "name": "Crouy-en-Thelle",
                "countryCode": "FR"
            },
            {
                "name": "Crèvecœur-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Crécy-en-Ponthieu",
                "countryCode": "FR"
            },
            {
                "name": "Crécy-sur-Serre",
                "countryCode": "FR"
            },
            {
                "name": "Crépy",
                "countryCode": "FR"
            },
            {
                "name": "Crépy-en-Valois",
                "countryCode": "FR"
            },
            {
                "name": "Crézancy",
                "countryCode": "FR"
            },
            {
                "name": "Cucq",
                "countryCode": "FR"
            },
            {
                "name": "Cuffies",
                "countryCode": "FR"
            },
            {
                "name": "Cuinchy",
                "countryCode": "FR"
            },
            {
                "name": "Cuincy",
                "countryCode": "FR"
            },
            {
                "name": "Cuise-la-Motte",
                "countryCode": "FR"
            },
            {
                "name": "Curgies",
                "countryCode": "FR"
            },
            {
                "name": "Cysoing",
                "countryCode": "FR"
            },
            {
                "name": "Dainville",
                "countryCode": "FR"
            },
            {
                "name": "Dannes",
                "countryCode": "FR"
            },
            {
                "name": "Dargnies",
                "countryCode": "FR"
            },
            {
                "name": "Dechy",
                "countryCode": "FR"
            },
            {
                "name": "Denain",
                "countryCode": "FR"
            },
            {
                "name": "Desvres",
                "countryCode": "FR"
            },
            {
                "name": "Deûlémont",
                "countryCode": "FR"
            },
            {
                "name": "Divion",
                "countryCode": "FR"
            },
            {
                "name": "Doingt",
                "countryCode": "FR"
            },
            {
                "name": "Domart-en-Ponthieu",
                "countryCode": "FR"
            },
            {
                "name": "Don",
                "countryCode": "FR"
            },
            {
                "name": "Douai",
                "countryCode": "FR"
            },
            {
                "name": "Douchy-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Doullens",
                "countryCode": "FR"
            },
            {
                "name": "Dourges",
                "countryCode": "FR"
            },
            {
                "name": "Douvrin",
                "countryCode": "FR"
            },
            {
                "name": "Dreslincourt",
                "countryCode": "FR"
            },
            {
                "name": "Dreuil-lès-Amiens",
                "countryCode": "FR"
            },
            {
                "name": "Drocourt",
                "countryCode": "FR"
            },
            {
                "name": "Duisans",
                "countryCode": "FR"
            },
            {
                "name": "Dunkerque",
                "countryCode": "FR"
            },
            {
                "name": "Dury",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Aisne",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Oise",
                "countryCode": "FR"
            },
            {
                "name": "Ecques",
                "countryCode": "FR"
            },
            {
                "name": "Emmerin",
                "countryCode": "FR"
            },
            {
                "name": "Englefontaine",
                "countryCode": "FR"
            },
            {
                "name": "Ennetières-en-Weppes",
                "countryCode": "FR"
            },
            {
                "name": "Ennevelin",
                "countryCode": "FR"
            },
            {
                "name": "Eppeville",
                "countryCode": "FR"
            },
            {
                "name": "Ercuis",
                "countryCode": "FR"
            },
            {
                "name": "Erquinghem-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Erre",
                "countryCode": "FR"
            },
            {
                "name": "Escaudain",
                "countryCode": "FR"
            },
            {
                "name": "Escaudœuvres",
                "countryCode": "FR"
            },
            {
                "name": "Escautpont",
                "countryCode": "FR"
            },
            {
                "name": "Esches",
                "countryCode": "FR"
            },
            {
                "name": "Esquelbecq",
                "countryCode": "FR"
            },
            {
                "name": "Esquerdes",
                "countryCode": "FR"
            },
            {
                "name": "Esquéhéries",
                "countryCode": "FR"
            },
            {
                "name": "Essars",
                "countryCode": "FR"
            },
            {
                "name": "Essigny-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Essômes-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Estaires",
                "countryCode": "FR"
            },
            {
                "name": "Estevelles",
                "countryCode": "FR"
            },
            {
                "name": "Estrées",
                "countryCode": "FR"
            },
            {
                "name": "Estrées-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Faches-Thumesnil",
                "countryCode": "FR"
            },
            {
                "name": "Famars",
                "countryCode": "FR"
            },
            {
                "name": "Fampoux",
                "countryCode": "FR"
            },
            {
                "name": "Faumont",
                "countryCode": "FR"
            },
            {
                "name": "Feignies",
                "countryCode": "FR"
            },
            {
                "name": "Fenain",
                "countryCode": "FR"
            },
            {
                "name": "Ferques",
                "countryCode": "FR"
            },
            {
                "name": "Ferrière-la-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Festubert",
                "countryCode": "FR"
            },
            {
                "name": "Feuchy",
                "countryCode": "FR"
            },
            {
                "name": "Feuquières",
                "countryCode": "FR"
            },
            {
                "name": "Feuquières-en-Vimeu",
                "countryCode": "FR"
            },
            {
                "name": "Fitz-James",
                "countryCode": "FR"
            },
            {
                "name": "Flavy-le-Martel",
                "countryCode": "FR"
            },
            {
                "name": "Flers-en-Escrebieux",
                "countryCode": "FR"
            },
            {
                "name": "Flesselles",
                "countryCode": "FR"
            },
            {
                "name": "Fleurbaix",
                "countryCode": "FR"
            },
            {
                "name": "Fleurines",
                "countryCode": "FR"
            },
            {
                "name": "Flines-lez-Raches",
                "countryCode": "FR"
            },
            {
                "name": "Flines-lès-Mortagne",
                "countryCode": "FR"
            },
            {
                "name": "Flixecourt",
                "countryCode": "FR"
            },
            {
                "name": "Folembray",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-Notre-Dame",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-au-Pire",
                "countryCode": "FR"
            },
            {
                "name": "Forest-sur-Marque",
                "countryCode": "FR"
            },
            {
                "name": "Formerie",
                "countryCode": "FR"
            },
            {
                "name": "Fort-Mahon-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Fort-Mardyck",
                "countryCode": "FR"
            },
            {
                "name": "Fouquereuil",
                "countryCode": "FR"
            },
            {
                "name": "Fouquières-lès-Béthune",
                "countryCode": "FR"
            },
            {
                "name": "Fouquières-lès-Lens",
                "countryCode": "FR"
            },
            {
                "name": "Fourmies",
                "countryCode": "FR"
            },
            {
                "name": "Fournes-en-Weppes",
                "countryCode": "FR"
            },
            {
                "name": "Frelinghien",
                "countryCode": "FR"
            },
            {
                "name": "Fresnes-sur-Escaut",
                "countryCode": "FR"
            },
            {
                "name": "Fresnoy-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Fressenneville",
                "countryCode": "FR"
            },
            {
                "name": "Fretin",
                "countryCode": "FR"
            },
            {
                "name": "Friville-Escarbotin",
                "countryCode": "FR"
            },
            {
                "name": "Fruges",
                "countryCode": "FR"
            },
            {
                "name": "Fréthun",
                "countryCode": "FR"
            },
            {
                "name": "Frévent",
                "countryCode": "FR"
            },
            {
                "name": "Fère-en-Tardenois",
                "countryCode": "FR"
            },
            {
                "name": "Féchain",
                "countryCode": "FR"
            },
            {
                "name": "Férin",
                "countryCode": "FR"
            },
            {
                "name": "Gamaches",
                "countryCode": "FR"
            },
            {
                "name": "Gauchy",
                "countryCode": "FR"
            },
            {
                "name": "Genech",
                "countryCode": "FR"
            },
            {
                "name": "Ghyvelde",
                "countryCode": "FR"
            },
            {
                "name": "Givenchy-en-Gohelle",
                "countryCode": "FR"
            },
            {
                "name": "Godewaersvelde",
                "countryCode": "FR"
            },
            {
                "name": "Goincourt",
                "countryCode": "FR"
            },
            {
                "name": "Gommegnies",
                "countryCode": "FR"
            },
            {
                "name": "Gondecourt",
                "countryCode": "FR"
            },
            {
                "name": "Gonnehem",
                "countryCode": "FR"
            },
            {
                "name": "Gosnay",
                "countryCode": "FR"
            },
            {
                "name": "Gouvieux",
                "countryCode": "FR"
            },
            {
                "name": "Gouy-sous-Bellonne",
                "countryCode": "FR"
            },
            {
                "name": "Gouzeaucourt",
                "countryCode": "FR"
            },
            {
                "name": "Grand-Fort-Philippe",
                "countryCode": "FR"
            },
            {
                "name": "Grande-Synthe",
                "countryCode": "FR"
            },
            {
                "name": "Grandfresnoy",
                "countryCode": "FR"
            },
            {
                "name": "Grandvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Gravelines",
                "countryCode": "FR"
            },
            {
                "name": "Grenay",
                "countryCode": "FR"
            },
            {
                "name": "Groffliers",
                "countryCode": "FR"
            },
            {
                "name": "Gruson",
                "countryCode": "FR"
            },
            {
                "name": "Guarbecque",
                "countryCode": "FR"
            },
            {
                "name": "Guesnain",
                "countryCode": "FR"
            },
            {
                "name": "Guignicourt",
                "countryCode": "FR"
            },
            {
                "name": "Guiscard",
                "countryCode": "FR"
            },
            {
                "name": "Guise",
                "countryCode": "FR"
            },
            {
                "name": "Guînes",
                "countryCode": "FR"
            },
            {
                "name": "Gœulzin",
                "countryCode": "FR"
            },
            {
                "name": "Haillicourt",
                "countryCode": "FR"
            },
            {
                "name": "Haisnes",
                "countryCode": "FR"
            },
            {
                "name": "Hallencourt",
                "countryCode": "FR"
            },
            {
                "name": "Hallennes-lez-Haubourdin",
                "countryCode": "FR"
            },
            {
                "name": "Hallines",
                "countryCode": "FR"
            },
            {
                "name": "Halluin",
                "countryCode": "FR"
            },
            {
                "name": "Ham",
                "countryCode": "FR"
            },
            {
                "name": "Hames-Boucres",
                "countryCode": "FR"
            },
            {
                "name": "Harbonnières",
                "countryCode": "FR"
            },
            {
                "name": "Hardinghen",
                "countryCode": "FR"
            },
            {
                "name": "Harly",
                "countryCode": "FR"
            },
            {
                "name": "Harnes",
                "countryCode": "FR"
            },
            {
                "name": "Hasnon",
                "countryCode": "FR"
            },
            {
                "name": "Haspres",
                "countryCode": "FR"
            },
            {
                "name": "Haubourdin",
                "countryCode": "FR"
            },
            {
                "name": "Haulchin",
                "countryCode": "FR"
            },
            {
                "name": "Haussy",
                "countryCode": "FR"
            },
            {
                "name": "Hautmont",
                "countryCode": "FR"
            },
            {
                "name": "Haveluy",
                "countryCode": "FR"
            },
            {
                "name": "Haverskerque",
                "countryCode": "FR"
            },
            {
                "name": "Hazebrouck",
                "countryCode": "FR"
            },
            {
                "name": "Helfaut",
                "countryCode": "FR"
            },
            {
                "name": "Hem",
                "countryCode": "FR"
            },
            {
                "name": "Hergnies",
                "countryCode": "FR"
            },
            {
                "name": "Herlies",
                "countryCode": "FR"
            },
            {
                "name": "Hermes",
                "countryCode": "FR"
            },
            {
                "name": "Hermies",
                "countryCode": "FR"
            },
            {
                "name": "Hersin-Coupigny",
                "countryCode": "FR"
            },
            {
                "name": "Herzeele",
                "countryCode": "FR"
            },
            {
                "name": "Hesdin",
                "countryCode": "FR"
            },
            {
                "name": "Heuringhem",
                "countryCode": "FR"
            },
            {
                "name": "Hinges",
                "countryCode": "FR"
            },
            {
                "name": "Hirson",
                "countryCode": "FR"
            },
            {
                "name": "Holnon",
                "countryCode": "FR"
            },
            {
                "name": "Hombleux",
                "countryCode": "FR"
            },
            {
                "name": "Homblières",
                "countryCode": "FR"
            },
            {
                "name": "Hondschoote",
                "countryCode": "FR"
            },
            {
                "name": "Hordain",
                "countryCode": "FR"
            },
            {
                "name": "Hornaing",
                "countryCode": "FR"
            },
            {
                "name": "Hornoy-le-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Houdain",
                "countryCode": "FR"
            },
            {
                "name": "Houplin-Ancoisne",
                "countryCode": "FR"
            },
            {
                "name": "Houplines",
                "countryCode": "FR"
            },
            {
                "name": "Hoymille",
                "countryCode": "FR"
            },
            {
                "name": "Hulluch",
                "countryCode": "FR"
            },
            {
                "name": "Hélesmes",
                "countryCode": "FR"
            },
            {
                "name": "Hénin-Beaumont",
                "countryCode": "FR"
            },
            {
                "name": "Hérin",
                "countryCode": "FR"
            },
            {
                "name": "Illies",
                "countryCode": "FR"
            },
            {
                "name": "Isbergues",
                "countryCode": "FR"
            },
            {
                "name": "Isques",
                "countryCode": "FR"
            },
            {
                "name": "Itancourt",
                "countryCode": "FR"
            },
            {
                "name": "Iwuy",
                "countryCode": "FR"
            },
            {
                "name": "Jaux",
                "countryCode": "FR"
            },
            {
                "name": "Jenlain",
                "countryCode": "FR"
            },
            {
                "name": "Jeumont",
                "countryCode": "FR"
            },
            {
                "name": "Jussy",
                "countryCode": "FR"
            },
            {
                "name": "Killem",
                "countryCode": "FR"
            },
            {
                "name": "La Bassée",
                "countryCode": "FR"
            },
            {
                "name": "La Capelle",
                "countryCode": "FR"
            },
            {
                "name": "La Capelle-lès-Boulogne",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-en-Serval",
                "countryCode": "FR"
            },
            {
                "name": "La Couture",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Milon",
                "countryCode": "FR"
            },
            {
                "name": "La Flamengrie",
                "countryCode": "FR"
            },
            {
                "name": "La Fère",
                "countryCode": "FR"
            },
            {
                "name": "La Gorgue",
                "countryCode": "FR"
            },
            {
                "name": "La Longueville",
                "countryCode": "FR"
            },
            {
                "name": "La Madeleine",
                "countryCode": "FR"
            },
            {
                "name": "La Sentinelle",
                "countryCode": "FR"
            },
            {
                "name": "Labeuvrière",
                "countryCode": "FR"
            },
            {
                "name": "Laboissière-en-Thelle",
                "countryCode": "FR"
            },
            {
                "name": "Labourse",
                "countryCode": "FR"
            },
            {
                "name": "Lachapelle-aux-Pots",
                "countryCode": "FR"
            },
            {
                "name": "Lacroix-Saint-Ouen",
                "countryCode": "FR"
            },
            {
                "name": "Lagny-le-Sec",
                "countryCode": "FR"
            },
            {
                "name": "Laigneville",
                "countryCode": "FR"
            },
            {
                "name": "Lallaing",
                "countryCode": "FR"
            },
            {
                "name": "Lambersart",
                "countryCode": "FR"
            },
            {
                "name": "Lambres-lez-Douai",
                "countryCode": "FR"
            },
            {
                "name": "Lamorlaye",
                "countryCode": "FR"
            },
            {
                "name": "Landas",
                "countryCode": "FR"
            },
            {
                "name": "Landrecies",
                "countryCode": "FR"
            },
            {
                "name": "Lannoy",
                "countryCode": "FR"
            },
            {
                "name": "Laon",
                "countryCode": "FR"
            },
            {
                "name": "Lapugnoy",
                "countryCode": "FR"
            },
            {
                "name": "Lassigny",
                "countryCode": "FR"
            },
            {
                "name": "Lauwin-Planque",
                "countryCode": "FR"
            },
            {
                "name": "Laventie",
                "countryCode": "FR"
            },
            {
                "name": "Le Crotoy",
                "countryCode": "FR"
            },
            {
                "name": "Le Doulieu",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-en-Thelle",
                "countryCode": "FR"
            },
            {
                "name": "Le Meux",
                "countryCode": "FR"
            },
            {
                "name": "Le Nouvion-en-Thiérache",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Belleville",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Brion",
                "countryCode": "FR"
            },
            {
                "name": "Le Portel",
                "countryCode": "FR"
            },
            {
                "name": "Le Quesnoy",
                "countryCode": "FR"
            },
            {
                "name": "Le Touquet-Paris-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Lecelles",
                "countryCode": "FR"
            },
            {
                "name": "Leers",
                "countryCode": "FR"
            },
            {
                "name": "Leffrinckoucke",
                "countryCode": "FR"
            },
            {
                "name": "Leforest",
                "countryCode": "FR"
            },
            {
                "name": "Lens",
                "countryCode": "FR"
            },
            {
                "name": "Les Ageux",
                "countryCode": "FR"
            },
            {
                "name": "Les Attaques",
                "countryCode": "FR"
            },
            {
                "name": "Lesquin",
                "countryCode": "FR"
            },
            {
                "name": "Lestrem",
                "countryCode": "FR"
            },
            {
                "name": "Leval",
                "countryCode": "FR"
            },
            {
                "name": "Lewarde",
                "countryCode": "FR"
            },
            {
                "name": "Lezennes",
                "countryCode": "FR"
            },
            {
                "name": "Liancourt",
                "countryCode": "FR"
            },
            {
                "name": "Libercourt",
                "countryCode": "FR"
            },
            {
                "name": "Licques",
                "countryCode": "FR"
            },
            {
                "name": "Liesse-Notre-Dame",
                "countryCode": "FR"
            },
            {
                "name": "Lieu-Saint-Amand",
                "countryCode": "FR"
            },
            {
                "name": "Ligny-en-Cambrésis",
                "countryCode": "FR"
            },
            {
                "name": "Lille",
                "countryCode": "FR"
            },
            {
                "name": "Lillers",
                "countryCode": "FR"
            },
            {
                "name": "Linselles",
                "countryCode": "FR"
            },
            {
                "name": "Liévin",
                "countryCode": "FR"
            },
            {
                "name": "Locon",
                "countryCode": "FR"
            },
            {
                "name": "Loison-sous-Lens",
                "countryCode": "FR"
            },
            {
                "name": "Lomme",
                "countryCode": "FR"
            },
            {
                "name": "Lompret",
                "countryCode": "FR"
            },
            {
                "name": "Longfossé",
                "countryCode": "FR"
            },
            {
                "name": "Longpré-les-Corps-Saints",
                "countryCode": "FR"
            },
            {
                "name": "Longueau",
                "countryCode": "FR"
            },
            {
                "name": "Longueil-Annel",
                "countryCode": "FR"
            },
            {
                "name": "Longueil-Sainte-Marie",
                "countryCode": "FR"
            },
            {
                "name": "Longuenesse",
                "countryCode": "FR"
            },
            {
                "name": "Looberghe",
                "countryCode": "FR"
            },
            {
                "name": "Loon-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Loos",
                "countryCode": "FR"
            },
            {
                "name": "Loos-en-Gohelle",
                "countryCode": "FR"
            },
            {
                "name": "Lorgies",
                "countryCode": "FR"
            },
            {
                "name": "Lormaison",
                "countryCode": "FR"
            },
            {
                "name": "Lourches",
                "countryCode": "FR"
            },
            {
                "name": "Louvroil",
                "countryCode": "FR"
            },
            {
                "name": "Lozinghem",
                "countryCode": "FR"
            },
            {
                "name": "Lumbres",
                "countryCode": "FR"
            },
            {
                "name": "Lys-lez-Lannoy",
                "countryCode": "FR"
            },
            {
                "name": "Lécluse",
                "countryCode": "FR"
            },
            {
                "name": "Maignelay-Montigny",
                "countryCode": "FR"
            },
            {
                "name": "Maing",
                "countryCode": "FR"
            },
            {
                "name": "Maisnil-lès-Ruitz",
                "countryCode": "FR"
            },
            {
                "name": "Mametz",
                "countryCode": "FR"
            },
            {
                "name": "Marchiennes",
                "countryCode": "FR"
            },
            {
                "name": "Marck",
                "countryCode": "FR"
            },
            {
                "name": "Marcoing",
                "countryCode": "FR"
            },
            {
                "name": "Marconne",
                "countryCode": "FR"
            },
            {
                "name": "Marconnelle",
                "countryCode": "FR"
            },
            {
                "name": "Marcq-en-Barœul",
                "countryCode": "FR"
            },
            {
                "name": "Maretz",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-sur-Ourcq",
                "countryCode": "FR"
            },
            {
                "name": "Margny-lès-Compiègne",
                "countryCode": "FR"
            },
            {
                "name": "Marle",
                "countryCode": "FR"
            },
            {
                "name": "Marles-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Marly",
                "countryCode": "FR"
            },
            {
                "name": "Maroilles",
                "countryCode": "FR"
            },
            {
                "name": "Marpent",
                "countryCode": "FR"
            },
            {
                "name": "Marquette-en-Ostrevant",
                "countryCode": "FR"
            },
            {
                "name": "Marquette-lez-Lille",
                "countryCode": "FR"
            },
            {
                "name": "Marquillies",
                "countryCode": "FR"
            },
            {
                "name": "Marquion",
                "countryCode": "FR"
            },
            {
                "name": "Marquise",
                "countryCode": "FR"
            },
            {
                "name": "Marœuil",
                "countryCode": "FR"
            },
            {
                "name": "Masnières",
                "countryCode": "FR"
            },
            {
                "name": "Masny",
                "countryCode": "FR"
            },
            {
                "name": "Maubeuge",
                "countryCode": "FR"
            },
            {
                "name": "Mazingarbe",
                "countryCode": "FR"
            },
            {
                "name": "Merlimont",
                "countryCode": "FR"
            },
            {
                "name": "Mers-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Merville",
                "countryCode": "FR"
            },
            {
                "name": "Meurchin",
                "countryCode": "FR"
            },
            {
                "name": "Milly-sur-Thérain",
                "countryCode": "FR"
            },
            {
                "name": "Mogneville",
                "countryCode": "FR"
            },
            {
                "name": "Moislains",
                "countryCode": "FR"
            },
            {
                "name": "Monchecourt",
                "countryCode": "FR"
            },
            {
                "name": "Monchy-Saint-Éloi",
                "countryCode": "FR"
            },
            {
                "name": "Mons-en-Barœul",
                "countryCode": "FR"
            },
            {
                "name": "Mons-en-Pévèle",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Bernanchon",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Saint-Éloi",
                "countryCode": "FR"
            },
            {
                "name": "Montataire",
                "countryCode": "FR"
            },
            {
                "name": "Montcornet",
                "countryCode": "FR"
            },
            {
                "name": "Montdidier",
                "countryCode": "FR"
            },
            {
                "name": "Montescourt-Lizerolles",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-en-Gohelle",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-en-Ostrevent",
                "countryCode": "FR"
            },
            {
                "name": "Montmacq",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil-aux-Lions",
                "countryCode": "FR"
            },
            {
                "name": "Morbecque",
                "countryCode": "FR"
            },
            {
                "name": "Moreuil",
                "countryCode": "FR"
            },
            {
                "name": "Morienval",
                "countryCode": "FR"
            },
            {
                "name": "Mortagne-du-Nord",
                "countryCode": "FR"
            },
            {
                "name": "Mouchin",
                "countryCode": "FR"
            },
            {
                "name": "Mouvaux",
                "countryCode": "FR"
            },
            {
                "name": "Mouy",
                "countryCode": "FR"
            },
            {
                "name": "Méaulte",
                "countryCode": "FR"
            },
            {
                "name": "Méricourt",
                "countryCode": "FR"
            },
            {
                "name": "Mérignies",
                "countryCode": "FR"
            },
            {
                "name": "Méru",
                "countryCode": "FR"
            },
            {
                "name": "Méteren",
                "countryCode": "FR"
            },
            {
                "name": "Nanteuil-le-Haudouin",
                "countryCode": "FR"
            },
            {
                "name": "Naours",
                "countryCode": "FR"
            },
            {
                "name": "Nesle",
                "countryCode": "FR"
            },
            {
                "name": "Nesles",
                "countryCode": "FR"
            },
            {
                "name": "Nesles-la-Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Neuf-Berquin",
                "countryCode": "FR"
            },
            {
                "name": "Neufchâtel-Hardelot",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-Saint-Front",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-en-Thelle",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-sous-Clermont",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-Saint-Rémy",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-Saint-Vaast",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-en-Ferrain",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-sur-Escaut",
                "countryCode": "FR"
            },
            {
                "name": "Nieppe",
                "countryCode": "FR"
            },
            {
                "name": "Noailles",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-l’Artaud",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Nogentel",
                "countryCode": "FR"
            },
            {
                "name": "Nomain",
                "countryCode": "FR"
            },
            {
                "name": "Nord",
                "countryCode": "FR"
            },
            {
                "name": "Norrent-Fontes",
                "countryCode": "FR"
            },
            {
                "name": "Nortkerque",
                "countryCode": "FR"
            },
            {
                "name": "Nouvion",
                "countryCode": "FR"
            },
            {
                "name": "Noyelles-Godault",
                "countryCode": "FR"
            },
            {
                "name": "Noyelles-lès-Vermelles",
                "countryCode": "FR"
            },
            {
                "name": "Noyelles-sous-Lens",
                "countryCode": "FR"
            },
            {
                "name": "Noyon",
                "countryCode": "FR"
            },
            {
                "name": "Nœux-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Ognes",
                "countryCode": "FR"
            },
            {
                "name": "Oignies",
                "countryCode": "FR"
            },
            {
                "name": "Oisemont",
                "countryCode": "FR"
            },
            {
                "name": "Oisy-le-Verger",
                "countryCode": "FR"
            },
            {
                "name": "Omissy",
                "countryCode": "FR"
            },
            {
                "name": "Onnaing",
                "countryCode": "FR"
            },
            {
                "name": "Ons-en-Bray",
                "countryCode": "FR"
            },
            {
                "name": "Orchies",
                "countryCode": "FR"
            },
            {
                "name": "Origny-Sainte-Benoite",
                "countryCode": "FR"
            },
            {
                "name": "Origny-en-Thiérache",
                "countryCode": "FR"
            },
            {
                "name": "Orry-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Ostricourt",
                "countryCode": "FR"
            },
            {
                "name": "Outreau",
                "countryCode": "FR"
            },
            {
                "name": "Oye-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Pas-de-Calais",
                "countryCode": "FR"
            },
            {
                "name": "Pasly",
                "countryCode": "FR"
            },
            {
                "name": "Pecquencourt",
                "countryCode": "FR"
            },
            {
                "name": "Pendé",
                "countryCode": "FR"
            },
            {
                "name": "Pernes",
                "countryCode": "FR"
            },
            {
                "name": "Petite-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Phalempin",
                "countryCode": "FR"
            },
            {
                "name": "Picquigny",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefonds",
                "countryCode": "FR"
            },
            {
                "name": "Pinon",
                "countryCode": "FR"
            },
            {
                "name": "Plailly",
                "countryCode": "FR"
            },
            {
                "name": "Poix-de-Picardie",
                "countryCode": "FR"
            },
            {
                "name": "Poix-du-Nord",
                "countryCode": "FR"
            },
            {
                "name": "Ponchon",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Remy",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Sainte-Maxence",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Metz",
                "countryCode": "FR"
            },
            {
                "name": "Pont-sur-Sambre",
                "countryCode": "FR"
            },
            {
                "name": "Pont-à-Marcq",
                "countryCode": "FR"
            },
            {
                "name": "Pont-à-Vendin",
                "countryCode": "FR"
            },
            {
                "name": "Pontpoint",
                "countryCode": "FR"
            },
            {
                "name": "Poulainville",
                "countryCode": "FR"
            },
            {
                "name": "Prouvy",
                "countryCode": "FR"
            },
            {
                "name": "Proville",
                "countryCode": "FR"
            },
            {
                "name": "Provin",
                "countryCode": "FR"
            },
            {
                "name": "Précy-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Prémesques",
                "countryCode": "FR"
            },
            {
                "name": "Préseau",
                "countryCode": "FR"
            },
            {
                "name": "Pérenchies",
                "countryCode": "FR"
            },
            {
                "name": "Péronne",
                "countryCode": "FR"
            },
            {
                "name": "Péroy-les-Gombries",
                "countryCode": "FR"
            },
            {
                "name": "Quarouble",
                "countryCode": "FR"
            },
            {
                "name": "Quaëdypre",
                "countryCode": "FR"
            },
            {
                "name": "Quend",
                "countryCode": "FR"
            },
            {
                "name": "Quesnoy-sur-Deûle",
                "countryCode": "FR"
            },
            {
                "name": "Quevauvillers",
                "countryCode": "FR"
            },
            {
                "name": "Quiévrechain",
                "countryCode": "FR"
            },
            {
                "name": "Quiévy",
                "countryCode": "FR"
            },
            {
                "name": "Racquinghem",
                "countryCode": "FR"
            },
            {
                "name": "Radinghem-en-Weppes",
                "countryCode": "FR"
            },
            {
                "name": "Raillencourt-Sainte-Olle",
                "countryCode": "FR"
            },
            {
                "name": "Raimbeaucourt",
                "countryCode": "FR"
            },
            {
                "name": "Raismes",
                "countryCode": "FR"
            },
            {
                "name": "Ranchicourt",
                "countryCode": "FR"
            },
            {
                "name": "Rang-du-Fliers",
                "countryCode": "FR"
            },
            {
                "name": "Rantigny",
                "countryCode": "FR"
            },
            {
                "name": "Ravenel",
                "countryCode": "FR"
            },
            {
                "name": "Recquignies",
                "countryCode": "FR"
            },
            {
                "name": "Remy",
                "countryCode": "FR"
            },
            {
                "name": "Renescure",
                "countryCode": "FR"
            },
            {
                "name": "Ressons-sur-Matz",
                "countryCode": "FR"
            },
            {
                "name": "Rety",
                "countryCode": "FR"
            },
            {
                "name": "Rexpoëde",
                "countryCode": "FR"
            },
            {
                "name": "Ribemont",
                "countryCode": "FR"
            },
            {
                "name": "Richebourg",
                "countryCode": "FR"
            },
            {
                "name": "Richebourg-l'Avoué",
                "countryCode": "FR"
            },
            {
                "name": "Rieulay",
                "countryCode": "FR"
            },
            {
                "name": "Rieux",
                "countryCode": "FR"
            },
            {
                "name": "Rieux-en-Cambrésis",
                "countryCode": "FR"
            },
            {
                "name": "Rinxent",
                "countryCode": "FR"
            },
            {
                "name": "Rivery",
                "countryCode": "FR"
            },
            {
                "name": "Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Robecq",
                "countryCode": "FR"
            },
            {
                "name": "Roisel",
                "countryCode": "FR"
            },
            {
                "name": "Ronchin",
                "countryCode": "FR"
            },
            {
                "name": "Roncq",
                "countryCode": "FR"
            },
            {
                "name": "Roost-Warendin",
                "countryCode": "FR"
            },
            {
                "name": "Roquetoire",
                "countryCode": "FR"
            },
            {
                "name": "Rosières-en-Santerre",
                "countryCode": "FR"
            },
            {
                "name": "Rosult",
                "countryCode": "FR"
            },
            {
                "name": "Roubaix",
                "countryCode": "FR"
            },
            {
                "name": "Rousies",
                "countryCode": "FR"
            },
            {
                "name": "Rouvroy",
                "countryCode": "FR"
            },
            {
                "name": "Roye",
                "countryCode": "FR"
            },
            {
                "name": "Rozoy-sur-Serre",
                "countryCode": "FR"
            },
            {
                "name": "Rue",
                "countryCode": "FR"
            },
            {
                "name": "Ruitz",
                "countryCode": "FR"
            },
            {
                "name": "Rumegies",
                "countryCode": "FR"
            },
            {
                "name": "Rumilly-en-Cambrésis",
                "countryCode": "FR"
            },
            {
                "name": "Ruminghem",
                "countryCode": "FR"
            },
            {
                "name": "Râches",
                "countryCode": "FR"
            },
            {
                "name": "Rœulx",
                "countryCode": "FR"
            },
            {
                "name": "Rœux",
                "countryCode": "FR"
            },
            {
                "name": "Sacy-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Sailly-Flibeaucourt",
                "countryCode": "FR"
            },
            {
                "name": "Sailly-Labourse",
                "countryCode": "FR"
            },
            {
                "name": "Sailly-lez-Lannoy",
                "countryCode": "FR"
            },
            {
                "name": "Sailly-sur-la-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Sainghin-en-Mélantois",
                "countryCode": "FR"
            },
            {
                "name": "Sainghin-en-Weppes",
                "countryCode": "FR"
            },
            {
                "name": "Sains-du-Nord",
                "countryCode": "FR"
            },
            {
                "name": "Sains-en-Amiénois",
                "countryCode": "FR"
            },
            {
                "name": "Sains-en-Gohelle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand-les-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-lez-Lille",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-en-Bray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Crépin-Ibouvillers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Erme-Outre-et-Ramecourt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Folquin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germer-de-Fly",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gobain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-lez-Cambrai",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jans-Cappel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Josse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-en-Chaussée",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-Blangy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-lès-Domart",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Boulogne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Longueau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-au-Laërt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maximin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Omer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Omer-en-Chaussée",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pol-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pol-sur-Ternoise",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Python",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Riquier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saulve",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Souplet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sylvestre-Cappel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Valery-sur-Somme",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Venant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-au-Mont",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Catherine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Geneviève",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-Kerque",
                "countryCode": "FR"
            },
            {
                "name": "Saleux",
                "countryCode": "FR"
            },
            {
                "name": "Sallaumines",
                "countryCode": "FR"
            },
            {
                "name": "Salomé",
                "countryCode": "FR"
            },
            {
                "name": "Salouël",
                "countryCode": "FR"
            },
            {
                "name": "Samer",
                "countryCode": "FR"
            },
            {
                "name": "Saméon",
                "countryCode": "FR"
            },
            {
                "name": "Sangatte",
                "countryCode": "FR"
            },
            {
                "name": "Santes",
                "countryCode": "FR"
            },
            {
                "name": "Sars-Poteries",
                "countryCode": "FR"
            },
            {
                "name": "Saultain",
                "countryCode": "FR"
            },
            {
                "name": "Saulzoir",
                "countryCode": "FR"
            },
            {
                "name": "Savy-Berlette",
                "countryCode": "FR"
            },
            {
                "name": "Seboncourt",
                "countryCode": "FR"
            },
            {
                "name": "Sebourg",
                "countryCode": "FR"
            },
            {
                "name": "Seclin",
                "countryCode": "FR"
            },
            {
                "name": "Selvigny",
                "countryCode": "FR"
            },
            {
                "name": "Senlis",
                "countryCode": "FR"
            },
            {
                "name": "Sequedin",
                "countryCode": "FR"
            },
            {
                "name": "Serques",
                "countryCode": "FR"
            },
            {
                "name": "Silly-le-Long",
                "countryCode": "FR"
            },
            {
                "name": "Sin-le-Noble",
                "countryCode": "FR"
            },
            {
                "name": "Sinceny",
                "countryCode": "FR"
            },
            {
                "name": "Sissonne",
                "countryCode": "FR"
            },
            {
                "name": "Socx",
                "countryCode": "FR"
            },
            {
                "name": "Soissons",
                "countryCode": "FR"
            },
            {
                "name": "Solesmes",
                "countryCode": "FR"
            },
            {
                "name": "Solre-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Somain",
                "countryCode": "FR"
            },
            {
                "name": "Somme",
                "countryCode": "FR"
            },
            {
                "name": "Songeons",
                "countryCode": "FR"
            },
            {
                "name": "Souchez",
                "countryCode": "FR"
            },
            {
                "name": "Spycker",
                "countryCode": "FR"
            },
            {
                "name": "Steenbecque",
                "countryCode": "FR"
            },
            {
                "name": "Steene",
                "countryCode": "FR"
            },
            {
                "name": "Steenvoorde",
                "countryCode": "FR"
            },
            {
                "name": "Steenwerck",
                "countryCode": "FR"
            },
            {
                "name": "Sérifontaine",
                "countryCode": "FR"
            },
            {
                "name": "Talmas",
                "countryCode": "FR"
            },
            {
                "name": "Tatinghem",
                "countryCode": "FR"
            },
            {
                "name": "Templemars",
                "countryCode": "FR"
            },
            {
                "name": "Templeuve-en-Pévèle",
                "countryCode": "FR"
            },
            {
                "name": "Tergnier",
                "countryCode": "FR"
            },
            {
                "name": "Thiant",
                "countryCode": "FR"
            },
            {
                "name": "Thiers-sur-Thève",
                "countryCode": "FR"
            },
            {
                "name": "Thiverny",
                "countryCode": "FR"
            },
            {
                "name": "Thourotte",
                "countryCode": "FR"
            },
            {
                "name": "Thumeries",
                "countryCode": "FR"
            },
            {
                "name": "Thun-Saint-Amand",
                "countryCode": "FR"
            },
            {
                "name": "Thélus",
                "countryCode": "FR"
            },
            {
                "name": "Thérouanne",
                "countryCode": "FR"
            },
            {
                "name": "Tilloy-lès-Mofflaines",
                "countryCode": "FR"
            },
            {
                "name": "Tillé",
                "countryCode": "FR"
            },
            {
                "name": "Tilques",
                "countryCode": "FR"
            },
            {
                "name": "Toufflers",
                "countryCode": "FR"
            },
            {
                "name": "Tourcoing",
                "countryCode": "FR"
            },
            {
                "name": "Tournehem-sur-la-Hem",
                "countryCode": "FR"
            },
            {
                "name": "Tracy-le-Mont",
                "countryCode": "FR"
            },
            {
                "name": "Tressin",
                "countryCode": "FR"
            },
            {
                "name": "Tricot",
                "countryCode": "FR"
            },
            {
                "name": "Trie-Château",
                "countryCode": "FR"
            },
            {
                "name": "Trith-Saint-Léger",
                "countryCode": "FR"
            },
            {
                "name": "Troissereux",
                "countryCode": "FR"
            },
            {
                "name": "Trosly-Breuil",
                "countryCode": "FR"
            },
            {
                "name": "Trélon",
                "countryCode": "FR"
            },
            {
                "name": "Téteghem",
                "countryCode": "FR"
            },
            {
                "name": "Ully-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Uxem",
                "countryCode": "FR"
            },
            {
                "name": "Vailly-sur-Aisne",
                "countryCode": "FR"
            },
            {
                "name": "Valenciennes",
                "countryCode": "FR"
            },
            {
                "name": "Vaulx-Vraucourt",
                "countryCode": "FR"
            },
            {
                "name": "Vaumoise",
                "countryCode": "FR"
            },
            {
                "name": "Vendegies-sur-Écaillon",
                "countryCode": "FR"
            },
            {
                "name": "Vendeville",
                "countryCode": "FR"
            },
            {
                "name": "Vendin-le-Vieil",
                "countryCode": "FR"
            },
            {
                "name": "Vendin-lès-Béthune",
                "countryCode": "FR"
            },
            {
                "name": "Venette",
                "countryCode": "FR"
            },
            {
                "name": "Venizel",
                "countryCode": "FR"
            },
            {
                "name": "Ver-sur-Launette",
                "countryCode": "FR"
            },
            {
                "name": "Verberie",
                "countryCode": "FR"
            },
            {
                "name": "Verlinghem",
                "countryCode": "FR"
            },
            {
                "name": "Vermand",
                "countryCode": "FR"
            },
            {
                "name": "Vermelles",
                "countryCode": "FR"
            },
            {
                "name": "Verneuil-en-Halatte",
                "countryCode": "FR"
            },
            {
                "name": "Verquin",
                "countryCode": "FR"
            },
            {
                "name": "Verton",
                "countryCode": "FR"
            },
            {
                "name": "Vervins",
                "countryCode": "FR"
            },
            {
                "name": "Vic-sur-Aisne",
                "countryCode": "FR"
            },
            {
                "name": "Vicq",
                "countryCode": "FR"
            },
            {
                "name": "Vieille-Église",
                "countryCode": "FR"
            },
            {
                "name": "Viesly",
                "countryCode": "FR"
            },
            {
                "name": "Vieux-Berquin",
                "countryCode": "FR"
            },
            {
                "name": "Vieux-Condé",
                "countryCode": "FR"
            },
            {
                "name": "Vignacourt",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-d'Ascq",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-les-Sablons",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Bocage",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Bretonneux",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Cotterêts",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Outréaux",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Pol",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Saint-Paul",
                "countryCode": "FR"
            },
            {
                "name": "Villers-en-Cauchies",
                "countryCode": "FR"
            },
            {
                "name": "Villers-sous-Saint-Leu",
                "countryCode": "FR"
            },
            {
                "name": "Villers-sur-Coudun",
                "countryCode": "FR"
            },
            {
                "name": "Vimy",
                "countryCode": "FR"
            },
            {
                "name": "Vineuil-Saint-Firmin",
                "countryCode": "FR"
            },
            {
                "name": "Violaines",
                "countryCode": "FR"
            },
            {
                "name": "Viry-Noureuil",
                "countryCode": "FR"
            },
            {
                "name": "Vitry-en-Artois",
                "countryCode": "FR"
            },
            {
                "name": "Vred",
                "countryCode": "FR"
            },
            {
                "name": "Wahagnies",
                "countryCode": "FR"
            },
            {
                "name": "Wailly",
                "countryCode": "FR"
            },
            {
                "name": "Walincourt-Selvigny",
                "countryCode": "FR"
            },
            {
                "name": "Wallers",
                "countryCode": "FR"
            },
            {
                "name": "Wambrechies",
                "countryCode": "FR"
            },
            {
                "name": "Wandignies-Hamage",
                "countryCode": "FR"
            },
            {
                "name": "Wardrecques",
                "countryCode": "FR"
            },
            {
                "name": "Wargnies-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Warhem",
                "countryCode": "FR"
            },
            {
                "name": "Warluis",
                "countryCode": "FR"
            },
            {
                "name": "Wasquehal",
                "countryCode": "FR"
            },
            {
                "name": "Wassigny",
                "countryCode": "FR"
            },
            {
                "name": "Watten",
                "countryCode": "FR"
            },
            {
                "name": "Wattignies",
                "countryCode": "FR"
            },
            {
                "name": "Wattrelos",
                "countryCode": "FR"
            },
            {
                "name": "Wavignies",
                "countryCode": "FR"
            },
            {
                "name": "Wavrechain-sous-Denain",
                "countryCode": "FR"
            },
            {
                "name": "Wavrin",
                "countryCode": "FR"
            },
            {
                "name": "Waziers",
                "countryCode": "FR"
            },
            {
                "name": "Wervicq-Sud",
                "countryCode": "FR"
            },
            {
                "name": "Wignehies",
                "countryCode": "FR"
            },
            {
                "name": "Willems",
                "countryCode": "FR"
            },
            {
                "name": "Wimereux",
                "countryCode": "FR"
            },
            {
                "name": "Wimille",
                "countryCode": "FR"
            },
            {
                "name": "Wingles",
                "countryCode": "FR"
            },
            {
                "name": "Winnezeele",
                "countryCode": "FR"
            },
            {
                "name": "Wissant",
                "countryCode": "FR"
            },
            {
                "name": "Wizernes",
                "countryCode": "FR"
            },
            {
                "name": "Woincourt",
                "countryCode": "FR"
            },
            {
                "name": "Wormhout",
                "countryCode": "FR"
            },
            {
                "name": "Zegerscappel",
                "countryCode": "FR"
            },
            {
                "name": "Zutkerque",
                "countryCode": "FR"
            },
            {
                "name": "Zuydcoote",
                "countryCode": "FR"
            },
            {
                "name": "Écaillon",
                "countryCode": "FR"
            },
            {
                "name": "Écourt-Saint-Quentin",
                "countryCode": "FR"
            },
            {
                "name": "Éleu-dit-Leauwette",
                "countryCode": "FR"
            },
            {
                "name": "Émerchicourt",
                "countryCode": "FR"
            },
            {
                "name": "Épehy",
                "countryCode": "FR"
            },
            {
                "name": "Éperlecques",
                "countryCode": "FR"
            },
            {
                "name": "Équihen-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Étampes-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Étaples",
                "countryCode": "FR"
            },
            {
                "name": "Étreillers",
                "countryCode": "FR"
            },
            {
                "name": "Étreux",
                "countryCode": "FR"
            },
            {
                "name": "Évin-Malmaison",
                "countryCode": "FR"
            },
            {
                "name": "Ableiges",
                "countryCode": "FR"
            },
            {
                "name": "Ablis",
                "countryCode": "FR"
            },
            {
                "name": "Ablon-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Achères",
                "countryCode": "FR"
            },
            {
                "name": "Achères-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Alfortville",
                "countryCode": "FR"
            },
            {
                "name": "Andilly",
                "countryCode": "FR"
            },
            {
                "name": "Andrésy",
                "countryCode": "FR"
            },
            {
                "name": "Angerville",
                "countryCode": "FR"
            },
            {
                "name": "Angervilliers",
                "countryCode": "FR"
            },
            {
                "name": "Annet-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Antony",
                "countryCode": "FR"
            },
            {
                "name": "Arbonne-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Arcueil",
                "countryCode": "FR"
            },
            {
                "name": "Argenteuil",
                "countryCode": "FR"
            },
            {
                "name": "Armentières-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Arnouville",
                "countryCode": "FR"
            },
            {
                "name": "Arpajon",
                "countryCode": "FR"
            },
            {
                "name": "Asnières-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Asnières-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Athis-Mons",
                "countryCode": "FR"
            },
            {
                "name": "Attainville",
                "countryCode": "FR"
            },
            {
                "name": "Aubergenville",
                "countryCode": "FR"
            },
            {
                "name": "Aubervilliers",
                "countryCode": "FR"
            },
            {
                "name": "Auffargis",
                "countryCode": "FR"
            },
            {
                "name": "Aulnay-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Aulnay-sur-Mauldre",
                "countryCode": "FR"
            },
            {
                "name": "Auvers-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Auvers-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Avon",
                "countryCode": "FR"
            },
            {
                "name": "Bagneaux-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Bagneux",
                "countryCode": "FR"
            },
            {
                "name": "Bagnolet",
                "countryCode": "FR"
            },
            {
                "name": "Baillet-en-France",
                "countryCode": "FR"
            },
            {
                "name": "Bailly",
                "countryCode": "FR"
            },
            {
                "name": "Bailly-Carrois",
                "countryCode": "FR"
            },
            {
                "name": "Bailly-Romainvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Ballainvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Ballancourt-sur-Essonne",
                "countryCode": "FR"
            },
            {
                "name": "Barbizon",
                "countryCode": "FR"
            },
            {
                "name": "Baulne",
                "countryCode": "FR"
            },
            {
                "name": "Bazainville",
                "countryCode": "FR"
            },
            {
                "name": "Bazemont",
                "countryCode": "FR"
            },
            {
                "name": "Beauchamp",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-du-Gâtinais",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Belloy-en-France",
                "countryCode": "FR"
            },
            {
                "name": "Bennecourt",
                "countryCode": "FR"
            },
            {
                "name": "Bernes-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Bessancourt",
                "countryCode": "FR"
            },
            {
                "name": "Beynes",
                "countryCode": "FR"
            },
            {
                "name": "Bezons",
                "countryCode": "FR"
            },
            {
                "name": "Bièvres",
                "countryCode": "FR"
            },
            {
                "name": "Bobigny",
                "countryCode": "FR"
            },
            {
                "name": "Bois-Colombes",
                "countryCode": "FR"
            },
            {
                "name": "Bois-d’Arcy",
                "countryCode": "FR"
            },
            {
                "name": "Bois-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Boissise-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Boissy-Saint-Léger",
                "countryCode": "FR"
            },
            {
                "name": "Boissy-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Boissy-le-Cutté",
                "countryCode": "FR"
            },
            {
                "name": "Boissy-sous-Saint-Yon",
                "countryCode": "FR"
            },
            {
                "name": "Bondoufle",
                "countryCode": "FR"
            },
            {
                "name": "Bondy",
                "countryCode": "FR"
            },
            {
                "name": "Bonnelles",
                "countryCode": "FR"
            },
            {
                "name": "Bonneuil-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Bonnières-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Bouafle",
                "countryCode": "FR"
            },
            {
                "name": "Bouffémont",
                "countryCode": "FR"
            },
            {
                "name": "Bougival",
                "countryCode": "FR"
            },
            {
                "name": "Bouleurs",
                "countryCode": "FR"
            },
            {
                "name": "Boulogne-Billancourt",
                "countryCode": "FR"
            },
            {
                "name": "Bouray-sur-Juine",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-la-Reine",
                "countryCode": "FR"
            },
            {
                "name": "Bourron-Marlotte",
                "countryCode": "FR"
            },
            {
                "name": "Boussy-Saint-Antoine",
                "countryCode": "FR"
            },
            {
                "name": "Boutigny-sur-Essonne",
                "countryCode": "FR"
            },
            {
                "name": "Bray-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Breuillet",
                "countryCode": "FR"
            },
            {
                "name": "Brie-Comte-Robert",
                "countryCode": "FR"
            },
            {
                "name": "Briis-sous-Forges",
                "countryCode": "FR"
            },
            {
                "name": "Brou-sur-Chantereine",
                "countryCode": "FR"
            },
            {
                "name": "Brunoy",
                "countryCode": "FR"
            },
            {
                "name": "Bruyères-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Bruyères-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Bry-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Brétigny-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Bréval",
                "countryCode": "FR"
            },
            {
                "name": "Buc",
                "countryCode": "FR"
            },
            {
                "name": "Buchelay",
                "countryCode": "FR"
            },
            {
                "name": "Bullion",
                "countryCode": "FR"
            },
            {
                "name": "Bures-sur-Yvette",
                "countryCode": "FR"
            },
            {
                "name": "Bussy-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Butry-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Cachan",
                "countryCode": "FR"
            },
            {
                "name": "Cannes-Écluse",
                "countryCode": "FR"
            },
            {
                "name": "Carrières-sous-Poissy",
                "countryCode": "FR"
            },
            {
                "name": "Carrières-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Cergy",
                "countryCode": "FR"
            },
            {
                "name": "Cergy-Pontoise",
                "countryCode": "FR"
            },
            {
                "name": "Cernay-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Cerny",
                "countryCode": "FR"
            },
            {
                "name": "Cesson",
                "countryCode": "FR"
            },
            {
                "name": "Chailly-en-Bière",
                "countryCode": "FR"
            },
            {
                "name": "Chailly-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Chalifert",
                "countryCode": "FR"
            },
            {
                "name": "Chalo-Saint-Mars",
                "countryCode": "FR"
            },
            {
                "name": "Chamarande",
                "countryCode": "FR"
            },
            {
                "name": "Chambourcy",
                "countryCode": "FR"
            },
            {
                "name": "Chamigny",
                "countryCode": "FR"
            },
            {
                "name": "Champagne-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Champagne-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Champcueil",
                "countryCode": "FR"
            },
            {
                "name": "Champigny-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Champlan",
                "countryCode": "FR"
            },
            {
                "name": "Champs-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Changis-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Chanteloup-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Chanteloup-les-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Chapet",
                "countryCode": "FR"
            },
            {
                "name": "Charenton-le-Pont",
                "countryCode": "FR"
            },
            {
                "name": "Charny",
                "countryCode": "FR"
            },
            {
                "name": "Chars",
                "countryCode": "FR"
            },
            {
                "name": "Chartrettes",
                "countryCode": "FR"
            },
            {
                "name": "Chatou",
                "countryCode": "FR"
            },
            {
                "name": "Chauconin-Neufmontiers",
                "countryCode": "FR"
            },
            {
                "name": "Chaumes-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Chaumontel",
                "countryCode": "FR"
            },
            {
                "name": "Chavenay",
                "countryCode": "FR"
            },
            {
                "name": "Chaville",
                "countryCode": "FR"
            },
            {
                "name": "Chelles",
                "countryCode": "FR"
            },
            {
                "name": "Chennevières-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Chenoise",
                "countryCode": "FR"
            },
            {
                "name": "Cheptainville",
                "countryCode": "FR"
            },
            {
                "name": "Chessy",
                "countryCode": "FR"
            },
            {
                "name": "Chevannes",
                "countryCode": "FR"
            },
            {
                "name": "Chevilly-Larue",
                "countryCode": "FR"
            },
            {
                "name": "Chevreuse",
                "countryCode": "FR"
            },
            {
                "name": "Chevry-Cossigny",
                "countryCode": "FR"
            },
            {
                "name": "Chilly-Mazarin",
                "countryCode": "FR"
            },
            {
                "name": "Choisy-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Choisy-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Château-Landon",
                "countryCode": "FR"
            },
            {
                "name": "Châteaufort",
                "countryCode": "FR"
            },
            {
                "name": "Châtenay-Malabry",
                "countryCode": "FR"
            },
            {
                "name": "Châtenay-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon",
                "countryCode": "FR"
            },
            {
                "name": "Clamart",
                "countryCode": "FR"
            },
            {
                "name": "Claye-Souilly",
                "countryCode": "FR"
            },
            {
                "name": "Clichy",
                "countryCode": "FR"
            },
            {
                "name": "Clichy-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Coignières",
                "countryCode": "FR"
            },
            {
                "name": "Collégien",
                "countryCode": "FR"
            },
            {
                "name": "Colombes",
                "countryCode": "FR"
            },
            {
                "name": "Combs-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Conches-sur-Gondoire",
                "countryCode": "FR"
            },
            {
                "name": "Condé-Sainte-Libiaire",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-Vesgre",
                "countryCode": "FR"
            },
            {
                "name": "Conflans-Sainte-Honorine",
                "countryCode": "FR"
            },
            {
                "name": "Congis-sur-Thérouanne",
                "countryCode": "FR"
            },
            {
                "name": "Corbeil-Essonnes",
                "countryCode": "FR"
            },
            {
                "name": "Corbreuse",
                "countryCode": "FR"
            },
            {
                "name": "Cormeilles-en-Parisis",
                "countryCode": "FR"
            },
            {
                "name": "Coubert",
                "countryCode": "FR"
            },
            {
                "name": "Coubron",
                "countryCode": "FR"
            },
            {
                "name": "Couilly-Pont-aux-Dames",
                "countryCode": "FR"
            },
            {
                "name": "Coulommiers",
                "countryCode": "FR"
            },
            {
                "name": "Coupvray",
                "countryCode": "FR"
            },
            {
                "name": "Courbevoie",
                "countryCode": "FR"
            },
            {
                "name": "Courcouronnes",
                "countryCode": "FR"
            },
            {
                "name": "Courdimanche",
                "countryCode": "FR"
            },
            {
                "name": "Courpalay",
                "countryCode": "FR"
            },
            {
                "name": "Courtry",
                "countryCode": "FR"
            },
            {
                "name": "Crespières",
                "countryCode": "FR"
            },
            {
                "name": "Croissy-Beaubourg",
                "countryCode": "FR"
            },
            {
                "name": "Croissy-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Crosne",
                "countryCode": "FR"
            },
            {
                "name": "Crouy-sur-Ourcq",
                "countryCode": "FR"
            },
            {
                "name": "Crégy-lès-Meaux",
                "countryCode": "FR"
            },
            {
                "name": "Créteil",
                "countryCode": "FR"
            },
            {
                "name": "Cély",
                "countryCode": "FR"
            },
            {
                "name": "Dammarie-les-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Dammartin-en-Goële",
                "countryCode": "FR"
            },
            {
                "name": "Dampierre-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Dampmart",
                "countryCode": "FR"
            },
            {
                "name": "Deuil-la-Barre",
                "countryCode": "FR"
            },
            {
                "name": "Domont",
                "countryCode": "FR"
            },
            {
                "name": "Donnemarie-Dontilly",
                "countryCode": "FR"
            },
            {
                "name": "Doue",
                "countryCode": "FR"
            },
            {
                "name": "Dourdan",
                "countryCode": "FR"
            },
            {
                "name": "Drancy",
                "countryCode": "FR"
            },
            {
                "name": "Draveil",
                "countryCode": "FR"
            },
            {
                "name": "Dugny",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Essonne",
                "countryCode": "FR"
            },
            {
                "name": "Département du Val-d’Oise",
                "countryCode": "FR"
            },
            {
                "name": "Eaubonne",
                "countryCode": "FR"
            },
            {
                "name": "Ecquevilly",
                "countryCode": "FR"
            },
            {
                "name": "Enghien-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Ennery",
                "countryCode": "FR"
            },
            {
                "name": "Ermont",
                "countryCode": "FR"
            },
            {
                "name": "Esbly",
                "countryCode": "FR"
            },
            {
                "name": "Faremoutiers",
                "countryCode": "FR"
            },
            {
                "name": "Favières",
                "countryCode": "FR"
            },
            {
                "name": "Ferrières-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Feucherolles",
                "countryCode": "FR"
            },
            {
                "name": "Fleury-Mérogis",
                "countryCode": "FR"
            },
            {
                "name": "Flins-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Follainville-Dennemont",
                "countryCode": "FR"
            },
            {
                "name": "Fontainebleau",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-Trésigny",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-aux-Roses",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-en-Parisis",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-le-Fleury",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-lès-Briis",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Forges-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Fosses",
                "countryCode": "FR"
            },
            {
                "name": "Fourqueux",
                "countryCode": "FR"
            },
            {
                "name": "Franconville",
                "countryCode": "FR"
            },
            {
                "name": "Freneuse",
                "countryCode": "FR"
            },
            {
                "name": "Fresnes",
                "countryCode": "FR"
            },
            {
                "name": "Frépillon",
                "countryCode": "FR"
            },
            {
                "name": "Fublaines",
                "countryCode": "FR"
            },
            {
                "name": "Férolles-Attilly",
                "countryCode": "FR"
            },
            {
                "name": "Gagny",
                "countryCode": "FR"
            },
            {
                "name": "Galluis",
                "countryCode": "FR"
            },
            {
                "name": "Gambais",
                "countryCode": "FR"
            },
            {
                "name": "Garancières",
                "countryCode": "FR"
            },
            {
                "name": "Garches",
                "countryCode": "FR"
            },
            {
                "name": "Gargenville",
                "countryCode": "FR"
            },
            {
                "name": "Garges-lès-Gonesse",
                "countryCode": "FR"
            },
            {
                "name": "Gazeran",
                "countryCode": "FR"
            },
            {
                "name": "Gennevilliers",
                "countryCode": "FR"
            },
            {
                "name": "Gentilly",
                "countryCode": "FR"
            },
            {
                "name": "Gif-sur-Yvette",
                "countryCode": "FR"
            },
            {
                "name": "Gometz-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Gometz-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Gonesse",
                "countryCode": "FR"
            },
            {
                "name": "Gouaix",
                "countryCode": "FR"
            },
            {
                "name": "Gournay-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Goussainville",
                "countryCode": "FR"
            },
            {
                "name": "Gouvernes",
                "countryCode": "FR"
            },
            {
                "name": "Gretz-Armainvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Grez-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Grigny",
                "countryCode": "FR"
            },
            {
                "name": "Grisy-Suisnes",
                "countryCode": "FR"
            },
            {
                "name": "Groslay",
                "countryCode": "FR"
            },
            {
                "name": "Guermantes",
                "countryCode": "FR"
            },
            {
                "name": "Guerville",
                "countryCode": "FR"
            },
            {
                "name": "Guignes",
                "countryCode": "FR"
            },
            {
                "name": "Guyancourt",
                "countryCode": "FR"
            },
            {
                "name": "Guérard",
                "countryCode": "FR"
            },
            {
                "name": "Hardricourt",
                "countryCode": "FR"
            },
            {
                "name": "Hauts-de-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Herblay",
                "countryCode": "FR"
            },
            {
                "name": "Houdan",
                "countryCode": "FR"
            },
            {
                "name": "Houilles",
                "countryCode": "FR"
            },
            {
                "name": "Héricy",
                "countryCode": "FR"
            },
            {
                "name": "Igny",
                "countryCode": "FR"
            },
            {
                "name": "Issou",
                "countryCode": "FR"
            },
            {
                "name": "Issy-les-Moulineaux",
                "countryCode": "FR"
            },
            {
                "name": "Itteville",
                "countryCode": "FR"
            },
            {
                "name": "Ivry-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Janville-sur-Juine",
                "countryCode": "FR"
            },
            {
                "name": "Joinville-le-Pont",
                "countryCode": "FR"
            },
            {
                "name": "Jouarre",
                "countryCode": "FR"
            },
            {
                "name": "Jouars-Pontchartrain",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-en-Josas",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-le-Châtel",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-le-Moutier",
                "countryCode": "FR"
            },
            {
                "name": "Jouy-sur-Morin",
                "countryCode": "FR"
            },
            {
                "name": "Juilly",
                "countryCode": "FR"
            },
            {
                "name": "Juvisy-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Juziers",
                "countryCode": "FR"
            },
            {
                "name": "L'Haÿ-les-Roses",
                "countryCode": "FR"
            },
            {
                "name": "La Celle-Saint-Cloud",
                "countryCode": "FR"
            },
            {
                "name": "La Celle-sur-Morin",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Gauthier",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-la-Reine",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-sur-Crécy",
                "countryCode": "FR"
            },
            {
                "name": "La Courneuve",
                "countryCode": "FR"
            },
            {
                "name": "La Defense",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Alais",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Gaucher",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-sous-Jouarre",
                "countryCode": "FR"
            },
            {
                "name": "La Frette-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "La Garenne-Colombes",
                "countryCode": "FR"
            },
            {
                "name": "La Grande-Paroisse",
                "countryCode": "FR"
            },
            {
                "name": "La Houssaye-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "La Norville",
                "countryCode": "FR"
            },
            {
                "name": "La Queue-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "La Queue-les-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "La Rochette",
                "countryCode": "FR"
            },
            {
                "name": "La Verrière",
                "countryCode": "FR"
            },
            {
                "name": "La Ville-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Lagny-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Lardy",
                "countryCode": "FR"
            },
            {
                "name": "Le Blanc-Mesnil",
                "countryCode": "FR"
            },
            {
                "name": "Le Bourget",
                "countryCode": "FR"
            },
            {
                "name": "Le Chesnay",
                "countryCode": "FR"
            },
            {
                "name": "Le Châtelet-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Le Coudray-Montceaux",
                "countryCode": "FR"
            },
            {
                "name": "Le Kremlin-Bicêtre",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Le Mée-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Le Pecq",
                "countryCode": "FR"
            },
            {
                "name": "Le Perray-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Le Perreux-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Le Pin",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Bouchard",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Pâté",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Robinson",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Trévise",
                "countryCode": "FR"
            },
            {
                "name": "Le Port-Marly",
                "countryCode": "FR"
            },
            {
                "name": "Le Pré-Saint-Gervais",
                "countryCode": "FR"
            },
            {
                "name": "Le Raincy",
                "countryCode": "FR"
            },
            {
                "name": "Le Thillay",
                "countryCode": "FR"
            },
            {
                "name": "Le Val-Saint-Germain",
                "countryCode": "FR"
            },
            {
                "name": "Le Vésinet",
                "countryCode": "FR"
            },
            {
                "name": "Les Alluets-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Les Clayes-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Les Essarts-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Les Lilas",
                "countryCode": "FR"
            },
            {
                "name": "Les Loges-en-Josas",
                "countryCode": "FR"
            },
            {
                "name": "Les Molières",
                "countryCode": "FR"
            },
            {
                "name": "Les Mureaux",
                "countryCode": "FR"
            },
            {
                "name": "Les Pavillons-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Les Ulis",
                "countryCode": "FR"
            },
            {
                "name": "Leudeville",
                "countryCode": "FR"
            },
            {
                "name": "Leuville-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Levallois-Perret",
                "countryCode": "FR"
            },
            {
                "name": "Lieusaint",
                "countryCode": "FR"
            },
            {
                "name": "Limay",
                "countryCode": "FR"
            },
            {
                "name": "Limeil-Brévannes",
                "countryCode": "FR"
            },
            {
                "name": "Limetz-Villez",
                "countryCode": "FR"
            },
            {
                "name": "Limours",
                "countryCode": "FR"
            },
            {
                "name": "Linas",
                "countryCode": "FR"
            },
            {
                "name": "Lisses",
                "countryCode": "FR"
            },
            {
                "name": "Liverdy-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Livry-Gargan",
                "countryCode": "FR"
            },
            {
                "name": "Livry-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Lizy-sur-Ourcq",
                "countryCode": "FR"
            },
            {
                "name": "Lognes",
                "countryCode": "FR"
            },
            {
                "name": "Longjumeau",
                "countryCode": "FR"
            },
            {
                "name": "Longnes",
                "countryCode": "FR"
            },
            {
                "name": "Longperrier",
                "countryCode": "FR"
            },
            {
                "name": "Longpont-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Longueville",
                "countryCode": "FR"
            },
            {
                "name": "Lorrez-le-Bocage-Préaux",
                "countryCode": "FR"
            },
            {
                "name": "Louveciennes",
                "countryCode": "FR"
            },
            {
                "name": "Louvres",
                "countryCode": "FR"
            },
            {
                "name": "Lumigny-Nesles-Ormeaux",
                "countryCode": "FR"
            },
            {
                "name": "Luzarches",
                "countryCode": "FR"
            },
            {
                "name": "Lésigny",
                "countryCode": "FR"
            },
            {
                "name": "Lévis-Saint-Nom",
                "countryCode": "FR"
            },
            {
                "name": "L’Isle-Adam",
                "countryCode": "FR"
            },
            {
                "name": "L’Étang-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Maffliers",
                "countryCode": "FR"
            },
            {
                "name": "Magnanville",
                "countryCode": "FR"
            },
            {
                "name": "Magny-en-Vexin",
                "countryCode": "FR"
            },
            {
                "name": "Magny-le-Hongre",
                "countryCode": "FR"
            },
            {
                "name": "Magny-les-Hameaux",
                "countryCode": "FR"
            },
            {
                "name": "Maincy",
                "countryCode": "FR"
            },
            {
                "name": "Maisons-Alfort",
                "countryCode": "FR"
            },
            {
                "name": "Maisons-Laffitte",
                "countryCode": "FR"
            },
            {
                "name": "Maisse",
                "countryCode": "FR"
            },
            {
                "name": "Malakoff",
                "countryCode": "FR"
            },
            {
                "name": "Mandres-les-Roses",
                "countryCode": "FR"
            },
            {
                "name": "Mantes-la-Jolie",
                "countryCode": "FR"
            },
            {
                "name": "Mantes-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Marcoussis",
                "countryCode": "FR"
            },
            {
                "name": "Mareil-Marly",
                "countryCode": "FR"
            },
            {
                "name": "Mareil-sur-Mauldre",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-lès-Meaux",
                "countryCode": "FR"
            },
            {
                "name": "Margency",
                "countryCode": "FR"
            },
            {
                "name": "Marines",
                "countryCode": "FR"
            },
            {
                "name": "Marles-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Marly-la-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Marly-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Marnes-la-Coquette",
                "countryCode": "FR"
            },
            {
                "name": "Marolles-en-Hurepoix",
                "countryCode": "FR"
            },
            {
                "name": "Marolles-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Mary-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Massy",
                "countryCode": "FR"
            },
            {
                "name": "Maule",
                "countryCode": "FR"
            },
            {
                "name": "Maurecourt",
                "countryCode": "FR"
            },
            {
                "name": "Maurepas",
                "countryCode": "FR"
            },
            {
                "name": "Meaux",
                "countryCode": "FR"
            },
            {
                "name": "Melun",
                "countryCode": "FR"
            },
            {
                "name": "Mennecy",
                "countryCode": "FR"
            },
            {
                "name": "Menucourt",
                "countryCode": "FR"
            },
            {
                "name": "Meudon",
                "countryCode": "FR"
            },
            {
                "name": "Meulan-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Milly-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Mitry-Mory",
                "countryCode": "FR"
            },
            {
                "name": "Moigny-sur-École",
                "countryCode": "FR"
            },
            {
                "name": "Moisenay",
                "countryCode": "FR"
            },
            {
                "name": "Moisselles",
                "countryCode": "FR"
            },
            {
                "name": "Moissy-Cramayel",
                "countryCode": "FR"
            },
            {
                "name": "Montcourt-Fromonville",
                "countryCode": "FR"
            },
            {
                "name": "Montereau-Fault-Yonne",
                "countryCode": "FR"
            },
            {
                "name": "Montesson",
                "countryCode": "FR"
            },
            {
                "name": "Montfermeil",
                "countryCode": "FR"
            },
            {
                "name": "Montgeron",
                "countryCode": "FR"
            },
            {
                "name": "Monthyon",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-Lencoup",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-le-Bretonneux",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-lès-Cormeilles",
                "countryCode": "FR"
            },
            {
                "name": "Montigny-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Montlhéry",
                "countryCode": "FR"
            },
            {
                "name": "Montlignon",
                "countryCode": "FR"
            },
            {
                "name": "Montmagny",
                "countryCode": "FR"
            },
            {
                "name": "Montmorency",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil",
                "countryCode": "FR"
            },
            {
                "name": "Montrouge",
                "countryCode": "FR"
            },
            {
                "name": "Montry",
                "countryCode": "FR"
            },
            {
                "name": "Montsoult",
                "countryCode": "FR"
            },
            {
                "name": "Montévrain",
                "countryCode": "FR"
            },
            {
                "name": "Morainvilliers",
                "countryCode": "FR"
            },
            {
                "name": "Morangis",
                "countryCode": "FR"
            },
            {
                "name": "Moret-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Morigny-Champigny",
                "countryCode": "FR"
            },
            {
                "name": "Mormant",
                "countryCode": "FR"
            },
            {
                "name": "Morsang-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Mortcerf",
                "countryCode": "FR"
            },
            {
                "name": "Mouroux",
                "countryCode": "FR"
            },
            {
                "name": "Mours",
                "countryCode": "FR"
            },
            {
                "name": "Moussy-le-Neuf",
                "countryCode": "FR"
            },
            {
                "name": "Moussy-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Médan",
                "countryCode": "FR"
            },
            {
                "name": "Mériel",
                "countryCode": "FR"
            },
            {
                "name": "Méry-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Méré",
                "countryCode": "FR"
            },
            {
                "name": "Méréville",
                "countryCode": "FR"
            },
            {
                "name": "Mézières-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Mézy-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Nandy",
                "countryCode": "FR"
            },
            {
                "name": "Nangis",
                "countryCode": "FR"
            },
            {
                "name": "Nanterre",
                "countryCode": "FR"
            },
            {
                "name": "Nanteuil-lès-Meaux",
                "countryCode": "FR"
            },
            {
                "name": "Neauphle-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Nemours",
                "countryCode": "FR"
            },
            {
                "name": "Nesles-la-Vallée",
                "countryCode": "FR"
            },
            {
                "name": "Neufmoutiers-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-Plaisance",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Neuilly-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-sur-Oise",
                "countryCode": "FR"
            },
            {
                "name": "Nogent-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Noiseau",
                "countryCode": "FR"
            },
            {
                "name": "Noisiel",
                "countryCode": "FR"
            },
            {
                "name": "Noisy-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Noisy-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Noisy-le-Sec",
                "countryCode": "FR"
            },
            {
                "name": "Noisy-sur-École",
                "countryCode": "FR"
            },
            {
                "name": "Nozay",
                "countryCode": "FR"
            },
            {
                "name": "Oinville-sur-Montcient",
                "countryCode": "FR"
            },
            {
                "name": "Oissery",
                "countryCode": "FR"
            },
            {
                "name": "Ollainville",
                "countryCode": "FR"
            },
            {
                "name": "Orgerus",
                "countryCode": "FR"
            },
            {
                "name": "Orgeval",
                "countryCode": "FR"
            },
            {
                "name": "Orly",
                "countryCode": "FR"
            },
            {
                "name": "Ormesson-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Ormoy",
                "countryCode": "FR"
            },
            {
                "name": "Orsay",
                "countryCode": "FR"
            },
            {
                "name": "Osny",
                "countryCode": "FR"
            },
            {
                "name": "Othis",
                "countryCode": "FR"
            },
            {
                "name": "Ozoir-la-Ferrière",
                "countryCode": "FR"
            },
            {
                "name": "Ozouer-le-Voulgis",
                "countryCode": "FR"
            },
            {
                "name": "Palaiseau",
                "countryCode": "FR"
            },
            {
                "name": "Pantin",
                "countryCode": "FR"
            },
            {
                "name": "Paray-Vieille-Poste",
                "countryCode": "FR"
            },
            {
                "name": "Paris",
                "countryCode": "FR"
            },
            {
                "name": "Parmain",
                "countryCode": "FR"
            },
            {
                "name": "Persan",
                "countryCode": "FR"
            },
            {
                "name": "Perthes",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefitte-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Pierrelaye",
                "countryCode": "FR"
            },
            {
                "name": "Plaisir",
                "countryCode": "FR"
            },
            {
                "name": "Poissy",
                "countryCode": "FR"
            },
            {
                "name": "Pommeuse",
                "countryCode": "FR"
            },
            {
                "name": "Pomponne",
                "countryCode": "FR"
            },
            {
                "name": "Pontault-Combault",
                "countryCode": "FR"
            },
            {
                "name": "Pontcarré",
                "countryCode": "FR"
            },
            {
                "name": "Ponthierry",
                "countryCode": "FR"
            },
            {
                "name": "Pontoise",
                "countryCode": "FR"
            },
            {
                "name": "Porcheville",
                "countryCode": "FR"
            },
            {
                "name": "Presles-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Pringy",
                "countryCode": "FR"
            },
            {
                "name": "Provins",
                "countryCode": "FR"
            },
            {
                "name": "Puiseux-en-France",
                "countryCode": "FR"
            },
            {
                "name": "Pussay",
                "countryCode": "FR"
            },
            {
                "name": "Puteaux",
                "countryCode": "FR"
            },
            {
                "name": "Périgny",
                "countryCode": "FR"
            },
            {
                "name": "Quincy-Voisins",
                "countryCode": "FR"
            },
            {
                "name": "Quincy-sous-Sénart",
                "countryCode": "FR"
            },
            {
                "name": "Rambouillet",
                "countryCode": "FR"
            },
            {
                "name": "Rebais",
                "countryCode": "FR"
            },
            {
                "name": "Ris-Orangis",
                "countryCode": "FR"
            },
            {
                "name": "Rocquencourt",
                "countryCode": "FR"
            },
            {
                "name": "Roissy-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Roissy-en-France",
                "countryCode": "FR"
            },
            {
                "name": "Romainville",
                "countryCode": "FR"
            },
            {
                "name": "Rosny-sous-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Rosny-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Rozay-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Rubelles",
                "countryCode": "FR"
            },
            {
                "name": "Rueil-Malmaison",
                "countryCode": "FR"
            },
            {
                "name": "Rungis",
                "countryCode": "FR"
            },
            {
                "name": "Saclas",
                "countryCode": "FR"
            },
            {
                "name": "Saclay",
                "countryCode": "FR"
            },
            {
                "name": "Sagy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Arnoult-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Augustin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brice-sous-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chéron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cloud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-l’École",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sous-Dourdan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sur-Morin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fargeau-Ponthierry",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Laval",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-de-la-Grange",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-en-Laye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-lès-Arpajon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-lès-Corbeil",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-sur-Morin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gratien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-les-Deux-Jumeaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Leu-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mammès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mandé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Tertre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maur-des-Fossés",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-Montcouronne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nom-la-Bretêche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen-l’Aumône",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pathus",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-du-Perray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-lès-Nemours",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Prix",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-en-Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-lès-Chevreuse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-sur-École",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Soupplets",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thibault-des-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vrain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Witz",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Colombe",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Geneviève-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saintry-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Saints",
                "countryCode": "FR"
            },
            {
                "name": "Salins",
                "countryCode": "FR"
            },
            {
                "name": "Sammeron",
                "countryCode": "FR"
            },
            {
                "name": "Samois-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Samoreau",
                "countryCode": "FR"
            },
            {
                "name": "Sannois",
                "countryCode": "FR"
            },
            {
                "name": "Santeny",
                "countryCode": "FR"
            },
            {
                "name": "Sarcelles",
                "countryCode": "FR"
            },
            {
                "name": "Sartrouville",
                "countryCode": "FR"
            },
            {
                "name": "Saulx-les-Chartreux",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-le-Temple",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Saâcy-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Sceaux",
                "countryCode": "FR"
            },
            {
                "name": "Seine-Port",
                "countryCode": "FR"
            },
            {
                "name": "Seine-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Seine-et-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Septeuil",
                "countryCode": "FR"
            },
            {
                "name": "Seraincourt",
                "countryCode": "FR"
            },
            {
                "name": "Serris",
                "countryCode": "FR"
            },
            {
                "name": "Servon",
                "countryCode": "FR"
            },
            {
                "name": "Seugy",
                "countryCode": "FR"
            },
            {
                "name": "Sevran",
                "countryCode": "FR"
            },
            {
                "name": "Soignolles-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Soisy-sous-Montmorency",
                "countryCode": "FR"
            },
            {
                "name": "Soisy-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Soisy-sur-École",
                "countryCode": "FR"
            },
            {
                "name": "Solers",
                "countryCode": "FR"
            },
            {
                "name": "Sonchamp",
                "countryCode": "FR"
            },
            {
                "name": "Souppes-sur-Loing",
                "countryCode": "FR"
            },
            {
                "name": "Sourdun",
                "countryCode": "FR"
            },
            {
                "name": "Stains",
                "countryCode": "FR"
            },
            {
                "name": "Sucy-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Suresnes",
                "countryCode": "FR"
            },
            {
                "name": "Survilliers",
                "countryCode": "FR"
            },
            {
                "name": "Sèvres",
                "countryCode": "FR"
            },
            {
                "name": "Tacoignières",
                "countryCode": "FR"
            },
            {
                "name": "Taverny",
                "countryCode": "FR"
            },
            {
                "name": "Thiais",
                "countryCode": "FR"
            },
            {
                "name": "Thiverval-Grignon",
                "countryCode": "FR"
            },
            {
                "name": "Thoiry",
                "countryCode": "FR"
            },
            {
                "name": "Thomery",
                "countryCode": "FR"
            },
            {
                "name": "Thorigny-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Tigery",
                "countryCode": "FR"
            },
            {
                "name": "Torcy",
                "countryCode": "FR"
            },
            {
                "name": "Touquin",
                "countryCode": "FR"
            },
            {
                "name": "Tournan-en-Brie",
                "countryCode": "FR"
            },
            {
                "name": "Trappes",
                "countryCode": "FR"
            },
            {
                "name": "Tremblay-en-France",
                "countryCode": "FR"
            },
            {
                "name": "Triel-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Trilport",
                "countryCode": "FR"
            },
            {
                "name": "Us",
                "countryCode": "FR"
            },
            {
                "name": "Vaires-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Val-de-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Valenton",
                "countryCode": "FR"
            },
            {
                "name": "Valmondois",
                "countryCode": "FR"
            },
            {
                "name": "Vanves",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-Jarcy",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Varreddes",
                "countryCode": "FR"
            },
            {
                "name": "Vaucresson",
                "countryCode": "FR"
            },
            {
                "name": "Vaugrigneuse",
                "countryCode": "FR"
            },
            {
                "name": "Vauhallan",
                "countryCode": "FR"
            },
            {
                "name": "Vaujours",
                "countryCode": "FR"
            },
            {
                "name": "Vauréal",
                "countryCode": "FR"
            },
            {
                "name": "Vaux-le-Pénil",
                "countryCode": "FR"
            },
            {
                "name": "Vaux-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Veneux-les-Sablons",
                "countryCode": "FR"
            },
            {
                "name": "Verneuil-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Vernou-la-Celle-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Vernouillet",
                "countryCode": "FR"
            },
            {
                "name": "Verrières-le-Buisson",
                "countryCode": "FR"
            },
            {
                "name": "Versailles",
                "countryCode": "FR"
            },
            {
                "name": "Vert-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Vert-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Vert-le-Petit",
                "countryCode": "FR"
            },
            {
                "name": "Viarmes",
                "countryCode": "FR"
            },
            {
                "name": "Vigneux-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Vigny",
                "countryCode": "FR"
            },
            {
                "name": "Villabé",
                "countryCode": "FR"
            },
            {
                "name": "Ville-d’Avray",
                "countryCode": "FR"
            },
            {
                "name": "Villebon-sur-Yvette",
                "countryCode": "FR"
            },
            {
                "name": "Villecresnes",
                "countryCode": "FR"
            },
            {
                "name": "Villejuif",
                "countryCode": "FR"
            },
            {
                "name": "Villejust",
                "countryCode": "FR"
            },
            {
                "name": "Villemoisson-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Villemomble",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-la-Garenne",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-le-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-sur-Bellot",
                "countryCode": "FR"
            },
            {
                "name": "Villennes-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Villenoy",
                "countryCode": "FR"
            },
            {
                "name": "Villeparisis",
                "countryCode": "FR"
            },
            {
                "name": "Villepinte",
                "countryCode": "FR"
            },
            {
                "name": "Villepreux",
                "countryCode": "FR"
            },
            {
                "name": "Villetaneuse",
                "countryCode": "FR"
            },
            {
                "name": "Villevaudé",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-Saint-Fréderic",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-le-Bel",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-le-Bâcle",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-sur-Marne",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-sur-Morin",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Vincennes",
                "countryCode": "FR"
            },
            {
                "name": "Viroflay",
                "countryCode": "FR"
            },
            {
                "name": "Viry-Châtillon",
                "countryCode": "FR"
            },
            {
                "name": "Vitry-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Voisenon",
                "countryCode": "FR"
            },
            {
                "name": "Voisins-le-Bretonneux",
                "countryCode": "FR"
            },
            {
                "name": "Voulangis",
                "countryCode": "FR"
            },
            {
                "name": "Voulx",
                "countryCode": "FR"
            },
            {
                "name": "Vulaines-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Vélizy-Villacoublay",
                "countryCode": "FR"
            },
            {
                "name": "Vémars",
                "countryCode": "FR"
            },
            {
                "name": "Wissous",
                "countryCode": "FR"
            },
            {
                "name": "Yerres",
                "countryCode": "FR"
            },
            {
                "name": "Yvelines",
                "countryCode": "FR"
            },
            {
                "name": "Écouen",
                "countryCode": "FR"
            },
            {
                "name": "Écuelles",
                "countryCode": "FR"
            },
            {
                "name": "Égly",
                "countryCode": "FR"
            },
            {
                "name": "Égreville",
                "countryCode": "FR"
            },
            {
                "name": "Élancourt",
                "countryCode": "FR"
            },
            {
                "name": "Émerainville",
                "countryCode": "FR"
            },
            {
                "name": "Épinay-sous-Sénart",
                "countryCode": "FR"
            },
            {
                "name": "Épinay-sur-Orge",
                "countryCode": "FR"
            },
            {
                "name": "Épinay-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Épône",
                "countryCode": "FR"
            },
            {
                "name": "Éragny",
                "countryCode": "FR"
            },
            {
                "name": "Étampes",
                "countryCode": "FR"
            },
            {
                "name": "Étiolles",
                "countryCode": "FR"
            },
            {
                "name": "Étréchy",
                "countryCode": "FR"
            },
            {
                "name": "Évry",
                "countryCode": "FR"
            },
            {
                "name": "Ézanville",
                "countryCode": "FR"
            },
            {
                "name": "Abzac",
                "countryCode": "FR"
            },
            {
                "name": "Agen",
                "countryCode": "FR"
            },
            {
                "name": "Agonac",
                "countryCode": "FR"
            },
            {
                "name": "Ahetze",
                "countryCode": "FR"
            },
            {
                "name": "Ahun",
                "countryCode": "FR"
            },
            {
                "name": "Aiffres",
                "countryCode": "FR"
            },
            {
                "name": "Aigre",
                "countryCode": "FR"
            },
            {
                "name": "Aiguillon",
                "countryCode": "FR"
            },
            {
                "name": "Airvault",
                "countryCode": "FR"
            },
            {
                "name": "Aixe-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Ajain",
                "countryCode": "FR"
            },
            {
                "name": "Allassac",
                "countryCode": "FR"
            },
            {
                "name": "Ambarès-et-Lagrave",
                "countryCode": "FR"
            },
            {
                "name": "Ambazac",
                "countryCode": "FR"
            },
            {
                "name": "Ambès",
                "countryCode": "FR"
            },
            {
                "name": "Amou",
                "countryCode": "FR"
            },
            {
                "name": "Andernos-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Andilly",
                "countryCode": "FR"
            },
            {
                "name": "Anglet",
                "countryCode": "FR"
            },
            {
                "name": "Angoulins",
                "countryCode": "FR"
            },
            {
                "name": "Angoulême",
                "countryCode": "FR"
            },
            {
                "name": "Angresse",
                "countryCode": "FR"
            },
            {
                "name": "Annesse-et-Beaulieu",
                "countryCode": "FR"
            },
            {
                "name": "Antonne-et-Trigonant",
                "countryCode": "FR"
            },
            {
                "name": "Antran",
                "countryCode": "FR"
            },
            {
                "name": "Arbonne",
                "countryCode": "FR"
            },
            {
                "name": "Arbus",
                "countryCode": "FR"
            },
            {
                "name": "Arcachon",
                "countryCode": "FR"
            },
            {
                "name": "Arcangues",
                "countryCode": "FR"
            },
            {
                "name": "Archigny",
                "countryCode": "FR"
            },
            {
                "name": "Ardin",
                "countryCode": "FR"
            },
            {
                "name": "Arette",
                "countryCode": "FR"
            },
            {
                "name": "Argentat",
                "countryCode": "FR"
            },
            {
                "name": "Argenton-les-Vallées",
                "countryCode": "FR"
            },
            {
                "name": "Arnac-Pompadour",
                "countryCode": "FR"
            },
            {
                "name": "Arnac-la-Poste",
                "countryCode": "FR"
            },
            {
                "name": "Ars-en-Ré",
                "countryCode": "FR"
            },
            {
                "name": "Arsac",
                "countryCode": "FR"
            },
            {
                "name": "Arthez-de-Béarn",
                "countryCode": "FR"
            },
            {
                "name": "Artiguelouve",
                "countryCode": "FR"
            },
            {
                "name": "Artigues-près-Bordeaux",
                "countryCode": "FR"
            },
            {
                "name": "Artix",
                "countryCode": "FR"
            },
            {
                "name": "Arudy",
                "countryCode": "FR"
            },
            {
                "name": "Arvert",
                "countryCode": "FR"
            },
            {
                "name": "Arveyres",
                "countryCode": "FR"
            },
            {
                "name": "Arès",
                "countryCode": "FR"
            },
            {
                "name": "Ascain",
                "countryCode": "FR"
            },
            {
                "name": "Asnières-sur-Nouère",
                "countryCode": "FR"
            },
            {
                "name": "Assat",
                "countryCode": "FR"
            },
            {
                "name": "Asson",
                "countryCode": "FR"
            },
            {
                "name": "Astaffort",
                "countryCode": "FR"
            },
            {
                "name": "Atur",
                "countryCode": "FR"
            },
            {
                "name": "Aubie-et-Espessas",
                "countryCode": "FR"
            },
            {
                "name": "Aubusson",
                "countryCode": "FR"
            },
            {
                "name": "Audenge",
                "countryCode": "FR"
            },
            {
                "name": "Aulnay",
                "countryCode": "FR"
            },
            {
                "name": "Auzances",
                "countryCode": "FR"
            },
            {
                "name": "Availles-Limouzine",
                "countryCode": "FR"
            },
            {
                "name": "Avanton",
                "countryCode": "FR"
            },
            {
                "name": "Avensan",
                "countryCode": "FR"
            },
            {
                "name": "Ayguemorte-les-Graves",
                "countryCode": "FR"
            },
            {
                "name": "Ayron",
                "countryCode": "FR"
            },
            {
                "name": "Aytré",
                "countryCode": "FR"
            },
            {
                "name": "Azay-le-Brûlé",
                "countryCode": "FR"
            },
            {
                "name": "Baignes-Sainte-Radegonde",
                "countryCode": "FR"
            },
            {
                "name": "Balzac",
                "countryCode": "FR"
            },
            {
                "name": "Barbaste",
                "countryCode": "FR"
            },
            {
                "name": "Barbezieux-Saint-Hilaire",
                "countryCode": "FR"
            },
            {
                "name": "Bardos",
                "countryCode": "FR"
            },
            {
                "name": "Barsac",
                "countryCode": "FR"
            },
            {
                "name": "Bassens",
                "countryCode": "FR"
            },
            {
                "name": "Bassillac",
                "countryCode": "FR"
            },
            {
                "name": "Bassussarry",
                "countryCode": "FR"
            },
            {
                "name": "Bayonne",
                "countryCode": "FR"
            },
            {
                "name": "Bazas",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu-sur-Dordogne",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-du-Périgord",
                "countryCode": "FR"
            },
            {
                "name": "Beaupuy",
                "countryCode": "FR"
            },
            {
                "name": "Beautiran",
                "countryCode": "FR"
            },
            {
                "name": "Beauvoir-sur-Niort",
                "countryCode": "FR"
            },
            {
                "name": "Belin-Béliet",
                "countryCode": "FR"
            },
            {
                "name": "Bellac",
                "countryCode": "FR"
            },
            {
                "name": "Belvès",
                "countryCode": "FR"
            },
            {
                "name": "Benquet",
                "countryCode": "FR"
            },
            {
                "name": "Bergerac",
                "countryCode": "FR"
            },
            {
                "name": "Berson",
                "countryCode": "FR"
            },
            {
                "name": "Bessines",
                "countryCode": "FR"
            },
            {
                "name": "Bessines-sur-Gartempe",
                "countryCode": "FR"
            },
            {
                "name": "Beychac-et-Caillau",
                "countryCode": "FR"
            },
            {
                "name": "Beynat",
                "countryCode": "FR"
            },
            {
                "name": "Biard",
                "countryCode": "FR"
            },
            {
                "name": "Biarritz",
                "countryCode": "FR"
            },
            {
                "name": "Bias",
                "countryCode": "FR"
            },
            {
                "name": "Bidache",
                "countryCode": "FR"
            },
            {
                "name": "Bidart",
                "countryCode": "FR"
            },
            {
                "name": "Biganos",
                "countryCode": "FR"
            },
            {
                "name": "Bignoux",
                "countryCode": "FR"
            },
            {
                "name": "Billère",
                "countryCode": "FR"
            },
            {
                "name": "Biscarrosse",
                "countryCode": "FR"
            },
            {
                "name": "Bizanos",
                "countryCode": "FR"
            },
            {
                "name": "Blanquefort",
                "countryCode": "FR"
            },
            {
                "name": "Blaye",
                "countryCode": "FR"
            },
            {
                "name": "Boeil-Bezing",
                "countryCode": "FR"
            },
            {
                "name": "Boismé",
                "countryCode": "FR"
            },
            {
                "name": "Boisseuil",
                "countryCode": "FR"
            },
            {
                "name": "Bon-Encontre",
                "countryCode": "FR"
            },
            {
                "name": "Bonnac-la-Côte",
                "countryCode": "FR"
            },
            {
                "name": "Bonnat",
                "countryCode": "FR"
            },
            {
                "name": "Bonnes",
                "countryCode": "FR"
            },
            {
                "name": "Bonneuil-Matours",
                "countryCode": "FR"
            },
            {
                "name": "Bordeaux",
                "countryCode": "FR"
            },
            {
                "name": "Bordes",
                "countryCode": "FR"
            },
            {
                "name": "Bords",
                "countryCode": "FR"
            },
            {
                "name": "Bort-les-Orgues",
                "countryCode": "FR"
            },
            {
                "name": "Bosdarros",
                "countryCode": "FR"
            },
            {
                "name": "Bosmie-l'Aiguille",
                "countryCode": "FR"
            },
            {
                "name": "Boucau",
                "countryCode": "FR"
            },
            {
                "name": "Bouillé-Loretz",
                "countryCode": "FR"
            },
            {
                "name": "Boulazac",
                "countryCode": "FR"
            },
            {
                "name": "Bouliac",
                "countryCode": "FR"
            },
            {
                "name": "Bourcefranc-le-Chapus",
                "countryCode": "FR"
            },
            {
                "name": "Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Bourganeuf",
                "countryCode": "FR"
            },
            {
                "name": "Bourgneuf",
                "countryCode": "FR"
            },
            {
                "name": "Boussac",
                "countryCode": "FR"
            },
            {
                "name": "Boutiers-Saint-Trojan",
                "countryCode": "FR"
            },
            {
                "name": "Boyard-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Boé",
                "countryCode": "FR"
            },
            {
                "name": "Branne",
                "countryCode": "FR"
            },
            {
                "name": "Brantôme",
                "countryCode": "FR"
            },
            {
                "name": "Braud-et-Saint-Louis",
                "countryCode": "FR"
            },
            {
                "name": "Brax",
                "countryCode": "FR"
            },
            {
                "name": "Bressuire",
                "countryCode": "FR"
            },
            {
                "name": "Breuil-Magné",
                "countryCode": "FR"
            },
            {
                "name": "Breuillet",
                "countryCode": "FR"
            },
            {
                "name": "Brie",
                "countryCode": "FR"
            },
            {
                "name": "Brigueuil",
                "countryCode": "FR"
            },
            {
                "name": "Brioux-sur-Boutonne",
                "countryCode": "FR"
            },
            {
                "name": "Briscous",
                "countryCode": "FR"
            },
            {
                "name": "Brive-la-Gaillarde",
                "countryCode": "FR"
            },
            {
                "name": "Bruges",
                "countryCode": "FR"
            },
            {
                "name": "Bugeat",
                "countryCode": "FR"
            },
            {
                "name": "Burie",
                "countryCode": "FR"
            },
            {
                "name": "Buros",
                "countryCode": "FR"
            },
            {
                "name": "Bussac-sur-Charente",
                "countryCode": "FR"
            },
            {
                "name": "Bussière-Dunoise",
                "countryCode": "FR"
            },
            {
                "name": "Bussière-Galant",
                "countryCode": "FR"
            },
            {
                "name": "Bussière-Poitevine",
                "countryCode": "FR"
            },
            {
                "name": "Buxerolles",
                "countryCode": "FR"
            },
            {
                "name": "Buzet-sur-Baïse",
                "countryCode": "FR"
            },
            {
                "name": "Bègles",
                "countryCode": "FR"
            },
            {
                "name": "Bégaar",
                "countryCode": "FR"
            },
            {
                "name": "Bénesse-Maremne",
                "countryCode": "FR"
            },
            {
                "name": "Bénéjacq",
                "countryCode": "FR"
            },
            {
                "name": "Béruges",
                "countryCode": "FR"
            },
            {
                "name": "Cabanac-et-Villagrains",
                "countryCode": "FR"
            },
            {
                "name": "Cabariot",
                "countryCode": "FR"
            },
            {
                "name": "Cadaujac",
                "countryCode": "FR"
            },
            {
                "name": "Cadillac",
                "countryCode": "FR"
            },
            {
                "name": "Cambes",
                "countryCode": "FR"
            },
            {
                "name": "Camblanes-et-Meynac",
                "countryCode": "FR"
            },
            {
                "name": "Cambo-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Cancon",
                "countryCode": "FR"
            },
            {
                "name": "Cantenac",
                "countryCode": "FR"
            },
            {
                "name": "Canéjan",
                "countryCode": "FR"
            },
            {
                "name": "Capbreton",
                "countryCode": "FR"
            },
            {
                "name": "Captieux",
                "countryCode": "FR"
            },
            {
                "name": "Carbon-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Carcans",
                "countryCode": "FR"
            },
            {
                "name": "Carignan-de-Bordeaux",
                "countryCode": "FR"
            },
            {
                "name": "Cars",
                "countryCode": "FR"
            },
            {
                "name": "Carsac-Aillac",
                "countryCode": "FR"
            },
            {
                "name": "Casseneuil",
                "countryCode": "FR"
            },
            {
                "name": "Castelculier",
                "countryCode": "FR"
            },
            {
                "name": "Casteljaloux",
                "countryCode": "FR"
            },
            {
                "name": "Castelmoron-sur-Lot",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-de-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Castets",
                "countryCode": "FR"
            },
            {
                "name": "Castets-en-Dorthe",
                "countryCode": "FR"
            },
            {
                "name": "Castillon-la-Bataille",
                "countryCode": "FR"
            },
            {
                "name": "Castillonnès",
                "countryCode": "FR"
            },
            {
                "name": "Castres-Gironde",
                "countryCode": "FR"
            },
            {
                "name": "Cavignac",
                "countryCode": "FR"
            },
            {
                "name": "Cazères-sur-l’Adour",
                "countryCode": "FR"
            },
            {
                "name": "Celle-Lévescault",
                "countryCode": "FR"
            },
            {
                "name": "Celles-sur-Belle",
                "countryCode": "FR"
            },
            {
                "name": "Cenon",
                "countryCode": "FR"
            },
            {
                "name": "Cenon-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Cercoux",
                "countryCode": "FR"
            },
            {
                "name": "Cerizay",
                "countryCode": "FR"
            },
            {
                "name": "Cestas",
                "countryCode": "FR"
            },
            {
                "name": "Chabanais",
                "countryCode": "FR"
            },
            {
                "name": "Chaillevette",
                "countryCode": "FR"
            },
            {
                "name": "Chalais",
                "countryCode": "FR"
            },
            {
                "name": "Chamberet",
                "countryCode": "FR"
            },
            {
                "name": "Chambon-sur-Voueize",
                "countryCode": "FR"
            },
            {
                "name": "Chamboulive",
                "countryCode": "FR"
            },
            {
                "name": "Chameyrat",
                "countryCode": "FR"
            },
            {
                "name": "Champagne-Mouton",
                "countryCode": "FR"
            },
            {
                "name": "Champcevinel",
                "countryCode": "FR"
            },
            {
                "name": "Champdeniers-Saint-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Champniers",
                "countryCode": "FR"
            },
            {
                "name": "Chancelade",
                "countryCode": "FR"
            },
            {
                "name": "Chaniers",
                "countryCode": "FR"
            },
            {
                "name": "Chaptelat",
                "countryCode": "FR"
            },
            {
                "name": "Charente",
                "countryCode": "FR"
            },
            {
                "name": "Charente-Maritime",
                "countryCode": "FR"
            },
            {
                "name": "Charron",
                "countryCode": "FR"
            },
            {
                "name": "Charroux",
                "countryCode": "FR"
            },
            {
                "name": "Chasseneuil-du-Poitou",
                "countryCode": "FR"
            },
            {
                "name": "Chasseneuil-sur-Bonnieure",
                "countryCode": "FR"
            },
            {
                "name": "Chassors",
                "countryCode": "FR"
            },
            {
                "name": "Chaunay",
                "countryCode": "FR"
            },
            {
                "name": "Chauray",
                "countryCode": "FR"
            },
            {
                "name": "Chauvigny",
                "countryCode": "FR"
            },
            {
                "name": "Chazelles",
                "countryCode": "FR"
            },
            {
                "name": "Chef-Boutonne",
                "countryCode": "FR"
            },
            {
                "name": "Chermignac",
                "countryCode": "FR"
            },
            {
                "name": "Cherves-Richemont",
                "countryCode": "FR"
            },
            {
                "name": "Cherveux",
                "countryCode": "FR"
            },
            {
                "name": "Chevanceaux",
                "countryCode": "FR"
            },
            {
                "name": "Chiché",
                "countryCode": "FR"
            },
            {
                "name": "Châlus",
                "countryCode": "FR"
            },
            {
                "name": "Châteaubernard",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-sur-Charente",
                "countryCode": "FR"
            },
            {
                "name": "Châteauponsac",
                "countryCode": "FR"
            },
            {
                "name": "Châtelaillon-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Châtellerault",
                "countryCode": "FR"
            },
            {
                "name": "Châtillon-sur-Thouet",
                "countryCode": "FR"
            },
            {
                "name": "Chérac",
                "countryCode": "FR"
            },
            {
                "name": "Chéraute",
                "countryCode": "FR"
            },
            {
                "name": "Ciboure",
                "countryCode": "FR"
            },
            {
                "name": "Ciré-d’Aunis",
                "countryCode": "FR"
            },
            {
                "name": "Cissé",
                "countryCode": "FR"
            },
            {
                "name": "Civray",
                "countryCode": "FR"
            },
            {
                "name": "Clairac",
                "countryCode": "FR"
            },
            {
                "name": "Clérac",
                "countryCode": "FR"
            },
            {
                "name": "Coarraze",
                "countryCode": "FR"
            },
            {
                "name": "Cognac",
                "countryCode": "FR"
            },
            {
                "name": "Cognac-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Colayrac-Saint-Cirq",
                "countryCode": "FR"
            },
            {
                "name": "Colombiers",
                "countryCode": "FR"
            },
            {
                "name": "Combrand",
                "countryCode": "FR"
            },
            {
                "name": "Compreignac",
                "countryCode": "FR"
            },
            {
                "name": "Condat-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Confolens",
                "countryCode": "FR"
            },
            {
                "name": "Corme-Royal",
                "countryCode": "FR"
            },
            {
                "name": "Cornil",
                "countryCode": "FR"
            },
            {
                "name": "Corrèze",
                "countryCode": "FR"
            },
            {
                "name": "Cosnac",
                "countryCode": "FR"
            },
            {
                "name": "Couhé",
                "countryCode": "FR"
            },
            {
                "name": "Coulombiers",
                "countryCode": "FR"
            },
            {
                "name": "Coulon",
                "countryCode": "FR"
            },
            {
                "name": "Coulonges-sur-l’Autize",
                "countryCode": "FR"
            },
            {
                "name": "Coulounieix-Chamiers",
                "countryCode": "FR"
            },
            {
                "name": "Courlay",
                "countryCode": "FR"
            },
            {
                "name": "Cours-de-Pile",
                "countryCode": "FR"
            },
            {
                "name": "Coursac",
                "countryCode": "FR"
            },
            {
                "name": "Courçon",
                "countryCode": "FR"
            },
            {
                "name": "Coussac-Bonneval",
                "countryCode": "FR"
            },
            {
                "name": "Coutras",
                "countryCode": "FR"
            },
            {
                "name": "Couzeix",
                "countryCode": "FR"
            },
            {
                "name": "Cozes",
                "countryCode": "FR"
            },
            {
                "name": "Creuse",
                "countryCode": "FR"
            },
            {
                "name": "Creysse",
                "countryCode": "FR"
            },
            {
                "name": "Créon",
                "countryCode": "FR"
            },
            {
                "name": "Cublac",
                "countryCode": "FR"
            },
            {
                "name": "Cubzac-les-Ponts",
                "countryCode": "FR"
            },
            {
                "name": "Cussac",
                "countryCode": "FR"
            },
            {
                "name": "Cussac-Fort-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Cénac",
                "countryCode": "FR"
            },
            {
                "name": "Cénac-et-Saint-Julien",
                "countryCode": "FR"
            },
            {
                "name": "Cérons",
                "countryCode": "FR"
            },
            {
                "name": "Cézac",
                "countryCode": "FR"
            },
            {
                "name": "Damazan",
                "countryCode": "FR"
            },
            {
                "name": "Dangé-Saint-Romain",
                "countryCode": "FR"
            },
            {
                "name": "Dax",
                "countryCode": "FR"
            },
            {
                "name": "Denguin",
                "countryCode": "FR"
            },
            {
                "name": "Dignac",
                "countryCode": "FR"
            },
            {
                "name": "Dirac",
                "countryCode": "FR"
            },
            {
                "name": "Dissay",
                "countryCode": "FR"
            },
            {
                "name": "Dolus-d'Oléron",
                "countryCode": "FR"
            },
            {
                "name": "Domme",
                "countryCode": "FR"
            },
            {
                "name": "Dompierre-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Donzenac",
                "countryCode": "FR"
            },
            {
                "name": "Dordogne",
                "countryCode": "FR"
            },
            {
                "name": "Dun-le-Palestel",
                "countryCode": "FR"
            },
            {
                "name": "Duras",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Corrèze",
                "countryCode": "FR"
            },
            {
                "name": "Département des Deux-Sèvres",
                "countryCode": "FR"
            },
            {
                "name": "Département des Pyrénées-Atlantiques",
                "countryCode": "FR"
            },
            {
                "name": "Département du Lot-et-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Esnandes",
                "countryCode": "FR"
            },
            {
                "name": "Espelette",
                "countryCode": "FR"
            },
            {
                "name": "Estillac",
                "countryCode": "FR"
            },
            {
                "name": "Excideuil",
                "countryCode": "FR"
            },
            {
                "name": "Exideuil",
                "countryCode": "FR"
            },
            {
                "name": "Exireuil",
                "countryCode": "FR"
            },
            {
                "name": "Eymet",
                "countryCode": "FR"
            },
            {
                "name": "Eymoutiers",
                "countryCode": "FR"
            },
            {
                "name": "Eysines",
                "countryCode": "FR"
            },
            {
                "name": "Eyvigues-et-Eybènes",
                "countryCode": "FR"
            },
            {
                "name": "Fargues-Saint-Hilaire",
                "countryCode": "FR"
            },
            {
                "name": "Felletin",
                "countryCode": "FR"
            },
            {
                "name": "Feytiat",
                "countryCode": "FR"
            },
            {
                "name": "Floirac",
                "countryCode": "FR"
            },
            {
                "name": "Fléac",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Fontcouverte",
                "countryCode": "FR"
            },
            {
                "name": "Fors",
                "countryCode": "FR"
            },
            {
                "name": "Foulayronnes",
                "countryCode": "FR"
            },
            {
                "name": "Fouras",
                "countryCode": "FR"
            },
            {
                "name": "Fourques-sur-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Fronsac",
                "countryCode": "FR"
            },
            {
                "name": "Frontenay-Rohan-Rohan",
                "countryCode": "FR"
            },
            {
                "name": "Fumel",
                "countryCode": "FR"
            },
            {
                "name": "Gabarret",
                "countryCode": "FR"
            },
            {
                "name": "Gaillan-en-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Galgon",
                "countryCode": "FR"
            },
            {
                "name": "Gan",
                "countryCode": "FR"
            },
            {
                "name": "Garat",
                "countryCode": "FR"
            },
            {
                "name": "Gardonne",
                "countryCode": "FR"
            },
            {
                "name": "Garlin",
                "countryCode": "FR"
            },
            {
                "name": "Gauriaguet",
                "countryCode": "FR"
            },
            {
                "name": "Gelos",
                "countryCode": "FR"
            },
            {
                "name": "Gensac-la-Pallue",
                "countryCode": "FR"
            },
            {
                "name": "Ger",
                "countryCode": "FR"
            },
            {
                "name": "Gironde",
                "countryCode": "FR"
            },
            {
                "name": "Gironde-sur-Dropt",
                "countryCode": "FR"
            },
            {
                "name": "Gond-Pontouvre",
                "countryCode": "FR"
            },
            {
                "name": "Gontaud-de-Nogaret",
                "countryCode": "FR"
            },
            {
                "name": "Gouzon",
                "countryCode": "FR"
            },
            {
                "name": "Gradignan",
                "countryCode": "FR"
            },
            {
                "name": "Grenade-sur-l’Adour",
                "countryCode": "FR"
            },
            {
                "name": "Grignols",
                "countryCode": "FR"
            },
            {
                "name": "Gujan-Mestras",
                "countryCode": "FR"
            },
            {
                "name": "Guéret",
                "countryCode": "FR"
            },
            {
                "name": "Guéthary",
                "countryCode": "FR"
            },
            {
                "name": "Guîtres",
                "countryCode": "FR"
            },
            {
                "name": "Gémozac",
                "countryCode": "FR"
            },
            {
                "name": "Génissac",
                "countryCode": "FR"
            },
            {
                "name": "Habas",
                "countryCode": "FR"
            },
            {
                "name": "Hagetmau",
                "countryCode": "FR"
            },
            {
                "name": "Hasparren",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Hautefort",
                "countryCode": "FR"
            },
            {
                "name": "Hendaye",
                "countryCode": "FR"
            },
            {
                "name": "Heugas",
                "countryCode": "FR"
            },
            {
                "name": "Hiersac",
                "countryCode": "FR"
            },
            {
                "name": "Hinx",
                "countryCode": "FR"
            },
            {
                "name": "Hourtin",
                "countryCode": "FR"
            },
            {
                "name": "Idron",
                "countryCode": "FR"
            },
            {
                "name": "Igon",
                "countryCode": "FR"
            },
            {
                "name": "Illats",
                "countryCode": "FR"
            },
            {
                "name": "Isle",
                "countryCode": "FR"
            },
            {
                "name": "Iteuil",
                "countryCode": "FR"
            },
            {
                "name": "Itxassou",
                "countryCode": "FR"
            },
            {
                "name": "Izon",
                "countryCode": "FR"
            },
            {
                "name": "Jarnac",
                "countryCode": "FR"
            },
            {
                "name": "Jaunay-Clan",
                "countryCode": "FR"
            },
            {
                "name": "Jonzac",
                "countryCode": "FR"
            },
            {
                "name": "Juillac",
                "countryCode": "FR"
            },
            {
                "name": "Jumilhac-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Jurançon",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Laurent",
                "countryCode": "FR"
            },
            {
                "name": "La Coquille",
                "countryCode": "FR"
            },
            {
                "name": "La Couarde-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Couronne",
                "countryCode": "FR"
            },
            {
                "name": "La Courtine",
                "countryCode": "FR"
            },
            {
                "name": "La Crèche",
                "countryCode": "FR"
            },
            {
                "name": "La Flotte",
                "countryCode": "FR"
            },
            {
                "name": "La Force",
                "countryCode": "FR"
            },
            {
                "name": "La Forêt-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "La Jarne",
                "countryCode": "FR"
            },
            {
                "name": "La Jarrie",
                "countryCode": "FR"
            },
            {
                "name": "La Lande-de-Fronsac",
                "countryCode": "FR"
            },
            {
                "name": "La Mothe-Saint-Héray",
                "countryCode": "FR"
            },
            {
                "name": "La Peyratte",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Chalais",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-Posay",
                "countryCode": "FR"
            },
            {
                "name": "La Rochefoucauld",
                "countryCode": "FR"
            },
            {
                "name": "La Rochelle",
                "countryCode": "FR"
            },
            {
                "name": "La Réole",
                "countryCode": "FR"
            },
            {
                "name": "La Sauve",
                "countryCode": "FR"
            },
            {
                "name": "La Souterraine",
                "countryCode": "FR"
            },
            {
                "name": "La Teste-de-Buch",
                "countryCode": "FR"
            },
            {
                "name": "La Tremblade",
                "countryCode": "FR"
            },
            {
                "name": "La Villedieu-du-Clain",
                "countryCode": "FR"
            },
            {
                "name": "Labatut",
                "countryCode": "FR"
            },
            {
                "name": "Labenne",
                "countryCode": "FR"
            },
            {
                "name": "Labouheyre",
                "countryCode": "FR"
            },
            {
                "name": "Lacanau",
                "countryCode": "FR"
            },
            {
                "name": "Ladignac-le-Long",
                "countryCode": "FR"
            },
            {
                "name": "Lagor",
                "countryCode": "FR"
            },
            {
                "name": "Lagorce",
                "countryCode": "FR"
            },
            {
                "name": "Lagord",
                "countryCode": "FR"
            },
            {
                "name": "Laguenne",
                "countryCode": "FR"
            },
            {
                "name": "Lahonce",
                "countryCode": "FR"
            },
            {
                "name": "Lalinde",
                "countryCode": "FR"
            },
            {
                "name": "Lamarque",
                "countryCode": "FR"
            },
            {
                "name": "Lamonzie-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Lamothe-Montravel",
                "countryCode": "FR"
            },
            {
                "name": "Landes",
                "countryCode": "FR"
            },
            {
                "name": "Landiras",
                "countryCode": "FR"
            },
            {
                "name": "Langoiran",
                "countryCode": "FR"
            },
            {
                "name": "Langon",
                "countryCode": "FR"
            },
            {
                "name": "Lanouaille",
                "countryCode": "FR"
            },
            {
                "name": "Lanton",
                "countryCode": "FR"
            },
            {
                "name": "Laplume",
                "countryCode": "FR"
            },
            {
                "name": "Larche",
                "countryCode": "FR"
            },
            {
                "name": "Laroque-Timbaut",
                "countryCode": "FR"
            },
            {
                "name": "Larressore",
                "countryCode": "FR"
            },
            {
                "name": "Laruns",
                "countryCode": "FR"
            },
            {
                "name": "Laruscade",
                "countryCode": "FR"
            },
            {
                "name": "Lasseube",
                "countryCode": "FR"
            },
            {
                "name": "Lathus-Saint-Rémy",
                "countryCode": "FR"
            },
            {
                "name": "Latillé",
                "countryCode": "FR"
            },
            {
                "name": "Latresne",
                "countryCode": "FR"
            },
            {
                "name": "Lavardac",
                "countryCode": "FR"
            },
            {
                "name": "Lavoux",
                "countryCode": "FR"
            },
            {
                "name": "Layrac",
                "countryCode": "FR"
            },
            {
                "name": "Le Barp",
                "countryCode": "FR"
            },
            {
                "name": "Le Bois-Plage-en-Ré",
                "countryCode": "FR"
            },
            {
                "name": "Le Bouscat",
                "countryCode": "FR"
            },
            {
                "name": "Le Bugue",
                "countryCode": "FR"
            },
            {
                "name": "Le Buisson-de-Cadouin",
                "countryCode": "FR"
            },
            {
                "name": "Le Dorat",
                "countryCode": "FR"
            },
            {
                "name": "Le Fleix",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Le Gua",
                "countryCode": "FR"
            },
            {
                "name": "Le Haillan",
                "countryCode": "FR"
            },
            {
                "name": "Le Lardin-Saint-Lazare",
                "countryCode": "FR"
            },
            {
                "name": "Le Mas-d’Agenais",
                "countryCode": "FR"
            },
            {
                "name": "Le Palais-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Le Passage",
                "countryCode": "FR"
            },
            {
                "name": "Le Pian-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Le Pin",
                "countryCode": "FR"
            },
            {
                "name": "Le Pizou",
                "countryCode": "FR"
            },
            {
                "name": "Le Porge",
                "countryCode": "FR"
            },
            {
                "name": "Le Taillan-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Le Tallud",
                "countryCode": "FR"
            },
            {
                "name": "Le Teich",
                "countryCode": "FR"
            },
            {
                "name": "Le Thou",
                "countryCode": "FR"
            },
            {
                "name": "Le Verdon-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Le Vigen",
                "countryCode": "FR"
            },
            {
                "name": "Ledeuix",
                "countryCode": "FR"
            },
            {
                "name": "Lembras",
                "countryCode": "FR"
            },
            {
                "name": "Lencloître",
                "countryCode": "FR"
            },
            {
                "name": "Les Artigues-de-Lussac",
                "countryCode": "FR"
            },
            {
                "name": "Les Gonds",
                "countryCode": "FR"
            },
            {
                "name": "Les Mathes",
                "countryCode": "FR"
            },
            {
                "name": "Les Ormes",
                "countryCode": "FR"
            },
            {
                "name": "Les Peintures",
                "countryCode": "FR"
            },
            {
                "name": "Les Églisottes-et-Chalaures",
                "countryCode": "FR"
            },
            {
                "name": "Lescar",
                "countryCode": "FR"
            },
            {
                "name": "Lesparre-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Lestelle-Bétharram",
                "countryCode": "FR"
            },
            {
                "name": "Lezay",
                "countryCode": "FR"
            },
            {
                "name": "Libourne",
                "countryCode": "FR"
            },
            {
                "name": "Ligugé",
                "countryCode": "FR"
            },
            {
                "name": "Limoges",
                "countryCode": "FR"
            },
            {
                "name": "Linards",
                "countryCode": "FR"
            },
            {
                "name": "Linars",
                "countryCode": "FR"
            },
            {
                "name": "Linxe",
                "countryCode": "FR"
            },
            {
                "name": "Listrac-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Lit-et-Mixe",
                "countryCode": "FR"
            },
            {
                "name": "Lons",
                "countryCode": "FR"
            },
            {
                "name": "Lormont",
                "countryCode": "FR"
            },
            {
                "name": "Loubert",
                "countryCode": "FR"
            },
            {
                "name": "Loudun",
                "countryCode": "FR"
            },
            {
                "name": "Loupiac",
                "countryCode": "FR"
            },
            {
                "name": "Louvie-Juzon",
                "countryCode": "FR"
            },
            {
                "name": "Louzy",
                "countryCode": "FR"
            },
            {
                "name": "Lubersac",
                "countryCode": "FR"
            },
            {
                "name": "Lucq-de-Béarn",
                "countryCode": "FR"
            },
            {
                "name": "Ludon-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Lugon-et-l'Île-du-Carnay",
                "countryCode": "FR"
            },
            {
                "name": "Lusignan",
                "countryCode": "FR"
            },
            {
                "name": "Lussac",
                "countryCode": "FR"
            },
            {
                "name": "Lussac-les-Châteaux",
                "countryCode": "FR"
            },
            {
                "name": "Léognan",
                "countryCode": "FR"
            },
            {
                "name": "Léon",
                "countryCode": "FR"
            },
            {
                "name": "Macau",
                "countryCode": "FR"
            },
            {
                "name": "Magescq",
                "countryCode": "FR"
            },
            {
                "name": "Magnac-Laval",
                "countryCode": "FR"
            },
            {
                "name": "Magnac-sur-Touvre",
                "countryCode": "FR"
            },
            {
                "name": "Magné",
                "countryCode": "FR"
            },
            {
                "name": "Malemort-sur-Corrèze",
                "countryCode": "FR"
            },
            {
                "name": "Mansac",
                "countryCode": "FR"
            },
            {
                "name": "Mansle",
                "countryCode": "FR"
            },
            {
                "name": "Marans",
                "countryCode": "FR"
            },
            {
                "name": "Marcamps",
                "countryCode": "FR"
            },
            {
                "name": "Marcheprime",
                "countryCode": "FR"
            },
            {
                "name": "Marcillac",
                "countryCode": "FR"
            },
            {
                "name": "Marennes",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil",
                "countryCode": "FR"
            },
            {
                "name": "Margaux",
                "countryCode": "FR"
            },
            {
                "name": "Marigny-Brizay",
                "countryCode": "FR"
            },
            {
                "name": "Marmande",
                "countryCode": "FR"
            },
            {
                "name": "Marsilly",
                "countryCode": "FR"
            },
            {
                "name": "Martignas-sur-Jalle",
                "countryCode": "FR"
            },
            {
                "name": "Martillac",
                "countryCode": "FR"
            },
            {
                "name": "Matha",
                "countryCode": "FR"
            },
            {
                "name": "Mauléon-Licharre",
                "countryCode": "FR"
            },
            {
                "name": "Mauzé-Thouarsais",
                "countryCode": "FR"
            },
            {
                "name": "Mauzé-sur-le-Mignon",
                "countryCode": "FR"
            },
            {
                "name": "Mazères-Lezons",
                "countryCode": "FR"
            },
            {
                "name": "Meilhan",
                "countryCode": "FR"
            },
            {
                "name": "Meilhan-sur-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Melle",
                "countryCode": "FR"
            },
            {
                "name": "Mensignac",
                "countryCode": "FR"
            },
            {
                "name": "Meschers-sur-Gironde",
                "countryCode": "FR"
            },
            {
                "name": "Meursac",
                "countryCode": "FR"
            },
            {
                "name": "Meymac",
                "countryCode": "FR"
            },
            {
                "name": "Meyssac",
                "countryCode": "FR"
            },
            {
                "name": "Mignaloux-Beauvoir",
                "countryCode": "FR"
            },
            {
                "name": "Migné-Auxances",
                "countryCode": "FR"
            },
            {
                "name": "Mimbaste",
                "countryCode": "FR"
            },
            {
                "name": "Mimizan",
                "countryCode": "FR"
            },
            {
                "name": "Mios",
                "countryCode": "FR"
            },
            {
                "name": "Mirambeau",
                "countryCode": "FR"
            },
            {
                "name": "Miramont-de-Guyenne",
                "countryCode": "FR"
            },
            {
                "name": "Mirebeau",
                "countryCode": "FR"
            },
            {
                "name": "Mirepeix",
                "countryCode": "FR"
            },
            {
                "name": "Monbazillac",
                "countryCode": "FR"
            },
            {
                "name": "Moncontour",
                "countryCode": "FR"
            },
            {
                "name": "Moncoutant",
                "countryCode": "FR"
            },
            {
                "name": "Monein",
                "countryCode": "FR"
            },
            {
                "name": "Monflanquin",
                "countryCode": "FR"
            },
            {
                "name": "Monsempron-Libos",
                "countryCode": "FR"
            },
            {
                "name": "Monségur",
                "countryCode": "FR"
            },
            {
                "name": "Mont",
                "countryCode": "FR"
            },
            {
                "name": "Mont-de-Marsan",
                "countryCode": "FR"
            },
            {
                "name": "Montagne",
                "countryCode": "FR"
            },
            {
                "name": "Montamisé",
                "countryCode": "FR"
            },
            {
                "name": "Montardon",
                "countryCode": "FR"
            },
            {
                "name": "Montaut",
                "countryCode": "FR"
            },
            {
                "name": "Montayral",
                "countryCode": "FR"
            },
            {
                "name": "Montbron",
                "countryCode": "FR"
            },
            {
                "name": "Montcaret",
                "countryCode": "FR"
            },
            {
                "name": "Montendre",
                "countryCode": "FR"
            },
            {
                "name": "Montfort-en-Chalosse",
                "countryCode": "FR"
            },
            {
                "name": "Montguyon",
                "countryCode": "FR"
            },
            {
                "name": "Montignac",
                "countryCode": "FR"
            },
            {
                "name": "Montlieu-la-Garde",
                "countryCode": "FR"
            },
            {
                "name": "Montmoreau-Saint-Cybard",
                "countryCode": "FR"
            },
            {
                "name": "Montmorillon",
                "countryCode": "FR"
            },
            {
                "name": "Montpon-Ménestérol",
                "countryCode": "FR"
            },
            {
                "name": "Montrem",
                "countryCode": "FR"
            },
            {
                "name": "Montussan",
                "countryCode": "FR"
            },
            {
                "name": "Morcenx",
                "countryCode": "FR"
            },
            {
                "name": "Morlaas",
                "countryCode": "FR"
            },
            {
                "name": "Mornac",
                "countryCode": "FR"
            },
            {
                "name": "Mortagne-sur-Gironde",
                "countryCode": "FR"
            },
            {
                "name": "Mougon",
                "countryCode": "FR"
            },
            {
                "name": "Mouguerre",
                "countryCode": "FR"
            },
            {
                "name": "Mouleydier",
                "countryCode": "FR"
            },
            {
                "name": "Mouliets-et-Villemartin",
                "countryCode": "FR"
            },
            {
                "name": "Moulis-en-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Mourenx",
                "countryCode": "FR"
            },
            {
                "name": "Mouthiers-sur-Boëme",
                "countryCode": "FR"
            },
            {
                "name": "Mugron",
                "countryCode": "FR"
            },
            {
                "name": "Muron",
                "countryCode": "FR"
            },
            {
                "name": "Mussidan",
                "countryCode": "FR"
            },
            {
                "name": "Médis",
                "countryCode": "FR"
            },
            {
                "name": "Mées",
                "countryCode": "FR"
            },
            {
                "name": "Ménesplet",
                "countryCode": "FR"
            },
            {
                "name": "Mérignac",
                "countryCode": "FR"
            },
            {
                "name": "Mézin",
                "countryCode": "FR"
            },
            {
                "name": "Naintré",
                "countryCode": "FR"
            },
            {
                "name": "Nanteuil",
                "countryCode": "FR"
            },
            {
                "name": "Nanteuil-en-Vallée",
                "countryCode": "FR"
            },
            {
                "name": "Nantiat",
                "countryCode": "FR"
            },
            {
                "name": "Narrosse",
                "countryCode": "FR"
            },
            {
                "name": "Navailles-Angos",
                "countryCode": "FR"
            },
            {
                "name": "Navarrenx",
                "countryCode": "FR"
            },
            {
                "name": "Naves",
                "countryCode": "FR"
            },
            {
                "name": "Nay",
                "countryCode": "FR"
            },
            {
                "name": "Nercillac",
                "countryCode": "FR"
            },
            {
                "name": "Nersac",
                "countryCode": "FR"
            },
            {
                "name": "Neuvic",
                "countryCode": "FR"
            },
            {
                "name": "Neuvic-Entier",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-de-Poitou",
                "countryCode": "FR"
            },
            {
                "name": "Nexon",
                "countryCode": "FR"
            },
            {
                "name": "Nieul",
                "countryCode": "FR"
            },
            {
                "name": "Nieul-lès-Saintes",
                "countryCode": "FR"
            },
            {
                "name": "Nieul-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Niort",
                "countryCode": "FR"
            },
            {
                "name": "Noaillan",
                "countryCode": "FR"
            },
            {
                "name": "Nontron",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Sanilhac",
                "countryCode": "FR"
            },
            {
                "name": "Nouaillé-Maupertuis",
                "countryCode": "FR"
            },
            {
                "name": "Nousty",
                "countryCode": "FR"
            },
            {
                "name": "Nueil-les-Aubiers",
                "countryCode": "FR"
            },
            {
                "name": "Nérac",
                "countryCode": "FR"
            },
            {
                "name": "Objat",
                "countryCode": "FR"
            },
            {
                "name": "Oeyreluy",
                "countryCode": "FR"
            },
            {
                "name": "Ogeu-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Oloron-Sainte-Marie",
                "countryCode": "FR"
            },
            {
                "name": "Ondres",
                "countryCode": "FR"
            },
            {
                "name": "Onesse-Laharie",
                "countryCode": "FR"
            },
            {
                "name": "Oradour-sur-Glane",
                "countryCode": "FR"
            },
            {
                "name": "Oradour-sur-Vayres",
                "countryCode": "FR"
            },
            {
                "name": "Orthez",
                "countryCode": "FR"
            },
            {
                "name": "Ousse",
                "countryCode": "FR"
            },
            {
                "name": "Paillet",
                "countryCode": "FR"
            },
            {
                "name": "Pamproux",
                "countryCode": "FR"
            },
            {
                "name": "Panazol",
                "countryCode": "FR"
            },
            {
                "name": "Parempuyre",
                "countryCode": "FR"
            },
            {
                "name": "Parentis-en-Born",
                "countryCode": "FR"
            },
            {
                "name": "Parthenay",
                "countryCode": "FR"
            },
            {
                "name": "Pau",
                "countryCode": "FR"
            },
            {
                "name": "Pauillac",
                "countryCode": "FR"
            },
            {
                "name": "Payzac",
                "countryCode": "FR"
            },
            {
                "name": "Pellegrue",
                "countryCode": "FR"
            },
            {
                "name": "Pessac",
                "countryCode": "FR"
            },
            {
                "name": "Peujard",
                "countryCode": "FR"
            },
            {
                "name": "Peyrat-de-Bellac",
                "countryCode": "FR"
            },
            {
                "name": "Peyrat-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Peyrehorade",
                "countryCode": "FR"
            },
            {
                "name": "Peyrilhac",
                "countryCode": "FR"
            },
            {
                "name": "Pierre-Buffière",
                "countryCode": "FR"
            },
            {
                "name": "Pissos",
                "countryCode": "FR"
            },
            {
                "name": "Piégut-Pluviers",
                "countryCode": "FR"
            },
            {
                "name": "Pleumartin",
                "countryCode": "FR"
            },
            {
                "name": "Podensac",
                "countryCode": "FR"
            },
            {
                "name": "Poey-de-Lescar",
                "countryCode": "FR"
            },
            {
                "name": "Poitiers",
                "countryCode": "FR"
            },
            {
                "name": "Pomarez",
                "countryCode": "FR"
            },
            {
                "name": "Pompaire",
                "countryCode": "FR"
            },
            {
                "name": "Pompignac",
                "countryCode": "FR"
            },
            {
                "name": "Pons",
                "countryCode": "FR"
            },
            {
                "name": "Pont-du-Casse",
                "countryCode": "FR"
            },
            {
                "name": "Pont-l’Abbé-d’Arnoult",
                "countryCode": "FR"
            },
            {
                "name": "Pontacq",
                "countryCode": "FR"
            },
            {
                "name": "Pontenx-les-Forges",
                "countryCode": "FR"
            },
            {
                "name": "Pontonx-sur-l'Adour",
                "countryCode": "FR"
            },
            {
                "name": "Port-Sainte-Foy-et-Ponchapt",
                "countryCode": "FR"
            },
            {
                "name": "Port-Sainte-Marie",
                "countryCode": "FR"
            },
            {
                "name": "Port-des-Barques",
                "countryCode": "FR"
            },
            {
                "name": "Portets",
                "countryCode": "FR"
            },
            {
                "name": "Pouillon",
                "countryCode": "FR"
            },
            {
                "name": "Prahecq",
                "countryCode": "FR"
            },
            {
                "name": "Preignac",
                "countryCode": "FR"
            },
            {
                "name": "Prigonrieux",
                "countryCode": "FR"
            },
            {
                "name": "Préchac",
                "countryCode": "FR"
            },
            {
                "name": "Pugnac",
                "countryCode": "FR"
            },
            {
                "name": "Puilboreau",
                "countryCode": "FR"
            },
            {
                "name": "Pujols",
                "countryCode": "FR"
            },
            {
                "name": "Puymoyen",
                "countryCode": "FR"
            },
            {
                "name": "Puyoô",
                "countryCode": "FR"
            },
            {
                "name": "Pérignac",
                "countryCode": "FR"
            },
            {
                "name": "Périgny",
                "countryCode": "FR"
            },
            {
                "name": "Périgueux",
                "countryCode": "FR"
            },
            {
                "name": "Quinsac",
                "countryCode": "FR"
            },
            {
                "name": "Rauzan",
                "countryCode": "FR"
            },
            {
                "name": "Razac-sur-l’Isle",
                "countryCode": "FR"
            },
            {
                "name": "Razès",
                "countryCode": "FR"
            },
            {
                "name": "Reignac",
                "countryCode": "FR"
            },
            {
                "name": "Ribérac",
                "countryCode": "FR"
            },
            {
                "name": "Rilhac-Rancon",
                "countryCode": "FR"
            },
            {
                "name": "Rion-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Rions",
                "countryCode": "FR"
            },
            {
                "name": "Rivedoux-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Rivière-Saas-et-Gourby",
                "countryCode": "FR"
            },
            {
                "name": "Rivières",
                "countryCode": "FR"
            },
            {
                "name": "Rochechouart",
                "countryCode": "FR"
            },
            {
                "name": "Rochefort",
                "countryCode": "FR"
            },
            {
                "name": "Roches-Prémarie-Andillé",
                "countryCode": "FR"
            },
            {
                "name": "Roquefort",
                "countryCode": "FR"
            },
            {
                "name": "Rouffignac-Saint-Cernin-de-Reilhac",
                "countryCode": "FR"
            },
            {
                "name": "Rouillac",
                "countryCode": "FR"
            },
            {
                "name": "Rouillé",
                "countryCode": "FR"
            },
            {
                "name": "Roullet-Saint-Estèphe",
                "countryCode": "FR"
            },
            {
                "name": "Roumazières-Loubert",
                "countryCode": "FR"
            },
            {
                "name": "Royan",
                "countryCode": "FR"
            },
            {
                "name": "Ruelle-sur-Touvre",
                "countryCode": "FR"
            },
            {
                "name": "Ruffec",
                "countryCode": "FR"
            },
            {
                "name": "Sablonceaux",
                "countryCode": "FR"
            },
            {
                "name": "Sablons",
                "countryCode": "FR"
            },
            {
                "name": "Sabres",
                "countryCode": "FR"
            },
            {
                "name": "Sadirac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Agnant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Agnant-de-Versillat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aigulin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amant-de-Boixe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-de-Seignanx",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Antoine-de-Breuilh",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Astier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-de-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-le-Cloud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aulaye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Benoît",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brice",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brice-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Caprais-de-Bordeaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christoly-de-Blaye",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ciers-d’Abzac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ciers-sur-Gironde",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Claud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clément",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyprien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-de-Pile",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-d’Oléron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Estèphe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gelais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gence",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Genis-de-Saintonge",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Didonne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-des-Coteaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-lès-Baillargeaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geours-de-Maremne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-de-Lusignan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-de-Marencennes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-du-Puch",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-les-Belles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais-les-Trois-Clochers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Villefranche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-la-Palud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hippolyte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-Pied-de-Port",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Liversay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Luz",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Sauves",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Thouars",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-d’Illac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jouvent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-en-Born",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Junien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-Luzac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just-le-Martel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-la-Prée",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-sur-Gorre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Loubès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Louis-de-Montferrand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-de-Montbrun",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léon-sur-l’Isle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léonard-de-Noblat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Macaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maixant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mariens",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Lacaussade",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Hinx",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Ré",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Seignanx",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mathieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-la-Clouère",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maurice-la-Souterraine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maxire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mexant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Morillon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Médard-de-Guizières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Médard-de-Mussidan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Médard-d’Eyrans",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Médard-en-Jalles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Même-les-Carrières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Palais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Palais-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pantaléon-de-Larche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pardoux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pardoux-Isaac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pardoux-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-lès-Dax",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Perdon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-des-Échaubrognes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-du-Mont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-d’Aurillac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-d’Oléron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Porchaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Priest-Taurion",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Priest-sous-Aixe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Privat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Projet-Saint-Constant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pée-sur-Nivelle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-de-Baron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rogatien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-de-Benet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauvant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-d’Aunis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Savin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Savinien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Selve",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Seurin-sur-l’Isle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sever",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-de-Cognac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-de-Faleyrens",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-de-Royan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-et-Cameyrac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-le-Guérétois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-les-Feuilles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sylvestre-sur-Lot",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Symphorien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Trojan-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Varent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vaury",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Viance",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Victurnien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-de-Paul",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-de-Tyrosse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vite",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vivien-de-Médoc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Xandre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yrieix-la-Perche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yrieix-sur-Charente",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Yzan-de-Soudiac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Émilion",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Baïgorry",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Bazeille",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Colombe-en-Bruilhois",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Eulalie",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Feyre",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Fortunade",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Foy-la-Grande",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Féréole",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Hélène",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Livrade-sur-Lot",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-de-Gosse",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-de-Ré",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Radegonde",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Soulle",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Terre",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Verge",
                "countryCode": "FR"
            },
            {
                "name": "Saintes",
                "countryCode": "FR"
            },
            {
                "name": "Saivres",
                "countryCode": "FR"
            },
            {
                "name": "Salies-de-Béarn",
                "countryCode": "FR"
            },
            {
                "name": "Salignac",
                "countryCode": "FR"
            },
            {
                "name": "Salignac-Eyvigues",
                "countryCode": "FR"
            },
            {
                "name": "Sallebœuf",
                "countryCode": "FR"
            },
            {
                "name": "Salles",
                "countryCode": "FR"
            },
            {
                "name": "Salles-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Samadet",
                "countryCode": "FR"
            },
            {
                "name": "Sanguinet",
                "countryCode": "FR"
            },
            {
                "name": "Sarbazan",
                "countryCode": "FR"
            },
            {
                "name": "Sare",
                "countryCode": "FR"
            },
            {
                "name": "Sarlat-la-Canéda",
                "countryCode": "FR"
            },
            {
                "name": "Saubion",
                "countryCode": "FR"
            },
            {
                "name": "Saubrigues",
                "countryCode": "FR"
            },
            {
                "name": "Saucats",
                "countryCode": "FR"
            },
            {
                "name": "Saugnac-et-Cambran",
                "countryCode": "FR"
            },
            {
                "name": "Saujon",
                "countryCode": "FR"
            },
            {
                "name": "Saulgé",
                "countryCode": "FR"
            },
            {
                "name": "Sauvagnon",
                "countryCode": "FR"
            },
            {
                "name": "Sauveterre-de-Béarn",
                "countryCode": "FR"
            },
            {
                "name": "Sauveterre-de-Guyenne",
                "countryCode": "FR"
            },
            {
                "name": "Sauviat-sur-Vige",
                "countryCode": "FR"
            },
            {
                "name": "Sauzé-Vaussais",
                "countryCode": "FR"
            },
            {
                "name": "Savigny-Lévescault",
                "countryCode": "FR"
            },
            {
                "name": "Savigné",
                "countryCode": "FR"
            },
            {
                "name": "Scorbé-Clairvaux",
                "countryCode": "FR"
            },
            {
                "name": "Secondigny",
                "countryCode": "FR"
            },
            {
                "name": "Segonzac",
                "countryCode": "FR"
            },
            {
                "name": "Seignosse",
                "countryCode": "FR"
            },
            {
                "name": "Seilhac",
                "countryCode": "FR"
            },
            {
                "name": "Semussac",
                "countryCode": "FR"
            },
            {
                "name": "Serres-Castet",
                "countryCode": "FR"
            },
            {
                "name": "Sireuil",
                "countryCode": "FR"
            },
            {
                "name": "Smarves",
                "countryCode": "FR"
            },
            {
                "name": "Solignac",
                "countryCode": "FR"
            },
            {
                "name": "Soorts-Hossegor",
                "countryCode": "FR"
            },
            {
                "name": "Sorges",
                "countryCode": "FR"
            },
            {
                "name": "Soubise",
                "countryCode": "FR"
            },
            {
                "name": "Soulac-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Soumoulou",
                "countryCode": "FR"
            },
            {
                "name": "Souprosse",
                "countryCode": "FR"
            },
            {
                "name": "Souraïde",
                "countryCode": "FR"
            },
            {
                "name": "Sourzac",
                "countryCode": "FR"
            },
            {
                "name": "Soussans",
                "countryCode": "FR"
            },
            {
                "name": "Soustons",
                "countryCode": "FR"
            },
            {
                "name": "Soyaux",
                "countryCode": "FR"
            },
            {
                "name": "Surgères",
                "countryCode": "FR"
            },
            {
                "name": "Sèvres-Anxaumont",
                "countryCode": "FR"
            },
            {
                "name": "Séreilhac",
                "countryCode": "FR"
            },
            {
                "name": "Tabanac",
                "countryCode": "FR"
            },
            {
                "name": "Talence",
                "countryCode": "FR"
            },
            {
                "name": "Taponnat-Fleurignac",
                "countryCode": "FR"
            },
            {
                "name": "Targon",
                "countryCode": "FR"
            },
            {
                "name": "Tarnos",
                "countryCode": "FR"
            },
            {
                "name": "Tartas",
                "countryCode": "FR"
            },
            {
                "name": "Tauriac",
                "countryCode": "FR"
            },
            {
                "name": "Tercis-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Tercé",
                "countryCode": "FR"
            },
            {
                "name": "Terrasson-Lavilledieu",
                "countryCode": "FR"
            },
            {
                "name": "Thairé",
                "countryCode": "FR"
            },
            {
                "name": "Thenon",
                "countryCode": "FR"
            },
            {
                "name": "Thiviers",
                "countryCode": "FR"
            },
            {
                "name": "Thorigné",
                "countryCode": "FR"
            },
            {
                "name": "Thouars",
                "countryCode": "FR"
            },
            {
                "name": "Thuré",
                "countryCode": "FR"
            },
            {
                "name": "Thénac",
                "countryCode": "FR"
            },
            {
                "name": "Thénezay",
                "countryCode": "FR"
            },
            {
                "name": "Tocane-Saint-Apre",
                "countryCode": "FR"
            },
            {
                "name": "Tonnay-Boutonne",
                "countryCode": "FR"
            },
            {
                "name": "Tonnay-Charente",
                "countryCode": "FR"
            },
            {
                "name": "Tonneins",
                "countryCode": "FR"
            },
            {
                "name": "Tosse",
                "countryCode": "FR"
            },
            {
                "name": "Toulenne",
                "countryCode": "FR"
            },
            {
                "name": "Touvre",
                "countryCode": "FR"
            },
            {
                "name": "Treignac",
                "countryCode": "FR"
            },
            {
                "name": "Tresses",
                "countryCode": "FR"
            },
            {
                "name": "Trizay",
                "countryCode": "FR"
            },
            {
                "name": "Trélissac",
                "countryCode": "FR"
            },
            {
                "name": "Tulle",
                "countryCode": "FR"
            },
            {
                "name": "Urcuit",
                "countryCode": "FR"
            },
            {
                "name": "Urrugne",
                "countryCode": "FR"
            },
            {
                "name": "Urt",
                "countryCode": "FR"
            },
            {
                "name": "Ussac",
                "countryCode": "FR"
            },
            {
                "name": "Ussel",
                "countryCode": "FR"
            },
            {
                "name": "Usson-du-Poitou",
                "countryCode": "FR"
            },
            {
                "name": "Ustaritz",
                "countryCode": "FR"
            },
            {
                "name": "Uzein",
                "countryCode": "FR"
            },
            {
                "name": "Uzerche",
                "countryCode": "FR"
            },
            {
                "name": "Varetz",
                "countryCode": "FR"
            },
            {
                "name": "Vars",
                "countryCode": "FR"
            },
            {
                "name": "Vasles",
                "countryCode": "FR"
            },
            {
                "name": "Vaux-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Vayres",
                "countryCode": "FR"
            },
            {
                "name": "Vendays-Montalivet",
                "countryCode": "FR"
            },
            {
                "name": "Vendeuvre-du-Poitou",
                "countryCode": "FR"
            },
            {
                "name": "Vergt",
                "countryCode": "FR"
            },
            {
                "name": "Verneuil-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Vertheuil",
                "countryCode": "FR"
            },
            {
                "name": "Veyrac",
                "countryCode": "FR"
            },
            {
                "name": "Vianne",
                "countryCode": "FR"
            },
            {
                "name": "Vicq-sur-Breuilh",
                "countryCode": "FR"
            },
            {
                "name": "Vielle-Saint-Girons",
                "countryCode": "FR"
            },
            {
                "name": "Viennay",
                "countryCode": "FR"
            },
            {
                "name": "Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Vieux-Boucau-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Vigeois",
                "countryCode": "FR"
            },
            {
                "name": "Villefagnan",
                "countryCode": "FR"
            },
            {
                "name": "Villefranque",
                "countryCode": "FR"
            },
            {
                "name": "Villegouge",
                "countryCode": "FR"
            },
            {
                "name": "Villenave-d’Ornon",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-de-Marsan",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-sur-Lot",
                "countryCode": "FR"
            },
            {
                "name": "Villeréal",
                "countryCode": "FR"
            },
            {
                "name": "Villiers-en-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Virazeil",
                "countryCode": "FR"
            },
            {
                "name": "Vivonne",
                "countryCode": "FR"
            },
            {
                "name": "Vouillé",
                "countryCode": "FR"
            },
            {
                "name": "Vouneuil-sous-Biard",
                "countryCode": "FR"
            },
            {
                "name": "Vouneuil-sur-Vienne",
                "countryCode": "FR"
            },
            {
                "name": "Voutezac",
                "countryCode": "FR"
            },
            {
                "name": "Vélines",
                "countryCode": "FR"
            },
            {
                "name": "Vérines",
                "countryCode": "FR"
            },
            {
                "name": "Vœuil-et-Giget",
                "countryCode": "FR"
            },
            {
                "name": "Ychoux",
                "countryCode": "FR"
            },
            {
                "name": "Ygos-Saint-Saturnin",
                "countryCode": "FR"
            },
            {
                "name": "Yves",
                "countryCode": "FR"
            },
            {
                "name": "Yvrac",
                "countryCode": "FR"
            },
            {
                "name": "Échillais",
                "countryCode": "FR"
            },
            {
                "name": "Échiré",
                "countryCode": "FR"
            },
            {
                "name": "Écoyeux",
                "countryCode": "FR"
            },
            {
                "name": "Égletons",
                "countryCode": "FR"
            },
            {
                "name": "Étagnac",
                "countryCode": "FR"
            },
            {
                "name": "Étaules",
                "countryCode": "FR"
            },
            {
                "name": "Étauliers",
                "countryCode": "FR"
            },
            {
                "name": "Évaux-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Ablon",
                "countryCode": "FR"
            },
            {
                "name": "Acquigny",
                "countryCode": "FR"
            },
            {
                "name": "Agneaux",
                "countryCode": "FR"
            },
            {
                "name": "Agon-Coutainville",
                "countryCode": "FR"
            },
            {
                "name": "Alençon",
                "countryCode": "FR"
            },
            {
                "name": "Alizay",
                "countryCode": "FR"
            },
            {
                "name": "Amfreville",
                "countryCode": "FR"
            },
            {
                "name": "Amfreville-la-Mi-Voie",
                "countryCode": "FR"
            },
            {
                "name": "Andé",
                "countryCode": "FR"
            },
            {
                "name": "Angerville-l’Orcher",
                "countryCode": "FR"
            },
            {
                "name": "Argences",
                "countryCode": "FR"
            },
            {
                "name": "Argentan",
                "countryCode": "FR"
            },
            {
                "name": "Arnières-sur-Iton",
                "countryCode": "FR"
            },
            {
                "name": "Arques-la-Bataille",
                "countryCode": "FR"
            },
            {
                "name": "Athis-de-l'Orne",
                "countryCode": "FR"
            },
            {
                "name": "Aube-sur-Rîle",
                "countryCode": "FR"
            },
            {
                "name": "Aubevoye",
                "countryCode": "FR"
            },
            {
                "name": "Auffay",
                "countryCode": "FR"
            },
            {
                "name": "Aumale",
                "countryCode": "FR"
            },
            {
                "name": "Aunay-sur-Odon",
                "countryCode": "FR"
            },
            {
                "name": "Authie",
                "countryCode": "FR"
            },
            {
                "name": "Auzebosc",
                "countryCode": "FR"
            },
            {
                "name": "Aviron",
                "countryCode": "FR"
            },
            {
                "name": "Avranches",
                "countryCode": "FR"
            },
            {
                "name": "Bacqueville-en-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Bagnoles-de-l'Orne",
                "countryCode": "FR"
            },
            {
                "name": "Barentin",
                "countryCode": "FR"
            },
            {
                "name": "Barenton",
                "countryCode": "FR"
            },
            {
                "name": "Barneville-Carteret",
                "countryCode": "FR"
            },
            {
                "name": "Barneville-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Bavent",
                "countryCode": "FR"
            },
            {
                "name": "Bayeux",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-Hague",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-le-Roger",
                "countryCode": "FR"
            },
            {
                "name": "Belbeuf",
                "countryCode": "FR"
            },
            {
                "name": "Bellengreville",
                "countryCode": "FR"
            },
            {
                "name": "Bellême",
                "countryCode": "FR"
            },
            {
                "name": "Bernay",
                "countryCode": "FR"
            },
            {
                "name": "Berneval-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Bernières-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Beuville",
                "countryCode": "FR"
            },
            {
                "name": "Beuvillers",
                "countryCode": "FR"
            },
            {
                "name": "Beuzeville",
                "countryCode": "FR"
            },
            {
                "name": "Beuzeville-la-Grenier",
                "countryCode": "FR"
            },
            {
                "name": "Bihorel",
                "countryCode": "FR"
            },
            {
                "name": "Biéville-Beuville",
                "countryCode": "FR"
            },
            {
                "name": "Blainville-Crevon",
                "countryCode": "FR"
            },
            {
                "name": "Blainville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Blainville-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Blangy-sur-Bresle",
                "countryCode": "FR"
            },
            {
                "name": "Blonville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Bois-Guillaume",
                "countryCode": "FR"
            },
            {
                "name": "Bolbec",
                "countryCode": "FR"
            },
            {
                "name": "Boos",
                "countryCode": "FR"
            },
            {
                "name": "Bosc-le-Hard",
                "countryCode": "FR"
            },
            {
                "name": "Bourg-Achard",
                "countryCode": "FR"
            },
            {
                "name": "Bourgtheroulde-Infreville",
                "countryCode": "FR"
            },
            {
                "name": "Bourguébus",
                "countryCode": "FR"
            },
            {
                "name": "Bourth",
                "countryCode": "FR"
            },
            {
                "name": "Bouville",
                "countryCode": "FR"
            },
            {
                "name": "Breteuil",
                "countryCode": "FR"
            },
            {
                "name": "Bretoncelles",
                "countryCode": "FR"
            },
            {
                "name": "Bretteville",
                "countryCode": "FR"
            },
            {
                "name": "Bretteville-du-Grand-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Bretteville-l’Orgueilleuse",
                "countryCode": "FR"
            },
            {
                "name": "Bretteville-sur-Laize",
                "countryCode": "FR"
            },
            {
                "name": "Bretteville-sur-Odon",
                "countryCode": "FR"
            },
            {
                "name": "Breuilpont",
                "countryCode": "FR"
            },
            {
                "name": "Bricquebec",
                "countryCode": "FR"
            },
            {
                "name": "Brionne",
                "countryCode": "FR"
            },
            {
                "name": "Briouze",
                "countryCode": "FR"
            },
            {
                "name": "Brix",
                "countryCode": "FR"
            },
            {
                "name": "Broglie",
                "countryCode": "FR"
            },
            {
                "name": "Bréauté",
                "countryCode": "FR"
            },
            {
                "name": "Brécey",
                "countryCode": "FR"
            },
            {
                "name": "Bréhal",
                "countryCode": "FR"
            },
            {
                "name": "Buchy",
                "countryCode": "FR"
            },
            {
                "name": "Bueil",
                "countryCode": "FR"
            },
            {
                "name": "Bully",
                "countryCode": "FR"
            },
            {
                "name": "Bénouville",
                "countryCode": "FR"
            },
            {
                "name": "Bézu-Saint-Éloi",
                "countryCode": "FR"
            },
            {
                "name": "Cabourg",
                "countryCode": "FR"
            },
            {
                "name": "Caen",
                "countryCode": "FR"
            },
            {
                "name": "Cagny",
                "countryCode": "FR"
            },
            {
                "name": "Cahagnes",
                "countryCode": "FR"
            },
            {
                "name": "Cairon",
                "countryCode": "FR"
            },
            {
                "name": "Calvados",
                "countryCode": "FR"
            },
            {
                "name": "Cambes-en-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Cambremer",
                "countryCode": "FR"
            },
            {
                "name": "Canon",
                "countryCode": "FR"
            },
            {
                "name": "Canteleu",
                "countryCode": "FR"
            },
            {
                "name": "Cany-Barville",
                "countryCode": "FR"
            },
            {
                "name": "Carentan",
                "countryCode": "FR"
            },
            {
                "name": "Carpiquet",
                "countryCode": "FR"
            },
            {
                "name": "Caudebec-en-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Caudebec-lès-Elbeuf",
                "countryCode": "FR"
            },
            {
                "name": "Caumont",
                "countryCode": "FR"
            },
            {
                "name": "Caumont-l'Éventé",
                "countryCode": "FR"
            },
            {
                "name": "Cauville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Ceaucé",
                "countryCode": "FR"
            },
            {
                "name": "Cerisy-la-Salle",
                "countryCode": "FR"
            },
            {
                "name": "Ceton",
                "countryCode": "FR"
            },
            {
                "name": "Champsecret",
                "countryCode": "FR"
            },
            {
                "name": "Chanu",
                "countryCode": "FR"
            },
            {
                "name": "Charleval",
                "countryCode": "FR"
            },
            {
                "name": "Cherbourg-Octeville",
                "countryCode": "FR"
            },
            {
                "name": "Cheux",
                "countryCode": "FR"
            },
            {
                "name": "Claville",
                "countryCode": "FR"
            },
            {
                "name": "Clinchamps-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Clères",
                "countryCode": "FR"
            },
            {
                "name": "Clécy",
                "countryCode": "FR"
            },
            {
                "name": "Cléon",
                "countryCode": "FR"
            },
            {
                "name": "Colleville-Montgomery",
                "countryCode": "FR"
            },
            {
                "name": "Colombelles",
                "countryCode": "FR"
            },
            {
                "name": "Conches-en-Ouche",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-Huisne",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-Noireau",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Condé-sur-Vire",
                "countryCode": "FR"
            },
            {
                "name": "Cormeilles",
                "countryCode": "FR"
            },
            {
                "name": "Cormelles-le-Royal",
                "countryCode": "FR"
            },
            {
                "name": "Corneville-sur-Risle",
                "countryCode": "FR"
            },
            {
                "name": "Courcelles-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Courseulles-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Courteilles",
                "countryCode": "FR"
            },
            {
                "name": "Coutances",
                "countryCode": "FR"
            },
            {
                "name": "Couterne",
                "countryCode": "FR"
            },
            {
                "name": "Cresserons",
                "countryCode": "FR"
            },
            {
                "name": "Creully",
                "countryCode": "FR"
            },
            {
                "name": "Criel-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Criquebeuf-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Criquetot-l’Esneval",
                "countryCode": "FR"
            },
            {
                "name": "Croth",
                "countryCode": "FR"
            },
            {
                "name": "Créances",
                "countryCode": "FR"
            },
            {
                "name": "Cuverville",
                "countryCode": "FR"
            },
            {
                "name": "Cérences",
                "countryCode": "FR"
            },
            {
                "name": "Damigny",
                "countryCode": "FR"
            },
            {
                "name": "Damville",
                "countryCode": "FR"
            },
            {
                "name": "Darnétal",
                "countryCode": "FR"
            },
            {
                "name": "Deauville",
                "countryCode": "FR"
            },
            {
                "name": "Dieppe",
                "countryCode": "FR"
            },
            {
                "name": "Digosville",
                "countryCode": "FR"
            },
            {
                "name": "Dives-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Domfront",
                "countryCode": "FR"
            },
            {
                "name": "Donville-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Doudeville",
                "countryCode": "FR"
            },
            {
                "name": "Douvres-la-Délivrande",
                "countryCode": "FR"
            },
            {
                "name": "Dozulé",
                "countryCode": "FR"
            },
            {
                "name": "Ducey",
                "countryCode": "FR"
            },
            {
                "name": "Duclair",
                "countryCode": "FR"
            },
            {
                "name": "Démouville",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Eure",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Orne",
                "countryCode": "FR"
            },
            {
                "name": "Déville-lès-Rouen",
                "countryCode": "FR"
            },
            {
                "name": "Elbeuf",
                "countryCode": "FR"
            },
            {
                "name": "Envermeu",
                "countryCode": "FR"
            },
            {
                "name": "Eslettes",
                "countryCode": "FR"
            },
            {
                "name": "Eu",
                "countryCode": "FR"
            },
            {
                "name": "Falaise",
                "countryCode": "FR"
            },
            {
                "name": "Fauville-en-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Fermanville",
                "countryCode": "FR"
            },
            {
                "name": "Flamanville",
                "countryCode": "FR"
            },
            {
                "name": "Flers",
                "countryCode": "FR"
            },
            {
                "name": "Fleury-sur-Andelle",
                "countryCode": "FR"
            },
            {
                "name": "Fleury-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-la-Mallet",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-le-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-le-Dun",
                "countryCode": "FR"
            },
            {
                "name": "Fontaine-Étoupefour",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-le-Marmion",
                "countryCode": "FR"
            },
            {
                "name": "Forges-les-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Foucarmont",
                "countryCode": "FR"
            },
            {
                "name": "Francheville",
                "countryCode": "FR"
            },
            {
                "name": "Fécamp",
                "countryCode": "FR"
            },
            {
                "name": "Gacé",
                "countryCode": "FR"
            },
            {
                "name": "Gaillefontaine",
                "countryCode": "FR"
            },
            {
                "name": "Gaillon",
                "countryCode": "FR"
            },
            {
                "name": "Gainneville",
                "countryCode": "FR"
            },
            {
                "name": "Garennes-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Gasny",
                "countryCode": "FR"
            },
            {
                "name": "Gavray",
                "countryCode": "FR"
            },
            {
                "name": "Giberville",
                "countryCode": "FR"
            },
            {
                "name": "Gisors",
                "countryCode": "FR"
            },
            {
                "name": "Goderville",
                "countryCode": "FR"
            },
            {
                "name": "Gonneville-la-Mallet",
                "countryCode": "FR"
            },
            {
                "name": "Gournay-en-Bray",
                "countryCode": "FR"
            },
            {
                "name": "Gouville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Grainville-la-Teinturière",
                "countryCode": "FR"
            },
            {
                "name": "Grainville-sur-Odon",
                "countryCode": "FR"
            },
            {
                "name": "Grand-Couronne",
                "countryCode": "FR"
            },
            {
                "name": "Grandcamp-Maisy",
                "countryCode": "FR"
            },
            {
                "name": "Granville",
                "countryCode": "FR"
            },
            {
                "name": "Gravigny",
                "countryCode": "FR"
            },
            {
                "name": "Gruchet-le-Valasse",
                "countryCode": "FR"
            },
            {
                "name": "Guichainville",
                "countryCode": "FR"
            },
            {
                "name": "Hambye",
                "countryCode": "FR"
            },
            {
                "name": "Harfleur",
                "countryCode": "FR"
            },
            {
                "name": "Hautot-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Hauville",
                "countryCode": "FR"
            },
            {
                "name": "Hermanville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Heudreville-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Honfleur",
                "countryCode": "FR"
            },
            {
                "name": "Houlbec-Cocherel",
                "countryCode": "FR"
            },
            {
                "name": "Houlgate",
                "countryCode": "FR"
            },
            {
                "name": "Houppeville",
                "countryCode": "FR"
            },
            {
                "name": "Hénouville",
                "countryCode": "FR"
            },
            {
                "name": "Hérouville-Saint-Clair",
                "countryCode": "FR"
            },
            {
                "name": "Hérouvillette",
                "countryCode": "FR"
            },
            {
                "name": "Ifs",
                "countryCode": "FR"
            },
            {
                "name": "Igoville",
                "countryCode": "FR"
            },
            {
                "name": "Incheville",
                "countryCode": "FR"
            },
            {
                "name": "Isigny-le-Buat",
                "countryCode": "FR"
            },
            {
                "name": "Isigny-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Isneauville",
                "countryCode": "FR"
            },
            {
                "name": "Ivry-la-Bataille",
                "countryCode": "FR"
            },
            {
                "name": "Jullouville",
                "countryCode": "FR"
            },
            {
                "name": "Jumièges",
                "countryCode": "FR"
            },
            {
                "name": "Juvigny-sous-Andaine",
                "countryCode": "FR"
            },
            {
                "name": "La Bonneville-sur-Iton",
                "countryCode": "FR"
            },
            {
                "name": "La Cerlangue",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Réanville",
                "countryCode": "FR"
            },
            {
                "name": "La Couture-Boussey",
                "countryCode": "FR"
            },
            {
                "name": "La Ferrière-aux-Étangs",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Macé",
                "countryCode": "FR"
            },
            {
                "name": "La Feuillie",
                "countryCode": "FR"
            },
            {
                "name": "La Glacerie",
                "countryCode": "FR"
            },
            {
                "name": "La Haye-Malherbe",
                "countryCode": "FR"
            },
            {
                "name": "La Haye-Pesnel",
                "countryCode": "FR"
            },
            {
                "name": "La Haye-du-Puits",
                "countryCode": "FR"
            },
            {
                "name": "La Lande-Patry",
                "countryCode": "FR"
            },
            {
                "name": "La Madeleine-de-Nonancourt",
                "countryCode": "FR"
            },
            {
                "name": "La Mailleraye-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "La Meauffe",
                "countryCode": "FR"
            },
            {
                "name": "La Remuée",
                "countryCode": "FR"
            },
            {
                "name": "La Rivière-Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "La Saussaye",
                "countryCode": "FR"
            },
            {
                "name": "La Selle-la-Forge",
                "countryCode": "FR"
            },
            {
                "name": "La Vaupalière",
                "countryCode": "FR"
            },
            {
                "name": "La Vespière",
                "countryCode": "FR"
            },
            {
                "name": "Langrune-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Lanquetot",
                "countryCode": "FR"
            },
            {
                "name": "Le Bosc-Roger-en-Roumois",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Quevilly",
                "countryCode": "FR"
            },
            {
                "name": "Le Havre",
                "countryCode": "FR"
            },
            {
                "name": "Le Houlme",
                "countryCode": "FR"
            },
            {
                "name": "Le Manoir",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-Esnard",
                "countryCode": "FR"
            },
            {
                "name": "Le Molay-Littry",
                "countryCode": "FR"
            },
            {
                "name": "Le Neubourg",
                "countryCode": "FR"
            },
            {
                "name": "Le Petit-Quevilly",
                "countryCode": "FR"
            },
            {
                "name": "Le Teilleul",
                "countryCode": "FR"
            },
            {
                "name": "Le Theil-Bocage",
                "countryCode": "FR"
            },
            {
                "name": "Le Thuit-Signol",
                "countryCode": "FR"
            },
            {
                "name": "Le Trait",
                "countryCode": "FR"
            },
            {
                "name": "Le Tréport",
                "countryCode": "FR"
            },
            {
                "name": "Le Val-Saint-Père",
                "countryCode": "FR"
            },
            {
                "name": "Le Vaudreuil",
                "countryCode": "FR"
            },
            {
                "name": "Les Andelys",
                "countryCode": "FR"
            },
            {
                "name": "Les Authieux-sur-le-Port-Saint-Ouen",
                "countryCode": "FR"
            },
            {
                "name": "Les Grandes-Ventes",
                "countryCode": "FR"
            },
            {
                "name": "Les Loges",
                "countryCode": "FR"
            },
            {
                "name": "Les Loges-Marchis",
                "countryCode": "FR"
            },
            {
                "name": "Les Pieux",
                "countryCode": "FR"
            },
            {
                "name": "Lessay",
                "countryCode": "FR"
            },
            {
                "name": "Lieurey",
                "countryCode": "FR"
            },
            {
                "name": "Lillebonne",
                "countryCode": "FR"
            },
            {
                "name": "Limésy",
                "countryCode": "FR"
            },
            {
                "name": "Lion-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Lisieux",
                "countryCode": "FR"
            },
            {
                "name": "Livarot",
                "countryCode": "FR"
            },
            {
                "name": "Londinières",
                "countryCode": "FR"
            },
            {
                "name": "Longny-au-Perche",
                "countryCode": "FR"
            },
            {
                "name": "Louviers",
                "countryCode": "FR"
            },
            {
                "name": "Louvigny",
                "countryCode": "FR"
            },
            {
                "name": "Luc-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Luneray",
                "countryCode": "FR"
            },
            {
                "name": "Léry",
                "countryCode": "FR"
            },
            {
                "name": "Magny-le-Désert",
                "countryCode": "FR"
            },
            {
                "name": "Malaunay",
                "countryCode": "FR"
            },
            {
                "name": "Manche",
                "countryCode": "FR"
            },
            {
                "name": "Manneville-sur-Risle",
                "countryCode": "FR"
            },
            {
                "name": "Manéglise",
                "countryCode": "FR"
            },
            {
                "name": "Marcey-les-Grèves",
                "countryCode": "FR"
            },
            {
                "name": "Marcilly-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Marigny",
                "countryCode": "FR"
            },
            {
                "name": "Maromme",
                "countryCode": "FR"
            },
            {
                "name": "Martin-Église",
                "countryCode": "FR"
            },
            {
                "name": "Martinvast",
                "countryCode": "FR"
            },
            {
                "name": "Mathieu",
                "countryCode": "FR"
            },
            {
                "name": "May-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Menneval",
                "countryCode": "FR"
            },
            {
                "name": "Merville-Franceville-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Mesnières-en-Bray",
                "countryCode": "FR"
            },
            {
                "name": "Messei",
                "countryCode": "FR"
            },
            {
                "name": "Mondeville",
                "countryCode": "FR"
            },
            {
                "name": "Mont-Saint-Aignan",
                "countryCode": "FR"
            },
            {
                "name": "Montaure",
                "countryCode": "FR"
            },
            {
                "name": "Montebourg",
                "countryCode": "FR"
            },
            {
                "name": "Montigny",
                "countryCode": "FR"
            },
            {
                "name": "Montivilliers",
                "countryCode": "FR"
            },
            {
                "name": "Montmain",
                "countryCode": "FR"
            },
            {
                "name": "Montmartin-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Mortagne-au-Perche",
                "countryCode": "FR"
            },
            {
                "name": "Mortain",
                "countryCode": "FR"
            },
            {
                "name": "Mortrée",
                "countryCode": "FR"
            },
            {
                "name": "Mouen",
                "countryCode": "FR"
            },
            {
                "name": "Moult",
                "countryCode": "FR"
            },
            {
                "name": "Moyaux",
                "countryCode": "FR"
            },
            {
                "name": "Ménilles",
                "countryCode": "FR"
            },
            {
                "name": "Nassandres",
                "countryCode": "FR"
            },
            {
                "name": "Neaufles-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Neufchâtel-en-Bray",
                "countryCode": "FR"
            },
            {
                "name": "Nointot",
                "countryCode": "FR"
            },
            {
                "name": "Nonancourt",
                "countryCode": "FR"
            },
            {
                "name": "Normanville",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Bondeville",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Gravenchon",
                "countryCode": "FR"
            },
            {
                "name": "Néville",
                "countryCode": "FR"
            },
            {
                "name": "Octeville",
                "countryCode": "FR"
            },
            {
                "name": "Octeville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Offranville",
                "countryCode": "FR"
            },
            {
                "name": "Oissel",
                "countryCode": "FR"
            },
            {
                "name": "Orbec",
                "countryCode": "FR"
            },
            {
                "name": "Orival",
                "countryCode": "FR"
            },
            {
                "name": "Ouistreham",
                "countryCode": "FR"
            },
            {
                "name": "Ourville-en-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Pacy-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Parigny",
                "countryCode": "FR"
            },
            {
                "name": "Pavilly",
                "countryCode": "FR"
            },
            {
                "name": "Percy",
                "countryCode": "FR"
            },
            {
                "name": "Perriers-sur-Andelle",
                "countryCode": "FR"
            },
            {
                "name": "Petit-Couronne",
                "countryCode": "FR"
            },
            {
                "name": "Petiville",
                "countryCode": "FR"
            },
            {
                "name": "Picauville",
                "countryCode": "FR"
            },
            {
                "name": "Pirou",
                "countryCode": "FR"
            },
            {
                "name": "Pissy-Pôville",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Audemer",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Hébert",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Pont-d'Ouilly",
                "countryCode": "FR"
            },
            {
                "name": "Pont-l’Évêque",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Écrepin",
                "countryCode": "FR"
            },
            {
                "name": "Pontorson",
                "countryCode": "FR"
            },
            {
                "name": "Port-en-Bessin-Huppain",
                "countryCode": "FR"
            },
            {
                "name": "Portbail",
                "countryCode": "FR"
            },
            {
                "name": "Poses",
                "countryCode": "FR"
            },
            {
                "name": "Potigny",
                "countryCode": "FR"
            },
            {
                "name": "Préaux",
                "countryCode": "FR"
            },
            {
                "name": "Putanges-Pont-Écrepin",
                "countryCode": "FR"
            },
            {
                "name": "Périers",
                "countryCode": "FR"
            },
            {
                "name": "Pîtres",
                "countryCode": "FR"
            },
            {
                "name": "Querqueville",
                "countryCode": "FR"
            },
            {
                "name": "Quettehou",
                "countryCode": "FR"
            },
            {
                "name": "Quettreville-sur-Sienne",
                "countryCode": "FR"
            },
            {
                "name": "Quillebeuf-sur-Seine",
                "countryCode": "FR"
            },
            {
                "name": "Quincampoix",
                "countryCode": "FR"
            },
            {
                "name": "Radon",
                "countryCode": "FR"
            },
            {
                "name": "Rai",
                "countryCode": "FR"
            },
            {
                "name": "Ranville",
                "countryCode": "FR"
            },
            {
                "name": "Rogerville",
                "countryCode": "FR"
            },
            {
                "name": "Rolleville",
                "countryCode": "FR"
            },
            {
                "name": "Romagny",
                "countryCode": "FR"
            },
            {
                "name": "Romilly-sur-Andelle",
                "countryCode": "FR"
            },
            {
                "name": "Roncherolles-sur-le-Vivier",
                "countryCode": "FR"
            },
            {
                "name": "Rots",
                "countryCode": "FR"
            },
            {
                "name": "Rouen",
                "countryCode": "FR"
            },
            {
                "name": "Roumare",
                "countryCode": "FR"
            },
            {
                "name": "Routot",
                "countryCode": "FR"
            },
            {
                "name": "Rouxmesnil-Bouteilles",
                "countryCode": "FR"
            },
            {
                "name": "Rugles",
                "countryCode": "FR"
            },
            {
                "name": "Rémalard",
                "countryCode": "FR"
            },
            {
                "name": "Réville",
                "countryCode": "FR"
            },
            {
                "name": "Sahurs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-sur-Orne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Arnoult",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-Routot",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-lès-Elbeuf",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-sur-Gaillon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-sur-Scie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Contest",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-sur-Sarthon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Désir",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Eustache-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gatien-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-des-Groseillers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-Village",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-du-Corbéis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-la-Blanche-Herbe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-sur-Avre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-Petitville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-du-Harcouët",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jacques-sur-Darnétal",
                "countryCode": "FR"
            },
            {
                "name": "Saint-James",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-des-Baisants",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-des-Champs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-du-Cardonnay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jouin-Bruneval",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-Brévedent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-du-Bourg-Denis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lô",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Manvieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Manvieu-Norrey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Osmonville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Boscherville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Fontenay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Landelles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-des-Besaces",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-des-Champs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Manoir",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Vivier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-en-Campagne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-de-la-Taille",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-d’Aliermont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen-de-Thouberville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen-du-Tilleul",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pair-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paër",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-de-Varengeville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-des-Fleurs",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-du-Regard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-du-Vauvray",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-en-Val",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-lès-Elbeuf",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-sur-Dives",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-Église",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Planchers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-sur-le-Homme",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Romain-de-Colbosc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-Lendelin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur-le-Vicomte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saëns",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Senier-sous-Avranches",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sever-Calvados",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-sur-Risle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sylvain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sébastien-de-Morsent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vaast-la-Hougue",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Valery-en-Caux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vigor-le-Grand",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Wandrille-Rançon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-du-Rouvray",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Adresse",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Gauburge-Sainte-Colombe",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Honorine-du-Fay",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marguerite-sur-Duclair",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-des-Champs",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Mère-Église",
                "countryCode": "FR"
            },
            {
                "name": "Sannerville",
                "countryCode": "FR"
            },
            {
                "name": "Sartilly",
                "countryCode": "FR"
            },
            {
                "name": "Seine-Maritime",
                "countryCode": "FR"
            },
            {
                "name": "Serqueux",
                "countryCode": "FR"
            },
            {
                "name": "Serquigny",
                "countryCode": "FR"
            },
            {
                "name": "Soliers",
                "countryCode": "FR"
            },
            {
                "name": "Sottevast",
                "countryCode": "FR"
            },
            {
                "name": "Sotteville-lès-Rouen",
                "countryCode": "FR"
            },
            {
                "name": "Sourdeval",
                "countryCode": "FR"
            },
            {
                "name": "Surtainville",
                "countryCode": "FR"
            },
            {
                "name": "Sées",
                "countryCode": "FR"
            },
            {
                "name": "Tancarville",
                "countryCode": "FR"
            },
            {
                "name": "Tessy-sur-Vire",
                "countryCode": "FR"
            },
            {
                "name": "Tessé-la-Madeleine",
                "countryCode": "FR"
            },
            {
                "name": "Thaon",
                "countryCode": "FR"
            },
            {
                "name": "Thiberville",
                "countryCode": "FR"
            },
            {
                "name": "Thury-Harcourt",
                "countryCode": "FR"
            },
            {
                "name": "Tillières-sur-Avre",
                "countryCode": "FR"
            },
            {
                "name": "Tilly-sur-Seulles",
                "countryCode": "FR"
            },
            {
                "name": "Tinchebray",
                "countryCode": "FR"
            },
            {
                "name": "Tollevast",
                "countryCode": "FR"
            },
            {
                "name": "Torigni-sur-Vire",
                "countryCode": "FR"
            },
            {
                "name": "Touques",
                "countryCode": "FR"
            },
            {
                "name": "Tourlaville",
                "countryCode": "FR"
            },
            {
                "name": "Tourouvre",
                "countryCode": "FR"
            },
            {
                "name": "Tourville-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Tourville-sur-Arques",
                "countryCode": "FR"
            },
            {
                "name": "Tourville-sur-Odon",
                "countryCode": "FR"
            },
            {
                "name": "Toutainville",
                "countryCode": "FR"
            },
            {
                "name": "Troarn",
                "countryCode": "FR"
            },
            {
                "name": "Trouville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Trun",
                "countryCode": "FR"
            },
            {
                "name": "Turretot",
                "countryCode": "FR"
            },
            {
                "name": "Tôtes",
                "countryCode": "FR"
            },
            {
                "name": "Urville-Nacqueville",
                "countryCode": "FR"
            },
            {
                "name": "Val-de-Reuil",
                "countryCode": "FR"
            },
            {
                "name": "Valframbert",
                "countryCode": "FR"
            },
            {
                "name": "Valliquerville",
                "countryCode": "FR"
            },
            {
                "name": "Valognes",
                "countryCode": "FR"
            },
            {
                "name": "Varengeville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Vassy",
                "countryCode": "FR"
            },
            {
                "name": "Vaudry",
                "countryCode": "FR"
            },
            {
                "name": "Ver-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Verneuil-sur-Avre",
                "countryCode": "FR"
            },
            {
                "name": "Vernon",
                "countryCode": "FR"
            },
            {
                "name": "Verson",
                "countryCode": "FR"
            },
            {
                "name": "Villedieu-les-Poêles",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Bocage",
                "countryCode": "FR"
            },
            {
                "name": "Villers-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Villers-Écalles",
                "countryCode": "FR"
            },
            {
                "name": "Vimoutiers",
                "countryCode": "FR"
            },
            {
                "name": "Vire",
                "countryCode": "FR"
            },
            {
                "name": "Yainville",
                "countryCode": "FR"
            },
            {
                "name": "Yerville",
                "countryCode": "FR"
            },
            {
                "name": "Ymare",
                "countryCode": "FR"
            },
            {
                "name": "Yport",
                "countryCode": "FR"
            },
            {
                "name": "Yvetot",
                "countryCode": "FR"
            },
            {
                "name": "Yébleron",
                "countryCode": "FR"
            },
            {
                "name": "Écouché",
                "countryCode": "FR"
            },
            {
                "name": "Écrainville",
                "countryCode": "FR"
            },
            {
                "name": "Épaignes",
                "countryCode": "FR"
            },
            {
                "name": "Épouville",
                "countryCode": "FR"
            },
            {
                "name": "Épron",
                "countryCode": "FR"
            },
            {
                "name": "Équemauville",
                "countryCode": "FR"
            },
            {
                "name": "Équeurdreville-Hainneville",
                "countryCode": "FR"
            },
            {
                "name": "Étainhus",
                "countryCode": "FR"
            },
            {
                "name": "Éterville",
                "countryCode": "FR"
            },
            {
                "name": "Étretat",
                "countryCode": "FR"
            },
            {
                "name": "Étrépagny",
                "countryCode": "FR"
            },
            {
                "name": "Évrecy",
                "countryCode": "FR"
            },
            {
                "name": "Évreux",
                "countryCode": "FR"
            },
            {
                "name": "Ézy-sur-Eure",
                "countryCode": "FR"
            },
            {
                "name": "Abeilhan",
                "countryCode": "FR"
            },
            {
                "name": "Agde",
                "countryCode": "FR"
            },
            {
                "name": "Aiguefonde",
                "countryCode": "FR"
            },
            {
                "name": "Aigues-Mortes",
                "countryCode": "FR"
            },
            {
                "name": "Aigues-Vives",
                "countryCode": "FR"
            },
            {
                "name": "Aimargues",
                "countryCode": "FR"
            },
            {
                "name": "Albi",
                "countryCode": "FR"
            },
            {
                "name": "Albias",
                "countryCode": "FR"
            },
            {
                "name": "Alignan-du-Vent",
                "countryCode": "FR"
            },
            {
                "name": "Alzonne",
                "countryCode": "FR"
            },
            {
                "name": "Alès",
                "countryCode": "FR"
            },
            {
                "name": "Alénya",
                "countryCode": "FR"
            },
            {
                "name": "Andrest",
                "countryCode": "FR"
            },
            {
                "name": "Anduze",
                "countryCode": "FR"
            },
            {
                "name": "Aniane",
                "countryCode": "FR"
            },
            {
                "name": "Aramon",
                "countryCode": "FR"
            },
            {
                "name": "Argelers",
                "countryCode": "FR"
            },
            {
                "name": "Argeliers",
                "countryCode": "FR"
            },
            {
                "name": "Argelès-Gazost",
                "countryCode": "FR"
            },
            {
                "name": "Arles",
                "countryCode": "FR"
            },
            {
                "name": "Armissan",
                "countryCode": "FR"
            },
            {
                "name": "Arthès",
                "countryCode": "FR"
            },
            {
                "name": "Arzens",
                "countryCode": "FR"
            },
            {
                "name": "Aspet",
                "countryCode": "FR"
            },
            {
                "name": "Aspiran",
                "countryCode": "FR"
            },
            {
                "name": "Assas",
                "countryCode": "FR"
            },
            {
                "name": "Aubais",
                "countryCode": "FR"
            },
            {
                "name": "Aubiet",
                "countryCode": "FR"
            },
            {
                "name": "Aubin",
                "countryCode": "FR"
            },
            {
                "name": "Aubord",
                "countryCode": "FR"
            },
            {
                "name": "Aucamville",
                "countryCode": "FR"
            },
            {
                "name": "Auch",
                "countryCode": "FR"
            },
            {
                "name": "Aumont-Aubrac",
                "countryCode": "FR"
            },
            {
                "name": "Aureilhan",
                "countryCode": "FR"
            },
            {
                "name": "Auriac-sur-Vendinelle",
                "countryCode": "FR"
            },
            {
                "name": "Aurignac",
                "countryCode": "FR"
            },
            {
                "name": "Aussillon",
                "countryCode": "FR"
            },
            {
                "name": "Aussonne",
                "countryCode": "FR"
            },
            {
                "name": "Auterive",
                "countryCode": "FR"
            },
            {
                "name": "Auvillar",
                "countryCode": "FR"
            },
            {
                "name": "Auzeville-Tolosane",
                "countryCode": "FR"
            },
            {
                "name": "Auzielle",
                "countryCode": "FR"
            },
            {
                "name": "Avignonet-Lauragais",
                "countryCode": "FR"
            },
            {
                "name": "Avèze",
                "countryCode": "FR"
            },
            {
                "name": "Ax-les-Thermes",
                "countryCode": "FR"
            },
            {
                "name": "Ayguesvives",
                "countryCode": "FR"
            },
            {
                "name": "Azille",
                "countryCode": "FR"
            },
            {
                "name": "Bagard",
                "countryCode": "FR"
            },
            {
                "name": "Bages",
                "countryCode": "FR"
            },
            {
                "name": "Bagnac-sur-Célé",
                "countryCode": "FR"
            },
            {
                "name": "Bagnols-sur-Cèze",
                "countryCode": "FR"
            },
            {
                "name": "Bagnères-de-Bigorre",
                "countryCode": "FR"
            },
            {
                "name": "Bagnères-de-Luchon",
                "countryCode": "FR"
            },
            {
                "name": "Baho",
                "countryCode": "FR"
            },
            {
                "name": "Baillargues",
                "countryCode": "FR"
            },
            {
                "name": "Baixas",
                "countryCode": "FR"
            },
            {
                "name": "Balaruc-le-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Balaruc-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Balma",
                "countryCode": "FR"
            },
            {
                "name": "Banyuls de la Marenda",
                "countryCode": "FR"
            },
            {
                "name": "Banyuls-dels-Aspres",
                "countryCode": "FR"
            },
            {
                "name": "Baraqueville",
                "countryCode": "FR"
            },
            {
                "name": "Barbazan-Debat",
                "countryCode": "FR"
            },
            {
                "name": "Barcelonne-du-Gers",
                "countryCode": "FR"
            },
            {
                "name": "Barjac",
                "countryCode": "FR"
            },
            {
                "name": "Bassan",
                "countryCode": "FR"
            },
            {
                "name": "Bazet",
                "countryCode": "FR"
            },
            {
                "name": "Baziège",
                "countryCode": "FR"
            },
            {
                "name": "Beaucaire",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-de-Lomagne",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-sur-Lèze",
                "countryCode": "FR"
            },
            {
                "name": "Beaupuy",
                "countryCode": "FR"
            },
            {
                "name": "Beauvoisin",
                "countryCode": "FR"
            },
            {
                "name": "Beauzelle",
                "countryCode": "FR"
            },
            {
                "name": "Belberaud",
                "countryCode": "FR"
            },
            {
                "name": "Bellegarde",
                "countryCode": "FR"
            },
            {
                "name": "Belmont-sur-Rance",
                "countryCode": "FR"
            },
            {
                "name": "Belpech",
                "countryCode": "FR"
            },
            {
                "name": "Bernis",
                "countryCode": "FR"
            },
            {
                "name": "Bessan",
                "countryCode": "FR"
            },
            {
                "name": "Bessières",
                "countryCode": "FR"
            },
            {
                "name": "Bessèges",
                "countryCode": "FR"
            },
            {
                "name": "Bezouce",
                "countryCode": "FR"
            },
            {
                "name": "Biars-sur-Cère",
                "countryCode": "FR"
            },
            {
                "name": "Bizanet",
                "countryCode": "FR"
            },
            {
                "name": "Bize-Minervois",
                "countryCode": "FR"
            },
            {
                "name": "Blagnac",
                "countryCode": "FR"
            },
            {
                "name": "Blauzac",
                "countryCode": "FR"
            },
            {
                "name": "Blaye-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Boisseron",
                "countryCode": "FR"
            },
            {
                "name": "Boisset-et-Gaujac",
                "countryCode": "FR"
            },
            {
                "name": "Bompas",
                "countryCode": "FR"
            },
            {
                "name": "Bouillargues",
                "countryCode": "FR"
            },
            {
                "name": "Boujan-sur-Libron",
                "countryCode": "FR"
            },
            {
                "name": "Bouloc",
                "countryCode": "FR"
            },
            {
                "name": "Boulogne-sur-Gesse",
                "countryCode": "FR"
            },
            {
                "name": "Bout-du-Pont-de-Larn",
                "countryCode": "FR"
            },
            {
                "name": "Bouzigues",
                "countryCode": "FR"
            },
            {
                "name": "Bozouls",
                "countryCode": "FR"
            },
            {
                "name": "Bram",
                "countryCode": "FR"
            },
            {
                "name": "Branoux-les-Taillades",
                "countryCode": "FR"
            },
            {
                "name": "Brassac",
                "countryCode": "FR"
            },
            {
                "name": "Brax",
                "countryCode": "FR"
            },
            {
                "name": "Brens",
                "countryCode": "FR"
            },
            {
                "name": "Bressols",
                "countryCode": "FR"
            },
            {
                "name": "Bretenoux",
                "countryCode": "FR"
            },
            {
                "name": "Briatexte",
                "countryCode": "FR"
            },
            {
                "name": "Bruguières",
                "countryCode": "FR"
            },
            {
                "name": "Burlats",
                "countryCode": "FR"
            },
            {
                "name": "Buzet-sur-Tarn",
                "countryCode": "FR"
            },
            {
                "name": "Bédarieux",
                "countryCode": "FR"
            },
            {
                "name": "Bélesta",
                "countryCode": "FR"
            },
            {
                "name": "Bérat",
                "countryCode": "FR"
            },
            {
                "name": "Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Cabestany",
                "countryCode": "FR"
            },
            {
                "name": "Cabrières",
                "countryCode": "FR"
            },
            {
                "name": "Cadalen",
                "countryCode": "FR"
            },
            {
                "name": "Cagnac-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Cahors",
                "countryCode": "FR"
            },
            {
                "name": "Cahuzac-sur-Vère",
                "countryCode": "FR"
            },
            {
                "name": "Caissargues",
                "countryCode": "FR"
            },
            {
                "name": "Cajarc",
                "countryCode": "FR"
            },
            {
                "name": "Calmont",
                "countryCode": "FR"
            },
            {
                "name": "Calvisson",
                "countryCode": "FR"
            },
            {
                "name": "Camarès",
                "countryCode": "FR"
            },
            {
                "name": "Campan",
                "countryCode": "FR"
            },
            {
                "name": "Campsas",
                "countryCode": "FR"
            },
            {
                "name": "Candillargues",
                "countryCode": "FR"
            },
            {
                "name": "Canet",
                "countryCode": "FR"
            },
            {
                "name": "Canet-en-Roussillon",
                "countryCode": "FR"
            },
            {
                "name": "Canohès",
                "countryCode": "FR"
            },
            {
                "name": "Capdenac-Gare",
                "countryCode": "FR"
            },
            {
                "name": "Capendu",
                "countryCode": "FR"
            },
            {
                "name": "Capestang",
                "countryCode": "FR"
            },
            {
                "name": "Capvern",
                "countryCode": "FR"
            },
            {
                "name": "Caraman",
                "countryCode": "FR"
            },
            {
                "name": "Carbonne",
                "countryCode": "FR"
            },
            {
                "name": "Carcassonne",
                "countryCode": "FR"
            },
            {
                "name": "Carmaux",
                "countryCode": "FR"
            },
            {
                "name": "Cassagnes-Bégonhès",
                "countryCode": "FR"
            },
            {
                "name": "Castanet-Tolosan",
                "countryCode": "FR"
            },
            {
                "name": "Castelginest",
                "countryCode": "FR"
            },
            {
                "name": "Castelmaurou",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-Montratier",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-d'Estrétefonds",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-de-Guers",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-de-Lévis",
                "countryCode": "FR"
            },
            {
                "name": "Castelnau-le-Lez",
                "countryCode": "FR"
            },
            {
                "name": "Castelnaudary",
                "countryCode": "FR"
            },
            {
                "name": "Castelsarrasin",
                "countryCode": "FR"
            },
            {
                "name": "Castillon-du-Gard",
                "countryCode": "FR"
            },
            {
                "name": "Castres",
                "countryCode": "FR"
            },
            {
                "name": "Castries",
                "countryCode": "FR"
            },
            {
                "name": "Caunes-Minervois",
                "countryCode": "FR"
            },
            {
                "name": "Caussade",
                "countryCode": "FR"
            },
            {
                "name": "Cauterets",
                "countryCode": "FR"
            },
            {
                "name": "Caux",
                "countryCode": "FR"
            },
            {
                "name": "Caveirac",
                "countryCode": "FR"
            },
            {
                "name": "Caylus",
                "countryCode": "FR"
            },
            {
                "name": "Cazaubon",
                "countryCode": "FR"
            },
            {
                "name": "Cazes-Mondenard",
                "countryCode": "FR"
            },
            {
                "name": "Cazilhac",
                "countryCode": "FR"
            },
            {
                "name": "Cazouls-lès-Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Cazères",
                "countryCode": "FR"
            },
            {
                "name": "Cendras",
                "countryCode": "FR"
            },
            {
                "name": "Ceret",
                "countryCode": "FR"
            },
            {
                "name": "Cers",
                "countryCode": "FR"
            },
            {
                "name": "Cervera de la Marenda",
                "countryCode": "FR"
            },
            {
                "name": "Cessenon-sur-Orb",
                "countryCode": "FR"
            },
            {
                "name": "Chalabre",
                "countryCode": "FR"
            },
            {
                "name": "Chanac",
                "countryCode": "FR"
            },
            {
                "name": "Chirac",
                "countryCode": "FR"
            },
            {
                "name": "Cintegabelle",
                "countryCode": "FR"
            },
            {
                "name": "Claira",
                "countryCode": "FR"
            },
            {
                "name": "Clapiers",
                "countryCode": "FR"
            },
            {
                "name": "Clarensac",
                "countryCode": "FR"
            },
            {
                "name": "Claret",
                "countryCode": "FR"
            },
            {
                "name": "Codognan",
                "countryCode": "FR"
            },
            {
                "name": "Collioure",
                "countryCode": "FR"
            },
            {
                "name": "Colombiers",
                "countryCode": "FR"
            },
            {
                "name": "Colombiès",
                "countryCode": "FR"
            },
            {
                "name": "Colomiers",
                "countryCode": "FR"
            },
            {
                "name": "Combaillaux",
                "countryCode": "FR"
            },
            {
                "name": "Comps",
                "countryCode": "FR"
            },
            {
                "name": "Condom",
                "countryCode": "FR"
            },
            {
                "name": "Congénies",
                "countryCode": "FR"
            },
            {
                "name": "Connaux",
                "countryCode": "FR"
            },
            {
                "name": "Conques-sur-Orbiel",
                "countryCode": "FR"
            },
            {
                "name": "Corbarieu",
                "countryCode": "FR"
            },
            {
                "name": "Cordes-sur-Ciel",
                "countryCode": "FR"
            },
            {
                "name": "Cornebarrieu",
                "countryCode": "FR"
            },
            {
                "name": "Corneilhan",
                "countryCode": "FR"
            },
            {
                "name": "Corneilla-del-Vercol",
                "countryCode": "FR"
            },
            {
                "name": "Corneilla-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Coufouleux",
                "countryCode": "FR"
            },
            {
                "name": "Couiza",
                "countryCode": "FR"
            },
            {
                "name": "Cournonsec",
                "countryCode": "FR"
            },
            {
                "name": "Cournonterral",
                "countryCode": "FR"
            },
            {
                "name": "Coursan",
                "countryCode": "FR"
            },
            {
                "name": "Cransac",
                "countryCode": "FR"
            },
            {
                "name": "Creissan",
                "countryCode": "FR"
            },
            {
                "name": "Creissels",
                "countryCode": "FR"
            },
            {
                "name": "Cugnaux",
                "countryCode": "FR"
            },
            {
                "name": "Cunac",
                "countryCode": "FR"
            },
            {
                "name": "Cuxac-Cabardès",
                "countryCode": "FR"
            },
            {
                "name": "Cépet",
                "countryCode": "FR"
            },
            {
                "name": "Daux",
                "countryCode": "FR"
            },
            {
                "name": "Decazeville",
                "countryCode": "FR"
            },
            {
                "name": "Dourgne",
                "countryCode": "FR"
            },
            {
                "name": "Druelle",
                "countryCode": "FR"
            },
            {
                "name": "Drémil-Lafage",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Ariège",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Aude",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Aveyron",
                "countryCode": "FR"
            },
            {
                "name": "Département de l'Hérault",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Lozère",
                "countryCode": "FR"
            },
            {
                "name": "Département des Hautes-Pyrénées",
                "countryCode": "FR"
            },
            {
                "name": "Département des Pyrénées-Orientales",
                "countryCode": "FR"
            },
            {
                "name": "Département du Tarn-et-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Eaunes",
                "countryCode": "FR"
            },
            {
                "name": "Eauze",
                "countryCode": "FR"
            },
            {
                "name": "Elne",
                "countryCode": "FR"
            },
            {
                "name": "Entraygues-sur-Truyère",
                "countryCode": "FR"
            },
            {
                "name": "Escalquens",
                "countryCode": "FR"
            },
            {
                "name": "Espalion",
                "countryCode": "FR"
            },
            {
                "name": "Espéraza",
                "countryCode": "FR"
            },
            {
                "name": "Estagel",
                "countryCode": "FR"
            },
            {
                "name": "Fabrezan",
                "countryCode": "FR"
            },
            {
                "name": "Fabrègues",
                "countryCode": "FR"
            },
            {
                "name": "Fenouillet",
                "countryCode": "FR"
            },
            {
                "name": "Ferrals-les-Corbières",
                "countryCode": "FR"
            },
            {
                "name": "Figeac",
                "countryCode": "FR"
            },
            {
                "name": "Finhan",
                "countryCode": "FR"
            },
            {
                "name": "Firmi",
                "countryCode": "FR"
            },
            {
                "name": "Flavin",
                "countryCode": "FR"
            },
            {
                "name": "Fleurance",
                "countryCode": "FR"
            },
            {
                "name": "Fleury",
                "countryCode": "FR"
            },
            {
                "name": "Florac",
                "countryCode": "FR"
            },
            {
                "name": "Florensac",
                "countryCode": "FR"
            },
            {
                "name": "Flourens",
                "countryCode": "FR"
            },
            {
                "name": "Foix",
                "countryCode": "FR"
            },
            {
                "name": "Fonbeauzard",
                "countryCode": "FR"
            },
            {
                "name": "Fonsorbes",
                "countryCode": "FR"
            },
            {
                "name": "Font-Romeu-Odeillo-Via",
                "countryCode": "FR"
            },
            {
                "name": "Fontenilles",
                "countryCode": "FR"
            },
            {
                "name": "Fourques",
                "countryCode": "FR"
            },
            {
                "name": "Frontignan",
                "countryCode": "FR"
            },
            {
                "name": "Fronton",
                "countryCode": "FR"
            },
            {
                "name": "Frouzins",
                "countryCode": "FR"
            },
            {
                "name": "Fréjairolles",
                "countryCode": "FR"
            },
            {
                "name": "Gagnac-sur-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Gaillac",
                "countryCode": "FR"
            },
            {
                "name": "Gaillac-Toulza",
                "countryCode": "FR"
            },
            {
                "name": "Gallargues-le-Montueux",
                "countryCode": "FR"
            },
            {
                "name": "Ganges",
                "countryCode": "FR"
            },
            {
                "name": "Gard",
                "countryCode": "FR"
            },
            {
                "name": "Gardouch",
                "countryCode": "FR"
            },
            {
                "name": "Garidech",
                "countryCode": "FR"
            },
            {
                "name": "Garons",
                "countryCode": "FR"
            },
            {
                "name": "Gerde",
                "countryCode": "FR"
            },
            {
                "name": "Gers",
                "countryCode": "FR"
            },
            {
                "name": "Gigean",
                "countryCode": "FR"
            },
            {
                "name": "Gignac",
                "countryCode": "FR"
            },
            {
                "name": "Gimont",
                "countryCode": "FR"
            },
            {
                "name": "Ginestas",
                "countryCode": "FR"
            },
            {
                "name": "Giroussens",
                "countryCode": "FR"
            },
            {
                "name": "Gondrin",
                "countryCode": "FR"
            },
            {
                "name": "Goudargues",
                "countryCode": "FR"
            },
            {
                "name": "Gourdan-Polignan",
                "countryCode": "FR"
            },
            {
                "name": "Gourdon",
                "countryCode": "FR"
            },
            {
                "name": "Grabels",
                "countryCode": "FR"
            },
            {
                "name": "Gragnague",
                "countryCode": "FR"
            },
            {
                "name": "Gramat",
                "countryCode": "FR"
            },
            {
                "name": "Gratentour",
                "countryCode": "FR"
            },
            {
                "name": "Graulhet",
                "countryCode": "FR"
            },
            {
                "name": "Grenade",
                "countryCode": "FR"
            },
            {
                "name": "Grisolles",
                "countryCode": "FR"
            },
            {
                "name": "Gruissan",
                "countryCode": "FR"
            },
            {
                "name": "Générac",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Horgues",
                "countryCode": "FR"
            },
            {
                "name": "Hérépian",
                "countryCode": "FR"
            },
            {
                "name": "Ibos",
                "countryCode": "FR"
            },
            {
                "name": "Ille-sur-Têt",
                "countryCode": "FR"
            },
            {
                "name": "Jacou",
                "countryCode": "FR"
            },
            {
                "name": "Jegun",
                "countryCode": "FR"
            },
            {
                "name": "Jonquières-Saint-Vincent",
                "countryCode": "FR"
            },
            {
                "name": "Juillan",
                "countryCode": "FR"
            },
            {
                "name": "Juvignac",
                "countryCode": "FR"
            },
            {
                "name": "L'Union",
                "countryCode": "FR"
            },
            {
                "name": "La Barthe-de-Neste",
                "countryCode": "FR"
            },
            {
                "name": "La Calmette",
                "countryCode": "FR"
            },
            {
                "name": "La Canourgue",
                "countryCode": "FR"
            },
            {
                "name": "La Cavalerie",
                "countryCode": "FR"
            },
            {
                "name": "La Fouillade",
                "countryCode": "FR"
            },
            {
                "name": "La Grande-Motte",
                "countryCode": "FR"
            },
            {
                "name": "La Loubière",
                "countryCode": "FR"
            },
            {
                "name": "La Palme",
                "countryCode": "FR"
            },
            {
                "name": "La Salvetat-Peyralès",
                "countryCode": "FR"
            },
            {
                "name": "La Salvetat-Saint-Gilles",
                "countryCode": "FR"
            },
            {
                "name": "La Salvetat-sur-Agout",
                "countryCode": "FR"
            },
            {
                "name": "La Tour-du-Crieu",
                "countryCode": "FR"
            },
            {
                "name": "La Tour-sur-Orb",
                "countryCode": "FR"
            },
            {
                "name": "Labarthe-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Labarthe-sur-Lèze",
                "countryCode": "FR"
            },
            {
                "name": "Labastide-Beauvoir",
                "countryCode": "FR"
            },
            {
                "name": "Labastide-Rouairoux",
                "countryCode": "FR"
            },
            {
                "name": "Labastide-Saint-Georges",
                "countryCode": "FR"
            },
            {
                "name": "Labastide-Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Labastide-Saint-Sernin",
                "countryCode": "FR"
            },
            {
                "name": "Labastidette",
                "countryCode": "FR"
            },
            {
                "name": "Labruguière",
                "countryCode": "FR"
            },
            {
                "name": "Labège",
                "countryCode": "FR"
            },
            {
                "name": "Lacapelle-Marival",
                "countryCode": "FR"
            },
            {
                "name": "Lacasse",
                "countryCode": "FR"
            },
            {
                "name": "Lacaune",
                "countryCode": "FR"
            },
            {
                "name": "Lacroix-Falgarde",
                "countryCode": "FR"
            },
            {
                "name": "Lacrouzette",
                "countryCode": "FR"
            },
            {
                "name": "Lafrançaise",
                "countryCode": "FR"
            },
            {
                "name": "Lagardelle-sur-Lèze",
                "countryCode": "FR"
            },
            {
                "name": "Lagarrigue",
                "countryCode": "FR"
            },
            {
                "name": "Lagrave",
                "countryCode": "FR"
            },
            {
                "name": "Laguiole",
                "countryCode": "FR"
            },
            {
                "name": "Laissac",
                "countryCode": "FR"
            },
            {
                "name": "Lalbenque",
                "countryCode": "FR"
            },
            {
                "name": "Laloubère",
                "countryCode": "FR"
            },
            {
                "name": "Lamagistère",
                "countryCode": "FR"
            },
            {
                "name": "Lamalou-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Langlade",
                "countryCode": "FR"
            },
            {
                "name": "Langogne",
                "countryCode": "FR"
            },
            {
                "name": "Lannemezan",
                "countryCode": "FR"
            },
            {
                "name": "Lansargues",
                "countryCode": "FR"
            },
            {
                "name": "Lanta",
                "countryCode": "FR"
            },
            {
                "name": "Lapeyrouse-Fossat",
                "countryCode": "FR"
            },
            {
                "name": "Laroque",
                "countryCode": "FR"
            },
            {
                "name": "Laroque-d’Olmes",
                "countryCode": "FR"
            },
            {
                "name": "Lasalle",
                "countryCode": "FR"
            },
            {
                "name": "Latour-Bas-Elne",
                "countryCode": "FR"
            },
            {
                "name": "Lattes",
                "countryCode": "FR"
            },
            {
                "name": "Laudun-l'Ardoise",
                "countryCode": "FR"
            },
            {
                "name": "Launac",
                "countryCode": "FR"
            },
            {
                "name": "Launaguet",
                "countryCode": "FR"
            },
            {
                "name": "Laure-Minervois",
                "countryCode": "FR"
            },
            {
                "name": "Laurens",
                "countryCode": "FR"
            },
            {
                "name": "Lautrec",
                "countryCode": "FR"
            },
            {
                "name": "Lauzerte",
                "countryCode": "FR"
            },
            {
                "name": "Laval-Pradel",
                "countryCode": "FR"
            },
            {
                "name": "Lavalette",
                "countryCode": "FR"
            },
            {
                "name": "Lavaur",
                "countryCode": "FR"
            },
            {
                "name": "Lavelanet",
                "countryCode": "FR"
            },
            {
                "name": "Lavernose-Lacasse",
                "countryCode": "FR"
            },
            {
                "name": "Lavit",
                "countryCode": "FR"
            },
            {
                "name": "Lavérune",
                "countryCode": "FR"
            },
            {
                "name": "Le Barcarès",
                "countryCode": "FR"
            },
            {
                "name": "Le Bousquet-d’Orb",
                "countryCode": "FR"
            },
            {
                "name": "Le Cailar",
                "countryCode": "FR"
            },
            {
                "name": "Le Cap d'Agde",
                "countryCode": "FR"
            },
            {
                "name": "Le Crès",
                "countryCode": "FR"
            },
            {
                "name": "Le Fauga",
                "countryCode": "FR"
            },
            {
                "name": "Le Fousseret",
                "countryCode": "FR"
            },
            {
                "name": "Le Garric",
                "countryCode": "FR"
            },
            {
                "name": "Le Grau-du-Roi",
                "countryCode": "FR"
            },
            {
                "name": "Le Houga",
                "countryCode": "FR"
            },
            {
                "name": "Le Malzieu-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Le Monastère",
                "countryCode": "FR"
            },
            {
                "name": "Le Pouget",
                "countryCode": "FR"
            },
            {
                "name": "Le Poujol-sur-Orb",
                "countryCode": "FR"
            },
            {
                "name": "Le Sequestre",
                "countryCode": "FR"
            },
            {
                "name": "Le Soler",
                "countryCode": "FR"
            },
            {
                "name": "Le Vigan",
                "countryCode": "FR"
            },
            {
                "name": "Lectoure",
                "countryCode": "FR"
            },
            {
                "name": "Les Angles",
                "countryCode": "FR"
            },
            {
                "name": "Les Mages",
                "countryCode": "FR"
            },
            {
                "name": "Les Matelles",
                "countryCode": "FR"
            },
            {
                "name": "Les Salles-du-Gardon",
                "countryCode": "FR"
            },
            {
                "name": "Lespignan",
                "countryCode": "FR"
            },
            {
                "name": "Lespinasse",
                "countryCode": "FR"
            },
            {
                "name": "Leucate",
                "countryCode": "FR"
            },
            {
                "name": "Leyme",
                "countryCode": "FR"
            },
            {
                "name": "Lherm",
                "countryCode": "FR"
            },
            {
                "name": "Lieuran-lès-Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Lignan-sur-Orb",
                "countryCode": "FR"
            },
            {
                "name": "Limoux",
                "countryCode": "FR"
            },
            {
                "name": "Lisle-sur-Tarn",
                "countryCode": "FR"
            },
            {
                "name": "Livinhac-le-Haut",
                "countryCode": "FR"
            },
            {
                "name": "Llupia",
                "countryCode": "FR"
            },
            {
                "name": "Lodève",
                "countryCode": "FR"
            },
            {
                "name": "Lombez",
                "countryCode": "FR"
            },
            {
                "name": "Longages",
                "countryCode": "FR"
            },
            {
                "name": "Lot",
                "countryCode": "FR"
            },
            {
                "name": "Loupian",
                "countryCode": "FR"
            },
            {
                "name": "Lourdes",
                "countryCode": "FR"
            },
            {
                "name": "Luc-la-Primaube",
                "countryCode": "FR"
            },
            {
                "name": "Lunel",
                "countryCode": "FR"
            },
            {
                "name": "Lunel-Viel",
                "countryCode": "FR"
            },
            {
                "name": "Luz-Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Luzech",
                "countryCode": "FR"
            },
            {
                "name": "Lédenon",
                "countryCode": "FR"
            },
            {
                "name": "Lédignan",
                "countryCode": "FR"
            },
            {
                "name": "Léguevin",
                "countryCode": "FR"
            },
            {
                "name": "Lévignac",
                "countryCode": "FR"
            },
            {
                "name": "Lézan",
                "countryCode": "FR"
            },
            {
                "name": "Lézat-sur-Lèze",
                "countryCode": "FR"
            },
            {
                "name": "Lézignan-Corbières",
                "countryCode": "FR"
            },
            {
                "name": "Lézignan-la-Cèbe",
                "countryCode": "FR"
            },
            {
                "name": "L’Isle-Jourdain",
                "countryCode": "FR"
            },
            {
                "name": "L’Isle-en-Dodon",
                "countryCode": "FR"
            },
            {
                "name": "Magalas",
                "countryCode": "FR"
            },
            {
                "name": "Manduel",
                "countryCode": "FR"
            },
            {
                "name": "Maraussan",
                "countryCode": "FR"
            },
            {
                "name": "Marciac",
                "countryCode": "FR"
            },
            {
                "name": "Marcillac-Vallon",
                "countryCode": "FR"
            },
            {
                "name": "Marcorignan",
                "countryCode": "FR"
            },
            {
                "name": "Marguerittes",
                "countryCode": "FR"
            },
            {
                "name": "Marquefave",
                "countryCode": "FR"
            },
            {
                "name": "Marseillan",
                "countryCode": "FR"
            },
            {
                "name": "Marsillargues",
                "countryCode": "FR"
            },
            {
                "name": "Marssac-sur-Tarn",
                "countryCode": "FR"
            },
            {
                "name": "Martel",
                "countryCode": "FR"
            },
            {
                "name": "Martres-Tolosane",
                "countryCode": "FR"
            },
            {
                "name": "Marvejols",
                "countryCode": "FR"
            },
            {
                "name": "Masseube",
                "countryCode": "FR"
            },
            {
                "name": "Maubourguet",
                "countryCode": "FR"
            },
            {
                "name": "Mauguio",
                "countryCode": "FR"
            },
            {
                "name": "Maureilhan",
                "countryCode": "FR"
            },
            {
                "name": "Maureillas-las-Illas",
                "countryCode": "FR"
            },
            {
                "name": "Mauvezin",
                "countryCode": "FR"
            },
            {
                "name": "Mazamet",
                "countryCode": "FR"
            },
            {
                "name": "Mazères",
                "countryCode": "FR"
            },
            {
                "name": "Mende",
                "countryCode": "FR"
            },
            {
                "name": "Mercus-Garrabet",
                "countryCode": "FR"
            },
            {
                "name": "Merville",
                "countryCode": "FR"
            },
            {
                "name": "Meynes",
                "countryCode": "FR"
            },
            {
                "name": "Meyrueis",
                "countryCode": "FR"
            },
            {
                "name": "Milhaud",
                "countryCode": "FR"
            },
            {
                "name": "Millas",
                "countryCode": "FR"
            },
            {
                "name": "Millau",
                "countryCode": "FR"
            },
            {
                "name": "Mirande",
                "countryCode": "FR"
            },
            {
                "name": "Mirandol-Bourgnounac",
                "countryCode": "FR"
            },
            {
                "name": "Miremont",
                "countryCode": "FR"
            },
            {
                "name": "Mirepoix",
                "countryCode": "FR"
            },
            {
                "name": "Mireval",
                "countryCode": "FR"
            },
            {
                "name": "Miélan",
                "countryCode": "FR"
            },
            {
                "name": "Moissac",
                "countryCode": "FR"
            },
            {
                "name": "Molières",
                "countryCode": "FR"
            },
            {
                "name": "Molières-sur-Cèze",
                "countryCode": "FR"
            },
            {
                "name": "Monclar-de-Quercy",
                "countryCode": "FR"
            },
            {
                "name": "Mondonville",
                "countryCode": "FR"
            },
            {
                "name": "Monestiés",
                "countryCode": "FR"
            },
            {
                "name": "Mons",
                "countryCode": "FR"
            },
            {
                "name": "Montady",
                "countryCode": "FR"
            },
            {
                "name": "Montagnac",
                "countryCode": "FR"
            },
            {
                "name": "Montaigu-de-Quercy",
                "countryCode": "FR"
            },
            {
                "name": "Montaigut-sur-Save",
                "countryCode": "FR"
            },
            {
                "name": "Montans",
                "countryCode": "FR"
            },
            {
                "name": "Montaren-et-Saint-Médiers",
                "countryCode": "FR"
            },
            {
                "name": "Montarnaud",
                "countryCode": "FR"
            },
            {
                "name": "Montastruc-la-Conseillère",
                "countryCode": "FR"
            },
            {
                "name": "Montauban",
                "countryCode": "FR"
            },
            {
                "name": "Montbazens",
                "countryCode": "FR"
            },
            {
                "name": "Montbazin",
                "countryCode": "FR"
            },
            {
                "name": "Montberon",
                "countryCode": "FR"
            },
            {
                "name": "Montbeton",
                "countryCode": "FR"
            },
            {
                "name": "Montblanc",
                "countryCode": "FR"
            },
            {
                "name": "Montcuq",
                "countryCode": "FR"
            },
            {
                "name": "Montech",
                "countryCode": "FR"
            },
            {
                "name": "Monteils",
                "countryCode": "FR"
            },
            {
                "name": "Montescot",
                "countryCode": "FR"
            },
            {
                "name": "Montesquieu-Volvestre",
                "countryCode": "FR"
            },
            {
                "name": "Montesquiu d'Albera",
                "countryCode": "FR"
            },
            {
                "name": "Montfaucon",
                "countryCode": "FR"
            },
            {
                "name": "Montferrier-sur-Lez",
                "countryCode": "FR"
            },
            {
                "name": "Montfrin",
                "countryCode": "FR"
            },
            {
                "name": "Montgaillard",
                "countryCode": "FR"
            },
            {
                "name": "Montgiscard",
                "countryCode": "FR"
            },
            {
                "name": "Montjoie-en-Couserans",
                "countryCode": "FR"
            },
            {
                "name": "Montjoire",
                "countryCode": "FR"
            },
            {
                "name": "Montlaur",
                "countryCode": "FR"
            },
            {
                "name": "Montpellier",
                "countryCode": "FR"
            },
            {
                "name": "Montpeyroux",
                "countryCode": "FR"
            },
            {
                "name": "Montpezat-de-Quercy",
                "countryCode": "FR"
            },
            {
                "name": "Montrabé",
                "countryCode": "FR"
            },
            {
                "name": "Montredon-Labessonnié",
                "countryCode": "FR"
            },
            {
                "name": "Montricoux",
                "countryCode": "FR"
            },
            {
                "name": "Montrodat",
                "countryCode": "FR"
            },
            {
                "name": "Montréal",
                "countryCode": "FR"
            },
            {
                "name": "Montréjeau",
                "countryCode": "FR"
            },
            {
                "name": "Moussac",
                "countryCode": "FR"
            },
            {
                "name": "Moussan",
                "countryCode": "FR"
            },
            {
                "name": "Moyrazès",
                "countryCode": "FR"
            },
            {
                "name": "Mudaison",
                "countryCode": "FR"
            },
            {
                "name": "Muret",
                "countryCode": "FR"
            },
            {
                "name": "Murviel-lès-Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Murviel-lès-Montpellier",
                "countryCode": "FR"
            },
            {
                "name": "Mus",
                "countryCode": "FR"
            },
            {
                "name": "Mèze",
                "countryCode": "FR"
            },
            {
                "name": "Nages-et-Solorgues",
                "countryCode": "FR"
            },
            {
                "name": "Nailloux",
                "countryCode": "FR"
            },
            {
                "name": "Narbonne",
                "countryCode": "FR"
            },
            {
                "name": "Naucelle",
                "countryCode": "FR"
            },
            {
                "name": "Nissan-lez-Enserune",
                "countryCode": "FR"
            },
            {
                "name": "Nogaro",
                "countryCode": "FR"
            },
            {
                "name": "Nohic",
                "countryCode": "FR"
            },
            {
                "name": "Noé",
                "countryCode": "FR"
            },
            {
                "name": "Nègrepelisse",
                "countryCode": "FR"
            },
            {
                "name": "Nébian",
                "countryCode": "FR"
            },
            {
                "name": "Névian",
                "countryCode": "FR"
            },
            {
                "name": "Nîmes",
                "countryCode": "FR"
            },
            {
                "name": "Odos",
                "countryCode": "FR"
            },
            {
                "name": "Olemps",
                "countryCode": "FR"
            },
            {
                "name": "Olonzac",
                "countryCode": "FR"
            },
            {
                "name": "Ondes",
                "countryCode": "FR"
            },
            {
                "name": "Orgueil",
                "countryCode": "FR"
            },
            {
                "name": "Orleix",
                "countryCode": "FR"
            },
            {
                "name": "Ornaisons",
                "countryCode": "FR"
            },
            {
                "name": "Orsan",
                "countryCode": "FR"
            },
            {
                "name": "Ortaffa",
                "countryCode": "FR"
            },
            {
                "name": "Ossun",
                "countryCode": "FR"
            },
            {
                "name": "Osséja",
                "countryCode": "FR"
            },
            {
                "name": "Oursbelille",
                "countryCode": "FR"
            },
            {
                "name": "Ouveillan",
                "countryCode": "FR"
            },
            {
                "name": "Palaja",
                "countryCode": "FR"
            },
            {
                "name": "Palau-del-Vidre",
                "countryCode": "FR"
            },
            {
                "name": "Palavas-les-Flots",
                "countryCode": "FR"
            },
            {
                "name": "Pamiers",
                "countryCode": "FR"
            },
            {
                "name": "Paulhac",
                "countryCode": "FR"
            },
            {
                "name": "Paulhan",
                "countryCode": "FR"
            },
            {
                "name": "Pavie",
                "countryCode": "FR"
            },
            {
                "name": "Payrin-Augmontel",
                "countryCode": "FR"
            },
            {
                "name": "Pechbonnieu",
                "countryCode": "FR"
            },
            {
                "name": "Pennautier",
                "countryCode": "FR"
            },
            {
                "name": "Perpignan",
                "countryCode": "FR"
            },
            {
                "name": "Peyrestortes",
                "countryCode": "FR"
            },
            {
                "name": "Peyriac-Minervois",
                "countryCode": "FR"
            },
            {
                "name": "Peyriac-de-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Pezens",
                "countryCode": "FR"
            },
            {
                "name": "Pia",
                "countryCode": "FR"
            },
            {
                "name": "Pibrac",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefitte-Nestalas",
                "countryCode": "FR"
            },
            {
                "name": "Pignan",
                "countryCode": "FR"
            },
            {
                "name": "Pinet",
                "countryCode": "FR"
            },
            {
                "name": "Pinsaguel",
                "countryCode": "FR"
            },
            {
                "name": "Plaisance",
                "countryCode": "FR"
            },
            {
                "name": "Plaisance-du-Touch",
                "countryCode": "FR"
            },
            {
                "name": "Pollestres",
                "countryCode": "FR"
            },
            {
                "name": "Pompertuzat",
                "countryCode": "FR"
            },
            {
                "name": "Pompignan",
                "countryCode": "FR"
            },
            {
                "name": "Pomérols",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Saint-Esprit",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Larn",
                "countryCode": "FR"
            },
            {
                "name": "Pont-de-Salars",
                "countryCode": "FR"
            },
            {
                "name": "Ponteilla",
                "countryCode": "FR"
            },
            {
                "name": "Port-la-Nouvelle",
                "countryCode": "FR"
            },
            {
                "name": "Portel-des-Corbières",
                "countryCode": "FR"
            },
            {
                "name": "Portet-sur-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Portiragnes",
                "countryCode": "FR"
            },
            {
                "name": "Portvendres",
                "countryCode": "FR"
            },
            {
                "name": "Poulx",
                "countryCode": "FR"
            },
            {
                "name": "Poussan",
                "countryCode": "FR"
            },
            {
                "name": "Pouzac",
                "countryCode": "FR"
            },
            {
                "name": "Prades",
                "countryCode": "FR"
            },
            {
                "name": "Prades-le-Lez",
                "countryCode": "FR"
            },
            {
                "name": "Pradines",
                "countryCode": "FR"
            },
            {
                "name": "Prats de Molló",
                "countryCode": "FR"
            },
            {
                "name": "Prayssac",
                "countryCode": "FR"
            },
            {
                "name": "Preignan",
                "countryCode": "FR"
            },
            {
                "name": "Puicheric",
                "countryCode": "FR"
            },
            {
                "name": "Puissalicon",
                "countryCode": "FR"
            },
            {
                "name": "Puisserguier",
                "countryCode": "FR"
            },
            {
                "name": "Pujaudran",
                "countryCode": "FR"
            },
            {
                "name": "Pujaut",
                "countryCode": "FR"
            },
            {
                "name": "Puy-l’Évêque",
                "countryCode": "FR"
            },
            {
                "name": "Puygouzon",
                "countryCode": "FR"
            },
            {
                "name": "Puylaurens",
                "countryCode": "FR"
            },
            {
                "name": "Péchabou",
                "countryCode": "FR"
            },
            {
                "name": "Pépieux",
                "countryCode": "FR"
            },
            {
                "name": "Pérols",
                "countryCode": "FR"
            },
            {
                "name": "Pézenas",
                "countryCode": "FR"
            },
            {
                "name": "Pézilla-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Quarante",
                "countryCode": "FR"
            },
            {
                "name": "Quillan",
                "countryCode": "FR"
            },
            {
                "name": "Quissac",
                "countryCode": "FR"
            },
            {
                "name": "Rabastens",
                "countryCode": "FR"
            },
            {
                "name": "Rabastens-de-Bigorre",
                "countryCode": "FR"
            },
            {
                "name": "Ramonville-Saint-Agne",
                "countryCode": "FR"
            },
            {
                "name": "Redessan",
                "countryCode": "FR"
            },
            {
                "name": "Remoulins",
                "countryCode": "FR"
            },
            {
                "name": "Restinclières",
                "countryCode": "FR"
            },
            {
                "name": "Revel",
                "countryCode": "FR"
            },
            {
                "name": "Reynès",
                "countryCode": "FR"
            },
            {
                "name": "Ria-Sirach",
                "countryCode": "FR"
            },
            {
                "name": "Ribaute-les-Tavernes",
                "countryCode": "FR"
            },
            {
                "name": "Rieumes",
                "countryCode": "FR"
            },
            {
                "name": "Rieupeyroux",
                "countryCode": "FR"
            },
            {
                "name": "Rieux-Minervois",
                "countryCode": "FR"
            },
            {
                "name": "Rieux-Volvestre",
                "countryCode": "FR"
            },
            {
                "name": "Rignac",
                "countryCode": "FR"
            },
            {
                "name": "Riscle",
                "countryCode": "FR"
            },
            {
                "name": "Rivesaltes",
                "countryCode": "FR"
            },
            {
                "name": "Rochefort-du-Gard",
                "countryCode": "FR"
            },
            {
                "name": "Rodez",
                "countryCode": "FR"
            },
            {
                "name": "Rodilhan",
                "countryCode": "FR"
            },
            {
                "name": "Roquecourbe",
                "countryCode": "FR"
            },
            {
                "name": "Roquemaure",
                "countryCode": "FR"
            },
            {
                "name": "Roquettes",
                "countryCode": "FR"
            },
            {
                "name": "Rouffiac-Tolosan",
                "countryCode": "FR"
            },
            {
                "name": "Roujan",
                "countryCode": "FR"
            },
            {
                "name": "Rousson",
                "countryCode": "FR"
            },
            {
                "name": "Réalmont",
                "countryCode": "FR"
            },
            {
                "name": "Réalville",
                "countryCode": "FR"
            },
            {
                "name": "Réquista",
                "countryCode": "FR"
            },
            {
                "name": "Sabran",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Affrique",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alban",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alban-sur-Limagnole",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Alexandre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amans-Soult",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Amans-Valtoret",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ambroix",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-de-Sangonis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aunès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bauzille-de-Putois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Benoît-de-Carmaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chaptes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chinian",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christol",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christol-lès-Alès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christophe-Vallon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clément-de-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyprien-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Céré",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Drézéry",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Estève",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Etienne-de-Tulmont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florent-sur-Auzonnet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Féliu-d’Avall",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Félix-Lauragais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gaudens",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniez-d’Olt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniès-Bellevue",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniès-de-Comolas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniès-de-Fontedit",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniès-de-Malgoirès",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Geniès-des-Mourgues",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Luzençon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervasy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gilles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Girons",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gély-du-Fesc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Génis-des-Fontaines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Brethmas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hippolyte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hippolyte-du-Fort",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Fos",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Védas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-du-Falga",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-du-Gard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jory",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-de-Peyrolas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-les-Rosiers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Juéry",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lary-Soulan",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-la-Salanque",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-des-Arbres",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lizier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Loup-Cammas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lys",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mamert-du-Gard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel-sur-Aude",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Lalande",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Londres",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Valgalgues",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mathieu-de-Tréviers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nauphary",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nazaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nazaire-d’Aude",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-de-la-Grave",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Orens-de-Gameville",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pargoire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-Cap-de-Joux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-de-Fenouillet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-de-Jarrat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paulet-de-Caisson",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pons-de-Thomières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Porquier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Privat-des-Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pé-de-Bigorre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-la-Poterie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sauveur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-la-Pointe",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sulpice-sur-Lèze",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Thibéry",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Victor-la-Coste",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Foy-de-Peyrolières",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Geneviève-sur-Argence",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marie-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Radegonde",
                "countryCode": "FR"
            },
            {
                "name": "Saleilles",
                "countryCode": "FR"
            },
            {
                "name": "Salies-du-Salat",
                "countryCode": "FR"
            },
            {
                "name": "Salindres",
                "countryCode": "FR"
            },
            {
                "name": "Salles-Curan",
                "countryCode": "FR"
            },
            {
                "name": "Salles-la-Source",
                "countryCode": "FR"
            },
            {
                "name": "Salses-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Salviac",
                "countryCode": "FR"
            },
            {
                "name": "Samatan",
                "countryCode": "FR"
            },
            {
                "name": "Sant Andreu de Sureda",
                "countryCode": "FR"
            },
            {
                "name": "Sant Joan de Pladecorts",
                "countryCode": "FR"
            },
            {
                "name": "Sant Llorenç de Cerdans",
                "countryCode": "FR"
            },
            {
                "name": "Saubens",
                "countryCode": "FR"
            },
            {
                "name": "Saussan",
                "countryCode": "FR"
            },
            {
                "name": "Sauve",
                "countryCode": "FR"
            },
            {
                "name": "Sauveterre",
                "countryCode": "FR"
            },
            {
                "name": "Sauvian",
                "countryCode": "FR"
            },
            {
                "name": "Saverdun",
                "countryCode": "FR"
            },
            {
                "name": "Saze",
                "countryCode": "FR"
            },
            {
                "name": "Saïx",
                "countryCode": "FR"
            },
            {
                "name": "Seilh",
                "countryCode": "FR"
            },
            {
                "name": "Seissan",
                "countryCode": "FR"
            },
            {
                "name": "Septfonds",
                "countryCode": "FR"
            },
            {
                "name": "Sernhac",
                "countryCode": "FR"
            },
            {
                "name": "Servian",
                "countryCode": "FR"
            },
            {
                "name": "Seysses",
                "countryCode": "FR"
            },
            {
                "name": "Sigean",
                "countryCode": "FR"
            },
            {
                "name": "Sommières",
                "countryCode": "FR"
            },
            {
                "name": "Sorède",
                "countryCode": "FR"
            },
            {
                "name": "Sorèze",
                "countryCode": "FR"
            },
            {
                "name": "Soual",
                "countryCode": "FR"
            },
            {
                "name": "Soues",
                "countryCode": "FR"
            },
            {
                "name": "Souillac",
                "countryCode": "FR"
            },
            {
                "name": "Sumène",
                "countryCode": "FR"
            },
            {
                "name": "Sussargues",
                "countryCode": "FR"
            },
            {
                "name": "Sète",
                "countryCode": "FR"
            },
            {
                "name": "Sébazac-Concourès",
                "countryCode": "FR"
            },
            {
                "name": "Sémalens",
                "countryCode": "FR"
            },
            {
                "name": "Séméac",
                "countryCode": "FR"
            },
            {
                "name": "Sérignan",
                "countryCode": "FR"
            },
            {
                "name": "Sévérac-le-Château",
                "countryCode": "FR"
            },
            {
                "name": "Tarascon-sur-Ariège",
                "countryCode": "FR"
            },
            {
                "name": "Tarbes",
                "countryCode": "FR"
            },
            {
                "name": "Tarn",
                "countryCode": "FR"
            },
            {
                "name": "Tavel",
                "countryCode": "FR"
            },
            {
                "name": "Teyran",
                "countryCode": "FR"
            },
            {
                "name": "Thuir",
                "countryCode": "FR"
            },
            {
                "name": "Théza",
                "countryCode": "FR"
            },
            {
                "name": "Thézan-lès-Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Torreilles",
                "countryCode": "FR"
            },
            {
                "name": "Toulouges",
                "countryCode": "FR"
            },
            {
                "name": "Toulouse",
                "countryCode": "FR"
            },
            {
                "name": "Tourbes",
                "countryCode": "FR"
            },
            {
                "name": "Tournay",
                "countryCode": "FR"
            },
            {
                "name": "Tournefeuille",
                "countryCode": "FR"
            },
            {
                "name": "Tresques",
                "countryCode": "FR"
            },
            {
                "name": "Trie-sur-Baïse",
                "countryCode": "FR"
            },
            {
                "name": "Trouillas",
                "countryCode": "FR"
            },
            {
                "name": "Trèbes",
                "countryCode": "FR"
            },
            {
                "name": "Uchaud",
                "countryCode": "FR"
            },
            {
                "name": "Uzès",
                "countryCode": "FR"
            },
            {
                "name": "Vacquiers",
                "countryCode": "FR"
            },
            {
                "name": "Vailhauquès",
                "countryCode": "FR"
            },
            {
                "name": "Valady",
                "countryCode": "FR"
            },
            {
                "name": "Valence",
                "countryCode": "FR"
            },
            {
                "name": "Valence-d’Albigeois",
                "countryCode": "FR"
            },
            {
                "name": "Valence-sur-Baïse",
                "countryCode": "FR"
            },
            {
                "name": "Valergues",
                "countryCode": "FR"
            },
            {
                "name": "Vallabrègues",
                "countryCode": "FR"
            },
            {
                "name": "Valleraugue",
                "countryCode": "FR"
            },
            {
                "name": "Valras-Plage",
                "countryCode": "FR"
            },
            {
                "name": "Valros",
                "countryCode": "FR"
            },
            {
                "name": "Varilhes",
                "countryCode": "FR"
            },
            {
                "name": "Vauvert",
                "countryCode": "FR"
            },
            {
                "name": "Vayrac",
                "countryCode": "FR"
            },
            {
                "name": "Vendargues",
                "countryCode": "FR"
            },
            {
                "name": "Vendres",
                "countryCode": "FR"
            },
            {
                "name": "Venerque",
                "countryCode": "FR"
            },
            {
                "name": "Verdun-sur-Garonne",
                "countryCode": "FR"
            },
            {
                "name": "Verfeil",
                "countryCode": "FR"
            },
            {
                "name": "Vergèze",
                "countryCode": "FR"
            },
            {
                "name": "Vernet",
                "countryCode": "FR"
            },
            {
                "name": "Vernet-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Verniolle",
                "countryCode": "FR"
            },
            {
                "name": "Vers-Pont-du-Gard",
                "countryCode": "FR"
            },
            {
                "name": "Vestric-et-Candiac",
                "countryCode": "FR"
            },
            {
                "name": "Vias",
                "countryCode": "FR"
            },
            {
                "name": "Vic-Fezensac",
                "countryCode": "FR"
            },
            {
                "name": "Vic-en-Bigorre",
                "countryCode": "FR"
            },
            {
                "name": "Vic-la-Gardiole",
                "countryCode": "FR"
            },
            {
                "name": "Vieille-Toulouse",
                "countryCode": "FR"
            },
            {
                "name": "Vielmur-sur-Agout",
                "countryCode": "FR"
            },
            {
                "name": "Vilallonga dels Monts",
                "countryCode": "FR"
            },
            {
                "name": "Villaudric",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-d'Albigeois",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-de-Lauragais",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-de-Rouergue",
                "countryCode": "FR"
            },
            {
                "name": "Villegailhenc",
                "countryCode": "FR"
            },
            {
                "name": "Villelongue-de-la-Salanque",
                "countryCode": "FR"
            },
            {
                "name": "Villemolaque",
                "countryCode": "FR"
            },
            {
                "name": "Villemoustaussou",
                "countryCode": "FR"
            },
            {
                "name": "Villemur-sur-Tarn",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-Tolosane",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-de-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-de-la-Raho",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-d’Olmes",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-la-Comptal",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-lès-Avignon",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-lès-Bouloc",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-lès-Béziers",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-lès-Maguelone",
                "countryCode": "FR"
            },
            {
                "name": "Villenouvelle",
                "countryCode": "FR"
            },
            {
                "name": "Villepinte",
                "countryCode": "FR"
            },
            {
                "name": "Villetelle",
                "countryCode": "FR"
            },
            {
                "name": "Villeveyrac",
                "countryCode": "FR"
            },
            {
                "name": "Villevieille",
                "countryCode": "FR"
            },
            {
                "name": "Vinassan",
                "countryCode": "FR"
            },
            {
                "name": "Vinça",
                "countryCode": "FR"
            },
            {
                "name": "Viviers-lès-Montagnes",
                "countryCode": "FR"
            },
            {
                "name": "Viviez",
                "countryCode": "FR"
            },
            {
                "name": "Vénéjan",
                "countryCode": "FR"
            },
            {
                "name": "Vézénobres",
                "countryCode": "FR"
            },
            {
                "name": "el Voló",
                "countryCode": "FR"
            },
            {
                "name": "els Banys d'Arles",
                "countryCode": "FR"
            },
            {
                "name": "la Guingueta d'Ix",
                "countryCode": "FR"
            },
            {
                "name": "la Roca d'Albera",
                "countryCode": "FR"
            },
            {
                "name": "Aix-en-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Allauch",
                "countryCode": "FR"
            },
            {
                "name": "Alleins",
                "countryCode": "FR"
            },
            {
                "name": "Alpes-Maritimes",
                "countryCode": "FR"
            },
            {
                "name": "Alpes-de-Haute-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Althen-des-Paluds",
                "countryCode": "FR"
            },
            {
                "name": "Annot",
                "countryCode": "FR"
            },
            {
                "name": "Ansouis",
                "countryCode": "FR"
            },
            {
                "name": "Antibes",
                "countryCode": "FR"
            },
            {
                "name": "Apt",
                "countryCode": "FR"
            },
            {
                "name": "Arenc",
                "countryCode": "FR"
            },
            {
                "name": "Arles",
                "countryCode": "FR"
            },
            {
                "name": "Aspremont",
                "countryCode": "FR"
            },
            {
                "name": "Aubagne",
                "countryCode": "FR"
            },
            {
                "name": "Aubignan",
                "countryCode": "FR"
            },
            {
                "name": "Aups",
                "countryCode": "FR"
            },
            {
                "name": "Aureille",
                "countryCode": "FR"
            },
            {
                "name": "Auribeau-sur-Siagne",
                "countryCode": "FR"
            },
            {
                "name": "Auriol",
                "countryCode": "FR"
            },
            {
                "name": "Avignon",
                "countryCode": "FR"
            },
            {
                "name": "Bagnols-en-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Baille",
                "countryCode": "FR"
            },
            {
                "name": "Bandol",
                "countryCode": "FR"
            },
            {
                "name": "Barbentane",
                "countryCode": "FR"
            },
            {
                "name": "Barcelonnette",
                "countryCode": "FR"
            },
            {
                "name": "Bargemon",
                "countryCode": "FR"
            },
            {
                "name": "Barjols",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Beaumes-de-Venise",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-de-Pertuis",
                "countryCode": "FR"
            },
            {
                "name": "Beausoleil",
                "countryCode": "FR"
            },
            {
                "name": "Belcodène",
                "countryCode": "FR"
            },
            {
                "name": "Belgentier",
                "countryCode": "FR"
            },
            {
                "name": "Belle de Mai",
                "countryCode": "FR"
            },
            {
                "name": "Belsunce",
                "countryCode": "FR"
            },
            {
                "name": "Berre-l'Étang",
                "countryCode": "FR"
            },
            {
                "name": "Berre-les-Alpes",
                "countryCode": "FR"
            },
            {
                "name": "Besse-sur-Issole",
                "countryCode": "FR"
            },
            {
                "name": "Biot",
                "countryCode": "FR"
            },
            {
                "name": "Blausasc",
                "countryCode": "FR"
            },
            {
                "name": "Bollène",
                "countryCode": "FR"
            },
            {
                "name": "Bon-Secours",
                "countryCode": "FR"
            },
            {
                "name": "Bonneveine",
                "countryCode": "FR"
            },
            {
                "name": "Bonnieux",
                "countryCode": "FR"
            },
            {
                "name": "Borel",
                "countryCode": "FR"
            },
            {
                "name": "Bormes-les-Mimosas",
                "countryCode": "FR"
            },
            {
                "name": "Bouc-Bel-Air",
                "countryCode": "FR"
            },
            {
                "name": "Boulbon",
                "countryCode": "FR"
            },
            {
                "name": "Bras",
                "countryCode": "FR"
            },
            {
                "name": "Breil-sur-Roya",
                "countryCode": "FR"
            },
            {
                "name": "Briançon",
                "countryCode": "FR"
            },
            {
                "name": "Brignoles",
                "countryCode": "FR"
            },
            {
                "name": "Bédarrides",
                "countryCode": "FR"
            },
            {
                "name": "Bédoin",
                "countryCode": "FR"
            },
            {
                "name": "Cabannes",
                "countryCode": "FR"
            },
            {
                "name": "Cabasse",
                "countryCode": "FR"
            },
            {
                "name": "Cabris",
                "countryCode": "FR"
            },
            {
                "name": "Cabriès",
                "countryCode": "FR"
            },
            {
                "name": "Cadenet",
                "countryCode": "FR"
            },
            {
                "name": "Caderousse",
                "countryCode": "FR"
            },
            {
                "name": "Cadolive",
                "countryCode": "FR"
            },
            {
                "name": "Cagnes-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Callas",
                "countryCode": "FR"
            },
            {
                "name": "Callian",
                "countryCode": "FR"
            },
            {
                "name": "Camaret-sur-Aigues",
                "countryCode": "FR"
            },
            {
                "name": "Camps-la-Source",
                "countryCode": "FR"
            },
            {
                "name": "Cannes",
                "countryCode": "FR"
            },
            {
                "name": "Cantaron",
                "countryCode": "FR"
            },
            {
                "name": "Cap-d’Ail",
                "countryCode": "FR"
            },
            {
                "name": "Carcès",
                "countryCode": "FR"
            },
            {
                "name": "Carnoules",
                "countryCode": "FR"
            },
            {
                "name": "Carnoux-en-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Caromb",
                "countryCode": "FR"
            },
            {
                "name": "Carpentras",
                "countryCode": "FR"
            },
            {
                "name": "Carqueiranne",
                "countryCode": "FR"
            },
            {
                "name": "Carros",
                "countryCode": "FR"
            },
            {
                "name": "Carry-le-Rouet",
                "countryCode": "FR"
            },
            {
                "name": "Cassis",
                "countryCode": "FR"
            },
            {
                "name": "Castagniers",
                "countryCode": "FR"
            },
            {
                "name": "Castellane",
                "countryCode": "FR"
            },
            {
                "name": "Caumont-sur-Durance",
                "countryCode": "FR"
            },
            {
                "name": "Cavaillon",
                "countryCode": "FR"
            },
            {
                "name": "Cavalaire-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Ceyreste",
                "countryCode": "FR"
            },
            {
                "name": "Charleval",
                "countryCode": "FR"
            },
            {
                "name": "Cheval-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Chorges",
                "countryCode": "FR"
            },
            {
                "name": "Chutes-Lavie",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-Grasse",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-de-Gadagne",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-du-Pape",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-le-Rouge",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-les-Martigues",
                "countryCode": "FR"
            },
            {
                "name": "Châteaurenard",
                "countryCode": "FR"
            },
            {
                "name": "Cinq Avenues",
                "countryCode": "FR"
            },
            {
                "name": "Cogolin",
                "countryCode": "FR"
            },
            {
                "name": "Collobrières",
                "countryCode": "FR"
            },
            {
                "name": "Colomars",
                "countryCode": "FR"
            },
            {
                "name": "Contes",
                "countryCode": "FR"
            },
            {
                "name": "Cornillon-Confoux",
                "countryCode": "FR"
            },
            {
                "name": "Cotignac",
                "countryCode": "FR"
            },
            {
                "name": "Coudoux",
                "countryCode": "FR"
            },
            {
                "name": "Courthézon",
                "countryCode": "FR"
            },
            {
                "name": "Cucuron",
                "countryCode": "FR"
            },
            {
                "name": "Cuers",
                "countryCode": "FR"
            },
            {
                "name": "Cuges-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Céreste",
                "countryCode": "FR"
            },
            {
                "name": "Digne-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Draguignan",
                "countryCode": "FR"
            },
            {
                "name": "Drap",
                "countryCode": "FR"
            },
            {
                "name": "Département des Bouches-du-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Département du Vaucluse",
                "countryCode": "FR"
            },
            {
                "name": "Embrun",
                "countryCode": "FR"
            },
            {
                "name": "Ensuès-la-Redonne",
                "countryCode": "FR"
            },
            {
                "name": "Entraigues-sur-la-Sorgue",
                "countryCode": "FR"
            },
            {
                "name": "Eygalières",
                "countryCode": "FR"
            },
            {
                "name": "Eyguières",
                "countryCode": "FR"
            },
            {
                "name": "Eyragues",
                "countryCode": "FR"
            },
            {
                "name": "Falicon",
                "countryCode": "FR"
            },
            {
                "name": "Fayence",
                "countryCode": "FR"
            },
            {
                "name": "Figanières",
                "countryCode": "FR"
            },
            {
                "name": "Flassans-sur-Issole",
                "countryCode": "FR"
            },
            {
                "name": "Flayosc",
                "countryCode": "FR"
            },
            {
                "name": "Fontvieille",
                "countryCode": "FR"
            },
            {
                "name": "Forcalqueiret",
                "countryCode": "FR"
            },
            {
                "name": "Forcalquier",
                "countryCode": "FR"
            },
            {
                "name": "Fos-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Fréjus",
                "countryCode": "FR"
            },
            {
                "name": "Fuveau",
                "countryCode": "FR"
            },
            {
                "name": "Gap",
                "countryCode": "FR"
            },
            {
                "name": "Gardanne",
                "countryCode": "FR"
            },
            {
                "name": "Gargas",
                "countryCode": "FR"
            },
            {
                "name": "Garéoult",
                "countryCode": "FR"
            },
            {
                "name": "Gassin",
                "countryCode": "FR"
            },
            {
                "name": "Gattières",
                "countryCode": "FR"
            },
            {
                "name": "Gignac-la-Nerthe",
                "countryCode": "FR"
            },
            {
                "name": "Gilette",
                "countryCode": "FR"
            },
            {
                "name": "Ginasservis",
                "countryCode": "FR"
            },
            {
                "name": "Gonfaron",
                "countryCode": "FR"
            },
            {
                "name": "Gorbio",
                "countryCode": "FR"
            },
            {
                "name": "Gordes",
                "countryCode": "FR"
            },
            {
                "name": "Goult",
                "countryCode": "FR"
            },
            {
                "name": "Grambois",
                "countryCode": "FR"
            },
            {
                "name": "Grans",
                "countryCode": "FR"
            },
            {
                "name": "Grasse",
                "countryCode": "FR"
            },
            {
                "name": "Graveson",
                "countryCode": "FR"
            },
            {
                "name": "Grillon",
                "countryCode": "FR"
            },
            {
                "name": "Grimaud",
                "countryCode": "FR"
            },
            {
                "name": "Gréasque",
                "countryCode": "FR"
            },
            {
                "name": "Gréoux-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Guillestre",
                "countryCode": "FR"
            },
            {
                "name": "Gémenos",
                "countryCode": "FR"
            },
            {
                "name": "Hautes-Alpes",
                "countryCode": "FR"
            },
            {
                "name": "Hyères",
                "countryCode": "FR"
            },
            {
                "name": "Istres",
                "countryCode": "FR"
            },
            {
                "name": "Jausiers",
                "countryCode": "FR"
            },
            {
                "name": "Jonquerettes",
                "countryCode": "FR"
            },
            {
                "name": "Jonquières",
                "countryCode": "FR"
            },
            {
                "name": "Jouques",
                "countryCode": "FR"
            },
            {
                "name": "L'Estaque",
                "countryCode": "FR"
            },
            {
                "name": "La Barasse",
                "countryCode": "FR"
            },
            {
                "name": "La Bastide-des-Jourdans",
                "countryCode": "FR"
            },
            {
                "name": "La Blancarde",
                "countryCode": "FR"
            },
            {
                "name": "La Bouilladisse",
                "countryCode": "FR"
            },
            {
                "name": "La Bâtie-Neuve",
                "countryCode": "FR"
            },
            {
                "name": "La Cabucelle",
                "countryCode": "FR"
            },
            {
                "name": "La Calade",
                "countryCode": "FR"
            },
            {
                "name": "La Capelette",
                "countryCode": "FR"
            },
            {
                "name": "La Celle",
                "countryCode": "FR"
            },
            {
                "name": "La Ciotat",
                "countryCode": "FR"
            },
            {
                "name": "La Colle-sur-Loup",
                "countryCode": "FR"
            },
            {
                "name": "La Conception",
                "countryCode": "FR"
            },
            {
                "name": "La Crau",
                "countryCode": "FR"
            },
            {
                "name": "La Croix-Rouge",
                "countryCode": "FR"
            },
            {
                "name": "La Croix-Valmer",
                "countryCode": "FR"
            },
            {
                "name": "La Delorme",
                "countryCode": "FR"
            },
            {
                "name": "La Destrousse",
                "countryCode": "FR"
            },
            {
                "name": "La Fare-les-Oliviers",
                "countryCode": "FR"
            },
            {
                "name": "La Farlède",
                "countryCode": "FR"
            },
            {
                "name": "La Fourragère",
                "countryCode": "FR"
            },
            {
                "name": "La Garde",
                "countryCode": "FR"
            },
            {
                "name": "La Garde-Freinet",
                "countryCode": "FR"
            },
            {
                "name": "La Gaude",
                "countryCode": "FR"
            },
            {
                "name": "La Joliette",
                "countryCode": "FR"
            },
            {
                "name": "La Londe-les-Maures",
                "countryCode": "FR"
            },
            {
                "name": "La Millère",
                "countryCode": "FR"
            },
            {
                "name": "La Motte",
                "countryCode": "FR"
            },
            {
                "name": "La Page",
                "countryCode": "FR"
            },
            {
                "name": "La Panouse",
                "countryCode": "FR"
            },
            {
                "name": "La Penne-sur-Huveaune",
                "countryCode": "FR"
            },
            {
                "name": "La Pointe Rouge",
                "countryCode": "FR"
            },
            {
                "name": "La Pomme",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-des-Arnauds",
                "countryCode": "FR"
            },
            {
                "name": "La Roque-d’Anthéron",
                "countryCode": "FR"
            },
            {
                "name": "La Roquebrussanne",
                "countryCode": "FR"
            },
            {
                "name": "La Roquette-sur-Siagne",
                "countryCode": "FR"
            },
            {
                "name": "La Rose",
                "countryCode": "FR"
            },
            {
                "name": "La Seyne-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Timone",
                "countryCode": "FR"
            },
            {
                "name": "La Trinité",
                "countryCode": "FR"
            },
            {
                "name": "La Turbie",
                "countryCode": "FR"
            },
            {
                "name": "La Valbarelle",
                "countryCode": "FR"
            },
            {
                "name": "La Valentine",
                "countryCode": "FR"
            },
            {
                "name": "La Valette-du-Var",
                "countryCode": "FR"
            },
            {
                "name": "La Villette",
                "countryCode": "FR"
            },
            {
                "name": "La Viste",
                "countryCode": "FR"
            },
            {
                "name": "Lagnes",
                "countryCode": "FR"
            },
            {
                "name": "Lamanon",
                "countryCode": "FR"
            },
            {
                "name": "Lambesc",
                "countryCode": "FR"
            },
            {
                "name": "Lantosque",
                "countryCode": "FR"
            },
            {
                "name": "Lançon-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Lapalud",
                "countryCode": "FR"
            },
            {
                "name": "Laragne-Montéglin",
                "countryCode": "FR"
            },
            {
                "name": "Lauris",
                "countryCode": "FR"
            },
            {
                "name": "Le Bar-sur-Loup",
                "countryCode": "FR"
            },
            {
                "name": "Le Beausset",
                "countryCode": "FR"
            },
            {
                "name": "Le Broc",
                "countryCode": "FR"
            },
            {
                "name": "Le Brusquet",
                "countryCode": "FR"
            },
            {
                "name": "Le Cabot",
                "countryCode": "FR"
            },
            {
                "name": "Le Camas",
                "countryCode": "FR"
            },
            {
                "name": "Le Canet",
                "countryCode": "FR"
            },
            {
                "name": "Le Cannet",
                "countryCode": "FR"
            },
            {
                "name": "Le Cannet-des-Maures",
                "countryCode": "FR"
            },
            {
                "name": "Le Castellet",
                "countryCode": "FR"
            },
            {
                "name": "Le Chapitre",
                "countryCode": "FR"
            },
            {
                "name": "Le Lavandou",
                "countryCode": "FR"
            },
            {
                "name": "Le Luc",
                "countryCode": "FR"
            },
            {
                "name": "Le Merlan",
                "countryCode": "FR"
            },
            {
                "name": "Le Monêtier-les-Bains",
                "countryCode": "FR"
            },
            {
                "name": "Le Muy",
                "countryCode": "FR"
            },
            {
                "name": "Le Pharo",
                "countryCode": "FR"
            },
            {
                "name": "Le Plan-de-la-Tour",
                "countryCode": "FR"
            },
            {
                "name": "Le Pontet",
                "countryCode": "FR"
            },
            {
                "name": "Le Pradet",
                "countryCode": "FR"
            },
            {
                "name": "Le Puy-Sainte-Réparade",
                "countryCode": "FR"
            },
            {
                "name": "Le Redon",
                "countryCode": "FR"
            },
            {
                "name": "Le Revest-les-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Le Rouret",
                "countryCode": "FR"
            },
            {
                "name": "Le Rove",
                "countryCode": "FR"
            },
            {
                "name": "Le Tholonet",
                "countryCode": "FR"
            },
            {
                "name": "Le Thor",
                "countryCode": "FR"
            },
            {
                "name": "Le Thoronet",
                "countryCode": "FR"
            },
            {
                "name": "Le Tignet",
                "countryCode": "FR"
            },
            {
                "name": "Le Val",
                "countryCode": "FR"
            },
            {
                "name": "Les Accates",
                "countryCode": "FR"
            },
            {
                "name": "Les Arcs",
                "countryCode": "FR"
            },
            {
                "name": "Les Arnavaux",
                "countryCode": "FR"
            },
            {
                "name": "Les Aygalades",
                "countryCode": "FR"
            },
            {
                "name": "Les Baumettes",
                "countryCode": "FR"
            },
            {
                "name": "Les Caillols",
                "countryCode": "FR"
            },
            {
                "name": "Les Camoins",
                "countryCode": "FR"
            },
            {
                "name": "Les Chartreux",
                "countryCode": "FR"
            },
            {
                "name": "Les Crottes",
                "countryCode": "FR"
            },
            {
                "name": "Les Grands Carmes",
                "countryCode": "FR"
            },
            {
                "name": "Les Médecins",
                "countryCode": "FR"
            },
            {
                "name": "Les Mées",
                "countryCode": "FR"
            },
            {
                "name": "Les Olives",
                "countryCode": "FR"
            },
            {
                "name": "Les Pennes-Mirabeau",
                "countryCode": "FR"
            },
            {
                "name": "Les Trois-Lucs",
                "countryCode": "FR"
            },
            {
                "name": "Levens",
                "countryCode": "FR"
            },
            {
                "name": "Lodi",
                "countryCode": "FR"
            },
            {
                "name": "Lorgues",
                "countryCode": "FR"
            },
            {
                "name": "Loriol-du-Comtat",
                "countryCode": "FR"
            },
            {
                "name": "Lourmarin",
                "countryCode": "FR"
            },
            {
                "name": "Lucéram",
                "countryCode": "FR"
            },
            {
                "name": "L’Escale",
                "countryCode": "FR"
            },
            {
                "name": "L’Escarène",
                "countryCode": "FR"
            },
            {
                "name": "L’Isle-sur-la-Sorgue",
                "countryCode": "FR"
            },
            {
                "name": "Maillane",
                "countryCode": "FR"
            },
            {
                "name": "Malaucène",
                "countryCode": "FR"
            },
            {
                "name": "Malemort-du-Comtat",
                "countryCode": "FR"
            },
            {
                "name": "Malijai",
                "countryCode": "FR"
            },
            {
                "name": "Mallemoisson",
                "countryCode": "FR"
            },
            {
                "name": "Mallemort",
                "countryCode": "FR"
            },
            {
                "name": "Malpassé",
                "countryCode": "FR"
            },
            {
                "name": "Mandelieu-la-Napoule",
                "countryCode": "FR"
            },
            {
                "name": "Mane",
                "countryCode": "FR"
            },
            {
                "name": "Manosque",
                "countryCode": "FR"
            },
            {
                "name": "Marignane",
                "countryCode": "FR"
            },
            {
                "name": "Marseille",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 01",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 02",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 03",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 04",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 05",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 06",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 07",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 08",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 09",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 10",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 11",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 12",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 13",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 14",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 15",
                "countryCode": "FR"
            },
            {
                "name": "Marseille 16",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Bompard",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Endoume",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Prefecture",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Roucas-Blanc",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Saint-Victor",
                "countryCode": "FR"
            },
            {
                "name": "Marseille Vauban",
                "countryCode": "FR"
            },
            {
                "name": "Martigues",
                "countryCode": "FR"
            },
            {
                "name": "Maubec",
                "countryCode": "FR"
            },
            {
                "name": "Maussane-les-Alpilles",
                "countryCode": "FR"
            },
            {
                "name": "Mazan",
                "countryCode": "FR"
            },
            {
                "name": "Mazargues",
                "countryCode": "FR"
            },
            {
                "name": "Menpenti",
                "countryCode": "FR"
            },
            {
                "name": "Menton",
                "countryCode": "FR"
            },
            {
                "name": "Meyrargues",
                "countryCode": "FR"
            },
            {
                "name": "Meyreuil",
                "countryCode": "FR"
            },
            {
                "name": "Mimet",
                "countryCode": "FR"
            },
            {
                "name": "Miramas",
                "countryCode": "FR"
            },
            {
                "name": "Mollégès",
                "countryCode": "FR"
            },
            {
                "name": "Mondragon",
                "countryCode": "FR"
            },
            {
                "name": "Montauroux",
                "countryCode": "FR"
            },
            {
                "name": "Monteux",
                "countryCode": "FR"
            },
            {
                "name": "Montfavet",
                "countryCode": "FR"
            },
            {
                "name": "Montferrat",
                "countryCode": "FR"
            },
            {
                "name": "Montolivet",
                "countryCode": "FR"
            },
            {
                "name": "Montredon",
                "countryCode": "FR"
            },
            {
                "name": "Morières-lès-Avignon",
                "countryCode": "FR"
            },
            {
                "name": "Mormoiron",
                "countryCode": "FR"
            },
            {
                "name": "Mornas",
                "countryCode": "FR"
            },
            {
                "name": "Mouans-Sartoux",
                "countryCode": "FR"
            },
            {
                "name": "Mougins",
                "countryCode": "FR"
            },
            {
                "name": "Mouret",
                "countryCode": "FR"
            },
            {
                "name": "Mouriès",
                "countryCode": "FR"
            },
            {
                "name": "Ménerbes",
                "countryCode": "FR"
            },
            {
                "name": "Méounes-lès-Montrieux",
                "countryCode": "FR"
            },
            {
                "name": "Mérindol",
                "countryCode": "FR"
            },
            {
                "name": "Nans-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Nice",
                "countryCode": "FR"
            },
            {
                "name": "Noailles",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame Limite",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame du Mont",
                "countryCode": "FR"
            },
            {
                "name": "Noves",
                "countryCode": "FR"
            },
            {
                "name": "Néoules",
                "countryCode": "FR"
            },
            {
                "name": "Ollioules",
                "countryCode": "FR"
            },
            {
                "name": "Opio",
                "countryCode": "FR"
            },
            {
                "name": "Oppède le Vieux",
                "countryCode": "FR"
            },
            {
                "name": "Opéra",
                "countryCode": "FR"
            },
            {
                "name": "Oraison",
                "countryCode": "FR"
            },
            {
                "name": "Orange",
                "countryCode": "FR"
            },
            {
                "name": "Orgon",
                "countryCode": "FR"
            },
            {
                "name": "Palais de Justice",
                "countryCode": "FR"
            },
            {
                "name": "Palama",
                "countryCode": "FR"
            },
            {
                "name": "Paradou",
                "countryCode": "FR"
            },
            {
                "name": "Peille",
                "countryCode": "FR"
            },
            {
                "name": "Peillon",
                "countryCode": "FR"
            },
            {
                "name": "Peipin",
                "countryCode": "FR"
            },
            {
                "name": "Pernes-les-Fontaines",
                "countryCode": "FR"
            },
            {
                "name": "Pertuis",
                "countryCode": "FR"
            },
            {
                "name": "Peymeinade",
                "countryCode": "FR"
            },
            {
                "name": "Peynier",
                "countryCode": "FR"
            },
            {
                "name": "Peypin",
                "countryCode": "FR"
            },
            {
                "name": "Peyrolles-en-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Peyruis",
                "countryCode": "FR"
            },
            {
                "name": "Pierrefeu-du-Var",
                "countryCode": "FR"
            },
            {
                "name": "Pierrevert",
                "countryCode": "FR"
            },
            {
                "name": "Pignans",
                "countryCode": "FR"
            },
            {
                "name": "Piolenc",
                "countryCode": "FR"
            },
            {
                "name": "Plan-d'Aups-Sainte-Baume",
                "countryCode": "FR"
            },
            {
                "name": "Plan-de-Cuques",
                "countryCode": "FR"
            },
            {
                "name": "Pont de Vivaux",
                "countryCode": "FR"
            },
            {
                "name": "Port-Saint-Louis-du-Rhône",
                "countryCode": "FR"
            },
            {
                "name": "Port-de-Bouc",
                "countryCode": "FR"
            },
            {
                "name": "Pourrières",
                "countryCode": "FR"
            },
            {
                "name": "Puget-Théniers",
                "countryCode": "FR"
            },
            {
                "name": "Puget-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Puget-sur-Argens",
                "countryCode": "FR"
            },
            {
                "name": "Puyloubier",
                "countryCode": "FR"
            },
            {
                "name": "Pégomas",
                "countryCode": "FR"
            },
            {
                "name": "Pélissanne",
                "countryCode": "FR"
            },
            {
                "name": "Périer",
                "countryCode": "FR"
            },
            {
                "name": "Ramatuelle",
                "countryCode": "FR"
            },
            {
                "name": "Reillanne",
                "countryCode": "FR"
            },
            {
                "name": "Rians",
                "countryCode": "FR"
            },
            {
                "name": "Riez",
                "countryCode": "FR"
            },
            {
                "name": "Robion",
                "countryCode": "FR"
            },
            {
                "name": "Rocbaron",
                "countryCode": "FR"
            },
            {
                "name": "Rognac",
                "countryCode": "FR"
            },
            {
                "name": "Rognes",
                "countryCode": "FR"
            },
            {
                "name": "Rognonas",
                "countryCode": "FR"
            },
            {
                "name": "Roquebillière",
                "countryCode": "FR"
            },
            {
                "name": "Roquebrune-Cap-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Roquebrune-sur-Argens",
                "countryCode": "FR"
            },
            {
                "name": "Roquefort-la-Bédoule",
                "countryCode": "FR"
            },
            {
                "name": "Roquevaire",
                "countryCode": "FR"
            },
            {
                "name": "Rouet",
                "countryCode": "FR"
            },
            {
                "name": "Rougiers",
                "countryCode": "FR"
            },
            {
                "name": "Rousset",
                "countryCode": "FR"
            },
            {
                "name": "Roussillon",
                "countryCode": "FR"
            },
            {
                "name": "Régusse",
                "countryCode": "FR"
            },
            {
                "name": "Sablet",
                "countryCode": "FR"
            },
            {
                "name": "Saignon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Andiol",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-de-la-Roche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Antoine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Barnabé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Barthélémy",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Bonnet-en-Champsaur",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cannat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chaffrey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Chamas",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Charles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cézaire-sur-Siagne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Didier",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Giniez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Henri",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean du Désert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-Cap-Ferrat",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jeannet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Joseph",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Just",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jérôme",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lambert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-du-Var",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lazare",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Louis",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Loup",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mandrier-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marc-Jaumegarde",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Marcel",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-Vésubie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Crau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-Queyrières",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Var",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mauront",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Maximin-la-Sainte-Baume",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Menet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-l’Observatoire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mitre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mitre-les-Remparts",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-de-Vence",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-en-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Raphaël",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-de-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin-lès-Apt",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin-lès-Avignon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Savournin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Tronc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Tropez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vallier-de-Thiey",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Victoret",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Zacharie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Tinée",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-du-Grès",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Agnès",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Anastasie-sur-Issole",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Anne",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Cécile-les-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marguerite",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Marthe",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Maxime",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Tulle",
                "countryCode": "FR"
            },
            {
                "name": "Saintes-Maries-de-la-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Salernes",
                "countryCode": "FR"
            },
            {
                "name": "Salon-de-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Sanary-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Sarrians",
                "countryCode": "FR"
            },
            {
                "name": "Sault",
                "countryCode": "FR"
            },
            {
                "name": "Sausset-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Seillans",
                "countryCode": "FR"
            },
            {
                "name": "Septèmes-les-Vallons",
                "countryCode": "FR"
            },
            {
                "name": "Serres",
                "countryCode": "FR"
            },
            {
                "name": "Seyne-les-Alpes",
                "countryCode": "FR"
            },
            {
                "name": "Signes",
                "countryCode": "FR"
            },
            {
                "name": "Simiane-Collongue",
                "countryCode": "FR"
            },
            {
                "name": "Sisteron",
                "countryCode": "FR"
            },
            {
                "name": "Six-Fours-les-Plages",
                "countryCode": "FR"
            },
            {
                "name": "Solliès-Pont",
                "countryCode": "FR"
            },
            {
                "name": "Solliès-Toucas",
                "countryCode": "FR"
            },
            {
                "name": "Solliès-Ville",
                "countryCode": "FR"
            },
            {
                "name": "Sorgues",
                "countryCode": "FR"
            },
            {
                "name": "Sormiou",
                "countryCode": "FR"
            },
            {
                "name": "Sospel",
                "countryCode": "FR"
            },
            {
                "name": "Spéracèdes",
                "countryCode": "FR"
            },
            {
                "name": "Sénas",
                "countryCode": "FR"
            },
            {
                "name": "Sérignan-du-Comtat",
                "countryCode": "FR"
            },
            {
                "name": "Taillades",
                "countryCode": "FR"
            },
            {
                "name": "Tallard",
                "countryCode": "FR"
            },
            {
                "name": "Tanneron",
                "countryCode": "FR"
            },
            {
                "name": "Taradeau",
                "countryCode": "FR"
            },
            {
                "name": "Tarascon",
                "countryCode": "FR"
            },
            {
                "name": "Tende",
                "countryCode": "FR"
            },
            {
                "name": "Thiers",
                "countryCode": "FR"
            },
            {
                "name": "Théoule-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Toulon",
                "countryCode": "FR"
            },
            {
                "name": "Tourrette-Levens",
                "countryCode": "FR"
            },
            {
                "name": "Tourrettes-sur-Loup",
                "countryCode": "FR"
            },
            {
                "name": "Tourves",
                "countryCode": "FR"
            },
            {
                "name": "Trans-en-Provence",
                "countryCode": "FR"
            },
            {
                "name": "Trets",
                "countryCode": "FR"
            },
            {
                "name": "Uchaux",
                "countryCode": "FR"
            },
            {
                "name": "Vacqueyras",
                "countryCode": "FR"
            },
            {
                "name": "Vaison-la-Romaine",
                "countryCode": "FR"
            },
            {
                "name": "Valbonne",
                "countryCode": "FR"
            },
            {
                "name": "Valensole",
                "countryCode": "FR"
            },
            {
                "name": "Vallauris",
                "countryCode": "FR"
            },
            {
                "name": "Valréas",
                "countryCode": "FR"
            },
            {
                "name": "Var",
                "countryCode": "FR"
            },
            {
                "name": "Vedène",
                "countryCode": "FR"
            },
            {
                "name": "Velaux",
                "countryCode": "FR"
            },
            {
                "name": "Velleron",
                "countryCode": "FR"
            },
            {
                "name": "Venasque",
                "countryCode": "FR"
            },
            {
                "name": "Vence",
                "countryCode": "FR"
            },
            {
                "name": "Venelles",
                "countryCode": "FR"
            },
            {
                "name": "Ventabren",
                "countryCode": "FR"
            },
            {
                "name": "Verduron",
                "countryCode": "FR"
            },
            {
                "name": "Vernègues",
                "countryCode": "FR"
            },
            {
                "name": "Veynes",
                "countryCode": "FR"
            },
            {
                "name": "Vidauban",
                "countryCode": "FR"
            },
            {
                "name": "Vieille Chapelle",
                "countryCode": "FR"
            },
            {
                "name": "Villar-Saint-Pancrace",
                "countryCode": "FR"
            },
            {
                "name": "Villecroze",
                "countryCode": "FR"
            },
            {
                "name": "Villefranche-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Villelaure",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve",
                "countryCode": "FR"
            },
            {
                "name": "Villeneuve-Loubet",
                "countryCode": "FR"
            },
            {
                "name": "Villes-sur-Auzon",
                "countryCode": "FR"
            },
            {
                "name": "Vinon-sur-Verdon",
                "countryCode": "FR"
            },
            {
                "name": "Violès",
                "countryCode": "FR"
            },
            {
                "name": "Visan",
                "countryCode": "FR"
            },
            {
                "name": "Vitrolles",
                "countryCode": "FR"
            },
            {
                "name": "Volonne",
                "countryCode": "FR"
            },
            {
                "name": "Volx",
                "countryCode": "FR"
            },
            {
                "name": "Èze",
                "countryCode": "FR"
            },
            {
                "name": "Éguilles",
                "countryCode": "FR"
            },
            {
                "name": "Éoures",
                "countryCode": "FR"
            },
            {
                "name": "Évenos",
                "countryCode": "FR"
            },
            {
                "name": "Abbaretz",
                "countryCode": "FR"
            },
            {
                "name": "Ahuillé",
                "countryCode": "FR"
            },
            {
                "name": "Aigné",
                "countryCode": "FR"
            },
            {
                "name": "Aigrefeuille-sur-Maine",
                "countryCode": "FR"
            },
            {
                "name": "Aizenay",
                "countryCode": "FR"
            },
            {
                "name": "Allonnes",
                "countryCode": "FR"
            },
            {
                "name": "Ambrières-les-Vallées",
                "countryCode": "FR"
            },
            {
                "name": "Ancenis",
                "countryCode": "FR"
            },
            {
                "name": "Andard",
                "countryCode": "FR"
            },
            {
                "name": "Andouillé",
                "countryCode": "FR"
            },
            {
                "name": "Andrezé",
                "countryCode": "FR"
            },
            {
                "name": "Anetz",
                "countryCode": "FR"
            },
            {
                "name": "Angers",
                "countryCode": "FR"
            },
            {
                "name": "Angles",
                "countryCode": "FR"
            },
            {
                "name": "Antigny",
                "countryCode": "FR"
            },
            {
                "name": "Apremont",
                "countryCode": "FR"
            },
            {
                "name": "Argentré",
                "countryCode": "FR"
            },
            {
                "name": "Arnage",
                "countryCode": "FR"
            },
            {
                "name": "Aron",
                "countryCode": "FR"
            },
            {
                "name": "Arthon-en-Retz",
                "countryCode": "FR"
            },
            {
                "name": "Arçonnay",
                "countryCode": "FR"
            },
            {
                "name": "Assérac",
                "countryCode": "FR"
            },
            {
                "name": "Aubigny",
                "countryCode": "FR"
            },
            {
                "name": "Aubigné-Racan",
                "countryCode": "FR"
            },
            {
                "name": "Auvers-le-Hamon",
                "countryCode": "FR"
            },
            {
                "name": "Avessac",
                "countryCode": "FR"
            },
            {
                "name": "Avrillé",
                "countryCode": "FR"
            },
            {
                "name": "Azé",
                "countryCode": "FR"
            },
            {
                "name": "Bais",
                "countryCode": "FR"
            },
            {
                "name": "Ballon",
                "countryCode": "FR"
            },
            {
                "name": "Ballots",
                "countryCode": "FR"
            },
            {
                "name": "Barbechat",
                "countryCode": "FR"
            },
            {
                "name": "Barbâtre",
                "countryCode": "FR"
            },
            {
                "name": "Basse-Goulaine",
                "countryCode": "FR"
            },
            {
                "name": "Batz-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Baugé-en-Anjou",
                "countryCode": "FR"
            },
            {
                "name": "Bauné",
                "countryCode": "FR"
            },
            {
                "name": "Bazoges-en-Pareds",
                "countryCode": "FR"
            },
            {
                "name": "Bazouges-sur-le-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Beaucouzé",
                "countryCode": "FR"
            },
            {
                "name": "Beaufay",
                "countryCode": "FR"
            },
            {
                "name": "Beaufort-en-Vallée",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu-sous-la-Roche",
                "countryCode": "FR"
            },
            {
                "name": "Beaulieu-sur-Layon",
                "countryCode": "FR"
            },
            {
                "name": "Beaumont-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Beaupréau",
                "countryCode": "FR"
            },
            {
                "name": "Beaurepaire",
                "countryCode": "FR"
            },
            {
                "name": "Beauvoir-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Belleville-sur-Vie",
                "countryCode": "FR"
            },
            {
                "name": "Belligné",
                "countryCode": "FR"
            },
            {
                "name": "Benet",
                "countryCode": "FR"
            },
            {
                "name": "Besné",
                "countryCode": "FR"
            },
            {
                "name": "Bessé-sur-Braye",
                "countryCode": "FR"
            },
            {
                "name": "Blain",
                "countryCode": "FR"
            },
            {
                "name": "Blaison-Gohier",
                "countryCode": "FR"
            },
            {
                "name": "Bois-de-Cené",
                "countryCode": "FR"
            },
            {
                "name": "Bonchamp-lès-Laval",
                "countryCode": "FR"
            },
            {
                "name": "Bonnétable",
                "countryCode": "FR"
            },
            {
                "name": "Bouaye",
                "countryCode": "FR"
            },
            {
                "name": "Bouchemaine",
                "countryCode": "FR"
            },
            {
                "name": "Boufféré",
                "countryCode": "FR"
            },
            {
                "name": "Bouguenais",
                "countryCode": "FR"
            },
            {
                "name": "Bouin",
                "countryCode": "FR"
            },
            {
                "name": "Bouloire",
                "countryCode": "FR"
            },
            {
                "name": "Bourg de Joué-sur-Erdre",
                "countryCode": "FR"
            },
            {
                "name": "Bourgneuf-en-Retz",
                "countryCode": "FR"
            },
            {
                "name": "Bournezeau",
                "countryCode": "FR"
            },
            {
                "name": "Boussay",
                "countryCode": "FR"
            },
            {
                "name": "Bouvron",
                "countryCode": "FR"
            },
            {
                "name": "Bouzillé",
                "countryCode": "FR"
            },
            {
                "name": "Brain-sur-Allonnes",
                "countryCode": "FR"
            },
            {
                "name": "Brains",
                "countryCode": "FR"
            },
            {
                "name": "Bretignolles-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Brette-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Briollay",
                "countryCode": "FR"
            },
            {
                "name": "Brion",
                "countryCode": "FR"
            },
            {
                "name": "Brissac-Quincé",
                "countryCode": "FR"
            },
            {
                "name": "Brézé",
                "countryCode": "FR"
            },
            {
                "name": "Brûlon",
                "countryCode": "FR"
            },
            {
                "name": "Bécon-les-Granits",
                "countryCode": "FR"
            },
            {
                "name": "Bégrolles-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Campbon",
                "countryCode": "FR"
            },
            {
                "name": "Candé",
                "countryCode": "FR"
            },
            {
                "name": "Cantenay-Épinard",
                "countryCode": "FR"
            },
            {
                "name": "Carquefou",
                "countryCode": "FR"
            },
            {
                "name": "Casson",
                "countryCode": "FR"
            },
            {
                "name": "Chacé",
                "countryCode": "FR"
            },
            {
                "name": "Chailland",
                "countryCode": "FR"
            },
            {
                "name": "Chaillé-les-Marais",
                "countryCode": "FR"
            },
            {
                "name": "Challans",
                "countryCode": "FR"
            },
            {
                "name": "Challes",
                "countryCode": "FR"
            },
            {
                "name": "Chalonnes-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Chambretaud",
                "countryCode": "FR"
            },
            {
                "name": "Champagné",
                "countryCode": "FR"
            },
            {
                "name": "Champagné-les-Marais",
                "countryCode": "FR"
            },
            {
                "name": "Champfleur",
                "countryCode": "FR"
            },
            {
                "name": "Champigné",
                "countryCode": "FR"
            },
            {
                "name": "Champtoceaux",
                "countryCode": "FR"
            },
            {
                "name": "Champtocé-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Changé",
                "countryCode": "FR"
            },
            {
                "name": "Chantonnay",
                "countryCode": "FR"
            },
            {
                "name": "Chauché",
                "countryCode": "FR"
            },
            {
                "name": "Chaudron-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Chauvé",
                "countryCode": "FR"
            },
            {
                "name": "Chavagnes-en-Paillers",
                "countryCode": "FR"
            },
            {
                "name": "Chemazé",
                "countryCode": "FR"
            },
            {
                "name": "Chemillé-Melay",
                "countryCode": "FR"
            },
            {
                "name": "Cherré",
                "countryCode": "FR"
            },
            {
                "name": "Cholet",
                "countryCode": "FR"
            },
            {
                "name": "Château-Gontier",
                "countryCode": "FR"
            },
            {
                "name": "Château-Guibert",
                "countryCode": "FR"
            },
            {
                "name": "Château-du-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Château-d’Olonne",
                "countryCode": "FR"
            },
            {
                "name": "Châteaubriant",
                "countryCode": "FR"
            },
            {
                "name": "Châteauneuf-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Chéméré",
                "countryCode": "FR"
            },
            {
                "name": "Chênehutte-Trèves-Cunault",
                "countryCode": "FR"
            },
            {
                "name": "Clermont-Créans",
                "countryCode": "FR"
            },
            {
                "name": "Clisson",
                "countryCode": "FR"
            },
            {
                "name": "Combrée",
                "countryCode": "FR"
            },
            {
                "name": "Commequiers",
                "countryCode": "FR"
            },
            {
                "name": "Commer",
                "countryCode": "FR"
            },
            {
                "name": "Congrier",
                "countryCode": "FR"
            },
            {
                "name": "Conlie",
                "countryCode": "FR"
            },
            {
                "name": "Connerré",
                "countryCode": "FR"
            },
            {
                "name": "Conquereuil",
                "countryCode": "FR"
            },
            {
                "name": "Corcoué-sur-Logne",
                "countryCode": "FR"
            },
            {
                "name": "Cordemais",
                "countryCode": "FR"
            },
            {
                "name": "Corné",
                "countryCode": "FR"
            },
            {
                "name": "Coron",
                "countryCode": "FR"
            },
            {
                "name": "Corsept",
                "countryCode": "FR"
            },
            {
                "name": "Corzé",
                "countryCode": "FR"
            },
            {
                "name": "Cossé-le-Vivien",
                "countryCode": "FR"
            },
            {
                "name": "Couffé",
                "countryCode": "FR"
            },
            {
                "name": "Coulaines",
                "countryCode": "FR"
            },
            {
                "name": "Coulans-sur-Gée",
                "countryCode": "FR"
            },
            {
                "name": "Courcité",
                "countryCode": "FR"
            },
            {
                "name": "Couëron",
                "countryCode": "FR"
            },
            {
                "name": "Coëx",
                "countryCode": "FR"
            },
            {
                "name": "Craon",
                "countryCode": "FR"
            },
            {
                "name": "Crossac",
                "countryCode": "FR"
            },
            {
                "name": "Cugand",
                "countryCode": "FR"
            },
            {
                "name": "Cérans-Foulletourte",
                "countryCode": "FR"
            },
            {
                "name": "Daumeray",
                "countryCode": "FR"
            },
            {
                "name": "Denée",
                "countryCode": "FR"
            },
            {
                "name": "Derval",
                "countryCode": "FR"
            },
            {
                "name": "Distré",
                "countryCode": "FR"
            },
            {
                "name": "Dollon",
                "countryCode": "FR"
            },
            {
                "name": "Dompierre-sur-Yon",
                "countryCode": "FR"
            },
            {
                "name": "Donges",
                "countryCode": "FR"
            },
            {
                "name": "Doué-la-Fontaine",
                "countryCode": "FR"
            },
            {
                "name": "Drain",
                "countryCode": "FR"
            },
            {
                "name": "Drefféac",
                "countryCode": "FR"
            },
            {
                "name": "Durtal",
                "countryCode": "FR"
            },
            {
                "name": "Département de Maine-et-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Département de la Vendée",
                "countryCode": "FR"
            },
            {
                "name": "Entrammes",
                "countryCode": "FR"
            },
            {
                "name": "Erbray",
                "countryCode": "FR"
            },
            {
                "name": "Ernée",
                "countryCode": "FR"
            },
            {
                "name": "Falleron",
                "countryCode": "FR"
            },
            {
                "name": "Fay-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Feneu",
                "countryCode": "FR"
            },
            {
                "name": "Fillé",
                "countryCode": "FR"
            },
            {
                "name": "Fontenay-le-Comte",
                "countryCode": "FR"
            },
            {
                "name": "Fontevraud-l'Abbaye",
                "countryCode": "FR"
            },
            {
                "name": "Fougerolles-du-Plessis",
                "countryCode": "FR"
            },
            {
                "name": "Foussais-Payré",
                "countryCode": "FR"
            },
            {
                "name": "Freigné",
                "countryCode": "FR"
            },
            {
                "name": "Fresnay-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Froidfond",
                "countryCode": "FR"
            },
            {
                "name": "Frossay",
                "countryCode": "FR"
            },
            {
                "name": "Fégréac",
                "countryCode": "FR"
            },
            {
                "name": "Geneston",
                "countryCode": "FR"
            },
            {
                "name": "Gennes",
                "countryCode": "FR"
            },
            {
                "name": "Gesté",
                "countryCode": "FR"
            },
            {
                "name": "Givrand",
                "countryCode": "FR"
            },
            {
                "name": "Gorges",
                "countryCode": "FR"
            },
            {
                "name": "Gorron",
                "countryCode": "FR"
            },
            {
                "name": "Grez-Neuville",
                "countryCode": "FR"
            },
            {
                "name": "Grosbreuil",
                "countryCode": "FR"
            },
            {
                "name": "Guenrouet",
                "countryCode": "FR"
            },
            {
                "name": "Guécélard",
                "countryCode": "FR"
            },
            {
                "name": "Guémené-Penfao",
                "countryCode": "FR"
            },
            {
                "name": "Guérande",
                "countryCode": "FR"
            },
            {
                "name": "Gétigné",
                "countryCode": "FR"
            },
            {
                "name": "Haute-Goulaine",
                "countryCode": "FR"
            },
            {
                "name": "Herbignac",
                "countryCode": "FR"
            },
            {
                "name": "Héric",
                "countryCode": "FR"
            },
            {
                "name": "Indre",
                "countryCode": "FR"
            },
            {
                "name": "Ingrandes",
                "countryCode": "FR"
            },
            {
                "name": "Issé",
                "countryCode": "FR"
            },
            {
                "name": "Jallais",
                "countryCode": "FR"
            },
            {
                "name": "Jans",
                "countryCode": "FR"
            },
            {
                "name": "Jard-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Jarzé",
                "countryCode": "FR"
            },
            {
                "name": "Javron-les-Chapelles",
                "countryCode": "FR"
            },
            {
                "name": "Juigné",
                "countryCode": "FR"
            },
            {
                "name": "Juigné-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Juigné-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Jumelles",
                "countryCode": "FR"
            },
            {
                "name": "Juvigné",
                "countryCode": "FR"
            },
            {
                "name": "La Baconnière",
                "countryCode": "FR"
            },
            {
                "name": "La Barre-de-Monts",
                "countryCode": "FR"
            },
            {
                "name": "La Baule-Escoublac",
                "countryCode": "FR"
            },
            {
                "name": "La Bazoge",
                "countryCode": "FR"
            },
            {
                "name": "La Bernardière",
                "countryCode": "FR"
            },
            {
                "name": "La Bernerie-en-Retz",
                "countryCode": "FR"
            },
            {
                "name": "La Bohalle",
                "countryCode": "FR"
            },
            {
                "name": "La Boissière-de-Montaigu",
                "countryCode": "FR"
            },
            {
                "name": "La Boissière-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "La Bruffière",
                "countryCode": "FR"
            },
            {
                "name": "La Chaize-le-Vicomte",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Achard",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Basse-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Heulin",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Launay",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Aubin",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-Saint-Florent",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-des-Marais",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-du-Genêt",
                "countryCode": "FR"
            },
            {
                "name": "La Chapelle-sur-Erdre",
                "countryCode": "FR"
            },
            {
                "name": "La Chartre-sur-le-Loir",
                "countryCode": "FR"
            },
            {
                "name": "La Chevrolière",
                "countryCode": "FR"
            },
            {
                "name": "La Châtaigneraie",
                "countryCode": "FR"
            },
            {
                "name": "La Daguenière",
                "countryCode": "FR"
            },
            {
                "name": "La Ferrière",
                "countryCode": "FR"
            },
            {
                "name": "La Ferté-Bernard",
                "countryCode": "FR"
            },
            {
                "name": "La Flocellière",
                "countryCode": "FR"
            },
            {
                "name": "La Flèche",
                "countryCode": "FR"
            },
            {
                "name": "La Garnache",
                "countryCode": "FR"
            },
            {
                "name": "La Gaubretière",
                "countryCode": "FR"
            },
            {
                "name": "La Grigonnais",
                "countryCode": "FR"
            },
            {
                "name": "La Guyonnière",
                "countryCode": "FR"
            },
            {
                "name": "La Guérinière",
                "countryCode": "FR"
            },
            {
                "name": "La Génétouze",
                "countryCode": "FR"
            },
            {
                "name": "La Haie-Fouassière",
                "countryCode": "FR"
            },
            {
                "name": "La Jubaudière",
                "countryCode": "FR"
            },
            {
                "name": "La Jumellière",
                "countryCode": "FR"
            },
            {
                "name": "La Limouzinière",
                "countryCode": "FR"
            },
            {
                "name": "La Meignanne",
                "countryCode": "FR"
            },
            {
                "name": "La Meilleraie-Tillay",
                "countryCode": "FR"
            },
            {
                "name": "La Meilleraye-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "La Membrolle-sur-Longuenée",
                "countryCode": "FR"
            },
            {
                "name": "La Milesse",
                "countryCode": "FR"
            },
            {
                "name": "La Montagne",
                "countryCode": "FR"
            },
            {
                "name": "La Mothe-Achard",
                "countryCode": "FR"
            },
            {
                "name": "La Ménitré",
                "countryCode": "FR"
            },
            {
                "name": "La Plaine-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Planche",
                "countryCode": "FR"
            },
            {
                "name": "La Pommeraie-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "La Pommeraye",
                "countryCode": "FR"
            },
            {
                "name": "La Possonnière",
                "countryCode": "FR"
            },
            {
                "name": "La Pouëze",
                "countryCode": "FR"
            },
            {
                "name": "La Regrippière",
                "countryCode": "FR"
            },
            {
                "name": "La Remaudière",
                "countryCode": "FR"
            },
            {
                "name": "La Roche-sur-Yon",
                "countryCode": "FR"
            },
            {
                "name": "La Romagne",
                "countryCode": "FR"
            },
            {
                "name": "La Salle-et-Chapelle-Aubry",
                "countryCode": "FR"
            },
            {
                "name": "La Suze-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "La Séguinière",
                "countryCode": "FR"
            },
            {
                "name": "La Tardière",
                "countryCode": "FR"
            },
            {
                "name": "La Tessoualle",
                "countryCode": "FR"
            },
            {
                "name": "La Tourlandry",
                "countryCode": "FR"
            },
            {
                "name": "La Tranche-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "La Turballe",
                "countryCode": "FR"
            },
            {
                "name": "La Varenne",
                "countryCode": "FR"
            },
            {
                "name": "La Verrie",
                "countryCode": "FR"
            },
            {
                "name": "Laigné-en-Belin",
                "countryCode": "FR"
            },
            {
                "name": "Landemont",
                "countryCode": "FR"
            },
            {
                "name": "Landeronde",
                "countryCode": "FR"
            },
            {
                "name": "Landivy",
                "countryCode": "FR"
            },
            {
                "name": "Larchamp",
                "countryCode": "FR"
            },
            {
                "name": "Lassay-les-Châteaux",
                "countryCode": "FR"
            },
            {
                "name": "Laval",
                "countryCode": "FR"
            },
            {
                "name": "Le Bignon",
                "countryCode": "FR"
            },
            {
                "name": "Le Boupère",
                "countryCode": "FR"
            },
            {
                "name": "Le Bourgneuf-la-Forêt",
                "countryCode": "FR"
            },
            {
                "name": "Le Breil-sur-Mérize",
                "countryCode": "FR"
            },
            {
                "name": "Le Cellier",
                "countryCode": "FR"
            },
            {
                "name": "Le Champ-Saint-Père",
                "countryCode": "FR"
            },
            {
                "name": "Le Croisic",
                "countryCode": "FR"
            },
            {
                "name": "Le Fenouiller",
                "countryCode": "FR"
            },
            {
                "name": "Le Fief-Sauvin",
                "countryCode": "FR"
            },
            {
                "name": "Le Fuilet",
                "countryCode": "FR"
            },
            {
                "name": "Le Grand-Lucé",
                "countryCode": "FR"
            },
            {
                "name": "Le Gâvre",
                "countryCode": "FR"
            },
            {
                "name": "Le Landreau",
                "countryCode": "FR"
            },
            {
                "name": "Le Langon",
                "countryCode": "FR"
            },
            {
                "name": "Le Longeron",
                "countryCode": "FR"
            },
            {
                "name": "Le Loroux-Bottereau",
                "countryCode": "FR"
            },
            {
                "name": "Le Louroux-Béconnais",
                "countryCode": "FR"
            },
            {
                "name": "Le Luart",
                "countryCode": "FR"
            },
            {
                "name": "Le Lude",
                "countryCode": "FR"
            },
            {
                "name": "Le Mans",
                "countryCode": "FR"
            },
            {
                "name": "Le May-sur-Èvre",
                "countryCode": "FR"
            },
            {
                "name": "Le Mesnil-en-Vallée",
                "countryCode": "FR"
            },
            {
                "name": "Le Pallet",
                "countryCode": "FR"
            },
            {
                "name": "Le Pellerin",
                "countryCode": "FR"
            },
            {
                "name": "Le Perrier",
                "countryCode": "FR"
            },
            {
                "name": "Le Pin-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Grammoire",
                "countryCode": "FR"
            },
            {
                "name": "Le Plessis-Macé",
                "countryCode": "FR"
            },
            {
                "name": "Le Poiré-sur-Vie",
                "countryCode": "FR"
            },
            {
                "name": "Le Pouliguen",
                "countryCode": "FR"
            },
            {
                "name": "Le Puy-Notre-Dame",
                "countryCode": "FR"
            },
            {
                "name": "Le Temple-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Le Vieil-Baugé",
                "countryCode": "FR"
            },
            {
                "name": "Legé",
                "countryCode": "FR"
            },
            {
                "name": "Les Brouzils",
                "countryCode": "FR"
            },
            {
                "name": "Les Clouzeaux",
                "countryCode": "FR"
            },
            {
                "name": "Les Epesses",
                "countryCode": "FR"
            },
            {
                "name": "Les Essarts",
                "countryCode": "FR"
            },
            {
                "name": "Les Herbiers",
                "countryCode": "FR"
            },
            {
                "name": "Les Landes-Genusson",
                "countryCode": "FR"
            },
            {
                "name": "Les Lucs-sur-Boulogne",
                "countryCode": "FR"
            },
            {
                "name": "Les Magnils-Reigniers",
                "countryCode": "FR"
            },
            {
                "name": "Les Moutiers-en-Retz",
                "countryCode": "FR"
            },
            {
                "name": "Les Ponts-de-Cé",
                "countryCode": "FR"
            },
            {
                "name": "Les Rosiers-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Les Sables-d’Olonne",
                "countryCode": "FR"
            },
            {
                "name": "Les Sorinières",
                "countryCode": "FR"
            },
            {
                "name": "Les Touches",
                "countryCode": "FR"
            },
            {
                "name": "Ligné",
                "countryCode": "FR"
            },
            {
                "name": "Liré",
                "countryCode": "FR"
            },
            {
                "name": "Loire-Atlantique",
                "countryCode": "FR"
            },
            {
                "name": "Loiron",
                "countryCode": "FR"
            },
            {
                "name": "Lombron",
                "countryCode": "FR"
            },
            {
                "name": "Longeville-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Louplande",
                "countryCode": "FR"
            },
            {
                "name": "Louverné",
                "countryCode": "FR"
            },
            {
                "name": "Loué",
                "countryCode": "FR"
            },
            {
                "name": "Luceau",
                "countryCode": "FR"
            },
            {
                "name": "Luché-Pringé",
                "countryCode": "FR"
            },
            {
                "name": "Lusanger",
                "countryCode": "FR"
            },
            {
                "name": "Luçon",
                "countryCode": "FR"
            },
            {
                "name": "Machecoul",
                "countryCode": "FR"
            },
            {
                "name": "Maché",
                "countryCode": "FR"
            },
            {
                "name": "Maillezais",
                "countryCode": "FR"
            },
            {
                "name": "Maisdon-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "Malicorne-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Malville",
                "countryCode": "FR"
            },
            {
                "name": "Mamers",
                "countryCode": "FR"
            },
            {
                "name": "Mansigné",
                "countryCode": "FR"
            },
            {
                "name": "Mareuil-sur-Lay-Dissais",
                "countryCode": "FR"
            },
            {
                "name": "Marigné-Laillé",
                "countryCode": "FR"
            },
            {
                "name": "Marolles-les-Braults",
                "countryCode": "FR"
            },
            {
                "name": "Marsac-sur-Don",
                "countryCode": "FR"
            },
            {
                "name": "Martigné-Briand",
                "countryCode": "FR"
            },
            {
                "name": "Martigné-sur-Mayenne",
                "countryCode": "FR"
            },
            {
                "name": "Marçon",
                "countryCode": "FR"
            },
            {
                "name": "Maulévrier",
                "countryCode": "FR"
            },
            {
                "name": "Mauves-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Mayenne",
                "countryCode": "FR"
            },
            {
                "name": "Mayet",
                "countryCode": "FR"
            },
            {
                "name": "Mazières-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Mazé",
                "countryCode": "FR"
            },
            {
                "name": "Melay",
                "countryCode": "FR"
            },
            {
                "name": "Mervent",
                "countryCode": "FR"
            },
            {
                "name": "Meslay-du-Maine",
                "countryCode": "FR"
            },
            {
                "name": "Mesquer",
                "countryCode": "FR"
            },
            {
                "name": "Miré",
                "countryCode": "FR"
            },
            {
                "name": "Missillac",
                "countryCode": "FR"
            },
            {
                "name": "Moisdon-la-Rivière",
                "countryCode": "FR"
            },
            {
                "name": "Moncé-en-Belin",
                "countryCode": "FR"
            },
            {
                "name": "Monnières",
                "countryCode": "FR"
            },
            {
                "name": "Montaigu",
                "countryCode": "FR"
            },
            {
                "name": "Montbert",
                "countryCode": "FR"
            },
            {
                "name": "Montbizot",
                "countryCode": "FR"
            },
            {
                "name": "Montenay",
                "countryCode": "FR"
            },
            {
                "name": "Montfaucon-Montigné",
                "countryCode": "FR"
            },
            {
                "name": "Montfort-le-Gesnois",
                "countryCode": "FR"
            },
            {
                "name": "Montigné-le-Brillant",
                "countryCode": "FR"
            },
            {
                "name": "Montjean-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Montoir-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Montournais",
                "countryCode": "FR"
            },
            {
                "name": "Montreuil-Bellay",
                "countryCode": "FR"
            },
            {
                "name": "Montrevault",
                "countryCode": "FR"
            },
            {
                "name": "Montsûrs",
                "countryCode": "FR"
            },
            {
                "name": "Morannes",
                "countryCode": "FR"
            },
            {
                "name": "Mortagne-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "Mouchamps",
                "countryCode": "FR"
            },
            {
                "name": "Mouilleron-en-Pareds",
                "countryCode": "FR"
            },
            {
                "name": "Mouilleron-le-Captif",
                "countryCode": "FR"
            },
            {
                "name": "Moulay",
                "countryCode": "FR"
            },
            {
                "name": "Moutiers-les-Mauxfaits",
                "countryCode": "FR"
            },
            {
                "name": "Mouzeil",
                "countryCode": "FR"
            },
            {
                "name": "Mouzillon",
                "countryCode": "FR"
            },
            {
                "name": "Mozé-sur-Louet",
                "countryCode": "FR"
            },
            {
                "name": "Mulsanne",
                "countryCode": "FR"
            },
            {
                "name": "Mésanger",
                "countryCode": "FR"
            },
            {
                "name": "Mézeray",
                "countryCode": "FR"
            },
            {
                "name": "Mûrs-Erigné",
                "countryCode": "FR"
            },
            {
                "name": "Nalliers",
                "countryCode": "FR"
            },
            {
                "name": "Nantes",
                "countryCode": "FR"
            },
            {
                "name": "Nesmy",
                "countryCode": "FR"
            },
            {
                "name": "Neuville-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Nieul-le-Dolent",
                "countryCode": "FR"
            },
            {
                "name": "Noirmoutier-en-l’Île",
                "countryCode": "FR"
            },
            {
                "name": "Nort-sur-Erdre",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Monts",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-de-Riez",
                "countryCode": "FR"
            },
            {
                "name": "Notre-Dame-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Noyant",
                "countryCode": "FR"
            },
            {
                "name": "Noyant-la-Gravoyère",
                "countryCode": "FR"
            },
            {
                "name": "Noyen-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Nozay",
                "countryCode": "FR"
            },
            {
                "name": "Nuaillé",
                "countryCode": "FR"
            },
            {
                "name": "Nueil-sur-Layon",
                "countryCode": "FR"
            },
            {
                "name": "Nuillé-sur-Vicoin",
                "countryCode": "FR"
            },
            {
                "name": "Nyoiseau",
                "countryCode": "FR"
            },
            {
                "name": "Oisseau",
                "countryCode": "FR"
            },
            {
                "name": "Olonne-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Orvault",
                "countryCode": "FR"
            },
            {
                "name": "Oudon",
                "countryCode": "FR"
            },
            {
                "name": "Paimboeuf",
                "countryCode": "FR"
            },
            {
                "name": "Pannecé",
                "countryCode": "FR"
            },
            {
                "name": "Parcé-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Parigné-le-Pôlin",
                "countryCode": "FR"
            },
            {
                "name": "Parçay-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Paulx",
                "countryCode": "FR"
            },
            {
                "name": "Pellouailles-les-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Petit-Mars",
                "countryCode": "FR"
            },
            {
                "name": "Piriac-sur-Mer",
                "countryCode": "FR"
            },
            {
                "name": "Pissotte",
                "countryCode": "FR"
            },
            {
                "name": "Plessé",
                "countryCode": "FR"
            },
            {
                "name": "Pont-Saint-Martin",
                "countryCode": "FR"
            },
            {
                "name": "Pontchâteau",
                "countryCode": "FR"
            },
            {
                "name": "Pontvallain",
                "countryCode": "FR"
            },
            {
                "name": "Pornic",
                "countryCode": "FR"
            },
            {
                "name": "Pornichet",
                "countryCode": "FR"
            },
            {
                "name": "Port-Brillet",
                "countryCode": "FR"
            },
            {
                "name": "Port-Saint-Père",
                "countryCode": "FR"
            },
            {
                "name": "Pouancé",
                "countryCode": "FR"
            },
            {
                "name": "Pouzauges",
                "countryCode": "FR"
            },
            {
                "name": "Prinquiau",
                "countryCode": "FR"
            },
            {
                "name": "Pruillé-le-Chétif",
                "countryCode": "FR"
            },
            {
                "name": "Pré-en-Pail",
                "countryCode": "FR"
            },
            {
                "name": "Précigné",
                "countryCode": "FR"
            },
            {
                "name": "Préfailles",
                "countryCode": "FR"
            },
            {
                "name": "Quelaines-Saint-Gault",
                "countryCode": "FR"
            },
            {
                "name": "Quilly",
                "countryCode": "FR"
            },
            {
                "name": "Remouillé",
                "countryCode": "FR"
            },
            {
                "name": "Renazé",
                "countryCode": "FR"
            },
            {
                "name": "Requeil",
                "countryCode": "FR"
            },
            {
                "name": "Rezé",
                "countryCode": "FR"
            },
            {
                "name": "Riaillé",
                "countryCode": "FR"
            },
            {
                "name": "Rochefort-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Rocheservière",
                "countryCode": "FR"
            },
            {
                "name": "Rouans",
                "countryCode": "FR"
            },
            {
                "name": "Rougé",
                "countryCode": "FR"
            },
            {
                "name": "Rouillon",
                "countryCode": "FR"
            },
            {
                "name": "Roussay",
                "countryCode": "FR"
            },
            {
                "name": "Roézé-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Ruaudin",
                "countryCode": "FR"
            },
            {
                "name": "Ruillé-sur-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Sablé-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Saffré",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-Treize-Voies",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-de-la-Marche",
                "countryCode": "FR"
            },
            {
                "name": "Saint-André-des-Eaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-des-Châteaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Aubin-des-Ormeaux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Baudelle",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Berthevin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Brevin-les-Pins",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Calais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christophe-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Christophe-du-Ligneron",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clément-de-la-Place",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Clément-des-Levées",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cosme-en-Vairais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Crespin-sur-Moine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Cyr-en-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-de-Gastines",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Denis-la-Chevasse",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fiacre-sur-Maine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florent-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Florent-le-Vieil",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fort",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Fulgent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-Buttavent",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Montaigu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-de-Pointindoux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Georges-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-de-Prinçay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-des-Prés",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Germain-sur-Moine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gervais-en-Belin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gildas-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Gilles-Croix-de-Vie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Géréon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Herblain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Herblon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Chaléons",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Clisson",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Loulay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Riez",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-de-Talmont",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Hilaire-des-Loges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Boiseau",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-de-Monts",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-des-Mauvrets",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Jean-sur-Mayenne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Joachim",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-de-Concelles",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Julien-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lambert-du-Lattay",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lambert-la-Potherie",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-de-la-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-des-Autels",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Laurent-sur-Sèvre",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lumine-de-Clisson",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lumine-de-Coutais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Lyphard",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-des-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-les-Vignes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Léger-sous-Cholet",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Macaire-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Malo-de-Guersac",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Malô-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mars-de-Coutais",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mars-du-Désert",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mars-d’Outillé",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mars-la-Brière",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mars-la-Jaille",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-de-la-Place",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-des-Noyers",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-du-Fouilloux",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Martin-sous-Mouzeuil",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mathurin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mathurin-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Melaine-sur-Aubance",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Mesmin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-Chef-Chef",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-Mont-Mercure",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Michel-le-Cloucq",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Molf",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Même-le-Tenu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nazaire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Nicolas-de-Redon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouen-en-Belin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Ouën-des-Toits",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paterne",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Paul-en-Pareds",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Philbert-de-Bouaine",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Philbert-de-Grand-Lieu",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Philbert-du-Peuple",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-Montlimart",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-des-Nids",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-du-Chemin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Pierre-la-Cour",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Prouant",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Père-en-Retz",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Quentin-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Rémy-en-Mauges",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Saturnin-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Sébastien-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Urbain",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Viaud",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-des-Landes",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Vincent-sur-Graon",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Mer-Morte",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-de-Montluc",
                "countryCode": "FR"
            },
            {
                "name": "Saint-Étienne-du-Bois",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Anne-sur-Brivet",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Cécile",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Flaive-des-Loups",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Foy",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Gemme-la-Plaine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Gemmes-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Hermine",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Jamme-sur-Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Luce-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Pazanne",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Reine-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Sainte-Suzanne",
                "countryCode": "FR"
            },
            {
                "name": "Saligny",
                "countryCode": "FR"
            },
            {
                "name": "Sallertaine",
                "countryCode": "FR"
            },
            {
                "name": "Sargé-lès-le-Mans",
                "countryCode": "FR"
            },
            {
                "name": "Sarthe",
                "countryCode": "FR"
            },
            {
                "name": "Saumur",
                "countryCode": "FR"
            },
            {
                "name": "Sautron",
                "countryCode": "FR"
            },
            {
                "name": "Savenay",
                "countryCode": "FR"
            },
            {
                "name": "Savennières",
                "countryCode": "FR"
            },
            {
                "name": "Segré",
                "countryCode": "FR"
            },
            {
                "name": "Seiches-sur-le-Loir",
                "countryCode": "FR"
            },
            {
                "name": "Sillé-le-Guillaume",
                "countryCode": "FR"
            },
            {
                "name": "Sion-les-Mines",
                "countryCode": "FR"
            },
            {
                "name": "Solesmes",
                "countryCode": "FR"
            },
            {
                "name": "Soucelles",
                "countryCode": "FR"
            },
            {
                "name": "Soudan",
                "countryCode": "FR"
            },
            {
                "name": "Soulaines-sur-Aubance",
                "countryCode": "FR"
            },
            {
                "name": "Soulaire-et-Bourg",
                "countryCode": "FR"
            },
            {
                "name": "Soulgé-sur-Ouette",
                "countryCode": "FR"
            },
            {
                "name": "Souligné-sous-Ballon",
                "countryCode": "FR"
            },
            {
                "name": "Soullans",
                "countryCode": "FR"
            },
            {
                "name": "Spay",
                "countryCode": "FR"
            },
            {
                "name": "Sucé-sur-Erdre",
                "countryCode": "FR"
            },
            {
                "name": "Sérigné",
                "countryCode": "FR"
            },
            {
                "name": "Sévérac",
                "countryCode": "FR"
            },
            {
                "name": "Teillé",
                "countryCode": "FR"
            },
            {
                "name": "Teloché",
                "countryCode": "FR"
            },
            {
                "name": "Tennie",
                "countryCode": "FR"
            },
            {
                "name": "Thorigné-sur-Dué",
                "countryCode": "FR"
            },
            {
                "name": "Thouarcé",
                "countryCode": "FR"
            },
            {
                "name": "Thouaré-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Tiercé",
                "countryCode": "FR"
            },
            {
                "name": "Tiffauges",
                "countryCode": "FR"
            },
            {
                "name": "Tillières",
                "countryCode": "FR"
            },
            {
                "name": "Torfou",
                "countryCode": "FR"
            },
            {
                "name": "Toutlemonde",
                "countryCode": "FR"
            },
            {
                "name": "Touvois",
                "countryCode": "FR"
            },
            {
                "name": "Trangé",
                "countryCode": "FR"
            },
            {
                "name": "Treillières",
                "countryCode": "FR"
            },
            {
                "name": "Treize-Septiers",
                "countryCode": "FR"
            },
            {
                "name": "Triaize",
                "countryCode": "FR"
            },
            {
                "name": "Trignac",
                "countryCode": "FR"
            },
            {
                "name": "Trélazé",
                "countryCode": "FR"
            },
            {
                "name": "Trémentines",
                "countryCode": "FR"
            },
            {
                "name": "Tuffé",
                "countryCode": "FR"
            },
            {
                "name": "Vaas",
                "countryCode": "FR"
            },
            {
                "name": "Vaiges",
                "countryCode": "FR"
            },
            {
                "name": "Vairé",
                "countryCode": "FR"
            },
            {
                "name": "Vallet",
                "countryCode": "FR"
            },
            {
                "name": "Varades",
                "countryCode": "FR"
            },
            {
                "name": "Varennes-sur-Loire",
                "countryCode": "FR"
            },
            {
                "name": "Varrains",
                "countryCode": "FR"
            },
            {
                "name": "Vauchrétien",
                "countryCode": "FR"
            },
            {
                "name": "Vaudelnay",
                "countryCode": "FR"
            },
            {
                "name": "Vay",
                "countryCode": "FR"
            },
            {
                "name": "Venansault",
                "countryCode": "FR"
            },
            {
                "name": "Vendrennes",
                "countryCode": "FR"
            },
            {
                "name": "Vern-d’Anjou",
                "countryCode": "FR"
            },
            {
                "name": "Vernantes",
                "countryCode": "FR"
            },
            {
                "name": "Vernoil-le-Fourrier",
                "countryCode": "FR"
            },
            {
                "name": "Vertou",
                "countryCode": "FR"
            },
            {
                "name": "Vezins",
                "countryCode": "FR"
            },
            {
                "name": "Vibraye",
                "countryCode": "FR"
            },
            {
                "name": "Vieillevigne",
                "countryCode": "FR"
            },
            {
                "name": "Vigneux-de-Bretagne",
                "countryCode": "FR"
            },
            {
                "name": "Vihiers",
                "countryCode": "FR"
            },
            {
                "name": "Villaines-la-Juhel",
                "countryCode": "FR"
            },
            {
                "name": "Villebernier",
                "countryCode": "FR"
            },
            {
                "name": "Villedieu-la-Blouère",
                "countryCode": "FR"
            },
            {
                "name": "Villevêque",
                "countryCode": "FR"
            },
            {
                "name": "Vion",
                "countryCode": "FR"
            },
            {
                "name": "Vivy",
                "countryCode": "FR"
            },
            {
                "name": "Vix",
                "countryCode": "FR"
            },
            {
                "name": "Vue",
                "countryCode": "FR"
            },
            {
                "name": "Yvré-le-Pôlin",
                "countryCode": "FR"
            },
            {
                "name": "Yzernay",
                "countryCode": "FR"
            },
            {
                "name": "Écommoy",
                "countryCode": "FR"
            },
            {
                "name": "Écouflant",
                "countryCode": "FR"
            },
            {
                "name": "Étival-lès-le-Mans",
                "countryCode": "FR"
            },
            {
                "name": "Étriché",
                "countryCode": "FR"
            },
            {
                "name": "Évron",
                "countryCode": "FR"
            }
        ]
    },
    {
        "name": "French Guiana",
        "phonecode": "594",
        "cities": []
    },
    {
        "name": "French Polynesia",
        "phonecode": "689",
        "cities": []
    },
    {
        "name": "French Southern Territories",
        "phonecode": "262",
        "cities": []
    },
    {
        "name": "Gabon",
        "phonecode": "241",
        "cities": [
            {
                "name": "Cocobeach",
                "countryCode": "GA"
            },
            {
                "name": "Libreville",
                "countryCode": "GA"
            },
            {
                "name": "Ntoum",
                "countryCode": "GA"
            },
            {
                "name": "Franceville",
                "countryCode": "GA"
            },
            {
                "name": "Lékoni",
                "countryCode": "GA"
            },
            {
                "name": "Moanda",
                "countryCode": "GA"
            },
            {
                "name": "Mounana",
                "countryCode": "GA"
            },
            {
                "name": "Okondja",
                "countryCode": "GA"
            },
            {
                "name": "Lambaréné",
                "countryCode": "GA"
            },
            {
                "name": "Ndjolé",
                "countryCode": "GA"
            },
            {
                "name": "Fougamou",
                "countryCode": "GA"
            },
            {
                "name": "Mbigou",
                "countryCode": "GA"
            },
            {
                "name": "Mimongo",
                "countryCode": "GA"
            },
            {
                "name": "Mouila",
                "countryCode": "GA"
            },
            {
                "name": "Ndendé",
                "countryCode": "GA"
            },
            {
                "name": "Mayumba",
                "countryCode": "GA"
            },
            {
                "name": "Tchibanga",
                "countryCode": "GA"
            },
            {
                "name": "Booué",
                "countryCode": "GA"
            },
            {
                "name": "Makokou",
                "countryCode": "GA"
            },
            {
                "name": "Zadie",
                "countryCode": "GA"
            },
            {
                "name": "Koulamoutou",
                "countryCode": "GA"
            },
            {
                "name": "Lastoursville",
                "countryCode": "GA"
            },
            {
                "name": "Gamba",
                "countryCode": "GA"
            },
            {
                "name": "Omboué",
                "countryCode": "GA"
            },
            {
                "name": "Port-Gentil",
                "countryCode": "GA"
            },
            {
                "name": "Bitam",
                "countryCode": "GA"
            },
            {
                "name": "Mitzic",
                "countryCode": "GA"
            },
            {
                "name": "Oyem",
                "countryCode": "GA"
            }
        ]
    },
    {
        "name": "The Gambia",
        "phonecode": "220",
        "cities": [
            {
                "name": "Bakau",
                "countryCode": "GM"
            },
            {
                "name": "Banjul",
                "countryCode": "GM"
            },
            {
                "name": "Kombo Saint Mary District",
                "countryCode": "GM"
            },
            {
                "name": "Serekunda",
                "countryCode": "GM"
            },
            {
                "name": "Baro Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Bureng",
                "countryCode": "GM"
            },
            {
                "name": "Jali",
                "countryCode": "GM"
            },
            {
                "name": "Jarra Central",
                "countryCode": "GM"
            },
            {
                "name": "Jarra East",
                "countryCode": "GM"
            },
            {
                "name": "Jarra West",
                "countryCode": "GM"
            },
            {
                "name": "Jenoi",
                "countryCode": "GM"
            },
            {
                "name": "Jifarong",
                "countryCode": "GM"
            },
            {
                "name": "Kaiaf",
                "countryCode": "GM"
            },
            {
                "name": "Karantaba",
                "countryCode": "GM"
            },
            {
                "name": "Keneba",
                "countryCode": "GM"
            },
            {
                "name": "Kiang Central",
                "countryCode": "GM"
            },
            {
                "name": "Kiang East",
                "countryCode": "GM"
            },
            {
                "name": "Kiang West District",
                "countryCode": "GM"
            },
            {
                "name": "Mansa Konko",
                "countryCode": "GM"
            },
            {
                "name": "Nioro",
                "countryCode": "GM"
            },
            {
                "name": "Sankwia",
                "countryCode": "GM"
            },
            {
                "name": "Si Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Soma",
                "countryCode": "GM"
            },
            {
                "name": "Sutukung",
                "countryCode": "GM"
            },
            {
                "name": "Toniataba",
                "countryCode": "GM"
            },
            {
                "name": "Wellingara Ba",
                "countryCode": "GM"
            },
            {
                "name": "Bansang",
                "countryCode": "GM"
            },
            {
                "name": "Brikama Nding",
                "countryCode": "GM"
            },
            {
                "name": "Dankunku",
                "countryCode": "GM"
            },
            {
                "name": "Denton",
                "countryCode": "GM"
            },
            {
                "name": "Fulladu West",
                "countryCode": "GM"
            },
            {
                "name": "Galleh Manda",
                "countryCode": "GM"
            },
            {
                "name": "Georgetown",
                "countryCode": "GM"
            },
            {
                "name": "Jakhaly",
                "countryCode": "GM"
            },
            {
                "name": "Janjanbureh",
                "countryCode": "GM"
            },
            {
                "name": "Jarreng",
                "countryCode": "GM"
            },
            {
                "name": "Karantaba",
                "countryCode": "GM"
            },
            {
                "name": "Kass Wollof",
                "countryCode": "GM"
            },
            {
                "name": "Kuntaur",
                "countryCode": "GM"
            },
            {
                "name": "Kunting",
                "countryCode": "GM"
            },
            {
                "name": "Lower Saloum",
                "countryCode": "GM"
            },
            {
                "name": "Niamina East District",
                "countryCode": "GM"
            },
            {
                "name": "Niamina West District",
                "countryCode": "GM"
            },
            {
                "name": "Niani",
                "countryCode": "GM"
            },
            {
                "name": "Nianija District",
                "countryCode": "GM"
            },
            {
                "name": "Pateh Sam",
                "countryCode": "GM"
            },
            {
                "name": "Sami",
                "countryCode": "GM"
            },
            {
                "name": "Sami District",
                "countryCode": "GM"
            },
            {
                "name": "Saruja",
                "countryCode": "GM"
            },
            {
                "name": "Sukuta",
                "countryCode": "GM"
            },
            {
                "name": "Upper Saloum",
                "countryCode": "GM"
            },
            {
                "name": "Wassu",
                "countryCode": "GM"
            },
            {
                "name": "Bambali",
                "countryCode": "GM"
            },
            {
                "name": "Barra",
                "countryCode": "GM"
            },
            {
                "name": "Central Baddibu",
                "countryCode": "GM"
            },
            {
                "name": "Chilla",
                "countryCode": "GM"
            },
            {
                "name": "Daru Rilwan",
                "countryCode": "GM"
            },
            {
                "name": "Essau",
                "countryCode": "GM"
            },
            {
                "name": "Farafenni",
                "countryCode": "GM"
            },
            {
                "name": "Gunjur",
                "countryCode": "GM"
            },
            {
                "name": "Jokadu",
                "countryCode": "GM"
            },
            {
                "name": "Katchang",
                "countryCode": "GM"
            },
            {
                "name": "Kerewan",
                "countryCode": "GM"
            },
            {
                "name": "Lamin",
                "countryCode": "GM"
            },
            {
                "name": "Lower Baddibu District",
                "countryCode": "GM"
            },
            {
                "name": "Lower Niumi District",
                "countryCode": "GM"
            },
            {
                "name": "No Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Saba",
                "countryCode": "GM"
            },
            {
                "name": "Sara Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Upper Baddibu",
                "countryCode": "GM"
            },
            {
                "name": "Upper Niumi District",
                "countryCode": "GM"
            },
            {
                "name": "Bakadagy",
                "countryCode": "GM"
            },
            {
                "name": "Basse Santa Su",
                "countryCode": "GM"
            },
            {
                "name": "Brifu",
                "countryCode": "GM"
            },
            {
                "name": "Daba Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Demba Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Diabugu",
                "countryCode": "GM"
            },
            {
                "name": "Diabugu Basilla",
                "countryCode": "GM"
            },
            {
                "name": "Fulladu East",
                "countryCode": "GM"
            },
            {
                "name": "Gunjur Kuta",
                "countryCode": "GM"
            },
            {
                "name": "Kantora",
                "countryCode": "GM"
            },
            {
                "name": "Koina",
                "countryCode": "GM"
            },
            {
                "name": "Kumbija",
                "countryCode": "GM"
            },
            {
                "name": "Nyamanari",
                "countryCode": "GM"
            },
            {
                "name": "Perai",
                "countryCode": "GM"
            },
            {
                "name": "Sabi",
                "countryCode": "GM"
            },
            {
                "name": "Sandu",
                "countryCode": "GM"
            },
            {
                "name": "Sudowol",
                "countryCode": "GM"
            },
            {
                "name": "Sun Kunda",
                "countryCode": "GM"
            },
            {
                "name": "Sutukoba",
                "countryCode": "GM"
            },
            {
                "name": "Wuli",
                "countryCode": "GM"
            },
            {
                "name": "Abuko",
                "countryCode": "GM"
            },
            {
                "name": "Brikama",
                "countryCode": "GM"
            },
            {
                "name": "Foni Bondali",
                "countryCode": "GM"
            },
            {
                "name": "Foni Brefet",
                "countryCode": "GM"
            },
            {
                "name": "Foni Jarrol",
                "countryCode": "GM"
            },
            {
                "name": "Foni Kansala",
                "countryCode": "GM"
            },
            {
                "name": "Gunjur",
                "countryCode": "GM"
            },
            {
                "name": "Kombo Central District",
                "countryCode": "GM"
            },
            {
                "name": "Kombo East District",
                "countryCode": "GM"
            },
            {
                "name": "Kombo North District",
                "countryCode": "GM"
            },
            {
                "name": "Kombo South District",
                "countryCode": "GM"
            },
            {
                "name": "Somita",
                "countryCode": "GM"
            },
            {
                "name": "Sukuta",
                "countryCode": "GM"
            }
        ]
    },
    {
        "name": "Georgia",
        "phonecode": "995",
        "cities": [
            {
                "name": "Bich’vinta",
                "countryCode": "GE"
            },
            {
                "name": "Dranda",
                "countryCode": "GE"
            },
            {
                "name": "Gagra",
                "countryCode": "GE"
            },
            {
                "name": "Gali",
                "countryCode": "GE"
            },
            {
                "name": "Gantiadi",
                "countryCode": "GE"
            },
            {
                "name": "Gudauta",
                "countryCode": "GE"
            },
            {
                "name": "Kelasuri",
                "countryCode": "GE"
            },
            {
                "name": "Och’amch’ire",
                "countryCode": "GE"
            },
            {
                "name": "P’rimorsk’oe",
                "countryCode": "GE"
            },
            {
                "name": "Sokhumi",
                "countryCode": "GE"
            },
            {
                "name": "Stantsiya Novyy Afon",
                "countryCode": "GE"
            },
            {
                "name": "Tqvarch'eli",
                "countryCode": "GE"
            },
            {
                "name": "Akhaldaba",
                "countryCode": "GE"
            },
            {
                "name": "Batumi",
                "countryCode": "GE"
            },
            {
                "name": "Chakvi",
                "countryCode": "GE"
            },
            {
                "name": "Dioknisi",
                "countryCode": "GE"
            },
            {
                "name": "Khelvachauri",
                "countryCode": "GE"
            },
            {
                "name": "Khulo",
                "countryCode": "GE"
            },
            {
                "name": "Kobuleti",
                "countryCode": "GE"
            },
            {
                "name": "Makhinjauri",
                "countryCode": "GE"
            },
            {
                "name": "Ochkhamuri",
                "countryCode": "GE"
            },
            {
                "name": "Shuakhevi",
                "countryCode": "GE"
            },
            {
                "name": "Tsikhisdziri",
                "countryCode": "GE"
            },
            {
                "name": "Lanchkhuti",
                "countryCode": "GE"
            },
            {
                "name": "Naruja",
                "countryCode": "GE"
            },
            {
                "name": "Ozurgeti",
                "countryCode": "GE"
            },
            {
                "name": "Urek’i",
                "countryCode": "GE"
            },
            {
                "name": "Baghdatis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Chiat’ura",
                "countryCode": "GE"
            },
            {
                "name": "Kharagauli",
                "countryCode": "GE"
            },
            {
                "name": "Khoni",
                "countryCode": "GE"
            },
            {
                "name": "Kutaisi",
                "countryCode": "GE"
            },
            {
                "name": "K’alak’i Chiat’ura",
                "countryCode": "GE"
            },
            {
                "name": "K’ulashi",
                "countryCode": "GE"
            },
            {
                "name": "Sach’khere",
                "countryCode": "GE"
            },
            {
                "name": "Samtredia",
                "countryCode": "GE"
            },
            {
                "name": "Shorapani",
                "countryCode": "GE"
            },
            {
                "name": "Tqibuli",
                "countryCode": "GE"
            },
            {
                "name": "Tsqaltubo",
                "countryCode": "GE"
            },
            {
                "name": "Vani",
                "countryCode": "GE"
            },
            {
                "name": "Zestap’oni",
                "countryCode": "GE"
            },
            {
                "name": "Akhmet’a",
                "countryCode": "GE"
            },
            {
                "name": "Akhmet’is Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Gurjaani",
                "countryCode": "GE"
            },
            {
                "name": "Lagodekhi",
                "countryCode": "GE"
            },
            {
                "name": "Qvareli",
                "countryCode": "GE"
            },
            {
                "name": "Sagarejo",
                "countryCode": "GE"
            },
            {
                "name": "Sighnaghi",
                "countryCode": "GE"
            },
            {
                "name": "Sighnaghis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Telavi",
                "countryCode": "GE"
            },
            {
                "name": "Tsinandali",
                "countryCode": "GE"
            },
            {
                "name": "Tsnori",
                "countryCode": "GE"
            },
            {
                "name": "Bolnisi",
                "countryCode": "GE"
            },
            {
                "name": "Bolnisis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Didi Lilo",
                "countryCode": "GE"
            },
            {
                "name": "Dmanisis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Gardabani",
                "countryCode": "GE"
            },
            {
                "name": "Gardabnis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Manglisi",
                "countryCode": "GE"
            },
            {
                "name": "Marneuli",
                "countryCode": "GE"
            },
            {
                "name": "Marneulis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Naghvarevi",
                "countryCode": "GE"
            },
            {
                "name": "Rust’avi",
                "countryCode": "GE"
            },
            {
                "name": "Tetrits’q’alos Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Tsalka",
                "countryCode": "GE"
            },
            {
                "name": "Ts’alk’is Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "T’et’ri Tsqaro",
                "countryCode": "GE"
            },
            {
                "name": "Akhalgori",
                "countryCode": "GE"
            },
            {
                "name": "Dzegvi",
                "countryCode": "GE"
            },
            {
                "name": "Gudauri",
                "countryCode": "GE"
            },
            {
                "name": "Java",
                "countryCode": "GE"
            },
            {
                "name": "Mtskheta",
                "countryCode": "GE"
            },
            {
                "name": "P’asanauri",
                "countryCode": "GE"
            },
            {
                "name": "Step’antsminda",
                "countryCode": "GE"
            },
            {
                "name": "Zhinvali",
                "countryCode": "GE"
            },
            {
                "name": "Ambrolauri",
                "countryCode": "GE"
            },
            {
                "name": "Ambrolauris Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Lent’ekhi",
                "countryCode": "GE"
            },
            {
                "name": "Oni",
                "countryCode": "GE"
            },
            {
                "name": "Adigeni",
                "countryCode": "GE"
            },
            {
                "name": "Adigeni Municipality",
                "countryCode": "GE"
            },
            {
                "name": "Akhaldaba",
                "countryCode": "GE"
            },
            {
                "name": "Akhalk’alak’i",
                "countryCode": "GE"
            },
            {
                "name": "Akhaltsikhe",
                "countryCode": "GE"
            },
            {
                "name": "Akhaltsikhis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Aspindza",
                "countryCode": "GE"
            },
            {
                "name": "Asp’indzis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Bakuriani",
                "countryCode": "GE"
            },
            {
                "name": "Borjomi",
                "countryCode": "GE"
            },
            {
                "name": "Ninotsminda",
                "countryCode": "GE"
            },
            {
                "name": "Tsaghveri",
                "countryCode": "GE"
            },
            {
                "name": "Vale",
                "countryCode": "GE"
            },
            {
                "name": "Agara",
                "countryCode": "GE"
            },
            {
                "name": "Gori",
                "countryCode": "GE"
            },
            {
                "name": "Goris Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Kaspi",
                "countryCode": "GE"
            },
            {
                "name": "Khashuri",
                "countryCode": "GE"
            },
            {
                "name": "Surami",
                "countryCode": "GE"
            },
            {
                "name": "Ts’khinvali",
                "countryCode": "GE"
            },
            {
                "name": "Abasha",
                "countryCode": "GE"
            },
            {
                "name": "Jvari",
                "countryCode": "GE"
            },
            {
                "name": "Khobi",
                "countryCode": "GE"
            },
            {
                "name": "Kveda Chkhorots’q’u",
                "countryCode": "GE"
            },
            {
                "name": "Mart’vili",
                "countryCode": "GE"
            },
            {
                "name": "Mest’ia",
                "countryCode": "GE"
            },
            {
                "name": "Mest’iis Munitsip’alit’et’i",
                "countryCode": "GE"
            },
            {
                "name": "Orsant’ia",
                "countryCode": "GE"
            },
            {
                "name": "P’ot’i",
                "countryCode": "GE"
            },
            {
                "name": "Senak’i",
                "countryCode": "GE"
            },
            {
                "name": "Tsalenjikha",
                "countryCode": "GE"
            },
            {
                "name": "Zugdidi",
                "countryCode": "GE"
            },
            {
                "name": "Tbilisi",
                "countryCode": "GE"
            }
        ]
    },
    {
        "name": "Germany",
        "phonecode": "49",
        "cities": [
            {
                "name": "Alt Tucheband",
                "countryCode": "DE"
            },
            {
                "name": "Altdöbern",
                "countryCode": "DE"
            },
            {
                "name": "Altlandsberg",
                "countryCode": "DE"
            },
            {
                "name": "Angermünde",
                "countryCode": "DE"
            },
            {
                "name": "Bad Belzig",
                "countryCode": "DE"
            },
            {
                "name": "Bad Freienwalde",
                "countryCode": "DE"
            },
            {
                "name": "Bad Liebenwerda",
                "countryCode": "DE"
            },
            {
                "name": "Bad Saarow",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wilsnack",
                "countryCode": "DE"
            },
            {
                "name": "Baruth",
                "countryCode": "DE"
            },
            {
                "name": "Beelitz",
                "countryCode": "DE"
            },
            {
                "name": "Beeskow",
                "countryCode": "DE"
            },
            {
                "name": "Bensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Berkenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Bernau bei Berlin",
                "countryCode": "DE"
            },
            {
                "name": "Bestensee",
                "countryCode": "DE"
            },
            {
                "name": "Biesenthal",
                "countryCode": "DE"
            },
            {
                "name": "Birkenwerder",
                "countryCode": "DE"
            },
            {
                "name": "Bliesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Borkheide",
                "countryCode": "DE"
            },
            {
                "name": "Borkwalde",
                "countryCode": "DE"
            },
            {
                "name": "Brandenburg an der Havel",
                "countryCode": "DE"
            },
            {
                "name": "Breddin",
                "countryCode": "DE"
            },
            {
                "name": "Breese",
                "countryCode": "DE"
            },
            {
                "name": "Brieselang",
                "countryCode": "DE"
            },
            {
                "name": "Briesen",
                "countryCode": "DE"
            },
            {
                "name": "Brieskow-Finkenheerd",
                "countryCode": "DE"
            },
            {
                "name": "Britz",
                "countryCode": "DE"
            },
            {
                "name": "Brück",
                "countryCode": "DE"
            },
            {
                "name": "Brüssow",
                "countryCode": "DE"
            },
            {
                "name": "Buckow",
                "countryCode": "DE"
            },
            {
                "name": "Burg",
                "countryCode": "DE"
            },
            {
                "name": "Calau",
                "countryCode": "DE"
            },
            {
                "name": "Casekow",
                "countryCode": "DE"
            },
            {
                "name": "Chorin",
                "countryCode": "DE"
            },
            {
                "name": "Cottbus",
                "countryCode": "DE"
            },
            {
                "name": "Crinitz",
                "countryCode": "DE"
            },
            {
                "name": "Dahme",
                "countryCode": "DE"
            },
            {
                "name": "Dallgow-Döberitz",
                "countryCode": "DE"
            },
            {
                "name": "Doberlug-Kirchhain",
                "countryCode": "DE"
            },
            {
                "name": "Drebkau",
                "countryCode": "DE"
            },
            {
                "name": "Dreetz",
                "countryCode": "DE"
            },
            {
                "name": "Eberswalde",
                "countryCode": "DE"
            },
            {
                "name": "Eichwalde",
                "countryCode": "DE"
            },
            {
                "name": "Eisenhüttenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Elsterwerda",
                "countryCode": "DE"
            },
            {
                "name": "Erkner",
                "countryCode": "DE"
            },
            {
                "name": "Falkenberg",
                "countryCode": "DE"
            },
            {
                "name": "Falkensee",
                "countryCode": "DE"
            },
            {
                "name": "Fehrbellin",
                "countryCode": "DE"
            },
            {
                "name": "Fichtenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Finsterwalde",
                "countryCode": "DE"
            },
            {
                "name": "Forst",
                "countryCode": "DE"
            },
            {
                "name": "Frankfurt (Oder)",
                "countryCode": "DE"
            },
            {
                "name": "Friedland",
                "countryCode": "DE"
            },
            {
                "name": "Friesack",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenberg",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Gartz",
                "countryCode": "DE"
            },
            {
                "name": "Gerswalde",
                "countryCode": "DE"
            },
            {
                "name": "Glienicke",
                "countryCode": "DE"
            },
            {
                "name": "Golzow",
                "countryCode": "DE"
            },
            {
                "name": "Golßen",
                "countryCode": "DE"
            },
            {
                "name": "Gramzow",
                "countryCode": "DE"
            },
            {
                "name": "Gransee",
                "countryCode": "DE"
            },
            {
                "name": "Groß Kreutz",
                "countryCode": "DE"
            },
            {
                "name": "Groß Köris",
                "countryCode": "DE"
            },
            {
                "name": "Groß Lindow",
                "countryCode": "DE"
            },
            {
                "name": "Groß Pankow",
                "countryCode": "DE"
            },
            {
                "name": "Großbeeren",
                "countryCode": "DE"
            },
            {
                "name": "Großkmehlen",
                "countryCode": "DE"
            },
            {
                "name": "Großräschen",
                "countryCode": "DE"
            },
            {
                "name": "Großthiemig",
                "countryCode": "DE"
            },
            {
                "name": "Großwoltersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Gröden",
                "countryCode": "DE"
            },
            {
                "name": "Grünheide",
                "countryCode": "DE"
            },
            {
                "name": "Guben",
                "countryCode": "DE"
            },
            {
                "name": "Gumtow",
                "countryCode": "DE"
            },
            {
                "name": "Görzke",
                "countryCode": "DE"
            },
            {
                "name": "Halbe",
                "countryCode": "DE"
            },
            {
                "name": "Heiligengrabe",
                "countryCode": "DE"
            },
            {
                "name": "Hennigsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herzberg",
                "countryCode": "DE"
            },
            {
                "name": "Hirschfeld",
                "countryCode": "DE"
            },
            {
                "name": "Hohen Neuendorf",
                "countryCode": "DE"
            },
            {
                "name": "Hohenbocka",
                "countryCode": "DE"
            },
            {
                "name": "Hohenleipisch",
                "countryCode": "DE"
            },
            {
                "name": "Jacobsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Joachimsthal",
                "countryCode": "DE"
            },
            {
                "name": "Jänschwalde",
                "countryCode": "DE"
            },
            {
                "name": "Jüterbog",
                "countryCode": "DE"
            },
            {
                "name": "Karstädt",
                "countryCode": "DE"
            },
            {
                "name": "Ketzin",
                "countryCode": "DE"
            },
            {
                "name": "Kleinmachnow",
                "countryCode": "DE"
            },
            {
                "name": "Kloster Lehnin",
                "countryCode": "DE"
            },
            {
                "name": "Kolkwitz",
                "countryCode": "DE"
            },
            {
                "name": "Kremmen",
                "countryCode": "DE"
            },
            {
                "name": "Kyritz",
                "countryCode": "DE"
            },
            {
                "name": "Königs Wusterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Lauchhammer",
                "countryCode": "DE"
            },
            {
                "name": "Lebus",
                "countryCode": "DE"
            },
            {
                "name": "Leegebruch",
                "countryCode": "DE"
            },
            {
                "name": "Lenzen",
                "countryCode": "DE"
            },
            {
                "name": "Letschin",
                "countryCode": "DE"
            },
            {
                "name": "Liebenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Lieberose",
                "countryCode": "DE"
            },
            {
                "name": "Lindow",
                "countryCode": "DE"
            },
            {
                "name": "Luckau",
                "countryCode": "DE"
            },
            {
                "name": "Luckenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Ludwigsfelde",
                "countryCode": "DE"
            },
            {
                "name": "Lychen",
                "countryCode": "DE"
            },
            {
                "name": "Lübben",
                "countryCode": "DE"
            },
            {
                "name": "Lübbenau",
                "countryCode": "DE"
            },
            {
                "name": "Manschnow",
                "countryCode": "DE"
            },
            {
                "name": "Marienwerder",
                "countryCode": "DE"
            },
            {
                "name": "Melchow",
                "countryCode": "DE"
            },
            {
                "name": "Meyenburg",
                "countryCode": "DE"
            },
            {
                "name": "Michendorf",
                "countryCode": "DE"
            },
            {
                "name": "Milmersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mittenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Mixdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mühlberg",
                "countryCode": "DE"
            },
            {
                "name": "Müllrose",
                "countryCode": "DE"
            },
            {
                "name": "Müncheberg",
                "countryCode": "DE"
            },
            {
                "name": "Nauen",
                "countryCode": "DE"
            },
            {
                "name": "Nennhausen",
                "countryCode": "DE"
            },
            {
                "name": "Neu Zauche",
                "countryCode": "DE"
            },
            {
                "name": "Neuenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Neuhardenberg",
                "countryCode": "DE"
            },
            {
                "name": "Neulewin",
                "countryCode": "DE"
            },
            {
                "name": "Neuruppin",
                "countryCode": "DE"
            },
            {
                "name": "Neutrebbin",
                "countryCode": "DE"
            },
            {
                "name": "Neuzelle",
                "countryCode": "DE"
            },
            {
                "name": "Niedergörsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Niemegk",
                "countryCode": "DE"
            },
            {
                "name": "Oderberg",
                "countryCode": "DE"
            },
            {
                "name": "Oranienburg",
                "countryCode": "DE"
            },
            {
                "name": "Ortrand",
                "countryCode": "DE"
            },
            {
                "name": "Paulinenaue",
                "countryCode": "DE"
            },
            {
                "name": "Peitz",
                "countryCode": "DE"
            },
            {
                "name": "Perleberg",
                "countryCode": "DE"
            },
            {
                "name": "Petershagen",
                "countryCode": "DE"
            },
            {
                "name": "Plattenburg",
                "countryCode": "DE"
            },
            {
                "name": "Plessa",
                "countryCode": "DE"
            },
            {
                "name": "Podelzig",
                "countryCode": "DE"
            },
            {
                "name": "Potsdam",
                "countryCode": "DE"
            },
            {
                "name": "Premnitz",
                "countryCode": "DE"
            },
            {
                "name": "Prenzlau",
                "countryCode": "DE"
            },
            {
                "name": "Pritzwalk",
                "countryCode": "DE"
            },
            {
                "name": "Prötzel",
                "countryCode": "DE"
            },
            {
                "name": "Putlitz",
                "countryCode": "DE"
            },
            {
                "name": "Rangsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rathenow",
                "countryCode": "DE"
            },
            {
                "name": "Rauen",
                "countryCode": "DE"
            },
            {
                "name": "Rehfelde",
                "countryCode": "DE"
            },
            {
                "name": "Reichenwalde",
                "countryCode": "DE"
            },
            {
                "name": "Rheinsberg",
                "countryCode": "DE"
            },
            {
                "name": "Rhinow",
                "countryCode": "DE"
            },
            {
                "name": "Rietz Neuendorf",
                "countryCode": "DE"
            },
            {
                "name": "Roskow",
                "countryCode": "DE"
            },
            {
                "name": "Ruhland",
                "countryCode": "DE"
            },
            {
                "name": "Rückersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rüdnitz",
                "countryCode": "DE"
            },
            {
                "name": "Sallgast",
                "countryCode": "DE"
            },
            {
                "name": "Schenkendöbern",
                "countryCode": "DE"
            },
            {
                "name": "Schipkau",
                "countryCode": "DE"
            },
            {
                "name": "Schlieben",
                "countryCode": "DE"
            },
            {
                "name": "Schulzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzheide",
                "countryCode": "DE"
            },
            {
                "name": "Schwedt (Oder)",
                "countryCode": "DE"
            },
            {
                "name": "Schönborn",
                "countryCode": "DE"
            },
            {
                "name": "Schönefeld",
                "countryCode": "DE"
            },
            {
                "name": "Schöneiche",
                "countryCode": "DE"
            },
            {
                "name": "Schönewalde",
                "countryCode": "DE"
            },
            {
                "name": "Seelow",
                "countryCode": "DE"
            },
            {
                "name": "Senftenberg",
                "countryCode": "DE"
            },
            {
                "name": "Sonnewalde",
                "countryCode": "DE"
            },
            {
                "name": "Sperenberg",
                "countryCode": "DE"
            },
            {
                "name": "Spreenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Stahnsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Steinhöfel",
                "countryCode": "DE"
            },
            {
                "name": "Storkow",
                "countryCode": "DE"
            },
            {
                "name": "Straupitz",
                "countryCode": "DE"
            },
            {
                "name": "Strausberg",
                "countryCode": "DE"
            },
            {
                "name": "Tauche",
                "countryCode": "DE"
            },
            {
                "name": "Teltow",
                "countryCode": "DE"
            },
            {
                "name": "Templin",
                "countryCode": "DE"
            },
            {
                "name": "Teupitz",
                "countryCode": "DE"
            },
            {
                "name": "Trebbin",
                "countryCode": "DE"
            },
            {
                "name": "Treuenbrietzen",
                "countryCode": "DE"
            },
            {
                "name": "Tschernitz",
                "countryCode": "DE"
            },
            {
                "name": "Uebigau",
                "countryCode": "DE"
            },
            {
                "name": "Velten",
                "countryCode": "DE"
            },
            {
                "name": "Vetschau",
                "countryCode": "DE"
            },
            {
                "name": "Waldsieversdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wandlitz",
                "countryCode": "DE"
            },
            {
                "name": "Weisen",
                "countryCode": "DE"
            },
            {
                "name": "Welzow",
                "countryCode": "DE"
            },
            {
                "name": "Wendisch Rietz",
                "countryCode": "DE"
            },
            {
                "name": "Werben",
                "countryCode": "DE"
            },
            {
                "name": "Werder",
                "countryCode": "DE"
            },
            {
                "name": "Werftpfuhl",
                "countryCode": "DE"
            },
            {
                "name": "Werneuchen",
                "countryCode": "DE"
            },
            {
                "name": "Wiesenau",
                "countryCode": "DE"
            },
            {
                "name": "Wiesenburg",
                "countryCode": "DE"
            },
            {
                "name": "Wittenberge",
                "countryCode": "DE"
            },
            {
                "name": "Wittstock",
                "countryCode": "DE"
            },
            {
                "name": "Woltersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wriezen",
                "countryCode": "DE"
            },
            {
                "name": "Wusterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wustermark",
                "countryCode": "DE"
            },
            {
                "name": "Wusterwitz",
                "countryCode": "DE"
            },
            {
                "name": "Zehdenick",
                "countryCode": "DE"
            },
            {
                "name": "Zeuthen",
                "countryCode": "DE"
            },
            {
                "name": "Ziesar",
                "countryCode": "DE"
            },
            {
                "name": "Ziltendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zossen",
                "countryCode": "DE"
            },
            {
                "name": "Adlershof",
                "countryCode": "DE"
            },
            {
                "name": "Alt-Hohenschönhausen",
                "countryCode": "DE"
            },
            {
                "name": "Alt-Treptow",
                "countryCode": "DE"
            },
            {
                "name": "Altglienicke",
                "countryCode": "DE"
            },
            {
                "name": "Baumschulenweg",
                "countryCode": "DE"
            },
            {
                "name": "Berlin",
                "countryCode": "DE"
            },
            {
                "name": "Berlin Köpenick",
                "countryCode": "DE"
            },
            {
                "name": "Berlin Treptow",
                "countryCode": "DE"
            },
            {
                "name": "Biesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Blankenburg",
                "countryCode": "DE"
            },
            {
                "name": "Blankenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Bohnsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Britz",
                "countryCode": "DE"
            },
            {
                "name": "Buch",
                "countryCode": "DE"
            },
            {
                "name": "Buckow",
                "countryCode": "DE"
            },
            {
                "name": "Charlottenburg",
                "countryCode": "DE"
            },
            {
                "name": "Charlottenburg-Nord",
                "countryCode": "DE"
            },
            {
                "name": "Dahlem",
                "countryCode": "DE"
            },
            {
                "name": "Falkenberg",
                "countryCode": "DE"
            },
            {
                "name": "Falkenhagener Feld",
                "countryCode": "DE"
            },
            {
                "name": "Fennpfuhl",
                "countryCode": "DE"
            },
            {
                "name": "Französisch Buchholz",
                "countryCode": "DE"
            },
            {
                "name": "Friedenau",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichsfelde",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichshagen",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichshain",
                "countryCode": "DE"
            },
            {
                "name": "Frohnau",
                "countryCode": "DE"
            },
            {
                "name": "Gatow",
                "countryCode": "DE"
            },
            {
                "name": "Gesundbrunnen",
                "countryCode": "DE"
            },
            {
                "name": "Gropiusstadt",
                "countryCode": "DE"
            },
            {
                "name": "Grunewald",
                "countryCode": "DE"
            },
            {
                "name": "Grünau",
                "countryCode": "DE"
            },
            {
                "name": "Hakenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Halensee",
                "countryCode": "DE"
            },
            {
                "name": "Hansaviertel",
                "countryCode": "DE"
            },
            {
                "name": "Haselhorst",
                "countryCode": "DE"
            },
            {
                "name": "Heiligensee",
                "countryCode": "DE"
            },
            {
                "name": "Heinersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hellersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Johannisthal",
                "countryCode": "DE"
            },
            {
                "name": "Karlshorst",
                "countryCode": "DE"
            },
            {
                "name": "Karow",
                "countryCode": "DE"
            },
            {
                "name": "Kaulsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kladow",
                "countryCode": "DE"
            },
            {
                "name": "Konradshöhe",
                "countryCode": "DE"
            },
            {
                "name": "Kreuzberg",
                "countryCode": "DE"
            },
            {
                "name": "Köpenick",
                "countryCode": "DE"
            },
            {
                "name": "Lankwitz",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenrade",
                "countryCode": "DE"
            },
            {
                "name": "Lichterfelde",
                "countryCode": "DE"
            },
            {
                "name": "Lübars",
                "countryCode": "DE"
            },
            {
                "name": "Mahlsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mariendorf",
                "countryCode": "DE"
            },
            {
                "name": "Marienfelde",
                "countryCode": "DE"
            },
            {
                "name": "Marzahn",
                "countryCode": "DE"
            },
            {
                "name": "Mitte",
                "countryCode": "DE"
            },
            {
                "name": "Moabit",
                "countryCode": "DE"
            },
            {
                "name": "Märkisches Viertel",
                "countryCode": "DE"
            },
            {
                "name": "Müggelheim",
                "countryCode": "DE"
            },
            {
                "name": "Neu-Hohenschönhausen",
                "countryCode": "DE"
            },
            {
                "name": "Neukölln",
                "countryCode": "DE"
            },
            {
                "name": "Niederschöneweide",
                "countryCode": "DE"
            },
            {
                "name": "Niederschönhausen",
                "countryCode": "DE"
            },
            {
                "name": "Nikolassee",
                "countryCode": "DE"
            },
            {
                "name": "Oberschöneweide",
                "countryCode": "DE"
            },
            {
                "name": "Pankow",
                "countryCode": "DE"
            },
            {
                "name": "Plänterwald",
                "countryCode": "DE"
            },
            {
                "name": "Prenzlauer Berg",
                "countryCode": "DE"
            },
            {
                "name": "Rahnsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Reinickendorf",
                "countryCode": "DE"
            },
            {
                "name": "Rosenthal",
                "countryCode": "DE"
            },
            {
                "name": "Rudow",
                "countryCode": "DE"
            },
            {
                "name": "Rummelsburg",
                "countryCode": "DE"
            },
            {
                "name": "Schmargendorf",
                "countryCode": "DE"
            },
            {
                "name": "Schmöckwitz",
                "countryCode": "DE"
            },
            {
                "name": "Schöneberg",
                "countryCode": "DE"
            },
            {
                "name": "Siemensstadt",
                "countryCode": "DE"
            },
            {
                "name": "Spandau",
                "countryCode": "DE"
            },
            {
                "name": "Staaken",
                "countryCode": "DE"
            },
            {
                "name": "Stadtrandsiedlung Malchow",
                "countryCode": "DE"
            },
            {
                "name": "Steglitz",
                "countryCode": "DE"
            },
            {
                "name": "Tegel",
                "countryCode": "DE"
            },
            {
                "name": "Tempelhof",
                "countryCode": "DE"
            },
            {
                "name": "Tiergarten",
                "countryCode": "DE"
            },
            {
                "name": "Waidmannslust",
                "countryCode": "DE"
            },
            {
                "name": "Wannsee",
                "countryCode": "DE"
            },
            {
                "name": "Wartenberg",
                "countryCode": "DE"
            },
            {
                "name": "Wedding",
                "countryCode": "DE"
            },
            {
                "name": "Weißensee",
                "countryCode": "DE"
            },
            {
                "name": "Westend",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmsruh",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wilmersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wittenau",
                "countryCode": "DE"
            },
            {
                "name": "Zehlendorf",
                "countryCode": "DE"
            },
            {
                "name": "Aach",
                "countryCode": "DE"
            },
            {
                "name": "Aalen",
                "countryCode": "DE"
            },
            {
                "name": "Abstatt",
                "countryCode": "DE"
            },
            {
                "name": "Abtsgmünd",
                "countryCode": "DE"
            },
            {
                "name": "Achern",
                "countryCode": "DE"
            },
            {
                "name": "Achstetten",
                "countryCode": "DE"
            },
            {
                "name": "Adelberg",
                "countryCode": "DE"
            },
            {
                "name": "Adelmannsfelden",
                "countryCode": "DE"
            },
            {
                "name": "Adelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Affalterbach",
                "countryCode": "DE"
            },
            {
                "name": "Aglasterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Aichelberg",
                "countryCode": "DE"
            },
            {
                "name": "Aichhalden",
                "countryCode": "DE"
            },
            {
                "name": "Aichstetten",
                "countryCode": "DE"
            },
            {
                "name": "Aidlingen",
                "countryCode": "DE"
            },
            {
                "name": "Aitrach",
                "countryCode": "DE"
            },
            {
                "name": "Albbruck",
                "countryCode": "DE"
            },
            {
                "name": "Albershausen",
                "countryCode": "DE"
            },
            {
                "name": "Albstadt",
                "countryCode": "DE"
            },
            {
                "name": "Aldingen",
                "countryCode": "DE"
            },
            {
                "name": "Alfdorf",
                "countryCode": "DE"
            },
            {
                "name": "Allensbach",
                "countryCode": "DE"
            },
            {
                "name": "Allmendingen",
                "countryCode": "DE"
            },
            {
                "name": "Allmersbach im Tal",
                "countryCode": "DE"
            },
            {
                "name": "Alpirsbach",
                "countryCode": "DE"
            },
            {
                "name": "Altbach",
                "countryCode": "DE"
            },
            {
                "name": "Altdorf",
                "countryCode": "DE"
            },
            {
                "name": "Altenriet",
                "countryCode": "DE"
            },
            {
                "name": "Altensteig",
                "countryCode": "DE"
            },
            {
                "name": "Altes Lager",
                "countryCode": "DE"
            },
            {
                "name": "Althengstett",
                "countryCode": "DE"
            },
            {
                "name": "Althütte",
                "countryCode": "DE"
            },
            {
                "name": "Altlußheim",
                "countryCode": "DE"
            },
            {
                "name": "Altshausen",
                "countryCode": "DE"
            },
            {
                "name": "Amstetten",
                "countryCode": "DE"
            },
            {
                "name": "Amtzell",
                "countryCode": "DE"
            },
            {
                "name": "Aspach",
                "countryCode": "DE"
            },
            {
                "name": "Asperg",
                "countryCode": "DE"
            },
            {
                "name": "Assamstadt",
                "countryCode": "DE"
            },
            {
                "name": "Asselfingen",
                "countryCode": "DE"
            },
            {
                "name": "Attenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Au",
                "countryCode": "DE"
            },
            {
                "name": "Au am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Auggen",
                "countryCode": "DE"
            },
            {
                "name": "Aulendorf",
                "countryCode": "DE"
            },
            {
                "name": "Backnang",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bellingen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Buchau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Ditzenbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Dürrheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Herrenalb",
                "countryCode": "DE"
            },
            {
                "name": "Bad Liebenzell",
                "countryCode": "DE"
            },
            {
                "name": "Bad Mergentheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Peterstal-Griesbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Rappenau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Rippoldsau-Schapbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schussenried",
                "countryCode": "DE"
            },
            {
                "name": "Bad Säckingen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Teinach-Zavelstein",
                "countryCode": "DE"
            },
            {
                "name": "Bad Urach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Waldsee",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wildbad",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wimpfen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wurzach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Überkingen",
                "countryCode": "DE"
            },
            {
                "name": "Baden-Baden",
                "countryCode": "DE"
            },
            {
                "name": "Badenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Bahlingen",
                "countryCode": "DE"
            },
            {
                "name": "Baienfurt",
                "countryCode": "DE"
            },
            {
                "name": "Baiersbronn",
                "countryCode": "DE"
            },
            {
                "name": "Baindt",
                "countryCode": "DE"
            },
            {
                "name": "Balingen",
                "countryCode": "DE"
            },
            {
                "name": "Baltmannsweiler",
                "countryCode": "DE"
            },
            {
                "name": "Balzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bammental",
                "countryCode": "DE"
            },
            {
                "name": "Bartholomä",
                "countryCode": "DE"
            },
            {
                "name": "Beilstein",
                "countryCode": "DE"
            },
            {
                "name": "Beimerstetten",
                "countryCode": "DE"
            },
            {
                "name": "Bempflingen",
                "countryCode": "DE"
            },
            {
                "name": "Benningen am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Bergatreute",
                "countryCode": "DE"
            },
            {
                "name": "Berghaupten",
                "countryCode": "DE"
            },
            {
                "name": "Berghülen",
                "countryCode": "DE"
            },
            {
                "name": "Berkheim",
                "countryCode": "DE"
            },
            {
                "name": "Bermatingen",
                "countryCode": "DE"
            },
            {
                "name": "Bernau",
                "countryCode": "DE"
            },
            {
                "name": "Bernstadt",
                "countryCode": "DE"
            },
            {
                "name": "Besigheim",
                "countryCode": "DE"
            },
            {
                "name": "Beuren",
                "countryCode": "DE"
            },
            {
                "name": "Biberach",
                "countryCode": "DE"
            },
            {
                "name": "Biberach an der Riß",
                "countryCode": "DE"
            },
            {
                "name": "Bietigheim",
                "countryCode": "DE"
            },
            {
                "name": "Bietigheim-Bissingen",
                "countryCode": "DE"
            },
            {
                "name": "Billigheim",
                "countryCode": "DE"
            },
            {
                "name": "Binau",
                "countryCode": "DE"
            },
            {
                "name": "Bingen",
                "countryCode": "DE"
            },
            {
                "name": "Binzen",
                "countryCode": "DE"
            },
            {
                "name": "Birenbach",
                "countryCode": "DE"
            },
            {
                "name": "Birkenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bischweier",
                "countryCode": "DE"
            },
            {
                "name": "Bisingen",
                "countryCode": "DE"
            },
            {
                "name": "Bissingen an der Teck",
                "countryCode": "DE"
            },
            {
                "name": "Bitz",
                "countryCode": "DE"
            },
            {
                "name": "Blaubeuren",
                "countryCode": "DE"
            },
            {
                "name": "Blaufelden",
                "countryCode": "DE"
            },
            {
                "name": "Blumberg",
                "countryCode": "DE"
            },
            {
                "name": "Bodelshausen",
                "countryCode": "DE"
            },
            {
                "name": "Bodman-Ludwigshafen",
                "countryCode": "DE"
            },
            {
                "name": "Bodnegg",
                "countryCode": "DE"
            },
            {
                "name": "Bolheim",
                "countryCode": "DE"
            },
            {
                "name": "Boll",
                "countryCode": "DE"
            },
            {
                "name": "Bollschweil",
                "countryCode": "DE"
            },
            {
                "name": "Bondorf",
                "countryCode": "DE"
            },
            {
                "name": "Bonndorf",
                "countryCode": "DE"
            },
            {
                "name": "Bopfingen",
                "countryCode": "DE"
            },
            {
                "name": "Boxberg",
                "countryCode": "DE"
            },
            {
                "name": "Brackenheim",
                "countryCode": "DE"
            },
            {
                "name": "Braunsbach",
                "countryCode": "DE"
            },
            {
                "name": "Breisach am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Breitnau",
                "countryCode": "DE"
            },
            {
                "name": "Bretten",
                "countryCode": "DE"
            },
            {
                "name": "Bretzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bruchsal",
                "countryCode": "DE"
            },
            {
                "name": "Bräunlingen",
                "countryCode": "DE"
            },
            {
                "name": "Brühl",
                "countryCode": "DE"
            },
            {
                "name": "Bubsheim",
                "countryCode": "DE"
            },
            {
                "name": "Buch am Ahorn",
                "countryCode": "DE"
            },
            {
                "name": "Buchen in Odenwald",
                "countryCode": "DE"
            },
            {
                "name": "Buchenbach",
                "countryCode": "DE"
            },
            {
                "name": "Buggingen",
                "countryCode": "DE"
            },
            {
                "name": "Burgrieden",
                "countryCode": "DE"
            },
            {
                "name": "Burgstetten",
                "countryCode": "DE"
            },
            {
                "name": "Burladingen",
                "countryCode": "DE"
            },
            {
                "name": "Böbingen an der Rems",
                "countryCode": "DE"
            },
            {
                "name": "Böblingen",
                "countryCode": "DE"
            },
            {
                "name": "Böhmenkirch",
                "countryCode": "DE"
            },
            {
                "name": "Bönnigheim",
                "countryCode": "DE"
            },
            {
                "name": "Börtlingen",
                "countryCode": "DE"
            },
            {
                "name": "Bösingen",
                "countryCode": "DE"
            },
            {
                "name": "Böttingen",
                "countryCode": "DE"
            },
            {
                "name": "Bötzingen",
                "countryCode": "DE"
            },
            {
                "name": "Bühl",
                "countryCode": "DE"
            },
            {
                "name": "Bühlertal",
                "countryCode": "DE"
            },
            {
                "name": "Bühlertann",
                "countryCode": "DE"
            },
            {
                "name": "Bühlerzell",
                "countryCode": "DE"
            },
            {
                "name": "Büsingen",
                "countryCode": "DE"
            },
            {
                "name": "Calw",
                "countryCode": "DE"
            },
            {
                "name": "Cleebronn",
                "countryCode": "DE"
            },
            {
                "name": "Crailsheim",
                "countryCode": "DE"
            },
            {
                "name": "Creglingen",
                "countryCode": "DE"
            },
            {
                "name": "Daisendorf",
                "countryCode": "DE"
            },
            {
                "name": "Dauchingen",
                "countryCode": "DE"
            },
            {
                "name": "Deckenpfronn",
                "countryCode": "DE"
            },
            {
                "name": "Deggingen",
                "countryCode": "DE"
            },
            {
                "name": "Deilingen",
                "countryCode": "DE"
            },
            {
                "name": "Deizisau",
                "countryCode": "DE"
            },
            {
                "name": "Deißlingen",
                "countryCode": "DE"
            },
            {
                "name": "Denkendorf",
                "countryCode": "DE"
            },
            {
                "name": "Denkingen",
                "countryCode": "DE"
            },
            {
                "name": "Denzlingen",
                "countryCode": "DE"
            },
            {
                "name": "Dettenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Dettighofen",
                "countryCode": "DE"
            },
            {
                "name": "Dettingen",
                "countryCode": "DE"
            },
            {
                "name": "Dettingen an der Erms",
                "countryCode": "DE"
            },
            {
                "name": "Dettingen unter Teck",
                "countryCode": "DE"
            },
            {
                "name": "Dielheim",
                "countryCode": "DE"
            },
            {
                "name": "Dietenheim",
                "countryCode": "DE"
            },
            {
                "name": "Dietingen",
                "countryCode": "DE"
            },
            {
                "name": "Dischingen",
                "countryCode": "DE"
            },
            {
                "name": "Ditzingen",
                "countryCode": "DE"
            },
            {
                "name": "Dobel",
                "countryCode": "DE"
            },
            {
                "name": "Dogern",
                "countryCode": "DE"
            },
            {
                "name": "Donaueschingen",
                "countryCode": "DE"
            },
            {
                "name": "Donzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dormettingen",
                "countryCode": "DE"
            },
            {
                "name": "Dornhan",
                "countryCode": "DE"
            },
            {
                "name": "Dornstadt",
                "countryCode": "DE"
            },
            {
                "name": "Dornstetten",
                "countryCode": "DE"
            },
            {
                "name": "Dossenheim",
                "countryCode": "DE"
            },
            {
                "name": "Dotternhausen",
                "countryCode": "DE"
            },
            {
                "name": "Dunningen",
                "countryCode": "DE"
            },
            {
                "name": "Durbach",
                "countryCode": "DE"
            },
            {
                "name": "Durlangen",
                "countryCode": "DE"
            },
            {
                "name": "Durmersheim",
                "countryCode": "DE"
            },
            {
                "name": "Dußlingen",
                "countryCode": "DE"
            },
            {
                "name": "Dörzbach",
                "countryCode": "DE"
            },
            {
                "name": "Dürbheim",
                "countryCode": "DE"
            },
            {
                "name": "Dürmentingen",
                "countryCode": "DE"
            },
            {
                "name": "Dürnau",
                "countryCode": "DE"
            },
            {
                "name": "Ebenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Eberbach",
                "countryCode": "DE"
            },
            {
                "name": "Eberdingen",
                "countryCode": "DE"
            },
            {
                "name": "Eberhardzell",
                "countryCode": "DE"
            },
            {
                "name": "Ebersbach an der Fils",
                "countryCode": "DE"
            },
            {
                "name": "Ebersbach-Musbach",
                "countryCode": "DE"
            },
            {
                "name": "Eberstadt",
                "countryCode": "DE"
            },
            {
                "name": "Ebhausen",
                "countryCode": "DE"
            },
            {
                "name": "Ebringen",
                "countryCode": "DE"
            },
            {
                "name": "Edingen-Neckarhausen",
                "countryCode": "DE"
            },
            {
                "name": "Efringen-Kirchen",
                "countryCode": "DE"
            },
            {
                "name": "Egenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Eggenstein-Leopoldshafen",
                "countryCode": "DE"
            },
            {
                "name": "Eggingen",
                "countryCode": "DE"
            },
            {
                "name": "Ehingen",
                "countryCode": "DE"
            },
            {
                "name": "Ehningen",
                "countryCode": "DE"
            },
            {
                "name": "Eichstetten",
                "countryCode": "DE"
            },
            {
                "name": "Eigeltingen",
                "countryCode": "DE"
            },
            {
                "name": "Eimeldingen",
                "countryCode": "DE"
            },
            {
                "name": "Eisenbach",
                "countryCode": "DE"
            },
            {
                "name": "Eisingen",
                "countryCode": "DE"
            },
            {
                "name": "Eislingen",
                "countryCode": "DE"
            },
            {
                "name": "Ellenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ellhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ellwangen",
                "countryCode": "DE"
            },
            {
                "name": "Elzach",
                "countryCode": "DE"
            },
            {
                "name": "Emmendingen",
                "countryCode": "DE"
            },
            {
                "name": "Emmingen-Liptingen",
                "countryCode": "DE"
            },
            {
                "name": "Empfingen",
                "countryCode": "DE"
            },
            {
                "name": "Endingen",
                "countryCode": "DE"
            },
            {
                "name": "Engelsbrand",
                "countryCode": "DE"
            },
            {
                "name": "Engen",
                "countryCode": "DE"
            },
            {
                "name": "Eningen unter Achalm",
                "countryCode": "DE"
            },
            {
                "name": "Ennetach",
                "countryCode": "DE"
            },
            {
                "name": "Enzklösterle",
                "countryCode": "DE"
            },
            {
                "name": "Epfenbach",
                "countryCode": "DE"
            },
            {
                "name": "Epfendorf",
                "countryCode": "DE"
            },
            {
                "name": "Eppelheim",
                "countryCode": "DE"
            },
            {
                "name": "Eppingen",
                "countryCode": "DE"
            },
            {
                "name": "Erbach",
                "countryCode": "DE"
            },
            {
                "name": "Erdmannhausen",
                "countryCode": "DE"
            },
            {
                "name": "Eriskirch",
                "countryCode": "DE"
            },
            {
                "name": "Erkenbrechtsweiler",
                "countryCode": "DE"
            },
            {
                "name": "Erlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Erlenmoos",
                "countryCode": "DE"
            },
            {
                "name": "Erligheim",
                "countryCode": "DE"
            },
            {
                "name": "Erolzheim",
                "countryCode": "DE"
            },
            {
                "name": "Ersingen",
                "countryCode": "DE"
            },
            {
                "name": "Ertingen",
                "countryCode": "DE"
            },
            {
                "name": "Eschach",
                "countryCode": "DE"
            },
            {
                "name": "Eschelbronn",
                "countryCode": "DE"
            },
            {
                "name": "Eschenbach",
                "countryCode": "DE"
            },
            {
                "name": "Essingen",
                "countryCode": "DE"
            },
            {
                "name": "Esslingen",
                "countryCode": "DE"
            },
            {
                "name": "Ettenheim",
                "countryCode": "DE"
            },
            {
                "name": "Ettlingen",
                "countryCode": "DE"
            },
            {
                "name": "Eutingen an der Enz",
                "countryCode": "DE"
            },
            {
                "name": "Fahrenbach",
                "countryCode": "DE"
            },
            {
                "name": "Feldberg",
                "countryCode": "DE"
            },
            {
                "name": "Fellbach",
                "countryCode": "DE"
            },
            {
                "name": "Fichtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Filderstadt",
                "countryCode": "DE"
            },
            {
                "name": "Fischerbach",
                "countryCode": "DE"
            },
            {
                "name": "Flein",
                "countryCode": "DE"
            },
            {
                "name": "Forbach",
                "countryCode": "DE"
            },
            {
                "name": "Forchheim",
                "countryCode": "DE"
            },
            {
                "name": "Forchtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Forst",
                "countryCode": "DE"
            },
            {
                "name": "Freiberg am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Freiburg",
                "countryCode": "DE"
            },
            {
                "name": "Freiburg Region",
                "countryCode": "DE"
            },
            {
                "name": "Freudenberg",
                "countryCode": "DE"
            },
            {
                "name": "Freudenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Freudental",
                "countryCode": "DE"
            },
            {
                "name": "Frickenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Frickingen",
                "countryCode": "DE"
            },
            {
                "name": "Fridingen an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Friedenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichshafen",
                "countryCode": "DE"
            },
            {
                "name": "Friolzheim",
                "countryCode": "DE"
            },
            {
                "name": "Frittlingen",
                "countryCode": "DE"
            },
            {
                "name": "Fronreute",
                "countryCode": "DE"
            },
            {
                "name": "Furtwangen",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenberg",
                "countryCode": "DE"
            },
            {
                "name": "Gaggenau",
                "countryCode": "DE"
            },
            {
                "name": "Gaiberg",
                "countryCode": "DE"
            },
            {
                "name": "Gaienhofen",
                "countryCode": "DE"
            },
            {
                "name": "Gaildorf",
                "countryCode": "DE"
            },
            {
                "name": "Gailingen",
                "countryCode": "DE"
            },
            {
                "name": "Gammelshausen",
                "countryCode": "DE"
            },
            {
                "name": "Gammertingen",
                "countryCode": "DE"
            },
            {
                "name": "Gechingen",
                "countryCode": "DE"
            },
            {
                "name": "Geisingen",
                "countryCode": "DE"
            },
            {
                "name": "Geislingen",
                "countryCode": "DE"
            },
            {
                "name": "Geislingen an der Steige",
                "countryCode": "DE"
            },
            {
                "name": "Gemmingen",
                "countryCode": "DE"
            },
            {
                "name": "Gemmrigheim",
                "countryCode": "DE"
            },
            {
                "name": "Gengenbach",
                "countryCode": "DE"
            },
            {
                "name": "Gerabronn",
                "countryCode": "DE"
            },
            {
                "name": "Gerlingen",
                "countryCode": "DE"
            },
            {
                "name": "Gernsbach",
                "countryCode": "DE"
            },
            {
                "name": "Gerstetten",
                "countryCode": "DE"
            },
            {
                "name": "Giengen an der Brenz",
                "countryCode": "DE"
            },
            {
                "name": "Gingen an der Fils",
                "countryCode": "DE"
            },
            {
                "name": "Glatten",
                "countryCode": "DE"
            },
            {
                "name": "Gomadingen",
                "countryCode": "DE"
            },
            {
                "name": "Gomaringen",
                "countryCode": "DE"
            },
            {
                "name": "Gondelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gosheim",
                "countryCode": "DE"
            },
            {
                "name": "Gottenheim",
                "countryCode": "DE"
            },
            {
                "name": "Gottmadingen",
                "countryCode": "DE"
            },
            {
                "name": "Graben-Neudorf",
                "countryCode": "DE"
            },
            {
                "name": "Grabenstetten",
                "countryCode": "DE"
            },
            {
                "name": "Grafenau",
                "countryCode": "DE"
            },
            {
                "name": "Grafenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Greffern",
                "countryCode": "DE"
            },
            {
                "name": "Grenzach-Wyhlen",
                "countryCode": "DE"
            },
            {
                "name": "Grosselfingen",
                "countryCode": "DE"
            },
            {
                "name": "Großbettlingen",
                "countryCode": "DE"
            },
            {
                "name": "Großbottwar",
                "countryCode": "DE"
            },
            {
                "name": "Großerlach",
                "countryCode": "DE"
            },
            {
                "name": "Großrinderfeld",
                "countryCode": "DE"
            },
            {
                "name": "Gruibingen",
                "countryCode": "DE"
            },
            {
                "name": "Grünkraut",
                "countryCode": "DE"
            },
            {
                "name": "Grünsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Gschwend",
                "countryCode": "DE"
            },
            {
                "name": "Gundelfingen",
                "countryCode": "DE"
            },
            {
                "name": "Gundelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gutach",
                "countryCode": "DE"
            },
            {
                "name": "Gutach im Breisgau",
                "countryCode": "DE"
            },
            {
                "name": "Gutenzell-Hürbel",
                "countryCode": "DE"
            },
            {
                "name": "Gärtringen",
                "countryCode": "DE"
            },
            {
                "name": "Göggingen",
                "countryCode": "DE"
            },
            {
                "name": "Göppingen",
                "countryCode": "DE"
            },
            {
                "name": "Görwihl",
                "countryCode": "DE"
            },
            {
                "name": "Güglingen",
                "countryCode": "DE"
            },
            {
                "name": "Gütenbach",
                "countryCode": "DE"
            },
            {
                "name": "Hagnau",
                "countryCode": "DE"
            },
            {
                "name": "Haigerloch",
                "countryCode": "DE"
            },
            {
                "name": "Haiterbach",
                "countryCode": "DE"
            },
            {
                "name": "Hambrücken",
                "countryCode": "DE"
            },
            {
                "name": "Hardheim",
                "countryCode": "DE"
            },
            {
                "name": "Hardt",
                "countryCode": "DE"
            },
            {
                "name": "Hartheim",
                "countryCode": "DE"
            },
            {
                "name": "Hasel",
                "countryCode": "DE"
            },
            {
                "name": "Haslach",
                "countryCode": "DE"
            },
            {
                "name": "Hattenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Hausach",
                "countryCode": "DE"
            },
            {
                "name": "Hausen",
                "countryCode": "DE"
            },
            {
                "name": "Hayingen",
                "countryCode": "DE"
            },
            {
                "name": "Haßmersheim",
                "countryCode": "DE"
            },
            {
                "name": "Hechingen",
                "countryCode": "DE"
            },
            {
                "name": "Heddesheim",
                "countryCode": "DE"
            },
            {
                "name": "Heidelberg",
                "countryCode": "DE"
            },
            {
                "name": "Heidenheim an der Brenz",
                "countryCode": "DE"
            },
            {
                "name": "Heilbronn",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenberg",
                "countryCode": "DE"
            },
            {
                "name": "Heiligkreuzsteinach",
                "countryCode": "DE"
            },
            {
                "name": "Heimsheim",
                "countryCode": "DE"
            },
            {
                "name": "Heiningen",
                "countryCode": "DE"
            },
            {
                "name": "Heitersheim",
                "countryCode": "DE"
            },
            {
                "name": "Helmstadt-Bargen",
                "countryCode": "DE"
            },
            {
                "name": "Hemmingen",
                "countryCode": "DE"
            },
            {
                "name": "Hemsbach",
                "countryCode": "DE"
            },
            {
                "name": "Herbertingen",
                "countryCode": "DE"
            },
            {
                "name": "Herbolzheim",
                "countryCode": "DE"
            },
            {
                "name": "Herbrechtingen",
                "countryCode": "DE"
            },
            {
                "name": "Herdwangen-Schönach",
                "countryCode": "DE"
            },
            {
                "name": "Hermaringen",
                "countryCode": "DE"
            },
            {
                "name": "Herrenberg",
                "countryCode": "DE"
            },
            {
                "name": "Herrischried",
                "countryCode": "DE"
            },
            {
                "name": "Hessigheim",
                "countryCode": "DE"
            },
            {
                "name": "Hettingen",
                "countryCode": "DE"
            },
            {
                "name": "Heubach",
                "countryCode": "DE"
            },
            {
                "name": "Heuchlingen",
                "countryCode": "DE"
            },
            {
                "name": "Hildrizhausen",
                "countryCode": "DE"
            },
            {
                "name": "Hilzingen",
                "countryCode": "DE"
            },
            {
                "name": "Hinterzarten",
                "countryCode": "DE"
            },
            {
                "name": "Hirrlingen",
                "countryCode": "DE"
            },
            {
                "name": "Hirschberg an der Bergstraße",
                "countryCode": "DE"
            },
            {
                "name": "Hochdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hockenheim",
                "countryCode": "DE"
            },
            {
                "name": "Hofstetten",
                "countryCode": "DE"
            },
            {
                "name": "Hohberg",
                "countryCode": "DE"
            },
            {
                "name": "Hohentengen",
                "countryCode": "DE"
            },
            {
                "name": "Holzgerlingen",
                "countryCode": "DE"
            },
            {
                "name": "Holzmaden",
                "countryCode": "DE"
            },
            {
                "name": "Horb am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Horben",
                "countryCode": "DE"
            },
            {
                "name": "Horgenzell",
                "countryCode": "DE"
            },
            {
                "name": "Hornberg",
                "countryCode": "DE"
            },
            {
                "name": "Horrenberg",
                "countryCode": "DE"
            },
            {
                "name": "Häusern",
                "countryCode": "DE"
            },
            {
                "name": "Höchenschwand",
                "countryCode": "DE"
            },
            {
                "name": "Höfen an der Enz",
                "countryCode": "DE"
            },
            {
                "name": "Höpfingen",
                "countryCode": "DE"
            },
            {
                "name": "Hüffenhardt",
                "countryCode": "DE"
            },
            {
                "name": "Hüfingen",
                "countryCode": "DE"
            },
            {
                "name": "Hügelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Hülben",
                "countryCode": "DE"
            },
            {
                "name": "Hüttisheim",
                "countryCode": "DE"
            },
            {
                "name": "Hüttlingen",
                "countryCode": "DE"
            },
            {
                "name": "Iffezheim",
                "countryCode": "DE"
            },
            {
                "name": "Igersheim",
                "countryCode": "DE"
            },
            {
                "name": "Iggingen",
                "countryCode": "DE"
            },
            {
                "name": "Ihringen",
                "countryCode": "DE"
            },
            {
                "name": "Illerrieden",
                "countryCode": "DE"
            },
            {
                "name": "Illingen",
                "countryCode": "DE"
            },
            {
                "name": "Illmensee",
                "countryCode": "DE"
            },
            {
                "name": "Ilsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Ilshofen",
                "countryCode": "DE"
            },
            {
                "name": "Ilvesheim",
                "countryCode": "DE"
            },
            {
                "name": "Immendingen",
                "countryCode": "DE"
            },
            {
                "name": "Immenstaad am Bodensee",
                "countryCode": "DE"
            },
            {
                "name": "Ingelfingen",
                "countryCode": "DE"
            },
            {
                "name": "Ingoldingen",
                "countryCode": "DE"
            },
            {
                "name": "Inzigkofen",
                "countryCode": "DE"
            },
            {
                "name": "Inzlingen",
                "countryCode": "DE"
            },
            {
                "name": "Isny",
                "countryCode": "DE"
            },
            {
                "name": "Ispringen",
                "countryCode": "DE"
            },
            {
                "name": "Ittlingen",
                "countryCode": "DE"
            },
            {
                "name": "Jagsthausen",
                "countryCode": "DE"
            },
            {
                "name": "Jagstzell",
                "countryCode": "DE"
            },
            {
                "name": "Jestetten",
                "countryCode": "DE"
            },
            {
                "name": "Jungingen",
                "countryCode": "DE"
            },
            {
                "name": "Jöhlingen",
                "countryCode": "DE"
            },
            {
                "name": "Kaisersbach",
                "countryCode": "DE"
            },
            {
                "name": "Kandern",
                "countryCode": "DE"
            },
            {
                "name": "Kappel-Grafenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Kappelrodeck",
                "countryCode": "DE"
            },
            {
                "name": "Karlsdorf-Neuthard",
                "countryCode": "DE"
            },
            {
                "name": "Karlsruhe",
                "countryCode": "DE"
            },
            {
                "name": "Karlsruhe Region",
                "countryCode": "DE"
            },
            {
                "name": "Kehl",
                "countryCode": "DE"
            },
            {
                "name": "Kenzingen",
                "countryCode": "DE"
            },
            {
                "name": "Ketsch",
                "countryCode": "DE"
            },
            {
                "name": "Kieselbronn",
                "countryCode": "DE"
            },
            {
                "name": "Kippenheim",
                "countryCode": "DE"
            },
            {
                "name": "Kirchardt",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg an der Iller",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg an der Jagst",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg an der Murr",
                "countryCode": "DE"
            },
            {
                "name": "Kirchdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kirchentellinsfurt",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim am Ries",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim unter Teck",
                "countryCode": "DE"
            },
            {
                "name": "Kirchzarten",
                "countryCode": "DE"
            },
            {
                "name": "Kißlegg",
                "countryCode": "DE"
            },
            {
                "name": "Klingenstein",
                "countryCode": "DE"
            },
            {
                "name": "Knittlingen",
                "countryCode": "DE"
            },
            {
                "name": "Kohlberg",
                "countryCode": "DE"
            },
            {
                "name": "Kolbingen",
                "countryCode": "DE"
            },
            {
                "name": "Konstanz",
                "countryCode": "DE"
            },
            {
                "name": "Korb",
                "countryCode": "DE"
            },
            {
                "name": "Korntal",
                "countryCode": "DE"
            },
            {
                "name": "Kornwestheim",
                "countryCode": "DE"
            },
            {
                "name": "Kraichtal",
                "countryCode": "DE"
            },
            {
                "name": "Krauchenwies",
                "countryCode": "DE"
            },
            {
                "name": "Krautheim",
                "countryCode": "DE"
            },
            {
                "name": "Kressbronn am Bodensee",
                "countryCode": "DE"
            },
            {
                "name": "Kronau",
                "countryCode": "DE"
            },
            {
                "name": "Kuchen",
                "countryCode": "DE"
            },
            {
                "name": "Kupferzell",
                "countryCode": "DE"
            },
            {
                "name": "Kuppenheim",
                "countryCode": "DE"
            },
            {
                "name": "Kusterdingen",
                "countryCode": "DE"
            },
            {
                "name": "Köngen",
                "countryCode": "DE"
            },
            {
                "name": "Königheim",
                "countryCode": "DE"
            },
            {
                "name": "Königsbach-Stein",
                "countryCode": "DE"
            },
            {
                "name": "Königsbronn",
                "countryCode": "DE"
            },
            {
                "name": "Königsfeld im Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Külsheim",
                "countryCode": "DE"
            },
            {
                "name": "Künzelsau",
                "countryCode": "DE"
            },
            {
                "name": "Kürnbach",
                "countryCode": "DE"
            },
            {
                "name": "Ladenburg",
                "countryCode": "DE"
            },
            {
                "name": "Lahr",
                "countryCode": "DE"
            },
            {
                "name": "Laichingen",
                "countryCode": "DE"
            },
            {
                "name": "Langenargen",
                "countryCode": "DE"
            },
            {
                "name": "Langenau",
                "countryCode": "DE"
            },
            {
                "name": "Langenbrettach",
                "countryCode": "DE"
            },
            {
                "name": "Langenburg",
                "countryCode": "DE"
            },
            {
                "name": "Langenenslingen",
                "countryCode": "DE"
            },
            {
                "name": "Lauchheim",
                "countryCode": "DE"
            },
            {
                "name": "Lauchringen",
                "countryCode": "DE"
            },
            {
                "name": "Lauda-Königshofen",
                "countryCode": "DE"
            },
            {
                "name": "Laudenbach",
                "countryCode": "DE"
            },
            {
                "name": "Lauf",
                "countryCode": "DE"
            },
            {
                "name": "Laufenburg",
                "countryCode": "DE"
            },
            {
                "name": "Lauffen am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Laupheim",
                "countryCode": "DE"
            },
            {
                "name": "Lautenbach",
                "countryCode": "DE"
            },
            {
                "name": "Lauterbach/Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Lehrensteinsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Leibertingen",
                "countryCode": "DE"
            },
            {
                "name": "Leimen",
                "countryCode": "DE"
            },
            {
                "name": "Leinfelden-Echterdingen",
                "countryCode": "DE"
            },
            {
                "name": "Leingarten",
                "countryCode": "DE"
            },
            {
                "name": "Leinzell",
                "countryCode": "DE"
            },
            {
                "name": "Lenningen",
                "countryCode": "DE"
            },
            {
                "name": "Lenzkirch",
                "countryCode": "DE"
            },
            {
                "name": "Leonberg",
                "countryCode": "DE"
            },
            {
                "name": "Leutenbach",
                "countryCode": "DE"
            },
            {
                "name": "Leutkirch",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenau",
                "countryCode": "DE"
            },
            {
                "name": "Linkenheim-Hochstetten",
                "countryCode": "DE"
            },
            {
                "name": "Lobbach",
                "countryCode": "DE"
            },
            {
                "name": "Loffenau",
                "countryCode": "DE"
            },
            {
                "name": "Lonsee",
                "countryCode": "DE"
            },
            {
                "name": "Lorch",
                "countryCode": "DE"
            },
            {
                "name": "Lottstetten",
                "countryCode": "DE"
            },
            {
                "name": "Loßburg",
                "countryCode": "DE"
            },
            {
                "name": "Ludwigsburg",
                "countryCode": "DE"
            },
            {
                "name": "Löchgau",
                "countryCode": "DE"
            },
            {
                "name": "Löffingen",
                "countryCode": "DE"
            },
            {
                "name": "Lörrach",
                "countryCode": "DE"
            },
            {
                "name": "Löwenstein",
                "countryCode": "DE"
            },
            {
                "name": "Magstadt",
                "countryCode": "DE"
            },
            {
                "name": "Mahlberg",
                "countryCode": "DE"
            },
            {
                "name": "Mainhardt",
                "countryCode": "DE"
            },
            {
                "name": "Malsch",
                "countryCode": "DE"
            },
            {
                "name": "Malterdingen",
                "countryCode": "DE"
            },
            {
                "name": "Mannheim",
                "countryCode": "DE"
            },
            {
                "name": "Marbach am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Markdorf",
                "countryCode": "DE"
            },
            {
                "name": "Markgröningen",
                "countryCode": "DE"
            },
            {
                "name": "Maselheim",
                "countryCode": "DE"
            },
            {
                "name": "Massenbachhausen",
                "countryCode": "DE"
            },
            {
                "name": "Mauer",
                "countryCode": "DE"
            },
            {
                "name": "Maulbronn",
                "countryCode": "DE"
            },
            {
                "name": "Maulburg",
                "countryCode": "DE"
            },
            {
                "name": "Meckenbeuren",
                "countryCode": "DE"
            },
            {
                "name": "Meckesheim",
                "countryCode": "DE"
            },
            {
                "name": "Meersburg",
                "countryCode": "DE"
            },
            {
                "name": "Mehrstetten",
                "countryCode": "DE"
            },
            {
                "name": "Meißenheim",
                "countryCode": "DE"
            },
            {
                "name": "Mengen",
                "countryCode": "DE"
            },
            {
                "name": "Merdingen",
                "countryCode": "DE"
            },
            {
                "name": "Merklingen",
                "countryCode": "DE"
            },
            {
                "name": "Merzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Metzingen",
                "countryCode": "DE"
            },
            {
                "name": "Meßkirch",
                "countryCode": "DE"
            },
            {
                "name": "Meßstetten",
                "countryCode": "DE"
            },
            {
                "name": "Michelbach an der Bilz",
                "countryCode": "DE"
            },
            {
                "name": "Michelfeld",
                "countryCode": "DE"
            },
            {
                "name": "Mietingen",
                "countryCode": "DE"
            },
            {
                "name": "Mittelbiberach",
                "countryCode": "DE"
            },
            {
                "name": "Mittelschöntal",
                "countryCode": "DE"
            },
            {
                "name": "Moos",
                "countryCode": "DE"
            },
            {
                "name": "Mosbach",
                "countryCode": "DE"
            },
            {
                "name": "Mudau",
                "countryCode": "DE"
            },
            {
                "name": "Muggensturm",
                "countryCode": "DE"
            },
            {
                "name": "Mulfingen",
                "countryCode": "DE"
            },
            {
                "name": "Mundelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Munderkingen",
                "countryCode": "DE"
            },
            {
                "name": "Murg",
                "countryCode": "DE"
            },
            {
                "name": "Murr",
                "countryCode": "DE"
            },
            {
                "name": "Murrhardt",
                "countryCode": "DE"
            },
            {
                "name": "Mutlangen",
                "countryCode": "DE"
            },
            {
                "name": "Möckmühl",
                "countryCode": "DE"
            },
            {
                "name": "Mögglingen",
                "countryCode": "DE"
            },
            {
                "name": "Möglingen",
                "countryCode": "DE"
            },
            {
                "name": "Mönchweiler",
                "countryCode": "DE"
            },
            {
                "name": "Mönsheim",
                "countryCode": "DE"
            },
            {
                "name": "Mössingen",
                "countryCode": "DE"
            },
            {
                "name": "Mötzingen",
                "countryCode": "DE"
            },
            {
                "name": "Mühlacker",
                "countryCode": "DE"
            },
            {
                "name": "Mühlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Mühlhausen",
                "countryCode": "DE"
            },
            {
                "name": "Mühlhausen-Ehingen",
                "countryCode": "DE"
            },
            {
                "name": "Mühlheim am Bach",
                "countryCode": "DE"
            },
            {
                "name": "Mühlingen",
                "countryCode": "DE"
            },
            {
                "name": "Müllheim",
                "countryCode": "DE"
            },
            {
                "name": "Münsingen",
                "countryCode": "DE"
            },
            {
                "name": "Münstertal/Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Nagold",
                "countryCode": "DE"
            },
            {
                "name": "Nattheim",
                "countryCode": "DE"
            },
            {
                "name": "Neckarbischofsheim",
                "countryCode": "DE"
            },
            {
                "name": "Neckargemünd",
                "countryCode": "DE"
            },
            {
                "name": "Neckargerach",
                "countryCode": "DE"
            },
            {
                "name": "Neckarsulm",
                "countryCode": "DE"
            },
            {
                "name": "Neckartailfingen",
                "countryCode": "DE"
            },
            {
                "name": "Neckartenzlingen",
                "countryCode": "DE"
            },
            {
                "name": "Neckarwestheim",
                "countryCode": "DE"
            },
            {
                "name": "Neckarzimmern",
                "countryCode": "DE"
            },
            {
                "name": "Nehren",
                "countryCode": "DE"
            },
            {
                "name": "Neidenstein",
                "countryCode": "DE"
            },
            {
                "name": "Neidlingen",
                "countryCode": "DE"
            },
            {
                "name": "Nellingen",
                "countryCode": "DE"
            },
            {
                "name": "Neresheim",
                "countryCode": "DE"
            },
            {
                "name": "Neubulach",
                "countryCode": "DE"
            },
            {
                "name": "Neudenau",
                "countryCode": "DE"
            },
            {
                "name": "Neuenburg am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Neuenbürg",
                "countryCode": "DE"
            },
            {
                "name": "Neuenstadt am Kocher",
                "countryCode": "DE"
            },
            {
                "name": "Neuenstein",
                "countryCode": "DE"
            },
            {
                "name": "Neuffen",
                "countryCode": "DE"
            },
            {
                "name": "Neufra",
                "countryCode": "DE"
            },
            {
                "name": "Neuhausen",
                "countryCode": "DE"
            },
            {
                "name": "Neuhausen auf den Fildern",
                "countryCode": "DE"
            },
            {
                "name": "Neukirch",
                "countryCode": "DE"
            },
            {
                "name": "Neuler",
                "countryCode": "DE"
            },
            {
                "name": "Neulußheim",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neuweiler",
                "countryCode": "DE"
            },
            {
                "name": "Niedereschach",
                "countryCode": "DE"
            },
            {
                "name": "Niedernhall",
                "countryCode": "DE"
            },
            {
                "name": "Niederstetten",
                "countryCode": "DE"
            },
            {
                "name": "Niederstotzingen",
                "countryCode": "DE"
            },
            {
                "name": "Niederwangen",
                "countryCode": "DE"
            },
            {
                "name": "Niefern-Öschelbronn",
                "countryCode": "DE"
            },
            {
                "name": "Nordheim",
                "countryCode": "DE"
            },
            {
                "name": "Nordrach",
                "countryCode": "DE"
            },
            {
                "name": "Notzingen",
                "countryCode": "DE"
            },
            {
                "name": "Nufringen",
                "countryCode": "DE"
            },
            {
                "name": "Nußloch",
                "countryCode": "DE"
            },
            {
                "name": "Nürtingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberboihingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberderdingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberdischingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberharmersbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberhausen-Rheinhausen",
                "countryCode": "DE"
            },
            {
                "name": "Oberjettingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberkirch",
                "countryCode": "DE"
            },
            {
                "name": "Oberkochen",
                "countryCode": "DE"
            },
            {
                "name": "Obermarchtal",
                "countryCode": "DE"
            },
            {
                "name": "Oberndorf",
                "countryCode": "DE"
            },
            {
                "name": "Obernheim",
                "countryCode": "DE"
            },
            {
                "name": "Oberreichenbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberried",
                "countryCode": "DE"
            },
            {
                "name": "Oberriexingen",
                "countryCode": "DE"
            },
            {
                "name": "Oberrot",
                "countryCode": "DE"
            },
            {
                "name": "Oberrotweil",
                "countryCode": "DE"
            },
            {
                "name": "Obersontheim",
                "countryCode": "DE"
            },
            {
                "name": "Oberstadion",
                "countryCode": "DE"
            },
            {
                "name": "Oberstenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Oberteuringen",
                "countryCode": "DE"
            },
            {
                "name": "Oberwolfach",
                "countryCode": "DE"
            },
            {
                "name": "Obrigheim",
                "countryCode": "DE"
            },
            {
                "name": "Ochsenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Oedheim",
                "countryCode": "DE"
            },
            {
                "name": "Offenau",
                "countryCode": "DE"
            },
            {
                "name": "Offenburg",
                "countryCode": "DE"
            },
            {
                "name": "Ofterdingen",
                "countryCode": "DE"
            },
            {
                "name": "Oftersheim",
                "countryCode": "DE"
            },
            {
                "name": "Ohlsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ohmden",
                "countryCode": "DE"
            },
            {
                "name": "Oppenau",
                "countryCode": "DE"
            },
            {
                "name": "Oppenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Orsingen-Nenzingen",
                "countryCode": "DE"
            },
            {
                "name": "Ortenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ostelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Osterburken",
                "countryCode": "DE"
            },
            {
                "name": "Ostfildern",
                "countryCode": "DE"
            },
            {
                "name": "Ostrach",
                "countryCode": "DE"
            },
            {
                "name": "Ottenbach",
                "countryCode": "DE"
            },
            {
                "name": "Ottenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ottersweier",
                "countryCode": "DE"
            },
            {
                "name": "Owen",
                "countryCode": "DE"
            },
            {
                "name": "Owingen",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Pfalzgrafenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Pfedelbach",
                "countryCode": "DE"
            },
            {
                "name": "Pforzheim",
                "countryCode": "DE"
            },
            {
                "name": "Pfronstetten",
                "countryCode": "DE"
            },
            {
                "name": "Pfullendorf",
                "countryCode": "DE"
            },
            {
                "name": "Pfullingen",
                "countryCode": "DE"
            },
            {
                "name": "Philippsburg",
                "countryCode": "DE"
            },
            {
                "name": "Plankstadt",
                "countryCode": "DE"
            },
            {
                "name": "Pleidelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Pliezhausen",
                "countryCode": "DE"
            },
            {
                "name": "Plochingen",
                "countryCode": "DE"
            },
            {
                "name": "Plüderhausen",
                "countryCode": "DE"
            },
            {
                "name": "Radolfzell",
                "countryCode": "DE"
            },
            {
                "name": "Rammingen",
                "countryCode": "DE"
            },
            {
                "name": "Rangendingen",
                "countryCode": "DE"
            },
            {
                "name": "Rastatt",
                "countryCode": "DE"
            },
            {
                "name": "Rauenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ravensburg",
                "countryCode": "DE"
            },
            {
                "name": "Rechberghausen",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Stuttgart",
                "countryCode": "DE"
            },
            {
                "name": "Reichartshausen",
                "countryCode": "DE"
            },
            {
                "name": "Reichenau",
                "countryCode": "DE"
            },
            {
                "name": "Reichenbach an der Fils",
                "countryCode": "DE"
            },
            {
                "name": "Reilingen",
                "countryCode": "DE"
            },
            {
                "name": "Renchen",
                "countryCode": "DE"
            },
            {
                "name": "Renningen",
                "countryCode": "DE"
            },
            {
                "name": "Reutlingen",
                "countryCode": "DE"
            },
            {
                "name": "Rheinau",
                "countryCode": "DE"
            },
            {
                "name": "Rheinfelden",
                "countryCode": "DE"
            },
            {
                "name": "Rheinstetten",
                "countryCode": "DE"
            },
            {
                "name": "Rickenbach",
                "countryCode": "DE"
            },
            {
                "name": "Riederich",
                "countryCode": "DE"
            },
            {
                "name": "Riedlingen",
                "countryCode": "DE"
            },
            {
                "name": "Riegel",
                "countryCode": "DE"
            },
            {
                "name": "Rielasingen-Worblingen",
                "countryCode": "DE"
            },
            {
                "name": "Rietheim-Weilheim",
                "countryCode": "DE"
            },
            {
                "name": "Ringsheim",
                "countryCode": "DE"
            },
            {
                "name": "Rohrdorf",
                "countryCode": "DE"
            },
            {
                "name": "Roigheim",
                "countryCode": "DE"
            },
            {
                "name": "Rosenberg",
                "countryCode": "DE"
            },
            {
                "name": "Rosenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Rot",
                "countryCode": "DE"
            },
            {
                "name": "Rot am See",
                "countryCode": "DE"
            },
            {
                "name": "Rottenacker",
                "countryCode": "DE"
            },
            {
                "name": "Rottenburg",
                "countryCode": "DE"
            },
            {
                "name": "Rottweil",
                "countryCode": "DE"
            },
            {
                "name": "Rudersberg",
                "countryCode": "DE"
            },
            {
                "name": "Ruppertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Rust",
                "countryCode": "DE"
            },
            {
                "name": "Rutesheim",
                "countryCode": "DE"
            },
            {
                "name": "Rümmingen",
                "countryCode": "DE"
            },
            {
                "name": "Sachsenheim",
                "countryCode": "DE"
            },
            {
                "name": "Salach",
                "countryCode": "DE"
            },
            {
                "name": "Salem",
                "countryCode": "DE"
            },
            {
                "name": "Sandhausen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Blasien",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Georgen im Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Johann",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Leon-Rot",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Märgen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Peter",
                "countryCode": "DE"
            },
            {
                "name": "Sasbach",
                "countryCode": "DE"
            },
            {
                "name": "Sasbachwalden",
                "countryCode": "DE"
            },
            {
                "name": "Satteldorf",
                "countryCode": "DE"
            },
            {
                "name": "Sauldorf",
                "countryCode": "DE"
            },
            {
                "name": "Saulgau",
                "countryCode": "DE"
            },
            {
                "name": "Schallstadt",
                "countryCode": "DE"
            },
            {
                "name": "Schechingen",
                "countryCode": "DE"
            },
            {
                "name": "Scheer",
                "countryCode": "DE"
            },
            {
                "name": "Schelklingen",
                "countryCode": "DE"
            },
            {
                "name": "Schenkenzell",
                "countryCode": "DE"
            },
            {
                "name": "Schiltach",
                "countryCode": "DE"
            },
            {
                "name": "Schlaitdorf",
                "countryCode": "DE"
            },
            {
                "name": "Schlat",
                "countryCode": "DE"
            },
            {
                "name": "Schliengen",
                "countryCode": "DE"
            },
            {
                "name": "Schlier",
                "countryCode": "DE"
            },
            {
                "name": "Schlierbach",
                "countryCode": "DE"
            },
            {
                "name": "Schluchsee",
                "countryCode": "DE"
            },
            {
                "name": "Schnürpflingen",
                "countryCode": "DE"
            },
            {
                "name": "Schonach im Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Schopfheim",
                "countryCode": "DE"
            },
            {
                "name": "Schopfloch",
                "countryCode": "DE"
            },
            {
                "name": "Schorndorf",
                "countryCode": "DE"
            },
            {
                "name": "Schramberg",
                "countryCode": "DE"
            },
            {
                "name": "Schriesheim",
                "countryCode": "DE"
            },
            {
                "name": "Schrozberg",
                "countryCode": "DE"
            },
            {
                "name": "Schuttertal",
                "countryCode": "DE"
            },
            {
                "name": "Schutterwald",
                "countryCode": "DE"
            },
            {
                "name": "Schwaigern",
                "countryCode": "DE"
            },
            {
                "name": "Schwaikheim",
                "countryCode": "DE"
            },
            {
                "name": "Schwanau",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzach",
                "countryCode": "DE"
            },
            {
                "name": "Schwendi",
                "countryCode": "DE"
            },
            {
                "name": "Schwenningen",
                "countryCode": "DE"
            },
            {
                "name": "Schwetzingen",
                "countryCode": "DE"
            },
            {
                "name": "Schwieberdingen",
                "countryCode": "DE"
            },
            {
                "name": "Schwäbisch Gmünd",
                "countryCode": "DE"
            },
            {
                "name": "Schwäbisch Hall",
                "countryCode": "DE"
            },
            {
                "name": "Schwörstadt",
                "countryCode": "DE"
            },
            {
                "name": "Schömberg",
                "countryCode": "DE"
            },
            {
                "name": "Schönaich",
                "countryCode": "DE"
            },
            {
                "name": "Schönau",
                "countryCode": "DE"
            },
            {
                "name": "Schönau im Schwarzwald",
                "countryCode": "DE"
            },
            {
                "name": "Schönwald",
                "countryCode": "DE"
            },
            {
                "name": "Seckach",
                "countryCode": "DE"
            },
            {
                "name": "Seebach",
                "countryCode": "DE"
            },
            {
                "name": "Seedorf",
                "countryCode": "DE"
            },
            {
                "name": "Seelbach",
                "countryCode": "DE"
            },
            {
                "name": "Seitingen-Oberflacht",
                "countryCode": "DE"
            },
            {
                "name": "Sersheim",
                "countryCode": "DE"
            },
            {
                "name": "Sexau",
                "countryCode": "DE"
            },
            {
                "name": "Sickenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Siegelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Sigmaringen",
                "countryCode": "DE"
            },
            {
                "name": "Sigmaringendorf",
                "countryCode": "DE"
            },
            {
                "name": "Simmersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Simmozheim",
                "countryCode": "DE"
            },
            {
                "name": "Sindelfingen",
                "countryCode": "DE"
            },
            {
                "name": "Singen",
                "countryCode": "DE"
            },
            {
                "name": "Sinsheim",
                "countryCode": "DE"
            },
            {
                "name": "Sinzheim",
                "countryCode": "DE"
            },
            {
                "name": "Sipplingen",
                "countryCode": "DE"
            },
            {
                "name": "Sontheim an der Brenz",
                "countryCode": "DE"
            },
            {
                "name": "Spaichingen",
                "countryCode": "DE"
            },
            {
                "name": "Spechbach",
                "countryCode": "DE"
            },
            {
                "name": "Spiegelberg",
                "countryCode": "DE"
            },
            {
                "name": "Spraitbach",
                "countryCode": "DE"
            },
            {
                "name": "Staig",
                "countryCode": "DE"
            },
            {
                "name": "Staufen",
                "countryCode": "DE"
            },
            {
                "name": "Stegen",
                "countryCode": "DE"
            },
            {
                "name": "Steinach",
                "countryCode": "DE"
            },
            {
                "name": "Steinen",
                "countryCode": "DE"
            },
            {
                "name": "Steinenbronn",
                "countryCode": "DE"
            },
            {
                "name": "Steinheim am Albuch",
                "countryCode": "DE"
            },
            {
                "name": "Steinheim am der Murr",
                "countryCode": "DE"
            },
            {
                "name": "Steinmauern",
                "countryCode": "DE"
            },
            {
                "name": "Steißlingen",
                "countryCode": "DE"
            },
            {
                "name": "Sternenfels",
                "countryCode": "DE"
            },
            {
                "name": "Stetten am Kalten Markt",
                "countryCode": "DE"
            },
            {
                "name": "Stimpfach",
                "countryCode": "DE"
            },
            {
                "name": "Stockach",
                "countryCode": "DE"
            },
            {
                "name": "Straßberg",
                "countryCode": "DE"
            },
            {
                "name": "Stuttgart",
                "countryCode": "DE"
            },
            {
                "name": "Stuttgart Feuerbach",
                "countryCode": "DE"
            },
            {
                "name": "Stuttgart Mühlhausen",
                "countryCode": "DE"
            },
            {
                "name": "Stuttgart-Ost",
                "countryCode": "DE"
            },
            {
                "name": "Stödtlen",
                "countryCode": "DE"
            },
            {
                "name": "Stühlingen",
                "countryCode": "DE"
            },
            {
                "name": "Sulz am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Sulzbach an der Murr",
                "countryCode": "DE"
            },
            {
                "name": "Sulzburg",
                "countryCode": "DE"
            },
            {
                "name": "Sulzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Sölden",
                "countryCode": "DE"
            },
            {
                "name": "Süßen",
                "countryCode": "DE"
            },
            {
                "name": "Talheim",
                "countryCode": "DE"
            },
            {
                "name": "Tamm",
                "countryCode": "DE"
            },
            {
                "name": "Tannhausen",
                "countryCode": "DE"
            },
            {
                "name": "Tannheim",
                "countryCode": "DE"
            },
            {
                "name": "Tauberbischofsheim",
                "countryCode": "DE"
            },
            {
                "name": "Tengen",
                "countryCode": "DE"
            },
            {
                "name": "Teningen",
                "countryCode": "DE"
            },
            {
                "name": "Tennenbronn",
                "countryCode": "DE"
            },
            {
                "name": "Tettnang",
                "countryCode": "DE"
            },
            {
                "name": "Tiefenbronn",
                "countryCode": "DE"
            },
            {
                "name": "Titisee-Neustadt",
                "countryCode": "DE"
            },
            {
                "name": "Todtmoos",
                "countryCode": "DE"
            },
            {
                "name": "Todtnau",
                "countryCode": "DE"
            },
            {
                "name": "Triberg",
                "countryCode": "DE"
            },
            {
                "name": "Trochtelfingen",
                "countryCode": "DE"
            },
            {
                "name": "Trossingen",
                "countryCode": "DE"
            },
            {
                "name": "Tuningen",
                "countryCode": "DE"
            },
            {
                "name": "Tuttlingen",
                "countryCode": "DE"
            },
            {
                "name": "Täferrot",
                "countryCode": "DE"
            },
            {
                "name": "Tübingen",
                "countryCode": "DE"
            },
            {
                "name": "Tübingen Region",
                "countryCode": "DE"
            },
            {
                "name": "Ubstadt-Weiher",
                "countryCode": "DE"
            },
            {
                "name": "Uhingen",
                "countryCode": "DE"
            },
            {
                "name": "Uhldingen-Mühlhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ulm",
                "countryCode": "DE"
            },
            {
                "name": "Umkirch",
                "countryCode": "DE"
            },
            {
                "name": "Ummendorf",
                "countryCode": "DE"
            },
            {
                "name": "Unlingen",
                "countryCode": "DE"
            },
            {
                "name": "Untereisesheim",
                "countryCode": "DE"
            },
            {
                "name": "Unterensingen",
                "countryCode": "DE"
            },
            {
                "name": "Untergruppenbach",
                "countryCode": "DE"
            },
            {
                "name": "Unterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Unterjettingen",
                "countryCode": "DE"
            },
            {
                "name": "Unterkirnach",
                "countryCode": "DE"
            },
            {
                "name": "Unterkrozingen",
                "countryCode": "DE"
            },
            {
                "name": "Untermünkheim",
                "countryCode": "DE"
            },
            {
                "name": "Unterreichenbach",
                "countryCode": "DE"
            },
            {
                "name": "Unterschneidheim",
                "countryCode": "DE"
            },
            {
                "name": "Urbach",
                "countryCode": "DE"
            },
            {
                "name": "Uttenweiler",
                "countryCode": "DE"
            },
            {
                "name": "Vaihingen an der Enz",
                "countryCode": "DE"
            },
            {
                "name": "Vellberg",
                "countryCode": "DE"
            },
            {
                "name": "Veringenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Villingen-Schwenningen",
                "countryCode": "DE"
            },
            {
                "name": "Villingendorf",
                "countryCode": "DE"
            },
            {
                "name": "Vogt",
                "countryCode": "DE"
            },
            {
                "name": "Vogtsburg",
                "countryCode": "DE"
            },
            {
                "name": "Volkertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Vöhrenbach",
                "countryCode": "DE"
            },
            {
                "name": "Vöhringen",
                "countryCode": "DE"
            },
            {
                "name": "Vörstetten",
                "countryCode": "DE"
            },
            {
                "name": "Waghäusel",
                "countryCode": "DE"
            },
            {
                "name": "Waiblingen",
                "countryCode": "DE"
            },
            {
                "name": "Waibstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wain",
                "countryCode": "DE"
            },
            {
                "name": "Wald",
                "countryCode": "DE"
            },
            {
                "name": "Waldburg",
                "countryCode": "DE"
            },
            {
                "name": "Waldenbuch",
                "countryCode": "DE"
            },
            {
                "name": "Waldenburg",
                "countryCode": "DE"
            },
            {
                "name": "Waldkirch",
                "countryCode": "DE"
            },
            {
                "name": "Waldshut-Tiengen",
                "countryCode": "DE"
            },
            {
                "name": "Waldstetten",
                "countryCode": "DE"
            },
            {
                "name": "Walheim",
                "countryCode": "DE"
            },
            {
                "name": "Walldorf",
                "countryCode": "DE"
            },
            {
                "name": "Walldürn",
                "countryCode": "DE"
            },
            {
                "name": "Wallhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wangen",
                "countryCode": "DE"
            },
            {
                "name": "Wannweil",
                "countryCode": "DE"
            },
            {
                "name": "Warthausen",
                "countryCode": "DE"
            },
            {
                "name": "Wehingen",
                "countryCode": "DE"
            },
            {
                "name": "Wehr",
                "countryCode": "DE"
            },
            {
                "name": "Weidenstetten",
                "countryCode": "DE"
            },
            {
                "name": "Weikersheim",
                "countryCode": "DE"
            },
            {
                "name": "Weil am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Weil der Stadt",
                "countryCode": "DE"
            },
            {
                "name": "Weil im Schönbuch",
                "countryCode": "DE"
            },
            {
                "name": "Weilheim",
                "countryCode": "DE"
            },
            {
                "name": "Weilheim an der Teck",
                "countryCode": "DE"
            },
            {
                "name": "Weingarten",
                "countryCode": "DE"
            },
            {
                "name": "Weinheim",
                "countryCode": "DE"
            },
            {
                "name": "Weinsberg",
                "countryCode": "DE"
            },
            {
                "name": "Weinstadt-Endersbach",
                "countryCode": "DE"
            },
            {
                "name": "Weisenbach",
                "countryCode": "DE"
            },
            {
                "name": "Weissach",
                "countryCode": "DE"
            },
            {
                "name": "Weisweil",
                "countryCode": "DE"
            },
            {
                "name": "Weißbach",
                "countryCode": "DE"
            },
            {
                "name": "Wellendingen",
                "countryCode": "DE"
            },
            {
                "name": "Welzheim",
                "countryCode": "DE"
            },
            {
                "name": "Wendlingen am Neckar",
                "countryCode": "DE"
            },
            {
                "name": "Werbach",
                "countryCode": "DE"
            },
            {
                "name": "Wernau",
                "countryCode": "DE"
            },
            {
                "name": "Wertheim",
                "countryCode": "DE"
            },
            {
                "name": "Westerheim",
                "countryCode": "DE"
            },
            {
                "name": "Westerstetten",
                "countryCode": "DE"
            },
            {
                "name": "Westhausen",
                "countryCode": "DE"
            },
            {
                "name": "Widdern",
                "countryCode": "DE"
            },
            {
                "name": "Wiernsheim",
                "countryCode": "DE"
            },
            {
                "name": "Wiesenbach",
                "countryCode": "DE"
            },
            {
                "name": "Wiesensteig",
                "countryCode": "DE"
            },
            {
                "name": "Wiesloch",
                "countryCode": "DE"
            },
            {
                "name": "Wildberg",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Willstätt",
                "countryCode": "DE"
            },
            {
                "name": "Wimsheim",
                "countryCode": "DE"
            },
            {
                "name": "Winnenden",
                "countryCode": "DE"
            },
            {
                "name": "Winterbach",
                "countryCode": "DE"
            },
            {
                "name": "Winterlingen",
                "countryCode": "DE"
            },
            {
                "name": "Wittnau",
                "countryCode": "DE"
            },
            {
                "name": "Wolfach",
                "countryCode": "DE"
            },
            {
                "name": "Wolfegg",
                "countryCode": "DE"
            },
            {
                "name": "Wolfschlugen",
                "countryCode": "DE"
            },
            {
                "name": "Wolpertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Wolpertswende",
                "countryCode": "DE"
            },
            {
                "name": "Wurmberg",
                "countryCode": "DE"
            },
            {
                "name": "Wurmlingen",
                "countryCode": "DE"
            },
            {
                "name": "Wutöschingen",
                "countryCode": "DE"
            },
            {
                "name": "Wyhl",
                "countryCode": "DE"
            },
            {
                "name": "Wäschenbeuren",
                "countryCode": "DE"
            },
            {
                "name": "Wört",
                "countryCode": "DE"
            },
            {
                "name": "Wössingen",
                "countryCode": "DE"
            },
            {
                "name": "Wüstenrot",
                "countryCode": "DE"
            },
            {
                "name": "Zaberfeld",
                "countryCode": "DE"
            },
            {
                "name": "Zaisenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Zell",
                "countryCode": "DE"
            },
            {
                "name": "Zell im Wiesental",
                "countryCode": "DE"
            },
            {
                "name": "Zell unter Aichelberg",
                "countryCode": "DE"
            },
            {
                "name": "Zimmern ob Rottweil",
                "countryCode": "DE"
            },
            {
                "name": "Zuzenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Zweiflingen",
                "countryCode": "DE"
            },
            {
                "name": "Zwiefalten",
                "countryCode": "DE"
            },
            {
                "name": "Öhningen",
                "countryCode": "DE"
            },
            {
                "name": "Öhringen",
                "countryCode": "DE"
            },
            {
                "name": "Ölbronn-Dürrn",
                "countryCode": "DE"
            },
            {
                "name": "Öpfingen",
                "countryCode": "DE"
            },
            {
                "name": "Östringen",
                "countryCode": "DE"
            },
            {
                "name": "Ötigheim",
                "countryCode": "DE"
            },
            {
                "name": "Ötisheim",
                "countryCode": "DE"
            },
            {
                "name": "Überlingen",
                "countryCode": "DE"
            },
            {
                "name": "Ühlingen-Birkendorf",
                "countryCode": "DE"
            },
            {
                "name": "Abenberg",
                "countryCode": "DE"
            },
            {
                "name": "Abensberg",
                "countryCode": "DE"
            },
            {
                "name": "Absberg",
                "countryCode": "DE"
            },
            {
                "name": "Achslach",
                "countryCode": "DE"
            },
            {
                "name": "Adelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Adelshofen",
                "countryCode": "DE"
            },
            {
                "name": "Adelsried",
                "countryCode": "DE"
            },
            {
                "name": "Adelzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Adlkofen",
                "countryCode": "DE"
            },
            {
                "name": "Affing",
                "countryCode": "DE"
            },
            {
                "name": "Aham",
                "countryCode": "DE"
            },
            {
                "name": "Aholfing",
                "countryCode": "DE"
            },
            {
                "name": "Aholming",
                "countryCode": "DE"
            },
            {
                "name": "Ahorn",
                "countryCode": "DE"
            },
            {
                "name": "Aichach",
                "countryCode": "DE"
            },
            {
                "name": "Aichen",
                "countryCode": "DE"
            },
            {
                "name": "Aidenbach",
                "countryCode": "DE"
            },
            {
                "name": "Aidhausen",
                "countryCode": "DE"
            },
            {
                "name": "Aiglsbach",
                "countryCode": "DE"
            },
            {
                "name": "Aindling",
                "countryCode": "DE"
            },
            {
                "name": "Ainring",
                "countryCode": "DE"
            },
            {
                "name": "Aislingen",
                "countryCode": "DE"
            },
            {
                "name": "Aitrang",
                "countryCode": "DE"
            },
            {
                "name": "Albaching",
                "countryCode": "DE"
            },
            {
                "name": "Albertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Aldersbach",
                "countryCode": "DE"
            },
            {
                "name": "Alerheim",
                "countryCode": "DE"
            },
            {
                "name": "Alesheim",
                "countryCode": "DE"
            },
            {
                "name": "Aletshausen",
                "countryCode": "DE"
            },
            {
                "name": "Alfeld",
                "countryCode": "DE"
            },
            {
                "name": "Allersberg",
                "countryCode": "DE"
            },
            {
                "name": "Allershausen",
                "countryCode": "DE"
            },
            {
                "name": "Alling",
                "countryCode": "DE"
            },
            {
                "name": "Altdorf",
                "countryCode": "DE"
            },
            {
                "name": "Alteglofsheim",
                "countryCode": "DE"
            },
            {
                "name": "Altenbuch",
                "countryCode": "DE"
            },
            {
                "name": "Altendorf",
                "countryCode": "DE"
            },
            {
                "name": "Altenkunstadt",
                "countryCode": "DE"
            },
            {
                "name": "Altenmarkt",
                "countryCode": "DE"
            },
            {
                "name": "Altenmünster",
                "countryCode": "DE"
            },
            {
                "name": "Altenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Altenthann",
                "countryCode": "DE"
            },
            {
                "name": "Alterhofen",
                "countryCode": "DE"
            },
            {
                "name": "Altfraunhofen",
                "countryCode": "DE"
            },
            {
                "name": "Althegnenberg",
                "countryCode": "DE"
            },
            {
                "name": "Altomünster",
                "countryCode": "DE"
            },
            {
                "name": "Altusried",
                "countryCode": "DE"
            },
            {
                "name": "Altötting",
                "countryCode": "DE"
            },
            {
                "name": "Alzenau in Unterfranken",
                "countryCode": "DE"
            },
            {
                "name": "Amberg",
                "countryCode": "DE"
            },
            {
                "name": "Amendingen",
                "countryCode": "DE"
            },
            {
                "name": "Amerang",
                "countryCode": "DE"
            },
            {
                "name": "Ammerndorf",
                "countryCode": "DE"
            },
            {
                "name": "Ammerthal",
                "countryCode": "DE"
            },
            {
                "name": "Ampfing",
                "countryCode": "DE"
            },
            {
                "name": "Andechs",
                "countryCode": "DE"
            },
            {
                "name": "Ansbach",
                "countryCode": "DE"
            },
            {
                "name": "Antdorf",
                "countryCode": "DE"
            },
            {
                "name": "Anzing",
                "countryCode": "DE"
            },
            {
                "name": "Apfeldorf",
                "countryCode": "DE"
            },
            {
                "name": "Arberg",
                "countryCode": "DE"
            },
            {
                "name": "Aresing",
                "countryCode": "DE"
            },
            {
                "name": "Arnbruck",
                "countryCode": "DE"
            },
            {
                "name": "Arnschwang",
                "countryCode": "DE"
            },
            {
                "name": "Arnstein",
                "countryCode": "DE"
            },
            {
                "name": "Arnstorf",
                "countryCode": "DE"
            },
            {
                "name": "Arrach",
                "countryCode": "DE"
            },
            {
                "name": "Arzberg",
                "countryCode": "DE"
            },
            {
                "name": "Asbach-Bäumenheim",
                "countryCode": "DE"
            },
            {
                "name": "Ascha",
                "countryCode": "DE"
            },
            {
                "name": "Aschaffenburg",
                "countryCode": "DE"
            },
            {
                "name": "Aschau am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Aschau im Chiemgau",
                "countryCode": "DE"
            },
            {
                "name": "Aschheim",
                "countryCode": "DE"
            },
            {
                "name": "Attenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Attenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Atting",
                "countryCode": "DE"
            },
            {
                "name": "Au in der Hallertau",
                "countryCode": "DE"
            },
            {
                "name": "Aub",
                "countryCode": "DE"
            },
            {
                "name": "Auerbach",
                "countryCode": "DE"
            },
            {
                "name": "Aufhausen",
                "countryCode": "DE"
            },
            {
                "name": "Aufseß",
                "countryCode": "DE"
            },
            {
                "name": "Augsburg",
                "countryCode": "DE"
            },
            {
                "name": "Auhausen",
                "countryCode": "DE"
            },
            {
                "name": "Aura im Sinngrund",
                "countryCode": "DE"
            },
            {
                "name": "Außernzell",
                "countryCode": "DE"
            },
            {
                "name": "Aying",
                "countryCode": "DE"
            },
            {
                "name": "Aystetten",
                "countryCode": "DE"
            },
            {
                "name": "Aßling",
                "countryCode": "DE"
            },
            {
                "name": "Baar-Ebenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Babenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Babensham",
                "countryCode": "DE"
            },
            {
                "name": "Bach",
                "countryCode": "DE"
            },
            {
                "name": "Bachhagel",
                "countryCode": "DE"
            },
            {
                "name": "Bad Abbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Aibling",
                "countryCode": "DE"
            },
            {
                "name": "Bad Alexandersbad",
                "countryCode": "DE"
            },
            {
                "name": "Bad Berneck im Fichtelgebirge",
                "countryCode": "DE"
            },
            {
                "name": "Bad Birnbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bocklet",
                "countryCode": "DE"
            },
            {
                "name": "Bad Brückenau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Endorf",
                "countryCode": "DE"
            },
            {
                "name": "Bad Feilnbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Füssing",
                "countryCode": "DE"
            },
            {
                "name": "Bad Griesbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Heilbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Bad Kissingen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Kohlgrub",
                "countryCode": "DE"
            },
            {
                "name": "Bad Königshofen im Grabfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bad Neustadt an der Saale",
                "countryCode": "DE"
            },
            {
                "name": "Bad Reichenhall",
                "countryCode": "DE"
            },
            {
                "name": "Bad Staffelstein",
                "countryCode": "DE"
            },
            {
                "name": "Bad Steben",
                "countryCode": "DE"
            },
            {
                "name": "Bad Tölz",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wiessee",
                "countryCode": "DE"
            },
            {
                "name": "Bad Windsheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wörishofen",
                "countryCode": "DE"
            },
            {
                "name": "Baierbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Baiersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Baisweil",
                "countryCode": "DE"
            },
            {
                "name": "Balzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bamberg",
                "countryCode": "DE"
            },
            {
                "name": "Barbing",
                "countryCode": "DE"
            },
            {
                "name": "Bastheim",
                "countryCode": "DE"
            },
            {
                "name": "Baudenbach",
                "countryCode": "DE"
            },
            {
                "name": "Baunach",
                "countryCode": "DE"
            },
            {
                "name": "Bayerbach",
                "countryCode": "DE"
            },
            {
                "name": "Bayerisch Eisenstein",
                "countryCode": "DE"
            },
            {
                "name": "Bayerisch Gmain",
                "countryCode": "DE"
            },
            {
                "name": "Bayreuth",
                "countryCode": "DE"
            },
            {
                "name": "Bayrischzell",
                "countryCode": "DE"
            },
            {
                "name": "Bechtsrieth",
                "countryCode": "DE"
            },
            {
                "name": "Bellenberg",
                "countryCode": "DE"
            },
            {
                "name": "Benediktbeuern",
                "countryCode": "DE"
            },
            {
                "name": "Benningen",
                "countryCode": "DE"
            },
            {
                "name": "Beratzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Berching",
                "countryCode": "DE"
            },
            {
                "name": "Berchtesgaden",
                "countryCode": "DE"
            },
            {
                "name": "Berg",
                "countryCode": "DE"
            },
            {
                "name": "Berg im Gau",
                "countryCode": "DE"
            },
            {
                "name": "Bergen",
                "countryCode": "DE"
            },
            {
                "name": "Bergkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Berglern",
                "countryCode": "DE"
            },
            {
                "name": "Bergrheinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bergtheim",
                "countryCode": "DE"
            },
            {
                "name": "Bernau am Chiemsee",
                "countryCode": "DE"
            },
            {
                "name": "Bernbeuren",
                "countryCode": "DE"
            },
            {
                "name": "Berngau",
                "countryCode": "DE"
            },
            {
                "name": "Bernhardswald",
                "countryCode": "DE"
            },
            {
                "name": "Bernried",
                "countryCode": "DE"
            },
            {
                "name": "Betzigau",
                "countryCode": "DE"
            },
            {
                "name": "Beutelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Biberbach",
                "countryCode": "DE"
            },
            {
                "name": "Biburg",
                "countryCode": "DE"
            },
            {
                "name": "Bichl",
                "countryCode": "DE"
            },
            {
                "name": "Bidingen",
                "countryCode": "DE"
            },
            {
                "name": "Biebelried",
                "countryCode": "DE"
            },
            {
                "name": "Biessenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Bindlach",
                "countryCode": "DE"
            },
            {
                "name": "Binswangen",
                "countryCode": "DE"
            },
            {
                "name": "Birkenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bischberg",
                "countryCode": "DE"
            },
            {
                "name": "Bischbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Bischofsgrün",
                "countryCode": "DE"
            },
            {
                "name": "Bischofsheim an der Rhön",
                "countryCode": "DE"
            },
            {
                "name": "Bischofsmais",
                "countryCode": "DE"
            },
            {
                "name": "Bischofswiesen",
                "countryCode": "DE"
            },
            {
                "name": "Bissingen",
                "countryCode": "DE"
            },
            {
                "name": "Blaibach",
                "countryCode": "DE"
            },
            {
                "name": "Blaichach",
                "countryCode": "DE"
            },
            {
                "name": "Blankenbach",
                "countryCode": "DE"
            },
            {
                "name": "Blindheim",
                "countryCode": "DE"
            },
            {
                "name": "Bobingen",
                "countryCode": "DE"
            },
            {
                "name": "Bockhorn",
                "countryCode": "DE"
            },
            {
                "name": "Bodenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Bodenmais",
                "countryCode": "DE"
            },
            {
                "name": "Bodenwöhr",
                "countryCode": "DE"
            },
            {
                "name": "Bodolz",
                "countryCode": "DE"
            },
            {
                "name": "Bogen",
                "countryCode": "DE"
            },
            {
                "name": "Bogenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bolsterlang",
                "countryCode": "DE"
            },
            {
                "name": "Bonstetten",
                "countryCode": "DE"
            },
            {
                "name": "Boos",
                "countryCode": "DE"
            },
            {
                "name": "Brand",
                "countryCode": "DE"
            },
            {
                "name": "Brannenburg",
                "countryCode": "DE"
            },
            {
                "name": "Breitbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Breitenberg",
                "countryCode": "DE"
            },
            {
                "name": "Breitenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Breitengüßbach",
                "countryCode": "DE"
            },
            {
                "name": "Breitenthal",
                "countryCode": "DE"
            },
            {
                "name": "Brennberg",
                "countryCode": "DE"
            },
            {
                "name": "Bruck",
                "countryCode": "DE"
            },
            {
                "name": "Bruck in der Oberpfalz",
                "countryCode": "DE"
            },
            {
                "name": "Bruckberg",
                "countryCode": "DE"
            },
            {
                "name": "Bruckmühl",
                "countryCode": "DE"
            },
            {
                "name": "Brunn",
                "countryCode": "DE"
            },
            {
                "name": "Brunnen",
                "countryCode": "DE"
            },
            {
                "name": "Brunnthal",
                "countryCode": "DE"
            },
            {
                "name": "Bubenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Bubesheim",
                "countryCode": "DE"
            },
            {
                "name": "Buch",
                "countryCode": "DE"
            },
            {
                "name": "Buch am Buchrain",
                "countryCode": "DE"
            },
            {
                "name": "Buchbach",
                "countryCode": "DE"
            },
            {
                "name": "Buchbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Buchdorf",
                "countryCode": "DE"
            },
            {
                "name": "Buchenberg",
                "countryCode": "DE"
            },
            {
                "name": "Buchloe",
                "countryCode": "DE"
            },
            {
                "name": "Buckenhof",
                "countryCode": "DE"
            },
            {
                "name": "Burgau",
                "countryCode": "DE"
            },
            {
                "name": "Burgberg",
                "countryCode": "DE"
            },
            {
                "name": "Burgbernheim",
                "countryCode": "DE"
            },
            {
                "name": "Burgebrach",
                "countryCode": "DE"
            },
            {
                "name": "Burggen",
                "countryCode": "DE"
            },
            {
                "name": "Burghaslach",
                "countryCode": "DE"
            },
            {
                "name": "Burghausen",
                "countryCode": "DE"
            },
            {
                "name": "Burgheim",
                "countryCode": "DE"
            },
            {
                "name": "Burgkirchen an der Alz",
                "countryCode": "DE"
            },
            {
                "name": "Burgkunstadt",
                "countryCode": "DE"
            },
            {
                "name": "Burglauer",
                "countryCode": "DE"
            },
            {
                "name": "Burglengenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Burgoberbach",
                "countryCode": "DE"
            },
            {
                "name": "Burgpreppach",
                "countryCode": "DE"
            },
            {
                "name": "Burgsalach",
                "countryCode": "DE"
            },
            {
                "name": "Burgsinn",
                "countryCode": "DE"
            },
            {
                "name": "Burgthann",
                "countryCode": "DE"
            },
            {
                "name": "Burgwindheim",
                "countryCode": "DE"
            },
            {
                "name": "Burk",
                "countryCode": "DE"
            },
            {
                "name": "Burkardroth",
                "countryCode": "DE"
            },
            {
                "name": "Burtenbach",
                "countryCode": "DE"
            },
            {
                "name": "Buttenheim",
                "countryCode": "DE"
            },
            {
                "name": "Buttenwiesen",
                "countryCode": "DE"
            },
            {
                "name": "Buxheim",
                "countryCode": "DE"
            },
            {
                "name": "Bächingen an der Brenz",
                "countryCode": "DE"
            },
            {
                "name": "Bärnau",
                "countryCode": "DE"
            },
            {
                "name": "Böbing",
                "countryCode": "DE"
            },
            {
                "name": "Böbrach",
                "countryCode": "DE"
            },
            {
                "name": "Büchenbach",
                "countryCode": "DE"
            },
            {
                "name": "Büchlberg",
                "countryCode": "DE"
            },
            {
                "name": "Bürgstadt",
                "countryCode": "DE"
            },
            {
                "name": "Bütthard",
                "countryCode": "DE"
            },
            {
                "name": "Cadolzburg",
                "countryCode": "DE"
            },
            {
                "name": "Cham",
                "countryCode": "DE"
            },
            {
                "name": "Chamerau",
                "countryCode": "DE"
            },
            {
                "name": "Chieming",
                "countryCode": "DE"
            },
            {
                "name": "Chostlarn",
                "countryCode": "DE"
            },
            {
                "name": "Coburg",
                "countryCode": "DE"
            },
            {
                "name": "Colmberg",
                "countryCode": "DE"
            },
            {
                "name": "Creußen",
                "countryCode": "DE"
            },
            {
                "name": "Dachau",
                "countryCode": "DE"
            },
            {
                "name": "Dasing",
                "countryCode": "DE"
            },
            {
                "name": "Deggendorf",
                "countryCode": "DE"
            },
            {
                "name": "Deining",
                "countryCode": "DE"
            },
            {
                "name": "Deiningen",
                "countryCode": "DE"
            },
            {
                "name": "Deisenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Denklingen",
                "countryCode": "DE"
            },
            {
                "name": "Dentlein am Forst",
                "countryCode": "DE"
            },
            {
                "name": "Dettelbach",
                "countryCode": "DE"
            },
            {
                "name": "Deuerling",
                "countryCode": "DE"
            },
            {
                "name": "Diebach",
                "countryCode": "DE"
            },
            {
                "name": "Diedorf",
                "countryCode": "DE"
            },
            {
                "name": "Diespeck",
                "countryCode": "DE"
            },
            {
                "name": "Dietenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Dietersburg",
                "countryCode": "DE"
            },
            {
                "name": "Dietersheim",
                "countryCode": "DE"
            },
            {
                "name": "Dieterskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Dietfurt",
                "countryCode": "DE"
            },
            {
                "name": "Dietmannsried",
                "countryCode": "DE"
            },
            {
                "name": "Dietramszell",
                "countryCode": "DE"
            },
            {
                "name": "Dießen am Ammersee",
                "countryCode": "DE"
            },
            {
                "name": "Dillingen an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Dingolfing",
                "countryCode": "DE"
            },
            {
                "name": "Dingolshausen",
                "countryCode": "DE"
            },
            {
                "name": "Dinkelsbühl",
                "countryCode": "DE"
            },
            {
                "name": "Dinkelscherben",
                "countryCode": "DE"
            },
            {
                "name": "Dirlewang",
                "countryCode": "DE"
            },
            {
                "name": "Dittelbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Dittenheim",
                "countryCode": "DE"
            },
            {
                "name": "Dombühl",
                "countryCode": "DE"
            },
            {
                "name": "Donaustauf",
                "countryCode": "DE"
            },
            {
                "name": "Donauwörth",
                "countryCode": "DE"
            },
            {
                "name": "Donnersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dorfen",
                "countryCode": "DE"
            },
            {
                "name": "Dorfprozelten",
                "countryCode": "DE"
            },
            {
                "name": "Dormitz",
                "countryCode": "DE"
            },
            {
                "name": "Drachselsried",
                "countryCode": "DE"
            },
            {
                "name": "Duggendorf",
                "countryCode": "DE"
            },
            {
                "name": "Durach",
                "countryCode": "DE"
            },
            {
                "name": "Döhlau",
                "countryCode": "DE"
            },
            {
                "name": "Dürrlauingen",
                "countryCode": "DE"
            },
            {
                "name": "Dürrwangen",
                "countryCode": "DE"
            },
            {
                "name": "Ebelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ebensfeld",
                "countryCode": "DE"
            },
            {
                "name": "Ebermannsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ebermannstadt",
                "countryCode": "DE"
            },
            {
                "name": "Ebersberg",
                "countryCode": "DE"
            },
            {
                "name": "Ebersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ebnath",
                "countryCode": "DE"
            },
            {
                "name": "Eching",
                "countryCode": "DE"
            },
            {
                "name": "Eckersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Edelsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Ederheim",
                "countryCode": "DE"
            },
            {
                "name": "Effeltrich",
                "countryCode": "DE"
            },
            {
                "name": "Egenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Egg an der Günz",
                "countryCode": "DE"
            },
            {
                "name": "Eggenfelden",
                "countryCode": "DE"
            },
            {
                "name": "Eggenthal",
                "countryCode": "DE"
            },
            {
                "name": "Egglham",
                "countryCode": "DE"
            },
            {
                "name": "Egglkofen",
                "countryCode": "DE"
            },
            {
                "name": "Eggolsheim",
                "countryCode": "DE"
            },
            {
                "name": "Eggstätt",
                "countryCode": "DE"
            },
            {
                "name": "Eging",
                "countryCode": "DE"
            },
            {
                "name": "Egling",
                "countryCode": "DE"
            },
            {
                "name": "Egloffstein",
                "countryCode": "DE"
            },
            {
                "name": "Egmating",
                "countryCode": "DE"
            },
            {
                "name": "Ehekirchen",
                "countryCode": "DE"
            },
            {
                "name": "Ehingen",
                "countryCode": "DE"
            },
            {
                "name": "Eibelstadt",
                "countryCode": "DE"
            },
            {
                "name": "Eichenau",
                "countryCode": "DE"
            },
            {
                "name": "Eichenbühl",
                "countryCode": "DE"
            },
            {
                "name": "Eichendorf",
                "countryCode": "DE"
            },
            {
                "name": "Eichenried",
                "countryCode": "DE"
            },
            {
                "name": "Eichstätt",
                "countryCode": "DE"
            },
            {
                "name": "Eiselfing",
                "countryCode": "DE"
            },
            {
                "name": "Eisenburg",
                "countryCode": "DE"
            },
            {
                "name": "Eisingen",
                "countryCode": "DE"
            },
            {
                "name": "Eitting",
                "countryCode": "DE"
            },
            {
                "name": "Elfershausen",
                "countryCode": "DE"
            },
            {
                "name": "Ellgau",
                "countryCode": "DE"
            },
            {
                "name": "Ellingen",
                "countryCode": "DE"
            },
            {
                "name": "Ellzee",
                "countryCode": "DE"
            },
            {
                "name": "Elsendorf",
                "countryCode": "DE"
            },
            {
                "name": "Elsenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Eltmann",
                "countryCode": "DE"
            },
            {
                "name": "Emersacker",
                "countryCode": "DE"
            },
            {
                "name": "Emmering",
                "countryCode": "DE"
            },
            {
                "name": "Emskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Emtmannsberg",
                "countryCode": "DE"
            },
            {
                "name": "Engelsberg",
                "countryCode": "DE"
            },
            {
                "name": "Engelthal",
                "countryCode": "DE"
            },
            {
                "name": "Ensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Eppishausen",
                "countryCode": "DE"
            },
            {
                "name": "Erbendorf",
                "countryCode": "DE"
            },
            {
                "name": "Erding",
                "countryCode": "DE"
            },
            {
                "name": "Erdweg",
                "countryCode": "DE"
            },
            {
                "name": "Eresing",
                "countryCode": "DE"
            },
            {
                "name": "Ergersheim",
                "countryCode": "DE"
            },
            {
                "name": "Ergolding",
                "countryCode": "DE"
            },
            {
                "name": "Ergoldsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ering",
                "countryCode": "DE"
            },
            {
                "name": "Erkheim",
                "countryCode": "DE"
            },
            {
                "name": "Erlabrunn",
                "countryCode": "DE"
            },
            {
                "name": "Erlangen",
                "countryCode": "DE"
            },
            {
                "name": "Erlbach",
                "countryCode": "DE"
            },
            {
                "name": "Erlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Erlenbach am Main",
                "countryCode": "DE"
            },
            {
                "name": "Ernsgaden",
                "countryCode": "DE"
            },
            {
                "name": "Eschau",
                "countryCode": "DE"
            },
            {
                "name": "Eschenlohe",
                "countryCode": "DE"
            },
            {
                "name": "Eschlkam",
                "countryCode": "DE"
            },
            {
                "name": "Eslarn",
                "countryCode": "DE"
            },
            {
                "name": "Esselbach",
                "countryCode": "DE"
            },
            {
                "name": "Essenbach",
                "countryCode": "DE"
            },
            {
                "name": "Essing",
                "countryCode": "DE"
            },
            {
                "name": "Estenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Ettringen",
                "countryCode": "DE"
            },
            {
                "name": "Etzelwang",
                "countryCode": "DE"
            },
            {
                "name": "Etzenricht",
                "countryCode": "DE"
            },
            {
                "name": "Euerbach",
                "countryCode": "DE"
            },
            {
                "name": "Euerdorf",
                "countryCode": "DE"
            },
            {
                "name": "Eurasburg",
                "countryCode": "DE"
            },
            {
                "name": "Eußenheim",
                "countryCode": "DE"
            },
            {
                "name": "Fahrenzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Falkenberg",
                "countryCode": "DE"
            },
            {
                "name": "Falkenfels",
                "countryCode": "DE"
            },
            {
                "name": "Falkenstein",
                "countryCode": "DE"
            },
            {
                "name": "Farchant",
                "countryCode": "DE"
            },
            {
                "name": "Faulbach",
                "countryCode": "DE"
            },
            {
                "name": "Feilitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Feldafing",
                "countryCode": "DE"
            },
            {
                "name": "Feldkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Feldkirchen-Westerham",
                "countryCode": "DE"
            },
            {
                "name": "Fellheim",
                "countryCode": "DE"
            },
            {
                "name": "Feucht",
                "countryCode": "DE"
            },
            {
                "name": "Feuchtwangen",
                "countryCode": "DE"
            },
            {
                "name": "Fichtelberg",
                "countryCode": "DE"
            },
            {
                "name": "Finningen",
                "countryCode": "DE"
            },
            {
                "name": "Finsing",
                "countryCode": "DE"
            },
            {
                "name": "Fischach",
                "countryCode": "DE"
            },
            {
                "name": "Fischbachau",
                "countryCode": "DE"
            },
            {
                "name": "Flachslanden",
                "countryCode": "DE"
            },
            {
                "name": "Fladungen",
                "countryCode": "DE"
            },
            {
                "name": "Flintsbach",
                "countryCode": "DE"
            },
            {
                "name": "Flossenbürg",
                "countryCode": "DE"
            },
            {
                "name": "Floß",
                "countryCode": "DE"
            },
            {
                "name": "Forchheim",
                "countryCode": "DE"
            },
            {
                "name": "Forstinning",
                "countryCode": "DE"
            },
            {
                "name": "Frammersbach",
                "countryCode": "DE"
            },
            {
                "name": "Frankenwinheim",
                "countryCode": "DE"
            },
            {
                "name": "Frasdorf",
                "countryCode": "DE"
            },
            {
                "name": "Frauenau",
                "countryCode": "DE"
            },
            {
                "name": "Fraueneuharting",
                "countryCode": "DE"
            },
            {
                "name": "Fraunberg",
                "countryCode": "DE"
            },
            {
                "name": "Freihung",
                "countryCode": "DE"
            },
            {
                "name": "Freilassing",
                "countryCode": "DE"
            },
            {
                "name": "Freising",
                "countryCode": "DE"
            },
            {
                "name": "Fremdingen",
                "countryCode": "DE"
            },
            {
                "name": "Frensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Freudenberg",
                "countryCode": "DE"
            },
            {
                "name": "Freystadt",
                "countryCode": "DE"
            },
            {
                "name": "Freyung",
                "countryCode": "DE"
            },
            {
                "name": "Frickenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Fridolfing",
                "countryCode": "DE"
            },
            {
                "name": "Friedberg",
                "countryCode": "DE"
            },
            {
                "name": "Friedenfels",
                "countryCode": "DE"
            },
            {
                "name": "Friesenried",
                "countryCode": "DE"
            },
            {
                "name": "Frontenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Fuchsmühl",
                "countryCode": "DE"
            },
            {
                "name": "Fuchsstadt",
                "countryCode": "DE"
            },
            {
                "name": "Furth",
                "countryCode": "DE"
            },
            {
                "name": "Furth im Wald",
                "countryCode": "DE"
            },
            {
                "name": "Fünfstetten",
                "countryCode": "DE"
            },
            {
                "name": "Fürsteneck",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenfeldbruck",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenstein",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenzell",
                "countryCode": "DE"
            },
            {
                "name": "Füssen",
                "countryCode": "DE"
            },
            {
                "name": "Gablingen",
                "countryCode": "DE"
            },
            {
                "name": "Gachenbach",
                "countryCode": "DE"
            },
            {
                "name": "Gadheim",
                "countryCode": "DE"
            },
            {
                "name": "Gaimersheim",
                "countryCode": "DE"
            },
            {
                "name": "Gaißach",
                "countryCode": "DE"
            },
            {
                "name": "Gammelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Gangkofen",
                "countryCode": "DE"
            },
            {
                "name": "Garching an der Alz",
                "countryCode": "DE"
            },
            {
                "name": "Garching bei München",
                "countryCode": "DE"
            },
            {
                "name": "Garmisch-Partenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Gars",
                "countryCode": "DE"
            },
            {
                "name": "Gattendorf",
                "countryCode": "DE"
            },
            {
                "name": "Gaukönigshofen",
                "countryCode": "DE"
            },
            {
                "name": "Gauting",
                "countryCode": "DE"
            },
            {
                "name": "Gebsattel",
                "countryCode": "DE"
            },
            {
                "name": "Gefrees",
                "countryCode": "DE"
            },
            {
                "name": "Geiersthal",
                "countryCode": "DE"
            },
            {
                "name": "Geiselbach",
                "countryCode": "DE"
            },
            {
                "name": "Geiselhöring",
                "countryCode": "DE"
            },
            {
                "name": "Geiselwind",
                "countryCode": "DE"
            },
            {
                "name": "Geisenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Geisenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Geldersheim",
                "countryCode": "DE"
            },
            {
                "name": "Geltendorf",
                "countryCode": "DE"
            },
            {
                "name": "Gemünden am Main",
                "countryCode": "DE"
            },
            {
                "name": "Genderkingen",
                "countryCode": "DE"
            },
            {
                "name": "Georgenberg",
                "countryCode": "DE"
            },
            {
                "name": "Georgensgmünd",
                "countryCode": "DE"
            },
            {
                "name": "Gerach",
                "countryCode": "DE"
            },
            {
                "name": "Gerbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Geretsried",
                "countryCode": "DE"
            },
            {
                "name": "Gerhardshofen",
                "countryCode": "DE"
            },
            {
                "name": "Germering",
                "countryCode": "DE"
            },
            {
                "name": "Geroldsgrün",
                "countryCode": "DE"
            },
            {
                "name": "Geroldshausen",
                "countryCode": "DE"
            },
            {
                "name": "Gerolfingen",
                "countryCode": "DE"
            },
            {
                "name": "Gerolsbach",
                "countryCode": "DE"
            },
            {
                "name": "Gerolzhofen",
                "countryCode": "DE"
            },
            {
                "name": "Gersthofen",
                "countryCode": "DE"
            },
            {
                "name": "Gerzen",
                "countryCode": "DE"
            },
            {
                "name": "Gesees",
                "countryCode": "DE"
            },
            {
                "name": "Geslau",
                "countryCode": "DE"
            },
            {
                "name": "Gessertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Gestratz",
                "countryCode": "DE"
            },
            {
                "name": "Giebelstadt",
                "countryCode": "DE"
            },
            {
                "name": "Gilching",
                "countryCode": "DE"
            },
            {
                "name": "Glashütten",
                "countryCode": "DE"
            },
            {
                "name": "Glattbach",
                "countryCode": "DE"
            },
            {
                "name": "Glonn",
                "countryCode": "DE"
            },
            {
                "name": "Glött",
                "countryCode": "DE"
            },
            {
                "name": "Gmund am Tegernsee",
                "countryCode": "DE"
            },
            {
                "name": "Gochsheim",
                "countryCode": "DE"
            },
            {
                "name": "Goldbach",
                "countryCode": "DE"
            },
            {
                "name": "Goldkronach",
                "countryCode": "DE"
            },
            {
                "name": "Gotteszell",
                "countryCode": "DE"
            },
            {
                "name": "Gottfrieding",
                "countryCode": "DE"
            },
            {
                "name": "Graben",
                "countryCode": "DE"
            },
            {
                "name": "Grabenstätt",
                "countryCode": "DE"
            },
            {
                "name": "Grafenau",
                "countryCode": "DE"
            },
            {
                "name": "Grafengehaig",
                "countryCode": "DE"
            },
            {
                "name": "Grafenrheinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Grafenwiesen",
                "countryCode": "DE"
            },
            {
                "name": "Grafenwöhr",
                "countryCode": "DE"
            },
            {
                "name": "Grafing bei München",
                "countryCode": "DE"
            },
            {
                "name": "Grafrath",
                "countryCode": "DE"
            },
            {
                "name": "Grainau",
                "countryCode": "DE"
            },
            {
                "name": "Grainet",
                "countryCode": "DE"
            },
            {
                "name": "Grasbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Grassau",
                "countryCode": "DE"
            },
            {
                "name": "Grattersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Greding",
                "countryCode": "DE"
            },
            {
                "name": "Greifenberg",
                "countryCode": "DE"
            },
            {
                "name": "Greiling",
                "countryCode": "DE"
            },
            {
                "name": "Gremsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Grettstadt",
                "countryCode": "DE"
            },
            {
                "name": "Greußenheim",
                "countryCode": "DE"
            },
            {
                "name": "Griesstätt",
                "countryCode": "DE"
            },
            {
                "name": "Großaitingen",
                "countryCode": "DE"
            },
            {
                "name": "Großbardorf",
                "countryCode": "DE"
            },
            {
                "name": "Großeibstadt",
                "countryCode": "DE"
            },
            {
                "name": "Großenseebach",
                "countryCode": "DE"
            },
            {
                "name": "Großhabersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großheirath",
                "countryCode": "DE"
            },
            {
                "name": "Großheubach",
                "countryCode": "DE"
            },
            {
                "name": "Großkarolinenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Großlangheim",
                "countryCode": "DE"
            },
            {
                "name": "Großostheim",
                "countryCode": "DE"
            },
            {
                "name": "Großreuth bei Schweinau",
                "countryCode": "DE"
            },
            {
                "name": "Großwallstadt",
                "countryCode": "DE"
            },
            {
                "name": "Großweil",
                "countryCode": "DE"
            },
            {
                "name": "Grub",
                "countryCode": "DE"
            },
            {
                "name": "Gräfelfing",
                "countryCode": "DE"
            },
            {
                "name": "Gräfenberg",
                "countryCode": "DE"
            },
            {
                "name": "Gräfendorf",
                "countryCode": "DE"
            },
            {
                "name": "Gröbenzell",
                "countryCode": "DE"
            },
            {
                "name": "Grünenbach",
                "countryCode": "DE"
            },
            {
                "name": "Grünwald",
                "countryCode": "DE"
            },
            {
                "name": "Gstadt am Chiemsee",
                "countryCode": "DE"
            },
            {
                "name": "Gundelfingen",
                "countryCode": "DE"
            },
            {
                "name": "Gundelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gundremmingen",
                "countryCode": "DE"
            },
            {
                "name": "Gunzenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Gutenstetten",
                "countryCode": "DE"
            },
            {
                "name": "Görisried",
                "countryCode": "DE"
            },
            {
                "name": "Gössenheim",
                "countryCode": "DE"
            },
            {
                "name": "Gößweinstein",
                "countryCode": "DE"
            },
            {
                "name": "Güntersleben",
                "countryCode": "DE"
            },
            {
                "name": "Günzach",
                "countryCode": "DE"
            },
            {
                "name": "Günzburg",
                "countryCode": "DE"
            },
            {
                "name": "Haag an der Amper",
                "countryCode": "DE"
            },
            {
                "name": "Haag in Oberbayern",
                "countryCode": "DE"
            },
            {
                "name": "Haar",
                "countryCode": "DE"
            },
            {
                "name": "Haarbach",
                "countryCode": "DE"
            },
            {
                "name": "Habach",
                "countryCode": "DE"
            },
            {
                "name": "Hafenlohr",
                "countryCode": "DE"
            },
            {
                "name": "Hagelstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hagenbüchach",
                "countryCode": "DE"
            },
            {
                "name": "Hahnbach",
                "countryCode": "DE"
            },
            {
                "name": "Haibach",
                "countryCode": "DE"
            },
            {
                "name": "Haidmühle",
                "countryCode": "DE"
            },
            {
                "name": "Haimhausen",
                "countryCode": "DE"
            },
            {
                "name": "Haiming",
                "countryCode": "DE"
            },
            {
                "name": "Hainsfarth",
                "countryCode": "DE"
            },
            {
                "name": "Halblech",
                "countryCode": "DE"
            },
            {
                "name": "Haldenwang",
                "countryCode": "DE"
            },
            {
                "name": "Halfing",
                "countryCode": "DE"
            },
            {
                "name": "Hallbergmoos",
                "countryCode": "DE"
            },
            {
                "name": "Hallerndorf",
                "countryCode": "DE"
            },
            {
                "name": "Hallstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hammelburg",
                "countryCode": "DE"
            },
            {
                "name": "Happurg",
                "countryCode": "DE"
            },
            {
                "name": "Harburg",
                "countryCode": "DE"
            },
            {
                "name": "Harsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hartenstein",
                "countryCode": "DE"
            },
            {
                "name": "Haselbach",
                "countryCode": "DE"
            },
            {
                "name": "Hasloch",
                "countryCode": "DE"
            },
            {
                "name": "Hattenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Haundorf",
                "countryCode": "DE"
            },
            {
                "name": "Haunsheim",
                "countryCode": "DE"
            },
            {
                "name": "Hausen",
                "countryCode": "DE"
            },
            {
                "name": "Hausham",
                "countryCode": "DE"
            },
            {
                "name": "Hauzenberg",
                "countryCode": "DE"
            },
            {
                "name": "Hawangen",
                "countryCode": "DE"
            },
            {
                "name": "Haßfurt",
                "countryCode": "DE"
            },
            {
                "name": "Hebertsfelden",
                "countryCode": "DE"
            },
            {
                "name": "Hebertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Heideck",
                "countryCode": "DE"
            },
            {
                "name": "Heidenheim",
                "countryCode": "DE"
            },
            {
                "name": "Heigenbrücken",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Heilsbronn",
                "countryCode": "DE"
            },
            {
                "name": "Heimbuchenthal",
                "countryCode": "DE"
            },
            {
                "name": "Heimenkirch",
                "countryCode": "DE"
            },
            {
                "name": "Heimertingen",
                "countryCode": "DE"
            },
            {
                "name": "Helmbrechts",
                "countryCode": "DE"
            },
            {
                "name": "Helmstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hemau",
                "countryCode": "DE"
            },
            {
                "name": "Hemhofen",
                "countryCode": "DE"
            },
            {
                "name": "Hendungen",
                "countryCode": "DE"
            },
            {
                "name": "Henfenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Hengersberg",
                "countryCode": "DE"
            },
            {
                "name": "Heretsried",
                "countryCode": "DE"
            },
            {
                "name": "Hergensweiler",
                "countryCode": "DE"
            },
            {
                "name": "Heroldsbach",
                "countryCode": "DE"
            },
            {
                "name": "Heroldsberg",
                "countryCode": "DE"
            },
            {
                "name": "Herrieden",
                "countryCode": "DE"
            },
            {
                "name": "Herrngiersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herrsching am Ammersee",
                "countryCode": "DE"
            },
            {
                "name": "Hersbruck",
                "countryCode": "DE"
            },
            {
                "name": "Herzogenaurach",
                "countryCode": "DE"
            },
            {
                "name": "Hettenshausen",
                "countryCode": "DE"
            },
            {
                "name": "Hettstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hetzles",
                "countryCode": "DE"
            },
            {
                "name": "Heustreu",
                "countryCode": "DE"
            },
            {
                "name": "Heßdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hilgertshausen-Tandern",
                "countryCode": "DE"
            },
            {
                "name": "Hilpoltstein",
                "countryCode": "DE"
            },
            {
                "name": "Hiltenfingen",
                "countryCode": "DE"
            },
            {
                "name": "Hiltpoltstein",
                "countryCode": "DE"
            },
            {
                "name": "Himmelkron",
                "countryCode": "DE"
            },
            {
                "name": "Himmelstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hinterschmiding",
                "countryCode": "DE"
            },
            {
                "name": "Hirschaid",
                "countryCode": "DE"
            },
            {
                "name": "Hirschau",
                "countryCode": "DE"
            },
            {
                "name": "Hirschbach",
                "countryCode": "DE"
            },
            {
                "name": "Hochstadt am Main",
                "countryCode": "DE"
            },
            {
                "name": "Hof",
                "countryCode": "DE"
            },
            {
                "name": "Hofheim in Unterfranken",
                "countryCode": "DE"
            },
            {
                "name": "Hofkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Hofstetten",
                "countryCode": "DE"
            },
            {
                "name": "Hohenau",
                "countryCode": "DE"
            },
            {
                "name": "Hohenberg an der Eger",
                "countryCode": "DE"
            },
            {
                "name": "Hohenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Hohenburg",
                "countryCode": "DE"
            },
            {
                "name": "Hohenfels",
                "countryCode": "DE"
            },
            {
                "name": "Hohenfurch",
                "countryCode": "DE"
            },
            {
                "name": "Hohenkammer",
                "countryCode": "DE"
            },
            {
                "name": "Hohenlinden",
                "countryCode": "DE"
            },
            {
                "name": "Hohenpeißenberg",
                "countryCode": "DE"
            },
            {
                "name": "Hohenpolding",
                "countryCode": "DE"
            },
            {
                "name": "Hohenthann",
                "countryCode": "DE"
            },
            {
                "name": "Hohenwarth",
                "countryCode": "DE"
            },
            {
                "name": "Hollenbach",
                "countryCode": "DE"
            },
            {
                "name": "Hollfeld",
                "countryCode": "DE"
            },
            {
                "name": "Hollstadt",
                "countryCode": "DE"
            },
            {
                "name": "Holzgünz",
                "countryCode": "DE"
            },
            {
                "name": "Holzheim",
                "countryCode": "DE"
            },
            {
                "name": "Holzkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Hopferau",
                "countryCode": "DE"
            },
            {
                "name": "Horgau",
                "countryCode": "DE"
            },
            {
                "name": "Huglfing",
                "countryCode": "DE"
            },
            {
                "name": "Huisheim",
                "countryCode": "DE"
            },
            {
                "name": "Hunderdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hunding",
                "countryCode": "DE"
            },
            {
                "name": "Hurlach",
                "countryCode": "DE"
            },
            {
                "name": "Hutthurm",
                "countryCode": "DE"
            },
            {
                "name": "Höchberg",
                "countryCode": "DE"
            },
            {
                "name": "Höchheim",
                "countryCode": "DE"
            },
            {
                "name": "Höchstadt an der Aisch",
                "countryCode": "DE"
            },
            {
                "name": "Höchstädt an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Höchstädt bei Thiersheim",
                "countryCode": "DE"
            },
            {
                "name": "Höhenkirchen-Siegertsbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Hörgertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Hösbach",
                "countryCode": "DE"
            },
            {
                "name": "Höslwang",
                "countryCode": "DE"
            },
            {
                "name": "Höttingen",
                "countryCode": "DE"
            },
            {
                "name": "Ichenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Icking",
                "countryCode": "DE"
            },
            {
                "name": "Iffeldorf",
                "countryCode": "DE"
            },
            {
                "name": "Igensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Iggensbach",
                "countryCode": "DE"
            },
            {
                "name": "Ihrlerstein",
                "countryCode": "DE"
            },
            {
                "name": "Illertissen",
                "countryCode": "DE"
            },
            {
                "name": "Illschwang",
                "countryCode": "DE"
            },
            {
                "name": "Ilmmünster",
                "countryCode": "DE"
            },
            {
                "name": "Immenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Immenstadt im Allgäu",
                "countryCode": "DE"
            },
            {
                "name": "Inchenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ingolstadt",
                "countryCode": "DE"
            },
            {
                "name": "Innernzell",
                "countryCode": "DE"
            },
            {
                "name": "Inning am Ammersee",
                "countryCode": "DE"
            },
            {
                "name": "Inning am Holz",
                "countryCode": "DE"
            },
            {
                "name": "Insingen",
                "countryCode": "DE"
            },
            {
                "name": "Inzell",
                "countryCode": "DE"
            },
            {
                "name": "Iphofen",
                "countryCode": "DE"
            },
            {
                "name": "Ippesheim",
                "countryCode": "DE"
            },
            {
                "name": "Ipsheim",
                "countryCode": "DE"
            },
            {
                "name": "Irchenrieth",
                "countryCode": "DE"
            },
            {
                "name": "Irlbach",
                "countryCode": "DE"
            },
            {
                "name": "Irschenberg",
                "countryCode": "DE"
            },
            {
                "name": "Irsee",
                "countryCode": "DE"
            },
            {
                "name": "Isen",
                "countryCode": "DE"
            },
            {
                "name": "Ismaning",
                "countryCode": "DE"
            },
            {
                "name": "Jandelsbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Jengen",
                "countryCode": "DE"
            },
            {
                "name": "Jesenwang",
                "countryCode": "DE"
            },
            {
                "name": "Jettingen-Scheppach",
                "countryCode": "DE"
            },
            {
                "name": "Jetzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Johannesberg",
                "countryCode": "DE"
            },
            {
                "name": "Johanniskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Julbach",
                "countryCode": "DE"
            },
            {
                "name": "Kahl am Main",
                "countryCode": "DE"
            },
            {
                "name": "Kaisheim",
                "countryCode": "DE"
            },
            {
                "name": "Kalchreuth",
                "countryCode": "DE"
            },
            {
                "name": "Kallmünz",
                "countryCode": "DE"
            },
            {
                "name": "Kammerstein",
                "countryCode": "DE"
            },
            {
                "name": "Karbach",
                "countryCode": "DE"
            },
            {
                "name": "Karlsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Karlshuld",
                "countryCode": "DE"
            },
            {
                "name": "Karlskron",
                "countryCode": "DE"
            },
            {
                "name": "Karlstadt",
                "countryCode": "DE"
            },
            {
                "name": "Karsbach",
                "countryCode": "DE"
            },
            {
                "name": "Kasendorf",
                "countryCode": "DE"
            },
            {
                "name": "Kastl",
                "countryCode": "DE"
            },
            {
                "name": "Kaufbeuren",
                "countryCode": "DE"
            },
            {
                "name": "Kaufering",
                "countryCode": "DE"
            },
            {
                "name": "Kelheim",
                "countryCode": "DE"
            },
            {
                "name": "Kellmünz",
                "countryCode": "DE"
            },
            {
                "name": "Kemmern",
                "countryCode": "DE"
            },
            {
                "name": "Kemnath",
                "countryCode": "DE"
            },
            {
                "name": "Kempten (Allgäu)",
                "countryCode": "DE"
            },
            {
                "name": "Kettershausen",
                "countryCode": "DE"
            },
            {
                "name": "Kiefersfelden",
                "countryCode": "DE"
            },
            {
                "name": "Kienberg",
                "countryCode": "DE"
            },
            {
                "name": "Kirchanschöring",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg",
                "countryCode": "DE"
            },
            {
                "name": "Kirchdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kirchdorf am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Kirchdorf im Wald",
                "countryCode": "DE"
            },
            {
                "name": "Kirchehrenbach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchenlamitz",
                "countryCode": "DE"
            },
            {
                "name": "Kirchenpingarten",
                "countryCode": "DE"
            },
            {
                "name": "Kirchensittenbach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchenthumbach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchham",
                "countryCode": "DE"
            },
            {
                "name": "Kirchhaslach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim bei München",
                "countryCode": "DE"
            },
            {
                "name": "Kirchlauter",
                "countryCode": "DE"
            },
            {
                "name": "Kirchroth",
                "countryCode": "DE"
            },
            {
                "name": "Kirchseeon",
                "countryCode": "DE"
            },
            {
                "name": "Kirchweidach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchzell",
                "countryCode": "DE"
            },
            {
                "name": "Kissing",
                "countryCode": "DE"
            },
            {
                "name": "Kist",
                "countryCode": "DE"
            },
            {
                "name": "Kitzingen",
                "countryCode": "DE"
            },
            {
                "name": "Kleinaitingen",
                "countryCode": "DE"
            },
            {
                "name": "Kleinheubach",
                "countryCode": "DE"
            },
            {
                "name": "Kleinkahl",
                "countryCode": "DE"
            },
            {
                "name": "Kleinlangheim",
                "countryCode": "DE"
            },
            {
                "name": "Kleinostheim",
                "countryCode": "DE"
            },
            {
                "name": "Kleinrinderfeld",
                "countryCode": "DE"
            },
            {
                "name": "Kleinsendelbach",
                "countryCode": "DE"
            },
            {
                "name": "Kleinwallstadt",
                "countryCode": "DE"
            },
            {
                "name": "Klingenberg am Main",
                "countryCode": "DE"
            },
            {
                "name": "Klosterlechfeld",
                "countryCode": "DE"
            },
            {
                "name": "Knetzgau",
                "countryCode": "DE"
            },
            {
                "name": "Kochel",
                "countryCode": "DE"
            },
            {
                "name": "Kohlberg",
                "countryCode": "DE"
            },
            {
                "name": "Kolbermoor",
                "countryCode": "DE"
            },
            {
                "name": "Kolitzheim",
                "countryCode": "DE"
            },
            {
                "name": "Kollnburg",
                "countryCode": "DE"
            },
            {
                "name": "Konradsreuth",
                "countryCode": "DE"
            },
            {
                "name": "Konzell",
                "countryCode": "DE"
            },
            {
                "name": "Kottgeisering",
                "countryCode": "DE"
            },
            {
                "name": "Kraiburg am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Krailling",
                "countryCode": "DE"
            },
            {
                "name": "Kreut",
                "countryCode": "DE"
            },
            {
                "name": "Kreuzwertheim",
                "countryCode": "DE"
            },
            {
                "name": "Krombach",
                "countryCode": "DE"
            },
            {
                "name": "Kronach",
                "countryCode": "DE"
            },
            {
                "name": "Kronburg",
                "countryCode": "DE"
            },
            {
                "name": "Krumbach",
                "countryCode": "DE"
            },
            {
                "name": "Krummennaab",
                "countryCode": "DE"
            },
            {
                "name": "Krün",
                "countryCode": "DE"
            },
            {
                "name": "Kueps Oberfranken",
                "countryCode": "DE"
            },
            {
                "name": "Kulmain",
                "countryCode": "DE"
            },
            {
                "name": "Kulmbach",
                "countryCode": "DE"
            },
            {
                "name": "Kumhausen",
                "countryCode": "DE"
            },
            {
                "name": "Kunreuth",
                "countryCode": "DE"
            },
            {
                "name": "Kupferberg",
                "countryCode": "DE"
            },
            {
                "name": "Kutzenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Köditz",
                "countryCode": "DE"
            },
            {
                "name": "Ködnitz",
                "countryCode": "DE"
            },
            {
                "name": "Köfering",
                "countryCode": "DE"
            },
            {
                "name": "Königsbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Königsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Königsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Königstein",
                "countryCode": "DE"
            },
            {
                "name": "Kötzting",
                "countryCode": "DE"
            },
            {
                "name": "Kühbach",
                "countryCode": "DE"
            },
            {
                "name": "Kümmersbruck",
                "countryCode": "DE"
            },
            {
                "name": "Künzing",
                "countryCode": "DE"
            },
            {
                "name": "Kürnach",
                "countryCode": "DE"
            },
            {
                "name": "Laberweinting",
                "countryCode": "DE"
            },
            {
                "name": "Lachen",
                "countryCode": "DE"
            },
            {
                "name": "Lalling",
                "countryCode": "DE"
            },
            {
                "name": "Lam",
                "countryCode": "DE"
            },
            {
                "name": "Lamerdingen",
                "countryCode": "DE"
            },
            {
                "name": "Landau an der Isar",
                "countryCode": "DE"
            },
            {
                "name": "Landsberg am Lech",
                "countryCode": "DE"
            },
            {
                "name": "Landsberied",
                "countryCode": "DE"
            },
            {
                "name": "Landshut",
                "countryCode": "DE"
            },
            {
                "name": "Langenaltheim",
                "countryCode": "DE"
            },
            {
                "name": "Langenbach",
                "countryCode": "DE"
            },
            {
                "name": "Langenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Langenmosen",
                "countryCode": "DE"
            },
            {
                "name": "Langenneufnach",
                "countryCode": "DE"
            },
            {
                "name": "Langenpreising",
                "countryCode": "DE"
            },
            {
                "name": "Langensendelbach",
                "countryCode": "DE"
            },
            {
                "name": "Langenzenn",
                "countryCode": "DE"
            },
            {
                "name": "Langerringen",
                "countryCode": "DE"
            },
            {
                "name": "Langfurth",
                "countryCode": "DE"
            },
            {
                "name": "Langquaid",
                "countryCode": "DE"
            },
            {
                "name": "Langweid",
                "countryCode": "DE"
            },
            {
                "name": "Lappersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lauben",
                "countryCode": "DE"
            },
            {
                "name": "Laudenbach",
                "countryCode": "DE"
            },
            {
                "name": "Lauf an der Pegnitz",
                "countryCode": "DE"
            },
            {
                "name": "Laufach",
                "countryCode": "DE"
            },
            {
                "name": "Laufen",
                "countryCode": "DE"
            },
            {
                "name": "Laugna",
                "countryCode": "DE"
            },
            {
                "name": "Lauingen",
                "countryCode": "DE"
            },
            {
                "name": "Lauter",
                "countryCode": "DE"
            },
            {
                "name": "Lauterhofen",
                "countryCode": "DE"
            },
            {
                "name": "Lautertal",
                "countryCode": "DE"
            },
            {
                "name": "Lautrach",
                "countryCode": "DE"
            },
            {
                "name": "Lechbruck",
                "countryCode": "DE"
            },
            {
                "name": "Legau",
                "countryCode": "DE"
            },
            {
                "name": "Lehrberg",
                "countryCode": "DE"
            },
            {
                "name": "Leiblfing",
                "countryCode": "DE"
            },
            {
                "name": "Leidersbach",
                "countryCode": "DE"
            },
            {
                "name": "Leinburg",
                "countryCode": "DE"
            },
            {
                "name": "Leipheim",
                "countryCode": "DE"
            },
            {
                "name": "Lengdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lengenwang",
                "countryCode": "DE"
            },
            {
                "name": "Lenggries",
                "countryCode": "DE"
            },
            {
                "name": "Leonberg",
                "countryCode": "DE"
            },
            {
                "name": "Leuchtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Leupoldsgrün",
                "countryCode": "DE"
            },
            {
                "name": "Leutenbach",
                "countryCode": "DE"
            },
            {
                "name": "Leutershausen",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenau",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenfels",
                "countryCode": "DE"
            },
            {
                "name": "Lindau",
                "countryCode": "DE"
            },
            {
                "name": "Lindberg",
                "countryCode": "DE"
            },
            {
                "name": "Litzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Lohberg",
                "countryCode": "DE"
            },
            {
                "name": "Lohr am Main",
                "countryCode": "DE"
            },
            {
                "name": "Loiching",
                "countryCode": "DE"
            },
            {
                "name": "Lonnerstadt",
                "countryCode": "DE"
            },
            {
                "name": "Lower Bavaria",
                "countryCode": "DE"
            },
            {
                "name": "Ludwigsstadt",
                "countryCode": "DE"
            },
            {
                "name": "Luhe-Wildenau",
                "countryCode": "DE"
            },
            {
                "name": "Lupburg",
                "countryCode": "DE"
            },
            {
                "name": "Maierhöfen",
                "countryCode": "DE"
            },
            {
                "name": "Maihingen",
                "countryCode": "DE"
            },
            {
                "name": "Mainaschaff",
                "countryCode": "DE"
            },
            {
                "name": "Mainbernheim",
                "countryCode": "DE"
            },
            {
                "name": "Mainburg",
                "countryCode": "DE"
            },
            {
                "name": "Mainleus",
                "countryCode": "DE"
            },
            {
                "name": "Mainstockheim",
                "countryCode": "DE"
            },
            {
                "name": "Maisach",
                "countryCode": "DE"
            },
            {
                "name": "Maitenbeth",
                "countryCode": "DE"
            },
            {
                "name": "Malching",
                "countryCode": "DE"
            },
            {
                "name": "Malgersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mallersdorf-Pfaffenberg",
                "countryCode": "DE"
            },
            {
                "name": "Mammendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mamming",
                "countryCode": "DE"
            },
            {
                "name": "Manching",
                "countryCode": "DE"
            },
            {
                "name": "Mantel",
                "countryCode": "DE"
            },
            {
                "name": "Margetshöchheim",
                "countryCode": "DE"
            },
            {
                "name": "Mariaposching",
                "countryCode": "DE"
            },
            {
                "name": "Marklkofen",
                "countryCode": "DE"
            },
            {
                "name": "Markt Berolzheim",
                "countryCode": "DE"
            },
            {
                "name": "Markt Bibart",
                "countryCode": "DE"
            },
            {
                "name": "Markt Einersheim",
                "countryCode": "DE"
            },
            {
                "name": "Markt Erlbach",
                "countryCode": "DE"
            },
            {
                "name": "Markt Indersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Markt Nordheim",
                "countryCode": "DE"
            },
            {
                "name": "Markt Rettenbach",
                "countryCode": "DE"
            },
            {
                "name": "Markt Schwaben",
                "countryCode": "DE"
            },
            {
                "name": "Markt Taschendorf",
                "countryCode": "DE"
            },
            {
                "name": "Markt Wald",
                "countryCode": "DE"
            },
            {
                "name": "Marktbergel",
                "countryCode": "DE"
            },
            {
                "name": "Marktbreit",
                "countryCode": "DE"
            },
            {
                "name": "Marktgraitz",
                "countryCode": "DE"
            },
            {
                "name": "Marktheidenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Marktl",
                "countryCode": "DE"
            },
            {
                "name": "Marktleugast",
                "countryCode": "DE"
            },
            {
                "name": "Marktleuthen",
                "countryCode": "DE"
            },
            {
                "name": "Marktoberdorf",
                "countryCode": "DE"
            },
            {
                "name": "Marktoffingen",
                "countryCode": "DE"
            },
            {
                "name": "Marktredwitz",
                "countryCode": "DE"
            },
            {
                "name": "Marktrodach",
                "countryCode": "DE"
            },
            {
                "name": "Marktschellenberg",
                "countryCode": "DE"
            },
            {
                "name": "Marktschorgast",
                "countryCode": "DE"
            },
            {
                "name": "Marktsteft",
                "countryCode": "DE"
            },
            {
                "name": "Marktzeuln",
                "countryCode": "DE"
            },
            {
                "name": "Marloffstein",
                "countryCode": "DE"
            },
            {
                "name": "Maroldsweisach",
                "countryCode": "DE"
            },
            {
                "name": "Marquartstein",
                "countryCode": "DE"
            },
            {
                "name": "Martinsheim",
                "countryCode": "DE"
            },
            {
                "name": "Marxheim",
                "countryCode": "DE"
            },
            {
                "name": "Marzling",
                "countryCode": "DE"
            },
            {
                "name": "Massing",
                "countryCode": "DE"
            },
            {
                "name": "Mauern",
                "countryCode": "DE"
            },
            {
                "name": "Mauerstetten",
                "countryCode": "DE"
            },
            {
                "name": "Mauth",
                "countryCode": "DE"
            },
            {
                "name": "Maxhütte-Haidhof",
                "countryCode": "DE"
            },
            {
                "name": "Maßbach",
                "countryCode": "DE"
            },
            {
                "name": "Medlingen",
                "countryCode": "DE"
            },
            {
                "name": "Meeder",
                "countryCode": "DE"
            },
            {
                "name": "Mehlmeisel",
                "countryCode": "DE"
            },
            {
                "name": "Mehring",
                "countryCode": "DE"
            },
            {
                "name": "Meitingen",
                "countryCode": "DE"
            },
            {
                "name": "Mellrichstadt",
                "countryCode": "DE"
            },
            {
                "name": "Memmelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Memmingen",
                "countryCode": "DE"
            },
            {
                "name": "Memmingerberg",
                "countryCode": "DE"
            },
            {
                "name": "Mengkofen",
                "countryCode": "DE"
            },
            {
                "name": "Merching",
                "countryCode": "DE"
            },
            {
                "name": "Mering",
                "countryCode": "DE"
            },
            {
                "name": "Merkendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mertingen",
                "countryCode": "DE"
            },
            {
                "name": "Mespelbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Metten",
                "countryCode": "DE"
            },
            {
                "name": "Mettenheim",
                "countryCode": "DE"
            },
            {
                "name": "Michelau",
                "countryCode": "DE"
            },
            {
                "name": "Michelsneukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Mickhausen",
                "countryCode": "DE"
            },
            {
                "name": "Miesbach",
                "countryCode": "DE"
            },
            {
                "name": "Miltach",
                "countryCode": "DE"
            },
            {
                "name": "Miltenberg",
                "countryCode": "DE"
            },
            {
                "name": "Mindelheim",
                "countryCode": "DE"
            },
            {
                "name": "Mintraching",
                "countryCode": "DE"
            },
            {
                "name": "Missen-Wilhams",
                "countryCode": "DE"
            },
            {
                "name": "Mistelgau",
                "countryCode": "DE"
            },
            {
                "name": "Mitteleschenbach",
                "countryCode": "DE"
            },
            {
                "name": "Mittelneufnach",
                "countryCode": "DE"
            },
            {
                "name": "Mittelstetten",
                "countryCode": "DE"
            },
            {
                "name": "Mittenwald",
                "countryCode": "DE"
            },
            {
                "name": "Mitterfels",
                "countryCode": "DE"
            },
            {
                "name": "Mitterskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Mitterteich",
                "countryCode": "DE"
            },
            {
                "name": "Mitwitz",
                "countryCode": "DE"
            },
            {
                "name": "Monheim",
                "countryCode": "DE"
            },
            {
                "name": "Moorenweis",
                "countryCode": "DE"
            },
            {
                "name": "Moosach",
                "countryCode": "DE"
            },
            {
                "name": "Moosbach",
                "countryCode": "DE"
            },
            {
                "name": "Moosburg",
                "countryCode": "DE"
            },
            {
                "name": "Moosinning",
                "countryCode": "DE"
            },
            {
                "name": "Moosthenning",
                "countryCode": "DE"
            },
            {
                "name": "Motten",
                "countryCode": "DE"
            },
            {
                "name": "Muhr am See",
                "countryCode": "DE"
            },
            {
                "name": "Munich",
                "countryCode": "DE"
            },
            {
                "name": "Munningen",
                "countryCode": "DE"
            },
            {
                "name": "Murnau am Staffelsee",
                "countryCode": "DE"
            },
            {
                "name": "Mähring",
                "countryCode": "DE"
            },
            {
                "name": "Mödingen",
                "countryCode": "DE"
            },
            {
                "name": "Möhrendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mömbris",
                "countryCode": "DE"
            },
            {
                "name": "Mömlingen",
                "countryCode": "DE"
            },
            {
                "name": "Mönchberg",
                "countryCode": "DE"
            },
            {
                "name": "Mönchsdeggingen",
                "countryCode": "DE"
            },
            {
                "name": "Mönchsroth",
                "countryCode": "DE"
            },
            {
                "name": "Möttingen",
                "countryCode": "DE"
            },
            {
                "name": "Mötzing",
                "countryCode": "DE"
            },
            {
                "name": "Mühldorf",
                "countryCode": "DE"
            },
            {
                "name": "Mühlhausen",
                "countryCode": "DE"
            },
            {
                "name": "Münchberg",
                "countryCode": "DE"
            },
            {
                "name": "Münchsmünster",
                "countryCode": "DE"
            },
            {
                "name": "Münchsteinach",
                "countryCode": "DE"
            },
            {
                "name": "Münnerstadt",
                "countryCode": "DE"
            },
            {
                "name": "Münsing",
                "countryCode": "DE"
            },
            {
                "name": "Münster",
                "countryCode": "DE"
            },
            {
                "name": "Münsterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Nabburg",
                "countryCode": "DE"
            },
            {
                "name": "Nagel",
                "countryCode": "DE"
            },
            {
                "name": "Naila",
                "countryCode": "DE"
            },
            {
                "name": "Nandlstadt",
                "countryCode": "DE"
            },
            {
                "name": "Nennslingen",
                "countryCode": "DE"
            },
            {
                "name": "Nersingen",
                "countryCode": "DE"
            },
            {
                "name": "Nesselwang",
                "countryCode": "DE"
            },
            {
                "name": "Neu-Ulm",
                "countryCode": "DE"
            },
            {
                "name": "Neualbenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Neubeuern",
                "countryCode": "DE"
            },
            {
                "name": "Neubiberg",
                "countryCode": "DE"
            },
            {
                "name": "Neubrunn",
                "countryCode": "DE"
            },
            {
                "name": "Neuburg",
                "countryCode": "DE"
            },
            {
                "name": "Neuburg an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Neudrossenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Neuendettelsau",
                "countryCode": "DE"
            },
            {
                "name": "Neuenmarkt",
                "countryCode": "DE"
            },
            {
                "name": "Neufahrn",
                "countryCode": "DE"
            },
            {
                "name": "Neufahrn bei Freising",
                "countryCode": "DE"
            },
            {
                "name": "Neufraunhofen",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus an der Pegnitz",
                "countryCode": "DE"
            },
            {
                "name": "Neuhof an der Zenn",
                "countryCode": "DE"
            },
            {
                "name": "Neuhütten",
                "countryCode": "DE"
            },
            {
                "name": "Neukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neukirchen-Balbini",
                "countryCode": "DE"
            },
            {
                "name": "Neumarkt in der Oberpfalz",
                "countryCode": "DE"
            },
            {
                "name": "Neumarkt-Sankt Veit",
                "countryCode": "DE"
            },
            {
                "name": "Neunburg vorm Wald",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen am Brand",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen am Main",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen am Sand",
                "countryCode": "DE"
            },
            {
                "name": "Neureichenau",
                "countryCode": "DE"
            },
            {
                "name": "Neuried",
                "countryCode": "DE"
            },
            {
                "name": "Neuschönau",
                "countryCode": "DE"
            },
            {
                "name": "Neusitz",
                "countryCode": "DE"
            },
            {
                "name": "Neusorg",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt am Main",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt an der Aisch",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt an der Waldnaab",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt bei Coburg",
                "countryCode": "DE"
            },
            {
                "name": "Neutraubling",
                "countryCode": "DE"
            },
            {
                "name": "Neuötting",
                "countryCode": "DE"
            },
            {
                "name": "Niederaichbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederalteich",
                "countryCode": "DE"
            },
            {
                "name": "Niederbergkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Niederfüllbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederlauer",
                "countryCode": "DE"
            },
            {
                "name": "Niedermurach",
                "countryCode": "DE"
            },
            {
                "name": "Niedernberg",
                "countryCode": "DE"
            },
            {
                "name": "Niederrieden",
                "countryCode": "DE"
            },
            {
                "name": "Niederschönenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Niedertaufkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Niederviehbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederwerrn",
                "countryCode": "DE"
            },
            {
                "name": "Niederwinkling",
                "countryCode": "DE"
            },
            {
                "name": "Nittenau",
                "countryCode": "DE"
            },
            {
                "name": "Nittendorf",
                "countryCode": "DE"
            },
            {
                "name": "Nonnenhorn",
                "countryCode": "DE"
            },
            {
                "name": "Nordendorf",
                "countryCode": "DE"
            },
            {
                "name": "Nordhalben",
                "countryCode": "DE"
            },
            {
                "name": "Nordheim",
                "countryCode": "DE"
            },
            {
                "name": "Nußdorf",
                "countryCode": "DE"
            },
            {
                "name": "Nußdorf am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Nördlingen",
                "countryCode": "DE"
            },
            {
                "name": "Nüdlingen",
                "countryCode": "DE"
            },
            {
                "name": "Nürnberg",
                "countryCode": "DE"
            },
            {
                "name": "Oberammergau",
                "countryCode": "DE"
            },
            {
                "name": "Oberasbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberaudorf",
                "countryCode": "DE"
            },
            {
                "name": "Oberbergkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Oberdachstetten",
                "countryCode": "DE"
            },
            {
                "name": "Oberding",
                "countryCode": "DE"
            },
            {
                "name": "Oberelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Obergriesbach",
                "countryCode": "DE"
            },
            {
                "name": "Obergünzburg",
                "countryCode": "DE"
            },
            {
                "name": "Oberhaching",
                "countryCode": "DE"
            },
            {
                "name": "Oberhaid",
                "countryCode": "DE"
            },
            {
                "name": "Oberhausen",
                "countryCode": "DE"
            },
            {
                "name": "Oberkotzau",
                "countryCode": "DE"
            },
            {
                "name": "Oberleichtersbach",
                "countryCode": "DE"
            },
            {
                "name": "Obermeitingen",
                "countryCode": "DE"
            },
            {
                "name": "Obermichelbach",
                "countryCode": "DE"
            },
            {
                "name": "Obernbreit",
                "countryCode": "DE"
            },
            {
                "name": "Obernburg am Main",
                "countryCode": "DE"
            },
            {
                "name": "Oberndorf",
                "countryCode": "DE"
            },
            {
                "name": "Obernzell",
                "countryCode": "DE"
            },
            {
                "name": "Obernzenn",
                "countryCode": "DE"
            },
            {
                "name": "Oberostendorf",
                "countryCode": "DE"
            },
            {
                "name": "Oberottmarshausen",
                "countryCode": "DE"
            },
            {
                "name": "Oberpframmern",
                "countryCode": "DE"
            },
            {
                "name": "Oberpleichfeld",
                "countryCode": "DE"
            },
            {
                "name": "Oberpöring",
                "countryCode": "DE"
            },
            {
                "name": "Oberreichenbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberreute",
                "countryCode": "DE"
            },
            {
                "name": "Oberrieden",
                "countryCode": "DE"
            },
            {
                "name": "Oberscheinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Oberschleißheim",
                "countryCode": "DE"
            },
            {
                "name": "Oberschneiding",
                "countryCode": "DE"
            },
            {
                "name": "Oberschwarzach",
                "countryCode": "DE"
            },
            {
                "name": "Oberschweinbach",
                "countryCode": "DE"
            },
            {
                "name": "Obersinn",
                "countryCode": "DE"
            },
            {
                "name": "Oberstaufen",
                "countryCode": "DE"
            },
            {
                "name": "Oberstdorf",
                "countryCode": "DE"
            },
            {
                "name": "Oberstreu",
                "countryCode": "DE"
            },
            {
                "name": "Obersöchering",
                "countryCode": "DE"
            },
            {
                "name": "Obersüßbach",
                "countryCode": "DE"
            },
            {
                "name": "Obertaufkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Oberthulba",
                "countryCode": "DE"
            },
            {
                "name": "Obertraubling",
                "countryCode": "DE"
            },
            {
                "name": "Obertrubach",
                "countryCode": "DE"
            },
            {
                "name": "Oberviechtach",
                "countryCode": "DE"
            },
            {
                "name": "Obing",
                "countryCode": "DE"
            },
            {
                "name": "Ochsenfurt",
                "countryCode": "DE"
            },
            {
                "name": "Odelzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Oerlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Oettingen in Bayern",
                "countryCode": "DE"
            },
            {
                "name": "Offenberg",
                "countryCode": "DE"
            },
            {
                "name": "Offenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Offingen",
                "countryCode": "DE"
            },
            {
                "name": "Ofterschwang",
                "countryCode": "DE"
            },
            {
                "name": "Ohlstadt",
                "countryCode": "DE"
            },
            {
                "name": "Olching",
                "countryCode": "DE"
            },
            {
                "name": "Opfenbach",
                "countryCode": "DE"
            },
            {
                "name": "Ornbau",
                "countryCode": "DE"
            },
            {
                "name": "Ortenburg",
                "countryCode": "DE"
            },
            {
                "name": "Osterhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ostheim vor der Rhön",
                "countryCode": "DE"
            },
            {
                "name": "Ottenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Ottensoos",
                "countryCode": "DE"
            },
            {
                "name": "Otterfing",
                "countryCode": "DE"
            },
            {
                "name": "Ottobeuren",
                "countryCode": "DE"
            },
            {
                "name": "Ottobrunn",
                "countryCode": "DE"
            },
            {
                "name": "Otzing",
                "countryCode": "DE"
            },
            {
                "name": "Oy-Mittelberg",
                "countryCode": "DE"
            },
            {
                "name": "Painten",
                "countryCode": "DE"
            },
            {
                "name": "Palling",
                "countryCode": "DE"
            },
            {
                "name": "Pappenheim",
                "countryCode": "DE"
            },
            {
                "name": "Parkstein",
                "countryCode": "DE"
            },
            {
                "name": "Parkstetten",
                "countryCode": "DE"
            },
            {
                "name": "Parsberg",
                "countryCode": "DE"
            },
            {
                "name": "Partenstein",
                "countryCode": "DE"
            },
            {
                "name": "Pasing",
                "countryCode": "DE"
            },
            {
                "name": "Passau",
                "countryCode": "DE"
            },
            {
                "name": "Pastetten",
                "countryCode": "DE"
            },
            {
                "name": "Patersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Paunzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Pechbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Pegnitz",
                "countryCode": "DE"
            },
            {
                "name": "Peiting",
                "countryCode": "DE"
            },
            {
                "name": "Peißenberg",
                "countryCode": "DE"
            },
            {
                "name": "Pemfling",
                "countryCode": "DE"
            },
            {
                "name": "Pentling",
                "countryCode": "DE"
            },
            {
                "name": "Penzberg",
                "countryCode": "DE"
            },
            {
                "name": "Penzing",
                "countryCode": "DE"
            },
            {
                "name": "Perach",
                "countryCode": "DE"
            },
            {
                "name": "Perkam",
                "countryCode": "DE"
            },
            {
                "name": "Perlesreut",
                "countryCode": "DE"
            },
            {
                "name": "Petersaurach",
                "countryCode": "DE"
            },
            {
                "name": "Petersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Petershausen",
                "countryCode": "DE"
            },
            {
                "name": "Pettendorf",
                "countryCode": "DE"
            },
            {
                "name": "Petting",
                "countryCode": "DE"
            },
            {
                "name": "Pettstadt",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenhofen an der Ilm",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffenhofen an der Roth",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffing",
                "countryCode": "DE"
            },
            {
                "name": "Pfakofen",
                "countryCode": "DE"
            },
            {
                "name": "Pfarrkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Pfarrweisach",
                "countryCode": "DE"
            },
            {
                "name": "Pfatter",
                "countryCode": "DE"
            },
            {
                "name": "Pfeffenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Pfofeld",
                "countryCode": "DE"
            },
            {
                "name": "Pforzen",
                "countryCode": "DE"
            },
            {
                "name": "Pfreimd",
                "countryCode": "DE"
            },
            {
                "name": "Pfronten",
                "countryCode": "DE"
            },
            {
                "name": "Piding",
                "countryCode": "DE"
            },
            {
                "name": "Pielenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Pilsach",
                "countryCode": "DE"
            },
            {
                "name": "Pilsting",
                "countryCode": "DE"
            },
            {
                "name": "Pinzberg",
                "countryCode": "DE"
            },
            {
                "name": "Pirk",
                "countryCode": "DE"
            },
            {
                "name": "Pittenhart",
                "countryCode": "DE"
            },
            {
                "name": "Planegg",
                "countryCode": "DE"
            },
            {
                "name": "Plattling",
                "countryCode": "DE"
            },
            {
                "name": "Pleinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Pleiskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Pleystein",
                "countryCode": "DE"
            },
            {
                "name": "Pliening",
                "countryCode": "DE"
            },
            {
                "name": "Plößberg",
                "countryCode": "DE"
            },
            {
                "name": "Pocking",
                "countryCode": "DE"
            },
            {
                "name": "Poing",
                "countryCode": "DE"
            },
            {
                "name": "Polling",
                "countryCode": "DE"
            },
            {
                "name": "Polsingen",
                "countryCode": "DE"
            },
            {
                "name": "Pommelsbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Pommersfelden",
                "countryCode": "DE"
            },
            {
                "name": "Poppenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Poppenricht",
                "countryCode": "DE"
            },
            {
                "name": "Postau",
                "countryCode": "DE"
            },
            {
                "name": "Postbauer-Heng",
                "countryCode": "DE"
            },
            {
                "name": "Postmünster",
                "countryCode": "DE"
            },
            {
                "name": "Pottenstein",
                "countryCode": "DE"
            },
            {
                "name": "Poxdorf",
                "countryCode": "DE"
            },
            {
                "name": "Prackenbach",
                "countryCode": "DE"
            },
            {
                "name": "Prebitz",
                "countryCode": "DE"
            },
            {
                "name": "Pressath",
                "countryCode": "DE"
            },
            {
                "name": "Presseck",
                "countryCode": "DE"
            },
            {
                "name": "Pressig",
                "countryCode": "DE"
            },
            {
                "name": "Pretzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Prichsenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Prien am Chiemsee",
                "countryCode": "DE"
            },
            {
                "name": "Priesendorf",
                "countryCode": "DE"
            },
            {
                "name": "Prittriching",
                "countryCode": "DE"
            },
            {
                "name": "Prosselsheim",
                "countryCode": "DE"
            },
            {
                "name": "Prutting",
                "countryCode": "DE"
            },
            {
                "name": "Puchheim",
                "countryCode": "DE"
            },
            {
                "name": "Pullach im Isartal",
                "countryCode": "DE"
            },
            {
                "name": "Pullenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Puschendorf",
                "countryCode": "DE"
            },
            {
                "name": "Putzbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Pyrbaum",
                "countryCode": "DE"
            },
            {
                "name": "Pähl",
                "countryCode": "DE"
            },
            {
                "name": "Pörnbach",
                "countryCode": "DE"
            },
            {
                "name": "Pöttmes",
                "countryCode": "DE"
            },
            {
                "name": "Püchersreuth",
                "countryCode": "DE"
            },
            {
                "name": "Pürgen",
                "countryCode": "DE"
            },
            {
                "name": "Rain",
                "countryCode": "DE"
            },
            {
                "name": "Raisting",
                "countryCode": "DE"
            },
            {
                "name": "Raitenbuch",
                "countryCode": "DE"
            },
            {
                "name": "Ramerberg",
                "countryCode": "DE"
            },
            {
                "name": "Ramsau",
                "countryCode": "DE"
            },
            {
                "name": "Ramsthal",
                "countryCode": "DE"
            },
            {
                "name": "Randersacker",
                "countryCode": "DE"
            },
            {
                "name": "Rannungen",
                "countryCode": "DE"
            },
            {
                "name": "Rattelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rattenberg",
                "countryCode": "DE"
            },
            {
                "name": "Rattiszell",
                "countryCode": "DE"
            },
            {
                "name": "Raubling",
                "countryCode": "DE"
            },
            {
                "name": "Rechtenbach",
                "countryCode": "DE"
            },
            {
                "name": "Rechtmehring",
                "countryCode": "DE"
            },
            {
                "name": "Rednitzhembach",
                "countryCode": "DE"
            },
            {
                "name": "Redwitz an der Rodach",
                "countryCode": "DE"
            },
            {
                "name": "Regen",
                "countryCode": "DE"
            },
            {
                "name": "Regensburg",
                "countryCode": "DE"
            },
            {
                "name": "Regenstauf",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Mittelfranken",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Unterfranken",
                "countryCode": "DE"
            },
            {
                "name": "Regnitzlosau",
                "countryCode": "DE"
            },
            {
                "name": "Rehau",
                "countryCode": "DE"
            },
            {
                "name": "Rehling",
                "countryCode": "DE"
            },
            {
                "name": "Reichenbach",
                "countryCode": "DE"
            },
            {
                "name": "Reichenberg",
                "countryCode": "DE"
            },
            {
                "name": "Reichenschwand",
                "countryCode": "DE"
            },
            {
                "name": "Reichersbeuern",
                "countryCode": "DE"
            },
            {
                "name": "Reichertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Reichertsheim",
                "countryCode": "DE"
            },
            {
                "name": "Reichertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Reichling",
                "countryCode": "DE"
            },
            {
                "name": "Reimlingen",
                "countryCode": "DE"
            },
            {
                "name": "Reinhausen",
                "countryCode": "DE"
            },
            {
                "name": "Reisbach",
                "countryCode": "DE"
            },
            {
                "name": "Reischach",
                "countryCode": "DE"
            },
            {
                "name": "Reit im Winkl",
                "countryCode": "DE"
            },
            {
                "name": "Remlingen",
                "countryCode": "DE"
            },
            {
                "name": "Rennertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Rentweinsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rettenbach",
                "countryCode": "DE"
            },
            {
                "name": "Rettenberg",
                "countryCode": "DE"
            },
            {
                "name": "Retzstadt",
                "countryCode": "DE"
            },
            {
                "name": "Reut",
                "countryCode": "DE"
            },
            {
                "name": "Reuth",
                "countryCode": "DE"
            },
            {
                "name": "Ried",
                "countryCode": "DE"
            },
            {
                "name": "Rieden",
                "countryCode": "DE"
            },
            {
                "name": "Rieden an der Kötz",
                "countryCode": "DE"
            },
            {
                "name": "Riedenberg",
                "countryCode": "DE"
            },
            {
                "name": "Riedenburg",
                "countryCode": "DE"
            },
            {
                "name": "Riedering",
                "countryCode": "DE"
            },
            {
                "name": "Riegsee",
                "countryCode": "DE"
            },
            {
                "name": "Rieneck",
                "countryCode": "DE"
            },
            {
                "name": "Rimbach",
                "countryCode": "DE"
            },
            {
                "name": "Rimpar",
                "countryCode": "DE"
            },
            {
                "name": "Rimsting",
                "countryCode": "DE"
            },
            {
                "name": "Rinchnach",
                "countryCode": "DE"
            },
            {
                "name": "Ringelai",
                "countryCode": "DE"
            },
            {
                "name": "Roden",
                "countryCode": "DE"
            },
            {
                "name": "Roding",
                "countryCode": "DE"
            },
            {
                "name": "Roggenburg",
                "countryCode": "DE"
            },
            {
                "name": "Rohr",
                "countryCode": "DE"
            },
            {
                "name": "Rohrbach",
                "countryCode": "DE"
            },
            {
                "name": "Rohrdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rohrenfels",
                "countryCode": "DE"
            },
            {
                "name": "Ronsberg",
                "countryCode": "DE"
            },
            {
                "name": "Rosenheim",
                "countryCode": "DE"
            },
            {
                "name": "Rossbach",
                "countryCode": "DE"
            },
            {
                "name": "Roth",
                "countryCode": "DE"
            },
            {
                "name": "Rothenbuch",
                "countryCode": "DE"
            },
            {
                "name": "Rothenburg ob der Tauber",
                "countryCode": "DE"
            },
            {
                "name": "Rothenfels",
                "countryCode": "DE"
            },
            {
                "name": "Rott",
                "countryCode": "DE"
            },
            {
                "name": "Rottach-Egern",
                "countryCode": "DE"
            },
            {
                "name": "Rottenbuch",
                "countryCode": "DE"
            },
            {
                "name": "Rottenburg an der Laaber",
                "countryCode": "DE"
            },
            {
                "name": "Rottendorf",
                "countryCode": "DE"
            },
            {
                "name": "Rotthalmünster",
                "countryCode": "DE"
            },
            {
                "name": "Roßhaupten",
                "countryCode": "DE"
            },
            {
                "name": "Roßtal",
                "countryCode": "DE"
            },
            {
                "name": "Rudelzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Ruderatshofen",
                "countryCode": "DE"
            },
            {
                "name": "Ruderting",
                "countryCode": "DE"
            },
            {
                "name": "Rugendorf",
                "countryCode": "DE"
            },
            {
                "name": "Ruhmannsfelden",
                "countryCode": "DE"
            },
            {
                "name": "Ruhpolding",
                "countryCode": "DE"
            },
            {
                "name": "Ruhstorf",
                "countryCode": "DE"
            },
            {
                "name": "Runding",
                "countryCode": "DE"
            },
            {
                "name": "Rödelsee",
                "countryCode": "DE"
            },
            {
                "name": "Rödental",
                "countryCode": "DE"
            },
            {
                "name": "Röfingen",
                "countryCode": "DE"
            },
            {
                "name": "Röhrmoos",
                "countryCode": "DE"
            },
            {
                "name": "Röhrnbach",
                "countryCode": "DE"
            },
            {
                "name": "Röllbach",
                "countryCode": "DE"
            },
            {
                "name": "Röslau",
                "countryCode": "DE"
            },
            {
                "name": "Röthenbach",
                "countryCode": "DE"
            },
            {
                "name": "Röthenbach an der Pegnitz",
                "countryCode": "DE"
            },
            {
                "name": "Röthlein",
                "countryCode": "DE"
            },
            {
                "name": "Röttenbach",
                "countryCode": "DE"
            },
            {
                "name": "Röttingen",
                "countryCode": "DE"
            },
            {
                "name": "Rötz",
                "countryCode": "DE"
            },
            {
                "name": "Rückersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rügland",
                "countryCode": "DE"
            },
            {
                "name": "Saal",
                "countryCode": "DE"
            },
            {
                "name": "Saal an der Saale",
                "countryCode": "DE"
            },
            {
                "name": "Sachsen",
                "countryCode": "DE"
            },
            {
                "name": "Sachsenkam",
                "countryCode": "DE"
            },
            {
                "name": "Sailauf",
                "countryCode": "DE"
            },
            {
                "name": "Salching",
                "countryCode": "DE"
            },
            {
                "name": "Saldenburg",
                "countryCode": "DE"
            },
            {
                "name": "Salgen",
                "countryCode": "DE"
            },
            {
                "name": "Salz",
                "countryCode": "DE"
            },
            {
                "name": "Salzweg",
                "countryCode": "DE"
            },
            {
                "name": "Samerberg",
                "countryCode": "DE"
            },
            {
                "name": "Sand",
                "countryCode": "DE"
            },
            {
                "name": "Sandberg",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Englmar",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Leonhard am Wonneberg",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Wolfgang",
                "countryCode": "DE"
            },
            {
                "name": "Sauerlach",
                "countryCode": "DE"
            },
            {
                "name": "Saulgrub",
                "countryCode": "DE"
            },
            {
                "name": "Schauenstein",
                "countryCode": "DE"
            },
            {
                "name": "Schaufling",
                "countryCode": "DE"
            },
            {
                "name": "Schechen",
                "countryCode": "DE"
            },
            {
                "name": "Scheidegg",
                "countryCode": "DE"
            },
            {
                "name": "Scheinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Scherstetten",
                "countryCode": "DE"
            },
            {
                "name": "Scheuring",
                "countryCode": "DE"
            },
            {
                "name": "Scheyern",
                "countryCode": "DE"
            },
            {
                "name": "Scheßlitz",
                "countryCode": "DE"
            },
            {
                "name": "Schierling",
                "countryCode": "DE"
            },
            {
                "name": "Schillingsfürst",
                "countryCode": "DE"
            },
            {
                "name": "Schiltberg",
                "countryCode": "DE"
            },
            {
                "name": "Schirmitz",
                "countryCode": "DE"
            },
            {
                "name": "Schirnding",
                "countryCode": "DE"
            },
            {
                "name": "Schleching",
                "countryCode": "DE"
            },
            {
                "name": "Schlehdorf",
                "countryCode": "DE"
            },
            {
                "name": "Schliersee",
                "countryCode": "DE"
            },
            {
                "name": "Schlüsselfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schmidgaden",
                "countryCode": "DE"
            },
            {
                "name": "Schmidmühlen",
                "countryCode": "DE"
            },
            {
                "name": "Schmiechen",
                "countryCode": "DE"
            },
            {
                "name": "Schnaitsee",
                "countryCode": "DE"
            },
            {
                "name": "Schnaittach",
                "countryCode": "DE"
            },
            {
                "name": "Schnaittenbach",
                "countryCode": "DE"
            },
            {
                "name": "Schneckenlohe",
                "countryCode": "DE"
            },
            {
                "name": "Schneizlreuth",
                "countryCode": "DE"
            },
            {
                "name": "Schnelldorf",
                "countryCode": "DE"
            },
            {
                "name": "Schondorf am Ammersee",
                "countryCode": "DE"
            },
            {
                "name": "Schondra",
                "countryCode": "DE"
            },
            {
                "name": "Schongau",
                "countryCode": "DE"
            },
            {
                "name": "Schonstett",
                "countryCode": "DE"
            },
            {
                "name": "Schonungen",
                "countryCode": "DE"
            },
            {
                "name": "Schopfloch",
                "countryCode": "DE"
            },
            {
                "name": "Schorndorf",
                "countryCode": "DE"
            },
            {
                "name": "Schrobenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Schwabach",
                "countryCode": "DE"
            },
            {
                "name": "Schwabhausen",
                "countryCode": "DE"
            },
            {
                "name": "Schwabmünchen",
                "countryCode": "DE"
            },
            {
                "name": "Schwabsoien",
                "countryCode": "DE"
            },
            {
                "name": "Schwaig",
                "countryCode": "DE"
            },
            {
                "name": "Schwandorf in Bayern",
                "countryCode": "DE"
            },
            {
                "name": "Schwanfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schwangau",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzach",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenbach",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenbach an der Saale",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenbruck",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzhofen",
                "countryCode": "DE"
            },
            {
                "name": "Schwebheim",
                "countryCode": "DE"
            },
            {
                "name": "Schweinfurt",
                "countryCode": "DE"
            },
            {
                "name": "Schweitenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Schwenningen",
                "countryCode": "DE"
            },
            {
                "name": "Schwindegg",
                "countryCode": "DE"
            },
            {
                "name": "Schäftlarn",
                "countryCode": "DE"
            },
            {
                "name": "Schöfweg",
                "countryCode": "DE"
            },
            {
                "name": "Schöllkrippen",
                "countryCode": "DE"
            },
            {
                "name": "Schöllnach",
                "countryCode": "DE"
            },
            {
                "name": "Schönau",
                "countryCode": "DE"
            },
            {
                "name": "Schönau am Königssee",
                "countryCode": "DE"
            },
            {
                "name": "Schöngeising",
                "countryCode": "DE"
            },
            {
                "name": "Schönsee",
                "countryCode": "DE"
            },
            {
                "name": "Schönthal",
                "countryCode": "DE"
            },
            {
                "name": "Schönwald",
                "countryCode": "DE"
            },
            {
                "name": "Seefeld",
                "countryCode": "DE"
            },
            {
                "name": "Seeg",
                "countryCode": "DE"
            },
            {
                "name": "Seehausen am Staffelsee",
                "countryCode": "DE"
            },
            {
                "name": "Seeon-Seebruck",
                "countryCode": "DE"
            },
            {
                "name": "Seeshaupt",
                "countryCode": "DE"
            },
            {
                "name": "Seinsheim",
                "countryCode": "DE"
            },
            {
                "name": "Selb",
                "countryCode": "DE"
            },
            {
                "name": "Selbitz",
                "countryCode": "DE"
            },
            {
                "name": "Senden",
                "countryCode": "DE"
            },
            {
                "name": "Sengenthal",
                "countryCode": "DE"
            },
            {
                "name": "Sennfeld",
                "countryCode": "DE"
            },
            {
                "name": "Seubersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Seukendorf",
                "countryCode": "DE"
            },
            {
                "name": "Seybothenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Seßlach",
                "countryCode": "DE"
            },
            {
                "name": "Siegenburg",
                "countryCode": "DE"
            },
            {
                "name": "Siegsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sielenbach",
                "countryCode": "DE"
            },
            {
                "name": "Sigmarszell",
                "countryCode": "DE"
            },
            {
                "name": "Simbach",
                "countryCode": "DE"
            },
            {
                "name": "Simbach am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Simmelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sindelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sinzing",
                "countryCode": "DE"
            },
            {
                "name": "Solnhofen",
                "countryCode": "DE"
            },
            {
                "name": "Sommerach",
                "countryCode": "DE"
            },
            {
                "name": "Sommerhausen",
                "countryCode": "DE"
            },
            {
                "name": "Sommerkahl",
                "countryCode": "DE"
            },
            {
                "name": "Sondheim vor der Rhön",
                "countryCode": "DE"
            },
            {
                "name": "Sonnefeld",
                "countryCode": "DE"
            },
            {
                "name": "Sonnen",
                "countryCode": "DE"
            },
            {
                "name": "Sontheim",
                "countryCode": "DE"
            },
            {
                "name": "Sonthofen",
                "countryCode": "DE"
            },
            {
                "name": "Soyen",
                "countryCode": "DE"
            },
            {
                "name": "Spalt",
                "countryCode": "DE"
            },
            {
                "name": "Spardorf",
                "countryCode": "DE"
            },
            {
                "name": "Sparneck",
                "countryCode": "DE"
            },
            {
                "name": "Speichersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Speinshart",
                "countryCode": "DE"
            },
            {
                "name": "Spiegelau",
                "countryCode": "DE"
            },
            {
                "name": "Stadelhofen",
                "countryCode": "DE"
            },
            {
                "name": "Stadtbergen",
                "countryCode": "DE"
            },
            {
                "name": "Stadtlauringen",
                "countryCode": "DE"
            },
            {
                "name": "Stadtprozelten",
                "countryCode": "DE"
            },
            {
                "name": "Stadtsteinach",
                "countryCode": "DE"
            },
            {
                "name": "Stallwang",
                "countryCode": "DE"
            },
            {
                "name": "Stammbach",
                "countryCode": "DE"
            },
            {
                "name": "Stammham",
                "countryCode": "DE"
            },
            {
                "name": "Stamsried",
                "countryCode": "DE"
            },
            {
                "name": "Starnberg",
                "countryCode": "DE"
            },
            {
                "name": "Staudach-Egerndach",
                "countryCode": "DE"
            },
            {
                "name": "Stegaurach",
                "countryCode": "DE"
            },
            {
                "name": "Stein",
                "countryCode": "DE"
            },
            {
                "name": "Steinach",
                "countryCode": "DE"
            },
            {
                "name": "Steinbach",
                "countryCode": "DE"
            },
            {
                "name": "Steinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Steingaden",
                "countryCode": "DE"
            },
            {
                "name": "Steinheim",
                "countryCode": "DE"
            },
            {
                "name": "Steinhöring",
                "countryCode": "DE"
            },
            {
                "name": "Steinkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Steinsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Steinwiesen",
                "countryCode": "DE"
            },
            {
                "name": "Stephanskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Stephansposching",
                "countryCode": "DE"
            },
            {
                "name": "Stetten",
                "countryCode": "DE"
            },
            {
                "name": "Stettfeld",
                "countryCode": "DE"
            },
            {
                "name": "Stiefenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Stockheim",
                "countryCode": "DE"
            },
            {
                "name": "Stockstadt am Main",
                "countryCode": "DE"
            },
            {
                "name": "Straubing",
                "countryCode": "DE"
            },
            {
                "name": "Straßkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Straßlach-Dingharting",
                "countryCode": "DE"
            },
            {
                "name": "Strullendorf",
                "countryCode": "DE"
            },
            {
                "name": "Stubenberg",
                "countryCode": "DE"
            },
            {
                "name": "Stulln",
                "countryCode": "DE"
            },
            {
                "name": "Störnstein",
                "countryCode": "DE"
            },
            {
                "name": "Stötten am Auerberg",
                "countryCode": "DE"
            },
            {
                "name": "Stöttwang",
                "countryCode": "DE"
            },
            {
                "name": "Sugenheim",
                "countryCode": "DE"
            },
            {
                "name": "Sulzbach am Main",
                "countryCode": "DE"
            },
            {
                "name": "Sulzbach-Rosenberg",
                "countryCode": "DE"
            },
            {
                "name": "Sulzberg",
                "countryCode": "DE"
            },
            {
                "name": "Sulzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sulzemoos",
                "countryCode": "DE"
            },
            {
                "name": "Sulzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Sulzfeld am Main",
                "countryCode": "DE"
            },
            {
                "name": "Sulzheim",
                "countryCode": "DE"
            },
            {
                "name": "Surberg",
                "countryCode": "DE"
            },
            {
                "name": "Swabia",
                "countryCode": "DE"
            },
            {
                "name": "Söchtenau",
                "countryCode": "DE"
            },
            {
                "name": "Sünching",
                "countryCode": "DE"
            },
            {
                "name": "Tacherting",
                "countryCode": "DE"
            },
            {
                "name": "Taching am See",
                "countryCode": "DE"
            },
            {
                "name": "Tagmersheim",
                "countryCode": "DE"
            },
            {
                "name": "Tann",
                "countryCode": "DE"
            },
            {
                "name": "Tapfheim",
                "countryCode": "DE"
            },
            {
                "name": "Taufkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Tegernheim",
                "countryCode": "DE"
            },
            {
                "name": "Tegernsee",
                "countryCode": "DE"
            },
            {
                "name": "Teisendorf",
                "countryCode": "DE"
            },
            {
                "name": "Teising",
                "countryCode": "DE"
            },
            {
                "name": "Teisnach",
                "countryCode": "DE"
            },
            {
                "name": "Tettau",
                "countryCode": "DE"
            },
            {
                "name": "Tettenweis",
                "countryCode": "DE"
            },
            {
                "name": "Teublitz",
                "countryCode": "DE"
            },
            {
                "name": "Teugn",
                "countryCode": "DE"
            },
            {
                "name": "Teunz",
                "countryCode": "DE"
            },
            {
                "name": "Teuschnitz",
                "countryCode": "DE"
            },
            {
                "name": "Thalmassing",
                "countryCode": "DE"
            },
            {
                "name": "Thannhausen",
                "countryCode": "DE"
            },
            {
                "name": "Thanstein",
                "countryCode": "DE"
            },
            {
                "name": "Theilheim",
                "countryCode": "DE"
            },
            {
                "name": "Theisseil",
                "countryCode": "DE"
            },
            {
                "name": "Thierhaupten",
                "countryCode": "DE"
            },
            {
                "name": "Thiersheim",
                "countryCode": "DE"
            },
            {
                "name": "Thierstein",
                "countryCode": "DE"
            },
            {
                "name": "Thundorf in Unterfranken",
                "countryCode": "DE"
            },
            {
                "name": "Thurmansbang",
                "countryCode": "DE"
            },
            {
                "name": "Thurnau",
                "countryCode": "DE"
            },
            {
                "name": "Thyrnau",
                "countryCode": "DE"
            },
            {
                "name": "Thüngen",
                "countryCode": "DE"
            },
            {
                "name": "Thüngersheim",
                "countryCode": "DE"
            },
            {
                "name": "Tiefenbach",
                "countryCode": "DE"
            },
            {
                "name": "Tirschenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Tittling",
                "countryCode": "DE"
            },
            {
                "name": "Tittmoning",
                "countryCode": "DE"
            },
            {
                "name": "Todtenweis",
                "countryCode": "DE"
            },
            {
                "name": "Trabitz",
                "countryCode": "DE"
            },
            {
                "name": "Train",
                "countryCode": "DE"
            },
            {
                "name": "Traitsching",
                "countryCode": "DE"
            },
            {
                "name": "Trappstadt",
                "countryCode": "DE"
            },
            {
                "name": "Traunreut",
                "countryCode": "DE"
            },
            {
                "name": "Traunstein",
                "countryCode": "DE"
            },
            {
                "name": "Trausnitz",
                "countryCode": "DE"
            },
            {
                "name": "Trautskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Trebgast",
                "countryCode": "DE"
            },
            {
                "name": "Treffelstein",
                "countryCode": "DE"
            },
            {
                "name": "Treuchtlingen",
                "countryCode": "DE"
            },
            {
                "name": "Triftern",
                "countryCode": "DE"
            },
            {
                "name": "Trogen",
                "countryCode": "DE"
            },
            {
                "name": "Trostberg an der Alz",
                "countryCode": "DE"
            },
            {
                "name": "Trunkelsberg",
                "countryCode": "DE"
            },
            {
                "name": "Tröstau",
                "countryCode": "DE"
            },
            {
                "name": "Tuchenbach",
                "countryCode": "DE"
            },
            {
                "name": "Tuntenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Tussenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Tutzing",
                "countryCode": "DE"
            },
            {
                "name": "Tyrlaching",
                "countryCode": "DE"
            },
            {
                "name": "Tännesberg",
                "countryCode": "DE"
            },
            {
                "name": "Töging am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Töpen",
                "countryCode": "DE"
            },
            {
                "name": "Türkenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Türkheim",
                "countryCode": "DE"
            },
            {
                "name": "Tüßling",
                "countryCode": "DE"
            },
            {
                "name": "Uehlfeld",
                "countryCode": "DE"
            },
            {
                "name": "Uettingen",
                "countryCode": "DE"
            },
            {
                "name": "Uffenheim",
                "countryCode": "DE"
            },
            {
                "name": "Uffing",
                "countryCode": "DE"
            },
            {
                "name": "Ungerhausen",
                "countryCode": "DE"
            },
            {
                "name": "Unterammergau",
                "countryCode": "DE"
            },
            {
                "name": "Unterdietfurt",
                "countryCode": "DE"
            },
            {
                "name": "Unterdießen",
                "countryCode": "DE"
            },
            {
                "name": "Unteregg",
                "countryCode": "DE"
            },
            {
                "name": "Unterföhring",
                "countryCode": "DE"
            },
            {
                "name": "Untergriesbach",
                "countryCode": "DE"
            },
            {
                "name": "Unterhaching",
                "countryCode": "DE"
            },
            {
                "name": "Unterleinleiter",
                "countryCode": "DE"
            },
            {
                "name": "Untermeitingen",
                "countryCode": "DE"
            },
            {
                "name": "Untermerzbach",
                "countryCode": "DE"
            },
            {
                "name": "Unterneukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Unterpleichfeld",
                "countryCode": "DE"
            },
            {
                "name": "Unterreit",
                "countryCode": "DE"
            },
            {
                "name": "Unterschleißheim",
                "countryCode": "DE"
            },
            {
                "name": "Untersiemau",
                "countryCode": "DE"
            },
            {
                "name": "Untersteinach",
                "countryCode": "DE"
            },
            {
                "name": "Unterthingau",
                "countryCode": "DE"
            },
            {
                "name": "Unterwössen",
                "countryCode": "DE"
            },
            {
                "name": "Untrasried",
                "countryCode": "DE"
            },
            {
                "name": "Upper Bavaria",
                "countryCode": "DE"
            },
            {
                "name": "Upper Franconia",
                "countryCode": "DE"
            },
            {
                "name": "Upper Palatinate",
                "countryCode": "DE"
            },
            {
                "name": "Ursberg",
                "countryCode": "DE"
            },
            {
                "name": "Ursensollen",
                "countryCode": "DE"
            },
            {
                "name": "Urspringen",
                "countryCode": "DE"
            },
            {
                "name": "Ustersbach",
                "countryCode": "DE"
            },
            {
                "name": "Uttenreuth",
                "countryCode": "DE"
            },
            {
                "name": "Utting am Ammersee",
                "countryCode": "DE"
            },
            {
                "name": "Vachendorf",
                "countryCode": "DE"
            },
            {
                "name": "Vagen",
                "countryCode": "DE"
            },
            {
                "name": "Valley",
                "countryCode": "DE"
            },
            {
                "name": "Vaterstetten",
                "countryCode": "DE"
            },
            {
                "name": "Veitsbronn",
                "countryCode": "DE"
            },
            {
                "name": "Veitshöchheim",
                "countryCode": "DE"
            },
            {
                "name": "Velburg",
                "countryCode": "DE"
            },
            {
                "name": "Velden",
                "countryCode": "DE"
            },
            {
                "name": "Vestenbergsgreuth",
                "countryCode": "DE"
            },
            {
                "name": "Viechtach",
                "countryCode": "DE"
            },
            {
                "name": "Viereth-Trunstadt",
                "countryCode": "DE"
            },
            {
                "name": "Vierkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Vilgertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Villenbach",
                "countryCode": "DE"
            },
            {
                "name": "Vilsbiburg",
                "countryCode": "DE"
            },
            {
                "name": "Vilseck",
                "countryCode": "DE"
            },
            {
                "name": "Vilsheim",
                "countryCode": "DE"
            },
            {
                "name": "Vilshofen",
                "countryCode": "DE"
            },
            {
                "name": "Vogtareuth",
                "countryCode": "DE"
            },
            {
                "name": "Vohburg an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Vohenstrauß",
                "countryCode": "DE"
            },
            {
                "name": "Volkach",
                "countryCode": "DE"
            },
            {
                "name": "Volkenschwand",
                "countryCode": "DE"
            },
            {
                "name": "Vorbach",
                "countryCode": "DE"
            },
            {
                "name": "Vorra",
                "countryCode": "DE"
            },
            {
                "name": "Vöhringen",
                "countryCode": "DE"
            },
            {
                "name": "Waakirchen",
                "countryCode": "DE"
            },
            {
                "name": "Waal",
                "countryCode": "DE"
            },
            {
                "name": "Wachenroth",
                "countryCode": "DE"
            },
            {
                "name": "Wackersberg",
                "countryCode": "DE"
            },
            {
                "name": "Wackersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Waffenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Waging am See",
                "countryCode": "DE"
            },
            {
                "name": "Waidhaus",
                "countryCode": "DE"
            },
            {
                "name": "Waidhofen",
                "countryCode": "DE"
            },
            {
                "name": "Waigolshausen",
                "countryCode": "DE"
            },
            {
                "name": "Waischenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Wald",
                "countryCode": "DE"
            },
            {
                "name": "Waldaschaff",
                "countryCode": "DE"
            },
            {
                "name": "Waldbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Waldbüttelbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Walderbach",
                "countryCode": "DE"
            },
            {
                "name": "Waldershof",
                "countryCode": "DE"
            },
            {
                "name": "Waldkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Waldkraiburg",
                "countryCode": "DE"
            },
            {
                "name": "Waldmünchen",
                "countryCode": "DE"
            },
            {
                "name": "Waldsassen",
                "countryCode": "DE"
            },
            {
                "name": "Waldstetten",
                "countryCode": "DE"
            },
            {
                "name": "Waldthurn",
                "countryCode": "DE"
            },
            {
                "name": "Walkertshofen",
                "countryCode": "DE"
            },
            {
                "name": "Wallenfels",
                "countryCode": "DE"
            },
            {
                "name": "Wallerfing",
                "countryCode": "DE"
            },
            {
                "name": "Wallersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wallerstein",
                "countryCode": "DE"
            },
            {
                "name": "Wallgau",
                "countryCode": "DE"
            },
            {
                "name": "Walpertskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Walsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Waltenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Wang",
                "countryCode": "DE"
            },
            {
                "name": "Warmensteinach",
                "countryCode": "DE"
            },
            {
                "name": "Warngau",
                "countryCode": "DE"
            },
            {
                "name": "Wartenberg",
                "countryCode": "DE"
            },
            {
                "name": "Wartmannsroth",
                "countryCode": "DE"
            },
            {
                "name": "Wasserburg",
                "countryCode": "DE"
            },
            {
                "name": "Wasserburg am Inn",
                "countryCode": "DE"
            },
            {
                "name": "Wasserlosen",
                "countryCode": "DE"
            },
            {
                "name": "Wassertrüdingen",
                "countryCode": "DE"
            },
            {
                "name": "Wechingen",
                "countryCode": "DE"
            },
            {
                "name": "Wegscheid",
                "countryCode": "DE"
            },
            {
                "name": "Wehringen",
                "countryCode": "DE"
            },
            {
                "name": "Weibersbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Weichering",
                "countryCode": "DE"
            },
            {
                "name": "Weichs",
                "countryCode": "DE"
            },
            {
                "name": "Weiden",
                "countryCode": "DE"
            },
            {
                "name": "Weidenbach",
                "countryCode": "DE"
            },
            {
                "name": "Weidhausen bei Coburg",
                "countryCode": "DE"
            },
            {
                "name": "Weiding",
                "countryCode": "DE"
            },
            {
                "name": "Weigendorf",
                "countryCode": "DE"
            },
            {
                "name": "Weihenzell",
                "countryCode": "DE"
            },
            {
                "name": "Weiherhammer",
                "countryCode": "DE"
            },
            {
                "name": "Weihmichl",
                "countryCode": "DE"
            },
            {
                "name": "Weil",
                "countryCode": "DE"
            },
            {
                "name": "Weilbach",
                "countryCode": "DE"
            },
            {
                "name": "Weiler-Simmerberg",
                "countryCode": "DE"
            },
            {
                "name": "Weilersbach",
                "countryCode": "DE"
            },
            {
                "name": "Weilheim",
                "countryCode": "DE"
            },
            {
                "name": "Weiltingen",
                "countryCode": "DE"
            },
            {
                "name": "Weisendorf",
                "countryCode": "DE"
            },
            {
                "name": "Weismain",
                "countryCode": "DE"
            },
            {
                "name": "Weitnau",
                "countryCode": "DE"
            },
            {
                "name": "Weitramsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Weißdorf",
                "countryCode": "DE"
            },
            {
                "name": "Weißenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Weißenburg in Bayern",
                "countryCode": "DE"
            },
            {
                "name": "Weißenhorn",
                "countryCode": "DE"
            },
            {
                "name": "Weißenohe",
                "countryCode": "DE"
            },
            {
                "name": "Weißensberg",
                "countryCode": "DE"
            },
            {
                "name": "Weißenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Welden",
                "countryCode": "DE"
            },
            {
                "name": "Wemding",
                "countryCode": "DE"
            },
            {
                "name": "Wendelstein",
                "countryCode": "DE"
            },
            {
                "name": "Weng",
                "countryCode": "DE"
            },
            {
                "name": "Wenzenbach",
                "countryCode": "DE"
            },
            {
                "name": "Wernberg-Köblitz",
                "countryCode": "DE"
            },
            {
                "name": "Werneck",
                "countryCode": "DE"
            },
            {
                "name": "Wertach",
                "countryCode": "DE"
            },
            {
                "name": "Wertingen",
                "countryCode": "DE"
            },
            {
                "name": "Wessobrunn",
                "countryCode": "DE"
            },
            {
                "name": "Westendorf",
                "countryCode": "DE"
            },
            {
                "name": "Westerheim",
                "countryCode": "DE"
            },
            {
                "name": "Westheim",
                "countryCode": "DE"
            },
            {
                "name": "Wetzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Weyarn",
                "countryCode": "DE"
            },
            {
                "name": "Weßling",
                "countryCode": "DE"
            },
            {
                "name": "Wiedergeltingen",
                "countryCode": "DE"
            },
            {
                "name": "Wiesau",
                "countryCode": "DE"
            },
            {
                "name": "Wiesen",
                "countryCode": "DE"
            },
            {
                "name": "Wiesenfelden",
                "countryCode": "DE"
            },
            {
                "name": "Wiesent",
                "countryCode": "DE"
            },
            {
                "name": "Wiesenthau",
                "countryCode": "DE"
            },
            {
                "name": "Wiesentheid",
                "countryCode": "DE"
            },
            {
                "name": "Wiesthal",
                "countryCode": "DE"
            },
            {
                "name": "Wiggensbach",
                "countryCode": "DE"
            },
            {
                "name": "Wilburgstetten",
                "countryCode": "DE"
            },
            {
                "name": "Wildenberg",
                "countryCode": "DE"
            },
            {
                "name": "Wildflecken",
                "countryCode": "DE"
            },
            {
                "name": "Wildpoldsried",
                "countryCode": "DE"
            },
            {
                "name": "Wildsteig",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmsthal",
                "countryCode": "DE"
            },
            {
                "name": "Wilhermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Willanzheim",
                "countryCode": "DE"
            },
            {
                "name": "Willmering",
                "countryCode": "DE"
            },
            {
                "name": "Windach",
                "countryCode": "DE"
            },
            {
                "name": "Windberg",
                "countryCode": "DE"
            },
            {
                "name": "Windelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Windischeschenbach",
                "countryCode": "DE"
            },
            {
                "name": "Windsbach",
                "countryCode": "DE"
            },
            {
                "name": "Winhöring",
                "countryCode": "DE"
            },
            {
                "name": "Winkelhaid",
                "countryCode": "DE"
            },
            {
                "name": "Winklarn",
                "countryCode": "DE"
            },
            {
                "name": "Winterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Winzer",
                "countryCode": "DE"
            },
            {
                "name": "Wipfeld",
                "countryCode": "DE"
            },
            {
                "name": "Wirsberg",
                "countryCode": "DE"
            },
            {
                "name": "Wittelshofen",
                "countryCode": "DE"
            },
            {
                "name": "Wittislingen",
                "countryCode": "DE"
            },
            {
                "name": "Witzmannsberg",
                "countryCode": "DE"
            },
            {
                "name": "Wolfersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wolferstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wolfertschwenden",
                "countryCode": "DE"
            },
            {
                "name": "Wolframs-Eschenbach",
                "countryCode": "DE"
            },
            {
                "name": "Wolfratshausen",
                "countryCode": "DE"
            },
            {
                "name": "Wolfsegg",
                "countryCode": "DE"
            },
            {
                "name": "Wollbach",
                "countryCode": "DE"
            },
            {
                "name": "Wolnzach",
                "countryCode": "DE"
            },
            {
                "name": "Wonfurt",
                "countryCode": "DE"
            },
            {
                "name": "Wonsees",
                "countryCode": "DE"
            },
            {
                "name": "Woringen",
                "countryCode": "DE"
            },
            {
                "name": "Wunsiedel",
                "countryCode": "DE"
            },
            {
                "name": "Wurmannsquick",
                "countryCode": "DE"
            },
            {
                "name": "Wurmsham",
                "countryCode": "DE"
            },
            {
                "name": "Wörth",
                "countryCode": "DE"
            },
            {
                "name": "Wörth am Main",
                "countryCode": "DE"
            },
            {
                "name": "Wörth an der Donau",
                "countryCode": "DE"
            },
            {
                "name": "Wörth an der Isar",
                "countryCode": "DE"
            },
            {
                "name": "Wörthsee",
                "countryCode": "DE"
            },
            {
                "name": "Wülfershausen",
                "countryCode": "DE"
            },
            {
                "name": "Würzburg",
                "countryCode": "DE"
            },
            {
                "name": "Zachenberg",
                "countryCode": "DE"
            },
            {
                "name": "Zandt",
                "countryCode": "DE"
            },
            {
                "name": "Zangberg",
                "countryCode": "DE"
            },
            {
                "name": "Zapfendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zeil",
                "countryCode": "DE"
            },
            {
                "name": "Zeilarn",
                "countryCode": "DE"
            },
            {
                "name": "Zeitlarn",
                "countryCode": "DE"
            },
            {
                "name": "Zeitlofs",
                "countryCode": "DE"
            },
            {
                "name": "Zell am Main",
                "countryCode": "DE"
            },
            {
                "name": "Zell im Fichtelgebirge",
                "countryCode": "DE"
            },
            {
                "name": "Zellingen",
                "countryCode": "DE"
            },
            {
                "name": "Zenting",
                "countryCode": "DE"
            },
            {
                "name": "Ziemetshausen",
                "countryCode": "DE"
            },
            {
                "name": "Ziertheim",
                "countryCode": "DE"
            },
            {
                "name": "Zirndorf",
                "countryCode": "DE"
            },
            {
                "name": "Zolling",
                "countryCode": "DE"
            },
            {
                "name": "Zorneding",
                "countryCode": "DE"
            },
            {
                "name": "Zusamaltheim",
                "countryCode": "DE"
            },
            {
                "name": "Zusmarshausen",
                "countryCode": "DE"
            },
            {
                "name": "Zwiesel",
                "countryCode": "DE"
            },
            {
                "name": "Übersee",
                "countryCode": "DE"
            },
            {
                "name": "Üchtelhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bremen",
                "countryCode": "DE"
            },
            {
                "name": "Bremerhaven",
                "countryCode": "DE"
            },
            {
                "name": "Burglesum",
                "countryCode": "DE"
            },
            {
                "name": "Vegesack",
                "countryCode": "DE"
            },
            {
                "name": "Albshausen",
                "countryCode": "DE"
            },
            {
                "name": "Alheim",
                "countryCode": "DE"
            },
            {
                "name": "Allendorf",
                "countryCode": "DE"
            },
            {
                "name": "Allendorf an der Lahn",
                "countryCode": "DE"
            },
            {
                "name": "Alsbach-Hähnlein",
                "countryCode": "DE"
            },
            {
                "name": "Alsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Alten Buseck",
                "countryCode": "DE"
            },
            {
                "name": "Altenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Amöneburg",
                "countryCode": "DE"
            },
            {
                "name": "Aßlar",
                "countryCode": "DE"
            },
            {
                "name": "Babenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Arolsen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Camberg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Endbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Hersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Bad Homburg vor der Höhe",
                "countryCode": "DE"
            },
            {
                "name": "Bad Karlshafen",
                "countryCode": "DE"
            },
            {
                "name": "Bad König",
                "countryCode": "DE"
            },
            {
                "name": "Bad Nauheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Orb",
                "countryCode": "DE"
            },
            {
                "name": "Bad Salzschlirf",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schwalbach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Soden am Taunus",
                "countryCode": "DE"
            },
            {
                "name": "Bad Soden-Salmünster",
                "countryCode": "DE"
            },
            {
                "name": "Bad Sooden-Allendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bad Vilbel",
                "countryCode": "DE"
            },
            {
                "name": "Bad Wildungen",
                "countryCode": "DE"
            },
            {
                "name": "Battenberg",
                "countryCode": "DE"
            },
            {
                "name": "Baunatal",
                "countryCode": "DE"
            },
            {
                "name": "Bebra",
                "countryCode": "DE"
            },
            {
                "name": "Beerfelden",
                "countryCode": "DE"
            },
            {
                "name": "Bensheim",
                "countryCode": "DE"
            },
            {
                "name": "Berstadt",
                "countryCode": "DE"
            },
            {
                "name": "Beuern",
                "countryCode": "DE"
            },
            {
                "name": "Biblis",
                "countryCode": "DE"
            },
            {
                "name": "Bickenbach",
                "countryCode": "DE"
            },
            {
                "name": "Biebesheim",
                "countryCode": "DE"
            },
            {
                "name": "Biedenkopf",
                "countryCode": "DE"
            },
            {
                "name": "Birkenau",
                "countryCode": "DE"
            },
            {
                "name": "Birstein",
                "countryCode": "DE"
            },
            {
                "name": "Bischoffen",
                "countryCode": "DE"
            },
            {
                "name": "Bischofsheim",
                "countryCode": "DE"
            },
            {
                "name": "Borken",
                "countryCode": "DE"
            },
            {
                "name": "Braunfels",
                "countryCode": "DE"
            },
            {
                "name": "Breidenbach",
                "countryCode": "DE"
            },
            {
                "name": "Breitenbach",
                "countryCode": "DE"
            },
            {
                "name": "Breitscheid",
                "countryCode": "DE"
            },
            {
                "name": "Brensbach",
                "countryCode": "DE"
            },
            {
                "name": "Breuna",
                "countryCode": "DE"
            },
            {
                "name": "Bromskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Bruchköbel",
                "countryCode": "DE"
            },
            {
                "name": "Burghaun",
                "countryCode": "DE"
            },
            {
                "name": "Burgsolms",
                "countryCode": "DE"
            },
            {
                "name": "Butzbach",
                "countryCode": "DE"
            },
            {
                "name": "Büdingen",
                "countryCode": "DE"
            },
            {
                "name": "Bürstadt",
                "countryCode": "DE"
            },
            {
                "name": "Büttelborn",
                "countryCode": "DE"
            },
            {
                "name": "Calden",
                "countryCode": "DE"
            },
            {
                "name": "Cornberg",
                "countryCode": "DE"
            },
            {
                "name": "Cölbe",
                "countryCode": "DE"
            },
            {
                "name": "Darmstadt",
                "countryCode": "DE"
            },
            {
                "name": "Dehrn",
                "countryCode": "DE"
            },
            {
                "name": "Dieburg",
                "countryCode": "DE"
            },
            {
                "name": "Dietzenbach",
                "countryCode": "DE"
            },
            {
                "name": "Dillenburg",
                "countryCode": "DE"
            },
            {
                "name": "Dipperz",
                "countryCode": "DE"
            },
            {
                "name": "Dreieich",
                "countryCode": "DE"
            },
            {
                "name": "Driedorf",
                "countryCode": "DE"
            },
            {
                "name": "Echzell",
                "countryCode": "DE"
            },
            {
                "name": "Egelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ehringshausen",
                "countryCode": "DE"
            },
            {
                "name": "Eichenzell",
                "countryCode": "DE"
            },
            {
                "name": "Einhausen",
                "countryCode": "DE"
            },
            {
                "name": "Eiterfeld",
                "countryCode": "DE"
            },
            {
                "name": "Eltville",
                "countryCode": "DE"
            },
            {
                "name": "Elz",
                "countryCode": "DE"
            },
            {
                "name": "Eppertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Eppstein",
                "countryCode": "DE"
            },
            {
                "name": "Erbach",
                "countryCode": "DE"
            },
            {
                "name": "Erlensee",
                "countryCode": "DE"
            },
            {
                "name": "Erzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Eschborn",
                "countryCode": "DE"
            },
            {
                "name": "Eschwege",
                "countryCode": "DE"
            },
            {
                "name": "Espenau",
                "countryCode": "DE"
            },
            {
                "name": "Felsberg",
                "countryCode": "DE"
            },
            {
                "name": "Flieden",
                "countryCode": "DE"
            },
            {
                "name": "Florstadt",
                "countryCode": "DE"
            },
            {
                "name": "Flörsheim",
                "countryCode": "DE"
            },
            {
                "name": "Frankenau",
                "countryCode": "DE"
            },
            {
                "name": "Frankenberg",
                "countryCode": "DE"
            },
            {
                "name": "Frankfurt am Main",
                "countryCode": "DE"
            },
            {
                "name": "Freiensteinau",
                "countryCode": "DE"
            },
            {
                "name": "Friedberg",
                "countryCode": "DE"
            },
            {
                "name": "Friedewald",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Frielendorf",
                "countryCode": "DE"
            },
            {
                "name": "Fritzlar",
                "countryCode": "DE"
            },
            {
                "name": "Fronhausen",
                "countryCode": "DE"
            },
            {
                "name": "Fränkisch-Crumbach",
                "countryCode": "DE"
            },
            {
                "name": "Fulda",
                "countryCode": "DE"
            },
            {
                "name": "Fuldatal",
                "countryCode": "DE"
            },
            {
                "name": "Fürth",
                "countryCode": "DE"
            },
            {
                "name": "Gallus",
                "countryCode": "DE"
            },
            {
                "name": "Gedern",
                "countryCode": "DE"
            },
            {
                "name": "Geisenheim",
                "countryCode": "DE"
            },
            {
                "name": "Gelnhausen",
                "countryCode": "DE"
            },
            {
                "name": "Gemünden an der Wohra",
                "countryCode": "DE"
            },
            {
                "name": "Gernsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Gießen",
                "countryCode": "DE"
            },
            {
                "name": "Gilserberg",
                "countryCode": "DE"
            },
            {
                "name": "Ginsheim-Gustavsburg",
                "countryCode": "DE"
            },
            {
                "name": "Gladenbach",
                "countryCode": "DE"
            },
            {
                "name": "Glashütten",
                "countryCode": "DE"
            },
            {
                "name": "Glauburg",
                "countryCode": "DE"
            },
            {
                "name": "Grebenau",
                "countryCode": "DE"
            },
            {
                "name": "Grebenhain",
                "countryCode": "DE"
            },
            {
                "name": "Grebenstein",
                "countryCode": "DE"
            },
            {
                "name": "Greifenstein",
                "countryCode": "DE"
            },
            {
                "name": "Griesheim",
                "countryCode": "DE"
            },
            {
                "name": "Groß-Bieberau",
                "countryCode": "DE"
            },
            {
                "name": "Groß-Gerau",
                "countryCode": "DE"
            },
            {
                "name": "Groß-Rohrheim",
                "countryCode": "DE"
            },
            {
                "name": "Groß-Umstadt",
                "countryCode": "DE"
            },
            {
                "name": "Groß-Zimmern",
                "countryCode": "DE"
            },
            {
                "name": "Großalmerode",
                "countryCode": "DE"
            },
            {
                "name": "Großenlüder",
                "countryCode": "DE"
            },
            {
                "name": "Großkrotzenburg",
                "countryCode": "DE"
            },
            {
                "name": "Grävenwiesbach",
                "countryCode": "DE"
            },
            {
                "name": "Grünberg",
                "countryCode": "DE"
            },
            {
                "name": "Gudensberg",
                "countryCode": "DE"
            },
            {
                "name": "Guxhagen",
                "countryCode": "DE"
            },
            {
                "name": "Hadamar",
                "countryCode": "DE"
            },
            {
                "name": "Haiger",
                "countryCode": "DE"
            },
            {
                "name": "Hain-Gründau",
                "countryCode": "DE"
            },
            {
                "name": "Haina",
                "countryCode": "DE"
            },
            {
                "name": "Hammersbach",
                "countryCode": "DE"
            },
            {
                "name": "Hanau am Main",
                "countryCode": "DE"
            },
            {
                "name": "Hattersheim",
                "countryCode": "DE"
            },
            {
                "name": "Hatzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Helsa",
                "countryCode": "DE"
            },
            {
                "name": "Heppenheim an der Bergstrasse",
                "countryCode": "DE"
            },
            {
                "name": "Herborn",
                "countryCode": "DE"
            },
            {
                "name": "Herbstein",
                "countryCode": "DE"
            },
            {
                "name": "Heringen",
                "countryCode": "DE"
            },
            {
                "name": "Herleshausen",
                "countryCode": "DE"
            },
            {
                "name": "Hessisch Lichtenau",
                "countryCode": "DE"
            },
            {
                "name": "Heuchelheim",
                "countryCode": "DE"
            },
            {
                "name": "Heusenstamm",
                "countryCode": "DE"
            },
            {
                "name": "Hilders",
                "countryCode": "DE"
            },
            {
                "name": "Hirschhorn",
                "countryCode": "DE"
            },
            {
                "name": "Hirzenhain",
                "countryCode": "DE"
            },
            {
                "name": "Hochheim am Main",
                "countryCode": "DE"
            },
            {
                "name": "Hofbieber",
                "countryCode": "DE"
            },
            {
                "name": "Hofgeismar",
                "countryCode": "DE"
            },
            {
                "name": "Hofheim am Taunus",
                "countryCode": "DE"
            },
            {
                "name": "Homberg",
                "countryCode": "DE"
            },
            {
                "name": "Hosenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Hungen",
                "countryCode": "DE"
            },
            {
                "name": "Höchst im Odenwald",
                "countryCode": "DE"
            },
            {
                "name": "Höingen",
                "countryCode": "DE"
            },
            {
                "name": "Hörnsheim",
                "countryCode": "DE"
            },
            {
                "name": "Hünfeld",
                "countryCode": "DE"
            },
            {
                "name": "Idstein",
                "countryCode": "DE"
            },
            {
                "name": "Immenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Jesberg",
                "countryCode": "DE"
            },
            {
                "name": "Karben",
                "countryCode": "DE"
            },
            {
                "name": "Kassel",
                "countryCode": "DE"
            },
            {
                "name": "Kaufungen",
                "countryCode": "DE"
            },
            {
                "name": "Kefenrod",
                "countryCode": "DE"
            },
            {
                "name": "Kelkheim (Taunus)",
                "countryCode": "DE"
            },
            {
                "name": "Kelsterbach",
                "countryCode": "DE"
            },
            {
                "name": "Kiedrich",
                "countryCode": "DE"
            },
            {
                "name": "Kirchhain",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim",
                "countryCode": "DE"
            },
            {
                "name": "Kirtorf",
                "countryCode": "DE"
            },
            {
                "name": "Korbach",
                "countryCode": "DE"
            },
            {
                "name": "Kriftel",
                "countryCode": "DE"
            },
            {
                "name": "Kronberg",
                "countryCode": "DE"
            },
            {
                "name": "Kronberg Tal",
                "countryCode": "DE"
            },
            {
                "name": "Königstein im Taunus",
                "countryCode": "DE"
            },
            {
                "name": "Körle",
                "countryCode": "DE"
            },
            {
                "name": "Künzell",
                "countryCode": "DE"
            },
            {
                "name": "Lampertheim",
                "countryCode": "DE"
            },
            {
                "name": "Langen",
                "countryCode": "DE"
            },
            {
                "name": "Langenselbold",
                "countryCode": "DE"
            },
            {
                "name": "Langgöns",
                "countryCode": "DE"
            },
            {
                "name": "Laubach",
                "countryCode": "DE"
            },
            {
                "name": "Laufdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lauterbach",
                "countryCode": "DE"
            },
            {
                "name": "Lautertal",
                "countryCode": "DE"
            },
            {
                "name": "Leun",
                "countryCode": "DE"
            },
            {
                "name": "Lich",
                "countryCode": "DE"
            },
            {
                "name": "Liebenau",
                "countryCode": "DE"
            },
            {
                "name": "Liederbach",
                "countryCode": "DE"
            },
            {
                "name": "Limburg an der Lahn",
                "countryCode": "DE"
            },
            {
                "name": "Lindenfels",
                "countryCode": "DE"
            },
            {
                "name": "Lohfelden",
                "countryCode": "DE"
            },
            {
                "name": "Lohra",
                "countryCode": "DE"
            },
            {
                "name": "Lollar",
                "countryCode": "DE"
            },
            {
                "name": "Lorsch",
                "countryCode": "DE"
            },
            {
                "name": "Löhnberg",
                "countryCode": "DE"
            },
            {
                "name": "Lützelbach",
                "countryCode": "DE"
            },
            {
                "name": "Maintal",
                "countryCode": "DE"
            },
            {
                "name": "Malsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Marburg an der Lahn",
                "countryCode": "DE"
            },
            {
                "name": "Martinhagen",
                "countryCode": "DE"
            },
            {
                "name": "Melbach",
                "countryCode": "DE"
            },
            {
                "name": "Melsungen",
                "countryCode": "DE"
            },
            {
                "name": "Mengerskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Merenberg",
                "countryCode": "DE"
            },
            {
                "name": "Merkenbach",
                "countryCode": "DE"
            },
            {
                "name": "Messel",
                "countryCode": "DE"
            },
            {
                "name": "Michelstadt",
                "countryCode": "DE"
            },
            {
                "name": "Mittenaar",
                "countryCode": "DE"
            },
            {
                "name": "Mörfelden-Walldorf",
                "countryCode": "DE"
            },
            {
                "name": "Mörlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Mühlheim am Main",
                "countryCode": "DE"
            },
            {
                "name": "Münchhausen",
                "countryCode": "DE"
            },
            {
                "name": "Münster",
                "countryCode": "DE"
            },
            {
                "name": "Münzenberg",
                "countryCode": "DE"
            },
            {
                "name": "Naumburg",
                "countryCode": "DE"
            },
            {
                "name": "Neckarsteinach",
                "countryCode": "DE"
            },
            {
                "name": "Nentershausen",
                "countryCode": "DE"
            },
            {
                "name": "Neu Isenburg",
                "countryCode": "DE"
            },
            {
                "name": "Neu-Anspach",
                "countryCode": "DE"
            },
            {
                "name": "Neuental",
                "countryCode": "DE"
            },
            {
                "name": "Neuhof",
                "countryCode": "DE"
            },
            {
                "name": "Neukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt (Hessen)",
                "countryCode": "DE"
            },
            {
                "name": "Nidda",
                "countryCode": "DE"
            },
            {
                "name": "Nidderau",
                "countryCode": "DE"
            },
            {
                "name": "Niedenstein",
                "countryCode": "DE"
            },
            {
                "name": "Nieder-Gründau",
                "countryCode": "DE"
            },
            {
                "name": "Niederaula",
                "countryCode": "DE"
            },
            {
                "name": "Niederbiel",
                "countryCode": "DE"
            },
            {
                "name": "Niederdorfelden",
                "countryCode": "DE"
            },
            {
                "name": "Niederklein",
                "countryCode": "DE"
            },
            {
                "name": "Niedernhausen",
                "countryCode": "DE"
            },
            {
                "name": "Niederrad",
                "countryCode": "DE"
            },
            {
                "name": "Ober-Mörlen",
                "countryCode": "DE"
            },
            {
                "name": "Ober-Ramstadt",
                "countryCode": "DE"
            },
            {
                "name": "Oberaula",
                "countryCode": "DE"
            },
            {
                "name": "Oberbiel",
                "countryCode": "DE"
            },
            {
                "name": "Obertshausen",
                "countryCode": "DE"
            },
            {
                "name": "Oberursel",
                "countryCode": "DE"
            },
            {
                "name": "Offenbach",
                "countryCode": "DE"
            },
            {
                "name": "Ortenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ottrau",
                "countryCode": "DE"
            },
            {
                "name": "Pfungstadt",
                "countryCode": "DE"
            },
            {
                "name": "Philippsthal",
                "countryCode": "DE"
            },
            {
                "name": "Poppenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Rabenau",
                "countryCode": "DE"
            },
            {
                "name": "Ranstadt",
                "countryCode": "DE"
            },
            {
                "name": "Rasdorf",
                "countryCode": "DE"
            },
            {
                "name": "Raunheim",
                "countryCode": "DE"
            },
            {
                "name": "Rauschenberg",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Darmstadt",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Gießen",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Kassel",
                "countryCode": "DE"
            },
            {
                "name": "Reichelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Reinhardshausen",
                "countryCode": "DE"
            },
            {
                "name": "Reinheim",
                "countryCode": "DE"
            },
            {
                "name": "Reiskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Riedstadt",
                "countryCode": "DE"
            },
            {
                "name": "Rimbach",
                "countryCode": "DE"
            },
            {
                "name": "Rockenberg",
                "countryCode": "DE"
            },
            {
                "name": "Rodenbach",
                "countryCode": "DE"
            },
            {
                "name": "Rodgau",
                "countryCode": "DE"
            },
            {
                "name": "Romrod",
                "countryCode": "DE"
            },
            {
                "name": "Ronshausen",
                "countryCode": "DE"
            },
            {
                "name": "Rosbach vor der Höhe",
                "countryCode": "DE"
            },
            {
                "name": "Rosenthal",
                "countryCode": "DE"
            },
            {
                "name": "Rotenburg an der Fulda",
                "countryCode": "DE"
            },
            {
                "name": "Rothenberg",
                "countryCode": "DE"
            },
            {
                "name": "Roßdorf",
                "countryCode": "DE"
            },
            {
                "name": "Runkel",
                "countryCode": "DE"
            },
            {
                "name": "Rüdesheim am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Rüsselsheim",
                "countryCode": "DE"
            },
            {
                "name": "Sachsenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Schaafheim",
                "countryCode": "DE"
            },
            {
                "name": "Schenklengsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schlangenbad",
                "countryCode": "DE"
            },
            {
                "name": "Schlitz",
                "countryCode": "DE"
            },
            {
                "name": "Schlüchtern",
                "countryCode": "DE"
            },
            {
                "name": "Schmitten",
                "countryCode": "DE"
            },
            {
                "name": "Schotten",
                "countryCode": "DE"
            },
            {
                "name": "Schrecksbach",
                "countryCode": "DE"
            },
            {
                "name": "Schwalbach",
                "countryCode": "DE"
            },
            {
                "name": "Schwalbach am Taunus",
                "countryCode": "DE"
            },
            {
                "name": "Schwalmstadt",
                "countryCode": "DE"
            },
            {
                "name": "Schwalmtal",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenborn",
                "countryCode": "DE"
            },
            {
                "name": "Schöffengrund",
                "countryCode": "DE"
            },
            {
                "name": "Seeheim-Jugenheim",
                "countryCode": "DE"
            },
            {
                "name": "Seligenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Selters",
                "countryCode": "DE"
            },
            {
                "name": "Sinn",
                "countryCode": "DE"
            },
            {
                "name": "Solms",
                "countryCode": "DE"
            },
            {
                "name": "Sontra",
                "countryCode": "DE"
            },
            {
                "name": "Spangenberg",
                "countryCode": "DE"
            },
            {
                "name": "Stadtallendorf",
                "countryCode": "DE"
            },
            {
                "name": "Staufenberg",
                "countryCode": "DE"
            },
            {
                "name": "Steeden",
                "countryCode": "DE"
            },
            {
                "name": "Steinau an der Straße",
                "countryCode": "DE"
            },
            {
                "name": "Steinbach am Taunus",
                "countryCode": "DE"
            },
            {
                "name": "Stockstadt am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Sulzbach",
                "countryCode": "DE"
            },
            {
                "name": "Södel",
                "countryCode": "DE"
            },
            {
                "name": "Tann",
                "countryCode": "DE"
            },
            {
                "name": "Taunusstein",
                "countryCode": "DE"
            },
            {
                "name": "Trebur",
                "countryCode": "DE"
            },
            {
                "name": "Trendelburg",
                "countryCode": "DE"
            },
            {
                "name": "Udenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Ulrichstein",
                "countryCode": "DE"
            },
            {
                "name": "Unter-Abtsteinach",
                "countryCode": "DE"
            },
            {
                "name": "Usingen",
                "countryCode": "DE"
            },
            {
                "name": "Vellmar",
                "countryCode": "DE"
            },
            {
                "name": "Viernheim",
                "countryCode": "DE"
            },
            {
                "name": "Villmar",
                "countryCode": "DE"
            },
            {
                "name": "Volkmarsen",
                "countryCode": "DE"
            },
            {
                "name": "Vöhl",
                "countryCode": "DE"
            },
            {
                "name": "Wabern",
                "countryCode": "DE"
            },
            {
                "name": "Wald-Michelbach",
                "countryCode": "DE"
            },
            {
                "name": "Waldbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Waldeck",
                "countryCode": "DE"
            },
            {
                "name": "Waldems",
                "countryCode": "DE"
            },
            {
                "name": "Waldkappel",
                "countryCode": "DE"
            },
            {
                "name": "Walluf",
                "countryCode": "DE"
            },
            {
                "name": "Wanfried",
                "countryCode": "DE"
            },
            {
                "name": "Wehrheim",
                "countryCode": "DE"
            },
            {
                "name": "Weilburg",
                "countryCode": "DE"
            },
            {
                "name": "Weilmünster",
                "countryCode": "DE"
            },
            {
                "name": "Weinbach",
                "countryCode": "DE"
            },
            {
                "name": "Weiterstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wetter",
                "countryCode": "DE"
            },
            {
                "name": "Wetzlar",
                "countryCode": "DE"
            },
            {
                "name": "Wiesbaden",
                "countryCode": "DE"
            },
            {
                "name": "Willingen",
                "countryCode": "DE"
            },
            {
                "name": "Willingshausen",
                "countryCode": "DE"
            },
            {
                "name": "Witzenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wohnbach",
                "countryCode": "DE"
            },
            {
                "name": "Wolfhagen",
                "countryCode": "DE"
            },
            {
                "name": "Wächtersbach",
                "countryCode": "DE"
            },
            {
                "name": "Wölfersheim",
                "countryCode": "DE"
            },
            {
                "name": "Zierenberg",
                "countryCode": "DE"
            },
            {
                "name": "Zwingenberg",
                "countryCode": "DE"
            },
            {
                "name": "Alsterdorf",
                "countryCode": "DE"
            },
            {
                "name": "Altona",
                "countryCode": "DE"
            },
            {
                "name": "Barmbek-Nord",
                "countryCode": "DE"
            },
            {
                "name": "Bergedorf",
                "countryCode": "DE"
            },
            {
                "name": "Bergstedt",
                "countryCode": "DE"
            },
            {
                "name": "Borgfelde",
                "countryCode": "DE"
            },
            {
                "name": "Duvenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Eidelstedt",
                "countryCode": "DE"
            },
            {
                "name": "Eimsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Farmsen-Berne",
                "countryCode": "DE"
            },
            {
                "name": "Fuhlsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Hamburg",
                "countryCode": "DE"
            },
            {
                "name": "Hamburg-Altstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hamburg-Mitte",
                "countryCode": "DE"
            },
            {
                "name": "Hamburg-Nord",
                "countryCode": "DE"
            },
            {
                "name": "Hammerbrook",
                "countryCode": "DE"
            },
            {
                "name": "Harburg",
                "countryCode": "DE"
            },
            {
                "name": "Hummelsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Kleiner Grasbrook",
                "countryCode": "DE"
            },
            {
                "name": "Langenhorn",
                "countryCode": "DE"
            },
            {
                "name": "Lemsahl-Mellingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Lurup",
                "countryCode": "DE"
            },
            {
                "name": "Marienthal",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt",
                "countryCode": "DE"
            },
            {
                "name": "Ohlsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ottensen",
                "countryCode": "DE"
            },
            {
                "name": "Poppenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Rothenburgsort",
                "countryCode": "DE"
            },
            {
                "name": "Sasel",
                "countryCode": "DE"
            },
            {
                "name": "St. Georg",
                "countryCode": "DE"
            },
            {
                "name": "St. Pauli",
                "countryCode": "DE"
            },
            {
                "name": "Steilshoop",
                "countryCode": "DE"
            },
            {
                "name": "Stellingen",
                "countryCode": "DE"
            },
            {
                "name": "Wandsbek",
                "countryCode": "DE"
            },
            {
                "name": "Wellingsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Winterhude",
                "countryCode": "DE"
            },
            {
                "name": "Wohldorf-Ohlstedt",
                "countryCode": "DE"
            },
            {
                "name": "Admannshagen-Bargeshagen",
                "countryCode": "DE"
            },
            {
                "name": "Ahlbeck",
                "countryCode": "DE"
            },
            {
                "name": "Alt Meteln",
                "countryCode": "DE"
            },
            {
                "name": "Alt-Sanitz",
                "countryCode": "DE"
            },
            {
                "name": "Altefähr",
                "countryCode": "DE"
            },
            {
                "name": "Altenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Altenpleen",
                "countryCode": "DE"
            },
            {
                "name": "Altentreptow",
                "countryCode": "DE"
            },
            {
                "name": "Altstadt",
                "countryCode": "DE"
            },
            {
                "name": "Anklam",
                "countryCode": "DE"
            },
            {
                "name": "Bad Doberan",
                "countryCode": "DE"
            },
            {
                "name": "Bad Kleinen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Sülze",
                "countryCode": "DE"
            },
            {
                "name": "Banzkow",
                "countryCode": "DE"
            },
            {
                "name": "Bartenshagen-Parkentin",
                "countryCode": "DE"
            },
            {
                "name": "Barth",
                "countryCode": "DE"
            },
            {
                "name": "Bastorf",
                "countryCode": "DE"
            },
            {
                "name": "Bentwisch",
                "countryCode": "DE"
            },
            {
                "name": "Bentzin",
                "countryCode": "DE"
            },
            {
                "name": "Bergen auf Rügen",
                "countryCode": "DE"
            },
            {
                "name": "Bernitt",
                "countryCode": "DE"
            },
            {
                "name": "Biendorf",
                "countryCode": "DE"
            },
            {
                "name": "Blankensee",
                "countryCode": "DE"
            },
            {
                "name": "Blowatz",
                "countryCode": "DE"
            },
            {
                "name": "Bobitz",
                "countryCode": "DE"
            },
            {
                "name": "Boizenburg",
                "countryCode": "DE"
            },
            {
                "name": "Born",
                "countryCode": "DE"
            },
            {
                "name": "Borrentin",
                "countryCode": "DE"
            },
            {
                "name": "Brandshagen",
                "countryCode": "DE"
            },
            {
                "name": "Broderstorf",
                "countryCode": "DE"
            },
            {
                "name": "Brunn",
                "countryCode": "DE"
            },
            {
                "name": "Brüel",
                "countryCode": "DE"
            },
            {
                "name": "Brüsewitz",
                "countryCode": "DE"
            },
            {
                "name": "Burg Stargard",
                "countryCode": "DE"
            },
            {
                "name": "Burow",
                "countryCode": "DE"
            },
            {
                "name": "Börgerende-Rethwisch",
                "countryCode": "DE"
            },
            {
                "name": "Bützow",
                "countryCode": "DE"
            },
            {
                "name": "Carlow",
                "countryCode": "DE"
            },
            {
                "name": "Carpin",
                "countryCode": "DE"
            },
            {
                "name": "Crivitz",
                "countryCode": "DE"
            },
            {
                "name": "Dabel",
                "countryCode": "DE"
            },
            {
                "name": "Dargun",
                "countryCode": "DE"
            },
            {
                "name": "Dassow",
                "countryCode": "DE"
            },
            {
                "name": "Demen",
                "countryCode": "DE"
            },
            {
                "name": "Demmin",
                "countryCode": "DE"
            },
            {
                "name": "Dersekow",
                "countryCode": "DE"
            },
            {
                "name": "Dierkow-Neu",
                "countryCode": "DE"
            },
            {
                "name": "Dierkow-West",
                "countryCode": "DE"
            },
            {
                "name": "Dobbertin",
                "countryCode": "DE"
            },
            {
                "name": "Domsühl",
                "countryCode": "DE"
            },
            {
                "name": "Dranske",
                "countryCode": "DE"
            },
            {
                "name": "Ducherow",
                "countryCode": "DE"
            },
            {
                "name": "Dummerstorf",
                "countryCode": "DE"
            },
            {
                "name": "Dömitz",
                "countryCode": "DE"
            },
            {
                "name": "Dümmer",
                "countryCode": "DE"
            },
            {
                "name": "Eggesin",
                "countryCode": "DE"
            },
            {
                "name": "Eldena",
                "countryCode": "DE"
            },
            {
                "name": "Elmenhorst",
                "countryCode": "DE"
            },
            {
                "name": "Feldstadt",
                "countryCode": "DE"
            },
            {
                "name": "Ferdinandshof",
                "countryCode": "DE"
            },
            {
                "name": "Franzburg",
                "countryCode": "DE"
            },
            {
                "name": "Friedland",
                "countryCode": "DE"
            },
            {
                "name": "Gadebusch",
                "countryCode": "DE"
            },
            {
                "name": "Garz",
                "countryCode": "DE"
            },
            {
                "name": "Gelbensande",
                "countryCode": "DE"
            },
            {
                "name": "Gielow",
                "countryCode": "DE"
            },
            {
                "name": "Gingst",
                "countryCode": "DE"
            },
            {
                "name": "Glowe",
                "countryCode": "DE"
            },
            {
                "name": "Gnoien",
                "countryCode": "DE"
            },
            {
                "name": "Goldberg",
                "countryCode": "DE"
            },
            {
                "name": "Grabow",
                "countryCode": "DE"
            },
            {
                "name": "Grabowhöfe",
                "countryCode": "DE"
            },
            {
                "name": "Gramkow",
                "countryCode": "DE"
            },
            {
                "name": "Greifswald",
                "countryCode": "DE"
            },
            {
                "name": "Grevesmühlen",
                "countryCode": "DE"
            },
            {
                "name": "Grimmen",
                "countryCode": "DE"
            },
            {
                "name": "Groß Kiesow",
                "countryCode": "DE"
            },
            {
                "name": "Groß Laasch",
                "countryCode": "DE"
            },
            {
                "name": "Groß Miltzow",
                "countryCode": "DE"
            },
            {
                "name": "Groß Nemerow",
                "countryCode": "DE"
            },
            {
                "name": "Groß Wokern",
                "countryCode": "DE"
            },
            {
                "name": "Gägelow",
                "countryCode": "DE"
            },
            {
                "name": "Görmin",
                "countryCode": "DE"
            },
            {
                "name": "Güstrow",
                "countryCode": "DE"
            },
            {
                "name": "Gützkow",
                "countryCode": "DE"
            },
            {
                "name": "Hagenow",
                "countryCode": "DE"
            },
            {
                "name": "Hiddensee",
                "countryCode": "DE"
            },
            {
                "name": "Hornstorf",
                "countryCode": "DE"
            },
            {
                "name": "Jarmen",
                "countryCode": "DE"
            },
            {
                "name": "Jatznick",
                "countryCode": "DE"
            },
            {
                "name": "Jördenstorf",
                "countryCode": "DE"
            },
            {
                "name": "Jürgenshagen",
                "countryCode": "DE"
            },
            {
                "name": "Kalkhorst",
                "countryCode": "DE"
            },
            {
                "name": "Karlshagen",
                "countryCode": "DE"
            },
            {
                "name": "Kavelstorf",
                "countryCode": "DE"
            },
            {
                "name": "Kemnitz",
                "countryCode": "DE"
            },
            {
                "name": "Kessin",
                "countryCode": "DE"
            },
            {
                "name": "Klein Rogahn",
                "countryCode": "DE"
            },
            {
                "name": "Klink",
                "countryCode": "DE"
            },
            {
                "name": "Klütz",
                "countryCode": "DE"
            },
            {
                "name": "Koserow",
                "countryCode": "DE"
            },
            {
                "name": "Krakow am See",
                "countryCode": "DE"
            },
            {
                "name": "Kramerhof",
                "countryCode": "DE"
            },
            {
                "name": "Kritzmow",
                "countryCode": "DE"
            },
            {
                "name": "Kröpelin",
                "countryCode": "DE"
            },
            {
                "name": "Kröslin",
                "countryCode": "DE"
            },
            {
                "name": "Laage",
                "countryCode": "DE"
            },
            {
                "name": "Lalendorf",
                "countryCode": "DE"
            },
            {
                "name": "Lambrechtshagen",
                "countryCode": "DE"
            },
            {
                "name": "Lankow",
                "countryCode": "DE"
            },
            {
                "name": "Lassan",
                "countryCode": "DE"
            },
            {
                "name": "Leezen",
                "countryCode": "DE"
            },
            {
                "name": "Lewenberg",
                "countryCode": "DE"
            },
            {
                "name": "Loddin",
                "countryCode": "DE"
            },
            {
                "name": "Loitz",
                "countryCode": "DE"
            },
            {
                "name": "Lubmin",
                "countryCode": "DE"
            },
            {
                "name": "Ludwigslust",
                "countryCode": "DE"
            },
            {
                "name": "Löcknitz",
                "countryCode": "DE"
            },
            {
                "name": "Lübow",
                "countryCode": "DE"
            },
            {
                "name": "Lübstorf",
                "countryCode": "DE"
            },
            {
                "name": "Lübtheen",
                "countryCode": "DE"
            },
            {
                "name": "Lübz",
                "countryCode": "DE"
            },
            {
                "name": "Lüdersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lützow",
                "countryCode": "DE"
            },
            {
                "name": "Malchin",
                "countryCode": "DE"
            },
            {
                "name": "Malchow",
                "countryCode": "DE"
            },
            {
                "name": "Malliß",
                "countryCode": "DE"
            },
            {
                "name": "Marlow",
                "countryCode": "DE"
            },
            {
                "name": "Mecklenburg",
                "countryCode": "DE"
            },
            {
                "name": "Mesekenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Mirow",
                "countryCode": "DE"
            },
            {
                "name": "Möllenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Mönchhagen",
                "countryCode": "DE"
            },
            {
                "name": "Mühl Rosin",
                "countryCode": "DE"
            },
            {
                "name": "Mühlen Eichsen",
                "countryCode": "DE"
            },
            {
                "name": "Neu Kaliß",
                "countryCode": "DE"
            },
            {
                "name": "Neubrandenburg",
                "countryCode": "DE"
            },
            {
                "name": "Neubukow",
                "countryCode": "DE"
            },
            {
                "name": "Neuburg",
                "countryCode": "DE"
            },
            {
                "name": "Neuenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neukalen",
                "countryCode": "DE"
            },
            {
                "name": "Neukloster",
                "countryCode": "DE"
            },
            {
                "name": "Neumühle",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt-Glewe",
                "countryCode": "DE"
            },
            {
                "name": "Neustrelitz",
                "countryCode": "DE"
            },
            {
                "name": "Neverin",
                "countryCode": "DE"
            },
            {
                "name": "Nienhagen",
                "countryCode": "DE"
            },
            {
                "name": "Niepars",
                "countryCode": "DE"
            },
            {
                "name": "Nostorf",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Binz",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Boltenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Dierhagen",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Göhren",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Kühlungsborn",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Prerow",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Sellin",
                "countryCode": "DE"
            },
            {
                "name": "Ostseebad Zinnowitz",
                "countryCode": "DE"
            },
            {
                "name": "Pampow",
                "countryCode": "DE"
            },
            {
                "name": "Papendorf",
                "countryCode": "DE"
            },
            {
                "name": "Parchim",
                "countryCode": "DE"
            },
            {
                "name": "Pasewalk",
                "countryCode": "DE"
            },
            {
                "name": "Paulsstadt",
                "countryCode": "DE"
            },
            {
                "name": "Penkun",
                "countryCode": "DE"
            },
            {
                "name": "Penzlin",
                "countryCode": "DE"
            },
            {
                "name": "Pinnow",
                "countryCode": "DE"
            },
            {
                "name": "Plate",
                "countryCode": "DE"
            },
            {
                "name": "Plau am See",
                "countryCode": "DE"
            },
            {
                "name": "Poseritz",
                "countryCode": "DE"
            },
            {
                "name": "Preetz",
                "countryCode": "DE"
            },
            {
                "name": "Prohn",
                "countryCode": "DE"
            },
            {
                "name": "Putbus",
                "countryCode": "DE"
            },
            {
                "name": "Raben Steinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Rambin",
                "countryCode": "DE"
            },
            {
                "name": "Rastow",
                "countryCode": "DE"
            },
            {
                "name": "Rechlin",
                "countryCode": "DE"
            },
            {
                "name": "Rehna",
                "countryCode": "DE"
            },
            {
                "name": "Reinberg",
                "countryCode": "DE"
            },
            {
                "name": "Retgendorf",
                "countryCode": "DE"
            },
            {
                "name": "Retschow",
                "countryCode": "DE"
            },
            {
                "name": "Ribnitz-Damgarten",
                "countryCode": "DE"
            },
            {
                "name": "Richtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Roggendorf",
                "countryCode": "DE"
            },
            {
                "name": "Roggentin",
                "countryCode": "DE"
            },
            {
                "name": "Rosenow",
                "countryCode": "DE"
            },
            {
                "name": "Rostock",
                "countryCode": "DE"
            },
            {
                "name": "Röbel",
                "countryCode": "DE"
            },
            {
                "name": "Rövershagen",
                "countryCode": "DE"
            },
            {
                "name": "Saal",
                "countryCode": "DE"
            },
            {
                "name": "Sagard",
                "countryCode": "DE"
            },
            {
                "name": "Samtens",
                "countryCode": "DE"
            },
            {
                "name": "Satow-Oberhagen",
                "countryCode": "DE"
            },
            {
                "name": "Saßnitz",
                "countryCode": "DE"
            },
            {
                "name": "Schelfstadt",
                "countryCode": "DE"
            },
            {
                "name": "Schlagsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Schwaan",
                "countryCode": "DE"
            },
            {
                "name": "Schwerin",
                "countryCode": "DE"
            },
            {
                "name": "Seebad Bansin",
                "countryCode": "DE"
            },
            {
                "name": "Seebad Heringsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Seeheilbad Graal-Müritz",
                "countryCode": "DE"
            },
            {
                "name": "Seehof",
                "countryCode": "DE"
            },
            {
                "name": "Sehlen",
                "countryCode": "DE"
            },
            {
                "name": "Sellin",
                "countryCode": "DE"
            },
            {
                "name": "Selmsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Siggelkow",
                "countryCode": "DE"
            },
            {
                "name": "Spornitz",
                "countryCode": "DE"
            },
            {
                "name": "Steinhagen",
                "countryCode": "DE"
            },
            {
                "name": "Sternberg",
                "countryCode": "DE"
            },
            {
                "name": "Stralendorf",
                "countryCode": "DE"
            },
            {
                "name": "Stralsund",
                "countryCode": "DE"
            },
            {
                "name": "Strasburg",
                "countryCode": "DE"
            },
            {
                "name": "Stäbelow",
                "countryCode": "DE"
            },
            {
                "name": "Sukow",
                "countryCode": "DE"
            },
            {
                "name": "Sülstorf",
                "countryCode": "DE"
            },
            {
                "name": "Tarnow",
                "countryCode": "DE"
            },
            {
                "name": "Tessin",
                "countryCode": "DE"
            },
            {
                "name": "Teterow",
                "countryCode": "DE"
            },
            {
                "name": "Torgelow",
                "countryCode": "DE"
            },
            {
                "name": "Tribsees",
                "countryCode": "DE"
            },
            {
                "name": "Trinwillershagen",
                "countryCode": "DE"
            },
            {
                "name": "Trollenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Tutow",
                "countryCode": "DE"
            },
            {
                "name": "Ueckermünde",
                "countryCode": "DE"
            },
            {
                "name": "Usedom",
                "countryCode": "DE"
            },
            {
                "name": "Velgast",
                "countryCode": "DE"
            },
            {
                "name": "Viereck",
                "countryCode": "DE"
            },
            {
                "name": "Wackerow",
                "countryCode": "DE"
            },
            {
                "name": "Wardow",
                "countryCode": "DE"
            },
            {
                "name": "Waren",
                "countryCode": "DE"
            },
            {
                "name": "Warin",
                "countryCode": "DE"
            },
            {
                "name": "Warnemünde",
                "countryCode": "DE"
            },
            {
                "name": "Warnow",
                "countryCode": "DE"
            },
            {
                "name": "Wattmannshagen",
                "countryCode": "DE"
            },
            {
                "name": "Weitenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Wendorf",
                "countryCode": "DE"
            },
            {
                "name": "Werdervorstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wesenberg",
                "countryCode": "DE"
            },
            {
                "name": "Weststadt",
                "countryCode": "DE"
            },
            {
                "name": "Wiek",
                "countryCode": "DE"
            },
            {
                "name": "Wismar",
                "countryCode": "DE"
            },
            {
                "name": "Wittenburg",
                "countryCode": "DE"
            },
            {
                "name": "Wittenförden",
                "countryCode": "DE"
            },
            {
                "name": "Wittenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Woldegk",
                "countryCode": "DE"
            },
            {
                "name": "Wolgast",
                "countryCode": "DE"
            },
            {
                "name": "Wulkenzin",
                "countryCode": "DE"
            },
            {
                "name": "Wusterhusen",
                "countryCode": "DE"
            },
            {
                "name": "Wustrow",
                "countryCode": "DE"
            },
            {
                "name": "Zarrendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zarrentin",
                "countryCode": "DE"
            },
            {
                "name": "Ziesendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zingst",
                "countryCode": "DE"
            },
            {
                "name": "Zurow",
                "countryCode": "DE"
            },
            {
                "name": "Züssow",
                "countryCode": "DE"
            },
            {
                "name": "Abbesbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Achim",
                "countryCode": "DE"
            },
            {
                "name": "Adelebsen",
                "countryCode": "DE"
            },
            {
                "name": "Adelheidsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Adenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Adendorf",
                "countryCode": "DE"
            },
            {
                "name": "Adenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Aerzen",
                "countryCode": "DE"
            },
            {
                "name": "Agathenburg",
                "countryCode": "DE"
            },
            {
                "name": "Ahausen",
                "countryCode": "DE"
            },
            {
                "name": "Ahlden",
                "countryCode": "DE"
            },
            {
                "name": "Ahlerstedt",
                "countryCode": "DE"
            },
            {
                "name": "Ahnsbeck",
                "countryCode": "DE"
            },
            {
                "name": "Ahnsen",
                "countryCode": "DE"
            },
            {
                "name": "Ahsen-Oetzen",
                "countryCode": "DE"
            },
            {
                "name": "Alfeld",
                "countryCode": "DE"
            },
            {
                "name": "Alfhausen",
                "countryCode": "DE"
            },
            {
                "name": "Algermissen",
                "countryCode": "DE"
            },
            {
                "name": "Alt Wallmoden",
                "countryCode": "DE"
            },
            {
                "name": "Altenau",
                "countryCode": "DE"
            },
            {
                "name": "Altenmedingen",
                "countryCode": "DE"
            },
            {
                "name": "Amelinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Ankum",
                "countryCode": "DE"
            },
            {
                "name": "Apelern",
                "countryCode": "DE"
            },
            {
                "name": "Apen",
                "countryCode": "DE"
            },
            {
                "name": "Apensen",
                "countryCode": "DE"
            },
            {
                "name": "Appel",
                "countryCode": "DE"
            },
            {
                "name": "Arpke",
                "countryCode": "DE"
            },
            {
                "name": "Artlenburg",
                "countryCode": "DE"
            },
            {
                "name": "Asendorf",
                "countryCode": "DE"
            },
            {
                "name": "Auf der Horst",
                "countryCode": "DE"
            },
            {
                "name": "Auhagen",
                "countryCode": "DE"
            },
            {
                "name": "Aurich",
                "countryCode": "DE"
            },
            {
                "name": "Axstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bentheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bevensen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Eilsen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Essen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Fallingbostel",
                "countryCode": "DE"
            },
            {
                "name": "Bad Gandersheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Grund",
                "countryCode": "DE"
            },
            {
                "name": "Bad Harzburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Iburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Laer",
                "countryCode": "DE"
            },
            {
                "name": "Bad Lauterberg im Harz",
                "countryCode": "DE"
            },
            {
                "name": "Bad Münder am Deister",
                "countryCode": "DE"
            },
            {
                "name": "Bad Nenndorf",
                "countryCode": "DE"
            },
            {
                "name": "Bad Pyrmont",
                "countryCode": "DE"
            },
            {
                "name": "Bad Rothenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Bad Sachsa",
                "countryCode": "DE"
            },
            {
                "name": "Bad Salzdetfurth",
                "countryCode": "DE"
            },
            {
                "name": "Bad Zwischenahn",
                "countryCode": "DE"
            },
            {
                "name": "Badbergen",
                "countryCode": "DE"
            },
            {
                "name": "Baddeckenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Badenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bahrdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bahrenborstel",
                "countryCode": "DE"
            },
            {
                "name": "Bakum",
                "countryCode": "DE"
            },
            {
                "name": "Balge",
                "countryCode": "DE"
            },
            {
                "name": "Balje",
                "countryCode": "DE"
            },
            {
                "name": "Banteln",
                "countryCode": "DE"
            },
            {
                "name": "Bardowick",
                "countryCode": "DE"
            },
            {
                "name": "Barenburg",
                "countryCode": "DE"
            },
            {
                "name": "Barendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bargstedt",
                "countryCode": "DE"
            },
            {
                "name": "Barnstorf",
                "countryCode": "DE"
            },
            {
                "name": "Barsinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Barum",
                "countryCode": "DE"
            },
            {
                "name": "Barver",
                "countryCode": "DE"
            },
            {
                "name": "Barwedel",
                "countryCode": "DE"
            },
            {
                "name": "Barßel",
                "countryCode": "DE"
            },
            {
                "name": "Basdahl",
                "countryCode": "DE"
            },
            {
                "name": "Bassum",
                "countryCode": "DE"
            },
            {
                "name": "Bawinkel",
                "countryCode": "DE"
            },
            {
                "name": "Beckdorf",
                "countryCode": "DE"
            },
            {
                "name": "Beckedorf",
                "countryCode": "DE"
            },
            {
                "name": "Beedenbostel",
                "countryCode": "DE"
            },
            {
                "name": "Beesten",
                "countryCode": "DE"
            },
            {
                "name": "Bei der Höhne",
                "countryCode": "DE"
            },
            {
                "name": "Belm",
                "countryCode": "DE"
            },
            {
                "name": "Bendestorf",
                "countryCode": "DE"
            },
            {
                "name": "Berge",
                "countryCode": "DE"
            },
            {
                "name": "Bergen",
                "countryCode": "DE"
            },
            {
                "name": "Bergen an der Dumme",
                "countryCode": "DE"
            },
            {
                "name": "Bersenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Berumbur",
                "countryCode": "DE"
            },
            {
                "name": "Betheln",
                "countryCode": "DE"
            },
            {
                "name": "Betzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bevenrode",
                "countryCode": "DE"
            },
            {
                "name": "Bevern",
                "countryCode": "DE"
            },
            {
                "name": "Beverstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bienenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Bilshausen",
                "countryCode": "DE"
            },
            {
                "name": "Binnen",
                "countryCode": "DE"
            },
            {
                "name": "Bippen",
                "countryCode": "DE"
            },
            {
                "name": "Bispingen",
                "countryCode": "DE"
            },
            {
                "name": "Bissendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bleckede",
                "countryCode": "DE"
            },
            {
                "name": "Blender",
                "countryCode": "DE"
            },
            {
                "name": "Bliedersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Blomberg",
                "countryCode": "DE"
            },
            {
                "name": "Bockenem",
                "countryCode": "DE"
            },
            {
                "name": "Bockhorn",
                "countryCode": "DE"
            },
            {
                "name": "Bockhorst",
                "countryCode": "DE"
            },
            {
                "name": "Bodenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Bodenwerder",
                "countryCode": "DE"
            },
            {
                "name": "Boffzen",
                "countryCode": "DE"
            },
            {
                "name": "Bohmte",
                "countryCode": "DE"
            },
            {
                "name": "Bokel",
                "countryCode": "DE"
            },
            {
                "name": "Bomlitz",
                "countryCode": "DE"
            },
            {
                "name": "Borkum",
                "countryCode": "DE"
            },
            {
                "name": "Borstel",
                "countryCode": "DE"
            },
            {
                "name": "Bothel",
                "countryCode": "DE"
            },
            {
                "name": "Bovenden",
                "countryCode": "DE"
            },
            {
                "name": "Brackel",
                "countryCode": "DE"
            },
            {
                "name": "Brake (Unterweser)",
                "countryCode": "DE"
            },
            {
                "name": "Bramsche",
                "countryCode": "DE"
            },
            {
                "name": "Bramstedt",
                "countryCode": "DE"
            },
            {
                "name": "Braunlage",
                "countryCode": "DE"
            },
            {
                "name": "Braunschweig",
                "countryCode": "DE"
            },
            {
                "name": "Breddorf",
                "countryCode": "DE"
            },
            {
                "name": "Bremervörde",
                "countryCode": "DE"
            },
            {
                "name": "Brietlingen",
                "countryCode": "DE"
            },
            {
                "name": "Brockel",
                "countryCode": "DE"
            },
            {
                "name": "Brockum",
                "countryCode": "DE"
            },
            {
                "name": "Brome",
                "countryCode": "DE"
            },
            {
                "name": "Bruchhausen-Vilsen",
                "countryCode": "DE"
            },
            {
                "name": "Brüggen",
                "countryCode": "DE"
            },
            {
                "name": "Buchholz",
                "countryCode": "DE"
            },
            {
                "name": "Buchholz in der Nordheide",
                "countryCode": "DE"
            },
            {
                "name": "Bunde",
                "countryCode": "DE"
            },
            {
                "name": "Burgdorf",
                "countryCode": "DE"
            },
            {
                "name": "Butjadingen",
                "countryCode": "DE"
            },
            {
                "name": "Buxtehude",
                "countryCode": "DE"
            },
            {
                "name": "Börger",
                "countryCode": "DE"
            },
            {
                "name": "Börßum",
                "countryCode": "DE"
            },
            {
                "name": "Bösel",
                "countryCode": "DE"
            },
            {
                "name": "Bötersen",
                "countryCode": "DE"
            },
            {
                "name": "Bückeburg",
                "countryCode": "DE"
            },
            {
                "name": "Bücken",
                "countryCode": "DE"
            },
            {
                "name": "Büddenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bühren",
                "countryCode": "DE"
            },
            {
                "name": "Cadenberge",
                "countryCode": "DE"
            },
            {
                "name": "Calberlah",
                "countryCode": "DE"
            },
            {
                "name": "Cappeln",
                "countryCode": "DE"
            },
            {
                "name": "Celle",
                "countryCode": "DE"
            },
            {
                "name": "Clausthal-Zellerfeld",
                "countryCode": "DE"
            },
            {
                "name": "Clenze",
                "countryCode": "DE"
            },
            {
                "name": "Cloppenburg",
                "countryCode": "DE"
            },
            {
                "name": "Coppenbrügge",
                "countryCode": "DE"
            },
            {
                "name": "Cremlingen",
                "countryCode": "DE"
            },
            {
                "name": "Cuxhaven",
                "countryCode": "DE"
            },
            {
                "name": "Dahlenburg",
                "countryCode": "DE"
            },
            {
                "name": "Damme",
                "countryCode": "DE"
            },
            {
                "name": "Danndorf",
                "countryCode": "DE"
            },
            {
                "name": "Dannenberg",
                "countryCode": "DE"
            },
            {
                "name": "Dassel",
                "countryCode": "DE"
            },
            {
                "name": "Dedelstorf",
                "countryCode": "DE"
            },
            {
                "name": "Deensen",
                "countryCode": "DE"
            },
            {
                "name": "Deinste",
                "countryCode": "DE"
            },
            {
                "name": "Delligsen",
                "countryCode": "DE"
            },
            {
                "name": "Delmenhorst",
                "countryCode": "DE"
            },
            {
                "name": "Dersum",
                "countryCode": "DE"
            },
            {
                "name": "Destedt",
                "countryCode": "DE"
            },
            {
                "name": "Detern",
                "countryCode": "DE"
            },
            {
                "name": "Detmerode",
                "countryCode": "DE"
            },
            {
                "name": "Dettum",
                "countryCode": "DE"
            },
            {
                "name": "Deutsch Evern",
                "countryCode": "DE"
            },
            {
                "name": "Didderse",
                "countryCode": "DE"
            },
            {
                "name": "Diekholzen",
                "countryCode": "DE"
            },
            {
                "name": "Diepholz",
                "countryCode": "DE"
            },
            {
                "name": "Dinklage",
                "countryCode": "DE"
            },
            {
                "name": "Dissen",
                "countryCode": "DE"
            },
            {
                "name": "Dohren",
                "countryCode": "DE"
            },
            {
                "name": "Dollbergen",
                "countryCode": "DE"
            },
            {
                "name": "Dollern",
                "countryCode": "DE"
            },
            {
                "name": "Dornum",
                "countryCode": "DE"
            },
            {
                "name": "Dorum",
                "countryCode": "DE"
            },
            {
                "name": "Drage",
                "countryCode": "DE"
            },
            {
                "name": "Drakenburg",
                "countryCode": "DE"
            },
            {
                "name": "Drangstedt",
                "countryCode": "DE"
            },
            {
                "name": "Dransfeld",
                "countryCode": "DE"
            },
            {
                "name": "Drentwede",
                "countryCode": "DE"
            },
            {
                "name": "Drochtersen",
                "countryCode": "DE"
            },
            {
                "name": "Duderstadt",
                "countryCode": "DE"
            },
            {
                "name": "Duingen",
                "countryCode": "DE"
            },
            {
                "name": "Dunum",
                "countryCode": "DE"
            },
            {
                "name": "Dörpen",
                "countryCode": "DE"
            },
            {
                "name": "Dörverden",
                "countryCode": "DE"
            },
            {
                "name": "Dötlingen",
                "countryCode": "DE"
            },
            {
                "name": "Dünsen",
                "countryCode": "DE"
            },
            {
                "name": "Ebergötzen",
                "countryCode": "DE"
            },
            {
                "name": "Ebersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ebstorf",
                "countryCode": "DE"
            },
            {
                "name": "Echem",
                "countryCode": "DE"
            },
            {
                "name": "Edemissen",
                "countryCode": "DE"
            },
            {
                "name": "Edewecht",
                "countryCode": "DE"
            },
            {
                "name": "Egestorf",
                "countryCode": "DE"
            },
            {
                "name": "Eggermühlen",
                "countryCode": "DE"
            },
            {
                "name": "Ehrenburg",
                "countryCode": "DE"
            },
            {
                "name": "Eicklingen",
                "countryCode": "DE"
            },
            {
                "name": "Eime",
                "countryCode": "DE"
            },
            {
                "name": "Eimen",
                "countryCode": "DE"
            },
            {
                "name": "Eimke",
                "countryCode": "DE"
            },
            {
                "name": "Einbeck",
                "countryCode": "DE"
            },
            {
                "name": "Eisdorf am Harz",
                "countryCode": "DE"
            },
            {
                "name": "Elbe",
                "countryCode": "DE"
            },
            {
                "name": "Eldingen",
                "countryCode": "DE"
            },
            {
                "name": "Elsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Elsfleth",
                "countryCode": "DE"
            },
            {
                "name": "Elze",
                "countryCode": "DE"
            },
            {
                "name": "Embsen",
                "countryCode": "DE"
            },
            {
                "name": "Emden",
                "countryCode": "DE"
            },
            {
                "name": "Emlichheim",
                "countryCode": "DE"
            },
            {
                "name": "Emsbüren",
                "countryCode": "DE"
            },
            {
                "name": "Emstek",
                "countryCode": "DE"
            },
            {
                "name": "Emtinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Engeln",
                "countryCode": "DE"
            },
            {
                "name": "Erkerode",
                "countryCode": "DE"
            },
            {
                "name": "Eschede",
                "countryCode": "DE"
            },
            {
                "name": "Eschershausen",
                "countryCode": "DE"
            },
            {
                "name": "Esens",
                "countryCode": "DE"
            },
            {
                "name": "Essel",
                "countryCode": "DE"
            },
            {
                "name": "Essen",
                "countryCode": "DE"
            },
            {
                "name": "Esterwegen",
                "countryCode": "DE"
            },
            {
                "name": "Estorf",
                "countryCode": "DE"
            },
            {
                "name": "Evessen",
                "countryCode": "DE"
            },
            {
                "name": "Eydelstedt",
                "countryCode": "DE"
            },
            {
                "name": "Eyendorf",
                "countryCode": "DE"
            },
            {
                "name": "Eystrup",
                "countryCode": "DE"
            },
            {
                "name": "Faßberg",
                "countryCode": "DE"
            },
            {
                "name": "Fedderwarden",
                "countryCode": "DE"
            },
            {
                "name": "Filsum",
                "countryCode": "DE"
            },
            {
                "name": "Fintel",
                "countryCode": "DE"
            },
            {
                "name": "Flöthe",
                "countryCode": "DE"
            },
            {
                "name": "Freden",
                "countryCode": "DE"
            },
            {
                "name": "Freiburg",
                "countryCode": "DE"
            },
            {
                "name": "Freren",
                "countryCode": "DE"
            },
            {
                "name": "Friedeburg",
                "countryCode": "DE"
            },
            {
                "name": "Friedland",
                "countryCode": "DE"
            },
            {
                "name": "Friesoythe",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenau",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ganderkesee",
                "countryCode": "DE"
            },
            {
                "name": "Garbsen",
                "countryCode": "DE"
            },
            {
                "name": "Garbsen-Mitte",
                "countryCode": "DE"
            },
            {
                "name": "Garlstorf",
                "countryCode": "DE"
            },
            {
                "name": "Garrel",
                "countryCode": "DE"
            },
            {
                "name": "Garstedt",
                "countryCode": "DE"
            },
            {
                "name": "Gartow",
                "countryCode": "DE"
            },
            {
                "name": "Geeste",
                "countryCode": "DE"
            },
            {
                "name": "Gehrde",
                "countryCode": "DE"
            },
            {
                "name": "Gehrden",
                "countryCode": "DE"
            },
            {
                "name": "Gemeinde Friedland",
                "countryCode": "DE"
            },
            {
                "name": "Georgsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Georgsmarienhütte",
                "countryCode": "DE"
            },
            {
                "name": "Gerdau",
                "countryCode": "DE"
            },
            {
                "name": "Gersten",
                "countryCode": "DE"
            },
            {
                "name": "Gieboldehausen",
                "countryCode": "DE"
            },
            {
                "name": "Giesen",
                "countryCode": "DE"
            },
            {
                "name": "Gifhorn",
                "countryCode": "DE"
            },
            {
                "name": "Gilten",
                "countryCode": "DE"
            },
            {
                "name": "Gittelde",
                "countryCode": "DE"
            },
            {
                "name": "Glandorf",
                "countryCode": "DE"
            },
            {
                "name": "Gnarrenburg",
                "countryCode": "DE"
            },
            {
                "name": "Goldenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Golmbach",
                "countryCode": "DE"
            },
            {
                "name": "Goslar",
                "countryCode": "DE"
            },
            {
                "name": "Grafhorst",
                "countryCode": "DE"
            },
            {
                "name": "Grasberg",
                "countryCode": "DE"
            },
            {
                "name": "Grasleben",
                "countryCode": "DE"
            },
            {
                "name": "Gronau",
                "countryCode": "DE"
            },
            {
                "name": "Groß Ippener",
                "countryCode": "DE"
            },
            {
                "name": "Groß Munzel",
                "countryCode": "DE"
            },
            {
                "name": "Groß Oesingen",
                "countryCode": "DE"
            },
            {
                "name": "Groß Twülpstedt",
                "countryCode": "DE"
            },
            {
                "name": "Großenkneten",
                "countryCode": "DE"
            },
            {
                "name": "Großgoltern",
                "countryCode": "DE"
            },
            {
                "name": "Grünendeich",
                "countryCode": "DE"
            },
            {
                "name": "Guderhandviertel",
                "countryCode": "DE"
            },
            {
                "name": "Gyhum",
                "countryCode": "DE"
            },
            {
                "name": "Göttingen",
                "countryCode": "DE"
            },
            {
                "name": "Hage",
                "countryCode": "DE"
            },
            {
                "name": "Hagen",
                "countryCode": "DE"
            },
            {
                "name": "Hagen im Bremischen",
                "countryCode": "DE"
            },
            {
                "name": "Hagenburg",
                "countryCode": "DE"
            },
            {
                "name": "Halbemond",
                "countryCode": "DE"
            },
            {
                "name": "Halle",
                "countryCode": "DE"
            },
            {
                "name": "Hambergen",
                "countryCode": "DE"
            },
            {
                "name": "Hambühren",
                "countryCode": "DE"
            },
            {
                "name": "Hameln",
                "countryCode": "DE"
            },
            {
                "name": "Hammah",
                "countryCode": "DE"
            },
            {
                "name": "Handeloh",
                "countryCode": "DE"
            },
            {
                "name": "Handorf",
                "countryCode": "DE"
            },
            {
                "name": "Hankensbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Hannover",
                "countryCode": "DE"
            },
            {
                "name": "Hannoversch Münden",
                "countryCode": "DE"
            },
            {
                "name": "Hanstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hanstedt Eins",
                "countryCode": "DE"
            },
            {
                "name": "Hardegsen",
                "countryCode": "DE"
            },
            {
                "name": "Haren",
                "countryCode": "DE"
            },
            {
                "name": "Harpstedt",
                "countryCode": "DE"
            },
            {
                "name": "Harsefeld",
                "countryCode": "DE"
            },
            {
                "name": "Harsum",
                "countryCode": "DE"
            },
            {
                "name": "Hasbergen",
                "countryCode": "DE"
            },
            {
                "name": "Haselünne",
                "countryCode": "DE"
            },
            {
                "name": "Hassel",
                "countryCode": "DE"
            },
            {
                "name": "Hassendorf",
                "countryCode": "DE"
            },
            {
                "name": "Haste",
                "countryCode": "DE"
            },
            {
                "name": "Hatten",
                "countryCode": "DE"
            },
            {
                "name": "Hattorf",
                "countryCode": "DE"
            },
            {
                "name": "Haverlah",
                "countryCode": "DE"
            },
            {
                "name": "Haßbergen",
                "countryCode": "DE"
            },
            {
                "name": "Hechthausen",
                "countryCode": "DE"
            },
            {
                "name": "Heede",
                "countryCode": "DE"
            },
            {
                "name": "Heemsen",
                "countryCode": "DE"
            },
            {
                "name": "Heere",
                "countryCode": "DE"
            },
            {
                "name": "Heeslingen",
                "countryCode": "DE"
            },
            {
                "name": "Heeßen",
                "countryCode": "DE"
            },
            {
                "name": "Hehlen",
                "countryCode": "DE"
            },
            {
                "name": "Heidenau",
                "countryCode": "DE"
            },
            {
                "name": "Heinade",
                "countryCode": "DE"
            },
            {
                "name": "Heinböckel",
                "countryCode": "DE"
            },
            {
                "name": "Heinsen",
                "countryCode": "DE"
            },
            {
                "name": "Hellwege",
                "countryCode": "DE"
            },
            {
                "name": "Helmstedt",
                "countryCode": "DE"
            },
            {
                "name": "Helpsen",
                "countryCode": "DE"
            },
            {
                "name": "Hemmingen",
                "countryCode": "DE"
            },
            {
                "name": "Hemmoor",
                "countryCode": "DE"
            },
            {
                "name": "Hemsbünde",
                "countryCode": "DE"
            },
            {
                "name": "Hemslingen",
                "countryCode": "DE"
            },
            {
                "name": "Hepstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hermannsburg",
                "countryCode": "DE"
            },
            {
                "name": "Herzberg am Harz",
                "countryCode": "DE"
            },
            {
                "name": "Herzlake",
                "countryCode": "DE"
            },
            {
                "name": "Hesel",
                "countryCode": "DE"
            },
            {
                "name": "Hespe",
                "countryCode": "DE"
            },
            {
                "name": "Hessisch Oldendorf",
                "countryCode": "DE"
            },
            {
                "name": "Heuerßen",
                "countryCode": "DE"
            },
            {
                "name": "Heyersum",
                "countryCode": "DE"
            },
            {
                "name": "Hildesheim",
                "countryCode": "DE"
            },
            {
                "name": "Hilgermissen",
                "countryCode": "DE"
            },
            {
                "name": "Hillerse",
                "countryCode": "DE"
            },
            {
                "name": "Hilter",
                "countryCode": "DE"
            },
            {
                "name": "Himbergen",
                "countryCode": "DE"
            },
            {
                "name": "Himmelpforten",
                "countryCode": "DE"
            },
            {
                "name": "Hinte",
                "countryCode": "DE"
            },
            {
                "name": "Hipstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hitzacker",
                "countryCode": "DE"
            },
            {
                "name": "Hodenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Hohenhameln",
                "countryCode": "DE"
            },
            {
                "name": "Hohnhorst",
                "countryCode": "DE"
            },
            {
                "name": "Holdorf",
                "countryCode": "DE"
            },
            {
                "name": "Holle",
                "countryCode": "DE"
            },
            {
                "name": "Hollenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Holtland",
                "countryCode": "DE"
            },
            {
                "name": "Holzminden",
                "countryCode": "DE"
            },
            {
                "name": "Hoogstede",
                "countryCode": "DE"
            },
            {
                "name": "Hornburg",
                "countryCode": "DE"
            },
            {
                "name": "Horneburg",
                "countryCode": "DE"
            },
            {
                "name": "Horstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hoya",
                "countryCode": "DE"
            },
            {
                "name": "Hude",
                "countryCode": "DE"
            },
            {
                "name": "Huede",
                "countryCode": "DE"
            },
            {
                "name": "Husum",
                "countryCode": "DE"
            },
            {
                "name": "Hänigsen",
                "countryCode": "DE"
            },
            {
                "name": "Hörden",
                "countryCode": "DE"
            },
            {
                "name": "Hülsede",
                "countryCode": "DE"
            },
            {
                "name": "Ihlienworth",
                "countryCode": "DE"
            },
            {
                "name": "Ilsede",
                "countryCode": "DE"
            },
            {
                "name": "Isenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Isernhagen Farster Bauerschaft",
                "countryCode": "DE"
            },
            {
                "name": "Itterbeck",
                "countryCode": "DE"
            },
            {
                "name": "Jameln",
                "countryCode": "DE"
            },
            {
                "name": "Jembke",
                "countryCode": "DE"
            },
            {
                "name": "Jemgum",
                "countryCode": "DE"
            },
            {
                "name": "Jerxheim",
                "countryCode": "DE"
            },
            {
                "name": "Jesteburg",
                "countryCode": "DE"
            },
            {
                "name": "Jever",
                "countryCode": "DE"
            },
            {
                "name": "Jork",
                "countryCode": "DE"
            },
            {
                "name": "Juist",
                "countryCode": "DE"
            },
            {
                "name": "Jühnde",
                "countryCode": "DE"
            },
            {
                "name": "Kakenstorf",
                "countryCode": "DE"
            },
            {
                "name": "Kalefeld",
                "countryCode": "DE"
            },
            {
                "name": "Katlenburg-Lindau",
                "countryCode": "DE"
            },
            {
                "name": "Kettenkamp",
                "countryCode": "DE"
            },
            {
                "name": "Kirchbrak",
                "countryCode": "DE"
            },
            {
                "name": "Kirchdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kirchgellersen",
                "countryCode": "DE"
            },
            {
                "name": "Kirchlinteln",
                "countryCode": "DE"
            },
            {
                "name": "Kirchseelte",
                "countryCode": "DE"
            },
            {
                "name": "Kirchtimke",
                "countryCode": "DE"
            },
            {
                "name": "Kirchwalsede",
                "countryCode": "DE"
            },
            {
                "name": "Kissenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Klein Berßen",
                "countryCode": "DE"
            },
            {
                "name": "Klein Gusborn",
                "countryCode": "DE"
            },
            {
                "name": "Klein Schwülper",
                "countryCode": "DE"
            },
            {
                "name": "Kluse",
                "countryCode": "DE"
            },
            {
                "name": "Krebeck",
                "countryCode": "DE"
            },
            {
                "name": "Kreiensen",
                "countryCode": "DE"
            },
            {
                "name": "Kutenholz",
                "countryCode": "DE"
            },
            {
                "name": "Königslutter am Elm",
                "countryCode": "DE"
            },
            {
                "name": "Kührstedt",
                "countryCode": "DE"
            },
            {
                "name": "Küsten",
                "countryCode": "DE"
            },
            {
                "name": "Laar",
                "countryCode": "DE"
            },
            {
                "name": "Laatzen",
                "countryCode": "DE"
            },
            {
                "name": "Lachendorf",
                "countryCode": "DE"
            },
            {
                "name": "Lamspringe",
                "countryCode": "DE"
            },
            {
                "name": "Lamstedt",
                "countryCode": "DE"
            },
            {
                "name": "Landesbergen",
                "countryCode": "DE"
            },
            {
                "name": "Landolfshausen",
                "countryCode": "DE"
            },
            {
                "name": "Langelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Langen",
                "countryCode": "DE"
            },
            {
                "name": "Langenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Langeoog",
                "countryCode": "DE"
            },
            {
                "name": "Langlingen",
                "countryCode": "DE"
            },
            {
                "name": "Langwedel",
                "countryCode": "DE"
            },
            {
                "name": "Lastrup",
                "countryCode": "DE"
            },
            {
                "name": "Lathen",
                "countryCode": "DE"
            },
            {
                "name": "Lauenau",
                "countryCode": "DE"
            },
            {
                "name": "Lauenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Lauenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Leer",
                "countryCode": "DE"
            },
            {
                "name": "Leese",
                "countryCode": "DE"
            },
            {
                "name": "Leezdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lehre",
                "countryCode": "DE"
            },
            {
                "name": "Lehrte",
                "countryCode": "DE"
            },
            {
                "name": "Leiferde",
                "countryCode": "DE"
            },
            {
                "name": "Lemförde",
                "countryCode": "DE"
            },
            {
                "name": "Lemwerder",
                "countryCode": "DE"
            },
            {
                "name": "Lengede",
                "countryCode": "DE"
            },
            {
                "name": "Lengerich",
                "countryCode": "DE"
            },
            {
                "name": "Liebenau",
                "countryCode": "DE"
            },
            {
                "name": "Liebenburg",
                "countryCode": "DE"
            },
            {
                "name": "Lilienthal",
                "countryCode": "DE"
            },
            {
                "name": "Lindhorst",
                "countryCode": "DE"
            },
            {
                "name": "Lindwedel",
                "countryCode": "DE"
            },
            {
                "name": "Lingen",
                "countryCode": "DE"
            },
            {
                "name": "Lintig",
                "countryCode": "DE"
            },
            {
                "name": "Lohne",
                "countryCode": "DE"
            },
            {
                "name": "Lorup",
                "countryCode": "DE"
            },
            {
                "name": "Loxstedt",
                "countryCode": "DE"
            },
            {
                "name": "Luhden",
                "countryCode": "DE"
            },
            {
                "name": "Lunestedt",
                "countryCode": "DE"
            },
            {
                "name": "Lutter am Barenberge",
                "countryCode": "DE"
            },
            {
                "name": "Lähden",
                "countryCode": "DE"
            },
            {
                "name": "Löningen",
                "countryCode": "DE"
            },
            {
                "name": "Lüchow",
                "countryCode": "DE"
            },
            {
                "name": "Lüder",
                "countryCode": "DE"
            },
            {
                "name": "Lüdersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Lüneburg",
                "countryCode": "DE"
            },
            {
                "name": "Lünne",
                "countryCode": "DE"
            },
            {
                "name": "Marienhafe",
                "countryCode": "DE"
            },
            {
                "name": "Mariental",
                "countryCode": "DE"
            },
            {
                "name": "Marklohe",
                "countryCode": "DE"
            },
            {
                "name": "Marschacht",
                "countryCode": "DE"
            },
            {
                "name": "Martfeld",
                "countryCode": "DE"
            },
            {
                "name": "Marxen",
                "countryCode": "DE"
            },
            {
                "name": "Meerbeck",
                "countryCode": "DE"
            },
            {
                "name": "Meerdorf",
                "countryCode": "DE"
            },
            {
                "name": "Meinersen",
                "countryCode": "DE"
            },
            {
                "name": "Melbeck",
                "countryCode": "DE"
            },
            {
                "name": "Melle",
                "countryCode": "DE"
            },
            {
                "name": "Mellinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Menslage",
                "countryCode": "DE"
            },
            {
                "name": "Meppen",
                "countryCode": "DE"
            },
            {
                "name": "Merzen",
                "countryCode": "DE"
            },
            {
                "name": "Messingen",
                "countryCode": "DE"
            },
            {
                "name": "Midlum",
                "countryCode": "DE"
            },
            {
                "name": "Mittegroßefehn",
                "countryCode": "DE"
            },
            {
                "name": "Moisburg",
                "countryCode": "DE"
            },
            {
                "name": "Molbergen",
                "countryCode": "DE"
            },
            {
                "name": "Moringen",
                "countryCode": "DE"
            },
            {
                "name": "Morsum",
                "countryCode": "DE"
            },
            {
                "name": "Munster",
                "countryCode": "DE"
            },
            {
                "name": "Müden",
                "countryCode": "DE"
            },
            {
                "name": "Nahrendorf",
                "countryCode": "DE"
            },
            {
                "name": "Neu Darchau",
                "countryCode": "DE"
            },
            {
                "name": "Neu Wulmstorf",
                "countryCode": "DE"
            },
            {
                "name": "Neubrück",
                "countryCode": "DE"
            },
            {
                "name": "Neubörger",
                "countryCode": "DE"
            },
            {
                "name": "Neuenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neuharlingersiel",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus an der Oste",
                "countryCode": "DE"
            },
            {
                "name": "Neukamperfehn",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt am Rübenberge",
                "countryCode": "DE"
            },
            {
                "name": "Niederlangen",
                "countryCode": "DE"
            },
            {
                "name": "Niedernwöhren",
                "countryCode": "DE"
            },
            {
                "name": "Nienburg",
                "countryCode": "DE"
            },
            {
                "name": "Nienstädt",
                "countryCode": "DE"
            },
            {
                "name": "Norddeich",
                "countryCode": "DE"
            },
            {
                "name": "Norden",
                "countryCode": "DE"
            },
            {
                "name": "Nordenham",
                "countryCode": "DE"
            },
            {
                "name": "Norderney",
                "countryCode": "DE"
            },
            {
                "name": "Nordholz",
                "countryCode": "DE"
            },
            {
                "name": "Nordhorn",
                "countryCode": "DE"
            },
            {
                "name": "Nordleda",
                "countryCode": "DE"
            },
            {
                "name": "Nordstemmen",
                "countryCode": "DE"
            },
            {
                "name": "Northeim",
                "countryCode": "DE"
            },
            {
                "name": "Nortmoor",
                "countryCode": "DE"
            },
            {
                "name": "Nortrup",
                "countryCode": "DE"
            },
            {
                "name": "Nottensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Nörten-Hardenberg",
                "countryCode": "DE"
            },
            {
                "name": "Oberndorf",
                "countryCode": "DE"
            },
            {
                "name": "Obernfeld",
                "countryCode": "DE"
            },
            {
                "name": "Obernkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Oederquart",
                "countryCode": "DE"
            },
            {
                "name": "Oerel",
                "countryCode": "DE"
            },
            {
                "name": "Oldenburg",
                "countryCode": "DE"
            },
            {
                "name": "Oldendorf",
                "countryCode": "DE"
            },
            {
                "name": "Osloß",
                "countryCode": "DE"
            },
            {
                "name": "Osnabrück",
                "countryCode": "DE"
            },
            {
                "name": "Osteel",
                "countryCode": "DE"
            },
            {
                "name": "Osten",
                "countryCode": "DE"
            },
            {
                "name": "Ostercappeln",
                "countryCode": "DE"
            },
            {
                "name": "Osterholz-Scharmbeck",
                "countryCode": "DE"
            },
            {
                "name": "Osterode am Harz",
                "countryCode": "DE"
            },
            {
                "name": "Ostrhauderfehn",
                "countryCode": "DE"
            },
            {
                "name": "Ottenstein",
                "countryCode": "DE"
            },
            {
                "name": "Otter",
                "countryCode": "DE"
            },
            {
                "name": "Otterndorf",
                "countryCode": "DE"
            },
            {
                "name": "Ottersberg",
                "countryCode": "DE"
            },
            {
                "name": "Ovelgönne",
                "countryCode": "DE"
            },
            {
                "name": "Oyten",
                "countryCode": "DE"
            },
            {
                "name": "Papenburg",
                "countryCode": "DE"
            },
            {
                "name": "Parsau",
                "countryCode": "DE"
            },
            {
                "name": "Pattensen",
                "countryCode": "DE"
            },
            {
                "name": "Peine",
                "countryCode": "DE"
            },
            {
                "name": "Pennigsehl",
                "countryCode": "DE"
            },
            {
                "name": "Polle",
                "countryCode": "DE"
            },
            {
                "name": "Pollhagen",
                "countryCode": "DE"
            },
            {
                "name": "Quakenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Radbruch",
                "countryCode": "DE"
            },
            {
                "name": "Raddestorf",
                "countryCode": "DE"
            },
            {
                "name": "Rastede",
                "countryCode": "DE"
            },
            {
                "name": "Rechtsupweg",
                "countryCode": "DE"
            },
            {
                "name": "Reeßum",
                "countryCode": "DE"
            },
            {
                "name": "Regesbostel",
                "countryCode": "DE"
            },
            {
                "name": "Rehburg-Loccum",
                "countryCode": "DE"
            },
            {
                "name": "Rehden",
                "countryCode": "DE"
            },
            {
                "name": "Reinstorf",
                "countryCode": "DE"
            },
            {
                "name": "Remlingen",
                "countryCode": "DE"
            },
            {
                "name": "Reppenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Rethem",
                "countryCode": "DE"
            },
            {
                "name": "Rethen",
                "countryCode": "DE"
            },
            {
                "name": "Rhade",
                "countryCode": "DE"
            },
            {
                "name": "Rhede",
                "countryCode": "DE"
            },
            {
                "name": "Rheden",
                "countryCode": "DE"
            },
            {
                "name": "Rhumspringe",
                "countryCode": "DE"
            },
            {
                "name": "Ribbesbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Riede",
                "countryCode": "DE"
            },
            {
                "name": "Rieste",
                "countryCode": "DE"
            },
            {
                "name": "Rinteln",
                "countryCode": "DE"
            },
            {
                "name": "Ritterhude",
                "countryCode": "DE"
            },
            {
                "name": "Rodenberg",
                "countryCode": "DE"
            },
            {
                "name": "Ronnenberg",
                "countryCode": "DE"
            },
            {
                "name": "Rosche",
                "countryCode": "DE"
            },
            {
                "name": "Rosdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rotenburg",
                "countryCode": "DE"
            },
            {
                "name": "Rullstorf",
                "countryCode": "DE"
            },
            {
                "name": "Röhrsen",
                "countryCode": "DE"
            },
            {
                "name": "Rötgesbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Rühen",
                "countryCode": "DE"
            },
            {
                "name": "Sachsenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Salzbergen",
                "countryCode": "DE"
            },
            {
                "name": "Salzgitter",
                "countryCode": "DE"
            },
            {
                "name": "Salzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Salzhemmendorf",
                "countryCode": "DE"
            },
            {
                "name": "Sande",
                "countryCode": "DE"
            },
            {
                "name": "Sandstedt",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Andreasberg",
                "countryCode": "DE"
            },
            {
                "name": "Sarstedt",
                "countryCode": "DE"
            },
            {
                "name": "Sassenburg",
                "countryCode": "DE"
            },
            {
                "name": "Sauensiek",
                "countryCode": "DE"
            },
            {
                "name": "Schapen",
                "countryCode": "DE"
            },
            {
                "name": "Scharnebeck",
                "countryCode": "DE"
            },
            {
                "name": "Scheden",
                "countryCode": "DE"
            },
            {
                "name": "Scheeßel",
                "countryCode": "DE"
            },
            {
                "name": "Schellerten",
                "countryCode": "DE"
            },
            {
                "name": "Schiffdorf",
                "countryCode": "DE"
            },
            {
                "name": "Schillig",
                "countryCode": "DE"
            },
            {
                "name": "Schladen",
                "countryCode": "DE"
            },
            {
                "name": "Schnega",
                "countryCode": "DE"
            },
            {
                "name": "Schneverdingen",
                "countryCode": "DE"
            },
            {
                "name": "Schortens",
                "countryCode": "DE"
            },
            {
                "name": "Schwaförden",
                "countryCode": "DE"
            },
            {
                "name": "Schwanewede",
                "countryCode": "DE"
            },
            {
                "name": "Schwarme",
                "countryCode": "DE"
            },
            {
                "name": "Schwarmstedt",
                "countryCode": "DE"
            },
            {
                "name": "Schöningen",
                "countryCode": "DE"
            },
            {
                "name": "Schöppenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Schüttorf",
                "countryCode": "DE"
            },
            {
                "name": "Seeburg",
                "countryCode": "DE"
            },
            {
                "name": "Seelze",
                "countryCode": "DE"
            },
            {
                "name": "Seesen",
                "countryCode": "DE"
            },
            {
                "name": "Seevetal",
                "countryCode": "DE"
            },
            {
                "name": "Seggebruch",
                "countryCode": "DE"
            },
            {
                "name": "Sehlde",
                "countryCode": "DE"
            },
            {
                "name": "Sehlem",
                "countryCode": "DE"
            },
            {
                "name": "Sehnde",
                "countryCode": "DE"
            },
            {
                "name": "Selsingen",
                "countryCode": "DE"
            },
            {
                "name": "Seulingen",
                "countryCode": "DE"
            },
            {
                "name": "Sibbesse",
                "countryCode": "DE"
            },
            {
                "name": "Sickte",
                "countryCode": "DE"
            },
            {
                "name": "Siedenburg",
                "countryCode": "DE"
            },
            {
                "name": "Sillenstede",
                "countryCode": "DE"
            },
            {
                "name": "Sittensen",
                "countryCode": "DE"
            },
            {
                "name": "Soderstorf",
                "countryCode": "DE"
            },
            {
                "name": "Soltau",
                "countryCode": "DE"
            },
            {
                "name": "Soltendieck",
                "countryCode": "DE"
            },
            {
                "name": "Sottrum",
                "countryCode": "DE"
            },
            {
                "name": "Spelle",
                "countryCode": "DE"
            },
            {
                "name": "Sprakensehl",
                "countryCode": "DE"
            },
            {
                "name": "Springe",
                "countryCode": "DE"
            },
            {
                "name": "Stade",
                "countryCode": "DE"
            },
            {
                "name": "Stadensen",
                "countryCode": "DE"
            },
            {
                "name": "Stadthagen",
                "countryCode": "DE"
            },
            {
                "name": "Stadtoldendorf",
                "countryCode": "DE"
            },
            {
                "name": "Stedesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Steimbke",
                "countryCode": "DE"
            },
            {
                "name": "Steinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Steinhorst",
                "countryCode": "DE"
            },
            {
                "name": "Steinkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Stelle",
                "countryCode": "DE"
            },
            {
                "name": "Steyerberg",
                "countryCode": "DE"
            },
            {
                "name": "Stolzenau",
                "countryCode": "DE"
            },
            {
                "name": "Stuhr",
                "countryCode": "DE"
            },
            {
                "name": "Stöckse",
                "countryCode": "DE"
            },
            {
                "name": "Suddendorf",
                "countryCode": "DE"
            },
            {
                "name": "Suderburg",
                "countryCode": "DE"
            },
            {
                "name": "Sudwalde",
                "countryCode": "DE"
            },
            {
                "name": "Suhlendorf",
                "countryCode": "DE"
            },
            {
                "name": "Sulingen",
                "countryCode": "DE"
            },
            {
                "name": "Surwold",
                "countryCode": "DE"
            },
            {
                "name": "Sustrum",
                "countryCode": "DE"
            },
            {
                "name": "Syke",
                "countryCode": "DE"
            },
            {
                "name": "Sögel",
                "countryCode": "DE"
            },
            {
                "name": "Söhlde",
                "countryCode": "DE"
            },
            {
                "name": "Südergellersen",
                "countryCode": "DE"
            },
            {
                "name": "Süpplingen",
                "countryCode": "DE"
            },
            {
                "name": "Süstedt",
                "countryCode": "DE"
            },
            {
                "name": "Tappenbeck",
                "countryCode": "DE"
            },
            {
                "name": "Tarmstedt",
                "countryCode": "DE"
            },
            {
                "name": "Tespe",
                "countryCode": "DE"
            },
            {
                "name": "Thedinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Thomasburg",
                "countryCode": "DE"
            },
            {
                "name": "Thuine",
                "countryCode": "DE"
            },
            {
                "name": "Tiddische",
                "countryCode": "DE"
            },
            {
                "name": "Toppenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Tostedt",
                "countryCode": "DE"
            },
            {
                "name": "Twistringen",
                "countryCode": "DE"
            },
            {
                "name": "Tülau",
                "countryCode": "DE"
            },
            {
                "name": "Uchte",
                "countryCode": "DE"
            },
            {
                "name": "Uehrde",
                "countryCode": "DE"
            },
            {
                "name": "Uelsen",
                "countryCode": "DE"
            },
            {
                "name": "Uelzen",
                "countryCode": "DE"
            },
            {
                "name": "Uetze",
                "countryCode": "DE"
            },
            {
                "name": "Ummern",
                "countryCode": "DE"
            },
            {
                "name": "Unterlüß",
                "countryCode": "DE"
            },
            {
                "name": "Upgant-Schott",
                "countryCode": "DE"
            },
            {
                "name": "Uslar",
                "countryCode": "DE"
            },
            {
                "name": "Uthlede",
                "countryCode": "DE"
            },
            {
                "name": "Varel",
                "countryCode": "DE"
            },
            {
                "name": "Varrel",
                "countryCode": "DE"
            },
            {
                "name": "Vechelde",
                "countryCode": "DE"
            },
            {
                "name": "Vechta",
                "countryCode": "DE"
            },
            {
                "name": "Velpke",
                "countryCode": "DE"
            },
            {
                "name": "Veltheim",
                "countryCode": "DE"
            },
            {
                "name": "Verden",
                "countryCode": "DE"
            },
            {
                "name": "Vienenburg",
                "countryCode": "DE"
            },
            {
                "name": "Visbek",
                "countryCode": "DE"
            },
            {
                "name": "Visselhövede",
                "countryCode": "DE"
            },
            {
                "name": "Vollersode",
                "countryCode": "DE"
            },
            {
                "name": "Voltlage",
                "countryCode": "DE"
            },
            {
                "name": "Vordorf",
                "countryCode": "DE"
            },
            {
                "name": "Vorwerk",
                "countryCode": "DE"
            },
            {
                "name": "Vrees",
                "countryCode": "DE"
            },
            {
                "name": "Vögelsen",
                "countryCode": "DE"
            },
            {
                "name": "Waake",
                "countryCode": "DE"
            },
            {
                "name": "Wagenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Wagenhoff",
                "countryCode": "DE"
            },
            {
                "name": "Wahrenholz",
                "countryCode": "DE"
            },
            {
                "name": "Walchum",
                "countryCode": "DE"
            },
            {
                "name": "Walkenried",
                "countryCode": "DE"
            },
            {
                "name": "Wallenhorst",
                "countryCode": "DE"
            },
            {
                "name": "Walsrode",
                "countryCode": "DE"
            },
            {
                "name": "Wangerooge",
                "countryCode": "DE"
            },
            {
                "name": "Wanna",
                "countryCode": "DE"
            },
            {
                "name": "Wardenburg",
                "countryCode": "DE"
            },
            {
                "name": "Warmsen",
                "countryCode": "DE"
            },
            {
                "name": "Wasbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Wathlingen",
                "countryCode": "DE"
            },
            {
                "name": "Weener",
                "countryCode": "DE"
            },
            {
                "name": "Welle",
                "countryCode": "DE"
            },
            {
                "name": "Wendeburg",
                "countryCode": "DE"
            },
            {
                "name": "Wendisch Evern",
                "countryCode": "DE"
            },
            {
                "name": "Wennigsen",
                "countryCode": "DE"
            },
            {
                "name": "Wenzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Werlte",
                "countryCode": "DE"
            },
            {
                "name": "Werpeloh",
                "countryCode": "DE"
            },
            {
                "name": "Wesendorf",
                "countryCode": "DE"
            },
            {
                "name": "Weste",
                "countryCode": "DE"
            },
            {
                "name": "Westergellersen",
                "countryCode": "DE"
            },
            {
                "name": "Westerholt",
                "countryCode": "DE"
            },
            {
                "name": "Westerstede",
                "countryCode": "DE"
            },
            {
                "name": "Westhagen",
                "countryCode": "DE"
            },
            {
                "name": "Wetschen",
                "countryCode": "DE"
            },
            {
                "name": "Weyhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wieda",
                "countryCode": "DE"
            },
            {
                "name": "Wiedensahl",
                "countryCode": "DE"
            },
            {
                "name": "Wiefelstede",
                "countryCode": "DE"
            },
            {
                "name": "Wienhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wieren",
                "countryCode": "DE"
            },
            {
                "name": "Wiesmoor",
                "countryCode": "DE"
            },
            {
                "name": "Wietmarschen",
                "countryCode": "DE"
            },
            {
                "name": "Wietze",
                "countryCode": "DE"
            },
            {
                "name": "Wietzen",
                "countryCode": "DE"
            },
            {
                "name": "Wietzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Wildemann",
                "countryCode": "DE"
            },
            {
                "name": "Wildeshausen",
                "countryCode": "DE"
            },
            {
                "name": "Wilhelmshaven",
                "countryCode": "DE"
            },
            {
                "name": "Wilstedt",
                "countryCode": "DE"
            },
            {
                "name": "Wilsum",
                "countryCode": "DE"
            },
            {
                "name": "Windhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wingst",
                "countryCode": "DE"
            },
            {
                "name": "Winsen",
                "countryCode": "DE"
            },
            {
                "name": "Wirdum",
                "countryCode": "DE"
            },
            {
                "name": "Wischhafen",
                "countryCode": "DE"
            },
            {
                "name": "Wistedt",
                "countryCode": "DE"
            },
            {
                "name": "Wittingen",
                "countryCode": "DE"
            },
            {
                "name": "Wittmar",
                "countryCode": "DE"
            },
            {
                "name": "Wittmund",
                "countryCode": "DE"
            },
            {
                "name": "Wittorf",
                "countryCode": "DE"
            },
            {
                "name": "Wolfenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Wolfsburg",
                "countryCode": "DE"
            },
            {
                "name": "Wolsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Woltersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Worpswede",
                "countryCode": "DE"
            },
            {
                "name": "Wremen",
                "countryCode": "DE"
            },
            {
                "name": "Wrestedt",
                "countryCode": "DE"
            },
            {
                "name": "Wriedel",
                "countryCode": "DE"
            },
            {
                "name": "Wulfsen",
                "countryCode": "DE"
            },
            {
                "name": "Wulften",
                "countryCode": "DE"
            },
            {
                "name": "Wulsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Wunstorf",
                "countryCode": "DE"
            },
            {
                "name": "Wustrow",
                "countryCode": "DE"
            },
            {
                "name": "Wölpinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Zernien",
                "countryCode": "DE"
            },
            {
                "name": "Zetel",
                "countryCode": "DE"
            },
            {
                "name": "Zeven",
                "countryCode": "DE"
            },
            {
                "name": "Zorge",
                "countryCode": "DE"
            },
            {
                "name": "Aachen",
                "countryCode": "DE"
            },
            {
                "name": "Ahaus",
                "countryCode": "DE"
            },
            {
                "name": "Ahlen",
                "countryCode": "DE"
            },
            {
                "name": "Aldenhoven",
                "countryCode": "DE"
            },
            {
                "name": "Alfter",
                "countryCode": "DE"
            },
            {
                "name": "Alpen",
                "countryCode": "DE"
            },
            {
                "name": "Alsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Altena",
                "countryCode": "DE"
            },
            {
                "name": "Altenbeken",
                "countryCode": "DE"
            },
            {
                "name": "Altenberge",
                "countryCode": "DE"
            },
            {
                "name": "Altenbüren",
                "countryCode": "DE"
            },
            {
                "name": "Altstadt Nord",
                "countryCode": "DE"
            },
            {
                "name": "Altstadt Sud",
                "countryCode": "DE"
            },
            {
                "name": "Anröchte",
                "countryCode": "DE"
            },
            {
                "name": "Arnsberg",
                "countryCode": "DE"
            },
            {
                "name": "Ascheberg",
                "countryCode": "DE"
            },
            {
                "name": "Attendorn",
                "countryCode": "DE"
            },
            {
                "name": "Augustdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bad Berleburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Driburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Fredeburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Holzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Honnef",
                "countryCode": "DE"
            },
            {
                "name": "Bad Laasphe",
                "countryCode": "DE"
            },
            {
                "name": "Bad Lippspringe",
                "countryCode": "DE"
            },
            {
                "name": "Bad Meinberg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Münstereifel",
                "countryCode": "DE"
            },
            {
                "name": "Bad Oeynhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Salzuflen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Sassendorf",
                "countryCode": "DE"
            },
            {
                "name": "Baesweiler",
                "countryCode": "DE"
            },
            {
                "name": "Balve",
                "countryCode": "DE"
            },
            {
                "name": "Bamenohl",
                "countryCode": "DE"
            },
            {
                "name": "Barntrup",
                "countryCode": "DE"
            },
            {
                "name": "Bayenthal",
                "countryCode": "DE"
            },
            {
                "name": "Beckum",
                "countryCode": "DE"
            },
            {
                "name": "Bedburg",
                "countryCode": "DE"
            },
            {
                "name": "Beelen",
                "countryCode": "DE"
            },
            {
                "name": "Bergheim",
                "countryCode": "DE"
            },
            {
                "name": "Bergisch Gladbach",
                "countryCode": "DE"
            },
            {
                "name": "Bergkamen",
                "countryCode": "DE"
            },
            {
                "name": "Bergneustadt",
                "countryCode": "DE"
            },
            {
                "name": "Bestwig",
                "countryCode": "DE"
            },
            {
                "name": "Beverungen",
                "countryCode": "DE"
            },
            {
                "name": "Bielefeld",
                "countryCode": "DE"
            },
            {
                "name": "Bilderstoeckchen",
                "countryCode": "DE"
            },
            {
                "name": "Billerbeck",
                "countryCode": "DE"
            },
            {
                "name": "Birgte",
                "countryCode": "DE"
            },
            {
                "name": "Blankenheim",
                "countryCode": "DE"
            },
            {
                "name": "Blomberg",
                "countryCode": "DE"
            },
            {
                "name": "Bocholt",
                "countryCode": "DE"
            },
            {
                "name": "Bochum",
                "countryCode": "DE"
            },
            {
                "name": "Bochum-Hordel",
                "countryCode": "DE"
            },
            {
                "name": "Bonn",
                "countryCode": "DE"
            },
            {
                "name": "Borgentreich",
                "countryCode": "DE"
            },
            {
                "name": "Borgholzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Borken",
                "countryCode": "DE"
            },
            {
                "name": "Bornheim",
                "countryCode": "DE"
            },
            {
                "name": "Bottrop",
                "countryCode": "DE"
            },
            {
                "name": "Brakel",
                "countryCode": "DE"
            },
            {
                "name": "Breckerfeld",
                "countryCode": "DE"
            },
            {
                "name": "Brilon",
                "countryCode": "DE"
            },
            {
                "name": "Brüggen",
                "countryCode": "DE"
            },
            {
                "name": "Brühl",
                "countryCode": "DE"
            },
            {
                "name": "Buchforst",
                "countryCode": "DE"
            },
            {
                "name": "Buchheim",
                "countryCode": "DE"
            },
            {
                "name": "Burbach",
                "countryCode": "DE"
            },
            {
                "name": "Burscheid",
                "countryCode": "DE"
            },
            {
                "name": "Bönen",
                "countryCode": "DE"
            },
            {
                "name": "Bünde",
                "countryCode": "DE"
            },
            {
                "name": "Büren",
                "countryCode": "DE"
            },
            {
                "name": "Castrop-Rauxel",
                "countryCode": "DE"
            },
            {
                "name": "Coesfeld",
                "countryCode": "DE"
            },
            {
                "name": "Dahlem",
                "countryCode": "DE"
            },
            {
                "name": "Datteln",
                "countryCode": "DE"
            },
            {
                "name": "Delbrück",
                "countryCode": "DE"
            },
            {
                "name": "Detmold",
                "countryCode": "DE"
            },
            {
                "name": "Deutz",
                "countryCode": "DE"
            },
            {
                "name": "Dinslaken",
                "countryCode": "DE"
            },
            {
                "name": "Dormagen",
                "countryCode": "DE"
            },
            {
                "name": "Dorsten",
                "countryCode": "DE"
            },
            {
                "name": "Dortmund",
                "countryCode": "DE"
            },
            {
                "name": "Dreierwalde",
                "countryCode": "DE"
            },
            {
                "name": "Drensteinfurt",
                "countryCode": "DE"
            },
            {
                "name": "Drolshagen",
                "countryCode": "DE"
            },
            {
                "name": "Duisburg",
                "countryCode": "DE"
            },
            {
                "name": "Dörentrup",
                "countryCode": "DE"
            },
            {
                "name": "Dülmen",
                "countryCode": "DE"
            },
            {
                "name": "Düren",
                "countryCode": "DE"
            },
            {
                "name": "Düsseldorf",
                "countryCode": "DE"
            },
            {
                "name": "Düsseldorf District",
                "countryCode": "DE"
            },
            {
                "name": "Düsseldorf-Pempelfort",
                "countryCode": "DE"
            },
            {
                "name": "Eil",
                "countryCode": "DE"
            },
            {
                "name": "Eitorf",
                "countryCode": "DE"
            },
            {
                "name": "Elsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Emmerich",
                "countryCode": "DE"
            },
            {
                "name": "Emsdetten",
                "countryCode": "DE"
            },
            {
                "name": "Engelskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Enger",
                "countryCode": "DE"
            },
            {
                "name": "Ennepetal",
                "countryCode": "DE"
            },
            {
                "name": "Ennigerloh",
                "countryCode": "DE"
            },
            {
                "name": "Erftstadt",
                "countryCode": "DE"
            },
            {
                "name": "Erkelenz",
                "countryCode": "DE"
            },
            {
                "name": "Erkrath",
                "countryCode": "DE"
            },
            {
                "name": "Erndtebrück",
                "countryCode": "DE"
            },
            {
                "name": "Erwitte",
                "countryCode": "DE"
            },
            {
                "name": "Eschweiler",
                "countryCode": "DE"
            },
            {
                "name": "Eslohe",
                "countryCode": "DE"
            },
            {
                "name": "Espelkamp",
                "countryCode": "DE"
            },
            {
                "name": "Essen",
                "countryCode": "DE"
            },
            {
                "name": "Euskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Everswinkel",
                "countryCode": "DE"
            },
            {
                "name": "Finnentrop",
                "countryCode": "DE"
            },
            {
                "name": "Frechen",
                "countryCode": "DE"
            },
            {
                "name": "Freudenberg",
                "countryCode": "DE"
            },
            {
                "name": "Fröndenberg",
                "countryCode": "DE"
            },
            {
                "name": "Gangelt",
                "countryCode": "DE"
            },
            {
                "name": "Geilenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Geldern",
                "countryCode": "DE"
            },
            {
                "name": "Gelsenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Gescher",
                "countryCode": "DE"
            },
            {
                "name": "Geseke",
                "countryCode": "DE"
            },
            {
                "name": "Gevelsberg",
                "countryCode": "DE"
            },
            {
                "name": "Gladbeck",
                "countryCode": "DE"
            },
            {
                "name": "Goch",
                "countryCode": "DE"
            },
            {
                "name": "Grefrath",
                "countryCode": "DE"
            },
            {
                "name": "Gremberghoven",
                "countryCode": "DE"
            },
            {
                "name": "Greven",
                "countryCode": "DE"
            },
            {
                "name": "Grevenbroich",
                "countryCode": "DE"
            },
            {
                "name": "Gronau",
                "countryCode": "DE"
            },
            {
                "name": "Gummersbach",
                "countryCode": "DE"
            },
            {
                "name": "Gütersloh",
                "countryCode": "DE"
            },
            {
                "name": "Haan",
                "countryCode": "DE"
            },
            {
                "name": "Hagen",
                "countryCode": "DE"
            },
            {
                "name": "Halle",
                "countryCode": "DE"
            },
            {
                "name": "Hallenberg",
                "countryCode": "DE"
            },
            {
                "name": "Haltern am See",
                "countryCode": "DE"
            },
            {
                "name": "Halver",
                "countryCode": "DE"
            },
            {
                "name": "Hamm",
                "countryCode": "DE"
            },
            {
                "name": "Hamminkeln",
                "countryCode": "DE"
            },
            {
                "name": "Harsewinkel",
                "countryCode": "DE"
            },
            {
                "name": "Hattingen",
                "countryCode": "DE"
            },
            {
                "name": "Havixbeck",
                "countryCode": "DE"
            },
            {
                "name": "Heek",
                "countryCode": "DE"
            },
            {
                "name": "Heiden",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenhaus",
                "countryCode": "DE"
            },
            {
                "name": "Heimbach",
                "countryCode": "DE"
            },
            {
                "name": "Heinsberg",
                "countryCode": "DE"
            },
            {
                "name": "Hellenthal",
                "countryCode": "DE"
            },
            {
                "name": "Hemer",
                "countryCode": "DE"
            },
            {
                "name": "Hennef",
                "countryCode": "DE"
            },
            {
                "name": "Herdecke",
                "countryCode": "DE"
            },
            {
                "name": "Herford",
                "countryCode": "DE"
            },
            {
                "name": "Herne",
                "countryCode": "DE"
            },
            {
                "name": "Herscheid",
                "countryCode": "DE"
            },
            {
                "name": "Herten",
                "countryCode": "DE"
            },
            {
                "name": "Herzogenrath",
                "countryCode": "DE"
            },
            {
                "name": "Hiddenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Hilchenbach",
                "countryCode": "DE"
            },
            {
                "name": "Hilden",
                "countryCode": "DE"
            },
            {
                "name": "Hille",
                "countryCode": "DE"
            },
            {
                "name": "Hochfeld",
                "countryCode": "DE"
            },
            {
                "name": "Holzwickede",
                "countryCode": "DE"
            },
            {
                "name": "Hordel",
                "countryCode": "DE"
            },
            {
                "name": "Horn",
                "countryCode": "DE"
            },
            {
                "name": "Horstmar",
                "countryCode": "DE"
            },
            {
                "name": "Humboldtkolonie",
                "countryCode": "DE"
            },
            {
                "name": "Höhenberg",
                "countryCode": "DE"
            },
            {
                "name": "Hörstel",
                "countryCode": "DE"
            },
            {
                "name": "Hövelhof",
                "countryCode": "DE"
            },
            {
                "name": "Höxter",
                "countryCode": "DE"
            },
            {
                "name": "Hückelhoven",
                "countryCode": "DE"
            },
            {
                "name": "Hückeswagen",
                "countryCode": "DE"
            },
            {
                "name": "Hüllhorst",
                "countryCode": "DE"
            },
            {
                "name": "Hünxe",
                "countryCode": "DE"
            },
            {
                "name": "Hürtgenwald",
                "countryCode": "DE"
            },
            {
                "name": "Hürth",
                "countryCode": "DE"
            },
            {
                "name": "Ibbenbüren",
                "countryCode": "DE"
            },
            {
                "name": "Inden",
                "countryCode": "DE"
            },
            {
                "name": "Iserlohn",
                "countryCode": "DE"
            },
            {
                "name": "Isselburg",
                "countryCode": "DE"
            },
            {
                "name": "Issum",
                "countryCode": "DE"
            },
            {
                "name": "Jüchen",
                "countryCode": "DE"
            },
            {
                "name": "Jülich",
                "countryCode": "DE"
            },
            {
                "name": "Kaarst",
                "countryCode": "DE"
            },
            {
                "name": "Kalk",
                "countryCode": "DE"
            },
            {
                "name": "Kalkar",
                "countryCode": "DE"
            },
            {
                "name": "Kall",
                "countryCode": "DE"
            },
            {
                "name": "Kamen",
                "countryCode": "DE"
            },
            {
                "name": "Kamp-Lintfort",
                "countryCode": "DE"
            },
            {
                "name": "Kempen",
                "countryCode": "DE"
            },
            {
                "name": "Kerpen",
                "countryCode": "DE"
            },
            {
                "name": "Kevelaer",
                "countryCode": "DE"
            },
            {
                "name": "Kierspe",
                "countryCode": "DE"
            },
            {
                "name": "Kirchhundem",
                "countryCode": "DE"
            },
            {
                "name": "Kirchlengern",
                "countryCode": "DE"
            },
            {
                "name": "Klein Reken",
                "countryCode": "DE"
            },
            {
                "name": "Kleve",
                "countryCode": "DE"
            },
            {
                "name": "Korschenbroich",
                "countryCode": "DE"
            },
            {
                "name": "Kranenburg",
                "countryCode": "DE"
            },
            {
                "name": "Krefeld",
                "countryCode": "DE"
            },
            {
                "name": "Kreuzau",
                "countryCode": "DE"
            },
            {
                "name": "Kreuztal",
                "countryCode": "DE"
            },
            {
                "name": "Köln",
                "countryCode": "DE"
            },
            {
                "name": "Königswinter",
                "countryCode": "DE"
            },
            {
                "name": "Kürten",
                "countryCode": "DE"
            },
            {
                "name": "Ladbergen",
                "countryCode": "DE"
            },
            {
                "name": "Laer",
                "countryCode": "DE"
            },
            {
                "name": "Lage",
                "countryCode": "DE"
            },
            {
                "name": "Langenberg",
                "countryCode": "DE"
            },
            {
                "name": "Langenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Langerwehe",
                "countryCode": "DE"
            },
            {
                "name": "Lanstrop",
                "countryCode": "DE"
            },
            {
                "name": "Legden",
                "countryCode": "DE"
            },
            {
                "name": "Leichlingen",
                "countryCode": "DE"
            },
            {
                "name": "Lemgo",
                "countryCode": "DE"
            },
            {
                "name": "Lengerich",
                "countryCode": "DE"
            },
            {
                "name": "Lennestadt",
                "countryCode": "DE"
            },
            {
                "name": "Leopoldshöhe",
                "countryCode": "DE"
            },
            {
                "name": "Leverkusen",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenau",
                "countryCode": "DE"
            },
            {
                "name": "Lienen",
                "countryCode": "DE"
            },
            {
                "name": "Lindlar",
                "countryCode": "DE"
            },
            {
                "name": "Linnich",
                "countryCode": "DE"
            },
            {
                "name": "Lippstadt",
                "countryCode": "DE"
            },
            {
                "name": "Lohmar",
                "countryCode": "DE"
            },
            {
                "name": "Lotte",
                "countryCode": "DE"
            },
            {
                "name": "Löhne",
                "countryCode": "DE"
            },
            {
                "name": "Lübbecke",
                "countryCode": "DE"
            },
            {
                "name": "Lüdenscheid",
                "countryCode": "DE"
            },
            {
                "name": "Lüdinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Lügde",
                "countryCode": "DE"
            },
            {
                "name": "Lünen",
                "countryCode": "DE"
            },
            {
                "name": "Marienheide",
                "countryCode": "DE"
            },
            {
                "name": "Marl",
                "countryCode": "DE"
            },
            {
                "name": "Marsberg",
                "countryCode": "DE"
            },
            {
                "name": "Mechernich",
                "countryCode": "DE"
            },
            {
                "name": "Meckenheim",
                "countryCode": "DE"
            },
            {
                "name": "Medebach",
                "countryCode": "DE"
            },
            {
                "name": "Meerbusch",
                "countryCode": "DE"
            },
            {
                "name": "Mehrhoog",
                "countryCode": "DE"
            },
            {
                "name": "Meiderich",
                "countryCode": "DE"
            },
            {
                "name": "Meinerzhagen",
                "countryCode": "DE"
            },
            {
                "name": "Menden",
                "countryCode": "DE"
            },
            {
                "name": "Mengenich",
                "countryCode": "DE"
            },
            {
                "name": "Meschede",
                "countryCode": "DE"
            },
            {
                "name": "Metelen",
                "countryCode": "DE"
            },
            {
                "name": "Mettingen",
                "countryCode": "DE"
            },
            {
                "name": "Mettmann",
                "countryCode": "DE"
            },
            {
                "name": "Minden",
                "countryCode": "DE"
            },
            {
                "name": "Moers",
                "countryCode": "DE"
            },
            {
                "name": "Monheim am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Monschau",
                "countryCode": "DE"
            },
            {
                "name": "Morsbach",
                "countryCode": "DE"
            },
            {
                "name": "Much",
                "countryCode": "DE"
            },
            {
                "name": "Möhnesee",
                "countryCode": "DE"
            },
            {
                "name": "Mönchengladbach",
                "countryCode": "DE"
            },
            {
                "name": "Mülheim",
                "countryCode": "DE"
            },
            {
                "name": "Münster",
                "countryCode": "DE"
            },
            {
                "name": "Nachrodt-Wiblingwerde",
                "countryCode": "DE"
            },
            {
                "name": "Netphen",
                "countryCode": "DE"
            },
            {
                "name": "Nettersheim",
                "countryCode": "DE"
            },
            {
                "name": "Nettetal",
                "countryCode": "DE"
            },
            {
                "name": "Neu-Pattern",
                "countryCode": "DE"
            },
            {
                "name": "Neubrück",
                "countryCode": "DE"
            },
            {
                "name": "Neuehrenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Neuenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neuenrade",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neuss",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt/Nord",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt/Süd",
                "countryCode": "DE"
            },
            {
                "name": "Nideggen",
                "countryCode": "DE"
            },
            {
                "name": "Niederkassel",
                "countryCode": "DE"
            },
            {
                "name": "Niederkrüchten",
                "countryCode": "DE"
            },
            {
                "name": "Niedermerz",
                "countryCode": "DE"
            },
            {
                "name": "Niederzier",
                "countryCode": "DE"
            },
            {
                "name": "Nieheim",
                "countryCode": "DE"
            },
            {
                "name": "Nippes",
                "countryCode": "DE"
            },
            {
                "name": "Nordkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Nordwalde",
                "countryCode": "DE"
            },
            {
                "name": "Nottuln",
                "countryCode": "DE"
            },
            {
                "name": "Nörvenich",
                "countryCode": "DE"
            },
            {
                "name": "Nümbrecht",
                "countryCode": "DE"
            },
            {
                "name": "Oberhausen",
                "countryCode": "DE"
            },
            {
                "name": "Obernbeck",
                "countryCode": "DE"
            },
            {
                "name": "Ochtrup",
                "countryCode": "DE"
            },
            {
                "name": "Odenthal",
                "countryCode": "DE"
            },
            {
                "name": "Oelde",
                "countryCode": "DE"
            },
            {
                "name": "Oer-Erkenschwick",
                "countryCode": "DE"
            },
            {
                "name": "Oerlinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Olfen",
                "countryCode": "DE"
            },
            {
                "name": "Olpe",
                "countryCode": "DE"
            },
            {
                "name": "Olsberg",
                "countryCode": "DE"
            },
            {
                "name": "Opladen",
                "countryCode": "DE"
            },
            {
                "name": "Ossendorf",
                "countryCode": "DE"
            },
            {
                "name": "Ostbevern",
                "countryCode": "DE"
            },
            {
                "name": "Ostheim",
                "countryCode": "DE"
            },
            {
                "name": "Overath",
                "countryCode": "DE"
            },
            {
                "name": "Paderborn",
                "countryCode": "DE"
            },
            {
                "name": "Petershagen",
                "countryCode": "DE"
            },
            {
                "name": "Plettenberg",
                "countryCode": "DE"
            },
            {
                "name": "Poll",
                "countryCode": "DE"
            },
            {
                "name": "Porta Westfalica",
                "countryCode": "DE"
            },
            {
                "name": "Porz am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Preußisch Oldendorf",
                "countryCode": "DE"
            },
            {
                "name": "Pulheim",
                "countryCode": "DE"
            },
            {
                "name": "Radevormwald",
                "countryCode": "DE"
            },
            {
                "name": "Raesfeld",
                "countryCode": "DE"
            },
            {
                "name": "Rahden",
                "countryCode": "DE"
            },
            {
                "name": "Rath",
                "countryCode": "DE"
            },
            {
                "name": "Ratingen",
                "countryCode": "DE"
            },
            {
                "name": "Recke",
                "countryCode": "DE"
            },
            {
                "name": "Recklinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Rees",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Arnsberg",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Detmold",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Köln",
                "countryCode": "DE"
            },
            {
                "name": "Regierungsbezirk Münster",
                "countryCode": "DE"
            },
            {
                "name": "Remscheid",
                "countryCode": "DE"
            },
            {
                "name": "Rheda-Wiedenbrück",
                "countryCode": "DE"
            },
            {
                "name": "Rhede",
                "countryCode": "DE"
            },
            {
                "name": "Rheinbach",
                "countryCode": "DE"
            },
            {
                "name": "Rheinberg",
                "countryCode": "DE"
            },
            {
                "name": "Rheine",
                "countryCode": "DE"
            },
            {
                "name": "Rheurdt",
                "countryCode": "DE"
            },
            {
                "name": "Riehl",
                "countryCode": "DE"
            },
            {
                "name": "Rietberg",
                "countryCode": "DE"
            },
            {
                "name": "Roetgen",
                "countryCode": "DE"
            },
            {
                "name": "Rommerskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Ruppichteroth",
                "countryCode": "DE"
            },
            {
                "name": "Rödinghausen",
                "countryCode": "DE"
            },
            {
                "name": "Rösrath",
                "countryCode": "DE"
            },
            {
                "name": "Rüthen",
                "countryCode": "DE"
            },
            {
                "name": "Saerbeck",
                "countryCode": "DE"
            },
            {
                "name": "Salzkotten",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Augustin",
                "countryCode": "DE"
            },
            {
                "name": "Sassenberg",
                "countryCode": "DE"
            },
            {
                "name": "Schalksmühle",
                "countryCode": "DE"
            },
            {
                "name": "Schermbeck",
                "countryCode": "DE"
            },
            {
                "name": "Schieder-Schwalenberg",
                "countryCode": "DE"
            },
            {
                "name": "Schlangen",
                "countryCode": "DE"
            },
            {
                "name": "Schleiden",
                "countryCode": "DE"
            },
            {
                "name": "Schmallenberg",
                "countryCode": "DE"
            },
            {
                "name": "Schwalmtal",
                "countryCode": "DE"
            },
            {
                "name": "Schwelm",
                "countryCode": "DE"
            },
            {
                "name": "Schwerte",
                "countryCode": "DE"
            },
            {
                "name": "Schöppingen",
                "countryCode": "DE"
            },
            {
                "name": "Selm",
                "countryCode": "DE"
            },
            {
                "name": "Senden",
                "countryCode": "DE"
            },
            {
                "name": "Sendenhorst",
                "countryCode": "DE"
            },
            {
                "name": "Siegburg",
                "countryCode": "DE"
            },
            {
                "name": "Siegen",
                "countryCode": "DE"
            },
            {
                "name": "Siersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Simmerath",
                "countryCode": "DE"
            },
            {
                "name": "Sinnersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Soest",
                "countryCode": "DE"
            },
            {
                "name": "Solingen",
                "countryCode": "DE"
            },
            {
                "name": "Sonsbeck",
                "countryCode": "DE"
            },
            {
                "name": "Spenge",
                "countryCode": "DE"
            },
            {
                "name": "Sprockhövel",
                "countryCode": "DE"
            },
            {
                "name": "Stadtlohn",
                "countryCode": "DE"
            },
            {
                "name": "Steinfurt",
                "countryCode": "DE"
            },
            {
                "name": "Steinhagen",
                "countryCode": "DE"
            },
            {
                "name": "Steinheim",
                "countryCode": "DE"
            },
            {
                "name": "Stolberg",
                "countryCode": "DE"
            },
            {
                "name": "Stoßdorf",
                "countryCode": "DE"
            },
            {
                "name": "Straelen",
                "countryCode": "DE"
            },
            {
                "name": "Sundern",
                "countryCode": "DE"
            },
            {
                "name": "Südlohn",
                "countryCode": "DE"
            },
            {
                "name": "Tecklenburg",
                "countryCode": "DE"
            },
            {
                "name": "Telgte",
                "countryCode": "DE"
            },
            {
                "name": "Titz",
                "countryCode": "DE"
            },
            {
                "name": "Troisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Tönisvorst",
                "countryCode": "DE"
            },
            {
                "name": "Türnich",
                "countryCode": "DE"
            },
            {
                "name": "Uedem",
                "countryCode": "DE"
            },
            {
                "name": "Unna",
                "countryCode": "DE"
            },
            {
                "name": "Velbert",
                "countryCode": "DE"
            },
            {
                "name": "Velen",
                "countryCode": "DE"
            },
            {
                "name": "Verl",
                "countryCode": "DE"
            },
            {
                "name": "Versmold",
                "countryCode": "DE"
            },
            {
                "name": "Vettweiß",
                "countryCode": "DE"
            },
            {
                "name": "Viersen",
                "countryCode": "DE"
            },
            {
                "name": "Vlotho",
                "countryCode": "DE"
            },
            {
                "name": "Voerde",
                "countryCode": "DE"
            },
            {
                "name": "Vreden",
                "countryCode": "DE"
            },
            {
                "name": "Wachtberg",
                "countryCode": "DE"
            },
            {
                "name": "Wachtendonk",
                "countryCode": "DE"
            },
            {
                "name": "Wadersloh",
                "countryCode": "DE"
            },
            {
                "name": "Wahn-Heide",
                "countryCode": "DE"
            },
            {
                "name": "Waldbröl",
                "countryCode": "DE"
            },
            {
                "name": "Waldfeucht",
                "countryCode": "DE"
            },
            {
                "name": "Waltrop",
                "countryCode": "DE"
            },
            {
                "name": "Warburg",
                "countryCode": "DE"
            },
            {
                "name": "Warendorf",
                "countryCode": "DE"
            },
            {
                "name": "Warstein",
                "countryCode": "DE"
            },
            {
                "name": "Wassenberg",
                "countryCode": "DE"
            },
            {
                "name": "Weeze",
                "countryCode": "DE"
            },
            {
                "name": "Wegberg",
                "countryCode": "DE"
            },
            {
                "name": "Weilerswist",
                "countryCode": "DE"
            },
            {
                "name": "Welver",
                "countryCode": "DE"
            },
            {
                "name": "Wenden",
                "countryCode": "DE"
            },
            {
                "name": "Werdohl",
                "countryCode": "DE"
            },
            {
                "name": "Werl",
                "countryCode": "DE"
            },
            {
                "name": "Wermelskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Werne",
                "countryCode": "DE"
            },
            {
                "name": "Werther",
                "countryCode": "DE"
            },
            {
                "name": "Wesel",
                "countryCode": "DE"
            },
            {
                "name": "Wesseling",
                "countryCode": "DE"
            },
            {
                "name": "Westerkappeln",
                "countryCode": "DE"
            },
            {
                "name": "Wetter (Ruhr)",
                "countryCode": "DE"
            },
            {
                "name": "Wettringen",
                "countryCode": "DE"
            },
            {
                "name": "Wickede",
                "countryCode": "DE"
            },
            {
                "name": "Wiehl",
                "countryCode": "DE"
            },
            {
                "name": "Willebadessen",
                "countryCode": "DE"
            },
            {
                "name": "Willich",
                "countryCode": "DE"
            },
            {
                "name": "Wilnsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Winterberg",
                "countryCode": "DE"
            },
            {
                "name": "Wipperfürth",
                "countryCode": "DE"
            },
            {
                "name": "Witten",
                "countryCode": "DE"
            },
            {
                "name": "Wuppertal",
                "countryCode": "DE"
            },
            {
                "name": "Wülfrath",
                "countryCode": "DE"
            },
            {
                "name": "Wünnenberg",
                "countryCode": "DE"
            },
            {
                "name": "Würselen",
                "countryCode": "DE"
            },
            {
                "name": "Xanten",
                "countryCode": "DE"
            },
            {
                "name": "Zulpich",
                "countryCode": "DE"
            },
            {
                "name": "Übach-Palenberg",
                "countryCode": "DE"
            },
            {
                "name": "Aach",
                "countryCode": "DE"
            },
            {
                "name": "Adenau",
                "countryCode": "DE"
            },
            {
                "name": "Ahrbrück",
                "countryCode": "DE"
            },
            {
                "name": "Albersweiler",
                "countryCode": "DE"
            },
            {
                "name": "Albig",
                "countryCode": "DE"
            },
            {
                "name": "Albisheim",
                "countryCode": "DE"
            },
            {
                "name": "Alpenrod",
                "countryCode": "DE"
            },
            {
                "name": "Alsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Alsenz",
                "countryCode": "DE"
            },
            {
                "name": "Alsheim",
                "countryCode": "DE"
            },
            {
                "name": "Altenahr",
                "countryCode": "DE"
            },
            {
                "name": "Altendiez",
                "countryCode": "DE"
            },
            {
                "name": "Altenglan",
                "countryCode": "DE"
            },
            {
                "name": "Altenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Altleiningen",
                "countryCode": "DE"
            },
            {
                "name": "Altrich",
                "countryCode": "DE"
            },
            {
                "name": "Altrip",
                "countryCode": "DE"
            },
            {
                "name": "Alzey",
                "countryCode": "DE"
            },
            {
                "name": "Andernach",
                "countryCode": "DE"
            },
            {
                "name": "Anhausen",
                "countryCode": "DE"
            },
            {
                "name": "Annweiler am Trifels",
                "countryCode": "DE"
            },
            {
                "name": "Appenheim",
                "countryCode": "DE"
            },
            {
                "name": "Argenthal",
                "countryCode": "DE"
            },
            {
                "name": "Armsheim",
                "countryCode": "DE"
            },
            {
                "name": "Arzbach",
                "countryCode": "DE"
            },
            {
                "name": "Arzfeld",
                "countryCode": "DE"
            },
            {
                "name": "Asbach",
                "countryCode": "DE"
            },
            {
                "name": "Ayl",
                "countryCode": "DE"
            },
            {
                "name": "Bacharach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bergzabern",
                "countryCode": "DE"
            },
            {
                "name": "Bad Breisig",
                "countryCode": "DE"
            },
            {
                "name": "Bad Dürkheim",
                "countryCode": "DE"
            },
            {
                "name": "Bad Ems",
                "countryCode": "DE"
            },
            {
                "name": "Bad Hönningen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Kreuznach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Marienberg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Münster am Stein-Ebernburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Neuenahr-Ahrweiler",
                "countryCode": "DE"
            },
            {
                "name": "Badem",
                "countryCode": "DE"
            },
            {
                "name": "Bann",
                "countryCode": "DE"
            },
            {
                "name": "Bassenheim",
                "countryCode": "DE"
            },
            {
                "name": "Baumholder",
                "countryCode": "DE"
            },
            {
                "name": "Bausendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bechhofen",
                "countryCode": "DE"
            },
            {
                "name": "Bechtheim",
                "countryCode": "DE"
            },
            {
                "name": "Bechtolsheim",
                "countryCode": "DE"
            },
            {
                "name": "Beindersheim",
                "countryCode": "DE"
            },
            {
                "name": "Bell",
                "countryCode": "DE"
            },
            {
                "name": "Bellheim",
                "countryCode": "DE"
            },
            {
                "name": "Beltheim",
                "countryCode": "DE"
            },
            {
                "name": "Bendorf",
                "countryCode": "DE"
            },
            {
                "name": "Berg",
                "countryCode": "DE"
            },
            {
                "name": "Bernkastel-Kues",
                "countryCode": "DE"
            },
            {
                "name": "Bettingen",
                "countryCode": "DE"
            },
            {
                "name": "Betzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Billigheim-Ingenheim",
                "countryCode": "DE"
            },
            {
                "name": "Bingen am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Binsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Birken-Honigsessen",
                "countryCode": "DE"
            },
            {
                "name": "Birkenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Birkenheide",
                "countryCode": "DE"
            },
            {
                "name": "Birlenbach",
                "countryCode": "DE"
            },
            {
                "name": "Birresborn",
                "countryCode": "DE"
            },
            {
                "name": "Bitburg",
                "countryCode": "DE"
            },
            {
                "name": "Blankenrath",
                "countryCode": "DE"
            },
            {
                "name": "Bleialf",
                "countryCode": "DE"
            },
            {
                "name": "Bobenheim-Roxheim",
                "countryCode": "DE"
            },
            {
                "name": "Bockenau",
                "countryCode": "DE"
            },
            {
                "name": "Bockenheim",
                "countryCode": "DE"
            },
            {
                "name": "Bodenheim",
                "countryCode": "DE"
            },
            {
                "name": "Bolanden",
                "countryCode": "DE"
            },
            {
                "name": "Bollendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bonefeld",
                "countryCode": "DE"
            },
            {
                "name": "Boppard",
                "countryCode": "DE"
            },
            {
                "name": "Bornheim",
                "countryCode": "DE"
            },
            {
                "name": "Bornich",
                "countryCode": "DE"
            },
            {
                "name": "Brachbach",
                "countryCode": "DE"
            },
            {
                "name": "Braubach",
                "countryCode": "DE"
            },
            {
                "name": "Brauneberg",
                "countryCode": "DE"
            },
            {
                "name": "Breitscheidt",
                "countryCode": "DE"
            },
            {
                "name": "Bretzenheim",
                "countryCode": "DE"
            },
            {
                "name": "Brey",
                "countryCode": "DE"
            },
            {
                "name": "Briedel",
                "countryCode": "DE"
            },
            {
                "name": "Brohl-Lützing",
                "countryCode": "DE"
            },
            {
                "name": "Bruchmühlbach-Miesau",
                "countryCode": "DE"
            },
            {
                "name": "Bruchweiler-Bärenbach",
                "countryCode": "DE"
            },
            {
                "name": "Bruttig-Fankel",
                "countryCode": "DE"
            },
            {
                "name": "Brücken",
                "countryCode": "DE"
            },
            {
                "name": "Budenheim",
                "countryCode": "DE"
            },
            {
                "name": "Bullay",
                "countryCode": "DE"
            },
            {
                "name": "Bundenbach",
                "countryCode": "DE"
            },
            {
                "name": "Bundenthal",
                "countryCode": "DE"
            },
            {
                "name": "Burgbrohl",
                "countryCode": "DE"
            },
            {
                "name": "Burgschwalbach",
                "countryCode": "DE"
            },
            {
                "name": "Busenberg",
                "countryCode": "DE"
            },
            {
                "name": "Böhl-Iggelheim",
                "countryCode": "DE"
            },
            {
                "name": "Büchel",
                "countryCode": "DE"
            },
            {
                "name": "Büchenbeuren",
                "countryCode": "DE"
            },
            {
                "name": "Carlsberg",
                "countryCode": "DE"
            },
            {
                "name": "Clausen",
                "countryCode": "DE"
            },
            {
                "name": "Cochem",
                "countryCode": "DE"
            },
            {
                "name": "Contwig",
                "countryCode": "DE"
            },
            {
                "name": "Daaden",
                "countryCode": "DE"
            },
            {
                "name": "Dachsenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Dahn",
                "countryCode": "DE"
            },
            {
                "name": "Dalheim",
                "countryCode": "DE"
            },
            {
                "name": "Dannstadt-Schauernheim",
                "countryCode": "DE"
            },
            {
                "name": "Dattenberg",
                "countryCode": "DE"
            },
            {
                "name": "Daun",
                "countryCode": "DE"
            },
            {
                "name": "Dausenau",
                "countryCode": "DE"
            },
            {
                "name": "Deidesheim",
                "countryCode": "DE"
            },
            {
                "name": "Dellfeld",
                "countryCode": "DE"
            },
            {
                "name": "Dernau",
                "countryCode": "DE"
            },
            {
                "name": "Dernbach",
                "countryCode": "DE"
            },
            {
                "name": "Derschen",
                "countryCode": "DE"
            },
            {
                "name": "Dexheim",
                "countryCode": "DE"
            },
            {
                "name": "Dieblich",
                "countryCode": "DE"
            },
            {
                "name": "Dienheim",
                "countryCode": "DE"
            },
            {
                "name": "Dierdorf",
                "countryCode": "DE"
            },
            {
                "name": "Diez",
                "countryCode": "DE"
            },
            {
                "name": "Dirmstein",
                "countryCode": "DE"
            },
            {
                "name": "Dittelsheim-Heßloch",
                "countryCode": "DE"
            },
            {
                "name": "Dommershausen",
                "countryCode": "DE"
            },
            {
                "name": "Dreikirchen",
                "countryCode": "DE"
            },
            {
                "name": "Dreis",
                "countryCode": "DE"
            },
            {
                "name": "Dreisen",
                "countryCode": "DE"
            },
            {
                "name": "Dudeldorf",
                "countryCode": "DE"
            },
            {
                "name": "Dudenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Dörrenbach",
                "countryCode": "DE"
            },
            {
                "name": "Düngenheim",
                "countryCode": "DE"
            },
            {
                "name": "Ebernhahn",
                "countryCode": "DE"
            },
            {
                "name": "Ebertsheim",
                "countryCode": "DE"
            },
            {
                "name": "Edenkoben",
                "countryCode": "DE"
            },
            {
                "name": "Edesheim",
                "countryCode": "DE"
            },
            {
                "name": "Ediger-Eller",
                "countryCode": "DE"
            },
            {
                "name": "Ehlscheid",
                "countryCode": "DE"
            },
            {
                "name": "Eisenberg",
                "countryCode": "DE"
            },
            {
                "name": "Eitelborn",
                "countryCode": "DE"
            },
            {
                "name": "Elkenroth",
                "countryCode": "DE"
            },
            {
                "name": "Ellerstadt",
                "countryCode": "DE"
            },
            {
                "name": "Elmstein",
                "countryCode": "DE"
            },
            {
                "name": "Emmelshausen",
                "countryCode": "DE"
            },
            {
                "name": "Enkenbach-Alsenborn",
                "countryCode": "DE"
            },
            {
                "name": "Enkirch",
                "countryCode": "DE"
            },
            {
                "name": "Eppelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Eppenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Erbes-Büdesheim",
                "countryCode": "DE"
            },
            {
                "name": "Erfweiler",
                "countryCode": "DE"
            },
            {
                "name": "Erpel",
                "countryCode": "DE"
            },
            {
                "name": "Erpolzheim",
                "countryCode": "DE"
            },
            {
                "name": "Essenheim",
                "countryCode": "DE"
            },
            {
                "name": "Essingen",
                "countryCode": "DE"
            },
            {
                "name": "Esthal",
                "countryCode": "DE"
            },
            {
                "name": "Ettringen",
                "countryCode": "DE"
            },
            {
                "name": "Etzbach",
                "countryCode": "DE"
            },
            {
                "name": "Fachbach",
                "countryCode": "DE"
            },
            {
                "name": "Faid",
                "countryCode": "DE"
            },
            {
                "name": "Feilbingert",
                "countryCode": "DE"
            },
            {
                "name": "Fell",
                "countryCode": "DE"
            },
            {
                "name": "Fischbach",
                "countryCode": "DE"
            },
            {
                "name": "Flacht",
                "countryCode": "DE"
            },
            {
                "name": "Flammersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Flomborn",
                "countryCode": "DE"
            },
            {
                "name": "Flonheim",
                "countryCode": "DE"
            },
            {
                "name": "Framersheim",
                "countryCode": "DE"
            },
            {
                "name": "Frankenstein",
                "countryCode": "DE"
            },
            {
                "name": "Frankenthal",
                "countryCode": "DE"
            },
            {
                "name": "Freckenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Frei-Laubersheim",
                "countryCode": "DE"
            },
            {
                "name": "Freinsheim",
                "countryCode": "DE"
            },
            {
                "name": "Freisbach",
                "countryCode": "DE"
            },
            {
                "name": "Freudenburg",
                "countryCode": "DE"
            },
            {
                "name": "Friedelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Friedewald",
                "countryCode": "DE"
            },
            {
                "name": "Friesenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Fußgönheim",
                "countryCode": "DE"
            },
            {
                "name": "Föhren",
                "countryCode": "DE"
            },
            {
                "name": "Fürfeld",
                "countryCode": "DE"
            },
            {
                "name": "Fürthen",
                "countryCode": "DE"
            },
            {
                "name": "Gartenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Gau-Algesheim",
                "countryCode": "DE"
            },
            {
                "name": "Gau-Bickelheim",
                "countryCode": "DE"
            },
            {
                "name": "Gau-Bischofsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gau-Odernheim",
                "countryCode": "DE"
            },
            {
                "name": "Gebhardshain",
                "countryCode": "DE"
            },
            {
                "name": "Gemünden",
                "countryCode": "DE"
            },
            {
                "name": "Gensingen",
                "countryCode": "DE"
            },
            {
                "name": "Germersheim",
                "countryCode": "DE"
            },
            {
                "name": "Gerolsheim",
                "countryCode": "DE"
            },
            {
                "name": "Gerolstein",
                "countryCode": "DE"
            },
            {
                "name": "Gillenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Gimbsheim",
                "countryCode": "DE"
            },
            {
                "name": "Girod",
                "countryCode": "DE"
            },
            {
                "name": "Glan-Münchweiler",
                "countryCode": "DE"
            },
            {
                "name": "Gommersheim",
                "countryCode": "DE"
            },
            {
                "name": "Gondershausen",
                "countryCode": "DE"
            },
            {
                "name": "Gossersweiler-Stein",
                "countryCode": "DE"
            },
            {
                "name": "Greimerath",
                "countryCode": "DE"
            },
            {
                "name": "Gries",
                "countryCode": "DE"
            },
            {
                "name": "Grolsheim",
                "countryCode": "DE"
            },
            {
                "name": "Großholbach",
                "countryCode": "DE"
            },
            {
                "name": "Großkarlbach",
                "countryCode": "DE"
            },
            {
                "name": "Großlittgen",
                "countryCode": "DE"
            },
            {
                "name": "Großmaischeid",
                "countryCode": "DE"
            },
            {
                "name": "Großniedesheim",
                "countryCode": "DE"
            },
            {
                "name": "Grünstadt",
                "countryCode": "DE"
            },
            {
                "name": "Gundersheim",
                "countryCode": "DE"
            },
            {
                "name": "Guntersblum",
                "countryCode": "DE"
            },
            {
                "name": "Gusenburg",
                "countryCode": "DE"
            },
            {
                "name": "Gusterath",
                "countryCode": "DE"
            },
            {
                "name": "Gutenberg",
                "countryCode": "DE"
            },
            {
                "name": "Göllheim",
                "countryCode": "DE"
            },
            {
                "name": "Gönnheim",
                "countryCode": "DE"
            },
            {
                "name": "Gückingen",
                "countryCode": "DE"
            },
            {
                "name": "Hachenburg",
                "countryCode": "DE"
            },
            {
                "name": "Hackenheim",
                "countryCode": "DE"
            },
            {
                "name": "Hagenbach",
                "countryCode": "DE"
            },
            {
                "name": "Hahnheim",
                "countryCode": "DE"
            },
            {
                "name": "Hahnstätten",
                "countryCode": "DE"
            },
            {
                "name": "Halsenbach",
                "countryCode": "DE"
            },
            {
                "name": "Hamm",
                "countryCode": "DE"
            },
            {
                "name": "Hanhofen",
                "countryCode": "DE"
            },
            {
                "name": "Hargesheim",
                "countryCode": "DE"
            },
            {
                "name": "Harthausen",
                "countryCode": "DE"
            },
            {
                "name": "Harxheim",
                "countryCode": "DE"
            },
            {
                "name": "Hattert",
                "countryCode": "DE"
            },
            {
                "name": "Hatzenbühl",
                "countryCode": "DE"
            },
            {
                "name": "Hauenstein",
                "countryCode": "DE"
            },
            {
                "name": "Hauptstuhl",
                "countryCode": "DE"
            },
            {
                "name": "Hausen",
                "countryCode": "DE"
            },
            {
                "name": "Haßloch",
                "countryCode": "DE"
            },
            {
                "name": "Heidesheim",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenroth",
                "countryCode": "DE"
            },
            {
                "name": "Heimbach",
                "countryCode": "DE"
            },
            {
                "name": "Heistenbach",
                "countryCode": "DE"
            },
            {
                "name": "Helferskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Hellenhahn-Schellenberg",
                "countryCode": "DE"
            },
            {
                "name": "Heltersberg",
                "countryCode": "DE"
            },
            {
                "name": "Hennweiler",
                "countryCode": "DE"
            },
            {
                "name": "Herdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herforst",
                "countryCode": "DE"
            },
            {
                "name": "Hermersberg",
                "countryCode": "DE"
            },
            {
                "name": "Hermeskeil",
                "countryCode": "DE"
            },
            {
                "name": "Herschweiler-Pettersheim",
                "countryCode": "DE"
            },
            {
                "name": "Herxheim am Berg",
                "countryCode": "DE"
            },
            {
                "name": "Hettenleidelheim",
                "countryCode": "DE"
            },
            {
                "name": "Hetzerath",
                "countryCode": "DE"
            },
            {
                "name": "Heuchelheim bei Frankenthal",
                "countryCode": "DE"
            },
            {
                "name": "Heßheim",
                "countryCode": "DE"
            },
            {
                "name": "Hilgert",
                "countryCode": "DE"
            },
            {
                "name": "Hillesheim",
                "countryCode": "DE"
            },
            {
                "name": "Hillscheid",
                "countryCode": "DE"
            },
            {
                "name": "Hinterweidenthal",
                "countryCode": "DE"
            },
            {
                "name": "Hochdorf-Assenheim",
                "countryCode": "DE"
            },
            {
                "name": "Hochspeyer",
                "countryCode": "DE"
            },
            {
                "name": "Hochstadt",
                "countryCode": "DE"
            },
            {
                "name": "Hochstetten-Dhaun",
                "countryCode": "DE"
            },
            {
                "name": "Hof",
                "countryCode": "DE"
            },
            {
                "name": "Holler",
                "countryCode": "DE"
            },
            {
                "name": "Holzappel",
                "countryCode": "DE"
            },
            {
                "name": "Holzhausen an der Haide",
                "countryCode": "DE"
            },
            {
                "name": "Hoppstädten-Weiersbach",
                "countryCode": "DE"
            },
            {
                "name": "Horhausen",
                "countryCode": "DE"
            },
            {
                "name": "Hornbach",
                "countryCode": "DE"
            },
            {
                "name": "Hundsangen",
                "countryCode": "DE"
            },
            {
                "name": "Höheinöd",
                "countryCode": "DE"
            },
            {
                "name": "Höheischweiler",
                "countryCode": "DE"
            },
            {
                "name": "Höhn",
                "countryCode": "DE"
            },
            {
                "name": "Höhr-Grenzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Hördt",
                "countryCode": "DE"
            },
            {
                "name": "Hüffelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Hütschenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Idar-Oberstein",
                "countryCode": "DE"
            },
            {
                "name": "Igel",
                "countryCode": "DE"
            },
            {
                "name": "Ilbesheim",
                "countryCode": "DE"
            },
            {
                "name": "Imsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ingelheim am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Insheim",
                "countryCode": "DE"
            },
            {
                "name": "Irrel",
                "countryCode": "DE"
            },
            {
                "name": "Irsch",
                "countryCode": "DE"
            },
            {
                "name": "Jockgrim",
                "countryCode": "DE"
            },
            {
                "name": "Jugenheim",
                "countryCode": "DE"
            },
            {
                "name": "Jünkerath",
                "countryCode": "DE"
            },
            {
                "name": "Kadenbach",
                "countryCode": "DE"
            },
            {
                "name": "Kaisersesch",
                "countryCode": "DE"
            },
            {
                "name": "Kaiserslautern",
                "countryCode": "DE"
            },
            {
                "name": "Kallstadt",
                "countryCode": "DE"
            },
            {
                "name": "Kaltenengers",
                "countryCode": "DE"
            },
            {
                "name": "Kamp-Bornhofen",
                "countryCode": "DE"
            },
            {
                "name": "Kandel",
                "countryCode": "DE"
            },
            {
                "name": "Kapsweyer",
                "countryCode": "DE"
            },
            {
                "name": "Kasbach-Ohlenberg",
                "countryCode": "DE"
            },
            {
                "name": "Kasel",
                "countryCode": "DE"
            },
            {
                "name": "Kastellaun",
                "countryCode": "DE"
            },
            {
                "name": "Katzenelnbogen",
                "countryCode": "DE"
            },
            {
                "name": "Katzweiler",
                "countryCode": "DE"
            },
            {
                "name": "Katzwinkel",
                "countryCode": "DE"
            },
            {
                "name": "Kaub",
                "countryCode": "DE"
            },
            {
                "name": "Kehrig",
                "countryCode": "DE"
            },
            {
                "name": "Kelberg",
                "countryCode": "DE"
            },
            {
                "name": "Kell",
                "countryCode": "DE"
            },
            {
                "name": "Kempenich",
                "countryCode": "DE"
            },
            {
                "name": "Kenn",
                "countryCode": "DE"
            },
            {
                "name": "Kerzenheim",
                "countryCode": "DE"
            },
            {
                "name": "Kettig",
                "countryCode": "DE"
            },
            {
                "name": "Kindenheim",
                "countryCode": "DE"
            },
            {
                "name": "Kinderbeuern",
                "countryCode": "DE"
            },
            {
                "name": "Kindsbach",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg",
                "countryCode": "DE"
            },
            {
                "name": "Kirchen",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim an der Weinstraße",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheimbolanden",
                "countryCode": "DE"
            },
            {
                "name": "Kirchwald",
                "countryCode": "DE"
            },
            {
                "name": "Kirn",
                "countryCode": "DE"
            },
            {
                "name": "Kirrweiler",
                "countryCode": "DE"
            },
            {
                "name": "Kirschweiler",
                "countryCode": "DE"
            },
            {
                "name": "Klausen",
                "countryCode": "DE"
            },
            {
                "name": "Klein-Winternheim",
                "countryCode": "DE"
            },
            {
                "name": "Kleinmaischeid",
                "countryCode": "DE"
            },
            {
                "name": "Klingenmünster",
                "countryCode": "DE"
            },
            {
                "name": "Klotten",
                "countryCode": "DE"
            },
            {
                "name": "Klüsserath",
                "countryCode": "DE"
            },
            {
                "name": "Knittelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Kobern-Gondorf",
                "countryCode": "DE"
            },
            {
                "name": "Koblenz",
                "countryCode": "DE"
            },
            {
                "name": "Konz",
                "countryCode": "DE"
            },
            {
                "name": "Kordel",
                "countryCode": "DE"
            },
            {
                "name": "Kottenheim",
                "countryCode": "DE"
            },
            {
                "name": "Kottweiler-Schwanden",
                "countryCode": "DE"
            },
            {
                "name": "Krickenbach",
                "countryCode": "DE"
            },
            {
                "name": "Kriegsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Kruft",
                "countryCode": "DE"
            },
            {
                "name": "Kröv",
                "countryCode": "DE"
            },
            {
                "name": "Kuhardt",
                "countryCode": "DE"
            },
            {
                "name": "Kusel",
                "countryCode": "DE"
            },
            {
                "name": "Kyllburg",
                "countryCode": "DE"
            },
            {
                "name": "Kölbingen",
                "countryCode": "DE"
            },
            {
                "name": "Köngernheim",
                "countryCode": "DE"
            },
            {
                "name": "Körperich",
                "countryCode": "DE"
            },
            {
                "name": "Lachen-Speyerdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lahnstein",
                "countryCode": "DE"
            },
            {
                "name": "Lambrecht",
                "countryCode": "DE"
            },
            {
                "name": "Lambsheim",
                "countryCode": "DE"
            },
            {
                "name": "Landau in der Pfalz",
                "countryCode": "DE"
            },
            {
                "name": "Landscheid",
                "countryCode": "DE"
            },
            {
                "name": "Landstuhl",
                "countryCode": "DE"
            },
            {
                "name": "Langenbach bei Marienberg",
                "countryCode": "DE"
            },
            {
                "name": "Langenhahn",
                "countryCode": "DE"
            },
            {
                "name": "Langenlonsheim",
                "countryCode": "DE"
            },
            {
                "name": "Langsur",
                "countryCode": "DE"
            },
            {
                "name": "Laubach",
                "countryCode": "DE"
            },
            {
                "name": "Lauterecken",
                "countryCode": "DE"
            },
            {
                "name": "Lehmen",
                "countryCode": "DE"
            },
            {
                "name": "Leimersheim",
                "countryCode": "DE"
            },
            {
                "name": "Leiwen",
                "countryCode": "DE"
            },
            {
                "name": "Lemberg",
                "countryCode": "DE"
            },
            {
                "name": "Leubsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Leutesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lieser",
                "countryCode": "DE"
            },
            {
                "name": "Limburgerhof",
                "countryCode": "DE"
            },
            {
                "name": "Linden",
                "countryCode": "DE"
            },
            {
                "name": "Lindenberg",
                "countryCode": "DE"
            },
            {
                "name": "Lingenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Linz am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Lissendorf",
                "countryCode": "DE"
            },
            {
                "name": "Longkamp",
                "countryCode": "DE"
            },
            {
                "name": "Longuich",
                "countryCode": "DE"
            },
            {
                "name": "Lonnig",
                "countryCode": "DE"
            },
            {
                "name": "Ludwigshafen am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Lustadt",
                "countryCode": "DE"
            },
            {
                "name": "Lutzerath",
                "countryCode": "DE"
            },
            {
                "name": "Löf",
                "countryCode": "DE"
            },
            {
                "name": "Lörzweiler",
                "countryCode": "DE"
            },
            {
                "name": "Mackenbach",
                "countryCode": "DE"
            },
            {
                "name": "Maikammer",
                "countryCode": "DE"
            },
            {
                "name": "Mainz",
                "countryCode": "DE"
            },
            {
                "name": "Malborn",
                "countryCode": "DE"
            },
            {
                "name": "Mammelzen",
                "countryCode": "DE"
            },
            {
                "name": "Manderscheid",
                "countryCode": "DE"
            },
            {
                "name": "Marienrachdorf",
                "countryCode": "DE"
            },
            {
                "name": "Maring-Noviand",
                "countryCode": "DE"
            },
            {
                "name": "Marnheim",
                "countryCode": "DE"
            },
            {
                "name": "Martinshöhe",
                "countryCode": "DE"
            },
            {
                "name": "Masburg",
                "countryCode": "DE"
            },
            {
                "name": "Mastershausen",
                "countryCode": "DE"
            },
            {
                "name": "Maxdorf",
                "countryCode": "DE"
            },
            {
                "name": "Maxsain",
                "countryCode": "DE"
            },
            {
                "name": "Mayen",
                "countryCode": "DE"
            },
            {
                "name": "Maßweiler",
                "countryCode": "DE"
            },
            {
                "name": "Meckenheim",
                "countryCode": "DE"
            },
            {
                "name": "Meddersheim",
                "countryCode": "DE"
            },
            {
                "name": "Mehlbach",
                "countryCode": "DE"
            },
            {
                "name": "Mehlingen",
                "countryCode": "DE"
            },
            {
                "name": "Mehren",
                "countryCode": "DE"
            },
            {
                "name": "Mehring",
                "countryCode": "DE"
            },
            {
                "name": "Meisenheim",
                "countryCode": "DE"
            },
            {
                "name": "Melsbach",
                "countryCode": "DE"
            },
            {
                "name": "Mendig",
                "countryCode": "DE"
            },
            {
                "name": "Mertesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mertloch",
                "countryCode": "DE"
            },
            {
                "name": "Merxheim",
                "countryCode": "DE"
            },
            {
                "name": "Merzalben",
                "countryCode": "DE"
            },
            {
                "name": "Mettendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mettenheim",
                "countryCode": "DE"
            },
            {
                "name": "Meudt",
                "countryCode": "DE"
            },
            {
                "name": "Miehlen",
                "countryCode": "DE"
            },
            {
                "name": "Minfeld",
                "countryCode": "DE"
            },
            {
                "name": "Mittelhof",
                "countryCode": "DE"
            },
            {
                "name": "Mogendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mommenheim",
                "countryCode": "DE"
            },
            {
                "name": "Monsheim",
                "countryCode": "DE"
            },
            {
                "name": "Montabaur",
                "countryCode": "DE"
            },
            {
                "name": "Monzelfeld",
                "countryCode": "DE"
            },
            {
                "name": "Monzingen",
                "countryCode": "DE"
            },
            {
                "name": "Morbach",
                "countryCode": "DE"
            },
            {
                "name": "Mudersbach",
                "countryCode": "DE"
            },
            {
                "name": "Mutterstadt",
                "countryCode": "DE"
            },
            {
                "name": "Mülheim-Kärlich",
                "countryCode": "DE"
            },
            {
                "name": "Münchweiler an der Alsenz",
                "countryCode": "DE"
            },
            {
                "name": "Münchweiler an der Rodalbe",
                "countryCode": "DE"
            },
            {
                "name": "Münster-Sarmsheim",
                "countryCode": "DE"
            },
            {
                "name": "Münstermaifeld",
                "countryCode": "DE"
            },
            {
                "name": "Müschenbach",
                "countryCode": "DE"
            },
            {
                "name": "Nackenheim",
                "countryCode": "DE"
            },
            {
                "name": "Nanzdietschweiler",
                "countryCode": "DE"
            },
            {
                "name": "Nassau",
                "countryCode": "DE"
            },
            {
                "name": "Nastätten",
                "countryCode": "DE"
            },
            {
                "name": "Nauort",
                "countryCode": "DE"
            },
            {
                "name": "Nauroth",
                "countryCode": "DE"
            },
            {
                "name": "Nentershausen",
                "countryCode": "DE"
            },
            {
                "name": "Neuburg",
                "countryCode": "DE"
            },
            {
                "name": "Neuerburg",
                "countryCode": "DE"
            },
            {
                "name": "Neuhofen",
                "countryCode": "DE"
            },
            {
                "name": "Neuhäusel",
                "countryCode": "DE"
            },
            {
                "name": "Neumagen-Dhron",
                "countryCode": "DE"
            },
            {
                "name": "Neunkhausen",
                "countryCode": "DE"
            },
            {
                "name": "Neupotz",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt",
                "countryCode": "DE"
            },
            {
                "name": "Neuwied",
                "countryCode": "DE"
            },
            {
                "name": "Newel",
                "countryCode": "DE"
            },
            {
                "name": "Nickenich",
                "countryCode": "DE"
            },
            {
                "name": "Nieder-Ingelheim",
                "countryCode": "DE"
            },
            {
                "name": "Nieder-Olm",
                "countryCode": "DE"
            },
            {
                "name": "Niederbreitbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederdreisbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederdürenbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederelbert",
                "countryCode": "DE"
            },
            {
                "name": "Niedererbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederfell",
                "countryCode": "DE"
            },
            {
                "name": "Niederfischbach",
                "countryCode": "DE"
            },
            {
                "name": "Niederkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Niederkirchen bei Deidesheim",
                "countryCode": "DE"
            },
            {
                "name": "Niedermohr",
                "countryCode": "DE"
            },
            {
                "name": "Niederneisen",
                "countryCode": "DE"
            },
            {
                "name": "Niederwerth",
                "countryCode": "DE"
            },
            {
                "name": "Niederzissen",
                "countryCode": "DE"
            },
            {
                "name": "Nierstein",
                "countryCode": "DE"
            },
            {
                "name": "Nister",
                "countryCode": "DE"
            },
            {
                "name": "Nittel",
                "countryCode": "DE"
            },
            {
                "name": "Norheim",
                "countryCode": "DE"
            },
            {
                "name": "Nörtershausen",
                "countryCode": "DE"
            },
            {
                "name": "Ober-Flörsheim",
                "countryCode": "DE"
            },
            {
                "name": "Ober-Olm",
                "countryCode": "DE"
            },
            {
                "name": "Ober-Saulheim",
                "countryCode": "DE"
            },
            {
                "name": "Oberelbert",
                "countryCode": "DE"
            },
            {
                "name": "Oberfell",
                "countryCode": "DE"
            },
            {
                "name": "Obermoschel",
                "countryCode": "DE"
            },
            {
                "name": "Obernheim-Kirchenarnbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberotterbach",
                "countryCode": "DE"
            },
            {
                "name": "Oberwesel",
                "countryCode": "DE"
            },
            {
                "name": "Oberzissen",
                "countryCode": "DE"
            },
            {
                "name": "Obrigheim",
                "countryCode": "DE"
            },
            {
                "name": "Ochtendung",
                "countryCode": "DE"
            },
            {
                "name": "Ockenfels",
                "countryCode": "DE"
            },
            {
                "name": "Ockenheim",
                "countryCode": "DE"
            },
            {
                "name": "Odernheim",
                "countryCode": "DE"
            },
            {
                "name": "Offenbach an der Queich",
                "countryCode": "DE"
            },
            {
                "name": "Offenbach-Hundheim",
                "countryCode": "DE"
            },
            {
                "name": "Offstein",
                "countryCode": "DE"
            },
            {
                "name": "Olsbrücken",
                "countryCode": "DE"
            },
            {
                "name": "Oppenheim",
                "countryCode": "DE"
            },
            {
                "name": "Orenhofen",
                "countryCode": "DE"
            },
            {
                "name": "Osann-Monzel",
                "countryCode": "DE"
            },
            {
                "name": "Osburg",
                "countryCode": "DE"
            },
            {
                "name": "Osterspai",
                "countryCode": "DE"
            },
            {
                "name": "Osthofen",
                "countryCode": "DE"
            },
            {
                "name": "Otterbach",
                "countryCode": "DE"
            },
            {
                "name": "Otterberg",
                "countryCode": "DE"
            },
            {
                "name": "Ottersheim",
                "countryCode": "DE"
            },
            {
                "name": "Otterstadt",
                "countryCode": "DE"
            },
            {
                "name": "Palzem",
                "countryCode": "DE"
            },
            {
                "name": "Partenheim",
                "countryCode": "DE"
            },
            {
                "name": "Pellingen",
                "countryCode": "DE"
            },
            {
                "name": "Pelm",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffen-Schwabenheim",
                "countryCode": "DE"
            },
            {
                "name": "Pfeffelbach",
                "countryCode": "DE"
            },
            {
                "name": "Piesport",
                "countryCode": "DE"
            },
            {
                "name": "Pirmasens",
                "countryCode": "DE"
            },
            {
                "name": "Plaidt",
                "countryCode": "DE"
            },
            {
                "name": "Pluwig",
                "countryCode": "DE"
            },
            {
                "name": "Polch",
                "countryCode": "DE"
            },
            {
                "name": "Pottum",
                "countryCode": "DE"
            },
            {
                "name": "Pracht",
                "countryCode": "DE"
            },
            {
                "name": "Prüm",
                "countryCode": "DE"
            },
            {
                "name": "Puderbach",
                "countryCode": "DE"
            },
            {
                "name": "Queidersbach",
                "countryCode": "DE"
            },
            {
                "name": "Ralingen",
                "countryCode": "DE"
            },
            {
                "name": "Ramberg",
                "countryCode": "DE"
            },
            {
                "name": "Rammelsbach",
                "countryCode": "DE"
            },
            {
                "name": "Ramsen",
                "countryCode": "DE"
            },
            {
                "name": "Ramstein-Miesenbach",
                "countryCode": "DE"
            },
            {
                "name": "Ransbach-Baumbach",
                "countryCode": "DE"
            },
            {
                "name": "Raubach",
                "countryCode": "DE"
            },
            {
                "name": "Rehe",
                "countryCode": "DE"
            },
            {
                "name": "Reichenbach-Steegen",
                "countryCode": "DE"
            },
            {
                "name": "Reil",
                "countryCode": "DE"
            },
            {
                "name": "Reinsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Remagen",
                "countryCode": "DE"
            },
            {
                "name": "Rengsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rennerod",
                "countryCode": "DE"
            },
            {
                "name": "Rhaunen",
                "countryCode": "DE"
            },
            {
                "name": "Rheinbreitbach",
                "countryCode": "DE"
            },
            {
                "name": "Rheinbrohl",
                "countryCode": "DE"
            },
            {
                "name": "Rheinböllen",
                "countryCode": "DE"
            },
            {
                "name": "Rheinzabern",
                "countryCode": "DE"
            },
            {
                "name": "Rhens",
                "countryCode": "DE"
            },
            {
                "name": "Rhodt unter Rietburg",
                "countryCode": "DE"
            },
            {
                "name": "Rieden",
                "countryCode": "DE"
            },
            {
                "name": "Rieschweiler-Mühlbach",
                "countryCode": "DE"
            },
            {
                "name": "Rimschweiler",
                "countryCode": "DE"
            },
            {
                "name": "Riol",
                "countryCode": "DE"
            },
            {
                "name": "Rittersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rockenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Rodalben",
                "countryCode": "DE"
            },
            {
                "name": "Rodenbach",
                "countryCode": "DE"
            },
            {
                "name": "Rohrbach",
                "countryCode": "DE"
            },
            {
                "name": "Roth",
                "countryCode": "DE"
            },
            {
                "name": "Roxheim",
                "countryCode": "DE"
            },
            {
                "name": "Ruppach-Goldhausen",
                "countryCode": "DE"
            },
            {
                "name": "Ruppertsberg",
                "countryCode": "DE"
            },
            {
                "name": "Ruppertsweiler",
                "countryCode": "DE"
            },
            {
                "name": "Rödersheim-Gronau",
                "countryCode": "DE"
            },
            {
                "name": "Rüdesheim",
                "countryCode": "DE"
            },
            {
                "name": "Rülzheim",
                "countryCode": "DE"
            },
            {
                "name": "Rümmelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Saarburg",
                "countryCode": "DE"
            },
            {
                "name": "Saffig",
                "countryCode": "DE"
            },
            {
                "name": "Salmtal",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Goar",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Goarshausen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Julian",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Katharinen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Martin",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Sebastian",
                "countryCode": "DE"
            },
            {
                "name": "Scheuerfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schifferstadt",
                "countryCode": "DE"
            },
            {
                "name": "Schillingen",
                "countryCode": "DE"
            },
            {
                "name": "Schopp",
                "countryCode": "DE"
            },
            {
                "name": "Schornsheim",
                "countryCode": "DE"
            },
            {
                "name": "Schwabenheim",
                "countryCode": "DE"
            },
            {
                "name": "Schwedelbach",
                "countryCode": "DE"
            },
            {
                "name": "Schwegenheim",
                "countryCode": "DE"
            },
            {
                "name": "Schweich",
                "countryCode": "DE"
            },
            {
                "name": "Schweigen-Rechtenbach",
                "countryCode": "DE"
            },
            {
                "name": "Schönecken",
                "countryCode": "DE"
            },
            {
                "name": "Schönenberg-Kübelberg",
                "countryCode": "DE"
            },
            {
                "name": "Seck",
                "countryCode": "DE"
            },
            {
                "name": "Seibersbach",
                "countryCode": "DE"
            },
            {
                "name": "Selters",
                "countryCode": "DE"
            },
            {
                "name": "Selzen",
                "countryCode": "DE"
            },
            {
                "name": "Sembach",
                "countryCode": "DE"
            },
            {
                "name": "Serrig",
                "countryCode": "DE"
            },
            {
                "name": "Siebeldingen",
                "countryCode": "DE"
            },
            {
                "name": "Siefersheim",
                "countryCode": "DE"
            },
            {
                "name": "Siershahn",
                "countryCode": "DE"
            },
            {
                "name": "Simmern",
                "countryCode": "DE"
            },
            {
                "name": "Simmertal",
                "countryCode": "DE"
            },
            {
                "name": "Singhofen",
                "countryCode": "DE"
            },
            {
                "name": "Sinzig",
                "countryCode": "DE"
            },
            {
                "name": "Sippersfeld",
                "countryCode": "DE"
            },
            {
                "name": "Sohren",
                "countryCode": "DE"
            },
            {
                "name": "Spabrücken",
                "countryCode": "DE"
            },
            {
                "name": "Spay",
                "countryCode": "DE"
            },
            {
                "name": "Speicher",
                "countryCode": "DE"
            },
            {
                "name": "Speyer",
                "countryCode": "DE"
            },
            {
                "name": "Spiesheim",
                "countryCode": "DE"
            },
            {
                "name": "Sprendlingen",
                "countryCode": "DE"
            },
            {
                "name": "Stadecken-Elsheim",
                "countryCode": "DE"
            },
            {
                "name": "Stadtkyll",
                "countryCode": "DE"
            },
            {
                "name": "Staudernheim",
                "countryCode": "DE"
            },
            {
                "name": "Steimel",
                "countryCode": "DE"
            },
            {
                "name": "Steinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Steinweiler",
                "countryCode": "DE"
            },
            {
                "name": "Steinwenden",
                "countryCode": "DE"
            },
            {
                "name": "Stelzenberg",
                "countryCode": "DE"
            },
            {
                "name": "Stromberg",
                "countryCode": "DE"
            },
            {
                "name": "Sulzheim",
                "countryCode": "DE"
            },
            {
                "name": "Sörgenloch",
                "countryCode": "DE"
            },
            {
                "name": "Tawern",
                "countryCode": "DE"
            },
            {
                "name": "Thaleischweiler-Fröschen",
                "countryCode": "DE"
            },
            {
                "name": "Thalfang",
                "countryCode": "DE"
            },
            {
                "name": "Thomm",
                "countryCode": "DE"
            },
            {
                "name": "Thür",
                "countryCode": "DE"
            },
            {
                "name": "Traben-Trarbach",
                "countryCode": "DE"
            },
            {
                "name": "Trassem",
                "countryCode": "DE"
            },
            {
                "name": "Trechtingshausen",
                "countryCode": "DE"
            },
            {
                "name": "Treis-Karden",
                "countryCode": "DE"
            },
            {
                "name": "Trier",
                "countryCode": "DE"
            },
            {
                "name": "Trierweiler",
                "countryCode": "DE"
            },
            {
                "name": "Trippstadt",
                "countryCode": "DE"
            },
            {
                "name": "Trittenheim",
                "countryCode": "DE"
            },
            {
                "name": "Trulben",
                "countryCode": "DE"
            },
            {
                "name": "Udenheim",
                "countryCode": "DE"
            },
            {
                "name": "Uelversheim",
                "countryCode": "DE"
            },
            {
                "name": "Ulmen",
                "countryCode": "DE"
            },
            {
                "name": "Undenheim",
                "countryCode": "DE"
            },
            {
                "name": "Unkel",
                "countryCode": "DE"
            },
            {
                "name": "Unnau",
                "countryCode": "DE"
            },
            {
                "name": "Urbach-Überdorf",
                "countryCode": "DE"
            },
            {
                "name": "Urbar",
                "countryCode": "DE"
            },
            {
                "name": "Urmitz",
                "countryCode": "DE"
            },
            {
                "name": "Vallendar",
                "countryCode": "DE"
            },
            {
                "name": "Vettelschoß",
                "countryCode": "DE"
            },
            {
                "name": "Vinningen",
                "countryCode": "DE"
            },
            {
                "name": "Volxheim",
                "countryCode": "DE"
            },
            {
                "name": "Wachenheim",
                "countryCode": "DE"
            },
            {
                "name": "Wackernheim",
                "countryCode": "DE"
            },
            {
                "name": "Waldalgesheim",
                "countryCode": "DE"
            },
            {
                "name": "Waldbreitbach",
                "countryCode": "DE"
            },
            {
                "name": "Waldböckelheim",
                "countryCode": "DE"
            },
            {
                "name": "Waldfischbach-Burgalben",
                "countryCode": "DE"
            },
            {
                "name": "Waldmohr",
                "countryCode": "DE"
            },
            {
                "name": "Waldrach",
                "countryCode": "DE"
            },
            {
                "name": "Waldsee",
                "countryCode": "DE"
            },
            {
                "name": "Wallertheim",
                "countryCode": "DE"
            },
            {
                "name": "Wallhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wallmenroth",
                "countryCode": "DE"
            },
            {
                "name": "Wallmerod",
                "countryCode": "DE"
            },
            {
                "name": "Wassenach",
                "countryCode": "DE"
            },
            {
                "name": "Wasserliesch",
                "countryCode": "DE"
            },
            {
                "name": "Wattenheim",
                "countryCode": "DE"
            },
            {
                "name": "Waxweiler",
                "countryCode": "DE"
            },
            {
                "name": "Wehr",
                "countryCode": "DE"
            },
            {
                "name": "Weibern",
                "countryCode": "DE"
            },
            {
                "name": "Weidenthal",
                "countryCode": "DE"
            },
            {
                "name": "Weiler",
                "countryCode": "DE"
            },
            {
                "name": "Weilerbach",
                "countryCode": "DE"
            },
            {
                "name": "Weingarten",
                "countryCode": "DE"
            },
            {
                "name": "Weinsheim",
                "countryCode": "DE"
            },
            {
                "name": "Weisel",
                "countryCode": "DE"
            },
            {
                "name": "Weitefeld",
                "countryCode": "DE"
            },
            {
                "name": "Weitersburg",
                "countryCode": "DE"
            },
            {
                "name": "Weißenthurm",
                "countryCode": "DE"
            },
            {
                "name": "Welschbillig",
                "countryCode": "DE"
            },
            {
                "name": "Wendelsheim",
                "countryCode": "DE"
            },
            {
                "name": "Wernersberg",
                "countryCode": "DE"
            },
            {
                "name": "Weselberg",
                "countryCode": "DE"
            },
            {
                "name": "Westerburg",
                "countryCode": "DE"
            },
            {
                "name": "Westheim",
                "countryCode": "DE"
            },
            {
                "name": "Westhofen",
                "countryCode": "DE"
            },
            {
                "name": "Weyerbusch",
                "countryCode": "DE"
            },
            {
                "name": "Wilgartswiesen",
                "countryCode": "DE"
            },
            {
                "name": "Wiltingen",
                "countryCode": "DE"
            },
            {
                "name": "Wincheringen",
                "countryCode": "DE"
            },
            {
                "name": "Winden",
                "countryCode": "DE"
            },
            {
                "name": "Windesheim",
                "countryCode": "DE"
            },
            {
                "name": "Windhagen",
                "countryCode": "DE"
            },
            {
                "name": "Winningen",
                "countryCode": "DE"
            },
            {
                "name": "Winnweiler",
                "countryCode": "DE"
            },
            {
                "name": "Wirges",
                "countryCode": "DE"
            },
            {
                "name": "Wissen",
                "countryCode": "DE"
            },
            {
                "name": "Wittlich",
                "countryCode": "DE"
            },
            {
                "name": "Wolfstein",
                "countryCode": "DE"
            },
            {
                "name": "Wolken",
                "countryCode": "DE"
            },
            {
                "name": "Worms",
                "countryCode": "DE"
            },
            {
                "name": "Wöllstein",
                "countryCode": "DE"
            },
            {
                "name": "Wörrstadt",
                "countryCode": "DE"
            },
            {
                "name": "Wörth am Rhein",
                "countryCode": "DE"
            },
            {
                "name": "Zeiskam",
                "countryCode": "DE"
            },
            {
                "name": "Zell",
                "countryCode": "DE"
            },
            {
                "name": "Zeltingen-Rachtig",
                "countryCode": "DE"
            },
            {
                "name": "Zemmer",
                "countryCode": "DE"
            },
            {
                "name": "Zerf",
                "countryCode": "DE"
            },
            {
                "name": "Zornheim",
                "countryCode": "DE"
            },
            {
                "name": "Zweibrücken",
                "countryCode": "DE"
            },
            {
                "name": "Ötzingen",
                "countryCode": "DE"
            },
            {
                "name": "Üdersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Üxheim",
                "countryCode": "DE"
            },
            {
                "name": "Achtrup",
                "countryCode": "DE"
            },
            {
                "name": "Ahrensburg",
                "countryCode": "DE"
            },
            {
                "name": "Ahrensbök",
                "countryCode": "DE"
            },
            {
                "name": "Albersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Alt Duvenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Altenholz",
                "countryCode": "DE"
            },
            {
                "name": "Altenkrempe",
                "countryCode": "DE"
            },
            {
                "name": "Alveslohe",
                "countryCode": "DE"
            },
            {
                "name": "Appen",
                "countryCode": "DE"
            },
            {
                "name": "Ascheberg",
                "countryCode": "DE"
            },
            {
                "name": "Aukrug",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bramstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bad Oldesloe",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schwartau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Segeberg",
                "countryCode": "DE"
            },
            {
                "name": "Bargfeld-Stegen",
                "countryCode": "DE"
            },
            {
                "name": "Bargteheide",
                "countryCode": "DE"
            },
            {
                "name": "Bark",
                "countryCode": "DE"
            },
            {
                "name": "Barkelsby",
                "countryCode": "DE"
            },
            {
                "name": "Barmstedt",
                "countryCode": "DE"
            },
            {
                "name": "Barsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Berkenthin",
                "countryCode": "DE"
            },
            {
                "name": "Blekendorf",
                "countryCode": "DE"
            },
            {
                "name": "Bokholt-Hanredder",
                "countryCode": "DE"
            },
            {
                "name": "Bollingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Boostedt",
                "countryCode": "DE"
            },
            {
                "name": "Bordelum",
                "countryCode": "DE"
            },
            {
                "name": "Bordesholm",
                "countryCode": "DE"
            },
            {
                "name": "Borgstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bornhöved",
                "countryCode": "DE"
            },
            {
                "name": "Borstel-Hohenraden",
                "countryCode": "DE"
            },
            {
                "name": "Bosau",
                "countryCode": "DE"
            },
            {
                "name": "Bovenau",
                "countryCode": "DE"
            },
            {
                "name": "Brande-Hörnerkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Bredenbek",
                "countryCode": "DE"
            },
            {
                "name": "Bredstedt",
                "countryCode": "DE"
            },
            {
                "name": "Breiholz",
                "countryCode": "DE"
            },
            {
                "name": "Breitenburg",
                "countryCode": "DE"
            },
            {
                "name": "Breitenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Brekendorf",
                "countryCode": "DE"
            },
            {
                "name": "Breklum",
                "countryCode": "DE"
            },
            {
                "name": "Brokdorf",
                "countryCode": "DE"
            },
            {
                "name": "Brokstedt",
                "countryCode": "DE"
            },
            {
                "name": "Brunsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Brügge",
                "countryCode": "DE"
            },
            {
                "name": "Buchholz",
                "countryCode": "DE"
            },
            {
                "name": "Burg",
                "countryCode": "DE"
            },
            {
                "name": "Burg auf Fehmarn",
                "countryCode": "DE"
            },
            {
                "name": "Busdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bönebüttel",
                "countryCode": "DE"
            },
            {
                "name": "Bönningstedt",
                "countryCode": "DE"
            },
            {
                "name": "Börnsen",
                "countryCode": "DE"
            },
            {
                "name": "Bösdorf",
                "countryCode": "DE"
            },
            {
                "name": "Büchen",
                "countryCode": "DE"
            },
            {
                "name": "Büdelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Büsum",
                "countryCode": "DE"
            },
            {
                "name": "Dahme",
                "countryCode": "DE"
            },
            {
                "name": "Damp",
                "countryCode": "DE"
            },
            {
                "name": "Dannewerk",
                "countryCode": "DE"
            },
            {
                "name": "Dassendorf",
                "countryCode": "DE"
            },
            {
                "name": "Delingsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dobersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dollerup",
                "countryCode": "DE"
            },
            {
                "name": "Drelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dägeling",
                "countryCode": "DE"
            },
            {
                "name": "Dänischenhagen",
                "countryCode": "DE"
            },
            {
                "name": "Eckernförde",
                "countryCode": "DE"
            },
            {
                "name": "Eddelak",
                "countryCode": "DE"
            },
            {
                "name": "Eggebek",
                "countryCode": "DE"
            },
            {
                "name": "Ellerau",
                "countryCode": "DE"
            },
            {
                "name": "Ellerbek",
                "countryCode": "DE"
            },
            {
                "name": "Ellerhoop",
                "countryCode": "DE"
            },
            {
                "name": "Elmenhorst",
                "countryCode": "DE"
            },
            {
                "name": "Elmshorn",
                "countryCode": "DE"
            },
            {
                "name": "Elsdorf-Westermühlen",
                "countryCode": "DE"
            },
            {
                "name": "Emkendorf",
                "countryCode": "DE"
            },
            {
                "name": "Emmelsbüll-Horsbüll",
                "countryCode": "DE"
            },
            {
                "name": "Erfde",
                "countryCode": "DE"
            },
            {
                "name": "Escheburg",
                "countryCode": "DE"
            },
            {
                "name": "Eutin",
                "countryCode": "DE"
            },
            {
                "name": "Fahrdorf",
                "countryCode": "DE"
            },
            {
                "name": "Fahrenkrug",
                "countryCode": "DE"
            },
            {
                "name": "Fehmarn",
                "countryCode": "DE"
            },
            {
                "name": "Felde",
                "countryCode": "DE"
            },
            {
                "name": "Felm",
                "countryCode": "DE"
            },
            {
                "name": "Fleckeby",
                "countryCode": "DE"
            },
            {
                "name": "Flensburg",
                "countryCode": "DE"
            },
            {
                "name": "Flintbek",
                "countryCode": "DE"
            },
            {
                "name": "Fockbek",
                "countryCode": "DE"
            },
            {
                "name": "Freienwill",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichskoog",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichstadt",
                "countryCode": "DE"
            },
            {
                "name": "Garding",
                "countryCode": "DE"
            },
            {
                "name": "Geesthacht",
                "countryCode": "DE"
            },
            {
                "name": "Gelting",
                "countryCode": "DE"
            },
            {
                "name": "Gettorf",
                "countryCode": "DE"
            },
            {
                "name": "Giekau",
                "countryCode": "DE"
            },
            {
                "name": "Glinde",
                "countryCode": "DE"
            },
            {
                "name": "Glücksburg",
                "countryCode": "DE"
            },
            {
                "name": "Glückstadt",
                "countryCode": "DE"
            },
            {
                "name": "Gnutz",
                "countryCode": "DE"
            },
            {
                "name": "Goel",
                "countryCode": "DE"
            },
            {
                "name": "Grebin",
                "countryCode": "DE"
            },
            {
                "name": "Gremersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Grossenbrode",
                "countryCode": "DE"
            },
            {
                "name": "Groß Grönau",
                "countryCode": "DE"
            },
            {
                "name": "Groß Kummerfeld",
                "countryCode": "DE"
            },
            {
                "name": "Groß Rheide",
                "countryCode": "DE"
            },
            {
                "name": "Groß Wittensee",
                "countryCode": "DE"
            },
            {
                "name": "Großenaspe",
                "countryCode": "DE"
            },
            {
                "name": "Großensee",
                "countryCode": "DE"
            },
            {
                "name": "Großenwiehe",
                "countryCode": "DE"
            },
            {
                "name": "Großhansdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großsolt",
                "countryCode": "DE"
            },
            {
                "name": "Grube",
                "countryCode": "DE"
            },
            {
                "name": "Grömitz",
                "countryCode": "DE"
            },
            {
                "name": "Grönwohld",
                "countryCode": "DE"
            },
            {
                "name": "Gudow",
                "countryCode": "DE"
            },
            {
                "name": "Gülzow",
                "countryCode": "DE"
            },
            {
                "name": "Güster",
                "countryCode": "DE"
            },
            {
                "name": "Halstenbek",
                "countryCode": "DE"
            },
            {
                "name": "Hamberge",
                "countryCode": "DE"
            },
            {
                "name": "Hamdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hammoor",
                "countryCode": "DE"
            },
            {
                "name": "Handewitt",
                "countryCode": "DE"
            },
            {
                "name": "Hanerau-Hademarschen",
                "countryCode": "DE"
            },
            {
                "name": "Harrislee",
                "countryCode": "DE"
            },
            {
                "name": "Hartenholm",
                "countryCode": "DE"
            },
            {
                "name": "Haselau",
                "countryCode": "DE"
            },
            {
                "name": "Haseldorf",
                "countryCode": "DE"
            },
            {
                "name": "Hasloh",
                "countryCode": "DE"
            },
            {
                "name": "Hattstedt",
                "countryCode": "DE"
            },
            {
                "name": "Heide",
                "countryCode": "DE"
            },
            {
                "name": "Heidgraben",
                "countryCode": "DE"
            },
            {
                "name": "Heikendorf",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenhafen",
                "countryCode": "DE"
            },
            {
                "name": "Heiligenstedten",
                "countryCode": "DE"
            },
            {
                "name": "Heist",
                "countryCode": "DE"
            },
            {
                "name": "Helgoland",
                "countryCode": "DE"
            },
            {
                "name": "Hemdingen",
                "countryCode": "DE"
            },
            {
                "name": "Hemmingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hennstedt",
                "countryCode": "DE"
            },
            {
                "name": "Henstedt-Ulzburg",
                "countryCode": "DE"
            },
            {
                "name": "Heringsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herzhorn",
                "countryCode": "DE"
            },
            {
                "name": "Hetlingen",
                "countryCode": "DE"
            },
            {
                "name": "Hitzhusen",
                "countryCode": "DE"
            },
            {
                "name": "Hochdonn",
                "countryCode": "DE"
            },
            {
                "name": "Hohenaspe",
                "countryCode": "DE"
            },
            {
                "name": "Hohenfelde",
                "countryCode": "DE"
            },
            {
                "name": "Hohenlockstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hohenwestedt",
                "countryCode": "DE"
            },
            {
                "name": "Hohn",
                "countryCode": "DE"
            },
            {
                "name": "Hoisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hollingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Holm",
                "countryCode": "DE"
            },
            {
                "name": "Holtsee",
                "countryCode": "DE"
            },
            {
                "name": "Horst",
                "countryCode": "DE"
            },
            {
                "name": "Husum",
                "countryCode": "DE"
            },
            {
                "name": "Hürup",
                "countryCode": "DE"
            },
            {
                "name": "Itzehoe",
                "countryCode": "DE"
            },
            {
                "name": "Itzstedt",
                "countryCode": "DE"
            },
            {
                "name": "Jarplund-Weding",
                "countryCode": "DE"
            },
            {
                "name": "Jerrishoe",
                "countryCode": "DE"
            },
            {
                "name": "Jersbek",
                "countryCode": "DE"
            },
            {
                "name": "Jevenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Jübek",
                "countryCode": "DE"
            },
            {
                "name": "Kaltenkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Kappeln",
                "countryCode": "DE"
            },
            {
                "name": "Kasseedorf",
                "countryCode": "DE"
            },
            {
                "name": "Kastorf",
                "countryCode": "DE"
            },
            {
                "name": "Kayhude",
                "countryCode": "DE"
            },
            {
                "name": "Keitum",
                "countryCode": "DE"
            },
            {
                "name": "Kellenhusen",
                "countryCode": "DE"
            },
            {
                "name": "Kellinghusen",
                "countryCode": "DE"
            },
            {
                "name": "Kiebitzreihe",
                "countryCode": "DE"
            },
            {
                "name": "Kiel",
                "countryCode": "DE"
            },
            {
                "name": "Kisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Klausdorf",
                "countryCode": "DE"
            },
            {
                "name": "Klein Nordende",
                "countryCode": "DE"
            },
            {
                "name": "Klein Offenseth-Sparrieshoop",
                "countryCode": "DE"
            },
            {
                "name": "Klein Rönnau",
                "countryCode": "DE"
            },
            {
                "name": "Kollmar",
                "countryCode": "DE"
            },
            {
                "name": "Kosel",
                "countryCode": "DE"
            },
            {
                "name": "Krempe",
                "countryCode": "DE"
            },
            {
                "name": "Kremperheide",
                "countryCode": "DE"
            },
            {
                "name": "Kronshagen",
                "countryCode": "DE"
            },
            {
                "name": "Kropp",
                "countryCode": "DE"
            },
            {
                "name": "Krummesse",
                "countryCode": "DE"
            },
            {
                "name": "Kröppelshagen-Fahrendorf",
                "countryCode": "DE"
            },
            {
                "name": "Kuddewörde",
                "countryCode": "DE"
            },
            {
                "name": "Kummerfeld",
                "countryCode": "DE"
            },
            {
                "name": "Kölln-Reisiek",
                "countryCode": "DE"
            },
            {
                "name": "Laboe",
                "countryCode": "DE"
            },
            {
                "name": "Ladelund",
                "countryCode": "DE"
            },
            {
                "name": "Langballig",
                "countryCode": "DE"
            },
            {
                "name": "Langenhorn",
                "countryCode": "DE"
            },
            {
                "name": "Langstedt",
                "countryCode": "DE"
            },
            {
                "name": "Langwedel",
                "countryCode": "DE"
            },
            {
                "name": "Lasbek",
                "countryCode": "DE"
            },
            {
                "name": "Lauenburg",
                "countryCode": "DE"
            },
            {
                "name": "Leck",
                "countryCode": "DE"
            },
            {
                "name": "Leezen",
                "countryCode": "DE"
            },
            {
                "name": "Lehe",
                "countryCode": "DE"
            },
            {
                "name": "Lehmkuhlen",
                "countryCode": "DE"
            },
            {
                "name": "Lensahn",
                "countryCode": "DE"
            },
            {
                "name": "Lentföhrden",
                "countryCode": "DE"
            },
            {
                "name": "Linau",
                "countryCode": "DE"
            },
            {
                "name": "Lindau",
                "countryCode": "DE"
            },
            {
                "name": "Lindewitt",
                "countryCode": "DE"
            },
            {
                "name": "List",
                "countryCode": "DE"
            },
            {
                "name": "Lohe-Rickelshof",
                "countryCode": "DE"
            },
            {
                "name": "Lunden",
                "countryCode": "DE"
            },
            {
                "name": "Lägerdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lübeck",
                "countryCode": "DE"
            },
            {
                "name": "Lürschau",
                "countryCode": "DE"
            },
            {
                "name": "Lütjenburg",
                "countryCode": "DE"
            },
            {
                "name": "Lütjensee",
                "countryCode": "DE"
            },
            {
                "name": "Malente",
                "countryCode": "DE"
            },
            {
                "name": "Martensrade",
                "countryCode": "DE"
            },
            {
                "name": "Meldorf",
                "countryCode": "DE"
            },
            {
                "name": "Melsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mielkendorf",
                "countryCode": "DE"
            },
            {
                "name": "Mildstedt",
                "countryCode": "DE"
            },
            {
                "name": "Mohrkirch",
                "countryCode": "DE"
            },
            {
                "name": "Molfsee",
                "countryCode": "DE"
            },
            {
                "name": "Moorrege",
                "countryCode": "DE"
            },
            {
                "name": "Munkbrarup",
                "countryCode": "DE"
            },
            {
                "name": "Mölln",
                "countryCode": "DE"
            },
            {
                "name": "Mönkeberg",
                "countryCode": "DE"
            },
            {
                "name": "Münsterdorf",
                "countryCode": "DE"
            },
            {
                "name": "Nahe",
                "countryCode": "DE"
            },
            {
                "name": "Negernbötel",
                "countryCode": "DE"
            },
            {
                "name": "Neuberend",
                "countryCode": "DE"
            },
            {
                "name": "Neudorf-Bornstein",
                "countryCode": "DE"
            },
            {
                "name": "Neukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neumünster",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt in Holstein",
                "countryCode": "DE"
            },
            {
                "name": "Neuwittenbek",
                "countryCode": "DE"
            },
            {
                "name": "Niebüll",
                "countryCode": "DE"
            },
            {
                "name": "Nindorf",
                "countryCode": "DE"
            },
            {
                "name": "Norderstedt",
                "countryCode": "DE"
            },
            {
                "name": "Nordhastedt",
                "countryCode": "DE"
            },
            {
                "name": "Nortorf",
                "countryCode": "DE"
            },
            {
                "name": "Nusse",
                "countryCode": "DE"
            },
            {
                "name": "Nübbel",
                "countryCode": "DE"
            },
            {
                "name": "Nützen",
                "countryCode": "DE"
            },
            {
                "name": "Oelixdorf",
                "countryCode": "DE"
            },
            {
                "name": "Oeversee",
                "countryCode": "DE"
            },
            {
                "name": "Oldenburg in Holstein",
                "countryCode": "DE"
            },
            {
                "name": "Oldendorf",
                "countryCode": "DE"
            },
            {
                "name": "Oldenswort",
                "countryCode": "DE"
            },
            {
                "name": "Osdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ostenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Osterrönfeld",
                "countryCode": "DE"
            },
            {
                "name": "Oststeinbek",
                "countryCode": "DE"
            },
            {
                "name": "Owschlag",
                "countryCode": "DE"
            },
            {
                "name": "Padenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Pahlen",
                "countryCode": "DE"
            },
            {
                "name": "Panker",
                "countryCode": "DE"
            },
            {
                "name": "Pellworm",
                "countryCode": "DE"
            },
            {
                "name": "Pinneberg",
                "countryCode": "DE"
            },
            {
                "name": "Plön",
                "countryCode": "DE"
            },
            {
                "name": "Preetz",
                "countryCode": "DE"
            },
            {
                "name": "Prisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Probsteierhagen",
                "countryCode": "DE"
            },
            {
                "name": "Pronstorf",
                "countryCode": "DE"
            },
            {
                "name": "Pölitz",
                "countryCode": "DE"
            },
            {
                "name": "Quarnbek",
                "countryCode": "DE"
            },
            {
                "name": "Quern",
                "countryCode": "DE"
            },
            {
                "name": "Quickborn",
                "countryCode": "DE"
            },
            {
                "name": "Raisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rantrum",
                "countryCode": "DE"
            },
            {
                "name": "Ratekau",
                "countryCode": "DE"
            },
            {
                "name": "Ratzeburg",
                "countryCode": "DE"
            },
            {
                "name": "Reinbek",
                "countryCode": "DE"
            },
            {
                "name": "Reinfeld",
                "countryCode": "DE"
            },
            {
                "name": "Rellingen",
                "countryCode": "DE"
            },
            {
                "name": "Rendsburg",
                "countryCode": "DE"
            },
            {
                "name": "Rickert",
                "countryCode": "DE"
            },
            {
                "name": "Rickling",
                "countryCode": "DE"
            },
            {
                "name": "Riepsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rieseby",
                "countryCode": "DE"
            },
            {
                "name": "Risum-Lindholm",
                "countryCode": "DE"
            },
            {
                "name": "Ruhwinkel",
                "countryCode": "DE"
            },
            {
                "name": "Rümpel",
                "countryCode": "DE"
            },
            {
                "name": "Sandesneben",
                "countryCode": "DE"
            },
            {
                "name": "Sankelmark",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Margarethen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Michaelisdonn",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Peter-Ording",
                "countryCode": "DE"
            },
            {
                "name": "Satrup",
                "countryCode": "DE"
            },
            {
                "name": "Schaalby",
                "countryCode": "DE"
            },
            {
                "name": "Schacht-Audorf",
                "countryCode": "DE"
            },
            {
                "name": "Schafflund",
                "countryCode": "DE"
            },
            {
                "name": "Scharbeutz",
                "countryCode": "DE"
            },
            {
                "name": "Schashagen",
                "countryCode": "DE"
            },
            {
                "name": "Schellhorn",
                "countryCode": "DE"
            },
            {
                "name": "Schenefeld",
                "countryCode": "DE"
            },
            {
                "name": "Schleswig",
                "countryCode": "DE"
            },
            {
                "name": "Schmalfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schobüll",
                "countryCode": "DE"
            },
            {
                "name": "Schuby",
                "countryCode": "DE"
            },
            {
                "name": "Schwabstedt",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenbek",
                "countryCode": "DE"
            },
            {
                "name": "Schönberg",
                "countryCode": "DE"
            },
            {
                "name": "Schönkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Schönwalde am Bungsberg",
                "countryCode": "DE"
            },
            {
                "name": "Schülp",
                "countryCode": "DE"
            },
            {
                "name": "Seedorf",
                "countryCode": "DE"
            },
            {
                "name": "Selent",
                "countryCode": "DE"
            },
            {
                "name": "Seth",
                "countryCode": "DE"
            },
            {
                "name": "Siek",
                "countryCode": "DE"
            },
            {
                "name": "Sierksdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sievershütten",
                "countryCode": "DE"
            },
            {
                "name": "Sieverstedt",
                "countryCode": "DE"
            },
            {
                "name": "Silberstedt",
                "countryCode": "DE"
            },
            {
                "name": "Stadum",
                "countryCode": "DE"
            },
            {
                "name": "Stapelfeld",
                "countryCode": "DE"
            },
            {
                "name": "Steinberg",
                "countryCode": "DE"
            },
            {
                "name": "Steinbergkirche",
                "countryCode": "DE"
            },
            {
                "name": "Sterup",
                "countryCode": "DE"
            },
            {
                "name": "Stockelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Stolpe",
                "countryCode": "DE"
            },
            {
                "name": "Strande",
                "countryCode": "DE"
            },
            {
                "name": "Struvenhütten",
                "countryCode": "DE"
            },
            {
                "name": "Sylt-Ost",
                "countryCode": "DE"
            },
            {
                "name": "Sörup",
                "countryCode": "DE"
            },
            {
                "name": "Süderbrarup",
                "countryCode": "DE"
            },
            {
                "name": "Süderlügum",
                "countryCode": "DE"
            },
            {
                "name": "Süderstapel",
                "countryCode": "DE"
            },
            {
                "name": "Sülfeld",
                "countryCode": "DE"
            },
            {
                "name": "Süsel",
                "countryCode": "DE"
            },
            {
                "name": "Tangstedt",
                "countryCode": "DE"
            },
            {
                "name": "Tarp",
                "countryCode": "DE"
            },
            {
                "name": "Tating",
                "countryCode": "DE"
            },
            {
                "name": "Tellingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Timmaspe",
                "countryCode": "DE"
            },
            {
                "name": "Timmendorfer Strand",
                "countryCode": "DE"
            },
            {
                "name": "Tinnum",
                "countryCode": "DE"
            },
            {
                "name": "Todenbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Todendorf",
                "countryCode": "DE"
            },
            {
                "name": "Todesfelde",
                "countryCode": "DE"
            },
            {
                "name": "Tolk",
                "countryCode": "DE"
            },
            {
                "name": "Tornesch",
                "countryCode": "DE"
            },
            {
                "name": "Trappenkamp",
                "countryCode": "DE"
            },
            {
                "name": "Travemünde",
                "countryCode": "DE"
            },
            {
                "name": "Treia",
                "countryCode": "DE"
            },
            {
                "name": "Tremsbüttel",
                "countryCode": "DE"
            },
            {
                "name": "Trittau",
                "countryCode": "DE"
            },
            {
                "name": "Tönning",
                "countryCode": "DE"
            },
            {
                "name": "Tüttendorf",
                "countryCode": "DE"
            },
            {
                "name": "Uetersen",
                "countryCode": "DE"
            },
            {
                "name": "Vaale",
                "countryCode": "DE"
            },
            {
                "name": "Viöl",
                "countryCode": "DE"
            },
            {
                "name": "Waabs",
                "countryCode": "DE"
            },
            {
                "name": "Wacken",
                "countryCode": "DE"
            },
            {
                "name": "Wahlstedt",
                "countryCode": "DE"
            },
            {
                "name": "Wanderup",
                "countryCode": "DE"
            },
            {
                "name": "Wangels",
                "countryCode": "DE"
            },
            {
                "name": "Wankendorf",
                "countryCode": "DE"
            },
            {
                "name": "Wasbek",
                "countryCode": "DE"
            },
            {
                "name": "Wattenbek",
                "countryCode": "DE"
            },
            {
                "name": "Weddelbrook",
                "countryCode": "DE"
            },
            {
                "name": "Weddingstedt",
                "countryCode": "DE"
            },
            {
                "name": "Wedel",
                "countryCode": "DE"
            },
            {
                "name": "Wees",
                "countryCode": "DE"
            },
            {
                "name": "Wendtorf",
                "countryCode": "DE"
            },
            {
                "name": "Wentorf bei Hamburg",
                "countryCode": "DE"
            },
            {
                "name": "Wesselburen",
                "countryCode": "DE"
            },
            {
                "name": "Wesseln",
                "countryCode": "DE"
            },
            {
                "name": "Westensee",
                "countryCode": "DE"
            },
            {
                "name": "Wester-Ohrstedt",
                "countryCode": "DE"
            },
            {
                "name": "Westerhorn",
                "countryCode": "DE"
            },
            {
                "name": "Westerland",
                "countryCode": "DE"
            },
            {
                "name": "Westerrönfeld",
                "countryCode": "DE"
            },
            {
                "name": "Wewelsfleth",
                "countryCode": "DE"
            },
            {
                "name": "Wiemersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wilster",
                "countryCode": "DE"
            },
            {
                "name": "Windeby",
                "countryCode": "DE"
            },
            {
                "name": "Witzhave",
                "countryCode": "DE"
            },
            {
                "name": "Wohltorf",
                "countryCode": "DE"
            },
            {
                "name": "Wrist",
                "countryCode": "DE"
            },
            {
                "name": "Wyk auf Föhr",
                "countryCode": "DE"
            },
            {
                "name": "Wöhrden",
                "countryCode": "DE"
            },
            {
                "name": "Zarpen",
                "countryCode": "DE"
            },
            {
                "name": "Beckingen",
                "countryCode": "DE"
            },
            {
                "name": "Bexbach",
                "countryCode": "DE"
            },
            {
                "name": "Blieskastel",
                "countryCode": "DE"
            },
            {
                "name": "Bous",
                "countryCode": "DE"
            },
            {
                "name": "Britten",
                "countryCode": "DE"
            },
            {
                "name": "Dillingen",
                "countryCode": "DE"
            },
            {
                "name": "Ensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Eppelborn",
                "countryCode": "DE"
            },
            {
                "name": "Freisen",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichsthal",
                "countryCode": "DE"
            },
            {
                "name": "Fürstenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Gersheim",
                "countryCode": "DE"
            },
            {
                "name": "Großrosseln",
                "countryCode": "DE"
            },
            {
                "name": "Hangard",
                "countryCode": "DE"
            },
            {
                "name": "Heidstock",
                "countryCode": "DE"
            },
            {
                "name": "Heusweiler",
                "countryCode": "DE"
            },
            {
                "name": "Homburg",
                "countryCode": "DE"
            },
            {
                "name": "Illingen",
                "countryCode": "DE"
            },
            {
                "name": "Kirkel",
                "countryCode": "DE"
            },
            {
                "name": "Kleinblittersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lebach",
                "countryCode": "DE"
            },
            {
                "name": "Losheim",
                "countryCode": "DE"
            },
            {
                "name": "Ludweiler-Warndt",
                "countryCode": "DE"
            },
            {
                "name": "Luisenthal",
                "countryCode": "DE"
            },
            {
                "name": "Mainzweiler",
                "countryCode": "DE"
            },
            {
                "name": "Marpingen",
                "countryCode": "DE"
            },
            {
                "name": "Merchweiler",
                "countryCode": "DE"
            },
            {
                "name": "Merzig",
                "countryCode": "DE"
            },
            {
                "name": "Mettlach",
                "countryCode": "DE"
            },
            {
                "name": "Nalbach",
                "countryCode": "DE"
            },
            {
                "name": "Namborn",
                "countryCode": "DE"
            },
            {
                "name": "Neunkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Nohfelden",
                "countryCode": "DE"
            },
            {
                "name": "Nonnweiler",
                "countryCode": "DE"
            },
            {
                "name": "Oberthal",
                "countryCode": "DE"
            },
            {
                "name": "Orscholz",
                "countryCode": "DE"
            },
            {
                "name": "Ottweiler",
                "countryCode": "DE"
            },
            {
                "name": "Püttlingen",
                "countryCode": "DE"
            },
            {
                "name": "Quierschied",
                "countryCode": "DE"
            },
            {
                "name": "Riegelsberg",
                "countryCode": "DE"
            },
            {
                "name": "Röchling-Höhe",
                "countryCode": "DE"
            },
            {
                "name": "Saarbrücken",
                "countryCode": "DE"
            },
            {
                "name": "Saarhölzbach",
                "countryCode": "DE"
            },
            {
                "name": "Saarlouis",
                "countryCode": "DE"
            },
            {
                "name": "Saarwellingen",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Ingbert",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Wendel",
                "countryCode": "DE"
            },
            {
                "name": "Schiffweiler",
                "countryCode": "DE"
            },
            {
                "name": "Schmelz",
                "countryCode": "DE"
            },
            {
                "name": "Schwalbach",
                "countryCode": "DE"
            },
            {
                "name": "Spiesen-Elversberg",
                "countryCode": "DE"
            },
            {
                "name": "Sulzbach",
                "countryCode": "DE"
            },
            {
                "name": "Tholey",
                "countryCode": "DE"
            },
            {
                "name": "Völklingen",
                "countryCode": "DE"
            },
            {
                "name": "Wadern",
                "countryCode": "DE"
            },
            {
                "name": "Wadgassen",
                "countryCode": "DE"
            },
            {
                "name": "Wallerfangen",
                "countryCode": "DE"
            },
            {
                "name": "Weiskirchen",
                "countryCode": "DE"
            },
            {
                "name": "Weiten",
                "countryCode": "DE"
            },
            {
                "name": "Überherrn",
                "countryCode": "DE"
            },
            {
                "name": "Adorf",
                "countryCode": "DE"
            },
            {
                "name": "Albertstadt",
                "countryCode": "DE"
            },
            {
                "name": "Altenberg",
                "countryCode": "DE"
            },
            {
                "name": "Altmittweida",
                "countryCode": "DE"
            },
            {
                "name": "Annaberg-Buchholz",
                "countryCode": "DE"
            },
            {
                "name": "Arzberg",
                "countryCode": "DE"
            },
            {
                "name": "Aue",
                "countryCode": "DE"
            },
            {
                "name": "Auerbach",
                "countryCode": "DE"
            },
            {
                "name": "Augustusburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Brambach",
                "countryCode": "DE"
            },
            {
                "name": "Bad Düben",
                "countryCode": "DE"
            },
            {
                "name": "Bad Elster",
                "countryCode": "DE"
            },
            {
                "name": "Bad Lausick",
                "countryCode": "DE"
            },
            {
                "name": "Bad Muskau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schandau",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schlema",
                "countryCode": "DE"
            },
            {
                "name": "Bannewitz",
                "countryCode": "DE"
            },
            {
                "name": "Bautzen",
                "countryCode": "DE"
            },
            {
                "name": "Beierfeld",
                "countryCode": "DE"
            },
            {
                "name": "Beiersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Beilrode",
                "countryCode": "DE"
            },
            {
                "name": "Belgern",
                "countryCode": "DE"
            },
            {
                "name": "Belgershain",
                "countryCode": "DE"
            },
            {
                "name": "Bennewitz",
                "countryCode": "DE"
            },
            {
                "name": "Bergen",
                "countryCode": "DE"
            },
            {
                "name": "Bernsbach",
                "countryCode": "DE"
            },
            {
                "name": "Bernsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bernstadt",
                "countryCode": "DE"
            },
            {
                "name": "Berthelsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bertsdorf-Hörnitz",
                "countryCode": "DE"
            },
            {
                "name": "Bischofswerda",
                "countryCode": "DE"
            },
            {
                "name": "Bockau",
                "countryCode": "DE"
            },
            {
                "name": "Bockelwitz",
                "countryCode": "DE"
            },
            {
                "name": "Borna",
                "countryCode": "DE"
            },
            {
                "name": "Borsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Borstendorf",
                "countryCode": "DE"
            },
            {
                "name": "Boxberg",
                "countryCode": "DE"
            },
            {
                "name": "Brand-Erbisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Brandis",
                "countryCode": "DE"
            },
            {
                "name": "Breitenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Burgstädt",
                "countryCode": "DE"
            },
            {
                "name": "Burkau",
                "countryCode": "DE"
            },
            {
                "name": "Burkhardtsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Bärenstein",
                "countryCode": "DE"
            },
            {
                "name": "Böhlen",
                "countryCode": "DE"
            },
            {
                "name": "Börnichen",
                "countryCode": "DE"
            },
            {
                "name": "Bösenbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Callenberg",
                "countryCode": "DE"
            },
            {
                "name": "Cavertitz",
                "countryCode": "DE"
            },
            {
                "name": "Chemnitz",
                "countryCode": "DE"
            },
            {
                "name": "Claußnitz",
                "countryCode": "DE"
            },
            {
                "name": "Colditz",
                "countryCode": "DE"
            },
            {
                "name": "Coswig",
                "countryCode": "DE"
            },
            {
                "name": "Crimmitschau",
                "countryCode": "DE"
            },
            {
                "name": "Crostau",
                "countryCode": "DE"
            },
            {
                "name": "Crostwitz",
                "countryCode": "DE"
            },
            {
                "name": "Crottendorf",
                "countryCode": "DE"
            },
            {
                "name": "Cunewalde",
                "countryCode": "DE"
            },
            {
                "name": "Dahlen",
                "countryCode": "DE"
            },
            {
                "name": "Delitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Demitz-Thumitz",
                "countryCode": "DE"
            },
            {
                "name": "Dennheritz",
                "countryCode": "DE"
            },
            {
                "name": "Deutzen",
                "countryCode": "DE"
            },
            {
                "name": "Dippoldiswalde",
                "countryCode": "DE"
            },
            {
                "name": "Doberschütz",
                "countryCode": "DE"
            },
            {
                "name": "Dohma",
                "countryCode": "DE"
            },
            {
                "name": "Dohna",
                "countryCode": "DE"
            },
            {
                "name": "Dommitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Dorfchemnitz",
                "countryCode": "DE"
            },
            {
                "name": "Dorfhain",
                "countryCode": "DE"
            },
            {
                "name": "Drebach",
                "countryCode": "DE"
            },
            {
                "name": "Dresden",
                "countryCode": "DE"
            },
            {
                "name": "Döbeln",
                "countryCode": "DE"
            },
            {
                "name": "Döbernitz",
                "countryCode": "DE"
            },
            {
                "name": "Dürrhennersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dürrröhrsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ebersbach",
                "countryCode": "DE"
            },
            {
                "name": "Ehrenfriedersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Eibau",
                "countryCode": "DE"
            },
            {
                "name": "Eibenstock",
                "countryCode": "DE"
            },
            {
                "name": "Eichigt",
                "countryCode": "DE"
            },
            {
                "name": "Eilenburg",
                "countryCode": "DE"
            },
            {
                "name": "Ellefeld",
                "countryCode": "DE"
            },
            {
                "name": "Elsnig",
                "countryCode": "DE"
            },
            {
                "name": "Elsterberg",
                "countryCode": "DE"
            },
            {
                "name": "Elstertrebnitz",
                "countryCode": "DE"
            },
            {
                "name": "Elstra",
                "countryCode": "DE"
            },
            {
                "name": "Elterlein",
                "countryCode": "DE"
            },
            {
                "name": "Eppendorf",
                "countryCode": "DE"
            },
            {
                "name": "Erlau",
                "countryCode": "DE"
            },
            {
                "name": "Erlbach",
                "countryCode": "DE"
            },
            {
                "name": "Espenhain",
                "countryCode": "DE"
            },
            {
                "name": "Falkenau",
                "countryCode": "DE"
            },
            {
                "name": "Falkenhain",
                "countryCode": "DE"
            },
            {
                "name": "Falkenstein",
                "countryCode": "DE"
            },
            {
                "name": "Flöha",
                "countryCode": "DE"
            },
            {
                "name": "Frankenberg",
                "countryCode": "DE"
            },
            {
                "name": "Frankenstein",
                "countryCode": "DE"
            },
            {
                "name": "Frankenthal",
                "countryCode": "DE"
            },
            {
                "name": "Frauenstein",
                "countryCode": "DE"
            },
            {
                "name": "Fraureuth",
                "countryCode": "DE"
            },
            {
                "name": "Freiberg",
                "countryCode": "DE"
            },
            {
                "name": "Freital",
                "countryCode": "DE"
            },
            {
                "name": "Friedersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Frohburg",
                "countryCode": "DE"
            },
            {
                "name": "Gablenz",
                "countryCode": "DE"
            },
            {
                "name": "Geising",
                "countryCode": "DE"
            },
            {
                "name": "Geithain",
                "countryCode": "DE"
            },
            {
                "name": "Gelenau",
                "countryCode": "DE"
            },
            {
                "name": "Geringswalde",
                "countryCode": "DE"
            },
            {
                "name": "Gersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Geyer",
                "countryCode": "DE"
            },
            {
                "name": "Glashütte",
                "countryCode": "DE"
            },
            {
                "name": "Glaubitz",
                "countryCode": "DE"
            },
            {
                "name": "Glauchau",
                "countryCode": "DE"
            },
            {
                "name": "Gornau",
                "countryCode": "DE"
            },
            {
                "name": "Gornsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Grimma",
                "countryCode": "DE"
            },
            {
                "name": "Groitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Groß Düben",
                "countryCode": "DE"
            },
            {
                "name": "Großbardau",
                "countryCode": "DE"
            },
            {
                "name": "Großbothen",
                "countryCode": "DE"
            },
            {
                "name": "Großdubrau",
                "countryCode": "DE"
            },
            {
                "name": "Großenhain",
                "countryCode": "DE"
            },
            {
                "name": "Großharthau",
                "countryCode": "DE"
            },
            {
                "name": "Großhartmannsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großhennersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großlehna",
                "countryCode": "DE"
            },
            {
                "name": "Großnaundorf",
                "countryCode": "DE"
            },
            {
                "name": "Großolbersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großpostwitz",
                "countryCode": "DE"
            },
            {
                "name": "Großpösna",
                "countryCode": "DE"
            },
            {
                "name": "Großröhrsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Großrückerswalde",
                "countryCode": "DE"
            },
            {
                "name": "Großschirma",
                "countryCode": "DE"
            },
            {
                "name": "Großschweidnitz",
                "countryCode": "DE"
            },
            {
                "name": "Großschönau",
                "countryCode": "DE"
            },
            {
                "name": "Großweitzschen",
                "countryCode": "DE"
            },
            {
                "name": "Grünbach",
                "countryCode": "DE"
            },
            {
                "name": "Grünhain",
                "countryCode": "DE"
            },
            {
                "name": "Grünhainichen",
                "countryCode": "DE"
            },
            {
                "name": "Guttau",
                "countryCode": "DE"
            },
            {
                "name": "Göda",
                "countryCode": "DE"
            },
            {
                "name": "Görlitz",
                "countryCode": "DE"
            },
            {
                "name": "Hainewalde",
                "countryCode": "DE"
            },
            {
                "name": "Hainichen",
                "countryCode": "DE"
            },
            {
                "name": "Halsbrücke",
                "countryCode": "DE"
            },
            {
                "name": "Hammerbrücke",
                "countryCode": "DE"
            },
            {
                "name": "Hartenstein",
                "countryCode": "DE"
            },
            {
                "name": "Hartha",
                "countryCode": "DE"
            },
            {
                "name": "Hartmannsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Haselbachtal",
                "countryCode": "DE"
            },
            {
                "name": "Hauswalde",
                "countryCode": "DE"
            },
            {
                "name": "Heidenau",
                "countryCode": "DE"
            },
            {
                "name": "Hermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herrnhut",
                "countryCode": "DE"
            },
            {
                "name": "Hilbersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hirschfeld",
                "countryCode": "DE"
            },
            {
                "name": "Hirschfelde",
                "countryCode": "DE"
            },
            {
                "name": "Hochkirch",
                "countryCode": "DE"
            },
            {
                "name": "Hohburg",
                "countryCode": "DE"
            },
            {
                "name": "Hohenstein-Ernstthal",
                "countryCode": "DE"
            },
            {
                "name": "Hohndorf",
                "countryCode": "DE"
            },
            {
                "name": "Hohnstein",
                "countryCode": "DE"
            },
            {
                "name": "Horka",
                "countryCode": "DE"
            },
            {
                "name": "Hormersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hoyerswerda",
                "countryCode": "DE"
            },
            {
                "name": "Hähnichen",
                "countryCode": "DE"
            },
            {
                "name": "Höckendorf",
                "countryCode": "DE"
            },
            {
                "name": "Jahnsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Jesewitz",
                "countryCode": "DE"
            },
            {
                "name": "Johanngeorgenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Jöhstadt",
                "countryCode": "DE"
            },
            {
                "name": "Kamenz",
                "countryCode": "DE"
            },
            {
                "name": "Kirchberg",
                "countryCode": "DE"
            },
            {
                "name": "Kirschau",
                "countryCode": "DE"
            },
            {
                "name": "Kitzen",
                "countryCode": "DE"
            },
            {
                "name": "Kitzscher",
                "countryCode": "DE"
            },
            {
                "name": "Klingenthal",
                "countryCode": "DE"
            },
            {
                "name": "Klipphausen",
                "countryCode": "DE"
            },
            {
                "name": "Klitten",
                "countryCode": "DE"
            },
            {
                "name": "Kodersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kossa",
                "countryCode": "DE"
            },
            {
                "name": "Krauschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Kreischa",
                "countryCode": "DE"
            },
            {
                "name": "Kriebstein",
                "countryCode": "DE"
            },
            {
                "name": "Krostitz",
                "countryCode": "DE"
            },
            {
                "name": "Kubschütz",
                "countryCode": "DE"
            },
            {
                "name": "Kurort Gohrisch",
                "countryCode": "DE"
            },
            {
                "name": "Kurort Jonsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kurort Oberwiesenthal",
                "countryCode": "DE"
            },
            {
                "name": "Kurort Oybin",
                "countryCode": "DE"
            },
            {
                "name": "Königsbrück",
                "countryCode": "DE"
            },
            {
                "name": "Königsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Königshain",
                "countryCode": "DE"
            },
            {
                "name": "Königstein",
                "countryCode": "DE"
            },
            {
                "name": "Königswalde",
                "countryCode": "DE"
            },
            {
                "name": "Königswartha",
                "countryCode": "DE"
            },
            {
                "name": "Lampertswalde",
                "countryCode": "DE"
            },
            {
                "name": "Langenbernsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lauter",
                "countryCode": "DE"
            },
            {
                "name": "Laußig",
                "countryCode": "DE"
            },
            {
                "name": "Laußnitz",
                "countryCode": "DE"
            },
            {
                "name": "Lawalde",
                "countryCode": "DE"
            },
            {
                "name": "Leipzig",
                "countryCode": "DE"
            },
            {
                "name": "Leisnig",
                "countryCode": "DE"
            },
            {
                "name": "Lengefeld",
                "countryCode": "DE"
            },
            {
                "name": "Lengenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Leubnitz",
                "countryCode": "DE"
            },
            {
                "name": "Leubsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Leutersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenberg",
                "countryCode": "DE"
            },
            {
                "name": "Lichtenstein",
                "countryCode": "DE"
            },
            {
                "name": "Lichtentanne",
                "countryCode": "DE"
            },
            {
                "name": "Liebstadt",
                "countryCode": "DE"
            },
            {
                "name": "Limbach",
                "countryCode": "DE"
            },
            {
                "name": "Limbach-Oberfrohna",
                "countryCode": "DE"
            },
            {
                "name": "Lobstädt",
                "countryCode": "DE"
            },
            {
                "name": "Lohmen",
                "countryCode": "DE"
            },
            {
                "name": "Lohsa",
                "countryCode": "DE"
            },
            {
                "name": "Lommatzsch",
                "countryCode": "DE"
            },
            {
                "name": "Lugau",
                "countryCode": "DE"
            },
            {
                "name": "Lunzenau",
                "countryCode": "DE"
            },
            {
                "name": "Löbau",
                "countryCode": "DE"
            },
            {
                "name": "Löbnitz",
                "countryCode": "DE"
            },
            {
                "name": "Lößnitz",
                "countryCode": "DE"
            },
            {
                "name": "Machern",
                "countryCode": "DE"
            },
            {
                "name": "Malschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Marienberg",
                "countryCode": "DE"
            },
            {
                "name": "Markersbach",
                "countryCode": "DE"
            },
            {
                "name": "Markersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Markkleeberg",
                "countryCode": "DE"
            },
            {
                "name": "Markneukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Markranstädt",
                "countryCode": "DE"
            },
            {
                "name": "Meerane",
                "countryCode": "DE"
            },
            {
                "name": "Mehltheuer",
                "countryCode": "DE"
            },
            {
                "name": "Meissen",
                "countryCode": "DE"
            },
            {
                "name": "Mildenau",
                "countryCode": "DE"
            },
            {
                "name": "Mittelherwigsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Mittweida",
                "countryCode": "DE"
            },
            {
                "name": "Mochau",
                "countryCode": "DE"
            },
            {
                "name": "Mockrehna",
                "countryCode": "DE"
            },
            {
                "name": "Moritzburg",
                "countryCode": "DE"
            },
            {
                "name": "Mulda",
                "countryCode": "DE"
            },
            {
                "name": "Mutzschen",
                "countryCode": "DE"
            },
            {
                "name": "Mylau",
                "countryCode": "DE"
            },
            {
                "name": "Mücka",
                "countryCode": "DE"
            },
            {
                "name": "Mügeln",
                "countryCode": "DE"
            },
            {
                "name": "Mühlau",
                "countryCode": "DE"
            },
            {
                "name": "Mühltroff",
                "countryCode": "DE"
            },
            {
                "name": "Mülsen",
                "countryCode": "DE"
            },
            {
                "name": "Narsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Naundorf",
                "countryCode": "DE"
            },
            {
                "name": "Naunhof",
                "countryCode": "DE"
            },
            {
                "name": "Nauwalde",
                "countryCode": "DE"
            },
            {
                "name": "Nebelschütz",
                "countryCode": "DE"
            },
            {
                "name": "Nerchau",
                "countryCode": "DE"
            },
            {
                "name": "Neschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Netzschkau",
                "countryCode": "DE"
            },
            {
                "name": "Neuensalz",
                "countryCode": "DE"
            },
            {
                "name": "Neugersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Neuhausen",
                "countryCode": "DE"
            },
            {
                "name": "Neukieritzsch",
                "countryCode": "DE"
            },
            {
                "name": "Neukirch",
                "countryCode": "DE"
            },
            {
                "name": "Neukirch/Lausitz",
                "countryCode": "DE"
            },
            {
                "name": "Neukirchen",
                "countryCode": "DE"
            },
            {
                "name": "Neumark",
                "countryCode": "DE"
            },
            {
                "name": "Neusalza-Spremberg",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt Vogtland",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt in Sachsen",
                "countryCode": "DE"
            },
            {
                "name": "Niederau",
                "countryCode": "DE"
            },
            {
                "name": "Niedercunnersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Niederdorf",
                "countryCode": "DE"
            },
            {
                "name": "Niederfrohna",
                "countryCode": "DE"
            },
            {
                "name": "Niederschöna",
                "countryCode": "DE"
            },
            {
                "name": "Niederstriegis",
                "countryCode": "DE"
            },
            {
                "name": "Niederwiesa",
                "countryCode": "DE"
            },
            {
                "name": "Niesky",
                "countryCode": "DE"
            },
            {
                "name": "Nossen",
                "countryCode": "DE"
            },
            {
                "name": "Nünchritz",
                "countryCode": "DE"
            },
            {
                "name": "Obercunnersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Obergurig",
                "countryCode": "DE"
            },
            {
                "name": "Oberlichtenau",
                "countryCode": "DE"
            },
            {
                "name": "Oberlungwitz",
                "countryCode": "DE"
            },
            {
                "name": "Oberschöna",
                "countryCode": "DE"
            },
            {
                "name": "Oberwiera",
                "countryCode": "DE"
            },
            {
                "name": "Oederan",
                "countryCode": "DE"
            },
            {
                "name": "Oelsnitz",
                "countryCode": "DE"
            },
            {
                "name": "Ohorn",
                "countryCode": "DE"
            },
            {
                "name": "Olbernhau",
                "countryCode": "DE"
            },
            {
                "name": "Olbersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Oppach",
                "countryCode": "DE"
            },
            {
                "name": "Oschatz",
                "countryCode": "DE"
            },
            {
                "name": "Ostrau",
                "countryCode": "DE"
            },
            {
                "name": "Ostritz",
                "countryCode": "DE"
            },
            {
                "name": "Ottendorf-Okrilla",
                "countryCode": "DE"
            },
            {
                "name": "Otterwisch",
                "countryCode": "DE"
            },
            {
                "name": "Oßling",
                "countryCode": "DE"
            },
            {
                "name": "Panschwitz-Kuckau",
                "countryCode": "DE"
            },
            {
                "name": "Pausa",
                "countryCode": "DE"
            },
            {
                "name": "Pegau",
                "countryCode": "DE"
            },
            {
                "name": "Penig",
                "countryCode": "DE"
            },
            {
                "name": "Pfaffroda",
                "countryCode": "DE"
            },
            {
                "name": "Pirna",
                "countryCode": "DE"
            },
            {
                "name": "Plauen",
                "countryCode": "DE"
            },
            {
                "name": "Pobershau",
                "countryCode": "DE"
            },
            {
                "name": "Pockau",
                "countryCode": "DE"
            },
            {
                "name": "Porschdorf",
                "countryCode": "DE"
            },
            {
                "name": "Pretzschendorf",
                "countryCode": "DE"
            },
            {
                "name": "Priestewitz",
                "countryCode": "DE"
            },
            {
                "name": "Puschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Pöhl",
                "countryCode": "DE"
            },
            {
                "name": "Pöhla",
                "countryCode": "DE"
            },
            {
                "name": "Quitzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rabenau",
                "countryCode": "DE"
            },
            {
                "name": "Rackwitz",
                "countryCode": "DE"
            },
            {
                "name": "Radeberg",
                "countryCode": "DE"
            },
            {
                "name": "Radebeul",
                "countryCode": "DE"
            },
            {
                "name": "Radeburg",
                "countryCode": "DE"
            },
            {
                "name": "Radibor",
                "countryCode": "DE"
            },
            {
                "name": "Rammenau",
                "countryCode": "DE"
            },
            {
                "name": "Raschau",
                "countryCode": "DE"
            },
            {
                "name": "Rathmannsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Rechenberg-Bienenmühle",
                "countryCode": "DE"
            },
            {
                "name": "Regis-Breitingen",
                "countryCode": "DE"
            },
            {
                "name": "Reichenbach",
                "countryCode": "DE"
            },
            {
                "name": "Reichenbach/Vogtland",
                "countryCode": "DE"
            },
            {
                "name": "Reinhardtsgrimma",
                "countryCode": "DE"
            },
            {
                "name": "Reinsberg",
                "countryCode": "DE"
            },
            {
                "name": "Reinsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Remse",
                "countryCode": "DE"
            },
            {
                "name": "Reuth",
                "countryCode": "DE"
            },
            {
                "name": "Riesa",
                "countryCode": "DE"
            },
            {
                "name": "Rietschen",
                "countryCode": "DE"
            },
            {
                "name": "Rittersgrün",
                "countryCode": "DE"
            },
            {
                "name": "Rochlitz",
                "countryCode": "DE"
            },
            {
                "name": "Rodewisch",
                "countryCode": "DE"
            },
            {
                "name": "Rossau",
                "countryCode": "DE"
            },
            {
                "name": "Rothenburg",
                "countryCode": "DE"
            },
            {
                "name": "Roßwein",
                "countryCode": "DE"
            },
            {
                "name": "Räckelwitz",
                "countryCode": "DE"
            },
            {
                "name": "Rötha",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Egidien",
                "countryCode": "DE"
            },
            {
                "name": "Sayda",
                "countryCode": "DE"
            },
            {
                "name": "Scharfenstein",
                "countryCode": "DE"
            },
            {
                "name": "Scheibenberg",
                "countryCode": "DE"
            },
            {
                "name": "Schildau",
                "countryCode": "DE"
            },
            {
                "name": "Schirgiswalde",
                "countryCode": "DE"
            },
            {
                "name": "Schkeuditz",
                "countryCode": "DE"
            },
            {
                "name": "Schlegel",
                "countryCode": "DE"
            },
            {
                "name": "Schleife",
                "countryCode": "DE"
            },
            {
                "name": "Schlettau",
                "countryCode": "DE"
            },
            {
                "name": "Schmiedeberg",
                "countryCode": "DE"
            },
            {
                "name": "Schneeberg",
                "countryCode": "DE"
            },
            {
                "name": "Schwarzenberg",
                "countryCode": "DE"
            },
            {
                "name": "Schwepnitz",
                "countryCode": "DE"
            },
            {
                "name": "Schönau-Berzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Schönbach",
                "countryCode": "DE"
            },
            {
                "name": "Schönberg",
                "countryCode": "DE"
            },
            {
                "name": "Schöneck",
                "countryCode": "DE"
            },
            {
                "name": "Schönfeld",
                "countryCode": "DE"
            },
            {
                "name": "Schönheide",
                "countryCode": "DE"
            },
            {
                "name": "Sebnitz",
                "countryCode": "DE"
            },
            {
                "name": "Seelitz",
                "countryCode": "DE"
            },
            {
                "name": "Seiffen",
                "countryCode": "DE"
            },
            {
                "name": "Seifhennersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sohland",
                "countryCode": "DE"
            },
            {
                "name": "Sohland am Rotstein",
                "countryCode": "DE"
            },
            {
                "name": "Sosa",
                "countryCode": "DE"
            },
            {
                "name": "Stadt Wehlen",
                "countryCode": "DE"
            },
            {
                "name": "Stauchitz",
                "countryCode": "DE"
            },
            {
                "name": "Steina",
                "countryCode": "DE"
            },
            {
                "name": "Steinigtwolmsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Stollberg",
                "countryCode": "DE"
            },
            {
                "name": "Stolpen",
                "countryCode": "DE"
            },
            {
                "name": "Strehla",
                "countryCode": "DE"
            },
            {
                "name": "Struppen",
                "countryCode": "DE"
            },
            {
                "name": "Stützengrün",
                "countryCode": "DE"
            },
            {
                "name": "Syrau",
                "countryCode": "DE"
            },
            {
                "name": "Tannenberg",
                "countryCode": "DE"
            },
            {
                "name": "Tannenbergsthal",
                "countryCode": "DE"
            },
            {
                "name": "Taucha",
                "countryCode": "DE"
            },
            {
                "name": "Taura",
                "countryCode": "DE"
            },
            {
                "name": "Tauscha",
                "countryCode": "DE"
            },
            {
                "name": "Thalheim",
                "countryCode": "DE"
            },
            {
                "name": "Thallwitz",
                "countryCode": "DE"
            },
            {
                "name": "Tharandt",
                "countryCode": "DE"
            },
            {
                "name": "Theuma",
                "countryCode": "DE"
            },
            {
                "name": "Thiendorf",
                "countryCode": "DE"
            },
            {
                "name": "Thum",
                "countryCode": "DE"
            },
            {
                "name": "Tirpersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Torgau",
                "countryCode": "DE"
            },
            {
                "name": "Trebendorf",
                "countryCode": "DE"
            },
            {
                "name": "Trebsen",
                "countryCode": "DE"
            },
            {
                "name": "Treuen",
                "countryCode": "DE"
            },
            {
                "name": "Triebel",
                "countryCode": "DE"
            },
            {
                "name": "Trossin",
                "countryCode": "DE"
            },
            {
                "name": "Uhyst",
                "countryCode": "DE"
            },
            {
                "name": "Venusberg",
                "countryCode": "DE"
            },
            {
                "name": "Wachau",
                "countryCode": "DE"
            },
            {
                "name": "Waldenburg",
                "countryCode": "DE"
            },
            {
                "name": "Waldheim",
                "countryCode": "DE"
            },
            {
                "name": "Waldkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Wechselburg",
                "countryCode": "DE"
            },
            {
                "name": "Weinböhla",
                "countryCode": "DE"
            },
            {
                "name": "Weischlitz",
                "countryCode": "DE"
            },
            {
                "name": "Weißenberg",
                "countryCode": "DE"
            },
            {
                "name": "Weißenborn",
                "countryCode": "DE"
            },
            {
                "name": "Weißig",
                "countryCode": "DE"
            },
            {
                "name": "Weißkeißel",
                "countryCode": "DE"
            },
            {
                "name": "Weißwasser",
                "countryCode": "DE"
            },
            {
                "name": "Werda",
                "countryCode": "DE"
            },
            {
                "name": "Werdau",
                "countryCode": "DE"
            },
            {
                "name": "Wermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wiedemar",
                "countryCode": "DE"
            },
            {
                "name": "Wiednitz",
                "countryCode": "DE"
            },
            {
                "name": "Wiesa",
                "countryCode": "DE"
            },
            {
                "name": "Wildenfels",
                "countryCode": "DE"
            },
            {
                "name": "Wildenhain",
                "countryCode": "DE"
            },
            {
                "name": "Wilkau-Haßlau",
                "countryCode": "DE"
            },
            {
                "name": "Wilsdruff",
                "countryCode": "DE"
            },
            {
                "name": "Wilthen",
                "countryCode": "DE"
            },
            {
                "name": "Wittgensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wittichenau",
                "countryCode": "DE"
            },
            {
                "name": "Wolkenstein",
                "countryCode": "DE"
            },
            {
                "name": "Wurzen",
                "countryCode": "DE"
            },
            {
                "name": "Wülknitz",
                "countryCode": "DE"
            },
            {
                "name": "Zabeltitz",
                "countryCode": "DE"
            },
            {
                "name": "Zeithain",
                "countryCode": "DE"
            },
            {
                "name": "Zinna",
                "countryCode": "DE"
            },
            {
                "name": "Zittau",
                "countryCode": "DE"
            },
            {
                "name": "Zschadrass",
                "countryCode": "DE"
            },
            {
                "name": "Zschepplin",
                "countryCode": "DE"
            },
            {
                "name": "Zschopau",
                "countryCode": "DE"
            },
            {
                "name": "Zschorlau",
                "countryCode": "DE"
            },
            {
                "name": "Zschortau",
                "countryCode": "DE"
            },
            {
                "name": "Zwenkau",
                "countryCode": "DE"
            },
            {
                "name": "Zwickau",
                "countryCode": "DE"
            },
            {
                "name": "Zwochau",
                "countryCode": "DE"
            },
            {
                "name": "Zwota",
                "countryCode": "DE"
            },
            {
                "name": "Zwönitz",
                "countryCode": "DE"
            },
            {
                "name": "Zöblitz",
                "countryCode": "DE"
            },
            {
                "name": "Abtsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Ahlsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Aken",
                "countryCode": "DE"
            },
            {
                "name": "Allstedt",
                "countryCode": "DE"
            },
            {
                "name": "Alsleben",
                "countryCode": "DE"
            },
            {
                "name": "Angern",
                "countryCode": "DE"
            },
            {
                "name": "Angersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Annaburg",
                "countryCode": "DE"
            },
            {
                "name": "Apollensdorf",
                "countryCode": "DE"
            },
            {
                "name": "Arneburg",
                "countryCode": "DE"
            },
            {
                "name": "Aschersleben",
                "countryCode": "DE"
            },
            {
                "name": "Atzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Ausleben",
                "countryCode": "DE"
            },
            {
                "name": "Baalberge",
                "countryCode": "DE"
            },
            {
                "name": "Bad Bibra",
                "countryCode": "DE"
            },
            {
                "name": "Bad Dürrenberg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Kösen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Lauchstädt",
                "countryCode": "DE"
            },
            {
                "name": "Bad Schmiedeberg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Suderode",
                "countryCode": "DE"
            },
            {
                "name": "Ballenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Barby",
                "countryCode": "DE"
            },
            {
                "name": "Barleben",
                "countryCode": "DE"
            },
            {
                "name": "Barnstädt",
                "countryCode": "DE"
            },
            {
                "name": "Beesenlaublingen",
                "countryCode": "DE"
            },
            {
                "name": "Beesenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Beetzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Belleben",
                "countryCode": "DE"
            },
            {
                "name": "Benndorf",
                "countryCode": "DE"
            },
            {
                "name": "Benneckenstein",
                "countryCode": "DE"
            },
            {
                "name": "Bennstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bennungen",
                "countryCode": "DE"
            },
            {
                "name": "Berga",
                "countryCode": "DE"
            },
            {
                "name": "Bergwitz",
                "countryCode": "DE"
            },
            {
                "name": "Bernburg",
                "countryCode": "DE"
            },
            {
                "name": "Beuna",
                "countryCode": "DE"
            },
            {
                "name": "Biederitz",
                "countryCode": "DE"
            },
            {
                "name": "Biere",
                "countryCode": "DE"
            },
            {
                "name": "Bismark",
                "countryCode": "DE"
            },
            {
                "name": "Bitterfeld-Wolfen",
                "countryCode": "DE"
            },
            {
                "name": "Blankenburg",
                "countryCode": "DE"
            },
            {
                "name": "Blankenheim",
                "countryCode": "DE"
            },
            {
                "name": "Bobbau",
                "countryCode": "DE"
            },
            {
                "name": "Borne",
                "countryCode": "DE"
            },
            {
                "name": "Braschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Braunsbedra",
                "countryCode": "DE"
            },
            {
                "name": "Brehna",
                "countryCode": "DE"
            },
            {
                "name": "Brumby",
                "countryCode": "DE"
            },
            {
                "name": "Burg bei Magdeburg",
                "countryCode": "DE"
            },
            {
                "name": "Burgwerben",
                "countryCode": "DE"
            },
            {
                "name": "Calbe",
                "countryCode": "DE"
            },
            {
                "name": "Calvörde",
                "countryCode": "DE"
            },
            {
                "name": "Cochstedt",
                "countryCode": "DE"
            },
            {
                "name": "Colbitz",
                "countryCode": "DE"
            },
            {
                "name": "Coswig",
                "countryCode": "DE"
            },
            {
                "name": "Dahlenwarsleben",
                "countryCode": "DE"
            },
            {
                "name": "Darlingerode",
                "countryCode": "DE"
            },
            {
                "name": "Derenburg",
                "countryCode": "DE"
            },
            {
                "name": "Dessau",
                "countryCode": "DE"
            },
            {
                "name": "Deuben",
                "countryCode": "DE"
            },
            {
                "name": "Diesdorf",
                "countryCode": "DE"
            },
            {
                "name": "Dieskau",
                "countryCode": "DE"
            },
            {
                "name": "Ditfurt",
                "countryCode": "DE"
            },
            {
                "name": "Dobien",
                "countryCode": "DE"
            },
            {
                "name": "Domersleben",
                "countryCode": "DE"
            },
            {
                "name": "Droyßig",
                "countryCode": "DE"
            },
            {
                "name": "Drübeck",
                "countryCode": "DE"
            },
            {
                "name": "Dölbau",
                "countryCode": "DE"
            },
            {
                "name": "Döllnitz",
                "countryCode": "DE"
            },
            {
                "name": "Ebendorf",
                "countryCode": "DE"
            },
            {
                "name": "Eckartsberga",
                "countryCode": "DE"
            },
            {
                "name": "Edderitz",
                "countryCode": "DE"
            },
            {
                "name": "Edersleben",
                "countryCode": "DE"
            },
            {
                "name": "Egeln",
                "countryCode": "DE"
            },
            {
                "name": "Eggersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Eichenbarleben",
                "countryCode": "DE"
            },
            {
                "name": "Eickendorf",
                "countryCode": "DE"
            },
            {
                "name": "Eilsleben",
                "countryCode": "DE"
            },
            {
                "name": "Eisleben Lutherstadt",
                "countryCode": "DE"
            },
            {
                "name": "Elbingerode",
                "countryCode": "DE"
            },
            {
                "name": "Elster",
                "countryCode": "DE"
            },
            {
                "name": "Erdeborn",
                "countryCode": "DE"
            },
            {
                "name": "Ermlitz",
                "countryCode": "DE"
            },
            {
                "name": "Erxleben",
                "countryCode": "DE"
            },
            {
                "name": "Farnstädt",
                "countryCode": "DE"
            },
            {
                "name": "Flechtingen",
                "countryCode": "DE"
            },
            {
                "name": "Flessau",
                "countryCode": "DE"
            },
            {
                "name": "Frankleben",
                "countryCode": "DE"
            },
            {
                "name": "Freyburg",
                "countryCode": "DE"
            },
            {
                "name": "Friedersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichsbrunn",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichstadt",
                "countryCode": "DE"
            },
            {
                "name": "Frose",
                "countryCode": "DE"
            },
            {
                "name": "Förderstedt",
                "countryCode": "DE"
            },
            {
                "name": "Gardelegen",
                "countryCode": "DE"
            },
            {
                "name": "Gatersleben",
                "countryCode": "DE"
            },
            {
                "name": "Genthin",
                "countryCode": "DE"
            },
            {
                "name": "Gerbstedt",
                "countryCode": "DE"
            },
            {
                "name": "Gernrode",
                "countryCode": "DE"
            },
            {
                "name": "Gerwisch",
                "countryCode": "DE"
            },
            {
                "name": "Geusa",
                "countryCode": "DE"
            },
            {
                "name": "Giersleben",
                "countryCode": "DE"
            },
            {
                "name": "Glindenberg",
                "countryCode": "DE"
            },
            {
                "name": "Glöthe",
                "countryCode": "DE"
            },
            {
                "name": "Goldbeck",
                "countryCode": "DE"
            },
            {
                "name": "Gommern",
                "countryCode": "DE"
            },
            {
                "name": "Goseck",
                "countryCode": "DE"
            },
            {
                "name": "Granschütz",
                "countryCode": "DE"
            },
            {
                "name": "Greppin",
                "countryCode": "DE"
            },
            {
                "name": "Groß Ammensleben",
                "countryCode": "DE"
            },
            {
                "name": "Groß Börnecke",
                "countryCode": "DE"
            },
            {
                "name": "Groß Quenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Groß Rodensleben",
                "countryCode": "DE"
            },
            {
                "name": "Groß Rosenburg",
                "countryCode": "DE"
            },
            {
                "name": "Groß Santersleben",
                "countryCode": "DE"
            },
            {
                "name": "Großkayna",
                "countryCode": "DE"
            },
            {
                "name": "Großkorbetha",
                "countryCode": "DE"
            },
            {
                "name": "Großkugel",
                "countryCode": "DE"
            },
            {
                "name": "Großörner",
                "countryCode": "DE"
            },
            {
                "name": "Gräfenhainichen",
                "countryCode": "DE"
            },
            {
                "name": "Gröbers",
                "countryCode": "DE"
            },
            {
                "name": "Gröbzig",
                "countryCode": "DE"
            },
            {
                "name": "Gröningen",
                "countryCode": "DE"
            },
            {
                "name": "Gutenberg",
                "countryCode": "DE"
            },
            {
                "name": "Görzig",
                "countryCode": "DE"
            },
            {
                "name": "Günthersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Güsten",
                "countryCode": "DE"
            },
            {
                "name": "Hadmersleben",
                "countryCode": "DE"
            },
            {
                "name": "Halberstadt",
                "countryCode": "DE"
            },
            {
                "name": "Haldensleben I",
                "countryCode": "DE"
            },
            {
                "name": "Halle (Saale)",
                "countryCode": "DE"
            },
            {
                "name": "Halle Neustadt",
                "countryCode": "DE"
            },
            {
                "name": "Harbke",
                "countryCode": "DE"
            },
            {
                "name": "Harsleben",
                "countryCode": "DE"
            },
            {
                "name": "Harzgerode",
                "countryCode": "DE"
            },
            {
                "name": "Hasselfelde",
                "countryCode": "DE"
            },
            {
                "name": "Havelberg",
                "countryCode": "DE"
            },
            {
                "name": "Hecklingen",
                "countryCode": "DE"
            },
            {
                "name": "Hedersleben",
                "countryCode": "DE"
            },
            {
                "name": "Helbra",
                "countryCode": "DE"
            },
            {
                "name": "Hergisdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Hettstedt",
                "countryCode": "DE"
            },
            {
                "name": "Heudeber",
                "countryCode": "DE"
            },
            {
                "name": "Hohendodeleben",
                "countryCode": "DE"
            },
            {
                "name": "Hohenmölsen",
                "countryCode": "DE"
            },
            {
                "name": "Hohenthurm",
                "countryCode": "DE"
            },
            {
                "name": "Hohenwarsleben",
                "countryCode": "DE"
            },
            {
                "name": "Hohenwarthe",
                "countryCode": "DE"
            },
            {
                "name": "Holleben",
                "countryCode": "DE"
            },
            {
                "name": "Holzdorf",
                "countryCode": "DE"
            },
            {
                "name": "Holzweißig",
                "countryCode": "DE"
            },
            {
                "name": "Hornhausen",
                "countryCode": "DE"
            },
            {
                "name": "Hoym",
                "countryCode": "DE"
            },
            {
                "name": "Huy-Neinstedt",
                "countryCode": "DE"
            },
            {
                "name": "Höhnstedt",
                "countryCode": "DE"
            },
            {
                "name": "Hötensleben",
                "countryCode": "DE"
            },
            {
                "name": "Hüttenrode",
                "countryCode": "DE"
            },
            {
                "name": "Ilberstedt",
                "countryCode": "DE"
            },
            {
                "name": "Ilsenburg",
                "countryCode": "DE"
            },
            {
                "name": "Irxleben",
                "countryCode": "DE"
            },
            {
                "name": "Jerichow",
                "countryCode": "DE"
            },
            {
                "name": "Jessen",
                "countryCode": "DE"
            },
            {
                "name": "Jeßnitz",
                "countryCode": "DE"
            },
            {
                "name": "Jävenitz",
                "countryCode": "DE"
            },
            {
                "name": "Kalbe",
                "countryCode": "DE"
            },
            {
                "name": "Karsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kayna",
                "countryCode": "DE"
            },
            {
                "name": "Kelbra",
                "countryCode": "DE"
            },
            {
                "name": "Kemberg",
                "countryCode": "DE"
            },
            {
                "name": "Klein Wanzleben",
                "countryCode": "DE"
            },
            {
                "name": "Klieken",
                "countryCode": "DE"
            },
            {
                "name": "Klietz",
                "countryCode": "DE"
            },
            {
                "name": "Klostermansfeld",
                "countryCode": "DE"
            },
            {
                "name": "Klötze",
                "countryCode": "DE"
            },
            {
                "name": "Kretzschau",
                "countryCode": "DE"
            },
            {
                "name": "Kroppenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Kropstädt",
                "countryCode": "DE"
            },
            {
                "name": "Krumpa",
                "countryCode": "DE"
            },
            {
                "name": "Kusey",
                "countryCode": "DE"
            },
            {
                "name": "Könnern",
                "countryCode": "DE"
            },
            {
                "name": "Köthen",
                "countryCode": "DE"
            },
            {
                "name": "Kötzschau",
                "countryCode": "DE"
            },
            {
                "name": "Landsberg",
                "countryCode": "DE"
            },
            {
                "name": "Langeln",
                "countryCode": "DE"
            },
            {
                "name": "Langenbogen",
                "countryCode": "DE"
            },
            {
                "name": "Langendorf",
                "countryCode": "DE"
            },
            {
                "name": "Langeneichstädt",
                "countryCode": "DE"
            },
            {
                "name": "Langenstein",
                "countryCode": "DE"
            },
            {
                "name": "Laucha",
                "countryCode": "DE"
            },
            {
                "name": "Leitzkau",
                "countryCode": "DE"
            },
            {
                "name": "Leißling",
                "countryCode": "DE"
            },
            {
                "name": "Letzlingen",
                "countryCode": "DE"
            },
            {
                "name": "Leuna",
                "countryCode": "DE"
            },
            {
                "name": "Lieskau",
                "countryCode": "DE"
            },
            {
                "name": "Lindau",
                "countryCode": "DE"
            },
            {
                "name": "Loburg",
                "countryCode": "DE"
            },
            {
                "name": "Lochau",
                "countryCode": "DE"
            },
            {
                "name": "Lostau",
                "countryCode": "DE"
            },
            {
                "name": "Luftkurort Arendsee",
                "countryCode": "DE"
            },
            {
                "name": "Löbejün",
                "countryCode": "DE"
            },
            {
                "name": "Löberitz",
                "countryCode": "DE"
            },
            {
                "name": "Lüderitz",
                "countryCode": "DE"
            },
            {
                "name": "Lützen",
                "countryCode": "DE"
            },
            {
                "name": "Magdeburg",
                "countryCode": "DE"
            },
            {
                "name": "Mansfeld",
                "countryCode": "DE"
            },
            {
                "name": "Mehringen",
                "countryCode": "DE"
            },
            {
                "name": "Meitzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Merseburg",
                "countryCode": "DE"
            },
            {
                "name": "Mieste",
                "countryCode": "DE"
            },
            {
                "name": "Muldenstein",
                "countryCode": "DE"
            },
            {
                "name": "Muschwitz",
                "countryCode": "DE"
            },
            {
                "name": "Möckern",
                "countryCode": "DE"
            },
            {
                "name": "Möhlau",
                "countryCode": "DE"
            },
            {
                "name": "Möser",
                "countryCode": "DE"
            },
            {
                "name": "Mücheln",
                "countryCode": "DE"
            },
            {
                "name": "Mühlanger",
                "countryCode": "DE"
            },
            {
                "name": "Nachterstedt",
                "countryCode": "DE"
            },
            {
                "name": "Nauendorf",
                "countryCode": "DE"
            },
            {
                "name": "Naumburg",
                "countryCode": "DE"
            },
            {
                "name": "Nebra",
                "countryCode": "DE"
            },
            {
                "name": "Neinstedt",
                "countryCode": "DE"
            },
            {
                "name": "Nessa",
                "countryCode": "DE"
            },
            {
                "name": "Neue Neustadt",
                "countryCode": "DE"
            },
            {
                "name": "Neundorf",
                "countryCode": "DE"
            },
            {
                "name": "Niederndodeleben",
                "countryCode": "DE"
            },
            {
                "name": "Niemberg",
                "countryCode": "DE"
            },
            {
                "name": "Nienburg/Saale",
                "countryCode": "DE"
            },
            {
                "name": "Nudersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Oberröblingen",
                "countryCode": "DE"
            },
            {
                "name": "Obhausen",
                "countryCode": "DE"
            },
            {
                "name": "Oebisfelde",
                "countryCode": "DE"
            },
            {
                "name": "Oppin",
                "countryCode": "DE"
            },
            {
                "name": "Oranienbaum",
                "countryCode": "DE"
            },
            {
                "name": "Oschersleben",
                "countryCode": "DE"
            },
            {
                "name": "Osterburg",
                "countryCode": "DE"
            },
            {
                "name": "Osterfeld",
                "countryCode": "DE"
            },
            {
                "name": "Osterhausen",
                "countryCode": "DE"
            },
            {
                "name": "Osternienburg",
                "countryCode": "DE"
            },
            {
                "name": "Osternienburger Land",
                "countryCode": "DE"
            },
            {
                "name": "Osterwieck",
                "countryCode": "DE"
            },
            {
                "name": "Peißen",
                "countryCode": "DE"
            },
            {
                "name": "Piesteritz",
                "countryCode": "DE"
            },
            {
                "name": "Plötzkau",
                "countryCode": "DE"
            },
            {
                "name": "Plötzky",
                "countryCode": "DE"
            },
            {
                "name": "Polleben",
                "countryCode": "DE"
            },
            {
                "name": "Pouch",
                "countryCode": "DE"
            },
            {
                "name": "Pratau",
                "countryCode": "DE"
            },
            {
                "name": "Prettin",
                "countryCode": "DE"
            },
            {
                "name": "Pretzier",
                "countryCode": "DE"
            },
            {
                "name": "Pretzsch",
                "countryCode": "DE"
            },
            {
                "name": "Prittitz",
                "countryCode": "DE"
            },
            {
                "name": "Quedlinburg",
                "countryCode": "DE"
            },
            {
                "name": "Queis",
                "countryCode": "DE"
            },
            {
                "name": "Quellendorf",
                "countryCode": "DE"
            },
            {
                "name": "Querfurt",
                "countryCode": "DE"
            },
            {
                "name": "Radegast",
                "countryCode": "DE"
            },
            {
                "name": "Radis",
                "countryCode": "DE"
            },
            {
                "name": "Raguhn",
                "countryCode": "DE"
            },
            {
                "name": "Ramsin",
                "countryCode": "DE"
            },
            {
                "name": "Raßnitz",
                "countryCode": "DE"
            },
            {
                "name": "Reichardtswerben",
                "countryCode": "DE"
            },
            {
                "name": "Reußen",
                "countryCode": "DE"
            },
            {
                "name": "Rieder",
                "countryCode": "DE"
            },
            {
                "name": "Riestedt",
                "countryCode": "DE"
            },
            {
                "name": "Rodleben",
                "countryCode": "DE"
            },
            {
                "name": "Rogätz",
                "countryCode": "DE"
            },
            {
                "name": "Roitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Rottleberode",
                "countryCode": "DE"
            },
            {
                "name": "Roßla",
                "countryCode": "DE"
            },
            {
                "name": "Roßlau",
                "countryCode": "DE"
            },
            {
                "name": "Röblingen am See",
                "countryCode": "DE"
            },
            {
                "name": "Rübeland",
                "countryCode": "DE"
            },
            {
                "name": "Salzmünde",
                "countryCode": "DE"
            },
            {
                "name": "Salzwedel",
                "countryCode": "DE"
            },
            {
                "name": "Samswegen",
                "countryCode": "DE"
            },
            {
                "name": "Sandau",
                "countryCode": "DE"
            },
            {
                "name": "Sandersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Sandersleben",
                "countryCode": "DE"
            },
            {
                "name": "Sangerhausen",
                "countryCode": "DE"
            },
            {
                "name": "Schafstädt",
                "countryCode": "DE"
            },
            {
                "name": "Schermen",
                "countryCode": "DE"
            },
            {
                "name": "Schkopau",
                "countryCode": "DE"
            },
            {
                "name": "Schlaitz",
                "countryCode": "DE"
            },
            {
                "name": "Schneidlingen",
                "countryCode": "DE"
            },
            {
                "name": "Schochwitz",
                "countryCode": "DE"
            },
            {
                "name": "Schraplau",
                "countryCode": "DE"
            },
            {
                "name": "Schwanebeck",
                "countryCode": "DE"
            },
            {
                "name": "Schönburg",
                "countryCode": "DE"
            },
            {
                "name": "Schönebeck",
                "countryCode": "DE"
            },
            {
                "name": "Schönhausen",
                "countryCode": "DE"
            },
            {
                "name": "Seehausen",
                "countryCode": "DE"
            },
            {
                "name": "Sennewitz",
                "countryCode": "DE"
            },
            {
                "name": "Seyda",
                "countryCode": "DE"
            },
            {
                "name": "Siersleben",
                "countryCode": "DE"
            },
            {
                "name": "Sommersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Spergau",
                "countryCode": "DE"
            },
            {
                "name": "Stapelburg",
                "countryCode": "DE"
            },
            {
                "name": "Staßfurt",
                "countryCode": "DE"
            },
            {
                "name": "Stedten",
                "countryCode": "DE"
            },
            {
                "name": "Stendal",
                "countryCode": "DE"
            },
            {
                "name": "Stolberg",
                "countryCode": "DE"
            },
            {
                "name": "Ströbeck",
                "countryCode": "DE"
            },
            {
                "name": "Stößen",
                "countryCode": "DE"
            },
            {
                "name": "Süplingen",
                "countryCode": "DE"
            },
            {
                "name": "Tangerhütte",
                "countryCode": "DE"
            },
            {
                "name": "Tangermünde",
                "countryCode": "DE"
            },
            {
                "name": "Teicha",
                "countryCode": "DE"
            },
            {
                "name": "Teuchel",
                "countryCode": "DE"
            },
            {
                "name": "Teuchern",
                "countryCode": "DE"
            },
            {
                "name": "Teutschenthal",
                "countryCode": "DE"
            },
            {
                "name": "Thale",
                "countryCode": "DE"
            },
            {
                "name": "Thalheim",
                "countryCode": "DE"
            },
            {
                "name": "Theißen",
                "countryCode": "DE"
            },
            {
                "name": "Timmenrode",
                "countryCode": "DE"
            },
            {
                "name": "Tollwitz",
                "countryCode": "DE"
            },
            {
                "name": "Trebitz",
                "countryCode": "DE"
            },
            {
                "name": "Tucheim",
                "countryCode": "DE"
            },
            {
                "name": "Uchtspringe",
                "countryCode": "DE"
            },
            {
                "name": "Uenglingen",
                "countryCode": "DE"
            },
            {
                "name": "Uftrungen",
                "countryCode": "DE"
            },
            {
                "name": "Uichteritz",
                "countryCode": "DE"
            },
            {
                "name": "Ummendorf",
                "countryCode": "DE"
            },
            {
                "name": "Unseburg",
                "countryCode": "DE"
            },
            {
                "name": "Veckenstedt",
                "countryCode": "DE"
            },
            {
                "name": "Vockerode",
                "countryCode": "DE"
            },
            {
                "name": "Volkstedt",
                "countryCode": "DE"
            },
            {
                "name": "Völpke",
                "countryCode": "DE"
            },
            {
                "name": "Wahlitz",
                "countryCode": "DE"
            },
            {
                "name": "Wallendorf",
                "countryCode": "DE"
            },
            {
                "name": "Wallhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wallwitz",
                "countryCode": "DE"
            },
            {
                "name": "Wansleben",
                "countryCode": "DE"
            },
            {
                "name": "Wanzleben",
                "countryCode": "DE"
            },
            {
                "name": "Wasserleben",
                "countryCode": "DE"
            },
            {
                "name": "Weddersleben",
                "countryCode": "DE"
            },
            {
                "name": "Wefensleben",
                "countryCode": "DE"
            },
            {
                "name": "Weferlingen",
                "countryCode": "DE"
            },
            {
                "name": "Wegeleben",
                "countryCode": "DE"
            },
            {
                "name": "Weißandt-Gölzau",
                "countryCode": "DE"
            },
            {
                "name": "Weißenfels",
                "countryCode": "DE"
            },
            {
                "name": "Wellen",
                "countryCode": "DE"
            },
            {
                "name": "Welsleben",
                "countryCode": "DE"
            },
            {
                "name": "Wernigerode",
                "countryCode": "DE"
            },
            {
                "name": "Westeregeln",
                "countryCode": "DE"
            },
            {
                "name": "Westerhausen",
                "countryCode": "DE"
            },
            {
                "name": "Wetterzeube",
                "countryCode": "DE"
            },
            {
                "name": "Wettin",
                "countryCode": "DE"
            },
            {
                "name": "Wiederstedt",
                "countryCode": "DE"
            },
            {
                "name": "Wimmelburg",
                "countryCode": "DE"
            },
            {
                "name": "Wippra",
                "countryCode": "DE"
            },
            {
                "name": "Wittenberg",
                "countryCode": "DE"
            },
            {
                "name": "Wolfen",
                "countryCode": "DE"
            },
            {
                "name": "Wolferode",
                "countryCode": "DE"
            },
            {
                "name": "Wolmirsleben",
                "countryCode": "DE"
            },
            {
                "name": "Wolmirstedt",
                "countryCode": "DE"
            },
            {
                "name": "Wulfen",
                "countryCode": "DE"
            },
            {
                "name": "Wörlitz",
                "countryCode": "DE"
            },
            {
                "name": "Zahna",
                "countryCode": "DE"
            },
            {
                "name": "Zappendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zeitz",
                "countryCode": "DE"
            },
            {
                "name": "Zerbst",
                "countryCode": "DE"
            },
            {
                "name": "Zielitz",
                "countryCode": "DE"
            },
            {
                "name": "Zscherben",
                "countryCode": "DE"
            },
            {
                "name": "Zscherndorf",
                "countryCode": "DE"
            },
            {
                "name": "Zschornewitz",
                "countryCode": "DE"
            },
            {
                "name": "Zörbig",
                "countryCode": "DE"
            },
            {
                "name": "Zöschen",
                "countryCode": "DE"
            },
            {
                "name": "Altenburg",
                "countryCode": "DE"
            },
            {
                "name": "Altenfeld",
                "countryCode": "DE"
            },
            {
                "name": "Altengottern",
                "countryCode": "DE"
            },
            {
                "name": "Altkirchen",
                "countryCode": "DE"
            },
            {
                "name": "Anrode",
                "countryCode": "DE"
            },
            {
                "name": "Apfelstädt",
                "countryCode": "DE"
            },
            {
                "name": "Apolda",
                "countryCode": "DE"
            },
            {
                "name": "Arenshausen",
                "countryCode": "DE"
            },
            {
                "name": "Arnstadt",
                "countryCode": "DE"
            },
            {
                "name": "Artern",
                "countryCode": "DE"
            },
            {
                "name": "Auleben",
                "countryCode": "DE"
            },
            {
                "name": "Auma",
                "countryCode": "DE"
            },
            {
                "name": "Bad Berka",
                "countryCode": "DE"
            },
            {
                "name": "Bad Blankenburg",
                "countryCode": "DE"
            },
            {
                "name": "Bad Frankenhausen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Klosterlausnitz",
                "countryCode": "DE"
            },
            {
                "name": "Bad Köstritz",
                "countryCode": "DE"
            },
            {
                "name": "Bad Langensalza",
                "countryCode": "DE"
            },
            {
                "name": "Bad Liebenstein",
                "countryCode": "DE"
            },
            {
                "name": "Bad Lobenstein",
                "countryCode": "DE"
            },
            {
                "name": "Bad Salzungen",
                "countryCode": "DE"
            },
            {
                "name": "Bad Sulza",
                "countryCode": "DE"
            },
            {
                "name": "Bad Tennstedt",
                "countryCode": "DE"
            },
            {
                "name": "Barchfeld",
                "countryCode": "DE"
            },
            {
                "name": "Behringen",
                "countryCode": "DE"
            },
            {
                "name": "Benshausen",
                "countryCode": "DE"
            },
            {
                "name": "Berga",
                "countryCode": "DE"
            },
            {
                "name": "Berlingerode",
                "countryCode": "DE"
            },
            {
                "name": "Berlstedt",
                "countryCode": "DE"
            },
            {
                "name": "Bischofferode",
                "countryCode": "DE"
            },
            {
                "name": "Blankenhain",
                "countryCode": "DE"
            },
            {
                "name": "Bleicherode",
                "countryCode": "DE"
            },
            {
                "name": "Brahmenau",
                "countryCode": "DE"
            },
            {
                "name": "Brehme",
                "countryCode": "DE"
            },
            {
                "name": "Breitenbach",
                "countryCode": "DE"
            },
            {
                "name": "Breitenworbis",
                "countryCode": "DE"
            },
            {
                "name": "Breitungen",
                "countryCode": "DE"
            },
            {
                "name": "Brotterode",
                "countryCode": "DE"
            },
            {
                "name": "Bucha",
                "countryCode": "DE"
            },
            {
                "name": "Bufleben",
                "countryCode": "DE"
            },
            {
                "name": "Buttelstedt",
                "countryCode": "DE"
            },
            {
                "name": "Buttlar",
                "countryCode": "DE"
            },
            {
                "name": "Buttstädt",
                "countryCode": "DE"
            },
            {
                "name": "Bürgel",
                "countryCode": "DE"
            },
            {
                "name": "Büttstedt",
                "countryCode": "DE"
            },
            {
                "name": "Camburg",
                "countryCode": "DE"
            },
            {
                "name": "Clingen",
                "countryCode": "DE"
            },
            {
                "name": "Crawinkel",
                "countryCode": "DE"
            },
            {
                "name": "Creuzburg",
                "countryCode": "DE"
            },
            {
                "name": "Dachwig",
                "countryCode": "DE"
            },
            {
                "name": "Dankmarshausen",
                "countryCode": "DE"
            },
            {
                "name": "Dermbach",
                "countryCode": "DE"
            },
            {
                "name": "Deuna",
                "countryCode": "DE"
            },
            {
                "name": "Dingelstädt",
                "countryCode": "DE"
            },
            {
                "name": "Dippach",
                "countryCode": "DE"
            },
            {
                "name": "Dorndorf",
                "countryCode": "DE"
            },
            {
                "name": "Döllstädt",
                "countryCode": "DE"
            },
            {
                "name": "Ebeleben",
                "countryCode": "DE"
            },
            {
                "name": "Effelder",
                "countryCode": "DE"
            },
            {
                "name": "Eisenach",
                "countryCode": "DE"
            },
            {
                "name": "Eisenberg",
                "countryCode": "DE"
            },
            {
                "name": "Eisfeld",
                "countryCode": "DE"
            },
            {
                "name": "Elgersburg",
                "countryCode": "DE"
            },
            {
                "name": "Ellrich",
                "countryCode": "DE"
            },
            {
                "name": "Elxleben",
                "countryCode": "DE"
            },
            {
                "name": "Erfurt",
                "countryCode": "DE"
            },
            {
                "name": "Ernstroda",
                "countryCode": "DE"
            },
            {
                "name": "Fambach",
                "countryCode": "DE"
            },
            {
                "name": "Finsterbergen",
                "countryCode": "DE"
            },
            {
                "name": "Frankenheim",
                "countryCode": "DE"
            },
            {
                "name": "Frauenprießnitz",
                "countryCode": "DE"
            },
            {
                "name": "Frauenwald",
                "countryCode": "DE"
            },
            {
                "name": "Freienbessingen",
                "countryCode": "DE"
            },
            {
                "name": "Friedrichroda",
                "countryCode": "DE"
            },
            {
                "name": "Friemar",
                "countryCode": "DE"
            },
            {
                "name": "Föritz",
                "countryCode": "DE"
            },
            {
                "name": "Gangloffsömmern",
                "countryCode": "DE"
            },
            {
                "name": "Gebesee",
                "countryCode": "DE"
            },
            {
                "name": "Gehren",
                "countryCode": "DE"
            },
            {
                "name": "Geisa",
                "countryCode": "DE"
            },
            {
                "name": "Geisleden",
                "countryCode": "DE"
            },
            {
                "name": "Geismar",
                "countryCode": "DE"
            },
            {
                "name": "Georgenthal",
                "countryCode": "DE"
            },
            {
                "name": "Gera",
                "countryCode": "DE"
            },
            {
                "name": "Geraberg",
                "countryCode": "DE"
            },
            {
                "name": "Gernrode",
                "countryCode": "DE"
            },
            {
                "name": "Gerstungen",
                "countryCode": "DE"
            },
            {
                "name": "Geschwenda",
                "countryCode": "DE"
            },
            {
                "name": "Gleichamberg",
                "countryCode": "DE"
            },
            {
                "name": "Goldbach",
                "countryCode": "DE"
            },
            {
                "name": "Gotha",
                "countryCode": "DE"
            },
            {
                "name": "Goßwitz",
                "countryCode": "DE"
            },
            {
                "name": "Grabsleben",
                "countryCode": "DE"
            },
            {
                "name": "Greiz",
                "countryCode": "DE"
            },
            {
                "name": "Greußen",
                "countryCode": "DE"
            },
            {
                "name": "Großbartloff",
                "countryCode": "DE"
            },
            {
                "name": "Großbodungen",
                "countryCode": "DE"
            },
            {
                "name": "Großbreitenbach",
                "countryCode": "DE"
            },
            {
                "name": "Großenehrich",
                "countryCode": "DE"
            },
            {
                "name": "Großengottern",
                "countryCode": "DE"
            },
            {
                "name": "Großenstein",
                "countryCode": "DE"
            },
            {
                "name": "Großmonra",
                "countryCode": "DE"
            },
            {
                "name": "Großrudestedt",
                "countryCode": "DE"
            },
            {
                "name": "Gräfenhain",
                "countryCode": "DE"
            },
            {
                "name": "Gräfenroda",
                "countryCode": "DE"
            },
            {
                "name": "Görsbach",
                "countryCode": "DE"
            },
            {
                "name": "Gößnitz",
                "countryCode": "DE"
            },
            {
                "name": "Haina",
                "countryCode": "DE"
            },
            {
                "name": "Haßleben",
                "countryCode": "DE"
            },
            {
                "name": "Heilbad Heiligenstadt",
                "countryCode": "DE"
            },
            {
                "name": "Heldrungen",
                "countryCode": "DE"
            },
            {
                "name": "Hellingen",
                "countryCode": "DE"
            },
            {
                "name": "Herbsleben",
                "countryCode": "DE"
            },
            {
                "name": "Heringen",
                "countryCode": "DE"
            },
            {
                "name": "Hermsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Herschdorf",
                "countryCode": "DE"
            },
            {
                "name": "Heyerode",
                "countryCode": "DE"
            },
            {
                "name": "Hildburghausen",
                "countryCode": "DE"
            },
            {
                "name": "Hohenleuben",
                "countryCode": "DE"
            },
            {
                "name": "Hundeshagen",
                "countryCode": "DE"
            },
            {
                "name": "Hörselgau",
                "countryCode": "DE"
            },
            {
                "name": "Ichtershausen",
                "countryCode": "DE"
            },
            {
                "name": "Ifta",
                "countryCode": "DE"
            },
            {
                "name": "Ilfeld",
                "countryCode": "DE"
            },
            {
                "name": "Ilmenau",
                "countryCode": "DE"
            },
            {
                "name": "Immelborn",
                "countryCode": "DE"
            },
            {
                "name": "Ingersleben",
                "countryCode": "DE"
            },
            {
                "name": "Jena",
                "countryCode": "DE"
            },
            {
                "name": "Judenbach",
                "countryCode": "DE"
            },
            {
                "name": "Jüchsen",
                "countryCode": "DE"
            },
            {
                "name": "Kahla",
                "countryCode": "DE"
            },
            {
                "name": "Kaltennordheim",
                "countryCode": "DE"
            },
            {
                "name": "Kaltenwestheim",
                "countryCode": "DE"
            },
            {
                "name": "Kamsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kannawurf",
                "countryCode": "DE"
            },
            {
                "name": "Katharinenberg",
                "countryCode": "DE"
            },
            {
                "name": "Katzhütte",
                "countryCode": "DE"
            },
            {
                "name": "Kaulsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kindelbrück",
                "countryCode": "DE"
            },
            {
                "name": "Kirchheim",
                "countryCode": "DE"
            },
            {
                "name": "Kirchworbis",
                "countryCode": "DE"
            },
            {
                "name": "Kleinfurra",
                "countryCode": "DE"
            },
            {
                "name": "Kleinwenden",
                "countryCode": "DE"
            },
            {
                "name": "Klettbach",
                "countryCode": "DE"
            },
            {
                "name": "Kraftsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Kranichfeld",
                "countryCode": "DE"
            },
            {
                "name": "Krauthausen",
                "countryCode": "DE"
            },
            {
                "name": "Kriebitzsch",
                "countryCode": "DE"
            },
            {
                "name": "Kromsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Krölpa",
                "countryCode": "DE"
            },
            {
                "name": "Kölleda",
                "countryCode": "DE"
            },
            {
                "name": "Königsee",
                "countryCode": "DE"
            },
            {
                "name": "Könitz",
                "countryCode": "DE"
            },
            {
                "name": "Körner",
                "countryCode": "DE"
            },
            {
                "name": "Kühndorf",
                "countryCode": "DE"
            },
            {
                "name": "Küllstedt",
                "countryCode": "DE"
            },
            {
                "name": "Langenorla",
                "countryCode": "DE"
            },
            {
                "name": "Langenwetzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Langewiesen",
                "countryCode": "DE"
            },
            {
                "name": "Langula",
                "countryCode": "DE"
            },
            {
                "name": "Lauchröden",
                "countryCode": "DE"
            },
            {
                "name": "Lauscha",
                "countryCode": "DE"
            },
            {
                "name": "Lehesten",
                "countryCode": "DE"
            },
            {
                "name": "Leimbach",
                "countryCode": "DE"
            },
            {
                "name": "Leinefelde-Worbis",
                "countryCode": "DE"
            },
            {
                "name": "Leutenberg",
                "countryCode": "DE"
            },
            {
                "name": "Lichte",
                "countryCode": "DE"
            },
            {
                "name": "Lucka",
                "countryCode": "DE"
            },
            {
                "name": "Luisenthal",
                "countryCode": "DE"
            },
            {
                "name": "Löbichau",
                "countryCode": "DE"
            },
            {
                "name": "Magdala",
                "countryCode": "DE"
            },
            {
                "name": "Marksuhl",
                "countryCode": "DE"
            },
            {
                "name": "Masserberg",
                "countryCode": "DE"
            },
            {
                "name": "Mechterstädt",
                "countryCode": "DE"
            },
            {
                "name": "Meiningen",
                "countryCode": "DE"
            },
            {
                "name": "Mellenbach-Glasbach",
                "countryCode": "DE"
            },
            {
                "name": "Mellingen",
                "countryCode": "DE"
            },
            {
                "name": "Mengersgereuth-Hämmern",
                "countryCode": "DE"
            },
            {
                "name": "Menteroda",
                "countryCode": "DE"
            },
            {
                "name": "Meuselbach",
                "countryCode": "DE"
            },
            {
                "name": "Meuselwitz",
                "countryCode": "DE"
            },
            {
                "name": "Mihla",
                "countryCode": "DE"
            },
            {
                "name": "Milz",
                "countryCode": "DE"
            },
            {
                "name": "Mohlsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Molschleben",
                "countryCode": "DE"
            },
            {
                "name": "Mönchenholzhausen",
                "countryCode": "DE"
            },
            {
                "name": "Mühlhausen",
                "countryCode": "DE"
            },
            {
                "name": "Münchenbernsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Neudietendorf",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus am Rennweg",
                "countryCode": "DE"
            },
            {
                "name": "Neuhaus-Schierschnitz",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt am Rennsteig",
                "countryCode": "DE"
            },
            {
                "name": "Neustadt an der Orla",
                "countryCode": "DE"
            },
            {
                "name": "Niederdorla",
                "countryCode": "DE"
            },
            {
                "name": "Niederorschel",
                "countryCode": "DE"
            },
            {
                "name": "Niederroßla",
                "countryCode": "DE"
            },
            {
                "name": "Niedersachswerfen",
                "countryCode": "DE"
            },
            {
                "name": "Niederzimmern",
                "countryCode": "DE"
            },
            {
                "name": "Nobitz",
                "countryCode": "DE"
            },
            {
                "name": "Nohra",
                "countryCode": "DE"
            },
            {
                "name": "Nordhausen",
                "countryCode": "DE"
            },
            {
                "name": "Nöbdenitz",
                "countryCode": "DE"
            },
            {
                "name": "Oberdorla",
                "countryCode": "DE"
            },
            {
                "name": "Oberhof",
                "countryCode": "DE"
            },
            {
                "name": "Obermaßfeld-Grimmenthal",
                "countryCode": "DE"
            },
            {
                "name": "Obermehler",
                "countryCode": "DE"
            },
            {
                "name": "Oberweißbach",
                "countryCode": "DE"
            },
            {
                "name": "Oldisleben",
                "countryCode": "DE"
            },
            {
                "name": "Oppurg",
                "countryCode": "DE"
            },
            {
                "name": "Orlamünde",
                "countryCode": "DE"
            },
            {
                "name": "Oßmanstedt",
                "countryCode": "DE"
            },
            {
                "name": "Pappenheim",
                "countryCode": "DE"
            },
            {
                "name": "Plaue",
                "countryCode": "DE"
            },
            {
                "name": "Ponitz",
                "countryCode": "DE"
            },
            {
                "name": "Probstzella",
                "countryCode": "DE"
            },
            {
                "name": "Pölzig",
                "countryCode": "DE"
            },
            {
                "name": "Pößneck",
                "countryCode": "DE"
            },
            {
                "name": "Ranis",
                "countryCode": "DE"
            },
            {
                "name": "Rastenberg",
                "countryCode": "DE"
            },
            {
                "name": "Remptendorf",
                "countryCode": "DE"
            },
            {
                "name": "Riethnordhausen",
                "countryCode": "DE"
            },
            {
                "name": "Ringleben",
                "countryCode": "DE"
            },
            {
                "name": "Rohr",
                "countryCode": "DE"
            },
            {
                "name": "Ronneburg",
                "countryCode": "DE"
            },
            {
                "name": "Rositz",
                "countryCode": "DE"
            },
            {
                "name": "Rothenstein",
                "countryCode": "DE"
            },
            {
                "name": "Rottenbach",
                "countryCode": "DE"
            },
            {
                "name": "Roßleben",
                "countryCode": "DE"
            },
            {
                "name": "Rudolstadt",
                "countryCode": "DE"
            },
            {
                "name": "Ruhla",
                "countryCode": "DE"
            },
            {
                "name": "Römhild",
                "countryCode": "DE"
            },
            {
                "name": "Saalfeld",
                "countryCode": "DE"
            },
            {
                "name": "Saara",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Gangloff",
                "countryCode": "DE"
            },
            {
                "name": "Sankt Kilian",
                "countryCode": "DE"
            },
            {
                "name": "Schalkau",
                "countryCode": "DE"
            },
            {
                "name": "Schernberg",
                "countryCode": "DE"
            },
            {
                "name": "Schkölen",
                "countryCode": "DE"
            },
            {
                "name": "Schleid",
                "countryCode": "DE"
            },
            {
                "name": "Schleiz",
                "countryCode": "DE"
            },
            {
                "name": "Schleusingen",
                "countryCode": "DE"
            },
            {
                "name": "Schlotheim",
                "countryCode": "DE"
            },
            {
                "name": "Schloßvippach",
                "countryCode": "DE"
            },
            {
                "name": "Schmalkalden",
                "countryCode": "DE"
            },
            {
                "name": "Schmiedefeld",
                "countryCode": "DE"
            },
            {
                "name": "Schmiedefeld am Rennsteig",
                "countryCode": "DE"
            },
            {
                "name": "Schmölln",
                "countryCode": "DE"
            },
            {
                "name": "Schwallungen",
                "countryCode": "DE"
            },
            {
                "name": "Schwarza",
                "countryCode": "DE"
            },
            {
                "name": "Schweina",
                "countryCode": "DE"
            },
            {
                "name": "Schönstedt",
                "countryCode": "DE"
            },
            {
                "name": "Seebach",
                "countryCode": "DE"
            },
            {
                "name": "Seebergen",
                "countryCode": "DE"
            },
            {
                "name": "Seelingstädt",
                "countryCode": "DE"
            },
            {
                "name": "Sitzendorf",
                "countryCode": "DE"
            },
            {
                "name": "Sollstedt",
                "countryCode": "DE"
            },
            {
                "name": "Sondershausen",
                "countryCode": "DE"
            },
            {
                "name": "Sonneberg",
                "countryCode": "DE"
            },
            {
                "name": "Sonneborn",
                "countryCode": "DE"
            },
            {
                "name": "Stadtilm",
                "countryCode": "DE"
            },
            {
                "name": "Stadtlengsfeld",
                "countryCode": "DE"
            },
            {
                "name": "Stadtroda",
                "countryCode": "DE"
            },
            {
                "name": "Steinach",
                "countryCode": "DE"
            },
            {
                "name": "Steinbach",
                "countryCode": "DE"
            },
            {
                "name": "Steinbach-Hallenberg",
                "countryCode": "DE"
            },
            {
                "name": "Steinheid",
                "countryCode": "DE"
            },
            {
                "name": "Straußfurt",
                "countryCode": "DE"
            },
            {
                "name": "Stützerbach",
                "countryCode": "DE"
            },
            {
                "name": "Suhl",
                "countryCode": "DE"
            },
            {
                "name": "Sömmerda",
                "countryCode": "DE"
            },
            {
                "name": "Tabarz",
                "countryCode": "DE"
            },
            {
                "name": "Tambach-Dietharz",
                "countryCode": "DE"
            },
            {
                "name": "Tanna",
                "countryCode": "DE"
            },
            {
                "name": "Tautenhain",
                "countryCode": "DE"
            },
            {
                "name": "Teichwolframsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Teistungen",
                "countryCode": "DE"
            },
            {
                "name": "Thal",
                "countryCode": "DE"
            },
            {
                "name": "Themar",
                "countryCode": "DE"
            },
            {
                "name": "Tiefenort",
                "countryCode": "DE"
            },
            {
                "name": "Treffurt",
                "countryCode": "DE"
            },
            {
                "name": "Triebes",
                "countryCode": "DE"
            },
            {
                "name": "Triptis",
                "countryCode": "DE"
            },
            {
                "name": "Trusetal",
                "countryCode": "DE"
            },
            {
                "name": "Uder",
                "countryCode": "DE"
            },
            {
                "name": "Unterbreizbach",
                "countryCode": "DE"
            },
            {
                "name": "Untermaßfeld",
                "countryCode": "DE"
            },
            {
                "name": "Unterwellenborn",
                "countryCode": "DE"
            },
            {
                "name": "Urbach",
                "countryCode": "DE"
            },
            {
                "name": "Uthleben",
                "countryCode": "DE"
            },
            {
                "name": "Vacha",
                "countryCode": "DE"
            },
            {
                "name": "Veilsdorf",
                "countryCode": "DE"
            },
            {
                "name": "Viernau",
                "countryCode": "DE"
            },
            {
                "name": "Voigtstedt",
                "countryCode": "DE"
            },
            {
                "name": "Völkershausen",
                "countryCode": "DE"
            },
            {
                "name": "Walldorf",
                "countryCode": "DE"
            },
            {
                "name": "Walschleben",
                "countryCode": "DE"
            },
            {
                "name": "Waltershausen",
                "countryCode": "DE"
            },
            {
                "name": "Wandersleben",
                "countryCode": "DE"
            },
            {
                "name": "Wasungen",
                "countryCode": "DE"
            },
            {
                "name": "Weida",
                "countryCode": "DE"
            },
            {
                "name": "Weimar",
                "countryCode": "DE"
            },
            {
                "name": "Weißenborn",
                "countryCode": "DE"
            },
            {
                "name": "Weißenborn-Lüderode",
                "countryCode": "DE"
            },
            {
                "name": "Weißensee",
                "countryCode": "DE"
            },
            {
                "name": "Wernshausen",
                "countryCode": "DE"
            },
            {
                "name": "Wiehe",
                "countryCode": "DE"
            },
            {
                "name": "Windischleuba",
                "countryCode": "DE"
            },
            {
                "name": "Wingerode",
                "countryCode": "DE"
            },
            {
                "name": "Wintersdorf",
                "countryCode": "DE"
            },
            {
                "name": "Wipperdorf",
                "countryCode": "DE"
            },
            {
                "name": "Witterda",
                "countryCode": "DE"
            },
            {
                "name": "Wolkramshausen",
                "countryCode": "DE"
            },
            {
                "name": "Worbis",
                "countryCode": "DE"
            },
            {
                "name": "Wurzbach",
                "countryCode": "DE"
            },
            {
                "name": "Wölfis",
                "countryCode": "DE"
            },
            {
                "name": "Wünschendorf",
                "countryCode": "DE"
            },
            {
                "name": "Zella-Mehlis",
                "countryCode": "DE"
            },
            {
                "name": "Zeulenroda",
                "countryCode": "DE"
            }
        ]
    },
    {
        "name": "Ghana",
        "phonecode": "233",
        "cities": [
            {
                "name": "Accra",
                "countryCode": "GH"
            },
            {
                "name": "Atsiaman",
                "countryCode": "GH"
            },
            {
                "name": "Dome",
                "countryCode": "GH"
            },
            {
                "name": "Gbawe",
                "countryCode": "GH"
            },
            {
                "name": "Medina Estates",
                "countryCode": "GH"
            },
            {
                "name": "Nungua",
                "countryCode": "GH"
            },
            {
                "name": "Tema",
                "countryCode": "GH"
            },
            {
                "name": "Teshi Old Town",
                "countryCode": "GH"
            },
            {
                "name": "Asunafo North",
                "countryCode": "GH"
            },
            {
                "name": "Asunafo South",
                "countryCode": "GH"
            },
            {
                "name": "Asutifi North",
                "countryCode": "GH"
            },
            {
                "name": "Asutifi South",
                "countryCode": "GH"
            },
            {
                "name": "Tano North",
                "countryCode": "GH"
            },
            {
                "name": "Tano South",
                "countryCode": "GH"
            },
            {
                "name": "Agogo",
                "countryCode": "GH"
            },
            {
                "name": "Bekwai",
                "countryCode": "GH"
            },
            {
                "name": "Ejura",
                "countryCode": "GH"
            },
            {
                "name": "Konongo",
                "countryCode": "GH"
            },
            {
                "name": "Kumasi",
                "countryCode": "GH"
            },
            {
                "name": "Mampong",
                "countryCode": "GH"
            },
            {
                "name": "Obuase",
                "countryCode": "GH"
            },
            {
                "name": "Tafo",
                "countryCode": "GH"
            },
            {
                "name": "Atebubu-Amantin",
                "countryCode": "GH"
            },
            {
                "name": "Kintampo North",
                "countryCode": "GH"
            },
            {
                "name": "Kintampo South",
                "countryCode": "GH"
            },
            {
                "name": "Nkoranza North",
                "countryCode": "GH"
            },
            {
                "name": "Nkoranza South",
                "countryCode": "GH"
            },
            {
                "name": "Pru East",
                "countryCode": "GH"
            },
            {
                "name": "Pru West",
                "countryCode": "GH"
            },
            {
                "name": "Sene East",
                "countryCode": "GH"
            },
            {
                "name": "Sene West",
                "countryCode": "GH"
            },
            {
                "name": "Techiman",
                "countryCode": "GH"
            },
            {
                "name": "Techiman North",
                "countryCode": "GH"
            },
            {
                "name": "Banda",
                "countryCode": "GH"
            },
            {
                "name": "Berekum East",
                "countryCode": "GH"
            },
            {
                "name": "Berekum West",
                "countryCode": "GH"
            },
            {
                "name": "Dormaa Central",
                "countryCode": "GH"
            },
            {
                "name": "Dormaa East",
                "countryCode": "GH"
            },
            {
                "name": "Dormaa West",
                "countryCode": "GH"
            },
            {
                "name": "Jaman North",
                "countryCode": "GH"
            },
            {
                "name": "Jaman South",
                "countryCode": "GH"
            },
            {
                "name": "Sunyani",
                "countryCode": "GH"
            },
            {
                "name": "Sunyani West",
                "countryCode": "GH"
            },
            {
                "name": "Tain",
                "countryCode": "GH"
            },
            {
                "name": "Wenchi",
                "countryCode": "GH"
            },
            {
                "name": "Apam",
                "countryCode": "GH"
            },
            {
                "name": "Cape Coast",
                "countryCode": "GH"
            },
            {
                "name": "Dunkwa",
                "countryCode": "GH"
            },
            {
                "name": "Elmina",
                "countryCode": "GH"
            },
            {
                "name": "Foso",
                "countryCode": "GH"
            },
            {
                "name": "Kasoa",
                "countryCode": "GH"
            },
            {
                "name": "Mumford",
                "countryCode": "GH"
            },
            {
                "name": "Saltpond",
                "countryCode": "GH"
            },
            {
                "name": "Swedru",
                "countryCode": "GH"
            },
            {
                "name": "Winneba",
                "countryCode": "GH"
            },
            {
                "name": "Aburi",
                "countryCode": "GH"
            },
            {
                "name": "Akim Oda",
                "countryCode": "GH"
            },
            {
                "name": "Akim Swedru",
                "countryCode": "GH"
            },
            {
                "name": "Akropong",
                "countryCode": "GH"
            },
            {
                "name": "Akwatia",
                "countryCode": "GH"
            },
            {
                "name": "Asamankese",
                "countryCode": "GH"
            },
            {
                "name": "Begoro",
                "countryCode": "GH"
            },
            {
                "name": "Kibi",
                "countryCode": "GH"
            },
            {
                "name": "Koforidua",
                "countryCode": "GH"
            },
            {
                "name": "Mpraeso",
                "countryCode": "GH"
            },
            {
                "name": "Nsawam",
                "countryCode": "GH"
            },
            {
                "name": "Suhum",
                "countryCode": "GH"
            },
            {
                "name": "Bunkpurugu-Nyakpanduri",
                "countryCode": "GH"
            },
            {
                "name": "Chereponi",
                "countryCode": "GH"
            },
            {
                "name": "East Mamprusi",
                "countryCode": "GH"
            },
            {
                "name": "Mamprugu-Moagduri",
                "countryCode": "GH"
            },
            {
                "name": "West Mamprusi",
                "countryCode": "GH"
            },
            {
                "name": "Yunyoo-Nasuan",
                "countryCode": "GH"
            },
            {
                "name": "Kpandae",
                "countryCode": "GH"
            },
            {
                "name": "Salaga",
                "countryCode": "GH"
            },
            {
                "name": "Savelugu",
                "countryCode": "GH"
            },
            {
                "name": "Tamale",
                "countryCode": "GH"
            },
            {
                "name": "Yendi",
                "countryCode": "GH"
            },
            {
                "name": "Biakoye",
                "countryCode": "GH"
            },
            {
                "name": "Jasikan",
                "countryCode": "GH"
            },
            {
                "name": "Kadjebi",
                "countryCode": "GH"
            },
            {
                "name": "Krachi East",
                "countryCode": "GH"
            },
            {
                "name": "Krachi Nchumuru",
                "countryCode": "GH"
            },
            {
                "name": "Krachi West",
                "countryCode": "GH"
            },
            {
                "name": "Nkwanta North",
                "countryCode": "GH"
            },
            {
                "name": "Nkwanta South",
                "countryCode": "GH"
            },
            {
                "name": "Bole",
                "countryCode": "GH"
            },
            {
                "name": "Central Gonja",
                "countryCode": "GH"
            },
            {
                "name": "East Gonja",
                "countryCode": "GH"
            },
            {
                "name": "North East Gonja",
                "countryCode": "GH"
            },
            {
                "name": "North Gonja",
                "countryCode": "GH"
            },
            {
                "name": "Sawla-Tuna-Kalba",
                "countryCode": "GH"
            },
            {
                "name": "West Gonja",
                "countryCode": "GH"
            },
            {
                "name": "Aflao",
                "countryCode": "GH"
            },
            {
                "name": "Anloga",
                "countryCode": "GH"
            },
            {
                "name": "Ho",
                "countryCode": "GH"
            },
            {
                "name": "Hohoe",
                "countryCode": "GH"
            },
            {
                "name": "Keta",
                "countryCode": "GH"
            },
            {
                "name": "Kete Krachi",
                "countryCode": "GH"
            },
            {
                "name": "Kpandu",
                "countryCode": "GH"
            },
            {
                "name": "Bawku",
                "countryCode": "GH"
            },
            {
                "name": "Bolgatanga",
                "countryCode": "GH"
            },
            {
                "name": "Navrongo",
                "countryCode": "GH"
            },
            {
                "name": "Wa",
                "countryCode": "GH"
            },
            {
                "name": "Aowin",
                "countryCode": "GH"
            },
            {
                "name": "Bia East",
                "countryCode": "GH"
            },
            {
                "name": "Bia West",
                "countryCode": "GH"
            },
            {
                "name": "Bibiani-Anhwiaso-Bekwai",
                "countryCode": "GH"
            },
            {
                "name": "Bodi",
                "countryCode": "GH"
            },
            {
                "name": "Juaboso",
                "countryCode": "GH"
            },
            {
                "name": "Sefwi-Akontombra",
                "countryCode": "GH"
            },
            {
                "name": "Sefwi-Wiawso",
                "countryCode": "GH"
            },
            {
                "name": "Suaman",
                "countryCode": "GH"
            },
            {
                "name": "Aboso",
                "countryCode": "GH"
            },
            {
                "name": "Axim",
                "countryCode": "GH"
            },
            {
                "name": "Bibiani",
                "countryCode": "GH"
            },
            {
                "name": "Prestea",
                "countryCode": "GH"
            },
            {
                "name": "Sekondi-Takoradi",
                "countryCode": "GH"
            },
            {
                "name": "Shama Junction",
                "countryCode": "GH"
            },
            {
                "name": "Takoradi",
                "countryCode": "GH"
            },
            {
                "name": "Tarkwa",
                "countryCode": "GH"
            }
        ]
    },
    {
        "name": "Gibraltar",
        "phonecode": "350",
        "cities": []
    },
    {
        "name": "Greece",
        "phonecode": "30",
        "cities": [
            {
                "name": "Farkadóna",
                "countryCode": "GR"
            },
            {
                "name": "Fíki",
                "countryCode": "GR"
            },
            {
                "name": "Grizáno",
                "countryCode": "GR"
            },
            {
                "name": "Gómfoi",
                "countryCode": "GR"
            },
            {
                "name": "Kalampáka",
                "countryCode": "GR"
            },
            {
                "name": "Kastráki",
                "countryCode": "GR"
            },
            {
                "name": "Megalochóri",
                "countryCode": "GR"
            },
            {
                "name": "Megála Kalývia",
                "countryCode": "GR"
            },
            {
                "name": "Oichalía",
                "countryCode": "GR"
            },
            {
                "name": "Palaiomonástiro",
                "countryCode": "GR"
            },
            {
                "name": "Palaiópyrgos",
                "countryCode": "GR"
            },
            {
                "name": "Pigí",
                "countryCode": "GR"
            },
            {
                "name": "Pyrgetós",
                "countryCode": "GR"
            },
            {
                "name": "Pýli",
                "countryCode": "GR"
            },
            {
                "name": "Rízoma",
                "countryCode": "GR"
            },
            {
                "name": "Taxiárches",
                "countryCode": "GR"
            },
            {
                "name": "Tríkala",
                "countryCode": "GR"
            },
            {
                "name": "Vasilikí",
                "countryCode": "GR"
            },
            {
                "name": "Zárkos",
                "countryCode": "GR"
            },
            {
                "name": "Agnanteró",
                "countryCode": "GR"
            },
            {
                "name": "Agía Triáda",
                "countryCode": "GR"
            },
            {
                "name": "Anthiró",
                "countryCode": "GR"
            },
            {
                "name": "Anávra",
                "countryCode": "GR"
            },
            {
                "name": "Artesianó",
                "countryCode": "GR"
            },
            {
                "name": "Itéa",
                "countryCode": "GR"
            },
            {
                "name": "Kallifóni",
                "countryCode": "GR"
            },
            {
                "name": "Kallíthiro",
                "countryCode": "GR"
            },
            {
                "name": "Karditsomagoúla",
                "countryCode": "GR"
            },
            {
                "name": "Kardítsa",
                "countryCode": "GR"
            },
            {
                "name": "Karpochóri",
                "countryCode": "GR"
            },
            {
                "name": "Magoúla",
                "countryCode": "GR"
            },
            {
                "name": "Makrychóri",
                "countryCode": "GR"
            },
            {
                "name": "Mavrommáti",
                "countryCode": "GR"
            },
            {
                "name": "Mitrópoli",
                "countryCode": "GR"
            },
            {
                "name": "Morfovoúni",
                "countryCode": "GR"
            },
            {
                "name": "Mouzáki",
                "countryCode": "GR"
            },
            {
                "name": "Palamás",
                "countryCode": "GR"
            },
            {
                "name": "Proástio",
                "countryCode": "GR"
            },
            {
                "name": "Sofádes",
                "countryCode": "GR"
            },
            {
                "name": "Agriá",
                "countryCode": "GR"
            },
            {
                "name": "Almyrós",
                "countryCode": "GR"
            },
            {
                "name": "Anakasiá",
                "countryCode": "GR"
            },
            {
                "name": "Argalastí",
                "countryCode": "GR"
            },
            {
                "name": "Evxinoúpolis",
                "countryCode": "GR"
            },
            {
                "name": "Kanália",
                "countryCode": "GR"
            },
            {
                "name": "Káto Lekhónia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Anchiálos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Ionía",
                "countryCode": "GR"
            },
            {
                "name": "Patitírion",
                "countryCode": "GR"
            },
            {
                "name": "Portariá",
                "countryCode": "GR"
            },
            {
                "name": "Pteleós",
                "countryCode": "GR"
            },
            {
                "name": "Rizómylos",
                "countryCode": "GR"
            },
            {
                "name": "Skiáthos",
                "countryCode": "GR"
            },
            {
                "name": "Skópelos",
                "countryCode": "GR"
            },
            {
                "name": "Soúrpi",
                "countryCode": "GR"
            },
            {
                "name": "Stefanovíkeio",
                "countryCode": "GR"
            },
            {
                "name": "Tríkeri",
                "countryCode": "GR"
            },
            {
                "name": "Velestíno",
                "countryCode": "GR"
            },
            {
                "name": "Volos",
                "countryCode": "GR"
            },
            {
                "name": "Zagorá",
                "countryCode": "GR"
            },
            {
                "name": "Álli Meriá",
                "countryCode": "GR"
            },
            {
                "name": "Áno Lekhónia",
                "countryCode": "GR"
            },
            {
                "name": "Alexandroupoli",
                "countryCode": "GR"
            },
            {
                "name": "Amygdaleónas",
                "countryCode": "GR"
            },
            {
                "name": "Arrianá",
                "countryCode": "GR"
            },
            {
                "name": "Arísvi",
                "countryCode": "GR"
            },
            {
                "name": "Choristí",
                "countryCode": "GR"
            },
            {
                "name": "Chrysochóri",
                "countryCode": "GR"
            },
            {
                "name": "Chrysoúpolis",
                "countryCode": "GR"
            },
            {
                "name": "Didymóteicho",
                "countryCode": "GR"
            },
            {
                "name": "Dráma",
                "countryCode": "GR"
            },
            {
                "name": "Echínos",
                "countryCode": "GR"
            },
            {
                "name": "Elaiochóri",
                "countryCode": "GR"
            },
            {
                "name": "Eleftheroúpolis",
                "countryCode": "GR"
            },
            {
                "name": "Eleftherés",
                "countryCode": "GR"
            },
            {
                "name": "Fillýra",
                "countryCode": "GR"
            },
            {
                "name": "Fotolívos",
                "countryCode": "GR"
            },
            {
                "name": "Fteliá",
                "countryCode": "GR"
            },
            {
                "name": "Féres",
                "countryCode": "GR"
            },
            {
                "name": "Genisséa",
                "countryCode": "GR"
            },
            {
                "name": "Iliokentima",
                "countryCode": "GR"
            },
            {
                "name": "Kalampáki",
                "countryCode": "GR"
            },
            {
                "name": "Kallífytos",
                "countryCode": "GR"
            },
            {
                "name": "Kalí Vrýsi",
                "countryCode": "GR"
            },
            {
                "name": "Kalós Agrós",
                "countryCode": "GR"
            },
            {
                "name": "Kamariótissa",
                "countryCode": "GR"
            },
            {
                "name": "Kastaniés",
                "countryCode": "GR"
            },
            {
                "name": "Kavála",
                "countryCode": "GR"
            },
            {
                "name": "Kavýli",
                "countryCode": "GR"
            },
            {
                "name": "Keramotí",
                "countryCode": "GR"
            },
            {
                "name": "Kimméria",
                "countryCode": "GR"
            },
            {
                "name": "Kokkinóchoma",
                "countryCode": "GR"
            },
            {
                "name": "Komotiní",
                "countryCode": "GR"
            },
            {
                "name": "Krinídes",
                "countryCode": "GR"
            },
            {
                "name": "Kyprínos",
                "countryCode": "GR"
            },
            {
                "name": "Káto Nevrokópi",
                "countryCode": "GR"
            },
            {
                "name": "Kýria",
                "countryCode": "GR"
            },
            {
                "name": "Lagós",
                "countryCode": "GR"
            },
            {
                "name": "Limenária",
                "countryCode": "GR"
            },
            {
                "name": "Lávara",
                "countryCode": "GR"
            },
            {
                "name": "Lýkeio",
                "countryCode": "GR"
            },
            {
                "name": "Mikrópolis",
                "countryCode": "GR"
            },
            {
                "name": "Mýki",
                "countryCode": "GR"
            },
            {
                "name": "Neochóri",
                "countryCode": "GR"
            },
            {
                "name": "Nikísiani",
                "countryCode": "GR"
            },
            {
                "name": "Néa Iraklítsa",
                "countryCode": "GR"
            },
            {
                "name": "Néa Karváli",
                "countryCode": "GR"
            },
            {
                "name": "Néa Karyá",
                "countryCode": "GR"
            },
            {
                "name": "Néa Péramos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Výssa",
                "countryCode": "GR"
            },
            {
                "name": "Orestiáda",
                "countryCode": "GR"
            },
            {
                "name": "Palaiochóri",
                "countryCode": "GR"
            },
            {
                "name": "Palaió Tsiflíki",
                "countryCode": "GR"
            },
            {
                "name": "Paralía Ofryníou",
                "countryCode": "GR"
            },
            {
                "name": "Paranésti",
                "countryCode": "GR"
            },
            {
                "name": "Peteinós",
                "countryCode": "GR"
            },
            {
                "name": "Petrochóri",
                "countryCode": "GR"
            },
            {
                "name": "Petroússa",
                "countryCode": "GR"
            },
            {
                "name": "Potamiá",
                "countryCode": "GR"
            },
            {
                "name": "Prosotsáni",
                "countryCode": "GR"
            },
            {
                "name": "Prínos",
                "countryCode": "GR"
            },
            {
                "name": "Páchni",
                "countryCode": "GR"
            },
            {
                "name": "Péplos",
                "countryCode": "GR"
            },
            {
                "name": "Rízia",
                "countryCode": "GR"
            },
            {
                "name": "Samothráki",
                "countryCode": "GR"
            },
            {
                "name": "Sitagroí",
                "countryCode": "GR"
            },
            {
                "name": "Smínthi",
                "countryCode": "GR"
            },
            {
                "name": "Souflí",
                "countryCode": "GR"
            },
            {
                "name": "Sápes",
                "countryCode": "GR"
            },
            {
                "name": "Sélero",
                "countryCode": "GR"
            },
            {
                "name": "Thásos",
                "countryCode": "GR"
            },
            {
                "name": "Tycheró",
                "countryCode": "GR"
            },
            {
                "name": "Vólakas",
                "countryCode": "GR"
            },
            {
                "name": "Xiropótamos",
                "countryCode": "GR"
            },
            {
                "name": "Xylaganí",
                "countryCode": "GR"
            },
            {
                "name": "Xánthi",
                "countryCode": "GR"
            },
            {
                "name": "Zygós",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Athanásios",
                "countryCode": "GR"
            },
            {
                "name": "Áratos",
                "countryCode": "GR"
            },
            {
                "name": "Ávato",
                "countryCode": "GR"
            },
            {
                "name": "Évlalo",
                "countryCode": "GR"
            },
            {
                "name": "Íasmos",
                "countryCode": "GR"
            },
            {
                "name": "Agkathiá",
                "countryCode": "GR"
            },
            {
                "name": "Agía Paraskeví",
                "countryCode": "GR"
            },
            {
                "name": "Agía Triáda",
                "countryCode": "GR"
            },
            {
                "name": "Aigínio",
                "countryCode": "GR"
            },
            {
                "name": "Akrolímni",
                "countryCode": "GR"
            },
            {
                "name": "Alexándreia",
                "countryCode": "GR"
            },
            {
                "name": "Alistráti",
                "countryCode": "GR"
            },
            {
                "name": "Ampeleíes",
                "countryCode": "GR"
            },
            {
                "name": "Ampelókipoi",
                "countryCode": "GR"
            },
            {
                "name": "Anatolikó",
                "countryCode": "GR"
            },
            {
                "name": "Angelochóri",
                "countryCode": "GR"
            },
            {
                "name": "Aravissós",
                "countryCode": "GR"
            },
            {
                "name": "Aridaía",
                "countryCode": "GR"
            },
            {
                "name": "Arnaía",
                "countryCode": "GR"
            },
            {
                "name": "Arsénio",
                "countryCode": "GR"
            },
            {
                "name": "Askós",
                "countryCode": "GR"
            },
            {
                "name": "Asproválta",
                "countryCode": "GR"
            },
            {
                "name": "Asvestochóri",
                "countryCode": "GR"
            },
            {
                "name": "Axioúpoli",
                "countryCode": "GR"
            },
            {
                "name": "Axós",
                "countryCode": "GR"
            },
            {
                "name": "Chalkidóna",
                "countryCode": "GR"
            },
            {
                "name": "Chalástra",
                "countryCode": "GR"
            },
            {
                "name": "Charopó",
                "countryCode": "GR"
            },
            {
                "name": "Chortiátis",
                "countryCode": "GR"
            },
            {
                "name": "Chrysochórafa",
                "countryCode": "GR"
            },
            {
                "name": "Chrysó",
                "countryCode": "GR"
            },
            {
                "name": "Diavatá",
                "countryCode": "GR"
            },
            {
                "name": "Diavatós",
                "countryCode": "GR"
            },
            {
                "name": "Dravískos",
                "countryCode": "GR"
            },
            {
                "name": "Drymós",
                "countryCode": "GR"
            },
            {
                "name": "Díon",
                "countryCode": "GR"
            },
            {
                "name": "Efkarpía",
                "countryCode": "GR"
            },
            {
                "name": "Eirinoúpoli",
                "countryCode": "GR"
            },
            {
                "name": "Epanomí",
                "countryCode": "GR"
            },
            {
                "name": "Episkopí",
                "countryCode": "GR"
            },
            {
                "name": "Evropós",
                "countryCode": "GR"
            },
            {
                "name": "Exaplátanos",
                "countryCode": "GR"
            },
            {
                "name": "Fíliro",
                "countryCode": "GR"
            },
            {
                "name": "Galatádes",
                "countryCode": "GR"
            },
            {
                "name": "Galátista",
                "countryCode": "GR"
            },
            {
                "name": "Gerakaroú",
                "countryCode": "GR"
            },
            {
                "name": "Giannitsá",
                "countryCode": "GR"
            },
            {
                "name": "Gouménissa",
                "countryCode": "GR"
            },
            {
                "name": "Gázoros",
                "countryCode": "GR"
            },
            {
                "name": "Géfyra",
                "countryCode": "GR"
            },
            {
                "name": "Ierissós",
                "countryCode": "GR"
            },
            {
                "name": "Irákleia",
                "countryCode": "GR"
            },
            {
                "name": "Kalamariá",
                "countryCode": "GR"
            },
            {
                "name": "Kallithéa",
                "countryCode": "GR"
            },
            {
                "name": "Kalochóri",
                "countryCode": "GR"
            },
            {
                "name": "Kalá Déndra",
                "countryCode": "GR"
            },
            {
                "name": "Kalí",
                "countryCode": "GR"
            },
            {
                "name": "Kalýves Polygýrou",
                "countryCode": "GR"
            },
            {
                "name": "Kalývia",
                "countryCode": "GR"
            },
            {
                "name": "Kampánis",
                "countryCode": "GR"
            },
            {
                "name": "Kardiá",
                "countryCode": "GR"
            },
            {
                "name": "Karyótissa",
                "countryCode": "GR"
            },
            {
                "name": "Karítsa",
                "countryCode": "GR"
            },
            {
                "name": "Kassándreia",
                "countryCode": "GR"
            },
            {
                "name": "Kateríni",
                "countryCode": "GR"
            },
            {
                "name": "Kavallári",
                "countryCode": "GR"
            },
            {
                "name": "Kilkís",
                "countryCode": "GR"
            },
            {
                "name": "Kleidí",
                "countryCode": "GR"
            },
            {
                "name": "Kolchikón",
                "countryCode": "GR"
            },
            {
                "name": "Kolindrós",
                "countryCode": "GR"
            },
            {
                "name": "Kontariótissa",
                "countryCode": "GR"
            },
            {
                "name": "Kopanós",
                "countryCode": "GR"
            },
            {
                "name": "Korinós",
                "countryCode": "GR"
            },
            {
                "name": "Koryfí",
                "countryCode": "GR"
            },
            {
                "name": "Koufália",
                "countryCode": "GR"
            },
            {
                "name": "Kouloúra",
                "countryCode": "GR"
            },
            {
                "name": "Koímisi",
                "countryCode": "GR"
            },
            {
                "name": "Krithiá",
                "countryCode": "GR"
            },
            {
                "name": "Krýa Vrýsi",
                "countryCode": "GR"
            },
            {
                "name": "Káto Kamíla",
                "countryCode": "GR"
            },
            {
                "name": "Káto Lipochóri",
                "countryCode": "GR"
            },
            {
                "name": "Káto Miliá",
                "countryCode": "GR"
            },
            {
                "name": "Káto Scholári",
                "countryCode": "GR"
            },
            {
                "name": "Kítros",
                "countryCode": "GR"
            },
            {
                "name": "Kýmina",
                "countryCode": "GR"
            },
            {
                "name": "Lagkadás",
                "countryCode": "GR"
            },
            {
                "name": "Lagyná",
                "countryCode": "GR"
            },
            {
                "name": "Lefkónas",
                "countryCode": "GR"
            },
            {
                "name": "Leptokaryá",
                "countryCode": "GR"
            },
            {
                "name": "Lianovérgi",
                "countryCode": "GR"
            },
            {
                "name": "Lití",
                "countryCode": "GR"
            },
            {
                "name": "Litóchoro",
                "countryCode": "GR"
            },
            {
                "name": "Loutráki",
                "countryCode": "GR"
            },
            {
                "name": "Loutrós",
                "countryCode": "GR"
            },
            {
                "name": "Lákkoma",
                "countryCode": "GR"
            },
            {
                "name": "Lófos",
                "countryCode": "GR"
            },
            {
                "name": "Makrochóri",
                "countryCode": "GR"
            },
            {
                "name": "Makrýgialos",
                "countryCode": "GR"
            },
            {
                "name": "Marína",
                "countryCode": "GR"
            },
            {
                "name": "Mavrothálassa",
                "countryCode": "GR"
            },
            {
                "name": "Mavrovoúni",
                "countryCode": "GR"
            },
            {
                "name": "Megáli Panagía",
                "countryCode": "GR"
            },
            {
                "name": "Melissochóri",
                "countryCode": "GR"
            },
            {
                "name": "Melíki",
                "countryCode": "GR"
            },
            {
                "name": "Melíssi",
                "countryCode": "GR"
            },
            {
                "name": "Meneméni",
                "countryCode": "GR"
            },
            {
                "name": "Mesiméri",
                "countryCode": "GR"
            },
            {
                "name": "Mikró Monastíri",
                "countryCode": "GR"
            },
            {
                "name": "Mitroúsi",
                "countryCode": "GR"
            },
            {
                "name": "Mándalo",
                "countryCode": "GR"
            },
            {
                "name": "Neapoli",
                "countryCode": "GR"
            },
            {
                "name": "Neochoroúda",
                "countryCode": "GR"
            },
            {
                "name": "Neochóri",
                "countryCode": "GR"
            },
            {
                "name": "Nigríta",
                "countryCode": "GR"
            },
            {
                "name": "Nisí",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Chalkidikís",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Péllis",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Thessaloníkis",
                "countryCode": "GR"
            },
            {
                "name": "Náousa",
                "countryCode": "GR"
            },
            {
                "name": "Néa Apollonía",
                "countryCode": "GR"
            },
            {
                "name": "Néa Flogitá",
                "countryCode": "GR"
            },
            {
                "name": "Néa Fókaia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Kallikráteia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Magnisía",
                "countryCode": "GR"
            },
            {
                "name": "Néa Mesimvría",
                "countryCode": "GR"
            },
            {
                "name": "Néa Michanióna",
                "countryCode": "GR"
            },
            {
                "name": "Néa Moudhaniá",
                "countryCode": "GR"
            },
            {
                "name": "Néa Málgara",
                "countryCode": "GR"
            },
            {
                "name": "Néa Plágia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Poteídaia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Potídhaia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Pélla",
                "countryCode": "GR"
            },
            {
                "name": "Néa Róda",
                "countryCode": "GR"
            },
            {
                "name": "Néa Sánta",
                "countryCode": "GR"
            },
            {
                "name": "Néa Tríglia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Vrasná",
                "countryCode": "GR"
            },
            {
                "name": "Néa Zíchni",
                "countryCode": "GR"
            },
            {
                "name": "Néa Éfesos",
                "countryCode": "GR"
            },
            {
                "name": "Néo Agionéri",
                "countryCode": "GR"
            },
            {
                "name": "Néo Petrítsi",
                "countryCode": "GR"
            },
            {
                "name": "Néo Rýsi",
                "countryCode": "GR"
            },
            {
                "name": "Néo Soúli",
                "countryCode": "GR"
            },
            {
                "name": "Néoi Epivátes",
                "countryCode": "GR"
            },
            {
                "name": "Néos Marmarás",
                "countryCode": "GR"
            },
            {
                "name": "Néos Mylótopos",
                "countryCode": "GR"
            },
            {
                "name": "Néos Skopós",
                "countryCode": "GR"
            },
            {
                "name": "Níkiti",
                "countryCode": "GR"
            },
            {
                "name": "Oraiókastro",
                "countryCode": "GR"
            },
            {
                "name": "Ormýlia",
                "countryCode": "GR"
            },
            {
                "name": "Ouranoupolis",
                "countryCode": "GR"
            },
            {
                "name": "Palaiochóri",
                "countryCode": "GR"
            },
            {
                "name": "Palaiokómi",
                "countryCode": "GR"
            },
            {
                "name": "Palaífyto",
                "countryCode": "GR"
            },
            {
                "name": "Panórama",
                "countryCode": "GR"
            },
            {
                "name": "Paralía",
                "countryCode": "GR"
            },
            {
                "name": "Patrída",
                "countryCode": "GR"
            },
            {
                "name": "Pefkochóri",
                "countryCode": "GR"
            },
            {
                "name": "Pentaplátano",
                "countryCode": "GR"
            },
            {
                "name": "Pentálofos",
                "countryCode": "GR"
            },
            {
                "name": "Peraía",
                "countryCode": "GR"
            },
            {
                "name": "Peristerá",
                "countryCode": "GR"
            },
            {
                "name": "Perístasi",
                "countryCode": "GR"
            },
            {
                "name": "Plagiári",
                "countryCode": "GR"
            },
            {
                "name": "Platý",
                "countryCode": "GR"
            },
            {
                "name": "Polykárpi",
                "countryCode": "GR"
            },
            {
                "name": "Políchni",
                "countryCode": "GR"
            },
            {
                "name": "Polýgyros",
                "countryCode": "GR"
            },
            {
                "name": "Polýkastro",
                "countryCode": "GR"
            },
            {
                "name": "Pontisméno",
                "countryCode": "GR"
            },
            {
                "name": "Portariá",
                "countryCode": "GR"
            },
            {
                "name": "Profítis Ilías",
                "countryCode": "GR"
            },
            {
                "name": "Provatás",
                "countryCode": "GR"
            },
            {
                "name": "Próchoma",
                "countryCode": "GR"
            },
            {
                "name": "Prómachoi",
                "countryCode": "GR"
            },
            {
                "name": "Próti",
                "countryCode": "GR"
            },
            {
                "name": "Pylaía",
                "countryCode": "GR"
            },
            {
                "name": "Péfka",
                "countryCode": "GR"
            },
            {
                "name": "Pélla",
                "countryCode": "GR"
            },
            {
                "name": "Ritíni",
                "countryCode": "GR"
            },
            {
                "name": "Rizári",
                "countryCode": "GR"
            },
            {
                "name": "Rizó",
                "countryCode": "GR"
            },
            {
                "name": "Rizómata",
                "countryCode": "GR"
            },
            {
                "name": "Rodolívos",
                "countryCode": "GR"
            },
            {
                "name": "Sevastianá",
                "countryCode": "GR"
            },
            {
                "name": "Sfendámi",
                "countryCode": "GR"
            },
            {
                "name": "Sidirókastro",
                "countryCode": "GR"
            },
            {
                "name": "Skotoússa",
                "countryCode": "GR"
            },
            {
                "name": "Skoútari",
                "countryCode": "GR"
            },
            {
                "name": "Skýdra",
                "countryCode": "GR"
            },
            {
                "name": "Sochós",
                "countryCode": "GR"
            },
            {
                "name": "Sosándra",
                "countryCode": "GR"
            },
            {
                "name": "Sourotí",
                "countryCode": "GR"
            },
            {
                "name": "Stathmós Mourión",
                "countryCode": "GR"
            },
            {
                "name": "Stavroúpoli",
                "countryCode": "GR"
            },
            {
                "name": "Stavrós",
                "countryCode": "GR"
            },
            {
                "name": "Stratónion",
                "countryCode": "GR"
            },
            {
                "name": "Strymonikó",
                "countryCode": "GR"
            },
            {
                "name": "Svorónos",
                "countryCode": "GR"
            },
            {
                "name": "Sykiá",
                "countryCode": "GR"
            },
            {
                "name": "Sykiés",
                "countryCode": "GR"
            },
            {
                "name": "Sárti",
                "countryCode": "GR"
            },
            {
                "name": "Sérres",
                "countryCode": "GR"
            },
            {
                "name": "Símantra",
                "countryCode": "GR"
            },
            {
                "name": "Síndos",
                "countryCode": "GR"
            },
            {
                "name": "Terpní",
                "countryCode": "GR"
            },
            {
                "name": "Thessaloníki",
                "countryCode": "GR"
            },
            {
                "name": "Thérmi",
                "countryCode": "GR"
            },
            {
                "name": "Triandría",
                "countryCode": "GR"
            },
            {
                "name": "Tríkala",
                "countryCode": "GR"
            },
            {
                "name": "Trílofos",
                "countryCode": "GR"
            },
            {
                "name": "Valteró",
                "countryCode": "GR"
            },
            {
                "name": "Vamvakófyto",
                "countryCode": "GR"
            },
            {
                "name": "Vasiliká",
                "countryCode": "GR"
            },
            {
                "name": "Vathýlakkos",
                "countryCode": "GR"
            },
            {
                "name": "Vergína",
                "countryCode": "GR"
            },
            {
                "name": "Vrontoú",
                "countryCode": "GR"
            },
            {
                "name": "Véroia",
                "countryCode": "GR"
            },
            {
                "name": "Xilópolis",
                "countryCode": "GR"
            },
            {
                "name": "Ádendro",
                "countryCode": "GR"
            },
            {
                "name": "Áfytos",
                "countryCode": "GR"
            },
            {
                "name": "Ágio Pnévma",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Athanásios",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Geórgios",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Loukás",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Nikólaos",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Pávlos",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Pétros",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Spyrídon",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Vasíleios",
                "countryCode": "GR"
            },
            {
                "name": "Ápsalos",
                "countryCode": "GR"
            },
            {
                "name": "Árnissa",
                "countryCode": "GR"
            },
            {
                "name": "Ássiros",
                "countryCode": "GR"
            },
            {
                "name": "Áthyra",
                "countryCode": "GR"
            },
            {
                "name": "Édessa",
                "countryCode": "GR"
            },
            {
                "name": "Évosmos",
                "countryCode": "GR"
            },
            {
                "name": "Ólynthos",
                "countryCode": "GR"
            },
            {
                "name": "Aianí",
                "countryCode": "GR"
            },
            {
                "name": "Akriní",
                "countryCode": "GR"
            },
            {
                "name": "Ammochóri",
                "countryCode": "GR"
            },
            {
                "name": "Amýntaio",
                "countryCode": "GR"
            },
            {
                "name": "Anaráchi",
                "countryCode": "GR"
            },
            {
                "name": "Argos Orestiko",
                "countryCode": "GR"
            },
            {
                "name": "Chlói",
                "countryCode": "GR"
            },
            {
                "name": "Deskáti",
                "countryCode": "GR"
            },
            {
                "name": "Empório",
                "countryCode": "GR"
            },
            {
                "name": "Erátyra",
                "countryCode": "GR"
            },
            {
                "name": "Filótas",
                "countryCode": "GR"
            },
            {
                "name": "Flórina",
                "countryCode": "GR"
            },
            {
                "name": "Galatiní",
                "countryCode": "GR"
            },
            {
                "name": "Grevená",
                "countryCode": "GR"
            },
            {
                "name": "Kastoria",
                "countryCode": "GR"
            },
            {
                "name": "Kleítos",
                "countryCode": "GR"
            },
            {
                "name": "Komniná",
                "countryCode": "GR"
            },
            {
                "name": "Kozáni",
                "countryCode": "GR"
            },
            {
                "name": "Koíla",
                "countryCode": "GR"
            },
            {
                "name": "Krókos",
                "countryCode": "GR"
            },
            {
                "name": "Laimós",
                "countryCode": "GR"
            },
            {
                "name": "Livaderó",
                "countryCode": "GR"
            },
            {
                "name": "Léchovo",
                "countryCode": "GR"
            },
            {
                "name": "Maniákoi",
                "countryCode": "GR"
            },
            {
                "name": "Mavrochóri",
                "countryCode": "GR"
            },
            {
                "name": "Melíti",
                "countryCode": "GR"
            },
            {
                "name": "Mesopotamía",
                "countryCode": "GR"
            },
            {
                "name": "Nea Lava",
                "countryCode": "GR"
            },
            {
                "name": "Nestório",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Kozánis",
                "countryCode": "GR"
            },
            {
                "name": "Platanórevma",
                "countryCode": "GR"
            },
            {
                "name": "Ptolemaḯda",
                "countryCode": "GR"
            },
            {
                "name": "Siátista",
                "countryCode": "GR"
            },
            {
                "name": "Sérvia",
                "countryCode": "GR"
            },
            {
                "name": "Tsotíli",
                "countryCode": "GR"
            },
            {
                "name": "Velventós",
                "countryCode": "GR"
            },
            {
                "name": "Xinó Neró",
                "countryCode": "GR"
            },
            {
                "name": "Áno Kómi",
                "countryCode": "GR"
            },
            {
                "name": "Agía Kyriakí",
                "countryCode": "GR"
            },
            {
                "name": "Anatolí",
                "countryCode": "GR"
            },
            {
                "name": "Anéza",
                "countryCode": "GR"
            },
            {
                "name": "Asprángeloi",
                "countryCode": "GR"
            },
            {
                "name": "Chalkiádes",
                "countryCode": "GR"
            },
            {
                "name": "Eksochí",
                "countryCode": "GR"
            },
            {
                "name": "Eleoúsa",
                "countryCode": "GR"
            },
            {
                "name": "Filippiáda",
                "countryCode": "GR"
            },
            {
                "name": "Filiátes",
                "countryCode": "GR"
            },
            {
                "name": "Graikochóri",
                "countryCode": "GR"
            },
            {
                "name": "Grammenítsa",
                "countryCode": "GR"
            },
            {
                "name": "Igoumenítsa",
                "countryCode": "GR"
            },
            {
                "name": "Ioánnina",
                "countryCode": "GR"
            },
            {
                "name": "Kalamiá",
                "countryCode": "GR"
            },
            {
                "name": "Kalpáki",
                "countryCode": "GR"
            },
            {
                "name": "Kanaláki",
                "countryCode": "GR"
            },
            {
                "name": "Kardamítsia",
                "countryCode": "GR"
            },
            {
                "name": "Katsikás",
                "countryCode": "GR"
            },
            {
                "name": "Kompóti",
                "countryCode": "GR"
            },
            {
                "name": "Kostakioí",
                "countryCode": "GR"
            },
            {
                "name": "Koutselió",
                "countryCode": "GR"
            },
            {
                "name": "Kónitsa",
                "countryCode": "GR"
            },
            {
                "name": "Loúros",
                "countryCode": "GR"
            },
            {
                "name": "Metsovo",
                "countryCode": "GR"
            },
            {
                "name": "Neochorópoulo",
                "countryCode": "GR"
            },
            {
                "name": "Neochóri",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Ioannínon",
                "countryCode": "GR"
            },
            {
                "name": "Néa Seléfkeia",
                "countryCode": "GR"
            },
            {
                "name": "Néos Oropós",
                "countryCode": "GR"
            },
            {
                "name": "Pappadátes",
                "countryCode": "GR"
            },
            {
                "name": "Paramythiá",
                "countryCode": "GR"
            },
            {
                "name": "Parapótamos",
                "countryCode": "GR"
            },
            {
                "name": "Pediní",
                "countryCode": "GR"
            },
            {
                "name": "Platariá",
                "countryCode": "GR"
            },
            {
                "name": "Prámanta",
                "countryCode": "GR"
            },
            {
                "name": "Préveza",
                "countryCode": "GR"
            },
            {
                "name": "Párga",
                "countryCode": "GR"
            },
            {
                "name": "Pérama",
                "countryCode": "GR"
            },
            {
                "name": "Pérdika",
                "countryCode": "GR"
            },
            {
                "name": "Péta",
                "countryCode": "GR"
            },
            {
                "name": "Rodotópi",
                "countryCode": "GR"
            },
            {
                "name": "Stavráki",
                "countryCode": "GR"
            },
            {
                "name": "Thesprotikó",
                "countryCode": "GR"
            },
            {
                "name": "Tsiflikópoulo",
                "countryCode": "GR"
            },
            {
                "name": "Voulgaréli",
                "countryCode": "GR"
            },
            {
                "name": "Vounoplagiá",
                "countryCode": "GR"
            },
            {
                "name": "Áno Kalentíni",
                "countryCode": "GR"
            },
            {
                "name": "Árta",
                "countryCode": "GR"
            },
            {
                "name": "Acharávi",
                "countryCode": "GR"
            },
            {
                "name": "Agios Georgis",
                "countryCode": "GR"
            },
            {
                "name": "Alepoú",
                "countryCode": "GR"
            },
            {
                "name": "Ambelókipoi",
                "countryCode": "GR"
            },
            {
                "name": "Argostólion",
                "countryCode": "GR"
            },
            {
                "name": "Corfu",
                "countryCode": "GR"
            },
            {
                "name": "Gaïtánion",
                "countryCode": "GR"
            },
            {
                "name": "Gáïos",
                "countryCode": "GR"
            },
            {
                "name": "Itháki",
                "countryCode": "GR"
            },
            {
                "name": "Kanáli",
                "countryCode": "GR"
            },
            {
                "name": "Katastárion",
                "countryCode": "GR"
            },
            {
                "name": "Kontokáli",
                "countryCode": "GR"
            },
            {
                "name": "Kynopiástes",
                "countryCode": "GR"
            },
            {
                "name": "Lefkada",
                "countryCode": "GR"
            },
            {
                "name": "Lefkímmi",
                "countryCode": "GR"
            },
            {
                "name": "Lithakiá",
                "countryCode": "GR"
            },
            {
                "name": "Lixoúri",
                "countryCode": "GR"
            },
            {
                "name": "Mouzaki",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Kerkýras",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Zakýnthou",
                "countryCode": "GR"
            },
            {
                "name": "Perama",
                "countryCode": "GR"
            },
            {
                "name": "Perivóli",
                "countryCode": "GR"
            },
            {
                "name": "Potamós",
                "countryCode": "GR"
            },
            {
                "name": "Póros",
                "countryCode": "GR"
            },
            {
                "name": "Sámi",
                "countryCode": "GR"
            },
            {
                "name": "Valsamáta",
                "countryCode": "GR"
            },
            {
                "name": "Vanáton",
                "countryCode": "GR"
            },
            {
                "name": "Virós",
                "countryCode": "GR"
            },
            {
                "name": "Zakynthos",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Matthaíos",
                "countryCode": "GR"
            },
            {
                "name": "Agrínio",
                "countryCode": "GR"
            },
            {
                "name": "Aitolikó",
                "countryCode": "GR"
            },
            {
                "name": "Akráta",
                "countryCode": "GR"
            },
            {
                "name": "Aktaío",
                "countryCode": "GR"
            },
            {
                "name": "Alfeioúsa",
                "countryCode": "GR"
            },
            {
                "name": "Amaliáda",
                "countryCode": "GR"
            },
            {
                "name": "Amfilochía",
                "countryCode": "GR"
            },
            {
                "name": "Andravída",
                "countryCode": "GR"
            },
            {
                "name": "Angelókastro",
                "countryCode": "GR"
            },
            {
                "name": "Antirrio",
                "countryCode": "GR"
            },
            {
                "name": "Archaía Olympía",
                "countryCode": "GR"
            },
            {
                "name": "Archontochóri",
                "countryCode": "GR"
            },
            {
                "name": "Arkoúdi",
                "countryCode": "GR"
            },
            {
                "name": "Astakós",
                "countryCode": "GR"
            },
            {
                "name": "Aígio",
                "countryCode": "GR"
            },
            {
                "name": "Aíyira",
                "countryCode": "GR"
            },
            {
                "name": "Chalandrítsa",
                "countryCode": "GR"
            },
            {
                "name": "Chávari",
                "countryCode": "GR"
            },
            {
                "name": "Dhokímion",
                "countryCode": "GR"
            },
            {
                "name": "Epitálio",
                "countryCode": "GR"
            },
            {
                "name": "Fyteíes",
                "countryCode": "GR"
            },
            {
                "name": "Galatás",
                "countryCode": "GR"
            },
            {
                "name": "Gastoúni",
                "countryCode": "GR"
            },
            {
                "name": "Gavaloú",
                "countryCode": "GR"
            },
            {
                "name": "Goúmero",
                "countryCode": "GR"
            },
            {
                "name": "Kainoúryion",
                "countryCode": "GR"
            },
            {
                "name": "Kalávryta",
                "countryCode": "GR"
            },
            {
                "name": "Kamárai",
                "countryCode": "GR"
            },
            {
                "name": "Kandíla",
                "countryCode": "GR"
            },
            {
                "name": "Kardamás",
                "countryCode": "GR"
            },
            {
                "name": "Karátoula",
                "countryCode": "GR"
            },
            {
                "name": "Katochí",
                "countryCode": "GR"
            },
            {
                "name": "Katoúna",
                "countryCode": "GR"
            },
            {
                "name": "Kavásila",
                "countryCode": "GR"
            },
            {
                "name": "Kréstena",
                "countryCode": "GR"
            },
            {
                "name": "Kríkellos",
                "countryCode": "GR"
            },
            {
                "name": "Kyllíni",
                "countryCode": "GR"
            },
            {
                "name": "Káto Achaḯa",
                "countryCode": "GR"
            },
            {
                "name": "Káto Mazaráki",
                "countryCode": "GR"
            },
            {
                "name": "Lechainá",
                "countryCode": "GR"
            },
            {
                "name": "Lepenoú",
                "countryCode": "GR"
            },
            {
                "name": "Limnokhórion",
                "countryCode": "GR"
            },
            {
                "name": "Lálas",
                "countryCode": "GR"
            },
            {
                "name": "Lápas",
                "countryCode": "GR"
            },
            {
                "name": "Makrísia",
                "countryCode": "GR"
            },
            {
                "name": "Manoláda",
                "countryCode": "GR"
            },
            {
                "name": "Mataránga",
                "countryCode": "GR"
            },
            {
                "name": "Megáli Khóra",
                "countryCode": "GR"
            },
            {
                "name": "Menídi",
                "countryCode": "GR"
            },
            {
                "name": "Mesolóngi",
                "countryCode": "GR"
            },
            {
                "name": "Mindilóglion",
                "countryCode": "GR"
            },
            {
                "name": "Monastiráki",
                "countryCode": "GR"
            },
            {
                "name": "Myrsíni",
                "countryCode": "GR"
            },
            {
                "name": "Neochóri",
                "countryCode": "GR"
            },
            {
                "name": "Neochórion",
                "countryCode": "GR"
            },
            {
                "name": "Neápolis",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Achaḯas",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Aitolías kai Akarnanías",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Ileías",
                "countryCode": "GR"
            },
            {
                "name": "Náfpaktos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Manoláda",
                "countryCode": "GR"
            },
            {
                "name": "Ovriá",
                "countryCode": "GR"
            },
            {
                "name": "Panaitólion",
                "countryCode": "GR"
            },
            {
                "name": "Pappadhátai",
                "countryCode": "GR"
            },
            {
                "name": "Paralía",
                "countryCode": "GR"
            },
            {
                "name": "Paravóla",
                "countryCode": "GR"
            },
            {
                "name": "Pelópi",
                "countryCode": "GR"
            },
            {
                "name": "Plátanos",
                "countryCode": "GR"
            },
            {
                "name": "Pálairos",
                "countryCode": "GR"
            },
            {
                "name": "Pátra",
                "countryCode": "GR"
            },
            {
                "name": "Pýrgos",
                "countryCode": "GR"
            },
            {
                "name": "Rododáfni",
                "countryCode": "GR"
            },
            {
                "name": "Royítika",
                "countryCode": "GR"
            },
            {
                "name": "Río",
                "countryCode": "GR"
            },
            {
                "name": "Sardínia",
                "countryCode": "GR"
            },
            {
                "name": "Savália",
                "countryCode": "GR"
            },
            {
                "name": "Stános",
                "countryCode": "GR"
            },
            {
                "name": "Sylivainiótika",
                "countryCode": "GR"
            },
            {
                "name": "Synoikismós Chavaríou",
                "countryCode": "GR"
            },
            {
                "name": "Thérmo",
                "countryCode": "GR"
            },
            {
                "name": "Triandaíika",
                "countryCode": "GR"
            },
            {
                "name": "Témeni",
                "countryCode": "GR"
            },
            {
                "name": "Vartholomió",
                "countryCode": "GR"
            },
            {
                "name": "Varvásaina",
                "countryCode": "GR"
            },
            {
                "name": "Vrachnaíika",
                "countryCode": "GR"
            },
            {
                "name": "Várda",
                "countryCode": "GR"
            },
            {
                "name": "Vónitsa",
                "countryCode": "GR"
            },
            {
                "name": "Zacháro",
                "countryCode": "GR"
            },
            {
                "name": "Áno Kastrítsi",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Konstandínos",
                "countryCode": "GR"
            },
            {
                "name": "Afrátion",
                "countryCode": "GR"
            },
            {
                "name": "Agía Triáda",
                "countryCode": "GR"
            },
            {
                "name": "Aidipsós",
                "countryCode": "GR"
            },
            {
                "name": "Akraifnía",
                "countryCode": "GR"
            },
            {
                "name": "Alivéri",
                "countryCode": "GR"
            },
            {
                "name": "Alíartos",
                "countryCode": "GR"
            },
            {
                "name": "Amfíkleia",
                "countryCode": "GR"
            },
            {
                "name": "Amárynthos",
                "countryCode": "GR"
            },
            {
                "name": "Anthíli",
                "countryCode": "GR"
            },
            {
                "name": "Antikyra",
                "countryCode": "GR"
            },
            {
                "name": "Aráchova",
                "countryCode": "GR"
            },
            {
                "name": "Asopía",
                "countryCode": "GR"
            },
            {
                "name": "Atalánti",
                "countryCode": "GR"
            },
            {
                "name": "Chairóneia",
                "countryCode": "GR"
            },
            {
                "name": "Chalkída",
                "countryCode": "GR"
            },
            {
                "name": "Delphi",
                "countryCode": "GR"
            },
            {
                "name": "Dhrosiá",
                "countryCode": "GR"
            },
            {
                "name": "Domokós",
                "countryCode": "GR"
            },
            {
                "name": "Domvraína",
                "countryCode": "GR"
            },
            {
                "name": "Dílesi",
                "countryCode": "GR"
            },
            {
                "name": "Dístomo",
                "countryCode": "GR"
            },
            {
                "name": "Eláteia",
                "countryCode": "GR"
            },
            {
                "name": "Erétria",
                "countryCode": "GR"
            },
            {
                "name": "Fáros",
                "countryCode": "GR"
            },
            {
                "name": "Fílla",
                "countryCode": "GR"
            },
            {
                "name": "Galaxídhion",
                "countryCode": "GR"
            },
            {
                "name": "Istiaía",
                "countryCode": "GR"
            },
            {
                "name": "Itéa",
                "countryCode": "GR"
            },
            {
                "name": "Kainoúryion",
                "countryCode": "GR"
            },
            {
                "name": "Kaména Voúrla",
                "countryCode": "GR"
            },
            {
                "name": "Kaparéllion",
                "countryCode": "GR"
            },
            {
                "name": "Karpenísi",
                "countryCode": "GR"
            },
            {
                "name": "Kastélla",
                "countryCode": "GR"
            },
            {
                "name": "Kerasochóri",
                "countryCode": "GR"
            },
            {
                "name": "Kyriáki",
                "countryCode": "GR"
            },
            {
                "name": "Kárystos",
                "countryCode": "GR"
            },
            {
                "name": "Káto Tithoréa",
                "countryCode": "GR"
            },
            {
                "name": "Kírra",
                "countryCode": "GR"
            },
            {
                "name": "Kými",
                "countryCode": "GR"
            },
            {
                "name": "Lamía",
                "countryCode": "GR"
            },
            {
                "name": "Lianokládhion",
                "countryCode": "GR"
            },
            {
                "name": "Lidoríki",
                "countryCode": "GR"
            },
            {
                "name": "Livadeiá",
                "countryCode": "GR"
            },
            {
                "name": "Livanátes",
                "countryCode": "GR"
            },
            {
                "name": "Loukísia",
                "countryCode": "GR"
            },
            {
                "name": "Loutrá Aidhipsoú",
                "countryCode": "GR"
            },
            {
                "name": "Límni",
                "countryCode": "GR"
            },
            {
                "name": "Magoúla",
                "countryCode": "GR"
            },
            {
                "name": "Makrakómi",
                "countryCode": "GR"
            },
            {
                "name": "Malakónta",
                "countryCode": "GR"
            },
            {
                "name": "Malesína",
                "countryCode": "GR"
            },
            {
                "name": "Mantoúdi",
                "countryCode": "GR"
            },
            {
                "name": "Marmárion",
                "countryCode": "GR"
            },
            {
                "name": "Martínon",
                "countryCode": "GR"
            },
            {
                "name": "Mytikas",
                "countryCode": "GR"
            },
            {
                "name": "Mólos",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Evrytanías",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Fokídos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Artáki",
                "countryCode": "GR"
            },
            {
                "name": "Néa Lámpsakos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Stíra",
                "countryCode": "GR"
            },
            {
                "name": "Néon Monastírion",
                "countryCode": "GR"
            },
            {
                "name": "Oinófyta",
                "countryCode": "GR"
            },
            {
                "name": "Omvriakí",
                "countryCode": "GR"
            },
            {
                "name": "Orchomenós",
                "countryCode": "GR"
            },
            {
                "name": "Oreoí",
                "countryCode": "GR"
            },
            {
                "name": "Oxílithos",
                "countryCode": "GR"
            },
            {
                "name": "Paralía Avlídhos",
                "countryCode": "GR"
            },
            {
                "name": "Pelasgía",
                "countryCode": "GR"
            },
            {
                "name": "Pláka Dílesi",
                "countryCode": "GR"
            },
            {
                "name": "Politiká",
                "countryCode": "GR"
            },
            {
                "name": "Prokópi",
                "countryCode": "GR"
            },
            {
                "name": "Psachná",
                "countryCode": "GR"
            },
            {
                "name": "Rodhítsa",
                "countryCode": "GR"
            },
            {
                "name": "Roviés",
                "countryCode": "GR"
            },
            {
                "name": "Schimatári",
                "countryCode": "GR"
            },
            {
                "name": "Skýros",
                "countryCode": "GR"
            },
            {
                "name": "Spercheiáda",
                "countryCode": "GR"
            },
            {
                "name": "Stavrós",
                "countryCode": "GR"
            },
            {
                "name": "Steíri",
                "countryCode": "GR"
            },
            {
                "name": "Stylída",
                "countryCode": "GR"
            },
            {
                "name": "Thespiés",
                "countryCode": "GR"
            },
            {
                "name": "Thívai",
                "countryCode": "GR"
            },
            {
                "name": "Vasilikón",
                "countryCode": "GR"
            },
            {
                "name": "Vathí",
                "countryCode": "GR"
            },
            {
                "name": "Vágia",
                "countryCode": "GR"
            },
            {
                "name": "Yimnón",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Geórgios",
                "countryCode": "GR"
            },
            {
                "name": "Ámfissa",
                "countryCode": "GR"
            },
            {
                "name": "Árma",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Konstandínos",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Nikólaos",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Thomás",
                "countryCode": "GR"
            },
            {
                "name": "Acharnés",
                "countryCode": "GR"
            },
            {
                "name": "Aegina",
                "countryCode": "GR"
            },
            {
                "name": "Afidnés",
                "countryCode": "GR"
            },
            {
                "name": "Aghios Panteleímon",
                "countryCode": "GR"
            },
            {
                "name": "Agios Dimitrios",
                "countryCode": "GR"
            },
            {
                "name": "Agios Dimítrios Kropiás",
                "countryCode": "GR"
            },
            {
                "name": "Agios Ioannis Rentis",
                "countryCode": "GR"
            },
            {
                "name": "Agía Marína",
                "countryCode": "GR"
            },
            {
                "name": "Agía Paraskeví",
                "countryCode": "GR"
            },
            {
                "name": "Agía Varvára",
                "countryCode": "GR"
            },
            {
                "name": "Aigáleo",
                "countryCode": "GR"
            },
            {
                "name": "Aiánteio",
                "countryCode": "GR"
            },
            {
                "name": "Ampelákia",
                "countryCode": "GR"
            },
            {
                "name": "Anoixi",
                "countryCode": "GR"
            },
            {
                "name": "Anthoúsa",
                "countryCode": "GR"
            },
            {
                "name": "Anávyssos",
                "countryCode": "GR"
            },
            {
                "name": "Argithéa",
                "countryCode": "GR"
            },
            {
                "name": "Argyroúpoli",
                "countryCode": "GR"
            },
            {
                "name": "Artémida",
                "countryCode": "GR"
            },
            {
                "name": "Asprópyrgos",
                "countryCode": "GR"
            },
            {
                "name": "Athens",
                "countryCode": "GR"
            },
            {
                "name": "Avlónas",
                "countryCode": "GR"
            },
            {
                "name": "Chaïdári",
                "countryCode": "GR"
            },
            {
                "name": "Cholargós",
                "countryCode": "GR"
            },
            {
                "name": "Dhafní",
                "countryCode": "GR"
            },
            {
                "name": "Dhráfi",
                "countryCode": "GR"
            },
            {
                "name": "Dióni",
                "countryCode": "GR"
            },
            {
                "name": "Diónysos",
                "countryCode": "GR"
            },
            {
                "name": "Drapetsóna",
                "countryCode": "GR"
            },
            {
                "name": "Drosiá",
                "countryCode": "GR"
            },
            {
                "name": "Ekáli",
                "countryCode": "GR"
            },
            {
                "name": "Elefsína",
                "countryCode": "GR"
            },
            {
                "name": "Ellinikó",
                "countryCode": "GR"
            },
            {
                "name": "Erythrés",
                "countryCode": "GR"
            },
            {
                "name": "Filothéi",
                "countryCode": "GR"
            },
            {
                "name": "Fylí",
                "countryCode": "GR"
            },
            {
                "name": "Galatás",
                "countryCode": "GR"
            },
            {
                "name": "Galátsi",
                "countryCode": "GR"
            },
            {
                "name": "Glyfáda",
                "countryCode": "GR"
            },
            {
                "name": "Grammatikó",
                "countryCode": "GR"
            },
            {
                "name": "Gérakas",
                "countryCode": "GR"
            },
            {
                "name": "Ilioúpoli",
                "countryCode": "GR"
            },
            {
                "name": "Irákleio",
                "countryCode": "GR"
            },
            {
                "name": "Kaisarianí",
                "countryCode": "GR"
            },
            {
                "name": "Kallithéa",
                "countryCode": "GR"
            },
            {
                "name": "Kalývia Thorikoú",
                "countryCode": "GR"
            },
            {
                "name": "Kamaterón",
                "countryCode": "GR"
            },
            {
                "name": "Kapandríti",
                "countryCode": "GR"
            },
            {
                "name": "Karellás",
                "countryCode": "GR"
            },
            {
                "name": "Keratsíni",
                "countryCode": "GR"
            },
            {
                "name": "Keratéa",
                "countryCode": "GR"
            },
            {
                "name": "Khalkoútsion",
                "countryCode": "GR"
            },
            {
                "name": "Khalándrion",
                "countryCode": "GR"
            },
            {
                "name": "Kifisiá",
                "countryCode": "GR"
            },
            {
                "name": "Kinéta",
                "countryCode": "GR"
            },
            {
                "name": "Kipséli",
                "countryCode": "GR"
            },
            {
                "name": "Koropí",
                "countryCode": "GR"
            },
            {
                "name": "Korydallós",
                "countryCode": "GR"
            },
            {
                "name": "Kouvarás",
                "countryCode": "GR"
            },
            {
                "name": "Kryonéri",
                "countryCode": "GR"
            },
            {
                "name": "Kypséli",
                "countryCode": "GR"
            },
            {
                "name": "Kálamos",
                "countryCode": "GR"
            },
            {
                "name": "Káto Soúlion",
                "countryCode": "GR"
            },
            {
                "name": "Kítsi",
                "countryCode": "GR"
            },
            {
                "name": "Kýthira",
                "countryCode": "GR"
            },
            {
                "name": "Leondárion",
                "countryCode": "GR"
            },
            {
                "name": "Limín Mesoyaías",
                "countryCode": "GR"
            },
            {
                "name": "Lykóvrysi",
                "countryCode": "GR"
            },
            {
                "name": "Lávrio",
                "countryCode": "GR"
            },
            {
                "name": "Magoúla",
                "countryCode": "GR"
            },
            {
                "name": "Marathónas",
                "countryCode": "GR"
            },
            {
                "name": "Markópoulo",
                "countryCode": "GR"
            },
            {
                "name": "Markópoulo Oropoú",
                "countryCode": "GR"
            },
            {
                "name": "Maroúsi",
                "countryCode": "GR"
            },
            {
                "name": "Megalochóri",
                "countryCode": "GR"
            },
            {
                "name": "Melíssia",
                "countryCode": "GR"
            },
            {
                "name": "Metamórfosi",
                "countryCode": "GR"
            },
            {
                "name": "Moskháton",
                "countryCode": "GR"
            },
            {
                "name": "Mándra",
                "countryCode": "GR"
            },
            {
                "name": "Mégara",
                "countryCode": "GR"
            },
            {
                "name": "Neos Voutzás",
                "countryCode": "GR"
            },
            {
                "name": "Néa Chalkidóna",
                "countryCode": "GR"
            },
            {
                "name": "Néa Erythraía",
                "countryCode": "GR"
            },
            {
                "name": "Néa Filadélfeia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Ionía",
                "countryCode": "GR"
            },
            {
                "name": "Néa Mákri",
                "countryCode": "GR"
            },
            {
                "name": "Néa Palátia",
                "countryCode": "GR"
            },
            {
                "name": "Néa Pentéli",
                "countryCode": "GR"
            },
            {
                "name": "Néa Péramos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Smýrni",
                "countryCode": "GR"
            },
            {
                "name": "Néo Psychikó",
                "countryCode": "GR"
            },
            {
                "name": "Níkaia",
                "countryCode": "GR"
            },
            {
                "name": "Oropós",
                "countryCode": "GR"
            },
            {
                "name": "Paianía",
                "countryCode": "GR"
            },
            {
                "name": "Palaiá Fókaia",
                "countryCode": "GR"
            },
            {
                "name": "Palaió Fáliro",
                "countryCode": "GR"
            },
            {
                "name": "Pallíni",
                "countryCode": "GR"
            },
            {
                "name": "Papágou",
                "countryCode": "GR"
            },
            {
                "name": "Pentéli",
                "countryCode": "GR"
            },
            {
                "name": "Peristéri",
                "countryCode": "GR"
            },
            {
                "name": "Petroúpolis",
                "countryCode": "GR"
            },
            {
                "name": "Pikérmi",
                "countryCode": "GR"
            },
            {
                "name": "Piraeus",
                "countryCode": "GR"
            },
            {
                "name": "Polydéndri",
                "countryCode": "GR"
            },
            {
                "name": "Psychikó",
                "countryCode": "GR"
            },
            {
                "name": "Péfki",
                "countryCode": "GR"
            },
            {
                "name": "Pérama",
                "countryCode": "GR"
            },
            {
                "name": "Póros",
                "countryCode": "GR"
            },
            {
                "name": "Rafína",
                "countryCode": "GR"
            },
            {
                "name": "Rodópoli",
                "countryCode": "GR"
            },
            {
                "name": "Salamína",
                "countryCode": "GR"
            },
            {
                "name": "Saronída",
                "countryCode": "GR"
            },
            {
                "name": "Selínia",
                "countryCode": "GR"
            },
            {
                "name": "Skarmagkás",
                "countryCode": "GR"
            },
            {
                "name": "Skála Oropoú",
                "countryCode": "GR"
            },
            {
                "name": "Spáta",
                "countryCode": "GR"
            },
            {
                "name": "Spétses",
                "countryCode": "GR"
            },
            {
                "name": "Stamáta",
                "countryCode": "GR"
            },
            {
                "name": "Thrakomakedónes",
                "countryCode": "GR"
            },
            {
                "name": "Távros",
                "countryCode": "GR"
            },
            {
                "name": "Varnávas",
                "countryCode": "GR"
            },
            {
                "name": "Varybóbi",
                "countryCode": "GR"
            },
            {
                "name": "Vathý",
                "countryCode": "GR"
            },
            {
                "name": "Vlycháda",
                "countryCode": "GR"
            },
            {
                "name": "Vouliagméni",
                "countryCode": "GR"
            },
            {
                "name": "Voúla",
                "countryCode": "GR"
            },
            {
                "name": "Vraná",
                "countryCode": "GR"
            },
            {
                "name": "Vrilissia",
                "countryCode": "GR"
            },
            {
                "name": "Vári",
                "countryCode": "GR"
            },
            {
                "name": "Vília",
                "countryCode": "GR"
            },
            {
                "name": "Výronas",
                "countryCode": "GR"
            },
            {
                "name": "Ymittos",
                "countryCode": "GR"
            },
            {
                "name": "Zefyri",
                "countryCode": "GR"
            },
            {
                "name": "Zográfos",
                "countryCode": "GR"
            },
            {
                "name": "Ágioi Anárgyroi",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Stéfanos",
                "countryCode": "GR"
            },
            {
                "name": "Álimos",
                "countryCode": "GR"
            },
            {
                "name": "Áno Liósia",
                "countryCode": "GR"
            },
            {
                "name": "Áyioi Apóstoloi",
                "countryCode": "GR"
            },
            {
                "name": "Ílion",
                "countryCode": "GR"
            },
            {
                "name": "Ýdra",
                "countryCode": "GR"
            },
            {
                "name": "Arfará",
                "countryCode": "GR"
            },
            {
                "name": "Aria",
                "countryCode": "GR"
            },
            {
                "name": "Arkhaía Kórinthos",
                "countryCode": "GR"
            },
            {
                "name": "Asopós",
                "countryCode": "GR"
            },
            {
                "name": "Asíni",
                "countryCode": "GR"
            },
            {
                "name": "Athíkia",
                "countryCode": "GR"
            },
            {
                "name": "Ayía Triás",
                "countryCode": "GR"
            },
            {
                "name": "Chóra",
                "countryCode": "GR"
            },
            {
                "name": "Dimitsána",
                "countryCode": "GR"
            },
            {
                "name": "Drépanon",
                "countryCode": "GR"
            },
            {
                "name": "Dídyma",
                "countryCode": "GR"
            },
            {
                "name": "Ermióni",
                "countryCode": "GR"
            },
            {
                "name": "Examília",
                "countryCode": "GR"
            },
            {
                "name": "Filiatrá",
                "countryCode": "GR"
            },
            {
                "name": "Gargaliánoi",
                "countryCode": "GR"
            },
            {
                "name": "Geráki",
                "countryCode": "GR"
            },
            {
                "name": "Géfyra",
                "countryCode": "GR"
            },
            {
                "name": "Gýtheio",
                "countryCode": "GR"
            },
            {
                "name": "Isthmía",
                "countryCode": "GR"
            },
            {
                "name": "Kalamata",
                "countryCode": "GR"
            },
            {
                "name": "Kardamýli",
                "countryCode": "GR"
            },
            {
                "name": "Karyés",
                "countryCode": "GR"
            },
            {
                "name": "Khiliomódhi",
                "countryCode": "GR"
            },
            {
                "name": "Kiáto",
                "countryCode": "GR"
            },
            {
                "name": "Koilás",
                "countryCode": "GR"
            },
            {
                "name": "Kokkónion",
                "countryCode": "GR"
            },
            {
                "name": "Kopanáki",
                "countryCode": "GR"
            },
            {
                "name": "Koróni",
                "countryCode": "GR"
            },
            {
                "name": "Koutsopódi",
                "countryCode": "GR"
            },
            {
                "name": "Kranídi",
                "countryCode": "GR"
            },
            {
                "name": "Krokeés",
                "countryCode": "GR"
            },
            {
                "name": "Kyparissía",
                "countryCode": "GR"
            },
            {
                "name": "Kyrás Vrýsi",
                "countryCode": "GR"
            },
            {
                "name": "Káto Dhiminió",
                "countryCode": "GR"
            },
            {
                "name": "Káto Glykóvrysi",
                "countryCode": "GR"
            },
            {
                "name": "Kórinthos",
                "countryCode": "GR"
            },
            {
                "name": "Langádhia",
                "countryCode": "GR"
            },
            {
                "name": "Leonídio",
                "countryCode": "GR"
            },
            {
                "name": "Levídion",
                "countryCode": "GR"
            },
            {
                "name": "Ligourión",
                "countryCode": "GR"
            },
            {
                "name": "Loutrá Oraías Elénis",
                "countryCode": "GR"
            },
            {
                "name": "Loutráki",
                "countryCode": "GR"
            },
            {
                "name": "Lékhaio",
                "countryCode": "GR"
            },
            {
                "name": "Magoúla",
                "countryCode": "GR"
            },
            {
                "name": "Megalópoli",
                "countryCode": "GR"
            },
            {
                "name": "Meligalás",
                "countryCode": "GR"
            },
            {
                "name": "Messíni",
                "countryCode": "GR"
            },
            {
                "name": "Methóni",
                "countryCode": "GR"
            },
            {
                "name": "Moláoi",
                "countryCode": "GR"
            },
            {
                "name": "Moúlki",
                "countryCode": "GR"
            },
            {
                "name": "Neméa",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Arkadías",
                "countryCode": "GR"
            },
            {
                "name": "Náfplio",
                "countryCode": "GR"
            },
            {
                "name": "Néa Kíos",
                "countryCode": "GR"
            },
            {
                "name": "Néa Tírins",
                "countryCode": "GR"
            },
            {
                "name": "Palaiá Epídavros",
                "countryCode": "GR"
            },
            {
                "name": "Paralía Vérgas",
                "countryCode": "GR"
            },
            {
                "name": "Perachóra",
                "countryCode": "GR"
            },
            {
                "name": "Periyiáli",
                "countryCode": "GR"
            },
            {
                "name": "Pásion",
                "countryCode": "GR"
            },
            {
                "name": "Pórto Chéli",
                "countryCode": "GR"
            },
            {
                "name": "Pýlos",
                "countryCode": "GR"
            },
            {
                "name": "Sikyón",
                "countryCode": "GR"
            },
            {
                "name": "Skála",
                "countryCode": "GR"
            },
            {
                "name": "Sofikón",
                "countryCode": "GR"
            },
            {
                "name": "Sperchógeia",
                "countryCode": "GR"
            },
            {
                "name": "Spárti",
                "countryCode": "GR"
            },
            {
                "name": "Sykiá",
                "countryCode": "GR"
            },
            {
                "name": "Thouría",
                "countryCode": "GR"
            },
            {
                "name": "Tolón",
                "countryCode": "GR"
            },
            {
                "name": "Traganón",
                "countryCode": "GR"
            },
            {
                "name": "Trípoli",
                "countryCode": "GR"
            },
            {
                "name": "Vlachiótis",
                "countryCode": "GR"
            },
            {
                "name": "Vlachópoulo",
                "countryCode": "GR"
            },
            {
                "name": "Vokhaïkó",
                "countryCode": "GR"
            },
            {
                "name": "Vrakháti",
                "countryCode": "GR"
            },
            {
                "name": "Vélo",
                "countryCode": "GR"
            },
            {
                "name": "Xylókastro",
                "countryCode": "GR"
            },
            {
                "name": "Zevgolateió",
                "countryCode": "GR"
            },
            {
                "name": "Ágioi Theódoroi",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Andréas",
                "countryCode": "GR"
            },
            {
                "name": "Árgos",
                "countryCode": "GR"
            },
            {
                "name": "Áris",
                "countryCode": "GR"
            },
            {
                "name": "Ássos",
                "countryCode": "GR"
            },
            {
                "name": "Ástros",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Adhrianós",
                "countryCode": "GR"
            },
            {
                "name": "Áyios Vasílios",
                "countryCode": "GR"
            },
            {
                "name": "Adámas",
                "countryCode": "GR"
            },
            {
                "name": "Afántou",
                "countryCode": "GR"
            },
            {
                "name": "Agía Marína",
                "countryCode": "GR"
            },
            {
                "name": "Amorgós",
                "countryCode": "GR"
            },
            {
                "name": "Andros",
                "countryCode": "GR"
            },
            {
                "name": "Antimácheia",
                "countryCode": "GR"
            },
            {
                "name": "Antíparos",
                "countryCode": "GR"
            },
            {
                "name": "Anáfi",
                "countryCode": "GR"
            },
            {
                "name": "Archángelos",
                "countryCode": "GR"
            },
            {
                "name": "Astypálaia",
                "countryCode": "GR"
            },
            {
                "name": "Chálki",
                "countryCode": "GR"
            },
            {
                "name": "Emporeío",
                "countryCode": "GR"
            },
            {
                "name": "Ermoúpolis",
                "countryCode": "GR"
            },
            {
                "name": "Faliraki",
                "countryCode": "GR"
            },
            {
                "name": "Filótion",
                "countryCode": "GR"
            },
            {
                "name": "Firá",
                "countryCode": "GR"
            },
            {
                "name": "Folégandros",
                "countryCode": "GR"
            },
            {
                "name": "Fry",
                "countryCode": "GR"
            },
            {
                "name": "Ialysós",
                "countryCode": "GR"
            },
            {
                "name": "Kardámaina",
                "countryCode": "GR"
            },
            {
                "name": "Karpathos",
                "countryCode": "GR"
            },
            {
                "name": "Kos",
                "countryCode": "GR"
            },
            {
                "name": "Kremastí",
                "countryCode": "GR"
            },
            {
                "name": "Kálymnos",
                "countryCode": "GR"
            },
            {
                "name": "Kéfalos",
                "countryCode": "GR"
            },
            {
                "name": "Kímolos",
                "countryCode": "GR"
            },
            {
                "name": "Kýthnos",
                "countryCode": "GR"
            },
            {
                "name": "Lakkí",
                "countryCode": "GR"
            },
            {
                "name": "Lárdos",
                "countryCode": "GR"
            },
            {
                "name": "Mandráki",
                "countryCode": "GR"
            },
            {
                "name": "Megálo Chorió",
                "countryCode": "GR"
            },
            {
                "name": "Megísti",
                "countryCode": "GR"
            },
            {
                "name": "Mesariá",
                "countryCode": "GR"
            },
            {
                "name": "Mykonos",
                "countryCode": "GR"
            },
            {
                "name": "Mílos",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Kykládon",
                "countryCode": "GR"
            },
            {
                "name": "Náousa",
                "countryCode": "GR"
            },
            {
                "name": "Náxos",
                "countryCode": "GR"
            },
            {
                "name": "Ornós",
                "countryCode": "GR"
            },
            {
                "name": "Oía",
                "countryCode": "GR"
            },
            {
                "name": "Pylí",
                "countryCode": "GR"
            },
            {
                "name": "Pánormos",
                "countryCode": "GR"
            },
            {
                "name": "Páros",
                "countryCode": "GR"
            },
            {
                "name": "Pátmos",
                "countryCode": "GR"
            },
            {
                "name": "Ródos",
                "countryCode": "GR"
            },
            {
                "name": "Skála",
                "countryCode": "GR"
            },
            {
                "name": "Sérifos",
                "countryCode": "GR"
            },
            {
                "name": "Sými",
                "countryCode": "GR"
            },
            {
                "name": "Tínos",
                "countryCode": "GR"
            },
            {
                "name": "Vári",
                "countryCode": "GR"
            },
            {
                "name": "Zipári",
                "countryCode": "GR"
            },
            {
                "name": "Áno Merá",
                "countryCode": "GR"
            },
            {
                "name": "Áno Sýros",
                "countryCode": "GR"
            },
            {
                "name": "Émponas",
                "countryCode": "GR"
            },
            {
                "name": "Íos",
                "countryCode": "GR"
            },
            {
                "name": "Agía Foteiní",
                "countryCode": "GR"
            },
            {
                "name": "Agía Galíni",
                "countryCode": "GR"
            },
            {
                "name": "Agía Marína",
                "countryCode": "GR"
            },
            {
                "name": "Agía Varvára",
                "countryCode": "GR"
            },
            {
                "name": "Ano Arhanes",
                "countryCode": "GR"
            },
            {
                "name": "Anógeia",
                "countryCode": "GR"
            },
            {
                "name": "Arkalochóri",
                "countryCode": "GR"
            },
            {
                "name": "Asímion",
                "countryCode": "GR"
            },
            {
                "name": "Atsipópoulo",
                "countryCode": "GR"
            },
            {
                "name": "Chaniá",
                "countryCode": "GR"
            },
            {
                "name": "Chóra Sfakíon",
                "countryCode": "GR"
            },
            {
                "name": "Darátsos",
                "countryCode": "GR"
            },
            {
                "name": "Eloúnda",
                "countryCode": "GR"
            },
            {
                "name": "Galatás",
                "countryCode": "GR"
            },
            {
                "name": "Georgioupolis",
                "countryCode": "GR"
            },
            {
                "name": "Geráni",
                "countryCode": "GR"
            },
            {
                "name": "Goúrnes",
                "countryCode": "GR"
            },
            {
                "name": "Gra Liyiá",
                "countryCode": "GR"
            },
            {
                "name": "Gázi",
                "countryCode": "GR"
            },
            {
                "name": "Gérgeri",
                "countryCode": "GR"
            },
            {
                "name": "Ierápetra",
                "countryCode": "GR"
            },
            {
                "name": "Irákleion",
                "countryCode": "GR"
            },
            {
                "name": "Kalýves",
                "countryCode": "GR"
            },
            {
                "name": "Kastrí",
                "countryCode": "GR"
            },
            {
                "name": "Kastélli",
                "countryCode": "GR"
            },
            {
                "name": "Kentrí",
                "countryCode": "GR"
            },
            {
                "name": "Kokkíni Cháni",
                "countryCode": "GR"
            },
            {
                "name": "Kolympári",
                "countryCode": "GR"
            },
            {
                "name": "Kritsá",
                "countryCode": "GR"
            },
            {
                "name": "Krousón",
                "countryCode": "GR"
            },
            {
                "name": "Káto Asítai",
                "countryCode": "GR"
            },
            {
                "name": "Káto Goúves",
                "countryCode": "GR"
            },
            {
                "name": "Kíssamos",
                "countryCode": "GR"
            },
            {
                "name": "Limín Khersonísou",
                "countryCode": "GR"
            },
            {
                "name": "Mokhós",
                "countryCode": "GR"
            },
            {
                "name": "Mourniés",
                "countryCode": "GR"
            },
            {
                "name": "Mouzourás",
                "countryCode": "GR"
            },
            {
                "name": "Moíres",
                "countryCode": "GR"
            },
            {
                "name": "Mália",
                "countryCode": "GR"
            },
            {
                "name": "Nerokoúros",
                "countryCode": "GR"
            },
            {
                "name": "Neápoli",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Irakleíou",
                "countryCode": "GR"
            },
            {
                "name": "Nomós Rethýmnis",
                "countryCode": "GR"
            },
            {
                "name": "Néa Alikarnassós",
                "countryCode": "GR"
            },
            {
                "name": "Néa Anatolí",
                "countryCode": "GR"
            },
            {
                "name": "Palaióchora",
                "countryCode": "GR"
            },
            {
                "name": "Palekastro",
                "countryCode": "GR"
            },
            {
                "name": "Perivólia",
                "countryCode": "GR"
            },
            {
                "name": "Pithári",
                "countryCode": "GR"
            },
            {
                "name": "Profítis Ilías",
                "countryCode": "GR"
            },
            {
                "name": "Pánormos",
                "countryCode": "GR"
            },
            {
                "name": "Pérama",
                "countryCode": "GR"
            },
            {
                "name": "Pýrgos",
                "countryCode": "GR"
            },
            {
                "name": "Rethymno",
                "countryCode": "GR"
            },
            {
                "name": "Schísma Eloúndas",
                "countryCode": "GR"
            },
            {
                "name": "Sitia",
                "countryCode": "GR"
            },
            {
                "name": "Skalánion",
                "countryCode": "GR"
            },
            {
                "name": "Soúda",
                "countryCode": "GR"
            },
            {
                "name": "Stalís",
                "countryCode": "GR"
            },
            {
                "name": "Sísion",
                "countryCode": "GR"
            },
            {
                "name": "Thrapsanón",
                "countryCode": "GR"
            },
            {
                "name": "Tsikalariá",
                "countryCode": "GR"
            },
            {
                "name": "Tympáki",
                "countryCode": "GR"
            },
            {
                "name": "Tílisos",
                "countryCode": "GR"
            },
            {
                "name": "Violí Charáki",
                "countryCode": "GR"
            },
            {
                "name": "Vrýses",
                "countryCode": "GR"
            },
            {
                "name": "Zarós",
                "countryCode": "GR"
            },
            {
                "name": "Zonianá",
                "countryCode": "GR"
            },
            {
                "name": "Ágioi Déka",
                "countryCode": "GR"
            },
            {
                "name": "Ágios Nikólaos",
                "countryCode": "GR"
            }
        ]
    },
    {
        "name": "Greenland",
        "phonecode": "299",
        "cities": []
    },
    {
        "name": "Grenada",
        "phonecode": "+1-473",
        "cities": [
            {
                "name": "Grenville",
                "countryCode": "GD"
            },
            {
                "name": "Saint David’s",
                "countryCode": "GD"
            },
            {
                "name": "Saint George's",
                "countryCode": "GD"
            },
            {
                "name": "Gouyave",
                "countryCode": "GD"
            },
            {
                "name": "Victoria",
                "countryCode": "GD"
            },
            {
                "name": "Sauteurs",
                "countryCode": "GD"
            },
            {
                "name": "Hillsborough",
                "countryCode": "GD"
            }
        ]
    },
    {
        "name": "Guadeloupe",
        "phonecode": "590",
        "cities": []
    },
    {
        "name": "Guam",
        "phonecode": "+1-671",
        "cities": []
    },
    {
        "name": "Guatemala",
        "phonecode": "502",
        "cities": [
            {
                "name": "Cahabón",
                "countryCode": "GT"
            },
            {
                "name": "Chahal Guatemala",
                "countryCode": "GT"
            },
            {
                "name": "Chisec",
                "countryCode": "GT"
            },
            {
                "name": "Cobán",
                "countryCode": "GT"
            },
            {
                "name": "La Tinta",
                "countryCode": "GT"
            },
            {
                "name": "Lanquín",
                "countryCode": "GT"
            },
            {
                "name": "Panzós",
                "countryCode": "GT"
            },
            {
                "name": "San Cristóbal Verapaz",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Chamelco",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Carchá",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz Verapaz",
                "countryCode": "GT"
            },
            {
                "name": "Senahú",
                "countryCode": "GT"
            },
            {
                "name": "Tactic",
                "countryCode": "GT"
            },
            {
                "name": "Tamahú",
                "countryCode": "GT"
            },
            {
                "name": "Tucurú",
                "countryCode": "GT"
            },
            {
                "name": "Cubulco",
                "countryCode": "GT"
            },
            {
                "name": "El Chol",
                "countryCode": "GT"
            },
            {
                "name": "Granados",
                "countryCode": "GT"
            },
            {
                "name": "Purulhá",
                "countryCode": "GT"
            },
            {
                "name": "Rabinal",
                "countryCode": "GT"
            },
            {
                "name": "Salamá",
                "countryCode": "GT"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Chicaj",
                "countryCode": "GT"
            },
            {
                "name": "Acatenango",
                "countryCode": "GT"
            },
            {
                "name": "Chimaltenango",
                "countryCode": "GT"
            },
            {
                "name": "Comalapa",
                "countryCode": "GT"
            },
            {
                "name": "El Tejar",
                "countryCode": "GT"
            },
            {
                "name": "Parramos",
                "countryCode": "GT"
            },
            {
                "name": "Patzicía",
                "countryCode": "GT"
            },
            {
                "name": "Patzún",
                "countryCode": "GT"
            },
            {
                "name": "Pochuta",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés Itzapa",
                "countryCode": "GT"
            },
            {
                "name": "San José Poaquil",
                "countryCode": "GT"
            },
            {
                "name": "San Martín Jilotepeque",
                "countryCode": "GT"
            },
            {
                "name": "Santa Apolonia",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz Balanyá",
                "countryCode": "GT"
            },
            {
                "name": "Tecpán Guatemala",
                "countryCode": "GT"
            },
            {
                "name": "Yepocapa",
                "countryCode": "GT"
            },
            {
                "name": "Zaragoza",
                "countryCode": "GT"
            },
            {
                "name": "Camotán",
                "countryCode": "GT"
            },
            {
                "name": "Chiquimula",
                "countryCode": "GT"
            },
            {
                "name": "Concepción Las Minas",
                "countryCode": "GT"
            },
            {
                "name": "Esquipulas",
                "countryCode": "GT"
            },
            {
                "name": "Ipala",
                "countryCode": "GT"
            },
            {
                "name": "Jocotán",
                "countryCode": "GT"
            },
            {
                "name": "Olopa",
                "countryCode": "GT"
            },
            {
                "name": "Quezaltepeque",
                "countryCode": "GT"
            },
            {
                "name": "San Jacinto",
                "countryCode": "GT"
            },
            {
                "name": "San José La Arada",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Ermita",
                "countryCode": "GT"
            },
            {
                "name": "Escuintla",
                "countryCode": "GT"
            },
            {
                "name": "Guanagazapa",
                "countryCode": "GT"
            },
            {
                "name": "Iztapa",
                "countryCode": "GT"
            },
            {
                "name": "La Democracia",
                "countryCode": "GT"
            },
            {
                "name": "La Gomera",
                "countryCode": "GT"
            },
            {
                "name": "Masagua",
                "countryCode": "GT"
            },
            {
                "name": "Nueva Concepción",
                "countryCode": "GT"
            },
            {
                "name": "Palín",
                "countryCode": "GT"
            },
            {
                "name": "Puerto San José",
                "countryCode": "GT"
            },
            {
                "name": "San Vicente Pacaya",
                "countryCode": "GT"
            },
            {
                "name": "Santa Lucía Cotzumalguapa",
                "countryCode": "GT"
            },
            {
                "name": "Siquinalá",
                "countryCode": "GT"
            },
            {
                "name": "Tiquisate",
                "countryCode": "GT"
            },
            {
                "name": "Amatitlán",
                "countryCode": "GT"
            },
            {
                "name": "Chinautla",
                "countryCode": "GT"
            },
            {
                "name": "Chuarrancho",
                "countryCode": "GT"
            },
            {
                "name": "Fraijanes",
                "countryCode": "GT"
            },
            {
                "name": "Guatemala City",
                "countryCode": "GT"
            },
            {
                "name": "Mixco",
                "countryCode": "GT"
            },
            {
                "name": "Palencia",
                "countryCode": "GT"
            },
            {
                "name": "Petapa",
                "countryCode": "GT"
            },
            {
                "name": "San José Pinula",
                "countryCode": "GT"
            },
            {
                "name": "San José del Golfo",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Ayampuc",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Raimundo",
                "countryCode": "GT"
            },
            {
                "name": "Santa Catarina Pinula",
                "countryCode": "GT"
            },
            {
                "name": "Villa Canales",
                "countryCode": "GT"
            },
            {
                "name": "Villa Nueva",
                "countryCode": "GT"
            },
            {
                "name": "Aguacatán",
                "countryCode": "GT"
            },
            {
                "name": "Barillas",
                "countryCode": "GT"
            },
            {
                "name": "Chiantla",
                "countryCode": "GT"
            },
            {
                "name": "Colotenango",
                "countryCode": "GT"
            },
            {
                "name": "Concepción Huista",
                "countryCode": "GT"
            },
            {
                "name": "Cuilco",
                "countryCode": "GT"
            },
            {
                "name": "Huehuetenango",
                "countryCode": "GT"
            },
            {
                "name": "Ixtahuacán",
                "countryCode": "GT"
            },
            {
                "name": "Jacaltenango",
                "countryCode": "GT"
            },
            {
                "name": "La Libertad",
                "countryCode": "GT"
            },
            {
                "name": "Malacatancito",
                "countryCode": "GT"
            },
            {
                "name": "Nentón",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Huista",
                "countryCode": "GT"
            },
            {
                "name": "San Gaspar Ixchil",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Atitán",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Ixcoy",
                "countryCode": "GT"
            },
            {
                "name": "San Mateo Ixtatán",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Acatán",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Necta",
                "countryCode": "GT"
            },
            {
                "name": "San Rafael La Independencia",
                "countryCode": "GT"
            },
            {
                "name": "San Rafael Petzal",
                "countryCode": "GT"
            },
            {
                "name": "San Sebastián Coatán",
                "countryCode": "GT"
            },
            {
                "name": "San Sebastián Huehuetenango",
                "countryCode": "GT"
            },
            {
                "name": "Santa Ana Huista",
                "countryCode": "GT"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "GT"
            },
            {
                "name": "Santa Eulalia",
                "countryCode": "GT"
            },
            {
                "name": "Santiago Chimaltenango",
                "countryCode": "GT"
            },
            {
                "name": "Soloma",
                "countryCode": "GT"
            },
            {
                "name": "Tectitán",
                "countryCode": "GT"
            },
            {
                "name": "Todos Santos Cuchumatán",
                "countryCode": "GT"
            },
            {
                "name": "El Estor",
                "countryCode": "GT"
            },
            {
                "name": "Los Amates",
                "countryCode": "GT"
            },
            {
                "name": "Lívingston",
                "countryCode": "GT"
            },
            {
                "name": "Morales",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Morales",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Puerto Barrios",
                "countryCode": "GT"
            },
            {
                "name": "Puerto Barrios",
                "countryCode": "GT"
            },
            {
                "name": "Jalapa",
                "countryCode": "GT"
            },
            {
                "name": "Mataquescuintla",
                "countryCode": "GT"
            },
            {
                "name": "Monjas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Jalapa",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Mataquescuintla",
                "countryCode": "GT"
            },
            {
                "name": "San Luis Jilotepeque",
                "countryCode": "GT"
            },
            {
                "name": "San Manuel Chaparrón",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Pinula",
                "countryCode": "GT"
            },
            {
                "name": "Agua Blanca",
                "countryCode": "GT"
            },
            {
                "name": "Asunción Mita",
                "countryCode": "GT"
            },
            {
                "name": "Atescatempa",
                "countryCode": "GT"
            },
            {
                "name": "Comapa",
                "countryCode": "GT"
            },
            {
                "name": "Conguaco",
                "countryCode": "GT"
            },
            {
                "name": "El Adelanto",
                "countryCode": "GT"
            },
            {
                "name": "El Progreso",
                "countryCode": "GT"
            },
            {
                "name": "Jalpatagua",
                "countryCode": "GT"
            },
            {
                "name": "Jerez",
                "countryCode": "GT"
            },
            {
                "name": "Jutiapa",
                "countryCode": "GT"
            },
            {
                "name": "Moyuta",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Asunción Mita",
                "countryCode": "GT"
            },
            {
                "name": "Pasaco",
                "countryCode": "GT"
            },
            {
                "name": "Quesada",
                "countryCode": "GT"
            },
            {
                "name": "San José Acatempa",
                "countryCode": "GT"
            },
            {
                "name": "Santa Catarina Mita",
                "countryCode": "GT"
            },
            {
                "name": "Yupiltepeque",
                "countryCode": "GT"
            },
            {
                "name": "Zapotitlán",
                "countryCode": "GT"
            },
            {
                "name": "Dolores",
                "countryCode": "GT"
            },
            {
                "name": "Flores",
                "countryCode": "GT"
            },
            {
                "name": "La Libertad",
                "countryCode": "GT"
            },
            {
                "name": "Melchor de Mencos",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Flores",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Poptún",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Andrés",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Benito",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Francisco",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa Ana",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Sayaxché",
                "countryCode": "GT"
            },
            {
                "name": "Poptún",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés",
                "countryCode": "GT"
            },
            {
                "name": "San Benito",
                "countryCode": "GT"
            },
            {
                "name": "San Francisco",
                "countryCode": "GT"
            },
            {
                "name": "San José",
                "countryCode": "GT"
            },
            {
                "name": "San Luis",
                "countryCode": "GT"
            },
            {
                "name": "Santa Ana",
                "countryCode": "GT"
            },
            {
                "name": "Sayaxché",
                "countryCode": "GT"
            },
            {
                "name": "El Jícaro",
                "countryCode": "GT"
            },
            {
                "name": "Guastatoya",
                "countryCode": "GT"
            },
            {
                "name": "Morazán",
                "countryCode": "GT"
            },
            {
                "name": "San Agustín Acasaguastlán",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio La Paz",
                "countryCode": "GT"
            },
            {
                "name": "San Cristóbal Acasaguastlán",
                "countryCode": "GT"
            },
            {
                "name": "Sanarate",
                "countryCode": "GT"
            },
            {
                "name": "Sansare",
                "countryCode": "GT"
            },
            {
                "name": "Canillá",
                "countryCode": "GT"
            },
            {
                "name": "Chajul",
                "countryCode": "GT"
            },
            {
                "name": "Chicamán",
                "countryCode": "GT"
            },
            {
                "name": "Chichicastenango",
                "countryCode": "GT"
            },
            {
                "name": "Chiché",
                "countryCode": "GT"
            },
            {
                "name": "Chinique",
                "countryCode": "GT"
            },
            {
                "name": "Cunén",
                "countryCode": "GT"
            },
            {
                "name": "Joyabaj",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Canillá",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chajul",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chicaman",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chichicastenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chiché",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chinique",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Cunén",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Ixcan",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Joyabaj",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Pachalum",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Patzité",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Andrés Sajcabajá",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Antonio Ilotenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Juan Cotzal",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Pedro Jocopilas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Uspantán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Zacualpa",
                "countryCode": "GT"
            },
            {
                "name": "Nebaj",
                "countryCode": "GT"
            },
            {
                "name": "Pachalum",
                "countryCode": "GT"
            },
            {
                "name": "Patzité",
                "countryCode": "GT"
            },
            {
                "name": "Playa Grande",
                "countryCode": "GT"
            },
            {
                "name": "Sacapulas",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés Sajcabajá",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Ilotenango",
                "countryCode": "GT"
            },
            {
                "name": "San Bartolomé Jocotenango",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Cotzal",
                "countryCode": "GT"
            },
            {
                "name": "San Luis Ixcán",
                "countryCode": "GT"
            },
            {
                "name": "San Pédro Jocopilas",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz del Quiché",
                "countryCode": "GT"
            },
            {
                "name": "Uspantán",
                "countryCode": "GT"
            },
            {
                "name": "Zacualpa",
                "countryCode": "GT"
            },
            {
                "name": "Almolonga",
                "countryCode": "GT"
            },
            {
                "name": "Cabricán",
                "countryCode": "GT"
            },
            {
                "name": "Cajolá",
                "countryCode": "GT"
            },
            {
                "name": "Cantel",
                "countryCode": "GT"
            },
            {
                "name": "Coatepeque",
                "countryCode": "GT"
            },
            {
                "name": "Colomba",
                "countryCode": "GT"
            },
            {
                "name": "Concepción Chiquirichapa",
                "countryCode": "GT"
            },
            {
                "name": "El Palmar",
                "countryCode": "GT"
            },
            {
                "name": "Flores Costa Cuca",
                "countryCode": "GT"
            },
            {
                "name": "Génova",
                "countryCode": "GT"
            },
            {
                "name": "Huitán",
                "countryCode": "GT"
            },
            {
                "name": "La Esperanza",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Almolonga",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Cabricán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Cantel",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Coatepeque",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Colomba",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Concepción Chiquirichapa",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Flores Costa Cuca",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Juan Ostuncalco",
                "countryCode": "GT"
            },
            {
                "name": "Olintepeque",
                "countryCode": "GT"
            },
            {
                "name": "Ostuncalco",
                "countryCode": "GT"
            },
            {
                "name": "Palestina de los Altos",
                "countryCode": "GT"
            },
            {
                "name": "Quetzaltenango",
                "countryCode": "GT"
            },
            {
                "name": "Salcajá",
                "countryCode": "GT"
            },
            {
                "name": "Samayac",
                "countryCode": "GT"
            },
            {
                "name": "San Carlos Sija",
                "countryCode": "GT"
            },
            {
                "name": "San Francisco la Unión",
                "countryCode": "GT"
            },
            {
                "name": "San Martín Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Mateo",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Sigüilá",
                "countryCode": "GT"
            },
            {
                "name": "Sibilia",
                "countryCode": "GT"
            },
            {
                "name": "Zunil",
                "countryCode": "GT"
            },
            {
                "name": "Champerico",
                "countryCode": "GT"
            },
            {
                "name": "El Asintal",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Felipe",
                "countryCode": "GT"
            },
            {
                "name": "Nuevo San Carlos",
                "countryCode": "GT"
            },
            {
                "name": "Retalhuleu",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés Villa Seca",
                "countryCode": "GT"
            },
            {
                "name": "San Felipe",
                "countryCode": "GT"
            },
            {
                "name": "San Martín Zapotitlán",
                "countryCode": "GT"
            },
            {
                "name": "San Sebastián",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz Muluá",
                "countryCode": "GT"
            },
            {
                "name": "Alotenango",
                "countryCode": "GT"
            },
            {
                "name": "Antigua Guatemala",
                "countryCode": "GT"
            },
            {
                "name": "Ciudad Vieja",
                "countryCode": "GT"
            },
            {
                "name": "Jocotenango",
                "countryCode": "GT"
            },
            {
                "name": "Magdalena Milpas Altas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Alotenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Antigua Guatemala",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Ciudad Vieja",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Jocotenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Magdalena Milpas Altas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa Lucía Milpas Altas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa María de Jesús",
                "countryCode": "GT"
            },
            {
                "name": "Pastores",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Aguas Calientes",
                "countryCode": "GT"
            },
            {
                "name": "San Bartolomé Milpas Altas",
                "countryCode": "GT"
            },
            {
                "name": "San Lucas Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Dueñas",
                "countryCode": "GT"
            },
            {
                "name": "Santa Catarina Barahona",
                "countryCode": "GT"
            },
            {
                "name": "Santa Lucía Milpas Altas",
                "countryCode": "GT"
            },
            {
                "name": "Santa María de Jesús",
                "countryCode": "GT"
            },
            {
                "name": "Santiago Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "Santo Domingo Xenacoj",
                "countryCode": "GT"
            },
            {
                "name": "Sumpango",
                "countryCode": "GT"
            },
            {
                "name": "Catarina",
                "countryCode": "GT"
            },
            {
                "name": "Ciudad Tecún Umán",
                "countryCode": "GT"
            },
            {
                "name": "Comitancillo",
                "countryCode": "GT"
            },
            {
                "name": "Concepción Tutuapa",
                "countryCode": "GT"
            },
            {
                "name": "El Quetzal",
                "countryCode": "GT"
            },
            {
                "name": "El Rodeo",
                "countryCode": "GT"
            },
            {
                "name": "El Tumbador",
                "countryCode": "GT"
            },
            {
                "name": "Esquipulas Palo Gordo",
                "countryCode": "GT"
            },
            {
                "name": "Ixchiguán",
                "countryCode": "GT"
            },
            {
                "name": "La Reforma",
                "countryCode": "GT"
            },
            {
                "name": "Malacatán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Concepción Tutuapa",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Malacatán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Sipacapa",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Tejutla",
                "countryCode": "GT"
            },
            {
                "name": "Nuevo Progreso",
                "countryCode": "GT"
            },
            {
                "name": "Ocós",
                "countryCode": "GT"
            },
            {
                "name": "Pajapita",
                "countryCode": "GT"
            },
            {
                "name": "Río Blanco",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Cristóbal Cucho",
                "countryCode": "GT"
            },
            {
                "name": "San José Ojetenam",
                "countryCode": "GT"
            },
            {
                "name": "San José Ojetenán",
                "countryCode": "GT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "GT"
            },
            {
                "name": "San Marcos",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Ixtahuacán",
                "countryCode": "GT"
            },
            {
                "name": "San Pablo",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro Sacatepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Rafael Pie de la Cuesta",
                "countryCode": "GT"
            },
            {
                "name": "Sibinal",
                "countryCode": "GT"
            },
            {
                "name": "Sipacapa",
                "countryCode": "GT"
            },
            {
                "name": "Tacaná",
                "countryCode": "GT"
            },
            {
                "name": "Tajumulco",
                "countryCode": "GT"
            },
            {
                "name": "Tejutla",
                "countryCode": "GT"
            },
            {
                "name": "Concepción",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Nahualá",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Panajachel",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa Catarina Palopó",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa Cruz La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "Nahualá",
                "countryCode": "GT"
            },
            {
                "name": "Panajachel",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés Semetabaj",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Palopó",
                "countryCode": "GT"
            },
            {
                "name": "San José Chacayá",
                "countryCode": "GT"
            },
            {
                "name": "San Juan La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "San Lucas Tolimán",
                "countryCode": "GT"
            },
            {
                "name": "San Marcos La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "San Pablo La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "San Pedro La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "Santa Catarina Ixtahuacán",
                "countryCode": "GT"
            },
            {
                "name": "Santa Catarina Palopó",
                "countryCode": "GT"
            },
            {
                "name": "Santa Clara La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz La Laguna",
                "countryCode": "GT"
            },
            {
                "name": "Santa Lucía Utatlán",
                "countryCode": "GT"
            },
            {
                "name": "Santa María Visitación",
                "countryCode": "GT"
            },
            {
                "name": "Santiago Atitlán",
                "countryCode": "GT"
            },
            {
                "name": "Sololá",
                "countryCode": "GT"
            },
            {
                "name": "Barberena",
                "countryCode": "GT"
            },
            {
                "name": "Casillas",
                "countryCode": "GT"
            },
            {
                "name": "Chiquimulilla",
                "countryCode": "GT"
            },
            {
                "name": "Cuilapa",
                "countryCode": "GT"
            },
            {
                "name": "Guazacapán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Casillas",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Chiquimulilla",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Guazacapán",
                "countryCode": "GT"
            },
            {
                "name": "Nueva Santa Rosa",
                "countryCode": "GT"
            },
            {
                "name": "Oratorio",
                "countryCode": "GT"
            },
            {
                "name": "Pueblo Nuevo Viñas",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Tecuaco",
                "countryCode": "GT"
            },
            {
                "name": "San Rafael Las Flores",
                "countryCode": "GT"
            },
            {
                "name": "Santa Cruz Naranjo",
                "countryCode": "GT"
            },
            {
                "name": "Santa María Ixhuatán",
                "countryCode": "GT"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "GT"
            },
            {
                "name": "Taxisco",
                "countryCode": "GT"
            },
            {
                "name": "Chicacao",
                "countryCode": "GT"
            },
            {
                "name": "Cuyotenango",
                "countryCode": "GT"
            },
            {
                "name": "Mazatenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Antonio Suchitepéquez",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de San Miguel Panán",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa Bárbara",
                "countryCode": "GT"
            },
            {
                "name": "Patulul",
                "countryCode": "GT"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "GT"
            },
            {
                "name": "Río Bravo",
                "countryCode": "GT"
            },
            {
                "name": "San Antonio Suchitepéquez",
                "countryCode": "GT"
            },
            {
                "name": "San Bernardino",
                "countryCode": "GT"
            },
            {
                "name": "San Francisco Zapotitlán",
                "countryCode": "GT"
            },
            {
                "name": "San Gabriel",
                "countryCode": "GT"
            },
            {
                "name": "San José El Ídolo",
                "countryCode": "GT"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "GT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "GT"
            },
            {
                "name": "San Miguel Panán",
                "countryCode": "GT"
            },
            {
                "name": "San Pablo Jocopilas",
                "countryCode": "GT"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "GT"
            },
            {
                "name": "Santo Domingo Suchitepéquez",
                "countryCode": "GT"
            },
            {
                "name": "Santo Tomás La Unión",
                "countryCode": "GT"
            },
            {
                "name": "Zunilito",
                "countryCode": "GT"
            },
            {
                "name": "Momostenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Momostenango",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Santa María Chiquimula",
                "countryCode": "GT"
            },
            {
                "name": "Municipio de Totonicapán",
                "countryCode": "GT"
            },
            {
                "name": "San Andrés Xecul",
                "countryCode": "GT"
            },
            {
                "name": "San Bartolo",
                "countryCode": "GT"
            },
            {
                "name": "San Cristóbal Totonicapán",
                "countryCode": "GT"
            },
            {
                "name": "San Francisco El Alto",
                "countryCode": "GT"
            },
            {
                "name": "Santa Lucia La Reforma",
                "countryCode": "GT"
            },
            {
                "name": "Santa María Chiquimula",
                "countryCode": "GT"
            },
            {
                "name": "Totonicapán",
                "countryCode": "GT"
            }
        ]
    },
    {
        "name": "Guernsey and Alderney",
        "phonecode": "+44-1481",
        "cities": []
    },
    {
        "name": "Guinea",
        "phonecode": "224",
        "cities": [
            {
                "name": "Boffa",
                "countryCode": "GN"
            },
            {
                "name": "Boke Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Boké",
                "countryCode": "GN"
            },
            {
                "name": "Fria",
                "countryCode": "GN"
            },
            {
                "name": "Gaoual",
                "countryCode": "GN"
            },
            {
                "name": "Gaoual Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Kimbo",
                "countryCode": "GN"
            },
            {
                "name": "Koundara",
                "countryCode": "GN"
            },
            {
                "name": "Koundara Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Sanguéya",
                "countryCode": "GN"
            },
            {
                "name": "Youkounkoun",
                "countryCode": "GN"
            },
            {
                "name": "Camayenne",
                "countryCode": "GN"
            },
            {
                "name": "Conakry",
                "countryCode": "GN"
            },
            {
                "name": "Coyah",
                "countryCode": "GN"
            },
            {
                "name": "Dubréka",
                "countryCode": "GN"
            },
            {
                "name": "Forécariah",
                "countryCode": "GN"
            },
            {
                "name": "Kindia",
                "countryCode": "GN"
            },
            {
                "name": "Préfecture de Dubréka",
                "countryCode": "GN"
            },
            {
                "name": "Préfecture de Forécariah",
                "countryCode": "GN"
            },
            {
                "name": "Telimele Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Tondon",
                "countryCode": "GN"
            },
            {
                "name": "Télimélé",
                "countryCode": "GN"
            },
            {
                "name": "Kankan",
                "countryCode": "GN"
            },
            {
                "name": "Kankan Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Kerouane Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Kouroussa",
                "countryCode": "GN"
            },
            {
                "name": "Kérouané",
                "countryCode": "GN"
            },
            {
                "name": "Mandiana",
                "countryCode": "GN"
            },
            {
                "name": "Mandiana Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Siguiri",
                "countryCode": "GN"
            },
            {
                "name": "Siguiri Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Tokonou",
                "countryCode": "GN"
            },
            {
                "name": "Koubia",
                "countryCode": "GN"
            },
            {
                "name": "Labe Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Labé",
                "countryCode": "GN"
            },
            {
                "name": "Lelouma Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Lélouma",
                "countryCode": "GN"
            },
            {
                "name": "Mali",
                "countryCode": "GN"
            },
            {
                "name": "Mali Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Tougue Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Tougué",
                "countryCode": "GN"
            },
            {
                "name": "Dalaba",
                "countryCode": "GN"
            },
            {
                "name": "Mamou",
                "countryCode": "GN"
            },
            {
                "name": "Mamou Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Pita",
                "countryCode": "GN"
            },
            {
                "name": "Beyla",
                "countryCode": "GN"
            },
            {
                "name": "Beyla Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Gueckedou",
                "countryCode": "GN"
            },
            {
                "name": "Lola",
                "countryCode": "GN"
            },
            {
                "name": "Macenta",
                "countryCode": "GN"
            },
            {
                "name": "Nzerekore Prefecture",
                "countryCode": "GN"
            },
            {
                "name": "Nzérékoré",
                "countryCode": "GN"
            },
            {
                "name": "Préfecture de Guékédou",
                "countryCode": "GN"
            },
            {
                "name": "Yomou",
                "countryCode": "GN"
            }
        ]
    },
    {
        "name": "Guinea-Bissau",
        "phonecode": "245",
        "cities": [
            {
                "name": "Bafatá",
                "countryCode": "GW"
            },
            {
                "name": "Contuboel Sector",
                "countryCode": "GW"
            },
            {
                "name": "Bolama",
                "countryCode": "GW"
            },
            {
                "name": "Bubaque",
                "countryCode": "GW"
            },
            {
                "name": "Quinhámel",
                "countryCode": "GW"
            },
            {
                "name": "Cacheu",
                "countryCode": "GW"
            },
            {
                "name": "Canchungo",
                "countryCode": "GW"
            },
            {
                "name": "Gabú",
                "countryCode": "GW"
            },
            {
                "name": "Bissorã",
                "countryCode": "GW"
            },
            {
                "name": "Farim",
                "countryCode": "GW"
            },
            {
                "name": "Mansôa",
                "countryCode": "GW"
            },
            {
                "name": "Buba",
                "countryCode": "GW"
            },
            {
                "name": "Catió",
                "countryCode": "GW"
            },
            {
                "name": "Quebo",
                "countryCode": "GW"
            }
        ]
    },
    {
        "name": "Guyana",
        "phonecode": "592",
        "cities": [
            {
                "name": "Mabaruma",
                "countryCode": "GY"
            },
            {
                "name": "Bartica",
                "countryCode": "GY"
            },
            {
                "name": "Georgetown",
                "countryCode": "GY"
            },
            {
                "name": "Mahaica Village",
                "countryCode": "GY"
            },
            {
                "name": "New Amsterdam",
                "countryCode": "GY"
            },
            {
                "name": "Skeldon",
                "countryCode": "GY"
            },
            {
                "name": "Parika",
                "countryCode": "GY"
            },
            {
                "name": "Vreed-en-Hoop",
                "countryCode": "GY"
            },
            {
                "name": "Mahaicony Village",
                "countryCode": "GY"
            },
            {
                "name": "Rosignol",
                "countryCode": "GY"
            },
            {
                "name": "Anna Regina",
                "countryCode": "GY"
            },
            {
                "name": "Mahdia",
                "countryCode": "GY"
            },
            {
                "name": "Linden",
                "countryCode": "GY"
            },
            {
                "name": "Lethem",
                "countryCode": "GY"
            }
        ]
    },
    {
        "name": "Haiti",
        "phonecode": "509",
        "cities": [
            {
                "name": "Anse Rouge",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Saint-Marc",
                "countryCode": "HT"
            },
            {
                "name": "Dessalines",
                "countryCode": "HT"
            },
            {
                "name": "Désarmes",
                "countryCode": "HT"
            },
            {
                "name": "Ennery",
                "countryCode": "HT"
            },
            {
                "name": "Gonaïves",
                "countryCode": "HT"
            },
            {
                "name": "Grande Saline",
                "countryCode": "HT"
            },
            {
                "name": "Gros Morne",
                "countryCode": "HT"
            },
            {
                "name": "Marmelade",
                "countryCode": "HT"
            },
            {
                "name": "Saint-Marc",
                "countryCode": "HT"
            },
            {
                "name": "Verrettes",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Cerca La Source",
                "countryCode": "HT"
            },
            {
                "name": "Cerca la Source",
                "countryCode": "HT"
            },
            {
                "name": "Hinche",
                "countryCode": "HT"
            },
            {
                "name": "Lascahobas",
                "countryCode": "HT"
            },
            {
                "name": "Mayisad",
                "countryCode": "HT"
            },
            {
                "name": "Mirebalais",
                "countryCode": "HT"
            },
            {
                "name": "Thomassique",
                "countryCode": "HT"
            },
            {
                "name": "Thomonde",
                "countryCode": "HT"
            },
            {
                "name": "Anse-à-Veau",
                "countryCode": "HT"
            },
            {
                "name": "Chambellan",
                "countryCode": "HT"
            },
            {
                "name": "Corail",
                "countryCode": "HT"
            },
            {
                "name": "Dame-Marie",
                "countryCode": "HT"
            },
            {
                "name": "Jeremi",
                "countryCode": "HT"
            },
            {
                "name": "Jérémie",
                "countryCode": "HT"
            },
            {
                "name": "Les Abricots",
                "countryCode": "HT"
            },
            {
                "name": "Les Irois",
                "countryCode": "HT"
            },
            {
                "name": "Moron",
                "countryCode": "HT"
            },
            {
                "name": "Petite Rivière de Nippes",
                "countryCode": "HT"
            },
            {
                "name": "Acul du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Plaisance",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de la Grande Rivière du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement du Borgne",
                "countryCode": "HT"
            },
            {
                "name": "Bahon",
                "countryCode": "HT"
            },
            {
                "name": "Borgne",
                "countryCode": "HT"
            },
            {
                "name": "Dondon",
                "countryCode": "HT"
            },
            {
                "name": "Grande Rivière du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Lenbe",
                "countryCode": "HT"
            },
            {
                "name": "Limonade",
                "countryCode": "HT"
            },
            {
                "name": "Milot",
                "countryCode": "HT"
            },
            {
                "name": "Okap",
                "countryCode": "HT"
            },
            {
                "name": "Pignon",
                "countryCode": "HT"
            },
            {
                "name": "Pilate",
                "countryCode": "HT"
            },
            {
                "name": "Plaine du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Plaisance",
                "countryCode": "HT"
            },
            {
                "name": "Port-Margot",
                "countryCode": "HT"
            },
            {
                "name": "Quartier Morin",
                "countryCode": "HT"
            },
            {
                "name": "Ranquitte",
                "countryCode": "HT"
            },
            {
                "name": "Saint-Raphaël",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Fort Liberté",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement du Trou du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Caracol",
                "countryCode": "HT"
            },
            {
                "name": "Carice",
                "countryCode": "HT"
            },
            {
                "name": "Dérac",
                "countryCode": "HT"
            },
            {
                "name": "Ferrier",
                "countryCode": "HT"
            },
            {
                "name": "Fort Liberté",
                "countryCode": "HT"
            },
            {
                "name": "Montòrganize",
                "countryCode": "HT"
            },
            {
                "name": "Ouanaminthe",
                "countryCode": "HT"
            },
            {
                "name": "Perches",
                "countryCode": "HT"
            },
            {
                "name": "Phaëton",
                "countryCode": "HT"
            },
            {
                "name": "Trou du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Wanament",
                "countryCode": "HT"
            },
            {
                "name": "Ansavo",
                "countryCode": "HT"
            },
            {
                "name": "Baradères",
                "countryCode": "HT"
            },
            {
                "name": "Miragoâne",
                "countryCode": "HT"
            },
            {
                "name": "Petit Trou de Nippes",
                "countryCode": "HT"
            },
            {
                "name": "Arcahaie",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Port-de-Paix",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Saint-Louis du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement du Môle Saint-Nicolas",
                "countryCode": "HT"
            },
            {
                "name": "Baie de Henne",
                "countryCode": "HT"
            },
            {
                "name": "Bombardopolis",
                "countryCode": "HT"
            },
            {
                "name": "Fond Bassin Bleu",
                "countryCode": "HT"
            },
            {
                "name": "Jean-Rabel",
                "countryCode": "HT"
            },
            {
                "name": "Môle Saint-Nicolas",
                "countryCode": "HT"
            },
            {
                "name": "Petite Anse",
                "countryCode": "HT"
            },
            {
                "name": "Port-de-Paix",
                "countryCode": "HT"
            },
            {
                "name": "Saint-Louis du Nord",
                "countryCode": "HT"
            },
            {
                "name": "Ti Port-de-Paix",
                "countryCode": "HT"
            },
            {
                "name": "Anse à Galets",
                "countryCode": "HT"
            },
            {
                "name": "Arcahaie",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Croix des Bouquets",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Léogâne",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Port-au-Prince",
                "countryCode": "HT"
            },
            {
                "name": "Cabaret",
                "countryCode": "HT"
            },
            {
                "name": "Carrefour",
                "countryCode": "HT"
            },
            {
                "name": "Cornillon",
                "countryCode": "HT"
            },
            {
                "name": "Croix-des-Bouquets",
                "countryCode": "HT"
            },
            {
                "name": "Delmas 73",
                "countryCode": "HT"
            },
            {
                "name": "Fond Parisien",
                "countryCode": "HT"
            },
            {
                "name": "Fonds Verrettes",
                "countryCode": "HT"
            },
            {
                "name": "Grangwav",
                "countryCode": "HT"
            },
            {
                "name": "Gressier",
                "countryCode": "HT"
            },
            {
                "name": "Kenscoff",
                "countryCode": "HT"
            },
            {
                "name": "Lagonav",
                "countryCode": "HT"
            },
            {
                "name": "Léogâne",
                "countryCode": "HT"
            },
            {
                "name": "Port-au-Prince",
                "countryCode": "HT"
            },
            {
                "name": "Pétionville",
                "countryCode": "HT"
            },
            {
                "name": "Thomazeau",
                "countryCode": "HT"
            },
            {
                "name": "Tigwav",
                "countryCode": "HT"
            },
            {
                "name": "Aquin",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Port-Salut",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement des Cayes",
                "countryCode": "HT"
            },
            {
                "name": "Cavaillon",
                "countryCode": "HT"
            },
            {
                "name": "Chantal",
                "countryCode": "HT"
            },
            {
                "name": "Chardonnière",
                "countryCode": "HT"
            },
            {
                "name": "Fond des Blancs",
                "countryCode": "HT"
            },
            {
                "name": "Koto",
                "countryCode": "HT"
            },
            {
                "name": "Les Anglais",
                "countryCode": "HT"
            },
            {
                "name": "Les Cayes",
                "countryCode": "HT"
            },
            {
                "name": "Port-à-Piment",
                "countryCode": "HT"
            },
            {
                "name": "Roche-à-Bateau",
                "countryCode": "HT"
            },
            {
                "name": "Saint-Louis du Sud",
                "countryCode": "HT"
            },
            {
                "name": "Tiburon",
                "countryCode": "HT"
            },
            {
                "name": "Torbeck",
                "countryCode": "HT"
            },
            {
                "name": "Anse-à-Pitre",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Bainet",
                "countryCode": "HT"
            },
            {
                "name": "Arrondissement de Jacmel",
                "countryCode": "HT"
            },
            {
                "name": "Belle-Anse",
                "countryCode": "HT"
            },
            {
                "name": "Cayes-Jacmel",
                "countryCode": "HT"
            },
            {
                "name": "Jacmel",
                "countryCode": "HT"
            },
            {
                "name": "Kotdefè",
                "countryCode": "HT"
            },
            {
                "name": "Marigot",
                "countryCode": "HT"
            },
            {
                "name": "Thiotte",
                "countryCode": "HT"
            }
        ]
    },
    {
        "name": "Heard Island and McDonald Islands",
        "phonecode": "672",
        "cities": []
    },
    {
        "name": "Honduras",
        "phonecode": "504",
        "cities": [
            {
                "name": "Arizona",
                "countryCode": "HN"
            },
            {
                "name": "Atenas de San Cristóbal",
                "countryCode": "HN"
            },
            {
                "name": "Corozal",
                "countryCode": "HN"
            },
            {
                "name": "El Pino",
                "countryCode": "HN"
            },
            {
                "name": "El Porvenir",
                "countryCode": "HN"
            },
            {
                "name": "El Triunfo de la Cruz",
                "countryCode": "HN"
            },
            {
                "name": "Esparta",
                "countryCode": "HN"
            },
            {
                "name": "Jutiapa",
                "countryCode": "HN"
            },
            {
                "name": "La Ceiba",
                "countryCode": "HN"
            },
            {
                "name": "La Masica",
                "countryCode": "HN"
            },
            {
                "name": "La Unión",
                "countryCode": "HN"
            },
            {
                "name": "Mezapa",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Armenia",
                "countryCode": "HN"
            },
            {
                "name": "Sambo Creek",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco",
                "countryCode": "HN"
            },
            {
                "name": "San Juan Pueblo",
                "countryCode": "HN"
            },
            {
                "name": "Santa Ana",
                "countryCode": "HN"
            },
            {
                "name": "Tela",
                "countryCode": "HN"
            },
            {
                "name": "Tornabé",
                "countryCode": "HN"
            },
            {
                "name": "Apacilagua",
                "countryCode": "HN"
            },
            {
                "name": "Choluteca",
                "countryCode": "HN"
            },
            {
                "name": "Ciudad Choluteca",
                "countryCode": "HN"
            },
            {
                "name": "Concepción de María",
                "countryCode": "HN"
            },
            {
                "name": "Corpus",
                "countryCode": "HN"
            },
            {
                "name": "Duyure",
                "countryCode": "HN"
            },
            {
                "name": "El Corpus",
                "countryCode": "HN"
            },
            {
                "name": "El Obraje",
                "countryCode": "HN"
            },
            {
                "name": "El Puente",
                "countryCode": "HN"
            },
            {
                "name": "El Triunfo",
                "countryCode": "HN"
            },
            {
                "name": "Los Llanitos",
                "countryCode": "HN"
            },
            {
                "name": "Marcovia",
                "countryCode": "HN"
            },
            {
                "name": "Monjarás",
                "countryCode": "HN"
            },
            {
                "name": "Morolica",
                "countryCode": "HN"
            },
            {
                "name": "Namasigüe",
                "countryCode": "HN"
            },
            {
                "name": "Orocuina",
                "countryCode": "HN"
            },
            {
                "name": "Pespire",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio de Flores",
                "countryCode": "HN"
            },
            {
                "name": "San Isidro",
                "countryCode": "HN"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "HN"
            },
            {
                "name": "San José",
                "countryCode": "HN"
            },
            {
                "name": "San José de Las Conchas",
                "countryCode": "HN"
            },
            {
                "name": "San Marcos de Colón",
                "countryCode": "HN"
            },
            {
                "name": "Santa Ana de Yusguare",
                "countryCode": "HN"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "HN"
            },
            {
                "name": "Balfate",
                "countryCode": "HN"
            },
            {
                "name": "Bonito Oriental",
                "countryCode": "HN"
            },
            {
                "name": "Corocito",
                "countryCode": "HN"
            },
            {
                "name": "Cusuna",
                "countryCode": "HN"
            },
            {
                "name": "Elíxir",
                "countryCode": "HN"
            },
            {
                "name": "Francia",
                "countryCode": "HN"
            },
            {
                "name": "Iriona",
                "countryCode": "HN"
            },
            {
                "name": "Jericó",
                "countryCode": "HN"
            },
            {
                "name": "La Brea",
                "countryCode": "HN"
            },
            {
                "name": "La Esperanza",
                "countryCode": "HN"
            },
            {
                "name": "Limón",
                "countryCode": "HN"
            },
            {
                "name": "Municipio de Sabá",
                "countryCode": "HN"
            },
            {
                "name": "Prieta",
                "countryCode": "HN"
            },
            {
                "name": "Puerto Castilla",
                "countryCode": "HN"
            },
            {
                "name": "Punta Piedra",
                "countryCode": "HN"
            },
            {
                "name": "Quebrada de Arena",
                "countryCode": "HN"
            },
            {
                "name": "Río Esteban",
                "countryCode": "HN"
            },
            {
                "name": "Sabá",
                "countryCode": "HN"
            },
            {
                "name": "Salamá",
                "countryCode": "HN"
            },
            {
                "name": "Santa Fe",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rosa de Aguán",
                "countryCode": "HN"
            },
            {
                "name": "Sonaguera",
                "countryCode": "HN"
            },
            {
                "name": "Taujica",
                "countryCode": "HN"
            },
            {
                "name": "Tocoa",
                "countryCode": "HN"
            },
            {
                "name": "Trujillo",
                "countryCode": "HN"
            },
            {
                "name": "Zamora",
                "countryCode": "HN"
            },
            {
                "name": "Aguas del Padre",
                "countryCode": "HN"
            },
            {
                "name": "Ajuterique",
                "countryCode": "HN"
            },
            {
                "name": "Cerro Blanco",
                "countryCode": "HN"
            },
            {
                "name": "Comayagua",
                "countryCode": "HN"
            },
            {
                "name": "Concepción de Guasistagua",
                "countryCode": "HN"
            },
            {
                "name": "El Agua Dulcita",
                "countryCode": "HN"
            },
            {
                "name": "El Porvenir",
                "countryCode": "HN"
            },
            {
                "name": "El Rancho",
                "countryCode": "HN"
            },
            {
                "name": "El Rincón",
                "countryCode": "HN"
            },
            {
                "name": "El Rosario",
                "countryCode": "HN"
            },
            {
                "name": "El Sauce",
                "countryCode": "HN"
            },
            {
                "name": "El Socorro",
                "countryCode": "HN"
            },
            {
                "name": "Esquías",
                "countryCode": "HN"
            },
            {
                "name": "Flores",
                "countryCode": "HN"
            },
            {
                "name": "Humuya",
                "countryCode": "HN"
            },
            {
                "name": "Jamalteca",
                "countryCode": "HN"
            },
            {
                "name": "La Libertad",
                "countryCode": "HN"
            },
            {
                "name": "La Trinidad",
                "countryCode": "HN"
            },
            {
                "name": "Lamaní",
                "countryCode": "HN"
            },
            {
                "name": "Las Lajas",
                "countryCode": "HN"
            },
            {
                "name": "Lejamaní",
                "countryCode": "HN"
            },
            {
                "name": "Meámbar",
                "countryCode": "HN"
            },
            {
                "name": "Minas de Oro",
                "countryCode": "HN"
            },
            {
                "name": "Ojos de Agua",
                "countryCode": "HN"
            },
            {
                "name": "Potrerillos",
                "countryCode": "HN"
            },
            {
                "name": "Río Bonito",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio de la Cuesta",
                "countryCode": "HN"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "HN"
            },
            {
                "name": "San José de Comayagua",
                "countryCode": "HN"
            },
            {
                "name": "San José del Potrero",
                "countryCode": "HN"
            },
            {
                "name": "San Luis",
                "countryCode": "HN"
            },
            {
                "name": "San Sebastián",
                "countryCode": "HN"
            },
            {
                "name": "Siguatepeque",
                "countryCode": "HN"
            },
            {
                "name": "Taulabé",
                "countryCode": "HN"
            },
            {
                "name": "Valle de Ángeles",
                "countryCode": "HN"
            },
            {
                "name": "Villa de San Antonio",
                "countryCode": "HN"
            },
            {
                "name": "Agua Caliente",
                "countryCode": "HN"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "HN"
            },
            {
                "name": "Cabañas",
                "countryCode": "HN"
            },
            {
                "name": "Chalmeca",
                "countryCode": "HN"
            },
            {
                "name": "Concepción",
                "countryCode": "HN"
            },
            {
                "name": "Concepción de la Barranca",
                "countryCode": "HN"
            },
            {
                "name": "Copán",
                "countryCode": "HN"
            },
            {
                "name": "Copán Ruinas",
                "countryCode": "HN"
            },
            {
                "name": "Corquín",
                "countryCode": "HN"
            },
            {
                "name": "Cucuyagua",
                "countryCode": "HN"
            },
            {
                "name": "Dolores",
                "countryCode": "HN"
            },
            {
                "name": "Dulce Nombre",
                "countryCode": "HN"
            },
            {
                "name": "El Corpus",
                "countryCode": "HN"
            },
            {
                "name": "El Ocotón",
                "countryCode": "HN"
            },
            {
                "name": "El Paraíso",
                "countryCode": "HN"
            },
            {
                "name": "Florida",
                "countryCode": "HN"
            },
            {
                "name": "La Entrada",
                "countryCode": "HN"
            },
            {
                "name": "La Jigua",
                "countryCode": "HN"
            },
            {
                "name": "La Playona",
                "countryCode": "HN"
            },
            {
                "name": "La Unión",
                "countryCode": "HN"
            },
            {
                "name": "La Zumbadora",
                "countryCode": "HN"
            },
            {
                "name": "Los Tangos",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Arcadia",
                "countryCode": "HN"
            },
            {
                "name": "Ojos de Agua",
                "countryCode": "HN"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "HN"
            },
            {
                "name": "Quezailica",
                "countryCode": "HN"
            },
            {
                "name": "San Agustín",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio",
                "countryCode": "HN"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "HN"
            },
            {
                "name": "San Joaquín",
                "countryCode": "HN"
            },
            {
                "name": "San José",
                "countryCode": "HN"
            },
            {
                "name": "San José de Copán",
                "countryCode": "HN"
            },
            {
                "name": "San Juan de Opoa",
                "countryCode": "HN"
            },
            {
                "name": "San Juan de Planes",
                "countryCode": "HN"
            },
            {
                "name": "San Nicolás",
                "countryCode": "HN"
            },
            {
                "name": "San Pedro de Copán",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rita",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rita, Copan",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rosa de Copán",
                "countryCode": "HN"
            },
            {
                "name": "Trinidad de Copán",
                "countryCode": "HN"
            },
            {
                "name": "Veracruz",
                "countryCode": "HN"
            },
            {
                "name": "Agua Azul",
                "countryCode": "HN"
            },
            {
                "name": "Agua Azul Rancho",
                "countryCode": "HN"
            },
            {
                "name": "Armenta",
                "countryCode": "HN"
            },
            {
                "name": "Baja Mar",
                "countryCode": "HN"
            },
            {
                "name": "Baracoa",
                "countryCode": "HN"
            },
            {
                "name": "Bejuco",
                "countryCode": "HN"
            },
            {
                "name": "Casa Quemada",
                "countryCode": "HN"
            },
            {
                "name": "Cañaveral",
                "countryCode": "HN"
            },
            {
                "name": "Chivana",
                "countryCode": "HN"
            },
            {
                "name": "Choloma",
                "countryCode": "HN"
            },
            {
                "name": "Chotepe",
                "countryCode": "HN"
            },
            {
                "name": "Cofradía",
                "countryCode": "HN"
            },
            {
                "name": "Cuyamel",
                "countryCode": "HN"
            },
            {
                "name": "El Llano",
                "countryCode": "HN"
            },
            {
                "name": "El Marañón",
                "countryCode": "HN"
            },
            {
                "name": "El Milagro",
                "countryCode": "HN"
            },
            {
                "name": "El Olivar",
                "countryCode": "HN"
            },
            {
                "name": "El Perico",
                "countryCode": "HN"
            },
            {
                "name": "El Plan",
                "countryCode": "HN"
            },
            {
                "name": "El Porvenir",
                "countryCode": "HN"
            },
            {
                "name": "El Rancho",
                "countryCode": "HN"
            },
            {
                "name": "El Tigre",
                "countryCode": "HN"
            },
            {
                "name": "El Zapotal del Norte",
                "countryCode": "HN"
            },
            {
                "name": "La Guama",
                "countryCode": "HN"
            },
            {
                "name": "La Huesa",
                "countryCode": "HN"
            },
            {
                "name": "La Jutosa",
                "countryCode": "HN"
            },
            {
                "name": "La Lima",
                "countryCode": "HN"
            },
            {
                "name": "La Sabana",
                "countryCode": "HN"
            },
            {
                "name": "Los Caminos",
                "countryCode": "HN"
            },
            {
                "name": "Los Naranjos",
                "countryCode": "HN"
            },
            {
                "name": "Monterrey",
                "countryCode": "HN"
            },
            {
                "name": "Nuevo Chamelecón",
                "countryCode": "HN"
            },
            {
                "name": "Omoa",
                "countryCode": "HN"
            },
            {
                "name": "Oropéndolas",
                "countryCode": "HN"
            },
            {
                "name": "Peña Blanca",
                "countryCode": "HN"
            },
            {
                "name": "Pimienta",
                "countryCode": "HN"
            },
            {
                "name": "Pimienta Vieja",
                "countryCode": "HN"
            },
            {
                "name": "Potrerillos",
                "countryCode": "HN"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "HN"
            },
            {
                "name": "Puerto Alto",
                "countryCode": "HN"
            },
            {
                "name": "Puerto Cortez",
                "countryCode": "HN"
            },
            {
                "name": "Puerto Cortés",
                "countryCode": "HN"
            },
            {
                "name": "Quebrada Seca",
                "countryCode": "HN"
            },
            {
                "name": "Río Blanquito",
                "countryCode": "HN"
            },
            {
                "name": "Río Chiquito",
                "countryCode": "HN"
            },
            {
                "name": "Río Lindo",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio de Cortés",
                "countryCode": "HN"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Yojoa",
                "countryCode": "HN"
            },
            {
                "name": "San José del Boquerón",
                "countryCode": "HN"
            },
            {
                "name": "San Manuel",
                "countryCode": "HN"
            },
            {
                "name": "San Pedro Sula",
                "countryCode": "HN"
            },
            {
                "name": "Santa Cruz de Yojoa",
                "countryCode": "HN"
            },
            {
                "name": "Santa Elena",
                "countryCode": "HN"
            },
            {
                "name": "Travesía",
                "countryCode": "HN"
            },
            {
                "name": "Villanueva",
                "countryCode": "HN"
            },
            {
                "name": "Alauca",
                "countryCode": "HN"
            },
            {
                "name": "Araulí",
                "countryCode": "HN"
            },
            {
                "name": "Cuyalí",
                "countryCode": "HN"
            },
            {
                "name": "Danlí",
                "countryCode": "HN"
            },
            {
                "name": "El Benque",
                "countryCode": "HN"
            },
            {
                "name": "El Chichicaste",
                "countryCode": "HN"
            },
            {
                "name": "El Obraje",
                "countryCode": "HN"
            },
            {
                "name": "El Paraíso",
                "countryCode": "HN"
            },
            {
                "name": "Güinope",
                "countryCode": "HN"
            },
            {
                "name": "Jacaleapa",
                "countryCode": "HN"
            },
            {
                "name": "Jutiapa",
                "countryCode": "HN"
            },
            {
                "name": "Las Trojes",
                "countryCode": "HN"
            },
            {
                "name": "Las Ánimas",
                "countryCode": "HN"
            },
            {
                "name": "Liure",
                "countryCode": "HN"
            },
            {
                "name": "Morocelí",
                "countryCode": "HN"
            },
            {
                "name": "Municipio de Texiguat",
                "countryCode": "HN"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "HN"
            },
            {
                "name": "Oropolí",
                "countryCode": "HN"
            },
            {
                "name": "Potrerillos",
                "countryCode": "HN"
            },
            {
                "name": "Quebrada Larga",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio de Flores",
                "countryCode": "HN"
            },
            {
                "name": "San Diego",
                "countryCode": "HN"
            },
            {
                "name": "San Lucas",
                "countryCode": "HN"
            },
            {
                "name": "San Matías",
                "countryCode": "HN"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "HN"
            },
            {
                "name": "Soledad",
                "countryCode": "HN"
            },
            {
                "name": "Teupasenti",
                "countryCode": "HN"
            },
            {
                "name": "Texíguat",
                "countryCode": "HN"
            },
            {
                "name": "Trojes",
                "countryCode": "HN"
            },
            {
                "name": "Vado Ancho",
                "countryCode": "HN"
            },
            {
                "name": "Yauyupe",
                "countryCode": "HN"
            },
            {
                "name": "Yuscarán",
                "countryCode": "HN"
            },
            {
                "name": "Agalteca",
                "countryCode": "HN"
            },
            {
                "name": "Alubarén",
                "countryCode": "HN"
            },
            {
                "name": "Cedros",
                "countryCode": "HN"
            },
            {
                "name": "Cerro Grande",
                "countryCode": "HN"
            },
            {
                "name": "Cofradía",
                "countryCode": "HN"
            },
            {
                "name": "Curarén",
                "countryCode": "HN"
            },
            {
                "name": "Distrito Central",
                "countryCode": "HN"
            },
            {
                "name": "El Chimbo",
                "countryCode": "HN"
            },
            {
                "name": "El Escanito",
                "countryCode": "HN"
            },
            {
                "name": "El Escaño de Tepale",
                "countryCode": "HN"
            },
            {
                "name": "El Guante",
                "countryCode": "HN"
            },
            {
                "name": "El Guantillo",
                "countryCode": "HN"
            },
            {
                "name": "El Guapinol",
                "countryCode": "HN"
            },
            {
                "name": "El Lolo",
                "countryCode": "HN"
            },
            {
                "name": "El Pedernal",
                "countryCode": "HN"
            },
            {
                "name": "El Porvenir",
                "countryCode": "HN"
            },
            {
                "name": "El Suyatal",
                "countryCode": "HN"
            },
            {
                "name": "El Tablón",
                "countryCode": "HN"
            },
            {
                "name": "El Terrero",
                "countryCode": "HN"
            },
            {
                "name": "Guaimaca",
                "countryCode": "HN"
            },
            {
                "name": "La Ermita",
                "countryCode": "HN"
            },
            {
                "name": "La Libertad",
                "countryCode": "HN"
            },
            {
                "name": "La Venta",
                "countryCode": "HN"
            },
            {
                "name": "Lepaterique",
                "countryCode": "HN"
            },
            {
                "name": "Maraita",
                "countryCode": "HN"
            },
            {
                "name": "Marale",
                "countryCode": "HN"
            },
            {
                "name": "Mata de Plátano",
                "countryCode": "HN"
            },
            {
                "name": "Mateo",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Armenia",
                "countryCode": "HN"
            },
            {
                "name": "Ojojona",
                "countryCode": "HN"
            },
            {
                "name": "Orica",
                "countryCode": "HN"
            },
            {
                "name": "Quebradas",
                "countryCode": "HN"
            },
            {
                "name": "Reitoca",
                "countryCode": "HN"
            },
            {
                "name": "Río Abajo",
                "countryCode": "HN"
            },
            {
                "name": "Sabanagrande",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio de Oriente",
                "countryCode": "HN"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "HN"
            },
            {
                "name": "San Ignacio",
                "countryCode": "HN"
            },
            {
                "name": "San Juan de Flores",
                "countryCode": "HN"
            },
            {
                "name": "San Miguelito",
                "countryCode": "HN"
            },
            {
                "name": "Santa Ana",
                "countryCode": "HN"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "HN"
            },
            {
                "name": "Talanga",
                "countryCode": "HN"
            },
            {
                "name": "Tatumbla",
                "countryCode": "HN"
            },
            {
                "name": "Tegucigalpa",
                "countryCode": "HN"
            },
            {
                "name": "Támara",
                "countryCode": "HN"
            },
            {
                "name": "Valle de Ángeles",
                "countryCode": "HN"
            },
            {
                "name": "Vallecillo",
                "countryCode": "HN"
            },
            {
                "name": "Villa Nueva",
                "countryCode": "HN"
            },
            {
                "name": "Villa de San Francisco",
                "countryCode": "HN"
            },
            {
                "name": "Yaguacire",
                "countryCode": "HN"
            },
            {
                "name": "Zambrano",
                "countryCode": "HN"
            },
            {
                "name": "Ahuas",
                "countryCode": "HN"
            },
            {
                "name": "Auas",
                "countryCode": "HN"
            },
            {
                "name": "Auka",
                "countryCode": "HN"
            },
            {
                "name": "Barra Patuca",
                "countryCode": "HN"
            },
            {
                "name": "Brus Laguna",
                "countryCode": "HN"
            },
            {
                "name": "Iralaya",
                "countryCode": "HN"
            },
            {
                "name": "Juan Francisco Bulnes",
                "countryCode": "HN"
            },
            {
                "name": "Paptalaya",
                "countryCode": "HN"
            },
            {
                "name": "Puerto Lempira",
                "countryCode": "HN"
            },
            {
                "name": "Villeda Morales",
                "countryCode": "HN"
            },
            {
                "name": "Wampusirpi",
                "countryCode": "HN"
            },
            {
                "name": "Wawina",
                "countryCode": "HN"
            },
            {
                "name": "Coxen Hole",
                "countryCode": "HN"
            },
            {
                "name": "French Harbor",
                "countryCode": "HN"
            },
            {
                "name": "Guanaja",
                "countryCode": "HN"
            },
            {
                "name": "José Santos Guardiola",
                "countryCode": "HN"
            },
            {
                "name": "Roatán",
                "countryCode": "HN"
            },
            {
                "name": "Sandy Bay",
                "countryCode": "HN"
            },
            {
                "name": "Savannah Bight",
                "countryCode": "HN"
            },
            {
                "name": "Utila",
                "countryCode": "HN"
            },
            {
                "name": "Camasca",
                "countryCode": "HN"
            },
            {
                "name": "Colomoncagua",
                "countryCode": "HN"
            },
            {
                "name": "Concepción",
                "countryCode": "HN"
            },
            {
                "name": "Dolores",
                "countryCode": "HN"
            },
            {
                "name": "Intibucá",
                "countryCode": "HN"
            },
            {
                "name": "Jesús de Otoro",
                "countryCode": "HN"
            },
            {
                "name": "Jiquinlaca",
                "countryCode": "HN"
            },
            {
                "name": "La Esperanza",
                "countryCode": "HN"
            },
            {
                "name": "Magdalena",
                "countryCode": "HN"
            },
            {
                "name": "Masaguara",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Opalaca",
                "countryCode": "HN"
            },
            {
                "name": "San Isidro",
                "countryCode": "HN"
            },
            {
                "name": "San Juan",
                "countryCode": "HN"
            },
            {
                "name": "San Marcos de la Sierra",
                "countryCode": "HN"
            },
            {
                "name": "San Miguelito",
                "countryCode": "HN"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "HN"
            },
            {
                "name": "Yamaranguila",
                "countryCode": "HN"
            },
            {
                "name": "Belén",
                "countryCode": "HN"
            },
            {
                "name": "Candelaria",
                "countryCode": "HN"
            },
            {
                "name": "Cololaca",
                "countryCode": "HN"
            },
            {
                "name": "El Achiotal",
                "countryCode": "HN"
            },
            {
                "name": "Erandique",
                "countryCode": "HN"
            },
            {
                "name": "Gracias",
                "countryCode": "HN"
            },
            {
                "name": "Gualcince",
                "countryCode": "HN"
            },
            {
                "name": "Guarita",
                "countryCode": "HN"
            },
            {
                "name": "La Campa",
                "countryCode": "HN"
            },
            {
                "name": "La Iguala",
                "countryCode": "HN"
            },
            {
                "name": "La Libertad",
                "countryCode": "HN"
            },
            {
                "name": "La Unión",
                "countryCode": "HN"
            },
            {
                "name": "La Virtud",
                "countryCode": "HN"
            },
            {
                "name": "Las Flores",
                "countryCode": "HN"
            },
            {
                "name": "Las Tejeras",
                "countryCode": "HN"
            },
            {
                "name": "Lepaera",
                "countryCode": "HN"
            },
            {
                "name": "Mapulaca",
                "countryCode": "HN"
            },
            {
                "name": "Piraera",
                "countryCode": "HN"
            },
            {
                "name": "San Andrés",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco",
                "countryCode": "HN"
            },
            {
                "name": "San Juan Guarita",
                "countryCode": "HN"
            },
            {
                "name": "San Manuel Colohete",
                "countryCode": "HN"
            },
            {
                "name": "San Marcos de Caiquin",
                "countryCode": "HN"
            },
            {
                "name": "San Rafael",
                "countryCode": "HN"
            },
            {
                "name": "San Sebastián",
                "countryCode": "HN"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "HN"
            },
            {
                "name": "Talgua",
                "countryCode": "HN"
            },
            {
                "name": "Tambla",
                "countryCode": "HN"
            },
            {
                "name": "Taragual",
                "countryCode": "HN"
            },
            {
                "name": "Tomalá",
                "countryCode": "HN"
            },
            {
                "name": "Valladolid",
                "countryCode": "HN"
            },
            {
                "name": "Virginia",
                "countryCode": "HN"
            },
            {
                "name": "Aguanqueterique",
                "countryCode": "HN"
            },
            {
                "name": "Cabañas",
                "countryCode": "HN"
            },
            {
                "name": "Cane",
                "countryCode": "HN"
            },
            {
                "name": "Chinacla",
                "countryCode": "HN"
            },
            {
                "name": "Guajiquiro",
                "countryCode": "HN"
            },
            {
                "name": "La Paz",
                "countryCode": "HN"
            },
            {
                "name": "Lauterique",
                "countryCode": "HN"
            },
            {
                "name": "Los Planes",
                "countryCode": "HN"
            },
            {
                "name": "Marcala",
                "countryCode": "HN"
            },
            {
                "name": "Mercedes de Oriente",
                "countryCode": "HN"
            },
            {
                "name": "Opatoro",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio del Norte",
                "countryCode": "HN"
            },
            {
                "name": "San José",
                "countryCode": "HN"
            },
            {
                "name": "San Juan",
                "countryCode": "HN"
            },
            {
                "name": "San Pedro de Tutule",
                "countryCode": "HN"
            },
            {
                "name": "Santa Ana",
                "countryCode": "HN"
            },
            {
                "name": "Santa Elena",
                "countryCode": "HN"
            },
            {
                "name": "Santa María",
                "countryCode": "HN"
            },
            {
                "name": "Santiago Puringla",
                "countryCode": "HN"
            },
            {
                "name": "Tepanguare",
                "countryCode": "HN"
            },
            {
                "name": "Yarula",
                "countryCode": "HN"
            },
            {
                "name": "Yarumela",
                "countryCode": "HN"
            },
            {
                "name": "Antigua Ocotepeque",
                "countryCode": "HN"
            },
            {
                "name": "Belén Gualcho",
                "countryCode": "HN"
            },
            {
                "name": "Concepción",
                "countryCode": "HN"
            },
            {
                "name": "Dolores Merendón",
                "countryCode": "HN"
            },
            {
                "name": "El Tránsito",
                "countryCode": "HN"
            },
            {
                "name": "Fraternidad",
                "countryCode": "HN"
            },
            {
                "name": "La Encarnación",
                "countryCode": "HN"
            },
            {
                "name": "La Labor",
                "countryCode": "HN"
            },
            {
                "name": "Lucerna",
                "countryCode": "HN"
            },
            {
                "name": "Mercedes",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Ocotepeque",
                "countryCode": "HN"
            },
            {
                "name": "San Fernando",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Cones",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco del Valle",
                "countryCode": "HN"
            },
            {
                "name": "San Jorge",
                "countryCode": "HN"
            },
            {
                "name": "San Marcos",
                "countryCode": "HN"
            },
            {
                "name": "Santa Fe",
                "countryCode": "HN"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "HN"
            },
            {
                "name": "Sensenti",
                "countryCode": "HN"
            },
            {
                "name": "Sinuapa",
                "countryCode": "HN"
            },
            {
                "name": "Yaruchel",
                "countryCode": "HN"
            },
            {
                "name": "Arimís",
                "countryCode": "HN"
            },
            {
                "name": "Campamento",
                "countryCode": "HN"
            },
            {
                "name": "Catacamas",
                "countryCode": "HN"
            },
            {
                "name": "Concordia",
                "countryCode": "HN"
            },
            {
                "name": "Dulce Nombre de Culmí",
                "countryCode": "HN"
            },
            {
                "name": "El Guayabito",
                "countryCode": "HN"
            },
            {
                "name": "El Rosario",
                "countryCode": "HN"
            },
            {
                "name": "El Rusio",
                "countryCode": "HN"
            },
            {
                "name": "Esquipulas del Norte",
                "countryCode": "HN"
            },
            {
                "name": "Gualaco",
                "countryCode": "HN"
            },
            {
                "name": "Guarizama",
                "countryCode": "HN"
            },
            {
                "name": "Guata",
                "countryCode": "HN"
            },
            {
                "name": "Guayape",
                "countryCode": "HN"
            },
            {
                "name": "Jano",
                "countryCode": "HN"
            },
            {
                "name": "Juticalpa",
                "countryCode": "HN"
            },
            {
                "name": "Jutiquile",
                "countryCode": "HN"
            },
            {
                "name": "La Concepción",
                "countryCode": "HN"
            },
            {
                "name": "La Estancia",
                "countryCode": "HN"
            },
            {
                "name": "La Guata",
                "countryCode": "HN"
            },
            {
                "name": "La Unión",
                "countryCode": "HN"
            },
            {
                "name": "Laguna Seca",
                "countryCode": "HN"
            },
            {
                "name": "Mangulile",
                "countryCode": "HN"
            },
            {
                "name": "Manto",
                "countryCode": "HN"
            },
            {
                "name": "Municipio de San Francisco de La Paz",
                "countryCode": "HN"
            },
            {
                "name": "Patuca",
                "countryCode": "HN"
            },
            {
                "name": "Punuare",
                "countryCode": "HN"
            },
            {
                "name": "Salamá",
                "countryCode": "HN"
            },
            {
                "name": "San Esteban",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Becerra",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de la Paz",
                "countryCode": "HN"
            },
            {
                "name": "San José de Río Tinto",
                "countryCode": "HN"
            },
            {
                "name": "San Nicolás",
                "countryCode": "HN"
            },
            {
                "name": "Santa María del Real",
                "countryCode": "HN"
            },
            {
                "name": "Silca",
                "countryCode": "HN"
            },
            {
                "name": "Yocón",
                "countryCode": "HN"
            },
            {
                "name": "Zopilotepe",
                "countryCode": "HN"
            },
            {
                "name": "Agualote",
                "countryCode": "HN"
            },
            {
                "name": "Arada",
                "countryCode": "HN"
            },
            {
                "name": "Atima",
                "countryCode": "HN"
            },
            {
                "name": "Azacualpa",
                "countryCode": "HN"
            },
            {
                "name": "Berlín",
                "countryCode": "HN"
            },
            {
                "name": "Callejones",
                "countryCode": "HN"
            },
            {
                "name": "Camalote",
                "countryCode": "HN"
            },
            {
                "name": "Casa Quemada",
                "countryCode": "HN"
            },
            {
                "name": "Ceguaca",
                "countryCode": "HN"
            },
            {
                "name": "Chinda",
                "countryCode": "HN"
            },
            {
                "name": "Concepción del Norte",
                "countryCode": "HN"
            },
            {
                "name": "Concepción del Sur",
                "countryCode": "HN"
            },
            {
                "name": "Correderos",
                "countryCode": "HN"
            },
            {
                "name": "El Ciruelo",
                "countryCode": "HN"
            },
            {
                "name": "El Corozal",
                "countryCode": "HN"
            },
            {
                "name": "El Mochito",
                "countryCode": "HN"
            },
            {
                "name": "El Níspero",
                "countryCode": "HN"
            },
            {
                "name": "Guacamaya",
                "countryCode": "HN"
            },
            {
                "name": "Gualala",
                "countryCode": "HN"
            },
            {
                "name": "Gualjoco",
                "countryCode": "HN"
            },
            {
                "name": "Ilama",
                "countryCode": "HN"
            },
            {
                "name": "Joconal",
                "countryCode": "HN"
            },
            {
                "name": "La Flecha",
                "countryCode": "HN"
            },
            {
                "name": "Laguna Verde",
                "countryCode": "HN"
            },
            {
                "name": "Las Vegas",
                "countryCode": "HN"
            },
            {
                "name": "Las Vegas, Santa Barbara",
                "countryCode": "HN"
            },
            {
                "name": "Loma Alta",
                "countryCode": "HN"
            },
            {
                "name": "Macuelizo",
                "countryCode": "HN"
            },
            {
                "name": "Naco",
                "countryCode": "HN"
            },
            {
                "name": "Naranjito",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Frontera",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Jalapa",
                "countryCode": "HN"
            },
            {
                "name": "Nuevo Celilac",
                "countryCode": "HN"
            },
            {
                "name": "Petoa",
                "countryCode": "HN"
            },
            {
                "name": "Pinalejo",
                "countryCode": "HN"
            },
            {
                "name": "Protección",
                "countryCode": "HN"
            },
            {
                "name": "Quimistán",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Ojuera",
                "countryCode": "HN"
            },
            {
                "name": "San José de Colinas",
                "countryCode": "HN"
            },
            {
                "name": "San José de Tarros",
                "countryCode": "HN"
            },
            {
                "name": "San Luis",
                "countryCode": "HN"
            },
            {
                "name": "San Luis de Planes",
                "countryCode": "HN"
            },
            {
                "name": "San Marcos",
                "countryCode": "HN"
            },
            {
                "name": "San Nicolás",
                "countryCode": "HN"
            },
            {
                "name": "San Pedro Zacapa",
                "countryCode": "HN"
            },
            {
                "name": "San Vicente Centenario",
                "countryCode": "HN"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rita",
                "countryCode": "HN"
            },
            {
                "name": "Sula",
                "countryCode": "HN"
            },
            {
                "name": "Tras Cerros",
                "countryCode": "HN"
            },
            {
                "name": "Trinidad",
                "countryCode": "HN"
            },
            {
                "name": "Agua Fría",
                "countryCode": "HN"
            },
            {
                "name": "Alianza",
                "countryCode": "HN"
            },
            {
                "name": "Amapala",
                "countryCode": "HN"
            },
            {
                "name": "Aramecina",
                "countryCode": "HN"
            },
            {
                "name": "Caridad",
                "countryCode": "HN"
            },
            {
                "name": "El Cubolero",
                "countryCode": "HN"
            },
            {
                "name": "El Tular",
                "countryCode": "HN"
            },
            {
                "name": "Goascorán",
                "countryCode": "HN"
            },
            {
                "name": "Jícaro Galán",
                "countryCode": "HN"
            },
            {
                "name": "La Alianza",
                "countryCode": "HN"
            },
            {
                "name": "La Criba",
                "countryCode": "HN"
            },
            {
                "name": "Langue",
                "countryCode": "HN"
            },
            {
                "name": "Nacaome",
                "countryCode": "HN"
            },
            {
                "name": "San Francisco de Coray",
                "countryCode": "HN"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "HN"
            },
            {
                "name": "Agua Blanca Sur",
                "countryCode": "HN"
            },
            {
                "name": "Arenal",
                "countryCode": "HN"
            },
            {
                "name": "Armenia",
                "countryCode": "HN"
            },
            {
                "name": "Ayapa",
                "countryCode": "HN"
            },
            {
                "name": "Bálsamo Oriental",
                "countryCode": "HN"
            },
            {
                "name": "Carbajales",
                "countryCode": "HN"
            },
            {
                "name": "Coyoles Central",
                "countryCode": "HN"
            },
            {
                "name": "El Bálsamo",
                "countryCode": "HN"
            },
            {
                "name": "El Juncal",
                "countryCode": "HN"
            },
            {
                "name": "El Negrito",
                "countryCode": "HN"
            },
            {
                "name": "El Ocote",
                "countryCode": "HN"
            },
            {
                "name": "El Progreso",
                "countryCode": "HN"
            },
            {
                "name": "Guaimitas",
                "countryCode": "HN"
            },
            {
                "name": "Jocón",
                "countryCode": "HN"
            },
            {
                "name": "La Estancia",
                "countryCode": "HN"
            },
            {
                "name": "La Guacamaya",
                "countryCode": "HN"
            },
            {
                "name": "La Mina",
                "countryCode": "HN"
            },
            {
                "name": "La Rosa",
                "countryCode": "HN"
            },
            {
                "name": "La Sarrosa",
                "countryCode": "HN"
            },
            {
                "name": "La Trinidad",
                "countryCode": "HN"
            },
            {
                "name": "Las Vegas",
                "countryCode": "HN"
            },
            {
                "name": "Lomitas",
                "countryCode": "HN"
            },
            {
                "name": "Mojimán",
                "countryCode": "HN"
            },
            {
                "name": "Morazán",
                "countryCode": "HN"
            },
            {
                "name": "Nombre de Jesús",
                "countryCode": "HN"
            },
            {
                "name": "Nueva Esperanza",
                "countryCode": "HN"
            },
            {
                "name": "Ocote Paulino",
                "countryCode": "HN"
            },
            {
                "name": "Olanchito",
                "countryCode": "HN"
            },
            {
                "name": "Paujiles",
                "countryCode": "HN"
            },
            {
                "name": "Punta Ocote",
                "countryCode": "HN"
            },
            {
                "name": "San Antonio",
                "countryCode": "HN"
            },
            {
                "name": "San José",
                "countryCode": "HN"
            },
            {
                "name": "Santa Rita",
                "countryCode": "HN"
            },
            {
                "name": "Subirana",
                "countryCode": "HN"
            },
            {
                "name": "Sulaco",
                "countryCode": "HN"
            },
            {
                "name": "Teguajinal",
                "countryCode": "HN"
            },
            {
                "name": "Tepusteca",
                "countryCode": "HN"
            },
            {
                "name": "Toyós",
                "countryCode": "HN"
            },
            {
                "name": "Trojas",
                "countryCode": "HN"
            },
            {
                "name": "Victoria",
                "countryCode": "HN"
            },
            {
                "name": "Yorito",
                "countryCode": "HN"
            },
            {
                "name": "Yoro",
                "countryCode": "HN"
            }
        ]
    },
    {
        "name": "Hong Kong S.A.R.",
        "phonecode": "852",
        "cities": []
    },
    {
        "name": "Hungary",
        "phonecode": "36",
        "cities": [
            {
                "name": "Beremend",
                "countryCode": "HU"
            },
            {
                "name": "Bóly",
                "countryCode": "HU"
            },
            {
                "name": "Bólyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Bükkösd",
                "countryCode": "HU"
            },
            {
                "name": "Dunaszekcső",
                "countryCode": "HU"
            },
            {
                "name": "Harkány",
                "countryCode": "HU"
            },
            {
                "name": "Hegyháti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hidas",
                "countryCode": "HU"
            },
            {
                "name": "Hosszúhetény",
                "countryCode": "HU"
            },
            {
                "name": "Komló",
                "countryCode": "HU"
            },
            {
                "name": "Komlói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kozármisleny",
                "countryCode": "HU"
            },
            {
                "name": "Lánycsók",
                "countryCode": "HU"
            },
            {
                "name": "Mecseknádasd",
                "countryCode": "HU"
            },
            {
                "name": "Mohács",
                "countryCode": "HU"
            },
            {
                "name": "Mohácsi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mágocs",
                "countryCode": "HU"
            },
            {
                "name": "Pellérd",
                "countryCode": "HU"
            },
            {
                "name": "Pécs",
                "countryCode": "HU"
            },
            {
                "name": "Pécsi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pécsvárad",
                "countryCode": "HU"
            },
            {
                "name": "Pécsváradi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sellye",
                "countryCode": "HU"
            },
            {
                "name": "Sellyei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Siklós",
                "countryCode": "HU"
            },
            {
                "name": "Siklósi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szentlőrinc",
                "countryCode": "HU"
            },
            {
                "name": "Szentlőrinci Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szigetvár",
                "countryCode": "HU"
            },
            {
                "name": "Szigetvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szászvár",
                "countryCode": "HU"
            },
            {
                "name": "Sásd",
                "countryCode": "HU"
            },
            {
                "name": "Vajszló",
                "countryCode": "HU"
            },
            {
                "name": "Villány",
                "countryCode": "HU"
            },
            {
                "name": "Battonya",
                "countryCode": "HU"
            },
            {
                "name": "Bucsa",
                "countryCode": "HU"
            },
            {
                "name": "Békés",
                "countryCode": "HU"
            },
            {
                "name": "Békéscsaba",
                "countryCode": "HU"
            },
            {
                "name": "Békéscsabai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Békési Járás",
                "countryCode": "HU"
            },
            {
                "name": "Békésszentandrás",
                "countryCode": "HU"
            },
            {
                "name": "Békéssámson",
                "countryCode": "HU"
            },
            {
                "name": "Csanádapáca",
                "countryCode": "HU"
            },
            {
                "name": "Csorvás",
                "countryCode": "HU"
            },
            {
                "name": "Doboz",
                "countryCode": "HU"
            },
            {
                "name": "Dombegyház",
                "countryCode": "HU"
            },
            {
                "name": "Dévaványa",
                "countryCode": "HU"
            },
            {
                "name": "Elek",
                "countryCode": "HU"
            },
            {
                "name": "Füzesgyarmat",
                "countryCode": "HU"
            },
            {
                "name": "Gyomaendrőd",
                "countryCode": "HU"
            },
            {
                "name": "Gyomaendrődi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Gyula",
                "countryCode": "HU"
            },
            {
                "name": "Gyulai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Gádoros",
                "countryCode": "HU"
            },
            {
                "name": "Kaszaper",
                "countryCode": "HU"
            },
            {
                "name": "Kevermes",
                "countryCode": "HU"
            },
            {
                "name": "Kondoros",
                "countryCode": "HU"
            },
            {
                "name": "Kunágota",
                "countryCode": "HU"
            },
            {
                "name": "Kétegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Körösladány",
                "countryCode": "HU"
            },
            {
                "name": "Köröstarcsa",
                "countryCode": "HU"
            },
            {
                "name": "Lőkösháza",
                "countryCode": "HU"
            },
            {
                "name": "Magyarbánhegyes",
                "countryCode": "HU"
            },
            {
                "name": "Medgyesegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Mezőberény",
                "countryCode": "HU"
            },
            {
                "name": "Mezőhegyes",
                "countryCode": "HU"
            },
            {
                "name": "Mezőkovácsháza",
                "countryCode": "HU"
            },
            {
                "name": "Mezőkovácsházai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Méhkerék",
                "countryCode": "HU"
            },
            {
                "name": "Nagyszénás",
                "countryCode": "HU"
            },
            {
                "name": "Okány",
                "countryCode": "HU"
            },
            {
                "name": "Orosháza",
                "countryCode": "HU"
            },
            {
                "name": "Orosházi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pusztaföldvár",
                "countryCode": "HU"
            },
            {
                "name": "Sarkad",
                "countryCode": "HU"
            },
            {
                "name": "Sarkadi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szabadkígyós",
                "countryCode": "HU"
            },
            {
                "name": "Szarvas",
                "countryCode": "HU"
            },
            {
                "name": "Szarvasi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szeghalmi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szeghalom",
                "countryCode": "HU"
            },
            {
                "name": "Tótkomlós",
                "countryCode": "HU"
            },
            {
                "name": "Vésztő",
                "countryCode": "HU"
            },
            {
                "name": "Újkígyós",
                "countryCode": "HU"
            },
            {
                "name": "Akasztó",
                "countryCode": "HU"
            },
            {
                "name": "Apostag",
                "countryCode": "HU"
            },
            {
                "name": "Baja",
                "countryCode": "HU"
            },
            {
                "name": "Bajai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ballószög",
                "countryCode": "HU"
            },
            {
                "name": "Bugac",
                "countryCode": "HU"
            },
            {
                "name": "Bácsalmás",
                "countryCode": "HU"
            },
            {
                "name": "Bácsalmási Járás",
                "countryCode": "HU"
            },
            {
                "name": "Bácsbokod",
                "countryCode": "HU"
            },
            {
                "name": "Bátya",
                "countryCode": "HU"
            },
            {
                "name": "Csengőd",
                "countryCode": "HU"
            },
            {
                "name": "Császártöltés",
                "countryCode": "HU"
            },
            {
                "name": "Csávoly",
                "countryCode": "HU"
            },
            {
                "name": "Dunapataj",
                "countryCode": "HU"
            },
            {
                "name": "Dunavecse",
                "countryCode": "HU"
            },
            {
                "name": "Dusnok",
                "countryCode": "HU"
            },
            {
                "name": "Dávod",
                "countryCode": "HU"
            },
            {
                "name": "Felsőszentiván",
                "countryCode": "HU"
            },
            {
                "name": "Fülöpjakab",
                "countryCode": "HU"
            },
            {
                "name": "Fülöpszállás",
                "countryCode": "HU"
            },
            {
                "name": "Gara",
                "countryCode": "HU"
            },
            {
                "name": "Hajós",
                "countryCode": "HU"
            },
            {
                "name": "Harta",
                "countryCode": "HU"
            },
            {
                "name": "Helvécia",
                "countryCode": "HU"
            },
            {
                "name": "Hercegszántó",
                "countryCode": "HU"
            },
            {
                "name": "Izsák",
                "countryCode": "HU"
            },
            {
                "name": "Jánoshalma",
                "countryCode": "HU"
            },
            {
                "name": "Jánoshalmai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Jászszentlászló",
                "countryCode": "HU"
            },
            {
                "name": "Kalocsa",
                "countryCode": "HU"
            },
            {
                "name": "Kalocsai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Katymár",
                "countryCode": "HU"
            },
            {
                "name": "Kecel",
                "countryCode": "HU"
            },
            {
                "name": "Kecskemét",
                "countryCode": "HU"
            },
            {
                "name": "Kecskeméti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kelebia",
                "countryCode": "HU"
            },
            {
                "name": "Kerekegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunfélegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunfélegyházi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunhalas",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunhalasi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunmajsa",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunmajsai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kiskőrös",
                "countryCode": "HU"
            },
            {
                "name": "Kiskőrösi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kisszállás",
                "countryCode": "HU"
            },
            {
                "name": "Kunfehértó",
                "countryCode": "HU"
            },
            {
                "name": "Kunszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Kunszentmiklósi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Lajosmizse",
                "countryCode": "HU"
            },
            {
                "name": "Lakitelek",
                "countryCode": "HU"
            },
            {
                "name": "Madaras",
                "countryCode": "HU"
            },
            {
                "name": "Mélykút",
                "countryCode": "HU"
            },
            {
                "name": "Nagybaracska",
                "countryCode": "HU"
            },
            {
                "name": "Nemesnádudvar",
                "countryCode": "HU"
            },
            {
                "name": "Nyárlőrinc",
                "countryCode": "HU"
            },
            {
                "name": "Orgovány",
                "countryCode": "HU"
            },
            {
                "name": "Pálmonostora",
                "countryCode": "HU"
            },
            {
                "name": "Solt",
                "countryCode": "HU"
            },
            {
                "name": "Soltvadkert",
                "countryCode": "HU"
            },
            {
                "name": "Szabadszállás",
                "countryCode": "HU"
            },
            {
                "name": "Szalkszentmárton",
                "countryCode": "HU"
            },
            {
                "name": "Szank",
                "countryCode": "HU"
            },
            {
                "name": "Szentkirály",
                "countryCode": "HU"
            },
            {
                "name": "Sükösd",
                "countryCode": "HU"
            },
            {
                "name": "Tass",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaalpár",
                "countryCode": "HU"
            },
            {
                "name": "Tiszakécske",
                "countryCode": "HU"
            },
            {
                "name": "Tiszakécskei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tompa",
                "countryCode": "HU"
            },
            {
                "name": "Tázlár",
                "countryCode": "HU"
            },
            {
                "name": "Vaskút",
                "countryCode": "HU"
            },
            {
                "name": "Városföld",
                "countryCode": "HU"
            },
            {
                "name": "Ágasegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Érsekcsanád",
                "countryCode": "HU"
            },
            {
                "name": "Budapest",
                "countryCode": "HU"
            },
            {
                "name": "Budapest I. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest II. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest III. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest IV. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest VI. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest VIII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest X. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XI. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XIII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XV. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XVI. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XVII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XVIII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XX. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XXI. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XXII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Budapest XXIII. kerület",
                "countryCode": "HU"
            },
            {
                "name": "Erzsébetváros",
                "countryCode": "HU"
            },
            {
                "name": "Józsefváros",
                "countryCode": "HU"
            },
            {
                "name": "Kispest",
                "countryCode": "HU"
            },
            {
                "name": "Zugló",
                "countryCode": "HU"
            },
            {
                "name": "Abaújszántó",
                "countryCode": "HU"
            },
            {
                "name": "Alsózsolca",
                "countryCode": "HU"
            },
            {
                "name": "Arló",
                "countryCode": "HU"
            },
            {
                "name": "Arnót",
                "countryCode": "HU"
            },
            {
                "name": "Aszaló",
                "countryCode": "HU"
            },
            {
                "name": "Bekecs",
                "countryCode": "HU"
            },
            {
                "name": "Bogács",
                "countryCode": "HU"
            },
            {
                "name": "Boldva",
                "countryCode": "HU"
            },
            {
                "name": "Borsodnádasd",
                "countryCode": "HU"
            },
            {
                "name": "Bőcs",
                "countryCode": "HU"
            },
            {
                "name": "Cigánd",
                "countryCode": "HU"
            },
            {
                "name": "Cigándi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Edelény",
                "countryCode": "HU"
            },
            {
                "name": "Edelényi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Emőd",
                "countryCode": "HU"
            },
            {
                "name": "Encs",
                "countryCode": "HU"
            },
            {
                "name": "Encsi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Farkaslyuk",
                "countryCode": "HU"
            },
            {
                "name": "Felsőzsolca",
                "countryCode": "HU"
            },
            {
                "name": "Gesztely",
                "countryCode": "HU"
            },
            {
                "name": "Gönc",
                "countryCode": "HU"
            },
            {
                "name": "Gönci Járás",
                "countryCode": "HU"
            },
            {
                "name": "Halmaj",
                "countryCode": "HU"
            },
            {
                "name": "Harsány",
                "countryCode": "HU"
            },
            {
                "name": "Hejőbába",
                "countryCode": "HU"
            },
            {
                "name": "Hernádnémeti",
                "countryCode": "HU"
            },
            {
                "name": "Izsófalva",
                "countryCode": "HU"
            },
            {
                "name": "Járdánháza",
                "countryCode": "HU"
            },
            {
                "name": "Karcsa",
                "countryCode": "HU"
            },
            {
                "name": "Kazincbarcika",
                "countryCode": "HU"
            },
            {
                "name": "Kazincbarcikai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Megyaszó",
                "countryCode": "HU"
            },
            {
                "name": "Mezőcsát",
                "countryCode": "HU"
            },
            {
                "name": "Mezőcsáti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mezőkeresztes",
                "countryCode": "HU"
            },
            {
                "name": "Mezőkövesd",
                "countryCode": "HU"
            },
            {
                "name": "Mezőkövesdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mezőzombor",
                "countryCode": "HU"
            },
            {
                "name": "Miskolc",
                "countryCode": "HU"
            },
            {
                "name": "Miskolci Járás",
                "countryCode": "HU"
            },
            {
                "name": "Monok",
                "countryCode": "HU"
            },
            {
                "name": "Mád",
                "countryCode": "HU"
            },
            {
                "name": "Mályi",
                "countryCode": "HU"
            },
            {
                "name": "Múcsony",
                "countryCode": "HU"
            },
            {
                "name": "Nyékládháza",
                "countryCode": "HU"
            },
            {
                "name": "Olaszliszka",
                "countryCode": "HU"
            },
            {
                "name": "Onga",
                "countryCode": "HU"
            },
            {
                "name": "Prügy",
                "countryCode": "HU"
            },
            {
                "name": "Putnok",
                "countryCode": "HU"
            },
            {
                "name": "Putnoki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ricse",
                "countryCode": "HU"
            },
            {
                "name": "Rudabánya",
                "countryCode": "HU"
            },
            {
                "name": "Sajóbábony",
                "countryCode": "HU"
            },
            {
                "name": "Sajókaza",
                "countryCode": "HU"
            },
            {
                "name": "Sajólád",
                "countryCode": "HU"
            },
            {
                "name": "Sajószentpéter",
                "countryCode": "HU"
            },
            {
                "name": "Sajószöged",
                "countryCode": "HU"
            },
            {
                "name": "Sajóvámos",
                "countryCode": "HU"
            },
            {
                "name": "Sajóörös",
                "countryCode": "HU"
            },
            {
                "name": "Szendrő",
                "countryCode": "HU"
            },
            {
                "name": "Szentistván",
                "countryCode": "HU"
            },
            {
                "name": "Szerencs",
                "countryCode": "HU"
            },
            {
                "name": "Szerencsi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szikszó",
                "countryCode": "HU"
            },
            {
                "name": "Szikszói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szirmabesenyő",
                "countryCode": "HU"
            },
            {
                "name": "Sály",
                "countryCode": "HU"
            },
            {
                "name": "Sárospatak",
                "countryCode": "HU"
            },
            {
                "name": "Sárospataki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sátoraljaújhely",
                "countryCode": "HU"
            },
            {
                "name": "Sátoraljaújhelyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Taktaharkány",
                "countryCode": "HU"
            },
            {
                "name": "Taktaszada",
                "countryCode": "HU"
            },
            {
                "name": "Tarcal",
                "countryCode": "HU"
            },
            {
                "name": "Tiszakarád",
                "countryCode": "HU"
            },
            {
                "name": "Tiszakeszi",
                "countryCode": "HU"
            },
            {
                "name": "Tiszalúc",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaújváros",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaújvárosi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tokaj",
                "countryCode": "HU"
            },
            {
                "name": "Tokaji Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tolcsva",
                "countryCode": "HU"
            },
            {
                "name": "Tállya",
                "countryCode": "HU"
            },
            {
                "name": "Ónod",
                "countryCode": "HU"
            },
            {
                "name": "Ózd",
                "countryCode": "HU"
            },
            {
                "name": "Ózdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Algyő",
                "countryCode": "HU"
            },
            {
                "name": "Apátfalva",
                "countryCode": "HU"
            },
            {
                "name": "Baks",
                "countryCode": "HU"
            },
            {
                "name": "Balástya",
                "countryCode": "HU"
            },
            {
                "name": "Bordány",
                "countryCode": "HU"
            },
            {
                "name": "Csanytelek",
                "countryCode": "HU"
            },
            {
                "name": "Csanádpalota",
                "countryCode": "HU"
            },
            {
                "name": "Csengele",
                "countryCode": "HU"
            },
            {
                "name": "Csongrád",
                "countryCode": "HU"
            },
            {
                "name": "Csongrádi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Deszk",
                "countryCode": "HU"
            },
            {
                "name": "Domaszék",
                "countryCode": "HU"
            },
            {
                "name": "Forráskút",
                "countryCode": "HU"
            },
            {
                "name": "Fábiánsebestyén",
                "countryCode": "HU"
            },
            {
                "name": "Földeák",
                "countryCode": "HU"
            },
            {
                "name": "Hódmezővásárhely",
                "countryCode": "HU"
            },
            {
                "name": "Hódmezővásárhelyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kistelek",
                "countryCode": "HU"
            },
            {
                "name": "Kisteleki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kiszombor",
                "countryCode": "HU"
            },
            {
                "name": "Makó",
                "countryCode": "HU"
            },
            {
                "name": "Makói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Maroslele",
                "countryCode": "HU"
            },
            {
                "name": "Mindszent",
                "countryCode": "HU"
            },
            {
                "name": "Mórahalmi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mórahalom",
                "countryCode": "HU"
            },
            {
                "name": "Pusztaszer",
                "countryCode": "HU"
            },
            {
                "name": "Ruzsa",
                "countryCode": "HU"
            },
            {
                "name": "Röszke",
                "countryCode": "HU"
            },
            {
                "name": "Szatymaz",
                "countryCode": "HU"
            },
            {
                "name": "Szeged",
                "countryCode": "HU"
            },
            {
                "name": "Szegedi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szegvár",
                "countryCode": "HU"
            },
            {
                "name": "Szentes",
                "countryCode": "HU"
            },
            {
                "name": "Szentesi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Székkutas",
                "countryCode": "HU"
            },
            {
                "name": "Sándorfalva",
                "countryCode": "HU"
            },
            {
                "name": "Tömörkény",
                "countryCode": "HU"
            },
            {
                "name": "Zsombó",
                "countryCode": "HU"
            },
            {
                "name": "Zákányszék",
                "countryCode": "HU"
            },
            {
                "name": "Ásotthalom",
                "countryCode": "HU"
            },
            {
                "name": "Ópusztaszer",
                "countryCode": "HU"
            },
            {
                "name": "Üllés",
                "countryCode": "HU"
            },
            {
                "name": "Aba",
                "countryCode": "HU"
            },
            {
                "name": "Adony",
                "countryCode": "HU"
            },
            {
                "name": "Alap",
                "countryCode": "HU"
            },
            {
                "name": "Bakonycsernye",
                "countryCode": "HU"
            },
            {
                "name": "Baracs",
                "countryCode": "HU"
            },
            {
                "name": "Baracska",
                "countryCode": "HU"
            },
            {
                "name": "Bicske",
                "countryCode": "HU"
            },
            {
                "name": "Bicskei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Bodajk",
                "countryCode": "HU"
            },
            {
                "name": "Cece",
                "countryCode": "HU"
            },
            {
                "name": "Csákvár",
                "countryCode": "HU"
            },
            {
                "name": "Dunaújvárosi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Dég",
                "countryCode": "HU"
            },
            {
                "name": "Előszállás",
                "countryCode": "HU"
            },
            {
                "name": "Enying",
                "countryCode": "HU"
            },
            {
                "name": "Enyingi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ercsi",
                "countryCode": "HU"
            },
            {
                "name": "Etyek",
                "countryCode": "HU"
            },
            {
                "name": "Fehérvárcsurgó",
                "countryCode": "HU"
            },
            {
                "name": "Gárdony",
                "countryCode": "HU"
            },
            {
                "name": "Gárdonyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Iváncsa",
                "countryCode": "HU"
            },
            {
                "name": "Kincsesbánya",
                "countryCode": "HU"
            },
            {
                "name": "Kisláng",
                "countryCode": "HU"
            },
            {
                "name": "Káloz",
                "countryCode": "HU"
            },
            {
                "name": "Kápolnásnyék",
                "countryCode": "HU"
            },
            {
                "name": "Lajoskomárom",
                "countryCode": "HU"
            },
            {
                "name": "Lepsény",
                "countryCode": "HU"
            },
            {
                "name": "Lovasberény",
                "countryCode": "HU"
            },
            {
                "name": "Martonvásár",
                "countryCode": "HU"
            },
            {
                "name": "Martonvásári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mezőfalva",
                "countryCode": "HU"
            },
            {
                "name": "Mezőszilas",
                "countryCode": "HU"
            },
            {
                "name": "Mány",
                "countryCode": "HU"
            },
            {
                "name": "Mór",
                "countryCode": "HU"
            },
            {
                "name": "Móri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Perkáta",
                "countryCode": "HU"
            },
            {
                "name": "Polgárdi",
                "countryCode": "HU"
            },
            {
                "name": "Pusztaszabolcs",
                "countryCode": "HU"
            },
            {
                "name": "Pusztavám",
                "countryCode": "HU"
            },
            {
                "name": "Pákozd",
                "countryCode": "HU"
            },
            {
                "name": "Pázmánd",
                "countryCode": "HU"
            },
            {
                "name": "Rácalmás",
                "countryCode": "HU"
            },
            {
                "name": "Ráckeresztúr",
                "countryCode": "HU"
            },
            {
                "name": "Seregélyes",
                "countryCode": "HU"
            },
            {
                "name": "Soponya",
                "countryCode": "HU"
            },
            {
                "name": "Szabadbattyán",
                "countryCode": "HU"
            },
            {
                "name": "Szárliget",
                "countryCode": "HU"
            },
            {
                "name": "Székesfehérvár",
                "countryCode": "HU"
            },
            {
                "name": "Székesfehérvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sárbogárd",
                "countryCode": "HU"
            },
            {
                "name": "Sárbogárdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sárkeresztúr",
                "countryCode": "HU"
            },
            {
                "name": "Sárosd",
                "countryCode": "HU"
            },
            {
                "name": "Sárszentmihály",
                "countryCode": "HU"
            },
            {
                "name": "Velence",
                "countryCode": "HU"
            },
            {
                "name": "Vál",
                "countryCode": "HU"
            },
            {
                "name": "Zámoly",
                "countryCode": "HU"
            },
            {
                "name": "dunaújváros",
                "countryCode": "HU"
            },
            {
                "name": "Abda",
                "countryCode": "HU"
            },
            {
                "name": "Bakonyszentlászló",
                "countryCode": "HU"
            },
            {
                "name": "Beled",
                "countryCode": "HU"
            },
            {
                "name": "Bőny",
                "countryCode": "HU"
            },
            {
                "name": "Bősárkány",
                "countryCode": "HU"
            },
            {
                "name": "Csorna",
                "countryCode": "HU"
            },
            {
                "name": "Csornai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Farád",
                "countryCode": "HU"
            },
            {
                "name": "Fertőd",
                "countryCode": "HU"
            },
            {
                "name": "Fertőrákos",
                "countryCode": "HU"
            },
            {
                "name": "Fertőszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Győr",
                "countryCode": "HU"
            },
            {
                "name": "Győri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Győrszemere",
                "countryCode": "HU"
            },
            {
                "name": "Győrújbarát",
                "countryCode": "HU"
            },
            {
                "name": "Halászi",
                "countryCode": "HU"
            },
            {
                "name": "Jánossomorja",
                "countryCode": "HU"
            },
            {
                "name": "Kapuvár",
                "countryCode": "HU"
            },
            {
                "name": "Kapuvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kimle",
                "countryCode": "HU"
            },
            {
                "name": "Kóny",
                "countryCode": "HU"
            },
            {
                "name": "Lébény",
                "countryCode": "HU"
            },
            {
                "name": "Mihályi",
                "countryCode": "HU"
            },
            {
                "name": "Mosonmagyaróvár",
                "countryCode": "HU"
            },
            {
                "name": "Mosonmagyaróvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mosonszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Nagycenk",
                "countryCode": "HU"
            },
            {
                "name": "Nyúl",
                "countryCode": "HU"
            },
            {
                "name": "Pannonhalma",
                "countryCode": "HU"
            },
            {
                "name": "Pannonhalmi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pér",
                "countryCode": "HU"
            },
            {
                "name": "Rajka",
                "countryCode": "HU"
            },
            {
                "name": "Rábapatona",
                "countryCode": "HU"
            },
            {
                "name": "Sopron",
                "countryCode": "HU"
            },
            {
                "name": "Soproni Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szany",
                "countryCode": "HU"
            },
            {
                "name": "Tét",
                "countryCode": "HU"
            },
            {
                "name": "Téti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Töltéstava",
                "countryCode": "HU"
            },
            {
                "name": "Ágfalva",
                "countryCode": "HU"
            },
            {
                "name": "Ásványráró",
                "countryCode": "HU"
            },
            {
                "name": "Öttevény",
                "countryCode": "HU"
            },
            {
                "name": "Bagamér",
                "countryCode": "HU"
            },
            {
                "name": "Balmazújváros",
                "countryCode": "HU"
            },
            {
                "name": "Balmazújvárosi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Berettyóújfalu",
                "countryCode": "HU"
            },
            {
                "name": "Berettyóújfalui Járás",
                "countryCode": "HU"
            },
            {
                "name": "Biharkeresztes",
                "countryCode": "HU"
            },
            {
                "name": "Biharnagybajom",
                "countryCode": "HU"
            },
            {
                "name": "Báránd",
                "countryCode": "HU"
            },
            {
                "name": "Csökmő",
                "countryCode": "HU"
            },
            {
                "name": "Debrecen",
                "countryCode": "HU"
            },
            {
                "name": "Debreceni Járás",
                "countryCode": "HU"
            },
            {
                "name": "Derecske",
                "countryCode": "HU"
            },
            {
                "name": "Derecskei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ebes",
                "countryCode": "HU"
            },
            {
                "name": "Egyek",
                "countryCode": "HU"
            },
            {
                "name": "Földes",
                "countryCode": "HU"
            },
            {
                "name": "Görbeháza",
                "countryCode": "HU"
            },
            {
                "name": "Hadjúszoboszlói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúbagos",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúböszörmény",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúböszörményi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúdorog",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúhadház",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúhadházi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúnánás",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúnánási Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúszoboszló",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúszovát",
                "countryCode": "HU"
            },
            {
                "name": "Hajdúsámson",
                "countryCode": "HU"
            },
            {
                "name": "Hortobágy",
                "countryCode": "HU"
            },
            {
                "name": "Hosszúpályi",
                "countryCode": "HU"
            },
            {
                "name": "Kaba",
                "countryCode": "HU"
            },
            {
                "name": "Komádi",
                "countryCode": "HU"
            },
            {
                "name": "Konyár",
                "countryCode": "HU"
            },
            {
                "name": "Létavértes",
                "countryCode": "HU"
            },
            {
                "name": "Mikepércs",
                "countryCode": "HU"
            },
            {
                "name": "Monostorpályi",
                "countryCode": "HU"
            },
            {
                "name": "Nagyrábé",
                "countryCode": "HU"
            },
            {
                "name": "Nyíracsád",
                "countryCode": "HU"
            },
            {
                "name": "Nyíradony",
                "countryCode": "HU"
            },
            {
                "name": "Nyíradonyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nyírmártonfalva",
                "countryCode": "HU"
            },
            {
                "name": "Nyírábrány",
                "countryCode": "HU"
            },
            {
                "name": "Nádudvar",
                "countryCode": "HU"
            },
            {
                "name": "Pocsaj",
                "countryCode": "HU"
            },
            {
                "name": "Polgár",
                "countryCode": "HU"
            },
            {
                "name": "Püspökladány",
                "countryCode": "HU"
            },
            {
                "name": "Püspökladányi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sárrétudvari",
                "countryCode": "HU"
            },
            {
                "name": "Sáránd",
                "countryCode": "HU"
            },
            {
                "name": "Tiszacsege",
                "countryCode": "HU"
            },
            {
                "name": "Téglás",
                "countryCode": "HU"
            },
            {
                "name": "Vámospércs",
                "countryCode": "HU"
            },
            {
                "name": "Abasár",
                "countryCode": "HU"
            },
            {
                "name": "Adács",
                "countryCode": "HU"
            },
            {
                "name": "Andornaktálya",
                "countryCode": "HU"
            },
            {
                "name": "Apc",
                "countryCode": "HU"
            },
            {
                "name": "Besenyőtelek",
                "countryCode": "HU"
            },
            {
                "name": "Boldog",
                "countryCode": "HU"
            },
            {
                "name": "Bélapátfalva",
                "countryCode": "HU"
            },
            {
                "name": "Bélapátfalvai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Csány",
                "countryCode": "HU"
            },
            {
                "name": "Domoszló",
                "countryCode": "HU"
            },
            {
                "name": "Ecséd",
                "countryCode": "HU"
            },
            {
                "name": "Eger",
                "countryCode": "HU"
            },
            {
                "name": "Egerszalók",
                "countryCode": "HU"
            },
            {
                "name": "Egri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Erdőtelek",
                "countryCode": "HU"
            },
            {
                "name": "Felsőtárkány",
                "countryCode": "HU"
            },
            {
                "name": "Füzesabony",
                "countryCode": "HU"
            },
            {
                "name": "Füzesabonyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyös",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyöshalász",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyösi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyöspata",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyössolymos",
                "countryCode": "HU"
            },
            {
                "name": "Gyöngyöstarján",
                "countryCode": "HU"
            },
            {
                "name": "Hatvan",
                "countryCode": "HU"
            },
            {
                "name": "Hatvani Járás",
                "countryCode": "HU"
            },
            {
                "name": "Heréd",
                "countryCode": "HU"
            },
            {
                "name": "Heves",
                "countryCode": "HU"
            },
            {
                "name": "Hevesi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hort",
                "countryCode": "HU"
            },
            {
                "name": "Karácsond",
                "countryCode": "HU"
            },
            {
                "name": "Kerecsend",
                "countryCode": "HU"
            },
            {
                "name": "Kisköre",
                "countryCode": "HU"
            },
            {
                "name": "Kompolt",
                "countryCode": "HU"
            },
            {
                "name": "Kál",
                "countryCode": "HU"
            },
            {
                "name": "Lőrinci",
                "countryCode": "HU"
            },
            {
                "name": "Maklár",
                "countryCode": "HU"
            },
            {
                "name": "Mátraderecske",
                "countryCode": "HU"
            },
            {
                "name": "Nagyréde",
                "countryCode": "HU"
            },
            {
                "name": "Ostoros",
                "countryCode": "HU"
            },
            {
                "name": "Parád",
                "countryCode": "HU"
            },
            {
                "name": "Parádsasvár",
                "countryCode": "HU"
            },
            {
                "name": "Petőfibánya",
                "countryCode": "HU"
            },
            {
                "name": "Poroszló",
                "countryCode": "HU"
            },
            {
                "name": "Pétervására",
                "countryCode": "HU"
            },
            {
                "name": "Pétervásárai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Recsk",
                "countryCode": "HU"
            },
            {
                "name": "Rózsaszentmárton",
                "countryCode": "HU"
            },
            {
                "name": "Sirok",
                "countryCode": "HU"
            },
            {
                "name": "Szihalom",
                "countryCode": "HU"
            },
            {
                "name": "Szilvásvárad",
                "countryCode": "HU"
            },
            {
                "name": "Tarnalelesz",
                "countryCode": "HU"
            },
            {
                "name": "Tarnaörs",
                "countryCode": "HU"
            },
            {
                "name": "Tiszanána",
                "countryCode": "HU"
            },
            {
                "name": "Verpelét",
                "countryCode": "HU"
            },
            {
                "name": "Vámosgyörk",
                "countryCode": "HU"
            },
            {
                "name": "Zagyvaszántó",
                "countryCode": "HU"
            },
            {
                "name": "Abádszalók",
                "countryCode": "HU"
            },
            {
                "name": "Alattyán",
                "countryCode": "HU"
            },
            {
                "name": "Besenyszög",
                "countryCode": "HU"
            },
            {
                "name": "Cibakháza",
                "countryCode": "HU"
            },
            {
                "name": "Cserkeszőlő",
                "countryCode": "HU"
            },
            {
                "name": "Fegyvernek",
                "countryCode": "HU"
            },
            {
                "name": "Jánoshida",
                "countryCode": "HU"
            },
            {
                "name": "Jászalsószentgyörgy",
                "countryCode": "HU"
            },
            {
                "name": "Jászapáti",
                "countryCode": "HU"
            },
            {
                "name": "Jászapáti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Jászberény",
                "countryCode": "HU"
            },
            {
                "name": "Jászberényi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Jászdózsa",
                "countryCode": "HU"
            },
            {
                "name": "Jászjákóhalma",
                "countryCode": "HU"
            },
            {
                "name": "Jászkisér",
                "countryCode": "HU"
            },
            {
                "name": "Jászladány",
                "countryCode": "HU"
            },
            {
                "name": "Jászszentandrás",
                "countryCode": "HU"
            },
            {
                "name": "Jászárokszállás",
                "countryCode": "HU"
            },
            {
                "name": "Karcag",
                "countryCode": "HU"
            },
            {
                "name": "Karcagi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kenderes",
                "countryCode": "HU"
            },
            {
                "name": "Kengyel",
                "countryCode": "HU"
            },
            {
                "name": "Kisújszállás",
                "countryCode": "HU"
            },
            {
                "name": "Kunhegyes",
                "countryCode": "HU"
            },
            {
                "name": "Kunhegyesi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kunmadaras",
                "countryCode": "HU"
            },
            {
                "name": "Kunszentmárton",
                "countryCode": "HU"
            },
            {
                "name": "Kunszentmártoni Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mezőtúr",
                "countryCode": "HU"
            },
            {
                "name": "Mezőtúri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Rákóczifalva",
                "countryCode": "HU"
            },
            {
                "name": "Rákócziújfalu",
                "countryCode": "HU"
            },
            {
                "name": "Szajol",
                "countryCode": "HU"
            },
            {
                "name": "Szelevény",
                "countryCode": "HU"
            },
            {
                "name": "Szolnok",
                "countryCode": "HU"
            },
            {
                "name": "Szolnoki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tiszabura",
                "countryCode": "HU"
            },
            {
                "name": "Tiszabő",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaföldvár",
                "countryCode": "HU"
            },
            {
                "name": "Tiszafüred",
                "countryCode": "HU"
            },
            {
                "name": "Tiszafüredi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tiszapüspöki",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaroff",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaszentimre",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaszőlős",
                "countryCode": "HU"
            },
            {
                "name": "Tiszasüly",
                "countryCode": "HU"
            },
            {
                "name": "Tószeg",
                "countryCode": "HU"
            },
            {
                "name": "Törökszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Törökszentmiklósi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Túrkeve",
                "countryCode": "HU"
            },
            {
                "name": "Zagyvarékas",
                "countryCode": "HU"
            },
            {
                "name": "Öcsöd",
                "countryCode": "HU"
            },
            {
                "name": "Újszász",
                "countryCode": "HU"
            },
            {
                "name": "Balassagyarmat",
                "countryCode": "HU"
            },
            {
                "name": "Balassagyarmati Járás",
                "countryCode": "HU"
            },
            {
                "name": "Bercel",
                "countryCode": "HU"
            },
            {
                "name": "Buják",
                "countryCode": "HU"
            },
            {
                "name": "Bátonyterenye",
                "countryCode": "HU"
            },
            {
                "name": "Bátonyterenyei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Diósjenő",
                "countryCode": "HU"
            },
            {
                "name": "Héhalom",
                "countryCode": "HU"
            },
            {
                "name": "Jobbágyi",
                "countryCode": "HU"
            },
            {
                "name": "Karancskeszi",
                "countryCode": "HU"
            },
            {
                "name": "Karancslapujtő",
                "countryCode": "HU"
            },
            {
                "name": "Kazár",
                "countryCode": "HU"
            },
            {
                "name": "Mátranovák",
                "countryCode": "HU"
            },
            {
                "name": "Mátraterenye",
                "countryCode": "HU"
            },
            {
                "name": "Mátraverebély",
                "countryCode": "HU"
            },
            {
                "name": "Nagyoroszi",
                "countryCode": "HU"
            },
            {
                "name": "Palotás",
                "countryCode": "HU"
            },
            {
                "name": "Pásztó",
                "countryCode": "HU"
            },
            {
                "name": "Pásztói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Rimóc",
                "countryCode": "HU"
            },
            {
                "name": "Romhány",
                "countryCode": "HU"
            },
            {
                "name": "Rétság",
                "countryCode": "HU"
            },
            {
                "name": "Rétsági Járás",
                "countryCode": "HU"
            },
            {
                "name": "Salgótarján",
                "countryCode": "HU"
            },
            {
                "name": "Salgótarjáni Járás",
                "countryCode": "HU"
            },
            {
                "name": "Somoskőújfalu",
                "countryCode": "HU"
            },
            {
                "name": "Szurdokpüspöki",
                "countryCode": "HU"
            },
            {
                "name": "Szécsény",
                "countryCode": "HU"
            },
            {
                "name": "Szécsényi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tar",
                "countryCode": "HU"
            },
            {
                "name": "Érsekvadkert",
                "countryCode": "HU"
            },
            {
                "name": "Abony",
                "countryCode": "HU"
            },
            {
                "name": "Acsa",
                "countryCode": "HU"
            },
            {
                "name": "Albertirsa",
                "countryCode": "HU"
            },
            {
                "name": "Alsónémedi",
                "countryCode": "HU"
            },
            {
                "name": "Aszód",
                "countryCode": "HU"
            },
            {
                "name": "Aszódi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Bag",
                "countryCode": "HU"
            },
            {
                "name": "Biatorbágy",
                "countryCode": "HU"
            },
            {
                "name": "Budakalász",
                "countryCode": "HU"
            },
            {
                "name": "Budakeszi",
                "countryCode": "HU"
            },
            {
                "name": "Budakeszi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Budaörs",
                "countryCode": "HU"
            },
            {
                "name": "Bugyi",
                "countryCode": "HU"
            },
            {
                "name": "Cegléd",
                "countryCode": "HU"
            },
            {
                "name": "Ceglédbercel",
                "countryCode": "HU"
            },
            {
                "name": "Ceglédi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Csemő",
                "countryCode": "HU"
            },
            {
                "name": "Csobánka",
                "countryCode": "HU"
            },
            {
                "name": "Csömör",
                "countryCode": "HU"
            },
            {
                "name": "Dabas",
                "countryCode": "HU"
            },
            {
                "name": "Dabasi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Diósd",
                "countryCode": "HU"
            },
            {
                "name": "Domony",
                "countryCode": "HU"
            },
            {
                "name": "Dunabogdány",
                "countryCode": "HU"
            },
            {
                "name": "Dunaharaszti",
                "countryCode": "HU"
            },
            {
                "name": "Dunakeszi",
                "countryCode": "HU"
            },
            {
                "name": "Dunakeszi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Dunavarsány",
                "countryCode": "HU"
            },
            {
                "name": "Dánszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Dány",
                "countryCode": "HU"
            },
            {
                "name": "Délegyháza",
                "countryCode": "HU"
            },
            {
                "name": "Dömsöd",
                "countryCode": "HU"
            },
            {
                "name": "Ecser",
                "countryCode": "HU"
            },
            {
                "name": "Erdőkertes",
                "countryCode": "HU"
            },
            {
                "name": "Farmos",
                "countryCode": "HU"
            },
            {
                "name": "Felsőpakony",
                "countryCode": "HU"
            },
            {
                "name": "Forrópuszta",
                "countryCode": "HU"
            },
            {
                "name": "Fót",
                "countryCode": "HU"
            },
            {
                "name": "Galgahévíz",
                "countryCode": "HU"
            },
            {
                "name": "Galgamácsa",
                "countryCode": "HU"
            },
            {
                "name": "Gomba",
                "countryCode": "HU"
            },
            {
                "name": "Gyál",
                "countryCode": "HU"
            },
            {
                "name": "Gyáli Járás",
                "countryCode": "HU"
            },
            {
                "name": "Gyömrő",
                "countryCode": "HU"
            },
            {
                "name": "Göd",
                "countryCode": "HU"
            },
            {
                "name": "Gödöllő",
                "countryCode": "HU"
            },
            {
                "name": "Gödöllői Járás",
                "countryCode": "HU"
            },
            {
                "name": "Halásztelek",
                "countryCode": "HU"
            },
            {
                "name": "Hernád",
                "countryCode": "HU"
            },
            {
                "name": "Hévízgyörk",
                "countryCode": "HU"
            },
            {
                "name": "Iklad",
                "countryCode": "HU"
            },
            {
                "name": "Inárcs",
                "countryCode": "HU"
            },
            {
                "name": "Isaszeg",
                "countryCode": "HU"
            },
            {
                "name": "Jászkarajenő",
                "countryCode": "HU"
            },
            {
                "name": "Kakucs",
                "countryCode": "HU"
            },
            {
                "name": "Kartal",
                "countryCode": "HU"
            },
            {
                "name": "Kerepes",
                "countryCode": "HU"
            },
            {
                "name": "Kiskunlacháza",
                "countryCode": "HU"
            },
            {
                "name": "Kismaros",
                "countryCode": "HU"
            },
            {
                "name": "Kistarcsa",
                "countryCode": "HU"
            },
            {
                "name": "Kocsér",
                "countryCode": "HU"
            },
            {
                "name": "Kosd",
                "countryCode": "HU"
            },
            {
                "name": "Kóka",
                "countryCode": "HU"
            },
            {
                "name": "Leányfalu",
                "countryCode": "HU"
            },
            {
                "name": "Maglód",
                "countryCode": "HU"
            },
            {
                "name": "Mende",
                "countryCode": "HU"
            },
            {
                "name": "Mogyoród",
                "countryCode": "HU"
            },
            {
                "name": "Monor",
                "countryCode": "HU"
            },
            {
                "name": "Monori Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nagykovácsi",
                "countryCode": "HU"
            },
            {
                "name": "Nagykáta",
                "countryCode": "HU"
            },
            {
                "name": "Nagykátai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nagykőrös",
                "countryCode": "HU"
            },
            {
                "name": "Nagykőrösi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nagymaros",
                "countryCode": "HU"
            },
            {
                "name": "Nagytarcsa",
                "countryCode": "HU"
            },
            {
                "name": "Nyáregyháza",
                "countryCode": "HU"
            },
            {
                "name": "Perbál",
                "countryCode": "HU"
            },
            {
                "name": "Pilis",
                "countryCode": "HU"
            },
            {
                "name": "Pilisborosjenő",
                "countryCode": "HU"
            },
            {
                "name": "Piliscsaba",
                "countryCode": "HU"
            },
            {
                "name": "Pilisszentiván",
                "countryCode": "HU"
            },
            {
                "name": "Pilisszentkereszt",
                "countryCode": "HU"
            },
            {
                "name": "Pilisszántó",
                "countryCode": "HU"
            },
            {
                "name": "Pilisvörösvár",
                "countryCode": "HU"
            },
            {
                "name": "Pilisvörösvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pomáz",
                "countryCode": "HU"
            },
            {
                "name": "Pánd",
                "countryCode": "HU"
            },
            {
                "name": "Páty",
                "countryCode": "HU"
            },
            {
                "name": "Pécel",
                "countryCode": "HU"
            },
            {
                "name": "Péteri",
                "countryCode": "HU"
            },
            {
                "name": "Ráckeve",
                "countryCode": "HU"
            },
            {
                "name": "Ráckevei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Solymár",
                "countryCode": "HU"
            },
            {
                "name": "Szada",
                "countryCode": "HU"
            },
            {
                "name": "Szentendre",
                "countryCode": "HU"
            },
            {
                "name": "Szentendrei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szentlőrinckáta",
                "countryCode": "HU"
            },
            {
                "name": "Szentmártonkáta",
                "countryCode": "HU"
            },
            {
                "name": "Szigetcsép",
                "countryCode": "HU"
            },
            {
                "name": "Szigethalom",
                "countryCode": "HU"
            },
            {
                "name": "Szigetszentmiklós",
                "countryCode": "HU"
            },
            {
                "name": "Szigetszentmiklósi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szigetújfalu",
                "countryCode": "HU"
            },
            {
                "name": "Szob",
                "countryCode": "HU"
            },
            {
                "name": "Szobi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Százhalombatta",
                "countryCode": "HU"
            },
            {
                "name": "Sződ",
                "countryCode": "HU"
            },
            {
                "name": "Sződliget",
                "countryCode": "HU"
            },
            {
                "name": "Sóskút",
                "countryCode": "HU"
            },
            {
                "name": "Sülysáp",
                "countryCode": "HU"
            },
            {
                "name": "Tahitótfalu",
                "countryCode": "HU"
            },
            {
                "name": "Taksony",
                "countryCode": "HU"
            },
            {
                "name": "Telki",
                "countryCode": "HU"
            },
            {
                "name": "Tura",
                "countryCode": "HU"
            },
            {
                "name": "Táborfalva",
                "countryCode": "HU"
            },
            {
                "name": "Tápióbicske",
                "countryCode": "HU"
            },
            {
                "name": "Tápiógyörgye",
                "countryCode": "HU"
            },
            {
                "name": "Tápiószecső",
                "countryCode": "HU"
            },
            {
                "name": "Tápiószele",
                "countryCode": "HU"
            },
            {
                "name": "Tápiószentmárton",
                "countryCode": "HU"
            },
            {
                "name": "Tápiószőlős",
                "countryCode": "HU"
            },
            {
                "name": "Tápióság",
                "countryCode": "HU"
            },
            {
                "name": "Tárnok",
                "countryCode": "HU"
            },
            {
                "name": "Tóalmás",
                "countryCode": "HU"
            },
            {
                "name": "Tököl",
                "countryCode": "HU"
            },
            {
                "name": "Törtel",
                "countryCode": "HU"
            },
            {
                "name": "Törökbálint",
                "countryCode": "HU"
            },
            {
                "name": "Valkó",
                "countryCode": "HU"
            },
            {
                "name": "Vecsés",
                "countryCode": "HU"
            },
            {
                "name": "Vecsési Járás",
                "countryCode": "HU"
            },
            {
                "name": "Veresegyház",
                "countryCode": "HU"
            },
            {
                "name": "Verőce",
                "countryCode": "HU"
            },
            {
                "name": "Visegrád",
                "countryCode": "HU"
            },
            {
                "name": "Vác",
                "countryCode": "HU"
            },
            {
                "name": "Váci Járás",
                "countryCode": "HU"
            },
            {
                "name": "Vácszentlászló",
                "countryCode": "HU"
            },
            {
                "name": "Zsámbok",
                "countryCode": "HU"
            },
            {
                "name": "Zsámbék",
                "countryCode": "HU"
            },
            {
                "name": "Érd",
                "countryCode": "HU"
            },
            {
                "name": "Érdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ócsa",
                "countryCode": "HU"
            },
            {
                "name": "Örkény",
                "countryCode": "HU"
            },
            {
                "name": "Újhartyán",
                "countryCode": "HU"
            },
            {
                "name": "Újszilvás",
                "countryCode": "HU"
            },
            {
                "name": "Úri",
                "countryCode": "HU"
            },
            {
                "name": "Üllő",
                "countryCode": "HU"
            },
            {
                "name": "Üröm",
                "countryCode": "HU"
            },
            {
                "name": "Őrbottyán",
                "countryCode": "HU"
            },
            {
                "name": "Babócsa",
                "countryCode": "HU"
            },
            {
                "name": "Balatonberény",
                "countryCode": "HU"
            },
            {
                "name": "Balatonboglár",
                "countryCode": "HU"
            },
            {
                "name": "Balatonfenyves",
                "countryCode": "HU"
            },
            {
                "name": "Balatonföldvár",
                "countryCode": "HU"
            },
            {
                "name": "Balatonlelle",
                "countryCode": "HU"
            },
            {
                "name": "Balatonszabadi",
                "countryCode": "HU"
            },
            {
                "name": "Balatonszárszó",
                "countryCode": "HU"
            },
            {
                "name": "Barcs",
                "countryCode": "HU"
            },
            {
                "name": "Barcsi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Berzence",
                "countryCode": "HU"
            },
            {
                "name": "Böhönye",
                "countryCode": "HU"
            },
            {
                "name": "Csurgó",
                "countryCode": "HU"
            },
            {
                "name": "Csurgói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Fonyód",
                "countryCode": "HU"
            },
            {
                "name": "Fonyódi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kadarkút",
                "countryCode": "HU"
            },
            {
                "name": "Kaposmérő",
                "countryCode": "HU"
            },
            {
                "name": "Kaposvár",
                "countryCode": "HU"
            },
            {
                "name": "Kaposvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Karád",
                "countryCode": "HU"
            },
            {
                "name": "Kéthely",
                "countryCode": "HU"
            },
            {
                "name": "Lengyeltóti",
                "countryCode": "HU"
            },
            {
                "name": "Lábod",
                "countryCode": "HU"
            },
            {
                "name": "Marcali",
                "countryCode": "HU"
            },
            {
                "name": "Marcali Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nagyatád",
                "countryCode": "HU"
            },
            {
                "name": "Nagyatádi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nagybajom",
                "countryCode": "HU"
            },
            {
                "name": "Segesd",
                "countryCode": "HU"
            },
            {
                "name": "Siófok",
                "countryCode": "HU"
            },
            {
                "name": "Siófoki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Somogyvár",
                "countryCode": "HU"
            },
            {
                "name": "Tab",
                "countryCode": "HU"
            },
            {
                "name": "Tabi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Taszár",
                "countryCode": "HU"
            },
            {
                "name": "Zamárdi",
                "countryCode": "HU"
            },
            {
                "name": "Ádánd",
                "countryCode": "HU"
            },
            {
                "name": "Ajak",
                "countryCode": "HU"
            },
            {
                "name": "Anarcs",
                "countryCode": "HU"
            },
            {
                "name": "Apagy",
                "countryCode": "HU"
            },
            {
                "name": "Aranyosapáti",
                "countryCode": "HU"
            },
            {
                "name": "Baktalórántháza",
                "countryCode": "HU"
            },
            {
                "name": "Baktalórántházai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Balkány",
                "countryCode": "HU"
            },
            {
                "name": "Buj",
                "countryCode": "HU"
            },
            {
                "name": "Bököny",
                "countryCode": "HU"
            },
            {
                "name": "Csenger",
                "countryCode": "HU"
            },
            {
                "name": "Csengeri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Demecser",
                "countryCode": "HU"
            },
            {
                "name": "Dombrád",
                "countryCode": "HU"
            },
            {
                "name": "Döge",
                "countryCode": "HU"
            },
            {
                "name": "Encsencs",
                "countryCode": "HU"
            },
            {
                "name": "Fehérgyarmat",
                "countryCode": "HU"
            },
            {
                "name": "Fehérgyarmati Járás",
                "countryCode": "HU"
            },
            {
                "name": "Fényeslitke",
                "countryCode": "HU"
            },
            {
                "name": "Gyulaháza",
                "countryCode": "HU"
            },
            {
                "name": "Gégény",
                "countryCode": "HU"
            },
            {
                "name": "Hodász",
                "countryCode": "HU"
            },
            {
                "name": "Ibrány",
                "countryCode": "HU"
            },
            {
                "name": "Ibrányi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kemecse",
                "countryCode": "HU"
            },
            {
                "name": "Kemecsei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kisléta",
                "countryCode": "HU"
            },
            {
                "name": "Kisvárda",
                "countryCode": "HU"
            },
            {
                "name": "Kisvárdai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kocsord",
                "countryCode": "HU"
            },
            {
                "name": "Kállósemjén",
                "countryCode": "HU"
            },
            {
                "name": "Kálmánháza",
                "countryCode": "HU"
            },
            {
                "name": "Kántorjánosi",
                "countryCode": "HU"
            },
            {
                "name": "Kék",
                "countryCode": "HU"
            },
            {
                "name": "Kótaj",
                "countryCode": "HU"
            },
            {
                "name": "Levelek",
                "countryCode": "HU"
            },
            {
                "name": "Mándok",
                "countryCode": "HU"
            },
            {
                "name": "Máriapócs",
                "countryCode": "HU"
            },
            {
                "name": "Mátészalka",
                "countryCode": "HU"
            },
            {
                "name": "Mátészalkai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Mérk",
                "countryCode": "HU"
            },
            {
                "name": "Nagycserkesz",
                "countryCode": "HU"
            },
            {
                "name": "Nagydobos",
                "countryCode": "HU"
            },
            {
                "name": "Nagyecsed",
                "countryCode": "HU"
            },
            {
                "name": "Nagyhalász",
                "countryCode": "HU"
            },
            {
                "name": "Nagykálló",
                "countryCode": "HU"
            },
            {
                "name": "Nagykállói Járás",
                "countryCode": "HU"
            },
            {
                "name": "Napkor",
                "countryCode": "HU"
            },
            {
                "name": "Nyírbogdány",
                "countryCode": "HU"
            },
            {
                "name": "Nyírbogát",
                "countryCode": "HU"
            },
            {
                "name": "Nyírbátor",
                "countryCode": "HU"
            },
            {
                "name": "Nyírbátori Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nyírbéltek",
                "countryCode": "HU"
            },
            {
                "name": "Nyírcsaholy",
                "countryCode": "HU"
            },
            {
                "name": "Nyíregyháza",
                "countryCode": "HU"
            },
            {
                "name": "Nyíregyházi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Nyírgyulaj",
                "countryCode": "HU"
            },
            {
                "name": "Nyírkarász",
                "countryCode": "HU"
            },
            {
                "name": "Nyírlugos",
                "countryCode": "HU"
            },
            {
                "name": "Nyírmada",
                "countryCode": "HU"
            },
            {
                "name": "Nyírmeggyes",
                "countryCode": "HU"
            },
            {
                "name": "Nyírmihálydi",
                "countryCode": "HU"
            },
            {
                "name": "Nyírpazony",
                "countryCode": "HU"
            },
            {
                "name": "Nyírtass",
                "countryCode": "HU"
            },
            {
                "name": "Nyírtelek",
                "countryCode": "HU"
            },
            {
                "name": "Nyírvasvári",
                "countryCode": "HU"
            },
            {
                "name": "Petneháza",
                "countryCode": "HU"
            },
            {
                "name": "Porcsalma",
                "countryCode": "HU"
            },
            {
                "name": "Pátroha",
                "countryCode": "HU"
            },
            {
                "name": "Rakamaz",
                "countryCode": "HU"
            },
            {
                "name": "Szakoly",
                "countryCode": "HU"
            },
            {
                "name": "Szamosszeg",
                "countryCode": "HU"
            },
            {
                "name": "Tarpa",
                "countryCode": "HU"
            },
            {
                "name": "Tiszabercel",
                "countryCode": "HU"
            },
            {
                "name": "Tiszabezdéd",
                "countryCode": "HU"
            },
            {
                "name": "Tiszadada",
                "countryCode": "HU"
            },
            {
                "name": "Tiszadob",
                "countryCode": "HU"
            },
            {
                "name": "Tiszaeszlár",
                "countryCode": "HU"
            },
            {
                "name": "Tiszalök",
                "countryCode": "HU"
            },
            {
                "name": "Tiszanagyfalu",
                "countryCode": "HU"
            },
            {
                "name": "Tiszavasvári",
                "countryCode": "HU"
            },
            {
                "name": "Tiszavasvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tornyospálca",
                "countryCode": "HU"
            },
            {
                "name": "Tunyogmatolcs",
                "countryCode": "HU"
            },
            {
                "name": "Tuzsér",
                "countryCode": "HU"
            },
            {
                "name": "Tyukod",
                "countryCode": "HU"
            },
            {
                "name": "Vaja",
                "countryCode": "HU"
            },
            {
                "name": "Vásárosnamény",
                "countryCode": "HU"
            },
            {
                "name": "Vásárosnaményi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Záhony",
                "countryCode": "HU"
            },
            {
                "name": "Záhonyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Ófehértó",
                "countryCode": "HU"
            },
            {
                "name": "Ópályi",
                "countryCode": "HU"
            },
            {
                "name": "Ököritófülpös",
                "countryCode": "HU"
            },
            {
                "name": "Újfehértó",
                "countryCode": "HU"
            },
            {
                "name": "Bogyiszló",
                "countryCode": "HU"
            },
            {
                "name": "Bonyhád",
                "countryCode": "HU"
            },
            {
                "name": "Bonyhádi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Báta",
                "countryCode": "HU"
            },
            {
                "name": "Bátaszék",
                "countryCode": "HU"
            },
            {
                "name": "Bölcske",
                "countryCode": "HU"
            },
            {
                "name": "Decs",
                "countryCode": "HU"
            },
            {
                "name": "Dombóvár",
                "countryCode": "HU"
            },
            {
                "name": "Dombóvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Dunaföldvár",
                "countryCode": "HU"
            },
            {
                "name": "Dunaszentgyörgy",
                "countryCode": "HU"
            },
            {
                "name": "Döbrököz",
                "countryCode": "HU"
            },
            {
                "name": "Fadd",
                "countryCode": "HU"
            },
            {
                "name": "Gyönk",
                "countryCode": "HU"
            },
            {
                "name": "Hőgyész",
                "countryCode": "HU"
            },
            {
                "name": "Iregszemcse",
                "countryCode": "HU"
            },
            {
                "name": "Madocsa",
                "countryCode": "HU"
            },
            {
                "name": "Nagydorog",
                "countryCode": "HU"
            },
            {
                "name": "Nagymányok",
                "countryCode": "HU"
            },
            {
                "name": "Németkér",
                "countryCode": "HU"
            },
            {
                "name": "Ozora",
                "countryCode": "HU"
            },
            {
                "name": "Paks",
                "countryCode": "HU"
            },
            {
                "name": "Paksi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pincehely",
                "countryCode": "HU"
            },
            {
                "name": "Simontornya",
                "countryCode": "HU"
            },
            {
                "name": "Szedres",
                "countryCode": "HU"
            },
            {
                "name": "Szekszárd",
                "countryCode": "HU"
            },
            {
                "name": "Szekszárdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szentgálpuszta",
                "countryCode": "HU"
            },
            {
                "name": "Tamási",
                "countryCode": "HU"
            },
            {
                "name": "Tamási Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tengelic",
                "countryCode": "HU"
            },
            {
                "name": "Tolna",
                "countryCode": "HU"
            },
            {
                "name": "Tolnai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Zomba",
                "countryCode": "HU"
            },
            {
                "name": "Őcsény",
                "countryCode": "HU"
            },
            {
                "name": "Bük",
                "countryCode": "HU"
            },
            {
                "name": "Celldömölk",
                "countryCode": "HU"
            },
            {
                "name": "Celldömölki Járás",
                "countryCode": "HU"
            },
            {
                "name": "Csepreg",
                "countryCode": "HU"
            },
            {
                "name": "Gencsapáti",
                "countryCode": "HU"
            },
            {
                "name": "Ják",
                "countryCode": "HU"
            },
            {
                "name": "Jánosháza",
                "countryCode": "HU"
            },
            {
                "name": "Körmend",
                "countryCode": "HU"
            },
            {
                "name": "Körmendi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Kőszeg",
                "countryCode": "HU"
            },
            {
                "name": "Kőszegi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Répcelak",
                "countryCode": "HU"
            },
            {
                "name": "Szentgotthárd",
                "countryCode": "HU"
            },
            {
                "name": "Szentgotthárdi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Szombathely",
                "countryCode": "HU"
            },
            {
                "name": "Szombathelyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Sárvár",
                "countryCode": "HU"
            },
            {
                "name": "Sárvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Táplánszentkereszt",
                "countryCode": "HU"
            },
            {
                "name": "Vasvár",
                "countryCode": "HU"
            },
            {
                "name": "Vasvári Járás",
                "countryCode": "HU"
            },
            {
                "name": "Vép",
                "countryCode": "HU"
            },
            {
                "name": "Ajka",
                "countryCode": "HU"
            },
            {
                "name": "Ajkai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Badacsonytomaj",
                "countryCode": "HU"
            },
            {
                "name": "Balatonalmádi",
                "countryCode": "HU"
            },
            {
                "name": "Balatonalmádi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Balatonfüred",
                "countryCode": "HU"
            },
            {
                "name": "Balatonfüredi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Balatonkenese",
                "countryCode": "HU"
            },
            {
                "name": "Berhida",
                "countryCode": "HU"
            },
            {
                "name": "Csabrendek",
                "countryCode": "HU"
            },
            {
                "name": "Csetény",
                "countryCode": "HU"
            },
            {
                "name": "Csopak",
                "countryCode": "HU"
            },
            {
                "name": "Devecser",
                "countryCode": "HU"
            },
            {
                "name": "Devecseri Járás",
                "countryCode": "HU"
            },
            {
                "name": "Hajmáskér",
                "countryCode": "HU"
            },
            {
                "name": "Herend",
                "countryCode": "HU"
            },
            {
                "name": "Litér",
                "countryCode": "HU"
            },
            {
                "name": "Nemesvámos",
                "countryCode": "HU"
            },
            {
                "name": "Pápa",
                "countryCode": "HU"
            },
            {
                "name": "Pápai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pétfürdő",
                "countryCode": "HU"
            },
            {
                "name": "Révfülöp",
                "countryCode": "HU"
            },
            {
                "name": "Szentkirályszabadja",
                "countryCode": "HU"
            },
            {
                "name": "Sümeg",
                "countryCode": "HU"
            },
            {
                "name": "Sümegi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tapolca",
                "countryCode": "HU"
            },
            {
                "name": "Tapolcai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Tihany",
                "countryCode": "HU"
            },
            {
                "name": "Veszprém",
                "countryCode": "HU"
            },
            {
                "name": "Veszprémi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Várpalota",
                "countryCode": "HU"
            },
            {
                "name": "Várpalotai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Zirc",
                "countryCode": "HU"
            },
            {
                "name": "Zirci Járás",
                "countryCode": "HU"
            },
            {
                "name": "Zánka",
                "countryCode": "HU"
            },
            {
                "name": "Úrkút",
                "countryCode": "HU"
            },
            {
                "name": "Ősi",
                "countryCode": "HU"
            },
            {
                "name": "Becsehely",
                "countryCode": "HU"
            },
            {
                "name": "Cserszegtomaj",
                "countryCode": "HU"
            },
            {
                "name": "Gyenesdiás",
                "countryCode": "HU"
            },
            {
                "name": "Hévíz",
                "countryCode": "HU"
            },
            {
                "name": "Keszthely",
                "countryCode": "HU"
            },
            {
                "name": "Keszthelyi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Lenti",
                "countryCode": "HU"
            },
            {
                "name": "Lenti Járás",
                "countryCode": "HU"
            },
            {
                "name": "Letenye",
                "countryCode": "HU"
            },
            {
                "name": "Letenyei Járás",
                "countryCode": "HU"
            },
            {
                "name": "Murakeresztúr",
                "countryCode": "HU"
            },
            {
                "name": "Nagykanizsa",
                "countryCode": "HU"
            },
            {
                "name": "Nagykanizsai Járás",
                "countryCode": "HU"
            },
            {
                "name": "Pacsa",
                "countryCode": "HU"
            },
            {
                "name": "Sármellék",
                "countryCode": "HU"
            },
            {
                "name": "Türje",
                "countryCode": "HU"
            },
            {
                "name": "Vonyarcvashegy",
                "countryCode": "HU"
            },
            {
                "name": "Zalaegerszeg",
                "countryCode": "HU"
            },
            {
                "name": "Zalaegerszegi Járás",
                "countryCode": "HU"
            },
            {
                "name": "Zalakomár",
                "countryCode": "HU"
            },
            {
                "name": "Zalalövő",
                "countryCode": "HU"
            },
            {
                "name": "Zalaszentgrót",
                "countryCode": "HU"
            },
            {
                "name": "Zalaszentgróti Járás",
                "countryCode": "HU"
            }
        ]
    },
    {
        "name": "Iceland",
        "phonecode": "354",
        "cities": [
            {
                "name": "Garðabær",
                "countryCode": "IS"
            },
            {
                "name": "Hafnarfjörður",
                "countryCode": "IS"
            },
            {
                "name": "Kjósarhreppur",
                "countryCode": "IS"
            },
            {
                "name": "Kópavogur",
                "countryCode": "IS"
            },
            {
                "name": "Mosfellsbaer",
                "countryCode": "IS"
            },
            {
                "name": "Mosfellsbær",
                "countryCode": "IS"
            },
            {
                "name": "Reykjavík",
                "countryCode": "IS"
            },
            {
                "name": "Seltjarnarnes",
                "countryCode": "IS"
            },
            {
                "name": "Álftanes",
                "countryCode": "IS"
            },
            {
                "name": "Garður",
                "countryCode": "IS"
            },
            {
                "name": "Grindavík",
                "countryCode": "IS"
            },
            {
                "name": "Keflavík",
                "countryCode": "IS"
            },
            {
                "name": "Reykjanesbær",
                "countryCode": "IS"
            },
            {
                "name": "Sandgerði",
                "countryCode": "IS"
            },
            {
                "name": "Vogar",
                "countryCode": "IS"
            },
            {
                "name": "Akranes",
                "countryCode": "IS"
            },
            {
                "name": "Borgarbyggð",
                "countryCode": "IS"
            },
            {
                "name": "Borgarnes",
                "countryCode": "IS"
            },
            {
                "name": "Dalabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Eyja- og Miklaholtshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Helgafellssveit",
                "countryCode": "IS"
            },
            {
                "name": "Hvalfjarðarsveit",
                "countryCode": "IS"
            },
            {
                "name": "Skorradalshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Snæfellsbær",
                "countryCode": "IS"
            },
            {
                "name": "Stykkishólmur",
                "countryCode": "IS"
            },
            {
                "name": "Ólafsvík",
                "countryCode": "IS"
            },
            {
                "name": "Reykhólahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Strandabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Tálknafjarðarhreppur",
                "countryCode": "IS"
            },
            {
                "name": "Ísafjarðarbær",
                "countryCode": "IS"
            },
            {
                "name": "Ísafjörður",
                "countryCode": "IS"
            },
            {
                "name": "Akrahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Húnaþing Vestra",
                "countryCode": "IS"
            },
            {
                "name": "Sauðárkrókur",
                "countryCode": "IS"
            },
            {
                "name": "Skagabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Sveitarfélagið Skagafjörður",
                "countryCode": "IS"
            },
            {
                "name": "Akureyri",
                "countryCode": "IS"
            },
            {
                "name": "Dalvík",
                "countryCode": "IS"
            },
            {
                "name": "Dalvíkurbyggð",
                "countryCode": "IS"
            },
            {
                "name": "Eyjafjarðarsveit",
                "countryCode": "IS"
            },
            {
                "name": "Fjallabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Grýtubakkahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Hörgársveit",
                "countryCode": "IS"
            },
            {
                "name": "Húsavík",
                "countryCode": "IS"
            },
            {
                "name": "Langanesbyggð",
                "countryCode": "IS"
            },
            {
                "name": "Laugar",
                "countryCode": "IS"
            },
            {
                "name": "Siglufjörður",
                "countryCode": "IS"
            },
            {
                "name": "Skútustaðahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Svalbarðsstrandarhreppur",
                "countryCode": "IS"
            },
            {
                "name": "Tjörneshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Þingeyjarsveit",
                "countryCode": "IS"
            },
            {
                "name": "Borgarfjarðarhreppur",
                "countryCode": "IS"
            },
            {
                "name": "Breiðdalshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Egilsstaðir",
                "countryCode": "IS"
            },
            {
                "name": "Eskifjörður",
                "countryCode": "IS"
            },
            {
                "name": "Fjarðabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Fljótsdalshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Fljótsdalshérað",
                "countryCode": "IS"
            },
            {
                "name": "Höfn",
                "countryCode": "IS"
            },
            {
                "name": "Neskaupstaður",
                "countryCode": "IS"
            },
            {
                "name": "Reyðarfjörður",
                "countryCode": "IS"
            },
            {
                "name": "Bláskógabyggð",
                "countryCode": "IS"
            },
            {
                "name": "Flóahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Grímsnes- og Grafningshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Hrunamannahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Hveragerði",
                "countryCode": "IS"
            },
            {
                "name": "Mýrdalshreppur",
                "countryCode": "IS"
            },
            {
                "name": "Selfoss",
                "countryCode": "IS"
            },
            {
                "name": "Skaftárhreppur",
                "countryCode": "IS"
            },
            {
                "name": "Skeiða- og Gnúpverjahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Vestmannaeyjabær",
                "countryCode": "IS"
            },
            {
                "name": "Vestmannaeyjar",
                "countryCode": "IS"
            },
            {
                "name": "Ásahreppur",
                "countryCode": "IS"
            },
            {
                "name": "Þorlákshöfn",
                "countryCode": "IS"
            }
        ]
    },
    {
        "name": "India",
        "phonecode": "91",
        "cities": [
            {
                "name": "Bamboo Flat",
                "countryCode": "IN"
            },
            {
                "name": "Nicobar",
                "countryCode": "IN"
            },
            {
                "name": "Port Blair",
                "countryCode": "IN"
            },
            {
                "name": "South Andaman",
                "countryCode": "IN"
            },
            {
                "name": "Addanki",
                "countryCode": "IN"
            },
            {
                "name": "Adoni",
                "countryCode": "IN"
            },
            {
                "name": "Akasahebpet",
                "countryCode": "IN"
            },
            {
                "name": "Akividu",
                "countryCode": "IN"
            },
            {
                "name": "Akkarampalle",
                "countryCode": "IN"
            },
            {
                "name": "Amalapuram",
                "countryCode": "IN"
            },
            {
                "name": "Amudalavalasa",
                "countryCode": "IN"
            },
            {
                "name": "Anakapalle",
                "countryCode": "IN"
            },
            {
                "name": "Anantapur",
                "countryCode": "IN"
            },
            {
                "name": "Atmakur",
                "countryCode": "IN"
            },
            {
                "name": "Attili",
                "countryCode": "IN"
            },
            {
                "name": "Avanigadda",
                "countryCode": "IN"
            },
            {
                "name": "Badvel",
                "countryCode": "IN"
            },
            {
                "name": "Banganapalle",
                "countryCode": "IN"
            },
            {
                "name": "Bapatla",
                "countryCode": "IN"
            },
            {
                "name": "Betamcherla",
                "countryCode": "IN"
            },
            {
                "name": "Bhattiprolu",
                "countryCode": "IN"
            },
            {
                "name": "Bhimavaram",
                "countryCode": "IN"
            },
            {
                "name": "Bhimunipatnam",
                "countryCode": "IN"
            },
            {
                "name": "Bobbili",
                "countryCode": "IN"
            },
            {
                "name": "Challapalle",
                "countryCode": "IN"
            },
            {
                "name": "Chemmumiahpet",
                "countryCode": "IN"
            },
            {
                "name": "Chilakalurupet",
                "countryCode": "IN"
            },
            {
                "name": "Chinnachowk",
                "countryCode": "IN"
            },
            {
                "name": "Chipurupalle",
                "countryCode": "IN"
            },
            {
                "name": "Chirala",
                "countryCode": "IN"
            },
            {
                "name": "Chittoor",
                "countryCode": "IN"
            },
            {
                "name": "Chodavaram",
                "countryCode": "IN"
            },
            {
                "name": "Cuddapah",
                "countryCode": "IN"
            },
            {
                "name": "Cumbum",
                "countryCode": "IN"
            },
            {
                "name": "Darsi",
                "countryCode": "IN"
            },
            {
                "name": "Dharmavaram",
                "countryCode": "IN"
            },
            {
                "name": "Dhone",
                "countryCode": "IN"
            },
            {
                "name": "Diguvametta",
                "countryCode": "IN"
            },
            {
                "name": "East Godavari",
                "countryCode": "IN"
            },
            {
                "name": "Elamanchili",
                "countryCode": "IN"
            },
            {
                "name": "Ellore",
                "countryCode": "IN"
            },
            {
                "name": "Emmiganur",
                "countryCode": "IN"
            },
            {
                "name": "Erraguntla",
                "countryCode": "IN"
            },
            {
                "name": "Etikoppaka",
                "countryCode": "IN"
            },
            {
                "name": "Gajuwaka",
                "countryCode": "IN"
            },
            {
                "name": "Ganguvada",
                "countryCode": "IN"
            },
            {
                "name": "Gannavaram",
                "countryCode": "IN"
            },
            {
                "name": "Giddalur",
                "countryCode": "IN"
            },
            {
                "name": "Gokavaram",
                "countryCode": "IN"
            },
            {
                "name": "Gorantla",
                "countryCode": "IN"
            },
            {
                "name": "Govindapuram,Chilakaluripet,Guntur",
                "countryCode": "IN"
            },
            {
                "name": "Gudivada",
                "countryCode": "IN"
            },
            {
                "name": "Gudlavalleru",
                "countryCode": "IN"
            },
            {
                "name": "Gudur",
                "countryCode": "IN"
            },
            {
                "name": "Guntakal Junction",
                "countryCode": "IN"
            },
            {
                "name": "Guntur",
                "countryCode": "IN"
            },
            {
                "name": "Hindupur",
                "countryCode": "IN"
            },
            {
                "name": "Ichchapuram",
                "countryCode": "IN"
            },
            {
                "name": "Jaggayyapeta",
                "countryCode": "IN"
            },
            {
                "name": "Jammalamadugu",
                "countryCode": "IN"
            },
            {
                "name": "Kadiri",
                "countryCode": "IN"
            },
            {
                "name": "Kaikalur",
                "countryCode": "IN"
            },
            {
                "name": "Kakinada",
                "countryCode": "IN"
            },
            {
                "name": "Kalyandurg",
                "countryCode": "IN"
            },
            {
                "name": "Kamalapuram",
                "countryCode": "IN"
            },
            {
                "name": "Kandukur",
                "countryCode": "IN"
            },
            {
                "name": "Kanigiri",
                "countryCode": "IN"
            },
            {
                "name": "Kankipadu",
                "countryCode": "IN"
            },
            {
                "name": "Kanuru",
                "countryCode": "IN"
            },
            {
                "name": "Kavali",
                "countryCode": "IN"
            },
            {
                "name": "Kolanukonda",
                "countryCode": "IN"
            },
            {
                "name": "Kondapalle",
                "countryCode": "IN"
            },
            {
                "name": "Korukollu",
                "countryCode": "IN"
            },
            {
                "name": "Kosigi",
                "countryCode": "IN"
            },
            {
                "name": "Kovvur",
                "countryCode": "IN"
            },
            {
                "name": "Krishna",
                "countryCode": "IN"
            },
            {
                "name": "Kuppam",
                "countryCode": "IN"
            },
            {
                "name": "Kurnool",
                "countryCode": "IN"
            },
            {
                "name": "Macherla",
                "countryCode": "IN"
            },
            {
                "name": "Machilipatnam",
                "countryCode": "IN"
            },
            {
                "name": "Madanapalle",
                "countryCode": "IN"
            },
            {
                "name": "Madugula",
                "countryCode": "IN"
            },
            {
                "name": "Mandapeta",
                "countryCode": "IN"
            },
            {
                "name": "Mandasa",
                "countryCode": "IN"
            },
            {
                "name": "Mangalagiri",
                "countryCode": "IN"
            },
            {
                "name": "Markapur",
                "countryCode": "IN"
            },
            {
                "name": "Nagari",
                "countryCode": "IN"
            },
            {
                "name": "Nagireddipalli",
                "countryCode": "IN"
            },
            {
                "name": "Nandigama",
                "countryCode": "IN"
            },
            {
                "name": "Nandikotkur",
                "countryCode": "IN"
            },
            {
                "name": "Nandyal",
                "countryCode": "IN"
            },
            {
                "name": "Narasannapeta",
                "countryCode": "IN"
            },
            {
                "name": "Narasapur",
                "countryCode": "IN"
            },
            {
                "name": "Narasaraopet",
                "countryCode": "IN"
            },
            {
                "name": "Narasingapuram",
                "countryCode": "IN"
            },
            {
                "name": "Narayanavanam",
                "countryCode": "IN"
            },
            {
                "name": "Narsipatnam",
                "countryCode": "IN"
            },
            {
                "name": "Nayudupet",
                "countryCode": "IN"
            },
            {
                "name": "Nellore",
                "countryCode": "IN"
            },
            {
                "name": "Nidadavole",
                "countryCode": "IN"
            },
            {
                "name": "Nuzvid",
                "countryCode": "IN"
            },
            {
                "name": "Ongole",
                "countryCode": "IN"
            },
            {
                "name": "Pakala",
                "countryCode": "IN"
            },
            {
                "name": "Palakollu",
                "countryCode": "IN"
            },
            {
                "name": "Palasa",
                "countryCode": "IN"
            },
            {
                "name": "Palkonda",
                "countryCode": "IN"
            },
            {
                "name": "Pallevada",
                "countryCode": "IN"
            },
            {
                "name": "Palmaner",
                "countryCode": "IN"
            },
            {
                "name": "Parlakimidi",
                "countryCode": "IN"
            },
            {
                "name": "Parvatipuram",
                "countryCode": "IN"
            },
            {
                "name": "Pavuluru",
                "countryCode": "IN"
            },
            {
                "name": "Pedana",
                "countryCode": "IN"
            },
            {
                "name": "Peddapuram",
                "countryCode": "IN"
            },
            {
                "name": "Penugonda",
                "countryCode": "IN"
            },
            {
                "name": "Penukonda",
                "countryCode": "IN"
            },
            {
                "name": "Phirangipuram",
                "countryCode": "IN"
            },
            {
                "name": "Pippara",
                "countryCode": "IN"
            },
            {
                "name": "Pithapuram",
                "countryCode": "IN"
            },
            {
                "name": "Polavaram",
                "countryCode": "IN"
            },
            {
                "name": "Ponnur",
                "countryCode": "IN"
            },
            {
                "name": "Ponnuru",
                "countryCode": "IN"
            },
            {
                "name": "Prakasam",
                "countryCode": "IN"
            },
            {
                "name": "Proddatur",
                "countryCode": "IN"
            },
            {
                "name": "Pulivendla",
                "countryCode": "IN"
            },
            {
                "name": "Punganuru",
                "countryCode": "IN"
            },
            {
                "name": "Puttaparthi",
                "countryCode": "IN"
            },
            {
                "name": "Puttur",
                "countryCode": "IN"
            },
            {
                "name": "Rajahmundry",
                "countryCode": "IN"
            },
            {
                "name": "Ramachandrapuram",
                "countryCode": "IN"
            },
            {
                "name": "Ramanayyapeta",
                "countryCode": "IN"
            },
            {
                "name": "Ramapuram",
                "countryCode": "IN"
            },
            {
                "name": "Rampachodavaram",
                "countryCode": "IN"
            },
            {
                "name": "Rayachoti",
                "countryCode": "IN"
            },
            {
                "name": "Rayadrug",
                "countryCode": "IN"
            },
            {
                "name": "Razam",
                "countryCode": "IN"
            },
            {
                "name": "Razampeta",
                "countryCode": "IN"
            },
            {
                "name": "Razole",
                "countryCode": "IN"
            },
            {
                "name": "Renigunta",
                "countryCode": "IN"
            },
            {
                "name": "Repalle",
                "countryCode": "IN"
            },
            {
                "name": "Salur",
                "countryCode": "IN"
            },
            {
                "name": "Samalkot",
                "countryCode": "IN"
            },
            {
                "name": "Sattenapalle",
                "countryCode": "IN"
            },
            {
                "name": "Singarayakonda",
                "countryCode": "IN"
            },
            {
                "name": "Sompeta",
                "countryCode": "IN"
            },
            {
                "name": "Srikakulam",
                "countryCode": "IN"
            },
            {
                "name": "Srisailain",
                "countryCode": "IN"
            },
            {
                "name": "Suluru",
                "countryCode": "IN"
            },
            {
                "name": "Tadepalle",
                "countryCode": "IN"
            },
            {
                "name": "Tadepallegudem",
                "countryCode": "IN"
            },
            {
                "name": "Tadpatri",
                "countryCode": "IN"
            },
            {
                "name": "Tanuku",
                "countryCode": "IN"
            },
            {
                "name": "Tekkali",
                "countryCode": "IN"
            },
            {
                "name": "Tirumala",
                "countryCode": "IN"
            },
            {
                "name": "Tirupati",
                "countryCode": "IN"
            },
            {
                "name": "Tuni",
                "countryCode": "IN"
            },
            {
                "name": "Uravakonda",
                "countryCode": "IN"
            },
            {
                "name": "Vadlapudi",
                "countryCode": "IN"
            },
            {
                "name": "Venkatagiri",
                "countryCode": "IN"
            },
            {
                "name": "Vepagunta",
                "countryCode": "IN"
            },
            {
                "name": "Vetapalem",
                "countryCode": "IN"
            },
            {
                "name": "Vijayawada",
                "countryCode": "IN"
            },
            {
                "name": "Vinukonda",
                "countryCode": "IN"
            },
            {
                "name": "Visakhapatnam",
                "countryCode": "IN"
            },
            {
                "name": "Vizianagaram",
                "countryCode": "IN"
            },
            {
                "name": "Vizianagaram District",
                "countryCode": "IN"
            },
            {
                "name": "Vuyyuru",
                "countryCode": "IN"
            },
            {
                "name": "West Godavari",
                "countryCode": "IN"
            },
            {
                "name": "Yanam",
                "countryCode": "IN"
            },
            {
                "name": "Yanamalakuduru",
                "countryCode": "IN"
            },
            {
                "name": "Yarada",
                "countryCode": "IN"
            },
            {
                "name": "pedda nakkalapalem",
                "countryCode": "IN"
            },
            {
                "name": "vadlamuru",
                "countryCode": "IN"
            },
            {
                "name": "Along",
                "countryCode": "IN"
            },
            {
                "name": "Anjaw",
                "countryCode": "IN"
            },
            {
                "name": "Basar",
                "countryCode": "IN"
            },
            {
                "name": "Bomdila",
                "countryCode": "IN"
            },
            {
                "name": "Changlang",
                "countryCode": "IN"
            },
            {
                "name": "Dibang Valley",
                "countryCode": "IN"
            },
            {
                "name": "East Kameng",
                "countryCode": "IN"
            },
            {
                "name": "East Siang",
                "countryCode": "IN"
            },
            {
                "name": "Hayuliang",
                "countryCode": "IN"
            },
            {
                "name": "Itanagar",
                "countryCode": "IN"
            },
            {
                "name": "Khonsa",
                "countryCode": "IN"
            },
            {
                "name": "Kurung Kumey",
                "countryCode": "IN"
            },
            {
                "name": "Lohit District",
                "countryCode": "IN"
            },
            {
                "name": "Lower Dibang Valley",
                "countryCode": "IN"
            },
            {
                "name": "Lower Subansiri",
                "countryCode": "IN"
            },
            {
                "name": "Margherita",
                "countryCode": "IN"
            },
            {
                "name": "Naharlagun",
                "countryCode": "IN"
            },
            {
                "name": "Pasighat",
                "countryCode": "IN"
            },
            {
                "name": "Tawang",
                "countryCode": "IN"
            },
            {
                "name": "Tezu",
                "countryCode": "IN"
            },
            {
                "name": "Tirap",
                "countryCode": "IN"
            },
            {
                "name": "Upper Siang",
                "countryCode": "IN"
            },
            {
                "name": "Upper Subansiri",
                "countryCode": "IN"
            },
            {
                "name": "West Kameng",
                "countryCode": "IN"
            },
            {
                "name": "West Siang",
                "countryCode": "IN"
            },
            {
                "name": "Ziro",
                "countryCode": "IN"
            },
            {
                "name": "Abhayapuri",
                "countryCode": "IN"
            },
            {
                "name": "Amguri",
                "countryCode": "IN"
            },
            {
                "name": "Badarpur",
                "countryCode": "IN"
            },
            {
                "name": "Baksa",
                "countryCode": "IN"
            },
            {
                "name": "Barpathar",
                "countryCode": "IN"
            },
            {
                "name": "Barpeta",
                "countryCode": "IN"
            },
            {
                "name": "Barpeta Road",
                "countryCode": "IN"
            },
            {
                "name": "Basugaon",
                "countryCode": "IN"
            },
            {
                "name": "Bihpuriagaon",
                "countryCode": "IN"
            },
            {
                "name": "Bijni",
                "countryCode": "IN"
            },
            {
                "name": "Bilasipara",
                "countryCode": "IN"
            },
            {
                "name": "Bokajan",
                "countryCode": "IN"
            },
            {
                "name": "Bokakhat",
                "countryCode": "IN"
            },
            {
                "name": "Bongaigaon",
                "countryCode": "IN"
            },
            {
                "name": "Cachar",
                "countryCode": "IN"
            },
            {
                "name": "Chabua",
                "countryCode": "IN"
            },
            {
                "name": "Chapar",
                "countryCode": "IN"
            },
            {
                "name": "Chirang",
                "countryCode": "IN"
            },
            {
                "name": "Darrang",
                "countryCode": "IN"
            },
            {
                "name": "Dergaon",
                "countryCode": "IN"
            },
            {
                "name": "Dhekiajuli",
                "countryCode": "IN"
            },
            {
                "name": "Dhemaji",
                "countryCode": "IN"
            },
            {
                "name": "Dhing",
                "countryCode": "IN"
            },
            {
                "name": "Dhubri",
                "countryCode": "IN"
            },
            {
                "name": "Dibrugarh",
                "countryCode": "IN"
            },
            {
                "name": "Digboi",
                "countryCode": "IN"
            },
            {
                "name": "Dima Hasao District",
                "countryCode": "IN"
            },
            {
                "name": "Diphu",
                "countryCode": "IN"
            },
            {
                "name": "Dispur",
                "countryCode": "IN"
            },
            {
                "name": "Duliagaon",
                "countryCode": "IN"
            },
            {
                "name": "Dum Duma",
                "countryCode": "IN"
            },
            {
                "name": "Gauripur",
                "countryCode": "IN"
            },
            {
                "name": "Goalpara",
                "countryCode": "IN"
            },
            {
                "name": "Gohpur",
                "countryCode": "IN"
            },
            {
                "name": "Golaghat",
                "countryCode": "IN"
            },
            {
                "name": "Golakganj",
                "countryCode": "IN"
            },
            {
                "name": "Goshaingaon",
                "countryCode": "IN"
            },
            {
                "name": "Guwahati",
                "countryCode": "IN"
            },
            {
                "name": "Haflong",
                "countryCode": "IN"
            },
            {
                "name": "Hailakandi",
                "countryCode": "IN"
            },
            {
                "name": "Hajo",
                "countryCode": "IN"
            },
            {
                "name": "Hojai",
                "countryCode": "IN"
            },
            {
                "name": "Howli",
                "countryCode": "IN"
            },
            {
                "name": "Jogighopa",
                "countryCode": "IN"
            },
            {
                "name": "Jorhat",
                "countryCode": "IN"
            },
            {
                "name": "Kamrup",
                "countryCode": "IN"
            },
            {
                "name": "Kamrup Metropolitan",
                "countryCode": "IN"
            },
            {
                "name": "Karbi Anglong",
                "countryCode": "IN"
            },
            {
                "name": "Karimganj",
                "countryCode": "IN"
            },
            {
                "name": "Kharupatia",
                "countryCode": "IN"
            },
            {
                "name": "Kokrajhar",
                "countryCode": "IN"
            },
            {
                "name": "Lakhimpur",
                "countryCode": "IN"
            },
            {
                "name": "Lakhipur",
                "countryCode": "IN"
            },
            {
                "name": "Lala",
                "countryCode": "IN"
            },
            {
                "name": "Lumding Railway Colony",
                "countryCode": "IN"
            },
            {
                "name": "Mahur",
                "countryCode": "IN"
            },
            {
                "name": "Maibong",
                "countryCode": "IN"
            },
            {
                "name": "Makum",
                "countryCode": "IN"
            },
            {
                "name": "Mangaldai",
                "countryCode": "IN"
            },
            {
                "name": "Mariani",
                "countryCode": "IN"
            },
            {
                "name": "Moranha",
                "countryCode": "IN"
            },
            {
                "name": "Morigaon",
                "countryCode": "IN"
            },
            {
                "name": "Nagaon",
                "countryCode": "IN"
            },
            {
                "name": "Nahorkatiya",
                "countryCode": "IN"
            },
            {
                "name": "Nalbari",
                "countryCode": "IN"
            },
            {
                "name": "Namrup",
                "countryCode": "IN"
            },
            {
                "name": "Nazira",
                "countryCode": "IN"
            },
            {
                "name": "North Guwahati",
                "countryCode": "IN"
            },
            {
                "name": "North Lakhimpur",
                "countryCode": "IN"
            },
            {
                "name": "Numaligarh",
                "countryCode": "IN"
            },
            {
                "name": "Palasbari",
                "countryCode": "IN"
            },
            {
                "name": "Raha",
                "countryCode": "IN"
            },
            {
                "name": "Rangapara",
                "countryCode": "IN"
            },
            {
                "name": "Rangia",
                "countryCode": "IN"
            },
            {
                "name": "Sapatgram",
                "countryCode": "IN"
            },
            {
                "name": "Sarupathar",
                "countryCode": "IN"
            },
            {
                "name": "Sibsagar",
                "countryCode": "IN"
            },
            {
                "name": "Silapathar",
                "countryCode": "IN"
            },
            {
                "name": "Silchar",
                "countryCode": "IN"
            },
            {
                "name": "Soalkuchi",
                "countryCode": "IN"
            },
            {
                "name": "Sonari",
                "countryCode": "IN"
            },
            {
                "name": "Sonitpur",
                "countryCode": "IN"
            },
            {
                "name": "Sorbhog",
                "countryCode": "IN"
            },
            {
                "name": "Tezpur",
                "countryCode": "IN"
            },
            {
                "name": "Tinsukia",
                "countryCode": "IN"
            },
            {
                "name": "Titabar",
                "countryCode": "IN"
            },
            {
                "name": "Udalguri",
                "countryCode": "IN"
            },
            {
                "name": "Amarpur",
                "countryCode": "IN"
            },
            {
                "name": "Araria",
                "countryCode": "IN"
            },
            {
                "name": "Arrah",
                "countryCode": "IN"
            },
            {
                "name": "Arwal",
                "countryCode": "IN"
            },
            {
                "name": "Asarganj",
                "countryCode": "IN"
            },
            {
                "name": "Aurangabad",
                "countryCode": "IN"
            },
            {
                "name": "Bagaha",
                "countryCode": "IN"
            },
            {
                "name": "Bahadurganj",
                "countryCode": "IN"
            },
            {
                "name": "Bairagnia",
                "countryCode": "IN"
            },
            {
                "name": "Baisi",
                "countryCode": "IN"
            },
            {
                "name": "Bakhtiyarpur",
                "countryCode": "IN"
            },
            {
                "name": "Bangaon",
                "countryCode": "IN"
            },
            {
                "name": "Banka",
                "countryCode": "IN"
            },
            {
                "name": "Banmankhi",
                "countryCode": "IN"
            },
            {
                "name": "Bar Bigha",
                "countryCode": "IN"
            },
            {
                "name": "Barauli",
                "countryCode": "IN"
            },
            {
                "name": "Barh",
                "countryCode": "IN"
            },
            {
                "name": "Barhiya",
                "countryCode": "IN"
            },
            {
                "name": "Bariarpur",
                "countryCode": "IN"
            },
            {
                "name": "Baruni",
                "countryCode": "IN"
            },
            {
                "name": "Begusarai",
                "countryCode": "IN"
            },
            {
                "name": "Belsand",
                "countryCode": "IN"
            },
            {
                "name": "Bettiah",
                "countryCode": "IN"
            },
            {
                "name": "Bhabhua",
                "countryCode": "IN"
            },
            {
                "name": "Bhagalpur",
                "countryCode": "IN"
            },
            {
                "name": "Bhagirathpur",
                "countryCode": "IN"
            },
            {
                "name": "Bhawanipur",
                "countryCode": "IN"
            },
            {
                "name": "Bhojpur",
                "countryCode": "IN"
            },
            {
                "name": "Bihar Sharif",
                "countryCode": "IN"
            },
            {
                "name": "Bihariganj",
                "countryCode": "IN"
            },
            {
                "name": "Bikramganj",
                "countryCode": "IN"
            },
            {
                "name": "Birpur",
                "countryCode": "IN"
            },
            {
                "name": "Buddh Gaya",
                "countryCode": "IN"
            },
            {
                "name": "Buxar",
                "countryCode": "IN"
            },
            {
                "name": "Chakia",
                "countryCode": "IN"
            },
            {
                "name": "Chapra",
                "countryCode": "IN"
            },
            {
                "name": "Chhatapur",
                "countryCode": "IN"
            },
            {
                "name": "Colgong",
                "countryCode": "IN"
            },
            {
                "name": "Dalsingh Sarai",
                "countryCode": "IN"
            },
            {
                "name": "Darbhanga",
                "countryCode": "IN"
            },
            {
                "name": "Daudnagar",
                "countryCode": "IN"
            },
            {
                "name": "Dehri",
                "countryCode": "IN"
            },
            {
                "name": "Dhaka",
                "countryCode": "IN"
            },
            {
                "name": "Dighwara",
                "countryCode": "IN"
            },
            {
                "name": "Dinapore",
                "countryCode": "IN"
            },
            {
                "name": "Dumra",
                "countryCode": "IN"
            },
            {
                "name": "Dumraon",
                "countryCode": "IN"
            },
            {
                "name": "Fatwa",
                "countryCode": "IN"
            },
            {
                "name": "Forbesganj",
                "countryCode": "IN"
            },
            {
                "name": "Gaya",
                "countryCode": "IN"
            },
            {
                "name": "Ghoga",
                "countryCode": "IN"
            },
            {
                "name": "Gopalganj",
                "countryCode": "IN"
            },
            {
                "name": "Hajipur",
                "countryCode": "IN"
            },
            {
                "name": "Hilsa",
                "countryCode": "IN"
            },
            {
                "name": "Hisua",
                "countryCode": "IN"
            },
            {
                "name": "Islampur",
                "countryCode": "IN"
            },
            {
                "name": "Jagdispur",
                "countryCode": "IN"
            },
            {
                "name": "Jahanabad",
                "countryCode": "IN"
            },
            {
                "name": "Jamalpur",
                "countryCode": "IN"
            },
            {
                "name": "Jamui",
                "countryCode": "IN"
            },
            {
                "name": "Jaynagar",
                "countryCode": "IN"
            },
            {
                "name": "Jehanabad",
                "countryCode": "IN"
            },
            {
                "name": "Jha-Jha",
                "countryCode": "IN"
            },
            {
                "name": "Jhanjharpur",
                "countryCode": "IN"
            },
            {
                "name": "Jogbani",
                "countryCode": "IN"
            },
            {
                "name": "Kaimur District",
                "countryCode": "IN"
            },
            {
                "name": "Kasba",
                "countryCode": "IN"
            },
            {
                "name": "Katihar",
                "countryCode": "IN"
            },
            {
                "name": "Khagaria",
                "countryCode": "IN"
            },
            {
                "name": "Khagaul",
                "countryCode": "IN"
            },
            {
                "name": "Kharagpur",
                "countryCode": "IN"
            },
            {
                "name": "Khusropur",
                "countryCode": "IN"
            },
            {
                "name": "Kishanganj",
                "countryCode": "IN"
            },
            {
                "name": "Koath",
                "countryCode": "IN"
            },
            {
                "name": "Koelwar",
                "countryCode": "IN"
            },
            {
                "name": "Lakhisarai",
                "countryCode": "IN"
            },
            {
                "name": "Lalganj",
                "countryCode": "IN"
            },
            {
                "name": "Luckeesarai",
                "countryCode": "IN"
            },
            {
                "name": "Madhepura",
                "countryCode": "IN"
            },
            {
                "name": "Madhipura",
                "countryCode": "IN"
            },
            {
                "name": "Madhubani",
                "countryCode": "IN"
            },
            {
                "name": "Maharajgani",
                "countryCode": "IN"
            },
            {
                "name": "Mairwa",
                "countryCode": "IN"
            },
            {
                "name": "Maner",
                "countryCode": "IN"
            },
            {
                "name": "Manihari",
                "countryCode": "IN"
            },
            {
                "name": "Marhaura",
                "countryCode": "IN"
            },
            {
                "name": "Masaurhi Buzurg",
                "countryCode": "IN"
            },
            {
                "name": "Mohiuddinnagar",
                "countryCode": "IN"
            },
            {
                "name": "Mokameh",
                "countryCode": "IN"
            },
            {
                "name": "Monghyr",
                "countryCode": "IN"
            },
            {
                "name": "Mothihari",
                "countryCode": "IN"
            },
            {
                "name": "Munger",
                "countryCode": "IN"
            },
            {
                "name": "Murliganj",
                "countryCode": "IN"
            },
            {
                "name": "Muzaffarpur",
                "countryCode": "IN"
            },
            {
                "name": "Nabinagar",
                "countryCode": "IN"
            },
            {
                "name": "Nalanda",
                "countryCode": "IN"
            },
            {
                "name": "Nasriganj",
                "countryCode": "IN"
            },
            {
                "name": "Naugachhia",
                "countryCode": "IN"
            },
            {
                "name": "Nawada",
                "countryCode": "IN"
            },
            {
                "name": "Nirmali",
                "countryCode": "IN"
            },
            {
                "name": "Pashchim Champaran",
                "countryCode": "IN"
            },
            {
                "name": "Patna",
                "countryCode": "IN"
            },
            {
                "name": "Piro",
                "countryCode": "IN"
            },
            {
                "name": "Pupri",
                "countryCode": "IN"
            },
            {
                "name": "Purba Champaran",
                "countryCode": "IN"
            },
            {
                "name": "Purnia",
                "countryCode": "IN"
            },
            {
                "name": "Rafiganj",
                "countryCode": "IN"
            },
            {
                "name": "Raghunathpur",
                "countryCode": "IN"
            },
            {
                "name": "Rajgir",
                "countryCode": "IN"
            },
            {
                "name": "Ramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Raxaul",
                "countryCode": "IN"
            },
            {
                "name": "Revelganj",
                "countryCode": "IN"
            },
            {
                "name": "Rohtas",
                "countryCode": "IN"
            },
            {
                "name": "Rusera",
                "countryCode": "IN"
            },
            {
                "name": "Sagauli",
                "countryCode": "IN"
            },
            {
                "name": "Saharsa",
                "countryCode": "IN"
            },
            {
                "name": "Samastipur",
                "countryCode": "IN"
            },
            {
                "name": "Saran",
                "countryCode": "IN"
            },
            {
                "name": "Shahbazpur",
                "countryCode": "IN"
            },
            {
                "name": "Shahpur",
                "countryCode": "IN"
            },
            {
                "name": "Sheikhpura",
                "countryCode": "IN"
            },
            {
                "name": "Sheohar",
                "countryCode": "IN"
            },
            {
                "name": "Sherghati",
                "countryCode": "IN"
            },
            {
                "name": "Silao",
                "countryCode": "IN"
            },
            {
                "name": "Sitamarhi",
                "countryCode": "IN"
            },
            {
                "name": "Siwan",
                "countryCode": "IN"
            },
            {
                "name": "Supaul",
                "countryCode": "IN"
            },
            {
                "name": "Teghra",
                "countryCode": "IN"
            },
            {
                "name": "Tekari",
                "countryCode": "IN"
            },
            {
                "name": "Thakurganj",
                "countryCode": "IN"
            },
            {
                "name": "Vaishali",
                "countryCode": "IN"
            },
            {
                "name": "Waris Aliganj",
                "countryCode": "IN"
            },
            {
                "name": "Chandigarh",
                "countryCode": "IN"
            },
            {
                "name": "Akaltara",
                "countryCode": "IN"
            },
            {
                "name": "Ambagarh Chauki",
                "countryCode": "IN"
            },
            {
                "name": "Ambikapur",
                "countryCode": "IN"
            },
            {
                "name": "Arang",
                "countryCode": "IN"
            },
            {
                "name": "Baikunthpur",
                "countryCode": "IN"
            },
            {
                "name": "Balod",
                "countryCode": "IN"
            },
            {
                "name": "Baloda",
                "countryCode": "IN"
            },
            {
                "name": "Baloda Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Basna",
                "countryCode": "IN"
            },
            {
                "name": "Bastar",
                "countryCode": "IN"
            },
            {
                "name": "Bemetara",
                "countryCode": "IN"
            },
            {
                "name": "Bhanpuri",
                "countryCode": "IN"
            },
            {
                "name": "Bhatapara",
                "countryCode": "IN"
            },
            {
                "name": "Bhatgaon",
                "countryCode": "IN"
            },
            {
                "name": "Bhilai",
                "countryCode": "IN"
            },
            {
                "name": "Bijapur",
                "countryCode": "IN"
            },
            {
                "name": "Bilaspur",
                "countryCode": "IN"
            },
            {
                "name": "Champa",
                "countryCode": "IN"
            },
            {
                "name": "Chhuikhadan",
                "countryCode": "IN"
            },
            {
                "name": "Deori",
                "countryCode": "IN"
            },
            {
                "name": "Dhamtari",
                "countryCode": "IN"
            },
            {
                "name": "Dongargaon",
                "countryCode": "IN"
            },
            {
                "name": "Dongargarh",
                "countryCode": "IN"
            },
            {
                "name": "Durg",
                "countryCode": "IN"
            },
            {
                "name": "Gandai",
                "countryCode": "IN"
            },
            {
                "name": "Gariaband",
                "countryCode": "IN"
            },
            {
                "name": "Gaurela",
                "countryCode": "IN"
            },
            {
                "name": "Gharghoda",
                "countryCode": "IN"
            },
            {
                "name": "Gidam",
                "countryCode": "IN"
            },
            {
                "name": "Jagdalpur",
                "countryCode": "IN"
            },
            {
                "name": "Janjgir",
                "countryCode": "IN"
            },
            {
                "name": "Janjgir-Champa",
                "countryCode": "IN"
            },
            {
                "name": "Jashpur",
                "countryCode": "IN"
            },
            {
                "name": "Jashpurnagar",
                "countryCode": "IN"
            },
            {
                "name": "Junagarh",
                "countryCode": "IN"
            },
            {
                "name": "Kabeerdham",
                "countryCode": "IN"
            },
            {
                "name": "Kanker",
                "countryCode": "IN"
            },
            {
                "name": "Katghora",
                "countryCode": "IN"
            },
            {
                "name": "Kawardha",
                "countryCode": "IN"
            },
            {
                "name": "Khairagarh",
                "countryCode": "IN"
            },
            {
                "name": "Khamharia",
                "countryCode": "IN"
            },
            {
                "name": "Kharod",
                "countryCode": "IN"
            },
            {
                "name": "Kharsia",
                "countryCode": "IN"
            },
            {
                "name": "Kirandul",
                "countryCode": "IN"
            },
            {
                "name": "Kondagaon",
                "countryCode": "IN"
            },
            {
                "name": "Korba",
                "countryCode": "IN"
            },
            {
                "name": "Koriya",
                "countryCode": "IN"
            },
            {
                "name": "Kota",
                "countryCode": "IN"
            },
            {
                "name": "Kotaparh",
                "countryCode": "IN"
            },
            {
                "name": "Kumhari",
                "countryCode": "IN"
            },
            {
                "name": "Kurud",
                "countryCode": "IN"
            },
            {
                "name": "Lormi",
                "countryCode": "IN"
            },
            {
                "name": "Mahasamund",
                "countryCode": "IN"
            },
            {
                "name": "Mungeli",
                "countryCode": "IN"
            },
            {
                "name": "Narayanpur",
                "countryCode": "IN"
            },
            {
                "name": "Narharpur",
                "countryCode": "IN"
            },
            {
                "name": "Pandaria",
                "countryCode": "IN"
            },
            {
                "name": "Pandatarai",
                "countryCode": "IN"
            },
            {
                "name": "Pasan",
                "countryCode": "IN"
            },
            {
                "name": "Patan",
                "countryCode": "IN"
            },
            {
                "name": "Pathalgaon",
                "countryCode": "IN"
            },
            {
                "name": "Pendra",
                "countryCode": "IN"
            },
            {
                "name": "Pithora",
                "countryCode": "IN"
            },
            {
                "name": "Raigarh",
                "countryCode": "IN"
            },
            {
                "name": "Raipur",
                "countryCode": "IN"
            },
            {
                "name": "Raj Nandgaon",
                "countryCode": "IN"
            },
            {
                "name": "Raj-Nandgaon",
                "countryCode": "IN"
            },
            {
                "name": "Ramanuj Ganj",
                "countryCode": "IN"
            },
            {
                "name": "Ratanpur",
                "countryCode": "IN"
            },
            {
                "name": "Sakti",
                "countryCode": "IN"
            },
            {
                "name": "Saraipali",
                "countryCode": "IN"
            },
            {
                "name": "Sarangarh",
                "countryCode": "IN"
            },
            {
                "name": "Seorinarayan",
                "countryCode": "IN"
            },
            {
                "name": "Simga",
                "countryCode": "IN"
            },
            {
                "name": "Surguja",
                "countryCode": "IN"
            },
            {
                "name": "Takhatpur",
                "countryCode": "IN"
            },
            {
                "name": "Umarkot",
                "countryCode": "IN"
            },
            {
                "name": "Uttar Bastar Kanker",
                "countryCode": "IN"
            },
            {
                "name": "Amli",
                "countryCode": "IN"
            },
            {
                "name": "Dadra",
                "countryCode": "IN"
            },
            {
                "name": "Dadra & Nagar Haveli",
                "countryCode": "IN"
            },
            {
                "name": "Daman",
                "countryCode": "IN"
            },
            {
                "name": "Daman District",
                "countryCode": "IN"
            },
            {
                "name": "Diu",
                "countryCode": "IN"
            },
            {
                "name": "Silvassa",
                "countryCode": "IN"
            },
            {
                "name": "Alipur",
                "countryCode": "IN"
            },
            {
                "name": "Bawana",
                "countryCode": "IN"
            },
            {
                "name": "Central Delhi",
                "countryCode": "IN"
            },
            {
                "name": "Delhi",
                "countryCode": "IN"
            },
            {
                "name": "Deoli",
                "countryCode": "IN"
            },
            {
                "name": "East Delhi",
                "countryCode": "IN"
            },
            {
                "name": "Karol Bagh",
                "countryCode": "IN"
            },
            {
                "name": "Najafgarh",
                "countryCode": "IN"
            },
            {
                "name": "Nangloi Jat",
                "countryCode": "IN"
            },
            {
                "name": "Narela",
                "countryCode": "IN"
            },
            {
                "name": "New Delhi",
                "countryCode": "IN"
            },
            {
                "name": "North Delhi",
                "countryCode": "IN"
            },
            {
                "name": "North East Delhi",
                "countryCode": "IN"
            },
            {
                "name": "North West Delhi",
                "countryCode": "IN"
            },
            {
                "name": "Pitampura",
                "countryCode": "IN"
            },
            {
                "name": "Rohini",
                "countryCode": "IN"
            },
            {
                "name": "South Delhi",
                "countryCode": "IN"
            },
            {
                "name": "South West Delhi",
                "countryCode": "IN"
            },
            {
                "name": "West Delhi",
                "countryCode": "IN"
            },
            {
                "name": "Aldona",
                "countryCode": "IN"
            },
            {
                "name": "Arambol",
                "countryCode": "IN"
            },
            {
                "name": "Baga",
                "countryCode": "IN"
            },
            {
                "name": "Bambolim",
                "countryCode": "IN"
            },
            {
                "name": "Bandora",
                "countryCode": "IN"
            },
            {
                "name": "Benaulim",
                "countryCode": "IN"
            },
            {
                "name": "Calangute",
                "countryCode": "IN"
            },
            {
                "name": "Candolim",
                "countryCode": "IN"
            },
            {
                "name": "Carapur",
                "countryCode": "IN"
            },
            {
                "name": "Cavelossim",
                "countryCode": "IN"
            },
            {
                "name": "Chicalim",
                "countryCode": "IN"
            },
            {
                "name": "Chinchinim",
                "countryCode": "IN"
            },
            {
                "name": "Colovale",
                "countryCode": "IN"
            },
            {
                "name": "Colva",
                "countryCode": "IN"
            },
            {
                "name": "Cortalim",
                "countryCode": "IN"
            },
            {
                "name": "Cuncolim",
                "countryCode": "IN"
            },
            {
                "name": "Curchorem",
                "countryCode": "IN"
            },
            {
                "name": "Curti",
                "countryCode": "IN"
            },
            {
                "name": "Davorlim",
                "countryCode": "IN"
            },
            {
                "name": "Dicholi",
                "countryCode": "IN"
            },
            {
                "name": "Goa Velha",
                "countryCode": "IN"
            },
            {
                "name": "Guirim",
                "countryCode": "IN"
            },
            {
                "name": "Jua",
                "countryCode": "IN"
            },
            {
                "name": "Kankon",
                "countryCode": "IN"
            },
            {
                "name": "Madgaon",
                "countryCode": "IN"
            },
            {
                "name": "Mapuca",
                "countryCode": "IN"
            },
            {
                "name": "Morjim",
                "countryCode": "IN"
            },
            {
                "name": "Mormugao",
                "countryCode": "IN"
            },
            {
                "name": "Navelim",
                "countryCode": "IN"
            },
            {
                "name": "North Goa",
                "countryCode": "IN"
            },
            {
                "name": "Palle",
                "countryCode": "IN"
            },
            {
                "name": "Panaji",
                "countryCode": "IN"
            },
            {
                "name": "Pernem",
                "countryCode": "IN"
            },
            {
                "name": "Ponda",
                "countryCode": "IN"
            },
            {
                "name": "Quepem",
                "countryCode": "IN"
            },
            {
                "name": "Queula",
                "countryCode": "IN"
            },
            {
                "name": "Raia",
                "countryCode": "IN"
            },
            {
                "name": "Saligao",
                "countryCode": "IN"
            },
            {
                "name": "Sancoale",
                "countryCode": "IN"
            },
            {
                "name": "Sanguem",
                "countryCode": "IN"
            },
            {
                "name": "Sanquelim",
                "countryCode": "IN"
            },
            {
                "name": "Sanvordem",
                "countryCode": "IN"
            },
            {
                "name": "Serula",
                "countryCode": "IN"
            },
            {
                "name": "Solim",
                "countryCode": "IN"
            },
            {
                "name": "South Goa",
                "countryCode": "IN"
            },
            {
                "name": "Taleigao",
                "countryCode": "IN"
            },
            {
                "name": "Vagator",
                "countryCode": "IN"
            },
            {
                "name": "Valpoy",
                "countryCode": "IN"
            },
            {
                "name": "Varca",
                "countryCode": "IN"
            },
            {
                "name": "Vasco da Gama",
                "countryCode": "IN"
            },
            {
                "name": "Abrama",
                "countryCode": "IN"
            },
            {
                "name": "Adalaj",
                "countryCode": "IN"
            },
            {
                "name": "Agol",
                "countryCode": "IN"
            },
            {
                "name": "Ahmedabad",
                "countryCode": "IN"
            },
            {
                "name": "Ahwa",
                "countryCode": "IN"
            },
            {
                "name": "Akrund",
                "countryCode": "IN"
            },
            {
                "name": "Amod",
                "countryCode": "IN"
            },
            {
                "name": "Amod,",
                "countryCode": "IN"
            },
            {
                "name": "Amreli",
                "countryCode": "IN"
            },
            {
                "name": "Amroli",
                "countryCode": "IN"
            },
            {
                "name": "Anand",
                "countryCode": "IN"
            },
            {
                "name": "Anjar",
                "countryCode": "IN"
            },
            {
                "name": "Ankleshwar",
                "countryCode": "IN"
            },
            {
                "name": "Babra",
                "countryCode": "IN"
            },
            {
                "name": "Bagasara",
                "countryCode": "IN"
            },
            {
                "name": "Bagasra",
                "countryCode": "IN"
            },
            {
                "name": "Bakharla",
                "countryCode": "IN"
            },
            {
                "name": "Balagam",
                "countryCode": "IN"
            },
            {
                "name": "Balasinor",
                "countryCode": "IN"
            },
            {
                "name": "Balisana",
                "countryCode": "IN"
            },
            {
                "name": "Bamanbore",
                "countryCode": "IN"
            },
            {
                "name": "Banas Kantha",
                "countryCode": "IN"
            },
            {
                "name": "Bandia",
                "countryCode": "IN"
            },
            {
                "name": "Bantva",
                "countryCode": "IN"
            },
            {
                "name": "Bardoli",
                "countryCode": "IN"
            },
            {
                "name": "Bedi",
                "countryCode": "IN"
            },
            {
                "name": "Bhachau",
                "countryCode": "IN"
            },
            {
                "name": "Bhadran",
                "countryCode": "IN"
            },
            {
                "name": "Bhandu",
                "countryCode": "IN"
            },
            {
                "name": "Bhanvad",
                "countryCode": "IN"
            },
            {
                "name": "Bharuch",
                "countryCode": "IN"
            },
            {
                "name": "Bhatha",
                "countryCode": "IN"
            },
            {
                "name": "Bhavnagar",
                "countryCode": "IN"
            },
            {
                "name": "Bhayavadar",
                "countryCode": "IN"
            },
            {
                "name": "Bhildi",
                "countryCode": "IN"
            },
            {
                "name": "Bhojpur Dharampur",
                "countryCode": "IN"
            },
            {
                "name": "Bhuj",
                "countryCode": "IN"
            },
            {
                "name": "Bilimora",
                "countryCode": "IN"
            },
            {
                "name": "Bilkha",
                "countryCode": "IN"
            },
            {
                "name": "Borsad",
                "countryCode": "IN"
            },
            {
                "name": "Botad",
                "countryCode": "IN"
            },
            {
                "name": "Chaklasi",
                "countryCode": "IN"
            },
            {
                "name": "Chalala",
                "countryCode": "IN"
            },
            {
                "name": "Chaloda",
                "countryCode": "IN"
            },
            {
                "name": "Champaner",
                "countryCode": "IN"
            },
            {
                "name": "Chanasma",
                "countryCode": "IN"
            },
            {
                "name": "Chhala",
                "countryCode": "IN"
            },
            {
                "name": "Chhota Udepur",
                "countryCode": "IN"
            },
            {
                "name": "Chikhli",
                "countryCode": "IN"
            },
            {
                "name": "Chotila",
                "countryCode": "IN"
            },
            {
                "name": "Chuda",
                "countryCode": "IN"
            },
            {
                "name": "Dabhoda",
                "countryCode": "IN"
            },
            {
                "name": "Dabhoi",
                "countryCode": "IN"
            },
            {
                "name": "Dahegam",
                "countryCode": "IN"
            },
            {
                "name": "Dahod",
                "countryCode": "IN"
            },
            {
                "name": "Dakor",
                "countryCode": "IN"
            },
            {
                "name": "Damnagar",
                "countryCode": "IN"
            },
            {
                "name": "Dandi",
                "countryCode": "IN"
            },
            {
                "name": "Dangs (India)",
                "countryCode": "IN"
            },
            {
                "name": "Danta",
                "countryCode": "IN"
            },
            {
                "name": "Dayapar",
                "countryCode": "IN"
            },
            {
                "name": "Delvada",
                "countryCode": "IN"
            },
            {
                "name": "Delwada",
                "countryCode": "IN"
            },
            {
                "name": "Detroj",
                "countryCode": "IN"
            },
            {
                "name": "Devbhumi Dwarka",
                "countryCode": "IN"
            },
            {
                "name": "Devgadh Bariya",
                "countryCode": "IN"
            },
            {
                "name": "Dhandhuka",
                "countryCode": "IN"
            },
            {
                "name": "Dhanera",
                "countryCode": "IN"
            },
            {
                "name": "Dhansura",
                "countryCode": "IN"
            },
            {
                "name": "Dharampur",
                "countryCode": "IN"
            },
            {
                "name": "Dharasana",
                "countryCode": "IN"
            },
            {
                "name": "Dhari",
                "countryCode": "IN"
            },
            {
                "name": "Dhasa",
                "countryCode": "IN"
            },
            {
                "name": "Dhola",
                "countryCode": "IN"
            },
            {
                "name": "Dholera",
                "countryCode": "IN"
            },
            {
                "name": "Dholka",
                "countryCode": "IN"
            },
            {
                "name": "Dhoraji",
                "countryCode": "IN"
            },
            {
                "name": "Dhrangadhra",
                "countryCode": "IN"
            },
            {
                "name": "Dhrol",
                "countryCode": "IN"
            },
            {
                "name": "Dhuwaran",
                "countryCode": "IN"
            },
            {
                "name": "Disa",
                "countryCode": "IN"
            },
            {
                "name": "Dohad",
                "countryCode": "IN"
            },
            {
                "name": "Dumkhal",
                "countryCode": "IN"
            },
            {
                "name": "Dungarpur",
                "countryCode": "IN"
            },
            {
                "name": "Dwarka",
                "countryCode": "IN"
            },
            {
                "name": "Gadhada",
                "countryCode": "IN"
            },
            {
                "name": "Gandevi",
                "countryCode": "IN"
            },
            {
                "name": "Gandhidham",
                "countryCode": "IN"
            },
            {
                "name": "Gandhinagar",
                "countryCode": "IN"
            },
            {
                "name": "Gariadhar",
                "countryCode": "IN"
            },
            {
                "name": "Ghodasar",
                "countryCode": "IN"
            },
            {
                "name": "Ghogha",
                "countryCode": "IN"
            },
            {
                "name": "Gir Somnath",
                "countryCode": "IN"
            },
            {
                "name": "Godhra",
                "countryCode": "IN"
            },
            {
                "name": "Gondal",
                "countryCode": "IN"
            },
            {
                "name": "Gorwa",
                "countryCode": "IN"
            },
            {
                "name": "Halenda",
                "countryCode": "IN"
            },
            {
                "name": "Halol",
                "countryCode": "IN"
            },
            {
                "name": "Halvad",
                "countryCode": "IN"
            },
            {
                "name": "Hansot",
                "countryCode": "IN"
            },
            {
                "name": "Harij",
                "countryCode": "IN"
            },
            {
                "name": "Harsol",
                "countryCode": "IN"
            },
            {
                "name": "Hathuran",
                "countryCode": "IN"
            },
            {
                "name": "Himatnagar",
                "countryCode": "IN"
            },
            {
                "name": "Idar",
                "countryCode": "IN"
            },
            {
                "name": "Jakhau",
                "countryCode": "IN"
            },
            {
                "name": "Jalalpore",
                "countryCode": "IN"
            },
            {
                "name": "Jalalpur",
                "countryCode": "IN"
            },
            {
                "name": "Jalia",
                "countryCode": "IN"
            },
            {
                "name": "Jambuda",
                "countryCode": "IN"
            },
            {
                "name": "Jambusar",
                "countryCode": "IN"
            },
            {
                "name": "Jamnagar",
                "countryCode": "IN"
            },
            {
                "name": "Jarod,",
                "countryCode": "IN"
            },
            {
                "name": "Jasdan",
                "countryCode": "IN"
            },
            {
                "name": "Jetalpur",
                "countryCode": "IN"
            },
            {
                "name": "Jetalsar",
                "countryCode": "IN"
            },
            {
                "name": "Jetpur",
                "countryCode": "IN"
            },
            {
                "name": "Jetpur (Navagadh)",
                "countryCode": "IN"
            },
            {
                "name": "Jhalod",
                "countryCode": "IN"
            },
            {
                "name": "Jhulasan",
                "countryCode": "IN"
            },
            {
                "name": "Jodhpur",
                "countryCode": "IN"
            },
            {
                "name": "Jodhpur (Ahmedabad)",
                "countryCode": "IN"
            },
            {
                "name": "Jodia",
                "countryCode": "IN"
            },
            {
                "name": "Jodiya Bandar",
                "countryCode": "IN"
            },
            {
                "name": "Junagadh",
                "countryCode": "IN"
            },
            {
                "name": "Kachchh",
                "countryCode": "IN"
            },
            {
                "name": "Kachholi",
                "countryCode": "IN"
            },
            {
                "name": "Kadi",
                "countryCode": "IN"
            },
            {
                "name": "Kadod",
                "countryCode": "IN"
            },
            {
                "name": "Kalavad",
                "countryCode": "IN"
            },
            {
                "name": "Kalol",
                "countryCode": "IN"
            },
            {
                "name": "Kandla",
                "countryCode": "IN"
            },
            {
                "name": "Kandla port",
                "countryCode": "IN"
            },
            {
                "name": "Kanodar",
                "countryCode": "IN"
            },
            {
                "name": "Kapadvanj",
                "countryCode": "IN"
            },
            {
                "name": "Karamsad",
                "countryCode": "IN"
            },
            {
                "name": "Kariana",
                "countryCode": "IN"
            },
            {
                "name": "Karjan",
                "countryCode": "IN"
            },
            {
                "name": "Kathor",
                "countryCode": "IN"
            },
            {
                "name": "Katpur",
                "countryCode": "IN"
            },
            {
                "name": "Kavant",
                "countryCode": "IN"
            },
            {
                "name": "Kawant",
                "countryCode": "IN"
            },
            {
                "name": "Kayavarohan",
                "countryCode": "IN"
            },
            {
                "name": "Kerwada",
                "countryCode": "IN"
            },
            {
                "name": "Keshod",
                "countryCode": "IN"
            },
            {
                "name": "Khambhalia",
                "countryCode": "IN"
            },
            {
                "name": "Khambhat",
                "countryCode": "IN"
            },
            {
                "name": "Khavda",
                "countryCode": "IN"
            },
            {
                "name": "Kheda",
                "countryCode": "IN"
            },
            {
                "name": "Khedbrahma",
                "countryCode": "IN"
            },
            {
                "name": "Khedoi",
                "countryCode": "IN"
            },
            {
                "name": "Kherali",
                "countryCode": "IN"
            },
            {
                "name": "Kheralu",
                "countryCode": "IN"
            },
            {
                "name": "Kodinar",
                "countryCode": "IN"
            },
            {
                "name": "Kosamba",
                "countryCode": "IN"
            },
            {
                "name": "Kothara",
                "countryCode": "IN"
            },
            {
                "name": "Kotharia",
                "countryCode": "IN"
            },
            {
                "name": "Kukarmunda",
                "countryCode": "IN"
            },
            {
                "name": "Kukma",
                "countryCode": "IN"
            },
            {
                "name": "Kundla",
                "countryCode": "IN"
            },
            {
                "name": "Kutch district",
                "countryCode": "IN"
            },
            {
                "name": "Kutiyana",
                "countryCode": "IN"
            },
            {
                "name": "Ladol",
                "countryCode": "IN"
            },
            {
                "name": "Lakhpat",
                "countryCode": "IN"
            },
            {
                "name": "Lakhtar",
                "countryCode": "IN"
            },
            {
                "name": "Lalpur",
                "countryCode": "IN"
            },
            {
                "name": "Langhnaj",
                "countryCode": "IN"
            },
            {
                "name": "Lathi",
                "countryCode": "IN"
            },
            {
                "name": "Limbdi",
                "countryCode": "IN"
            },
            {
                "name": "Limkheda",
                "countryCode": "IN"
            },
            {
                "name": "Lunavada",
                "countryCode": "IN"
            },
            {
                "name": "Madhavpur Ghed",
                "countryCode": "IN"
            },
            {
                "name": "Madhi",
                "countryCode": "IN"
            },
            {
                "name": "Mahemdavad",
                "countryCode": "IN"
            },
            {
                "name": "Mahesana",
                "countryCode": "IN"
            },
            {
                "name": "Mahisa",
                "countryCode": "IN"
            },
            {
                "name": "Mahudha",
                "countryCode": "IN"
            },
            {
                "name": "Mahuva",
                "countryCode": "IN"
            },
            {
                "name": "Mahuva (Surat)",
                "countryCode": "IN"
            },
            {
                "name": "Malpur",
                "countryCode": "IN"
            },
            {
                "name": "Manavadar",
                "countryCode": "IN"
            },
            {
                "name": "Mandal",
                "countryCode": "IN"
            },
            {
                "name": "Mandvi",
                "countryCode": "IN"
            },
            {
                "name": "Mandvi (Surat)",
                "countryCode": "IN"
            },
            {
                "name": "Mangrol",
                "countryCode": "IN"
            },
            {
                "name": "Mangrol (Junagadh)",
                "countryCode": "IN"
            },
            {
                "name": "Mansa",
                "countryCode": "IN"
            },
            {
                "name": "Meghraj",
                "countryCode": "IN"
            },
            {
                "name": "Mehsana",
                "countryCode": "IN"
            },
            {
                "name": "Mendarda",
                "countryCode": "IN"
            },
            {
                "name": "Mithapur",
                "countryCode": "IN"
            },
            {
                "name": "Modasa",
                "countryCode": "IN"
            },
            {
                "name": "Morbi",
                "countryCode": "IN"
            },
            {
                "name": "Morva (Hadaf)",
                "countryCode": "IN"
            },
            {
                "name": "Morwa",
                "countryCode": "IN"
            },
            {
                "name": "Mundra",
                "countryCode": "IN"
            },
            {
                "name": "Nadiad",
                "countryCode": "IN"
            },
            {
                "name": "Nadiad,",
                "countryCode": "IN"
            },
            {
                "name": "Nagwa",
                "countryCode": "IN"
            },
            {
                "name": "Naldhara",
                "countryCode": "IN"
            },
            {
                "name": "Naliya",
                "countryCode": "IN"
            },
            {
                "name": "Nargol",
                "countryCode": "IN"
            },
            {
                "name": "Narmada",
                "countryCode": "IN"
            },
            {
                "name": "Naroda",
                "countryCode": "IN"
            },
            {
                "name": "Navsari",
                "countryCode": "IN"
            },
            {
                "name": "Nikora",
                "countryCode": "IN"
            },
            {
                "name": "Nizar",
                "countryCode": "IN"
            },
            {
                "name": "Odadar",
                "countryCode": "IN"
            },
            {
                "name": "Okha",
                "countryCode": "IN"
            },
            {
                "name": "Olpad",
                "countryCode": "IN"
            },
            {
                "name": "Paddhari",
                "countryCode": "IN"
            },
            {
                "name": "Padra",
                "countryCode": "IN"
            },
            {
                "name": "Palanpur",
                "countryCode": "IN"
            },
            {
                "name": "Palanswa",
                "countryCode": "IN"
            },
            {
                "name": "Palitana",
                "countryCode": "IN"
            },
            {
                "name": "Paliyad",
                "countryCode": "IN"
            },
            {
                "name": "Paliyad (Bhavnagar)",
                "countryCode": "IN"
            },
            {
                "name": "Palsana",
                "countryCode": "IN"
            },
            {
                "name": "Panch Mahals",
                "countryCode": "IN"
            },
            {
                "name": "Panchmahal district",
                "countryCode": "IN"
            },
            {
                "name": "Pardi",
                "countryCode": "IN"
            },
            {
                "name": "Parnera",
                "countryCode": "IN"
            },
            {
                "name": "Patan",
                "countryCode": "IN"
            },
            {
                "name": "Pavi Jetpur",
                "countryCode": "IN"
            },
            {
                "name": "Petlad",
                "countryCode": "IN"
            },
            {
                "name": "Pipavav",
                "countryCode": "IN"
            },
            {
                "name": "Piplod",
                "countryCode": "IN"
            },
            {
                "name": "Porbandar",
                "countryCode": "IN"
            },
            {
                "name": "Prabhas Patan",
                "countryCode": "IN"
            },
            {
                "name": "Prantij",
                "countryCode": "IN"
            },
            {
                "name": "Radhanpur",
                "countryCode": "IN"
            },
            {
                "name": "Rajkot",
                "countryCode": "IN"
            },
            {
                "name": "Rajpipla",
                "countryCode": "IN"
            },
            {
                "name": "Rajula",
                "countryCode": "IN"
            },
            {
                "name": "Ranavav",
                "countryCode": "IN"
            },
            {
                "name": "Ranpur",
                "countryCode": "IN"
            },
            {
                "name": "Rapar",
                "countryCode": "IN"
            },
            {
                "name": "Reha",
                "countryCode": "IN"
            },
            {
                "name": "Roha",
                "countryCode": "IN"
            },
            {
                "name": "Sabar Kantha",
                "countryCode": "IN"
            },
            {
                "name": "Sachin",
                "countryCode": "IN"
            },
            {
                "name": "Salaya",
                "countryCode": "IN"
            },
            {
                "name": "Samakhiali",
                "countryCode": "IN"
            },
            {
                "name": "Sanand",
                "countryCode": "IN"
            },
            {
                "name": "Sankheda",
                "countryCode": "IN"
            },
            {
                "name": "Sarbhon",
                "countryCode": "IN"
            },
            {
                "name": "Sardoi",
                "countryCode": "IN"
            },
            {
                "name": "Sarkhej",
                "countryCode": "IN"
            },
            {
                "name": "Sathamba",
                "countryCode": "IN"
            },
            {
                "name": "Savarkundla",
                "countryCode": "IN"
            },
            {
                "name": "Savli",
                "countryCode": "IN"
            },
            {
                "name": "Sayla",
                "countryCode": "IN"
            },
            {
                "name": "Shahpur",
                "countryCode": "IN"
            },
            {
                "name": "Shivrajpur",
                "countryCode": "IN"
            },
            {
                "name": "Siddhpur",
                "countryCode": "IN"
            },
            {
                "name": "Sihor",
                "countryCode": "IN"
            },
            {
                "name": "Sikka",
                "countryCode": "IN"
            },
            {
                "name": "Sinor",
                "countryCode": "IN"
            },
            {
                "name": "Sojitra",
                "countryCode": "IN"
            },
            {
                "name": "Songadh",
                "countryCode": "IN"
            },
            {
                "name": "Supedi",
                "countryCode": "IN"
            },
            {
                "name": "Surat",
                "countryCode": "IN"
            },
            {
                "name": "Surendranagar",
                "countryCode": "IN"
            },
            {
                "name": "Sutrapada",
                "countryCode": "IN"
            },
            {
                "name": "Talaja",
                "countryCode": "IN"
            },
            {
                "name": "Tankara",
                "countryCode": "IN"
            },
            {
                "name": "Tapi",
                "countryCode": "IN"
            },
            {
                "name": "Than",
                "countryCode": "IN"
            },
            {
                "name": "Thangadh",
                "countryCode": "IN"
            },
            {
                "name": "Tharad",
                "countryCode": "IN"
            },
            {
                "name": "Thasra",
                "countryCode": "IN"
            },
            {
                "name": "The Dangs",
                "countryCode": "IN"
            },
            {
                "name": "Umarpada",
                "countryCode": "IN"
            },
            {
                "name": "Umrala",
                "countryCode": "IN"
            },
            {
                "name": "Umreth",
                "countryCode": "IN"
            },
            {
                "name": "Un",
                "countryCode": "IN"
            },
            {
                "name": "Una",
                "countryCode": "IN"
            },
            {
                "name": "Unjha",
                "countryCode": "IN"
            },
            {
                "name": "Upleta",
                "countryCode": "IN"
            },
            {
                "name": "Utran",
                "countryCode": "IN"
            },
            {
                "name": "Vadgam",
                "countryCode": "IN"
            },
            {
                "name": "Vadnagar",
                "countryCode": "IN"
            },
            {
                "name": "Vadodara",
                "countryCode": "IN"
            },
            {
                "name": "Vaghodia",
                "countryCode": "IN"
            },
            {
                "name": "Vaghodia INA",
                "countryCode": "IN"
            },
            {
                "name": "Vallabh Vidyanagar",
                "countryCode": "IN"
            },
            {
                "name": "Vallabhipur",
                "countryCode": "IN"
            },
            {
                "name": "Valsad",
                "countryCode": "IN"
            },
            {
                "name": "Vanala",
                "countryCode": "IN"
            },
            {
                "name": "Vansda",
                "countryCode": "IN"
            },
            {
                "name": "Vanthli",
                "countryCode": "IN"
            },
            {
                "name": "Vapi",
                "countryCode": "IN"
            },
            {
                "name": "Vartej",
                "countryCode": "IN"
            },
            {
                "name": "Vasa",
                "countryCode": "IN"
            },
            {
                "name": "Vasavad",
                "countryCode": "IN"
            },
            {
                "name": "Vaso",
                "countryCode": "IN"
            },
            {
                "name": "Vataman",
                "countryCode": "IN"
            },
            {
                "name": "Vejalpur",
                "countryCode": "IN"
            },
            {
                "name": "Veraval",
                "countryCode": "IN"
            },
            {
                "name": "Vijapur",
                "countryCode": "IN"
            },
            {
                "name": "Vinchhiya",
                "countryCode": "IN"
            },
            {
                "name": "Vinchia",
                "countryCode": "IN"
            },
            {
                "name": "Viramgam",
                "countryCode": "IN"
            },
            {
                "name": "Virpur",
                "countryCode": "IN"
            },
            {
                "name": "Visavadar",
                "countryCode": "IN"
            },
            {
                "name": "Visnagar",
                "countryCode": "IN"
            },
            {
                "name": "Vyara",
                "countryCode": "IN"
            },
            {
                "name": "Wadhai",
                "countryCode": "IN"
            },
            {
                "name": "Wadhwan",
                "countryCode": "IN"
            },
            {
                "name": "Waghai",
                "countryCode": "IN"
            },
            {
                "name": "Wankaner",
                "countryCode": "IN"
            },
            {
                "name": "Arki",
                "countryCode": "IN"
            },
            {
                "name": "Baddi",
                "countryCode": "IN"
            },
            {
                "name": "Banjar",
                "countryCode": "IN"
            },
            {
                "name": "Bilaspur",
                "countryCode": "IN"
            },
            {
                "name": "Chamba",
                "countryCode": "IN"
            },
            {
                "name": "Chaupal",
                "countryCode": "IN"
            },
            {
                "name": "Chowari",
                "countryCode": "IN"
            },
            {
                "name": "Chuari Khas",
                "countryCode": "IN"
            },
            {
                "name": "Dagshai",
                "countryCode": "IN"
            },
            {
                "name": "Dalhousie",
                "countryCode": "IN"
            },
            {
                "name": "Daulatpur",
                "countryCode": "IN"
            },
            {
                "name": "Dera Gopipur",
                "countryCode": "IN"
            },
            {
                "name": "Dharamsala",
                "countryCode": "IN"
            },
            {
                "name": "Gagret",
                "countryCode": "IN"
            },
            {
                "name": "Ghumarwin",
                "countryCode": "IN"
            },
            {
                "name": "Hamirpur",
                "countryCode": "IN"
            },
            {
                "name": "Jawala Mukhi",
                "countryCode": "IN"
            },
            {
                "name": "Jogindarnagar",
                "countryCode": "IN"
            },
            {
                "name": "Jubbal",
                "countryCode": "IN"
            },
            {
                "name": "Jutogh",
                "countryCode": "IN"
            },
            {
                "name": "Kalka",
                "countryCode": "IN"
            },
            {
                "name": "Kangar",
                "countryCode": "IN"
            },
            {
                "name": "Kangra",
                "countryCode": "IN"
            },
            {
                "name": "Kasauli",
                "countryCode": "IN"
            },
            {
                "name": "Kinnaur",
                "countryCode": "IN"
            },
            {
                "name": "Kotkhai",
                "countryCode": "IN"
            },
            {
                "name": "Kotla",
                "countryCode": "IN"
            },
            {
                "name": "Kulu",
                "countryCode": "IN"
            },
            {
                "name": "Kyelang",
                "countryCode": "IN"
            },
            {
                "name": "Lahul and Spiti",
                "countryCode": "IN"
            },
            {
                "name": "Manali",
                "countryCode": "IN"
            },
            {
                "name": "Mandi",
                "countryCode": "IN"
            },
            {
                "name": "Nadaun",
                "countryCode": "IN"
            },
            {
                "name": "Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Nagrota",
                "countryCode": "IN"
            },
            {
                "name": "Nahan",
                "countryCode": "IN"
            },
            {
                "name": "Nalagarh",
                "countryCode": "IN"
            },
            {
                "name": "Palampur",
                "countryCode": "IN"
            },
            {
                "name": "Pandoh",
                "countryCode": "IN"
            },
            {
                "name": "Paonta Sahib",
                "countryCode": "IN"
            },
            {
                "name": "Parwanoo",
                "countryCode": "IN"
            },
            {
                "name": "Rajgarh",
                "countryCode": "IN"
            },
            {
                "name": "Rampur",
                "countryCode": "IN"
            },
            {
                "name": "Rohru",
                "countryCode": "IN"
            },
            {
                "name": "Sabathu",
                "countryCode": "IN"
            },
            {
                "name": "Santokhgarh",
                "countryCode": "IN"
            },
            {
                "name": "Sarahan",
                "countryCode": "IN"
            },
            {
                "name": "Sarka Ghat",
                "countryCode": "IN"
            },
            {
                "name": "Seoni",
                "countryCode": "IN"
            },
            {
                "name": "Shimla",
                "countryCode": "IN"
            },
            {
                "name": "Sirmaur",
                "countryCode": "IN"
            },
            {
                "name": "Solan",
                "countryCode": "IN"
            },
            {
                "name": "Sundarnagar",
                "countryCode": "IN"
            },
            {
                "name": "Theog",
                "countryCode": "IN"
            },
            {
                "name": "Tira Sujanpur",
                "countryCode": "IN"
            },
            {
                "name": "Una",
                "countryCode": "IN"
            },
            {
                "name": "Yol",
                "countryCode": "IN"
            },
            {
                "name": "Ambala",
                "countryCode": "IN"
            },
            {
                "name": "Asandh",
                "countryCode": "IN"
            },
            {
                "name": "Ateli Mandi",
                "countryCode": "IN"
            },
            {
                "name": "Bahadurgarh",
                "countryCode": "IN"
            },
            {
                "name": "Bara Uchana",
                "countryCode": "IN"
            },
            {
                "name": "Barwala",
                "countryCode": "IN"
            },
            {
                "name": "Bawal",
                "countryCode": "IN"
            },
            {
                "name": "Beri Khas",
                "countryCode": "IN"
            },
            {
                "name": "Bhiwani",
                "countryCode": "IN"
            },
            {
                "name": "Bilaspur",
                "countryCode": "IN"
            },
            {
                "name": "Buriya",
                "countryCode": "IN"
            },
            {
                "name": "Charkhi Dadri",
                "countryCode": "IN"
            },
            {
                "name": "Chhachhrauli",
                "countryCode": "IN"
            },
            {
                "name": "Dabwali",
                "countryCode": "IN"
            },
            {
                "name": "Dharuhera",
                "countryCode": "IN"
            },
            {
                "name": "Ellenabad",
                "countryCode": "IN"
            },
            {
                "name": "Faridabad",
                "countryCode": "IN"
            },
            {
                "name": "Faridabad District",
                "countryCode": "IN"
            },
            {
                "name": "Farrukhnagar",
                "countryCode": "IN"
            },
            {
                "name": "Fatehabad",
                "countryCode": "IN"
            },
            {
                "name": "Fatehabad District",
                "countryCode": "IN"
            },
            {
                "name": "Firozpur Jhirka",
                "countryCode": "IN"
            },
            {
                "name": "Gharaunda",
                "countryCode": "IN"
            },
            {
                "name": "Gohana",
                "countryCode": "IN"
            },
            {
                "name": "Gorakhpur",
                "countryCode": "IN"
            },
            {
                "name": "Gurgaon",
                "countryCode": "IN"
            },
            {
                "name": "Hansi",
                "countryCode": "IN"
            },
            {
                "name": "Hasanpur",
                "countryCode": "IN"
            },
            {
                "name": "Hisar",
                "countryCode": "IN"
            },
            {
                "name": "Hodal",
                "countryCode": "IN"
            },
            {
                "name": "Inda Chhoi",
                "countryCode": "IN"
            },
            {
                "name": "Indri",
                "countryCode": "IN"
            },
            {
                "name": "Jagadhri",
                "countryCode": "IN"
            },
            {
                "name": "Jakhal",
                "countryCode": "IN"
            },
            {
                "name": "Jhajjar",
                "countryCode": "IN"
            },
            {
                "name": "Jind",
                "countryCode": "IN"
            },
            {
                "name": "Kaithal",
                "countryCode": "IN"
            },
            {
                "name": "Kalanaur",
                "countryCode": "IN"
            },
            {
                "name": "Kalanwali",
                "countryCode": "IN"
            },
            {
                "name": "Kanina Khas",
                "countryCode": "IN"
            },
            {
                "name": "Karnal",
                "countryCode": "IN"
            },
            {
                "name": "Kharkhauda",
                "countryCode": "IN"
            },
            {
                "name": "Kheri Sampla",
                "countryCode": "IN"
            },
            {
                "name": "Kurukshetra",
                "countryCode": "IN"
            },
            {
                "name": "Ladwa",
                "countryCode": "IN"
            },
            {
                "name": "Loharu",
                "countryCode": "IN"
            },
            {
                "name": "Maham",
                "countryCode": "IN"
            },
            {
                "name": "Mahendragarh",
                "countryCode": "IN"
            },
            {
                "name": "Mandholi Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Mustafabad",
                "countryCode": "IN"
            },
            {
                "name": "Narayangarh",
                "countryCode": "IN"
            },
            {
                "name": "Narnaul",
                "countryCode": "IN"
            },
            {
                "name": "Narnaund",
                "countryCode": "IN"
            },
            {
                "name": "Narwana",
                "countryCode": "IN"
            },
            {
                "name": "Nilokheri",
                "countryCode": "IN"
            },
            {
                "name": "Nuh",
                "countryCode": "IN"
            },
            {
                "name": "Palwal",
                "countryCode": "IN"
            },
            {
                "name": "Panchkula",
                "countryCode": "IN"
            },
            {
                "name": "Panipat",
                "countryCode": "IN"
            },
            {
                "name": "Pataudi",
                "countryCode": "IN"
            },
            {
                "name": "Pehowa",
                "countryCode": "IN"
            },
            {
                "name": "Pinjaur",
                "countryCode": "IN"
            },
            {
                "name": "Punahana",
                "countryCode": "IN"
            },
            {
                "name": "Pundri",
                "countryCode": "IN"
            },
            {
                "name": "Radaur",
                "countryCode": "IN"
            },
            {
                "name": "Rania",
                "countryCode": "IN"
            },
            {
                "name": "Ratia",
                "countryCode": "IN"
            },
            {
                "name": "Rewari",
                "countryCode": "IN"
            },
            {
                "name": "Rewari District",
                "countryCode": "IN"
            },
            {
                "name": "Rohtak",
                "countryCode": "IN"
            },
            {
                "name": "Safidon",
                "countryCode": "IN"
            },
            {
                "name": "Samalkha",
                "countryCode": "IN"
            },
            {
                "name": "Shadipur Julana",
                "countryCode": "IN"
            },
            {
                "name": "Shahabad",
                "countryCode": "IN"
            },
            {
                "name": "Sirsa",
                "countryCode": "IN"
            },
            {
                "name": "Sohna",
                "countryCode": "IN"
            },
            {
                "name": "Sonipat",
                "countryCode": "IN"
            },
            {
                "name": "Taoru",
                "countryCode": "IN"
            },
            {
                "name": "Thanesar",
                "countryCode": "IN"
            },
            {
                "name": "Tohana",
                "countryCode": "IN"
            },
            {
                "name": "Tosham",
                "countryCode": "IN"
            },
            {
                "name": "Uklana",
                "countryCode": "IN"
            },
            {
                "name": "Yamunanagar",
                "countryCode": "IN"
            },
            {
                "name": "Bagra",
                "countryCode": "IN"
            },
            {
                "name": "Barka Kana",
                "countryCode": "IN"
            },
            {
                "name": "Barki Saria",
                "countryCode": "IN"
            },
            {
                "name": "Barwadih",
                "countryCode": "IN"
            },
            {
                "name": "Bhojudih",
                "countryCode": "IN"
            },
            {
                "name": "Bokaro",
                "countryCode": "IN"
            },
            {
                "name": "Bundu",
                "countryCode": "IN"
            },
            {
                "name": "Chaibasa",
                "countryCode": "IN"
            },
            {
                "name": "Chakradharpur",
                "countryCode": "IN"
            },
            {
                "name": "Chakulia",
                "countryCode": "IN"
            },
            {
                "name": "Chandil",
                "countryCode": "IN"
            },
            {
                "name": "Chas",
                "countryCode": "IN"
            },
            {
                "name": "Chatra",
                "countryCode": "IN"
            },
            {
                "name": "Chiria",
                "countryCode": "IN"
            },
            {
                "name": "Daltonganj",
                "countryCode": "IN"
            },
            {
                "name": "Deogarh",
                "countryCode": "IN"
            },
            {
                "name": "Dhanbad",
                "countryCode": "IN"
            },
            {
                "name": "Dhanwar",
                "countryCode": "IN"
            },
            {
                "name": "Dugda",
                "countryCode": "IN"
            },
            {
                "name": "Dumka",
                "countryCode": "IN"
            },
            {
                "name": "Garhwa",
                "countryCode": "IN"
            },
            {
                "name": "Ghatsila",
                "countryCode": "IN"
            },
            {
                "name": "Giridih",
                "countryCode": "IN"
            },
            {
                "name": "Gobindpur",
                "countryCode": "IN"
            },
            {
                "name": "Godda",
                "countryCode": "IN"
            },
            {
                "name": "Gomoh",
                "countryCode": "IN"
            },
            {
                "name": "Gopinathpur",
                "countryCode": "IN"
            },
            {
                "name": "Gua",
                "countryCode": "IN"
            },
            {
                "name": "Gumia",
                "countryCode": "IN"
            },
            {
                "name": "Gumla",
                "countryCode": "IN"
            },
            {
                "name": "Hazaribag",
                "countryCode": "IN"
            },
            {
                "name": "Hazaribagh",
                "countryCode": "IN"
            },
            {
                "name": "Hesla",
                "countryCode": "IN"
            },
            {
                "name": "Husainabad",
                "countryCode": "IN"
            },
            {
                "name": "Jagannathpur",
                "countryCode": "IN"
            },
            {
                "name": "Jamadoba",
                "countryCode": "IN"
            },
            {
                "name": "Jamshedpur",
                "countryCode": "IN"
            },
            {
                "name": "Jamtara",
                "countryCode": "IN"
            },
            {
                "name": "Jasidih",
                "countryCode": "IN"
            },
            {
                "name": "Jharia",
                "countryCode": "IN"
            },
            {
                "name": "Jugsalai",
                "countryCode": "IN"
            },
            {
                "name": "Jumri Tilaiya",
                "countryCode": "IN"
            },
            {
                "name": "Kalikapur",
                "countryCode": "IN"
            },
            {
                "name": "Kandra",
                "countryCode": "IN"
            },
            {
                "name": "Kanke",
                "countryCode": "IN"
            },
            {
                "name": "Katras",
                "countryCode": "IN"
            },
            {
                "name": "Kenduadih",
                "countryCode": "IN"
            },
            {
                "name": "Kharsawan",
                "countryCode": "IN"
            },
            {
                "name": "Khunti",
                "countryCode": "IN"
            },
            {
                "name": "Kodarma",
                "countryCode": "IN"
            },
            {
                "name": "Kuju",
                "countryCode": "IN"
            },
            {
                "name": "Latehar",
                "countryCode": "IN"
            },
            {
                "name": "Lohardaga",
                "countryCode": "IN"
            },
            {
                "name": "Madhupur",
                "countryCode": "IN"
            },
            {
                "name": "Malkera",
                "countryCode": "IN"
            },
            {
                "name": "Manoharpur",
                "countryCode": "IN"
            },
            {
                "name": "Mugma",
                "countryCode": "IN"
            },
            {
                "name": "Mushabani",
                "countryCode": "IN"
            },
            {
                "name": "Neturhat",
                "countryCode": "IN"
            },
            {
                "name": "Nirsa",
                "countryCode": "IN"
            },
            {
                "name": "Noamundi",
                "countryCode": "IN"
            },
            {
                "name": "Pakur",
                "countryCode": "IN"
            },
            {
                "name": "Palamu",
                "countryCode": "IN"
            },
            {
                "name": "Pashchim Singhbhum",
                "countryCode": "IN"
            },
            {
                "name": "Pathardih",
                "countryCode": "IN"
            },
            {
                "name": "Purba Singhbhum",
                "countryCode": "IN"
            },
            {
                "name": "Ramgarh",
                "countryCode": "IN"
            },
            {
                "name": "Ranchi",
                "countryCode": "IN"
            },
            {
                "name": "Ray",
                "countryCode": "IN"
            },
            {
                "name": "Sahibganj",
                "countryCode": "IN"
            },
            {
                "name": "Saraikela",
                "countryCode": "IN"
            },
            {
                "name": "Sarubera",
                "countryCode": "IN"
            },
            {
                "name": "Sijua",
                "countryCode": "IN"
            },
            {
                "name": "Simdega",
                "countryCode": "IN"
            },
            {
                "name": "Sini",
                "countryCode": "IN"
            },
            {
                "name": "Topchanchi",
                "countryCode": "IN"
            },
            {
                "name": "patamda",
                "countryCode": "IN"
            },
            {
                "name": "Akhnur",
                "countryCode": "IN"
            },
            {
                "name": "Anantnag",
                "countryCode": "IN"
            },
            {
                "name": "Awantipur",
                "countryCode": "IN"
            },
            {
                "name": "Badgam",
                "countryCode": "IN"
            },
            {
                "name": "Bandipore",
                "countryCode": "IN"
            },
            {
                "name": "Bandipura",
                "countryCode": "IN"
            },
            {
                "name": "Banihal",
                "countryCode": "IN"
            },
            {
                "name": "Baramula",
                "countryCode": "IN"
            },
            {
                "name": "Batoti",
                "countryCode": "IN"
            },
            {
                "name": "Bhadarwah",
                "countryCode": "IN"
            },
            {
                "name": "Bijbehara",
                "countryCode": "IN"
            },
            {
                "name": "Bishnah",
                "countryCode": "IN"
            },
            {
                "name": "Doda",
                "countryCode": "IN"
            },
            {
                "name": "Gandarbal",
                "countryCode": "IN"
            },
            {
                "name": "Ganderbal",
                "countryCode": "IN"
            },
            {
                "name": "Gho Brahmanan de",
                "countryCode": "IN"
            },
            {
                "name": "Hajan",
                "countryCode": "IN"
            },
            {
                "name": "Hiranagar",
                "countryCode": "IN"
            },
            {
                "name": "Jammu",
                "countryCode": "IN"
            },
            {
                "name": "Jaurian",
                "countryCode": "IN"
            },
            {
                "name": "Kathua",
                "countryCode": "IN"
            },
            {
                "name": "Katra",
                "countryCode": "IN"
            },
            {
                "name": "Khaur",
                "countryCode": "IN"
            },
            {
                "name": "Kishtwar",
                "countryCode": "IN"
            },
            {
                "name": "Kud",
                "countryCode": "IN"
            },
            {
                "name": "Kulgam",
                "countryCode": "IN"
            },
            {
                "name": "Kupwara",
                "countryCode": "IN"
            },
            {
                "name": "Ladakh",
                "countryCode": "IN"
            },
            {
                "name": "Magam",
                "countryCode": "IN"
            },
            {
                "name": "Nawanshahr",
                "countryCode": "IN"
            },
            {
                "name": "Noria",
                "countryCode": "IN"
            },
            {
                "name": "Padam",
                "countryCode": "IN"
            },
            {
                "name": "Pahlgam",
                "countryCode": "IN"
            },
            {
                "name": "Parol",
                "countryCode": "IN"
            },
            {
                "name": "Pattan",
                "countryCode": "IN"
            },
            {
                "name": "Pulwama",
                "countryCode": "IN"
            },
            {
                "name": "Punch",
                "countryCode": "IN"
            },
            {
                "name": "Qazigund",
                "countryCode": "IN"
            },
            {
                "name": "Rajaori",
                "countryCode": "IN"
            },
            {
                "name": "Rajauri",
                "countryCode": "IN"
            },
            {
                "name": "Ramban",
                "countryCode": "IN"
            },
            {
                "name": "Ramgarh",
                "countryCode": "IN"
            },
            {
                "name": "Ramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Riasi",
                "countryCode": "IN"
            },
            {
                "name": "Samba",
                "countryCode": "IN"
            },
            {
                "name": "Shupiyan",
                "countryCode": "IN"
            },
            {
                "name": "Sopur",
                "countryCode": "IN"
            },
            {
                "name": "Soyibug",
                "countryCode": "IN"
            },
            {
                "name": "Srinagar",
                "countryCode": "IN"
            },
            {
                "name": "Sumbal",
                "countryCode": "IN"
            },
            {
                "name": "Thang",
                "countryCode": "IN"
            },
            {
                "name": "Thanna Mandi",
                "countryCode": "IN"
            },
            {
                "name": "Tral",
                "countryCode": "IN"
            },
            {
                "name": "Tsrar Sharif",
                "countryCode": "IN"
            },
            {
                "name": "Udhampur",
                "countryCode": "IN"
            },
            {
                "name": "Uri",
                "countryCode": "IN"
            },
            {
                "name": "Afzalpur",
                "countryCode": "IN"
            },
            {
                "name": "Ajjampur",
                "countryCode": "IN"
            },
            {
                "name": "Aland",
                "countryCode": "IN"
            },
            {
                "name": "Alnavar",
                "countryCode": "IN"
            },
            {
                "name": "Alur",
                "countryCode": "IN"
            },
            {
                "name": "Anekal",
                "countryCode": "IN"
            },
            {
                "name": "Ankola",
                "countryCode": "IN"
            },
            {
                "name": "Annigeri",
                "countryCode": "IN"
            },
            {
                "name": "Arkalgud",
                "countryCode": "IN"
            },
            {
                "name": "Arsikere",
                "countryCode": "IN"
            },
            {
                "name": "Athni",
                "countryCode": "IN"
            },
            {
                "name": "Aurad",
                "countryCode": "IN"
            },
            {
                "name": "Badami",
                "countryCode": "IN"
            },
            {
                "name": "Bagalkot",
                "countryCode": "IN"
            },
            {
                "name": "Bagepalli",
                "countryCode": "IN"
            },
            {
                "name": "Bail-Hongal",
                "countryCode": "IN"
            },
            {
                "name": "Ballari",
                "countryCode": "IN"
            },
            {
                "name": "Ballary",
                "countryCode": "IN"
            },
            {
                "name": "Banavar",
                "countryCode": "IN"
            },
            {
                "name": "Bangalore Rural",
                "countryCode": "IN"
            },
            {
                "name": "Bangalore Urban",
                "countryCode": "IN"
            },
            {
                "name": "Bangarapet",
                "countryCode": "IN"
            },
            {
                "name": "Bannur",
                "countryCode": "IN"
            },
            {
                "name": "Bantval",
                "countryCode": "IN"
            },
            {
                "name": "Basavakalyan",
                "countryCode": "IN"
            },
            {
                "name": "Basavana Bagevadi",
                "countryCode": "IN"
            },
            {
                "name": "Belagavi",
                "countryCode": "IN"
            },
            {
                "name": "Belluru",
                "countryCode": "IN"
            },
            {
                "name": "Beltangadi",
                "countryCode": "IN"
            },
            {
                "name": "Belur",
                "countryCode": "IN"
            },
            {
                "name": "Bengaluru",
                "countryCode": "IN"
            },
            {
                "name": "Bhadravati",
                "countryCode": "IN"
            },
            {
                "name": "Bhalki",
                "countryCode": "IN"
            },
            {
                "name": "Bhatkal",
                "countryCode": "IN"
            },
            {
                "name": "Bidar",
                "countryCode": "IN"
            },
            {
                "name": "Bilgi",
                "countryCode": "IN"
            },
            {
                "name": "Birur",
                "countryCode": "IN"
            },
            {
                "name": "Byadgi",
                "countryCode": "IN"
            },
            {
                "name": "Byndoor",
                "countryCode": "IN"
            },
            {
                "name": "Canacona",
                "countryCode": "IN"
            },
            {
                "name": "Challakere",
                "countryCode": "IN"
            },
            {
                "name": "Chamrajnagar",
                "countryCode": "IN"
            },
            {
                "name": "Channagiri",
                "countryCode": "IN"
            },
            {
                "name": "Channapatna",
                "countryCode": "IN"
            },
            {
                "name": "Channarayapatna",
                "countryCode": "IN"
            },
            {
                "name": "Chik Ballapur",
                "countryCode": "IN"
            },
            {
                "name": "Chikkaballapur",
                "countryCode": "IN"
            },
            {
                "name": "Chikkamagaluru",
                "countryCode": "IN"
            },
            {
                "name": "Chiknayakanhalli",
                "countryCode": "IN"
            },
            {
                "name": "Chikodi",
                "countryCode": "IN"
            },
            {
                "name": "Chincholi",
                "countryCode": "IN"
            },
            {
                "name": "Chintamani",
                "countryCode": "IN"
            },
            {
                "name": "Chitapur",
                "countryCode": "IN"
            },
            {
                "name": "Chitradurga",
                "countryCode": "IN"
            },
            {
                "name": "Closepet",
                "countryCode": "IN"
            },
            {
                "name": "Coondapoor",
                "countryCode": "IN"
            },
            {
                "name": "Dakshina Kannada",
                "countryCode": "IN"
            },
            {
                "name": "Dandeli",
                "countryCode": "IN"
            },
            {
                "name": "Davanagere",
                "countryCode": "IN"
            },
            {
                "name": "Devanhalli",
                "countryCode": "IN"
            },
            {
                "name": "Dharwad",
                "countryCode": "IN"
            },
            {
                "name": "Dod Ballapur",
                "countryCode": "IN"
            },
            {
                "name": "French Rocks",
                "countryCode": "IN"
            },
            {
                "name": "Gadag",
                "countryCode": "IN"
            },
            {
                "name": "Gadag-Betageri",
                "countryCode": "IN"
            },
            {
                "name": "Gajendragarh",
                "countryCode": "IN"
            },
            {
                "name": "Gangawati",
                "countryCode": "IN"
            },
            {
                "name": "Gangolli",
                "countryCode": "IN"
            },
            {
                "name": "Gokak",
                "countryCode": "IN"
            },
            {
                "name": "Gokarna",
                "countryCode": "IN"
            },
            {
                "name": "Goribidnur",
                "countryCode": "IN"
            },
            {
                "name": "Gorur",
                "countryCode": "IN"
            },
            {
                "name": "Gubbi",
                "countryCode": "IN"
            },
            {
                "name": "Gudibanda",
                "countryCode": "IN"
            },
            {
                "name": "Guledagudda",
                "countryCode": "IN"
            },
            {
                "name": "Gundlupēt",
                "countryCode": "IN"
            },
            {
                "name": "Gurmatkal",
                "countryCode": "IN"
            },
            {
                "name": "Hadagalli",
                "countryCode": "IN"
            },
            {
                "name": "Haliyal",
                "countryCode": "IN"
            },
            {
                "name": "Hampi",
                "countryCode": "IN"
            },
            {
                "name": "Hangal",
                "countryCode": "IN"
            },
            {
                "name": "Harihar",
                "countryCode": "IN"
            },
            {
                "name": "Harpanahalli",
                "countryCode": "IN"
            },
            {
                "name": "Hassan",
                "countryCode": "IN"
            },
            {
                "name": "Haveri",
                "countryCode": "IN"
            },
            {
                "name": "Heggadadevankote",
                "countryCode": "IN"
            },
            {
                "name": "Hirekerur",
                "countryCode": "IN"
            },
            {
                "name": "Hiriyur",
                "countryCode": "IN"
            },
            {
                "name": "Holalkere",
                "countryCode": "IN"
            },
            {
                "name": "Hole Narsipur",
                "countryCode": "IN"
            },
            {
                "name": "Homnabad",
                "countryCode": "IN"
            },
            {
                "name": "Honavar",
                "countryCode": "IN"
            },
            {
                "name": "Honnali",
                "countryCode": "IN"
            },
            {
                "name": "Hosanagara",
                "countryCode": "IN"
            },
            {
                "name": "Hosangadi",
                "countryCode": "IN"
            },
            {
                "name": "Hosdurga",
                "countryCode": "IN"
            },
            {
                "name": "Hoskote",
                "countryCode": "IN"
            },
            {
                "name": "Hospet",
                "countryCode": "IN"
            },
            {
                "name": "Hubballi",
                "countryCode": "IN"
            },
            {
                "name": "Hukeri",
                "countryCode": "IN"
            },
            {
                "name": "Hungund",
                "countryCode": "IN"
            },
            {
                "name": "Hunsur",
                "countryCode": "IN"
            },
            {
                "name": "Ilkal",
                "countryCode": "IN"
            },
            {
                "name": "Indi",
                "countryCode": "IN"
            },
            {
                "name": "Jagalur",
                "countryCode": "IN"
            },
            {
                "name": "Jamkhandi",
                "countryCode": "IN"
            },
            {
                "name": "Jevargi",
                "countryCode": "IN"
            },
            {
                "name": "Kadur",
                "countryCode": "IN"
            },
            {
                "name": "Kalaburgi",
                "countryCode": "IN"
            },
            {
                "name": "Kalghatgi",
                "countryCode": "IN"
            },
            {
                "name": "Kampli",
                "countryCode": "IN"
            },
            {
                "name": "Kankanhalli",
                "countryCode": "IN"
            },
            {
                "name": "Karkala",
                "countryCode": "IN"
            },
            {
                "name": "Karwar",
                "countryCode": "IN"
            },
            {
                "name": "Kavalur",
                "countryCode": "IN"
            },
            {
                "name": "Kerur",
                "countryCode": "IN"
            },
            {
                "name": "Khanapur",
                "countryCode": "IN"
            },
            {
                "name": "Kodagu",
                "countryCode": "IN"
            },
            {
                "name": "Kodigenahalli",
                "countryCode": "IN"
            },
            {
                "name": "Kodlipet",
                "countryCode": "IN"
            },
            {
                "name": "Kolar",
                "countryCode": "IN"
            },
            {
                "name": "Kollegal",
                "countryCode": "IN"
            },
            {
                "name": "Konanur",
                "countryCode": "IN"
            },
            {
                "name": "Konnur",
                "countryCode": "IN"
            },
            {
                "name": "Koppa",
                "countryCode": "IN"
            },
            {
                "name": "Koppal",
                "countryCode": "IN"
            },
            {
                "name": "Koratagere",
                "countryCode": "IN"
            },
            {
                "name": "Kotturu",
                "countryCode": "IN"
            },
            {
                "name": "Krishnarajpet",
                "countryCode": "IN"
            },
            {
                "name": "Kudachi",
                "countryCode": "IN"
            },
            {
                "name": "Kudligi",
                "countryCode": "IN"
            },
            {
                "name": "Kumsi",
                "countryCode": "IN"
            },
            {
                "name": "Kumta",
                "countryCode": "IN"
            },
            {
                "name": "Kundgol",
                "countryCode": "IN"
            },
            {
                "name": "Kunigal",
                "countryCode": "IN"
            },
            {
                "name": "Kurgunta",
                "countryCode": "IN"
            },
            {
                "name": "Kushalnagar",
                "countryCode": "IN"
            },
            {
                "name": "Kushtagi",
                "countryCode": "IN"
            },
            {
                "name": "Lakshmeshwar",
                "countryCode": "IN"
            },
            {
                "name": "Lingsugur",
                "countryCode": "IN"
            },
            {
                "name": "Londa",
                "countryCode": "IN"
            },
            {
                "name": "Maddagiri",
                "countryCode": "IN"
            },
            {
                "name": "Maddur",
                "countryCode": "IN"
            },
            {
                "name": "Madikeri",
                "countryCode": "IN"
            },
            {
                "name": "Magadi",
                "countryCode": "IN"
            },
            {
                "name": "Mahalingpur",
                "countryCode": "IN"
            },
            {
                "name": "Malavalli",
                "countryCode": "IN"
            },
            {
                "name": "Malpe",
                "countryCode": "IN"
            },
            {
                "name": "Malur",
                "countryCode": "IN"
            },
            {
                "name": "Mandya",
                "countryCode": "IN"
            },
            {
                "name": "Mangaluru",
                "countryCode": "IN"
            },
            {
                "name": "Manipal",
                "countryCode": "IN"
            },
            {
                "name": "Manvi",
                "countryCode": "IN"
            },
            {
                "name": "Mayakonda",
                "countryCode": "IN"
            },
            {
                "name": "Melukote",
                "countryCode": "IN"
            },
            {
                "name": "Mudbidri",
                "countryCode": "IN"
            },
            {
                "name": "Muddebihal",
                "countryCode": "IN"
            },
            {
                "name": "Mudgal",
                "countryCode": "IN"
            },
            {
                "name": "Mudgere",
                "countryCode": "IN"
            },
            {
                "name": "Mudhol",
                "countryCode": "IN"
            },
            {
                "name": "Mulbagal",
                "countryCode": "IN"
            },
            {
                "name": "Mulgund",
                "countryCode": "IN"
            },
            {
                "name": "Mulki",
                "countryCode": "IN"
            },
            {
                "name": "Mundargi",
                "countryCode": "IN"
            },
            {
                "name": "Mundgod",
                "countryCode": "IN"
            },
            {
                "name": "Munirabad",
                "countryCode": "IN"
            },
            {
                "name": "Murudeshwara",
                "countryCode": "IN"
            },
            {
                "name": "Mysuru",
                "countryCode": "IN"
            },
            {
                "name": "Nagamangala",
                "countryCode": "IN"
            },
            {
                "name": "Nanjangud",
                "countryCode": "IN"
            },
            {
                "name": "Narasimharajapura",
                "countryCode": "IN"
            },
            {
                "name": "Naregal",
                "countryCode": "IN"
            },
            {
                "name": "Nargund",
                "countryCode": "IN"
            },
            {
                "name": "Navalgund",
                "countryCode": "IN"
            },
            {
                "name": "Nelamangala",
                "countryCode": "IN"
            },
            {
                "name": "Nyamti",
                "countryCode": "IN"
            },
            {
                "name": "Pangala",
                "countryCode": "IN"
            },
            {
                "name": "Pavugada",
                "countryCode": "IN"
            },
            {
                "name": "Piriyapatna",
                "countryCode": "IN"
            },
            {
                "name": "Ponnampet",
                "countryCode": "IN"
            },
            {
                "name": "Puttur",
                "countryCode": "IN"
            },
            {
                "name": "Rabkavi",
                "countryCode": "IN"
            },
            {
                "name": "Raichur",
                "countryCode": "IN"
            },
            {
                "name": "Ramanagara",
                "countryCode": "IN"
            },
            {
                "name": "Ranibennur",
                "countryCode": "IN"
            },
            {
                "name": "Raybag",
                "countryCode": "IN"
            },
            {
                "name": "Robertsonpet",
                "countryCode": "IN"
            },
            {
                "name": "Ron",
                "countryCode": "IN"
            },
            {
                "name": "Sadalgi",
                "countryCode": "IN"
            },
            {
                "name": "Sagar",
                "countryCode": "IN"
            },
            {
                "name": "Sakleshpur",
                "countryCode": "IN"
            },
            {
                "name": "Sandur",
                "countryCode": "IN"
            },
            {
                "name": "Sanivarsante",
                "countryCode": "IN"
            },
            {
                "name": "Sankeshwar",
                "countryCode": "IN"
            },
            {
                "name": "Sargur",
                "countryCode": "IN"
            },
            {
                "name": "Saundatti",
                "countryCode": "IN"
            },
            {
                "name": "Savanur",
                "countryCode": "IN"
            },
            {
                "name": "Seram",
                "countryCode": "IN"
            },
            {
                "name": "Shahabad",
                "countryCode": "IN"
            },
            {
                "name": "Shahpur",
                "countryCode": "IN"
            },
            {
                "name": "Shiggaon",
                "countryCode": "IN"
            },
            {
                "name": "Shikarpur",
                "countryCode": "IN"
            },
            {
                "name": "Shimoga",
                "countryCode": "IN"
            },
            {
                "name": "Shirhatti",
                "countryCode": "IN"
            },
            {
                "name": "Shorapur",
                "countryCode": "IN"
            },
            {
                "name": "Shrirangapattana",
                "countryCode": "IN"
            },
            {
                "name": "Siddapur",
                "countryCode": "IN"
            },
            {
                "name": "Sidlaghatta",
                "countryCode": "IN"
            },
            {
                "name": "Sindgi",
                "countryCode": "IN"
            },
            {
                "name": "Sindhnur",
                "countryCode": "IN"
            },
            {
                "name": "Sira",
                "countryCode": "IN"
            },
            {
                "name": "Sirsi",
                "countryCode": "IN"
            },
            {
                "name": "Siruguppa",
                "countryCode": "IN"
            },
            {
                "name": "Someshwar",
                "countryCode": "IN"
            },
            {
                "name": "Somvarpet",
                "countryCode": "IN"
            },
            {
                "name": "Sorab",
                "countryCode": "IN"
            },
            {
                "name": "Sravana Belgola",
                "countryCode": "IN"
            },
            {
                "name": "Sringeri",
                "countryCode": "IN"
            },
            {
                "name": "Srinivaspur",
                "countryCode": "IN"
            },
            {
                "name": "Sulya",
                "countryCode": "IN"
            },
            {
                "name": "Suntikoppa",
                "countryCode": "IN"
            },
            {
                "name": "Talikota",
                "countryCode": "IN"
            },
            {
                "name": "Tarikere",
                "countryCode": "IN"
            },
            {
                "name": "Tekkalakote",
                "countryCode": "IN"
            },
            {
                "name": "Terdal",
                "countryCode": "IN"
            },
            {
                "name": "Tiptur",
                "countryCode": "IN"
            },
            {
                "name": "Tirthahalli",
                "countryCode": "IN"
            },
            {
                "name": "Tirumakudal Narsipur",
                "countryCode": "IN"
            },
            {
                "name": "Tumakuru",
                "countryCode": "IN"
            },
            {
                "name": "Turuvekere",
                "countryCode": "IN"
            },
            {
                "name": "Udupi",
                "countryCode": "IN"
            },
            {
                "name": "Ullal",
                "countryCode": "IN"
            },
            {
                "name": "Uttar Kannada",
                "countryCode": "IN"
            },
            {
                "name": "Vadigenhalli",
                "countryCode": "IN"
            },
            {
                "name": "Vijayapura",
                "countryCode": "IN"
            },
            {
                "name": "Virarajendrapet",
                "countryCode": "IN"
            },
            {
                "name": "Wadi",
                "countryCode": "IN"
            },
            {
                "name": "Yadgir",
                "countryCode": "IN"
            },
            {
                "name": "Yelahanka",
                "countryCode": "IN"
            },
            {
                "name": "Yelandur",
                "countryCode": "IN"
            },
            {
                "name": "Yelbarga",
                "countryCode": "IN"
            },
            {
                "name": "Yellapur",
                "countryCode": "IN"
            },
            {
                "name": "Adur",
                "countryCode": "IN"
            },
            {
                "name": "Alappuzha",
                "countryCode": "IN"
            },
            {
                "name": "Aluva",
                "countryCode": "IN"
            },
            {
                "name": "Alwaye",
                "countryCode": "IN"
            },
            {
                "name": "Angamali",
                "countryCode": "IN"
            },
            {
                "name": "Aroor",
                "countryCode": "IN"
            },
            {
                "name": "Arukutti",
                "countryCode": "IN"
            },
            {
                "name": "Attingal",
                "countryCode": "IN"
            },
            {
                "name": "Avanoor",
                "countryCode": "IN"
            },
            {
                "name": "Azhikkal",
                "countryCode": "IN"
            },
            {
                "name": "Badagara",
                "countryCode": "IN"
            },
            {
                "name": "Beypore",
                "countryCode": "IN"
            },
            {
                "name": "Changanacheri",
                "countryCode": "IN"
            },
            {
                "name": "Chengannur",
                "countryCode": "IN"
            },
            {
                "name": "Cherpulassery",
                "countryCode": "IN"
            },
            {
                "name": "Cherthala",
                "countryCode": "IN"
            },
            {
                "name": "Chetwayi",
                "countryCode": "IN"
            },
            {
                "name": "Chittur",
                "countryCode": "IN"
            },
            {
                "name": "Chēlakara",
                "countryCode": "IN"
            },
            {
                "name": "Cochin",
                "countryCode": "IN"
            },
            {
                "name": "Dharmadam",
                "countryCode": "IN"
            },
            {
                "name": "Edakkulam",
                "countryCode": "IN"
            },
            {
                "name": "Elur",
                "countryCode": "IN"
            },
            {
                "name": "Erattupetta",
                "countryCode": "IN"
            },
            {
                "name": "Ernakulam",
                "countryCode": "IN"
            },
            {
                "name": "Ferokh",
                "countryCode": "IN"
            },
            {
                "name": "Guruvayur",
                "countryCode": "IN"
            },
            {
                "name": "Idukki",
                "countryCode": "IN"
            },
            {
                "name": "Iringal",
                "countryCode": "IN"
            },
            {
                "name": "Irinjalakuda",
                "countryCode": "IN"
            },
            {
                "name": "Kadakkavoor",
                "countryCode": "IN"
            },
            {
                "name": "Kalamassery",
                "countryCode": "IN"
            },
            {
                "name": "Kalavoor",
                "countryCode": "IN"
            },
            {
                "name": "Kalpatta",
                "countryCode": "IN"
            },
            {
                "name": "Kannangad",
                "countryCode": "IN"
            },
            {
                "name": "Kannavam",
                "countryCode": "IN"
            },
            {
                "name": "Kannur",
                "countryCode": "IN"
            },
            {
                "name": "Kasaragod",
                "countryCode": "IN"
            },
            {
                "name": "Kasaragod District",
                "countryCode": "IN"
            },
            {
                "name": "Kattanam",
                "countryCode": "IN"
            },
            {
                "name": "Kayankulam",
                "countryCode": "IN"
            },
            {
                "name": "Kizhake Chalakudi",
                "countryCode": "IN"
            },
            {
                "name": "Kodungallur",
                "countryCode": "IN"
            },
            {
                "name": "Kollam",
                "countryCode": "IN"
            },
            {
                "name": "Kotamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Kottayam",
                "countryCode": "IN"
            },
            {
                "name": "Kovalam",
                "countryCode": "IN"
            },
            {
                "name": "Kozhikode",
                "countryCode": "IN"
            },
            {
                "name": "Kumbalam",
                "countryCode": "IN"
            },
            {
                "name": "Kunnamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Kunnamkulam",
                "countryCode": "IN"
            },
            {
                "name": "Kunnumma",
                "countryCode": "IN"
            },
            {
                "name": "Kutiatodu",
                "countryCode": "IN"
            },
            {
                "name": "Kuttampuzha",
                "countryCode": "IN"
            },
            {
                "name": "Lalam",
                "countryCode": "IN"
            },
            {
                "name": "Mahē",
                "countryCode": "IN"
            },
            {
                "name": "Malappuram",
                "countryCode": "IN"
            },
            {
                "name": "Manjeri",
                "countryCode": "IN"
            },
            {
                "name": "Manjēshvar",
                "countryCode": "IN"
            },
            {
                "name": "Mannarakkat",
                "countryCode": "IN"
            },
            {
                "name": "Marayur",
                "countryCode": "IN"
            },
            {
                "name": "Mattanur",
                "countryCode": "IN"
            },
            {
                "name": "Mavelikara",
                "countryCode": "IN"
            },
            {
                "name": "Mavoor",
                "countryCode": "IN"
            },
            {
                "name": "Muluppilagadu",
                "countryCode": "IN"
            },
            {
                "name": "Munnar",
                "countryCode": "IN"
            },
            {
                "name": "Muvattupula",
                "countryCode": "IN"
            },
            {
                "name": "Muvattupuzha",
                "countryCode": "IN"
            },
            {
                "name": "Nadapuram",
                "countryCode": "IN"
            },
            {
                "name": "Naduvannur",
                "countryCode": "IN"
            },
            {
                "name": "Nedumangad",
                "countryCode": "IN"
            },
            {
                "name": "Neyyattinkara",
                "countryCode": "IN"
            },
            {
                "name": "Nilēshwar",
                "countryCode": "IN"
            },
            {
                "name": "Ottappalam",
                "countryCode": "IN"
            },
            {
                "name": "Palackattumala",
                "countryCode": "IN"
            },
            {
                "name": "Palakkad district",
                "countryCode": "IN"
            },
            {
                "name": "Palghat",
                "countryCode": "IN"
            },
            {
                "name": "Panamaram",
                "countryCode": "IN"
            },
            {
                "name": "Pappinisshēri",
                "countryCode": "IN"
            },
            {
                "name": "Paravur Tekkumbhagam",
                "countryCode": "IN"
            },
            {
                "name": "Pariyapuram",
                "countryCode": "IN"
            },
            {
                "name": "Pathanamthitta",
                "countryCode": "IN"
            },
            {
                "name": "Pattanamtitta",
                "countryCode": "IN"
            },
            {
                "name": "Payyannur",
                "countryCode": "IN"
            },
            {
                "name": "Perumbavoor",
                "countryCode": "IN"
            },
            {
                "name": "Perumpavur",
                "countryCode": "IN"
            },
            {
                "name": "Perya",
                "countryCode": "IN"
            },
            {
                "name": "Piravam",
                "countryCode": "IN"
            },
            {
                "name": "Ponmana",
                "countryCode": "IN"
            },
            {
                "name": "Ponnani",
                "countryCode": "IN"
            },
            {
                "name": "Punalur",
                "countryCode": "IN"
            },
            {
                "name": "Ramamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Shertallai",
                "countryCode": "IN"
            },
            {
                "name": "Shōranur",
                "countryCode": "IN"
            },
            {
                "name": "Talipparamba",
                "countryCode": "IN"
            },
            {
                "name": "Tellicherry",
                "countryCode": "IN"
            },
            {
                "name": "Thanniyam",
                "countryCode": "IN"
            },
            {
                "name": "Thiruvananthapuram",
                "countryCode": "IN"
            },
            {
                "name": "Thrissur",
                "countryCode": "IN"
            },
            {
                "name": "Thrissur District",
                "countryCode": "IN"
            },
            {
                "name": "Tirur",
                "countryCode": "IN"
            },
            {
                "name": "Tiruvalla",
                "countryCode": "IN"
            },
            {
                "name": "Vaikam",
                "countryCode": "IN"
            },
            {
                "name": "Varkala",
                "countryCode": "IN"
            },
            {
                "name": "Vayalar",
                "countryCode": "IN"
            },
            {
                "name": "Vettur",
                "countryCode": "IN"
            },
            {
                "name": "Wayanad",
                "countryCode": "IN"
            },
            {
                "name": "Kargil",
                "countryCode": "IN"
            },
            {
                "name": "Leh",
                "countryCode": "IN"
            },
            {
                "name": "Kavaratti",
                "countryCode": "IN"
            },
            {
                "name": "Lakshadweep",
                "countryCode": "IN"
            },
            {
                "name": "Achalpur",
                "countryCode": "IN"
            },
            {
                "name": "Adawad",
                "countryCode": "IN"
            },
            {
                "name": "Agar Panchaitan",
                "countryCode": "IN"
            },
            {
                "name": "Aheri",
                "countryCode": "IN"
            },
            {
                "name": "Ahiri",
                "countryCode": "IN"
            },
            {
                "name": "Ahmadnagar",
                "countryCode": "IN"
            },
            {
                "name": "Ahmadpur",
                "countryCode": "IN"
            },
            {
                "name": "Ahmednagar",
                "countryCode": "IN"
            },
            {
                "name": "Airoli",
                "countryCode": "IN"
            },
            {
                "name": "Ajra",
                "countryCode": "IN"
            },
            {
                "name": "Akalkot",
                "countryCode": "IN"
            },
            {
                "name": "Akkalkot",
                "countryCode": "IN"
            },
            {
                "name": "Akluj",
                "countryCode": "IN"
            },
            {
                "name": "Akola",
                "countryCode": "IN"
            },
            {
                "name": "Akolner",
                "countryCode": "IN"
            },
            {
                "name": "Akot",
                "countryCode": "IN"
            },
            {
                "name": "Akrani",
                "countryCode": "IN"
            },
            {
                "name": "Alandi",
                "countryCode": "IN"
            },
            {
                "name": "Ale",
                "countryCode": "IN"
            },
            {
                "name": "Alibag",
                "countryCode": "IN"
            },
            {
                "name": "Alkuti",
                "countryCode": "IN"
            },
            {
                "name": "Allapalli",
                "countryCode": "IN"
            },
            {
                "name": "Amalner",
                "countryCode": "IN"
            },
            {
                "name": "Amarnath",
                "countryCode": "IN"
            },
            {
                "name": "Ambad",
                "countryCode": "IN"
            },
            {
                "name": "Ambajogai",
                "countryCode": "IN"
            },
            {
                "name": "Ambarnath",
                "countryCode": "IN"
            },
            {
                "name": "Ambegaon",
                "countryCode": "IN"
            },
            {
                "name": "Amgaon",
                "countryCode": "IN"
            },
            {
                "name": "Amravati",
                "countryCode": "IN"
            },
            {
                "name": "Amravati Division",
                "countryCode": "IN"
            },
            {
                "name": "Andheri",
                "countryCode": "IN"
            },
            {
                "name": "Andura",
                "countryCode": "IN"
            },
            {
                "name": "Anjangaon",
                "countryCode": "IN"
            },
            {
                "name": "Anjarle",
                "countryCode": "IN"
            },
            {
                "name": "Anshing",
                "countryCode": "IN"
            },
            {
                "name": "Arag",
                "countryCode": "IN"
            },
            {
                "name": "Arangaon",
                "countryCode": "IN"
            },
            {
                "name": "Ardhapur",
                "countryCode": "IN"
            },
            {
                "name": "Argaon",
                "countryCode": "IN"
            },
            {
                "name": "Artist Village",
                "countryCode": "IN"
            },
            {
                "name": "Arvi",
                "countryCode": "IN"
            },
            {
                "name": "Ashta",
                "countryCode": "IN"
            },
            {
                "name": "Ashti",
                "countryCode": "IN"
            },
            {
                "name": "Asoda",
                "countryCode": "IN"
            },
            {
                "name": "Assaye",
                "countryCode": "IN"
            },
            {
                "name": "Astagaon",
                "countryCode": "IN"
            },
            {
                "name": "Aundh, Satara",
                "countryCode": "IN"
            },
            {
                "name": "Aurangabad",
                "countryCode": "IN"
            },
            {
                "name": "Ausa",
                "countryCode": "IN"
            },
            {
                "name": "Badlapur",
                "countryCode": "IN"
            },
            {
                "name": "Badnapur",
                "countryCode": "IN"
            },
            {
                "name": "Badnera",
                "countryCode": "IN"
            },
            {
                "name": "Bagewadi",
                "countryCode": "IN"
            },
            {
                "name": "Balapur",
                "countryCode": "IN"
            },
            {
                "name": "Balapur, Akola district",
                "countryCode": "IN"
            },
            {
                "name": "Ballalpur",
                "countryCode": "IN"
            },
            {
                "name": "Ballard Estate",
                "countryCode": "IN"
            },
            {
                "name": "Ballarpur",
                "countryCode": "IN"
            },
            {
                "name": "Banda, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Bandra",
                "countryCode": "IN"
            },
            {
                "name": "Baner",
                "countryCode": "IN"
            },
            {
                "name": "Bankot",
                "countryCode": "IN"
            },
            {
                "name": "Baramati",
                "countryCode": "IN"
            },
            {
                "name": "Barsi",
                "countryCode": "IN"
            },
            {
                "name": "Basmat",
                "countryCode": "IN"
            },
            {
                "name": "Basmath",
                "countryCode": "IN"
            },
            {
                "name": "Bavdhan",
                "countryCode": "IN"
            },
            {
                "name": "Bawanbir",
                "countryCode": "IN"
            },
            {
                "name": "Beed",
                "countryCode": "IN"
            },
            {
                "name": "Bhadgaon, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Bhandara",
                "countryCode": "IN"
            },
            {
                "name": "Bhandardara",
                "countryCode": "IN"
            },
            {
                "name": "Bhandup",
                "countryCode": "IN"
            },
            {
                "name": "Bhayandar",
                "countryCode": "IN"
            },
            {
                "name": "Bhigvan",
                "countryCode": "IN"
            },
            {
                "name": "Bhiwandi",
                "countryCode": "IN"
            },
            {
                "name": "Bhiwapur",
                "countryCode": "IN"
            },
            {
                "name": "Bhokar",
                "countryCode": "IN"
            },
            {
                "name": "Bhokardan",
                "countryCode": "IN"
            },
            {
                "name": "Bhoom",
                "countryCode": "IN"
            },
            {
                "name": "Bhor",
                "countryCode": "IN"
            },
            {
                "name": "Bhudgaon",
                "countryCode": "IN"
            },
            {
                "name": "Bhugaon",
                "countryCode": "IN"
            },
            {
                "name": "Bhum",
                "countryCode": "IN"
            },
            {
                "name": "Bhusaval",
                "countryCode": "IN"
            },
            {
                "name": "Bhusawal",
                "countryCode": "IN"
            },
            {
                "name": "Bid",
                "countryCode": "IN"
            },
            {
                "name": "Bijur",
                "countryCode": "IN"
            },
            {
                "name": "Bilashi",
                "countryCode": "IN"
            },
            {
                "name": "Biloli",
                "countryCode": "IN"
            },
            {
                "name": "Boisar",
                "countryCode": "IN"
            },
            {
                "name": "Borgaon Manju",
                "countryCode": "IN"
            },
            {
                "name": "Borivali",
                "countryCode": "IN"
            },
            {
                "name": "Borivli",
                "countryCode": "IN"
            },
            {
                "name": "Brahmapuri",
                "countryCode": "IN"
            },
            {
                "name": "Breach Candy",
                "countryCode": "IN"
            },
            {
                "name": "Buldana",
                "countryCode": "IN"
            },
            {
                "name": "Buldhana",
                "countryCode": "IN"
            },
            {
                "name": "Byculla",
                "countryCode": "IN"
            },
            {
                "name": "Chakan",
                "countryCode": "IN"
            },
            {
                "name": "Chakur",
                "countryCode": "IN"
            },
            {
                "name": "Chalisgaon",
                "countryCode": "IN"
            },
            {
                "name": "Chanda",
                "countryCode": "IN"
            },
            {
                "name": "Chandgad",
                "countryCode": "IN"
            },
            {
                "name": "Chandor",
                "countryCode": "IN"
            },
            {
                "name": "Chandrapur",
                "countryCode": "IN"
            },
            {
                "name": "Chandur",
                "countryCode": "IN"
            },
            {
                "name": "Chandur Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Chausala",
                "countryCode": "IN"
            },
            {
                "name": "Chembur",
                "countryCode": "IN"
            },
            {
                "name": "Chicholi",
                "countryCode": "IN"
            },
            {
                "name": "Chichondi Patil",
                "countryCode": "IN"
            },
            {
                "name": "Chikhli (Buldhana)",
                "countryCode": "IN"
            },
            {
                "name": "Chikhli (Jalna)",
                "countryCode": "IN"
            },
            {
                "name": "Chimur",
                "countryCode": "IN"
            },
            {
                "name": "Chinchani",
                "countryCode": "IN"
            },
            {
                "name": "Chinchpokli",
                "countryCode": "IN"
            },
            {
                "name": "Chiplun",
                "countryCode": "IN"
            },
            {
                "name": "Chopda",
                "countryCode": "IN"
            },
            {
                "name": "Colaba",
                "countryCode": "IN"
            },
            {
                "name": "Dabhol",
                "countryCode": "IN"
            },
            {
                "name": "Daddi",
                "countryCode": "IN"
            },
            {
                "name": "Dahanu",
                "countryCode": "IN"
            },
            {
                "name": "Dahivel",
                "countryCode": "IN"
            },
            {
                "name": "Dapoli",
                "countryCode": "IN"
            },
            {
                "name": "Darwha",
                "countryCode": "IN"
            },
            {
                "name": "Daryapur",
                "countryCode": "IN"
            },
            {
                "name": "Dattapur",
                "countryCode": "IN"
            },
            {
                "name": "Daulatabad",
                "countryCode": "IN"
            },
            {
                "name": "Daund",
                "countryCode": "IN"
            },
            {
                "name": "Deccan Gymkhana",
                "countryCode": "IN"
            },
            {
                "name": "Deglur",
                "countryCode": "IN"
            },
            {
                "name": "Dehu",
                "countryCode": "IN"
            },
            {
                "name": "Deolali",
                "countryCode": "IN"
            },
            {
                "name": "Deolapar",
                "countryCode": "IN"
            },
            {
                "name": "Deoli",
                "countryCode": "IN"
            },
            {
                "name": "Deoni",
                "countryCode": "IN"
            },
            {
                "name": "Deulgaon Raja",
                "countryCode": "IN"
            },
            {
                "name": "Devrukh",
                "countryCode": "IN"
            },
            {
                "name": "Dharangaon",
                "countryCode": "IN"
            },
            {
                "name": "Dharavi",
                "countryCode": "IN"
            },
            {
                "name": "Dharmabad",
                "countryCode": "IN"
            },
            {
                "name": "Dharur",
                "countryCode": "IN"
            },
            {
                "name": "Dhawalpuri",
                "countryCode": "IN"
            },
            {
                "name": "Dhule",
                "countryCode": "IN"
            },
            {
                "name": "Dhulia",
                "countryCode": "IN"
            },
            {
                "name": "Dighori",
                "countryCode": "IN"
            },
            {
                "name": "Diglur",
                "countryCode": "IN"
            },
            {
                "name": "Digras",
                "countryCode": "IN"
            },
            {
                "name": "Dindori, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Diveagar",
                "countryCode": "IN"
            },
            {
                "name": "Dombivli",
                "countryCode": "IN"
            },
            {
                "name": "Dondaicha",
                "countryCode": "IN"
            },
            {
                "name": "Dongri",
                "countryCode": "IN"
            },
            {
                "name": "Dudhani",
                "countryCode": "IN"
            },
            {
                "name": "Durgapur",
                "countryCode": "IN"
            },
            {
                "name": "Durgapur, Chandrapur",
                "countryCode": "IN"
            },
            {
                "name": "Erandol",
                "countryCode": "IN"
            },
            {
                "name": "Faizpur",
                "countryCode": "IN"
            },
            {
                "name": "Fort",
                "countryCode": "IN"
            },
            {
                "name": "Gadchiroli",
                "countryCode": "IN"
            },
            {
                "name": "Gadhinglaj",
                "countryCode": "IN"
            },
            {
                "name": "Gangakher",
                "countryCode": "IN"
            },
            {
                "name": "Gangapur",
                "countryCode": "IN"
            },
            {
                "name": "Ganpatipule",
                "countryCode": "IN"
            },
            {
                "name": "Gevrai",
                "countryCode": "IN"
            },
            {
                "name": "Ghargaon",
                "countryCode": "IN"
            },
            {
                "name": "Ghatanji",
                "countryCode": "IN"
            },
            {
                "name": "Ghatkopar",
                "countryCode": "IN"
            },
            {
                "name": "Ghoti Budrukh",
                "countryCode": "IN"
            },
            {
                "name": "Ghugus",
                "countryCode": "IN"
            },
            {
                "name": "Girgaon",
                "countryCode": "IN"
            },
            {
                "name": "Gondia",
                "countryCode": "IN"
            },
            {
                "name": "Gondiya",
                "countryCode": "IN"
            },
            {
                "name": "Gorai",
                "countryCode": "IN"
            },
            {
                "name": "Goregaon",
                "countryCode": "IN"
            },
            {
                "name": "Guhagar",
                "countryCode": "IN"
            },
            {
                "name": "Hadapsar, Pune",
                "countryCode": "IN"
            },
            {
                "name": "Hadgaon",
                "countryCode": "IN"
            },
            {
                "name": "Halkarni",
                "countryCode": "IN"
            },
            {
                "name": "Harangul",
                "countryCode": "IN"
            },
            {
                "name": "Harnai",
                "countryCode": "IN"
            },
            {
                "name": "Helwak",
                "countryCode": "IN"
            },
            {
                "name": "Hinganghat",
                "countryCode": "IN"
            },
            {
                "name": "Hingoli",
                "countryCode": "IN"
            },
            {
                "name": "Hirapur",
                "countryCode": "IN"
            },
            {
                "name": "Hirapur Hamesha",
                "countryCode": "IN"
            },
            {
                "name": "Hotgi",
                "countryCode": "IN"
            },
            {
                "name": "Ichalkaranji",
                "countryCode": "IN"
            },
            {
                "name": "Igatpuri",
                "countryCode": "IN"
            },
            {
                "name": "Indapur",
                "countryCode": "IN"
            },
            {
                "name": "Jaisingpur",
                "countryCode": "IN"
            },
            {
                "name": "Jaitapur",
                "countryCode": "IN"
            },
            {
                "name": "Jakhangaon",
                "countryCode": "IN"
            },
            {
                "name": "Jalgaon",
                "countryCode": "IN"
            },
            {
                "name": "Jalgaon Jamod",
                "countryCode": "IN"
            },
            {
                "name": "Jalkot",
                "countryCode": "IN"
            },
            {
                "name": "Jalna",
                "countryCode": "IN"
            },
            {
                "name": "Jamkhed",
                "countryCode": "IN"
            },
            {
                "name": "Jamod",
                "countryCode": "IN"
            },
            {
                "name": "Janephal",
                "countryCode": "IN"
            },
            {
                "name": "Jaoli",
                "countryCode": "IN"
            },
            {
                "name": "Jat, Sangli",
                "countryCode": "IN"
            },
            {
                "name": "Jategaon",
                "countryCode": "IN"
            },
            {
                "name": "Jawhar",
                "countryCode": "IN"
            },
            {
                "name": "Jaysingpur",
                "countryCode": "IN"
            },
            {
                "name": "Jejuri",
                "countryCode": "IN"
            },
            {
                "name": "Jintur",
                "countryCode": "IN"
            },
            {
                "name": "Jogeshwari",
                "countryCode": "IN"
            },
            {
                "name": "Juhu",
                "countryCode": "IN"
            },
            {
                "name": "Junnar",
                "countryCode": "IN"
            },
            {
                "name": "Kachurwahi",
                "countryCode": "IN"
            },
            {
                "name": "Kadegaon",
                "countryCode": "IN"
            },
            {
                "name": "Kadus",
                "countryCode": "IN"
            },
            {
                "name": "Kagal",
                "countryCode": "IN"
            },
            {
                "name": "Kaij",
                "countryCode": "IN"
            },
            {
                "name": "Kalamb",
                "countryCode": "IN"
            },
            {
                "name": "Kalamb, Osmanabad",
                "countryCode": "IN"
            },
            {
                "name": "Kalamboli",
                "countryCode": "IN"
            },
            {
                "name": "Kalamnuri",
                "countryCode": "IN"
            },
            {
                "name": "Kalas",
                "countryCode": "IN"
            },
            {
                "name": "Kali(DK)",
                "countryCode": "IN"
            },
            {
                "name": "Kalmeshwar",
                "countryCode": "IN"
            },
            {
                "name": "Kalundri",
                "countryCode": "IN"
            },
            {
                "name": "Kalyan",
                "countryCode": "IN"
            },
            {
                "name": "Kalyani Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Kamargaon",
                "countryCode": "IN"
            },
            {
                "name": "Kamatgi",
                "countryCode": "IN"
            },
            {
                "name": "Kamptee",
                "countryCode": "IN"
            },
            {
                "name": "Kamthi",
                "countryCode": "IN"
            },
            {
                "name": "Kandri",
                "countryCode": "IN"
            },
            {
                "name": "Kankauli",
                "countryCode": "IN"
            },
            {
                "name": "Kankavli",
                "countryCode": "IN"
            },
            {
                "name": "Kannad",
                "countryCode": "IN"
            },
            {
                "name": "Karad",
                "countryCode": "IN"
            },
            {
                "name": "Karajagi",
                "countryCode": "IN"
            },
            {
                "name": "Karanja",
                "countryCode": "IN"
            },
            {
                "name": "Karanja Lad",
                "countryCode": "IN"
            },
            {
                "name": "Karjat",
                "countryCode": "IN"
            },
            {
                "name": "Karkamb",
                "countryCode": "IN"
            },
            {
                "name": "Karmala",
                "countryCode": "IN"
            },
            {
                "name": "Kasara",
                "countryCode": "IN"
            },
            {
                "name": "Kasoda",
                "countryCode": "IN"
            },
            {
                "name": "Kati",
                "countryCode": "IN"
            },
            {
                "name": "Katol",
                "countryCode": "IN"
            },
            {
                "name": "Katral",
                "countryCode": "IN"
            },
            {
                "name": "Khadki",
                "countryCode": "IN"
            },
            {
                "name": "Khalapur",
                "countryCode": "IN"
            },
            {
                "name": "Khallar",
                "countryCode": "IN"
            },
            {
                "name": "Khamgaon",
                "countryCode": "IN"
            },
            {
                "name": "Khanapur",
                "countryCode": "IN"
            },
            {
                "name": "Khandala",
                "countryCode": "IN"
            },
            {
                "name": "Khangaon",
                "countryCode": "IN"
            },
            {
                "name": "Khapa",
                "countryCode": "IN"
            },
            {
                "name": "Kharakvasla",
                "countryCode": "IN"
            },
            {
                "name": "Kharda",
                "countryCode": "IN"
            },
            {
                "name": "Kharghar",
                "countryCode": "IN"
            },
            {
                "name": "Kharsundi",
                "countryCode": "IN"
            },
            {
                "name": "Khed",
                "countryCode": "IN"
            },
            {
                "name": "Khede",
                "countryCode": "IN"
            },
            {
                "name": "Khetia",
                "countryCode": "IN"
            },
            {
                "name": "Khoni",
                "countryCode": "IN"
            },
            {
                "name": "Khopoli",
                "countryCode": "IN"
            },
            {
                "name": "Khuldabad",
                "countryCode": "IN"
            },
            {
                "name": "Kinwat",
                "countryCode": "IN"
            },
            {
                "name": "Kodoli",
                "countryCode": "IN"
            },
            {
                "name": "Kolhapur",
                "countryCode": "IN"
            },
            {
                "name": "Kondalwadi",
                "countryCode": "IN"
            },
            {
                "name": "Kondhali",
                "countryCode": "IN"
            },
            {
                "name": "Kopar Khairane",
                "countryCode": "IN"
            },
            {
                "name": "Kopargaon",
                "countryCode": "IN"
            },
            {
                "name": "Kopela",
                "countryCode": "IN"
            },
            {
                "name": "Koradi",
                "countryCode": "IN"
            },
            {
                "name": "Koregaon",
                "countryCode": "IN"
            },
            {
                "name": "Koynanagar",
                "countryCode": "IN"
            },
            {
                "name": "Kudal",
                "countryCode": "IN"
            },
            {
                "name": "Kuhi",
                "countryCode": "IN"
            },
            {
                "name": "Kurandvad",
                "countryCode": "IN"
            },
            {
                "name": "Kurankhed",
                "countryCode": "IN"
            },
            {
                "name": "Kurduvadi",
                "countryCode": "IN"
            },
            {
                "name": "Kusumba",
                "countryCode": "IN"
            },
            {
                "name": "Lakhandur",
                "countryCode": "IN"
            },
            {
                "name": "Lanja",
                "countryCode": "IN"
            },
            {
                "name": "Lasalgaon",
                "countryCode": "IN"
            },
            {
                "name": "Latur",
                "countryCode": "IN"
            },
            {
                "name": "Lavasa",
                "countryCode": "IN"
            },
            {
                "name": "Lohogaon",
                "countryCode": "IN"
            },
            {
                "name": "Lonar",
                "countryCode": "IN"
            },
            {
                "name": "Lonavla",
                "countryCode": "IN"
            },
            {
                "name": "Mahabaleshwar",
                "countryCode": "IN"
            },
            {
                "name": "Mahad",
                "countryCode": "IN"
            },
            {
                "name": "Mahape",
                "countryCode": "IN"
            },
            {
                "name": "Mahim",
                "countryCode": "IN"
            },
            {
                "name": "Maindargi",
                "countryCode": "IN"
            },
            {
                "name": "Majalgaon",
                "countryCode": "IN"
            },
            {
                "name": "Makhjan",
                "countryCode": "IN"
            },
            {
                "name": "Malabar Hill",
                "countryCode": "IN"
            },
            {
                "name": "Malad",
                "countryCode": "IN"
            },
            {
                "name": "Malegaon",
                "countryCode": "IN"
            },
            {
                "name": "Malkapur",
                "countryCode": "IN"
            },
            {
                "name": "Malvan",
                "countryCode": "IN"
            },
            {
                "name": "Manchar",
                "countryCode": "IN"
            },
            {
                "name": "Mandangad",
                "countryCode": "IN"
            },
            {
                "name": "Mandhal",
                "countryCode": "IN"
            },
            {
                "name": "Mandwa",
                "countryCode": "IN"
            },
            {
                "name": "Mangaon",
                "countryCode": "IN"
            },
            {
                "name": "Mangrul Pir",
                "countryCode": "IN"
            },
            {
                "name": "Manjlegaon",
                "countryCode": "IN"
            },
            {
                "name": "Mankeshwar",
                "countryCode": "IN"
            },
            {
                "name": "Mankhurd",
                "countryCode": "IN"
            },
            {
                "name": "Manmad",
                "countryCode": "IN"
            },
            {
                "name": "Manor",
                "countryCode": "IN"
            },
            {
                "name": "Mansar",
                "countryCode": "IN"
            },
            {
                "name": "Manwat",
                "countryCode": "IN"
            },
            {
                "name": "Manwath",
                "countryCode": "IN"
            },
            {
                "name": "Maregaon",
                "countryCode": "IN"
            },
            {
                "name": "Mastiholi",
                "countryCode": "IN"
            },
            {
                "name": "Masur, India",
                "countryCode": "IN"
            },
            {
                "name": "Matheran",
                "countryCode": "IN"
            },
            {
                "name": "Matunga",
                "countryCode": "IN"
            },
            {
                "name": "Mazagaon",
                "countryCode": "IN"
            },
            {
                "name": "Mehekar",
                "countryCode": "IN"
            },
            {
                "name": "Mehergaon",
                "countryCode": "IN"
            },
            {
                "name": "Mehkar",
                "countryCode": "IN"
            },
            {
                "name": "Mhasla",
                "countryCode": "IN"
            },
            {
                "name": "Mhasvad",
                "countryCode": "IN"
            },
            {
                "name": "Miraj",
                "countryCode": "IN"
            },
            {
                "name": "Mohadi",
                "countryCode": "IN"
            },
            {
                "name": "Mohol",
                "countryCode": "IN"
            },
            {
                "name": "Mohpa",
                "countryCode": "IN"
            },
            {
                "name": "Mokhada taluka",
                "countryCode": "IN"
            },
            {
                "name": "Mora, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Moram",
                "countryCode": "IN"
            },
            {
                "name": "Morsi",
                "countryCode": "IN"
            },
            {
                "name": "Mowad",
                "countryCode": "IN"
            },
            {
                "name": "Mudkhed",
                "countryCode": "IN"
            },
            {
                "name": "Mukher",
                "countryCode": "IN"
            },
            {
                "name": "Mul",
                "countryCode": "IN"
            },
            {
                "name": "Mulher",
                "countryCode": "IN"
            },
            {
                "name": "Mulund",
                "countryCode": "IN"
            },
            {
                "name": "Mumbai",
                "countryCode": "IN"
            },
            {
                "name": "Mumbai Suburban",
                "countryCode": "IN"
            },
            {
                "name": "Murbad",
                "countryCode": "IN"
            },
            {
                "name": "Murgud",
                "countryCode": "IN"
            },
            {
                "name": "Murtajapur",
                "countryCode": "IN"
            },
            {
                "name": "Murud (Raigad)",
                "countryCode": "IN"
            },
            {
                "name": "Murud (Ratnagiri)",
                "countryCode": "IN"
            },
            {
                "name": "Murum",
                "countryCode": "IN"
            },
            {
                "name": "Nadgaon",
                "countryCode": "IN"
            },
            {
                "name": "Nagapur",
                "countryCode": "IN"
            },
            {
                "name": "Nagothana",
                "countryCode": "IN"
            },
            {
                "name": "Nagpur",
                "countryCode": "IN"
            },
            {
                "name": "Nagpur Division",
                "countryCode": "IN"
            },
            {
                "name": "Nala Sopara",
                "countryCode": "IN"
            },
            {
                "name": "Naldurg",
                "countryCode": "IN"
            },
            {
                "name": "Nalegaon",
                "countryCode": "IN"
            },
            {
                "name": "Nampur",
                "countryCode": "IN"
            },
            {
                "name": "Nanded",
                "countryCode": "IN"
            },
            {
                "name": "Nandgaon",
                "countryCode": "IN"
            },
            {
                "name": "Nandnee",
                "countryCode": "IN"
            },
            {
                "name": "Nandura",
                "countryCode": "IN"
            },
            {
                "name": "Nandura Buzurg",
                "countryCode": "IN"
            },
            {
                "name": "Nandurbar",
                "countryCode": "IN"
            },
            {
                "name": "Narayangaon",
                "countryCode": "IN"
            },
            {
                "name": "Nardana",
                "countryCode": "IN"
            },
            {
                "name": "Nariman Point",
                "countryCode": "IN"
            },
            {
                "name": "Narkhed",
                "countryCode": "IN"
            },
            {
                "name": "Nashik",
                "countryCode": "IN"
            },
            {
                "name": "Nashik Division",
                "countryCode": "IN"
            },
            {
                "name": "Navapur",
                "countryCode": "IN"
            },
            {
                "name": "Navi Mumbai",
                "countryCode": "IN"
            },
            {
                "name": "Neral",
                "countryCode": "IN"
            },
            {
                "name": "Nerur",
                "countryCode": "IN"
            },
            {
                "name": "Nevasa",
                "countryCode": "IN"
            },
            {
                "name": "Nighoj",
                "countryCode": "IN"
            },
            {
                "name": "Nilanga",
                "countryCode": "IN"
            },
            {
                "name": "Nipani",
                "countryCode": "IN"
            },
            {
                "name": "Niphad",
                "countryCode": "IN"
            },
            {
                "name": "Nira Narsingpur",
                "countryCode": "IN"
            },
            {
                "name": "Osmanabad",
                "countryCode": "IN"
            },
            {
                "name": "Ozar",
                "countryCode": "IN"
            },
            {
                "name": "Pabal",
                "countryCode": "IN"
            },
            {
                "name": "Pachora",
                "countryCode": "IN"
            },
            {
                "name": "Pahur, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Paithan",
                "countryCode": "IN"
            },
            {
                "name": "Palghar",
                "countryCode": "IN"
            },
            {
                "name": "Pali, Raigad",
                "countryCode": "IN"
            },
            {
                "name": "Palso",
                "countryCode": "IN"
            },
            {
                "name": "Panchgani",
                "countryCode": "IN"
            },
            {
                "name": "Pandharpur",
                "countryCode": "IN"
            },
            {
                "name": "Pandhurli",
                "countryCode": "IN"
            },
            {
                "name": "Panhala",
                "countryCode": "IN"
            },
            {
                "name": "Panvel",
                "countryCode": "IN"
            },
            {
                "name": "Parbhani",
                "countryCode": "IN"
            },
            {
                "name": "Parel",
                "countryCode": "IN"
            },
            {
                "name": "Parli Vaijnath",
                "countryCode": "IN"
            },
            {
                "name": "Parner",
                "countryCode": "IN"
            },
            {
                "name": "Parola",
                "countryCode": "IN"
            },
            {
                "name": "Parseoni",
                "countryCode": "IN"
            },
            {
                "name": "Partur",
                "countryCode": "IN"
            },
            {
                "name": "Patan",
                "countryCode": "IN"
            },
            {
                "name": "Pathardi",
                "countryCode": "IN"
            },
            {
                "name": "Pathri",
                "countryCode": "IN"
            },
            {
                "name": "Patur",
                "countryCode": "IN"
            },
            {
                "name": "Paturda",
                "countryCode": "IN"
            },
            {
                "name": "Paud",
                "countryCode": "IN"
            },
            {
                "name": "Pauni",
                "countryCode": "IN"
            },
            {
                "name": "Pawni",
                "countryCode": "IN"
            },
            {
                "name": "Pedgaon",
                "countryCode": "IN"
            },
            {
                "name": "Peint",
                "countryCode": "IN"
            },
            {
                "name": "Pen",
                "countryCode": "IN"
            },
            {
                "name": "Phaltan",
                "countryCode": "IN"
            },
            {
                "name": "Phulambri",
                "countryCode": "IN"
            },
            {
                "name": "Piliv",
                "countryCode": "IN"
            },
            {
                "name": "Pimpalgaon Baswant",
                "countryCode": "IN"
            },
            {
                "name": "Pimpalgaon Raja",
                "countryCode": "IN"
            },
            {
                "name": "Pimpri",
                "countryCode": "IN"
            },
            {
                "name": "Pimpri-Chinchwad",
                "countryCode": "IN"
            },
            {
                "name": "Pipri",
                "countryCode": "IN"
            },
            {
                "name": "Powai",
                "countryCode": "IN"
            },
            {
                "name": "Prabhadevi",
                "countryCode": "IN"
            },
            {
                "name": "Prakasha",
                "countryCode": "IN"
            },
            {
                "name": "Pulgaon",
                "countryCode": "IN"
            },
            {
                "name": "Pune",
                "countryCode": "IN"
            },
            {
                "name": "Pune Division",
                "countryCode": "IN"
            },
            {
                "name": "Puntamba",
                "countryCode": "IN"
            },
            {
                "name": "Pural",
                "countryCode": "IN"
            },
            {
                "name": "Purna",
                "countryCode": "IN"
            },
            {
                "name": "Pusad",
                "countryCode": "IN"
            },
            {
                "name": "Radhanagari",
                "countryCode": "IN"
            },
            {
                "name": "Rahata",
                "countryCode": "IN"
            },
            {
                "name": "Rahimatpur",
                "countryCode": "IN"
            },
            {
                "name": "Rahuri",
                "countryCode": "IN"
            },
            {
                "name": "Raigarh",
                "countryCode": "IN"
            },
            {
                "name": "Raireshwar",
                "countryCode": "IN"
            },
            {
                "name": "Rajapur",
                "countryCode": "IN"
            },
            {
                "name": "Rajgurunagar",
                "countryCode": "IN"
            },
            {
                "name": "Rajur",
                "countryCode": "IN"
            },
            {
                "name": "Rajura",
                "countryCode": "IN"
            },
            {
                "name": "Ralegaon",
                "countryCode": "IN"
            },
            {
                "name": "Ramewadi",
                "countryCode": "IN"
            },
            {
                "name": "Ramtek",
                "countryCode": "IN"
            },
            {
                "name": "Ratnagiri",
                "countryCode": "IN"
            },
            {
                "name": "Raver",
                "countryCode": "IN"
            },
            {
                "name": "Renapur",
                "countryCode": "IN"
            },
            {
                "name": "Renavi",
                "countryCode": "IN"
            },
            {
                "name": "Revadanda",
                "countryCode": "IN"
            },
            {
                "name": "Revdanda",
                "countryCode": "IN"
            },
            {
                "name": "Risod",
                "countryCode": "IN"
            },
            {
                "name": "Roha",
                "countryCode": "IN"
            },
            {
                "name": "Sailu",
                "countryCode": "IN"
            },
            {
                "name": "Sakol",
                "countryCode": "IN"
            },
            {
                "name": "Sakoli",
                "countryCode": "IN"
            },
            {
                "name": "Sakri",
                "countryCode": "IN"
            },
            {
                "name": "Samudrapur",
                "countryCode": "IN"
            },
            {
                "name": "Sangameshwar",
                "countryCode": "IN"
            },
            {
                "name": "Sangamner",
                "countryCode": "IN"
            },
            {
                "name": "Sangli",
                "countryCode": "IN"
            },
            {
                "name": "Sangola",
                "countryCode": "IN"
            },
            {
                "name": "Sangole",
                "countryCode": "IN"
            },
            {
                "name": "Sangrampur, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Saoli",
                "countryCode": "IN"
            },
            {
                "name": "Saoner",
                "countryCode": "IN"
            },
            {
                "name": "Sarangkheda",
                "countryCode": "IN"
            },
            {
                "name": "Sasvad",
                "countryCode": "IN"
            },
            {
                "name": "Saswad",
                "countryCode": "IN"
            },
            {
                "name": "Satana",
                "countryCode": "IN"
            },
            {
                "name": "Satara",
                "countryCode": "IN"
            },
            {
                "name": "Satara Division",
                "countryCode": "IN"
            },
            {
                "name": "Satpati",
                "countryCode": "IN"
            },
            {
                "name": "Savantvadi",
                "countryCode": "IN"
            },
            {
                "name": "Savda",
                "countryCode": "IN"
            },
            {
                "name": "Savlaj",
                "countryCode": "IN"
            },
            {
                "name": "Sawantvadi",
                "countryCode": "IN"
            },
            {
                "name": "Selu",
                "countryCode": "IN"
            },
            {
                "name": "Sevagram",
                "countryCode": "IN"
            },
            {
                "name": "Sewri",
                "countryCode": "IN"
            },
            {
                "name": "Shahada",
                "countryCode": "IN"
            },
            {
                "name": "Shahapur",
                "countryCode": "IN"
            },
            {
                "name": "Shedbal",
                "countryCode": "IN"
            },
            {
                "name": "Shegaon",
                "countryCode": "IN"
            },
            {
                "name": "Shevgaon",
                "countryCode": "IN"
            },
            {
                "name": "Shikrapur",
                "countryCode": "IN"
            },
            {
                "name": "Shiraguppi",
                "countryCode": "IN"
            },
            {
                "name": "Shirala",
                "countryCode": "IN"
            },
            {
                "name": "Shirdi",
                "countryCode": "IN"
            },
            {
                "name": "Shirgaon",
                "countryCode": "IN"
            },
            {
                "name": "Shirol",
                "countryCode": "IN"
            },
            {
                "name": "Shirpur",
                "countryCode": "IN"
            },
            {
                "name": "Shirud",
                "countryCode": "IN"
            },
            {
                "name": "Shirwal",
                "countryCode": "IN"
            },
            {
                "name": "Shivaji Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Shrigonda",
                "countryCode": "IN"
            },
            {
                "name": "Sillod",
                "countryCode": "IN"
            },
            {
                "name": "Sindewahi",
                "countryCode": "IN"
            },
            {
                "name": "Sindhudurg",
                "countryCode": "IN"
            },
            {
                "name": "Sindi",
                "countryCode": "IN"
            },
            {
                "name": "Sindkheda",
                "countryCode": "IN"
            },
            {
                "name": "Sinnar",
                "countryCode": "IN"
            },
            {
                "name": "Sion, Mumbai",
                "countryCode": "IN"
            },
            {
                "name": "Sironcha",
                "countryCode": "IN"
            },
            {
                "name": "Sirur",
                "countryCode": "IN"
            },
            {
                "name": "Sivala, East Godavari district",
                "countryCode": "IN"
            },
            {
                "name": "Solapur",
                "countryCode": "IN"
            },
            {
                "name": "Sonala",
                "countryCode": "IN"
            },
            {
                "name": "Sonegaon",
                "countryCode": "IN"
            },
            {
                "name": "Songir",
                "countryCode": "IN"
            },
            {
                "name": "Sonvad",
                "countryCode": "IN"
            },
            {
                "name": "Soygaon",
                "countryCode": "IN"
            },
            {
                "name": "Srivardhan",
                "countryCode": "IN"
            },
            {
                "name": "Surgana",
                "countryCode": "IN"
            },
            {
                "name": "Taklibhan",
                "countryCode": "IN"
            },
            {
                "name": "Talbid",
                "countryCode": "IN"
            },
            {
                "name": "Talegaon Dabhade",
                "countryCode": "IN"
            },
            {
                "name": "Talegaon Dhamdhere",
                "countryCode": "IN"
            },
            {
                "name": "Taloda",
                "countryCode": "IN"
            },
            {
                "name": "Talode",
                "countryCode": "IN"
            },
            {
                "name": "Tarapur",
                "countryCode": "IN"
            },
            {
                "name": "Tardeo",
                "countryCode": "IN"
            },
            {
                "name": "Tasgaon",
                "countryCode": "IN"
            },
            {
                "name": "Telhara",
                "countryCode": "IN"
            },
            {
                "name": "Thalner",
                "countryCode": "IN"
            },
            {
                "name": "Thane",
                "countryCode": "IN"
            },
            {
                "name": "Trimbak",
                "countryCode": "IN"
            },
            {
                "name": "Trombay",
                "countryCode": "IN"
            },
            {
                "name": "Tuljapur",
                "countryCode": "IN"
            },
            {
                "name": "Tumsar",
                "countryCode": "IN"
            },
            {
                "name": "Udgir",
                "countryCode": "IN"
            },
            {
                "name": "Ulhasnagar",
                "countryCode": "IN"
            },
            {
                "name": "Umarga",
                "countryCode": "IN"
            },
            {
                "name": "Umarkhed",
                "countryCode": "IN"
            },
            {
                "name": "Umred",
                "countryCode": "IN"
            },
            {
                "name": "Uran",
                "countryCode": "IN"
            },
            {
                "name": "Uruli Kanchan",
                "countryCode": "IN"
            },
            {
                "name": "Vada",
                "countryCode": "IN"
            },
            {
                "name": "Vadgaon",
                "countryCode": "IN"
            },
            {
                "name": "Vadner",
                "countryCode": "IN"
            },
            {
                "name": "Vaijapur",
                "countryCode": "IN"
            },
            {
                "name": "Vairag",
                "countryCode": "IN"
            },
            {
                "name": "Valsang",
                "countryCode": "IN"
            },
            {
                "name": "Vangaon",
                "countryCode": "IN"
            },
            {
                "name": "Varangaon",
                "countryCode": "IN"
            },
            {
                "name": "Vashi",
                "countryCode": "IN"
            },
            {
                "name": "Vasind",
                "countryCode": "IN"
            },
            {
                "name": "Vatul",
                "countryCode": "IN"
            },
            {
                "name": "Velas, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Velneshwar",
                "countryCode": "IN"
            },
            {
                "name": "Vengurla",
                "countryCode": "IN"
            },
            {
                "name": "Vijaydurg",
                "countryCode": "IN"
            },
            {
                "name": "Vikhroli",
                "countryCode": "IN"
            },
            {
                "name": "Vile Parle",
                "countryCode": "IN"
            },
            {
                "name": "Vinchur",
                "countryCode": "IN"
            },
            {
                "name": "Virar",
                "countryCode": "IN"
            },
            {
                "name": "Vita, Maharashtra",
                "countryCode": "IN"
            },
            {
                "name": "Vite",
                "countryCode": "IN"
            },
            {
                "name": "Wadala",
                "countryCode": "IN"
            },
            {
                "name": "Wadgaon",
                "countryCode": "IN"
            },
            {
                "name": "Wadner",
                "countryCode": "IN"
            },
            {
                "name": "Wadwani",
                "countryCode": "IN"
            },
            {
                "name": "Wagholi",
                "countryCode": "IN"
            },
            {
                "name": "Wai",
                "countryCode": "IN"
            },
            {
                "name": "Wakad",
                "countryCode": "IN"
            },
            {
                "name": "Walgaon",
                "countryCode": "IN"
            },
            {
                "name": "Walki",
                "countryCode": "IN"
            },
            {
                "name": "Wani",
                "countryCode": "IN"
            },
            {
                "name": "Wardha",
                "countryCode": "IN"
            },
            {
                "name": "Warora",
                "countryCode": "IN"
            },
            {
                "name": "Warud",
                "countryCode": "IN"
            },
            {
                "name": "Washim",
                "countryCode": "IN"
            },
            {
                "name": "Worli",
                "countryCode": "IN"
            },
            {
                "name": "Yaval",
                "countryCode": "IN"
            },
            {
                "name": "Yavatmal",
                "countryCode": "IN"
            },
            {
                "name": "Yeola",
                "countryCode": "IN"
            },
            {
                "name": "Cherrapunji",
                "countryCode": "IN"
            },
            {
                "name": "East Garo Hills",
                "countryCode": "IN"
            },
            {
                "name": "East Jaintia Hills",
                "countryCode": "IN"
            },
            {
                "name": "East Khasi Hills",
                "countryCode": "IN"
            },
            {
                "name": "Mairang",
                "countryCode": "IN"
            },
            {
                "name": "Mankachar",
                "countryCode": "IN"
            },
            {
                "name": "Nongpoh",
                "countryCode": "IN"
            },
            {
                "name": "Nongstoin",
                "countryCode": "IN"
            },
            {
                "name": "North Garo Hills",
                "countryCode": "IN"
            },
            {
                "name": "Ri-Bhoi",
                "countryCode": "IN"
            },
            {
                "name": "Shillong",
                "countryCode": "IN"
            },
            {
                "name": "South Garo Hills",
                "countryCode": "IN"
            },
            {
                "name": "South West Garo Hills",
                "countryCode": "IN"
            },
            {
                "name": "South West Khasi Hills",
                "countryCode": "IN"
            },
            {
                "name": "Tura",
                "countryCode": "IN"
            },
            {
                "name": "West Garo Hills",
                "countryCode": "IN"
            },
            {
                "name": "West Jaintia Hills",
                "countryCode": "IN"
            },
            {
                "name": "West Khasi Hills",
                "countryCode": "IN"
            },
            {
                "name": "Bishnupur",
                "countryCode": "IN"
            },
            {
                "name": "Churachandpur",
                "countryCode": "IN"
            },
            {
                "name": "Imphal",
                "countryCode": "IN"
            },
            {
                "name": "Kakching",
                "countryCode": "IN"
            },
            {
                "name": "Mayang Imphal",
                "countryCode": "IN"
            },
            {
                "name": "Moirang",
                "countryCode": "IN"
            },
            {
                "name": "Phek",
                "countryCode": "IN"
            },
            {
                "name": "Senapati",
                "countryCode": "IN"
            },
            {
                "name": "Tamenglong",
                "countryCode": "IN"
            },
            {
                "name": "Thoubal",
                "countryCode": "IN"
            },
            {
                "name": "Ukhrul",
                "countryCode": "IN"
            },
            {
                "name": "Wangjing",
                "countryCode": "IN"
            },
            {
                "name": "Yairipok",
                "countryCode": "IN"
            },
            {
                "name": "Agar",
                "countryCode": "IN"
            },
            {
                "name": "Ajaigarh",
                "countryCode": "IN"
            },
            {
                "name": "Akodia",
                "countryCode": "IN"
            },
            {
                "name": "Alampur",
                "countryCode": "IN"
            },
            {
                "name": "Alirajpur",
                "countryCode": "IN"
            },
            {
                "name": "Alot",
                "countryCode": "IN"
            },
            {
                "name": "Amanganj",
                "countryCode": "IN"
            },
            {
                "name": "Amarkantak",
                "countryCode": "IN"
            },
            {
                "name": "Amarpatan",
                "countryCode": "IN"
            },
            {
                "name": "Amarwara",
                "countryCode": "IN"
            },
            {
                "name": "Ambah",
                "countryCode": "IN"
            },
            {
                "name": "Amla",
                "countryCode": "IN"
            },
            {
                "name": "Anjad",
                "countryCode": "IN"
            },
            {
                "name": "Antri",
                "countryCode": "IN"
            },
            {
                "name": "Anuppur",
                "countryCode": "IN"
            },
            {
                "name": "Aron",
                "countryCode": "IN"
            },
            {
                "name": "Ashoknagar",
                "countryCode": "IN"
            },
            {
                "name": "Ashta",
                "countryCode": "IN"
            },
            {
                "name": "Babai",
                "countryCode": "IN"
            },
            {
                "name": "Badarwas",
                "countryCode": "IN"
            },
            {
                "name": "Badnawar",
                "countryCode": "IN"
            },
            {
                "name": "Bag",
                "countryCode": "IN"
            },
            {
                "name": "Bagli",
                "countryCode": "IN"
            },
            {
                "name": "Baihar",
                "countryCode": "IN"
            },
            {
                "name": "Baikunthpur",
                "countryCode": "IN"
            },
            {
                "name": "Bakshwaho",
                "countryCode": "IN"
            },
            {
                "name": "Balaghat",
                "countryCode": "IN"
            },
            {
                "name": "Baldeogarh",
                "countryCode": "IN"
            },
            {
                "name": "Bamna",
                "countryCode": "IN"
            },
            {
                "name": "Bamor Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Bamora",
                "countryCode": "IN"
            },
            {
                "name": "Banda",
                "countryCode": "IN"
            },
            {
                "name": "Barela",
                "countryCode": "IN"
            },
            {
                "name": "Barghat",
                "countryCode": "IN"
            },
            {
                "name": "Bargi",
                "countryCode": "IN"
            },
            {
                "name": "Barhi",
                "countryCode": "IN"
            },
            {
                "name": "Barwani",
                "countryCode": "IN"
            },
            {
                "name": "Basoda",
                "countryCode": "IN"
            },
            {
                "name": "Begamganj",
                "countryCode": "IN"
            },
            {
                "name": "Beohari",
                "countryCode": "IN"
            },
            {
                "name": "Berasia",
                "countryCode": "IN"
            },
            {
                "name": "Betma",
                "countryCode": "IN"
            },
            {
                "name": "Betul",
                "countryCode": "IN"
            },
            {
                "name": "Betul Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Bhabhra",
                "countryCode": "IN"
            },
            {
                "name": "Bhainsdehi",
                "countryCode": "IN"
            },
            {
                "name": "Bhander",
                "countryCode": "IN"
            },
            {
                "name": "Bhanpura",
                "countryCode": "IN"
            },
            {
                "name": "Bhawaniganj",
                "countryCode": "IN"
            },
            {
                "name": "Bhikangaon",
                "countryCode": "IN"
            },
            {
                "name": "Bhind",
                "countryCode": "IN"
            },
            {
                "name": "Bhitarwar",
                "countryCode": "IN"
            },
            {
                "name": "Bhopal",
                "countryCode": "IN"
            },
            {
                "name": "Biaora",
                "countryCode": "IN"
            },
            {
                "name": "Bijawar",
                "countryCode": "IN"
            },
            {
                "name": "Bijrauni",
                "countryCode": "IN"
            },
            {
                "name": "Bodri",
                "countryCode": "IN"
            },
            {
                "name": "Burhanpur",
                "countryCode": "IN"
            },
            {
                "name": "Burhar",
                "countryCode": "IN"
            },
            {
                "name": "Chanderi",
                "countryCode": "IN"
            },
            {
                "name": "Chandia",
                "countryCode": "IN"
            },
            {
                "name": "Chandla",
                "countryCode": "IN"
            },
            {
                "name": "Chhatarpur",
                "countryCode": "IN"
            },
            {
                "name": "Chhindwara",
                "countryCode": "IN"
            },
            {
                "name": "Chichli",
                "countryCode": "IN"
            },
            {
                "name": "Chorhat",
                "countryCode": "IN"
            },
            {
                "name": "Daboh",
                "countryCode": "IN"
            },
            {
                "name": "Dabra",
                "countryCode": "IN"
            },
            {
                "name": "Damoh",
                "countryCode": "IN"
            },
            {
                "name": "Datia",
                "countryCode": "IN"
            },
            {
                "name": "Deori Khas",
                "countryCode": "IN"
            },
            {
                "name": "Depalpur",
                "countryCode": "IN"
            },
            {
                "name": "Dewas",
                "countryCode": "IN"
            },
            {
                "name": "Dhamnod",
                "countryCode": "IN"
            },
            {
                "name": "Dhana",
                "countryCode": "IN"
            },
            {
                "name": "Dhar",
                "countryCode": "IN"
            },
            {
                "name": "Dharampuri",
                "countryCode": "IN"
            },
            {
                "name": "Dindori",
                "countryCode": "IN"
            },
            {
                "name": "Etawa",
                "countryCode": "IN"
            },
            {
                "name": "Gadarwara",
                "countryCode": "IN"
            },
            {
                "name": "Garha Brahman",
                "countryCode": "IN"
            },
            {
                "name": "Garhakota",
                "countryCode": "IN"
            },
            {
                "name": "Gautampura",
                "countryCode": "IN"
            },
            {
                "name": "Ghansor",
                "countryCode": "IN"
            },
            {
                "name": "Gogapur",
                "countryCode": "IN"
            },
            {
                "name": "Gohadi",
                "countryCode": "IN"
            },
            {
                "name": "Govindgarh",
                "countryCode": "IN"
            },
            {
                "name": "Guna",
                "countryCode": "IN"
            },
            {
                "name": "Gurh",
                "countryCode": "IN"
            },
            {
                "name": "Gwalior",
                "countryCode": "IN"
            },
            {
                "name": "Harda",
                "countryCode": "IN"
            },
            {
                "name": "Harda Khas",
                "countryCode": "IN"
            },
            {
                "name": "Harpalpur",
                "countryCode": "IN"
            },
            {
                "name": "Harrai",
                "countryCode": "IN"
            },
            {
                "name": "Harsud",
                "countryCode": "IN"
            },
            {
                "name": "Hatod",
                "countryCode": "IN"
            },
            {
                "name": "Hatta",
                "countryCode": "IN"
            },
            {
                "name": "Hindoria",
                "countryCode": "IN"
            },
            {
                "name": "Hoshangabad",
                "countryCode": "IN"
            },
            {
                "name": "Iawar",
                "countryCode": "IN"
            },
            {
                "name": "Ichhawar",
                "countryCode": "IN"
            },
            {
                "name": "Iklehra",
                "countryCode": "IN"
            },
            {
                "name": "Indore",
                "countryCode": "IN"
            },
            {
                "name": "Isagarh",
                "countryCode": "IN"
            },
            {
                "name": "Itarsi",
                "countryCode": "IN"
            },
            {
                "name": "Jabalpur",
                "countryCode": "IN"
            },
            {
                "name": "Jaisinghnagar",
                "countryCode": "IN"
            },
            {
                "name": "Jaithari",
                "countryCode": "IN"
            },
            {
                "name": "Jamai",
                "countryCode": "IN"
            },
            {
                "name": "Jaora",
                "countryCode": "IN"
            },
            {
                "name": "Jatara",
                "countryCode": "IN"
            },
            {
                "name": "Jawad",
                "countryCode": "IN"
            },
            {
                "name": "Jhabua",
                "countryCode": "IN"
            },
            {
                "name": "Jiran",
                "countryCode": "IN"
            },
            {
                "name": "Jobat",
                "countryCode": "IN"
            },
            {
                "name": "Kailaras",
                "countryCode": "IN"
            },
            {
                "name": "Kaimori",
                "countryCode": "IN"
            },
            {
                "name": "Kannod",
                "countryCode": "IN"
            },
            {
                "name": "Kareli",
                "countryCode": "IN"
            },
            {
                "name": "Karera",
                "countryCode": "IN"
            },
            {
                "name": "Karrapur",
                "countryCode": "IN"
            },
            {
                "name": "Kasrawad",
                "countryCode": "IN"
            },
            {
                "name": "Katangi",
                "countryCode": "IN"
            },
            {
                "name": "Katni",
                "countryCode": "IN"
            },
            {
                "name": "Khachrod",
                "countryCode": "IN"
            },
            {
                "name": "Khailar",
                "countryCode": "IN"
            },
            {
                "name": "Khajuraho Group of Monuments",
                "countryCode": "IN"
            },
            {
                "name": "Khamaria",
                "countryCode": "IN"
            },
            {
                "name": "Khandwa",
                "countryCode": "IN"
            },
            {
                "name": "Khandwa district",
                "countryCode": "IN"
            },
            {
                "name": "Khargapur",
                "countryCode": "IN"
            },
            {
                "name": "Khargone",
                "countryCode": "IN"
            },
            {
                "name": "Khategaon",
                "countryCode": "IN"
            },
            {
                "name": "Khilchipur",
                "countryCode": "IN"
            },
            {
                "name": "Khirkiyan",
                "countryCode": "IN"
            },
            {
                "name": "Khujner",
                "countryCode": "IN"
            },
            {
                "name": "Khurai",
                "countryCode": "IN"
            },
            {
                "name": "Kolaras",
                "countryCode": "IN"
            },
            {
                "name": "Korwai",
                "countryCode": "IN"
            },
            {
                "name": "Kotar",
                "countryCode": "IN"
            },
            {
                "name": "Kothi",
                "countryCode": "IN"
            },
            {
                "name": "Kotma",
                "countryCode": "IN"
            },
            {
                "name": "Kotwa",
                "countryCode": "IN"
            },
            {
                "name": "Kukshi",
                "countryCode": "IN"
            },
            {
                "name": "Kumbhraj",
                "countryCode": "IN"
            },
            {
                "name": "Lahar",
                "countryCode": "IN"
            },
            {
                "name": "Lakhnadon",
                "countryCode": "IN"
            },
            {
                "name": "Leteri",
                "countryCode": "IN"
            },
            {
                "name": "Lodhikheda",
                "countryCode": "IN"
            },
            {
                "name": "Machalpur",
                "countryCode": "IN"
            },
            {
                "name": "Madhogarh",
                "countryCode": "IN"
            },
            {
                "name": "Maheshwar",
                "countryCode": "IN"
            },
            {
                "name": "Mahgawan",
                "countryCode": "IN"
            },
            {
                "name": "Maihar",
                "countryCode": "IN"
            },
            {
                "name": "Majholi",
                "countryCode": "IN"
            },
            {
                "name": "Maksi",
                "countryCode": "IN"
            },
            {
                "name": "Malhargarh",
                "countryCode": "IN"
            },
            {
                "name": "Manasa",
                "countryCode": "IN"
            },
            {
                "name": "Manawar",
                "countryCode": "IN"
            },
            {
                "name": "Mandideep",
                "countryCode": "IN"
            },
            {
                "name": "Mandla",
                "countryCode": "IN"
            },
            {
                "name": "Mandleshwar",
                "countryCode": "IN"
            },
            {
                "name": "Mandsaur",
                "countryCode": "IN"
            },
            {
                "name": "Mangawan",
                "countryCode": "IN"
            },
            {
                "name": "Manpur",
                "countryCode": "IN"
            },
            {
                "name": "Mau",
                "countryCode": "IN"
            },
            {
                "name": "Mauganj",
                "countryCode": "IN"
            },
            {
                "name": "Mihona",
                "countryCode": "IN"
            },
            {
                "name": "Mohgaon",
                "countryCode": "IN"
            },
            {
                "name": "Morar",
                "countryCode": "IN"
            },
            {
                "name": "Morena",
                "countryCode": "IN"
            },
            {
                "name": "Multai",
                "countryCode": "IN"
            },
            {
                "name": "Mundi",
                "countryCode": "IN"
            },
            {
                "name": "Mungaoli",
                "countryCode": "IN"
            },
            {
                "name": "Murwara",
                "countryCode": "IN"
            },
            {
                "name": "Nagda",
                "countryCode": "IN"
            },
            {
                "name": "Nagod",
                "countryCode": "IN"
            },
            {
                "name": "Naigarhi",
                "countryCode": "IN"
            },
            {
                "name": "Nainpur",
                "countryCode": "IN"
            },
            {
                "name": "Namli",
                "countryCode": "IN"
            },
            {
                "name": "Naraini",
                "countryCode": "IN"
            },
            {
                "name": "Narayangarh",
                "countryCode": "IN"
            },
            {
                "name": "Narsimhapur",
                "countryCode": "IN"
            },
            {
                "name": "Narsinghgarh",
                "countryCode": "IN"
            },
            {
                "name": "Narwar",
                "countryCode": "IN"
            },
            {
                "name": "Nasrullahganj",
                "countryCode": "IN"
            },
            {
                "name": "Neemuch",
                "countryCode": "IN"
            },
            {
                "name": "Nepanagar",
                "countryCode": "IN"
            },
            {
                "name": "Orchha",
                "countryCode": "IN"
            },
            {
                "name": "Pachmarhi",
                "countryCode": "IN"
            },
            {
                "name": "Palera",
                "countryCode": "IN"
            },
            {
                "name": "Pali",
                "countryCode": "IN"
            },
            {
                "name": "Panagar",
                "countryCode": "IN"
            },
            {
                "name": "Panara",
                "countryCode": "IN"
            },
            {
                "name": "Pandhana",
                "countryCode": "IN"
            },
            {
                "name": "Pandhurna",
                "countryCode": "IN"
            },
            {
                "name": "Panna",
                "countryCode": "IN"
            },
            {
                "name": "Pansemal",
                "countryCode": "IN"
            },
            {
                "name": "Parasia",
                "countryCode": "IN"
            },
            {
                "name": "Patan",
                "countryCode": "IN"
            },
            {
                "name": "Patharia",
                "countryCode": "IN"
            },
            {
                "name": "Pawai",
                "countryCode": "IN"
            },
            {
                "name": "Petlawad",
                "countryCode": "IN"
            },
            {
                "name": "Piploda",
                "countryCode": "IN"
            },
            {
                "name": "Pithampur",
                "countryCode": "IN"
            },
            {
                "name": "Porsa",
                "countryCode": "IN"
            },
            {
                "name": "Punasa",
                "countryCode": "IN"
            },
            {
                "name": "Raghogarh",
                "countryCode": "IN"
            },
            {
                "name": "Rahatgarh",
                "countryCode": "IN"
            },
            {
                "name": "Raisen",
                "countryCode": "IN"
            },
            {
                "name": "Rajgarh",
                "countryCode": "IN"
            },
            {
                "name": "Rajnagar",
                "countryCode": "IN"
            },
            {
                "name": "Rajpur",
                "countryCode": "IN"
            },
            {
                "name": "Rampura",
                "countryCode": "IN"
            },
            {
                "name": "Ranapur",
                "countryCode": "IN"
            },
            {
                "name": "Ratangarh",
                "countryCode": "IN"
            },
            {
                "name": "Ratlam",
                "countryCode": "IN"
            },
            {
                "name": "Rehli",
                "countryCode": "IN"
            },
            {
                "name": "Rehti",
                "countryCode": "IN"
            },
            {
                "name": "Rewa",
                "countryCode": "IN"
            },
            {
                "name": "Sabalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Sagar",
                "countryCode": "IN"
            },
            {
                "name": "Sailana",
                "countryCode": "IN"
            },
            {
                "name": "Sanawad",
                "countryCode": "IN"
            },
            {
                "name": "Sanchi",
                "countryCode": "IN"
            },
            {
                "name": "Sanwer",
                "countryCode": "IN"
            },
            {
                "name": "Sarangpur",
                "countryCode": "IN"
            },
            {
                "name": "Satna",
                "countryCode": "IN"
            },
            {
                "name": "Satwas",
                "countryCode": "IN"
            },
            {
                "name": "Saugor",
                "countryCode": "IN"
            },
            {
                "name": "Sausar",
                "countryCode": "IN"
            },
            {
                "name": "Sehore",
                "countryCode": "IN"
            },
            {
                "name": "Sendhwa",
                "countryCode": "IN"
            },
            {
                "name": "Seondha",
                "countryCode": "IN"
            },
            {
                "name": "Seoni",
                "countryCode": "IN"
            },
            {
                "name": "Seoni Malwa",
                "countryCode": "IN"
            },
            {
                "name": "Shahdol",
                "countryCode": "IN"
            },
            {
                "name": "Shahgarh",
                "countryCode": "IN"
            },
            {
                "name": "Shahpur",
                "countryCode": "IN"
            },
            {
                "name": "Shahpura",
                "countryCode": "IN"
            },
            {
                "name": "Shajapur",
                "countryCode": "IN"
            },
            {
                "name": "Shamgarh",
                "countryCode": "IN"
            },
            {
                "name": "Sheopur",
                "countryCode": "IN"
            },
            {
                "name": "Shivpuri",
                "countryCode": "IN"
            },
            {
                "name": "Shujalpur",
                "countryCode": "IN"
            },
            {
                "name": "Sidhi",
                "countryCode": "IN"
            },
            {
                "name": "Sihora",
                "countryCode": "IN"
            },
            {
                "name": "Simaria",
                "countryCode": "IN"
            },
            {
                "name": "Singoli",
                "countryCode": "IN"
            },
            {
                "name": "Singrauli",
                "countryCode": "IN"
            },
            {
                "name": "Sirmaur",
                "countryCode": "IN"
            },
            {
                "name": "Sironj",
                "countryCode": "IN"
            },
            {
                "name": "Sitamau",
                "countryCode": "IN"
            },
            {
                "name": "Sohagi",
                "countryCode": "IN"
            },
            {
                "name": "Sohagpur",
                "countryCode": "IN"
            },
            {
                "name": "Sultanpur",
                "countryCode": "IN"
            },
            {
                "name": "Susner",
                "countryCode": "IN"
            },
            {
                "name": "Tal",
                "countryCode": "IN"
            },
            {
                "name": "Talen",
                "countryCode": "IN"
            },
            {
                "name": "Tarana",
                "countryCode": "IN"
            },
            {
                "name": "Tekanpur",
                "countryCode": "IN"
            },
            {
                "name": "Tendukheda",
                "countryCode": "IN"
            },
            {
                "name": "Teonthar",
                "countryCode": "IN"
            },
            {
                "name": "Thandla",
                "countryCode": "IN"
            },
            {
                "name": "Tikamgarh",
                "countryCode": "IN"
            },
            {
                "name": "Tirodi",
                "countryCode": "IN"
            },
            {
                "name": "Udaipura",
                "countryCode": "IN"
            },
            {
                "name": "Ujjain",
                "countryCode": "IN"
            },
            {
                "name": "Ukwa",
                "countryCode": "IN"
            },
            {
                "name": "Umaria",
                "countryCode": "IN"
            },
            {
                "name": "Umaria District",
                "countryCode": "IN"
            },
            {
                "name": "Umri",
                "countryCode": "IN"
            },
            {
                "name": "Unhel",
                "countryCode": "IN"
            },
            {
                "name": "Vidisha",
                "countryCode": "IN"
            },
            {
                "name": "Waraseoni",
                "countryCode": "IN"
            },
            {
                "name": "Aizawl",
                "countryCode": "IN"
            },
            {
                "name": "Champhai",
                "countryCode": "IN"
            },
            {
                "name": "Darlawn",
                "countryCode": "IN"
            },
            {
                "name": "Khawhai",
                "countryCode": "IN"
            },
            {
                "name": "Kolasib",
                "countryCode": "IN"
            },
            {
                "name": "Kolasib district",
                "countryCode": "IN"
            },
            {
                "name": "Lawngtlai",
                "countryCode": "IN"
            },
            {
                "name": "Lunglei",
                "countryCode": "IN"
            },
            {
                "name": "Mamit",
                "countryCode": "IN"
            },
            {
                "name": "North Vanlaiphai",
                "countryCode": "IN"
            },
            {
                "name": "Saiha",
                "countryCode": "IN"
            },
            {
                "name": "Sairang",
                "countryCode": "IN"
            },
            {
                "name": "Saitlaw",
                "countryCode": "IN"
            },
            {
                "name": "Serchhip",
                "countryCode": "IN"
            },
            {
                "name": "Thenzawl",
                "countryCode": "IN"
            },
            {
                "name": "Dimapur",
                "countryCode": "IN"
            },
            {
                "name": "Kohima",
                "countryCode": "IN"
            },
            {
                "name": "Mokokchung",
                "countryCode": "IN"
            },
            {
                "name": "Mon",
                "countryCode": "IN"
            },
            {
                "name": "Peren",
                "countryCode": "IN"
            },
            {
                "name": "Phek",
                "countryCode": "IN"
            },
            {
                "name": "Tuensang",
                "countryCode": "IN"
            },
            {
                "name": "Tuensang District",
                "countryCode": "IN"
            },
            {
                "name": "Wokha",
                "countryCode": "IN"
            },
            {
                "name": "Zunheboto",
                "countryCode": "IN"
            },
            {
                "name": "Angul",
                "countryCode": "IN"
            },
            {
                "name": "Angul District",
                "countryCode": "IN"
            },
            {
                "name": "Asika",
                "countryCode": "IN"
            },
            {
                "name": "Athagarh",
                "countryCode": "IN"
            },
            {
                "name": "Bada Barabil",
                "countryCode": "IN"
            },
            {
                "name": "Balangir",
                "countryCode": "IN"
            },
            {
                "name": "Balasore",
                "countryCode": "IN"
            },
            {
                "name": "Baleshwar",
                "countryCode": "IN"
            },
            {
                "name": "Balimila",
                "countryCode": "IN"
            },
            {
                "name": "Balugaon",
                "countryCode": "IN"
            },
            {
                "name": "Banapur",
                "countryCode": "IN"
            },
            {
                "name": "Banki",
                "countryCode": "IN"
            },
            {
                "name": "Banposh",
                "countryCode": "IN"
            },
            {
                "name": "Baragarh",
                "countryCode": "IN"
            },
            {
                "name": "Barbil",
                "countryCode": "IN"
            },
            {
                "name": "Bargarh",
                "countryCode": "IN"
            },
            {
                "name": "Barpali",
                "countryCode": "IN"
            },
            {
                "name": "Basudebpur",
                "countryCode": "IN"
            },
            {
                "name": "Baud",
                "countryCode": "IN"
            },
            {
                "name": "Baudh",
                "countryCode": "IN"
            },
            {
                "name": "Belaguntha",
                "countryCode": "IN"
            },
            {
                "name": "Bhadrak",
                "countryCode": "IN"
            },
            {
                "name": "Bhadrakh",
                "countryCode": "IN"
            },
            {
                "name": "Bhanjanagar",
                "countryCode": "IN"
            },
            {
                "name": "Bhawanipatna",
                "countryCode": "IN"
            },
            {
                "name": "Bhuban",
                "countryCode": "IN"
            },
            {
                "name": "Bhubaneshwar",
                "countryCode": "IN"
            },
            {
                "name": "Binka",
                "countryCode": "IN"
            },
            {
                "name": "Birmitrapur",
                "countryCode": "IN"
            },
            {
                "name": "Bolanikhodan",
                "countryCode": "IN"
            },
            {
                "name": "Brahmapur",
                "countryCode": "IN"
            },
            {
                "name": "Brajarajnagar",
                "countryCode": "IN"
            },
            {
                "name": "Buguda",
                "countryCode": "IN"
            },
            {
                "name": "Burla",
                "countryCode": "IN"
            },
            {
                "name": "Champua",
                "countryCode": "IN"
            },
            {
                "name": "Chandbali",
                "countryCode": "IN"
            },
            {
                "name": "Chatrapur",
                "countryCode": "IN"
            },
            {
                "name": "Chikitigarh",
                "countryCode": "IN"
            },
            {
                "name": "Chittarkonda",
                "countryCode": "IN"
            },
            {
                "name": "Cuttack",
                "countryCode": "IN"
            },
            {
                "name": "Daitari",
                "countryCode": "IN"
            },
            {
                "name": "Deogarh",
                "countryCode": "IN"
            },
            {
                "name": "Dhenkanal",
                "countryCode": "IN"
            },
            {
                "name": "Digapahandi",
                "countryCode": "IN"
            },
            {
                "name": "Gajapati",
                "countryCode": "IN"
            },
            {
                "name": "Ganjam",
                "countryCode": "IN"
            },
            {
                "name": "Gopalpur",
                "countryCode": "IN"
            },
            {
                "name": "Gudari",
                "countryCode": "IN"
            },
            {
                "name": "Gunupur",
                "countryCode": "IN"
            },
            {
                "name": "Hinjilikatu",
                "countryCode": "IN"
            },
            {
                "name": "Hirakud",
                "countryCode": "IN"
            },
            {
                "name": "Jagatsinghapur",
                "countryCode": "IN"
            },
            {
                "name": "Jagatsinghpur",
                "countryCode": "IN"
            },
            {
                "name": "Jajpur",
                "countryCode": "IN"
            },
            {
                "name": "Jaleshwar",
                "countryCode": "IN"
            },
            {
                "name": "Jatani",
                "countryCode": "IN"
            },
            {
                "name": "Jeypore",
                "countryCode": "IN"
            },
            {
                "name": "Jharsuguda",
                "countryCode": "IN"
            },
            {
                "name": "Jharsuguda District",
                "countryCode": "IN"
            },
            {
                "name": "Kaintragarh",
                "countryCode": "IN"
            },
            {
                "name": "Kalahandi",
                "countryCode": "IN"
            },
            {
                "name": "Kamakhyanagar",
                "countryCode": "IN"
            },
            {
                "name": "Kandhamal",
                "countryCode": "IN"
            },
            {
                "name": "Kantabanji",
                "countryCode": "IN"
            },
            {
                "name": "Kantilo",
                "countryCode": "IN"
            },
            {
                "name": "Kendrapara",
                "countryCode": "IN"
            },
            {
                "name": "Kendraparha",
                "countryCode": "IN"
            },
            {
                "name": "Kendujhar",
                "countryCode": "IN"
            },
            {
                "name": "Kesinga",
                "countryCode": "IN"
            },
            {
                "name": "Khallikot",
                "countryCode": "IN"
            },
            {
                "name": "Kharhial",
                "countryCode": "IN"
            },
            {
                "name": "Khordha",
                "countryCode": "IN"
            },
            {
                "name": "Khurda",
                "countryCode": "IN"
            },
            {
                "name": "Kiri Buru",
                "countryCode": "IN"
            },
            {
                "name": "Kodala",
                "countryCode": "IN"
            },
            {
                "name": "Konarka",
                "countryCode": "IN"
            },
            {
                "name": "Koraput",
                "countryCode": "IN"
            },
            {
                "name": "Kuchaiburi",
                "countryCode": "IN"
            },
            {
                "name": "Kuchinda",
                "countryCode": "IN"
            },
            {
                "name": "Malakanagiri",
                "countryCode": "IN"
            },
            {
                "name": "Malkangiri",
                "countryCode": "IN"
            },
            {
                "name": "Mayurbhanj",
                "countryCode": "IN"
            },
            {
                "name": "Nabarangpur",
                "countryCode": "IN"
            },
            {
                "name": "Nayagarh",
                "countryCode": "IN"
            },
            {
                "name": "Nayagarh District",
                "countryCode": "IN"
            },
            {
                "name": "Nilgiri",
                "countryCode": "IN"
            },
            {
                "name": "Nimaparha",
                "countryCode": "IN"
            },
            {
                "name": "Nowrangapur",
                "countryCode": "IN"
            },
            {
                "name": "Nuapada",
                "countryCode": "IN"
            },
            {
                "name": "Padampur",
                "countryCode": "IN"
            },
            {
                "name": "Paradip Garh",
                "countryCode": "IN"
            },
            {
                "name": "Patamundai",
                "countryCode": "IN"
            },
            {
                "name": "Patnagarh",
                "countryCode": "IN"
            },
            {
                "name": "Phulbani",
                "countryCode": "IN"
            },
            {
                "name": "Pipili",
                "countryCode": "IN"
            },
            {
                "name": "Polasara",
                "countryCode": "IN"
            },
            {
                "name": "Puri",
                "countryCode": "IN"
            },
            {
                "name": "Purushottampur",
                "countryCode": "IN"
            },
            {
                "name": "Rambha",
                "countryCode": "IN"
            },
            {
                "name": "Raurkela",
                "countryCode": "IN"
            },
            {
                "name": "Rayagada",
                "countryCode": "IN"
            },
            {
                "name": "Remuna",
                "countryCode": "IN"
            },
            {
                "name": "Rengali",
                "countryCode": "IN"
            },
            {
                "name": "Sambalpur",
                "countryCode": "IN"
            },
            {
                "name": "Sonepur",
                "countryCode": "IN"
            },
            {
                "name": "Sorada",
                "countryCode": "IN"
            },
            {
                "name": "Soro",
                "countryCode": "IN"
            },
            {
                "name": "Subarnapur",
                "countryCode": "IN"
            },
            {
                "name": "Sundargarh",
                "countryCode": "IN"
            },
            {
                "name": "Talcher",
                "countryCode": "IN"
            },
            {
                "name": "Tarabha",
                "countryCode": "IN"
            },
            {
                "name": "Titlagarh",
                "countryCode": "IN"
            },
            {
                "name": "Udayagiri",
                "countryCode": "IN"
            },
            {
                "name": "Abohar",
                "countryCode": "IN"
            },
            {
                "name": "Adampur",
                "countryCode": "IN"
            },
            {
                "name": "Ajitgarh",
                "countryCode": "IN"
            },
            {
                "name": "Ajnala",
                "countryCode": "IN"
            },
            {
                "name": "Akalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Alawalpur",
                "countryCode": "IN"
            },
            {
                "name": "Amloh",
                "countryCode": "IN"
            },
            {
                "name": "Amritsar",
                "countryCode": "IN"
            },
            {
                "name": "Anandpur Sahib",
                "countryCode": "IN"
            },
            {
                "name": "Badhni Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Bagha Purana",
                "countryCode": "IN"
            },
            {
                "name": "Bakloh",
                "countryCode": "IN"
            },
            {
                "name": "Balachor",
                "countryCode": "IN"
            },
            {
                "name": "Banga",
                "countryCode": "IN"
            },
            {
                "name": "Banur",
                "countryCode": "IN"
            },
            {
                "name": "Barnala",
                "countryCode": "IN"
            },
            {
                "name": "Batala",
                "countryCode": "IN"
            },
            {
                "name": "Begowal",
                "countryCode": "IN"
            },
            {
                "name": "Bhadaur",
                "countryCode": "IN"
            },
            {
                "name": "Bhatinda",
                "countryCode": "IN"
            },
            {
                "name": "Bhawanigarh",
                "countryCode": "IN"
            },
            {
                "name": "Bhikhi",
                "countryCode": "IN"
            },
            {
                "name": "Bhogpur",
                "countryCode": "IN"
            },
            {
                "name": "Bholath",
                "countryCode": "IN"
            },
            {
                "name": "Budhlada",
                "countryCode": "IN"
            },
            {
                "name": "Chima",
                "countryCode": "IN"
            },
            {
                "name": "Dasuya",
                "countryCode": "IN"
            },
            {
                "name": "Dera Baba Nanak",
                "countryCode": "IN"
            },
            {
                "name": "Dera Bassi",
                "countryCode": "IN"
            },
            {
                "name": "Dhanaula",
                "countryCode": "IN"
            },
            {
                "name": "Dhariwal",
                "countryCode": "IN"
            },
            {
                "name": "Dhilwan",
                "countryCode": "IN"
            },
            {
                "name": "Dhudi",
                "countryCode": "IN"
            },
            {
                "name": "Dhuri",
                "countryCode": "IN"
            },
            {
                "name": "Dina Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Dirba",
                "countryCode": "IN"
            },
            {
                "name": "Doraha",
                "countryCode": "IN"
            },
            {
                "name": "Faridkot",
                "countryCode": "IN"
            },
            {
                "name": "Fatehgarh Churian",
                "countryCode": "IN"
            },
            {
                "name": "Fatehgarh Sahib",
                "countryCode": "IN"
            },
            {
                "name": "Fazilka",
                "countryCode": "IN"
            },
            {
                "name": "Firozpur",
                "countryCode": "IN"
            },
            {
                "name": "Firozpur District",
                "countryCode": "IN"
            },
            {
                "name": "Gardhiwala",
                "countryCode": "IN"
            },
            {
                "name": "Garhshankar",
                "countryCode": "IN"
            },
            {
                "name": "Ghanaur",
                "countryCode": "IN"
            },
            {
                "name": "Giddarbaha",
                "countryCode": "IN"
            },
            {
                "name": "Gurdaspur",
                "countryCode": "IN"
            },
            {
                "name": "Guru Har Sahai",
                "countryCode": "IN"
            },
            {
                "name": "Hajipur",
                "countryCode": "IN"
            },
            {
                "name": "Hariana",
                "countryCode": "IN"
            },
            {
                "name": "Hoshiarpur",
                "countryCode": "IN"
            },
            {
                "name": "Ishanpur",
                "countryCode": "IN"
            },
            {
                "name": "Jagraon",
                "countryCode": "IN"
            },
            {
                "name": "Jaito",
                "countryCode": "IN"
            },
            {
                "name": "Jalalabad",
                "countryCode": "IN"
            },
            {
                "name": "Jalandhar",
                "countryCode": "IN"
            },
            {
                "name": "Jandiala",
                "countryCode": "IN"
            },
            {
                "name": "Jandiala Guru",
                "countryCode": "IN"
            },
            {
                "name": "Kalanaur",
                "countryCode": "IN"
            },
            {
                "name": "Kapurthala",
                "countryCode": "IN"
            },
            {
                "name": "Kartarpur",
                "countryCode": "IN"
            },
            {
                "name": "Khamanon",
                "countryCode": "IN"
            },
            {
                "name": "Khanna",
                "countryCode": "IN"
            },
            {
                "name": "Kharar",
                "countryCode": "IN"
            },
            {
                "name": "Khemkaran",
                "countryCode": "IN"
            },
            {
                "name": "Kot Isa Khan",
                "countryCode": "IN"
            },
            {
                "name": "Kotkapura",
                "countryCode": "IN"
            },
            {
                "name": "Laungowal",
                "countryCode": "IN"
            },
            {
                "name": "Ludhiana",
                "countryCode": "IN"
            },
            {
                "name": "Machhiwara",
                "countryCode": "IN"
            },
            {
                "name": "Majitha",
                "countryCode": "IN"
            },
            {
                "name": "Makhu",
                "countryCode": "IN"
            },
            {
                "name": "Malaut",
                "countryCode": "IN"
            },
            {
                "name": "Malerkotla",
                "countryCode": "IN"
            },
            {
                "name": "Mansa",
                "countryCode": "IN"
            },
            {
                "name": "Maur Mandi",
                "countryCode": "IN"
            },
            {
                "name": "Moga",
                "countryCode": "IN"
            },
            {
                "name": "Mohali",
                "countryCode": "IN"
            },
            {
                "name": "Morinda",
                "countryCode": "IN"
            },
            {
                "name": "Mukerian",
                "countryCode": "IN"
            },
            {
                "name": "Nabha",
                "countryCode": "IN"
            },
            {
                "name": "Nakodar",
                "countryCode": "IN"
            },
            {
                "name": "Nangal",
                "countryCode": "IN"
            },
            {
                "name": "Nawanshahr",
                "countryCode": "IN"
            },
            {
                "name": "Nurmahal",
                "countryCode": "IN"
            },
            {
                "name": "Nurpur Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Pathankot",
                "countryCode": "IN"
            },
            {
                "name": "Patiala",
                "countryCode": "IN"
            },
            {
                "name": "Patti",
                "countryCode": "IN"
            },
            {
                "name": "Phagwara",
                "countryCode": "IN"
            },
            {
                "name": "Phillaur",
                "countryCode": "IN"
            },
            {
                "name": "Qadian",
                "countryCode": "IN"
            },
            {
                "name": "Rahon",
                "countryCode": "IN"
            },
            {
                "name": "Raikot",
                "countryCode": "IN"
            },
            {
                "name": "Rajasansi",
                "countryCode": "IN"
            },
            {
                "name": "Rajpura",
                "countryCode": "IN"
            },
            {
                "name": "Ram Das",
                "countryCode": "IN"
            },
            {
                "name": "Rampura",
                "countryCode": "IN"
            },
            {
                "name": "Rupnagar",
                "countryCode": "IN"
            },
            {
                "name": "Samrala",
                "countryCode": "IN"
            },
            {
                "name": "Sanaur",
                "countryCode": "IN"
            },
            {
                "name": "Sangrur",
                "countryCode": "IN"
            },
            {
                "name": "Sardulgarh",
                "countryCode": "IN"
            },
            {
                "name": "Shahid Bhagat Singh Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Shahkot",
                "countryCode": "IN"
            },
            {
                "name": "Sham Churasi",
                "countryCode": "IN"
            },
            {
                "name": "Sirhind-Fategarh",
                "countryCode": "IN"
            },
            {
                "name": "Sri Muktsar Sahib",
                "countryCode": "IN"
            },
            {
                "name": "Sultanpur Lodhi",
                "countryCode": "IN"
            },
            {
                "name": "Sunam",
                "countryCode": "IN"
            },
            {
                "name": "Talwandi Bhai",
                "countryCode": "IN"
            },
            {
                "name": "Talwara",
                "countryCode": "IN"
            },
            {
                "name": "Tarn Taran Sahib",
                "countryCode": "IN"
            },
            {
                "name": "Zira",
                "countryCode": "IN"
            },
            {
                "name": "Karaikal",
                "countryCode": "IN"
            },
            {
                "name": "Mahe",
                "countryCode": "IN"
            },
            {
                "name": "Puducherry",
                "countryCode": "IN"
            },
            {
                "name": "Yanam",
                "countryCode": "IN"
            },
            {
                "name": "Abhaneri",
                "countryCode": "IN"
            },
            {
                "name": "Abu",
                "countryCode": "IN"
            },
            {
                "name": "Abu Road",
                "countryCode": "IN"
            },
            {
                "name": "Ajmer",
                "countryCode": "IN"
            },
            {
                "name": "Aklera",
                "countryCode": "IN"
            },
            {
                "name": "Alwar",
                "countryCode": "IN"
            },
            {
                "name": "Amet",
                "countryCode": "IN"
            },
            {
                "name": "Anta",
                "countryCode": "IN"
            },
            {
                "name": "Anupgarh",
                "countryCode": "IN"
            },
            {
                "name": "Asind",
                "countryCode": "IN"
            },
            {
                "name": "Bagar",
                "countryCode": "IN"
            },
            {
                "name": "Bakani",
                "countryCode": "IN"
            },
            {
                "name": "Bali",
                "countryCode": "IN"
            },
            {
                "name": "Balotra",
                "countryCode": "IN"
            },
            {
                "name": "Bandikui",
                "countryCode": "IN"
            },
            {
                "name": "Banswara",
                "countryCode": "IN"
            },
            {
                "name": "Baran",
                "countryCode": "IN"
            },
            {
                "name": "Bari",
                "countryCode": "IN"
            },
            {
                "name": "Bari Sadri",
                "countryCode": "IN"
            },
            {
                "name": "Barmer",
                "countryCode": "IN"
            },
            {
                "name": "Basi",
                "countryCode": "IN"
            },
            {
                "name": "Basni",
                "countryCode": "IN"
            },
            {
                "name": "Baswa",
                "countryCode": "IN"
            },
            {
                "name": "Bayana",
                "countryCode": "IN"
            },
            {
                "name": "Beawar",
                "countryCode": "IN"
            },
            {
                "name": "Begun",
                "countryCode": "IN"
            },
            {
                "name": "Behror",
                "countryCode": "IN"
            },
            {
                "name": "Bhadasar",
                "countryCode": "IN"
            },
            {
                "name": "Bhadra",
                "countryCode": "IN"
            },
            {
                "name": "Bharatpur",
                "countryCode": "IN"
            },
            {
                "name": "Bhasawar",
                "countryCode": "IN"
            },
            {
                "name": "Bhilwara",
                "countryCode": "IN"
            },
            {
                "name": "Bhindar",
                "countryCode": "IN"
            },
            {
                "name": "Bhinmal",
                "countryCode": "IN"
            },
            {
                "name": "Bhiwadi",
                "countryCode": "IN"
            },
            {
                "name": "Bhuma",
                "countryCode": "IN"
            },
            {
                "name": "Bikaner",
                "countryCode": "IN"
            },
            {
                "name": "Bilara",
                "countryCode": "IN"
            },
            {
                "name": "Bissau",
                "countryCode": "IN"
            },
            {
                "name": "Borkhera",
                "countryCode": "IN"
            },
            {
                "name": "Bundi",
                "countryCode": "IN"
            },
            {
                "name": "Chaksu",
                "countryCode": "IN"
            },
            {
                "name": "Chechat",
                "countryCode": "IN"
            },
            {
                "name": "Chhabra",
                "countryCode": "IN"
            },
            {
                "name": "Chhapar",
                "countryCode": "IN"
            },
            {
                "name": "Chhoti Sadri",
                "countryCode": "IN"
            },
            {
                "name": "Chidawa",
                "countryCode": "IN"
            },
            {
                "name": "Chittaurgarh",
                "countryCode": "IN"
            },
            {
                "name": "Churu",
                "countryCode": "IN"
            },
            {
                "name": "Dariba",
                "countryCode": "IN"
            },
            {
                "name": "Dausa",
                "countryCode": "IN"
            },
            {
                "name": "Deoli",
                "countryCode": "IN"
            },
            {
                "name": "Deshnoke",
                "countryCode": "IN"
            },
            {
                "name": "Devgarh",
                "countryCode": "IN"
            },
            {
                "name": "Dhaulpur",
                "countryCode": "IN"
            },
            {
                "name": "Didwana",
                "countryCode": "IN"
            },
            {
                "name": "Dig",
                "countryCode": "IN"
            },
            {
                "name": "Dungarpur",
                "countryCode": "IN"
            },
            {
                "name": "Fatehpur",
                "countryCode": "IN"
            },
            {
                "name": "Galiakot",
                "countryCode": "IN"
            },
            {
                "name": "Ganganagar",
                "countryCode": "IN"
            },
            {
                "name": "Gangapur",
                "countryCode": "IN"
            },
            {
                "name": "Govindgarh",
                "countryCode": "IN"
            },
            {
                "name": "Gulabpura",
                "countryCode": "IN"
            },
            {
                "name": "Hanumangarh",
                "countryCode": "IN"
            },
            {
                "name": "Hindaun",
                "countryCode": "IN"
            },
            {
                "name": "Jahazpur",
                "countryCode": "IN"
            },
            {
                "name": "Jaipur",
                "countryCode": "IN"
            },
            {
                "name": "Jaisalmer",
                "countryCode": "IN"
            },
            {
                "name": "Jaitaran",
                "countryCode": "IN"
            },
            {
                "name": "Jalor",
                "countryCode": "IN"
            },
            {
                "name": "Jalore",
                "countryCode": "IN"
            },
            {
                "name": "Jhalawar",
                "countryCode": "IN"
            },
            {
                "name": "Jhalrapatan",
                "countryCode": "IN"
            },
            {
                "name": "Jhunjhunun",
                "countryCode": "IN"
            },
            {
                "name": "Jobner",
                "countryCode": "IN"
            },
            {
                "name": "Jodhpur",
                "countryCode": "IN"
            },
            {
                "name": "Kaman",
                "countryCode": "IN"
            },
            {
                "name": "Kanor",
                "countryCode": "IN"
            },
            {
                "name": "Kapren",
                "countryCode": "IN"
            },
            {
                "name": "Karanpur",
                "countryCode": "IN"
            },
            {
                "name": "Karauli",
                "countryCode": "IN"
            },
            {
                "name": "Kekri",
                "countryCode": "IN"
            },
            {
                "name": "Keshorai Patan",
                "countryCode": "IN"
            },
            {
                "name": "Khandela",
                "countryCode": "IN"
            },
            {
                "name": "Khanpur",
                "countryCode": "IN"
            },
            {
                "name": "Khetri",
                "countryCode": "IN"
            },
            {
                "name": "Kishangarh",
                "countryCode": "IN"
            },
            {
                "name": "Kota",
                "countryCode": "IN"
            },
            {
                "name": "Kotputli",
                "countryCode": "IN"
            },
            {
                "name": "Kuchaman",
                "countryCode": "IN"
            },
            {
                "name": "Kuchera",
                "countryCode": "IN"
            },
            {
                "name": "Kumher",
                "countryCode": "IN"
            },
            {
                "name": "Kushalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Lachhmangarh Sikar",
                "countryCode": "IN"
            },
            {
                "name": "Ladnun",
                "countryCode": "IN"
            },
            {
                "name": "Lakheri",
                "countryCode": "IN"
            },
            {
                "name": "Lalsot",
                "countryCode": "IN"
            },
            {
                "name": "Losal",
                "countryCode": "IN"
            },
            {
                "name": "Mahwah",
                "countryCode": "IN"
            },
            {
                "name": "Makrana",
                "countryCode": "IN"
            },
            {
                "name": "Malpura",
                "countryCode": "IN"
            },
            {
                "name": "Mandal",
                "countryCode": "IN"
            },
            {
                "name": "Mandalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Mandawar",
                "countryCode": "IN"
            },
            {
                "name": "Mangrol",
                "countryCode": "IN"
            },
            {
                "name": "Manohar Thana",
                "countryCode": "IN"
            },
            {
                "name": "Manoharpur",
                "countryCode": "IN"
            },
            {
                "name": "Meethari Marwar",
                "countryCode": "IN"
            },
            {
                "name": "Merta",
                "countryCode": "IN"
            },
            {
                "name": "Mundwa",
                "countryCode": "IN"
            },
            {
                "name": "Nadbai",
                "countryCode": "IN"
            },
            {
                "name": "Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Nagaur",
                "countryCode": "IN"
            },
            {
                "name": "Nainwa",
                "countryCode": "IN"
            },
            {
                "name": "Napasar",
                "countryCode": "IN"
            },
            {
                "name": "Naraina",
                "countryCode": "IN"
            },
            {
                "name": "Nasirabad",
                "countryCode": "IN"
            },
            {
                "name": "Nathdwara",
                "countryCode": "IN"
            },
            {
                "name": "Nawa",
                "countryCode": "IN"
            },
            {
                "name": "Nawalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Neem ka Thana",
                "countryCode": "IN"
            },
            {
                "name": "Nimaj",
                "countryCode": "IN"
            },
            {
                "name": "Nimbahera",
                "countryCode": "IN"
            },
            {
                "name": "Niwai",
                "countryCode": "IN"
            },
            {
                "name": "Nohar",
                "countryCode": "IN"
            },
            {
                "name": "Nokha",
                "countryCode": "IN"
            },
            {
                "name": "Padampur",
                "countryCode": "IN"
            },
            {
                "name": "Pali",
                "countryCode": "IN"
            },
            {
                "name": "Partapur",
                "countryCode": "IN"
            },
            {
                "name": "Parvatsar",
                "countryCode": "IN"
            },
            {
                "name": "Phalodi",
                "countryCode": "IN"
            },
            {
                "name": "Phulera",
                "countryCode": "IN"
            },
            {
                "name": "Pilani",
                "countryCode": "IN"
            },
            {
                "name": "Pilibangan",
                "countryCode": "IN"
            },
            {
                "name": "Pindwara",
                "countryCode": "IN"
            },
            {
                "name": "Pipar",
                "countryCode": "IN"
            },
            {
                "name": "Pirawa",
                "countryCode": "IN"
            },
            {
                "name": "Pokaran",
                "countryCode": "IN"
            },
            {
                "name": "Pratapgarh",
                "countryCode": "IN"
            },
            {
                "name": "Pushkar",
                "countryCode": "IN"
            },
            {
                "name": "Raipur",
                "countryCode": "IN"
            },
            {
                "name": "Raisinghnagar",
                "countryCode": "IN"
            },
            {
                "name": "Rajakhera",
                "countryCode": "IN"
            },
            {
                "name": "Rajaldesar",
                "countryCode": "IN"
            },
            {
                "name": "Rajgarh",
                "countryCode": "IN"
            },
            {
                "name": "Rajsamand",
                "countryCode": "IN"
            },
            {
                "name": "Ramganj Mandi",
                "countryCode": "IN"
            },
            {
                "name": "Ramgarh",
                "countryCode": "IN"
            },
            {
                "name": "Rani",
                "countryCode": "IN"
            },
            {
                "name": "Ratangarh",
                "countryCode": "IN"
            },
            {
                "name": "Rawatbhata",
                "countryCode": "IN"
            },
            {
                "name": "Rawatsar",
                "countryCode": "IN"
            },
            {
                "name": "Ringas",
                "countryCode": "IN"
            },
            {
                "name": "Sadri",
                "countryCode": "IN"
            },
            {
                "name": "Salumbar",
                "countryCode": "IN"
            },
            {
                "name": "Sambhar",
                "countryCode": "IN"
            },
            {
                "name": "Samdari",
                "countryCode": "IN"
            },
            {
                "name": "Sanchor",
                "countryCode": "IN"
            },
            {
                "name": "Sangaria",
                "countryCode": "IN"
            },
            {
                "name": "Sangod",
                "countryCode": "IN"
            },
            {
                "name": "Sardarshahr",
                "countryCode": "IN"
            },
            {
                "name": "Sarwar",
                "countryCode": "IN"
            },
            {
                "name": "Sawai Madhopur",
                "countryCode": "IN"
            },
            {
                "name": "Shahpura",
                "countryCode": "IN"
            },
            {
                "name": "Sheoganj",
                "countryCode": "IN"
            },
            {
                "name": "Sikar",
                "countryCode": "IN"
            },
            {
                "name": "Sirohi",
                "countryCode": "IN"
            },
            {
                "name": "Siwana",
                "countryCode": "IN"
            },
            {
                "name": "Sojat",
                "countryCode": "IN"
            },
            {
                "name": "Sri Dungargarh",
                "countryCode": "IN"
            },
            {
                "name": "Sri Madhopur",
                "countryCode": "IN"
            },
            {
                "name": "Sujangarh",
                "countryCode": "IN"
            },
            {
                "name": "Suket",
                "countryCode": "IN"
            },
            {
                "name": "Sunel",
                "countryCode": "IN"
            },
            {
                "name": "Surajgarh",
                "countryCode": "IN"
            },
            {
                "name": "Suratgarh",
                "countryCode": "IN"
            },
            {
                "name": "Takhatgarh",
                "countryCode": "IN"
            },
            {
                "name": "Taranagar",
                "countryCode": "IN"
            },
            {
                "name": "Tijara",
                "countryCode": "IN"
            },
            {
                "name": "Todabhim",
                "countryCode": "IN"
            },
            {
                "name": "Todaraisingh",
                "countryCode": "IN"
            },
            {
                "name": "Tonk",
                "countryCode": "IN"
            },
            {
                "name": "Udaipur",
                "countryCode": "IN"
            },
            {
                "name": "Udpura",
                "countryCode": "IN"
            },
            {
                "name": "Uniara",
                "countryCode": "IN"
            },
            {
                "name": "Wer",
                "countryCode": "IN"
            },
            {
                "name": "East District",
                "countryCode": "IN"
            },
            {
                "name": "Gangtok",
                "countryCode": "IN"
            },
            {
                "name": "Gyalshing",
                "countryCode": "IN"
            },
            {
                "name": "Jorethang",
                "countryCode": "IN"
            },
            {
                "name": "Mangan",
                "countryCode": "IN"
            },
            {
                "name": "Namchi",
                "countryCode": "IN"
            },
            {
                "name": "Naya Bazar",
                "countryCode": "IN"
            },
            {
                "name": "North District",
                "countryCode": "IN"
            },
            {
                "name": "Rangpo",
                "countryCode": "IN"
            },
            {
                "name": "Singtam",
                "countryCode": "IN"
            },
            {
                "name": "South District",
                "countryCode": "IN"
            },
            {
                "name": "West District",
                "countryCode": "IN"
            },
            {
                "name": "Adilabad",
                "countryCode": "IN"
            },
            {
                "name": "Alampur",
                "countryCode": "IN"
            },
            {
                "name": "Andol",
                "countryCode": "IN"
            },
            {
                "name": "Asifabad",
                "countryCode": "IN"
            },
            {
                "name": "Balapur",
                "countryCode": "IN"
            },
            {
                "name": "Banswada",
                "countryCode": "IN"
            },
            {
                "name": "Bellampalli",
                "countryCode": "IN"
            },
            {
                "name": "Bhadrachalam",
                "countryCode": "IN"
            },
            {
                "name": "Bhadradri Kothagudem",
                "countryCode": "IN"
            },
            {
                "name": "Bhaisa",
                "countryCode": "IN"
            },
            {
                "name": "Bhongir",
                "countryCode": "IN"
            },
            {
                "name": "Bodhan",
                "countryCode": "IN"
            },
            {
                "name": "Chandur",
                "countryCode": "IN"
            },
            {
                "name": "Chatakonda",
                "countryCode": "IN"
            },
            {
                "name": "Dasnapur",
                "countryCode": "IN"
            },
            {
                "name": "Devarkonda",
                "countryCode": "IN"
            },
            {
                "name": "Dornakal",
                "countryCode": "IN"
            },
            {
                "name": "Farrukhnagar",
                "countryCode": "IN"
            },
            {
                "name": "Gaddi Annaram",
                "countryCode": "IN"
            },
            {
                "name": "Gadwal",
                "countryCode": "IN"
            },
            {
                "name": "Ghatkesar",
                "countryCode": "IN"
            },
            {
                "name": "Gopalur",
                "countryCode": "IN"
            },
            {
                "name": "Gudur",
                "countryCode": "IN"
            },
            {
                "name": "Hyderabad",
                "countryCode": "IN"
            },
            {
                "name": "Jagtial",
                "countryCode": "IN"
            },
            {
                "name": "Jangaon",
                "countryCode": "IN"
            },
            {
                "name": "Jangoan",
                "countryCode": "IN"
            },
            {
                "name": "Jayashankar Bhupalapally",
                "countryCode": "IN"
            },
            {
                "name": "Jogulamba Gadwal",
                "countryCode": "IN"
            },
            {
                "name": "Kagaznagar",
                "countryCode": "IN"
            },
            {
                "name": "Kamareddi",
                "countryCode": "IN"
            },
            {
                "name": "Kamareddy",
                "countryCode": "IN"
            },
            {
                "name": "Karimnagar",
                "countryCode": "IN"
            },
            {
                "name": "Khammam",
                "countryCode": "IN"
            },
            {
                "name": "Kodar",
                "countryCode": "IN"
            },
            {
                "name": "Koratla",
                "countryCode": "IN"
            },
            {
                "name": "Kothapet",
                "countryCode": "IN"
            },
            {
                "name": "Kottagudem",
                "countryCode": "IN"
            },
            {
                "name": "Kottapalli",
                "countryCode": "IN"
            },
            {
                "name": "Kukatpalli",
                "countryCode": "IN"
            },
            {
                "name": "Kyathampalle",
                "countryCode": "IN"
            },
            {
                "name": "Lakshettipet",
                "countryCode": "IN"
            },
            {
                "name": "Lal Bahadur Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Mahabubabad",
                "countryCode": "IN"
            },
            {
                "name": "Mahbubnagar",
                "countryCode": "IN"
            },
            {
                "name": "Malkajgiri",
                "countryCode": "IN"
            },
            {
                "name": "Mancheral",
                "countryCode": "IN"
            },
            {
                "name": "Mandamarri",
                "countryCode": "IN"
            },
            {
                "name": "Manthani",
                "countryCode": "IN"
            },
            {
                "name": "Manuguru",
                "countryCode": "IN"
            },
            {
                "name": "Medak",
                "countryCode": "IN"
            },
            {
                "name": "Medchal",
                "countryCode": "IN"
            },
            {
                "name": "Medchal Malkajgiri",
                "countryCode": "IN"
            },
            {
                "name": "Mirialguda",
                "countryCode": "IN"
            },
            {
                "name": "Nagar Karnul",
                "countryCode": "IN"
            },
            {
                "name": "Nalgonda",
                "countryCode": "IN"
            },
            {
                "name": "Narayanpet",
                "countryCode": "IN"
            },
            {
                "name": "Narsingi",
                "countryCode": "IN"
            },
            {
                "name": "Naspur",
                "countryCode": "IN"
            },
            {
                "name": "Nirmal",
                "countryCode": "IN"
            },
            {
                "name": "Nizamabad",
                "countryCode": "IN"
            },
            {
                "name": "Paloncha",
                "countryCode": "IN"
            },
            {
                "name": "Palwancha",
                "countryCode": "IN"
            },
            {
                "name": "Patancheru",
                "countryCode": "IN"
            },
            {
                "name": "Peddapalli",
                "countryCode": "IN"
            },
            {
                "name": "Quthbullapur",
                "countryCode": "IN"
            },
            {
                "name": "Rajanna Sircilla",
                "countryCode": "IN"
            },
            {
                "name": "Ramagundam",
                "countryCode": "IN"
            },
            {
                "name": "Ramgundam",
                "countryCode": "IN"
            },
            {
                "name": "Rangareddi",
                "countryCode": "IN"
            },
            {
                "name": "Sadaseopet",
                "countryCode": "IN"
            },
            {
                "name": "Sangareddi",
                "countryCode": "IN"
            },
            {
                "name": "Sathupalli",
                "countryCode": "IN"
            },
            {
                "name": "Secunderabad",
                "countryCode": "IN"
            },
            {
                "name": "Serilingampalle",
                "countryCode": "IN"
            },
            {
                "name": "Siddipet",
                "countryCode": "IN"
            },
            {
                "name": "Singapur",
                "countryCode": "IN"
            },
            {
                "name": "Sirpur",
                "countryCode": "IN"
            },
            {
                "name": "Sirsilla",
                "countryCode": "IN"
            },
            {
                "name": "Sriramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Suriapet",
                "countryCode": "IN"
            },
            {
                "name": "Tandur",
                "countryCode": "IN"
            },
            {
                "name": "Uppal Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Vemalwada",
                "countryCode": "IN"
            },
            {
                "name": "Vikarabad",
                "countryCode": "IN"
            },
            {
                "name": "Wanparti",
                "countryCode": "IN"
            },
            {
                "name": "Warangal",
                "countryCode": "IN"
            },
            {
                "name": "Yellandu",
                "countryCode": "IN"
            },
            {
                "name": "Zahirabad",
                "countryCode": "IN"
            },
            {
                "name": "Abiramam",
                "countryCode": "IN"
            },
            {
                "name": "Adirampattinam",
                "countryCode": "IN"
            },
            {
                "name": "Aduthurai",
                "countryCode": "IN"
            },
            {
                "name": "Alagapuram",
                "countryCode": "IN"
            },
            {
                "name": "Alandur",
                "countryCode": "IN"
            },
            {
                "name": "Alanganallur",
                "countryCode": "IN"
            },
            {
                "name": "Alangayam",
                "countryCode": "IN"
            },
            {
                "name": "Alangudi",
                "countryCode": "IN"
            },
            {
                "name": "Alangulam",
                "countryCode": "IN"
            },
            {
                "name": "Alappakkam",
                "countryCode": "IN"
            },
            {
                "name": "Alwa Tirunagari",
                "countryCode": "IN"
            },
            {
                "name": "Ambasamudram",
                "countryCode": "IN"
            },
            {
                "name": "Ambattur",
                "countryCode": "IN"
            },
            {
                "name": "Ambur",
                "countryCode": "IN"
            },
            {
                "name": "Ammapettai",
                "countryCode": "IN"
            },
            {
                "name": "Anamalais",
                "countryCode": "IN"
            },
            {
                "name": "Andippatti",
                "countryCode": "IN"
            },
            {
                "name": "Annamalainagar",
                "countryCode": "IN"
            },
            {
                "name": "Annavasal",
                "countryCode": "IN"
            },
            {
                "name": "Annur",
                "countryCode": "IN"
            },
            {
                "name": "Anthiyur",
                "countryCode": "IN"
            },
            {
                "name": "Arakkonam",
                "countryCode": "IN"
            },
            {
                "name": "Arantangi",
                "countryCode": "IN"
            },
            {
                "name": "Arcot",
                "countryCode": "IN"
            },
            {
                "name": "Arimalam",
                "countryCode": "IN"
            },
            {
                "name": "Ariyalur",
                "countryCode": "IN"
            },
            {
                "name": "Arni",
                "countryCode": "IN"
            },
            {
                "name": "Arumbavur",
                "countryCode": "IN"
            },
            {
                "name": "Arumuganeri",
                "countryCode": "IN"
            },
            {
                "name": "Aruppukkottai",
                "countryCode": "IN"
            },
            {
                "name": "Aruvankad",
                "countryCode": "IN"
            },
            {
                "name": "Attayyampatti",
                "countryCode": "IN"
            },
            {
                "name": "Attur",
                "countryCode": "IN"
            },
            {
                "name": "Auroville",
                "countryCode": "IN"
            },
            {
                "name": "Avadi",
                "countryCode": "IN"
            },
            {
                "name": "Avinashi",
                "countryCode": "IN"
            },
            {
                "name": "Ayakudi",
                "countryCode": "IN"
            },
            {
                "name": "Ayyampettai",
                "countryCode": "IN"
            },
            {
                "name": "Belur",
                "countryCode": "IN"
            },
            {
                "name": "Bhavani",
                "countryCode": "IN"
            },
            {
                "name": "Bodinayakkanur",
                "countryCode": "IN"
            },
            {
                "name": "Chengam",
                "countryCode": "IN"
            },
            {
                "name": "Chennai",
                "countryCode": "IN"
            },
            {
                "name": "Chennimalai",
                "countryCode": "IN"
            },
            {
                "name": "Chetput",
                "countryCode": "IN"
            },
            {
                "name": "Chettipalaiyam",
                "countryCode": "IN"
            },
            {
                "name": "Cheyyar",
                "countryCode": "IN"
            },
            {
                "name": "Cheyyur",
                "countryCode": "IN"
            },
            {
                "name": "Chidambaram",
                "countryCode": "IN"
            },
            {
                "name": "Chingleput",
                "countryCode": "IN"
            },
            {
                "name": "Chinna Salem",
                "countryCode": "IN"
            },
            {
                "name": "Chinnamanur",
                "countryCode": "IN"
            },
            {
                "name": "Chinnasekkadu",
                "countryCode": "IN"
            },
            {
                "name": "Cholapuram",
                "countryCode": "IN"
            },
            {
                "name": "Coimbatore",
                "countryCode": "IN"
            },
            {
                "name": "Colachel",
                "countryCode": "IN"
            },
            {
                "name": "Cuddalore",
                "countryCode": "IN"
            },
            {
                "name": "Cumbum",
                "countryCode": "IN"
            },
            {
                "name": "Denkanikota",
                "countryCode": "IN"
            },
            {
                "name": "Desur",
                "countryCode": "IN"
            },
            {
                "name": "Devadanappatti",
                "countryCode": "IN"
            },
            {
                "name": "Devakottai",
                "countryCode": "IN"
            },
            {
                "name": "Dhali",
                "countryCode": "IN"
            },
            {
                "name": "Dharapuram",
                "countryCode": "IN"
            },
            {
                "name": "Dharmapuri",
                "countryCode": "IN"
            },
            {
                "name": "Dindigul",
                "countryCode": "IN"
            },
            {
                "name": "Dusi",
                "countryCode": "IN"
            },
            {
                "name": "Elayirampannai",
                "countryCode": "IN"
            },
            {
                "name": "Elumalai",
                "countryCode": "IN"
            },
            {
                "name": "Eral",
                "countryCode": "IN"
            },
            {
                "name": "Eraniel",
                "countryCode": "IN"
            },
            {
                "name": "Erode",
                "countryCode": "IN"
            },
            {
                "name": "Erumaippatti",
                "countryCode": "IN"
            },
            {
                "name": "Ettaiyapuram",
                "countryCode": "IN"
            },
            {
                "name": "Gandhi Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Gangaikondan",
                "countryCode": "IN"
            },
            {
                "name": "Gangavalli",
                "countryCode": "IN"
            },
            {
                "name": "Gingee",
                "countryCode": "IN"
            },
            {
                "name": "Gobichettipalayam",
                "countryCode": "IN"
            },
            {
                "name": "Gudalur",
                "countryCode": "IN"
            },
            {
                "name": "Gudiyatham",
                "countryCode": "IN"
            },
            {
                "name": "Guduvancheri",
                "countryCode": "IN"
            },
            {
                "name": "Gummidipundi",
                "countryCode": "IN"
            },
            {
                "name": "Harur",
                "countryCode": "IN"
            },
            {
                "name": "Hosur",
                "countryCode": "IN"
            },
            {
                "name": "Idappadi",
                "countryCode": "IN"
            },
            {
                "name": "Ilampillai",
                "countryCode": "IN"
            },
            {
                "name": "Iluppur",
                "countryCode": "IN"
            },
            {
                "name": "Injambakkam",
                "countryCode": "IN"
            },
            {
                "name": "Irugur",
                "countryCode": "IN"
            },
            {
                "name": "Jalakandapuram",
                "countryCode": "IN"
            },
            {
                "name": "Jalarpet",
                "countryCode": "IN"
            },
            {
                "name": "Jayamkondacholapuram",
                "countryCode": "IN"
            },
            {
                "name": "Kadambur",
                "countryCode": "IN"
            },
            {
                "name": "Kadayanallur",
                "countryCode": "IN"
            },
            {
                "name": "Kalakkadu",
                "countryCode": "IN"
            },
            {
                "name": "Kalavai",
                "countryCode": "IN"
            },
            {
                "name": "Kallakkurichchi",
                "countryCode": "IN"
            },
            {
                "name": "Kallidaikurichi",
                "countryCode": "IN"
            },
            {
                "name": "Kallupatti",
                "countryCode": "IN"
            },
            {
                "name": "Kalugumalai",
                "countryCode": "IN"
            },
            {
                "name": "Kamuthi",
                "countryCode": "IN"
            },
            {
                "name": "Kanadukattan",
                "countryCode": "IN"
            },
            {
                "name": "Kancheepuram",
                "countryCode": "IN"
            },
            {
                "name": "Kanchipuram",
                "countryCode": "IN"
            },
            {
                "name": "Kangayam",
                "countryCode": "IN"
            },
            {
                "name": "Kanniyakumari",
                "countryCode": "IN"
            },
            {
                "name": "Karaikkudi",
                "countryCode": "IN"
            },
            {
                "name": "Karamadai",
                "countryCode": "IN"
            },
            {
                "name": "Karambakkudi",
                "countryCode": "IN"
            },
            {
                "name": "Kariapatti",
                "countryCode": "IN"
            },
            {
                "name": "Karumbakkam",
                "countryCode": "IN"
            },
            {
                "name": "Karur",
                "countryCode": "IN"
            },
            {
                "name": "Katpadi",
                "countryCode": "IN"
            },
            {
                "name": "Kattivakkam",
                "countryCode": "IN"
            },
            {
                "name": "Kattupputtur",
                "countryCode": "IN"
            },
            {
                "name": "Kaveripatnam",
                "countryCode": "IN"
            },
            {
                "name": "Kayalpattinam",
                "countryCode": "IN"
            },
            {
                "name": "Kayattar",
                "countryCode": "IN"
            },
            {
                "name": "Keelakarai",
                "countryCode": "IN"
            },
            {
                "name": "Kelamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Kil Bhuvanagiri",
                "countryCode": "IN"
            },
            {
                "name": "Kilvelur",
                "countryCode": "IN"
            },
            {
                "name": "Kiranur",
                "countryCode": "IN"
            },
            {
                "name": "Kodaikanal",
                "countryCode": "IN"
            },
            {
                "name": "Kodumudi",
                "countryCode": "IN"
            },
            {
                "name": "Kombai",
                "countryCode": "IN"
            },
            {
                "name": "Konganapuram",
                "countryCode": "IN"
            },
            {
                "name": "Koothanallur",
                "countryCode": "IN"
            },
            {
                "name": "Koradachcheri",
                "countryCode": "IN"
            },
            {
                "name": "Korampallam",
                "countryCode": "IN"
            },
            {
                "name": "Kotagiri",
                "countryCode": "IN"
            },
            {
                "name": "Kottaiyur",
                "countryCode": "IN"
            },
            {
                "name": "Kovilpatti",
                "countryCode": "IN"
            },
            {
                "name": "Krishnagiri",
                "countryCode": "IN"
            },
            {
                "name": "Kulattur",
                "countryCode": "IN"
            },
            {
                "name": "Kulittalai",
                "countryCode": "IN"
            },
            {
                "name": "Kumaralingam",
                "countryCode": "IN"
            },
            {
                "name": "Kumbakonam",
                "countryCode": "IN"
            },
            {
                "name": "Kunnattur",
                "countryCode": "IN"
            },
            {
                "name": "Kurinjippadi",
                "countryCode": "IN"
            },
            {
                "name": "Kuttalam",
                "countryCode": "IN"
            },
            {
                "name": "Kuzhithurai",
                "countryCode": "IN"
            },
            {
                "name": "Lalgudi",
                "countryCode": "IN"
            },
            {
                "name": "Madambakkam",
                "countryCode": "IN"
            },
            {
                "name": "Madipakkam",
                "countryCode": "IN"
            },
            {
                "name": "Madukkarai",
                "countryCode": "IN"
            },
            {
                "name": "Madukkur",
                "countryCode": "IN"
            },
            {
                "name": "Madurai",
                "countryCode": "IN"
            },
            {
                "name": "Madurantakam",
                "countryCode": "IN"
            },
            {
                "name": "Mallapuram",
                "countryCode": "IN"
            },
            {
                "name": "Mallasamudram",
                "countryCode": "IN"
            },
            {
                "name": "Mallur",
                "countryCode": "IN"
            },
            {
                "name": "Manali",
                "countryCode": "IN"
            },
            {
                "name": "Manalurpettai",
                "countryCode": "IN"
            },
            {
                "name": "Manamadurai",
                "countryCode": "IN"
            },
            {
                "name": "Manappakkam",
                "countryCode": "IN"
            },
            {
                "name": "Manapparai",
                "countryCode": "IN"
            },
            {
                "name": "Manavalakurichi",
                "countryCode": "IN"
            },
            {
                "name": "Mandapam",
                "countryCode": "IN"
            },
            {
                "name": "Mangalam",
                "countryCode": "IN"
            },
            {
                "name": "Mannargudi",
                "countryCode": "IN"
            },
            {
                "name": "Marakkanam",
                "countryCode": "IN"
            },
            {
                "name": "Marandahalli",
                "countryCode": "IN"
            },
            {
                "name": "Masinigudi",
                "countryCode": "IN"
            },
            {
                "name": "Mattur",
                "countryCode": "IN"
            },
            {
                "name": "Mayiladuthurai",
                "countryCode": "IN"
            },
            {
                "name": "Melur",
                "countryCode": "IN"
            },
            {
                "name": "Mettuppalaiyam",
                "countryCode": "IN"
            },
            {
                "name": "Mettur",
                "countryCode": "IN"
            },
            {
                "name": "Minjur",
                "countryCode": "IN"
            },
            {
                "name": "Mohanur",
                "countryCode": "IN"
            },
            {
                "name": "Mudukulattur",
                "countryCode": "IN"
            },
            {
                "name": "Mulanur",
                "countryCode": "IN"
            },
            {
                "name": "Musiri",
                "countryCode": "IN"
            },
            {
                "name": "Muttupet",
                "countryCode": "IN"
            },
            {
                "name": "Naduvattam",
                "countryCode": "IN"
            },
            {
                "name": "Nagapattinam",
                "countryCode": "IN"
            },
            {
                "name": "Nagercoil",
                "countryCode": "IN"
            },
            {
                "name": "Namagiripettai",
                "countryCode": "IN"
            },
            {
                "name": "Namakkal",
                "countryCode": "IN"
            },
            {
                "name": "Nambiyur",
                "countryCode": "IN"
            },
            {
                "name": "Nambutalai",
                "countryCode": "IN"
            },
            {
                "name": "Nandambakkam",
                "countryCode": "IN"
            },
            {
                "name": "Nangavalli",
                "countryCode": "IN"
            },
            {
                "name": "Nangilickondan",
                "countryCode": "IN"
            },
            {
                "name": "Nanguneri",
                "countryCode": "IN"
            },
            {
                "name": "Nannilam",
                "countryCode": "IN"
            },
            {
                "name": "Naravarikuppam",
                "countryCode": "IN"
            },
            {
                "name": "Nattam",
                "countryCode": "IN"
            },
            {
                "name": "Nattarasankottai",
                "countryCode": "IN"
            },
            {
                "name": "Needamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Neelankarai",
                "countryCode": "IN"
            },
            {
                "name": "Negapatam",
                "countryCode": "IN"
            },
            {
                "name": "Nellikkuppam",
                "countryCode": "IN"
            },
            {
                "name": "Nilakottai",
                "countryCode": "IN"
            },
            {
                "name": "Nilgiris",
                "countryCode": "IN"
            },
            {
                "name": "Odugattur",
                "countryCode": "IN"
            },
            {
                "name": "Omalur",
                "countryCode": "IN"
            },
            {
                "name": "Ooty",
                "countryCode": "IN"
            },
            {
                "name": "Padmanabhapuram",
                "countryCode": "IN"
            },
            {
                "name": "Palakkodu",
                "countryCode": "IN"
            },
            {
                "name": "Palamedu",
                "countryCode": "IN"
            },
            {
                "name": "Palani",
                "countryCode": "IN"
            },
            {
                "name": "Palavakkam",
                "countryCode": "IN"
            },
            {
                "name": "Palladam",
                "countryCode": "IN"
            },
            {
                "name": "Pallappatti",
                "countryCode": "IN"
            },
            {
                "name": "Pallattur",
                "countryCode": "IN"
            },
            {
                "name": "Pallavaram",
                "countryCode": "IN"
            },
            {
                "name": "Pallikondai",
                "countryCode": "IN"
            },
            {
                "name": "Pallipattu",
                "countryCode": "IN"
            },
            {
                "name": "Pallippatti",
                "countryCode": "IN"
            },
            {
                "name": "Panruti",
                "countryCode": "IN"
            },
            {
                "name": "Papanasam",
                "countryCode": "IN"
            },
            {
                "name": "Papireddippatti",
                "countryCode": "IN"
            },
            {
                "name": "Papparappatti",
                "countryCode": "IN"
            },
            {
                "name": "Paramagudi",
                "countryCode": "IN"
            },
            {
                "name": "Pattukkottai",
                "countryCode": "IN"
            },
            {
                "name": "Pennadam",
                "countryCode": "IN"
            },
            {
                "name": "Pennagaram",
                "countryCode": "IN"
            },
            {
                "name": "Pennathur",
                "countryCode": "IN"
            },
            {
                "name": "Peraiyur",
                "countryCode": "IN"
            },
            {
                "name": "Perambalur",
                "countryCode": "IN"
            },
            {
                "name": "Peranamallur",
                "countryCode": "IN"
            },
            {
                "name": "Peranampattu",
                "countryCode": "IN"
            },
            {
                "name": "Peravurani",
                "countryCode": "IN"
            },
            {
                "name": "Periyakulam",
                "countryCode": "IN"
            },
            {
                "name": "Periyanayakkanpalaiyam",
                "countryCode": "IN"
            },
            {
                "name": "Periyanegamam",
                "countryCode": "IN"
            },
            {
                "name": "Periyapatti",
                "countryCode": "IN"
            },
            {
                "name": "Periyapattinam",
                "countryCode": "IN"
            },
            {
                "name": "Perundurai",
                "countryCode": "IN"
            },
            {
                "name": "Perungudi",
                "countryCode": "IN"
            },
            {
                "name": "Perur",
                "countryCode": "IN"
            },
            {
                "name": "Pollachi",
                "countryCode": "IN"
            },
            {
                "name": "Polur",
                "countryCode": "IN"
            },
            {
                "name": "Ponnamaravati",
                "countryCode": "IN"
            },
            {
                "name": "Ponneri",
                "countryCode": "IN"
            },
            {
                "name": "Poonamalle",
                "countryCode": "IN"
            },
            {
                "name": "Porur",
                "countryCode": "IN"
            },
            {
                "name": "Pudukkottai",
                "countryCode": "IN"
            },
            {
                "name": "Puduppatti",
                "countryCode": "IN"
            },
            {
                "name": "Pudur",
                "countryCode": "IN"
            },
            {
                "name": "Puduvayal",
                "countryCode": "IN"
            },
            {
                "name": "Puliyangudi",
                "countryCode": "IN"
            },
            {
                "name": "Puliyur",
                "countryCode": "IN"
            },
            {
                "name": "Pullambadi",
                "countryCode": "IN"
            },
            {
                "name": "Punjai Puliyampatti",
                "countryCode": "IN"
            },
            {
                "name": "Rajapalaiyam",
                "countryCode": "IN"
            },
            {
                "name": "Ramanathapuram",
                "countryCode": "IN"
            },
            {
                "name": "Rameswaram",
                "countryCode": "IN"
            },
            {
                "name": "Ranipet",
                "countryCode": "IN"
            },
            {
                "name": "Rasipuram",
                "countryCode": "IN"
            },
            {
                "name": "Saint Thomas Mount",
                "countryCode": "IN"
            },
            {
                "name": "Salem",
                "countryCode": "IN"
            },
            {
                "name": "Sathankulam",
                "countryCode": "IN"
            },
            {
                "name": "Sathyamangalam",
                "countryCode": "IN"
            },
            {
                "name": "Sattur",
                "countryCode": "IN"
            },
            {
                "name": "Sayalkudi",
                "countryCode": "IN"
            },
            {
                "name": "Seven Pagodas",
                "countryCode": "IN"
            },
            {
                "name": "Sholinghur",
                "countryCode": "IN"
            },
            {
                "name": "Singanallur",
                "countryCode": "IN"
            },
            {
                "name": "Singapperumalkovil",
                "countryCode": "IN"
            },
            {
                "name": "Sirkazhi",
                "countryCode": "IN"
            },
            {
                "name": "Sirumugai",
                "countryCode": "IN"
            },
            {
                "name": "Sivaganga",
                "countryCode": "IN"
            },
            {
                "name": "Sivagiri",
                "countryCode": "IN"
            },
            {
                "name": "Sivakasi",
                "countryCode": "IN"
            },
            {
                "name": "Srimushnam",
                "countryCode": "IN"
            },
            {
                "name": "Sriperumbudur",
                "countryCode": "IN"
            },
            {
                "name": "Srivaikuntam",
                "countryCode": "IN"
            },
            {
                "name": "Srivilliputhur",
                "countryCode": "IN"
            },
            {
                "name": "Suchindram",
                "countryCode": "IN"
            },
            {
                "name": "Sulur",
                "countryCode": "IN"
            },
            {
                "name": "Surandai",
                "countryCode": "IN"
            },
            {
                "name": "Swamimalai",
                "countryCode": "IN"
            },
            {
                "name": "Tambaram",
                "countryCode": "IN"
            },
            {
                "name": "Tanjore",
                "countryCode": "IN"
            },
            {
                "name": "Taramangalam",
                "countryCode": "IN"
            },
            {
                "name": "Tattayyangarpettai",
                "countryCode": "IN"
            },
            {
                "name": "Thanjavur",
                "countryCode": "IN"
            },
            {
                "name": "Tharangambadi",
                "countryCode": "IN"
            },
            {
                "name": "Theni",
                "countryCode": "IN"
            },
            {
                "name": "Thenkasi",
                "countryCode": "IN"
            },
            {
                "name": "Thirukattupalli",
                "countryCode": "IN"
            },
            {
                "name": "Thiruthani",
                "countryCode": "IN"
            },
            {
                "name": "Thiruvaiyaru",
                "countryCode": "IN"
            },
            {
                "name": "Thiruvallur",
                "countryCode": "IN"
            },
            {
                "name": "Thiruvarur",
                "countryCode": "IN"
            },
            {
                "name": "Thiruvidaimaruthur",
                "countryCode": "IN"
            },
            {
                "name": "Thoothukudi",
                "countryCode": "IN"
            },
            {
                "name": "Tindivanam",
                "countryCode": "IN"
            },
            {
                "name": "Tinnanur",
                "countryCode": "IN"
            },
            {
                "name": "Tiruchchendur",
                "countryCode": "IN"
            },
            {
                "name": "Tiruchengode",
                "countryCode": "IN"
            },
            {
                "name": "Tiruchirappalli",
                "countryCode": "IN"
            },
            {
                "name": "Tirukkoyilur",
                "countryCode": "IN"
            },
            {
                "name": "Tirumullaivasal",
                "countryCode": "IN"
            },
            {
                "name": "Tirunelveli",
                "countryCode": "IN"
            },
            {
                "name": "Tirunelveli Kattabo",
                "countryCode": "IN"
            },
            {
                "name": "Tiruppalaikudi",
                "countryCode": "IN"
            },
            {
                "name": "Tirupparangunram",
                "countryCode": "IN"
            },
            {
                "name": "Tiruppur",
                "countryCode": "IN"
            },
            {
                "name": "Tiruppuvanam",
                "countryCode": "IN"
            },
            {
                "name": "Tiruttangal",
                "countryCode": "IN"
            },
            {
                "name": "Tiruvannamalai",
                "countryCode": "IN"
            },
            {
                "name": "Tiruvottiyur",
                "countryCode": "IN"
            },
            {
                "name": "Tisaiyanvilai",
                "countryCode": "IN"
            },
            {
                "name": "Tondi",
                "countryCode": "IN"
            },
            {
                "name": "Turaiyur",
                "countryCode": "IN"
            },
            {
                "name": "Udangudi",
                "countryCode": "IN"
            },
            {
                "name": "Udumalaippettai",
                "countryCode": "IN"
            },
            {
                "name": "Uppiliyapuram",
                "countryCode": "IN"
            },
            {
                "name": "Usilampatti",
                "countryCode": "IN"
            },
            {
                "name": "Uttamapalaiyam",
                "countryCode": "IN"
            },
            {
                "name": "Uttiramerur",
                "countryCode": "IN"
            },
            {
                "name": "Uttukkuli",
                "countryCode": "IN"
            },
            {
                "name": "V.S.K.Valasai (Dindigul-Dist.)",
                "countryCode": "IN"
            },
            {
                "name": "Vadakku Valliyur",
                "countryCode": "IN"
            },
            {
                "name": "Vadakku Viravanallur",
                "countryCode": "IN"
            },
            {
                "name": "Vadamadurai",
                "countryCode": "IN"
            },
            {
                "name": "Vadippatti",
                "countryCode": "IN"
            },
            {
                "name": "Valangaiman",
                "countryCode": "IN"
            },
            {
                "name": "Valavanur",
                "countryCode": "IN"
            },
            {
                "name": "Vallam",
                "countryCode": "IN"
            },
            {
                "name": "Valparai",
                "countryCode": "IN"
            },
            {
                "name": "Vandalur",
                "countryCode": "IN"
            },
            {
                "name": "Vandavasi",
                "countryCode": "IN"
            },
            {
                "name": "Vaniyambadi",
                "countryCode": "IN"
            },
            {
                "name": "Vasudevanallur",
                "countryCode": "IN"
            },
            {
                "name": "Vattalkundu",
                "countryCode": "IN"
            },
            {
                "name": "Vedaraniyam",
                "countryCode": "IN"
            },
            {
                "name": "Vedasandur",
                "countryCode": "IN"
            },
            {
                "name": "Velankanni",
                "countryCode": "IN"
            },
            {
                "name": "Vellanur",
                "countryCode": "IN"
            },
            {
                "name": "Vellore",
                "countryCode": "IN"
            },
            {
                "name": "Velur",
                "countryCode": "IN"
            },
            {
                "name": "Vengavasal",
                "countryCode": "IN"
            },
            {
                "name": "Vettaikkaranpudur",
                "countryCode": "IN"
            },
            {
                "name": "Vettavalam",
                "countryCode": "IN"
            },
            {
                "name": "Vijayapuri",
                "countryCode": "IN"
            },
            {
                "name": "Vikravandi",
                "countryCode": "IN"
            },
            {
                "name": "Vilattikulam",
                "countryCode": "IN"
            },
            {
                "name": "Villupuram",
                "countryCode": "IN"
            },
            {
                "name": "Viraganur",
                "countryCode": "IN"
            },
            {
                "name": "Virudhunagar",
                "countryCode": "IN"
            },
            {
                "name": "Vriddhachalam",
                "countryCode": "IN"
            },
            {
                "name": "Walajapet",
                "countryCode": "IN"
            },
            {
                "name": "Wallajahbad",
                "countryCode": "IN"
            },
            {
                "name": "Wellington",
                "countryCode": "IN"
            },
            {
                "name": "Agartala",
                "countryCode": "IN"
            },
            {
                "name": "Amarpur",
                "countryCode": "IN"
            },
            {
                "name": "Ambasa",
                "countryCode": "IN"
            },
            {
                "name": "Barjala",
                "countryCode": "IN"
            },
            {
                "name": "Belonia",
                "countryCode": "IN"
            },
            {
                "name": "Dhalai",
                "countryCode": "IN"
            },
            {
                "name": "Dharmanagar",
                "countryCode": "IN"
            },
            {
                "name": "Gomati",
                "countryCode": "IN"
            },
            {
                "name": "Kailashahar",
                "countryCode": "IN"
            },
            {
                "name": "Kamalpur",
                "countryCode": "IN"
            },
            {
                "name": "Khowai",
                "countryCode": "IN"
            },
            {
                "name": "North Tripura",
                "countryCode": "IN"
            },
            {
                "name": "Ranir Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Sabrum",
                "countryCode": "IN"
            },
            {
                "name": "Sonamura",
                "countryCode": "IN"
            },
            {
                "name": "South Tripura",
                "countryCode": "IN"
            },
            {
                "name": "Udaipur",
                "countryCode": "IN"
            },
            {
                "name": "Unakoti",
                "countryCode": "IN"
            },
            {
                "name": "West Tripura",
                "countryCode": "IN"
            },
            {
                "name": "Achhnera",
                "countryCode": "IN"
            },
            {
                "name": "Afzalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Agra",
                "countryCode": "IN"
            },
            {
                "name": "Ahraura",
                "countryCode": "IN"
            },
            {
                "name": "Aidalpur",
                "countryCode": "IN"
            },
            {
                "name": "Airwa",
                "countryCode": "IN"
            },
            {
                "name": "Akbarpur",
                "countryCode": "IN"
            },
            {
                "name": "Akola",
                "countryCode": "IN"
            },
            {
                "name": "Aliganj",
                "countryCode": "IN"
            },
            {
                "name": "Aligarh",
                "countryCode": "IN"
            },
            {
                "name": "Allahabad",
                "countryCode": "IN"
            },
            {
                "name": "Allahganj",
                "countryCode": "IN"
            },
            {
                "name": "Amanpur",
                "countryCode": "IN"
            },
            {
                "name": "Amauli",
                "countryCode": "IN"
            },
            {
                "name": "Ambahta",
                "countryCode": "IN"
            },
            {
                "name": "Ambedkar Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Amethi",
                "countryCode": "IN"
            },
            {
                "name": "Amroha",
                "countryCode": "IN"
            },
            {
                "name": "Anandnagar",
                "countryCode": "IN"
            },
            {
                "name": "Antu",
                "countryCode": "IN"
            },
            {
                "name": "Anupshahr",
                "countryCode": "IN"
            },
            {
                "name": "Aonla",
                "countryCode": "IN"
            },
            {
                "name": "Araul",
                "countryCode": "IN"
            },
            {
                "name": "Asalatganj",
                "countryCode": "IN"
            },
            {
                "name": "Atarra",
                "countryCode": "IN"
            },
            {
                "name": "Atrauli",
                "countryCode": "IN"
            },
            {
                "name": "Atraulia",
                "countryCode": "IN"
            },
            {
                "name": "Auraiya",
                "countryCode": "IN"
            },
            {
                "name": "Auras",
                "countryCode": "IN"
            },
            {
                "name": "Ayodhya",
                "countryCode": "IN"
            },
            {
                "name": "Azamgarh",
                "countryCode": "IN"
            },
            {
                "name": "Azizpur",
                "countryCode": "IN"
            },
            {
                "name": "Baberu",
                "countryCode": "IN"
            },
            {
                "name": "Babina",
                "countryCode": "IN"
            },
            {
                "name": "Babrala",
                "countryCode": "IN"
            },
            {
                "name": "Babugarh",
                "countryCode": "IN"
            },
            {
                "name": "Bachhraon",
                "countryCode": "IN"
            },
            {
                "name": "Bachhrawan",
                "countryCode": "IN"
            },
            {
                "name": "Baghpat",
                "countryCode": "IN"
            },
            {
                "name": "Baghra",
                "countryCode": "IN"
            },
            {
                "name": "Bah",
                "countryCode": "IN"
            },
            {
                "name": "Baheri",
                "countryCode": "IN"
            },
            {
                "name": "Bahjoi",
                "countryCode": "IN"
            },
            {
                "name": "Bahraich",
                "countryCode": "IN"
            },
            {
                "name": "Bahraigh",
                "countryCode": "IN"
            },
            {
                "name": "Bahsuma",
                "countryCode": "IN"
            },
            {
                "name": "Bahua",
                "countryCode": "IN"
            },
            {
                "name": "Bajna",
                "countryCode": "IN"
            },
            {
                "name": "Bakewar",
                "countryCode": "IN"
            },
            {
                "name": "Baksar",
                "countryCode": "IN"
            },
            {
                "name": "Balamau",
                "countryCode": "IN"
            },
            {
                "name": "Baldeo",
                "countryCode": "IN"
            },
            {
                "name": "Baldev",
                "countryCode": "IN"
            },
            {
                "name": "Ballia",
                "countryCode": "IN"
            },
            {
                "name": "Balrampur",
                "countryCode": "IN"
            },
            {
                "name": "Banat",
                "countryCode": "IN"
            },
            {
                "name": "Banbasa",
                "countryCode": "IN"
            },
            {
                "name": "Banda",
                "countryCode": "IN"
            },
            {
                "name": "Bangarmau",
                "countryCode": "IN"
            },
            {
                "name": "Bansdih",
                "countryCode": "IN"
            },
            {
                "name": "Bansgaon",
                "countryCode": "IN"
            },
            {
                "name": "Bansi",
                "countryCode": "IN"
            },
            {
                "name": "Banthra",
                "countryCode": "IN"
            },
            {
                "name": "Bara Banki",
                "countryCode": "IN"
            },
            {
                "name": "Baragaon",
                "countryCode": "IN"
            },
            {
                "name": "Baraut",
                "countryCode": "IN"
            },
            {
                "name": "Bareilly",
                "countryCode": "IN"
            },
            {
                "name": "Barhalganj",
                "countryCode": "IN"
            },
            {
                "name": "Barkhera",
                "countryCode": "IN"
            },
            {
                "name": "Barkhera Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Barokhar",
                "countryCode": "IN"
            },
            {
                "name": "Barsana",
                "countryCode": "IN"
            },
            {
                "name": "Barwar (Lakhimpur Kheri)",
                "countryCode": "IN"
            },
            {
                "name": "Basti",
                "countryCode": "IN"
            },
            {
                "name": "Behat",
                "countryCode": "IN"
            },
            {
                "name": "Bela",
                "countryCode": "IN"
            },
            {
                "name": "Belthara",
                "countryCode": "IN"
            },
            {
                "name": "Beniganj",
                "countryCode": "IN"
            },
            {
                "name": "Beswan",
                "countryCode": "IN"
            },
            {
                "name": "Bewar",
                "countryCode": "IN"
            },
            {
                "name": "Bhadarsa",
                "countryCode": "IN"
            },
            {
                "name": "Bhadohi",
                "countryCode": "IN"
            },
            {
                "name": "Bhagwantnagar",
                "countryCode": "IN"
            },
            {
                "name": "Bharatpura",
                "countryCode": "IN"
            },
            {
                "name": "Bhargain",
                "countryCode": "IN"
            },
            {
                "name": "Bharthana",
                "countryCode": "IN"
            },
            {
                "name": "Bharwari",
                "countryCode": "IN"
            },
            {
                "name": "Bhaupur",
                "countryCode": "IN"
            },
            {
                "name": "Bhimtal",
                "countryCode": "IN"
            },
            {
                "name": "Bhinga",
                "countryCode": "IN"
            },
            {
                "name": "Bhognipur",
                "countryCode": "IN"
            },
            {
                "name": "Bhongaon",
                "countryCode": "IN"
            },
            {
                "name": "Bidhnu",
                "countryCode": "IN"
            },
            {
                "name": "Bidhuna",
                "countryCode": "IN"
            },
            {
                "name": "Bighapur",
                "countryCode": "IN"
            },
            {
                "name": "Bighapur Khurd",
                "countryCode": "IN"
            },
            {
                "name": "Bijnor",
                "countryCode": "IN"
            },
            {
                "name": "Bikapur",
                "countryCode": "IN"
            },
            {
                "name": "Bilari",
                "countryCode": "IN"
            },
            {
                "name": "Bilariaganj",
                "countryCode": "IN"
            },
            {
                "name": "Bilaspur",
                "countryCode": "IN"
            },
            {
                "name": "Bilgram",
                "countryCode": "IN"
            },
            {
                "name": "Bilhaur",
                "countryCode": "IN"
            },
            {
                "name": "Bilsanda",
                "countryCode": "IN"
            },
            {
                "name": "Bilsi",
                "countryCode": "IN"
            },
            {
                "name": "Bilthra",
                "countryCode": "IN"
            },
            {
                "name": "Binauli",
                "countryCode": "IN"
            },
            {
                "name": "Binaur",
                "countryCode": "IN"
            },
            {
                "name": "Bindki",
                "countryCode": "IN"
            },
            {
                "name": "Birdpur",
                "countryCode": "IN"
            },
            {
                "name": "Birpur",
                "countryCode": "IN"
            },
            {
                "name": "Bisalpur",
                "countryCode": "IN"
            },
            {
                "name": "Bisanda Buzurg",
                "countryCode": "IN"
            },
            {
                "name": "Bisauli",
                "countryCode": "IN"
            },
            {
                "name": "Bisenda Buzurg",
                "countryCode": "IN"
            },
            {
                "name": "Bishunpur Urf Maharajganj",
                "countryCode": "IN"
            },
            {
                "name": "Biswan",
                "countryCode": "IN"
            },
            {
                "name": "Bithur",
                "countryCode": "IN"
            },
            {
                "name": "Budaun",
                "countryCode": "IN"
            },
            {
                "name": "Budhana",
                "countryCode": "IN"
            },
            {
                "name": "Bulandshahr",
                "countryCode": "IN"
            },
            {
                "name": "Captainganj",
                "countryCode": "IN"
            },
            {
                "name": "Chail",
                "countryCode": "IN"
            },
            {
                "name": "Chakia",
                "countryCode": "IN"
            },
            {
                "name": "Chandauli",
                "countryCode": "IN"
            },
            {
                "name": "Chandauli District",
                "countryCode": "IN"
            },
            {
                "name": "Chandausi",
                "countryCode": "IN"
            },
            {
                "name": "Chandpur",
                "countryCode": "IN"
            },
            {
                "name": "Chanduasi",
                "countryCode": "IN"
            },
            {
                "name": "Charkhari",
                "countryCode": "IN"
            },
            {
                "name": "Charthawal",
                "countryCode": "IN"
            },
            {
                "name": "Chhaprauli",
                "countryCode": "IN"
            },
            {
                "name": "Chharra",
                "countryCode": "IN"
            },
            {
                "name": "Chhata",
                "countryCode": "IN"
            },
            {
                "name": "Chhibramau",
                "countryCode": "IN"
            },
            {
                "name": "Chhitauni",
                "countryCode": "IN"
            },
            {
                "name": "Chhutmalpur",
                "countryCode": "IN"
            },
            {
                "name": "Chillupar",
                "countryCode": "IN"
            },
            {
                "name": "Chirgaon",
                "countryCode": "IN"
            },
            {
                "name": "Chitrakoot",
                "countryCode": "IN"
            },
            {
                "name": "Chitrakoot Dham",
                "countryCode": "IN"
            },
            {
                "name": "Chopan",
                "countryCode": "IN"
            },
            {
                "name": "Chunar",
                "countryCode": "IN"
            },
            {
                "name": "Churk",
                "countryCode": "IN"
            },
            {
                "name": "Colonelganj",
                "countryCode": "IN"
            },
            {
                "name": "Dadri",
                "countryCode": "IN"
            },
            {
                "name": "Dalmau",
                "countryCode": "IN"
            },
            {
                "name": "Dankaur",
                "countryCode": "IN"
            },
            {
                "name": "Daraganj",
                "countryCode": "IN"
            },
            {
                "name": "Daranagar",
                "countryCode": "IN"
            },
            {
                "name": "Dasna",
                "countryCode": "IN"
            },
            {
                "name": "Dataganj",
                "countryCode": "IN"
            },
            {
                "name": "Daurala",
                "countryCode": "IN"
            },
            {
                "name": "Dayal Bagh",
                "countryCode": "IN"
            },
            {
                "name": "Deoband",
                "countryCode": "IN"
            },
            {
                "name": "Deogarh",
                "countryCode": "IN"
            },
            {
                "name": "Deoranian",
                "countryCode": "IN"
            },
            {
                "name": "Deoria",
                "countryCode": "IN"
            },
            {
                "name": "Derapur",
                "countryCode": "IN"
            },
            {
                "name": "Dewa",
                "countryCode": "IN"
            },
            {
                "name": "Dhampur",
                "countryCode": "IN"
            },
            {
                "name": "Dhanaura",
                "countryCode": "IN"
            },
            {
                "name": "Dhanghata",
                "countryCode": "IN"
            },
            {
                "name": "Dharau",
                "countryCode": "IN"
            },
            {
                "name": "Dhaurahra",
                "countryCode": "IN"
            },
            {
                "name": "Dibai",
                "countryCode": "IN"
            },
            {
                "name": "Divrasai",
                "countryCode": "IN"
            },
            {
                "name": "Dohrighat",
                "countryCode": "IN"
            },
            {
                "name": "Domariaganj",
                "countryCode": "IN"
            },
            {
                "name": "Dostpur",
                "countryCode": "IN"
            },
            {
                "name": "Dudhi",
                "countryCode": "IN"
            },
            {
                "name": "Etah",
                "countryCode": "IN"
            },
            {
                "name": "Etawah",
                "countryCode": "IN"
            },
            {
                "name": "Etmadpur",
                "countryCode": "IN"
            },
            {
                "name": "Faizabad",
                "countryCode": "IN"
            },
            {
                "name": "Farah",
                "countryCode": "IN"
            },
            {
                "name": "Faridnagar",
                "countryCode": "IN"
            },
            {
                "name": "Faridpur",
                "countryCode": "IN"
            },
            {
                "name": "Farrukhabad",
                "countryCode": "IN"
            },
            {
                "name": "Fatehabad",
                "countryCode": "IN"
            },
            {
                "name": "Fatehganj West",
                "countryCode": "IN"
            },
            {
                "name": "Fatehgarh",
                "countryCode": "IN"
            },
            {
                "name": "Fatehpur",
                "countryCode": "IN"
            },
            {
                "name": "Fatehpur (Barabanki)",
                "countryCode": "IN"
            },
            {
                "name": "Fatehpur Chaurasi",
                "countryCode": "IN"
            },
            {
                "name": "Fatehpur Sikri",
                "countryCode": "IN"
            },
            {
                "name": "Firozabad",
                "countryCode": "IN"
            },
            {
                "name": "Fyzabad",
                "countryCode": "IN"
            },
            {
                "name": "Gahlon",
                "countryCode": "IN"
            },
            {
                "name": "Gahmar",
                "countryCode": "IN"
            },
            {
                "name": "Gaini",
                "countryCode": "IN"
            },
            {
                "name": "Gajraula",
                "countryCode": "IN"
            },
            {
                "name": "Gangoh",
                "countryCode": "IN"
            },
            {
                "name": "Ganj Dundawara",
                "countryCode": "IN"
            },
            {
                "name": "Ganj Dundwara",
                "countryCode": "IN"
            },
            {
                "name": "Ganj Muradabad",
                "countryCode": "IN"
            },
            {
                "name": "Garautha",
                "countryCode": "IN"
            },
            {
                "name": "Garhi Pukhta",
                "countryCode": "IN"
            },
            {
                "name": "Garhmuktesar",
                "countryCode": "IN"
            },
            {
                "name": "Garhwa",
                "countryCode": "IN"
            },
            {
                "name": "Gauriganj",
                "countryCode": "IN"
            },
            {
                "name": "Gautam Buddha Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Gawan",
                "countryCode": "IN"
            },
            {
                "name": "Ghatampur",
                "countryCode": "IN"
            },
            {
                "name": "Ghaziabad",
                "countryCode": "IN"
            },
            {
                "name": "Ghazipur",
                "countryCode": "IN"
            },
            {
                "name": "Ghiror",
                "countryCode": "IN"
            },
            {
                "name": "Ghorawal",
                "countryCode": "IN"
            },
            {
                "name": "Ghosi",
                "countryCode": "IN"
            },
            {
                "name": "Gohand",
                "countryCode": "IN"
            },
            {
                "name": "Gokul",
                "countryCode": "IN"
            },
            {
                "name": "Gola Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Gola Gokarannath",
                "countryCode": "IN"
            },
            {
                "name": "Gonda",
                "countryCode": "IN"
            },
            {
                "name": "Gonda (Aligarh)",
                "countryCode": "IN"
            },
            {
                "name": "Gonda City",
                "countryCode": "IN"
            },
            {
                "name": "Gopamau",
                "countryCode": "IN"
            },
            {
                "name": "Gorakhpur",
                "countryCode": "IN"
            },
            {
                "name": "Gosainganj",
                "countryCode": "IN"
            },
            {
                "name": "Goshainganj",
                "countryCode": "IN"
            },
            {
                "name": "Govardhan",
                "countryCode": "IN"
            },
            {
                "name": "Greater Noida",
                "countryCode": "IN"
            },
            {
                "name": "Gulaothi",
                "countryCode": "IN"
            },
            {
                "name": "Gunnaur",
                "countryCode": "IN"
            },
            {
                "name": "Gursahaiganj",
                "countryCode": "IN"
            },
            {
                "name": "Gursarai",
                "countryCode": "IN"
            },
            {
                "name": "Gyanpur",
                "countryCode": "IN"
            },
            {
                "name": "Haldaur",
                "countryCode": "IN"
            },
            {
                "name": "Hamirpur",
                "countryCode": "IN"
            },
            {
                "name": "Handia",
                "countryCode": "IN"
            },
            {
                "name": "Hapur",
                "countryCode": "IN"
            },
            {
                "name": "Haraipur",
                "countryCode": "IN"
            },
            {
                "name": "Haraiya",
                "countryCode": "IN"
            },
            {
                "name": "Harchandpur",
                "countryCode": "IN"
            },
            {
                "name": "Hardoi",
                "countryCode": "IN"
            },
            {
                "name": "Harduaganj",
                "countryCode": "IN"
            },
            {
                "name": "Hasanpur",
                "countryCode": "IN"
            },
            {
                "name": "Hastinapur",
                "countryCode": "IN"
            },
            {
                "name": "Hata",
                "countryCode": "IN"
            },
            {
                "name": "Hata (India)",
                "countryCode": "IN"
            },
            {
                "name": "Hathras",
                "countryCode": "IN"
            },
            {
                "name": "Hulas",
                "countryCode": "IN"
            },
            {
                "name": "Ibrahimpur",
                "countryCode": "IN"
            },
            {
                "name": "Iglas",
                "countryCode": "IN"
            },
            {
                "name": "Ikauna",
                "countryCode": "IN"
            },
            {
                "name": "Indergarh",
                "countryCode": "IN"
            },
            {
                "name": "Indragarh",
                "countryCode": "IN"
            },
            {
                "name": "Islamnagar",
                "countryCode": "IN"
            },
            {
                "name": "Islamnagar (Badaun)",
                "countryCode": "IN"
            },
            {
                "name": "Itaunja",
                "countryCode": "IN"
            },
            {
                "name": "Itimadpur",
                "countryCode": "IN"
            },
            {
                "name": "Jagdishpur",
                "countryCode": "IN"
            },
            {
                "name": "Jagnair",
                "countryCode": "IN"
            },
            {
                "name": "Jahanabad",
                "countryCode": "IN"
            },
            {
                "name": "Jahanabad (Pilibhit)",
                "countryCode": "IN"
            },
            {
                "name": "Jahangirabad",
                "countryCode": "IN"
            },
            {
                "name": "Jahangirpur",
                "countryCode": "IN"
            },
            {
                "name": "Jainpur",
                "countryCode": "IN"
            },
            {
                "name": "Jais",
                "countryCode": "IN"
            },
            {
                "name": "Jalalabad",
                "countryCode": "IN"
            },
            {
                "name": "Jalali",
                "countryCode": "IN"
            },
            {
                "name": "Jalalpur",
                "countryCode": "IN"
            },
            {
                "name": "Jalaun",
                "countryCode": "IN"
            },
            {
                "name": "Jalesar",
                "countryCode": "IN"
            },
            {
                "name": "Janghai",
                "countryCode": "IN"
            },
            {
                "name": "Jansath",
                "countryCode": "IN"
            },
            {
                "name": "Jarwa",
                "countryCode": "IN"
            },
            {
                "name": "Jarwal",
                "countryCode": "IN"
            },
            {
                "name": "Jasrana",
                "countryCode": "IN"
            },
            {
                "name": "Jaswantnagar",
                "countryCode": "IN"
            },
            {
                "name": "Jaunpur",
                "countryCode": "IN"
            },
            {
                "name": "Jewar",
                "countryCode": "IN"
            },
            {
                "name": "Jhajhar",
                "countryCode": "IN"
            },
            {
                "name": "Jhalu",
                "countryCode": "IN"
            },
            {
                "name": "Jhansi",
                "countryCode": "IN"
            },
            {
                "name": "Jhinjhak",
                "countryCode": "IN"
            },
            {
                "name": "Jhinjhana",
                "countryCode": "IN"
            },
            {
                "name": "Jhusi",
                "countryCode": "IN"
            },
            {
                "name": "Jiyanpur",
                "countryCode": "IN"
            },
            {
                "name": "Jyotiba Phule Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Kabrai",
                "countryCode": "IN"
            },
            {
                "name": "Kachhwa",
                "countryCode": "IN"
            },
            {
                "name": "Kadaura",
                "countryCode": "IN"
            },
            {
                "name": "Kadipur",
                "countryCode": "IN"
            },
            {
                "name": "Kagarol",
                "countryCode": "IN"
            },
            {
                "name": "Kaimganj",
                "countryCode": "IN"
            },
            {
                "name": "Kairana",
                "countryCode": "IN"
            },
            {
                "name": "Kakori",
                "countryCode": "IN"
            },
            {
                "name": "Kakrala",
                "countryCode": "IN"
            },
            {
                "name": "Kalinagar",
                "countryCode": "IN"
            },
            {
                "name": "Kalpi",
                "countryCode": "IN"
            },
            {
                "name": "Kalyanpur",
                "countryCode": "IN"
            },
            {
                "name": "Kamalganj",
                "countryCode": "IN"
            },
            {
                "name": "Kampil",
                "countryCode": "IN"
            },
            {
                "name": "Kandhla",
                "countryCode": "IN"
            },
            {
                "name": "Kannauj",
                "countryCode": "IN"
            },
            {
                "name": "Kanpur",
                "countryCode": "IN"
            },
            {
                "name": "Kanpur Dehat",
                "countryCode": "IN"
            },
            {
                "name": "Kant",
                "countryCode": "IN"
            },
            {
                "name": "Kanth",
                "countryCode": "IN"
            },
            {
                "name": "Kaptanganj",
                "countryCode": "IN"
            },
            {
                "name": "Kara",
                "countryCode": "IN"
            },
            {
                "name": "Karari",
                "countryCode": "IN"
            },
            {
                "name": "Karbigwan",
                "countryCode": "IN"
            },
            {
                "name": "Karchana",
                "countryCode": "IN"
            },
            {
                "name": "Karhal",
                "countryCode": "IN"
            },
            {
                "name": "Kasganj",
                "countryCode": "IN"
            },
            {
                "name": "Katra",
                "countryCode": "IN"
            },
            {
                "name": "Kausani",
                "countryCode": "IN"
            },
            {
                "name": "Kaushambi District",
                "countryCode": "IN"
            },
            {
                "name": "Kemri",
                "countryCode": "IN"
            },
            {
                "name": "Khada",
                "countryCode": "IN"
            },
            {
                "name": "Khaga",
                "countryCode": "IN"
            },
            {
                "name": "Khailar",
                "countryCode": "IN"
            },
            {
                "name": "Khair",
                "countryCode": "IN"
            },
            {
                "name": "Khairabad",
                "countryCode": "IN"
            },
            {
                "name": "Khalilabad",
                "countryCode": "IN"
            },
            {
                "name": "Khanpur",
                "countryCode": "IN"
            },
            {
                "name": "Kharela",
                "countryCode": "IN"
            },
            {
                "name": "Khargupur",
                "countryCode": "IN"
            },
            {
                "name": "Kharkhauda",
                "countryCode": "IN"
            },
            {
                "name": "Khatauli",
                "countryCode": "IN"
            },
            {
                "name": "Khekra",
                "countryCode": "IN"
            },
            {
                "name": "Kheri",
                "countryCode": "IN"
            },
            {
                "name": "Khudaganj",
                "countryCode": "IN"
            },
            {
                "name": "Khurja",
                "countryCode": "IN"
            },
            {
                "name": "Khutar",
                "countryCode": "IN"
            },
            {
                "name": "Kirakat",
                "countryCode": "IN"
            },
            {
                "name": "Kiraoli",
                "countryCode": "IN"
            },
            {
                "name": "Kiratpur",
                "countryCode": "IN"
            },
            {
                "name": "Kishanpur",
                "countryCode": "IN"
            },
            {
                "name": "Kishanpur baral",
                "countryCode": "IN"
            },
            {
                "name": "Kishni",
                "countryCode": "IN"
            },
            {
                "name": "Kithor",
                "countryCode": "IN"
            },
            {
                "name": "Konch",
                "countryCode": "IN"
            },
            {
                "name": "Kopaganj",
                "countryCode": "IN"
            },
            {
                "name": "Kosi",
                "countryCode": "IN"
            },
            {
                "name": "Kota",
                "countryCode": "IN"
            },
            {
                "name": "Kotra",
                "countryCode": "IN"
            },
            {
                "name": "Kuchesar",
                "countryCode": "IN"
            },
            {
                "name": "Kudarkot",
                "countryCode": "IN"
            },
            {
                "name": "Kulpahar",
                "countryCode": "IN"
            },
            {
                "name": "Kunda",
                "countryCode": "IN"
            },
            {
                "name": "Kundarkhi",
                "countryCode": "IN"
            },
            {
                "name": "Kundarki",
                "countryCode": "IN"
            },
            {
                "name": "Kurara",
                "countryCode": "IN"
            },
            {
                "name": "Kurebhar,saidkhanpur",
                "countryCode": "IN"
            },
            {
                "name": "Kushinagar",
                "countryCode": "IN"
            },
            {
                "name": "Kusmara",
                "countryCode": "IN"
            },
            {
                "name": "Kuthaund",
                "countryCode": "IN"
            },
            {
                "name": "Laharpur",
                "countryCode": "IN"
            },
            {
                "name": "Lakhimpur",
                "countryCode": "IN"
            },
            {
                "name": "Lakhna",
                "countryCode": "IN"
            },
            {
                "name": "Lalganj",
                "countryCode": "IN"
            },
            {
                "name": "Lalitpur",
                "countryCode": "IN"
            },
            {
                "name": "Lambhua",
                "countryCode": "IN"
            },
            {
                "name": "Lar",
                "countryCode": "IN"
            },
            {
                "name": "Lawar",
                "countryCode": "IN"
            },
            {
                "name": "Lawar Khas",
                "countryCode": "IN"
            },
            {
                "name": "Loni",
                "countryCode": "IN"
            },
            {
                "name": "Lucknow",
                "countryCode": "IN"
            },
            {
                "name": "Lucknow District",
                "countryCode": "IN"
            },
            {
                "name": "Machhali Shahar",
                "countryCode": "IN"
            },
            {
                "name": "Machhlishahr",
                "countryCode": "IN"
            },
            {
                "name": "Madhoganj",
                "countryCode": "IN"
            },
            {
                "name": "Madhogarh",
                "countryCode": "IN"
            },
            {
                "name": "Maghar",
                "countryCode": "IN"
            },
            {
                "name": "Mahaban",
                "countryCode": "IN"
            },
            {
                "name": "Maharajganj",
                "countryCode": "IN"
            },
            {
                "name": "Mahmudabad",
                "countryCode": "IN"
            },
            {
                "name": "Mahoba",
                "countryCode": "IN"
            },
            {
                "name": "Maholi",
                "countryCode": "IN"
            },
            {
                "name": "Mahrajganj",
                "countryCode": "IN"
            },
            {
                "name": "Mahrajganj (Raebareli)",
                "countryCode": "IN"
            },
            {
                "name": "Mahroni",
                "countryCode": "IN"
            },
            {
                "name": "Mahul",
                "countryCode": "IN"
            },
            {
                "name": "Mailani",
                "countryCode": "IN"
            },
            {
                "name": "Mainpuri",
                "countryCode": "IN"
            },
            {
                "name": "Majhupur",
                "countryCode": "IN"
            },
            {
                "name": "Makanpur",
                "countryCode": "IN"
            },
            {
                "name": "Malasa",
                "countryCode": "IN"
            },
            {
                "name": "Malihabad",
                "countryCode": "IN"
            },
            {
                "name": "Mandawar",
                "countryCode": "IN"
            },
            {
                "name": "Maniar",
                "countryCode": "IN"
            },
            {
                "name": "Manikpur",
                "countryCode": "IN"
            },
            {
                "name": "Manjhanpur",
                "countryCode": "IN"
            },
            {
                "name": "Mankapur",
                "countryCode": "IN"
            },
            {
                "name": "Marahra",
                "countryCode": "IN"
            },
            {
                "name": "Mariahu",
                "countryCode": "IN"
            },
            {
                "name": "Mataundh",
                "countryCode": "IN"
            },
            {
                "name": "Mathura",
                "countryCode": "IN"
            },
            {
                "name": "Mau",
                "countryCode": "IN"
            },
            {
                "name": "Mau Aima",
                "countryCode": "IN"
            },
            {
                "name": "Mau Aimma",
                "countryCode": "IN"
            },
            {
                "name": "Maudaha",
                "countryCode": "IN"
            },
            {
                "name": "Mauranwan",
                "countryCode": "IN"
            },
            {
                "name": "Maurawan",
                "countryCode": "IN"
            },
            {
                "name": "Mawana",
                "countryCode": "IN"
            },
            {
                "name": "Mawar",
                "countryCode": "IN"
            },
            {
                "name": "Meerut",
                "countryCode": "IN"
            },
            {
                "name": "Mehdawal",
                "countryCode": "IN"
            },
            {
                "name": "Mehnagar",
                "countryCode": "IN"
            },
            {
                "name": "Mehndawal",
                "countryCode": "IN"
            },
            {
                "name": "Milak",
                "countryCode": "IN"
            },
            {
                "name": "Milkipur",
                "countryCode": "IN"
            },
            {
                "name": "Miranpur",
                "countryCode": "IN"
            },
            {
                "name": "Miranpur Katra",
                "countryCode": "IN"
            },
            {
                "name": "Mirganj",
                "countryCode": "IN"
            },
            {
                "name": "Mirzapur",
                "countryCode": "IN"
            },
            {
                "name": "Misrikh",
                "countryCode": "IN"
            },
            {
                "name": "Mohan",
                "countryCode": "IN"
            },
            {
                "name": "Mohanpur",
                "countryCode": "IN"
            },
            {
                "name": "Moradabad",
                "countryCode": "IN"
            },
            {
                "name": "Moth",
                "countryCode": "IN"
            },
            {
                "name": "Mubarakpur",
                "countryCode": "IN"
            },
            {
                "name": "Mughal Sarai",
                "countryCode": "IN"
            },
            {
                "name": "Muhammadabad",
                "countryCode": "IN"
            },
            {
                "name": "Mukteshwar",
                "countryCode": "IN"
            },
            {
                "name": "Mungra Badshahpur",
                "countryCode": "IN"
            },
            {
                "name": "Munsyari",
                "countryCode": "IN"
            },
            {
                "name": "Muradabad",
                "countryCode": "IN"
            },
            {
                "name": "Muradnagar",
                "countryCode": "IN"
            },
            {
                "name": "Mursan",
                "countryCode": "IN"
            },
            {
                "name": "Musafir-Khana",
                "countryCode": "IN"
            },
            {
                "name": "Musafirkhana",
                "countryCode": "IN"
            },
            {
                "name": "Muzaffarnagar",
                "countryCode": "IN"
            },
            {
                "name": "Nadigaon",
                "countryCode": "IN"
            },
            {
                "name": "Nagina",
                "countryCode": "IN"
            },
            {
                "name": "Nagla",
                "countryCode": "IN"
            },
            {
                "name": "Nagram",
                "countryCode": "IN"
            },
            {
                "name": "Najibabad",
                "countryCode": "IN"
            },
            {
                "name": "Nakur",
                "countryCode": "IN"
            },
            {
                "name": "Nanauta",
                "countryCode": "IN"
            },
            {
                "name": "Nandgaon",
                "countryCode": "IN"
            },
            {
                "name": "Nanpara",
                "countryCode": "IN"
            },
            {
                "name": "Narauli",
                "countryCode": "IN"
            },
            {
                "name": "Naraura",
                "countryCode": "IN"
            },
            {
                "name": "Narora",
                "countryCode": "IN"
            },
            {
                "name": "Naugama",
                "countryCode": "IN"
            },
            {
                "name": "Naurangpur",
                "countryCode": "IN"
            },
            {
                "name": "Nautanwa",
                "countryCode": "IN"
            },
            {
                "name": "Nawabganj",
                "countryCode": "IN"
            },
            {
                "name": "Nawabganj (Barabanki)",
                "countryCode": "IN"
            },
            {
                "name": "Nawabganj (Bareilly)",
                "countryCode": "IN"
            },
            {
                "name": "Newara",
                "countryCode": "IN"
            },
            {
                "name": "Nichlaul",
                "countryCode": "IN"
            },
            {
                "name": "Nigoh",
                "countryCode": "IN"
            },
            {
                "name": "Nihtaur",
                "countryCode": "IN"
            },
            {
                "name": "Niwari",
                "countryCode": "IN"
            },
            {
                "name": "Nizamabad",
                "countryCode": "IN"
            },
            {
                "name": "Noida",
                "countryCode": "IN"
            },
            {
                "name": "Nurpur",
                "countryCode": "IN"
            },
            {
                "name": "Obra",
                "countryCode": "IN"
            },
            {
                "name": "Orai",
                "countryCode": "IN"
            },
            {
                "name": "Oran",
                "countryCode": "IN"
            },
            {
                "name": "Pachperwa",
                "countryCode": "IN"
            },
            {
                "name": "Padrauna",
                "countryCode": "IN"
            },
            {
                "name": "Pahasu",
                "countryCode": "IN"
            },
            {
                "name": "Paigaon",
                "countryCode": "IN"
            },
            {
                "name": "Pali",
                "countryCode": "IN"
            },
            {
                "name": "Palia Kalan",
                "countryCode": "IN"
            },
            {
                "name": "Paras Rampur",
                "countryCode": "IN"
            },
            {
                "name": "Parichha",
                "countryCode": "IN"
            },
            {
                "name": "Parichhatgarh",
                "countryCode": "IN"
            },
            {
                "name": "Parshadepur",
                "countryCode": "IN"
            },
            {
                "name": "Pathakpura",
                "countryCode": "IN"
            },
            {
                "name": "Patiali",
                "countryCode": "IN"
            },
            {
                "name": "Patti",
                "countryCode": "IN"
            },
            {
                "name": "Pawayan",
                "countryCode": "IN"
            },
            {
                "name": "Payagpur",
                "countryCode": "IN"
            },
            {
                "name": "Phalauda",
                "countryCode": "IN"
            },
            {
                "name": "Phaphamau",
                "countryCode": "IN"
            },
            {
                "name": "Phaphund",
                "countryCode": "IN"
            },
            {
                "name": "Phariha",
                "countryCode": "IN"
            },
            {
                "name": "Pheona",
                "countryCode": "IN"
            },
            {
                "name": "Phulpur",
                "countryCode": "IN"
            },
            {
                "name": "Pichhaura",
                "countryCode": "IN"
            },
            {
                "name": "Pihani",
                "countryCode": "IN"
            },
            {
                "name": "Pilibhit",
                "countryCode": "IN"
            },
            {
                "name": "Pilkhua",
                "countryCode": "IN"
            },
            {
                "name": "Pilkhuwa",
                "countryCode": "IN"
            },
            {
                "name": "Pinahat",
                "countryCode": "IN"
            },
            {
                "name": "Pipraich",
                "countryCode": "IN"
            },
            {
                "name": "Pipri",
                "countryCode": "IN"
            },
            {
                "name": "Pratapgarh",
                "countryCode": "IN"
            },
            {
                "name": "Prayagraj (Allahabad)",
                "countryCode": "IN"
            },
            {
                "name": "Pukhrayan",
                "countryCode": "IN"
            },
            {
                "name": "Puranpur",
                "countryCode": "IN"
            },
            {
                "name": "Purmafi",
                "countryCode": "IN"
            },
            {
                "name": "Purwa",
                "countryCode": "IN"
            },
            {
                "name": "Qadirganj",
                "countryCode": "IN"
            },
            {
                "name": "Rabupura",
                "countryCode": "IN"
            },
            {
                "name": "Radha Kund",
                "countryCode": "IN"
            },
            {
                "name": "Radhakund",
                "countryCode": "IN"
            },
            {
                "name": "Raebareli",
                "countryCode": "IN"
            },
            {
                "name": "Rajapur",
                "countryCode": "IN"
            },
            {
                "name": "Ramkola",
                "countryCode": "IN"
            },
            {
                "name": "Ramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Rampur",
                "countryCode": "IN"
            },
            {
                "name": "Rampura",
                "countryCode": "IN"
            },
            {
                "name": "Ranipur",
                "countryCode": "IN"
            },
            {
                "name": "Ranipur Barsi",
                "countryCode": "IN"
            },
            {
                "name": "Rasra",
                "countryCode": "IN"
            },
            {
                "name": "Rasulabad",
                "countryCode": "IN"
            },
            {
                "name": "Rath",
                "countryCode": "IN"
            },
            {
                "name": "Raya",
                "countryCode": "IN"
            },
            {
                "name": "Rehar",
                "countryCode": "IN"
            },
            {
                "name": "Renukoot",
                "countryCode": "IN"
            },
            {
                "name": "Renukut",
                "countryCode": "IN"
            },
            {
                "name": "Reoti",
                "countryCode": "IN"
            },
            {
                "name": "Reotipur",
                "countryCode": "IN"
            },
            {
                "name": "Richha",
                "countryCode": "IN"
            },
            {
                "name": "Robertsganj",
                "countryCode": "IN"
            },
            {
                "name": "Rudarpur",
                "countryCode": "IN"
            },
            {
                "name": "Rudauli",
                "countryCode": "IN"
            },
            {
                "name": "Rura",
                "countryCode": "IN"
            },
            {
                "name": "Sabalpur",
                "countryCode": "IN"
            },
            {
                "name": "Sachendi",
                "countryCode": "IN"
            },
            {
                "name": "Sadabad",
                "countryCode": "IN"
            },
            {
                "name": "Sadat",
                "countryCode": "IN"
            },
            {
                "name": "Safipur",
                "countryCode": "IN"
            },
            {
                "name": "Saharanpur",
                "countryCode": "IN"
            },
            {
                "name": "Sahaspur",
                "countryCode": "IN"
            },
            {
                "name": "Sahaswan",
                "countryCode": "IN"
            },
            {
                "name": "Sahawar",
                "countryCode": "IN"
            },
            {
                "name": "Sahibabad",
                "countryCode": "IN"
            },
            {
                "name": "Sahpau",
                "countryCode": "IN"
            },
            {
                "name": "Saidpur",
                "countryCode": "IN"
            },
            {
                "name": "Sakhanu",
                "countryCode": "IN"
            },
            {
                "name": "Sakit",
                "countryCode": "IN"
            },
            {
                "name": "Salempur",
                "countryCode": "IN"
            },
            {
                "name": "Salon",
                "countryCode": "IN"
            },
            {
                "name": "Sambhal",
                "countryCode": "IN"
            },
            {
                "name": "Samthar",
                "countryCode": "IN"
            },
            {
                "name": "Sandi",
                "countryCode": "IN"
            },
            {
                "name": "Sandila",
                "countryCode": "IN"
            },
            {
                "name": "Sant Kabir Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Sant Ravi Das Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Sarai Akil",
                "countryCode": "IN"
            },
            {
                "name": "Sarai Ekdil",
                "countryCode": "IN"
            },
            {
                "name": "Sarai Mir",
                "countryCode": "IN"
            },
            {
                "name": "Sarauli",
                "countryCode": "IN"
            },
            {
                "name": "Sardhana",
                "countryCode": "IN"
            },
            {
                "name": "Sarila",
                "countryCode": "IN"
            },
            {
                "name": "Sarurpur",
                "countryCode": "IN"
            },
            {
                "name": "Sasni",
                "countryCode": "IN"
            },
            {
                "name": "Satrikh",
                "countryCode": "IN"
            },
            {
                "name": "Saurikh",
                "countryCode": "IN"
            },
            {
                "name": "Sector",
                "countryCode": "IN"
            },
            {
                "name": "Seohara",
                "countryCode": "IN"
            },
            {
                "name": "Shahabad",
                "countryCode": "IN"
            },
            {
                "name": "Shahganj",
                "countryCode": "IN"
            },
            {
                "name": "Shahi",
                "countryCode": "IN"
            },
            {
                "name": "Shahjahanpur",
                "countryCode": "IN"
            },
            {
                "name": "Shahpur",
                "countryCode": "IN"
            },
            {
                "name": "Shamli",
                "countryCode": "IN"
            },
            {
                "name": "Shamsabad",
                "countryCode": "IN"
            },
            {
                "name": "Shankargarh",
                "countryCode": "IN"
            },
            {
                "name": "Shergarh",
                "countryCode": "IN"
            },
            {
                "name": "Sherkot",
                "countryCode": "IN"
            },
            {
                "name": "Shibnagar",
                "countryCode": "IN"
            },
            {
                "name": "Shikarpur",
                "countryCode": "IN"
            },
            {
                "name": "Shikarpur (Bulandshahr)",
                "countryCode": "IN"
            },
            {
                "name": "Shikohabad",
                "countryCode": "IN"
            },
            {
                "name": "Shishgarh",
                "countryCode": "IN"
            },
            {
                "name": "Shivrajpur",
                "countryCode": "IN"
            },
            {
                "name": "Shrawasti",
                "countryCode": "IN"
            },
            {
                "name": "Siddharthnagar",
                "countryCode": "IN"
            },
            {
                "name": "Siddhaur",
                "countryCode": "IN"
            },
            {
                "name": "Sidhauli",
                "countryCode": "IN"
            },
            {
                "name": "Sidhpura",
                "countryCode": "IN"
            },
            {
                "name": "Sikandarabad",
                "countryCode": "IN"
            },
            {
                "name": "Sikandarpur",
                "countryCode": "IN"
            },
            {
                "name": "Sikandra",
                "countryCode": "IN"
            },
            {
                "name": "Sikandra Rao",
                "countryCode": "IN"
            },
            {
                "name": "Sikandrabad",
                "countryCode": "IN"
            },
            {
                "name": "Sirathu",
                "countryCode": "IN"
            },
            {
                "name": "Sirsa",
                "countryCode": "IN"
            },
            {
                "name": "Sirsaganj",
                "countryCode": "IN"
            },
            {
                "name": "Sirsi",
                "countryCode": "IN"
            },
            {
                "name": "Sisauli",
                "countryCode": "IN"
            },
            {
                "name": "Siswa Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Sitapur",
                "countryCode": "IN"
            },
            {
                "name": "Sonbhadra",
                "countryCode": "IN"
            },
            {
                "name": "Soron",
                "countryCode": "IN"
            },
            {
                "name": "Suar",
                "countryCode": "IN"
            },
            {
                "name": "Sultanpur",
                "countryCode": "IN"
            },
            {
                "name": "Surianwan",
                "countryCode": "IN"
            },
            {
                "name": "Tajpur",
                "countryCode": "IN"
            },
            {
                "name": "Talbahat",
                "countryCode": "IN"
            },
            {
                "name": "Talgram",
                "countryCode": "IN"
            },
            {
                "name": "Tanda",
                "countryCode": "IN"
            },
            {
                "name": "Terha",
                "countryCode": "IN"
            },
            {
                "name": "Thakurdwara",
                "countryCode": "IN"
            },
            {
                "name": "Thana Bhawan",
                "countryCode": "IN"
            },
            {
                "name": "Tigri",
                "countryCode": "IN"
            },
            {
                "name": "Tikaitnagar",
                "countryCode": "IN"
            },
            {
                "name": "Tikri",
                "countryCode": "IN"
            },
            {
                "name": "Tilhar",
                "countryCode": "IN"
            },
            {
                "name": "Tilsahri",
                "countryCode": "IN"
            },
            {
                "name": "Tindwari",
                "countryCode": "IN"
            },
            {
                "name": "Titron",
                "countryCode": "IN"
            },
            {
                "name": "Tori Fatehpur",
                "countryCode": "IN"
            },
            {
                "name": "Tori-Fatehpur",
                "countryCode": "IN"
            },
            {
                "name": "Tulsipur",
                "countryCode": "IN"
            },
            {
                "name": "Tundla",
                "countryCode": "IN"
            },
            {
                "name": "Ugu",
                "countryCode": "IN"
            },
            {
                "name": "Ujhani",
                "countryCode": "IN"
            },
            {
                "name": "Umri",
                "countryCode": "IN"
            },
            {
                "name": "Un",
                "countryCode": "IN"
            },
            {
                "name": "Unnao",
                "countryCode": "IN"
            },
            {
                "name": "Usawan",
                "countryCode": "IN"
            },
            {
                "name": "Usehat",
                "countryCode": "IN"
            },
            {
                "name": "Uska",
                "countryCode": "IN"
            },
            {
                "name": "Utraula",
                "countryCode": "IN"
            },
            {
                "name": "Varanasi",
                "countryCode": "IN"
            },
            {
                "name": "Vindhyachal",
                "countryCode": "IN"
            },
            {
                "name": "Vrindavan",
                "countryCode": "IN"
            },
            {
                "name": "Walterganj",
                "countryCode": "IN"
            },
            {
                "name": "Wazirganj",
                "countryCode": "IN"
            },
            {
                "name": "Yusufpur",
                "countryCode": "IN"
            },
            {
                "name": "Zafarabad",
                "countryCode": "IN"
            },
            {
                "name": "Zaidpur",
                "countryCode": "IN"
            },
            {
                "name": "Zamania",
                "countryCode": "IN"
            },
            {
                "name": "Almora",
                "countryCode": "IN"
            },
            {
                "name": "Bageshwar",
                "countryCode": "IN"
            },
            {
                "name": "Barkot",
                "countryCode": "IN"
            },
            {
                "name": "Bazpur",
                "countryCode": "IN"
            },
            {
                "name": "Bhim Tal",
                "countryCode": "IN"
            },
            {
                "name": "Bhowali",
                "countryCode": "IN"
            },
            {
                "name": "Birbhaddar",
                "countryCode": "IN"
            },
            {
                "name": "Chakrata",
                "countryCode": "IN"
            },
            {
                "name": "Chamoli",
                "countryCode": "IN"
            },
            {
                "name": "Champawat",
                "countryCode": "IN"
            },
            {
                "name": "Clement Town",
                "countryCode": "IN"
            },
            {
                "name": "Dehradun",
                "countryCode": "IN"
            },
            {
                "name": "Devaprayag",
                "countryCode": "IN"
            },
            {
                "name": "Dharchula",
                "countryCode": "IN"
            },
            {
                "name": "Doiwala",
                "countryCode": "IN"
            },
            {
                "name": "Dugadda",
                "countryCode": "IN"
            },
            {
                "name": "Dwarahat",
                "countryCode": "IN"
            },
            {
                "name": "Garhwal",
                "countryCode": "IN"
            },
            {
                "name": "Haldwani",
                "countryCode": "IN"
            },
            {
                "name": "Harbatpur",
                "countryCode": "IN"
            },
            {
                "name": "Haridwar",
                "countryCode": "IN"
            },
            {
                "name": "Jaspur",
                "countryCode": "IN"
            },
            {
                "name": "Joshimath",
                "countryCode": "IN"
            },
            {
                "name": "Kaladhungi",
                "countryCode": "IN"
            },
            {
                "name": "Kalagarh Project Colony",
                "countryCode": "IN"
            },
            {
                "name": "Kashipur",
                "countryCode": "IN"
            },
            {
                "name": "Khatima",
                "countryCode": "IN"
            },
            {
                "name": "Kichha",
                "countryCode": "IN"
            },
            {
                "name": "Kotdwara",
                "countryCode": "IN"
            },
            {
                "name": "Laksar",
                "countryCode": "IN"
            },
            {
                "name": "Lansdowne",
                "countryCode": "IN"
            },
            {
                "name": "Lohaghat",
                "countryCode": "IN"
            },
            {
                "name": "Manglaur",
                "countryCode": "IN"
            },
            {
                "name": "Mussoorie",
                "countryCode": "IN"
            },
            {
                "name": "Naini Tal",
                "countryCode": "IN"
            },
            {
                "name": "Narendranagar",
                "countryCode": "IN"
            },
            {
                "name": "Pauri",
                "countryCode": "IN"
            },
            {
                "name": "Pipalkoti",
                "countryCode": "IN"
            },
            {
                "name": "Pithoragarh",
                "countryCode": "IN"
            },
            {
                "name": "Raipur",
                "countryCode": "IN"
            },
            {
                "name": "Raiwala Bara",
                "countryCode": "IN"
            },
            {
                "name": "Ramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Ranikhet",
                "countryCode": "IN"
            },
            {
                "name": "Rishikesh",
                "countryCode": "IN"
            },
            {
                "name": "Roorkee",
                "countryCode": "IN"
            },
            {
                "name": "Rudraprayag",
                "countryCode": "IN"
            },
            {
                "name": "Sitarganj",
                "countryCode": "IN"
            },
            {
                "name": "Srinagar",
                "countryCode": "IN"
            },
            {
                "name": "Sultanpur",
                "countryCode": "IN"
            },
            {
                "name": "Tanakpur",
                "countryCode": "IN"
            },
            {
                "name": "Tehri",
                "countryCode": "IN"
            },
            {
                "name": "Tehri-Garhwal",
                "countryCode": "IN"
            },
            {
                "name": "Udham Singh Nagar",
                "countryCode": "IN"
            },
            {
                "name": "Uttarkashi",
                "countryCode": "IN"
            },
            {
                "name": "Vikasnagar",
                "countryCode": "IN"
            },
            {
                "name": "Ahmedpur",
                "countryCode": "IN"
            },
            {
                "name": "Aistala",
                "countryCode": "IN"
            },
            {
                "name": "Aknapur",
                "countryCode": "IN"
            },
            {
                "name": "Alipurduar",
                "countryCode": "IN"
            },
            {
                "name": "Amlagora",
                "countryCode": "IN"
            },
            {
                "name": "Amta",
                "countryCode": "IN"
            },
            {
                "name": "Amtala",
                "countryCode": "IN"
            },
            {
                "name": "Andal",
                "countryCode": "IN"
            },
            {
                "name": "Arambagh community development block",
                "countryCode": "IN"
            },
            {
                "name": "Asansol",
                "countryCode": "IN"
            },
            {
                "name": "Ashoknagar Kalyangarh",
                "countryCode": "IN"
            },
            {
                "name": "Badkulla",
                "countryCode": "IN"
            },
            {
                "name": "Baduria",
                "countryCode": "IN"
            },
            {
                "name": "Bagdogra",
                "countryCode": "IN"
            },
            {
                "name": "Bagnan",
                "countryCode": "IN"
            },
            {
                "name": "Bagula",
                "countryCode": "IN"
            },
            {
                "name": "Bahula",
                "countryCode": "IN"
            },
            {
                "name": "Baidyabati",
                "countryCode": "IN"
            },
            {
                "name": "Bakreswar",
                "countryCode": "IN"
            },
            {
                "name": "Balarampur",
                "countryCode": "IN"
            },
            {
                "name": "Bali Chak",
                "countryCode": "IN"
            },
            {
                "name": "Bally",
                "countryCode": "IN"
            },
            {
                "name": "Balurghat",
                "countryCode": "IN"
            },
            {
                "name": "Bamangola community development block",
                "countryCode": "IN"
            },
            {
                "name": "Baneswar",
                "countryCode": "IN"
            },
            {
                "name": "Bangaon",
                "countryCode": "IN"
            },
            {
                "name": "Bankra",
                "countryCode": "IN"
            },
            {
                "name": "Bankura",
                "countryCode": "IN"
            },
            {
                "name": "Bansberia",
                "countryCode": "IN"
            },
            {
                "name": "Bansihari community development block",
                "countryCode": "IN"
            },
            {
                "name": "Barabazar",
                "countryCode": "IN"
            },
            {
                "name": "Baranagar",
                "countryCode": "IN"
            },
            {
                "name": "Barasat",
                "countryCode": "IN"
            },
            {
                "name": "Bardhaman",
                "countryCode": "IN"
            },
            {
                "name": "Barjora",
                "countryCode": "IN"
            },
            {
                "name": "Barrackpore",
                "countryCode": "IN"
            },
            {
                "name": "Baruipur",
                "countryCode": "IN"
            },
            {
                "name": "Basanti",
                "countryCode": "IN"
            },
            {
                "name": "Basirhat",
                "countryCode": "IN"
            },
            {
                "name": "Bawali",
                "countryCode": "IN"
            },
            {
                "name": "Begampur",
                "countryCode": "IN"
            },
            {
                "name": "Belda",
                "countryCode": "IN"
            },
            {
                "name": "Beldanga",
                "countryCode": "IN"
            },
            {
                "name": "Beliatore",
                "countryCode": "IN"
            },
            {
                "name": "Berhampore",
                "countryCode": "IN"
            },
            {
                "name": "Bhadreswar",
                "countryCode": "IN"
            },
            {
                "name": "Bhandardaha",
                "countryCode": "IN"
            },
            {
                "name": "Bhatpara",
                "countryCode": "IN"
            },
            {
                "name": "Birbhum district",
                "countryCode": "IN"
            },
            {
                "name": "Birpara",
                "countryCode": "IN"
            },
            {
                "name": "Bishnupur",
                "countryCode": "IN"
            },
            {
                "name": "Bolpur",
                "countryCode": "IN"
            },
            {
                "name": "Budge Budge",
                "countryCode": "IN"
            },
            {
                "name": "Canning",
                "countryCode": "IN"
            },
            {
                "name": "Chakapara",
                "countryCode": "IN"
            },
            {
                "name": "Chakdaha",
                "countryCode": "IN"
            },
            {
                "name": "Champadanga",
                "countryCode": "IN"
            },
            {
                "name": "Champahati",
                "countryCode": "IN"
            },
            {
                "name": "Champdani",
                "countryCode": "IN"
            },
            {
                "name": "Chandannagar",
                "countryCode": "IN"
            },
            {
                "name": "Chandrakona",
                "countryCode": "IN"
            },
            {
                "name": "Chittaranjan",
                "countryCode": "IN"
            },
            {
                "name": "Churulia",
                "countryCode": "IN"
            },
            {
                "name": "Contai",
                "countryCode": "IN"
            },
            {
                "name": "Cooch Behar",
                "countryCode": "IN"
            },
            {
                "name": "Cossimbazar",
                "countryCode": "IN"
            },
            {
                "name": "Dakshin Dinajpur district",
                "countryCode": "IN"
            },
            {
                "name": "Dalkola",
                "countryCode": "IN"
            },
            {
                "name": "Dam Dam",
                "countryCode": "IN"
            },
            {
                "name": "Darjeeling",
                "countryCode": "IN"
            },
            {
                "name": "Daulatpur",
                "countryCode": "IN"
            },
            {
                "name": "Debagram",
                "countryCode": "IN"
            },
            {
                "name": "Debipur",
                "countryCode": "IN"
            },
            {
                "name": "Dhaniakhali community development block",
                "countryCode": "IN"
            },
            {
                "name": "Dhulagari",
                "countryCode": "IN"
            },
            {
                "name": "Dhulian",
                "countryCode": "IN"
            },
            {
                "name": "Dhupguri",
                "countryCode": "IN"
            },
            {
                "name": "Diamond Harbour",
                "countryCode": "IN"
            },
            {
                "name": "Digha",
                "countryCode": "IN"
            },
            {
                "name": "Dinhata",
                "countryCode": "IN"
            },
            {
                "name": "Domjur",
                "countryCode": "IN"
            },
            {
                "name": "Dubrajpur",
                "countryCode": "IN"
            },
            {
                "name": "Durgapur",
                "countryCode": "IN"
            },
            {
                "name": "Egra",
                "countryCode": "IN"
            },
            {
                "name": "Falakata",
                "countryCode": "IN"
            },
            {
                "name": "Farakka",
                "countryCode": "IN"
            },
            {
                "name": "Fort Gloster",
                "countryCode": "IN"
            },
            {
                "name": "Gaighata community development block",
                "countryCode": "IN"
            },
            {
                "name": "Gairkata",
                "countryCode": "IN"
            },
            {
                "name": "Gangadharpur",
                "countryCode": "IN"
            },
            {
                "name": "Gangarampur",
                "countryCode": "IN"
            },
            {
                "name": "Garui",
                "countryCode": "IN"
            },
            {
                "name": "Garulia",
                "countryCode": "IN"
            },
            {
                "name": "Ghatal",
                "countryCode": "IN"
            },
            {
                "name": "Giria",
                "countryCode": "IN"
            },
            {
                "name": "Gobardanga",
                "countryCode": "IN"
            },
            {
                "name": "Gobindapur",
                "countryCode": "IN"
            },
            {
                "name": "Gopalpur",
                "countryCode": "IN"
            },
            {
                "name": "Gopinathpur",
                "countryCode": "IN"
            },
            {
                "name": "Gorubathan",
                "countryCode": "IN"
            },
            {
                "name": "Gosaba",
                "countryCode": "IN"
            },
            {
                "name": "Gosanimari",
                "countryCode": "IN"
            },
            {
                "name": "Gurdaha",
                "countryCode": "IN"
            },
            {
                "name": "Guskhara",
                "countryCode": "IN"
            },
            {
                "name": "Habra",
                "countryCode": "IN"
            },
            {
                "name": "Haldia",
                "countryCode": "IN"
            },
            {
                "name": "Haldibari",
                "countryCode": "IN"
            },
            {
                "name": "Halisahar",
                "countryCode": "IN"
            },
            {
                "name": "Harindanga",
                "countryCode": "IN"
            },
            {
                "name": "Haringhata",
                "countryCode": "IN"
            },
            {
                "name": "Haripur",
                "countryCode": "IN"
            },
            {
                "name": "Hasimara",
                "countryCode": "IN"
            },
            {
                "name": "Hindusthan Cables Town",
                "countryCode": "IN"
            },
            {
                "name": "Hooghly district",
                "countryCode": "IN"
            },
            {
                "name": "Howrah",
                "countryCode": "IN"
            },
            {
                "name": "Ichapur",
                "countryCode": "IN"
            },
            {
                "name": "Indpur community development block",
                "countryCode": "IN"
            },
            {
                "name": "Ingraj Bazar",
                "countryCode": "IN"
            },
            {
                "name": "Islampur",
                "countryCode": "IN"
            },
            {
                "name": "Jafarpur",
                "countryCode": "IN"
            },
            {
                "name": "Jaigaon",
                "countryCode": "IN"
            },
            {
                "name": "Jalpaiguri",
                "countryCode": "IN"
            },
            {
                "name": "Jamuria",
                "countryCode": "IN"
            },
            {
                "name": "Jangipur",
                "countryCode": "IN"
            },
            {
                "name": "Jaynagar Majilpur",
                "countryCode": "IN"
            },
            {
                "name": "Jejur",
                "countryCode": "IN"
            },
            {
                "name": "Jhalida",
                "countryCode": "IN"
            },
            {
                "name": "Jhargram",
                "countryCode": "IN"
            },
            {
                "name": "Jhilimili",
                "countryCode": "IN"
            },
            {
                "name": "Kakdwip",
                "countryCode": "IN"
            },
            {
                "name": "Kalaikunda",
                "countryCode": "IN"
            },
            {
                "name": "Kaliaganj",
                "countryCode": "IN"
            },
            {
                "name": "Kalimpong",
                "countryCode": "IN"
            },
            {
                "name": "Kalna",
                "countryCode": "IN"
            },
            {
                "name": "Kalyani",
                "countryCode": "IN"
            },
            {
                "name": "Kamarhati",
                "countryCode": "IN"
            },
            {
                "name": "Kamarpukur",
                "countryCode": "IN"
            },
            {
                "name": "Kanchrapara",
                "countryCode": "IN"
            },
            {
                "name": "Kandi",
                "countryCode": "IN"
            },
            {
                "name": "Karimpur",
                "countryCode": "IN"
            },
            {
                "name": "Katwa",
                "countryCode": "IN"
            },
            {
                "name": "Kenda",
                "countryCode": "IN"
            },
            {
                "name": "Keshabpur",
                "countryCode": "IN"
            },
            {
                "name": "Kharagpur",
                "countryCode": "IN"
            },
            {
                "name": "Kharar",
                "countryCode": "IN"
            },
            {
                "name": "Kharba",
                "countryCode": "IN"
            },
            {
                "name": "Khardaha",
                "countryCode": "IN"
            },
            {
                "name": "Khatra",
                "countryCode": "IN"
            },
            {
                "name": "Kirnahar",
                "countryCode": "IN"
            },
            {
                "name": "Kolkata",
                "countryCode": "IN"
            },
            {
                "name": "Konnagar",
                "countryCode": "IN"
            },
            {
                "name": "Krishnanagar",
                "countryCode": "IN"
            },
            {
                "name": "Krishnapur",
                "countryCode": "IN"
            },
            {
                "name": "Kshirpai",
                "countryCode": "IN"
            },
            {
                "name": "Kulpi",
                "countryCode": "IN"
            },
            {
                "name": "Kultali",
                "countryCode": "IN"
            },
            {
                "name": "Kulti",
                "countryCode": "IN"
            },
            {
                "name": "Kurseong",
                "countryCode": "IN"
            },
            {
                "name": "Lalgarh",
                "countryCode": "IN"
            },
            {
                "name": "Lalgola",
                "countryCode": "IN"
            },
            {
                "name": "Loyabad",
                "countryCode": "IN"
            },
            {
                "name": "Madanpur",
                "countryCode": "IN"
            },
            {
                "name": "Madhyamgram",
                "countryCode": "IN"
            },
            {
                "name": "Mahiari",
                "countryCode": "IN"
            },
            {
                "name": "Mahishadal community development block",
                "countryCode": "IN"
            },
            {
                "name": "Mainaguri",
                "countryCode": "IN"
            },
            {
                "name": "Manikpara",
                "countryCode": "IN"
            },
            {
                "name": "Masila",
                "countryCode": "IN"
            },
            {
                "name": "Mathabhanga",
                "countryCode": "IN"
            },
            {
                "name": "Matiali community development block",
                "countryCode": "IN"
            },
            {
                "name": "Matigara community development block",
                "countryCode": "IN"
            },
            {
                "name": "Medinipur",
                "countryCode": "IN"
            },
            {
                "name": "Mejia community development block",
                "countryCode": "IN"
            },
            {
                "name": "Memari",
                "countryCode": "IN"
            },
            {
                "name": "Mirik",
                "countryCode": "IN"
            },
            {
                "name": "Mohanpur community development block",
                "countryCode": "IN"
            },
            {
                "name": "Monoharpur",
                "countryCode": "IN"
            },
            {
                "name": "Muragacha",
                "countryCode": "IN"
            },
            {
                "name": "Muri",
                "countryCode": "IN"
            },
            {
                "name": "Murshidabad",
                "countryCode": "IN"
            },
            {
                "name": "Nabadwip",
                "countryCode": "IN"
            },
            {
                "name": "Nabagram",
                "countryCode": "IN"
            },
            {
                "name": "Nadia district",
                "countryCode": "IN"
            },
            {
                "name": "Nagarukhra",
                "countryCode": "IN"
            },
            {
                "name": "Nagrakata",
                "countryCode": "IN"
            },
            {
                "name": "Naihati",
                "countryCode": "IN"
            },
            {
                "name": "Naksalbari",
                "countryCode": "IN"
            },
            {
                "name": "Nalhati",
                "countryCode": "IN"
            },
            {
                "name": "Nalpur",
                "countryCode": "IN"
            },
            {
                "name": "Namkhana community development block",
                "countryCode": "IN"
            },
            {
                "name": "Nandigram",
                "countryCode": "IN"
            },
            {
                "name": "Nangi",
                "countryCode": "IN"
            },
            {
                "name": "Nayagram community development block",
                "countryCode": "IN"
            },
            {
                "name": "North 24 Parganas district",
                "countryCode": "IN"
            },
            {
                "name": "Odlabari",
                "countryCode": "IN"
            },
            {
                "name": "Paikpara",
                "countryCode": "IN"
            },
            {
                "name": "Panagarh",
                "countryCode": "IN"
            },
            {
                "name": "Panchla",
                "countryCode": "IN"
            },
            {
                "name": "Panchmura",
                "countryCode": "IN"
            },
            {
                "name": "Pandua",
                "countryCode": "IN"
            },
            {
                "name": "Panihati",
                "countryCode": "IN"
            },
            {
                "name": "Panskura",
                "countryCode": "IN"
            },
            {
                "name": "Parbatipur",
                "countryCode": "IN"
            },
            {
                "name": "Paschim Medinipur district",
                "countryCode": "IN"
            },
            {
                "name": "Patiram",
                "countryCode": "IN"
            },
            {
                "name": "Patrasaer",
                "countryCode": "IN"
            },
            {
                "name": "Patuli",
                "countryCode": "IN"
            },
            {
                "name": "Pujali",
                "countryCode": "IN"
            },
            {
                "name": "Puncha community development block",
                "countryCode": "IN"
            },
            {
                "name": "Purba Medinipur district",
                "countryCode": "IN"
            },
            {
                "name": "Purulia",
                "countryCode": "IN"
            },
            {
                "name": "Raghudebbati",
                "countryCode": "IN"
            },
            {
                "name": "Raghunathpur",
                "countryCode": "IN"
            },
            {
                "name": "Raiganj",
                "countryCode": "IN"
            },
            {
                "name": "Rajmahal",
                "countryCode": "IN"
            },
            {
                "name": "Rajnagar community development block",
                "countryCode": "IN"
            },
            {
                "name": "Ramchandrapur",
                "countryCode": "IN"
            },
            {
                "name": "Ramjibanpur",
                "countryCode": "IN"
            },
            {
                "name": "Ramnagar",
                "countryCode": "IN"
            },
            {
                "name": "Rampur Hat",
                "countryCode": "IN"
            },
            {
                "name": "Ranaghat",
                "countryCode": "IN"
            },
            {
                "name": "Raniganj",
                "countryCode": "IN"
            },
            {
                "name": "Raypur",
                "countryCode": "IN"
            },
            {
                "name": "Rishra",
                "countryCode": "IN"
            },
            {
                "name": "Sahapur",
                "countryCode": "IN"
            },
            {
                "name": "Sainthia",
                "countryCode": "IN"
            },
            {
                "name": "Salanpur community development block",
                "countryCode": "IN"
            },
            {
                "name": "Sankarpur",
                "countryCode": "IN"
            },
            {
                "name": "Sankrail",
                "countryCode": "IN"
            },
            {
                "name": "Santipur",
                "countryCode": "IN"
            },
            {
                "name": "Santoshpur",
                "countryCode": "IN"
            },
            {
                "name": "Santuri community development block",
                "countryCode": "IN"
            },
            {
                "name": "Sarenga",
                "countryCode": "IN"
            },
            {
                "name": "Serampore",
                "countryCode": "IN"
            },
            {
                "name": "Serpur",
                "countryCode": "IN"
            },
            {
                "name": "Shyamnagar, West Bengal",
                "countryCode": "IN"
            },
            {
                "name": "Siliguri",
                "countryCode": "IN"
            },
            {
                "name": "Singur",
                "countryCode": "IN"
            },
            {
                "name": "Sodpur",
                "countryCode": "IN"
            },
            {
                "name": "Solap",
                "countryCode": "IN"
            },
            {
                "name": "Sonada",
                "countryCode": "IN"
            },
            {
                "name": "Sonamukhi",
                "countryCode": "IN"
            },
            {
                "name": "Sonarpur community development block",
                "countryCode": "IN"
            },
            {
                "name": "South 24 Parganas district",
                "countryCode": "IN"
            },
            {
                "name": "Srikhanda",
                "countryCode": "IN"
            },
            {
                "name": "Srirampur",
                "countryCode": "IN"
            },
            {
                "name": "Suri",
                "countryCode": "IN"
            },
            {
                "name": "Swarupnagar community development block",
                "countryCode": "IN"
            },
            {
                "name": "Takdah",
                "countryCode": "IN"
            },
            {
                "name": "Taki",
                "countryCode": "IN"
            },
            {
                "name": "Tamluk",
                "countryCode": "IN"
            },
            {
                "name": "Tarakeswar",
                "countryCode": "IN"
            },
            {
                "name": "Titagarh",
                "countryCode": "IN"
            },
            {
                "name": "Tufanganj",
                "countryCode": "IN"
            },
            {
                "name": "Tulin",
                "countryCode": "IN"
            },
            {
                "name": "Uchalan",
                "countryCode": "IN"
            },
            {
                "name": "Ula",
                "countryCode": "IN"
            },
            {
                "name": "Uluberia",
                "countryCode": "IN"
            },
            {
                "name": "Uttar Dinajpur district",
                "countryCode": "IN"
            },
            {
                "name": "Uttarpara Kotrung",
                "countryCode": "IN"
            }
        ]
    },
    {
        "name": "Indonesia",
        "phonecode": "62",
        "cities": [
            {
                "name": "Banda Aceh",
                "countryCode": "ID"
            },
            {
                "name": "Bireun",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Barat Daya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Besar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Jaya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Singkil",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Tamiang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Tenggara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Aceh Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bener Meriah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bireuen",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gayo Lues",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nagan Raya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pidie",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Simeulue",
                "countryCode": "ID"
            },
            {
                "name": "Kota Banda Aceh",
                "countryCode": "ID"
            },
            {
                "name": "Kota Langsa",
                "countryCode": "ID"
            },
            {
                "name": "Kota Lhokseumawe",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sabang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Subulussalam",
                "countryCode": "ID"
            },
            {
                "name": "Langsa",
                "countryCode": "ID"
            },
            {
                "name": "Lhokseumawe",
                "countryCode": "ID"
            },
            {
                "name": "Meulaboh",
                "countryCode": "ID"
            },
            {
                "name": "Reuleuet",
                "countryCode": "ID"
            },
            {
                "name": "Sabang",
                "countryCode": "ID"
            },
            {
                "name": "Sigli",
                "countryCode": "ID"
            },
            {
                "name": "Sinabang",
                "countryCode": "ID"
            },
            {
                "name": "Singkil",
                "countryCode": "ID"
            },
            {
                "name": "Amlapura",
                "countryCode": "ID"
            },
            {
                "name": "Amlapura city",
                "countryCode": "ID"
            },
            {
                "name": "Banjar",
                "countryCode": "ID"
            },
            {
                "name": "Banjar Wangsian",
                "countryCode": "ID"
            },
            {
                "name": "Bedugul",
                "countryCode": "ID"
            },
            {
                "name": "Denpasar",
                "countryCode": "ID"
            },
            {
                "name": "Jimbaran",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Badung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangli",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buleleng",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gianyar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jembrana",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Karang Asem",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Klungkung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tabanan",
                "countryCode": "ID"
            },
            {
                "name": "Klungkung",
                "countryCode": "ID"
            },
            {
                "name": "Kota Denpasar",
                "countryCode": "ID"
            },
            {
                "name": "Kuta",
                "countryCode": "ID"
            },
            {
                "name": "Legian",
                "countryCode": "ID"
            },
            {
                "name": "Lovina",
                "countryCode": "ID"
            },
            {
                "name": "Munduk",
                "countryCode": "ID"
            },
            {
                "name": "Negara",
                "countryCode": "ID"
            },
            {
                "name": "Nusa Dua",
                "countryCode": "ID"
            },
            {
                "name": "Seririt",
                "countryCode": "ID"
            },
            {
                "name": "Singaraja",
                "countryCode": "ID"
            },
            {
                "name": "Tabanan",
                "countryCode": "ID"
            },
            {
                "name": "Ubud",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangka",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangka Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangka Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangka Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Belitung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Belitung Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pangkal Pinang",
                "countryCode": "ID"
            },
            {
                "name": "Manggar",
                "countryCode": "ID"
            },
            {
                "name": "Muntok",
                "countryCode": "ID"
            },
            {
                "name": "Pangkalpinang",
                "countryCode": "ID"
            },
            {
                "name": "Sungailiat",
                "countryCode": "ID"
            },
            {
                "name": "Tanjung Pandan",
                "countryCode": "ID"
            },
            {
                "name": "Bengkulu",
                "countryCode": "ID"
            },
            {
                "name": "Curup",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bengkulu Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bengkulu Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bengkulu Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kaur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepahiang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lebong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mukomuko",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Rejang Lebong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Seluma",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bengkulu",
                "countryCode": "ID"
            },
            {
                "name": "Curug",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lebak",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pandeglang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Serang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tangerang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Cilegon",
                "countryCode": "ID"
            },
            {
                "name": "Kota Serang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tangerang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tangerang Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Labuan",
                "countryCode": "ID"
            },
            {
                "name": "Pandeglang",
                "countryCode": "ID"
            },
            {
                "name": "Rangkasbitung",
                "countryCode": "ID"
            },
            {
                "name": "Serang",
                "countryCode": "ID"
            },
            {
                "name": "South Tangerang",
                "countryCode": "ID"
            },
            {
                "name": "Tangerang",
                "countryCode": "ID"
            },
            {
                "name": "Gorontalo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Boalemo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bone Bolango",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gorontalo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gorontalo Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pohuwato",
                "countryCode": "ID"
            },
            {
                "name": "Kota Gorontalo",
                "countryCode": "ID"
            },
            {
                "name": "Bejubang Dua",
                "countryCode": "ID"
            },
            {
                "name": "Jambi City",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Batang Hari",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bungo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kerinci",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Merangin",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Muaro Jambi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sarolangun",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanjung Jabung Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanjung Jabung Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tebo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Jambi",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sungai Penuh",
                "countryCode": "ID"
            },
            {
                "name": "Kuala Tungkal",
                "countryCode": "ID"
            },
            {
                "name": "Mendaha",
                "countryCode": "ID"
            },
            {
                "name": "Simpang",
                "countryCode": "ID"
            },
            {
                "name": "Sungai Penuh",
                "countryCode": "ID"
            },
            {
                "name": "Arjawinangun",
                "countryCode": "ID"
            },
            {
                "name": "Astanajapura",
                "countryCode": "ID"
            },
            {
                "name": "Bandung",
                "countryCode": "ID"
            },
            {
                "name": "Banjar",
                "countryCode": "ID"
            },
            {
                "name": "Banjaran",
                "countryCode": "ID"
            },
            {
                "name": "Bekasi",
                "countryCode": "ID"
            },
            {
                "name": "Bogor",
                "countryCode": "ID"
            },
            {
                "name": "Caringin",
                "countryCode": "ID"
            },
            {
                "name": "Ciamis",
                "countryCode": "ID"
            },
            {
                "name": "Ciampea",
                "countryCode": "ID"
            },
            {
                "name": "Cibinong",
                "countryCode": "ID"
            },
            {
                "name": "Cicurug",
                "countryCode": "ID"
            },
            {
                "name": "Cikampek",
                "countryCode": "ID"
            },
            {
                "name": "Cikarang",
                "countryCode": "ID"
            },
            {
                "name": "Cikupa",
                "countryCode": "ID"
            },
            {
                "name": "Cileungsir",
                "countryCode": "ID"
            },
            {
                "name": "Cileunyi",
                "countryCode": "ID"
            },
            {
                "name": "Cimahi",
                "countryCode": "ID"
            },
            {
                "name": "Ciputat",
                "countryCode": "ID"
            },
            {
                "name": "Ciranjang-hilir",
                "countryCode": "ID"
            },
            {
                "name": "Cirebon",
                "countryCode": "ID"
            },
            {
                "name": "Citeureup",
                "countryCode": "ID"
            },
            {
                "name": "Depok",
                "countryCode": "ID"
            },
            {
                "name": "Indramayu",
                "countryCode": "ID"
            },
            {
                "name": "Jatibarang",
                "countryCode": "ID"
            },
            {
                "name": "Jatiwangi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bandung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bandung Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bekasi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bogor",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ciamis",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Cianjur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Cirebon",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Garut",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Indramayu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Karawang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kuningan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Majalengka",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pangandaran",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Purwakarta",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Subang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sukabumi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumedang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tasikmalaya",
                "countryCode": "ID"
            },
            {
                "name": "Karangampel",
                "countryCode": "ID"
            },
            {
                "name": "Karangsembung",
                "countryCode": "ID"
            },
            {
                "name": "Kawalu",
                "countryCode": "ID"
            },
            {
                "name": "Klangenan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bandung",
                "countryCode": "ID"
            },
            {
                "name": "Kota Banjar",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bekasi",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bogor",
                "countryCode": "ID"
            },
            {
                "name": "Kota Cimahi",
                "countryCode": "ID"
            },
            {
                "name": "Kota Cirebon",
                "countryCode": "ID"
            },
            {
                "name": "Kota Depok",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sukabumi",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tasikmalaya",
                "countryCode": "ID"
            },
            {
                "name": "Kresek",
                "countryCode": "ID"
            },
            {
                "name": "Kuningan",
                "countryCode": "ID"
            },
            {
                "name": "Lembang",
                "countryCode": "ID"
            },
            {
                "name": "Majalengka",
                "countryCode": "ID"
            },
            {
                "name": "Margahayukencana",
                "countryCode": "ID"
            },
            {
                "name": "Ngawi",
                "countryCode": "ID"
            },
            {
                "name": "Padalarang",
                "countryCode": "ID"
            },
            {
                "name": "Palimanan",
                "countryCode": "ID"
            },
            {
                "name": "Pamanukan",
                "countryCode": "ID"
            },
            {
                "name": "Pameungpeuk",
                "countryCode": "ID"
            },
            {
                "name": "Pamulang",
                "countryCode": "ID"
            },
            {
                "name": "Parung",
                "countryCode": "ID"
            },
            {
                "name": "Pasarkemis",
                "countryCode": "ID"
            },
            {
                "name": "Paseh",
                "countryCode": "ID"
            },
            {
                "name": "Pelabuhanratu",
                "countryCode": "ID"
            },
            {
                "name": "Plumbon",
                "countryCode": "ID"
            },
            {
                "name": "Purwakarta",
                "countryCode": "ID"
            },
            {
                "name": "Rajapolah",
                "countryCode": "ID"
            },
            {
                "name": "Rengasdengklok",
                "countryCode": "ID"
            },
            {
                "name": "Sawangan",
                "countryCode": "ID"
            },
            {
                "name": "Sepatan",
                "countryCode": "ID"
            },
            {
                "name": "Serpong",
                "countryCode": "ID"
            },
            {
                "name": "Singaparna",
                "countryCode": "ID"
            },
            {
                "name": "Soreang",
                "countryCode": "ID"
            },
            {
                "name": "Sukabumi",
                "countryCode": "ID"
            },
            {
                "name": "Sumber",
                "countryCode": "ID"
            },
            {
                "name": "Sumedang",
                "countryCode": "ID"
            },
            {
                "name": "Sumedang Utara",
                "countryCode": "ID"
            },
            {
                "name": "Tasikmalaya",
                "countryCode": "ID"
            },
            {
                "name": "Teluknaga",
                "countryCode": "ID"
            },
            {
                "name": "Wanaraja",
                "countryCode": "ID"
            },
            {
                "name": "Weru",
                "countryCode": "ID"
            },
            {
                "name": "Babat",
                "countryCode": "ID"
            },
            {
                "name": "Balung",
                "countryCode": "ID"
            },
            {
                "name": "Bangil",
                "countryCode": "ID"
            },
            {
                "name": "Bangkalan",
                "countryCode": "ID"
            },
            {
                "name": "Banyuwangi",
                "countryCode": "ID"
            },
            {
                "name": "Batu",
                "countryCode": "ID"
            },
            {
                "name": "Besuki",
                "countryCode": "ID"
            },
            {
                "name": "Blitar",
                "countryCode": "ID"
            },
            {
                "name": "Bojonegoro",
                "countryCode": "ID"
            },
            {
                "name": "Bondowoso",
                "countryCode": "ID"
            },
            {
                "name": "Boyolangu",
                "countryCode": "ID"
            },
            {
                "name": "Buduran",
                "countryCode": "ID"
            },
            {
                "name": "Dampit",
                "countryCode": "ID"
            },
            {
                "name": "Diwek",
                "countryCode": "ID"
            },
            {
                "name": "Driyorejo",
                "countryCode": "ID"
            },
            {
                "name": "Gambiran Satu",
                "countryCode": "ID"
            },
            {
                "name": "Gampengrejo",
                "countryCode": "ID"
            },
            {
                "name": "Gedangan",
                "countryCode": "ID"
            },
            {
                "name": "Genteng",
                "countryCode": "ID"
            },
            {
                "name": "Gongdanglegi Kulon",
                "countryCode": "ID"
            },
            {
                "name": "Gresik",
                "countryCode": "ID"
            },
            {
                "name": "Gresik Regency",
                "countryCode": "ID"
            },
            {
                "name": "Jember",
                "countryCode": "ID"
            },
            {
                "name": "Jombang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bangkalan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banyuwangi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Blitar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bojonegoro",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bondowoso",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jember",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jombang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kediri",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lamongan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lumajang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Madiun",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Magetan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Malang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mojokerto",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nganjuk",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ngawi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pacitan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pamekasan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pasuruan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ponorogo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Probolinggo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sampang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sidoarjo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Situbondo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumenep",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Trenggalek",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tuban",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tulungagung",
                "countryCode": "ID"
            },
            {
                "name": "Kalianget",
                "countryCode": "ID"
            },
            {
                "name": "Kamal",
                "countryCode": "ID"
            },
            {
                "name": "Kebomas",
                "countryCode": "ID"
            },
            {
                "name": "Kediri",
                "countryCode": "ID"
            },
            {
                "name": "Kedungwaru",
                "countryCode": "ID"
            },
            {
                "name": "Kencong",
                "countryCode": "ID"
            },
            {
                "name": "Kepanjen",
                "countryCode": "ID"
            },
            {
                "name": "Kertosono",
                "countryCode": "ID"
            },
            {
                "name": "Kota Batu",
                "countryCode": "ID"
            },
            {
                "name": "Kota Blitar",
                "countryCode": "ID"
            },
            {
                "name": "Kota Kediri",
                "countryCode": "ID"
            },
            {
                "name": "Kota Madiun",
                "countryCode": "ID"
            },
            {
                "name": "Kota Malang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Mojokerto",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pasuruan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Probolinggo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Surabaya",
                "countryCode": "ID"
            },
            {
                "name": "Kraksaan",
                "countryCode": "ID"
            },
            {
                "name": "Krian",
                "countryCode": "ID"
            },
            {
                "name": "Lamongan",
                "countryCode": "ID"
            },
            {
                "name": "Lawang",
                "countryCode": "ID"
            },
            {
                "name": "Lumajang",
                "countryCode": "ID"
            },
            {
                "name": "Madiun",
                "countryCode": "ID"
            },
            {
                "name": "Malang",
                "countryCode": "ID"
            },
            {
                "name": "Mojoagung",
                "countryCode": "ID"
            },
            {
                "name": "Mojokerto",
                "countryCode": "ID"
            },
            {
                "name": "Muncar",
                "countryCode": "ID"
            },
            {
                "name": "Nganjuk",
                "countryCode": "ID"
            },
            {
                "name": "Ngoro",
                "countryCode": "ID"
            },
            {
                "name": "Ngunut",
                "countryCode": "ID"
            },
            {
                "name": "Paciran",
                "countryCode": "ID"
            },
            {
                "name": "Pakisaji",
                "countryCode": "ID"
            },
            {
                "name": "Pamekasan",
                "countryCode": "ID"
            },
            {
                "name": "Panarukan",
                "countryCode": "ID"
            },
            {
                "name": "Pandaan",
                "countryCode": "ID"
            },
            {
                "name": "Panji",
                "countryCode": "ID"
            },
            {
                "name": "Pare",
                "countryCode": "ID"
            },
            {
                "name": "Pasuruan",
                "countryCode": "ID"
            },
            {
                "name": "Ponorogo",
                "countryCode": "ID"
            },
            {
                "name": "Prigen",
                "countryCode": "ID"
            },
            {
                "name": "Probolinggo",
                "countryCode": "ID"
            },
            {
                "name": "Sampang",
                "countryCode": "ID"
            },
            {
                "name": "Sidoarjo",
                "countryCode": "ID"
            },
            {
                "name": "Singojuruh",
                "countryCode": "ID"
            },
            {
                "name": "Singosari",
                "countryCode": "ID"
            },
            {
                "name": "Situbondo",
                "countryCode": "ID"
            },
            {
                "name": "Soko",
                "countryCode": "ID"
            },
            {
                "name": "Srono",
                "countryCode": "ID"
            },
            {
                "name": "Sumberpucung",
                "countryCode": "ID"
            },
            {
                "name": "Sumenep",
                "countryCode": "ID"
            },
            {
                "name": "Surabaya",
                "countryCode": "ID"
            },
            {
                "name": "Tanggul",
                "countryCode": "ID"
            },
            {
                "name": "Tanggulangin",
                "countryCode": "ID"
            },
            {
                "name": "Trenggalek",
                "countryCode": "ID"
            },
            {
                "name": "Tuban",
                "countryCode": "ID"
            },
            {
                "name": "Tulangan Utara",
                "countryCode": "ID"
            },
            {
                "name": "Tulungagung",
                "countryCode": "ID"
            },
            {
                "name": "Wongsorejo",
                "countryCode": "ID"
            },
            {
                "name": "Jakarta",
                "countryCode": "ID"
            },
            {
                "name": "Kota Administrasi Jakarta Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kota Administrasi Jakarta Pusat",
                "countryCode": "ID"
            },
            {
                "name": "Kota Administrasi Jakarta Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Administrasi Jakarta Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kota Administrasi Jakarta Utara",
                "countryCode": "ID"
            },
            {
                "name": "Adiwerna",
                "countryCode": "ID"
            },
            {
                "name": "Ambarawa",
                "countryCode": "ID"
            },
            {
                "name": "Baekrajan",
                "countryCode": "ID"
            },
            {
                "name": "Baki",
                "countryCode": "ID"
            },
            {
                "name": "Balapulang",
                "countryCode": "ID"
            },
            {
                "name": "Banyumas",
                "countryCode": "ID"
            },
            {
                "name": "Batang",
                "countryCode": "ID"
            },
            {
                "name": "Baturaden",
                "countryCode": "ID"
            },
            {
                "name": "Blora",
                "countryCode": "ID"
            },
            {
                "name": "Boyolali",
                "countryCode": "ID"
            },
            {
                "name": "Buaran",
                "countryCode": "ID"
            },
            {
                "name": "Bulakamba",
                "countryCode": "ID"
            },
            {
                "name": "Candi Prambanan",
                "countryCode": "ID"
            },
            {
                "name": "Ceper",
                "countryCode": "ID"
            },
            {
                "name": "Cepu",
                "countryCode": "ID"
            },
            {
                "name": "Colomadu",
                "countryCode": "ID"
            },
            {
                "name": "Comal",
                "countryCode": "ID"
            },
            {
                "name": "Delanggu",
                "countryCode": "ID"
            },
            {
                "name": "Demak",
                "countryCode": "ID"
            },
            {
                "name": "Dukuhturi",
                "countryCode": "ID"
            },
            {
                "name": "Gatak",
                "countryCode": "ID"
            },
            {
                "name": "Gebog",
                "countryCode": "ID"
            },
            {
                "name": "Gombong",
                "countryCode": "ID"
            },
            {
                "name": "Grogol",
                "countryCode": "ID"
            },
            {
                "name": "Gunung Kendil",
                "countryCode": "ID"
            },
            {
                "name": "Jaten",
                "countryCode": "ID"
            },
            {
                "name": "Jatiroto",
                "countryCode": "ID"
            },
            {
                "name": "Jekulo",
                "countryCode": "ID"
            },
            {
                "name": "Jogonalan",
                "countryCode": "ID"
            },
            {
                "name": "Juwana",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banjarnegara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banyumas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Batang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Blora",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Boyolali",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Brebes",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Cilacap",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Demak",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Grobogan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jepara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Karanganyar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kebumen",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kendal",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Klaten",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kudus",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Magelang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pati",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pekalongan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pemalang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Purbalingga",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Purworejo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Rembang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Semarang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sragen",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sukoharjo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tegal",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Temanggung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Wonogiri",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Wonosobo",
                "countryCode": "ID"
            },
            {
                "name": "Karanganom",
                "countryCode": "ID"
            },
            {
                "name": "Kartasura",
                "countryCode": "ID"
            },
            {
                "name": "Kebonarun",
                "countryCode": "ID"
            },
            {
                "name": "Kedungwuni",
                "countryCode": "ID"
            },
            {
                "name": "Ketanggungan",
                "countryCode": "ID"
            },
            {
                "name": "Klaten",
                "countryCode": "ID"
            },
            {
                "name": "Kota Magelang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pekalongan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Salatiga",
                "countryCode": "ID"
            },
            {
                "name": "Kota Semarang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Surakarta",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tegal",
                "countryCode": "ID"
            },
            {
                "name": "Kroya",
                "countryCode": "ID"
            },
            {
                "name": "Kudus",
                "countryCode": "ID"
            },
            {
                "name": "Kutoarjo",
                "countryCode": "ID"
            },
            {
                "name": "Lasem",
                "countryCode": "ID"
            },
            {
                "name": "Lebaksiu",
                "countryCode": "ID"
            },
            {
                "name": "Magelang",
                "countryCode": "ID"
            },
            {
                "name": "Majenang",
                "countryCode": "ID"
            },
            {
                "name": "Margasari",
                "countryCode": "ID"
            },
            {
                "name": "Mertoyudan",
                "countryCode": "ID"
            },
            {
                "name": "Mlonggo",
                "countryCode": "ID"
            },
            {
                "name": "Mranggen",
                "countryCode": "ID"
            },
            {
                "name": "Muntilan",
                "countryCode": "ID"
            },
            {
                "name": "Ngemplak",
                "countryCode": "ID"
            },
            {
                "name": "Pati",
                "countryCode": "ID"
            },
            {
                "name": "Pecangaan",
                "countryCode": "ID"
            },
            {
                "name": "Pekalongan",
                "countryCode": "ID"
            },
            {
                "name": "Pemalang",
                "countryCode": "ID"
            },
            {
                "name": "Purbalingga",
                "countryCode": "ID"
            },
            {
                "name": "Purwodadi",
                "countryCode": "ID"
            },
            {
                "name": "Purwokerto",
                "countryCode": "ID"
            },
            {
                "name": "Randudongkal",
                "countryCode": "ID"
            },
            {
                "name": "Rembangan",
                "countryCode": "ID"
            },
            {
                "name": "Salatiga",
                "countryCode": "ID"
            },
            {
                "name": "Selogiri",
                "countryCode": "ID"
            },
            {
                "name": "Semarang",
                "countryCode": "ID"
            },
            {
                "name": "Sidareja",
                "countryCode": "ID"
            },
            {
                "name": "Slawi",
                "countryCode": "ID"
            },
            {
                "name": "Sokaraja",
                "countryCode": "ID"
            },
            {
                "name": "Sragen",
                "countryCode": "ID"
            },
            {
                "name": "Surakarta",
                "countryCode": "ID"
            },
            {
                "name": "Tarub",
                "countryCode": "ID"
            },
            {
                "name": "Tayu",
                "countryCode": "ID"
            },
            {
                "name": "Tegal",
                "countryCode": "ID"
            },
            {
                "name": "Trucuk",
                "countryCode": "ID"
            },
            {
                "name": "Ungaran",
                "countryCode": "ID"
            },
            {
                "name": "Wangon",
                "countryCode": "ID"
            },
            {
                "name": "Wedi",
                "countryCode": "ID"
            },
            {
                "name": "Welahan",
                "countryCode": "ID"
            },
            {
                "name": "Weleri",
                "countryCode": "ID"
            },
            {
                "name": "Wiradesa",
                "countryCode": "ID"
            },
            {
                "name": "Wonopringgo",
                "countryCode": "ID"
            },
            {
                "name": "Wonosobo",
                "countryCode": "ID"
            },
            {
                "name": "Balikpapan",
                "countryCode": "ID"
            },
            {
                "name": "Bontang",
                "countryCode": "ID"
            },
            {
                "name": "City of Balikpapan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Berau",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kutai Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kutai Kartanegara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kutai Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mahakam Hulu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Paser",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Penajam Paser Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kota Balikpapan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bontang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Samarinda",
                "countryCode": "ID"
            },
            {
                "name": "Loa Janan",
                "countryCode": "ID"
            },
            {
                "name": "Samarinda",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bintan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Karimun",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Anambas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lingga",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Natuna",
                "countryCode": "ID"
            },
            {
                "name": "Kijang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Batam",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tanjung Pinang",
                "countryCode": "ID"
            },
            {
                "name": "Tanjung Pinang",
                "countryCode": "ID"
            },
            {
                "name": "Amuntai",
                "countryCode": "ID"
            },
            {
                "name": "Banjarmasin",
                "countryCode": "ID"
            },
            {
                "name": "Barabai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Balangan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banjar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Barito Kuala",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Hulu Sungai Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Hulu Sungai Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Hulu Sungai Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kota Baru",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tabalong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanah Bumbu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanah Laut",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tapin",
                "countryCode": "ID"
            },
            {
                "name": "Kota Banjar Baru",
                "countryCode": "ID"
            },
            {
                "name": "Kota Banjarmasin",
                "countryCode": "ID"
            },
            {
                "name": "Martapura",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Barito Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Barito Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Barito Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gunung Mas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kapuas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Katingan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kotawaringin Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kotawaringin Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lamandau",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Murung Raya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pulang Pisau",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Seruyan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sukamara",
                "countryCode": "ID"
            },
            {
                "name": "Kota Palangka Raya",
                "countryCode": "ID"
            },
            {
                "name": "Kualakapuas",
                "countryCode": "ID"
            },
            {
                "name": "Palangkaraya",
                "countryCode": "ID"
            },
            {
                "name": "Pangkalanbuun",
                "countryCode": "ID"
            },
            {
                "name": "Sampit",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bulungan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Malinau",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nunukan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tana Tidung",
                "countryCode": "ID"
            },
            {
                "name": "Tanjung Selor",
                "countryCode": "ID"
            },
            {
                "name": "Tarakan",
                "countryCode": "ID"
            },
            {
                "name": "Bandar Lampung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lampung Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lampung Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lampung Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lampung Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lampung Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mesuji",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pesawaran",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pesisir Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pringsewu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanggamus",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tulangbawang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Way Kanan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bandar Lampung",
                "countryCode": "ID"
            },
            {
                "name": "Kota Metro",
                "countryCode": "ID"
            },
            {
                "name": "Kotabumi",
                "countryCode": "ID"
            },
            {
                "name": "Metro",
                "countryCode": "ID"
            },
            {
                "name": "Terbanggi Besar",
                "countryCode": "ID"
            },
            {
                "name": "Amahai",
                "countryCode": "ID"
            },
            {
                "name": "Ambon",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buru",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buru Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Aru",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maluku Barat Daya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maluku Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maluku Tenggara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maluku Tenggara Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Seram Bagian Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Seram Bagian Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kota Ambon",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tual",
                "countryCode": "ID"
            },
            {
                "name": "Tual",
                "countryCode": "ID"
            },
            {
                "name": "East Halmahera Regency",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Halmahera Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Halmahera Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Halmahera Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Halmahera Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Sula",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pulau Morotai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pulau Taliabu",
                "countryCode": "ID"
            },
            {
                "name": "Kota Ternate",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tidore Kepulauan",
                "countryCode": "ID"
            },
            {
                "name": "Sofifi",
                "countryCode": "ID"
            },
            {
                "name": "Ternate",
                "countryCode": "ID"
            },
            {
                "name": "Tobelo",
                "countryCode": "ID"
            },
            {
                "name": "Bima",
                "countryCode": "ID"
            },
            {
                "name": "Dompu",
                "countryCode": "ID"
            },
            {
                "name": "Gili Air",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bima",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Dompu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lombok Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lombok Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lombok Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lombok Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumbawa",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumbawa Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bima",
                "countryCode": "ID"
            },
            {
                "name": "Kota Mataram",
                "countryCode": "ID"
            },
            {
                "name": "Labuan Lombok",
                "countryCode": "ID"
            },
            {
                "name": "Lembar",
                "countryCode": "ID"
            },
            {
                "name": "Mataram",
                "countryCode": "ID"
            },
            {
                "name": "Pemenang",
                "countryCode": "ID"
            },
            {
                "name": "Pototano",
                "countryCode": "ID"
            },
            {
                "name": "Praya",
                "countryCode": "ID"
            },
            {
                "name": "Senggigi",
                "countryCode": "ID"
            },
            {
                "name": "Sumbawa Besar",
                "countryCode": "ID"
            },
            {
                "name": "Atambua",
                "countryCode": "ID"
            },
            {
                "name": "Ende",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Alor",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Belu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ende",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Flores Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kupang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lembata",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Malaka",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Manggarai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Manggarai Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Manggarai Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nagekeo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ngada",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Rote Ndao",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sabu Raijua",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sikka",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumba Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumba Barat Daya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumba Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sumba Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Timor Tengah Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Timor Tengah Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kefamenanu",
                "countryCode": "ID"
            },
            {
                "name": "Komodo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Kupang",
                "countryCode": "ID"
            },
            {
                "name": "Kupang",
                "countryCode": "ID"
            },
            {
                "name": "Labuan Bajo",
                "countryCode": "ID"
            },
            {
                "name": "Maumere",
                "countryCode": "ID"
            },
            {
                "name": "Naisano Dua",
                "countryCode": "ID"
            },
            {
                "name": "Ruteng",
                "countryCode": "ID"
            },
            {
                "name": "Soe",
                "countryCode": "ID"
            },
            {
                "name": "Waingapu",
                "countryCode": "ID"
            },
            {
                "name": "Abepura",
                "countryCode": "ID"
            },
            {
                "name": "Biak",
                "countryCode": "ID"
            },
            {
                "name": "Insrom",
                "countryCode": "ID"
            },
            {
                "name": "Jayapura",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Asmat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Biak Numfor",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Boven Digoel",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Deiyai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Dogiyai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Intan Jaya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jayapura",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jayawijaya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Keerom",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Yapen",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lanny Jaya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamberamo Raya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamberamo Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mappi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Merauke",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mimika",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nabire",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nduga",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Paniai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pegunungan Bintang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Puncak Jaya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sarmi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Supiori",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tolikara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Waropen",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Yahukimo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Yalimo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Jayapura",
                "countryCode": "ID"
            },
            {
                "name": "Nabire",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Fakfak",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kaimana",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Manokwari",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Manokwari Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maybrat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Raja Ampat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sorong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sorong Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tambrauw",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Teluk Bintuni",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Teluk Wondama",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sorong",
                "countryCode": "ID"
            },
            {
                "name": "Manokwari",
                "countryCode": "ID"
            },
            {
                "name": "Sorong",
                "countryCode": "ID"
            },
            {
                "name": "Balaipungut",
                "countryCode": "ID"
            },
            {
                "name": "Batam",
                "countryCode": "ID"
            },
            {
                "name": "Dumai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bengkalis",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Indragiri Hilir",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Indragiri Hulu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kampar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Meranti",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kuantan Singingi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pelalawan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Rokan Hilir",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Rokan Hulu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Siak",
                "countryCode": "ID"
            },
            {
                "name": "Kota Dumai",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pekanbaru",
                "countryCode": "ID"
            },
            {
                "name": "Pekanbaru",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bolaang Mongondow",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bolaang Mongondow Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bolaang Mongondow Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bolaang Mongondow Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Sangihe",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Talaud",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Minahasa",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Minahasa Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Minahasa Tenggara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Minahasa Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Siau Tagulandang Biaro",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bitung",
                "countryCode": "ID"
            },
            {
                "name": "Kota Kotamobagu",
                "countryCode": "ID"
            },
            {
                "name": "Kota Manado",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tomohon",
                "countryCode": "ID"
            },
            {
                "name": "Laikit, Laikit II (Dimembe)",
                "countryCode": "ID"
            },
            {
                "name": "Manado",
                "countryCode": "ID"
            },
            {
                "name": "Tomohon",
                "countryCode": "ID"
            },
            {
                "name": "Tondano",
                "countryCode": "ID"
            },
            {
                "name": "Bukittinggi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Agam",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Dharmasraya",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kepulauan Mentawai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Lima Puluh Kota",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Padang Pariaman",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pasaman",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pasaman Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pesisir Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sijunjung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Solok",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Solok Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tanah Datar",
                "countryCode": "ID"
            },
            {
                "name": "Kota Bukittinggi",
                "countryCode": "ID"
            },
            {
                "name": "Kota Padang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Padang Panjang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pariaman",
                "countryCode": "ID"
            },
            {
                "name": "Kota Payakumbuh",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sawah Lunto",
                "countryCode": "ID"
            },
            {
                "name": "Kota Solok",
                "countryCode": "ID"
            },
            {
                "name": "Padang",
                "countryCode": "ID"
            },
            {
                "name": "Pariaman",
                "countryCode": "ID"
            },
            {
                "name": "Payakumbuh",
                "countryCode": "ID"
            },
            {
                "name": "Sijunjung",
                "countryCode": "ID"
            },
            {
                "name": "Solok",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bombana",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buton",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buton Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buton Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buton Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kolaka",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kolaka Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kolaka Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Konawe",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Konawe Kepulauan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Konawe Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Konawe Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Muna",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Muna Barat",
                "countryCode": "ID"
            },
            {
                "name": "Katabu",
                "countryCode": "ID"
            },
            {
                "name": "Kendari",
                "countryCode": "ID"
            },
            {
                "name": "Kota Baubau",
                "countryCode": "ID"
            },
            {
                "name": "Kota Kendari",
                "countryCode": "ID"
            },
            {
                "name": "Wakatobi Regency",
                "countryCode": "ID"
            },
            {
                "name": "Galesong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bantaeng",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Barru",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bone",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bulukumba",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Enrekang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gowa",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Jeneponto",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Luwu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Luwu Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Luwu Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Maros",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pangkajene Dan Kepulauan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pinrang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sidenreng Rappang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sinjai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Soppeng",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Takalar",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tana Toraja",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Toraja Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Wajo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Makassar",
                "countryCode": "ID"
            },
            {
                "name": "Kota Palopo",
                "countryCode": "ID"
            },
            {
                "name": "Kota Parepare",
                "countryCode": "ID"
            },
            {
                "name": "Makassar",
                "countryCode": "ID"
            },
            {
                "name": "Maros",
                "countryCode": "ID"
            },
            {
                "name": "Palopo",
                "countryCode": "ID"
            },
            {
                "name": "Parepare",
                "countryCode": "ID"
            },
            {
                "name": "Rantepao",
                "countryCode": "ID"
            },
            {
                "name": "Selayar Islands Regency",
                "countryCode": "ID"
            },
            {
                "name": "Sengkang",
                "countryCode": "ID"
            },
            {
                "name": "Sinjai",
                "countryCode": "ID"
            },
            {
                "name": "Watampone",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Majene",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamasa",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamuju",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamuju Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mamuju Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Polewali Mandar",
                "countryCode": "ID"
            },
            {
                "name": "Majene",
                "countryCode": "ID"
            },
            {
                "name": "Mamuju",
                "countryCode": "ID"
            },
            {
                "name": "Polewali",
                "countryCode": "ID"
            },
            {
                "name": "Baturaja",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Empat Lawang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Muara Enim",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Musi Banyuasin",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Musi Rawas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Musi Rawas Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ogan Ilir",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ogan Komering Ilir",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ogan Komering Ulu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ogan Komering Ulu Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Ogan Komering Ulu Timur",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Penukal Abab Lematang Ilir",
                "countryCode": "ID"
            },
            {
                "name": "Kota Lubuklinggau",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pagar Alam",
                "countryCode": "ID"
            },
            {
                "name": "Kota Palembang",
                "countryCode": "ID"
            },
            {
                "name": "Kota Prabumulih",
                "countryCode": "ID"
            },
            {
                "name": "Lahat",
                "countryCode": "ID"
            },
            {
                "name": "Lahat Regency",
                "countryCode": "ID"
            },
            {
                "name": "Lubuklinggau",
                "countryCode": "ID"
            },
            {
                "name": "Pagar Alam",
                "countryCode": "ID"
            },
            {
                "name": "Palembang",
                "countryCode": "ID"
            },
            {
                "name": "Prabumulih",
                "countryCode": "ID"
            },
            {
                "name": "Tanjungagung",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banggai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banggai Kepulauan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Banggai Laut",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Buol",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Donggala",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Morowali Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Parigi Moutong",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Poso",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sigi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Toli-Toli",
                "countryCode": "ID"
            },
            {
                "name": "Kota Palu",
                "countryCode": "ID"
            },
            {
                "name": "Luwuk",
                "countryCode": "ID"
            },
            {
                "name": "Morowali Regency",
                "countryCode": "ID"
            },
            {
                "name": "Palu",
                "countryCode": "ID"
            },
            {
                "name": "Poso",
                "countryCode": "ID"
            },
            {
                "name": "Tojo Una-Una Regency",
                "countryCode": "ID"
            },
            {
                "name": "Ambarita",
                "countryCode": "ID"
            },
            {
                "name": "Bandar",
                "countryCode": "ID"
            },
            {
                "name": "Belawan",
                "countryCode": "ID"
            },
            {
                "name": "Berastagi",
                "countryCode": "ID"
            },
            {
                "name": "Binjai",
                "countryCode": "ID"
            },
            {
                "name": "Deli Tua",
                "countryCode": "ID"
            },
            {
                "name": "Gunungsitoli",
                "countryCode": "ID"
            },
            {
                "name": "Kabanjahe",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Asahan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Batu Bara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Dairi",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Deli Serdang",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Humbang Hasundutan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Karo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Labuhan Batu",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Labuhan Batu Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Labuhan Batu Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Langkat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Mandailing Natal",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nias",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nias Barat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Nias Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Padang Lawas",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Padang Lawas Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Pakpak Bharat",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Samosir",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Serdang Bedagai",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Simalungun",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tapanuli Selatan",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tapanuli Tengah",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Tapanuli Utara",
                "countryCode": "ID"
            },
            {
                "name": "Kisaran",
                "countryCode": "ID"
            },
            {
                "name": "Kota Binjai",
                "countryCode": "ID"
            },
            {
                "name": "Kota Gunungsitoli",
                "countryCode": "ID"
            },
            {
                "name": "Kota Medan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Padangsidimpuan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Pematang Siantar",
                "countryCode": "ID"
            },
            {
                "name": "Kota Sibolga",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tanjung Balai",
                "countryCode": "ID"
            },
            {
                "name": "Kota Tebing Tinggi",
                "countryCode": "ID"
            },
            {
                "name": "Labuhan Deli",
                "countryCode": "ID"
            },
            {
                "name": "Medan",
                "countryCode": "ID"
            },
            {
                "name": "Padangsidempuan",
                "countryCode": "ID"
            },
            {
                "name": "Pangkalan Brandan",
                "countryCode": "ID"
            },
            {
                "name": "Parapat",
                "countryCode": "ID"
            },
            {
                "name": "Pekan Bahapal",
                "countryCode": "ID"
            },
            {
                "name": "Pematangsiantar",
                "countryCode": "ID"
            },
            {
                "name": "Perbaungan",
                "countryCode": "ID"
            },
            {
                "name": "Percut",
                "countryCode": "ID"
            },
            {
                "name": "Rantauprapat",
                "countryCode": "ID"
            },
            {
                "name": "Sibolga",
                "countryCode": "ID"
            },
            {
                "name": "Stabat",
                "countryCode": "ID"
            },
            {
                "name": "Sunggal",
                "countryCode": "ID"
            },
            {
                "name": "Tanjungbalai",
                "countryCode": "ID"
            },
            {
                "name": "Tanjungtiram",
                "countryCode": "ID"
            },
            {
                "name": "Tebingtinggi",
                "countryCode": "ID"
            },
            {
                "name": "Teluk Nibung",
                "countryCode": "ID"
            },
            {
                "name": "Tomok Bolon",
                "countryCode": "ID"
            },
            {
                "name": "Tongging",
                "countryCode": "ID"
            },
            {
                "name": "Tuktuk Sonak",
                "countryCode": "ID"
            },
            {
                "name": "Bambanglipuro",
                "countryCode": "ID"
            },
            {
                "name": "Bantul",
                "countryCode": "ID"
            },
            {
                "name": "Depok",
                "countryCode": "ID"
            },
            {
                "name": "Gamping Lor",
                "countryCode": "ID"
            },
            {
                "name": "Godean",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Bantul",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Gunung Kidul",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Kulon Progo",
                "countryCode": "ID"
            },
            {
                "name": "Kabupaten Sleman",
                "countryCode": "ID"
            },
            {
                "name": "Kasihan",
                "countryCode": "ID"
            },
            {
                "name": "Kota Yogyakarta",
                "countryCode": "ID"
            },
            {
                "name": "Melati",
                "countryCode": "ID"
            },
            {
                "name": "Pandak",
                "countryCode": "ID"
            },
            {
                "name": "Pundong",
                "countryCode": "ID"
            },
            {
                "name": "Sewon",
                "countryCode": "ID"
            },
            {
                "name": "Sleman",
                "countryCode": "ID"
            },
            {
                "name": "Srandakan",
                "countryCode": "ID"
            },
            {
                "name": "Yogyakarta",
                "countryCode": "ID"
            }
        ]
    },
    {
        "name": "Iran",
        "phonecode": "98",
        "cities": [
            {
                "name": "Arāk",
                "countryCode": "IR"
            },
            {
                "name": "Delījān",
                "countryCode": "IR"
            },
            {
                "name": "Khomeyn",
                "countryCode": "IR"
            },
            {
                "name": "Komījān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Arāk",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Delījān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Farahān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khondāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Komījān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Maḩallāt",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shāzand",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sāveh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zarandīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āshtīān",
                "countryCode": "IR"
            },
            {
                "name": "Sāveh",
                "countryCode": "IR"
            },
            {
                "name": "Tafresh",
                "countryCode": "IR"
            },
            {
                "name": "Ābyek",
                "countryCode": "IR"
            },
            {
                "name": "Bandar-e Anzalī",
                "countryCode": "IR"
            },
            {
                "name": "Fūman",
                "countryCode": "IR"
            },
            {
                "name": "Hashtpar",
                "countryCode": "IR"
            },
            {
                "name": "Langarūd",
                "countryCode": "IR"
            },
            {
                "name": "Pādegān-e Manjīl",
                "countryCode": "IR"
            },
            {
                "name": "Rasht",
                "countryCode": "IR"
            },
            {
                "name": "Reẕvānshahr",
                "countryCode": "IR"
            },
            {
                "name": "Rūdsar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bandar-e Anzalī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fūman",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Langarūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lāhījān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Māsāl",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Reẕvānshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rūdbār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rūdsar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sīāhkal",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tālesh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āstāneh-ye Ashrafīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āstārā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Şowme‘eh Sarā",
                "countryCode": "IR"
            },
            {
                "name": "Ziabar (Gaskar)",
                "countryCode": "IR"
            },
            {
                "name": "Āstāneh-ye Ashrafīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Āstārā",
                "countryCode": "IR"
            },
            {
                "name": "Behshahr",
                "countryCode": "IR"
            },
            {
                "name": "Bābol",
                "countryCode": "IR"
            },
            {
                "name": "Bābolsar",
                "countryCode": "IR"
            },
            {
                "name": "Chālūs",
                "countryCode": "IR"
            },
            {
                "name": "Fereydūnkenār",
                "countryCode": "IR"
            },
            {
                "name": "Jūybār",
                "countryCode": "IR"
            },
            {
                "name": "Nashtārūd",
                "countryCode": "IR"
            },
            {
                "name": "Nekā",
                "countryCode": "IR"
            },
            {
                "name": "Nowshahr",
                "countryCode": "IR"
            },
            {
                "name": "Sari",
                "countryCode": "IR"
            },
            {
                "name": "Savadkuh-e Shomali",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestan-e Kalār Dasht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bābol",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bābolsar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chālūs",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fareydūnkenār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Galūgāh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Jūybār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Maḩmūdābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mīāndorūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nekā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nūr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qā’em Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rāmsar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Savādkūh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sārī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tonekābon",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āmol",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e ‘Abbāsābād",
                "countryCode": "IR"
            },
            {
                "name": "Simorgh County",
                "countryCode": "IR"
            },
            {
                "name": "Tonekābon",
                "countryCode": "IR"
            },
            {
                "name": "Āmol",
                "countryCode": "IR"
            },
            {
                "name": "Ahar",
                "countryCode": "IR"
            },
            {
                "name": "Bonāb",
                "countryCode": "IR"
            },
            {
                "name": "Hashtrūd",
                "countryCode": "IR"
            },
            {
                "name": "Marand",
                "countryCode": "IR"
            },
            {
                "name": "Sarāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bonāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bostānābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chārāvīmāq",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Hashtrūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Herīs",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Jolfā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khodā Āfarīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Malekān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Marāgheh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mīāneh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Oskū",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tabrīz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Varzaqān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āz̄arshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e ‘Ajab Shīr",
                "countryCode": "IR"
            },
            {
                "name": "Tabriz",
                "countryCode": "IR"
            },
            {
                "name": "‘Ajab Shīr",
                "countryCode": "IR"
            },
            {
                "name": "Būkān",
                "countryCode": "IR"
            },
            {
                "name": "Chāypāreh",
                "countryCode": "IR"
            },
            {
                "name": "Khowy",
                "countryCode": "IR"
            },
            {
                "name": "Mahābād",
                "countryCode": "IR"
            },
            {
                "name": "Mīāndoāb",
                "countryCode": "IR"
            },
            {
                "name": "Naqadeh",
                "countryCode": "IR"
            },
            {
                "name": "Orūmīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Oshnavīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Piranshahr",
                "countryCode": "IR"
            },
            {
                "name": "Poldasht",
                "countryCode": "IR"
            },
            {
                "name": "Qarah Ẕīā’ od Dīn",
                "countryCode": "IR"
            },
            {
                "name": "Salmās",
                "countryCode": "IR"
            },
            {
                "name": "Sardasht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Būkān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chāldorān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mahābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mākū",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mīāndoāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Orūmīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Oshnavīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pīrānshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Salmās",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shāhīn Dezh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Takāb",
                "countryCode": "IR"
            },
            {
                "name": "Shāhīn Dezh",
                "countryCode": "IR"
            },
            {
                "name": "Takāb",
                "countryCode": "IR"
            },
            {
                "name": "Harsīn",
                "countryCode": "IR"
            },
            {
                "name": "Javānrūd",
                "countryCode": "IR"
            },
            {
                "name": "Kahrīz",
                "countryCode": "IR"
            },
            {
                "name": "Kangāvar",
                "countryCode": "IR"
            },
            {
                "name": "Kermanshah",
                "countryCode": "IR"
            },
            {
                "name": "Pāveh",
                "countryCode": "IR"
            },
            {
                "name": "Sarpol-e Z̄ahāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dālāhū",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eslāmābād-e Gharb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gīlān-e Gharb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Harsīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Javānrūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kangāvar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kermānshāh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pāveh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qaşr-e Shīrīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ravānsar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarpol-e Z̄ahāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e S̄alās̄-e Bābā Jānī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Şaḩneh",
                "countryCode": "IR"
            },
            {
                "name": "Sonqor",
                "countryCode": "IR"
            },
            {
                "name": "Abadan",
                "countryCode": "IR"
            },
            {
                "name": "Aghajari",
                "countryCode": "IR"
            },
            {
                "name": "Ahvaz",
                "countryCode": "IR"
            },
            {
                "name": "Behbahān",
                "countryCode": "IR"
            },
            {
                "name": "Bāgh Shahrestān-e Malek",
                "countryCode": "IR"
            },
            {
                "name": "Gotvand",
                "countryCode": "IR"
            },
            {
                "name": "Hamidiyeh",
                "countryCode": "IR"
            },
            {
                "name": "Jongīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Karun",
                "countryCode": "IR"
            },
            {
                "name": "Khorramshahr",
                "countryCode": "IR"
            },
            {
                "name": "Masjed Soleymān",
                "countryCode": "IR"
            },
            {
                "name": "Omīdīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Rāmhormoz",
                "countryCode": "IR"
            },
            {
                "name": "Rāmshīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrak-e Kūlūrī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ahvāz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Andīkā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Andīmeshk",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bandar-e Māhshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Behbahān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bāvī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dasht-e Āzādegān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dezfūl",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Hendījān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lālī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Masjed Soleymān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Omīdīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rāmhormoz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rāmshīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shādegān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shūsh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shūshtar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ābādān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Īz̄eh",
                "countryCode": "IR"
            },
            {
                "name": "Shādegān",
                "countryCode": "IR"
            },
            {
                "name": "Shūsh",
                "countryCode": "IR"
            },
            {
                "name": "Shūshtar",
                "countryCode": "IR"
            },
            {
                "name": "Sūsangerd",
                "countryCode": "IR"
            },
            {
                "name": "sedeyen-e Yek",
                "countryCode": "IR"
            },
            {
                "name": "Akbarābād",
                "countryCode": "IR"
            },
            {
                "name": "Dārāb",
                "countryCode": "IR"
            },
            {
                "name": "Fasā",
                "countryCode": "IR"
            },
            {
                "name": "Fīrūzābād",
                "countryCode": "IR"
            },
            {
                "name": "Gerāsh",
                "countryCode": "IR"
            },
            {
                "name": "Kāzerūn",
                "countryCode": "IR"
            },
            {
                "name": "Mamasanī",
                "countryCode": "IR"
            },
            {
                "name": "Marvdasht",
                "countryCode": "IR"
            },
            {
                "name": "Mohr",
                "countryCode": "IR"
            },
            {
                "name": "Neyrīz",
                "countryCode": "IR"
            },
            {
                "name": "Nūrābād",
                "countryCode": "IR"
            },
            {
                "name": "Pāsārgād",
                "countryCode": "IR"
            },
            {
                "name": "Rostam",
                "countryCode": "IR"
            },
            {
                "name": "Shahr-e Qadīm-e Lār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Arsanjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bavānāt",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dārāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eqlīd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Estahbān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Farāshband",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fasā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fīrūzābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gerāsh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kavār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kherāmeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khorrambīd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kāzerūn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lāmerd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lārestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Neyrīz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pāsārgād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qīr va Kārzīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarvestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sepīdān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shīrāz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zarrīn Dasht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ābādeh",
                "countryCode": "IR"
            },
            {
                "name": "Shiraz",
                "countryCode": "IR"
            },
            {
                "name": "Ābādeh",
                "countryCode": "IR"
            },
            {
                "name": "Bam",
                "countryCode": "IR"
            },
            {
                "name": "Bardsīr",
                "countryCode": "IR"
            },
            {
                "name": "Bāft",
                "countryCode": "IR"
            },
            {
                "name": "Kerman",
                "countryCode": "IR"
            },
            {
                "name": "Kūh Sefīd",
                "countryCode": "IR"
            },
            {
                "name": "Rafsanjān",
                "countryCode": "IR"
            },
            {
                "name": "Rāvar",
                "countryCode": "IR"
            },
            {
                "name": "Rīgān",
                "countryCode": "IR"
            },
            {
                "name": "Shahr-e Bābak",
                "countryCode": "IR"
            },
            {
                "name": "Shahrak-e Pābedānā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Anār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bardsīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bāft",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fāryāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Jīroft",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kahnūj",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kermān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kūhbanān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Manūjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Narmāshīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Orzū‘īyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qal‘eh Ganj",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rafsanjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rābor",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rūdbār-e Jonūbī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shahr-e Bābak",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sīrjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e ‘Anbarābād",
                "countryCode": "IR"
            },
            {
                "name": "Sirjan",
                "countryCode": "IR"
            },
            {
                "name": "Zarand",
                "countryCode": "IR"
            },
            {
                "name": "Bardaskan",
                "countryCode": "IR"
            },
            {
                "name": "Chenārān",
                "countryCode": "IR"
            },
            {
                "name": "Dargaz",
                "countryCode": "IR"
            },
            {
                "name": "Dāvarzan",
                "countryCode": "IR"
            },
            {
                "name": "Gonābād",
                "countryCode": "IR"
            },
            {
                "name": "Joveyn",
                "countryCode": "IR"
            },
            {
                "name": "Kāshmar",
                "countryCode": "IR"
            },
            {
                "name": "Mashhad",
                "countryCode": "IR"
            },
            {
                "name": "Neyshābūr",
                "countryCode": "IR"
            },
            {
                "name": "Qūchān",
                "countryCode": "IR"
            },
            {
                "name": "Sabzevar",
                "countryCode": "IR"
            },
            {
                "name": "Sarakhs",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bajestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bākharz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bīnālūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chenārān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Farīmān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fīrūzeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gonābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Joghatāy",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kalāt",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khalīlābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khowshāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khvāf",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kāshmar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mah Velāt",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Neyshābūr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qūchān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Roshtkhvār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sabzevār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Torbat-e Jām",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Torbat-e Ḩeydarīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tāybād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zāveh",
                "countryCode": "IR"
            },
            {
                "name": "Torbat-e Jām",
                "countryCode": "IR"
            },
            {
                "name": "Torbat-e Ḩeydarīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Tāybād",
                "countryCode": "IR"
            },
            {
                "name": "Abrīsham",
                "countryCode": "IR"
            },
            {
                "name": "Ardestān",
                "countryCode": "IR"
            },
            {
                "name": "Buin va Miandasht",
                "countryCode": "IR"
            },
            {
                "name": "Chādegān",
                "countryCode": "IR"
            },
            {
                "name": "Dehāqān",
                "countryCode": "IR"
            },
            {
                "name": "Dorcheh Pīāz",
                "countryCode": "IR"
            },
            {
                "name": "Dowlatābād",
                "countryCode": "IR"
            },
            {
                "name": "Dārān",
                "countryCode": "IR"
            },
            {
                "name": "Falāvarjān",
                "countryCode": "IR"
            },
            {
                "name": "Fareydūnshahr",
                "countryCode": "IR"
            },
            {
                "name": "Fereydan",
                "countryCode": "IR"
            },
            {
                "name": "Golpāyegān",
                "countryCode": "IR"
            },
            {
                "name": "Isfahan",
                "countryCode": "IR"
            },
            {
                "name": "Kelīshād va Sūdarjān",
                "countryCode": "IR"
            },
            {
                "name": "Khomeynī Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Khvānsār",
                "countryCode": "IR"
            },
            {
                "name": "Khūr",
                "countryCode": "IR"
            },
            {
                "name": "Mobārakeh",
                "countryCode": "IR"
            },
            {
                "name": "Najafābād",
                "countryCode": "IR"
            },
            {
                "name": "Naţanz",
                "countryCode": "IR"
            },
            {
                "name": "Nā’īn",
                "countryCode": "IR"
            },
            {
                "name": "Qahderījān",
                "countryCode": "IR"
            },
            {
                "name": "Rehnān",
                "countryCode": "IR"
            },
            {
                "name": "Semīrom",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ardestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Borkhvār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chādegān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dehāqān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eşfahān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Falāvarjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fareydūnshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Golpāyegān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khomeynī Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khvānsār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khūr va Bīābānak",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kāshān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lenjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mobārakeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Najafābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Naţanz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nā’īn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Semīrom",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shahreẕā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shāhīn Shahr va Meymeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tīrān va Karvan",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ārān va Bīdgol",
                "countryCode": "IR"
            },
            {
                "name": "Shahreẕā",
                "countryCode": "IR"
            },
            {
                "name": "Shāhīn Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Tīrān",
                "countryCode": "IR"
            },
            {
                "name": "Zarrīn Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Chabahar",
                "countryCode": "IR"
            },
            {
                "name": "Fannūj",
                "countryCode": "IR"
            },
            {
                "name": "Fanuj",
                "countryCode": "IR"
            },
            {
                "name": "Hamoon",
                "countryCode": "IR"
            },
            {
                "name": "Iranshahr",
                "countryCode": "IR"
            },
            {
                "name": "Khāsh",
                "countryCode": "IR"
            },
            {
                "name": "Mirjaveh",
                "countryCode": "IR"
            },
            {
                "name": "Nimruz",
                "countryCode": "IR"
            },
            {
                "name": "Noşratābād",
                "countryCode": "IR"
            },
            {
                "name": "Nīkshahr",
                "countryCode": "IR"
            },
            {
                "name": "Qaser-e Qand",
                "countryCode": "IR"
            },
            {
                "name": "Qaşr-e Qand",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Chābahār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dalgān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Hīrmand",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khāsh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Konārak",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mehrestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nīkshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarbāz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarāvān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sīb va Sūrān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zābol",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zāhedān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Īrānshahr",
                "countryCode": "IR"
            },
            {
                "name": "Zahedan",
                "countryCode": "IR"
            },
            {
                "name": "Zehak",
                "countryCode": "IR"
            },
            {
                "name": "Zābol",
                "countryCode": "IR"
            },
            {
                "name": "Bāneh",
                "countryCode": "IR"
            },
            {
                "name": "Bījār",
                "countryCode": "IR"
            },
            {
                "name": "Kāmyārān",
                "countryCode": "IR"
            },
            {
                "name": "Marīvān",
                "countryCode": "IR"
            },
            {
                "name": "Qorveh",
                "countryCode": "IR"
            },
            {
                "name": "Sanandaj",
                "countryCode": "IR"
            },
            {
                "name": "Saqqez",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bāneh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bījār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dehgolān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dīvāndarreh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kāmyārān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Marīvān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarvābād",
                "countryCode": "IR"
            },
            {
                "name": "Ab Meshkin",
                "countryCode": "IR"
            },
            {
                "name": "Abarlaq-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Abdalan",
                "countryCode": "IR"
            },
            {
                "name": "Abdol Rahim",
                "countryCode": "IR"
            },
            {
                "name": "Abrumand",
                "countryCode": "IR"
            },
            {
                "name": "Abshineh",
                "countryCode": "IR"
            },
            {
                "name": "Abu ol Fathabad",
                "countryCode": "IR"
            },
            {
                "name": "Aghcheh Qayah",
                "countryCode": "IR"
            },
            {
                "name": "Ahmadabad-e Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Ahu Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Ajin",
                "countryCode": "IR"
            },
            {
                "name": "Ajorlu",
                "countryCode": "IR"
            },
            {
                "name": "Akanlu",
                "countryCode": "IR"
            },
            {
                "name": "Akleh",
                "countryCode": "IR"
            },
            {
                "name": "Alan-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Alanjeh",
                "countryCode": "IR"
            },
            {
                "name": "Alfavut",
                "countryCode": "IR"
            },
            {
                "name": "Aliabad-e Posht Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Amirabad-e Kord",
                "countryCode": "IR"
            },
            {
                "name": "Amzajerd",
                "countryCode": "IR"
            },
            {
                "name": "Angoshteh",
                "countryCode": "IR"
            },
            {
                "name": "Anjir Baghi",
                "countryCode": "IR"
            },
            {
                "name": "Ansar ol Emam",
                "countryCode": "IR"
            },
            {
                "name": "Anuch",
                "countryCode": "IR"
            },
            {
                "name": "Aq Bolagh-e Aqdaq",
                "countryCode": "IR"
            },
            {
                "name": "Aq Bolagh-e Latgah",
                "countryCode": "IR"
            },
            {
                "name": "Aq Bolagh-e Morshed",
                "countryCode": "IR"
            },
            {
                "name": "Aq Chay",
                "countryCode": "IR"
            },
            {
                "name": "Aqa Bolaghi",
                "countryCode": "IR"
            },
            {
                "name": "Aqa Jan Bolaghi",
                "countryCode": "IR"
            },
            {
                "name": "Aqaj",
                "countryCode": "IR"
            },
            {
                "name": "Aqcheh Kharabeh",
                "countryCode": "IR"
            },
            {
                "name": "Aqchelu",
                "countryCode": "IR"
            },
            {
                "name": "Arges-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Arikan",
                "countryCode": "IR"
            },
            {
                "name": "Arpa Darreh",
                "countryCode": "IR"
            },
            {
                "name": "Arteh Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Artiman",
                "countryCode": "IR"
            },
            {
                "name": "Arzan Fud",
                "countryCode": "IR"
            },
            {
                "name": "Arzuvaj",
                "countryCode": "IR"
            },
            {
                "name": "Asadabad",
                "countryCode": "IR"
            },
            {
                "name": "Ashmizan",
                "countryCode": "IR"
            },
            {
                "name": "Avarzaman",
                "countryCode": "IR"
            },
            {
                "name": "Azandarian",
                "countryCode": "IR"
            },
            {
                "name": "Aznahri",
                "countryCode": "IR"
            },
            {
                "name": "Baba Khanjar",
                "countryCode": "IR"
            },
            {
                "name": "Baba Pir",
                "countryCode": "IR"
            },
            {
                "name": "Baba Qasem",
                "countryCode": "IR"
            },
            {
                "name": "Babolqani",
                "countryCode": "IR"
            },
            {
                "name": "Bad Khvoreh",
                "countryCode": "IR"
            },
            {
                "name": "Bahar",
                "countryCode": "IR"
            },
            {
                "name": "Band Aman",
                "countryCode": "IR"
            },
            {
                "name": "Banesareh",
                "countryCode": "IR"
            },
            {
                "name": "Baraband",
                "countryCode": "IR"
            },
            {
                "name": "Bargecheh",
                "countryCode": "IR"
            },
            {
                "name": "Barreh Farakh",
                "countryCode": "IR"
            },
            {
                "name": "Barzul",
                "countryCode": "IR"
            },
            {
                "name": "Bashqurtaran",
                "countryCode": "IR"
            },
            {
                "name": "Bazeran",
                "countryCode": "IR"
            },
            {
                "name": "Behkandan",
                "countryCode": "IR"
            },
            {
                "name": "Belartu",
                "countryCode": "IR"
            },
            {
                "name": "Beshik Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Biaj",
                "countryCode": "IR"
            },
            {
                "name": "Biatan-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Bid Korpeh-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Bid Korpeh-ye Vosta",
                "countryCode": "IR"
            },
            {
                "name": "Bifanaj",
                "countryCode": "IR"
            },
            {
                "name": "Bighash",
                "countryCode": "IR"
            },
            {
                "name": "Bish Aghaj",
                "countryCode": "IR"
            },
            {
                "name": "Bitervan",
                "countryCode": "IR"
            },
            {
                "name": "Borjaki",
                "countryCode": "IR"
            },
            {
                "name": "Boyukabad",
                "countryCode": "IR"
            },
            {
                "name": "Bozchalu",
                "countryCode": "IR"
            },
            {
                "name": "Bozineh Jerd",
                "countryCode": "IR"
            },
            {
                "name": "Bujin",
                "countryCode": "IR"
            },
            {
                "name": "Bustandar",
                "countryCode": "IR"
            },
            {
                "name": "Buyaqchi",
                "countryCode": "IR"
            },
            {
                "name": "Chahar Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Chal Boqa",
                "countryCode": "IR"
            },
            {
                "name": "Chaleh Kand",
                "countryCode": "IR"
            },
            {
                "name": "Charoq",
                "countryCode": "IR"
            },
            {
                "name": "Chasht Khvoreh",
                "countryCode": "IR"
            },
            {
                "name": "Chenari",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Malek",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Pahn-e Nanaj",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Qandab",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Qareh",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Qassaban",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Valad",
                "countryCode": "IR"
            },
            {
                "name": "Cheshmeh Zowraq",
                "countryCode": "IR"
            },
            {
                "name": "Choqa Sarahi",
                "countryCode": "IR"
            },
            {
                "name": "Chowtash",
                "countryCode": "IR"
            },
            {
                "name": "Chulak Qapanuri",
                "countryCode": "IR"
            },
            {
                "name": "Dahnejerd",
                "countryCode": "IR"
            },
            {
                "name": "Dali Chu",
                "countryCode": "IR"
            },
            {
                "name": "Damaq",
                "countryCode": "IR"
            },
            {
                "name": "Daqdaqabad",
                "countryCode": "IR"
            },
            {
                "name": "Dar Qeshlaq",
                "countryCode": "IR"
            },
            {
                "name": "Darani-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Darcham",
                "countryCode": "IR"
            },
            {
                "name": "Darreh Chenar",
                "countryCode": "IR"
            },
            {
                "name": "Darreh Mianeh-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Darreh Mirza",
                "countryCode": "IR"
            },
            {
                "name": "Darreh-ye Ebrahim",
                "countryCode": "IR"
            },
            {
                "name": "Darsibeh",
                "countryCode": "IR"
            },
            {
                "name": "Davijan",
                "countryCode": "IR"
            },
            {
                "name": "Deh Chaneh",
                "countryCode": "IR"
            },
            {
                "name": "Deh Dalian",
                "countryCode": "IR"
            },
            {
                "name": "Deh Ful",
                "countryCode": "IR"
            },
            {
                "name": "Deh Mianeh",
                "countryCode": "IR"
            },
            {
                "name": "Deh Nush",
                "countryCode": "IR"
            },
            {
                "name": "Deh Piaz",
                "countryCode": "IR"
            },
            {
                "name": "Deh-e Shaker",
                "countryCode": "IR"
            },
            {
                "name": "Dehnow-e Avarzaman",
                "countryCode": "IR"
            },
            {
                "name": "Dingeleh Kahriz",
                "countryCode": "IR"
            },
            {
                "name": "Do Rudan",
                "countryCode": "IR"
            },
            {
                "name": "Doraneh",
                "countryCode": "IR"
            },
            {
                "name": "Dorudgaran",
                "countryCode": "IR"
            },
            {
                "name": "Dow Baraleh",
                "countryCode": "IR"
            },
            {
                "name": "Dowlatabad-e Hajjilu",
                "countryCode": "IR"
            },
            {
                "name": "Dowlujerdin-e Bala",
                "countryCode": "IR"
            },
            {
                "name": "Durijan",
                "countryCode": "IR"
            },
            {
                "name": "Durnian",
                "countryCode": "IR"
            },
            {
                "name": "Emamzadeh Khatun",
                "countryCode": "IR"
            },
            {
                "name": "Emamzadeh Pir Nahan",
                "countryCode": "IR"
            },
            {
                "name": "Eskanan",
                "countryCode": "IR"
            },
            {
                "name": "Fahrumand",
                "countryCode": "IR"
            },
            {
                "name": "Famast",
                "countryCode": "IR"
            },
            {
                "name": "Famenin",
                "countryCode": "IR"
            },
            {
                "name": "Farasfaj",
                "countryCode": "IR"
            },
            {
                "name": "Farsban",
                "countryCode": "IR"
            },
            {
                "name": "Farvaz",
                "countryCode": "IR"
            },
            {
                "name": "Faryazan",
                "countryCode": "IR"
            },
            {
                "name": "Fasijan",
                "countryCode": "IR"
            },
            {
                "name": "Fiazaman",
                "countryCode": "IR"
            },
            {
                "name": "Firuzabad-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Firuzan",
                "countryCode": "IR"
            },
            {
                "name": "Gamasa",
                "countryCode": "IR"
            },
            {
                "name": "Gamasb",
                "countryCode": "IR"
            },
            {
                "name": "Ganj Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Ganjab",
                "countryCode": "IR"
            },
            {
                "name": "Gashani",
                "countryCode": "IR"
            },
            {
                "name": "Gav Zaban",
                "countryCode": "IR"
            },
            {
                "name": "Gavkaran",
                "countryCode": "IR"
            },
            {
                "name": "Ghazyatan",
                "countryCode": "IR"
            },
            {
                "name": "Gholam Veys",
                "countryCode": "IR"
            },
            {
                "name": "Givaki",
                "countryCode": "IR"
            },
            {
                "name": "Giyan",
                "countryCode": "IR"
            },
            {
                "name": "Gol Darreh-ye Anuch",
                "countryCode": "IR"
            },
            {
                "name": "Gol Heydar",
                "countryCode": "IR"
            },
            {
                "name": "Golparabad",
                "countryCode": "IR"
            },
            {
                "name": "Golshir",
                "countryCode": "IR"
            },
            {
                "name": "Golushjerd",
                "countryCode": "IR"
            },
            {
                "name": "Gomush Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Gonbad Chay",
                "countryCode": "IR"
            },
            {
                "name": "Gonbad-e Kabud",
                "countryCode": "IR"
            },
            {
                "name": "Gostar",
                "countryCode": "IR"
            },
            {
                "name": "Gozal Abdal",
                "countryCode": "IR"
            },
            {
                "name": "Gug Qash",
                "countryCode": "IR"
            },
            {
                "name": "Gujak",
                "countryCode": "IR"
            },
            {
                "name": "Gulvand",
                "countryCode": "IR"
            },
            {
                "name": "Gunestan",
                "countryCode": "IR"
            },
            {
                "name": "Gur Gaz",
                "countryCode": "IR"
            },
            {
                "name": "Gurjiu",
                "countryCode": "IR"
            },
            {
                "name": "Gushalan",
                "countryCode": "IR"
            },
            {
                "name": "Gusheh-ye Kasavand",
                "countryCode": "IR"
            },
            {
                "name": "Hajji Kheder",
                "countryCode": "IR"
            },
            {
                "name": "Hajji Maqsud",
                "countryCode": "IR"
            },
            {
                "name": "Hajji Tu",
                "countryCode": "IR"
            },
            {
                "name": "Hamadan",
                "countryCode": "IR"
            },
            {
                "name": "Hamilabad",
                "countryCode": "IR"
            },
            {
                "name": "Hamzehlu-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Haramabad",
                "countryCode": "IR"
            },
            {
                "name": "Hasan Kusej",
                "countryCode": "IR"
            },
            {
                "name": "Hasan Teymur",
                "countryCode": "IR"
            },
            {
                "name": "Hasanabad-e Emam",
                "countryCode": "IR"
            },
            {
                "name": "Hasanabad-e Qush Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Hasanabad-e Sheverin",
                "countryCode": "IR"
            },
            {
                "name": "Heriraz",
                "countryCode": "IR"
            },
            {
                "name": "Hesar-e Qarah Baghi",
                "countryCode": "IR"
            },
            {
                "name": "Hesar-e Qujeh Baghi",
                "countryCode": "IR"
            },
            {
                "name": "Heydareh-ye Dar-e Emam",
                "countryCode": "IR"
            },
            {
                "name": "Heydareh-ye Posht-e Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Hizaj",
                "countryCode": "IR"
            },
            {
                "name": "Holvar-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Hom-e Khosrow",
                "countryCode": "IR"
            },
            {
                "name": "Horhoreh",
                "countryCode": "IR"
            },
            {
                "name": "Hoseynabad-e Bahar",
                "countryCode": "IR"
            },
            {
                "name": "Hoseynabad-e Nazem",
                "countryCode": "IR"
            },
            {
                "name": "Hoseynabad-e Shamlu",
                "countryCode": "IR"
            },
            {
                "name": "Hudaraj",
                "countryCode": "IR"
            },
            {
                "name": "Iraneh",
                "countryCode": "IR"
            },
            {
                "name": "Isti Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Jamishlu",
                "countryCode": "IR"
            },
            {
                "name": "Jaria",
                "countryCode": "IR"
            },
            {
                "name": "Javar Sajin",
                "countryCode": "IR"
            },
            {
                "name": "Jerbanlu",
                "countryCode": "IR"
            },
            {
                "name": "Jighi",
                "countryCode": "IR"
            },
            {
                "name": "Jijan Kuh",
                "countryCode": "IR"
            },
            {
                "name": "Jijian Rud",
                "countryCode": "IR"
            },
            {
                "name": "Jin Taraqayah",
                "countryCode": "IR"
            },
            {
                "name": "Juraqan",
                "countryCode": "IR"
            },
            {
                "name": "Kaboodrahang",
                "countryCode": "IR"
            },
            {
                "name": "Kahard",
                "countryCode": "IR"
            },
            {
                "name": "Kahkadan",
                "countryCode": "IR"
            },
            {
                "name": "Kahriz-e Baba Hoseyn",
                "countryCode": "IR"
            },
            {
                "name": "Kahriz-e Boqazi",
                "countryCode": "IR"
            },
            {
                "name": "Kahriz-e Hoseynabad-e Nazem",
                "countryCode": "IR"
            },
            {
                "name": "Kahriz-e Jamal",
                "countryCode": "IR"
            },
            {
                "name": "Kahriz-e Salim",
                "countryCode": "IR"
            },
            {
                "name": "Kal Kabud",
                "countryCode": "IR"
            },
            {
                "name": "Kalb Hesari",
                "countryCode": "IR"
            },
            {
                "name": "Kalilabad",
                "countryCode": "IR"
            },
            {
                "name": "Kamak-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Kamar Boneh",
                "countryCode": "IR"
            },
            {
                "name": "Kamazan",
                "countryCode": "IR"
            },
            {
                "name": "Kand Halan",
                "countryCode": "IR"
            },
            {
                "name": "Kand Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Kand-e Bolaghi",
                "countryCode": "IR"
            },
            {
                "name": "Kangavar-e Kohneh",
                "countryCode": "IR"
            },
            {
                "name": "Karafs",
                "countryCode": "IR"
            },
            {
                "name": "Kark-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Kartilabad",
                "countryCode": "IR"
            },
            {
                "name": "Kasavand",
                "countryCode": "IR"
            },
            {
                "name": "Kaslan Qayah",
                "countryCode": "IR"
            },
            {
                "name": "Kavanaj",
                "countryCode": "IR"
            },
            {
                "name": "Kesb",
                "countryCode": "IR"
            },
            {
                "name": "Keykaleh",
                "countryCode": "IR"
            },
            {
                "name": "Keyni Sayeh",
                "countryCode": "IR"
            },
            {
                "name": "Khabar Arkhi",
                "countryCode": "IR"
            },
            {
                "name": "Khaku",
                "countryCode": "IR"
            },
            {
                "name": "Khaleq Verdi",
                "countryCode": "IR"
            },
            {
                "name": "Khalil Kord",
                "countryCode": "IR"
            },
            {
                "name": "Kheradmand",
                "countryCode": "IR"
            },
            {
                "name": "Kheyr Qoli",
                "countryCode": "IR"
            },
            {
                "name": "Khomeygan",
                "countryCode": "IR"
            },
            {
                "name": "Khvajeh Hesar",
                "countryCode": "IR"
            },
            {
                "name": "Khvajeh Hoseyni",
                "countryCode": "IR"
            },
            {
                "name": "Khvajeh Kandi",
                "countryCode": "IR"
            },
            {
                "name": "Khvoshab-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Kitu",
                "countryCode": "IR"
            },
            {
                "name": "Kohnush",
                "countryCode": "IR"
            },
            {
                "name": "Kolanjan",
                "countryCode": "IR"
            },
            {
                "name": "Konjineh",
                "countryCode": "IR"
            },
            {
                "name": "Kord Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Korzan",
                "countryCode": "IR"
            },
            {
                "name": "Kowzareh",
                "countryCode": "IR"
            },
            {
                "name": "Kuhani",
                "countryCode": "IR"
            },
            {
                "name": "Kur Kahriz",
                "countryCode": "IR"
            },
            {
                "name": "Kusaj Khalil",
                "countryCode": "IR"
            },
            {
                "name": "Kutah Darreh",
                "countryCode": "IR"
            },
            {
                "name": "Laleh Dan",
                "countryCode": "IR"
            },
            {
                "name": "Lalejin",
                "countryCode": "IR"
            },
            {
                "name": "Lamiyan",
                "countryCode": "IR"
            },
            {
                "name": "Latgah",
                "countryCode": "IR"
            },
            {
                "name": "Lavashan",
                "countryCode": "IR"
            },
            {
                "name": "Leyli Yadegar",
                "countryCode": "IR"
            },
            {
                "name": "Luluhar",
                "countryCode": "IR"
            },
            {
                "name": "Mahanabad",
                "countryCode": "IR"
            },
            {
                "name": "Mahbar",
                "countryCode": "IR"
            },
            {
                "name": "Mahdaviyeh",
                "countryCode": "IR"
            },
            {
                "name": "Mahnian",
                "countryCode": "IR"
            },
            {
                "name": "Malayer",
                "countryCode": "IR"
            },
            {
                "name": "Malek-e Ashtar",
                "countryCode": "IR"
            },
            {
                "name": "Malusan",
                "countryCode": "IR"
            },
            {
                "name": "Mangavi",
                "countryCode": "IR"
            },
            {
                "name": "Marvil",
                "countryCode": "IR"
            },
            {
                "name": "Maryanaj",
                "countryCode": "IR"
            },
            {
                "name": "Masjedin",
                "countryCode": "IR"
            },
            {
                "name": "Menjan",
                "countryCode": "IR"
            },
            {
                "name": "Mesinak",
                "countryCode": "IR"
            },
            {
                "name": "Meyvaleh",
                "countryCode": "IR"
            },
            {
                "name": "Mianzulan",
                "countryCode": "IR"
            },
            {
                "name": "Mihamleh-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Milab",
                "countryCode": "IR"
            },
            {
                "name": "Mirza Hesari",
                "countryCode": "IR"
            },
            {
                "name": "Mishen",
                "countryCode": "IR"
            },
            {
                "name": "Mobarakin",
                "countryCode": "IR"
            },
            {
                "name": "Mohajeran",
                "countryCode": "IR"
            },
            {
                "name": "Mohammadabad-e Chulak",
                "countryCode": "IR"
            },
            {
                "name": "Mohammadiyeh-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Mohara",
                "countryCode": "IR"
            },
            {
                "name": "Mokarrabi",
                "countryCode": "IR"
            },
            {
                "name": "Molham Darreh",
                "countryCode": "IR"
            },
            {
                "name": "Molla Bodagh",
                "countryCode": "IR"
            },
            {
                "name": "Monavvar Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Morghabad",
                "countryCode": "IR"
            },
            {
                "name": "Mowdaran",
                "countryCode": "IR"
            },
            {
                "name": "Musa Bolaghi",
                "countryCode": "IR"
            },
            {
                "name": "Nahandar",
                "countryCode": "IR"
            },
            {
                "name": "Nahavand",
                "countryCode": "IR"
            },
            {
                "name": "Najafiyeh",
                "countryCode": "IR"
            },
            {
                "name": "Nakilabad",
                "countryCode": "IR"
            },
            {
                "name": "Nakin",
                "countryCode": "IR"
            },
            {
                "name": "Namileh",
                "countryCode": "IR"
            },
            {
                "name": "Nanaj",
                "countryCode": "IR"
            },
            {
                "name": "Nazul",
                "countryCode": "IR"
            },
            {
                "name": "Negar Khatun",
                "countryCode": "IR"
            },
            {
                "name": "Negarabad",
                "countryCode": "IR"
            },
            {
                "name": "Nehenjeh",
                "countryCode": "IR"
            },
            {
                "name": "Nesar",
                "countryCode": "IR"
            },
            {
                "name": "Neshar",
                "countryCode": "IR"
            },
            {
                "name": "Niyanj",
                "countryCode": "IR"
            },
            {
                "name": "Nosratabad-e Behraz",
                "countryCode": "IR"
            },
            {
                "name": "Nosratabad-e Laklak",
                "countryCode": "IR"
            },
            {
                "name": "Nurabad-e Simineh",
                "countryCode": "IR"
            },
            {
                "name": "Ojaq",
                "countryCode": "IR"
            },
            {
                "name": "Ommatlar",
                "countryCode": "IR"
            },
            {
                "name": "Oshtoran",
                "countryCode": "IR"
            },
            {
                "name": "Oshtorjin",
                "countryCode": "IR"
            },
            {
                "name": "Ovraqin",
                "countryCode": "IR"
            },
            {
                "name": "Owrteh Qamish",
                "countryCode": "IR"
            },
            {
                "name": "Owryad",
                "countryCode": "IR"
            },
            {
                "name": "Pahnabad",
                "countryCode": "IR"
            },
            {
                "name": "Panbeh Dar",
                "countryCode": "IR"
            },
            {
                "name": "Parluk",
                "countryCode": "IR"
            },
            {
                "name": "Parvaraq",
                "countryCode": "IR"
            },
            {
                "name": "Payandeh",
                "countryCode": "IR"
            },
            {
                "name": "Peyhan",
                "countryCode": "IR"
            },
            {
                "name": "Pileh Jin",
                "countryCode": "IR"
            },
            {
                "name": "Pir Anbar",
                "countryCode": "IR"
            },
            {
                "name": "Pir Badam",
                "countryCode": "IR"
            },
            {
                "name": "Pir Khodaverdi",
                "countryCode": "IR"
            },
            {
                "name": "Pir Malu",
                "countryCode": "IR"
            },
            {
                "name": "Pir Mishan",
                "countryCode": "IR"
            },
            {
                "name": "Pir Savaran",
                "countryCode": "IR"
            },
            {
                "name": "Pir Yusof",
                "countryCode": "IR"
            },
            {
                "name": "Piruz",
                "countryCode": "IR"
            },
            {
                "name": "Poshtjin",
                "countryCode": "IR"
            },
            {
                "name": "Qabanqoli",
                "countryCode": "IR"
            },
            {
                "name": "Qader Khalaj",
                "countryCode": "IR"
            },
            {
                "name": "Qadimi",
                "countryCode": "IR"
            },
            {
                "name": "Qahavand",
                "countryCode": "IR"
            },
            {
                "name": "Qajar Ab-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Qarah Day",
                "countryCode": "IR"
            },
            {
                "name": "Qarah Tegini",
                "countryCode": "IR"
            },
            {
                "name": "Qaranqu Darreh",
                "countryCode": "IR"
            },
            {
                "name": "Qasabestan",
                "countryCode": "IR"
            },
            {
                "name": "Qasemabad-e Laklak",
                "countryCode": "IR"
            },
            {
                "name": "Qater Owlan",
                "countryCode": "IR"
            },
            {
                "name": "Qayesh",
                "countryCode": "IR"
            },
            {
                "name": "Qazan Qarah",
                "countryCode": "IR"
            },
            {
                "name": "Qazi Mardan",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq Baba Rostam",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Anuch",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Deh Ful",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Dehnow",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Mohammadi",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Najaf",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Pust Shuran",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Qobad",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Shirazi",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Qeshlaq-e Valiollah",
                "countryCode": "IR"
            },
            {
                "name": "Qeydli Bolagh",
                "countryCode": "IR"
            },
            {
                "name": "Qilab",
                "countryCode": "IR"
            },
            {
                "name": "Qohurd-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Qoli Laleh-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Qolqolabad",
                "countryCode": "IR"
            },
            {
                "name": "Quch Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Qushjeh",
                "countryCode": "IR"
            },
            {
                "name": "Rahmanabad, Malayer",
                "countryCode": "IR"
            },
            {
                "name": "Ramishan",
                "countryCode": "IR"
            },
            {
                "name": "Rastguyan",
                "countryCode": "IR"
            },
            {
                "name": "Rasulabad-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Ravand-e Vosta",
                "countryCode": "IR"
            },
            {
                "name": "Rayegan-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Razaj",
                "countryCode": "IR"
            },
            {
                "name": "Razan",
                "countryCode": "IR"
            },
            {
                "name": "Razini",
                "countryCode": "IR"
            },
            {
                "name": "Reza Baghi",
                "countryCode": "IR"
            },
            {
                "name": "Rezvankadeh",
                "countryCode": "IR"
            },
            {
                "name": "Rivasijan",
                "countryCode": "IR"
            },
            {
                "name": "Robat-e Sheverin",
                "countryCode": "IR"
            },
            {
                "name": "Rud Avar",
                "countryCode": "IR"
            },
            {
                "name": "Rud-e Hasan-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Safa Riz",
                "countryCode": "IR"
            },
            {
                "name": "Sahamabad",
                "countryCode": "IR"
            },
            {
                "name": "Salehabad",
                "countryCode": "IR"
            },
            {
                "name": "Salilak",
                "countryCode": "IR"
            },
            {
                "name": "Saluk",
                "countryCode": "IR"
            },
            {
                "name": "Samen",
                "countryCode": "IR"
            },
            {
                "name": "Sanaj",
                "countryCode": "IR"
            },
            {
                "name": "Sanjuzan",
                "countryCode": "IR"
            },
            {
                "name": "Sar Duran",
                "countryCode": "IR"
            },
            {
                "name": "Sarab-e Tajar",
                "countryCode": "IR"
            },
            {
                "name": "Saravak",
                "countryCode": "IR"
            },
            {
                "name": "Sarayjuq",
                "countryCode": "IR"
            },
            {
                "name": "Sarijlu",
                "countryCode": "IR"
            },
            {
                "name": "Sarkan",
                "countryCode": "IR"
            },
            {
                "name": "Saziyan",
                "countryCode": "IR"
            },
            {
                "name": "Sefid Khaneh",
                "countryCode": "IR"
            },
            {
                "name": "Segavi",
                "countryCode": "IR"
            },
            {
                "name": "Setaq",
                "countryCode": "IR"
            },
            {
                "name": "Shademaneh",
                "countryCode": "IR"
            },
            {
                "name": "Shanabad",
                "countryCode": "IR"
            },
            {
                "name": "Shanjur",
                "countryCode": "IR"
            },
            {
                "name": "Sharifabad-e Quzan",
                "countryCode": "IR"
            },
            {
                "name": "Sharifabad-e Tajar",
                "countryCode": "IR"
            },
            {
                "name": "Shatzal",
                "countryCode": "IR"
            },
            {
                "name": "Shavand",
                "countryCode": "IR"
            },
            {
                "name": "Sheverin",
                "countryCode": "IR"
            },
            {
                "name": "Sheykh Jarrah",
                "countryCode": "IR"
            },
            {
                "name": "Shilandar-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Shir Barat",
                "countryCode": "IR"
            },
            {
                "name": "Shirin Su",
                "countryCode": "IR"
            },
            {
                "name": "Shush Ab",
                "countryCode": "IR"
            },
            {
                "name": "Siah Goleh",
                "countryCode": "IR"
            },
            {
                "name": "Sib Dar",
                "countryCode": "IR"
            },
            {
                "name": "Silvar",
                "countryCode": "IR"
            },
            {
                "name": "Simin-e Abaru",
                "countryCode": "IR"
            },
            {
                "name": "Simin-e Zagheh",
                "countryCode": "IR"
            },
            {
                "name": "Siravand",
                "countryCode": "IR"
            },
            {
                "name": "Sistaneh",
                "countryCode": "IR"
            },
            {
                "name": "Solulan",
                "countryCode": "IR"
            },
            {
                "name": "Sorkh Kand",
                "countryCode": "IR"
            },
            {
                "name": "Sulijeh",
                "countryCode": "IR"
            },
            {
                "name": "Surtajin",
                "countryCode": "IR"
            },
            {
                "name": "Sutlaq",
                "countryCode": "IR"
            },
            {
                "name": "Tafrijan",
                "countryCode": "IR"
            },
            {
                "name": "Tahunabad",
                "countryCode": "IR"
            },
            {
                "name": "Tajar-e Samen",
                "countryCode": "IR"
            },
            {
                "name": "Takhtiabad",
                "countryCode": "IR"
            },
            {
                "name": "Taleh Jerd-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Tamuzan",
                "countryCode": "IR"
            },
            {
                "name": "Tappeh Yazdan",
                "countryCode": "IR"
            },
            {
                "name": "Tappeh-ye Dibi",
                "countryCode": "IR"
            },
            {
                "name": "Taraqayah",
                "countryCode": "IR"
            },
            {
                "name": "Tarik Darreh-ye Bala",
                "countryCode": "IR"
            },
            {
                "name": "Tarkhinabad",
                "countryCode": "IR"
            },
            {
                "name": "Tas Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Tasbandi",
                "countryCode": "IR"
            },
            {
                "name": "Tasran",
                "countryCode": "IR"
            },
            {
                "name": "Tavaleh",
                "countryCode": "IR"
            },
            {
                "name": "Tavaneh",
                "countryCode": "IR"
            },
            {
                "name": "Taveh",
                "countryCode": "IR"
            },
            {
                "name": "Tavilan-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Tazehnab-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Termianak",
                "countryCode": "IR"
            },
            {
                "name": "Timi Jan",
                "countryCode": "IR"
            },
            {
                "name": "Tuchaqaz",
                "countryCode": "IR"
            },
            {
                "name": "Tulki Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Tusk-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Tutal",
                "countryCode": "IR"
            },
            {
                "name": "Tuyserkan",
                "countryCode": "IR"
            },
            {
                "name": "Vafr-e Jin",
                "countryCode": "IR"
            },
            {
                "name": "Vahandeh",
                "countryCode": "IR"
            },
            {
                "name": "Vahman",
                "countryCode": "IR"
            },
            {
                "name": "Vahnan",
                "countryCode": "IR"
            },
            {
                "name": "Vali Mohammad",
                "countryCode": "IR"
            },
            {
                "name": "Vali Sir Ab",
                "countryCode": "IR"
            },
            {
                "name": "Vandarabad",
                "countryCode": "IR"
            },
            {
                "name": "Varayeneh",
                "countryCode": "IR"
            },
            {
                "name": "Varazaneh",
                "countryCode": "IR"
            },
            {
                "name": "Varchaq",
                "countryCode": "IR"
            },
            {
                "name": "Vardavard-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Vardavard-e Vosta",
                "countryCode": "IR"
            },
            {
                "name": "Varkaneh",
                "countryCode": "IR"
            },
            {
                "name": "Varqestan",
                "countryCode": "IR"
            },
            {
                "name": "Varvazin",
                "countryCode": "IR"
            },
            {
                "name": "Vasaj",
                "countryCode": "IR"
            },
            {
                "name": "Vashur",
                "countryCode": "IR"
            },
            {
                "name": "Vasleh",
                "countryCode": "IR"
            },
            {
                "name": "Vesht",
                "countryCode": "IR"
            },
            {
                "name": "Vezendan",
                "countryCode": "IR"
            },
            {
                "name": "Yalfan",
                "countryCode": "IR"
            },
            {
                "name": "Yarom Qayah",
                "countryCode": "IR"
            },
            {
                "name": "Yaromjeh Bagh",
                "countryCode": "IR"
            },
            {
                "name": "Yeganeh",
                "countryCode": "IR"
            },
            {
                "name": "Yekanabad",
                "countryCode": "IR"
            },
            {
                "name": "Yekleh",
                "countryCode": "IR"
            },
            {
                "name": "Yeserlu",
                "countryCode": "IR"
            },
            {
                "name": "Yunes",
                "countryCode": "IR"
            },
            {
                "name": "Yunji",
                "countryCode": "IR"
            },
            {
                "name": "Zagheh-ye Anuch",
                "countryCode": "IR"
            },
            {
                "name": "Zagheh-ye Tasbandi",
                "countryCode": "IR"
            },
            {
                "name": "Zaghlijeh",
                "countryCode": "IR"
            },
            {
                "name": "Zamanabad-e Mohammadabad",
                "countryCode": "IR"
            },
            {
                "name": "Zangeneh-ye Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Zapon",
                "countryCode": "IR"
            },
            {
                "name": "Zaramin-e Sofla",
                "countryCode": "IR"
            },
            {
                "name": "Zarrin Bagh",
                "countryCode": "IR"
            },
            {
                "name": "Zeraq",
                "countryCode": "IR"
            },
            {
                "name": "Zirabiyeh",
                "countryCode": "IR"
            },
            {
                "name": "Ben",
                "countryCode": "IR"
            },
            {
                "name": "Borūjen",
                "countryCode": "IR"
            },
            {
                "name": "Chelgard",
                "countryCode": "IR"
            },
            {
                "name": "Farrokh Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Fārsān",
                "countryCode": "IR"
            },
            {
                "name": "Saman",
                "countryCode": "IR"
            },
            {
                "name": "Shahr-e Kord",
                "countryCode": "IR"
            },
            {
                "name": "Shahrekord",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Borūjen",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fārsān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kīār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kūhrang",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Lordegān",
                "countryCode": "IR"
            },
            {
                "name": "Aleshtar",
                "countryCode": "IR"
            },
            {
                "name": "Alīgūdarz",
                "countryCode": "IR"
            },
            {
                "name": "Aznā",
                "countryCode": "IR"
            },
            {
                "name": "Borūjerd",
                "countryCode": "IR"
            },
            {
                "name": "Delfan",
                "countryCode": "IR"
            },
            {
                "name": "Khorramabad",
                "countryCode": "IR"
            },
            {
                "name": "Kūhdasht",
                "countryCode": "IR"
            },
            {
                "name": "Nūrābād",
                "countryCode": "IR"
            },
            {
                "name": "Pol-e Dokhtar",
                "countryCode": "IR"
            },
            {
                "name": "Rumeshkhan County",
                "countryCode": "IR"
            },
            {
                "name": "Selseleh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Alīgūdarz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Aznā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Borūjerd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dūreh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khorramābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kūhdasht",
                "countryCode": "IR"
            },
            {
                "name": "Vasīān",
                "countryCode": "IR"
            },
            {
                "name": "Badreh",
                "countryCode": "IR"
            },
            {
                "name": "Chardavol County",
                "countryCode": "IR"
            },
            {
                "name": "Darreh Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Darrehshahr",
                "countryCode": "IR"
            },
            {
                "name": "Dehlorān",
                "countryCode": "IR"
            },
            {
                "name": "Mehrān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dehlorān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eyvān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Malekshāhī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mehrān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ābdānān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Īlām",
                "countryCode": "IR"
            },
            {
                "name": "Sirvan",
                "countryCode": "IR"
            },
            {
                "name": "Ābdānān",
                "countryCode": "IR"
            },
            {
                "name": "Īlām",
                "countryCode": "IR"
            },
            {
                "name": "Dehdasht",
                "countryCode": "IR"
            },
            {
                "name": "Dogonbadan",
                "countryCode": "IR"
            },
            {
                "name": "Landeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bahma’ī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bowyer Aḩmad",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bāsht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Charām",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Danā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gachsārān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kohgīlūyeh",
                "countryCode": "IR"
            },
            {
                "name": "Yasuj",
                "countryCode": "IR"
            },
            {
                "name": "Ahram",
                "countryCode": "IR"
            },
            {
                "name": "Bandar-e Genāveh",
                "countryCode": "IR"
            },
            {
                "name": "Borāzjān",
                "countryCode": "IR"
            },
            {
                "name": "Bushehr",
                "countryCode": "IR"
            },
            {
                "name": "Deylam",
                "countryCode": "IR"
            },
            {
                "name": "Khārk",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Būshehr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dashtestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dashtī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Genāveh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kangān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tangestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e ‘Asalūyeh",
                "countryCode": "IR"
            },
            {
                "name": "Abhar",
                "countryCode": "IR"
            },
            {
                "name": "Alvand",
                "countryCode": "IR"
            },
            {
                "name": "Khorramdarreh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khodābandeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Māhneshān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zanjān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ījrūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ţārom",
                "countryCode": "IR"
            },
            {
                "name": "Soltaniyeh",
                "countryCode": "IR"
            },
            {
                "name": "Zanjān",
                "countryCode": "IR"
            },
            {
                "name": "Dāmghān",
                "countryCode": "IR"
            },
            {
                "name": "Mahdishahr",
                "countryCode": "IR"
            },
            {
                "name": "Semnan",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Dāmghān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Garmsār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mayāmey",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Semnān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shāhrūd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ārādān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrud",
                "countryCode": "IR"
            },
            {
                "name": "Īstgāh-e Rāh Āhan-e Garmsār",
                "countryCode": "IR"
            },
            {
                "name": "Ardakān",
                "countryCode": "IR"
            },
            {
                "name": "Bāfq",
                "countryCode": "IR"
            },
            {
                "name": "Khavāş Kūh",
                "countryCode": "IR"
            },
            {
                "name": "Mahrīz",
                "countryCode": "IR"
            },
            {
                "name": "Meybod",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Abarkūh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ardakān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ashkez̄ar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Behābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bāfq",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khātam",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mehrīz",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Yazd",
                "countryCode": "IR"
            },
            {
                "name": "Tabas",
                "countryCode": "IR"
            },
            {
                "name": "Taft",
                "countryCode": "IR"
            },
            {
                "name": "Yazd",
                "countryCode": "IR"
            },
            {
                "name": "Bandar Abbas",
                "countryCode": "IR"
            },
            {
                "name": "Bandar Lengeh",
                "countryCode": "IR"
            },
            {
                "name": "Bandar-e Lengeh",
                "countryCode": "IR"
            },
            {
                "name": "Bastak",
                "countryCode": "IR"
            },
            {
                "name": "Kīsh",
                "countryCode": "IR"
            },
            {
                "name": "Mīnāb",
                "countryCode": "IR"
            },
            {
                "name": "Qeshm",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Abū Mūsá",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bandar ‘Abbās",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bashāgard",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Jāsk",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khamīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mīnāb",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pārsīān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rūdān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sīrīk",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ḩājjīābād",
                "countryCode": "IR"
            },
            {
                "name": "Damāvand",
                "countryCode": "IR"
            },
            {
                "name": "Eqbālīyeh",
                "countryCode": "IR"
            },
            {
                "name": "Eslāmshahr",
                "countryCode": "IR"
            },
            {
                "name": "Malārd",
                "countryCode": "IR"
            },
            {
                "name": "Pardis",
                "countryCode": "IR"
            },
            {
                "name": "Pīshvā",
                "countryCode": "IR"
            },
            {
                "name": "Qarchak",
                "countryCode": "IR"
            },
            {
                "name": "Qods",
                "countryCode": "IR"
            },
            {
                "name": "Rey",
                "countryCode": "IR"
            },
            {
                "name": "Robāţ Karīm",
                "countryCode": "IR"
            },
            {
                "name": "Shahrak-e Emām Ḩasan",
                "countryCode": "IR"
            },
            {
                "name": "Shahre Jadide Andisheh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bahārestān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Damāvand",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eslāmshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fīrūzkūh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Malārd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pākdasht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pīshvā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Robāţ Karīm",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shahrīār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shemīrānāt",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tehrān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Varāmīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrīār",
                "countryCode": "IR"
            },
            {
                "name": "Sharīfābād",
                "countryCode": "IR"
            },
            {
                "name": "Soleh Bon",
                "countryCode": "IR"
            },
            {
                "name": "Tehran",
                "countryCode": "IR"
            },
            {
                "name": "Varāmīn",
                "countryCode": "IR"
            },
            {
                "name": "Ţāleb ābād",
                "countryCode": "IR"
            },
            {
                "name": "Ardabīl",
                "countryCode": "IR"
            },
            {
                "name": "Bīleh Savār",
                "countryCode": "IR"
            },
            {
                "name": "Khalkhāl",
                "countryCode": "IR"
            },
            {
                "name": "Omīdcheh",
                "countryCode": "IR"
            },
            {
                "name": "Pārsābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ardabīl",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bīleh Savār",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Germī",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khalkhāl",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kows̄ar",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Meshgīn Shahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Namīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nīr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Pārsābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sar‘eyn",
                "countryCode": "IR"
            },
            {
                "name": "Qom",
                "countryCode": "IR"
            },
            {
                "name": "Alborz",
                "countryCode": "IR"
            },
            {
                "name": "Alvand",
                "countryCode": "IR"
            },
            {
                "name": "Avaj",
                "countryCode": "IR"
            },
            {
                "name": "Qazvin",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bū’īn Zahrā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qazvīn",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Tākestān",
                "countryCode": "IR"
            },
            {
                "name": "Tākestān",
                "countryCode": "IR"
            },
            {
                "name": "Gonbad-e Kāvūs",
                "countryCode": "IR"
            },
            {
                "name": "Gorgān",
                "countryCode": "IR"
            },
            {
                "name": "Kalāleh",
                "countryCode": "IR"
            },
            {
                "name": "Qarnābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gomīshān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gonbad-e Kāvūs",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gorgān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Gālīkesh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kalāleh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Kordkūy",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Marāveh Tappeh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Mīnūdasht",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Rāmīān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āq Qalā",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Āzādshahr",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e ‘Alīābād",
                "countryCode": "IR"
            },
            {
                "name": "Torkaman",
                "countryCode": "IR"
            },
            {
                "name": "Āq Qāyeh",
                "countryCode": "IR"
            },
            {
                "name": "Āzādshahr",
                "countryCode": "IR"
            },
            {
                "name": "Bojnūrd",
                "countryCode": "IR"
            },
            {
                "name": "Esfarāyen",
                "countryCode": "IR"
            },
            {
                "name": "Raz and Jargalan",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bojnūrd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Esfarāyen",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Fārūj",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Jājarm",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Māneh va Samalqān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Shīrvān",
                "countryCode": "IR"
            },
            {
                "name": "Shīrvān",
                "countryCode": "IR"
            },
            {
                "name": "Boshrūyeh",
                "countryCode": "IR"
            },
            {
                "name": "Bīrjand",
                "countryCode": "IR"
            },
            {
                "name": "Qā’en",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Bīrjand",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Darmīān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Khūsf",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Nehbandān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Qā’en",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarbīsheh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sarāyān",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Zīrkūh",
                "countryCode": "IR"
            },
            {
                "name": "Tabas",
                "countryCode": "IR"
            },
            {
                "name": "Fardis",
                "countryCode": "IR"
            },
            {
                "name": "Karaj",
                "countryCode": "IR"
            },
            {
                "name": "Naz̧arābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Eshtehārd",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Naz̧arābād",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Sāvojbolāgh",
                "countryCode": "IR"
            },
            {
                "name": "Shahrestān-e Ţāleqān",
                "countryCode": "IR"
            }
        ]
    },
    {
        "name": "Iraq",
        "phonecode": "964",
        "cities": [
            {
                "name": "Al Fallūjah",
                "countryCode": "IQ"
            },
            {
                "name": "Ar Ruţbah",
                "countryCode": "IQ"
            },
            {
                "name": "Hīt",
                "countryCode": "IQ"
            },
            {
                "name": "Hīt District",
                "countryCode": "IQ"
            },
            {
                "name": "Ramadi",
                "countryCode": "IQ"
            },
            {
                "name": "Rāwah",
                "countryCode": "IQ"
            },
            {
                "name": "Ḩadīthah",
                "countryCode": "IQ"
            },
            {
                "name": "‘Anah",
                "countryCode": "IQ"
            },
            {
                "name": "‘Anat al Qadīmah",
                "countryCode": "IQ"
            },
            {
                "name": "Arbil",
                "countryCode": "IQ"
            },
            {
                "name": "Erbil",
                "countryCode": "IQ"
            },
            {
                "name": "Koysinceq",
                "countryCode": "IQ"
            },
            {
                "name": "Ruwāndiz",
                "countryCode": "IQ"
            },
            {
                "name": "Shaqlāwah",
                "countryCode": "IQ"
            },
            {
                "name": "Soran",
                "countryCode": "IQ"
            },
            {
                "name": "Al Başrah al Qadīmah",
                "countryCode": "IQ"
            },
            {
                "name": "Al Fāw",
                "countryCode": "IQ"
            },
            {
                "name": "Al Hārithah",
                "countryCode": "IQ"
            },
            {
                "name": "Az Zubayr",
                "countryCode": "IQ"
            },
            {
                "name": "Basrah",
                "countryCode": "IQ"
            },
            {
                "name": "Umm Qaşr",
                "countryCode": "IQ"
            },
            {
                "name": "Al Musayyib",
                "countryCode": "IQ"
            },
            {
                "name": "Al Ḩillah",
                "countryCode": "IQ"
            },
            {
                "name": "Imam Qasim",
                "countryCode": "IQ"
            },
            {
                "name": "Nāḩīyat Saddat al Hindīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Abu Ghraib District",
                "countryCode": "IQ"
            },
            {
                "name": "Abū Ghurayb",
                "countryCode": "IQ"
            },
            {
                "name": "Baghdad",
                "countryCode": "IQ"
            },
            {
                "name": "Al ‘Amādīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Batifa",
                "countryCode": "IQ"
            },
            {
                "name": "Dihok",
                "countryCode": "IQ"
            },
            {
                "name": "Sīnah",
                "countryCode": "IQ"
            },
            {
                "name": "Zaxo",
                "countryCode": "IQ"
            },
            {
                "name": "Al Miqdādīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Baladrūz",
                "countryCode": "IQ"
            },
            {
                "name": "Baqubah",
                "countryCode": "IQ"
            },
            {
                "name": "Khāliş",
                "countryCode": "IQ"
            },
            {
                "name": "Kifrī",
                "countryCode": "IQ"
            },
            {
                "name": "Mandalī",
                "countryCode": "IQ"
            },
            {
                "name": "Qaḑā’ Kifrī",
                "countryCode": "IQ"
            },
            {
                "name": "Ash Shaţrah",
                "countryCode": "IQ"
            },
            {
                "name": "Nasiriyah",
                "countryCode": "IQ"
            },
            {
                "name": "Nāḩiyat al Fuhūd",
                "countryCode": "IQ"
            },
            {
                "name": "Al Hindīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Karbala",
                "countryCode": "IQ"
            },
            {
                "name": "Kirkuk",
                "countryCode": "IQ"
            },
            {
                "name": "Al ‘Amārah",
                "countryCode": "IQ"
            },
            {
                "name": "Al-Mejar Al-Kabi District",
                "countryCode": "IQ"
            },
            {
                "name": "‘Alī al Gharbī",
                "countryCode": "IQ"
            },
            {
                "name": "Ar Rumaythah",
                "countryCode": "IQ"
            },
            {
                "name": "As Samawah",
                "countryCode": "IQ"
            },
            {
                "name": "Al Mishkhāb",
                "countryCode": "IQ"
            },
            {
                "name": "Kufa",
                "countryCode": "IQ"
            },
            {
                "name": "Najaf",
                "countryCode": "IQ"
            },
            {
                "name": "Al Mawşil al Jadīdah",
                "countryCode": "IQ"
            },
            {
                "name": "Al-Hamdaniya",
                "countryCode": "IQ"
            },
            {
                "name": "Ash Shaykhān",
                "countryCode": "IQ"
            },
            {
                "name": "Mosul",
                "countryCode": "IQ"
            },
            {
                "name": "Sinjar",
                "countryCode": "IQ"
            },
            {
                "name": "Tall ‘Afar",
                "countryCode": "IQ"
            },
            {
                "name": "Tallkayf",
                "countryCode": "IQ"
            },
            {
                "name": "‘Aqrah",
                "countryCode": "IQ"
            },
            {
                "name": "Ad Dīwānīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Ash Shāmīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Nahiyat Ghammas",
                "countryCode": "IQ"
            },
            {
                "name": "Nāḩiyat ash Shināfīyah",
                "countryCode": "IQ"
            },
            {
                "name": "‘Afak",
                "countryCode": "IQ"
            },
            {
                "name": "Ad Dujayl",
                "countryCode": "IQ"
            },
            {
                "name": "Balad",
                "countryCode": "IQ"
            },
            {
                "name": "Bayjī",
                "countryCode": "IQ"
            },
            {
                "name": "Sāmarrā’",
                "countryCode": "IQ"
            },
            {
                "name": "Tikrīt",
                "countryCode": "IQ"
            },
            {
                "name": "Tozkhurmato",
                "countryCode": "IQ"
            },
            {
                "name": "As Sulaymānīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Baynjiwayn",
                "countryCode": "IQ"
            },
            {
                "name": "Jamjamāl",
                "countryCode": "IQ"
            },
            {
                "name": "Ḩalabjah",
                "countryCode": "IQ"
            },
            {
                "name": "Al Kūt",
                "countryCode": "IQ"
            },
            {
                "name": "Al Ḩayy",
                "countryCode": "IQ"
            },
            {
                "name": "Al ‘Azīzīyah",
                "countryCode": "IQ"
            },
            {
                "name": "Aş Şuwayrah",
                "countryCode": "IQ"
            }
        ]
    },
    {
        "name": "Ireland",
        "phonecode": "353",
        "cities": [
            {
                "name": "Athenry",
                "countryCode": "IE"
            },
            {
                "name": "Ballaghaderreen",
                "countryCode": "IE"
            },
            {
                "name": "Ballina",
                "countryCode": "IE"
            },
            {
                "name": "Ballinasloe",
                "countryCode": "IE"
            },
            {
                "name": "Ballinrobe",
                "countryCode": "IE"
            },
            {
                "name": "Ballisodare",
                "countryCode": "IE"
            },
            {
                "name": "Ballyhaunis",
                "countryCode": "IE"
            },
            {
                "name": "Ballymote",
                "countryCode": "IE"
            },
            {
                "name": "Bearna",
                "countryCode": "IE"
            },
            {
                "name": "Belmullet",
                "countryCode": "IE"
            },
            {
                "name": "Boyle",
                "countryCode": "IE"
            },
            {
                "name": "Carrick-on-Shannon",
                "countryCode": "IE"
            },
            {
                "name": "Castlebar",
                "countryCode": "IE"
            },
            {
                "name": "Castlerea",
                "countryCode": "IE"
            },
            {
                "name": "Claregalway",
                "countryCode": "IE"
            },
            {
                "name": "Claremorris",
                "countryCode": "IE"
            },
            {
                "name": "Clifden",
                "countryCode": "IE"
            },
            {
                "name": "Collooney",
                "countryCode": "IE"
            },
            {
                "name": "County Galway",
                "countryCode": "IE"
            },
            {
                "name": "County Leitrim",
                "countryCode": "IE"
            },
            {
                "name": "Crossmolina",
                "countryCode": "IE"
            },
            {
                "name": "Foxford",
                "countryCode": "IE"
            },
            {
                "name": "Gaillimh",
                "countryCode": "IE"
            },
            {
                "name": "Galway City",
                "countryCode": "IE"
            },
            {
                "name": "Gort",
                "countryCode": "IE"
            },
            {
                "name": "Inishcrone",
                "countryCode": "IE"
            },
            {
                "name": "Kiltamagh",
                "countryCode": "IE"
            },
            {
                "name": "Kinlough",
                "countryCode": "IE"
            },
            {
                "name": "Loughrea",
                "countryCode": "IE"
            },
            {
                "name": "Manorhamilton",
                "countryCode": "IE"
            },
            {
                "name": "Mayo County",
                "countryCode": "IE"
            },
            {
                "name": "Moycullen",
                "countryCode": "IE"
            },
            {
                "name": "Oranmore",
                "countryCode": "IE"
            },
            {
                "name": "Oughterard",
                "countryCode": "IE"
            },
            {
                "name": "Portumna",
                "countryCode": "IE"
            },
            {
                "name": "Roscommon",
                "countryCode": "IE"
            },
            {
                "name": "Sligo",
                "countryCode": "IE"
            },
            {
                "name": "Strandhill",
                "countryCode": "IE"
            },
            {
                "name": "Swinford",
                "countryCode": "IE"
            },
            {
                "name": "Tobercurry",
                "countryCode": "IE"
            },
            {
                "name": "Tuam",
                "countryCode": "IE"
            },
            {
                "name": "Westport",
                "countryCode": "IE"
            },
            {
                "name": "Abbeyleix",
                "countryCode": "IE"
            },
            {
                "name": "An Iarmhí",
                "countryCode": "IE"
            },
            {
                "name": "An Longfort",
                "countryCode": "IE"
            },
            {
                "name": "An Mhí",
                "countryCode": "IE"
            },
            {
                "name": "An Muileann gCearr",
                "countryCode": "IE"
            },
            {
                "name": "An Ros",
                "countryCode": "IE"
            },
            {
                "name": "Ardee",
                "countryCode": "IE"
            },
            {
                "name": "Arklow",
                "countryCode": "IE"
            },
            {
                "name": "Artane",
                "countryCode": "IE"
            },
            {
                "name": "Ashbourne",
                "countryCode": "IE"
            },
            {
                "name": "Ashford",
                "countryCode": "IE"
            },
            {
                "name": "Athboy",
                "countryCode": "IE"
            },
            {
                "name": "Athgarvan",
                "countryCode": "IE"
            },
            {
                "name": "Athlone",
                "countryCode": "IE"
            },
            {
                "name": "Athy",
                "countryCode": "IE"
            },
            {
                "name": "Aughrim",
                "countryCode": "IE"
            },
            {
                "name": "Bagenalstown",
                "countryCode": "IE"
            },
            {
                "name": "Balally",
                "countryCode": "IE"
            },
            {
                "name": "Balbriggan",
                "countryCode": "IE"
            },
            {
                "name": "Baldoyle",
                "countryCode": "IE"
            },
            {
                "name": "Ballinroad",
                "countryCode": "IE"
            },
            {
                "name": "Ballinteer",
                "countryCode": "IE"
            },
            {
                "name": "Ballivor",
                "countryCode": "IE"
            },
            {
                "name": "Ballyboden",
                "countryCode": "IE"
            },
            {
                "name": "Ballyfermot",
                "countryCode": "IE"
            },
            {
                "name": "Ballygerry",
                "countryCode": "IE"
            },
            {
                "name": "Ballylinan",
                "countryCode": "IE"
            },
            {
                "name": "Ballymahon",
                "countryCode": "IE"
            },
            {
                "name": "Ballymun",
                "countryCode": "IE"
            },
            {
                "name": "Ballyragget",
                "countryCode": "IE"
            },
            {
                "name": "Balrothery",
                "countryCode": "IE"
            },
            {
                "name": "Baltinglass",
                "countryCode": "IE"
            },
            {
                "name": "Banagher",
                "countryCode": "IE"
            },
            {
                "name": "Bayside",
                "countryCode": "IE"
            },
            {
                "name": "Beaumont",
                "countryCode": "IE"
            },
            {
                "name": "Birr",
                "countryCode": "IE"
            },
            {
                "name": "Blackrock",
                "countryCode": "IE"
            },
            {
                "name": "Blanchardstown",
                "countryCode": "IE"
            },
            {
                "name": "Blessington",
                "countryCode": "IE"
            },
            {
                "name": "Bonnybrook",
                "countryCode": "IE"
            },
            {
                "name": "Booterstown",
                "countryCode": "IE"
            },
            {
                "name": "Bray",
                "countryCode": "IE"
            },
            {
                "name": "Bunclody",
                "countryCode": "IE"
            },
            {
                "name": "Cabinteely",
                "countryCode": "IE"
            },
            {
                "name": "Cabra",
                "countryCode": "IE"
            },
            {
                "name": "Callan",
                "countryCode": "IE"
            },
            {
                "name": "Carlingford",
                "countryCode": "IE"
            },
            {
                "name": "Carlow",
                "countryCode": "IE"
            },
            {
                "name": "Carnew",
                "countryCode": "IE"
            },
            {
                "name": "Castlebellingham",
                "countryCode": "IE"
            },
            {
                "name": "Castlebridge",
                "countryCode": "IE"
            },
            {
                "name": "Castlecomer",
                "countryCode": "IE"
            },
            {
                "name": "Castledermot",
                "countryCode": "IE"
            },
            {
                "name": "Castleknock",
                "countryCode": "IE"
            },
            {
                "name": "Castlepollard",
                "countryCode": "IE"
            },
            {
                "name": "Castletown",
                "countryCode": "IE"
            },
            {
                "name": "Celbridge",
                "countryCode": "IE"
            },
            {
                "name": "Chapelizod",
                "countryCode": "IE"
            },
            {
                "name": "Charlesland",
                "countryCode": "IE"
            },
            {
                "name": "Cherry Orchard",
                "countryCode": "IE"
            },
            {
                "name": "Cherryville",
                "countryCode": "IE"
            },
            {
                "name": "Clane",
                "countryCode": "IE"
            },
            {
                "name": "Clara",
                "countryCode": "IE"
            },
            {
                "name": "Clogherhead",
                "countryCode": "IE"
            },
            {
                "name": "Clondalkin",
                "countryCode": "IE"
            },
            {
                "name": "Clonskeagh",
                "countryCode": "IE"
            },
            {
                "name": "Confey",
                "countryCode": "IE"
            },
            {
                "name": "Coolock",
                "countryCode": "IE"
            },
            {
                "name": "County Carlow",
                "countryCode": "IE"
            },
            {
                "name": "Courtown",
                "countryCode": "IE"
            },
            {
                "name": "Crumlin",
                "countryCode": "IE"
            },
            {
                "name": "Daingean",
                "countryCode": "IE"
            },
            {
                "name": "Dalkey",
                "countryCode": "IE"
            },
            {
                "name": "Darndale",
                "countryCode": "IE"
            },
            {
                "name": "Derrinturn",
                "countryCode": "IE"
            },
            {
                "name": "Dollymount",
                "countryCode": "IE"
            },
            {
                "name": "Donabate",
                "countryCode": "IE"
            },
            {
                "name": "Donaghmede",
                "countryCode": "IE"
            },
            {
                "name": "Donnybrook",
                "countryCode": "IE"
            },
            {
                "name": "Donnycarney",
                "countryCode": "IE"
            },
            {
                "name": "Drogheda",
                "countryCode": "IE"
            },
            {
                "name": "Droichead Nua",
                "countryCode": "IE"
            },
            {
                "name": "Dromiskin",
                "countryCode": "IE"
            },
            {
                "name": "Drumcondra",
                "countryCode": "IE"
            },
            {
                "name": "Dublin",
                "countryCode": "IE"
            },
            {
                "name": "Dublin City",
                "countryCode": "IE"
            },
            {
                "name": "Duleek",
                "countryCode": "IE"
            },
            {
                "name": "Dunboyne",
                "countryCode": "IE"
            },
            {
                "name": "Dundalk",
                "countryCode": "IE"
            },
            {
                "name": "Dundrum",
                "countryCode": "IE"
            },
            {
                "name": "Dunleer",
                "countryCode": "IE"
            },
            {
                "name": "Dunshaughlin",
                "countryCode": "IE"
            },
            {
                "name": "Dún Laoghaire",
                "countryCode": "IE"
            },
            {
                "name": "Dún Laoghaire-Rathdown",
                "countryCode": "IE"
            },
            {
                "name": "Eadestown",
                "countryCode": "IE"
            },
            {
                "name": "Edenderry",
                "countryCode": "IE"
            },
            {
                "name": "Edgeworthstown",
                "countryCode": "IE"
            },
            {
                "name": "Enfield",
                "countryCode": "IE"
            },
            {
                "name": "Enniscorthy",
                "countryCode": "IE"
            },
            {
                "name": "Enniskerry",
                "countryCode": "IE"
            },
            {
                "name": "Fairview",
                "countryCode": "IE"
            },
            {
                "name": "Ferbane",
                "countryCode": "IE"
            },
            {
                "name": "Ferns",
                "countryCode": "IE"
            },
            {
                "name": "Fingal County",
                "countryCode": "IE"
            },
            {
                "name": "Finglas",
                "countryCode": "IE"
            },
            {
                "name": "Firhouse",
                "countryCode": "IE"
            },
            {
                "name": "Foxrock",
                "countryCode": "IE"
            },
            {
                "name": "Glasnevin",
                "countryCode": "IE"
            },
            {
                "name": "Gorey",
                "countryCode": "IE"
            },
            {
                "name": "Graiguenamanagh",
                "countryCode": "IE"
            },
            {
                "name": "Granard",
                "countryCode": "IE"
            },
            {
                "name": "Greenhills",
                "countryCode": "IE"
            },
            {
                "name": "Greystones",
                "countryCode": "IE"
            },
            {
                "name": "Hartstown",
                "countryCode": "IE"
            },
            {
                "name": "Howth",
                "countryCode": "IE"
            },
            {
                "name": "Jobstown",
                "countryCode": "IE"
            },
            {
                "name": "Johnstown",
                "countryCode": "IE"
            },
            {
                "name": "Kells",
                "countryCode": "IE"
            },
            {
                "name": "Kentstown",
                "countryCode": "IE"
            },
            {
                "name": "Kilbeggan",
                "countryCode": "IE"
            },
            {
                "name": "Kilcock",
                "countryCode": "IE"
            },
            {
                "name": "Kilcoole",
                "countryCode": "IE"
            },
            {
                "name": "Kilcullen",
                "countryCode": "IE"
            },
            {
                "name": "Kildare",
                "countryCode": "IE"
            },
            {
                "name": "Kilkenny",
                "countryCode": "IE"
            },
            {
                "name": "Kill",
                "countryCode": "IE"
            },
            {
                "name": "Killester",
                "countryCode": "IE"
            },
            {
                "name": "Kilmacanoge",
                "countryCode": "IE"
            },
            {
                "name": "Kilpedder",
                "countryCode": "IE"
            },
            {
                "name": "Kilquade",
                "countryCode": "IE"
            },
            {
                "name": "Kinnegad",
                "countryCode": "IE"
            },
            {
                "name": "Kinsealy-Drinan",
                "countryCode": "IE"
            },
            {
                "name": "Knocklyon",
                "countryCode": "IE"
            },
            {
                "name": "Lanesborough",
                "countryCode": "IE"
            },
            {
                "name": "Laois",
                "countryCode": "IE"
            },
            {
                "name": "Laytown",
                "countryCode": "IE"
            },
            {
                "name": "Leixlip",
                "countryCode": "IE"
            },
            {
                "name": "Little Bray",
                "countryCode": "IE"
            },
            {
                "name": "Loch Garman",
                "countryCode": "IE"
            },
            {
                "name": "Longford",
                "countryCode": "IE"
            },
            {
                "name": "Longwood",
                "countryCode": "IE"
            },
            {
                "name": "Loughlinstown",
                "countryCode": "IE"
            },
            {
                "name": "Lucan",
                "countryCode": "IE"
            },
            {
                "name": "Lusk",
                "countryCode": "IE"
            },
            {
                "name": "Lú",
                "countryCode": "IE"
            },
            {
                "name": "Malahide",
                "countryCode": "IE"
            },
            {
                "name": "Marino",
                "countryCode": "IE"
            },
            {
                "name": "Maynooth",
                "countryCode": "IE"
            },
            {
                "name": "Milltown",
                "countryCode": "IE"
            },
            {
                "name": "Moate",
                "countryCode": "IE"
            },
            {
                "name": "Monasterevin",
                "countryCode": "IE"
            },
            {
                "name": "Monkstown",
                "countryCode": "IE"
            },
            {
                "name": "Mooncoin",
                "countryCode": "IE"
            },
            {
                "name": "Moone",
                "countryCode": "IE"
            },
            {
                "name": "Mount Merrion",
                "countryCode": "IE"
            },
            {
                "name": "Mountmellick",
                "countryCode": "IE"
            },
            {
                "name": "Mountrath",
                "countryCode": "IE"
            },
            {
                "name": "Naas",
                "countryCode": "IE"
            },
            {
                "name": "Navan",
                "countryCode": "IE"
            },
            {
                "name": "New Ross",
                "countryCode": "IE"
            },
            {
                "name": "Newcastle",
                "countryCode": "IE"
            },
            {
                "name": "Newtown Trim",
                "countryCode": "IE"
            },
            {
                "name": "Newtownmountkennedy",
                "countryCode": "IE"
            },
            {
                "name": "Old Kilcullen",
                "countryCode": "IE"
            },
            {
                "name": "Oldbawn",
                "countryCode": "IE"
            },
            {
                "name": "Oldcastle",
                "countryCode": "IE"
            },
            {
                "name": "Palmerstown",
                "countryCode": "IE"
            },
            {
                "name": "Piltown",
                "countryCode": "IE"
            },
            {
                "name": "Portarlington",
                "countryCode": "IE"
            },
            {
                "name": "Portlaoise",
                "countryCode": "IE"
            },
            {
                "name": "Portmarnock",
                "countryCode": "IE"
            },
            {
                "name": "Portraine",
                "countryCode": "IE"
            },
            {
                "name": "Prosperous",
                "countryCode": "IE"
            },
            {
                "name": "Raheny",
                "countryCode": "IE"
            },
            {
                "name": "Rathangan",
                "countryCode": "IE"
            },
            {
                "name": "Rathcoole",
                "countryCode": "IE"
            },
            {
                "name": "Rathdowney",
                "countryCode": "IE"
            },
            {
                "name": "Rathdrum",
                "countryCode": "IE"
            },
            {
                "name": "Rathgar",
                "countryCode": "IE"
            },
            {
                "name": "Rathmines",
                "countryCode": "IE"
            },
            {
                "name": "Rathnew",
                "countryCode": "IE"
            },
            {
                "name": "Rathwire",
                "countryCode": "IE"
            },
            {
                "name": "Ratoath",
                "countryCode": "IE"
            },
            {
                "name": "Rialto",
                "countryCode": "IE"
            },
            {
                "name": "Ringsend",
                "countryCode": "IE"
            },
            {
                "name": "Rochfortbridge",
                "countryCode": "IE"
            },
            {
                "name": "Rosslare",
                "countryCode": "IE"
            },
            {
                "name": "Saggart",
                "countryCode": "IE"
            },
            {
                "name": "Sallins",
                "countryCode": "IE"
            },
            {
                "name": "Sallynoggin",
                "countryCode": "IE"
            },
            {
                "name": "Sandyford",
                "countryCode": "IE"
            },
            {
                "name": "Sandymount",
                "countryCode": "IE"
            },
            {
                "name": "Shankill",
                "countryCode": "IE"
            },
            {
                "name": "Skerries",
                "countryCode": "IE"
            },
            {
                "name": "Slane",
                "countryCode": "IE"
            },
            {
                "name": "South Dublin",
                "countryCode": "IE"
            },
            {
                "name": "Stamullin",
                "countryCode": "IE"
            },
            {
                "name": "Stradbally",
                "countryCode": "IE"
            },
            {
                "name": "Sutton",
                "countryCode": "IE"
            },
            {
                "name": "Swords",
                "countryCode": "IE"
            },
            {
                "name": "Tallaght",
                "countryCode": "IE"
            },
            {
                "name": "Templeogue",
                "countryCode": "IE"
            },
            {
                "name": "Terenure",
                "countryCode": "IE"
            },
            {
                "name": "Termonfeckin",
                "countryCode": "IE"
            },
            {
                "name": "Thomastown",
                "countryCode": "IE"
            },
            {
                "name": "Trim",
                "countryCode": "IE"
            },
            {
                "name": "Tullamore",
                "countryCode": "IE"
            },
            {
                "name": "Tullow",
                "countryCode": "IE"
            },
            {
                "name": "Tullyallen",
                "countryCode": "IE"
            },
            {
                "name": "Uíbh Fhailí",
                "countryCode": "IE"
            },
            {
                "name": "Valleymount",
                "countryCode": "IE"
            },
            {
                "name": "Wicklow",
                "countryCode": "IE"
            },
            {
                "name": "Abbeyfeale",
                "countryCode": "IE"
            },
            {
                "name": "Adare",
                "countryCode": "IE"
            },
            {
                "name": "Aghada",
                "countryCode": "IE"
            },
            {
                "name": "An Clár",
                "countryCode": "IE"
            },
            {
                "name": "Annacotty",
                "countryCode": "IE"
            },
            {
                "name": "Ardnacrusha",
                "countryCode": "IE"
            },
            {
                "name": "Askeaton",
                "countryCode": "IE"
            },
            {
                "name": "Ballina",
                "countryCode": "IE"
            },
            {
                "name": "Ballybunnion",
                "countryCode": "IE"
            },
            {
                "name": "Bandon",
                "countryCode": "IE"
            },
            {
                "name": "Bantry",
                "countryCode": "IE"
            },
            {
                "name": "Blarney",
                "countryCode": "IE"
            },
            {
                "name": "Caherconlish",
                "countryCode": "IE"
            },
            {
                "name": "Cahersiveen",
                "countryCode": "IE"
            },
            {
                "name": "Cahir",
                "countryCode": "IE"
            },
            {
                "name": "Carrick-on-Suir",
                "countryCode": "IE"
            },
            {
                "name": "Carrigaline",
                "countryCode": "IE"
            },
            {
                "name": "Carrigtwohill",
                "countryCode": "IE"
            },
            {
                "name": "Cashel",
                "countryCode": "IE"
            },
            {
                "name": "Castleconnell",
                "countryCode": "IE"
            },
            {
                "name": "Castleisland",
                "countryCode": "IE"
            },
            {
                "name": "Castlemartyr",
                "countryCode": "IE"
            },
            {
                "name": "Ciarraí",
                "countryCode": "IE"
            },
            {
                "name": "Cill Airne",
                "countryCode": "IE"
            },
            {
                "name": "Clonakilty",
                "countryCode": "IE"
            },
            {
                "name": "Cloyne",
                "countryCode": "IE"
            },
            {
                "name": "Cluain Meala",
                "countryCode": "IE"
            },
            {
                "name": "Cobh",
                "countryCode": "IE"
            },
            {
                "name": "Cork",
                "countryCode": "IE"
            },
            {
                "name": "Cork City",
                "countryCode": "IE"
            },
            {
                "name": "County Cork",
                "countryCode": "IE"
            },
            {
                "name": "County Tipperary",
                "countryCode": "IE"
            },
            {
                "name": "Croom",
                "countryCode": "IE"
            },
            {
                "name": "Crosshaven",
                "countryCode": "IE"
            },
            {
                "name": "Derry",
                "countryCode": "IE"
            },
            {
                "name": "Dingle",
                "countryCode": "IE"
            },
            {
                "name": "Dungarvan",
                "countryCode": "IE"
            },
            {
                "name": "Dunmanway",
                "countryCode": "IE"
            },
            {
                "name": "Dunmore East",
                "countryCode": "IE"
            },
            {
                "name": "Ennis",
                "countryCode": "IE"
            },
            {
                "name": "Fermoy",
                "countryCode": "IE"
            },
            {
                "name": "Fethard",
                "countryCode": "IE"
            },
            {
                "name": "Kanturk",
                "countryCode": "IE"
            },
            {
                "name": "Kenmare",
                "countryCode": "IE"
            },
            {
                "name": "Killaloe",
                "countryCode": "IE"
            },
            {
                "name": "Killorglin",
                "countryCode": "IE"
            },
            {
                "name": "Killumney",
                "countryCode": "IE"
            },
            {
                "name": "Kilmallock",
                "countryCode": "IE"
            },
            {
                "name": "Kilrush",
                "countryCode": "IE"
            },
            {
                "name": "Kinsale",
                "countryCode": "IE"
            },
            {
                "name": "Listowel",
                "countryCode": "IE"
            },
            {
                "name": "Luimneach",
                "countryCode": "IE"
            },
            {
                "name": "Macroom",
                "countryCode": "IE"
            },
            {
                "name": "Mallow",
                "countryCode": "IE"
            },
            {
                "name": "Midleton",
                "countryCode": "IE"
            },
            {
                "name": "Millstreet",
                "countryCode": "IE"
            },
            {
                "name": "Mitchelstown",
                "countryCode": "IE"
            },
            {
                "name": "Moroe",
                "countryCode": "IE"
            },
            {
                "name": "Moyross",
                "countryCode": "IE"
            },
            {
                "name": "Nenagh",
                "countryCode": "IE"
            },
            {
                "name": "Nenagh Bridge",
                "countryCode": "IE"
            },
            {
                "name": "Newcastle West",
                "countryCode": "IE"
            },
            {
                "name": "Newmarket on Fergus",
                "countryCode": "IE"
            },
            {
                "name": "Newport",
                "countryCode": "IE"
            },
            {
                "name": "Passage West",
                "countryCode": "IE"
            },
            {
                "name": "Portlaw",
                "countryCode": "IE"
            },
            {
                "name": "Rathcormac",
                "countryCode": "IE"
            },
            {
                "name": "Rathkeale",
                "countryCode": "IE"
            },
            {
                "name": "Roscrea",
                "countryCode": "IE"
            },
            {
                "name": "Ráth Luirc",
                "countryCode": "IE"
            },
            {
                "name": "Shannon",
                "countryCode": "IE"
            },
            {
                "name": "Sixmilebridge",
                "countryCode": "IE"
            },
            {
                "name": "Skibbereen",
                "countryCode": "IE"
            },
            {
                "name": "Templemore",
                "countryCode": "IE"
            },
            {
                "name": "Thurles",
                "countryCode": "IE"
            },
            {
                "name": "Tipperary",
                "countryCode": "IE"
            },
            {
                "name": "Tower",
                "countryCode": "IE"
            },
            {
                "name": "Tralee",
                "countryCode": "IE"
            },
            {
                "name": "Trá Mhór",
                "countryCode": "IE"
            },
            {
                "name": "Waterford",
                "countryCode": "IE"
            },
            {
                "name": "Watergrasshill",
                "countryCode": "IE"
            },
            {
                "name": "Whitegate",
                "countryCode": "IE"
            },
            {
                "name": "Youghal",
                "countryCode": "IE"
            },
            {
                "name": "An Cabhán",
                "countryCode": "IE"
            },
            {
                "name": "Bailieborough",
                "countryCode": "IE"
            },
            {
                "name": "Ballybofey",
                "countryCode": "IE"
            },
            {
                "name": "Ballyconnell",
                "countryCode": "IE"
            },
            {
                "name": "Ballyjamesduff",
                "countryCode": "IE"
            },
            {
                "name": "Ballyshannon",
                "countryCode": "IE"
            },
            {
                "name": "Belturbet",
                "countryCode": "IE"
            },
            {
                "name": "Buncrana",
                "countryCode": "IE"
            },
            {
                "name": "Bundoran",
                "countryCode": "IE"
            },
            {
                "name": "Carndonagh",
                "countryCode": "IE"
            },
            {
                "name": "Carrickmacross",
                "countryCode": "IE"
            },
            {
                "name": "Castleblayney",
                "countryCode": "IE"
            },
            {
                "name": "Cavan",
                "countryCode": "IE"
            },
            {
                "name": "Clones",
                "countryCode": "IE"
            },
            {
                "name": "Convoy",
                "countryCode": "IE"
            },
            {
                "name": "Cootehill",
                "countryCode": "IE"
            },
            {
                "name": "County Donegal",
                "countryCode": "IE"
            },
            {
                "name": "County Monaghan",
                "countryCode": "IE"
            },
            {
                "name": "Derrybeg",
                "countryCode": "IE"
            },
            {
                "name": "Donegal",
                "countryCode": "IE"
            },
            {
                "name": "Dungloe",
                "countryCode": "IE"
            },
            {
                "name": "Dunlewy",
                "countryCode": "IE"
            },
            {
                "name": "Gweedore",
                "countryCode": "IE"
            },
            {
                "name": "Killybegs",
                "countryCode": "IE"
            },
            {
                "name": "Kingscourt",
                "countryCode": "IE"
            },
            {
                "name": "Leifear",
                "countryCode": "IE"
            },
            {
                "name": "Letterkenny",
                "countryCode": "IE"
            },
            {
                "name": "Monaghan",
                "countryCode": "IE"
            },
            {
                "name": "Moville",
                "countryCode": "IE"
            },
            {
                "name": "Muff",
                "countryCode": "IE"
            },
            {
                "name": "Mullagh",
                "countryCode": "IE"
            },
            {
                "name": "Newtown Cunningham",
                "countryCode": "IE"
            },
            {
                "name": "Ramelton",
                "countryCode": "IE"
            },
            {
                "name": "Raphoe",
                "countryCode": "IE"
            },
            {
                "name": "Virginia",
                "countryCode": "IE"
            }
        ]
    },
    {
        "name": "Israel",
        "phonecode": "972",
        "cities": [
            {
                "name": "Arad",
                "countryCode": "IL"
            },
            {
                "name": "Ashdod",
                "countryCode": "IL"
            },
            {
                "name": "Ashkelon",
                "countryCode": "IL"
            },
            {
                "name": "Beersheba",
                "countryCode": "IL"
            },
            {
                "name": "Dimona",
                "countryCode": "IL"
            },
            {
                "name": "Eilat",
                "countryCode": "IL"
            },
            {
                "name": "Lehavim",
                "countryCode": "IL"
            },
            {
                "name": "Midreshet Ben-Gurion",
                "countryCode": "IL"
            },
            {
                "name": "Mitzpe Ramon",
                "countryCode": "IL"
            },
            {
                "name": "Netivot",
                "countryCode": "IL"
            },
            {
                "name": "Ofaqim",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Gat",
                "countryCode": "IL"
            },
            {
                "name": "Rahat",
                "countryCode": "IL"
            },
            {
                "name": "Sederot",
                "countryCode": "IL"
            },
            {
                "name": "Yeroẖam",
                "countryCode": "IL"
            },
            {
                "name": "‘En Boqeq",
                "countryCode": "IL"
            },
            {
                "name": "Atlit",
                "countryCode": "IL"
            },
            {
                "name": "Caesarea",
                "countryCode": "IL"
            },
            {
                "name": "Daliyat al Karmel",
                "countryCode": "IL"
            },
            {
                "name": "El Fureidīs",
                "countryCode": "IL"
            },
            {
                "name": "Hadera",
                "countryCode": "IL"
            },
            {
                "name": "Haifa",
                "countryCode": "IL"
            },
            {
                "name": "Ibṭīn",
                "countryCode": "IL"
            },
            {
                "name": "Nesher",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Ata",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Bialik",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Moẕqin",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Yam",
                "countryCode": "IL"
            },
            {
                "name": "Rekhasim",
                "countryCode": "IL"
            },
            {
                "name": "Tirat Karmel",
                "countryCode": "IL"
            },
            {
                "name": "Umm el Faḥm",
                "countryCode": "IL"
            },
            {
                "name": "Abū Ghaush",
                "countryCode": "IL"
            },
            {
                "name": "Bet Shemesh",
                "countryCode": "IL"
            },
            {
                "name": "Har Adar",
                "countryCode": "IL"
            },
            {
                "name": "Jerusalem",
                "countryCode": "IL"
            },
            {
                "name": "Mevasseret Ẕiyyon",
                "countryCode": "IL"
            },
            {
                "name": "Modiin Ilit",
                "countryCode": "IL"
            },
            {
                "name": "West Jerusalem",
                "countryCode": "IL"
            },
            {
                "name": "Ẕur Hadassa",
                "countryCode": "IL"
            },
            {
                "name": "Bet Dagan",
                "countryCode": "IL"
            },
            {
                "name": "Bet Yiẕẖaq",
                "countryCode": "IL"
            },
            {
                "name": "Bnei Ayish",
                "countryCode": "IL"
            },
            {
                "name": "Elyakhin",
                "countryCode": "IL"
            },
            {
                "name": "Even Yehuda",
                "countryCode": "IL"
            },
            {
                "name": "Eṭ Ṭaiyiba",
                "countryCode": "IL"
            },
            {
                "name": "Gan Yavne",
                "countryCode": "IL"
            },
            {
                "name": "Ganei Tikva",
                "countryCode": "IL"
            },
            {
                "name": "Gedera",
                "countryCode": "IL"
            },
            {
                "name": "Hod HaSharon",
                "countryCode": "IL"
            },
            {
                "name": "Jaljūlya",
                "countryCode": "IL"
            },
            {
                "name": "Kafr Qāsim",
                "countryCode": "IL"
            },
            {
                "name": "Kefar H̱abad",
                "countryCode": "IL"
            },
            {
                "name": "Kefar Yona",
                "countryCode": "IL"
            },
            {
                "name": "Kfar Saba",
                "countryCode": "IL"
            },
            {
                "name": "Lapid",
                "countryCode": "IL"
            },
            {
                "name": "Lod",
                "countryCode": "IL"
            },
            {
                "name": "Mazkeret Batya",
                "countryCode": "IL"
            },
            {
                "name": "Modi‘in Makkabbim Re‘ut",
                "countryCode": "IL"
            },
            {
                "name": "Ness Ziona",
                "countryCode": "IL"
            },
            {
                "name": "Netanya",
                "countryCode": "IL"
            },
            {
                "name": "Neẖalim",
                "countryCode": "IL"
            },
            {
                "name": "Nirit",
                "countryCode": "IL"
            },
            {
                "name": "Nof Ayalon",
                "countryCode": "IL"
            },
            {
                "name": "Nordiyya",
                "countryCode": "IL"
            },
            {
                "name": "Pardesiyya",
                "countryCode": "IL"
            },
            {
                "name": "Petaẖ Tiqwa",
                "countryCode": "IL"
            },
            {
                "name": "Qalansuwa",
                "countryCode": "IL"
            },
            {
                "name": "Ra'anana",
                "countryCode": "IL"
            },
            {
                "name": "Ramla",
                "countryCode": "IL"
            },
            {
                "name": "Reẖovot",
                "countryCode": "IL"
            },
            {
                "name": "Rishon LeẔiyyon",
                "countryCode": "IL"
            },
            {
                "name": "Rosh Ha‘Ayin",
                "countryCode": "IL"
            },
            {
                "name": "Savyon",
                "countryCode": "IL"
            },
            {
                "name": "Shoham",
                "countryCode": "IL"
            },
            {
                "name": "Tel Mond",
                "countryCode": "IL"
            },
            {
                "name": "Tirah",
                "countryCode": "IL"
            },
            {
                "name": "Yavné",
                "countryCode": "IL"
            },
            {
                "name": "Yehud",
                "countryCode": "IL"
            },
            {
                "name": "Ẕur Moshe",
                "countryCode": "IL"
            },
            {
                "name": "Azor",
                "countryCode": "IL"
            },
            {
                "name": "Bat Yam",
                "countryCode": "IL"
            },
            {
                "name": "Bnei Brak",
                "countryCode": "IL"
            },
            {
                "name": "Giv'at Shmuel",
                "countryCode": "IL"
            },
            {
                "name": "Givatayim",
                "countryCode": "IL"
            },
            {
                "name": "Herzliya",
                "countryCode": "IL"
            },
            {
                "name": "Herzliya Pituah",
                "countryCode": "IL"
            },
            {
                "name": "H̱olon",
                "countryCode": "IL"
            },
            {
                "name": "Jaffa",
                "countryCode": "IL"
            },
            {
                "name": "Kefar Shemaryahu",
                "countryCode": "IL"
            },
            {
                "name": "Or Yehuda",
                "countryCode": "IL"
            },
            {
                "name": "Ramat Gan",
                "countryCode": "IL"
            },
            {
                "name": "Ramat HaSharon",
                "countryCode": "IL"
            },
            {
                "name": "Tel Aviv",
                "countryCode": "IL"
            },
            {
                "name": "Yehud-Monosson",
                "countryCode": "IL"
            },
            {
                "name": "Acre",
                "countryCode": "IL"
            },
            {
                "name": "Afula",
                "countryCode": "IL"
            },
            {
                "name": "Basmat Ṭab‘ūn",
                "countryCode": "IL"
            },
            {
                "name": "Beit Jann",
                "countryCode": "IL"
            },
            {
                "name": "Bet She’an",
                "countryCode": "IL"
            },
            {
                "name": "Buqei‘a",
                "countryCode": "IL"
            },
            {
                "name": "Bu‘eina",
                "countryCode": "IL"
            },
            {
                "name": "Bīr el Maksūr",
                "countryCode": "IL"
            },
            {
                "name": "Dabbūrīya",
                "countryCode": "IL"
            },
            {
                "name": "Deir Ḥannā",
                "countryCode": "IL"
            },
            {
                "name": "El Mazra‘a",
                "countryCode": "IL"
            },
            {
                "name": "Er Reina",
                "countryCode": "IL"
            },
            {
                "name": "Esh Sheikh Dannūn",
                "countryCode": "IL"
            },
            {
                "name": "Iksāl",
                "countryCode": "IL"
            },
            {
                "name": "Judeida Makr",
                "countryCode": "IL"
            },
            {
                "name": "Jīsh",
                "countryCode": "IL"
            },
            {
                "name": "Kafr Kammā",
                "countryCode": "IL"
            },
            {
                "name": "Kafr Kannā",
                "countryCode": "IL"
            },
            {
                "name": "Kafr Mandā",
                "countryCode": "IL"
            },
            {
                "name": "Kafr Miṣr",
                "countryCode": "IL"
            },
            {
                "name": "Karmi’el",
                "countryCode": "IL"
            },
            {
                "name": "Kaukab Abū el Hījā",
                "countryCode": "IL"
            },
            {
                "name": "Kefar Rosh HaNiqra",
                "countryCode": "IL"
            },
            {
                "name": "Kefar Tavor",
                "countryCode": "IL"
            },
            {
                "name": "Kefar Weradim",
                "countryCode": "IL"
            },
            {
                "name": "Kfar Yasif",
                "countryCode": "IL"
            },
            {
                "name": "Kābūl",
                "countryCode": "IL"
            },
            {
                "name": "Maghār",
                "countryCode": "IL"
            },
            {
                "name": "Metulla",
                "countryCode": "IL"
            },
            {
                "name": "Migdal Ha‘Emeq",
                "countryCode": "IL"
            },
            {
                "name": "Mi‘ilyā",
                "countryCode": "IL"
            },
            {
                "name": "Nahariyya",
                "countryCode": "IL"
            },
            {
                "name": "Nazareth",
                "countryCode": "IL"
            },
            {
                "name": "Naḥf",
                "countryCode": "IL"
            },
            {
                "name": "Nefat ‘Akko",
                "countryCode": "IL"
            },
            {
                "name": "Nein",
                "countryCode": "IL"
            },
            {
                "name": "Pasuta",
                "countryCode": "IL"
            },
            {
                "name": "Qiryat Shemona",
                "countryCode": "IL"
            },
            {
                "name": "Ramat Yishay",
                "countryCode": "IL"
            },
            {
                "name": "Rosh Pinna",
                "countryCode": "IL"
            },
            {
                "name": "Rumat Heib",
                "countryCode": "IL"
            },
            {
                "name": "Safed",
                "countryCode": "IL"
            },
            {
                "name": "Sakhnīn",
                "countryCode": "IL"
            },
            {
                "name": "Sallama",
                "countryCode": "IL"
            },
            {
                "name": "Shelomi",
                "countryCode": "IL"
            },
            {
                "name": "Shibli",
                "countryCode": "IL"
            },
            {
                "name": "Sājūr",
                "countryCode": "IL"
            },
            {
                "name": "Sūlam",
                "countryCode": "IL"
            },
            {
                "name": "Tamra",
                "countryCode": "IL"
            },
            {
                "name": "Tiberias",
                "countryCode": "IL"
            },
            {
                "name": "Timrat",
                "countryCode": "IL"
            },
            {
                "name": "Yavne’el",
                "countryCode": "IL"
            },
            {
                "name": "maalot Tarshīhā",
                "countryCode": "IL"
            },
            {
                "name": "Ḥurfeish",
                "countryCode": "IL"
            },
            {
                "name": "‘Eilabun",
                "countryCode": "IL"
            },
            {
                "name": "‘Uzeir",
                "countryCode": "IL"
            }
        ]
    },
    {
        "name": "Italy",
        "phonecode": "39",
        "cities": [
            {
                "name": "Acceglio",
                "countryCode": "IT"
            },
            {
                "name": "Acqui Terme",
                "countryCode": "IT"
            },
            {
                "name": "Agliano",
                "countryCode": "IT"
            },
            {
                "name": "Agliano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Agliè",
                "countryCode": "IT"
            },
            {
                "name": "Agrate",
                "countryCode": "IT"
            },
            {
                "name": "Agrate Conturbia",
                "countryCode": "IT"
            },
            {
                "name": "Ailoche",
                "countryCode": "IT"
            },
            {
                "name": "Airali",
                "countryCode": "IT"
            },
            {
                "name": "Airasca",
                "countryCode": "IT"
            },
            {
                "name": "Aisone",
                "countryCode": "IT"
            },
            {
                "name": "Ala di Stura",
                "countryCode": "IT"
            },
            {
                "name": "Alagna Valsesia",
                "countryCode": "IT"
            },
            {
                "name": "Alba",
                "countryCode": "IT"
            },
            {
                "name": "Albano Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Albaretto della Torre",
                "countryCode": "IT"
            },
            {
                "name": "Albera Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Albiano d'Ivrea",
                "countryCode": "IT"
            },
            {
                "name": "Albugnano",
                "countryCode": "IT"
            },
            {
                "name": "Alessandria",
                "countryCode": "IT"
            },
            {
                "name": "Alfiano Natta",
                "countryCode": "IT"
            },
            {
                "name": "Alice Bel Colle",
                "countryCode": "IT"
            },
            {
                "name": "Alice Castello",
                "countryCode": "IT"
            },
            {
                "name": "Alice Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Almese-Rivera",
                "countryCode": "IT"
            },
            {
                "name": "Alpette",
                "countryCode": "IT"
            },
            {
                "name": "Alpignano",
                "countryCode": "IT"
            },
            {
                "name": "Altavilla Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Alto",
                "countryCode": "IT"
            },
            {
                "name": "Alzano Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Ameno",
                "countryCode": "IT"
            },
            {
                "name": "Andezeno",
                "countryCode": "IT"
            },
            {
                "name": "Andorno Cacciorna",
                "countryCode": "IT"
            },
            {
                "name": "Andrate",
                "countryCode": "IT"
            },
            {
                "name": "Angrogna",
                "countryCode": "IT"
            },
            {
                "name": "Antignano",
                "countryCode": "IT"
            },
            {
                "name": "Antronapiana",
                "countryCode": "IT"
            },
            {
                "name": "Anzola d'Ossola",
                "countryCode": "IT"
            },
            {
                "name": "Aramengo",
                "countryCode": "IT"
            },
            {
                "name": "Arborio",
                "countryCode": "IT"
            },
            {
                "name": "Arguello",
                "countryCode": "IT"
            },
            {
                "name": "Arignano",
                "countryCode": "IT"
            },
            {
                "name": "Arizzano",
                "countryCode": "IT"
            },
            {
                "name": "Armeno",
                "countryCode": "IT"
            },
            {
                "name": "Arola",
                "countryCode": "IT"
            },
            {
                "name": "Arona",
                "countryCode": "IT"
            },
            {
                "name": "Arquata Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Asigliano Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Asti",
                "countryCode": "IT"
            },
            {
                "name": "Aurano",
                "countryCode": "IT"
            },
            {
                "name": "Avigliana",
                "countryCode": "IT"
            },
            {
                "name": "Avolasca",
                "countryCode": "IT"
            },
            {
                "name": "Azeglio",
                "countryCode": "IT"
            },
            {
                "name": "Azzano d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Baceno",
                "countryCode": "IT"
            },
            {
                "name": "Bagnasco",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Bairo",
                "countryCode": "IT"
            },
            {
                "name": "Balangero",
                "countryCode": "IT"
            },
            {
                "name": "Baldichieri d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Baldissero Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Baldissero Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Baldissero d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Balme",
                "countryCode": "IT"
            },
            {
                "name": "Balmuccia",
                "countryCode": "IT"
            },
            {
                "name": "Balocco",
                "countryCode": "IT"
            },
            {
                "name": "Balzola",
                "countryCode": "IT"
            },
            {
                "name": "Banchette",
                "countryCode": "IT"
            },
            {
                "name": "Bandito",
                "countryCode": "IT"
            },
            {
                "name": "Bannio",
                "countryCode": "IT"
            },
            {
                "name": "Baratte",
                "countryCode": "IT"
            },
            {
                "name": "Barbania",
                "countryCode": "IT"
            },
            {
                "name": "Barbaresco",
                "countryCode": "IT"
            },
            {
                "name": "Bardonecchia",
                "countryCode": "IT"
            },
            {
                "name": "Barengo",
                "countryCode": "IT"
            },
            {
                "name": "Barge",
                "countryCode": "IT"
            },
            {
                "name": "Barolo",
                "countryCode": "IT"
            },
            {
                "name": "Barone Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Barquedo",
                "countryCode": "IT"
            },
            {
                "name": "Basaluzzo",
                "countryCode": "IT"
            },
            {
                "name": "Bassignana",
                "countryCode": "IT"
            },
            {
                "name": "Bastia Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Battifollo",
                "countryCode": "IT"
            },
            {
                "name": "Baveno",
                "countryCode": "IT"
            },
            {
                "name": "Bee",
                "countryCode": "IT"
            },
            {
                "name": "Beinasco",
                "countryCode": "IT"
            },
            {
                "name": "Beinette",
                "countryCode": "IT"
            },
            {
                "name": "Belforte Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Belgirate",
                "countryCode": "IT"
            },
            {
                "name": "Bellinzago Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere Langhe",
                "countryCode": "IT"
            },
            {
                "name": "Belveglio",
                "countryCode": "IT"
            },
            {
                "name": "Bene Vagienna",
                "countryCode": "IT"
            },
            {
                "name": "Benevello",
                "countryCode": "IT"
            },
            {
                "name": "Benna",
                "countryCode": "IT"
            },
            {
                "name": "Bergamasco",
                "countryCode": "IT"
            },
            {
                "name": "Bergolo",
                "countryCode": "IT"
            },
            {
                "name": "Bernezzo",
                "countryCode": "IT"
            },
            {
                "name": "Bersezio",
                "countryCode": "IT"
            },
            {
                "name": "Berzano di San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Berzano di Tortona",
                "countryCode": "IT"
            },
            {
                "name": "Beura",
                "countryCode": "IT"
            },
            {
                "name": "Biandrate",
                "countryCode": "IT"
            },
            {
                "name": "Bianzè",
                "countryCode": "IT"
            },
            {
                "name": "Bibiana",
                "countryCode": "IT"
            },
            {
                "name": "Biella",
                "countryCode": "IT"
            },
            {
                "name": "Bioglio-Portula-Andrè",
                "countryCode": "IT"
            },
            {
                "name": "Bistagno",
                "countryCode": "IT"
            },
            {
                "name": "Bobbio Pellice",
                "countryCode": "IT"
            },
            {
                "name": "Boca",
                "countryCode": "IT"
            },
            {
                "name": "Boccioleto",
                "countryCode": "IT"
            },
            {
                "name": "Bogogno",
                "countryCode": "IT"
            },
            {
                "name": "Bollengo",
                "countryCode": "IT"
            },
            {
                "name": "Bolzano Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Bonvicino",
                "countryCode": "IT"
            },
            {
                "name": "Borgaro Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto di Borbera",
                "countryCode": "IT"
            },
            {
                "name": "Borgiallo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Melano",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Dalmazzo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Vercelli",
                "countryCode": "IT"
            },
            {
                "name": "Borgo d'Ale",
                "countryCode": "IT"
            },
            {
                "name": "Borgofranco d'Ivrea",
                "countryCode": "IT"
            },
            {
                "name": "Borgolavezzaro",
                "countryCode": "IT"
            },
            {
                "name": "Borgomale",
                "countryCode": "IT"
            },
            {
                "name": "Borgomanero",
                "countryCode": "IT"
            },
            {
                "name": "Borgomasino",
                "countryCode": "IT"
            },
            {
                "name": "Borgone Susa",
                "countryCode": "IT"
            },
            {
                "name": "Borgoratto Alessandrino",
                "countryCode": "IT"
            },
            {
                "name": "Borgosesia",
                "countryCode": "IT"
            },
            {
                "name": "Borriana",
                "countryCode": "IT"
            },
            {
                "name": "Bosco Marengo",
                "countryCode": "IT"
            },
            {
                "name": "Bosconero",
                "countryCode": "IT"
            },
            {
                "name": "Bosia",
                "countryCode": "IT"
            },
            {
                "name": "Bosio",
                "countryCode": "IT"
            },
            {
                "name": "Bossolasco",
                "countryCode": "IT"
            },
            {
                "name": "Boves",
                "countryCode": "IT"
            },
            {
                "name": "Bozzole",
                "countryCode": "IT"
            },
            {
                "name": "Bra",
                "countryCode": "IT"
            },
            {
                "name": "Brandizzo",
                "countryCode": "IT"
            },
            {
                "name": "Breia",
                "countryCode": "IT"
            },
            {
                "name": "Breo",
                "countryCode": "IT"
            },
            {
                "name": "Briaglia",
                "countryCode": "IT"
            },
            {
                "name": "Bricco di Neive",
                "countryCode": "IT"
            },
            {
                "name": "Bricherasio",
                "countryCode": "IT"
            },
            {
                "name": "Briga Alta",
                "countryCode": "IT"
            },
            {
                "name": "Briga Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Brignano",
                "countryCode": "IT"
            },
            {
                "name": "Briona",
                "countryCode": "IT"
            },
            {
                "name": "Brondello",
                "countryCode": "IT"
            },
            {
                "name": "Brossasco",
                "countryCode": "IT"
            },
            {
                "name": "Brosso",
                "countryCode": "IT"
            },
            {
                "name": "Brovello",
                "countryCode": "IT"
            },
            {
                "name": "Brovello-Carpugnino",
                "countryCode": "IT"
            },
            {
                "name": "Brozolo",
                "countryCode": "IT"
            },
            {
                "name": "Bruino",
                "countryCode": "IT"
            },
            {
                "name": "Bruno",
                "countryCode": "IT"
            },
            {
                "name": "Brusasco",
                "countryCode": "IT"
            },
            {
                "name": "Brusnengo",
                "countryCode": "IT"
            },
            {
                "name": "Bruzolo",
                "countryCode": "IT"
            },
            {
                "name": "Bubbio",
                "countryCode": "IT"
            },
            {
                "name": "Buriasco",
                "countryCode": "IT"
            },
            {
                "name": "Burolo",
                "countryCode": "IT"
            },
            {
                "name": "Buronzo",
                "countryCode": "IT"
            },
            {
                "name": "Busano",
                "countryCode": "IT"
            },
            {
                "name": "Busca",
                "countryCode": "IT"
            },
            {
                "name": "Bussoleno",
                "countryCode": "IT"
            },
            {
                "name": "Buttigliera Alta",
                "countryCode": "IT"
            },
            {
                "name": "Buttigliera d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Cabella Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Cafasse",
                "countryCode": "IT"
            },
            {
                "name": "Calamandrana",
                "countryCode": "IT"
            },
            {
                "name": "Calasca-Castiglione",
                "countryCode": "IT"
            },
            {
                "name": "Callabiana - Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Calliano",
                "countryCode": "IT"
            },
            {
                "name": "Calosso",
                "countryCode": "IT"
            },
            {
                "name": "Caltignaga",
                "countryCode": "IT"
            },
            {
                "name": "Caluso",
                "countryCode": "IT"
            },
            {
                "name": "Camagna Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Camandona",
                "countryCode": "IT"
            },
            {
                "name": "Cambiano",
                "countryCode": "IT"
            },
            {
                "name": "Cambiasca",
                "countryCode": "IT"
            },
            {
                "name": "Camburzano",
                "countryCode": "IT"
            },
            {
                "name": "Camerana",
                "countryCode": "IT"
            },
            {
                "name": "Camerano",
                "countryCode": "IT"
            },
            {
                "name": "Camerano Casasco",
                "countryCode": "IT"
            },
            {
                "name": "Cameri",
                "countryCode": "IT"
            },
            {
                "name": "Camino",
                "countryCode": "IT"
            },
            {
                "name": "Camo",
                "countryCode": "IT"
            },
            {
                "name": "Campertogno",
                "countryCode": "IT"
            },
            {
                "name": "Campiglia Cervo",
                "countryCode": "IT"
            },
            {
                "name": "Campiglione",
                "countryCode": "IT"
            },
            {
                "name": "Campomolino",
                "countryCode": "IT"
            },
            {
                "name": "Canale",
                "countryCode": "IT"
            },
            {
                "name": "Candelo",
                "countryCode": "IT"
            },
            {
                "name": "Candia Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Candiolo",
                "countryCode": "IT"
            },
            {
                "name": "Canelli",
                "countryCode": "IT"
            },
            {
                "name": "Canischio",
                "countryCode": "IT"
            },
            {
                "name": "Cannero Riviera",
                "countryCode": "IT"
            },
            {
                "name": "Cannobio",
                "countryCode": "IT"
            },
            {
                "name": "Canosio",
                "countryCode": "IT"
            },
            {
                "name": "Cantalupa",
                "countryCode": "IT"
            },
            {
                "name": "Cantalupo Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Cantarana",
                "countryCode": "IT"
            },
            {
                "name": "Cantoira",
                "countryCode": "IT"
            },
            {
                "name": "Caprauna",
                "countryCode": "IT"
            },
            {
                "name": "Caprezzo",
                "countryCode": "IT"
            },
            {
                "name": "Capriata d'Orba",
                "countryCode": "IT"
            },
            {
                "name": "Caprie",
                "countryCode": "IT"
            },
            {
                "name": "Capriglio",
                "countryCode": "IT"
            },
            {
                "name": "Caraglio",
                "countryCode": "IT"
            },
            {
                "name": "Caramagna Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Caravino",
                "countryCode": "IT"
            },
            {
                "name": "Carbonara Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Carcoforo",
                "countryCode": "IT"
            },
            {
                "name": "Cardè",
                "countryCode": "IT"
            },
            {
                "name": "Carema",
                "countryCode": "IT"
            },
            {
                "name": "Carentino",
                "countryCode": "IT"
            },
            {
                "name": "Caresana",
                "countryCode": "IT"
            },
            {
                "name": "Caresanablot",
                "countryCode": "IT"
            },
            {
                "name": "Carezzano Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Carignano",
                "countryCode": "IT"
            },
            {
                "name": "Carisio",
                "countryCode": "IT"
            },
            {
                "name": "Carmagnola",
                "countryCode": "IT"
            },
            {
                "name": "Carpeneto",
                "countryCode": "IT"
            },
            {
                "name": "Carpignano Sesia",
                "countryCode": "IT"
            },
            {
                "name": "Carrega Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Carrosio",
                "countryCode": "IT"
            },
            {
                "name": "Carrù",
                "countryCode": "IT"
            },
            {
                "name": "Cartignano",
                "countryCode": "IT"
            },
            {
                "name": "Cartosio",
                "countryCode": "IT"
            },
            {
                "name": "Casal Cermelli",
                "countryCode": "IT"
            },
            {
                "name": "Casalbeltrame",
                "countryCode": "IT"
            },
            {
                "name": "Casalborgone",
                "countryCode": "IT"
            },
            {
                "name": "Casale Corte Cerro",
                "countryCode": "IT"
            },
            {
                "name": "Casale Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Casaleggio Boiro",
                "countryCode": "IT"
            },
            {
                "name": "Casaleggio Novara",
                "countryCode": "IT"
            },
            {
                "name": "Casalgrasso",
                "countryCode": "IT"
            },
            {
                "name": "Casalino",
                "countryCode": "IT"
            },
            {
                "name": "Casalnoceto",
                "countryCode": "IT"
            },
            {
                "name": "Casalvolone",
                "countryCode": "IT"
            },
            {
                "name": "Casanova Elvo",
                "countryCode": "IT"
            },
            {
                "name": "Casapinta",
                "countryCode": "IT"
            },
            {
                "name": "Casasco",
                "countryCode": "IT"
            },
            {
                "name": "Cascinette d'Ivrea",
                "countryCode": "IT"
            },
            {
                "name": "Caselette",
                "countryCode": "IT"
            },
            {
                "name": "Caselle Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Casorzo",
                "countryCode": "IT"
            },
            {
                "name": "Cassano Spinola",
                "countryCode": "IT"
            },
            {
                "name": "Cassinasco",
                "countryCode": "IT"
            },
            {
                "name": "Cassine",
                "countryCode": "IT"
            },
            {
                "name": "Cassinelle-Concentrico",
                "countryCode": "IT"
            },
            {
                "name": "Castagneto Po",
                "countryCode": "IT"
            },
            {
                "name": "Castagnito",
                "countryCode": "IT"
            },
            {
                "name": "Castagnole Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Castagnole Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Castagnole delle Lanze",
                "countryCode": "IT"
            },
            {
                "name": "Castel Boglione",
                "countryCode": "IT"
            },
            {
                "name": "Castel Rocchero",
                "countryCode": "IT"
            },
            {
                "name": "Castelceriolo",
                "countryCode": "IT"
            },
            {
                "name": "Casteldelfino",
                "countryCode": "IT"
            },
            {
                "name": "Castell'Alfero",
                "countryCode": "IT"
            },
            {
                "name": "Castellamonte",
                "countryCode": "IT"
            },
            {
                "name": "Castellania",
                "countryCode": "IT"
            },
            {
                "name": "Castellar",
                "countryCode": "IT"
            },
            {
                "name": "Castellar Guidobono",
                "countryCode": "IT"
            },
            {
                "name": "Castellazzo Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Castellazzo Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Castellero",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Cervo",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Merli",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Molina",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Sopra Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Stura",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Uzzone",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto d'Erro",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto d'Orba",
                "countryCode": "IT"
            },
            {
                "name": "Castellinaldo",
                "countryCode": "IT"
            },
            {
                "name": "Castellino Tanaro",
                "countryCode": "IT"
            },
            {
                "name": "Castello di Annone",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Belbo",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Calcea",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Don Bosco",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Nigra",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Ceva",
                "countryCode": "IT"
            },
            {
                "name": "Castelspina",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Falletto",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Tinella",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Castino",
                "countryCode": "IT"
            },
            {
                "name": "Cavaglietto",
                "countryCode": "IT"
            },
            {
                "name": "Cavaglio D'Agogna",
                "countryCode": "IT"
            },
            {
                "name": "Cavaglio-Spoccia",
                "countryCode": "IT"
            },
            {
                "name": "Cavaglià",
                "countryCode": "IT"
            },
            {
                "name": "Cavagnolo",
                "countryCode": "IT"
            },
            {
                "name": "Cavallerleone",
                "countryCode": "IT"
            },
            {
                "name": "Cavallermaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cavallirio",
                "countryCode": "IT"
            },
            {
                "name": "Cavatore",
                "countryCode": "IT"
            },
            {
                "name": "Cavour",
                "countryCode": "IT"
            },
            {
                "name": "Cella Monte",
                "countryCode": "IT"
            },
            {
                "name": "Cellarengo",
                "countryCode": "IT"
            },
            {
                "name": "Celle Enomondo",
                "countryCode": "IT"
            },
            {
                "name": "Cellio",
                "countryCode": "IT"
            },
            {
                "name": "Centallo",
                "countryCode": "IT"
            },
            {
                "name": "Ceppo Morelli",
                "countryCode": "IT"
            },
            {
                "name": "Cerano",
                "countryCode": "IT"
            },
            {
                "name": "Cercenasco",
                "countryCode": "IT"
            },
            {
                "name": "Ceres",
                "countryCode": "IT"
            },
            {
                "name": "Ceresane-Curanuova",
                "countryCode": "IT"
            },
            {
                "name": "Cereseto",
                "countryCode": "IT"
            },
            {
                "name": "Ceresole Alba",
                "countryCode": "IT"
            },
            {
                "name": "Ceresole Reale",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Castello",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Grue",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Langhe",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Cerrina",
                "countryCode": "IT"
            },
            {
                "name": "Cerrione",
                "countryCode": "IT"
            },
            {
                "name": "Cerro Tanaro",
                "countryCode": "IT"
            },
            {
                "name": "Cervasca",
                "countryCode": "IT"
            },
            {
                "name": "Cervatto",
                "countryCode": "IT"
            },
            {
                "name": "Cervere",
                "countryCode": "IT"
            },
            {
                "name": "Cesana Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Cesara",
                "countryCode": "IT"
            },
            {
                "name": "Cessole",
                "countryCode": "IT"
            },
            {
                "name": "Ceva",
                "countryCode": "IT"
            },
            {
                "name": "Cherasco",
                "countryCode": "IT"
            },
            {
                "name": "Chialamberto",
                "countryCode": "IT"
            },
            {
                "name": "Chianocco",
                "countryCode": "IT"
            },
            {
                "name": "Chiaverano",
                "countryCode": "IT"
            },
            {
                "name": "Chieri",
                "countryCode": "IT"
            },
            {
                "name": "Chiesa di Macra",
                "countryCode": "IT"
            },
            {
                "name": "Chiesanuova",
                "countryCode": "IT"
            },
            {
                "name": "Chiomonte",
                "countryCode": "IT"
            },
            {
                "name": "Chiusa di Pesio",
                "countryCode": "IT"
            },
            {
                "name": "Chiusa di San Michele",
                "countryCode": "IT"
            },
            {
                "name": "Chiusano d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Chivasso",
                "countryCode": "IT"
            },
            {
                "name": "Ciconio",
                "countryCode": "IT"
            },
            {
                "name": "Cigliano",
                "countryCode": "IT"
            },
            {
                "name": "Cigliè",
                "countryCode": "IT"
            },
            {
                "name": "Cinaglio",
                "countryCode": "IT"
            },
            {
                "name": "Cintano",
                "countryCode": "IT"
            },
            {
                "name": "Cinzano",
                "countryCode": "IT"
            },
            {
                "name": "Ciriè",
                "countryCode": "IT"
            },
            {
                "name": "Cissone",
                "countryCode": "IT"
            },
            {
                "name": "Cisterna d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Civiasco",
                "countryCode": "IT"
            },
            {
                "name": "Clavesana",
                "countryCode": "IT"
            },
            {
                "name": "Claviere",
                "countryCode": "IT"
            },
            {
                "name": "Coassolo",
                "countryCode": "IT"
            },
            {
                "name": "Coassolo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Coazze",
                "countryCode": "IT"
            },
            {
                "name": "Coazzolo",
                "countryCode": "IT"
            },
            {
                "name": "Cocconato",
                "countryCode": "IT"
            },
            {
                "name": "Coggiola",
                "countryCode": "IT"
            },
            {
                "name": "Colazza",
                "countryCode": "IT"
            },
            {
                "name": "Collegno",
                "countryCode": "IT"
            },
            {
                "name": "Colleretto Castelnuovo",
                "countryCode": "IT"
            },
            {
                "name": "Colleretto Giacosa",
                "countryCode": "IT"
            },
            {
                "name": "Collobiano",
                "countryCode": "IT"
            },
            {
                "name": "Comignago",
                "countryCode": "IT"
            },
            {
                "name": "Condove",
                "countryCode": "IT"
            },
            {
                "name": "Coniolo Bricco",
                "countryCode": "IT"
            },
            {
                "name": "Conzano",
                "countryCode": "IT"
            },
            {
                "name": "Corio",
                "countryCode": "IT"
            },
            {
                "name": "Corneliano d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Corsione",
                "countryCode": "IT"
            },
            {
                "name": "Cortandone",
                "countryCode": "IT"
            },
            {
                "name": "Cortanze",
                "countryCode": "IT"
            },
            {
                "name": "Cortazzone",
                "countryCode": "IT"
            },
            {
                "name": "Cortemilia",
                "countryCode": "IT"
            },
            {
                "name": "Cortiglione",
                "countryCode": "IT"
            },
            {
                "name": "Cossano Belbo",
                "countryCode": "IT"
            },
            {
                "name": "Cossano Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Cossato",
                "countryCode": "IT"
            },
            {
                "name": "Cossogno",
                "countryCode": "IT"
            },
            {
                "name": "Cossombrato",
                "countryCode": "IT"
            },
            {
                "name": "Costa Vescovato",
                "countryCode": "IT"
            },
            {
                "name": "Costanzana",
                "countryCode": "IT"
            },
            {
                "name": "Costigliole d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Costiglione Saluzzo",
                "countryCode": "IT"
            },
            {
                "name": "Crava",
                "countryCode": "IT"
            },
            {
                "name": "Cravagliana",
                "countryCode": "IT"
            },
            {
                "name": "Cravanzana",
                "countryCode": "IT"
            },
            {
                "name": "Craveggia",
                "countryCode": "IT"
            },
            {
                "name": "Cremolino",
                "countryCode": "IT"
            },
            {
                "name": "Crescentino",
                "countryCode": "IT"
            },
            {
                "name": "Cressa",
                "countryCode": "IT"
            },
            {
                "name": "Crevacuore",
                "countryCode": "IT"
            },
            {
                "name": "Crevoladossola",
                "countryCode": "IT"
            },
            {
                "name": "Crissolo",
                "countryCode": "IT"
            },
            {
                "name": "Crodo",
                "countryCode": "IT"
            },
            {
                "name": "Crova",
                "countryCode": "IT"
            },
            {
                "name": "Cuccaro Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Cuceglio",
                "countryCode": "IT"
            },
            {
                "name": "Cumiana",
                "countryCode": "IT"
            },
            {
                "name": "Cuneo",
                "countryCode": "IT"
            },
            {
                "name": "Cunico",
                "countryCode": "IT"
            },
            {
                "name": "Cuorgnè",
                "countryCode": "IT"
            },
            {
                "name": "Cureggio",
                "countryCode": "IT"
            },
            {
                "name": "Curino",
                "countryCode": "IT"
            },
            {
                "name": "Cursolo",
                "countryCode": "IT"
            },
            {
                "name": "Demonte",
                "countryCode": "IT"
            },
            {
                "name": "Denice",
                "countryCode": "IT"
            },
            {
                "name": "Dernice",
                "countryCode": "IT"
            },
            {
                "name": "Desana",
                "countryCode": "IT"
            },
            {
                "name": "Diano d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Divignano",
                "countryCode": "IT"
            },
            {
                "name": "Dogliani",
                "countryCode": "IT"
            },
            {
                "name": "Domodossola",
                "countryCode": "IT"
            },
            {
                "name": "Donato",
                "countryCode": "IT"
            },
            {
                "name": "Dormelletto",
                "countryCode": "IT"
            },
            {
                "name": "Dorzano",
                "countryCode": "IT"
            },
            {
                "name": "Dronero",
                "countryCode": "IT"
            },
            {
                "name": "Drubiaglio-Grangia",
                "countryCode": "IT"
            },
            {
                "name": "Druento",
                "countryCode": "IT"
            },
            {
                "name": "Druogno",
                "countryCode": "IT"
            },
            {
                "name": "Dusino",
                "countryCode": "IT"
            },
            {
                "name": "Entracque",
                "countryCode": "IT"
            },
            {
                "name": "Envie",
                "countryCode": "IT"
            },
            {
                "name": "Exilles",
                "countryCode": "IT"
            },
            {
                "name": "Falmenta",
                "countryCode": "IT"
            },
            {
                "name": "Fara Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Farigliano",
                "countryCode": "IT"
            },
            {
                "name": "Faule",
                "countryCode": "IT"
            },
            {
                "name": "Favari-Avatanei",
                "countryCode": "IT"
            },
            {
                "name": "Favria",
                "countryCode": "IT"
            },
            {
                "name": "Feisoglio",
                "countryCode": "IT"
            },
            {
                "name": "Feletto",
                "countryCode": "IT"
            },
            {
                "name": "Felizzano",
                "countryCode": "IT"
            },
            {
                "name": "Fenestrelle",
                "countryCode": "IT"
            },
            {
                "name": "Ferrere",
                "countryCode": "IT"
            },
            {
                "name": "Ferriera",
                "countryCode": "IT"
            },
            {
                "name": "Fiano",
                "countryCode": "IT"
            },
            {
                "name": "Fiorano Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Fleccia-Chianavasso",
                "countryCode": "IT"
            },
            {
                "name": "Fobello",
                "countryCode": "IT"
            },
            {
                "name": "Foglizzo",
                "countryCode": "IT"
            },
            {
                "name": "Fontanella-Ozino",
                "countryCode": "IT"
            },
            {
                "name": "Fontaneto D'Agogna",
                "countryCode": "IT"
            },
            {
                "name": "Fontanetto Po",
                "countryCode": "IT"
            },
            {
                "name": "Fontanile",
                "countryCode": "IT"
            },
            {
                "name": "Formazza",
                "countryCode": "IT"
            },
            {
                "name": "Formigliana",
                "countryCode": "IT"
            },
            {
                "name": "Fornaci",
                "countryCode": "IT"
            },
            {
                "name": "Forno Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Fossano",
                "countryCode": "IT"
            },
            {
                "name": "Frabosa Soprana",
                "countryCode": "IT"
            },
            {
                "name": "Frabosa Sottana",
                "countryCode": "IT"
            },
            {
                "name": "Fraconalto",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla Bisio",
                "countryCode": "IT"
            },
            {
                "name": "Frascaro",
                "countryCode": "IT"
            },
            {
                "name": "Frassinello Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Frassineto Po",
                "countryCode": "IT"
            },
            {
                "name": "Frassinetto",
                "countryCode": "IT"
            },
            {
                "name": "Frassino",
                "countryCode": "IT"
            },
            {
                "name": "Frazione Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Fresonara",
                "countryCode": "IT"
            },
            {
                "name": "Frinco",
                "countryCode": "IT"
            },
            {
                "name": "Front",
                "countryCode": "IT"
            },
            {
                "name": "Frossasco",
                "countryCode": "IT"
            },
            {
                "name": "Frugarolo",
                "countryCode": "IT"
            },
            {
                "name": "Fubine",
                "countryCode": "IT"
            },
            {
                "name": "Gabbio-Cereda-Ramate",
                "countryCode": "IT"
            },
            {
                "name": "Gabiano",
                "countryCode": "IT"
            },
            {
                "name": "Gaglianico",
                "countryCode": "IT"
            },
            {
                "name": "Gaiola",
                "countryCode": "IT"
            },
            {
                "name": "Galliate",
                "countryCode": "IT"
            },
            {
                "name": "Gamalero",
                "countryCode": "IT"
            },
            {
                "name": "Gambasca",
                "countryCode": "IT"
            },
            {
                "name": "Garadassi",
                "countryCode": "IT"
            },
            {
                "name": "Garbagna",
                "countryCode": "IT"
            },
            {
                "name": "Garbagna Novarese",
                "countryCode": "IT"
            },
            {
                "name": "Garessio",
                "countryCode": "IT"
            },
            {
                "name": "Gargallo",
                "countryCode": "IT"
            },
            {
                "name": "Garino",
                "countryCode": "IT"
            },
            {
                "name": "Garzigliana",
                "countryCode": "IT"
            },
            {
                "name": "Gassino Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Gattico",
                "countryCode": "IT"
            },
            {
                "name": "Gattinara",
                "countryCode": "IT"
            },
            {
                "name": "Gavazzana",
                "countryCode": "IT"
            },
            {
                "name": "Gavi",
                "countryCode": "IT"
            },
            {
                "name": "Genola",
                "countryCode": "IT"
            },
            {
                "name": "Gerbido",
                "countryCode": "IT"
            },
            {
                "name": "Gerbole",
                "countryCode": "IT"
            },
            {
                "name": "Gerbole-Zucche",
                "countryCode": "IT"
            },
            {
                "name": "Germagnano",
                "countryCode": "IT"
            },
            {
                "name": "Germagno",
                "countryCode": "IT"
            },
            {
                "name": "Ghemme",
                "countryCode": "IT"
            },
            {
                "name": "Ghiare-Madonna",
                "countryCode": "IT"
            },
            {
                "name": "Ghiffa",
                "countryCode": "IT"
            },
            {
                "name": "Ghislarengo",
                "countryCode": "IT"
            },
            {
                "name": "Giaglione",
                "countryCode": "IT"
            },
            {
                "name": "Giarole",
                "countryCode": "IT"
            },
            {
                "name": "Giaveno",
                "countryCode": "IT"
            },
            {
                "name": "Gifflenga",
                "countryCode": "IT"
            },
            {
                "name": "Gignese",
                "countryCode": "IT"
            },
            {
                "name": "Givoletto",
                "countryCode": "IT"
            },
            {
                "name": "Gonte",
                "countryCode": "IT"
            },
            {
                "name": "Gorzegno",
                "countryCode": "IT"
            },
            {
                "name": "Gottasecca",
                "countryCode": "IT"
            },
            {
                "name": "Govone",
                "countryCode": "IT"
            },
            {
                "name": "Gozzano",
                "countryCode": "IT"
            },
            {
                "name": "Graglia",
                "countryCode": "IT"
            },
            {
                "name": "Grana",
                "countryCode": "IT"
            },
            {
                "name": "Granozzo con Monticello",
                "countryCode": "IT"
            },
            {
                "name": "Gravellona Toce",
                "countryCode": "IT"
            },
            {
                "name": "Gravere",
                "countryCode": "IT"
            },
            {
                "name": "Grazzano Badoglio",
                "countryCode": "IT"
            },
            {
                "name": "Greggio",
                "countryCode": "IT"
            },
            {
                "name": "Gremiasco",
                "countryCode": "IT"
            },
            {
                "name": "Grignasco",
                "countryCode": "IT"
            },
            {
                "name": "Grinzane Cavour",
                "countryCode": "IT"
            },
            {
                "name": "Grognardo",
                "countryCode": "IT"
            },
            {
                "name": "Grondona",
                "countryCode": "IT"
            },
            {
                "name": "Groscavallo",
                "countryCode": "IT"
            },
            {
                "name": "Grosso",
                "countryCode": "IT"
            },
            {
                "name": "Grugliasco",
                "countryCode": "IT"
            },
            {
                "name": "Guardabosone",
                "countryCode": "IT"
            },
            {
                "name": "Guarene",
                "countryCode": "IT"
            },
            {
                "name": "Guazzora",
                "countryCode": "IT"
            },
            {
                "name": "Gurro",
                "countryCode": "IT"
            },
            {
                "name": "Igliano",
                "countryCode": "IT"
            },
            {
                "name": "Incisa Scapaccino",
                "countryCode": "IT"
            },
            {
                "name": "Ingria",
                "countryCode": "IT"
            },
            {
                "name": "Intragna",
                "countryCode": "IT"
            },
            {
                "name": "Inverso Pinasca",
                "countryCode": "IT"
            },
            {
                "name": "Invorio",
                "countryCode": "IT"
            },
            {
                "name": "Isasca",
                "countryCode": "IT"
            },
            {
                "name": "Isola Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Isola d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Isolabella",
                "countryCode": "IT"
            },
            {
                "name": "Issiglio",
                "countryCode": "IT"
            },
            {
                "name": "Ivrea",
                "countryCode": "IT"
            },
            {
                "name": "La Cassa",
                "countryCode": "IT"
            },
            {
                "name": "La Loggia",
                "countryCode": "IT"
            },
            {
                "name": "La Morra",
                "countryCode": "IT"
            },
            {
                "name": "La Villa",
                "countryCode": "IT"
            },
            {
                "name": "Lagnasco",
                "countryCode": "IT"
            },
            {
                "name": "Lamporo",
                "countryCode": "IT"
            },
            {
                "name": "Landiona",
                "countryCode": "IT"
            },
            {
                "name": "Lanvario",
                "countryCode": "IT"
            },
            {
                "name": "Lanzo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Lauriano",
                "countryCode": "IT"
            },
            {
                "name": "Leini",
                "countryCode": "IT"
            },
            {
                "name": "Lemie",
                "countryCode": "IT"
            },
            {
                "name": "Lenta",
                "countryCode": "IT"
            },
            {
                "name": "Lequio Berria",
                "countryCode": "IT"
            },
            {
                "name": "Lequio Tanaro",
                "countryCode": "IT"
            },
            {
                "name": "Lerma",
                "countryCode": "IT"
            },
            {
                "name": "Lesa",
                "countryCode": "IT"
            },
            {
                "name": "Lesegno",
                "countryCode": "IT"
            },
            {
                "name": "Lesna",
                "countryCode": "IT"
            },
            {
                "name": "Lessolo",
                "countryCode": "IT"
            },
            {
                "name": "Lessona",
                "countryCode": "IT"
            },
            {
                "name": "Levice",
                "countryCode": "IT"
            },
            {
                "name": "Levone",
                "countryCode": "IT"
            },
            {
                "name": "Lignana",
                "countryCode": "IT"
            },
            {
                "name": "Limone Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Lisio",
                "countryCode": "IT"
            },
            {
                "name": "Litta Parodi-Cascinagrossa",
                "countryCode": "IT"
            },
            {
                "name": "Livera",
                "countryCode": "IT"
            },
            {
                "name": "Livorno Ferraris",
                "countryCode": "IT"
            },
            {
                "name": "Loazzolo",
                "countryCode": "IT"
            },
            {
                "name": "Locana",
                "countryCode": "IT"
            },
            {
                "name": "Lombardore",
                "countryCode": "IT"
            },
            {
                "name": "Lombriasco",
                "countryCode": "IT"
            },
            {
                "name": "Loranzè",
                "countryCode": "IT"
            },
            {
                "name": "Loreglia",
                "countryCode": "IT"
            },
            {
                "name": "Lozzolo",
                "countryCode": "IT"
            },
            {
                "name": "Lu",
                "countryCode": "IT"
            },
            {
                "name": "Lugnacco",
                "countryCode": "IT"
            },
            {
                "name": "Lumellogno",
                "countryCode": "IT"
            },
            {
                "name": "Luserna",
                "countryCode": "IT"
            },
            {
                "name": "Lusernetta",
                "countryCode": "IT"
            },
            {
                "name": "Lusigliè",
                "countryCode": "IT"
            },
            {
                "name": "Macello",
                "countryCode": "IT"
            },
            {
                "name": "Macra",
                "countryCode": "IT"
            },
            {
                "name": "Macugnaga",
                "countryCode": "IT"
            },
            {
                "name": "Madonna del Sasso",
                "countryCode": "IT"
            },
            {
                "name": "Maggiora",
                "countryCode": "IT"
            },
            {
                "name": "Magliano Alfieri",
                "countryCode": "IT"
            },
            {
                "name": "Magliano Alpi",
                "countryCode": "IT"
            },
            {
                "name": "Maglione",
                "countryCode": "IT"
            },
            {
                "name": "Maglione-Crosa",
                "countryCode": "IT"
            },
            {
                "name": "Magnano",
                "countryCode": "IT"
            },
            {
                "name": "Malesco",
                "countryCode": "IT"
            },
            {
                "name": "Malvicino",
                "countryCode": "IT"
            },
            {
                "name": "Mandello Vitta",
                "countryCode": "IT"
            },
            {
                "name": "Mandrogne",
                "countryCode": "IT"
            },
            {
                "name": "Mango",
                "countryCode": "IT"
            },
            {
                "name": "Manta",
                "countryCode": "IT"
            },
            {
                "name": "Mappano",
                "countryCode": "IT"
            },
            {
                "name": "Marano Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Maranzana",
                "countryCode": "IT"
            },
            {
                "name": "Marene",
                "countryCode": "IT"
            },
            {
                "name": "Marentino",
                "countryCode": "IT"
            },
            {
                "name": "Maretto",
                "countryCode": "IT"
            },
            {
                "name": "Margarita",
                "countryCode": "IT"
            },
            {
                "name": "Marmora",
                "countryCode": "IT"
            },
            {
                "name": "Marocchi",
                "countryCode": "IT"
            },
            {
                "name": "Marsaglia",
                "countryCode": "IT"
            },
            {
                "name": "Martiniana Po",
                "countryCode": "IT"
            },
            {
                "name": "Masera",
                "countryCode": "IT"
            },
            {
                "name": "Masio",
                "countryCode": "IT"
            },
            {
                "name": "Massazza",
                "countryCode": "IT"
            },
            {
                "name": "Massello",
                "countryCode": "IT"
            },
            {
                "name": "Masserano",
                "countryCode": "IT"
            },
            {
                "name": "Massino Visconti",
                "countryCode": "IT"
            },
            {
                "name": "Massiola",
                "countryCode": "IT"
            },
            {
                "name": "Mathi",
                "countryCode": "IT"
            },
            {
                "name": "Mattie",
                "countryCode": "IT"
            },
            {
                "name": "Mazze",
                "countryCode": "IT"
            },
            {
                "name": "Meana di Susa",
                "countryCode": "IT"
            },
            {
                "name": "Meina",
                "countryCode": "IT"
            },
            {
                "name": "Melazzo",
                "countryCode": "IT"
            },
            {
                "name": "Melle",
                "countryCode": "IT"
            },
            {
                "name": "Merana",
                "countryCode": "IT"
            },
            {
                "name": "Mercenasco",
                "countryCode": "IT"
            },
            {
                "name": "Mergozzo",
                "countryCode": "IT"
            },
            {
                "name": "Meugliano",
                "countryCode": "IT"
            },
            {
                "name": "Mezzana Mortigliengo",
                "countryCode": "IT"
            },
            {
                "name": "Mezzenile",
                "countryCode": "IT"
            },
            {
                "name": "Mezzomerico",
                "countryCode": "IT"
            },
            {
                "name": "Miagliano",
                "countryCode": "IT"
            },
            {
                "name": "Miasino",
                "countryCode": "IT"
            },
            {
                "name": "Miazzina",
                "countryCode": "IT"
            },
            {
                "name": "Milanere",
                "countryCode": "IT"
            },
            {
                "name": "Mirabello Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Moasca",
                "countryCode": "IT"
            },
            {
                "name": "Moiola",
                "countryCode": "IT"
            },
            {
                "name": "Molare",
                "countryCode": "IT"
            },
            {
                "name": "Molino dei Torti",
                "countryCode": "IT"
            },
            {
                "name": "Mollia",
                "countryCode": "IT"
            },
            {
                "name": "Mombaldone",
                "countryCode": "IT"
            },
            {
                "name": "Mombarcaro",
                "countryCode": "IT"
            },
            {
                "name": "Mombaruzzo",
                "countryCode": "IT"
            },
            {
                "name": "Mombasiglio",
                "countryCode": "IT"
            },
            {
                "name": "Mombello Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Mombello di Torino",
                "countryCode": "IT"
            },
            {
                "name": "Mombercelli",
                "countryCode": "IT"
            },
            {
                "name": "Momo",
                "countryCode": "IT"
            },
            {
                "name": "Mompantero",
                "countryCode": "IT"
            },
            {
                "name": "Momperone",
                "countryCode": "IT"
            },
            {
                "name": "Monale",
                "countryCode": "IT"
            },
            {
                "name": "Monastero Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Monastero di Lanzo",
                "countryCode": "IT"
            },
            {
                "name": "Monastero di Vasco",
                "countryCode": "IT"
            },
            {
                "name": "Monasterolo",
                "countryCode": "IT"
            },
            {
                "name": "Monasterolo Casotto",
                "countryCode": "IT"
            },
            {
                "name": "Monasterolo di Savigliano",
                "countryCode": "IT"
            },
            {
                "name": "Moncalieri",
                "countryCode": "IT"
            },
            {
                "name": "Moncalvo",
                "countryCode": "IT"
            },
            {
                "name": "Moncenisio",
                "countryCode": "IT"
            },
            {
                "name": "Moncestino",
                "countryCode": "IT"
            },
            {
                "name": "Monchiero Borgonuovo",
                "countryCode": "IT"
            },
            {
                "name": "Moncrivello",
                "countryCode": "IT"
            },
            {
                "name": "Moncucco Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Monesiglio",
                "countryCode": "IT"
            },
            {
                "name": "Monforte d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Mongardino",
                "countryCode": "IT"
            },
            {
                "name": "Mongiardino Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Mongrando",
                "countryCode": "IT"
            },
            {
                "name": "Monleale",
                "countryCode": "IT"
            },
            {
                "name": "Montabone",
                "countryCode": "IT"
            },
            {
                "name": "Montacuto",
                "countryCode": "IT"
            },
            {
                "name": "Montafia",
                "countryCode": "IT"
            },
            {
                "name": "Montaldeo",
                "countryCode": "IT"
            },
            {
                "name": "Montaldo Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Montaldo Roero",
                "countryCode": "IT"
            },
            {
                "name": "Montaldo Scarampi",
                "countryCode": "IT"
            },
            {
                "name": "Montaldo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Montaldo di Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Montalenghe",
                "countryCode": "IT"
            },
            {
                "name": "Montalto Dora",
                "countryCode": "IT"
            },
            {
                "name": "Montanaro",
                "countryCode": "IT"
            },
            {
                "name": "Montanera",
                "countryCode": "IT"
            },
            {
                "name": "Montariolo",
                "countryCode": "IT"
            },
            {
                "name": "Montecastello",
                "countryCode": "IT"
            },
            {
                "name": "Montechiaro d'Acqui",
                "countryCode": "IT"
            },
            {
                "name": "Montechiaro d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Montecrestese",
                "countryCode": "IT"
            },
            {
                "name": "Montegioco",
                "countryCode": "IT"
            },
            {
                "name": "Montegrosso",
                "countryCode": "IT"
            },
            {
                "name": "Montelera",
                "countryCode": "IT"
            },
            {
                "name": "Montelupo Albese",
                "countryCode": "IT"
            },
            {
                "name": "Montemagno",
                "countryCode": "IT"
            },
            {
                "name": "Montemale di Cuneo",
                "countryCode": "IT"
            },
            {
                "name": "Montemarzino",
                "countryCode": "IT"
            },
            {
                "name": "Monterosso Grana",
                "countryCode": "IT"
            },
            {
                "name": "Montescheno",
                "countryCode": "IT"
            },
            {
                "name": "Monteu Roero",
                "countryCode": "IT"
            },
            {
                "name": "Monteu da Po",
                "countryCode": "IT"
            },
            {
                "name": "Montezemolo",
                "countryCode": "IT"
            },
            {
                "name": "Monticello d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Montiglio",
                "countryCode": "IT"
            },
            {
                "name": "Montà",
                "countryCode": "IT"
            },
            {
                "name": "Morano sul Po",
                "countryCode": "IT"
            },
            {
                "name": "Moransengo",
                "countryCode": "IT"
            },
            {
                "name": "Morbello",
                "countryCode": "IT"
            },
            {
                "name": "Moretta",
                "countryCode": "IT"
            },
            {
                "name": "Moriondo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Mornese",
                "countryCode": "IT"
            },
            {
                "name": "Morozzo",
                "countryCode": "IT"
            },
            {
                "name": "Morsasco",
                "countryCode": "IT"
            },
            {
                "name": "Mosso",
                "countryCode": "IT"
            },
            {
                "name": "Mosso Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Motta Dè Conti",
                "countryCode": "IT"
            },
            {
                "name": "Mottalciata",
                "countryCode": "IT"
            },
            {
                "name": "Murazzano",
                "countryCode": "IT"
            },
            {
                "name": "Murello",
                "countryCode": "IT"
            },
            {
                "name": "Murisengo",
                "countryCode": "IT"
            },
            {
                "name": "Mussotto",
                "countryCode": "IT"
            },
            {
                "name": "Muzzano",
                "countryCode": "IT"
            },
            {
                "name": "Narzole",
                "countryCode": "IT"
            },
            {
                "name": "Nebbiuno",
                "countryCode": "IT"
            },
            {
                "name": "Neive-Borgonovo",
                "countryCode": "IT"
            },
            {
                "name": "Netro",
                "countryCode": "IT"
            },
            {
                "name": "Neviglie",
                "countryCode": "IT"
            },
            {
                "name": "Nibbiola",
                "countryCode": "IT"
            },
            {
                "name": "Nichelino",
                "countryCode": "IT"
            },
            {
                "name": "Niella Belbo",
                "countryCode": "IT"
            },
            {
                "name": "Niella Tanaro",
                "countryCode": "IT"
            },
            {
                "name": "Nizza Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Noasca",
                "countryCode": "IT"
            },
            {
                "name": "Nole",
                "countryCode": "IT"
            },
            {
                "name": "Nomaglio",
                "countryCode": "IT"
            },
            {
                "name": "None",
                "countryCode": "IT"
            },
            {
                "name": "Nonio",
                "countryCode": "IT"
            },
            {
                "name": "Novalesa",
                "countryCode": "IT"
            },
            {
                "name": "Novara",
                "countryCode": "IT"
            },
            {
                "name": "Novello",
                "countryCode": "IT"
            },
            {
                "name": "Novi Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Nucetto",
                "countryCode": "IT"
            },
            {
                "name": "Occhieppo Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Occhieppo Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Occimiano",
                "countryCode": "IT"
            },
            {
                "name": "Odalengo Grande",
                "countryCode": "IT"
            },
            {
                "name": "Odalengo Piccolo",
                "countryCode": "IT"
            },
            {
                "name": "Oggebbio",
                "countryCode": "IT"
            },
            {
                "name": "Oglianico",
                "countryCode": "IT"
            },
            {
                "name": "Olcenengo",
                "countryCode": "IT"
            },
            {
                "name": "Oldenico",
                "countryCode": "IT"
            },
            {
                "name": "Oleggio",
                "countryCode": "IT"
            },
            {
                "name": "Oleggio Castello",
                "countryCode": "IT"
            },
            {
                "name": "Olivola",
                "countryCode": "IT"
            },
            {
                "name": "Olmo Gentile",
                "countryCode": "IT"
            },
            {
                "name": "Omegna",
                "countryCode": "IT"
            },
            {
                "name": "Oncino",
                "countryCode": "IT"
            },
            {
                "name": "Orbassano",
                "countryCode": "IT"
            },
            {
                "name": "Orio Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Ormea",
                "countryCode": "IT"
            },
            {
                "name": "Ornavasso",
                "countryCode": "IT"
            },
            {
                "name": "Orsara Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Orta San Giulio",
                "countryCode": "IT"
            },
            {
                "name": "Osasco",
                "countryCode": "IT"
            },
            {
                "name": "Osasio",
                "countryCode": "IT"
            },
            {
                "name": "Ostana",
                "countryCode": "IT"
            },
            {
                "name": "Ottiglio",
                "countryCode": "IT"
            },
            {
                "name": "Oulx",
                "countryCode": "IT"
            },
            {
                "name": "Ovada",
                "countryCode": "IT"
            },
            {
                "name": "Oviglio",
                "countryCode": "IT"
            },
            {
                "name": "Ozegna",
                "countryCode": "IT"
            },
            {
                "name": "Ozzano Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Paderna",
                "countryCode": "IT"
            },
            {
                "name": "Paesana",
                "countryCode": "IT"
            },
            {
                "name": "Pagno",
                "countryCode": "IT"
            },
            {
                "name": "Palazzo Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Palazzolo Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Pallanza-Intra-Suna",
                "countryCode": "IT"
            },
            {
                "name": "Pallanzeno",
                "countryCode": "IT"
            },
            {
                "name": "Pamparato",
                "countryCode": "IT"
            },
            {
                "name": "Pancalieri",
                "countryCode": "IT"
            },
            {
                "name": "Parella",
                "countryCode": "IT"
            },
            {
                "name": "Pareto",
                "countryCode": "IT"
            },
            {
                "name": "Parodi Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Paroldo",
                "countryCode": "IT"
            },
            {
                "name": "Paruzzaro",
                "countryCode": "IT"
            },
            {
                "name": "Passerano Marmorito",
                "countryCode": "IT"
            },
            {
                "name": "Pasta",
                "countryCode": "IT"
            },
            {
                "name": "Pasturana",
                "countryCode": "IT"
            },
            {
                "name": "Pavarolo",
                "countryCode": "IT"
            },
            {
                "name": "Pavone Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Pecco",
                "countryCode": "IT"
            },
            {
                "name": "Pecetto",
                "countryCode": "IT"
            },
            {
                "name": "Pecetto di Valenza",
                "countryCode": "IT"
            },
            {
                "name": "Pella",
                "countryCode": "IT"
            },
            {
                "name": "Penango",
                "countryCode": "IT"
            },
            {
                "name": "Perletto",
                "countryCode": "IT"
            },
            {
                "name": "Perlo",
                "countryCode": "IT"
            },
            {
                "name": "Pernate",
                "countryCode": "IT"
            },
            {
                "name": "Perosa Argentina",
                "countryCode": "IT"
            },
            {
                "name": "Perosa Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Perrero",
                "countryCode": "IT"
            },
            {
                "name": "Pertengo",
                "countryCode": "IT"
            },
            {
                "name": "Pertusio",
                "countryCode": "IT"
            },
            {
                "name": "Pessinetto",
                "countryCode": "IT"
            },
            {
                "name": "Pessione",
                "countryCode": "IT"
            },
            {
                "name": "Pettenasco",
                "countryCode": "IT"
            },
            {
                "name": "Pettinengo",
                "countryCode": "IT"
            },
            {
                "name": "Peveragno",
                "countryCode": "IT"
            },
            {
                "name": "Pezzana",
                "countryCode": "IT"
            },
            {
                "name": "Pezzolo Valle Uzzone",
                "countryCode": "IT"
            },
            {
                "name": "Piana San Raffaele",
                "countryCode": "IT"
            },
            {
                "name": "Pianezza",
                "countryCode": "IT"
            },
            {
                "name": "Pianfei",
                "countryCode": "IT"
            },
            {
                "name": "Piano-Molini d'Isola",
                "countryCode": "IT"
            },
            {
                "name": "Piasco",
                "countryCode": "IT"
            },
            {
                "name": "Piatto",
                "countryCode": "IT"
            },
            {
                "name": "Piazza",
                "countryCode": "IT"
            },
            {
                "name": "Piea",
                "countryCode": "IT"
            },
            {
                "name": "Piedicavallo",
                "countryCode": "IT"
            },
            {
                "name": "Piedimulera",
                "countryCode": "IT"
            },
            {
                "name": "Pietra Marazzi",
                "countryCode": "IT"
            },
            {
                "name": "Pietraporzio",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Vergonte",
                "countryCode": "IT"
            },
            {
                "name": "Pila",
                "countryCode": "IT"
            },
            {
                "name": "Pinasca-Dubbione",
                "countryCode": "IT"
            },
            {
                "name": "Pinerolo",
                "countryCode": "IT"
            },
            {
                "name": "Pino Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Pino d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Piobesi Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Piobesi d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Piode",
                "countryCode": "IT"
            },
            {
                "name": "Piossasco",
                "countryCode": "IT"
            },
            {
                "name": "Piovera",
                "countryCode": "IT"
            },
            {
                "name": "Piovà Massaia",
                "countryCode": "IT"
            },
            {
                "name": "Piozzo",
                "countryCode": "IT"
            },
            {
                "name": "Pisano",
                "countryCode": "IT"
            },
            {
                "name": "Piscina",
                "countryCode": "IT"
            },
            {
                "name": "Piverone",
                "countryCode": "IT"
            },
            {
                "name": "Pleyne",
                "countryCode": "IT"
            },
            {
                "name": "Pocapaglia",
                "countryCode": "IT"
            },
            {
                "name": "Pogno",
                "countryCode": "IT"
            },
            {
                "name": "Poirino",
                "countryCode": "IT"
            },
            {
                "name": "Pollone",
                "countryCode": "IT"
            },
            {
                "name": "Polonghera",
                "countryCode": "IT"
            },
            {
                "name": "Pomaretto",
                "countryCode": "IT"
            },
            {
                "name": "Pomaro Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Pombia",
                "countryCode": "IT"
            },
            {
                "name": "Ponderano",
                "countryCode": "IT"
            },
            {
                "name": "Pont-Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Pontechianale",
                "countryCode": "IT"
            },
            {
                "name": "Pontecurone",
                "countryCode": "IT"
            },
            {
                "name": "Pontestura",
                "countryCode": "IT"
            },
            {
                "name": "Ponti",
                "countryCode": "IT"
            },
            {
                "name": "Ponzano Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Ponzone",
                "countryCode": "IT"
            },
            {
                "name": "Portacomaro",
                "countryCode": "IT"
            },
            {
                "name": "Porte",
                "countryCode": "IT"
            },
            {
                "name": "Portula",
                "countryCode": "IT"
            },
            {
                "name": "Postua",
                "countryCode": "IT"
            },
            {
                "name": "Pozzol Groppo",
                "countryCode": "IT"
            },
            {
                "name": "Pozzolo Formigaro",
                "countryCode": "IT"
            },
            {
                "name": "Pradleves",
                "countryCode": "IT"
            },
            {
                "name": "Pragelato-Ruà",
                "countryCode": "IT"
            },
            {
                "name": "Prali",
                "countryCode": "IT"
            },
            {
                "name": "Pralormo",
                "countryCode": "IT"
            },
            {
                "name": "Pralungo",
                "countryCode": "IT"
            },
            {
                "name": "Pramollo",
                "countryCode": "IT"
            },
            {
                "name": "Prarolo",
                "countryCode": "IT"
            },
            {
                "name": "Prarostino",
                "countryCode": "IT"
            },
            {
                "name": "Prasco",
                "countryCode": "IT"
            },
            {
                "name": "Prascorsano",
                "countryCode": "IT"
            },
            {
                "name": "Pratiglione",
                "countryCode": "IT"
            },
            {
                "name": "Prato Sesia",
                "countryCode": "IT"
            },
            {
                "name": "Pray",
                "countryCode": "IT"
            },
            {
                "name": "Prazzo Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Predosa",
                "countryCode": "IT"
            },
            {
                "name": "Premeno",
                "countryCode": "IT"
            },
            {
                "name": "Premia",
                "countryCode": "IT"
            },
            {
                "name": "Premosello-Chiovenda",
                "countryCode": "IT"
            },
            {
                "name": "Priero",
                "countryCode": "IT"
            },
            {
                "name": "Priocca",
                "countryCode": "IT"
            },
            {
                "name": "Priola",
                "countryCode": "IT"
            },
            {
                "name": "Provincia Verbano-Cusio-Ossola",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Alessandria",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Asti",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Biella",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Cuneo",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Novara",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Vercelli",
                "countryCode": "IT"
            },
            {
                "name": "Prunetto",
                "countryCode": "IT"
            },
            {
                "name": "Quagliuzzo",
                "countryCode": "IT"
            },
            {
                "name": "Quaranti",
                "countryCode": "IT"
            },
            {
                "name": "Quaregna",
                "countryCode": "IT"
            },
            {
                "name": "Quargnento",
                "countryCode": "IT"
            },
            {
                "name": "Quarna Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Quarna Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Quarona",
                "countryCode": "IT"
            },
            {
                "name": "Quassolo",
                "countryCode": "IT"
            },
            {
                "name": "Quattordio",
                "countryCode": "IT"
            },
            {
                "name": "Quincinetto",
                "countryCode": "IT"
            },
            {
                "name": "Quinto Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Racconigi",
                "countryCode": "IT"
            },
            {
                "name": "Rassa",
                "countryCode": "IT"
            },
            {
                "name": "Re",
                "countryCode": "IT"
            },
            {
                "name": "Reano",
                "countryCode": "IT"
            },
            {
                "name": "Recetto",
                "countryCode": "IT"
            },
            {
                "name": "Refrancore",
                "countryCode": "IT"
            },
            {
                "name": "Revello",
                "countryCode": "IT"
            },
            {
                "name": "Revigliasco",
                "countryCode": "IT"
            },
            {
                "name": "Revigliasco d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Ribordone",
                "countryCode": "IT"
            },
            {
                "name": "Ricaldone",
                "countryCode": "IT"
            },
            {
                "name": "Ricca",
                "countryCode": "IT"
            },
            {
                "name": "Rifreddo",
                "countryCode": "IT"
            },
            {
                "name": "Rima",
                "countryCode": "IT"
            },
            {
                "name": "Rimasco",
                "countryCode": "IT"
            },
            {
                "name": "Rimella",
                "countryCode": "IT"
            },
            {
                "name": "Rittana",
                "countryCode": "IT"
            },
            {
                "name": "Riva",
                "countryCode": "IT"
            },
            {
                "name": "Riva Presso Chieri",
                "countryCode": "IT"
            },
            {
                "name": "Riva Valdobbia",
                "countryCode": "IT"
            },
            {
                "name": "Rivalba",
                "countryCode": "IT"
            },
            {
                "name": "Rivalta Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Rivalta di Torino",
                "countryCode": "IT"
            },
            {
                "name": "Rivara",
                "countryCode": "IT"
            },
            {
                "name": "Rivarolo Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Rivarone",
                "countryCode": "IT"
            },
            {
                "name": "Rivarossa",
                "countryCode": "IT"
            },
            {
                "name": "Rive",
                "countryCode": "IT"
            },
            {
                "name": "Rivoli",
                "countryCode": "IT"
            },
            {
                "name": "Roapiana",
                "countryCode": "IT"
            },
            {
                "name": "Roaschia",
                "countryCode": "IT"
            },
            {
                "name": "Roascio",
                "countryCode": "IT"
            },
            {
                "name": "Roasio",
                "countryCode": "IT"
            },
            {
                "name": "Roata Rossi",
                "countryCode": "IT"
            },
            {
                "name": "Roatto",
                "countryCode": "IT"
            },
            {
                "name": "Robassomero",
                "countryCode": "IT"
            },
            {
                "name": "Robella",
                "countryCode": "IT"
            },
            {
                "name": "Robilante",
                "countryCode": "IT"
            },
            {
                "name": "Roburent",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Cigliè",
                "countryCode": "IT"
            },
            {
                "name": "Rocca De' Baldi",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Grimalda",
                "countryCode": "IT"
            },
            {
                "name": "Rocca d'Arazzo",
                "countryCode": "IT"
            },
            {
                "name": "Roccabruna",
                "countryCode": "IT"
            },
            {
                "name": "Roccaforte Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Roccaforte Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Roccasparvera",
                "countryCode": "IT"
            },
            {
                "name": "Roccaverano",
                "countryCode": "IT"
            },
            {
                "name": "Roccavione",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Belbo",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Palafea",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Tanaro",
                "countryCode": "IT"
            },
            {
                "name": "Roddi",
                "countryCode": "IT"
            },
            {
                "name": "Roddino",
                "countryCode": "IT"
            },
            {
                "name": "Rodello",
                "countryCode": "IT"
            },
            {
                "name": "Roletto",
                "countryCode": "IT"
            },
            {
                "name": "Romagnano Sesia",
                "countryCode": "IT"
            },
            {
                "name": "Romano Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Romentino",
                "countryCode": "IT"
            },
            {
                "name": "Ronco Biellese",
                "countryCode": "IT"
            },
            {
                "name": "Ronco Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Rondissone",
                "countryCode": "IT"
            },
            {
                "name": "Ronsecco",
                "countryCode": "IT"
            },
            {
                "name": "Roppolo",
                "countryCode": "IT"
            },
            {
                "name": "Roreto",
                "countryCode": "IT"
            },
            {
                "name": "Rorà",
                "countryCode": "IT"
            },
            {
                "name": "Rosazza",
                "countryCode": "IT"
            },
            {
                "name": "Rosignano Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Rossa",
                "countryCode": "IT"
            },
            {
                "name": "Rossana",
                "countryCode": "IT"
            },
            {
                "name": "Rosta",
                "countryCode": "IT"
            },
            {
                "name": "Roure",
                "countryCode": "IT"
            },
            {
                "name": "Rovasenda",
                "countryCode": "IT"
            },
            {
                "name": "Rubiana",
                "countryCode": "IT"
            },
            {
                "name": "Rueglio",
                "countryCode": "IT"
            },
            {
                "name": "Ruffia",
                "countryCode": "IT"
            },
            {
                "name": "Sabbia",
                "countryCode": "IT"
            },
            {
                "name": "Sagliano Micca",
                "countryCode": "IT"
            },
            {
                "name": "Sala Biellese",
                "countryCode": "IT"
            },
            {
                "name": "Sala Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Salasco",
                "countryCode": "IT"
            },
            {
                "name": "Salassa",
                "countryCode": "IT"
            },
            {
                "name": "Salbertrand",
                "countryCode": "IT"
            },
            {
                "name": "Sale",
                "countryCode": "IT"
            },
            {
                "name": "Sale San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Sale delle Langhe",
                "countryCode": "IT"
            },
            {
                "name": "Salerano Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Sali Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Saliceto",
                "countryCode": "IT"
            },
            {
                "name": "Salmour",
                "countryCode": "IT"
            },
            {
                "name": "Saluggia",
                "countryCode": "IT"
            },
            {
                "name": "Salussola",
                "countryCode": "IT"
            },
            {
                "name": "Saluzzo",
                "countryCode": "IT"
            },
            {
                "name": "Salza di Pinerolo",
                "countryCode": "IT"
            },
            {
                "name": "Sambuco",
                "countryCode": "IT"
            },
            {
                "name": "Samone",
                "countryCode": "IT"
            },
            {
                "name": "Sampeyre",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto Belbo",
                "countryCode": "IT"
            },
            {
                "name": "San Benigno Canavese",
                "countryCode": "IT"
            },
            {
                "name": "San Bernardino Verbano",
                "countryCode": "IT"
            },
            {
                "name": "San Carlo Canavese",
                "countryCode": "IT"
            },
            {
                "name": "San Colombano Belmonte",
                "countryCode": "IT"
            },
            {
                "name": "San Cristoforo",
                "countryCode": "IT"
            },
            {
                "name": "San Damiano Macra",
                "countryCode": "IT"
            },
            {
                "name": "San Damiano d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "San Defendente",
                "countryCode": "IT"
            },
            {
                "name": "San Didero",
                "countryCode": "IT"
            },
            {
                "name": "San Francesco al Campo",
                "countryCode": "IT"
            },
            {
                "name": "San Germano Chisone",
                "countryCode": "IT"
            },
            {
                "name": "San Germano Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "San Gillio",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Canavese",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Scarampi",
                "countryCode": "IT"
            },
            {
                "name": "San Giorio",
                "countryCode": "IT"
            },
            {
                "name": "San Giuliano Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "San Giusto Canavese",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Alfieri",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Canavese",
                "countryCode": "IT"
            },
            {
                "name": "San Marzano Oliveto",
                "countryCode": "IT"
            },
            {
                "name": "San Maurizio",
                "countryCode": "IT"
            },
            {
                "name": "San Maurizio D'Opaglio",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Torinese",
                "countryCode": "IT"
            },
            {
                "name": "San Michele Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "San Nazzaro Sesia",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo Solbrito",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Mosezzo",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Val Lemina",
                "countryCode": "IT"
            },
            {
                "name": "San Ponso",
                "countryCode": "IT"
            },
            {
                "name": "San Raffaele Cimena",
                "countryCode": "IT"
            },
            {
                "name": "San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "San Salvatore Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "San Sebastiano Curone",
                "countryCode": "IT"
            },
            {
                "name": "San Sebastiano da Po",
                "countryCode": "IT"
            },
            {
                "name": "San Secondo di Pinerolo",
                "countryCode": "IT"
            },
            {
                "name": "Sandigliano",
                "countryCode": "IT"
            },
            {
                "name": "Sanfront",
                "countryCode": "IT"
            },
            {
                "name": "Sanfrè",
                "countryCode": "IT"
            },
            {
                "name": "Sangano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata Fossili",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Albano Stura",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ambrogio di Torino",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonino di Susa",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Santa Vittoria d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Santena",
                "countryCode": "IT"
            },
            {
                "name": "Santhià",
                "countryCode": "IT"
            },
            {
                "name": "Santino",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano Belbo",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano Roero",
                "countryCode": "IT"
            },
            {
                "name": "Sardigliano",
                "countryCode": "IT"
            },
            {
                "name": "Sarezzano",
                "countryCode": "IT"
            },
            {
                "name": "Sauze d'Oulx",
                "countryCode": "IT"
            },
            {
                "name": "Sauze di Cesana",
                "countryCode": "IT"
            },
            {
                "name": "Savigliano",
                "countryCode": "IT"
            },
            {
                "name": "Savonera",
                "countryCode": "IT"
            },
            {
                "name": "Scagnello",
                "countryCode": "IT"
            },
            {
                "name": "Scalenghe",
                "countryCode": "IT"
            },
            {
                "name": "Scarmagno",
                "countryCode": "IT"
            },
            {
                "name": "Scarnafigi",
                "countryCode": "IT"
            },
            {
                "name": "Sciolze",
                "countryCode": "IT"
            },
            {
                "name": "Scopa",
                "countryCode": "IT"
            },
            {
                "name": "Scopello",
                "countryCode": "IT"
            },
            {
                "name": "Scurzolengo",
                "countryCode": "IT"
            },
            {
                "name": "Selve Marcone",
                "countryCode": "IT"
            },
            {
                "name": "Seppiana",
                "countryCode": "IT"
            },
            {
                "name": "Serole",
                "countryCode": "IT"
            },
            {
                "name": "Serralunga d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Serralunga di Crea",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle Langhe",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle Sesia",
                "countryCode": "IT"
            },
            {
                "name": "Serre",
                "countryCode": "IT"
            },
            {
                "name": "Sessame",
                "countryCode": "IT"
            },
            {
                "name": "Sestriere",
                "countryCode": "IT"
            },
            {
                "name": "Settime",
                "countryCode": "IT"
            },
            {
                "name": "Settimo Rottaro",
                "countryCode": "IT"
            },
            {
                "name": "Settimo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Settimo Vittone",
                "countryCode": "IT"
            },
            {
                "name": "Sezzadio",
                "countryCode": "IT"
            },
            {
                "name": "Sillavengo",
                "countryCode": "IT"
            },
            {
                "name": "Silvano d'Orba",
                "countryCode": "IT"
            },
            {
                "name": "Sinio",
                "countryCode": "IT"
            },
            {
                "name": "Sizzano",
                "countryCode": "IT"
            },
            {
                "name": "Soglio",
                "countryCode": "IT"
            },
            {
                "name": "Solero",
                "countryCode": "IT"
            },
            {
                "name": "Solonghello",
                "countryCode": "IT"
            },
            {
                "name": "Somano",
                "countryCode": "IT"
            },
            {
                "name": "Sommariva Perno",
                "countryCode": "IT"
            },
            {
                "name": "Sommariva del Bosco",
                "countryCode": "IT"
            },
            {
                "name": "Soprana",
                "countryCode": "IT"
            },
            {
                "name": "Sordevolo",
                "countryCode": "IT"
            },
            {
                "name": "Soriso",
                "countryCode": "IT"
            },
            {
                "name": "Sostegno",
                "countryCode": "IT"
            },
            {
                "name": "Sozzago",
                "countryCode": "IT"
            },
            {
                "name": "Sparone",
                "countryCode": "IT"
            },
            {
                "name": "Spigno Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Spineto Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Spinetta Marengo",
                "countryCode": "IT"
            },
            {
                "name": "Staffa",
                "countryCode": "IT"
            },
            {
                "name": "Stazzano",
                "countryCode": "IT"
            },
            {
                "name": "Strambinello",
                "countryCode": "IT"
            },
            {
                "name": "Strambino",
                "countryCode": "IT"
            },
            {
                "name": "Stresa",
                "countryCode": "IT"
            },
            {
                "name": "Strevi",
                "countryCode": "IT"
            },
            {
                "name": "Stroppiana",
                "countryCode": "IT"
            },
            {
                "name": "Stroppo",
                "countryCode": "IT"
            },
            {
                "name": "Suno",
                "countryCode": "IT"
            },
            {
                "name": "Susa",
                "countryCode": "IT"
            },
            {
                "name": "Tagliaferro",
                "countryCode": "IT"
            },
            {
                "name": "Tagliolo Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Tarantasca",
                "countryCode": "IT"
            },
            {
                "name": "Tassarolo",
                "countryCode": "IT"
            },
            {
                "name": "Tavagnasco",
                "countryCode": "IT"
            },
            {
                "name": "Tavigliano",
                "countryCode": "IT"
            },
            {
                "name": "Terdobbiate",
                "countryCode": "IT"
            },
            {
                "name": "Ternengo",
                "countryCode": "IT"
            },
            {
                "name": "Terruggia",
                "countryCode": "IT"
            },
            {
                "name": "Terzo",
                "countryCode": "IT"
            },
            {
                "name": "Tetti Neirotti",
                "countryCode": "IT"
            },
            {
                "name": "Tettorosso",
                "countryCode": "IT"
            },
            {
                "name": "Ticineto",
                "countryCode": "IT"
            },
            {
                "name": "Tigliole",
                "countryCode": "IT"
            },
            {
                "name": "Toceno",
                "countryCode": "IT"
            },
            {
                "name": "Tollegno",
                "countryCode": "IT"
            },
            {
                "name": "Tonco",
                "countryCode": "IT"
            },
            {
                "name": "Tonengo",
                "countryCode": "IT"
            },
            {
                "name": "Tonengo-Casale",
                "countryCode": "IT"
            },
            {
                "name": "Torino",
                "countryCode": "IT"
            },
            {
                "name": "Tornaco",
                "countryCode": "IT"
            },
            {
                "name": "Torrazza Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Torrazzo",
                "countryCode": "IT"
            },
            {
                "name": "Torre Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Torre Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Torre Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Torre Pellice",
                "countryCode": "IT"
            },
            {
                "name": "Torre San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Torresina",
                "countryCode": "IT"
            },
            {
                "name": "Torrion Quartara",
                "countryCode": "IT"
            },
            {
                "name": "Tortona",
                "countryCode": "IT"
            },
            {
                "name": "Trana",
                "countryCode": "IT"
            },
            {
                "name": "Trarego",
                "countryCode": "IT"
            },
            {
                "name": "Trasquera",
                "countryCode": "IT"
            },
            {
                "name": "Trausella",
                "countryCode": "IT"
            },
            {
                "name": "Traversella",
                "countryCode": "IT"
            },
            {
                "name": "Traves",
                "countryCode": "IT"
            },
            {
                "name": "Trecate",
                "countryCode": "IT"
            },
            {
                "name": "Treiso",
                "countryCode": "IT"
            },
            {
                "name": "Treville",
                "countryCode": "IT"
            },
            {
                "name": "Trezzo Tinella",
                "countryCode": "IT"
            },
            {
                "name": "Tricerro",
                "countryCode": "IT"
            },
            {
                "name": "Trinità",
                "countryCode": "IT"
            },
            {
                "name": "Trino",
                "countryCode": "IT"
            },
            {
                "name": "Trisobbio",
                "countryCode": "IT"
            },
            {
                "name": "Trivero-Prativero-Ponzone",
                "countryCode": "IT"
            },
            {
                "name": "Trofarello",
                "countryCode": "IT"
            },
            {
                "name": "Trontano",
                "countryCode": "IT"
            },
            {
                "name": "Tronzano Vercellese",
                "countryCode": "IT"
            },
            {
                "name": "Turin",
                "countryCode": "IT"
            },
            {
                "name": "Usseaux",
                "countryCode": "IT"
            },
            {
                "name": "Usseglio",
                "countryCode": "IT"
            },
            {
                "name": "Vaccheria",
                "countryCode": "IT"
            },
            {
                "name": "Vaglio Serra",
                "countryCode": "IT"
            },
            {
                "name": "Vaie",
                "countryCode": "IT"
            },
            {
                "name": "Val della Torre",
                "countryCode": "IT"
            },
            {
                "name": "Valdengo",
                "countryCode": "IT"
            },
            {
                "name": "Valdieri",
                "countryCode": "IT"
            },
            {
                "name": "Valduggia",
                "countryCode": "IT"
            },
            {
                "name": "Valentino",
                "countryCode": "IT"
            },
            {
                "name": "Valenza",
                "countryCode": "IT"
            },
            {
                "name": "Valfenera",
                "countryCode": "IT"
            },
            {
                "name": "Valgioie",
                "countryCode": "IT"
            },
            {
                "name": "Valgrana",
                "countryCode": "IT"
            },
            {
                "name": "Vallanzengo",
                "countryCode": "IT"
            },
            {
                "name": "Valle Mosso",
                "countryCode": "IT"
            },
            {
                "name": "Valle San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "Valle San Nicolao",
                "countryCode": "IT"
            },
            {
                "name": "Vallo Torinese",
                "countryCode": "IT"
            },
            {
                "name": "Valloriate",
                "countryCode": "IT"
            },
            {
                "name": "Valmacca",
                "countryCode": "IT"
            },
            {
                "name": "Valmadonna",
                "countryCode": "IT"
            },
            {
                "name": "Valmala",
                "countryCode": "IT"
            },
            {
                "name": "Valperga",
                "countryCode": "IT"
            },
            {
                "name": "Valprato Soana",
                "countryCode": "IT"
            },
            {
                "name": "Valstrona",
                "countryCode": "IT"
            },
            {
                "name": "Vanzone",
                "countryCode": "IT"
            },
            {
                "name": "Vaprio D'Agogna",
                "countryCode": "IT"
            },
            {
                "name": "Varallo",
                "countryCode": "IT"
            },
            {
                "name": "Varallo Pombia",
                "countryCode": "IT"
            },
            {
                "name": "Varisella",
                "countryCode": "IT"
            },
            {
                "name": "Varzo",
                "countryCode": "IT"
            },
            {
                "name": "Vauda Canavese Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Veglio",
                "countryCode": "IT"
            },
            {
                "name": "Venaria Reale",
                "countryCode": "IT"
            },
            {
                "name": "Venasca",
                "countryCode": "IT"
            },
            {
                "name": "Venaus",
                "countryCode": "IT"
            },
            {
                "name": "Verbania",
                "countryCode": "IT"
            },
            {
                "name": "Vercelli",
                "countryCode": "IT"
            },
            {
                "name": "Verduno",
                "countryCode": "IT"
            },
            {
                "name": "Vergnasco",
                "countryCode": "IT"
            },
            {
                "name": "Vernante",
                "countryCode": "IT"
            },
            {
                "name": "Verolengo",
                "countryCode": "IT"
            },
            {
                "name": "Verrone",
                "countryCode": "IT"
            },
            {
                "name": "Verrua Savoia",
                "countryCode": "IT"
            },
            {
                "name": "Veruno",
                "countryCode": "IT"
            },
            {
                "name": "Verzuolo",
                "countryCode": "IT"
            },
            {
                "name": "Vesime",
                "countryCode": "IT"
            },
            {
                "name": "Vespolate",
                "countryCode": "IT"
            },
            {
                "name": "Vestignè",
                "countryCode": "IT"
            },
            {
                "name": "Vezza d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Viale",
                "countryCode": "IT"
            },
            {
                "name": "Vialfrè",
                "countryCode": "IT"
            },
            {
                "name": "Viarigi",
                "countryCode": "IT"
            },
            {
                "name": "Vico Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Vicoforte",
                "countryCode": "IT"
            },
            {
                "name": "Vicolungo",
                "countryCode": "IT"
            },
            {
                "name": "Vidracco",
                "countryCode": "IT"
            },
            {
                "name": "Viganella",
                "countryCode": "IT"
            },
            {
                "name": "Vigliano Biellese",
                "countryCode": "IT"
            },
            {
                "name": "Vigliano d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Vignale Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Vignole Borbera",
                "countryCode": "IT"
            },
            {
                "name": "Vignolo",
                "countryCode": "IT"
            },
            {
                "name": "Vignone",
                "countryCode": "IT"
            },
            {
                "name": "Vigone",
                "countryCode": "IT"
            },
            {
                "name": "Viguzzolo",
                "countryCode": "IT"
            },
            {
                "name": "Villa",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Secondo",
                "countryCode": "IT"
            },
            {
                "name": "Villa del Bosco",
                "countryCode": "IT"
            },
            {
                "name": "Villa-Borgo",
                "countryCode": "IT"
            },
            {
                "name": "Villadeati",
                "countryCode": "IT"
            },
            {
                "name": "Villadossola",
                "countryCode": "IT"
            },
            {
                "name": "Villafalletto",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Villalvernia",
                "countryCode": "IT"
            },
            {
                "name": "Villamiroglio",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Biellese",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Canavese",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Mondovì",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Monferrato",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Solaro",
                "countryCode": "IT"
            },
            {
                "name": "Villanova d'Asti",
                "countryCode": "IT"
            },
            {
                "name": "Villar Dora",
                "countryCode": "IT"
            },
            {
                "name": "Villar Focchiardo",
                "countryCode": "IT"
            },
            {
                "name": "Villar Pellice",
                "countryCode": "IT"
            },
            {
                "name": "Villar Perosa",
                "countryCode": "IT"
            },
            {
                "name": "Villar San Costanzo",
                "countryCode": "IT"
            },
            {
                "name": "Villarbasse",
                "countryCode": "IT"
            },
            {
                "name": "Villarboit",
                "countryCode": "IT"
            },
            {
                "name": "Villareggia",
                "countryCode": "IT"
            },
            {
                "name": "Villaromagnano",
                "countryCode": "IT"
            },
            {
                "name": "Villastellone",
                "countryCode": "IT"
            },
            {
                "name": "Villata",
                "countryCode": "IT"
            },
            {
                "name": "Villette",
                "countryCode": "IT"
            },
            {
                "name": "Vinadio",
                "countryCode": "IT"
            },
            {
                "name": "Vinchio",
                "countryCode": "IT"
            },
            {
                "name": "Vinovo",
                "countryCode": "IT"
            },
            {
                "name": "Vinzaglio",
                "countryCode": "IT"
            },
            {
                "name": "Viola",
                "countryCode": "IT"
            },
            {
                "name": "Virle Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "Vische",
                "countryCode": "IT"
            },
            {
                "name": "Visone",
                "countryCode": "IT"
            },
            {
                "name": "Vistrorio",
                "countryCode": "IT"
            },
            {
                "name": "Viverone",
                "countryCode": "IT"
            },
            {
                "name": "Viù",
                "countryCode": "IT"
            },
            {
                "name": "Vocca",
                "countryCode": "IT"
            },
            {
                "name": "Vogogna",
                "countryCode": "IT"
            },
            {
                "name": "Volpedo",
                "countryCode": "IT"
            },
            {
                "name": "Volpeglino",
                "countryCode": "IT"
            },
            {
                "name": "Volpiano",
                "countryCode": "IT"
            },
            {
                "name": "Voltaggio",
                "countryCode": "IT"
            },
            {
                "name": "Volvera",
                "countryCode": "IT"
            },
            {
                "name": "Vottignasco",
                "countryCode": "IT"
            },
            {
                "name": "Zimone",
                "countryCode": "IT"
            },
            {
                "name": "Zubiena",
                "countryCode": "IT"
            },
            {
                "name": "Zumaglia",
                "countryCode": "IT"
            },
            {
                "name": "Allein",
                "countryCode": "IT"
            },
            {
                "name": "Antagnod",
                "countryCode": "IT"
            },
            {
                "name": "Antey-Saint-Andrè",
                "countryCode": "IT"
            },
            {
                "name": "Aosta",
                "countryCode": "IT"
            },
            {
                "name": "Arnad",
                "countryCode": "IT"
            },
            {
                "name": "Arvier",
                "countryCode": "IT"
            },
            {
                "name": "Avise",
                "countryCode": "IT"
            },
            {
                "name": "Ayas",
                "countryCode": "IT"
            },
            {
                "name": "Aymavilles",
                "countryCode": "IT"
            },
            {
                "name": "Bard",
                "countryCode": "IT"
            },
            {
                "name": "Berriat",
                "countryCode": "IT"
            },
            {
                "name": "Bionaz",
                "countryCode": "IT"
            },
            {
                "name": "Brusson",
                "countryCode": "IT"
            },
            {
                "name": "Challand-Saint-Anselme",
                "countryCode": "IT"
            },
            {
                "name": "Challand-Saint-Victor",
                "countryCode": "IT"
            },
            {
                "name": "Chambave",
                "countryCode": "IT"
            },
            {
                "name": "Chamois",
                "countryCode": "IT"
            },
            {
                "name": "Champdepraz",
                "countryCode": "IT"
            },
            {
                "name": "Champorcher",
                "countryCode": "IT"
            },
            {
                "name": "Charvensod",
                "countryCode": "IT"
            },
            {
                "name": "Chatillon",
                "countryCode": "IT"
            },
            {
                "name": "Chef-Lieu",
                "countryCode": "IT"
            },
            {
                "name": "Cogne",
                "countryCode": "IT"
            },
            {
                "name": "Courmayeur",
                "countryCode": "IT"
            },
            {
                "name": "Donnas",
                "countryCode": "IT"
            },
            {
                "name": "Doues",
                "countryCode": "IT"
            },
            {
                "name": "Emarese",
                "countryCode": "IT"
            },
            {
                "name": "Etroubles",
                "countryCode": "IT"
            },
            {
                "name": "Fontainemore",
                "countryCode": "IT"
            },
            {
                "name": "Fénis",
                "countryCode": "IT"
            },
            {
                "name": "Gaby",
                "countryCode": "IT"
            },
            {
                "name": "Gignod",
                "countryCode": "IT"
            },
            {
                "name": "Grand Brissogne",
                "countryCode": "IT"
            },
            {
                "name": "Gressan",
                "countryCode": "IT"
            },
            {
                "name": "Gressoney-La-Trinitè",
                "countryCode": "IT"
            },
            {
                "name": "Gressoney-Saint-Jean",
                "countryCode": "IT"
            },
            {
                "name": "Hone",
                "countryCode": "IT"
            },
            {
                "name": "Introd",
                "countryCode": "IT"
            },
            {
                "name": "Issime",
                "countryCode": "IT"
            },
            {
                "name": "Issogne",
                "countryCode": "IT"
            },
            {
                "name": "Jovencan",
                "countryCode": "IT"
            },
            {
                "name": "La Cretaz-Roisan",
                "countryCode": "IT"
            },
            {
                "name": "La Magdeleine",
                "countryCode": "IT"
            },
            {
                "name": "La Place",
                "countryCode": "IT"
            },
            {
                "name": "La Salle",
                "countryCode": "IT"
            },
            {
                "name": "La Thuile",
                "countryCode": "IT"
            },
            {
                "name": "Lassolaz",
                "countryCode": "IT"
            },
            {
                "name": "Lillianes",
                "countryCode": "IT"
            },
            {
                "name": "Mongnod",
                "countryCode": "IT"
            },
            {
                "name": "Montan-Angelin-Arensod",
                "countryCode": "IT"
            },
            {
                "name": "Montjovet",
                "countryCode": "IT"
            },
            {
                "name": "Morgex",
                "countryCode": "IT"
            },
            {
                "name": "Nus",
                "countryCode": "IT"
            },
            {
                "name": "Ollomont",
                "countryCode": "IT"
            },
            {
                "name": "Oyace",
                "countryCode": "IT"
            },
            {
                "name": "Paquier",
                "countryCode": "IT"
            },
            {
                "name": "Perloz",
                "countryCode": "IT"
            },
            {
                "name": "Petit Fenis",
                "countryCode": "IT"
            },
            {
                "name": "Plan d'Introd",
                "countryCode": "IT"
            },
            {
                "name": "Pollein",
                "countryCode": "IT"
            },
            {
                "name": "Pont-Bozet",
                "countryCode": "IT"
            },
            {
                "name": "Pont-Saint-Martin",
                "countryCode": "IT"
            },
            {
                "name": "Pontey",
                "countryCode": "IT"
            },
            {
                "name": "Prè Saint Didier",
                "countryCode": "IT"
            },
            {
                "name": "Quart",
                "countryCode": "IT"
            },
            {
                "name": "Quincod",
                "countryCode": "IT"
            },
            {
                "name": "Rhemes-Notre-Dame",
                "countryCode": "IT"
            },
            {
                "name": "Rhemes-Saint-Georges",
                "countryCode": "IT"
            },
            {
                "name": "Saint Marcel",
                "countryCode": "IT"
            },
            {
                "name": "Saint Maurice",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Christophe",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Denis",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Nicolas",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Oyen",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Pierre",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Rhémy",
                "countryCode": "IT"
            },
            {
                "name": "Saint-Vincent",
                "countryCode": "IT"
            },
            {
                "name": "San Leonardo",
                "countryCode": "IT"
            },
            {
                "name": "Torgnon",
                "countryCode": "IT"
            },
            {
                "name": "Valgrisenche",
                "countryCode": "IT"
            },
            {
                "name": "Valle d'Aosta",
                "countryCode": "IT"
            },
            {
                "name": "Valpelline",
                "countryCode": "IT"
            },
            {
                "name": "Valsavarenche",
                "countryCode": "IT"
            },
            {
                "name": "Valtournenche",
                "countryCode": "IT"
            },
            {
                "name": "Verrayes",
                "countryCode": "IT"
            },
            {
                "name": "Verres",
                "countryCode": "IT"
            },
            {
                "name": "Villa-Nabian",
                "countryCode": "IT"
            },
            {
                "name": "Villair-Amerique",
                "countryCode": "IT"
            },
            {
                "name": "Ville Sur Sarre",
                "countryCode": "IT"
            },
            {
                "name": "Villefranche",
                "countryCode": "IT"
            },
            {
                "name": "Villeneuve",
                "countryCode": "IT"
            },
            {
                "name": "Abbadia Cerreto",
                "countryCode": "IT"
            },
            {
                "name": "Abbadia Lariana",
                "countryCode": "IT"
            },
            {
                "name": "Abbazia",
                "countryCode": "IT"
            },
            {
                "name": "Abbiategrasso",
                "countryCode": "IT"
            },
            {
                "name": "Acquafredda",
                "countryCode": "IT"
            },
            {
                "name": "Acquanegra Cremonese",
                "countryCode": "IT"
            },
            {
                "name": "Acquanegra sul Chiese",
                "countryCode": "IT"
            },
            {
                "name": "Adrara San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Adrara San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "Adro",
                "countryCode": "IT"
            },
            {
                "name": "Agnadello",
                "countryCode": "IT"
            },
            {
                "name": "Agnosine",
                "countryCode": "IT"
            },
            {
                "name": "Agra",
                "countryCode": "IT"
            },
            {
                "name": "Agrate Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Aicurzio",
                "countryCode": "IT"
            },
            {
                "name": "Airuno",
                "countryCode": "IT"
            },
            {
                "name": "Alagna",
                "countryCode": "IT"
            },
            {
                "name": "Albairate",
                "countryCode": "IT"
            },
            {
                "name": "Albano Sant'Alessandro",
                "countryCode": "IT"
            },
            {
                "name": "Albaredo",
                "countryCode": "IT"
            },
            {
                "name": "Albaredo Arnaboldi",
                "countryCode": "IT"
            },
            {
                "name": "Albavilla",
                "countryCode": "IT"
            },
            {
                "name": "Albese Con Cassano",
                "countryCode": "IT"
            },
            {
                "name": "Albiate",
                "countryCode": "IT"
            },
            {
                "name": "Albignano",
                "countryCode": "IT"
            },
            {
                "name": "Albino",
                "countryCode": "IT"
            },
            {
                "name": "Albiolo",
                "countryCode": "IT"
            },
            {
                "name": "Albizzate",
                "countryCode": "IT"
            },
            {
                "name": "Albonese",
                "countryCode": "IT"
            },
            {
                "name": "Albosaggia",
                "countryCode": "IT"
            },
            {
                "name": "Albuzzano",
                "countryCode": "IT"
            },
            {
                "name": "Alfianello",
                "countryCode": "IT"
            },
            {
                "name": "Algua",
                "countryCode": "IT"
            },
            {
                "name": "Almenno San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "Almenno San Salvatore",
                "countryCode": "IT"
            },
            {
                "name": "Almè",
                "countryCode": "IT"
            },
            {
                "name": "Alserio",
                "countryCode": "IT"
            },
            {
                "name": "Alzano Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Alzate Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Ambivere",
                "countryCode": "IT"
            },
            {
                "name": "Andalo Valtellino",
                "countryCode": "IT"
            },
            {
                "name": "Anfo",
                "countryCode": "IT"
            },
            {
                "name": "Angera",
                "countryCode": "IT"
            },
            {
                "name": "Angolo Terme",
                "countryCode": "IT"
            },
            {
                "name": "Annicco",
                "countryCode": "IT"
            },
            {
                "name": "Annone di Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Antegnate",
                "countryCode": "IT"
            },
            {
                "name": "Anzano del Parco",
                "countryCode": "IT"
            },
            {
                "name": "Appiano Gentile",
                "countryCode": "IT"
            },
            {
                "name": "Aprica",
                "countryCode": "IT"
            },
            {
                "name": "Arcene",
                "countryCode": "IT"
            },
            {
                "name": "Arcisate",
                "countryCode": "IT"
            },
            {
                "name": "Arconate",
                "countryCode": "IT"
            },
            {
                "name": "Arcore",
                "countryCode": "IT"
            },
            {
                "name": "Ardenno",
                "countryCode": "IT"
            },
            {
                "name": "Ardesio",
                "countryCode": "IT"
            },
            {
                "name": "Ardole San Marino",
                "countryCode": "IT"
            },
            {
                "name": "Arena Po",
                "countryCode": "IT"
            },
            {
                "name": "Arese",
                "countryCode": "IT"
            },
            {
                "name": "Argegno",
                "countryCode": "IT"
            },
            {
                "name": "Arlate",
                "countryCode": "IT"
            },
            {
                "name": "Arluno",
                "countryCode": "IT"
            },
            {
                "name": "Arosio",
                "countryCode": "IT"
            },
            {
                "name": "Arsago Seprio",
                "countryCode": "IT"
            },
            {
                "name": "Artogne",
                "countryCode": "IT"
            },
            {
                "name": "Arzago d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Asola",
                "countryCode": "IT"
            },
            {
                "name": "Assago",
                "countryCode": "IT"
            },
            {
                "name": "Asso",
                "countryCode": "IT"
            },
            {
                "name": "Aurogna",
                "countryCode": "IT"
            },
            {
                "name": "Averara",
                "countryCode": "IT"
            },
            {
                "name": "Aviatico",
                "countryCode": "IT"
            },
            {
                "name": "Azzanello",
                "countryCode": "IT"
            },
            {
                "name": "Azzano",
                "countryCode": "IT"
            },
            {
                "name": "Azzano Mella",
                "countryCode": "IT"
            },
            {
                "name": "Azzano San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "Azzate",
                "countryCode": "IT"
            },
            {
                "name": "Azzio",
                "countryCode": "IT"
            },
            {
                "name": "Azzone",
                "countryCode": "IT"
            },
            {
                "name": "Badalasco",
                "countryCode": "IT"
            },
            {
                "name": "Badia Pavese",
                "countryCode": "IT"
            },
            {
                "name": "Badile",
                "countryCode": "IT"
            },
            {
                "name": "Bagnaria",
                "countryCode": "IT"
            },
            {
                "name": "Bagnatica",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo Cremasco",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo Mella",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Bagolino",
                "countryCode": "IT"
            },
            {
                "name": "Ballabio",
                "countryCode": "IT"
            },
            {
                "name": "Baraggia",
                "countryCode": "IT"
            },
            {
                "name": "Baranzate",
                "countryCode": "IT"
            },
            {
                "name": "Barasso",
                "countryCode": "IT"
            },
            {
                "name": "Barbariga",
                "countryCode": "IT"
            },
            {
                "name": "Barbata",
                "countryCode": "IT"
            },
            {
                "name": "Barbianello",
                "countryCode": "IT"
            },
            {
                "name": "Bardello",
                "countryCode": "IT"
            },
            {
                "name": "Bareggio",
                "countryCode": "IT"
            },
            {
                "name": "Bargano",
                "countryCode": "IT"
            },
            {
                "name": "Barghe",
                "countryCode": "IT"
            },
            {
                "name": "Bariano",
                "countryCode": "IT"
            },
            {
                "name": "Barlassina",
                "countryCode": "IT"
            },
            {
                "name": "Barni",
                "countryCode": "IT"
            },
            {
                "name": "Barzago",
                "countryCode": "IT"
            },
            {
                "name": "Barzana",
                "countryCode": "IT"
            },
            {
                "name": "Barzanò",
                "countryCode": "IT"
            },
            {
                "name": "Barzio",
                "countryCode": "IT"
            },
            {
                "name": "Bascapè",
                "countryCode": "IT"
            },
            {
                "name": "Basiano",
                "countryCode": "IT"
            },
            {
                "name": "Basiglio",
                "countryCode": "IT"
            },
            {
                "name": "Bassano Bresciano",
                "countryCode": "IT"
            },
            {
                "name": "Bastida Pancarana",
                "countryCode": "IT"
            },
            {
                "name": "Bastida de' Dossi",
                "countryCode": "IT"
            },
            {
                "name": "Battaglione-Bagnara",
                "countryCode": "IT"
            },
            {
                "name": "Battuda",
                "countryCode": "IT"
            },
            {
                "name": "Bedero",
                "countryCode": "IT"
            },
            {
                "name": "Bedero Valcuvia",
                "countryCode": "IT"
            },
            {
                "name": "Bedizzole",
                "countryCode": "IT"
            },
            {
                "name": "Bedulita",
                "countryCode": "IT"
            },
            {
                "name": "Belforte",
                "countryCode": "IT"
            },
            {
                "name": "Belgioioso",
                "countryCode": "IT"
            },
            {
                "name": "Bellagio",
                "countryCode": "IT"
            },
            {
                "name": "Bellano",
                "countryCode": "IT"
            },
            {
                "name": "Bellinzago Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Bellusco",
                "countryCode": "IT"
            },
            {
                "name": "Bema",
                "countryCode": "IT"
            },
            {
                "name": "Bene Lario",
                "countryCode": "IT"
            },
            {
                "name": "Berbenno",
                "countryCode": "IT"
            },
            {
                "name": "Berbenno di Valtellina",
                "countryCode": "IT"
            },
            {
                "name": "Beregazzo",
                "countryCode": "IT"
            },
            {
                "name": "Beregazzo con Figliaro",
                "countryCode": "IT"
            },
            {
                "name": "Bereguardo",
                "countryCode": "IT"
            },
            {
                "name": "Bergamo",
                "countryCode": "IT"
            },
            {
                "name": "Berlingo",
                "countryCode": "IT"
            },
            {
                "name": "Bernareggio",
                "countryCode": "IT"
            },
            {
                "name": "Bernate Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Bertonico",
                "countryCode": "IT"
            },
            {
                "name": "Berzo",
                "countryCode": "IT"
            },
            {
                "name": "Berzo Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Berzo San Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Besana in Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Besano",
                "countryCode": "IT"
            },
            {
                "name": "Besate",
                "countryCode": "IT"
            },
            {
                "name": "Besnate",
                "countryCode": "IT"
            },
            {
                "name": "Besozzo",
                "countryCode": "IT"
            },
            {
                "name": "Bettola",
                "countryCode": "IT"
            },
            {
                "name": "Bettola-Zeloforomagno",
                "countryCode": "IT"
            },
            {
                "name": "Biandronno",
                "countryCode": "IT"
            },
            {
                "name": "Bianzano",
                "countryCode": "IT"
            },
            {
                "name": "Bianzone",
                "countryCode": "IT"
            },
            {
                "name": "Biassono",
                "countryCode": "IT"
            },
            {
                "name": "Bienno",
                "countryCode": "IT"
            },
            {
                "name": "Binago",
                "countryCode": "IT"
            },
            {
                "name": "Binasco",
                "countryCode": "IT"
            },
            {
                "name": "Bione",
                "countryCode": "IT"
            },
            {
                "name": "Bisuschio",
                "countryCode": "IT"
            },
            {
                "name": "Bizzarone",
                "countryCode": "IT"
            },
            {
                "name": "Blello",
                "countryCode": "IT"
            },
            {
                "name": "Blessagno",
                "countryCode": "IT"
            },
            {
                "name": "Blevio",
                "countryCode": "IT"
            },
            {
                "name": "Bodio",
                "countryCode": "IT"
            },
            {
                "name": "Bodio Lomnago",
                "countryCode": "IT"
            },
            {
                "name": "Boffalora Sopra Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Boffalora d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Bolgare",
                "countryCode": "IT"
            },
            {
                "name": "Bolladello-Peveranza",
                "countryCode": "IT"
            },
            {
                "name": "Bollate",
                "countryCode": "IT"
            },
            {
                "name": "Boltiere",
                "countryCode": "IT"
            },
            {
                "name": "Bonate Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Bonate Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Bondeno",
                "countryCode": "IT"
            },
            {
                "name": "Bondione",
                "countryCode": "IT"
            },
            {
                "name": "Bonemerse",
                "countryCode": "IT"
            },
            {
                "name": "Bordolano",
                "countryCode": "IT"
            },
            {
                "name": "Borgarello",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Priolo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Siro",
                "countryCode": "IT"
            },
            {
                "name": "Borgo di Terzo",
                "countryCode": "IT"
            },
            {
                "name": "Borgoforte",
                "countryCode": "IT"
            },
            {
                "name": "Borgofranco sul Po",
                "countryCode": "IT"
            },
            {
                "name": "Borgoratto Mormorolo",
                "countryCode": "IT"
            },
            {
                "name": "Borgosatollo",
                "countryCode": "IT"
            },
            {
                "name": "Bormio",
                "countryCode": "IT"
            },
            {
                "name": "Bornasco",
                "countryCode": "IT"
            },
            {
                "name": "Bornato",
                "countryCode": "IT"
            },
            {
                "name": "Borno",
                "countryCode": "IT"
            },
            {
                "name": "Boschetto",
                "countryCode": "IT"
            },
            {
                "name": "Bosco Ex Parmigiano",
                "countryCode": "IT"
            },
            {
                "name": "Bosisio Parini",
                "countryCode": "IT"
            },
            {
                "name": "Bosnasco",
                "countryCode": "IT"
            },
            {
                "name": "Bossico",
                "countryCode": "IT"
            },
            {
                "name": "Botta",
                "countryCode": "IT"
            },
            {
                "name": "Bottanuco",
                "countryCode": "IT"
            },
            {
                "name": "Botticino",
                "countryCode": "IT"
            },
            {
                "name": "Bovegno",
                "countryCode": "IT"
            },
            {
                "name": "Bovezzo",
                "countryCode": "IT"
            },
            {
                "name": "Bovisio-Masciago",
                "countryCode": "IT"
            },
            {
                "name": "Bozzolo",
                "countryCode": "IT"
            },
            {
                "name": "Bracca",
                "countryCode": "IT"
            },
            {
                "name": "Brandico",
                "countryCode": "IT"
            },
            {
                "name": "Branzi",
                "countryCode": "IT"
            },
            {
                "name": "Braone",
                "countryCode": "IT"
            },
            {
                "name": "Brebbia",
                "countryCode": "IT"
            },
            {
                "name": "Bregano",
                "countryCode": "IT"
            },
            {
                "name": "Bregnano",
                "countryCode": "IT"
            },
            {
                "name": "Brembate",
                "countryCode": "IT"
            },
            {
                "name": "Brembate di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Brembilla",
                "countryCode": "IT"
            },
            {
                "name": "Brembio",
                "countryCode": "IT"
            },
            {
                "name": "Breme",
                "countryCode": "IT"
            },
            {
                "name": "Brenna",
                "countryCode": "IT"
            },
            {
                "name": "Brenno Useria",
                "countryCode": "IT"
            },
            {
                "name": "Breno",
                "countryCode": "IT"
            },
            {
                "name": "Brenta",
                "countryCode": "IT"
            },
            {
                "name": "Brescia",
                "countryCode": "IT"
            },
            {
                "name": "Bressana",
                "countryCode": "IT"
            },
            {
                "name": "Bresso",
                "countryCode": "IT"
            },
            {
                "name": "Brezzo di Bedero",
                "countryCode": "IT"
            },
            {
                "name": "Brienno",
                "countryCode": "IT"
            },
            {
                "name": "Brignano Gera d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Brinzio",
                "countryCode": "IT"
            },
            {
                "name": "Brione",
                "countryCode": "IT"
            },
            {
                "name": "Briosco",
                "countryCode": "IT"
            },
            {
                "name": "Brissago-Valtravaglia",
                "countryCode": "IT"
            },
            {
                "name": "Brivio",
                "countryCode": "IT"
            },
            {
                "name": "Brongio",
                "countryCode": "IT"
            },
            {
                "name": "Broni",
                "countryCode": "IT"
            },
            {
                "name": "Brozzo",
                "countryCode": "IT"
            },
            {
                "name": "Brugherio",
                "countryCode": "IT"
            },
            {
                "name": "Brumano",
                "countryCode": "IT"
            },
            {
                "name": "Brunate",
                "countryCode": "IT"
            },
            {
                "name": "Brunello",
                "countryCode": "IT"
            },
            {
                "name": "Brusaporto",
                "countryCode": "IT"
            },
            {
                "name": "Brusimpiano",
                "countryCode": "IT"
            },
            {
                "name": "Bubbiano",
                "countryCode": "IT"
            },
            {
                "name": "Buccinasco",
                "countryCode": "IT"
            },
            {
                "name": "Buffalora-Bettole",
                "countryCode": "IT"
            },
            {
                "name": "Buglio in Monte",
                "countryCode": "IT"
            },
            {
                "name": "Buguggiate",
                "countryCode": "IT"
            },
            {
                "name": "Bulciago",
                "countryCode": "IT"
            },
            {
                "name": "Bulgarograsso",
                "countryCode": "IT"
            },
            {
                "name": "Bulgorello",
                "countryCode": "IT"
            },
            {
                "name": "Burago di Molgora",
                "countryCode": "IT"
            },
            {
                "name": "Buscate",
                "countryCode": "IT"
            },
            {
                "name": "Buscoldo",
                "countryCode": "IT"
            },
            {
                "name": "Busnago",
                "countryCode": "IT"
            },
            {
                "name": "Bussero",
                "countryCode": "IT"
            },
            {
                "name": "Busto Arsizio",
                "countryCode": "IT"
            },
            {
                "name": "Busto Garolfo",
                "countryCode": "IT"
            },
            {
                "name": "Ca' d'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Cabiate",
                "countryCode": "IT"
            },
            {
                "name": "Cadegliano-Viconago",
                "countryCode": "IT"
            },
            {
                "name": "Cadenabbia",
                "countryCode": "IT"
            },
            {
                "name": "Cadorago-Caslino al Piano",
                "countryCode": "IT"
            },
            {
                "name": "Cadrezzate",
                "countryCode": "IT"
            },
            {
                "name": "Caglio",
                "countryCode": "IT"
            },
            {
                "name": "Cagno",
                "countryCode": "IT"
            },
            {
                "name": "Caino",
                "countryCode": "IT"
            },
            {
                "name": "Caiolo",
                "countryCode": "IT"
            },
            {
                "name": "Cairate",
                "countryCode": "IT"
            },
            {
                "name": "Calchera-Frontale",
                "countryCode": "IT"
            },
            {
                "name": "Calcinate",
                "countryCode": "IT"
            },
            {
                "name": "Calcinato",
                "countryCode": "IT"
            },
            {
                "name": "Calcio",
                "countryCode": "IT"
            },
            {
                "name": "Calco",
                "countryCode": "IT"
            },
            {
                "name": "Caleppio",
                "countryCode": "IT"
            },
            {
                "name": "Calolziocorte",
                "countryCode": "IT"
            },
            {
                "name": "Calozzo",
                "countryCode": "IT"
            },
            {
                "name": "Calusco d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Calvagese della Riviera",
                "countryCode": "IT"
            },
            {
                "name": "Calvatone",
                "countryCode": "IT"
            },
            {
                "name": "Calvenzano",
                "countryCode": "IT"
            },
            {
                "name": "Calvignano",
                "countryCode": "IT"
            },
            {
                "name": "Calvignasco",
                "countryCode": "IT"
            },
            {
                "name": "Calvisano",
                "countryCode": "IT"
            },
            {
                "name": "Calò",
                "countryCode": "IT"
            },
            {
                "name": "Camairago",
                "countryCode": "IT"
            },
            {
                "name": "Cambiago",
                "countryCode": "IT"
            },
            {
                "name": "Camerata Cornello",
                "countryCode": "IT"
            },
            {
                "name": "Camisano",
                "countryCode": "IT"
            },
            {
                "name": "Camnago-Boscone",
                "countryCode": "IT"
            },
            {
                "name": "Camoneone",
                "countryCode": "IT"
            },
            {
                "name": "Campagnola Cremasca",
                "countryCode": "IT"
            },
            {
                "name": "Camparada",
                "countryCode": "IT"
            },
            {
                "name": "Campione",
                "countryCode": "IT"
            },
            {
                "name": "Campitello",
                "countryCode": "IT"
            },
            {
                "name": "Campodolcino",
                "countryCode": "IT"
            },
            {
                "name": "Campofiorenzo-California",
                "countryCode": "IT"
            },
            {
                "name": "Campospinoso",
                "countryCode": "IT"
            },
            {
                "name": "Candia Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Canegrate",
                "countryCode": "IT"
            },
            {
                "name": "Canneto",
                "countryCode": "IT"
            },
            {
                "name": "Canneto Pavese",
                "countryCode": "IT"
            },
            {
                "name": "Canneto sull'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Canonica",
                "countryCode": "IT"
            },
            {
                "name": "Canonica d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Canova-San Zeno",
                "countryCode": "IT"
            },
            {
                "name": "Cantalupo",
                "countryCode": "IT"
            },
            {
                "name": "Cantello",
                "countryCode": "IT"
            },
            {
                "name": "Cantù",
                "countryCode": "IT"
            },
            {
                "name": "Canzo",
                "countryCode": "IT"
            },
            {
                "name": "Capergnanica",
                "countryCode": "IT"
            },
            {
                "name": "Capiago-Intimiano-Olmeda",
                "countryCode": "IT"
            },
            {
                "name": "Capo di Ponte",
                "countryCode": "IT"
            },
            {
                "name": "Caponago",
                "countryCode": "IT"
            },
            {
                "name": "Capovalle",
                "countryCode": "IT"
            },
            {
                "name": "Cappella Cantone",
                "countryCode": "IT"
            },
            {
                "name": "Cappella de' Picenardi",
                "countryCode": "IT"
            },
            {
                "name": "Cappelletta",
                "countryCode": "IT"
            },
            {
                "name": "Capralba",
                "countryCode": "IT"
            },
            {
                "name": "Capriano",
                "countryCode": "IT"
            },
            {
                "name": "Capriano del Colle",
                "countryCode": "IT"
            },
            {
                "name": "Capriate San Gervasio",
                "countryCode": "IT"
            },
            {
                "name": "Caprino",
                "countryCode": "IT"
            },
            {
                "name": "Capriolo",
                "countryCode": "IT"
            },
            {
                "name": "Carate Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Carate Urio",
                "countryCode": "IT"
            },
            {
                "name": "Caravaggio",
                "countryCode": "IT"
            },
            {
                "name": "Caravate",
                "countryCode": "IT"
            },
            {
                "name": "Carbonara al Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Carbonara di Po",
                "countryCode": "IT"
            },
            {
                "name": "Carbonate",
                "countryCode": "IT"
            },
            {
                "name": "Cardano al Campo",
                "countryCode": "IT"
            },
            {
                "name": "Carenno",
                "countryCode": "IT"
            },
            {
                "name": "Carimate",
                "countryCode": "IT"
            },
            {
                "name": "Carlazzo",
                "countryCode": "IT"
            },
            {
                "name": "Carnago",
                "countryCode": "IT"
            },
            {
                "name": "Carnate",
                "countryCode": "IT"
            },
            {
                "name": "Carobbio",
                "countryCode": "IT"
            },
            {
                "name": "Carobbio degli Angeli",
                "countryCode": "IT"
            },
            {
                "name": "Carona",
                "countryCode": "IT"
            },
            {
                "name": "Caronno Pertusella",
                "countryCode": "IT"
            },
            {
                "name": "Caronno Varesino",
                "countryCode": "IT"
            },
            {
                "name": "Carpenedolo",
                "countryCode": "IT"
            },
            {
                "name": "Carpiano",
                "countryCode": "IT"
            },
            {
                "name": "Carugate",
                "countryCode": "IT"
            },
            {
                "name": "Carugo",
                "countryCode": "IT"
            },
            {
                "name": "Carvico",
                "countryCode": "IT"
            },
            {
                "name": "Carzago Riviera",
                "countryCode": "IT"
            },
            {
                "name": "Casa Ponte",
                "countryCode": "IT"
            },
            {
                "name": "Casalbuttano",
                "countryCode": "IT"
            },
            {
                "name": "Casale Cremasco",
                "countryCode": "IT"
            },
            {
                "name": "Casale Litta",
                "countryCode": "IT"
            },
            {
                "name": "Casaletto Ceredano",
                "countryCode": "IT"
            },
            {
                "name": "Casaletto Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Casaletto Vaprio",
                "countryCode": "IT"
            },
            {
                "name": "Casaletto di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Casalmaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Casalmaiocco",
                "countryCode": "IT"
            },
            {
                "name": "Casalmorano",
                "countryCode": "IT"
            },
            {
                "name": "Casalmoro",
                "countryCode": "IT"
            },
            {
                "name": "Casaloldo",
                "countryCode": "IT"
            },
            {
                "name": "Casalpusterlengo",
                "countryCode": "IT"
            },
            {
                "name": "Casalromano",
                "countryCode": "IT"
            },
            {
                "name": "Casalzuigno",
                "countryCode": "IT"
            },
            {
                "name": "Casanova Lonati",
                "countryCode": "IT"
            },
            {
                "name": "Casargo",
                "countryCode": "IT"
            },
            {
                "name": "Casarile",
                "countryCode": "IT"
            },
            {
                "name": "Casasco Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "Casate",
                "countryCode": "IT"
            },
            {
                "name": "Casatenovo",
                "countryCode": "IT"
            },
            {
                "name": "Casatisma",
                "countryCode": "IT"
            },
            {
                "name": "Casazza",
                "countryCode": "IT"
            },
            {
                "name": "Casciago",
                "countryCode": "IT"
            },
            {
                "name": "Cascina Elisa",
                "countryCode": "IT"
            },
            {
                "name": "Casco",
                "countryCode": "IT"
            },
            {
                "name": "Case Nuove",
                "countryCode": "IT"
            },
            {
                "name": "Casei",
                "countryCode": "IT"
            },
            {
                "name": "Caselle Landi",
                "countryCode": "IT"
            },
            {
                "name": "Caselle Lurani",
                "countryCode": "IT"
            },
            {
                "name": "Caseo",
                "countryCode": "IT"
            },
            {
                "name": "Casirate d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Caslino d'Erba",
                "countryCode": "IT"
            },
            {
                "name": "Casnate Con Bernate",
                "countryCode": "IT"
            },
            {
                "name": "Casnigo",
                "countryCode": "IT"
            },
            {
                "name": "Casone",
                "countryCode": "IT"
            },
            {
                "name": "Casorate Primo",
                "countryCode": "IT"
            },
            {
                "name": "Casorate Sempione",
                "countryCode": "IT"
            },
            {
                "name": "Casorezzo",
                "countryCode": "IT"
            },
            {
                "name": "Caspoggio",
                "countryCode": "IT"
            },
            {
                "name": "Cassago Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Cassano Magnago",
                "countryCode": "IT"
            },
            {
                "name": "Cassano Valcuvia",
                "countryCode": "IT"
            },
            {
                "name": "Cassano d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Cassiglio",
                "countryCode": "IT"
            },
            {
                "name": "Cassina Rizzardi",
                "countryCode": "IT"
            },
            {
                "name": "Cassina Valsassina",
                "countryCode": "IT"
            },
            {
                "name": "Cassina de' Pecchi",
                "countryCode": "IT"
            },
            {
                "name": "Cassinetta di Lugagnano",
                "countryCode": "IT"
            },
            {
                "name": "Cassino d'Alberi",
                "countryCode": "IT"
            },
            {
                "name": "Cassolnovo",
                "countryCode": "IT"
            },
            {
                "name": "Castana",
                "countryCode": "IT"
            },
            {
                "name": "Castano Primo",
                "countryCode": "IT"
            },
            {
                "name": "Casteggio",
                "countryCode": "IT"
            },
            {
                "name": "Castegnato",
                "countryCode": "IT"
            },
            {
                "name": "Castel Gabbiano",
                "countryCode": "IT"
            },
            {
                "name": "Castel Goffredo",
                "countryCode": "IT"
            },
            {
                "name": "Castel Mella",
                "countryCode": "IT"
            },
            {
                "name": "Castel Rozzone",
                "countryCode": "IT"
            },
            {
                "name": "Castel d'Ario",
                "countryCode": "IT"
            },
            {
                "name": "Castelbelforte",
                "countryCode": "IT"
            },
            {
                "name": "Castelcovati",
                "countryCode": "IT"
            },
            {
                "name": "Casteldidone",
                "countryCode": "IT"
            },
            {
                "name": "Castellanza",
                "countryCode": "IT"
            },
            {
                "name": "Castelleone",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto Po",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto di Branduzzo",
                "countryCode": "IT"
            },
            {
                "name": "Castelli Calepio",
                "countryCode": "IT"
            },
            {
                "name": "Castello",
                "countryCode": "IT"
            },
            {
                "name": "Castello Cabiaglio",
                "countryCode": "IT"
            },
            {
                "name": "Castello d'Agogna",
                "countryCode": "IT"
            },
            {
                "name": "Castello dell'Acqua",
                "countryCode": "IT"
            },
            {
                "name": "Castello di Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Castellucchio",
                "countryCode": "IT"
            },
            {
                "name": "Castelmarte",
                "countryCode": "IT"
            },
            {
                "name": "Castelnovetto",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Bocca d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Bozzente",
                "countryCode": "IT"
            },
            {
                "name": "Castelseprio",
                "countryCode": "IT"
            },
            {
                "name": "Castelveccana",
                "countryCode": "IT"
            },
            {
                "name": "Castelverde",
                "countryCode": "IT"
            },
            {
                "name": "Castelvisconti",
                "countryCode": "IT"
            },
            {
                "name": "Castenedolo",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Olona",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione d'Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione delle Stiviere",
                "countryCode": "IT"
            },
            {
                "name": "Castione",
                "countryCode": "IT"
            },
            {
                "name": "Castione Andevenno",
                "countryCode": "IT"
            },
            {
                "name": "Castione della Presolana",
                "countryCode": "IT"
            },
            {
                "name": "Castiraga Vidardo",
                "countryCode": "IT"
            },
            {
                "name": "Casto",
                "countryCode": "IT"
            },
            {
                "name": "Castrezzato",
                "countryCode": "IT"
            },
            {
                "name": "Castro",
                "countryCode": "IT"
            },
            {
                "name": "Castronno",
                "countryCode": "IT"
            },
            {
                "name": "Cataeggio",
                "countryCode": "IT"
            },
            {
                "name": "Cava Manara",
                "countryCode": "IT"
            },
            {
                "name": "Cavacurta",
                "countryCode": "IT"
            },
            {
                "name": "Cavallasca",
                "countryCode": "IT"
            },
            {
                "name": "Cavargna",
                "countryCode": "IT"
            },
            {
                "name": "Cavaria Con Premezzo",
                "countryCode": "IT"
            },
            {
                "name": "Cavenago d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Cavenago di Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Cavernago",
                "countryCode": "IT"
            },
            {
                "name": "Cavriana",
                "countryCode": "IT"
            },
            {
                "name": "Cazzago Brabbia",
                "countryCode": "IT"
            },
            {
                "name": "Cazzago San Martino-Calino",
                "countryCode": "IT"
            },
            {
                "name": "Cazzano Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Cecima",
                "countryCode": "IT"
            },
            {
                "name": "Cedegolo",
                "countryCode": "IT"
            },
            {
                "name": "Cedessano",
                "countryCode": "IT"
            },
            {
                "name": "Cedrasco",
                "countryCode": "IT"
            },
            {
                "name": "Cella Dati",
                "countryCode": "IT"
            },
            {
                "name": "Cellatica",
                "countryCode": "IT"
            },
            {
                "name": "Cenate Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Cenate di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Cene",
                "countryCode": "IT"
            },
            {
                "name": "Cepina",
                "countryCode": "IT"
            },
            {
                "name": "Cerano d'Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "Ceranova",
                "countryCode": "IT"
            },
            {
                "name": "Cercino",
                "countryCode": "IT"
            },
            {
                "name": "Cerello-Battuello",
                "countryCode": "IT"
            },
            {
                "name": "Ceresara",
                "countryCode": "IT"
            },
            {
                "name": "Cerese",
                "countryCode": "IT"
            },
            {
                "name": "Cerete Alto",
                "countryCode": "IT"
            },
            {
                "name": "Ceretto Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Cergnago",
                "countryCode": "IT"
            },
            {
                "name": "Ceriano Laghetto",
                "countryCode": "IT"
            },
            {
                "name": "Cerlongo",
                "countryCode": "IT"
            },
            {
                "name": "Cermenate",
                "countryCode": "IT"
            },
            {
                "name": "Cernobbio",
                "countryCode": "IT"
            },
            {
                "name": "Cernusco Lombardone",
                "countryCode": "IT"
            },
            {
                "name": "Cernusco sul Naviglio",
                "countryCode": "IT"
            },
            {
                "name": "Cerro Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cerro al Lambro",
                "countryCode": "IT"
            },
            {
                "name": "Certosa di Pavia",
                "countryCode": "IT"
            },
            {
                "name": "Cerveno",
                "countryCode": "IT"
            },
            {
                "name": "Cervesina",
                "countryCode": "IT"
            },
            {
                "name": "Cervignano d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Cesana Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Cesano Boscone",
                "countryCode": "IT"
            },
            {
                "name": "Cesano Maderno",
                "countryCode": "IT"
            },
            {
                "name": "Cesate",
                "countryCode": "IT"
            },
            {
                "name": "Ceto",
                "countryCode": "IT"
            },
            {
                "name": "Cevo",
                "countryCode": "IT"
            },
            {
                "name": "Chiaravalle",
                "countryCode": "IT"
            },
            {
                "name": "Chiari",
                "countryCode": "IT"
            },
            {
                "name": "Chiavenna",
                "countryCode": "IT"
            },
            {
                "name": "Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Chiesa in Valmalenco",
                "countryCode": "IT"
            },
            {
                "name": "Chieve",
                "countryCode": "IT"
            },
            {
                "name": "Chignolo Po",
                "countryCode": "IT"
            },
            {
                "name": "Chignolo d'Isola",
                "countryCode": "IT"
            },
            {
                "name": "Chiuduno",
                "countryCode": "IT"
            },
            {
                "name": "Chiuro",
                "countryCode": "IT"
            },
            {
                "name": "Cicognolo",
                "countryCode": "IT"
            },
            {
                "name": "Cigognola",
                "countryCode": "IT"
            },
            {
                "name": "Cigole",
                "countryCode": "IT"
            },
            {
                "name": "Cilavegna",
                "countryCode": "IT"
            },
            {
                "name": "Cimbergo",
                "countryCode": "IT"
            },
            {
                "name": "Cimbro",
                "countryCode": "IT"
            },
            {
                "name": "Cingia de' Botti",
                "countryCode": "IT"
            },
            {
                "name": "Cinisello Balsamo",
                "countryCode": "IT"
            },
            {
                "name": "Cino",
                "countryCode": "IT"
            },
            {
                "name": "Cirimido",
                "countryCode": "IT"
            },
            {
                "name": "Cisano Bergamasco",
                "countryCode": "IT"
            },
            {
                "name": "Ciserano",
                "countryCode": "IT"
            },
            {
                "name": "Cislago",
                "countryCode": "IT"
            },
            {
                "name": "Cisliano",
                "countryCode": "IT"
            },
            {
                "name": "Cittiglio",
                "countryCode": "IT"
            },
            {
                "name": "Città metropolitana di Milano",
                "countryCode": "IT"
            },
            {
                "name": "Civate",
                "countryCode": "IT"
            },
            {
                "name": "Civesio",
                "countryCode": "IT"
            },
            {
                "name": "Cividate Camuno",
                "countryCode": "IT"
            },
            {
                "name": "Cividate al Piano",
                "countryCode": "IT"
            },
            {
                "name": "Cizzago-Comezzano",
                "countryCode": "IT"
            },
            {
                "name": "Clivio",
                "countryCode": "IT"
            },
            {
                "name": "Clusane",
                "countryCode": "IT"
            },
            {
                "name": "Clusone",
                "countryCode": "IT"
            },
            {
                "name": "Coccaglio",
                "countryCode": "IT"
            },
            {
                "name": "Cocquio",
                "countryCode": "IT"
            },
            {
                "name": "Codevilla",
                "countryCode": "IT"
            },
            {
                "name": "Codogna-Cardano",
                "countryCode": "IT"
            },
            {
                "name": "Codogno",
                "countryCode": "IT"
            },
            {
                "name": "Cogliate",
                "countryCode": "IT"
            },
            {
                "name": "Colere",
                "countryCode": "IT"
            },
            {
                "name": "Colico Piano",
                "countryCode": "IT"
            },
            {
                "name": "Colla-Muggiasca",
                "countryCode": "IT"
            },
            {
                "name": "Colle Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Collebeato",
                "countryCode": "IT"
            },
            {
                "name": "Collio",
                "countryCode": "IT"
            },
            {
                "name": "Cologna-Caraverio",
                "countryCode": "IT"
            },
            {
                "name": "Cologne",
                "countryCode": "IT"
            },
            {
                "name": "Cologno Monzese",
                "countryCode": "IT"
            },
            {
                "name": "Cologno al Serio",
                "countryCode": "IT"
            },
            {
                "name": "Colombaro-Timoline",
                "countryCode": "IT"
            },
            {
                "name": "Colonno",
                "countryCode": "IT"
            },
            {
                "name": "Colorina",
                "countryCode": "IT"
            },
            {
                "name": "Colturano",
                "countryCode": "IT"
            },
            {
                "name": "Colzate",
                "countryCode": "IT"
            },
            {
                "name": "Comabbio",
                "countryCode": "IT"
            },
            {
                "name": "Comazzo",
                "countryCode": "IT"
            },
            {
                "name": "Comerio",
                "countryCode": "IT"
            },
            {
                "name": "Commessaggio",
                "countryCode": "IT"
            },
            {
                "name": "Como",
                "countryCode": "IT"
            },
            {
                "name": "Comun Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Concesio",
                "countryCode": "IT"
            },
            {
                "name": "Concorezzo",
                "countryCode": "IT"
            },
            {
                "name": "Confienza",
                "countryCode": "IT"
            },
            {
                "name": "Consiglio di Rumo",
                "countryCode": "IT"
            },
            {
                "name": "Copiano",
                "countryCode": "IT"
            },
            {
                "name": "Corana",
                "countryCode": "IT"
            },
            {
                "name": "Corbetta",
                "countryCode": "IT"
            },
            {
                "name": "Corgeno",
                "countryCode": "IT"
            },
            {
                "name": "Cormano",
                "countryCode": "IT"
            },
            {
                "name": "Corna Imagna",
                "countryCode": "IT"
            },
            {
                "name": "Cornalba",
                "countryCode": "IT"
            },
            {
                "name": "Cornale",
                "countryCode": "IT"
            },
            {
                "name": "Cornaredo",
                "countryCode": "IT"
            },
            {
                "name": "Cornate d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Corneno-Galliano-Carella Mariaga",
                "countryCode": "IT"
            },
            {
                "name": "Corno Giovine",
                "countryCode": "IT"
            },
            {
                "name": "Cornovecchio",
                "countryCode": "IT"
            },
            {
                "name": "Correzzana",
                "countryCode": "IT"
            },
            {
                "name": "Corrido",
                "countryCode": "IT"
            },
            {
                "name": "Corsico",
                "countryCode": "IT"
            },
            {
                "name": "Corte Franca",
                "countryCode": "IT"
            },
            {
                "name": "Corte de' Cortesi",
                "countryCode": "IT"
            },
            {
                "name": "Corte de' Cortesi con Cignone",
                "countryCode": "IT"
            },
            {
                "name": "Corte de' Frati",
                "countryCode": "IT"
            },
            {
                "name": "Corteno Golgi",
                "countryCode": "IT"
            },
            {
                "name": "Cortenova",
                "countryCode": "IT"
            },
            {
                "name": "Cortenuova",
                "countryCode": "IT"
            },
            {
                "name": "Corteolona",
                "countryCode": "IT"
            },
            {
                "name": "Corvino San Quirico",
                "countryCode": "IT"
            },
            {
                "name": "Corzano",
                "countryCode": "IT"
            },
            {
                "name": "Cosio Valtellino",
                "countryCode": "IT"
            },
            {
                "name": "Costa Lambro",
                "countryCode": "IT"
            },
            {
                "name": "Costa Masnaga",
                "countryCode": "IT"
            },
            {
                "name": "Costa Sant'Abramo",
                "countryCode": "IT"
            },
            {
                "name": "Costa Valle Imagna",
                "countryCode": "IT"
            },
            {
                "name": "Costa Volpino",
                "countryCode": "IT"
            },
            {
                "name": "Costa de' Nobili",
                "countryCode": "IT"
            },
            {
                "name": "Costa di Mezzate",
                "countryCode": "IT"
            },
            {
                "name": "Costa di Serina",
                "countryCode": "IT"
            },
            {
                "name": "Costa-Barco",
                "countryCode": "IT"
            },
            {
                "name": "Covo",
                "countryCode": "IT"
            },
            {
                "name": "Cozzo",
                "countryCode": "IT"
            },
            {
                "name": "Crandola Valsassina",
                "countryCode": "IT"
            },
            {
                "name": "Credaro",
                "countryCode": "IT"
            },
            {
                "name": "Credera",
                "countryCode": "IT"
            },
            {
                "name": "Crema",
                "countryCode": "IT"
            },
            {
                "name": "Cremella",
                "countryCode": "IT"
            },
            {
                "name": "Cremenaga",
                "countryCode": "IT"
            },
            {
                "name": "Cremeno",
                "countryCode": "IT"
            },
            {
                "name": "Cremia",
                "countryCode": "IT"
            },
            {
                "name": "Cremona",
                "countryCode": "IT"
            },
            {
                "name": "Cremosano",
                "countryCode": "IT"
            },
            {
                "name": "Crespiatica",
                "countryCode": "IT"
            },
            {
                "name": "Crone",
                "countryCode": "IT"
            },
            {
                "name": "Crosio della Valle",
                "countryCode": "IT"
            },
            {
                "name": "Crotta d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Crugnola",
                "countryCode": "IT"
            },
            {
                "name": "Cuasso al Monte",
                "countryCode": "IT"
            },
            {
                "name": "Cuasso al Piano",
                "countryCode": "IT"
            },
            {
                "name": "Cucciago",
                "countryCode": "IT"
            },
            {
                "name": "Cuggiono",
                "countryCode": "IT"
            },
            {
                "name": "Cugliate-Fabiasco",
                "countryCode": "IT"
            },
            {
                "name": "Cumignano sul Naviglio",
                "countryCode": "IT"
            },
            {
                "name": "Cunardo",
                "countryCode": "IT"
            },
            {
                "name": "Cunettone-Villa",
                "countryCode": "IT"
            },
            {
                "name": "Cura Carpignano",
                "countryCode": "IT"
            },
            {
                "name": "Curiglia",
                "countryCode": "IT"
            },
            {
                "name": "Curno",
                "countryCode": "IT"
            },
            {
                "name": "Cusago",
                "countryCode": "IT"
            },
            {
                "name": "Cusano",
                "countryCode": "IT"
            },
            {
                "name": "Cusino",
                "countryCode": "IT"
            },
            {
                "name": "Cusio",
                "countryCode": "IT"
            },
            {
                "name": "Cuveglio",
                "countryCode": "IT"
            },
            {
                "name": "Cuvio",
                "countryCode": "IT"
            },
            {
                "name": "Dairago",
                "countryCode": "IT"
            },
            {
                "name": "Dalmine",
                "countryCode": "IT"
            },
            {
                "name": "Darfo Boario Terme",
                "countryCode": "IT"
            },
            {
                "name": "Daverio",
                "countryCode": "IT"
            },
            {
                "name": "Dazio",
                "countryCode": "IT"
            },
            {
                "name": "Delebio",
                "countryCode": "IT"
            },
            {
                "name": "Dello",
                "countryCode": "IT"
            },
            {
                "name": "Derovere",
                "countryCode": "IT"
            },
            {
                "name": "Dervio",
                "countryCode": "IT"
            },
            {
                "name": "Desenzano del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Desio",
                "countryCode": "IT"
            },
            {
                "name": "Dizzasco-Biazzeno",
                "countryCode": "IT"
            },
            {
                "name": "Dolzago",
                "countryCode": "IT"
            },
            {
                "name": "Domaso",
                "countryCode": "IT"
            },
            {
                "name": "Dongo",
                "countryCode": "IT"
            },
            {
                "name": "Dorio",
                "countryCode": "IT"
            },
            {
                "name": "Dorno",
                "countryCode": "IT"
            },
            {
                "name": "Dosimo",
                "countryCode": "IT"
            },
            {
                "name": "Dosolo",
                "countryCode": "IT"
            },
            {
                "name": "Dossena",
                "countryCode": "IT"
            },
            {
                "name": "Dosso del Liro",
                "countryCode": "IT"
            },
            {
                "name": "Dosso-Ville",
                "countryCode": "IT"
            },
            {
                "name": "Dovera",
                "countryCode": "IT"
            },
            {
                "name": "Dresano",
                "countryCode": "IT"
            },
            {
                "name": "Drizzona",
                "countryCode": "IT"
            },
            {
                "name": "Dubino",
                "countryCode": "IT"
            },
            {
                "name": "Dumenza",
                "countryCode": "IT"
            },
            {
                "name": "Duno",
                "countryCode": "IT"
            },
            {
                "name": "Duomo",
                "countryCode": "IT"
            },
            {
                "name": "Edolo",
                "countryCode": "IT"
            },
            {
                "name": "Ello",
                "countryCode": "IT"
            },
            {
                "name": "Endine",
                "countryCode": "IT"
            },
            {
                "name": "Entratico",
                "countryCode": "IT"
            },
            {
                "name": "Era",
                "countryCode": "IT"
            },
            {
                "name": "Erba",
                "countryCode": "IT"
            },
            {
                "name": "Erbusco",
                "countryCode": "IT"
            },
            {
                "name": "Eremo",
                "countryCode": "IT"
            },
            {
                "name": "Erve",
                "countryCode": "IT"
            },
            {
                "name": "Esine",
                "countryCode": "IT"
            },
            {
                "name": "Esino Lario",
                "countryCode": "IT"
            },
            {
                "name": "Faedo Valtellino",
                "countryCode": "IT"
            },
            {
                "name": "Faggeto Lario",
                "countryCode": "IT"
            },
            {
                "name": "Fagnano Olona-Bergoro",
                "countryCode": "IT"
            },
            {
                "name": "Faloppio",
                "countryCode": "IT"
            },
            {
                "name": "Fantasina",
                "countryCode": "IT"
            },
            {
                "name": "Fara Gera d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Fara Olivana",
                "countryCode": "IT"
            },
            {
                "name": "Felonica",
                "countryCode": "IT"
            },
            {
                "name": "Fenegrò",
                "countryCode": "IT"
            },
            {
                "name": "Ferno",
                "countryCode": "IT"
            },
            {
                "name": "Ferrera Erbognone",
                "countryCode": "IT"
            },
            {
                "name": "Ferrera di Varese",
                "countryCode": "IT"
            },
            {
                "name": "Fiesco",
                "countryCode": "IT"
            },
            {
                "name": "Fiesse",
                "countryCode": "IT"
            },
            {
                "name": "Figino",
                "countryCode": "IT"
            },
            {
                "name": "Figino Serenza",
                "countryCode": "IT"
            },
            {
                "name": "Figliaro",
                "countryCode": "IT"
            },
            {
                "name": "Filago",
                "countryCode": "IT"
            },
            {
                "name": "Filighera",
                "countryCode": "IT"
            },
            {
                "name": "Fino Mornasco",
                "countryCode": "IT"
            },
            {
                "name": "Fino del Monte",
                "countryCode": "IT"
            },
            {
                "name": "Fiorano al Serio",
                "countryCode": "IT"
            },
            {
                "name": "Fizzonasco",
                "countryCode": "IT"
            },
            {
                "name": "Flero",
                "countryCode": "IT"
            },
            {
                "name": "Folzano",
                "countryCode": "IT"
            },
            {
                "name": "Fombio",
                "countryCode": "IT"
            },
            {
                "name": "Fontane-Zurane-Gresine",
                "countryCode": "IT"
            },
            {
                "name": "Fontanella",
                "countryCode": "IT"
            },
            {
                "name": "Fonteno",
                "countryCode": "IT"
            },
            {
                "name": "Foppolo",
                "countryCode": "IT"
            },
            {
                "name": "Forcola",
                "countryCode": "IT"
            },
            {
                "name": "Foresto Sparso",
                "countryCode": "IT"
            },
            {
                "name": "Formigara",
                "countryCode": "IT"
            },
            {
                "name": "Fornaci",
                "countryCode": "IT"
            },
            {
                "name": "Fornovo San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Fortunago",
                "countryCode": "IT"
            },
            {
                "name": "Frascarolo",
                "countryCode": "IT"
            },
            {
                "name": "Fuipiano Valle Imagna",
                "countryCode": "IT"
            },
            {
                "name": "Furato",
                "countryCode": "IT"
            },
            {
                "name": "Fusine",
                "countryCode": "IT"
            },
            {
                "name": "Gabbioneta",
                "countryCode": "IT"
            },
            {
                "name": "Gadesco-Pieve Delmona",
                "countryCode": "IT"
            },
            {
                "name": "Gaggiano",
                "countryCode": "IT"
            },
            {
                "name": "Gaggino",
                "countryCode": "IT"
            },
            {
                "name": "Galbiate",
                "countryCode": "IT"
            },
            {
                "name": "Galgagnano",
                "countryCode": "IT"
            },
            {
                "name": "Gallarate",
                "countryCode": "IT"
            },
            {
                "name": "Galliate Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Galliavola",
                "countryCode": "IT"
            },
            {
                "name": "Gallo-Tre Re-Mezzana Corti",
                "countryCode": "IT"
            },
            {
                "name": "Gambara",
                "countryCode": "IT"
            },
            {
                "name": "Gambarana",
                "countryCode": "IT"
            },
            {
                "name": "Gambolò",
                "countryCode": "IT"
            },
            {
                "name": "Gandellino",
                "countryCode": "IT"
            },
            {
                "name": "Gandino",
                "countryCode": "IT"
            },
            {
                "name": "Gandosso",
                "countryCode": "IT"
            },
            {
                "name": "Ganna",
                "countryCode": "IT"
            },
            {
                "name": "Garbagnate",
                "countryCode": "IT"
            },
            {
                "name": "Garbagnate Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Garbagnate Monastero",
                "countryCode": "IT"
            },
            {
                "name": "Gardola",
                "countryCode": "IT"
            },
            {
                "name": "Gardone Riviera",
                "countryCode": "IT"
            },
            {
                "name": "Gardone Val Trompia",
                "countryCode": "IT"
            },
            {
                "name": "Gargnano",
                "countryCode": "IT"
            },
            {
                "name": "Garlasco",
                "countryCode": "IT"
            },
            {
                "name": "Garlate",
                "countryCode": "IT"
            },
            {
                "name": "Garzeno",
                "countryCode": "IT"
            },
            {
                "name": "Gavardo-Sopraponte",
                "countryCode": "IT"
            },
            {
                "name": "Gavarno Rinnovata",
                "countryCode": "IT"
            },
            {
                "name": "Gavarno-Tribulina",
                "countryCode": "IT"
            },
            {
                "name": "Gaverina Terme",
                "countryCode": "IT"
            },
            {
                "name": "Gavirate",
                "countryCode": "IT"
            },
            {
                "name": "Gazoldo degli Ippoliti",
                "countryCode": "IT"
            },
            {
                "name": "Gazzada Schianno",
                "countryCode": "IT"
            },
            {
                "name": "Gazzaniga",
                "countryCode": "IT"
            },
            {
                "name": "Gazzo",
                "countryCode": "IT"
            },
            {
                "name": "Gazzuolo",
                "countryCode": "IT"
            },
            {
                "name": "Gemonio",
                "countryCode": "IT"
            },
            {
                "name": "Genivolta",
                "countryCode": "IT"
            },
            {
                "name": "Genzone",
                "countryCode": "IT"
            },
            {
                "name": "Gera Lario",
                "countryCode": "IT"
            },
            {
                "name": "Gerenzago",
                "countryCode": "IT"
            },
            {
                "name": "Gerenzano",
                "countryCode": "IT"
            },
            {
                "name": "Germignaga",
                "countryCode": "IT"
            },
            {
                "name": "Gerola Alta",
                "countryCode": "IT"
            },
            {
                "name": "Geromina",
                "countryCode": "IT"
            },
            {
                "name": "Gerosa",
                "countryCode": "IT"
            },
            {
                "name": "Gerre de' Caprioli",
                "countryCode": "IT"
            },
            {
                "name": "Gessate",
                "countryCode": "IT"
            },
            {
                "name": "Ghedi",
                "countryCode": "IT"
            },
            {
                "name": "Ghiaie",
                "countryCode": "IT"
            },
            {
                "name": "Ghisalba",
                "countryCode": "IT"
            },
            {
                "name": "Gianico",
                "countryCode": "IT"
            },
            {
                "name": "Giovenzano",
                "countryCode": "IT"
            },
            {
                "name": "Gironico al Piano",
                "countryCode": "IT"
            },
            {
                "name": "Giussago",
                "countryCode": "IT"
            },
            {
                "name": "Giussano",
                "countryCode": "IT"
            },
            {
                "name": "Godiasco",
                "countryCode": "IT"
            },
            {
                "name": "Goito",
                "countryCode": "IT"
            },
            {
                "name": "Golasecca",
                "countryCode": "IT"
            },
            {
                "name": "Golferenzo",
                "countryCode": "IT"
            },
            {
                "name": "Gombito",
                "countryCode": "IT"
            },
            {
                "name": "Gonzaga",
                "countryCode": "IT"
            },
            {
                "name": "Gordona",
                "countryCode": "IT"
            },
            {
                "name": "Gorgonzola",
                "countryCode": "IT"
            },
            {
                "name": "Gorla Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Gorla Minore",
                "countryCode": "IT"
            },
            {
                "name": "Gorlago",
                "countryCode": "IT"
            },
            {
                "name": "Gorle",
                "countryCode": "IT"
            },
            {
                "name": "Gornate Olona",
                "countryCode": "IT"
            },
            {
                "name": "Gorno",
                "countryCode": "IT"
            },
            {
                "name": "Gottolengo",
                "countryCode": "IT"
            },
            {
                "name": "Graffignana",
                "countryCode": "IT"
            },
            {
                "name": "Grandate",
                "countryCode": "IT"
            },
            {
                "name": "Grandola ed Uniti",
                "countryCode": "IT"
            },
            {
                "name": "Grantola",
                "countryCode": "IT"
            },
            {
                "name": "Grassobbio",
                "countryCode": "IT"
            },
            {
                "name": "Gratacasolo",
                "countryCode": "IT"
            },
            {
                "name": "Gravedona-San Gregorio",
                "countryCode": "IT"
            },
            {
                "name": "Gravellona",
                "countryCode": "IT"
            },
            {
                "name": "Grezzago",
                "countryCode": "IT"
            },
            {
                "name": "Griante",
                "countryCode": "IT"
            },
            {
                "name": "Grignano",
                "countryCode": "IT"
            },
            {
                "name": "Gromlongo",
                "countryCode": "IT"
            },
            {
                "name": "Gromo",
                "countryCode": "IT"
            },
            {
                "name": "Grone",
                "countryCode": "IT"
            },
            {
                "name": "Grontardo",
                "countryCode": "IT"
            },
            {
                "name": "Gropello Cairoli",
                "countryCode": "IT"
            },
            {
                "name": "Grosio",
                "countryCode": "IT"
            },
            {
                "name": "Grosotto",
                "countryCode": "IT"
            },
            {
                "name": "Grumello Cremonese",
                "countryCode": "IT"
            },
            {
                "name": "Grumello del Monte",
                "countryCode": "IT"
            },
            {
                "name": "Guanzate",
                "countryCode": "IT"
            },
            {
                "name": "Guardamiglio",
                "countryCode": "IT"
            },
            {
                "name": "Gudo Visconti",
                "countryCode": "IT"
            },
            {
                "name": "Guidizzolo",
                "countryCode": "IT"
            },
            {
                "name": "Guinzano",
                "countryCode": "IT"
            },
            {
                "name": "Gussago",
                "countryCode": "IT"
            },
            {
                "name": "Gussola",
                "countryCode": "IT"
            },
            {
                "name": "Idro",
                "countryCode": "IT"
            },
            {
                "name": "Imbersago",
                "countryCode": "IT"
            },
            {
                "name": "Inarzo",
                "countryCode": "IT"
            },
            {
                "name": "Incudine",
                "countryCode": "IT"
            },
            {
                "name": "Induno Olona",
                "countryCode": "IT"
            },
            {
                "name": "Introbio",
                "countryCode": "IT"
            },
            {
                "name": "Introzzo",
                "countryCode": "IT"
            },
            {
                "name": "Inverigo",
                "countryCode": "IT"
            },
            {
                "name": "Inverno",
                "countryCode": "IT"
            },
            {
                "name": "Inverno e Monteleone",
                "countryCode": "IT"
            },
            {
                "name": "Inveruno",
                "countryCode": "IT"
            },
            {
                "name": "Inzago",
                "countryCode": "IT"
            },
            {
                "name": "Irma",
                "countryCode": "IT"
            },
            {
                "name": "Iseo",
                "countryCode": "IT"
            },
            {
                "name": "Isola Dovarese",
                "countryCode": "IT"
            },
            {
                "name": "Isola di Fondra",
                "countryCode": "IT"
            },
            {
                "name": "Isolaccia",
                "countryCode": "IT"
            },
            {
                "name": "Isorella",
                "countryCode": "IT"
            },
            {
                "name": "Ispra",
                "countryCode": "IT"
            },
            {
                "name": "Isso",
                "countryCode": "IT"
            },
            {
                "name": "Izano",
                "countryCode": "IT"
            },
            {
                "name": "Jerago Con Orago",
                "countryCode": "IT"
            },
            {
                "name": "Lacchiarella",
                "countryCode": "IT"
            },
            {
                "name": "Laglio",
                "countryCode": "IT"
            },
            {
                "name": "Lainate",
                "countryCode": "IT"
            },
            {
                "name": "Laino",
                "countryCode": "IT"
            },
            {
                "name": "Lallio",
                "countryCode": "IT"
            },
            {
                "name": "Lambrinia",
                "countryCode": "IT"
            },
            {
                "name": "Lambrugo",
                "countryCode": "IT"
            },
            {
                "name": "Landriano",
                "countryCode": "IT"
            },
            {
                "name": "Langosco",
                "countryCode": "IT"
            },
            {
                "name": "Lanzada",
                "countryCode": "IT"
            },
            {
                "name": "Lanzo d'Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "Lardirago",
                "countryCode": "IT"
            },
            {
                "name": "Lasnigo",
                "countryCode": "IT"
            },
            {
                "name": "Lavena Ponte Tresa",
                "countryCode": "IT"
            },
            {
                "name": "Laveno",
                "countryCode": "IT"
            },
            {
                "name": "Lavenone",
                "countryCode": "IT"
            },
            {
                "name": "Laxolo",
                "countryCode": "IT"
            },
            {
                "name": "Lazzate",
                "countryCode": "IT"
            },
            {
                "name": "Lecco",
                "countryCode": "IT"
            },
            {
                "name": "Leffe",
                "countryCode": "IT"
            },
            {
                "name": "Leggiuno",
                "countryCode": "IT"
            },
            {
                "name": "Legnano",
                "countryCode": "IT"
            },
            {
                "name": "Lemna",
                "countryCode": "IT"
            },
            {
                "name": "Lenna",
                "countryCode": "IT"
            },
            {
                "name": "Lenno",
                "countryCode": "IT"
            },
            {
                "name": "Leno",
                "countryCode": "IT"
            },
            {
                "name": "Lentate sul Seveso",
                "countryCode": "IT"
            },
            {
                "name": "Lesmo",
                "countryCode": "IT"
            },
            {
                "name": "Levata",
                "countryCode": "IT"
            },
            {
                "name": "Levate",
                "countryCode": "IT"
            },
            {
                "name": "Lezzeno",
                "countryCode": "IT"
            },
            {
                "name": "Lierna",
                "countryCode": "IT"
            },
            {
                "name": "Limbiate",
                "countryCode": "IT"
            },
            {
                "name": "Limido Comasco",
                "countryCode": "IT"
            },
            {
                "name": "Limone sul Garda",
                "countryCode": "IT"
            },
            {
                "name": "Linarolo",
                "countryCode": "IT"
            },
            {
                "name": "Linate",
                "countryCode": "IT"
            },
            {
                "name": "Lipomo",
                "countryCode": "IT"
            },
            {
                "name": "Lirio",
                "countryCode": "IT"
            },
            {
                "name": "Lisanza",
                "countryCode": "IT"
            },
            {
                "name": "Liscate",
                "countryCode": "IT"
            },
            {
                "name": "Lissone",
                "countryCode": "IT"
            },
            {
                "name": "Livigno",
                "countryCode": "IT"
            },
            {
                "name": "Livo",
                "countryCode": "IT"
            },
            {
                "name": "Livraga",
                "countryCode": "IT"
            },
            {
                "name": "Locate Varesino",
                "countryCode": "IT"
            },
            {
                "name": "Locate di Triulzi",
                "countryCode": "IT"
            },
            {
                "name": "Locatello",
                "countryCode": "IT"
            },
            {
                "name": "Lodetto",
                "countryCode": "IT"
            },
            {
                "name": "Lodi",
                "countryCode": "IT"
            },
            {
                "name": "Lodi Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "Lodrino",
                "countryCode": "IT"
            },
            {
                "name": "Lograto",
                "countryCode": "IT"
            },
            {
                "name": "Lomagna",
                "countryCode": "IT"
            },
            {
                "name": "Lomazzo",
                "countryCode": "IT"
            },
            {
                "name": "Lomello",
                "countryCode": "IT"
            },
            {
                "name": "Lonate Ceppino",
                "countryCode": "IT"
            },
            {
                "name": "Lonate Pozzolo",
                "countryCode": "IT"
            },
            {
                "name": "Lonato",
                "countryCode": "IT"
            },
            {
                "name": "Longhena",
                "countryCode": "IT"
            },
            {
                "name": "Longone al Segrino",
                "countryCode": "IT"
            },
            {
                "name": "Losine",
                "countryCode": "IT"
            },
            {
                "name": "Lovere",
                "countryCode": "IT"
            },
            {
                "name": "Lovero",
                "countryCode": "IT"
            },
            {
                "name": "Lozio",
                "countryCode": "IT"
            },
            {
                "name": "Lozza",
                "countryCode": "IT"
            },
            {
                "name": "Lucino",
                "countryCode": "IT"
            },
            {
                "name": "Lucino-Rodano",
                "countryCode": "IT"
            },
            {
                "name": "Ludriano",
                "countryCode": "IT"
            },
            {
                "name": "Luino",
                "countryCode": "IT"
            },
            {
                "name": "Luisago",
                "countryCode": "IT"
            },
            {
                "name": "Lumezzane",
                "countryCode": "IT"
            },
            {
                "name": "Lunetta-Frassino",
                "countryCode": "IT"
            },
            {
                "name": "Lungavilla",
                "countryCode": "IT"
            },
            {
                "name": "Lurago Marinone",
                "countryCode": "IT"
            },
            {
                "name": "Lurago d'Erba",
                "countryCode": "IT"
            },
            {
                "name": "Lurano",
                "countryCode": "IT"
            },
            {
                "name": "Lurate Caccivio",
                "countryCode": "IT"
            },
            {
                "name": "Luvinate",
                "countryCode": "IT"
            },
            {
                "name": "Luzzana",
                "countryCode": "IT"
            },
            {
                "name": "Maccagno",
                "countryCode": "IT"
            },
            {
                "name": "Maccastorna",
                "countryCode": "IT"
            },
            {
                "name": "Macherio",
                "countryCode": "IT"
            },
            {
                "name": "Maclodio",
                "countryCode": "IT"
            },
            {
                "name": "Madignano",
                "countryCode": "IT"
            },
            {
                "name": "Madone",
                "countryCode": "IT"
            },
            {
                "name": "Magasa",
                "countryCode": "IT"
            },
            {
                "name": "Magenta",
                "countryCode": "IT"
            },
            {
                "name": "Magherno",
                "countryCode": "IT"
            },
            {
                "name": "Magnacavallo",
                "countryCode": "IT"
            },
            {
                "name": "Magnago",
                "countryCode": "IT"
            },
            {
                "name": "Magno",
                "countryCode": "IT"
            },
            {
                "name": "Magreglio",
                "countryCode": "IT"
            },
            {
                "name": "Mairago",
                "countryCode": "IT"
            },
            {
                "name": "Mairano",
                "countryCode": "IT"
            },
            {
                "name": "Malagnino",
                "countryCode": "IT"
            },
            {
                "name": "Malavicina",
                "countryCode": "IT"
            },
            {
                "name": "Malegno",
                "countryCode": "IT"
            },
            {
                "name": "Maleo",
                "countryCode": "IT"
            },
            {
                "name": "Malgesso",
                "countryCode": "IT"
            },
            {
                "name": "Malgrate",
                "countryCode": "IT"
            },
            {
                "name": "Malnate",
                "countryCode": "IT"
            },
            {
                "name": "Malonno",
                "countryCode": "IT"
            },
            {
                "name": "Mandello del Lario",
                "countryCode": "IT"
            },
            {
                "name": "Manera",
                "countryCode": "IT"
            },
            {
                "name": "Manerba del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Manerbio",
                "countryCode": "IT"
            },
            {
                "name": "Mantegazza",
                "countryCode": "IT"
            },
            {
                "name": "Mantello",
                "countryCode": "IT"
            },
            {
                "name": "Mantova",
                "countryCode": "IT"
            },
            {
                "name": "Mapello",
                "countryCode": "IT"
            },
            {
                "name": "Marcallo",
                "countryCode": "IT"
            },
            {
                "name": "Marcaria",
                "countryCode": "IT"
            },
            {
                "name": "Marcheno",
                "countryCode": "IT"
            },
            {
                "name": "Marchirolo",
                "countryCode": "IT"
            },
            {
                "name": "Marcignago",
                "countryCode": "IT"
            },
            {
                "name": "Maresso",
                "countryCode": "IT"
            },
            {
                "name": "Margno",
                "countryCode": "IT"
            },
            {
                "name": "Mariana Mantovana",
                "countryCode": "IT"
            },
            {
                "name": "Mariano Comense",
                "countryCode": "IT"
            },
            {
                "name": "Marmentino",
                "countryCode": "IT"
            },
            {
                "name": "Marmirolo",
                "countryCode": "IT"
            },
            {
                "name": "Marnate",
                "countryCode": "IT"
            },
            {
                "name": "Marone",
                "countryCode": "IT"
            },
            {
                "name": "Martignana di Po",
                "countryCode": "IT"
            },
            {
                "name": "Martinengo",
                "countryCode": "IT"
            },
            {
                "name": "Marudo",
                "countryCode": "IT"
            },
            {
                "name": "Marzano",
                "countryCode": "IT"
            },
            {
                "name": "Marzio",
                "countryCode": "IT"
            },
            {
                "name": "Masate",
                "countryCode": "IT"
            },
            {
                "name": "Masciago Primo",
                "countryCode": "IT"
            },
            {
                "name": "Maslianico",
                "countryCode": "IT"
            },
            {
                "name": "Massalengo-Motta Vigana",
                "countryCode": "IT"
            },
            {
                "name": "Mazzano",
                "countryCode": "IT"
            },
            {
                "name": "Mazzo di Valtellina",
                "countryCode": "IT"
            },
            {
                "name": "Meda",
                "countryCode": "IT"
            },
            {
                "name": "Mede",
                "countryCode": "IT"
            },
            {
                "name": "Mediglia",
                "countryCode": "IT"
            },
            {
                "name": "Medolago",
                "countryCode": "IT"
            },
            {
                "name": "Medole",
                "countryCode": "IT"
            },
            {
                "name": "Melegnano",
                "countryCode": "IT"
            },
            {
                "name": "Meleti",
                "countryCode": "IT"
            },
            {
                "name": "Mello",
                "countryCode": "IT"
            },
            {
                "name": "Melzo",
                "countryCode": "IT"
            },
            {
                "name": "Menaggio",
                "countryCode": "IT"
            },
            {
                "name": "Menarola",
                "countryCode": "IT"
            },
            {
                "name": "Menconico",
                "countryCode": "IT"
            },
            {
                "name": "Merate",
                "countryCode": "IT"
            },
            {
                "name": "Mercallo",
                "countryCode": "IT"
            },
            {
                "name": "Merlino",
                "countryCode": "IT"
            },
            {
                "name": "Merone",
                "countryCode": "IT"
            },
            {
                "name": "Mese",
                "countryCode": "IT"
            },
            {
                "name": "Mesenzana",
                "countryCode": "IT"
            },
            {
                "name": "Mesero",
                "countryCode": "IT"
            },
            {
                "name": "Mezzago",
                "countryCode": "IT"
            },
            {
                "name": "Mezzana Bigli",
                "countryCode": "IT"
            },
            {
                "name": "Mezzana Rabattone",
                "countryCode": "IT"
            },
            {
                "name": "Mezzanino",
                "countryCode": "IT"
            },
            {
                "name": "Mezzate",
                "countryCode": "IT"
            },
            {
                "name": "Mezzegra",
                "countryCode": "IT"
            },
            {
                "name": "Mezzoldo",
                "countryCode": "IT"
            },
            {
                "name": "Milan",
                "countryCode": "IT"
            },
            {
                "name": "Millepini",
                "countryCode": "IT"
            },
            {
                "name": "Milzano",
                "countryCode": "IT"
            },
            {
                "name": "Miradolo Terme",
                "countryCode": "IT"
            },
            {
                "name": "Misano di Gera d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Misinto",
                "countryCode": "IT"
            },
            {
                "name": "Missaglia",
                "countryCode": "IT"
            },
            {
                "name": "Moggio",
                "countryCode": "IT"
            },
            {
                "name": "Moglia",
                "countryCode": "IT"
            },
            {
                "name": "Moio de' Calvi",
                "countryCode": "IT"
            },
            {
                "name": "Molinetto",
                "countryCode": "IT"
            },
            {
                "name": "Molteno",
                "countryCode": "IT"
            },
            {
                "name": "Moltrasio",
                "countryCode": "IT"
            },
            {
                "name": "Mombelli",
                "countryCode": "IT"
            },
            {
                "name": "Mombretto",
                "countryCode": "IT"
            },
            {
                "name": "Monasterolo",
                "countryCode": "IT"
            },
            {
                "name": "Monasterolo del Castello",
                "countryCode": "IT"
            },
            {
                "name": "Moncucco",
                "countryCode": "IT"
            },
            {
                "name": "Moniga del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Monno",
                "countryCode": "IT"
            },
            {
                "name": "Montagna in Valtellina",
                "countryCode": "IT"
            },
            {
                "name": "Montalto Pavese",
                "countryCode": "IT"
            },
            {
                "name": "Montanara",
                "countryCode": "IT"
            },
            {
                "name": "Montanaso Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Montano",
                "countryCode": "IT"
            },
            {
                "name": "Montano Lucino",
                "countryCode": "IT"
            },
            {
                "name": "Monte",
                "countryCode": "IT"
            },
            {
                "name": "Monte Cremasco",
                "countryCode": "IT"
            },
            {
                "name": "Monte Marenzo",
                "countryCode": "IT"
            },
            {
                "name": "Montebello",
                "countryCode": "IT"
            },
            {
                "name": "Montecalvo Versiggia",
                "countryCode": "IT"
            },
            {
                "name": "Montegrino Valtravaglia",
                "countryCode": "IT"
            },
            {
                "name": "Montello",
                "countryCode": "IT"
            },
            {
                "name": "Montemezzo",
                "countryCode": "IT"
            },
            {
                "name": "Montescano",
                "countryCode": "IT"
            },
            {
                "name": "Montesegale",
                "countryCode": "IT"
            },
            {
                "name": "Montesolaro",
                "countryCode": "IT"
            },
            {
                "name": "Montevecchia",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli Brusati",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli Pavese",
                "countryCode": "IT"
            },
            {
                "name": "Monticello",
                "countryCode": "IT"
            },
            {
                "name": "Monticello Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Montichiari",
                "countryCode": "IT"
            },
            {
                "name": "Montirone",
                "countryCode": "IT"
            },
            {
                "name": "Montodine",
                "countryCode": "IT"
            },
            {
                "name": "Montorfano",
                "countryCode": "IT"
            },
            {
                "name": "Montù Beccaria",
                "countryCode": "IT"
            },
            {
                "name": "Monvalle",
                "countryCode": "IT"
            },
            {
                "name": "Monza",
                "countryCode": "IT"
            },
            {
                "name": "Monzambano",
                "countryCode": "IT"
            },
            {
                "name": "Morazzone",
                "countryCode": "IT"
            },
            {
                "name": "Morbegno",
                "countryCode": "IT"
            },
            {
                "name": "Morengo",
                "countryCode": "IT"
            },
            {
                "name": "Morimondo",
                "countryCode": "IT"
            },
            {
                "name": "Mornago",
                "countryCode": "IT"
            },
            {
                "name": "Mornico",
                "countryCode": "IT"
            },
            {
                "name": "Mornico Losana",
                "countryCode": "IT"
            },
            {
                "name": "Mornico al Serio",
                "countryCode": "IT"
            },
            {
                "name": "Mortara",
                "countryCode": "IT"
            },
            {
                "name": "Morterone",
                "countryCode": "IT"
            },
            {
                "name": "Moscazzano",
                "countryCode": "IT"
            },
            {
                "name": "Motta Baluffi",
                "countryCode": "IT"
            },
            {
                "name": "Motta Visconti",
                "countryCode": "IT"
            },
            {
                "name": "Motteggiana",
                "countryCode": "IT"
            },
            {
                "name": "Mottella",
                "countryCode": "IT"
            },
            {
                "name": "Mozzanica",
                "countryCode": "IT"
            },
            {
                "name": "Mozzate",
                "countryCode": "IT"
            },
            {
                "name": "Mozzo",
                "countryCode": "IT"
            },
            {
                "name": "Muggiò",
                "countryCode": "IT"
            },
            {
                "name": "Mulazzano",
                "countryCode": "IT"
            },
            {
                "name": "Mura",
                "countryCode": "IT"
            },
            {
                "name": "Muscoline",
                "countryCode": "IT"
            },
            {
                "name": "Musso",
                "countryCode": "IT"
            },
            {
                "name": "Muzza di Cornegliano Laudense",
                "countryCode": "IT"
            },
            {
                "name": "Nava",
                "countryCode": "IT"
            },
            {
                "name": "Nave",
                "countryCode": "IT"
            },
            {
                "name": "Navedano",
                "countryCode": "IT"
            },
            {
                "name": "Negrone",
                "countryCode": "IT"
            },
            {
                "name": "Nembro",
                "countryCode": "IT"
            },
            {
                "name": "Nerviano",
                "countryCode": "IT"
            },
            {
                "name": "Nesso",
                "countryCode": "IT"
            },
            {
                "name": "Niardo",
                "countryCode": "IT"
            },
            {
                "name": "Nibionno",
                "countryCode": "IT"
            },
            {
                "name": "Nicorvo",
                "countryCode": "IT"
            },
            {
                "name": "Nigoline-Bonomelli",
                "countryCode": "IT"
            },
            {
                "name": "Nobile-Monguzzo",
                "countryCode": "IT"
            },
            {
                "name": "Nosadello",
                "countryCode": "IT"
            },
            {
                "name": "Nosate",
                "countryCode": "IT"
            },
            {
                "name": "Nova Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Novagli",
                "countryCode": "IT"
            },
            {
                "name": "Novate Mezzola",
                "countryCode": "IT"
            },
            {
                "name": "Novate Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Novedrate",
                "countryCode": "IT"
            },
            {
                "name": "Novegro-Tregarezzo-San Felice",
                "countryCode": "IT"
            },
            {
                "name": "Noverasco-Sporting Mirasole",
                "countryCode": "IT"
            },
            {
                "name": "Noviglio",
                "countryCode": "IT"
            },
            {
                "name": "Nuvolento",
                "countryCode": "IT"
            },
            {
                "name": "Nuvolera",
                "countryCode": "IT"
            },
            {
                "name": "Occagno",
                "countryCode": "IT"
            },
            {
                "name": "Odolo",
                "countryCode": "IT"
            },
            {
                "name": "Offanengo",
                "countryCode": "IT"
            },
            {
                "name": "Offlaga",
                "countryCode": "IT"
            },
            {
                "name": "Oggiona-Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Oggiono",
                "countryCode": "IT"
            },
            {
                "name": "Olevano di Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Olgiate Comasco",
                "countryCode": "IT"
            },
            {
                "name": "Olgiate Molgora",
                "countryCode": "IT"
            },
            {
                "name": "Olgiate Olona",
                "countryCode": "IT"
            },
            {
                "name": "Olginate",
                "countryCode": "IT"
            },
            {
                "name": "Oliva Gessi",
                "countryCode": "IT"
            },
            {
                "name": "Olmeneta",
                "countryCode": "IT"
            },
            {
                "name": "Olmo al Brembo",
                "countryCode": "IT"
            },
            {
                "name": "Oltre Il Colle",
                "countryCode": "IT"
            },
            {
                "name": "Oltressenda Alta",
                "countryCode": "IT"
            },
            {
                "name": "Oltrona di San Mamette",
                "countryCode": "IT"
            },
            {
                "name": "Ome",
                "countryCode": "IT"
            },
            {
                "name": "Oneta",
                "countryCode": "IT"
            },
            {
                "name": "Ono San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Onore",
                "countryCode": "IT"
            },
            {
                "name": "Opera",
                "countryCode": "IT"
            },
            {
                "name": "Origgio",
                "countryCode": "IT"
            },
            {
                "name": "Orino",
                "countryCode": "IT"
            },
            {
                "name": "Orio Litta",
                "countryCode": "IT"
            },
            {
                "name": "Orio al Serio",
                "countryCode": "IT"
            },
            {
                "name": "Ornago",
                "countryCode": "IT"
            },
            {
                "name": "Ornica",
                "countryCode": "IT"
            },
            {
                "name": "Orsenigo",
                "countryCode": "IT"
            },
            {
                "name": "Orzinuovi",
                "countryCode": "IT"
            },
            {
                "name": "Orzivecchi",
                "countryCode": "IT"
            },
            {
                "name": "Osigo",
                "countryCode": "IT"
            },
            {
                "name": "Osio Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Osio Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Osmate",
                "countryCode": "IT"
            },
            {
                "name": "Osnago",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Ospitaletto",
                "countryCode": "IT"
            },
            {
                "name": "Ossago Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Ossimo Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Ossona",
                "countryCode": "IT"
            },
            {
                "name": "Ossuccio",
                "countryCode": "IT"
            },
            {
                "name": "Osteno",
                "countryCode": "IT"
            },
            {
                "name": "Osteno-Claino",
                "countryCode": "IT"
            },
            {
                "name": "Ostiano",
                "countryCode": "IT"
            },
            {
                "name": "Ostiglia",
                "countryCode": "IT"
            },
            {
                "name": "Ottobiano",
                "countryCode": "IT"
            },
            {
                "name": "Ozzero",
                "countryCode": "IT"
            },
            {
                "name": "Padenghe sul Garda",
                "countryCode": "IT"
            },
            {
                "name": "Paderno Dugnano",
                "countryCode": "IT"
            },
            {
                "name": "Paderno Franciacorta",
                "countryCode": "IT"
            },
            {
                "name": "Paderno Ponchielli",
                "countryCode": "IT"
            },
            {
                "name": "Paderno d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Pagazzano",
                "countryCode": "IT"
            },
            {
                "name": "Pagnona",
                "countryCode": "IT"
            },
            {
                "name": "Paisco Loveno",
                "countryCode": "IT"
            },
            {
                "name": "Paitone",
                "countryCode": "IT"
            },
            {
                "name": "Paladina",
                "countryCode": "IT"
            },
            {
                "name": "Palazzago",
                "countryCode": "IT"
            },
            {
                "name": "Palazzo Pignano",
                "countryCode": "IT"
            },
            {
                "name": "Palazzolo sull'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Palestro",
                "countryCode": "IT"
            },
            {
                "name": "Palidano",
                "countryCode": "IT"
            },
            {
                "name": "Palosco",
                "countryCode": "IT"
            },
            {
                "name": "Pancarana",
                "countryCode": "IT"
            },
            {
                "name": "Pandino",
                "countryCode": "IT"
            },
            {
                "name": "Pantigliate",
                "countryCode": "IT"
            },
            {
                "name": "Parabiago",
                "countryCode": "IT"
            },
            {
                "name": "Paratico",
                "countryCode": "IT"
            },
            {
                "name": "Parlasco",
                "countryCode": "IT"
            },
            {
                "name": "Parmezzana Calzana",
                "countryCode": "IT"
            },
            {
                "name": "Parona",
                "countryCode": "IT"
            },
            {
                "name": "Parre",
                "countryCode": "IT"
            },
            {
                "name": "Parzanica",
                "countryCode": "IT"
            },
            {
                "name": "Parè",
                "countryCode": "IT"
            },
            {
                "name": "Paspardo",
                "countryCode": "IT"
            },
            {
                "name": "Passirana",
                "countryCode": "IT"
            },
            {
                "name": "Passirano",
                "countryCode": "IT"
            },
            {
                "name": "Pasturo",
                "countryCode": "IT"
            },
            {
                "name": "Paullo",
                "countryCode": "IT"
            },
            {
                "name": "Pavia",
                "countryCode": "IT"
            },
            {
                "name": "Pavone del Mella",
                "countryCode": "IT"
            },
            {
                "name": "Pedesina",
                "countryCode": "IT"
            },
            {
                "name": "Pedrengo",
                "countryCode": "IT"
            },
            {
                "name": "Peglio",
                "countryCode": "IT"
            },
            {
                "name": "Pegognaga",
                "countryCode": "IT"
            },
            {
                "name": "Peia",
                "countryCode": "IT"
            },
            {
                "name": "Pellio Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "Perego",
                "countryCode": "IT"
            },
            {
                "name": "Perledo",
                "countryCode": "IT"
            },
            {
                "name": "Pero",
                "countryCode": "IT"
            },
            {
                "name": "Persico Dosimo",
                "countryCode": "IT"
            },
            {
                "name": "Pertica Alta",
                "countryCode": "IT"
            },
            {
                "name": "Pertica Bassa",
                "countryCode": "IT"
            },
            {
                "name": "Pescarolo",
                "countryCode": "IT"
            },
            {
                "name": "Pescate",
                "countryCode": "IT"
            },
            {
                "name": "Pessano Con Bornago",
                "countryCode": "IT"
            },
            {
                "name": "Pessina Cremonese",
                "countryCode": "IT"
            },
            {
                "name": "Pezzaze",
                "countryCode": "IT"
            },
            {
                "name": "Piadena",
                "countryCode": "IT"
            },
            {
                "name": "Piamborno",
                "countryCode": "IT"
            },
            {
                "name": "Pian Camuno",
                "countryCode": "IT"
            },
            {
                "name": "Pianazzo",
                "countryCode": "IT"
            },
            {
                "name": "Pianello del Lario",
                "countryCode": "IT"
            },
            {
                "name": "Pianengo",
                "countryCode": "IT"
            },
            {
                "name": "Piangaiano",
                "countryCode": "IT"
            },
            {
                "name": "Pianico",
                "countryCode": "IT"
            },
            {
                "name": "Piantedo",
                "countryCode": "IT"
            },
            {
                "name": "Piario",
                "countryCode": "IT"
            },
            {
                "name": "Piateda Centro",
                "countryCode": "IT"
            },
            {
                "name": "Piazza",
                "countryCode": "IT"
            },
            {
                "name": "Piazza Brembana",
                "countryCode": "IT"
            },
            {
                "name": "Piazza Caduti",
                "countryCode": "IT"
            },
            {
                "name": "Piazzatorre",
                "countryCode": "IT"
            },
            {
                "name": "Piazzolo",
                "countryCode": "IT"
            },
            {
                "name": "Pieranica",
                "countryCode": "IT"
            },
            {
                "name": "Pietra de' Giorgi",
                "countryCode": "IT"
            },
            {
                "name": "Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Albignola",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Emanuele",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Fissiraga",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Porto Morone",
                "countryCode": "IT"
            },
            {
                "name": "Pieve San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "Pieve d'Olmi",
                "countryCode": "IT"
            },
            {
                "name": "Pieve del Cairo",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Coriano",
                "countryCode": "IT"
            },
            {
                "name": "Pievedizio",
                "countryCode": "IT"
            },
            {
                "name": "Pigra",
                "countryCode": "IT"
            },
            {
                "name": "Pinarolo Po",
                "countryCode": "IT"
            },
            {
                "name": "Pino sulla Sponda del Lago Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Pioltello",
                "countryCode": "IT"
            },
            {
                "name": "Pisogne",
                "countryCode": "IT"
            },
            {
                "name": "Piubega",
                "countryCode": "IT"
            },
            {
                "name": "Piuro",
                "countryCode": "IT"
            },
            {
                "name": "Pizzale",
                "countryCode": "IT"
            },
            {
                "name": "Pizzighettone",
                "countryCode": "IT"
            },
            {
                "name": "Plesio",
                "countryCode": "IT"
            },
            {
                "name": "Poasco-Sorigherio",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Rusco",
                "countryCode": "IT"
            },
            {
                "name": "Poggiridenti Alto",
                "countryCode": "IT"
            },
            {
                "name": "Poggiridenti Piano",
                "countryCode": "IT"
            },
            {
                "name": "Pogliano Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Pognana Lario",
                "countryCode": "IT"
            },
            {
                "name": "Pognano",
                "countryCode": "IT"
            },
            {
                "name": "Polaveno",
                "countryCode": "IT"
            },
            {
                "name": "Polpenazze del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Pometo",
                "countryCode": "IT"
            },
            {
                "name": "Pompiano",
                "countryCode": "IT"
            },
            {
                "name": "Pomponesco",
                "countryCode": "IT"
            },
            {
                "name": "Poncarale",
                "countryCode": "IT"
            },
            {
                "name": "Ponna Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Caffaro",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Cingoli",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Lambro",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Nizza",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Nossa",
                "countryCode": "IT"
            },
            {
                "name": "Ponte San Marco",
                "countryCode": "IT"
            },
            {
                "name": "Ponte San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Ponte di Legno",
                "countryCode": "IT"
            },
            {
                "name": "Ponte in Valtellina",
                "countryCode": "IT"
            },
            {
                "name": "Ponteranica",
                "countryCode": "IT"
            },
            {
                "name": "Pontevico",
                "countryCode": "IT"
            },
            {
                "name": "Ponti sul Mincio",
                "countryCode": "IT"
            },
            {
                "name": "Pontida",
                "countryCode": "IT"
            },
            {
                "name": "Pontirolo Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Pontoglio",
                "countryCode": "IT"
            },
            {
                "name": "Porlezza",
                "countryCode": "IT"
            },
            {
                "name": "Portalbera",
                "countryCode": "IT"
            },
            {
                "name": "Porto Ceresio",
                "countryCode": "IT"
            },
            {
                "name": "Porto Valtravaglia",
                "countryCode": "IT"
            },
            {
                "name": "Porto d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Postalesio",
                "countryCode": "IT"
            },
            {
                "name": "Pozzaglio",
                "countryCode": "IT"
            },
            {
                "name": "Pozzaglio ed Uniti",
                "countryCode": "IT"
            },
            {
                "name": "Pozzo d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Pozzolengo",
                "countryCode": "IT"
            },
            {
                "name": "Pozzuolo Martesana",
                "countryCode": "IT"
            },
            {
                "name": "Pradalunga",
                "countryCode": "IT"
            },
            {
                "name": "Pralboino",
                "countryCode": "IT"
            },
            {
                "name": "Prata Camportaccio",
                "countryCode": "IT"
            },
            {
                "name": "Prata Centro",
                "countryCode": "IT"
            },
            {
                "name": "Predore",
                "countryCode": "IT"
            },
            {
                "name": "Pregnana Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Pregola",
                "countryCode": "IT"
            },
            {
                "name": "Premana",
                "countryCode": "IT"
            },
            {
                "name": "Premenugo",
                "countryCode": "IT"
            },
            {
                "name": "Premolo",
                "countryCode": "IT"
            },
            {
                "name": "Preseglie",
                "countryCode": "IT"
            },
            {
                "name": "Presezzo",
                "countryCode": "IT"
            },
            {
                "name": "Prevalle",
                "countryCode": "IT"
            },
            {
                "name": "Primaluna",
                "countryCode": "IT"
            },
            {
                "name": "Proserpio",
                "countryCode": "IT"
            },
            {
                "name": "Prosto",
                "countryCode": "IT"
            },
            {
                "name": "Provaglio Val Sabbia",
                "countryCode": "IT"
            },
            {
                "name": "Provaglio d'Iseo",
                "countryCode": "IT"
            },
            {
                "name": "Provezze",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Bergamo",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Brescia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Como",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Cremona",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Lecco",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Lodi",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Mantova",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Monza e della Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Pavia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Sondrio",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Varese",
                "countryCode": "IT"
            },
            {
                "name": "Puegnago sul Garda",
                "countryCode": "IT"
            },
            {
                "name": "Puginate",
                "countryCode": "IT"
            },
            {
                "name": "Pumenengo",
                "countryCode": "IT"
            },
            {
                "name": "Pusiano",
                "countryCode": "IT"
            },
            {
                "name": "Quartiano",
                "countryCode": "IT"
            },
            {
                "name": "Quattro Strade",
                "countryCode": "IT"
            },
            {
                "name": "Quingentole",
                "countryCode": "IT"
            },
            {
                "name": "Quintano",
                "countryCode": "IT"
            },
            {
                "name": "Quinzano d'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Quistello",
                "countryCode": "IT"
            },
            {
                "name": "Raffa",
                "countryCode": "IT"
            },
            {
                "name": "Ramponio Verna",
                "countryCode": "IT"
            },
            {
                "name": "Rancio Valcuvia",
                "countryCode": "IT"
            },
            {
                "name": "Ranco",
                "countryCode": "IT"
            },
            {
                "name": "Ranica",
                "countryCode": "IT"
            },
            {
                "name": "Ranzanico",
                "countryCode": "IT"
            },
            {
                "name": "Rasura",
                "countryCode": "IT"
            },
            {
                "name": "Rea",
                "countryCode": "IT"
            },
            {
                "name": "Redavalle",
                "countryCode": "IT"
            },
            {
                "name": "Redondesco",
                "countryCode": "IT"
            },
            {
                "name": "Regoledo",
                "countryCode": "IT"
            },
            {
                "name": "Remedello",
                "countryCode": "IT"
            },
            {
                "name": "Remedello di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Renate",
                "countryCode": "IT"
            },
            {
                "name": "Rescaldina",
                "countryCode": "IT"
            },
            {
                "name": "Retorbido",
                "countryCode": "IT"
            },
            {
                "name": "Revere",
                "countryCode": "IT"
            },
            {
                "name": "Rezzago",
                "countryCode": "IT"
            },
            {
                "name": "Rezzato",
                "countryCode": "IT"
            },
            {
                "name": "Rho",
                "countryCode": "IT"
            },
            {
                "name": "Ricengo",
                "countryCode": "IT"
            },
            {
                "name": "Riozzo",
                "countryCode": "IT"
            },
            {
                "name": "Ripalta Arpina",
                "countryCode": "IT"
            },
            {
                "name": "Ripalta Guerina",
                "countryCode": "IT"
            },
            {
                "name": "Ripalta Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Riva di Solto",
                "countryCode": "IT"
            },
            {
                "name": "Rivanazzano",
                "countryCode": "IT"
            },
            {
                "name": "Rivarolo Mantovano",
                "countryCode": "IT"
            },
            {
                "name": "Rivarolo del Re",
                "countryCode": "IT"
            },
            {
                "name": "Rivolta d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Robbiate",
                "countryCode": "IT"
            },
            {
                "name": "Robbio",
                "countryCode": "IT"
            },
            {
                "name": "Robecchetto Con Induno",
                "countryCode": "IT"
            },
            {
                "name": "Robecco Pavese",
                "countryCode": "IT"
            },
            {
                "name": "Robecco d'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Robecco sul Naviglio",
                "countryCode": "IT"
            },
            {
                "name": "Roccafranca",
                "countryCode": "IT"
            },
            {
                "name": "Rodengo-Saiano",
                "countryCode": "IT"
            },
            {
                "name": "Rodero",
                "countryCode": "IT"
            },
            {
                "name": "Rodigo",
                "countryCode": "IT"
            },
            {
                "name": "Rogeno",
                "countryCode": "IT"
            },
            {
                "name": "Roggione",
                "countryCode": "IT"
            },
            {
                "name": "Rognano",
                "countryCode": "IT"
            },
            {
                "name": "Rogno",
                "countryCode": "IT"
            },
            {
                "name": "Rogolo",
                "countryCode": "IT"
            },
            {
                "name": "Rogoredo-Valaperta-Rimoldo",
                "countryCode": "IT"
            },
            {
                "name": "Romagnese",
                "countryCode": "IT"
            },
            {
                "name": "Romanengo",
                "countryCode": "IT"
            },
            {
                "name": "Romano Banco",
                "countryCode": "IT"
            },
            {
                "name": "Romano di Lombardia",
                "countryCode": "IT"
            },
            {
                "name": "Ronago",
                "countryCode": "IT"
            },
            {
                "name": "Roncadelle",
                "countryCode": "IT"
            },
            {
                "name": "Roncaro",
                "countryCode": "IT"
            },
            {
                "name": "Roncello",
                "countryCode": "IT"
            },
            {
                "name": "Ronco",
                "countryCode": "IT"
            },
            {
                "name": "Ronco Briantino",
                "countryCode": "IT"
            },
            {
                "name": "Roncobello",
                "countryCode": "IT"
            },
            {
                "name": "Roncoferraro",
                "countryCode": "IT"
            },
            {
                "name": "Roncola",
                "countryCode": "IT"
            },
            {
                "name": "Rosasco",
                "countryCode": "IT"
            },
            {
                "name": "Rosate",
                "countryCode": "IT"
            },
            {
                "name": "Rota d'Imagna",
                "countryCode": "IT"
            },
            {
                "name": "Rotta",
                "countryCode": "IT"
            },
            {
                "name": "Rovagnate",
                "countryCode": "IT"
            },
            {
                "name": "Rovato",
                "countryCode": "IT"
            },
            {
                "name": "Rovellasca",
                "countryCode": "IT"
            },
            {
                "name": "Rovello Porro",
                "countryCode": "IT"
            },
            {
                "name": "Roverbella",
                "countryCode": "IT"
            },
            {
                "name": "Rovescala",
                "countryCode": "IT"
            },
            {
                "name": "Rovetta",
                "countryCode": "IT"
            },
            {
                "name": "Rozzano",
                "countryCode": "IT"
            },
            {
                "name": "Roè",
                "countryCode": "IT"
            },
            {
                "name": "Roè Volciano",
                "countryCode": "IT"
            },
            {
                "name": "Rudiano",
                "countryCode": "IT"
            },
            {
                "name": "Ruino",
                "countryCode": "IT"
            },
            {
                "name": "Sabbio Chiese",
                "countryCode": "IT"
            },
            {
                "name": "Sabbioneta",
                "countryCode": "IT"
            },
            {
                "name": "Sacca",
                "countryCode": "IT"
            },
            {
                "name": "Sairano",
                "countryCode": "IT"
            },
            {
                "name": "Sala Comacina",
                "countryCode": "IT"
            },
            {
                "name": "Sala al Barro",
                "countryCode": "IT"
            },
            {
                "name": "Sale Marasino",
                "countryCode": "IT"
            },
            {
                "name": "Salerano sul Lambro",
                "countryCode": "IT"
            },
            {
                "name": "Salice Terme",
                "countryCode": "IT"
            },
            {
                "name": "Saltrio",
                "countryCode": "IT"
            },
            {
                "name": "Salvirola",
                "countryCode": "IT"
            },
            {
                "name": "Salò",
                "countryCode": "IT"
            },
            {
                "name": "Samarate",
                "countryCode": "IT"
            },
            {
                "name": "Samolaco",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo Val Cavargna",
                "countryCode": "IT"
            },
            {
                "name": "San Bassano",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto Po",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio",
                "countryCode": "IT"
            },
            {
                "name": "San Bovio-San Felice",
                "countryCode": "IT"
            },
            {
                "name": "San Cassiano",
                "countryCode": "IT"
            },
            {
                "name": "San Cipriano Po",
                "countryCode": "IT"
            },
            {
                "name": "San Colombano al Lambro",
                "countryCode": "IT"
            },
            {
                "name": "San Damiano al Colle",
                "countryCode": "IT"
            },
            {
                "name": "San Daniele Po",
                "countryCode": "IT"
            },
            {
                "name": "San Donato Milanese",
                "countryCode": "IT"
            },
            {
                "name": "San Fedele Intelvi",
                "countryCode": "IT"
            },
            {
                "name": "San Fedele Superiore",
                "countryCode": "IT"
            },
            {
                "name": "San Felice",
                "countryCode": "IT"
            },
            {
                "name": "San Felice del Benaco",
                "countryCode": "IT"
            },
            {
                "name": "San Fermo della Battaglia",
                "countryCode": "IT"
            },
            {
                "name": "San Fiorano",
                "countryCode": "IT"
            },
            {
                "name": "San Genesio ed Uniti",
                "countryCode": "IT"
            },
            {
                "name": "San Gervasio Bresciano",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo Filippo",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo delle Segnate",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Su Legnano",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio di Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Bianco",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni del Dosso",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni in Croce",
                "countryCode": "IT"
            },
            {
                "name": "San Giuliano Milanese",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo di Rovetta",
                "countryCode": "IT"
            },
            {
                "name": "San Mamete",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Dall'Argine",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Siccomario",
                "countryCode": "IT"
            },
            {
                "name": "San Martino del Lago",
                "countryCode": "IT"
            },
            {
                "name": "San Martino della Battaglia",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Strada",
                "countryCode": "IT"
            },
            {
                "name": "San Michele-San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "San Nazzaro Val Cavargna",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo d'Argon",
                "countryCode": "IT"
            },
            {
                "name": "San Pellegrino Terme",
                "countryCode": "IT"
            },
            {
                "name": "San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "San Rocco al Porto",
                "countryCode": "IT"
            },
            {
                "name": "San Sebastiano",
                "countryCode": "IT"
            },
            {
                "name": "San Siro",
                "countryCode": "IT"
            },
            {
                "name": "San Vigilio",
                "countryCode": "IT"
            },
            {
                "name": "San Vittore",
                "countryCode": "IT"
            },
            {
                "name": "San Vittore Olona",
                "countryCode": "IT"
            },
            {
                "name": "San Zeno Naviglio",
                "countryCode": "IT"
            },
            {
                "name": "San Zenone",
                "countryCode": "IT"
            },
            {
                "name": "San Zenone al Lambro",
                "countryCode": "IT"
            },
            {
                "name": "San Zenone al Po",
                "countryCode": "IT"
            },
            {
                "name": "Sangiano",
                "countryCode": "IT"
            },
            {
                "name": "Sannazzaro de' Burgondi",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata Martesana",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alessio Con Vialone",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Omobono Terme",
                "countryCode": "IT"
            },
            {
                "name": "Santa Brigida",
                "countryCode": "IT"
            },
            {
                "name": "Santa Corinna",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cristina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Giuletta",
                "countryCode": "IT"
            },
            {
                "name": "Santa Margherita",
                "countryCode": "IT"
            },
            {
                "name": "Santa Margherita di Staffora",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Hoè",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria dei Sabbioni",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria della Versa",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Sarezzo",
                "countryCode": "IT"
            },
            {
                "name": "Sarnico",
                "countryCode": "IT"
            },
            {
                "name": "Saronno",
                "countryCode": "IT"
            },
            {
                "name": "Sartirana Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Saviore",
                "countryCode": "IT"
            },
            {
                "name": "Scaldasole",
                "countryCode": "IT"
            },
            {
                "name": "Scandolara Ravara",
                "countryCode": "IT"
            },
            {
                "name": "Scandolara Ripa d'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Scannabue-Cascine Capri",
                "countryCode": "IT"
            },
            {
                "name": "Scano al Brembo",
                "countryCode": "IT"
            },
            {
                "name": "Scanzorosciate",
                "countryCode": "IT"
            },
            {
                "name": "Schignano",
                "countryCode": "IT"
            },
            {
                "name": "Schilpario",
                "countryCode": "IT"
            },
            {
                "name": "Schivenoglia",
                "countryCode": "IT"
            },
            {
                "name": "Secugnago",
                "countryCode": "IT"
            },
            {
                "name": "Sedriano",
                "countryCode": "IT"
            },
            {
                "name": "Sedrina",
                "countryCode": "IT"
            },
            {
                "name": "Segrate",
                "countryCode": "IT"
            },
            {
                "name": "Selino Basso",
                "countryCode": "IT"
            },
            {
                "name": "Sellero",
                "countryCode": "IT"
            },
            {
                "name": "Selvino",
                "countryCode": "IT"
            },
            {
                "name": "Semiana",
                "countryCode": "IT"
            },
            {
                "name": "Senago",
                "countryCode": "IT"
            },
            {
                "name": "Seniga",
                "countryCode": "IT"
            },
            {
                "name": "Senna Comasco",
                "countryCode": "IT"
            },
            {
                "name": "Senna Lodigiana",
                "countryCode": "IT"
            },
            {
                "name": "Seregno",
                "countryCode": "IT"
            },
            {
                "name": "Sergnano",
                "countryCode": "IT"
            },
            {
                "name": "Seriate",
                "countryCode": "IT"
            },
            {
                "name": "Serina",
                "countryCode": "IT"
            },
            {
                "name": "Serle",
                "countryCode": "IT"
            },
            {
                "name": "Sermide",
                "countryCode": "IT"
            },
            {
                "name": "Sernio",
                "countryCode": "IT"
            },
            {
                "name": "Serone",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle a Po",
                "countryCode": "IT"
            },
            {
                "name": "Sesto Calende",
                "countryCode": "IT"
            },
            {
                "name": "Sesto San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Sesto ed Uniti",
                "countryCode": "IT"
            },
            {
                "name": "Settala",
                "countryCode": "IT"
            },
            {
                "name": "Settimo Milanese",
                "countryCode": "IT"
            },
            {
                "name": "Seveso",
                "countryCode": "IT"
            },
            {
                "name": "Silvano Pietra",
                "countryCode": "IT"
            },
            {
                "name": "Sirmione",
                "countryCode": "IT"
            },
            {
                "name": "Sirone",
                "countryCode": "IT"
            },
            {
                "name": "Sirta",
                "countryCode": "IT"
            },
            {
                "name": "Sirtori",
                "countryCode": "IT"
            },
            {
                "name": "Siviano",
                "countryCode": "IT"
            },
            {
                "name": "Siziano",
                "countryCode": "IT"
            },
            {
                "name": "Soave",
                "countryCode": "IT"
            },
            {
                "name": "Soiano",
                "countryCode": "IT"
            },
            {
                "name": "Solaro",
                "countryCode": "IT"
            },
            {
                "name": "Solarolo",
                "countryCode": "IT"
            },
            {
                "name": "Solarolo Rainerio",
                "countryCode": "IT"
            },
            {
                "name": "Solbiate",
                "countryCode": "IT"
            },
            {
                "name": "Solbiate Arno",
                "countryCode": "IT"
            },
            {
                "name": "Solbiate Olona",
                "countryCode": "IT"
            },
            {
                "name": "Solferino",
                "countryCode": "IT"
            },
            {
                "name": "Solto Collina",
                "countryCode": "IT"
            },
            {
                "name": "Solza",
                "countryCode": "IT"
            },
            {
                "name": "Somaglia",
                "countryCode": "IT"
            },
            {
                "name": "Somma Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Sommo",
                "countryCode": "IT"
            },
            {
                "name": "Soncino",
                "countryCode": "IT"
            },
            {
                "name": "Sondalo",
                "countryCode": "IT"
            },
            {
                "name": "Sondrio",
                "countryCode": "IT"
            },
            {
                "name": "Songavazzo",
                "countryCode": "IT"
            },
            {
                "name": "Sonico",
                "countryCode": "IT"
            },
            {
                "name": "Sordio",
                "countryCode": "IT"
            },
            {
                "name": "Soresina",
                "countryCode": "IT"
            },
            {
                "name": "Sorico",
                "countryCode": "IT"
            },
            {
                "name": "Sorisole",
                "countryCode": "IT"
            },
            {
                "name": "Sormano",
                "countryCode": "IT"
            },
            {
                "name": "Sospiro",
                "countryCode": "IT"
            },
            {
                "name": "Sotto il Monte Giovanni XXIII",
                "countryCode": "IT"
            },
            {
                "name": "Sottocastello",
                "countryCode": "IT"
            },
            {
                "name": "Sovere",
                "countryCode": "IT"
            },
            {
                "name": "Sovico",
                "countryCode": "IT"
            },
            {
                "name": "Spessa",
                "countryCode": "IT"
            },
            {
                "name": "Spinadesco",
                "countryCode": "IT"
            },
            {
                "name": "Spineda",
                "countryCode": "IT"
            },
            {
                "name": "Spino d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Spinone al Lago",
                "countryCode": "IT"
            },
            {
                "name": "Spirano",
                "countryCode": "IT"
            },
            {
                "name": "Spriana",
                "countryCode": "IT"
            },
            {
                "name": "Stagno Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Stazzona",
                "countryCode": "IT"
            },
            {
                "name": "Stezzano",
                "countryCode": "IT"
            },
            {
                "name": "Stradella",
                "countryCode": "IT"
            },
            {
                "name": "Stravignino",
                "countryCode": "IT"
            },
            {
                "name": "Strozza",
                "countryCode": "IT"
            },
            {
                "name": "Suardi",
                "countryCode": "IT"
            },
            {
                "name": "Sueglio",
                "countryCode": "IT"
            },
            {
                "name": "Suello",
                "countryCode": "IT"
            },
            {
                "name": "Suisio",
                "countryCode": "IT"
            },
            {
                "name": "Sulbiate",
                "countryCode": "IT"
            },
            {
                "name": "Sulzano",
                "countryCode": "IT"
            },
            {
                "name": "Sumirago",
                "countryCode": "IT"
            },
            {
                "name": "Susella",
                "countryCode": "IT"
            },
            {
                "name": "Sustinente",
                "countryCode": "IT"
            },
            {
                "name": "Suzzara",
                "countryCode": "IT"
            },
            {
                "name": "Tabiago-Cibrone",
                "countryCode": "IT"
            },
            {
                "name": "Taceno",
                "countryCode": "IT"
            },
            {
                "name": "Taino",
                "countryCode": "IT"
            },
            {
                "name": "Talamona",
                "countryCode": "IT"
            },
            {
                "name": "Taleggio",
                "countryCode": "IT"
            },
            {
                "name": "Tartano",
                "countryCode": "IT"
            },
            {
                "name": "Tavazzano",
                "countryCode": "IT"
            },
            {
                "name": "Tavernerio",
                "countryCode": "IT"
            },
            {
                "name": "Tavernola Bergamasca",
                "countryCode": "IT"
            },
            {
                "name": "Tavernole",
                "countryCode": "IT"
            },
            {
                "name": "Teglio",
                "countryCode": "IT"
            },
            {
                "name": "Telgate",
                "countryCode": "IT"
            },
            {
                "name": "Temù",
                "countryCode": "IT"
            },
            {
                "name": "Ternate",
                "countryCode": "IT"
            },
            {
                "name": "Terno d'Isola",
                "countryCode": "IT"
            },
            {
                "name": "Terranova dei Passerini",
                "countryCode": "IT"
            },
            {
                "name": "Terraverde-Corte Palasio",
                "countryCode": "IT"
            },
            {
                "name": "Ticengo",
                "countryCode": "IT"
            },
            {
                "name": "Tirano",
                "countryCode": "IT"
            },
            {
                "name": "Torbole Casaglia",
                "countryCode": "IT"
            },
            {
                "name": "Torchione-Moia",
                "countryCode": "IT"
            },
            {
                "name": "Torlino Vimercati",
                "countryCode": "IT"
            },
            {
                "name": "Tornata",
                "countryCode": "IT"
            },
            {
                "name": "Torno",
                "countryCode": "IT"
            },
            {
                "name": "Torrazza Coste",
                "countryCode": "IT"
            },
            {
                "name": "Torrazza dei Mandelli",
                "countryCode": "IT"
            },
            {
                "name": "Torre Beretti",
                "countryCode": "IT"
            },
            {
                "name": "Torre Boldone",
                "countryCode": "IT"
            },
            {
                "name": "Torre Pallavicina",
                "countryCode": "IT"
            },
            {
                "name": "Torre d'Arese",
                "countryCode": "IT"
            },
            {
                "name": "Torre d'Isola",
                "countryCode": "IT"
            },
            {
                "name": "Torre de' Busi",
                "countryCode": "IT"
            },
            {
                "name": "Torre de' Negri",
                "countryCode": "IT"
            },
            {
                "name": "Torre de' Picenardi",
                "countryCode": "IT"
            },
            {
                "name": "Torre de' Roveri",
                "countryCode": "IT"
            },
            {
                "name": "Torre del Mangano",
                "countryCode": "IT"
            },
            {
                "name": "Torre di Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Torrevecchia Pia",
                "countryCode": "IT"
            },
            {
                "name": "Torricella Verzate",
                "countryCode": "IT"
            },
            {
                "name": "Torricella del Pizzo",
                "countryCode": "IT"
            },
            {
                "name": "Toscolano Maderno",
                "countryCode": "IT"
            },
            {
                "name": "Tovo di Sant'Agata",
                "countryCode": "IT"
            },
            {
                "name": "Tradate",
                "countryCode": "IT"
            },
            {
                "name": "Traona",
                "countryCode": "IT"
            },
            {
                "name": "Travacò Siccomario",
                "countryCode": "IT"
            },
            {
                "name": "Travagliato",
                "countryCode": "IT"
            },
            {
                "name": "Travedona Monate",
                "countryCode": "IT"
            },
            {
                "name": "Trebbio",
                "countryCode": "IT"
            },
            {
                "name": "Trecella",
                "countryCode": "IT"
            },
            {
                "name": "Tregasio",
                "countryCode": "IT"
            },
            {
                "name": "Tremenico",
                "countryCode": "IT"
            },
            {
                "name": "Tremezzo",
                "countryCode": "IT"
            },
            {
                "name": "Trenzano",
                "countryCode": "IT"
            },
            {
                "name": "Trescore Balneario",
                "countryCode": "IT"
            },
            {
                "name": "Trescore Cremasco",
                "countryCode": "IT"
            },
            {
                "name": "Tresivio",
                "countryCode": "IT"
            },
            {
                "name": "Treviglio",
                "countryCode": "IT"
            },
            {
                "name": "Treviolo",
                "countryCode": "IT"
            },
            {
                "name": "Trezzano Rosa",
                "countryCode": "IT"
            },
            {
                "name": "Trezzano sul Naviglio",
                "countryCode": "IT"
            },
            {
                "name": "Trezzo sull'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Trezzone",
                "countryCode": "IT"
            },
            {
                "name": "Tribiano",
                "countryCode": "IT"
            },
            {
                "name": "Triginto",
                "countryCode": "IT"
            },
            {
                "name": "Trigolo",
                "countryCode": "IT"
            },
            {
                "name": "Triuggio",
                "countryCode": "IT"
            },
            {
                "name": "Trivolzio",
                "countryCode": "IT"
            },
            {
                "name": "Tromello",
                "countryCode": "IT"
            },
            {
                "name": "Tronzano Lago Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Trovo",
                "countryCode": "IT"
            },
            {
                "name": "Truccazzano",
                "countryCode": "IT"
            },
            {
                "name": "Turano Lodigiano",
                "countryCode": "IT"
            },
            {
                "name": "Turate",
                "countryCode": "IT"
            },
            {
                "name": "Turbigo",
                "countryCode": "IT"
            },
            {
                "name": "Ubiale",
                "countryCode": "IT"
            },
            {
                "name": "Ubiale Clanezzo",
                "countryCode": "IT"
            },
            {
                "name": "Uboldo",
                "countryCode": "IT"
            },
            {
                "name": "Uggiate Trevano",
                "countryCode": "IT"
            },
            {
                "name": "Urago d'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Urgnano",
                "countryCode": "IT"
            },
            {
                "name": "Usmate-Velate",
                "countryCode": "IT"
            },
            {
                "name": "Vaiano Cremasco",
                "countryCode": "IT"
            },
            {
                "name": "Vailate",
                "countryCode": "IT"
            },
            {
                "name": "Val Masino",
                "countryCode": "IT"
            },
            {
                "name": "Val Rezzo",
                "countryCode": "IT"
            },
            {
                "name": "Valbrona",
                "countryCode": "IT"
            },
            {
                "name": "Valdisotto",
                "countryCode": "IT"
            },
            {
                "name": "Valeggio",
                "countryCode": "IT"
            },
            {
                "name": "Valera Fratta",
                "countryCode": "IT"
            },
            {
                "name": "Valganna",
                "countryCode": "IT"
            },
            {
                "name": "Valgoglio",
                "countryCode": "IT"
            },
            {
                "name": "Valgreghentino",
                "countryCode": "IT"
            },
            {
                "name": "Vall'Alta",
                "countryCode": "IT"
            },
            {
                "name": "Valle Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Valle Salimbene",
                "countryCode": "IT"
            },
            {
                "name": "Valleve",
                "countryCode": "IT"
            },
            {
                "name": "Vallio Terme",
                "countryCode": "IT"
            },
            {
                "name": "Valmadrera-Caserta",
                "countryCode": "IT"
            },
            {
                "name": "Valmorea",
                "countryCode": "IT"
            },
            {
                "name": "Valnegra",
                "countryCode": "IT"
            },
            {
                "name": "Valtorta",
                "countryCode": "IT"
            },
            {
                "name": "Valvestino",
                "countryCode": "IT"
            },
            {
                "name": "Vanzaghello",
                "countryCode": "IT"
            },
            {
                "name": "Vanzago",
                "countryCode": "IT"
            },
            {
                "name": "Vaprio d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Varano Borghi",
                "countryCode": "IT"
            },
            {
                "name": "Varedo",
                "countryCode": "IT"
            },
            {
                "name": "Varenna",
                "countryCode": "IT"
            },
            {
                "name": "Varese",
                "countryCode": "IT"
            },
            {
                "name": "Varzi",
                "countryCode": "IT"
            },
            {
                "name": "Vassena",
                "countryCode": "IT"
            },
            {
                "name": "Vedano Olona",
                "countryCode": "IT"
            },
            {
                "name": "Vedano al Lambro",
                "countryCode": "IT"
            },
            {
                "name": "Veddasca",
                "countryCode": "IT"
            },
            {
                "name": "Vedeseta",
                "countryCode": "IT"
            },
            {
                "name": "Veduggio Con Colzano",
                "countryCode": "IT"
            },
            {
                "name": "Velasca",
                "countryCode": "IT"
            },
            {
                "name": "Veleso",
                "countryCode": "IT"
            },
            {
                "name": "Velezzo Lomellina",
                "countryCode": "IT"
            },
            {
                "name": "Vellezzo Bellini",
                "countryCode": "IT"
            },
            {
                "name": "Vendrogno",
                "countryCode": "IT"
            },
            {
                "name": "Venegono Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Venegono Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Veniano",
                "countryCode": "IT"
            },
            {
                "name": "Verano Brianza",
                "countryCode": "IT"
            },
            {
                "name": "Vercana",
                "countryCode": "IT"
            },
            {
                "name": "Verceia",
                "countryCode": "IT"
            },
            {
                "name": "Vercurago",
                "countryCode": "IT"
            },
            {
                "name": "Verdellino",
                "countryCode": "IT"
            },
            {
                "name": "Verdello",
                "countryCode": "IT"
            },
            {
                "name": "Verderio Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Verderio Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Vergano-Villa",
                "countryCode": "IT"
            },
            {
                "name": "Vergiate",
                "countryCode": "IT"
            },
            {
                "name": "Vermezzo",
                "countryCode": "IT"
            },
            {
                "name": "Vernate",
                "countryCode": "IT"
            },
            {
                "name": "Verolanuova",
                "countryCode": "IT"
            },
            {
                "name": "Verolavecchia",
                "countryCode": "IT"
            },
            {
                "name": "Verretto",
                "countryCode": "IT"
            },
            {
                "name": "Verrua Po",
                "countryCode": "IT"
            },
            {
                "name": "Vertemate Con Minoprio",
                "countryCode": "IT"
            },
            {
                "name": "Vertova",
                "countryCode": "IT"
            },
            {
                "name": "Vervio",
                "countryCode": "IT"
            },
            {
                "name": "Vescovato",
                "countryCode": "IT"
            },
            {
                "name": "Vestone",
                "countryCode": "IT"
            },
            {
                "name": "Vestreno",
                "countryCode": "IT"
            },
            {
                "name": "Vezza d'Oglio",
                "countryCode": "IT"
            },
            {
                "name": "Viadana",
                "countryCode": "IT"
            },
            {
                "name": "Viadanica",
                "countryCode": "IT"
            },
            {
                "name": "Vicomoscano",
                "countryCode": "IT"
            },
            {
                "name": "Vidalengo",
                "countryCode": "IT"
            },
            {
                "name": "Vidardo",
                "countryCode": "IT"
            },
            {
                "name": "Vidigulfo",
                "countryCode": "IT"
            },
            {
                "name": "Vigano San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Viganò",
                "countryCode": "IT"
            },
            {
                "name": "Vigevano",
                "countryCode": "IT"
            },
            {
                "name": "Viggiù",
                "countryCode": "IT"
            },
            {
                "name": "Vighignolo",
                "countryCode": "IT"
            },
            {
                "name": "Vighizzolo",
                "countryCode": "IT"
            },
            {
                "name": "Vigliano-Bettolino",
                "countryCode": "IT"
            },
            {
                "name": "Vignate",
                "countryCode": "IT"
            },
            {
                "name": "Vignola",
                "countryCode": "IT"
            },
            {
                "name": "Vigolo",
                "countryCode": "IT"
            },
            {
                "name": "Vigonzone",
                "countryCode": "IT"
            },
            {
                "name": "Vill'Albese",
                "countryCode": "IT"
            },
            {
                "name": "Villa",
                "countryCode": "IT"
            },
            {
                "name": "Villa Biscossi",
                "countryCode": "IT"
            },
            {
                "name": "Villa Carcina",
                "countryCode": "IT"
            },
            {
                "name": "Villa Cortese",
                "countryCode": "IT"
            },
            {
                "name": "Villa Fornace",
                "countryCode": "IT"
            },
            {
                "name": "Villa Guardia",
                "countryCode": "IT"
            },
            {
                "name": "Villa Pedergnano",
                "countryCode": "IT"
            },
            {
                "name": "Villa Poma",
                "countryCode": "IT"
            },
            {
                "name": "Villa Raverio",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Adda",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Almè",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Ogna",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Oneta",
                "countryCode": "IT"
            },
            {
                "name": "Villa di Chiavenna",
                "countryCode": "IT"
            },
            {
                "name": "Villa di Serio",
                "countryCode": "IT"
            },
            {
                "name": "Villachiara",
                "countryCode": "IT"
            },
            {
                "name": "Villaggio Residenziale",
                "countryCode": "IT"
            },
            {
                "name": "Villaggio del Sole",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Villanova d'Ardenghi",
                "countryCode": "IT"
            },
            {
                "name": "Villanova del Sillaro",
                "countryCode": "IT"
            },
            {
                "name": "Villanterio",
                "countryCode": "IT"
            },
            {
                "name": "Villanuova",
                "countryCode": "IT"
            },
            {
                "name": "Villanuova sul Clisi",
                "countryCode": "IT"
            },
            {
                "name": "Villapinta",
                "countryCode": "IT"
            },
            {
                "name": "Villasanta",
                "countryCode": "IT"
            },
            {
                "name": "Villassio",
                "countryCode": "IT"
            },
            {
                "name": "Villimpenta",
                "countryCode": "IT"
            },
            {
                "name": "Villongo",
                "countryCode": "IT"
            },
            {
                "name": "Vilminore",
                "countryCode": "IT"
            },
            {
                "name": "Vilminore di Scalve",
                "countryCode": "IT"
            },
            {
                "name": "Vimercate",
                "countryCode": "IT"
            },
            {
                "name": "Vimodrone",
                "countryCode": "IT"
            },
            {
                "name": "Vione",
                "countryCode": "IT"
            },
            {
                "name": "Visano",
                "countryCode": "IT"
            },
            {
                "name": "Vistarino",
                "countryCode": "IT"
            },
            {
                "name": "Vittuone",
                "countryCode": "IT"
            },
            {
                "name": "Vizzola Ticino",
                "countryCode": "IT"
            },
            {
                "name": "Vizzolo Predabissi",
                "countryCode": "IT"
            },
            {
                "name": "Vobarno",
                "countryCode": "IT"
            },
            {
                "name": "Voghera",
                "countryCode": "IT"
            },
            {
                "name": "Volongo",
                "countryCode": "IT"
            },
            {
                "name": "Volpara",
                "countryCode": "IT"
            },
            {
                "name": "Volta Mantovana",
                "countryCode": "IT"
            },
            {
                "name": "Voltido",
                "countryCode": "IT"
            },
            {
                "name": "Zandobbio",
                "countryCode": "IT"
            },
            {
                "name": "Zanica",
                "countryCode": "IT"
            },
            {
                "name": "Zavattarello",
                "countryCode": "IT"
            },
            {
                "name": "Zeccone",
                "countryCode": "IT"
            },
            {
                "name": "Zelbio",
                "countryCode": "IT"
            },
            {
                "name": "Zelo Buon Persico",
                "countryCode": "IT"
            },
            {
                "name": "Zelo Surrigone",
                "countryCode": "IT"
            },
            {
                "name": "Zeme",
                "countryCode": "IT"
            },
            {
                "name": "Zenevredo",
                "countryCode": "IT"
            },
            {
                "name": "Zerbo",
                "countryCode": "IT"
            },
            {
                "name": "Zerbolò",
                "countryCode": "IT"
            },
            {
                "name": "Zibido San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "Zinasco Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "Zoccorino-Vergo",
                "countryCode": "IT"
            },
            {
                "name": "Zogno",
                "countryCode": "IT"
            },
            {
                "name": "Zone",
                "countryCode": "IT"
            },
            {
                "name": "Zorlesco",
                "countryCode": "IT"
            },
            {
                "name": "Ala",
                "countryCode": "IT"
            },
            {
                "name": "Albiano",
                "countryCode": "IT"
            },
            {
                "name": "Aldeno",
                "countryCode": "IT"
            },
            {
                "name": "Aldino",
                "countryCode": "IT"
            },
            {
                "name": "Amblar",
                "countryCode": "IT"
            },
            {
                "name": "Andalo",
                "countryCode": "IT"
            },
            {
                "name": "Andriano",
                "countryCode": "IT"
            },
            {
                "name": "Anterivo",
                "countryCode": "IT"
            },
            {
                "name": "Arco",
                "countryCode": "IT"
            },
            {
                "name": "Avelengo",
                "countryCode": "IT"
            },
            {
                "name": "Avio",
                "countryCode": "IT"
            },
            {
                "name": "Badia",
                "countryCode": "IT"
            },
            {
                "name": "Barbiano - Barbian",
                "countryCode": "IT"
            },
            {
                "name": "Baselga di Pinè",
                "countryCode": "IT"
            },
            {
                "name": "Bersone",
                "countryCode": "IT"
            },
            {
                "name": "Besenello",
                "countryCode": "IT"
            },
            {
                "name": "Bieno",
                "countryCode": "IT"
            },
            {
                "name": "Bleggio Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Bocenago",
                "countryCode": "IT"
            },
            {
                "name": "Bolbeno",
                "countryCode": "IT"
            },
            {
                "name": "Bolognano-Vignole",
                "countryCode": "IT"
            },
            {
                "name": "Bolzano",
                "countryCode": "IT"
            },
            {
                "name": "Bondo",
                "countryCode": "IT"
            },
            {
                "name": "Bondone",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Valsugana",
                "countryCode": "IT"
            },
            {
                "name": "Bosentino-Migazzone",
                "countryCode": "IT"
            },
            {
                "name": "Braies",
                "countryCode": "IT"
            },
            {
                "name": "Breguzzo",
                "countryCode": "IT"
            },
            {
                "name": "Brennero - Brenner",
                "countryCode": "IT"
            },
            {
                "name": "Brentonico",
                "countryCode": "IT"
            },
            {
                "name": "Bresimo",
                "countryCode": "IT"
            },
            {
                "name": "Bressanone",
                "countryCode": "IT"
            },
            {
                "name": "Brez",
                "countryCode": "IT"
            },
            {
                "name": "Brione",
                "countryCode": "IT"
            },
            {
                "name": "Bronzolo",
                "countryCode": "IT"
            },
            {
                "name": "Brunico",
                "countryCode": "IT"
            },
            {
                "name": "Caderzone Terme",
                "countryCode": "IT"
            },
            {
                "name": "Cadine",
                "countryCode": "IT"
            },
            {
                "name": "Cadipietra",
                "countryCode": "IT"
            },
            {
                "name": "Cagnò",
                "countryCode": "IT"
            },
            {
                "name": "Caines",
                "countryCode": "IT"
            },
            {
                "name": "Caines - Kuens",
                "countryCode": "IT"
            },
            {
                "name": "Calavino",
                "countryCode": "IT"
            },
            {
                "name": "Calceranica al Lago",
                "countryCode": "IT"
            },
            {
                "name": "Caldaro sulla Strada del Vino",
                "countryCode": "IT"
            },
            {
                "name": "Caldes",
                "countryCode": "IT"
            },
            {
                "name": "Caldonazzo",
                "countryCode": "IT"
            },
            {
                "name": "Calliano",
                "countryCode": "IT"
            },
            {
                "name": "Campitello di Fassa",
                "countryCode": "IT"
            },
            {
                "name": "Campo Tures",
                "countryCode": "IT"
            },
            {
                "name": "Campo di Trens",
                "countryCode": "IT"
            },
            {
                "name": "Campodenno",
                "countryCode": "IT"
            },
            {
                "name": "Canal San Bovo",
                "countryCode": "IT"
            },
            {
                "name": "Canazei",
                "countryCode": "IT"
            },
            {
                "name": "Capriana",
                "countryCode": "IT"
            },
            {
                "name": "Carano",
                "countryCode": "IT"
            },
            {
                "name": "Cardano",
                "countryCode": "IT"
            },
            {
                "name": "Carisolo",
                "countryCode": "IT"
            },
            {
                "name": "Carzano",
                "countryCode": "IT"
            },
            {
                "name": "Castel Condino",
                "countryCode": "IT"
            },
            {
                "name": "Castelbello",
                "countryCode": "IT"
            },
            {
                "name": "Castelbello-Ciardes - Kastelbell-Tschars",
                "countryCode": "IT"
            },
            {
                "name": "Castelfondo",
                "countryCode": "IT"
            },
            {
                "name": "Castello Molina di Fiemme",
                "countryCode": "IT"
            },
            {
                "name": "Castello Tesino",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo",
                "countryCode": "IT"
            },
            {
                "name": "Castelrotto",
                "countryCode": "IT"
            },
            {
                "name": "Cavalese",
                "countryCode": "IT"
            },
            {
                "name": "Cavareno",
                "countryCode": "IT"
            },
            {
                "name": "Cavedago",
                "countryCode": "IT"
            },
            {
                "name": "Cavedine",
                "countryCode": "IT"
            },
            {
                "name": "Cavizzana",
                "countryCode": "IT"
            },
            {
                "name": "Cembra",
                "countryCode": "IT"
            },
            {
                "name": "Centa San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "Centrale",
                "countryCode": "IT"
            },
            {
                "name": "Cermes",
                "countryCode": "IT"
            },
            {
                "name": "Certosa",
                "countryCode": "IT"
            },
            {
                "name": "Chienes",
                "countryCode": "IT"
            },
            {
                "name": "Chiusa",
                "countryCode": "IT"
            },
            {
                "name": "Cimego",
                "countryCode": "IT"
            },
            {
                "name": "Cimoneri",
                "countryCode": "IT"
            },
            {
                "name": "Cinte Tesino",
                "countryCode": "IT"
            },
            {
                "name": "Cis",
                "countryCode": "IT"
            },
            {
                "name": "Civezzano",
                "countryCode": "IT"
            },
            {
                "name": "Cles",
                "countryCode": "IT"
            },
            {
                "name": "Cloz",
                "countryCode": "IT"
            },
            {
                "name": "Cogolo",
                "countryCode": "IT"
            },
            {
                "name": "Collalbo",
                "countryCode": "IT"
            },
            {
                "name": "Colle Isarco",
                "countryCode": "IT"
            },
            {
                "name": "Commezzadura",
                "countryCode": "IT"
            },
            {
                "name": "Condino",
                "countryCode": "IT"
            },
            {
                "name": "Coredo",
                "countryCode": "IT"
            },
            {
                "name": "Cornaiano",
                "countryCode": "IT"
            },
            {
                "name": "Cornedo All'Isarco",
                "countryCode": "IT"
            },
            {
                "name": "Cortaccia sulla Strada del Vino",
                "countryCode": "IT"
            },
            {
                "name": "Cortina sulla Strada del Vino",
                "countryCode": "IT"
            },
            {
                "name": "Corvara in Badia",
                "countryCode": "IT"
            },
            {
                "name": "Covelo",
                "countryCode": "IT"
            },
            {
                "name": "Creto",
                "countryCode": "IT"
            },
            {
                "name": "Croviana",
                "countryCode": "IT"
            },
            {
                "name": "Cunevo",
                "countryCode": "IT"
            },
            {
                "name": "Curon Venosta",
                "countryCode": "IT"
            },
            {
                "name": "Daiano",
                "countryCode": "IT"
            },
            {
                "name": "Dambel",
                "countryCode": "IT"
            },
            {
                "name": "Daone",
                "countryCode": "IT"
            },
            {
                "name": "Denno",
                "countryCode": "IT"
            },
            {
                "name": "Dimaro",
                "countryCode": "IT"
            },
            {
                "name": "Dobbiaco",
                "countryCode": "IT"
            },
            {
                "name": "Don",
                "countryCode": "IT"
            },
            {
                "name": "Dorsino",
                "countryCode": "IT"
            },
            {
                "name": "Drena",
                "countryCode": "IT"
            },
            {
                "name": "Dro",
                "countryCode": "IT"
            },
            {
                "name": "Egna",
                "countryCode": "IT"
            },
            {
                "name": "Faedo",
                "countryCode": "IT"
            },
            {
                "name": "Fai della Paganella",
                "countryCode": "IT"
            },
            {
                "name": "Falzes",
                "countryCode": "IT"
            },
            {
                "name": "Faver",
                "countryCode": "IT"
            },
            {
                "name": "Fiavè",
                "countryCode": "IT"
            },
            {
                "name": "Fiera di Primiero",
                "countryCode": "IT"
            },
            {
                "name": "Fierozzo",
                "countryCode": "IT"
            },
            {
                "name": "Fiè Allo Sciliar",
                "countryCode": "IT"
            },
            {
                "name": "Flavon",
                "countryCode": "IT"
            },
            {
                "name": "Folgaria",
                "countryCode": "IT"
            },
            {
                "name": "Fondo",
                "countryCode": "IT"
            },
            {
                "name": "Fontana Nuova-Bevia",
                "countryCode": "IT"
            },
            {
                "name": "Fornace",
                "countryCode": "IT"
            },
            {
                "name": "Fortezza",
                "countryCode": "IT"
            },
            {
                "name": "Frassilongo",
                "countryCode": "IT"
            },
            {
                "name": "Fraviano",
                "countryCode": "IT"
            },
            {
                "name": "Funes - Villnoess",
                "countryCode": "IT"
            },
            {
                "name": "Gais",
                "countryCode": "IT"
            },
            {
                "name": "Ganda",
                "countryCode": "IT"
            },
            {
                "name": "Gargazzone",
                "countryCode": "IT"
            },
            {
                "name": "Garniga Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Gionghi-Cappella",
                "countryCode": "IT"
            },
            {
                "name": "Giovo",
                "countryCode": "IT"
            },
            {
                "name": "Giustino",
                "countryCode": "IT"
            },
            {
                "name": "Glorenza",
                "countryCode": "IT"
            },
            {
                "name": "Grauno",
                "countryCode": "IT"
            },
            {
                "name": "Grigno",
                "countryCode": "IT"
            },
            {
                "name": "Grumes",
                "countryCode": "IT"
            },
            {
                "name": "Imer",
                "countryCode": "IT"
            },
            {
                "name": "Isera",
                "countryCode": "IT"
            },
            {
                "name": "La Valle - Wengen",
                "countryCode": "IT"
            },
            {
                "name": "Laces",
                "countryCode": "IT"
            },
            {
                "name": "Laghetti",
                "countryCode": "IT"
            },
            {
                "name": "Lagundo",
                "countryCode": "IT"
            },
            {
                "name": "Laion",
                "countryCode": "IT"
            },
            {
                "name": "Laives",
                "countryCode": "IT"
            },
            {
                "name": "Lana",
                "countryCode": "IT"
            },
            {
                "name": "Lardaro",
                "countryCode": "IT"
            },
            {
                "name": "Lasa",
                "countryCode": "IT"
            },
            {
                "name": "Lases",
                "countryCode": "IT"
            },
            {
                "name": "Lasino",
                "countryCode": "IT"
            },
            {
                "name": "Lauregno",
                "countryCode": "IT"
            },
            {
                "name": "Lavis",
                "countryCode": "IT"
            },
            {
                "name": "Leone-Santa Elisabetta",
                "countryCode": "IT"
            },
            {
                "name": "Levico Terme",
                "countryCode": "IT"
            },
            {
                "name": "Lisignago",
                "countryCode": "IT"
            },
            {
                "name": "Livo",
                "countryCode": "IT"
            },
            {
                "name": "Lodrone-Darzo",
                "countryCode": "IT"
            },
            {
                "name": "Lona-Lases",
                "countryCode": "IT"
            },
            {
                "name": "Luserna",
                "countryCode": "IT"
            },
            {
                "name": "Luson - Luesen",
                "countryCode": "IT"
            },
            {
                "name": "Madrano-Canzolino",
                "countryCode": "IT"
            },
            {
                "name": "Magrè sulla Strada del Vino",
                "countryCode": "IT"
            },
            {
                "name": "Malles Venosta",
                "countryCode": "IT"
            },
            {
                "name": "Malosco",
                "countryCode": "IT"
            },
            {
                "name": "Malè",
                "countryCode": "IT"
            },
            {
                "name": "Marco",
                "countryCode": "IT"
            },
            {
                "name": "Marlengo",
                "countryCode": "IT"
            },
            {
                "name": "Massimeno",
                "countryCode": "IT"
            },
            {
                "name": "Mattarello",
                "countryCode": "IT"
            },
            {
                "name": "Mazzin",
                "countryCode": "IT"
            },
            {
                "name": "Meano",
                "countryCode": "IT"
            },
            {
                "name": "Meiern",
                "countryCode": "IT"
            },
            {
                "name": "Meltina",
                "countryCode": "IT"
            },
            {
                "name": "Merano",
                "countryCode": "IT"
            },
            {
                "name": "Mezzana",
                "countryCode": "IT"
            },
            {
                "name": "Mezzano",
                "countryCode": "IT"
            },
            {
                "name": "Mezzocorona",
                "countryCode": "IT"
            },
            {
                "name": "Mezzolombardo",
                "countryCode": "IT"
            },
            {
                "name": "Miola di Pinè",
                "countryCode": "IT"
            },
            {
                "name": "Moena",
                "countryCode": "IT"
            },
            {
                "name": "Molina di Ledro",
                "countryCode": "IT"
            },
            {
                "name": "Molveno",
                "countryCode": "IT"
            },
            {
                "name": "Monclassico",
                "countryCode": "IT"
            },
            {
                "name": "Monguelfo",
                "countryCode": "IT"
            },
            {
                "name": "Montagna",
                "countryCode": "IT"
            },
            {
                "name": "Montagne",
                "countryCode": "IT"
            },
            {
                "name": "Mori",
                "countryCode": "IT"
            },
            {
                "name": "Moscheri",
                "countryCode": "IT"
            },
            {
                "name": "Moso in Passiria",
                "countryCode": "IT"
            },
            {
                "name": "Mühlen in Taufers",
                "countryCode": "IT"
            },
            {
                "name": "Nago-Torbole",
                "countryCode": "IT"
            },
            {
                "name": "Nalles",
                "countryCode": "IT"
            },
            {
                "name": "Nanno",
                "countryCode": "IT"
            },
            {
                "name": "Naturno",
                "countryCode": "IT"
            },
            {
                "name": "Nave San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "Naz-Sciaves - Natz-Schabs",
                "countryCode": "IT"
            },
            {
                "name": "Nogaredo",
                "countryCode": "IT"
            },
            {
                "name": "Nomi",
                "countryCode": "IT"
            },
            {
                "name": "Nova Levante",
                "countryCode": "IT"
            },
            {
                "name": "Nova Ponente",
                "countryCode": "IT"
            },
            {
                "name": "Novaledo",
                "countryCode": "IT"
            },
            {
                "name": "Ora",
                "countryCode": "IT"
            },
            {
                "name": "Ortisei",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto",
                "countryCode": "IT"
            },
            {
                "name": "Ossana",
                "countryCode": "IT"
            },
            {
                "name": "Padergnone",
                "countryCode": "IT"
            },
            {
                "name": "Palù del Fersina",
                "countryCode": "IT"
            },
            {
                "name": "Panchià",
                "countryCode": "IT"
            },
            {
                "name": "Parcines",
                "countryCode": "IT"
            },
            {
                "name": "Pellizzano",
                "countryCode": "IT"
            },
            {
                "name": "Pelugo",
                "countryCode": "IT"
            },
            {
                "name": "Perca",
                "countryCode": "IT"
            },
            {
                "name": "Pergine Valsugana",
                "countryCode": "IT"
            },
            {
                "name": "Piazza",
                "countryCode": "IT"
            },
            {
                "name": "Pietramurata",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Tesino",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Bono",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Ledro",
                "countryCode": "IT"
            },
            {
                "name": "Pineta",
                "countryCode": "IT"
            },
            {
                "name": "Pinzolo",
                "countryCode": "IT"
            },
            {
                "name": "Plaus",
                "countryCode": "IT"
            },
            {
                "name": "Pomarolo",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Arche",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Gardena",
                "countryCode": "IT"
            },
            {
                "name": "Postal",
                "countryCode": "IT"
            },
            {
                "name": "Povo",
                "countryCode": "IT"
            },
            {
                "name": "Pozza di Fassa",
                "countryCode": "IT"
            },
            {
                "name": "Praso",
                "countryCode": "IT"
            },
            {
                "name": "Prati",
                "countryCode": "IT"
            },
            {
                "name": "Prato Allo Stelvio",
                "countryCode": "IT"
            },
            {
                "name": "Predazzo",
                "countryCode": "IT"
            },
            {
                "name": "Predoi",
                "countryCode": "IT"
            },
            {
                "name": "Preore",
                "countryCode": "IT"
            },
            {
                "name": "Prezzo",
                "countryCode": "IT"
            },
            {
                "name": "Proves - Proveis",
                "countryCode": "IT"
            },
            {
                "name": "Provincia autonoma di Trento",
                "countryCode": "IT"
            },
            {
                "name": "Rabbi Fonti",
                "countryCode": "IT"
            },
            {
                "name": "Rablà",
                "countryCode": "IT"
            },
            {
                "name": "Racines",
                "countryCode": "IT"
            },
            {
                "name": "Racines - Ratschings",
                "countryCode": "IT"
            },
            {
                "name": "Ragoli",
                "countryCode": "IT"
            },
            {
                "name": "Rasun Anterselva - Rasen-Antholz",
                "countryCode": "IT"
            },
            {
                "name": "Ravina",
                "countryCode": "IT"
            },
            {
                "name": "Renon - Ritten",
                "countryCode": "IT"
            },
            {
                "name": "Revò",
                "countryCode": "IT"
            },
            {
                "name": "Ried",
                "countryCode": "IT"
            },
            {
                "name": "Rifiano",
                "countryCode": "IT"
            },
            {
                "name": "Rio di Pusteria",
                "countryCode": "IT"
            },
            {
                "name": "Riscone",
                "countryCode": "IT"
            },
            {
                "name": "Riva del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Rodengo",
                "countryCode": "IT"
            },
            {
                "name": "Rodengo - Rodeneck",
                "countryCode": "IT"
            },
            {
                "name": "Romagnano",
                "countryCode": "IT"
            },
            {
                "name": "Romallo",
                "countryCode": "IT"
            },
            {
                "name": "Romeno",
                "countryCode": "IT"
            },
            {
                "name": "Roncegno",
                "countryCode": "IT"
            },
            {
                "name": "Ronchi Valsugana",
                "countryCode": "IT"
            },
            {
                "name": "Roncone",
                "countryCode": "IT"
            },
            {
                "name": "Ronzo-Chienis",
                "countryCode": "IT"
            },
            {
                "name": "Ronzone",
                "countryCode": "IT"
            },
            {
                "name": "Rovereto",
                "countryCode": "IT"
            },
            {
                "name": "Roverè della Luna",
                "countryCode": "IT"
            },
            {
                "name": "Ruffrè",
                "countryCode": "IT"
            },
            {
                "name": "Rumo",
                "countryCode": "IT"
            },
            {
                "name": "Sabbionara",
                "countryCode": "IT"
            },
            {
                "name": "Sagron Mis",
                "countryCode": "IT"
            },
            {
                "name": "Salorno",
                "countryCode": "IT"
            },
            {
                "name": "Samone",
                "countryCode": "IT"
            },
            {
                "name": "San Bernardo",
                "countryCode": "IT"
            },
            {
                "name": "San Candido",
                "countryCode": "IT"
            },
            {
                "name": "San Felice",
                "countryCode": "IT"
            },
            {
                "name": "San Genesio",
                "countryCode": "IT"
            },
            {
                "name": "San Genesio Atesino",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "San Leonardo in Passiria",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo di Sebato",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo in Banale",
                "countryCode": "IT"
            },
            {
                "name": "San Martino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Badia",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Passiria",
                "countryCode": "IT"
            },
            {
                "name": "San Michele",
                "countryCode": "IT"
            },
            {
                "name": "San Michele All'Adige",
                "countryCode": "IT"
            },
            {
                "name": "San Pancrazio",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "San Vigilio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Orsola",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Orsola Terme",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cristina Valgardena",
                "countryCode": "IT"
            },
            {
                "name": "Santa Valburga",
                "countryCode": "IT"
            },
            {
                "name": "Sanzeno",
                "countryCode": "IT"
            },
            {
                "name": "Sarentino",
                "countryCode": "IT"
            },
            {
                "name": "Sarnonico",
                "countryCode": "IT"
            },
            {
                "name": "Scena",
                "countryCode": "IT"
            },
            {
                "name": "Scena - Schenna",
                "countryCode": "IT"
            },
            {
                "name": "Sciaves",
                "countryCode": "IT"
            },
            {
                "name": "Scurelle",
                "countryCode": "IT"
            },
            {
                "name": "Segonzano",
                "countryCode": "IT"
            },
            {
                "name": "Selva",
                "countryCode": "IT"
            },
            {
                "name": "Selva dei Molini",
                "countryCode": "IT"
            },
            {
                "name": "Senale",
                "countryCode": "IT"
            },
            {
                "name": "Senales",
                "countryCode": "IT"
            },
            {
                "name": "Sesto",
                "countryCode": "IT"
            },
            {
                "name": "Sfruz",
                "countryCode": "IT"
            },
            {
                "name": "Silandro",
                "countryCode": "IT"
            },
            {
                "name": "Sinigo",
                "countryCode": "IT"
            },
            {
                "name": "Siror",
                "countryCode": "IT"
            },
            {
                "name": "Siusi",
                "countryCode": "IT"
            },
            {
                "name": "Sluderno",
                "countryCode": "IT"
            },
            {
                "name": "Smarano",
                "countryCode": "IT"
            },
            {
                "name": "Soprabolzano",
                "countryCode": "IT"
            },
            {
                "name": "Soraga",
                "countryCode": "IT"
            },
            {
                "name": "Sover",
                "countryCode": "IT"
            },
            {
                "name": "Spera",
                "countryCode": "IT"
            },
            {
                "name": "Spiazzo",
                "countryCode": "IT"
            },
            {
                "name": "Spormaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Sporminore",
                "countryCode": "IT"
            },
            {
                "name": "Stanghe",
                "countryCode": "IT"
            },
            {
                "name": "Stelvio",
                "countryCode": "IT"
            },
            {
                "name": "Stenico",
                "countryCode": "IT"
            },
            {
                "name": "Storo",
                "countryCode": "IT"
            },
            {
                "name": "Strembo",
                "countryCode": "IT"
            },
            {
                "name": "Strigno",
                "countryCode": "IT"
            },
            {
                "name": "Taio",
                "countryCode": "IT"
            },
            {
                "name": "Tassullo",
                "countryCode": "IT"
            },
            {
                "name": "Telve",
                "countryCode": "IT"
            },
            {
                "name": "Telve di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Tenna",
                "countryCode": "IT"
            },
            {
                "name": "Tenno",
                "countryCode": "IT"
            },
            {
                "name": "Terento",
                "countryCode": "IT"
            },
            {
                "name": "Terlago",
                "countryCode": "IT"
            },
            {
                "name": "Terlano",
                "countryCode": "IT"
            },
            {
                "name": "Termeno",
                "countryCode": "IT"
            },
            {
                "name": "Terragnolo",
                "countryCode": "IT"
            },
            {
                "name": "Terres",
                "countryCode": "IT"
            },
            {
                "name": "Terzolas",
                "countryCode": "IT"
            },
            {
                "name": "Tesero",
                "countryCode": "IT"
            },
            {
                "name": "Tesimo",
                "countryCode": "IT"
            },
            {
                "name": "Tione di Trento",
                "countryCode": "IT"
            },
            {
                "name": "Tires",
                "countryCode": "IT"
            },
            {
                "name": "Tirolo",
                "countryCode": "IT"
            },
            {
                "name": "Tonadico",
                "countryCode": "IT"
            },
            {
                "name": "Torcegno",
                "countryCode": "IT"
            },
            {
                "name": "Trambileno",
                "countryCode": "IT"
            },
            {
                "name": "Transacqua",
                "countryCode": "IT"
            },
            {
                "name": "Trento",
                "countryCode": "IT"
            },
            {
                "name": "Tres",
                "countryCode": "IT"
            },
            {
                "name": "Trodena",
                "countryCode": "IT"
            },
            {
                "name": "Tubre",
                "countryCode": "IT"
            },
            {
                "name": "Tuenno",
                "countryCode": "IT"
            },
            {
                "name": "Ultimo - Ulten",
                "countryCode": "IT"
            },
            {
                "name": "Vadena",
                "countryCode": "IT"
            },
            {
                "name": "Valda",
                "countryCode": "IT"
            },
            {
                "name": "Valdaora di Mezzo",
                "countryCode": "IT"
            },
            {
                "name": "Valfloriana",
                "countryCode": "IT"
            },
            {
                "name": "Vallarsa",
                "countryCode": "IT"
            },
            {
                "name": "Valle Aurina - Ahrntal",
                "countryCode": "IT"
            },
            {
                "name": "Valle di Casies - Gsies",
                "countryCode": "IT"
            },
            {
                "name": "Vandoies - Vintl",
                "countryCode": "IT"
            },
            {
                "name": "Vandoies di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Varena",
                "countryCode": "IT"
            },
            {
                "name": "Varna",
                "countryCode": "IT"
            },
            {
                "name": "Vattaro",
                "countryCode": "IT"
            },
            {
                "name": "Velturno",
                "countryCode": "IT"
            },
            {
                "name": "Verano",
                "countryCode": "IT"
            },
            {
                "name": "Verano - Voeran",
                "countryCode": "IT"
            },
            {
                "name": "Verla",
                "countryCode": "IT"
            },
            {
                "name": "Vermiglio",
                "countryCode": "IT"
            },
            {
                "name": "Vervò",
                "countryCode": "IT"
            },
            {
                "name": "Vezzano",
                "countryCode": "IT"
            },
            {
                "name": "Vignola",
                "countryCode": "IT"
            },
            {
                "name": "Vigo di Fassa",
                "countryCode": "IT"
            },
            {
                "name": "Vigo di Ton",
                "countryCode": "IT"
            },
            {
                "name": "Vigolo Vattaro",
                "countryCode": "IT"
            },
            {
                "name": "Villa",
                "countryCode": "IT"
            },
            {
                "name": "Villa Lagarina",
                "countryCode": "IT"
            },
            {
                "name": "Villa Rendena",
                "countryCode": "IT"
            },
            {
                "name": "Villa-Agnedo",
                "countryCode": "IT"
            },
            {
                "name": "Villabassa",
                "countryCode": "IT"
            },
            {
                "name": "Villandro",
                "countryCode": "IT"
            },
            {
                "name": "Villandro - Villanders",
                "countryCode": "IT"
            },
            {
                "name": "Vipiteno",
                "countryCode": "IT"
            },
            {
                "name": "Volano",
                "countryCode": "IT"
            },
            {
                "name": "Zambana",
                "countryCode": "IT"
            },
            {
                "name": "Ziano di Fiemme",
                "countryCode": "IT"
            },
            {
                "name": "Zuclo",
                "countryCode": "IT"
            },
            {
                "name": "Abano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Abbazia Pisani",
                "countryCode": "IT"
            },
            {
                "name": "Adria",
                "countryCode": "IT"
            },
            {
                "name": "Affi",
                "countryCode": "IT"
            },
            {
                "name": "Agna",
                "countryCode": "IT"
            },
            {
                "name": "Agordo",
                "countryCode": "IT"
            },
            {
                "name": "Agugliaro",
                "countryCode": "IT"
            },
            {
                "name": "Alano di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Albaredo",
                "countryCode": "IT"
            },
            {
                "name": "Albaredo d'Adige",
                "countryCode": "IT"
            },
            {
                "name": "Albettone",
                "countryCode": "IT"
            },
            {
                "name": "Albignasego",
                "countryCode": "IT"
            },
            {
                "name": "Alleghe",
                "countryCode": "IT"
            },
            {
                "name": "Alonte",
                "countryCode": "IT"
            },
            {
                "name": "Alpo",
                "countryCode": "IT"
            },
            {
                "name": "Altavilla Vicentina",
                "countryCode": "IT"
            },
            {
                "name": "Altissimo",
                "countryCode": "IT"
            },
            {
                "name": "Altivole",
                "countryCode": "IT"
            },
            {
                "name": "Angiari",
                "countryCode": "IT"
            },
            {
                "name": "Anguillara Veneta",
                "countryCode": "IT"
            },
            {
                "name": "Annone Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Arbizzano-Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Arcade",
                "countryCode": "IT"
            },
            {
                "name": "Arcole",
                "countryCode": "IT"
            },
            {
                "name": "Arcugnano-Torri",
                "countryCode": "IT"
            },
            {
                "name": "Ariano",
                "countryCode": "IT"
            },
            {
                "name": "Arino",
                "countryCode": "IT"
            },
            {
                "name": "Arlesega",
                "countryCode": "IT"
            },
            {
                "name": "Arquà Petrarca",
                "countryCode": "IT"
            },
            {
                "name": "Arquà Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Arre",
                "countryCode": "IT"
            },
            {
                "name": "Arsiero",
                "countryCode": "IT"
            },
            {
                "name": "Arsiè",
                "countryCode": "IT"
            },
            {
                "name": "Arzergrande",
                "countryCode": "IT"
            },
            {
                "name": "Arzignano",
                "countryCode": "IT"
            },
            {
                "name": "Asiago",
                "countryCode": "IT"
            },
            {
                "name": "Asigliano Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Asolo",
                "countryCode": "IT"
            },
            {
                "name": "Asparetto",
                "countryCode": "IT"
            },
            {
                "name": "Auronzo",
                "countryCode": "IT"
            },
            {
                "name": "Badia Calavena",
                "countryCode": "IT"
            },
            {
                "name": "Badia Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Badoere",
                "countryCode": "IT"
            },
            {
                "name": "Bagnoli di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo di Po",
                "countryCode": "IT"
            },
            {
                "name": "Ballò",
                "countryCode": "IT"
            },
            {
                "name": "Baone",
                "countryCode": "IT"
            },
            {
                "name": "Barbarano Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Barbisano",
                "countryCode": "IT"
            },
            {
                "name": "Barbona",
                "countryCode": "IT"
            },
            {
                "name": "Bardolino",
                "countryCode": "IT"
            },
            {
                "name": "Baruchella",
                "countryCode": "IT"
            },
            {
                "name": "Bassano del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Bassone",
                "countryCode": "IT"
            },
            {
                "name": "Bastia",
                "countryCode": "IT"
            },
            {
                "name": "Battaglia Terme",
                "countryCode": "IT"
            },
            {
                "name": "Beccacivetta-Azzano",
                "countryCode": "IT"
            },
            {
                "name": "Belfiore",
                "countryCode": "IT"
            },
            {
                "name": "Belluno",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere",
                "countryCode": "IT"
            },
            {
                "name": "Bergantino",
                "countryCode": "IT"
            },
            {
                "name": "Bertipaglia",
                "countryCode": "IT"
            },
            {
                "name": "Bevilacqua",
                "countryCode": "IT"
            },
            {
                "name": "Biancade",
                "countryCode": "IT"
            },
            {
                "name": "Bibano",
                "countryCode": "IT"
            },
            {
                "name": "Bibione",
                "countryCode": "IT"
            },
            {
                "name": "Bigolino",
                "countryCode": "IT"
            },
            {
                "name": "Boara Pisani",
                "countryCode": "IT"
            },
            {
                "name": "Bojon-Lova",
                "countryCode": "IT"
            },
            {
                "name": "Bolzano Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Bonavicina",
                "countryCode": "IT"
            },
            {
                "name": "Bonavigo",
                "countryCode": "IT"
            },
            {
                "name": "Bonferraro",
                "countryCode": "IT"
            },
            {
                "name": "Borca",
                "countryCode": "IT"
            },
            {
                "name": "Borgoricco-San Michele delle Badesse-Sant'Eufemia",
                "countryCode": "IT"
            },
            {
                "name": "Borso del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Bortolot",
                "countryCode": "IT"
            },
            {
                "name": "Bosaro",
                "countryCode": "IT"
            },
            {
                "name": "Boschi Sant'Anna",
                "countryCode": "IT"
            },
            {
                "name": "Bosco",
                "countryCode": "IT"
            },
            {
                "name": "Bosco Chiesanuova",
                "countryCode": "IT"
            },
            {
                "name": "Boscochiaro",
                "countryCode": "IT"
            },
            {
                "name": "Bottrighe",
                "countryCode": "IT"
            },
            {
                "name": "Bovolenta",
                "countryCode": "IT"
            },
            {
                "name": "Bovolone",
                "countryCode": "IT"
            },
            {
                "name": "Braglia",
                "countryCode": "IT"
            },
            {
                "name": "Breda",
                "countryCode": "IT"
            },
            {
                "name": "Breganze",
                "countryCode": "IT"
            },
            {
                "name": "Brendola",
                "countryCode": "IT"
            },
            {
                "name": "Brentino Belluno",
                "countryCode": "IT"
            },
            {
                "name": "Brenzone",
                "countryCode": "IT"
            },
            {
                "name": "Bressanvido",
                "countryCode": "IT"
            },
            {
                "name": "Brogliano",
                "countryCode": "IT"
            },
            {
                "name": "Brognoligo-Costalunga",
                "countryCode": "IT"
            },
            {
                "name": "Brugine",
                "countryCode": "IT"
            },
            {
                "name": "Burano",
                "countryCode": "IT"
            },
            {
                "name": "Buso",
                "countryCode": "IT"
            },
            {
                "name": "Bussolengo",
                "countryCode": "IT"
            },
            {
                "name": "Buttapietra",
                "countryCode": "IT"
            },
            {
                "name": "Ca' Rainati",
                "countryCode": "IT"
            },
            {
                "name": "Ca' Savio",
                "countryCode": "IT"
            },
            {
                "name": "Ca' Tiepolo",
                "countryCode": "IT"
            },
            {
                "name": "Ca' degli Oppi",
                "countryCode": "IT"
            },
            {
                "name": "Cadoneghe",
                "countryCode": "IT"
            },
            {
                "name": "Caerano di San Marco",
                "countryCode": "IT"
            },
            {
                "name": "Calalzo di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Caldierino-Rota",
                "countryCode": "IT"
            },
            {
                "name": "Caldiero",
                "countryCode": "IT"
            },
            {
                "name": "Caldogno-Rettorgole-Cresole",
                "countryCode": "IT"
            },
            {
                "name": "Calmasino",
                "countryCode": "IT"
            },
            {
                "name": "Caltana",
                "countryCode": "IT"
            },
            {
                "name": "Calto",
                "countryCode": "IT"
            },
            {
                "name": "Caltrano",
                "countryCode": "IT"
            },
            {
                "name": "Calvene",
                "countryCode": "IT"
            },
            {
                "name": "Camalò",
                "countryCode": "IT"
            },
            {
                "name": "Camisano Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Campagna Lupia",
                "countryCode": "IT"
            },
            {
                "name": "Campagnola",
                "countryCode": "IT"
            },
            {
                "name": "Campalto",
                "countryCode": "IT"
            },
            {
                "name": "Campese",
                "countryCode": "IT"
            },
            {
                "name": "Campiglia dei Berici",
                "countryCode": "IT"
            },
            {
                "name": "Campo San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Campodarsego",
                "countryCode": "IT"
            },
            {
                "name": "Campodoro",
                "countryCode": "IT"
            },
            {
                "name": "Campolongo Maggiore Liettoli",
                "countryCode": "IT"
            },
            {
                "name": "Campolongo sul Brenta",
                "countryCode": "IT"
            },
            {
                "name": "Camponogara",
                "countryCode": "IT"
            },
            {
                "name": "Camposampiero",
                "countryCode": "IT"
            },
            {
                "name": "Canale d'Agordo",
                "countryCode": "IT"
            },
            {
                "name": "Canaro",
                "countryCode": "IT"
            },
            {
                "name": "Canda",
                "countryCode": "IT"
            },
            {
                "name": "Candelù",
                "countryCode": "IT"
            },
            {
                "name": "Candiana",
                "countryCode": "IT"
            },
            {
                "name": "Candide",
                "countryCode": "IT"
            },
            {
                "name": "Caniezza",
                "countryCode": "IT"
            },
            {
                "name": "Canove di Roana",
                "countryCode": "IT"
            },
            {
                "name": "Caorle",
                "countryCode": "IT"
            },
            {
                "name": "Capitello",
                "countryCode": "IT"
            },
            {
                "name": "Cappella Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Caprino Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Carbonera",
                "countryCode": "IT"
            },
            {
                "name": "Carceri",
                "countryCode": "IT"
            },
            {
                "name": "Carmignano di Brenta",
                "countryCode": "IT"
            },
            {
                "name": "Carpesica",
                "countryCode": "IT"
            },
            {
                "name": "Carrara San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Carrè",
                "countryCode": "IT"
            },
            {
                "name": "Cartigliano",
                "countryCode": "IT"
            },
            {
                "name": "Cartura",
                "countryCode": "IT"
            },
            {
                "name": "Casacorba",
                "countryCode": "IT"
            },
            {
                "name": "Casale di Scodosia",
                "countryCode": "IT"
            },
            {
                "name": "Casale sul Sile",
                "countryCode": "IT"
            },
            {
                "name": "Casaleone",
                "countryCode": "IT"
            },
            {
                "name": "Casalserugo",
                "countryCode": "IT"
            },
            {
                "name": "Caselle",
                "countryCode": "IT"
            },
            {
                "name": "Casier",
                "countryCode": "IT"
            },
            {
                "name": "Casoni",
                "countryCode": "IT"
            },
            {
                "name": "Cassola",
                "countryCode": "IT"
            },
            {
                "name": "Castagnaro",
                "countryCode": "IT"
            },
            {
                "name": "Castagnole",
                "countryCode": "IT"
            },
            {
                "name": "Castelbaldo",
                "countryCode": "IT"
            },
            {
                "name": "Castelcucco",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranco Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Castelgomberto",
                "countryCode": "IT"
            },
            {
                "name": "Castelguglielmo",
                "countryCode": "IT"
            },
            {
                "name": "Castello di Godego",
                "countryCode": "IT"
            },
            {
                "name": "Castelmassa",
                "countryCode": "IT"
            },
            {
                "name": "Castelminio",
                "countryCode": "IT"
            },
            {
                "name": "Castelnovo",
                "countryCode": "IT"
            },
            {
                "name": "Castelnovo Bariano",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Castion",
                "countryCode": "IT"
            },
            {
                "name": "Cavajon Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Cavalcaselle",
                "countryCode": "IT"
            },
            {
                "name": "Cavallino",
                "countryCode": "IT"
            },
            {
                "name": "Cavarzere",
                "countryCode": "IT"
            },
            {
                "name": "Cavaso del Tomba",
                "countryCode": "IT"
            },
            {
                "name": "Cavino",
                "countryCode": "IT"
            },
            {
                "name": "Cazzago-Ex Polo",
                "countryCode": "IT"
            },
            {
                "name": "Cazzano di Tramigna",
                "countryCode": "IT"
            },
            {
                "name": "Ceggia",
                "countryCode": "IT"
            },
            {
                "name": "Celat-San Tomaso Agordino",
                "countryCode": "IT"
            },
            {
                "name": "Cellore",
                "countryCode": "IT"
            },
            {
                "name": "Cencenighe Agordino",
                "countryCode": "IT"
            },
            {
                "name": "Cendon",
                "countryCode": "IT"
            },
            {
                "name": "Ceneselli",
                "countryCode": "IT"
            },
            {
                "name": "Centrale",
                "countryCode": "IT"
            },
            {
                "name": "Cerea",
                "countryCode": "IT"
            },
            {
                "name": "Cereda-Cozza Cornedo",
                "countryCode": "IT"
            },
            {
                "name": "Ceregnano",
                "countryCode": "IT"
            },
            {
                "name": "Cerro Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Cervarese Santa Croce",
                "countryCode": "IT"
            },
            {
                "name": "Cesarolo",
                "countryCode": "IT"
            },
            {
                "name": "Cesiomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cessalto",
                "countryCode": "IT"
            },
            {
                "name": "Chiampo",
                "countryCode": "IT"
            },
            {
                "name": "Chiarano",
                "countryCode": "IT"
            },
            {
                "name": "Chioggia",
                "countryCode": "IT"
            },
            {
                "name": "Chiuppano",
                "countryCode": "IT"
            },
            {
                "name": "Ciano",
                "countryCode": "IT"
            },
            {
                "name": "Cibiana",
                "countryCode": "IT"
            },
            {
                "name": "Cimadolmo",
                "countryCode": "IT"
            },
            {
                "name": "Cinto Caomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cismon del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Cison di Valmarino",
                "countryCode": "IT"
            },
            {
                "name": "Cittadella",
                "countryCode": "IT"
            },
            {
                "name": "Codevigo",
                "countryCode": "IT"
            },
            {
                "name": "Codiverno",
                "countryCode": "IT"
            },
            {
                "name": "Codognè",
                "countryCode": "IT"
            },
            {
                "name": "Cogollo del Cengio",
                "countryCode": "IT"
            },
            {
                "name": "Col San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Colle Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Colle Umberto",
                "countryCode": "IT"
            },
            {
                "name": "Cologna Veneta",
                "countryCode": "IT"
            },
            {
                "name": "Colognola ai Colli",
                "countryCode": "IT"
            },
            {
                "name": "Colà",
                "countryCode": "IT"
            },
            {
                "name": "Conco",
                "countryCode": "IT"
            },
            {
                "name": "Concordia Sagittaria",
                "countryCode": "IT"
            },
            {
                "name": "Conegliano",
                "countryCode": "IT"
            },
            {
                "name": "Conscio",
                "countryCode": "IT"
            },
            {
                "name": "Conselve",
                "countryCode": "IT"
            },
            {
                "name": "Corbanese",
                "countryCode": "IT"
            },
            {
                "name": "Corbola",
                "countryCode": "IT"
            },
            {
                "name": "Corbolone",
                "countryCode": "IT"
            },
            {
                "name": "Cordignano",
                "countryCode": "IT"
            },
            {
                "name": "Cornedo Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Cornuda",
                "countryCode": "IT"
            },
            {
                "name": "Correzzola",
                "countryCode": "IT"
            },
            {
                "name": "Corrubbio",
                "countryCode": "IT"
            },
            {
                "name": "Corte",
                "countryCode": "IT"
            },
            {
                "name": "Cortina d'Ampezzo",
                "countryCode": "IT"
            },
            {
                "name": "Cosniga-Zoppè",
                "countryCode": "IT"
            },
            {
                "name": "Costa di Rovigo",
                "countryCode": "IT"
            },
            {
                "name": "Costabissara",
                "countryCode": "IT"
            },
            {
                "name": "Costermano",
                "countryCode": "IT"
            },
            {
                "name": "Covolo-Levada",
                "countryCode": "IT"
            },
            {
                "name": "Creazzo",
                "countryCode": "IT"
            },
            {
                "name": "Crepaldo",
                "countryCode": "IT"
            },
            {
                "name": "Crespadoro",
                "countryCode": "IT"
            },
            {
                "name": "Crespano del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Crespino",
                "countryCode": "IT"
            },
            {
                "name": "Crispi Cavour",
                "countryCode": "IT"
            },
            {
                "name": "Crocetta del Montello",
                "countryCode": "IT"
            },
            {
                "name": "Crocetta-Nogarè",
                "countryCode": "IT"
            },
            {
                "name": "Curtarolo",
                "countryCode": "IT"
            },
            {
                "name": "Danta",
                "countryCode": "IT"
            },
            {
                "name": "Dese",
                "countryCode": "IT"
            },
            {
                "name": "Dolcè",
                "countryCode": "IT"
            },
            {
                "name": "Dolo",
                "countryCode": "IT"
            },
            {
                "name": "Domegge di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Don",
                "countryCode": "IT"
            },
            {
                "name": "Donzella",
                "countryCode": "IT"
            },
            {
                "name": "Dossobuono",
                "countryCode": "IT"
            },
            {
                "name": "Dosson",
                "countryCode": "IT"
            },
            {
                "name": "Due Carrare",
                "countryCode": "IT"
            },
            {
                "name": "Dueville",
                "countryCode": "IT"
            },
            {
                "name": "Enego",
                "countryCode": "IT"
            },
            {
                "name": "Eraclea",
                "countryCode": "IT"
            },
            {
                "name": "Erbezzo",
                "countryCode": "IT"
            },
            {
                "name": "Erbè",
                "countryCode": "IT"
            },
            {
                "name": "Este",
                "countryCode": "IT"
            },
            {
                "name": "Falcade Alto",
                "countryCode": "IT"
            },
            {
                "name": "Falzè di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Falzè-Signoressa",
                "countryCode": "IT"
            },
            {
                "name": "Fane",
                "countryCode": "IT"
            },
            {
                "name": "Fanzolo",
                "countryCode": "IT"
            },
            {
                "name": "Fara Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Farra d'Alpago",
                "countryCode": "IT"
            },
            {
                "name": "Farra di Soligo",
                "countryCode": "IT"
            },
            {
                "name": "Feltre",
                "countryCode": "IT"
            },
            {
                "name": "Ferrara di Monte Baldo",
                "countryCode": "IT"
            },
            {
                "name": "Ficarolo",
                "countryCode": "IT"
            },
            {
                "name": "Fiesso",
                "countryCode": "IT"
            },
            {
                "name": "Fiesso Umbertiano",
                "countryCode": "IT"
            },
            {
                "name": "Fiesso d'Artico",
                "countryCode": "IT"
            },
            {
                "name": "Follina",
                "countryCode": "IT"
            },
            {
                "name": "Fontanafredda",
                "countryCode": "IT"
            },
            {
                "name": "Fontanelle",
                "countryCode": "IT"
            },
            {
                "name": "Fontaniva",
                "countryCode": "IT"
            },
            {
                "name": "Fonzaso",
                "countryCode": "IT"
            },
            {
                "name": "Forette",
                "countryCode": "IT"
            },
            {
                "name": "Fornaci",
                "countryCode": "IT"
            },
            {
                "name": "Fornase",
                "countryCode": "IT"
            },
            {
                "name": "Forno di Zoldo",
                "countryCode": "IT"
            },
            {
                "name": "Fossalta di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Fossalta di Portogruaro",
                "countryCode": "IT"
            },
            {
                "name": "Fossalunga",
                "countryCode": "IT"
            },
            {
                "name": "Fossò",
                "countryCode": "IT"
            },
            {
                "name": "Foza",
                "countryCode": "IT"
            },
            {
                "name": "Francenigo",
                "countryCode": "IT"
            },
            {
                "name": "Frassinelle Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Fratta Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Fregona",
                "countryCode": "IT"
            },
            {
                "name": "Frescada",
                "countryCode": "IT"
            },
            {
                "name": "Fumane",
                "countryCode": "IT"
            },
            {
                "name": "Fusine",
                "countryCode": "IT"
            },
            {
                "name": "Gaiarine",
                "countryCode": "IT"
            },
            {
                "name": "Gaiba",
                "countryCode": "IT"
            },
            {
                "name": "Galliera Veneta",
                "countryCode": "IT"
            },
            {
                "name": "Gallio",
                "countryCode": "IT"
            },
            {
                "name": "Galta",
                "countryCode": "IT"
            },
            {
                "name": "Galzignano",
                "countryCode": "IT"
            },
            {
                "name": "Gambellara",
                "countryCode": "IT"
            },
            {
                "name": "Gambugliano",
                "countryCode": "IT"
            },
            {
                "name": "Garda",
                "countryCode": "IT"
            },
            {
                "name": "Gavello",
                "countryCode": "IT"
            },
            {
                "name": "Gazzo",
                "countryCode": "IT"
            },
            {
                "name": "Gazzolo-Volpino",
                "countryCode": "IT"
            },
            {
                "name": "Giacciano con Baruchella",
                "countryCode": "IT"
            },
            {
                "name": "Giavenale",
                "countryCode": "IT"
            },
            {
                "name": "Giavera del Montello",
                "countryCode": "IT"
            },
            {
                "name": "Giudecca",
                "countryCode": "IT"
            },
            {
                "name": "Godega",
                "countryCode": "IT"
            },
            {
                "name": "Gorgo al Monticano",
                "countryCode": "IT"
            },
            {
                "name": "Gorgo della Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Gosaldo",
                "countryCode": "IT"
            },
            {
                "name": "Grancona",
                "countryCode": "IT"
            },
            {
                "name": "Grantorto",
                "countryCode": "IT"
            },
            {
                "name": "Granze",
                "countryCode": "IT"
            },
            {
                "name": "Grezzana",
                "countryCode": "IT"
            },
            {
                "name": "Grignano Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Grisignano di Zocco",
                "countryCode": "IT"
            },
            {
                "name": "Gruaro",
                "countryCode": "IT"
            },
            {
                "name": "Gruaro-Bagnara",
                "countryCode": "IT"
            },
            {
                "name": "Grumolo Pedemonte",
                "countryCode": "IT"
            },
            {
                "name": "Grumolo delle Abbadesse",
                "countryCode": "IT"
            },
            {
                "name": "Guarda Veneta",
                "countryCode": "IT"
            },
            {
                "name": "Iesolo",
                "countryCode": "IT"
            },
            {
                "name": "Illasi",
                "countryCode": "IT"
            },
            {
                "name": "Isola Rizza",
                "countryCode": "IT"
            },
            {
                "name": "Isola Vicentina",
                "countryCode": "IT"
            },
            {
                "name": "Isola della Scala",
                "countryCode": "IT"
            },
            {
                "name": "Istrana",
                "countryCode": "IT"
            },
            {
                "name": "La Salute di Livenza",
                "countryCode": "IT"
            },
            {
                "name": "La Valle Agordina",
                "countryCode": "IT"
            },
            {
                "name": "Laghi",
                "countryCode": "IT"
            },
            {
                "name": "Lama Pezzoli",
                "countryCode": "IT"
            },
            {
                "name": "Lamon",
                "countryCode": "IT"
            },
            {
                "name": "Lamosano",
                "countryCode": "IT"
            },
            {
                "name": "Lancenigo-Villorba",
                "countryCode": "IT"
            },
            {
                "name": "Lastebasse",
                "countryCode": "IT"
            },
            {
                "name": "Lavagno",
                "countryCode": "IT"
            },
            {
                "name": "Lazise",
                "countryCode": "IT"
            },
            {
                "name": "Legnago",
                "countryCode": "IT"
            },
            {
                "name": "Legnaro",
                "countryCode": "IT"
            },
            {
                "name": "Lendinara",
                "countryCode": "IT"
            },
            {
                "name": "Lentiai",
                "countryCode": "IT"
            },
            {
                "name": "Levada",
                "countryCode": "IT"
            },
            {
                "name": "Lido",
                "countryCode": "IT"
            },
            {
                "name": "Lido di Jesolo",
                "countryCode": "IT"
            },
            {
                "name": "Limana",
                "countryCode": "IT"
            },
            {
                "name": "Limena",
                "countryCode": "IT"
            },
            {
                "name": "Lisiera",
                "countryCode": "IT"
            },
            {
                "name": "Livinallongo del Col di Lana",
                "countryCode": "IT"
            },
            {
                "name": "Locara",
                "countryCode": "IT"
            },
            {
                "name": "Longare",
                "countryCode": "IT"
            },
            {
                "name": "Longarone",
                "countryCode": "IT"
            },
            {
                "name": "Longhi",
                "countryCode": "IT"
            },
            {
                "name": "Lonigo",
                "countryCode": "IT"
            },
            {
                "name": "Loreggia",
                "countryCode": "IT"
            },
            {
                "name": "Loreggiola",
                "countryCode": "IT"
            },
            {
                "name": "Lorenzago di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Loreo",
                "countryCode": "IT"
            },
            {
                "name": "Loria Bessica",
                "countryCode": "IT"
            },
            {
                "name": "Lozzo Atestino",
                "countryCode": "IT"
            },
            {
                "name": "Lozzo di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Lugagnano",
                "countryCode": "IT"
            },
            {
                "name": "Lughignano",
                "countryCode": "IT"
            },
            {
                "name": "Lugo di Vicenza",
                "countryCode": "IT"
            },
            {
                "name": "Lugugnana",
                "countryCode": "IT"
            },
            {
                "name": "Lumignano",
                "countryCode": "IT"
            },
            {
                "name": "Lusia",
                "countryCode": "IT"
            },
            {
                "name": "Lusiana",
                "countryCode": "IT"
            },
            {
                "name": "Lutrano",
                "countryCode": "IT"
            },
            {
                "name": "Maccacari",
                "countryCode": "IT"
            },
            {
                "name": "Maerne",
                "countryCode": "IT"
            },
            {
                "name": "Magugnano",
                "countryCode": "IT"
            },
            {
                "name": "Malcesine",
                "countryCode": "IT"
            },
            {
                "name": "Malo",
                "countryCode": "IT"
            },
            {
                "name": "Mandriola-Sant'Agostino",
                "countryCode": "IT"
            },
            {
                "name": "Mansuè",
                "countryCode": "IT"
            },
            {
                "name": "Marano",
                "countryCode": "IT"
            },
            {
                "name": "Marano Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Marano di Valpolicella",
                "countryCode": "IT"
            },
            {
                "name": "Marchesino-Bovo",
                "countryCode": "IT"
            },
            {
                "name": "Marcon-Gaggio-Colmello",
                "countryCode": "IT"
            },
            {
                "name": "Mardimago",
                "countryCode": "IT"
            },
            {
                "name": "Mareno di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Marola",
                "countryCode": "IT"
            },
            {
                "name": "Marostica",
                "countryCode": "IT"
            },
            {
                "name": "Martellago",
                "countryCode": "IT"
            },
            {
                "name": "Maser",
                "countryCode": "IT"
            },
            {
                "name": "Maserada sul Piave",
                "countryCode": "IT"
            },
            {
                "name": "Maserà di Padova",
                "countryCode": "IT"
            },
            {
                "name": "Masi",
                "countryCode": "IT"
            },
            {
                "name": "Mason Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Massanzago-Ca' Baglioni-San Dono",
                "countryCode": "IT"
            },
            {
                "name": "Meduna di Livenza",
                "countryCode": "IT"
            },
            {
                "name": "Megliadino San Fidenzio",
                "countryCode": "IT"
            },
            {
                "name": "Megliadino San Vitale",
                "countryCode": "IT"
            },
            {
                "name": "Mejaniga",
                "countryCode": "IT"
            },
            {
                "name": "Mel",
                "countryCode": "IT"
            },
            {
                "name": "Melara",
                "countryCode": "IT"
            },
            {
                "name": "Meledo",
                "countryCode": "IT"
            },
            {
                "name": "Meolo",
                "countryCode": "IT"
            },
            {
                "name": "Merlara",
                "countryCode": "IT"
            },
            {
                "name": "Mestre",
                "countryCode": "IT"
            },
            {
                "name": "Mestrino",
                "countryCode": "IT"
            },
            {
                "name": "Mezzane di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Miane",
                "countryCode": "IT"
            },
            {
                "name": "Mignagola",
                "countryCode": "IT"
            },
            {
                "name": "Minerbe",
                "countryCode": "IT"
            },
            {
                "name": "Mira Taglio",
                "countryCode": "IT"
            },
            {
                "name": "Mirano",
                "countryCode": "IT"
            },
            {
                "name": "Mogliano Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Molino-Mozzi-Bittarelli",
                "countryCode": "IT"
            },
            {
                "name": "Monastier di Treviso",
                "countryCode": "IT"
            },
            {
                "name": "Monfumo",
                "countryCode": "IT"
            },
            {
                "name": "Moniego",
                "countryCode": "IT"
            },
            {
                "name": "Monselice",
                "countryCode": "IT"
            },
            {
                "name": "Montagnana",
                "countryCode": "IT"
            },
            {
                "name": "Monte di Malo",
                "countryCode": "IT"
            },
            {
                "name": "Montebello Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Montebelluna",
                "countryCode": "IT"
            },
            {
                "name": "Montecchia di Crosara",
                "countryCode": "IT"
            },
            {
                "name": "Montecchio Maggiore-Alte Ceccato",
                "countryCode": "IT"
            },
            {
                "name": "Montecchio Precalcino",
                "countryCode": "IT"
            },
            {
                "name": "Monteforte d'Alpone",
                "countryCode": "IT"
            },
            {
                "name": "Montegalda",
                "countryCode": "IT"
            },
            {
                "name": "Montegaldella",
                "countryCode": "IT"
            },
            {
                "name": "Montegrotto Terme",
                "countryCode": "IT"
            },
            {
                "name": "Montemerlo",
                "countryCode": "IT"
            },
            {
                "name": "Monteviale",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli-Fontana",
                "countryCode": "IT"
            },
            {
                "name": "Monticello Conte Otto",
                "countryCode": "IT"
            },
            {
                "name": "Monticello di Fara",
                "countryCode": "IT"
            },
            {
                "name": "Montorio",
                "countryCode": "IT"
            },
            {
                "name": "Montorso Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Moriago della Battaglia",
                "countryCode": "IT"
            },
            {
                "name": "Mosnigo",
                "countryCode": "IT"
            },
            {
                "name": "Mossano",
                "countryCode": "IT"
            },
            {
                "name": "Motta",
                "countryCode": "IT"
            },
            {
                "name": "Motta di Livenza",
                "countryCode": "IT"
            },
            {
                "name": "Mozzecane",
                "countryCode": "IT"
            },
            {
                "name": "Murano",
                "countryCode": "IT"
            },
            {
                "name": "Mure",
                "countryCode": "IT"
            },
            {
                "name": "Musano",
                "countryCode": "IT"
            },
            {
                "name": "Musestre",
                "countryCode": "IT"
            },
            {
                "name": "Musile di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Mussolente",
                "countryCode": "IT"
            },
            {
                "name": "Negrar",
                "countryCode": "IT"
            },
            {
                "name": "Nervesa della Battaglia",
                "countryCode": "IT"
            },
            {
                "name": "Noale",
                "countryCode": "IT"
            },
            {
                "name": "Nogara",
                "countryCode": "IT"
            },
            {
                "name": "Nogarole Rocca",
                "countryCode": "IT"
            },
            {
                "name": "Nogarole Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Nove",
                "countryCode": "IT"
            },
            {
                "name": "Noventa",
                "countryCode": "IT"
            },
            {
                "name": "Noventa Vicentina",
                "countryCode": "IT"
            },
            {
                "name": "Noventa di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Novoledo",
                "countryCode": "IT"
            },
            {
                "name": "Occhiobello",
                "countryCode": "IT"
            },
            {
                "name": "Oderzo",
                "countryCode": "IT"
            },
            {
                "name": "Oltre Brenta",
                "countryCode": "IT"
            },
            {
                "name": "Onigo",
                "countryCode": "IT"
            },
            {
                "name": "Onè",
                "countryCode": "IT"
            },
            {
                "name": "Oppeano",
                "countryCode": "IT"
            },
            {
                "name": "Orgiano",
                "countryCode": "IT"
            },
            {
                "name": "Ormelle",
                "countryCode": "IT"
            },
            {
                "name": "Orsago",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto Euganeo",
                "countryCode": "IT"
            },
            {
                "name": "Ospitale di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Padernello",
                "countryCode": "IT"
            },
            {
                "name": "Paderno",
                "countryCode": "IT"
            },
            {
                "name": "Paderno del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Padova",
                "countryCode": "IT"
            },
            {
                "name": "Paese",
                "countryCode": "IT"
            },
            {
                "name": "Palù",
                "countryCode": "IT"
            },
            {
                "name": "Papozze",
                "countryCode": "IT"
            },
            {
                "name": "Parona",
                "countryCode": "IT"
            },
            {
                "name": "Passarella",
                "countryCode": "IT"
            },
            {
                "name": "Pastrengo",
                "countryCode": "IT"
            },
            {
                "name": "Pedavena",
                "countryCode": "IT"
            },
            {
                "name": "Pedemonte",
                "countryCode": "IT"
            },
            {
                "name": "Pederiva",
                "countryCode": "IT"
            },
            {
                "name": "Pederobba",
                "countryCode": "IT"
            },
            {
                "name": "Pegolotte",
                "countryCode": "IT"
            },
            {
                "name": "Pellestrina",
                "countryCode": "IT"
            },
            {
                "name": "Perarolo di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Pernumia",
                "countryCode": "IT"
            },
            {
                "name": "Pero",
                "countryCode": "IT"
            },
            {
                "name": "Pescantina",
                "countryCode": "IT"
            },
            {
                "name": "Peschiera del Garda",
                "countryCode": "IT"
            },
            {
                "name": "Peseggia-Gardigiano",
                "countryCode": "IT"
            },
            {
                "name": "Pettorazza Grimani",
                "countryCode": "IT"
            },
            {
                "name": "Pezzan",
                "countryCode": "IT"
            },
            {
                "name": "Piacenza d'Adige",
                "countryCode": "IT"
            },
            {
                "name": "Pianezze",
                "countryCode": "IT"
            },
            {
                "name": "Pianiga",
                "countryCode": "IT"
            },
            {
                "name": "Piavon",
                "countryCode": "IT"
            },
            {
                "name": "Piazza",
                "countryCode": "IT"
            },
            {
                "name": "Piazzola sul Brenta",
                "countryCode": "IT"
            },
            {
                "name": "Pie' Falcade",
                "countryCode": "IT"
            },
            {
                "name": "Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Pieve d'Alpago",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Soligo",
                "countryCode": "IT"
            },
            {
                "name": "Pincara",
                "countryCode": "IT"
            },
            {
                "name": "Piombino Dese",
                "countryCode": "IT"
            },
            {
                "name": "Pionca",
                "countryCode": "IT"
            },
            {
                "name": "Piove di Sacco-Piovega",
                "countryCode": "IT"
            },
            {
                "name": "Piovene Rocchette",
                "countryCode": "IT"
            },
            {
                "name": "Poiana Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Poianella",
                "countryCode": "IT"
            },
            {
                "name": "Poiano",
                "countryCode": "IT"
            },
            {
                "name": "Polesella",
                "countryCode": "IT"
            },
            {
                "name": "Polverara",
                "countryCode": "IT"
            },
            {
                "name": "Ponso",
                "countryCode": "IT"
            },
            {
                "name": "Ponte San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "Ponte di Barbarano",
                "countryCode": "IT"
            },
            {
                "name": "Ponte di Castegnero",
                "countryCode": "IT"
            },
            {
                "name": "Ponte di Nanto",
                "countryCode": "IT"
            },
            {
                "name": "Ponte di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Ponte nelle Alpi",
                "countryCode": "IT"
            },
            {
                "name": "Ponte nelle Alpi-Polpet",
                "countryCode": "IT"
            },
            {
                "name": "Pontecchio Polesine",
                "countryCode": "IT"
            },
            {
                "name": "Pontelongo",
                "countryCode": "IT"
            },
            {
                "name": "Ponzano",
                "countryCode": "IT"
            },
            {
                "name": "Porcellengo",
                "countryCode": "IT"
            },
            {
                "name": "Porto Tolle",
                "countryCode": "IT"
            },
            {
                "name": "Porto Viro",
                "countryCode": "IT"
            },
            {
                "name": "Portobuffolè",
                "countryCode": "IT"
            },
            {
                "name": "Portogruaro",
                "countryCode": "IT"
            },
            {
                "name": "Posina",
                "countryCode": "IT"
            },
            {
                "name": "Possagno",
                "countryCode": "IT"
            },
            {
                "name": "Postioma",
                "countryCode": "IT"
            },
            {
                "name": "Pove del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Povegliano",
                "countryCode": "IT"
            },
            {
                "name": "Povegliano Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Pozzoleone",
                "countryCode": "IT"
            },
            {
                "name": "Pozzonovo",
                "countryCode": "IT"
            },
            {
                "name": "Pradelle",
                "countryCode": "IT"
            },
            {
                "name": "Pramaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Pramaggiore Blessaglia",
                "countryCode": "IT"
            },
            {
                "name": "Preara-Moraro-Levà Nord",
                "countryCode": "IT"
            },
            {
                "name": "Preganziol",
                "countryCode": "IT"
            },
            {
                "name": "Pressana",
                "countryCode": "IT"
            },
            {
                "name": "Priula-Colfosco",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Belluno",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Padova",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Rovigo",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Treviso",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Venezia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Verona",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Vicenza",
                "countryCode": "IT"
            },
            {
                "name": "Puos d'Alpago",
                "countryCode": "IT"
            },
            {
                "name": "Quaderni",
                "countryCode": "IT"
            },
            {
                "name": "Quarto d'Altino",
                "countryCode": "IT"
            },
            {
                "name": "Quero",
                "countryCode": "IT"
            },
            {
                "name": "Quinto Vicentino",
                "countryCode": "IT"
            },
            {
                "name": "Quinto di Treviso",
                "countryCode": "IT"
            },
            {
                "name": "Raldon",
                "countryCode": "IT"
            },
            {
                "name": "Ramon",
                "countryCode": "IT"
            },
            {
                "name": "Recoaro Terme",
                "countryCode": "IT"
            },
            {
                "name": "Refrontolo",
                "countryCode": "IT"
            },
            {
                "name": "Resana",
                "countryCode": "IT"
            },
            {
                "name": "Revine",
                "countryCode": "IT"
            },
            {
                "name": "Riese Pio X",
                "countryCode": "IT"
            },
            {
                "name": "Rivalta",
                "countryCode": "IT"
            },
            {
                "name": "Rivamonte Agordino",
                "countryCode": "IT"
            },
            {
                "name": "Rivoli Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Roana",
                "countryCode": "IT"
            },
            {
                "name": "Robegano",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Pietore",
                "countryCode": "IT"
            },
            {
                "name": "Romano d'Ezzelino",
                "countryCode": "IT"
            },
            {
                "name": "Roncade",
                "countryCode": "IT"
            },
            {
                "name": "Roncanova",
                "countryCode": "IT"
            },
            {
                "name": "Ronchi di Campanile",
                "countryCode": "IT"
            },
            {
                "name": "Ronco All'Adige",
                "countryCode": "IT"
            },
            {
                "name": "Roncà",
                "countryCode": "IT"
            },
            {
                "name": "Rosegaferro",
                "countryCode": "IT"
            },
            {
                "name": "Rosolina",
                "countryCode": "IT"
            },
            {
                "name": "Rossano Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Rosà",
                "countryCode": "IT"
            },
            {
                "name": "Rottanova",
                "countryCode": "IT"
            },
            {
                "name": "Rotzo",
                "countryCode": "IT"
            },
            {
                "name": "Roverchiara",
                "countryCode": "IT"
            },
            {
                "name": "Roveredo di Guà",
                "countryCode": "IT"
            },
            {
                "name": "Roverè Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Rovigo",
                "countryCode": "IT"
            },
            {
                "name": "Rubano",
                "countryCode": "IT"
            },
            {
                "name": "Saccolongo",
                "countryCode": "IT"
            },
            {
                "name": "Sachet",
                "countryCode": "IT"
            },
            {
                "name": "Salara",
                "countryCode": "IT"
            },
            {
                "name": "Salcedo",
                "countryCode": "IT"
            },
            {
                "name": "Saletto",
                "countryCode": "IT"
            },
            {
                "name": "Saletto-San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "Salgareda",
                "countryCode": "IT"
            },
            {
                "name": "Salionze",
                "countryCode": "IT"
            },
            {
                "name": "Salizzole",
                "countryCode": "IT"
            },
            {
                "name": "Salvatronda",
                "countryCode": "IT"
            },
            {
                "name": "Salzano",
                "countryCode": "IT"
            },
            {
                "name": "Sambruson",
                "countryCode": "IT"
            },
            {
                "name": "Sambughe",
                "countryCode": "IT"
            },
            {
                "name": "San Bellino",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio di Callalta",
                "countryCode": "IT"
            },
            {
                "name": "San Bonifacio",
                "countryCode": "IT"
            },
            {
                "name": "San Donà di Piave",
                "countryCode": "IT"
            },
            {
                "name": "San Fidenzio",
                "countryCode": "IT"
            },
            {
                "name": "San Fior di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "San Floriano",
                "countryCode": "IT"
            },
            {
                "name": "San Floriano-Olmi",
                "countryCode": "IT"
            },
            {
                "name": "San Germano dei Berici",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio al Tagliamento-Pozzi",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio delle Pertiche",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio di Livenza",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio in Bosco",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio in Salici",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Ilarione",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Lupatoto",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio nelle Alpi",
                "countryCode": "IT"
            },
            {
                "name": "San Liberale",
                "countryCode": "IT"
            },
            {
                "name": "San Martino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Buon Albergo",
                "countryCode": "IT"
            },
            {
                "name": "San Martino di Lupari",
                "countryCode": "IT"
            },
            {
                "name": "San Martino di Venezze",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro di Saline",
                "countryCode": "IT"
            },
            {
                "name": "San Michele al Tagliamento",
                "countryCode": "IT"
            },
            {
                "name": "San Michele di Piave",
                "countryCode": "IT"
            },
            {
                "name": "San Nazario",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò Comelico",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Mussolino",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Valdastico",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Viminario",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro di Feletto",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro di Morubio",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Cariano",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Gu",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Volta",
                "countryCode": "IT"
            },
            {
                "name": "San Polo di Piave",
                "countryCode": "IT"
            },
            {
                "name": "San Quirico",
                "countryCode": "IT"
            },
            {
                "name": "San Stino di Livenza",
                "countryCode": "IT"
            },
            {
                "name": "San Vendemiano",
                "countryCode": "IT"
            },
            {
                "name": "San Vendemiano-Fossamerlo",
                "countryCode": "IT"
            },
            {
                "name": "San Vito",
                "countryCode": "IT"
            },
            {
                "name": "San Vito al Mantico",
                "countryCode": "IT"
            },
            {
                "name": "San Vito di Leguzzano",
                "countryCode": "IT"
            },
            {
                "name": "San Zeno",
                "countryCode": "IT"
            },
            {
                "name": "San Zeno di Montagna",
                "countryCode": "IT"
            },
            {
                "name": "San Zeno-San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "San Zenone degli Ezzelini",
                "countryCode": "IT"
            },
            {
                "name": "Sandrigo",
                "countryCode": "IT"
            },
            {
                "name": "Sandrà",
                "countryCode": "IT"
            },
            {
                "name": "Sanguinetto",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alberto",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ambrogio di Valpollicella",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo di Piove di Sacco",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Anna",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Anna d'Alfaedo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Apollinare",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elena",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cristina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Giustina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Giustina in Colle",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Santa Margherita d'Adige",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Maddalena",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria di Non",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria di Sala",
                "countryCode": "IT"
            },
            {
                "name": "Santandrà",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano-Bonaldo",
                "countryCode": "IT"
            },
            {
                "name": "Santorso",
                "countryCode": "IT"
            },
            {
                "name": "Saonara",
                "countryCode": "IT"
            },
            {
                "name": "Sarcedo",
                "countryCode": "IT"
            },
            {
                "name": "Sarego",
                "countryCode": "IT"
            },
            {
                "name": "Sarmede",
                "countryCode": "IT"
            },
            {
                "name": "Sarmeola",
                "countryCode": "IT"
            },
            {
                "name": "Scaltenigo",
                "countryCode": "IT"
            },
            {
                "name": "Scardovari",
                "countryCode": "IT"
            },
            {
                "name": "Schiavon",
                "countryCode": "IT"
            },
            {
                "name": "Schio",
                "countryCode": "IT"
            },
            {
                "name": "Scomigo",
                "countryCode": "IT"
            },
            {
                "name": "Scorzè",
                "countryCode": "IT"
            },
            {
                "name": "Sedico",
                "countryCode": "IT"
            },
            {
                "name": "Segusino",
                "countryCode": "IT"
            },
            {
                "name": "Selva del Montello",
                "countryCode": "IT"
            },
            {
                "name": "Selva di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Selva di Progno",
                "countryCode": "IT"
            },
            {
                "name": "Selvazzano Dentro",
                "countryCode": "IT"
            },
            {
                "name": "Seren del Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Sernaglia della Battaglia",
                "countryCode": "IT"
            },
            {
                "name": "Servo",
                "countryCode": "IT"
            },
            {
                "name": "Settimo",
                "countryCode": "IT"
            },
            {
                "name": "Silea",
                "countryCode": "IT"
            },
            {
                "name": "Soave",
                "countryCode": "IT"
            },
            {
                "name": "Soccher-Paiane-Casan-Arsie",
                "countryCode": "IT"
            },
            {
                "name": "Solagna",
                "countryCode": "IT"
            },
            {
                "name": "Solesino",
                "countryCode": "IT"
            },
            {
                "name": "Sommacampagna",
                "countryCode": "IT"
            },
            {
                "name": "Sona",
                "countryCode": "IT"
            },
            {
                "name": "Sorgà",
                "countryCode": "IT"
            },
            {
                "name": "Sospirolo",
                "countryCode": "IT"
            },
            {
                "name": "Sossano",
                "countryCode": "IT"
            },
            {
                "name": "Soverzene",
                "countryCode": "IT"
            },
            {
                "name": "Sovizzo",
                "countryCode": "IT"
            },
            {
                "name": "Sovramonte",
                "countryCode": "IT"
            },
            {
                "name": "Spinea-Orgnano",
                "countryCode": "IT"
            },
            {
                "name": "Spinimbecco",
                "countryCode": "IT"
            },
            {
                "name": "Spresiano",
                "countryCode": "IT"
            },
            {
                "name": "Stallavena-Lugo",
                "countryCode": "IT"
            },
            {
                "name": "Stanghella",
                "countryCode": "IT"
            },
            {
                "name": "Stienta",
                "countryCode": "IT"
            },
            {
                "name": "Stigliano",
                "countryCode": "IT"
            },
            {
                "name": "Stra",
                "countryCode": "IT"
            },
            {
                "name": "Strà-Montanara-Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Summaga",
                "countryCode": "IT"
            },
            {
                "name": "Susegana",
                "countryCode": "IT"
            },
            {
                "name": "Taggì",
                "countryCode": "IT"
            },
            {
                "name": "Taglio",
                "countryCode": "IT"
            },
            {
                "name": "Taglio di Po",
                "countryCode": "IT"
            },
            {
                "name": "Taibon Agordino",
                "countryCode": "IT"
            },
            {
                "name": "Talponada",
                "countryCode": "IT"
            },
            {
                "name": "Tambre",
                "countryCode": "IT"
            },
            {
                "name": "Tarzo",
                "countryCode": "IT"
            },
            {
                "name": "Tavernelle",
                "countryCode": "IT"
            },
            {
                "name": "Tavo",
                "countryCode": "IT"
            },
            {
                "name": "Teglio Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Tencarola",
                "countryCode": "IT"
            },
            {
                "name": "Terradura",
                "countryCode": "IT"
            },
            {
                "name": "Terrassa Padovana",
                "countryCode": "IT"
            },
            {
                "name": "Terrazzo",
                "countryCode": "IT"
            },
            {
                "name": "Terrossa",
                "countryCode": "IT"
            },
            {
                "name": "Tessera",
                "countryCode": "IT"
            },
            {
                "name": "Tezze",
                "countryCode": "IT"
            },
            {
                "name": "Thiene",
                "countryCode": "IT"
            },
            {
                "name": "Tombelle",
                "countryCode": "IT"
            },
            {
                "name": "Tombolo",
                "countryCode": "IT"
            },
            {
                "name": "Tonezza del Cimone",
                "countryCode": "IT"
            },
            {
                "name": "Torre di Mosto",
                "countryCode": "IT"
            },
            {
                "name": "Torrebelvicino",
                "countryCode": "IT"
            },
            {
                "name": "Torreglia",
                "countryCode": "IT"
            },
            {
                "name": "Torreselle",
                "countryCode": "IT"
            },
            {
                "name": "Torri del Benaco",
                "countryCode": "IT"
            },
            {
                "name": "Torri di Quartesolo",
                "countryCode": "IT"
            },
            {
                "name": "Trebaseleghe",
                "countryCode": "IT"
            },
            {
                "name": "Trecenta",
                "countryCode": "IT"
            },
            {
                "name": "Tregnago",
                "countryCode": "IT"
            },
            {
                "name": "Tremignon",
                "countryCode": "IT"
            },
            {
                "name": "Treponti",
                "countryCode": "IT"
            },
            {
                "name": "Trevenzuolo",
                "countryCode": "IT"
            },
            {
                "name": "Trevignano",
                "countryCode": "IT"
            },
            {
                "name": "Treviso",
                "countryCode": "IT"
            },
            {
                "name": "Tribano",
                "countryCode": "IT"
            },
            {
                "name": "Trichiana",
                "countryCode": "IT"
            },
            {
                "name": "Trissino",
                "countryCode": "IT"
            },
            {
                "name": "Trivignano",
                "countryCode": "IT"
            },
            {
                "name": "Urbana",
                "countryCode": "IT"
            },
            {
                "name": "Vago",
                "countryCode": "IT"
            },
            {
                "name": "Valdagno",
                "countryCode": "IT"
            },
            {
                "name": "Valdobbiadene",
                "countryCode": "IT"
            },
            {
                "name": "Valeggio sul Mincio",
                "countryCode": "IT"
            },
            {
                "name": "Valgatara",
                "countryCode": "IT"
            },
            {
                "name": "Vallada Agordina",
                "countryCode": "IT"
            },
            {
                "name": "Valle di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Vallese",
                "countryCode": "IT"
            },
            {
                "name": "Valli",
                "countryCode": "IT"
            },
            {
                "name": "Valli del Pasubio",
                "countryCode": "IT"
            },
            {
                "name": "Vallonga",
                "countryCode": "IT"
            },
            {
                "name": "Vallà",
                "countryCode": "IT"
            },
            {
                "name": "Valstagna",
                "countryCode": "IT"
            },
            {
                "name": "Varago",
                "countryCode": "IT"
            },
            {
                "name": "Vas",
                "countryCode": "IT"
            },
            {
                "name": "Vazzola",
                "countryCode": "IT"
            },
            {
                "name": "Vedelago",
                "countryCode": "IT"
            },
            {
                "name": "Veggiano",
                "countryCode": "IT"
            },
            {
                "name": "Velo",
                "countryCode": "IT"
            },
            {
                "name": "Velo Veronese",
                "countryCode": "IT"
            },
            {
                "name": "Velo d'Astico",
                "countryCode": "IT"
            },
            {
                "name": "Venegazzù",
                "countryCode": "IT"
            },
            {
                "name": "Venice",
                "countryCode": "IT"
            },
            {
                "name": "Verona",
                "countryCode": "IT"
            },
            {
                "name": "Veronella",
                "countryCode": "IT"
            },
            {
                "name": "Vescovana",
                "countryCode": "IT"
            },
            {
                "name": "Vestenanova",
                "countryCode": "IT"
            },
            {
                "name": "Veternigo",
                "countryCode": "IT"
            },
            {
                "name": "Vicenza",
                "countryCode": "IT"
            },
            {
                "name": "Vidor",
                "countryCode": "IT"
            },
            {
                "name": "Vigardolo",
                "countryCode": "IT"
            },
            {
                "name": "Vigasio",
                "countryCode": "IT"
            },
            {
                "name": "Vighizzolo d'Este",
                "countryCode": "IT"
            },
            {
                "name": "Vigo di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Vigodarzere",
                "countryCode": "IT"
            },
            {
                "name": "Vigonovo",
                "countryCode": "IT"
            },
            {
                "name": "Vigonza",
                "countryCode": "IT"
            },
            {
                "name": "Villa Bartolomea",
                "countryCode": "IT"
            },
            {
                "name": "Villa Estense",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Asolo",
                "countryCode": "IT"
            },
            {
                "name": "Villa del Conte",
                "countryCode": "IT"
            },
            {
                "name": "Villabruna-Umin",
                "countryCode": "IT"
            },
            {
                "name": "Villadose",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca Padovana",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca di Verona",
                "countryCode": "IT"
            },
            {
                "name": "Villaga",
                "countryCode": "IT"
            },
            {
                "name": "Villaganzerla",
                "countryCode": "IT"
            },
            {
                "name": "Villaggio Montegrappa",
                "countryCode": "IT"
            },
            {
                "name": "Villaguattera",
                "countryCode": "IT"
            },
            {
                "name": "Villamarzana",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Marchesana",
                "countryCode": "IT"
            },
            {
                "name": "Villanova del Ghebbo Canton",
                "countryCode": "IT"
            },
            {
                "name": "Villatora",
                "countryCode": "IT"
            },
            {
                "name": "Villaverla",
                "countryCode": "IT"
            },
            {
                "name": "Vittorio Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Vodo",
                "countryCode": "IT"
            },
            {
                "name": "Volargne",
                "countryCode": "IT"
            },
            {
                "name": "Volpago del Montello",
                "countryCode": "IT"
            },
            {
                "name": "Voltago",
                "countryCode": "IT"
            },
            {
                "name": "Voltago Agordino",
                "countryCode": "IT"
            },
            {
                "name": "Vò",
                "countryCode": "IT"
            },
            {
                "name": "Zanè",
                "countryCode": "IT"
            },
            {
                "name": "Zelarino",
                "countryCode": "IT"
            },
            {
                "name": "Zenson di Piave",
                "countryCode": "IT"
            },
            {
                "name": "Zermeghedo",
                "countryCode": "IT"
            },
            {
                "name": "Zero Branco",
                "countryCode": "IT"
            },
            {
                "name": "Zevio",
                "countryCode": "IT"
            },
            {
                "name": "Zoppè di Cadore",
                "countryCode": "IT"
            },
            {
                "name": "Zovencedo",
                "countryCode": "IT"
            },
            {
                "name": "Zugliano",
                "countryCode": "IT"
            },
            {
                "name": "Aiello del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Amaro",
                "countryCode": "IT"
            },
            {
                "name": "Ampezzo",
                "countryCode": "IT"
            },
            {
                "name": "Andreis",
                "countryCode": "IT"
            },
            {
                "name": "Anduins",
                "countryCode": "IT"
            },
            {
                "name": "Aquileia",
                "countryCode": "IT"
            },
            {
                "name": "Arba",
                "countryCode": "IT"
            },
            {
                "name": "Arta Terme",
                "countryCode": "IT"
            },
            {
                "name": "Artegna",
                "countryCode": "IT"
            },
            {
                "name": "Arzene",
                "countryCode": "IT"
            },
            {
                "name": "Attimis",
                "countryCode": "IT"
            },
            {
                "name": "Aurisina",
                "countryCode": "IT"
            },
            {
                "name": "Aviano",
                "countryCode": "IT"
            },
            {
                "name": "Aviano-Castello",
                "countryCode": "IT"
            },
            {
                "name": "Azzano Decimo",
                "countryCode": "IT"
            },
            {
                "name": "Bagnaria Arsa",
                "countryCode": "IT"
            },
            {
                "name": "Bagnarola",
                "countryCode": "IT"
            },
            {
                "name": "Barcis",
                "countryCode": "IT"
            },
            {
                "name": "Basaldella",
                "countryCode": "IT"
            },
            {
                "name": "Basiliano",
                "countryCode": "IT"
            },
            {
                "name": "Basiliano-Vissandone",
                "countryCode": "IT"
            },
            {
                "name": "Begliano",
                "countryCode": "IT"
            },
            {
                "name": "Bertiolo",
                "countryCode": "IT"
            },
            {
                "name": "Bicinicco",
                "countryCode": "IT"
            },
            {
                "name": "Bordano",
                "countryCode": "IT"
            },
            {
                "name": "Bressa",
                "countryCode": "IT"
            },
            {
                "name": "Brugnera",
                "countryCode": "IT"
            },
            {
                "name": "Budoia",
                "countryCode": "IT"
            },
            {
                "name": "Buia",
                "countryCode": "IT"
            },
            {
                "name": "Buttrio",
                "countryCode": "IT"
            },
            {
                "name": "Camino al Tagliamento",
                "countryCode": "IT"
            },
            {
                "name": "Campoformido",
                "countryCode": "IT"
            },
            {
                "name": "Campolongo al Torre",
                "countryCode": "IT"
            },
            {
                "name": "Campolongo al Torre-Cavenzano",
                "countryCode": "IT"
            },
            {
                "name": "Caneva",
                "countryCode": "IT"
            },
            {
                "name": "Capriva del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Carlino",
                "countryCode": "IT"
            },
            {
                "name": "Casarsa della Delizia",
                "countryCode": "IT"
            },
            {
                "name": "Cassacco",
                "countryCode": "IT"
            },
            {
                "name": "Castions",
                "countryCode": "IT"
            },
            {
                "name": "Castions di Strada",
                "countryCode": "IT"
            },
            {
                "name": "Cavasso Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Cavazzo Carnico",
                "countryCode": "IT"
            },
            {
                "name": "Cavolano-Schiavoi",
                "countryCode": "IT"
            },
            {
                "name": "Cecchini",
                "countryCode": "IT"
            },
            {
                "name": "Cercivento",
                "countryCode": "IT"
            },
            {
                "name": "Cervignano del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Chiaulis",
                "countryCode": "IT"
            },
            {
                "name": "Chions",
                "countryCode": "IT"
            },
            {
                "name": "Chiopris",
                "countryCode": "IT"
            },
            {
                "name": "Chiusaforte",
                "countryCode": "IT"
            },
            {
                "name": "Cimolais",
                "countryCode": "IT"
            },
            {
                "name": "Cividale del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Claut",
                "countryCode": "IT"
            },
            {
                "name": "Clauzetto",
                "countryCode": "IT"
            },
            {
                "name": "Clodig",
                "countryCode": "IT"
            },
            {
                "name": "Codroipo",
                "countryCode": "IT"
            },
            {
                "name": "Colloredo di Monte Albano",
                "countryCode": "IT"
            },
            {
                "name": "Colloredo di Monte Albano-Lauzzana",
                "countryCode": "IT"
            },
            {
                "name": "Colloredo di Prato",
                "countryCode": "IT"
            },
            {
                "name": "Colugna",
                "countryCode": "IT"
            },
            {
                "name": "Comeglians",
                "countryCode": "IT"
            },
            {
                "name": "Cordenons",
                "countryCode": "IT"
            },
            {
                "name": "Cordovado",
                "countryCode": "IT"
            },
            {
                "name": "Cormons",
                "countryCode": "IT"
            },
            {
                "name": "Corno di Rosazzo",
                "countryCode": "IT"
            },
            {
                "name": "Coseano",
                "countryCode": "IT"
            },
            {
                "name": "Cras",
                "countryCode": "IT"
            },
            {
                "name": "Dignano",
                "countryCode": "IT"
            },
            {
                "name": "Doberdò del Lago",
                "countryCode": "IT"
            },
            {
                "name": "Dogna",
                "countryCode": "IT"
            },
            {
                "name": "Dolegna del Collio",
                "countryCode": "IT"
            },
            {
                "name": "Dolina",
                "countryCode": "IT"
            },
            {
                "name": "Domio",
                "countryCode": "IT"
            },
            {
                "name": "Duino",
                "countryCode": "IT"
            },
            {
                "name": "Enemonzo-Quinis",
                "countryCode": "IT"
            },
            {
                "name": "Erto",
                "countryCode": "IT"
            },
            {
                "name": "Faedis",
                "countryCode": "IT"
            },
            {
                "name": "Fagagna",
                "countryCode": "IT"
            },
            {
                "name": "Fanna",
                "countryCode": "IT"
            },
            {
                "name": "Farra d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "Fiume Veneto",
                "countryCode": "IT"
            },
            {
                "name": "Fiumicello",
                "countryCode": "IT"
            },
            {
                "name": "Flaibano",
                "countryCode": "IT"
            },
            {
                "name": "Fogliano",
                "countryCode": "IT"
            },
            {
                "name": "Forgaria nel Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Forni Avoltri",
                "countryCode": "IT"
            },
            {
                "name": "Forni di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Forni di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Frisanco",
                "countryCode": "IT"
            },
            {
                "name": "Gemona",
                "countryCode": "IT"
            },
            {
                "name": "Gonars",
                "countryCode": "IT"
            },
            {
                "name": "Gorizia",
                "countryCode": "IT"
            },
            {
                "name": "Gradisca d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "Grado",
                "countryCode": "IT"
            },
            {
                "name": "Granvilla",
                "countryCode": "IT"
            },
            {
                "name": "Ialmicco",
                "countryCode": "IT"
            },
            {
                "name": "Isola",
                "countryCode": "IT"
            },
            {
                "name": "Latisana",
                "countryCode": "IT"
            },
            {
                "name": "Lauco",
                "countryCode": "IT"
            },
            {
                "name": "Lauzacco",
                "countryCode": "IT"
            },
            {
                "name": "Lestans",
                "countryCode": "IT"
            },
            {
                "name": "Lestizza",
                "countryCode": "IT"
            },
            {
                "name": "Lignano Sabbiadoro",
                "countryCode": "IT"
            },
            {
                "name": "Ligosullo",
                "countryCode": "IT"
            },
            {
                "name": "Lusevera",
                "countryCode": "IT"
            },
            {
                "name": "Magnano in Riviera",
                "countryCode": "IT"
            },
            {
                "name": "Majano",
                "countryCode": "IT"
            },
            {
                "name": "Malborghetto",
                "countryCode": "IT"
            },
            {
                "name": "Maniago",
                "countryCode": "IT"
            },
            {
                "name": "Manzano",
                "countryCode": "IT"
            },
            {
                "name": "Marano Lagunare",
                "countryCode": "IT"
            },
            {
                "name": "Mariano del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Marsure",
                "countryCode": "IT"
            },
            {
                "name": "Martignacco",
                "countryCode": "IT"
            },
            {
                "name": "Medea",
                "countryCode": "IT"
            },
            {
                "name": "Mediis",
                "countryCode": "IT"
            },
            {
                "name": "Meduno",
                "countryCode": "IT"
            },
            {
                "name": "Mereto di Tomba",
                "countryCode": "IT"
            },
            {
                "name": "Merso di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Moggio Udinese",
                "countryCode": "IT"
            },
            {
                "name": "Moggio di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Moimacco",
                "countryCode": "IT"
            },
            {
                "name": "Monfalcone",
                "countryCode": "IT"
            },
            {
                "name": "Montenars",
                "countryCode": "IT"
            },
            {
                "name": "Montereale Valcellina",
                "countryCode": "IT"
            },
            {
                "name": "Moraro",
                "countryCode": "IT"
            },
            {
                "name": "Morsano",
                "countryCode": "IT"
            },
            {
                "name": "Mortegliano",
                "countryCode": "IT"
            },
            {
                "name": "Moruzzo",
                "countryCode": "IT"
            },
            {
                "name": "Mossa",
                "countryCode": "IT"
            },
            {
                "name": "Muggia",
                "countryCode": "IT"
            },
            {
                "name": "Muzzana del Turgnano",
                "countryCode": "IT"
            },
            {
                "name": "Nimis",
                "countryCode": "IT"
            },
            {
                "name": "Orcenico Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Orsaria",
                "countryCode": "IT"
            },
            {
                "name": "Osoppo",
                "countryCode": "IT"
            },
            {
                "name": "Ovaro",
                "countryCode": "IT"
            },
            {
                "name": "Pagnacco",
                "countryCode": "IT"
            },
            {
                "name": "Palazzolo dello Stella",
                "countryCode": "IT"
            },
            {
                "name": "Palmanova",
                "countryCode": "IT"
            },
            {
                "name": "Paludea",
                "countryCode": "IT"
            },
            {
                "name": "Paluzza",
                "countryCode": "IT"
            },
            {
                "name": "Pasian di Prato",
                "countryCode": "IT"
            },
            {
                "name": "Pasiano",
                "countryCode": "IT"
            },
            {
                "name": "Passons",
                "countryCode": "IT"
            },
            {
                "name": "Paularo",
                "countryCode": "IT"
            },
            {
                "name": "Pavia di Udine",
                "countryCode": "IT"
            },
            {
                "name": "Percoto",
                "countryCode": "IT"
            },
            {
                "name": "Pertegada",
                "countryCode": "IT"
            },
            {
                "name": "Pieria-Prato Carnico",
                "countryCode": "IT"
            },
            {
                "name": "Pieris",
                "countryCode": "IT"
            },
            {
                "name": "Pinzano al Tagliamento",
                "countryCode": "IT"
            },
            {
                "name": "Pocenia",
                "countryCode": "IT"
            },
            {
                "name": "Polcenigo",
                "countryCode": "IT"
            },
            {
                "name": "Pontebba",
                "countryCode": "IT"
            },
            {
                "name": "Porcia",
                "countryCode": "IT"
            },
            {
                "name": "Pordenone",
                "countryCode": "IT"
            },
            {
                "name": "Porpetto",
                "countryCode": "IT"
            },
            {
                "name": "Povoletto",
                "countryCode": "IT"
            },
            {
                "name": "Pozzuolo del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "Pradamano",
                "countryCode": "IT"
            },
            {
                "name": "Prata di Pordenone",
                "countryCode": "IT"
            },
            {
                "name": "Prato",
                "countryCode": "IT"
            },
            {
                "name": "Prato Carnico",
                "countryCode": "IT"
            },
            {
                "name": "Pravisdomini",
                "countryCode": "IT"
            },
            {
                "name": "Precenicco",
                "countryCode": "IT"
            },
            {
                "name": "Premariacco",
                "countryCode": "IT"
            },
            {
                "name": "Preone",
                "countryCode": "IT"
            },
            {
                "name": "Prepotto",
                "countryCode": "IT"
            },
            {
                "name": "Prosecco-Contovello",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Gorizia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Pordenone",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Trieste",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Udine",
                "countryCode": "IT"
            },
            {
                "name": "Puia-Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Pulfero",
                "countryCode": "IT"
            },
            {
                "name": "Ramuscello",
                "countryCode": "IT"
            },
            {
                "name": "Rauscedo-Domanins",
                "countryCode": "IT"
            },
            {
                "name": "Ravascletto",
                "countryCode": "IT"
            },
            {
                "name": "Raveo",
                "countryCode": "IT"
            },
            {
                "name": "Reana del Roiale",
                "countryCode": "IT"
            },
            {
                "name": "Reana del Rojale",
                "countryCode": "IT"
            },
            {
                "name": "Remanzacco",
                "countryCode": "IT"
            },
            {
                "name": "Resiutta",
                "countryCode": "IT"
            },
            {
                "name": "Rigolato",
                "countryCode": "IT"
            },
            {
                "name": "Rive d'Arcano",
                "countryCode": "IT"
            },
            {
                "name": "Rivignano",
                "countryCode": "IT"
            },
            {
                "name": "Rodeano",
                "countryCode": "IT"
            },
            {
                "name": "Romans d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "Ronchi dei Legionari",
                "countryCode": "IT"
            },
            {
                "name": "Ronchis",
                "countryCode": "IT"
            },
            {
                "name": "Rosa",
                "countryCode": "IT"
            },
            {
                "name": "Roveredo in Piano",
                "countryCode": "IT"
            },
            {
                "name": "Ruda",
                "countryCode": "IT"
            },
            {
                "name": "Sacile",
                "countryCode": "IT"
            },
            {
                "name": "Sagrado",
                "countryCode": "IT"
            },
            {
                "name": "San Canzian d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "San Daniele del Friuli",
                "countryCode": "IT"
            },
            {
                "name": "San Floriano del Collio",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio della Richinvelda",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio di Nogaro",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni al Natisone",
                "countryCode": "IT"
            },
            {
                "name": "San Leonardo",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo Isontino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino al Tagliamento",
                "countryCode": "IT"
            },
            {
                "name": "San Pier d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro al Natisone",
                "countryCode": "IT"
            },
            {
                "name": "San Quirino",
                "countryCode": "IT"
            },
            {
                "name": "San Valentino",
                "countryCode": "IT"
            },
            {
                "name": "San Vito al Tagliamento",
                "countryCode": "IT"
            },
            {
                "name": "San Vito al Torre",
                "countryCode": "IT"
            },
            {
                "name": "San Vito di Fagagna",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria la Longa",
                "countryCode": "IT"
            },
            {
                "name": "Sappada",
                "countryCode": "IT"
            },
            {
                "name": "Sauris di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Savogna",
                "countryCode": "IT"
            },
            {
                "name": "Savogna d'Isonzo",
                "countryCode": "IT"
            },
            {
                "name": "Sedegliano",
                "countryCode": "IT"
            },
            {
                "name": "Sequals",
                "countryCode": "IT"
            },
            {
                "name": "Sesto al Reghena",
                "countryCode": "IT"
            },
            {
                "name": "Sevegliano",
                "countryCode": "IT"
            },
            {
                "name": "Sgonico",
                "countryCode": "IT"
            },
            {
                "name": "Sistiana-Visogliano",
                "countryCode": "IT"
            },
            {
                "name": "Sottoselva",
                "countryCode": "IT"
            },
            {
                "name": "Spilimbergo",
                "countryCode": "IT"
            },
            {
                "name": "Staranzano",
                "countryCode": "IT"
            },
            {
                "name": "Stregna",
                "countryCode": "IT"
            },
            {
                "name": "Sutrio",
                "countryCode": "IT"
            },
            {
                "name": "Taipana",
                "countryCode": "IT"
            },
            {
                "name": "Talmassons",
                "countryCode": "IT"
            },
            {
                "name": "Tamai",
                "countryCode": "IT"
            },
            {
                "name": "Tarcento",
                "countryCode": "IT"
            },
            {
                "name": "Tarvisio",
                "countryCode": "IT"
            },
            {
                "name": "Tavagnacco",
                "countryCode": "IT"
            },
            {
                "name": "Teor",
                "countryCode": "IT"
            },
            {
                "name": "Terzo d'Aquileia",
                "countryCode": "IT"
            },
            {
                "name": "Tolmezzo",
                "countryCode": "IT"
            },
            {
                "name": "Torreano",
                "countryCode": "IT"
            },
            {
                "name": "Torviscosa",
                "countryCode": "IT"
            },
            {
                "name": "Tramonti di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Tramonti di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Trasaghis",
                "countryCode": "IT"
            },
            {
                "name": "Travesio",
                "countryCode": "IT"
            },
            {
                "name": "Treppo Carnico",
                "countryCode": "IT"
            },
            {
                "name": "Treppo Grande",
                "countryCode": "IT"
            },
            {
                "name": "Tricesimo",
                "countryCode": "IT"
            },
            {
                "name": "Trieste",
                "countryCode": "IT"
            },
            {
                "name": "Trivignano Udinese",
                "countryCode": "IT"
            },
            {
                "name": "Turriaco",
                "countryCode": "IT"
            },
            {
                "name": "Udine",
                "countryCode": "IT"
            },
            {
                "name": "Vajont",
                "countryCode": "IT"
            },
            {
                "name": "Valvasone",
                "countryCode": "IT"
            },
            {
                "name": "Varmo",
                "countryCode": "IT"
            },
            {
                "name": "Venzone",
                "countryCode": "IT"
            },
            {
                "name": "Vigonovo-Fontanafredda",
                "countryCode": "IT"
            },
            {
                "name": "Villa Opicina",
                "countryCode": "IT"
            },
            {
                "name": "Villa Santina",
                "countryCode": "IT"
            },
            {
                "name": "Villa Vicentina",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Villesse",
                "countryCode": "IT"
            },
            {
                "name": "Villotta",
                "countryCode": "IT"
            },
            {
                "name": "Visco",
                "countryCode": "IT"
            },
            {
                "name": "Vivaro",
                "countryCode": "IT"
            },
            {
                "name": "Zolla",
                "countryCode": "IT"
            },
            {
                "name": "Zoppola",
                "countryCode": "IT"
            },
            {
                "name": "Zugliano-Terenzano-Cargnacco",
                "countryCode": "IT"
            },
            {
                "name": "Zuglio",
                "countryCode": "IT"
            },
            {
                "name": "Airole",
                "countryCode": "IT"
            },
            {
                "name": "Alassio",
                "countryCode": "IT"
            },
            {
                "name": "Albenga",
                "countryCode": "IT"
            },
            {
                "name": "Albisola Marina",
                "countryCode": "IT"
            },
            {
                "name": "Albisola Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Altare",
                "countryCode": "IT"
            },
            {
                "name": "Ameglia",
                "countryCode": "IT"
            },
            {
                "name": "Andora",
                "countryCode": "IT"
            },
            {
                "name": "Apricale",
                "countryCode": "IT"
            },
            {
                "name": "Aquila di Arroscia",
                "countryCode": "IT"
            },
            {
                "name": "Arcola",
                "countryCode": "IT"
            },
            {
                "name": "Arenzano",
                "countryCode": "IT"
            },
            {
                "name": "Armo",
                "countryCode": "IT"
            },
            {
                "name": "Arnasco",
                "countryCode": "IT"
            },
            {
                "name": "Aurigo",
                "countryCode": "IT"
            },
            {
                "name": "Avegno",
                "countryCode": "IT"
            },
            {
                "name": "Badalucco",
                "countryCode": "IT"
            },
            {
                "name": "Bajardo",
                "countryCode": "IT"
            },
            {
                "name": "Balestrino",
                "countryCode": "IT"
            },
            {
                "name": "Bardineto",
                "countryCode": "IT"
            },
            {
                "name": "Bargagli",
                "countryCode": "IT"
            },
            {
                "name": "Bergeggi",
                "countryCode": "IT"
            },
            {
                "name": "Beverino",
                "countryCode": "IT"
            },
            {
                "name": "Bocco",
                "countryCode": "IT"
            },
            {
                "name": "Bogliasco",
                "countryCode": "IT"
            },
            {
                "name": "Boissano",
                "countryCode": "IT"
            },
            {
                "name": "Bolano",
                "countryCode": "IT"
            },
            {
                "name": "Bonassola",
                "countryCode": "IT"
            },
            {
                "name": "Bordighera",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto Santo Spirito",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto d'Arroscia",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto di Vara",
                "countryCode": "IT"
            },
            {
                "name": "Borgio",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Fornari-Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Borgo di Ranzo",
                "countryCode": "IT"
            },
            {
                "name": "Borgomaro",
                "countryCode": "IT"
            },
            {
                "name": "Bormida",
                "countryCode": "IT"
            },
            {
                "name": "Bormida-Genepro",
                "countryCode": "IT"
            },
            {
                "name": "Borzonasca",
                "countryCode": "IT"
            },
            {
                "name": "Brugnato",
                "countryCode": "IT"
            },
            {
                "name": "Busalla",
                "countryCode": "IT"
            },
            {
                "name": "Cairo Montenotte",
                "countryCode": "IT"
            },
            {
                "name": "Calice Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Calice al Cornoviglio",
                "countryCode": "IT"
            },
            {
                "name": "Calizzano",
                "countryCode": "IT"
            },
            {
                "name": "Camogli",
                "countryCode": "IT"
            },
            {
                "name": "Campo Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Campochiesa",
                "countryCode": "IT"
            },
            {
                "name": "Campomorone",
                "countryCode": "IT"
            },
            {
                "name": "Camporosso",
                "countryCode": "IT"
            },
            {
                "name": "Carasco",
                "countryCode": "IT"
            },
            {
                "name": "Caravonica",
                "countryCode": "IT"
            },
            {
                "name": "Carcare",
                "countryCode": "IT"
            },
            {
                "name": "Carpasio",
                "countryCode": "IT"
            },
            {
                "name": "Carro",
                "countryCode": "IT"
            },
            {
                "name": "Carrodano",
                "countryCode": "IT"
            },
            {
                "name": "Casano-Dogana-Isola",
                "countryCode": "IT"
            },
            {
                "name": "Casanova Lerrone",
                "countryCode": "IT"
            },
            {
                "name": "Casarza Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Casavecchia",
                "countryCode": "IT"
            },
            {
                "name": "Casella",
                "countryCode": "IT"
            },
            {
                "name": "Castel Vittorio",
                "countryCode": "IT"
            },
            {
                "name": "Castelbianco",
                "countryCode": "IT"
            },
            {
                "name": "Castellaro",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Magra",
                "countryCode": "IT"
            },
            {
                "name": "Castelvecchio di Rocca Barbena",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione",
                "countryCode": "IT"
            },
            {
                "name": "Celle Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Cengio Alto",
                "countryCode": "IT"
            },
            {
                "name": "Ceparana-Carpena",
                "countryCode": "IT"
            },
            {
                "name": "Ceranesi",
                "countryCode": "IT"
            },
            {
                "name": "Ceriale",
                "countryCode": "IT"
            },
            {
                "name": "Ceriana",
                "countryCode": "IT"
            },
            {
                "name": "Cervo",
                "countryCode": "IT"
            },
            {
                "name": "Cesio",
                "countryCode": "IT"
            },
            {
                "name": "Chiavari",
                "countryCode": "IT"
            },
            {
                "name": "Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Chiesa Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Chiusanico",
                "countryCode": "IT"
            },
            {
                "name": "Chiusavecchia",
                "countryCode": "IT"
            },
            {
                "name": "Cicagna",
                "countryCode": "IT"
            },
            {
                "name": "Cipressa",
                "countryCode": "IT"
            },
            {
                "name": "Cisano",
                "countryCode": "IT"
            },
            {
                "name": "Civezza",
                "countryCode": "IT"
            },
            {
                "name": "Cogoleto",
                "countryCode": "IT"
            },
            {
                "name": "Cogorno",
                "countryCode": "IT"
            },
            {
                "name": "Colombiera-Molicciara",
                "countryCode": "IT"
            },
            {
                "name": "Conscenti",
                "countryCode": "IT"
            },
            {
                "name": "Coreglia Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Cosio di Arroscia",
                "countryCode": "IT"
            },
            {
                "name": "Cosseria",
                "countryCode": "IT"
            },
            {
                "name": "Costarainera",
                "countryCode": "IT"
            },
            {
                "name": "Crocefieschi",
                "countryCode": "IT"
            },
            {
                "name": "Davagna",
                "countryCode": "IT"
            },
            {
                "name": "Dego",
                "countryCode": "IT"
            },
            {
                "name": "Deiva Marina",
                "countryCode": "IT"
            },
            {
                "name": "Diano Arentino",
                "countryCode": "IT"
            },
            {
                "name": "Diano Castello",
                "countryCode": "IT"
            },
            {
                "name": "Diano Marina",
                "countryCode": "IT"
            },
            {
                "name": "Diano San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Dolceacqua",
                "countryCode": "IT"
            },
            {
                "name": "Dolcedo",
                "countryCode": "IT"
            },
            {
                "name": "Erli",
                "countryCode": "IT"
            },
            {
                "name": "Fascia",
                "countryCode": "IT"
            },
            {
                "name": "Favale di Malvaro",
                "countryCode": "IT"
            },
            {
                "name": "Feglino",
                "countryCode": "IT"
            },
            {
                "name": "Ferrada",
                "countryCode": "IT"
            },
            {
                "name": "Ferriere",
                "countryCode": "IT"
            },
            {
                "name": "Finale Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Fontanigorda",
                "countryCode": "IT"
            },
            {
                "name": "Framura",
                "countryCode": "IT"
            },
            {
                "name": "Garlenda",
                "countryCode": "IT"
            },
            {
                "name": "Genoa",
                "countryCode": "IT"
            },
            {
                "name": "Giustenice",
                "countryCode": "IT"
            },
            {
                "name": "Giusvalla",
                "countryCode": "IT"
            },
            {
                "name": "Gorreto",
                "countryCode": "IT"
            },
            {
                "name": "Imperia",
                "countryCode": "IT"
            },
            {
                "name": "Isola del Cantone",
                "countryCode": "IT"
            },
            {
                "name": "Isolabona",
                "countryCode": "IT"
            },
            {
                "name": "Isoverde",
                "countryCode": "IT"
            },
            {
                "name": "La Spezia",
                "countryCode": "IT"
            },
            {
                "name": "Laigueglia",
                "countryCode": "IT"
            },
            {
                "name": "Lavagna",
                "countryCode": "IT"
            },
            {
                "name": "Le Grazie",
                "countryCode": "IT"
            },
            {
                "name": "Leivi",
                "countryCode": "IT"
            },
            {
                "name": "Lerici",
                "countryCode": "IT"
            },
            {
                "name": "Levanto",
                "countryCode": "IT"
            },
            {
                "name": "Loano",
                "countryCode": "IT"
            },
            {
                "name": "Lorsica",
                "countryCode": "IT"
            },
            {
                "name": "Lucinasco",
                "countryCode": "IT"
            },
            {
                "name": "Magliolo",
                "countryCode": "IT"
            },
            {
                "name": "Maissana",
                "countryCode": "IT"
            },
            {
                "name": "Mallare",
                "countryCode": "IT"
            },
            {
                "name": "Manesseno",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Andora",
                "countryCode": "IT"
            },
            {
                "name": "Masone",
                "countryCode": "IT"
            },
            {
                "name": "Massimino",
                "countryCode": "IT"
            },
            {
                "name": "Mele",
                "countryCode": "IT"
            },
            {
                "name": "Mendatica",
                "countryCode": "IT"
            },
            {
                "name": "Mezzanego",
                "countryCode": "IT"
            },
            {
                "name": "Mignanego",
                "countryCode": "IT"
            },
            {
                "name": "Millesimo",
                "countryCode": "IT"
            },
            {
                "name": "Mioglia",
                "countryCode": "IT"
            },
            {
                "name": "Moconesi",
                "countryCode": "IT"
            },
            {
                "name": "Molini",
                "countryCode": "IT"
            },
            {
                "name": "Molini di Triora",
                "countryCode": "IT"
            },
            {
                "name": "Molino Vecchio-Scapitola-Baio",
                "countryCode": "IT"
            },
            {
                "name": "Molino-Pera",
                "countryCode": "IT"
            },
            {
                "name": "Moneglia",
                "countryCode": "IT"
            },
            {
                "name": "Montalto Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Montebruno",
                "countryCode": "IT"
            },
            {
                "name": "Montegrosso Pian Latte",
                "countryCode": "IT"
            },
            {
                "name": "Monterosso al Mare",
                "countryCode": "IT"
            },
            {
                "name": "Montoggio",
                "countryCode": "IT"
            },
            {
                "name": "Murialdo",
                "countryCode": "IT"
            },
            {
                "name": "Nasino-Borgo",
                "countryCode": "IT"
            },
            {
                "name": "Neirone",
                "countryCode": "IT"
            },
            {
                "name": "Noli",
                "countryCode": "IT"
            },
            {
                "name": "Olivetta San Michele",
                "countryCode": "IT"
            },
            {
                "name": "Onzo",
                "countryCode": "IT"
            },
            {
                "name": "Orco Feglino",
                "countryCode": "IT"
            },
            {
                "name": "Orero",
                "countryCode": "IT"
            },
            {
                "name": "Ortovero",
                "countryCode": "IT"
            },
            {
                "name": "Osiglia",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletti",
                "countryCode": "IT"
            },
            {
                "name": "Osteria dei Cacciatori-Stella",
                "countryCode": "IT"
            },
            {
                "name": "Padivarma",
                "countryCode": "IT"
            },
            {
                "name": "Pallare",
                "countryCode": "IT"
            },
            {
                "name": "Pedemonte",
                "countryCode": "IT"
            },
            {
                "name": "Perinaldo",
                "countryCode": "IT"
            },
            {
                "name": "Piana Battolla",
                "countryCode": "IT"
            },
            {
                "name": "Piana Crixia",
                "countryCode": "IT"
            },
            {
                "name": "Piano",
                "countryCode": "IT"
            },
            {
                "name": "Piano di Follo",
                "countryCode": "IT"
            },
            {
                "name": "Piccarello",
                "countryCode": "IT"
            },
            {
                "name": "Pietra Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Pietrabruna",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Teco",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Zignago",
                "countryCode": "IT"
            },
            {
                "name": "Pigna",
                "countryCode": "IT"
            },
            {
                "name": "Pignone",
                "countryCode": "IT"
            },
            {
                "name": "Pitelli",
                "countryCode": "IT"
            },
            {
                "name": "Plodio",
                "countryCode": "IT"
            },
            {
                "name": "Pompeiana",
                "countryCode": "IT"
            },
            {
                "name": "Pontedassio",
                "countryCode": "IT"
            },
            {
                "name": "Pontinvrea",
                "countryCode": "IT"
            },
            {
                "name": "Pornassio",
                "countryCode": "IT"
            },
            {
                "name": "Portofino",
                "countryCode": "IT"
            },
            {
                "name": "Portovenere",
                "countryCode": "IT"
            },
            {
                "name": "Prati",
                "countryCode": "IT"
            },
            {
                "name": "Prelà Castello",
                "countryCode": "IT"
            },
            {
                "name": "Propata",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Genova",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Imperia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di La Spezia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Savona",
                "countryCode": "IT"
            },
            {
                "name": "Quiliano",
                "countryCode": "IT"
            },
            {
                "name": "Rapallo",
                "countryCode": "IT"
            },
            {
                "name": "Recco",
                "countryCode": "IT"
            },
            {
                "name": "Rezzo",
                "countryCode": "IT"
            },
            {
                "name": "Rezzoaglio",
                "countryCode": "IT"
            },
            {
                "name": "Rialto",
                "countryCode": "IT"
            },
            {
                "name": "Riccò del Golfo",
                "countryCode": "IT"
            },
            {
                "name": "Riomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Riva Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Roccavignale",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Nervina",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta di Vara",
                "countryCode": "IT"
            },
            {
                "name": "Romito Magra",
                "countryCode": "IT"
            },
            {
                "name": "Ronco Scrivia",
                "countryCode": "IT"
            },
            {
                "name": "Rondanina",
                "countryCode": "IT"
            },
            {
                "name": "Rossi",
                "countryCode": "IT"
            },
            {
                "name": "Rossiglione",
                "countryCode": "IT"
            },
            {
                "name": "Rovegno",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo al Mare",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio della Cima",
                "countryCode": "IT"
            },
            {
                "name": "San Fedele-Lusignano",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo al Mare",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro d'Olba",
                "countryCode": "IT"
            },
            {
                "name": "San Remo",
                "countryCode": "IT"
            },
            {
                "name": "San Salvatore",
                "countryCode": "IT"
            },
            {
                "name": "San Terenzo",
                "countryCode": "IT"
            },
            {
                "name": "Santa Margherita Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Santa Marta",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano al Mare",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano d'Aveto",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano di Magra",
                "countryCode": "IT"
            },
            {
                "name": "Sarzana",
                "countryCode": "IT"
            },
            {
                "name": "Sassello",
                "countryCode": "IT"
            },
            {
                "name": "Savignone",
                "countryCode": "IT"
            },
            {
                "name": "Savona",
                "countryCode": "IT"
            },
            {
                "name": "Sciarborasca",
                "countryCode": "IT"
            },
            {
                "name": "Seborga",
                "countryCode": "IT"
            },
            {
                "name": "Serra Riccò",
                "countryCode": "IT"
            },
            {
                "name": "Sesta Godano",
                "countryCode": "IT"
            },
            {
                "name": "Sestri Levante",
                "countryCode": "IT"
            },
            {
                "name": "Soldano",
                "countryCode": "IT"
            },
            {
                "name": "Sori",
                "countryCode": "IT"
            },
            {
                "name": "Spotorno",
                "countryCode": "IT"
            },
            {
                "name": "Stazione-Fornola",
                "countryCode": "IT"
            },
            {
                "name": "Stellanello",
                "countryCode": "IT"
            },
            {
                "name": "Taggia",
                "countryCode": "IT"
            },
            {
                "name": "Terzorio",
                "countryCode": "IT"
            },
            {
                "name": "Testico",
                "countryCode": "IT"
            },
            {
                "name": "Tiglieto",
                "countryCode": "IT"
            },
            {
                "name": "Toirano",
                "countryCode": "IT"
            },
            {
                "name": "Torriglia",
                "countryCode": "IT"
            },
            {
                "name": "Tovo San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "Tribogna",
                "countryCode": "IT"
            },
            {
                "name": "Triora",
                "countryCode": "IT"
            },
            {
                "name": "Urbe",
                "countryCode": "IT"
            },
            {
                "name": "Uscio",
                "countryCode": "IT"
            },
            {
                "name": "Vado Centro",
                "countryCode": "IT"
            },
            {
                "name": "Vado Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Valle di Vado",
                "countryCode": "IT"
            },
            {
                "name": "Vallebona",
                "countryCode": "IT"
            },
            {
                "name": "Vallecrosia",
                "countryCode": "IT"
            },
            {
                "name": "Valleggia",
                "countryCode": "IT"
            },
            {
                "name": "Valzemola",
                "countryCode": "IT"
            },
            {
                "name": "Varazze",
                "countryCode": "IT"
            },
            {
                "name": "Varese Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Vasia",
                "countryCode": "IT"
            },
            {
                "name": "Vendone",
                "countryCode": "IT"
            },
            {
                "name": "Ventimiglia",
                "countryCode": "IT"
            },
            {
                "name": "Vernazza",
                "countryCode": "IT"
            },
            {
                "name": "Vessalico",
                "countryCode": "IT"
            },
            {
                "name": "Vezzano Ligure",
                "countryCode": "IT"
            },
            {
                "name": "Vezzi Portio",
                "countryCode": "IT"
            },
            {
                "name": "Villa Faraldi",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca",
                "countryCode": "IT"
            },
            {
                "name": "Villanova d'Albenga",
                "countryCode": "IT"
            },
            {
                "name": "Vobbia",
                "countryCode": "IT"
            },
            {
                "name": "Zoagli",
                "countryCode": "IT"
            },
            {
                "name": "Zuccarello",
                "countryCode": "IT"
            },
            {
                "name": "Agazzano",
                "countryCode": "IT"
            },
            {
                "name": "Albareto",
                "countryCode": "IT"
            },
            {
                "name": "Alberi",
                "countryCode": "IT"
            },
            {
                "name": "Albinea",
                "countryCode": "IT"
            },
            {
                "name": "Alfonsine",
                "countryCode": "IT"
            },
            {
                "name": "Alseno",
                "countryCode": "IT"
            },
            {
                "name": "Altedo",
                "countryCode": "IT"
            },
            {
                "name": "Anzola dell'Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Arceto",
                "countryCode": "IT"
            },
            {
                "name": "Argelato",
                "countryCode": "IT"
            },
            {
                "name": "Argenta",
                "countryCode": "IT"
            },
            {
                "name": "Argine",
                "countryCode": "IT"
            },
            {
                "name": "Baganzola",
                "countryCode": "IT"
            },
            {
                "name": "Baggiovara",
                "countryCode": "IT"
            },
            {
                "name": "Bagnacavallo",
                "countryCode": "IT"
            },
            {
                "name": "Bagnara di Romagna",
                "countryCode": "IT"
            },
            {
                "name": "Bagnarola",
                "countryCode": "IT"
            },
            {
                "name": "Bagno",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo in Piano",
                "countryCode": "IT"
            },
            {
                "name": "Baiso",
                "countryCode": "IT"
            },
            {
                "name": "Barbiano",
                "countryCode": "IT"
            },
            {
                "name": "Barco",
                "countryCode": "IT"
            },
            {
                "name": "Bardi",
                "countryCode": "IT"
            },
            {
                "name": "Baricella",
                "countryCode": "IT"
            },
            {
                "name": "Basilicagoiano",
                "countryCode": "IT"
            },
            {
                "name": "Basilicanova",
                "countryCode": "IT"
            },
            {
                "name": "Bastiglia",
                "countryCode": "IT"
            },
            {
                "name": "Bazzano",
                "countryCode": "IT"
            },
            {
                "name": "Bedonia",
                "countryCode": "IT"
            },
            {
                "name": "Bellaria-Igea Marina",
                "countryCode": "IT"
            },
            {
                "name": "Bentivoglio",
                "countryCode": "IT"
            },
            {
                "name": "Berceto",
                "countryCode": "IT"
            },
            {
                "name": "Berra",
                "countryCode": "IT"
            },
            {
                "name": "Bertinoro",
                "countryCode": "IT"
            },
            {
                "name": "Berzantina",
                "countryCode": "IT"
            },
            {
                "name": "Besenzone",
                "countryCode": "IT"
            },
            {
                "name": "Bettola",
                "countryCode": "IT"
            },
            {
                "name": "Bibbiano",
                "countryCode": "IT"
            },
            {
                "name": "Bobbio",
                "countryCode": "IT"
            },
            {
                "name": "Bologna",
                "countryCode": "IT"
            },
            {
                "name": "Bomporto",
                "countryCode": "IT"
            },
            {
                "name": "Bondeno",
                "countryCode": "IT"
            },
            {
                "name": "Bora Bassa",
                "countryCode": "IT"
            },
            {
                "name": "Bore",
                "countryCode": "IT"
            },
            {
                "name": "Borello",
                "countryCode": "IT"
            },
            {
                "name": "Boretto",
                "countryCode": "IT"
            },
            {
                "name": "Borghi",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Tossignano",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Val di Taro",
                "countryCode": "IT"
            },
            {
                "name": "Borgonovo Valtidone",
                "countryCode": "IT"
            },
            {
                "name": "Borgonuovo",
                "countryCode": "IT"
            },
            {
                "name": "Borzano",
                "countryCode": "IT"
            },
            {
                "name": "Bosco Mesola",
                "countryCode": "IT"
            },
            {
                "name": "Brescello",
                "countryCode": "IT"
            },
            {
                "name": "Brisighella",
                "countryCode": "IT"
            },
            {
                "name": "Bubano",
                "countryCode": "IT"
            },
            {
                "name": "Budrio",
                "countryCode": "IT"
            },
            {
                "name": "Busana",
                "countryCode": "IT"
            },
            {
                "name": "Busseto",
                "countryCode": "IT"
            },
            {
                "name": "Ca' di Sola",
                "countryCode": "IT"
            },
            {
                "name": "Cadelbosco di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Cadelbosco di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Cadè-Gaida",
                "countryCode": "IT"
            },
            {
                "name": "Calcara",
                "countryCode": "IT"
            },
            {
                "name": "Calderara di Reno",
                "countryCode": "IT"
            },
            {
                "name": "Calderino",
                "countryCode": "IT"
            },
            {
                "name": "Calendasco",
                "countryCode": "IT"
            },
            {
                "name": "Calerno",
                "countryCode": "IT"
            },
            {
                "name": "Calestano",
                "countryCode": "IT"
            },
            {
                "name": "Caminata",
                "countryCode": "IT"
            },
            {
                "name": "Campagnola Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Campegine",
                "countryCode": "IT"
            },
            {
                "name": "Campogalliano",
                "countryCode": "IT"
            },
            {
                "name": "Camposanto",
                "countryCode": "IT"
            },
            {
                "name": "Camugnano",
                "countryCode": "IT"
            },
            {
                "name": "Caorso",
                "countryCode": "IT"
            },
            {
                "name": "Caprara",
                "countryCode": "IT"
            },
            {
                "name": "Carpaneto Piacentino",
                "countryCode": "IT"
            },
            {
                "name": "Carpi Centro",
                "countryCode": "IT"
            },
            {
                "name": "Carpineti",
                "countryCode": "IT"
            },
            {
                "name": "Casale",
                "countryCode": "IT"
            },
            {
                "name": "Casalecchio di Reno",
                "countryCode": "IT"
            },
            {
                "name": "Casalfiumanese",
                "countryCode": "IT"
            },
            {
                "name": "Casalgrande",
                "countryCode": "IT"
            },
            {
                "name": "Casina",
                "countryCode": "IT"
            },
            {
                "name": "Casola Valsenio",
                "countryCode": "IT"
            },
            {
                "name": "Castel Bolognese",
                "countryCode": "IT"
            },
            {
                "name": "Castel Guelfo di Bologna",
                "countryCode": "IT"
            },
            {
                "name": "Castel Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Pietro Terme",
                "countryCode": "IT"
            },
            {
                "name": "Castel d'Aiano",
                "countryCode": "IT"
            },
            {
                "name": "Castel del Rio",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Casio",
                "countryCode": "IT"
            },
            {
                "name": "Casteldelci",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranco Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Castell'Arquato",
                "countryCode": "IT"
            },
            {
                "name": "Castellarano",
                "countryCode": "IT"
            },
            {
                "name": "Castelletto",
                "countryCode": "IT"
            },
            {
                "name": "Castello d'Argile",
                "countryCode": "IT"
            },
            {
                "name": "Castelnovo di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Castelnovo ne'Monti",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Rangone",
                "countryCode": "IT"
            },
            {
                "name": "Castelvetro Piacentino",
                "countryCode": "IT"
            },
            {
                "name": "Castelvetro di Modena",
                "countryCode": "IT"
            },
            {
                "name": "Castenaso",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione dei Pepoli",
                "countryCode": "IT"
            },
            {
                "name": "Castrocaro Terme e Terra del Sole",
                "countryCode": "IT"
            },
            {
                "name": "Cattolica",
                "countryCode": "IT"
            },
            {
                "name": "Cavazzona",
                "countryCode": "IT"
            },
            {
                "name": "Cavezzo",
                "countryCode": "IT"
            },
            {
                "name": "Cavriago",
                "countryCode": "IT"
            },
            {
                "name": "Cento",
                "countryCode": "IT"
            },
            {
                "name": "Cerasolo",
                "countryCode": "IT"
            },
            {
                "name": "Cerignale",
                "countryCode": "IT"
            },
            {
                "name": "Cervia",
                "countryCode": "IT"
            },
            {
                "name": "Cesena",
                "countryCode": "IT"
            },
            {
                "name": "Cesenatico",
                "countryCode": "IT"
            },
            {
                "name": "Ciano d'Enza",
                "countryCode": "IT"
            },
            {
                "name": "Civitella di Romagna",
                "countryCode": "IT"
            },
            {
                "name": "Classe",
                "countryCode": "IT"
            },
            {
                "name": "Coccanile-Cesta",
                "countryCode": "IT"
            },
            {
                "name": "Codemondo-Quaresimo",
                "countryCode": "IT"
            },
            {
                "name": "Codigoro",
                "countryCode": "IT"
            },
            {
                "name": "Codisotto",
                "countryCode": "IT"
            },
            {
                "name": "Coli",
                "countryCode": "IT"
            },
            {
                "name": "Collagna",
                "countryCode": "IT"
            },
            {
                "name": "Collecchio",
                "countryCode": "IT"
            },
            {
                "name": "Colombaro",
                "countryCode": "IT"
            },
            {
                "name": "Colorno",
                "countryCode": "IT"
            },
            {
                "name": "Comacchio",
                "countryCode": "IT"
            },
            {
                "name": "Compiano",
                "countryCode": "IT"
            },
            {
                "name": "Cona",
                "countryCode": "IT"
            },
            {
                "name": "Concordia sulla Secchia",
                "countryCode": "IT"
            },
            {
                "name": "Consandolo",
                "countryCode": "IT"
            },
            {
                "name": "Conselice",
                "countryCode": "IT"
            },
            {
                "name": "Copparo",
                "countryCode": "IT"
            },
            {
                "name": "Corcagnano",
                "countryCode": "IT"
            },
            {
                "name": "Coriano",
                "countryCode": "IT"
            },
            {
                "name": "Corniglio",
                "countryCode": "IT"
            },
            {
                "name": "Corpo Reno",
                "countryCode": "IT"
            },
            {
                "name": "Corpolò",
                "countryCode": "IT"
            },
            {
                "name": "Correggio",
                "countryCode": "IT"
            },
            {
                "name": "Cortemaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cotignola",
                "countryCode": "IT"
            },
            {
                "name": "Crespellano",
                "countryCode": "IT"
            },
            {
                "name": "Crevalcore",
                "countryCode": "IT"
            },
            {
                "name": "Cusercoli",
                "countryCode": "IT"
            },
            {
                "name": "Cà Dè Fabbri",
                "countryCode": "IT"
            },
            {
                "name": "Decima",
                "countryCode": "IT"
            },
            {
                "name": "Dodici Morelli",
                "countryCode": "IT"
            },
            {
                "name": "Dosso",
                "countryCode": "IT"
            },
            {
                "name": "Dovadola",
                "countryCode": "IT"
            },
            {
                "name": "Dozza",
                "countryCode": "IT"
            },
            {
                "name": "Fabbrico",
                "countryCode": "IT"
            },
            {
                "name": "Faenza",
                "countryCode": "IT"
            },
            {
                "name": "Fanano",
                "countryCode": "IT"
            },
            {
                "name": "Farini",
                "countryCode": "IT"
            },
            {
                "name": "Felegara",
                "countryCode": "IT"
            },
            {
                "name": "Felina",
                "countryCode": "IT"
            },
            {
                "name": "Felino",
                "countryCode": "IT"
            },
            {
                "name": "Fellegara",
                "countryCode": "IT"
            },
            {
                "name": "Ferrara",
                "countryCode": "IT"
            },
            {
                "name": "Ferriere",
                "countryCode": "IT"
            },
            {
                "name": "Fidenza",
                "countryCode": "IT"
            },
            {
                "name": "Finale Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Fiorano",
                "countryCode": "IT"
            },
            {
                "name": "Fiorenzuola d'Arda",
                "countryCode": "IT"
            },
            {
                "name": "Fiumalbo-Dogana",
                "countryCode": "IT"
            },
            {
                "name": "Fiumana",
                "countryCode": "IT"
            },
            {
                "name": "Fogliano",
                "countryCode": "IT"
            },
            {
                "name": "Fognano",
                "countryCode": "IT"
            },
            {
                "name": "Fontanelice",
                "countryCode": "IT"
            },
            {
                "name": "Fontanellato",
                "countryCode": "IT"
            },
            {
                "name": "Fontevivo",
                "countryCode": "IT"
            },
            {
                "name": "Forche",
                "countryCode": "IT"
            },
            {
                "name": "Forlimpopoli",
                "countryCode": "IT"
            },
            {
                "name": "Forlì",
                "countryCode": "IT"
            },
            {
                "name": "Formica",
                "countryCode": "IT"
            },
            {
                "name": "Formigine",
                "countryCode": "IT"
            },
            {
                "name": "Formignana",
                "countryCode": "IT"
            },
            {
                "name": "Fornace Zarattini",
                "countryCode": "IT"
            },
            {
                "name": "Fornovo di Taro",
                "countryCode": "IT"
            },
            {
                "name": "Fossa",
                "countryCode": "IT"
            },
            {
                "name": "Fosso Ghiaia",
                "countryCode": "IT"
            },
            {
                "name": "Fossoli",
                "countryCode": "IT"
            },
            {
                "name": "Francolino",
                "countryCode": "IT"
            },
            {
                "name": "Frassinoro",
                "countryCode": "IT"
            },
            {
                "name": "Fratta Terme",
                "countryCode": "IT"
            },
            {
                "name": "Fusignano",
                "countryCode": "IT"
            },
            {
                "name": "Gaggio",
                "countryCode": "IT"
            },
            {
                "name": "Gaggio Montano",
                "countryCode": "IT"
            },
            {
                "name": "Gaiano",
                "countryCode": "IT"
            },
            {
                "name": "Gaibanella-Sant'Edigio",
                "countryCode": "IT"
            },
            {
                "name": "Galeata",
                "countryCode": "IT"
            },
            {
                "name": "Gallo",
                "countryCode": "IT"
            },
            {
                "name": "Gambettola",
                "countryCode": "IT"
            },
            {
                "name": "Gatteo a Mare",
                "countryCode": "IT"
            },
            {
                "name": "Gatteo-Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Gazzola",
                "countryCode": "IT"
            },
            {
                "name": "Gemmano",
                "countryCode": "IT"
            },
            {
                "name": "Glorie",
                "countryCode": "IT"
            },
            {
                "name": "Godo",
                "countryCode": "IT"
            },
            {
                "name": "Goro",
                "countryCode": "IT"
            },
            {
                "name": "Gorzano",
                "countryCode": "IT"
            },
            {
                "name": "Gossolengo",
                "countryCode": "IT"
            },
            {
                "name": "Gragnano Trebbiense",
                "countryCode": "IT"
            },
            {
                "name": "Granarolo",
                "countryCode": "IT"
            },
            {
                "name": "Granarolo dell'Emilia e Viadagola",
                "countryCode": "IT"
            },
            {
                "name": "Grizzana",
                "countryCode": "IT"
            },
            {
                "name": "Grizzana Morandi",
                "countryCode": "IT"
            },
            {
                "name": "Gropparello",
                "countryCode": "IT"
            },
            {
                "name": "Gualtieri",
                "countryCode": "IT"
            },
            {
                "name": "Guastalla",
                "countryCode": "IT"
            },
            {
                "name": "Guiglia",
                "countryCode": "IT"
            },
            {
                "name": "Imola",
                "countryCode": "IT"
            },
            {
                "name": "Jolanda di Savoia",
                "countryCode": "IT"
            },
            {
                "name": "La Saletta-Tamara",
                "countryCode": "IT"
            },
            {
                "name": "Lagosanto",
                "countryCode": "IT"
            },
            {
                "name": "Lama",
                "countryCode": "IT"
            },
            {
                "name": "Langhirano",
                "countryCode": "IT"
            },
            {
                "name": "Lavezzola",
                "countryCode": "IT"
            },
            {
                "name": "Lentigione-Sorbolo a Mane",
                "countryCode": "IT"
            },
            {
                "name": "Lesignano de'Bagni",
                "countryCode": "IT"
            },
            {
                "name": "Lido Adriano",
                "countryCode": "IT"
            },
            {
                "name": "Lido degli Estensi",
                "countryCode": "IT"
            },
            {
                "name": "Lido di Pomposa-Lido degli Scacchi",
                "countryCode": "IT"
            },
            {
                "name": "Ligonchio",
                "countryCode": "IT"
            },
            {
                "name": "Limidi",
                "countryCode": "IT"
            },
            {
                "name": "Lizzano in Belvedere",
                "countryCode": "IT"
            },
            {
                "name": "Loiano",
                "countryCode": "IT"
            },
            {
                "name": "Longara",
                "countryCode": "IT"
            },
            {
                "name": "Longastrino",
                "countryCode": "IT"
            },
            {
                "name": "Longiano",
                "countryCode": "IT"
            },
            {
                "name": "Lugagnano Val d'Arda",
                "countryCode": "IT"
            },
            {
                "name": "Lugo",
                "countryCode": "IT"
            },
            {
                "name": "Luzzara",
                "countryCode": "IT"
            },
            {
                "name": "Madonna Dell'Albero",
                "countryCode": "IT"
            },
            {
                "name": "Magreta",
                "countryCode": "IT"
            },
            {
                "name": "Malalbergo",
                "countryCode": "IT"
            },
            {
                "name": "Manzolino",
                "countryCode": "IT"
            },
            {
                "name": "Maranello",
                "countryCode": "IT"
            },
            {
                "name": "Marano sul Panaro",
                "countryCode": "IT"
            },
            {
                "name": "Marina Romea",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Ravenna",
                "countryCode": "IT"
            },
            {
                "name": "Marsaglia",
                "countryCode": "IT"
            },
            {
                "name": "Marzabotto",
                "countryCode": "IT"
            },
            {
                "name": "Masi-Torello",
                "countryCode": "IT"
            },
            {
                "name": "Massa Finalese",
                "countryCode": "IT"
            },
            {
                "name": "Massa Fiscaglia",
                "countryCode": "IT"
            },
            {
                "name": "Massa Lombarda",
                "countryCode": "IT"
            },
            {
                "name": "Massenzatico",
                "countryCode": "IT"
            },
            {
                "name": "Medesano",
                "countryCode": "IT"
            },
            {
                "name": "Medicina-Buda",
                "countryCode": "IT"
            },
            {
                "name": "Medolla",
                "countryCode": "IT"
            },
            {
                "name": "Meldola",
                "countryCode": "IT"
            },
            {
                "name": "Mercato Saraceno",
                "countryCode": "IT"
            },
            {
                "name": "Mesola",
                "countryCode": "IT"
            },
            {
                "name": "Mezzano",
                "countryCode": "IT"
            },
            {
                "name": "Mezzano Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Mezzogoro",
                "countryCode": "IT"
            },
            {
                "name": "Mezzolara",
                "countryCode": "IT"
            },
            {
                "name": "Migliarina",
                "countryCode": "IT"
            },
            {
                "name": "Migliarino",
                "countryCode": "IT"
            },
            {
                "name": "Migliaro",
                "countryCode": "IT"
            },
            {
                "name": "Milano Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Minerbio",
                "countryCode": "IT"
            },
            {
                "name": "Mirabello",
                "countryCode": "IT"
            },
            {
                "name": "Mirandola",
                "countryCode": "IT"
            },
            {
                "name": "Misano Adriatico",
                "countryCode": "IT"
            },
            {
                "name": "Modena",
                "countryCode": "IT"
            },
            {
                "name": "Modigliana",
                "countryCode": "IT"
            },
            {
                "name": "Molinella",
                "countryCode": "IT"
            },
            {
                "name": "Molino del Pallone",
                "countryCode": "IT"
            },
            {
                "name": "Monchio delle Corti",
                "countryCode": "IT"
            },
            {
                "name": "Mondaino",
                "countryCode": "IT"
            },
            {
                "name": "Monghidoro",
                "countryCode": "IT"
            },
            {
                "name": "Montalbano",
                "countryCode": "IT"
            },
            {
                "name": "Montale",
                "countryCode": "IT"
            },
            {
                "name": "Monte Colombo",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Montecavolo",
                "countryCode": "IT"
            },
            {
                "name": "Montecchio Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Montechiarugolo",
                "countryCode": "IT"
            },
            {
                "name": "Montecreto",
                "countryCode": "IT"
            },
            {
                "name": "Montefiore Conca",
                "countryCode": "IT"
            },
            {
                "name": "Montefiorino",
                "countryCode": "IT"
            },
            {
                "name": "Montegridolfo",
                "countryCode": "IT"
            },
            {
                "name": "Monterenzio",
                "countryCode": "IT"
            },
            {
                "name": "Montescudo",
                "countryCode": "IT"
            },
            {
                "name": "Montese",
                "countryCode": "IT"
            },
            {
                "name": "Montiano",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli Terme",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli d'Ongina",
                "countryCode": "IT"
            },
            {
                "name": "Monzuno",
                "countryCode": "IT"
            },
            {
                "name": "Morciano di Romagna",
                "countryCode": "IT"
            },
            {
                "name": "Mordano",
                "countryCode": "IT"
            },
            {
                "name": "Morfasso",
                "countryCode": "IT"
            },
            {
                "name": "Mulino",
                "countryCode": "IT"
            },
            {
                "name": "Neviano degli Arduini",
                "countryCode": "IT"
            },
            {
                "name": "Nibbiano",
                "countryCode": "IT"
            },
            {
                "name": "Niviano",
                "countryCode": "IT"
            },
            {
                "name": "Noceto",
                "countryCode": "IT"
            },
            {
                "name": "Nonantola",
                "countryCode": "IT"
            },
            {
                "name": "Novafeltria",
                "countryCode": "IT"
            },
            {
                "name": "Novellara",
                "countryCode": "IT"
            },
            {
                "name": "Novi di Modena",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto",
                "countryCode": "IT"
            },
            {
                "name": "Ostellato",
                "countryCode": "IT"
            },
            {
                "name": "Osteria Grande",
                "countryCode": "IT"
            },
            {
                "name": "Osteria Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Ottone",
                "countryCode": "IT"
            },
            {
                "name": "Ozzano Taro",
                "countryCode": "IT"
            },
            {
                "name": "Ozzano dell'Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Padulle",
                "countryCode": "IT"
            },
            {
                "name": "Palagano",
                "countryCode": "IT"
            },
            {
                "name": "Palanzano",
                "countryCode": "IT"
            },
            {
                "name": "Panighina",
                "countryCode": "IT"
            },
            {
                "name": "Parma",
                "countryCode": "IT"
            },
            {
                "name": "Pavullo nel Frignano",
                "countryCode": "IT"
            },
            {
                "name": "Pecorara",
                "countryCode": "IT"
            },
            {
                "name": "Pellegrino Parmense",
                "countryCode": "IT"
            },
            {
                "name": "Pennabilli",
                "countryCode": "IT"
            },
            {
                "name": "Piacenza",
                "countryCode": "IT"
            },
            {
                "name": "Pianello Val Tidone",
                "countryCode": "IT"
            },
            {
                "name": "Piangipane",
                "countryCode": "IT"
            },
            {
                "name": "Pianoro",
                "countryCode": "IT"
            },
            {
                "name": "Pietracuta",
                "countryCode": "IT"
            },
            {
                "name": "Pieve di Cento",
                "countryCode": "IT"
            },
            {
                "name": "Pievepelago",
                "countryCode": "IT"
            },
            {
                "name": "Pilastro",
                "countryCode": "IT"
            },
            {
                "name": "Pinarella",
                "countryCode": "IT"
            },
            {
                "name": "Piozzano",
                "countryCode": "IT"
            },
            {
                "name": "Pisignano",
                "countryCode": "IT"
            },
            {
                "name": "Piumazzo",
                "countryCode": "IT"
            },
            {
                "name": "Pizzano",
                "countryCode": "IT"
            },
            {
                "name": "Podenzano",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Berni",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Renatico",
                "countryCode": "IT"
            },
            {
                "name": "Polesine Parmense",
                "countryCode": "IT"
            },
            {
                "name": "Polinago",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Ronca",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Taro",
                "countryCode": "IT"
            },
            {
                "name": "Ponte dell'Olio",
                "countryCode": "IT"
            },
            {
                "name": "Ponte della Venturina",
                "countryCode": "IT"
            },
            {
                "name": "Pontegradella",
                "countryCode": "IT"
            },
            {
                "name": "Pontelangorino",
                "countryCode": "IT"
            },
            {
                "name": "Pontenure",
                "countryCode": "IT"
            },
            {
                "name": "Ponticella",
                "countryCode": "IT"
            },
            {
                "name": "Porotto-Cassama",
                "countryCode": "IT"
            },
            {
                "name": "Porporano",
                "countryCode": "IT"
            },
            {
                "name": "Porretta Terme",
                "countryCode": "IT"
            },
            {
                "name": "Portico di Romagna",
                "countryCode": "IT"
            },
            {
                "name": "Portico e San Benedetto",
                "countryCode": "IT"
            },
            {
                "name": "Porto Corsini",
                "countryCode": "IT"
            },
            {
                "name": "Porto Fuori",
                "countryCode": "IT"
            },
            {
                "name": "Porto Garibaldi",
                "countryCode": "IT"
            },
            {
                "name": "Portomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Poviglio",
                "countryCode": "IT"
            },
            {
                "name": "Pozza",
                "countryCode": "IT"
            },
            {
                "name": "Praticello",
                "countryCode": "IT"
            },
            {
                "name": "Pratissolo",
                "countryCode": "IT"
            },
            {
                "name": "Prato",
                "countryCode": "IT"
            },
            {
                "name": "Predappio",
                "countryCode": "IT"
            },
            {
                "name": "Premilcuore",
                "countryCode": "IT"
            },
            {
                "name": "Prignano",
                "countryCode": "IT"
            },
            {
                "name": "Prignano sulla Secchia",
                "countryCode": "IT"
            },
            {
                "name": "Progresso",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Ferrara",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Forlì-Cesena",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Modena",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Parma",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Piacenza",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Ravenna",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Reggio Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Rimini",
                "countryCode": "IT"
            },
            {
                "name": "Puianello",
                "countryCode": "IT"
            },
            {
                "name": "Pulce",
                "countryCode": "IT"
            },
            {
                "name": "Punta Marina",
                "countryCode": "IT"
            },
            {
                "name": "Quarantoli",
                "countryCode": "IT"
            },
            {
                "name": "Quartesana",
                "countryCode": "IT"
            },
            {
                "name": "Quarto Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Quattro Castella",
                "countryCode": "IT"
            },
            {
                "name": "Ramiola",
                "countryCode": "IT"
            },
            {
                "name": "Ramiseto",
                "countryCode": "IT"
            },
            {
                "name": "Rastignano-Carteria di Sesto",
                "countryCode": "IT"
            },
            {
                "name": "Ravarino",
                "countryCode": "IT"
            },
            {
                "name": "Ravenna",
                "countryCode": "IT"
            },
            {
                "name": "Reggio nell'Emilia",
                "countryCode": "IT"
            },
            {
                "name": "Reggiolo",
                "countryCode": "IT"
            },
            {
                "name": "Renazzo",
                "countryCode": "IT"
            },
            {
                "name": "Riale",
                "countryCode": "IT"
            },
            {
                "name": "Riccione",
                "countryCode": "IT"
            },
            {
                "name": "Riccò",
                "countryCode": "IT"
            },
            {
                "name": "Rimini",
                "countryCode": "IT"
            },
            {
                "name": "Rio Saliceto",
                "countryCode": "IT"
            },
            {
                "name": "Riolo Terme",
                "countryCode": "IT"
            },
            {
                "name": "Riolunato",
                "countryCode": "IT"
            },
            {
                "name": "Rivazzurra",
                "countryCode": "IT"
            },
            {
                "name": "Rivergaro",
                "countryCode": "IT"
            },
            {
                "name": "Ro",
                "countryCode": "IT"
            },
            {
                "name": "Rocca San Casciano",
                "countryCode": "IT"
            },
            {
                "name": "Roccabianca",
                "countryCode": "IT"
            },
            {
                "name": "Rolo",
                "countryCode": "IT"
            },
            {
                "name": "Roncocesi",
                "countryCode": "IT"
            },
            {
                "name": "Roncofreddo",
                "countryCode": "IT"
            },
            {
                "name": "Roncofreddo-Santa Paola",
                "countryCode": "IT"
            },
            {
                "name": "Roteglia",
                "countryCode": "IT"
            },
            {
                "name": "Rottofreno",
                "countryCode": "IT"
            },
            {
                "name": "Roveleto",
                "countryCode": "IT"
            },
            {
                "name": "Rovereto",
                "countryCode": "IT"
            },
            {
                "name": "Rubiera",
                "countryCode": "IT"
            },
            {
                "name": "Russi",
                "countryCode": "IT"
            },
            {
                "name": "Sala",
                "countryCode": "IT"
            },
            {
                "name": "Sala Baganza",
                "countryCode": "IT"
            },
            {
                "name": "Sala Bolognese",
                "countryCode": "IT"
            },
            {
                "name": "Salsomaggiore Terme",
                "countryCode": "IT"
            },
            {
                "name": "Saludecio",
                "countryCode": "IT"
            },
            {
                "name": "Salvaterra",
                "countryCode": "IT"
            },
            {
                "name": "Sam Marino",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo In Bosco",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto Val di Sambro",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio",
                "countryCode": "IT"
            },
            {
                "name": "San Carlo",
                "countryCode": "IT"
            },
            {
                "name": "San Cesario sul Panaro",
                "countryCode": "IT"
            },
            {
                "name": "San Clemente",
                "countryCode": "IT"
            },
            {
                "name": "San Damaso",
                "countryCode": "IT"
            },
            {
                "name": "San Felice sul Panaro",
                "countryCode": "IT"
            },
            {
                "name": "San Gabriele-Mondonuovo",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Piacentino",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio di Piano",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni in Marignano",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni in Persiceto",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni-San Bernardino",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "San Lazzaro",
                "countryCode": "IT"
            },
            {
                "name": "San Leo",
                "countryCode": "IT"
            },
            {
                "name": "San Martino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino dei Mulini",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Argine",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Rio",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Pascoli",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro a Mare",
                "countryCode": "IT"
            },
            {
                "name": "San Michele Tiorre",
                "countryCode": "IT"
            },
            {
                "name": "San Michele dei Mucchietti",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "San Pancrazio",
                "countryCode": "IT"
            },
            {
                "name": "San Piero in Bagno",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Capofiume",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro In Vincoli",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Casale",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Cerro",
                "countryCode": "IT"
            },
            {
                "name": "San Polo",
                "countryCode": "IT"
            },
            {
                "name": "San Polo d'Enza",
                "countryCode": "IT"
            },
            {
                "name": "San Possidonio",
                "countryCode": "IT"
            },
            {
                "name": "San Prospero",
                "countryCode": "IT"
            },
            {
                "name": "San Secondo Parmense",
                "countryCode": "IT"
            },
            {
                "name": "San Venanzio",
                "countryCode": "IT"
            },
            {
                "name": "San Vincenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata Bolognese",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata Feltria",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata sul Santerno",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agostino",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alberto",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea in Casale",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ilario d'Enza",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce Scuole",
                "countryCode": "IT"
            },
            {
                "name": "Santa Giustina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Codifiume",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Santa Monica-Cella",
                "countryCode": "IT"
            },
            {
                "name": "Santa Sofia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Vittoria",
                "countryCode": "IT"
            },
            {
                "name": "Santarcangelo",
                "countryCode": "IT"
            },
            {
                "name": "Santerno",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano-Carraie",
                "countryCode": "IT"
            },
            {
                "name": "Sarmato",
                "countryCode": "IT"
            },
            {
                "name": "Sarsina",
                "countryCode": "IT"
            },
            {
                "name": "Sasso Marconi",
                "countryCode": "IT"
            },
            {
                "name": "Sassuolo",
                "countryCode": "IT"
            },
            {
                "name": "Savarna-Conventello",
                "countryCode": "IT"
            },
            {
                "name": "Savignano sul Panaro",
                "countryCode": "IT"
            },
            {
                "name": "Savignano sul Rubicone",
                "countryCode": "IT"
            },
            {
                "name": "Savigno",
                "countryCode": "IT"
            },
            {
                "name": "Savio",
                "countryCode": "IT"
            },
            {
                "name": "Scandiano",
                "countryCode": "IT"
            },
            {
                "name": "Scortichino",
                "countryCode": "IT"
            },
            {
                "name": "Secchiano",
                "countryCode": "IT"
            },
            {
                "name": "Serra di Maiolo",
                "countryCode": "IT"
            },
            {
                "name": "Serramazzoni",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle",
                "countryCode": "IT"
            },
            {
                "name": "Sesto Imolese",
                "countryCode": "IT"
            },
            {
                "name": "Sestola",
                "countryCode": "IT"
            },
            {
                "name": "Sissa",
                "countryCode": "IT"
            },
            {
                "name": "Sogliano al Rubicone",
                "countryCode": "IT"
            },
            {
                "name": "Solara",
                "countryCode": "IT"
            },
            {
                "name": "Solarolo",
                "countryCode": "IT"
            },
            {
                "name": "Soliera",
                "countryCode": "IT"
            },
            {
                "name": "Solignano",
                "countryCode": "IT"
            },
            {
                "name": "Solignano Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Soragna",
                "countryCode": "IT"
            },
            {
                "name": "Sorbara",
                "countryCode": "IT"
            },
            {
                "name": "Sorbolo",
                "countryCode": "IT"
            },
            {
                "name": "Spilamberto",
                "countryCode": "IT"
            },
            {
                "name": "Stazione Valmozzola",
                "countryCode": "IT"
            },
            {
                "name": "Talamello",
                "countryCode": "IT"
            },
            {
                "name": "Taneto",
                "countryCode": "IT"
            },
            {
                "name": "Terenzo",
                "countryCode": "IT"
            },
            {
                "name": "Tizzano Val Parma",
                "countryCode": "IT"
            },
            {
                "name": "Toano",
                "countryCode": "IT"
            },
            {
                "name": "Tornolo",
                "countryCode": "IT"
            },
            {
                "name": "Torriana",
                "countryCode": "IT"
            },
            {
                "name": "Torrile",
                "countryCode": "IT"
            },
            {
                "name": "Toscanella",
                "countryCode": "IT"
            },
            {
                "name": "Trarivi",
                "countryCode": "IT"
            },
            {
                "name": "Traversetolo",
                "countryCode": "IT"
            },
            {
                "name": "Travo",
                "countryCode": "IT"
            },
            {
                "name": "Trebbo",
                "countryCode": "IT"
            },
            {
                "name": "Trecasali",
                "countryCode": "IT"
            },
            {
                "name": "Tredozio",
                "countryCode": "IT"
            },
            {
                "name": "Tresigallo-Final di Rero",
                "countryCode": "IT"
            },
            {
                "name": "Vado",
                "countryCode": "IT"
            },
            {
                "name": "Varano De' Melegari",
                "countryCode": "IT"
            },
            {
                "name": "Varsi",
                "countryCode": "IT"
            },
            {
                "name": "Veggia-Villalunga",
                "countryCode": "IT"
            },
            {
                "name": "Venezzano",
                "countryCode": "IT"
            },
            {
                "name": "Ventoso",
                "countryCode": "IT"
            },
            {
                "name": "Vergato",
                "countryCode": "IT"
            },
            {
                "name": "Verghereto",
                "countryCode": "IT"
            },
            {
                "name": "Vernasca",
                "countryCode": "IT"
            },
            {
                "name": "Verucchio",
                "countryCode": "IT"
            },
            {
                "name": "Vetto",
                "countryCode": "IT"
            },
            {
                "name": "Vezzano sul Crostolo",
                "countryCode": "IT"
            },
            {
                "name": "Viano",
                "countryCode": "IT"
            },
            {
                "name": "Vicofertile",
                "countryCode": "IT"
            },
            {
                "name": "Vigarano Mainarda",
                "countryCode": "IT"
            },
            {
                "name": "Vigarano Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Vigatto",
                "countryCode": "IT"
            },
            {
                "name": "Vignola",
                "countryCode": "IT"
            },
            {
                "name": "Vigolzone",
                "countryCode": "IT"
            },
            {
                "name": "Villa Fontana",
                "countryCode": "IT"
            },
            {
                "name": "Villa Minozzo",
                "countryCode": "IT"
            },
            {
                "name": "Villa Verucchio",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Villarotta",
                "countryCode": "IT"
            },
            {
                "name": "Voghiera",
                "countryCode": "IT"
            },
            {
                "name": "Voltana",
                "countryCode": "IT"
            },
            {
                "name": "Zerba",
                "countryCode": "IT"
            },
            {
                "name": "Ziano Piacentino",
                "countryCode": "IT"
            },
            {
                "name": "Zibello",
                "countryCode": "IT"
            },
            {
                "name": "Zocca",
                "countryCode": "IT"
            },
            {
                "name": "Zola Predosa",
                "countryCode": "IT"
            },
            {
                "name": "Abbadia San Salvatore",
                "countryCode": "IT"
            },
            {
                "name": "Abetone",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva",
                "countryCode": "IT"
            },
            {
                "name": "Agliana",
                "countryCode": "IT"
            },
            {
                "name": "Albiano Magra",
                "countryCode": "IT"
            },
            {
                "name": "Albinia",
                "countryCode": "IT"
            },
            {
                "name": "Altopascio",
                "countryCode": "IT"
            },
            {
                "name": "Ambra",
                "countryCode": "IT"
            },
            {
                "name": "Anchione",
                "countryCode": "IT"
            },
            {
                "name": "Anghiari",
                "countryCode": "IT"
            },
            {
                "name": "Antella",
                "countryCode": "IT"
            },
            {
                "name": "Arbia",
                "countryCode": "IT"
            },
            {
                "name": "Arcidosso",
                "countryCode": "IT"
            },
            {
                "name": "Arezzo",
                "countryCode": "IT"
            },
            {
                "name": "Asciano",
                "countryCode": "IT"
            },
            {
                "name": "Aulla",
                "countryCode": "IT"
            },
            {
                "name": "Avane",
                "countryCode": "IT"
            },
            {
                "name": "Badia Tedalda",
                "countryCode": "IT"
            },
            {
                "name": "Badia al Pino",
                "countryCode": "IT"
            },
            {
                "name": "Bagni di Lucca",
                "countryCode": "IT"
            },
            {
                "name": "Bagno Roselle",
                "countryCode": "IT"
            },
            {
                "name": "Bagno a Ripoli",
                "countryCode": "IT"
            },
            {
                "name": "Bagnone",
                "countryCode": "IT"
            },
            {
                "name": "Barba",
                "countryCode": "IT"
            },
            {
                "name": "Barbarasco",
                "countryCode": "IT"
            },
            {
                "name": "Barberino Val d'Elsa",
                "countryCode": "IT"
            },
            {
                "name": "Barberino di Mugello",
                "countryCode": "IT"
            },
            {
                "name": "Barga",
                "countryCode": "IT"
            },
            {
                "name": "Battifolle-Ruscello-Poggiola",
                "countryCode": "IT"
            },
            {
                "name": "Bellavista",
                "countryCode": "IT"
            },
            {
                "name": "Belverde",
                "countryCode": "IT"
            },
            {
                "name": "Bettolle",
                "countryCode": "IT"
            },
            {
                "name": "Bibbiena",
                "countryCode": "IT"
            },
            {
                "name": "Bibbona",
                "countryCode": "IT"
            },
            {
                "name": "Bientina",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto-Melara",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "Borgo a Buggiano",
                "countryCode": "IT"
            },
            {
                "name": "Borgo a Mozzano",
                "countryCode": "IT"
            },
            {
                "name": "Braccagni",
                "countryCode": "IT"
            },
            {
                "name": "Bucine",
                "countryCode": "IT"
            },
            {
                "name": "Buonconvento",
                "countryCode": "IT"
            },
            {
                "name": "Buti",
                "countryCode": "IT"
            },
            {
                "name": "Calci",
                "countryCode": "IT"
            },
            {
                "name": "Calcinaia",
                "countryCode": "IT"
            },
            {
                "name": "Caldine",
                "countryCode": "IT"
            },
            {
                "name": "Calenzano",
                "countryCode": "IT"
            },
            {
                "name": "Camaiore",
                "countryCode": "IT"
            },
            {
                "name": "Campagnatico",
                "countryCode": "IT"
            },
            {
                "name": "Campi Bisenzio",
                "countryCode": "IT"
            },
            {
                "name": "Campiglia Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Campo",
                "countryCode": "IT"
            },
            {
                "name": "Campo Tizzoro",
                "countryCode": "IT"
            },
            {
                "name": "Campo nell'Elba",
                "countryCode": "IT"
            },
            {
                "name": "Camporgiano",
                "countryCode": "IT"
            },
            {
                "name": "Camucia-Monsigliolo",
                "countryCode": "IT"
            },
            {
                "name": "Cantagallo",
                "countryCode": "IT"
            },
            {
                "name": "Cantagrillo-Casalguidi",
                "countryCode": "IT"
            },
            {
                "name": "Capalbio",
                "countryCode": "IT"
            },
            {
                "name": "Capanne-Prato-Cinquale",
                "countryCode": "IT"
            },
            {
                "name": "Capannoli",
                "countryCode": "IT"
            },
            {
                "name": "Capannori",
                "countryCode": "IT"
            },
            {
                "name": "Capoliveri",
                "countryCode": "IT"
            },
            {
                "name": "Capolona",
                "countryCode": "IT"
            },
            {
                "name": "Capraia Isola",
                "countryCode": "IT"
            },
            {
                "name": "Capraia e Limite",
                "countryCode": "IT"
            },
            {
                "name": "Caprese Michelangelo",
                "countryCode": "IT"
            },
            {
                "name": "Careggine",
                "countryCode": "IT"
            },
            {
                "name": "Carmignano",
                "countryCode": "IT"
            },
            {
                "name": "Carraia",
                "countryCode": "IT"
            },
            {
                "name": "Carrara",
                "countryCode": "IT"
            },
            {
                "name": "Casale Marittimo",
                "countryCode": "IT"
            },
            {
                "name": "Casciana Terme",
                "countryCode": "IT"
            },
            {
                "name": "Cascina",
                "countryCode": "IT"
            },
            {
                "name": "Cascine-La Croce",
                "countryCode": "IT"
            },
            {
                "name": "Casola in Lunigiana",
                "countryCode": "IT"
            },
            {
                "name": "Casole d'Elsa",
                "countryCode": "IT"
            },
            {
                "name": "Castagneto Carducci",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Niccolò",
                "countryCode": "IT"
            },
            {
                "name": "Castel del Piano",
                "countryCode": "IT"
            },
            {
                "name": "Castelfiorentino",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranco di Sopra",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranco di Sotto",
                "countryCode": "IT"
            },
            {
                "name": "Castell'Azzara",
                "countryCode": "IT"
            },
            {
                "name": "Castellina Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Castellina Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Castellina in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Berardenga",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo dei Sabbioni",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Garfagnana",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Val di Cecina",
                "countryCode": "IT"
            },
            {
                "name": "Castiglion Fibocchi",
                "countryCode": "IT"
            },
            {
                "name": "Castiglion Fiorentino",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione d'Orcia",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione della Pescaia",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione di Garfagnana",
                "countryCode": "IT"
            },
            {
                "name": "Catena",
                "countryCode": "IT"
            },
            {
                "name": "Cavallina",
                "countryCode": "IT"
            },
            {
                "name": "Cavriglia-Monastero",
                "countryCode": "IT"
            },
            {
                "name": "Cecina",
                "countryCode": "IT"
            },
            {
                "name": "Cenaia",
                "countryCode": "IT"
            },
            {
                "name": "Cerbaia",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Guidi",
                "countryCode": "IT"
            },
            {
                "name": "Certaldo",
                "countryCode": "IT"
            },
            {
                "name": "Cesa",
                "countryCode": "IT"
            },
            {
                "name": "Cetona",
                "countryCode": "IT"
            },
            {
                "name": "Chianciano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Chianni",
                "countryCode": "IT"
            },
            {
                "name": "Chiassa-Tregozzano",
                "countryCode": "IT"
            },
            {
                "name": "Chiesina Uzzanese",
                "countryCode": "IT"
            },
            {
                "name": "Chiesino-Collodi",
                "countryCode": "IT"
            },
            {
                "name": "Chitignano",
                "countryCode": "IT"
            },
            {
                "name": "Chiusdino",
                "countryCode": "IT"
            },
            {
                "name": "Chiusi",
                "countryCode": "IT"
            },
            {
                "name": "Chiusi Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Chiusi della Verna",
                "countryCode": "IT"
            },
            {
                "name": "Cinigiano",
                "countryCode": "IT"
            },
            {
                "name": "Cintolese",
                "countryCode": "IT"
            },
            {
                "name": "Civitella Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Civitella in Val di Chiana",
                "countryCode": "IT"
            },
            {
                "name": "Colle di Val d'Elsa",
                "countryCode": "IT"
            },
            {
                "name": "Collesalvetti",
                "countryCode": "IT"
            },
            {
                "name": "Comano",
                "countryCode": "IT"
            },
            {
                "name": "Comeana",
                "countryCode": "IT"
            },
            {
                "name": "Compiobbi",
                "countryCode": "IT"
            },
            {
                "name": "Coreglia Antelminelli",
                "countryCode": "IT"
            },
            {
                "name": "Corsanico-Bargecchia",
                "countryCode": "IT"
            },
            {
                "name": "Cortona",
                "countryCode": "IT"
            },
            {
                "name": "Crespina",
                "countryCode": "IT"
            },
            {
                "name": "Cutigliano",
                "countryCode": "IT"
            },
            {
                "name": "Dicomano",
                "countryCode": "IT"
            },
            {
                "name": "Donoratico",
                "countryCode": "IT"
            },
            {
                "name": "Empoli",
                "countryCode": "IT"
            },
            {
                "name": "Fabbriche di Vallico",
                "countryCode": "IT"
            },
            {
                "name": "Faella",
                "countryCode": "IT"
            },
            {
                "name": "Fauglia",
                "countryCode": "IT"
            },
            {
                "name": "Fiesole",
                "countryCode": "IT"
            },
            {
                "name": "Figline Valdarno",
                "countryCode": "IT"
            },
            {
                "name": "Filattiera",
                "countryCode": "IT"
            },
            {
                "name": "Filettole",
                "countryCode": "IT"
            },
            {
                "name": "Firenzuola",
                "countryCode": "IT"
            },
            {
                "name": "Fivizzano",
                "countryCode": "IT"
            },
            {
                "name": "Florence",
                "countryCode": "IT"
            },
            {
                "name": "Foiano della Chiana",
                "countryCode": "IT"
            },
            {
                "name": "Follonica",
                "countryCode": "IT"
            },
            {
                "name": "Fontana delle Monache",
                "countryCode": "IT"
            },
            {
                "name": "Fonteblanda",
                "countryCode": "IT"
            },
            {
                "name": "Forcoli",
                "countryCode": "IT"
            },
            {
                "name": "Fornacelle",
                "countryCode": "IT"
            },
            {
                "name": "Fornacette",
                "countryCode": "IT"
            },
            {
                "name": "Fornaci di Barga",
                "countryCode": "IT"
            },
            {
                "name": "Forte dei Marmi",
                "countryCode": "IT"
            },
            {
                "name": "Fosciandora",
                "countryCode": "IT"
            },
            {
                "name": "Fosdinovo",
                "countryCode": "IT"
            },
            {
                "name": "Fucecchio",
                "countryCode": "IT"
            },
            {
                "name": "Gabella",
                "countryCode": "IT"
            },
            {
                "name": "Gaiole in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Galliano",
                "countryCode": "IT"
            },
            {
                "name": "Gallicano",
                "countryCode": "IT"
            },
            {
                "name": "Gambassi Terme",
                "countryCode": "IT"
            },
            {
                "name": "Gavorrano",
                "countryCode": "IT"
            },
            {
                "name": "Gello",
                "countryCode": "IT"
            },
            {
                "name": "Giglio Castello",
                "countryCode": "IT"
            },
            {
                "name": "Ginestra Fiorentina",
                "countryCode": "IT"
            },
            {
                "name": "Giovi-Ponte alla Chiassa",
                "countryCode": "IT"
            },
            {
                "name": "Giuncugnano",
                "countryCode": "IT"
            },
            {
                "name": "Grassina Ponte a Ema",
                "countryCode": "IT"
            },
            {
                "name": "Greve in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Grosseto",
                "countryCode": "IT"
            },
            {
                "name": "Guardistallo",
                "countryCode": "IT"
            },
            {
                "name": "Guasticce",
                "countryCode": "IT"
            },
            {
                "name": "Guazzino",
                "countryCode": "IT"
            },
            {
                "name": "Il Romito",
                "countryCode": "IT"
            },
            {
                "name": "Impruneta",
                "countryCode": "IT"
            },
            {
                "name": "Incisa in Val d'Arno",
                "countryCode": "IT"
            },
            {
                "name": "Isola D'Arbia",
                "countryCode": "IT"
            },
            {
                "name": "Isola del Giglio",
                "countryCode": "IT"
            },
            {
                "name": "Istia D'Ombrone-Le Stiacciole",
                "countryCode": "IT"
            },
            {
                "name": "La California",
                "countryCode": "IT"
            },
            {
                "name": "La Pieve-Molino",
                "countryCode": "IT"
            },
            {
                "name": "La Rotta",
                "countryCode": "IT"
            },
            {
                "name": "Lajatico",
                "countryCode": "IT"
            },
            {
                "name": "Lamporecchio",
                "countryCode": "IT"
            },
            {
                "name": "Larciano",
                "countryCode": "IT"
            },
            {
                "name": "Lari",
                "countryCode": "IT"
            },
            {
                "name": "Lastra a Signa",
                "countryCode": "IT"
            },
            {
                "name": "Laterina",
                "countryCode": "IT"
            },
            {
                "name": "Lazzeretto",
                "countryCode": "IT"
            },
            {
                "name": "Le Casine-Perignano-Spinelli",
                "countryCode": "IT"
            },
            {
                "name": "Licciana Nardi",
                "countryCode": "IT"
            },
            {
                "name": "Limite",
                "countryCode": "IT"
            },
            {
                "name": "Livorno",
                "countryCode": "IT"
            },
            {
                "name": "Londa",
                "countryCode": "IT"
            },
            {
                "name": "Lorenzana",
                "countryCode": "IT"
            },
            {
                "name": "Loro Ciuffenna",
                "countryCode": "IT"
            },
            {
                "name": "Lucca",
                "countryCode": "IT"
            },
            {
                "name": "Lucignano",
                "countryCode": "IT"
            },
            {
                "name": "Luco Mugello",
                "countryCode": "IT"
            },
            {
                "name": "Madonna dell'Acqua",
                "countryCode": "IT"
            },
            {
                "name": "Magliano in Toscana",
                "countryCode": "IT"
            },
            {
                "name": "Malmantile",
                "countryCode": "IT"
            },
            {
                "name": "Manciano",
                "countryCode": "IT"
            },
            {
                "name": "Mantignano-Ugnano",
                "countryCode": "IT"
            },
            {
                "name": "Marciana",
                "countryCode": "IT"
            },
            {
                "name": "Marciana Marina",
                "countryCode": "IT"
            },
            {
                "name": "Marciano",
                "countryCode": "IT"
            },
            {
                "name": "Marciano della Chiana",
                "countryCode": "IT"
            },
            {
                "name": "Marcignana",
                "countryCode": "IT"
            },
            {
                "name": "Margine Coperta-Traversagna",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Campo",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Carrara",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Grosseto",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Massa",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Pisa",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Pisa-Tirrenia-Calambrone",
                "countryCode": "IT"
            },
            {
                "name": "Marliana",
                "countryCode": "IT"
            },
            {
                "name": "Marradi",
                "countryCode": "IT"
            },
            {
                "name": "Marti",
                "countryCode": "IT"
            },
            {
                "name": "Massa",
                "countryCode": "IT"
            },
            {
                "name": "Massa Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Massarosa",
                "countryCode": "IT"
            },
            {
                "name": "Mercatale",
                "countryCode": "IT"
            },
            {
                "name": "Mercatale-San Quirico",
                "countryCode": "IT"
            },
            {
                "name": "Metato",
                "countryCode": "IT"
            },
            {
                "name": "Migliarino",
                "countryCode": "IT"
            },
            {
                "name": "Minucciano",
                "countryCode": "IT"
            },
            {
                "name": "Molazzana",
                "countryCode": "IT"
            },
            {
                "name": "Molino del Piano",
                "countryCode": "IT"
            },
            {
                "name": "Monsummano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Montaione",
                "countryCode": "IT"
            },
            {
                "name": "Montalcino",
                "countryCode": "IT"
            },
            {
                "name": "Montale",
                "countryCode": "IT"
            },
            {
                "name": "Montalto",
                "countryCode": "IT"
            },
            {
                "name": "Monte Argentario",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Savino",
                "countryCode": "IT"
            },
            {
                "name": "Montebonello",
                "countryCode": "IT"
            },
            {
                "name": "Montecalvoli",
                "countryCode": "IT"
            },
            {
                "name": "Montecarlo",
                "countryCode": "IT"
            },
            {
                "name": "Montecatini Val di Cecina",
                "countryCode": "IT"
            },
            {
                "name": "Montecatini-Terme",
                "countryCode": "IT"
            },
            {
                "name": "Montelupo Fiorentino",
                "countryCode": "IT"
            },
            {
                "name": "Montemignaio",
                "countryCode": "IT"
            },
            {
                "name": "Montemurlo",
                "countryCode": "IT"
            },
            {
                "name": "Montepulciano",
                "countryCode": "IT"
            },
            {
                "name": "Montepulciano Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Monterchi",
                "countryCode": "IT"
            },
            {
                "name": "Monteriggioni",
                "countryCode": "IT"
            },
            {
                "name": "Monteroni d'Arbia",
                "countryCode": "IT"
            },
            {
                "name": "Monterotondo Marittimo",
                "countryCode": "IT"
            },
            {
                "name": "Montescudaio",
                "countryCode": "IT"
            },
            {
                "name": "Montespertoli",
                "countryCode": "IT"
            },
            {
                "name": "Montevarchi",
                "countryCode": "IT"
            },
            {
                "name": "Monteverdi Marittimo",
                "countryCode": "IT"
            },
            {
                "name": "Monti",
                "countryCode": "IT"
            },
            {
                "name": "Monticiano",
                "countryCode": "IT"
            },
            {
                "name": "Montieri",
                "countryCode": "IT"
            },
            {
                "name": "Montignoso",
                "countryCode": "IT"
            },
            {
                "name": "Montopoli",
                "countryCode": "IT"
            },
            {
                "name": "Mulazzo",
                "countryCode": "IT"
            },
            {
                "name": "Murlo",
                "countryCode": "IT"
            },
            {
                "name": "Orbetello",
                "countryCode": "IT"
            },
            {
                "name": "Orbetello Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Orciano Pisano",
                "countryCode": "IT"
            },
            {
                "name": "Orentano",
                "countryCode": "IT"
            },
            {
                "name": "Ortignano Raggiolo",
                "countryCode": "IT"
            },
            {
                "name": "Orzignano",
                "countryCode": "IT"
            },
            {
                "name": "Palaia",
                "countryCode": "IT"
            },
            {
                "name": "Palazzuolo sul Senio",
                "countryCode": "IT"
            },
            {
                "name": "Pallerone",
                "countryCode": "IT"
            },
            {
                "name": "Panzano in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Patigno",
                "countryCode": "IT"
            },
            {
                "name": "Peccioli",
                "countryCode": "IT"
            },
            {
                "name": "Pelago",
                "countryCode": "IT"
            },
            {
                "name": "Pergine Valdarno",
                "countryCode": "IT"
            },
            {
                "name": "Pescaglia",
                "countryCode": "IT"
            },
            {
                "name": "Pescia",
                "countryCode": "IT"
            },
            {
                "name": "Pian di Mugnone",
                "countryCode": "IT"
            },
            {
                "name": "Pian di Scò",
                "countryCode": "IT"
            },
            {
                "name": "Piancastagnaio",
                "countryCode": "IT"
            },
            {
                "name": "Piano di Conca",
                "countryCode": "IT"
            },
            {
                "name": "Piano di Coreglia-Ghivizzano",
                "countryCode": "IT"
            },
            {
                "name": "Piano di Mommio",
                "countryCode": "IT"
            },
            {
                "name": "Piazza al Serchio-San Michele",
                "countryCode": "IT"
            },
            {
                "name": "Pienza",
                "countryCode": "IT"
            },
            {
                "name": "Pietrasanta",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Fosciana",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Pieve a Nievole",
                "countryCode": "IT"
            },
            {
                "name": "Pieve al Toppo",
                "countryCode": "IT"
            },
            {
                "name": "Piombino",
                "countryCode": "IT"
            },
            {
                "name": "Pisa",
                "countryCode": "IT"
            },
            {
                "name": "Pistoia",
                "countryCode": "IT"
            },
            {
                "name": "Piteglio",
                "countryCode": "IT"
            },
            {
                "name": "Pitigliano",
                "countryCode": "IT"
            },
            {
                "name": "Podenzana",
                "countryCode": "IT"
            },
            {
                "name": "Poggetto",
                "countryCode": "IT"
            },
            {
                "name": "Poggibonsi",
                "countryCode": "IT"
            },
            {
                "name": "Poggio A Caiano",
                "countryCode": "IT"
            },
            {
                "name": "Pomarance",
                "countryCode": "IT"
            },
            {
                "name": "Ponsacco",
                "countryCode": "IT"
            },
            {
                "name": "Pontasserchio",
                "countryCode": "IT"
            },
            {
                "name": "Pontassieve",
                "countryCode": "IT"
            },
            {
                "name": "Ponte A Elsa",
                "countryCode": "IT"
            },
            {
                "name": "Ponte A Tressa",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Buggianese",
                "countryCode": "IT"
            },
            {
                "name": "Ponte a Poppi",
                "countryCode": "IT"
            },
            {
                "name": "Pontedera",
                "countryCode": "IT"
            },
            {
                "name": "Pontestazzemese",
                "countryCode": "IT"
            },
            {
                "name": "Ponticino",
                "countryCode": "IT"
            },
            {
                "name": "Pontremoli",
                "countryCode": "IT"
            },
            {
                "name": "Poppi",
                "countryCode": "IT"
            },
            {
                "name": "Porcari",
                "countryCode": "IT"
            },
            {
                "name": "Porto Azzurro",
                "countryCode": "IT"
            },
            {
                "name": "Porto Ercole",
                "countryCode": "IT"
            },
            {
                "name": "Porto Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Portoferraio",
                "countryCode": "IT"
            },
            {
                "name": "Pozzale-Case Nuove",
                "countryCode": "IT"
            },
            {
                "name": "Pratantico-Indicatore",
                "countryCode": "IT"
            },
            {
                "name": "Prato",
                "countryCode": "IT"
            },
            {
                "name": "Pratovecchio",
                "countryCode": "IT"
            },
            {
                "name": "Province of Arezzo",
                "countryCode": "IT"
            },
            {
                "name": "Province of Florence",
                "countryCode": "IT"
            },
            {
                "name": "Province of Pisa",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Grosseto",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Livorno",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Lucca",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Massa-Carrara",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Pistoia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Prato",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Siena",
                "countryCode": "IT"
            },
            {
                "name": "Quarata",
                "countryCode": "IT"
            },
            {
                "name": "Quarrata",
                "countryCode": "IT"
            },
            {
                "name": "Quattro Strade",
                "countryCode": "IT"
            },
            {
                "name": "Quercianella",
                "countryCode": "IT"
            },
            {
                "name": "Radda in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Radicofani",
                "countryCode": "IT"
            },
            {
                "name": "Radicondoli",
                "countryCode": "IT"
            },
            {
                "name": "Rapolano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Rassina",
                "countryCode": "IT"
            },
            {
                "name": "Reggello",
                "countryCode": "IT"
            },
            {
                "name": "Ribolla",
                "countryCode": "IT"
            },
            {
                "name": "Rignano sull'Arno",
                "countryCode": "IT"
            },
            {
                "name": "Rio Marina",
                "countryCode": "IT"
            },
            {
                "name": "Rio nell'Elba",
                "countryCode": "IT"
            },
            {
                "name": "Ripa-Pozzi-Querceta-Ponterosso",
                "countryCode": "IT"
            },
            {
                "name": "Riparbella",
                "countryCode": "IT"
            },
            {
                "name": "Roccalbegna",
                "countryCode": "IT"
            },
            {
                "name": "Roccastrada",
                "countryCode": "IT"
            },
            {
                "name": "Ronta",
                "countryCode": "IT"
            },
            {
                "name": "Rosia",
                "countryCode": "IT"
            },
            {
                "name": "Rosignano Marittimo",
                "countryCode": "IT"
            },
            {
                "name": "Rosignano Solvay-Castiglioncello",
                "countryCode": "IT"
            },
            {
                "name": "Rufina",
                "countryCode": "IT"
            },
            {
                "name": "S.P. in Palazzi",
                "countryCode": "IT"
            },
            {
                "name": "Saline",
                "countryCode": "IT"
            },
            {
                "name": "Sambuca",
                "countryCode": "IT"
            },
            {
                "name": "Sambuca Pistoiese",
                "countryCode": "IT"
            },
            {
                "name": "San Casciano dei Bagni",
                "countryCode": "IT"
            },
            {
                "name": "San Casciano in Val di Pesa",
                "countryCode": "IT"
            },
            {
                "name": "San Cipriano-S.Barbara-Centinale",
                "countryCode": "IT"
            },
            {
                "name": "San Donato",
                "countryCode": "IT"
            },
            {
                "name": "San Donnino",
                "countryCode": "IT"
            },
            {
                "name": "San Francesco",
                "countryCode": "IT"
            },
            {
                "name": "San Gimignano",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Valdarno",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni d'Asso",
                "countryCode": "IT"
            },
            {
                "name": "San Giuliano Terme",
                "countryCode": "IT"
            },
            {
                "name": "San Giustino Valdarno",
                "countryCode": "IT"
            },
            {
                "name": "San Godenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Jacopo al Girone",
                "countryCode": "IT"
            },
            {
                "name": "San Marcello Pistoiese",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Freddana-Monsagrati",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro",
                "countryCode": "IT"
            },
            {
                "name": "San Miniato",
                "countryCode": "IT"
            },
            {
                "name": "San Miniato Basso",
                "countryCode": "IT"
            },
            {
                "name": "San Pierino",
                "countryCode": "IT"
            },
            {
                "name": "San Piero a Sieve",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Belvedere",
                "countryCode": "IT"
            },
            {
                "name": "San Quirico d'Orcia",
                "countryCode": "IT"
            },
            {
                "name": "San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "San Romano",
                "countryCode": "IT"
            },
            {
                "name": "San Romano in Garfagnana",
                "countryCode": "IT"
            },
            {
                "name": "San Vincenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Vito-Cerreto",
                "countryCode": "IT"
            },
            {
                "name": "Sansepolcro",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Albino",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce sull'Arno",
                "countryCode": "IT"
            },
            {
                "name": "Santa Fiora",
                "countryCode": "IT"
            },
            {
                "name": "Santa Luce",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria a Monte",
                "countryCode": "IT"
            },
            {
                "name": "Sarteano",
                "countryCode": "IT"
            },
            {
                "name": "Sassetta",
                "countryCode": "IT"
            },
            {
                "name": "Scandicci",
                "countryCode": "IT"
            },
            {
                "name": "Scansano",
                "countryCode": "IT"
            },
            {
                "name": "Scarlino",
                "countryCode": "IT"
            },
            {
                "name": "Scarlino Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Scarperia",
                "countryCode": "IT"
            },
            {
                "name": "Seano",
                "countryCode": "IT"
            },
            {
                "name": "Seggiano",
                "countryCode": "IT"
            },
            {
                "name": "Selvatelle",
                "countryCode": "IT"
            },
            {
                "name": "Semproniano",
                "countryCode": "IT"
            },
            {
                "name": "Seravezza",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle Pistoiese",
                "countryCode": "IT"
            },
            {
                "name": "Serre di Rapolano",
                "countryCode": "IT"
            },
            {
                "name": "Sestino",
                "countryCode": "IT"
            },
            {
                "name": "Sesto Fiorentino",
                "countryCode": "IT"
            },
            {
                "name": "Sieci",
                "countryCode": "IT"
            },
            {
                "name": "Siena",
                "countryCode": "IT"
            },
            {
                "name": "Signa",
                "countryCode": "IT"
            },
            {
                "name": "Sillano",
                "countryCode": "IT"
            },
            {
                "name": "Sinalunga",
                "countryCode": "IT"
            },
            {
                "name": "Soci",
                "countryCode": "IT"
            },
            {
                "name": "Sorano",
                "countryCode": "IT"
            },
            {
                "name": "Sovicille",
                "countryCode": "IT"
            },
            {
                "name": "Spianate",
                "countryCode": "IT"
            },
            {
                "name": "Spicchio-Sovigliana",
                "countryCode": "IT"
            },
            {
                "name": "Stabbia",
                "countryCode": "IT"
            },
            {
                "name": "Staggia",
                "countryCode": "IT"
            },
            {
                "name": "Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Stazione Masotti",
                "countryCode": "IT"
            },
            {
                "name": "Stia",
                "countryCode": "IT"
            },
            {
                "name": "Stiava",
                "countryCode": "IT"
            },
            {
                "name": "Strada",
                "countryCode": "IT"
            },
            {
                "name": "Strada in Chianti",
                "countryCode": "IT"
            },
            {
                "name": "Strettoia",
                "countryCode": "IT"
            },
            {
                "name": "Subbiano",
                "countryCode": "IT"
            },
            {
                "name": "Suvereto",
                "countryCode": "IT"
            },
            {
                "name": "Talla",
                "countryCode": "IT"
            },
            {
                "name": "Tavarnelle Val di Pesa",
                "countryCode": "IT"
            },
            {
                "name": "Tavarnuzze",
                "countryCode": "IT"
            },
            {
                "name": "Taverne D'Arbia",
                "countryCode": "IT"
            },
            {
                "name": "Tegoleto",
                "countryCode": "IT"
            },
            {
                "name": "Terontola",
                "countryCode": "IT"
            },
            {
                "name": "Terranuova Bracciolini",
                "countryCode": "IT"
            },
            {
                "name": "Terrarossa",
                "countryCode": "IT"
            },
            {
                "name": "Terricciola",
                "countryCode": "IT"
            },
            {
                "name": "Torre del Lago Puccini",
                "countryCode": "IT"
            },
            {
                "name": "Torrenieri",
                "countryCode": "IT"
            },
            {
                "name": "Torrita di Siena",
                "countryCode": "IT"
            },
            {
                "name": "Treggiaia",
                "countryCode": "IT"
            },
            {
                "name": "Trequanda",
                "countryCode": "IT"
            },
            {
                "name": "Tresana",
                "countryCode": "IT"
            },
            {
                "name": "Uggia-Pazzera-Bizzarrino",
                "countryCode": "IT"
            },
            {
                "name": "Uliveto Terme",
                "countryCode": "IT"
            },
            {
                "name": "Uzzano",
                "countryCode": "IT"
            },
            {
                "name": "Vacchereccia",
                "countryCode": "IT"
            },
            {
                "name": "Vada",
                "countryCode": "IT"
            },
            {
                "name": "Vaglia",
                "countryCode": "IT"
            },
            {
                "name": "Vaiano",
                "countryCode": "IT"
            },
            {
                "name": "Val di Cava",
                "countryCode": "IT"
            },
            {
                "name": "Valdottavo",
                "countryCode": "IT"
            },
            {
                "name": "Valenzatico",
                "countryCode": "IT"
            },
            {
                "name": "Vecchiano-Nodica",
                "countryCode": "IT"
            },
            {
                "name": "Venturina",
                "countryCode": "IT"
            },
            {
                "name": "Vernio",
                "countryCode": "IT"
            },
            {
                "name": "Vescovado",
                "countryCode": "IT"
            },
            {
                "name": "Viareggio",
                "countryCode": "IT"
            },
            {
                "name": "Vicarello",
                "countryCode": "IT"
            },
            {
                "name": "Vicchio",
                "countryCode": "IT"
            },
            {
                "name": "Viciomaggio",
                "countryCode": "IT"
            },
            {
                "name": "Vicopisano",
                "countryCode": "IT"
            },
            {
                "name": "Vignale Riotorto",
                "countryCode": "IT"
            },
            {
                "name": "Vignole-Olmi",
                "countryCode": "IT"
            },
            {
                "name": "Villa Basilica",
                "countryCode": "IT"
            },
            {
                "name": "Villa Collemandina",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca in Lunigiana",
                "countryCode": "IT"
            },
            {
                "name": "Vinci",
                "countryCode": "IT"
            },
            {
                "name": "Volterra",
                "countryCode": "IT"
            },
            {
                "name": "Zeri",
                "countryCode": "IT"
            },
            {
                "name": "Acquasparta",
                "countryCode": "IT"
            },
            {
                "name": "Allerona",
                "countryCode": "IT"
            },
            {
                "name": "Alviano",
                "countryCode": "IT"
            },
            {
                "name": "Amelia",
                "countryCode": "IT"
            },
            {
                "name": "Arrone",
                "countryCode": "IT"
            },
            {
                "name": "Assisi",
                "countryCode": "IT"
            },
            {
                "name": "Attigliano",
                "countryCode": "IT"
            },
            {
                "name": "Avigliano Umbro",
                "countryCode": "IT"
            },
            {
                "name": "Baschi",
                "countryCode": "IT"
            },
            {
                "name": "Bastardo",
                "countryCode": "IT"
            },
            {
                "name": "Bastia umbra",
                "countryCode": "IT"
            },
            {
                "name": "Bettona",
                "countryCode": "IT"
            },
            {
                "name": "Bevagna",
                "countryCode": "IT"
            },
            {
                "name": "Bruna",
                "countryCode": "IT"
            },
            {
                "name": "Calvi dell'Umbria",
                "countryCode": "IT"
            },
            {
                "name": "Campello sul Clitunno",
                "countryCode": "IT"
            },
            {
                "name": "Cannara",
                "countryCode": "IT"
            },
            {
                "name": "Capitan Loreto",
                "countryCode": "IT"
            },
            {
                "name": "Casa del Diavolo",
                "countryCode": "IT"
            },
            {
                "name": "Cascia",
                "countryCode": "IT"
            },
            {
                "name": "Castel Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Castel Ritaldi",
                "countryCode": "IT"
            },
            {
                "name": "Castel Viscardo",
                "countryCode": "IT"
            },
            {
                "name": "Casteltodino",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione del Lago",
                "countryCode": "IT"
            },
            {
                "name": "Cerbara",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto di Spoleto",
                "countryCode": "IT"
            },
            {
                "name": "Chiugiana-La Commenda",
                "countryCode": "IT"
            },
            {
                "name": "Ciconia",
                "countryCode": "IT"
            },
            {
                "name": "Cipolleto",
                "countryCode": "IT"
            },
            {
                "name": "Citerna",
                "countryCode": "IT"
            },
            {
                "name": "Città della Pieve",
                "countryCode": "IT"
            },
            {
                "name": "Città di Castello",
                "countryCode": "IT"
            },
            {
                "name": "Collazzone",
                "countryCode": "IT"
            },
            {
                "name": "Collepepe",
                "countryCode": "IT"
            },
            {
                "name": "Colombella",
                "countryCode": "IT"
            },
            {
                "name": "Corciano",
                "countryCode": "IT"
            },
            {
                "name": "Costacciaro",
                "countryCode": "IT"
            },
            {
                "name": "Costano",
                "countryCode": "IT"
            },
            {
                "name": "Deruta",
                "countryCode": "IT"
            },
            {
                "name": "Fabro",
                "countryCode": "IT"
            },
            {
                "name": "Fabro Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Ferentillo",
                "countryCode": "IT"
            },
            {
                "name": "Ficulle",
                "countryCode": "IT"
            },
            {
                "name": "Foligno",
                "countryCode": "IT"
            },
            {
                "name": "Fontignano",
                "countryCode": "IT"
            },
            {
                "name": "Fornole",
                "countryCode": "IT"
            },
            {
                "name": "Fossato di Vico",
                "countryCode": "IT"
            },
            {
                "name": "Fratta Todina",
                "countryCode": "IT"
            },
            {
                "name": "Giano dell'Umbria",
                "countryCode": "IT"
            },
            {
                "name": "Giove",
                "countryCode": "IT"
            },
            {
                "name": "Gualdo Cattaneo",
                "countryCode": "IT"
            },
            {
                "name": "Gualdo Tadino",
                "countryCode": "IT"
            },
            {
                "name": "Guardea",
                "countryCode": "IT"
            },
            {
                "name": "Gubbio",
                "countryCode": "IT"
            },
            {
                "name": "Lacugnano",
                "countryCode": "IT"
            },
            {
                "name": "Lisciano Niccone",
                "countryCode": "IT"
            },
            {
                "name": "Lugnano in Teverina",
                "countryCode": "IT"
            },
            {
                "name": "Magione",
                "countryCode": "IT"
            },
            {
                "name": "Mantignana",
                "countryCode": "IT"
            },
            {
                "name": "Marsciano",
                "countryCode": "IT"
            },
            {
                "name": "Massa Martana",
                "countryCode": "IT"
            },
            {
                "name": "Meggiano",
                "countryCode": "IT"
            },
            {
                "name": "Monte Castello di Vibio",
                "countryCode": "IT"
            },
            {
                "name": "Monte Grimano",
                "countryCode": "IT"
            },
            {
                "name": "Monte Santa Maria Tiberina",
                "countryCode": "IT"
            },
            {
                "name": "Montecastrilli",
                "countryCode": "IT"
            },
            {
                "name": "Montecchio",
                "countryCode": "IT"
            },
            {
                "name": "Montefalco",
                "countryCode": "IT"
            },
            {
                "name": "Montefranco",
                "countryCode": "IT"
            },
            {
                "name": "Montegabbione",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone d'Orvieto",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone di Spoleto",
                "countryCode": "IT"
            },
            {
                "name": "Montone",
                "countryCode": "IT"
            },
            {
                "name": "Narni",
                "countryCode": "IT"
            },
            {
                "name": "Narni Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Nocera Umbra",
                "countryCode": "IT"
            },
            {
                "name": "Norcia",
                "countryCode": "IT"
            },
            {
                "name": "Orvieto",
                "countryCode": "IT"
            },
            {
                "name": "Orvieto Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Oscano",
                "countryCode": "IT"
            },
            {
                "name": "Ospedalicchio",
                "countryCode": "IT"
            },
            {
                "name": "Osteria del Gatto",
                "countryCode": "IT"
            },
            {
                "name": "Otricoli",
                "countryCode": "IT"
            },
            {
                "name": "Paciano",
                "countryCode": "IT"
            },
            {
                "name": "Padule-San Marco",
                "countryCode": "IT"
            },
            {
                "name": "Palazzo",
                "countryCode": "IT"
            },
            {
                "name": "Panicale",
                "countryCode": "IT"
            },
            {
                "name": "Pantalla",
                "countryCode": "IT"
            },
            {
                "name": "Parrano",
                "countryCode": "IT"
            },
            {
                "name": "Passaggio",
                "countryCode": "IT"
            },
            {
                "name": "Passignano sul Trasimeno",
                "countryCode": "IT"
            },
            {
                "name": "Penna in Teverina",
                "countryCode": "IT"
            },
            {
                "name": "Perugia",
                "countryCode": "IT"
            },
            {
                "name": "Petrignano",
                "countryCode": "IT"
            },
            {
                "name": "Piegaro",
                "countryCode": "IT"
            },
            {
                "name": "Pietralunga",
                "countryCode": "IT"
            },
            {
                "name": "Pila",
                "countryCode": "IT"
            },
            {
                "name": "Pistrino",
                "countryCode": "IT"
            },
            {
                "name": "Poggiodomo",
                "countryCode": "IT"
            },
            {
                "name": "Polino",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Felcino",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Pattoli",
                "countryCode": "IT"
            },
            {
                "name": "Ponterio-Pian di Porto",
                "countryCode": "IT"
            },
            {
                "name": "Porano",
                "countryCode": "IT"
            },
            {
                "name": "Pozzuolo",
                "countryCode": "IT"
            },
            {
                "name": "Preci",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Perugia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Terni",
                "countryCode": "IT"
            },
            {
                "name": "Rivotorto",
                "countryCode": "IT"
            },
            {
                "name": "San Gemini",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "San Giustino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Campo",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Trignano",
                "countryCode": "IT"
            },
            {
                "name": "San Niccolò di Celle",
                "countryCode": "IT"
            },
            {
                "name": "San Secondo",
                "countryCode": "IT"
            },
            {
                "name": "San Terenziano",
                "countryCode": "IT"
            },
            {
                "name": "San Venanzo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Anatolia di Narco",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria degli Angeli",
                "countryCode": "IT"
            },
            {
                "name": "Scheggia",
                "countryCode": "IT"
            },
            {
                "name": "Scheggino",
                "countryCode": "IT"
            },
            {
                "name": "Selci-Lama",
                "countryCode": "IT"
            },
            {
                "name": "Sellano",
                "countryCode": "IT"
            },
            {
                "name": "Semonte-Casamorcia",
                "countryCode": "IT"
            },
            {
                "name": "Sferracavallo",
                "countryCode": "IT"
            },
            {
                "name": "Sigillo",
                "countryCode": "IT"
            },
            {
                "name": "Spello",
                "countryCode": "IT"
            },
            {
                "name": "Spoleto",
                "countryCode": "IT"
            },
            {
                "name": "Stazione di Allerona",
                "countryCode": "IT"
            },
            {
                "name": "Stazione di Padule",
                "countryCode": "IT"
            },
            {
                "name": "Stroncone",
                "countryCode": "IT"
            },
            {
                "name": "Tavernelle",
                "countryCode": "IT"
            },
            {
                "name": "Terni",
                "countryCode": "IT"
            },
            {
                "name": "Todi",
                "countryCode": "IT"
            },
            {
                "name": "Torgiano",
                "countryCode": "IT"
            },
            {
                "name": "Trestina",
                "countryCode": "IT"
            },
            {
                "name": "Trevi",
                "countryCode": "IT"
            },
            {
                "name": "Tuoro sul Trasimeno",
                "countryCode": "IT"
            },
            {
                "name": "Umbertide",
                "countryCode": "IT"
            },
            {
                "name": "Valfabbrica",
                "countryCode": "IT"
            },
            {
                "name": "Valtopina",
                "countryCode": "IT"
            },
            {
                "name": "Via Lippia",
                "countryCode": "IT"
            },
            {
                "name": "Acqualagna",
                "countryCode": "IT"
            },
            {
                "name": "Acquasanta Terme",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva Picena",
                "countryCode": "IT"
            },
            {
                "name": "Agugliano",
                "countryCode": "IT"
            },
            {
                "name": "Altidona",
                "countryCode": "IT"
            },
            {
                "name": "Amandola",
                "countryCode": "IT"
            },
            {
                "name": "Ancona",
                "countryCode": "IT"
            },
            {
                "name": "Apecchio",
                "countryCode": "IT"
            },
            {
                "name": "Apiro",
                "countryCode": "IT"
            },
            {
                "name": "Appignano",
                "countryCode": "IT"
            },
            {
                "name": "Appignano del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "Arcevia",
                "countryCode": "IT"
            },
            {
                "name": "Arquata del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "Ascoli Piceno",
                "countryCode": "IT"
            },
            {
                "name": "Auditore",
                "countryCode": "IT"
            },
            {
                "name": "Balzo",
                "countryCode": "IT"
            },
            {
                "name": "Barbara",
                "countryCode": "IT"
            },
            {
                "name": "Barchi",
                "countryCode": "IT"
            },
            {
                "name": "Belforte",
                "countryCode": "IT"
            },
            {
                "name": "Belforte del Chienti",
                "countryCode": "IT"
            },
            {
                "name": "Bellocchi",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte Piceno",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere Ostrense",
                "countryCode": "IT"
            },
            {
                "name": "Bolognola",
                "countryCode": "IT"
            },
            {
                "name": "Borghetto",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Massano",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Pace",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Bottega",
                "countryCode": "IT"
            },
            {
                "name": "Cagli",
                "countryCode": "IT"
            },
            {
                "name": "Calcinelli",
                "countryCode": "IT"
            },
            {
                "name": "Caldarola",
                "countryCode": "IT"
            },
            {
                "name": "Camerano",
                "countryCode": "IT"
            },
            {
                "name": "Camerata Picena",
                "countryCode": "IT"
            },
            {
                "name": "Camerino",
                "countryCode": "IT"
            },
            {
                "name": "Campiglione",
                "countryCode": "IT"
            },
            {
                "name": "Campocavallo",
                "countryCode": "IT"
            },
            {
                "name": "Campofilone",
                "countryCode": "IT"
            },
            {
                "name": "Camporotondo di Fiastrone",
                "countryCode": "IT"
            },
            {
                "name": "Canavaccio",
                "countryCode": "IT"
            },
            {
                "name": "Cantiano",
                "countryCode": "IT"
            },
            {
                "name": "Capodarco",
                "countryCode": "IT"
            },
            {
                "name": "Cappone",
                "countryCode": "IT"
            },
            {
                "name": "Carassai",
                "countryCode": "IT"
            },
            {
                "name": "Carpegna",
                "countryCode": "IT"
            },
            {
                "name": "Cartoceto",
                "countryCode": "IT"
            },
            {
                "name": "Cascinare",
                "countryCode": "IT"
            },
            {
                "name": "Casette Verdini",
                "countryCode": "IT"
            },
            {
                "name": "Casette d'Ete",
                "countryCode": "IT"
            },
            {
                "name": "Casine",
                "countryCode": "IT"
            },
            {
                "name": "Casinina",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Lama",
                "countryCode": "IT"
            },
            {
                "name": "Castelbellino",
                "countryCode": "IT"
            },
            {
                "name": "Castelfidardo",
                "countryCode": "IT"
            },
            {
                "name": "Castelleone di Suasa",
                "countryCode": "IT"
            },
            {
                "name": "Castelplanio",
                "countryCode": "IT"
            },
            {
                "name": "Castelraimondo",
                "countryCode": "IT"
            },
            {
                "name": "Castelsantangelo sul Nera",
                "countryCode": "IT"
            },
            {
                "name": "Castignano",
                "countryCode": "IT"
            },
            {
                "name": "Castorano",
                "countryCode": "IT"
            },
            {
                "name": "Centinarola",
                "countryCode": "IT"
            },
            {
                "name": "Centobuchi",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto d'Esi",
                "countryCode": "IT"
            },
            {
                "name": "Cessapalombo",
                "countryCode": "IT"
            },
            {
                "name": "Chiaravalle",
                "countryCode": "IT"
            },
            {
                "name": "Cingoli",
                "countryCode": "IT"
            },
            {
                "name": "Civitanova Alta",
                "countryCode": "IT"
            },
            {
                "name": "Civitanova Marche",
                "countryCode": "IT"
            },
            {
                "name": "Colbordolo",
                "countryCode": "IT"
            },
            {
                "name": "Colli del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "Colmurano",
                "countryCode": "IT"
            },
            {
                "name": "Comunanza",
                "countryCode": "IT"
            },
            {
                "name": "Corinaldo",
                "countryCode": "IT"
            },
            {
                "name": "Corridonia",
                "countryCode": "IT"
            },
            {
                "name": "Cossignano",
                "countryCode": "IT"
            },
            {
                "name": "Cuccurano",
                "countryCode": "IT"
            },
            {
                "name": "Cupra Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Cupramontana",
                "countryCode": "IT"
            },
            {
                "name": "Esanatoglia",
                "countryCode": "IT"
            },
            {
                "name": "Fabriano",
                "countryCode": "IT"
            },
            {
                "name": "Falconara Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Falerone",
                "countryCode": "IT"
            },
            {
                "name": "Fanano",
                "countryCode": "IT"
            },
            {
                "name": "Fano",
                "countryCode": "IT"
            },
            {
                "name": "Fenile",
                "countryCode": "IT"
            },
            {
                "name": "Fermignano",
                "countryCode": "IT"
            },
            {
                "name": "Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Filottrano",
                "countryCode": "IT"
            },
            {
                "name": "Folignano",
                "countryCode": "IT"
            },
            {
                "name": "Force",
                "countryCode": "IT"
            },
            {
                "name": "Fossombrone",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla d'Ete",
                "countryCode": "IT"
            },
            {
                "name": "Fratte Rosa",
                "countryCode": "IT"
            },
            {
                "name": "Frontino",
                "countryCode": "IT"
            },
            {
                "name": "Frontone",
                "countryCode": "IT"
            },
            {
                "name": "Gabicce Mare",
                "countryCode": "IT"
            },
            {
                "name": "Gagliole",
                "countryCode": "IT"
            },
            {
                "name": "Gallo",
                "countryCode": "IT"
            },
            {
                "name": "Genga",
                "countryCode": "IT"
            },
            {
                "name": "Gradara",
                "countryCode": "IT"
            },
            {
                "name": "Grottammare",
                "countryCode": "IT"
            },
            {
                "name": "Grottazzolina",
                "countryCode": "IT"
            },
            {
                "name": "Gualdo",
                "countryCode": "IT"
            },
            {
                "name": "Isola del Piano",
                "countryCode": "IT"
            },
            {
                "name": "Jesi",
                "countryCode": "IT"
            },
            {
                "name": "Lapedona",
                "countryCode": "IT"
            },
            {
                "name": "Le Grazie di Ancona",
                "countryCode": "IT"
            },
            {
                "name": "Lido di Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Loreto",
                "countryCode": "IT"
            },
            {
                "name": "Loreto Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Loro Piceno",
                "countryCode": "IT"
            },
            {
                "name": "Lucrezia",
                "countryCode": "IT"
            },
            {
                "name": "Lunano",
                "countryCode": "IT"
            },
            {
                "name": "Macerata",
                "countryCode": "IT"
            },
            {
                "name": "Macerata Feltria",
                "countryCode": "IT"
            },
            {
                "name": "Macine-Borgo Loreto",
                "countryCode": "IT"
            },
            {
                "name": "Magliano di Tenna",
                "countryCode": "IT"
            },
            {
                "name": "Maiolati Spontini",
                "countryCode": "IT"
            },
            {
                "name": "Maltignano",
                "countryCode": "IT"
            },
            {
                "name": "Marcelli",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Altidona",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Montemarciano",
                "countryCode": "IT"
            },
            {
                "name": "Marotta",
                "countryCode": "IT"
            },
            {
                "name": "Marsia",
                "countryCode": "IT"
            },
            {
                "name": "Massa",
                "countryCode": "IT"
            },
            {
                "name": "Massa Fermana",
                "countryCode": "IT"
            },
            {
                "name": "Massignano",
                "countryCode": "IT"
            },
            {
                "name": "Matelica",
                "countryCode": "IT"
            },
            {
                "name": "Mercatale",
                "countryCode": "IT"
            },
            {
                "name": "Mercatello sul Metauro",
                "countryCode": "IT"
            },
            {
                "name": "Mercatino Conca",
                "countryCode": "IT"
            },
            {
                "name": "Mercato Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "Mergo",
                "countryCode": "IT"
            },
            {
                "name": "Mogliano",
                "countryCode": "IT"
            },
            {
                "name": "Moie",
                "countryCode": "IT"
            },
            {
                "name": "Mombaroccio",
                "countryCode": "IT"
            },
            {
                "name": "Mondavio",
                "countryCode": "IT"
            },
            {
                "name": "Mondolfo",
                "countryCode": "IT"
            },
            {
                "name": "Monsampietro Morico",
                "countryCode": "IT"
            },
            {
                "name": "Monsampolo del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "Monsano",
                "countryCode": "IT"
            },
            {
                "name": "Montalto delle Marche",
                "countryCode": "IT"
            },
            {
                "name": "Montappone",
                "countryCode": "IT"
            },
            {
                "name": "Monte Cerignone",
                "countryCode": "IT"
            },
            {
                "name": "Monte Giberto",
                "countryCode": "IT"
            },
            {
                "name": "Monte Grimano Terme",
                "countryCode": "IT"
            },
            {
                "name": "Monte Porzio",
                "countryCode": "IT"
            },
            {
                "name": "Monte Rinaldo",
                "countryCode": "IT"
            },
            {
                "name": "Monte Roberto",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Giusto",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Pietrangeli",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Monte Urano",
                "countryCode": "IT"
            },
            {
                "name": "Monte Vidon Combatte",
                "countryCode": "IT"
            },
            {
                "name": "Monte Vidon Corrado",
                "countryCode": "IT"
            },
            {
                "name": "Montecalvo in Foglia",
                "countryCode": "IT"
            },
            {
                "name": "Montecarotto",
                "countryCode": "IT"
            },
            {
                "name": "Montecassiano",
                "countryCode": "IT"
            },
            {
                "name": "Monteciccardo",
                "countryCode": "IT"
            },
            {
                "name": "Montecosaro",
                "countryCode": "IT"
            },
            {
                "name": "Montedinove",
                "countryCode": "IT"
            },
            {
                "name": "Montefalcone Appennino",
                "countryCode": "IT"
            },
            {
                "name": "Montefano",
                "countryCode": "IT"
            },
            {
                "name": "Montefelcino",
                "countryCode": "IT"
            },
            {
                "name": "Montefiore dell'Aso",
                "countryCode": "IT"
            },
            {
                "name": "Montefortino",
                "countryCode": "IT"
            },
            {
                "name": "Montegiorgio",
                "countryCode": "IT"
            },
            {
                "name": "Montegranaro",
                "countryCode": "IT"
            },
            {
                "name": "Montelabbate",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone di Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Montelparo",
                "countryCode": "IT"
            },
            {
                "name": "Montelupone",
                "countryCode": "IT"
            },
            {
                "name": "Montemaggiore al Metauro",
                "countryCode": "IT"
            },
            {
                "name": "Montemarciano",
                "countryCode": "IT"
            },
            {
                "name": "Montemonaco",
                "countryCode": "IT"
            },
            {
                "name": "Monteprandone",
                "countryCode": "IT"
            },
            {
                "name": "Monterado",
                "countryCode": "IT"
            },
            {
                "name": "Monterubbiano",
                "countryCode": "IT"
            },
            {
                "name": "Montignano-Marzocca",
                "countryCode": "IT"
            },
            {
                "name": "Montottone",
                "countryCode": "IT"
            },
            {
                "name": "Moresco",
                "countryCode": "IT"
            },
            {
                "name": "Morro d'Alba",
                "countryCode": "IT"
            },
            {
                "name": "Morrovalle",
                "countryCode": "IT"
            },
            {
                "name": "Muccia",
                "countryCode": "IT"
            },
            {
                "name": "Numana",
                "countryCode": "IT"
            },
            {
                "name": "Offagna",
                "countryCode": "IT"
            },
            {
                "name": "Offida",
                "countryCode": "IT"
            },
            {
                "name": "Orciano di Pesaro",
                "countryCode": "IT"
            },
            {
                "name": "Ortezzano",
                "countryCode": "IT"
            },
            {
                "name": "Osimo",
                "countryCode": "IT"
            },
            {
                "name": "Osteria",
                "countryCode": "IT"
            },
            {
                "name": "Ostra",
                "countryCode": "IT"
            },
            {
                "name": "Ostra Vetere",
                "countryCode": "IT"
            },
            {
                "name": "Padiglione",
                "countryCode": "IT"
            },
            {
                "name": "Pagliare",
                "countryCode": "IT"
            },
            {
                "name": "Palmiano",
                "countryCode": "IT"
            },
            {
                "name": "Passo Ripe",
                "countryCode": "IT"
            },
            {
                "name": "Passo di Treia",
                "countryCode": "IT"
            },
            {
                "name": "Pedaso",
                "countryCode": "IT"
            },
            {
                "name": "Peglio",
                "countryCode": "IT"
            },
            {
                "name": "Penna San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Pergola",
                "countryCode": "IT"
            },
            {
                "name": "Pesaro",
                "countryCode": "IT"
            },
            {
                "name": "Petriano",
                "countryCode": "IT"
            },
            {
                "name": "Petriolo",
                "countryCode": "IT"
            },
            {
                "name": "Petritoli",
                "countryCode": "IT"
            },
            {
                "name": "Piagge",
                "countryCode": "IT"
            },
            {
                "name": "Piandimeleto",
                "countryCode": "IT"
            },
            {
                "name": "Piane",
                "countryCode": "IT"
            },
            {
                "name": "Piane di Montegiorgio",
                "countryCode": "IT"
            },
            {
                "name": "Piane di Morro",
                "countryCode": "IT"
            },
            {
                "name": "Pianello",
                "countryCode": "IT"
            },
            {
                "name": "Pianello Vallesina",
                "countryCode": "IT"
            },
            {
                "name": "Piattoni-Villa Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Pie' del Colle",
                "countryCode": "IT"
            },
            {
                "name": "Pie' del Sasso",
                "countryCode": "IT"
            },
            {
                "name": "Piediripa",
                "countryCode": "IT"
            },
            {
                "name": "Pietra la Croce",
                "countryCode": "IT"
            },
            {
                "name": "Pieve Torina",
                "countryCode": "IT"
            },
            {
                "name": "Pievebovigliana",
                "countryCode": "IT"
            },
            {
                "name": "Pinocchio di Ancona",
                "countryCode": "IT"
            },
            {
                "name": "Piobbico",
                "countryCode": "IT"
            },
            {
                "name": "Pioraco",
                "countryCode": "IT"
            },
            {
                "name": "Poggio San Marcello",
                "countryCode": "IT"
            },
            {
                "name": "Poggio San Vicino",
                "countryCode": "IT"
            },
            {
                "name": "Pollenza",
                "countryCode": "IT"
            },
            {
                "name": "Polverigi",
                "countryCode": "IT"
            },
            {
                "name": "Ponzano di Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Porto Potenza Picena",
                "countryCode": "IT"
            },
            {
                "name": "Porto Recanati",
                "countryCode": "IT"
            },
            {
                "name": "Porto San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Porto Sant'Elpidio",
                "countryCode": "IT"
            },
            {
                "name": "Posatora",
                "countryCode": "IT"
            },
            {
                "name": "Potenza Picena",
                "countryCode": "IT"
            },
            {
                "name": "Province of Fermo",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Ancona",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Ascoli Piceno",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Macerata",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Pesaro e Urbino",
                "countryCode": "IT"
            },
            {
                "name": "Rapagnano",
                "countryCode": "IT"
            },
            {
                "name": "Recanati",
                "countryCode": "IT"
            },
            {
                "name": "Rio Salso-Case Bernardi",
                "countryCode": "IT"
            },
            {
                "name": "Ripatransone",
                "countryCode": "IT"
            },
            {
                "name": "Ripe",
                "countryCode": "IT"
            },
            {
                "name": "Ripe San Ginesio",
                "countryCode": "IT"
            },
            {
                "name": "Rosciano",
                "countryCode": "IT"
            },
            {
                "name": "Rosora",
                "countryCode": "IT"
            },
            {
                "name": "Rotella",
                "countryCode": "IT"
            },
            {
                "name": "Saltara",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio",
                "countryCode": "IT"
            },
            {
                "name": "San Costanzo",
                "countryCode": "IT"
            },
            {
                "name": "San Ginesio",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio di Pesaro",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo in Campo",
                "countryCode": "IT"
            },
            {
                "name": "San Marcello",
                "countryCode": "IT"
            },
            {
                "name": "San Michele",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo di Jesi",
                "countryCode": "IT"
            },
            {
                "name": "San Severino Marche",
                "countryCode": "IT"
            },
            {
                "name": "San Tommaso Tre Archi",
                "countryCode": "IT"
            },
            {
                "name": "San Vittoria in Matenano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo in Lizzola",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo in Pontano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo in Vado",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elpidio a Mare",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ippolito",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Apparente",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Sarnano",
                "countryCode": "IT"
            },
            {
                "name": "Sassocorvaro",
                "countryCode": "IT"
            },
            {
                "name": "Sassofeltrio",
                "countryCode": "IT"
            },
            {
                "name": "Sassoferrato",
                "countryCode": "IT"
            },
            {
                "name": "Sefro",
                "countryCode": "IT"
            },
            {
                "name": "Senigallia",
                "countryCode": "IT"
            },
            {
                "name": "Serra San Quirico",
                "countryCode": "IT"
            },
            {
                "name": "Serra Sant'Abbondio",
                "countryCode": "IT"
            },
            {
                "name": "Serra de' Conti",
                "countryCode": "IT"
            },
            {
                "name": "Serrapetrona",
                "countryCode": "IT"
            },
            {
                "name": "Serravalle di Chienti",
                "countryCode": "IT"
            },
            {
                "name": "Serrungarina",
                "countryCode": "IT"
            },
            {
                "name": "Servigliano",
                "countryCode": "IT"
            },
            {
                "name": "Sforzacosta",
                "countryCode": "IT"
            },
            {
                "name": "Sirolo",
                "countryCode": "IT"
            },
            {
                "name": "Smerillo",
                "countryCode": "IT"
            },
            {
                "name": "Spinetoli",
                "countryCode": "IT"
            },
            {
                "name": "Staffolo",
                "countryCode": "IT"
            },
            {
                "name": "Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Stella",
                "countryCode": "IT"
            },
            {
                "name": "Strada",
                "countryCode": "IT"
            },
            {
                "name": "Tavernelle",
                "countryCode": "IT"
            },
            {
                "name": "Tavoleto",
                "countryCode": "IT"
            },
            {
                "name": "Tavullia",
                "countryCode": "IT"
            },
            {
                "name": "Tolentino",
                "countryCode": "IT"
            },
            {
                "name": "Torre San Patrizio",
                "countryCode": "IT"
            },
            {
                "name": "Trebbio",
                "countryCode": "IT"
            },
            {
                "name": "Treia",
                "countryCode": "IT"
            },
            {
                "name": "Trodica",
                "countryCode": "IT"
            },
            {
                "name": "Troviggiano",
                "countryCode": "IT"
            },
            {
                "name": "Urbania",
                "countryCode": "IT"
            },
            {
                "name": "Urbino",
                "countryCode": "IT"
            },
            {
                "name": "Urbisaglia",
                "countryCode": "IT"
            },
            {
                "name": "Valle e Castello",
                "countryCode": "IT"
            },
            {
                "name": "Venarotta",
                "countryCode": "IT"
            },
            {
                "name": "Villa Ceccolini",
                "countryCode": "IT"
            },
            {
                "name": "Villa Musone",
                "countryCode": "IT"
            },
            {
                "name": "Villa Pigna",
                "countryCode": "IT"
            },
            {
                "name": "Villa Potenza",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Filippo",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "Villagrande",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Visso",
                "countryCode": "IT"
            },
            {
                "name": "Zona Industriale",
                "countryCode": "IT"
            },
            {
                "name": "Accumoli",
                "countryCode": "IT"
            },
            {
                "name": "Acilia-Castel Fusano-Ostia Antica",
                "countryCode": "IT"
            },
            {
                "name": "Acquafondata",
                "countryCode": "IT"
            },
            {
                "name": "Acquapendente",
                "countryCode": "IT"
            },
            {
                "name": "Acuto",
                "countryCode": "IT"
            },
            {
                "name": "Affile",
                "countryCode": "IT"
            },
            {
                "name": "Agosta",
                "countryCode": "IT"
            },
            {
                "name": "Alatri",
                "countryCode": "IT"
            },
            {
                "name": "Albano Laziale",
                "countryCode": "IT"
            },
            {
                "name": "Albuccione",
                "countryCode": "IT"
            },
            {
                "name": "Allumiere",
                "countryCode": "IT"
            },
            {
                "name": "Alvito",
                "countryCode": "IT"
            },
            {
                "name": "Amaseno",
                "countryCode": "IT"
            },
            {
                "name": "Amatrice",
                "countryCode": "IT"
            },
            {
                "name": "Anagni",
                "countryCode": "IT"
            },
            {
                "name": "Anguillara Sabazia",
                "countryCode": "IT"
            },
            {
                "name": "Anitrella-Chiaiamari",
                "countryCode": "IT"
            },
            {
                "name": "Anticoli Corrado",
                "countryCode": "IT"
            },
            {
                "name": "Antrodoco",
                "countryCode": "IT"
            },
            {
                "name": "Anzio",
                "countryCode": "IT"
            },
            {
                "name": "Aprilia",
                "countryCode": "IT"
            },
            {
                "name": "Aquino",
                "countryCode": "IT"
            },
            {
                "name": "Ara Nova",
                "countryCode": "IT"
            },
            {
                "name": "Arce",
                "countryCode": "IT"
            },
            {
                "name": "Arci-Empolitana",
                "countryCode": "IT"
            },
            {
                "name": "Arcinazzo Romano",
                "countryCode": "IT"
            },
            {
                "name": "Ardea",
                "countryCode": "IT"
            },
            {
                "name": "Area Produttiva",
                "countryCode": "IT"
            },
            {
                "name": "Ariccia",
                "countryCode": "IT"
            },
            {
                "name": "Arlena di Castro",
                "countryCode": "IT"
            },
            {
                "name": "Armetta",
                "countryCode": "IT"
            },
            {
                "name": "Arnara",
                "countryCode": "IT"
            },
            {
                "name": "Arpino",
                "countryCode": "IT"
            },
            {
                "name": "Arsoli",
                "countryCode": "IT"
            },
            {
                "name": "Artena",
                "countryCode": "IT"
            },
            {
                "name": "Ascrea",
                "countryCode": "IT"
            },
            {
                "name": "Atina",
                "countryCode": "IT"
            },
            {
                "name": "Atina Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Aurelia",
                "countryCode": "IT"
            },
            {
                "name": "Ausonia",
                "countryCode": "IT"
            },
            {
                "name": "Bagni di Tivoli",
                "countryCode": "IT"
            },
            {
                "name": "Bagnoregio",
                "countryCode": "IT"
            },
            {
                "name": "Barbarano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Bassano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Bassano in Teverina",
                "countryCode": "IT"
            },
            {
                "name": "Bassiano",
                "countryCode": "IT"
            },
            {
                "name": "Bella Farnia",
                "countryCode": "IT"
            },
            {
                "name": "Bellegra",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte Castello",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Bivio San Polo",
                "countryCode": "IT"
            },
            {
                "name": "Bivio di Capanelle",
                "countryCode": "IT"
            },
            {
                "name": "Blera",
                "countryCode": "IT"
            },
            {
                "name": "Bolsena",
                "countryCode": "IT"
            },
            {
                "name": "Bomarzo",
                "countryCode": "IT"
            },
            {
                "name": "Borbona",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Grappa",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Hermada",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Lotti",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Podgora",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Sabotino-Foce Verde",
                "countryCode": "IT"
            },
            {
                "name": "Borgo San Michele",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Velino",
                "countryCode": "IT"
            },
            {
                "name": "Borgorose",
                "countryCode": "IT"
            },
            {
                "name": "Boville Ernica",
                "countryCode": "IT"
            },
            {
                "name": "Bracciano",
                "countryCode": "IT"
            },
            {
                "name": "Broccostella",
                "countryCode": "IT"
            },
            {
                "name": "Caira",
                "countryCode": "IT"
            },
            {
                "name": "Calcata Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Camerata Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Camilleri-Vallelata",
                "countryCode": "IT"
            },
            {
                "name": "Campagnano di Roma",
                "countryCode": "IT"
            },
            {
                "name": "Campo Jemini",
                "countryCode": "IT"
            },
            {
                "name": "Campo Limpido-Favale",
                "countryCode": "IT"
            },
            {
                "name": "Campo di Carne",
                "countryCode": "IT"
            },
            {
                "name": "Campodimele",
                "countryCode": "IT"
            },
            {
                "name": "Campoleone",
                "countryCode": "IT"
            },
            {
                "name": "Campoli Appennino",
                "countryCode": "IT"
            },
            {
                "name": "Canale Monterano",
                "countryCode": "IT"
            },
            {
                "name": "Canepina",
                "countryCode": "IT"
            },
            {
                "name": "Canino",
                "countryCode": "IT"
            },
            {
                "name": "Cantalice",
                "countryCode": "IT"
            },
            {
                "name": "Cantalupo in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Canterano",
                "countryCode": "IT"
            },
            {
                "name": "Capena",
                "countryCode": "IT"
            },
            {
                "name": "Capodimonte",
                "countryCode": "IT"
            },
            {
                "name": "Capranica",
                "countryCode": "IT"
            },
            {
                "name": "Capranica Prenestina",
                "countryCode": "IT"
            },
            {
                "name": "Caprarola",
                "countryCode": "IT"
            },
            {
                "name": "Carbognano",
                "countryCode": "IT"
            },
            {
                "name": "Carchitti",
                "countryCode": "IT"
            },
            {
                "name": "Carpineto Romano",
                "countryCode": "IT"
            },
            {
                "name": "Carrara-Pontenuovo",
                "countryCode": "IT"
            },
            {
                "name": "Cartiera-Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Casal Palocco",
                "countryCode": "IT"
            },
            {
                "name": "Casalattico",
                "countryCode": "IT"
            },
            {
                "name": "Casalazzara",
                "countryCode": "IT"
            },
            {
                "name": "Casali",
                "countryCode": "IT"
            },
            {
                "name": "Casalvieri",
                "countryCode": "IT"
            },
            {
                "name": "Casape",
                "countryCode": "IT"
            },
            {
                "name": "Casaprota",
                "countryCode": "IT"
            },
            {
                "name": "Case Campoli-Panetta",
                "countryCode": "IT"
            },
            {
                "name": "Casperia",
                "countryCode": "IT"
            },
            {
                "name": "Cassino",
                "countryCode": "IT"
            },
            {
                "name": "Castel Chiodato",
                "countryCode": "IT"
            },
            {
                "name": "Castel Fusano",
                "countryCode": "IT"
            },
            {
                "name": "Castel Gandolfo",
                "countryCode": "IT"
            },
            {
                "name": "Castel Madama",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Pietro Romano",
                "countryCode": "IT"
            },
            {
                "name": "Castel Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Castel Sant'Elia",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Tora",
                "countryCode": "IT"
            },
            {
                "name": "Castelforte",
                "countryCode": "IT"
            },
            {
                "name": "Castelliri",
                "countryCode": "IT"
            },
            {
                "name": "Castelmassimo",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Parano",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Farfa",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Porto",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione in Teverina",
                "countryCode": "IT"
            },
            {
                "name": "Castro dei Volsci",
                "countryCode": "IT"
            },
            {
                "name": "Castrocielo",
                "countryCode": "IT"
            },
            {
                "name": "Cave",
                "countryCode": "IT"
            },
            {
                "name": "Ceccano",
                "countryCode": "IT"
            },
            {
                "name": "Cecchina",
                "countryCode": "IT"
            },
            {
                "name": "Celleno",
                "countryCode": "IT"
            },
            {
                "name": "Cellere",
                "countryCode": "IT"
            },
            {
                "name": "Ceprano",
                "countryCode": "IT"
            },
            {
                "name": "Cerquotti-Madonna del Piano",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Laziale",
                "countryCode": "IT"
            },
            {
                "name": "Cervara di Roma",
                "countryCode": "IT"
            },
            {
                "name": "Cervaro",
                "countryCode": "IT"
            },
            {
                "name": "Cerveteri",
                "countryCode": "IT"
            },
            {
                "name": "Cesano",
                "countryCode": "IT"
            },
            {
                "name": "Ciampino",
                "countryCode": "IT"
            },
            {
                "name": "Ciciliano",
                "countryCode": "IT"
            },
            {
                "name": "Cineto Romano",
                "countryCode": "IT"
            },
            {
                "name": "Cisterna di Latina",
                "countryCode": "IT"
            },
            {
                "name": "Cittaducale",
                "countryCode": "IT"
            },
            {
                "name": "Cittareale",
                "countryCode": "IT"
            },
            {
                "name": "Città metropolitana di Roma Capitale",
                "countryCode": "IT"
            },
            {
                "name": "Civita Castellana",
                "countryCode": "IT"
            },
            {
                "name": "Civitavecchia",
                "countryCode": "IT"
            },
            {
                "name": "Civitella San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "Civitella d'Agliano",
                "countryCode": "IT"
            },
            {
                "name": "Coldragone",
                "countryCode": "IT"
            },
            {
                "name": "Collalto Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Colle Campano-Scrima",
                "countryCode": "IT"
            },
            {
                "name": "Colle Mainello",
                "countryCode": "IT"
            },
            {
                "name": "Colle San Magno",
                "countryCode": "IT"
            },
            {
                "name": "Colle Spina",
                "countryCode": "IT"
            },
            {
                "name": "Colle Verde",
                "countryCode": "IT"
            },
            {
                "name": "Colle del Pino",
                "countryCode": "IT"
            },
            {
                "name": "Colle di Fuori",
                "countryCode": "IT"
            },
            {
                "name": "Colle di Tora",
                "countryCode": "IT"
            },
            {
                "name": "Colleferro",
                "countryCode": "IT"
            },
            {
                "name": "Collefontana-Fontana Liri Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Collegiove",
                "countryCode": "IT"
            },
            {
                "name": "Collepardo",
                "countryCode": "IT"
            },
            {
                "name": "Collevecchio",
                "countryCode": "IT"
            },
            {
                "name": "Colleverde II",
                "countryCode": "IT"
            },
            {
                "name": "Colli di Enea",
                "countryCode": "IT"
            },
            {
                "name": "Colli sul Velino",
                "countryCode": "IT"
            },
            {
                "name": "Colonna",
                "countryCode": "IT"
            },
            {
                "name": "Colubro",
                "countryCode": "IT"
            },
            {
                "name": "Concerviano",
                "countryCode": "IT"
            },
            {
                "name": "Configni",
                "countryCode": "IT"
            },
            {
                "name": "Contigliano",
                "countryCode": "IT"
            },
            {
                "name": "Corchiano",
                "countryCode": "IT"
            },
            {
                "name": "Corcolle",
                "countryCode": "IT"
            },
            {
                "name": "Coreno Ausonio",
                "countryCode": "IT"
            },
            {
                "name": "Cori",
                "countryCode": "IT"
            },
            {
                "name": "Corvaro",
                "countryCode": "IT"
            },
            {
                "name": "Costaroni",
                "countryCode": "IT"
            },
            {
                "name": "Cottanello",
                "countryCode": "IT"
            },
            {
                "name": "Cretone",
                "countryCode": "IT"
            },
            {
                "name": "Esperia",
                "countryCode": "IT"
            },
            {
                "name": "Fabrica di Roma",
                "countryCode": "IT"
            },
            {
                "name": "Faleria",
                "countryCode": "IT"
            },
            {
                "name": "Falvaterra",
                "countryCode": "IT"
            },
            {
                "name": "Fara in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Farnese",
                "countryCode": "IT"
            },
            {
                "name": "Ferentino",
                "countryCode": "IT"
            },
            {
                "name": "Fiamignano",
                "countryCode": "IT"
            },
            {
                "name": "Fiano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Filacciano",
                "countryCode": "IT"
            },
            {
                "name": "Filettino",
                "countryCode": "IT"
            },
            {
                "name": "Fiuggi",
                "countryCode": "IT"
            },
            {
                "name": "Fiumicino-Isola Sacra",
                "countryCode": "IT"
            },
            {
                "name": "Focene",
                "countryCode": "IT"
            },
            {
                "name": "Fondi",
                "countryCode": "IT"
            },
            {
                "name": "Fontechiari",
                "countryCode": "IT"
            },
            {
                "name": "Forano",
                "countryCode": "IT"
            },
            {
                "name": "Formello",
                "countryCode": "IT"
            },
            {
                "name": "Formia",
                "countryCode": "IT"
            },
            {
                "name": "Fossignano",
                "countryCode": "IT"
            },
            {
                "name": "Frascati",
                "countryCode": "IT"
            },
            {
                "name": "Frasso Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Fregene",
                "countryCode": "IT"
            },
            {
                "name": "Frosinone",
                "countryCode": "IT"
            },
            {
                "name": "Fumone",
                "countryCode": "IT"
            },
            {
                "name": "Gaeta",
                "countryCode": "IT"
            },
            {
                "name": "Gallese",
                "countryCode": "IT"
            },
            {
                "name": "Gallicano nel Lazio",
                "countryCode": "IT"
            },
            {
                "name": "Gallinaro",
                "countryCode": "IT"
            },
            {
                "name": "Gavignano",
                "countryCode": "IT"
            },
            {
                "name": "Genazzano",
                "countryCode": "IT"
            },
            {
                "name": "Genio Civile",
                "countryCode": "IT"
            },
            {
                "name": "Genzano di Roma",
                "countryCode": "IT"
            },
            {
                "name": "Gerano",
                "countryCode": "IT"
            },
            {
                "name": "Girardi-Bellavista-Terrazze",
                "countryCode": "IT"
            },
            {
                "name": "Giulianello",
                "countryCode": "IT"
            },
            {
                "name": "Giuliano di Roma",
                "countryCode": "IT"
            },
            {
                "name": "Gorga",
                "countryCode": "IT"
            },
            {
                "name": "Gradoli",
                "countryCode": "IT"
            },
            {
                "name": "Graffignano",
                "countryCode": "IT"
            },
            {
                "name": "Grottaferrata",
                "countryCode": "IT"
            },
            {
                "name": "Grotte Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Grotte di Castro",
                "countryCode": "IT"
            },
            {
                "name": "Grunuovo-Campomaggiore San Luca",
                "countryCode": "IT"
            },
            {
                "name": "Guarcino",
                "countryCode": "IT"
            },
            {
                "name": "Guidonia",
                "countryCode": "IT"
            },
            {
                "name": "Guidonia Montecelio",
                "countryCode": "IT"
            },
            {
                "name": "Ischia di Castro",
                "countryCode": "IT"
            },
            {
                "name": "Isola del Liri",
                "countryCode": "IT"
            },
            {
                "name": "Itri",
                "countryCode": "IT"
            },
            {
                "name": "Jenne",
                "countryCode": "IT"
            },
            {
                "name": "La Botte",
                "countryCode": "IT"
            },
            {
                "name": "La Forma",
                "countryCode": "IT"
            },
            {
                "name": "La Massimina-Casal Lumbroso",
                "countryCode": "IT"
            },
            {
                "name": "Labico",
                "countryCode": "IT"
            },
            {
                "name": "Labro",
                "countryCode": "IT"
            },
            {
                "name": "Ladispoli",
                "countryCode": "IT"
            },
            {
                "name": "Laghetto",
                "countryCode": "IT"
            },
            {
                "name": "Lanuvio",
                "countryCode": "IT"
            },
            {
                "name": "Lariano",
                "countryCode": "IT"
            },
            {
                "name": "Latera",
                "countryCode": "IT"
            },
            {
                "name": "Latina",
                "countryCode": "IT"
            },
            {
                "name": "Latina Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Lavinio",
                "countryCode": "IT"
            },
            {
                "name": "Le Forna",
                "countryCode": "IT"
            },
            {
                "name": "Le Rughe",
                "countryCode": "IT"
            },
            {
                "name": "Lenola",
                "countryCode": "IT"
            },
            {
                "name": "Leonessa",
                "countryCode": "IT"
            },
            {
                "name": "Licenza",
                "countryCode": "IT"
            },
            {
                "name": "Lido dei Pini",
                "countryCode": "IT"
            },
            {
                "name": "Lido di Ostia",
                "countryCode": "IT"
            },
            {
                "name": "Limiti di Greccio",
                "countryCode": "IT"
            },
            {
                "name": "Longone Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Lubriano",
                "countryCode": "IT"
            },
            {
                "name": "Maccarese",
                "countryCode": "IT"
            },
            {
                "name": "Macere",
                "countryCode": "IT"
            },
            {
                "name": "Maenza",
                "countryCode": "IT"
            },
            {
                "name": "Magliano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Magliano Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Mandela",
                "countryCode": "IT"
            },
            {
                "name": "Mantiglia di Ardea",
                "countryCode": "IT"
            },
            {
                "name": "Manziana",
                "countryCode": "IT"
            },
            {
                "name": "Marano Equo",
                "countryCode": "IT"
            },
            {
                "name": "Maranola-Trivio",
                "countryCode": "IT"
            },
            {
                "name": "Marcellina",
                "countryCode": "IT"
            },
            {
                "name": "Marcetelli",
                "countryCode": "IT"
            },
            {
                "name": "Marco Simone",
                "countryCode": "IT"
            },
            {
                "name": "Marina San Nicola",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Ardea-Tor San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Cerveteri",
                "countryCode": "IT"
            },
            {
                "name": "Marino",
                "countryCode": "IT"
            },
            {
                "name": "Marta",
                "countryCode": "IT"
            },
            {
                "name": "Mazzano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Mentana",
                "countryCode": "IT"
            },
            {
                "name": "Micigliano",
                "countryCode": "IT"
            },
            {
                "name": "Minturno",
                "countryCode": "IT"
            },
            {
                "name": "Molella",
                "countryCode": "IT"
            },
            {
                "name": "Mompeo",
                "countryCode": "IT"
            },
            {
                "name": "Montalto di Castro",
                "countryCode": "IT"
            },
            {
                "name": "Montasola",
                "countryCode": "IT"
            },
            {
                "name": "Monte Caminetto",
                "countryCode": "IT"
            },
            {
                "name": "Monte Migliore La Selvotta",
                "countryCode": "IT"
            },
            {
                "name": "Monte Porzio Catone",
                "countryCode": "IT"
            },
            {
                "name": "Monte Romano",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Biagio",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Giovanni Campano",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Giovanni in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Marino",
                "countryCode": "IT"
            },
            {
                "name": "Montebuono",
                "countryCode": "IT"
            },
            {
                "name": "Montecelio",
                "countryCode": "IT"
            },
            {
                "name": "Montecompatri",
                "countryCode": "IT"
            },
            {
                "name": "Montefiascone",
                "countryCode": "IT"
            },
            {
                "name": "Monteflavio",
                "countryCode": "IT"
            },
            {
                "name": "Montelanico",
                "countryCode": "IT"
            },
            {
                "name": "Montelarco",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Montelibretti",
                "countryCode": "IT"
            },
            {
                "name": "Montenero Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Monterosi",
                "countryCode": "IT"
            },
            {
                "name": "Monterotondo",
                "countryCode": "IT"
            },
            {
                "name": "Montopoli in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Montorio Romano",
                "countryCode": "IT"
            },
            {
                "name": "Moricone",
                "countryCode": "IT"
            },
            {
                "name": "Morlupo",
                "countryCode": "IT"
            },
            {
                "name": "Morolo",
                "countryCode": "IT"
            },
            {
                "name": "Morro Reatino",
                "countryCode": "IT"
            },
            {
                "name": "Nazzano",
                "countryCode": "IT"
            },
            {
                "name": "Nemi",
                "countryCode": "IT"
            },
            {
                "name": "Nepi",
                "countryCode": "IT"
            },
            {
                "name": "Nerola",
                "countryCode": "IT"
            },
            {
                "name": "Nespolo",
                "countryCode": "IT"
            },
            {
                "name": "Nettuno",
                "countryCode": "IT"
            },
            {
                "name": "Norma",
                "countryCode": "IT"
            },
            {
                "name": "Olevano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Onano",
                "countryCode": "IT"
            },
            {
                "name": "Oriolo Romano",
                "countryCode": "IT"
            },
            {
                "name": "Orte",
                "countryCode": "IT"
            },
            {
                "name": "Orte Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Orvinio",
                "countryCode": "IT"
            },
            {
                "name": "Osteria Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Paganico Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Palestrina",
                "countryCode": "IT"
            },
            {
                "name": "Paliano",
                "countryCode": "IT"
            },
            {
                "name": "Palombara Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Parco Leonardo",
                "countryCode": "IT"
            },
            {
                "name": "Passoscuro",
                "countryCode": "IT"
            },
            {
                "name": "Pastena",
                "countryCode": "IT"
            },
            {
                "name": "Patrica",
                "countryCode": "IT"
            },
            {
                "name": "Pavona",
                "countryCode": "IT"
            },
            {
                "name": "Penitro",
                "countryCode": "IT"
            },
            {
                "name": "Percile",
                "countryCode": "IT"
            },
            {
                "name": "Pescia Romana",
                "countryCode": "IT"
            },
            {
                "name": "Pescorocchiano",
                "countryCode": "IT"
            },
            {
                "name": "Pescosolido",
                "countryCode": "IT"
            },
            {
                "name": "Petrella Salto",
                "countryCode": "IT"
            },
            {
                "name": "Piansano",
                "countryCode": "IT"
            },
            {
                "name": "Picinisco",
                "countryCode": "IT"
            },
            {
                "name": "Pico",
                "countryCode": "IT"
            },
            {
                "name": "Piedimonte San Germano",
                "countryCode": "IT"
            },
            {
                "name": "Piedimonte San Germano Alta",
                "countryCode": "IT"
            },
            {
                "name": "Piglio",
                "countryCode": "IT"
            },
            {
                "name": "Pignataro Interamna",
                "countryCode": "IT"
            },
            {
                "name": "Pisoniano",
                "countryCode": "IT"
            },
            {
                "name": "Pofi",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Bustone",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Catino",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Ellera",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Mirteto",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Moiano",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Nativo",
                "countryCode": "IT"
            },
            {
                "name": "Poggio San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "Poli",
                "countryCode": "IT"
            },
            {
                "name": "Pomezia",
                "countryCode": "IT"
            },
            {
                "name": "Ponte Galeria-La Pisana",
                "countryCode": "IT"
            },
            {
                "name": "Pontecorvo",
                "countryCode": "IT"
            },
            {
                "name": "Pontinia",
                "countryCode": "IT"
            },
            {
                "name": "Ponton dell'Elce",
                "countryCode": "IT"
            },
            {
                "name": "Ponza",
                "countryCode": "IT"
            },
            {
                "name": "Ponzano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Posta",
                "countryCode": "IT"
            },
            {
                "name": "Posta Fibreno",
                "countryCode": "IT"
            },
            {
                "name": "Pozzaglia Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Prato di Coppola",
                "countryCode": "IT"
            },
            {
                "name": "Priverno",
                "countryCode": "IT"
            },
            {
                "name": "Proceno",
                "countryCode": "IT"
            },
            {
                "name": "Prossedi",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Frosinone",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Latina",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Rieti",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Viterbo",
                "countryCode": "IT"
            },
            {
                "name": "Regolelli",
                "countryCode": "IT"
            },
            {
                "name": "Riano",
                "countryCode": "IT"
            },
            {
                "name": "Rieti",
                "countryCode": "IT"
            },
            {
                "name": "Rignano Flaminio",
                "countryCode": "IT"
            },
            {
                "name": "Riofreddo",
                "countryCode": "IT"
            },
            {
                "name": "Ripi",
                "countryCode": "IT"
            },
            {
                "name": "Rivodutri",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Canterano",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Massima",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Priora",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Sinibalda",
                "countryCode": "IT"
            },
            {
                "name": "Rocca d'Arce",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Cave",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Papa",
                "countryCode": "IT"
            },
            {
                "name": "Roccagiovine",
                "countryCode": "IT"
            },
            {
                "name": "Roccagorga",
                "countryCode": "IT"
            },
            {
                "name": "Roccantica",
                "countryCode": "IT"
            },
            {
                "name": "Roccasecca",
                "countryCode": "IT"
            },
            {
                "name": "Roccasecca Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Roccasecca dei Volsci",
                "countryCode": "IT"
            },
            {
                "name": "Roiate",
                "countryCode": "IT"
            },
            {
                "name": "Rome",
                "countryCode": "IT"
            },
            {
                "name": "Ronciglione",
                "countryCode": "IT"
            },
            {
                "name": "Roviano",
                "countryCode": "IT"
            },
            {
                "name": "Sabaudia",
                "countryCode": "IT"
            },
            {
                "name": "Sacrofano",
                "countryCode": "IT"
            },
            {
                "name": "Salisano",
                "countryCode": "IT"
            },
            {
                "name": "Sambuci",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio Saracinisco",
                "countryCode": "IT"
            },
            {
                "name": "San Cesareo",
                "countryCode": "IT"
            },
            {
                "name": "San Donato Val di Comino",
                "countryCode": "IT"
            },
            {
                "name": "San Felice Circeo",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio a Liri",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Incarico",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni-Patoni",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe le Prata-Cotropagno",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio da Sassola",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "San Martino al Cimino",
                "countryCode": "IT"
            },
            {
                "name": "San Polo dei Cavalieri",
                "countryCode": "IT"
            },
            {
                "name": "San Vito Romano",
                "countryCode": "IT"
            },
            {
                "name": "San Vittore del Lazio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ambrogio sul Garigliano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea del Garigliano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo Romano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo in Villa-Giglio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Apollinare",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elia Fiumerapido",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Oreste",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Marinella",
                "countryCode": "IT"
            },
            {
                "name": "Santa Rufina",
                "countryCode": "IT"
            },
            {
                "name": "Santi Cosma e Damiano",
                "countryCode": "IT"
            },
            {
                "name": "Santopadre",
                "countryCode": "IT"
            },
            {
                "name": "Saracinesco",
                "countryCode": "IT"
            },
            {
                "name": "Sassacci",
                "countryCode": "IT"
            },
            {
                "name": "Scandriglia",
                "countryCode": "IT"
            },
            {
                "name": "Segni",
                "countryCode": "IT"
            },
            {
                "name": "Selcetta",
                "countryCode": "IT"
            },
            {
                "name": "Selci",
                "countryCode": "IT"
            },
            {
                "name": "Sermoneta",
                "countryCode": "IT"
            },
            {
                "name": "Serrone",
                "countryCode": "IT"
            },
            {
                "name": "Settefrati",
                "countryCode": "IT"
            },
            {
                "name": "Setteville",
                "countryCode": "IT"
            },
            {
                "name": "Sezze",
                "countryCode": "IT"
            },
            {
                "name": "Sezze Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Sgurgola",
                "countryCode": "IT"
            },
            {
                "name": "Sonnino",
                "countryCode": "IT"
            },
            {
                "name": "Sora",
                "countryCode": "IT"
            },
            {
                "name": "Soriano nel Cimino",
                "countryCode": "IT"
            },
            {
                "name": "Sperlonga",
                "countryCode": "IT"
            },
            {
                "name": "Spigno Saturnia Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Spigno Saturnia Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Stimigliano",
                "countryCode": "IT"
            },
            {
                "name": "Strangolagalli",
                "countryCode": "IT"
            },
            {
                "name": "Subiaco",
                "countryCode": "IT"
            },
            {
                "name": "Supino",
                "countryCode": "IT"
            },
            {
                "name": "Sutri",
                "countryCode": "IT"
            },
            {
                "name": "Tarano",
                "countryCode": "IT"
            },
            {
                "name": "Tarquinia",
                "countryCode": "IT"
            },
            {
                "name": "Tecchiena",
                "countryCode": "IT"
            },
            {
                "name": "Terelle",
                "countryCode": "IT"
            },
            {
                "name": "Terracina",
                "countryCode": "IT"
            },
            {
                "name": "Tessennano",
                "countryCode": "IT"
            },
            {
                "name": "Tivoli",
                "countryCode": "IT"
            },
            {
                "name": "Toffia",
                "countryCode": "IT"
            },
            {
                "name": "Tolfa",
                "countryCode": "IT"
            },
            {
                "name": "Tor Lupara",
                "countryCode": "IT"
            },
            {
                "name": "Torre Caietani",
                "countryCode": "IT"
            },
            {
                "name": "Torri in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Torrice",
                "countryCode": "IT"
            },
            {
                "name": "Torricella in Sabina",
                "countryCode": "IT"
            },
            {
                "name": "Torrita Tiberina",
                "countryCode": "IT"
            },
            {
                "name": "Torvaianica",
                "countryCode": "IT"
            },
            {
                "name": "Torvaianica Alta",
                "countryCode": "IT"
            },
            {
                "name": "Tragliatella Campitello",
                "countryCode": "IT"
            },
            {
                "name": "Trevi nel Lazio",
                "countryCode": "IT"
            },
            {
                "name": "Trevignano Romano",
                "countryCode": "IT"
            },
            {
                "name": "Trivigliano",
                "countryCode": "IT"
            },
            {
                "name": "Turania",
                "countryCode": "IT"
            },
            {
                "name": "Tuscania",
                "countryCode": "IT"
            },
            {
                "name": "Vacone",
                "countryCode": "IT"
            },
            {
                "name": "Valcanneto",
                "countryCode": "IT"
            },
            {
                "name": "Valentano",
                "countryCode": "IT"
            },
            {
                "name": "Valle Martella",
                "countryCode": "IT"
            },
            {
                "name": "Valle Santa",
                "countryCode": "IT"
            },
            {
                "name": "Vallecorsa",
                "countryCode": "IT"
            },
            {
                "name": "Vallemaio",
                "countryCode": "IT"
            },
            {
                "name": "Vallepietra",
                "countryCode": "IT"
            },
            {
                "name": "Vallerano",
                "countryCode": "IT"
            },
            {
                "name": "Vallerotonda",
                "countryCode": "IT"
            },
            {
                "name": "Vallinfreda",
                "countryCode": "IT"
            },
            {
                "name": "Valmontone",
                "countryCode": "IT"
            },
            {
                "name": "Varco Sabino",
                "countryCode": "IT"
            },
            {
                "name": "Vasanello",
                "countryCode": "IT"
            },
            {
                "name": "Vejano",
                "countryCode": "IT"
            },
            {
                "name": "Velletri",
                "countryCode": "IT"
            },
            {
                "name": "Ventotene",
                "countryCode": "IT"
            },
            {
                "name": "Veroli",
                "countryCode": "IT"
            },
            {
                "name": "Vetralla",
                "countryCode": "IT"
            },
            {
                "name": "Vicalvi",
                "countryCode": "IT"
            },
            {
                "name": "Vico nel Lazio",
                "countryCode": "IT"
            },
            {
                "name": "Vicovaro",
                "countryCode": "IT"
            },
            {
                "name": "Vignanello",
                "countryCode": "IT"
            },
            {
                "name": "Villa Adriana",
                "countryCode": "IT"
            },
            {
                "name": "Villa Latina",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Giovanni in Tuscia",
                "countryCode": "IT"
            },
            {
                "name": "Villa Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Villa Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Villalba",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Viterbo",
                "countryCode": "IT"
            },
            {
                "name": "Viticuso",
                "countryCode": "IT"
            },
            {
                "name": "Vitinia",
                "countryCode": "IT"
            },
            {
                "name": "Vitorchiano",
                "countryCode": "IT"
            },
            {
                "name": "Vivaro Romano",
                "countryCode": "IT"
            },
            {
                "name": "Zagarolo",
                "countryCode": "IT"
            },
            {
                "name": "Zepponami",
                "countryCode": "IT"
            },
            {
                "name": "Abbateggio",
                "countryCode": "IT"
            },
            {
                "name": "Acciano",
                "countryCode": "IT"
            },
            {
                "name": "Aielli",
                "countryCode": "IT"
            },
            {
                "name": "Alanno",
                "countryCode": "IT"
            },
            {
                "name": "Alba Adriatica",
                "countryCode": "IT"
            },
            {
                "name": "Alfedena",
                "countryCode": "IT"
            },
            {
                "name": "Altino",
                "countryCode": "IT"
            },
            {
                "name": "Ancarano",
                "countryCode": "IT"
            },
            {
                "name": "Anversa degli Abruzzi",
                "countryCode": "IT"
            },
            {
                "name": "Archi",
                "countryCode": "IT"
            },
            {
                "name": "Ari",
                "countryCode": "IT"
            },
            {
                "name": "Arielli",
                "countryCode": "IT"
            },
            {
                "name": "Arsita",
                "countryCode": "IT"
            },
            {
                "name": "Ateleta",
                "countryCode": "IT"
            },
            {
                "name": "Atessa",
                "countryCode": "IT"
            },
            {
                "name": "Atri",
                "countryCode": "IT"
            },
            {
                "name": "Avezzano",
                "countryCode": "IT"
            },
            {
                "name": "Balsorano",
                "countryCode": "IT"
            },
            {
                "name": "Balsorano Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Barberi",
                "countryCode": "IT"
            },
            {
                "name": "Barete",
                "countryCode": "IT"
            },
            {
                "name": "Barisciano",
                "countryCode": "IT"
            },
            {
                "name": "Barrea",
                "countryCode": "IT"
            },
            {
                "name": "Basciano",
                "countryCode": "IT"
            },
            {
                "name": "Bellante",
                "countryCode": "IT"
            },
            {
                "name": "Bellante Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Bisegna",
                "countryCode": "IT"
            },
            {
                "name": "Bisenti",
                "countryCode": "IT"
            },
            {
                "name": "Bolognano",
                "countryCode": "IT"
            },
            {
                "name": "Bomba",
                "countryCode": "IT"
            },
            {
                "name": "Borgo Santa Maria Immacolata",
                "countryCode": "IT"
            },
            {
                "name": "Borrello",
                "countryCode": "IT"
            },
            {
                "name": "Brittoli",
                "countryCode": "IT"
            },
            {
                "name": "Bucchianico",
                "countryCode": "IT"
            },
            {
                "name": "Bugnara",
                "countryCode": "IT"
            },
            {
                "name": "Bussi sul Tirino",
                "countryCode": "IT"
            },
            {
                "name": "Cagnano Amiterno",
                "countryCode": "IT"
            },
            {
                "name": "Calascio",
                "countryCode": "IT"
            },
            {
                "name": "Campli",
                "countryCode": "IT"
            },
            {
                "name": "Campo di Giove",
                "countryCode": "IT"
            },
            {
                "name": "Campotosto",
                "countryCode": "IT"
            },
            {
                "name": "Canistro Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Canosa Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Cansano",
                "countryCode": "IT"
            },
            {
                "name": "Canzano",
                "countryCode": "IT"
            },
            {
                "name": "Capestrano",
                "countryCode": "IT"
            },
            {
                "name": "Capistrello",
                "countryCode": "IT"
            },
            {
                "name": "Capitignano",
                "countryCode": "IT"
            },
            {
                "name": "Caporciano",
                "countryCode": "IT"
            },
            {
                "name": "Cappadocia",
                "countryCode": "IT"
            },
            {
                "name": "Cappelle sul Tavo",
                "countryCode": "IT"
            },
            {
                "name": "Caramanico Terme",
                "countryCode": "IT"
            },
            {
                "name": "Carapelle Calvisio",
                "countryCode": "IT"
            },
            {
                "name": "Carpineto Sinello",
                "countryCode": "IT"
            },
            {
                "name": "Carpineto della Nora",
                "countryCode": "IT"
            },
            {
                "name": "Carsoli",
                "countryCode": "IT"
            },
            {
                "name": "Carunchio",
                "countryCode": "IT"
            },
            {
                "name": "Casacanditella",
                "countryCode": "IT"
            },
            {
                "name": "Casalanguida",
                "countryCode": "IT"
            },
            {
                "name": "Casalbordino-Miracoli",
                "countryCode": "IT"
            },
            {
                "name": "Casalincontrada",
                "countryCode": "IT"
            },
            {
                "name": "Casoli",
                "countryCode": "IT"
            },
            {
                "name": "Castel Castagna",
                "countryCode": "IT"
            },
            {
                "name": "Castel Frentano",
                "countryCode": "IT"
            },
            {
                "name": "Castel del Monte",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Ieri",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Sangro",
                "countryCode": "IT"
            },
            {
                "name": "Castelguidone",
                "countryCode": "IT"
            },
            {
                "name": "Castellafiume",
                "countryCode": "IT"
            },
            {
                "name": "Castellalto",
                "countryCode": "IT"
            },
            {
                "name": "Castelli",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Vomano",
                "countryCode": "IT"
            },
            {
                "name": "Castelvecchio Calvisio",
                "countryCode": "IT"
            },
            {
                "name": "Castelvecchio Subequo",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Messer Marino",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Messer Raimondo",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione a Casauria",
                "countryCode": "IT"
            },
            {
                "name": "Castilenti",
                "countryCode": "IT"
            },
            {
                "name": "Catignano",
                "countryCode": "IT"
            },
            {
                "name": "Celano",
                "countryCode": "IT"
            },
            {
                "name": "Celenza sul Trigno",
                "countryCode": "IT"
            },
            {
                "name": "Cellino Attanasio",
                "countryCode": "IT"
            },
            {
                "name": "Cepagatti",
                "countryCode": "IT"
            },
            {
                "name": "Cerchio",
                "countryCode": "IT"
            },
            {
                "name": "Cermignano",
                "countryCode": "IT"
            },
            {
                "name": "Cerratina",
                "countryCode": "IT"
            },
            {
                "name": "Chieti",
                "countryCode": "IT"
            },
            {
                "name": "Città Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Civita d'Antino",
                "countryCode": "IT"
            },
            {
                "name": "Civitaluparella",
                "countryCode": "IT"
            },
            {
                "name": "Civitaquana",
                "countryCode": "IT"
            },
            {
                "name": "Civitella Alfedena",
                "countryCode": "IT"
            },
            {
                "name": "Civitella Casanova",
                "countryCode": "IT"
            },
            {
                "name": "Civitella Messer Raimondo",
                "countryCode": "IT"
            },
            {
                "name": "Civitella Roveto",
                "countryCode": "IT"
            },
            {
                "name": "Civitella del Tronto",
                "countryCode": "IT"
            },
            {
                "name": "Cocullo",
                "countryCode": "IT"
            },
            {
                "name": "Collarmele",
                "countryCode": "IT"
            },
            {
                "name": "Collecorvino",
                "countryCode": "IT"
            },
            {
                "name": "Colledara",
                "countryCode": "IT"
            },
            {
                "name": "Colledimacine",
                "countryCode": "IT"
            },
            {
                "name": "Colledimezzo",
                "countryCode": "IT"
            },
            {
                "name": "Collelongo",
                "countryCode": "IT"
            },
            {
                "name": "Collepietro",
                "countryCode": "IT"
            },
            {
                "name": "Colleranesco",
                "countryCode": "IT"
            },
            {
                "name": "Cologna Spiaggia",
                "countryCode": "IT"
            },
            {
                "name": "Colonnella",
                "countryCode": "IT"
            },
            {
                "name": "Cominio",
                "countryCode": "IT"
            },
            {
                "name": "Controguerra",
                "countryCode": "IT"
            },
            {
                "name": "Coppito",
                "countryCode": "IT"
            },
            {
                "name": "Corfinio",
                "countryCode": "IT"
            },
            {
                "name": "Corropoli",
                "countryCode": "IT"
            },
            {
                "name": "Cortino",
                "countryCode": "IT"
            },
            {
                "name": "Corvara",
                "countryCode": "IT"
            },
            {
                "name": "Crecchio",
                "countryCode": "IT"
            },
            {
                "name": "Crognaleto",
                "countryCode": "IT"
            },
            {
                "name": "Cugnoli",
                "countryCode": "IT"
            },
            {
                "name": "Cupello",
                "countryCode": "IT"
            },
            {
                "name": "Dogliola",
                "countryCode": "IT"
            },
            {
                "name": "Elice",
                "countryCode": "IT"
            },
            {
                "name": "Fallo",
                "countryCode": "IT"
            },
            {
                "name": "Fano Adriano",
                "countryCode": "IT"
            },
            {
                "name": "Fara Filiorum Petri",
                "countryCode": "IT"
            },
            {
                "name": "Fara San Martino",
                "countryCode": "IT"
            },
            {
                "name": "Farindola",
                "countryCode": "IT"
            },
            {
                "name": "Filetto",
                "countryCode": "IT"
            },
            {
                "name": "Fonte Umano-San Martino Alta",
                "countryCode": "IT"
            },
            {
                "name": "Fontecchio",
                "countryCode": "IT"
            },
            {
                "name": "Fossa",
                "countryCode": "IT"
            },
            {
                "name": "Fossacesia",
                "countryCode": "IT"
            },
            {
                "name": "Fraine",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla al Mare",
                "countryCode": "IT"
            },
            {
                "name": "Fresagrandinaria",
                "countryCode": "IT"
            },
            {
                "name": "Frisa",
                "countryCode": "IT"
            },
            {
                "name": "Furci",
                "countryCode": "IT"
            },
            {
                "name": "Gagliano Aterno",
                "countryCode": "IT"
            },
            {
                "name": "Gamberale",
                "countryCode": "IT"
            },
            {
                "name": "Gessopalena",
                "countryCode": "IT"
            },
            {
                "name": "Gioia dei Marsi",
                "countryCode": "IT"
            },
            {
                "name": "Gissi",
                "countryCode": "IT"
            },
            {
                "name": "Giuliano Teatino",
                "countryCode": "IT"
            },
            {
                "name": "Giulianova",
                "countryCode": "IT"
            },
            {
                "name": "Goriano Sicoli",
                "countryCode": "IT"
            },
            {
                "name": "Guardiagrele",
                "countryCode": "IT"
            },
            {
                "name": "Guilmi",
                "countryCode": "IT"
            },
            {
                "name": "Imposte",
                "countryCode": "IT"
            },
            {
                "name": "Introdacqua",
                "countryCode": "IT"
            },
            {
                "name": "Isola del Gran Sasso d'Italia",
                "countryCode": "IT"
            },
            {
                "name": "L'Aquila",
                "countryCode": "IT"
            },
            {
                "name": "Lama dei Peligni",
                "countryCode": "IT"
            },
            {
                "name": "Lanciano",
                "countryCode": "IT"
            },
            {
                "name": "Lecce Nei Marsi",
                "countryCode": "IT"
            },
            {
                "name": "Lentella",
                "countryCode": "IT"
            },
            {
                "name": "Lettomanoppello",
                "countryCode": "IT"
            },
            {
                "name": "Lettopalena",
                "countryCode": "IT"
            },
            {
                "name": "Liscia",
                "countryCode": "IT"
            },
            {
                "name": "Loreto Aprutino",
                "countryCode": "IT"
            },
            {
                "name": "Luco dei Marsi",
                "countryCode": "IT"
            },
            {
                "name": "Lucoli",
                "countryCode": "IT"
            },
            {
                "name": "Magliano De'Marsi",
                "countryCode": "IT"
            },
            {
                "name": "Manoppello",
                "countryCode": "IT"
            },
            {
                "name": "Manoppello Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Marina di San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Vasto",
                "countryCode": "IT"
            },
            {
                "name": "Martinsicuro",
                "countryCode": "IT"
            },
            {
                "name": "Massa d'Albe-Corona",
                "countryCode": "IT"
            },
            {
                "name": "Miglianico",
                "countryCode": "IT"
            },
            {
                "name": "Molina Aterno",
                "countryCode": "IT"
            },
            {
                "name": "Montazzoli",
                "countryCode": "IT"
            },
            {
                "name": "Montebello di Bertona",
                "countryCode": "IT"
            },
            {
                "name": "Montebello sul Sangro",
                "countryCode": "IT"
            },
            {
                "name": "Monteferrante",
                "countryCode": "IT"
            },
            {
                "name": "Montefino",
                "countryCode": "IT"
            },
            {
                "name": "Montelapiano",
                "countryCode": "IT"
            },
            {
                "name": "Montenerodomo",
                "countryCode": "IT"
            },
            {
                "name": "Monteodorisio",
                "countryCode": "IT"
            },
            {
                "name": "Montereale",
                "countryCode": "IT"
            },
            {
                "name": "Montesilvano Marina",
                "countryCode": "IT"
            },
            {
                "name": "Monticchio",
                "countryCode": "IT"
            },
            {
                "name": "Montorio al Vomano",
                "countryCode": "IT"
            },
            {
                "name": "Morino",
                "countryCode": "IT"
            },
            {
                "name": "Morro d'Oro",
                "countryCode": "IT"
            },
            {
                "name": "Mosciano Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Moscufo",
                "countryCode": "IT"
            },
            {
                "name": "Mozzagrogna",
                "countryCode": "IT"
            },
            {
                "name": "Navelli",
                "countryCode": "IT"
            },
            {
                "name": "Nepezzano",
                "countryCode": "IT"
            },
            {
                "name": "Nereto",
                "countryCode": "IT"
            },
            {
                "name": "Nerito",
                "countryCode": "IT"
            },
            {
                "name": "Nocciano",
                "countryCode": "IT"
            },
            {
                "name": "Notaresco",
                "countryCode": "IT"
            },
            {
                "name": "Nucleo Industriale di Bazzano",
                "countryCode": "IT"
            },
            {
                "name": "Ofena",
                "countryCode": "IT"
            },
            {
                "name": "Opi",
                "countryCode": "IT"
            },
            {
                "name": "Oricola",
                "countryCode": "IT"
            },
            {
                "name": "Orsogna",
                "countryCode": "IT"
            },
            {
                "name": "Ortona",
                "countryCode": "IT"
            },
            {
                "name": "Ortona dei Marsi",
                "countryCode": "IT"
            },
            {
                "name": "Ortucchio",
                "countryCode": "IT"
            },
            {
                "name": "Ovindoli",
                "countryCode": "IT"
            },
            {
                "name": "Pacentro",
                "countryCode": "IT"
            },
            {
                "name": "Paganica-Tempera",
                "countryCode": "IT"
            },
            {
                "name": "Pagliare",
                "countryCode": "IT"
            },
            {
                "name": "Paglieta",
                "countryCode": "IT"
            },
            {
                "name": "Palena",
                "countryCode": "IT"
            },
            {
                "name": "Palmoli",
                "countryCode": "IT"
            },
            {
                "name": "Palombaro",
                "countryCode": "IT"
            },
            {
                "name": "Penna Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Pennadomo",
                "countryCode": "IT"
            },
            {
                "name": "Pennapiedimonte",
                "countryCode": "IT"
            },
            {
                "name": "Penne",
                "countryCode": "IT"
            },
            {
                "name": "Perano",
                "countryCode": "IT"
            },
            {
                "name": "Pereto",
                "countryCode": "IT"
            },
            {
                "name": "Pescara",
                "countryCode": "IT"
            },
            {
                "name": "Pescasseroli",
                "countryCode": "IT"
            },
            {
                "name": "Pescina",
                "countryCode": "IT"
            },
            {
                "name": "Pescocostanzo",
                "countryCode": "IT"
            },
            {
                "name": "Pescosansonesco Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Pettorano sul Gizio",
                "countryCode": "IT"
            },
            {
                "name": "Pianella",
                "countryCode": "IT"
            },
            {
                "name": "Pianola",
                "countryCode": "IT"
            },
            {
                "name": "Pianura Vomano",
                "countryCode": "IT"
            },
            {
                "name": "Picciano",
                "countryCode": "IT"
            },
            {
                "name": "Pietracamela",
                "countryCode": "IT"
            },
            {
                "name": "Pietraferrazzana",
                "countryCode": "IT"
            },
            {
                "name": "Pietranico",
                "countryCode": "IT"
            },
            {
                "name": "Pineto",
                "countryCode": "IT"
            },
            {
                "name": "Pizzoferrato",
                "countryCode": "IT"
            },
            {
                "name": "Pizzoli",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Picenze",
                "countryCode": "IT"
            },
            {
                "name": "Poggiofiorito",
                "countryCode": "IT"
            },
            {
                "name": "Pollutri",
                "countryCode": "IT"
            },
            {
                "name": "Popoli",
                "countryCode": "IT"
            },
            {
                "name": "Prata d'Ansidonia",
                "countryCode": "IT"
            },
            {
                "name": "Pratola Peligna",
                "countryCode": "IT"
            },
            {
                "name": "Pretoro",
                "countryCode": "IT"
            },
            {
                "name": "Preturo",
                "countryCode": "IT"
            },
            {
                "name": "Prezza",
                "countryCode": "IT"
            },
            {
                "name": "Progetto Case Bazzano",
                "countryCode": "IT"
            },
            {
                "name": "Progetto Case Coppito 3",
                "countryCode": "IT"
            },
            {
                "name": "Progetto Case Sassa Nsi",
                "countryCode": "IT"
            },
            {
                "name": "Provincia dell' Aquila",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Chieti",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Pescara",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Teramo",
                "countryCode": "IT"
            },
            {
                "name": "Quadri",
                "countryCode": "IT"
            },
            {
                "name": "Raiano",
                "countryCode": "IT"
            },
            {
                "name": "Rapino",
                "countryCode": "IT"
            },
            {
                "name": "Ripa Teatina",
                "countryCode": "IT"
            },
            {
                "name": "Rivisondoli",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Pia",
                "countryCode": "IT"
            },
            {
                "name": "Rocca San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Botte",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Cambio",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Mezzo",
                "countryCode": "IT"
            },
            {
                "name": "Roccacasale",
                "countryCode": "IT"
            },
            {
                "name": "Roccamorice",
                "countryCode": "IT"
            },
            {
                "name": "Roccaraso",
                "countryCode": "IT"
            },
            {
                "name": "Roccascalegna",
                "countryCode": "IT"
            },
            {
                "name": "Roccaspinalveti-Santa Giusta",
                "countryCode": "IT"
            },
            {
                "name": "Roccavivi",
                "countryCode": "IT"
            },
            {
                "name": "Roio del Sangro",
                "countryCode": "IT"
            },
            {
                "name": "Rosciano",
                "countryCode": "IT"
            },
            {
                "name": "Rosello",
                "countryCode": "IT"
            },
            {
                "name": "Roseto degli Abruzzi",
                "countryCode": "IT"
            },
            {
                "name": "Salino",
                "countryCode": "IT"
            },
            {
                "name": "Salle",
                "countryCode": "IT"
            },
            {
                "name": "Salvo Marina",
                "countryCode": "IT"
            },
            {
                "name": "Sambuceto",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto dei Marsi",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto in Perillis",
                "countryCode": "IT"
            },
            {
                "name": "San Buono",
                "countryCode": "IT"
            },
            {
                "name": "San Demetrio Ne' Vestini",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Lipioni",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Bassa",
                "countryCode": "IT"
            },
            {
                "name": "San Martino sulla Marrucina",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò a Tordino",
                "countryCode": "IT"
            },
            {
                "name": "San Panfilo d'Ocre",
                "countryCode": "IT"
            },
            {
                "name": "San Pio delle Camere",
                "countryCode": "IT"
            },
            {
                "name": "San Rocco",
                "countryCode": "IT"
            },
            {
                "name": "San Salvo",
                "countryCode": "IT"
            },
            {
                "name": "San Valentino in Abruzzo Citeriore",
                "countryCode": "IT"
            },
            {
                "name": "San Vincenzo Valle Roveto",
                "countryCode": "IT"
            },
            {
                "name": "San Vito Chietino",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Egidio alla Vibrata",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Eufemia a Maiella",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Eusanio Forconese",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Eusanio del Sangro",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Omero",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Imbaro",
                "countryCode": "IT"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "IT"
            },
            {
                "name": "Sante Marie",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano di Sessanio",
                "countryCode": "IT"
            },
            {
                "name": "Sassa",
                "countryCode": "IT"
            },
            {
                "name": "Scafa",
                "countryCode": "IT"
            },
            {
                "name": "Scanno",
                "countryCode": "IT"
            },
            {
                "name": "Scerne",
                "countryCode": "IT"
            },
            {
                "name": "Scerni",
                "countryCode": "IT"
            },
            {
                "name": "Schiavi di Abruzzo",
                "countryCode": "IT"
            },
            {
                "name": "Scontrone",
                "countryCode": "IT"
            },
            {
                "name": "Scoppito",
                "countryCode": "IT"
            },
            {
                "name": "Scurcola Marsicana",
                "countryCode": "IT"
            },
            {
                "name": "Secinaro",
                "countryCode": "IT"
            },
            {
                "name": "Selva",
                "countryCode": "IT"
            },
            {
                "name": "Serramonacesca",
                "countryCode": "IT"
            },
            {
                "name": "Silvi",
                "countryCode": "IT"
            },
            {
                "name": "Spoltore",
                "countryCode": "IT"
            },
            {
                "name": "Sulmona",
                "countryCode": "IT"
            },
            {
                "name": "Tagliacozzo",
                "countryCode": "IT"
            },
            {
                "name": "Taranta Peligna",
                "countryCode": "IT"
            },
            {
                "name": "Teramo",
                "countryCode": "IT"
            },
            {
                "name": "Tione degli Abruzzi",
                "countryCode": "IT"
            },
            {
                "name": "Tocco da Casauria",
                "countryCode": "IT"
            },
            {
                "name": "Tollo",
                "countryCode": "IT"
            },
            {
                "name": "Torano Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Torino di Sangro",
                "countryCode": "IT"
            },
            {
                "name": "Tornareccio",
                "countryCode": "IT"
            },
            {
                "name": "Tornimparte",
                "countryCode": "IT"
            },
            {
                "name": "Torre de' Passeri",
                "countryCode": "IT"
            },
            {
                "name": "Torrebruna",
                "countryCode": "IT"
            },
            {
                "name": "Torrevecchia",
                "countryCode": "IT"
            },
            {
                "name": "Torricella",
                "countryCode": "IT"
            },
            {
                "name": "Torricella Peligna",
                "countryCode": "IT"
            },
            {
                "name": "Tortoreto",
                "countryCode": "IT"
            },
            {
                "name": "Tortoreto Lido",
                "countryCode": "IT"
            },
            {
                "name": "Tossicia",
                "countryCode": "IT"
            },
            {
                "name": "Trasacco",
                "countryCode": "IT"
            },
            {
                "name": "Treglio",
                "countryCode": "IT"
            },
            {
                "name": "Tufillo",
                "countryCode": "IT"
            },
            {
                "name": "Turrivalignani",
                "countryCode": "IT"
            },
            {
                "name": "Vacri",
                "countryCode": "IT"
            },
            {
                "name": "Valle Castellana",
                "countryCode": "IT"
            },
            {
                "name": "Vallecupa",
                "countryCode": "IT"
            },
            {
                "name": "Vasto",
                "countryCode": "IT"
            },
            {
                "name": "Vicoli",
                "countryCode": "IT"
            },
            {
                "name": "Villa Caldari",
                "countryCode": "IT"
            },
            {
                "name": "Villa Celiera",
                "countryCode": "IT"
            },
            {
                "name": "Villa Lempa",
                "countryCode": "IT"
            },
            {
                "name": "Villa Raspa",
                "countryCode": "IT"
            },
            {
                "name": "Villa Rosa",
                "countryCode": "IT"
            },
            {
                "name": "Villa Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Villa Santa Lucia degli Abruzzi",
                "countryCode": "IT"
            },
            {
                "name": "Villa Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Villagrande",
                "countryCode": "IT"
            },
            {
                "name": "Villalago",
                "countryCode": "IT"
            },
            {
                "name": "Villalfonsina",
                "countryCode": "IT"
            },
            {
                "name": "Villamagna",
                "countryCode": "IT"
            },
            {
                "name": "Villanova",
                "countryCode": "IT"
            },
            {
                "name": "Villavallelonga",
                "countryCode": "IT"
            },
            {
                "name": "Villetta Barrea",
                "countryCode": "IT"
            },
            {
                "name": "Vittorito",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva Collecroce",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva d'Isernia",
                "countryCode": "IT"
            },
            {
                "name": "Agnone",
                "countryCode": "IT"
            },
            {
                "name": "Bagnoli del Trigno",
                "countryCode": "IT"
            },
            {
                "name": "Baranello",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Bojano",
                "countryCode": "IT"
            },
            {
                "name": "Bonefro",
                "countryCode": "IT"
            },
            {
                "name": "Busso",
                "countryCode": "IT"
            },
            {
                "name": "Campobasso",
                "countryCode": "IT"
            },
            {
                "name": "Campochiaro",
                "countryCode": "IT"
            },
            {
                "name": "Campodipietra",
                "countryCode": "IT"
            },
            {
                "name": "Campolieto",
                "countryCode": "IT"
            },
            {
                "name": "Campomarino",
                "countryCode": "IT"
            },
            {
                "name": "Cantalupo nel Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Capracotta",
                "countryCode": "IT"
            },
            {
                "name": "Carovilli",
                "countryCode": "IT"
            },
            {
                "name": "Carpinone",
                "countryCode": "IT"
            },
            {
                "name": "Casacalenda",
                "countryCode": "IT"
            },
            {
                "name": "Casalciprano",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Vincenzo",
                "countryCode": "IT"
            },
            {
                "name": "Castel del Giudice",
                "countryCode": "IT"
            },
            {
                "name": "Castelbottaccio",
                "countryCode": "IT"
            },
            {
                "name": "Castellino del Biferno",
                "countryCode": "IT"
            },
            {
                "name": "Castelmauro",
                "countryCode": "IT"
            },
            {
                "name": "Castelpetroso",
                "countryCode": "IT"
            },
            {
                "name": "Castelpizzuto",
                "countryCode": "IT"
            },
            {
                "name": "Castelverrino",
                "countryCode": "IT"
            },
            {
                "name": "Castropignano",
                "countryCode": "IT"
            },
            {
                "name": "Cercemaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cercepiccola",
                "countryCode": "IT"
            },
            {
                "name": "Cerro al Volturno",
                "countryCode": "IT"
            },
            {
                "name": "Chiauci",
                "countryCode": "IT"
            },
            {
                "name": "Civitacampomarano",
                "countryCode": "IT"
            },
            {
                "name": "Civitanova del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Colle d'Anchise",
                "countryCode": "IT"
            },
            {
                "name": "Colletorto",
                "countryCode": "IT"
            },
            {
                "name": "Colli a Volturno",
                "countryCode": "IT"
            },
            {
                "name": "Conca Casale",
                "countryCode": "IT"
            },
            {
                "name": "Duronia",
                "countryCode": "IT"
            },
            {
                "name": "Ferrazzano",
                "countryCode": "IT"
            },
            {
                "name": "Filignano",
                "countryCode": "IT"
            },
            {
                "name": "Forlì del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Fornelli",
                "countryCode": "IT"
            },
            {
                "name": "Fossalto",
                "countryCode": "IT"
            },
            {
                "name": "Frosolone",
                "countryCode": "IT"
            },
            {
                "name": "Gambatesa",
                "countryCode": "IT"
            },
            {
                "name": "Gildone",
                "countryCode": "IT"
            },
            {
                "name": "Guardialfiera",
                "countryCode": "IT"
            },
            {
                "name": "Guardiaregia",
                "countryCode": "IT"
            },
            {
                "name": "Guglionesi",
                "countryCode": "IT"
            },
            {
                "name": "Isernia",
                "countryCode": "IT"
            },
            {
                "name": "Jelsi",
                "countryCode": "IT"
            },
            {
                "name": "Larino",
                "countryCode": "IT"
            },
            {
                "name": "Limosano",
                "countryCode": "IT"
            },
            {
                "name": "Longano",
                "countryCode": "IT"
            },
            {
                "name": "Lucito",
                "countryCode": "IT"
            },
            {
                "name": "Lupara",
                "countryCode": "IT"
            },
            {
                "name": "Macchia Valfortore",
                "countryCode": "IT"
            },
            {
                "name": "Macchia d'Isernia",
                "countryCode": "IT"
            },
            {
                "name": "Macchiagodena",
                "countryCode": "IT"
            },
            {
                "name": "Mafalda",
                "countryCode": "IT"
            },
            {
                "name": "Matrice",
                "countryCode": "IT"
            },
            {
                "name": "Mimosa-Poggio Verde-Nuova Comunità",
                "countryCode": "IT"
            },
            {
                "name": "Mirabello Sannitico",
                "countryCode": "IT"
            },
            {
                "name": "Miranda",
                "countryCode": "IT"
            },
            {
                "name": "Molise",
                "countryCode": "IT"
            },
            {
                "name": "Monacilioni",
                "countryCode": "IT"
            },
            {
                "name": "Montagano",
                "countryCode": "IT"
            },
            {
                "name": "Montaquila",
                "countryCode": "IT"
            },
            {
                "name": "Montecilfone",
                "countryCode": "IT"
            },
            {
                "name": "Montefalcone nel Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Montelongo",
                "countryCode": "IT"
            },
            {
                "name": "Montemitro",
                "countryCode": "IT"
            },
            {
                "name": "Montenero Val Cocchiara",
                "countryCode": "IT"
            },
            {
                "name": "Montenero di Bisaccia",
                "countryCode": "IT"
            },
            {
                "name": "Monteroduni",
                "countryCode": "IT"
            },
            {
                "name": "Montorio Nei Frentani",
                "countryCode": "IT"
            },
            {
                "name": "Morrone del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Oratino",
                "countryCode": "IT"
            },
            {
                "name": "Palata",
                "countryCode": "IT"
            },
            {
                "name": "Pesche",
                "countryCode": "IT"
            },
            {
                "name": "Pescolanciano",
                "countryCode": "IT"
            },
            {
                "name": "Pescopennataro",
                "countryCode": "IT"
            },
            {
                "name": "Petacciato",
                "countryCode": "IT"
            },
            {
                "name": "Petrella Tifernina",
                "countryCode": "IT"
            },
            {
                "name": "Pettoranello del Molise",
                "countryCode": "IT"
            },
            {
                "name": "Pietrabbondante",
                "countryCode": "IT"
            },
            {
                "name": "Pietracatella",
                "countryCode": "IT"
            },
            {
                "name": "Pietracupa",
                "countryCode": "IT"
            },
            {
                "name": "Pizzone",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Portocannone",
                "countryCode": "IT"
            },
            {
                "name": "Pozzilli",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Campobasso",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Isernia",
                "countryCode": "IT"
            },
            {
                "name": "Provvidenti",
                "countryCode": "IT"
            },
            {
                "name": "Riccia",
                "countryCode": "IT"
            },
            {
                "name": "Rionero Sannitico",
                "countryCode": "IT"
            },
            {
                "name": "Ripabottoni",
                "countryCode": "IT"
            },
            {
                "name": "Ripalimosani",
                "countryCode": "IT"
            },
            {
                "name": "Roccamandolfi",
                "countryCode": "IT"
            },
            {
                "name": "Roccasicura",
                "countryCode": "IT"
            },
            {
                "name": "Roccavivara",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta a Volturno",
                "countryCode": "IT"
            },
            {
                "name": "Rotello",
                "countryCode": "IT"
            },
            {
                "name": "Salcito",
                "countryCode": "IT"
            },
            {
                "name": "San Biase",
                "countryCode": "IT"
            },
            {
                "name": "San Felice del Molise",
                "countryCode": "IT"
            },
            {
                "name": "San Giacomo degli Schiavoni",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni in Galdo",
                "countryCode": "IT"
            },
            {
                "name": "San Giuliano del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "San Giuliano di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "San Martino in Pensilis",
                "countryCode": "IT"
            },
            {
                "name": "San Massimo",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Avellana",
                "countryCode": "IT"
            },
            {
                "name": "San Polomatese",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agapito",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo Limosano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo del Pesco",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elena Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elia a Pianisi",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce di Magliano",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria del Molise",
                "countryCode": "IT"
            },
            {
                "name": "Scapoli",
                "countryCode": "IT"
            },
            {
                "name": "Sepino",
                "countryCode": "IT"
            },
            {
                "name": "Sessano del Molise",
                "countryCode": "IT"
            },
            {
                "name": "Sesto Campano",
                "countryCode": "IT"
            },
            {
                "name": "Spinete",
                "countryCode": "IT"
            },
            {
                "name": "Tavenna",
                "countryCode": "IT"
            },
            {
                "name": "Taverna Ravindola",
                "countryCode": "IT"
            },
            {
                "name": "Termoli",
                "countryCode": "IT"
            },
            {
                "name": "Torella del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Toro",
                "countryCode": "IT"
            },
            {
                "name": "Trivento",
                "countryCode": "IT"
            },
            {
                "name": "Tufara",
                "countryCode": "IT"
            },
            {
                "name": "Ururi",
                "countryCode": "IT"
            },
            {
                "name": "Vastogirardi",
                "countryCode": "IT"
            },
            {
                "name": "Venafro",
                "countryCode": "IT"
            },
            {
                "name": "Vinchiaturo",
                "countryCode": "IT"
            },
            {
                "name": "Acerno",
                "countryCode": "IT"
            },
            {
                "name": "Acerra",
                "countryCode": "IT"
            },
            {
                "name": "Afragola",
                "countryCode": "IT"
            },
            {
                "name": "Agerola",
                "countryCode": "IT"
            },
            {
                "name": "Agropoli",
                "countryCode": "IT"
            },
            {
                "name": "Aiello del Sabato",
                "countryCode": "IT"
            },
            {
                "name": "Ailano",
                "countryCode": "IT"
            },
            {
                "name": "Airola",
                "countryCode": "IT"
            },
            {
                "name": "Albanella",
                "countryCode": "IT"
            },
            {
                "name": "Alfano",
                "countryCode": "IT"
            },
            {
                "name": "Alife",
                "countryCode": "IT"
            },
            {
                "name": "Altavilla Irpina",
                "countryCode": "IT"
            },
            {
                "name": "Altavilla Silentina",
                "countryCode": "IT"
            },
            {
                "name": "Alvignano",
                "countryCode": "IT"
            },
            {
                "name": "Amalfi",
                "countryCode": "IT"
            },
            {
                "name": "Amodio-Massariola",
                "countryCode": "IT"
            },
            {
                "name": "Amorosi",
                "countryCode": "IT"
            },
            {
                "name": "Anacapri",
                "countryCode": "IT"
            },
            {
                "name": "Andretta-Mattinella",
                "countryCode": "IT"
            },
            {
                "name": "Angri",
                "countryCode": "IT"
            },
            {
                "name": "Annunziata",
                "countryCode": "IT"
            },
            {
                "name": "Antessano",
                "countryCode": "IT"
            },
            {
                "name": "Apice Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "Apollosa",
                "countryCode": "IT"
            },
            {
                "name": "Aquara",
                "countryCode": "IT"
            },
            {
                "name": "Aquilonia",
                "countryCode": "IT"
            },
            {
                "name": "Arenella",
                "countryCode": "IT"
            },
            {
                "name": "Ariano",
                "countryCode": "IT"
            },
            {
                "name": "Ariano Irpino-Martiri",
                "countryCode": "IT"
            },
            {
                "name": "Arienzo",
                "countryCode": "IT"
            },
            {
                "name": "Ariola",
                "countryCode": "IT"
            },
            {
                "name": "Arola-Preazzano",
                "countryCode": "IT"
            },
            {
                "name": "Arpaia",
                "countryCode": "IT"
            },
            {
                "name": "Arpaise",
                "countryCode": "IT"
            },
            {
                "name": "Arpino",
                "countryCode": "IT"
            },
            {
                "name": "Arzano",
                "countryCode": "IT"
            },
            {
                "name": "Ascea",
                "countryCode": "IT"
            },
            {
                "name": "Atena Lucana",
                "countryCode": "IT"
            },
            {
                "name": "Atrani",
                "countryCode": "IT"
            },
            {
                "name": "Atripalda",
                "countryCode": "IT"
            },
            {
                "name": "Auletta",
                "countryCode": "IT"
            },
            {
                "name": "Avella",
                "countryCode": "IT"
            },
            {
                "name": "Avellino",
                "countryCode": "IT"
            },
            {
                "name": "Aversa",
                "countryCode": "IT"
            },
            {
                "name": "Avvocata",
                "countryCode": "IT"
            },
            {
                "name": "Bacoli",
                "countryCode": "IT"
            },
            {
                "name": "Bagnoli",
                "countryCode": "IT"
            },
            {
                "name": "Bagnoli Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Baia",
                "countryCode": "IT"
            },
            {
                "name": "Baiano",
                "countryCode": "IT"
            },
            {
                "name": "Banzano",
                "countryCode": "IT"
            },
            {
                "name": "Barano d'Ischia",
                "countryCode": "IT"
            },
            {
                "name": "Baronissi",
                "countryCode": "IT"
            },
            {
                "name": "Barra",
                "countryCode": "IT"
            },
            {
                "name": "Baselice",
                "countryCode": "IT"
            },
            {
                "name": "Battipaglia",
                "countryCode": "IT"
            },
            {
                "name": "Bellizzi",
                "countryCode": "IT"
            },
            {
                "name": "Bellona",
                "countryCode": "IT"
            },
            {
                "name": "Bellosguardo",
                "countryCode": "IT"
            },
            {
                "name": "Beltiglio-San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Benevento",
                "countryCode": "IT"
            },
            {
                "name": "Bisaccia",
                "countryCode": "IT"
            },
            {
                "name": "Bisaccia Nuova",
                "countryCode": "IT"
            },
            {
                "name": "Bivio Mortola",
                "countryCode": "IT"
            },
            {
                "name": "Bivio Santa Cecilia",
                "countryCode": "IT"
            },
            {
                "name": "Bonea",
                "countryCode": "IT"
            },
            {
                "name": "Bonito",
                "countryCode": "IT"
            },
            {
                "name": "Borgo",
                "countryCode": "IT"
            },
            {
                "name": "Boscoreale",
                "countryCode": "IT"
            },
            {
                "name": "Boscotrecase",
                "countryCode": "IT"
            },
            {
                "name": "Bracigliano",
                "countryCode": "IT"
            },
            {
                "name": "Brezza",
                "countryCode": "IT"
            },
            {
                "name": "Brusciano",
                "countryCode": "IT"
            },
            {
                "name": "Bucciano",
                "countryCode": "IT"
            },
            {
                "name": "Buccino",
                "countryCode": "IT"
            },
            {
                "name": "Buonabitacolo",
                "countryCode": "IT"
            },
            {
                "name": "Buonalbergo",
                "countryCode": "IT"
            },
            {
                "name": "Caggiano",
                "countryCode": "IT"
            },
            {
                "name": "Caianello",
                "countryCode": "IT"
            },
            {
                "name": "Caiazzo",
                "countryCode": "IT"
            },
            {
                "name": "Cairano",
                "countryCode": "IT"
            },
            {
                "name": "Caivano",
                "countryCode": "IT"
            },
            {
                "name": "Calabritto",
                "countryCode": "IT"
            },
            {
                "name": "Calitri",
                "countryCode": "IT"
            },
            {
                "name": "Calvanico",
                "countryCode": "IT"
            },
            {
                "name": "Calvi",
                "countryCode": "IT"
            },
            {
                "name": "Calvi Risorta",
                "countryCode": "IT"
            },
            {
                "name": "Calvizzano",
                "countryCode": "IT"
            },
            {
                "name": "Camerota",
                "countryCode": "IT"
            },
            {
                "name": "Camigliano",
                "countryCode": "IT"
            },
            {
                "name": "Campagna",
                "countryCode": "IT"
            },
            {
                "name": "Campanarello",
                "countryCode": "IT"
            },
            {
                "name": "Campolattaro",
                "countryCode": "IT"
            },
            {
                "name": "Campoli del Monte Taburno",
                "countryCode": "IT"
            },
            {
                "name": "Campora",
                "countryCode": "IT"
            },
            {
                "name": "Camposano",
                "countryCode": "IT"
            },
            {
                "name": "Cancello-Arnone",
                "countryCode": "IT"
            },
            {
                "name": "Candida",
                "countryCode": "IT"
            },
            {
                "name": "Cannalonga",
                "countryCode": "IT"
            },
            {
                "name": "Capaccio",
                "countryCode": "IT"
            },
            {
                "name": "Capaccio Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Capezzano Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Capezzano-Cologna",
                "countryCode": "IT"
            },
            {
                "name": "Capitignano",
                "countryCode": "IT"
            },
            {
                "name": "Capodrise",
                "countryCode": "IT"
            },
            {
                "name": "Caposele",
                "countryCode": "IT"
            },
            {
                "name": "Capri",
                "countryCode": "IT"
            },
            {
                "name": "Capriati A Volturno",
                "countryCode": "IT"
            },
            {
                "name": "Capriglia",
                "countryCode": "IT"
            },
            {
                "name": "Capriglia Irpina",
                "countryCode": "IT"
            },
            {
                "name": "Capua",
                "countryCode": "IT"
            },
            {
                "name": "Carano",
                "countryCode": "IT"
            },
            {
                "name": "Carbonara di Nola",
                "countryCode": "IT"
            },
            {
                "name": "Cardito",
                "countryCode": "IT"
            },
            {
                "name": "Carife",
                "countryCode": "IT"
            },
            {
                "name": "Carifi-Torello-Priscoli",
                "countryCode": "IT"
            },
            {
                "name": "Carinaro",
                "countryCode": "IT"
            },
            {
                "name": "Carinola",
                "countryCode": "IT"
            },
            {
                "name": "Casagiove",
                "countryCode": "IT"
            },
            {
                "name": "Casal Velino",
                "countryCode": "IT"
            },
            {
                "name": "Casal di Principe",
                "countryCode": "IT"
            },
            {
                "name": "Casalbore",
                "countryCode": "IT"
            },
            {
                "name": "Casalbuono",
                "countryCode": "IT"
            },
            {
                "name": "Casalduni",
                "countryCode": "IT"
            },
            {
                "name": "Casale",
                "countryCode": "IT"
            },
            {
                "name": "Casaletto Spartano",
                "countryCode": "IT"
            },
            {
                "name": "Casali-San Potito",
                "countryCode": "IT"
            },
            {
                "name": "Casalnuovo di Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Casaluce",
                "countryCode": "IT"
            },
            {
                "name": "Casamarciano",
                "countryCode": "IT"
            },
            {
                "name": "Casamicciola Terme",
                "countryCode": "IT"
            },
            {
                "name": "Casandrino",
                "countryCode": "IT"
            },
            {
                "name": "Casanova",
                "countryCode": "IT"
            },
            {
                "name": "Casapesenna",
                "countryCode": "IT"
            },
            {
                "name": "Casapulla",
                "countryCode": "IT"
            },
            {
                "name": "Casarea",
                "countryCode": "IT"
            },
            {
                "name": "Casavatore",
                "countryCode": "IT"
            },
            {
                "name": "Cascano",
                "countryCode": "IT"
            },
            {
                "name": "Caselle in Pittari",
                "countryCode": "IT"
            },
            {
                "name": "Caserta",
                "countryCode": "IT"
            },
            {
                "name": "Casola",
                "countryCode": "IT"
            },
            {
                "name": "Casola di Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Casoria",
                "countryCode": "IT"
            },
            {
                "name": "Cassano Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Castel Baronia",
                "countryCode": "IT"
            },
            {
                "name": "Castel Campagnano",
                "countryCode": "IT"
            },
            {
                "name": "Castel Morrone",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Castel San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "Castel Volturno",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Sasso",
                "countryCode": "IT"
            },
            {
                "name": "Castelcivita",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranci",
                "countryCode": "IT"
            },
            {
                "name": "Castelfranco in Miscano",
                "countryCode": "IT"
            },
            {
                "name": "Castellammare di Stabia",
                "countryCode": "IT"
            },
            {
                "name": "Castello del Matese",
                "countryCode": "IT"
            },
            {
                "name": "Castello di Cisterna",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo di Conza",
                "countryCode": "IT"
            },
            {
                "name": "Castelpagano",
                "countryCode": "IT"
            },
            {
                "name": "Castelpoto",
                "countryCode": "IT"
            },
            {
                "name": "Castelvenere",
                "countryCode": "IT"
            },
            {
                "name": "Castelvetere in Val Fortore",
                "countryCode": "IT"
            },
            {
                "name": "Castelvetere sul Calore",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione del Genovesi",
                "countryCode": "IT"
            },
            {
                "name": "Cautano",
                "countryCode": "IT"
            },
            {
                "name": "Cava Dè Tirreni",
                "countryCode": "IT"
            },
            {
                "name": "Celle di Bulgheria",
                "countryCode": "IT"
            },
            {
                "name": "Cellole",
                "countryCode": "IT"
            },
            {
                "name": "Centola",
                "countryCode": "IT"
            },
            {
                "name": "Centro Urbano",
                "countryCode": "IT"
            },
            {
                "name": "Ceppaloni",
                "countryCode": "IT"
            },
            {
                "name": "Ceraso",
                "countryCode": "IT"
            },
            {
                "name": "Cercola",
                "countryCode": "IT"
            },
            {
                "name": "Cerreto Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Cervinara",
                "countryCode": "IT"
            },
            {
                "name": "Cervino",
                "countryCode": "IT"
            },
            {
                "name": "Cesa",
                "countryCode": "IT"
            },
            {
                "name": "Cesinali",
                "countryCode": "IT"
            },
            {
                "name": "Cetara",
                "countryCode": "IT"
            },
            {
                "name": "Chiaia",
                "countryCode": "IT"
            },
            {
                "name": "Chiaiano",
                "countryCode": "IT"
            },
            {
                "name": "Chianche",
                "countryCode": "IT"
            },
            {
                "name": "Chiusano di San Domenico",
                "countryCode": "IT"
            },
            {
                "name": "Cicciano",
                "countryCode": "IT"
            },
            {
                "name": "Cicerale",
                "countryCode": "IT"
            },
            {
                "name": "Cimitile",
                "countryCode": "IT"
            },
            {
                "name": "Ciorlano",
                "countryCode": "IT"
            },
            {
                "name": "Circello",
                "countryCode": "IT"
            },
            {
                "name": "Colle Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Colliano",
                "countryCode": "IT"
            },
            {
                "name": "Comiziano",
                "countryCode": "IT"
            },
            {
                "name": "Conca dei Marini",
                "countryCode": "IT"
            },
            {
                "name": "Conca della Campania",
                "countryCode": "IT"
            },
            {
                "name": "Contrada",
                "countryCode": "IT"
            },
            {
                "name": "Controne",
                "countryCode": "IT"
            },
            {
                "name": "Contursi Terme",
                "countryCode": "IT"
            },
            {
                "name": "Coperchia",
                "countryCode": "IT"
            },
            {
                "name": "Corbara",
                "countryCode": "IT"
            },
            {
                "name": "Corleto Monforte",
                "countryCode": "IT"
            },
            {
                "name": "Country Park",
                "countryCode": "IT"
            },
            {
                "name": "Crispano",
                "countryCode": "IT"
            },
            {
                "name": "Cuccaro Vetere",
                "countryCode": "IT"
            },
            {
                "name": "Curti",
                "countryCode": "IT"
            },
            {
                "name": "Cusano Mutri",
                "countryCode": "IT"
            },
            {
                "name": "Domicella",
                "countryCode": "IT"
            },
            {
                "name": "Dragonea",
                "countryCode": "IT"
            },
            {
                "name": "Dragoni",
                "countryCode": "IT"
            },
            {
                "name": "Dugenta",
                "countryCode": "IT"
            },
            {
                "name": "Durazzano",
                "countryCode": "IT"
            },
            {
                "name": "Eboli",
                "countryCode": "IT"
            },
            {
                "name": "Ercolano",
                "countryCode": "IT"
            },
            {
                "name": "Faiano",
                "countryCode": "IT"
            },
            {
                "name": "Faicchio",
                "countryCode": "IT"
            },
            {
                "name": "Falciano del Massico",
                "countryCode": "IT"
            },
            {
                "name": "Faraldo-Nocelleto",
                "countryCode": "IT"
            },
            {
                "name": "Felitto",
                "countryCode": "IT"
            },
            {
                "name": "Fisciano",
                "countryCode": "IT"
            },
            {
                "name": "Flumeri",
                "countryCode": "IT"
            },
            {
                "name": "Foglianise",
                "countryCode": "IT"
            },
            {
                "name": "Foiano di Val Fortore",
                "countryCode": "IT"
            },
            {
                "name": "Fontanarosa",
                "countryCode": "IT"
            },
            {
                "name": "Fontegreca",
                "countryCode": "IT"
            },
            {
                "name": "Forchia",
                "countryCode": "IT"
            },
            {
                "name": "Forino",
                "countryCode": "IT"
            },
            {
                "name": "Forio",
                "countryCode": "IT"
            },
            {
                "name": "Formicola",
                "countryCode": "IT"
            },
            {
                "name": "Fragneto L'Abate",
                "countryCode": "IT"
            },
            {
                "name": "Fragneto Monforte",
                "countryCode": "IT"
            },
            {
                "name": "Franche",
                "countryCode": "IT"
            },
            {
                "name": "Francolise",
                "countryCode": "IT"
            },
            {
                "name": "Frasso Telesino",
                "countryCode": "IT"
            },
            {
                "name": "Frattamaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Frattaminore",
                "countryCode": "IT"
            },
            {
                "name": "Frigento",
                "countryCode": "IT"
            },
            {
                "name": "Frignano",
                "countryCode": "IT"
            },
            {
                "name": "Fuorigrotta",
                "countryCode": "IT"
            },
            {
                "name": "Fuorni",
                "countryCode": "IT"
            },
            {
                "name": "Furore",
                "countryCode": "IT"
            },
            {
                "name": "Futani",
                "countryCode": "IT"
            },
            {
                "name": "Gallo",
                "countryCode": "IT"
            },
            {
                "name": "Gallo Matese",
                "countryCode": "IT"
            },
            {
                "name": "Galluccio",
                "countryCode": "IT"
            },
            {
                "name": "Gesualdo",
                "countryCode": "IT"
            },
            {
                "name": "Giano Vetusto",
                "countryCode": "IT"
            },
            {
                "name": "Giffoni Valle Piana",
                "countryCode": "IT"
            },
            {
                "name": "Ginestra degli Schiavoni",
                "countryCode": "IT"
            },
            {
                "name": "Gioi",
                "countryCode": "IT"
            },
            {
                "name": "Gioia Sannitica",
                "countryCode": "IT"
            },
            {
                "name": "Giugliano in Campania",
                "countryCode": "IT"
            },
            {
                "name": "Giungano",
                "countryCode": "IT"
            },
            {
                "name": "Gragnano",
                "countryCode": "IT"
            },
            {
                "name": "Grazzanise",
                "countryCode": "IT"
            },
            {
                "name": "Greci",
                "countryCode": "IT"
            },
            {
                "name": "Gricignano di Aversa",
                "countryCode": "IT"
            },
            {
                "name": "Grottaminarda",
                "countryCode": "IT"
            },
            {
                "name": "Grottola",
                "countryCode": "IT"
            },
            {
                "name": "Grottolella",
                "countryCode": "IT"
            },
            {
                "name": "Grumo Nevano",
                "countryCode": "IT"
            },
            {
                "name": "Guardia Lombardi",
                "countryCode": "IT"
            },
            {
                "name": "Guardia Sanframondi",
                "countryCode": "IT"
            },
            {
                "name": "Ischia",
                "countryCode": "IT"
            },
            {
                "name": "Ischia Porto",
                "countryCode": "IT"
            },
            {
                "name": "Ispani",
                "countryCode": "IT"
            },
            {
                "name": "Lacco Ameno",
                "countryCode": "IT"
            },
            {
                "name": "Lacedonia",
                "countryCode": "IT"
            },
            {
                "name": "Lancusi-Penta-Bolano",
                "countryCode": "IT"
            },
            {
                "name": "Lanzara",
                "countryCode": "IT"
            },
            {
                "name": "Lapio",
                "countryCode": "IT"
            },
            {
                "name": "Laura",
                "countryCode": "IT"
            },
            {
                "name": "Laureana Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Laurino",
                "countryCode": "IT"
            },
            {
                "name": "Laurito",
                "countryCode": "IT"
            },
            {
                "name": "Lauro",
                "countryCode": "IT"
            },
            {
                "name": "Laviano",
                "countryCode": "IT"
            },
            {
                "name": "Letino",
                "countryCode": "IT"
            },
            {
                "name": "Lettere",
                "countryCode": "IT"
            },
            {
                "name": "Liberi",
                "countryCode": "IT"
            },
            {
                "name": "Licinella-Torre di Paestum",
                "countryCode": "IT"
            },
            {
                "name": "Licusati",
                "countryCode": "IT"
            },
            {
                "name": "Limatola",
                "countryCode": "IT"
            },
            {
                "name": "Lioni",
                "countryCode": "IT"
            },
            {
                "name": "Liveri",
                "countryCode": "IT"
            },
            {
                "name": "Luogosano",
                "countryCode": "IT"
            },
            {
                "name": "Lusciano",
                "countryCode": "IT"
            },
            {
                "name": "Lustra",
                "countryCode": "IT"
            },
            {
                "name": "Luzzano",
                "countryCode": "IT"
            },
            {
                "name": "Macchia",
                "countryCode": "IT"
            },
            {
                "name": "Maccoli-Perrillo",
                "countryCode": "IT"
            },
            {
                "name": "Macerata Campania",
                "countryCode": "IT"
            },
            {
                "name": "Maddaloni",
                "countryCode": "IT"
            },
            {
                "name": "Magliano Vetere",
                "countryCode": "IT"
            },
            {
                "name": "Maiori",
                "countryCode": "IT"
            },
            {
                "name": "Malche-Santa Croce-Serroni",
                "countryCode": "IT"
            },
            {
                "name": "Manocalzati",
                "countryCode": "IT"
            },
            {
                "name": "Marano di Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Marcianise",
                "countryCode": "IT"
            },
            {
                "name": "Mariglianella",
                "countryCode": "IT"
            },
            {
                "name": "Marigliano",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Camerota",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Casal Velino",
                "countryCode": "IT"
            },
            {
                "name": "Marzanello",
                "countryCode": "IT"
            },
            {
                "name": "Marzano Appio",
                "countryCode": "IT"
            },
            {
                "name": "Marzano di Nola",
                "countryCode": "IT"
            },
            {
                "name": "Massa Lubrense",
                "countryCode": "IT"
            },
            {
                "name": "Massa di Somma",
                "countryCode": "IT"
            },
            {
                "name": "Masseria Vecchia Ovest",
                "countryCode": "IT"
            },
            {
                "name": "Matinella",
                "countryCode": "IT"
            },
            {
                "name": "Melito Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Melito di Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Melizzano",
                "countryCode": "IT"
            },
            {
                "name": "Mercato",
                "countryCode": "IT"
            },
            {
                "name": "Mercato San Severino",
                "countryCode": "IT"
            },
            {
                "name": "Mercogliano",
                "countryCode": "IT"
            },
            {
                "name": "Meta",
                "countryCode": "IT"
            },
            {
                "name": "Miano",
                "countryCode": "IT"
            },
            {
                "name": "Mignano Monte Lungo",
                "countryCode": "IT"
            },
            {
                "name": "Minori",
                "countryCode": "IT"
            },
            {
                "name": "Mirabella Eclano",
                "countryCode": "IT"
            },
            {
                "name": "Moiano",
                "countryCode": "IT"
            },
            {
                "name": "Moio della Civitella-Pellare",
                "countryCode": "IT"
            },
            {
                "name": "Molinara",
                "countryCode": "IT"
            },
            {
                "name": "Mondragone",
                "countryCode": "IT"
            },
            {
                "name": "Montaguto",
                "countryCode": "IT"
            },
            {
                "name": "Montano Antilia",
                "countryCode": "IT"
            },
            {
                "name": "Monte San Giacomo",
                "countryCode": "IT"
            },
            {
                "name": "Monte di Procida",
                "countryCode": "IT"
            },
            {
                "name": "Montecalvario",
                "countryCode": "IT"
            },
            {
                "name": "Montecalvo Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Montecorice",
                "countryCode": "IT"
            },
            {
                "name": "Montecorvino Pugliano",
                "countryCode": "IT"
            },
            {
                "name": "Montecorvino Rovella",
                "countryCode": "IT"
            },
            {
                "name": "Montedecoro",
                "countryCode": "IT"
            },
            {
                "name": "Montefalcione",
                "countryCode": "IT"
            },
            {
                "name": "Montefalcone di Val Fortore",
                "countryCode": "IT"
            },
            {
                "name": "Monteforte Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Monteforte Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Montefredane",
                "countryCode": "IT"
            },
            {
                "name": "Montefusco",
                "countryCode": "IT"
            },
            {
                "name": "Montella",
                "countryCode": "IT"
            },
            {
                "name": "Montemarano",
                "countryCode": "IT"
            },
            {
                "name": "Montemiletto",
                "countryCode": "IT"
            },
            {
                "name": "Monterusciello",
                "countryCode": "IT"
            },
            {
                "name": "Montesano sulla Marcellana",
                "countryCode": "IT"
            },
            {
                "name": "Montesarchio",
                "countryCode": "IT"
            },
            {
                "name": "Monteverde",
                "countryCode": "IT"
            },
            {
                "name": "Monticelli",
                "countryCode": "IT"
            },
            {
                "name": "Montoro Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Morcone",
                "countryCode": "IT"
            },
            {
                "name": "Morigerati",
                "countryCode": "IT"
            },
            {
                "name": "Morra de Sanctis",
                "countryCode": "IT"
            },
            {
                "name": "Moschiano",
                "countryCode": "IT"
            },
            {
                "name": "Mugnano del Cardinale",
                "countryCode": "IT"
            },
            {
                "name": "Mugnano di Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Musci",
                "countryCode": "IT"
            },
            {
                "name": "Naples",
                "countryCode": "IT"
            },
            {
                "name": "Napoli",
                "countryCode": "IT"
            },
            {
                "name": "Nocelleto",
                "countryCode": "IT"
            },
            {
                "name": "Nocera Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Nocera Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Nola",
                "countryCode": "IT"
            },
            {
                "name": "Novi Velia",
                "countryCode": "IT"
            },
            {
                "name": "Nuova Conza della Campania",
                "countryCode": "IT"
            },
            {
                "name": "Nusco",
                "countryCode": "IT"
            },
            {
                "name": "Ogliastro Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Olevano sul Tusciano",
                "countryCode": "IT"
            },
            {
                "name": "Oliveto Citra",
                "countryCode": "IT"
            },
            {
                "name": "Omignano",
                "countryCode": "IT"
            },
            {
                "name": "Orria",
                "countryCode": "IT"
            },
            {
                "name": "Orta di Atella",
                "countryCode": "IT"
            },
            {
                "name": "Ospedaletto d'Alpinolo",
                "countryCode": "IT"
            },
            {
                "name": "Ottati",
                "countryCode": "IT"
            },
            {
                "name": "Ottaviano",
                "countryCode": "IT"
            },
            {
                "name": "Padula",
                "countryCode": "IT"
            },
            {
                "name": "Paduli",
                "countryCode": "IT"
            },
            {
                "name": "Pagani",
                "countryCode": "IT"
            },
            {
                "name": "Pagliarone",
                "countryCode": "IT"
            },
            {
                "name": "Pago Veiano",
                "countryCode": "IT"
            },
            {
                "name": "Pago del Vallo di Lauro",
                "countryCode": "IT"
            },
            {
                "name": "Palinuro",
                "countryCode": "IT"
            },
            {
                "name": "Palma Campania",
                "countryCode": "IT"
            },
            {
                "name": "Palomonte",
                "countryCode": "IT"
            },
            {
                "name": "Pannarano",
                "countryCode": "IT"
            },
            {
                "name": "Paolisi",
                "countryCode": "IT"
            },
            {
                "name": "Parete",
                "countryCode": "IT"
            },
            {
                "name": "Parolise",
                "countryCode": "IT"
            },
            {
                "name": "Pascarola",
                "countryCode": "IT"
            },
            {
                "name": "Passo di Mirabella-Pianopantano",
                "countryCode": "IT"
            },
            {
                "name": "Pastorano",
                "countryCode": "IT"
            },
            {
                "name": "Paternopoli",
                "countryCode": "IT"
            },
            {
                "name": "Paupisi",
                "countryCode": "IT"
            },
            {
                "name": "Pellezzano",
                "countryCode": "IT"
            },
            {
                "name": "Pendino",
                "countryCode": "IT"
            },
            {
                "name": "Perdifumo",
                "countryCode": "IT"
            },
            {
                "name": "Perito",
                "countryCode": "IT"
            },
            {
                "name": "Pertosa",
                "countryCode": "IT"
            },
            {
                "name": "Pesco Sannita",
                "countryCode": "IT"
            },
            {
                "name": "Petina",
                "countryCode": "IT"
            },
            {
                "name": "Petruro Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Pezzano-Filetta",
                "countryCode": "IT"
            },
            {
                "name": "Piaggine",
                "countryCode": "IT"
            },
            {
                "name": "Piana di Monte Verna",
                "countryCode": "IT"
            },
            {
                "name": "Pianillo",
                "countryCode": "IT"
            },
            {
                "name": "Piano",
                "countryCode": "IT"
            },
            {
                "name": "Piano di Sorrento",
                "countryCode": "IT"
            },
            {
                "name": "Pianura",
                "countryCode": "IT"
            },
            {
                "name": "Piazza Roma",
                "countryCode": "IT"
            },
            {
                "name": "Piazza del Galdo-Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Piazza di Pandola",
                "countryCode": "IT"
            },
            {
                "name": "Piazza-Tralia-Pendolo",
                "countryCode": "IT"
            },
            {
                "name": "Piazzola",
                "countryCode": "IT"
            },
            {
                "name": "Piazzolla",
                "countryCode": "IT"
            },
            {
                "name": "Piedimonte",
                "countryCode": "IT"
            },
            {
                "name": "Piedimonte Matese",
                "countryCode": "IT"
            },
            {
                "name": "Piegolelle-San Bartolomeo",
                "countryCode": "IT"
            },
            {
                "name": "Pietradefusi",
                "countryCode": "IT"
            },
            {
                "name": "Pietramelara",
                "countryCode": "IT"
            },
            {
                "name": "Pietraroja",
                "countryCode": "IT"
            },
            {
                "name": "Pietrastornina",
                "countryCode": "IT"
            },
            {
                "name": "Pietravairano",
                "countryCode": "IT"
            },
            {
                "name": "Pietre",
                "countryCode": "IT"
            },
            {
                "name": "Pietrelcina",
                "countryCode": "IT"
            },
            {
                "name": "Pignataro Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "Pimonte",
                "countryCode": "IT"
            },
            {
                "name": "Piscinola",
                "countryCode": "IT"
            },
            {
                "name": "Pisciotta",
                "countryCode": "IT"
            },
            {
                "name": "Poggiomarino",
                "countryCode": "IT"
            },
            {
                "name": "Poggioreale",
                "countryCode": "IT"
            },
            {
                "name": "Policastro Bussentino",
                "countryCode": "IT"
            },
            {
                "name": "Polla",
                "countryCode": "IT"
            },
            {
                "name": "Pollena Trocchia",
                "countryCode": "IT"
            },
            {
                "name": "Pollica",
                "countryCode": "IT"
            },
            {
                "name": "Polvica",
                "countryCode": "IT"
            },
            {
                "name": "Pomigliano d'Arco",
                "countryCode": "IT"
            },
            {
                "name": "Pompei",
                "countryCode": "IT"
            },
            {
                "name": "Ponte",
                "countryCode": "IT"
            },
            {
                "name": "Pontecagnano",
                "countryCode": "IT"
            },
            {
                "name": "Pontelandolfo",
                "countryCode": "IT"
            },
            {
                "name": "Pontelatone",
                "countryCode": "IT"
            },
            {
                "name": "Ponticelli",
                "countryCode": "IT"
            },
            {
                "name": "Portici",
                "countryCode": "IT"
            },
            {
                "name": "Portico di Caserta",
                "countryCode": "IT"
            },
            {
                "name": "Porto",
                "countryCode": "IT"
            },
            {
                "name": "Posillipo",
                "countryCode": "IT"
            },
            {
                "name": "Positano",
                "countryCode": "IT"
            },
            {
                "name": "Postiglione",
                "countryCode": "IT"
            },
            {
                "name": "Pozzillo",
                "countryCode": "IT"
            },
            {
                "name": "Pozzuoli",
                "countryCode": "IT"
            },
            {
                "name": "Praiano",
                "countryCode": "IT"
            },
            {
                "name": "Prata Sannita Centro",
                "countryCode": "IT"
            },
            {
                "name": "Prata di Principato Ultra",
                "countryCode": "IT"
            },
            {
                "name": "Pratella",
                "countryCode": "IT"
            },
            {
                "name": "Prato Perillo",
                "countryCode": "IT"
            },
            {
                "name": "Pratola Serra",
                "countryCode": "IT"
            },
            {
                "name": "Pratole",
                "countryCode": "IT"
            },
            {
                "name": "Prepezzano",
                "countryCode": "IT"
            },
            {
                "name": "Presenzano",
                "countryCode": "IT"
            },
            {
                "name": "Prignano Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Procida",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Avellino",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Benevento",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Caserta",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Salerno",
                "countryCode": "IT"
            },
            {
                "name": "Puglianello",
                "countryCode": "IT"
            },
            {
                "name": "Pugliano",
                "countryCode": "IT"
            },
            {
                "name": "Quadrelle",
                "countryCode": "IT"
            },
            {
                "name": "Quadrivio",
                "countryCode": "IT"
            },
            {
                "name": "Qualiano",
                "countryCode": "IT"
            },
            {
                "name": "Quarto",
                "countryCode": "IT"
            },
            {
                "name": "Quindici",
                "countryCode": "IT"
            },
            {
                "name": "Ravello",
                "countryCode": "IT"
            },
            {
                "name": "Raviscanina",
                "countryCode": "IT"
            },
            {
                "name": "Recale",
                "countryCode": "IT"
            },
            {
                "name": "Reino",
                "countryCode": "IT"
            },
            {
                "name": "Rettifilo-Vannullo",
                "countryCode": "IT"
            },
            {
                "name": "Riardo",
                "countryCode": "IT"
            },
            {
                "name": "Ricigliano",
                "countryCode": "IT"
            },
            {
                "name": "Rocca San Felice",
                "countryCode": "IT"
            },
            {
                "name": "Roccabascerana",
                "countryCode": "IT"
            },
            {
                "name": "Roccadaspide",
                "countryCode": "IT"
            },
            {
                "name": "Roccagloriosa",
                "countryCode": "IT"
            },
            {
                "name": "Roccamonfina",
                "countryCode": "IT"
            },
            {
                "name": "Roccapiemonte",
                "countryCode": "IT"
            },
            {
                "name": "Roccarainola",
                "countryCode": "IT"
            },
            {
                "name": "Roccaromana",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta",
                "countryCode": "IT"
            },
            {
                "name": "Rofrano",
                "countryCode": "IT"
            },
            {
                "name": "Roscigno",
                "countryCode": "IT"
            },
            {
                "name": "Rotondi",
                "countryCode": "IT"
            },
            {
                "name": "Rutino",
                "countryCode": "IT"
            },
            {
                "name": "Ruviano",
                "countryCode": "IT"
            },
            {
                "name": "Sacco",
                "countryCode": "IT"
            },
            {
                "name": "Sala",
                "countryCode": "IT"
            },
            {
                "name": "Sala Consilina",
                "countryCode": "IT"
            },
            {
                "name": "Salento",
                "countryCode": "IT"
            },
            {
                "name": "Salerno",
                "countryCode": "IT"
            },
            {
                "name": "Salitto-Valle",
                "countryCode": "IT"
            },
            {
                "name": "Salvitelle",
                "countryCode": "IT"
            },
            {
                "name": "Salza Irpina",
                "countryCode": "IT"
            },
            {
                "name": "San Bartolomeo in Galdo",
                "countryCode": "IT"
            },
            {
                "name": "San Carlo All'Arena",
                "countryCode": "IT"
            },
            {
                "name": "San Castrese",
                "countryCode": "IT"
            },
            {
                "name": "San Cipriano Picentino",
                "countryCode": "IT"
            },
            {
                "name": "San Cipriano d'Aversa",
                "countryCode": "IT"
            },
            {
                "name": "San Clemente",
                "countryCode": "IT"
            },
            {
                "name": "San Felice A Cancello",
                "countryCode": "IT"
            },
            {
                "name": "San Ferdinando",
                "countryCode": "IT"
            },
            {
                "name": "San Gennaro Vesuviano",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio a Cremano",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio la Molara",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni A Piro",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni a Teduccio",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe Vesuviano",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio Magno",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio Matese",
                "countryCode": "IT"
            },
            {
                "name": "San Leucio del Sannio-Cavuoti",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzello",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo Maggiore",
                "countryCode": "IT"
            },
            {
                "name": "San Lupo",
                "countryCode": "IT"
            },
            {
                "name": "San Mango Piemonte",
                "countryCode": "IT"
            },
            {
                "name": "San Mango sul Calore",
                "countryCode": "IT"
            },
            {
                "name": "San Marcellino",
                "countryCode": "IT"
            },
            {
                "name": "San Marco",
                "countryCode": "IT"
            },
            {
                "name": "San Marco Evangelista",
                "countryCode": "IT"
            },
            {
                "name": "San Marco dei Cavoti",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Sannita",
                "countryCode": "IT"
            },
            {
                "name": "San Martino Valle Caudina",
                "countryCode": "IT"
            },
            {
                "name": "San Marzano sul Sarno",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Cilento",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro la Bruca",
                "countryCode": "IT"
            },
            {
                "name": "San Michele",
                "countryCode": "IT"
            },
            {
                "name": "San Michele di Serino",
                "countryCode": "IT"
            },
            {
                "name": "San Nazzaro",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola Baronia",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola Manfredi",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola la Strada",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo Bel Sito",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Infine",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro a Patierno",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro al Tanagro",
                "countryCode": "IT"
            },
            {
                "name": "San Potito Sannitico",
                "countryCode": "IT"
            },
            {
                "name": "San Potito Ultra",
                "countryCode": "IT"
            },
            {
                "name": "San Prisco",
                "countryCode": "IT"
            },
            {
                "name": "San Rufo",
                "countryCode": "IT"
            },
            {
                "name": "San Salvatore Telesino",
                "countryCode": "IT"
            },
            {
                "name": "San Sebastiano al Vesuvio",
                "countryCode": "IT"
            },
            {
                "name": "San Sossio Baronia",
                "countryCode": "IT"
            },
            {
                "name": "San Tammaro",
                "countryCode": "IT"
            },
            {
                "name": "San Valentino Torio",
                "countryCode": "IT"
            },
            {
                "name": "San Vitaliano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata de'Goti",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata sui Due Golfi",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agnello",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Anastasia",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea di Conza",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea-Pizzone-Ciamprisco",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo A Cupolo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo A Fasanella",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo A Scala",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo All'Esca",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo d'Alife",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo dei Lombardi",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo in Formis",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antimo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio Abate",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Arcangelo Trimonte",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Arpino",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Arsenio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Egidio del Monte Albino",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elena Irpina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce del Sannio",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia di Serino",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria A Vico",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Capua Vetere",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria La Carità",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria la Fossa",
                "countryCode": "IT"
            },
            {
                "name": "Santa Marina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Paolina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Tecla-Castelpagano",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano del Sole",
                "countryCode": "IT"
            },
            {
                "name": "Santomenna",
                "countryCode": "IT"
            },
            {
                "name": "Sanza",
                "countryCode": "IT"
            },
            {
                "name": "Sapri",
                "countryCode": "IT"
            },
            {
                "name": "Sarno",
                "countryCode": "IT"
            },
            {
                "name": "Sassano",
                "countryCode": "IT"
            },
            {
                "name": "Sassinoro",
                "countryCode": "IT"
            },
            {
                "name": "Saviano",
                "countryCode": "IT"
            },
            {
                "name": "Savignano Irpino",
                "countryCode": "IT"
            },
            {
                "name": "Scafati",
                "countryCode": "IT"
            },
            {
                "name": "Scala",
                "countryCode": "IT"
            },
            {
                "name": "Scalo Romagnano al Monte",
                "countryCode": "IT"
            },
            {
                "name": "Scampia",
                "countryCode": "IT"
            },
            {
                "name": "Scampitella",
                "countryCode": "IT"
            },
            {
                "name": "Scario",
                "countryCode": "IT"
            },
            {
                "name": "Scisciano",
                "countryCode": "IT"
            },
            {
                "name": "Secondigliano",
                "countryCode": "IT"
            },
            {
                "name": "Senerchia",
                "countryCode": "IT"
            },
            {
                "name": "Serino",
                "countryCode": "IT"
            },
            {
                "name": "Serramezzana",
                "countryCode": "IT"
            },
            {
                "name": "Serrara Fontana",
                "countryCode": "IT"
            },
            {
                "name": "Serre",
                "countryCode": "IT"
            },
            {
                "name": "Sessa Aurunca",
                "countryCode": "IT"
            },
            {
                "name": "Sessa Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Siano",
                "countryCode": "IT"
            },
            {
                "name": "Sicignano degli Alburni",
                "countryCode": "IT"
            },
            {
                "name": "Sirignano",
                "countryCode": "IT"
            },
            {
                "name": "Soccavo",
                "countryCode": "IT"
            },
            {
                "name": "Solofra",
                "countryCode": "IT"
            },
            {
                "name": "Solopaca",
                "countryCode": "IT"
            },
            {
                "name": "Somma Vesuviana",
                "countryCode": "IT"
            },
            {
                "name": "Sorbo Serpico",
                "countryCode": "IT"
            },
            {
                "name": "Sorrento",
                "countryCode": "IT"
            },
            {
                "name": "Sparanise",
                "countryCode": "IT"
            },
            {
                "name": "Spartimento",
                "countryCode": "IT"
            },
            {
                "name": "Sperone",
                "countryCode": "IT"
            },
            {
                "name": "Starza Vecchia",
                "countryCode": "IT"
            },
            {
                "name": "Stella",
                "countryCode": "IT"
            },
            {
                "name": "Stella Cilento",
                "countryCode": "IT"
            },
            {
                "name": "Stio",
                "countryCode": "IT"
            },
            {
                "name": "Striano",
                "countryCode": "IT"
            },
            {
                "name": "Sturno",
                "countryCode": "IT"
            },
            {
                "name": "Succivo",
                "countryCode": "IT"
            },
            {
                "name": "Summonte",
                "countryCode": "IT"
            },
            {
                "name": "Taurano",
                "countryCode": "IT"
            },
            {
                "name": "Taurasi",
                "countryCode": "IT"
            },
            {
                "name": "Teano",
                "countryCode": "IT"
            },
            {
                "name": "Teggiano-Macchiaroli",
                "countryCode": "IT"
            },
            {
                "name": "Telese",
                "countryCode": "IT"
            },
            {
                "name": "Teora",
                "countryCode": "IT"
            },
            {
                "name": "Terzigno",
                "countryCode": "IT"
            },
            {
                "name": "Teverola",
                "countryCode": "IT"
            },
            {
                "name": "Tocco Caudio",
                "countryCode": "IT"
            },
            {
                "name": "Tora",
                "countryCode": "IT"
            },
            {
                "name": "Torchiara",
                "countryCode": "IT"
            },
            {
                "name": "Torchiati",
                "countryCode": "IT"
            },
            {
                "name": "Torella dei Lombardi",
                "countryCode": "IT"
            },
            {
                "name": "Torelli-Torrette",
                "countryCode": "IT"
            },
            {
                "name": "Torraca",
                "countryCode": "IT"
            },
            {
                "name": "Torre Annunziata",
                "countryCode": "IT"
            },
            {
                "name": "Torre Caracciolo",
                "countryCode": "IT"
            },
            {
                "name": "Torre Orsaia",
                "countryCode": "IT"
            },
            {
                "name": "Torre del Greco",
                "countryCode": "IT"
            },
            {
                "name": "Torre le Nocelle",
                "countryCode": "IT"
            },
            {
                "name": "Torrecuso",
                "countryCode": "IT"
            },
            {
                "name": "Torretta-Scalzapecora",
                "countryCode": "IT"
            },
            {
                "name": "Torrioni",
                "countryCode": "IT"
            },
            {
                "name": "Tortorella",
                "countryCode": "IT"
            },
            {
                "name": "Tramonti",
                "countryCode": "IT"
            },
            {
                "name": "Trecase",
                "countryCode": "IT"
            },
            {
                "name": "Trentinara",
                "countryCode": "IT"
            },
            {
                "name": "Trentola-Ducenta",
                "countryCode": "IT"
            },
            {
                "name": "Trescine",
                "countryCode": "IT"
            },
            {
                "name": "Trevico",
                "countryCode": "IT"
            },
            {
                "name": "Tufino",
                "countryCode": "IT"
            },
            {
                "name": "Tufo",
                "countryCode": "IT"
            },
            {
                "name": "Vairano-Patenora",
                "countryCode": "IT"
            },
            {
                "name": "Vallata",
                "countryCode": "IT"
            },
            {
                "name": "Valle Agricola",
                "countryCode": "IT"
            },
            {
                "name": "Valle dell'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Valle di Maddaloni",
                "countryCode": "IT"
            },
            {
                "name": "Vallesaccarda",
                "countryCode": "IT"
            },
            {
                "name": "Vallo della Lucania",
                "countryCode": "IT"
            },
            {
                "name": "Valva",
                "countryCode": "IT"
            },
            {
                "name": "Venticano",
                "countryCode": "IT"
            },
            {
                "name": "Vibonati",
                "countryCode": "IT"
            },
            {
                "name": "Vicaria",
                "countryCode": "IT"
            },
            {
                "name": "Vico Equense",
                "countryCode": "IT"
            },
            {
                "name": "Vietri sul Mare",
                "countryCode": "IT"
            },
            {
                "name": "Villa Literno",
                "countryCode": "IT"
            },
            {
                "name": "Villa di Briano",
                "countryCode": "IT"
            },
            {
                "name": "Villamaina",
                "countryCode": "IT"
            },
            {
                "name": "Villammare",
                "countryCode": "IT"
            },
            {
                "name": "Villanova del Battista",
                "countryCode": "IT"
            },
            {
                "name": "Villaricca",
                "countryCode": "IT"
            },
            {
                "name": "Visciano",
                "countryCode": "IT"
            },
            {
                "name": "Vitulano",
                "countryCode": "IT"
            },
            {
                "name": "Vitulazio",
                "countryCode": "IT"
            },
            {
                "name": "Volla",
                "countryCode": "IT"
            },
            {
                "name": "Volturara Irpina",
                "countryCode": "IT"
            },
            {
                "name": "Vomero",
                "countryCode": "IT"
            },
            {
                "name": "Zona Industriale",
                "countryCode": "IT"
            },
            {
                "name": "Zungoli",
                "countryCode": "IT"
            },
            {
                "name": "Zuni",
                "countryCode": "IT"
            },
            {
                "name": "Accadia",
                "countryCode": "IT"
            },
            {
                "name": "Acquarica del Capo",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva delle Fonti",
                "countryCode": "IT"
            },
            {
                "name": "Adelfia",
                "countryCode": "IT"
            },
            {
                "name": "Alberobello",
                "countryCode": "IT"
            },
            {
                "name": "Alberona",
                "countryCode": "IT"
            },
            {
                "name": "Alessano",
                "countryCode": "IT"
            },
            {
                "name": "Alezio",
                "countryCode": "IT"
            },
            {
                "name": "Alliste",
                "countryCode": "IT"
            },
            {
                "name": "Altamura",
                "countryCode": "IT"
            },
            {
                "name": "Andrano",
                "countryCode": "IT"
            },
            {
                "name": "Andria",
                "countryCode": "IT"
            },
            {
                "name": "Anzano di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Apricena",
                "countryCode": "IT"
            },
            {
                "name": "Aradeo",
                "countryCode": "IT"
            },
            {
                "name": "Arnesano",
                "countryCode": "IT"
            },
            {
                "name": "Ascoli Satriano",
                "countryCode": "IT"
            },
            {
                "name": "Avetrana",
                "countryCode": "IT"
            },
            {
                "name": "Bagnolo del Salento",
                "countryCode": "IT"
            },
            {
                "name": "Bari",
                "countryCode": "IT"
            },
            {
                "name": "Barletta",
                "countryCode": "IT"
            },
            {
                "name": "Biccari",
                "countryCode": "IT"
            },
            {
                "name": "Binetto",
                "countryCode": "IT"
            },
            {
                "name": "Bisceglie",
                "countryCode": "IT"
            },
            {
                "name": "Bitetto",
                "countryCode": "IT"
            },
            {
                "name": "Bitonto",
                "countryCode": "IT"
            },
            {
                "name": "Bitritto",
                "countryCode": "IT"
            },
            {
                "name": "Borgagne",
                "countryCode": "IT"
            },
            {
                "name": "Botrugno",
                "countryCode": "IT"
            },
            {
                "name": "Bovino",
                "countryCode": "IT"
            },
            {
                "name": "Brindisi",
                "countryCode": "IT"
            },
            {
                "name": "Cagnano Varano",
                "countryCode": "IT"
            },
            {
                "name": "Calimera",
                "countryCode": "IT"
            },
            {
                "name": "Campi Salentina",
                "countryCode": "IT"
            },
            {
                "name": "Candela",
                "countryCode": "IT"
            },
            {
                "name": "Cannole",
                "countryCode": "IT"
            },
            {
                "name": "Canosa di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Capirro",
                "countryCode": "IT"
            },
            {
                "name": "Caprarica di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Capurso",
                "countryCode": "IT"
            },
            {
                "name": "Carapelle",
                "countryCode": "IT"
            },
            {
                "name": "Carlantino",
                "countryCode": "IT"
            },
            {
                "name": "Carmiano",
                "countryCode": "IT"
            },
            {
                "name": "Carosino",
                "countryCode": "IT"
            },
            {
                "name": "Carovigno",
                "countryCode": "IT"
            },
            {
                "name": "Carpignano Salentino",
                "countryCode": "IT"
            },
            {
                "name": "Carpino",
                "countryCode": "IT"
            },
            {
                "name": "Casalini",
                "countryCode": "IT"
            },
            {
                "name": "Casalnuovo Monterotaro",
                "countryCode": "IT"
            },
            {
                "name": "Casalvecchio di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Casamassella",
                "countryCode": "IT"
            },
            {
                "name": "Casamassima",
                "countryCode": "IT"
            },
            {
                "name": "Casarano",
                "countryCode": "IT"
            },
            {
                "name": "Cassano delle Murge",
                "countryCode": "IT"
            },
            {
                "name": "Castellana",
                "countryCode": "IT"
            },
            {
                "name": "Castellaneta",
                "countryCode": "IT"
            },
            {
                "name": "Castelluccio Valmaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Castelluccio dei Sauri",
                "countryCode": "IT"
            },
            {
                "name": "Castelnuovo della Daunia",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione",
                "countryCode": "IT"
            },
            {
                "name": "Castri di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Castrignano De' Greci",
                "countryCode": "IT"
            },
            {
                "name": "Castrignano del Capo",
                "countryCode": "IT"
            },
            {
                "name": "Castro",
                "countryCode": "IT"
            },
            {
                "name": "Castromediano",
                "countryCode": "IT"
            },
            {
                "name": "Cavallino",
                "countryCode": "IT"
            },
            {
                "name": "Ceglie Messapica",
                "countryCode": "IT"
            },
            {
                "name": "Celenza Valfortore",
                "countryCode": "IT"
            },
            {
                "name": "Cellamare",
                "countryCode": "IT"
            },
            {
                "name": "Celle di San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Cellino San Marco",
                "countryCode": "IT"
            },
            {
                "name": "Cerfignano",
                "countryCode": "IT"
            },
            {
                "name": "Cerignola",
                "countryCode": "IT"
            },
            {
                "name": "Chieuti",
                "countryCode": "IT"
            },
            {
                "name": "Cisternino",
                "countryCode": "IT"
            },
            {
                "name": "Collemeto",
                "countryCode": "IT"
            },
            {
                "name": "Collepasso",
                "countryCode": "IT"
            },
            {
                "name": "Conversano",
                "countryCode": "IT"
            },
            {
                "name": "Copertino",
                "countryCode": "IT"
            },
            {
                "name": "Corato",
                "countryCode": "IT"
            },
            {
                "name": "Corigliano d'Otranto",
                "countryCode": "IT"
            },
            {
                "name": "Corsano",
                "countryCode": "IT"
            },
            {
                "name": "Cozzana",
                "countryCode": "IT"
            },
            {
                "name": "Crispiano",
                "countryCode": "IT"
            },
            {
                "name": "Cursi",
                "countryCode": "IT"
            },
            {
                "name": "Cutrofiano",
                "countryCode": "IT"
            },
            {
                "name": "Deliceto",
                "countryCode": "IT"
            },
            {
                "name": "Depressa",
                "countryCode": "IT"
            },
            {
                "name": "Diso",
                "countryCode": "IT"
            },
            {
                "name": "Erchie",
                "countryCode": "IT"
            },
            {
                "name": "Faeto",
                "countryCode": "IT"
            },
            {
                "name": "Faggiano",
                "countryCode": "IT"
            },
            {
                "name": "Fasano",
                "countryCode": "IT"
            },
            {
                "name": "Felline",
                "countryCode": "IT"
            },
            {
                "name": "Foggia",
                "countryCode": "IT"
            },
            {
                "name": "Fragagnano",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla Fontana",
                "countryCode": "IT"
            },
            {
                "name": "Gagliano del Capo",
                "countryCode": "IT"
            },
            {
                "name": "Galatina",
                "countryCode": "IT"
            },
            {
                "name": "Galatone",
                "countryCode": "IT"
            },
            {
                "name": "Gallipoli",
                "countryCode": "IT"
            },
            {
                "name": "Galugnano",
                "countryCode": "IT"
            },
            {
                "name": "Gemini",
                "countryCode": "IT"
            },
            {
                "name": "Ginosa",
                "countryCode": "IT"
            },
            {
                "name": "Gioia del Colle",
                "countryCode": "IT"
            },
            {
                "name": "Giorgilorio",
                "countryCode": "IT"
            },
            {
                "name": "Giovinazzo",
                "countryCode": "IT"
            },
            {
                "name": "Giuggianello",
                "countryCode": "IT"
            },
            {
                "name": "Giurdignano",
                "countryCode": "IT"
            },
            {
                "name": "Gravina in Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Grottaglie",
                "countryCode": "IT"
            },
            {
                "name": "Grumo Appula",
                "countryCode": "IT"
            },
            {
                "name": "Guagnano",
                "countryCode": "IT"
            },
            {
                "name": "Ischitella",
                "countryCode": "IT"
            },
            {
                "name": "Isole Tremiti",
                "countryCode": "IT"
            },
            {
                "name": "La Rosa",
                "countryCode": "IT"
            },
            {
                "name": "Lamie",
                "countryCode": "IT"
            },
            {
                "name": "Lamie di Olimpie-Selva",
                "countryCode": "IT"
            },
            {
                "name": "Laterza",
                "countryCode": "IT"
            },
            {
                "name": "Latiano",
                "countryCode": "IT"
            },
            {
                "name": "Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Leporano",
                "countryCode": "IT"
            },
            {
                "name": "Leporano Marina",
                "countryCode": "IT"
            },
            {
                "name": "Lequile",
                "countryCode": "IT"
            },
            {
                "name": "Lesina",
                "countryCode": "IT"
            },
            {
                "name": "Leuca",
                "countryCode": "IT"
            },
            {
                "name": "Leverano",
                "countryCode": "IT"
            },
            {
                "name": "Lizzanello",
                "countryCode": "IT"
            },
            {
                "name": "Lizzano",
                "countryCode": "IT"
            },
            {
                "name": "Locorotondo",
                "countryCode": "IT"
            },
            {
                "name": "Loseto",
                "countryCode": "IT"
            },
            {
                "name": "Lucera",
                "countryCode": "IT"
            },
            {
                "name": "Lucugnano",
                "countryCode": "IT"
            },
            {
                "name": "Magliano",
                "countryCode": "IT"
            },
            {
                "name": "Maglie",
                "countryCode": "IT"
            },
            {
                "name": "Manduria",
                "countryCode": "IT"
            },
            {
                "name": "Manfredonia",
                "countryCode": "IT"
            },
            {
                "name": "Margherita di Savoia",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Ginosa",
                "countryCode": "IT"
            },
            {
                "name": "Mariotto",
                "countryCode": "IT"
            },
            {
                "name": "Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Martano",
                "countryCode": "IT"
            },
            {
                "name": "Martignano",
                "countryCode": "IT"
            },
            {
                "name": "Martina Franca",
                "countryCode": "IT"
            },
            {
                "name": "Maruggio",
                "countryCode": "IT"
            },
            {
                "name": "Massafra",
                "countryCode": "IT"
            },
            {
                "name": "Materdomini",
                "countryCode": "IT"
            },
            {
                "name": "Matino",
                "countryCode": "IT"
            },
            {
                "name": "Mattinata",
                "countryCode": "IT"
            },
            {
                "name": "Melendugno",
                "countryCode": "IT"
            },
            {
                "name": "Melissano",
                "countryCode": "IT"
            },
            {
                "name": "Melpignano",
                "countryCode": "IT"
            },
            {
                "name": "Merine",
                "countryCode": "IT"
            },
            {
                "name": "Mesagne",
                "countryCode": "IT"
            },
            {
                "name": "Miggiano",
                "countryCode": "IT"
            },
            {
                "name": "Minervino Murge",
                "countryCode": "IT"
            },
            {
                "name": "Minervino di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Modugno",
                "countryCode": "IT"
            },
            {
                "name": "Mola di Bari",
                "countryCode": "IT"
            },
            {
                "name": "Molfetta",
                "countryCode": "IT"
            },
            {
                "name": "Monopoli",
                "countryCode": "IT"
            },
            {
                "name": "Montalbano",
                "countryCode": "IT"
            },
            {
                "name": "Monte Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Monteiasi",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Montemesola",
                "countryCode": "IT"
            },
            {
                "name": "Monteparano",
                "countryCode": "IT"
            },
            {
                "name": "Monteroni di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Montesano Salentino",
                "countryCode": "IT"
            },
            {
                "name": "Montesardo",
                "countryCode": "IT"
            },
            {
                "name": "Monti d'Arena-Bosco Caggione",
                "countryCode": "IT"
            },
            {
                "name": "Morciano di Leuca",
                "countryCode": "IT"
            },
            {
                "name": "Motta Montecorvino",
                "countryCode": "IT"
            },
            {
                "name": "Mottola",
                "countryCode": "IT"
            },
            {
                "name": "Muro Leccese",
                "countryCode": "IT"
            },
            {
                "name": "Nardò",
                "countryCode": "IT"
            },
            {
                "name": "Neviano",
                "countryCode": "IT"
            },
            {
                "name": "Noci",
                "countryCode": "IT"
            },
            {
                "name": "Nociglia",
                "countryCode": "IT"
            },
            {
                "name": "Noha",
                "countryCode": "IT"
            },
            {
                "name": "Noicattaro",
                "countryCode": "IT"
            },
            {
                "name": "Novoli",
                "countryCode": "IT"
            },
            {
                "name": "Ordona",
                "countryCode": "IT"
            },
            {
                "name": "Oria",
                "countryCode": "IT"
            },
            {
                "name": "Orsara di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Orta Nova",
                "countryCode": "IT"
            },
            {
                "name": "Ortelle",
                "countryCode": "IT"
            },
            {
                "name": "Ostuni",
                "countryCode": "IT"
            },
            {
                "name": "Otranto",
                "countryCode": "IT"
            },
            {
                "name": "Palagianello",
                "countryCode": "IT"
            },
            {
                "name": "Palagiano",
                "countryCode": "IT"
            },
            {
                "name": "Palmariggi",
                "countryCode": "IT"
            },
            {
                "name": "Palo del Colle",
                "countryCode": "IT"
            },
            {
                "name": "Palombaio",
                "countryCode": "IT"
            },
            {
                "name": "Panni",
                "countryCode": "IT"
            },
            {
                "name": "Paolo VI",
                "countryCode": "IT"
            },
            {
                "name": "Parabita",
                "countryCode": "IT"
            },
            {
                "name": "Parco Scizzo-Parchitello",
                "countryCode": "IT"
            },
            {
                "name": "Patù",
                "countryCode": "IT"
            },
            {
                "name": "Peschici",
                "countryCode": "IT"
            },
            {
                "name": "Pezze di Greco",
                "countryCode": "IT"
            },
            {
                "name": "Pietramontecorvino",
                "countryCode": "IT"
            },
            {
                "name": "Pisignano",
                "countryCode": "IT"
            },
            {
                "name": "Poggiardo",
                "countryCode": "IT"
            },
            {
                "name": "Poggio Imperiale",
                "countryCode": "IT"
            },
            {
                "name": "Poggiorsini",
                "countryCode": "IT"
            },
            {
                "name": "Polignano a Mare",
                "countryCode": "IT"
            },
            {
                "name": "Porto Cesareo",
                "countryCode": "IT"
            },
            {
                "name": "Presicce",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Barletta - Andria - Trani",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Brindisi",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Foggia",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Taranto",
                "countryCode": "IT"
            },
            {
                "name": "Pulsano",
                "countryCode": "IT"
            },
            {
                "name": "Putignano",
                "countryCode": "IT"
            },
            {
                "name": "Quasano",
                "countryCode": "IT"
            },
            {
                "name": "Racale",
                "countryCode": "IT"
            },
            {
                "name": "Rignano Garganico",
                "countryCode": "IT"
            },
            {
                "name": "Roccaforzata",
                "countryCode": "IT"
            },
            {
                "name": "Rocchetta Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Rodi Garganico",
                "countryCode": "IT"
            },
            {
                "name": "Roseto Valfortore",
                "countryCode": "IT"
            },
            {
                "name": "Ruffano",
                "countryCode": "IT"
            },
            {
                "name": "Rutigliano",
                "countryCode": "IT"
            },
            {
                "name": "Ruvo di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Salice Salentino",
                "countryCode": "IT"
            },
            {
                "name": "Salve",
                "countryCode": "IT"
            },
            {
                "name": "Sammichele di Bari",
                "countryCode": "IT"
            },
            {
                "name": "San Cassiano",
                "countryCode": "IT"
            },
            {
                "name": "San Cesario di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "San Donaci",
                "countryCode": "IT"
            },
            {
                "name": "San Donato di Lecce",
                "countryCode": "IT"
            },
            {
                "name": "San Ferdinando di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Ionico",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Rotondo",
                "countryCode": "IT"
            },
            {
                "name": "San Marco in Lamis",
                "countryCode": "IT"
            },
            {
                "name": "San Marco la Catola",
                "countryCode": "IT"
            },
            {
                "name": "San Marzano di San Giuseppe",
                "countryCode": "IT"
            },
            {
                "name": "San Michele Salentino",
                "countryCode": "IT"
            },
            {
                "name": "San Pancrazio Salentino",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo di Civitate",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Vernotico",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Lama",
                "countryCode": "IT"
            },
            {
                "name": "San Severo",
                "countryCode": "IT"
            },
            {
                "name": "San Vito dei Normanni",
                "countryCode": "IT"
            },
            {
                "name": "Sanarica",
                "countryCode": "IT"
            },
            {
                "name": "Sannicandro Garganico",
                "countryCode": "IT"
            },
            {
                "name": "Sannicandro di Bari",
                "countryCode": "IT"
            },
            {
                "name": "Sannicola",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata di Puglia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cesarea Terme",
                "countryCode": "IT"
            },
            {
                "name": "Santeramo in Colle",
                "countryCode": "IT"
            },
            {
                "name": "Sava",
                "countryCode": "IT"
            },
            {
                "name": "Scorrano",
                "countryCode": "IT"
            },
            {
                "name": "Seclì",
                "countryCode": "IT"
            },
            {
                "name": "Serracapriola",
                "countryCode": "IT"
            },
            {
                "name": "Serrano",
                "countryCode": "IT"
            },
            {
                "name": "Sogliano Cavour",
                "countryCode": "IT"
            },
            {
                "name": "Soleto",
                "countryCode": "IT"
            },
            {
                "name": "Specchia",
                "countryCode": "IT"
            },
            {
                "name": "Spinazzola",
                "countryCode": "IT"
            },
            {
                "name": "Spongano",
                "countryCode": "IT"
            },
            {
                "name": "Squinzano",
                "countryCode": "IT"
            },
            {
                "name": "Statte",
                "countryCode": "IT"
            },
            {
                "name": "Sternatia",
                "countryCode": "IT"
            },
            {
                "name": "Stornara",
                "countryCode": "IT"
            },
            {
                "name": "Stornarella",
                "countryCode": "IT"
            },
            {
                "name": "Strudà",
                "countryCode": "IT"
            },
            {
                "name": "Supersano",
                "countryCode": "IT"
            },
            {
                "name": "Surano",
                "countryCode": "IT"
            },
            {
                "name": "Surbo",
                "countryCode": "IT"
            },
            {
                "name": "Taranto",
                "countryCode": "IT"
            },
            {
                "name": "Taurisano",
                "countryCode": "IT"
            },
            {
                "name": "Taviano",
                "countryCode": "IT"
            },
            {
                "name": "Terlizzi",
                "countryCode": "IT"
            },
            {
                "name": "Tiggiano",
                "countryCode": "IT"
            },
            {
                "name": "Torchiarolo",
                "countryCode": "IT"
            },
            {
                "name": "Toritto",
                "countryCode": "IT"
            },
            {
                "name": "Torre San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Torre Santa Susanna",
                "countryCode": "IT"
            },
            {
                "name": "Torremaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Torricella",
                "countryCode": "IT"
            },
            {
                "name": "Trani",
                "countryCode": "IT"
            },
            {
                "name": "Trepuzzi",
                "countryCode": "IT"
            },
            {
                "name": "Tricase",
                "countryCode": "IT"
            },
            {
                "name": "Triggiano",
                "countryCode": "IT"
            },
            {
                "name": "Trinitapoli",
                "countryCode": "IT"
            },
            {
                "name": "Troia",
                "countryCode": "IT"
            },
            {
                "name": "Tuglie",
                "countryCode": "IT"
            },
            {
                "name": "Turi",
                "countryCode": "IT"
            },
            {
                "name": "Tuturano",
                "countryCode": "IT"
            },
            {
                "name": "Ugento",
                "countryCode": "IT"
            },
            {
                "name": "Uggiano Montefusco",
                "countryCode": "IT"
            },
            {
                "name": "Uggiano la Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Valenzano",
                "countryCode": "IT"
            },
            {
                "name": "Veglie",
                "countryCode": "IT"
            },
            {
                "name": "Vernole",
                "countryCode": "IT"
            },
            {
                "name": "Vico del Gargano",
                "countryCode": "IT"
            },
            {
                "name": "Vieste",
                "countryCode": "IT"
            },
            {
                "name": "Vignacastrisi",
                "countryCode": "IT"
            },
            {
                "name": "Villa Castelli",
                "countryCode": "IT"
            },
            {
                "name": "Vitigliano",
                "countryCode": "IT"
            },
            {
                "name": "Volturara Appula",
                "countryCode": "IT"
            },
            {
                "name": "Volturino",
                "countryCode": "IT"
            },
            {
                "name": "Zapponeta",
                "countryCode": "IT"
            },
            {
                "name": "Zollino",
                "countryCode": "IT"
            },
            {
                "name": "Abriola",
                "countryCode": "IT"
            },
            {
                "name": "Accettura",
                "countryCode": "IT"
            },
            {
                "name": "Acerenza",
                "countryCode": "IT"
            },
            {
                "name": "Albano di Lucania",
                "countryCode": "IT"
            },
            {
                "name": "Aliano",
                "countryCode": "IT"
            },
            {
                "name": "Anzi",
                "countryCode": "IT"
            },
            {
                "name": "Armento",
                "countryCode": "IT"
            },
            {
                "name": "Atella",
                "countryCode": "IT"
            },
            {
                "name": "Avigliano",
                "countryCode": "IT"
            },
            {
                "name": "Balvano",
                "countryCode": "IT"
            },
            {
                "name": "Banzi",
                "countryCode": "IT"
            },
            {
                "name": "Baragiano",
                "countryCode": "IT"
            },
            {
                "name": "Barile",
                "countryCode": "IT"
            },
            {
                "name": "Bella",
                "countryCode": "IT"
            },
            {
                "name": "Bernalda",
                "countryCode": "IT"
            },
            {
                "name": "Brienza",
                "countryCode": "IT"
            },
            {
                "name": "Brindisi Montagna",
                "countryCode": "IT"
            },
            {
                "name": "Calciano",
                "countryCode": "IT"
            },
            {
                "name": "Calvello",
                "countryCode": "IT"
            },
            {
                "name": "Calvera",
                "countryCode": "IT"
            },
            {
                "name": "Campomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Cancellara",
                "countryCode": "IT"
            },
            {
                "name": "Carbone",
                "countryCode": "IT"
            },
            {
                "name": "Castelgrande",
                "countryCode": "IT"
            },
            {
                "name": "Castelluccio Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Castelluccio Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Castelmezzano",
                "countryCode": "IT"
            },
            {
                "name": "Castelsaraceno",
                "countryCode": "IT"
            },
            {
                "name": "Castronuovo di Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Cersosimo",
                "countryCode": "IT"
            },
            {
                "name": "Chiaromonte",
                "countryCode": "IT"
            },
            {
                "name": "Cirigliano",
                "countryCode": "IT"
            },
            {
                "name": "Colobraro",
                "countryCode": "IT"
            },
            {
                "name": "Corleto Perticara",
                "countryCode": "IT"
            },
            {
                "name": "Craco-Sant'Angelo",
                "countryCode": "IT"
            },
            {
                "name": "Episcopia",
                "countryCode": "IT"
            },
            {
                "name": "Fardella",
                "countryCode": "IT"
            },
            {
                "name": "Ferrandina",
                "countryCode": "IT"
            },
            {
                "name": "Filiano",
                "countryCode": "IT"
            },
            {
                "name": "Forenza",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla in Sinni",
                "countryCode": "IT"
            },
            {
                "name": "Gallicchio",
                "countryCode": "IT"
            },
            {
                "name": "Garaguso",
                "countryCode": "IT"
            },
            {
                "name": "Genzano di Lucania",
                "countryCode": "IT"
            },
            {
                "name": "Ginestra",
                "countryCode": "IT"
            },
            {
                "name": "Gorgoglione",
                "countryCode": "IT"
            },
            {
                "name": "Grassano",
                "countryCode": "IT"
            },
            {
                "name": "Grottole",
                "countryCode": "IT"
            },
            {
                "name": "Grumento Nova",
                "countryCode": "IT"
            },
            {
                "name": "Guardia Perticara",
                "countryCode": "IT"
            },
            {
                "name": "Irsina",
                "countryCode": "IT"
            },
            {
                "name": "La Martella",
                "countryCode": "IT"
            },
            {
                "name": "Lagonegro",
                "countryCode": "IT"
            },
            {
                "name": "Latronico",
                "countryCode": "IT"
            },
            {
                "name": "Laurenzana",
                "countryCode": "IT"
            },
            {
                "name": "Lauria",
                "countryCode": "IT"
            },
            {
                "name": "Lavello",
                "countryCode": "IT"
            },
            {
                "name": "Lido",
                "countryCode": "IT"
            },
            {
                "name": "Maratea",
                "countryCode": "IT"
            },
            {
                "name": "Marconia",
                "countryCode": "IT"
            },
            {
                "name": "Marsico Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Marsicovetere",
                "countryCode": "IT"
            },
            {
                "name": "Maschito",
                "countryCode": "IT"
            },
            {
                "name": "Matera",
                "countryCode": "IT"
            },
            {
                "name": "Melfi",
                "countryCode": "IT"
            },
            {
                "name": "Miglionico",
                "countryCode": "IT"
            },
            {
                "name": "Missanello",
                "countryCode": "IT"
            },
            {
                "name": "Moliterno",
                "countryCode": "IT"
            },
            {
                "name": "Montalbano Jonico",
                "countryCode": "IT"
            },
            {
                "name": "Montemilone",
                "countryCode": "IT"
            },
            {
                "name": "Montemurro",
                "countryCode": "IT"
            },
            {
                "name": "Montescaglioso",
                "countryCode": "IT"
            },
            {
                "name": "Muro Lucano",
                "countryCode": "IT"
            },
            {
                "name": "Nemoli",
                "countryCode": "IT"
            },
            {
                "name": "Noepoli",
                "countryCode": "IT"
            },
            {
                "name": "Nova Siri",
                "countryCode": "IT"
            },
            {
                "name": "Nova Siri Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Oliveto Lucano",
                "countryCode": "IT"
            },
            {
                "name": "Oppido Lucano",
                "countryCode": "IT"
            },
            {
                "name": "Palazzo San Gervasio",
                "countryCode": "IT"
            },
            {
                "name": "Paterno",
                "countryCode": "IT"
            },
            {
                "name": "Pescopagano",
                "countryCode": "IT"
            },
            {
                "name": "Picerno",
                "countryCode": "IT"
            },
            {
                "name": "Pietragalla",
                "countryCode": "IT"
            },
            {
                "name": "Pietrapertosa",
                "countryCode": "IT"
            },
            {
                "name": "Pignola",
                "countryCode": "IT"
            },
            {
                "name": "Pisticci",
                "countryCode": "IT"
            },
            {
                "name": "Policoro",
                "countryCode": "IT"
            },
            {
                "name": "Pomarico",
                "countryCode": "IT"
            },
            {
                "name": "Potenza",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Matera",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Potenza",
                "countryCode": "IT"
            },
            {
                "name": "Rapolla",
                "countryCode": "IT"
            },
            {
                "name": "Rapone",
                "countryCode": "IT"
            },
            {
                "name": "Rionero in Vulture",
                "countryCode": "IT"
            },
            {
                "name": "Ripacandida",
                "countryCode": "IT"
            },
            {
                "name": "Rivello",
                "countryCode": "IT"
            },
            {
                "name": "Roccanova",
                "countryCode": "IT"
            },
            {
                "name": "Rotonda",
                "countryCode": "IT"
            },
            {
                "name": "Rotondella",
                "countryCode": "IT"
            },
            {
                "name": "Ruoti",
                "countryCode": "IT"
            },
            {
                "name": "Ruvo del Monte",
                "countryCode": "IT"
            },
            {
                "name": "Salandra",
                "countryCode": "IT"
            },
            {
                "name": "San Brancato",
                "countryCode": "IT"
            },
            {
                "name": "San Chirico Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "San Chirico Raparo",
                "countryCode": "IT"
            },
            {
                "name": "San Costantino Albanese",
                "countryCode": "IT"
            },
            {
                "name": "San Fele",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Lucano",
                "countryCode": "IT"
            },
            {
                "name": "San Martino d'Agri",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Forte",
                "countryCode": "IT"
            },
            {
                "name": "San Paolo Albanese",
                "countryCode": "IT"
            },
            {
                "name": "San Severino Lucano",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo le Fratte",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Arcangelo",
                "countryCode": "IT"
            },
            {
                "name": "Sarconi",
                "countryCode": "IT"
            },
            {
                "name": "Sasso di Castalda",
                "countryCode": "IT"
            },
            {
                "name": "Satriano di Lucania",
                "countryCode": "IT"
            },
            {
                "name": "Savoia di Lucania",
                "countryCode": "IT"
            },
            {
                "name": "Scalo di Baragiano",
                "countryCode": "IT"
            },
            {
                "name": "Scanzano Jonico",
                "countryCode": "IT"
            },
            {
                "name": "Senise",
                "countryCode": "IT"
            },
            {
                "name": "Spinoso",
                "countryCode": "IT"
            },
            {
                "name": "Stigliano",
                "countryCode": "IT"
            },
            {
                "name": "Teana",
                "countryCode": "IT"
            },
            {
                "name": "Terranova di Pollino",
                "countryCode": "IT"
            },
            {
                "name": "Tito",
                "countryCode": "IT"
            },
            {
                "name": "Tolve",
                "countryCode": "IT"
            },
            {
                "name": "Tramutola",
                "countryCode": "IT"
            },
            {
                "name": "Trecchina",
                "countryCode": "IT"
            },
            {
                "name": "Tricarico",
                "countryCode": "IT"
            },
            {
                "name": "Trivigno",
                "countryCode": "IT"
            },
            {
                "name": "Tursi",
                "countryCode": "IT"
            },
            {
                "name": "Vaglio Basilicata",
                "countryCode": "IT"
            },
            {
                "name": "Valsinni",
                "countryCode": "IT"
            },
            {
                "name": "Venosa",
                "countryCode": "IT"
            },
            {
                "name": "Vietri di Potenza",
                "countryCode": "IT"
            },
            {
                "name": "Viggianello",
                "countryCode": "IT"
            },
            {
                "name": "Viggiano",
                "countryCode": "IT"
            },
            {
                "name": "Villa d'Agri",
                "countryCode": "IT"
            },
            {
                "name": "Zona 179",
                "countryCode": "IT"
            },
            {
                "name": "Acconia",
                "countryCode": "IT"
            },
            {
                "name": "Acquaformosa",
                "countryCode": "IT"
            },
            {
                "name": "Acquappesa",
                "countryCode": "IT"
            },
            {
                "name": "Acquaro",
                "countryCode": "IT"
            },
            {
                "name": "Acri",
                "countryCode": "IT"
            },
            {
                "name": "Africo Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Africo Vecchio",
                "countryCode": "IT"
            },
            {
                "name": "Agnana Calabra",
                "countryCode": "IT"
            },
            {
                "name": "Aiello Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Aieta",
                "countryCode": "IT"
            },
            {
                "name": "Albi",
                "countryCode": "IT"
            },
            {
                "name": "Albidona",
                "countryCode": "IT"
            },
            {
                "name": "Alessandria del Carretto",
                "countryCode": "IT"
            },
            {
                "name": "Altilia",
                "countryCode": "IT"
            },
            {
                "name": "Altomonte",
                "countryCode": "IT"
            },
            {
                "name": "Amantea",
                "countryCode": "IT"
            },
            {
                "name": "Amaroni",
                "countryCode": "IT"
            },
            {
                "name": "Amato",
                "countryCode": "IT"
            },
            {
                "name": "Amendolara",
                "countryCode": "IT"
            },
            {
                "name": "Amica",
                "countryCode": "IT"
            },
            {
                "name": "Andali",
                "countryCode": "IT"
            },
            {
                "name": "Andreotta",
                "countryCode": "IT"
            },
            {
                "name": "Anoia Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Anoia Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Antonimina",
                "countryCode": "IT"
            },
            {
                "name": "Aprigliano",
                "countryCode": "IT"
            },
            {
                "name": "Arcavacata",
                "countryCode": "IT"
            },
            {
                "name": "Ardore",
                "countryCode": "IT"
            },
            {
                "name": "Ardore Marina",
                "countryCode": "IT"
            },
            {
                "name": "Arena",
                "countryCode": "IT"
            },
            {
                "name": "Argusto",
                "countryCode": "IT"
            },
            {
                "name": "Badolato",
                "countryCode": "IT"
            },
            {
                "name": "Badolato Marina",
                "countryCode": "IT"
            },
            {
                "name": "Bagaladi",
                "countryCode": "IT"
            },
            {
                "name": "Bagnara Calabra",
                "countryCode": "IT"
            },
            {
                "name": "Barone",
                "countryCode": "IT"
            },
            {
                "name": "Belcastro",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Belsito",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere Marittimo",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere Spinello",
                "countryCode": "IT"
            },
            {
                "name": "Benestare",
                "countryCode": "IT"
            },
            {
                "name": "Bianchi",
                "countryCode": "IT"
            },
            {
                "name": "Bianco",
                "countryCode": "IT"
            },
            {
                "name": "Bisignano",
                "countryCode": "IT"
            },
            {
                "name": "Bivongi",
                "countryCode": "IT"
            },
            {
                "name": "Bocchigliero",
                "countryCode": "IT"
            },
            {
                "name": "Bonifati",
                "countryCode": "IT"
            },
            {
                "name": "Borgata Marina",
                "countryCode": "IT"
            },
            {
                "name": "Borgia",
                "countryCode": "IT"
            },
            {
                "name": "Botricello",
                "countryCode": "IT"
            },
            {
                "name": "Bova",
                "countryCode": "IT"
            },
            {
                "name": "Bova Marina",
                "countryCode": "IT"
            },
            {
                "name": "Bovalino",
                "countryCode": "IT"
            },
            {
                "name": "Bovalino Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Brancaleone",
                "countryCode": "IT"
            },
            {
                "name": "Brancaleone-Marina",
                "countryCode": "IT"
            },
            {
                "name": "Briatico",
                "countryCode": "IT"
            },
            {
                "name": "Brognaturo",
                "countryCode": "IT"
            },
            {
                "name": "Bruzzano Zeffirio",
                "countryCode": "IT"
            },
            {
                "name": "Buonvicino",
                "countryCode": "IT"
            },
            {
                "name": "Caccuri",
                "countryCode": "IT"
            },
            {
                "name": "Calanna",
                "countryCode": "IT"
            },
            {
                "name": "Caloppezzati",
                "countryCode": "IT"
            },
            {
                "name": "Caloveto",
                "countryCode": "IT"
            },
            {
                "name": "Calvisi",
                "countryCode": "IT"
            },
            {
                "name": "Camini",
                "countryCode": "IT"
            },
            {
                "name": "Campana",
                "countryCode": "IT"
            },
            {
                "name": "Campo Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Campora San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Candidoni",
                "countryCode": "IT"
            },
            {
                "name": "Canna",
                "countryCode": "IT"
            },
            {
                "name": "Canolo",
                "countryCode": "IT"
            },
            {
                "name": "Cantinella",
                "countryCode": "IT"
            },
            {
                "name": "Capistrano",
                "countryCode": "IT"
            },
            {
                "name": "Capo Rizzuto",
                "countryCode": "IT"
            },
            {
                "name": "Caraffa del Bianco",
                "countryCode": "IT"
            },
            {
                "name": "Caraffa di Catanzaro",
                "countryCode": "IT"
            },
            {
                "name": "Cardeto",
                "countryCode": "IT"
            },
            {
                "name": "Cardinale",
                "countryCode": "IT"
            },
            {
                "name": "Careri",
                "countryCode": "IT"
            },
            {
                "name": "Carfizzi",
                "countryCode": "IT"
            },
            {
                "name": "Cariati",
                "countryCode": "IT"
            },
            {
                "name": "Carlopoli",
                "countryCode": "IT"
            },
            {
                "name": "Carolei",
                "countryCode": "IT"
            },
            {
                "name": "Carpanzano",
                "countryCode": "IT"
            },
            {
                "name": "Casabona",
                "countryCode": "IT"
            },
            {
                "name": "Casignana",
                "countryCode": "IT"
            },
            {
                "name": "Casole Bruzio",
                "countryCode": "IT"
            },
            {
                "name": "Cassano Allo Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Castelsilano",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione Cosentino",
                "countryCode": "IT"
            },
            {
                "name": "Castrolibero",
                "countryCode": "IT"
            },
            {
                "name": "Castroregio",
                "countryCode": "IT"
            },
            {
                "name": "Castrovillari",
                "countryCode": "IT"
            },
            {
                "name": "Catanzaro",
                "countryCode": "IT"
            },
            {
                "name": "Caulonia",
                "countryCode": "IT"
            },
            {
                "name": "Caulonia Marina",
                "countryCode": "IT"
            },
            {
                "name": "Cava-Cuculera Nobile",
                "countryCode": "IT"
            },
            {
                "name": "Cavoni-Ginestreto",
                "countryCode": "IT"
            },
            {
                "name": "Celico",
                "countryCode": "IT"
            },
            {
                "name": "Cellara",
                "countryCode": "IT"
            },
            {
                "name": "Cenadi",
                "countryCode": "IT"
            },
            {
                "name": "Centrache",
                "countryCode": "IT"
            },
            {
                "name": "Ceramida-Pellegrina",
                "countryCode": "IT"
            },
            {
                "name": "Cerchiara di Calabria",
                "countryCode": "IT"
            },
            {
                "name": "Cerenzia",
                "countryCode": "IT"
            },
            {
                "name": "Cerisano",
                "countryCode": "IT"
            },
            {
                "name": "Cerva",
                "countryCode": "IT"
            },
            {
                "name": "Cervicati",
                "countryCode": "IT"
            },
            {
                "name": "Cerzeto",
                "countryCode": "IT"
            },
            {
                "name": "Cessaniti",
                "countryCode": "IT"
            },
            {
                "name": "Cetraro",
                "countryCode": "IT"
            },
            {
                "name": "Cetraro Marina",
                "countryCode": "IT"
            },
            {
                "name": "Chiaravalle Centrale",
                "countryCode": "IT"
            },
            {
                "name": "Cicala",
                "countryCode": "IT"
            },
            {
                "name": "Ciminà",
                "countryCode": "IT"
            },
            {
                "name": "Cinque Frondi",
                "countryCode": "IT"
            },
            {
                "name": "Cirò",
                "countryCode": "IT"
            },
            {
                "name": "Cirò Marina",
                "countryCode": "IT"
            },
            {
                "name": "Cittadella del Capo",
                "countryCode": "IT"
            },
            {
                "name": "Cittanova",
                "countryCode": "IT"
            },
            {
                "name": "Civita",
                "countryCode": "IT"
            },
            {
                "name": "Cleto",
                "countryCode": "IT"
            },
            {
                "name": "Colosimi",
                "countryCode": "IT"
            },
            {
                "name": "Condofuri",
                "countryCode": "IT"
            },
            {
                "name": "Conflenti",
                "countryCode": "IT"
            },
            {
                "name": "Corigliano Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Corigliano Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Cortale",
                "countryCode": "IT"
            },
            {
                "name": "Cosenza",
                "countryCode": "IT"
            },
            {
                "name": "Cosoleto",
                "countryCode": "IT"
            },
            {
                "name": "Cotronei",
                "countryCode": "IT"
            },
            {
                "name": "Crichi",
                "countryCode": "IT"
            },
            {
                "name": "Cropalati",
                "countryCode": "IT"
            },
            {
                "name": "Cropani",
                "countryCode": "IT"
            },
            {
                "name": "Cropani Marina",
                "countryCode": "IT"
            },
            {
                "name": "Crosia",
                "countryCode": "IT"
            },
            {
                "name": "Crotone",
                "countryCode": "IT"
            },
            {
                "name": "Crucoli",
                "countryCode": "IT"
            },
            {
                "name": "Curinga",
                "countryCode": "IT"
            },
            {
                "name": "Cutro",
                "countryCode": "IT"
            },
            {
                "name": "Dasà",
                "countryCode": "IT"
            },
            {
                "name": "Davoli",
                "countryCode": "IT"
            },
            {
                "name": "Decollatura",
                "countryCode": "IT"
            },
            {
                "name": "Delianuova",
                "countryCode": "IT"
            },
            {
                "name": "Diamante",
                "countryCode": "IT"
            },
            {
                "name": "Dinami",
                "countryCode": "IT"
            },
            {
                "name": "Dipignano",
                "countryCode": "IT"
            },
            {
                "name": "Domanico",
                "countryCode": "IT"
            },
            {
                "name": "Donnici Inferiore",
                "countryCode": "IT"
            },
            {
                "name": "Doria",
                "countryCode": "IT"
            },
            {
                "name": "Drapia",
                "countryCode": "IT"
            },
            {
                "name": "Fabrizia",
                "countryCode": "IT"
            },
            {
                "name": "Fabrizio",
                "countryCode": "IT"
            },
            {
                "name": "Fagnano Castello",
                "countryCode": "IT"
            },
            {
                "name": "Falconara Albanese",
                "countryCode": "IT"
            },
            {
                "name": "Falerna",
                "countryCode": "IT"
            },
            {
                "name": "Falerna Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Feroleto Antico",
                "countryCode": "IT"
            },
            {
                "name": "Feroleto della Chiesa",
                "countryCode": "IT"
            },
            {
                "name": "Ferruzzano",
                "countryCode": "IT"
            },
            {
                "name": "Figline Vegliaturo",
                "countryCode": "IT"
            },
            {
                "name": "Filadelfia",
                "countryCode": "IT"
            },
            {
                "name": "Filandari",
                "countryCode": "IT"
            },
            {
                "name": "Filogaso",
                "countryCode": "IT"
            },
            {
                "name": "Firmo",
                "countryCode": "IT"
            },
            {
                "name": "Fiumara",
                "countryCode": "IT"
            },
            {
                "name": "Fiumefreddo Bruzio",
                "countryCode": "IT"
            },
            {
                "name": "Foresta",
                "countryCode": "IT"
            },
            {
                "name": "Fossato Ionico-Fossatello-San Luca Marcelluzzo",
                "countryCode": "IT"
            },
            {
                "name": "Fossato Serralta",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla Angitola",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Francica",
                "countryCode": "IT"
            },
            {
                "name": "Frascineto",
                "countryCode": "IT"
            },
            {
                "name": "Fuscaldo",
                "countryCode": "IT"
            },
            {
                "name": "Gagliato",
                "countryCode": "IT"
            },
            {
                "name": "Galatro",
                "countryCode": "IT"
            },
            {
                "name": "Gasperina",
                "countryCode": "IT"
            },
            {
                "name": "Gerace",
                "countryCode": "IT"
            },
            {
                "name": "Gerocarne",
                "countryCode": "IT"
            },
            {
                "name": "Giffone",
                "countryCode": "IT"
            },
            {
                "name": "Gimigliano",
                "countryCode": "IT"
            },
            {
                "name": "Gioia Tauro",
                "countryCode": "IT"
            },
            {
                "name": "Gioiosa Ionica",
                "countryCode": "IT"
            },
            {
                "name": "Girifalco",
                "countryCode": "IT"
            },
            {
                "name": "Gizzeria",
                "countryCode": "IT"
            },
            {
                "name": "Grimaldi",
                "countryCode": "IT"
            },
            {
                "name": "Grisolia",
                "countryCode": "IT"
            },
            {
                "name": "Grotteria",
                "countryCode": "IT"
            },
            {
                "name": "Guardavalle",
                "countryCode": "IT"
            },
            {
                "name": "Guardavalle Marina",
                "countryCode": "IT"
            },
            {
                "name": "Guardia Piemontese",
                "countryCode": "IT"
            },
            {
                "name": "Isca Marina",
                "countryCode": "IT"
            },
            {
                "name": "Isca sullo Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Isola di Capo Rizzuto",
                "countryCode": "IT"
            },
            {
                "name": "Jacurso",
                "countryCode": "IT"
            },
            {
                "name": "Jonadi",
                "countryCode": "IT"
            },
            {
                "name": "Joppolo",
                "countryCode": "IT"
            },
            {
                "name": "Laganadi",
                "countryCode": "IT"
            },
            {
                "name": "Lago",
                "countryCode": "IT"
            },
            {
                "name": "Laino Borgo",
                "countryCode": "IT"
            },
            {
                "name": "Laino Castello-Nuovo Centro",
                "countryCode": "IT"
            },
            {
                "name": "Lamezia Terme",
                "countryCode": "IT"
            },
            {
                "name": "Lappano",
                "countryCode": "IT"
            },
            {
                "name": "Lattarico",
                "countryCode": "IT"
            },
            {
                "name": "Laureana di Borrello",
                "countryCode": "IT"
            },
            {
                "name": "Laurignano",
                "countryCode": "IT"
            },
            {
                "name": "Lazzaro",
                "countryCode": "IT"
            },
            {
                "name": "Le Castella",
                "countryCode": "IT"
            },
            {
                "name": "Limbadi-Caroni",
                "countryCode": "IT"
            },
            {
                "name": "Locri",
                "countryCode": "IT"
            },
            {
                "name": "Longobardi",
                "countryCode": "IT"
            },
            {
                "name": "Longobucco",
                "countryCode": "IT"
            },
            {
                "name": "Lungro",
                "countryCode": "IT"
            },
            {
                "name": "Luzzi",
                "countryCode": "IT"
            },
            {
                "name": "Magisano",
                "countryCode": "IT"
            },
            {
                "name": "Maida",
                "countryCode": "IT"
            },
            {
                "name": "Maierato",
                "countryCode": "IT"
            },
            {
                "name": "Maierà",
                "countryCode": "IT"
            },
            {
                "name": "Malito",
                "countryCode": "IT"
            },
            {
                "name": "Malvito",
                "countryCode": "IT"
            },
            {
                "name": "Mammola",
                "countryCode": "IT"
            },
            {
                "name": "Mandatoriccio",
                "countryCode": "IT"
            },
            {
                "name": "Mangone",
                "countryCode": "IT"
            },
            {
                "name": "Marano Marchesato",
                "countryCode": "IT"
            },
            {
                "name": "Marano Principato",
                "countryCode": "IT"
            },
            {
                "name": "Marcedusa",
                "countryCode": "IT"
            },
            {
                "name": "Marcellina",
                "countryCode": "IT"
            },
            {
                "name": "Marcellinara",
                "countryCode": "IT"
            },
            {
                "name": "Marina",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Davoli",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Fuscaldo",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Gioiosa Ionica",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Schiavonea",
                "countryCode": "IT"
            },
            {
                "name": "Maropati",
                "countryCode": "IT"
            },
            {
                "name": "Martelli-Laganosa",
                "countryCode": "IT"
            },
            {
                "name": "Martirano",
                "countryCode": "IT"
            },
            {
                "name": "Martirano Lombardo",
                "countryCode": "IT"
            },
            {
                "name": "Martone",
                "countryCode": "IT"
            },
            {
                "name": "Marzi",
                "countryCode": "IT"
            },
            {
                "name": "Melicucco",
                "countryCode": "IT"
            },
            {
                "name": "Melicuccà",
                "countryCode": "IT"
            },
            {
                "name": "Melissa",
                "countryCode": "IT"
            },
            {
                "name": "Melito di Porto Salvo",
                "countryCode": "IT"
            },
            {
                "name": "Mendicino",
                "countryCode": "IT"
            },
            {
                "name": "Mesoraca",
                "countryCode": "IT"
            },
            {
                "name": "Messignadi",
                "countryCode": "IT"
            },
            {
                "name": "Miglierina",
                "countryCode": "IT"
            },
            {
                "name": "Mileto",
                "countryCode": "IT"
            },
            {
                "name": "Mirto",
                "countryCode": "IT"
            },
            {
                "name": "Molochio",
                "countryCode": "IT"
            },
            {
                "name": "Monasterace",
                "countryCode": "IT"
            },
            {
                "name": "Monasterace Marina",
                "countryCode": "IT"
            },
            {
                "name": "Mongiana",
                "countryCode": "IT"
            },
            {
                "name": "Mongrassano",
                "countryCode": "IT"
            },
            {
                "name": "Montalto Uffugo",
                "countryCode": "IT"
            },
            {
                "name": "Montauro",
                "countryCode": "IT"
            },
            {
                "name": "Montebello Jonico",
                "countryCode": "IT"
            },
            {
                "name": "Montegiordano",
                "countryCode": "IT"
            },
            {
                "name": "Montepaone",
                "countryCode": "IT"
            },
            {
                "name": "Montepaone Lido",
                "countryCode": "IT"
            },
            {
                "name": "Monterosso Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Morano Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Mormanno",
                "countryCode": "IT"
            },
            {
                "name": "Mosorrofa",
                "countryCode": "IT"
            },
            {
                "name": "Motta San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Motta Santa Lucia",
                "countryCode": "IT"
            },
            {
                "name": "Mottafollone",
                "countryCode": "IT"
            },
            {
                "name": "Nardodipace",
                "countryCode": "IT"
            },
            {
                "name": "Natile Nuovo",
                "countryCode": "IT"
            },
            {
                "name": "Nicastro",
                "countryCode": "IT"
            },
            {
                "name": "Nicotera",
                "countryCode": "IT"
            },
            {
                "name": "Nocara",
                "countryCode": "IT"
            },
            {
                "name": "Nocera Scalo",
                "countryCode": "IT"
            },
            {
                "name": "Nocera Terinese",
                "countryCode": "IT"
            },
            {
                "name": "Olivadi",
                "countryCode": "IT"
            },
            {
                "name": "Oppido Mamertina",
                "countryCode": "IT"
            },
            {
                "name": "Oriolo",
                "countryCode": "IT"
            },
            {
                "name": "Orsomarso",
                "countryCode": "IT"
            },
            {
                "name": "Pagliarelle",
                "countryCode": "IT"
            },
            {
                "name": "Palermiti",
                "countryCode": "IT"
            },
            {
                "name": "Palizzi",
                "countryCode": "IT"
            },
            {
                "name": "Palizzi Marina",
                "countryCode": "IT"
            },
            {
                "name": "Pallagorio",
                "countryCode": "IT"
            },
            {
                "name": "Palmi",
                "countryCode": "IT"
            },
            {
                "name": "Paludi",
                "countryCode": "IT"
            },
            {
                "name": "Panettieri",
                "countryCode": "IT"
            },
            {
                "name": "Pannaconi",
                "countryCode": "IT"
            },
            {
                "name": "Paola",
                "countryCode": "IT"
            },
            {
                "name": "Papanice",
                "countryCode": "IT"
            },
            {
                "name": "Papasidero",
                "countryCode": "IT"
            },
            {
                "name": "Paravati",
                "countryCode": "IT"
            },
            {
                "name": "Parenti",
                "countryCode": "IT"
            },
            {
                "name": "Parghelia",
                "countryCode": "IT"
            },
            {
                "name": "Paterno Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Pavigliana",
                "countryCode": "IT"
            },
            {
                "name": "Pazzano",
                "countryCode": "IT"
            },
            {
                "name": "Pedace-Perito",
                "countryCode": "IT"
            },
            {
                "name": "Pedivigliano",
                "countryCode": "IT"
            },
            {
                "name": "Pellaro",
                "countryCode": "IT"
            },
            {
                "name": "Pentone",
                "countryCode": "IT"
            },
            {
                "name": "Pernocari-Presinaci",
                "countryCode": "IT"
            },
            {
                "name": "Petilia Policastro",
                "countryCode": "IT"
            },
            {
                "name": "Petrizzi",
                "countryCode": "IT"
            },
            {
                "name": "Petronà",
                "countryCode": "IT"
            },
            {
                "name": "Piane Crati",
                "countryCode": "IT"
            },
            {
                "name": "Pianopoli",
                "countryCode": "IT"
            },
            {
                "name": "Pietrafitta",
                "countryCode": "IT"
            },
            {
                "name": "Pietrapaola",
                "countryCode": "IT"
            },
            {
                "name": "Piscopio",
                "countryCode": "IT"
            },
            {
                "name": "Pizzo",
                "countryCode": "IT"
            },
            {
                "name": "Pizzoni",
                "countryCode": "IT"
            },
            {
                "name": "Placanica",
                "countryCode": "IT"
            },
            {
                "name": "Plataci",
                "countryCode": "IT"
            },
            {
                "name": "Platania",
                "countryCode": "IT"
            },
            {
                "name": "Platì",
                "countryCode": "IT"
            },
            {
                "name": "Polia",
                "countryCode": "IT"
            },
            {
                "name": "Polistena",
                "countryCode": "IT"
            },
            {
                "name": "Portigliola",
                "countryCode": "IT"
            },
            {
                "name": "Praia a Mare",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Catanzaro",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Cosenza",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Crotone",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Reggio Calabria",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Vibo-Valentia",
                "countryCode": "IT"
            },
            {
                "name": "Quattromiglia",
                "countryCode": "IT"
            },
            {
                "name": "Reggio Calabria",
                "countryCode": "IT"
            },
            {
                "name": "Rende",
                "countryCode": "IT"
            },
            {
                "name": "Riace",
                "countryCode": "IT"
            },
            {
                "name": "Riace Marina",
                "countryCode": "IT"
            },
            {
                "name": "Ricadi",
                "countryCode": "IT"
            },
            {
                "name": "Rizziconi",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Imperiale",
                "countryCode": "IT"
            },
            {
                "name": "Rocca Imperiale Marina",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Neto",
                "countryCode": "IT"
            },
            {
                "name": "Roccabernarda",
                "countryCode": "IT"
            },
            {
                "name": "Roccaforte del Greco",
                "countryCode": "IT"
            },
            {
                "name": "Roccella Ionica",
                "countryCode": "IT"
            },
            {
                "name": "Roccelletta",
                "countryCode": "IT"
            },
            {
                "name": "Roggiano Gravina",
                "countryCode": "IT"
            },
            {
                "name": "Roghudi",
                "countryCode": "IT"
            },
            {
                "name": "Rogliano",
                "countryCode": "IT"
            },
            {
                "name": "Rombiolo",
                "countryCode": "IT"
            },
            {
                "name": "Rosario",
                "countryCode": "IT"
            },
            {
                "name": "Rosarno",
                "countryCode": "IT"
            },
            {
                "name": "Rose",
                "countryCode": "IT"
            },
            {
                "name": "Roseto Capo Spulico",
                "countryCode": "IT"
            },
            {
                "name": "Rossano",
                "countryCode": "IT"
            },
            {
                "name": "Rossano Stazione",
                "countryCode": "IT"
            },
            {
                "name": "Rota Greca",
                "countryCode": "IT"
            },
            {
                "name": "Rovito",
                "countryCode": "IT"
            },
            {
                "name": "Saline Ioniche",
                "countryCode": "IT"
            },
            {
                "name": "Sambiase",
                "countryCode": "IT"
            },
            {
                "name": "Samo",
                "countryCode": "IT"
            },
            {
                "name": "San Basile",
                "countryCode": "IT"
            },
            {
                "name": "San Benedetto Ullano",
                "countryCode": "IT"
            },
            {
                "name": "San Calogero",
                "countryCode": "IT"
            },
            {
                "name": "San Carlo-Condofuri Marina",
                "countryCode": "IT"
            },
            {
                "name": "San Cosmo Albanese",
                "countryCode": "IT"
            },
            {
                "name": "San Costantino Calabro",
                "countryCode": "IT"
            },
            {
                "name": "San Demetrio Corone",
                "countryCode": "IT"
            },
            {
                "name": "San Donato di Ninea",
                "countryCode": "IT"
            },
            {
                "name": "San Ferdinando",
                "countryCode": "IT"
            },
            {
                "name": "San Fili",
                "countryCode": "IT"
            },
            {
                "name": "San Floro",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Albanese",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio Morgeto",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni di Gerace",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni in Fiore",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio d'Ippona",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo Bellizzi",
                "countryCode": "IT"
            },
            {
                "name": "San Lorenzo del Vallo",
                "countryCode": "IT"
            },
            {
                "name": "San Luca",
                "countryCode": "IT"
            },
            {
                "name": "San Lucido",
                "countryCode": "IT"
            },
            {
                "name": "San Mango d'Aquino",
                "countryCode": "IT"
            },
            {
                "name": "San Marco Argentano",
                "countryCode": "IT"
            },
            {
                "name": "San Martino",
                "countryCode": "IT"
            },
            {
                "name": "San Martino di Finita",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Marchesato",
                "countryCode": "IT"
            },
            {
                "name": "San Nico",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola Arcella",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola da Crissa",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola dell'Alto",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Apostolo",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro a Maida",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro di Caridà",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Amantea",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro in Guarano",
                "countryCode": "IT"
            },
            {
                "name": "San Procopio",
                "countryCode": "IT"
            },
            {
                "name": "San Roberto",
                "countryCode": "IT"
            },
            {
                "name": "San Sostene",
                "countryCode": "IT"
            },
            {
                "name": "San Sosti",
                "countryCode": "IT"
            },
            {
                "name": "San Vincenzo la Costa",
                "countryCode": "IT"
            },
            {
                "name": "San Vito Sullo Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Sangineto",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata del Bianco",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata di Esaro",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alessio in Aspromonte",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea Apostolo dello Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea Ionio Marina",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Elia",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Eufemia d'Aspromonte",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Ilario dello Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Onofrio",
                "countryCode": "IT"
            },
            {
                "name": "Santa Caterina Albanese",
                "countryCode": "IT"
            },
            {
                "name": "Santa Caterina dello Ionio",
                "countryCode": "IT"
            },
            {
                "name": "Santa Caterina dello Ionio Marina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cristina d'Aspromonte",
                "countryCode": "IT"
            },
            {
                "name": "Santa Domenica",
                "countryCode": "IT"
            },
            {
                "name": "Santa Domenica Talao",
                "countryCode": "IT"
            },
            {
                "name": "Santa Eufemia Lamezia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria del Cedro",
                "countryCode": "IT"
            },
            {
                "name": "Santa Severina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Sofia d'Epiro",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano di Rogliano",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano in Aspromonte",
                "countryCode": "IT"
            },
            {
                "name": "Saracena",
                "countryCode": "IT"
            },
            {
                "name": "Sartano",
                "countryCode": "IT"
            },
            {
                "name": "Satriano",
                "countryCode": "IT"
            },
            {
                "name": "Savelli",
                "countryCode": "IT"
            },
            {
                "name": "Scala Coeli",
                "countryCode": "IT"
            },
            {
                "name": "Scalea",
                "countryCode": "IT"
            },
            {
                "name": "Scandale",
                "countryCode": "IT"
            },
            {
                "name": "Scarcelli",
                "countryCode": "IT"
            },
            {
                "name": "Scido",
                "countryCode": "IT"
            },
            {
                "name": "Scilla",
                "countryCode": "IT"
            },
            {
                "name": "Sellia",
                "countryCode": "IT"
            },
            {
                "name": "Sellia Marina",
                "countryCode": "IT"
            },
            {
                "name": "Seminara",
                "countryCode": "IT"
            },
            {
                "name": "Serra Pedace",
                "countryCode": "IT"
            },
            {
                "name": "Serra San Bruno",
                "countryCode": "IT"
            },
            {
                "name": "Serra d'Aiello",
                "countryCode": "IT"
            },
            {
                "name": "Serrastretta",
                "countryCode": "IT"
            },
            {
                "name": "Serrata",
                "countryCode": "IT"
            },
            {
                "name": "Sersale",
                "countryCode": "IT"
            },
            {
                "name": "Settimo",
                "countryCode": "IT"
            },
            {
                "name": "Settingiano",
                "countryCode": "IT"
            },
            {
                "name": "Siano",
                "countryCode": "IT"
            },
            {
                "name": "Sibari",
                "countryCode": "IT"
            },
            {
                "name": "Siderno",
                "countryCode": "IT"
            },
            {
                "name": "Simbario",
                "countryCode": "IT"
            },
            {
                "name": "Sinopoli",
                "countryCode": "IT"
            },
            {
                "name": "Sorbo San Basile",
                "countryCode": "IT"
            },
            {
                "name": "Sorianello",
                "countryCode": "IT"
            },
            {
                "name": "Soriano Calabro",
                "countryCode": "IT"
            },
            {
                "name": "Soverato Marina",
                "countryCode": "IT"
            },
            {
                "name": "Soverato Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Soveria Mannelli",
                "countryCode": "IT"
            },
            {
                "name": "Soveria Simeri",
                "countryCode": "IT"
            },
            {
                "name": "Spadola",
                "countryCode": "IT"
            },
            {
                "name": "Spezzano Albanese",
                "countryCode": "IT"
            },
            {
                "name": "Spezzano Piccolo",
                "countryCode": "IT"
            },
            {
                "name": "Spezzano della Sila",
                "countryCode": "IT"
            },
            {
                "name": "Spilinga",
                "countryCode": "IT"
            },
            {
                "name": "Squillace",
                "countryCode": "IT"
            },
            {
                "name": "Squillace Lido",
                "countryCode": "IT"
            },
            {
                "name": "Staiti",
                "countryCode": "IT"
            },
            {
                "name": "Staletti",
                "countryCode": "IT"
            },
            {
                "name": "Stazione Montalto-Coretto",
                "countryCode": "IT"
            },
            {
                "name": "Stefanaconi",
                "countryCode": "IT"
            },
            {
                "name": "Stignano",
                "countryCode": "IT"
            },
            {
                "name": "Stilo",
                "countryCode": "IT"
            },
            {
                "name": "Strongoli",
                "countryCode": "IT"
            },
            {
                "name": "Surdo",
                "countryCode": "IT"
            },
            {
                "name": "Tarsia",
                "countryCode": "IT"
            },
            {
                "name": "Taurianova",
                "countryCode": "IT"
            },
            {
                "name": "Taverna",
                "countryCode": "IT"
            },
            {
                "name": "Terranova Sappo Minulio",
                "countryCode": "IT"
            },
            {
                "name": "Terranova da Sibari",
                "countryCode": "IT"
            },
            {
                "name": "Terravecchia",
                "countryCode": "IT"
            },
            {
                "name": "Tiriolo",
                "countryCode": "IT"
            },
            {
                "name": "Tivolille Pasquali-Merenzata",
                "countryCode": "IT"
            },
            {
                "name": "Torano Castello",
                "countryCode": "IT"
            },
            {
                "name": "Torre Melissa",
                "countryCode": "IT"
            },
            {
                "name": "Torre di Ruggiero",
                "countryCode": "IT"
            },
            {
                "name": "Torretta",
                "countryCode": "IT"
            },
            {
                "name": "Tortora",
                "countryCode": "IT"
            },
            {
                "name": "Tortora Marina",
                "countryCode": "IT"
            },
            {
                "name": "Trebisacce",
                "countryCode": "IT"
            },
            {
                "name": "Trenta",
                "countryCode": "IT"
            },
            {
                "name": "Tronca",
                "countryCode": "IT"
            },
            {
                "name": "Tropea",
                "countryCode": "IT"
            },
            {
                "name": "Umbriatico",
                "countryCode": "IT"
            },
            {
                "name": "Vaccarizzo Albanese",
                "countryCode": "IT"
            },
            {
                "name": "Vadue",
                "countryCode": "IT"
            },
            {
                "name": "Vallefiorita",
                "countryCode": "IT"
            },
            {
                "name": "Vallelonga",
                "countryCode": "IT"
            },
            {
                "name": "Varapodio",
                "countryCode": "IT"
            },
            {
                "name": "Vazzano",
                "countryCode": "IT"
            },
            {
                "name": "Verbicaro",
                "countryCode": "IT"
            },
            {
                "name": "Verzino",
                "countryCode": "IT"
            },
            {
                "name": "Vibo Valentia",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Villapiana",
                "countryCode": "IT"
            },
            {
                "name": "Villapiana Lido",
                "countryCode": "IT"
            },
            {
                "name": "Weather Station",
                "countryCode": "IT"
            },
            {
                "name": "Zaccanopoli",
                "countryCode": "IT"
            },
            {
                "name": "Zagarise",
                "countryCode": "IT"
            },
            {
                "name": "Zambrone",
                "countryCode": "IT"
            },
            {
                "name": "Zumpano",
                "countryCode": "IT"
            },
            {
                "name": "Zungri",
                "countryCode": "IT"
            },
            {
                "name": "Acate",
                "countryCode": "IT"
            },
            {
                "name": "Aci Bonaccorsi",
                "countryCode": "IT"
            },
            {
                "name": "Aci Castello",
                "countryCode": "IT"
            },
            {
                "name": "Aci Catena",
                "countryCode": "IT"
            },
            {
                "name": "Aci Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Aci Trezza",
                "countryCode": "IT"
            },
            {
                "name": "Acireale",
                "countryCode": "IT"
            },
            {
                "name": "Acitrezza",
                "countryCode": "IT"
            },
            {
                "name": "Acquaviva Platani",
                "countryCode": "IT"
            },
            {
                "name": "Acquedolci",
                "countryCode": "IT"
            },
            {
                "name": "Adrano",
                "countryCode": "IT"
            },
            {
                "name": "Agira",
                "countryCode": "IT"
            },
            {
                "name": "Agliandroni-Paternella",
                "countryCode": "IT"
            },
            {
                "name": "Agrigento",
                "countryCode": "IT"
            },
            {
                "name": "Aidone",
                "countryCode": "IT"
            },
            {
                "name": "Alcamo",
                "countryCode": "IT"
            },
            {
                "name": "Alcara Li Fusi",
                "countryCode": "IT"
            },
            {
                "name": "Alessandria della Rocca",
                "countryCode": "IT"
            },
            {
                "name": "Alia",
                "countryCode": "IT"
            },
            {
                "name": "Alimena",
                "countryCode": "IT"
            },
            {
                "name": "Aliminusa",
                "countryCode": "IT"
            },
            {
                "name": "Altavilla Milicia",
                "countryCode": "IT"
            },
            {
                "name": "Altofonte",
                "countryCode": "IT"
            },
            {
                "name": "Alì",
                "countryCode": "IT"
            },
            {
                "name": "Alì Terme",
                "countryCode": "IT"
            },
            {
                "name": "Antillo",
                "countryCode": "IT"
            },
            {
                "name": "Aragona",
                "countryCode": "IT"
            },
            {
                "name": "Assoro",
                "countryCode": "IT"
            },
            {
                "name": "Augusta",
                "countryCode": "IT"
            },
            {
                "name": "Avola",
                "countryCode": "IT"
            },
            {
                "name": "Bagheria",
                "countryCode": "IT"
            },
            {
                "name": "Balestrate",
                "countryCode": "IT"
            },
            {
                "name": "Barcellona Pozzo di Gotto",
                "countryCode": "IT"
            },
            {
                "name": "Barrafranca",
                "countryCode": "IT"
            },
            {
                "name": "Basicò",
                "countryCode": "IT"
            },
            {
                "name": "Baucina",
                "countryCode": "IT"
            },
            {
                "name": "Belmonte Mezzagno",
                "countryCode": "IT"
            },
            {
                "name": "Belpasso",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere",
                "countryCode": "IT"
            },
            {
                "name": "Belvedere-Piano Tavola",
                "countryCode": "IT"
            },
            {
                "name": "Biancavilla",
                "countryCode": "IT"
            },
            {
                "name": "Bisacquino",
                "countryCode": "IT"
            },
            {
                "name": "Bivona",
                "countryCode": "IT"
            },
            {
                "name": "Blufi",
                "countryCode": "IT"
            },
            {
                "name": "Bolognetta",
                "countryCode": "IT"
            },
            {
                "name": "Bompensiere",
                "countryCode": "IT"
            },
            {
                "name": "Bompietro",
                "countryCode": "IT"
            },
            {
                "name": "Borgetto",
                "countryCode": "IT"
            },
            {
                "name": "Brolo",
                "countryCode": "IT"
            },
            {
                "name": "Bronte",
                "countryCode": "IT"
            },
            {
                "name": "Brucoli",
                "countryCode": "IT"
            },
            {
                "name": "Buccheri",
                "countryCode": "IT"
            },
            {
                "name": "Burgio",
                "countryCode": "IT"
            },
            {
                "name": "Buscemi",
                "countryCode": "IT"
            },
            {
                "name": "Buseto Palizzolo",
                "countryCode": "IT"
            },
            {
                "name": "Butera",
                "countryCode": "IT"
            },
            {
                "name": "Caccamo",
                "countryCode": "IT"
            },
            {
                "name": "Calamonaci",
                "countryCode": "IT"
            },
            {
                "name": "Calascibetta",
                "countryCode": "IT"
            },
            {
                "name": "Calatabiano",
                "countryCode": "IT"
            },
            {
                "name": "Calatafimi",
                "countryCode": "IT"
            },
            {
                "name": "Caltabellotta",
                "countryCode": "IT"
            },
            {
                "name": "Caltagirone",
                "countryCode": "IT"
            },
            {
                "name": "Caltanissetta",
                "countryCode": "IT"
            },
            {
                "name": "Caltavuturo",
                "countryCode": "IT"
            },
            {
                "name": "Camastra",
                "countryCode": "IT"
            },
            {
                "name": "Cammarata",
                "countryCode": "IT"
            },
            {
                "name": "Campanella-Gianforma",
                "countryCode": "IT"
            },
            {
                "name": "Campobello di Licata",
                "countryCode": "IT"
            },
            {
                "name": "Campobello di Mazara",
                "countryCode": "IT"
            },
            {
                "name": "Campofelice di Fitalia",
                "countryCode": "IT"
            },
            {
                "name": "Campofelice di Roccella",
                "countryCode": "IT"
            },
            {
                "name": "Campofiorito",
                "countryCode": "IT"
            },
            {
                "name": "Campofranco",
                "countryCode": "IT"
            },
            {
                "name": "Camporeale",
                "countryCode": "IT"
            },
            {
                "name": "Camporotondo Etneo",
                "countryCode": "IT"
            },
            {
                "name": "Canalicchio",
                "countryCode": "IT"
            },
            {
                "name": "Canicattini Bagni",
                "countryCode": "IT"
            },
            {
                "name": "Canicattì",
                "countryCode": "IT"
            },
            {
                "name": "Canneto",
                "countryCode": "IT"
            },
            {
                "name": "Cannizzaro-Favara",
                "countryCode": "IT"
            },
            {
                "name": "Capaci",
                "countryCode": "IT"
            },
            {
                "name": "Capizzi",
                "countryCode": "IT"
            },
            {
                "name": "Capo d'Orlando",
                "countryCode": "IT"
            },
            {
                "name": "Capri Leone",
                "countryCode": "IT"
            },
            {
                "name": "Carini",
                "countryCode": "IT"
            },
            {
                "name": "Carlentini",
                "countryCode": "IT"
            },
            {
                "name": "Caronia",
                "countryCode": "IT"
            },
            {
                "name": "Carrozziere",
                "countryCode": "IT"
            },
            {
                "name": "Carruba",
                "countryCode": "IT"
            },
            {
                "name": "Carrubazza-Motta",
                "countryCode": "IT"
            },
            {
                "name": "Casa Santa",
                "countryCode": "IT"
            },
            {
                "name": "Casalvecchio Siculo",
                "countryCode": "IT"
            },
            {
                "name": "Cassaro",
                "countryCode": "IT"
            },
            {
                "name": "Cassibile",
                "countryCode": "IT"
            },
            {
                "name": "Castanea delle Furie",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Judica",
                "countryCode": "IT"
            },
            {
                "name": "Castel di Lucio",
                "countryCode": "IT"
            },
            {
                "name": "Castelbuono",
                "countryCode": "IT"
            },
            {
                "name": "Casteldaccia",
                "countryCode": "IT"
            },
            {
                "name": "Castell'Umberto",
                "countryCode": "IT"
            },
            {
                "name": "Castellammare del Golfo",
                "countryCode": "IT"
            },
            {
                "name": "Castellana Sicula",
                "countryCode": "IT"
            },
            {
                "name": "Castelluzzo",
                "countryCode": "IT"
            },
            {
                "name": "Castelmola",
                "countryCode": "IT"
            },
            {
                "name": "Casteltermini",
                "countryCode": "IT"
            },
            {
                "name": "Castelvetrano",
                "countryCode": "IT"
            },
            {
                "name": "Castiglione di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Castrofilippo",
                "countryCode": "IT"
            },
            {
                "name": "Castronuovo di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Castroreale",
                "countryCode": "IT"
            },
            {
                "name": "Catania",
                "countryCode": "IT"
            },
            {
                "name": "Catenanuova",
                "countryCode": "IT"
            },
            {
                "name": "Cattolica Eraclea",
                "countryCode": "IT"
            },
            {
                "name": "Cava d'Aliga",
                "countryCode": "IT"
            },
            {
                "name": "Cefalà Diana",
                "countryCode": "IT"
            },
            {
                "name": "Cefalù",
                "countryCode": "IT"
            },
            {
                "name": "Centuripe",
                "countryCode": "IT"
            },
            {
                "name": "Cerami",
                "countryCode": "IT"
            },
            {
                "name": "Cerda",
                "countryCode": "IT"
            },
            {
                "name": "Cerza",
                "countryCode": "IT"
            },
            {
                "name": "Cesarò",
                "countryCode": "IT"
            },
            {
                "name": "Chianchitta-Pallio",
                "countryCode": "IT"
            },
            {
                "name": "Chianchitta-Trappitello",
                "countryCode": "IT"
            },
            {
                "name": "Chiaramonte Gulfi",
                "countryCode": "IT"
            },
            {
                "name": "Chiusa Sclafani",
                "countryCode": "IT"
            },
            {
                "name": "Ciaculli",
                "countryCode": "IT"
            },
            {
                "name": "Cianciana",
                "countryCode": "IT"
            },
            {
                "name": "Ciavolo",
                "countryCode": "IT"
            },
            {
                "name": "Ciminna",
                "countryCode": "IT"
            },
            {
                "name": "Cinisi",
                "countryCode": "IT"
            },
            {
                "name": "Città Giardino",
                "countryCode": "IT"
            },
            {
                "name": "Collesano",
                "countryCode": "IT"
            },
            {
                "name": "Comiso",
                "countryCode": "IT"
            },
            {
                "name": "Comitini",
                "countryCode": "IT"
            },
            {
                "name": "Condrò",
                "countryCode": "IT"
            },
            {
                "name": "Contessa Entellina",
                "countryCode": "IT"
            },
            {
                "name": "Corleone",
                "countryCode": "IT"
            },
            {
                "name": "Custonaci",
                "countryCode": "IT"
            },
            {
                "name": "Delia",
                "countryCode": "IT"
            },
            {
                "name": "Donnalucata",
                "countryCode": "IT"
            },
            {
                "name": "Enna",
                "countryCode": "IT"
            },
            {
                "name": "Erice",
                "countryCode": "IT"
            },
            {
                "name": "Evangelisti-Rubino",
                "countryCode": "IT"
            },
            {
                "name": "Falcone",
                "countryCode": "IT"
            },
            {
                "name": "Faro Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Favara",
                "countryCode": "IT"
            },
            {
                "name": "Favignana",
                "countryCode": "IT"
            },
            {
                "name": "Ferla",
                "countryCode": "IT"
            },
            {
                "name": "Ficarazzi",
                "countryCode": "IT"
            },
            {
                "name": "Ficarra",
                "countryCode": "IT"
            },
            {
                "name": "Finale",
                "countryCode": "IT"
            },
            {
                "name": "Fiumedinisi",
                "countryCode": "IT"
            },
            {
                "name": "Fiumefreddo Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Floresta",
                "countryCode": "IT"
            },
            {
                "name": "Floridia",
                "countryCode": "IT"
            },
            {
                "name": "Fondachelli-Fantina",
                "countryCode": "IT"
            },
            {
                "name": "Forza d'Agrò",
                "countryCode": "IT"
            },
            {
                "name": "Francavilla di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Francofonte",
                "countryCode": "IT"
            },
            {
                "name": "Frazzanò",
                "countryCode": "IT"
            },
            {
                "name": "Fulgatore-Torretta",
                "countryCode": "IT"
            },
            {
                "name": "Furci Siculo",
                "countryCode": "IT"
            },
            {
                "name": "Furnari",
                "countryCode": "IT"
            },
            {
                "name": "Gaggi",
                "countryCode": "IT"
            },
            {
                "name": "Gagliano Castelferrato",
                "countryCode": "IT"
            },
            {
                "name": "Galati Mamertino",
                "countryCode": "IT"
            },
            {
                "name": "Gallodoro",
                "countryCode": "IT"
            },
            {
                "name": "Gangi",
                "countryCode": "IT"
            },
            {
                "name": "Gela",
                "countryCode": "IT"
            },
            {
                "name": "Geraci Siculo",
                "countryCode": "IT"
            },
            {
                "name": "Giacalone",
                "countryCode": "IT"
            },
            {
                "name": "Giammoro",
                "countryCode": "IT"
            },
            {
                "name": "Giardina Gallotti",
                "countryCode": "IT"
            },
            {
                "name": "Giardinello",
                "countryCode": "IT"
            },
            {
                "name": "Giardini-Naxos",
                "countryCode": "IT"
            },
            {
                "name": "Giarratana",
                "countryCode": "IT"
            },
            {
                "name": "Giarre",
                "countryCode": "IT"
            },
            {
                "name": "Gioiosa Marea",
                "countryCode": "IT"
            },
            {
                "name": "Giuliana",
                "countryCode": "IT"
            },
            {
                "name": "Gliaca",
                "countryCode": "IT"
            },
            {
                "name": "Godrano",
                "countryCode": "IT"
            },
            {
                "name": "Grammichele",
                "countryCode": "IT"
            },
            {
                "name": "Graniti",
                "countryCode": "IT"
            },
            {
                "name": "Gratteri",
                "countryCode": "IT"
            },
            {
                "name": "Gravina di Catania",
                "countryCode": "IT"
            },
            {
                "name": "Grotte",
                "countryCode": "IT"
            },
            {
                "name": "Gualtieri Sicaminò",
                "countryCode": "IT"
            },
            {
                "name": "Guidomandri Marina",
                "countryCode": "IT"
            },
            {
                "name": "Isnello",
                "countryCode": "IT"
            },
            {
                "name": "Isola delle Femmine",
                "countryCode": "IT"
            },
            {
                "name": "Ispica",
                "countryCode": "IT"
            },
            {
                "name": "Itala",
                "countryCode": "IT"
            },
            {
                "name": "Joppolo Giancaxio",
                "countryCode": "IT"
            },
            {
                "name": "Kamma",
                "countryCode": "IT"
            },
            {
                "name": "Lampedusa",
                "countryCode": "IT"
            },
            {
                "name": "Larderia",
                "countryCode": "IT"
            },
            {
                "name": "Lascari",
                "countryCode": "IT"
            },
            {
                "name": "Lavinaio-Monterosso",
                "countryCode": "IT"
            },
            {
                "name": "Leni",
                "countryCode": "IT"
            },
            {
                "name": "Lentini",
                "countryCode": "IT"
            },
            {
                "name": "Leonforte",
                "countryCode": "IT"
            },
            {
                "name": "Lercara Friddi",
                "countryCode": "IT"
            },
            {
                "name": "Letojanni",
                "countryCode": "IT"
            },
            {
                "name": "Librizzi",
                "countryCode": "IT"
            },
            {
                "name": "Licata",
                "countryCode": "IT"
            },
            {
                "name": "Licodia Eubea",
                "countryCode": "IT"
            },
            {
                "name": "Limina",
                "countryCode": "IT"
            },
            {
                "name": "Linera",
                "countryCode": "IT"
            },
            {
                "name": "Linguaglossa",
                "countryCode": "IT"
            },
            {
                "name": "Lipari",
                "countryCode": "IT"
            },
            {
                "name": "Longi",
                "countryCode": "IT"
            },
            {
                "name": "Lucca Sicula",
                "countryCode": "IT"
            },
            {
                "name": "Maletto",
                "countryCode": "IT"
            },
            {
                "name": "Malfa",
                "countryCode": "IT"
            },
            {
                "name": "Malvagna",
                "countryCode": "IT"
            },
            {
                "name": "Mandanici",
                "countryCode": "IT"
            },
            {
                "name": "Maniace",
                "countryCode": "IT"
            },
            {
                "name": "Marausa",
                "countryCode": "IT"
            },
            {
                "name": "Marianopoli",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Caronia",
                "countryCode": "IT"
            },
            {
                "name": "Marina di Ragusa",
                "countryCode": "IT"
            },
            {
                "name": "Marinella",
                "countryCode": "IT"
            },
            {
                "name": "Marineo",
                "countryCode": "IT"
            },
            {
                "name": "Marsala",
                "countryCode": "IT"
            },
            {
                "name": "Mascali",
                "countryCode": "IT"
            },
            {
                "name": "Mascalucia",
                "countryCode": "IT"
            },
            {
                "name": "Maugeri",
                "countryCode": "IT"
            },
            {
                "name": "Mazara II",
                "countryCode": "IT"
            },
            {
                "name": "Mazara del Vallo",
                "countryCode": "IT"
            },
            {
                "name": "Mazzarino",
                "countryCode": "IT"
            },
            {
                "name": "Mazzarrone",
                "countryCode": "IT"
            },
            {
                "name": "Mazzarrà Sant'Andrea",
                "countryCode": "IT"
            },
            {
                "name": "Melia",
                "countryCode": "IT"
            },
            {
                "name": "Melilli",
                "countryCode": "IT"
            },
            {
                "name": "Menfi",
                "countryCode": "IT"
            },
            {
                "name": "Merì",
                "countryCode": "IT"
            },
            {
                "name": "Messina",
                "countryCode": "IT"
            },
            {
                "name": "Mezzojuso",
                "countryCode": "IT"
            },
            {
                "name": "Milazzo",
                "countryCode": "IT"
            },
            {
                "name": "Milena",
                "countryCode": "IT"
            },
            {
                "name": "Militello Rosmarino",
                "countryCode": "IT"
            },
            {
                "name": "Militello in Val di Catania",
                "countryCode": "IT"
            },
            {
                "name": "Milo",
                "countryCode": "IT"
            },
            {
                "name": "Mineo",
                "countryCode": "IT"
            },
            {
                "name": "Mirabella Imbaccari",
                "countryCode": "IT"
            },
            {
                "name": "Mirto",
                "countryCode": "IT"
            },
            {
                "name": "Misilmeri",
                "countryCode": "IT"
            },
            {
                "name": "Misterbianco",
                "countryCode": "IT"
            },
            {
                "name": "Mistretta",
                "countryCode": "IT"
            },
            {
                "name": "Modica",
                "countryCode": "IT"
            },
            {
                "name": "Moio Alcantara",
                "countryCode": "IT"
            },
            {
                "name": "Monforte San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "Mongiuffi Melia",
                "countryCode": "IT"
            },
            {
                "name": "Monreale",
                "countryCode": "IT"
            },
            {
                "name": "Montagnareale",
                "countryCode": "IT"
            },
            {
                "name": "Montalbano Elicona",
                "countryCode": "IT"
            },
            {
                "name": "Montallegro",
                "countryCode": "IT"
            },
            {
                "name": "Montedoro",
                "countryCode": "IT"
            },
            {
                "name": "Montelepre",
                "countryCode": "IT"
            },
            {
                "name": "Montemaggiore Belsito",
                "countryCode": "IT"
            },
            {
                "name": "Monterosso Almo",
                "countryCode": "IT"
            },
            {
                "name": "Montevago",
                "countryCode": "IT"
            },
            {
                "name": "Motta Camastra",
                "countryCode": "IT"
            },
            {
                "name": "Motta Sant'Anastasia",
                "countryCode": "IT"
            },
            {
                "name": "Motta d'Affermo",
                "countryCode": "IT"
            },
            {
                "name": "Mussomeli",
                "countryCode": "IT"
            },
            {
                "name": "Naro",
                "countryCode": "IT"
            },
            {
                "name": "Naso",
                "countryCode": "IT"
            },
            {
                "name": "Nicolosi",
                "countryCode": "IT"
            },
            {
                "name": "Nicosia",
                "countryCode": "IT"
            },
            {
                "name": "Niscemi",
                "countryCode": "IT"
            },
            {
                "name": "Nissoria",
                "countryCode": "IT"
            },
            {
                "name": "Nizza di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Noto",
                "countryCode": "IT"
            },
            {
                "name": "Novara di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Nunziata",
                "countryCode": "IT"
            },
            {
                "name": "Nuova Gibellina",
                "countryCode": "IT"
            },
            {
                "name": "Nuovo Centro Urbano Poggioreale",
                "countryCode": "IT"
            },
            {
                "name": "Oliveri",
                "countryCode": "IT"
            },
            {
                "name": "Pace del Mela",
                "countryCode": "IT"
            },
            {
                "name": "Paceco",
                "countryCode": "IT"
            },
            {
                "name": "Pachino",
                "countryCode": "IT"
            },
            {
                "name": "Pagliara",
                "countryCode": "IT"
            },
            {
                "name": "Palagonia",
                "countryCode": "IT"
            },
            {
                "name": "Palazzo Adriano",
                "countryCode": "IT"
            },
            {
                "name": "Palazzolo",
                "countryCode": "IT"
            },
            {
                "name": "Palazzolo Acreide",
                "countryCode": "IT"
            },
            {
                "name": "Palermo",
                "countryCode": "IT"
            },
            {
                "name": "Palma di Montechiaro",
                "countryCode": "IT"
            },
            {
                "name": "Pantelleria",
                "countryCode": "IT"
            },
            {
                "name": "Partanna",
                "countryCode": "IT"
            },
            {
                "name": "Partinico",
                "countryCode": "IT"
            },
            {
                "name": "Pasteria-Lapide",
                "countryCode": "IT"
            },
            {
                "name": "Paternò",
                "countryCode": "IT"
            },
            {
                "name": "Patti",
                "countryCode": "IT"
            },
            {
                "name": "Pedagaggi",
                "countryCode": "IT"
            },
            {
                "name": "Pedalino",
                "countryCode": "IT"
            },
            {
                "name": "Pedara",
                "countryCode": "IT"
            },
            {
                "name": "Pennisi",
                "countryCode": "IT"
            },
            {
                "name": "Petralia Soprana",
                "countryCode": "IT"
            },
            {
                "name": "Petralia Sottana",
                "countryCode": "IT"
            },
            {
                "name": "Petrosino",
                "countryCode": "IT"
            },
            {
                "name": "Pettineo",
                "countryCode": "IT"
            },
            {
                "name": "Piana degli Albanesi",
                "countryCode": "IT"
            },
            {
                "name": "Piano Maglio-Blandino",
                "countryCode": "IT"
            },
            {
                "name": "Piano dei Geli",
                "countryCode": "IT"
            },
            {
                "name": "Pianoconte",
                "countryCode": "IT"
            },
            {
                "name": "Piazza Armerina",
                "countryCode": "IT"
            },
            {
                "name": "Piedimonte Etneo",
                "countryCode": "IT"
            },
            {
                "name": "Pietraperzia",
                "countryCode": "IT"
            },
            {
                "name": "Pioppo",
                "countryCode": "IT"
            },
            {
                "name": "Piraino",
                "countryCode": "IT"
            },
            {
                "name": "Polizzi Generosa",
                "countryCode": "IT"
            },
            {
                "name": "Pollina",
                "countryCode": "IT"
            },
            {
                "name": "Portella di Mare",
                "countryCode": "IT"
            },
            {
                "name": "Porto Empedocle",
                "countryCode": "IT"
            },
            {
                "name": "Portopalo di Capo Passero",
                "countryCode": "IT"
            },
            {
                "name": "Pozzallo",
                "countryCode": "IT"
            },
            {
                "name": "Priolo Gargallo",
                "countryCode": "IT"
            },
            {
                "name": "Prizzi",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Caltanissetta",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Siracusa",
                "countryCode": "IT"
            },
            {
                "name": "Racalmuto",
                "countryCode": "IT"
            },
            {
                "name": "Raccuja",
                "countryCode": "IT"
            },
            {
                "name": "Raddusa",
                "countryCode": "IT"
            },
            {
                "name": "Raffadali",
                "countryCode": "IT"
            },
            {
                "name": "Ragalna",
                "countryCode": "IT"
            },
            {
                "name": "Ragusa",
                "countryCode": "IT"
            },
            {
                "name": "Ramacca",
                "countryCode": "IT"
            },
            {
                "name": "Randazzo",
                "countryCode": "IT"
            },
            {
                "name": "Ravanusa",
                "countryCode": "IT"
            },
            {
                "name": "Realmonte",
                "countryCode": "IT"
            },
            {
                "name": "Regalbuto",
                "countryCode": "IT"
            },
            {
                "name": "Reitano",
                "countryCode": "IT"
            },
            {
                "name": "Resuttano",
                "countryCode": "IT"
            },
            {
                "name": "Ribera",
                "countryCode": "IT"
            },
            {
                "name": "Riesi",
                "countryCode": "IT"
            },
            {
                "name": "Rilievo",
                "countryCode": "IT"
            },
            {
                "name": "Riposto",
                "countryCode": "IT"
            },
            {
                "name": "Rocca di Capri Leone",
                "countryCode": "IT"
            },
            {
                "name": "Roccafiorita",
                "countryCode": "IT"
            },
            {
                "name": "Roccalumera",
                "countryCode": "IT"
            },
            {
                "name": "Roccamena",
                "countryCode": "IT"
            },
            {
                "name": "Roccapalumba",
                "countryCode": "IT"
            },
            {
                "name": "Roccavaldina",
                "countryCode": "IT"
            },
            {
                "name": "Roccella Valdemone",
                "countryCode": "IT"
            },
            {
                "name": "Rodì",
                "countryCode": "IT"
            },
            {
                "name": "Rometta",
                "countryCode": "IT"
            },
            {
                "name": "Rometta Marea",
                "countryCode": "IT"
            },
            {
                "name": "Rosolini",
                "countryCode": "IT"
            },
            {
                "name": "Salaparuta",
                "countryCode": "IT"
            },
            {
                "name": "Salemi",
                "countryCode": "IT"
            },
            {
                "name": "Sambuca di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "San Biagio Platani",
                "countryCode": "IT"
            },
            {
                "name": "San Cataldo",
                "countryCode": "IT"
            },
            {
                "name": "San Cipirello",
                "countryCode": "IT"
            },
            {
                "name": "San Ciro-Ulmi-Filci",
                "countryCode": "IT"
            },
            {
                "name": "San Cono",
                "countryCode": "IT"
            },
            {
                "name": "San Filippo del Mela",
                "countryCode": "IT"
            },
            {
                "name": "San Fratello",
                "countryCode": "IT"
            },
            {
                "name": "San Giorgio",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Gemini",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni la Punta",
                "countryCode": "IT"
            },
            {
                "name": "San Giuseppe Jato",
                "countryCode": "IT"
            },
            {
                "name": "San Gregorio di Catania",
                "countryCode": "IT"
            },
            {
                "name": "San Leone Mosè",
                "countryCode": "IT"
            },
            {
                "name": "San Marco d'Alunzio",
                "countryCode": "IT"
            },
            {
                "name": "San Mauro Castelverde",
                "countryCode": "IT"
            },
            {
                "name": "San Michele di Ganzaria",
                "countryCode": "IT"
            },
            {
                "name": "San Pier Niceto",
                "countryCode": "IT"
            },
            {
                "name": "San Piero Patti",
                "countryCode": "IT"
            },
            {
                "name": "San Pietro Clarenza",
                "countryCode": "IT"
            },
            {
                "name": "San Salvatore di Fitalia",
                "countryCode": "IT"
            },
            {
                "name": "San Teodoro",
                "countryCode": "IT"
            },
            {
                "name": "San Vito Lo Capo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata Li Battiati",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Agata di Militello",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alessio Siculo",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Alfio",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo Muxaro",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Angelo di Brolo",
                "countryCode": "IT"
            },
            {
                "name": "Santa Caterina Villarmosa",
                "countryCode": "IT"
            },
            {
                "name": "Santa Cristina Gela",
                "countryCode": "IT"
            },
            {
                "name": "Santa Croce Camerina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Domenica Vittoria",
                "countryCode": "IT"
            },
            {
                "name": "Santa Elisabetta",
                "countryCode": "IT"
            },
            {
                "name": "Santa Flavia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Lucia del Mela",
                "countryCode": "IT"
            },
            {
                "name": "Santa Margherita di Belice",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria di Licodia",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria la Stella",
                "countryCode": "IT"
            },
            {
                "name": "Santa Marina Salina",
                "countryCode": "IT"
            },
            {
                "name": "Santa Ninfa",
                "countryCode": "IT"
            },
            {
                "name": "Santa Teresa di Riva",
                "countryCode": "IT"
            },
            {
                "name": "Santa Venerina",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano Quisquina",
                "countryCode": "IT"
            },
            {
                "name": "Santo Stefano di Camastra",
                "countryCode": "IT"
            },
            {
                "name": "Saponara",
                "countryCode": "IT"
            },
            {
                "name": "Saponara Marittima",
                "countryCode": "IT"
            },
            {
                "name": "Sasi",
                "countryCode": "IT"
            },
            {
                "name": "Savoca",
                "countryCode": "IT"
            },
            {
                "name": "Scaletta Zanclea",
                "countryCode": "IT"
            },
            {
                "name": "Sciacca",
                "countryCode": "IT"
            },
            {
                "name": "Sciara",
                "countryCode": "IT"
            },
            {
                "name": "Scicli",
                "countryCode": "IT"
            },
            {
                "name": "Scillato",
                "countryCode": "IT"
            },
            {
                "name": "Sclafani Bagni",
                "countryCode": "IT"
            },
            {
                "name": "Scoglitti",
                "countryCode": "IT"
            },
            {
                "name": "Scordia",
                "countryCode": "IT"
            },
            {
                "name": "Serradifalco",
                "countryCode": "IT"
            },
            {
                "name": "Sfaranda",
                "countryCode": "IT"
            },
            {
                "name": "Sferracavallo",
                "countryCode": "IT"
            },
            {
                "name": "Siculiana",
                "countryCode": "IT"
            },
            {
                "name": "Sinagra",
                "countryCode": "IT"
            },
            {
                "name": "Siracusa",
                "countryCode": "IT"
            },
            {
                "name": "Solarino",
                "countryCode": "IT"
            },
            {
                "name": "Sommatino",
                "countryCode": "IT"
            },
            {
                "name": "Sortino",
                "countryCode": "IT"
            },
            {
                "name": "Spadafora",
                "countryCode": "IT"
            },
            {
                "name": "Sparta'",
                "countryCode": "IT"
            },
            {
                "name": "Sperlinga",
                "countryCode": "IT"
            },
            {
                "name": "Sutera",
                "countryCode": "IT"
            },
            {
                "name": "Taormina",
                "countryCode": "IT"
            },
            {
                "name": "Terme",
                "countryCode": "IT"
            },
            {
                "name": "Termini Imerese",
                "countryCode": "IT"
            },
            {
                "name": "Terrasini",
                "countryCode": "IT"
            },
            {
                "name": "Tonnara di Bonagia",
                "countryCode": "IT"
            },
            {
                "name": "Tonnarella",
                "countryCode": "IT"
            },
            {
                "name": "Torre Colonna-Sperone",
                "countryCode": "IT"
            },
            {
                "name": "Torregrotta",
                "countryCode": "IT"
            },
            {
                "name": "Torrenova",
                "countryCode": "IT"
            },
            {
                "name": "Torretta",
                "countryCode": "IT"
            },
            {
                "name": "Tortorici",
                "countryCode": "IT"
            },
            {
                "name": "Trabia",
                "countryCode": "IT"
            },
            {
                "name": "Trapani",
                "countryCode": "IT"
            },
            {
                "name": "Trappeto",
                "countryCode": "IT"
            },
            {
                "name": "Tre Fontane",
                "countryCode": "IT"
            },
            {
                "name": "Trecastagni",
                "countryCode": "IT"
            },
            {
                "name": "Tremestieri Etneo",
                "countryCode": "IT"
            },
            {
                "name": "Tripi",
                "countryCode": "IT"
            },
            {
                "name": "Triscina",
                "countryCode": "IT"
            },
            {
                "name": "Troina",
                "countryCode": "IT"
            },
            {
                "name": "Tusa",
                "countryCode": "IT"
            },
            {
                "name": "Ucria",
                "countryCode": "IT"
            },
            {
                "name": "Ustica",
                "countryCode": "IT"
            },
            {
                "name": "Valderice",
                "countryCode": "IT"
            },
            {
                "name": "Valdina",
                "countryCode": "IT"
            },
            {
                "name": "Valguarnera Caropepe",
                "countryCode": "IT"
            },
            {
                "name": "Valledolmo",
                "countryCode": "IT"
            },
            {
                "name": "Vallelunga Pratameno",
                "countryCode": "IT"
            },
            {
                "name": "Valverde",
                "countryCode": "IT"
            },
            {
                "name": "Vambolieri",
                "countryCode": "IT"
            },
            {
                "name": "Venetico Marina",
                "countryCode": "IT"
            },
            {
                "name": "Venetico Superiore",
                "countryCode": "IT"
            },
            {
                "name": "Ventimiglia di Sicilia",
                "countryCode": "IT"
            },
            {
                "name": "Viagrande",
                "countryCode": "IT"
            },
            {
                "name": "Vicari",
                "countryCode": "IT"
            },
            {
                "name": "Vigliatore 2",
                "countryCode": "IT"
            },
            {
                "name": "Villa Ciambra",
                "countryCode": "IT"
            },
            {
                "name": "Villabate",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca Sicula",
                "countryCode": "IT"
            },
            {
                "name": "Villafranca Tirrena",
                "countryCode": "IT"
            },
            {
                "name": "Villafrati",
                "countryCode": "IT"
            },
            {
                "name": "Villaggio del Pino-Le Ginestre",
                "countryCode": "IT"
            },
            {
                "name": "Villalba",
                "countryCode": "IT"
            },
            {
                "name": "Villarosa",
                "countryCode": "IT"
            },
            {
                "name": "Villaseta",
                "countryCode": "IT"
            },
            {
                "name": "Villasmundo",
                "countryCode": "IT"
            },
            {
                "name": "Vita",
                "countryCode": "IT"
            },
            {
                "name": "Vittoria",
                "countryCode": "IT"
            },
            {
                "name": "Vizzini",
                "countryCode": "IT"
            },
            {
                "name": "Zafferana Etnea",
                "countryCode": "IT"
            },
            {
                "name": "Abbasanta",
                "countryCode": "IT"
            },
            {
                "name": "Aggius",
                "countryCode": "IT"
            },
            {
                "name": "Aglientu",
                "countryCode": "IT"
            },
            {
                "name": "Aidomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Albagiara",
                "countryCode": "IT"
            },
            {
                "name": "Ales",
                "countryCode": "IT"
            },
            {
                "name": "Alghero",
                "countryCode": "IT"
            },
            {
                "name": "Allai",
                "countryCode": "IT"
            },
            {
                "name": "Alà dei Sardi",
                "countryCode": "IT"
            },
            {
                "name": "Anela",
                "countryCode": "IT"
            },
            {
                "name": "Arbatax",
                "countryCode": "IT"
            },
            {
                "name": "Arborea",
                "countryCode": "IT"
            },
            {
                "name": "Arbus",
                "countryCode": "IT"
            },
            {
                "name": "Ardara",
                "countryCode": "IT"
            },
            {
                "name": "Ardauli",
                "countryCode": "IT"
            },
            {
                "name": "Aritzo",
                "countryCode": "IT"
            },
            {
                "name": "Armungia",
                "countryCode": "IT"
            },
            {
                "name": "Arzachena",
                "countryCode": "IT"
            },
            {
                "name": "Arzana",
                "countryCode": "IT"
            },
            {
                "name": "Assemini",
                "countryCode": "IT"
            },
            {
                "name": "Assolo",
                "countryCode": "IT"
            },
            {
                "name": "Asuni",
                "countryCode": "IT"
            },
            {
                "name": "Atzara",
                "countryCode": "IT"
            },
            {
                "name": "Austis",
                "countryCode": "IT"
            },
            {
                "name": "Bacu Abis",
                "countryCode": "IT"
            },
            {
                "name": "Badesi",
                "countryCode": "IT"
            },
            {
                "name": "Ballao",
                "countryCode": "IT"
            },
            {
                "name": "Banari",
                "countryCode": "IT"
            },
            {
                "name": "Bancali",
                "countryCode": "IT"
            },
            {
                "name": "Baradili",
                "countryCode": "IT"
            },
            {
                "name": "Baratili San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Baressa",
                "countryCode": "IT"
            },
            {
                "name": "Bari Sardo",
                "countryCode": "IT"
            },
            {
                "name": "Barrali",
                "countryCode": "IT"
            },
            {
                "name": "Barumini",
                "countryCode": "IT"
            },
            {
                "name": "Bauladu",
                "countryCode": "IT"
            },
            {
                "name": "Baunei",
                "countryCode": "IT"
            },
            {
                "name": "Belvì",
                "countryCode": "IT"
            },
            {
                "name": "Benetutti",
                "countryCode": "IT"
            },
            {
                "name": "Berchidda",
                "countryCode": "IT"
            },
            {
                "name": "Bessude",
                "countryCode": "IT"
            },
            {
                "name": "Bidonì",
                "countryCode": "IT"
            },
            {
                "name": "Birori",
                "countryCode": "IT"
            },
            {
                "name": "Bitti",
                "countryCode": "IT"
            },
            {
                "name": "Bolotana",
                "countryCode": "IT"
            },
            {
                "name": "Bonarcado",
                "countryCode": "IT"
            },
            {
                "name": "Bonnanaro",
                "countryCode": "IT"
            },
            {
                "name": "Bono",
                "countryCode": "IT"
            },
            {
                "name": "Bonorva",
                "countryCode": "IT"
            },
            {
                "name": "Boroneddu",
                "countryCode": "IT"
            },
            {
                "name": "Borore",
                "countryCode": "IT"
            },
            {
                "name": "Bortigali",
                "countryCode": "IT"
            },
            {
                "name": "Bortigiadas",
                "countryCode": "IT"
            },
            {
                "name": "Borutta",
                "countryCode": "IT"
            },
            {
                "name": "Bosa",
                "countryCode": "IT"
            },
            {
                "name": "Bottidda",
                "countryCode": "IT"
            },
            {
                "name": "Buddusò",
                "countryCode": "IT"
            },
            {
                "name": "Budoni",
                "countryCode": "IT"
            },
            {
                "name": "Buggerru",
                "countryCode": "IT"
            },
            {
                "name": "Bultei",
                "countryCode": "IT"
            },
            {
                "name": "Bulzi",
                "countryCode": "IT"
            },
            {
                "name": "Burcei",
                "countryCode": "IT"
            },
            {
                "name": "Burgos",
                "countryCode": "IT"
            },
            {
                "name": "Busachi",
                "countryCode": "IT"
            },
            {
                "name": "Cabras",
                "countryCode": "IT"
            },
            {
                "name": "Cagliari",
                "countryCode": "IT"
            },
            {
                "name": "Cala Gonone",
                "countryCode": "IT"
            },
            {
                "name": "Calangianus",
                "countryCode": "IT"
            },
            {
                "name": "Calasetta",
                "countryCode": "IT"
            },
            {
                "name": "Capoterra",
                "countryCode": "IT"
            },
            {
                "name": "Carbonia",
                "countryCode": "IT"
            },
            {
                "name": "Cardedu",
                "countryCode": "IT"
            },
            {
                "name": "Cargeghe",
                "countryCode": "IT"
            },
            {
                "name": "Carloforte",
                "countryCode": "IT"
            },
            {
                "name": "Castelsardo",
                "countryCode": "IT"
            },
            {
                "name": "Castiadas",
                "countryCode": "IT"
            },
            {
                "name": "Cheremule",
                "countryCode": "IT"
            },
            {
                "name": "Chiaramonti",
                "countryCode": "IT"
            },
            {
                "name": "Codaruina",
                "countryCode": "IT"
            },
            {
                "name": "Codrongianos",
                "countryCode": "IT"
            },
            {
                "name": "Collinas",
                "countryCode": "IT"
            },
            {
                "name": "Cortoghiana",
                "countryCode": "IT"
            },
            {
                "name": "Cossoine",
                "countryCode": "IT"
            },
            {
                "name": "Cuglieri",
                "countryCode": "IT"
            },
            {
                "name": "Curcuris",
                "countryCode": "IT"
            },
            {
                "name": "Decimomannu",
                "countryCode": "IT"
            },
            {
                "name": "Decimoputzu",
                "countryCode": "IT"
            },
            {
                "name": "Desulo",
                "countryCode": "IT"
            },
            {
                "name": "Dolianova",
                "countryCode": "IT"
            },
            {
                "name": "Domus de Maria",
                "countryCode": "IT"
            },
            {
                "name": "Domusnovas",
                "countryCode": "IT"
            },
            {
                "name": "Donigala Fenugheddu",
                "countryCode": "IT"
            },
            {
                "name": "Donorì",
                "countryCode": "IT"
            },
            {
                "name": "Dorgali",
                "countryCode": "IT"
            },
            {
                "name": "Dualchi",
                "countryCode": "IT"
            },
            {
                "name": "Elini",
                "countryCode": "IT"
            },
            {
                "name": "Elmas",
                "countryCode": "IT"
            },
            {
                "name": "Erula",
                "countryCode": "IT"
            },
            {
                "name": "Escalaplano",
                "countryCode": "IT"
            },
            {
                "name": "Escolca",
                "countryCode": "IT"
            },
            {
                "name": "Esporlatu",
                "countryCode": "IT"
            },
            {
                "name": "Esterzili",
                "countryCode": "IT"
            },
            {
                "name": "Florinas",
                "countryCode": "IT"
            },
            {
                "name": "Fluminimaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Flussio",
                "countryCode": "IT"
            },
            {
                "name": "Fonni",
                "countryCode": "IT"
            },
            {
                "name": "Fordongianus",
                "countryCode": "IT"
            },
            {
                "name": "Furtei",
                "countryCode": "IT"
            },
            {
                "name": "Gadoni",
                "countryCode": "IT"
            },
            {
                "name": "Gairo Sant'Elena",
                "countryCode": "IT"
            },
            {
                "name": "Galtellì",
                "countryCode": "IT"
            },
            {
                "name": "Gavoi",
                "countryCode": "IT"
            },
            {
                "name": "Genoni",
                "countryCode": "IT"
            },
            {
                "name": "Genuri",
                "countryCode": "IT"
            },
            {
                "name": "Gergei",
                "countryCode": "IT"
            },
            {
                "name": "Gesico",
                "countryCode": "IT"
            },
            {
                "name": "Gesturi",
                "countryCode": "IT"
            },
            {
                "name": "Ghilarza",
                "countryCode": "IT"
            },
            {
                "name": "Giave",
                "countryCode": "IT"
            },
            {
                "name": "Giba",
                "countryCode": "IT"
            },
            {
                "name": "Girasole",
                "countryCode": "IT"
            },
            {
                "name": "Golfo Aranci",
                "countryCode": "IT"
            },
            {
                "name": "Goni",
                "countryCode": "IT"
            },
            {
                "name": "Gonnesa",
                "countryCode": "IT"
            },
            {
                "name": "Gonnoscodina",
                "countryCode": "IT"
            },
            {
                "name": "Gonnosfanadiga",
                "countryCode": "IT"
            },
            {
                "name": "Gonnosnò",
                "countryCode": "IT"
            },
            {
                "name": "Gonnostramatza",
                "countryCode": "IT"
            },
            {
                "name": "Guamaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Guasila",
                "countryCode": "IT"
            },
            {
                "name": "Guspini",
                "countryCode": "IT"
            },
            {
                "name": "Iglesias",
                "countryCode": "IT"
            },
            {
                "name": "Ilbono",
                "countryCode": "IT"
            },
            {
                "name": "Illorai",
                "countryCode": "IT"
            },
            {
                "name": "Irgoli",
                "countryCode": "IT"
            },
            {
                "name": "Isili",
                "countryCode": "IT"
            },
            {
                "name": "Ittireddu",
                "countryCode": "IT"
            },
            {
                "name": "Ittiri",
                "countryCode": "IT"
            },
            {
                "name": "Jerzu",
                "countryCode": "IT"
            },
            {
                "name": "La Caletta",
                "countryCode": "IT"
            },
            {
                "name": "La Maddalena",
                "countryCode": "IT"
            },
            {
                "name": "Laconi",
                "countryCode": "IT"
            },
            {
                "name": "Laerru",
                "countryCode": "IT"
            },
            {
                "name": "Lanusei",
                "countryCode": "IT"
            },
            {
                "name": "Las Plassas",
                "countryCode": "IT"
            },
            {
                "name": "Lei",
                "countryCode": "IT"
            },
            {
                "name": "Li Punti-San Giovanni",
                "countryCode": "IT"
            },
            {
                "name": "Loceri",
                "countryCode": "IT"
            },
            {
                "name": "Loculi",
                "countryCode": "IT"
            },
            {
                "name": "Lodine",
                "countryCode": "IT"
            },
            {
                "name": "Lodè",
                "countryCode": "IT"
            },
            {
                "name": "Loiri Porto San Paolo",
                "countryCode": "IT"
            },
            {
                "name": "Lotzorai",
                "countryCode": "IT"
            },
            {
                "name": "Lu Bagnu",
                "countryCode": "IT"
            },
            {
                "name": "Lula",
                "countryCode": "IT"
            },
            {
                "name": "Lunamatrona",
                "countryCode": "IT"
            },
            {
                "name": "Luogosanto",
                "countryCode": "IT"
            },
            {
                "name": "Luras",
                "countryCode": "IT"
            },
            {
                "name": "Macomer",
                "countryCode": "IT"
            },
            {
                "name": "Magomadas",
                "countryCode": "IT"
            },
            {
                "name": "Mamoiada",
                "countryCode": "IT"
            },
            {
                "name": "Mandas",
                "countryCode": "IT"
            },
            {
                "name": "Mara",
                "countryCode": "IT"
            },
            {
                "name": "Maracalagonis",
                "countryCode": "IT"
            },
            {
                "name": "Marrubiu",
                "countryCode": "IT"
            },
            {
                "name": "Martis",
                "countryCode": "IT"
            },
            {
                "name": "Masainas",
                "countryCode": "IT"
            },
            {
                "name": "Masullas",
                "countryCode": "IT"
            },
            {
                "name": "Meana Sardo",
                "countryCode": "IT"
            },
            {
                "name": "Milis",
                "countryCode": "IT"
            },
            {
                "name": "Modolo",
                "countryCode": "IT"
            },
            {
                "name": "Mogorella",
                "countryCode": "IT"
            },
            {
                "name": "Mogoro",
                "countryCode": "IT"
            },
            {
                "name": "Monastir",
                "countryCode": "IT"
            },
            {
                "name": "Monserrato",
                "countryCode": "IT"
            },
            {
                "name": "Monteleone Rocca Doria",
                "countryCode": "IT"
            },
            {
                "name": "Monti",
                "countryCode": "IT"
            },
            {
                "name": "Montresta",
                "countryCode": "IT"
            },
            {
                "name": "Mores",
                "countryCode": "IT"
            },
            {
                "name": "Morgongiori",
                "countryCode": "IT"
            },
            {
                "name": "Muravera",
                "countryCode": "IT"
            },
            {
                "name": "Muros",
                "countryCode": "IT"
            },
            {
                "name": "Musei",
                "countryCode": "IT"
            },
            {
                "name": "Narbolia",
                "countryCode": "IT"
            },
            {
                "name": "Narcao",
                "countryCode": "IT"
            },
            {
                "name": "Neoneli",
                "countryCode": "IT"
            },
            {
                "name": "Noragugume",
                "countryCode": "IT"
            },
            {
                "name": "Norbello",
                "countryCode": "IT"
            },
            {
                "name": "Nughedu San Nicolò",
                "countryCode": "IT"
            },
            {
                "name": "Nughedu Santa Vittoria",
                "countryCode": "IT"
            },
            {
                "name": "Nule",
                "countryCode": "IT"
            },
            {
                "name": "Nulvi",
                "countryCode": "IT"
            },
            {
                "name": "Nuoro",
                "countryCode": "IT"
            },
            {
                "name": "Nurachi",
                "countryCode": "IT"
            },
            {
                "name": "Nuragus",
                "countryCode": "IT"
            },
            {
                "name": "Nurallao",
                "countryCode": "IT"
            },
            {
                "name": "Nuraminis",
                "countryCode": "IT"
            },
            {
                "name": "Nuraxinieddu",
                "countryCode": "IT"
            },
            {
                "name": "Nureci",
                "countryCode": "IT"
            },
            {
                "name": "Nurri",
                "countryCode": "IT"
            },
            {
                "name": "Nuxis",
                "countryCode": "IT"
            },
            {
                "name": "Olbia",
                "countryCode": "IT"
            },
            {
                "name": "Olia Speciosa",
                "countryCode": "IT"
            },
            {
                "name": "Oliena",
                "countryCode": "IT"
            },
            {
                "name": "Ollastra",
                "countryCode": "IT"
            },
            {
                "name": "Ollolai",
                "countryCode": "IT"
            },
            {
                "name": "Olmedo",
                "countryCode": "IT"
            },
            {
                "name": "Olzai",
                "countryCode": "IT"
            },
            {
                "name": "Onanì",
                "countryCode": "IT"
            },
            {
                "name": "Onifai",
                "countryCode": "IT"
            },
            {
                "name": "Oniferi",
                "countryCode": "IT"
            },
            {
                "name": "Orani",
                "countryCode": "IT"
            },
            {
                "name": "Orgosolo",
                "countryCode": "IT"
            },
            {
                "name": "Oristano",
                "countryCode": "IT"
            },
            {
                "name": "Orosei",
                "countryCode": "IT"
            },
            {
                "name": "Orotelli",
                "countryCode": "IT"
            },
            {
                "name": "Orroli",
                "countryCode": "IT"
            },
            {
                "name": "Ortacesus",
                "countryCode": "IT"
            },
            {
                "name": "Ortueri",
                "countryCode": "IT"
            },
            {
                "name": "Orune",
                "countryCode": "IT"
            },
            {
                "name": "Oschiri",
                "countryCode": "IT"
            },
            {
                "name": "Osidda",
                "countryCode": "IT"
            },
            {
                "name": "Osilo",
                "countryCode": "IT"
            },
            {
                "name": "Osini",
                "countryCode": "IT"
            },
            {
                "name": "Ossi",
                "countryCode": "IT"
            },
            {
                "name": "Ottana",
                "countryCode": "IT"
            },
            {
                "name": "Ottava",
                "countryCode": "IT"
            },
            {
                "name": "Ovodda",
                "countryCode": "IT"
            },
            {
                "name": "Ozieri",
                "countryCode": "IT"
            },
            {
                "name": "Pabillonis",
                "countryCode": "IT"
            },
            {
                "name": "Padria",
                "countryCode": "IT"
            },
            {
                "name": "Padru",
                "countryCode": "IT"
            },
            {
                "name": "Palau",
                "countryCode": "IT"
            },
            {
                "name": "Palmas Arborea",
                "countryCode": "IT"
            },
            {
                "name": "Pattada",
                "countryCode": "IT"
            },
            {
                "name": "Pau",
                "countryCode": "IT"
            },
            {
                "name": "Pauli Arbarei",
                "countryCode": "IT"
            },
            {
                "name": "Paulilatino",
                "countryCode": "IT"
            },
            {
                "name": "Perdasdefogu",
                "countryCode": "IT"
            },
            {
                "name": "Perdaxius",
                "countryCode": "IT"
            },
            {
                "name": "Perfugas",
                "countryCode": "IT"
            },
            {
                "name": "Pimentel",
                "countryCode": "IT"
            },
            {
                "name": "Piscinas",
                "countryCode": "IT"
            },
            {
                "name": "Ploaghe",
                "countryCode": "IT"
            },
            {
                "name": "Poggio dei Pini",
                "countryCode": "IT"
            },
            {
                "name": "Pompu",
                "countryCode": "IT"
            },
            {
                "name": "Porto Cervo",
                "countryCode": "IT"
            },
            {
                "name": "Porto Torres",
                "countryCode": "IT"
            },
            {
                "name": "Portoscuso",
                "countryCode": "IT"
            },
            {
                "name": "Posada",
                "countryCode": "IT"
            },
            {
                "name": "Pozzomaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Cagliari",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Nuoro",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Oristano",
                "countryCode": "IT"
            },
            {
                "name": "Provincia di Sassari",
                "countryCode": "IT"
            },
            {
                "name": "Pula",
                "countryCode": "IT"
            },
            {
                "name": "Putifigari",
                "countryCode": "IT"
            },
            {
                "name": "Quartu Sant'Elena",
                "countryCode": "IT"
            },
            {
                "name": "Quartucciu",
                "countryCode": "IT"
            },
            {
                "name": "Riola Sardo",
                "countryCode": "IT"
            },
            {
                "name": "Romana",
                "countryCode": "IT"
            },
            {
                "name": "Ruinas",
                "countryCode": "IT"
            },
            {
                "name": "Sadali",
                "countryCode": "IT"
            },
            {
                "name": "Sagama",
                "countryCode": "IT"
            },
            {
                "name": "Samassi",
                "countryCode": "IT"
            },
            {
                "name": "Samatzai",
                "countryCode": "IT"
            },
            {
                "name": "Samugheo",
                "countryCode": "IT"
            },
            {
                "name": "San Basilio",
                "countryCode": "IT"
            },
            {
                "name": "San Gavino Monreale",
                "countryCode": "IT"
            },
            {
                "name": "San Giovanni Suergiu",
                "countryCode": "IT"
            },
            {
                "name": "San Nicola",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolo'Gerrei",
                "countryCode": "IT"
            },
            {
                "name": "San Nicolò d'Arcidano",
                "countryCode": "IT"
            },
            {
                "name": "San Sperate",
                "countryCode": "IT"
            },
            {
                "name": "San Teodoro",
                "countryCode": "IT"
            },
            {
                "name": "San Vero Milis",
                "countryCode": "IT"
            },
            {
                "name": "San Vito",
                "countryCode": "IT"
            },
            {
                "name": "Sanluri",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Andrea Frius",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Anna Arresi",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antioco",
                "countryCode": "IT"
            },
            {
                "name": "Sant'Antonio di Gallura",
                "countryCode": "IT"
            },
            {
                "name": "Santa Giusta",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Coghinas",
                "countryCode": "IT"
            },
            {
                "name": "Santa Maria Navarrese",
                "countryCode": "IT"
            },
            {
                "name": "Santa Teresa Gallura",
                "countryCode": "IT"
            },
            {
                "name": "Santadi",
                "countryCode": "IT"
            },
            {
                "name": "Santu Lussurgiu",
                "countryCode": "IT"
            },
            {
                "name": "Sardara",
                "countryCode": "IT"
            },
            {
                "name": "Sarroch",
                "countryCode": "IT"
            },
            {
                "name": "Sarule",
                "countryCode": "IT"
            },
            {
                "name": "Sassari",
                "countryCode": "IT"
            },
            {
                "name": "Scano di Montiferro",
                "countryCode": "IT"
            },
            {
                "name": "Sedilo",
                "countryCode": "IT"
            },
            {
                "name": "Sedini",
                "countryCode": "IT"
            },
            {
                "name": "Segariu",
                "countryCode": "IT"
            },
            {
                "name": "Selargius",
                "countryCode": "IT"
            },
            {
                "name": "Selegas",
                "countryCode": "IT"
            },
            {
                "name": "Semestene",
                "countryCode": "IT"
            },
            {
                "name": "Seneghe",
                "countryCode": "IT"
            },
            {
                "name": "Senis",
                "countryCode": "IT"
            },
            {
                "name": "Sennariolo",
                "countryCode": "IT"
            },
            {
                "name": "Sennori",
                "countryCode": "IT"
            },
            {
                "name": "Senorbì",
                "countryCode": "IT"
            },
            {
                "name": "Serdiana",
                "countryCode": "IT"
            },
            {
                "name": "Serramanna",
                "countryCode": "IT"
            },
            {
                "name": "Serrenti",
                "countryCode": "IT"
            },
            {
                "name": "Serri",
                "countryCode": "IT"
            },
            {
                "name": "Sestu",
                "countryCode": "IT"
            },
            {
                "name": "Settimo San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Setzu",
                "countryCode": "IT"
            },
            {
                "name": "Seui",
                "countryCode": "IT"
            },
            {
                "name": "Seulo",
                "countryCode": "IT"
            },
            {
                "name": "Siamaggiore",
                "countryCode": "IT"
            },
            {
                "name": "Siamanna",
                "countryCode": "IT"
            },
            {
                "name": "Siapiccia",
                "countryCode": "IT"
            },
            {
                "name": "Siddi",
                "countryCode": "IT"
            },
            {
                "name": "Silanus",
                "countryCode": "IT"
            },
            {
                "name": "Siligo",
                "countryCode": "IT"
            },
            {
                "name": "Siliqua",
                "countryCode": "IT"
            },
            {
                "name": "Silius",
                "countryCode": "IT"
            },
            {
                "name": "Silì",
                "countryCode": "IT"
            },
            {
                "name": "Simala",
                "countryCode": "IT"
            },
            {
                "name": "Simaxis",
                "countryCode": "IT"
            },
            {
                "name": "Sindia",
                "countryCode": "IT"
            },
            {
                "name": "Sini",
                "countryCode": "IT"
            },
            {
                "name": "Siniscola",
                "countryCode": "IT"
            },
            {
                "name": "Sinnai",
                "countryCode": "IT"
            },
            {
                "name": "Siris",
                "countryCode": "IT"
            },
            {
                "name": "Siurgus Donigala",
                "countryCode": "IT"
            },
            {
                "name": "Soddì",
                "countryCode": "IT"
            },
            {
                "name": "Solanas",
                "countryCode": "IT"
            },
            {
                "name": "Solarussa",
                "countryCode": "IT"
            },
            {
                "name": "Soleminis",
                "countryCode": "IT"
            },
            {
                "name": "Sorgono",
                "countryCode": "IT"
            },
            {
                "name": "Sorradile",
                "countryCode": "IT"
            },
            {
                "name": "Sorso",
                "countryCode": "IT"
            },
            {
                "name": "Stintino",
                "countryCode": "IT"
            },
            {
                "name": "Su Planu",
                "countryCode": "IT"
            },
            {
                "name": "Suelli",
                "countryCode": "IT"
            },
            {
                "name": "Suni",
                "countryCode": "IT"
            },
            {
                "name": "Tadasuni",
                "countryCode": "IT"
            },
            {
                "name": "Talana",
                "countryCode": "IT"
            },
            {
                "name": "Telti",
                "countryCode": "IT"
            },
            {
                "name": "Tempio Pausania",
                "countryCode": "IT"
            },
            {
                "name": "Tergu",
                "countryCode": "IT"
            },
            {
                "name": "Terralba",
                "countryCode": "IT"
            },
            {
                "name": "Tertenia",
                "countryCode": "IT"
            },
            {
                "name": "Teti",
                "countryCode": "IT"
            },
            {
                "name": "Teulada",
                "countryCode": "IT"
            },
            {
                "name": "Thiesi",
                "countryCode": "IT"
            },
            {
                "name": "Tiana",
                "countryCode": "IT"
            },
            {
                "name": "Tinnura",
                "countryCode": "IT"
            },
            {
                "name": "Tissi",
                "countryCode": "IT"
            },
            {
                "name": "Tonara",
                "countryCode": "IT"
            },
            {
                "name": "Torpè",
                "countryCode": "IT"
            },
            {
                "name": "Torralba",
                "countryCode": "IT"
            },
            {
                "name": "Tortolì",
                "countryCode": "IT"
            },
            {
                "name": "Tramatza",
                "countryCode": "IT"
            },
            {
                "name": "Tratalias",
                "countryCode": "IT"
            },
            {
                "name": "Tresnuraghes",
                "countryCode": "IT"
            },
            {
                "name": "Triei",
                "countryCode": "IT"
            },
            {
                "name": "Trinità d'Agultu",
                "countryCode": "IT"
            },
            {
                "name": "Trinità d'Agultu e Vignola",
                "countryCode": "IT"
            },
            {
                "name": "Tuili",
                "countryCode": "IT"
            },
            {
                "name": "Tula",
                "countryCode": "IT"
            },
            {
                "name": "Turri",
                "countryCode": "IT"
            },
            {
                "name": "Ulassai",
                "countryCode": "IT"
            },
            {
                "name": "Ulà Tirso",
                "countryCode": "IT"
            },
            {
                "name": "Uras",
                "countryCode": "IT"
            },
            {
                "name": "Uri",
                "countryCode": "IT"
            },
            {
                "name": "Urzulei",
                "countryCode": "IT"
            },
            {
                "name": "Usellus",
                "countryCode": "IT"
            },
            {
                "name": "Usini",
                "countryCode": "IT"
            },
            {
                "name": "Ussana",
                "countryCode": "IT"
            },
            {
                "name": "Ussaramanna",
                "countryCode": "IT"
            },
            {
                "name": "Ussassai",
                "countryCode": "IT"
            },
            {
                "name": "Uta",
                "countryCode": "IT"
            },
            {
                "name": "Valledoria",
                "countryCode": "IT"
            },
            {
                "name": "Vallermosa",
                "countryCode": "IT"
            },
            {
                "name": "Viddalba",
                "countryCode": "IT"
            },
            {
                "name": "Villa San Pietro",
                "countryCode": "IT"
            },
            {
                "name": "Villa Sant'Antonio",
                "countryCode": "IT"
            },
            {
                "name": "Villa Verde",
                "countryCode": "IT"
            },
            {
                "name": "Villacidro",
                "countryCode": "IT"
            },
            {
                "name": "Villagrande Strisaili",
                "countryCode": "IT"
            },
            {
                "name": "Villamar",
                "countryCode": "IT"
            },
            {
                "name": "Villamassargia",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Monteleone",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Truschedu",
                "countryCode": "IT"
            },
            {
                "name": "Villanova Tulo",
                "countryCode": "IT"
            },
            {
                "name": "Villanovaforru",
                "countryCode": "IT"
            },
            {
                "name": "Villanovafranca",
                "countryCode": "IT"
            },
            {
                "name": "Villaperuccio",
                "countryCode": "IT"
            },
            {
                "name": "Villaputzu",
                "countryCode": "IT"
            },
            {
                "name": "Villasalto",
                "countryCode": "IT"
            },
            {
                "name": "Villasimius",
                "countryCode": "IT"
            },
            {
                "name": "Villasor",
                "countryCode": "IT"
            },
            {
                "name": "Villaspeciosa",
                "countryCode": "IT"
            },
            {
                "name": "Villaurbana",
                "countryCode": "IT"
            },
            {
                "name": "Zeddiani",
                "countryCode": "IT"
            },
            {
                "name": "Zerfaliu",
                "countryCode": "IT"
            }
        ]
    },
    {
        "name": "Jamaica",
        "phonecode": "+1-876",
        "cities": [
            {
                "name": "Allman Town",
                "countryCode": "JM"
            },
            {
                "name": "Bournemouth Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Campbell Town",
                "countryCode": "JM"
            },
            {
                "name": "Central Down Town",
                "countryCode": "JM"
            },
            {
                "name": "D'Aguilar Town/ Rennock Lodge",
                "countryCode": "JM"
            },
            {
                "name": "Denham Town",
                "countryCode": "JM"
            },
            {
                "name": "East Down Town",
                "countryCode": "JM"
            },
            {
                "name": "Fletchers Land",
                "countryCode": "JM"
            },
            {
                "name": "Franklyn Town",
                "countryCode": "JM"
            },
            {
                "name": "Hannah Town/ Craig Town",
                "countryCode": "JM"
            },
            {
                "name": "Johnson Town",
                "countryCode": "JM"
            },
            {
                "name": "Kingston",
                "countryCode": "JM"
            },
            {
                "name": "Kingston Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Manley Meadows",
                "countryCode": "JM"
            },
            {
                "name": "Newport East",
                "countryCode": "JM"
            },
            {
                "name": "Newton Square",
                "countryCode": "JM"
            },
            {
                "name": "Norman Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Passmore Town",
                "countryCode": "JM"
            },
            {
                "name": "Port Royal",
                "countryCode": "JM"
            },
            {
                "name": "Rae Town",
                "countryCode": "JM"
            },
            {
                "name": "Rollington Town",
                "countryCode": "JM"
            },
            {
                "name": "Southside",
                "countryCode": "JM"
            },
            {
                "name": "Springfield",
                "countryCode": "JM"
            },
            {
                "name": "Tivoli Gardens",
                "countryCode": "JM"
            },
            {
                "name": "West Down Town",
                "countryCode": "JM"
            },
            {
                "name": "Arcadia",
                "countryCode": "JM"
            },
            {
                "name": "Arlene Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Arnett Gardens",
                "countryCode": "JM"
            },
            {
                "name": "August Town",
                "countryCode": "JM"
            },
            {
                "name": "Barbican",
                "countryCode": "JM"
            },
            {
                "name": "Beverley Hills",
                "countryCode": "JM"
            },
            {
                "name": "Bito",
                "countryCode": "JM"
            },
            {
                "name": "Bloxborough",
                "countryCode": "JM"
            },
            {
                "name": "Boucher Park",
                "countryCode": "JM"
            },
            {
                "name": "Brandon Hill",
                "countryCode": "JM"
            },
            {
                "name": "Bull Bay/ Seven Mile",
                "countryCode": "JM"
            },
            {
                "name": "Cane River",
                "countryCode": "JM"
            },
            {
                "name": "Cassia Park",
                "countryCode": "JM"
            },
            {
                "name": "Cavaliers",
                "countryCode": "JM"
            },
            {
                "name": "Cherry Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Cockburn Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Constant Spring",
                "countryCode": "JM"
            },
            {
                "name": "Constant Spring Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Constitution Hill",
                "countryCode": "JM"
            },
            {
                "name": "Content Gap",
                "countryCode": "JM"
            },
            {
                "name": "Cooreville Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Cross Roads",
                "countryCode": "JM"
            },
            {
                "name": "Dallas",
                "countryCode": "JM"
            },
            {
                "name": "Delacree Park/ Union Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Delacree Pen",
                "countryCode": "JM"
            },
            {
                "name": "Drewsland",
                "countryCode": "JM"
            },
            {
                "name": "Drumblair",
                "countryCode": "JM"
            },
            {
                "name": "Duhaney Park",
                "countryCode": "JM"
            },
            {
                "name": "Eastwood Park Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Elleston Flats/ Mona Commons",
                "countryCode": "JM"
            },
            {
                "name": "Ferry",
                "countryCode": "JM"
            },
            {
                "name": "Forest Hills Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Forest Hills/ Plantation Height",
                "countryCode": "JM"
            },
            {
                "name": "Golden Spring",
                "countryCode": "JM"
            },
            {
                "name": "Gordon Town",
                "countryCode": "JM"
            },
            {
                "name": "Grants Pen",
                "countryCode": "JM"
            },
            {
                "name": "Greenwich Town/ Newport West",
                "countryCode": "JM"
            },
            {
                "name": "Half Way Tree",
                "countryCode": "JM"
            },
            {
                "name": "Half-Way-Tree",
                "countryCode": "JM"
            },
            {
                "name": "Harbour View",
                "countryCode": "JM"
            },
            {
                "name": "Havendale",
                "countryCode": "JM"
            },
            {
                "name": "Hermitage",
                "countryCode": "JM"
            },
            {
                "name": "Hope Pastures/ UTECH",
                "countryCode": "JM"
            },
            {
                "name": "Hope Tavern",
                "countryCode": "JM"
            },
            {
                "name": "Hughenden",
                "countryCode": "JM"
            },
            {
                "name": "Irish Town",
                "countryCode": "JM"
            },
            {
                "name": "Jacks Hill",
                "countryCode": "JM"
            },
            {
                "name": "Jones Town",
                "countryCode": "JM"
            },
            {
                "name": "Kencot",
                "countryCode": "JM"
            },
            {
                "name": "Kintyre",
                "countryCode": "JM"
            },
            {
                "name": "Lawrence Tavern",
                "countryCode": "JM"
            },
            {
                "name": "Liguanea",
                "countryCode": "JM"
            },
            {
                "name": "Majestic Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Mannings Hill",
                "countryCode": "JM"
            },
            {
                "name": "Mannings Hill (Rural)",
                "countryCode": "JM"
            },
            {
                "name": "Marverley",
                "countryCode": "JM"
            },
            {
                "name": "Maryland",
                "countryCode": "JM"
            },
            {
                "name": "Mavis Bank",
                "countryCode": "JM"
            },
            {
                "name": "Maxfield Park",
                "countryCode": "JM"
            },
            {
                "name": "Meadowbrook",
                "countryCode": "JM"
            },
            {
                "name": "Meadowbrook Estate",
                "countryCode": "JM"
            },
            {
                "name": "Molynes Four Roads",
                "countryCode": "JM"
            },
            {
                "name": "Molynes Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Mona Heights",
                "countryCode": "JM"
            },
            {
                "name": "Mount James",
                "countryCode": "JM"
            },
            {
                "name": "Mountain View Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Nannyville",
                "countryCode": "JM"
            },
            {
                "name": "New Haven",
                "countryCode": "JM"
            },
            {
                "name": "New Kingston",
                "countryCode": "JM"
            },
            {
                "name": "Norbrook",
                "countryCode": "JM"
            },
            {
                "name": "Oaklands",
                "countryCode": "JM"
            },
            {
                "name": "Papine",
                "countryCode": "JM"
            },
            {
                "name": "Parks Road",
                "countryCode": "JM"
            },
            {
                "name": "Patrick City",
                "countryCode": "JM"
            },
            {
                "name": "Pembroke Hall",
                "countryCode": "JM"
            },
            {
                "name": "Penwood",
                "countryCode": "JM"
            },
            {
                "name": "Queensborough/ Tunbridge",
                "countryCode": "JM"
            },
            {
                "name": "Red Hills (Rural)",
                "countryCode": "JM"
            },
            {
                "name": "Red Hills Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Red Hills/ Sterling Castle",
                "countryCode": "JM"
            },
            {
                "name": "Richmond Park",
                "countryCode": "JM"
            },
            {
                "name": "Riverton City",
                "countryCode": "JM"
            },
            {
                "name": "Roehampton",
                "countryCode": "JM"
            },
            {
                "name": "Rose Town",
                "countryCode": "JM"
            },
            {
                "name": "Seaview Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Seaward Pen",
                "countryCode": "JM"
            },
            {
                "name": "Seymour Lands",
                "countryCode": "JM"
            },
            {
                "name": "St. Peter's",
                "countryCode": "JM"
            },
            {
                "name": "Stadium Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Stony Hill",
                "countryCode": "JM"
            },
            {
                "name": "Swallowfield",
                "countryCode": "JM"
            },
            {
                "name": "Temple Hall",
                "countryCode": "JM"
            },
            {
                "name": "Three Oaks/ Glendale",
                "countryCode": "JM"
            },
            {
                "name": "Tower Hill",
                "countryCode": "JM"
            },
            {
                "name": "Trafalgar Park",
                "countryCode": "JM"
            },
            {
                "name": "Trench Town",
                "countryCode": "JM"
            },
            {
                "name": "University",
                "countryCode": "JM"
            },
            {
                "name": "Vineyard Town",
                "countryCode": "JM"
            },
            {
                "name": "Waltham Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Washington Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Waterhouse",
                "countryCode": "JM"
            },
            {
                "name": "Whitehall",
                "countryCode": "JM"
            },
            {
                "name": "Whitfield Town",
                "countryCode": "JM"
            },
            {
                "name": "Wilton Gardens/ Rema",
                "countryCode": "JM"
            },
            {
                "name": "Woodford",
                "countryCode": "JM"
            },
            {
                "name": "Woodford Park",
                "countryCode": "JM"
            },
            {
                "name": "Zaidie Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Airy Castle",
                "countryCode": "JM"
            },
            {
                "name": "Albion",
                "countryCode": "JM"
            },
            {
                "name": "Amity Hall",
                "countryCode": "JM"
            },
            {
                "name": "Arcadia",
                "countryCode": "JM"
            },
            {
                "name": "Baptist",
                "countryCode": "JM"
            },
            {
                "name": "Barking Lodge",
                "countryCode": "JM"
            },
            {
                "name": "Bath",
                "countryCode": "JM"
            },
            {
                "name": "Bethel",
                "countryCode": "JM"
            },
            {
                "name": "Cedar Valley",
                "countryCode": "JM"
            },
            {
                "name": "Church Corner",
                "countryCode": "JM"
            },
            {
                "name": "Dalvey",
                "countryCode": "JM"
            },
            {
                "name": "Danvers Pen",
                "countryCode": "JM"
            },
            {
                "name": "Duckenfield",
                "countryCode": "JM"
            },
            {
                "name": "Duhaney Pen",
                "countryCode": "JM"
            },
            {
                "name": "Easington",
                "countryCode": "JM"
            },
            {
                "name": "Eleven Miles",
                "countryCode": "JM"
            },
            {
                "name": "Font Hill",
                "countryCode": "JM"
            },
            {
                "name": "Golden Grove",
                "countryCode": "JM"
            },
            {
                "name": "Hagley Gap",
                "countryCode": "JM"
            },
            {
                "name": "Hampton Court",
                "countryCode": "JM"
            },
            {
                "name": "Hayfield",
                "countryCode": "JM"
            },
            {
                "name": "Hillside",
                "countryCode": "JM"
            },
            {
                "name": "Johns Town",
                "countryCode": "JM"
            },
            {
                "name": "Johnson Mountain",
                "countryCode": "JM"
            },
            {
                "name": "Jones Pen",
                "countryCode": "JM"
            },
            {
                "name": "Leith Hall",
                "countryCode": "JM"
            },
            {
                "name": "Llandewey",
                "countryCode": "JM"
            },
            {
                "name": "Lloyds",
                "countryCode": "JM"
            },
            {
                "name": "Lyssons",
                "countryCode": "JM"
            },
            {
                "name": "Middleton",
                "countryCode": "JM"
            },
            {
                "name": "Morant Bay",
                "countryCode": "JM"
            },
            {
                "name": "Mount Lebanus",
                "countryCode": "JM"
            },
            {
                "name": "New Monkland",
                "countryCode": "JM"
            },
            {
                "name": "Pamphret",
                "countryCode": "JM"
            },
            {
                "name": "Pear Tree",
                "countryCode": "JM"
            },
            {
                "name": "Poormans Corner",
                "countryCode": "JM"
            },
            {
                "name": "Port Morant",
                "countryCode": "JM"
            },
            {
                "name": "Prospect",
                "countryCode": "JM"
            },
            {
                "name": "Ramble",
                "countryCode": "JM"
            },
            {
                "name": "Retreat",
                "countryCode": "JM"
            },
            {
                "name": "Richmond Vale",
                "countryCode": "JM"
            },
            {
                "name": "River Head",
                "countryCode": "JM"
            },
            {
                "name": "Rowlandsfield",
                "countryCode": "JM"
            },
            {
                "name": "Seaforth",
                "countryCode": "JM"
            },
            {
                "name": "Somerset",
                "countryCode": "JM"
            },
            {
                "name": "Spring Bank",
                "countryCode": "JM"
            },
            {
                "name": "Spring Garden",
                "countryCode": "JM"
            },
            {
                "name": "Sunning Hill",
                "countryCode": "JM"
            },
            {
                "name": "Trinity Ville",
                "countryCode": "JM"
            },
            {
                "name": "Trinityville",
                "countryCode": "JM"
            },
            {
                "name": "Wheelerfield",
                "countryCode": "JM"
            },
            {
                "name": "White Hall",
                "countryCode": "JM"
            },
            {
                "name": "White Horses",
                "countryCode": "JM"
            },
            {
                "name": "Wilmington",
                "countryCode": "JM"
            },
            {
                "name": "Winchester",
                "countryCode": "JM"
            },
            {
                "name": "Yallahs",
                "countryCode": "JM"
            },
            {
                "name": "York",
                "countryCode": "JM"
            },
            {
                "name": "Balcarres",
                "countryCode": "JM"
            },
            {
                "name": "Bangor Ridge",
                "countryCode": "JM"
            },
            {
                "name": "Belvedere",
                "countryCode": "JM"
            },
            {
                "name": "Black Hill",
                "countryCode": "JM"
            },
            {
                "name": "Boundbrook",
                "countryCode": "JM"
            },
            {
                "name": "Breastworks",
                "countryCode": "JM"
            },
            {
                "name": "Buff Bay",
                "countryCode": "JM"
            },
            {
                "name": "Bybrook",
                "countryCode": "JM"
            },
            {
                "name": "Cascade",
                "countryCode": "JM"
            },
            {
                "name": "Castle Comfort",
                "countryCode": "JM"
            },
            {
                "name": "Central Port Antonio",
                "countryCode": "JM"
            },
            {
                "name": "Charles Town",
                "countryCode": "JM"
            },
            {
                "name": "Claverty Cottage",
                "countryCode": "JM"
            },
            {
                "name": "Comfort Castle",
                "countryCode": "JM"
            },
            {
                "name": "Drapers",
                "countryCode": "JM"
            },
            {
                "name": "Durham",
                "countryCode": "JM"
            },
            {
                "name": "Fairy Hill",
                "countryCode": "JM"
            },
            {
                "name": "Fellowship",
                "countryCode": "JM"
            },
            {
                "name": "Fruitfulvale",
                "countryCode": "JM"
            },
            {
                "name": "Hectors River",
                "countryCode": "JM"
            },
            {
                "name": "Hope Bay",
                "countryCode": "JM"
            },
            {
                "name": "Kensington",
                "countryCode": "JM"
            },
            {
                "name": "Long Bay",
                "countryCode": "JM"
            },
            {
                "name": "Long Road",
                "countryCode": "JM"
            },
            {
                "name": "Manchioneal",
                "countryCode": "JM"
            },
            {
                "name": "Moore Town",
                "countryCode": "JM"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "JM"
            },
            {
                "name": "Nonsuch",
                "countryCode": "JM"
            },
            {
                "name": "Norwich",
                "countryCode": "JM"
            },
            {
                "name": "Orange Bay",
                "countryCode": "JM"
            },
            {
                "name": "Port Antonio",
                "countryCode": "JM"
            },
            {
                "name": "Prospect",
                "countryCode": "JM"
            },
            {
                "name": "Rock Hall",
                "countryCode": "JM"
            },
            {
                "name": "Sherwood Forest",
                "countryCode": "JM"
            },
            {
                "name": "Shirley Castle",
                "countryCode": "JM"
            },
            {
                "name": "Skibo",
                "countryCode": "JM"
            },
            {
                "name": "Snow Hill",
                "countryCode": "JM"
            },
            {
                "name": "Spring Hill",
                "countryCode": "JM"
            },
            {
                "name": "St. Margaret's Bay",
                "countryCode": "JM"
            },
            {
                "name": "Swift River",
                "countryCode": "JM"
            },
            {
                "name": "Tranquility",
                "countryCode": "JM"
            },
            {
                "name": "Windsor",
                "countryCode": "JM"
            },
            {
                "name": "Windsor Castle",
                "countryCode": "JM"
            },
            {
                "name": "Windsor Forest",
                "countryCode": "JM"
            },
            {
                "name": "Amiel Town",
                "countryCode": "JM"
            },
            {
                "name": "Annotto Bay",
                "countryCode": "JM"
            },
            {
                "name": "Baileys Vale",
                "countryCode": "JM"
            },
            {
                "name": "Baxter Mountain",
                "countryCode": "JM"
            },
            {
                "name": "Belfield",
                "countryCode": "JM"
            },
            {
                "name": "Bonnygate",
                "countryCode": "JM"
            },
            {
                "name": "Brainerd",
                "countryCode": "JM"
            },
            {
                "name": "Broadgate",
                "countryCode": "JM"
            },
            {
                "name": "Camberwell",
                "countryCode": "JM"
            },
            {
                "name": "Carron Hall",
                "countryCode": "JM"
            },
            {
                "name": "Castleton",
                "countryCode": "JM"
            },
            {
                "name": "Charles Town",
                "countryCode": "JM"
            },
            {
                "name": "Clonmel",
                "countryCode": "JM"
            },
            {
                "name": "Devon Pen",
                "countryCode": "JM"
            },
            {
                "name": "Dover",
                "countryCode": "JM"
            },
            {
                "name": "Enfield",
                "countryCode": "JM"
            },
            {
                "name": "Epson",
                "countryCode": "JM"
            },
            {
                "name": "Flint River",
                "countryCode": "JM"
            },
            {
                "name": "Free Hill",
                "countryCode": "JM"
            },
            {
                "name": "Friendship Gap",
                "countryCode": "JM"
            },
            {
                "name": "Galina",
                "countryCode": "JM"
            },
            {
                "name": "Gayle",
                "countryCode": "JM"
            },
            {
                "name": "Guys Hill",
                "countryCode": "JM"
            },
            {
                "name": "Hampstead",
                "countryCode": "JM"
            },
            {
                "name": "Highgate",
                "countryCode": "JM"
            },
            {
                "name": "Islington",
                "countryCode": "JM"
            },
            {
                "name": "Jacks River",
                "countryCode": "JM"
            },
            {
                "name": "Jeffery Town",
                "countryCode": "JM"
            },
            {
                "name": "Labyrinth",
                "countryCode": "JM"
            },
            {
                "name": "Long Road",
                "countryCode": "JM"
            },
            {
                "name": "Lucky Hill",
                "countryCode": "JM"
            },
            {
                "name": "Mango Valley",
                "countryCode": "JM"
            },
            {
                "name": "Martin",
                "countryCode": "JM"
            },
            {
                "name": "Mason Hall",
                "countryCode": "JM"
            },
            {
                "name": "Mile Gully",
                "countryCode": "JM"
            },
            {
                "name": "Oracabessa",
                "countryCode": "JM"
            },
            {
                "name": "Port Maria",
                "countryCode": "JM"
            },
            {
                "name": "Prospect",
                "countryCode": "JM"
            },
            {
                "name": "Retreat",
                "countryCode": "JM"
            },
            {
                "name": "Richmond",
                "countryCode": "JM"
            },
            {
                "name": "Robins Bay",
                "countryCode": "JM"
            },
            {
                "name": "Sandside",
                "countryCode": "JM"
            },
            {
                "name": "Scotts Hall",
                "countryCode": "JM"
            },
            {
                "name": "Woodpark",
                "countryCode": "JM"
            },
            {
                "name": "Woodside",
                "countryCode": "JM"
            },
            {
                "name": "Aboukir",
                "countryCode": "JM"
            },
            {
                "name": "Alderton",
                "countryCode": "JM"
            },
            {
                "name": "Alexandria",
                "countryCode": "JM"
            },
            {
                "name": "Alva",
                "countryCode": "JM"
            },
            {
                "name": "Bamboo",
                "countryCode": "JM"
            },
            {
                "name": "Beecher Town",
                "countryCode": "JM"
            },
            {
                "name": "Bensonton",
                "countryCode": "JM"
            },
            {
                "name": "Blackstonedge",
                "countryCode": "JM"
            },
            {
                "name": "Bohemia",
                "countryCode": "JM"
            },
            {
                "name": "Borobridge",
                "countryCode": "JM"
            },
            {
                "name": "Browns Town",
                "countryCode": "JM"
            },
            {
                "name": "Calderwood",
                "countryCode": "JM"
            },
            {
                "name": "Cascade",
                "countryCode": "JM"
            },
            {
                "name": "Cave Valley",
                "countryCode": "JM"
            },
            {
                "name": "Chalky Hill",
                "countryCode": "JM"
            },
            {
                "name": "Chester",
                "countryCode": "JM"
            },
            {
                "name": "Claremont",
                "countryCode": "JM"
            },
            {
                "name": "Cole Gate",
                "countryCode": "JM"
            },
            {
                "name": "Content Garden",
                "countryCode": "JM"
            },
            {
                "name": "Cuffie Ridge",
                "countryCode": "JM"
            },
            {
                "name": "Discovery Bay",
                "countryCode": "JM"
            },
            {
                "name": "Epworth",
                "countryCode": "JM"
            },
            {
                "name": "Exchange",
                "countryCode": "JM"
            },
            {
                "name": "Faith's Pen",
                "countryCode": "JM"
            },
            {
                "name": "Gibraltar",
                "countryCode": "JM"
            },
            {
                "name": "Golden Grove",
                "countryCode": "JM"
            },
            {
                "name": "Great Pond",
                "countryCode": "JM"
            },
            {
                "name": "Higgin Land",
                "countryCode": "JM"
            },
            {
                "name": "Hinds Town",
                "countryCode": "JM"
            },
            {
                "name": "Inverness",
                "countryCode": "JM"
            },
            {
                "name": "Lime Hall",
                "countryCode": "JM"
            },
            {
                "name": "Lime Tree Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Linton Park",
                "countryCode": "JM"
            },
            {
                "name": "Macknie",
                "countryCode": "JM"
            },
            {
                "name": "Madras",
                "countryCode": "JM"
            },
            {
                "name": "Mansfield",
                "countryCode": "JM"
            },
            {
                "name": "Moneague",
                "countryCode": "JM"
            },
            {
                "name": "Mount Moriah",
                "countryCode": "JM"
            },
            {
                "name": "Mount Zion",
                "countryCode": "JM"
            },
            {
                "name": "Ocho Rios",
                "countryCode": "JM"
            },
            {
                "name": "Parry Town",
                "countryCode": "JM"
            },
            {
                "name": "Philadelphia",
                "countryCode": "JM"
            },
            {
                "name": "Pimento Walk",
                "countryCode": "JM"
            },
            {
                "name": "Priory",
                "countryCode": "JM"
            },
            {
                "name": "River Head",
                "countryCode": "JM"
            },
            {
                "name": "Runaway Bay",
                "countryCode": "JM"
            },
            {
                "name": "Saint Ann’s Bay",
                "countryCode": "JM"
            },
            {
                "name": "St. Ann's Bay",
                "countryCode": "JM"
            },
            {
                "name": "St. D'Acre",
                "countryCode": "JM"
            },
            {
                "name": "Steer Town",
                "countryCode": "JM"
            },
            {
                "name": "Sturge Town",
                "countryCode": "JM"
            },
            {
                "name": "Walkers Wood",
                "countryCode": "JM"
            },
            {
                "name": "Watt Town",
                "countryCode": "JM"
            },
            {
                "name": "York Castle",
                "countryCode": "JM"
            },
            {
                "name": "Albert Town",
                "countryCode": "JM"
            },
            {
                "name": "Alps",
                "countryCode": "JM"
            },
            {
                "name": "Bounty Hall",
                "countryCode": "JM"
            },
            {
                "name": "Brampton",
                "countryCode": "JM"
            },
            {
                "name": "Bunkers Hill",
                "countryCode": "JM"
            },
            {
                "name": "Clarks Town",
                "countryCode": "JM"
            },
            {
                "name": "Daniel Town",
                "countryCode": "JM"
            },
            {
                "name": "Deeside",
                "countryCode": "JM"
            },
            {
                "name": "Duanvale",
                "countryCode": "JM"
            },
            {
                "name": "Duncans",
                "countryCode": "JM"
            },
            {
                "name": "Falmouth",
                "countryCode": "JM"
            },
            {
                "name": "Freemans Hall",
                "countryCode": "JM"
            },
            {
                "name": "Granville",
                "countryCode": "JM"
            },
            {
                "name": "Hampden",
                "countryCode": "JM"
            },
            {
                "name": "Jackson Town",
                "countryCode": "JM"
            },
            {
                "name": "Joe Hut",
                "countryCode": "JM"
            },
            {
                "name": "Kinloss",
                "countryCode": "JM"
            },
            {
                "name": "Lorrimers",
                "countryCode": "JM"
            },
            {
                "name": "Martha Brae",
                "countryCode": "JM"
            },
            {
                "name": "Perth Town",
                "countryCode": "JM"
            },
            {
                "name": "Refuge",
                "countryCode": "JM"
            },
            {
                "name": "Rio Bueno",
                "countryCode": "JM"
            },
            {
                "name": "Salt Marsh",
                "countryCode": "JM"
            },
            {
                "name": "Samuels Prospect",
                "countryCode": "JM"
            },
            {
                "name": "Sawyers",
                "countryCode": "JM"
            },
            {
                "name": "Sherwood Content",
                "countryCode": "JM"
            },
            {
                "name": "Spicy Hill",
                "countryCode": "JM"
            },
            {
                "name": "Spring Garden",
                "countryCode": "JM"
            },
            {
                "name": "St. Vincent",
                "countryCode": "JM"
            },
            {
                "name": "Stettin",
                "countryCode": "JM"
            },
            {
                "name": "Stewart Town",
                "countryCode": "JM"
            },
            {
                "name": "Troy",
                "countryCode": "JM"
            },
            {
                "name": "Ulster Spring",
                "countryCode": "JM"
            },
            {
                "name": "Wait-A-Bit",
                "countryCode": "JM"
            },
            {
                "name": "Wakefield",
                "countryCode": "JM"
            },
            {
                "name": "Warsop",
                "countryCode": "JM"
            },
            {
                "name": "Wilson's Run",
                "countryCode": "JM"
            },
            {
                "name": "Wirefence",
                "countryCode": "JM"
            },
            {
                "name": "Adelphi",
                "countryCode": "JM"
            },
            {
                "name": "Albion",
                "countryCode": "JM"
            },
            {
                "name": "Anchovy",
                "countryCode": "JM"
            },
            {
                "name": "Arcadia",
                "countryCode": "JM"
            },
            {
                "name": "Barrett Town",
                "countryCode": "JM"
            },
            {
                "name": "Bickersteth",
                "countryCode": "JM"
            },
            {
                "name": "Bogue",
                "countryCode": "JM"
            },
            {
                "name": "Brandon Hill",
                "countryCode": "JM"
            },
            {
                "name": "Cambridge",
                "countryCode": "JM"
            },
            {
                "name": "Canterbury",
                "countryCode": "JM"
            },
            {
                "name": "Catadupa",
                "countryCode": "JM"
            },
            {
                "name": "Catherine Hall",
                "countryCode": "JM"
            },
            {
                "name": "Catherine Mount",
                "countryCode": "JM"
            },
            {
                "name": "Comfort Hall",
                "countryCode": "JM"
            },
            {
                "name": "Coral Gardens",
                "countryCode": "JM"
            },
            {
                "name": "Cornwall",
                "countryCode": "JM"
            },
            {
                "name": "Down Town Montego Bay",
                "countryCode": "JM"
            },
            {
                "name": "Dumfries",
                "countryCode": "JM"
            },
            {
                "name": "Fairfield",
                "countryCode": "JM"
            },
            {
                "name": "Farm Heights",
                "countryCode": "JM"
            },
            {
                "name": "Flagstaff",
                "countryCode": "JM"
            },
            {
                "name": "Flamstead",
                "countryCode": "JM"
            },
            {
                "name": "Flankers",
                "countryCode": "JM"
            },
            {
                "name": "Flower Hill",
                "countryCode": "JM"
            },
            {
                "name": "Friendship",
                "countryCode": "JM"
            },
            {
                "name": "Garlands",
                "countryCode": "JM"
            },
            {
                "name": "Glendevon",
                "countryCode": "JM"
            },
            {
                "name": "Goodwill",
                "countryCode": "JM"
            },
            {
                "name": "Granville",
                "countryCode": "JM"
            },
            {
                "name": "Green Pond",
                "countryCode": "JM"
            },
            {
                "name": "Greenwood",
                "countryCode": "JM"
            },
            {
                "name": "Hopeton",
                "countryCode": "JM"
            },
            {
                "name": "Ironshore",
                "countryCode": "JM"
            },
            {
                "name": "Irwin",
                "countryCode": "JM"
            },
            {
                "name": "John's Hall",
                "countryCode": "JM"
            },
            {
                "name": "Lilliput",
                "countryCode": "JM"
            },
            {
                "name": "Lottery",
                "countryCode": "JM"
            },
            {
                "name": "Maldon",
                "countryCode": "JM"
            },
            {
                "name": "Maroon Town",
                "countryCode": "JM"
            },
            {
                "name": "Mocho",
                "countryCode": "JM"
            },
            {
                "name": "Montego Bay",
                "countryCode": "JM"
            },
            {
                "name": "Montego Hill",
                "countryCode": "JM"
            },
            {
                "name": "Montpelier",
                "countryCode": "JM"
            },
            {
                "name": "Mount Carey",
                "countryCode": "JM"
            },
            {
                "name": "Mount Horeb",
                "countryCode": "JM"
            },
            {
                "name": "Mount Salem",
                "countryCode": "JM"
            },
            {
                "name": "Niagara",
                "countryCode": "JM"
            },
            {
                "name": "Norwood",
                "countryCode": "JM"
            },
            {
                "name": "Orange",
                "countryCode": "JM"
            },
            {
                "name": "Paradise",
                "countryCode": "JM"
            },
            {
                "name": "Pitfour",
                "countryCode": "JM"
            },
            {
                "name": "Porto Bello",
                "countryCode": "JM"
            },
            {
                "name": "Reading",
                "countryCode": "JM"
            },
            {
                "name": "Retirement",
                "countryCode": "JM"
            },
            {
                "name": "Retrieve",
                "countryCode": "JM"
            },
            {
                "name": "Roehampton",
                "countryCode": "JM"
            },
            {
                "name": "Rose Hall",
                "countryCode": "JM"
            },
            {
                "name": "Rose Heights",
                "countryCode": "JM"
            },
            {
                "name": "Rose Mount Garden",
                "countryCode": "JM"
            },
            {
                "name": "Rosemount",
                "countryCode": "JM"
            },
            {
                "name": "Salt Spring",
                "countryCode": "JM"
            },
            {
                "name": "Seven Rivers",
                "countryCode": "JM"
            },
            {
                "name": "Somerton",
                "countryCode": "JM"
            },
            {
                "name": "Spot Valley",
                "countryCode": "JM"
            },
            {
                "name": "Stonehenge",
                "countryCode": "JM"
            },
            {
                "name": "Summer Hill",
                "countryCode": "JM"
            },
            {
                "name": "Sunderland",
                "countryCode": "JM"
            },
            {
                "name": "Tangle River",
                "countryCode": "JM"
            },
            {
                "name": "Tower Hill/Moy Hall",
                "countryCode": "JM"
            },
            {
                "name": "Tucker",
                "countryCode": "JM"
            },
            {
                "name": "Unity Hall",
                "countryCode": "JM"
            },
            {
                "name": "Vaughnsfield",
                "countryCode": "JM"
            },
            {
                "name": "Welcome Hall",
                "countryCode": "JM"
            },
            {
                "name": "West Green",
                "countryCode": "JM"
            },
            {
                "name": "Askenish",
                "countryCode": "JM"
            },
            {
                "name": "Cacoon",
                "countryCode": "JM"
            },
            {
                "name": "Cacoon Castle",
                "countryCode": "JM"
            },
            {
                "name": "Cascade",
                "countryCode": "JM"
            },
            {
                "name": "Cash Hill",
                "countryCode": "JM"
            },
            {
                "name": "Cauldwell",
                "countryCode": "JM"
            },
            {
                "name": "Chambers Pen",
                "countryCode": "JM"
            },
            {
                "name": "Chester Castle",
                "countryCode": "JM"
            },
            {
                "name": "Copse",
                "countryCode": "JM"
            },
            {
                "name": "Cousins Cove",
                "countryCode": "JM"
            },
            {
                "name": "Dias",
                "countryCode": "JM"
            },
            {
                "name": "Great Valley",
                "countryCode": "JM"
            },
            {
                "name": "Green Island",
                "countryCode": "JM"
            },
            {
                "name": "Haddington",
                "countryCode": "JM"
            },
            {
                "name": "Hopewell",
                "countryCode": "JM"
            },
            {
                "name": "Jericho",
                "countryCode": "JM"
            },
            {
                "name": "Kendal",
                "countryCode": "JM"
            },
            {
                "name": "Kingsvale",
                "countryCode": "JM"
            },
            {
                "name": "Lances Bay",
                "countryCode": "JM"
            },
            {
                "name": "Logwood",
                "countryCode": "JM"
            },
            {
                "name": "Lucea",
                "countryCode": "JM"
            },
            {
                "name": "Lucea East",
                "countryCode": "JM"
            },
            {
                "name": "Lucea West",
                "countryCode": "JM"
            },
            {
                "name": "March Town",
                "countryCode": "JM"
            },
            {
                "name": "Maryland",
                "countryCode": "JM"
            },
            {
                "name": "Mount Peto",
                "countryCode": "JM"
            },
            {
                "name": "Orange Bay",
                "countryCode": "JM"
            },
            {
                "name": "Pell River",
                "countryCode": "JM"
            },
            {
                "name": "Pondside",
                "countryCode": "JM"
            },
            {
                "name": "Ramble",
                "countryCode": "JM"
            },
            {
                "name": "Rock Spring",
                "countryCode": "JM"
            },
            {
                "name": "Sandy Bay",
                "countryCode": "JM"
            },
            {
                "name": "Santoy",
                "countryCode": "JM"
            },
            {
                "name": "Success",
                "countryCode": "JM"
            },
            {
                "name": "Woodlands",
                "countryCode": "JM"
            },
            {
                "name": "Woodsville",
                "countryCode": "JM"
            },
            {
                "name": "12th Street",
                "countryCode": "JM"
            },
            {
                "name": "Amity",
                "countryCode": "JM"
            },
            {
                "name": "Ashton",
                "countryCode": "JM"
            },
            {
                "name": "Barneyside",
                "countryCode": "JM"
            },
            {
                "name": "Bath",
                "countryCode": "JM"
            },
            {
                "name": "Bath Mountain",
                "countryCode": "JM"
            },
            {
                "name": "Beaufort",
                "countryCode": "JM"
            },
            {
                "name": "Beeston Spring",
                "countryCode": "JM"
            },
            {
                "name": "Belvedere",
                "countryCode": "JM"
            },
            {
                "name": "Berkshire",
                "countryCode": "JM"
            },
            {
                "name": "Bethel Town",
                "countryCode": "JM"
            },
            {
                "name": "Big Bridge",
                "countryCode": "JM"
            },
            {
                "name": "Bluefields",
                "countryCode": "JM"
            },
            {
                "name": "Bog",
                "countryCode": "JM"
            },
            {
                "name": "Broughton",
                "countryCode": "JM"
            },
            {
                "name": "Burnt Savannah",
                "countryCode": "JM"
            },
            {
                "name": "Cairn Curran",
                "countryCode": "JM"
            },
            {
                "name": "Caledonia",
                "countryCode": "JM"
            },
            {
                "name": "Carmel",
                "countryCode": "JM"
            },
            {
                "name": "Cave",
                "countryCode": "JM"
            },
            {
                "name": "Chantilly",
                "countryCode": "JM"
            },
            {
                "name": "Content",
                "countryCode": "JM"
            },
            {
                "name": "Cornwall Mountian",
                "countryCode": "JM"
            },
            {
                "name": "Darliston",
                "countryCode": "JM"
            },
            {
                "name": "Delveland",
                "countryCode": "JM"
            },
            {
                "name": "Dillion Bigwoods",
                "countryCode": "JM"
            },
            {
                "name": "Dundee",
                "countryCode": "JM"
            },
            {
                "name": "Enfield",
                "countryCode": "JM"
            },
            {
                "name": "Ferris",
                "countryCode": "JM"
            },
            {
                "name": "Fort Williams",
                "countryCode": "JM"
            },
            {
                "name": "Frome",
                "countryCode": "JM"
            },
            {
                "name": "Fullersfield",
                "countryCode": "JM"
            },
            {
                "name": "Georges Plain",
                "countryCode": "JM"
            },
            {
                "name": "Gooden's River",
                "countryCode": "JM"
            },
            {
                "name": "Grange",
                "countryCode": "JM"
            },
            {
                "name": "Grange Hill",
                "countryCode": "JM"
            },
            {
                "name": "Haddo",
                "countryCode": "JM"
            },
            {
                "name": "Harmony Town",
                "countryCode": "JM"
            },
            {
                "name": "Hertford",
                "countryCode": "JM"
            },
            {
                "name": "Jerusalem",
                "countryCode": "JM"
            },
            {
                "name": "Kentucky",
                "countryCode": "JM"
            },
            {
                "name": "Kilmarnoch",
                "countryCode": "JM"
            },
            {
                "name": "Lambs River",
                "countryCode": "JM"
            },
            {
                "name": "Leamington",
                "countryCode": "JM"
            },
            {
                "name": "Lenox Bigwoods",
                "countryCode": "JM"
            },
            {
                "name": "Little London",
                "countryCode": "JM"
            },
            {
                "name": "Llandilo",
                "countryCode": "JM"
            },
            {
                "name": "Mackfield",
                "countryCode": "JM"
            },
            {
                "name": "Mearnsville",
                "countryCode": "JM"
            },
            {
                "name": "Mount Airy",
                "countryCode": "JM"
            },
            {
                "name": "Mount Stewart",
                "countryCode": "JM"
            },
            {
                "name": "Negril",
                "countryCode": "JM"
            },
            {
                "name": "New Market Oval",
                "countryCode": "JM"
            },
            {
                "name": "New Roads",
                "countryCode": "JM"
            },
            {
                "name": "New Works",
                "countryCode": "JM"
            },
            {
                "name": "Orange Hill",
                "countryCode": "JM"
            },
            {
                "name": "Paul Island",
                "countryCode": "JM"
            },
            {
                "name": "Petersfield",
                "countryCode": "JM"
            },
            {
                "name": "Petersville",
                "countryCode": "JM"
            },
            {
                "name": "Porters Mountain",
                "countryCode": "JM"
            },
            {
                "name": "Red Hills",
                "countryCode": "JM"
            },
            {
                "name": "Revival",
                "countryCode": "JM"
            },
            {
                "name": "Roaring River",
                "countryCode": "JM"
            },
            {
                "name": "Russia",
                "countryCode": "JM"
            },
            {
                "name": "Savanna-la-Mar",
                "countryCode": "JM"
            },
            {
                "name": "Savannah-la-mar Business Dist.",
                "countryCode": "JM"
            },
            {
                "name": "Seaford Town",
                "countryCode": "JM"
            },
            {
                "name": "Seaton Crescent",
                "countryCode": "JM"
            },
            {
                "name": "Shefield",
                "countryCode": "JM"
            },
            {
                "name": "Shoalin Grotto",
                "countryCode": "JM"
            },
            {
                "name": "Smithfield",
                "countryCode": "JM"
            },
            {
                "name": "St. Leonards",
                "countryCode": "JM"
            },
            {
                "name": "Strathbogie",
                "countryCode": "JM"
            },
            {
                "name": "Strawberry",
                "countryCode": "JM"
            },
            {
                "name": "Struie",
                "countryCode": "JM"
            },
            {
                "name": "Three Mile River",
                "countryCode": "JM"
            },
            {
                "name": "Town Head",
                "countryCode": "JM"
            },
            {
                "name": "Truro",
                "countryCode": "JM"
            },
            {
                "name": "Water Works",
                "countryCode": "JM"
            },
            {
                "name": "Whitehouse",
                "countryCode": "JM"
            },
            {
                "name": "Whithorn",
                "countryCode": "JM"
            },
            {
                "name": "Williamsfield",
                "countryCode": "JM"
            },
            {
                "name": "Aberdeen",
                "countryCode": "JM"
            },
            {
                "name": "Accompong",
                "countryCode": "JM"
            },
            {
                "name": "Alligator Pond",
                "countryCode": "JM"
            },
            {
                "name": "Balaclava",
                "countryCode": "JM"
            },
            {
                "name": "Ballards  Valley",
                "countryCode": "JM"
            },
            {
                "name": "Barbary Hall",
                "countryCode": "JM"
            },
            {
                "name": "Bigwoods",
                "countryCode": "JM"
            },
            {
                "name": "Black River",
                "countryCode": "JM"
            },
            {
                "name": "Bogue",
                "countryCode": "JM"
            },
            {
                "name": "Braes River",
                "countryCode": "JM"
            },
            {
                "name": "Brighton",
                "countryCode": "JM"
            },
            {
                "name": "Brompton",
                "countryCode": "JM"
            },
            {
                "name": "Bull Savanna",
                "countryCode": "JM"
            },
            {
                "name": "Bull Savannah",
                "countryCode": "JM"
            },
            {
                "name": "Burnt Savannah",
                "countryCode": "JM"
            },
            {
                "name": "Carisbrook",
                "countryCode": "JM"
            },
            {
                "name": "Cotterwood",
                "countryCode": "JM"
            },
            {
                "name": "Crawford",
                "countryCode": "JM"
            },
            {
                "name": "Elderslie",
                "countryCode": "JM"
            },
            {
                "name": "Fullerswood",
                "countryCode": "JM"
            },
            {
                "name": "Giddy Hall",
                "countryCode": "JM"
            },
            {
                "name": "Ginger Hill",
                "countryCode": "JM"
            },
            {
                "name": "Goshen",
                "countryCode": "JM"
            },
            {
                "name": "Holland",
                "countryCode": "JM"
            },
            {
                "name": "Hopeton",
                "countryCode": "JM"
            },
            {
                "name": "Junction",
                "countryCode": "JM"
            },
            {
                "name": "Lacovia",
                "countryCode": "JM"
            },
            {
                "name": "Leeds",
                "countryCode": "JM"
            },
            {
                "name": "Lititz",
                "countryCode": "JM"
            },
            {
                "name": "Maggotty",
                "countryCode": "JM"
            },
            {
                "name": "Malvern",
                "countryCode": "JM"
            },
            {
                "name": "Merrywood",
                "countryCode": "JM"
            },
            {
                "name": "Middle Quarters",
                "countryCode": "JM"
            },
            {
                "name": "Mountainside",
                "countryCode": "JM"
            },
            {
                "name": "Mulgrave",
                "countryCode": "JM"
            },
            {
                "name": "Myersville",
                "countryCode": "JM"
            },
            {
                "name": "Nain",
                "countryCode": "JM"
            },
            {
                "name": "New Market",
                "countryCode": "JM"
            },
            {
                "name": "Newell",
                "countryCode": "JM"
            },
            {
                "name": "Newton",
                "countryCode": "JM"
            },
            {
                "name": "Parottee",
                "countryCode": "JM"
            },
            {
                "name": "Pedro Plains",
                "countryCode": "JM"
            },
            {
                "name": "Pepper",
                "countryCode": "JM"
            },
            {
                "name": "Pisgah",
                "countryCode": "JM"
            },
            {
                "name": "Pondside",
                "countryCode": "JM"
            },
            {
                "name": "Potsdam",
                "countryCode": "JM"
            },
            {
                "name": "Quick Step",
                "countryCode": "JM"
            },
            {
                "name": "Red Bank",
                "countryCode": "JM"
            },
            {
                "name": "Retirement",
                "countryCode": "JM"
            },
            {
                "name": "Rose Hall",
                "countryCode": "JM"
            },
            {
                "name": "Russels",
                "countryCode": "JM"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "JM"
            },
            {
                "name": "Scholefield",
                "countryCode": "JM"
            },
            {
                "name": "Siloah",
                "countryCode": "JM"
            },
            {
                "name": "Slipe",
                "countryCode": "JM"
            },
            {
                "name": "Southfield",
                "countryCode": "JM"
            },
            {
                "name": "Springfield",
                "countryCode": "JM"
            },
            {
                "name": "Thornton",
                "countryCode": "JM"
            },
            {
                "name": "Top Hill",
                "countryCode": "JM"
            },
            {
                "name": "Treasure Beach",
                "countryCode": "JM"
            },
            {
                "name": "Warminister",
                "countryCode": "JM"
            },
            {
                "name": "Watchwell",
                "countryCode": "JM"
            },
            {
                "name": "White Hill",
                "countryCode": "JM"
            },
            {
                "name": "Alligator Pond",
                "countryCode": "JM"
            },
            {
                "name": "Asia/Pratville",
                "countryCode": "JM"
            },
            {
                "name": "Auchtembeddie",
                "countryCode": "JM"
            },
            {
                "name": "Bellefield",
                "countryCode": "JM"
            },
            {
                "name": "Bethany",
                "countryCode": "JM"
            },
            {
                "name": "Blue Mountain",
                "countryCode": "JM"
            },
            {
                "name": "Bombay",
                "countryCode": "JM"
            },
            {
                "name": "Butt-Up",
                "countryCode": "JM"
            },
            {
                "name": "Cedar Grove",
                "countryCode": "JM"
            },
            {
                "name": "Chantilly",
                "countryCode": "JM"
            },
            {
                "name": "Christiana",
                "countryCode": "JM"
            },
            {
                "name": "Chudleigh",
                "countryCode": "JM"
            },
            {
                "name": "Cobbla",
                "countryCode": "JM"
            },
            {
                "name": "Coleyville",
                "countryCode": "JM"
            },
            {
                "name": "Comfort",
                "countryCode": "JM"
            },
            {
                "name": "Comfort Hall",
                "countryCode": "JM"
            },
            {
                "name": "Content",
                "countryCode": "JM"
            },
            {
                "name": "Craig Head",
                "countryCode": "JM"
            },
            {
                "name": "Cross Keys",
                "countryCode": "JM"
            },
            {
                "name": "Devon",
                "countryCode": "JM"
            },
            {
                "name": "Downs",
                "countryCode": "JM"
            },
            {
                "name": "Ellen Street",
                "countryCode": "JM"
            },
            {
                "name": "Evergreen",
                "countryCode": "JM"
            },
            {
                "name": "Fairfield",
                "countryCode": "JM"
            },
            {
                "name": "French Park",
                "countryCode": "JM"
            },
            {
                "name": "George's Valley",
                "countryCode": "JM"
            },
            {
                "name": "Good Intent",
                "countryCode": "JM"
            },
            {
                "name": "Greenvale",
                "countryCode": "JM"
            },
            {
                "name": "Grove Place",
                "countryCode": "JM"
            },
            {
                "name": "Grove Town",
                "countryCode": "JM"
            },
            {
                "name": "Harmons",
                "countryCode": "JM"
            },
            {
                "name": "Harry Watch",
                "countryCode": "JM"
            },
            {
                "name": "Hatfield",
                "countryCode": "JM"
            },
            {
                "name": "Heartease",
                "countryCode": "JM"
            },
            {
                "name": "Hibernia",
                "countryCode": "JM"
            },
            {
                "name": "Huntley",
                "countryCode": "JM"
            },
            {
                "name": "Kendal",
                "countryCode": "JM"
            },
            {
                "name": "Knockpatrick",
                "countryCode": "JM"
            },
            {
                "name": "Lancaster",
                "countryCode": "JM"
            },
            {
                "name": "Litchfield",
                "countryCode": "JM"
            },
            {
                "name": "Maidstone",
                "countryCode": "JM"
            },
            {
                "name": "Mandeville",
                "countryCode": "JM"
            },
            {
                "name": "Mandeville Proper",
                "countryCode": "JM"
            },
            {
                "name": "Marlie Hill",
                "countryCode": "JM"
            },
            {
                "name": "May Day",
                "countryCode": "JM"
            },
            {
                "name": "Medina",
                "countryCode": "JM"
            },
            {
                "name": "Mike Town",
                "countryCode": "JM"
            },
            {
                "name": "Mile Gully",
                "countryCode": "JM"
            },
            {
                "name": "New Forest",
                "countryCode": "JM"
            },
            {
                "name": "New Green",
                "countryCode": "JM"
            },
            {
                "name": "Newport",
                "countryCode": "JM"
            },
            {
                "name": "Old England",
                "countryCode": "JM"
            },
            {
                "name": "Part Of Banana Ground",
                "countryCode": "JM"
            },
            {
                "name": "Part Of Gutters",
                "countryCode": "JM"
            },
            {
                "name": "Part of Spaldings",
                "countryCode": "JM"
            },
            {
                "name": "Pike",
                "countryCode": "JM"
            },
            {
                "name": "Plowden",
                "countryCode": "JM"
            },
            {
                "name": "Porus",
                "countryCode": "JM"
            },
            {
                "name": "Prospect",
                "countryCode": "JM"
            },
            {
                "name": "Richmond",
                "countryCode": "JM"
            },
            {
                "name": "Robins Hall",
                "countryCode": "JM"
            },
            {
                "name": "Rose Hill",
                "countryCode": "JM"
            },
            {
                "name": "Royal Flat",
                "countryCode": "JM"
            },
            {
                "name": "Scotts Pass",
                "countryCode": "JM"
            },
            {
                "name": "Snowdon",
                "countryCode": "JM"
            },
            {
                "name": "Somerset",
                "countryCode": "JM"
            },
            {
                "name": "Spring Ground",
                "countryCode": "JM"
            },
            {
                "name": "Spur Tree",
                "countryCode": "JM"
            },
            {
                "name": "St. Paul's",
                "countryCode": "JM"
            },
            {
                "name": "Swaby's Hope",
                "countryCode": "JM"
            },
            {
                "name": "Top Hill",
                "countryCode": "JM"
            },
            {
                "name": "Victoria Town",
                "countryCode": "JM"
            },
            {
                "name": "Walderston",
                "countryCode": "JM"
            },
            {
                "name": "Watham",
                "countryCode": "JM"
            },
            {
                "name": "Williamsfield",
                "countryCode": "JM"
            },
            {
                "name": "Aenon Town",
                "countryCode": "JM"
            },
            {
                "name": "Alley",
                "countryCode": "JM"
            },
            {
                "name": "Alston",
                "countryCode": "JM"
            },
            {
                "name": "Ashley",
                "countryCode": "JM"
            },
            {
                "name": "Banks",
                "countryCode": "JM"
            },
            {
                "name": "Beckford Kraal",
                "countryCode": "JM"
            },
            {
                "name": "Brandon Hill",
                "countryCode": "JM"
            },
            {
                "name": "Brixton Hill",
                "countryCode": "JM"
            },
            {
                "name": "Bucknor",
                "countryCode": "JM"
            },
            {
                "name": "Bucks Common",
                "countryCode": "JM"
            },
            {
                "name": "Bushy Park",
                "countryCode": "JM"
            },
            {
                "name": "Chapelton",
                "countryCode": "JM"
            },
            {
                "name": "Chateau",
                "countryCode": "JM"
            },
            {
                "name": "Cockpit",
                "countryCode": "JM"
            },
            {
                "name": "Coffee Piece",
                "countryCode": "JM"
            },
            {
                "name": "Colonels Ridge",
                "countryCode": "JM"
            },
            {
                "name": "Coxswain",
                "countryCode": "JM"
            },
            {
                "name": "Crofts Hill",
                "countryCode": "JM"
            },
            {
                "name": "Crooked River",
                "countryCode": "JM"
            },
            {
                "name": "Cumberland",
                "countryCode": "JM"
            },
            {
                "name": "Curatoe Hill",
                "countryCode": "JM"
            },
            {
                "name": "Dawkins",
                "countryCode": "JM"
            },
            {
                "name": "Effortville",
                "countryCode": "JM"
            },
            {
                "name": "Four Paths",
                "countryCode": "JM"
            },
            {
                "name": "Frankfield",
                "countryCode": "JM"
            },
            {
                "name": "Freetown",
                "countryCode": "JM"
            },
            {
                "name": "Gimme-me-bit",
                "countryCode": "JM"
            },
            {
                "name": "Glenmuir",
                "countryCode": "JM"
            },
            {
                "name": "Grantham",
                "countryCode": "JM"
            },
            {
                "name": "Gravel Hill",
                "countryCode": "JM"
            },
            {
                "name": "Hayes",
                "countryCode": "JM"
            },
            {
                "name": "Hazard",
                "countryCode": "JM"
            },
            {
                "name": "Inverness",
                "countryCode": "JM"
            },
            {
                "name": "James Hill",
                "countryCode": "JM"
            },
            {
                "name": "Johns Hall",
                "countryCode": "JM"
            },
            {
                "name": "Kellits",
                "countryCode": "JM"
            },
            {
                "name": "Limit",
                "countryCode": "JM"
            },
            {
                "name": "Lionel Town",
                "countryCode": "JM"
            },
            {
                "name": "Longville Park",
                "countryCode": "JM"
            },
            {
                "name": "Longwood",
                "countryCode": "JM"
            },
            {
                "name": "May Pen",
                "countryCode": "JM"
            },
            {
                "name": "May Pen Proper",
                "countryCode": "JM"
            },
            {
                "name": "Milk River",
                "countryCode": "JM"
            },
            {
                "name": "Mineral Heights",
                "countryCode": "JM"
            },
            {
                "name": "Mitchell Town",
                "countryCode": "JM"
            },
            {
                "name": "Moores",
                "countryCode": "JM"
            },
            {
                "name": "Morgans Forest",
                "countryCode": "JM"
            },
            {
                "name": "Morgans Pass",
                "countryCode": "JM"
            },
            {
                "name": "Mount Airy",
                "countryCode": "JM"
            },
            {
                "name": "New Denbigh",
                "countryCode": "JM"
            },
            {
                "name": "New Longsville",
                "countryCode": "JM"
            },
            {
                "name": "Old Denbigh",
                "countryCode": "JM"
            },
            {
                "name": "Orange Hill",
                "countryCode": "JM"
            },
            {
                "name": "Osbourne Store",
                "countryCode": "JM"
            },
            {
                "name": "Paisley",
                "countryCode": "JM"
            },
            {
                "name": "Palmers Cross",
                "countryCode": "JM"
            },
            {
                "name": "Part of Banana Ground",
                "countryCode": "JM"
            },
            {
                "name": "Part of Douglas Castle",
                "countryCode": "JM"
            },
            {
                "name": "Part of Kellits",
                "countryCode": "JM"
            },
            {
                "name": "Peckham",
                "countryCode": "JM"
            },
            {
                "name": "Pennants",
                "countryCode": "JM"
            },
            {
                "name": "Pleasant Valley",
                "countryCode": "JM"
            },
            {
                "name": "Portland Cottage",
                "countryCode": "JM"
            },
            {
                "name": "Porus",
                "countryCode": "JM"
            },
            {
                "name": "Race Course",
                "countryCode": "JM"
            },
            {
                "name": "Richmond Park",
                "countryCode": "JM"
            },
            {
                "name": "Ritchies",
                "countryCode": "JM"
            },
            {
                "name": "Rock",
                "countryCode": "JM"
            },
            {
                "name": "Rock River",
                "countryCode": "JM"
            },
            {
                "name": "Rocky Point",
                "countryCode": "JM"
            },
            {
                "name": "Salt River",
                "countryCode": "JM"
            },
            {
                "name": "Sandy Bay",
                "countryCode": "JM"
            },
            {
                "name": "Sanguinetti",
                "countryCode": "JM"
            },
            {
                "name": "Sedgepond",
                "countryCode": "JM"
            },
            {
                "name": "Silent Hill",
                "countryCode": "JM"
            },
            {
                "name": "Smithville",
                "countryCode": "JM"
            },
            {
                "name": "Spaldings",
                "countryCode": "JM"
            },
            {
                "name": "Summerfield",
                "countryCode": "JM"
            },
            {
                "name": "Thompson Town",
                "countryCode": "JM"
            },
            {
                "name": "Tollgate",
                "countryCode": "JM"
            },
            {
                "name": "Treadlight",
                "countryCode": "JM"
            },
            {
                "name": "Trout Hall",
                "countryCode": "JM"
            },
            {
                "name": "Turners",
                "countryCode": "JM"
            },
            {
                "name": "Water Lane",
                "countryCode": "JM"
            },
            {
                "name": "Woodhall",
                "countryCode": "JM"
            },
            {
                "name": "York Town",
                "countryCode": "JM"
            },
            {
                "name": "Above Rocks",
                "countryCode": "JM"
            },
            {
                "name": "Bartons",
                "countryCode": "JM"
            },
            {
                "name": "Bellas Gate",
                "countryCode": "JM"
            },
            {
                "name": "Bog Walk",
                "countryCode": "JM"
            },
            {
                "name": "Bogwalk",
                "countryCode": "JM"
            },
            {
                "name": "Braeton",
                "countryCode": "JM"
            },
            {
                "name": "Bridgeport",
                "countryCode": "JM"
            },
            {
                "name": "Browns Hill",
                "countryCode": "JM"
            },
            {
                "name": "Caymanas",
                "countryCode": "JM"
            },
            {
                "name": "Central Village",
                "countryCode": "JM"
            },
            {
                "name": "Crescent",
                "countryCode": "JM"
            },
            {
                "name": "Cromarty",
                "countryCode": "JM"
            },
            {
                "name": "Cumberland",
                "countryCode": "JM"
            },
            {
                "name": "Edgewater",
                "countryCode": "JM"
            },
            {
                "name": "Ensom",
                "countryCode": "JM"
            },
            {
                "name": "Ewarton",
                "countryCode": "JM"
            },
            {
                "name": "Ginger Ridge",
                "countryCode": "JM"
            },
            {
                "name": "Glengoffe",
                "countryCode": "JM"
            },
            {
                "name": "Golden Acres",
                "countryCode": "JM"
            },
            {
                "name": "Greater Portmore",
                "countryCode": "JM"
            },
            {
                "name": "Greendale",
                "countryCode": "JM"
            },
            {
                "name": "Gregory Park",
                "countryCode": "JM"
            },
            {
                "name": "Hampton Green",
                "countryCode": "JM"
            },
            {
                "name": "Harewood",
                "countryCode": "JM"
            },
            {
                "name": "Harkers Hall",
                "countryCode": "JM"
            },
            {
                "name": "Hellshire",
                "countryCode": "JM"
            },
            {
                "name": "Independence City",
                "countryCode": "JM"
            },
            {
                "name": "Keystone",
                "countryCode": "JM"
            },
            {
                "name": "Kitson Town",
                "countryCode": "JM"
            },
            {
                "name": "Linstead",
                "countryCode": "JM"
            },
            {
                "name": "Lluidas Vale",
                "countryCode": "JM"
            },
            {
                "name": "Naggo Head",
                "countryCode": "JM"
            },
            {
                "name": "Old Harbour",
                "countryCode": "JM"
            },
            {
                "name": "Old Harbour Bay",
                "countryCode": "JM"
            },
            {
                "name": "Old Harbour Road",
                "countryCode": "JM"
            },
            {
                "name": "Part of Guys Hill",
                "countryCode": "JM"
            },
            {
                "name": "Part of Keystone",
                "countryCode": "JM"
            },
            {
                "name": "Part of Rock Hall",
                "countryCode": "JM"
            },
            {
                "name": "Passage Fort",
                "countryCode": "JM"
            },
            {
                "name": "Pear Tree Grove",
                "countryCode": "JM"
            },
            {
                "name": "Point Hill",
                "countryCode": "JM"
            },
            {
                "name": "Portmore",
                "countryCode": "JM"
            },
            {
                "name": "Redwood",
                "countryCode": "JM"
            },
            {
                "name": "Riversdale",
                "countryCode": "JM"
            },
            {
                "name": "Sligoville",
                "countryCode": "JM"
            },
            {
                "name": "Spanish Town",
                "countryCode": "JM"
            },
            {
                "name": "Spanish Town Central",
                "countryCode": "JM"
            },
            {
                "name": "St. John's East",
                "countryCode": "JM"
            },
            {
                "name": "St. John's West",
                "countryCode": "JM"
            },
            {
                "name": "Troja",
                "countryCode": "JM"
            },
            {
                "name": "Waterford",
                "countryCode": "JM"
            },
            {
                "name": "Watermount",
                "countryCode": "JM"
            },
            {
                "name": "West Cumberland",
                "countryCode": "JM"
            },
            {
                "name": "Willowdene",
                "countryCode": "JM"
            }
        ]
    },
    {
        "name": "Japan",
        "phonecode": "81",
        "cities": [
            {
                "name": "Abashiri",
                "countryCode": "JP"
            },
            {
                "name": "Abashiri Shi",
                "countryCode": "JP"
            },
            {
                "name": "Akabira",
                "countryCode": "JP"
            },
            {
                "name": "Akabira-shi",
                "countryCode": "JP"
            },
            {
                "name": "Asahikawa",
                "countryCode": "JP"
            },
            {
                "name": "Ashibetsu",
                "countryCode": "JP"
            },
            {
                "name": "Ashibetsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Bibai",
                "countryCode": "JP"
            },
            {
                "name": "Chitose",
                "countryCode": "JP"
            },
            {
                "name": "Chitose Shi",
                "countryCode": "JP"
            },
            {
                "name": "Date",
                "countryCode": "JP"
            },
            {
                "name": "Date-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ebetsu",
                "countryCode": "JP"
            },
            {
                "name": "Eniwa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukagawa",
                "countryCode": "JP"
            },
            {
                "name": "Fukagawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Furano-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hakodate",
                "countryCode": "JP"
            },
            {
                "name": "Hakodate Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hokuto",
                "countryCode": "JP"
            },
            {
                "name": "Hokuto-shi",
                "countryCode": "JP"
            },
            {
                "name": "Honchō",
                "countryCode": "JP"
            },
            {
                "name": "Ishikari",
                "countryCode": "JP"
            },
            {
                "name": "Ishikari-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwamizawa",
                "countryCode": "JP"
            },
            {
                "name": "Iwamizawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwanai",
                "countryCode": "JP"
            },
            {
                "name": "Kamiiso",
                "countryCode": "JP"
            },
            {
                "name": "Kamikawa",
                "countryCode": "JP"
            },
            {
                "name": "Kitahiroshima",
                "countryCode": "JP"
            },
            {
                "name": "Kitahiroshima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitami",
                "countryCode": "JP"
            },
            {
                "name": "Ktiami Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kushiro",
                "countryCode": "JP"
            },
            {
                "name": "Kushiro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Makubetsu",
                "countryCode": "JP"
            },
            {
                "name": "Mikasa",
                "countryCode": "JP"
            },
            {
                "name": "Mikasa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mombetsu",
                "countryCode": "JP"
            },
            {
                "name": "Monbetsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Motomachi",
                "countryCode": "JP"
            },
            {
                "name": "Muroran",
                "countryCode": "JP"
            },
            {
                "name": "Muroran-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nayoro",
                "countryCode": "JP"
            },
            {
                "name": "Nayoro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nemuro",
                "countryCode": "JP"
            },
            {
                "name": "Nemuro-shi",
                "countryCode": "JP"
            },
            {
                "name": "Niseko Town",
                "countryCode": "JP"
            },
            {
                "name": "Noboribetsu",
                "countryCode": "JP"
            },
            {
                "name": "Noboribetsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Obihiro",
                "countryCode": "JP"
            },
            {
                "name": "Obihiro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Otaru",
                "countryCode": "JP"
            },
            {
                "name": "Otaru-shi",
                "countryCode": "JP"
            },
            {
                "name": "Otofuke",
                "countryCode": "JP"
            },
            {
                "name": "Rebun Gun",
                "countryCode": "JP"
            },
            {
                "name": "Rishiri Gun",
                "countryCode": "JP"
            },
            {
                "name": "Rishiri Town",
                "countryCode": "JP"
            },
            {
                "name": "Rumoi",
                "countryCode": "JP"
            },
            {
                "name": "Rumoi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sapporo",
                "countryCode": "JP"
            },
            {
                "name": "Sapporo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shibetsu",
                "countryCode": "JP"
            },
            {
                "name": "Shibetsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimo-furano",
                "countryCode": "JP"
            },
            {
                "name": "Shiraoi",
                "countryCode": "JP"
            },
            {
                "name": "Shizunai-furukawachō",
                "countryCode": "JP"
            },
            {
                "name": "Sunagawa",
                "countryCode": "JP"
            },
            {
                "name": "Sunagawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takikawa",
                "countryCode": "JP"
            },
            {
                "name": "Takikawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tomakomai",
                "countryCode": "JP"
            },
            {
                "name": "Tomakomai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōbetsu",
                "countryCode": "JP"
            },
            {
                "name": "Utashinai",
                "countryCode": "JP"
            },
            {
                "name": "Utashinai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Wakkanai",
                "countryCode": "JP"
            },
            {
                "name": "Wakkanai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yoichi",
                "countryCode": "JP"
            },
            {
                "name": "Yūbari",
                "countryCode": "JP"
            },
            {
                "name": "Yūbari-shi",
                "countryCode": "JP"
            },
            {
                "name": "Aomori",
                "countryCode": "JP"
            },
            {
                "name": "Aomori Shi",
                "countryCode": "JP"
            },
            {
                "name": "Goshogawara",
                "countryCode": "JP"
            },
            {
                "name": "Goshogawara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hachinohe",
                "countryCode": "JP"
            },
            {
                "name": "Hachinohe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hirakawa",
                "countryCode": "JP"
            },
            {
                "name": "Hirakawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hirosaki",
                "countryCode": "JP"
            },
            {
                "name": "Hirosaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kuroishi",
                "countryCode": "JP"
            },
            {
                "name": "Kuroishi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Misawa",
                "countryCode": "JP"
            },
            {
                "name": "Misawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mutsu",
                "countryCode": "JP"
            },
            {
                "name": "Mutsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Namioka",
                "countryCode": "JP"
            },
            {
                "name": "Shimokizukuri",
                "countryCode": "JP"
            },
            {
                "name": "Towada Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsugaru",
                "countryCode": "JP"
            },
            {
                "name": "Tsugaru Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hachimantai",
                "countryCode": "JP"
            },
            {
                "name": "Hachimantai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hanamaki",
                "countryCode": "JP"
            },
            {
                "name": "Hanamaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ichinohe",
                "countryCode": "JP"
            },
            {
                "name": "Ichinoseki",
                "countryCode": "JP"
            },
            {
                "name": "Ichinoseki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwate-gun",
                "countryCode": "JP"
            },
            {
                "name": "Kamaishi",
                "countryCode": "JP"
            },
            {
                "name": "Kamaishi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitakami",
                "countryCode": "JP"
            },
            {
                "name": "Kitakami-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kuji",
                "countryCode": "JP"
            },
            {
                "name": "Kuji-shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyako",
                "countryCode": "JP"
            },
            {
                "name": "Miyako-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mizusawa",
                "countryCode": "JP"
            },
            {
                "name": "Morioka",
                "countryCode": "JP"
            },
            {
                "name": "Morioka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ninohe",
                "countryCode": "JP"
            },
            {
                "name": "Ninohe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Rikuzentakata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shizukuishi",
                "countryCode": "JP"
            },
            {
                "name": "Takizawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōno",
                "countryCode": "JP"
            },
            {
                "name": "Tōno-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamada",
                "countryCode": "JP"
            },
            {
                "name": "Ōfunato",
                "countryCode": "JP"
            },
            {
                "name": "Ōfunato-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōshū",
                "countryCode": "JP"
            },
            {
                "name": "Ōshū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōtsuchi",
                "countryCode": "JP"
            },
            {
                "name": "Furukawa",
                "countryCode": "JP"
            },
            {
                "name": "Higashimatshushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashimatsushima",
                "countryCode": "JP"
            },
            {
                "name": "Ishinomaki",
                "countryCode": "JP"
            },
            {
                "name": "Ishinomaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwanuma",
                "countryCode": "JP"
            },
            {
                "name": "Iwanuma-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kakuda",
                "countryCode": "JP"
            },
            {
                "name": "Kakuda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kesennuma",
                "countryCode": "JP"
            },
            {
                "name": "Kesennuma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kogota",
                "countryCode": "JP"
            },
            {
                "name": "Kurihara",
                "countryCode": "JP"
            },
            {
                "name": "Kurihara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Marumori",
                "countryCode": "JP"
            },
            {
                "name": "Matsushima",
                "countryCode": "JP"
            },
            {
                "name": "Natori Shi",
                "countryCode": "JP"
            },
            {
                "name": "Onagawa Chō",
                "countryCode": "JP"
            },
            {
                "name": "Rifu",
                "countryCode": "JP"
            },
            {
                "name": "Sendai",
                "countryCode": "JP"
            },
            {
                "name": "Shiogama",
                "countryCode": "JP"
            },
            {
                "name": "Shiroishi",
                "countryCode": "JP"
            },
            {
                "name": "Shiroishi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tagajō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tome Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tomiya",
                "countryCode": "JP"
            },
            {
                "name": "Wakuya",
                "countryCode": "JP"
            },
            {
                "name": "Watari",
                "countryCode": "JP"
            },
            {
                "name": "Watari-gun",
                "countryCode": "JP"
            },
            {
                "name": "Yamoto",
                "countryCode": "JP"
            },
            {
                "name": "Ōkawara",
                "countryCode": "JP"
            },
            {
                "name": "Ōsaki",
                "countryCode": "JP"
            },
            {
                "name": "Ōsaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Akita",
                "countryCode": "JP"
            },
            {
                "name": "Akita Shi",
                "countryCode": "JP"
            },
            {
                "name": "Daisen",
                "countryCode": "JP"
            },
            {
                "name": "Daisen-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hanawa",
                "countryCode": "JP"
            },
            {
                "name": "Kakunodatemachi",
                "countryCode": "JP"
            },
            {
                "name": "Katagami",
                "countryCode": "JP"
            },
            {
                "name": "Katagami-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kazuno Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitaakita-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nikaho-shi",
                "countryCode": "JP"
            },
            {
                "name": "Noshiro",
                "countryCode": "JP"
            },
            {
                "name": "Noshiro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Oga",
                "countryCode": "JP"
            },
            {
                "name": "Oga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Semboku-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takanosu",
                "countryCode": "JP"
            },
            {
                "name": "Tennō",
                "countryCode": "JP"
            },
            {
                "name": "Yokote",
                "countryCode": "JP"
            },
            {
                "name": "Yokote-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yurihonjō",
                "countryCode": "JP"
            },
            {
                "name": "Yurihonjō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yuzawa",
                "countryCode": "JP"
            },
            {
                "name": "Yuzawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōdate",
                "countryCode": "JP"
            },
            {
                "name": "Ōdate-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmagari",
                "countryCode": "JP"
            },
            {
                "name": "Higashine",
                "countryCode": "JP"
            },
            {
                "name": "Higashine Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kaminoyama",
                "countryCode": "JP"
            },
            {
                "name": "Kaminoyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Murayama",
                "countryCode": "JP"
            },
            {
                "name": "Murayama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagai",
                "countryCode": "JP"
            },
            {
                "name": "Nagai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nanyō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Obanazawa",
                "countryCode": "JP"
            },
            {
                "name": "Obanazawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sagae",
                "countryCode": "JP"
            },
            {
                "name": "Sagae-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakata",
                "countryCode": "JP"
            },
            {
                "name": "Sakata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shinjō",
                "countryCode": "JP"
            },
            {
                "name": "Shinjō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takahata",
                "countryCode": "JP"
            },
            {
                "name": "Tendō",
                "countryCode": "JP"
            },
            {
                "name": "Tendō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsuruoka",
                "countryCode": "JP"
            },
            {
                "name": "Tsuruoka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamagata",
                "countryCode": "JP"
            },
            {
                "name": "Yamagata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yonezawa",
                "countryCode": "JP"
            },
            {
                "name": "Yonezawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yuza",
                "countryCode": "JP"
            },
            {
                "name": "Aizu-wakamatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Date-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukushima",
                "countryCode": "JP"
            },
            {
                "name": "Fukushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Funehikimachi-funehiki",
                "countryCode": "JP"
            },
            {
                "name": "Hobaramachi",
                "countryCode": "JP"
            },
            {
                "name": "Inawashiro",
                "countryCode": "JP"
            },
            {
                "name": "Ishikawa",
                "countryCode": "JP"
            },
            {
                "name": "Iwaki",
                "countryCode": "JP"
            },
            {
                "name": "Iwaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitakata",
                "countryCode": "JP"
            },
            {
                "name": "Kitakata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōriyama",
                "countryCode": "JP"
            },
            {
                "name": "Kōriyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miharu",
                "countryCode": "JP"
            },
            {
                "name": "Minami-Sōma",
                "countryCode": "JP"
            },
            {
                "name": "Minamisōma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Motomiya",
                "countryCode": "JP"
            },
            {
                "name": "Motomiya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Namie",
                "countryCode": "JP"
            },
            {
                "name": "Nihommatsu",
                "countryCode": "JP"
            },
            {
                "name": "Nihonmatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shirakawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sukagawa",
                "countryCode": "JP"
            },
            {
                "name": "Sukagawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sōma",
                "countryCode": "JP"
            },
            {
                "name": "Sōma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tamura",
                "countryCode": "JP"
            },
            {
                "name": "Tamura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yanagawamachi-saiwaichō",
                "countryCode": "JP"
            },
            {
                "name": "Ami",
                "countryCode": "JP"
            },
            {
                "name": "Bandō",
                "countryCode": "JP"
            },
            {
                "name": "Bandō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chikusei",
                "countryCode": "JP"
            },
            {
                "name": "Chikusei-shi",
                "countryCode": "JP"
            },
            {
                "name": "Daigo",
                "countryCode": "JP"
            },
            {
                "name": "Edosaki",
                "countryCode": "JP"
            },
            {
                "name": "Fujishiro",
                "countryCode": "JP"
            },
            {
                "name": "Funaishikawa",
                "countryCode": "JP"
            },
            {
                "name": "Hitachi",
                "countryCode": "JP"
            },
            {
                "name": "Hitachi-Naka",
                "countryCode": "JP"
            },
            {
                "name": "Hitachi-ota",
                "countryCode": "JP"
            },
            {
                "name": "Hitachi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hitachinaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hitachiōmiya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hitachiōta-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hokota-shi",
                "countryCode": "JP"
            },
            {
                "name": "Inashiki",
                "countryCode": "JP"
            },
            {
                "name": "Inashiki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ishige",
                "countryCode": "JP"
            },
            {
                "name": "Ishioka",
                "countryCode": "JP"
            },
            {
                "name": "Ishioka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Itako",
                "countryCode": "JP"
            },
            {
                "name": "Itako-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwai",
                "countryCode": "JP"
            },
            {
                "name": "Iwase",
                "countryCode": "JP"
            },
            {
                "name": "Jōsō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamisu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kasama",
                "countryCode": "JP"
            },
            {
                "name": "Kasama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kasumigaura",
                "countryCode": "JP"
            },
            {
                "name": "Kasumigaura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuta",
                "countryCode": "JP"
            },
            {
                "name": "Kitaibaraki",
                "countryCode": "JP"
            },
            {
                "name": "Kitaibaraki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Koga",
                "countryCode": "JP"
            },
            {
                "name": "Koga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Makabe",
                "countryCode": "JP"
            },
            {
                "name": "Mito",
                "countryCode": "JP"
            },
            {
                "name": "Mito-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mitsukaidō",
                "countryCode": "JP"
            },
            {
                "name": "Moriya",
                "countryCode": "JP"
            },
            {
                "name": "Moriya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Naka",
                "countryCode": "JP"
            },
            {
                "name": "Naka-gun",
                "countryCode": "JP"
            },
            {
                "name": "Namegata",
                "countryCode": "JP"
            },
            {
                "name": "Namegata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Okunoya",
                "countryCode": "JP"
            },
            {
                "name": "Omitama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ryūgasaki",
                "countryCode": "JP"
            },
            {
                "name": "Ryūgasaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakai",
                "countryCode": "JP"
            },
            {
                "name": "Sakuragawa",
                "countryCode": "JP"
            },
            {
                "name": "Sakuragawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimodate",
                "countryCode": "JP"
            },
            {
                "name": "Shimotsuma-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takahagi",
                "countryCode": "JP"
            },
            {
                "name": "Tomobe",
                "countryCode": "JP"
            },
            {
                "name": "Toride",
                "countryCode": "JP"
            },
            {
                "name": "Toride-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsuchiura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsukuba",
                "countryCode": "JP"
            },
            {
                "name": "Tsukuba-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsukubamirai",
                "countryCode": "JP"
            },
            {
                "name": "Tsukubamirai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ushiku",
                "countryCode": "JP"
            },
            {
                "name": "Ushiku-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yūki",
                "countryCode": "JP"
            },
            {
                "name": "Yūki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōarai",
                "countryCode": "JP"
            },
            {
                "name": "Ōmiya",
                "countryCode": "JP"
            },
            {
                "name": "Ashikaga",
                "countryCode": "JP"
            },
            {
                "name": "Fujioka",
                "countryCode": "JP"
            },
            {
                "name": "Imaichi",
                "countryCode": "JP"
            },
            {
                "name": "Kaminokawa",
                "countryCode": "JP"
            },
            {
                "name": "Kanuma",
                "countryCode": "JP"
            },
            {
                "name": "Kanuma-shi",
                "countryCode": "JP"
            },
            {
                "name": "Karasuyama",
                "countryCode": "JP"
            },
            {
                "name": "Kuroiso",
                "countryCode": "JP"
            },
            {
                "name": "Mashiko",
                "countryCode": "JP"
            },
            {
                "name": "Mibu",
                "countryCode": "JP"
            },
            {
                "name": "Mooka",
                "countryCode": "JP"
            },
            {
                "name": "Mooka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Motegi",
                "countryCode": "JP"
            },
            {
                "name": "Nasukarasuyama",
                "countryCode": "JP"
            },
            {
                "name": "Nasukarasuyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nasushiobara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nikko-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nikkō",
                "countryCode": "JP"
            },
            {
                "name": "Oyama",
                "countryCode": "JP"
            },
            {
                "name": "Oyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sano",
                "countryCode": "JP"
            },
            {
                "name": "Sano-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimotsuke-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanuma",
                "countryCode": "JP"
            },
            {
                "name": "Tochigi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ujiie",
                "countryCode": "JP"
            },
            {
                "name": "Utsunomiya",
                "countryCode": "JP"
            },
            {
                "name": "Utsunomiya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yaita",
                "countryCode": "JP"
            },
            {
                "name": "Yaita-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōtawara",
                "countryCode": "JP"
            },
            {
                "name": "Ōtawara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Agatsuma-gun",
                "countryCode": "JP"
            },
            {
                "name": "Annaka",
                "countryCode": "JP"
            },
            {
                "name": "Annaka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujioka",
                "countryCode": "JP"
            },
            {
                "name": "Fujioka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Isesaki",
                "countryCode": "JP"
            },
            {
                "name": "Isesaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kanekomachi",
                "countryCode": "JP"
            },
            {
                "name": "Kiryū",
                "countryCode": "JP"
            },
            {
                "name": "Kiryū Shi",
                "countryCode": "JP"
            },
            {
                "name": "Maebashi",
                "countryCode": "JP"
            },
            {
                "name": "Maebashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Midori",
                "countryCode": "JP"
            },
            {
                "name": "Midori Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakanojōmachi",
                "countryCode": "JP"
            },
            {
                "name": "Numata",
                "countryCode": "JP"
            },
            {
                "name": "Numata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakai-nakajima",
                "countryCode": "JP"
            },
            {
                "name": "Shibukawa",
                "countryCode": "JP"
            },
            {
                "name": "Shibukawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takasaki",
                "countryCode": "JP"
            },
            {
                "name": "Takasaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tamamura",
                "countryCode": "JP"
            },
            {
                "name": "Tatebayashi",
                "countryCode": "JP"
            },
            {
                "name": "Tatebayashi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tomioka",
                "countryCode": "JP"
            },
            {
                "name": "Tomioka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yoshii",
                "countryCode": "JP"
            },
            {
                "name": "Ōmamachō-ōmama",
                "countryCode": "JP"
            },
            {
                "name": "Ōta",
                "countryCode": "JP"
            },
            {
                "name": "Ōta-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ageo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ageoshimo",
                "countryCode": "JP"
            },
            {
                "name": "Asaka",
                "countryCode": "JP"
            },
            {
                "name": "Asaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chichibu",
                "countryCode": "JP"
            },
            {
                "name": "Chichibu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujimi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujimino Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukaya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukayachō",
                "countryCode": "JP"
            },
            {
                "name": "Fukiage-fujimi",
                "countryCode": "JP"
            },
            {
                "name": "Gyōda",
                "countryCode": "JP"
            },
            {
                "name": "Hannō",
                "countryCode": "JP"
            },
            {
                "name": "Hannō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hanyū",
                "countryCode": "JP"
            },
            {
                "name": "Hanyū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hasuda",
                "countryCode": "JP"
            },
            {
                "name": "Hasuda-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hatogaya-honchō",
                "countryCode": "JP"
            },
            {
                "name": "Hidaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashimatsuyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hiki-gun",
                "countryCode": "JP"
            },
            {
                "name": "Honjō",
                "countryCode": "JP"
            },
            {
                "name": "Honjō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iruma-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwatsuki",
                "countryCode": "JP"
            },
            {
                "name": "Kamifukuoka",
                "countryCode": "JP"
            },
            {
                "name": "Kasukabe",
                "countryCode": "JP"
            },
            {
                "name": "Kasukabe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawagoe",
                "countryCode": "JP"
            },
            {
                "name": "Kawagoe-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawaguchi",
                "countryCode": "JP"
            },
            {
                "name": "Kawaguchi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kazo",
                "countryCode": "JP"
            },
            {
                "name": "Kazo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kisai",
                "countryCode": "JP"
            },
            {
                "name": "Kitamoto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kodamachō-kodamaminami",
                "countryCode": "JP"
            },
            {
                "name": "Konosu-Shi",
                "countryCode": "JP"
            },
            {
                "name": "Koshigaya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kuki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kukichūō",
                "countryCode": "JP"
            },
            {
                "name": "Kumagaya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kurihashi",
                "countryCode": "JP"
            },
            {
                "name": "Kōnosu",
                "countryCode": "JP"
            },
            {
                "name": "Menuma",
                "countryCode": "JP"
            },
            {
                "name": "Misato Shi",
                "countryCode": "JP"
            },
            {
                "name": "Morohongō",
                "countryCode": "JP"
            },
            {
                "name": "Niiza-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ogawa",
                "countryCode": "JP"
            },
            {
                "name": "Okegawa",
                "countryCode": "JP"
            },
            {
                "name": "Okegawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Saitama",
                "countryCode": "JP"
            },
            {
                "name": "Saitama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakado",
                "countryCode": "JP"
            },
            {
                "name": "Sakado-shi",
                "countryCode": "JP"
            },
            {
                "name": "Satte",
                "countryCode": "JP"
            },
            {
                "name": "Satte Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sayama",
                "countryCode": "JP"
            },
            {
                "name": "Shiki",
                "countryCode": "JP"
            },
            {
                "name": "Shiki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimotoda",
                "countryCode": "JP"
            },
            {
                "name": "Shiraoka",
                "countryCode": "JP"
            },
            {
                "name": "Shiraoka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shōbu",
                "countryCode": "JP"
            },
            {
                "name": "Sugito",
                "countryCode": "JP"
            },
            {
                "name": "Sōka",
                "countryCode": "JP"
            },
            {
                "name": "Sōka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Toda-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tokorozawa",
                "countryCode": "JP"
            },
            {
                "name": "Tokorozawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsurugashima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Wako",
                "countryCode": "JP"
            },
            {
                "name": "Wakō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Warabi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yashio",
                "countryCode": "JP"
            },
            {
                "name": "Yashio-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yono",
                "countryCode": "JP"
            },
            {
                "name": "Yorii",
                "countryCode": "JP"
            },
            {
                "name": "Yoshikawa",
                "countryCode": "JP"
            },
            {
                "name": "Yoshikawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōi",
                "countryCode": "JP"
            },
            {
                "name": "Ōsato-gun",
                "countryCode": "JP"
            },
            {
                "name": "Abiko",
                "countryCode": "JP"
            },
            {
                "name": "Abiko-shi",
                "countryCode": "JP"
            },
            {
                "name": "Asahi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chiba",
                "countryCode": "JP"
            },
            {
                "name": "Chōshi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Funabashi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Futtsu",
                "countryCode": "JP"
            },
            {
                "name": "Hasaki",
                "countryCode": "JP"
            },
            {
                "name": "Honchō",
                "countryCode": "JP"
            },
            {
                "name": "Ichihara",
                "countryCode": "JP"
            },
            {
                "name": "Ichihara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ichikawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Inzai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Isumi",
                "countryCode": "JP"
            },
            {
                "name": "Isumi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamagaya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamogawa",
                "countryCode": "JP"
            },
            {
                "name": "Kamogawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashiwa",
                "countryCode": "JP"
            },
            {
                "name": "Kashiwa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Katori-shi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuura",
                "countryCode": "JP"
            },
            {
                "name": "Katsuura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kimitsu",
                "countryCode": "JP"
            },
            {
                "name": "Kisarazu",
                "countryCode": "JP"
            },
            {
                "name": "Kisarazu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsudo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamibōsō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mobara",
                "countryCode": "JP"
            },
            {
                "name": "Mobara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagareyama",
                "countryCode": "JP"
            },
            {
                "name": "Nagareyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Narashino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Narita",
                "countryCode": "JP"
            },
            {
                "name": "Narita-shi",
                "countryCode": "JP"
            },
            {
                "name": "Narutō",
                "countryCode": "JP"
            },
            {
                "name": "Noda",
                "countryCode": "JP"
            },
            {
                "name": "Noda-shi",
                "countryCode": "JP"
            },
            {
                "name": "Omigawa",
                "countryCode": "JP"
            },
            {
                "name": "Sakura",
                "countryCode": "JP"
            },
            {
                "name": "Sakura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sambu-gun",
                "countryCode": "JP"
            },
            {
                "name": "Sanmu",
                "countryCode": "JP"
            },
            {
                "name": "Sanmu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sawara",
                "countryCode": "JP"
            },
            {
                "name": "Shiroi",
                "countryCode": "JP"
            },
            {
                "name": "Shiroi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shisui",
                "countryCode": "JP"
            },
            {
                "name": "Sodegaura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sōsa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tateyama",
                "countryCode": "JP"
            },
            {
                "name": "Tateyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tomisato-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōgane",
                "countryCode": "JP"
            },
            {
                "name": "Tōgane-shi",
                "countryCode": "JP"
            },
            {
                "name": "Urayasu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yachimata",
                "countryCode": "JP"
            },
            {
                "name": "Yachimata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yachiyo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yotsukaidō",
                "countryCode": "JP"
            },
            {
                "name": "Yotsukaidō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yōkaichiba",
                "countryCode": "JP"
            },
            {
                "name": "Ōami",
                "countryCode": "JP"
            },
            {
                "name": "Ōamishirasato-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōhara",
                "countryCode": "JP"
            },
            {
                "name": "Adachi Ku",
                "countryCode": "JP"
            },
            {
                "name": "Akiruno-shi",
                "countryCode": "JP"
            },
            {
                "name": "Akishima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Arakawa Ku",
                "countryCode": "JP"
            },
            {
                "name": "Bunkyō-ku",
                "countryCode": "JP"
            },
            {
                "name": "Chiyoda-ku",
                "countryCode": "JP"
            },
            {
                "name": "Chōfu",
                "countryCode": "JP"
            },
            {
                "name": "Chōfu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chūō Ku",
                "countryCode": "JP"
            },
            {
                "name": "Edogawa Ku",
                "countryCode": "JP"
            },
            {
                "name": "Fuchū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fussa",
                "countryCode": "JP"
            },
            {
                "name": "Hachiōji",
                "countryCode": "JP"
            },
            {
                "name": "Hamura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashi-murayama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashikurume-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashimurayama",
                "countryCode": "JP"
            },
            {
                "name": "Higashiyamato",
                "countryCode": "JP"
            },
            {
                "name": "Higashiyamato-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hino",
                "countryCode": "JP"
            },
            {
                "name": "Hino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Inagi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Itabashi-ku",
                "countryCode": "JP"
            },
            {
                "name": "Itsukaichi",
                "countryCode": "JP"
            },
            {
                "name": "Kamirenjaku",
                "countryCode": "JP"
            },
            {
                "name": "Katsushika Ku",
                "countryCode": "JP"
            },
            {
                "name": "Kita-ku",
                "countryCode": "JP"
            },
            {
                "name": "Kiyose-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kodaira-shi",
                "countryCode": "JP"
            },
            {
                "name": "Koganei-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kokubunji",
                "countryCode": "JP"
            },
            {
                "name": "Kokubunji-shi",
                "countryCode": "JP"
            },
            {
                "name": "Komae-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kunitachi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōtō-ku",
                "countryCode": "JP"
            },
            {
                "name": "Machida",
                "countryCode": "JP"
            },
            {
                "name": "Machida-shi",
                "countryCode": "JP"
            },
            {
                "name": "Meguro-ku",
                "countryCode": "JP"
            },
            {
                "name": "Minato-ku",
                "countryCode": "JP"
            },
            {
                "name": "Mitaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Musashimurayama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Musashino",
                "countryCode": "JP"
            },
            {
                "name": "Musashino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakano-ku",
                "countryCode": "JP"
            },
            {
                "name": "Nerima-ku",
                "countryCode": "JP"
            },
            {
                "name": "Nishi-Tokyo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nishitōkyō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Setagaya-ku",
                "countryCode": "JP"
            },
            {
                "name": "Shibuya-ku",
                "countryCode": "JP"
            },
            {
                "name": "Shinagawa-ku",
                "countryCode": "JP"
            },
            {
                "name": "Shinjuku-ku",
                "countryCode": "JP"
            },
            {
                "name": "Suginami-ku",
                "countryCode": "JP"
            },
            {
                "name": "Sumida-ku",
                "countryCode": "JP"
            },
            {
                "name": "Tachikawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Taitō-ku",
                "countryCode": "JP"
            },
            {
                "name": "Tama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanashichō",
                "countryCode": "JP"
            },
            {
                "name": "Tokyo",
                "countryCode": "JP"
            },
            {
                "name": "Toshima-ku",
                "countryCode": "JP"
            },
            {
                "name": "Urayasu",
                "countryCode": "JP"
            },
            {
                "name": "Ōme",
                "countryCode": "JP"
            },
            {
                "name": "Ōme-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōta-ku",
                "countryCode": "JP"
            },
            {
                "name": "Atsugi",
                "countryCode": "JP"
            },
            {
                "name": "Atsugi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ayase Shi",
                "countryCode": "JP"
            },
            {
                "name": "Chigasaki",
                "countryCode": "JP"
            },
            {
                "name": "Chigasaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ebina Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujisawa",
                "countryCode": "JP"
            },
            {
                "name": "Fujisawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hadano",
                "countryCode": "JP"
            },
            {
                "name": "Hadano-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hakone",
                "countryCode": "JP"
            },
            {
                "name": "Hayama",
                "countryCode": "JP"
            },
            {
                "name": "Hiratsuka",
                "countryCode": "JP"
            },
            {
                "name": "Hiratsuka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Isehara",
                "countryCode": "JP"
            },
            {
                "name": "Isehara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamakura",
                "countryCode": "JP"
            },
            {
                "name": "Kamakura Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawasaki",
                "countryCode": "JP"
            },
            {
                "name": "Kawasaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamiashigara",
                "countryCode": "JP"
            },
            {
                "name": "Minamiashigara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamirinkan",
                "countryCode": "JP"
            },
            {
                "name": "Miura",
                "countryCode": "JP"
            },
            {
                "name": "Miura Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ninomiya",
                "countryCode": "JP"
            },
            {
                "name": "Odawara",
                "countryCode": "JP"
            },
            {
                "name": "Odawara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sagamihara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamakita",
                "countryCode": "JP"
            },
            {
                "name": "Yamato-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yokohama",
                "countryCode": "JP"
            },
            {
                "name": "Yokohama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yokosuka",
                "countryCode": "JP"
            },
            {
                "name": "Yokosuka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yugawara",
                "countryCode": "JP"
            },
            {
                "name": "Zama",
                "countryCode": "JP"
            },
            {
                "name": "Zama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Zushi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōiso",
                "countryCode": "JP"
            },
            {
                "name": "Agano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Arai",
                "countryCode": "JP"
            },
            {
                "name": "Gosen",
                "countryCode": "JP"
            },
            {
                "name": "Gosen Shi",
                "countryCode": "JP"
            },
            {
                "name": "Itoigawa",
                "countryCode": "JP"
            },
            {
                "name": "Itoigawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Jōetsu",
                "countryCode": "JP"
            },
            {
                "name": "Jōetsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kameda-honchō",
                "countryCode": "JP"
            },
            {
                "name": "Kamo",
                "countryCode": "JP"
            },
            {
                "name": "Kamo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashiwazaki",
                "countryCode": "JP"
            },
            {
                "name": "Kashiwazaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Maki",
                "countryCode": "JP"
            },
            {
                "name": "Minamiuonuma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mitsuke",
                "countryCode": "JP"
            },
            {
                "name": "Mitsuke Shi",
                "countryCode": "JP"
            },
            {
                "name": "Muikamachi",
                "countryCode": "JP"
            },
            {
                "name": "Murakami Shi",
                "countryCode": "JP"
            },
            {
                "name": "Muramatsu",
                "countryCode": "JP"
            },
            {
                "name": "Myoko-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagaoka",
                "countryCode": "JP"
            },
            {
                "name": "Nagaoka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Niigata",
                "countryCode": "JP"
            },
            {
                "name": "Niigata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Niitsu-honchō",
                "countryCode": "JP"
            },
            {
                "name": "Ojiya",
                "countryCode": "JP"
            },
            {
                "name": "Ojiya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ryōtsu-minato",
                "countryCode": "JP"
            },
            {
                "name": "Sado Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sanjō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shibata",
                "countryCode": "JP"
            },
            {
                "name": "Shibata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shiozawa",
                "countryCode": "JP"
            },
            {
                "name": "Shirone",
                "countryCode": "JP"
            },
            {
                "name": "Suibara",
                "countryCode": "JP"
            },
            {
                "name": "Tainai",
                "countryCode": "JP"
            },
            {
                "name": "Tainai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tochio-honchō",
                "countryCode": "JP"
            },
            {
                "name": "Tsubame",
                "countryCode": "JP"
            },
            {
                "name": "Tsubame Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōkamachi",
                "countryCode": "JP"
            },
            {
                "name": "Tōkamachi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Uonuma",
                "countryCode": "JP"
            },
            {
                "name": "Uonuma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yoshida-kasugachō",
                "countryCode": "JP"
            },
            {
                "name": "Fukumitsu",
                "countryCode": "JP"
            },
            {
                "name": "Himi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Himimachi",
                "countryCode": "JP"
            },
            {
                "name": "Imizu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamiichi",
                "countryCode": "JP"
            },
            {
                "name": "Kurobe-shi",
                "countryCode": "JP"
            },
            {
                "name": "Namerikawa",
                "countryCode": "JP"
            },
            {
                "name": "Namerikawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nanto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nanto-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nishishinminato",
                "countryCode": "JP"
            },
            {
                "name": "Nyūzen",
                "countryCode": "JP"
            },
            {
                "name": "Oyabe",
                "countryCode": "JP"
            },
            {
                "name": "Oyabe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takaoka",
                "countryCode": "JP"
            },
            {
                "name": "Takaoka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tonami Shi",
                "countryCode": "JP"
            },
            {
                "name": "Toyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Uozu",
                "countryCode": "JP"
            },
            {
                "name": "Uozu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yatsuomachi-higashikumisaka",
                "countryCode": "JP"
            },
            {
                "name": "Hakui",
                "countryCode": "JP"
            },
            {
                "name": "Hakui Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hakusan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kaga Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kahoku Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kanazawa",
                "countryCode": "JP"
            },
            {
                "name": "Kanazawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Komatsu",
                "countryCode": "JP"
            },
            {
                "name": "Matsutō",
                "countryCode": "JP"
            },
            {
                "name": "Nanao Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nomi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nonoichi",
                "countryCode": "JP"
            },
            {
                "name": "Nonoichi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Suzu",
                "countryCode": "JP"
            },
            {
                "name": "Suzu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsubata",
                "countryCode": "JP"
            },
            {
                "name": "Tsurugi-asahimachi",
                "countryCode": "JP"
            },
            {
                "name": "Wajima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Asahi",
                "countryCode": "JP"
            },
            {
                "name": "Awara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Echizen-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukui-shi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuyama",
                "countryCode": "JP"
            },
            {
                "name": "Katsuyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Maruoka",
                "countryCode": "JP"
            },
            {
                "name": "Mikuni",
                "countryCode": "JP"
            },
            {
                "name": "Obama",
                "countryCode": "JP"
            },
            {
                "name": "Obama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ono",
                "countryCode": "JP"
            },
            {
                "name": "Sabae",
                "countryCode": "JP"
            },
            {
                "name": "Sabae-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takefu",
                "countryCode": "JP"
            },
            {
                "name": "Tsuruga",
                "countryCode": "JP"
            },
            {
                "name": "Tsuruga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōi-gun",
                "countryCode": "JP"
            },
            {
                "name": "Ōno-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chūō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Enzan",
                "countryCode": "JP"
            },
            {
                "name": "Fuefuki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fuji-yoshida Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujikawaguchiko",
                "countryCode": "JP"
            },
            {
                "name": "Fujiyoshida",
                "countryCode": "JP"
            },
            {
                "name": "Hokuto",
                "countryCode": "JP"
            },
            {
                "name": "Hokuto-shi",
                "countryCode": "JP"
            },
            {
                "name": "Isawa",
                "countryCode": "JP"
            },
            {
                "name": "Kai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōfu",
                "countryCode": "JP"
            },
            {
                "name": "Kōfu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōshū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Minami Alps-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nirasaki",
                "countryCode": "JP"
            },
            {
                "name": "Nirasaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ryūō",
                "countryCode": "JP"
            },
            {
                "name": "Tsuru-shi",
                "countryCode": "JP"
            },
            {
                "name": "Uenohara",
                "countryCode": "JP"
            },
            {
                "name": "Uenohara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamanashi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōtsuki",
                "countryCode": "JP"
            },
            {
                "name": "Ōtsuki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Azumino-Shi",
                "countryCode": "JP"
            },
            {
                "name": "Chikuma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Chino",
                "countryCode": "JP"
            },
            {
                "name": "Chino Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hakuba",
                "countryCode": "JP"
            },
            {
                "name": "Hotaka",
                "countryCode": "JP"
            },
            {
                "name": "Iida",
                "countryCode": "JP"
            },
            {
                "name": "Iida-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iiyama",
                "countryCode": "JP"
            },
            {
                "name": "Iiyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ina",
                "countryCode": "JP"
            },
            {
                "name": "Ina-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamimaruko",
                "countryCode": "JP"
            },
            {
                "name": "Komagane",
                "countryCode": "JP"
            },
            {
                "name": "Komagane-shi",
                "countryCode": "JP"
            },
            {
                "name": "Komoro",
                "countryCode": "JP"
            },
            {
                "name": "Komoro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsumoto",
                "countryCode": "JP"
            },
            {
                "name": "Matsumoto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagano",
                "countryCode": "JP"
            },
            {
                "name": "Nagano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Okaya",
                "countryCode": "JP"
            },
            {
                "name": "Okaya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Saku",
                "countryCode": "JP"
            },
            {
                "name": "Saku Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shiojiri",
                "countryCode": "JP"
            },
            {
                "name": "Shiojiri-shi",
                "countryCode": "JP"
            },
            {
                "name": "Suwa",
                "countryCode": "JP"
            },
            {
                "name": "Suwa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Suzaka",
                "countryCode": "JP"
            },
            {
                "name": "Suzaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tatsuno",
                "countryCode": "JP"
            },
            {
                "name": "Toyoshina",
                "countryCode": "JP"
            },
            {
                "name": "Tōmi",
                "countryCode": "JP"
            },
            {
                "name": "Tōmi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ueda",
                "countryCode": "JP"
            },
            {
                "name": "Ueda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmachi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmachi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ena-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gero",
                "countryCode": "JP"
            },
            {
                "name": "Gero-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gifu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gujō",
                "countryCode": "JP"
            },
            {
                "name": "Gujō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gōdo",
                "countryCode": "JP"
            },
            {
                "name": "Hashima",
                "countryCode": "JP"
            },
            {
                "name": "Hashima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hida",
                "countryCode": "JP"
            },
            {
                "name": "Hida Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kaizu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kakamigahara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kani-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kasamatsuchō",
                "countryCode": "JP"
            },
            {
                "name": "Mino",
                "countryCode": "JP"
            },
            {
                "name": "Mino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Minokamo",
                "countryCode": "JP"
            },
            {
                "name": "Minokamo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mitake",
                "countryCode": "JP"
            },
            {
                "name": "Mizuho-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mizunami",
                "countryCode": "JP"
            },
            {
                "name": "Mizunami-shi",
                "countryCode": "JP"
            },
            {
                "name": "Motosu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakatsugawa",
                "countryCode": "JP"
            },
            {
                "name": "Nakatsugawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Seki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tajimi",
                "countryCode": "JP"
            },
            {
                "name": "Tajimi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takayama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tarui",
                "countryCode": "JP"
            },
            {
                "name": "Toki",
                "countryCode": "JP"
            },
            {
                "name": "Toki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamagata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōgaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Atami",
                "countryCode": "JP"
            },
            {
                "name": "Atami-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fuji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujieda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fujinomiya",
                "countryCode": "JP"
            },
            {
                "name": "Fujinomiya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukuroi",
                "countryCode": "JP"
            },
            {
                "name": "Fukuroi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gotenba Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hamakita",
                "countryCode": "JP"
            },
            {
                "name": "Hamamatsu",
                "countryCode": "JP"
            },
            {
                "name": "Hamamatsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Heda",
                "countryCode": "JP"
            },
            {
                "name": "Itō",
                "countryCode": "JP"
            },
            {
                "name": "Itō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwata",
                "countryCode": "JP"
            },
            {
                "name": "Iwata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Izu",
                "countryCode": "JP"
            },
            {
                "name": "Izu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Izunokuni-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kakegawa",
                "countryCode": "JP"
            },
            {
                "name": "Kakegawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kanaya",
                "countryCode": "JP"
            },
            {
                "name": "Kikugawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kosai",
                "countryCode": "JP"
            },
            {
                "name": "Kosai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Makinohara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mishima",
                "countryCode": "JP"
            },
            {
                "name": "Mishima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mori",
                "countryCode": "JP"
            },
            {
                "name": "Numazu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Omaezaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sagara",
                "countryCode": "JP"
            },
            {
                "name": "Shimada",
                "countryCode": "JP"
            },
            {
                "name": "Shimada-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimoda",
                "countryCode": "JP"
            },
            {
                "name": "Shimoda-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shizuoka",
                "countryCode": "JP"
            },
            {
                "name": "Shizuoka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Susono",
                "countryCode": "JP"
            },
            {
                "name": "Susono Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yaizu",
                "countryCode": "JP"
            },
            {
                "name": "Yaizu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōyama",
                "countryCode": "JP"
            },
            {
                "name": "Aisai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ama-gun",
                "countryCode": "JP"
            },
            {
                "name": "Anjō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chiryū",
                "countryCode": "JP"
            },
            {
                "name": "Chiryū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chita",
                "countryCode": "JP"
            },
            {
                "name": "Chita-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gamagōri",
                "countryCode": "JP"
            },
            {
                "name": "Gamagōri-shi",
                "countryCode": "JP"
            },
            {
                "name": "Handa",
                "countryCode": "JP"
            },
            {
                "name": "Handa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hekinan",
                "countryCode": "JP"
            },
            {
                "name": "Hekinan-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ichinomiya",
                "countryCode": "JP"
            },
            {
                "name": "Ichinomiya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Inazawa",
                "countryCode": "JP"
            },
            {
                "name": "Inazawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Inuyama",
                "countryCode": "JP"
            },
            {
                "name": "Inuyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ishiki",
                "countryCode": "JP"
            },
            {
                "name": "Iwakura",
                "countryCode": "JP"
            },
            {
                "name": "Iwakura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kanie",
                "countryCode": "JP"
            },
            {
                "name": "Kariya",
                "countryCode": "JP"
            },
            {
                "name": "Kariya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kasugai",
                "countryCode": "JP"
            },
            {
                "name": "Kasugai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitanagoya",
                "countryCode": "JP"
            },
            {
                "name": "Kitanagoya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kiyosu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Komaki",
                "countryCode": "JP"
            },
            {
                "name": "Komaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kozakai-chō",
                "countryCode": "JP"
            },
            {
                "name": "Kuroda",
                "countryCode": "JP"
            },
            {
                "name": "Kōnan",
                "countryCode": "JP"
            },
            {
                "name": "Kōnan-shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyoshi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagakute-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagoya",
                "countryCode": "JP"
            },
            {
                "name": "Nishio",
                "countryCode": "JP"
            },
            {
                "name": "Nishio-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nisshin-shi",
                "countryCode": "JP"
            },
            {
                "name": "Okazaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Owariasahi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Seto",
                "countryCode": "JP"
            },
            {
                "name": "Seto-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shinshiro",
                "countryCode": "JP"
            },
            {
                "name": "Shinshiro-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sobue",
                "countryCode": "JP"
            },
            {
                "name": "Tahara",
                "countryCode": "JP"
            },
            {
                "name": "Tahara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takahama",
                "countryCode": "JP"
            },
            {
                "name": "Takahama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Taketoyo",
                "countryCode": "JP"
            },
            {
                "name": "Tokoname",
                "countryCode": "JP"
            },
            {
                "name": "Tokoname-shi",
                "countryCode": "JP"
            },
            {
                "name": "Toyoake",
                "countryCode": "JP"
            },
            {
                "name": "Toyoake-shi",
                "countryCode": "JP"
            },
            {
                "name": "Toyohama",
                "countryCode": "JP"
            },
            {
                "name": "Toyohashi",
                "countryCode": "JP"
            },
            {
                "name": "Toyohashi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Toyokawa",
                "countryCode": "JP"
            },
            {
                "name": "Toyokawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Toyota-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsushima",
                "countryCode": "JP"
            },
            {
                "name": "Tsushima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōkai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yatomi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōbu",
                "countryCode": "JP"
            },
            {
                "name": "Ōbu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hisai-motomachi",
                "countryCode": "JP"
            },
            {
                "name": "Iga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Inabe",
                "countryCode": "JP"
            },
            {
                "name": "Inabe-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ise",
                "countryCode": "JP"
            },
            {
                "name": "Ise-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kameyama",
                "countryCode": "JP"
            },
            {
                "name": "Kameyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawage",
                "countryCode": "JP"
            },
            {
                "name": "Komono",
                "countryCode": "JP"
            },
            {
                "name": "Kumano",
                "countryCode": "JP"
            },
            {
                "name": "Kumano-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kuwana",
                "countryCode": "JP"
            },
            {
                "name": "Kuwana-shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsuzaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nabari",
                "countryCode": "JP"
            },
            {
                "name": "Nabari-shi",
                "countryCode": "JP"
            },
            {
                "name": "Owase",
                "countryCode": "JP"
            },
            {
                "name": "Shima",
                "countryCode": "JP"
            },
            {
                "name": "Shima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Suzuka",
                "countryCode": "JP"
            },
            {
                "name": "Suzuka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Toba",
                "countryCode": "JP"
            },
            {
                "name": "Toba-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsu",
                "countryCode": "JP"
            },
            {
                "name": "Tsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ueno-ebisumachi",
                "countryCode": "JP"
            },
            {
                "name": "Yokkaichi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashiōmi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hikone",
                "countryCode": "JP"
            },
            {
                "name": "Hikone-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hino",
                "countryCode": "JP"
            },
            {
                "name": "Kitahama",
                "countryCode": "JP"
            },
            {
                "name": "Konan-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kusatsu",
                "countryCode": "JP"
            },
            {
                "name": "Kusatsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Maibara",
                "countryCode": "JP"
            },
            {
                "name": "Maibara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Minakuchichō-matoba",
                "countryCode": "JP"
            },
            {
                "name": "Moriyama",
                "countryCode": "JP"
            },
            {
                "name": "Moriyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagahama",
                "countryCode": "JP"
            },
            {
                "name": "Nagahama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Rittō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takashima",
                "countryCode": "JP"
            },
            {
                "name": "Takashima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yasu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Youkaichi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmihachiman",
                "countryCode": "JP"
            },
            {
                "name": "Ōmihachiman-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōtsu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Arashiyama",
                "countryCode": "JP"
            },
            {
                "name": "Ayabe",
                "countryCode": "JP"
            },
            {
                "name": "Ayabe-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukuchiyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Jōyō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kameoka",
                "countryCode": "JP"
            },
            {
                "name": "Kameoka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamigyō-ku",
                "countryCode": "JP"
            },
            {
                "name": "Kizugawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kyoto",
                "countryCode": "JP"
            },
            {
                "name": "Kyōtanabe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kyōtango-shi",
                "countryCode": "JP"
            },
            {
                "name": "Maizuru",
                "countryCode": "JP"
            },
            {
                "name": "Maizuru-shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyazu",
                "countryCode": "JP"
            },
            {
                "name": "Miyazu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mukō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagaokakyō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nantan-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanabe",
                "countryCode": "JP"
            },
            {
                "name": "Uji",
                "countryCode": "JP"
            },
            {
                "name": "Uji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yawata",
                "countryCode": "JP"
            },
            {
                "name": "Yawata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Daitō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Daitōchō",
                "countryCode": "JP"
            },
            {
                "name": "Fujiidera-shi",
                "countryCode": "JP"
            },
            {
                "name": "Habikino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hannan",
                "countryCode": "JP"
            },
            {
                "name": "Hannan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashiōsaka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hirakata",
                "countryCode": "JP"
            },
            {
                "name": "Hirakata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ibaraki",
                "countryCode": "JP"
            },
            {
                "name": "Ibaraki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ikeda",
                "countryCode": "JP"
            },
            {
                "name": "Ikeda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Izumi",
                "countryCode": "JP"
            },
            {
                "name": "Izumi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Izumisano",
                "countryCode": "JP"
            },
            {
                "name": "Izumisano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Izumiōtsu",
                "countryCode": "JP"
            },
            {
                "name": "Izumiōtsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kadoma",
                "countryCode": "JP"
            },
            {
                "name": "Kadoma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kaizuka",
                "countryCode": "JP"
            },
            {
                "name": "Kaizuka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashihara",
                "countryCode": "JP"
            },
            {
                "name": "Kashiwara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Katano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawachi-nagano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kishiwada",
                "countryCode": "JP"
            },
            {
                "name": "Kishiwada Shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsubara",
                "countryCode": "JP"
            },
            {
                "name": "Matsubara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mino",
                "countryCode": "JP"
            },
            {
                "name": "Minoo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mishima-gun",
                "countryCode": "JP"
            },
            {
                "name": "Moriguchi",
                "countryCode": "JP"
            },
            {
                "name": "Neyagawa",
                "countryCode": "JP"
            },
            {
                "name": "Neyagawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Osaka",
                "countryCode": "JP"
            },
            {
                "name": "Sakai",
                "countryCode": "JP"
            },
            {
                "name": "Sakai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sennan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Settsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shijonawate Shi",
                "countryCode": "JP"
            },
            {
                "name": "Suita",
                "countryCode": "JP"
            },
            {
                "name": "Suita Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tajiri",
                "countryCode": "JP"
            },
            {
                "name": "Takaishi",
                "countryCode": "JP"
            },
            {
                "name": "Takaishi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takatsuki",
                "countryCode": "JP"
            },
            {
                "name": "Takatsuki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tondabayashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tondabayashichō",
                "countryCode": "JP"
            },
            {
                "name": "Toyonaka",
                "countryCode": "JP"
            },
            {
                "name": "Toyonaka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yao",
                "countryCode": "JP"
            },
            {
                "name": "Yao-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōsaka-sayama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Aioi",
                "countryCode": "JP"
            },
            {
                "name": "Aioi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Akashi",
                "countryCode": "JP"
            },
            {
                "name": "Akashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Amagasaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Asago-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ashiya",
                "countryCode": "JP"
            },
            {
                "name": "Ashiya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Awaji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukura",
                "countryCode": "JP"
            },
            {
                "name": "Himeji",
                "countryCode": "JP"
            },
            {
                "name": "Himeji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Itami",
                "countryCode": "JP"
            },
            {
                "name": "Itami Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kakogawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kakogawachō-honmachi",
                "countryCode": "JP"
            },
            {
                "name": "Kariya",
                "countryCode": "JP"
            },
            {
                "name": "Kasai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Katō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawanishi",
                "countryCode": "JP"
            },
            {
                "name": "Kawanishi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kobe",
                "countryCode": "JP"
            },
            {
                "name": "Miki",
                "countryCode": "JP"
            },
            {
                "name": "Miki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamiawaji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nishinomiya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nishinomiya-hama",
                "countryCode": "JP"
            },
            {
                "name": "Nishiwaki",
                "countryCode": "JP"
            },
            {
                "name": "Nishiwaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ono",
                "countryCode": "JP"
            },
            {
                "name": "Ono Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sanda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sandachō",
                "countryCode": "JP"
            },
            {
                "name": "Sasayama",
                "countryCode": "JP"
            },
            {
                "name": "Sasayama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shirahamachō-usazakiminami",
                "countryCode": "JP"
            },
            {
                "name": "Shisō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sumoto",
                "countryCode": "JP"
            },
            {
                "name": "Sumoto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Taka-gun",
                "countryCode": "JP"
            },
            {
                "name": "Takarazuka",
                "countryCode": "JP"
            },
            {
                "name": "Takarazuka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takasago Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanba-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tatsuno-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tatsunochō-tominaga",
                "countryCode": "JP"
            },
            {
                "name": "Toyooka",
                "countryCode": "JP"
            },
            {
                "name": "Toyooka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yabu",
                "countryCode": "JP"
            },
            {
                "name": "Yabu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamazakichō-nakabirose",
                "countryCode": "JP"
            },
            {
                "name": "Yashiro",
                "countryCode": "JP"
            },
            {
                "name": "Yonedacho Sendo",
                "countryCode": "JP"
            },
            {
                "name": "Gojō",
                "countryCode": "JP"
            },
            {
                "name": "Gojō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Gose",
                "countryCode": "JP"
            },
            {
                "name": "Haibara-akanedai",
                "countryCode": "JP"
            },
            {
                "name": "Hōryūji",
                "countryCode": "JP"
            },
            {
                "name": "Ikoma",
                "countryCode": "JP"
            },
            {
                "name": "Ikoma-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashiba-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashihara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuragi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuragi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakurai",
                "countryCode": "JP"
            },
            {
                "name": "Sakurai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tawaramoto",
                "countryCode": "JP"
            },
            {
                "name": "Tenri",
                "countryCode": "JP"
            },
            {
                "name": "Tenri-shi",
                "countryCode": "JP"
            },
            {
                "name": "Uda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamatokōriyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamatotakada-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yoshino-chō",
                "countryCode": "JP"
            },
            {
                "name": "Arida Shi",
                "countryCode": "JP"
            },
            {
                "name": "Gobō",
                "countryCode": "JP"
            },
            {
                "name": "Hashimoto",
                "countryCode": "JP"
            },
            {
                "name": "Hashimoto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iwade",
                "countryCode": "JP"
            },
            {
                "name": "Iwade Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kainan",
                "countryCode": "JP"
            },
            {
                "name": "Kainan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kinokawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōya",
                "countryCode": "JP"
            },
            {
                "name": "Minato",
                "countryCode": "JP"
            },
            {
                "name": "Shingū",
                "countryCode": "JP"
            },
            {
                "name": "Shingū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanabe-shi",
                "countryCode": "JP"
            },
            {
                "name": "Wakayama",
                "countryCode": "JP"
            },
            {
                "name": "Wakayama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kurayoshi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakaiminato",
                "countryCode": "JP"
            },
            {
                "name": "Sakaiminato Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tottori-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yonago Shi",
                "countryCode": "JP"
            },
            {
                "name": "Gōtsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Gōtsuchō",
                "countryCode": "JP"
            },
            {
                "name": "Hamada",
                "countryCode": "JP"
            },
            {
                "name": "Hamada Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hiratachō",
                "countryCode": "JP"
            },
            {
                "name": "Izumo",
                "countryCode": "JP"
            },
            {
                "name": "Izumo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Masuda",
                "countryCode": "JP"
            },
            {
                "name": "Masuda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsue",
                "countryCode": "JP"
            },
            {
                "name": "Matsue Shi",
                "countryCode": "JP"
            },
            {
                "name": "Unnan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yasugi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yasugichō",
                "countryCode": "JP"
            },
            {
                "name": "Ōda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōdachō-ōda",
                "countryCode": "JP"
            },
            {
                "name": "Akaiwa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Asakuchi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Bizen Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ibara",
                "countryCode": "JP"
            },
            {
                "name": "Ibara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kamogatachō-kamogata",
                "countryCode": "JP"
            },
            {
                "name": "Kasaoka",
                "countryCode": "JP"
            },
            {
                "name": "Kasaoka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kurashiki",
                "countryCode": "JP"
            },
            {
                "name": "Kurashiki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Maniwa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mimasaka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Niimi",
                "countryCode": "JP"
            },
            {
                "name": "Niimi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Okayama",
                "countryCode": "JP"
            },
            {
                "name": "Okayama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Setouchi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sōja",
                "countryCode": "JP"
            },
            {
                "name": "Sōja Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takahashi",
                "countryCode": "JP"
            },
            {
                "name": "Takahashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tamano",
                "countryCode": "JP"
            },
            {
                "name": "Tamano Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsuyama",
                "countryCode": "JP"
            },
            {
                "name": "Tsuyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Aki-takata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Etajima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fuchū-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fuchūchō",
                "countryCode": "JP"
            },
            {
                "name": "Fukuyama",
                "countryCode": "JP"
            },
            {
                "name": "Fukuyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hatsukaichi",
                "countryCode": "JP"
            },
            {
                "name": "Hatsukaichi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashihiroshima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hiroshima",
                "countryCode": "JP"
            },
            {
                "name": "Hiroshima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Innoshima",
                "countryCode": "JP"
            },
            {
                "name": "Kannabechō-yahiro",
                "countryCode": "JP"
            },
            {
                "name": "Kure",
                "countryCode": "JP"
            },
            {
                "name": "Kure-shi",
                "countryCode": "JP"
            },
            {
                "name": "Mihara",
                "countryCode": "JP"
            },
            {
                "name": "Mihara Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyajima",
                "countryCode": "JP"
            },
            {
                "name": "Miyoshi",
                "countryCode": "JP"
            },
            {
                "name": "Miyoshi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Onomichi",
                "countryCode": "JP"
            },
            {
                "name": "Onomichi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shin’ichi",
                "countryCode": "JP"
            },
            {
                "name": "Shōbara",
                "countryCode": "JP"
            },
            {
                "name": "Shōbara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takehara",
                "countryCode": "JP"
            },
            {
                "name": "Takehara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōno-hara",
                "countryCode": "JP"
            },
            {
                "name": "Ōtake",
                "countryCode": "JP"
            },
            {
                "name": "Ōtake-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hagi",
                "countryCode": "JP"
            },
            {
                "name": "Hagi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hikari",
                "countryCode": "JP"
            },
            {
                "name": "Hikari Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hofu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hōfu",
                "countryCode": "JP"
            },
            {
                "name": "Iwakuni Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kudamatsu",
                "countryCode": "JP"
            },
            {
                "name": "Kudamatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mine Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagato",
                "countryCode": "JP"
            },
            {
                "name": "Nagato Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ogōri-shimogō",
                "countryCode": "JP"
            },
            {
                "name": "Onoda",
                "countryCode": "JP"
            },
            {
                "name": "Sanyōonoda Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimonoseki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shūnan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tokuyama",
                "countryCode": "JP"
            },
            {
                "name": "Ube",
                "countryCode": "JP"
            },
            {
                "name": "Yamaguchi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yanai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Anan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Awa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ikedachō",
                "countryCode": "JP"
            },
            {
                "name": "Ishii",
                "countryCode": "JP"
            },
            {
                "name": "Kamojimachō-jōgejima",
                "countryCode": "JP"
            },
            {
                "name": "Katsuura Gun",
                "countryCode": "JP"
            },
            {
                "name": "Komatsushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Komatsushimachō",
                "countryCode": "JP"
            },
            {
                "name": "Mima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyoshi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyoshi-gun",
                "countryCode": "JP"
            },
            {
                "name": "Naruto-shi",
                "countryCode": "JP"
            },
            {
                "name": "Narutochō-mitsuishi",
                "countryCode": "JP"
            },
            {
                "name": "Tokushima",
                "countryCode": "JP"
            },
            {
                "name": "Tokushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Wakimachi",
                "countryCode": "JP"
            },
            {
                "name": "Yoshinogawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Higashikagawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kan’onji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kan’onjichō",
                "countryCode": "JP"
            },
            {
                "name": "Marugame",
                "countryCode": "JP"
            },
            {
                "name": "Marugame Shi",
                "countryCode": "JP"
            },
            {
                "name": "Mitoyo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakaide Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sakaidechō",
                "countryCode": "JP"
            },
            {
                "name": "Sanuki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shido",
                "countryCode": "JP"
            },
            {
                "name": "Tadotsu",
                "countryCode": "JP"
            },
            {
                "name": "Takamatsu",
                "countryCode": "JP"
            },
            {
                "name": "Takamatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tonoshō",
                "countryCode": "JP"
            },
            {
                "name": "Zentsūji Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hōjō",
                "countryCode": "JP"
            },
            {
                "name": "Imabari-shi",
                "countryCode": "JP"
            },
            {
                "name": "Iyo",
                "countryCode": "JP"
            },
            {
                "name": "Iyo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawanoechō",
                "countryCode": "JP"
            },
            {
                "name": "Kihoku-chō",
                "countryCode": "JP"
            },
            {
                "name": "Masaki-chō",
                "countryCode": "JP"
            },
            {
                "name": "Matsuyama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Niihama",
                "countryCode": "JP"
            },
            {
                "name": "Niihama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Saijō",
                "countryCode": "JP"
            },
            {
                "name": "Saijō-shi",
                "countryCode": "JP"
            },
            {
                "name": "Seiyo-shi",
                "countryCode": "JP"
            },
            {
                "name": "Shikoku-chūō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tōon-shi",
                "countryCode": "JP"
            },
            {
                "name": "Uwajima",
                "countryCode": "JP"
            },
            {
                "name": "Uwajima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Yawatahama-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōzu",
                "countryCode": "JP"
            },
            {
                "name": "Ōzu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Achi",
                "countryCode": "JP"
            },
            {
                "name": "Agematsu",
                "countryCode": "JP"
            },
            {
                "name": "Aki",
                "countryCode": "JP"
            },
            {
                "name": "Anan",
                "countryCode": "JP"
            },
            {
                "name": "Aoki",
                "countryCode": "JP"
            },
            {
                "name": "Asahi",
                "countryCode": "JP"
            },
            {
                "name": "Azumino",
                "countryCode": "JP"
            },
            {
                "name": "Chikuhoku",
                "countryCode": "JP"
            },
            {
                "name": "Chikuma",
                "countryCode": "JP"
            },
            {
                "name": "Chino",
                "countryCode": "JP"
            },
            {
                "name": "Fujimi",
                "countryCode": "JP"
            },
            {
                "name": "Geisei",
                "countryCode": "JP"
            },
            {
                "name": "Hakuba",
                "countryCode": "JP"
            },
            {
                "name": "Hara",
                "countryCode": "JP"
            },
            {
                "name": "Hidaka",
                "countryCode": "JP"
            },
            {
                "name": "Hiraya",
                "countryCode": "JP"
            },
            {
                "name": "Iida",
                "countryCode": "JP"
            },
            {
                "name": "Iijima",
                "countryCode": "JP"
            },
            {
                "name": "Iiyama",
                "countryCode": "JP"
            },
            {
                "name": "Iizuna",
                "countryCode": "JP"
            },
            {
                "name": "Ikeda",
                "countryCode": "JP"
            },
            {
                "name": "Ikusaka",
                "countryCode": "JP"
            },
            {
                "name": "Ina",
                "countryCode": "JP"
            },
            {
                "name": "Ino",
                "countryCode": "JP"
            },
            {
                "name": "Karuizawa",
                "countryCode": "JP"
            },
            {
                "name": "Kawakami",
                "countryCode": "JP"
            },
            {
                "name": "Kijimadaira",
                "countryCode": "JP"
            },
            {
                "name": "Kiso",
                "countryCode": "JP"
            },
            {
                "name": "Kitaaiki",
                "countryCode": "JP"
            },
            {
                "name": "Kitagawa",
                "countryCode": "JP"
            },
            {
                "name": "Komoro",
                "countryCode": "JP"
            },
            {
                "name": "Kuroshio",
                "countryCode": "JP"
            },
            {
                "name": "Kōchi",
                "countryCode": "JP"
            },
            {
                "name": "Kōnan",
                "countryCode": "JP"
            },
            {
                "name": "Matsukawa",
                "countryCode": "JP"
            },
            {
                "name": "Matsumoto",
                "countryCode": "JP"
            },
            {
                "name": "Mihara",
                "countryCode": "JP"
            },
            {
                "name": "Minamiaiki",
                "countryCode": "JP"
            },
            {
                "name": "Minamimaki",
                "countryCode": "JP"
            },
            {
                "name": "Minamiminowa",
                "countryCode": "JP"
            },
            {
                "name": "Minowa",
                "countryCode": "JP"
            },
            {
                "name": "Mitsushima",
                "countryCode": "JP"
            },
            {
                "name": "Miyada",
                "countryCode": "JP"
            },
            {
                "name": "Miyota",
                "countryCode": "JP"
            },
            {
                "name": "Motoyama",
                "countryCode": "JP"
            },
            {
                "name": "Muroto",
                "countryCode": "JP"
            },
            {
                "name": "Nagano",
                "countryCode": "JP"
            },
            {
                "name": "Nagawa",
                "countryCode": "JP"
            },
            {
                "name": "Nagiso",
                "countryCode": "JP"
            },
            {
                "name": "Nahari",
                "countryCode": "JP"
            },
            {
                "name": "Nakagawa",
                "countryCode": "JP"
            },
            {
                "name": "Nakajō",
                "countryCode": "JP"
            },
            {
                "name": "Nakamura",
                "countryCode": "JP"
            },
            {
                "name": "Nakano",
                "countryCode": "JP"
            },
            {
                "name": "Nakatosa",
                "countryCode": "JP"
            },
            {
                "name": "Nankoku",
                "countryCode": "JP"
            },
            {
                "name": "Neba",
                "countryCode": "JP"
            },
            {
                "name": "Niyodogawa",
                "countryCode": "JP"
            },
            {
                "name": "Nozawaonsen",
                "countryCode": "JP"
            },
            {
                "name": "Obuse",
                "countryCode": "JP"
            },
            {
                "name": "Ochi",
                "countryCode": "JP"
            },
            {
                "name": "Ogawa",
                "countryCode": "JP"
            },
            {
                "name": "Okaya",
                "countryCode": "JP"
            },
            {
                "name": "Omi",
                "countryCode": "JP"
            },
            {
                "name": "Otari",
                "countryCode": "JP"
            },
            {
                "name": "Sakae",
                "countryCode": "JP"
            },
            {
                "name": "Sakaki",
                "countryCode": "JP"
            },
            {
                "name": "Sakawa",
                "countryCode": "JP"
            },
            {
                "name": "Saku",
                "countryCode": "JP"
            },
            {
                "name": "Sakuho",
                "countryCode": "JP"
            },
            {
                "name": "Sanada",
                "countryCode": "JP"
            },
            {
                "name": "Shimanto",
                "countryCode": "JP"
            },
            {
                "name": "Shimojō",
                "countryCode": "JP"
            },
            {
                "name": "Shimosuwa",
                "countryCode": "JP"
            },
            {
                "name": "Shinano",
                "countryCode": "JP"
            },
            {
                "name": "Shiojiri",
                "countryCode": "JP"
            },
            {
                "name": "Sukumo",
                "countryCode": "JP"
            },
            {
                "name": "Susaki",
                "countryCode": "JP"
            },
            {
                "name": "Suwa",
                "countryCode": "JP"
            },
            {
                "name": "Suzaka",
                "countryCode": "JP"
            },
            {
                "name": "Takagi",
                "countryCode": "JP"
            },
            {
                "name": "Takamori",
                "countryCode": "JP"
            },
            {
                "name": "Takayama",
                "countryCode": "JP"
            },
            {
                "name": "Tano",
                "countryCode": "JP"
            },
            {
                "name": "Tateshina",
                "countryCode": "JP"
            },
            {
                "name": "Tatsuno",
                "countryCode": "JP"
            },
            {
                "name": "Togari",
                "countryCode": "JP"
            },
            {
                "name": "Tosa",
                "countryCode": "JP"
            },
            {
                "name": "Tosashimizu",
                "countryCode": "JP"
            },
            {
                "name": "Toyooka",
                "countryCode": "JP"
            },
            {
                "name": "Tsuno",
                "countryCode": "JP"
            },
            {
                "name": "Tōmi",
                "countryCode": "JP"
            },
            {
                "name": "Tōyō",
                "countryCode": "JP"
            },
            {
                "name": "Ueda",
                "countryCode": "JP"
            },
            {
                "name": "Umaji",
                "countryCode": "JP"
            },
            {
                "name": "Urugi",
                "countryCode": "JP"
            },
            {
                "name": "Usa",
                "countryCode": "JP"
            },
            {
                "name": "Yamagata",
                "countryCode": "JP"
            },
            {
                "name": "Yamanouchi",
                "countryCode": "JP"
            },
            {
                "name": "Yasuda",
                "countryCode": "JP"
            },
            {
                "name": "Yasuoka",
                "countryCode": "JP"
            },
            {
                "name": "Yawata",
                "countryCode": "JP"
            },
            {
                "name": "Yusuhara",
                "countryCode": "JP"
            },
            {
                "name": "Ōkawa",
                "countryCode": "JP"
            },
            {
                "name": "Ōkuwa",
                "countryCode": "JP"
            },
            {
                "name": "Ōmachi",
                "countryCode": "JP"
            },
            {
                "name": "Ōshika",
                "countryCode": "JP"
            },
            {
                "name": "Ōtaki",
                "countryCode": "JP"
            },
            {
                "name": "Ōtoyo",
                "countryCode": "JP"
            },
            {
                "name": "Ōtsuki",
                "countryCode": "JP"
            },
            {
                "name": "Amagi",
                "countryCode": "JP"
            },
            {
                "name": "Asakura Shi",
                "countryCode": "JP"
            },
            {
                "name": "Buzen",
                "countryCode": "JP"
            },
            {
                "name": "Buzen-shi",
                "countryCode": "JP"
            },
            {
                "name": "Chikugo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Chikushino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Dazaifu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukuoka",
                "countryCode": "JP"
            },
            {
                "name": "Fukuoka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukutsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iizuka",
                "countryCode": "JP"
            },
            {
                "name": "Iizuka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Itoshima-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kanda",
                "countryCode": "JP"
            },
            {
                "name": "Kasuga Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kawasaki",
                "countryCode": "JP"
            },
            {
                "name": "Kitakyushu",
                "countryCode": "JP"
            },
            {
                "name": "Koga",
                "countryCode": "JP"
            },
            {
                "name": "Koga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kurume",
                "countryCode": "JP"
            },
            {
                "name": "Kurume Shi",
                "countryCode": "JP"
            },
            {
                "name": "Maebaru-chūō",
                "countryCode": "JP"
            },
            {
                "name": "Miyama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyata",
                "countryCode": "JP"
            },
            {
                "name": "Miyawaka Shi",
                "countryCode": "JP"
            },
            {
                "name": "Munakata-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakama",
                "countryCode": "JP"
            },
            {
                "name": "Nakama Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nishifukuma",
                "countryCode": "JP"
            },
            {
                "name": "Nōgata",
                "countryCode": "JP"
            },
            {
                "name": "Nōgata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ogōri-shi",
                "countryCode": "JP"
            },
            {
                "name": "Onga-gun",
                "countryCode": "JP"
            },
            {
                "name": "Sasaguri",
                "countryCode": "JP"
            },
            {
                "name": "Setakamachi-takayanagi",
                "countryCode": "JP"
            },
            {
                "name": "Shiida",
                "countryCode": "JP"
            },
            {
                "name": "Shingū",
                "countryCode": "JP"
            },
            {
                "name": "Tagawa",
                "countryCode": "JP"
            },
            {
                "name": "Tagawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tanushimarumachi-toyoki",
                "countryCode": "JP"
            },
            {
                "name": "Tsuiki",
                "countryCode": "JP"
            },
            {
                "name": "Ukiha Shi",
                "countryCode": "JP"
            },
            {
                "name": "Umi",
                "countryCode": "JP"
            },
            {
                "name": "Yame Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yanagawa",
                "countryCode": "JP"
            },
            {
                "name": "Yanagawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yoshitomi",
                "countryCode": "JP"
            },
            {
                "name": "Yukuhashi",
                "countryCode": "JP"
            },
            {
                "name": "Yukuhashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōkawa",
                "countryCode": "JP"
            },
            {
                "name": "Ōkawa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmuta",
                "countryCode": "JP"
            },
            {
                "name": "Ōmuta Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōnojō",
                "countryCode": "JP"
            },
            {
                "name": "Ōnojō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Imari Shi",
                "countryCode": "JP"
            },
            {
                "name": "Imarichō-kō",
                "countryCode": "JP"
            },
            {
                "name": "Kanzaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Karatsu",
                "countryCode": "JP"
            },
            {
                "name": "Karatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kashima",
                "countryCode": "JP"
            },
            {
                "name": "Kashima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyaki-gun",
                "countryCode": "JP"
            },
            {
                "name": "Ogi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Saga",
                "countryCode": "JP"
            },
            {
                "name": "Saga Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takeo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Takeochō-takeo",
                "countryCode": "JP"
            },
            {
                "name": "Taku Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tosu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ureshino Shi",
                "countryCode": "JP"
            },
            {
                "name": "Fukuechō",
                "countryCode": "JP"
            },
            {
                "name": "Gotō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hirado",
                "countryCode": "JP"
            },
            {
                "name": "Hirado Shi",
                "countryCode": "JP"
            },
            {
                "name": "Iki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Isahaya",
                "countryCode": "JP"
            },
            {
                "name": "Isahaya-shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsuura",
                "countryCode": "JP"
            },
            {
                "name": "Matsuura Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamishimabara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nagasaki",
                "countryCode": "JP"
            },
            {
                "name": "Nagasaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Obita",
                "countryCode": "JP"
            },
            {
                "name": "Saikai-shi",
                "countryCode": "JP"
            },
            {
                "name": "Sasebo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shimabara",
                "countryCode": "JP"
            },
            {
                "name": "Shimabara-shi",
                "countryCode": "JP"
            },
            {
                "name": "Togitsu",
                "countryCode": "JP"
            },
            {
                "name": "Tsushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Unzen-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōmura",
                "countryCode": "JP"
            },
            {
                "name": "Ōmura-shi",
                "countryCode": "JP"
            },
            {
                "name": "Amakusa Gun",
                "countryCode": "JP"
            },
            {
                "name": "Amakusa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Arao Shi",
                "countryCode": "JP"
            },
            {
                "name": "Aso-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hitoyoshi",
                "countryCode": "JP"
            },
            {
                "name": "Hitoyoshi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hondomachi-hondo",
                "countryCode": "JP"
            },
            {
                "name": "Honmachi",
                "countryCode": "JP"
            },
            {
                "name": "Kamiamakusa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kikuchi",
                "countryCode": "JP"
            },
            {
                "name": "Kikuchi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kumamoto",
                "countryCode": "JP"
            },
            {
                "name": "Kumamoto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kōshi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Matsubase",
                "countryCode": "JP"
            },
            {
                "name": "Minamata",
                "countryCode": "JP"
            },
            {
                "name": "Minamata Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tamana",
                "countryCode": "JP"
            },
            {
                "name": "Tamana Shi",
                "countryCode": "JP"
            },
            {
                "name": "Uekimachi-mōno",
                "countryCode": "JP"
            },
            {
                "name": "Uki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ushibukamachi",
                "countryCode": "JP"
            },
            {
                "name": "Uto",
                "countryCode": "JP"
            },
            {
                "name": "Uto Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yamaga Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yatsushiro Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōzu",
                "countryCode": "JP"
            },
            {
                "name": "Beppu",
                "countryCode": "JP"
            },
            {
                "name": "Beppu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Bungo-Takada-shi",
                "countryCode": "JP"
            },
            {
                "name": "Bungo-ōno Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hiji",
                "countryCode": "JP"
            },
            {
                "name": "Hita",
                "countryCode": "JP"
            },
            {
                "name": "Hita Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kitsuki",
                "countryCode": "JP"
            },
            {
                "name": "Kitsuki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kunisaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakatsu",
                "countryCode": "JP"
            },
            {
                "name": "Nakatsu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Saiki",
                "countryCode": "JP"
            },
            {
                "name": "Saiki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takedamachi",
                "countryCode": "JP"
            },
            {
                "name": "Taketa-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsukawaki",
                "countryCode": "JP"
            },
            {
                "name": "Tsukumi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Tsukumiura",
                "countryCode": "JP"
            },
            {
                "name": "Tsurusaki",
                "countryCode": "JP"
            },
            {
                "name": "Usa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Usuki",
                "countryCode": "JP"
            },
            {
                "name": "Usuki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yufu-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ōita",
                "countryCode": "JP"
            },
            {
                "name": "Ōita-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ebino-shi",
                "countryCode": "JP"
            },
            {
                "name": "Hyūga-shi",
                "countryCode": "JP"
            },
            {
                "name": "Kobayashi",
                "countryCode": "JP"
            },
            {
                "name": "Kobayashi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kushima",
                "countryCode": "JP"
            },
            {
                "name": "Kushima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyakonojō",
                "countryCode": "JP"
            },
            {
                "name": "Miyakonojō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyazaki",
                "countryCode": "JP"
            },
            {
                "name": "Miyazaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Nichinan",
                "countryCode": "JP"
            },
            {
                "name": "Nichinan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nobeoka",
                "countryCode": "JP"
            },
            {
                "name": "Nobeoka-shi",
                "countryCode": "JP"
            },
            {
                "name": "Saito-shi",
                "countryCode": "JP"
            },
            {
                "name": "Takanabe",
                "countryCode": "JP"
            },
            {
                "name": "Tsuma",
                "countryCode": "JP"
            },
            {
                "name": "Aira Shi",
                "countryCode": "JP"
            },
            {
                "name": "Akune",
                "countryCode": "JP"
            },
            {
                "name": "Akune Shi",
                "countryCode": "JP"
            },
            {
                "name": "Amami",
                "countryCode": "JP"
            },
            {
                "name": "Amami Shi",
                "countryCode": "JP"
            },
            {
                "name": "Hamanoichi",
                "countryCode": "JP"
            },
            {
                "name": "Hioki",
                "countryCode": "JP"
            },
            {
                "name": "Hioki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ibusuki",
                "countryCode": "JP"
            },
            {
                "name": "Ibusuki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ichikikushikino Shi",
                "countryCode": "JP"
            },
            {
                "name": "Ijūin",
                "countryCode": "JP"
            },
            {
                "name": "Isa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Izumi",
                "countryCode": "JP"
            },
            {
                "name": "Izumi Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kagoshima",
                "countryCode": "JP"
            },
            {
                "name": "Kagoshima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kajiki",
                "countryCode": "JP"
            },
            {
                "name": "Kanoya",
                "countryCode": "JP"
            },
            {
                "name": "Kanoya Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kaseda-shirakame",
                "countryCode": "JP"
            },
            {
                "name": "Kirishima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Kokubu-matsuki",
                "countryCode": "JP"
            },
            {
                "name": "Koseda",
                "countryCode": "JP"
            },
            {
                "name": "Koshima",
                "countryCode": "JP"
            },
            {
                "name": "Kurio",
                "countryCode": "JP"
            },
            {
                "name": "Kushikino",
                "countryCode": "JP"
            },
            {
                "name": "Makurazaki",
                "countryCode": "JP"
            },
            {
                "name": "Makurazaki Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamikyushu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Minamisatsuma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Miyanoura",
                "countryCode": "JP"
            },
            {
                "name": "Nagata",
                "countryCode": "JP"
            },
            {
                "name": "Naze",
                "countryCode": "JP"
            },
            {
                "name": "Nishinoomote",
                "countryCode": "JP"
            },
            {
                "name": "Nishinoomote Shi",
                "countryCode": "JP"
            },
            {
                "name": "Satsumasendai",
                "countryCode": "JP"
            },
            {
                "name": "Satsumasendai Shi",
                "countryCode": "JP"
            },
            {
                "name": "Shibushi",
                "countryCode": "JP"
            },
            {
                "name": "Shibushi-shi",
                "countryCode": "JP"
            },
            {
                "name": "Soo Shi",
                "countryCode": "JP"
            },
            {
                "name": "Sueyoshichō-ninokata",
                "countryCode": "JP"
            },
            {
                "name": "Tarumizu",
                "countryCode": "JP"
            },
            {
                "name": "Tarumizu Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yudomari",
                "countryCode": "JP"
            },
            {
                "name": "Ōkuchi-shinohara",
                "countryCode": "JP"
            },
            {
                "name": "Chatan",
                "countryCode": "JP"
            },
            {
                "name": "Ginowan Shi",
                "countryCode": "JP"
            },
            {
                "name": "Gushikawa",
                "countryCode": "JP"
            },
            {
                "name": "Hirara",
                "countryCode": "JP"
            },
            {
                "name": "Ishigaki",
                "countryCode": "JP"
            },
            {
                "name": "Ishigaki-shi",
                "countryCode": "JP"
            },
            {
                "name": "Ishikawa",
                "countryCode": "JP"
            },
            {
                "name": "Itoman",
                "countryCode": "JP"
            },
            {
                "name": "Itoman Shi",
                "countryCode": "JP"
            },
            {
                "name": "Katsuren-haebaru",
                "countryCode": "JP"
            },
            {
                "name": "Miyakojima Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nago",
                "countryCode": "JP"
            },
            {
                "name": "Nago Shi",
                "countryCode": "JP"
            },
            {
                "name": "Naha",
                "countryCode": "JP"
            },
            {
                "name": "Naha Shi",
                "countryCode": "JP"
            },
            {
                "name": "Nakagami-gun",
                "countryCode": "JP"
            },
            {
                "name": "Nanjō",
                "countryCode": "JP"
            },
            {
                "name": "Nanjō Shi",
                "countryCode": "JP"
            },
            {
                "name": "Okinawa",
                "countryCode": "JP"
            },
            {
                "name": "Okinawa Shi",
                "countryCode": "JP"
            },
            {
                "name": "Tomigusuku",
                "countryCode": "JP"
            },
            {
                "name": "Tomigusuku-shi",
                "countryCode": "JP"
            },
            {
                "name": "Urasoe Shi",
                "countryCode": "JP"
            },
            {
                "name": "Uruma Shi",
                "countryCode": "JP"
            },
            {
                "name": "Yonakuni",
                "countryCode": "JP"
            }
        ]
    },
    {
        "name": "Jersey",
        "phonecode": "+44-1534",
        "cities": []
    },
    {
        "name": "Jordan",
        "phonecode": "962",
        "cities": [
            {
                "name": "Şakhrah",
                "countryCode": "JO"
            },
            {
                "name": "Ḩalāwah",
                "countryCode": "JO"
            },
            {
                "name": "‘Ajlūn",
                "countryCode": "JO"
            },
            {
                "name": "‘Anjarah",
                "countryCode": "JO"
            },
            {
                "name": "‘Ayn Jannah",
                "countryCode": "JO"
            },
            {
                "name": "Al Jubayhah",
                "countryCode": "JO"
            },
            {
                "name": "Al Jīzah",
                "countryCode": "JO"
            },
            {
                "name": "Amman",
                "countryCode": "JO"
            },
            {
                "name": "Jāwā",
                "countryCode": "JO"
            },
            {
                "name": "Saḩāb",
                "countryCode": "JO"
            },
            {
                "name": "Umm as Summāq",
                "countryCode": "JO"
            },
            {
                "name": "Wādī as Sīr",
                "countryCode": "JO"
            },
            {
                "name": "Ḩayy al Bunayyāt",
                "countryCode": "JO"
            },
            {
                "name": "Ḩayy al Quwaysimah",
                "countryCode": "JO"
            },
            {
                "name": "Aqaba",
                "countryCode": "JO"
            },
            {
                "name": "Tala Bay",
                "countryCode": "JO"
            },
            {
                "name": "Aţ Ţafīlah",
                "countryCode": "JO"
            },
            {
                "name": "Buşayrā",
                "countryCode": "JO"
            },
            {
                "name": "Al Azraq ash Shamālī",
                "countryCode": "JO"
            },
            {
                "name": "Russeifa",
                "countryCode": "JO"
            },
            {
                "name": "Zarqa",
                "countryCode": "JO"
            },
            {
                "name": "Al Karāmah",
                "countryCode": "JO"
            },
            {
                "name": "As Salţ",
                "countryCode": "JO"
            },
            {
                "name": "Yarqā",
                "countryCode": "JO"
            },
            {
                "name": "Ar Ramthā",
                "countryCode": "JO"
            },
            {
                "name": "Ash Shajarah",
                "countryCode": "JO"
            },
            {
                "name": "Aydūn",
                "countryCode": "JO"
            },
            {
                "name": "Aţ Ţayyibah",
                "countryCode": "JO"
            },
            {
                "name": "Aţ Ţurrah",
                "countryCode": "JO"
            },
            {
                "name": "Bayt Yāfā",
                "countryCode": "JO"
            },
            {
                "name": "Bayt Īdis",
                "countryCode": "JO"
            },
            {
                "name": "Dayr Yūsuf",
                "countryCode": "JO"
            },
            {
                "name": "Irbid",
                "countryCode": "JO"
            },
            {
                "name": "Judita",
                "countryCode": "JO"
            },
            {
                "name": "Kafr Abīl",
                "countryCode": "JO"
            },
            {
                "name": "Kafr Asad",
                "countryCode": "JO"
            },
            {
                "name": "Kafr Sawm",
                "countryCode": "JO"
            },
            {
                "name": "Kharjā",
                "countryCode": "JO"
            },
            {
                "name": "Kitim",
                "countryCode": "JO"
            },
            {
                "name": "Kurayyimah",
                "countryCode": "JO"
            },
            {
                "name": "Malkā",
                "countryCode": "JO"
            },
            {
                "name": "Qumaym",
                "countryCode": "JO"
            },
            {
                "name": "Saḩam al Kaffārāt",
                "countryCode": "JO"
            },
            {
                "name": "Sāl",
                "countryCode": "JO"
            },
            {
                "name": "Tibnah",
                "countryCode": "JO"
            },
            {
                "name": "Umm Qays",
                "countryCode": "JO"
            },
            {
                "name": "Waqqāş",
                "countryCode": "JO"
            },
            {
                "name": "Zaḩar",
                "countryCode": "JO"
            },
            {
                "name": "Şammā",
                "countryCode": "JO"
            },
            {
                "name": "Ḩakamā",
                "countryCode": "JO"
            },
            {
                "name": "Ḩātim",
                "countryCode": "JO"
            },
            {
                "name": "Al Kittah",
                "countryCode": "JO"
            },
            {
                "name": "Balīlā",
                "countryCode": "JO"
            },
            {
                "name": "Burmā",
                "countryCode": "JO"
            },
            {
                "name": "Jarash",
                "countryCode": "JO"
            },
            {
                "name": "Qafqafā",
                "countryCode": "JO"
            },
            {
                "name": "Raymūn",
                "countryCode": "JO"
            },
            {
                "name": "Sakib",
                "countryCode": "JO"
            },
            {
                "name": "Sūf",
                "countryCode": "JO"
            },
            {
                "name": "Adir",
                "countryCode": "JO"
            },
            {
                "name": "Al Khinzīrah",
                "countryCode": "JO"
            },
            {
                "name": "Al Mazār al Janūbī",
                "countryCode": "JO"
            },
            {
                "name": "Al Qaşr",
                "countryCode": "JO"
            },
            {
                "name": "Ar Rabbah",
                "countryCode": "JO"
            },
            {
                "name": "Karak City",
                "countryCode": "JO"
            },
            {
                "name": "Safi",
                "countryCode": "JO"
            },
            {
                "name": "‘Ayy",
                "countryCode": "JO"
            },
            {
                "name": "‘Izrā",
                "countryCode": "JO"
            },
            {
                "name": "Al Ḩamrā’",
                "countryCode": "JO"
            },
            {
                "name": "Mafraq",
                "countryCode": "JO"
            },
            {
                "name": "Rehab",
                "countryCode": "JO"
            },
            {
                "name": "Rukban",
                "countryCode": "JO"
            },
            {
                "name": "Umm al Qiţţayn",
                "countryCode": "JO"
            },
            {
                "name": "Şabḩā",
                "countryCode": "JO"
            },
            {
                "name": "Mādabā",
                "countryCode": "JO"
            },
            {
                "name": "Al Jafr",
                "countryCode": "JO"
            },
            {
                "name": "Al Quwayrah",
                "countryCode": "JO"
            },
            {
                "name": "Ash Shawbak",
                "countryCode": "JO"
            },
            {
                "name": "Aţ Ţayyibah",
                "countryCode": "JO"
            },
            {
                "name": "Ma'an",
                "countryCode": "JO"
            },
            {
                "name": "Petra",
                "countryCode": "JO"
            },
            {
                "name": "Qīr Moāv",
                "countryCode": "JO"
            }
        ]
    },
    {
        "name": "Kazakhstan",
        "phonecode": "7",
        "cities": [
            {
                "name": "Akkol",
                "countryCode": "KZ"
            },
            {
                "name": "Akkol’",
                "countryCode": "KZ"
            },
            {
                "name": "Aksu",
                "countryCode": "KZ"
            },
            {
                "name": "Astrakhan",
                "countryCode": "KZ"
            },
            {
                "name": "Atbasar",
                "countryCode": "KZ"
            },
            {
                "name": "Balkashino",
                "countryCode": "KZ"
            },
            {
                "name": "Bestobe",
                "countryCode": "KZ"
            },
            {
                "name": "Derzhavīnsk",
                "countryCode": "KZ"
            },
            {
                "name": "Egindiköl",
                "countryCode": "KZ"
            },
            {
                "name": "Esil",
                "countryCode": "KZ"
            },
            {
                "name": "Kokshetau",
                "countryCode": "KZ"
            },
            {
                "name": "Krasnogorskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Makinsk",
                "countryCode": "KZ"
            },
            {
                "name": "Shantobe",
                "countryCode": "KZ"
            },
            {
                "name": "Shchuchinsk",
                "countryCode": "KZ"
            },
            {
                "name": "Shortandy",
                "countryCode": "KZ"
            },
            {
                "name": "Stepnogorsk",
                "countryCode": "KZ"
            },
            {
                "name": "Stepnyak",
                "countryCode": "KZ"
            },
            {
                "name": "Yermentau",
                "countryCode": "KZ"
            },
            {
                "name": "Zavodskoy",
                "countryCode": "KZ"
            },
            {
                "name": "Zhaqsy",
                "countryCode": "KZ"
            },
            {
                "name": "Zholymbet",
                "countryCode": "KZ"
            },
            {
                "name": "Aktobe",
                "countryCode": "KZ"
            },
            {
                "name": "Batamshinskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Bayganin",
                "countryCode": "KZ"
            },
            {
                "name": "Embi",
                "countryCode": "KZ"
            },
            {
                "name": "Kandyagash",
                "countryCode": "KZ"
            },
            {
                "name": "Khromtau",
                "countryCode": "KZ"
            },
            {
                "name": "Martuk",
                "countryCode": "KZ"
            },
            {
                "name": "Shalqar",
                "countryCode": "KZ"
            },
            {
                "name": "Shubarkuduk",
                "countryCode": "KZ"
            },
            {
                "name": "Shubarshi",
                "countryCode": "KZ"
            },
            {
                "name": "Temir",
                "countryCode": "KZ"
            },
            {
                "name": "Yrghyz",
                "countryCode": "KZ"
            },
            {
                "name": "Almaty",
                "countryCode": "KZ"
            },
            {
                "name": "Bakanas",
                "countryCode": "KZ"
            },
            {
                "name": "Balpyk Bī",
                "countryCode": "KZ"
            },
            {
                "name": "Burunday",
                "countryCode": "KZ"
            },
            {
                "name": "Chemolgan",
                "countryCode": "KZ"
            },
            {
                "name": "Druzhba",
                "countryCode": "KZ"
            },
            {
                "name": "Esik",
                "countryCode": "KZ"
            },
            {
                "name": "Kapshagay",
                "countryCode": "KZ"
            },
            {
                "name": "Kegen",
                "countryCode": "KZ"
            },
            {
                "name": "Lepsy",
                "countryCode": "KZ"
            },
            {
                "name": "Matay",
                "countryCode": "KZ"
            },
            {
                "name": "Otegen Batyra",
                "countryCode": "KZ"
            },
            {
                "name": "Pervomayka",
                "countryCode": "KZ"
            },
            {
                "name": "Sarkand",
                "countryCode": "KZ"
            },
            {
                "name": "Saryozek",
                "countryCode": "KZ"
            },
            {
                "name": "Taldykorgan",
                "countryCode": "KZ"
            },
            {
                "name": "Talghar",
                "countryCode": "KZ"
            },
            {
                "name": "Tekeli",
                "countryCode": "KZ"
            },
            {
                "name": "Turgen",
                "countryCode": "KZ"
            },
            {
                "name": "Ush-Tyube",
                "countryCode": "KZ"
            },
            {
                "name": "Zharkent",
                "countryCode": "KZ"
            },
            {
                "name": "Ülken",
                "countryCode": "KZ"
            },
            {
                "name": "Nur-Sultan",
                "countryCode": "KZ"
            },
            {
                "name": "Akkol’",
                "countryCode": "KZ"
            },
            {
                "name": "Atyrau",
                "countryCode": "KZ"
            },
            {
                "name": "Balykshi",
                "countryCode": "KZ"
            },
            {
                "name": "Bayshonas",
                "countryCode": "KZ"
            },
            {
                "name": "Dossor",
                "countryCode": "KZ"
            },
            {
                "name": "Inderbor",
                "countryCode": "KZ"
            },
            {
                "name": "Makhambet",
                "countryCode": "KZ"
            },
            {
                "name": "Maloye Ganyushkino",
                "countryCode": "KZ"
            },
            {
                "name": "Maqat",
                "countryCode": "KZ"
            },
            {
                "name": "Miyaly",
                "countryCode": "KZ"
            },
            {
                "name": "Qaraton",
                "countryCode": "KZ"
            },
            {
                "name": "Qulsary",
                "countryCode": "KZ"
            },
            {
                "name": "Shalkar",
                "countryCode": "KZ"
            },
            {
                "name": "Baikonur",
                "countryCode": "KZ"
            },
            {
                "name": "Abay",
                "countryCode": "KZ"
            },
            {
                "name": "Abay Qalasy",
                "countryCode": "KZ"
            },
            {
                "name": "Aksu-Ayuly",
                "countryCode": "KZ"
            },
            {
                "name": "Aktas",
                "countryCode": "KZ"
            },
            {
                "name": "Aktau",
                "countryCode": "KZ"
            },
            {
                "name": "Aktogay",
                "countryCode": "KZ"
            },
            {
                "name": "Aqadyr",
                "countryCode": "KZ"
            },
            {
                "name": "Aqshataū",
                "countryCode": "KZ"
            },
            {
                "name": "Aqtoghay Aūdany",
                "countryCode": "KZ"
            },
            {
                "name": "Atasū",
                "countryCode": "KZ"
            },
            {
                "name": "Balqash",
                "countryCode": "KZ"
            },
            {
                "name": "Bukhar-Zhyrau",
                "countryCode": "KZ"
            },
            {
                "name": "Dolinka",
                "countryCode": "KZ"
            },
            {
                "name": "Karagandy",
                "countryCode": "KZ"
            },
            {
                "name": "Koktal",
                "countryCode": "KZ"
            },
            {
                "name": "Kushoky",
                "countryCode": "KZ"
            },
            {
                "name": "Kyzylzhar",
                "countryCode": "KZ"
            },
            {
                "name": "Kīevka",
                "countryCode": "KZ"
            },
            {
                "name": "Moyynty",
                "countryCode": "KZ"
            },
            {
                "name": "Novodolinskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Osakarovka",
                "countryCode": "KZ"
            },
            {
                "name": "Prigorodnoye",
                "countryCode": "KZ"
            },
            {
                "name": "Priozersk",
                "countryCode": "KZ"
            },
            {
                "name": "Qarazhal",
                "countryCode": "KZ"
            },
            {
                "name": "Qarqaraly",
                "countryCode": "KZ"
            },
            {
                "name": "Saryshaghan",
                "countryCode": "KZ"
            },
            {
                "name": "Sayaq",
                "countryCode": "KZ"
            },
            {
                "name": "Shakhan",
                "countryCode": "KZ"
            },
            {
                "name": "Shakhtinsk",
                "countryCode": "KZ"
            },
            {
                "name": "Shashūbay",
                "countryCode": "KZ"
            },
            {
                "name": "Shubarköl",
                "countryCode": "KZ"
            },
            {
                "name": "Soran",
                "countryCode": "KZ"
            },
            {
                "name": "Temirtau",
                "countryCode": "KZ"
            },
            {
                "name": "Tokarevka",
                "countryCode": "KZ"
            },
            {
                "name": "Verkhniye Kayrakty",
                "countryCode": "KZ"
            },
            {
                "name": "Zhambyl",
                "countryCode": "KZ"
            },
            {
                "name": "Zharyk",
                "countryCode": "KZ"
            },
            {
                "name": "Zhezqazghan",
                "countryCode": "KZ"
            },
            {
                "name": "Arkalyk",
                "countryCode": "KZ"
            },
            {
                "name": "Ayat",
                "countryCode": "KZ"
            },
            {
                "name": "Borovskoy",
                "countryCode": "KZ"
            },
            {
                "name": "Dzhetygara",
                "countryCode": "KZ"
            },
            {
                "name": "Fyodorovka",
                "countryCode": "KZ"
            },
            {
                "name": "Karasu",
                "countryCode": "KZ"
            },
            {
                "name": "Komsomolets",
                "countryCode": "KZ"
            },
            {
                "name": "Kostanay",
                "countryCode": "KZ"
            },
            {
                "name": "Lisakovsk",
                "countryCode": "KZ"
            },
            {
                "name": "Ordzhonikidze",
                "countryCode": "KZ"
            },
            {
                "name": "Qashar",
                "countryCode": "KZ"
            },
            {
                "name": "Qusmuryn",
                "countryCode": "KZ"
            },
            {
                "name": "Rudnyy",
                "countryCode": "KZ"
            },
            {
                "name": "Tobol",
                "countryCode": "KZ"
            },
            {
                "name": "Torghay",
                "countryCode": "KZ"
            },
            {
                "name": "Troyebratskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Aral",
                "countryCode": "KZ"
            },
            {
                "name": "Ayteke Bi",
                "countryCode": "KZ"
            },
            {
                "name": "Belköl",
                "countryCode": "KZ"
            },
            {
                "name": "Dzhalagash",
                "countryCode": "KZ"
            },
            {
                "name": "Kyzylorda",
                "countryCode": "KZ"
            },
            {
                "name": "Qazaly",
                "countryCode": "KZ"
            },
            {
                "name": "Sekseūil",
                "countryCode": "KZ"
            },
            {
                "name": "Shalqīya",
                "countryCode": "KZ"
            },
            {
                "name": "Shīeli",
                "countryCode": "KZ"
            },
            {
                "name": "Tasböget",
                "countryCode": "KZ"
            },
            {
                "name": "Terenozek",
                "countryCode": "KZ"
            },
            {
                "name": "Yanykurgan",
                "countryCode": "KZ"
            },
            {
                "name": "Zhosaly",
                "countryCode": "KZ"
            },
            {
                "name": "Aktau",
                "countryCode": "KZ"
            },
            {
                "name": "Baūtīno",
                "countryCode": "KZ"
            },
            {
                "name": "Beyneu",
                "countryCode": "KZ"
            },
            {
                "name": "Fort-Shevchenko",
                "countryCode": "KZ"
            },
            {
                "name": "Munayshy",
                "countryCode": "KZ"
            },
            {
                "name": "Sayötesh",
                "countryCode": "KZ"
            },
            {
                "name": "Shetpe",
                "countryCode": "KZ"
            },
            {
                "name": "Taūshyq",
                "countryCode": "KZ"
            },
            {
                "name": "Yeraliyev",
                "countryCode": "KZ"
            },
            {
                "name": "Zhanaozen",
                "countryCode": "KZ"
            },
            {
                "name": "Zhetibay",
                "countryCode": "KZ"
            },
            {
                "name": "Ömirzaq",
                "countryCode": "KZ"
            },
            {
                "name": "Aksu",
                "countryCode": "KZ"
            },
            {
                "name": "Bayanaul",
                "countryCode": "KZ"
            },
            {
                "name": "Belogor’ye",
                "countryCode": "KZ"
            },
            {
                "name": "Ekibastuz",
                "countryCode": "KZ"
            },
            {
                "name": "Irtyshsk",
                "countryCode": "KZ"
            },
            {
                "name": "Kalkaman",
                "countryCode": "KZ"
            },
            {
                "name": "Leninskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Mayqayyng",
                "countryCode": "KZ"
            },
            {
                "name": "Pavlodar",
                "countryCode": "KZ"
            },
            {
                "name": "Qashyr",
                "countryCode": "KZ"
            },
            {
                "name": "Zhelezinka",
                "countryCode": "KZ"
            },
            {
                "name": "Birlestik",
                "countryCode": "KZ"
            },
            {
                "name": "Bishkul",
                "countryCode": "KZ"
            },
            {
                "name": "Bulayevo",
                "countryCode": "KZ"
            },
            {
                "name": "Būrabay",
                "countryCode": "KZ"
            },
            {
                "name": "Kzyltu",
                "countryCode": "KZ"
            },
            {
                "name": "Novoishimskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Petropavl",
                "countryCode": "KZ"
            },
            {
                "name": "Sergeyevka",
                "countryCode": "KZ"
            },
            {
                "name": "Smirnovo",
                "countryCode": "KZ"
            },
            {
                "name": "Taiynsha",
                "countryCode": "KZ"
            },
            {
                "name": "Talshik",
                "countryCode": "KZ"
            },
            {
                "name": "Timiryazevo",
                "countryCode": "KZ"
            },
            {
                "name": "Volodarskoye",
                "countryCode": "KZ"
            },
            {
                "name": "Yavlenka",
                "countryCode": "KZ"
            },
            {
                "name": "Altayskiy",
                "countryCode": "KZ"
            },
            {
                "name": "Aqtoghay",
                "countryCode": "KZ"
            },
            {
                "name": "Asūbulaq",
                "countryCode": "KZ"
            },
            {
                "name": "Auezov",
                "countryCode": "KZ"
            },
            {
                "name": "Ayagoz",
                "countryCode": "KZ"
            },
            {
                "name": "Belogorskīy",
                "countryCode": "KZ"
            },
            {
                "name": "Belousovka",
                "countryCode": "KZ"
            },
            {
                "name": "Borodulikha",
                "countryCode": "KZ"
            },
            {
                "name": "Georgīevka",
                "countryCode": "KZ"
            },
            {
                "name": "Glubokoye",
                "countryCode": "KZ"
            },
            {
                "name": "Kurchatov",
                "countryCode": "KZ"
            },
            {
                "name": "Kurchum",
                "countryCode": "KZ"
            },
            {
                "name": "Maleyevsk",
                "countryCode": "KZ"
            },
            {
                "name": "Ognevka",
                "countryCode": "KZ"
            },
            {
                "name": "Priisk Boko",
                "countryCode": "KZ"
            },
            {
                "name": "Qaraūyl",
                "countryCode": "KZ"
            },
            {
                "name": "Ridder",
                "countryCode": "KZ"
            },
            {
                "name": "Semey",
                "countryCode": "KZ"
            },
            {
                "name": "Shar",
                "countryCode": "KZ"
            },
            {
                "name": "Shemonaīkha",
                "countryCode": "KZ"
            },
            {
                "name": "Suykbulak",
                "countryCode": "KZ"
            },
            {
                "name": "Tūghyl",
                "countryCode": "KZ"
            },
            {
                "name": "Urzhar",
                "countryCode": "KZ"
            },
            {
                "name": "Ust-Kamenogorsk",
                "countryCode": "KZ"
            },
            {
                "name": "Zaysan",
                "countryCode": "KZ"
            },
            {
                "name": "Zhalghyztobe",
                "countryCode": "KZ"
            },
            {
                "name": "Zhanga Buqtyrma",
                "countryCode": "KZ"
            },
            {
                "name": "Zhezkent",
                "countryCode": "KZ"
            },
            {
                "name": "Zyryanovsk",
                "countryCode": "KZ"
            },
            {
                "name": "Ūst’-Talovka",
                "countryCode": "KZ"
            },
            {
                "name": "Arys",
                "countryCode": "KZ"
            },
            {
                "name": "Ashchysay",
                "countryCode": "KZ"
            },
            {
                "name": "Asyqata",
                "countryCode": "KZ"
            },
            {
                "name": "Atakent",
                "countryCode": "KZ"
            },
            {
                "name": "Bayzhansay",
                "countryCode": "KZ"
            },
            {
                "name": "Belyye Vody",
                "countryCode": "KZ"
            },
            {
                "name": "Chardara",
                "countryCode": "KZ"
            },
            {
                "name": "Chayan",
                "countryCode": "KZ"
            },
            {
                "name": "Chulakkurgan",
                "countryCode": "KZ"
            },
            {
                "name": "Kantagi",
                "countryCode": "KZ"
            },
            {
                "name": "Kentau",
                "countryCode": "KZ"
            },
            {
                "name": "Kokterek",
                "countryCode": "KZ"
            },
            {
                "name": "Lenger",
                "countryCode": "KZ"
            },
            {
                "name": "Leninskoye",
                "countryCode": "KZ"
            },
            {
                "name": "Maqtaaral Aūdany",
                "countryCode": "KZ"
            },
            {
                "name": "Myrzakent",
                "countryCode": "KZ"
            },
            {
                "name": "Qogham",
                "countryCode": "KZ"
            },
            {
                "name": "Saryaghash",
                "countryCode": "KZ"
            },
            {
                "name": "Saryaghash Aūdany",
                "countryCode": "KZ"
            },
            {
                "name": "Sastobe",
                "countryCode": "KZ"
            },
            {
                "name": "Sozaq Aūdany",
                "countryCode": "KZ"
            },
            {
                "name": "Temirlanovka",
                "countryCode": "KZ"
            },
            {
                "name": "Turar Ryskulov",
                "countryCode": "KZ"
            },
            {
                "name": "Turkestan",
                "countryCode": "KZ"
            },
            {
                "name": "Tyul’kubas",
                "countryCode": "KZ"
            },
            {
                "name": "Zhabagly",
                "countryCode": "KZ"
            },
            {
                "name": "Aqsay",
                "countryCode": "KZ"
            },
            {
                "name": "Burlin",
                "countryCode": "KZ"
            },
            {
                "name": "Chapaev",
                "countryCode": "KZ"
            },
            {
                "name": "Chingirlau",
                "countryCode": "KZ"
            },
            {
                "name": "Dzhambeyty",
                "countryCode": "KZ"
            },
            {
                "name": "Fedorovka",
                "countryCode": "KZ"
            },
            {
                "name": "Kaztalovka",
                "countryCode": "KZ"
            },
            {
                "name": "Krūgloozernoe",
                "countryCode": "KZ"
            },
            {
                "name": "Oral",
                "countryCode": "KZ"
            },
            {
                "name": "Peremetnoe",
                "countryCode": "KZ"
            },
            {
                "name": "Saykhin",
                "countryCode": "KZ"
            },
            {
                "name": "Tasqala",
                "countryCode": "KZ"
            },
            {
                "name": "Zhänibek",
                "countryCode": "KZ"
            },
            {
                "name": "Aqbaqay",
                "countryCode": "KZ"
            },
            {
                "name": "Chu",
                "countryCode": "KZ"
            },
            {
                "name": "Georgiyevka",
                "countryCode": "KZ"
            },
            {
                "name": "Granitogorsk",
                "countryCode": "KZ"
            },
            {
                "name": "Karatau",
                "countryCode": "KZ"
            },
            {
                "name": "Khantaū",
                "countryCode": "KZ"
            },
            {
                "name": "Lugovoy",
                "countryCode": "KZ"
            },
            {
                "name": "Lugovoye",
                "countryCode": "KZ"
            },
            {
                "name": "Merke",
                "countryCode": "KZ"
            },
            {
                "name": "Moyynkum",
                "countryCode": "KZ"
            },
            {
                "name": "Mynaral",
                "countryCode": "KZ"
            },
            {
                "name": "Oytal",
                "countryCode": "KZ"
            },
            {
                "name": "Sarykemer",
                "countryCode": "KZ"
            },
            {
                "name": "Shyghanaq",
                "countryCode": "KZ"
            },
            {
                "name": "Taraz",
                "countryCode": "KZ"
            },
            {
                "name": "Zhangatas",
                "countryCode": "KZ"
            }
        ]
    },
    {
        "name": "Kenya",
        "phonecode": "254",
        "cities": [
            {
                "name": "Baringo",
                "countryCode": "KE"
            },
            {
                "name": "Eldama Ravine",
                "countryCode": "KE"
            },
            {
                "name": "Kabarnet",
                "countryCode": "KE"
            },
            {
                "name": "Sotik",
                "countryCode": "KE"
            },
            {
                "name": "Sotik Post",
                "countryCode": "KE"
            },
            {
                "name": "Bungoma",
                "countryCode": "KE"
            },
            {
                "name": "Malikisi",
                "countryCode": "KE"
            },
            {
                "name": "Webuye",
                "countryCode": "KE"
            },
            {
                "name": "Busia",
                "countryCode": "KE"
            },
            {
                "name": "Luanda",
                "countryCode": "KE"
            },
            {
                "name": "Lugulu",
                "countryCode": "KE"
            },
            {
                "name": "Malaba",
                "countryCode": "KE"
            },
            {
                "name": "Nambare",
                "countryCode": "KE"
            },
            {
                "name": "Port Victoria",
                "countryCode": "KE"
            },
            {
                "name": "Embu",
                "countryCode": "KE"
            },
            {
                "name": "Garissa",
                "countryCode": "KE"
            },
            {
                "name": "Homa Bay",
                "countryCode": "KE"
            },
            {
                "name": "Oyugis",
                "countryCode": "KE"
            },
            {
                "name": "Rachuonyo District",
                "countryCode": "KE"
            },
            {
                "name": "Isiolo",
                "countryCode": "KE"
            },
            {
                "name": "Kajiado",
                "countryCode": "KE"
            },
            {
                "name": "Magadi",
                "countryCode": "KE"
            },
            {
                "name": "Ngong",
                "countryCode": "KE"
            },
            {
                "name": "Butere",
                "countryCode": "KE"
            },
            {
                "name": "Kakamega",
                "countryCode": "KE"
            },
            {
                "name": "Mumias",
                "countryCode": "KE"
            },
            {
                "name": "Kericho",
                "countryCode": "KE"
            },
            {
                "name": "Kipkelion",
                "countryCode": "KE"
            },
            {
                "name": "Litein",
                "countryCode": "KE"
            },
            {
                "name": "Londiani",
                "countryCode": "KE"
            },
            {
                "name": "Kiambu",
                "countryCode": "KE"
            },
            {
                "name": "Kikuyu",
                "countryCode": "KE"
            },
            {
                "name": "Limuru",
                "countryCode": "KE"
            },
            {
                "name": "Pumwani",
                "countryCode": "KE"
            },
            {
                "name": "Thika",
                "countryCode": "KE"
            },
            {
                "name": "Iten",
                "countryCode": "KE"
            },
            {
                "name": "Kapsowar",
                "countryCode": "KE"
            },
            {
                "name": "Kilifi",
                "countryCode": "KE"
            },
            {
                "name": "Malindi",
                "countryCode": "KE"
            },
            {
                "name": "Mariakani",
                "countryCode": "KE"
            },
            {
                "name": "Takaungu",
                "countryCode": "KE"
            },
            {
                "name": "Kerugoya",
                "countryCode": "KE"
            },
            {
                "name": "Sagana",
                "countryCode": "KE"
            },
            {
                "name": "Kisii",
                "countryCode": "KE"
            },
            {
                "name": "Ogembo",
                "countryCode": "KE"
            },
            {
                "name": "Ahero",
                "countryCode": "KE"
            },
            {
                "name": "Kisumu",
                "countryCode": "KE"
            },
            {
                "name": "Muhoroni",
                "countryCode": "KE"
            },
            {
                "name": "Kitui",
                "countryCode": "KE"
            },
            {
                "name": "Mwingi",
                "countryCode": "KE"
            },
            {
                "name": "Gazi",
                "countryCode": "KE"
            },
            {
                "name": "Kinango",
                "countryCode": "KE"
            },
            {
                "name": "Kwale",
                "countryCode": "KE"
            },
            {
                "name": "Sawa Sawa",
                "countryCode": "KE"
            },
            {
                "name": "Shimoni",
                "countryCode": "KE"
            },
            {
                "name": "Nanyuki",
                "countryCode": "KE"
            },
            {
                "name": "Nyahururu",
                "countryCode": "KE"
            },
            {
                "name": "Rumuruti",
                "countryCode": "KE"
            },
            {
                "name": "Lamu",
                "countryCode": "KE"
            },
            {
                "name": "Witu",
                "countryCode": "KE"
            },
            {
                "name": "Athi River",
                "countryCode": "KE"
            },
            {
                "name": "Kangundo",
                "countryCode": "KE"
            },
            {
                "name": "Konza",
                "countryCode": "KE"
            },
            {
                "name": "Machakos",
                "countryCode": "KE"
            },
            {
                "name": "Makueni Boma",
                "countryCode": "KE"
            },
            {
                "name": "Mtito Andei",
                "countryCode": "KE"
            },
            {
                "name": "Wote",
                "countryCode": "KE"
            },
            {
                "name": "Mandera",
                "countryCode": "KE"
            },
            {
                "name": "Marsabit",
                "countryCode": "KE"
            },
            {
                "name": "Moyale",
                "countryCode": "KE"
            },
            {
                "name": "Maua",
                "countryCode": "KE"
            },
            {
                "name": "Meru",
                "countryCode": "KE"
            },
            {
                "name": "Kihancha",
                "countryCode": "KE"
            },
            {
                "name": "Migori",
                "countryCode": "KE"
            },
            {
                "name": "Mombasa",
                "countryCode": "KE"
            },
            {
                "name": "Kangema",
                "countryCode": "KE"
            },
            {
                "name": "Karuri",
                "countryCode": "KE"
            },
            {
                "name": "Maragua",
                "countryCode": "KE"
            },
            {
                "name": "Murang’a",
                "countryCode": "KE"
            },
            {
                "name": "Nairobi",
                "countryCode": "KE"
            },
            {
                "name": "Kijabe",
                "countryCode": "KE"
            },
            {
                "name": "Molo",
                "countryCode": "KE"
            },
            {
                "name": "Naivasha",
                "countryCode": "KE"
            },
            {
                "name": "Nakuru",
                "countryCode": "KE"
            },
            {
                "name": "Rongai",
                "countryCode": "KE"
            },
            {
                "name": "Kapsabet",
                "countryCode": "KE"
            },
            {
                "name": "Nandi Hills",
                "countryCode": "KE"
            },
            {
                "name": "Narok",
                "countryCode": "KE"
            },
            {
                "name": "Keroka",
                "countryCode": "KE"
            },
            {
                "name": "Nyamira",
                "countryCode": "KE"
            },
            {
                "name": "Ol Kalou",
                "countryCode": "KE"
            },
            {
                "name": "Naro Moru",
                "countryCode": "KE"
            },
            {
                "name": "Nyeri",
                "countryCode": "KE"
            },
            {
                "name": "Othaya",
                "countryCode": "KE"
            },
            {
                "name": "Maralal",
                "countryCode": "KE"
            },
            {
                "name": "Bondo",
                "countryCode": "KE"
            },
            {
                "name": "Siaya",
                "countryCode": "KE"
            },
            {
                "name": "Yala",
                "countryCode": "KE"
            },
            {
                "name": "Hola",
                "countryCode": "KE"
            },
            {
                "name": "Kipini",
                "countryCode": "KE"
            },
            {
                "name": "Chuka",
                "countryCode": "KE"
            },
            {
                "name": "Kitale",
                "countryCode": "KE"
            },
            {
                "name": "Lodwar",
                "countryCode": "KE"
            },
            {
                "name": "Eldoret",
                "countryCode": "KE"
            },
            {
                "name": "Wajir",
                "countryCode": "KE"
            },
            {
                "name": "Chepareria",
                "countryCode": "KE"
            },
            {
                "name": "Kapenguria",
                "countryCode": "KE"
            },
            {
                "name": "Taveta",
                "countryCode": "KE"
            },
            {
                "name": "Voi",
                "countryCode": "KE"
            },
            {
                "name": "Wundanyi",
                "countryCode": "KE"
            }
        ]
    },
    {
        "name": "Kiribati",
        "phonecode": "686",
        "cities": [
            {
                "name": "Abaiang",
                "countryCode": "KI"
            },
            {
                "name": "Abemama",
                "countryCode": "KI"
            },
            {
                "name": "Ambo Village",
                "countryCode": "KI"
            },
            {
                "name": "Aranuka",
                "countryCode": "KI"
            },
            {
                "name": "Arorae",
                "countryCode": "KI"
            },
            {
                "name": "Bairiki Village",
                "countryCode": "KI"
            },
            {
                "name": "Banaba",
                "countryCode": "KI"
            },
            {
                "name": "Banraeaba Village",
                "countryCode": "KI"
            },
            {
                "name": "Beru",
                "countryCode": "KI"
            },
            {
                "name": "Betio Village",
                "countryCode": "KI"
            },
            {
                "name": "Bikenibeu Village",
                "countryCode": "KI"
            },
            {
                "name": "Bonriki Village",
                "countryCode": "KI"
            },
            {
                "name": "Buota Village",
                "countryCode": "KI"
            },
            {
                "name": "Butaritari",
                "countryCode": "KI"
            },
            {
                "name": "Eita Village",
                "countryCode": "KI"
            },
            {
                "name": "Kuria",
                "countryCode": "KI"
            },
            {
                "name": "Maiana",
                "countryCode": "KI"
            },
            {
                "name": "Makin",
                "countryCode": "KI"
            },
            {
                "name": "Makin Village",
                "countryCode": "KI"
            },
            {
                "name": "Marakei",
                "countryCode": "KI"
            },
            {
                "name": "Nawerewere Village",
                "countryCode": "KI"
            },
            {
                "name": "Nikunau",
                "countryCode": "KI"
            },
            {
                "name": "Nonouti",
                "countryCode": "KI"
            },
            {
                "name": "Onotoa",
                "countryCode": "KI"
            },
            {
                "name": "Rawannawi Village",
                "countryCode": "KI"
            },
            {
                "name": "Tabiteuea",
                "countryCode": "KI"
            },
            {
                "name": "Tamana",
                "countryCode": "KI"
            },
            {
                "name": "Tarawa",
                "countryCode": "KI"
            },
            {
                "name": "Teaoraereke Village",
                "countryCode": "KI"
            },
            {
                "name": "Temaiku Village",
                "countryCode": "KI"
            },
            {
                "name": "Banana Village",
                "countryCode": "KI"
            },
            {
                "name": "Kiritimati",
                "countryCode": "KI"
            },
            {
                "name": "London Village",
                "countryCode": "KI"
            },
            {
                "name": "Napari Village",
                "countryCode": "KI"
            },
            {
                "name": "Tabuaeran",
                "countryCode": "KI"
            },
            {
                "name": "Tabwakea Village",
                "countryCode": "KI"
            },
            {
                "name": "Teraina",
                "countryCode": "KI"
            },
            {
                "name": "Kanton",
                "countryCode": "KI"
            }
        ]
    },
    {
        "name": "North Korea",
        "phonecode": "850",
        "cities": [
            {
                "name": "Chunghwa",
                "countryCode": "KP"
            },
            {
                "name": "Kangdong-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Pyongyang",
                "countryCode": "KP"
            },
            {
                "name": "Sunan",
                "countryCode": "KP"
            },
            {
                "name": "Sŭngho 1-tong",
                "countryCode": "KP"
            },
            {
                "name": "Anju",
                "countryCode": "KP"
            },
            {
                "name": "Namp’o",
                "countryCode": "KP"
            },
            {
                "name": "P’yŏngsŏng",
                "countryCode": "KP"
            },
            {
                "name": "Sangsŏng-ni",
                "countryCode": "KP"
            },
            {
                "name": "Sil-li",
                "countryCode": "KP"
            },
            {
                "name": "Sinanju",
                "countryCode": "KP"
            },
            {
                "name": "Sunch’ŏn",
                "countryCode": "KP"
            },
            {
                "name": "Yonggang-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Chŏngju",
                "countryCode": "KP"
            },
            {
                "name": "Chŏngju-gun",
                "countryCode": "KP"
            },
            {
                "name": "Kujang-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Kusŏng",
                "countryCode": "KP"
            },
            {
                "name": "Kwaksan",
                "countryCode": "KP"
            },
            {
                "name": "Panghyŏn-dong",
                "countryCode": "KP"
            },
            {
                "name": "Pukchil-lodongjagu",
                "countryCode": "KP"
            },
            {
                "name": "Sakchu-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Sinŭiju",
                "countryCode": "KP"
            },
            {
                "name": "Yŏmju-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Yŏngbyŏn",
                "countryCode": "KP"
            },
            {
                "name": "Ŭiju",
                "countryCode": "KP"
            },
            {
                "name": "Changgang-gun",
                "countryCode": "KP"
            },
            {
                "name": "Chasŏng",
                "countryCode": "KP"
            },
            {
                "name": "Kanggye",
                "countryCode": "KP"
            },
            {
                "name": "Manp’o",
                "countryCode": "KP"
            },
            {
                "name": "Ayang-ni",
                "countryCode": "KP"
            },
            {
                "name": "Chaeryŏng-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Changyŏn",
                "countryCode": "KP"
            },
            {
                "name": "Haeju",
                "countryCode": "KP"
            },
            {
                "name": "Kaesŏng",
                "countryCode": "KP"
            },
            {
                "name": "Ongjin",
                "countryCode": "KP"
            },
            {
                "name": "Pyŏksŏng-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Yŏnan-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Anak",
                "countryCode": "KP"
            },
            {
                "name": "Hwangju-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Hŭkkyo-ri",
                "countryCode": "KP"
            },
            {
                "name": "Koksan",
                "countryCode": "KP"
            },
            {
                "name": "Sariwŏn",
                "countryCode": "KP"
            },
            {
                "name": "Sinmak",
                "countryCode": "KP"
            },
            {
                "name": "Songnim",
                "countryCode": "KP"
            },
            {
                "name": "Anbyŏn-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Hoeyang",
                "countryCode": "KP"
            },
            {
                "name": "Kosan",
                "countryCode": "KP"
            },
            {
                "name": "T’ongch’ŏn-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Wŏnsan",
                "countryCode": "KP"
            },
            {
                "name": "Hamhŭng",
                "countryCode": "KP"
            },
            {
                "name": "Hongwŏn",
                "countryCode": "KP"
            },
            {
                "name": "Hŭngnam",
                "countryCode": "KP"
            },
            {
                "name": "Iwŏn-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Kowŏn-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Samho-rodongjagu",
                "countryCode": "KP"
            },
            {
                "name": "Sinsang-ni",
                "countryCode": "KP"
            },
            {
                "name": "Yuktae-dong",
                "countryCode": "KP"
            },
            {
                "name": "Yŏnggwang-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Aoji",
                "countryCode": "KP"
            },
            {
                "name": "Chongjin",
                "countryCode": "KP"
            },
            {
                "name": "Hau-ri",
                "countryCode": "KP"
            },
            {
                "name": "Hoemul-li",
                "countryCode": "KP"
            },
            {
                "name": "Hoeryŏng",
                "countryCode": "KP"
            },
            {
                "name": "Kilju",
                "countryCode": "KP"
            },
            {
                "name": "Kimch’aek-si",
                "countryCode": "KP"
            },
            {
                "name": "Komusan Il-tong",
                "countryCode": "KP"
            },
            {
                "name": "Kyŏngsŏng",
                "countryCode": "KP"
            },
            {
                "name": "Kyŏngwŏn",
                "countryCode": "KP"
            },
            {
                "name": "Musan-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Namyang-dong",
                "countryCode": "KP"
            },
            {
                "name": "Nanam",
                "countryCode": "KP"
            },
            {
                "name": "Onsŏng",
                "countryCode": "KP"
            },
            {
                "name": "Puryŏng",
                "countryCode": "KP"
            },
            {
                "name": "Sŭngam-nodongjagu",
                "countryCode": "KP"
            },
            {
                "name": "Hyesan",
                "countryCode": "KP"
            },
            {
                "name": "Hyesan-dong",
                "countryCode": "KP"
            },
            {
                "name": "Kapsan-ŭp",
                "countryCode": "KP"
            },
            {
                "name": "Sŭngjibaegam",
                "countryCode": "KP"
            },
            {
                "name": "Sŏnbong",
                "countryCode": "KP"
            },
            {
                "name": "Ungsang-nodongjagu",
                "countryCode": "KP"
            }
        ]
    },
    {
        "name": "South Korea",
        "phonecode": "82",
        "cities": [
            {
                "name": "Dobong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Dongdaemun-gu",
                "countryCode": "KR"
            },
            {
                "name": "Dongjak-gu",
                "countryCode": "KR"
            },
            {
                "name": "Eunpyeong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gangbuk-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gangdong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gangnam-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gangseo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Geumcheon-gu",
                "countryCode": "KR"
            },
            {
                "name": "Guro-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gwanak-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gwangjin-gu",
                "countryCode": "KR"
            },
            {
                "name": "Jongno-gu",
                "countryCode": "KR"
            },
            {
                "name": "Jung-gu",
                "countryCode": "KR"
            },
            {
                "name": "Jungnang-gu",
                "countryCode": "KR"
            },
            {
                "name": "Mapo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Nowon-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seocho-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seodaemun-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seongbuk-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seongdong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seoul",
                "countryCode": "KR"
            },
            {
                "name": "Songpa-gu",
                "countryCode": "KR"
            },
            {
                "name": "Yangcheon-gu",
                "countryCode": "KR"
            },
            {
                "name": "Yeongdeungpo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Yongsan-dong",
                "countryCode": "KR"
            },
            {
                "name": "Yongsan-gu",
                "countryCode": "KR"
            },
            {
                "name": "Buk-gu",
                "countryCode": "KR"
            },
            {
                "name": "Busan",
                "countryCode": "KR"
            },
            {
                "name": "Dongnae-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gangseo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Geumjeong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gijang",
                "countryCode": "KR"
            },
            {
                "name": "Gijang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Haeundae-gu",
                "countryCode": "KR"
            },
            {
                "name": "Jung-gu",
                "countryCode": "KR"
            },
            {
                "name": "Nam-gu",
                "countryCode": "KR"
            },
            {
                "name": "Saha-gu",
                "countryCode": "KR"
            },
            {
                "name": "Sasang-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Suyeong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Yeongdo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Daegu",
                "countryCode": "KR"
            },
            {
                "name": "Dalseo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Dalseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Dong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Hwawŏn",
                "countryCode": "KR"
            },
            {
                "name": "Jung-gu",
                "countryCode": "KR"
            },
            {
                "name": "Seo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Suseong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Bupyeong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Incheon",
                "countryCode": "KR"
            },
            {
                "name": "Michuhol",
                "countryCode": "KR"
            },
            {
                "name": "Yeonsu-gu",
                "countryCode": "KR"
            },
            {
                "name": "Gwangju",
                "countryCode": "KR"
            },
            {
                "name": "Gwangsan-gu",
                "countryCode": "KR"
            },
            {
                "name": "Masan",
                "countryCode": "KR"
            },
            {
                "name": "Daejeon",
                "countryCode": "KR"
            },
            {
                "name": "Seo-gu",
                "countryCode": "KR"
            },
            {
                "name": "Sintansin",
                "countryCode": "KR"
            },
            {
                "name": "Songgangdong",
                "countryCode": "KR"
            },
            {
                "name": "Yuseong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Buk-gu",
                "countryCode": "KR"
            },
            {
                "name": "Dong-gu",
                "countryCode": "KR"
            },
            {
                "name": "Jung-gu",
                "countryCode": "KR"
            },
            {
                "name": "Nam-gu",
                "countryCode": "KR"
            },
            {
                "name": "Ulju-gun",
                "countryCode": "KR"
            },
            {
                "name": "Ulsan",
                "countryCode": "KR"
            },
            {
                "name": "Ansan-si",
                "countryCode": "KR"
            },
            {
                "name": "Anseong",
                "countryCode": "KR"
            },
            {
                "name": "Anyang-si",
                "countryCode": "KR"
            },
            {
                "name": "Bucheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Dongducheon",
                "countryCode": "KR"
            },
            {
                "name": "Gapyeong",
                "countryCode": "KR"
            },
            {
                "name": "Gapyeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gimpo-si",
                "countryCode": "KR"
            },
            {
                "name": "Goyang-si",
                "countryCode": "KR"
            },
            {
                "name": "Gunpo-si",
                "countryCode": "KR"
            },
            {
                "name": "Guri-si",
                "countryCode": "KR"
            },
            {
                "name": "Gwacheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Gwangju",
                "countryCode": "KR"
            },
            {
                "name": "Gwangju-si",
                "countryCode": "KR"
            },
            {
                "name": "Gwangmyeong-si",
                "countryCode": "KR"
            },
            {
                "name": "Hanam",
                "countryCode": "KR"
            },
            {
                "name": "Hanam-si",
                "countryCode": "KR"
            },
            {
                "name": "Hwado",
                "countryCode": "KR"
            },
            {
                "name": "Hwaseong-si",
                "countryCode": "KR"
            },
            {
                "name": "Icheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Kwangmyŏng",
                "countryCode": "KR"
            },
            {
                "name": "Munsan",
                "countryCode": "KR"
            },
            {
                "name": "Namyangju",
                "countryCode": "KR"
            },
            {
                "name": "Namyangju-si",
                "countryCode": "KR"
            },
            {
                "name": "Osan",
                "countryCode": "KR"
            },
            {
                "name": "Osan-si",
                "countryCode": "KR"
            },
            {
                "name": "Paju-si",
                "countryCode": "KR"
            },
            {
                "name": "Pocheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Pubal",
                "countryCode": "KR"
            },
            {
                "name": "Pyeongtaek",
                "countryCode": "KR"
            },
            {
                "name": "Seongnam-si",
                "countryCode": "KR"
            },
            {
                "name": "Siheung",
                "countryCode": "KR"
            },
            {
                "name": "Suwon",
                "countryCode": "KR"
            },
            {
                "name": "Uijeongbu-si",
                "countryCode": "KR"
            },
            {
                "name": "Uiwang-si",
                "countryCode": "KR"
            },
            {
                "name": "Wabu",
                "countryCode": "KR"
            },
            {
                "name": "Yangju",
                "countryCode": "KR"
            },
            {
                "name": "Yangju-si",
                "countryCode": "KR"
            },
            {
                "name": "Yangp'yŏng",
                "countryCode": "KR"
            },
            {
                "name": "Yangpyeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeoju-si",
                "countryCode": "KR"
            },
            {
                "name": "Yeoncheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yongin-si",
                "countryCode": "KR"
            },
            {
                "name": "Cheorwon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Chuncheon",
                "countryCode": "KR"
            },
            {
                "name": "Chuncheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Donghae-si",
                "countryCode": "KR"
            },
            {
                "name": "Gangneung",
                "countryCode": "KR"
            },
            {
                "name": "Gangneung-si",
                "countryCode": "KR"
            },
            {
                "name": "Goseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hoengseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hongcheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hongch’ŏn",
                "countryCode": "KR"
            },
            {
                "name": "Hwacheon",
                "countryCode": "KR"
            },
            {
                "name": "Hwacheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Inje-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jeongseon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Kosong",
                "countryCode": "KR"
            },
            {
                "name": "Neietsu",
                "countryCode": "KR"
            },
            {
                "name": "Pyeongchang",
                "countryCode": "KR"
            },
            {
                "name": "Pyeongchang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Samcheok-si",
                "countryCode": "KR"
            },
            {
                "name": "Santyoku",
                "countryCode": "KR"
            },
            {
                "name": "Sokcho",
                "countryCode": "KR"
            },
            {
                "name": "Sokcho-si",
                "countryCode": "KR"
            },
            {
                "name": "Taebaek-si",
                "countryCode": "KR"
            },
            {
                "name": "Tonghae",
                "countryCode": "KR"
            },
            {
                "name": "T’aebaek",
                "countryCode": "KR"
            },
            {
                "name": "Wonju-si",
                "countryCode": "KR"
            },
            {
                "name": "Wŏnju",
                "countryCode": "KR"
            },
            {
                "name": "Yanggu",
                "countryCode": "KR"
            },
            {
                "name": "Yanggu-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yangyang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Boeun-gun",
                "countryCode": "KR"
            },
            {
                "name": "Cheongju-si",
                "countryCode": "KR"
            },
            {
                "name": "Chinch'ŏn",
                "countryCode": "KR"
            },
            {
                "name": "Chungju-si",
                "countryCode": "KR"
            },
            {
                "name": "Danyang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Eumseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Goesan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jecheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Jeungpyeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jincheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Koesan",
                "countryCode": "KR"
            },
            {
                "name": "Okcheon",
                "countryCode": "KR"
            },
            {
                "name": "Okcheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeongdong",
                "countryCode": "KR"
            },
            {
                "name": "Yeongdong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Asan",
                "countryCode": "KR"
            },
            {
                "name": "Asan-si",
                "countryCode": "KR"
            },
            {
                "name": "Boryeong",
                "countryCode": "KR"
            },
            {
                "name": "Boryeong-si",
                "countryCode": "KR"
            },
            {
                "name": "Buyeo",
                "countryCode": "KR"
            },
            {
                "name": "Buyeo-gun",
                "countryCode": "KR"
            },
            {
                "name": "Cheonan",
                "countryCode": "KR"
            },
            {
                "name": "Cheonan-si",
                "countryCode": "KR"
            },
            {
                "name": "Cheongyang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Geumsan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gongju",
                "countryCode": "KR"
            },
            {
                "name": "Gongju-si",
                "countryCode": "KR"
            },
            {
                "name": "Gyeryong-si",
                "countryCode": "KR"
            },
            {
                "name": "Hongseong",
                "countryCode": "KR"
            },
            {
                "name": "Hongseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Kinzan",
                "countryCode": "KR"
            },
            {
                "name": "Nonsan",
                "countryCode": "KR"
            },
            {
                "name": "Nonsan-si",
                "countryCode": "KR"
            },
            {
                "name": "Seocheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Seonghwan",
                "countryCode": "KR"
            },
            {
                "name": "Seosan",
                "countryCode": "KR"
            },
            {
                "name": "Seosan-si",
                "countryCode": "KR"
            },
            {
                "name": "Taean-gun",
                "countryCode": "KR"
            },
            {
                "name": "Taesal-li",
                "countryCode": "KR"
            },
            {
                "name": "Tangjin",
                "countryCode": "KR"
            },
            {
                "name": "Yesan",
                "countryCode": "KR"
            },
            {
                "name": "Yesan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yŏnmu",
                "countryCode": "KR"
            },
            {
                "name": "Buan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Changsu",
                "countryCode": "KR"
            },
            {
                "name": "Gimje-si",
                "countryCode": "KR"
            },
            {
                "name": "Gochang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gunsan",
                "countryCode": "KR"
            },
            {
                "name": "Gunsan-si",
                "countryCode": "KR"
            },
            {
                "name": "Iksan",
                "countryCode": "KR"
            },
            {
                "name": "Iksan-si",
                "countryCode": "KR"
            },
            {
                "name": "Imsil",
                "countryCode": "KR"
            },
            {
                "name": "Imsil-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jangsu-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jeongeup",
                "countryCode": "KR"
            },
            {
                "name": "Jeongeup-si",
                "countryCode": "KR"
            },
            {
                "name": "Jeonju",
                "countryCode": "KR"
            },
            {
                "name": "Jeonju-si",
                "countryCode": "KR"
            },
            {
                "name": "Jinan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Kimje",
                "countryCode": "KR"
            },
            {
                "name": "Koch'ang",
                "countryCode": "KR"
            },
            {
                "name": "Muju-gun",
                "countryCode": "KR"
            },
            {
                "name": "Namwon",
                "countryCode": "KR"
            },
            {
                "name": "Nangen",
                "countryCode": "KR"
            },
            {
                "name": "Puan",
                "countryCode": "KR"
            },
            {
                "name": "Sunchang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Wanju",
                "countryCode": "KR"
            },
            {
                "name": "Beolgyo",
                "countryCode": "KR"
            },
            {
                "name": "Boseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Damyang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gangjin-gun",
                "countryCode": "KR"
            },
            {
                "name": "Goheung-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gokseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gurye-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gwangyang",
                "countryCode": "KR"
            },
            {
                "name": "Gwangyang-si",
                "countryCode": "KR"
            },
            {
                "name": "Haenam",
                "countryCode": "KR"
            },
            {
                "name": "Haenam-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hampyeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hwasun",
                "countryCode": "KR"
            },
            {
                "name": "Hwasun-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jangheung-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jangseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jindo-gun",
                "countryCode": "KR"
            },
            {
                "name": "Kurye",
                "countryCode": "KR"
            },
            {
                "name": "Kwangyang",
                "countryCode": "KR"
            },
            {
                "name": "Mokpo",
                "countryCode": "KR"
            },
            {
                "name": "Mokpo-si",
                "countryCode": "KR"
            },
            {
                "name": "Muan",
                "countryCode": "KR"
            },
            {
                "name": "Muan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Naju",
                "countryCode": "KR"
            },
            {
                "name": "Naju-si",
                "countryCode": "KR"
            },
            {
                "name": "Sinan",
                "countryCode": "KR"
            },
            {
                "name": "Sinan-gun",
                "countryCode": "KR"
            },
            {
                "name": "Suncheon",
                "countryCode": "KR"
            },
            {
                "name": "Suncheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Wando-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeongam",
                "countryCode": "KR"
            },
            {
                "name": "Yeongam-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeonggwang",
                "countryCode": "KR"
            },
            {
                "name": "Yeonggwang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeosu",
                "countryCode": "KR"
            },
            {
                "name": "Andong",
                "countryCode": "KR"
            },
            {
                "name": "Andong-si",
                "countryCode": "KR"
            },
            {
                "name": "Bonghwa-gun",
                "countryCode": "KR"
            },
            {
                "name": "Cheongdo-gun",
                "countryCode": "KR"
            },
            {
                "name": "Cheongsong gun",
                "countryCode": "KR"
            },
            {
                "name": "Cheongsong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Chilgok-gun",
                "countryCode": "KR"
            },
            {
                "name": "Eisen",
                "countryCode": "KR"
            },
            {
                "name": "Gimcheon",
                "countryCode": "KR"
            },
            {
                "name": "Gimcheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Goryeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gumi-si",
                "countryCode": "KR"
            },
            {
                "name": "Gunwi-gun",
                "countryCode": "KR"
            },
            {
                "name": "Gyeongju",
                "countryCode": "KR"
            },
            {
                "name": "Gyeongsan-si",
                "countryCode": "KR"
            },
            {
                "name": "Hayang",
                "countryCode": "KR"
            },
            {
                "name": "Heunghae",
                "countryCode": "KR"
            },
            {
                "name": "Jenzan",
                "countryCode": "KR"
            },
            {
                "name": "Kunwi",
                "countryCode": "KR"
            },
            {
                "name": "Mungyeong",
                "countryCode": "KR"
            },
            {
                "name": "Mungyeong-si",
                "countryCode": "KR"
            },
            {
                "name": "Pohang",
                "countryCode": "KR"
            },
            {
                "name": "Pohang-si",
                "countryCode": "KR"
            },
            {
                "name": "Sangju",
                "countryCode": "KR"
            },
            {
                "name": "Seongju-gun",
                "countryCode": "KR"
            },
            {
                "name": "Uiseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Uljin-gun",
                "countryCode": "KR"
            },
            {
                "name": "Ulleung-gun",
                "countryCode": "KR"
            },
            {
                "name": "Waegwan",
                "countryCode": "KR"
            },
            {
                "name": "Yecheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeongcheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Yeongdeok-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeongju-si",
                "countryCode": "KR"
            },
            {
                "name": "Yeongyang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Yeonil",
                "countryCode": "KR"
            },
            {
                "name": "Changnyeong",
                "countryCode": "KR"
            },
            {
                "name": "Changnyeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Changwon",
                "countryCode": "KR"
            },
            {
                "name": "Changwon-si",
                "countryCode": "KR"
            },
            {
                "name": "Chinju",
                "countryCode": "KR"
            },
            {
                "name": "Geochang-gun",
                "countryCode": "KR"
            },
            {
                "name": "Geoje-si",
                "countryCode": "KR"
            },
            {
                "name": "Gimhae-si",
                "countryCode": "KR"
            },
            {
                "name": "Goseong",
                "countryCode": "KR"
            },
            {
                "name": "Goseong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hadong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Haman-gun",
                "countryCode": "KR"
            },
            {
                "name": "Hapcheon-gun",
                "countryCode": "KR"
            },
            {
                "name": "Jinju-si",
                "countryCode": "KR"
            },
            {
                "name": "Kimhae",
                "countryCode": "KR"
            },
            {
                "name": "Kyosai",
                "countryCode": "KR"
            },
            {
                "name": "Miryang",
                "countryCode": "KR"
            },
            {
                "name": "Miryang-si",
                "countryCode": "KR"
            },
            {
                "name": "Naesŏ",
                "countryCode": "KR"
            },
            {
                "name": "Namhae-gun",
                "countryCode": "KR"
            },
            {
                "name": "Sacheon-si",
                "countryCode": "KR"
            },
            {
                "name": "Sancheong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Sinhyeon",
                "countryCode": "KR"
            },
            {
                "name": "Uiryeong-gun",
                "countryCode": "KR"
            },
            {
                "name": "Ungsang",
                "countryCode": "KR"
            },
            {
                "name": "Yangsan",
                "countryCode": "KR"
            },
            {
                "name": "Gaigeturi",
                "countryCode": "KR"
            },
            {
                "name": "Jeju City",
                "countryCode": "KR"
            },
            {
                "name": "Jeju-si",
                "countryCode": "KR"
            },
            {
                "name": "Seogwipo",
                "countryCode": "KR"
            },
            {
                "name": "Sejong",
                "countryCode": "KR"
            }
        ]
    },
    {
        "name": "Kuwait",
        "phonecode": "965",
        "cities": [
            {
                "name": "Al Aḩmadī",
                "countryCode": "KW"
            },
            {
                "name": "Al Faḩāḩīl",
                "countryCode": "KW"
            },
            {
                "name": "Al Finţās",
                "countryCode": "KW"
            },
            {
                "name": "Al Mahbūlah",
                "countryCode": "KW"
            },
            {
                "name": "Al Manqaf",
                "countryCode": "KW"
            },
            {
                "name": "Al Wafrah",
                "countryCode": "KW"
            },
            {
                "name": "Ar Riqqah",
                "countryCode": "KW"
            },
            {
                "name": "Al Farwānīyah",
                "countryCode": "KW"
            },
            {
                "name": "Janūb as Surrah",
                "countryCode": "KW"
            },
            {
                "name": "Ar Rumaythīyah",
                "countryCode": "KW"
            },
            {
                "name": "As Sālimīyah",
                "countryCode": "KW"
            },
            {
                "name": "Bayān",
                "countryCode": "KW"
            },
            {
                "name": "Salwá",
                "countryCode": "KW"
            },
            {
                "name": "Ḩawallī",
                "countryCode": "KW"
            },
            {
                "name": "Al Jahrā’",
                "countryCode": "KW"
            },
            {
                "name": "Ad Dasmah",
                "countryCode": "KW"
            },
            {
                "name": "Ar Rābiyah",
                "countryCode": "KW"
            },
            {
                "name": "Ash Shāmīyah",
                "countryCode": "KW"
            },
            {
                "name": "Az Zawr",
                "countryCode": "KW"
            },
            {
                "name": "Kuwait City",
                "countryCode": "KW"
            },
            {
                "name": "Abu Al Hasaniya",
                "countryCode": "KW"
            },
            {
                "name": "Abu Fatira",
                "countryCode": "KW"
            },
            {
                "name": "Al Funayţīs",
                "countryCode": "KW"
            },
            {
                "name": "Al-Masayel",
                "countryCode": "KW"
            },
            {
                "name": "Şabāḩ as Sālim",
                "countryCode": "KW"
            }
        ]
    },
    {
        "name": "Kyrgyzstan",
        "phonecode": "996",
        "cities": [
            {
                "name": "Aydarken",
                "countryCode": "KG"
            },
            {
                "name": "Batken",
                "countryCode": "KG"
            },
            {
                "name": "Iradan",
                "countryCode": "KG"
            },
            {
                "name": "Isfana",
                "countryCode": "KG"
            },
            {
                "name": "Karavan",
                "countryCode": "KG"
            },
            {
                "name": "Kyzyl-Kyya",
                "countryCode": "KG"
            },
            {
                "name": "Suluktu",
                "countryCode": "KG"
            },
            {
                "name": "Alamudunskiy Rayon",
                "countryCode": "KG"
            },
            {
                "name": "Belovodskoye",
                "countryCode": "KG"
            },
            {
                "name": "Chuyskiy Rayon",
                "countryCode": "KG"
            },
            {
                "name": "Ivanovka",
                "countryCode": "KG"
            },
            {
                "name": "Kaindy",
                "countryCode": "KG"
            },
            {
                "name": "Kant",
                "countryCode": "KG"
            },
            {
                "name": "Kara-Balta",
                "countryCode": "KG"
            },
            {
                "name": "Kemin",
                "countryCode": "KG"
            },
            {
                "name": "Lebedinovka",
                "countryCode": "KG"
            },
            {
                "name": "Sokulukskiy Rayon",
                "countryCode": "KG"
            },
            {
                "name": "Sosnovka",
                "countryCode": "KG"
            },
            {
                "name": "Tokmok",
                "countryCode": "KG"
            },
            {
                "name": "Ysyk-Ata",
                "countryCode": "KG"
            },
            {
                "name": "Bishkek",
                "countryCode": "KG"
            },
            {
                "name": "Ala-Buka",
                "countryCode": "KG"
            },
            {
                "name": "Bazar-Korgon",
                "countryCode": "KG"
            },
            {
                "name": "Jalal-Abad",
                "countryCode": "KG"
            },
            {
                "name": "Kazarman",
                "countryCode": "KG"
            },
            {
                "name": "Kerben",
                "countryCode": "KG"
            },
            {
                "name": "Kochkor-Ata",
                "countryCode": "KG"
            },
            {
                "name": "Suzak",
                "countryCode": "KG"
            },
            {
                "name": "Tash-Kumyr",
                "countryCode": "KG"
            },
            {
                "name": "Toktogul",
                "countryCode": "KG"
            },
            {
                "name": "Toktogul District",
                "countryCode": "KG"
            },
            {
                "name": "At-Bashi",
                "countryCode": "KG"
            },
            {
                "name": "Jumgal",
                "countryCode": "KG"
            },
            {
                "name": "Naryn",
                "countryCode": "KG"
            },
            {
                "name": "Chong-Alay District",
                "countryCode": "KG"
            },
            {
                "name": "Daroot-Korgon",
                "countryCode": "KG"
            },
            {
                "name": "Kara Kulja",
                "countryCode": "KG"
            },
            {
                "name": "Kara Suu",
                "countryCode": "KG"
            },
            {
                "name": "Kyzyl-Eshme",
                "countryCode": "KG"
            },
            {
                "name": "Nookat",
                "countryCode": "KG"
            },
            {
                "name": "Osh",
                "countryCode": "KG"
            },
            {
                "name": "Uzgen",
                "countryCode": "KG"
            },
            {
                "name": "Uzgen District",
                "countryCode": "KG"
            },
            {
                "name": "Kara-Buurinskiy Rayon",
                "countryCode": "KG"
            },
            {
                "name": "Talas",
                "countryCode": "KG"
            },
            {
                "name": "Talasskiy Rayon",
                "countryCode": "KG"
            },
            {
                "name": "Ak-Suu",
                "countryCode": "KG"
            },
            {
                "name": "Balykchy",
                "countryCode": "KG"
            },
            {
                "name": "Cholpon-Ata",
                "countryCode": "KG"
            },
            {
                "name": "Kadzhi-Say",
                "countryCode": "KG"
            },
            {
                "name": "Karakol",
                "countryCode": "KG"
            },
            {
                "name": "Kyzyl-Suu",
                "countryCode": "KG"
            },
            {
                "name": "Tyup",
                "countryCode": "KG"
            }
        ]
    },
    {
        "name": "Laos",
        "phonecode": "856",
        "cities": [
            {
                "name": "Attapeu",
                "countryCode": "LA"
            },
            {
                "name": "Muang Phouvong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Samakhixai",
                "countryCode": "LA"
            },
            {
                "name": "Muang Sanamxai",
                "countryCode": "LA"
            },
            {
                "name": "Muang Sanxai",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xaiséttha",
                "countryCode": "LA"
            },
            {
                "name": "Ban Houakhoua",
                "countryCode": "LA"
            },
            {
                "name": "Ban Houayxay",
                "countryCode": "LA"
            },
            {
                "name": "Muang Houayxay",
                "countryCode": "LA"
            },
            {
                "name": "Muang Meung",
                "countryCode": "LA"
            },
            {
                "name": "Muang Paktha",
                "countryCode": "LA"
            },
            {
                "name": "Muang Pha Oudôm",
                "countryCode": "LA"
            },
            {
                "name": "Muang Tônpheung",
                "countryCode": "LA"
            },
            {
                "name": "Ban Nahin",
                "countryCode": "LA"
            },
            {
                "name": "Pakxan",
                "countryCode": "LA"
            },
            {
                "name": "Champasak",
                "countryCode": "LA"
            },
            {
                "name": "Muang Bachiangchaleunsook",
                "countryCode": "LA"
            },
            {
                "name": "Muang Champasak",
                "countryCode": "LA"
            },
            {
                "name": "Muang Không",
                "countryCode": "LA"
            },
            {
                "name": "Muang Mounlapamôk",
                "countryCode": "LA"
            },
            {
                "name": "Muang Pakxong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Pakxé",
                "countryCode": "LA"
            },
            {
                "name": "Muang Pathoumphon",
                "countryCode": "LA"
            },
            {
                "name": "Muang Phônthong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Soukhouma",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xanasômboun",
                "countryCode": "LA"
            },
            {
                "name": "Pakse",
                "countryCode": "LA"
            },
            {
                "name": "Pakxong",
                "countryCode": "LA"
            },
            {
                "name": "Xam Neua",
                "countryCode": "LA"
            },
            {
                "name": "Xam Nua",
                "countryCode": "LA"
            },
            {
                "name": "Muang Thakhèk",
                "countryCode": "LA"
            },
            {
                "name": "Thakhèk",
                "countryCode": "LA"
            },
            {
                "name": "Luang Namtha",
                "countryCode": "LA"
            },
            {
                "name": "Muang Louang Namtha",
                "countryCode": "LA"
            },
            {
                "name": "Luang Prabang",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xay",
                "countryCode": "LA"
            },
            {
                "name": "Phôngsali",
                "countryCode": "LA"
            },
            {
                "name": "Muang Khôngxédôn",
                "countryCode": "LA"
            },
            {
                "name": "Muang Lakhonphéng",
                "countryCode": "LA"
            },
            {
                "name": "Muang Laongam",
                "countryCode": "LA"
            },
            {
                "name": "Muang Samouay",
                "countryCode": "LA"
            },
            {
                "name": "Muang Saravan",
                "countryCode": "LA"
            },
            {
                "name": "Muang Ta-Ôy",
                "countryCode": "LA"
            },
            {
                "name": "Muang Toumlan",
                "countryCode": "LA"
            },
            {
                "name": "Muang Vapi",
                "countryCode": "LA"
            },
            {
                "name": "Salavan",
                "countryCode": "LA"
            },
            {
                "name": "Kaysone Phomvihane",
                "countryCode": "LA"
            },
            {
                "name": "Muang Alsaphangthong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Atsaphan",
                "countryCode": "LA"
            },
            {
                "name": "Muang Champhon",
                "countryCode": "LA"
            },
            {
                "name": "Muang Nong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Outhoumphon",
                "countryCode": "LA"
            },
            {
                "name": "Muang Phin",
                "countryCode": "LA"
            },
            {
                "name": "Muang Songkhon",
                "countryCode": "LA"
            },
            {
                "name": "Muang Thapangthong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Vilabouli",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xaibouli",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xayphoothong",
                "countryCode": "LA"
            },
            {
                "name": "Muang Xônbouli",
                "countryCode": "LA"
            },
            {
                "name": "Savannakhet",
                "countryCode": "LA"
            },
            {
                "name": "Thaphalanxay",
                "countryCode": "LA"
            },
            {
                "name": "Muang Phôn-Hông",
                "countryCode": "LA"
            },
            {
                "name": "Vangviang",
                "countryCode": "LA"
            },
            {
                "name": "Vientiane",
                "countryCode": "LA"
            },
            {
                "name": "Sainyabuli",
                "countryCode": "LA"
            },
            {
                "name": "Ban Thatèng",
                "countryCode": "LA"
            },
            {
                "name": "Lamam",
                "countryCode": "LA"
            },
            {
                "name": "Muang Dakchung",
                "countryCode": "LA"
            },
            {
                "name": "Muang Khaleum",
                "countryCode": "LA"
            },
            {
                "name": "Muang Laman",
                "countryCode": "LA"
            },
            {
                "name": "Muang Thatèng",
                "countryCode": "LA"
            },
            {
                "name": "Muang Phônsavan",
                "countryCode": "LA"
            },
            {
                "name": "Anouvong district",
                "countryCode": "LA"
            },
            {
                "name": "Longchaeng",
                "countryCode": "LA"
            },
            {
                "name": "Muang Longxan",
                "countryCode": "LA"
            },
            {
                "name": "Muang Thathôm",
                "countryCode": "LA"
            }
        ]
    },
    {
        "name": "Latvia",
        "phonecode": "371",
        "cities": [
            {
                "name": "Aglona",
                "countryCode": "LV"
            },
            {
                "name": "Aizkraukle",
                "countryCode": "LV"
            },
            {
                "name": "Aizpute",
                "countryCode": "LV"
            },
            {
                "name": "Aknīste",
                "countryCode": "LV"
            },
            {
                "name": "Aloja",
                "countryCode": "LV"
            },
            {
                "name": "Staicele",
                "countryCode": "LV"
            },
            {
                "name": "Alsunga",
                "countryCode": "LV"
            },
            {
                "name": "Alūksne",
                "countryCode": "LV"
            },
            {
                "name": "Ape",
                "countryCode": "LV"
            },
            {
                "name": "Auce",
                "countryCode": "LV"
            },
            {
                "name": "Piņķi",
                "countryCode": "LV"
            },
            {
                "name": "Baldone",
                "countryCode": "LV"
            },
            {
                "name": "Baltinava",
                "countryCode": "LV"
            },
            {
                "name": "Balvi",
                "countryCode": "LV"
            },
            {
                "name": "Bauska",
                "countryCode": "LV"
            },
            {
                "name": "Mūrmuiža",
                "countryCode": "LV"
            },
            {
                "name": "Brocēni",
                "countryCode": "LV"
            },
            {
                "name": "Carnikava",
                "countryCode": "LV"
            },
            {
                "name": "Cesvaine",
                "countryCode": "LV"
            },
            {
                "name": "Cēsis",
                "countryCode": "LV"
            },
            {
                "name": "Cibla",
                "countryCode": "LV"
            },
            {
                "name": "Dagda",
                "countryCode": "LV"
            },
            {
                "name": "Daugavpils",
                "countryCode": "LV"
            },
            {
                "name": "Dobele",
                "countryCode": "LV"
            },
            {
                "name": "Dundaga",
                "countryCode": "LV"
            },
            {
                "name": "Lieģi",
                "countryCode": "LV"
            },
            {
                "name": "Smārde",
                "countryCode": "LV"
            },
            {
                "name": "Ērgļi",
                "countryCode": "LV"
            },
            {
                "name": "Garkalne",
                "countryCode": "LV"
            },
            {
                "name": "Grobiņa",
                "countryCode": "LV"
            },
            {
                "name": "Gulbene",
                "countryCode": "LV"
            },
            {
                "name": "Iecava",
                "countryCode": "LV"
            },
            {
                "name": "Ikšķile",
                "countryCode": "LV"
            },
            {
                "name": "Ilūkste",
                "countryCode": "LV"
            },
            {
                "name": "Inčukalns",
                "countryCode": "LV"
            },
            {
                "name": "Vangaži",
                "countryCode": "LV"
            },
            {
                "name": "Jaunjelgava",
                "countryCode": "LV"
            },
            {
                "name": "Jaunpils",
                "countryCode": "LV"
            },
            {
                "name": "Tīreļi",
                "countryCode": "LV"
            },
            {
                "name": "Jēkabpils",
                "countryCode": "LV"
            },
            {
                "name": "Krustpils",
                "countryCode": "LV"
            },
            {
                "name": "Kandava",
                "countryCode": "LV"
            },
            {
                "name": "Kārsava",
                "countryCode": "LV"
            },
            {
                "name": "Kocēni",
                "countryCode": "LV"
            },
            {
                "name": "Koknese",
                "countryCode": "LV"
            },
            {
                "name": "Krāslava",
                "countryCode": "LV"
            },
            {
                "name": "Kuldīga",
                "countryCode": "LV"
            },
            {
                "name": "Ķegums",
                "countryCode": "LV"
            },
            {
                "name": "Baloži",
                "countryCode": "LV"
            },
            {
                "name": "Ķekava",
                "countryCode": "LV"
            },
            {
                "name": "Lielvārde",
                "countryCode": "LV"
            },
            {
                "name": "Limbaži",
                "countryCode": "LV"
            },
            {
                "name": "Līgatne",
                "countryCode": "LV"
            },
            {
                "name": "Līvāni",
                "countryCode": "LV"
            },
            {
                "name": "Lubāna",
                "countryCode": "LV"
            },
            {
                "name": "Ludza",
                "countryCode": "LV"
            },
            {
                "name": "Madona",
                "countryCode": "LV"
            },
            {
                "name": "Mazsalaca",
                "countryCode": "LV"
            },
            {
                "name": "Mālpils",
                "countryCode": "LV"
            },
            {
                "name": "Mārupe",
                "countryCode": "LV"
            },
            {
                "name": "Naukšēni",
                "countryCode": "LV"
            },
            {
                "name": "Nereta",
                "countryCode": "LV"
            },
            {
                "name": "Nīca",
                "countryCode": "LV"
            },
            {
                "name": "Jumprava",
                "countryCode": "LV"
            },
            {
                "name": "Ogre",
                "countryCode": "LV"
            },
            {
                "name": "Olaine",
                "countryCode": "LV"
            },
            {
                "name": "Ozolnieki",
                "countryCode": "LV"
            },
            {
                "name": "Stalbe",
                "countryCode": "LV"
            },
            {
                "name": "Pāvilosta",
                "countryCode": "LV"
            },
            {
                "name": "Pļaviņas",
                "countryCode": "LV"
            },
            {
                "name": "Jaunaglona",
                "countryCode": "LV"
            },
            {
                "name": "Preiļi",
                "countryCode": "LV"
            },
            {
                "name": "Priekule",
                "countryCode": "LV"
            },
            {
                "name": "Priekuļi",
                "countryCode": "LV"
            },
            {
                "name": "Rauna",
                "countryCode": "LV"
            },
            {
                "name": "Riebiņi",
                "countryCode": "LV"
            },
            {
                "name": "Roja",
                "countryCode": "LV"
            },
            {
                "name": "Ropaži",
                "countryCode": "LV"
            },
            {
                "name": "Rucava",
                "countryCode": "LV"
            },
            {
                "name": "Rugāji",
                "countryCode": "LV"
            },
            {
                "name": "Pilsrundāle",
                "countryCode": "LV"
            },
            {
                "name": "Rūjiena",
                "countryCode": "LV"
            },
            {
                "name": "Ainaži",
                "countryCode": "LV"
            },
            {
                "name": "Salacgrīva",
                "countryCode": "LV"
            },
            {
                "name": "Salaspils",
                "countryCode": "LV"
            },
            {
                "name": "Saldus",
                "countryCode": "LV"
            },
            {
                "name": "Saulkrasti",
                "countryCode": "LV"
            },
            {
                "name": "Engure",
                "countryCode": "LV"
            },
            {
                "name": "Tukums",
                "countryCode": "LV"
            },
            {
                "name": "Sigulda",
                "countryCode": "LV"
            },
            {
                "name": "Skrīveri",
                "countryCode": "LV"
            },
            {
                "name": "Skrunda",
                "countryCode": "LV"
            },
            {
                "name": "Smiltene",
                "countryCode": "LV"
            },
            {
                "name": "Ulbroka",
                "countryCode": "LV"
            },
            {
                "name": "Seda",
                "countryCode": "LV"
            },
            {
                "name": "Strenči",
                "countryCode": "LV"
            },
            {
                "name": "Sabile",
                "countryCode": "LV"
            },
            {
                "name": "Stende",
                "countryCode": "LV"
            },
            {
                "name": "Talsi",
                "countryCode": "LV"
            },
            {
                "name": "Valdemārpils",
                "countryCode": "LV"
            },
            {
                "name": "Tērvete",
                "countryCode": "LV"
            },
            {
                "name": "Zelmeņi",
                "countryCode": "LV"
            },
            {
                "name": "Vaiņode",
                "countryCode": "LV"
            },
            {
                "name": "Valka",
                "countryCode": "LV"
            },
            {
                "name": "Varakļāni",
                "countryCode": "LV"
            },
            {
                "name": "Vecvārkava",
                "countryCode": "LV"
            },
            {
                "name": "Vecpiebalga",
                "countryCode": "LV"
            },
            {
                "name": "Vecumnieki",
                "countryCode": "LV"
            },
            {
                "name": "Piltene",
                "countryCode": "LV"
            },
            {
                "name": "Viesīte",
                "countryCode": "LV"
            },
            {
                "name": "Viļaka",
                "countryCode": "LV"
            },
            {
                "name": "Viļāni",
                "countryCode": "LV"
            },
            {
                "name": "Zilupe",
                "countryCode": "LV"
            },
            {
                "name": "Jelgava",
                "countryCode": "LV"
            },
            {
                "name": "Jūrmala",
                "countryCode": "LV"
            },
            {
                "name": "Karosta",
                "countryCode": "LV"
            },
            {
                "name": "Liepāja",
                "countryCode": "LV"
            },
            {
                "name": "Rēzekne",
                "countryCode": "LV"
            },
            {
                "name": "Bolderaja",
                "countryCode": "LV"
            },
            {
                "name": "Daugavgrīva",
                "countryCode": "LV"
            },
            {
                "name": "Jaunciems",
                "countryCode": "LV"
            },
            {
                "name": "Mežaparks",
                "countryCode": "LV"
            },
            {
                "name": "Riga",
                "countryCode": "LV"
            },
            {
                "name": "Ventspils",
                "countryCode": "LV"
            },
            {
                "name": "Valmiera",
                "countryCode": "LV"
            }
        ]
    },
    {
        "name": "Lebanon",
        "phonecode": "961",
        "cities": [
            {
                "name": "Caza de Aakkar",
                "countryCode": "LB"
            },
            {
                "name": "Batroûn",
                "countryCode": "LB"
            },
            {
                "name": "Bcharré",
                "countryCode": "LB"
            },
            {
                "name": "Tripoli",
                "countryCode": "LB"
            },
            {
                "name": "Beirut",
                "countryCode": "LB"
            },
            {
                "name": "Ra’s Bayrūt",
                "countryCode": "LB"
            },
            {
                "name": "Baalbek",
                "countryCode": "LB"
            },
            {
                "name": "Caza de Baalbek",
                "countryCode": "LB"
            },
            {
                "name": "Aanjar",
                "countryCode": "LB"
            },
            {
                "name": "Zahlé",
                "countryCode": "LB"
            },
            {
                "name": "En Nâqoûra",
                "countryCode": "LB"
            },
            {
                "name": "Ghazieh",
                "countryCode": "LB"
            },
            {
                "name": "Sidon",
                "countryCode": "LB"
            },
            {
                "name": "Tyre",
                "countryCode": "LB"
            },
            {
                "name": "Baabda",
                "countryCode": "LB"
            },
            {
                "name": "Bhamdoun",
                "countryCode": "LB"
            },
            {
                "name": "Bhamdoûn el Mhatta",
                "countryCode": "LB"
            },
            {
                "name": "Caza de Baabda",
                "countryCode": "LB"
            },
            {
                "name": "Jbaïl",
                "countryCode": "LB"
            },
            {
                "name": "Jounieh",
                "countryCode": "LB"
            },
            {
                "name": "Ain Ebel",
                "countryCode": "LB"
            },
            {
                "name": "Caza de Bent Jbaïl",
                "countryCode": "LB"
            },
            {
                "name": "Caza de Nabatîyé",
                "countryCode": "LB"
            },
            {
                "name": "Habboûch",
                "countryCode": "LB"
            },
            {
                "name": "Marjayoûn",
                "countryCode": "LB"
            },
            {
                "name": "Nabatîyé et Tahta",
                "countryCode": "LB"
            }
        ]
    },
    {
        "name": "Lesotho",
        "phonecode": "266",
        "cities": [
            {
                "name": "Maseru",
                "countryCode": "LS"
            },
            {
                "name": "Nako",
                "countryCode": "LS"
            },
            {
                "name": "Butha-Buthe",
                "countryCode": "LS"
            },
            {
                "name": "Leribe",
                "countryCode": "LS"
            },
            {
                "name": "Maputsoe",
                "countryCode": "LS"
            },
            {
                "name": "Teyateyaneng",
                "countryCode": "LS"
            },
            {
                "name": "Mafeteng",
                "countryCode": "LS"
            },
            {
                "name": "Mohale’s Hoek",
                "countryCode": "LS"
            },
            {
                "name": "Quthing",
                "countryCode": "LS"
            },
            {
                "name": "Qacha’s Nek",
                "countryCode": "LS"
            },
            {
                "name": "Mokhotlong",
                "countryCode": "LS"
            },
            {
                "name": "Thaba-Tseka",
                "countryCode": "LS"
            }
        ]
    },
    {
        "name": "Liberia",
        "phonecode": "231",
        "cities": [
            {
                "name": "Gbarnga",
                "countryCode": "LR"
            },
            {
                "name": "Tubmanburg",
                "countryCode": "LR"
            },
            {
                "name": "Robertsport",
                "countryCode": "LR"
            },
            {
                "name": "Buchanan",
                "countryCode": "LR"
            },
            {
                "name": "Zwedru",
                "countryCode": "LR"
            },
            {
                "name": "Barclayville",
                "countryCode": "LR"
            },
            {
                "name": "Bopolu",
                "countryCode": "LR"
            },
            {
                "name": "Voinjama",
                "countryCode": "LR"
            },
            {
                "name": "Kakata",
                "countryCode": "LR"
            },
            {
                "name": "Bensonville",
                "countryCode": "LR"
            },
            {
                "name": "Monrovia",
                "countryCode": "LR"
            },
            {
                "name": "Harper",
                "countryCode": "LR"
            },
            {
                "name": "Ganta",
                "countryCode": "LR"
            },
            {
                "name": "New Yekepa",
                "countryCode": "LR"
            },
            {
                "name": "Sanniquellie",
                "countryCode": "LR"
            },
            {
                "name": "Fish Town",
                "countryCode": "LR"
            },
            {
                "name": "Cestos City",
                "countryCode": "LR"
            },
            {
                "name": "Greenville",
                "countryCode": "LR"
            }
        ]
    },
    {
        "name": "Libya",
        "phonecode": "218",
        "cities": [
            {
                "name": "Benghazi",
                "countryCode": "LY"
            },
            {
                "name": "Qaryat Sulūq",
                "countryCode": "LY"
            },
            {
                "name": "Al Qubbah",
                "countryCode": "LY"
            },
            {
                "name": "Darnah",
                "countryCode": "LY"
            },
            {
                "name": "Ghat",
                "countryCode": "LY"
            },
            {
                "name": "Al Bayḑā’",
                "countryCode": "LY"
            },
            {
                "name": "Gharyan",
                "countryCode": "LY"
            },
            {
                "name": "Giado",
                "countryCode": "LY"
            },
            {
                "name": "Mizdah",
                "countryCode": "LY"
            },
            {
                "name": "Yafran",
                "countryCode": "LY"
            },
            {
                "name": "Zintan",
                "countryCode": "LY"
            },
            {
                "name": "Al ‘Azīzīyah",
                "countryCode": "LY"
            },
            {
                "name": "Hūn",
                "countryCode": "LY"
            },
            {
                "name": "Waddān",
                "countryCode": "LY"
            },
            {
                "name": "Al Jawf",
                "countryCode": "LY"
            },
            {
                "name": "At Tāj",
                "countryCode": "LY"
            },
            {
                "name": "Al Khums",
                "countryCode": "LY"
            },
            {
                "name": "Masallātah",
                "countryCode": "LY"
            },
            {
                "name": "Tarhuna",
                "countryCode": "LY"
            },
            {
                "name": "Bani Walid",
                "countryCode": "LY"
            },
            {
                "name": "Mişrātah",
                "countryCode": "LY"
            },
            {
                "name": "Zliten",
                "countryCode": "LY"
            },
            {
                "name": "Al Abyār",
                "countryCode": "LY"
            },
            {
                "name": "Al Marj",
                "countryCode": "LY"
            },
            {
                "name": "Tūkrah",
                "countryCode": "LY"
            },
            {
                "name": "Al Qaţrūn",
                "countryCode": "LY"
            },
            {
                "name": "Murzuq",
                "countryCode": "LY"
            },
            {
                "name": "Ghadāmis",
                "countryCode": "LY"
            },
            {
                "name": "Nālūt",
                "countryCode": "LY"
            },
            {
                "name": "Al Ajaylat",
                "countryCode": "LY"
            },
            {
                "name": "Zalţan",
                "countryCode": "LY"
            },
            {
                "name": "Zuwārah",
                "countryCode": "LY"
            },
            {
                "name": "Al Jadīd",
                "countryCode": "LY"
            },
            {
                "name": "Sabhā",
                "countryCode": "LY"
            },
            {
                "name": "Qasr Abu Hadi",
                "countryCode": "LY"
            },
            {
                "name": "Sirte",
                "countryCode": "LY"
            },
            {
                "name": "Tagiura",
                "countryCode": "LY"
            },
            {
                "name": "Tripoli",
                "countryCode": "LY"
            },
            {
                "name": "Ajdabiya",
                "countryCode": "LY"
            },
            {
                "name": "Al Burayqah",
                "countryCode": "LY"
            },
            {
                "name": "Awjilah",
                "countryCode": "LY"
            },
            {
                "name": "Az Zuwaytīnah",
                "countryCode": "LY"
            },
            {
                "name": "Gialo",
                "countryCode": "LY"
            },
            {
                "name": "Marādah",
                "countryCode": "LY"
            },
            {
                "name": "Ubari",
                "countryCode": "LY"
            },
            {
                "name": "Brak",
                "countryCode": "LY"
            },
            {
                "name": "Idrī",
                "countryCode": "LY"
            },
            {
                "name": "Az Zāwīyah",
                "countryCode": "LY"
            },
            {
                "name": "Zawiya",
                "countryCode": "LY"
            },
            {
                "name": "Şabrātah",
                "countryCode": "LY"
            },
            {
                "name": "Şurmān",
                "countryCode": "LY"
            }
        ]
    },
    {
        "name": "Liechtenstein",
        "phonecode": "423",
        "cities": [
            {
                "name": "Balzers",
                "countryCode": "LI"
            },
            {
                "name": "Eschen",
                "countryCode": "LI"
            },
            {
                "name": "Gamprin",
                "countryCode": "LI"
            },
            {
                "name": "Mauren",
                "countryCode": "LI"
            },
            {
                "name": "Planken",
                "countryCode": "LI"
            },
            {
                "name": "Ruggell",
                "countryCode": "LI"
            },
            {
                "name": "Schaan",
                "countryCode": "LI"
            },
            {
                "name": "Schellenberg",
                "countryCode": "LI"
            },
            {
                "name": "Triesen",
                "countryCode": "LI"
            },
            {
                "name": "Triesenberg",
                "countryCode": "LI"
            },
            {
                "name": "Vaduz",
                "countryCode": "LI"
            }
        ]
    },
    {
        "name": "Lithuania",
        "phonecode": "370",
        "cities": [
            {
                "name": "Alytus",
                "countryCode": "LT"
            },
            {
                "name": "Daugai",
                "countryCode": "LT"
            },
            {
                "name": "Druskininkai",
                "countryCode": "LT"
            },
            {
                "name": "Lazdijai",
                "countryCode": "LT"
            },
            {
                "name": "Simnas",
                "countryCode": "LT"
            },
            {
                "name": "Varėna",
                "countryCode": "LT"
            },
            {
                "name": "Veisiejai",
                "countryCode": "LT"
            },
            {
                "name": "Akademija (Kaunas)",
                "countryCode": "LT"
            },
            {
                "name": "Aleksotas",
                "countryCode": "LT"
            },
            {
                "name": "Ariogala",
                "countryCode": "LT"
            },
            {
                "name": "Birštonas",
                "countryCode": "LT"
            },
            {
                "name": "Dainava (Kaunas)",
                "countryCode": "LT"
            },
            {
                "name": "Eiguliai",
                "countryCode": "LT"
            },
            {
                "name": "Ežerėlis",
                "countryCode": "LT"
            },
            {
                "name": "Garliava",
                "countryCode": "LT"
            },
            {
                "name": "Jieznas",
                "countryCode": "LT"
            },
            {
                "name": "Jonava",
                "countryCode": "LT"
            },
            {
                "name": "Kaišiadorys",
                "countryCode": "LT"
            },
            {
                "name": "Karmėlava",
                "countryCode": "LT"
            },
            {
                "name": "Kaunas",
                "countryCode": "LT"
            },
            {
                "name": "Kulautuva",
                "countryCode": "LT"
            },
            {
                "name": "Kėdainiai",
                "countryCode": "LT"
            },
            {
                "name": "Mastaiciai",
                "countryCode": "LT"
            },
            {
                "name": "Prienai",
                "countryCode": "LT"
            },
            {
                "name": "Ramučiai",
                "countryCode": "LT"
            },
            {
                "name": "Raseiniai",
                "countryCode": "LT"
            },
            {
                "name": "Sargėnai",
                "countryCode": "LT"
            },
            {
                "name": "Vilkija",
                "countryCode": "LT"
            },
            {
                "name": "Šilainiai",
                "countryCode": "LT"
            },
            {
                "name": "Žiežmariai",
                "countryCode": "LT"
            },
            {
                "name": "Gargždai",
                "countryCode": "LT"
            },
            {
                "name": "Klaipėda",
                "countryCode": "LT"
            },
            {
                "name": "Kretinga",
                "countryCode": "LT"
            },
            {
                "name": "Neringa",
                "countryCode": "LT"
            },
            {
                "name": "Nida",
                "countryCode": "LT"
            },
            {
                "name": "Palanga",
                "countryCode": "LT"
            },
            {
                "name": "Priekulė",
                "countryCode": "LT"
            },
            {
                "name": "Rusnė",
                "countryCode": "LT"
            },
            {
                "name": "Salantai",
                "countryCode": "LT"
            },
            {
                "name": "Silute",
                "countryCode": "LT"
            },
            {
                "name": "Skuodas",
                "countryCode": "LT"
            },
            {
                "name": "Šventoji",
                "countryCode": "LT"
            },
            {
                "name": "Žemaičių Naumiestis",
                "countryCode": "LT"
            },
            {
                "name": "Gelgaudiškis",
                "countryCode": "LT"
            },
            {
                "name": "Kalvarija",
                "countryCode": "LT"
            },
            {
                "name": "Kalvarija Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Kazlų Rūda",
                "countryCode": "LT"
            },
            {
                "name": "Kazlų Rūda Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Kudirkos Naumiestis",
                "countryCode": "LT"
            },
            {
                "name": "Kybartai",
                "countryCode": "LT"
            },
            {
                "name": "Marijampolė",
                "countryCode": "LT"
            },
            {
                "name": "Marijampolė Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Sakiai",
                "countryCode": "LT"
            },
            {
                "name": "Vilkaviskis",
                "countryCode": "LT"
            },
            {
                "name": "Vilkaviškis District Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Virbalis",
                "countryCode": "LT"
            },
            {
                "name": "Birzai",
                "countryCode": "LT"
            },
            {
                "name": "Juodupė",
                "countryCode": "LT"
            },
            {
                "name": "Kupiskis",
                "countryCode": "LT"
            },
            {
                "name": "Obeliai",
                "countryCode": "LT"
            },
            {
                "name": "Panevėžys",
                "countryCode": "LT"
            },
            {
                "name": "Panevėžys City",
                "countryCode": "LT"
            },
            {
                "name": "Panevėžys District Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Pasvalys",
                "countryCode": "LT"
            },
            {
                "name": "Ramygala",
                "countryCode": "LT"
            },
            {
                "name": "Rokiškis",
                "countryCode": "LT"
            },
            {
                "name": "Vabalninkas",
                "countryCode": "LT"
            },
            {
                "name": "Akmenė",
                "countryCode": "LT"
            },
            {
                "name": "Joniškis",
                "countryCode": "LT"
            },
            {
                "name": "Kelmė",
                "countryCode": "LT"
            },
            {
                "name": "Kuršėnai",
                "countryCode": "LT"
            },
            {
                "name": "Linkuva",
                "countryCode": "LT"
            },
            {
                "name": "Naujoji Akmene",
                "countryCode": "LT"
            },
            {
                "name": "Pakruojis",
                "countryCode": "LT"
            },
            {
                "name": "Radviliskis",
                "countryCode": "LT"
            },
            {
                "name": "Tytuvėnėliai",
                "countryCode": "LT"
            },
            {
                "name": "Venta",
                "countryCode": "LT"
            },
            {
                "name": "Šeduva",
                "countryCode": "LT"
            },
            {
                "name": "Šiauliai",
                "countryCode": "LT"
            },
            {
                "name": "Žagarė",
                "countryCode": "LT"
            },
            {
                "name": "Mazeikiai",
                "countryCode": "LT"
            },
            {
                "name": "Plateliai",
                "countryCode": "LT"
            },
            {
                "name": "Plunge",
                "countryCode": "LT"
            },
            {
                "name": "Rietavas",
                "countryCode": "LT"
            },
            {
                "name": "Seda",
                "countryCode": "LT"
            },
            {
                "name": "Telsiai",
                "countryCode": "LT"
            },
            {
                "name": "Varniai",
                "countryCode": "LT"
            },
            {
                "name": "Viekšniai",
                "countryCode": "LT"
            },
            {
                "name": "Aukstadvaris",
                "countryCode": "LT"
            },
            {
                "name": "Baltoji Vokė",
                "countryCode": "LT"
            },
            {
                "name": "Eišiškės",
                "countryCode": "LT"
            },
            {
                "name": "Elektrėnai",
                "countryCode": "LT"
            },
            {
                "name": "Fabijoniškės",
                "countryCode": "LT"
            },
            {
                "name": "Grigiškės",
                "countryCode": "LT"
            },
            {
                "name": "Justiniškės",
                "countryCode": "LT"
            },
            {
                "name": "Lazdynai",
                "countryCode": "LT"
            },
            {
                "name": "Lentvaris",
                "countryCode": "LT"
            },
            {
                "name": "Naujamiestis",
                "countryCode": "LT"
            },
            {
                "name": "Nemenčinė",
                "countryCode": "LT"
            },
            {
                "name": "Pabradė",
                "countryCode": "LT"
            },
            {
                "name": "Pašilaičiai",
                "countryCode": "LT"
            },
            {
                "name": "Pilaitė",
                "countryCode": "LT"
            },
            {
                "name": "Rasos",
                "countryCode": "LT"
            },
            {
                "name": "Rūdiškės",
                "countryCode": "LT"
            },
            {
                "name": "Senieji Trakai",
                "countryCode": "LT"
            },
            {
                "name": "Sirvintos",
                "countryCode": "LT"
            },
            {
                "name": "Skaidiškės",
                "countryCode": "LT"
            },
            {
                "name": "Svencionys",
                "countryCode": "LT"
            },
            {
                "name": "Trakai",
                "countryCode": "LT"
            },
            {
                "name": "Ukmerge",
                "countryCode": "LT"
            },
            {
                "name": "Vievis",
                "countryCode": "LT"
            },
            {
                "name": "Vilkpėdė",
                "countryCode": "LT"
            },
            {
                "name": "Vilnius",
                "countryCode": "LT"
            },
            {
                "name": "Vilnius District Municipality",
                "countryCode": "LT"
            },
            {
                "name": "Šalčininkai",
                "countryCode": "LT"
            },
            {
                "name": "Šeškinė",
                "countryCode": "LT"
            },
            {
                "name": "Švenčionėliai",
                "countryCode": "LT"
            },
            {
                "name": "Būgai",
                "countryCode": "LT"
            },
            {
                "name": "Jurbarkas",
                "countryCode": "LT"
            },
            {
                "name": "Pagėgiai",
                "countryCode": "LT"
            },
            {
                "name": "Taurage",
                "countryCode": "LT"
            },
            {
                "name": "Šilalė",
                "countryCode": "LT"
            },
            {
                "name": "Anyksciai",
                "countryCode": "LT"
            },
            {
                "name": "Dūkštas",
                "countryCode": "LT"
            },
            {
                "name": "Ignalina",
                "countryCode": "LT"
            },
            {
                "name": "Moletai",
                "countryCode": "LT"
            },
            {
                "name": "Utena",
                "countryCode": "LT"
            },
            {
                "name": "Visaginas",
                "countryCode": "LT"
            },
            {
                "name": "Zarasai",
                "countryCode": "LT"
            }
        ]
    },
    {
        "name": "Luxembourg",
        "phonecode": "352",
        "cities": [
            {
                "name": "Bascharage",
                "countryCode": "LU"
            },
            {
                "name": "Bridel",
                "countryCode": "LU"
            },
            {
                "name": "Capellen",
                "countryCode": "LU"
            },
            {
                "name": "Clemency",
                "countryCode": "LU"
            },
            {
                "name": "Dippach",
                "countryCode": "LU"
            },
            {
                "name": "Eischen",
                "countryCode": "LU"
            },
            {
                "name": "Garnich",
                "countryCode": "LU"
            },
            {
                "name": "Hautcharage",
                "countryCode": "LU"
            },
            {
                "name": "Hobscheid",
                "countryCode": "LU"
            },
            {
                "name": "Kehlen",
                "countryCode": "LU"
            },
            {
                "name": "Koerich",
                "countryCode": "LU"
            },
            {
                "name": "Kopstal",
                "countryCode": "LU"
            },
            {
                "name": "Käerjeng",
                "countryCode": "LU"
            },
            {
                "name": "Mamer",
                "countryCode": "LU"
            },
            {
                "name": "Schouweiler",
                "countryCode": "LU"
            },
            {
                "name": "Septfontaines",
                "countryCode": "LU"
            },
            {
                "name": "Steinfort",
                "countryCode": "LU"
            },
            {
                "name": "Clervaux",
                "countryCode": "LU"
            },
            {
                "name": "Hosingen",
                "countryCode": "LU"
            },
            {
                "name": "Parc Hosingen",
                "countryCode": "LU"
            },
            {
                "name": "Troisvierges",
                "countryCode": "LU"
            },
            {
                "name": "Weiswampach",
                "countryCode": "LU"
            },
            {
                "name": "Wincrange",
                "countryCode": "LU"
            },
            {
                "name": "Bettendorf",
                "countryCode": "LU"
            },
            {
                "name": "Bourscheid",
                "countryCode": "LU"
            },
            {
                "name": "Commune de la Vallée de l’Ernz",
                "countryCode": "LU"
            },
            {
                "name": "Diekirch",
                "countryCode": "LU"
            },
            {
                "name": "Erpeldange",
                "countryCode": "LU"
            },
            {
                "name": "Ettelbruck",
                "countryCode": "LU"
            },
            {
                "name": "Feulen",
                "countryCode": "LU"
            },
            {
                "name": "Medernach",
                "countryCode": "LU"
            },
            {
                "name": "Mertzig",
                "countryCode": "LU"
            },
            {
                "name": "Niederfeulen",
                "countryCode": "LU"
            },
            {
                "name": "Reisdorf",
                "countryCode": "LU"
            },
            {
                "name": "Schieren",
                "countryCode": "LU"
            },
            {
                "name": "Warken",
                "countryCode": "LU"
            },
            {
                "name": "Beaufort",
                "countryCode": "LU"
            },
            {
                "name": "Bech",
                "countryCode": "LU"
            },
            {
                "name": "Berdorf",
                "countryCode": "LU"
            },
            {
                "name": "Consdorf",
                "countryCode": "LU"
            },
            {
                "name": "Echternach",
                "countryCode": "LU"
            },
            {
                "name": "Mompach",
                "countryCode": "LU"
            },
            {
                "name": "Rosport",
                "countryCode": "LU"
            },
            {
                "name": "Waldbillig",
                "countryCode": "LU"
            },
            {
                "name": "Aspelt",
                "countryCode": "LU"
            },
            {
                "name": "Belvaux",
                "countryCode": "LU"
            },
            {
                "name": "Bergem",
                "countryCode": "LU"
            },
            {
                "name": "Bettembourg",
                "countryCode": "LU"
            },
            {
                "name": "Crauthem",
                "countryCode": "LU"
            },
            {
                "name": "Differdange",
                "countryCode": "LU"
            },
            {
                "name": "Dudelange",
                "countryCode": "LU"
            },
            {
                "name": "Esch-sur-Alzette",
                "countryCode": "LU"
            },
            {
                "name": "Frisange",
                "countryCode": "LU"
            },
            {
                "name": "Kayl",
                "countryCode": "LU"
            },
            {
                "name": "Lamadelaine",
                "countryCode": "LU"
            },
            {
                "name": "Leudelange",
                "countryCode": "LU"
            },
            {
                "name": "Mondercange",
                "countryCode": "LU"
            },
            {
                "name": "Niedercorn",
                "countryCode": "LU"
            },
            {
                "name": "Obercorn",
                "countryCode": "LU"
            },
            {
                "name": "Pontpierre",
                "countryCode": "LU"
            },
            {
                "name": "Pétange",
                "countryCode": "LU"
            },
            {
                "name": "Reckange-sur-Mess",
                "countryCode": "LU"
            },
            {
                "name": "Rodange",
                "countryCode": "LU"
            },
            {
                "name": "Roeser",
                "countryCode": "LU"
            },
            {
                "name": "Rumelange",
                "countryCode": "LU"
            },
            {
                "name": "Sanem",
                "countryCode": "LU"
            },
            {
                "name": "Schifflange",
                "countryCode": "LU"
            },
            {
                "name": "Soleuvre",
                "countryCode": "LU"
            },
            {
                "name": "Tétange",
                "countryCode": "LU"
            },
            {
                "name": "Betzdorf",
                "countryCode": "LU"
            },
            {
                "name": "Biwer",
                "countryCode": "LU"
            },
            {
                "name": "Flaxweiler",
                "countryCode": "LU"
            },
            {
                "name": "Gonderange",
                "countryCode": "LU"
            },
            {
                "name": "Grevenmacher",
                "countryCode": "LU"
            },
            {
                "name": "Junglinster",
                "countryCode": "LU"
            },
            {
                "name": "Manternach",
                "countryCode": "LU"
            },
            {
                "name": "Mertert",
                "countryCode": "LU"
            },
            {
                "name": "Wasserbillig",
                "countryCode": "LU"
            },
            {
                "name": "Wormeldange",
                "countryCode": "LU"
            },
            {
                "name": "Alzingen",
                "countryCode": "LU"
            },
            {
                "name": "Bertrange",
                "countryCode": "LU"
            },
            {
                "name": "Béreldange",
                "countryCode": "LU"
            },
            {
                "name": "Contern",
                "countryCode": "LU"
            },
            {
                "name": "Fentange",
                "countryCode": "LU"
            },
            {
                "name": "Heisdorf",
                "countryCode": "LU"
            },
            {
                "name": "Helmsange",
                "countryCode": "LU"
            },
            {
                "name": "Hesperange",
                "countryCode": "LU"
            },
            {
                "name": "Itzig",
                "countryCode": "LU"
            },
            {
                "name": "Kirchberg",
                "countryCode": "LU"
            },
            {
                "name": "Luxembourg",
                "countryCode": "LU"
            },
            {
                "name": "Moutfort",
                "countryCode": "LU"
            },
            {
                "name": "Müllendorf",
                "countryCode": "LU"
            },
            {
                "name": "Niederanven",
                "countryCode": "LU"
            },
            {
                "name": "Olm",
                "countryCode": "LU"
            },
            {
                "name": "Sandweiler",
                "countryCode": "LU"
            },
            {
                "name": "Schrassig",
                "countryCode": "LU"
            },
            {
                "name": "Schuttrange",
                "countryCode": "LU"
            },
            {
                "name": "Steinsel",
                "countryCode": "LU"
            },
            {
                "name": "Strassen",
                "countryCode": "LU"
            },
            {
                "name": "Walferdange",
                "countryCode": "LU"
            },
            {
                "name": "Weiler-la-Tour",
                "countryCode": "LU"
            },
            {
                "name": "Bissen",
                "countryCode": "LU"
            },
            {
                "name": "Boevange-sur-Attert",
                "countryCode": "LU"
            },
            {
                "name": "Colmar",
                "countryCode": "LU"
            },
            {
                "name": "Fischbach",
                "countryCode": "LU"
            },
            {
                "name": "Heffingen",
                "countryCode": "LU"
            },
            {
                "name": "Larochette",
                "countryCode": "LU"
            },
            {
                "name": "Lintgen",
                "countryCode": "LU"
            },
            {
                "name": "Lorentzweiler",
                "countryCode": "LU"
            },
            {
                "name": "Mersch",
                "countryCode": "LU"
            },
            {
                "name": "Nommern",
                "countryCode": "LU"
            },
            {
                "name": "Rollingen",
                "countryCode": "LU"
            },
            {
                "name": "Tuntange",
                "countryCode": "LU"
            },
            {
                "name": "Beckerich",
                "countryCode": "LU"
            },
            {
                "name": "Bettborn",
                "countryCode": "LU"
            },
            {
                "name": "Commune de Préizerdaul",
                "countryCode": "LU"
            },
            {
                "name": "Ell",
                "countryCode": "LU"
            },
            {
                "name": "Grosbous",
                "countryCode": "LU"
            },
            {
                "name": "Rambrouch",
                "countryCode": "LU"
            },
            {
                "name": "Redange-sur-Attert",
                "countryCode": "LU"
            },
            {
                "name": "Saeul",
                "countryCode": "LU"
            },
            {
                "name": "Useldange",
                "countryCode": "LU"
            },
            {
                "name": "Vichten",
                "countryCode": "LU"
            },
            {
                "name": "Wahl",
                "countryCode": "LU"
            },
            {
                "name": "Bous",
                "countryCode": "LU"
            },
            {
                "name": "Dalheim",
                "countryCode": "LU"
            },
            {
                "name": "Lenningen",
                "countryCode": "LU"
            },
            {
                "name": "Mondorf-les-Bains",
                "countryCode": "LU"
            },
            {
                "name": "Remich",
                "countryCode": "LU"
            },
            {
                "name": "Schengen",
                "countryCode": "LU"
            },
            {
                "name": "Stadtbredimus",
                "countryCode": "LU"
            },
            {
                "name": "Waldbredimus",
                "countryCode": "LU"
            },
            {
                "name": "Putscheid",
                "countryCode": "LU"
            },
            {
                "name": "Tandel",
                "countryCode": "LU"
            },
            {
                "name": "Vianden",
                "countryCode": "LU"
            },
            {
                "name": "Bavigne",
                "countryCode": "LU"
            },
            {
                "name": "Boulaide",
                "countryCode": "LU"
            },
            {
                "name": "Esch-sur-Sûre",
                "countryCode": "LU"
            },
            {
                "name": "Goesdorf",
                "countryCode": "LU"
            },
            {
                "name": "Kiischpelt",
                "countryCode": "LU"
            },
            {
                "name": "Lac de la Haute-Sûre",
                "countryCode": "LU"
            },
            {
                "name": "Wiltz",
                "countryCode": "LU"
            },
            {
                "name": "Wilwerwiltz",
                "countryCode": "LU"
            },
            {
                "name": "Winseler",
                "countryCode": "LU"
            }
        ]
    },
    {
        "name": "Macau S.A.R.",
        "phonecode": "853",
        "cities": []
    },
    {
        "name": "Macedonia",
        "phonecode": "389",
        "cities": [
            {
                "name": "Арачиново",
                "countryCode": "MK"
            },
            {
                "name": "Berovo",
                "countryCode": "MK"
            },
            {
                "name": "Rusinovo",
                "countryCode": "MK"
            },
            {
                "name": "Vladimirovo",
                "countryCode": "MK"
            },
            {
                "name": "Bistrica",
                "countryCode": "MK"
            },
            {
                "name": "Bitola",
                "countryCode": "MK"
            },
            {
                "name": "Capari",
                "countryCode": "MK"
            },
            {
                "name": "Dolno Orizari",
                "countryCode": "MK"
            },
            {
                "name": "Gorno Orizari",
                "countryCode": "MK"
            },
            {
                "name": "Kukurečani",
                "countryCode": "MK"
            },
            {
                "name": "Logovardi",
                "countryCode": "MK"
            },
            {
                "name": "Bogdanci",
                "countryCode": "MK"
            },
            {
                "name": "Stojakovo",
                "countryCode": "MK"
            },
            {
                "name": "Bogovinje",
                "countryCode": "MK"
            },
            {
                "name": "Dolno Palčište",
                "countryCode": "MK"
            },
            {
                "name": "Gradec",
                "countryCode": "MK"
            },
            {
                "name": "Kamenjane",
                "countryCode": "MK"
            },
            {
                "name": "Bosilovo",
                "countryCode": "MK"
            },
            {
                "name": "Ilovica",
                "countryCode": "MK"
            },
            {
                "name": "Sekirnik",
                "countryCode": "MK"
            },
            {
                "name": "Brvenica",
                "countryCode": "MK"
            },
            {
                "name": "Gurgurnica",
                "countryCode": "MK"
            },
            {
                "name": "Miletino",
                "countryCode": "MK"
            },
            {
                "name": "Čelopek",
                "countryCode": "MK"
            },
            {
                "name": "Butel",
                "countryCode": "MK"
            },
            {
                "name": "Radishani",
                "countryCode": "MK"
            },
            {
                "name": "Pirava",
                "countryCode": "MK"
            },
            {
                "name": "Valandovo",
                "countryCode": "MK"
            },
            {
                "name": "Vasilevo",
                "countryCode": "MK"
            },
            {
                "name": "Vevčani",
                "countryCode": "MK"
            },
            {
                "name": "Gorno Orizari",
                "countryCode": "MK"
            },
            {
                "name": "Ivankovci",
                "countryCode": "MK"
            },
            {
                "name": "Veles",
                "countryCode": "MK"
            },
            {
                "name": "Blatec",
                "countryCode": "MK"
            },
            {
                "name": "Gradec",
                "countryCode": "MK"
            },
            {
                "name": "Vinica",
                "countryCode": "MK"
            },
            {
                "name": "Dobri Dol",
                "countryCode": "MK"
            },
            {
                "name": "Negotino",
                "countryCode": "MK"
            },
            {
                "name": "Vrapčište",
                "countryCode": "MK"
            },
            {
                "name": "Cresevo",
                "countryCode": "MK"
            },
            {
                "name": "Gevgelija",
                "countryCode": "MK"
            },
            {
                "name": "Miravci",
                "countryCode": "MK"
            },
            {
                "name": "Cegrane",
                "countryCode": "MK"
            },
            {
                "name": "Dolna Banjica",
                "countryCode": "MK"
            },
            {
                "name": "Forino",
                "countryCode": "MK"
            },
            {
                "name": "Gostivar",
                "countryCode": "MK"
            },
            {
                "name": "Srbinovo",
                "countryCode": "MK"
            },
            {
                "name": "Vrutok",
                "countryCode": "MK"
            },
            {
                "name": "Gradsko",
                "countryCode": "MK"
            },
            {
                "name": "Belčišta",
                "countryCode": "MK"
            },
            {
                "name": "Mešeišta",
                "countryCode": "MK"
            },
            {
                "name": "Delcevo",
                "countryCode": "MK"
            },
            {
                "name": "Demir Kapija",
                "countryCode": "MK"
            },
            {
                "name": "Demir Hisar",
                "countryCode": "MK"
            },
            {
                "name": "Slepče",
                "countryCode": "MK"
            },
            {
                "name": "Sopotnica",
                "countryCode": "MK"
            },
            {
                "name": "Star Dojran",
                "countryCode": "MK"
            },
            {
                "name": "Crnilište",
                "countryCode": "MK"
            },
            {
                "name": "Desovo",
                "countryCode": "MK"
            },
            {
                "name": "Dolneni",
                "countryCode": "MK"
            },
            {
                "name": "Lažani",
                "countryCode": "MK"
            },
            {
                "name": "Žitoše",
                "countryCode": "MK"
            },
            {
                "name": "Gjorče Petro",
                "countryCode": "MK"
            },
            {
                "name": "Dobarce",
                "countryCode": "MK"
            },
            {
                "name": "Larce",
                "countryCode": "MK"
            },
            {
                "name": "Sedlarevo",
                "countryCode": "MK"
            },
            {
                "name": "Strimnica",
                "countryCode": "MK"
            },
            {
                "name": "Zelino",
                "countryCode": "MK"
            },
            {
                "name": "Zelenikovo",
                "countryCode": "MK"
            },
            {
                "name": "Zrnovci",
                "countryCode": "MK"
            },
            {
                "name": "Ilinden",
                "countryCode": "MK"
            },
            {
                "name": "Kadino",
                "countryCode": "MK"
            },
            {
                "name": "Marino",
                "countryCode": "MK"
            },
            {
                "name": "Miladinovci",
                "countryCode": "MK"
            },
            {
                "name": "Јурумлери",
                "countryCode": "MK"
            },
            {
                "name": "Идризово",
                "countryCode": "MK"
            },
            {
                "name": "Jegunovce",
                "countryCode": "MK"
            },
            {
                "name": "Vratnica",
                "countryCode": "MK"
            },
            {
                "name": "Kavadarci",
                "countryCode": "MK"
            },
            {
                "name": "Vataša",
                "countryCode": "MK"
            },
            {
                "name": "Karbinci",
                "countryCode": "MK"
            },
            {
                "name": "Skopje",
                "countryCode": "MK"
            },
            {
                "name": "Kisela Voda",
                "countryCode": "MK"
            },
            {
                "name": "Drugovo",
                "countryCode": "MK"
            },
            {
                "name": "Kičevo",
                "countryCode": "MK"
            },
            {
                "name": "Srbica",
                "countryCode": "MK"
            },
            {
                "name": "Strelci",
                "countryCode": "MK"
            },
            {
                "name": "Tuin",
                "countryCode": "MK"
            },
            {
                "name": "Vraneštica",
                "countryCode": "MK"
            },
            {
                "name": "Zajas",
                "countryCode": "MK"
            },
            {
                "name": "Konče",
                "countryCode": "MK"
            },
            {
                "name": "Kochani",
                "countryCode": "MK"
            },
            {
                "name": "Orizari",
                "countryCode": "MK"
            },
            {
                "name": "Kratovo",
                "countryCode": "MK"
            },
            {
                "name": "Kriva Palanka",
                "countryCode": "MK"
            },
            {
                "name": "Krivogashtani",
                "countryCode": "MK"
            },
            {
                "name": "Obršani",
                "countryCode": "MK"
            },
            {
                "name": "Клечовце",
                "countryCode": "MK"
            },
            {
                "name": "Bučin",
                "countryCode": "MK"
            },
            {
                "name": "Krusevo",
                "countryCode": "MK"
            },
            {
                "name": "Bedinje",
                "countryCode": "MK"
            },
            {
                "name": "Kumanovo",
                "countryCode": "MK"
            },
            {
                "name": "Romanovci",
                "countryCode": "MK"
            },
            {
                "name": "Izvor",
                "countryCode": "MK"
            },
            {
                "name": "Lipkovo",
                "countryCode": "MK"
            },
            {
                "name": "Lojane",
                "countryCode": "MK"
            },
            {
                "name": "Matejce",
                "countryCode": "MK"
            },
            {
                "name": "Otlja",
                "countryCode": "MK"
            },
            {
                "name": "Vaksince",
                "countryCode": "MK"
            },
            {
                "name": "Слупчане",
                "countryCode": "MK"
            },
            {
                "name": "Lozovo",
                "countryCode": "MK"
            },
            {
                "name": "Rostusa",
                "countryCode": "MK"
            },
            {
                "name": "Makedonska Kamenica",
                "countryCode": "MK"
            },
            {
                "name": "Makedonski Brod",
                "countryCode": "MK"
            },
            {
                "name": "Samokov",
                "countryCode": "MK"
            },
            {
                "name": "Beranci",
                "countryCode": "MK"
            },
            {
                "name": "Dobruševo",
                "countryCode": "MK"
            },
            {
                "name": "Dolno Srpci",
                "countryCode": "MK"
            },
            {
                "name": "Mogila",
                "countryCode": "MK"
            },
            {
                "name": "Negotino",
                "countryCode": "MK"
            },
            {
                "name": "Novaci",
                "countryCode": "MK"
            },
            {
                "name": "Novo Selo",
                "countryCode": "MK"
            },
            {
                "name": "Sušica",
                "countryCode": "MK"
            },
            {
                "name": "Kosel",
                "countryCode": "MK"
            },
            {
                "name": "Ohrid",
                "countryCode": "MK"
            },
            {
                "name": "Ohrid Opština",
                "countryCode": "MK"
            },
            {
                "name": "Sredno Konjare",
                "countryCode": "MK"
            },
            {
                "name": "Петровец",
                "countryCode": "MK"
            },
            {
                "name": "Pehčevo",
                "countryCode": "MK"
            },
            {
                "name": "Lisičani",
                "countryCode": "MK"
            },
            {
                "name": "Plasnica",
                "countryCode": "MK"
            },
            {
                "name": "Kanatlarci",
                "countryCode": "MK"
            },
            {
                "name": "Markov Grad",
                "countryCode": "MK"
            },
            {
                "name": "Prilep",
                "countryCode": "MK"
            },
            {
                "name": "Topolčani",
                "countryCode": "MK"
            },
            {
                "name": "Probishtip",
                "countryCode": "MK"
            },
            {
                "name": "Zletovo",
                "countryCode": "MK"
            },
            {
                "name": "Oraovica",
                "countryCode": "MK"
            },
            {
                "name": "Podareš",
                "countryCode": "MK"
            },
            {
                "name": "Radovis",
                "countryCode": "MK"
            },
            {
                "name": "Ранковце",
                "countryCode": "MK"
            },
            {
                "name": "Grnčari",
                "countryCode": "MK"
            },
            {
                "name": "Jankovec",
                "countryCode": "MK"
            },
            {
                "name": "Krani",
                "countryCode": "MK"
            },
            {
                "name": "Resen",
                "countryCode": "MK"
            },
            {
                "name": "Rosoman",
                "countryCode": "MK"
            },
            {
                "name": "Bukovik",
                "countryCode": "MK"
            },
            {
                "name": "Grčec",
                "countryCode": "MK"
            },
            {
                "name": "Kondovo",
                "countryCode": "MK"
            },
            {
                "name": "Krušopek",
                "countryCode": "MK"
            },
            {
                "name": "Gorobinci",
                "countryCode": "MK"
            },
            {
                "name": "Sveti Nikole",
                "countryCode": "MK"
            },
            {
                "name": "Сопиште",
                "countryCode": "MK"
            },
            {
                "name": "Старо Нагоричане",
                "countryCode": "MK"
            },
            {
                "name": "Delogožda",
                "countryCode": "MK"
            },
            {
                "name": "Labunista",
                "countryCode": "MK"
            },
            {
                "name": "Lukovo",
                "countryCode": "MK"
            },
            {
                "name": "Mislesevo",
                "countryCode": "MK"
            },
            {
                "name": "Oktisi",
                "countryCode": "MK"
            },
            {
                "name": "Radolista",
                "countryCode": "MK"
            },
            {
                "name": "Struga",
                "countryCode": "MK"
            },
            {
                "name": "Velesta",
                "countryCode": "MK"
            },
            {
                "name": "Kuklis",
                "countryCode": "MK"
            },
            {
                "name": "Murtino",
                "countryCode": "MK"
            },
            {
                "name": "Strumica",
                "countryCode": "MK"
            },
            {
                "name": "Veljusa",
                "countryCode": "MK"
            },
            {
                "name": "Batinci",
                "countryCode": "MK"
            },
            {
                "name": "Dolno Količani",
                "countryCode": "MK"
            },
            {
                "name": "Morani",
                "countryCode": "MK"
            },
            {
                "name": "Studeničane",
                "countryCode": "MK"
            },
            {
                "name": "Nerašte",
                "countryCode": "MK"
            },
            {
                "name": "Orashac",
                "countryCode": "MK"
            },
            {
                "name": "Pršovce",
                "countryCode": "MK"
            },
            {
                "name": "Slatino",
                "countryCode": "MK"
            },
            {
                "name": "Tearce",
                "countryCode": "MK"
            },
            {
                "name": "Dobrošte",
                "countryCode": "MK"
            },
            {
                "name": "Džepčište",
                "countryCode": "MK"
            },
            {
                "name": "Golema Rečica",
                "countryCode": "MK"
            },
            {
                "name": "Pirok",
                "countryCode": "MK"
            },
            {
                "name": "Poroj",
                "countryCode": "MK"
            },
            {
                "name": "Selce",
                "countryCode": "MK"
            },
            {
                "name": "Tetovo",
                "countryCode": "MK"
            },
            {
                "name": "Šipkovica",
                "countryCode": "MK"
            },
            {
                "name": "Centar Župa",
                "countryCode": "MK"
            },
            {
                "name": "Čair",
                "countryCode": "MK"
            },
            {
                "name": "Bogomila",
                "countryCode": "MK"
            },
            {
                "name": "Čaška",
                "countryCode": "MK"
            },
            {
                "name": "Oblesevo",
                "countryCode": "MK"
            },
            {
                "name": "Češinovo",
                "countryCode": "MK"
            },
            {
                "name": "Чучер - Сандево",
                "countryCode": "MK"
            },
            {
                "name": "Shtip",
                "countryCode": "MK"
            },
            {
                "name": "Šuto Orizare",
                "countryCode": "MK"
            },
            {
                "name": "Bojane",
                "countryCode": "MK"
            },
            {
                "name": "Dračevo",
                "countryCode": "MK"
            },
            {
                "name": "Ljubin",
                "countryCode": "MK"
            },
            {
                "name": "Saraj",
                "countryCode": "MK"
            },
            {
                "name": "Usje",
                "countryCode": "MK"
            }
        ]
    },
    {
        "name": "Madagascar",
        "phonecode": "261",
        "cities": [
            {
                "name": "Ampasimanolotra",
                "countryCode": "MG"
            },
            {
                "name": "Andovoranto",
                "countryCode": "MG"
            },
            {
                "name": "Mahanoro",
                "countryCode": "MG"
            },
            {
                "name": "Mahavelona",
                "countryCode": "MG"
            },
            {
                "name": "Marolambo",
                "countryCode": "MG"
            },
            {
                "name": "Toamasina",
                "countryCode": "MG"
            },
            {
                "name": "Toamasina I",
                "countryCode": "MG"
            },
            {
                "name": "Toamasina II",
                "countryCode": "MG"
            },
            {
                "name": "Vohibinany",
                "countryCode": "MG"
            }
        ]
    },
    {
        "name": "Malawi",
        "phonecode": "265",
        "cities": [
            {
                "name": "Chipoka",
                "countryCode": "MW"
            },
            {
                "name": "Dedza",
                "countryCode": "MW"
            },
            {
                "name": "Dedza District",
                "countryCode": "MW"
            },
            {
                "name": "Dowa",
                "countryCode": "MW"
            },
            {
                "name": "Dowa District",
                "countryCode": "MW"
            },
            {
                "name": "Kasungu",
                "countryCode": "MW"
            },
            {
                "name": "Kasungu District",
                "countryCode": "MW"
            },
            {
                "name": "Lilongwe",
                "countryCode": "MW"
            },
            {
                "name": "Lilongwe District",
                "countryCode": "MW"
            },
            {
                "name": "Mchinji",
                "countryCode": "MW"
            },
            {
                "name": "Mchinji District",
                "countryCode": "MW"
            },
            {
                "name": "Mponela",
                "countryCode": "MW"
            },
            {
                "name": "Nkhotakota",
                "countryCode": "MW"
            },
            {
                "name": "Nkhotakota District",
                "countryCode": "MW"
            },
            {
                "name": "Ntcheu",
                "countryCode": "MW"
            },
            {
                "name": "Ntcheu District",
                "countryCode": "MW"
            },
            {
                "name": "Ntchisi",
                "countryCode": "MW"
            },
            {
                "name": "Ntchisi District",
                "countryCode": "MW"
            },
            {
                "name": "Salima",
                "countryCode": "MW"
            },
            {
                "name": "Salima District",
                "countryCode": "MW"
            },
            {
                "name": "Chitipa",
                "countryCode": "MW"
            },
            {
                "name": "Chitipa District",
                "countryCode": "MW"
            },
            {
                "name": "Karonga",
                "countryCode": "MW"
            },
            {
                "name": "Karonga District",
                "countryCode": "MW"
            },
            {
                "name": "Likoma District",
                "countryCode": "MW"
            },
            {
                "name": "Livingstonia",
                "countryCode": "MW"
            },
            {
                "name": "Mzimba",
                "countryCode": "MW"
            },
            {
                "name": "Mzimba District",
                "countryCode": "MW"
            },
            {
                "name": "Mzuzu",
                "countryCode": "MW"
            },
            {
                "name": "Nkhata Bay",
                "countryCode": "MW"
            },
            {
                "name": "Nkhata Bay District",
                "countryCode": "MW"
            },
            {
                "name": "Rumphi",
                "countryCode": "MW"
            },
            {
                "name": "Rumphi District",
                "countryCode": "MW"
            },
            {
                "name": "Balaka",
                "countryCode": "MW"
            },
            {
                "name": "Balaka District",
                "countryCode": "MW"
            },
            {
                "name": "Blantyre",
                "countryCode": "MW"
            },
            {
                "name": "Blantyre District",
                "countryCode": "MW"
            },
            {
                "name": "Chikwawa",
                "countryCode": "MW"
            },
            {
                "name": "Chikwawa District",
                "countryCode": "MW"
            },
            {
                "name": "Chiradzulu",
                "countryCode": "MW"
            },
            {
                "name": "Chiradzulu District",
                "countryCode": "MW"
            },
            {
                "name": "Liwonde",
                "countryCode": "MW"
            },
            {
                "name": "Luchenza",
                "countryCode": "MW"
            },
            {
                "name": "Machinga",
                "countryCode": "MW"
            },
            {
                "name": "Machinga District",
                "countryCode": "MW"
            },
            {
                "name": "Mangochi",
                "countryCode": "MW"
            },
            {
                "name": "Mangochi District",
                "countryCode": "MW"
            },
            {
                "name": "Monkey Bay",
                "countryCode": "MW"
            },
            {
                "name": "Mulanje",
                "countryCode": "MW"
            },
            {
                "name": "Mulanje District",
                "countryCode": "MW"
            },
            {
                "name": "Mwanza",
                "countryCode": "MW"
            },
            {
                "name": "Mwanza District",
                "countryCode": "MW"
            },
            {
                "name": "Neno District",
                "countryCode": "MW"
            },
            {
                "name": "Nsanje",
                "countryCode": "MW"
            },
            {
                "name": "Nsanje District",
                "countryCode": "MW"
            },
            {
                "name": "Phalombe",
                "countryCode": "MW"
            },
            {
                "name": "Phalombe District",
                "countryCode": "MW"
            },
            {
                "name": "Thyolo",
                "countryCode": "MW"
            },
            {
                "name": "Thyolo District",
                "countryCode": "MW"
            },
            {
                "name": "Zomba",
                "countryCode": "MW"
            },
            {
                "name": "Zomba District",
                "countryCode": "MW"
            }
        ]
    },
    {
        "name": "Malaysia",
        "phonecode": "60",
        "cities": [
            {
                "name": "Bakri",
                "countryCode": "MY"
            },
            {
                "name": "Batu Pahat",
                "countryCode": "MY"
            },
            {
                "name": "Buloh Kasap",
                "countryCode": "MY"
            },
            {
                "name": "Chaah",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Batu Pahat",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Johor Baharu",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Keluang",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Kota Tinggi",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Mersing",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Muar",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Pontian",
                "countryCode": "MY"
            },
            {
                "name": "Daerah Segamat",
                "countryCode": "MY"
            },
            {
                "name": "Johor Bahru",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Pasir Gudang Baru",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Simpang Renggam",
                "countryCode": "MY"
            },
            {
                "name": "Kelapa Sawit",
                "countryCode": "MY"
            },
            {
                "name": "Kluang",
                "countryCode": "MY"
            },
            {
                "name": "Kota Tinggi",
                "countryCode": "MY"
            },
            {
                "name": "Kulai",
                "countryCode": "MY"
            },
            {
                "name": "Labis",
                "countryCode": "MY"
            },
            {
                "name": "Mersing",
                "countryCode": "MY"
            },
            {
                "name": "Muar",
                "countryCode": "MY"
            },
            {
                "name": "Parit Raja",
                "countryCode": "MY"
            },
            {
                "name": "Pekan Nenas",
                "countryCode": "MY"
            },
            {
                "name": "Pontian Kechil",
                "countryCode": "MY"
            },
            {
                "name": "Segamat",
                "countryCode": "MY"
            },
            {
                "name": "Skudai",
                "countryCode": "MY"
            },
            {
                "name": "Taman Senai",
                "countryCode": "MY"
            },
            {
                "name": "Tangkak",
                "countryCode": "MY"
            },
            {
                "name": "Ulu Tiram",
                "countryCode": "MY"
            },
            {
                "name": "Yong Peng",
                "countryCode": "MY"
            },
            {
                "name": "Alor Setar",
                "countryCode": "MY"
            },
            {
                "name": "Ayer Hangat",
                "countryCode": "MY"
            },
            {
                "name": "Bedong",
                "countryCode": "MY"
            },
            {
                "name": "Gurun",
                "countryCode": "MY"
            },
            {
                "name": "Jitra",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Kilim",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Kok",
                "countryCode": "MY"
            },
            {
                "name": "Kuah",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Kedah",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Teriang",
                "countryCode": "MY"
            },
            {
                "name": "Kulim",
                "countryCode": "MY"
            },
            {
                "name": "Langkawi",
                "countryCode": "MY"
            },
            {
                "name": "Padang Mat Sirat",
                "countryCode": "MY"
            },
            {
                "name": "Sungai Petani",
                "countryCode": "MY"
            },
            {
                "name": "Gua Musang",
                "countryCode": "MY"
            },
            {
                "name": "Kampong Kadok",
                "countryCode": "MY"
            },
            {
                "name": "Kampong Pangkal Kalong",
                "countryCode": "MY"
            },
            {
                "name": "Kota Bharu",
                "countryCode": "MY"
            },
            {
                "name": "Pasir Mas",
                "countryCode": "MY"
            },
            {
                "name": "Peringat",
                "countryCode": "MY"
            },
            {
                "name": "Tumpat",
                "countryCode": "MY"
            },
            {
                "name": "Alor Gajah",
                "countryCode": "MY"
            },
            {
                "name": "Batu Berendam",
                "countryCode": "MY"
            },
            {
                "name": "Bemban",
                "countryCode": "MY"
            },
            {
                "name": "Bukit Rambai",
                "countryCode": "MY"
            },
            {
                "name": "Kampong Masjid Tanah",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Ayer Keroh",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Ayer Molek",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Bukit Baharu",
                "countryCode": "MY"
            },
            {
                "name": "Klebang Besar",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Sungai Baru",
                "countryCode": "MY"
            },
            {
                "name": "Malacca",
                "countryCode": "MY"
            },
            {
                "name": "Pulau Sebang",
                "countryCode": "MY"
            },
            {
                "name": "Sungai Udang",
                "countryCode": "MY"
            },
            {
                "name": "Bahau",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Baharu Nilai",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Pilah",
                "countryCode": "MY"
            },
            {
                "name": "Port Dickson",
                "countryCode": "MY"
            },
            {
                "name": "Seremban",
                "countryCode": "MY"
            },
            {
                "name": "Tampin",
                "countryCode": "MY"
            },
            {
                "name": "Bentong Town",
                "countryCode": "MY"
            },
            {
                "name": "Jerantut",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Bukit Tinggi, Bentong",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Tekek",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Lipis",
                "countryCode": "MY"
            },
            {
                "name": "Kuantan",
                "countryCode": "MY"
            },
            {
                "name": "Mentekab",
                "countryCode": "MY"
            },
            {
                "name": "Pekan",
                "countryCode": "MY"
            },
            {
                "name": "Raub",
                "countryCode": "MY"
            },
            {
                "name": "Tanah Rata",
                "countryCode": "MY"
            },
            {
                "name": "Temerluh",
                "countryCode": "MY"
            },
            {
                "name": "Batu Feringgi",
                "countryCode": "MY"
            },
            {
                "name": "Bukit Mertajam",
                "countryCode": "MY"
            },
            {
                "name": "Butterworth",
                "countryCode": "MY"
            },
            {
                "name": "George Town",
                "countryCode": "MY"
            },
            {
                "name": "Juru",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Batu Feringgi",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Sungai Ara",
                "countryCode": "MY"
            },
            {
                "name": "Kepala Batas",
                "countryCode": "MY"
            },
            {
                "name": "Nibong Tebal",
                "countryCode": "MY"
            },
            {
                "name": "Perai",
                "countryCode": "MY"
            },
            {
                "name": "Permatang Kuching",
                "countryCode": "MY"
            },
            {
                "name": "Seberang Perai",
                "countryCode": "MY"
            },
            {
                "name": "Tanjung Tokong",
                "countryCode": "MY"
            },
            {
                "name": "Tasek Glugor",
                "countryCode": "MY"
            },
            {
                "name": "Telaga Batu",
                "countryCode": "MY"
            },
            {
                "name": "Bagan Serai",
                "countryCode": "MY"
            },
            {
                "name": "Batu Gajah",
                "countryCode": "MY"
            },
            {
                "name": "Bidur",
                "countryCode": "MY"
            },
            {
                "name": "Ipoh",
                "countryCode": "MY"
            },
            {
                "name": "Kampar",
                "countryCode": "MY"
            },
            {
                "name": "Kampong Dungun",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Kangsar",
                "countryCode": "MY"
            },
            {
                "name": "Lumut",
                "countryCode": "MY"
            },
            {
                "name": "Pantai Remis",
                "countryCode": "MY"
            },
            {
                "name": "Parit Buntar",
                "countryCode": "MY"
            },
            {
                "name": "Simpang Empat",
                "countryCode": "MY"
            },
            {
                "name": "Taiping",
                "countryCode": "MY"
            },
            {
                "name": "Tapah Road",
                "countryCode": "MY"
            },
            {
                "name": "Teluk Intan",
                "countryCode": "MY"
            },
            {
                "name": "Kangar",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Perlis",
                "countryCode": "MY"
            },
            {
                "name": "Pantai Cenang",
                "countryCode": "MY"
            },
            {
                "name": "Ampang",
                "countryCode": "MY"
            },
            {
                "name": "Bagan Pulau Ketam",
                "countryCode": "MY"
            },
            {
                "name": "Banting",
                "countryCode": "MY"
            },
            {
                "name": "Batang Berjuntai",
                "countryCode": "MY"
            },
            {
                "name": "Batu Arang",
                "countryCode": "MY"
            },
            {
                "name": "Jenjarum",
                "countryCode": "MY"
            },
            {
                "name": "Kampong Baharu Balakong",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Baru Subang",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Tanjung Karang",
                "countryCode": "MY"
            },
            {
                "name": "Klang",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Selangor",
                "countryCode": "MY"
            },
            {
                "name": "Kuang",
                "countryCode": "MY"
            },
            {
                "name": "Ladang Seri Kundang",
                "countryCode": "MY"
            },
            {
                "name": "Pelabuhan Klang",
                "countryCode": "MY"
            },
            {
                "name": "Petaling Jaya",
                "countryCode": "MY"
            },
            {
                "name": "Putra Heights",
                "countryCode": "MY"
            },
            {
                "name": "Rawang",
                "countryCode": "MY"
            },
            {
                "name": "Semenyih",
                "countryCode": "MY"
            },
            {
                "name": "Serendah",
                "countryCode": "MY"
            },
            {
                "name": "Shah Alam",
                "countryCode": "MY"
            },
            {
                "name": "Subang Jaya",
                "countryCode": "MY"
            },
            {
                "name": "Sungai Besar",
                "countryCode": "MY"
            },
            {
                "name": "Sungai Pelek New Village",
                "countryCode": "MY"
            },
            {
                "name": "Tanjung Sepat",
                "countryCode": "MY"
            },
            {
                "name": "Cukai",
                "countryCode": "MY"
            },
            {
                "name": "Jertih",
                "countryCode": "MY"
            },
            {
                "name": "Kampung Kuala Besut",
                "countryCode": "MY"
            },
            {
                "name": "Kertih",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Besut",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Terengganu",
                "countryCode": "MY"
            },
            {
                "name": "Marang",
                "countryCode": "MY"
            },
            {
                "name": "Paka",
                "countryCode": "MY"
            },
            {
                "name": "Bandar Labuan",
                "countryCode": "MY"
            },
            {
                "name": "Beaufort",
                "countryCode": "MY"
            },
            {
                "name": "Donggongon",
                "countryCode": "MY"
            },
            {
                "name": "Keningau",
                "countryCode": "MY"
            },
            {
                "name": "Kinarut",
                "countryCode": "MY"
            },
            {
                "name": "Kota Belud",
                "countryCode": "MY"
            },
            {
                "name": "Kota Kinabalu",
                "countryCode": "MY"
            },
            {
                "name": "Kudat",
                "countryCode": "MY"
            },
            {
                "name": "Lahad Datu",
                "countryCode": "MY"
            },
            {
                "name": "Papar",
                "countryCode": "MY"
            },
            {
                "name": "Putatan",
                "countryCode": "MY"
            },
            {
                "name": "Ranau",
                "countryCode": "MY"
            },
            {
                "name": "Sandakan",
                "countryCode": "MY"
            },
            {
                "name": "Semporna",
                "countryCode": "MY"
            },
            {
                "name": "Taman Rajawali",
                "countryCode": "MY"
            },
            {
                "name": "Tawau",
                "countryCode": "MY"
            },
            {
                "name": "Bintulu",
                "countryCode": "MY"
            },
            {
                "name": "Data Kakus",
                "countryCode": "MY"
            },
            {
                "name": "Kapit",
                "countryCode": "MY"
            },
            {
                "name": "Kuching",
                "countryCode": "MY"
            },
            {
                "name": "Lawas",
                "countryCode": "MY"
            },
            {
                "name": "Lidung Jelo",
                "countryCode": "MY"
            },
            {
                "name": "Limbang",
                "countryCode": "MY"
            },
            {
                "name": "Long Ampan Aing or Abanang",
                "countryCode": "MY"
            },
            {
                "name": "Marudi",
                "countryCode": "MY"
            },
            {
                "name": "Miri",
                "countryCode": "MY"
            },
            {
                "name": "Mukah",
                "countryCode": "MY"
            },
            {
                "name": "Sarikei",
                "countryCode": "MY"
            },
            {
                "name": "Sibu",
                "countryCode": "MY"
            },
            {
                "name": "Simanggang",
                "countryCode": "MY"
            },
            {
                "name": "Kuala Lumpur",
                "countryCode": "MY"
            },
            {
                "name": "Labuan",
                "countryCode": "MY"
            },
            {
                "name": "Putrajaya",
                "countryCode": "MY"
            }
        ]
    },
    {
        "name": "Maldives",
        "phonecode": "960",
        "cities": [
            {
                "name": "Hithadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Meedhoo",
                "countryCode": "MV"
            },
            {
                "name": "Naifaru",
                "countryCode": "MV"
            },
            {
                "name": "Felidhoo",
                "countryCode": "MV"
            },
            {
                "name": "Fonadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Dhidhdhoo",
                "countryCode": "MV"
            },
            {
                "name": "Veymandoo",
                "countryCode": "MV"
            },
            {
                "name": "Muli",
                "countryCode": "MV"
            },
            {
                "name": "Ugoofaaru",
                "countryCode": "MV"
            },
            {
                "name": "Kudahuvadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Kulhudhuffushi",
                "countryCode": "MV"
            },
            {
                "name": "Funadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Manadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Guraidhoo",
                "countryCode": "MV"
            },
            {
                "name": "Hulhumale",
                "countryCode": "MV"
            },
            {
                "name": "Maafushi",
                "countryCode": "MV"
            },
            {
                "name": "Male",
                "countryCode": "MV"
            },
            {
                "name": "Viligili",
                "countryCode": "MV"
            },
            {
                "name": "Thinadhoo",
                "countryCode": "MV"
            },
            {
                "name": "Fuvahmulah",
                "countryCode": "MV"
            },
            {
                "name": "Mahibadhoo",
                "countryCode": "MV"
            }
        ]
    },
    {
        "name": "Mali",
        "phonecode": "223",
        "cities": [
            {
                "name": "Bafoulabé",
                "countryCode": "ML"
            },
            {
                "name": "Kayes",
                "countryCode": "ML"
            },
            {
                "name": "Kita",
                "countryCode": "ML"
            },
            {
                "name": "Kokofata",
                "countryCode": "ML"
            },
            {
                "name": "Sagalo",
                "countryCode": "ML"
            },
            {
                "name": "Banamba",
                "countryCode": "ML"
            },
            {
                "name": "Kangaba",
                "countryCode": "ML"
            },
            {
                "name": "Kati",
                "countryCode": "ML"
            },
            {
                "name": "Kolokani",
                "countryCode": "ML"
            },
            {
                "name": "Koulikoro",
                "countryCode": "ML"
            },
            {
                "name": "Bougouni",
                "countryCode": "ML"
            },
            {
                "name": "Kolondiéba",
                "countryCode": "ML"
            },
            {
                "name": "Koutiala",
                "countryCode": "ML"
            },
            {
                "name": "Koutiala Cercle",
                "countryCode": "ML"
            },
            {
                "name": "Ntossoni",
                "countryCode": "ML"
            },
            {
                "name": "Sikasso",
                "countryCode": "ML"
            },
            {
                "name": "Yorosso",
                "countryCode": "ML"
            },
            {
                "name": "Baroueli",
                "countryCode": "ML"
            },
            {
                "name": "Cercle de San",
                "countryCode": "ML"
            },
            {
                "name": "Kinmparana",
                "countryCode": "ML"
            },
            {
                "name": "Ké-Macina",
                "countryCode": "ML"
            },
            {
                "name": "Markala",
                "countryCode": "ML"
            },
            {
                "name": "San",
                "countryCode": "ML"
            },
            {
                "name": "Sokolo",
                "countryCode": "ML"
            },
            {
                "name": "Ségou",
                "countryCode": "ML"
            },
            {
                "name": "Bandiagara",
                "countryCode": "ML"
            },
            {
                "name": "Djénné",
                "countryCode": "ML"
            },
            {
                "name": "Douentza",
                "countryCode": "ML"
            },
            {
                "name": "Douentza Cercle",
                "countryCode": "ML"
            },
            {
                "name": "Mopti",
                "countryCode": "ML"
            },
            {
                "name": "Ténenkou",
                "countryCode": "ML"
            },
            {
                "name": "Araouane",
                "countryCode": "ML"
            },
            {
                "name": "Cercle de Goundam",
                "countryCode": "ML"
            },
            {
                "name": "Dire",
                "countryCode": "ML"
            },
            {
                "name": "Goundam",
                "countryCode": "ML"
            },
            {
                "name": "Gourma-Rharous Cercle",
                "countryCode": "ML"
            },
            {
                "name": "Niafunké",
                "countryCode": "ML"
            },
            {
                "name": "Timbuktu",
                "countryCode": "ML"
            },
            {
                "name": "Ansongo",
                "countryCode": "ML"
            },
            {
                "name": "Cercle de Bourem",
                "countryCode": "ML"
            },
            {
                "name": "Gao",
                "countryCode": "ML"
            },
            {
                "name": "Gao Cercle",
                "countryCode": "ML"
            },
            {
                "name": "Inékar",
                "countryCode": "ML"
            },
            {
                "name": "Abeïbara",
                "countryCode": "ML"
            },
            {
                "name": "Cercle d’Abeïbara",
                "countryCode": "ML"
            },
            {
                "name": "Kidal",
                "countryCode": "ML"
            },
            {
                "name": "Bamako",
                "countryCode": "ML"
            }
        ]
    },
    {
        "name": "Malta",
        "phonecode": "356",
        "cities": [
            {
                "name": "Attard",
                "countryCode": "MT"
            },
            {
                "name": "Balzan",
                "countryCode": "MT"
            },
            {
                "name": "Vittoriosa",
                "countryCode": "MT"
            },
            {
                "name": "Birkirkara",
                "countryCode": "MT"
            },
            {
                "name": "Birżebbuġa",
                "countryCode": "MT"
            },
            {
                "name": "Cospicua",
                "countryCode": "MT"
            },
            {
                "name": "Dingli",
                "countryCode": "MT"
            },
            {
                "name": "Fgura",
                "countryCode": "MT"
            },
            {
                "name": "Floriana",
                "countryCode": "MT"
            },
            {
                "name": "Fontana",
                "countryCode": "MT"
            },
            {
                "name": "Għarb",
                "countryCode": "MT"
            },
            {
                "name": "Għajnsielem",
                "countryCode": "MT"
            },
            {
                "name": "Hal Gharghur",
                "countryCode": "MT"
            },
            {
                "name": "Għaxaq",
                "countryCode": "MT"
            },
            {
                "name": "Gudja",
                "countryCode": "MT"
            },
            {
                "name": "Gżira",
                "countryCode": "MT"
            },
            {
                "name": "Ħamrun",
                "countryCode": "MT"
            },
            {
                "name": "L-Iklin",
                "countryCode": "MT"
            },
            {
                "name": "Imdina",
                "countryCode": "MT"
            },
            {
                "name": "Imġarr",
                "countryCode": "MT"
            },
            {
                "name": "Mġarr",
                "countryCode": "MT"
            },
            {
                "name": "Mqabba",
                "countryCode": "MT"
            },
            {
                "name": "Imsida",
                "countryCode": "MT"
            },
            {
                "name": "Kirkop",
                "countryCode": "MT"
            },
            {
                "name": "Imtarfa",
                "countryCode": "MT"
            },
            {
                "name": "Lija",
                "countryCode": "MT"
            },
            {
                "name": "Luqa",
                "countryCode": "MT"
            },
            {
                "name": "Senglea",
                "countryCode": "MT"
            },
            {
                "name": "Kalkara",
                "countryCode": "MT"
            },
            {
                "name": "Kerċem",
                "countryCode": "MT"
            },
            {
                "name": "Marsaskala",
                "countryCode": "MT"
            },
            {
                "name": "Marsaxlokk",
                "countryCode": "MT"
            },
            {
                "name": "Marsa",
                "countryCode": "MT"
            },
            {
                "name": "Mellieħa",
                "countryCode": "MT"
            },
            {
                "name": "Munxar",
                "countryCode": "MT"
            },
            {
                "name": "Nadur",
                "countryCode": "MT"
            },
            {
                "name": "Naxxar",
                "countryCode": "MT"
            },
            {
                "name": "Paola",
                "countryCode": "MT"
            },
            {
                "name": "Pembroke",
                "countryCode": "MT"
            },
            {
                "name": "Pietà",
                "countryCode": "MT"
            },
            {
                "name": "Qala",
                "countryCode": "MT"
            },
            {
                "name": "Qormi",
                "countryCode": "MT"
            },
            {
                "name": "Qrendi",
                "countryCode": "MT"
            },
            {
                "name": "Rabat",
                "countryCode": "MT"
            },
            {
                "name": "Victoria",
                "countryCode": "MT"
            },
            {
                "name": "Saint John",
                "countryCode": "MT"
            },
            {
                "name": "San Ġiljan",
                "countryCode": "MT"
            },
            {
                "name": "San Lawrenz",
                "countryCode": "MT"
            },
            {
                "name": "Mosta",
                "countryCode": "MT"
            },
            {
                "name": "Saint Lucia",
                "countryCode": "MT"
            },
            {
                "name": "Santa Luċija",
                "countryCode": "MT"
            },
            {
                "name": "San Pawl il-Baħar",
                "countryCode": "MT"
            },
            {
                "name": "Sannat",
                "countryCode": "MT"
            },
            {
                "name": "Santa Venera",
                "countryCode": "MT"
            },
            {
                "name": "Siġġiewi",
                "countryCode": "MT"
            },
            {
                "name": "Sliema",
                "countryCode": "MT"
            },
            {
                "name": "Swieqi",
                "countryCode": "MT"
            },
            {
                "name": "Ta’ Xbiex",
                "countryCode": "MT"
            },
            {
                "name": "Tarxien",
                "countryCode": "MT"
            },
            {
                "name": "Valletta",
                "countryCode": "MT"
            },
            {
                "name": "Xagħra",
                "countryCode": "MT"
            },
            {
                "name": "Xewkija",
                "countryCode": "MT"
            },
            {
                "name": "Xgħajra",
                "countryCode": "MT"
            },
            {
                "name": "Żabbar",
                "countryCode": "MT"
            },
            {
                "name": "Haz-Zebbug",
                "countryCode": "MT"
            },
            {
                "name": "Żebbuġ",
                "countryCode": "MT"
            },
            {
                "name": "Żejtun",
                "countryCode": "MT"
            },
            {
                "name": "Żurrieq",
                "countryCode": "MT"
            }
        ]
    },
    {
        "name": "Man (Isle of)",
        "phonecode": "+44-1624",
        "cities": []
    },
    {
        "name": "Marshall Islands",
        "phonecode": "692",
        "cities": []
    },
    {
        "name": "Martinique",
        "phonecode": "596",
        "cities": []
    },
    {
        "name": "Mauritania",
        "phonecode": "222",
        "cities": [
            {
                "name": "Néma",
                "countryCode": "MR"
            },
            {
                "name": "Aioun",
                "countryCode": "MR"
            },
            {
                "name": "Barkéwol",
                "countryCode": "MR"
            },
            {
                "name": "Kiffa",
                "countryCode": "MR"
            },
            {
                "name": "Kaédi",
                "countryCode": "MR"
            },
            {
                "name": "Aleg",
                "countryCode": "MR"
            },
            {
                "name": "’Elb el Jmel",
                "countryCode": "MR"
            },
            {
                "name": "Rosso",
                "countryCode": "MR"
            },
            {
                "name": "Tékane",
                "countryCode": "MR"
            },
            {
                "name": "Atar",
                "countryCode": "MR"
            },
            {
                "name": "Chingueṭṭi",
                "countryCode": "MR"
            },
            {
                "name": "Nouadhibou",
                "countryCode": "MR"
            },
            {
                "name": "Sélibaby",
                "countryCode": "MR"
            },
            {
                "name": "Zouerate",
                "countryCode": "MR"
            },
            {
                "name": "Akjoujt",
                "countryCode": "MR"
            }
        ]
    },
    {
        "name": "Mauritius",
        "phonecode": "230",
        "cities": [
            {
                "name": "Vingt Cinq",
                "countryCode": "MU"
            },
            {
                "name": "Albion",
                "countryCode": "MU"
            },
            {
                "name": "Bambous",
                "countryCode": "MU"
            },
            {
                "name": "Cascavelle",
                "countryCode": "MU"
            },
            {
                "name": "Flic en Flac",
                "countryCode": "MU"
            },
            {
                "name": "Grande Rivière Noire",
                "countryCode": "MU"
            },
            {
                "name": "Gros Cailloux",
                "countryCode": "MU"
            },
            {
                "name": "Petite Case Noyale",
                "countryCode": "MU"
            },
            {
                "name": "Petite Rivière",
                "countryCode": "MU"
            },
            {
                "name": "Tamarin",
                "countryCode": "MU"
            },
            {
                "name": "Cargados Carajos",
                "countryCode": "MU"
            },
            {
                "name": "Bel Air Rivière Sèche",
                "countryCode": "MU"
            },
            {
                "name": "Bon Accueil",
                "countryCode": "MU"
            },
            {
                "name": "Brisée Verdière",
                "countryCode": "MU"
            },
            {
                "name": "Camp Ithier",
                "countryCode": "MU"
            },
            {
                "name": "Camp de Masque",
                "countryCode": "MU"
            },
            {
                "name": "Centre de Flacq",
                "countryCode": "MU"
            },
            {
                "name": "Clémencia",
                "countryCode": "MU"
            },
            {
                "name": "Ecroignard",
                "countryCode": "MU"
            },
            {
                "name": "Grande Rivière Sud Est",
                "countryCode": "MU"
            },
            {
                "name": "Lalmatie",
                "countryCode": "MU"
            },
            {
                "name": "Laventure",
                "countryCode": "MU"
            },
            {
                "name": "Mare La Chaux",
                "countryCode": "MU"
            },
            {
                "name": "Olivia",
                "countryCode": "MU"
            },
            {
                "name": "Poste de Flacq",
                "countryCode": "MU"
            },
            {
                "name": "Quatre Cocos",
                "countryCode": "MU"
            },
            {
                "name": "Quatre Soeurs",
                "countryCode": "MU"
            },
            {
                "name": "Queen Victoria",
                "countryCode": "MU"
            },
            {
                "name": "Saint Julien",
                "countryCode": "MU"
            },
            {
                "name": "Sebastopol",
                "countryCode": "MU"
            },
            {
                "name": "Bambous Virieux",
                "countryCode": "MU"
            },
            {
                "name": "Beau Vallon",
                "countryCode": "MU"
            },
            {
                "name": "Bois des Amourettes",
                "countryCode": "MU"
            },
            {
                "name": "Cluny",
                "countryCode": "MU"
            },
            {
                "name": "Grand Sable",
                "countryCode": "MU"
            },
            {
                "name": "Mahébourg",
                "countryCode": "MU"
            },
            {
                "name": "New Grove",
                "countryCode": "MU"
            },
            {
                "name": "Nouvelle France",
                "countryCode": "MU"
            },
            {
                "name": "Plaine Magnien",
                "countryCode": "MU"
            },
            {
                "name": "Rose Belle",
                "countryCode": "MU"
            },
            {
                "name": "Saint Hubert",
                "countryCode": "MU"
            },
            {
                "name": "Camp Thorel",
                "countryCode": "MU"
            },
            {
                "name": "Dagotière",
                "countryCode": "MU"
            },
            {
                "name": "Dubreuil",
                "countryCode": "MU"
            },
            {
                "name": "Melrose",
                "countryCode": "MU"
            },
            {
                "name": "Moka",
                "countryCode": "MU"
            },
            {
                "name": "Pailles",
                "countryCode": "MU"
            },
            {
                "name": "Providence",
                "countryCode": "MU"
            },
            {
                "name": "Quartier Militaire",
                "countryCode": "MU"
            },
            {
                "name": "Saint Pierre",
                "countryCode": "MU"
            },
            {
                "name": "Verdun",
                "countryCode": "MU"
            },
            {
                "name": "Arsenal",
                "countryCode": "MU"
            },
            {
                "name": "Calebasses",
                "countryCode": "MU"
            },
            {
                "name": "Congomah",
                "countryCode": "MU"
            },
            {
                "name": "Crève Coeur",
                "countryCode": "MU"
            },
            {
                "name": "Fond du Sac",
                "countryCode": "MU"
            },
            {
                "name": "Le Hochet",
                "countryCode": "MU"
            },
            {
                "name": "Long Mountain",
                "countryCode": "MU"
            },
            {
                "name": "Morcellement Saint André",
                "countryCode": "MU"
            },
            {
                "name": "Notre Dame",
                "countryCode": "MU"
            },
            {
                "name": "Pamplemousses",
                "countryCode": "MU"
            },
            {
                "name": "Plaine des Papayes",
                "countryCode": "MU"
            },
            {
                "name": "Pointe aux Piments",
                "countryCode": "MU"
            },
            {
                "name": "Terre Rouge",
                "countryCode": "MU"
            },
            {
                "name": "Triolet",
                "countryCode": "MU"
            },
            {
                "name": "Port Louis",
                "countryCode": "MU"
            },
            {
                "name": "Beau Bassin-Rose Hill",
                "countryCode": "MU"
            },
            {
                "name": "Curepipe",
                "countryCode": "MU"
            },
            {
                "name": "Ebene",
                "countryCode": "MU"
            },
            {
                "name": "Midlands",
                "countryCode": "MU"
            },
            {
                "name": "Quatre Bornes",
                "countryCode": "MU"
            },
            {
                "name": "Vacoas",
                "countryCode": "MU"
            },
            {
                "name": "Baie aux Huîtres",
                "countryCode": "MU"
            },
            {
                "name": "Port Mathurin",
                "countryCode": "MU"
            },
            {
                "name": "Amaury",
                "countryCode": "MU"
            },
            {
                "name": "Cap Malheureux",
                "countryCode": "MU"
            },
            {
                "name": "Cottage",
                "countryCode": "MU"
            },
            {
                "name": "Espérance Trébuchet",
                "countryCode": "MU"
            },
            {
                "name": "Goodlands",
                "countryCode": "MU"
            },
            {
                "name": "Grand Baie",
                "countryCode": "MU"
            },
            {
                "name": "Grand Gaube",
                "countryCode": "MU"
            },
            {
                "name": "Mapou",
                "countryCode": "MU"
            },
            {
                "name": "Petit Raffray",
                "countryCode": "MU"
            },
            {
                "name": "Piton",
                "countryCode": "MU"
            },
            {
                "name": "Plaines des Roches",
                "countryCode": "MU"
            },
            {
                "name": "Rivière du Rempart",
                "countryCode": "MU"
            },
            {
                "name": "Roche Terre",
                "countryCode": "MU"
            },
            {
                "name": "Roches Noire",
                "countryCode": "MU"
            },
            {
                "name": "The Vale",
                "countryCode": "MU"
            },
            {
                "name": "Camp Diable",
                "countryCode": "MU"
            },
            {
                "name": "Chamouny",
                "countryCode": "MU"
            },
            {
                "name": "Chemin Grenier",
                "countryCode": "MU"
            },
            {
                "name": "Grand Bois",
                "countryCode": "MU"
            },
            {
                "name": "Rivière des Anguilles",
                "countryCode": "MU"
            },
            {
                "name": "Saint Aubin",
                "countryCode": "MU"
            },
            {
                "name": "Souillac",
                "countryCode": "MU"
            },
            {
                "name": "Surinam",
                "countryCode": "MU"
            }
        ]
    },
    {
        "name": "Mayotte",
        "phonecode": "262",
        "cities": []
    },
    {
        "name": "Mexico",
        "phonecode": "52",
        "cities": [
            {
                "name": "Aguascalientes",
                "countryCode": "MX"
            },
            {
                "name": "Arboledas Paso Blanco [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Arellano",
                "countryCode": "MX"
            },
            {
                "name": "Asientos",
                "countryCode": "MX"
            },
            {
                "name": "Bimbaletes Aguascalientes (El Álamo)",
                "countryCode": "MX"
            },
            {
                "name": "Calvillo",
                "countryCode": "MX"
            },
            {
                "name": "Carboneras",
                "countryCode": "MX"
            },
            {
                "name": "Cartagena [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Centro de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega Grande",
                "countryCode": "MX"
            },
            {
                "name": "Corral de Barrancos",
                "countryCode": "MX"
            },
            {
                "name": "Cosío",
                "countryCode": "MX"
            },
            {
                "name": "Cotorina",
                "countryCode": "MX"
            },
            {
                "name": "Crucero Las Pilas",
                "countryCode": "MX"
            },
            {
                "name": "Cumbres III",
                "countryCode": "MX"
            },
            {
                "name": "Ejido la Guayana (Rancho Seco)",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío",
                "countryCode": "MX"
            },
            {
                "name": "El Chayote",
                "countryCode": "MX"
            },
            {
                "name": "El Cuervero (Cuerveros)",
                "countryCode": "MX"
            },
            {
                "name": "El Llano",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio de Peñuelas",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio de Providencia (Providencia)",
                "countryCode": "MX"
            },
            {
                "name": "El Salero",
                "countryCode": "MX"
            },
            {
                "name": "El Tule",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Escaleras",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Viñedos Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "General Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe de Atlas",
                "countryCode": "MX"
            },
            {
                "name": "Jaltiche de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Jaltomate",
                "countryCode": "MX"
            },
            {
                "name": "Jarillas",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Gómez Portugal",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Gómez Portugal (Margaritas)",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Escondida",
                "countryCode": "MX"
            },
            {
                "name": "La Labor",
                "countryCode": "MX"
            },
            {
                "name": "La Loma de los Negritos",
                "countryCode": "MX"
            },
            {
                "name": "La Panadera",
                "countryCode": "MX"
            },
            {
                "name": "La Punta",
                "countryCode": "MX"
            },
            {
                "name": "Las Norias de Ojocaliente",
                "countryCode": "MX"
            },
            {
                "name": "Las Ánimas",
                "countryCode": "MX"
            },
            {
                "name": "Los Arquitos",
                "countryCode": "MX"
            },
            {
                "name": "Los Caños",
                "countryCode": "MX"
            },
            {
                "name": "Los Conos",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Macario J. Gómez [Colonia]",
                "countryCode": "MX"
            },
            {
                "name": "Malpaso",
                "countryCode": "MX"
            },
            {
                "name": "Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Mesa Grande",
                "countryCode": "MX"
            },
            {
                "name": "Miravalle",
                "countryCode": "MX"
            },
            {
                "name": "Molinos",
                "countryCode": "MX"
            },
            {
                "name": "Montoro",
                "countryCode": "MX"
            },
            {
                "name": "Noria del Borrego (Norias)",
                "countryCode": "MX"
            },
            {
                "name": "Norias del Paso Hondo",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Crucitas",
                "countryCode": "MX"
            },
            {
                "name": "Ojocaliente",
                "countryCode": "MX"
            },
            {
                "name": "Pabellón de Arteaga",
                "countryCode": "MX"
            },
            {
                "name": "Pabellón de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Palo Alto",
                "countryCode": "MX"
            },
            {
                "name": "Paredes",
                "countryCode": "MX"
            },
            {
                "name": "Paseos de la Providencia [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Paseos de las Haciendas [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Paso Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Peñuelas (El Cienegal)",
                "countryCode": "MX"
            },
            {
                "name": "Pilotos",
                "countryCode": "MX"
            },
            {
                "name": "Pocitos",
                "countryCode": "MX"
            },
            {
                "name": "Puertecito de la Virgen",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Romos",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de Peñuelas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Horcones",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Ríos",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de los Romo",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San Jacinto",
                "countryCode": "MX"
            },
            {
                "name": "San José de Gracia",
                "countryCode": "MX"
            },
            {
                "name": "San Luis de Letras",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "San Tadeo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa (El Huizache)",
                "countryCode": "MX"
            },
            {
                "name": "Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Tepetates",
                "countryCode": "MX"
            },
            {
                "name": "Tepezalá",
                "countryCode": "MX"
            },
            {
                "name": "Valladolid",
                "countryCode": "MX"
            },
            {
                "name": "Valle Huejúcar (Fraccionamiento Popular) [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Licenciado Jesús Terán (Calvillito)",
                "countryCode": "MX"
            },
            {
                "name": "Alfonso Garzón [Granjas Familiares]",
                "countryCode": "MX"
            },
            {
                "name": "Benito García (El Zorrillo)",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Camalú",
                "countryCode": "MX"
            },
            {
                "name": "Cereso del Hongo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Coahuila (Kilómetro Cincuenta y Siete)",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Colinas del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Gómez Morín",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lomas de San Ramón (Triquis)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Nueva Era",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Delta",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Alberto Oviedo Mota",
                "countryCode": "MX"
            },
            {
                "name": "Durango",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Doctor Alberto Oviedo Mota (El Indiviso)",
                "countryCode": "MX"
            },
            {
                "name": "Ejido General Leandro Valle",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Javier Rojo Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Jiquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Michoacán de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Netzahualcóyotl",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Nuevo León",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Plan de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Pátzcuaro",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Quintana Roo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Saltillo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Sinaloa",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Sonora",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Tabasco",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Toluca",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Yucatán",
                "countryCode": "MX"
            },
            {
                "name": "El Niño",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "El Sauzal de Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Ensenada",
                "countryCode": "MX"
            },
            {
                "name": "Estación Coahuila",
                "countryCode": "MX"
            },
            {
                "name": "Estación Pescaderos",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Zarco",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guerrero Negro",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Tecate",
                "countryCode": "MX"
            },
            {
                "name": "Hermosillo",
                "countryCode": "MX"
            },
            {
                "name": "Islas Agrarias Grupo A",
                "countryCode": "MX"
            },
            {
                "name": "Islas Agrarias Grupo B",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza [Granjas Familiares]",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia",
                "countryCode": "MX"
            },
            {
                "name": "Las Brisas",
                "countryCode": "MX"
            },
            {
                "name": "Las Delicias",
                "countryCode": "MX"
            },
            {
                "name": "Licenciado Gustavo Díaz Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Santa Anita",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Tlatelolco",
                "countryCode": "MX"
            },
            {
                "name": "Los Algodones",
                "countryCode": "MX"
            },
            {
                "name": "Los Valles",
                "countryCode": "MX"
            },
            {
                "name": "Luis Rodríguez (El Vergel)",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Maclovio Herrera (Colonia Aviación)",
                "countryCode": "MX"
            },
            {
                "name": "Maclovio Rojas",
                "countryCode": "MX"
            },
            {
                "name": "Mexicali",
                "countryCode": "MX"
            },
            {
                "name": "Playas de Rosarito",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Chulavista (El Chorizo)",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Lázaro Cárdenas (La Veintiocho)",
                "countryCode": "MX"
            },
            {
                "name": "Primo Tapia",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Puebla",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Benito García",
                "countryCode": "MX"
            },
            {
                "name": "Pórticos de San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "Quinta del Cedro",
                "countryCode": "MX"
            },
            {
                "name": "Rancho La Gloria",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Verde",
                "countryCode": "MX"
            },
            {
                "name": "Rodolfo Sánchez Taboada",
                "countryCode": "MX"
            },
            {
                "name": "Rosarito",
                "countryCode": "MX"
            },
            {
                "name": "Rumorosa",
                "countryCode": "MX"
            },
            {
                "name": "Salvador Rosas Magallón",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe",
                "countryCode": "MX"
            },
            {
                "name": "San Luis",
                "countryCode": "MX"
            },
            {
                "name": "San Quintín",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "Santa Anita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fé",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "MX"
            },
            {
                "name": "Tecate",
                "countryCode": "MX"
            },
            {
                "name": "Tecolots",
                "countryCode": "MX"
            },
            {
                "name": "Terrazas del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Tijuana",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa del Campo",
                "countryCode": "MX"
            },
            {
                "name": "Villa del Prado 2da Sección",
                "countryCode": "MX"
            },
            {
                "name": "Viñas del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Bahía Asunción",
                "countryCode": "MX"
            },
            {
                "name": "Bahía Tortugas",
                "countryCode": "MX"
            },
            {
                "name": "Cabo San Lucas",
                "countryCode": "MX"
            },
            {
                "name": "Chametla",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Constitución",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Insurgentes",
                "countryCode": "MX"
            },
            {
                "name": "Colonia del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Comondú",
                "countryCode": "MX"
            },
            {
                "name": "El Centenario",
                "countryCode": "MX"
            },
            {
                "name": "El Pescadero",
                "countryCode": "MX"
            },
            {
                "name": "El Silencio",
                "countryCode": "MX"
            },
            {
                "name": "La Paz",
                "countryCode": "MX"
            },
            {
                "name": "La Playa",
                "countryCode": "MX"
            },
            {
                "name": "La Rivera",
                "countryCode": "MX"
            },
            {
                "name": "Las Margaritas",
                "countryCode": "MX"
            },
            {
                "name": "Las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "Las Veredas",
                "countryCode": "MX"
            },
            {
                "name": "Loreto",
                "countryCode": "MX"
            },
            {
                "name": "Los Barriles",
                "countryCode": "MX"
            },
            {
                "name": "Los Cabos",
                "countryCode": "MX"
            },
            {
                "name": "Melitón Albáñez Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "Miraflores",
                "countryCode": "MX"
            },
            {
                "name": "Mulegé",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Adolfo Lopez Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Puerto San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San José del Cabo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosalía",
                "countryCode": "MX"
            },
            {
                "name": "Todos Santos",
                "countryCode": "MX"
            },
            {
                "name": "Villa Alberto Andrés Alvarado Arámburo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Abelardo L. Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil",
                "countryCode": "MX"
            },
            {
                "name": "Altamira de Zináparo",
                "countryCode": "MX"
            },
            {
                "name": "Atasta",
                "countryCode": "MX"
            },
            {
                "name": "Bacabchén",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez Uno",
                "countryCode": "MX"
            },
            {
                "name": "Bolonchén de Rejón",
                "countryCode": "MX"
            },
            {
                "name": "Bécal",
                "countryCode": "MX"
            },
            {
                "name": "Calakmul",
                "countryCode": "MX"
            },
            {
                "name": "Calkiní",
                "countryCode": "MX"
            },
            {
                "name": "Campeche",
                "countryCode": "MX"
            },
            {
                "name": "Candelaria",
                "countryCode": "MX"
            },
            {
                "name": "Carmen",
                "countryCode": "MX"
            },
            {
                "name": "Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Castamay",
                "countryCode": "MX"
            },
            {
                "name": "Champotón",
                "countryCode": "MX"
            },
            {
                "name": "Chicbul",
                "countryCode": "MX"
            },
            {
                "name": "Chiná",
                "countryCode": "MX"
            },
            {
                "name": "Chunchintok",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad del Carmen",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Constitución",
                "countryCode": "MX"
            },
            {
                "name": "Cumpich",
                "countryCode": "MX"
            },
            {
                "name": "División del Norte",
                "countryCode": "MX"
            },
            {
                "name": "Don Samuel",
                "countryCode": "MX"
            },
            {
                "name": "Dzibalchén",
                "countryCode": "MX"
            },
            {
                "name": "Dzitbalché",
                "countryCode": "MX"
            },
            {
                "name": "El Aguacatal",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "Escárcega",
                "countryCode": "MX"
            },
            {
                "name": "Hampolol",
                "countryCode": "MX"
            },
            {
                "name": "Haro",
                "countryCode": "MX"
            },
            {
                "name": "Hecelchakán",
                "countryCode": "MX"
            },
            {
                "name": "Hool",
                "countryCode": "MX"
            },
            {
                "name": "Hopelchén",
                "countryCode": "MX"
            },
            {
                "name": "Isla Aguada",
                "countryCode": "MX"
            },
            {
                "name": "Iturbide",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Lerma",
                "countryCode": "MX"
            },
            {
                "name": "Licenciado Gustavo Díaz Ordaz (18 de Marzo)",
                "countryCode": "MX"
            },
            {
                "name": "Los Laureles",
                "countryCode": "MX"
            },
            {
                "name": "Mamantel",
                "countryCode": "MX"
            },
            {
                "name": "Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Maya Tecún I",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Nunkiní",
                "countryCode": "MX"
            },
            {
                "name": "Palizada",
                "countryCode": "MX"
            },
            {
                "name": "Pich",
                "countryCode": "MX"
            },
            {
                "name": "Pocboc",
                "countryCode": "MX"
            },
            {
                "name": "Pomuch",
                "countryCode": "MX"
            },
            {
                "name": "Sabancuy",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Sahcabchén",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Kobén",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Pixtún",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Ex-Hacienda",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Pueblo",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Kesté",
                "countryCode": "MX"
            },
            {
                "name": "Seybaplaya",
                "countryCode": "MX"
            },
            {
                "name": "Sihochac",
                "countryCode": "MX"
            },
            {
                "name": "Suc-Tuc",
                "countryCode": "MX"
            },
            {
                "name": "Tankuché",
                "countryCode": "MX"
            },
            {
                "name": "Tenabo",
                "countryCode": "MX"
            },
            {
                "name": "Tepakán",
                "countryCode": "MX"
            },
            {
                "name": "Tikinmul",
                "countryCode": "MX"
            },
            {
                "name": "Ukúm",
                "countryCode": "MX"
            },
            {
                "name": "Villa Madero",
                "countryCode": "MX"
            },
            {
                "name": "Xbacab",
                "countryCode": "MX"
            },
            {
                "name": "Xmabén",
                "countryCode": "MX"
            },
            {
                "name": "Xpujil",
                "countryCode": "MX"
            },
            {
                "name": "Zoh-Laguna",
                "countryCode": "MX"
            },
            {
                "name": "Azcapotzalco",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juarez",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de México",
                "countryCode": "MX"
            },
            {
                "name": "Col. Bosques de las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Nativitas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Coyoacán",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Delegación Cuajimalpa de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Gustavo A. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Gustavo Adolfo Madero",
                "countryCode": "MX"
            },
            {
                "name": "Iztacalco",
                "countryCode": "MX"
            },
            {
                "name": "Iztapalapa",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Tepemecatl",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Contreras",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Milpa Alta",
                "countryCode": "MX"
            },
            {
                "name": "Polanco",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Mixquic",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tecómitl",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolome Xicomulco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tecoxpa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ixtayopan",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Acopilco",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Tlacoyucan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ajusco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Topilejo",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Oztotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Atocpan",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador Cuauhtenco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Tlacotenco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Yecahuizotl",
                "countryCode": "MX"
            },
            {
                "name": "Tetelco",
                "countryCode": "MX"
            },
            {
                "name": "Tlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Tláhuac",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Xochimilco",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Abdenago C. García",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo López Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Ahumada",
                "countryCode": "MX"
            },
            {
                "name": "Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ampliación Colonia Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Anáhuac",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Ascensión",
                "countryCode": "MX"
            },
            {
                "name": "Baborigame",
                "countryCode": "MX"
            },
            {
                "name": "Bachíniva",
                "countryCode": "MX"
            },
            {
                "name": "Bahuichivo",
                "countryCode": "MX"
            },
            {
                "name": "Balleza",
                "countryCode": "MX"
            },
            {
                "name": "Basaseachic",
                "countryCode": "MX"
            },
            {
                "name": "Basúchil",
                "countryCode": "MX"
            },
            {
                "name": "Batopilas",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Bocoyna",
                "countryCode": "MX"
            },
            {
                "name": "Boquilla de Babisas (La Boquilla de Conchos)",
                "countryCode": "MX"
            },
            {
                "name": "Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "Camargo",
                "countryCode": "MX"
            },
            {
                "name": "Carichí",
                "countryCode": "MX"
            },
            {
                "name": "Carretas",
                "countryCode": "MX"
            },
            {
                "name": "Casas Grandes",
                "countryCode": "MX"
            },
            {
                "name": "Cerocahui",
                "countryCode": "MX"
            },
            {
                "name": "Chihuahua",
                "countryCode": "MX"
            },
            {
                "name": "Chínipas",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Delicias",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Abraham González (La Quemada)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Campesina",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Felipe Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Nicolás Bravo (Kilómetro Noventa y Dos)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Revolución",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Terrazas",
                "countryCode": "MX"
            },
            {
                "name": "Conchos",
                "countryCode": "MX"
            },
            {
                "name": "Congregación Ortíz",
                "countryCode": "MX"
            },
            {
                "name": "Coyame",
                "countryCode": "MX"
            },
            {
                "name": "Coyame del Sotol",
                "countryCode": "MX"
            },
            {
                "name": "Creel",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cusihuiriachi",
                "countryCode": "MX"
            },
            {
                "name": "Delicias",
                "countryCode": "MX"
            },
            {
                "name": "Dr. Belisario Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Ejido El Largo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido El Vergel",
                "countryCode": "MX"
            },
            {
                "name": "Ejido La Quemada",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Oro",
                "countryCode": "MX"
            },
            {
                "name": "El Sauz",
                "countryCode": "MX"
            },
            {
                "name": "El Terrero",
                "countryCode": "MX"
            },
            {
                "name": "El Tule",
                "countryCode": "MX"
            },
            {
                "name": "Estación Conchos",
                "countryCode": "MX"
            },
            {
                "name": "Estación Consuelo",
                "countryCode": "MX"
            },
            {
                "name": "Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Gran Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Guachochi",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe y Calvo",
                "countryCode": "MX"
            },
            {
                "name": "Guazapares",
                "countryCode": "MX"
            },
            {
                "name": "Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Gómez Farías",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo del Parral",
                "countryCode": "MX"
            },
            {
                "name": "Huejotitán",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Janos",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "José Esteban Coronado",
                "countryCode": "MX"
            },
            {
                "name": "José Mariano Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Julimes",
                "countryCode": "MX"
            },
            {
                "name": "Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Kilómetro Noventa y Nueve",
                "countryCode": "MX"
            },
            {
                "name": "La Constitución",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Junta",
                "countryCode": "MX"
            },
            {
                "name": "Las Cruces",
                "countryCode": "MX"
            },
            {
                "name": "Las Varas",
                "countryCode": "MX"
            },
            {
                "name": "Las Varas (Estación Babícora)",
                "countryCode": "MX"
            },
            {
                "name": "Las Yerbitas [Aserradero]",
                "countryCode": "MX"
            },
            {
                "name": "Loma Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Madera",
                "countryCode": "MX"
            },
            {
                "name": "Maguarichi",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Benavides",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Ojinaga",
                "countryCode": "MX"
            },
            {
                "name": "Mariano Balleza",
                "countryCode": "MX"
            },
            {
                "name": "Matachí",
                "countryCode": "MX"
            },
            {
                "name": "Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Meoqui",
                "countryCode": "MX"
            },
            {
                "name": "Mesa del Huracán",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Ahumada",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Monte Verde",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Moris",
                "countryCode": "MX"
            },
            {
                "name": "Naica",
                "countryCode": "MX"
            },
            {
                "name": "Namiquipa",
                "countryCode": "MX"
            },
            {
                "name": "Nicolas Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Ninguno [CERESO]",
                "countryCode": "MX"
            },
            {
                "name": "Nonoava",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Casas Grandes",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Octaviano López",
                "countryCode": "MX"
            },
            {
                "name": "Ojinaga",
                "countryCode": "MX"
            },
            {
                "name": "Orranteño",
                "countryCode": "MX"
            },
            {
                "name": "Oscar Soto Maynez",
                "countryCode": "MX"
            },
            {
                "name": "Parral",
                "countryCode": "MX"
            },
            {
                "name": "Peña Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "Praxedis G. Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Praxédis Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Pueblito de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Palomas",
                "countryCode": "MX"
            },
            {
                "name": "Riva Palacio",
                "countryCode": "MX"
            },
            {
                "name": "Rodrígo M. Quevedo",
                "countryCode": "MX"
            },
            {
                "name": "Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Samachique",
                "countryCode": "MX"
            },
            {
                "name": "Samalayuca",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Borja",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Conchos",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco del Oro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Juanito",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Eulalia",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "MX"
            },
            {
                "name": "Saucillo",
                "countryCode": "MX"
            },
            {
                "name": "Sisoguichi",
                "countryCode": "MX"
            },
            {
                "name": "Temósachic",
                "countryCode": "MX"
            },
            {
                "name": "Tomochic",
                "countryCode": "MX"
            },
            {
                "name": "Turuachi",
                "countryCode": "MX"
            },
            {
                "name": "Témoris",
                "countryCode": "MX"
            },
            {
                "name": "Urique",
                "countryCode": "MX"
            },
            {
                "name": "Uruachi",
                "countryCode": "MX"
            },
            {
                "name": "Valentín Gómez Farías",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Valle del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Yécora",
                "countryCode": "MX"
            },
            {
                "name": "1ra. Sección de Izapa",
                "countryCode": "MX"
            },
            {
                "name": "2da. Sección de Medio Monte",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "Acacoyagua",
                "countryCode": "MX"
            },
            {
                "name": "Acala",
                "countryCode": "MX"
            },
            {
                "name": "Acapetahua",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo López Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Agrónomos Mexicanos",
                "countryCode": "MX"
            },
            {
                "name": "Agua Azul",
                "countryCode": "MX"
            },
            {
                "name": "Agua Blanca Serranía",
                "countryCode": "MX"
            },
            {
                "name": "Agua Zarca",
                "countryCode": "MX"
            },
            {
                "name": "Aguacatenango",
                "countryCode": "MX"
            },
            {
                "name": "Agustín de Iturbide",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ajilhó",
                "countryCode": "MX"
            },
            {
                "name": "Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Alfonso Moguel",
                "countryCode": "MX"
            },
            {
                "name": "Altamirano",
                "countryCode": "MX"
            },
            {
                "name": "Amado Nervo",
                "countryCode": "MX"
            },
            {
                "name": "Amatenango de la Frontera",
                "countryCode": "MX"
            },
            {
                "name": "Amatenango del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Amatán",
                "countryCode": "MX"
            },
            {
                "name": "América Libre",
                "countryCode": "MX"
            },
            {
                "name": "Apas",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Arimatea",
                "countryCode": "MX"
            },
            {
                "name": "Arriaga",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Grande",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Granizo",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Palenque",
                "countryCode": "MX"
            },
            {
                "name": "Arvenza Uno",
                "countryCode": "MX"
            },
            {
                "name": "Aurora Ermita",
                "countryCode": "MX"
            },
            {
                "name": "Aurora Esquipulas",
                "countryCode": "MX"
            },
            {
                "name": "Azteca",
                "countryCode": "MX"
            },
            {
                "name": "Aztlán",
                "countryCode": "MX"
            },
            {
                "name": "Bachajón",
                "countryCode": "MX"
            },
            {
                "name": "Bajucu",
                "countryCode": "MX"
            },
            {
                "name": "Bautista Chico",
                "countryCode": "MX"
            },
            {
                "name": "Bejucal de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Belisario Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "Bella Vista",
                "countryCode": "MX"
            },
            {
                "name": "Benemérito de las Américas",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Berriozábal",
                "countryCode": "MX"
            },
            {
                "name": "Betania",
                "countryCode": "MX"
            },
            {
                "name": "Bochil",
                "countryCode": "MX"
            },
            {
                "name": "Bochojbo Alto",
                "countryCode": "MX"
            },
            {
                "name": "Brisas Barra de Suchiate",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista (Matasanos)",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Cabeza de Toro",
                "countryCode": "MX"
            },
            {
                "name": "Cacahoatán",
                "countryCode": "MX"
            },
            {
                "name": "Cacaté",
                "countryCode": "MX"
            },
            {
                "name": "Calzada Larga",
                "countryCode": "MX"
            },
            {
                "name": "Cancuc",
                "countryCode": "MX"
            },
            {
                "name": "Cantioc",
                "countryCode": "MX"
            },
            {
                "name": "Cantón Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Cantón Villaflor",
                "countryCode": "MX"
            },
            {
                "name": "Cantón las Delicias",
                "countryCode": "MX"
            },
            {
                "name": "Carmen Yalchuch",
                "countryCode": "MX"
            },
            {
                "name": "Carmen Zacatal",
                "countryCode": "MX"
            },
            {
                "name": "Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Cash",
                "countryCode": "MX"
            },
            {
                "name": "Catazajá",
                "countryCode": "MX"
            },
            {
                "name": "Catishtic",
                "countryCode": "MX"
            },
            {
                "name": "Cebadilla 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Cenobio Aguilar (La Trinidad)",
                "countryCode": "MX"
            },
            {
                "name": "Cereso 14 (El Amate)",
                "countryCode": "MX"
            },
            {
                "name": "Chacaljocóm",
                "countryCode": "MX"
            },
            {
                "name": "Chacoma",
                "countryCode": "MX"
            },
            {
                "name": "Chalam",
                "countryCode": "MX"
            },
            {
                "name": "Chalchihuitán",
                "countryCode": "MX"
            },
            {
                "name": "Chamula",
                "countryCode": "MX"
            },
            {
                "name": "Chamulapita",
                "countryCode": "MX"
            },
            {
                "name": "Chanal",
                "countryCode": "MX"
            },
            {
                "name": "Chapallal Grande",
                "countryCode": "MX"
            },
            {
                "name": "Chapultenango",
                "countryCode": "MX"
            },
            {
                "name": "Chiapa de Corzo",
                "countryCode": "MX"
            },
            {
                "name": "Chiapilla",
                "countryCode": "MX"
            },
            {
                "name": "Chicoasén",
                "countryCode": "MX"
            },
            {
                "name": "Chicomuselo",
                "countryCode": "MX"
            },
            {
                "name": "Chicumtantic",
                "countryCode": "MX"
            },
            {
                "name": "Chihuahua",
                "countryCode": "MX"
            },
            {
                "name": "Chiloljá",
                "countryCode": "MX"
            },
            {
                "name": "Chilón",
                "countryCode": "MX"
            },
            {
                "name": "Chimhucum",
                "countryCode": "MX"
            },
            {
                "name": "Chiquinival",
                "countryCode": "MX"
            },
            {
                "name": "Chiquinshulum",
                "countryCode": "MX"
            },
            {
                "name": "Chixtontic",
                "countryCode": "MX"
            },
            {
                "name": "Chulum Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Chulum Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Cintalapa",
                "countryCode": "MX"
            },
            {
                "name": "Cintalapa de Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Coapilla",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Obrera",
                "countryCode": "MX"
            },
            {
                "name": "Comalapa",
                "countryCode": "MX"
            },
            {
                "name": "Comitán",
                "countryCode": "MX"
            },
            {
                "name": "Concepción",
                "countryCode": "MX"
            },
            {
                "name": "Congregación Reforma",
                "countryCode": "MX"
            },
            {
                "name": "Constitución",
                "countryCode": "MX"
            },
            {
                "name": "Copainalá",
                "countryCode": "MX"
            },
            {
                "name": "Copoya",
                "countryCode": "MX"
            },
            {
                "name": "Cristóbal Colón",
                "countryCode": "MX"
            },
            {
                "name": "Cristóbal Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Cruztón",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuchulumtic",
                "countryCode": "MX"
            },
            {
                "name": "Cálido",
                "countryCode": "MX"
            },
            {
                "name": "Damasco",
                "countryCode": "MX"
            },
            {
                "name": "Diamante de Echeverría",
                "countryCode": "MX"
            },
            {
                "name": "Dieciséis de Septiembre",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Belisario Domínguez (La Barra)",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Domingo Chanona",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Rodulfo Figueroa (Tierra Blanca)",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Samuel León Brindis",
                "countryCode": "MX"
            },
            {
                "name": "Dolores Jaltenango",
                "countryCode": "MX"
            },
            {
                "name": "Efraín A. Gutiérrez",
                "countryCode": "MX"
            },
            {
                "name": "Egipto",
                "countryCode": "MX"
            },
            {
                "name": "El Arenal",
                "countryCode": "MX"
            },
            {
                "name": "El Bosque",
                "countryCode": "MX"
            },
            {
                "name": "El Calvario",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen (El Limón)",
                "countryCode": "MX"
            },
            {
                "name": "El Censo",
                "countryCode": "MX"
            },
            {
                "name": "El Consuelo Ulapa",
                "countryCode": "MX"
            },
            {
                "name": "El Copal",
                "countryCode": "MX"
            },
            {
                "name": "El Copalar",
                "countryCode": "MX"
            },
            {
                "name": "El Edén",
                "countryCode": "MX"
            },
            {
                "name": "El Encanto",
                "countryCode": "MX"
            },
            {
                "name": "El Espinal",
                "countryCode": "MX"
            },
            {
                "name": "El Jardín",
                "countryCode": "MX"
            },
            {
                "name": "El Jobo",
                "countryCode": "MX"
            },
            {
                "name": "El Limar",
                "countryCode": "MX"
            },
            {
                "name": "El Limonar",
                "countryCode": "MX"
            },
            {
                "name": "El Mango",
                "countryCode": "MX"
            },
            {
                "name": "El Nopal",
                "countryCode": "MX"
            },
            {
                "name": "El Pacayal",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar (San Gabriel)",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar Grande",
                "countryCode": "MX"
            },
            {
                "name": "El Palmarcito",
                "countryCode": "MX"
            },
            {
                "name": "El Paraíso",
                "countryCode": "MX"
            },
            {
                "name": "El Parral",
                "countryCode": "MX"
            },
            {
                "name": "El Portal",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir Agrarista",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir de Velasco Suárez",
                "countryCode": "MX"
            },
            {
                "name": "El Pozo",
                "countryCode": "MX"
            },
            {
                "name": "El Progreso",
                "countryCode": "MX"
            },
            {
                "name": "El Puerto",
                "countryCode": "MX"
            },
            {
                "name": "El Ramal (Porvenir)",
                "countryCode": "MX"
            },
            {
                "name": "El Sacrificio",
                "countryCode": "MX"
            },
            {
                "name": "El Sibal",
                "countryCode": "MX"
            },
            {
                "name": "El Triunfo",
                "countryCode": "MX"
            },
            {
                "name": "El Triunfo 1ra. Sección (Cardona)",
                "countryCode": "MX"
            },
            {
                "name": "El Triunfo de las Tres Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "El Tumbo",
                "countryCode": "MX"
            },
            {
                "name": "El Tzay",
                "countryCode": "MX"
            },
            {
                "name": "El Vergel",
                "countryCode": "MX"
            },
            {
                "name": "El Zapotal",
                "countryCode": "MX"
            },
            {
                "name": "El Zapotillo",
                "countryCode": "MX"
            },
            {
                "name": "El Águila",
                "countryCode": "MX"
            },
            {
                "name": "El Ámbar",
                "countryCode": "MX"
            },
            {
                "name": "El Ámbar (El Ámbar de Echeverría)",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Escuintla",
                "countryCode": "MX"
            },
            {
                "name": "Estación Huehuetán",
                "countryCode": "MX"
            },
            {
                "name": "Estación San Manuel",
                "countryCode": "MX"
            },
            {
                "name": "Estación Tuzantán",
                "countryCode": "MX"
            },
            {
                "name": "Estrella de Belén",
                "countryCode": "MX"
            },
            {
                "name": "Faja de Oro",
                "countryCode": "MX"
            },
            {
                "name": "Flor de Cacao",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco León",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Villa",
                "countryCode": "MX"
            },
            {
                "name": "Frontera Corozal",
                "countryCode": "MX"
            },
            {
                "name": "Frontera Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Gabriel Esquinca",
                "countryCode": "MX"
            },
            {
                "name": "Galecio Narcia",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Grijalva",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guaquitepec",
                "countryCode": "MX"
            },
            {
                "name": "Hermenegildo Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo Joshil",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetán",
                "countryCode": "MX"
            },
            {
                "name": "Huitiupán",
                "countryCode": "MX"
            },
            {
                "name": "Huixtla",
                "countryCode": "MX"
            },
            {
                "name": "Huizachal",
                "countryCode": "MX"
            },
            {
                "name": "Héroes de Chapultepec",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio López Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza (El Morro)",
                "countryCode": "MX"
            },
            {
                "name": "Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuatán",
                "countryCode": "MX"
            },
            {
                "name": "Ixtacomitán",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapa",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapangajoya",
                "countryCode": "MX"
            },
            {
                "name": "Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Jaltenango de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "Jardínes del Grijalva",
                "countryCode": "MX"
            },
            {
                "name": "Jericó",
                "countryCode": "MX"
            },
            {
                "name": "Jerusalén",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María Garza",
                "countryCode": "MX"
            },
            {
                "name": "Jiquilpan (Estación Bonanza)",
                "countryCode": "MX"
            },
            {
                "name": "Jiquipilas",
                "countryCode": "MX"
            },
            {
                "name": "Jitotol",
                "countryCode": "MX"
            },
            {
                "name": "Joaquín Miguel Gutiérrez",
                "countryCode": "MX"
            },
            {
                "name": "Joaquín Miguel Gutiérrez (Margaritas)",
                "countryCode": "MX"
            },
            {
                "name": "Joljá",
                "countryCode": "MX"
            },
            {
                "name": "Jolsibaquil",
                "countryCode": "MX"
            },
            {
                "name": "Joltealal",
                "countryCode": "MX"
            },
            {
                "name": "Jomanichim",
                "countryCode": "MX"
            },
            {
                "name": "Joshil",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos y Pavón",
                "countryCode": "MX"
            },
            {
                "name": "José María Pino Suárez",
                "countryCode": "MX"
            },
            {
                "name": "Juan Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Juan del Grijalva",
                "countryCode": "MX"
            },
            {
                "name": "Julián Grajales",
                "countryCode": "MX"
            },
            {
                "name": "Justo Sierra",
                "countryCode": "MX"
            },
            {
                "name": "Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Kotolte",
                "countryCode": "MX"
            },
            {
                "name": "La Candelaria",
                "countryCode": "MX"
            },
            {
                "name": "La Ceiba",
                "countryCode": "MX"
            },
            {
                "name": "La Competencia",
                "countryCode": "MX"
            },
            {
                "name": "La Concordia",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza (El Zapotal)",
                "countryCode": "MX"
            },
            {
                "name": "La Floresta",
                "countryCode": "MX"
            },
            {
                "name": "La Gloria",
                "countryCode": "MX"
            },
            {
                "name": "La Grandeza",
                "countryCode": "MX"
            },
            {
                "name": "La Independencia",
                "countryCode": "MX"
            },
            {
                "name": "La Independencia (Las Pilas)",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Línea",
                "countryCode": "MX"
            },
            {
                "name": "La Patria",
                "countryCode": "MX"
            },
            {
                "name": "La Pimienta",
                "countryCode": "MX"
            },
            {
                "name": "La Rinconada",
                "countryCode": "MX"
            },
            {
                "name": "La Tigrilla",
                "countryCode": "MX"
            },
            {
                "name": "La Trinitaria",
                "countryCode": "MX"
            },
            {
                "name": "Lacandón",
                "countryCode": "MX"
            },
            {
                "name": "Laguna del Cofre",
                "countryCode": "MX"
            },
            {
                "name": "Larráinzar",
                "countryCode": "MX"
            },
            {
                "name": "Las Brisas",
                "countryCode": "MX"
            },
            {
                "name": "Las Chicharras",
                "countryCode": "MX"
            },
            {
                "name": "Las Delicias",
                "countryCode": "MX"
            },
            {
                "name": "Las Flechas",
                "countryCode": "MX"
            },
            {
                "name": "Las Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Las Margaritas",
                "countryCode": "MX"
            },
            {
                "name": "Las Ollas",
                "countryCode": "MX"
            },
            {
                "name": "Las Rosas",
                "countryCode": "MX"
            },
            {
                "name": "Las Tazas",
                "countryCode": "MX"
            },
            {
                "name": "Libertad Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Libertad Ventanas",
                "countryCode": "MX"
            },
            {
                "name": "Llano de la Lima",
                "countryCode": "MX"
            },
            {
                "name": "Loma Bonita",
                "countryCode": "MX"
            },
            {
                "name": "Lomantán",
                "countryCode": "MX"
            },
            {
                "name": "Los Cafetales",
                "countryCode": "MX"
            },
            {
                "name": "Los Corazones",
                "countryCode": "MX"
            },
            {
                "name": "Los Naranjos",
                "countryCode": "MX"
            },
            {
                "name": "Los Palacios",
                "countryCode": "MX"
            },
            {
                "name": "Los Pinos",
                "countryCode": "MX"
            },
            {
                "name": "Los Plátanos",
                "countryCode": "MX"
            },
            {
                "name": "Los Pozos",
                "countryCode": "MX"
            },
            {
                "name": "Los Riegos",
                "countryCode": "MX"
            },
            {
                "name": "Luis Espinoza",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas (Chilil)",
                "countryCode": "MX"
            },
            {
                "name": "Macvilhó",
                "countryCode": "MX"
            },
            {
                "name": "Majastic",
                "countryCode": "MX"
            },
            {
                "name": "Majomut",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Lazos",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Ávila Camacho",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Ávila Camacho (Ponte Duro)",
                "countryCode": "MX"
            },
            {
                "name": "Mapastepec",
                "countryCode": "MX"
            },
            {
                "name": "Maravilla Tenejapa",
                "countryCode": "MX"
            },
            {
                "name": "Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Mariano Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Mariscal Subikuski",
                "countryCode": "MX"
            },
            {
                "name": "Marqués de Comillas",
                "countryCode": "MX"
            },
            {
                "name": "Marte R. Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Matzam",
                "countryCode": "MX"
            },
            {
                "name": "Mazapa",
                "countryCode": "MX"
            },
            {
                "name": "Mazapa de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Mazatán",
                "countryCode": "MX"
            },
            {
                "name": "Metapa",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Utrilla (Los Chorros)",
                "countryCode": "MX"
            },
            {
                "name": "Misija",
                "countryCode": "MX"
            },
            {
                "name": "Mitontic",
                "countryCode": "MX"
            },
            {
                "name": "Mitzitón",
                "countryCode": "MX"
            },
            {
                "name": "Mixcum",
                "countryCode": "MX"
            },
            {
                "name": "Monte Redondo",
                "countryCode": "MX"
            },
            {
                "name": "Montecristo",
                "countryCode": "MX"
            },
            {
                "name": "Montecristo de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Monterrey",
                "countryCode": "MX"
            },
            {
                "name": "Morelia (Victórico Rodolfo Grajales)",
                "countryCode": "MX"
            },
            {
                "name": "Motozintla",
                "countryCode": "MX"
            },
            {
                "name": "Moyos",
                "countryCode": "MX"
            },
            {
                "name": "Muquén",
                "countryCode": "MX"
            },
            {
                "name": "Mérida",
                "countryCode": "MX"
            },
            {
                "name": "Nachig",
                "countryCode": "MX"
            },
            {
                "name": "Narciso Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Navenchauc",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Ruiz",
                "countryCode": "MX"
            },
            {
                "name": "Nueva América",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Colombia",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Libertad (El Colorado)",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Morelia",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Palestina",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Sesecapa",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Tenochtitlán (Rizo de Oro)",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Amatenango",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Carmen Tonapac",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Francisco León",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Juan del Grijalva",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo León",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Limar",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Milenio Valdivia",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo México",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Nicapa",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Pacayal",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Pumpuapa (Cereso)",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo San Juan Chamula",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Sitalá",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Vicente Guerrero (El Chichonal)",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Volcán Chichonal",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Xochimilco",
                "countryCode": "MX"
            },
            {
                "name": "Ochusjob",
                "countryCode": "MX"
            },
            {
                "name": "Ococh",
                "countryCode": "MX"
            },
            {
                "name": "Ocosingo",
                "countryCode": "MX"
            },
            {
                "name": "Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "Ocozocoautla de Espinosa",
                "countryCode": "MX"
            },
            {
                "name": "Octavio Paz",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Omoa",
                "countryCode": "MX"
            },
            {
                "name": "Once de Abril",
                "countryCode": "MX"
            },
            {
                "name": "Ostuacán",
                "countryCode": "MX"
            },
            {
                "name": "Osumacinta",
                "countryCode": "MX"
            },
            {
                "name": "Oxchuc",
                "countryCode": "MX"
            },
            {
                "name": "Oxinam",
                "countryCode": "MX"
            },
            {
                "name": "Pablo L. Sidar",
                "countryCode": "MX"
            },
            {
                "name": "Pacú",
                "countryCode": "MX"
            },
            {
                "name": "Palenque",
                "countryCode": "MX"
            },
            {
                "name": "Pantelhó",
                "countryCode": "MX"
            },
            {
                "name": "Pantepec",
                "countryCode": "MX"
            },
            {
                "name": "Paraíso del Grijalva",
                "countryCode": "MX"
            },
            {
                "name": "Paredón",
                "countryCode": "MX"
            },
            {
                "name": "Paso Hondo",
                "countryCode": "MX"
            },
            {
                "name": "Pasté",
                "countryCode": "MX"
            },
            {
                "name": "Patosil",
                "countryCode": "MX"
            },
            {
                "name": "Patria Nueva (San José el Contento)",
                "countryCode": "MX"
            },
            {
                "name": "Pavencul",
                "countryCode": "MX"
            },
            {
                "name": "Perla de Acapulco",
                "countryCode": "MX"
            },
            {
                "name": "Petalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Pichucalco",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Labrada",
                "countryCode": "MX"
            },
            {
                "name": "Pijijiapan",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Agua Prieta",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Pomposo Castellanos",
                "countryCode": "MX"
            },
            {
                "name": "Potrerillo",
                "countryCode": "MX"
            },
            {
                "name": "Presidente Echeverría (Laja Tendida)",
                "countryCode": "MX"
            },
            {
                "name": "Primer Cantón",
                "countryCode": "MX"
            },
            {
                "name": "Profresor Roberto Barrios",
                "countryCode": "MX"
            },
            {
                "name": "Puebla",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Madero",
                "countryCode": "MX"
            },
            {
                "name": "Pugchén Mumuntic",
                "countryCode": "MX"
            },
            {
                "name": "Punta Arena",
                "countryCode": "MX"
            },
            {
                "name": "Querétaro",
                "countryCode": "MX"
            },
            {
                "name": "Quintana Roo",
                "countryCode": "MX"
            },
            {
                "name": "Rafael Pascacio Gamboa",
                "countryCode": "MX"
            },
            {
                "name": "Rafael Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Ramón F. Balboa",
                "countryCode": "MX"
            },
            {
                "name": "Raudales Malpaso",
                "countryCode": "MX"
            },
            {
                "name": "Raymundo Enríquez",
                "countryCode": "MX"
            },
            {
                "name": "Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Reforma",
                "countryCode": "MX"
            },
            {
                "name": "Reforma y Planada",
                "countryCode": "MX"
            },
            {
                "name": "Revolución Mexicana",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Rincón Chamula",
                "countryCode": "MX"
            },
            {
                "name": "Rizo de Oro",
                "countryCode": "MX"
            },
            {
                "name": "Roblada Grande",
                "countryCode": "MX"
            },
            {
                "name": "Rodulfo Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "Romerillo",
                "countryCode": "MX"
            },
            {
                "name": "Río Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Río Chancalá",
                "countryCode": "MX"
            },
            {
                "name": "Río Jordán",
                "countryCode": "MX"
            },
            {
                "name": "Sabanilla",
                "countryCode": "MX"
            },
            {
                "name": "Sabinalito",
                "countryCode": "MX"
            },
            {
                "name": "Saclamantón",
                "countryCode": "MX"
            },
            {
                "name": "Saltillo",
                "countryCode": "MX"
            },
            {
                "name": "Salto de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Salvador Urbina",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Duraznal",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Bulujib",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio del Monte",
                "countryCode": "MX"
            },
            {
                "name": "San Caralampio",
                "countryCode": "MX"
            },
            {
                "name": "San Cayetano",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal de las Casas",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Tizapa",
                "countryCode": "MX"
            },
            {
                "name": "San Fernando",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco (El Calvito)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Jaconá",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Pujiltic",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro las Banderas",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Tulijá",
                "countryCode": "MX"
            },
            {
                "name": "San José Chapayal",
                "countryCode": "MX"
            },
            {
                "name": "San José Yashitinín",
                "countryCode": "MX"
            },
            {
                "name": "San José Yocnajab",
                "countryCode": "MX"
            },
            {
                "name": "San José las Chicharras",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas",
                "countryCode": "MX"
            },
            {
                "name": "San Luqueño",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel la Sardina",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Huacano",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Nichtalucum",
                "countryCode": "MX"
            },
            {
                "name": "San Quintín",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente la Mesilla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Elena",
                "countryCode": "MX"
            },
            {
                "name": "Santa María",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Pojcol",
                "countryCode": "MX"
            },
            {
                "name": "Santiago el Pinar",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo de las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "Señor del Pozo",
                "countryCode": "MX"
            },
            {
                "name": "Shoctic",
                "countryCode": "MX"
            },
            {
                "name": "Sibacá",
                "countryCode": "MX"
            },
            {
                "name": "Sibaniljá Pocolum",
                "countryCode": "MX"
            },
            {
                "name": "Siltepec",
                "countryCode": "MX"
            },
            {
                "name": "Simojovel",
                "countryCode": "MX"
            },
            {
                "name": "Simojovel de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Sinaloa",
                "countryCode": "MX"
            },
            {
                "name": "Sitalá",
                "countryCode": "MX"
            },
            {
                "name": "Socoltenango",
                "countryCode": "MX"
            },
            {
                "name": "Soconusco",
                "countryCode": "MX"
            },
            {
                "name": "Solosuchiapa",
                "countryCode": "MX"
            },
            {
                "name": "Soyaló",
                "countryCode": "MX"
            },
            {
                "name": "Soyatitán",
                "countryCode": "MX"
            },
            {
                "name": "Suchiapa",
                "countryCode": "MX"
            },
            {
                "name": "Suchiate",
                "countryCode": "MX"
            },
            {
                "name": "Tacuba Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Talismán",
                "countryCode": "MX"
            },
            {
                "name": "Tamaulipas",
                "countryCode": "MX"
            },
            {
                "name": "Taniperla",
                "countryCode": "MX"
            },
            {
                "name": "Tapachula",
                "countryCode": "MX"
            },
            {
                "name": "Tapalapa",
                "countryCode": "MX"
            },
            {
                "name": "Tapilula",
                "countryCode": "MX"
            },
            {
                "name": "Tecpatán",
                "countryCode": "MX"
            },
            {
                "name": "Tenango",
                "countryCode": "MX"
            },
            {
                "name": "Tentic",
                "countryCode": "MX"
            },
            {
                "name": "Teopisca",
                "countryCode": "MX"
            },
            {
                "name": "Texcaltic",
                "countryCode": "MX"
            },
            {
                "name": "Tierra y Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Tila",
                "countryCode": "MX"
            },
            {
                "name": "Tiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Tinajas 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Tocob Leglemal",
                "countryCode": "MX"
            },
            {
                "name": "Tonalá",
                "countryCode": "MX"
            },
            {
                "name": "Totolapa",
                "countryCode": "MX"
            },
            {
                "name": "Tres Cerros",
                "countryCode": "MX"
            },
            {
                "name": "Tres Picos",
                "countryCode": "MX"
            },
            {
                "name": "Tumbala",
                "countryCode": "MX"
            },
            {
                "name": "Tuxtla",
                "countryCode": "MX"
            },
            {
                "name": "Tuxtla Chico",
                "countryCode": "MX"
            },
            {
                "name": "Tuzantán",
                "countryCode": "MX"
            },
            {
                "name": "Tz'Aquiviljok",
                "countryCode": "MX"
            },
            {
                "name": "Tzajalchén",
                "countryCode": "MX"
            },
            {
                "name": "Tzajalá",
                "countryCode": "MX"
            },
            {
                "name": "Tzeltal",
                "countryCode": "MX"
            },
            {
                "name": "Tzimol",
                "countryCode": "MX"
            },
            {
                "name": "Tzinil",
                "countryCode": "MX"
            },
            {
                "name": "Tziscao",
                "countryCode": "MX"
            },
            {
                "name": "Tzoeptic",
                "countryCode": "MX"
            },
            {
                "name": "Tzontehuitz",
                "countryCode": "MX"
            },
            {
                "name": "Tzopilja",
                "countryCode": "MX"
            },
            {
                "name": "Ubilio García",
                "countryCode": "MX"
            },
            {
                "name": "Unión Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Unión Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Unión Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Unión Roja",
                "countryCode": "MX"
            },
            {
                "name": "Usipa",
                "countryCode": "MX"
            },
            {
                "name": "Vega del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Veinte de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Veracruz",
                "countryCode": "MX"
            },
            {
                "name": "Verapaz",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Vida Mejor I",
                "countryCode": "MX"
            },
            {
                "name": "Villa Comaltitlán",
                "countryCode": "MX"
            },
            {
                "name": "Villa Corzo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villaflores",
                "countryCode": "MX"
            },
            {
                "name": "Villahermosa Yaluma",
                "countryCode": "MX"
            },
            {
                "name": "Villamorelos",
                "countryCode": "MX"
            },
            {
                "name": "Viva Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Viva México",
                "countryCode": "MX"
            },
            {
                "name": "Xochiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Yajalón",
                "countryCode": "MX"
            },
            {
                "name": "Yaltem",
                "countryCode": "MX"
            },
            {
                "name": "Yasha",
                "countryCode": "MX"
            },
            {
                "name": "Yashanal",
                "countryCode": "MX"
            },
            {
                "name": "Yibeljoj",
                "countryCode": "MX"
            },
            {
                "name": "Yoshib",
                "countryCode": "MX"
            },
            {
                "name": "Zacatonal de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpa",
                "countryCode": "MX"
            },
            {
                "name": "Zamora Pico de Oro",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza la Montaña",
                "countryCode": "MX"
            },
            {
                "name": "Zequentic",
                "countryCode": "MX"
            },
            {
                "name": "Zinacantán",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Ángel Albino Corzo",
                "countryCode": "MX"
            },
            {
                "name": "Ángel Albino Corzo (Guadalupe)",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "Agua Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Albia",
                "countryCode": "MX"
            },
            {
                "name": "Alejo González (Bilbao)",
                "countryCode": "MX"
            },
            {
                "name": "Arteaga",
                "countryCode": "MX"
            },
            {
                "name": "Boquilla de las Perlas",
                "countryCode": "MX"
            },
            {
                "name": "Candela",
                "countryCode": "MX"
            },
            {
                "name": "Castaños",
                "countryCode": "MX"
            },
            {
                "name": "Chula Vista",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Acuña",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Melchor Múzquiz",
                "countryCode": "MX"
            },
            {
                "name": "Cloete",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Diana Laura Riojas de Colosio",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Compuertas",
                "countryCode": "MX"
            },
            {
                "name": "Concordia",
                "countryCode": "MX"
            },
            {
                "name": "Coyote",
                "countryCode": "MX"
            },
            {
                "name": "Cuatro Ciénegas de Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "El Cambio",
                "countryCode": "MX"
            },
            {
                "name": "El Consuelo",
                "countryCode": "MX"
            },
            {
                "name": "El Cuije",
                "countryCode": "MX"
            },
            {
                "name": "El Lequeitio",
                "countryCode": "MX"
            },
            {
                "name": "El Nilo",
                "countryCode": "MX"
            },
            {
                "name": "El Perú",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir (El Porvenir de Arriba)",
                "countryCode": "MX"
            },
            {
                "name": "El Retiro",
                "countryCode": "MX"
            },
            {
                "name": "Escuadrón Doscientos Uno",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento la Noria",
                "countryCode": "MX"
            },
            {
                "name": "Frontera",
                "countryCode": "MX"
            },
            {
                "name": "General Cepeda",
                "countryCode": "MX"
            },
            {
                "name": "Gilita",
                "countryCode": "MX"
            },
            {
                "name": "Granada",
                "countryCode": "MX"
            },
            {
                "name": "Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Hormiguero",
                "countryCode": "MX"
            },
            {
                "name": "Huachichil",
                "countryCode": "MX"
            },
            {
                "name": "Huitrón",
                "countryCode": "MX"
            },
            {
                "name": "Hércules",
                "countryCode": "MX"
            },
            {
                "name": "Jaboncillo",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "La Concha",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Esmeralda",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Fe",
                "countryCode": "MX"
            },
            {
                "name": "La Florida",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Partida",
                "countryCode": "MX"
            },
            {
                "name": "La Paz",
                "countryCode": "MX"
            },
            {
                "name": "La Perla",
                "countryCode": "MX"
            },
            {
                "name": "La Pinta",
                "countryCode": "MX"
            },
            {
                "name": "Laguna del Rey (Químicas del Rey)",
                "countryCode": "MX"
            },
            {
                "name": "Lamadrid",
                "countryCode": "MX"
            },
            {
                "name": "Las Mieleras",
                "countryCode": "MX"
            },
            {
                "name": "Los Álamos",
                "countryCode": "MX"
            },
            {
                "name": "Luchanas",
                "countryCode": "MX"
            },
            {
                "name": "Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Mayran",
                "countryCode": "MX"
            },
            {
                "name": "Minas de Barroterán",
                "countryCode": "MX"
            },
            {
                "name": "Monclova",
                "countryCode": "MX"
            },
            {
                "name": "Nadadores",
                "countryCode": "MX"
            },
            {
                "name": "Nava",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Rosita",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo León",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Ocho de Enero",
                "countryCode": "MX"
            },
            {
                "name": "Palau",
                "countryCode": "MX"
            },
            {
                "name": "Paredón",
                "countryCode": "MX"
            },
            {
                "name": "Parras de la Fuente",
                "countryCode": "MX"
            },
            {
                "name": "Piedras Negras",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Purísima",
                "countryCode": "MX"
            },
            {
                "name": "Ramos Arizpe",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Alegre",
                "countryCode": "MX"
            },
            {
                "name": "Río Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Sabinas",
                "countryCode": "MX"
            },
            {
                "name": "Sacramento",
                "countryCode": "MX"
            },
            {
                "name": "Saltillo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de las Alazanas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio del Coyote",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Esteban de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San José de Aura",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Sabinas",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de la Vaquería",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana del Pilar",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fé",
                "countryCode": "MX"
            },
            {
                "name": "Santo Niño Aguanaval",
                "countryCode": "MX"
            },
            {
                "name": "Seis de Octubre (Santo Niño)",
                "countryCode": "MX"
            },
            {
                "name": "Sierra Mojada",
                "countryCode": "MX"
            },
            {
                "name": "Solima",
                "countryCode": "MX"
            },
            {
                "name": "Tacubaya",
                "countryCode": "MX"
            },
            {
                "name": "Torreón",
                "countryCode": "MX"
            },
            {
                "name": "Veinte de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Veinte de Noviembre (Santa Lucía)",
                "countryCode": "MX"
            },
            {
                "name": "Ventana",
                "countryCode": "MX"
            },
            {
                "name": "Viesca",
                "countryCode": "MX"
            },
            {
                "name": "Villa Las Esperanzas",
                "countryCode": "MX"
            },
            {
                "name": "Villa Union",
                "countryCode": "MX"
            },
            {
                "name": "Virginias",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Alcaraces",
                "countryCode": "MX"
            },
            {
                "name": "Armería",
                "countryCode": "MX"
            },
            {
                "name": "Augusto Gómez Villanueva",
                "countryCode": "MX"
            },
            {
                "name": "Caleras",
                "countryCode": "MX"
            },
            {
                "name": "Camotlán de Miraflores",
                "countryCode": "MX"
            },
            {
                "name": "Cerro de Ortega",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Armería",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Villa de Álvarez",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía de Suchitlán",
                "countryCode": "MX"
            },
            {
                "name": "Comala",
                "countryCode": "MX"
            },
            {
                "name": "Coquimatlán",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuyutlán",
                "countryCode": "MX"
            },
            {
                "name": "El Colomo",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "El Trapiche",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuacán",
                "countryCode": "MX"
            },
            {
                "name": "Jalipa",
                "countryCode": "MX"
            },
            {
                "name": "La Central",
                "countryCode": "MX"
            },
            {
                "name": "Los Tepames",
                "countryCode": "MX"
            },
            {
                "name": "Madrid",
                "countryCode": "MX"
            },
            {
                "name": "Minatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Piscila",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Juárez (La Magdalena)",
                "countryCode": "MX"
            },
            {
                "name": "Quesería",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de López",
                "countryCode": "MX"
            },
            {
                "name": "Suchitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tecomán",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "Antonio Amaro",
                "countryCode": "MX"
            },
            {
                "name": "Arcinas",
                "countryCode": "MX"
            },
            {
                "name": "Arturo Martínez Adame",
                "countryCode": "MX"
            },
            {
                "name": "Banco Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Banderas del Águila",
                "countryCode": "MX"
            },
            {
                "name": "Bermejillo",
                "countryCode": "MX"
            },
            {
                "name": "California",
                "countryCode": "MX"
            },
            {
                "name": "Canatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ceballos",
                "countryCode": "MX"
            },
            {
                "name": "Cieneguillas",
                "countryCode": "MX"
            },
            {
                "name": "Cinco de Febrero",
                "countryCode": "MX"
            },
            {
                "name": "Cinco de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Lerdo",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega de Nuestra Señora de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtemoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuencamé",
                "countryCode": "MX"
            },
            {
                "name": "Cuencamé de Ceniceros",
                "countryCode": "MX"
            },
            {
                "name": "Diez de Octubre",
                "countryCode": "MX"
            },
            {
                "name": "El Arenal",
                "countryCode": "MX"
            },
            {
                "name": "El Durazno",
                "countryCode": "MX"
            },
            {
                "name": "El Huarache (El Guarache)",
                "countryCode": "MX"
            },
            {
                "name": "El Lucero",
                "countryCode": "MX"
            },
            {
                "name": "El Nayar",
                "countryCode": "MX"
            },
            {
                "name": "El Rayo",
                "countryCode": "MX"
            },
            {
                "name": "El Salto",
                "countryCode": "MX"
            },
            {
                "name": "El Vergel",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Esfuerzos Unidos",
                "countryCode": "MX"
            },
            {
                "name": "Esmeralda",
                "countryCode": "MX"
            },
            {
                "name": "Eureka de Media Luna (Eureka)",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Gabriel Hernández (Mancinas)",
                "countryCode": "MX"
            },
            {
                "name": "General Calixto Contreras",
                "countryCode": "MX"
            },
            {
                "name": "General Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Guanaceví",
                "countryCode": "MX"
            },
            {
                "name": "Guatimapé",
                "countryCode": "MX"
            },
            {
                "name": "Gómez Palacio",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Indé",
                "countryCode": "MX"
            },
            {
                "name": "Jauja",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "José Guadalupe Aguilera (Santa Lucía)",
                "countryCode": "MX"
            },
            {
                "name": "José Guadalupe Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "José María Pino Suárez",
                "countryCode": "MX"
            },
            {
                "name": "José Refugio Salcido",
                "countryCode": "MX"
            },
            {
                "name": "Juan E. García",
                "countryCode": "MX"
            },
            {
                "name": "La Ciudad",
                "countryCode": "MX"
            },
            {
                "name": "La Flor",
                "countryCode": "MX"
            },
            {
                "name": "La Goma",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Loma",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Popular",
                "countryCode": "MX"
            },
            {
                "name": "Las Cuevas",
                "countryCode": "MX"
            },
            {
                "name": "León Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Los Angeles",
                "countryCode": "MX"
            },
            {
                "name": "Mapimí",
                "countryCode": "MX"
            },
            {
                "name": "Mezquital",
                "countryCode": "MX"
            },
            {
                "name": "Narciso Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Nazareno",
                "countryCode": "MX"
            },
            {
                "name": "Nazas",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nogales",
                "countryCode": "MX"
            },
            {
                "name": "Nombre de Dios",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Ideal",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Orizaba",
                "countryCode": "MX"
            },
            {
                "name": "Pasaje",
                "countryCode": "MX"
            },
            {
                "name": "Paso Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Pastor Rovaix",
                "countryCode": "MX"
            },
            {
                "name": "Pedriceña",
                "countryCode": "MX"
            },
            {
                "name": "Picardías",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Pánuco de Coronado",
                "countryCode": "MX"
            },
            {
                "name": "Ramón Corona",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Rodeo",
                "countryCode": "MX"
            },
            {
                "name": "San Atenógenes (La Villita)",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardino de Milpillas Chico",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Javier",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Horizonte (Horizonte)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco del Mezquital",
                "countryCode": "MX"
            },
            {
                "name": "San Jacinto",
                "countryCode": "MX"
            },
            {
                "name": "San José de Gracia",
                "countryCode": "MX"
            },
            {
                "name": "San José de Viñedo",
                "countryCode": "MX"
            },
            {
                "name": "San José de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "San Juan del Rio del Centauro del Norte",
                "countryCode": "MX"
            },
            {
                "name": "San Julio",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de Cruces",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro del Gallo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina de Tepehuanes",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Luján",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Bayacora",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Papasquiaro",
                "countryCode": "MX"
            },
            {
                "name": "Sapioris",
                "countryCode": "MX"
            },
            {
                "name": "Sebastián Lerdo de Tejada",
                "countryCode": "MX"
            },
            {
                "name": "Seis de Enero",
                "countryCode": "MX"
            },
            {
                "name": "Seis de Octubre",
                "countryCode": "MX"
            },
            {
                "name": "Súchil",
                "countryCode": "MX"
            },
            {
                "name": "Tamazula",
                "countryCode": "MX"
            },
            {
                "name": "Tamazula de Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Tayoltita",
                "countryCode": "MX"
            },
            {
                "name": "Tepehuanes",
                "countryCode": "MX"
            },
            {
                "name": "Tlahualilo de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Topia",
                "countryCode": "MX"
            },
            {
                "name": "Transporte",
                "countryCode": "MX"
            },
            {
                "name": "Velardeña",
                "countryCode": "MX"
            },
            {
                "name": "Venecia",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Victoria de Durango",
                "countryCode": "MX"
            },
            {
                "name": "Villa Gregorio García",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Montemorelos",
                "countryCode": "MX"
            },
            {
                "name": "Villa Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Unión",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "10 de Abril",
                "countryCode": "MX"
            },
            {
                "name": "Acahuizotla",
                "countryCode": "MX"
            },
            {
                "name": "Acalco",
                "countryCode": "MX"
            },
            {
                "name": "Acamixtla",
                "countryCode": "MX"
            },
            {
                "name": "Acapetlahuaya",
                "countryCode": "MX"
            },
            {
                "name": "Acapulco de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Acatempa",
                "countryCode": "MX"
            },
            {
                "name": "Acatempan",
                "countryCode": "MX"
            },
            {
                "name": "Acatepec",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "Acuitlapan",
                "countryCode": "MX"
            },
            {
                "name": "Agua Zarca",
                "countryCode": "MX"
            },
            {
                "name": "Aguas Blancas",
                "countryCode": "MX"
            },
            {
                "name": "Aguas Calientes",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacachahue (Ndog'yo Itún Tichi)",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacuotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Ahuajutla",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatepec Pueblo",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehuepan",
                "countryCode": "MX"
            },
            {
                "name": "Ahuexotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuihuiyuco",
                "countryCode": "MX"
            },
            {
                "name": "Ajuchitlán del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Alcholoa",
                "countryCode": "MX"
            },
            {
                "name": "Alcozacán",
                "countryCode": "MX"
            },
            {
                "name": "Alcozauca de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Almolonga",
                "countryCode": "MX"
            },
            {
                "name": "Alpoyeca",
                "countryCode": "MX"
            },
            {
                "name": "Alpuyecancingo de las Montañas",
                "countryCode": "MX"
            },
            {
                "name": "Amatillo",
                "countryCode": "MX"
            },
            {
                "name": "Amuco de la Reforma",
                "countryCode": "MX"
            },
            {
                "name": "Apalani",
                "countryCode": "MX"
            },
            {
                "name": "Apango",
                "countryCode": "MX"
            },
            {
                "name": "Apantla",
                "countryCode": "MX"
            },
            {
                "name": "Apaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Apaxtla de Castrejón",
                "countryCode": "MX"
            },
            {
                "name": "Apipilulco",
                "countryCode": "MX"
            },
            {
                "name": "Aratichanguío",
                "countryCode": "MX"
            },
            {
                "name": "Arcelia",
                "countryCode": "MX"
            },
            {
                "name": "Arcelia de Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Arenal de Álvarez (Arenal de Paco)",
                "countryCode": "MX"
            },
            {
                "name": "Atenango del Río",
                "countryCode": "MX"
            },
            {
                "name": "Atenxoxola",
                "countryCode": "MX"
            },
            {
                "name": "Atlamajac",
                "countryCode": "MX"
            },
            {
                "name": "Atlamajalcingo del Río",
                "countryCode": "MX"
            },
            {
                "name": "Atliaca",
                "countryCode": "MX"
            },
            {
                "name": "Atlixtac",
                "countryCode": "MX"
            },
            {
                "name": "Atoyac de Álvarez",
                "countryCode": "MX"
            },
            {
                "name": "Atzacoaloya",
                "countryCode": "MX"
            },
            {
                "name": "Axaxacualco",
                "countryCode": "MX"
            },
            {
                "name": "Axixintla",
                "countryCode": "MX"
            },
            {
                "name": "Ayahualulco",
                "countryCode": "MX"
            },
            {
                "name": "Ayotzinapa",
                "countryCode": "MX"
            },
            {
                "name": "Ayutla de los Libres",
                "countryCode": "MX"
            },
            {
                "name": "Azoyú",
                "countryCode": "MX"
            },
            {
                "name": "Bajos del Ejido",
                "countryCode": "MX"
            },
            {
                "name": "Barra de Tecoanapa",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Nuevo de los Muertos",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista de la Salud",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Cuéllar",
                "countryCode": "MX"
            },
            {
                "name": "Cacalotenango",
                "countryCode": "MX"
            },
            {
                "name": "Cacalutla",
                "countryCode": "MX"
            },
            {
                "name": "Cahuatache",
                "countryCode": "MX"
            },
            {
                "name": "Cajelitos",
                "countryCode": "MX"
            },
            {
                "name": "Campanario",
                "countryCode": "MX"
            },
            {
                "name": "Carrizalillo",
                "countryCode": "MX"
            },
            {
                "name": "Caxitepec",
                "countryCode": "MX"
            },
            {
                "name": "Cayaco",
                "countryCode": "MX"
            },
            {
                "name": "Cerro de Piedra",
                "countryCode": "MX"
            },
            {
                "name": "Chacalapa",
                "countryCode": "MX"
            },
            {
                "name": "Changata",
                "countryCode": "MX"
            },
            {
                "name": "Chaucingo",
                "countryCode": "MX"
            },
            {
                "name": "Chiaucingo",
                "countryCode": "MX"
            },
            {
                "name": "Chichihualco",
                "countryCode": "MX"
            },
            {
                "name": "Chiepetepec",
                "countryCode": "MX"
            },
            {
                "name": "Chilacachapa",
                "countryCode": "MX"
            },
            {
                "name": "Chilapa de Álvarez",
                "countryCode": "MX"
            },
            {
                "name": "Chilpancingo",
                "countryCode": "MX"
            },
            {
                "name": "Chontalcoatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Altamirano",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Huitzuco",
                "countryCode": "MX"
            },
            {
                "name": "Coachimalco",
                "countryCode": "MX"
            },
            {
                "name": "Coacoyula de Álvarez",
                "countryCode": "MX"
            },
            {
                "name": "Coacoyulichán",
                "countryCode": "MX"
            },
            {
                "name": "Coacoyulillo",
                "countryCode": "MX"
            },
            {
                "name": "Coahuayutla de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Coahuayutla de José María Izazaga",
                "countryCode": "MX"
            },
            {
                "name": "Coatepec Costales",
                "countryCode": "MX"
            },
            {
                "name": "Cochoapa",
                "countryCode": "MX"
            },
            {
                "name": "Cochoapa el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cocula",
                "countryCode": "MX"
            },
            {
                "name": "Colombia de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Aeropuerto",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Alborada",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Constitución",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Luces en el Mar",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Nueva Revolución",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Renacimiento",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Pedregal",
                "countryCode": "MX"
            },
            {
                "name": "Colotepec",
                "countryCode": "MX"
            },
            {
                "name": "Colotlipa",
                "countryCode": "MX"
            },
            {
                "name": "Copala",
                "countryCode": "MX"
            },
            {
                "name": "Copalillo",
                "countryCode": "MX"
            },
            {
                "name": "Copanatoyac",
                "countryCode": "MX"
            },
            {
                "name": "Corral Falso",
                "countryCode": "MX"
            },
            {
                "name": "Coyahualco",
                "countryCode": "MX"
            },
            {
                "name": "Coyuca de Benítez",
                "countryCode": "MX"
            },
            {
                "name": "Coyuca de Catalán",
                "countryCode": "MX"
            },
            {
                "name": "Coyuquilla Norte",
                "countryCode": "MX"
            },
            {
                "name": "Cruz Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cruz Quemada",
                "countryCode": "MX"
            },
            {
                "name": "Cuajinicuilapa",
                "countryCode": "MX"
            },
            {
                "name": "Cualác",
                "countryCode": "MX"
            },
            {
                "name": "Cuanacaxtitlán",
                "countryCode": "MX"
            },
            {
                "name": "Cuatzoquitengo",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtenango",
                "countryCode": "MX"
            },
            {
                "name": "Cuautepec",
                "countryCode": "MX"
            },
            {
                "name": "Cuetzala del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Cumbre de Barranca Honda",
                "countryCode": "MX"
            },
            {
                "name": "Cuonetzingo",
                "countryCode": "MX"
            },
            {
                "name": "Cutzamala de Pinzón",
                "countryCode": "MX"
            },
            {
                "name": "Cuyuxtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Dos Arroyos",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Viejo",
                "countryCode": "MX"
            },
            {
                "name": "El Bejuco",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Coacoyul",
                "countryCode": "MX"
            },
            {
                "name": "El Conchero",
                "countryCode": "MX"
            },
            {
                "name": "El Cortijo",
                "countryCode": "MX"
            },
            {
                "name": "El Cortés",
                "countryCode": "MX"
            },
            {
                "name": "El Durazno",
                "countryCode": "MX"
            },
            {
                "name": "El Embarcadero",
                "countryCode": "MX"
            },
            {
                "name": "El Escondido",
                "countryCode": "MX"
            },
            {
                "name": "El Espinalillo",
                "countryCode": "MX"
            },
            {
                "name": "El Fresno",
                "countryCode": "MX"
            },
            {
                "name": "El Jicaral",
                "countryCode": "MX"
            },
            {
                "name": "El Limón",
                "countryCode": "MX"
            },
            {
                "name": "El Mesón",
                "countryCode": "MX"
            },
            {
                "name": "El Metlapil",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjito",
                "countryCode": "MX"
            },
            {
                "name": "El Ocotito",
                "countryCode": "MX"
            },
            {
                "name": "El Papayo",
                "countryCode": "MX"
            },
            {
                "name": "El Paraíso",
                "countryCode": "MX"
            },
            {
                "name": "El Pericón",
                "countryCode": "MX"
            },
            {
                "name": "El Pitahayo",
                "countryCode": "MX"
            },
            {
                "name": "El Polvorín",
                "countryCode": "MX"
            },
            {
                "name": "El Potrerillo (Potrerillo del Rincón)",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón (Santa Cruz del Rincón)",
                "countryCode": "MX"
            },
            {
                "name": "El Salto",
                "countryCode": "MX"
            },
            {
                "name": "El Súchil",
                "countryCode": "MX"
            },
            {
                "name": "El Tejocote",
                "countryCode": "MX"
            },
            {
                "name": "El Terrero",
                "countryCode": "MX"
            },
            {
                "name": "El Ticui",
                "countryCode": "MX"
            },
            {
                "name": "Escalerilla Lagunas",
                "countryCode": "MX"
            },
            {
                "name": "Fermín Rabadán Cervantes",
                "countryCode": "MX"
            },
            {
                "name": "Florencio Villarreal",
                "countryCode": "MX"
            },
            {
                "name": "General Canuto A. Neri",
                "countryCode": "MX"
            },
            {
                "name": "General Heliodoro Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guayameo",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda de Cabañas",
                "countryCode": "MX"
            },
            {
                "name": "Horcasitas",
                "countryCode": "MX"
            },
            {
                "name": "Huamuchapa",
                "countryCode": "MX"
            },
            {
                "name": "Huamuxtitlán",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetán",
                "countryCode": "MX"
            },
            {
                "name": "Hueycantenango",
                "countryCode": "MX"
            },
            {
                "name": "Hueyitlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Huitziltepec",
                "countryCode": "MX"
            },
            {
                "name": "Huitzuco de los Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "Huixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Iguala de la Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Igualapa",
                "countryCode": "MX"
            },
            {
                "name": "Iliatenco",
                "countryCode": "MX"
            },
            {
                "name": "Ixcamilpa",
                "countryCode": "MX"
            },
            {
                "name": "Ixcapuzalco",
                "countryCode": "MX"
            },
            {
                "name": "Ixcateopan",
                "countryCode": "MX"
            },
            {
                "name": "Ixcateopan de Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Ixcatla",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapa",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapa-Zihuatanejo",
                "countryCode": "MX"
            },
            {
                "name": "Izotepec",
                "countryCode": "MX"
            },
            {
                "name": "Jalapa",
                "countryCode": "MX"
            },
            {
                "name": "Jaleaca de Catalán",
                "countryCode": "MX"
            },
            {
                "name": "Jicayán de Tovar",
                "countryCode": "MX"
            },
            {
                "name": "Jolotichán",
                "countryCode": "MX"
            },
            {
                "name": "Julián Blanco (Dos Caminos)",
                "countryCode": "MX"
            },
            {
                "name": "Kilómetro 30",
                "countryCode": "MX"
            },
            {
                "name": "Kilómetro 40",
                "countryCode": "MX"
            },
            {
                "name": "La Azozuca",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción (La Concha)",
                "countryCode": "MX"
            },
            {
                "name": "La Concordia",
                "countryCode": "MX"
            },
            {
                "name": "La Dicha",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Estación",
                "countryCode": "MX"
            },
            {
                "name": "La Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "La Luz de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "La Mohonera",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "Lagunillas",
                "countryCode": "MX"
            },
            {
                "name": "Las Compuertas",
                "countryCode": "MX"
            },
            {
                "name": "Las Cruces",
                "countryCode": "MX"
            },
            {
                "name": "Las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "Las Mesas",
                "countryCode": "MX"
            },
            {
                "name": "Las Plazuelas",
                "countryCode": "MX"
            },
            {
                "name": "Las Tunas",
                "countryCode": "MX"
            },
            {
                "name": "Las Vigas",
                "countryCode": "MX"
            },
            {
                "name": "Las Ánimas",
                "countryCode": "MX"
            },
            {
                "name": "Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Llano de la Puerta",
                "countryCode": "MX"
            },
            {
                "name": "Lodo Grande",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Chapultepec",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Los Achotes",
                "countryCode": "MX"
            },
            {
                "name": "Los Almendros",
                "countryCode": "MX"
            },
            {
                "name": "Los Liros",
                "countryCode": "MX"
            },
            {
                "name": "Los Mogotes",
                "countryCode": "MX"
            },
            {
                "name": "Los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "Los Saucitos",
                "countryCode": "MX"
            },
            {
                "name": "Los Tepetates",
                "countryCode": "MX"
            },
            {
                "name": "Los Órganos de San Agustín",
                "countryCode": "MX"
            },
            {
                "name": "Malinaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Marquelia",
                "countryCode": "MX"
            },
            {
                "name": "Maxela",
                "countryCode": "MX"
            },
            {
                "name": "Mayanalán",
                "countryCode": "MX"
            },
            {
                "name": "Mazatlán",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Metlapa",
                "countryCode": "MX"
            },
            {
                "name": "Metlatónoc",
                "countryCode": "MX"
            },
            {
                "name": "Mezcala",
                "countryCode": "MX"
            },
            {
                "name": "Mochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Mohoneras",
                "countryCode": "MX"
            },
            {
                "name": "Montecillos",
                "countryCode": "MX"
            },
            {
                "name": "Morelita",
                "countryCode": "MX"
            },
            {
                "name": "Moyotepec (Moyotepec de Juárez)",
                "countryCode": "MX"
            },
            {
                "name": "Nejapa",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Frontera",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Balsas",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Nuxco",
                "countryCode": "MX"
            },
            {
                "name": "Ocotequila",
                "countryCode": "MX"
            },
            {
                "name": "Ocotillo",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Olinalá",
                "countryCode": "MX"
            },
            {
                "name": "Ometepec",
                "countryCode": "MX"
            },
            {
                "name": "Oxtotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Oztotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Paintla",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Palo Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Palos Blancos",
                "countryCode": "MX"
            },
            {
                "name": "Pantitlán",
                "countryCode": "MX"
            },
            {
                "name": "Pantla",
                "countryCode": "MX"
            },
            {
                "name": "Papanoa",
                "countryCode": "MX"
            },
            {
                "name": "Paraje Montero (Paraje Montero de Zaragoza)",
                "countryCode": "MX"
            },
            {
                "name": "Pascala del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Paso Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Arena",
                "countryCode": "MX"
            },
            {
                "name": "Patambó",
                "countryCode": "MX"
            },
            {
                "name": "Patlicha",
                "countryCode": "MX"
            },
            {
                "name": "Petacalco",
                "countryCode": "MX"
            },
            {
                "name": "Petaquillas",
                "countryCode": "MX"
            },
            {
                "name": "Petatlán",
                "countryCode": "MX"
            },
            {
                "name": "Pilcaya",
                "countryCode": "MX"
            },
            {
                "name": "Placeres del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Platanillo",
                "countryCode": "MX"
            },
            {
                "name": "Pochahuizco",
                "countryCode": "MX"
            },
            {
                "name": "Pochotillo",
                "countryCode": "MX"
            },
            {
                "name": "Pochutla",
                "countryCode": "MX"
            },
            {
                "name": "Pololcingo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Madero (El Playón)",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Pungarabato",
                "countryCode": "MX"
            },
            {
                "name": "Quechultenango",
                "countryCode": "MX"
            },
            {
                "name": "Querendas",
                "countryCode": "MX"
            },
            {
                "name": "Quetzalapa",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo (Rancho Nuevo de la Democracia)",
                "countryCode": "MX"
            },
            {
                "name": "Rancho de las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "Sabanillas",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Oapan",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Playa Encantada (El Podrido)",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Coyahuacán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de las Huertas",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Oxtutla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Ozomatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Gallinero (El Gallinero)",
                "countryCode": "MX"
            },
            {
                "name": "San Jeronimito",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San José Ixtapa (Barrio Viejo)",
                "countryCode": "MX"
            },
            {
                "name": "San José Poliutla",
                "countryCode": "MX"
            },
            {
                "name": "San José la Hacienda",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Totolcintla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Dios (Naranjas de Dios)",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Luis San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "San Luis de La Loma",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos",
                "countryCode": "MX"
            },
            {
                "name": "San Martín",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Pachivia (Pachivia)",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Peras",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Axoxuca",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tecuiciapan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Totolapan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Miguelito",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Cacahuatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Cuitlapan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Huitzapula Norte (Huitzapula Norte)",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro las Playas",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Palapa",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Zoyatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Ángel",
                "countryCode": "MX"
            },
            {
                "name": "Santa Anita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Yucucani",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fe Tepetlapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tilapa",
                "countryCode": "MX"
            },
            {
                "name": "Sinahua",
                "countryCode": "MX"
            },
            {
                "name": "Tanganhuato",
                "countryCode": "MX"
            },
            {
                "name": "Taxco el Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Tecalpulco",
                "countryCode": "MX"
            },
            {
                "name": "Tecolcuautla",
                "countryCode": "MX"
            },
            {
                "name": "Tecuexcontitlán (Tecoescontitlán)",
                "countryCode": "MX"
            },
            {
                "name": "Tehuilotepec",
                "countryCode": "MX"
            },
            {
                "name": "Tehuixtla",
                "countryCode": "MX"
            },
            {
                "name": "Teloloapan",
                "countryCode": "MX"
            },
            {
                "name": "Tenexpa",
                "countryCode": "MX"
            },
            {
                "name": "Tepechicotlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepecoacuilco de Trujano",
                "countryCode": "MX"
            },
            {
                "name": "Tepetixtla",
                "countryCode": "MX"
            },
            {
                "name": "Tepozcuautla",
                "countryCode": "MX"
            },
            {
                "name": "Tequicuilco",
                "countryCode": "MX"
            },
            {
                "name": "Tetipac",
                "countryCode": "MX"
            },
            {
                "name": "Tetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Texca",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Colorada",
                "countryCode": "MX"
            },
            {
                "name": "Tixtla de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Tixtlancingo",
                "countryCode": "MX"
            },
            {
                "name": "Tlacoachistlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Tlacoapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlacoaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlalchapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlalcozotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tlalixtaquilla",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltempanapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlamacazapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlamixtlahuacan",
                "countryCode": "MX"
            },
            {
                "name": "Tlanipatla",
                "countryCode": "MX"
            },
            {
                "name": "Tlapa de Comonfort",
                "countryCode": "MX"
            },
            {
                "name": "Tlapehuala",
                "countryCode": "MX"
            },
            {
                "name": "Tlaquiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlatlauquitepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlatzala",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxcalixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxinga (Clatzinga)",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxmalac",
                "countryCode": "MX"
            },
            {
                "name": "Tonalapa",
                "countryCode": "MX"
            },
            {
                "name": "Tonalapa del Sur",
                "countryCode": "MX"
            },
            {
                "name": "Tonalá",
                "countryCode": "MX"
            },
            {
                "name": "Topiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Totomixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Tototepec",
                "countryCode": "MX"
            },
            {
                "name": "Tres Palos",
                "countryCode": "MX"
            },
            {
                "name": "Tulimán",
                "countryCode": "MX"
            },
            {
                "name": "Tuncingo",
                "countryCode": "MX"
            },
            {
                "name": "Tutepec",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpan",
                "countryCode": "MX"
            },
            {
                "name": "Técpan de Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Valle Luz",
                "countryCode": "MX"
            },
            {
                "name": "Valle del Río",
                "countryCode": "MX"
            },
            {
                "name": "Vallecitos de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hermosa (Las Pozas)",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo (El Cubo)",
                "countryCode": "MX"
            },
            {
                "name": "Villa Madero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Rotaria",
                "countryCode": "MX"
            },
            {
                "name": "Xalatzala",
                "countryCode": "MX"
            },
            {
                "name": "Xalitla",
                "countryCode": "MX"
            },
            {
                "name": "Xalpatlahuac",
                "countryCode": "MX"
            },
            {
                "name": "Xaltianguis",
                "countryCode": "MX"
            },
            {
                "name": "Xochapa",
                "countryCode": "MX"
            },
            {
                "name": "Xochihuehuetlán",
                "countryCode": "MX"
            },
            {
                "name": "Xochipala",
                "countryCode": "MX"
            },
            {
                "name": "Xochistlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Xochitepec",
                "countryCode": "MX"
            },
            {
                "name": "Xocoyolzintla",
                "countryCode": "MX"
            },
            {
                "name": "Yetla",
                "countryCode": "MX"
            },
            {
                "name": "Yetlancingo",
                "countryCode": "MX"
            },
            {
                "name": "Yextla",
                "countryCode": "MX"
            },
            {
                "name": "Yoloxóchitl",
                "countryCode": "MX"
            },
            {
                "name": "Zacacoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Zacapuato",
                "countryCode": "MX"
            },
            {
                "name": "Zacatula",
                "countryCode": "MX"
            },
            {
                "name": "Zacoalpan",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitlán Tablas",
                "countryCode": "MX"
            },
            {
                "name": "Zelocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zilacayotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zirándaro",
                "countryCode": "MX"
            },
            {
                "name": "Zirándaro de los Chávez",
                "countryCode": "MX"
            },
            {
                "name": "Zitlala",
                "countryCode": "MX"
            },
            {
                "name": "Zizicazapa",
                "countryCode": "MX"
            },
            {
                "name": "Zoquiapa",
                "countryCode": "MX"
            },
            {
                "name": "Zoquitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zotoltitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zoyatlán",
                "countryCode": "MX"
            },
            {
                "name": "Zumpango",
                "countryCode": "MX"
            },
            {
                "name": "Zumpango del Río",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "Acámbaro",
                "countryCode": "MX"
            },
            {
                "name": "Adjuntas del Río",
                "countryCode": "MX"
            },
            {
                "name": "Aguas Buenas",
                "countryCode": "MX"
            },
            {
                "name": "Alcocer",
                "countryCode": "MX"
            },
            {
                "name": "Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Alfaro",
                "countryCode": "MX"
            },
            {
                "name": "Ameche",
                "countryCode": "MX"
            },
            {
                "name": "Apaseo el Alto",
                "countryCode": "MX"
            },
            {
                "name": "Apaseo el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Arandas",
                "countryCode": "MX"
            },
            {
                "name": "Atarjea",
                "countryCode": "MX"
            },
            {
                "name": "Bajío de Bonillas",
                "countryCode": "MX"
            },
            {
                "name": "Barretos",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Guadalupe del Mezquitillo",
                "countryCode": "MX"
            },
            {
                "name": "Barrón",
                "countryCode": "MX"
            },
            {
                "name": "Boquillas",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Cortés",
                "countryCode": "MX"
            },
            {
                "name": "Caleras de Ameche",
                "countryCode": "MX"
            },
            {
                "name": "Calzada del Tepozán",
                "countryCode": "MX"
            },
            {
                "name": "Capulín de Bustos",
                "countryCode": "MX"
            },
            {
                "name": "Carrizal Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Bustos",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Caracheo",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Negros",
                "countryCode": "MX"
            },
            {
                "name": "Celaya",
                "countryCode": "MX"
            },
            {
                "name": "Centro Familiar la Soledad",
                "countryCode": "MX"
            },
            {
                "name": "Cerano",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de Gasca",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Chamácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Charco de Pantoja",
                "countryCode": "MX"
            },
            {
                "name": "Chichimequillas",
                "countryCode": "MX"
            },
            {
                "name": "Chirimoya (Estación Chirimoya)",
                "countryCode": "MX"
            },
            {
                "name": "Churipitzeo",
                "countryCode": "MX"
            },
            {
                "name": "Cieneguilla",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Manuel Doblado",
                "countryCode": "MX"
            },
            {
                "name": "Coachiti",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 18 de Marzo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Fraccionamiento el Puente",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Francisco Javier Mina",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Latinoamericana",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Morelos de Guadalupe de Rivera",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Nuevo México",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Padre Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Patria Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Pedro María Anaya",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Rafael Corrales Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Luis Rey",
                "countryCode": "MX"
            },
            {
                "name": "Colonia la Calzada (La Olla)",
                "countryCode": "MX"
            },
            {
                "name": "Comanjilla",
                "countryCode": "MX"
            },
            {
                "name": "Comonfort",
                "countryCode": "MX"
            },
            {
                "name": "Coroneo",
                "countryCode": "MX"
            },
            {
                "name": "Corral de Piedras de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Corralejo de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Cortazar",
                "countryCode": "MX"
            },
            {
                "name": "Cuarta Brigada",
                "countryCode": "MX"
            },
            {
                "name": "Cuchicuato",
                "countryCode": "MX"
            },
            {
                "name": "Cuerámaro",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta de Peñones",
                "countryCode": "MX"
            },
            {
                "name": "Cupareo",
                "countryCode": "MX"
            },
            {
                "name": "Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "De Parral",
                "countryCode": "MX"
            },
            {
                "name": "Delgado de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Delgado de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Derramadero Segundo (Infiernillo)",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Mora",
                "countryCode": "MX"
            },
            {
                "name": "Dolores Hidalgo Cuna de la Independencia Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Don Diego",
                "countryCode": "MX"
            },
            {
                "name": "Don Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Duarte",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Ejido la Joya",
                "countryCode": "MX"
            },
            {
                "name": "El Acebuche",
                "countryCode": "MX"
            },
            {
                "name": "El CERESO",
                "countryCode": "MX"
            },
            {
                "name": "El Canario",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín",
                "countryCode": "MX"
            },
            {
                "name": "El Caracol",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Carretón",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizalito",
                "countryCode": "MX"
            },
            {
                "name": "El Castillo",
                "countryCode": "MX"
            },
            {
                "name": "El Chinaco (El Pujido)",
                "countryCode": "MX"
            },
            {
                "name": "El Coecillo",
                "countryCode": "MX"
            },
            {
                "name": "El Copalillo",
                "countryCode": "MX"
            },
            {
                "name": "El Divisador",
                "countryCode": "MX"
            },
            {
                "name": "El Escoplo",
                "countryCode": "MX"
            },
            {
                "name": "El Espejo",
                "countryCode": "MX"
            },
            {
                "name": "El Fresno",
                "countryCode": "MX"
            },
            {
                "name": "El Gallinero",
                "countryCode": "MX"
            },
            {
                "name": "El Huizache",
                "countryCode": "MX"
            },
            {
                "name": "El Jaguey",
                "countryCode": "MX"
            },
            {
                "name": "El Llanito",
                "countryCode": "MX"
            },
            {
                "name": "El Maguey",
                "countryCode": "MX"
            },
            {
                "name": "El Mezquitillo",
                "countryCode": "MX"
            },
            {
                "name": "El Moral",
                "countryCode": "MX"
            },
            {
                "name": "El Nacimiento",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjillo",
                "countryCode": "MX"
            },
            {
                "name": "El Picacho",
                "countryCode": "MX"
            },
            {
                "name": "El Puesto",
                "countryCode": "MX"
            },
            {
                "name": "El Recuerdo de Ancón (Xoconoxtle de Arriba)",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio de los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "El Sabino",
                "countryCode": "MX"
            },
            {
                "name": "El Salto de Espejo",
                "countryCode": "MX"
            },
            {
                "name": "El Salvador (Ranchito San José del Carmen)",
                "countryCode": "MX"
            },
            {
                "name": "El Sauz (El Sauz de Villaseñor)",
                "countryCode": "MX"
            },
            {
                "name": "El Tecolote",
                "countryCode": "MX"
            },
            {
                "name": "El Tejocote (El Domingo)",
                "countryCode": "MX"
            },
            {
                "name": "El Tunal",
                "countryCode": "MX"
            },
            {
                "name": "El Varal",
                "countryCode": "MX"
            },
            {
                "name": "El Vicarlo",
                "countryCode": "MX"
            },
            {
                "name": "El Zapote",
                "countryCode": "MX"
            },
            {
                "name": "Empalme Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Estación Corralejo",
                "countryCode": "MX"
            },
            {
                "name": "Estación Joaquín",
                "countryCode": "MX"
            },
            {
                "name": "Estación Pénjamo",
                "countryCode": "MX"
            },
            {
                "name": "Estación de San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Estación la Piedad",
                "countryCode": "MX"
            },
            {
                "name": "Estancia del Llano",
                "countryCode": "MX"
            },
            {
                "name": "Estanzuela de Romero",
                "countryCode": "MX"
            },
            {
                "name": "Estrada",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Hacienda del Copal",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Paraíso Real",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Paseo de las Torres",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Praderas de la Venta",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Villa Jardín",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Villas de Guanajuato",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento la Mezquitera",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento las Liebres",
                "countryCode": "MX"
            },
            {
                "name": "Franco",
                "countryCode": "MX"
            },
            {
                "name": "Franco Tavera",
                "countryCode": "MX"
            },
            {
                "name": "Frías",
                "countryCode": "MX"
            },
            {
                "name": "Fábrica de Melchor",
                "countryCode": "MX"
            },
            {
                "name": "Gachupines",
                "countryCode": "MX"
            },
            {
                "name": "Galerade Panales",
                "countryCode": "MX"
            },
            {
                "name": "Gasca",
                "countryCode": "MX"
            },
            {
                "name": "Gavia de Rionda",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe de Paso Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe de Rivera",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe de Tambula",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Guanajuato",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda de Márquez",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda de la Bolsa",
                "countryCode": "MX"
            },
            {
                "name": "Hoya de Cintora (La Hoya de Arriba)",
                "countryCode": "MX"
            },
            {
                "name": "Huanímaro",
                "countryCode": "MX"
            },
            {
                "name": "Huitzatarito",
                "countryCode": "MX"
            },
            {
                "name": "Ibarrilla",
                "countryCode": "MX"
            },
            {
                "name": "Irapuato",
                "countryCode": "MX"
            },
            {
                "name": "Irámuco",
                "countryCode": "MX"
            },
            {
                "name": "Jalpilla",
                "countryCode": "MX"
            },
            {
                "name": "Jamaica",
                "countryCode": "MX"
            },
            {
                "name": "Jaral del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Jauregui",
                "countryCode": "MX"
            },
            {
                "name": "Jerécuaro",
                "countryCode": "MX"
            },
            {
                "name": "Jesús del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Jocoqui",
                "countryCode": "MX"
            },
            {
                "name": "Juan Martín",
                "countryCode": "MX"
            },
            {
                "name": "Juventino Rosas",
                "countryCode": "MX"
            },
            {
                "name": "Jícamas",
                "countryCode": "MX"
            },
            {
                "name": "La Aldea",
                "countryCode": "MX"
            },
            {
                "name": "La Angostura",
                "countryCode": "MX"
            },
            {
                "name": "La Aurora",
                "countryCode": "MX"
            },
            {
                "name": "La Caja",
                "countryCode": "MX"
            },
            {
                "name": "La Calera",
                "countryCode": "MX"
            },
            {
                "name": "La Capilla",
                "countryCode": "MX"
            },
            {
                "name": "La Cieneguita",
                "countryCode": "MX"
            },
            {
                "name": "La Ciénega",
                "countryCode": "MX"
            },
            {
                "name": "La Compañía",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz del Palmar",
                "countryCode": "MX"
            },
            {
                "name": "La Cuevita",
                "countryCode": "MX"
            },
            {
                "name": "La Ermita",
                "countryCode": "MX"
            },
            {
                "name": "La Escondida",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia de San José del Carmen",
                "countryCode": "MX"
            },
            {
                "name": "La Estrella",
                "countryCode": "MX"
            },
            {
                "name": "La Gavia",
                "countryCode": "MX"
            },
            {
                "name": "La Joya de Calvillo",
                "countryCode": "MX"
            },
            {
                "name": "La Labor",
                "countryCode": "MX"
            },
            {
                "name": "La Laborcita",
                "countryCode": "MX"
            },
            {
                "name": "La Laja",
                "countryCode": "MX"
            },
            {
                "name": "La Lobera",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Moncada",
                "countryCode": "MX"
            },
            {
                "name": "La Norita",
                "countryCode": "MX"
            },
            {
                "name": "La Ordeña",
                "countryCode": "MX"
            },
            {
                "name": "La Ortiga",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Palmita (La Palmita de San Gabriel)",
                "countryCode": "MX"
            },
            {
                "name": "La Purísima",
                "countryCode": "MX"
            },
            {
                "name": "La Sabana",
                "countryCode": "MX"
            },
            {
                "name": "La Sardina",
                "countryCode": "MX"
            },
            {
                "name": "La Sauceda",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Tinaja",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "Labor de Peralta",
                "countryCode": "MX"
            },
            {
                "name": "Ladrilleras del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Laguna Larga",
                "countryCode": "MX"
            },
            {
                "name": "Laguna Larga de Cortés",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Landín",
                "countryCode": "MX"
            },
            {
                "name": "Las Cañas",
                "countryCode": "MX"
            },
            {
                "name": "Las Huertas Tercera Sección",
                "countryCode": "MX"
            },
            {
                "name": "Las Liebres",
                "countryCode": "MX"
            },
            {
                "name": "Las Masas (La Luz Masas)",
                "countryCode": "MX"
            },
            {
                "name": "Las Trojas",
                "countryCode": "MX"
            },
            {
                "name": "Las Ánimas",
                "countryCode": "MX"
            },
            {
                "name": "León",
                "countryCode": "MX"
            },
            {
                "name": "León de los Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Lo de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Loma Pelada",
                "countryCode": "MX"
            },
            {
                "name": "Loma Tendida",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Yerbabuena",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Zempoala",
                "countryCode": "MX"
            },
            {
                "name": "Loma de la Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Los Arcos",
                "countryCode": "MX"
            },
            {
                "name": "Los Desmontes",
                "countryCode": "MX"
            },
            {
                "name": "Los Dolores (Las Quince Letras)",
                "countryCode": "MX"
            },
            {
                "name": "Los Dulces Nombres",
                "countryCode": "MX"
            },
            {
                "name": "Los Fierros",
                "countryCode": "MX"
            },
            {
                "name": "Los Galvan",
                "countryCode": "MX"
            },
            {
                "name": "Los Lorenzos",
                "countryCode": "MX"
            },
            {
                "name": "Los Mancera",
                "countryCode": "MX"
            },
            {
                "name": "Los Medranos",
                "countryCode": "MX"
            },
            {
                "name": "Los Nicolases",
                "countryCode": "MX"
            },
            {
                "name": "Los Ocotes",
                "countryCode": "MX"
            },
            {
                "name": "Los Prietos",
                "countryCode": "MX"
            },
            {
                "name": "Los Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Los Remedios",
                "countryCode": "MX"
            },
            {
                "name": "Los Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Loza de Barrera",
                "countryCode": "MX"
            },
            {
                "name": "Loza de los Padres",
                "countryCode": "MX"
            },
            {
                "name": "Lucio Blanco (Los Gavilanes)",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena de Araceo",
                "countryCode": "MX"
            },
            {
                "name": "Malagana (San Antonio del Monte)",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Doblado",
                "countryCode": "MX"
            },
            {
                "name": "Maravatío del Encinal",
                "countryCode": "MX"
            },
            {
                "name": "Marfil",
                "countryCode": "MX"
            },
            {
                "name": "Marroquín",
                "countryCode": "MX"
            },
            {
                "name": "Medina",
                "countryCode": "MX"
            },
            {
                "name": "Medio Sitio",
                "countryCode": "MX"
            },
            {
                "name": "Menores",
                "countryCode": "MX"
            },
            {
                "name": "Merino",
                "countryCode": "MX"
            },
            {
                "name": "Mexicanos",
                "countryCode": "MX"
            },
            {
                "name": "Mezquite Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Mezquite de Luna",
                "countryCode": "MX"
            },
            {
                "name": "Mezquite de Sotelo",
                "countryCode": "MX"
            },
            {
                "name": "Mineral de la Luz",
                "countryCode": "MX"
            },
            {
                "name": "Misión de Chichimecas",
                "countryCode": "MX"
            },
            {
                "name": "Molino de San José",
                "countryCode": "MX"
            },
            {
                "name": "Molino de Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Monte Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Monte Grande",
                "countryCode": "MX"
            },
            {
                "name": "Monte del Coecillo",
                "countryCode": "MX"
            },
            {
                "name": "Morales",
                "countryCode": "MX"
            },
            {
                "name": "Moroleón",
                "countryCode": "MX"
            },
            {
                "name": "Neutla",
                "countryCode": "MX"
            },
            {
                "name": "Nigromante",
                "countryCode": "MX"
            },
            {
                "name": "Obrajuelo",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Ojo Seco",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Ballesteros",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de la Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Orduña de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Orduña de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Otates",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas de San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Palo Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Panales Jamaica (Cañones)",
                "countryCode": "MX"
            },
            {
                "name": "Parangarico",
                "countryCode": "MX"
            },
            {
                "name": "Parácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Pirules",
                "countryCode": "MX"
            },
            {
                "name": "Paxtle",
                "countryCode": "MX"
            },
            {
                "name": "Peralta",
                "countryCode": "MX"
            },
            {
                "name": "Peñuelas",
                "countryCode": "MX"
            },
            {
                "name": "Piedras Negras",
                "countryCode": "MX"
            },
            {
                "name": "Piedras de Lumbre",
                "countryCode": "MX"
            },
            {
                "name": "Piñícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Plan Guanajuato (La Sandía)",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Plancarte",
                "countryCode": "MX"
            },
            {
                "name": "Potrerillos (Guanajal)",
                "countryCode": "MX"
            },
            {
                "name": "Pozos",
                "countryCode": "MX"
            },
            {
                "name": "Prados del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Presa Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Primera Fracción de Crespo",
                "countryCode": "MX"
            },
            {
                "name": "Providencia",
                "countryCode": "MX"
            },
            {
                "name": "Providencia de Nápoles",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Puentecillas",
                "countryCode": "MX"
            },
            {
                "name": "Puerta del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Puroagua",
                "countryCode": "MX"
            },
            {
                "name": "Purísima de Bustos",
                "countryCode": "MX"
            },
            {
                "name": "Purísima de Covarrubias",
                "countryCode": "MX"
            },
            {
                "name": "Purísima del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Purísima del Rincón",
                "countryCode": "MX"
            },
            {
                "name": "Pénjamo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo de la Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo de la Luz",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo del Llanito",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Rinconadas del Bosque",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Cano",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Parangueo",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Tamayo",
                "countryCode": "MX"
            },
            {
                "name": "Rincón del Centeno",
                "countryCode": "MX"
            },
            {
                "name": "Rizos de la Joya (Rizos del Saucillo)",
                "countryCode": "MX"
            },
            {
                "name": "Romita",
                "countryCode": "MX"
            },
            {
                "name": "Roque",
                "countryCode": "MX"
            },
            {
                "name": "Río Laja",
                "countryCode": "MX"
            },
            {
                "name": "Salamanca",
                "countryCode": "MX"
            },
            {
                "name": "Salitrillo",
                "countryCode": "MX"
            },
            {
                "name": "Salvatierra",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Enguaro",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Calichar",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Eménguaro",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Gallardo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Primero",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Texas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de Corrales",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de Romerillo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Morales",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Tepetates",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio el Chico",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio el Rico",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo de Berrios",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Aguas Calientes",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo Peña Blanca",
                "countryCode": "MX"
            },
            {
                "name": "San Cayetano",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Diego de Alcalá",
                "countryCode": "MX"
            },
            {
                "name": "San Diego de la Unión",
                "countryCode": "MX"
            },
            {
                "name": "San Elías",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Quiriceo",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco (Baños de Agua Caliente)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Durán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco del Rincón",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel y San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio de Rivera (Ojo de Agua)",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio de San José Parangueo",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro de Gamboa",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro de la Concepción",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro de la Estacada",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro de los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "San Javier",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo de Araceo",
                "countryCode": "MX"
            },
            {
                "name": "San José Iturbide",
                "countryCode": "MX"
            },
            {
                "name": "San José Temascatío",
                "countryCode": "MX"
            },
            {
                "name": "San José Viborillas",
                "countryCode": "MX"
            },
            {
                "name": "San José de Agua Azul",
                "countryCode": "MX"
            },
            {
                "name": "San José de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "San José de Bernalejo (El Guayabo)",
                "countryCode": "MX"
            },
            {
                "name": "San José de Cervera",
                "countryCode": "MX"
            },
            {
                "name": "San José de Durán (Los Troncoso)",
                "countryCode": "MX"
            },
            {
                "name": "San José de Guanajuato",
                "countryCode": "MX"
            },
            {
                "name": "San José de Llanos",
                "countryCode": "MX"
            },
            {
                "name": "San José de Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "San José de Rancho Nuevo (Los Arrieros)",
                "countryCode": "MX"
            },
            {
                "name": "San José de la Montaña",
                "countryCode": "MX"
            },
            {
                "name": "San José de las Pilas",
                "countryCode": "MX"
            },
            {
                "name": "San José del Cerrito de Camargo",
                "countryCode": "MX"
            },
            {
                "name": "San José del Potrero",
                "countryCode": "MX"
            },
            {
                "name": "San José del Rodeo",
                "countryCode": "MX"
            },
            {
                "name": "San José del Torreón",
                "countryCode": "MX"
            },
            {
                "name": "San José el Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Bautista Cacalote",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Jaripeo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Pan de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Otates",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de la Vega",
                "countryCode": "MX"
            },
            {
                "name": "San Juan del Llanito",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas",
                "countryCode": "MX"
            },
            {
                "name": "San Luis de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Eménguaro",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Octopan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de Allende",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Arenal",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Parangueo",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Temascatío",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de la Condesa",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de los Agustinos",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás del Cármen",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Pejo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tenango",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de Ibarra",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de los Naranjos",
                "countryCode": "MX"
            },
            {
                "name": "San Roque",
                "countryCode": "MX"
            },
            {
                "name": "San Roque de Montes",
                "countryCode": "MX"
            },
            {
                "name": "San Roque de Torres",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador Torrecillas",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián de Salitre",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente de Flores",
                "countryCode": "MX"
            },
            {
                "name": "San Ángel",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Pacueco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Anita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de Juventino Rosas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fe de la Purísima",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa de Rivas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa los Angeles",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresita de Don Diego",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Capitiro",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Maravatío",
                "countryCode": "MX"
            },
            {
                "name": "Santiago de Cuenda",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Huatzindeo",
                "countryCode": "MX"
            },
            {
                "name": "Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Serrano",
                "countryCode": "MX"
            },
            {
                "name": "Silao",
                "countryCode": "MX"
            },
            {
                "name": "Silao de la Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Silva",
                "countryCode": "MX"
            },
            {
                "name": "Soledad Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Sotelo",
                "countryCode": "MX"
            },
            {
                "name": "Suchitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tamazula",
                "countryCode": "MX"
            },
            {
                "name": "Tarandacuao",
                "countryCode": "MX"
            },
            {
                "name": "Taretán",
                "countryCode": "MX"
            },
            {
                "name": "Tarimoro",
                "countryCode": "MX"
            },
            {
                "name": "Tejocote de Calera",
                "countryCode": "MX"
            },
            {
                "name": "Tenango el Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Tenería del Santuario",
                "countryCode": "MX"
            },
            {
                "name": "Tequisquiapan",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Fría",
                "countryCode": "MX"
            },
            {
                "name": "Tomelopitos",
                "countryCode": "MX"
            },
            {
                "name": "Tulillos de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Tupataro",
                "countryCode": "MX"
            },
            {
                "name": "Uriangato",
                "countryCode": "MX"
            },
            {
                "name": "Urireo",
                "countryCode": "MX"
            },
            {
                "name": "Valencia de Cerro Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Valencianita",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Valtierrilla",
                "countryCode": "MX"
            },
            {
                "name": "Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Victoria de Cortazar",
                "countryCode": "MX"
            },
            {
                "name": "Villagrán",
                "countryCode": "MX"
            },
            {
                "name": "Villas de Irapuato",
                "countryCode": "MX"
            },
            {
                "name": "Xichú",
                "countryCode": "MX"
            },
            {
                "name": "Xoconoxtle el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Yerbabuena",
                "countryCode": "MX"
            },
            {
                "name": "Yuriria",
                "countryCode": "MX"
            },
            {
                "name": "Yustis",
                "countryCode": "MX"
            },
            {
                "name": "Zangarro (Zangarro Nuevo)",
                "countryCode": "MX"
            },
            {
                "name": "Zapote de Barajas",
                "countryCode": "MX"
            },
            {
                "name": "Zapote de Cestao",
                "countryCode": "MX"
            },
            {
                "name": "Zapote de Palomas",
                "countryCode": "MX"
            },
            {
                "name": "Zapote de Peralta",
                "countryCode": "MX"
            },
            {
                "name": "Zapotillo de Mogotes",
                "countryCode": "MX"
            },
            {
                "name": "Acahuasco",
                "countryCode": "MX"
            },
            {
                "name": "Acatepec",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "Acaxochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Acayuca",
                "countryCode": "MX"
            },
            {
                "name": "Acoapa",
                "countryCode": "MX"
            },
            {
                "name": "Acoxcatlán",
                "countryCode": "MX"
            },
            {
                "name": "Acoyotla",
                "countryCode": "MX"
            },
            {
                "name": "Actopan",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatitla",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehuetitla",
                "countryCode": "MX"
            },
            {
                "name": "Ajacuba",
                "countryCode": "MX"
            },
            {
                "name": "Alfajayucan",
                "countryCode": "MX"
            },
            {
                "name": "Almoloya",
                "countryCode": "MX"
            },
            {
                "name": "Apan",
                "countryCode": "MX"
            },
            {
                "name": "Atengo",
                "countryCode": "MX"
            },
            {
                "name": "Atitalaquia",
                "countryCode": "MX"
            },
            {
                "name": "Atlapexco",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco de Tula",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Bangandhó",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Aztlán",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Bomanxotha",
                "countryCode": "MX"
            },
            {
                "name": "Bomintzha",
                "countryCode": "MX"
            },
            {
                "name": "Bondojito",
                "countryCode": "MX"
            },
            {
                "name": "Calnali",
                "countryCode": "MX"
            },
            {
                "name": "Caltimacan",
                "countryCode": "MX"
            },
            {
                "name": "Camelia (Barrio la Camelia)",
                "countryCode": "MX"
            },
            {
                "name": "Campestre Villas del Álamo",
                "countryCode": "MX"
            },
            {
                "name": "Cantera de Villagrán",
                "countryCode": "MX"
            },
            {
                "name": "Cantinela",
                "countryCode": "MX"
            },
            {
                "name": "Carboneras",
                "countryCode": "MX"
            },
            {
                "name": "Cardonal",
                "countryCode": "MX"
            },
            {
                "name": "Carpinteros",
                "countryCode": "MX"
            },
            {
                "name": "Caxuxi",
                "countryCode": "MX"
            },
            {
                "name": "Cañada",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Chachahuantla",
                "countryCode": "MX"
            },
            {
                "name": "Chalahuiyapa",
                "countryCode": "MX"
            },
            {
                "name": "Chapantongo",
                "countryCode": "MX"
            },
            {
                "name": "Chapulhuacanito",
                "countryCode": "MX"
            },
            {
                "name": "Chapulhuacán",
                "countryCode": "MX"
            },
            {
                "name": "Chatipán",
                "countryCode": "MX"
            },
            {
                "name": "Chicavasco",
                "countryCode": "MX"
            },
            {
                "name": "Chichatla",
                "countryCode": "MX"
            },
            {
                "name": "Chilcuautla",
                "countryCode": "MX"
            },
            {
                "name": "Chililico",
                "countryCode": "MX"
            },
            {
                "name": "Chimalapa",
                "countryCode": "MX"
            },
            {
                "name": "Chimalpa y Tlalayote",
                "countryCode": "MX"
            },
            {
                "name": "Cipreses",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Sahagun",
                "countryCode": "MX"
            },
            {
                "name": "Coacuilco",
                "countryCode": "MX"
            },
            {
                "name": "Colinas de Plata",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 28 de Mayo (Santa Rosa)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Felipe Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Militar",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Morelos (El Nueve)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Sesenta y Dos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Teñhe",
                "countryCode": "MX"
            },
            {
                "name": "Conejos",
                "countryCode": "MX"
            },
            {
                "name": "Coyotillos",
                "countryCode": "MX"
            },
            {
                "name": "Cuapaxtitla",
                "countryCode": "MX"
            },
            {
                "name": "Cuatolol",
                "countryCode": "MX"
            },
            {
                "name": "Cuautepec de Hinojosa",
                "countryCode": "MX"
            },
            {
                "name": "Cuazahuatl",
                "countryCode": "MX"
            },
            {
                "name": "Dajiedi",
                "countryCode": "MX"
            },
            {
                "name": "Dantzibojay",
                "countryCode": "MX"
            },
            {
                "name": "Daxtha",
                "countryCode": "MX"
            },
            {
                "name": "Dengantzha",
                "countryCode": "MX"
            },
            {
                "name": "Dios Padre",
                "countryCode": "MX"
            },
            {
                "name": "Don Antonio",
                "countryCode": "MX"
            },
            {
                "name": "Doxey",
                "countryCode": "MX"
            },
            {
                "name": "Déxtho de Victoria",
                "countryCode": "MX"
            },
            {
                "name": "El Arenal",
                "countryCode": "MX"
            },
            {
                "name": "El Barrido",
                "countryCode": "MX"
            },
            {
                "name": "El Bingú",
                "countryCode": "MX"
            },
            {
                "name": "El Boxtha",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín",
                "countryCode": "MX"
            },
            {
                "name": "El Cid",
                "countryCode": "MX"
            },
            {
                "name": "El Colorado",
                "countryCode": "MX"
            },
            {
                "name": "El Huaxtho",
                "countryCode": "MX"
            },
            {
                "name": "El Huixmí",
                "countryCode": "MX"
            },
            {
                "name": "El Jagüey",
                "countryCode": "MX"
            },
            {
                "name": "El Jiadi",
                "countryCode": "MX"
            },
            {
                "name": "El Llano",
                "countryCode": "MX"
            },
            {
                "name": "El Mirador",
                "countryCode": "MX"
            },
            {
                "name": "El Moreno (San Miguel Moreno)",
                "countryCode": "MX"
            },
            {
                "name": "El Nith",
                "countryCode": "MX"
            },
            {
                "name": "El Olvera",
                "countryCode": "MX"
            },
            {
                "name": "El Paredón",
                "countryCode": "MX"
            },
            {
                "name": "El Pintor",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón",
                "countryCode": "MX"
            },
            {
                "name": "El Roble",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Saucillo",
                "countryCode": "MX"
            },
            {
                "name": "El Saucillo (Fraccionamiento)",
                "countryCode": "MX"
            },
            {
                "name": "El Susto",
                "countryCode": "MX"
            },
            {
                "name": "El Tepeyac",
                "countryCode": "MX"
            },
            {
                "name": "El Tephé",
                "countryCode": "MX"
            },
            {
                "name": "El Venado",
                "countryCode": "MX"
            },
            {
                "name": "Eloxochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Epazoyucan",
                "countryCode": "MX"
            },
            {
                "name": "Estación de Apulco",
                "countryCode": "MX"
            },
            {
                "name": "Fontezuelas",
                "countryCode": "MX"
            },
            {
                "name": "Forjadores de Pachuca",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Carlos Salinas de Gortari",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento del Magisterio Tulancinguense",
                "countryCode": "MX"
            },
            {
                "name": "Gandhó",
                "countryCode": "MX"
            },
            {
                "name": "General Felipe Ángeles (Los Ángeles)",
                "countryCode": "MX"
            },
            {
                "name": "General Pedro María Anaya",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Minerva",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Hualula",
                "countryCode": "MX"
            },
            {
                "name": "Huasca de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Huautla",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetla",
                "countryCode": "MX"
            },
            {
                "name": "Huejutla de Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Hueyapa",
                "countryCode": "MX"
            },
            {
                "name": "Huichapan",
                "countryCode": "MX"
            },
            {
                "name": "Huitel",
                "countryCode": "MX"
            },
            {
                "name": "Huitepec",
                "countryCode": "MX"
            },
            {
                "name": "Huitzila",
                "countryCode": "MX"
            },
            {
                "name": "Huitzitzilingo",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Irolo",
                "countryCode": "MX"
            },
            {
                "name": "Ixcatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ixcuinquitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "Ixmiquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Jacala",
                "countryCode": "MX"
            },
            {
                "name": "Jagüey Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Jagüey de Téllez (Estación Téllez)",
                "countryCode": "MX"
            },
            {
                "name": "Jalpa",
                "countryCode": "MX"
            },
            {
                "name": "Jaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Jaltocan",
                "countryCode": "MX"
            },
            {
                "name": "Javier Rojo Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Jonacapa",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos (San José)",
                "countryCode": "MX"
            },
            {
                "name": "José María Pino Suárez",
                "countryCode": "MX"
            },
            {
                "name": "Julián Villagrán",
                "countryCode": "MX"
            },
            {
                "name": "La Amistad",
                "countryCode": "MX"
            },
            {
                "name": "La Boveda",
                "countryCode": "MX"
            },
            {
                "name": "La Colonia",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Esquina",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia",
                "countryCode": "MX"
            },
            {
                "name": "La Estanzuela",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Lagunilla",
                "countryCode": "MX"
            },
            {
                "name": "La Loma",
                "countryCode": "MX"
            },
            {
                "name": "La Mesilla",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia Siglo XXI",
                "countryCode": "MX"
            },
            {
                "name": "La Reforma",
                "countryCode": "MX"
            },
            {
                "name": "La Sabinita",
                "countryCode": "MX"
            },
            {
                "name": "La Sala (La Caliente)",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "Lagunilla",
                "countryCode": "MX"
            },
            {
                "name": "Lindavista",
                "countryCode": "MX"
            },
            {
                "name": "Llano Largo",
                "countryCode": "MX"
            },
            {
                "name": "Lolotla",
                "countryCode": "MX"
            },
            {
                "name": "Los Cides",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Los Romeros",
                "countryCode": "MX"
            },
            {
                "name": "Los Sabinos",
                "countryCode": "MX"
            },
            {
                "name": "Los Tuzos",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Machetla",
                "countryCode": "MX"
            },
            {
                "name": "Magisterio Digno",
                "countryCode": "MX"
            },
            {
                "name": "Maguey Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Mamithi",
                "countryCode": "MX"
            },
            {
                "name": "Mangas",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Ávila Camacho",
                "countryCode": "MX"
            },
            {
                "name": "Mecatlán",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Metepec",
                "countryCode": "MX"
            },
            {
                "name": "Metepec Primero",
                "countryCode": "MX"
            },
            {
                "name": "Metztitlán",
                "countryCode": "MX"
            },
            {
                "name": "Mezquititlán",
                "countryCode": "MX"
            },
            {
                "name": "Michimaloya",
                "countryCode": "MX"
            },
            {
                "name": "Mineral del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Mixquiahuala de Juarez",
                "countryCode": "MX"
            },
            {
                "name": "Molango",
                "countryCode": "MX"
            },
            {
                "name": "Monte Alegre",
                "countryCode": "MX"
            },
            {
                "name": "Motovatha",
                "countryCode": "MX"
            },
            {
                "name": "Munitepec de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Nantzha",
                "countryCode": "MX"
            },
            {
                "name": "Nopala de Villagran",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Centro de Población Agrícola el Chacón",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Ohuatipa",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Omitlán de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Oriental de Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Orizabita",
                "countryCode": "MX"
            },
            {
                "name": "Oxeloco",
                "countryCode": "MX"
            },
            {
                "name": "PRI Chacón",
                "countryCode": "MX"
            },
            {
                "name": "Pachiquita",
                "countryCode": "MX"
            },
            {
                "name": "Pachuca de Soto",
                "countryCode": "MX"
            },
            {
                "name": "Pachuquilla",
                "countryCode": "MX"
            },
            {
                "name": "Pahactla",
                "countryCode": "MX"
            },
            {
                "name": "Pahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Panales",
                "countryCode": "MX"
            },
            {
                "name": "Panuaya",
                "countryCode": "MX"
            },
            {
                "name": "Papatlatla",
                "countryCode": "MX"
            },
            {
                "name": "Parque Urbano Napateco",
                "countryCode": "MX"
            },
            {
                "name": "Parque de Poblamiento Solidaridad",
                "countryCode": "MX"
            },
            {
                "name": "Paseos de la Pradera",
                "countryCode": "MX"
            },
            {
                "name": "Patria Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Paxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Pañhé",
                "countryCode": "MX"
            },
            {
                "name": "Pedregal de San José",
                "countryCode": "MX"
            },
            {
                "name": "Pino Suárez",
                "countryCode": "MX"
            },
            {
                "name": "Pisaflores",
                "countryCode": "MX"
            },
            {
                "name": "Portezuelo",
                "countryCode": "MX"
            },
            {
                "name": "Pozuelos",
                "countryCode": "MX"
            },
            {
                "name": "Praderas del Potrero",
                "countryCode": "MX"
            },
            {
                "name": "Presas",
                "countryCode": "MX"
            },
            {
                "name": "Privada del Álamo",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Progreso de Alvaro Obregon",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Residencial Arboledas",
                "countryCode": "MX"
            },
            {
                "name": "Rinconada de los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Rinconadas de San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Rinconadas del Venado I",
                "countryCode": "MX"
            },
            {
                "name": "Rincones de la Hacienda",
                "countryCode": "MX"
            },
            {
                "name": "Rio de la Soledad",
                "countryCode": "MX"
            },
            {
                "name": "Río Seco Puente de Doria",
                "countryCode": "MX"
            },
            {
                "name": "Salitrillo",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Tlaxiaca",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Zapotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés (San Andrés Chichayotla)",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio el Paso",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Ozocalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Tutotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Chacón",
                "countryCode": "MX"
            },
            {
                "name": "San Esteban",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Orizatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Atotonilco",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Azteca",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio Nopala",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Joaquín",
                "countryCode": "MX"
            },
            {
                "name": "San José",
                "countryCode": "MX"
            },
            {
                "name": "San José Atlán",
                "countryCode": "MX"
            },
            {
                "name": "San José Boxay",
                "countryCode": "MX"
            },
            {
                "name": "San José Corral Blanco",
                "countryCode": "MX"
            },
            {
                "name": "San José Ocotillos",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Achichilco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ahuehueco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Hueyapan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Solís",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tepa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tilcuautla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tizahuapán",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Achiotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Sayula",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Tecuhautitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Guaquilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Cerezo (El Cerezo)",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Vindho",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Tecomatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás el Chico",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlachichilco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlaquilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Tenochtitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Ahuehuepan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Azcapotzaltongo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Batha",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Hueytlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Tlachiahualpa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Tzacuala",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Elena Paliseca",
                "countryCode": "MX"
            },
            {
                "name": "Santa Maria Amealco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Maria Macua",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Actipac",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Amajac",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Apaxco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Asunción",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Batha",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ilucan",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nativitas",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Quelites",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Xigui",
                "countryCode": "MX"
            },
            {
                "name": "Santa María la Calera",
                "countryCode": "MX"
            },
            {
                "name": "Santa Mónica",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Úrsula",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Acayutlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Cuaula",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Jaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepeyahualco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tezontlale",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlaltepoxco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlapacoya",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlapanaloya",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlautla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tulantepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago de Anaya",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "MX"
            },
            {
                "name": "Singuilucan",
                "countryCode": "MX"
            },
            {
                "name": "Talol",
                "countryCode": "MX"
            },
            {
                "name": "Tancazahuela",
                "countryCode": "MX"
            },
            {
                "name": "Tasquillo",
                "countryCode": "MX"
            },
            {
                "name": "Taxadho",
                "countryCode": "MX"
            },
            {
                "name": "Teacalco",
                "countryCode": "MX"
            },
            {
                "name": "Tecacahuaco",
                "countryCode": "MX"
            },
            {
                "name": "Tecozautla",
                "countryCode": "MX"
            },
            {
                "name": "Tehuetlán",
                "countryCode": "MX"
            },
            {
                "name": "Teltipán de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Temango",
                "countryCode": "MX"
            },
            {
                "name": "Tenango",
                "countryCode": "MX"
            },
            {
                "name": "Tenango de Doria",
                "countryCode": "MX"
            },
            {
                "name": "Teocalco",
                "countryCode": "MX"
            },
            {
                "name": "Teofani",
                "countryCode": "MX"
            },
            {
                "name": "Tepatepec",
                "countryCode": "MX"
            },
            {
                "name": "Tepeapulco",
                "countryCode": "MX"
            },
            {
                "name": "Tepehuacán de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Tepeitic",
                "countryCode": "MX"
            },
            {
                "name": "Tepeji del Río de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Tepepa",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitla",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepexititla",
                "countryCode": "MX"
            },
            {
                "name": "Tepojaco",
                "countryCode": "MX"
            },
            {
                "name": "Tetepango",
                "countryCode": "MX"
            },
            {
                "name": "Texcaco",
                "countryCode": "MX"
            },
            {
                "name": "Texcaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Texcapa",
                "countryCode": "MX"
            },
            {
                "name": "Texcatepec",
                "countryCode": "MX"
            },
            {
                "name": "Tezapotla",
                "countryCode": "MX"
            },
            {
                "name": "Tezontepec",
                "countryCode": "MX"
            },
            {
                "name": "Tezontepec de Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Tezoquipa",
                "countryCode": "MX"
            },
            {
                "name": "Tianguistengo",
                "countryCode": "MX"
            },
            {
                "name": "Tianguistengo (La Romera)",
                "countryCode": "MX"
            },
            {
                "name": "Tiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Tinajas",
                "countryCode": "MX"
            },
            {
                "name": "Tizayuca",
                "countryCode": "MX"
            },
            {
                "name": "Tlacomulco",
                "countryCode": "MX"
            },
            {
                "name": "Tlacpac",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuelilpan",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuelompa (San Francisco Tlahuelompa)",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuiltepa",
                "countryCode": "MX"
            },
            {
                "name": "Tlalchiyahualica",
                "countryCode": "MX"
            },
            {
                "name": "Tlalminulpa",
                "countryCode": "MX"
            },
            {
                "name": "Tlamaco (San Gerónimo Tlamaco)",
                "countryCode": "MX"
            },
            {
                "name": "Tlanalapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlanchinol",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxcalilla",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxcoapan",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxocoyucan",
                "countryCode": "MX"
            },
            {
                "name": "Tolcayuca",
                "countryCode": "MX"
            },
            {
                "name": "Tula de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Tulancingo",
                "countryCode": "MX"
            },
            {
                "name": "Tulancingo de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Tulipanes",
                "countryCode": "MX"
            },
            {
                "name": "Tunititlán",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional Antonio Osorio de León (Bojay)",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Minera 11 de Julio",
                "countryCode": "MX"
            },
            {
                "name": "Unidades Habitacionales",
                "countryCode": "MX"
            },
            {
                "name": "Ventoquipa",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero (San Javier)",
                "countryCode": "MX"
            },
            {
                "name": "Vito",
                "countryCode": "MX"
            },
            {
                "name": "Xiquila",
                "countryCode": "MX"
            },
            {
                "name": "Xiteje de Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Xochiatipan",
                "countryCode": "MX"
            },
            {
                "name": "Xochiatipan de Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Xochicoatlán",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Xolostitla de Morelos (Xolostitla)",
                "countryCode": "MX"
            },
            {
                "name": "Xoxolpa",
                "countryCode": "MX"
            },
            {
                "name": "Yahualica",
                "countryCode": "MX"
            },
            {
                "name": "Yolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Zacacuautla",
                "countryCode": "MX"
            },
            {
                "name": "Zacamulpa",
                "countryCode": "MX"
            },
            {
                "name": "Zacualtipanito",
                "countryCode": "MX"
            },
            {
                "name": "Zacualtipán",
                "countryCode": "MX"
            },
            {
                "name": "Zapotlán de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Zempoala",
                "countryCode": "MX"
            },
            {
                "name": "Zimapan",
                "countryCode": "MX"
            },
            {
                "name": "Zocea",
                "countryCode": "MX"
            },
            {
                "name": "Zoquitipán",
                "countryCode": "MX"
            },
            {
                "name": "Zothé",
                "countryCode": "MX"
            },
            {
                "name": "Zotoluca",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán de Juárez Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Acueducto Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "Agua Bermeja",
                "countryCode": "MX"
            },
            {
                "name": "Agua Caliente",
                "countryCode": "MX"
            },
            {
                "name": "Agua Colorada",
                "countryCode": "MX"
            },
            {
                "name": "Agua Delgada",
                "countryCode": "MX"
            },
            {
                "name": "Agua Escondida",
                "countryCode": "MX"
            },
            {
                "name": "Agua Fría",
                "countryCode": "MX"
            },
            {
                "name": "Agua Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Agua Paloma",
                "countryCode": "MX"
            },
            {
                "name": "Agua Prieta",
                "countryCode": "MX"
            },
            {
                "name": "Agua Rica",
                "countryCode": "MX"
            },
            {
                "name": "Agua Salada",
                "countryCode": "MX"
            },
            {
                "name": "Agua Zarca",
                "countryCode": "MX"
            },
            {
                "name": "Ahualulco de Mercado Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuetita de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Ahuisculco",
                "countryCode": "MX"
            },
            {
                "name": "Ajijic",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil",
                "countryCode": "MX"
            },
            {
                "name": "Alista",
                "countryCode": "MX"
            },
            {
                "name": "Allende",
                "countryCode": "MX"
            },
            {
                "name": "Altavista de Ramos",
                "countryCode": "MX"
            },
            {
                "name": "Altus Bosques",
                "countryCode": "MX"
            },
            {
                "name": "Amacuautitlán",
                "countryCode": "MX"
            },
            {
                "name": "Amacueca Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Amajaquillo",
                "countryCode": "MX"
            },
            {
                "name": "Amatanejo",
                "countryCode": "MX"
            },
            {
                "name": "Amatitán",
                "countryCode": "MX"
            },
            {
                "name": "Ambrosio",
                "countryCode": "MX"
            },
            {
                "name": "Ameca",
                "countryCode": "MX"
            },
            {
                "name": "Ameca Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Analco",
                "countryCode": "MX"
            },
            {
                "name": "Anoca",
                "countryCode": "MX"
            },
            {
                "name": "Antonio Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Apazulco",
                "countryCode": "MX"
            },
            {
                "name": "Apozol de Gutiérrez",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Arandas",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Hondo",
                "countryCode": "MX"
            },
            {
                "name": "Atacco",
                "countryCode": "MX"
            },
            {
                "name": "Atarjeas de Covarrubias",
                "countryCode": "MX"
            },
            {
                "name": "Atemanica",
                "countryCode": "MX"
            },
            {
                "name": "Atengo",
                "countryCode": "MX"
            },
            {
                "name": "Atenguillo",
                "countryCode": "MX"
            },
            {
                "name": "Atequiza",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco el Alto Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco el Bajo",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilquillo",
                "countryCode": "MX"
            },
            {
                "name": "Atoyac",
                "countryCode": "MX"
            },
            {
                "name": "Atzcatlán",
                "countryCode": "MX"
            },
            {
                "name": "Autlán de Navarro",
                "countryCode": "MX"
            },
            {
                "name": "Ayotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ayotlán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Ayuquila",
                "countryCode": "MX"
            },
            {
                "name": "Ayutla, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Bajío Seco",
                "countryCode": "MX"
            },
            {
                "name": "Bajío de Ratones",
                "countryCode": "MX"
            },
            {
                "name": "Bajío de San José",
                "countryCode": "MX"
            },
            {
                "name": "Balcones de la Calera",
                "countryCode": "MX"
            },
            {
                "name": "Banus Vallarta",
                "countryCode": "MX"
            },
            {
                "name": "Barra de Navidad",
                "countryCode": "MX"
            },
            {
                "name": "Barranca de Otates",
                "countryCode": "MX"
            },
            {
                "name": "Barranca de Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Barranca de los Laureles",
                "countryCode": "MX"
            },
            {
                "name": "Barranca del Tule",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Tapias",
                "countryCode": "MX"
            },
            {
                "name": "Belem del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Bellavista",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Betania",
                "countryCode": "MX"
            },
            {
                "name": "Betulia",
                "countryCode": "MX"
            },
            {
                "name": "Bicercio",
                "countryCode": "MX"
            },
            {
                "name": "Boca de Tomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Bolaños Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Bosque Real Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "Brisas de la Navidad",
                "countryCode": "MX"
            },
            {
                "name": "Brizuela",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Cabo Corrientes",
                "countryCode": "MX"
            },
            {
                "name": "Cajititlán",
                "countryCode": "MX"
            },
            {
                "name": "Calerita",
                "countryCode": "MX"
            },
            {
                "name": "Callejones",
                "countryCode": "MX"
            },
            {
                "name": "Camajapita",
                "countryCode": "MX"
            },
            {
                "name": "Campestre las Palomas Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "Campo Acosta",
                "countryCode": "MX"
            },
            {
                "name": "Campo Real",
                "countryCode": "MX"
            },
            {
                "name": "Canchol",
                "countryCode": "MX"
            },
            {
                "name": "Canoas",
                "countryCode": "MX"
            },
            {
                "name": "Canoas de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Capilla de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Capilla de Milpillas",
                "countryCode": "MX"
            },
            {
                "name": "Capulín",
                "countryCode": "MX"
            },
            {
                "name": "Caquixtle de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Carrasqueño",
                "countryCode": "MX"
            },
            {
                "name": "Carretas",
                "countryCode": "MX"
            },
            {
                "name": "Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "Casa de Teja",
                "countryCode": "MX"
            },
            {
                "name": "Casas Viejas",
                "countryCode": "MX"
            },
            {
                "name": "Casimiro Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Castro Urdiales",
                "countryCode": "MX"
            },
            {
                "name": "Cañada Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Islas",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Ricos",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "Cañadas de Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Ceja de Bolitas",
                "countryCode": "MX"
            },
            {
                "name": "Centro de Readaptación Social",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de Tierra",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Santa Fe",
                "countryCode": "MX"
            },
            {
                "name": "Chacala",
                "countryCode": "MX"
            },
            {
                "name": "Chachahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Chalpicote",
                "countryCode": "MX"
            },
            {
                "name": "Chamacuero",
                "countryCode": "MX"
            },
            {
                "name": "Chamela, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Chancol",
                "countryCode": "MX"
            },
            {
                "name": "Chancuellar",
                "countryCode": "MX"
            },
            {
                "name": "Chapala",
                "countryCode": "MX"
            },
            {
                "name": "Chayotillo",
                "countryCode": "MX"
            },
            {
                "name": "Chilacayote",
                "countryCode": "MX"
            },
            {
                "name": "Chimaltitán",
                "countryCode": "MX"
            },
            {
                "name": "Chinampas",
                "countryCode": "MX"
            },
            {
                "name": "Chipinque de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Chiquihuitillo",
                "countryCode": "MX"
            },
            {
                "name": "Chiquilistlán",
                "countryCode": "MX"
            },
            {
                "name": "Chiquilistlán, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Chome",
                "countryCode": "MX"
            },
            {
                "name": "Churintzio",
                "countryCode": "MX"
            },
            {
                "name": "Cihuatlán, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Cima del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Citala",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Guzmán CERESO",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega de Galvanes",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega del Pastor",
                "countryCode": "MX"
            },
            {
                "name": "Coamostita",
                "countryCode": "MX"
            },
            {
                "name": "Coatlancillo",
                "countryCode": "MX"
            },
            {
                "name": "Cocuasco",
                "countryCode": "MX"
            },
            {
                "name": "Cocula, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Codorniz",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía Santa Cruz de Camotlán",
                "countryCode": "MX"
            },
            {
                "name": "Colinas del Roble",
                "countryCode": "MX"
            },
            {
                "name": "Colinas del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Agua Caliente",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Bosques del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Capilla de Rayo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Cuisillos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Ganadera",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Guadalupana",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Hatmasie",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Loma Alta",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lázaro Cárdenas del Río",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Madero",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Niños Héroes",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Paso del Molino",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Pinal Villa",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Santa Cecilia",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Trigomil",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Veintitrés de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Veintiuno de Marzo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia la Granjena",
                "countryCode": "MX"
            },
            {
                "name": "Colonia las Granjas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia las Palmas Campamento SAGAR",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Robles",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "Colotlán",
                "countryCode": "MX"
            },
            {
                "name": "Colotlán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Comanja de Corona",
                "countryCode": "MX"
            },
            {
                "name": "Concepción de Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Concepción del Bramador",
                "countryCode": "MX"
            },
            {
                "name": "Copales",
                "countryCode": "MX"
            },
            {
                "name": "Corral Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Corral de Piedra",
                "countryCode": "MX"
            },
            {
                "name": "Corralillos",
                "countryCode": "MX"
            },
            {
                "name": "Coyamel",
                "countryCode": "MX"
            },
            {
                "name": "Coyula",
                "countryCode": "MX"
            },
            {
                "name": "Cristeros Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuautitlán",
                "countryCode": "MX"
            },
            {
                "name": "Cuautitlán de García Barragán",
                "countryCode": "MX"
            },
            {
                "name": "Cuautla",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta Chica",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta de Ovejas",
                "countryCode": "MX"
            },
            {
                "name": "Cuexcomatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Cuisillos",
                "countryCode": "MX"
            },
            {
                "name": "Cuitzeo",
                "countryCode": "MX"
            },
            {
                "name": "Cuquío",
                "countryCode": "MX"
            },
            {
                "name": "Custique",
                "countryCode": "MX"
            },
            {
                "name": "Cuxpala",
                "countryCode": "MX"
            },
            {
                "name": "Cópala",
                "countryCode": "MX"
            },
            {
                "name": "Degollado",
                "countryCode": "MX"
            },
            {
                "name": "Dieciocho de Marzo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Agua Zarca",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Ciénega de Mora",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Modelo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Poblado Atenquique",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Uno de Febrero",
                "countryCode": "MX"
            },
            {
                "name": "Ejutla",
                "countryCode": "MX"
            },
            {
                "name": "El Agostadero",
                "countryCode": "MX"
            },
            {
                "name": "El Aguacate",
                "countryCode": "MX"
            },
            {
                "name": "El Alto",
                "countryCode": "MX"
            },
            {
                "name": "El Amarillo",
                "countryCode": "MX"
            },
            {
                "name": "El Amarradero",
                "countryCode": "MX"
            },
            {
                "name": "El Arca",
                "countryCode": "MX"
            },
            {
                "name": "El Arco, Ameca",
                "countryCode": "MX"
            },
            {
                "name": "El Arenal",
                "countryCode": "MX"
            },
            {
                "name": "El Asoleadero",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío Lomas del Club",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío de Plascencia",
                "countryCode": "MX"
            },
            {
                "name": "El Banco del Venado",
                "countryCode": "MX"
            },
            {
                "name": "El Barrio",
                "countryCode": "MX"
            },
            {
                "name": "El Brillante Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "El Briseño",
                "countryCode": "MX"
            },
            {
                "name": "El Cabezón",
                "countryCode": "MX"
            },
            {
                "name": "El Cacalote",
                "countryCode": "MX"
            },
            {
                "name": "El Camichín",
                "countryCode": "MX"
            },
            {
                "name": "El Cantón",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín de Chávez",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Carricillo",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "El Cedazo",
                "countryCode": "MX"
            },
            {
                "name": "El Cerrito",
                "countryCode": "MX"
            },
            {
                "name": "El Cerro, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "El Chalate",
                "countryCode": "MX"
            },
            {
                "name": "El Chante",
                "countryCode": "MX"
            },
            {
                "name": "El Chaveño",
                "countryCode": "MX"
            },
            {
                "name": "El Chilar",
                "countryCode": "MX"
            },
            {
                "name": "El Chispeadero",
                "countryCode": "MX"
            },
            {
                "name": "El Chivatillo",
                "countryCode": "MX"
            },
            {
                "name": "El Coahuayote",
                "countryCode": "MX"
            },
            {
                "name": "El Colomo",
                "countryCode": "MX"
            },
            {
                "name": "El Colorado",
                "countryCode": "MX"
            },
            {
                "name": "El Columpio",
                "countryCode": "MX"
            },
            {
                "name": "El Conde",
                "countryCode": "MX"
            },
            {
                "name": "El Conejo",
                "countryCode": "MX"
            },
            {
                "name": "El Corcovado",
                "countryCode": "MX"
            },
            {
                "name": "El Cortijo",
                "countryCode": "MX"
            },
            {
                "name": "El Coyotillo",
                "countryCode": "MX"
            },
            {
                "name": "El Crucero",
                "countryCode": "MX"
            },
            {
                "name": "El Crucero de Arandas",
                "countryCode": "MX"
            },
            {
                "name": "El Crucero de Santa María",
                "countryCode": "MX"
            },
            {
                "name": "El Cuatro",
                "countryCode": "MX"
            },
            {
                "name": "El Derramadero",
                "countryCode": "MX"
            },
            {
                "name": "El Dique",
                "countryCode": "MX"
            },
            {
                "name": "El Fraile",
                "countryCode": "MX"
            },
            {
                "name": "El Gato",
                "countryCode": "MX"
            },
            {
                "name": "El Grullo Municipality",
                "countryCode": "MX"
            },
            {
                "name": "El Hollejo",
                "countryCode": "MX"
            },
            {
                "name": "El Huiroche",
                "countryCode": "MX"
            },
            {
                "name": "El Jabalí",
                "countryCode": "MX"
            },
            {
                "name": "El Jagüey",
                "countryCode": "MX"
            },
            {
                "name": "El Jaral",
                "countryCode": "MX"
            },
            {
                "name": "El Jaralillo",
                "countryCode": "MX"
            },
            {
                "name": "El Jaralito",
                "countryCode": "MX"
            },
            {
                "name": "El Jardín",
                "countryCode": "MX"
            },
            {
                "name": "El Jazmín",
                "countryCode": "MX"
            },
            {
                "name": "El Jomate",
                "countryCode": "MX"
            },
            {
                "name": "El Laurel",
                "countryCode": "MX"
            },
            {
                "name": "El Lazo",
                "countryCode": "MX"
            },
            {
                "name": "El Limón",
                "countryCode": "MX"
            },
            {
                "name": "El Llano de Barajas",
                "countryCode": "MX"
            },
            {
                "name": "El Llano de Plascencia",
                "countryCode": "MX"
            },
            {
                "name": "El Llano de los Toros",
                "countryCode": "MX"
            },
            {
                "name": "El Lobo",
                "countryCode": "MX"
            },
            {
                "name": "El Lopeño",
                "countryCode": "MX"
            },
            {
                "name": "El Loreto Occidental",
                "countryCode": "MX"
            },
            {
                "name": "El Macuchi",
                "countryCode": "MX"
            },
            {
                "name": "El Maestranzo",
                "countryCode": "MX"
            },
            {
                "name": "El Manantial",
                "countryCode": "MX"
            },
            {
                "name": "El Mastranzo",
                "countryCode": "MX"
            },
            {
                "name": "El Mayoral de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "El Medineño",
                "countryCode": "MX"
            },
            {
                "name": "El Mentidero",
                "countryCode": "MX"
            },
            {
                "name": "El Mesoncito",
                "countryCode": "MX"
            },
            {
                "name": "El Mezquite Grande",
                "countryCode": "MX"
            },
            {
                "name": "El Mirador",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Moral",
                "countryCode": "MX"
            },
            {
                "name": "El Muey",
                "countryCode": "MX"
            },
            {
                "name": "El Nacimiento",
                "countryCode": "MX"
            },
            {
                "name": "El Nacimiento de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "El Nuevo Poblado",
                "countryCode": "MX"
            },
            {
                "name": "El Ocote",
                "countryCode": "MX"
            },
            {
                "name": "El Ocotillo",
                "countryCode": "MX"
            },
            {
                "name": "El Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "El Ojuelo",
                "countryCode": "MX"
            },
            {
                "name": "El Olvido",
                "countryCode": "MX"
            },
            {
                "name": "El Padre Juan",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar de San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "El Pantano",
                "countryCode": "MX"
            },
            {
                "name": "El Paso Real",
                "countryCode": "MX"
            },
            {
                "name": "El Pino",
                "countryCode": "MX"
            },
            {
                "name": "El Plan",
                "countryCode": "MX"
            },
            {
                "name": "El Platanar",
                "countryCode": "MX"
            },
            {
                "name": "El Pochote",
                "countryCode": "MX"
            },
            {
                "name": "El Popote",
                "countryCode": "MX"
            },
            {
                "name": "El Portezuelo",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "El Pozo Santo",
                "countryCode": "MX"
            },
            {
                "name": "El Pueblito",
                "countryCode": "MX"
            },
            {
                "name": "El Puente",
                "countryCode": "MX"
            },
            {
                "name": "El Puerto",
                "countryCode": "MX"
            },
            {
                "name": "El Puerto de Amolero",
                "countryCode": "MX"
            },
            {
                "name": "El Puesto",
                "countryCode": "MX"
            },
            {
                "name": "El Quemado",
                "countryCode": "MX"
            },
            {
                "name": "El Quince",
                "countryCode": "MX"
            },
            {
                "name": "El Ranchito",
                "countryCode": "MX"
            },
            {
                "name": "El Rayo",
                "countryCode": "MX"
            },
            {
                "name": "El Rebalse",
                "countryCode": "MX"
            },
            {
                "name": "El Rebalsito de Apazulco",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio Suchitlán",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio de Vázquez",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio de los Altos",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "El Reparo",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón",
                "countryCode": "MX"
            },
            {
                "name": "El Rodeo",
                "countryCode": "MX"
            },
            {
                "name": "El Rodeo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "El Romereño",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Sabino",
                "countryCode": "MX"
            },
            {
                "name": "El Sacrificio",
                "countryCode": "MX"
            },
            {
                "name": "El Salero",
                "countryCode": "MX"
            },
            {
                "name": "El Salitre",
                "countryCode": "MX"
            },
            {
                "name": "El Salto",
                "countryCode": "MX"
            },
            {
                "name": "El Salto Municipality",
                "countryCode": "MX"
            },
            {
                "name": "El Salto de San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "El Salto de las Peñas",
                "countryCode": "MX"
            },
            {
                "name": "El Salvador",
                "countryCode": "MX"
            },
            {
                "name": "El Salvial",
                "countryCode": "MX"
            },
            {
                "name": "El Saucillo",
                "countryCode": "MX"
            },
            {
                "name": "El Saucillo de los Pérez",
                "countryCode": "MX"
            },
            {
                "name": "El Saucito",
                "countryCode": "MX"
            },
            {
                "name": "El Sauz de Ibarra",
                "countryCode": "MX"
            },
            {
                "name": "El Seminario",
                "countryCode": "MX"
            },
            {
                "name": "El Sitio",
                "countryCode": "MX"
            },
            {
                "name": "El Sopial",
                "countryCode": "MX"
            },
            {
                "name": "El Soyate",
                "countryCode": "MX"
            },
            {
                "name": "El Tablero",
                "countryCode": "MX"
            },
            {
                "name": "El Taray",
                "countryCode": "MX"
            },
            {
                "name": "El Tarengo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "El Tecolote",
                "countryCode": "MX"
            },
            {
                "name": "El Tecuán",
                "countryCode": "MX"
            },
            {
                "name": "El Tempisque",
                "countryCode": "MX"
            },
            {
                "name": "El Tepame",
                "countryCode": "MX"
            },
            {
                "name": "El Tepec",
                "countryCode": "MX"
            },
            {
                "name": "El Tepetate",
                "countryCode": "MX"
            },
            {
                "name": "El Tepozán",
                "countryCode": "MX"
            },
            {
                "name": "El Tequesquite",
                "countryCode": "MX"
            },
            {
                "name": "El Terrerito de Sevilla",
                "countryCode": "MX"
            },
            {
                "name": "El Terrero",
                "countryCode": "MX"
            },
            {
                "name": "El Torque",
                "countryCode": "MX"
            },
            {
                "name": "El Torreón",
                "countryCode": "MX"
            },
            {
                "name": "El Tuito",
                "countryCode": "MX"
            },
            {
                "name": "El Tule",
                "countryCode": "MX"
            },
            {
                "name": "El Tulillo",
                "countryCode": "MX"
            },
            {
                "name": "El Uvalano",
                "countryCode": "MX"
            },
            {
                "name": "El Verde",
                "countryCode": "MX"
            },
            {
                "name": "El Vergel",
                "countryCode": "MX"
            },
            {
                "name": "El Vigía",
                "countryCode": "MX"
            },
            {
                "name": "El Zancudo",
                "countryCode": "MX"
            },
            {
                "name": "El Zapatero",
                "countryCode": "MX"
            },
            {
                "name": "El Zapote",
                "countryCode": "MX"
            },
            {
                "name": "El Zapote de Chávez",
                "countryCode": "MX"
            },
            {
                "name": "El Zapotillo",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Encarnación de Díaz",
                "countryCode": "MX"
            },
            {
                "name": "Epeche Chico",
                "countryCode": "MX"
            },
            {
                "name": "Epeche Grande",
                "countryCode": "MX"
            },
            {
                "name": "Estancia Vieja de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Estancia de San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "Estanzuela",
                "countryCode": "MX"
            },
            {
                "name": "Estipa",
                "countryCode": "MX"
            },
            {
                "name": "Etzatlán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Ferrería de Tula",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Primo de Verdad",
                "countryCode": "MX"
            },
            {
                "name": "Galaxia Bonito Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Galaxia la Noria",
                "countryCode": "MX"
            },
            {
                "name": "Gargantillo",
                "countryCode": "MX"
            },
            {
                "name": "General Andrés Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "General Gabriel Leyva",
                "countryCode": "MX"
            },
            {
                "name": "Geovillas Laureles del Campanario",
                "countryCode": "MX"
            },
            {
                "name": "Getsemaní",
                "countryCode": "MX"
            },
            {
                "name": "Granadillas",
                "countryCode": "MX"
            },
            {
                "name": "Guachinango",
                "countryCode": "MX"
            },
            {
                "name": "Guadalajara",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe de Lerma",
                "countryCode": "MX"
            },
            {
                "name": "Guamuchilillo",
                "countryCode": "MX"
            },
            {
                "name": "Guayabitos",
                "countryCode": "MX"
            },
            {
                "name": "Gómez Farías Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Santa Fe",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Vieja",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Vieja del Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda de la Labor",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda los Fresnos",
                "countryCode": "MX"
            },
            {
                "name": "Haciendas la Herradura",
                "countryCode": "MX"
            },
            {
                "name": "Hierba de Matancillas",
                "countryCode": "MX"
            },
            {
                "name": "Higueras San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Higuerillas",
                "countryCode": "MX"
            },
            {
                "name": "Hostotipaquillo",
                "countryCode": "MX"
            },
            {
                "name": "Huajacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Huascato",
                "countryCode": "MX"
            },
            {
                "name": "Huaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Huejotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Huejotitán",
                "countryCode": "MX"
            },
            {
                "name": "Huejuquilla El Alto Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Huejúcar",
                "countryCode": "MX"
            },
            {
                "name": "Huertas Familiares San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "Huisichi",
                "countryCode": "MX"
            },
            {
                "name": "Huitzometl",
                "countryCode": "MX"
            },
            {
                "name": "Huizaches",
                "countryCode": "MX"
            },
            {
                "name": "IPROVIPE I",
                "countryCode": "MX"
            },
            {
                "name": "Ipazoltic",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapa",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuacán de los Membrillos",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuacán del Río",
                "countryCode": "MX"
            },
            {
                "name": "Izolta",
                "countryCode": "MX"
            },
            {
                "name": "J. Isabel Flores",
                "countryCode": "MX"
            },
            {
                "name": "Jalostotitlan",
                "countryCode": "MX"
            },
            {
                "name": "Jaluco",
                "countryCode": "MX"
            },
            {
                "name": "Jamay",
                "countryCode": "MX"
            },
            {
                "name": "Jardines de San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "Jardines del Edén",
                "countryCode": "MX"
            },
            {
                "name": "Jaritas",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María, Nayarit",
                "countryCode": "MX"
            },
            {
                "name": "Jilotlán de los Dolores",
                "countryCode": "MX"
            },
            {
                "name": "Jiquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Joconoxtle",
                "countryCode": "MX"
            },
            {
                "name": "Jocotepec",
                "countryCode": "MX"
            },
            {
                "name": "Josefa Ortíz de Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "José María Pino Suárez",
                "countryCode": "MX"
            },
            {
                "name": "José de Jesús Aguirre, Teocaltiche",
                "countryCode": "MX"
            },
            {
                "name": "Juan Gil Preciado",
                "countryCode": "MX"
            },
            {
                "name": "Juanacatlán",
                "countryCode": "MX"
            },
            {
                "name": "La Alameda",
                "countryCode": "MX"
            },
            {
                "name": "La Aldaba",
                "countryCode": "MX"
            },
            {
                "name": "La Angostura de los Altos",
                "countryCode": "MX"
            },
            {
                "name": "La Arbolada Plus",
                "countryCode": "MX"
            },
            {
                "name": "La Barca",
                "countryCode": "MX"
            },
            {
                "name": "La Barranca",
                "countryCode": "MX"
            },
            {
                "name": "La Calera",
                "countryCode": "MX"
            },
            {
                "name": "La Calera Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "La Cantera",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada Tezcaltilti",
                "countryCode": "MX"
            },
            {
                "name": "La Cebadilla",
                "countryCode": "MX"
            },
            {
                "name": "La Ceja de la Capilla",
                "countryCode": "MX"
            },
            {
                "name": "La Cerca Blanca",
                "countryCode": "MX"
            },
            {
                "name": "La Cieneguita",
                "countryCode": "MX"
            },
            {
                "name": "La Ciénega",
                "countryCode": "MX"
            },
            {
                "name": "La Ciénega de Huaistita",
                "countryCode": "MX"
            },
            {
                "name": "La Cofradía",
                "countryCode": "MX"
            },
            {
                "name": "La Cofradía del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "La Colonia San Juanito",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Conchilla",
                "countryCode": "MX"
            },
            {
                "name": "La Conchita",
                "countryCode": "MX"
            },
            {
                "name": "La Constancia",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz de Loreto",
                "countryCode": "MX"
            },
            {
                "name": "La Cuchilla",
                "countryCode": "MX"
            },
            {
                "name": "La Cuesta de Medina",
                "countryCode": "MX"
            },
            {
                "name": "La Cueva",
                "countryCode": "MX"
            },
            {
                "name": "La Estacada",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia de Ayones",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia de Navarro",
                "countryCode": "MX"
            },
            {
                "name": "La Estancita",
                "countryCode": "MX"
            },
            {
                "name": "La Estanzuela",
                "countryCode": "MX"
            },
            {
                "name": "La Floreña",
                "countryCode": "MX"
            },
            {
                "name": "La Fortuna Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "La Frontera",
                "countryCode": "MX"
            },
            {
                "name": "La Garita, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "La Garruña",
                "countryCode": "MX"
            },
            {
                "name": "La Gila",
                "countryCode": "MX"
            },
            {
                "name": "La Gloria",
                "countryCode": "MX"
            },
            {
                "name": "La Granjena",
                "countryCode": "MX"
            },
            {
                "name": "La Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "La Guásima",
                "countryCode": "MX"
            },
            {
                "name": "La Hermosura",
                "countryCode": "MX"
            },
            {
                "name": "La Higuera",
                "countryCode": "MX"
            },
            {
                "name": "La Huerta",
                "countryCode": "MX"
            },
            {
                "name": "La Huevera",
                "countryCode": "MX"
            },
            {
                "name": "La Isla",
                "countryCode": "MX"
            },
            {
                "name": "La Jarrilla",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Joya Chica",
                "countryCode": "MX"
            },
            {
                "name": "La Joya Grande",
                "countryCode": "MX"
            },
            {
                "name": "La Labor de la Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Labor de los Alba",
                "countryCode": "MX"
            },
            {
                "name": "La Ladera",
                "countryCode": "MX"
            },
            {
                "name": "La Ladera Grande",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna Grande",
                "countryCode": "MX"
            },
            {
                "name": "La Laja",
                "countryCode": "MX"
            },
            {
                "name": "La Lobera",
                "countryCode": "MX"
            },
            {
                "name": "La Loma",
                "countryCode": "MX"
            },
            {
                "name": "La Loma de los Rizo",
                "countryCode": "MX"
            },
            {
                "name": "La Loma del Nopal",
                "countryCode": "MX"
            },
            {
                "name": "La Lomita",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Manzanilla",
                "countryCode": "MX"
            },
            {
                "name": "La Manzanilla de La Paz",
                "countryCode": "MX"
            },
            {
                "name": "La Mata",
                "countryCode": "MX"
            },
            {
                "name": "La Merced",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa del Guitarrero",
                "countryCode": "MX"
            },
            {
                "name": "La Mesita",
                "countryCode": "MX"
            },
            {
                "name": "La Mezcalilla",
                "countryCode": "MX"
            },
            {
                "name": "La Mezquitera",
                "countryCode": "MX"
            },
            {
                "name": "La Mial",
                "countryCode": "MX"
            },
            {
                "name": "La Mina",
                "countryCode": "MX"
            },
            {
                "name": "La Mojarra",
                "countryCode": "MX"
            },
            {
                "name": "La Mora",
                "countryCode": "MX"
            },
            {
                "name": "La Mota",
                "countryCode": "MX"
            },
            {
                "name": "La Nance",
                "countryCode": "MX"
            },
            {
                "name": "La Nopalera",
                "countryCode": "MX"
            },
            {
                "name": "La Noria",
                "countryCode": "MX"
            },
            {
                "name": "La Orilla de la Cerca",
                "countryCode": "MX"
            },
            {
                "name": "La Orilla del Agua",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Palmita",
                "countryCode": "MX"
            },
            {
                "name": "La Parota",
                "countryCode": "MX"
            },
            {
                "name": "La Parrita",
                "countryCode": "MX"
            },
            {
                "name": "La Paz",
                "countryCode": "MX"
            },
            {
                "name": "La Paz de Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "La Peña, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "La Playa",
                "countryCode": "MX"
            },
            {
                "name": "La Presa",
                "countryCode": "MX"
            },
            {
                "name": "La Primavera",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia",
                "countryCode": "MX"
            },
            {
                "name": "La Puerta de San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "La Puerta del Coche",
                "countryCode": "MX"
            },
            {
                "name": "La Punta",
                "countryCode": "MX"
            },
            {
                "name": "La Purísima",
                "countryCode": "MX"
            },
            {
                "name": "La Quemada",
                "countryCode": "MX"
            },
            {
                "name": "La Rana",
                "countryCode": "MX"
            },
            {
                "name": "La Raya",
                "countryCode": "MX"
            },
            {
                "name": "La Red",
                "countryCode": "MX"
            },
            {
                "name": "La Resolana",
                "countryCode": "MX"
            },
            {
                "name": "La Ribera",
                "countryCode": "MX"
            },
            {
                "name": "La Roca",
                "countryCode": "MX"
            },
            {
                "name": "La Rosa",
                "countryCode": "MX"
            },
            {
                "name": "La Rosa Amarilla",
                "countryCode": "MX"
            },
            {
                "name": "La Rosa de Castilla",
                "countryCode": "MX"
            },
            {
                "name": "La Rueda",
                "countryCode": "MX"
            },
            {
                "name": "La Sanguijuela",
                "countryCode": "MX"
            },
            {
                "name": "La Sauceda",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Teja",
                "countryCode": "MX"
            },
            {
                "name": "La Tijera",
                "countryCode": "MX"
            },
            {
                "name": "La Tinajera",
                "countryCode": "MX"
            },
            {
                "name": "La Tiricia",
                "countryCode": "MX"
            },
            {
                "name": "La Tortuga",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "La Unión del Cuatro",
                "countryCode": "MX"
            },
            {
                "name": "La Vaquera",
                "countryCode": "MX"
            },
            {
                "name": "La Vega",
                "countryCode": "MX"
            },
            {
                "name": "La Venta del Astillero",
                "countryCode": "MX"
            },
            {
                "name": "La Verdolaga",
                "countryCode": "MX"
            },
            {
                "name": "La Villa",
                "countryCode": "MX"
            },
            {
                "name": "La Virgencita",
                "countryCode": "MX"
            },
            {
                "name": "La Yerbabuena",
                "countryCode": "MX"
            },
            {
                "name": "La Zapotera",
                "countryCode": "MX"
            },
            {
                "name": "Labor de Medina",
                "countryCode": "MX"
            },
            {
                "name": "Labor de Montoya",
                "countryCode": "MX"
            },
            {
                "name": "Ladera Chica",
                "countryCode": "MX"
            },
            {
                "name": "Lagos de Moreno",
                "countryCode": "MX"
            },
            {
                "name": "Laguna Colorada",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Piedra",
                "countryCode": "MX"
            },
            {
                "name": "Lagunillas, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Las Alfalfas",
                "countryCode": "MX"
            },
            {
                "name": "Las Amarillas",
                "countryCode": "MX"
            },
            {
                "name": "Las Asturias Fraccionamiento",
                "countryCode": "MX"
            },
            {
                "name": "Las Azules",
                "countryCode": "MX"
            },
            {
                "name": "Las Calaveras",
                "countryCode": "MX"
            },
            {
                "name": "Las Canoas",
                "countryCode": "MX"
            },
            {
                "name": "Las Carretas",
                "countryCode": "MX"
            },
            {
                "name": "Las Cañadas",
                "countryCode": "MX"
            },
            {
                "name": "Las Golondrinas [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Las Juntas",
                "countryCode": "MX"
            },
            {
                "name": "Las Pintas de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Las Pintitas",
                "countryCode": "MX"
            },
            {
                "name": "Las Primaveras [Invernadero]",
                "countryCode": "MX"
            },
            {
                "name": "Lo Arado",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de San Agustín",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Tejeda",
                "countryCode": "MX"
            },
            {
                "name": "Lomas del Sur",
                "countryCode": "MX"
            },
            {
                "name": "Los Azulitos",
                "countryCode": "MX"
            },
            {
                "name": "Los Cedros",
                "countryCode": "MX"
            },
            {
                "name": "Los Gavilanes",
                "countryCode": "MX"
            },
            {
                "name": "Los Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Los Mazos",
                "countryCode": "MX"
            },
            {
                "name": "Los Olivos",
                "countryCode": "MX"
            },
            {
                "name": "Los Pocitos",
                "countryCode": "MX"
            },
            {
                "name": "Los Ruiseñores",
                "countryCode": "MX"
            },
            {
                "name": "Los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "Los Vergara",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Manalisco",
                "countryCode": "MX"
            },
            {
                "name": "Margaritas",
                "countryCode": "MX"
            },
            {
                "name": "Mascota Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Matancillas (San Isidro Matancillas)",
                "countryCode": "MX"
            },
            {
                "name": "Matanzas",
                "countryCode": "MX"
            },
            {
                "name": "Matatlán",
                "countryCode": "MX"
            },
            {
                "name": "Mazamitlongo",
                "countryCode": "MX"
            },
            {
                "name": "Mechoacanejo",
                "countryCode": "MX"
            },
            {
                "name": "Mexticacán",
                "countryCode": "MX"
            },
            {
                "name": "Mezcala",
                "countryCode": "MX"
            },
            {
                "name": "Mezquitic Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Mezquitic de la Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Milpillas",
                "countryCode": "MX"
            },
            {
                "name": "Misión San Luis Gonzaga Chiriyaqui",
                "countryCode": "MX"
            },
            {
                "name": "Mismaloya",
                "countryCode": "MX"
            },
            {
                "name": "Mismaloya (Fraccionamiento Pedregal de Santa Martha)",
                "countryCode": "MX"
            },
            {
                "name": "Mixtlán",
                "countryCode": "MX"
            },
            {
                "name": "Moras",
                "countryCode": "MX"
            },
            {
                "name": "Navajas",
                "countryCode": "MX"
            },
            {
                "name": "Nextipac",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás R Casillas",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo México, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Refugio de Afuera (Nuevo Refugio)",
                "countryCode": "MX"
            },
            {
                "name": "Oconahua, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlán, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Morán, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Ojuelos de Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Otatlán",
                "countryCode": "MX"
            },
            {
                "name": "Palomar",
                "countryCode": "MX"
            },
            {
                "name": "Palos Altos",
                "countryCode": "MX"
            },
            {
                "name": "Paseo Puente Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Paseo de las Cañadas",
                "countryCode": "MX"
            },
            {
                "name": "Paseo del Prado",
                "countryCode": "MX"
            },
            {
                "name": "Paseos del Valle [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Cuarenta",
                "countryCode": "MX"
            },
            {
                "name": "Pegueros",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Pesada",
                "countryCode": "MX"
            },
            {
                "name": "Pihuamo",
                "countryCode": "MX"
            },
            {
                "name": "Pochotita",
                "countryCode": "MX"
            },
            {
                "name": "Poncitlán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Portes Gil",
                "countryCode": "MX"
            },
            {
                "name": "Potrerillos",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo (Chupaderos)",
                "countryCode": "MX"
            },
            {
                "name": "Puente Grande",
                "countryCode": "MX"
            },
            {
                "name": "Puente de Camotlán",
                "countryCode": "MX"
            },
            {
                "name": "Puerta del Llano",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Vallarta",
                "countryCode": "MX"
            },
            {
                "name": "Punta de la Vega",
                "countryCode": "MX"
            },
            {
                "name": "Purificación",
                "countryCode": "MX"
            },
            {
                "name": "Quitupan",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Alegre [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Real del Valle (El Paraíso) [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Rinconada la Loma [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Cohamiata",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ixtlán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de Rivas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Barrera",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de los Vázquez",
                "countryCode": "MX"
            },
            {
                "name": "San Clemente",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Zapotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal de la Barranca Municipality",
                "countryCode": "MX"
            },
            {
                "name": "San Diego de Alejandría",
                "countryCode": "MX"
            },
            {
                "name": "San Esteban (San Miguel Tateposco)",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe de Híjar",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Asís",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Rivas",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel,  Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "San Gaspar",
                "countryCode": "MX"
            },
            {
                "name": "San Gaspar de los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio Municipality, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Mazatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Jacintito",
                "countryCode": "MX"
            },
            {
                "name": "San Jacinto, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "San Joaquín Zorrillos",
                "countryCode": "MX"
            },
            {
                "name": "San Jorge",
                "countryCode": "MX"
            },
            {
                "name": "San Jose de Gracia, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "San José Casas Caídas",
                "countryCode": "MX"
            },
            {
                "name": "San José de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "San José de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "San José de los Reynoso",
                "countryCode": "MX"
            },
            {
                "name": "San José del Caliche (El Caliche)",
                "countryCode": "MX"
            },
            {
                "name": "San José del Castillo",
                "countryCode": "MX"
            },
            {
                "name": "San José del Valle",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cosalá",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tecomatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de los Arcos",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de los Lagos",
                "countryCode": "MX"
            },
            {
                "name": "San Juanito de Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "San Julián",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Evangelista",
                "countryCode": "MX"
            },
            {
                "name": "San Luciano",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Soyatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Luis de Agua Caliente",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos",
                "countryCode": "MX"
            },
            {
                "name": "San Martín de Bolaños",
                "countryCode": "MX"
            },
            {
                "name": "San Martín de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Cuyutlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Zapotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de la Sierra, Ayutla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolas",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "San Patricio, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Itzicán",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastian",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián del Oeste",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "Santa Anita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de Barcenas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de la Loma (Tepetates)",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de la Soledad",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de las Flores, Tlajomulco de Zúñiga, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz del Astillero",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Transpontina",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de Enmedio",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosalía, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santiaguito",
                "countryCode": "MX"
            },
            {
                "name": "Santiaguito (Santiaguito de Velázquez)",
                "countryCode": "MX"
            },
            {
                "name": "Sayula",
                "countryCode": "MX"
            },
            {
                "name": "Soyatlán de Afuera",
                "countryCode": "MX"
            },
            {
                "name": "Soyatlán del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Talpa de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Tamazula de Gordiano",
                "countryCode": "MX"
            },
            {
                "name": "Tamazulita, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Tapalpa",
                "countryCode": "MX"
            },
            {
                "name": "Tazumbo",
                "countryCode": "MX"
            },
            {
                "name": "Tecalitlán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Techaluta de Montenegro",
                "countryCode": "MX"
            },
            {
                "name": "Tecolotlán",
                "countryCode": "MX"
            },
            {
                "name": "Tecomates",
                "countryCode": "MX"
            },
            {
                "name": "Tecuattitan",
                "countryCode": "MX"
            },
            {
                "name": "Telcruz",
                "countryCode": "MX"
            },
            {
                "name": "Temastián (La Cantera)",
                "countryCode": "MX"
            },
            {
                "name": "Tenamaxtlán",
                "countryCode": "MX"
            },
            {
                "name": "Tenayuca",
                "countryCode": "MX"
            },
            {
                "name": "Teocaltiche",
                "countryCode": "MX"
            },
            {
                "name": "Teocuitatlán de Corona",
                "countryCode": "MX"
            },
            {
                "name": "Tepatitlán de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Tepec",
                "countryCode": "MX"
            },
            {
                "name": "Tepusco",
                "countryCode": "MX"
            },
            {
                "name": "Tequesquitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tequila",
                "countryCode": "MX"
            },
            {
                "name": "Tesistán",
                "countryCode": "MX"
            },
            {
                "name": "Teuchitlán, Jalisco",
                "countryCode": "MX"
            },
            {
                "name": "Tierras Coloradas",
                "countryCode": "MX"
            },
            {
                "name": "Tizapán el Alto",
                "countryCode": "MX"
            },
            {
                "name": "Tlacuitapan",
                "countryCode": "MX"
            },
            {
                "name": "Tlajomulco de Zuñiga",
                "countryCode": "MX"
            },
            {
                "name": "Tlaquepaque",
                "countryCode": "MX"
            },
            {
                "name": "Tolimán Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Tomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Tonalá",
                "countryCode": "MX"
            },
            {
                "name": "Tonaya",
                "countryCode": "MX"
            },
            {
                "name": "Tonila",
                "countryCode": "MX"
            },
            {
                "name": "Totatiche Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Tototlán",
                "countryCode": "MX"
            },
            {
                "name": "Trapiche de Abra",
                "countryCode": "MX"
            },
            {
                "name": "Trejos",
                "countryCode": "MX"
            },
            {
                "name": "Tultitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tuxcacuesco",
                "countryCode": "MX"
            },
            {
                "name": "Tuxcueca",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpan",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpan de Bolaños",
                "countryCode": "MX"
            },
            {
                "name": "Unión de San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "Unión de Tula",
                "countryCode": "MX"
            },
            {
                "name": "Usmajac",
                "countryCode": "MX"
            },
            {
                "name": "Valle Dorado Inn",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Guadalupe Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Valle de los Girasoles [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Vaquerias",
                "countryCode": "MX"
            },
            {
                "name": "Villa Corona",
                "countryCode": "MX"
            },
            {
                "name": "Villa Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Villa Guerrero Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Purificación",
                "countryCode": "MX"
            },
            {
                "name": "Villa de los Niños",
                "countryCode": "MX"
            },
            {
                "name": "Villa las Flores",
                "countryCode": "MX"
            },
            {
                "name": "Villas de la Hacienda [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Vistas del Maguey [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Yahualica de González Gallo",
                "countryCode": "MX"
            },
            {
                "name": "Zacoalco de Torres",
                "countryCode": "MX"
            },
            {
                "name": "Zalamea",
                "countryCode": "MX"
            },
            {
                "name": "Zapopan",
                "countryCode": "MX"
            },
            {
                "name": "Zapotiltic",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitlán de Vadillo",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitán de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Zapotlanejo",
                "countryCode": "MX"
            },
            {
                "name": "Zapotlán El Grande Municipality",
                "countryCode": "MX"
            },
            {
                "name": "Zapotlán del Rey",
                "countryCode": "MX"
            },
            {
                "name": "Zula",
                "countryCode": "MX"
            },
            {
                "name": "Acachuén",
                "countryCode": "MX"
            },
            {
                "name": "Acalpican de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Acambay",
                "countryCode": "MX"
            },
            {
                "name": "Acatzingo (Acatzingo de la Piedra)",
                "countryCode": "MX"
            },
            {
                "name": "Acolman de Netzahualcóyotl",
                "countryCode": "MX"
            },
            {
                "name": "Acuitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "Aculco de Espinoza",
                "countryCode": "MX"
            },
            {
                "name": "Agua Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Agua Caliente",
                "countryCode": "MX"
            },
            {
                "name": "Agua Escondida",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatitlán Cuarto y Quinto Cuartel",
                "countryCode": "MX"
            },
            {
                "name": "Alborada Jaltenco",
                "countryCode": "MX"
            },
            {
                "name": "Almoloya de Alquisiras",
                "countryCode": "MX"
            },
            {
                "name": "Almoloya del Río",
                "countryCode": "MX"
            },
            {
                "name": "Amanalco de Becerra",
                "countryCode": "MX"
            },
            {
                "name": "Amatepec",
                "countryCode": "MX"
            },
            {
                "name": "Amecameca",
                "countryCode": "MX"
            },
            {
                "name": "Ampliación San Mateo (Colonia Solidaridad)",
                "countryCode": "MX"
            },
            {
                "name": "Ampliación Tezoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Apaxco de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Arbolada los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "Arenal",
                "countryCode": "MX"
            },
            {
                "name": "Atenguillo",
                "countryCode": "MX"
            },
            {
                "name": "Atizapán",
                "countryCode": "MX"
            },
            {
                "name": "Atlacomulco de Fabela",
                "countryCode": "MX"
            },
            {
                "name": "Atlatongo",
                "countryCode": "MX"
            },
            {
                "name": "Atlautla",
                "countryCode": "MX"
            },
            {
                "name": "Axapusco",
                "countryCode": "MX"
            },
            {
                "name": "Axotlán",
                "countryCode": "MX"
            },
            {
                "name": "Ayapango",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Bordo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Chiquichuca",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Cuarto (La Loma)",
                "countryCode": "MX"
            },
            {
                "name": "Barrio San Joaquín el Junco",
                "countryCode": "MX"
            },
            {
                "name": "Barrio San Miguel Dorami",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Tepetitlán Emilio Portes Gil",
                "countryCode": "MX"
            },
            {
                "name": "Barrio Tlatenco",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Arriba de San Juan Xoconusco",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Boyecha",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Canales",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Centro del Cerrillo",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Ensido",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de España",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Jesús Fracción Primera",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de México",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Puentecillas",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Pedro la Cabecera",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Ramón",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de la Barranca",
                "countryCode": "MX"
            },
            {
                "name": "Barrio del Cajón",
                "countryCode": "MX"
            },
            {
                "name": "Barrio el Boncho",
                "countryCode": "MX"
            },
            {
                "name": "Barrio el Vivero",
                "countryCode": "MX"
            },
            {
                "name": "Barrio la Joya",
                "countryCode": "MX"
            },
            {
                "name": "Barrio la Tenería",
                "countryCode": "MX"
            },
            {
                "name": "Barrio los Tules",
                "countryCode": "MX"
            },
            {
                "name": "Bejucos",
                "countryCode": "MX"
            },
            {
                "name": "Belem",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Bobashi de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Boshindó",
                "countryCode": "MX"
            },
            {
                "name": "Bosques de la Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Cabecera de Indígenas",
                "countryCode": "MX"
            },
            {
                "name": "Cacalomacan",
                "countryCode": "MX"
            },
            {
                "name": "Caja de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Calimaya",
                "countryCode": "MX"
            },
            {
                "name": "Calixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Calvario Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Calvario del Carmen",
                "countryCode": "MX"
            },
            {
                "name": "Canalejas",
                "countryCode": "MX"
            },
            {
                "name": "Capula",
                "countryCode": "MX"
            },
            {
                "name": "Capulhuac de Mirafuentes",
                "countryCode": "MX"
            },
            {
                "name": "Carapán",
                "countryCode": "MX"
            },
            {
                "name": "Casa Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Casas Viejas",
                "countryCode": "MX"
            },
            {
                "name": "Caserío de Cortés",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Cisneros",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Guadarrama",
                "countryCode": "MX"
            },
            {
                "name": "Cedro de la Manzana",
                "countryCode": "MX"
            },
            {
                "name": "Celayita",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos del Pilar",
                "countryCode": "MX"
            },
            {
                "name": "Cerro La Calera",
                "countryCode": "MX"
            },
            {
                "name": "Cerro de San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Cerro del Murciélago",
                "countryCode": "MX"
            },
            {
                "name": "Chalchihuapan",
                "countryCode": "MX"
            },
            {
                "name": "Chalco",
                "countryCode": "MX"
            },
            {
                "name": "Chalma",
                "countryCode": "MX"
            },
            {
                "name": "Chalmita",
                "countryCode": "MX"
            },
            {
                "name": "Chapa de Mota",
                "countryCode": "MX"
            },
            {
                "name": "Chaparaco",
                "countryCode": "MX"
            },
            {
                "name": "Chapultepec",
                "countryCode": "MX"
            },
            {
                "name": "Chiautla",
                "countryCode": "MX"
            },
            {
                "name": "Chicoloapan",
                "countryCode": "MX"
            },
            {
                "name": "Chiconcuac",
                "countryCode": "MX"
            },
            {
                "name": "Chilchota",
                "countryCode": "MX"
            },
            {
                "name": "Chiltepec de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Chimalpa Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Chosto de los Jarros",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad López Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Nezahualcoyotl",
                "countryCode": "MX"
            },
            {
                "name": "Coacalco",
                "countryCode": "MX"
            },
            {
                "name": "Coamilpa de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "Coatepec Harinas",
                "countryCode": "MX"
            },
            {
                "name": "Cocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 2 de Septiembre",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 3 de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 3 de Mayo (La Cruz)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Adolfo López Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Agrícola Analco",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Agrícola Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Arboledas (San Andrés)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Aviación Autopan",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Azteca",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Bellavista",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Doctor Gustavo Baz",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Emiliano Zapata Ejido de Tenancingo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Guadalupe Victoria (La Capilla)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Gustavo Baz Prada",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Isidro Fabela",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lindavista",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lázaro Cárdenas (Los Hornos)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Palma",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Reforma Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Rincón Villa del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Río Lerma (Tic Ti)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Francisco de Asís",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Ramón",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Tulteca Teopan",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Wenceslao Labra",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Mirador",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Pirame",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Salado",
                "countryCode": "MX"
            },
            {
                "name": "Colonia la Asunción",
                "countryCode": "MX"
            },
            {
                "name": "Colonia la Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Aguiluchos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Cedros",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Remedios",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Colorines",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Caro",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Jolalpan",
                "countryCode": "MX"
            },
            {
                "name": "Concepción del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Concepción la Venta",
                "countryCode": "MX"
            },
            {
                "name": "Conejeras",
                "countryCode": "MX"
            },
            {
                "name": "Conjunto Habitacional Ecológico SUTEYM",
                "countryCode": "MX"
            },
            {
                "name": "Conjunto Urbano la Loma I",
                "countryCode": "MX"
            },
            {
                "name": "Contepec",
                "countryCode": "MX"
            },
            {
                "name": "Coscomate del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Coyotepec",
                "countryCode": "MX"
            },
            {
                "name": "Cozotlán Norte",
                "countryCode": "MX"
            },
            {
                "name": "Cuadrilla Vieja",
                "countryCode": "MX"
            },
            {
                "name": "Cuadrilla de Dolores",
                "countryCode": "MX"
            },
            {
                "name": "Cuautitlán",
                "countryCode": "MX"
            },
            {
                "name": "Cuautlacingo",
                "countryCode": "MX"
            },
            {
                "name": "Cuecuecuatitla",
                "countryCode": "MX"
            },
            {
                "name": "Cuendo",
                "countryCode": "MX"
            },
            {
                "name": "Denjhi",
                "countryCode": "MX"
            },
            {
                "name": "Detiña (San Antonio Detiña)",
                "countryCode": "MX"
            },
            {
                "name": "Dios Padre",
                "countryCode": "MX"
            },
            {
                "name": "Dolores",
                "countryCode": "MX"
            },
            {
                "name": "Dolores Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Dongu",
                "countryCode": "MX"
            },
            {
                "name": "Dotegiare",
                "countryCode": "MX"
            },
            {
                "name": "Doxhicho",
                "countryCode": "MX"
            },
            {
                "name": "Doxteje Barrio Primero",
                "countryCode": "MX"
            },
            {
                "name": "Doxteje Centro",
                "countryCode": "MX"
            },
            {
                "name": "Ecatepec de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Ecatzingo de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Loma de Malacota",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Miraflores",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Palma (Ejido San Francisco)",
                "countryCode": "MX"
            },
            {
                "name": "Ejido San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "Ejido San Diego",
                "countryCode": "MX"
            },
            {
                "name": "Ejido San Lorenzo Cuauhtenco",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de Coscomate del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de Dolores",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de Mozoquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de San Mateo Coapexco",
                "countryCode": "MX"
            },
            {
                "name": "Ejido de la Finca",
                "countryCode": "MX"
            },
            {
                "name": "Ejido del Tejocote",
                "countryCode": "MX"
            },
            {
                "name": "Ejido del Tunal Nenaxi",
                "countryCode": "MX"
            },
            {
                "name": "Ejido el Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido la Soledad",
                "countryCode": "MX"
            },
            {
                "name": "El Arco",
                "countryCode": "MX"
            },
            {
                "name": "El Cabi",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen (El Desierto del Carmen)",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "El Cerrillo Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "El Cerrito",
                "countryCode": "MX"
            },
            {
                "name": "El Colorado",
                "countryCode": "MX"
            },
            {
                "name": "El Coloso",
                "countryCode": "MX"
            },
            {
                "name": "El Contadero de Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "El Curtidor",
                "countryCode": "MX"
            },
            {
                "name": "El Cóporo",
                "countryCode": "MX"
            },
            {
                "name": "El Esclavo",
                "countryCode": "MX"
            },
            {
                "name": "El Espinal",
                "countryCode": "MX"
            },
            {
                "name": "El Habillal",
                "countryCode": "MX"
            },
            {
                "name": "El Hielo",
                "countryCode": "MX"
            },
            {
                "name": "El Jacal",
                "countryCode": "MX"
            },
            {
                "name": "El Llano Santa María",
                "countryCode": "MX"
            },
            {
                "name": "El Llano del Compromiso",
                "countryCode": "MX"
            },
            {
                "name": "El Magueyal",
                "countryCode": "MX"
            },
            {
                "name": "El Mogote",
                "countryCode": "MX"
            },
            {
                "name": "El Obraje",
                "countryCode": "MX"
            },
            {
                "name": "El Ocotal",
                "countryCode": "MX"
            },
            {
                "name": "El Oro de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "El Palacio",
                "countryCode": "MX"
            },
            {
                "name": "El Palmito",
                "countryCode": "MX"
            },
            {
                "name": "El Pedregal de Guadalupe Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "El Pilar",
                "countryCode": "MX"
            },
            {
                "name": "El Pino",
                "countryCode": "MX"
            },
            {
                "name": "El Plan",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir I",
                "countryCode": "MX"
            },
            {
                "name": "El Potrero",
                "countryCode": "MX"
            },
            {
                "name": "El Potrero de San Diego",
                "countryCode": "MX"
            },
            {
                "name": "El Progreso Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "El Puerto Magú",
                "countryCode": "MX"
            },
            {
                "name": "El Recodo de San José Axalco",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón de la Candelaria",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón de los Perales",
                "countryCode": "MX"
            },
            {
                "name": "El Rosal",
                "countryCode": "MX"
            },
            {
                "name": "El Salitre",
                "countryCode": "MX"
            },
            {
                "name": "El Tepetatal",
                "countryCode": "MX"
            },
            {
                "name": "El Terrero",
                "countryCode": "MX"
            },
            {
                "name": "El Águila (La Mesa)",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata (San José Bata)",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata (Santo Domingo)",
                "countryCode": "MX"
            },
            {
                "name": "Emilio Portes Gil",
                "countryCode": "MX"
            },
            {
                "name": "Epitacio Huerta",
                "countryCode": "MX"
            },
            {
                "name": "Etúcuaro",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Hacienda Santa Inés",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Hacienda de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Rancho San Dimas",
                "countryCode": "MX"
            },
            {
                "name": "Ex-hacienda de Xalpa",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Colinas del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Hacienda del Bosque",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Real de San Pablo",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Rinconada del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento la Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento y Club de Golf los Encinos",
                "countryCode": "MX"
            },
            {
                "name": "Fracción San Roque (El Prieto)",
                "countryCode": "MX"
            },
            {
                "name": "Fresno Nichi",
                "countryCode": "MX"
            },
            {
                "name": "Fuentes del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Fábrica Concepción",
                "countryCode": "MX"
            },
            {
                "name": "Galaxias Toluca",
                "countryCode": "MX"
            },
            {
                "name": "Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Ganzda",
                "countryCode": "MX"
            },
            {
                "name": "Granjas Ampliación Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Cachi",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Coté",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Gunyo Poniente (San José Gunyo)",
                "countryCode": "MX"
            },
            {
                "name": "Huancito",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetoca",
                "countryCode": "MX"
            },
            {
                "name": "Huemetla",
                "countryCode": "MX"
            },
            {
                "name": "Hueypoxtla",
                "countryCode": "MX"
            },
            {
                "name": "Huilango",
                "countryCode": "MX"
            },
            {
                "name": "Huitzoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Huixquilucan",
                "countryCode": "MX"
            },
            {
                "name": "Huixquilucan de Degollado",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapaluca",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapan de la Sal",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapan del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuaca de Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuaca de Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Jacona de Plancarte",
                "countryCode": "MX"
            },
            {
                "name": "Jalmolonga (La Hacienda)",
                "countryCode": "MX"
            },
            {
                "name": "Jalpa de los Baños",
                "countryCode": "MX"
            },
            {
                "name": "Jaltenco",
                "countryCode": "MX"
            },
            {
                "name": "Janambo",
                "countryCode": "MX"
            },
            {
                "name": "Janamuato",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Carranza (Rancho de Jesús)",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Jesús del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Jicaltepec Autopan",
                "countryCode": "MX"
            },
            {
                "name": "Jicaltepec Cuexcontitlán",
                "countryCode": "MX"
            },
            {
                "name": "Jilotepec de Molina Enríquez",
                "countryCode": "MX"
            },
            {
                "name": "Jilotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Jiquipilco",
                "countryCode": "MX"
            },
            {
                "name": "Jocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Joquicingo",
                "countryCode": "MX"
            },
            {
                "name": "Jorge Jiménez Cantú",
                "countryCode": "MX"
            },
            {
                "name": "Jorobas",
                "countryCode": "MX"
            },
            {
                "name": "Juchitepec",
                "countryCode": "MX"
            },
            {
                "name": "La Albarrada (San Francisco la Albarrada)",
                "countryCode": "MX"
            },
            {
                "name": "La Cabecera",
                "countryCode": "MX"
            },
            {
                "name": "La Cabecera Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Calle",
                "countryCode": "MX"
            },
            {
                "name": "La Campanilla",
                "countryCode": "MX"
            },
            {
                "name": "La Candelaria Tlapala",
                "countryCode": "MX"
            },
            {
                "name": "La Cantera",
                "countryCode": "MX"
            },
            {
                "name": "La Caridad",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada",
                "countryCode": "MX"
            },
            {
                "name": "La Ciénega",
                "countryCode": "MX"
            },
            {
                "name": "La Colonia",
                "countryCode": "MX"
            },
            {
                "name": "La Compañía",
                "countryCode": "MX"
            },
            {
                "name": "La Comunidad",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción Coatipac (La Conchita)",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción Enyege",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción Xochicuautla",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción de los Baños",
                "countryCode": "MX"
            },
            {
                "name": "La Constitución Toltepec",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz y Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia Sector Uno (La Estancia)",
                "countryCode": "MX"
            },
            {
                "name": "La Finca",
                "countryCode": "MX"
            },
            {
                "name": "La Glorieta",
                "countryCode": "MX"
            },
            {
                "name": "La Guadalupana",
                "countryCode": "MX"
            },
            {
                "name": "La Herradura",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Loma",
                "countryCode": "MX"
            },
            {
                "name": "La Loma Cuexcontitlán",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena Chichicaspa",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena Tenexpan",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena de los Reyes (La Magdalena)",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa de Chosto",
                "countryCode": "MX"
            },
            {
                "name": "La Pastoría",
                "countryCode": "MX"
            },
            {
                "name": "La Planada",
                "countryCode": "MX"
            },
            {
                "name": "La Planada (El Arenal)",
                "countryCode": "MX"
            },
            {
                "name": "La Presita Segundo Cuartel",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia",
                "countryCode": "MX"
            },
            {
                "name": "La Puerta del Pilar",
                "countryCode": "MX"
            },
            {
                "name": "La Purificación",
                "countryCode": "MX"
            },
            {
                "name": "La Rosa",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad Barrio",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "La Y",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Tabernillas (El Resbaloso)",
                "countryCode": "MX"
            },
            {
                "name": "Las Huertas",
                "countryCode": "MX"
            },
            {
                "name": "Las Manzanas",
                "countryCode": "MX"
            },
            {
                "name": "Las Peñas",
                "countryCode": "MX"
            },
            {
                "name": "Las Pintas",
                "countryCode": "MX"
            },
            {
                "name": "Las Ranas",
                "countryCode": "MX"
            },
            {
                "name": "Las Rosas",
                "countryCode": "MX"
            },
            {
                "name": "Lerma de Villada",
                "countryCode": "MX"
            },
            {
                "name": "Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Llano de Zacapexco",
                "countryCode": "MX"
            },
            {
                "name": "Llano de la Y",
                "countryCode": "MX"
            },
            {
                "name": "Llano de las Flores (Barrio del Hueso)",
                "countryCode": "MX"
            },
            {
                "name": "Loma Alta",
                "countryCode": "MX"
            },
            {
                "name": "Loma Alta Taxhimay",
                "countryCode": "MX"
            },
            {
                "name": "Loma Bonita",
                "countryCode": "MX"
            },
            {
                "name": "Loma Larga",
                "countryCode": "MX"
            },
            {
                "name": "Loma Larga (Barrio de Loma Larga)",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Guadalupe (La Biznaga)",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Loma de San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Loma de San José",
                "countryCode": "MX"
            },
            {
                "name": "Loma de San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Loma del Río",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Altavista",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de San Pablo",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Tenopalco",
                "countryCode": "MX"
            },
            {
                "name": "Los Arana",
                "countryCode": "MX"
            },
            {
                "name": "Los Berros",
                "countryCode": "MX"
            },
            {
                "name": "Los Hucuares",
                "countryCode": "MX"
            },
            {
                "name": "Los Nogales",
                "countryCode": "MX"
            },
            {
                "name": "Los Remedios",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes Acaquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes Acatlixhuayán",
                "countryCode": "MX"
            },
            {
                "name": "Los Saucos",
                "countryCode": "MX"
            },
            {
                "name": "Luvianos",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Atlicpac",
                "countryCode": "MX"
            },
            {
                "name": "Malinalco",
                "countryCode": "MX"
            },
            {
                "name": "Manto del Río Ejido",
                "countryCode": "MX"
            },
            {
                "name": "Manzana Quinta (La Cañada)",
                "countryCode": "MX"
            },
            {
                "name": "Manzana Segunda",
                "countryCode": "MX"
            },
            {
                "name": "Manzana Sexta Parte Centro",
                "countryCode": "MX"
            },
            {
                "name": "Manzana Tercera de Santa Cruz Tepexpan",
                "countryCode": "MX"
            },
            {
                "name": "Mavoro",
                "countryCode": "MX"
            },
            {
                "name": "Maxtleca de Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Mayorazgo de León",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Metepec",
                "countryCode": "MX"
            },
            {
                "name": "Mexicaltzingo",
                "countryCode": "MX"
            },
            {
                "name": "Mextepec",
                "countryCode": "MX"
            },
            {
                "name": "Mezapa la Fábrica",
                "countryCode": "MX"
            },
            {
                "name": "Mina México",
                "countryCode": "MX"
            },
            {
                "name": "Mina Vieja",
                "countryCode": "MX"
            },
            {
                "name": "Miraflores",
                "countryCode": "MX"
            },
            {
                "name": "Molino Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Monte Calvario",
                "countryCode": "MX"
            },
            {
                "name": "Montecillo",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Naucalpan de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Nepantla de Sor Juana Inés de la Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Romero",
                "countryCode": "MX"
            },
            {
                "name": "Nopaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Ameyalco",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Ocopulco",
                "countryCode": "MX"
            },
            {
                "name": "Ocoyoacac",
                "countryCode": "MX"
            },
            {
                "name": "Octeyuco Dos Mil",
                "countryCode": "MX"
            },
            {
                "name": "Ocuilan",
                "countryCode": "MX"
            },
            {
                "name": "Ocuilan de Arteaga",
                "countryCode": "MX"
            },
            {
                "name": "Ocumicho",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Otumba",
                "countryCode": "MX"
            },
            {
                "name": "Otzoloapan",
                "countryCode": "MX"
            },
            {
                "name": "Otzolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Oxtotipac",
                "countryCode": "MX"
            },
            {
                "name": "Ozumba de Alzate",
                "countryCode": "MX"
            },
            {
                "name": "Palizada",
                "countryCode": "MX"
            },
            {
                "name": "Palmar Chico",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Palos Amarillos (Palos Amarillos Yebuciví)",
                "countryCode": "MX"
            },
            {
                "name": "Papalotla",
                "countryCode": "MX"
            },
            {
                "name": "Paraje Trejo (El Chaparral)",
                "countryCode": "MX"
            },
            {
                "name": "Paraje el Mirador",
                "countryCode": "MX"
            },
            {
                "name": "Paraje la Pera",
                "countryCode": "MX"
            },
            {
                "name": "Paseos de San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Pathé",
                "countryCode": "MX"
            },
            {
                "name": "Playa Azul",
                "countryCode": "MX"
            },
            {
                "name": "Plaza Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Polotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Polotitlán de la Ilustración",
                "countryCode": "MX"
            },
            {
                "name": "Polvillos (San Bartolo Quinta Sección)",
                "countryCode": "MX"
            },
            {
                "name": "Polígonos",
                "countryCode": "MX"
            },
            {
                "name": "Popo Park",
                "countryCode": "MX"
            },
            {
                "name": "Porfirío Díaz",
                "countryCode": "MX"
            },
            {
                "name": "Pothé",
                "countryCode": "MX"
            },
            {
                "name": "Potreros",
                "countryCode": "MX"
            },
            {
                "name": "Prados San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Prados de San Juan",
                "countryCode": "MX"
            },
            {
                "name": "Primera de Analco",
                "countryCode": "MX"
            },
            {
                "name": "Profesor Carlos Hank González",
                "countryCode": "MX"
            },
            {
                "name": "Progreso Industrial",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "Puentecillas",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Escondido (Tepeolulco Puerto Escondido)",
                "countryCode": "MX"
            },
            {
                "name": "Purísima Concepción Mayorazgo",
                "countryCode": "MX"
            },
            {
                "name": "Quetzalapa",
                "countryCode": "MX"
            },
            {
                "name": "Quinto Barrio (Ejido Cahuacán)",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Alegre",
                "countryCode": "MX"
            },
            {
                "name": "Rincón Verde",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Aguirre",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Jaimes",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de los Pirules",
                "countryCode": "MX"
            },
            {
                "name": "Río Frío de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Salitrillo",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Berros",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Citlali",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Huitzizilapan",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Mextepec",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Mimbres",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín de las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Alejo",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Metla",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Timilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Tlalamac",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés de las Peras",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés de los Gama",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés del Pedregal",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Acahualco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Bonixi",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Enchisi",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Guaracha",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Nixini",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Solís",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tlaltecahuacán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Xahuento",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de la Laguna",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de las Huertas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio el Llanito",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio la Isla",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolito Tlaltelolco",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Cuautlalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Lanzados",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Morelos",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Oxtotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo del Llano",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo del Llano (San Isidro)",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo el Viejo",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Atlatlahuca",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardino",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos Autopan",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Nexquipayac",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Diego Huehuecalco",
                "countryCode": "MX"
            },
            {
                "name": "San Diego del Cerrito",
                "countryCode": "MX"
            },
            {
                "name": "San Diego la Huerta",
                "countryCode": "MX"
            },
            {
                "name": "San Diego los Padres Cuexcontitlán Sección 5 B",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Coamango",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Santiago",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe el Mirasol",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Acuautla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Ayotuzco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Chalchihuapan",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Chejé",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cuaxusco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Magú",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Mazapa",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Mihualtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Oxtotilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Putla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Solis",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tenopalco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tepeolulco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tepexoxica",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tlalcilalcalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tlaltica",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Xochicuautla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Ixtla",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Zepayautla",
                "countryCode": "MX"
            },
            {
                "name": "San Gaspar",
                "countryCode": "MX"
            },
            {
                "name": "San Gaspar Tonatico",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio Cuautzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro (El Reservado)",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Boxipe",
                "countryCode": "MX"
            },
            {
                "name": "San Jacinto",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Amanalco",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Bonchete",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Cuatro Vientos",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Ixtapantongo",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo de los Jarros",
                "countryCode": "MX"
            },
            {
                "name": "San Joaquín Coapango",
                "countryCode": "MX"
            },
            {
                "name": "San Joaquín del Monte",
                "countryCode": "MX"
            },
            {
                "name": "San Jorge Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "San José Barbabosa",
                "countryCode": "MX"
            },
            {
                "name": "San José Boqui",
                "countryCode": "MX"
            },
            {
                "name": "San José Chalmita",
                "countryCode": "MX"
            },
            {
                "name": "San José Guadalupe Otzacatipan",
                "countryCode": "MX"
            },
            {
                "name": "San José Mezapa Sección I",
                "countryCode": "MX"
            },
            {
                "name": "San José Pathé",
                "countryCode": "MX"
            },
            {
                "name": "San José Tejamanil",
                "countryCode": "MX"
            },
            {
                "name": "San José Tenería (Tenería)",
                "countryCode": "MX"
            },
            {
                "name": "San José Tlacotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San José Toxi",
                "countryCode": "MX"
            },
            {
                "name": "San José Villa de Allende",
                "countryCode": "MX"
            },
            {
                "name": "San José del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "San José del Puente",
                "countryCode": "MX"
            },
            {
                "name": "San José del Rincón Centro",
                "countryCode": "MX"
            },
            {
                "name": "San José del Sitio",
                "countryCode": "MX"
            },
            {
                "name": "San José del Tunal",
                "countryCode": "MX"
            },
            {
                "name": "San José el Cuartel",
                "countryCode": "MX"
            },
            {
                "name": "San José el Vidrio",
                "countryCode": "MX"
            },
            {
                "name": "San José las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "San José las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Juan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Atezcapan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Coajomulco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cote Ejido",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Coxtocan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Daxthi",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Jalpa Centro",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tehuixtitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tepecoculco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tlacotompa (Tlacotompa)",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tomasquillo Herradura",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Totolapan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tuxtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Xoconusco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de las Huertas",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de las Manzanas",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de los Jarros",
                "countryCode": "MX"
            },
            {
                "name": "San Juan y San Pedro Tezompa",
                "countryCode": "MX"
            },
            {
                "name": "San Juanico Sector Uno",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Huehuetitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Malacota",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Toxico",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Amalinalco",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Huitzilhuacán",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Texcaltitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Totolmaloya",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas del Pulque",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Anáhuac (Toriles)",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Boro",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Taxhimay",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Huixtoco",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Nepantla",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Tecomaxusco",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Tlazalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Yachihuacaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos de la Loma",
                "countryCode": "MX"
            },
            {
                "name": "San Martín",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Ahuatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Azcatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Cachihuapan",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Coapaxtongo",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Cuautlalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Ejido",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Obispo (San Martín San Pedro)",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Tequesquipan (Tequesquipan)",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Toltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Tuchicuitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "San Martín de las Pirámides",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Almomoloha",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Atenco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Capulhuac",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Coapexco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Huitzilzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Ixtacalco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Ixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Mozoquilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Tecalco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Texcalyacac",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Tlalchichilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Xoloc",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo el Viejo",
                "countryCode": "MX"
            },
            {
                "name": "San Matías Cuijingo",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Agua Bendita",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Almoloyan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Atlamajac",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Coatlinchán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Enyege",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Hila",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ixtapan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Jaltocan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Laderas",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Mimlapan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Oxtotilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tecomatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tecpan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tenochtitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tlaixpan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Totocuitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Xoltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Yuxtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Centro",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Amealco",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Tolentino",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás el Oro",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Atlazalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Atotonilco",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Huantepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Ixayoc",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Ixquitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Tecalco",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Tejalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo de las Salinas",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo de los Gallos",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo de los Remedios",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Abajo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Arriba",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Arriba 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Chiautzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Cholula",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Denxhi Centro",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Huitzizilapan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Limón",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Los Baños",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Nexapa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tarímbaro",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tejalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tenayac",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de la Hortaliza (Ejido Almoloyán)",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de los Baños",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de los Metates",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro del Rincón",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro del Rosal",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "San Roque",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador Atenco",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador Tizatlalli",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Chimalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Xolalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Simonito",
                "countryCode": "MX"
            },
            {
                "name": "San Simón de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "San Simón de la Laguna",
                "countryCode": "MX"
            },
            {
                "name": "San Simón el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente (Calpulalpan Tercera Manzana)",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Chimalhuacán",
                "countryCode": "MX"
            },
            {
                "name": "Sanata Lucía",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Ixtlahuaca (Santa Ana Ixtlahuacingo)",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Ixtlahuatzingo (Santa Ana)",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Jilotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Mayorazgo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Nextlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Nichi Ejido",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Zicatecoyan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana la Ladera",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Ayotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Atizapán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Bombatevi",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Cuauhtenco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Huitzizilapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Mextepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Pueblo Nuevo (Pueblo Nuevo)",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santa Gertrudis",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel Chalma",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel Ixtapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Juana Centro",
                "countryCode": "MX"
            },
            {
                "name": "Santa Juana Primera Sección",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "MX"
            },
            {
                "name": "Santa Martha",
                "countryCode": "MX"
            },
            {
                "name": "Santa María",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ajoloapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Aranzazú (Santa María)",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Canchesdá",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Chimalhuacán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Citendejé",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Cuevas",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Endare",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huecatitla",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huexoculco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Jajalpa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Magdalena Cahuacán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Mazatla",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nativitas",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Palapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Pipioltepec (Pipioltepec)",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tecuanulco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Llano",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santa María la Asunción",
                "countryCode": "MX"
            },
            {
                "name": "Santa Mónica",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Acutzilapan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Casandeje",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Chimalpa (Chimalpa)",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Citendejé",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Coachochitlan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Cuautenco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Cuautlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Huitlapaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Mamalhuazuca",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Oxtempan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Oxtotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tejocotillos",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepopula",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Teyahualco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tianguistenco de Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tolman",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yancuitlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yeché",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Zacualuca",
                "countryCode": "MX"
            },
            {
                "name": "Santiago del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santiaguito Tlalcilalcalli",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Shomege (Shomege)",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo de Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Atzingo",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Chiconautla",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás de los Plátanos",
                "countryCode": "MX"
            },
            {
                "name": "Sebastián Lerdo de Tejada",
                "countryCode": "MX"
            },
            {
                "name": "Sección del Cerrito",
                "countryCode": "MX"
            },
            {
                "name": "Sierra de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Sitio Ejido",
                "countryCode": "MX"
            },
            {
                "name": "Solalpan 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Soyaniquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Sultepec",
                "countryCode": "MX"
            },
            {
                "name": "Tamándaro",
                "countryCode": "MX"
            },
            {
                "name": "Tanaquillo",
                "countryCode": "MX"
            },
            {
                "name": "Tangancícuaro de Arista",
                "countryCode": "MX"
            },
            {
                "name": "Tarécuato",
                "countryCode": "MX"
            },
            {
                "name": "Tecamachalco",
                "countryCode": "MX"
            },
            {
                "name": "Tecaxic",
                "countryCode": "MX"
            },
            {
                "name": "Techachaltitla",
                "countryCode": "MX"
            },
            {
                "name": "Techichili",
                "countryCode": "MX"
            },
            {
                "name": "Tecoac (Santa María Nativitas)",
                "countryCode": "MX"
            },
            {
                "name": "Tecomatepec",
                "countryCode": "MX"
            },
            {
                "name": "Tecámac",
                "countryCode": "MX"
            },
            {
                "name": "Tecámac de Felipe Villanueva",
                "countryCode": "MX"
            },
            {
                "name": "Tejalpa",
                "countryCode": "MX"
            },
            {
                "name": "Tejupilco",
                "countryCode": "MX"
            },
            {
                "name": "Tejupilco de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Temamatla",
                "countryCode": "MX"
            },
            {
                "name": "Temascalapa",
                "countryCode": "MX"
            },
            {
                "name": "Temascalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Temascaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Temascaltepec de González",
                "countryCode": "MX"
            },
            {
                "name": "Temoaya",
                "countryCode": "MX"
            },
            {
                "name": "Tenancingo",
                "countryCode": "MX"
            },
            {
                "name": "Tenancingo de Degollado",
                "countryCode": "MX"
            },
            {
                "name": "Tenango de Arista",
                "countryCode": "MX"
            },
            {
                "name": "Tenango del Aire",
                "countryCode": "MX"
            },
            {
                "name": "Tenería",
                "countryCode": "MX"
            },
            {
                "name": "Tengüecho",
                "countryCode": "MX"
            },
            {
                "name": "Tenjay",
                "countryCode": "MX"
            },
            {
                "name": "Teoloyucan",
                "countryCode": "MX"
            },
            {
                "name": "Teotihuacán",
                "countryCode": "MX"
            },
            {
                "name": "Teotihuacán de Arista",
                "countryCode": "MX"
            },
            {
                "name": "Tepalcatepec",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlaoxtoc",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlaoxtoc de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlixpa",
                "countryCode": "MX"
            },
            {
                "name": "Tepetongo",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tepexpan",
                "countryCode": "MX"
            },
            {
                "name": "Tepotzotlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepoxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Tequexquináhuac",
                "countryCode": "MX"
            },
            {
                "name": "Tequisistlán",
                "countryCode": "MX"
            },
            {
                "name": "Tequisistlán Primero",
                "countryCode": "MX"
            },
            {
                "name": "Tequixquiac",
                "countryCode": "MX"
            },
            {
                "name": "Tercera Manzana de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Teremendo",
                "countryCode": "MX"
            },
            {
                "name": "Texcaltitlán",
                "countryCode": "MX"
            },
            {
                "name": "Texcalyacac",
                "countryCode": "MX"
            },
            {
                "name": "Texcapilla",
                "countryCode": "MX"
            },
            {
                "name": "Texcoco de Mora",
                "countryCode": "MX"
            },
            {
                "name": "Tezoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Timilpan",
                "countryCode": "MX"
            },
            {
                "name": "Tixmadeje Chiquito",
                "countryCode": "MX"
            },
            {
                "name": "Tlacomulco",
                "countryCode": "MX"
            },
            {
                "name": "Tlalmanalco",
                "countryCode": "MX"
            },
            {
                "name": "Tlalnepantla",
                "countryCode": "MX"
            },
            {
                "name": "Tlalnepantla de Baz",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltecahuacán",
                "countryCode": "MX"
            },
            {
                "name": "Tlaminca",
                "countryCode": "MX"
            },
            {
                "name": "Tlatlaya",
                "countryCode": "MX"
            },
            {
                "name": "Tlazala de Fabela",
                "countryCode": "MX"
            },
            {
                "name": "Tlazazalca",
                "countryCode": "MX"
            },
            {
                "name": "Toluca",
                "countryCode": "MX"
            },
            {
                "name": "Tonanitla",
                "countryCode": "MX"
            },
            {
                "name": "Tonatico",
                "countryCode": "MX"
            },
            {
                "name": "Totolmajac",
                "countryCode": "MX"
            },
            {
                "name": "Transfiguración",
                "countryCode": "MX"
            },
            {
                "name": "Tres Estrellas",
                "countryCode": "MX"
            },
            {
                "name": "Tres Mezquites",
                "countryCode": "MX"
            },
            {
                "name": "Tulantongo",
                "countryCode": "MX"
            },
            {
                "name": "Tultepec",
                "countryCode": "MX"
            },
            {
                "name": "Tultitlán de Mariano Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Tupátaro",
                "countryCode": "MX"
            },
            {
                "name": "Turcio Segunda Sección",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Acaquilpan",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Unidad San Miguel Jagüeyes",
                "countryCode": "MX"
            },
            {
                "name": "Urén",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Vare Chiquichuca",
                "countryCode": "MX"
            },
            {
                "name": "Veintidós de Febrero",
                "countryCode": "MX"
            },
            {
                "name": "Venta de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Venta de Ocotillos",
                "countryCode": "MX"
            },
            {
                "name": "Villa Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Villa Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Luvianos",
                "countryCode": "MX"
            },
            {
                "name": "Villa Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Villa URBI del Rey",
                "countryCode": "MX"
            },
            {
                "name": "Villa Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Almoloya de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Villa del Carbón",
                "countryCode": "MX"
            },
            {
                "name": "Villachuato",
                "countryCode": "MX"
            },
            {
                "name": "Villas de la Laguna",
                "countryCode": "MX"
            },
            {
                "name": "Villas del Campo",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Xalatlaco",
                "countryCode": "MX"
            },
            {
                "name": "Xhimojay",
                "countryCode": "MX"
            },
            {
                "name": "Xhitey",
                "countryCode": "MX"
            },
            {
                "name": "Xhixhata",
                "countryCode": "MX"
            },
            {
                "name": "Xico",
                "countryCode": "MX"
            },
            {
                "name": "Xochimanca",
                "countryCode": "MX"
            },
            {
                "name": "Xocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Xometla",
                "countryCode": "MX"
            },
            {
                "name": "Xonacatlan",
                "countryCode": "MX"
            },
            {
                "name": "Zacamulpa",
                "countryCode": "MX"
            },
            {
                "name": "Zacamulpa Tlalmimilolpan",
                "countryCode": "MX"
            },
            {
                "name": "Zacango",
                "countryCode": "MX"
            },
            {
                "name": "Zacazonapan",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "Zapotlán",
                "countryCode": "MX"
            },
            {
                "name": "Zentlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Zinacantepec",
                "countryCode": "MX"
            },
            {
                "name": "Zipiajo",
                "countryCode": "MX"
            },
            {
                "name": "Zopoco",
                "countryCode": "MX"
            },
            {
                "name": "Zoquiapan",
                "countryCode": "MX"
            },
            {
                "name": "Zoyatzingo",
                "countryCode": "MX"
            },
            {
                "name": "Zula",
                "countryCode": "MX"
            },
            {
                "name": "Zumpahuacán",
                "countryCode": "MX"
            },
            {
                "name": "Zumpango",
                "countryCode": "MX"
            },
            {
                "name": "Acuitzio",
                "countryCode": "MX"
            },
            {
                "name": "Acuítzio del Canje",
                "countryCode": "MX"
            },
            {
                "name": "Agostitlán",
                "countryCode": "MX"
            },
            {
                "name": "Agua Caliente",
                "countryCode": "MX"
            },
            {
                "name": "Aguililla",
                "countryCode": "MX"
            },
            {
                "name": "Ahuirán",
                "countryCode": "MX"
            },
            {
                "name": "Ajuno",
                "countryCode": "MX"
            },
            {
                "name": "Angahuán",
                "countryCode": "MX"
            },
            {
                "name": "Angamacutiro de la Unión",
                "countryCode": "MX"
            },
            {
                "name": "Ansihuacuaro",
                "countryCode": "MX"
            },
            {
                "name": "Antúnez",
                "countryCode": "MX"
            },
            {
                "name": "Antúnez (Morelos)",
                "countryCode": "MX"
            },
            {
                "name": "Apatzingán",
                "countryCode": "MX"
            },
            {
                "name": "Apeo",
                "countryCode": "MX"
            },
            {
                "name": "Apo",
                "countryCode": "MX"
            },
            {
                "name": "Aporo",
                "countryCode": "MX"
            },
            {
                "name": "Aputzio de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Aquila",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Córdova Morán",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Arantepacua",
                "countryCode": "MX"
            },
            {
                "name": "Aranza",
                "countryCode": "MX"
            },
            {
                "name": "Araró",
                "countryCode": "MX"
            },
            {
                "name": "Ario de Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Ario de Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Arteaga",
                "countryCode": "MX"
            },
            {
                "name": "Atacheo de Regalado",
                "countryCode": "MX"
            },
            {
                "name": "Atapaneo",
                "countryCode": "MX"
            },
            {
                "name": "Atapán",
                "countryCode": "MX"
            },
            {
                "name": "Atecucario de la Constitución (Atecuario)",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco",
                "countryCode": "MX"
            },
            {
                "name": "Belisario Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "Bellas Fuentes",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Bocaneo (San Pedro)",
                "countryCode": "MX"
            },
            {
                "name": "Bonifacio Moreno (El Aguaje)",
                "countryCode": "MX"
            },
            {
                "name": "Briseñas",
                "countryCode": "MX"
            },
            {
                "name": "Briseñas de Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista Tomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Caltzontzín",
                "countryCode": "MX"
            },
            {
                "name": "Campestre Tarímbaro",
                "countryCode": "MX"
            },
            {
                "name": "Camémbaro",
                "countryCode": "MX"
            },
            {
                "name": "Cantabria",
                "countryCode": "MX"
            },
            {
                "name": "Capacho",
                "countryCode": "MX"
            },
            {
                "name": "Capula",
                "countryCode": "MX"
            },
            {
                "name": "Capácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Carona",
                "countryCode": "MX"
            },
            {
                "name": "Carácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Caurio de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Cañada de Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Cenobio Moreno",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Colorado de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Chapa",
                "countryCode": "MX"
            },
            {
                "name": "Charapendo",
                "countryCode": "MX"
            },
            {
                "name": "Charapán",
                "countryCode": "MX"
            },
            {
                "name": "Charo",
                "countryCode": "MX"
            },
            {
                "name": "Chavinda",
                "countryCode": "MX"
            },
            {
                "name": "Cherán",
                "countryCode": "MX"
            },
            {
                "name": "Cherán Atzicuirín (Cheranástico)",
                "countryCode": "MX"
            },
            {
                "name": "Chilchota",
                "countryCode": "MX"
            },
            {
                "name": "Chiquimitío",
                "countryCode": "MX"
            },
            {
                "name": "Chitejé de Garabato",
                "countryCode": "MX"
            },
            {
                "name": "Chucándiro",
                "countryCode": "MX"
            },
            {
                "name": "Chupio",
                "countryCode": "MX"
            },
            {
                "name": "Churintzio",
                "countryCode": "MX"
            },
            {
                "name": "Churumuco de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Coahuayana Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Coahuayana de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Coajomulco",
                "countryCode": "MX"
            },
            {
                "name": "Coalcomán de Vázquez Pallares",
                "countryCode": "MX"
            },
            {
                "name": "Cocucho",
                "countryCode": "MX"
            },
            {
                "name": "Coeneo de la Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Cojumatlán de Régules",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Antorcha Campesina (Santa Rosa)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Aquiles Córdoba Morán",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Ecológica Asociación de Lucha Social (Lucha Social)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Emiliano Zapata (San Juan Zitácuaro)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Vista Bella (Lomas del Peaje)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Mirador (Llano del Ejido)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia las Malvinas (Colonia Antorcha)",
                "countryCode": "MX"
            },
            {
                "name": "Comachuén",
                "countryCode": "MX"
            },
            {
                "name": "Comanjá",
                "countryCode": "MX"
            },
            {
                "name": "Condémbaro",
                "countryCode": "MX"
            },
            {
                "name": "Conjunto Habitacional Villas del Pedregal",
                "countryCode": "MX"
            },
            {
                "name": "Conjunto Habitacional el Trébol",
                "countryCode": "MX"
            },
            {
                "name": "Copándaro (Copándaro del Cuatro)",
                "countryCode": "MX"
            },
            {
                "name": "Copándaro de Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Corupo",
                "countryCode": "MX"
            },
            {
                "name": "Cotija de la Paz",
                "countryCode": "MX"
            },
            {
                "name": "Crescencio Morales (San Mateo)",
                "countryCode": "MX"
            },
            {
                "name": "Cuamio",
                "countryCode": "MX"
            },
            {
                "name": "Cuanajo",
                "countryCode": "MX"
            },
            {
                "name": "Cuaracurío",
                "countryCode": "MX"
            },
            {
                "name": "Cuartel la Mesa (El Asoleadero)",
                "countryCode": "MX"
            },
            {
                "name": "Cuatro Caminos",
                "countryCode": "MX"
            },
            {
                "name": "Cucuchucho",
                "countryCode": "MX"
            },
            {
                "name": "Cuitzeo del Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "Cumuato",
                "countryCode": "MX"
            },
            {
                "name": "Cuparátaro",
                "countryCode": "MX"
            },
            {
                "name": "Cupuán del Río",
                "countryCode": "MX"
            },
            {
                "name": "Curimeo",
                "countryCode": "MX"
            },
            {
                "name": "Curungueo",
                "countryCode": "MX"
            },
            {
                "name": "Cuto de la Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Cuto del Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "Dieciocho de Marzo",
                "countryCode": "MX"
            },
            {
                "name": "División del Norte",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Miguel Silva (San Guillermo)",
                "countryCode": "MX"
            },
            {
                "name": "Donaciano Ojeda",
                "countryCode": "MX"
            },
            {
                "name": "Ecuandureo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido el Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Alvareño",
                "countryCode": "MX"
            },
            {
                "name": "El Cahulote de Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín",
                "countryCode": "MX"
            },
            {
                "name": "El Ceñidor",
                "countryCode": "MX"
            },
            {
                "name": "El Chauz",
                "countryCode": "MX"
            },
            {
                "name": "El Colegio",
                "countryCode": "MX"
            },
            {
                "name": "El Cuenqueño",
                "countryCode": "MX"
            },
            {
                "name": "El Cuitzillo Grande",
                "countryCode": "MX"
            },
            {
                "name": "El Césped",
                "countryCode": "MX"
            },
            {
                "name": "El Durazno",
                "countryCode": "MX"
            },
            {
                "name": "El Fortín",
                "countryCode": "MX"
            },
            {
                "name": "El Fuerte",
                "countryCode": "MX"
            },
            {
                "name": "El Guaco",
                "countryCode": "MX"
            },
            {
                "name": "El Letrero",
                "countryCode": "MX"
            },
            {
                "name": "El Maluco",
                "countryCode": "MX"
            },
            {
                "name": "El Paracho",
                "countryCode": "MX"
            },
            {
                "name": "El Pedregal",
                "countryCode": "MX"
            },
            {
                "name": "El Platanal",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón de San Felipe (Tercera Manzana San Felipe)",
                "countryCode": "MX"
            },
            {
                "name": "El Sauz de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "El Tequesquite",
                "countryCode": "MX"
            },
            {
                "name": "El Tigre (Segunda Manzana de Crescencio Morales)",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Erongarícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Estación Queréndaro",
                "countryCode": "MX"
            },
            {
                "name": "Etúcuaro",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Hacienda de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Colinas Universidad",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Ex-Hacienda el Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Galaxia Tarímbaro",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Laureles Eréndira",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Metrópolis II",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Misión del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Monte Olivo",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Privadas del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Sarabia (Cerrito Pelón)",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Serrato (San Bartolo)",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Villa",
                "countryCode": "MX"
            },
            {
                "name": "Gambara",
                "countryCode": "MX"
            },
            {
                "name": "General Francisco Villa",
                "countryCode": "MX"
            },
            {
                "name": "Gildardo Magaña (Los Ángeles)",
                "countryCode": "MX"
            },
            {
                "name": "Guacamayas",
                "countryCode": "MX"
            },
            {
                "name": "Guándaro",
                "countryCode": "MX"
            },
            {
                "name": "Heróica Zitácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Huajúmbaro",
                "countryCode": "MX"
            },
            {
                "name": "Huandacareo",
                "countryCode": "MX"
            },
            {
                "name": "Huanguitío",
                "countryCode": "MX"
            },
            {
                "name": "Huaniqueo de Morales",
                "countryCode": "MX"
            },
            {
                "name": "Huetamo de Núñez",
                "countryCode": "MX"
            },
            {
                "name": "Huiramba",
                "countryCode": "MX"
            },
            {
                "name": "Héroes de Chapultepec (Rodeo de San Antonio)",
                "countryCode": "MX"
            },
            {
                "name": "Ibarra",
                "countryCode": "MX"
            },
            {
                "name": "Ichán",
                "countryCode": "MX"
            },
            {
                "name": "Ihuatzio",
                "countryCode": "MX"
            },
            {
                "name": "Indaparapeo",
                "countryCode": "MX"
            },
            {
                "name": "Infiernillo (Morelos de Infiernillo)",
                "countryCode": "MX"
            },
            {
                "name": "Irapeo",
                "countryCode": "MX"
            },
            {
                "name": "Iratzio",
                "countryCode": "MX"
            },
            {
                "name": "Irimbo",
                "countryCode": "MX"
            },
            {
                "name": "Isaac Arriaga (Santa Ana Mancera)",
                "countryCode": "MX"
            },
            {
                "name": "Istaro",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlán de los Hervores",
                "countryCode": "MX"
            },
            {
                "name": "J. Jesús Díaz Tzirio",
                "countryCode": "MX"
            },
            {
                "name": "Jacona",
                "countryCode": "MX"
            },
            {
                "name": "Jamaica",
                "countryCode": "MX"
            },
            {
                "name": "Janitzio",
                "countryCode": "MX"
            },
            {
                "name": "Jaripeo",
                "countryCode": "MX"
            },
            {
                "name": "Jarácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Jesús del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Jiquílpan de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos (La Yegüería)",
                "countryCode": "MX"
            },
            {
                "name": "Jucutacato",
                "countryCode": "MX"
            },
            {
                "name": "Jungapeo de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "La Aldea",
                "countryCode": "MX"
            },
            {
                "name": "La Angostura",
                "countryCode": "MX"
            },
            {
                "name": "La Calera (Nacimientos)",
                "countryCode": "MX"
            },
            {
                "name": "La Encarnación",
                "countryCode": "MX"
            },
            {
                "name": "La Ermita (Nueva Jerusalén)",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia de Amezcua",
                "countryCode": "MX"
            },
            {
                "name": "La Fundición (Quinta Manzana)",
                "countryCode": "MX"
            },
            {
                "name": "La Goleta",
                "countryCode": "MX"
            },
            {
                "name": "La Higuerilla (Los Lirios)",
                "countryCode": "MX"
            },
            {
                "name": "La Higuerita (Colonia San Rafael)",
                "countryCode": "MX"
            },
            {
                "name": "La Huacana",
                "countryCode": "MX"
            },
            {
                "name": "La Ibérica (La Gotera)",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa (La Mesa de Cedano)",
                "countryCode": "MX"
            },
            {
                "name": "La Mintzita (Piedra Dura)",
                "countryCode": "MX"
            },
            {
                "name": "La Mira",
                "countryCode": "MX"
            },
            {
                "name": "La Mira Tumbiscatio",
                "countryCode": "MX"
            },
            {
                "name": "La Mojonera",
                "countryCode": "MX"
            },
            {
                "name": "La Orilla",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Palma (Las Palmas)",
                "countryCode": "MX"
            },
            {
                "name": "La Piedad",
                "countryCode": "MX"
            },
            {
                "name": "La Placita de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "La Plaza del Limón",
                "countryCode": "MX"
            },
            {
                "name": "La Rinconoda",
                "countryCode": "MX"
            },
            {
                "name": "La Sauceda",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "Lagunillas",
                "countryCode": "MX"
            },
            {
                "name": "Las Cañadas",
                "countryCode": "MX"
            },
            {
                "name": "Las Cañas",
                "countryCode": "MX"
            },
            {
                "name": "Las Cieneguitas",
                "countryCode": "MX"
            },
            {
                "name": "Las Letras",
                "countryCode": "MX"
            },
            {
                "name": "Loma de los Hoyos",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de la Maestranza",
                "countryCode": "MX"
            },
            {
                "name": "Lombardía",
                "countryCode": "MX"
            },
            {
                "name": "Los Cajones",
                "countryCode": "MX"
            },
            {
                "name": "Los Charcos",
                "countryCode": "MX"
            },
            {
                "name": "Los Guajes",
                "countryCode": "MX"
            },
            {
                "name": "Los Limones",
                "countryCode": "MX"
            },
            {
                "name": "Los Pilares",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes de Salgado",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas (La Purísima)",
                "countryCode": "MX"
            },
            {
                "name": "Macho de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Macutzio",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Villalongín",
                "countryCode": "MX"
            },
            {
                "name": "Manzana de San Luis",
                "countryCode": "MX"
            },
            {
                "name": "Manzana la Cofradía",
                "countryCode": "MX"
            },
            {
                "name": "Maravatío de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Mariano Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Mesón Nuevo (Cañada de la Magdalena)",
                "countryCode": "MX"
            },
            {
                "name": "Mineral de Angangueo",
                "countryCode": "MX"
            },
            {
                "name": "Montaña Monarca (Punta Altozano)",
                "countryCode": "MX"
            },
            {
                "name": "Monteleón",
                "countryCode": "MX"
            },
            {
                "name": "Morelia",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Nahuatzén",
                "countryCode": "MX"
            },
            {
                "name": "Naranja de Tapia",
                "countryCode": "MX"
            },
            {
                "name": "Nocupétaro",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Italia de Ruiz",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo San Juan Parangaricutiro",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Urecho",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Zirosto",
                "countryCode": "MX"
            },
            {
                "name": "Numarán",
                "countryCode": "MX"
            },
            {
                "name": "Nurío",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Ocurio",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Bucio",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Rana",
                "countryCode": "MX"
            },
            {
                "name": "Opopeo",
                "countryCode": "MX"
            },
            {
                "name": "Pajacuarán",
                "countryCode": "MX"
            },
            {
                "name": "Pamatácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Panindícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Paracho de Verduzco",
                "countryCode": "MX"
            },
            {
                "name": "Paracuaro",
                "countryCode": "MX"
            },
            {
                "name": "Parahuén",
                "countryCode": "MX"
            },
            {
                "name": "Parícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Hidalgo (Paso de Álamos)",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Núñez (Buenavista)",
                "countryCode": "MX"
            },
            {
                "name": "Pastor Ortíz",
                "countryCode": "MX"
            },
            {
                "name": "Patuán",
                "countryCode": "MX"
            },
            {
                "name": "Pedernales",
                "countryCode": "MX"
            },
            {
                "name": "Penjamillo de Degollado",
                "countryCode": "MX"
            },
            {
                "name": "Peribán de Ramos",
                "countryCode": "MX"
            },
            {
                "name": "Peña del Panal",
                "countryCode": "MX"
            },
            {
                "name": "Pichátaro",
                "countryCode": "MX"
            },
            {
                "name": "Pinzándaro",
                "countryCode": "MX"
            },
            {
                "name": "Pomacuarán",
                "countryCode": "MX"
            },
            {
                "name": "Poturo",
                "countryCode": "MX"
            },
            {
                "name": "Presa del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Puentecillas (Tercera Manzana de Zirahuato)",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Buenavista (Lázaro Cárdenas)",
                "countryCode": "MX"
            },
            {
                "name": "Purechucho",
                "countryCode": "MX"
            },
            {
                "name": "Puruarán",
                "countryCode": "MX"
            },
            {
                "name": "Puruándiro",
                "countryCode": "MX"
            },
            {
                "name": "Purépero de Echáiz",
                "countryCode": "MX"
            },
            {
                "name": "Puácuaro",
                "countryCode": "MX"
            },
            {
                "name": "Páreo",
                "countryCode": "MX"
            },
            {
                "name": "Pátzcuaro",
                "countryCode": "MX"
            },
            {
                "name": "Queréndaro",
                "countryCode": "MX"
            },
            {
                "name": "Quinceo",
                "countryCode": "MX"
            },
            {
                "name": "Quiroga",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Real Hacienda (Metrópolis)",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Cedeños (Rincón de Dolores)",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Curungueo",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Nicolás Romero (Cedros Tercera Manzana)",
                "countryCode": "MX"
            },
            {
                "name": "Romero de Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "Romero de Torres",
                "countryCode": "MX"
            },
            {
                "name": "Río Grande",
                "countryCode": "MX"
            },
            {
                "name": "Sahuayo",
                "countryCode": "MX"
            },
            {
                "name": "Sahuayo de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Salguero",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín del Maíz",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín del Pulque",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Coru",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ziróndaro",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Molinos",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tariácuri",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Villalongín",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio la Labor",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Cuitareo",
                "countryCode": "MX"
            },
            {
                "name": "San Benito",
                "countryCode": "MX"
            },
            {
                "name": "San Bernabé de las Canteras",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe de los Herreros",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe los Alzati (Colonia Nueva)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Peribán",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Alta Huerta",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Purenchecuaro",
                "countryCode": "MX"
            },
            {
                "name": "San José Cuaro",
                "countryCode": "MX"
            },
            {
                "name": "San José Huipana",
                "countryCode": "MX"
            },
            {
                "name": "San José de Gracia",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Benito Juárez (San Juan Tararameo)",
                "countryCode": "MX"
            },
            {
                "name": "San Juan De Los Platanos",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Viña",
                "countryCode": "MX"
            },
            {
                "name": "San Juanito Itzícuaro",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Itzícuaro",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Queréndaro",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Huarirapeo (La Mesa de San Lucas)",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Pío",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Totolán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Epejan",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Obispo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Jácuaro",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Tecario",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Chapitiro",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Maya",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Zirosto",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana de Guerrero (El Cascabel)",
                "countryCode": "MX"
            },
            {
                "name": "Santa Casilda",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara de Valladares",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara del Cobre",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara del Tule",
                "countryCode": "MX"
            },
            {
                "name": "Santa Elena",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fe del Río",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fé de la Laguna",
                "countryCode": "MX"
            },
            {
                "name": "Santa Juana",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huiramangaro (San Juan Tumbio)",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Urapicho",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa (Santa Bárbara)",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Azajo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Puriatzícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tangamandapio",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Undameo",
                "countryCode": "MX"
            },
            {
                "name": "Senguio",
                "countryCode": "MX"
            },
            {
                "name": "Sevina",
                "countryCode": "MX"
            },
            {
                "name": "Susupuato de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Tacicuaro",
                "countryCode": "MX"
            },
            {
                "name": "Tacuro (Santa María Tacuro)",
                "countryCode": "MX"
            },
            {
                "name": "Tacámbaro",
                "countryCode": "MX"
            },
            {
                "name": "Tacámbaro de Codallos",
                "countryCode": "MX"
            },
            {
                "name": "Tacátzcuaro",
                "countryCode": "MX"
            },
            {
                "name": "Tafetán",
                "countryCode": "MX"
            },
            {
                "name": "Tanaco",
                "countryCode": "MX"
            },
            {
                "name": "Tancítaro",
                "countryCode": "MX"
            },
            {
                "name": "Tangancícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Tanhuato de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Tarejero",
                "countryCode": "MX"
            },
            {
                "name": "Taretán",
                "countryCode": "MX"
            },
            {
                "name": "Tarímbaro",
                "countryCode": "MX"
            },
            {
                "name": "Tavera",
                "countryCode": "MX"
            },
            {
                "name": "Tecario",
                "countryCode": "MX"
            },
            {
                "name": "Tecomatán",
                "countryCode": "MX"
            },
            {
                "name": "Tepalcatepec",
                "countryCode": "MX"
            },
            {
                "name": "Tepuxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Tierras Coloradas (San Pedro)",
                "countryCode": "MX"
            },
            {
                "name": "Tingambato",
                "countryCode": "MX"
            },
            {
                "name": "Tingüindín",
                "countryCode": "MX"
            },
            {
                "name": "Tiquicheo",
                "countryCode": "MX"
            },
            {
                "name": "Tiquicheo de Nicolás Romero",
                "countryCode": "MX"
            },
            {
                "name": "Tiríndaro",
                "countryCode": "MX"
            },
            {
                "name": "Tirípetio",
                "countryCode": "MX"
            },
            {
                "name": "Tlalpujahua de Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Tocumbo",
                "countryCode": "MX"
            },
            {
                "name": "Tomendán",
                "countryCode": "MX"
            },
            {
                "name": "Toreo Bajo (El Toreo Bajo)",
                "countryCode": "MX"
            },
            {
                "name": "Tumbiscatío de Ruiz",
                "countryCode": "MX"
            },
            {
                "name": "Tungareo",
                "countryCode": "MX"
            },
            {
                "name": "Turicato",
                "countryCode": "MX"
            },
            {
                "name": "Turícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpan",
                "countryCode": "MX"
            },
            {
                "name": "Tuzantla",
                "countryCode": "MX"
            },
            {
                "name": "Tzintzimeo",
                "countryCode": "MX"
            },
            {
                "name": "Tzintzingareo",
                "countryCode": "MX"
            },
            {
                "name": "Tzintzuntzan",
                "countryCode": "MX"
            },
            {
                "name": "Tziritzícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Tzitzio",
                "countryCode": "MX"
            },
            {
                "name": "Ucareo",
                "countryCode": "MX"
            },
            {
                "name": "Uricho",
                "countryCode": "MX"
            },
            {
                "name": "Uripitio",
                "countryCode": "MX"
            },
            {
                "name": "Uruapan",
                "countryCode": "MX"
            },
            {
                "name": "Uruétaro",
                "countryCode": "MX"
            },
            {
                "name": "Uspero",
                "countryCode": "MX"
            },
            {
                "name": "Valle Verde",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Villa Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Madero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Magna",
                "countryCode": "MX"
            },
            {
                "name": "Villa Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Villamar",
                "countryCode": "MX"
            },
            {
                "name": "Villas de la Loma",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa de Negrete",
                "countryCode": "MX"
            },
            {
                "name": "Yurécuaro",
                "countryCode": "MX"
            },
            {
                "name": "Zacapu",
                "countryCode": "MX"
            },
            {
                "name": "Zamora",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza (Puerto de Medina)",
                "countryCode": "MX"
            },
            {
                "name": "Zicuicho",
                "countryCode": "MX"
            },
            {
                "name": "Zicuirán",
                "countryCode": "MX"
            },
            {
                "name": "Zinapécuaro",
                "countryCode": "MX"
            },
            {
                "name": "Zináparo",
                "countryCode": "MX"
            },
            {
                "name": "Ziquítaro",
                "countryCode": "MX"
            },
            {
                "name": "Ziracuaretiro",
                "countryCode": "MX"
            },
            {
                "name": "Zirahuato de los Bernal",
                "countryCode": "MX"
            },
            {
                "name": "Zirahuén",
                "countryCode": "MX"
            },
            {
                "name": "Zirimícuaro",
                "countryCode": "MX"
            },
            {
                "name": "Zurumbeneo",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Abelardo L. Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Acamilpa",
                "countryCode": "MX"
            },
            {
                "name": "Achichipico",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo López Mateos",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehuetzingo",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil (Chacampalco)",
                "countryCode": "MX"
            },
            {
                "name": "Alpuyeca",
                "countryCode": "MX"
            },
            {
                "name": "Amacuitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "Amacuzac",
                "countryCode": "MX"
            },
            {
                "name": "Amatlán de Quetzalcoatl",
                "countryCode": "MX"
            },
            {
                "name": "Amayuca",
                "countryCode": "MX"
            },
            {
                "name": "Amilcingo",
                "countryCode": "MX"
            },
            {
                "name": "Anenecuilco",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Atlacahualoya",
                "countryCode": "MX"
            },
            {
                "name": "Atlacholoaya",
                "countryCode": "MX"
            },
            {
                "name": "Atlatlahucan",
                "countryCode": "MX"
            },
            {
                "name": "Atotonilco",
                "countryCode": "MX"
            },
            {
                "name": "Axochiapan",
                "countryCode": "MX"
            },
            {
                "name": "Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Bonifacio García",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Calera Chica",
                "countryCode": "MX"
            },
            {
                "name": "Calle Real",
                "countryCode": "MX"
            },
            {
                "name": "Casahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos de Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Chalcatzingo",
                "countryCode": "MX"
            },
            {
                "name": "Chavarría",
                "countryCode": "MX"
            },
            {
                "name": "Chiconcuac",
                "countryCode": "MX"
            },
            {
                "name": "Chinameca",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Cliserio Alanís (San Gaspar)",
                "countryCode": "MX"
            },
            {
                "name": "Coahuixtla",
                "countryCode": "MX"
            },
            {
                "name": "Coatetelco",
                "countryCode": "MX"
            },
            {
                "name": "Coatlán del Río",
                "countryCode": "MX"
            },
            {
                "name": "Cocoyoc",
                "countryCode": "MX"
            },
            {
                "name": "Cocoyotla",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Obrera",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Palo Prieto (Chipitongo)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Florido",
                "countryCode": "MX"
            },
            {
                "name": "Colonia las Arboledas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Ángel Bocanegra (Adolfo López Mateos)",
                "countryCode": "MX"
            },
            {
                "name": "Constancio Farfán (La Pascuala)",
                "countryCode": "MX"
            },
            {
                "name": "Cuauchichinola",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtempan (San Andrés Cuauhtempan)",
                "countryCode": "MX"
            },
            {
                "name": "Cuautla",
                "countryCode": "MX"
            },
            {
                "name": "Cuentepec",
                "countryCode": "MX"
            },
            {
                "name": "Cuernavaca",
                "countryCode": "MX"
            },
            {
                "name": "Dos Ríos",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Estudiante",
                "countryCode": "MX"
            },
            {
                "name": "El Mirador",
                "countryCode": "MX"
            },
            {
                "name": "El Rodeo",
                "countryCode": "MX"
            },
            {
                "name": "El Salitre",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata (Casahuates)",
                "countryCode": "MX"
            },
            {
                "name": "Enthavi",
                "countryCode": "MX"
            },
            {
                "name": "Ex-Hacienda el Hospital",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Neri (Cuatepec)",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Lomas de Ahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Universo",
                "countryCode": "MX"
            },
            {
                "name": "Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Higuerón",
                "countryCode": "MX"
            },
            {
                "name": "Huajintlán",
                "countryCode": "MX"
            },
            {
                "name": "Huatecalco",
                "countryCode": "MX"
            },
            {
                "name": "Huazulco",
                "countryCode": "MX"
            },
            {
                "name": "Huecahuasco",
                "countryCode": "MX"
            },
            {
                "name": "Huepalcalco (San Miguel)",
                "countryCode": "MX"
            },
            {
                "name": "Hueyapan",
                "countryCode": "MX"
            },
            {
                "name": "Huitchila",
                "countryCode": "MX"
            },
            {
                "name": "Huitzilac",
                "countryCode": "MX"
            },
            {
                "name": "Huitzililla",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Bastida (Santa Catarina Tlayca)",
                "countryCode": "MX"
            },
            {
                "name": "Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Itzamatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlilco el Chico",
                "countryCode": "MX"
            },
            {
                "name": "Jaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Jantetelco",
                "countryCode": "MX"
            },
            {
                "name": "Jicarero",
                "countryCode": "MX"
            },
            {
                "name": "Jiutepec",
                "countryCode": "MX"
            },
            {
                "name": "Jojutla",
                "countryCode": "MX"
            },
            {
                "name": "Jonacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Juan Morales",
                "countryCode": "MX"
            },
            {
                "name": "Jumiltepec",
                "countryCode": "MX"
            },
            {
                "name": "La Huanica",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Unidad Huitzizilapan",
                "countryCode": "MX"
            },
            {
                "name": "Loma Bonita",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Ahuatepec",
                "countryCode": "MX"
            },
            {
                "name": "Los Arcos",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas (El Empalme)",
                "countryCode": "MX"
            },
            {
                "name": "Marcelino Rodríguez (San Ignacio)",
                "countryCode": "MX"
            },
            {
                "name": "Mazatepec",
                "countryCode": "MX"
            },
            {
                "name": "Metepec",
                "countryCode": "MX"
            },
            {
                "name": "Miacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Michapa",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Bocanegra",
                "countryCode": "MX"
            },
            {
                "name": "Mixtlalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Moyotepec",
                "countryCode": "MX"
            },
            {
                "name": "Narciso Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Nepopualco",
                "countryCode": "MX"
            },
            {
                "name": "Ninguno [Centro de Readaptación Social de Atlacholoaya]",
                "countryCode": "MX"
            },
            {
                "name": "Oacalco",
                "countryCode": "MX"
            },
            {
                "name": "Oaxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Ocoxaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Ocuituco",
                "countryCode": "MX"
            },
            {
                "name": "Olintepec",
                "countryCode": "MX"
            },
            {
                "name": "Pedro Amaro",
                "countryCode": "MX"
            },
            {
                "name": "Peña Flores (Palo Verde)",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Puente de Ixtla",
                "countryCode": "MX"
            },
            {
                "name": "Puxtla",
                "countryCode": "MX"
            },
            {
                "name": "Pérez de Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Quebrantadero",
                "countryCode": "MX"
            },
            {
                "name": "Reyes Acozac",
                "countryCode": "MX"
            },
            {
                "name": "Salazar",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Cuexcontitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés de la Cal",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Chiverías",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio del Puente",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Tlaltelulco",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Diego Alcalá",
                "countryCode": "MX"
            },
            {
                "name": "San Diego Linares",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Teotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Chimalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tetetla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Zacacalco",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Gaspar Tlahuelilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Acazulco",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Chicahualco",
                "countryCode": "MX"
            },
            {
                "name": "San Jose Solís",
                "countryCode": "MX"
            },
            {
                "name": "San José",
                "countryCode": "MX"
            },
            {
                "name": "San José Buenavista el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San José Comalco",
                "countryCode": "MX"
            },
            {
                "name": "San José Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "San José el Llanito",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ahuehueyo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Texcalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tilapa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Xochiaca",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Yautepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Zitlaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan la Isla",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Cuauhtenco",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Nenamicoyan",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Oyamel",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Ayucán",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos de la Cruz",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Atarasquíllo",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Otzacatipan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Almaya",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ameyalco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Atepoxco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Balderas",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de La Victoria",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Peralta",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Solís",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Tlazala",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Autopan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Apatlaco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Atlapulco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Chochula",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Huaquilpan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Techuchulco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlaltizapan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tultepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Xalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Zictepec",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Jilotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Ayotuxco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Martha",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ajoloapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Atarasquillo",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Magdalena Ocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Treinta",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Analco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Oxthoc",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepatlaxco",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tílapa",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Aztacameca",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Ocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tecajec",
                "countryCode": "MX"
            },
            {
                "name": "Tehuixtla",
                "countryCode": "MX"
            },
            {
                "name": "Telixtac",
                "countryCode": "MX"
            },
            {
                "name": "Temimilcingo",
                "countryCode": "MX"
            },
            {
                "name": "Temixco",
                "countryCode": "MX"
            },
            {
                "name": "Temoac",
                "countryCode": "MX"
            },
            {
                "name": "Tenango",
                "countryCode": "MX"
            },
            {
                "name": "Tenextepango",
                "countryCode": "MX"
            },
            {
                "name": "Tepalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tepoztlán",
                "countryCode": "MX"
            },
            {
                "name": "Tequesquitengo",
                "countryCode": "MX"
            },
            {
                "name": "Tetecala",
                "countryCode": "MX"
            },
            {
                "name": "Tetecalita",
                "countryCode": "MX"
            },
            {
                "name": "Tetecolala",
                "countryCode": "MX"
            },
            {
                "name": "Tetela del Volcán",
                "countryCode": "MX"
            },
            {
                "name": "Tetelilla",
                "countryCode": "MX"
            },
            {
                "name": "Texcala",
                "countryCode": "MX"
            },
            {
                "name": "Tezoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Tianguistongo",
                "countryCode": "MX"
            },
            {
                "name": "Ticumán",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Larga (Campo Nuevo)",
                "countryCode": "MX"
            },
            {
                "name": "Tilzapotla",
                "countryCode": "MX"
            },
            {
                "name": "Tlachaloya",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlacuitlapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlalnepantla",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltenanguito",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltetelco (San Miguel Tlaltetelco)",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltizapán",
                "countryCode": "MX"
            },
            {
                "name": "Tlapanaloya",
                "countryCode": "MX"
            },
            {
                "name": "Tlaquiltenango",
                "countryCode": "MX"
            },
            {
                "name": "Tlatenchi",
                "countryCode": "MX"
            },
            {
                "name": "Tlayacapan",
                "countryCode": "MX"
            },
            {
                "name": "Totolapan",
                "countryCode": "MX"
            },
            {
                "name": "Tres Marías",
                "countryCode": "MX"
            },
            {
                "name": "Tres de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional 10 de Abril",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional José María Morelos y Pavón",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional Mariano Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Habitacional Rinconada Acolapa",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Vázquez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Xalostoc",
                "countryCode": "MX"
            },
            {
                "name": "Xochicalco (Cirenio Longares)",
                "countryCode": "MX"
            },
            {
                "name": "Xochitepec",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Xoxocotla",
                "countryCode": "MX"
            },
            {
                "name": "Yautepec",
                "countryCode": "MX"
            },
            {
                "name": "Yecapixteca",
                "countryCode": "MX"
            },
            {
                "name": "Yecapixtla",
                "countryCode": "MX"
            },
            {
                "name": "Zacapalco",
                "countryCode": "MX"
            },
            {
                "name": "Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan de Amilpas",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Acaponeta",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Amapa",
                "countryCode": "MX"
            },
            {
                "name": "Amatlán de Cañas",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Aticama",
                "countryCode": "MX"
            },
            {
                "name": "Atonalisco",
                "countryCode": "MX"
            },
            {
                "name": "Aután",
                "countryCode": "MX"
            },
            {
                "name": "Bellavista",
                "countryCode": "MX"
            },
            {
                "name": "Bucerías",
                "countryCode": "MX"
            },
            {
                "name": "Camalotita",
                "countryCode": "MX"
            },
            {
                "name": "Camichín de Jauja",
                "countryCode": "MX"
            },
            {
                "name": "Cañada del Tabaco",
                "countryCode": "MX"
            },
            {
                "name": "Chapalilla",
                "countryCode": "MX"
            },
            {
                "name": "Chilapa",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Paraíso Escondido",
                "countryCode": "MX"
            },
            {
                "name": "Compostela",
                "countryCode": "MX"
            },
            {
                "name": "Corral del Risco (Punta de Mita)",
                "countryCode": "MX"
            },
            {
                "name": "Cruz de Huanacaxtle",
                "countryCode": "MX"
            },
            {
                "name": "Cuamiles",
                "countryCode": "MX"
            },
            {
                "name": "El Ahuacate",
                "countryCode": "MX"
            },
            {
                "name": "El Botadero",
                "countryCode": "MX"
            },
            {
                "name": "El Capomal",
                "countryCode": "MX"
            },
            {
                "name": "El Corte",
                "countryCode": "MX"
            },
            {
                "name": "El Jicote",
                "countryCode": "MX"
            },
            {
                "name": "El Llano",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Tamarindo",
                "countryCode": "MX"
            },
            {
                "name": "El Tizate",
                "countryCode": "MX"
            },
            {
                "name": "El Vado de San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "El Venado",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Higuera Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Huajicori",
                "countryCode": "MX"
            },
            {
                "name": "Huajimic",
                "countryCode": "MX"
            },
            {
                "name": "Ixtapan de la Concepción",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlán del Río",
                "countryCode": "MX"
            },
            {
                "name": "Jalcocotán",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Jomulco",
                "countryCode": "MX"
            },
            {
                "name": "Juan Escutia (Borbollón)",
                "countryCode": "MX"
            },
            {
                "name": "La Cantera",
                "countryCode": "MX"
            },
            {
                "name": "La Corregidora",
                "countryCode": "MX"
            },
            {
                "name": "La Fortuna",
                "countryCode": "MX"
            },
            {
                "name": "La Guásima",
                "countryCode": "MX"
            },
            {
                "name": "La Labor",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Peñita de Jaltomba",
                "countryCode": "MX"
            },
            {
                "name": "La Presa",
                "countryCode": "MX"
            },
            {
                "name": "La Yesca",
                "countryCode": "MX"
            },
            {
                "name": "Las Jarretaderas",
                "countryCode": "MX"
            },
            {
                "name": "Las Varas",
                "countryCode": "MX"
            },
            {
                "name": "Llano del Tigre",
                "countryCode": "MX"
            },
            {
                "name": "Lo de Lamedo",
                "countryCode": "MX"
            },
            {
                "name": "Lo de Marcos",
                "countryCode": "MX"
            },
            {
                "name": "Los Aguajes",
                "countryCode": "MX"
            },
            {
                "name": "Los Otates",
                "countryCode": "MX"
            },
            {
                "name": "Mecatán",
                "countryCode": "MX"
            },
            {
                "name": "Mesa del Nayar",
                "countryCode": "MX"
            },
            {
                "name": "Mezcales",
                "countryCode": "MX"
            },
            {
                "name": "Milpas Viejas",
                "countryCode": "MX"
            },
            {
                "name": "Mojarras",
                "countryCode": "MX"
            },
            {
                "name": "Monteón",
                "countryCode": "MX"
            },
            {
                "name": "Méxpan",
                "countryCode": "MX"
            },
            {
                "name": "Navarrete",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Vallarta",
                "countryCode": "MX"
            },
            {
                "name": "Palma Grande",
                "countryCode": "MX"
            },
            {
                "name": "Palmar de Cuautla",
                "countryCode": "MX"
            },
            {
                "name": "Pantanal",
                "countryCode": "MX"
            },
            {
                "name": "Pericos",
                "countryCode": "MX"
            },
            {
                "name": "Pozo de Ibarra",
                "countryCode": "MX"
            },
            {
                "name": "Puerta de Mangos",
                "countryCode": "MX"
            },
            {
                "name": "Puga",
                "countryCode": "MX"
            },
            {
                "name": "Quimichis",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Guayabitos",
                "countryCode": "MX"
            },
            {
                "name": "Rosa Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Rosamorada",
                "countryCode": "MX"
            },
            {
                "name": "Ruíz",
                "countryCode": "MX"
            },
            {
                "name": "San Blas",
                "countryCode": "MX"
            },
            {
                "name": "San Cayetano",
                "countryCode": "MX"
            },
            {
                "name": "San Clemente de Lima",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Aztatán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San José de Gracia",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "San Luis de Lozada",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Lagunillas",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ixcuintla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago de Pochotitán",
                "countryCode": "MX"
            },
            {
                "name": "Sauta",
                "countryCode": "MX"
            },
            {
                "name": "Sayulilla",
                "countryCode": "MX"
            },
            {
                "name": "Sayulita",
                "countryCode": "MX"
            },
            {
                "name": "Sentispac",
                "countryCode": "MX"
            },
            {
                "name": "Tecuala",
                "countryCode": "MX"
            },
            {
                "name": "Tepic",
                "countryCode": "MX"
            },
            {
                "name": "Tequepexpan",
                "countryCode": "MX"
            },
            {
                "name": "Testerazo",
                "countryCode": "MX"
            },
            {
                "name": "Tetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Unión de Corrientes",
                "countryCode": "MX"
            },
            {
                "name": "Uzeta",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Banderas",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Yago",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "Zapotanito",
                "countryCode": "MX"
            },
            {
                "name": "Zapotán",
                "countryCode": "MX"
            },
            {
                "name": "Agualeguas",
                "countryCode": "MX"
            },
            {
                "name": "Alianza Real",
                "countryCode": "MX"
            },
            {
                "name": "Anáhuac",
                "countryCode": "MX"
            },
            {
                "name": "Aramberri",
                "countryCode": "MX"
            },
            {
                "name": "Artemio Treviño",
                "countryCode": "MX"
            },
            {
                "name": "Bosques de San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Bustamante",
                "countryCode": "MX"
            },
            {
                "name": "Cadereyta",
                "countryCode": "MX"
            },
            {
                "name": "Cadereyta Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Carmen",
                "countryCode": "MX"
            },
            {
                "name": "Catarino Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Cerralvo",
                "countryCode": "MX"
            },
            {
                "name": "China",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Apodaca",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Cerralvo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad General Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad General Terán",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Sabinas Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Satélite del Norte",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Villaldama",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega de Flores",
                "countryCode": "MX"
            },
            {
                "name": "Colinas del Aeropuerto",
                "countryCode": "MX"
            },
            {
                "name": "Congregación Calles",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Arroyo",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Coss",
                "countryCode": "MX"
            },
            {
                "name": "Doctor González",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Entronque Laredo-Salinas Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Cosmópolis Octavo Sector",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Misión de San Javier",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Real Palmas",
                "countryCode": "MX"
            },
            {
                "name": "Galeana",
                "countryCode": "MX"
            },
            {
                "name": "García",
                "countryCode": "MX"
            },
            {
                "name": "General Bravo",
                "countryCode": "MX"
            },
            {
                "name": "General Zuazua",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "Hualahuises",
                "countryCode": "MX"
            },
            {
                "name": "Héctor Caballero",
                "countryCode": "MX"
            },
            {
                "name": "Iturbide",
                "countryCode": "MX"
            },
            {
                "name": "Jardines de la Silla (Jardines)",
                "countryCode": "MX"
            },
            {
                "name": "La Ascensión",
                "countryCode": "MX"
            },
            {
                "name": "Ladrillera (Entronque Pesquería)",
                "countryCode": "MX"
            },
            {
                "name": "Lampazos de Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "Las Torres",
                "countryCode": "MX"
            },
            {
                "name": "Las Torres de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Linares",
                "countryCode": "MX"
            },
            {
                "name": "Loma la Paz",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de San Martín",
                "countryCode": "MX"
            },
            {
                "name": "Los Parques",
                "countryCode": "MX"
            },
            {
                "name": "Los Ramones",
                "countryCode": "MX"
            },
            {
                "name": "Marín",
                "countryCode": "MX"
            },
            {
                "name": "Mazatlan",
                "countryCode": "MX"
            },
            {
                "name": "Mina",
                "countryCode": "MX"
            },
            {
                "name": "Misión San Pablo",
                "countryCode": "MX"
            },
            {
                "name": "Mitras Poniente",
                "countryCode": "MX"
            },
            {
                "name": "Monclova Primer Sector",
                "countryCode": "MX"
            },
            {
                "name": "Monclova Segundo Sector",
                "countryCode": "MX"
            },
            {
                "name": "Monte Kristal",
                "countryCode": "MX"
            },
            {
                "name": "Montemorelos",
                "countryCode": "MX"
            },
            {
                "name": "Monterrey",
                "countryCode": "MX"
            },
            {
                "name": "Parque Industrial Ciudad Mitras",
                "countryCode": "MX"
            },
            {
                "name": "Parás",
                "countryCode": "MX"
            },
            {
                "name": "Paseo de San Javier",
                "countryCode": "MX"
            },
            {
                "name": "Pesquería",
                "countryCode": "MX"
            },
            {
                "name": "Portal de las Salinas",
                "countryCode": "MX"
            },
            {
                "name": "Praderas de San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "Prados de Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Rayones",
                "countryCode": "MX"
            },
            {
                "name": "Real del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Río Verde",
                "countryCode": "MX"
            },
            {
                "name": "Salinas Victoria",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San José de Raíces",
                "countryCode": "MX"
            },
            {
                "name": "San Juan",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de los Garza",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Garza Garcia",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Simeprodeso (Colectivo Nuevo)",
                "countryCode": "MX"
            },
            {
                "name": "Unión Agropecuarios Lázaro Cárdenas del Norte",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Lincoln",
                "countryCode": "MX"
            },
            {
                "name": "Valle de Vaquerías",
                "countryCode": "MX"
            },
            {
                "name": "Vallecillo",
                "countryCode": "MX"
            },
            {
                "name": "Villaldama",
                "countryCode": "MX"
            },
            {
                "name": "Villas de Alcalá",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán de Pérez Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "Agua del Espino",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehuetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Albarradas",
                "countryCode": "MX"
            },
            {
                "name": "Almolonga",
                "countryCode": "MX"
            },
            {
                "name": "Animas Trujano",
                "countryCode": "MX"
            },
            {
                "name": "Apoala",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Chical (Nuevo Arroyo Chicali)",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Choápam",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Limón",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Zapotillo",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo de Banco",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo de Enmedio",
                "countryCode": "MX"
            },
            {
                "name": "Asunción Cacalotepec",
                "countryCode": "MX"
            },
            {
                "name": "Asunción Ixtaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Asunción Nochixtlán",
                "countryCode": "MX"
            },
            {
                "name": "Asunción Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Asunción Tlacolulita",
                "countryCode": "MX"
            },
            {
                "name": "Ayoquezco de Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Ayotzintepec",
                "countryCode": "MX"
            },
            {
                "name": "Ayutla",
                "countryCode": "MX"
            },
            {
                "name": "Bajos de Chila",
                "countryCode": "MX"
            },
            {
                "name": "Barrio San Diego",
                "countryCode": "MX"
            },
            {
                "name": "Benemérito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez II (San Martín)",
                "countryCode": "MX"
            },
            {
                "name": "Bethania",
                "countryCode": "MX"
            },
            {
                "name": "Brisas de Zicatela",
                "countryCode": "MX"
            },
            {
                "name": "Cajonos",
                "countryCode": "MX"
            },
            {
                "name": "Calihualá",
                "countryCode": "MX"
            },
            {
                "name": "Camelia Roja",
                "countryCode": "MX"
            },
            {
                "name": "Camotinchan",
                "countryCode": "MX"
            },
            {
                "name": "Candelaria Loxicha",
                "countryCode": "MX"
            },
            {
                "name": "Capulálpam de Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Armadillo Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Quemado",
                "countryCode": "MX"
            },
            {
                "name": "Cerro del Aire",
                "countryCode": "MX"
            },
            {
                "name": "Chahuite",
                "countryCode": "MX"
            },
            {
                "name": "Chahuites",
                "countryCode": "MX"
            },
            {
                "name": "Chicahua",
                "countryCode": "MX"
            },
            {
                "name": "Chicapa de Castro",
                "countryCode": "MX"
            },
            {
                "name": "Cieneguilla",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Huajuapan de León",
                "countryCode": "MX"
            },
            {
                "name": "Ciénega de Zimatlán",
                "countryCode": "MX"
            },
            {
                "name": "Coatecas Altas",
                "countryCode": "MX"
            },
            {
                "name": "Coixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Collantes",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Jordán",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Rincón Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Luis",
                "countryCode": "MX"
            },
            {
                "name": "Colonia la Central",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Pápalo",
                "countryCode": "MX"
            },
            {
                "name": "Constancia del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Constitución Mexicana",
                "countryCode": "MX"
            },
            {
                "name": "Corral de Piedra",
                "countryCode": "MX"
            },
            {
                "name": "Corralero",
                "countryCode": "MX"
            },
            {
                "name": "Cosoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Crucecita",
                "countryCode": "MX"
            },
            {
                "name": "Cuapinolito (Azulillo)",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuicatlan",
                "countryCode": "MX"
            },
            {
                "name": "Cuilapan de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Donaji",
                "countryCode": "MX"
            },
            {
                "name": "Ejutla de Crespo",
                "countryCode": "MX"
            },
            {
                "name": "El Arador",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío",
                "countryCode": "MX"
            },
            {
                "name": "El Barrio de la Soledad",
                "countryCode": "MX"
            },
            {
                "name": "El Camalote",
                "countryCode": "MX"
            },
            {
                "name": "El Camarón",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín (La Nueva Pochota)",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizo",
                "countryCode": "MX"
            },
            {
                "name": "El Chocolate",
                "countryCode": "MX"
            },
            {
                "name": "El Ciruelo",
                "countryCode": "MX"
            },
            {
                "name": "El Coyul",
                "countryCode": "MX"
            },
            {
                "name": "El Espinal",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Morro",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Eloxochitlán de Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Encinal Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Estación Mogoñé",
                "countryCode": "MX"
            },
            {
                "name": "Flor Batavia",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Ciudad Yagul",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Ex-Hacienda Catano",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Real del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Riberas de San Jerónimo",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Ibarra Ramos",
                "countryCode": "MX"
            },
            {
                "name": "General Pascual Fentes",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Etla",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guamúchil",
                "countryCode": "MX"
            },
            {
                "name": "Guixé",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Ciudad de Ejutla de Crespo",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Ciudad de Juchitán de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Ciudad de Tlaxiaco",
                "countryCode": "MX"
            },
            {
                "name": "Huajintepec",
                "countryCode": "MX"
            },
            {
                "name": "Huautepec",
                "countryCode": "MX"
            },
            {
                "name": "Huazantlán del Río",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Isla Soyaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Ixpantepec Nieves",
                "countryCode": "MX"
            },
            {
                "name": "Ixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlán de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Jalapa",
                "countryCode": "MX"
            },
            {
                "name": "Jaltepec de Candayoc",
                "countryCode": "MX"
            },
            {
                "name": "Jaltepetongo",
                "countryCode": "MX"
            },
            {
                "name": "Jamiltepec",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Juchitán de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "La Barra de Colotepec",
                "countryCode": "MX"
            },
            {
                "name": "La Blanca",
                "countryCode": "MX"
            },
            {
                "name": "La Candelaria",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada",
                "countryCode": "MX"
            },
            {
                "name": "La Erradura",
                "countryCode": "MX"
            },
            {
                "name": "La Humedad",
                "countryCode": "MX"
            },
            {
                "name": "La Junta",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Mina",
                "countryCode": "MX"
            },
            {
                "name": "La Noria",
                "countryCode": "MX"
            },
            {
                "name": "La Pe",
                "countryCode": "MX"
            },
            {
                "name": "La Reforma",
                "countryCode": "MX"
            },
            {
                "name": "La Tabaquera",
                "countryCode": "MX"
            },
            {
                "name": "La Venta",
                "countryCode": "MX"
            },
            {
                "name": "La Ventosa",
                "countryCode": "MX"
            },
            {
                "name": "Las Amilpas",
                "countryCode": "MX"
            },
            {
                "name": "Las Margaritas",
                "countryCode": "MX"
            },
            {
                "name": "Llano Suchiapa",
                "countryCode": "MX"
            },
            {
                "name": "Llano de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Loma Bonita",
                "countryCode": "MX"
            },
            {
                "name": "Loma Chapultepec",
                "countryCode": "MX"
            },
            {
                "name": "Los Naranjos Esquipulas",
                "countryCode": "MX"
            },
            {
                "name": "Los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Macuilxóchitl de Artigas Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Macín Chico",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Apasco",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Jaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Teitipac",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Tequisistlán",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Yodocono de Porfirio Díaz",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Zahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Mancuernas",
                "countryCode": "MX"
            },
            {
                "name": "Mariscala de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "María Lombardo de Caso",
                "countryCode": "MX"
            },
            {
                "name": "Matías Romero",
                "countryCode": "MX"
            },
            {
                "name": "Mazatlán Villa de Flores",
                "countryCode": "MX"
            },
            {
                "name": "Mazín Grande",
                "countryCode": "MX"
            },
            {
                "name": "Merced del Potrero",
                "countryCode": "MX"
            },
            {
                "name": "Mesones Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Miahuatlán de Porfirio Díaz",
                "countryCode": "MX"
            },
            {
                "name": "Mixistlán de la Reforma",
                "countryCode": "MX"
            },
            {
                "name": "Montenegro la Lana",
                "countryCode": "MX"
            },
            {
                "name": "Nanahuatípam",
                "countryCode": "MX"
            },
            {
                "name": "Nativitas",
                "countryCode": "MX"
            },
            {
                "name": "Nazareno Etla",
                "countryCode": "MX"
            },
            {
                "name": "Nejapa de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Niltepec",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Ixcatlán",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Paso Nazareno (Chichicazapa)",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo San Martín",
                "countryCode": "MX"
            },
            {
                "name": "Oaxaca",
                "countryCode": "MX"
            },
            {
                "name": "Oaxaca de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlán de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Palomares",
                "countryCode": "MX"
            },
            {
                "name": "Papaloapan",
                "countryCode": "MX"
            },
            {
                "name": "Paso Canoa",
                "countryCode": "MX"
            },
            {
                "name": "Paso Real de Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Peña Colorada",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Piedra de Amolar",
                "countryCode": "MX"
            },
            {
                "name": "Pinotepa Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Pinotepa de Don Luis",
                "countryCode": "MX"
            },
            {
                "name": "Playa Chica",
                "countryCode": "MX"
            },
            {
                "name": "Pluma Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Praxedis de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Escondido",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Ángel",
                "countryCode": "MX"
            },
            {
                "name": "Putla Villa de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Quelové",
                "countryCode": "MX"
            },
            {
                "name": "Quinicuena",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Grande",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Reforma de Pineda",
                "countryCode": "MX"
            },
            {
                "name": "Reyes Etla",
                "countryCode": "MX"
            },
            {
                "name": "Reyes Mantecón",
                "countryCode": "MX"
            },
            {
                "name": "Rincón Moreno",
                "countryCode": "MX"
            },
            {
                "name": "Rojas de Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Río Chiquito",
                "countryCode": "MX"
            },
            {
                "name": "Río Grande",
                "countryCode": "MX"
            },
            {
                "name": "Río Pachiñe",
                "countryCode": "MX"
            },
            {
                "name": "Salina Cruz",
                "countryCode": "MX"
            },
            {
                "name": "San Agustin de las Juntas",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Amatengo",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Atenango",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Chayuco",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Etla",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Loxicha",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Yatareni",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Chicahuaxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Dinicuiti",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Huaxpaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Huayápam",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Paxtlán",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Sinaxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Solaga",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Teotilalpam",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Yaá",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Zabache",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Zautla",
                "countryCode": "MX"
            },
            {
                "name": "San Antonino Castillo Velasco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonino Monte Verde",
                "countryCode": "MX"
            },
            {
                "name": "San Antonino el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Arrazola",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Huitepec",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tepetlapa",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de la Cal",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "San Baltazar Chichicapam",
                "countryCode": "MX"
            },
            {
                "name": "San Baltazar Guelavila",
                "countryCode": "MX"
            },
            {
                "name": "San Baltazar Loxicha",
                "countryCode": "MX"
            },
            {
                "name": "San Baltazar Yatzachi el Bajo",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Coyotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Coyotespec",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolo Yautepec",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Ayautla",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Loxícha",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Quialana",
                "countryCode": "MX"
            },
            {
                "name": "San Benito Encinal",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Blas Atempa",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos Yautepec",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Amoltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Honduras",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Lachirioag",
                "countryCode": "MX"
            },
            {
                "name": "San Dionisio Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Dionisio Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Dionisio del Mar",
                "countryCode": "MX"
            },
            {
                "name": "San Esteban Atatlahuca",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Cihualtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Jalapa de Díaz",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Tejalápam",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Tílpam",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Usila",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cajonos",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Chindúa",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cozoaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Huehuetlán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Ixhuatan",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Jaltepetongo",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Javier",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Lachigoló",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Logueche",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Loxicha",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Nuxaño",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Ozolotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Sola",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Telixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tutla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco del Mar",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco del Mar Viejo",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso Villa Alta",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Apango",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Monjas",
                "countryCode": "MX"
            },
            {
                "name": "San Jacinto Amilpas",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Silacayoapilla",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Sosola",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Taviche",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Tecóatl",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Tlacochahuaya",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Yahuiche",
                "countryCode": "MX"
            },
            {
                "name": "San Jorge Nuchita",
                "countryCode": "MX"
            },
            {
                "name": "San José Ayuquila",
                "countryCode": "MX"
            },
            {
                "name": "San José Chacalapa",
                "countryCode": "MX"
            },
            {
                "name": "San José Chiltepec",
                "countryCode": "MX"
            },
            {
                "name": "San José Estancia Grande",
                "countryCode": "MX"
            },
            {
                "name": "San José Lachiguirí",
                "countryCode": "MX"
            },
            {
                "name": "San José Piedras Negras",
                "countryCode": "MX"
            },
            {
                "name": "San José Río Manzo",
                "countryCode": "MX"
            },
            {
                "name": "San José Tenango",
                "countryCode": "MX"
            },
            {
                "name": "San José de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "San José del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "San José la Garzona",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Achiutla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Atepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Bautista Jayacatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Bautista Lo de Soto",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Bautista la Raya",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cabeza del Río",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cacahuatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Chilateca",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Coatzospam",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Colorado",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cotzocón",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Diuxi",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Guelavía",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Guichicovi",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ihualtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Jaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Jicayán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Juquila",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Juquila Vijanos",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Lachao",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Lagunas",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Mazatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Metaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Mixtepec -Dto. 08 -",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Mixtepec -Dto. 26 -",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ozolotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Petlapa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Quiahue",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Quiotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Sautla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Sayultepec",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tabaá",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Teitipac",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Teposcolula",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Yaee",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Yatzona",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Zapotitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de los Cues",
                "countryCode": "MX"
            },
            {
                "name": "San Juan del Estado",
                "countryCode": "MX"
            },
            {
                "name": "San Juan del Río",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ñumí",
                "countryCode": "MX"
            },
            {
                "name": "San Juán Lachigalla",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Albarradas",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Cacaotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Cuaunecuiltitla",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Victoria",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Camotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Ojitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Quiavini",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Zoquiápam",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Marcial Ozolotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Arteaga",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Itunyoso",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Lachila",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Tilcajete",
                "countryCode": "MX"
            },
            {
                "name": "San Martín de los Canseco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Cajonos",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Etlatongo",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Nejápam",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Piñas",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Sindihui",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Yoloxochitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo del Mar",
                "countryCode": "MX"
            },
            {
                "name": "San Melchor Betaza",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Abejones",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Achiutla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Aloápam",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Chimalapa",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ejutla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Figueroa",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Monteverde",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Panixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Peras",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Quetzaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Suchixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tecomatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tenango",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tetepelcingo",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tilquiapam",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tlacamama",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Puerto",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Valle",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel el Grande Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Quialana",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Cuatro Venados",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Etla",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Güilá",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Huitzo",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Huixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Macuiltianguis",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Villa de Mitla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Amuzgos",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Apóstol",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Atoyac",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Comitancillo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Coxcaltepec Cántaros",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Huamelula",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Huilotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ixcatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Jicayán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Juchaltengo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Martir",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Mártir Quiechapa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Mártir Yucuxaco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ocopetatillo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ozumacín",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Pochutla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Quiatoni",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Sochiápam",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tapanatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Taviche",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Teutila",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tidaá",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Topiltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Totolápam",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tulixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Yólox",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro y San Pablo Teposcolula",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro y San Pablo Tequistepec",
                "countryCode": "MX"
            },
            {
                "name": "San Raymundo Jalpam",
                "countryCode": "MX"
            },
            {
                "name": "San Roque",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastian Teitipac",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Etla",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Ixcapa",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Nicananduta",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Nopalera",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Río Hondo",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Tecomaxtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Tutla",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián del Monte",
                "countryCode": "MX"
            },
            {
                "name": "San Simón Almolongas",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Coatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Lachixío",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Nuñu",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Piñas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Tlapacoyan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Zegache",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catalina Quierí",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Cuixtla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Juquila",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Loxicha",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Mechoacán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Minas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Quiané",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Roatina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Tayata",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cecilia Jalieza",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Acatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Amilpas",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Lachixolana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Mixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Nundaco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Ozolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Papalutla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Tacache de Mina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Tayata",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Xitla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Xoxocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Santa Fe y la Mar",
                "countryCode": "MX"
            },
            {
                "name": "Santa Gertrudis",
                "countryCode": "MX"
            },
            {
                "name": "Santa Inés Yatzeche",
                "countryCode": "MX"
            },
            {
                "name": "Santa Inés de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Santa Inés del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía Teotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía del Camino",
                "countryCode": "MX"
            },
            {
                "name": "Santa Magdalena Jicotlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Margarita Huitepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Martha Chichihualtepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Acatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Alotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Apazco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Camotlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Chachoápam",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Chico Ometepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Chilapa de Díaz",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Chimalapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Colotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Cortijo",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Coyotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ecatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Guelacé",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Guenagati",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huamelula",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huatulco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Huazolotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ipalapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Jacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Jalapa del Marqués",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Jicaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Lachixío",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Mixtequilla",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nativitas",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nduayaco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Petapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Puxmetacán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Pápalo",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Quiegolani",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Sola",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Temaxcalapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Temaxcaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Teopoxco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tepantlali",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tlalixtac",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tonameca",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Velato",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Xadani",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Yavesía",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Yolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Yucuhiti",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Yucunicoco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zaniza",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zapotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zoquitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Tule",
                "countryCode": "MX"
            },
            {
                "name": "Santa María la Asunción",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Caxtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Amoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Apostol",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Astata",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ayuquililla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Cacaloxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Chazumba",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Chilixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Choápam",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Comaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Cuixtla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Etla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Huajolotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Huauclilla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Huaxolotipac",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ihuitlán Plumas",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ixcuintepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ixtaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Ixtayutla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Jicayán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Jocotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Juxtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Lachiguiri",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Lalopa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Laollaga",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Laxopa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Malacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Matatlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Miltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Nejapilla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Niltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Nundiche",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Nuyoó",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Quiavicuzas",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Suchilquitongo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tenango",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepextla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tetepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Texcalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Textitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tilantongo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tillo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Xanica",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yaitepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yogana",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yolomécatl",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yosondúa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Zoochila",
                "countryCode": "MX"
            },
            {
                "name": "Santiago del Río",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Albarradas",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Armenta",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Chihuitán",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Jalieza",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Ozolotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Petapa",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Roayaga",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Tehuantepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Tepuxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Tlatayapam",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Tomaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Tonalá",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Yanhuitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Yodohino",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Zanatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Jalieza",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Mazaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Tamazulapam",
                "countryCode": "MX"
            },
            {
                "name": "Santos Reyes Nopala",
                "countryCode": "MX"
            },
            {
                "name": "Santos Reyes Pápalo",
                "countryCode": "MX"
            },
            {
                "name": "Santos Reyes Tepejillo",
                "countryCode": "MX"
            },
            {
                "name": "Santos Reyes Yucuná",
                "countryCode": "MX"
            },
            {
                "name": "Sector H Tres",
                "countryCode": "MX"
            },
            {
                "name": "Silacayoápam",
                "countryCode": "MX"
            },
            {
                "name": "Sitio de Xitlapehua",
                "countryCode": "MX"
            },
            {
                "name": "Soledad Etla",
                "countryCode": "MX"
            },
            {
                "name": "Soledad Salinas",
                "countryCode": "MX"
            },
            {
                "name": "Tamazola",
                "countryCode": "MX"
            },
            {
                "name": "Tamazulapam Villa del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Tamazulápam del Espíritu Santo",
                "countryCode": "MX"
            },
            {
                "name": "Tanetze de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Taniche",
                "countryCode": "MX"
            },
            {
                "name": "Tapanalá",
                "countryCode": "MX"
            },
            {
                "name": "Tatahuicapá",
                "countryCode": "MX"
            },
            {
                "name": "Tataltepec de Valdés",
                "countryCode": "MX"
            },
            {
                "name": "Tecomavaca",
                "countryCode": "MX"
            },
            {
                "name": "Temascal",
                "countryCode": "MX"
            },
            {
                "name": "Teococuilco de Marcos Pérez",
                "countryCode": "MX"
            },
            {
                "name": "Teojomulco",
                "countryCode": "MX"
            },
            {
                "name": "Teotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Teotitlán del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Tepenixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlapa",
                "countryCode": "MX"
            },
            {
                "name": "Tetela",
                "countryCode": "MX"
            },
            {
                "name": "Texcatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Texmelucan",
                "countryCode": "MX"
            },
            {
                "name": "Tezoatlán de Segura y Luna",
                "countryCode": "MX"
            },
            {
                "name": "Tlachichilco",
                "countryCode": "MX"
            },
            {
                "name": "Tlacoatzintepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlacolula de Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuitoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlalixtac de Cabrera",
                "countryCode": "MX"
            },
            {
                "name": "Totontepec Villa de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Toxpalan",
                "countryCode": "MX"
            },
            {
                "name": "Trinidad Zaachila",
                "countryCode": "MX"
            },
            {
                "name": "Trinidad de Viguera",
                "countryCode": "MX"
            },
            {
                "name": "Tuxtepec",
                "countryCode": "MX"
            },
            {
                "name": "Unión Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Valdeflores",
                "countryCode": "MX"
            },
            {
                "name": "Valerio Trujano",
                "countryCode": "MX"
            },
            {
                "name": "Valle Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Vega del Sol",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Camalote",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Chalcatongo de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Díaz Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Sola de Vega",
                "countryCode": "MX"
            },
            {
                "name": "Villa Talea de Castro",
                "countryCode": "MX"
            },
            {
                "name": "Villa Tejúpam de la Unión",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Etla",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Tamazulápam del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Tututepec de Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Zaachila",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Xaaga",
                "countryCode": "MX"
            },
            {
                "name": "Xagacía",
                "countryCode": "MX"
            },
            {
                "name": "Xochitonalco",
                "countryCode": "MX"
            },
            {
                "name": "Yaganiza",
                "countryCode": "MX"
            },
            {
                "name": "Yalina",
                "countryCode": "MX"
            },
            {
                "name": "Yatzachi",
                "countryCode": "MX"
            },
            {
                "name": "Yaxe",
                "countryCode": "MX"
            },
            {
                "name": "Yetla de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Yucuita",
                "countryCode": "MX"
            },
            {
                "name": "Yutanduchi de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Zacatal",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitlán Lagunas",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitlán Palmas",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Zarzal",
                "countryCode": "MX"
            },
            {
                "name": "Zimatlán de Álvarez",
                "countryCode": "MX"
            },
            {
                "name": "Zocoteaca de León",
                "countryCode": "MX"
            },
            {
                "name": "Zona Urbana Ejidal",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "18 de Marzo",
                "countryCode": "MX"
            },
            {
                "name": "Acajete",
                "countryCode": "MX"
            },
            {
                "name": "Acateno",
                "countryCode": "MX"
            },
            {
                "name": "Acatepec",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán de Osorio",
                "countryCode": "MX"
            },
            {
                "name": "Acatzingo",
                "countryCode": "MX"
            },
            {
                "name": "Acaxtlahuacán de Albino Zertuche",
                "countryCode": "MX"
            },
            {
                "name": "Acteopan",
                "countryCode": "MX"
            },
            {
                "name": "Actipan",
                "countryCode": "MX"
            },
            {
                "name": "Actipan de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Acuaco",
                "countryCode": "MX"
            },
            {
                "name": "Acuexcomac",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatempan",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatepec",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatepec de Camino",
                "countryCode": "MX"
            },
            {
                "name": "Ahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuaxintitla",
                "countryCode": "MX"
            },
            {
                "name": "Ahuazotepec",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehuetitla",
                "countryCode": "MX"
            },
            {
                "name": "Ajalpan",
                "countryCode": "MX"
            },
            {
                "name": "Albino Zertuche",
                "countryCode": "MX"
            },
            {
                "name": "Alhuaca",
                "countryCode": "MX"
            },
            {
                "name": "Aljojuca",
                "countryCode": "MX"
            },
            {
                "name": "Allende",
                "countryCode": "MX"
            },
            {
                "name": "Almecatla",
                "countryCode": "MX"
            },
            {
                "name": "Almolonga",
                "countryCode": "MX"
            },
            {
                "name": "Altepexi",
                "countryCode": "MX"
            },
            {
                "name": "Amatitlán de Azueta",
                "countryCode": "MX"
            },
            {
                "name": "Ameluca",
                "countryCode": "MX"
            },
            {
                "name": "Amixtlán",
                "countryCode": "MX"
            },
            {
                "name": "Amozoc",
                "countryCode": "MX"
            },
            {
                "name": "Amozoc de Mota",
                "countryCode": "MX"
            },
            {
                "name": "Analco de Ponciano Arriaga (Santa Cruz Analco)",
                "countryCode": "MX"
            },
            {
                "name": "Apango de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Apantéopan",
                "countryCode": "MX"
            },
            {
                "name": "Apapantilla",
                "countryCode": "MX"
            },
            {
                "name": "Apatauyan",
                "countryCode": "MX"
            },
            {
                "name": "Aquixtla",
                "countryCode": "MX"
            },
            {
                "name": "Artículo Primero Constitucional",
                "countryCode": "MX"
            },
            {
                "name": "Atalpan",
                "countryCode": "MX"
            },
            {
                "name": "Atecax",
                "countryCode": "MX"
            },
            {
                "name": "Atempan",
                "countryCode": "MX"
            },
            {
                "name": "Atencingo",
                "countryCode": "MX"
            },
            {
                "name": "Atexcal",
                "countryCode": "MX"
            },
            {
                "name": "Atla",
                "countryCode": "MX"
            },
            {
                "name": "Atlequizayan",
                "countryCode": "MX"
            },
            {
                "name": "Atlixco",
                "countryCode": "MX"
            },
            {
                "name": "Atoluca",
                "countryCode": "MX"
            },
            {
                "name": "Atoyatempan",
                "countryCode": "MX"
            },
            {
                "name": "Atzala",
                "countryCode": "MX"
            },
            {
                "name": "Atzalán",
                "countryCode": "MX"
            },
            {
                "name": "Atzingo (La Cumbre)",
                "countryCode": "MX"
            },
            {
                "name": "Atzitzintla",
                "countryCode": "MX"
            },
            {
                "name": "Axutla",
                "countryCode": "MX"
            },
            {
                "name": "Axuxco",
                "countryCode": "MX"
            },
            {
                "name": "Ayotla",
                "countryCode": "MX"
            },
            {
                "name": "Ayotoxco de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Ayutla",
                "countryCode": "MX"
            },
            {
                "name": "Azumbilla",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de Nuevo León",
                "countryCode": "MX"
            },
            {
                "name": "Bellavista de Victoria (San José Bellavista)",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Beristain",
                "countryCode": "MX"
            },
            {
                "name": "Bienvenido",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista Tetela",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Cacaloxúchitl",
                "countryCode": "MX"
            },
            {
                "name": "Cala Norte",
                "countryCode": "MX"
            },
            {
                "name": "Cala Sur",
                "countryCode": "MX"
            },
            {
                "name": "Calipan",
                "countryCode": "MX"
            },
            {
                "name": "Calmeca",
                "countryCode": "MX"
            },
            {
                "name": "Calpanería Atezquilla",
                "countryCode": "MX"
            },
            {
                "name": "Camocuautla",
                "countryCode": "MX"
            },
            {
                "name": "Camotepec",
                "countryCode": "MX"
            },
            {
                "name": "Candelaria Portezuelo",
                "countryCode": "MX"
            },
            {
                "name": "Carmen Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Casa Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Caxhuacán",
                "countryCode": "MX"
            },
            {
                "name": "Chalchihuapan",
                "countryCode": "MX"
            },
            {
                "name": "Chapulco",
                "countryCode": "MX"
            },
            {
                "name": "Chiautla de Tapia",
                "countryCode": "MX"
            },
            {
                "name": "Chicahuaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Chichicapa",
                "countryCode": "MX"
            },
            {
                "name": "Chichicuautla",
                "countryCode": "MX"
            },
            {
                "name": "Chichiquila",
                "countryCode": "MX"
            },
            {
                "name": "Chiconcuautla",
                "countryCode": "MX"
            },
            {
                "name": "Chicontla",
                "countryCode": "MX"
            },
            {
                "name": "Chietla",
                "countryCode": "MX"
            },
            {
                "name": "Chigmecatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Chignahuapan",
                "countryCode": "MX"
            },
            {
                "name": "Chila",
                "countryCode": "MX"
            },
            {
                "name": "Chila de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Chila de la Sal",
                "countryCode": "MX"
            },
            {
                "name": "Chilchotla",
                "countryCode": "MX"
            },
            {
                "name": "Chinantla",
                "countryCode": "MX"
            },
            {
                "name": "Chinautla",
                "countryCode": "MX"
            },
            {
                "name": "Chipahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Chipilo de Francisco Javier Mina",
                "countryCode": "MX"
            },
            {
                "name": "Cholula",
                "countryCode": "MX"
            },
            {
                "name": "Cinco de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Chiautla de Tapia",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Cuetzalan",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Tlatlauquitepec",
                "countryCode": "MX"
            },
            {
                "name": "Coahuixco",
                "countryCode": "MX"
            },
            {
                "name": "Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "Coatzingo",
                "countryCode": "MX"
            },
            {
                "name": "Cohuecán",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Agrícola de Ocotepec (Colonia San José)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Obrera",
                "countryCode": "MX"
            },
            {
                "name": "Colucan",
                "countryCode": "MX"
            },
            {
                "name": "Comaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Capulac (La Ex-Hacienda)",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Cuautla",
                "countryCode": "MX"
            },
            {
                "name": "Copila",
                "countryCode": "MX"
            },
            {
                "name": "Coronango",
                "countryCode": "MX"
            },
            {
                "name": "Coronel Tito Hernández",
                "countryCode": "MX"
            },
            {
                "name": "Coxcatlán",
                "countryCode": "MX"
            },
            {
                "name": "Coxolico",
                "countryCode": "MX"
            },
            {
                "name": "Coyomeapan",
                "countryCode": "MX"
            },
            {
                "name": "Coyotepec",
                "countryCode": "MX"
            },
            {
                "name": "Cuacnopalan",
                "countryCode": "MX"
            },
            {
                "name": "Cuacuila",
                "countryCode": "MX"
            },
            {
                "name": "Cuanalá",
                "countryCode": "MX"
            },
            {
                "name": "Cuapiaxtla de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Cuatro Caminos",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtamazaco",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Cuautempan",
                "countryCode": "MX"
            },
            {
                "name": "Cuautinchán",
                "countryCode": "MX"
            },
            {
                "name": "Cuautlancingo",
                "countryCode": "MX"
            },
            {
                "name": "Cuautotola",
                "countryCode": "MX"
            },
            {
                "name": "Cuautotolapan (San José)",
                "countryCode": "MX"
            },
            {
                "name": "Cuaxoxpan",
                "countryCode": "MX"
            },
            {
                "name": "Cuaxuxpa",
                "countryCode": "MX"
            },
            {
                "name": "Cuayuca de Andrade",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Cuetzalan",
                "countryCode": "MX"
            },
            {
                "name": "Cuetzalan del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Cuexpala",
                "countryCode": "MX"
            },
            {
                "name": "Cuyoaco",
                "countryCode": "MX"
            },
            {
                "name": "Dolores Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Domingo Arenas",
                "countryCode": "MX"
            },
            {
                "name": "El Capulo (La Quebradora)",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Chacal",
                "countryCode": "MX"
            },
            {
                "name": "El Encanto del Cerril",
                "countryCode": "MX"
            },
            {
                "name": "El Fuerte de la Unión",
                "countryCode": "MX"
            },
            {
                "name": "El Mirador",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar",
                "countryCode": "MX"
            },
            {
                "name": "El Progreso",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón Citlaltépetl",
                "countryCode": "MX"
            },
            {
                "name": "El Veladero",
                "countryCode": "MX"
            },
            {
                "name": "Eloxochitlán",
                "countryCode": "MX"
            },
            {
                "name": "Emancipación Quetzalapa",
                "countryCode": "MX"
            },
            {
                "name": "Emilio Carranza (Santa Cruz)",
                "countryCode": "MX"
            },
            {
                "name": "Emilio Portes Gil",
                "countryCode": "MX"
            },
            {
                "name": "Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Ignacio Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Villa",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Z. Mena",
                "countryCode": "MX"
            },
            {
                "name": "Galaxia la Calera",
                "countryCode": "MX"
            },
            {
                "name": "General Felipe Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Gilberto Camacho",
                "countryCode": "MX"
            },
            {
                "name": "González Ortega",
                "countryCode": "MX"
            },
            {
                "name": "Grajales",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Calderón",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Enríquez",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Gómez Poniente",
                "countryCode": "MX"
            },
            {
                "name": "Hermenegildo Galeana",
                "countryCode": "MX"
            },
            {
                "name": "Honey",
                "countryCode": "MX"
            },
            {
                "name": "Huachinantla",
                "countryCode": "MX"
            },
            {
                "name": "Huahuaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Huajoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Huaquechula",
                "countryCode": "MX"
            },
            {
                "name": "Huatlatlauca",
                "countryCode": "MX"
            },
            {
                "name": "Huauchinango",
                "countryCode": "MX"
            },
            {
                "name": "Huautla de Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Huaxcaleca",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetla",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetlan el Chico",
                "countryCode": "MX"
            },
            {
                "name": "Huehuetlán el Grande",
                "countryCode": "MX"
            },
            {
                "name": "Huejonapan",
                "countryCode": "MX"
            },
            {
                "name": "Huejotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Hueyapan",
                "countryCode": "MX"
            },
            {
                "name": "Hueyotlipan",
                "countryCode": "MX"
            },
            {
                "name": "Hueytamalco",
                "countryCode": "MX"
            },
            {
                "name": "Hueytlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Huilacapixtla",
                "countryCode": "MX"
            },
            {
                "name": "Huitzilac",
                "countryCode": "MX"
            },
            {
                "name": "Huitzilan",
                "countryCode": "MX"
            },
            {
                "name": "Huitziltepec",
                "countryCode": "MX"
            },
            {
                "name": "Huitzmaloc",
                "countryCode": "MX"
            },
            {
                "name": "Huixcolotla",
                "countryCode": "MX"
            },
            {
                "name": "Icxotitla",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Allende (Concepción)",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Manuel Altamirano",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Itzoteno",
                "countryCode": "MX"
            },
            {
                "name": "Ixcamilpa de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Ixcaquixtla",
                "countryCode": "MX"
            },
            {
                "name": "Ixtacamaxtitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ixtahuiata (La Legua)",
                "countryCode": "MX"
            },
            {
                "name": "Ixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Ixticpan",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Ixtlahuaca Barrio",
                "countryCode": "MX"
            },
            {
                "name": "Ixtolco de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Izúcar de Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Jalpan",
                "countryCode": "MX"
            },
            {
                "name": "Jaltocan",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Nazareno",
                "countryCode": "MX"
            },
            {
                "name": "Jicolapa",
                "countryCode": "MX"
            },
            {
                "name": "Jilotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Jolalpan",
                "countryCode": "MX"
            },
            {
                "name": "Jonotla",
                "countryCode": "MX"
            },
            {
                "name": "Jopala",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Juan C. Bonilla",
                "countryCode": "MX"
            },
            {
                "name": "Juan Galindo",
                "countryCode": "MX"
            },
            {
                "name": "Juan N. Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Juan de la Granja",
                "countryCode": "MX"
            },
            {
                "name": "Juárez Coronaco",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada",
                "countryCode": "MX"
            },
            {
                "name": "La Compañia",
                "countryCode": "MX"
            },
            {
                "name": "La Galarza",
                "countryCode": "MX"
            },
            {
                "name": "La Gloria",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena Tetela Morelos",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena Yancuitlalpan",
                "countryCode": "MX"
            },
            {
                "name": "La Pahua",
                "countryCode": "MX"
            },
            {
                "name": "La Purísima",
                "countryCode": "MX"
            },
            {
                "name": "La Resurrección",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad Chautenco",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad Tianguismanalco",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "La Unión Ejido Mexcaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Lacapan Camallagne",
                "countryCode": "MX"
            },
            {
                "name": "Lafragua",
                "countryCode": "MX"
            },
            {
                "name": "Las Colonias de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Las Lajas",
                "countryCode": "MX"
            },
            {
                "name": "Las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "Las Nieves",
                "countryCode": "MX"
            },
            {
                "name": "Leacaman",
                "countryCode": "MX"
            },
            {
                "name": "Libertad Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Libres",
                "countryCode": "MX"
            },
            {
                "name": "Lipuntahuaca",
                "countryCode": "MX"
            },
            {
                "name": "Loma Alta",
                "countryCode": "MX"
            },
            {
                "name": "Loma Bonita",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Romero",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Los Cristales (Talican)",
                "countryCode": "MX"
            },
            {
                "name": "Los Encinos",
                "countryCode": "MX"
            },
            {
                "name": "Los Garcías",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes Tlanechicolpan",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Los Ángeles Tetela",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena Cuayucatepec",
                "countryCode": "MX"
            },
            {
                "name": "Manantiales",
                "countryCode": "MX"
            },
            {
                "name": "Manuel Edgardo Ávalos (San Isidro)",
                "countryCode": "MX"
            },
            {
                "name": "Matlahuacales Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Matzaco",
                "countryCode": "MX"
            },
            {
                "name": "Mazapiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Mazatepec",
                "countryCode": "MX"
            },
            {
                "name": "Mecapalapa",
                "countryCode": "MX"
            },
            {
                "name": "Metlaltoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Mexcalcuautla",
                "countryCode": "MX"
            },
            {
                "name": "Miahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Michac",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Negrete",
                "countryCode": "MX"
            },
            {
                "name": "Miravalles",
                "countryCode": "MX"
            },
            {
                "name": "Mixtla",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Morelos Cañada",
                "countryCode": "MX"
            },
            {
                "name": "Moyotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Máximo Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Nanacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Naupan",
                "countryCode": "MX"
            },
            {
                "name": "Nauzontla",
                "countryCode": "MX"
            },
            {
                "name": "Nealtican",
                "countryCode": "MX"
            },
            {
                "name": "Necaxa",
                "countryCode": "MX"
            },
            {
                "name": "Necoxcalco",
                "countryCode": "MX"
            },
            {
                "name": "Necoxtla",
                "countryCode": "MX"
            },
            {
                "name": "Nenehuaca",
                "countryCode": "MX"
            },
            {
                "name": "Nexpan",
                "countryCode": "MX"
            },
            {
                "name": "Nexticapan",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nopalucan",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo México",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Necaxa",
                "countryCode": "MX"
            },
            {
                "name": "Ocotepec",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlamanic",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlán de Betancourt",
                "countryCode": "MX"
            },
            {
                "name": "Ocotlán de Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Olintla",
                "countryCode": "MX"
            },
            {
                "name": "Ometepec",
                "countryCode": "MX"
            },
            {
                "name": "Oriental",
                "countryCode": "MX"
            },
            {
                "name": "Oyameles de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ozelonacaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Pahuatlán de Valle",
                "countryCode": "MX"
            },
            {
                "name": "Palmar de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Palmarito Tochapan",
                "countryCode": "MX"
            },
            {
                "name": "Pantepec",
                "countryCode": "MX"
            },
            {
                "name": "Papaloctipan",
                "countryCode": "MX"
            },
            {
                "name": "Papatlazolco",
                "countryCode": "MX"
            },
            {
                "name": "Paso Carretas",
                "countryCode": "MX"
            },
            {
                "name": "Paso Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Patla",
                "countryCode": "MX"
            },
            {
                "name": "Pericotepec",
                "countryCode": "MX"
            },
            {
                "name": "Petlalcingo",
                "countryCode": "MX"
            },
            {
                "name": "Pezmatlán",
                "countryCode": "MX"
            },
            {
                "name": "Piaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Pochálcatl",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Progreso de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Puebla",
                "countryCode": "MX"
            },
            {
                "name": "Putlunichuchut (Vista Hermosa)",
                "countryCode": "MX"
            },
            {
                "name": "Quamila",
                "countryCode": "MX"
            },
            {
                "name": "Quecholac",
                "countryCode": "MX"
            },
            {
                "name": "Quechulac",
                "countryCode": "MX"
            },
            {
                "name": "Rafael J. García",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Chico",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Saltillo",
                "countryCode": "MX"
            },
            {
                "name": "San Acateno",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Huixaxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín Tlaxco",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Azumiatla",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Cacaloapan",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Calpan",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Cholula",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Hueyacatitla",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Payuca",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Yahuitlalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Alpanocan",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Atotonilco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Cañada",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Juárez",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Matlahuacales",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Mihuacán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Ocopetlatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Portezuelo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Soledad",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tecolco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tlacamilco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tlatenco",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Virreyes",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio la Portilla",
                "countryCode": "MX"
            },
            {
                "name": "San Baltazar Atlimeyaya",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Hueyapan",
                "countryCode": "MX"
            },
            {
                "name": "San Bernabé Temoxtitla",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardino Lagunas",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardino Tlaxcalancingo",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura Tecaltzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal Xochimilpa",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal los Nava",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Diego el Organal",
                "countryCode": "MX"
            },
            {
                "name": "San Diego la Mesa Tochimiltzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Otlaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cuapa",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cuautlancingo",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Independencia (Santa María Aserradero)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Ocotlán",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tepeyecac",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tláloc",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Zacapexpan",
                "countryCode": "MX"
            },
            {
                "name": "San Félix Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "San Félix Rijo",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Chilac",
                "countryCode": "MX"
            },
            {
                "name": "San Gabriel Tetzoyocán",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "San Gregorio Aztotoacan",
                "countryCode": "MX"
            },
            {
                "name": "San Hipólito",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Huilotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Monterrosas",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Almoloya",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Axochitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Coaltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Coyula",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Ocotitla",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Xayacatlán",
                "countryCode": "MX"
            },
            {
                "name": "San José",
                "countryCode": "MX"
            },
            {
                "name": "San José Acateno",
                "countryCode": "MX"
            },
            {
                "name": "San José Alchichica",
                "countryCode": "MX"
            },
            {
                "name": "San José Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "San José Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San José Carpinteros",
                "countryCode": "MX"
            },
            {
                "name": "San José Chiapa",
                "countryCode": "MX"
            },
            {
                "name": "San José Cuyachapa",
                "countryCode": "MX"
            },
            {
                "name": "San José Ixtapa",
                "countryCode": "MX"
            },
            {
                "name": "San José Manzanitos",
                "countryCode": "MX"
            },
            {
                "name": "San José Monte Chiquito",
                "countryCode": "MX"
            },
            {
                "name": "San José Ozumba",
                "countryCode": "MX"
            },
            {
                "name": "San José Tilapa",
                "countryCode": "MX"
            },
            {
                "name": "San José Tlacuitlapan",
                "countryCode": "MX"
            },
            {
                "name": "San José de Gracia",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Amecac",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Atenco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Atzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Colón",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Cuautla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Epatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Huiluco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Ixcaquixtla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Pancoac",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Raboso",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tahitic",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tejaluca",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tejupa",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tepulco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tetla",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tezongo",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Tuxco",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Xiutetelco",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Chiautzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Joya de Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Tlaxipehuala",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Atzala",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas el Grande",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas el Viejo",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Ajajalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Coyotzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Tehuiloyocan",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Eloxochitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Tlacoyalco",
                "countryCode": "MX"
            },
            {
                "name": "San Martin Texmelucan de Labastida",
                "countryCode": "MX"
            },
            {
                "name": "San Martín",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Caltenco",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Esperilla",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Mazateopan",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Texmelucan",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Tlamapa",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Tlapala",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Ayecac",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Capultitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Ozolco",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Parra",
                "countryCode": "MX"
            },
            {
                "name": "San Mateo Soltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Matias Tlalancaleca",
                "countryCode": "MX"
            },
            {
                "name": "San Matías Atzala",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Aguacomulican",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Analco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Atlapulco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Canoa",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Espejo",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ixitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Ocotenco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Papaxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tecuitlapa",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tenango",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tenextatiloyan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tianguistenco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tianguizolco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Xoxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Zoapan",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Zozutla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Milagro",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel del Progreso",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolas Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Tolentino",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Zecalacoayan",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Zoyapetlayoca",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de los Ranchos",
                "countryCode": "MX"
            },
            {
                "name": "San Pablito",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Ahuatempa",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Anicano",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo de las Tunas",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Atmatla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Cholula",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Cuayuca",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Itztla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Petlacotla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Temamatla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tetitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlaolantongo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Yancuitlalpan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Yeloixtlahuaca",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Zacachimalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro la Joya",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Ixtapalucan",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Tlanalapan",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador (Naranjillo)",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador El Seco",
                "countryCode": "MX"
            },
            {
                "name": "San Salvador el Verde",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Alcomunga",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Teteles",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián Villanueva",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián de Aparicio",
                "countryCode": "MX"
            },
            {
                "name": "San Simón",
                "countryCode": "MX"
            },
            {
                "name": "San Simón Atzitzintla",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Boquerón",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Coyotepec",
                "countryCode": "MX"
            },
            {
                "name": "Sanctorum",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Acozautla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Coapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Necoxtla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Xalmimilulco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Tlaltempan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Villanueva",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cecilia Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Santa Clara Huitziltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Acapa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Ajajalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Cuautomatitla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Huitziltepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Otlatla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Texmalaquilla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Xaltetela",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz el Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "Santa Inés Tecuexcomac",
                "countryCode": "MX"
            },
            {
                "name": "Santa Inés Varela la Luz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel Cholula",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía Cosamaloapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Maria Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa Martha Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Atexcac",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Guadalupe Tecola",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ixtiyucán",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Malacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nenetzintla",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Nepopualco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Techachalco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Texmelucan",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Xonacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Santa María la Alta",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita Tlahuapan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Acozac",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Alseseca",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Atzitzihuacán",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Coltzingo",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Momoxpan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tenango",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Yancuictlalpan",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Atoyatempan",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo Huehuetlán",
                "countryCode": "MX"
            },
            {
                "name": "Santo Nombre",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás Chautla",
                "countryCode": "MX"
            },
            {
                "name": "Sección 23",
                "countryCode": "MX"
            },
            {
                "name": "Soledad Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Soltepec",
                "countryCode": "MX"
            },
            {
                "name": "Sosa",
                "countryCode": "MX"
            },
            {
                "name": "Tacopan",
                "countryCode": "MX"
            },
            {
                "name": "Tanamacoyan",
                "countryCode": "MX"
            },
            {
                "name": "Tanhuixco",
                "countryCode": "MX"
            },
            {
                "name": "Tatauzoquico",
                "countryCode": "MX"
            },
            {
                "name": "Tatoxcac",
                "countryCode": "MX"
            },
            {
                "name": "Teacalco de Dorantes (San José Teacalco)",
                "countryCode": "MX"
            },
            {
                "name": "Tecali",
                "countryCode": "MX"
            },
            {
                "name": "Tecamachalco",
                "countryCode": "MX"
            },
            {
                "name": "Tecoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Tecomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Tecpantzacoalco",
                "countryCode": "MX"
            },
            {
                "name": "Tecuanipa",
                "countryCode": "MX"
            },
            {
                "name": "Tecuanipan",
                "countryCode": "MX"
            },
            {
                "name": "Tehuacán",
                "countryCode": "MX"
            },
            {
                "name": "Tehuitzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tejaluca",
                "countryCode": "MX"
            },
            {
                "name": "Teloxtoc",
                "countryCode": "MX"
            },
            {
                "name": "Telpatlán",
                "countryCode": "MX"
            },
            {
                "name": "Temalacayuca",
                "countryCode": "MX"
            },
            {
                "name": "Temextla",
                "countryCode": "MX"
            },
            {
                "name": "Tenampulco",
                "countryCode": "MX"
            },
            {
                "name": "Tenango de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "Teontepec",
                "countryCode": "MX"
            },
            {
                "name": "Teopantlán",
                "countryCode": "MX"
            },
            {
                "name": "Teotlalco",
                "countryCode": "MX"
            },
            {
                "name": "Teotlaltzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tepanco",
                "countryCode": "MX"
            },
            {
                "name": "Tepango",
                "countryCode": "MX"
            },
            {
                "name": "Tepango de Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Tepapayeca",
                "countryCode": "MX"
            },
            {
                "name": "Tepatlaxco de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Tepazolco",
                "countryCode": "MX"
            },
            {
                "name": "Tepeaca",
                "countryCode": "MX"
            },
            {
                "name": "Tepeojuma",
                "countryCode": "MX"
            },
            {
                "name": "Tepeteno de Iturbide",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitán Reyeshogpan de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlacolco",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzintla",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzitzintla",
                "countryCode": "MX"
            },
            {
                "name": "Tepexco",
                "countryCode": "MX"
            },
            {
                "name": "Tepexi de Rodríguez",
                "countryCode": "MX"
            },
            {
                "name": "Tepeyahualco",
                "countryCode": "MX"
            },
            {
                "name": "Tepeyahualco de Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Tepeyehualco de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Tetela de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Tetelcingo",
                "countryCode": "MX"
            },
            {
                "name": "Teteles de Avila Castillo",
                "countryCode": "MX"
            },
            {
                "name": "Texcala",
                "countryCode": "MX"
            },
            {
                "name": "Texcalapa de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Texocoyohuac",
                "countryCode": "MX"
            },
            {
                "name": "Tezhuatepec",
                "countryCode": "MX"
            },
            {
                "name": "Teziutlan",
                "countryCode": "MX"
            },
            {
                "name": "Tezonteopan de Bonilla",
                "countryCode": "MX"
            },
            {
                "name": "Tianguismanalco",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Negra",
                "countryCode": "MX"
            },
            {
                "name": "Tilapa",
                "countryCode": "MX"
            },
            {
                "name": "Tlacamilco",
                "countryCode": "MX"
            },
            {
                "name": "Tlachichuca",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec (San Mateo)",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec de Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec de José Manzo",
                "countryCode": "MX"
            },
            {
                "name": "Tlacuilotepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlahuapan",
                "countryCode": "MX"
            },
            {
                "name": "Tlaixpan",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltenango",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltepango",
                "countryCode": "MX"
            },
            {
                "name": "Tlamanca",
                "countryCode": "MX"
            },
            {
                "name": "Tlamanca de Hernández",
                "countryCode": "MX"
            },
            {
                "name": "Tlamaya Grande",
                "countryCode": "MX"
            },
            {
                "name": "Tlanalapan",
                "countryCode": "MX"
            },
            {
                "name": "Tlancualpican",
                "countryCode": "MX"
            },
            {
                "name": "Tlanepantla",
                "countryCode": "MX"
            },
            {
                "name": "Tlaola",
                "countryCode": "MX"
            },
            {
                "name": "Tlapacoya",
                "countryCode": "MX"
            },
            {
                "name": "Tlapanalá",
                "countryCode": "MX"
            },
            {
                "name": "Tlatempa",
                "countryCode": "MX"
            },
            {
                "name": "Tlatlauquitepec",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxcalantongo",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxco",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxco (Santiago Tlaxco)",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxpanaloya",
                "countryCode": "MX"
            },
            {
                "name": "Tlayehualancingo",
                "countryCode": "MX"
            },
            {
                "name": "Tlixco",
                "countryCode": "MX"
            },
            {
                "name": "Tochimilco",
                "countryCode": "MX"
            },
            {
                "name": "Tochtepec",
                "countryCode": "MX"
            },
            {
                "name": "Tomaquilapa",
                "countryCode": "MX"
            },
            {
                "name": "Tomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Tonalixco",
                "countryCode": "MX"
            },
            {
                "name": "Totoltepec",
                "countryCode": "MX"
            },
            {
                "name": "Totoltepec de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Toxtla",
                "countryCode": "MX"
            },
            {
                "name": "Tozihuic",
                "countryCode": "MX"
            },
            {
                "name": "Tronconal",
                "countryCode": "MX"
            },
            {
                "name": "Tula",
                "countryCode": "MX"
            },
            {
                "name": "Tulcingo",
                "countryCode": "MX"
            },
            {
                "name": "Tulcingo de Valle",
                "countryCode": "MX"
            },
            {
                "name": "Tulimanca",
                "countryCode": "MX"
            },
            {
                "name": "Tuxtla",
                "countryCode": "MX"
            },
            {
                "name": "Tuzamapan",
                "countryCode": "MX"
            },
            {
                "name": "Tuzuapan",
                "countryCode": "MX"
            },
            {
                "name": "Tzicatlacoyan",
                "countryCode": "MX"
            },
            {
                "name": "Tzicatlán",
                "countryCode": "MX"
            },
            {
                "name": "Tzinacapan",
                "countryCode": "MX"
            },
            {
                "name": "Unidad Grajales INFONAVIT",
                "countryCode": "MX"
            },
            {
                "name": "Venta Grande",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Avila Camacho",
                "countryCode": "MX"
            },
            {
                "name": "Villa Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Villa Mariano Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Xacaxomulco",
                "countryCode": "MX"
            },
            {
                "name": "Xalacapan de Lucke",
                "countryCode": "MX"
            },
            {
                "name": "Xalitzintla",
                "countryCode": "MX"
            },
            {
                "name": "Xaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Xaltepuxtla",
                "countryCode": "MX"
            },
            {
                "name": "Xalticpac",
                "countryCode": "MX"
            },
            {
                "name": "Xayacatlán de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Xicotepec",
                "countryCode": "MX"
            },
            {
                "name": "Xicotepec de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Xicotlán",
                "countryCode": "MX"
            },
            {
                "name": "Xilocuautla",
                "countryCode": "MX"
            },
            {
                "name": "Xiloxochico de Rafael Ávila Camacho",
                "countryCode": "MX"
            },
            {
                "name": "Xitlama",
                "countryCode": "MX"
            },
            {
                "name": "Xiutetelco",
                "countryCode": "MX"
            },
            {
                "name": "Xochiapulco",
                "countryCode": "MX"
            },
            {
                "name": "Xochicuautla",
                "countryCode": "MX"
            },
            {
                "name": "Xochiltepec",
                "countryCode": "MX"
            },
            {
                "name": "Xochimilco",
                "countryCode": "MX"
            },
            {
                "name": "Xochinanacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Xochitepec",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlan Todos Santos",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlaxco (San Baltazar)",
                "countryCode": "MX"
            },
            {
                "name": "Xochitlán de Vicente Suárez",
                "countryCode": "MX"
            },
            {
                "name": "Xocotepéc",
                "countryCode": "MX"
            },
            {
                "name": "Xoloateno",
                "countryCode": "MX"
            },
            {
                "name": "Xolotla",
                "countryCode": "MX"
            },
            {
                "name": "Xonalpu",
                "countryCode": "MX"
            },
            {
                "name": "Xonocuautla",
                "countryCode": "MX"
            },
            {
                "name": "Xoxonacatla",
                "countryCode": "MX"
            },
            {
                "name": "Xuchapa",
                "countryCode": "MX"
            },
            {
                "name": "Yaonahuac",
                "countryCode": "MX"
            },
            {
                "name": "Yehualtepec",
                "countryCode": "MX"
            },
            {
                "name": "Yopi",
                "countryCode": "MX"
            },
            {
                "name": "Zacaola",
                "countryCode": "MX"
            },
            {
                "name": "Zacapala",
                "countryCode": "MX"
            },
            {
                "name": "Zacapechpan",
                "countryCode": "MX"
            },
            {
                "name": "Zacapoaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Zacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Zacatlán",
                "countryCode": "MX"
            },
            {
                "name": "Zahuatlán de Morelos (San José)",
                "countryCode": "MX"
            },
            {
                "name": "Zapotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Zautla",
                "countryCode": "MX"
            },
            {
                "name": "Zempoala",
                "countryCode": "MX"
            },
            {
                "name": "Zihuateutla",
                "countryCode": "MX"
            },
            {
                "name": "Zinacatepec",
                "countryCode": "MX"
            },
            {
                "name": "Zoatecpan",
                "countryCode": "MX"
            },
            {
                "name": "Zolonquiapa",
                "countryCode": "MX"
            },
            {
                "name": "Zongozotla",
                "countryCode": "MX"
            },
            {
                "name": "Zoquiapan",
                "countryCode": "MX"
            },
            {
                "name": "Zoquiopan",
                "countryCode": "MX"
            },
            {
                "name": "Zoquitlán",
                "countryCode": "MX"
            },
            {
                "name": "Zoyatitla",
                "countryCode": "MX"
            },
            {
                "name": "Zoyotla",
                "countryCode": "MX"
            },
            {
                "name": "Zozocolco de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Álamos Tepetitlán",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Agua Azul",
                "countryCode": "MX"
            },
            {
                "name": "Agua Fría",
                "countryCode": "MX"
            },
            {
                "name": "Agua Zarca",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatlán de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Ajuchitlancito",
                "countryCode": "MX"
            },
            {
                "name": "Ajuchitlán",
                "countryCode": "MX"
            },
            {
                "name": "Alfajayucan",
                "countryCode": "MX"
            },
            {
                "name": "Amazcala",
                "countryCode": "MX"
            },
            {
                "name": "Amealco",
                "countryCode": "MX"
            },
            {
                "name": "Apapátaro",
                "countryCode": "MX"
            },
            {
                "name": "Arcila",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Seco",
                "countryCode": "MX"
            },
            {
                "name": "Atongo",
                "countryCode": "MX"
            },
            {
                "name": "Bella Vista del Río",
                "countryCode": "MX"
            },
            {
                "name": "Bernal",
                "countryCode": "MX"
            },
            {
                "name": "Bordo Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Boxasní",
                "countryCode": "MX"
            },
            {
                "name": "Boyé",
                "countryCode": "MX"
            },
            {
                "name": "Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Cadereyta",
                "countryCode": "MX"
            },
            {
                "name": "Calamanda",
                "countryCode": "MX"
            },
            {
                "name": "Carbonera",
                "countryCode": "MX"
            },
            {
                "name": "Casa Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Ceja de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Cerro de la Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Charco Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Chichimequillas",
                "countryCode": "MX"
            },
            {
                "name": "Colinas de Santa Cruz Segunda Sección",
                "countryCode": "MX"
            },
            {
                "name": "Colonia los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Colón",
                "countryCode": "MX"
            },
            {
                "name": "Concá",
                "countryCode": "MX"
            },
            {
                "name": "Coyotillos",
                "countryCode": "MX"
            },
            {
                "name": "Dolores Cuadrilla de Enmedio",
                "countryCode": "MX"
            },
            {
                "name": "El Blanco",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen",
                "countryCode": "MX"
            },
            {
                "name": "El Cazadero",
                "countryCode": "MX"
            },
            {
                "name": "El Ciervo",
                "countryCode": "MX"
            },
            {
                "name": "El Colorado",
                "countryCode": "MX"
            },
            {
                "name": "El Coto",
                "countryCode": "MX"
            },
            {
                "name": "El Gallo",
                "countryCode": "MX"
            },
            {
                "name": "El Jaral",
                "countryCode": "MX"
            },
            {
                "name": "El Jazmín",
                "countryCode": "MX"
            },
            {
                "name": "El Lobo",
                "countryCode": "MX"
            },
            {
                "name": "El Milagro",
                "countryCode": "MX"
            },
            {
                "name": "El Nabo",
                "countryCode": "MX"
            },
            {
                "name": "El Organal",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar",
                "countryCode": "MX"
            },
            {
                "name": "El Paraíso",
                "countryCode": "MX"
            },
            {
                "name": "El Pinto",
                "countryCode": "MX"
            },
            {
                "name": "El Pozo",
                "countryCode": "MX"
            },
            {
                "name": "El Pueblito",
                "countryCode": "MX"
            },
            {
                "name": "El Rincón",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Salitre",
                "countryCode": "MX"
            },
            {
                "name": "El Saúz",
                "countryCode": "MX"
            },
            {
                "name": "El Tejocote",
                "countryCode": "MX"
            },
            {
                "name": "El Vegil",
                "countryCode": "MX"
            },
            {
                "name": "Epigmenio González",
                "countryCode": "MX"
            },
            {
                "name": "Escolasticas",
                "countryCode": "MX"
            },
            {
                "name": "Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Ezequiel Montes",
                "countryCode": "MX"
            },
            {
                "name": "Fuentezuelas",
                "countryCode": "MX"
            },
            {
                "name": "Galeras",
                "countryCode": "MX"
            },
            {
                "name": "Galindo",
                "countryCode": "MX"
            },
            {
                "name": "General Lázaro Cárdenas (El Colorado)",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Septién",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda la Cruz [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Higuerillas",
                "countryCode": "MX"
            },
            {
                "name": "Huimilpan",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Pérez",
                "countryCode": "MX"
            },
            {
                "name": "Jalpan",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Joaquín Herrera",
                "countryCode": "MX"
            },
            {
                "name": "Jofrito",
                "countryCode": "MX"
            },
            {
                "name": "Juriquilla",
                "countryCode": "MX"
            },
            {
                "name": "La Barreta",
                "countryCode": "MX"
            },
            {
                "name": "La Cañada",
                "countryCode": "MX"
            },
            {
                "name": "La Ceja",
                "countryCode": "MX"
            },
            {
                "name": "La D",
                "countryCode": "MX"
            },
            {
                "name": "La Estacada",
                "countryCode": "MX"
            },
            {
                "name": "La Estancia",
                "countryCode": "MX"
            },
            {
                "name": "La Fuente",
                "countryCode": "MX"
            },
            {
                "name": "La Gotera",
                "countryCode": "MX"
            },
            {
                "name": "La Griega",
                "countryCode": "MX"
            },
            {
                "name": "La Laja",
                "countryCode": "MX"
            },
            {
                "name": "La Lira",
                "countryCode": "MX"
            },
            {
                "name": "La Llave",
                "countryCode": "MX"
            },
            {
                "name": "La Loma",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Monja",
                "countryCode": "MX"
            },
            {
                "name": "La Negreta",
                "countryCode": "MX"
            },
            {
                "name": "La Noria",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Piedad",
                "countryCode": "MX"
            },
            {
                "name": "La Pradera",
                "countryCode": "MX"
            },
            {
                "name": "La Solana",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Tortuga",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "La Valla",
                "countryCode": "MX"
            },
            {
                "name": "La Versolilla",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Vaquerías",
                "countryCode": "MX"
            },
            {
                "name": "Lagunillas",
                "countryCode": "MX"
            },
            {
                "name": "Landa de Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Las Lajitas",
                "countryCode": "MX"
            },
            {
                "name": "Las Taponas",
                "countryCode": "MX"
            },
            {
                "name": "Loma Linda",
                "countryCode": "MX"
            },
            {
                "name": "Los Baños",
                "countryCode": "MX"
            },
            {
                "name": "Los Benitos",
                "countryCode": "MX"
            },
            {
                "name": "Los Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Los Cues",
                "countryCode": "MX"
            },
            {
                "name": "Los Olvera",
                "countryCode": "MX"
            },
            {
                "name": "Los Pocitos",
                "countryCode": "MX"
            },
            {
                "name": "Lourdes",
                "countryCode": "MX"
            },
            {
                "name": "Mesillas",
                "countryCode": "MX"
            },
            {
                "name": "Mompaní",
                "countryCode": "MX"
            },
            {
                "name": "Montenegro",
                "countryCode": "MX"
            },
            {
                "name": "Ninguno [CERESO]",
                "countryCode": "MX"
            },
            {
                "name": "Noria Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Palo Alto",
                "countryCode": "MX"
            },
            {
                "name": "Paseos del Marqués",
                "countryCode": "MX"
            },
            {
                "name": "Paseos del Pedregal",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Mata",
                "countryCode": "MX"
            },
            {
                "name": "Patria Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Pedro Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Peñamiller",
                "countryCode": "MX"
            },
            {
                "name": "Pie de Gallo",
                "countryCode": "MX"
            },
            {
                "name": "Pinal de Amoles",
                "countryCode": "MX"
            },
            {
                "name": "Pintillo",
                "countryCode": "MX"
            },
            {
                "name": "Presa de Rayas",
                "countryCode": "MX"
            },
            {
                "name": "Puerta de Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Aguirre",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Carroza",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Chiquihuite",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Nieto",
                "countryCode": "MX"
            },
            {
                "name": "Purísima de Arista",
                "countryCode": "MX"
            },
            {
                "name": "Purísima de Cubos (La Purísima)",
                "countryCode": "MX"
            },
            {
                "name": "Querétaro",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Bellavista [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Saldarriaga",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio de la Cal",
                "countryCode": "MX"
            },
            {
                "name": "San Clemente",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de la Palma",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "MX"
            },
            {
                "name": "San Ildefonso Tultepec",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Miranda",
                "countryCode": "MX"
            },
            {
                "name": "San Javier",
                "countryCode": "MX"
            },
            {
                "name": "San Jose de los Olvera",
                "countryCode": "MX"
            },
            {
                "name": "San José Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "San José Itho",
                "countryCode": "MX"
            },
            {
                "name": "San José Tepuzas",
                "countryCode": "MX"
            },
            {
                "name": "San José de la Laja",
                "countryCode": "MX"
            },
            {
                "name": "San José del Jagüey",
                "countryCode": "MX"
            },
            {
                "name": "San José el Alto",
                "countryCode": "MX"
            },
            {
                "name": "San Juan del Río",
                "countryCode": "MX"
            },
            {
                "name": "San Martín",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Florida",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Amazcala",
                "countryCode": "MX"
            },
            {
                "name": "San Miguelito",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás de la Torre",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo Tolimán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Mártir",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián de Las Barrancas Sur",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Ferrer",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente el Alto",
                "countryCode": "MX"
            },
            {
                "name": "Santa Bárbara de La Cueva",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Escandón",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Begoña",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Santa Matilde",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Jauregui",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Xajay",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa de Lima",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Mexquititlán Barrio 4to.",
                "countryCode": "MX"
            },
            {
                "name": "Santiago de Querétaro",
                "countryCode": "MX"
            },
            {
                "name": "Santillán",
                "countryCode": "MX"
            },
            {
                "name": "Sergio Villaseñor",
                "countryCode": "MX"
            },
            {
                "name": "Tequisquiapan",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Tlacote el Bajo",
                "countryCode": "MX"
            },
            {
                "name": "Tolimán",
                "countryCode": "MX"
            },
            {
                "name": "Urecho",
                "countryCode": "MX"
            },
            {
                "name": "Venceremos",
                "countryCode": "MX"
            },
            {
                "name": "Viborillas",
                "countryCode": "MX"
            },
            {
                "name": "Villa Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Villas Fundadores [Fraccionamiento]",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa (Cuasinada)",
                "countryCode": "MX"
            },
            {
                "name": "Visthá",
                "countryCode": "MX"
            },
            {
                "name": "Vizarrón",
                "countryCode": "MX"
            },
            {
                "name": "Yosphí",
                "countryCode": "MX"
            },
            {
                "name": "Zamorano",
                "countryCode": "MX"
            },
            {
                "name": "Akumal",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil",
                "countryCode": "MX"
            },
            {
                "name": "Bacalar",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Cacao",
                "countryCode": "MX"
            },
            {
                "name": "Calderitas",
                "countryCode": "MX"
            },
            {
                "name": "Cancún",
                "countryCode": "MX"
            },
            {
                "name": "Caobas",
                "countryCode": "MX"
            },
            {
                "name": "Carlos A. Madrazo",
                "countryCode": "MX"
            },
            {
                "name": "Chetumal",
                "countryCode": "MX"
            },
            {
                "name": "Chiquilá",
                "countryCode": "MX"
            },
            {
                "name": "Chunhuhub",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Chemuyil",
                "countryCode": "MX"
            },
            {
                "name": "Coba",
                "countryCode": "MX"
            },
            {
                "name": "Cocoyol",
                "countryCode": "MX"
            },
            {
                "name": "Cozumel",
                "countryCode": "MX"
            },
            {
                "name": "Dziuche",
                "countryCode": "MX"
            },
            {
                "name": "Dzula",
                "countryCode": "MX"
            },
            {
                "name": "El Tintal",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Huay Max",
                "countryCode": "MX"
            },
            {
                "name": "Huay-Pix",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Isla Holbox",
                "countryCode": "MX"
            },
            {
                "name": "Isla Mujeres",
                "countryCode": "MX"
            },
            {
                "name": "Javier Rojo Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Jose Narciso Rovirosa",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Juan Sarabia",
                "countryCode": "MX"
            },
            {
                "name": "Kancabchén",
                "countryCode": "MX"
            },
            {
                "name": "Kantunilkín",
                "countryCode": "MX"
            },
            {
                "name": "La Presumida",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "Leona Vicario",
                "countryCode": "MX"
            },
            {
                "name": "Los Divorciados",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Maya Balam",
                "countryCode": "MX"
            },
            {
                "name": "Morocoy",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Xcán",
                "countryCode": "MX"
            },
            {
                "name": "Othón P. Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Playa del Carmen",
                "countryCode": "MX"
            },
            {
                "name": "Polyuc",
                "countryCode": "MX"
            },
            {
                "name": "Presidente Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Pucté",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Aventuras",
                "countryCode": "MX"
            },
            {
                "name": "Saban",
                "countryCode": "MX"
            },
            {
                "name": "Sabidos",
                "countryCode": "MX"
            },
            {
                "name": "San Angel",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Segundo",
                "countryCode": "MX"
            },
            {
                "name": "Sergio Butrón Casas",
                "countryCode": "MX"
            },
            {
                "name": "Señor",
                "countryCode": "MX"
            },
            {
                "name": "Subteniente López",
                "countryCode": "MX"
            },
            {
                "name": "Tepich",
                "countryCode": "MX"
            },
            {
                "name": "Tihosuco",
                "countryCode": "MX"
            },
            {
                "name": "Tulum",
                "countryCode": "MX"
            },
            {
                "name": "Ucum",
                "countryCode": "MX"
            },
            {
                "name": "X Cabil",
                "countryCode": "MX"
            },
            {
                "name": "X-Hazil Sur",
                "countryCode": "MX"
            },
            {
                "name": "X-pichil",
                "countryCode": "MX"
            },
            {
                "name": "Xul-Ha",
                "countryCode": "MX"
            },
            {
                "name": "Zacalaca",
                "countryCode": "MX"
            },
            {
                "name": "Zona Urbana Ejido Isla Mujeres",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo López Mateos (El Tamarindo)",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo Ruiz Cortines",
                "countryCode": "MX"
            },
            {
                "name": "Agua Caliente Grande (De Gastélum)",
                "countryCode": "MX"
            },
            {
                "name": "Agua Verde",
                "countryCode": "MX"
            },
            {
                "name": "Ahome",
                "countryCode": "MX"
            },
            {
                "name": "Alfonso G. Calderón (Poblado Siete)",
                "countryCode": "MX"
            },
            {
                "name": "Alfonso G. Calderón Velarde",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil (Siete Ejidos)",
                "countryCode": "MX"
            },
            {
                "name": "Alhuey",
                "countryCode": "MX"
            },
            {
                "name": "Altata",
                "countryCode": "MX"
            },
            {
                "name": "Angostura",
                "countryCode": "MX"
            },
            {
                "name": "Apoderado",
                "countryCode": "MX"
            },
            {
                "name": "Baburia",
                "countryCode": "MX"
            },
            {
                "name": "Bachigualatito",
                "countryCode": "MX"
            },
            {
                "name": "Bachoco",
                "countryCode": "MX"
            },
            {
                "name": "Bachomobampo Número Dos",
                "countryCode": "MX"
            },
            {
                "name": "Bacorehuis",
                "countryCode": "MX"
            },
            {
                "name": "Bacubirito",
                "countryCode": "MX"
            },
            {
                "name": "Badiraguato",
                "countryCode": "MX"
            },
            {
                "name": "Bagojo Colectivo",
                "countryCode": "MX"
            },
            {
                "name": "Bamoa",
                "countryCode": "MX"
            },
            {
                "name": "Bariometo",
                "countryCode": "MX"
            },
            {
                "name": "Barrón",
                "countryCode": "MX"
            },
            {
                "name": "Bellavista",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez (Vinatería)",
                "countryCode": "MX"
            },
            {
                "name": "Buen Retiro (El Retiro)",
                "countryCode": "MX"
            },
            {
                "name": "CERESO Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Cacalotán",
                "countryCode": "MX"
            },
            {
                "name": "Caimanero",
                "countryCode": "MX"
            },
            {
                "name": "Campo Balbuena",
                "countryCode": "MX"
            },
            {
                "name": "Campo Pesquero el Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Campo la Arrocera",
                "countryCode": "MX"
            },
            {
                "name": "Carricitos",
                "countryCode": "MX"
            },
            {
                "name": "Casa Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Cerrillos (Campo 35)",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Agudo",
                "countryCode": "MX"
            },
            {
                "name": "Ceuta",
                "countryCode": "MX"
            },
            {
                "name": "Chametla",
                "countryCode": "MX"
            },
            {
                "name": "Charay",
                "countryCode": "MX"
            },
            {
                "name": "Chihuahuita",
                "countryCode": "MX"
            },
            {
                "name": "Chinobampo",
                "countryCode": "MX"
            },
            {
                "name": "Choix",
                "countryCode": "MX"
            },
            {
                "name": "Cofradía de Navolato (Cofradía de los Rocha)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia 24 de Febrero",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Agrícola México (Palmitas)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Michoacana",
                "countryCode": "MX"
            },
            {
                "name": "Concentración 5 de Febrero",
                "countryCode": "MX"
            },
            {
                "name": "Concordia",
                "countryCode": "MX"
            },
            {
                "name": "Constancia",
                "countryCode": "MX"
            },
            {
                "name": "Corerepe",
                "countryCode": "MX"
            },
            {
                "name": "Cosalá",
                "countryCode": "MX"
            },
            {
                "name": "Costa Azul",
                "countryCode": "MX"
            },
            {
                "name": "Coyotitán",
                "countryCode": "MX"
            },
            {
                "name": "Cristo Rey",
                "countryCode": "MX"
            },
            {
                "name": "Cruz Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Cubiri de Portelas",
                "countryCode": "MX"
            },
            {
                "name": "Culiacancito",
                "countryCode": "MX"
            },
            {
                "name": "Culiacán",
                "countryCode": "MX"
            },
            {
                "name": "Dautillos",
                "countryCode": "MX"
            },
            {
                "name": "Dos de Abril",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Cajón Ojo de Agua Número Dos",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Cinco de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Cohuibampo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Mayocoba",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Ohuira",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Tosalibampo",
                "countryCode": "MX"
            },
            {
                "name": "El Aguajito",
                "countryCode": "MX"
            },
            {
                "name": "El Burrión",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizo",
                "countryCode": "MX"
            },
            {
                "name": "El Castillo",
                "countryCode": "MX"
            },
            {
                "name": "El Diez",
                "countryCode": "MX"
            },
            {
                "name": "El Dorado",
                "countryCode": "MX"
            },
            {
                "name": "El Fuerte",
                "countryCode": "MX"
            },
            {
                "name": "El Guayabo",
                "countryCode": "MX"
            },
            {
                "name": "El Habal",
                "countryCode": "MX"
            },
            {
                "name": "El Higueral",
                "countryCode": "MX"
            },
            {
                "name": "El Huajote",
                "countryCode": "MX"
            },
            {
                "name": "El Huitusi",
                "countryCode": "MX"
            },
            {
                "name": "El Limón de los Ramos",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Nio",
                "countryCode": "MX"
            },
            {
                "name": "El Pochotal",
                "countryCode": "MX"
            },
            {
                "name": "El Potrero de Sataya",
                "countryCode": "MX"
            },
            {
                "name": "El Pozole",
                "countryCode": "MX"
            },
            {
                "name": "El Progreso",
                "countryCode": "MX"
            },
            {
                "name": "El Quelite",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio",
                "countryCode": "MX"
            },
            {
                "name": "El Roble",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Sabino",
                "countryCode": "MX"
            },
            {
                "name": "El Saladito",
                "countryCode": "MX"
            },
            {
                "name": "El Varal (San Sebastián Número Uno)",
                "countryCode": "MX"
            },
            {
                "name": "El Verde",
                "countryCode": "MX"
            },
            {
                "name": "El Walamo",
                "countryCode": "MX"
            },
            {
                "name": "Eldorado",
                "countryCode": "MX"
            },
            {
                "name": "Elota",
                "countryCode": "MX"
            },
            {
                "name": "Empaque Tarriba",
                "countryCode": "MX"
            },
            {
                "name": "Escamillas",
                "countryCode": "MX"
            },
            {
                "name": "Escuinapa",
                "countryCode": "MX"
            },
            {
                "name": "Estacion Bamoa",
                "countryCode": "MX"
            },
            {
                "name": "Estación Capomas",
                "countryCode": "MX"
            },
            {
                "name": "Estación Chimaneco",
                "countryCode": "MX"
            },
            {
                "name": "Estación Obispo",
                "countryCode": "MX"
            },
            {
                "name": "Estación Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Angeles",
                "countryCode": "MX"
            },
            {
                "name": "Flor Azul",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Gabriel Leyva Solano (Zapotillo Dos)",
                "countryCode": "MX"
            },
            {
                "name": "Gabriel Leyva Velázquez",
                "countryCode": "MX"
            },
            {
                "name": "Gambino",
                "countryCode": "MX"
            },
            {
                "name": "Genaro Estrada",
                "countryCode": "MX"
            },
            {
                "name": "General Ángel Flores (La Palma)",
                "countryCode": "MX"
            },
            {
                "name": "Goros Número Dos",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guamúchil",
                "countryCode": "MX"
            },
            {
                "name": "Guasave",
                "countryCode": "MX"
            },
            {
                "name": "Guasavito",
                "countryCode": "MX"
            },
            {
                "name": "Gustavo Díaz Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "Heriberto Valdez Romero (El Guayabo)",
                "countryCode": "MX"
            },
            {
                "name": "Higuera de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Higuera de los Vega",
                "countryCode": "MX"
            },
            {
                "name": "Higueras de Abuya",
                "countryCode": "MX"
            },
            {
                "name": "Higueras de los Natoches",
                "countryCode": "MX"
            },
            {
                "name": "Huepaco",
                "countryCode": "MX"
            },
            {
                "name": "Isla del Bosque",
                "countryCode": "MX"
            },
            {
                "name": "Javier Rojo Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "Jitzamuri",
                "countryCode": "MX"
            },
            {
                "name": "Juan Aldama (El Tigre)",
                "countryCode": "MX"
            },
            {
                "name": "Juan José Ríos",
                "countryCode": "MX"
            },
            {
                "name": "La Brecha",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Concha (La Concepción)",
                "countryCode": "MX"
            },
            {
                "name": "La Cruz",
                "countryCode": "MX"
            },
            {
                "name": "La Despensa",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Loma (La Loma de Quila)",
                "countryCode": "MX"
            },
            {
                "name": "La Noria",
                "countryCode": "MX"
            },
            {
                "name": "La Noria de San Antonio",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Palmita y Anexos (La Presita)",
                "countryCode": "MX"
            },
            {
                "name": "La Presita",
                "countryCode": "MX"
            },
            {
                "name": "La Reforma",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad",
                "countryCode": "MX"
            },
            {
                "name": "Ladrilleras de Ocoro",
                "countryCode": "MX"
            },
            {
                "name": "Las Aguamitas",
                "countryCode": "MX"
            },
            {
                "name": "Las Arenitas",
                "countryCode": "MX"
            },
            {
                "name": "Las Brisas",
                "countryCode": "MX"
            },
            {
                "name": "Las Compuertas",
                "countryCode": "MX"
            },
            {
                "name": "Las Grullas Margen Derecha",
                "countryCode": "MX"
            },
            {
                "name": "Las Grullas Margen Izquierda",
                "countryCode": "MX"
            },
            {
                "name": "Las Moras",
                "countryCode": "MX"
            },
            {
                "name": "Las Quemazones",
                "countryCode": "MX"
            },
            {
                "name": "Leyva Solano",
                "countryCode": "MX"
            },
            {
                "name": "Licenciado Benito Juárez (Campo Gobierno)",
                "countryCode": "MX"
            },
            {
                "name": "Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Lo de Jesús (Campo Romero)",
                "countryCode": "MX"
            },
            {
                "name": "Los Angeles",
                "countryCode": "MX"
            },
            {
                "name": "Los Mochis",
                "countryCode": "MX"
            },
            {
                "name": "Los Pozos",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Macapule",
                "countryCode": "MX"
            },
            {
                "name": "Marcol",
                "countryCode": "MX"
            },
            {
                "name": "Maripa",
                "countryCode": "MX"
            },
            {
                "name": "Mazatlán",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Mesillas",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Valdez Quintero (El Corazón)",
                "countryCode": "MX"
            },
            {
                "name": "Mochicahui",
                "countryCode": "MX"
            },
            {
                "name": "Mochis",
                "countryCode": "MX"
            },
            {
                "name": "Mocorito",
                "countryCode": "MX"
            },
            {
                "name": "Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "Navolato",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Orba (Infiernito)",
                "countryCode": "MX"
            },
            {
                "name": "Oso Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Palmito del Verde",
                "countryCode": "MX"
            },
            {
                "name": "Palos Verdes",
                "countryCode": "MX"
            },
            {
                "name": "Pericos",
                "countryCode": "MX"
            },
            {
                "name": "Piaxtla de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Pitahayal",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Ayala (Campo Cinco)",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Número Cinco",
                "countryCode": "MX"
            },
            {
                "name": "Portaceli",
                "countryCode": "MX"
            },
            {
                "name": "Portugués de Gálvez",
                "countryCode": "MX"
            },
            {
                "name": "Potrerillo del Norote",
                "countryCode": "MX"
            },
            {
                "name": "Potrero de los Sánchez (Estación Techa)",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblos Unidos",
                "countryCode": "MX"
            },
            {
                "name": "Quila",
                "countryCode": "MX"
            },
            {
                "name": "Ranchito de Castro",
                "countryCode": "MX"
            },
            {
                "name": "Recoveco",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Salado",
                "countryCode": "MX"
            },
            {
                "name": "San Blas",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco de Tacuichamona",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Zapotitlan",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Guasave",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "Sanalona",
                "countryCode": "MX"
            },
            {
                "name": "Sinaloa",
                "countryCode": "MX"
            },
            {
                "name": "Sinaloa de Leyva",
                "countryCode": "MX"
            },
            {
                "name": "Siqueros",
                "countryCode": "MX"
            },
            {
                "name": "Tamazula",
                "countryCode": "MX"
            },
            {
                "name": "Tanques",
                "countryCode": "MX"
            },
            {
                "name": "Taxtes",
                "countryCode": "MX"
            },
            {
                "name": "Teacapan",
                "countryCode": "MX"
            },
            {
                "name": "Tecualilla",
                "countryCode": "MX"
            },
            {
                "name": "Topolobampo",
                "countryCode": "MX"
            },
            {
                "name": "Tres Garantías",
                "countryCode": "MX"
            },
            {
                "name": "Vallejo (Porvenir Vallejo)",
                "countryCode": "MX"
            },
            {
                "name": "Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Unión",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Costa Rica",
                "countryCode": "MX"
            },
            {
                "name": "Agua Buena",
                "countryCode": "MX"
            },
            {
                "name": "Agua Señora",
                "countryCode": "MX"
            },
            {
                "name": "Ahualulco del Sonido Trece",
                "countryCode": "MX"
            },
            {
                "name": "Ahuehueyo Primero Centro",
                "countryCode": "MX"
            },
            {
                "name": "Alaquines",
                "countryCode": "MX"
            },
            {
                "name": "Alberto Carrera Torres",
                "countryCode": "MX"
            },
            {
                "name": "Ampliación la Hincada",
                "countryCode": "MX"
            },
            {
                "name": "Aquismón",
                "countryCode": "MX"
            },
            {
                "name": "Arroyos",
                "countryCode": "MX"
            },
            {
                "name": "Aurelio Manrique",
                "countryCode": "MX"
            },
            {
                "name": "Axtla de Terrazas",
                "countryCode": "MX"
            },
            {
                "name": "Bledos",
                "countryCode": "MX"
            },
            {
                "name": "Bocas",
                "countryCode": "MX"
            },
            {
                "name": "Carranco",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de Jaral",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de Zavala",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Cerro de San Pedro",
                "countryCode": "MX"
            },
            {
                "name": "Chalchocoyo",
                "countryCode": "MX"
            },
            {
                "name": "Charcas",
                "countryCode": "MX"
            },
            {
                "name": "Chiconamel",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Fernández",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Satélite",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Valles",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad del Maíz",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Veinte de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Álvaro Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Corcovado",
                "countryCode": "MX"
            },
            {
                "name": "Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Damian Carmona",
                "countryCode": "MX"
            },
            {
                "name": "Derramaderos",
                "countryCode": "MX"
            },
            {
                "name": "Dulce Grande",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Gogorrón (Ex-Hacienda de Gogorrón)",
                "countryCode": "MX"
            },
            {
                "name": "Ejido San José Xilatzén",
                "countryCode": "MX"
            },
            {
                "name": "Ejido el Saucillo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido la Pitahaya (Santo Domingo)",
                "countryCode": "MX"
            },
            {
                "name": "Ejido los Huastecos",
                "countryCode": "MX"
            },
            {
                "name": "El Barril",
                "countryCode": "MX"
            },
            {
                "name": "El Capulín",
                "countryCode": "MX"
            },
            {
                "name": "El Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "El Fuerte",
                "countryCode": "MX"
            },
            {
                "name": "El Huexco",
                "countryCode": "MX"
            },
            {
                "name": "El Jabalí",
                "countryCode": "MX"
            },
            {
                "name": "El Jaralito",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "El Peñasco",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "El Tepetate",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Enramadas",
                "countryCode": "MX"
            },
            {
                "name": "Enrique Estrada",
                "countryCode": "MX"
            },
            {
                "name": "Entronque de Matehuala (El Huizache)",
                "countryCode": "MX"
            },
            {
                "name": "Escalerillas",
                "countryCode": "MX"
            },
            {
                "name": "Estación Tamuín",
                "countryCode": "MX"
            },
            {
                "name": "Estanzuela",
                "countryCode": "MX"
            },
            {
                "name": "Fracción Milpillas",
                "countryCode": "MX"
            },
            {
                "name": "Granjenal",
                "countryCode": "MX"
            },
            {
                "name": "Guadalcázar",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Hernández",
                "countryCode": "MX"
            },
            {
                "name": "Higinio Olivo",
                "countryCode": "MX"
            },
            {
                "name": "Huichihuayan",
                "countryCode": "MX"
            },
            {
                "name": "Illescas",
                "countryCode": "MX"
            },
            {
                "name": "Iztacapa",
                "countryCode": "MX"
            },
            {
                "name": "Jalpilla",
                "countryCode": "MX"
            },
            {
                "name": "Jesús María",
                "countryCode": "MX"
            },
            {
                "name": "La Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "La Herradura",
                "countryCode": "MX"
            },
            {
                "name": "La Lima",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa de los Conejos",
                "countryCode": "MX"
            },
            {
                "name": "La Pila",
                "countryCode": "MX"
            },
            {
                "name": "La Reforma",
                "countryCode": "MX"
            },
            {
                "name": "La Subida",
                "countryCode": "MX"
            },
            {
                "name": "La Ventilla",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de San Vicente",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Laguna del Mante",
                "countryCode": "MX"
            },
            {
                "name": "Las Armas",
                "countryCode": "MX"
            },
            {
                "name": "Las Palmas",
                "countryCode": "MX"
            },
            {
                "name": "Los Zacatones",
                "countryCode": "MX"
            },
            {
                "name": "Maitinez",
                "countryCode": "MX"
            },
            {
                "name": "Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Martinez",
                "countryCode": "MX"
            },
            {
                "name": "Matehuala",
                "countryCode": "MX"
            },
            {
                "name": "Moctezuma",
                "countryCode": "MX"
            },
            {
                "name": "Monte Obscuro",
                "countryCode": "MX"
            },
            {
                "name": "Negritas",
                "countryCode": "MX"
            },
            {
                "name": "Norias del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Primavera",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Crucitas",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Tampaón",
                "countryCode": "MX"
            },
            {
                "name": "Ocuiltzapoyo",
                "countryCode": "MX"
            },
            {
                "name": "Ojo Caliente",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Ojo de Agua de Solano",
                "countryCode": "MX"
            },
            {
                "name": "Paisanos",
                "countryCode": "MX"
            },
            {
                "name": "Palma Pegada",
                "countryCode": "MX"
            },
            {
                "name": "Palmar Primero",
                "countryCode": "MX"
            },
            {
                "name": "Palo Seco (El Gato)",
                "countryCode": "MX"
            },
            {
                "name": "Palomas",
                "countryCode": "MX"
            },
            {
                "name": "Pardo",
                "countryCode": "MX"
            },
            {
                "name": "Paso Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Paso Bonito",
                "countryCode": "MX"
            },
            {
                "name": "Pastora",
                "countryCode": "MX"
            },
            {
                "name": "Pemucho",
                "countryCode": "MX"
            },
            {
                "name": "Peotillos",
                "countryCode": "MX"
            },
            {
                "name": "Picholco",
                "countryCode": "MX"
            },
            {
                "name": "Ponciano Arriaga",
                "countryCode": "MX"
            },
            {
                "name": "Portezuelo",
                "countryCode": "MX"
            },
            {
                "name": "Pozuelos",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Puerto de Providencia",
                "countryCode": "MX"
            },
            {
                "name": "Pujal-Coy",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Rascón",
                "countryCode": "MX"
            },
            {
                "name": "Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Real de Catorce",
                "countryCode": "MX"
            },
            {
                "name": "Rinconada",
                "countryCode": "MX"
            },
            {
                "name": "Rincón del Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "Rioverde",
                "countryCode": "MX"
            },
            {
                "name": "Rodrigo",
                "countryCode": "MX"
            },
            {
                "name": "Salinas de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Salitral de Carrera",
                "countryCode": "MX"
            },
            {
                "name": "San Ciro de Acosta",
                "countryCode": "MX"
            },
            {
                "name": "San Diego",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cuayalab",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San José del Tapanco",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Potosí",
                "countryCode": "MX"
            },
            {
                "name": "San Martín Chalchicuautla",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás Tolentino",
                "countryCode": "MX"
            },
            {
                "name": "San Vicente Tancuayalab",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Martha",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Río",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Centro",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "MX"
            },
            {
                "name": "Sauz de Calera",
                "countryCode": "MX"
            },
            {
                "name": "Socavón (El Carmen)",
                "countryCode": "MX"
            },
            {
                "name": "Soledad de Graciano Sánchez",
                "countryCode": "MX"
            },
            {
                "name": "Suspiro Picacho",
                "countryCode": "MX"
            },
            {
                "name": "Sánchez",
                "countryCode": "MX"
            },
            {
                "name": "Tamasopo",
                "countryCode": "MX"
            },
            {
                "name": "Tamazunchale",
                "countryCode": "MX"
            },
            {
                "name": "Tambaca",
                "countryCode": "MX"
            },
            {
                "name": "Tamcuime",
                "countryCode": "MX"
            },
            {
                "name": "Tampacán",
                "countryCode": "MX"
            },
            {
                "name": "Tampate",
                "countryCode": "MX"
            },
            {
                "name": "Tampemoche",
                "countryCode": "MX"
            },
            {
                "name": "Tamuín",
                "countryCode": "MX"
            },
            {
                "name": "Tamán",
                "countryCode": "MX"
            },
            {
                "name": "Tamápatz",
                "countryCode": "MX"
            },
            {
                "name": "Tancanhuitz",
                "countryCode": "MX"
            },
            {
                "name": "Tandzumadz",
                "countryCode": "MX"
            },
            {
                "name": "Tanquián de Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Tanute",
                "countryCode": "MX"
            },
            {
                "name": "Tazaquil",
                "countryCode": "MX"
            },
            {
                "name": "Temalacaco",
                "countryCode": "MX"
            },
            {
                "name": "Tenexio",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Tlacuilola",
                "countryCode": "MX"
            },
            {
                "name": "Tlalnepantla",
                "countryCode": "MX"
            },
            {
                "name": "Tocoy",
                "countryCode": "MX"
            },
            {
                "name": "Vanegas",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Ramos",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Xilitla",
                "countryCode": "MX"
            },
            {
                "name": "Yerbabuena",
                "countryCode": "MX"
            },
            {
                "name": "Zamachihue",
                "countryCode": "MX"
            },
            {
                "name": "Zapuyo",
                "countryCode": "MX"
            },
            {
                "name": "Ébano",
                "countryCode": "MX"
            },
            {
                "name": "31 de Octubre",
                "countryCode": "MX"
            },
            {
                "name": "Aconchi",
                "countryCode": "MX"
            },
            {
                "name": "Aduana del Sásabe",
                "countryCode": "MX"
            },
            {
                "name": "Agiabampo Uno",
                "countryCode": "MX"
            },
            {
                "name": "Agua Prieta",
                "countryCode": "MX"
            },
            {
                "name": "Allende",
                "countryCode": "MX"
            },
            {
                "name": "Antonio Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Arivechi",
                "countryCode": "MX"
            },
            {
                "name": "Arizpe",
                "countryCode": "MX"
            },
            {
                "name": "Atil",
                "countryCode": "MX"
            },
            {
                "name": "Bacabachi",
                "countryCode": "MX"
            },
            {
                "name": "Bacadéhuachi",
                "countryCode": "MX"
            },
            {
                "name": "Bacame Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Bacanora",
                "countryCode": "MX"
            },
            {
                "name": "Bacerac",
                "countryCode": "MX"
            },
            {
                "name": "Bacobampo",
                "countryCode": "MX"
            },
            {
                "name": "Bahía de Kino",
                "countryCode": "MX"
            },
            {
                "name": "Bahía de Lobos",
                "countryCode": "MX"
            },
            {
                "name": "Basconcobe",
                "countryCode": "MX"
            },
            {
                "name": "Bavispe",
                "countryCode": "MX"
            },
            {
                "name": "Benjamín Hill",
                "countryCode": "MX"
            },
            {
                "name": "Buaysiacobe",
                "countryCode": "MX"
            },
            {
                "name": "Buayums",
                "countryCode": "MX"
            },
            {
                "name": "Bácum",
                "countryCode": "MX"
            },
            {
                "name": "Campo Carretero",
                "countryCode": "MX"
            },
            {
                "name": "Campo Sesenta",
                "countryCode": "MX"
            },
            {
                "name": "Cananea",
                "countryCode": "MX"
            },
            {
                "name": "Carbo",
                "countryCode": "MX"
            },
            {
                "name": "Centro de Readaptación Social Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Chucarit",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Obregón",
                "countryCode": "MX"
            },
            {
                "name": "Cocorit",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc (Campo Cinco)",
                "countryCode": "MX"
            },
            {
                "name": "Cucurpe",
                "countryCode": "MX"
            },
            {
                "name": "Cumpas",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Lagunitas",
                "countryCode": "MX"
            },
            {
                "name": "El Coyote",
                "countryCode": "MX"
            },
            {
                "name": "El Diamante (La Retranca)",
                "countryCode": "MX"
            },
            {
                "name": "El Paredoncito",
                "countryCode": "MX"
            },
            {
                "name": "El Recodo",
                "countryCode": "MX"
            },
            {
                "name": "El Rodeo",
                "countryCode": "MX"
            },
            {
                "name": "El Sahuaral",
                "countryCode": "MX"
            },
            {
                "name": "El Saneal",
                "countryCode": "MX"
            },
            {
                "name": "El Siviral (Jigica)",
                "countryCode": "MX"
            },
            {
                "name": "El Tobarito",
                "countryCode": "MX"
            },
            {
                "name": "Empalme",
                "countryCode": "MX"
            },
            {
                "name": "Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Esqueda",
                "countryCode": "MX"
            },
            {
                "name": "Estación Corral",
                "countryCode": "MX"
            },
            {
                "name": "Estación Llano",
                "countryCode": "MX"
            },
            {
                "name": "Estación Zamora",
                "countryCode": "MX"
            },
            {
                "name": "Etchojoa",
                "countryCode": "MX"
            },
            {
                "name": "Etchoropo",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Javier Mina",
                "countryCode": "MX"
            },
            {
                "name": "Fronteras",
                "countryCode": "MX"
            },
            {
                "name": "Golfo de Santa Clara",
                "countryCode": "MX"
            },
            {
                "name": "Granados",
                "countryCode": "MX"
            },
            {
                "name": "Guaymitas",
                "countryCode": "MX"
            },
            {
                "name": "Guásimas (De Belem)",
                "countryCode": "MX"
            },
            {
                "name": "Hermosillo",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Caborca",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Guaymas",
                "countryCode": "MX"
            },
            {
                "name": "Huachinera",
                "countryCode": "MX"
            },
            {
                "name": "Huatabampo",
                "countryCode": "MX"
            },
            {
                "name": "Huásabas",
                "countryCode": "MX"
            },
            {
                "name": "Huépac",
                "countryCode": "MX"
            },
            {
                "name": "Imuris",
                "countryCode": "MX"
            },
            {
                "name": "Islita",
                "countryCode": "MX"
            },
            {
                "name": "Jecopaco",
                "countryCode": "MX"
            },
            {
                "name": "Jitonhueca",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos y Pavón",
                "countryCode": "MX"
            },
            {
                "name": "Juraré",
                "countryCode": "MX"
            },
            {
                "name": "La Bocana",
                "countryCode": "MX"
            },
            {
                "name": "La Colorada",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Mesa",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "La Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Las Bocas",
                "countryCode": "MX"
            },
            {
                "name": "Las Mercedes [Agropecuaria]",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Bácum",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Guamúchil",
                "countryCode": "MX"
            },
            {
                "name": "Loma del Refugio",
                "countryCode": "MX"
            },
            {
                "name": "Los Bahuises",
                "countryCode": "MX"
            },
            {
                "name": "Los Hoyos",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena de Kino",
                "countryCode": "MX"
            },
            {
                "name": "Marte R. Gómez (Tobarito)",
                "countryCode": "MX"
            },
            {
                "name": "Masiaca",
                "countryCode": "MX"
            },
            {
                "name": "Mazatán",
                "countryCode": "MX"
            },
            {
                "name": "Mi Patria es Primero",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Alemán (La Doce)",
                "countryCode": "MX"
            },
            {
                "name": "Milpillas",
                "countryCode": "MX"
            },
            {
                "name": "Moctezuma",
                "countryCode": "MX"
            },
            {
                "name": "Molino de Camou",
                "countryCode": "MX"
            },
            {
                "name": "Mora Villalobos",
                "countryCode": "MX"
            },
            {
                "name": "Moroncarit",
                "countryCode": "MX"
            },
            {
                "name": "Naco",
                "countryCode": "MX"
            },
            {
                "name": "Nacozari Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Nacozari de García",
                "countryCode": "MX"
            },
            {
                "name": "Navojoa",
                "countryCode": "MX"
            },
            {
                "name": "Nogales",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Michoacán (Estación Riíto)",
                "countryCode": "MX"
            },
            {
                "name": "Nácori Chico",
                "countryCode": "MX"
            },
            {
                "name": "Onavas",
                "countryCode": "MX"
            },
            {
                "name": "Opodepe",
                "countryCode": "MX"
            },
            {
                "name": "Oquitoa",
                "countryCode": "MX"
            },
            {
                "name": "Ortiz",
                "countryCode": "MX"
            },
            {
                "name": "Paredón Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Pesqueira",
                "countryCode": "MX"
            },
            {
                "name": "Pitiquito",
                "countryCode": "MX"
            },
            {
                "name": "Plutarco Elías Calles (La Y Griega)",
                "countryCode": "MX"
            },
            {
                "name": "Potam",
                "countryCode": "MX"
            },
            {
                "name": "Pozo Dulce",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Progreso (Campo 47)",
                "countryCode": "MX"
            },
            {
                "name": "Providencia",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Yaqui",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Peñasco",
                "countryCode": "MX"
            },
            {
                "name": "Querobabi",
                "countryCode": "MX"
            },
            {
                "name": "Quetchehueca",
                "countryCode": "MX"
            },
            {
                "name": "Quiriego",
                "countryCode": "MX"
            },
            {
                "name": "Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Sahuaral de Otero",
                "countryCode": "MX"
            },
            {
                "name": "Sahuaripa",
                "countryCode": "MX"
            },
            {
                "name": "San Bernardo",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio",
                "countryCode": "MX"
            },
            {
                "name": "San Ignacio Río Muerto",
                "countryCode": "MX"
            },
            {
                "name": "San José de Bacum",
                "countryCode": "MX"
            },
            {
                "name": "San José de Guaymas",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Río Colorado",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel de Horcasitas",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Río Mayo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro de la Cueva",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de Guaymas",
                "countryCode": "MX"
            },
            {
                "name": "Santa María del Buáraje",
                "countryCode": "MX"
            },
            {
                "name": "Sinahuiza",
                "countryCode": "MX"
            },
            {
                "name": "Siviral",
                "countryCode": "MX"
            },
            {
                "name": "Sonoita",
                "countryCode": "MX"
            },
            {
                "name": "Soyopa",
                "countryCode": "MX"
            },
            {
                "name": "Suaqui Grande",
                "countryCode": "MX"
            },
            {
                "name": "Sáric",
                "countryCode": "MX"
            },
            {
                "name": "Tepache",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Tubutama",
                "countryCode": "MX"
            },
            {
                "name": "Ures",
                "countryCode": "MX"
            },
            {
                "name": "Vicam",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Villa Pesqueira",
                "countryCode": "MX"
            },
            {
                "name": "Yavaros",
                "countryCode": "MX"
            },
            {
                "name": "Álamos",
                "countryCode": "MX"
            },
            {
                "name": "Acachapan y Colmena 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Amado Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Anacleto Canabal 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Anacleto Canabal 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Andrés Quintana Roo",
                "countryCode": "MX"
            },
            {
                "name": "Apatzingán",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán (San Fernando)",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Arena 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Arena 6ta. Sección (La Bolsa)",
                "countryCode": "MX"
            },
            {
                "name": "Arena de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Hondo Abejonal",
                "countryCode": "MX"
            },
            {
                "name": "Astapa",
                "countryCode": "MX"
            },
            {
                "name": "Ayapa",
                "countryCode": "MX"
            },
            {
                "name": "Balancán",
                "countryCode": "MX"
            },
            {
                "name": "Banderas (Guatacalca 2da. Sección)",
                "countryCode": "MX"
            },
            {
                "name": "Barrancas y Amate 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Belén",
                "countryCode": "MX"
            },
            {
                "name": "Benito González",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez (La Playita)",
                "countryCode": "MX"
            },
            {
                "name": "Betania",
                "countryCode": "MX"
            },
            {
                "name": "Blasillo 1ra. Sección (Nicolás Bravo)",
                "countryCode": "MX"
            },
            {
                "name": "Boquiapa",
                "countryCode": "MX"
            },
            {
                "name": "Bosque de Saloya",
                "countryCode": "MX"
            },
            {
                "name": "Brisas del Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista (Apasco)",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista (Puxcatán)",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "C-32 (Licenciado Francisco Trujillo Gurría)",
                "countryCode": "MX"
            },
            {
                "name": "C-41 (Licenciado Carlos A. Madrazo)",
                "countryCode": "MX"
            },
            {
                "name": "Caobanal 1ra. Sección (Mezcalapa)",
                "countryCode": "MX"
            },
            {
                "name": "Caparroso",
                "countryCode": "MX"
            },
            {
                "name": "Capitán Felipe Castellanos Díaz (San Pedro)",
                "countryCode": "MX"
            },
            {
                "name": "Ceiba 1ra. Sección (Jahuactal)",
                "countryCode": "MX"
            },
            {
                "name": "Centro",
                "countryCode": "MX"
            },
            {
                "name": "Chablé",
                "countryCode": "MX"
            },
            {
                "name": "Chichicapa",
                "countryCode": "MX"
            },
            {
                "name": "Chichicastle 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Chontalpa",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Pemex",
                "countryCode": "MX"
            },
            {
                "name": "Cocohital",
                "countryCode": "MX"
            },
            {
                "name": "Comalcalco",
                "countryCode": "MX"
            },
            {
                "name": "Constitución",
                "countryCode": "MX"
            },
            {
                "name": "Corriente 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Cucuyulapa Primera Sección",
                "countryCode": "MX"
            },
            {
                "name": "Cumuapa 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Cunduacán",
                "countryCode": "MX"
            },
            {
                "name": "Cupilco",
                "countryCode": "MX"
            },
            {
                "name": "Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Cúlico 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Dos Ceibas",
                "countryCode": "MX"
            },
            {
                "name": "Dos Montes",
                "countryCode": "MX"
            },
            {
                "name": "El Bajío",
                "countryCode": "MX"
            },
            {
                "name": "El Bellote (Miguel de la Madrid)",
                "countryCode": "MX"
            },
            {
                "name": "El Cedro",
                "countryCode": "MX"
            },
            {
                "name": "El Congo",
                "countryCode": "MX"
            },
            {
                "name": "El Dorado",
                "countryCode": "MX"
            },
            {
                "name": "El Escribano",
                "countryCode": "MX"
            },
            {
                "name": "El Recreo",
                "countryCode": "MX"
            },
            {
                "name": "El Tigre",
                "countryCode": "MX"
            },
            {
                "name": "El Tortuguero",
                "countryCode": "MX"
            },
            {
                "name": "El Triunfo",
                "countryCode": "MX"
            },
            {
                "name": "El Tular",
                "countryCode": "MX"
            },
            {
                "name": "Encrucijada 3ra. Sección (Las Calzadas)",
                "countryCode": "MX"
            },
            {
                "name": "Estapilla",
                "countryCode": "MX"
            },
            {
                "name": "Eureka y Belén",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Ocuiltzapotlán Dos",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Rueda",
                "countryCode": "MX"
            },
            {
                "name": "Frontera",
                "countryCode": "MX"
            },
            {
                "name": "Gaviotas Sur (El Cedral)",
                "countryCode": "MX"
            },
            {
                "name": "General Luis Felipe Domínguez Suárez",
                "countryCode": "MX"
            },
            {
                "name": "Gobernador Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Gobernadores",
                "countryCode": "MX"
            },
            {
                "name": "Gregorio Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Guatacalca",
                "countryCode": "MX"
            },
            {
                "name": "Guaytalpa",
                "countryCode": "MX"
            },
            {
                "name": "Habanero 1ra. Sección (Venustiano Carranza)",
                "countryCode": "MX"
            },
            {
                "name": "Hermenegildo Galeana 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Huacapa y Amestoy",
                "countryCode": "MX"
            },
            {
                "name": "Huapacal 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Huapacal 2da. Sección (Punta Brava)",
                "countryCode": "MX"
            },
            {
                "name": "Huapinol",
                "countryCode": "MX"
            },
            {
                "name": "Huimanguillo",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Allende",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Gutiérrez Gómez",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Iquinuapa",
                "countryCode": "MX"
            },
            {
                "name": "Jalapa",
                "countryCode": "MX"
            },
            {
                "name": "Jalapita",
                "countryCode": "MX"
            },
            {
                "name": "Jalpa de Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Jalupa",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Jolochero 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Jonuta",
                "countryCode": "MX"
            },
            {
                "name": "José Colomo",
                "countryCode": "MX"
            },
            {
                "name": "La Ceiba",
                "countryCode": "MX"
            },
            {
                "name": "La Curva",
                "countryCode": "MX"
            },
            {
                "name": "La Escalera",
                "countryCode": "MX"
            },
            {
                "name": "La Estrella",
                "countryCode": "MX"
            },
            {
                "name": "La Lima",
                "countryCode": "MX"
            },
            {
                "name": "La Lucha",
                "countryCode": "MX"
            },
            {
                "name": "La Manga 2da. Sección (El Jobal)",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Península",
                "countryCode": "MX"
            },
            {
                "name": "La Selva",
                "countryCode": "MX"
            },
            {
                "name": "La Sábana",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "La Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Lagartera 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Lomitas",
                "countryCode": "MX"
            },
            {
                "name": "Los Cenotes",
                "countryCode": "MX"
            },
            {
                "name": "Los Sauces",
                "countryCode": "MX"
            },
            {
                "name": "Luis Gil Pérez",
                "countryCode": "MX"
            },
            {
                "name": "Mactún",
                "countryCode": "MX"
            },
            {
                "name": "Macultepec",
                "countryCode": "MX"
            },
            {
                "name": "Macuspana",
                "countryCode": "MX"
            },
            {
                "name": "Mazateupa",
                "countryCode": "MX"
            },
            {
                "name": "Mecatepec",
                "countryCode": "MX"
            },
            {
                "name": "Mecoacán",
                "countryCode": "MX"
            },
            {
                "name": "Mecoacán 2da. Sección (San Lorenzo)",
                "countryCode": "MX"
            },
            {
                "name": "Medellín y Madero Segunda Sección",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo 2da. Sección B (La Natividad)",
                "countryCode": "MX"
            },
            {
                "name": "Monte Grande",
                "countryCode": "MX"
            },
            {
                "name": "Monte Largo 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Morelos Piedra 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Multé",
                "countryCode": "MX"
            },
            {
                "name": "Nacajuca",
                "countryCode": "MX"
            },
            {
                "name": "Netzahualcóyotl",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo 5ta. Sección (Punta Brava)",
                "countryCode": "MX"
            },
            {
                "name": "Norte 1ra. Sección (San Julián)",
                "countryCode": "MX"
            },
            {
                "name": "Nueva División del Bayo (Guatemala)",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Torno Largo",
                "countryCode": "MX"
            },
            {
                "name": "Occidente (San Francisco)",
                "countryCode": "MX"
            },
            {
                "name": "Occidente 4ta. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Ocuapan",
                "countryCode": "MX"
            },
            {
                "name": "Olcuatitán",
                "countryCode": "MX"
            },
            {
                "name": "Oriente 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Oriente 1ra. Sección (Santo Domingo)",
                "countryCode": "MX"
            },
            {
                "name": "Oriente 2da. Sección (Palma Huaca)",
                "countryCode": "MX"
            },
            {
                "name": "Oriente 2da. Sección (San Cayetano)",
                "countryCode": "MX"
            },
            {
                "name": "Oriente 6ta. Sección (Los Mulatos)",
                "countryCode": "MX"
            },
            {
                "name": "Oxiacaque",
                "countryCode": "MX"
            },
            {
                "name": "Oxolotán",
                "countryCode": "MX"
            },
            {
                "name": "Pablo L. Sidar",
                "countryCode": "MX"
            },
            {
                "name": "Palo Mulato",
                "countryCode": "MX"
            },
            {
                "name": "Palomas",
                "countryCode": "MX"
            },
            {
                "name": "Paraiso",
                "countryCode": "MX"
            },
            {
                "name": "Parilla",
                "countryCode": "MX"
            },
            {
                "name": "Parrilla II",
                "countryCode": "MX"
            },
            {
                "name": "Paso de la Mina 2da. Sección (Barrial)",
                "countryCode": "MX"
            },
            {
                "name": "Paso de la Mina 3ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Pechucalco",
                "countryCode": "MX"
            },
            {
                "name": "Pechucalco 2da. Sección (Las Cruces)",
                "countryCode": "MX"
            },
            {
                "name": "Pejelagartero 1ra. Sección (Plataforma)",
                "countryCode": "MX"
            },
            {
                "name": "Playas del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Poblado C-11 José María Morelos y Pavón",
                "countryCode": "MX"
            },
            {
                "name": "Poblado C-21 Licenciado Benito Juárez García",
                "countryCode": "MX"
            },
            {
                "name": "Poblado C-33 20 de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Pomoca",
                "countryCode": "MX"
            },
            {
                "name": "Potrerillo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Nuevo de las Raíces",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Ceiba",
                "countryCode": "MX"
            },
            {
                "name": "Puxcatán",
                "countryCode": "MX"
            },
            {
                "name": "Pénjamo",
                "countryCode": "MX"
            },
            {
                "name": "Quintín Arauz",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Raya Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Reforma",
                "countryCode": "MX"
            },
            {
                "name": "Río Seco 2da. Sección (Santiaguito)",
                "countryCode": "MX"
            },
            {
                "name": "Río Viejo Primera Sección",
                "countryCode": "MX"
            },
            {
                "name": "Río de Teapa",
                "countryCode": "MX"
            },
            {
                "name": "Saloya 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Samarkanda",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Simón",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosalía",
                "countryCode": "MX"
            },
            {
                "name": "Santana 2da. Sección B (La Palma)",
                "countryCode": "MX"
            },
            {
                "name": "Sargento López 2da. Sección (El Chuzo)",
                "countryCode": "MX"
            },
            {
                "name": "Simón Sarlat",
                "countryCode": "MX"
            },
            {
                "name": "Soyataco",
                "countryCode": "MX"
            },
            {
                "name": "Tacotalpa",
                "countryCode": "MX"
            },
            {
                "name": "Tamulte de las Sabanas",
                "countryCode": "MX"
            },
            {
                "name": "Tapijulapa",
                "countryCode": "MX"
            },
            {
                "name": "Tapotzingo",
                "countryCode": "MX"
            },
            {
                "name": "Taxco",
                "countryCode": "MX"
            },
            {
                "name": "Teapa",
                "countryCode": "MX"
            },
            {
                "name": "Tecoluta 2da. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Tecominoacán",
                "countryCode": "MX"
            },
            {
                "name": "Tenosique",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitán",
                "countryCode": "MX"
            },
            {
                "name": "Tequila 1ra. Sección (La Aurora)",
                "countryCode": "MX"
            },
            {
                "name": "Transito Tular",
                "countryCode": "MX"
            },
            {
                "name": "Tres Bocas 1ra. Sección",
                "countryCode": "MX"
            },
            {
                "name": "Tucta",
                "countryCode": "MX"
            },
            {
                "name": "Veinte de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Villa Tecolutilla",
                "countryCode": "MX"
            },
            {
                "name": "Villa Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa la Venta",
                "countryCode": "MX"
            },
            {
                "name": "Villahermosa",
                "countryCode": "MX"
            },
            {
                "name": "Xicoténcatl",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón (Santa Cruz)",
                "countryCode": "MX"
            },
            {
                "name": "Álvaro Obregón 2da. Sección (El Lechugal)",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo",
                "countryCode": "MX"
            },
            {
                "name": "Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Alfredo V. Bonfil",
                "countryCode": "MX"
            },
            {
                "name": "Altamira",
                "countryCode": "MX"
            },
            {
                "name": "Antiguo Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Anáhuac",
                "countryCode": "MX"
            },
            {
                "name": "Barretal",
                "countryCode": "MX"
            },
            {
                "name": "Burgos",
                "countryCode": "MX"
            },
            {
                "name": "Bustamante",
                "countryCode": "MX"
            },
            {
                "name": "CEFERESO Número 3",
                "countryCode": "MX"
            },
            {
                "name": "Camargo",
                "countryCode": "MX"
            },
            {
                "name": "Carboneras",
                "countryCode": "MX"
            },
            {
                "name": "Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Casas",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Camargo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Gustavo Díaz Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Madero",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Mante",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Miguel Alemán",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Río Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Tula",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Comales",
                "countryCode": "MX"
            },
            {
                "name": "Cruillas",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "El Abra",
                "countryCode": "MX"
            },
            {
                "name": "El Barrancón del Tío Blas (El Barrancón)",
                "countryCode": "MX"
            },
            {
                "name": "El Campanario y Oradel",
                "countryCode": "MX"
            },
            {
                "name": "El Control",
                "countryCode": "MX"
            },
            {
                "name": "El Galaneño",
                "countryCode": "MX"
            },
            {
                "name": "El Limón",
                "countryCode": "MX"
            },
            {
                "name": "El Mante",
                "countryCode": "MX"
            },
            {
                "name": "El Realito",
                "countryCode": "MX"
            },
            {
                "name": "Estación Santa Engracia",
                "countryCode": "MX"
            },
            {
                "name": "Esteros",
                "countryCode": "MX"
            },
            {
                "name": "González",
                "countryCode": "MX"
            },
            {
                "name": "Graciano Sánchez",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guemes",
                "countryCode": "MX"
            },
            {
                "name": "Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Guillermo Zúñiga",
                "countryCode": "MX"
            },
            {
                "name": "Gustavo Díaz Ordaz",
                "countryCode": "MX"
            },
            {
                "name": "Güémez",
                "countryCode": "MX"
            },
            {
                "name": "Heroica Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Jaumave",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "La Colonia",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Misión",
                "countryCode": "MX"
            },
            {
                "name": "La Pedrera",
                "countryCode": "MX"
            },
            {
                "name": "La Pesca",
                "countryCode": "MX"
            },
            {
                "name": "Las Higuerillas",
                "countryCode": "MX"
            },
            {
                "name": "Llera",
                "countryCode": "MX"
            },
            {
                "name": "Llera de Canales",
                "countryCode": "MX"
            },
            {
                "name": "Lomas del Real",
                "countryCode": "MX"
            },
            {
                "name": "Los Aztecas",
                "countryCode": "MX"
            },
            {
                "name": "Los Cavazos",
                "countryCode": "MX"
            },
            {
                "name": "Los Guerra",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "López Rayón",
                "countryCode": "MX"
            },
            {
                "name": "Maclovio Herrera",
                "countryCode": "MX"
            },
            {
                "name": "Magdaleno Cedillo",
                "countryCode": "MX"
            },
            {
                "name": "Mainero",
                "countryCode": "MX"
            },
            {
                "name": "Matamoros",
                "countryCode": "MX"
            },
            {
                "name": "Mier",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Alemán",
                "countryCode": "MX"
            },
            {
                "name": "Miquihuana",
                "countryCode": "MX"
            },
            {
                "name": "Miramar",
                "countryCode": "MX"
            },
            {
                "name": "Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Apolonia",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Ciudad Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Nueva Villa de Padilla",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Cereso Regional de Altamira",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Laredo",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Tantoán",
                "countryCode": "MX"
            },
            {
                "name": "Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Padilla",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Quintero",
                "countryCode": "MX"
            },
            {
                "name": "Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Reynosa",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Río Bravo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Rayón",
                "countryCode": "MX"
            },
            {
                "name": "San Carlos",
                "countryCode": "MX"
            },
            {
                "name": "San Fernando",
                "countryCode": "MX"
            },
            {
                "name": "San Germán",
                "countryCode": "MX"
            },
            {
                "name": "San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "Santa Adelaida",
                "countryCode": "MX"
            },
            {
                "name": "Santa Apolonia",
                "countryCode": "MX"
            },
            {
                "name": "Santander Jiménez",
                "countryCode": "MX"
            },
            {
                "name": "Soto la Marina",
                "countryCode": "MX"
            },
            {
                "name": "Tampico",
                "countryCode": "MX"
            },
            {
                "name": "Tampiquito",
                "countryCode": "MX"
            },
            {
                "name": "Tula",
                "countryCode": "MX"
            },
            {
                "name": "Unidos Avanzamos",
                "countryCode": "MX"
            },
            {
                "name": "Ursulo Galván",
                "countryCode": "MX"
            },
            {
                "name": "Valadeces",
                "countryCode": "MX"
            },
            {
                "name": "Valle Hermoso",
                "countryCode": "MX"
            },
            {
                "name": "Vamos Tamaulipas",
                "countryCode": "MX"
            },
            {
                "name": "Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Villa Mainero",
                "countryCode": "MX"
            },
            {
                "name": "Villagrán",
                "countryCode": "MX"
            },
            {
                "name": "Xicoténcatl",
                "countryCode": "MX"
            },
            {
                "name": "Acopinalco del Peñón",
                "countryCode": "MX"
            },
            {
                "name": "Acuamanala",
                "countryCode": "MX"
            },
            {
                "name": "Acuitlapilco",
                "countryCode": "MX"
            },
            {
                "name": "Acxotla del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Acxotla del Río",
                "countryCode": "MX"
            },
            {
                "name": "Altzayanca",
                "countryCode": "MX"
            },
            {
                "name": "Amaxac de Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Apetatitlán Antonio Carbajal",
                "countryCode": "MX"
            },
            {
                "name": "Apizaco",
                "countryCode": "MX"
            },
            {
                "name": "Atexcatzingo",
                "countryCode": "MX"
            },
            {
                "name": "Atlangatepec",
                "countryCode": "MX"
            },
            {
                "name": "Atlzayanca",
                "countryCode": "MX"
            },
            {
                "name": "Ayometitla",
                "countryCode": "MX"
            },
            {
                "name": "Belén Atzitzimititlán",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Calpulalpan",
                "countryCode": "MX"
            },
            {
                "name": "Capula",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad de Nanacamilpa",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Ignacio Allende",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Venustiano Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Chimalpa",
                "countryCode": "MX"
            },
            {
                "name": "Concepción Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Contla",
                "countryCode": "MX"
            },
            {
                "name": "Cuapiaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Cuaxomulco",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen Aztama",
                "countryCode": "MX"
            },
            {
                "name": "El Carmen Xalpatlahuaya",
                "countryCode": "MX"
            },
            {
                "name": "El Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento la Virgen",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Francisco Villa",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Texcalac",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Tlachco",
                "countryCode": "MX"
            },
            {
                "name": "Hualcaltzinco",
                "countryCode": "MX"
            },
            {
                "name": "Huamantla",
                "countryCode": "MX"
            },
            {
                "name": "Hueyotlipan",
                "countryCode": "MX"
            },
            {
                "name": "Huiloapan",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Huitznahuac",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Tepactepec",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "La Aurora",
                "countryCode": "MX"
            },
            {
                "name": "La Candelaria Teotlalpan",
                "countryCode": "MX"
            },
            {
                "name": "La Magdalena Tlaltelulco",
                "countryCode": "MX"
            },
            {
                "name": "La Soledad",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad Chimalpa",
                "countryCode": "MX"
            },
            {
                "name": "La Trinidad Tenexyecac",
                "countryCode": "MX"
            },
            {
                "name": "Lagunilla",
                "countryCode": "MX"
            },
            {
                "name": "Los Pilares",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes Quiahuixtlan",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Mazapa",
                "countryCode": "MX"
            },
            {
                "name": "Mazatecochco",
                "countryCode": "MX"
            },
            {
                "name": "Muñoz",
                "countryCode": "MX"
            },
            {
                "name": "Natívitas",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Panotla",
                "countryCode": "MX"
            },
            {
                "name": "Papalotla",
                "countryCode": "MX"
            },
            {
                "name": "Ranchería de Pocitos",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Ahuashuatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Cuahuixmatlac",
                "countryCode": "MX"
            },
            {
                "name": "San Bartolomé Tenango",
                "countryCode": "MX"
            },
            {
                "name": "San Benito Xaltocan",
                "countryCode": "MX"
            },
            {
                "name": "San Buenaventura Atempan",
                "countryCode": "MX"
            },
            {
                "name": "San Cosme Atlamaxac",
                "countryCode": "MX"
            },
            {
                "name": "San Damián Texoloc",
                "countryCode": "MX"
            },
            {
                "name": "San Esteban Tizatlán",
                "countryCode": "MX"
            },
            {
                "name": "San Felipe Sultepec",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Cuexcontzi",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Temetzontla",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Tlacuilohcan",
                "countryCode": "MX"
            },
            {
                "name": "San Hipólito Chimalpa",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Buen Suceso",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "San Jorge Tezoquipan",
                "countryCode": "MX"
            },
            {
                "name": "San José Atoyatenco",
                "countryCode": "MX"
            },
            {
                "name": "San José Aztatla",
                "countryCode": "MX"
            },
            {
                "name": "San José Cuamantzingo",
                "countryCode": "MX"
            },
            {
                "name": "San José Teacalco",
                "countryCode": "MX"
            },
            {
                "name": "San José Tepeyahualco",
                "countryCode": "MX"
            },
            {
                "name": "San José Tetel",
                "countryCode": "MX"
            },
            {
                "name": "San José Villarreal",
                "countryCode": "MX"
            },
            {
                "name": "San José Xicohténcatl",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Quetzalcoapan",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Axocomanitla",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Sóltepec",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Xaltelulco",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Tecopilco",
                "countryCode": "MX"
            },
            {
                "name": "San Lucas Tlacochcalco",
                "countryCode": "MX"
            },
            {
                "name": "San Luis Apizaquito",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Contla",
                "countryCode": "MX"
            },
            {
                "name": "San Matías Tepetomatitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Contla",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tlamahuco",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Xochitecatitla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Ecatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Muñoztla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlacotepec",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Xalcaltzinco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Xochiteotla",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Tenanyecac",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Tepatlaxco",
                "countryCode": "MX"
            },
            {
                "name": "San Simeón Xipetzingo",
                "countryCode": "MX"
            },
            {
                "name": "San Simón Tlatlahuquitepec",
                "countryCode": "MX"
            },
            {
                "name": "Sanctórum",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana Chiautempan",
                "countryCode": "MX"
            },
            {
                "name": "Santa Anita Huiloac",
                "countryCode": "MX"
            },
            {
                "name": "Santa Apolonia Teacalco",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catarina Ayometla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Aquiahuac",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Pocitos",
                "countryCode": "MX"
            },
            {
                "name": "Santa Cruz Tetela",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel Xiloxoxtla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Justina Ecatepec",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Atlihuetzian",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Ixtulco",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Texcalac",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Michac",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tepeticpac",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tlacochcalco",
                "countryCode": "MX"
            },
            {
                "name": "Santo Tomás la Concordia",
                "countryCode": "MX"
            },
            {
                "name": "Tenancingo",
                "countryCode": "MX"
            },
            {
                "name": "Teolocholco",
                "countryCode": "MX"
            },
            {
                "name": "Tepetitla",
                "countryCode": "MX"
            },
            {
                "name": "Tepeyanco",
                "countryCode": "MX"
            },
            {
                "name": "Terrenate",
                "countryCode": "MX"
            },
            {
                "name": "Tetla",
                "countryCode": "MX"
            },
            {
                "name": "Tetlanohcán",
                "countryCode": "MX"
            },
            {
                "name": "Tlatempan",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxcala",
                "countryCode": "MX"
            },
            {
                "name": "Tlaxco",
                "countryCode": "MX"
            },
            {
                "name": "Tocatlán",
                "countryCode": "MX"
            },
            {
                "name": "Toluca de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Topilco de Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Totolac",
                "countryCode": "MX"
            },
            {
                "name": "Tzompantepec",
                "countryCode": "MX"
            },
            {
                "name": "Unión Ejidal Tierra y Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Villa Alta",
                "countryCode": "MX"
            },
            {
                "name": "Villa Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Villa de El Carmen Tequexquitla",
                "countryCode": "MX"
            },
            {
                "name": "Xaltocan",
                "countryCode": "MX"
            },
            {
                "name": "Xicohtzinco",
                "countryCode": "MX"
            },
            {
                "name": "Xocoyucan",
                "countryCode": "MX"
            },
            {
                "name": "Yauhquemehcan",
                "countryCode": "MX"
            },
            {
                "name": "Zacatelco",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Zimatepec",
                "countryCode": "MX"
            },
            {
                "name": "Zitlaltépec",
                "countryCode": "MX"
            },
            {
                "name": "Zumpango",
                "countryCode": "MX"
            },
            {
                "name": "Abasolo del Valle",
                "countryCode": "MX"
            },
            {
                "name": "Abrevadero",
                "countryCode": "MX"
            },
            {
                "name": "Acajete",
                "countryCode": "MX"
            },
            {
                "name": "Acatla",
                "countryCode": "MX"
            },
            {
                "name": "Acatlán",
                "countryCode": "MX"
            },
            {
                "name": "Acayucan",
                "countryCode": "MX"
            },
            {
                "name": "Acazónica",
                "countryCode": "MX"
            },
            {
                "name": "Achotal de Moreno",
                "countryCode": "MX"
            },
            {
                "name": "Acontitla",
                "countryCode": "MX"
            },
            {
                "name": "Actopan",
                "countryCode": "MX"
            },
            {
                "name": "Acula",
                "countryCode": "MX"
            },
            {
                "name": "Acultzingo",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo Moreno",
                "countryCode": "MX"
            },
            {
                "name": "Adolfo Ruíz Cortines",
                "countryCode": "MX"
            },
            {
                "name": "Agrícola Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Agua Dulce",
                "countryCode": "MX"
            },
            {
                "name": "Aguilera",
                "countryCode": "MX"
            },
            {
                "name": "Ahuacatán",
                "countryCode": "MX"
            },
            {
                "name": "Ahuateno",
                "countryCode": "MX"
            },
            {
                "name": "Ahueyahualco",
                "countryCode": "MX"
            },
            {
                "name": "Alborada",
                "countryCode": "MX"
            },
            {
                "name": "Allende",
                "countryCode": "MX"
            },
            {
                "name": "Almagres",
                "countryCode": "MX"
            },
            {
                "name": "Alpatláhuac",
                "countryCode": "MX"
            },
            {
                "name": "Alto Lucero",
                "countryCode": "MX"
            },
            {
                "name": "Altotonga",
                "countryCode": "MX"
            },
            {
                "name": "Alvarado",
                "countryCode": "MX"
            },
            {
                "name": "Amatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "Amatlán de los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Anahuac",
                "countryCode": "MX"
            },
            {
                "name": "Angel R. Cabada",
                "countryCode": "MX"
            },
            {
                "name": "Antonio J Bermúdez",
                "countryCode": "MX"
            },
            {
                "name": "Antón Lizardo",
                "countryCode": "MX"
            },
            {
                "name": "Apazapan",
                "countryCode": "MX"
            },
            {
                "name": "Aquila",
                "countryCode": "MX"
            },
            {
                "name": "Aquiles Serdán",
                "countryCode": "MX"
            },
            {
                "name": "Arboledas",
                "countryCode": "MX"
            },
            {
                "name": "Arbolillo",
                "countryCode": "MX"
            },
            {
                "name": "Arenal Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo Hondo",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo del Maíz Uno",
                "countryCode": "MX"
            },
            {
                "name": "Arroyo del Potrero",
                "countryCode": "MX"
            },
            {
                "name": "Astacinga",
                "countryCode": "MX"
            },
            {
                "name": "Atlahuilco",
                "countryCode": "MX"
            },
            {
                "name": "Atoyac",
                "countryCode": "MX"
            },
            {
                "name": "Atzacan",
                "countryCode": "MX"
            },
            {
                "name": "Atzalan",
                "countryCode": "MX"
            },
            {
                "name": "Axochío",
                "countryCode": "MX"
            },
            {
                "name": "Ayahualulco",
                "countryCode": "MX"
            },
            {
                "name": "Ayotuxtla",
                "countryCode": "MX"
            },
            {
                "name": "Banderas",
                "countryCode": "MX"
            },
            {
                "name": "Banderilla",
                "countryCode": "MX"
            },
            {
                "name": "Bara de Chachalacas",
                "countryCode": "MX"
            },
            {
                "name": "Barra de Cazones",
                "countryCode": "MX"
            },
            {
                "name": "Barrancas",
                "countryCode": "MX"
            },
            {
                "name": "Barrio de San Miguel",
                "countryCode": "MX"
            },
            {
                "name": "Bella Esperanza",
                "countryCode": "MX"
            },
            {
                "name": "Benito Juárez",
                "countryCode": "MX"
            },
            {
                "name": "Blanca Espuma",
                "countryCode": "MX"
            },
            {
                "name": "Boca del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Boca del Rio",
                "countryCode": "MX"
            },
            {
                "name": "Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "MX"
            },
            {
                "name": "Buenos Aires (San Isidro)",
                "countryCode": "MX"
            },
            {
                "name": "Cabezas",
                "countryCode": "MX"
            },
            {
                "name": "Cacahuatal",
                "countryCode": "MX"
            },
            {
                "name": "Calcahualco",
                "countryCode": "MX"
            },
            {
                "name": "Calería",
                "countryCode": "MX"
            },
            {
                "name": "Calichar Palma Sola",
                "countryCode": "MX"
            },
            {
                "name": "Calzadas",
                "countryCode": "MX"
            },
            {
                "name": "Camarón de Tejeda",
                "countryCode": "MX"
            },
            {
                "name": "Camerino Z. Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Campo Chico",
                "countryCode": "MX"
            },
            {
                "name": "Campo Grande",
                "countryCode": "MX"
            },
            {
                "name": "Campo de Águila",
                "countryCode": "MX"
            },
            {
                "name": "Capoluca",
                "countryCode": "MX"
            },
            {
                "name": "Caristay",
                "countryCode": "MX"
            },
            {
                "name": "Carlos A. Carrillo",
                "countryCode": "MX"
            },
            {
                "name": "Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Carrizal",
                "countryCode": "MX"
            },
            {
                "name": "Casitas",
                "countryCode": "MX"
            },
            {
                "name": "Castillo de Teayo",
                "countryCode": "MX"
            },
            {
                "name": "Catemaco",
                "countryCode": "MX"
            },
            {
                "name": "Caxapa",
                "countryCode": "MX"
            },
            {
                "name": "Cazones de Herrera",
                "countryCode": "MX"
            },
            {
                "name": "Cecilio Terán",
                "countryCode": "MX"
            },
            {
                "name": "Cementeras del Pital",
                "countryCode": "MX"
            },
            {
                "name": "Centro de Readaptación Social",
                "countryCode": "MX"
            },
            {
                "name": "Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Azul",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Gordo",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Grande",
                "countryCode": "MX"
            },
            {
                "name": "Cerro Guzmán",
                "countryCode": "MX"
            },
            {
                "name": "Cerro las Iguanas",
                "countryCode": "MX"
            },
            {
                "name": "Chacalapa",
                "countryCode": "MX"
            },
            {
                "name": "Chacaltianguis",
                "countryCode": "MX"
            },
            {
                "name": "Chalma",
                "countryCode": "MX"
            },
            {
                "name": "Chapopote Chico",
                "countryCode": "MX"
            },
            {
                "name": "Chapopote Núñez",
                "countryCode": "MX"
            },
            {
                "name": "Chavarrillo",
                "countryCode": "MX"
            },
            {
                "name": "Chavaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Chichicaxtle",
                "countryCode": "MX"
            },
            {
                "name": "Chicola",
                "countryCode": "MX"
            },
            {
                "name": "Chiconamel",
                "countryCode": "MX"
            },
            {
                "name": "Chiconquiaco",
                "countryCode": "MX"
            },
            {
                "name": "Chicontepec",
                "countryCode": "MX"
            },
            {
                "name": "Chicualoque",
                "countryCode": "MX"
            },
            {
                "name": "Chicuasen",
                "countryCode": "MX"
            },
            {
                "name": "Chilapa",
                "countryCode": "MX"
            },
            {
                "name": "Chiltoyac",
                "countryCode": "MX"
            },
            {
                "name": "Chinameca",
                "countryCode": "MX"
            },
            {
                "name": "Chinampa de Gorostiza",
                "countryCode": "MX"
            },
            {
                "name": "Chocamán",
                "countryCode": "MX"
            },
            {
                "name": "Chonegal",
                "countryCode": "MX"
            },
            {
                "name": "Chontla",
                "countryCode": "MX"
            },
            {
                "name": "Chumatlán",
                "countryCode": "MX"
            },
            {
                "name": "Chuniapan de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Citlaltépec",
                "countryCode": "MX"
            },
            {
                "name": "Citlaltépetl",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Ciudad Mendoza",
                "countryCode": "MX"
            },
            {
                "name": "Coacoatzintla",
                "countryCode": "MX"
            },
            {
                "name": "Coacotla",
                "countryCode": "MX"
            },
            {
                "name": "Coahuitlán",
                "countryCode": "MX"
            },
            {
                "name": "Coatepec",
                "countryCode": "MX"
            },
            {
                "name": "Coatzacoalcos",
                "countryCode": "MX"
            },
            {
                "name": "Coatzintla",
                "countryCode": "MX"
            },
            {
                "name": "Coetzala",
                "countryCode": "MX"
            },
            {
                "name": "Colatlán",
                "countryCode": "MX"
            },
            {
                "name": "Colipa",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Adolfo Ruiz Cortines (Colonia Obrera)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Chalchihuecan",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Lealtad",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Manuel González",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Santa Bárbara",
                "countryCode": "MX"
            },
            {
                "name": "Colonia el Renacimiento",
                "countryCode": "MX"
            },
            {
                "name": "Colonia las Flores",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Úrsulo Galván",
                "countryCode": "MX"
            },
            {
                "name": "Colonias Pedernales",
                "countryCode": "MX"
            },
            {
                "name": "Colorines",
                "countryCode": "MX"
            },
            {
                "name": "Comapa",
                "countryCode": "MX"
            },
            {
                "name": "Comején",
                "countryCode": "MX"
            },
            {
                "name": "Comoapan",
                "countryCode": "MX"
            },
            {
                "name": "Congregación Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Congregación el Tajín",
                "countryCode": "MX"
            },
            {
                "name": "Corozal",
                "countryCode": "MX"
            },
            {
                "name": "Corral Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Corralillos",
                "countryCode": "MX"
            },
            {
                "name": "Cosamaloapan",
                "countryCode": "MX"
            },
            {
                "name": "Cosamaloapan de Carpio",
                "countryCode": "MX"
            },
            {
                "name": "Cosautlán",
                "countryCode": "MX"
            },
            {
                "name": "Cosautlán de Carvajal",
                "countryCode": "MX"
            },
            {
                "name": "Coscomatepec de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Cosolapa",
                "countryCode": "MX"
            },
            {
                "name": "Cosoleacaque",
                "countryCode": "MX"
            },
            {
                "name": "Cotaxtla",
                "countryCode": "MX"
            },
            {
                "name": "Coxquihui",
                "countryCode": "MX"
            },
            {
                "name": "Coyolito",
                "countryCode": "MX"
            },
            {
                "name": "Coyutla",
                "countryCode": "MX"
            },
            {
                "name": "Cruz Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Cruz Verde",
                "countryCode": "MX"
            },
            {
                "name": "Cruz del Milagro",
                "countryCode": "MX"
            },
            {
                "name": "Cuauhtamingo",
                "countryCode": "MX"
            },
            {
                "name": "Cuautlapán",
                "countryCode": "MX"
            },
            {
                "name": "Cucharas",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta Amarilla",
                "countryCode": "MX"
            },
            {
                "name": "Cuesta de Laja",
                "countryCode": "MX"
            },
            {
                "name": "Cuichapa",
                "countryCode": "MX"
            },
            {
                "name": "Cuitláhuac",
                "countryCode": "MX"
            },
            {
                "name": "Cuiyachapa",
                "countryCode": "MX"
            },
            {
                "name": "Córdoba",
                "countryCode": "MX"
            },
            {
                "name": "Córdoba (Santa Leticia)",
                "countryCode": "MX"
            },
            {
                "name": "Dehesa",
                "countryCode": "MX"
            },
            {
                "name": "Delfino Victoria (Santa Fe)",
                "countryCode": "MX"
            },
            {
                "name": "Doctor Montes de Oca",
                "countryCode": "MX"
            },
            {
                "name": "Dos Bocas",
                "countryCode": "MX"
            },
            {
                "name": "Dos Ríos",
                "countryCode": "MX"
            },
            {
                "name": "Ejidal",
                "countryCode": "MX"
            },
            {
                "name": "Ejido la Piña (Maromilla)",
                "countryCode": "MX"
            },
            {
                "name": "El Aguacate",
                "countryCode": "MX"
            },
            {
                "name": "El Anono",
                "countryCode": "MX"
            },
            {
                "name": "El Arenal",
                "countryCode": "MX"
            },
            {
                "name": "El Blanco",
                "countryCode": "MX"
            },
            {
                "name": "El Castillo",
                "countryCode": "MX"
            },
            {
                "name": "El Chico",
                "countryCode": "MX"
            },
            {
                "name": "El Chote",
                "countryCode": "MX"
            },
            {
                "name": "El Cocuite",
                "countryCode": "MX"
            },
            {
                "name": "El Conejo",
                "countryCode": "MX"
            },
            {
                "name": "El Corte",
                "countryCode": "MX"
            },
            {
                "name": "El Escobillo",
                "countryCode": "MX"
            },
            {
                "name": "El Espinal",
                "countryCode": "MX"
            },
            {
                "name": "El Hatito",
                "countryCode": "MX"
            },
            {
                "name": "El Higo",
                "countryCode": "MX"
            },
            {
                "name": "El Huidero",
                "countryCode": "MX"
            },
            {
                "name": "El Humo",
                "countryCode": "MX"
            },
            {
                "name": "El Huérfano",
                "countryCode": "MX"
            },
            {
                "name": "El Jobo",
                "countryCode": "MX"
            },
            {
                "name": "El Juile",
                "countryCode": "MX"
            },
            {
                "name": "El Laurel",
                "countryCode": "MX"
            },
            {
                "name": "El Lencero",
                "countryCode": "MX"
            },
            {
                "name": "El Lindero",
                "countryCode": "MX"
            },
            {
                "name": "El Maguey",
                "countryCode": "MX"
            },
            {
                "name": "El Mirador",
                "countryCode": "MX"
            },
            {
                "name": "El Molino",
                "countryCode": "MX"
            },
            {
                "name": "El Mollejon",
                "countryCode": "MX"
            },
            {
                "name": "El Naranjito",
                "countryCode": "MX"
            },
            {
                "name": "El Nigromante",
                "countryCode": "MX"
            },
            {
                "name": "El Palmar",
                "countryCode": "MX"
            },
            {
                "name": "El Panorama",
                "countryCode": "MX"
            },
            {
                "name": "El Paraíso",
                "countryCode": "MX"
            },
            {
                "name": "El Paraíso (La Charca)",
                "countryCode": "MX"
            },
            {
                "name": "El Pital",
                "countryCode": "MX"
            },
            {
                "name": "El Porvenir",
                "countryCode": "MX"
            },
            {
                "name": "El Potrero",
                "countryCode": "MX"
            },
            {
                "name": "El Progreso",
                "countryCode": "MX"
            },
            {
                "name": "El Pueblito",
                "countryCode": "MX"
            },
            {
                "name": "El Pueblito (Garbanzal)",
                "countryCode": "MX"
            },
            {
                "name": "El Remolino",
                "countryCode": "MX"
            },
            {
                "name": "El Salto de Eyipantla",
                "countryCode": "MX"
            },
            {
                "name": "El Tejar",
                "countryCode": "MX"
            },
            {
                "name": "El Terrero",
                "countryCode": "MX"
            },
            {
                "name": "El Triunfo",
                "countryCode": "MX"
            },
            {
                "name": "El Tulín",
                "countryCode": "MX"
            },
            {
                "name": "El Volador",
                "countryCode": "MX"
            },
            {
                "name": "El Zapote Bravo",
                "countryCode": "MX"
            },
            {
                "name": "El Águila",
                "countryCode": "MX"
            },
            {
                "name": "Elotepec",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Entabladero",
                "countryCode": "MX"
            },
            {
                "name": "Escolín de Olarte",
                "countryCode": "MX"
            },
            {
                "name": "Espinal",
                "countryCode": "MX"
            },
            {
                "name": "Estación Dobladero",
                "countryCode": "MX"
            },
            {
                "name": "Estación Juanita",
                "countryCode": "MX"
            },
            {
                "name": "Estanzuela",
                "countryCode": "MX"
            },
            {
                "name": "Estero de Milpas",
                "countryCode": "MX"
            },
            {
                "name": "Estero del Ídolo",
                "countryCode": "MX"
            },
            {
                "name": "Ex-hacienda la Concepción",
                "countryCode": "MX"
            },
            {
                "name": "Excola",
                "countryCode": "MX"
            },
            {
                "name": "Felipe Carrillo Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Filomeno Mata",
                "countryCode": "MX"
            },
            {
                "name": "Fortín de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Arboledas San Ramón",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Ciudad Olmeca",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Costa Dorada",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Geovillas los Pinos",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Santa Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Valle Dorado",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento Villas de la Llave",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento la Florida",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento las Fuentes",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento los Prados",
                "countryCode": "MX"
            },
            {
                "name": "Fraccionamiento los Álamos",
                "countryCode": "MX"
            },
            {
                "name": "Francisco I. Madero",
                "countryCode": "MX"
            },
            {
                "name": "Fredepo",
                "countryCode": "MX"
            },
            {
                "name": "Gabino Barreda",
                "countryCode": "MX"
            },
            {
                "name": "General Alatriste (San Joaquín)",
                "countryCode": "MX"
            },
            {
                "name": "General Juan José Baz (San José del Corral)",
                "countryCode": "MX"
            },
            {
                "name": "General Miguel Alemán",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Guayalejo",
                "countryCode": "MX"
            },
            {
                "name": "Guillermo Prieto",
                "countryCode": "MX"
            },
            {
                "name": "Gutiérrez Zamora",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Sotavento",
                "countryCode": "MX"
            },
            {
                "name": "Helio García Alfaro",
                "countryCode": "MX"
            },
            {
                "name": "Hermanos Cedillo",
                "countryCode": "MX"
            },
            {
                "name": "Hermenegildo J. Aldana",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo Amajac",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Hornitos",
                "countryCode": "MX"
            },
            {
                "name": "Huatusco",
                "countryCode": "MX"
            },
            {
                "name": "Huayacanes",
                "countryCode": "MX"
            },
            {
                "name": "Huayacocotla",
                "countryCode": "MX"
            },
            {
                "name": "Huazuntlán",
                "countryCode": "MX"
            },
            {
                "name": "Huexotitla",
                "countryCode": "MX"
            },
            {
                "name": "Hueyapan de Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Hueytepec",
                "countryCode": "MX"
            },
            {
                "name": "Huiloapan",
                "countryCode": "MX"
            },
            {
                "name": "Huiloapan de Cuauhtémoc",
                "countryCode": "MX"
            },
            {
                "name": "Huitzila",
                "countryCode": "MX"
            },
            {
                "name": "Huixcolotla",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio de la Llave",
                "countryCode": "MX"
            },
            {
                "name": "Ilamatlán",
                "countryCode": "MX"
            },
            {
                "name": "Isla",
                "countryCode": "MX"
            },
            {
                "name": "Ixcapantla",
                "countryCode": "MX"
            },
            {
                "name": "Ixcatepec",
                "countryCode": "MX"
            },
            {
                "name": "Ixcatla",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuacán de los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuapan",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuatlancillo",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuatlán de Madero",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuatlán del Café",
                "countryCode": "MX"
            },
            {
                "name": "Ixhuatlán del Sureste",
                "countryCode": "MX"
            },
            {
                "name": "Ixpila",
                "countryCode": "MX"
            },
            {
                "name": "Ixtacapa el Chico",
                "countryCode": "MX"
            },
            {
                "name": "Ixtaczoquitlán",
                "countryCode": "MX"
            },
            {
                "name": "Jacarandas",
                "countryCode": "MX"
            },
            {
                "name": "Jalacingo",
                "countryCode": "MX"
            },
            {
                "name": "Jalapilla",
                "countryCode": "MX"
            },
            {
                "name": "Jalcomulco",
                "countryCode": "MX"
            },
            {
                "name": "Jamapa",
                "countryCode": "MX"
            },
            {
                "name": "Jesús Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Jilotepec",
                "countryCode": "MX"
            },
            {
                "name": "Joachín",
                "countryCode": "MX"
            },
            {
                "name": "José Cardel",
                "countryCode": "MX"
            },
            {
                "name": "José F. Gutiérrez",
                "countryCode": "MX"
            },
            {
                "name": "Juan Díaz Covarrubias",
                "countryCode": "MX"
            },
            {
                "name": "Juan Jacobo Torres [Bodega de Totontepec]",
                "countryCode": "MX"
            },
            {
                "name": "Juan Marcos (San José Buenavista)",
                "countryCode": "MX"
            },
            {
                "name": "Juan Rodríguez Clara",
                "countryCode": "MX"
            },
            {
                "name": "Juchique de Ferrer",
                "countryCode": "MX"
            },
            {
                "name": "Jáltipan de Morelos",
                "countryCode": "MX"
            },
            {
                "name": "La Antigua",
                "countryCode": "MX"
            },
            {
                "name": "La Camelia",
                "countryCode": "MX"
            },
            {
                "name": "La Capilla",
                "countryCode": "MX"
            },
            {
                "name": "La Cerquilla",
                "countryCode": "MX"
            },
            {
                "name": "La Colonia Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Cuesta",
                "countryCode": "MX"
            },
            {
                "name": "La Defensa",
                "countryCode": "MX"
            },
            {
                "name": "La Gloria",
                "countryCode": "MX"
            },
            {
                "name": "La Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "La Guásima",
                "countryCode": "MX"
            },
            {
                "name": "La Horqueta (Poblado Doce)",
                "countryCode": "MX"
            },
            {
                "name": "La Isla",
                "countryCode": "MX"
            },
            {
                "name": "La Isla (Kilómetro 10)",
                "countryCode": "MX"
            },
            {
                "name": "La Isla Km 10",
                "countryCode": "MX"
            },
            {
                "name": "La Isla de Chapachapa",
                "countryCode": "MX"
            },
            {
                "name": "La Joya",
                "countryCode": "MX"
            },
            {
                "name": "La Junta",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna y Monte del Castillo",
                "countryCode": "MX"
            },
            {
                "name": "La Libertad",
                "countryCode": "MX"
            },
            {
                "name": "La Nueva Era",
                "countryCode": "MX"
            },
            {
                "name": "La Nueva Victoria",
                "countryCode": "MX"
            },
            {
                "name": "La Orduña",
                "countryCode": "MX"
            },
            {
                "name": "La Palma",
                "countryCode": "MX"
            },
            {
                "name": "La Palmilla",
                "countryCode": "MX"
            },
            {
                "name": "La Perla",
                "countryCode": "MX"
            },
            {
                "name": "La Providencia",
                "countryCode": "MX"
            },
            {
                "name": "La Reforma",
                "countryCode": "MX"
            },
            {
                "name": "La Sidra",
                "countryCode": "MX"
            },
            {
                "name": "La Sombra",
                "countryCode": "MX"
            },
            {
                "name": "La Tinaja",
                "countryCode": "MX"
            },
            {
                "name": "La Toma",
                "countryCode": "MX"
            },
            {
                "name": "La Unión",
                "countryCode": "MX"
            },
            {
                "name": "La Unión Paso Largo",
                "countryCode": "MX"
            },
            {
                "name": "La Victoria",
                "countryCode": "MX"
            },
            {
                "name": "La Victoria (La Peñita)",
                "countryCode": "MX"
            },
            {
                "name": "Laguna Chica (Pueblo Nuevo)",
                "countryCode": "MX"
            },
            {
                "name": "Laguna de Farfán",
                "countryCode": "MX"
            },
            {
                "name": "Laguneta",
                "countryCode": "MX"
            },
            {
                "name": "Landero y Coss",
                "countryCode": "MX"
            },
            {
                "name": "Las Amapolas",
                "countryCode": "MX"
            },
            {
                "name": "Las Barrillas",
                "countryCode": "MX"
            },
            {
                "name": "Las Choapas",
                "countryCode": "MX"
            },
            {
                "name": "Las Higueras",
                "countryCode": "MX"
            },
            {
                "name": "Las Lomas",
                "countryCode": "MX"
            },
            {
                "name": "Las Lomas de Tacamichapan",
                "countryCode": "MX"
            },
            {
                "name": "Las Minas",
                "countryCode": "MX"
            },
            {
                "name": "Las Sabanetas",
                "countryCode": "MX"
            },
            {
                "name": "Las Sabinas",
                "countryCode": "MX"
            },
            {
                "name": "Las Trancas",
                "countryCode": "MX"
            },
            {
                "name": "Las Vigas de Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Lerdo de Tejada",
                "countryCode": "MX"
            },
            {
                "name": "Limones",
                "countryCode": "MX"
            },
            {
                "name": "Lindavista",
                "countryCode": "MX"
            },
            {
                "name": "Llano Grande",
                "countryCode": "MX"
            },
            {
                "name": "Llano de Enmedio",
                "countryCode": "MX"
            },
            {
                "name": "Loma Angosta",
                "countryCode": "MX"
            },
            {
                "name": "Loma Grande",
                "countryCode": "MX"
            },
            {
                "name": "Loma de Sogotegoyo",
                "countryCode": "MX"
            },
            {
                "name": "Loma de los Carmona",
                "countryCode": "MX"
            },
            {
                "name": "Lomas Verdes",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Barrillas",
                "countryCode": "MX"
            },
            {
                "name": "Lomas de Río Medio Cuatro",
                "countryCode": "MX"
            },
            {
                "name": "Los Altos",
                "countryCode": "MX"
            },
            {
                "name": "Los Cerritos",
                "countryCode": "MX"
            },
            {
                "name": "Los Lirios",
                "countryCode": "MX"
            },
            {
                "name": "Los Mangos",
                "countryCode": "MX"
            },
            {
                "name": "Los Molinos",
                "countryCode": "MX"
            },
            {
                "name": "Los Naranjos",
                "countryCode": "MX"
            },
            {
                "name": "Los Pescados",
                "countryCode": "MX"
            },
            {
                "name": "Los Pinos",
                "countryCode": "MX"
            },
            {
                "name": "Los Reyes",
                "countryCode": "MX"
            },
            {
                "name": "Los Robles",
                "countryCode": "MX"
            },
            {
                "name": "Los Tigres (San Marcos)",
                "countryCode": "MX"
            },
            {
                "name": "Los Torrentes",
                "countryCode": "MX"
            },
            {
                "name": "Los Ídolos",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas (Santana)",
                "countryCode": "MX"
            },
            {
                "name": "Macedonio Alonso",
                "countryCode": "MX"
            },
            {
                "name": "Mafafas",
                "countryCode": "MX"
            },
            {
                "name": "Magdalena",
                "countryCode": "MX"
            },
            {
                "name": "Mahuixtlan",
                "countryCode": "MX"
            },
            {
                "name": "Maltrata",
                "countryCode": "MX"
            },
            {
                "name": "Manuel León",
                "countryCode": "MX"
            },
            {
                "name": "Manuel María Contreras",
                "countryCode": "MX"
            },
            {
                "name": "Mapachapa",
                "countryCode": "MX"
            },
            {
                "name": "Mariano Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Martínez de la Torre",
                "countryCode": "MX"
            },
            {
                "name": "María de la Torre",
                "countryCode": "MX"
            },
            {
                "name": "Mata Loma",
                "countryCode": "MX"
            },
            {
                "name": "Mata Naranjo",
                "countryCode": "MX"
            },
            {
                "name": "Mata Obscura",
                "countryCode": "MX"
            },
            {
                "name": "Mata Tenatito (Casco Hacienda)",
                "countryCode": "MX"
            },
            {
                "name": "Mata de Indio",
                "countryCode": "MX"
            },
            {
                "name": "Matacapan",
                "countryCode": "MX"
            },
            {
                "name": "Mazumiapam",
                "countryCode": "MX"
            },
            {
                "name": "Mecatlán",
                "countryCode": "MX"
            },
            {
                "name": "Mecayapan",
                "countryCode": "MX"
            },
            {
                "name": "Medellín",
                "countryCode": "MX"
            },
            {
                "name": "Medellín de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Medias Aguas",
                "countryCode": "MX"
            },
            {
                "name": "Melchor Ocampo",
                "countryCode": "MX"
            },
            {
                "name": "Mequetla",
                "countryCode": "MX"
            },
            {
                "name": "Mesa de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Metlac Hernández (Metlac Primero)",
                "countryCode": "MX"
            },
            {
                "name": "Mexcala",
                "countryCode": "MX"
            },
            {
                "name": "Miahuatlán",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Minatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Minzapan",
                "countryCode": "MX"
            },
            {
                "name": "Misantla",
                "countryCode": "MX"
            },
            {
                "name": "Mixquiapan",
                "countryCode": "MX"
            },
            {
                "name": "Mixtla de Altamirano",
                "countryCode": "MX"
            },
            {
                "name": "Moloacán",
                "countryCode": "MX"
            },
            {
                "name": "Monte Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Monte Salas",
                "countryCode": "MX"
            },
            {
                "name": "Monte Verde Chivería",
                "countryCode": "MX"
            },
            {
                "name": "Moralillo",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Motzorongo",
                "countryCode": "MX"
            },
            {
                "name": "Moyoapan",
                "countryCode": "MX"
            },
            {
                "name": "Mozomboa",
                "countryCode": "MX"
            },
            {
                "name": "Mundo Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Nanchital de Lázaro Cárdenas del Río",
                "countryCode": "MX"
            },
            {
                "name": "Naolinco",
                "countryCode": "MX"
            },
            {
                "name": "Naolinco de Victoria",
                "countryCode": "MX"
            },
            {
                "name": "Naranjal",
                "countryCode": "MX"
            },
            {
                "name": "Naranjos",
                "countryCode": "MX"
            },
            {
                "name": "Naranjos Amatlán",
                "countryCode": "MX"
            },
            {
                "name": "Nautla",
                "countryCode": "MX"
            },
            {
                "name": "Necoxtla",
                "countryCode": "MX"
            },
            {
                "name": "Nicolás Blanco (San Pancho)",
                "countryCode": "MX"
            },
            {
                "name": "Niños Héroes",
                "countryCode": "MX"
            },
            {
                "name": "Nogales",
                "countryCode": "MX"
            },
            {
                "name": "Nopalapan",
                "countryCode": "MX"
            },
            {
                "name": "Nopaltepec",
                "countryCode": "MX"
            },
            {
                "name": "Novara",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Nuevo San José Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Oasis",
                "countryCode": "MX"
            },
            {
                "name": "Ocelota",
                "countryCode": "MX"
            },
            {
                "name": "Ocotal Chico",
                "countryCode": "MX"
            },
            {
                "name": "Ocotitlán",
                "countryCode": "MX"
            },
            {
                "name": "Ocozotepec",
                "countryCode": "MX"
            },
            {
                "name": "Ohuapan",
                "countryCode": "MX"
            },
            {
                "name": "Ohuilapam",
                "countryCode": "MX"
            },
            {
                "name": "Ojite Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Oluta",
                "countryCode": "MX"
            },
            {
                "name": "Omealca",
                "countryCode": "MX"
            },
            {
                "name": "Orilla del Monte",
                "countryCode": "MX"
            },
            {
                "name": "Orizaba",
                "countryCode": "MX"
            },
            {
                "name": "Otates",
                "countryCode": "MX"
            },
            {
                "name": "Otatitlán",
                "countryCode": "MX"
            },
            {
                "name": "Oteapan",
                "countryCode": "MX"
            },
            {
                "name": "Otilpan",
                "countryCode": "MX"
            },
            {
                "name": "Oviedo",
                "countryCode": "MX"
            },
            {
                "name": "Ozuluama de Mascareñas",
                "countryCode": "MX"
            },
            {
                "name": "Pacho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Pacho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Pahua Hueca",
                "countryCode": "MX"
            },
            {
                "name": "Pajapan",
                "countryCode": "MX"
            },
            {
                "name": "Palma Sola",
                "countryCode": "MX"
            },
            {
                "name": "Palmar Grande",
                "countryCode": "MX"
            },
            {
                "name": "Palmas de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Palmillas",
                "countryCode": "MX"
            },
            {
                "name": "Palmira",
                "countryCode": "MX"
            },
            {
                "name": "Palo Bendito",
                "countryCode": "MX"
            },
            {
                "name": "Palo Gacho",
                "countryCode": "MX"
            },
            {
                "name": "Papantla de Olarte",
                "countryCode": "MX"
            },
            {
                "name": "Paraiso Novillero",
                "countryCode": "MX"
            },
            {
                "name": "Paraje Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Paso Nacional",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Ovejas",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Valencia",
                "countryCode": "MX"
            },
            {
                "name": "Paso del Correo",
                "countryCode": "MX"
            },
            {
                "name": "Paso del Macho",
                "countryCode": "MX"
            },
            {
                "name": "Paso del Toro",
                "countryCode": "MX"
            },
            {
                "name": "Perote",
                "countryCode": "MX"
            },
            {
                "name": "Perseverancia",
                "countryCode": "MX"
            },
            {
                "name": "Peñuela",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Parada",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Pinta",
                "countryCode": "MX"
            },
            {
                "name": "Piedras Negras",
                "countryCode": "MX"
            },
            {
                "name": "Piletas",
                "countryCode": "MX"
            },
            {
                "name": "Pisaflores",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Arroyos",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Ayala",
                "countryCode": "MX"
            },
            {
                "name": "Plan de Iguala",
                "countryCode": "MX"
            },
            {
                "name": "Plan de las Hayas",
                "countryCode": "MX"
            },
            {
                "name": "Plan del Río",
                "countryCode": "MX"
            },
            {
                "name": "Platón Sánchez",
                "countryCode": "MX"
            },
            {
                "name": "Playa Vicente",
                "countryCode": "MX"
            },
            {
                "name": "Playa de Chachalacas",
                "countryCode": "MX"
            },
            {
                "name": "Playa de la Libertad",
                "countryCode": "MX"
            },
            {
                "name": "Poblado 10",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Cinco",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Dos",
                "countryCode": "MX"
            },
            {
                "name": "Poblado Tres",
                "countryCode": "MX"
            },
            {
                "name": "Polutla",
                "countryCode": "MX"
            },
            {
                "name": "Potrero Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Potrero del Llano",
                "countryCode": "MX"
            },
            {
                "name": "Poza Rica de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Presidio",
                "countryCode": "MX"
            },
            {
                "name": "Presidio (Plan de Libres)",
                "countryCode": "MX"
            },
            {
                "name": "Primero de Mayo",
                "countryCode": "MX"
            },
            {
                "name": "Progreso de Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Pueblillo",
                "countryCode": "MX"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Puente Jula",
                "countryCode": "MX"
            },
            {
                "name": "Puente de Piedra",
                "countryCode": "MX"
            },
            {
                "name": "Puerto Esmeralda",
                "countryCode": "MX"
            },
            {
                "name": "Puntilla Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Pánuco",
                "countryCode": "MX"
            },
            {
                "name": "Quiamoloapan",
                "countryCode": "MX"
            },
            {
                "name": "Rafael Delgado",
                "countryCode": "MX"
            },
            {
                "name": "Rafael Lucio",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Alegre",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho del Padre",
                "countryCode": "MX"
            },
            {
                "name": "Residencial Tajín",
                "countryCode": "MX"
            },
            {
                "name": "Residencial las Olas",
                "countryCode": "MX"
            },
            {
                "name": "Ricardo Flores Magón",
                "countryCode": "MX"
            },
            {
                "name": "Rinconada",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Barrabás",
                "countryCode": "MX"
            },
            {
                "name": "Rincón de Buena Vista",
                "countryCode": "MX"
            },
            {
                "name": "Rodríguez Tejeda",
                "countryCode": "MX"
            },
            {
                "name": "Río Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Río Medio [Granja]",
                "countryCode": "MX"
            },
            {
                "name": "Sabanas de Xalostoc",
                "countryCode": "MX"
            },
            {
                "name": "Sabaneta",
                "countryCode": "MX"
            },
            {
                "name": "Saladero",
                "countryCode": "MX"
            },
            {
                "name": "Salinas",
                "countryCode": "MX"
            },
            {
                "name": "Saltabarranca",
                "countryCode": "MX"
            },
            {
                "name": "Saltillo",
                "countryCode": "MX"
            },
            {
                "name": "Salvador Díaz Mirón",
                "countryCode": "MX"
            },
            {
                "name": "San Agustín del Palmar",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Tenejapan",
                "countryCode": "MX"
            },
            {
                "name": "San Andrés Tuxtla",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Xoquitla",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "San Fernando",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco (Mata Clara)",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Nacaxtle",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Palotal",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro Xoteapan (San Isidro Texcaltitán)",
                "countryCode": "MX"
            },
            {
                "name": "San Isidro el Berro",
                "countryCode": "MX"
            },
            {
                "name": "San José Independencia",
                "countryCode": "MX"
            },
            {
                "name": "San José Neria",
                "countryCode": "MX"
            },
            {
                "name": "San José Súchil",
                "countryCode": "MX"
            },
            {
                "name": "San José Tenejapa",
                "countryCode": "MX"
            },
            {
                "name": "San José de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "San José de Abajo [Unidad Habitacional]",
                "countryCode": "MX"
            },
            {
                "name": "San José de Tapia",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Evangelista",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Seco de Valencia",
                "countryCode": "MX"
            },
            {
                "name": "San Juan Volador",
                "countryCode": "MX"
            },
            {
                "name": "San Juan de los Reyes (Luis Valenzuela)",
                "countryCode": "MX"
            },
            {
                "name": "San Julián",
                "countryCode": "MX"
            },
            {
                "name": "San Leoncio Jamaya",
                "countryCode": "MX"
            },
            {
                "name": "San Lorenzo Tenochtitlán",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos Atesquilapan",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos de León",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Mecatepec",
                "countryCode": "MX"
            },
            {
                "name": "San Miguel Tlalpoalán",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Coyutla",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Mártir",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlanixco",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Tlapacoyan",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Calería",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael Río Seco",
                "countryCode": "MX"
            },
            {
                "name": "San Sebastián",
                "countryCode": "MX"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "MX"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "MX"
            },
            {
                "name": "Santa Lucía Potrerillo",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Tatetla",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Abata",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rosa Loma Larga",
                "countryCode": "MX"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "MX"
            },
            {
                "name": "Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Sochiapan",
                "countryCode": "MX"
            },
            {
                "name": "Santiago Tuxtla",
                "countryCode": "MX"
            },
            {
                "name": "Santiago de la Peña",
                "countryCode": "MX"
            },
            {
                "name": "Sasaltitla",
                "countryCode": "MX"
            },
            {
                "name": "Sayula de Alemán",
                "countryCode": "MX"
            },
            {
                "name": "Sierra de Agua",
                "countryCode": "MX"
            },
            {
                "name": "Sihuapan",
                "countryCode": "MX"
            },
            {
                "name": "Sinapan",
                "countryCode": "MX"
            },
            {
                "name": "Sochiapa",
                "countryCode": "MX"
            },
            {
                "name": "Soconusco",
                "countryCode": "MX"
            },
            {
                "name": "Soledad Atzompa",
                "countryCode": "MX"
            },
            {
                "name": "Soledad de Doblado",
                "countryCode": "MX"
            },
            {
                "name": "Sonora",
                "countryCode": "MX"
            },
            {
                "name": "Sontecomapan",
                "countryCode": "MX"
            },
            {
                "name": "Soteapan",
                "countryCode": "MX"
            },
            {
                "name": "Soyata",
                "countryCode": "MX"
            },
            {
                "name": "Suchilapan del Río",
                "countryCode": "MX"
            },
            {
                "name": "Sumidero",
                "countryCode": "MX"
            },
            {
                "name": "Tamalín",
                "countryCode": "MX"
            },
            {
                "name": "Tamiahua",
                "countryCode": "MX"
            },
            {
                "name": "Tampico Alto",
                "countryCode": "MX"
            },
            {
                "name": "Tancoco",
                "countryCode": "MX"
            },
            {
                "name": "Tantima",
                "countryCode": "MX"
            },
            {
                "name": "Tantoyuca",
                "countryCode": "MX"
            },
            {
                "name": "Tapalapan",
                "countryCode": "MX"
            },
            {
                "name": "Tatahuicapan",
                "countryCode": "MX"
            },
            {
                "name": "Tatatila",
                "countryCode": "MX"
            },
            {
                "name": "Taza de Agua Ojo Zarco",
                "countryCode": "MX"
            },
            {
                "name": "Teayo",
                "countryCode": "MX"
            },
            {
                "name": "Tecama",
                "countryCode": "MX"
            },
            {
                "name": "Tecamalucan",
                "countryCode": "MX"
            },
            {
                "name": "Tecolapan",
                "countryCode": "MX"
            },
            {
                "name": "Tecolutla",
                "countryCode": "MX"
            },
            {
                "name": "Tehuipango",
                "countryCode": "MX"
            },
            {
                "name": "Temapache",
                "countryCode": "MX"
            },
            {
                "name": "Tempoal",
                "countryCode": "MX"
            },
            {
                "name": "Tempoal de Sánchez",
                "countryCode": "MX"
            },
            {
                "name": "Tenampa",
                "countryCode": "MX"
            },
            {
                "name": "Tenantitla",
                "countryCode": "MX"
            },
            {
                "name": "Tenenexpan",
                "countryCode": "MX"
            },
            {
                "name": "Tenixtepec",
                "countryCode": "MX"
            },
            {
                "name": "Tenochtitlán",
                "countryCode": "MX"
            },
            {
                "name": "Teocelo",
                "countryCode": "MX"
            },
            {
                "name": "Tepatlaxco",
                "countryCode": "MX"
            },
            {
                "name": "Tepetlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepetzintla",
                "countryCode": "MX"
            },
            {
                "name": "Tequila",
                "countryCode": "MX"
            },
            {
                "name": "Tesechoacan",
                "countryCode": "MX"
            },
            {
                "name": "Teteltzingo",
                "countryCode": "MX"
            },
            {
                "name": "Tetla",
                "countryCode": "MX"
            },
            {
                "name": "Tetlatzinga",
                "countryCode": "MX"
            },
            {
                "name": "Tetlaxco",
                "countryCode": "MX"
            },
            {
                "name": "Tetzacual",
                "countryCode": "MX"
            },
            {
                "name": "Texalpan de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Texalpan de Arriba",
                "countryCode": "MX"
            },
            {
                "name": "Texcaltitán Xoteapan (Texcaltitán)",
                "countryCode": "MX"
            },
            {
                "name": "Texcatepec",
                "countryCode": "MX"
            },
            {
                "name": "Texhuacán",
                "countryCode": "MX"
            },
            {
                "name": "Texin",
                "countryCode": "MX"
            },
            {
                "name": "Texistepec",
                "countryCode": "MX"
            },
            {
                "name": "Texmola",
                "countryCode": "MX"
            },
            {
                "name": "Tezonapa",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca Booxter",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Colorada",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Tihuatlan",
                "countryCode": "MX"
            },
            {
                "name": "Tilapan",
                "countryCode": "MX"
            },
            {
                "name": "Tinajitas",
                "countryCode": "MX"
            },
            {
                "name": "Tincontlán",
                "countryCode": "MX"
            },
            {
                "name": "Tlachichilco",
                "countryCode": "MX"
            },
            {
                "name": "Tlacojalpan",
                "countryCode": "MX"
            },
            {
                "name": "Tlacolula",
                "countryCode": "MX"
            },
            {
                "name": "Tlacolulan",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotalpan",
                "countryCode": "MX"
            },
            {
                "name": "Tlacotepec de Mejía",
                "countryCode": "MX"
            },
            {
                "name": "Tlacuilolapan",
                "countryCode": "MX"
            },
            {
                "name": "Tlalconteno",
                "countryCode": "MX"
            },
            {
                "name": "Tlalixcoyan",
                "countryCode": "MX"
            },
            {
                "name": "Tlalnelhuayocan",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltetela",
                "countryCode": "MX"
            },
            {
                "name": "Tlamatoca",
                "countryCode": "MX"
            },
            {
                "name": "Tlapacoyan",
                "countryCode": "MX"
            },
            {
                "name": "Tlapala",
                "countryCode": "MX"
            },
            {
                "name": "Tlatilpa",
                "countryCode": "MX"
            },
            {
                "name": "Tlatzala",
                "countryCode": "MX"
            },
            {
                "name": "Tlilapan",
                "countryCode": "MX"
            },
            {
                "name": "Tocuila",
                "countryCode": "MX"
            },
            {
                "name": "Tolome",
                "countryCode": "MX"
            },
            {
                "name": "Tomatlán",
                "countryCode": "MX"
            },
            {
                "name": "Tonalaco",
                "countryCode": "MX"
            },
            {
                "name": "Tonalapan",
                "countryCode": "MX"
            },
            {
                "name": "Tonalixco",
                "countryCode": "MX"
            },
            {
                "name": "Tonalá",
                "countryCode": "MX"
            },
            {
                "name": "Tonayán",
                "countryCode": "MX"
            },
            {
                "name": "Totolapa",
                "countryCode": "MX"
            },
            {
                "name": "Totutla",
                "countryCode": "MX"
            },
            {
                "name": "Tozongo",
                "countryCode": "MX"
            },
            {
                "name": "Trapiche Viejo",
                "countryCode": "MX"
            },
            {
                "name": "Trapiche del Rosario",
                "countryCode": "MX"
            },
            {
                "name": "Tres Valles",
                "countryCode": "MX"
            },
            {
                "name": "Tres Zapotes",
                "countryCode": "MX"
            },
            {
                "name": "Tronconal",
                "countryCode": "MX"
            },
            {
                "name": "Tula",
                "countryCode": "MX"
            },
            {
                "name": "Tulapam",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpan",
                "countryCode": "MX"
            },
            {
                "name": "Tuxpanguillo",
                "countryCode": "MX"
            },
            {
                "name": "Tuxtilla",
                "countryCode": "MX"
            },
            {
                "name": "Tuzamapan",
                "countryCode": "MX"
            },
            {
                "name": "Tzicatlán",
                "countryCode": "MX"
            },
            {
                "name": "Túxpam de Rodríguez Cano",
                "countryCode": "MX"
            },
            {
                "name": "Unión y Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Uxpanapa",
                "countryCode": "MX"
            },
            {
                "name": "Valente Diaz",
                "countryCode": "MX"
            },
            {
                "name": "Vargas",
                "countryCode": "MX"
            },
            {
                "name": "Vega de Alatorre",
                "countryCode": "MX"
            },
            {
                "name": "Vegas de la Soledad y Soledad Dos",
                "countryCode": "MX"
            },
            {
                "name": "Veinte de Noviembre",
                "countryCode": "MX"
            },
            {
                "name": "Veintiuno de Agosto",
                "countryCode": "MX"
            },
            {
                "name": "Venustiano Carranza (Peña Blanca)",
                "countryCode": "MX"
            },
            {
                "name": "Veracruz",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Guerrero",
                "countryCode": "MX"
            },
            {
                "name": "Vicente Herrera",
                "countryCode": "MX"
            },
            {
                "name": "Villa Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Villa Azueta",
                "countryCode": "MX"
            },
            {
                "name": "Villa Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Villa Emilio Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Villa Independencia",
                "countryCode": "MX"
            },
            {
                "name": "Villa Lázaro Cárdenas",
                "countryCode": "MX"
            },
            {
                "name": "Villa Unión",
                "countryCode": "MX"
            },
            {
                "name": "Villa Zempoala",
                "countryCode": "MX"
            },
            {
                "name": "Villa de las Flores",
                "countryCode": "MX"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "MX"
            },
            {
                "name": "Xalapa de Enríquez",
                "countryCode": "MX"
            },
            {
                "name": "Xalatlaco",
                "countryCode": "MX"
            },
            {
                "name": "Xico",
                "countryCode": "MX"
            },
            {
                "name": "Xoampolco",
                "countryCode": "MX"
            },
            {
                "name": "Xochimilco",
                "countryCode": "MX"
            },
            {
                "name": "Xococapa",
                "countryCode": "MX"
            },
            {
                "name": "Xocotla",
                "countryCode": "MX"
            },
            {
                "name": "Xopilapa",
                "countryCode": "MX"
            },
            {
                "name": "Xoteapan",
                "countryCode": "MX"
            },
            {
                "name": "Xoxocotla",
                "countryCode": "MX"
            },
            {
                "name": "Yanga",
                "countryCode": "MX"
            },
            {
                "name": "Yecuatla",
                "countryCode": "MX"
            },
            {
                "name": "Zacamixtle",
                "countryCode": "MX"
            },
            {
                "name": "Zacate Colorado",
                "countryCode": "MX"
            },
            {
                "name": "Zacate Colorado Segundo (Fraternidad)",
                "countryCode": "MX"
            },
            {
                "name": "Zacatla",
                "countryCode": "MX"
            },
            {
                "name": "Zacualpan",
                "countryCode": "MX"
            },
            {
                "name": "Zamora",
                "countryCode": "MX"
            },
            {
                "name": "Zapoapan",
                "countryCode": "MX"
            },
            {
                "name": "Zapoapan de Amapan",
                "countryCode": "MX"
            },
            {
                "name": "Zapoapan de Cabañas",
                "countryCode": "MX"
            },
            {
                "name": "Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "Zentla",
                "countryCode": "MX"
            },
            {
                "name": "Zongolica",
                "countryCode": "MX"
            },
            {
                "name": "Zontecomatlán de López y Fuentes",
                "countryCode": "MX"
            },
            {
                "name": "Zozocolco de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Álamo",
                "countryCode": "MX"
            },
            {
                "name": "Álamo Temapache",
                "countryCode": "MX"
            },
            {
                "name": "Úrsulo Galván",
                "countryCode": "MX"
            },
            {
                "name": "Abala",
                "countryCode": "MX"
            },
            {
                "name": "Acanceh",
                "countryCode": "MX"
            },
            {
                "name": "Akil",
                "countryCode": "MX"
            },
            {
                "name": "Baca",
                "countryCode": "MX"
            },
            {
                "name": "Becanchén",
                "countryCode": "MX"
            },
            {
                "name": "Bokoba",
                "countryCode": "MX"
            },
            {
                "name": "Bolón",
                "countryCode": "MX"
            },
            {
                "name": "Buctzotz",
                "countryCode": "MX"
            },
            {
                "name": "Cacalchen",
                "countryCode": "MX"
            },
            {
                "name": "Calcehtoc",
                "countryCode": "MX"
            },
            {
                "name": "Calotmul",
                "countryCode": "MX"
            },
            {
                "name": "Campestre Flamboyanes",
                "countryCode": "MX"
            },
            {
                "name": "Cansahcab",
                "countryCode": "MX"
            },
            {
                "name": "Cantamayec",
                "countryCode": "MX"
            },
            {
                "name": "Caucel",
                "countryCode": "MX"
            },
            {
                "name": "Celestún",
                "countryCode": "MX"
            },
            {
                "name": "Cenotillo",
                "countryCode": "MX"
            },
            {
                "name": "Cepeda",
                "countryCode": "MX"
            },
            {
                "name": "Chablekal",
                "countryCode": "MX"
            },
            {
                "name": "Chacsinkin",
                "countryCode": "MX"
            },
            {
                "name": "Chan Cenote",
                "countryCode": "MX"
            },
            {
                "name": "Chankom",
                "countryCode": "MX"
            },
            {
                "name": "Chapab",
                "countryCode": "MX"
            },
            {
                "name": "Chelem",
                "countryCode": "MX"
            },
            {
                "name": "Chemax",
                "countryCode": "MX"
            },
            {
                "name": "Chichimila",
                "countryCode": "MX"
            },
            {
                "name": "Chichén-Itzá",
                "countryCode": "MX"
            },
            {
                "name": "Chicxulub Pueblo",
                "countryCode": "MX"
            },
            {
                "name": "Chicxulub Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Chikindzonot",
                "countryCode": "MX"
            },
            {
                "name": "Chochola",
                "countryCode": "MX"
            },
            {
                "name": "Cholul",
                "countryCode": "MX"
            },
            {
                "name": "Cholul Cantón",
                "countryCode": "MX"
            },
            {
                "name": "Chuburná",
                "countryCode": "MX"
            },
            {
                "name": "Chumayel",
                "countryCode": "MX"
            },
            {
                "name": "Chunchucmil",
                "countryCode": "MX"
            },
            {
                "name": "Citilcum",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Yucatán",
                "countryCode": "MX"
            },
            {
                "name": "Conkal",
                "countryCode": "MX"
            },
            {
                "name": "Cuch Holoch",
                "countryCode": "MX"
            },
            {
                "name": "Cuncunul",
                "countryCode": "MX"
            },
            {
                "name": "Cuzama",
                "countryCode": "MX"
            },
            {
                "name": "Dzan",
                "countryCode": "MX"
            },
            {
                "name": "Dzemul",
                "countryCode": "MX"
            },
            {
                "name": "Dzibikak",
                "countryCode": "MX"
            },
            {
                "name": "Dzidzantun",
                "countryCode": "MX"
            },
            {
                "name": "Dzilam González",
                "countryCode": "MX"
            },
            {
                "name": "Dzilam de Bravo",
                "countryCode": "MX"
            },
            {
                "name": "Dzitnup",
                "countryCode": "MX"
            },
            {
                "name": "Dzityá",
                "countryCode": "MX"
            },
            {
                "name": "Dzitás",
                "countryCode": "MX"
            },
            {
                "name": "Dzoncauich",
                "countryCode": "MX"
            },
            {
                "name": "Dzonot Carretero",
                "countryCode": "MX"
            },
            {
                "name": "Dzununcan",
                "countryCode": "MX"
            },
            {
                "name": "Ekmul",
                "countryCode": "MX"
            },
            {
                "name": "Ekpedz",
                "countryCode": "MX"
            },
            {
                "name": "El Cuyo",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Espita",
                "countryCode": "MX"
            },
            {
                "name": "Euan",
                "countryCode": "MX"
            },
            {
                "name": "Halachó",
                "countryCode": "MX"
            },
            {
                "name": "Hocaba",
                "countryCode": "MX"
            },
            {
                "name": "Hoctun",
                "countryCode": "MX"
            },
            {
                "name": "Holca",
                "countryCode": "MX"
            },
            {
                "name": "Homun",
                "countryCode": "MX"
            },
            {
                "name": "Huhi",
                "countryCode": "MX"
            },
            {
                "name": "Hunucmá",
                "countryCode": "MX"
            },
            {
                "name": "Hunuku",
                "countryCode": "MX"
            },
            {
                "name": "Itzincab Palomeque",
                "countryCode": "MX"
            },
            {
                "name": "Ixil",
                "countryCode": "MX"
            },
            {
                "name": "Izamal",
                "countryCode": "MX"
            },
            {
                "name": "Kanasín",
                "countryCode": "MX"
            },
            {
                "name": "Kancab",
                "countryCode": "MX"
            },
            {
                "name": "Kantunil",
                "countryCode": "MX"
            },
            {
                "name": "Kanxoc",
                "countryCode": "MX"
            },
            {
                "name": "Kaua",
                "countryCode": "MX"
            },
            {
                "name": "Kimbila",
                "countryCode": "MX"
            },
            {
                "name": "Kinchil",
                "countryCode": "MX"
            },
            {
                "name": "Kini",
                "countryCode": "MX"
            },
            {
                "name": "Kinil",
                "countryCode": "MX"
            },
            {
                "name": "Kochol",
                "countryCode": "MX"
            },
            {
                "name": "Komchén",
                "countryCode": "MX"
            },
            {
                "name": "Kopoma",
                "countryCode": "MX"
            },
            {
                "name": "Las Coloradas",
                "countryCode": "MX"
            },
            {
                "name": "Leona Vicario",
                "countryCode": "MX"
            },
            {
                "name": "Lepan",
                "countryCode": "MX"
            },
            {
                "name": "Libre Unión",
                "countryCode": "MX"
            },
            {
                "name": "Loché",
                "countryCode": "MX"
            },
            {
                "name": "Mama",
                "countryCode": "MX"
            },
            {
                "name": "Mani",
                "countryCode": "MX"
            },
            {
                "name": "Maxcanú",
                "countryCode": "MX"
            },
            {
                "name": "Mayapan",
                "countryCode": "MX"
            },
            {
                "name": "Mococha",
                "countryCode": "MX"
            },
            {
                "name": "Molas",
                "countryCode": "MX"
            },
            {
                "name": "Motul",
                "countryCode": "MX"
            },
            {
                "name": "Muna",
                "countryCode": "MX"
            },
            {
                "name": "Muxupip",
                "countryCode": "MX"
            },
            {
                "name": "Mérida",
                "countryCode": "MX"
            },
            {
                "name": "Nacuche",
                "countryCode": "MX"
            },
            {
                "name": "Nolo",
                "countryCode": "MX"
            },
            {
                "name": "Opichen",
                "countryCode": "MX"
            },
            {
                "name": "Oxcum",
                "countryCode": "MX"
            },
            {
                "name": "Oxkutzcab",
                "countryCode": "MX"
            },
            {
                "name": "Oxkutzkab",
                "countryCode": "MX"
            },
            {
                "name": "Panabá",
                "countryCode": "MX"
            },
            {
                "name": "Pencuyut",
                "countryCode": "MX"
            },
            {
                "name": "Peto",
                "countryCode": "MX"
            },
            {
                "name": "Piste",
                "countryCode": "MX"
            },
            {
                "name": "Pixoy",
                "countryCode": "MX"
            },
            {
                "name": "Popola",
                "countryCode": "MX"
            },
            {
                "name": "Popolnah",
                "countryCode": "MX"
            },
            {
                "name": "Progreso",
                "countryCode": "MX"
            },
            {
                "name": "Pustunich",
                "countryCode": "MX"
            },
            {
                "name": "Quintana Roo",
                "countryCode": "MX"
            },
            {
                "name": "Río Lagartos",
                "countryCode": "MX"
            },
            {
                "name": "Sacalum",
                "countryCode": "MX"
            },
            {
                "name": "Sahcaba",
                "countryCode": "MX"
            },
            {
                "name": "Samahil",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio Tedzidz",
                "countryCode": "MX"
            },
            {
                "name": "San Francisco Grande",
                "countryCode": "MX"
            },
            {
                "name": "San José Tzal",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Chimay",
                "countryCode": "MX"
            },
            {
                "name": "San Rafael",
                "countryCode": "MX"
            },
            {
                "name": "Sanahcat",
                "countryCode": "MX"
            },
            {
                "name": "Santa Elena",
                "countryCode": "MX"
            },
            {
                "name": "Santa María Acú",
                "countryCode": "MX"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "MX"
            },
            {
                "name": "Seyé",
                "countryCode": "MX"
            },
            {
                "name": "Sierra Papacal",
                "countryCode": "MX"
            },
            {
                "name": "Sinanche",
                "countryCode": "MX"
            },
            {
                "name": "Sisal",
                "countryCode": "MX"
            },
            {
                "name": "Sitilpech",
                "countryCode": "MX"
            },
            {
                "name": "Sitpach",
                "countryCode": "MX"
            },
            {
                "name": "Sotuta",
                "countryCode": "MX"
            },
            {
                "name": "Sucilá",
                "countryCode": "MX"
            },
            {
                "name": "Sucopó",
                "countryCode": "MX"
            },
            {
                "name": "Sudzal",
                "countryCode": "MX"
            },
            {
                "name": "Suma",
                "countryCode": "MX"
            },
            {
                "name": "Suma de Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Tahdzibichen",
                "countryCode": "MX"
            },
            {
                "name": "Tahdziu",
                "countryCode": "MX"
            },
            {
                "name": "Tahmek",
                "countryCode": "MX"
            },
            {
                "name": "Teabo",
                "countryCode": "MX"
            },
            {
                "name": "Tecax",
                "countryCode": "MX"
            },
            {
                "name": "Tecoh",
                "countryCode": "MX"
            },
            {
                "name": "Tekal de Venegas",
                "countryCode": "MX"
            },
            {
                "name": "Tekanto",
                "countryCode": "MX"
            },
            {
                "name": "Tekax",
                "countryCode": "MX"
            },
            {
                "name": "Tekik de Regil",
                "countryCode": "MX"
            },
            {
                "name": "Tekit",
                "countryCode": "MX"
            },
            {
                "name": "Tekoh",
                "countryCode": "MX"
            },
            {
                "name": "Tekom",
                "countryCode": "MX"
            },
            {
                "name": "Telchac Pueblo",
                "countryCode": "MX"
            },
            {
                "name": "Telchac Puerto",
                "countryCode": "MX"
            },
            {
                "name": "Telchaquillo",
                "countryCode": "MX"
            },
            {
                "name": "Temax",
                "countryCode": "MX"
            },
            {
                "name": "Temozon",
                "countryCode": "MX"
            },
            {
                "name": "Tepakan",
                "countryCode": "MX"
            },
            {
                "name": "Tesoco",
                "countryCode": "MX"
            },
            {
                "name": "Tetiz",
                "countryCode": "MX"
            },
            {
                "name": "Texan de Palomeque",
                "countryCode": "MX"
            },
            {
                "name": "Teya",
                "countryCode": "MX"
            },
            {
                "name": "Tibolón",
                "countryCode": "MX"
            },
            {
                "name": "Ticopó",
                "countryCode": "MX"
            },
            {
                "name": "Ticul",
                "countryCode": "MX"
            },
            {
                "name": "Tiholop",
                "countryCode": "MX"
            },
            {
                "name": "Tikuch",
                "countryCode": "MX"
            },
            {
                "name": "Timucuy",
                "countryCode": "MX"
            },
            {
                "name": "Tinum",
                "countryCode": "MX"
            },
            {
                "name": "Tixcacalcupul",
                "countryCode": "MX"
            },
            {
                "name": "Tixcacaltuyub",
                "countryCode": "MX"
            },
            {
                "name": "Tixcancal",
                "countryCode": "MX"
            },
            {
                "name": "Tixhualactún",
                "countryCode": "MX"
            },
            {
                "name": "Tixkokob",
                "countryCode": "MX"
            },
            {
                "name": "Tixmehuac",
                "countryCode": "MX"
            },
            {
                "name": "Tixpehual",
                "countryCode": "MX"
            },
            {
                "name": "Tizimín",
                "countryCode": "MX"
            },
            {
                "name": "Tunkas",
                "countryCode": "MX"
            },
            {
                "name": "Tzucacab",
                "countryCode": "MX"
            },
            {
                "name": "Uayalceh de Peón",
                "countryCode": "MX"
            },
            {
                "name": "Uayma",
                "countryCode": "MX"
            },
            {
                "name": "Uci",
                "countryCode": "MX"
            },
            {
                "name": "Ucú",
                "countryCode": "MX"
            },
            {
                "name": "Uman",
                "countryCode": "MX"
            },
            {
                "name": "Valladolid",
                "countryCode": "MX"
            },
            {
                "name": "X-Cán",
                "countryCode": "MX"
            },
            {
                "name": "Xanabá",
                "countryCode": "MX"
            },
            {
                "name": "Xaya",
                "countryCode": "MX"
            },
            {
                "name": "Xcanatún",
                "countryCode": "MX"
            },
            {
                "name": "Xocchel",
                "countryCode": "MX"
            },
            {
                "name": "Xocen",
                "countryCode": "MX"
            },
            {
                "name": "Xohuayan",
                "countryCode": "MX"
            },
            {
                "name": "Xul",
                "countryCode": "MX"
            },
            {
                "name": "Yalkoba",
                "countryCode": "MX"
            },
            {
                "name": "Yaxcabá",
                "countryCode": "MX"
            },
            {
                "name": "Yaxcopoil",
                "countryCode": "MX"
            },
            {
                "name": "Yaxhachen",
                "countryCode": "MX"
            },
            {
                "name": "Yaxkukul",
                "countryCode": "MX"
            },
            {
                "name": "Yobain",
                "countryCode": "MX"
            },
            {
                "name": "Yotholin",
                "countryCode": "MX"
            },
            {
                "name": "Agua Gorda",
                "countryCode": "MX"
            },
            {
                "name": "Altamira",
                "countryCode": "MX"
            },
            {
                "name": "Apozol",
                "countryCode": "MX"
            },
            {
                "name": "Atolinga",
                "countryCode": "MX"
            },
            {
                "name": "Bajío de San Nicolás",
                "countryCode": "MX"
            },
            {
                "name": "Buenavista de Trujillo",
                "countryCode": "MX"
            },
            {
                "name": "Cantuna",
                "countryCode": "MX"
            },
            {
                "name": "Carrillo",
                "countryCode": "MX"
            },
            {
                "name": "Casa de Cerros",
                "countryCode": "MX"
            },
            {
                "name": "Cañas",
                "countryCode": "MX"
            },
            {
                "name": "Cañitas de Felipe Pescador",
                "countryCode": "MX"
            },
            {
                "name": "Cedros",
                "countryCode": "MX"
            },
            {
                "name": "Cerrito de la Cruz",
                "countryCode": "MX"
            },
            {
                "name": "Chalchihuites",
                "countryCode": "MX"
            },
            {
                "name": "Chaparrosa",
                "countryCode": "MX"
            },
            {
                "name": "Charco Blanco",
                "countryCode": "MX"
            },
            {
                "name": "Chichimequillas",
                "countryCode": "MX"
            },
            {
                "name": "Chupaderos",
                "countryCode": "MX"
            },
            {
                "name": "Cicacalco",
                "countryCode": "MX"
            },
            {
                "name": "Cieneguillas",
                "countryCode": "MX"
            },
            {
                "name": "Cieneguitas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Felipe Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Francisco García Salinas",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Hidalgo (El Tecolote)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Montemariana (Colonia Mariana)",
                "countryCode": "MX"
            },
            {
                "name": "Colonia Plenitud",
                "countryCode": "MX"
            },
            {
                "name": "Colonia San Francisco (San Francisco)",
                "countryCode": "MX"
            },
            {
                "name": "Concepción del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Crisóstomos",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ejido Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "El Cazadero",
                "countryCode": "MX"
            },
            {
                "name": "El Copetillo",
                "countryCode": "MX"
            },
            {
                "name": "El Fuerte",
                "countryCode": "MX"
            },
            {
                "name": "El Lampotal",
                "countryCode": "MX"
            },
            {
                "name": "El Nigromante",
                "countryCode": "MX"
            },
            {
                "name": "El Obraje",
                "countryCode": "MX"
            },
            {
                "name": "El Plateado de Joaquín Amaro",
                "countryCode": "MX"
            },
            {
                "name": "El Refugio",
                "countryCode": "MX"
            },
            {
                "name": "El Rucio",
                "countryCode": "MX"
            },
            {
                "name": "El Salto",
                "countryCode": "MX"
            },
            {
                "name": "El Salvador",
                "countryCode": "MX"
            },
            {
                "name": "El Saucito (El Horno)",
                "countryCode": "MX"
            },
            {
                "name": "El Sitio",
                "countryCode": "MX"
            },
            {
                "name": "El Tule",
                "countryCode": "MX"
            },
            {
                "name": "Emiliano Zapata",
                "countryCode": "MX"
            },
            {
                "name": "Emilio Carranza",
                "countryCode": "MX"
            },
            {
                "name": "Ermita de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Estación Camacho",
                "countryCode": "MX"
            },
            {
                "name": "Estación San José",
                "countryCode": "MX"
            },
            {
                "name": "Estancia de Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Estancia de Ánimas",
                "countryCode": "MX"
            },
            {
                "name": "Florencia",
                "countryCode": "MX"
            },
            {
                "name": "Fresnillo",
                "countryCode": "MX"
            },
            {
                "name": "General Enrique Estrada",
                "countryCode": "MX"
            },
            {
                "name": "General Juan José Ríos",
                "countryCode": "MX"
            },
            {
                "name": "General Lauro G. Caloca (El Rascón)",
                "countryCode": "MX"
            },
            {
                "name": "General Pánfilo Natera",
                "countryCode": "MX"
            },
            {
                "name": "Gonzales Ortega",
                "countryCode": "MX"
            },
            {
                "name": "Granadas",
                "countryCode": "MX"
            },
            {
                "name": "Guadalupe",
                "countryCode": "MX"
            },
            {
                "name": "Guanajuatillo",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Nueva",
                "countryCode": "MX"
            },
            {
                "name": "Hacienda Toribio",
                "countryCode": "MX"
            },
            {
                "name": "Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Ignacio Zaragoza",
                "countryCode": "MX"
            },
            {
                "name": "J. Jesús González Ortega (San Mateo)",
                "countryCode": "MX"
            },
            {
                "name": "Jalpa",
                "countryCode": "MX"
            },
            {
                "name": "Jaula de Abajo",
                "countryCode": "MX"
            },
            {
                "name": "Jerez de García Salinas",
                "countryCode": "MX"
            },
            {
                "name": "Jiménez del Teul",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos (Santa Mónica)",
                "countryCode": "MX"
            },
            {
                "name": "José María Morelos y Pavón",
                "countryCode": "MX"
            },
            {
                "name": "Juan Aldama",
                "countryCode": "MX"
            },
            {
                "name": "Juchipila",
                "countryCode": "MX"
            },
            {
                "name": "La Ballena",
                "countryCode": "MX"
            },
            {
                "name": "La Capilla",
                "countryCode": "MX"
            },
            {
                "name": "La Concepción",
                "countryCode": "MX"
            },
            {
                "name": "La Florida",
                "countryCode": "MX"
            },
            {
                "name": "La Laguna",
                "countryCode": "MX"
            },
            {
                "name": "La Luz",
                "countryCode": "MX"
            },
            {
                "name": "La Pendencia",
                "countryCode": "MX"
            },
            {
                "name": "La Tesorera",
                "countryCode": "MX"
            },
            {
                "name": "La Victoria",
                "countryCode": "MX"
            },
            {
                "name": "La Zacatecana",
                "countryCode": "MX"
            },
            {
                "name": "Laguna Seca",
                "countryCode": "MX"
            },
            {
                "name": "Las Catarinas",
                "countryCode": "MX"
            },
            {
                "name": "Las Esperanzas (El Ranchito)",
                "countryCode": "MX"
            },
            {
                "name": "Las Pilas",
                "countryCode": "MX"
            },
            {
                "name": "Lobatos",
                "countryCode": "MX"
            },
            {
                "name": "Loreto",
                "countryCode": "MX"
            },
            {
                "name": "Los Condes",
                "countryCode": "MX"
            },
            {
                "name": "Los Ramírez",
                "countryCode": "MX"
            },
            {
                "name": "Luis Moya",
                "countryCode": "MX"
            },
            {
                "name": "Lázaro Cárdenas (Rancho Grande)",
                "countryCode": "MX"
            },
            {
                "name": "Malpaso",
                "countryCode": "MX"
            },
            {
                "name": "Maravillas",
                "countryCode": "MX"
            },
            {
                "name": "Martínez Domínguez",
                "countryCode": "MX"
            },
            {
                "name": "Mazapil",
                "countryCode": "MX"
            },
            {
                "name": "Mezquital del Oro",
                "countryCode": "MX"
            },
            {
                "name": "Miguel Auza",
                "countryCode": "MX"
            },
            {
                "name": "Milagros",
                "countryCode": "MX"
            },
            {
                "name": "Momax",
                "countryCode": "MX"
            },
            {
                "name": "Monte Escobedo",
                "countryCode": "MX"
            },
            {
                "name": "Morelos",
                "countryCode": "MX"
            },
            {
                "name": "Moyahua de Estrada",
                "countryCode": "MX"
            },
            {
                "name": "Nieves",
                "countryCode": "MX"
            },
            {
                "name": "Nochistlán de Mejía",
                "countryCode": "MX"
            },
            {
                "name": "Noria de Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Ojitos",
                "countryCode": "MX"
            },
            {
                "name": "Paso de Méndez",
                "countryCode": "MX"
            },
            {
                "name": "Pastelera",
                "countryCode": "MX"
            },
            {
                "name": "Pastoría",
                "countryCode": "MX"
            },
            {
                "name": "Pedregoso",
                "countryCode": "MX"
            },
            {
                "name": "Piedra Gorda",
                "countryCode": "MX"
            },
            {
                "name": "Pinos",
                "countryCode": "MX"
            },
            {
                "name": "Plateros",
                "countryCode": "MX"
            },
            {
                "name": "Pozo de Jarillas",
                "countryCode": "MX"
            },
            {
                "name": "Pozos de Gamboa",
                "countryCode": "MX"
            },
            {
                "name": "Rafael Yáñez Sosa (El Mezquite)",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo",
                "countryCode": "MX"
            },
            {
                "name": "Rancho Nuevo de Morelos (De Guadalupe)",
                "countryCode": "MX"
            },
            {
                "name": "Rio de Medina",
                "countryCode": "MX"
            },
            {
                "name": "Río Florido",
                "countryCode": "MX"
            },
            {
                "name": "Río Grande",
                "countryCode": "MX"
            },
            {
                "name": "Sain Alto",
                "countryCode": "MX"
            },
            {
                "name": "Sain Bajo",
                "countryCode": "MX"
            },
            {
                "name": "San Antonio del Cipres",
                "countryCode": "MX"
            },
            {
                "name": "San Blas",
                "countryCode": "MX"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "MX"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "MX"
            },
            {
                "name": "San Jose de Lourdes",
                "countryCode": "MX"
            },
            {
                "name": "San José de Castellanos",
                "countryCode": "MX"
            },
            {
                "name": "San José de la Era",
                "countryCode": "MX"
            },
            {
                "name": "San José el Saladillo (El Saladillo)",
                "countryCode": "MX"
            },
            {
                "name": "San Marcos",
                "countryCode": "MX"
            },
            {
                "name": "San Martin",
                "countryCode": "MX"
            },
            {
                "name": "San Pablo",
                "countryCode": "MX"
            },
            {
                "name": "San Pedro Piedra Gorda",
                "countryCode": "MX"
            },
            {
                "name": "Santa Ana",
                "countryCode": "MX"
            },
            {
                "name": "Santa Elena",
                "countryCode": "MX"
            },
            {
                "name": "Santa María de los Ángeles",
                "countryCode": "MX"
            },
            {
                "name": "Santa Mónica",
                "countryCode": "MX"
            },
            {
                "name": "Santa Rita",
                "countryCode": "MX"
            },
            {
                "name": "Santiago",
                "countryCode": "MX"
            },
            {
                "name": "Santiaguillo",
                "countryCode": "MX"
            },
            {
                "name": "Sauceda de La Borda",
                "countryCode": "MX"
            },
            {
                "name": "Seis de Enero",
                "countryCode": "MX"
            },
            {
                "name": "Susticacán",
                "countryCode": "MX"
            },
            {
                "name": "Tabasco",
                "countryCode": "MX"
            },
            {
                "name": "Tacoaleche",
                "countryCode": "MX"
            },
            {
                "name": "Tapias de Santa Cruz (Pedro Ruiz González)",
                "countryCode": "MX"
            },
            {
                "name": "Tayahua",
                "countryCode": "MX"
            },
            {
                "name": "Tepechitlán",
                "countryCode": "MX"
            },
            {
                "name": "Tepetongo",
                "countryCode": "MX"
            },
            {
                "name": "Terminal de Providencia",
                "countryCode": "MX"
            },
            {
                "name": "Tetillas",
                "countryCode": "MX"
            },
            {
                "name": "Teúl de González Ortega",
                "countryCode": "MX"
            },
            {
                "name": "Tierra Blanca",
                "countryCode": "MX"
            },
            {
                "name": "Tlachichila",
                "countryCode": "MX"
            },
            {
                "name": "Tlaltenango de Sánchez Román",
                "countryCode": "MX"
            },
            {
                "name": "Trancoso",
                "countryCode": "MX"
            },
            {
                "name": "Trinidad García de la Cadena",
                "countryCode": "MX"
            },
            {
                "name": "Valparaíso",
                "countryCode": "MX"
            },
            {
                "name": "Villa García",
                "countryCode": "MX"
            },
            {
                "name": "Villa González Ortega",
                "countryCode": "MX"
            },
            {
                "name": "Villa Hidalgo",
                "countryCode": "MX"
            },
            {
                "name": "Villa Insurgentes (El Calabazal)",
                "countryCode": "MX"
            },
            {
                "name": "Villa de Cos",
                "countryCode": "MX"
            },
            {
                "name": "Villanueva",
                "countryCode": "MX"
            },
            {
                "name": "Víctor Rosales",
                "countryCode": "MX"
            },
            {
                "name": "Zacatecas",
                "countryCode": "MX"
            },
            {
                "name": "Zoquite",
                "countryCode": "MX"
            }
        ]
    },
    {
        "name": "Micronesia",
        "phonecode": "691",
        "cities": [
            {
                "name": "Lelu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Malem Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tafunsak Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tofol",
                "countryCode": "FM"
            },
            {
                "name": "Utwe Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Kitti Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Kolonia",
                "countryCode": "FM"
            },
            {
                "name": "Kolonia Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Kolonia Town",
                "countryCode": "FM"
            },
            {
                "name": "Madolenihm Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Mokil Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Nett Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ngatik",
                "countryCode": "FM"
            },
            {
                "name": "Nukuoro Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Palikir - National Government Center",
                "countryCode": "FM"
            },
            {
                "name": "Pingelap Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Sapwuahfik Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Sokehs Municipality",
                "countryCode": "FM"
            },
            {
                "name": "U Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Eot Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ettal Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fananu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fanapanges Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fefen Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fonoton Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Houk Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Kuttu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Lekinioch Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Losap Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Makur Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Moch Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Murilo",
                "countryCode": "FM"
            },
            {
                "name": "Murilo Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Namoluk Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Nema",
                "countryCode": "FM"
            },
            {
                "name": "Nema Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Nomwin Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Oneop Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Onou Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Onoun Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Paata-Tupunion Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Parem Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Piherarh Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Piis-Emwar Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Piis-Panewu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Pollap Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Polowat Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Pwene Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ramanum Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ruo Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Satowan Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Siis Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ta Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tamatam Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tolensom Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tonoas Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Udot-Fonuweisom Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Uman-Fonuweisom Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Unanu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Weno",
                "countryCode": "FM"
            },
            {
                "name": "Weno Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Wonei Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Colonia",
                "countryCode": "FM"
            },
            {
                "name": "Dalipebinaw Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fais",
                "countryCode": "FM"
            },
            {
                "name": "Fais Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Fanif Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Faraulep Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Gagil Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Gilman Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Kanifay Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Lamotrek Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Maap Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ngulu Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Rull Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Rumung Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Satawal Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Tomil Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Ulithi Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Weloy Municipality",
                "countryCode": "FM"
            },
            {
                "name": "Woleai Municipality",
                "countryCode": "FM"
            }
        ]
    },
    {
        "name": "Moldova",
        "phonecode": "373",
        "cities": [
            {
                "name": "Anenii Noi",
                "countryCode": "MD"
            },
            {
                "name": "Varniţa",
                "countryCode": "MD"
            },
            {
                "name": "Bălţi",
                "countryCode": "MD"
            },
            {
                "name": "Bender",
                "countryCode": "MD"
            },
            {
                "name": "Briceni",
                "countryCode": "MD"
            },
            {
                "name": "Basarabeasca",
                "countryCode": "MD"
            },
            {
                "name": "Cahul",
                "countryCode": "MD"
            },
            {
                "name": "Giurgiuleşti",
                "countryCode": "MD"
            },
            {
                "name": "Călăraşi",
                "countryCode": "MD"
            },
            {
                "name": "Cimişlia",
                "countryCode": "MD"
            },
            {
                "name": "Criuleni",
                "countryCode": "MD"
            },
            {
                "name": "Chiţcani",
                "countryCode": "MD"
            },
            {
                "name": "Căuşeni",
                "countryCode": "MD"
            },
            {
                "name": "Cantemir",
                "countryCode": "MD"
            },
            {
                "name": "Iargara",
                "countryCode": "MD"
            },
            {
                "name": "Vişniovca",
                "countryCode": "MD"
            },
            {
                "name": "Chisinau",
                "countryCode": "MD"
            },
            {
                "name": "Ciorescu",
                "countryCode": "MD"
            },
            {
                "name": "Cricova",
                "countryCode": "MD"
            },
            {
                "name": "Stăuceni",
                "countryCode": "MD"
            },
            {
                "name": "Sîngera",
                "countryCode": "MD"
            },
            {
                "name": "Vadul lui Vodă",
                "countryCode": "MD"
            },
            {
                "name": "Vatra",
                "countryCode": "MD"
            },
            {
                "name": "Briceni",
                "countryCode": "MD"
            },
            {
                "name": "Donduşeni",
                "countryCode": "MD"
            },
            {
                "name": "Drochia",
                "countryCode": "MD"
            },
            {
                "name": "Cocieri",
                "countryCode": "MD"
            },
            {
                "name": "Ustia",
                "countryCode": "MD"
            },
            {
                "name": "Edineţ",
                "countryCode": "MD"
            },
            {
                "name": "Fălești",
                "countryCode": "MD"
            },
            {
                "name": "Floreşti",
                "countryCode": "MD"
            },
            {
                "name": "Ghindești",
                "countryCode": "MD"
            },
            {
                "name": "Mărculeşti",
                "countryCode": "MD"
            },
            {
                "name": "Bugeac",
                "countryCode": "MD"
            },
            {
                "name": "Ceadîr-Lunga",
                "countryCode": "MD"
            },
            {
                "name": "Comrat",
                "countryCode": "MD"
            },
            {
                "name": "Vulcăneşti",
                "countryCode": "MD"
            },
            {
                "name": "Glodeni",
                "countryCode": "MD"
            },
            {
                "name": "Dancu",
                "countryCode": "MD"
            },
            {
                "name": "Hînceşti",
                "countryCode": "MD"
            },
            {
                "name": "Ialoveni",
                "countryCode": "MD"
            },
            {
                "name": "Nisporeni",
                "countryCode": "MD"
            },
            {
                "name": "Ocniţa",
                "countryCode": "MD"
            },
            {
                "name": "Otaci",
                "countryCode": "MD"
            },
            {
                "name": "Orhei",
                "countryCode": "MD"
            },
            {
                "name": "Rezina",
                "countryCode": "MD"
            },
            {
                "name": "Saharna",
                "countryCode": "MD"
            },
            {
                "name": "Rîşcani",
                "countryCode": "MD"
            },
            {
                "name": "Şoldăneşti",
                "countryCode": "MD"
            },
            {
                "name": "Bilicenii Vechi",
                "countryCode": "MD"
            },
            {
                "name": "Biruinţa",
                "countryCode": "MD"
            },
            {
                "name": "Sîngerei",
                "countryCode": "MD"
            },
            {
                "name": "Camenca",
                "countryCode": "MD"
            },
            {
                "name": "Crasnoe",
                "countryCode": "MD"
            },
            {
                "name": "Dnestrovsc",
                "countryCode": "MD"
            },
            {
                "name": "Dubăsari",
                "countryCode": "MD"
            },
            {
                "name": "Hryhoriopol",
                "countryCode": "MD"
            },
            {
                "name": "Maiac",
                "countryCode": "MD"
            },
            {
                "name": "Pervomaisc",
                "countryCode": "MD"
            },
            {
                "name": "Rîbniţa",
                "countryCode": "MD"
            },
            {
                "name": "Slobozia",
                "countryCode": "MD"
            },
            {
                "name": "Tiraspol",
                "countryCode": "MD"
            },
            {
                "name": "Tiraspolul Nou",
                "countryCode": "MD"
            },
            {
                "name": "Soroca",
                "countryCode": "MD"
            },
            {
                "name": "Bucovăţ",
                "countryCode": "MD"
            },
            {
                "name": "Strășeni",
                "countryCode": "MD"
            },
            {
                "name": "Ştefan Vodă",
                "countryCode": "MD"
            },
            {
                "name": "Taraclia",
                "countryCode": "MD"
            },
            {
                "name": "Tvardița",
                "countryCode": "MD"
            },
            {
                "name": "Mîndreşti",
                "countryCode": "MD"
            },
            {
                "name": "Teleneşti",
                "countryCode": "MD"
            },
            {
                "name": "Ungheni",
                "countryCode": "MD"
            }
        ]
    },
    {
        "name": "Monaco",
        "phonecode": "377",
        "cities": []
    },
    {
        "name": "Mongolia",
        "phonecode": "976",
        "cities": [
            {
                "name": "Erdenet",
                "countryCode": "MN"
            },
            {
                "name": "Darhan",
                "countryCode": "MN"
            },
            {
                "name": "Undurkhaan",
                "countryCode": "MN"
            },
            {
                "name": "Hanh",
                "countryCode": "MN"
            },
            {
                "name": "Murun-kuren",
                "countryCode": "MN"
            },
            {
                "name": "Tsengel",
                "countryCode": "MN"
            },
            {
                "name": "Turt",
                "countryCode": "MN"
            },
            {
                "name": "Khovd",
                "countryCode": "MN"
            },
            {
                "name": "Möst",
                "countryCode": "MN"
            },
            {
                "name": "Üyönch",
                "countryCode": "MN"
            },
            {
                "name": "Ulaangom",
                "countryCode": "MN"
            },
            {
                "name": "Dzuunmod",
                "countryCode": "MN"
            },
            {
                "name": "Möngönmorĭt",
                "countryCode": "MN"
            },
            {
                "name": "Ulaanhudag",
                "countryCode": "MN"
            },
            {
                "name": "Зуунмод",
                "countryCode": "MN"
            },
            {
                "name": "Dzüünharaa",
                "countryCode": "MN"
            },
            {
                "name": "Sühbaatar",
                "countryCode": "MN"
            },
            {
                "name": "Baruun-Urt",
                "countryCode": "MN"
            },
            {
                "name": "Dalandzadgad",
                "countryCode": "MN"
            },
            {
                "name": "Hanhongor",
                "countryCode": "MN"
            },
            {
                "name": "Nomgon Sum",
                "countryCode": "MN"
            },
            {
                "name": "Arvayheer",
                "countryCode": "MN"
            },
            {
                "name": "Harhorin",
                "countryCode": "MN"
            },
            {
                "name": "Hovd",
                "countryCode": "MN"
            },
            {
                "name": "Kharkhorin",
                "countryCode": "MN"
            },
            {
                "name": "Nariynteel",
                "countryCode": "MN"
            },
            {
                "name": "Uliastay",
                "countryCode": "MN"
            },
            {
                "name": "Mandalgovi",
                "countryCode": "MN"
            },
            {
                "name": "Choibalsan",
                "countryCode": "MN"
            },
            {
                "name": "Ereencav",
                "countryCode": "MN"
            },
            {
                "name": "Choyr",
                "countryCode": "MN"
            },
            {
                "name": "Altai",
                "countryCode": "MN"
            },
            {
                "name": "Bulgan",
                "countryCode": "MN"
            },
            {
                "name": "Bayanhongor",
                "countryCode": "MN"
            },
            {
                "name": "Altay",
                "countryCode": "MN"
            },
            {
                "name": "Tsengel",
                "countryCode": "MN"
            },
            {
                "name": "Ölgii",
                "countryCode": "MN"
            },
            {
                "name": "Tsetserleg",
                "countryCode": "MN"
            }
        ]
    },
    {
        "name": "Montenegro",
        "phonecode": "382",
        "cities": [
            {
                "name": "Andrijevica",
                "countryCode": "ME"
            },
            {
                "name": "Bar",
                "countryCode": "ME"
            },
            {
                "name": "Stari Bar",
                "countryCode": "ME"
            },
            {
                "name": "Sutomore",
                "countryCode": "ME"
            },
            {
                "name": "Šušanj",
                "countryCode": "ME"
            },
            {
                "name": "Berane",
                "countryCode": "ME"
            },
            {
                "name": "Bijelo Polje",
                "countryCode": "ME"
            },
            {
                "name": "Budva",
                "countryCode": "ME"
            },
            {
                "name": "Petrovac na Moru",
                "countryCode": "ME"
            },
            {
                "name": "Cetinje",
                "countryCode": "ME"
            },
            {
                "name": "Danilovgrad",
                "countryCode": "ME"
            },
            {
                "name": "Spuž",
                "countryCode": "ME"
            },
            {
                "name": "Kolašin",
                "countryCode": "ME"
            },
            {
                "name": "Dobrota",
                "countryCode": "ME"
            },
            {
                "name": "Kotor",
                "countryCode": "ME"
            },
            {
                "name": "Prčanj",
                "countryCode": "ME"
            },
            {
                "name": "Risan",
                "countryCode": "ME"
            },
            {
                "name": "Mojkovac",
                "countryCode": "ME"
            },
            {
                "name": "Nikšić",
                "countryCode": "ME"
            },
            {
                "name": "Plav",
                "countryCode": "ME"
            },
            {
                "name": "Pljevlja",
                "countryCode": "ME"
            },
            {
                "name": "Plužine",
                "countryCode": "ME"
            },
            {
                "name": "Golubovci",
                "countryCode": "ME"
            },
            {
                "name": "Goričani",
                "countryCode": "ME"
            },
            {
                "name": "Mataguži",
                "countryCode": "ME"
            },
            {
                "name": "Mojanovići",
                "countryCode": "ME"
            },
            {
                "name": "Podgorica",
                "countryCode": "ME"
            },
            {
                "name": "Tuzi",
                "countryCode": "ME"
            },
            {
                "name": "Rožaje",
                "countryCode": "ME"
            },
            {
                "name": "Šavnik",
                "countryCode": "ME"
            },
            {
                "name": "Tivat",
                "countryCode": "ME"
            },
            {
                "name": "Ulcinj",
                "countryCode": "ME"
            },
            {
                "name": "Žabljak",
                "countryCode": "ME"
            },
            {
                "name": "Gusinje",
                "countryCode": "ME"
            }
        ]
    },
    {
        "name": "Montserrat",
        "phonecode": "+1-664",
        "cities": []
    },
    {
        "name": "Morocco",
        "phonecode": "212",
        "cities": [
            {
                "name": "Al Hoceïma",
                "countryCode": "MA"
            },
            {
                "name": "Al-Hoceima",
                "countryCode": "MA"
            },
            {
                "name": "Asilah",
                "countryCode": "MA"
            },
            {
                "name": "Bni Bouayach",
                "countryCode": "MA"
            },
            {
                "name": "Brikcha",
                "countryCode": "MA"
            },
            {
                "name": "Cap Negro II",
                "countryCode": "MA"
            },
            {
                "name": "Chefchaouen Province",
                "countryCode": "MA"
            },
            {
                "name": "Chefchaouene",
                "countryCode": "MA"
            },
            {
                "name": "Derdara",
                "countryCode": "MA"
            },
            {
                "name": "Fahs-Anjra",
                "countryCode": "MA"
            },
            {
                "name": "Fnidek",
                "countryCode": "MA"
            },
            {
                "name": "Imzouren",
                "countryCode": "MA"
            },
            {
                "name": "Ksar El Kebir",
                "countryCode": "MA"
            },
            {
                "name": "Larache",
                "countryCode": "MA"
            },
            {
                "name": "M'Diq-Fnideq",
                "countryCode": "MA"
            },
            {
                "name": "Martil",
                "countryCode": "MA"
            },
            {
                "name": "Oued Laou",
                "countryCode": "MA"
            },
            {
                "name": "Ouezzane",
                "countryCode": "MA"
            },
            {
                "name": "Ouezzane Province",
                "countryCode": "MA"
            },
            {
                "name": "Senada",
                "countryCode": "MA"
            },
            {
                "name": "Tamorot",
                "countryCode": "MA"
            },
            {
                "name": "Tanger-Assilah",
                "countryCode": "MA"
            },
            {
                "name": "Tangier",
                "countryCode": "MA"
            },
            {
                "name": "Targuist",
                "countryCode": "MA"
            },
            {
                "name": "Tetouan",
                "countryCode": "MA"
            },
            {
                "name": "Tirhanimîne",
                "countryCode": "MA"
            },
            {
                "name": "Zinat",
                "countryCode": "MA"
            },
            {
                "name": "Zoumi",
                "countryCode": "MA"
            },
            {
                "name": "Ahfir",
                "countryCode": "MA"
            },
            {
                "name": "Al Aaroui",
                "countryCode": "MA"
            },
            {
                "name": "Aïn Beni Mathar",
                "countryCode": "MA"
            },
            {
                "name": "Berkane",
                "countryCode": "MA"
            },
            {
                "name": "Bouarfa",
                "countryCode": "MA"
            },
            {
                "name": "Debdou",
                "countryCode": "MA"
            },
            {
                "name": "Driouch Province",
                "countryCode": "MA"
            },
            {
                "name": "El Aïoun",
                "countryCode": "MA"
            },
            {
                "name": "Figuig",
                "countryCode": "MA"
            },
            {
                "name": "Guercif Province",
                "countryCode": "MA"
            },
            {
                "name": "Jerada",
                "countryCode": "MA"
            },
            {
                "name": "Madagh",
                "countryCode": "MA"
            },
            {
                "name": "Midar",
                "countryCode": "MA"
            },
            {
                "name": "Nador",
                "countryCode": "MA"
            },
            {
                "name": "Oujda-Angad",
                "countryCode": "MA"
            },
            {
                "name": "Saidia",
                "countryCode": "MA"
            },
            {
                "name": "Selouane",
                "countryCode": "MA"
            },
            {
                "name": "Taourirt",
                "countryCode": "MA"
            },
            {
                "name": "Tiztoutine",
                "countryCode": "MA"
            },
            {
                "name": "Zaïo",
                "countryCode": "MA"
            },
            {
                "name": "Aknoul",
                "countryCode": "MA"
            },
            {
                "name": "Almis Marmoucha",
                "countryCode": "MA"
            },
            {
                "name": "Azrou",
                "countryCode": "MA"
            },
            {
                "name": "Aïn Leuh",
                "countryCode": "MA"
            },
            {
                "name": "Bhalil",
                "countryCode": "MA"
            },
            {
                "name": "Bouarouss",
                "countryCode": "MA"
            },
            {
                "name": "Boulemane",
                "countryCode": "MA"
            },
            {
                "name": "El Hajeb",
                "countryCode": "MA"
            },
            {
                "name": "El-Hajeb",
                "countryCode": "MA"
            },
            {
                "name": "Fes",
                "countryCode": "MA"
            },
            {
                "name": "Fès al Bali",
                "countryCode": "MA"
            },
            {
                "name": "Galaz",
                "countryCode": "MA"
            },
            {
                "name": "Ghouazi",
                "countryCode": "MA"
            },
            {
                "name": "Guercif",
                "countryCode": "MA"
            },
            {
                "name": "Ifrane",
                "countryCode": "MA"
            },
            {
                "name": "Meknes",
                "countryCode": "MA"
            },
            {
                "name": "Missour",
                "countryCode": "MA"
            },
            {
                "name": "Moulay Bouchta",
                "countryCode": "MA"
            },
            {
                "name": "Moulay-Yacoub",
                "countryCode": "MA"
            },
            {
                "name": "Oued Amlil",
                "countryCode": "MA"
            },
            {
                "name": "Oulad Tayeb",
                "countryCode": "MA"
            },
            {
                "name": "Ourtzagh",
                "countryCode": "MA"
            },
            {
                "name": "Sefrou",
                "countryCode": "MA"
            },
            {
                "name": "Tahla",
                "countryCode": "MA"
            },
            {
                "name": "Talzemt",
                "countryCode": "MA"
            },
            {
                "name": "Taounate",
                "countryCode": "MA"
            },
            {
                "name": "Taza",
                "countryCode": "MA"
            },
            {
                "name": "Tmourghout",
                "countryCode": "MA"
            },
            {
                "name": "Azemmour",
                "countryCode": "MA"
            },
            {
                "name": "Benslimane",
                "countryCode": "MA"
            },
            {
                "name": "Berrechid",
                "countryCode": "MA"
            },
            {
                "name": "Berrechid Province",
                "countryCode": "MA"
            },
            {
                "name": "Boulaouane",
                "countryCode": "MA"
            },
            {
                "name": "Bouskoura",
                "countryCode": "MA"
            },
            {
                "name": "Bouznika",
                "countryCode": "MA"
            },
            {
                "name": "Casablanca",
                "countryCode": "MA"
            },
            {
                "name": "El Jadid",
                "countryCode": "MA"
            },
            {
                "name": "El-Jadida",
                "countryCode": "MA"
            },
            {
                "name": "Mediouna",
                "countryCode": "MA"
            },
            {
                "name": "Mohammedia",
                "countryCode": "MA"
            },
            {
                "name": "Nouaceur",
                "countryCode": "MA"
            },
            {
                "name": "Oualidia",
                "countryCode": "MA"
            },
            {
                "name": "Oulad Frej",
                "countryCode": "MA"
            },
            {
                "name": "Settat",
                "countryCode": "MA"
            },
            {
                "name": "Settat Province",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Bennour",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Smai’il",
                "countryCode": "MA"
            },
            {
                "name": "Tit Mellil",
                "countryCode": "MA"
            },
            {
                "name": "Zawyat an Nwaçer",
                "countryCode": "MA"
            },
            {
                "name": "Agdz",
                "countryCode": "MA"
            },
            {
                "name": "Alnif",
                "countryCode": "MA"
            },
            {
                "name": "Aoufous",
                "countryCode": "MA"
            },
            {
                "name": "Arfoud",
                "countryCode": "MA"
            },
            {
                "name": "Errachidia",
                "countryCode": "MA"
            },
            {
                "name": "Imilchil",
                "countryCode": "MA"
            },
            {
                "name": "Jebel Tiskaouine",
                "countryCode": "MA"
            },
            {
                "name": "Jorf",
                "countryCode": "MA"
            },
            {
                "name": "Kelaat Mgouna",
                "countryCode": "MA"
            },
            {
                "name": "Mhamid",
                "countryCode": "MA"
            },
            {
                "name": "Midelt",
                "countryCode": "MA"
            },
            {
                "name": "Ouarzazat",
                "countryCode": "MA"
            },
            {
                "name": "Ouarzazate",
                "countryCode": "MA"
            },
            {
                "name": "Reçani",
                "countryCode": "MA"
            },
            {
                "name": "Taznakht",
                "countryCode": "MA"
            },
            {
                "name": "Telouet",
                "countryCode": "MA"
            },
            {
                "name": "Tinghir",
                "countryCode": "MA"
            },
            {
                "name": "Tinghir Province",
                "countryCode": "MA"
            },
            {
                "name": "Zagora",
                "countryCode": "MA"
            },
            {
                "name": "Assa-Zag",
                "countryCode": "MA"
            },
            {
                "name": "Guelmim",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Ifni",
                "countryCode": "MA"
            },
            {
                "name": "Tan-Tan",
                "countryCode": "MA"
            },
            {
                "name": "Akhfennir",
                "countryCode": "MA"
            },
            {
                "name": "Boujdour",
                "countryCode": "MA"
            },
            {
                "name": "Es-Semara",
                "countryCode": "MA"
            },
            {
                "name": "Gueltat Zemmour",
                "countryCode": "MA"
            },
            {
                "name": "Laayoune",
                "countryCode": "MA"
            },
            {
                "name": "Smara",
                "countryCode": "MA"
            },
            {
                "name": "Tarfaya",
                "countryCode": "MA"
            },
            {
                "name": "Agadir",
                "countryCode": "MA"
            },
            {
                "name": "Agadir Melloul",
                "countryCode": "MA"
            },
            {
                "name": "Agadir-Ida-ou-Tnan",
                "countryCode": "MA"
            },
            {
                "name": "Aoulouz",
                "countryCode": "MA"
            },
            {
                "name": "Aourir",
                "countryCode": "MA"
            },
            {
                "name": "Arazane",
                "countryCode": "MA"
            },
            {
                "name": "Argana",
                "countryCode": "MA"
            },
            {
                "name": "Bigoudine",
                "countryCode": "MA"
            },
            {
                "name": "Chtouka-Ait-Baha",
                "countryCode": "MA"
            },
            {
                "name": "Inezgane",
                "countryCode": "MA"
            },
            {
                "name": "Inezgane-Ait Melloul",
                "countryCode": "MA"
            },
            {
                "name": "Ouijjane",
                "countryCode": "MA"
            },
            {
                "name": "Oulad Teïma",
                "countryCode": "MA"
            },
            {
                "name": "Reggada",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Ifni",
                "countryCode": "MA"
            },
            {
                "name": "Tadrart",
                "countryCode": "MA"
            },
            {
                "name": "Tafraout",
                "countryCode": "MA"
            },
            {
                "name": "Taghazout",
                "countryCode": "MA"
            },
            {
                "name": "Taliouine",
                "countryCode": "MA"
            },
            {
                "name": "Tamri",
                "countryCode": "MA"
            },
            {
                "name": "Tanalt",
                "countryCode": "MA"
            },
            {
                "name": "Taroudannt",
                "countryCode": "MA"
            },
            {
                "name": "Taroudant",
                "countryCode": "MA"
            },
            {
                "name": "Tarsouat",
                "countryCode": "MA"
            },
            {
                "name": "Tata",
                "countryCode": "MA"
            },
            {
                "name": "Tiznit",
                "countryCode": "MA"
            },
            {
                "name": "Arbaoua",
                "countryCode": "MA"
            },
            {
                "name": "Had Kourt",
                "countryCode": "MA"
            },
            {
                "name": "Kenitra",
                "countryCode": "MA"
            },
            {
                "name": "Kenitra Province",
                "countryCode": "MA"
            },
            {
                "name": "Khemisset",
                "countryCode": "MA"
            },
            {
                "name": "Mechraa Bel Ksiri",
                "countryCode": "MA"
            },
            {
                "name": "Oulmes",
                "countryCode": "MA"
            },
            {
                "name": "Rabat",
                "countryCode": "MA"
            },
            {
                "name": "Sale",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Bousber",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Qacem",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Redouane",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Slimane",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Yahia El Gharb",
                "countryCode": "MA"
            },
            {
                "name": "Sidi-Kacem",
                "countryCode": "MA"
            },
            {
                "name": "Skhirate",
                "countryCode": "MA"
            },
            {
                "name": "Skhirate-Temara",
                "countryCode": "MA"
            },
            {
                "name": "Souq Larb’a al Gharb",
                "countryCode": "MA"
            },
            {
                "name": "Temara",
                "countryCode": "MA"
            },
            {
                "name": "Teroual",
                "countryCode": "MA"
            },
            {
                "name": "Tiflet",
                "countryCode": "MA"
            },
            {
                "name": "Aguelmous",
                "countryCode": "MA"
            },
            {
                "name": "Al Fqih Ben Çalah",
                "countryCode": "MA"
            },
            {
                "name": "Azilal",
                "countryCode": "MA"
            },
            {
                "name": "Azilal Province",
                "countryCode": "MA"
            },
            {
                "name": "Beni Mellal",
                "countryCode": "MA"
            },
            {
                "name": "Beni-Mellal",
                "countryCode": "MA"
            },
            {
                "name": "Boujniba",
                "countryCode": "MA"
            },
            {
                "name": "Bzou",
                "countryCode": "MA"
            },
            {
                "name": "Dar Ould Zidouh",
                "countryCode": "MA"
            },
            {
                "name": "Demnate",
                "countryCode": "MA"
            },
            {
                "name": "El Ksiba",
                "countryCode": "MA"
            },
            {
                "name": "Fquih Ben Salah Province",
                "countryCode": "MA"
            },
            {
                "name": "Ifrane",
                "countryCode": "MA"
            },
            {
                "name": "Isseksi",
                "countryCode": "MA"
            },
            {
                "name": "Itzer",
                "countryCode": "MA"
            },
            {
                "name": "Kasba Tadla",
                "countryCode": "MA"
            },
            {
                "name": "Kerrouchen",
                "countryCode": "MA"
            },
            {
                "name": "Khenifra",
                "countryCode": "MA"
            },
            {
                "name": "Khouribga",
                "countryCode": "MA"
            },
            {
                "name": "Khouribga Province",
                "countryCode": "MA"
            },
            {
                "name": "Midelt",
                "countryCode": "MA"
            },
            {
                "name": "Ouaoula",
                "countryCode": "MA"
            },
            {
                "name": "Oued Zem",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Jaber",
                "countryCode": "MA"
            },
            {
                "name": "Timoulilt",
                "countryCode": "MA"
            },
            {
                "name": "Zawyat ech Cheïkh",
                "countryCode": "MA"
            },
            {
                "name": "Aousserd",
                "countryCode": "MA"
            },
            {
                "name": "Imlili",
                "countryCode": "MA"
            },
            {
                "name": "Oued-Ed-Dahab",
                "countryCode": "MA"
            },
            {
                "name": "Abadou",
                "countryCode": "MA"
            },
            {
                "name": "Adassil",
                "countryCode": "MA"
            },
            {
                "name": "Al-Haouz",
                "countryCode": "MA"
            },
            {
                "name": "Bouabout",
                "countryCode": "MA"
            },
            {
                "name": "Chichaoua",
                "countryCode": "MA"
            },
            {
                "name": "Essaouira",
                "countryCode": "MA"
            },
            {
                "name": "Kelaa-Des-Sraghna",
                "countryCode": "MA"
            },
            {
                "name": "Marrakech",
                "countryCode": "MA"
            },
            {
                "name": "Marrakesh",
                "countryCode": "MA"
            },
            {
                "name": "Oukaïmedene",
                "countryCode": "MA"
            },
            {
                "name": "Rehamna",
                "countryCode": "MA"
            },
            {
                "name": "Safi",
                "countryCode": "MA"
            },
            {
                "name": "Setti Fatma",
                "countryCode": "MA"
            },
            {
                "name": "Sidi Rahhal",
                "countryCode": "MA"
            },
            {
                "name": "Smimou",
                "countryCode": "MA"
            },
            {
                "name": "Tamanar",
                "countryCode": "MA"
            },
            {
                "name": "Taouloukoult",
                "countryCode": "MA"
            },
            {
                "name": "Tidili Mesfioua",
                "countryCode": "MA"
            },
            {
                "name": "Timezgadiouine",
                "countryCode": "MA"
            },
            {
                "name": "Youssoufia",
                "countryCode": "MA"
            },
            {
                "name": "Zerkten",
                "countryCode": "MA"
            }
        ]
    },
    {
        "name": "Mozambique",
        "phonecode": "258",
        "cities": [
            {
                "name": "Cuamba",
                "countryCode": "MZ"
            },
            {
                "name": "Lichinga",
                "countryCode": "MZ"
            },
            {
                "name": "Mandimba",
                "countryCode": "MZ"
            },
            {
                "name": "Chimoio",
                "countryCode": "MZ"
            },
            {
                "name": "Chibuto",
                "countryCode": "MZ"
            },
            {
                "name": "Chokwé",
                "countryCode": "MZ"
            },
            {
                "name": "Macia",
                "countryCode": "MZ"
            },
            {
                "name": "Xai-Xai",
                "countryCode": "MZ"
            },
            {
                "name": "Inhambane",
                "countryCode": "MZ"
            },
            {
                "name": "Maxixe",
                "countryCode": "MZ"
            },
            {
                "name": "Boane District",
                "countryCode": "MZ"
            },
            {
                "name": "Concelho de Matola",
                "countryCode": "MZ"
            },
            {
                "name": "Magude District",
                "countryCode": "MZ"
            },
            {
                "name": "Manhica",
                "countryCode": "MZ"
            },
            {
                "name": "Marracuene District",
                "countryCode": "MZ"
            },
            {
                "name": "Matola",
                "countryCode": "MZ"
            },
            {
                "name": "Matutiune District",
                "countryCode": "MZ"
            },
            {
                "name": "Moamba District",
                "countryCode": "MZ"
            },
            {
                "name": "Namaacha District",
                "countryCode": "MZ"
            },
            {
                "name": "Ressano Garcia",
                "countryCode": "MZ"
            },
            {
                "name": "KaTembe",
                "countryCode": "MZ"
            },
            {
                "name": "Maputo",
                "countryCode": "MZ"
            },
            {
                "name": "António Enes",
                "countryCode": "MZ"
            },
            {
                "name": "Ilha de Moçambique",
                "countryCode": "MZ"
            },
            {
                "name": "Mutuáli",
                "countryCode": "MZ"
            },
            {
                "name": "Nacala",
                "countryCode": "MZ"
            },
            {
                "name": "Nampula",
                "countryCode": "MZ"
            },
            {
                "name": "Chiure",
                "countryCode": "MZ"
            },
            {
                "name": "Mocímboa",
                "countryCode": "MZ"
            },
            {
                "name": "Montepuez",
                "countryCode": "MZ"
            },
            {
                "name": "Pemba",
                "countryCode": "MZ"
            },
            {
                "name": "Alto Molócuè",
                "countryCode": "MZ"
            },
            {
                "name": "Chinde",
                "countryCode": "MZ"
            },
            {
                "name": "Quelimane",
                "countryCode": "MZ"
            },
            {
                "name": "Beira",
                "countryCode": "MZ"
            },
            {
                "name": "Dondo",
                "countryCode": "MZ"
            },
            {
                "name": "Nhamatanda District",
                "countryCode": "MZ"
            },
            {
                "name": "Tete",
                "countryCode": "MZ"
            }
        ]
    },
    {
        "name": "Myanmar",
        "phonecode": "95",
        "cities": [
            {
                "name": "Mawlaik",
                "countryCode": "MM"
            },
            {
                "name": "Monywa",
                "countryCode": "MM"
            },
            {
                "name": "Sagaing",
                "countryCode": "MM"
            },
            {
                "name": "Shwebo",
                "countryCode": "MM"
            },
            {
                "name": "Bago",
                "countryCode": "MM"
            },
            {
                "name": "Letpandan",
                "countryCode": "MM"
            },
            {
                "name": "Nyaunglebin",
                "countryCode": "MM"
            },
            {
                "name": "Paungde",
                "countryCode": "MM"
            },
            {
                "name": "Pyay",
                "countryCode": "MM"
            },
            {
                "name": "Pyu",
                "countryCode": "MM"
            },
            {
                "name": "Taungoo",
                "countryCode": "MM"
            },
            {
                "name": "Thanatpin",
                "countryCode": "MM"
            },
            {
                "name": "Tharyarwady",
                "countryCode": "MM"
            },
            {
                "name": "Chauk",
                "countryCode": "MM"
            },
            {
                "name": "Magway",
                "countryCode": "MM"
            },
            {
                "name": "Minbu",
                "countryCode": "MM"
            },
            {
                "name": "Myaydo",
                "countryCode": "MM"
            },
            {
                "name": "Pakokku",
                "countryCode": "MM"
            },
            {
                "name": "Taungdwingyi",
                "countryCode": "MM"
            },
            {
                "name": "Thayetmyo",
                "countryCode": "MM"
            },
            {
                "name": "Yenangyaung",
                "countryCode": "MM"
            },
            {
                "name": "Kyaukse",
                "countryCode": "MM"
            },
            {
                "name": "Mandalay",
                "countryCode": "MM"
            },
            {
                "name": "Meiktila",
                "countryCode": "MM"
            },
            {
                "name": "Mogok",
                "countryCode": "MM"
            },
            {
                "name": "Myingyan",
                "countryCode": "MM"
            },
            {
                "name": "Nyaungshwe",
                "countryCode": "MM"
            },
            {
                "name": "Pyin Oo Lwin",
                "countryCode": "MM"
            },
            {
                "name": "Yamethin",
                "countryCode": "MM"
            },
            {
                "name": "Dawei",
                "countryCode": "MM"
            },
            {
                "name": "Kawthoung",
                "countryCode": "MM"
            },
            {
                "name": "Myeik",
                "countryCode": "MM"
            },
            {
                "name": "Kanbe",
                "countryCode": "MM"
            },
            {
                "name": "Kayan",
                "countryCode": "MM"
            },
            {
                "name": "Syriam",
                "countryCode": "MM"
            },
            {
                "name": "Thongwa",
                "countryCode": "MM"
            },
            {
                "name": "Twante",
                "countryCode": "MM"
            },
            {
                "name": "Yangon",
                "countryCode": "MM"
            },
            {
                "name": "Bogale",
                "countryCode": "MM"
            },
            {
                "name": "Hinthada",
                "countryCode": "MM"
            },
            {
                "name": "Kyaiklat",
                "countryCode": "MM"
            },
            {
                "name": "Maubin",
                "countryCode": "MM"
            },
            {
                "name": "Mawlamyinegyunn",
                "countryCode": "MM"
            },
            {
                "name": "Myanaung",
                "countryCode": "MM"
            },
            {
                "name": "Nyaungdon",
                "countryCode": "MM"
            },
            {
                "name": "Pathein",
                "countryCode": "MM"
            },
            {
                "name": "Pyapon",
                "countryCode": "MM"
            },
            {
                "name": "Wakema",
                "countryCode": "MM"
            },
            {
                "name": "Bhamo",
                "countryCode": "MM"
            },
            {
                "name": "Myitkyina",
                "countryCode": "MM"
            },
            {
                "name": "Loikaw",
                "countryCode": "MM"
            },
            {
                "name": "Dellok",
                "countryCode": "MM"
            },
            {
                "name": "Hpa-An",
                "countryCode": "MM"
            },
            {
                "name": "Klonhtoug",
                "countryCode": "MM"
            },
            {
                "name": "Kyain Seikgyi Township",
                "countryCode": "MM"
            },
            {
                "name": "Mikenaungea",
                "countryCode": "MM"
            },
            {
                "name": "Myawadi",
                "countryCode": "MM"
            },
            {
                "name": "Pulei",
                "countryCode": "MM"
            },
            {
                "name": "Tagondaing",
                "countryCode": "MM"
            },
            {
                "name": "Tamoowoug",
                "countryCode": "MM"
            },
            {
                "name": "Falam",
                "countryCode": "MM"
            },
            {
                "name": "Hakha",
                "countryCode": "MM"
            },
            {
                "name": "Kyaikkami",
                "countryCode": "MM"
            },
            {
                "name": "Kyaikto",
                "countryCode": "MM"
            },
            {
                "name": "Martaban",
                "countryCode": "MM"
            },
            {
                "name": "Mawlamyine",
                "countryCode": "MM"
            },
            {
                "name": "Mudon",
                "countryCode": "MM"
            },
            {
                "name": "Thaton",
                "countryCode": "MM"
            },
            {
                "name": "Sittwe",
                "countryCode": "MM"
            },
            {
                "name": "Lashio",
                "countryCode": "MM"
            },
            {
                "name": "Tachilek",
                "countryCode": "MM"
            },
            {
                "name": "Taunggyi",
                "countryCode": "MM"
            },
            {
                "name": "Nay Pyi Taw",
                "countryCode": "MM"
            },
            {
                "name": "Pyinmana",
                "countryCode": "MM"
            }
        ]
    },
    {
        "name": "Namibia",
        "phonecode": "264",
        "cities": [
            {
                "name": "Bagani",
                "countryCode": "NA"
            },
            {
                "name": "Katima Mulilo",
                "countryCode": "NA"
            },
            {
                "name": "Arandis",
                "countryCode": "NA"
            },
            {
                "name": "Hentiesbaai",
                "countryCode": "NA"
            },
            {
                "name": "Karibib",
                "countryCode": "NA"
            },
            {
                "name": "Omaruru",
                "countryCode": "NA"
            },
            {
                "name": "Otjimbingwe",
                "countryCode": "NA"
            },
            {
                "name": "Swakopmund",
                "countryCode": "NA"
            },
            {
                "name": "Swakopmund Constituency",
                "countryCode": "NA"
            },
            {
                "name": "Usakos",
                "countryCode": "NA"
            },
            {
                "name": "Walvis Bay",
                "countryCode": "NA"
            },
            {
                "name": "Aranos",
                "countryCode": "NA"
            },
            {
                "name": "Hoachanas",
                "countryCode": "NA"
            },
            {
                "name": "Maltahöhe",
                "countryCode": "NA"
            },
            {
                "name": "Mariental",
                "countryCode": "NA"
            },
            {
                "name": "Rehoboth",
                "countryCode": "NA"
            },
            {
                "name": "Bethanie",
                "countryCode": "NA"
            },
            {
                "name": "Karasburg",
                "countryCode": "NA"
            },
            {
                "name": "Keetmanshoop",
                "countryCode": "NA"
            },
            {
                "name": "Lüderitz",
                "countryCode": "NA"
            },
            {
                "name": "Oranjemund",
                "countryCode": "NA"
            },
            {
                "name": "Tses",
                "countryCode": "NA"
            },
            {
                "name": "Warmbad",
                "countryCode": "NA"
            },
            {
                "name": "Rundu",
                "countryCode": "NA"
            },
            {
                "name": "Katutura",
                "countryCode": "NA"
            },
            {
                "name": "Windhoek",
                "countryCode": "NA"
            },
            {
                "name": "Epupa Constituency",
                "countryCode": "NA"
            },
            {
                "name": "Khorixas",
                "countryCode": "NA"
            },
            {
                "name": "Khorixas Constituency",
                "countryCode": "NA"
            },
            {
                "name": "Opuwo",
                "countryCode": "NA"
            },
            {
                "name": "Opuwo Constituency",
                "countryCode": "NA"
            },
            {
                "name": "Outjo",
                "countryCode": "NA"
            },
            {
                "name": "Sesfontein Constituency",
                "countryCode": "NA"
            },
            {
                "name": "Grootfontein",
                "countryCode": "NA"
            },
            {
                "name": "Okahandja",
                "countryCode": "NA"
            },
            {
                "name": "Okakarara",
                "countryCode": "NA"
            },
            {
                "name": "Otavi",
                "countryCode": "NA"
            },
            {
                "name": "Otjiwarongo",
                "countryCode": "NA"
            },
            {
                "name": "Gobabis",
                "countryCode": "NA"
            },
            {
                "name": "Ondangwa",
                "countryCode": "NA"
            },
            {
                "name": "Ongwediva",
                "countryCode": "NA"
            },
            {
                "name": "Oshakati",
                "countryCode": "NA"
            },
            {
                "name": "Okahao",
                "countryCode": "NA"
            },
            {
                "name": "Ongandjera",
                "countryCode": "NA"
            },
            {
                "name": "Outapi",
                "countryCode": "NA"
            },
            {
                "name": "Omuthiya",
                "countryCode": "NA"
            },
            {
                "name": "Tsumeb",
                "countryCode": "NA"
            },
            {
                "name": "Oshikango",
                "countryCode": "NA"
            }
        ]
    },
    {
        "name": "Nauru",
        "phonecode": "674",
        "cities": [
            {
                "name": "Arijejen",
                "countryCode": "NR"
            },
            {
                "name": "Anabar",
                "countryCode": "NR"
            },
            {
                "name": "Baiti",
                "countryCode": "NR"
            },
            {
                "name": "Ijuw",
                "countryCode": "NR"
            },
            {
                "name": "Menen",
                "countryCode": "NR"
            },
            {
                "name": "Uaboe",
                "countryCode": "NR"
            },
            {
                "name": "Yaren",
                "countryCode": "NR"
            }
        ]
    },
    {
        "name": "Nepal",
        "phonecode": "977",
        "cities": [
            {
                "name": "Banepā",
                "countryCode": "NP"
            },
            {
                "name": "Bharatpur",
                "countryCode": "NP"
            },
            {
                "name": "Birgañj",
                "countryCode": "NP"
            },
            {
                "name": "Dhulikhel",
                "countryCode": "NP"
            },
            {
                "name": "Gaur",
                "countryCode": "NP"
            },
            {
                "name": "Hari Bdr Tamang House",
                "countryCode": "NP"
            },
            {
                "name": "Hetauda",
                "countryCode": "NP"
            },
            {
                "name": "Jaleshwar",
                "countryCode": "NP"
            },
            {
                "name": "Janakpur",
                "countryCode": "NP"
            },
            {
                "name": "Kathmandu",
                "countryCode": "NP"
            },
            {
                "name": "Kirtipur",
                "countryCode": "NP"
            },
            {
                "name": "Madhyapur Thimi",
                "countryCode": "NP"
            },
            {
                "name": "Malaṅgawā",
                "countryCode": "NP"
            },
            {
                "name": "Nagarkot",
                "countryCode": "NP"
            },
            {
                "name": "Panauti",
                "countryCode": "NP"
            },
            {
                "name": "Pātan",
                "countryCode": "NP"
            },
            {
                "name": "Rāmechhāp",
                "countryCode": "NP"
            },
            {
                "name": "kankrabari Dovan",
                "countryCode": "NP"
            },
            {
                "name": "Bhojpur",
                "countryCode": "NP"
            },
            {
                "name": "Birendranagar",
                "countryCode": "NP"
            },
            {
                "name": "Dailekh",
                "countryCode": "NP"
            },
            {
                "name": "Gulariyā",
                "countryCode": "NP"
            },
            {
                "name": "Jumla",
                "countryCode": "NP"
            },
            {
                "name": "Nepalgunj",
                "countryCode": "NP"
            },
            {
                "name": "Tulsīpur",
                "countryCode": "NP"
            },
            {
                "name": "Bhattarai Danda",
                "countryCode": "NP"
            },
            {
                "name": "Butwāl",
                "countryCode": "NP"
            },
            {
                "name": "Bāglung",
                "countryCode": "NP"
            },
            {
                "name": "Chitre",
                "countryCode": "NP"
            },
            {
                "name": "Dihi",
                "countryCode": "NP"
            },
            {
                "name": "Dārchulā",
                "countryCode": "NP"
            },
            {
                "name": "Pokhara",
                "countryCode": "NP"
            },
            {
                "name": "Siddharthanagar",
                "countryCode": "NP"
            },
            {
                "name": "Tānsen",
                "countryCode": "NP"
            },
            {
                "name": "Wāliṅ",
                "countryCode": "NP"
            },
            {
                "name": "Bhadrapur",
                "countryCode": "NP"
            },
            {
                "name": "Bhojpur",
                "countryCode": "NP"
            },
            {
                "name": "Biratnagar",
                "countryCode": "NP"
            },
            {
                "name": "Dhankutā",
                "countryCode": "NP"
            },
            {
                "name": "Dharān",
                "countryCode": "NP"
            },
            {
                "name": "Ilām",
                "countryCode": "NP"
            },
            {
                "name": "Inaruwa",
                "countryCode": "NP"
            },
            {
                "name": "Khanbari",
                "countryCode": "NP"
            },
            {
                "name": "Lahān",
                "countryCode": "NP"
            },
            {
                "name": "Lobujya",
                "countryCode": "NP"
            },
            {
                "name": "Namche Bazar",
                "countryCode": "NP"
            },
            {
                "name": "Rājbirāj",
                "countryCode": "NP"
            },
            {
                "name": "Siraha",
                "countryCode": "NP"
            },
            {
                "name": "Titahari",
                "countryCode": "NP"
            },
            {
                "name": "Triyuga",
                "countryCode": "NP"
            },
            {
                "name": "Dadeldhurā",
                "countryCode": "NP"
            },
            {
                "name": "Dhangaḍhi̇̄",
                "countryCode": "NP"
            },
            {
                "name": "Dipayal",
                "countryCode": "NP"
            },
            {
                "name": "Mahendranagar",
                "countryCode": "NP"
            },
            {
                "name": "Ṭikāpur",
                "countryCode": "NP"
            }
        ]
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "phonecode": "599",
        "cities": []
    },
    {
        "name": "Netherlands",
        "phonecode": "31",
        "cities": [
            {
                "name": "Aalden",
                "countryCode": "NL"
            },
            {
                "name": "Angelslo",
                "countryCode": "NL"
            },
            {
                "name": "Annen",
                "countryCode": "NL"
            },
            {
                "name": "Assen",
                "countryCode": "NL"
            },
            {
                "name": "Ballast",
                "countryCode": "NL"
            },
            {
                "name": "Barger-Oosterveld",
                "countryCode": "NL"
            },
            {
                "name": "Bargeres",
                "countryCode": "NL"
            },
            {
                "name": "Beilen",
                "countryCode": "NL"
            },
            {
                "name": "Borger",
                "countryCode": "NL"
            },
            {
                "name": "Coevorden",
                "countryCode": "NL"
            },
            {
                "name": "Dalen",
                "countryCode": "NL"
            },
            {
                "name": "De Loo",
                "countryCode": "NL"
            },
            {
                "name": "De Wijk",
                "countryCode": "NL"
            },
            {
                "name": "Diever",
                "countryCode": "NL"
            },
            {
                "name": "Dwingeloo",
                "countryCode": "NL"
            },
            {
                "name": "Eelde",
                "countryCode": "NL"
            },
            {
                "name": "Eext",
                "countryCode": "NL"
            },
            {
                "name": "Emmen",
                "countryCode": "NL"
            },
            {
                "name": "Emmer-Compascuum",
                "countryCode": "NL"
            },
            {
                "name": "Emmer-Erfscheidenveen",
                "countryCode": "NL"
            },
            {
                "name": "Emmerhout",
                "countryCode": "NL"
            },
            {
                "name": "Emmermeer",
                "countryCode": "NL"
            },
            {
                "name": "Emmerschans",
                "countryCode": "NL"
            },
            {
                "name": "Exloo",
                "countryCode": "NL"
            },
            {
                "name": "Gasselte",
                "countryCode": "NL"
            },
            {
                "name": "Gasselternijveen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Aa en Hunze",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Assen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Borger-Odoorn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Coevorden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente De Wolden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Emmen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hoogeveen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Meppel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Midden-Drenthe",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Noordenveld",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tynaarlo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Westerveld",
                "countryCode": "NL"
            },
            {
                "name": "Gieten",
                "countryCode": "NL"
            },
            {
                "name": "Havelte",
                "countryCode": "NL"
            },
            {
                "name": "Hoogeveen",
                "countryCode": "NL"
            },
            {
                "name": "Klazienaveen",
                "countryCode": "NL"
            },
            {
                "name": "Koekange",
                "countryCode": "NL"
            },
            {
                "name": "Krakeel",
                "countryCode": "NL"
            },
            {
                "name": "Marsdijk",
                "countryCode": "NL"
            },
            {
                "name": "Meppel",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Buinen",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Dordrecht",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Roden",
                "countryCode": "NL"
            },
            {
                "name": "Noordbarge",
                "countryCode": "NL"
            },
            {
                "name": "Norg",
                "countryCode": "NL"
            },
            {
                "name": "Odoorn",
                "countryCode": "NL"
            },
            {
                "name": "Oosterhesselen",
                "countryCode": "NL"
            },
            {
                "name": "Paterswolde",
                "countryCode": "NL"
            },
            {
                "name": "Peelo",
                "countryCode": "NL"
            },
            {
                "name": "Peize",
                "countryCode": "NL"
            },
            {
                "name": "Roden",
                "countryCode": "NL"
            },
            {
                "name": "Rolde",
                "countryCode": "NL"
            },
            {
                "name": "Ruinen",
                "countryCode": "NL"
            },
            {
                "name": "Ruinerwold",
                "countryCode": "NL"
            },
            {
                "name": "Schoonebeek",
                "countryCode": "NL"
            },
            {
                "name": "Schoonoord",
                "countryCode": "NL"
            },
            {
                "name": "Sleen",
                "countryCode": "NL"
            },
            {
                "name": "Tweede Exloërmond",
                "countryCode": "NL"
            },
            {
                "name": "Tynaarlo",
                "countryCode": "NL"
            },
            {
                "name": "Uffelte",
                "countryCode": "NL"
            },
            {
                "name": "Valthe",
                "countryCode": "NL"
            },
            {
                "name": "Valthermond",
                "countryCode": "NL"
            },
            {
                "name": "Veenoord",
                "countryCode": "NL"
            },
            {
                "name": "Vledder",
                "countryCode": "NL"
            },
            {
                "name": "Vries",
                "countryCode": "NL"
            },
            {
                "name": "Weiteveen",
                "countryCode": "NL"
            },
            {
                "name": "Westerbork",
                "countryCode": "NL"
            },
            {
                "name": "Westlaren",
                "countryCode": "NL"
            },
            {
                "name": "Wolfsbos",
                "countryCode": "NL"
            },
            {
                "name": "Zuidlaren",
                "countryCode": "NL"
            },
            {
                "name": "Almere Stad",
                "countryCode": "NL"
            },
            {
                "name": "Biddinghuizen",
                "countryCode": "NL"
            },
            {
                "name": "Dronten",
                "countryCode": "NL"
            },
            {
                "name": "Emmeloord",
                "countryCode": "NL"
            },
            {
                "name": "Ens",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Almere",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dronten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lelystad",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Noordoostpolder",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Urk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zeewolde",
                "countryCode": "NL"
            },
            {
                "name": "Lelystad",
                "countryCode": "NL"
            },
            {
                "name": "Urk",
                "countryCode": "NL"
            },
            {
                "name": "Zeewolde",
                "countryCode": "NL"
            },
            {
                "name": "Akkrum",
                "countryCode": "NL"
            },
            {
                "name": "Aldeboarn",
                "countryCode": "NL"
            },
            {
                "name": "Aldlân-Oost",
                "countryCode": "NL"
            },
            {
                "name": "Appelscha",
                "countryCode": "NL"
            },
            {
                "name": "Augustinusga",
                "countryCode": "NL"
            },
            {
                "name": "Bakhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Bakkeveen",
                "countryCode": "NL"
            },
            {
                "name": "Balk",
                "countryCode": "NL"
            },
            {
                "name": "Beetsterzwaag",
                "countryCode": "NL"
            },
            {
                "name": "Berltsum",
                "countryCode": "NL"
            },
            {
                "name": "Bilgaard",
                "countryCode": "NL"
            },
            {
                "name": "Bolsward",
                "countryCode": "NL"
            },
            {
                "name": "Boornbergum",
                "countryCode": "NL"
            },
            {
                "name": "Broeksterwâld",
                "countryCode": "NL"
            },
            {
                "name": "Buitenpost",
                "countryCode": "NL"
            },
            {
                "name": "Burdaard",
                "countryCode": "NL"
            },
            {
                "name": "Burgum",
                "countryCode": "NL"
            },
            {
                "name": "Camminghaburen",
                "countryCode": "NL"
            },
            {
                "name": "Damwâld",
                "countryCode": "NL"
            },
            {
                "name": "De Domp",
                "countryCode": "NL"
            },
            {
                "name": "De Drait",
                "countryCode": "NL"
            },
            {
                "name": "De Fryske Marren",
                "countryCode": "NL"
            },
            {
                "name": "De Greiden",
                "countryCode": "NL"
            },
            {
                "name": "De Knipe",
                "countryCode": "NL"
            },
            {
                "name": "De Westereen",
                "countryCode": "NL"
            },
            {
                "name": "Dokkum",
                "countryCode": "NL"
            },
            {
                "name": "Donkerbroek",
                "countryCode": "NL"
            },
            {
                "name": "Drachten",
                "countryCode": "NL"
            },
            {
                "name": "Drachtstercompagnie",
                "countryCode": "NL"
            },
            {
                "name": "Drogeham",
                "countryCode": "NL"
            },
            {
                "name": "Dronryp",
                "countryCode": "NL"
            },
            {
                "name": "Eastermar",
                "countryCode": "NL"
            },
            {
                "name": "Echtenerbrug",
                "countryCode": "NL"
            },
            {
                "name": "Feanwâlden",
                "countryCode": "NL"
            },
            {
                "name": "Ferwert",
                "countryCode": "NL"
            },
            {
                "name": "Franeker",
                "countryCode": "NL"
            },
            {
                "name": "Garyp",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Achtkarspelen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ameland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dantumadiel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Harlingen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heerenveen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leeuwarden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ooststellingwerf",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Opsterland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Schiermonnikoog",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Smallingerland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Terschelling",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tytsjerksteradiel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Vlieland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Weststellingwerf",
                "countryCode": "NL"
            },
            {
                "name": "Gorredijk",
                "countryCode": "NL"
            },
            {
                "name": "Goutum",
                "countryCode": "NL"
            },
            {
                "name": "Grou",
                "countryCode": "NL"
            },
            {
                "name": "Gytsjerk",
                "countryCode": "NL"
            },
            {
                "name": "Hallum",
                "countryCode": "NL"
            },
            {
                "name": "Harkema",
                "countryCode": "NL"
            },
            {
                "name": "Harlingen",
                "countryCode": "NL"
            },
            {
                "name": "Haulerwijk",
                "countryCode": "NL"
            },
            {
                "name": "Heechterp",
                "countryCode": "NL"
            },
            {
                "name": "Heeg",
                "countryCode": "NL"
            },
            {
                "name": "Heerenveen",
                "countryCode": "NL"
            },
            {
                "name": "Hoek",
                "countryCode": "NL"
            },
            {
                "name": "Hollum",
                "countryCode": "NL"
            },
            {
                "name": "Holwerd",
                "countryCode": "NL"
            },
            {
                "name": "Huizum",
                "countryCode": "NL"
            },
            {
                "name": "Hurdegaryp",
                "countryCode": "NL"
            },
            {
                "name": "IJlst",
                "countryCode": "NL"
            },
            {
                "name": "Jirnsum",
                "countryCode": "NL"
            },
            {
                "name": "Joure",
                "countryCode": "NL"
            },
            {
                "name": "Jubbega",
                "countryCode": "NL"
            },
            {
                "name": "Kollum",
                "countryCode": "NL"
            },
            {
                "name": "Kollumerzwaag",
                "countryCode": "NL"
            },
            {
                "name": "Kootstertille",
                "countryCode": "NL"
            },
            {
                "name": "Koudum",
                "countryCode": "NL"
            },
            {
                "name": "Langweer",
                "countryCode": "NL"
            },
            {
                "name": "Leeuwarden",
                "countryCode": "NL"
            },
            {
                "name": "Lemmer",
                "countryCode": "NL"
            },
            {
                "name": "Makkum",
                "countryCode": "NL"
            },
            {
                "name": "Mantgum",
                "countryCode": "NL"
            },
            {
                "name": "Marrum",
                "countryCode": "NL"
            },
            {
                "name": "Marsum",
                "countryCode": "NL"
            },
            {
                "name": "Menaam",
                "countryCode": "NL"
            },
            {
                "name": "Minnertsga",
                "countryCode": "NL"
            },
            {
                "name": "Nes",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwehorne",
                "countryCode": "NL"
            },
            {
                "name": "Noardburgum",
                "countryCode": "NL"
            },
            {
                "name": "Noordwolde",
                "countryCode": "NL"
            },
            {
                "name": "Oentsjerk",
                "countryCode": "NL"
            },
            {
                "name": "Oldeberkoop",
                "countryCode": "NL"
            },
            {
                "name": "Oost-Vlieland",
                "countryCode": "NL"
            },
            {
                "name": "Oosterwolde",
                "countryCode": "NL"
            },
            {
                "name": "Opeinde",
                "countryCode": "NL"
            },
            {
                "name": "Oppenhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Oranjewoud",
                "countryCode": "NL"
            },
            {
                "name": "Oudega",
                "countryCode": "NL"
            },
            {
                "name": "Oudehaske",
                "countryCode": "NL"
            },
            {
                "name": "Oudemirdum",
                "countryCode": "NL"
            },
            {
                "name": "Oudeschoot",
                "countryCode": "NL"
            },
            {
                "name": "Rottevalle",
                "countryCode": "NL"
            },
            {
                "name": "Scharnegoutum",
                "countryCode": "NL"
            },
            {
                "name": "Schiermonnikoog",
                "countryCode": "NL"
            },
            {
                "name": "Sexbierum",
                "countryCode": "NL"
            },
            {
                "name": "Sint Annaparochie",
                "countryCode": "NL"
            },
            {
                "name": "Sint Jacobiparochie",
                "countryCode": "NL"
            },
            {
                "name": "Sint Nicolaasga",
                "countryCode": "NL"
            },
            {
                "name": "Sintjohannesga",
                "countryCode": "NL"
            },
            {
                "name": "Sneek",
                "countryCode": "NL"
            },
            {
                "name": "Stadsfenne",
                "countryCode": "NL"
            },
            {
                "name": "Stiens",
                "countryCode": "NL"
            },
            {
                "name": "Surhuisterveen",
                "countryCode": "NL"
            },
            {
                "name": "Sûdwest Fryslân",
                "countryCode": "NL"
            },
            {
                "name": "Ternaard",
                "countryCode": "NL"
            },
            {
                "name": "Tijnje",
                "countryCode": "NL"
            },
            {
                "name": "Tinga",
                "countryCode": "NL"
            },
            {
                "name": "Twijzel",
                "countryCode": "NL"
            },
            {
                "name": "Twijzelerheide",
                "countryCode": "NL"
            },
            {
                "name": "Tytsjerk",
                "countryCode": "NL"
            },
            {
                "name": "Tzummarum",
                "countryCode": "NL"
            },
            {
                "name": "Ureterp",
                "countryCode": "NL"
            },
            {
                "name": "Waadhoeke",
                "countryCode": "NL"
            },
            {
                "name": "Warga",
                "countryCode": "NL"
            },
            {
                "name": "West-Terschelling",
                "countryCode": "NL"
            },
            {
                "name": "Westeinde",
                "countryCode": "NL"
            },
            {
                "name": "Wijnjewoude",
                "countryCode": "NL"
            },
            {
                "name": "Wirdum",
                "countryCode": "NL"
            },
            {
                "name": "Witmarsum",
                "countryCode": "NL"
            },
            {
                "name": "Wolvega",
                "countryCode": "NL"
            },
            {
                "name": "Wommels",
                "countryCode": "NL"
            },
            {
                "name": "Workum",
                "countryCode": "NL"
            },
            {
                "name": "Woudsend",
                "countryCode": "NL"
            },
            {
                "name": "Zuiderburen",
                "countryCode": "NL"
            },
            {
                "name": "'s-Heerenberg",
                "countryCode": "NL"
            },
            {
                "name": "Aalst",
                "countryCode": "NL"
            },
            {
                "name": "Aalten",
                "countryCode": "NL"
            },
            {
                "name": "Afferden",
                "countryCode": "NL"
            },
            {
                "name": "Alverna",
                "countryCode": "NL"
            },
            {
                "name": "Ammerzoden",
                "countryCode": "NL"
            },
            {
                "name": "Andelst",
                "countryCode": "NL"
            },
            {
                "name": "Angeren",
                "countryCode": "NL"
            },
            {
                "name": "Angerlo",
                "countryCode": "NL"
            },
            {
                "name": "Anklaar",
                "countryCode": "NL"
            },
            {
                "name": "Apeldoorn",
                "countryCode": "NL"
            },
            {
                "name": "Arnhem",
                "countryCode": "NL"
            },
            {
                "name": "Babberich",
                "countryCode": "NL"
            },
            {
                "name": "Bakenberg",
                "countryCode": "NL"
            },
            {
                "name": "Barneveld",
                "countryCode": "NL"
            },
            {
                "name": "Beek gem Montferland",
                "countryCode": "NL"
            },
            {
                "name": "Beekbergen",
                "countryCode": "NL"
            },
            {
                "name": "Beesd",
                "countryCode": "NL"
            },
            {
                "name": "Beltrum",
                "countryCode": "NL"
            },
            {
                "name": "Bemmel",
                "countryCode": "NL"
            },
            {
                "name": "Bennekom",
                "countryCode": "NL"
            },
            {
                "name": "Berg en Bos",
                "countryCode": "NL"
            },
            {
                "name": "Berg en Dal",
                "countryCode": "NL"
            },
            {
                "name": "Bergharen",
                "countryCode": "NL"
            },
            {
                "name": "Berkelland",
                "countryCode": "NL"
            },
            {
                "name": "Beuningen",
                "countryCode": "NL"
            },
            {
                "name": "Beusichem",
                "countryCode": "NL"
            },
            {
                "name": "Binnenstad",
                "countryCode": "NL"
            },
            {
                "name": "Borculo",
                "countryCode": "NL"
            },
            {
                "name": "Brakel",
                "countryCode": "NL"
            },
            {
                "name": "Brakkenstein",
                "countryCode": "NL"
            },
            {
                "name": "Bredevoort",
                "countryCode": "NL"
            },
            {
                "name": "Bredeweg",
                "countryCode": "NL"
            },
            {
                "name": "Brinkhorst",
                "countryCode": "NL"
            },
            {
                "name": "Bruchem",
                "countryCode": "NL"
            },
            {
                "name": "Brummelhof",
                "countryCode": "NL"
            },
            {
                "name": "Brummen",
                "countryCode": "NL"
            },
            {
                "name": "Burgemeesterswijk",
                "countryCode": "NL"
            },
            {
                "name": "Componistenkwartier",
                "countryCode": "NL"
            },
            {
                "name": "Corlaer",
                "countryCode": "NL"
            },
            {
                "name": "Culemborg",
                "countryCode": "NL"
            },
            {
                "name": "De Bouwhof",
                "countryCode": "NL"
            },
            {
                "name": "De Haven",
                "countryCode": "NL"
            },
            {
                "name": "De Heeze",
                "countryCode": "NL"
            },
            {
                "name": "De Mheen",
                "countryCode": "NL"
            },
            {
                "name": "Deest",
                "countryCode": "NL"
            },
            {
                "name": "Deil",
                "countryCode": "NL"
            },
            {
                "name": "Dieren",
                "countryCode": "NL"
            },
            {
                "name": "Doesburg",
                "countryCode": "NL"
            },
            {
                "name": "Doetinchem",
                "countryCode": "NL"
            },
            {
                "name": "Doornenburg",
                "countryCode": "NL"
            },
            {
                "name": "Doornspijk",
                "countryCode": "NL"
            },
            {
                "name": "Doornsteeg",
                "countryCode": "NL"
            },
            {
                "name": "Doorwerth",
                "countryCode": "NL"
            },
            {
                "name": "Driehuizen",
                "countryCode": "NL"
            },
            {
                "name": "Driel",
                "countryCode": "NL"
            },
            {
                "name": "Drumpt",
                "countryCode": "NL"
            },
            {
                "name": "Druten",
                "countryCode": "NL"
            },
            {
                "name": "Duiven",
                "countryCode": "NL"
            },
            {
                "name": "Ede",
                "countryCode": "NL"
            },
            {
                "name": "Ederveen",
                "countryCode": "NL"
            },
            {
                "name": "Eefde",
                "countryCode": "NL"
            },
            {
                "name": "Eerbeek",
                "countryCode": "NL"
            },
            {
                "name": "Eibergen",
                "countryCode": "NL"
            },
            {
                "name": "Elburg",
                "countryCode": "NL"
            },
            {
                "name": "Elden",
                "countryCode": "NL"
            },
            {
                "name": "Ellecom",
                "countryCode": "NL"
            },
            {
                "name": "Elspeet",
                "countryCode": "NL"
            },
            {
                "name": "Elst",
                "countryCode": "NL"
            },
            {
                "name": "Emst",
                "countryCode": "NL"
            },
            {
                "name": "Epe",
                "countryCode": "NL"
            },
            {
                "name": "Epse",
                "countryCode": "NL"
            },
            {
                "name": "Ermelo",
                "countryCode": "NL"
            },
            {
                "name": "Etten",
                "countryCode": "NL"
            },
            {
                "name": "Gameren",
                "countryCode": "NL"
            },
            {
                "name": "Garderen",
                "countryCode": "NL"
            },
            {
                "name": "Geitenkamp",
                "countryCode": "NL"
            },
            {
                "name": "Geldermalsen",
                "countryCode": "NL"
            },
            {
                "name": "Geldermalsen-West",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Aalten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Apeldoorn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Arnhem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Barneveld",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Berg en Dal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Beuningen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bronckhorst",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Brummen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Buren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Culemborg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Doesburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Doetinchem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Druten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Duiven",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ede",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Elburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Epe",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ermelo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Harderwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hattem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heerde",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heumen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lingewaard",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lochem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Maasdriel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Montferland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Neder-Betuwe",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nijkerk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nijmegen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nunspeet",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oldebroek",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Overbetuwe",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Putten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Renkum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rheden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rozendaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Scherpenzeel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tiel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Voorst",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wageningen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente West Maas en Waal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Westervoort",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wijchen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Winterswijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zaltbommel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zevenaar",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zutphen",
                "countryCode": "NL"
            },
            {
                "name": "Gendringen",
                "countryCode": "NL"
            },
            {
                "name": "Gendt",
                "countryCode": "NL"
            },
            {
                "name": "Giesbeek",
                "countryCode": "NL"
            },
            {
                "name": "Gorssel",
                "countryCode": "NL"
            },
            {
                "name": "Groesbeek",
                "countryCode": "NL"
            },
            {
                "name": "Groessen",
                "countryCode": "NL"
            },
            {
                "name": "Gulden Bodem",
                "countryCode": "NL"
            },
            {
                "name": "Haaften",
                "countryCode": "NL"
            },
            {
                "name": "Haalderen",
                "countryCode": "NL"
            },
            {
                "name": "Harderwijk",
                "countryCode": "NL"
            },
            {
                "name": "Harskamp",
                "countryCode": "NL"
            },
            {
                "name": "Hatert",
                "countryCode": "NL"
            },
            {
                "name": "Hattem",
                "countryCode": "NL"
            },
            {
                "name": "Hattemerbroek",
                "countryCode": "NL"
            },
            {
                "name": "Hedel",
                "countryCode": "NL"
            },
            {
                "name": "Heelsum",
                "countryCode": "NL"
            },
            {
                "name": "Heerde",
                "countryCode": "NL"
            },
            {
                "name": "Heerewaarden",
                "countryCode": "NL"
            },
            {
                "name": "Hees",
                "countryCode": "NL"
            },
            {
                "name": "Hengelo",
                "countryCode": "NL"
            },
            {
                "name": "Hengstdal",
                "countryCode": "NL"
            },
            {
                "name": "Herwijnen",
                "countryCode": "NL"
            },
            {
                "name": "Het Loo",
                "countryCode": "NL"
            },
            {
                "name": "Heteren",
                "countryCode": "NL"
            },
            {
                "name": "Heumen",
                "countryCode": "NL"
            },
            {
                "name": "Hoogkamp",
                "countryCode": "NL"
            },
            {
                "name": "Horssen",
                "countryCode": "NL"
            },
            {
                "name": "Hummelo",
                "countryCode": "NL"
            },
            {
                "name": "Hunnerberg",
                "countryCode": "NL"
            },
            {
                "name": "Ingen",
                "countryCode": "NL"
            },
            {
                "name": "Keijenborg",
                "countryCode": "NL"
            },
            {
                "name": "Kerkdriel",
                "countryCode": "NL"
            },
            {
                "name": "Kerschoten",
                "countryCode": "NL"
            },
            {
                "name": "Kesteren",
                "countryCode": "NL"
            },
            {
                "name": "Kootwijkerbroek",
                "countryCode": "NL"
            },
            {
                "name": "Laren",
                "countryCode": "NL"
            },
            {
                "name": "Leesten",
                "countryCode": "NL"
            },
            {
                "name": "Lent",
                "countryCode": "NL"
            },
            {
                "name": "Leuth",
                "countryCode": "NL"
            },
            {
                "name": "Lichtenvoorde",
                "countryCode": "NL"
            },
            {
                "name": "Lienden",
                "countryCode": "NL"
            },
            {
                "name": "Lindenholt",
                "countryCode": "NL"
            },
            {
                "name": "Lobith",
                "countryCode": "NL"
            },
            {
                "name": "Lochem",
                "countryCode": "NL"
            },
            {
                "name": "Loenen",
                "countryCode": "NL"
            },
            {
                "name": "Lunteren",
                "countryCode": "NL"
            },
            {
                "name": "Malburgen West",
                "countryCode": "NL"
            },
            {
                "name": "Matendonk",
                "countryCode": "NL"
            },
            {
                "name": "Matendreef",
                "countryCode": "NL"
            },
            {
                "name": "Matengaarde",
                "countryCode": "NL"
            },
            {
                "name": "Matenhoeve",
                "countryCode": "NL"
            },
            {
                "name": "Matenhorst",
                "countryCode": "NL"
            },
            {
                "name": "Matenveld",
                "countryCode": "NL"
            },
            {
                "name": "Maurik",
                "countryCode": "NL"
            },
            {
                "name": "Meteren",
                "countryCode": "NL"
            },
            {
                "name": "Nederhemert",
                "countryCode": "NL"
            },
            {
                "name": "Nederhemert-Noord",
                "countryCode": "NL"
            },
            {
                "name": "Nederwoud",
                "countryCode": "NL"
            },
            {
                "name": "Neede",
                "countryCode": "NL"
            },
            {
                "name": "Nijkerk",
                "countryCode": "NL"
            },
            {
                "name": "Nijkerkerveen",
                "countryCode": "NL"
            },
            {
                "name": "Nijmegen",
                "countryCode": "NL"
            },
            {
                "name": "Nunspeet",
                "countryCode": "NL"
            },
            {
                "name": "Ochten",
                "countryCode": "NL"
            },
            {
                "name": "Oldebroek",
                "countryCode": "NL"
            },
            {
                "name": "Ooij",
                "countryCode": "NL"
            },
            {
                "name": "Oost Gelre",
                "countryCode": "NL"
            },
            {
                "name": "Oostendorp",
                "countryCode": "NL"
            },
            {
                "name": "Oosterbeek",
                "countryCode": "NL"
            },
            {
                "name": "Oosterhout",
                "countryCode": "NL"
            },
            {
                "name": "Ooy",
                "countryCode": "NL"
            },
            {
                "name": "Ooyerhoek",
                "countryCode": "NL"
            },
            {
                "name": "Ophemert",
                "countryCode": "NL"
            },
            {
                "name": "Opheusden",
                "countryCode": "NL"
            },
            {
                "name": "Opijnen",
                "countryCode": "NL"
            },
            {
                "name": "Orden",
                "countryCode": "NL"
            },
            {
                "name": "Otterlo",
                "countryCode": "NL"
            },
            {
                "name": "Oude IJsselstreek",
                "countryCode": "NL"
            },
            {
                "name": "Overasselt",
                "countryCode": "NL"
            },
            {
                "name": "Pannerden",
                "countryCode": "NL"
            },
            {
                "name": "Puiflijk",
                "countryCode": "NL"
            },
            {
                "name": "Putten",
                "countryCode": "NL"
            },
            {
                "name": "Renkum",
                "countryCode": "NL"
            },
            {
                "name": "Rheden",
                "countryCode": "NL"
            },
            {
                "name": "Rivierenkwartier",
                "countryCode": "NL"
            },
            {
                "name": "Rossum",
                "countryCode": "NL"
            },
            {
                "name": "Rozendaal",
                "countryCode": "NL"
            },
            {
                "name": "Ruurlo",
                "countryCode": "NL"
            },
            {
                "name": "Scherpenzeel",
                "countryCode": "NL"
            },
            {
                "name": "Silvolde",
                "countryCode": "NL"
            },
            {
                "name": "Sluisoord",
                "countryCode": "NL"
            },
            {
                "name": "Spainkbos",
                "countryCode": "NL"
            },
            {
                "name": "Sprengenbos",
                "countryCode": "NL"
            },
            {
                "name": "Sprengenweg-Noord",
                "countryCode": "NL"
            },
            {
                "name": "Sprenkelaar",
                "countryCode": "NL"
            },
            {
                "name": "Staatsliedenkwartier",
                "countryCode": "NL"
            },
            {
                "name": "Steenderen",
                "countryCode": "NL"
            },
            {
                "name": "Stegeslag",
                "countryCode": "NL"
            },
            {
                "name": "Sterrenberg",
                "countryCode": "NL"
            },
            {
                "name": "Terborg",
                "countryCode": "NL"
            },
            {
                "name": "Terschuur",
                "countryCode": "NL"
            },
            {
                "name": "Terwolde",
                "countryCode": "NL"
            },
            {
                "name": "Tiel",
                "countryCode": "NL"
            },
            {
                "name": "Tolkamer",
                "countryCode": "NL"
            },
            {
                "name": "Tricht",
                "countryCode": "NL"
            },
            {
                "name": "Twello",
                "countryCode": "NL"
            },
            {
                "name": "Uddel",
                "countryCode": "NL"
            },
            {
                "name": "Ugchelen",
                "countryCode": "NL"
            },
            {
                "name": "Vaassen",
                "countryCode": "NL"
            },
            {
                "name": "Valburg",
                "countryCode": "NL"
            },
            {
                "name": "Valendries",
                "countryCode": "NL"
            },
            {
                "name": "Varsseveld",
                "countryCode": "NL"
            },
            {
                "name": "Velp",
                "countryCode": "NL"
            },
            {
                "name": "Vogelkwartier",
                "countryCode": "NL"
            },
            {
                "name": "Voorst",
                "countryCode": "NL"
            },
            {
                "name": "Voorthuizen",
                "countryCode": "NL"
            },
            {
                "name": "Vorden",
                "countryCode": "NL"
            },
            {
                "name": "Vredenburg",
                "countryCode": "NL"
            },
            {
                "name": "Vuren",
                "countryCode": "NL"
            },
            {
                "name": "Waardenburg",
                "countryCode": "NL"
            },
            {
                "name": "Wageningen",
                "countryCode": "NL"
            },
            {
                "name": "Wapenveld",
                "countryCode": "NL"
            },
            {
                "name": "Warnsveld",
                "countryCode": "NL"
            },
            {
                "name": "Wekerom",
                "countryCode": "NL"
            },
            {
                "name": "Welgelegen",
                "countryCode": "NL"
            },
            {
                "name": "West Betuwe",
                "countryCode": "NL"
            },
            {
                "name": "Westeneng",
                "countryCode": "NL"
            },
            {
                "name": "Westenenk",
                "countryCode": "NL"
            },
            {
                "name": "Westervoort",
                "countryCode": "NL"
            },
            {
                "name": "Wijchen",
                "countryCode": "NL"
            },
            {
                "name": "Winkewijert",
                "countryCode": "NL"
            },
            {
                "name": "Winterswijk",
                "countryCode": "NL"
            },
            {
                "name": "Wisch",
                "countryCode": "NL"
            },
            {
                "name": "Wolfheze",
                "countryCode": "NL"
            },
            {
                "name": "Woudhuis",
                "countryCode": "NL"
            },
            {
                "name": "Zaltbommel",
                "countryCode": "NL"
            },
            {
                "name": "Zeddam",
                "countryCode": "NL"
            },
            {
                "name": "Zelhem",
                "countryCode": "NL"
            },
            {
                "name": "Zetten",
                "countryCode": "NL"
            },
            {
                "name": "Zevenaar",
                "countryCode": "NL"
            },
            {
                "name": "Zevenhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Zoelen",
                "countryCode": "NL"
            },
            {
                "name": "Zonnemaat",
                "countryCode": "NL"
            },
            {
                "name": "Zuilichem",
                "countryCode": "NL"
            },
            {
                "name": "Zutphen",
                "countryCode": "NL"
            },
            {
                "name": "Aduard",
                "countryCode": "NL"
            },
            {
                "name": "Appingedam",
                "countryCode": "NL"
            },
            {
                "name": "Baflo",
                "countryCode": "NL"
            },
            {
                "name": "Bedum",
                "countryCode": "NL"
            },
            {
                "name": "Bellingwolde",
                "countryCode": "NL"
            },
            {
                "name": "Blijham",
                "countryCode": "NL"
            },
            {
                "name": "Coendersborg",
                "countryCode": "NL"
            },
            {
                "name": "De Wijert",
                "countryCode": "NL"
            },
            {
                "name": "Delfzijl",
                "countryCode": "NL"
            },
            {
                "name": "Eenrum",
                "countryCode": "NL"
            },
            {
                "name": "Eexta",
                "countryCode": "NL"
            },
            {
                "name": "Farmsum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente  Oldambt",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Appingedam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Delfzijl",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Groningen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Loppersum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Pekela",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Stadskanaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Veendam",
                "countryCode": "NL"
            },
            {
                "name": "Glimmen",
                "countryCode": "NL"
            },
            {
                "name": "Grijpskerk",
                "countryCode": "NL"
            },
            {
                "name": "Groningen",
                "countryCode": "NL"
            },
            {
                "name": "Grootegast",
                "countryCode": "NL"
            },
            {
                "name": "Haren",
                "countryCode": "NL"
            },
            {
                "name": "Harkstede",
                "countryCode": "NL"
            },
            {
                "name": "Heiligerlee",
                "countryCode": "NL"
            },
            {
                "name": "Helpman",
                "countryCode": "NL"
            },
            {
                "name": "Hoogezand",
                "countryCode": "NL"
            },
            {
                "name": "Korrewegwijk",
                "countryCode": "NL"
            },
            {
                "name": "Leek",
                "countryCode": "NL"
            },
            {
                "name": "Leens",
                "countryCode": "NL"
            },
            {
                "name": "Loppersum",
                "countryCode": "NL"
            },
            {
                "name": "Marum",
                "countryCode": "NL"
            },
            {
                "name": "Meeden",
                "countryCode": "NL"
            },
            {
                "name": "Middelstum",
                "countryCode": "NL"
            },
            {
                "name": "Midden-Groningen",
                "countryCode": "NL"
            },
            {
                "name": "Midwolda",
                "countryCode": "NL"
            },
            {
                "name": "Niekerk",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwe Pekela",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwolda",
                "countryCode": "NL"
            },
            {
                "name": "Noordbroek",
                "countryCode": "NL"
            },
            {
                "name": "Noordhorn",
                "countryCode": "NL"
            },
            {
                "name": "Obergum",
                "countryCode": "NL"
            },
            {
                "name": "Oldehove",
                "countryCode": "NL"
            },
            {
                "name": "Ommelanderwijk",
                "countryCode": "NL"
            },
            {
                "name": "Onstwedde",
                "countryCode": "NL"
            },
            {
                "name": "Oosterhoogebrug",
                "countryCode": "NL"
            },
            {
                "name": "Oosterpark",
                "countryCode": "NL"
            },
            {
                "name": "Oude Pekela",
                "countryCode": "NL"
            },
            {
                "name": "Reitdiep",
                "countryCode": "NL"
            },
            {
                "name": "Sappemeer",
                "countryCode": "NL"
            },
            {
                "name": "Scheemda",
                "countryCode": "NL"
            },
            {
                "name": "Schildwolde",
                "countryCode": "NL"
            },
            {
                "name": "Sellingen",
                "countryCode": "NL"
            },
            {
                "name": "Selwerd",
                "countryCode": "NL"
            },
            {
                "name": "Siddeburen",
                "countryCode": "NL"
            },
            {
                "name": "Slochteren",
                "countryCode": "NL"
            },
            {
                "name": "Spijk",
                "countryCode": "NL"
            },
            {
                "name": "Stadskanaal",
                "countryCode": "NL"
            },
            {
                "name": "Ten Boer",
                "countryCode": "NL"
            },
            {
                "name": "Ter Apel",
                "countryCode": "NL"
            },
            {
                "name": "Tuikwerd",
                "countryCode": "NL"
            },
            {
                "name": "Uithuizen",
                "countryCode": "NL"
            },
            {
                "name": "Uithuizermeeden",
                "countryCode": "NL"
            },
            {
                "name": "Ulrum",
                "countryCode": "NL"
            },
            {
                "name": "Usquert",
                "countryCode": "NL"
            },
            {
                "name": "Veendam",
                "countryCode": "NL"
            },
            {
                "name": "Vlagtwedde",
                "countryCode": "NL"
            },
            {
                "name": "Wagenborgen",
                "countryCode": "NL"
            },
            {
                "name": "Warffum",
                "countryCode": "NL"
            },
            {
                "name": "Westerlee",
                "countryCode": "NL"
            },
            {
                "name": "Westerwolde",
                "countryCode": "NL"
            },
            {
                "name": "Wildervank",
                "countryCode": "NL"
            },
            {
                "name": "Winschoten",
                "countryCode": "NL"
            },
            {
                "name": "Winsum",
                "countryCode": "NL"
            },
            {
                "name": "Zoutkamp",
                "countryCode": "NL"
            },
            {
                "name": "Zuidbroek",
                "countryCode": "NL"
            },
            {
                "name": "Zuidhorn",
                "countryCode": "NL"
            },
            {
                "name": "Abdissenbosch",
                "countryCode": "NL"
            },
            {
                "name": "Amby",
                "countryCode": "NL"
            },
            {
                "name": "America",
                "countryCode": "NL"
            },
            {
                "name": "Amstenrade",
                "countryCode": "NL"
            },
            {
                "name": "Arcen",
                "countryCode": "NL"
            },
            {
                "name": "Baarlo",
                "countryCode": "NL"
            },
            {
                "name": "Banholt",
                "countryCode": "NL"
            },
            {
                "name": "Beegden",
                "countryCode": "NL"
            },
            {
                "name": "Beek",
                "countryCode": "NL"
            },
            {
                "name": "Beersdal",
                "countryCode": "NL"
            },
            {
                "name": "Beesel",
                "countryCode": "NL"
            },
            {
                "name": "Belfort",
                "countryCode": "NL"
            },
            {
                "name": "Berg",
                "countryCode": "NL"
            },
            {
                "name": "Beringe",
                "countryCode": "NL"
            },
            {
                "name": "Biesland",
                "countryCode": "NL"
            },
            {
                "name": "Bleijerheide",
                "countryCode": "NL"
            },
            {
                "name": "Blitterswijck",
                "countryCode": "NL"
            },
            {
                "name": "Bocholtz",
                "countryCode": "NL"
            },
            {
                "name": "Borgharen",
                "countryCode": "NL"
            },
            {
                "name": "Born",
                "countryCode": "NL"
            },
            {
                "name": "Brabander",
                "countryCode": "NL"
            },
            {
                "name": "Brachterbeek",
                "countryCode": "NL"
            },
            {
                "name": "Broekhem",
                "countryCode": "NL"
            },
            {
                "name": "Broeksittard",
                "countryCode": "NL"
            },
            {
                "name": "Brukske",
                "countryCode": "NL"
            },
            {
                "name": "Brunssum",
                "countryCode": "NL"
            },
            {
                "name": "Buchten",
                "countryCode": "NL"
            },
            {
                "name": "Budschop",
                "countryCode": "NL"
            },
            {
                "name": "Bunde",
                "countryCode": "NL"
            },
            {
                "name": "Chevremont",
                "countryCode": "NL"
            },
            {
                "name": "De Heeg",
                "countryCode": "NL"
            },
            {
                "name": "Doenrade",
                "countryCode": "NL"
            },
            {
                "name": "Egchel",
                "countryCode": "NL"
            },
            {
                "name": "Eijsden",
                "countryCode": "NL"
            },
            {
                "name": "Eijsden-Margraten",
                "countryCode": "NL"
            },
            {
                "name": "Einighausen",
                "countryCode": "NL"
            },
            {
                "name": "Elsloo",
                "countryCode": "NL"
            },
            {
                "name": "Geleen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Beek",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Beesel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bergen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Brunssum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Echt-Susteren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Gennep",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heerlen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Horst aan de Maas",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Kerkrade",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leudal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Maasgouw",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Maastricht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Meerssen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Mook en Middelaar",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nederweert",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Peel en Maas",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Roerdalen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Roermond",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Simpelveld",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Sittard-Geleen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Stein",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Vaals",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Venlo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Venray",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Voerendaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Weert",
                "countryCode": "NL"
            },
            {
                "name": "Gennep",
                "countryCode": "NL"
            },
            {
                "name": "Gracht",
                "countryCode": "NL"
            },
            {
                "name": "Grashoek",
                "countryCode": "NL"
            },
            {
                "name": "Grevenbicht",
                "countryCode": "NL"
            },
            {
                "name": "Gronsveld",
                "countryCode": "NL"
            },
            {
                "name": "Gulpen",
                "countryCode": "NL"
            },
            {
                "name": "Gulpen-Wittem",
                "countryCode": "NL"
            },
            {
                "name": "Guttecoven",
                "countryCode": "NL"
            },
            {
                "name": "Haanrade",
                "countryCode": "NL"
            },
            {
                "name": "Haelen",
                "countryCode": "NL"
            },
            {
                "name": "Heel",
                "countryCode": "NL"
            },
            {
                "name": "Heer",
                "countryCode": "NL"
            },
            {
                "name": "Heerlen",
                "countryCode": "NL"
            },
            {
                "name": "Hegelsom",
                "countryCode": "NL"
            },
            {
                "name": "Heide",
                "countryCode": "NL"
            },
            {
                "name": "Heijen",
                "countryCode": "NL"
            },
            {
                "name": "Heksenberg",
                "countryCode": "NL"
            },
            {
                "name": "Helden",
                "countryCode": "NL"
            },
            {
                "name": "Herkenbosch",
                "countryCode": "NL"
            },
            {
                "name": "Herten",
                "countryCode": "NL"
            },
            {
                "name": "Heugem",
                "countryCode": "NL"
            },
            {
                "name": "Heythuysen",
                "countryCode": "NL"
            },
            {
                "name": "Hoensbroek",
                "countryCode": "NL"
            },
            {
                "name": "Holtum",
                "countryCode": "NL"
            },
            {
                "name": "Holz",
                "countryCode": "NL"
            },
            {
                "name": "Hopel",
                "countryCode": "NL"
            },
            {
                "name": "Houthem",
                "countryCode": "NL"
            },
            {
                "name": "Hulsberg",
                "countryCode": "NL"
            },
            {
                "name": "Husken",
                "countryCode": "NL"
            },
            {
                "name": "Kaalheide",
                "countryCode": "NL"
            },
            {
                "name": "Kakert",
                "countryCode": "NL"
            },
            {
                "name": "Keent",
                "countryCode": "NL"
            },
            {
                "name": "Kelpen-Oler",
                "countryCode": "NL"
            },
            {
                "name": "Kerensheide",
                "countryCode": "NL"
            },
            {
                "name": "Kerkrade",
                "countryCode": "NL"
            },
            {
                "name": "Kessel",
                "countryCode": "NL"
            },
            {
                "name": "Klimmen",
                "countryCode": "NL"
            },
            {
                "name": "Koningsbosch",
                "countryCode": "NL"
            },
            {
                "name": "Koningslust",
                "countryCode": "NL"
            },
            {
                "name": "Kruisberg",
                "countryCode": "NL"
            },
            {
                "name": "Kunrade",
                "countryCode": "NL"
            },
            {
                "name": "Landgraaf",
                "countryCode": "NL"
            },
            {
                "name": "Lauradorp",
                "countryCode": "NL"
            },
            {
                "name": "Leeuwen",
                "countryCode": "NL"
            },
            {
                "name": "Leuken",
                "countryCode": "NL"
            },
            {
                "name": "Leunen",
                "countryCode": "NL"
            },
            {
                "name": "Lichtenberg",
                "countryCode": "NL"
            },
            {
                "name": "Limbricht",
                "countryCode": "NL"
            },
            {
                "name": "Limmel",
                "countryCode": "NL"
            },
            {
                "name": "Lindenheuvel",
                "countryCode": "NL"
            },
            {
                "name": "Linne",
                "countryCode": "NL"
            },
            {
                "name": "Lottum",
                "countryCode": "NL"
            },
            {
                "name": "Maasbracht",
                "countryCode": "NL"
            },
            {
                "name": "Maasbree",
                "countryCode": "NL"
            },
            {
                "name": "Maastricht",
                "countryCode": "NL"
            },
            {
                "name": "Malberg",
                "countryCode": "NL"
            },
            {
                "name": "Margraten",
                "countryCode": "NL"
            },
            {
                "name": "Mariarade",
                "countryCode": "NL"
            },
            {
                "name": "Mechelen",
                "countryCode": "NL"
            },
            {
                "name": "Meerlo",
                "countryCode": "NL"
            },
            {
                "name": "Meerssen",
                "countryCode": "NL"
            },
            {
                "name": "Meezenbroek",
                "countryCode": "NL"
            },
            {
                "name": "Meijel",
                "countryCode": "NL"
            },
            {
                "name": "Melderslo",
                "countryCode": "NL"
            },
            {
                "name": "Melick",
                "countryCode": "NL"
            },
            {
                "name": "Merkelbeek",
                "countryCode": "NL"
            },
            {
                "name": "Merselo",
                "countryCode": "NL"
            },
            {
                "name": "Merum",
                "countryCode": "NL"
            },
            {
                "name": "Milsbeek",
                "countryCode": "NL"
            },
            {
                "name": "Moesel",
                "countryCode": "NL"
            },
            {
                "name": "Montfort",
                "countryCode": "NL"
            },
            {
                "name": "Mook",
                "countryCode": "NL"
            },
            {
                "name": "Munstergeleen",
                "countryCode": "NL"
            },
            {
                "name": "Nederweert",
                "countryCode": "NL"
            },
            {
                "name": "Neerbeek",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Lotbroek",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwdorp",
                "countryCode": "NL"
            },
            {
                "name": "Nuth",
                "countryCode": "NL"
            },
            {
                "name": "Obbicht",
                "countryCode": "NL"
            },
            {
                "name": "Offenbeek",
                "countryCode": "NL"
            },
            {
                "name": "Oirlo",
                "countryCode": "NL"
            },
            {
                "name": "Oirsbeek",
                "countryCode": "NL"
            },
            {
                "name": "Oostrum",
                "countryCode": "NL"
            },
            {
                "name": "Ophoven",
                "countryCode": "NL"
            },
            {
                "name": "Ospel",
                "countryCode": "NL"
            },
            {
                "name": "Ottersum",
                "countryCode": "NL"
            },
            {
                "name": "Oud-Caberg",
                "countryCode": "NL"
            },
            {
                "name": "Overhoven",
                "countryCode": "NL"
            },
            {
                "name": "Panningen",
                "countryCode": "NL"
            },
            {
                "name": "Passart",
                "countryCode": "NL"
            },
            {
                "name": "Peij",
                "countryCode": "NL"
            },
            {
                "name": "Posterholt",
                "countryCode": "NL"
            },
            {
                "name": "Pottenberg",
                "countryCode": "NL"
            },
            {
                "name": "Puth",
                "countryCode": "NL"
            },
            {
                "name": "Reuver",
                "countryCode": "NL"
            },
            {
                "name": "Roermond",
                "countryCode": "NL"
            },
            {
                "name": "Roosteren",
                "countryCode": "NL"
            },
            {
                "name": "Rothem",
                "countryCode": "NL"
            },
            {
                "name": "Scharn",
                "countryCode": "NL"
            },
            {
                "name": "Schilberg",
                "countryCode": "NL"
            },
            {
                "name": "Schimmert",
                "countryCode": "NL"
            },
            {
                "name": "Schinnen",
                "countryCode": "NL"
            },
            {
                "name": "Schinveld",
                "countryCode": "NL"
            },
            {
                "name": "Sevenum",
                "countryCode": "NL"
            },
            {
                "name": "Sibbe",
                "countryCode": "NL"
            },
            {
                "name": "Simpelveld",
                "countryCode": "NL"
            },
            {
                "name": "Sint Joost",
                "countryCode": "NL"
            },
            {
                "name": "Sint Odiliënberg",
                "countryCode": "NL"
            },
            {
                "name": "Sittard",
                "countryCode": "NL"
            },
            {
                "name": "Spaubeek",
                "countryCode": "NL"
            },
            {
                "name": "Spekholzerheide",
                "countryCode": "NL"
            },
            {
                "name": "Stadbroek",
                "countryCode": "NL"
            },
            {
                "name": "Stein",
                "countryCode": "NL"
            },
            {
                "name": "Stramproy",
                "countryCode": "NL"
            },
            {
                "name": "Tegelen",
                "countryCode": "NL"
            },
            {
                "name": "Terwinselen",
                "countryCode": "NL"
            },
            {
                "name": "Thorn",
                "countryCode": "NL"
            },
            {
                "name": "Tienray",
                "countryCode": "NL"
            },
            {
                "name": "Ubachsberg",
                "countryCode": "NL"
            },
            {
                "name": "Ulestraten",
                "countryCode": "NL"
            },
            {
                "name": "Urmond",
                "countryCode": "NL"
            },
            {
                "name": "Vaals",
                "countryCode": "NL"
            },
            {
                "name": "Valkenburg",
                "countryCode": "NL"
            },
            {
                "name": "Valkenburg aan de Geul",
                "countryCode": "NL"
            },
            {
                "name": "Veltum",
                "countryCode": "NL"
            },
            {
                "name": "Venlo",
                "countryCode": "NL"
            },
            {
                "name": "Venray",
                "countryCode": "NL"
            },
            {
                "name": "Vijlen",
                "countryCode": "NL"
            },
            {
                "name": "Vlodrop",
                "countryCode": "NL"
            },
            {
                "name": "Voerendaal",
                "countryCode": "NL"
            },
            {
                "name": "Vrieheide",
                "countryCode": "NL"
            },
            {
                "name": "Wanssum",
                "countryCode": "NL"
            },
            {
                "name": "Waubach",
                "countryCode": "NL"
            },
            {
                "name": "Weert",
                "countryCode": "NL"
            },
            {
                "name": "Well",
                "countryCode": "NL"
            },
            {
                "name": "Wellerlooi",
                "countryCode": "NL"
            },
            {
                "name": "Wessem",
                "countryCode": "NL"
            },
            {
                "name": "Wijlre",
                "countryCode": "NL"
            },
            {
                "name": "Wijnandsrade",
                "countryCode": "NL"
            },
            {
                "name": "Wolder",
                "countryCode": "NL"
            },
            {
                "name": "Ysselsteyn",
                "countryCode": "NL"
            },
            {
                "name": "'s Gravenmoer",
                "countryCode": "NL"
            },
            {
                "name": "'s-Hertogenbosch",
                "countryCode": "NL"
            },
            {
                "name": "'t Hofke",
                "countryCode": "NL"
            },
            {
                "name": "Aalburg",
                "countryCode": "NL"
            },
            {
                "name": "Aalst",
                "countryCode": "NL"
            },
            {
                "name": "Aarle-Rixtel",
                "countryCode": "NL"
            },
            {
                "name": "Abcoven",
                "countryCode": "NL"
            },
            {
                "name": "Akert",
                "countryCode": "NL"
            },
            {
                "name": "Almkerk",
                "countryCode": "NL"
            },
            {
                "name": "Alphen",
                "countryCode": "NL"
            },
            {
                "name": "Andel",
                "countryCode": "NL"
            },
            {
                "name": "Asten",
                "countryCode": "NL"
            },
            {
                "name": "Baardwijk",
                "countryCode": "NL"
            },
            {
                "name": "Baarle-Nassau",
                "countryCode": "NL"
            },
            {
                "name": "Bakel",
                "countryCode": "NL"
            },
            {
                "name": "Bavel",
                "countryCode": "NL"
            },
            {
                "name": "Beek",
                "countryCode": "NL"
            },
            {
                "name": "Beers",
                "countryCode": "NL"
            },
            {
                "name": "Belcrum",
                "countryCode": "NL"
            },
            {
                "name": "Bergeijk",
                "countryCode": "NL"
            },
            {
                "name": "Bergen op Zoom",
                "countryCode": "NL"
            },
            {
                "name": "Berghem",
                "countryCode": "NL"
            },
            {
                "name": "Berlicum",
                "countryCode": "NL"
            },
            {
                "name": "Besoijen",
                "countryCode": "NL"
            },
            {
                "name": "Best",
                "countryCode": "NL"
            },
            {
                "name": "Besterd",
                "countryCode": "NL"
            },
            {
                "name": "Biesdonk",
                "countryCode": "NL"
            },
            {
                "name": "Bitswijk",
                "countryCode": "NL"
            },
            {
                "name": "Blaarthem",
                "countryCode": "NL"
            },
            {
                "name": "Bladel",
                "countryCode": "NL"
            },
            {
                "name": "Boekel",
                "countryCode": "NL"
            },
            {
                "name": "Bosschenhoofd",
                "countryCode": "NL"
            },
            {
                "name": "Boxmeer",
                "countryCode": "NL"
            },
            {
                "name": "Boxtel",
                "countryCode": "NL"
            },
            {
                "name": "Brand",
                "countryCode": "NL"
            },
            {
                "name": "Breda",
                "countryCode": "NL"
            },
            {
                "name": "Breugel",
                "countryCode": "NL"
            },
            {
                "name": "Broekhoven",
                "countryCode": "NL"
            },
            {
                "name": "Budel",
                "countryCode": "NL"
            },
            {
                "name": "Budel-Dorplein",
                "countryCode": "NL"
            },
            {
                "name": "Budel-Schoot",
                "countryCode": "NL"
            },
            {
                "name": "Chaam",
                "countryCode": "NL"
            },
            {
                "name": "Coevering",
                "countryCode": "NL"
            },
            {
                "name": "Cranendonck",
                "countryCode": "NL"
            },
            {
                "name": "Cuijk",
                "countryCode": "NL"
            },
            {
                "name": "De Blaak",
                "countryCode": "NL"
            },
            {
                "name": "De Doornakkers",
                "countryCode": "NL"
            },
            {
                "name": "De Kruiskamp",
                "countryCode": "NL"
            },
            {
                "name": "De Reit",
                "countryCode": "NL"
            },
            {
                "name": "De Rompert",
                "countryCode": "NL"
            },
            {
                "name": "Den Dungen",
                "countryCode": "NL"
            },
            {
                "name": "Deuteren",
                "countryCode": "NL"
            },
            {
                "name": "Diessen",
                "countryCode": "NL"
            },
            {
                "name": "Dinteloord",
                "countryCode": "NL"
            },
            {
                "name": "Dommelen",
                "countryCode": "NL"
            },
            {
                "name": "Dongen",
                "countryCode": "NL"
            },
            {
                "name": "Donk",
                "countryCode": "NL"
            },
            {
                "name": "Doonheide",
                "countryCode": "NL"
            },
            {
                "name": "Dorst",
                "countryCode": "NL"
            },
            {
                "name": "Duizel",
                "countryCode": "NL"
            },
            {
                "name": "Eckart",
                "countryCode": "NL"
            },
            {
                "name": "Eerde",
                "countryCode": "NL"
            },
            {
                "name": "Eerschot",
                "countryCode": "NL"
            },
            {
                "name": "Eersel",
                "countryCode": "NL"
            },
            {
                "name": "Eindhoven",
                "countryCode": "NL"
            },
            {
                "name": "Ekenrooi",
                "countryCode": "NL"
            },
            {
                "name": "Elshout",
                "countryCode": "NL"
            },
            {
                "name": "Enschot",
                "countryCode": "NL"
            },
            {
                "name": "Erp",
                "countryCode": "NL"
            },
            {
                "name": "Esch",
                "countryCode": "NL"
            },
            {
                "name": "Fijnaart",
                "countryCode": "NL"
            },
            {
                "name": "Gageldonk",
                "countryCode": "NL"
            },
            {
                "name": "Geenhoven",
                "countryCode": "NL"
            },
            {
                "name": "Geertruidenberg",
                "countryCode": "NL"
            },
            {
                "name": "Geffen",
                "countryCode": "NL"
            },
            {
                "name": "Geldrop",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente 's-Hertogenbosch",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Alphen-Chaam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Asten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Baarle-Nassau",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bergen op Zoom",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bernheze",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Best",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bladel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Boekel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Boxmeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Boxtel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Breda",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Cranendonck",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Cuijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Deurne",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dongen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Drimmelen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Eersel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Eindhoven",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Etten-Leur",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Geertruidenberg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Geldrop-Mierlo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Gemert-Bakel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Gilze en Rijen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Goirle",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Grave",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Haaren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Halderberge",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heeze-Leende",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Helmond",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heusden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hilvarenbeek",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Laarbeek",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Landerd",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Loon op Zand",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Mill en Sint Hubert",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Moerdijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nuenen, Gerwen en Nederwetten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oirschot",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oisterwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oosterhout",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oss",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Reusel-De Mierden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rucphen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Sint Anthonis",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Sint-Michielsgestel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Someren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Son en Breugel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Steenbergen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tilburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Uden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Valkenswaard",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Veldhoven",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Vught",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Waalre",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Waalwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Woensdrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zundert",
                "countryCode": "NL"
            },
            {
                "name": "Gemert",
                "countryCode": "NL"
            },
            {
                "name": "Gemonde",
                "countryCode": "NL"
            },
            {
                "name": "Genderen",
                "countryCode": "NL"
            },
            {
                "name": "Genoenhuis",
                "countryCode": "NL"
            },
            {
                "name": "Gerwen",
                "countryCode": "NL"
            },
            {
                "name": "Giessen",
                "countryCode": "NL"
            },
            {
                "name": "Gilze",
                "countryCode": "NL"
            },
            {
                "name": "Ginneken",
                "countryCode": "NL"
            },
            {
                "name": "Goirle",
                "countryCode": "NL"
            },
            {
                "name": "Grasrijk",
                "countryCode": "NL"
            },
            {
                "name": "Grave",
                "countryCode": "NL"
            },
            {
                "name": "Groenewoud",
                "countryCode": "NL"
            },
            {
                "name": "Haaren",
                "countryCode": "NL"
            },
            {
                "name": "Haarsteeg",
                "countryCode": "NL"
            },
            {
                "name": "Halsteren",
                "countryCode": "NL"
            },
            {
                "name": "Handel",
                "countryCode": "NL"
            },
            {
                "name": "Hapert",
                "countryCode": "NL"
            },
            {
                "name": "Haps",
                "countryCode": "NL"
            },
            {
                "name": "Heerle",
                "countryCode": "NL"
            },
            {
                "name": "Heesch",
                "countryCode": "NL"
            },
            {
                "name": "Heesterakker",
                "countryCode": "NL"
            },
            {
                "name": "Heeswijk-Dinther",
                "countryCode": "NL"
            },
            {
                "name": "Heeze",
                "countryCode": "NL"
            },
            {
                "name": "Helmond",
                "countryCode": "NL"
            },
            {
                "name": "Helvoirt",
                "countryCode": "NL"
            },
            {
                "name": "Herpen",
                "countryCode": "NL"
            },
            {
                "name": "Heusden",
                "countryCode": "NL"
            },
            {
                "name": "Heusdenhout",
                "countryCode": "NL"
            },
            {
                "name": "Hilvarenbeek",
                "countryCode": "NL"
            },
            {
                "name": "Hoeven",
                "countryCode": "NL"
            },
            {
                "name": "Hoge Vucht",
                "countryCode": "NL"
            },
            {
                "name": "Hooge Mierde",
                "countryCode": "NL"
            },
            {
                "name": "Hooge Zwaluwe",
                "countryCode": "NL"
            },
            {
                "name": "Hoogeloon",
                "countryCode": "NL"
            },
            {
                "name": "Hoogerheide",
                "countryCode": "NL"
            },
            {
                "name": "Huijbergen",
                "countryCode": "NL"
            },
            {
                "name": "Jagershoef",
                "countryCode": "NL"
            },
            {
                "name": "Kalsdonk",
                "countryCode": "NL"
            },
            {
                "name": "Klein-Zundert",
                "countryCode": "NL"
            },
            {
                "name": "Klundert",
                "countryCode": "NL"
            },
            {
                "name": "Korvel",
                "countryCode": "NL"
            },
            {
                "name": "Krooswijk",
                "countryCode": "NL"
            },
            {
                "name": "Kruisland",
                "countryCode": "NL"
            },
            {
                "name": "Lage Mierde",
                "countryCode": "NL"
            },
            {
                "name": "Lakerlopen",
                "countryCode": "NL"
            },
            {
                "name": "Langenboom",
                "countryCode": "NL"
            },
            {
                "name": "Leende",
                "countryCode": "NL"
            },
            {
                "name": "Leest",
                "countryCode": "NL"
            },
            {
                "name": "Lepelstraat",
                "countryCode": "NL"
            },
            {
                "name": "Liempde",
                "countryCode": "NL"
            },
            {
                "name": "Lierop",
                "countryCode": "NL"
            },
            {
                "name": "Lieshout",
                "countryCode": "NL"
            },
            {
                "name": "Liessel",
                "countryCode": "NL"
            },
            {
                "name": "Lievendaal",
                "countryCode": "NL"
            },
            {
                "name": "Lith",
                "countryCode": "NL"
            },
            {
                "name": "Loon op Zand",
                "countryCode": "NL"
            },
            {
                "name": "Loosbroek",
                "countryCode": "NL"
            },
            {
                "name": "Loven",
                "countryCode": "NL"
            },
            {
                "name": "Luyksgestel",
                "countryCode": "NL"
            },
            {
                "name": "Maarheeze",
                "countryCode": "NL"
            },
            {
                "name": "Maaskantje",
                "countryCode": "NL"
            },
            {
                "name": "Made",
                "countryCode": "NL"
            },
            {
                "name": "Mariahout",
                "countryCode": "NL"
            },
            {
                "name": "Meerhoven",
                "countryCode": "NL"
            },
            {
                "name": "Meerveldhoven",
                "countryCode": "NL"
            },
            {
                "name": "Megen",
                "countryCode": "NL"
            },
            {
                "name": "Meierijstad",
                "countryCode": "NL"
            },
            {
                "name": "Melle",
                "countryCode": "NL"
            },
            {
                "name": "Middelbeers",
                "countryCode": "NL"
            },
            {
                "name": "Middelrode",
                "countryCode": "NL"
            },
            {
                "name": "Mierlo",
                "countryCode": "NL"
            },
            {
                "name": "Milheeze",
                "countryCode": "NL"
            },
            {
                "name": "Moerdijk",
                "countryCode": "NL"
            },
            {
                "name": "Molenhoek",
                "countryCode": "NL"
            },
            {
                "name": "Muschberg en Geestenberg",
                "countryCode": "NL"
            },
            {
                "name": "Naastenbest",
                "countryCode": "NL"
            },
            {
                "name": "Neerkant",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Vossemeer",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwkuijk",
                "countryCode": "NL"
            },
            {
                "name": "Nijnsel",
                "countryCode": "NL"
            },
            {
                "name": "Nispen",
                "countryCode": "NL"
            },
            {
                "name": "Nistelrode",
                "countryCode": "NL"
            },
            {
                "name": "Noordgeest",
                "countryCode": "NL"
            },
            {
                "name": "Noordhoek",
                "countryCode": "NL"
            },
            {
                "name": "Nuenen",
                "countryCode": "NL"
            },
            {
                "name": "Odiliapeel",
                "countryCode": "NL"
            },
            {
                "name": "Oerle",
                "countryCode": "NL"
            },
            {
                "name": "Oirschot",
                "countryCode": "NL"
            },
            {
                "name": "Oisterwijk",
                "countryCode": "NL"
            },
            {
                "name": "Oosteind",
                "countryCode": "NL"
            },
            {
                "name": "Oostelbeers",
                "countryCode": "NL"
            },
            {
                "name": "Oosterhout",
                "countryCode": "NL"
            },
            {
                "name": "Orthen",
                "countryCode": "NL"
            },
            {
                "name": "Oss",
                "countryCode": "NL"
            },
            {
                "name": "Ossendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Oud Gastel",
                "countryCode": "NL"
            },
            {
                "name": "Overloon",
                "countryCode": "NL"
            },
            {
                "name": "Princenhage",
                "countryCode": "NL"
            },
            {
                "name": "Prinsenbeek",
                "countryCode": "NL"
            },
            {
                "name": "Putte",
                "countryCode": "NL"
            },
            {
                "name": "Quirijnstok",
                "countryCode": "NL"
            },
            {
                "name": "Raam",
                "countryCode": "NL"
            },
            {
                "name": "Raamsdonk",
                "countryCode": "NL"
            },
            {
                "name": "Raamsdonksveer",
                "countryCode": "NL"
            },
            {
                "name": "Ravenstein",
                "countryCode": "NL"
            },
            {
                "name": "Reek",
                "countryCode": "NL"
            },
            {
                "name": "Reusel",
                "countryCode": "NL"
            },
            {
                "name": "Riethoven",
                "countryCode": "NL"
            },
            {
                "name": "Rijen",
                "countryCode": "NL"
            },
            {
                "name": "Rijsbergen",
                "countryCode": "NL"
            },
            {
                "name": "Rijswijk",
                "countryCode": "NL"
            },
            {
                "name": "Roosendaal",
                "countryCode": "NL"
            },
            {
                "name": "Rucphen",
                "countryCode": "NL"
            },
            {
                "name": "Salderes",
                "countryCode": "NL"
            },
            {
                "name": "Schaijk",
                "countryCode": "NL"
            },
            {
                "name": "Schijndel",
                "countryCode": "NL"
            },
            {
                "name": "Schutsboom",
                "countryCode": "NL"
            },
            {
                "name": "Sint Anthonis",
                "countryCode": "NL"
            },
            {
                "name": "Sint Willebrord",
                "countryCode": "NL"
            },
            {
                "name": "Sint-Michielsgestel",
                "countryCode": "NL"
            },
            {
                "name": "Sint-Oedenrode",
                "countryCode": "NL"
            },
            {
                "name": "Soerendonk",
                "countryCode": "NL"
            },
            {
                "name": "Someren",
                "countryCode": "NL"
            },
            {
                "name": "Someren-Eind",
                "countryCode": "NL"
            },
            {
                "name": "Son",
                "countryCode": "NL"
            },
            {
                "name": "Speelheide",
                "countryCode": "NL"
            },
            {
                "name": "Sprundel",
                "countryCode": "NL"
            },
            {
                "name": "Stampersgat",
                "countryCode": "NL"
            },
            {
                "name": "Standdaarbuiten",
                "countryCode": "NL"
            },
            {
                "name": "Steenbergen",
                "countryCode": "NL"
            },
            {
                "name": "Steensel",
                "countryCode": "NL"
            },
            {
                "name": "Stepekolk",
                "countryCode": "NL"
            },
            {
                "name": "Terheijden",
                "countryCode": "NL"
            },
            {
                "name": "Teteringen",
                "countryCode": "NL"
            },
            {
                "name": "Theereheide",
                "countryCode": "NL"
            },
            {
                "name": "Tilburg",
                "countryCode": "NL"
            },
            {
                "name": "Tivoli",
                "countryCode": "NL"
            },
            {
                "name": "Tongelre",
                "countryCode": "NL"
            },
            {
                "name": "Uden",
                "countryCode": "NL"
            },
            {
                "name": "Udenhout",
                "countryCode": "NL"
            },
            {
                "name": "Ulvenhout",
                "countryCode": "NL"
            },
            {
                "name": "Vaartbroek",
                "countryCode": "NL"
            },
            {
                "name": "Valkenswaard",
                "countryCode": "NL"
            },
            {
                "name": "Veen",
                "countryCode": "NL"
            },
            {
                "name": "Veghel",
                "countryCode": "NL"
            },
            {
                "name": "Veldhoven",
                "countryCode": "NL"
            },
            {
                "name": "Ven",
                "countryCode": "NL"
            },
            {
                "name": "Venhorst",
                "countryCode": "NL"
            },
            {
                "name": "Villapark",
                "countryCode": "NL"
            },
            {
                "name": "Vliedberg",
                "countryCode": "NL"
            },
            {
                "name": "Vlierden",
                "countryCode": "NL"
            },
            {
                "name": "Vlokhoven",
                "countryCode": "NL"
            },
            {
                "name": "Voldijn",
                "countryCode": "NL"
            },
            {
                "name": "Volkel",
                "countryCode": "NL"
            },
            {
                "name": "Vorstenbosch",
                "countryCode": "NL"
            },
            {
                "name": "Vught",
                "countryCode": "NL"
            },
            {
                "name": "Waalre",
                "countryCode": "NL"
            },
            {
                "name": "Waalwijk",
                "countryCode": "NL"
            },
            {
                "name": "Wagenberg",
                "countryCode": "NL"
            },
            {
                "name": "Wanroij",
                "countryCode": "NL"
            },
            {
                "name": "Waspik",
                "countryCode": "NL"
            },
            {
                "name": "Waterdonken",
                "countryCode": "NL"
            },
            {
                "name": "Welberg",
                "countryCode": "NL"
            },
            {
                "name": "Werkendam",
                "countryCode": "NL"
            },
            {
                "name": "Wernhout",
                "countryCode": "NL"
            },
            {
                "name": "Westerhoven",
                "countryCode": "NL"
            },
            {
                "name": "Wijbosch",
                "countryCode": "NL"
            },
            {
                "name": "Wilhelminadorp",
                "countryCode": "NL"
            },
            {
                "name": "Wisselaar",
                "countryCode": "NL"
            },
            {
                "name": "Woensdrecht",
                "countryCode": "NL"
            },
            {
                "name": "Woenselse Heide",
                "countryCode": "NL"
            },
            {
                "name": "Woudrichem",
                "countryCode": "NL"
            },
            {
                "name": "Wouw",
                "countryCode": "NL"
            },
            {
                "name": "Zandberg",
                "countryCode": "NL"
            },
            {
                "name": "Zeeland",
                "countryCode": "NL"
            },
            {
                "name": "Zeelst",
                "countryCode": "NL"
            },
            {
                "name": "Zegge",
                "countryCode": "NL"
            },
            {
                "name": "Zeilberg",
                "countryCode": "NL"
            },
            {
                "name": "Zesgehuchten",
                "countryCode": "NL"
            },
            {
                "name": "Zevenbergschen Hoek",
                "countryCode": "NL"
            },
            {
                "name": "Zijtaart",
                "countryCode": "NL"
            },
            {
                "name": "Zonderwijk",
                "countryCode": "NL"
            },
            {
                "name": "Zundert",
                "countryCode": "NL"
            },
            {
                "name": "'t Zand",
                "countryCode": "NL"
            },
            {
                "name": "Aalsmeer",
                "countryCode": "NL"
            },
            {
                "name": "Abbekerk",
                "countryCode": "NL"
            },
            {
                "name": "Alkmaar",
                "countryCode": "NL"
            },
            {
                "name": "Amstelveen",
                "countryCode": "NL"
            },
            {
                "name": "Amsterdam",
                "countryCode": "NL"
            },
            {
                "name": "Amsterdam-Zuidoost",
                "countryCode": "NL"
            },
            {
                "name": "Ankeveense Rade",
                "countryCode": "NL"
            },
            {
                "name": "Avenhorn",
                "countryCode": "NL"
            },
            {
                "name": "Bangert",
                "countryCode": "NL"
            },
            {
                "name": "Bennebroek",
                "countryCode": "NL"
            },
            {
                "name": "Berkhout",
                "countryCode": "NL"
            },
            {
                "name": "Beverwijk",
                "countryCode": "NL"
            },
            {
                "name": "Bijvanck",
                "countryCode": "NL"
            },
            {
                "name": "Blaricum",
                "countryCode": "NL"
            },
            {
                "name": "Bloemendaal",
                "countryCode": "NL"
            },
            {
                "name": "Breezand",
                "countryCode": "NL"
            },
            {
                "name": "Broek in Waterland",
                "countryCode": "NL"
            },
            {
                "name": "Broek op Langedijk",
                "countryCode": "NL"
            },
            {
                "name": "Bussum",
                "countryCode": "NL"
            },
            {
                "name": "Callantsoog",
                "countryCode": "NL"
            },
            {
                "name": "Castricum",
                "countryCode": "NL"
            },
            {
                "name": "Commandeurs",
                "countryCode": "NL"
            },
            {
                "name": "De Glip",
                "countryCode": "NL"
            },
            {
                "name": "De Goorn",
                "countryCode": "NL"
            },
            {
                "name": "De Koog",
                "countryCode": "NL"
            },
            {
                "name": "De Maer",
                "countryCode": "NL"
            },
            {
                "name": "De Noord",
                "countryCode": "NL"
            },
            {
                "name": "De Rijp",
                "countryCode": "NL"
            },
            {
                "name": "Den Burg",
                "countryCode": "NL"
            },
            {
                "name": "Den Helder",
                "countryCode": "NL"
            },
            {
                "name": "Den Oever",
                "countryCode": "NL"
            },
            {
                "name": "Diemen",
                "countryCode": "NL"
            },
            {
                "name": "Dirkshorn",
                "countryCode": "NL"
            },
            {
                "name": "Driemond",
                "countryCode": "NL"
            },
            {
                "name": "Duivendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Edam",
                "countryCode": "NL"
            },
            {
                "name": "Egmond aan Zee",
                "countryCode": "NL"
            },
            {
                "name": "Egmond-Binnen",
                "countryCode": "NL"
            },
            {
                "name": "Enkhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Aalsmeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Alkmaar",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Amstelveen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Amsterdam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Beemster",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bergen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Beverwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Blaricum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bloemendaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Castricum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Den Helder",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Diemen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Drechterland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Edam-Volendam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Enkhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Haarlem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Haarlemmermeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heemskerk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heemstede",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heerhugowaard",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Heiloo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hilversum",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hollands Kroon",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hoorn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Huizen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Koggenland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Landsmeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Langedijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Laren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Medemblik",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oostzaan",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Opmeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ouder-Amstel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Purmerend",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Schagen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Stede Broec",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Texel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Uitgeest",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Uithoorn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Velsen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Waterland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Weesp",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wijdemeren",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wormerland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zaanstad",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zandvoort",
                "countryCode": "NL"
            },
            {
                "name": "Gooise Meren",
                "countryCode": "NL"
            },
            {
                "name": "Haarlem",
                "countryCode": "NL"
            },
            {
                "name": "Halfweg",
                "countryCode": "NL"
            },
            {
                "name": "Harenkarspel",
                "countryCode": "NL"
            },
            {
                "name": "Heemskerk",
                "countryCode": "NL"
            },
            {
                "name": "Heemstede",
                "countryCode": "NL"
            },
            {
                "name": "Heerhugowaard",
                "countryCode": "NL"
            },
            {
                "name": "Heiloo",
                "countryCode": "NL"
            },
            {
                "name": "Hem",
                "countryCode": "NL"
            },
            {
                "name": "Hensbroek",
                "countryCode": "NL"
            },
            {
                "name": "Hilversum",
                "countryCode": "NL"
            },
            {
                "name": "Hilversumse Meent",
                "countryCode": "NL"
            },
            {
                "name": "Hippolytushoef",
                "countryCode": "NL"
            },
            {
                "name": "Hofgeest",
                "countryCode": "NL"
            },
            {
                "name": "Hoofddorp",
                "countryCode": "NL"
            },
            {
                "name": "Hoogwoud",
                "countryCode": "NL"
            },
            {
                "name": "Hoorn",
                "countryCode": "NL"
            },
            {
                "name": "Huizen",
                "countryCode": "NL"
            },
            {
                "name": "Ilpendam",
                "countryCode": "NL"
            },
            {
                "name": "Kadoelen",
                "countryCode": "NL"
            },
            {
                "name": "Kerkelanden",
                "countryCode": "NL"
            },
            {
                "name": "Kortenhoef",
                "countryCode": "NL"
            },
            {
                "name": "Kudelstaart",
                "countryCode": "NL"
            },
            {
                "name": "Landsmeer",
                "countryCode": "NL"
            },
            {
                "name": "Langeheit",
                "countryCode": "NL"
            },
            {
                "name": "Laren",
                "countryCode": "NL"
            },
            {
                "name": "Limmen",
                "countryCode": "NL"
            },
            {
                "name": "Lisserbroek",
                "countryCode": "NL"
            },
            {
                "name": "Lutjebroek",
                "countryCode": "NL"
            },
            {
                "name": "Marken",
                "countryCode": "NL"
            },
            {
                "name": "Medemblik",
                "countryCode": "NL"
            },
            {
                "name": "Middenbeemster",
                "countryCode": "NL"
            },
            {
                "name": "Middenmeer",
                "countryCode": "NL"
            },
            {
                "name": "Midwoud",
                "countryCode": "NL"
            },
            {
                "name": "Monnickendam",
                "countryCode": "NL"
            },
            {
                "name": "Muiden",
                "countryCode": "NL"
            },
            {
                "name": "Muiderberg",
                "countryCode": "NL"
            },
            {
                "name": "Naarden",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Loosdrecht",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwe-Niedorp",
                "countryCode": "NL"
            },
            {
                "name": "Noord-Scharwoude",
                "countryCode": "NL"
            },
            {
                "name": "Obdam",
                "countryCode": "NL"
            },
            {
                "name": "Onderdijk",
                "countryCode": "NL"
            },
            {
                "name": "Oosteinde",
                "countryCode": "NL"
            },
            {
                "name": "Oosterblokker",
                "countryCode": "NL"
            },
            {
                "name": "Oosterzij",
                "countryCode": "NL"
            },
            {
                "name": "Oosthuizen",
                "countryCode": "NL"
            },
            {
                "name": "Opmeer",
                "countryCode": "NL"
            },
            {
                "name": "Opperdoes",
                "countryCode": "NL"
            },
            {
                "name": "Oud-Loosdrecht",
                "countryCode": "NL"
            },
            {
                "name": "Ouderkerk aan de Amstel",
                "countryCode": "NL"
            },
            {
                "name": "Oudeschild",
                "countryCode": "NL"
            },
            {
                "name": "Oudkarspel",
                "countryCode": "NL"
            },
            {
                "name": "Overveen",
                "countryCode": "NL"
            },
            {
                "name": "Petten",
                "countryCode": "NL"
            },
            {
                "name": "Purmerend",
                "countryCode": "NL"
            },
            {
                "name": "Schagen",
                "countryCode": "NL"
            },
            {
                "name": "Schagerbrug",
                "countryCode": "NL"
            },
            {
                "name": "Schermerhorn",
                "countryCode": "NL"
            },
            {
                "name": "Sint Pancras",
                "countryCode": "NL"
            },
            {
                "name": "Slootdorp",
                "countryCode": "NL"
            },
            {
                "name": "Spierdijk",
                "countryCode": "NL"
            },
            {
                "name": "Stompetoren",
                "countryCode": "NL"
            },
            {
                "name": "Twisk",
                "countryCode": "NL"
            },
            {
                "name": "Uitgeest",
                "countryCode": "NL"
            },
            {
                "name": "Uithoorn",
                "countryCode": "NL"
            },
            {
                "name": "Velsen-Zuid",
                "countryCode": "NL"
            },
            {
                "name": "Vijfhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Vogelenzang",
                "countryCode": "NL"
            },
            {
                "name": "Volendam",
                "countryCode": "NL"
            },
            {
                "name": "Waarland",
                "countryCode": "NL"
            },
            {
                "name": "Waterakkers",
                "countryCode": "NL"
            },
            {
                "name": "Weesp",
                "countryCode": "NL"
            },
            {
                "name": "Westwoud",
                "countryCode": "NL"
            },
            {
                "name": "Wieringerwaard",
                "countryCode": "NL"
            },
            {
                "name": "Wieringerwerf",
                "countryCode": "NL"
            },
            {
                "name": "Wijdenes",
                "countryCode": "NL"
            },
            {
                "name": "Wijk aan Zee",
                "countryCode": "NL"
            },
            {
                "name": "Winkel",
                "countryCode": "NL"
            },
            {
                "name": "Zaandam",
                "countryCode": "NL"
            },
            {
                "name": "Zaandijk",
                "countryCode": "NL"
            },
            {
                "name": "Zaanstad",
                "countryCode": "NL"
            },
            {
                "name": "Zandvoort",
                "countryCode": "NL"
            },
            {
                "name": "Zuid-Scharwoude",
                "countryCode": "NL"
            },
            {
                "name": "Zwaagdijk-Oost",
                "countryCode": "NL"
            },
            {
                "name": "Zwaanshoek",
                "countryCode": "NL"
            },
            {
                "name": "Almelo",
                "countryCode": "NL"
            },
            {
                "name": "Baalder",
                "countryCode": "NL"
            },
            {
                "name": "Berkum",
                "countryCode": "NL"
            },
            {
                "name": "Blokzijl",
                "countryCode": "NL"
            },
            {
                "name": "Borgele",
                "countryCode": "NL"
            },
            {
                "name": "Borne",
                "countryCode": "NL"
            },
            {
                "name": "Boskamp",
                "countryCode": "NL"
            },
            {
                "name": "Brunnepe",
                "countryCode": "NL"
            },
            {
                "name": "Dalfsen",
                "countryCode": "NL"
            },
            {
                "name": "De Hoven",
                "countryCode": "NL"
            },
            {
                "name": "Delden",
                "countryCode": "NL"
            },
            {
                "name": "Den Ham",
                "countryCode": "NL"
            },
            {
                "name": "Deventer",
                "countryCode": "NL"
            },
            {
                "name": "Diepenheim",
                "countryCode": "NL"
            },
            {
                "name": "Enschede",
                "countryCode": "NL"
            },
            {
                "name": "Flevowijk",
                "countryCode": "NL"
            },
            {
                "name": "Frankhuis",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Almelo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Borne",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dalfsen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Deventer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dinkelland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Enschede",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Haaksbergen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hardenberg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hellendoorn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hengelo",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hof van Twente",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Kampen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Losser",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oldenzaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Olst-Wijhe",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ommen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Raalte",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rijssen-Holten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Staphorst",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Steenwijkerland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tubbergen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Twenterand",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wierden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zwartewaterland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zwolle",
                "countryCode": "NL"
            },
            {
                "name": "Giethoorn",
                "countryCode": "NL"
            },
            {
                "name": "Goor",
                "countryCode": "NL"
            },
            {
                "name": "Grafhorst",
                "countryCode": "NL"
            },
            {
                "name": "Haaksbergen",
                "countryCode": "NL"
            },
            {
                "name": "Haarle",
                "countryCode": "NL"
            },
            {
                "name": "Hardenberg",
                "countryCode": "NL"
            },
            {
                "name": "Hasselt",
                "countryCode": "NL"
            },
            {
                "name": "Heino",
                "countryCode": "NL"
            },
            {
                "name": "Hengelo",
                "countryCode": "NL"
            },
            {
                "name": "Hengevelde",
                "countryCode": "NL"
            },
            {
                "name": "Het Oostrik",
                "countryCode": "NL"
            },
            {
                "name": "Kampen",
                "countryCode": "NL"
            },
            {
                "name": "Klein Driene",
                "countryCode": "NL"
            },
            {
                "name": "Losser",
                "countryCode": "NL"
            },
            {
                "name": "Magele",
                "countryCode": "NL"
            },
            {
                "name": "Markelo",
                "countryCode": "NL"
            },
            {
                "name": "Mekkelholt",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwoord",
                "countryCode": "NL"
            },
            {
                "name": "Nijrees",
                "countryCode": "NL"
            },
            {
                "name": "Oldemarkt",
                "countryCode": "NL"
            },
            {
                "name": "Oldenzaal",
                "countryCode": "NL"
            },
            {
                "name": "Olst",
                "countryCode": "NL"
            },
            {
                "name": "Ommen",
                "countryCode": "NL"
            },
            {
                "name": "Oosterholt",
                "countryCode": "NL"
            },
            {
                "name": "Oostermeenthe",
                "countryCode": "NL"
            },
            {
                "name": "Ootmarsum",
                "countryCode": "NL"
            },
            {
                "name": "Pathmos",
                "countryCode": "NL"
            },
            {
                "name": "Pierik",
                "countryCode": "NL"
            },
            {
                "name": "Raalte",
                "countryCode": "NL"
            },
            {
                "name": "Rossum",
                "countryCode": "NL"
            },
            {
                "name": "Schalkhaar",
                "countryCode": "NL"
            },
            {
                "name": "Sint Jansklooster",
                "countryCode": "NL"
            },
            {
                "name": "Staphorst",
                "countryCode": "NL"
            },
            {
                "name": "Steenwijk",
                "countryCode": "NL"
            },
            {
                "name": "Steenwijkerwold",
                "countryCode": "NL"
            },
            {
                "name": "Tubbergen",
                "countryCode": "NL"
            },
            {
                "name": "Tuk",
                "countryCode": "NL"
            },
            {
                "name": "Twekkelerveld",
                "countryCode": "NL"
            },
            {
                "name": "Vollenhove",
                "countryCode": "NL"
            },
            {
                "name": "Vriezenveen",
                "countryCode": "NL"
            },
            {
                "name": "Vroomshoop",
                "countryCode": "NL"
            },
            {
                "name": "Westerhaar-Vriezenveensewijk",
                "countryCode": "NL"
            },
            {
                "name": "Wierden",
                "countryCode": "NL"
            },
            {
                "name": "Wijhe",
                "countryCode": "NL"
            },
            {
                "name": "Woolde",
                "countryCode": "NL"
            },
            {
                "name": "Wooldrik",
                "countryCode": "NL"
            },
            {
                "name": "Zuid-Berghuizen",
                "countryCode": "NL"
            },
            {
                "name": "Zwolle",
                "countryCode": "NL"
            },
            {
                "name": "Abcoude",
                "countryCode": "NL"
            },
            {
                "name": "Amerongen",
                "countryCode": "NL"
            },
            {
                "name": "Amersfoort",
                "countryCode": "NL"
            },
            {
                "name": "Austerlitz",
                "countryCode": "NL"
            },
            {
                "name": "Baambrugge",
                "countryCode": "NL"
            },
            {
                "name": "Baarn",
                "countryCode": "NL"
            },
            {
                "name": "Bosch en Duin",
                "countryCode": "NL"
            },
            {
                "name": "Breukelen",
                "countryCode": "NL"
            },
            {
                "name": "Bunnik",
                "countryCode": "NL"
            },
            {
                "name": "Bunschoten",
                "countryCode": "NL"
            },
            {
                "name": "Cothen",
                "countryCode": "NL"
            },
            {
                "name": "De Bilt",
                "countryCode": "NL"
            },
            {
                "name": "De Hagen",
                "countryCode": "NL"
            },
            {
                "name": "De Uithof",
                "countryCode": "NL"
            },
            {
                "name": "Doorn",
                "countryCode": "NL"
            },
            {
                "name": "Driebergen-Rijsenburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Amersfoort",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Baarn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bunnik",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Bunschoten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente De Bilt",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente De Ronde Venen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Eemnes",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Houten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente IJsselstein",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leusden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lopik",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Montfoort",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nieuwegein",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oudewater",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Renswoude",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rhenen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Soest",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Utrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Utrechtse Heuvelrug",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Veenendaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wijk bij Duurstede",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Woerden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Woudenberg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zeist",
                "countryCode": "NL"
            },
            {
                "name": "Groenekan",
                "countryCode": "NL"
            },
            {
                "name": "Hollandsche Rading",
                "countryCode": "NL"
            },
            {
                "name": "Houten",
                "countryCode": "NL"
            },
            {
                "name": "IJsselstein",
                "countryCode": "NL"
            },
            {
                "name": "Kedichem",
                "countryCode": "NL"
            },
            {
                "name": "Kerckebosch",
                "countryCode": "NL"
            },
            {
                "name": "Langenoord",
                "countryCode": "NL"
            },
            {
                "name": "Leerdam",
                "countryCode": "NL"
            },
            {
                "name": "Leersum",
                "countryCode": "NL"
            },
            {
                "name": "Leusden",
                "countryCode": "NL"
            },
            {
                "name": "Linschoten",
                "countryCode": "NL"
            },
            {
                "name": "Lopik",
                "countryCode": "NL"
            },
            {
                "name": "Lunetten",
                "countryCode": "NL"
            },
            {
                "name": "Maarn",
                "countryCode": "NL"
            },
            {
                "name": "Maarssen",
                "countryCode": "NL"
            },
            {
                "name": "Maartensdijk",
                "countryCode": "NL"
            },
            {
                "name": "Mijdrecht",
                "countryCode": "NL"
            },
            {
                "name": "Montfoort",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwegein",
                "countryCode": "NL"
            },
            {
                "name": "Nijenheim",
                "countryCode": "NL"
            },
            {
                "name": "Odijk",
                "countryCode": "NL"
            },
            {
                "name": "Oog in Al",
                "countryCode": "NL"
            },
            {
                "name": "Oudewater",
                "countryCode": "NL"
            },
            {
                "name": "Overberg",
                "countryCode": "NL"
            },
            {
                "name": "Randenbroek",
                "countryCode": "NL"
            },
            {
                "name": "Renswoude",
                "countryCode": "NL"
            },
            {
                "name": "Rhenen",
                "countryCode": "NL"
            },
            {
                "name": "Schoonrewoerd",
                "countryCode": "NL"
            },
            {
                "name": "Soest",
                "countryCode": "NL"
            },
            {
                "name": "Soestdijk",
                "countryCode": "NL"
            },
            {
                "name": "Spakenburg",
                "countryCode": "NL"
            },
            {
                "name": "Spechtenkamp",
                "countryCode": "NL"
            },
            {
                "name": "Stichtse Vecht",
                "countryCode": "NL"
            },
            {
                "name": "Utrecht",
                "countryCode": "NL"
            },
            {
                "name": "Veenendaal",
                "countryCode": "NL"
            },
            {
                "name": "Veldhuizen",
                "countryCode": "NL"
            },
            {
                "name": "Vianen",
                "countryCode": "NL"
            },
            {
                "name": "Vinkeveen",
                "countryCode": "NL"
            },
            {
                "name": "Vleuten",
                "countryCode": "NL"
            },
            {
                "name": "Vreeswijk",
                "countryCode": "NL"
            },
            {
                "name": "Werkhoven",
                "countryCode": "NL"
            },
            {
                "name": "Wijk bij Duurstede",
                "countryCode": "NL"
            },
            {
                "name": "Wilnis",
                "countryCode": "NL"
            },
            {
                "name": "Woerden",
                "countryCode": "NL"
            },
            {
                "name": "Woudenberg",
                "countryCode": "NL"
            },
            {
                "name": "Zeist",
                "countryCode": "NL"
            },
            {
                "name": "Aagtekerke",
                "countryCode": "NL"
            },
            {
                "name": "Aardenburg",
                "countryCode": "NL"
            },
            {
                "name": "Arnemuiden",
                "countryCode": "NL"
            },
            {
                "name": "Axel",
                "countryCode": "NL"
            },
            {
                "name": "Borssele",
                "countryCode": "NL"
            },
            {
                "name": "Breskens",
                "countryCode": "NL"
            },
            {
                "name": "Brouwershaven",
                "countryCode": "NL"
            },
            {
                "name": "Bruinisse",
                "countryCode": "NL"
            },
            {
                "name": "Colijnsplaat",
                "countryCode": "NL"
            },
            {
                "name": "Dauwendaele",
                "countryCode": "NL"
            },
            {
                "name": "Domburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Borsele",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Goes",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hulst",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Kapelle",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Middelburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Noord-Beveland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Reimerswaal",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Sluis",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Terneuzen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Tholen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Veere",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Vlissingen",
                "countryCode": "NL"
            },
            {
                "name": "Goes",
                "countryCode": "NL"
            },
            {
                "name": "Griffioen",
                "countryCode": "NL"
            },
            {
                "name": "Grijpskerke",
                "countryCode": "NL"
            },
            {
                "name": "Haamstede",
                "countryCode": "NL"
            },
            {
                "name": "Hulst",
                "countryCode": "NL"
            },
            {
                "name": "IJzendijke",
                "countryCode": "NL"
            },
            {
                "name": "Kamperland",
                "countryCode": "NL"
            },
            {
                "name": "Kapelle",
                "countryCode": "NL"
            },
            {
                "name": "Klarenbeek",
                "countryCode": "NL"
            },
            {
                "name": "Kloetinge",
                "countryCode": "NL"
            },
            {
                "name": "Koewacht",
                "countryCode": "NL"
            },
            {
                "name": "Koudekerke",
                "countryCode": "NL"
            },
            {
                "name": "Lammerenburg",
                "countryCode": "NL"
            },
            {
                "name": "Malta",
                "countryCode": "NL"
            },
            {
                "name": "Meliskerke",
                "countryCode": "NL"
            },
            {
                "name": "Middelburg",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw- en Sint Joosland",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwerkerk",
                "countryCode": "NL"
            },
            {
                "name": "Oostburg",
                "countryCode": "NL"
            },
            {
                "name": "Oosterland",
                "countryCode": "NL"
            },
            {
                "name": "Oostkapelle",
                "countryCode": "NL"
            },
            {
                "name": "Othene",
                "countryCode": "NL"
            },
            {
                "name": "Oud-Vossemeer",
                "countryCode": "NL"
            },
            {
                "name": "Poortvliet",
                "countryCode": "NL"
            },
            {
                "name": "Renesse",
                "countryCode": "NL"
            },
            {
                "name": "Sas van Gent",
                "countryCode": "NL"
            },
            {
                "name": "Scharendijke",
                "countryCode": "NL"
            },
            {
                "name": "Scherpenisse",
                "countryCode": "NL"
            },
            {
                "name": "Schoondijke",
                "countryCode": "NL"
            },
            {
                "name": "Schouwen-Duiveland",
                "countryCode": "NL"
            },
            {
                "name": "Serooskerke",
                "countryCode": "NL"
            },
            {
                "name": "Sint Laurens",
                "countryCode": "NL"
            },
            {
                "name": "Sint Philipsland",
                "countryCode": "NL"
            },
            {
                "name": "Sluis",
                "countryCode": "NL"
            },
            {
                "name": "Sluiskil",
                "countryCode": "NL"
            },
            {
                "name": "Stavenisse",
                "countryCode": "NL"
            },
            {
                "name": "Terneuzen",
                "countryCode": "NL"
            },
            {
                "name": "Tholen",
                "countryCode": "NL"
            },
            {
                "name": "Vlissingen",
                "countryCode": "NL"
            },
            {
                "name": "Waarde",
                "countryCode": "NL"
            },
            {
                "name": "Wemeldinge",
                "countryCode": "NL"
            },
            {
                "name": "West-Souburg",
                "countryCode": "NL"
            },
            {
                "name": "Westdorpe",
                "countryCode": "NL"
            },
            {
                "name": "Westerzicht",
                "countryCode": "NL"
            },
            {
                "name": "Westkapelle",
                "countryCode": "NL"
            },
            {
                "name": "Wissenkerke",
                "countryCode": "NL"
            },
            {
                "name": "Wolphaartsdijk",
                "countryCode": "NL"
            },
            {
                "name": "Yerseke",
                "countryCode": "NL"
            },
            {
                "name": "Zaamslag",
                "countryCode": "NL"
            },
            {
                "name": "Zierikzee",
                "countryCode": "NL"
            },
            {
                "name": "Zoutelande",
                "countryCode": "NL"
            },
            {
                "name": "'s-Gravenland",
                "countryCode": "NL"
            },
            {
                "name": "'s-Gravenzande",
                "countryCode": "NL"
            },
            {
                "name": "Adegeest",
                "countryCode": "NL"
            },
            {
                "name": "Alblasserdam",
                "countryCode": "NL"
            },
            {
                "name": "Alphen aan den Rijn",
                "countryCode": "NL"
            },
            {
                "name": "Ammerstol",
                "countryCode": "NL"
            },
            {
                "name": "Arkel",
                "countryCode": "NL"
            },
            {
                "name": "Barendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Benthuizen",
                "countryCode": "NL"
            },
            {
                "name": "Bergschenhoek",
                "countryCode": "NL"
            },
            {
                "name": "Bergstoep",
                "countryCode": "NL"
            },
            {
                "name": "Berkel en Rodenrijs",
                "countryCode": "NL"
            },
            {
                "name": "Berkenwoude",
                "countryCode": "NL"
            },
            {
                "name": "Binnenhof",
                "countryCode": "NL"
            },
            {
                "name": "Bleiswijk",
                "countryCode": "NL"
            },
            {
                "name": "Bleskensgraaf",
                "countryCode": "NL"
            },
            {
                "name": "Bloemendaal",
                "countryCode": "NL"
            },
            {
                "name": "Bloemhof",
                "countryCode": "NL"
            },
            {
                "name": "Bodegraven",
                "countryCode": "NL"
            },
            {
                "name": "Bodegraven-Reeuwijk",
                "countryCode": "NL"
            },
            {
                "name": "Bolnes",
                "countryCode": "NL"
            },
            {
                "name": "Boskoop",
                "countryCode": "NL"
            },
            {
                "name": "Boven-Hardinxveld",
                "countryCode": "NL"
            },
            {
                "name": "Brielle",
                "countryCode": "NL"
            },
            {
                "name": "Capelle aan den IJssel",
                "countryCode": "NL"
            },
            {
                "name": "Capelle-West",
                "countryCode": "NL"
            },
            {
                "name": "Carnisse",
                "countryCode": "NL"
            },
            {
                "name": "De Kieviet",
                "countryCode": "NL"
            },
            {
                "name": "De Lier",
                "countryCode": "NL"
            },
            {
                "name": "De Peulen",
                "countryCode": "NL"
            },
            {
                "name": "Delfshaven",
                "countryCode": "NL"
            },
            {
                "name": "Delft",
                "countryCode": "NL"
            },
            {
                "name": "Dirksland",
                "countryCode": "NL"
            },
            {
                "name": "Dordrecht",
                "countryCode": "NL"
            },
            {
                "name": "Driebruggen",
                "countryCode": "NL"
            },
            {
                "name": "Driemanspolder",
                "countryCode": "NL"
            },
            {
                "name": "Duindorp",
                "countryCode": "NL"
            },
            {
                "name": "Duinzigt",
                "countryCode": "NL"
            },
            {
                "name": "Essesteijn",
                "countryCode": "NL"
            },
            {
                "name": "Feijenoord",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Alblasserdam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Albrandswaard",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Alphen aan den Rijn",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Barendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Brielle",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Capelle aan den IJssel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Delft",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Den Haag",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Dordrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Goeree-Overflakkee",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Gorinchem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Gouda",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hardinxveld-Giessendam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hellevoetsluis",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hendrik-Ido-Ambacht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Hillegom",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Kaag en Braassem",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Katwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Krimpen aan den IJssel",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lansingerland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leiden",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leiderdorp",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Leidschendam-Voorburg",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Lisse",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Maassluis",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Midden-Delfland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Nieuwkoop",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Noordwijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Oegstgeest",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Papendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Pijnacker-Nootdorp",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Ridderkerk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rijswijk",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Rotterdam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Schiedam",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Sliedrecht",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Teylingen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Vlaardingen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Voorschoten",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Waddinxveen",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Wassenaar",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Westland",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Westvoorne",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zoetermeer",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zoeterwoude",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zuidplas",
                "countryCode": "NL"
            },
            {
                "name": "Gemeente Zwijndrecht",
                "countryCode": "NL"
            },
            {
                "name": "Giessenburg",
                "countryCode": "NL"
            },
            {
                "name": "Giessendam",
                "countryCode": "NL"
            },
            {
                "name": "Goedereede",
                "countryCode": "NL"
            },
            {
                "name": "Gorinchem",
                "countryCode": "NL"
            },
            {
                "name": "Gouda",
                "countryCode": "NL"
            },
            {
                "name": "Gouderak",
                "countryCode": "NL"
            },
            {
                "name": "Goudswaard",
                "countryCode": "NL"
            },
            {
                "name": "Gouwsluis",
                "countryCode": "NL"
            },
            {
                "name": "Groenswaard",
                "countryCode": "NL"
            },
            {
                "name": "Groot IJsselmonde",
                "countryCode": "NL"
            },
            {
                "name": "Groot-Ammers",
                "countryCode": "NL"
            },
            {
                "name": "Haastrecht",
                "countryCode": "NL"
            },
            {
                "name": "Heerjansdam",
                "countryCode": "NL"
            },
            {
                "name": "Heijplaat",
                "countryCode": "NL"
            },
            {
                "name": "Hellevoetsluis",
                "countryCode": "NL"
            },
            {
                "name": "Hendrik-Ido-Ambacht",
                "countryCode": "NL"
            },
            {
                "name": "Hillegom",
                "countryCode": "NL"
            },
            {
                "name": "Hoek van Holland",
                "countryCode": "NL"
            },
            {
                "name": "Hoge Mors",
                "countryCode": "NL"
            },
            {
                "name": "Honselersdijk",
                "countryCode": "NL"
            },
            {
                "name": "Hoogblokland",
                "countryCode": "NL"
            },
            {
                "name": "Hoogmade",
                "countryCode": "NL"
            },
            {
                "name": "Katendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Katwijk aan Zee",
                "countryCode": "NL"
            },
            {
                "name": "Katwijk aan den Rijn",
                "countryCode": "NL"
            },
            {
                "name": "Kerkehout",
                "countryCode": "NL"
            },
            {
                "name": "Kijkduin",
                "countryCode": "NL"
            },
            {
                "name": "Klaaswaal",
                "countryCode": "NL"
            },
            {
                "name": "Kop van Zuid",
                "countryCode": "NL"
            },
            {
                "name": "Krimpen aan den IJssel",
                "countryCode": "NL"
            },
            {
                "name": "Krimpenerwaard",
                "countryCode": "NL"
            },
            {
                "name": "Kwintsheul",
                "countryCode": "NL"
            },
            {
                "name": "Leiden",
                "countryCode": "NL"
            },
            {
                "name": "Leiderdorp",
                "countryCode": "NL"
            },
            {
                "name": "Leimuiden",
                "countryCode": "NL"
            },
            {
                "name": "Liesveld",
                "countryCode": "NL"
            },
            {
                "name": "Lisse",
                "countryCode": "NL"
            },
            {
                "name": "Lombardijen",
                "countryCode": "NL"
            },
            {
                "name": "Maasdijk",
                "countryCode": "NL"
            },
            {
                "name": "Maasland",
                "countryCode": "NL"
            },
            {
                "name": "Maassluis",
                "countryCode": "NL"
            },
            {
                "name": "Merenwijk",
                "countryCode": "NL"
            },
            {
                "name": "Middelharnis",
                "countryCode": "NL"
            },
            {
                "name": "Middelsluis",
                "countryCode": "NL"
            },
            {
                "name": "Mijnsheerenland",
                "countryCode": "NL"
            },
            {
                "name": "Monster",
                "countryCode": "NL"
            },
            {
                "name": "Naaldwijk",
                "countryCode": "NL"
            },
            {
                "name": "Neder-Hardinxveld",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Beijerland",
                "countryCode": "NL"
            },
            {
                "name": "Nieuw-Lekkerland",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwenhoorn",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwkoop",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwpoort",
                "countryCode": "NL"
            },
            {
                "name": "Nieuwveen",
                "countryCode": "NL"
            },
            {
                "name": "Nissewaard",
                "countryCode": "NL"
            },
            {
                "name": "Noord-Hofland",
                "countryCode": "NL"
            },
            {
                "name": "Noordeinde",
                "countryCode": "NL"
            },
            {
                "name": "Noordeloos",
                "countryCode": "NL"
            },
            {
                "name": "Noordwijk-Binnen",
                "countryCode": "NL"
            },
            {
                "name": "Noordwijkerhout",
                "countryCode": "NL"
            },
            {
                "name": "Numansdorp",
                "countryCode": "NL"
            },
            {
                "name": "Oegstgeest",
                "countryCode": "NL"
            },
            {
                "name": "Ommoord",
                "countryCode": "NL"
            },
            {
                "name": "Oostdorp",
                "countryCode": "NL"
            },
            {
                "name": "Oostvoorne",
                "countryCode": "NL"
            },
            {
                "name": "Oranjewijk",
                "countryCode": "NL"
            },
            {
                "name": "Oud-Beijerland",
                "countryCode": "NL"
            },
            {
                "name": "Ouddorp",
                "countryCode": "NL"
            },
            {
                "name": "Oude Wetering",
                "countryCode": "NL"
            },
            {
                "name": "Ouderkerk aan den IJssel",
                "countryCode": "NL"
            },
            {
                "name": "Overschie",
                "countryCode": "NL"
            },
            {
                "name": "Palenstein",
                "countryCode": "NL"
            },
            {
                "name": "Papendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Papenveer",
                "countryCode": "NL"
            },
            {
                "name": "Pendrecht",
                "countryCode": "NL"
            },
            {
                "name": "Pernis",
                "countryCode": "NL"
            },
            {
                "name": "Piershil",
                "countryCode": "NL"
            },
            {
                "name": "Pijnacker",
                "countryCode": "NL"
            },
            {
                "name": "Poeldijk",
                "countryCode": "NL"
            },
            {
                "name": "Reeuwijk",
                "countryCode": "NL"
            },
            {
                "name": "Rhoon",
                "countryCode": "NL"
            },
            {
                "name": "Ridderkerk",
                "countryCode": "NL"
            },
            {
                "name": "Rijnsaterwoude",
                "countryCode": "NL"
            },
            {
                "name": "Rijnsburg",
                "countryCode": "NL"
            },
            {
                "name": "Rijpwetering",
                "countryCode": "NL"
            },
            {
                "name": "Rijsoord",
                "countryCode": "NL"
            },
            {
                "name": "Rijswijk",
                "countryCode": "NL"
            },
            {
                "name": "Rockanje",
                "countryCode": "NL"
            },
            {
                "name": "Roelofarendsveen",
                "countryCode": "NL"
            },
            {
                "name": "Rotterdam",
                "countryCode": "NL"
            },
            {
                "name": "Rozenburg",
                "countryCode": "NL"
            },
            {
                "name": "Rugge",
                "countryCode": "NL"
            },
            {
                "name": "Sassenheim",
                "countryCode": "NL"
            },
            {
                "name": "Schelluinen",
                "countryCode": "NL"
            },
            {
                "name": "Scheveningen",
                "countryCode": "NL"
            },
            {
                "name": "Schiebroek",
                "countryCode": "NL"
            },
            {
                "name": "Schiedam",
                "countryCode": "NL"
            },
            {
                "name": "Schipluiden",
                "countryCode": "NL"
            },
            {
                "name": "Schoonhoven",
                "countryCode": "NL"
            },
            {
                "name": "Sliedrecht",
                "countryCode": "NL"
            },
            {
                "name": "Slikkerveer",
                "countryCode": "NL"
            },
            {
                "name": "Spangen",
                "countryCode": "NL"
            },
            {
                "name": "Spijkenisse",
                "countryCode": "NL"
            },
            {
                "name": "Spoorwijk",
                "countryCode": "NL"
            },
            {
                "name": "Statenkwartier",
                "countryCode": "NL"
            },
            {
                "name": "Stein",
                "countryCode": "NL"
            },
            {
                "name": "Stolwijk",
                "countryCode": "NL"
            },
            {
                "name": "Strijen",
                "countryCode": "NL"
            },
            {
                "name": "Strijp",
                "countryCode": "NL"
            },
            {
                "name": "Terbregge",
                "countryCode": "NL"
            },
            {
                "name": "The Hague",
                "countryCode": "NL"
            },
            {
                "name": "Tuindorp",
                "countryCode": "NL"
            },
            {
                "name": "Valkenburg",
                "countryCode": "NL"
            },
            {
                "name": "Vierpolders",
                "countryCode": "NL"
            },
            {
                "name": "Vlaardingen",
                "countryCode": "NL"
            },
            {
                "name": "Vlietwijk",
                "countryCode": "NL"
            },
            {
                "name": "Vogelwijk",
                "countryCode": "NL"
            },
            {
                "name": "Vondelwijk",
                "countryCode": "NL"
            },
            {
                "name": "Voorburg",
                "countryCode": "NL"
            },
            {
                "name": "Voorhout",
                "countryCode": "NL"
            },
            {
                "name": "Voorschoten",
                "countryCode": "NL"
            },
            {
                "name": "Vreewijk",
                "countryCode": "NL"
            },
            {
                "name": "Waarder",
                "countryCode": "NL"
            },
            {
                "name": "Waddinxveen",
                "countryCode": "NL"
            },
            {
                "name": "Warmond",
                "countryCode": "NL"
            },
            {
                "name": "Wassenaar",
                "countryCode": "NL"
            },
            {
                "name": "Weerestein",
                "countryCode": "NL"
            },
            {
                "name": "Weijpoort",
                "countryCode": "NL"
            },
            {
                "name": "Westmaas",
                "countryCode": "NL"
            },
            {
                "name": "Woubrugge",
                "countryCode": "NL"
            },
            {
                "name": "Ypenburg",
                "countryCode": "NL"
            },
            {
                "name": "Zevenhoven",
                "countryCode": "NL"
            },
            {
                "name": "Zoetermeer",
                "countryCode": "NL"
            },
            {
                "name": "Zoeterwoude-Dorp",
                "countryCode": "NL"
            },
            {
                "name": "Zuid-Beijerland",
                "countryCode": "NL"
            },
            {
                "name": "Zuidwijk",
                "countryCode": "NL"
            },
            {
                "name": "Zwijndrecht",
                "countryCode": "NL"
            }
        ]
    },
    {
        "name": "New Caledonia",
        "phonecode": "687",
        "cities": []
    },
    {
        "name": "New Zealand",
        "phonecode": "64",
        "cities": [
            {
                "name": "Auckland",
                "countryCode": "NZ"
            },
            {
                "name": "Mangere",
                "countryCode": "NZ"
            },
            {
                "name": "Manukau City",
                "countryCode": "NZ"
            },
            {
                "name": "Muriwai Beach",
                "countryCode": "NZ"
            },
            {
                "name": "Murrays Bay",
                "countryCode": "NZ"
            },
            {
                "name": "North Shore",
                "countryCode": "NZ"
            },
            {
                "name": "Pakuranga",
                "countryCode": "NZ"
            },
            {
                "name": "Papakura",
                "countryCode": "NZ"
            },
            {
                "name": "Parakai",
                "countryCode": "NZ"
            },
            {
                "name": "Pukekohe East",
                "countryCode": "NZ"
            },
            {
                "name": "Red Hill",
                "countryCode": "NZ"
            },
            {
                "name": "Rosebank",
                "countryCode": "NZ"
            },
            {
                "name": "Rothesay Bay",
                "countryCode": "NZ"
            },
            {
                "name": "Takanini",
                "countryCode": "NZ"
            },
            {
                "name": "Tamaki",
                "countryCode": "NZ"
            },
            {
                "name": "Titirangi",
                "countryCode": "NZ"
            },
            {
                "name": "Waitakere",
                "countryCode": "NZ"
            },
            {
                "name": "Waiuku",
                "countryCode": "NZ"
            },
            {
                "name": "Warkworth",
                "countryCode": "NZ"
            },
            {
                "name": "Wellsford",
                "countryCode": "NZ"
            },
            {
                "name": "Wiri",
                "countryCode": "NZ"
            },
            {
                "name": "Edgecumbe",
                "countryCode": "NZ"
            },
            {
                "name": "Katikati",
                "countryCode": "NZ"
            },
            {
                "name": "Kawerau",
                "countryCode": "NZ"
            },
            {
                "name": "Maketu",
                "countryCode": "NZ"
            },
            {
                "name": "Murupara",
                "countryCode": "NZ"
            },
            {
                "name": "Opotiki",
                "countryCode": "NZ"
            },
            {
                "name": "Rotorua",
                "countryCode": "NZ"
            },
            {
                "name": "Tauranga",
                "countryCode": "NZ"
            },
            {
                "name": "Waihi Beach",
                "countryCode": "NZ"
            },
            {
                "name": "Whakatane",
                "countryCode": "NZ"
            },
            {
                "name": "Amberley",
                "countryCode": "NZ"
            },
            {
                "name": "Ashburton",
                "countryCode": "NZ"
            },
            {
                "name": "Ashburton District",
                "countryCode": "NZ"
            },
            {
                "name": "Burnham",
                "countryCode": "NZ"
            },
            {
                "name": "Christchurch",
                "countryCode": "NZ"
            },
            {
                "name": "Christchurch City",
                "countryCode": "NZ"
            },
            {
                "name": "Darfield",
                "countryCode": "NZ"
            },
            {
                "name": "Geraldine",
                "countryCode": "NZ"
            },
            {
                "name": "Kaiapoi",
                "countryCode": "NZ"
            },
            {
                "name": "Leeston",
                "countryCode": "NZ"
            },
            {
                "name": "Lincoln",
                "countryCode": "NZ"
            },
            {
                "name": "Mackenzie District",
                "countryCode": "NZ"
            },
            {
                "name": "Methven",
                "countryCode": "NZ"
            },
            {
                "name": "Oxford",
                "countryCode": "NZ"
            },
            {
                "name": "Pleasant Point",
                "countryCode": "NZ"
            },
            {
                "name": "Prebbleton",
                "countryCode": "NZ"
            },
            {
                "name": "Rakaia",
                "countryCode": "NZ"
            },
            {
                "name": "Rolleston",
                "countryCode": "NZ"
            },
            {
                "name": "Selwyn District",
                "countryCode": "NZ"
            },
            {
                "name": "Timaru",
                "countryCode": "NZ"
            },
            {
                "name": "Timaru District",
                "countryCode": "NZ"
            },
            {
                "name": "Tinwald",
                "countryCode": "NZ"
            },
            {
                "name": "Waimakariri District",
                "countryCode": "NZ"
            },
            {
                "name": "Woodend",
                "countryCode": "NZ"
            },
            {
                "name": "Waitangi",
                "countryCode": "NZ"
            },
            {
                "name": "Gisborne",
                "countryCode": "NZ"
            },
            {
                "name": "Hastings",
                "countryCode": "NZ"
            },
            {
                "name": "Napier",
                "countryCode": "NZ"
            },
            {
                "name": "Taradale",
                "countryCode": "NZ"
            },
            {
                "name": "Wairoa",
                "countryCode": "NZ"
            },
            {
                "name": "Blenheim",
                "countryCode": "NZ"
            },
            {
                "name": "Picton",
                "countryCode": "NZ"
            },
            {
                "name": "Bulls",
                "countryCode": "NZ"
            },
            {
                "name": "Foxton",
                "countryCode": "NZ"
            },
            {
                "name": "Horowhenua District",
                "countryCode": "NZ"
            },
            {
                "name": "Levin",
                "countryCode": "NZ"
            },
            {
                "name": "Manawatu District",
                "countryCode": "NZ"
            },
            {
                "name": "Palmerston North",
                "countryCode": "NZ"
            },
            {
                "name": "Waiouru",
                "countryCode": "NZ"
            },
            {
                "name": "Wanganui",
                "countryCode": "NZ"
            },
            {
                "name": "Nelson",
                "countryCode": "NZ"
            },
            {
                "name": "Ahipara",
                "countryCode": "NZ"
            },
            {
                "name": "Dargaville",
                "countryCode": "NZ"
            },
            {
                "name": "Far North District",
                "countryCode": "NZ"
            },
            {
                "name": "Kaipara District",
                "countryCode": "NZ"
            },
            {
                "name": "Kaitaia",
                "countryCode": "NZ"
            },
            {
                "name": "Kawakawa",
                "countryCode": "NZ"
            },
            {
                "name": "Kerikeri",
                "countryCode": "NZ"
            },
            {
                "name": "Maungatapere",
                "countryCode": "NZ"
            },
            {
                "name": "Moerewa",
                "countryCode": "NZ"
            },
            {
                "name": "Ngunguru",
                "countryCode": "NZ"
            },
            {
                "name": "Paihia",
                "countryCode": "NZ"
            },
            {
                "name": "Ruakaka",
                "countryCode": "NZ"
            },
            {
                "name": "Taipa",
                "countryCode": "NZ"
            },
            {
                "name": "Waimate North",
                "countryCode": "NZ"
            },
            {
                "name": "Whangarei",
                "countryCode": "NZ"
            },
            {
                "name": "Arrowtown",
                "countryCode": "NZ"
            },
            {
                "name": "Balclutha",
                "countryCode": "NZ"
            },
            {
                "name": "Clutha District",
                "countryCode": "NZ"
            },
            {
                "name": "Cromwell",
                "countryCode": "NZ"
            },
            {
                "name": "Dunedin",
                "countryCode": "NZ"
            },
            {
                "name": "Kingston",
                "countryCode": "NZ"
            },
            {
                "name": "Milton",
                "countryCode": "NZ"
            },
            {
                "name": "Oamaru",
                "countryCode": "NZ"
            },
            {
                "name": "Papatowai",
                "countryCode": "NZ"
            },
            {
                "name": "Portobello",
                "countryCode": "NZ"
            },
            {
                "name": "Queenstown",
                "countryCode": "NZ"
            },
            {
                "name": "Wanaka",
                "countryCode": "NZ"
            },
            {
                "name": "Bluff",
                "countryCode": "NZ"
            },
            {
                "name": "Gore",
                "countryCode": "NZ"
            },
            {
                "name": "Invercargill",
                "countryCode": "NZ"
            },
            {
                "name": "Riverton",
                "countryCode": "NZ"
            },
            {
                "name": "Southland District",
                "countryCode": "NZ"
            },
            {
                "name": "Te Anau",
                "countryCode": "NZ"
            },
            {
                "name": "Winton",
                "countryCode": "NZ"
            },
            {
                "name": "Brightwater",
                "countryCode": "NZ"
            },
            {
                "name": "Mapua",
                "countryCode": "NZ"
            },
            {
                "name": "Motueka",
                "countryCode": "NZ"
            },
            {
                "name": "Richmond",
                "countryCode": "NZ"
            },
            {
                "name": "Takaka",
                "countryCode": "NZ"
            },
            {
                "name": "Wakefield",
                "countryCode": "NZ"
            },
            {
                "name": "Eltham",
                "countryCode": "NZ"
            },
            {
                "name": "Hawera",
                "countryCode": "NZ"
            },
            {
                "name": "New Plymouth",
                "countryCode": "NZ"
            },
            {
                "name": "New Plymouth District",
                "countryCode": "NZ"
            },
            {
                "name": "Opunake",
                "countryCode": "NZ"
            },
            {
                "name": "Patea",
                "countryCode": "NZ"
            },
            {
                "name": "South Taranaki District",
                "countryCode": "NZ"
            },
            {
                "name": "Waitara",
                "countryCode": "NZ"
            },
            {
                "name": "Brooklyn",
                "countryCode": "NZ"
            },
            {
                "name": "Castlepoint",
                "countryCode": "NZ"
            },
            {
                "name": "Kapiti Coast District",
                "countryCode": "NZ"
            },
            {
                "name": "Kelburn",
                "countryCode": "NZ"
            },
            {
                "name": "Khandallah",
                "countryCode": "NZ"
            },
            {
                "name": "Lower Hutt",
                "countryCode": "NZ"
            },
            {
                "name": "Masterton",
                "countryCode": "NZ"
            },
            {
                "name": "Otaki",
                "countryCode": "NZ"
            },
            {
                "name": "Paraparaumu",
                "countryCode": "NZ"
            },
            {
                "name": "Petone",
                "countryCode": "NZ"
            },
            {
                "name": "Porirua",
                "countryCode": "NZ"
            },
            {
                "name": "South Wairarapa District",
                "countryCode": "NZ"
            },
            {
                "name": "Upper Hutt",
                "countryCode": "NZ"
            },
            {
                "name": "Waipawa",
                "countryCode": "NZ"
            },
            {
                "name": "Wellington",
                "countryCode": "NZ"
            },
            {
                "name": "Wellington City",
                "countryCode": "NZ"
            },
            {
                "name": "Cambridge",
                "countryCode": "NZ"
            },
            {
                "name": "Coromandel",
                "countryCode": "NZ"
            },
            {
                "name": "Hamilton",
                "countryCode": "NZ"
            },
            {
                "name": "Matamata",
                "countryCode": "NZ"
            },
            {
                "name": "Ngaruawahia",
                "countryCode": "NZ"
            },
            {
                "name": "Ngatea",
                "countryCode": "NZ"
            },
            {
                "name": "Otorohanga",
                "countryCode": "NZ"
            },
            {
                "name": "Paeroa",
                "countryCode": "NZ"
            },
            {
                "name": "Raglan",
                "countryCode": "NZ"
            },
            {
                "name": "South Waikato District",
                "countryCode": "NZ"
            },
            {
                "name": "Tairua",
                "countryCode": "NZ"
            },
            {
                "name": "Taupo",
                "countryCode": "NZ"
            },
            {
                "name": "Te Kauwhata",
                "countryCode": "NZ"
            },
            {
                "name": "Thames",
                "countryCode": "NZ"
            },
            {
                "name": "Tokoroa",
                "countryCode": "NZ"
            },
            {
                "name": "Turangi",
                "countryCode": "NZ"
            },
            {
                "name": "Waihi",
                "countryCode": "NZ"
            },
            {
                "name": "Whangamata",
                "countryCode": "NZ"
            },
            {
                "name": "Whitianga",
                "countryCode": "NZ"
            },
            {
                "name": "Greymouth",
                "countryCode": "NZ"
            },
            {
                "name": "Hokitika",
                "countryCode": "NZ"
            },
            {
                "name": "Westport",
                "countryCode": "NZ"
            }
        ]
    },
    {
        "name": "Nicaragua",
        "phonecode": "505",
        "cities": [
            {
                "name": "Bonanza",
                "countryCode": "NI"
            },
            {
                "name": "Prinzapolka",
                "countryCode": "NI"
            },
            {
                "name": "Puerto Cabezas",
                "countryCode": "NI"
            },
            {
                "name": "Siuna",
                "countryCode": "NI"
            },
            {
                "name": "Waslala",
                "countryCode": "NI"
            },
            {
                "name": "Waspán",
                "countryCode": "NI"
            },
            {
                "name": "Bluefields",
                "countryCode": "NI"
            },
            {
                "name": "Bocana de Paiwas",
                "countryCode": "NI"
            },
            {
                "name": "Corn Island",
                "countryCode": "NI"
            },
            {
                "name": "El Rama",
                "countryCode": "NI"
            },
            {
                "name": "El Tortuguero",
                "countryCode": "NI"
            },
            {
                "name": "Kukrahill",
                "countryCode": "NI"
            },
            {
                "name": "La Cruz de Río Grande",
                "countryCode": "NI"
            },
            {
                "name": "Laguna de Perlas",
                "countryCode": "NI"
            },
            {
                "name": "Muelle de los Bueyes",
                "countryCode": "NI"
            },
            {
                "name": "Nueva Guinea",
                "countryCode": "NI"
            },
            {
                "name": "Boaco",
                "countryCode": "NI"
            },
            {
                "name": "Camoapa",
                "countryCode": "NI"
            },
            {
                "name": "San José de los Remates",
                "countryCode": "NI"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "NI"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "NI"
            },
            {
                "name": "Teustepe",
                "countryCode": "NI"
            },
            {
                "name": "Diriamba",
                "countryCode": "NI"
            },
            {
                "name": "Dolores",
                "countryCode": "NI"
            },
            {
                "name": "El Rosario",
                "countryCode": "NI"
            },
            {
                "name": "Jinotepe",
                "countryCode": "NI"
            },
            {
                "name": "La Conquista",
                "countryCode": "NI"
            },
            {
                "name": "La Paz de Carazo",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de San Marcos",
                "countryCode": "NI"
            },
            {
                "name": "San Marcos",
                "countryCode": "NI"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "NI"
            },
            {
                "name": "Chichigalpa",
                "countryCode": "NI"
            },
            {
                "name": "Chinandega",
                "countryCode": "NI"
            },
            {
                "name": "Cinco Pinos",
                "countryCode": "NI"
            },
            {
                "name": "Corinto",
                "countryCode": "NI"
            },
            {
                "name": "El Realejo",
                "countryCode": "NI"
            },
            {
                "name": "El Viejo",
                "countryCode": "NI"
            },
            {
                "name": "Jiquilillo",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de San Francisco del Norte",
                "countryCode": "NI"
            },
            {
                "name": "Posoltega",
                "countryCode": "NI"
            },
            {
                "name": "Puerto Morazán",
                "countryCode": "NI"
            },
            {
                "name": "Santo Tomás del Norte",
                "countryCode": "NI"
            },
            {
                "name": "Somotillo",
                "countryCode": "NI"
            },
            {
                "name": "Acoyapa",
                "countryCode": "NI"
            },
            {
                "name": "Comalapa",
                "countryCode": "NI"
            },
            {
                "name": "Cuapa",
                "countryCode": "NI"
            },
            {
                "name": "El Ayote",
                "countryCode": "NI"
            },
            {
                "name": "Juigalpa",
                "countryCode": "NI"
            },
            {
                "name": "La Libertad",
                "countryCode": "NI"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "NI"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "NI"
            },
            {
                "name": "Villa Sandino",
                "countryCode": "NI"
            },
            {
                "name": "Condega",
                "countryCode": "NI"
            },
            {
                "name": "Estelí",
                "countryCode": "NI"
            },
            {
                "name": "La Trinidad",
                "countryCode": "NI"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "NI"
            },
            {
                "name": "San Juan de Limay",
                "countryCode": "NI"
            },
            {
                "name": "Diriomo",
                "countryCode": "NI"
            },
            {
                "name": "Diriá",
                "countryCode": "NI"
            },
            {
                "name": "Granada",
                "countryCode": "NI"
            },
            {
                "name": "Nandaime",
                "countryCode": "NI"
            },
            {
                "name": "El Cuá",
                "countryCode": "NI"
            },
            {
                "name": "Jinotega",
                "countryCode": "NI"
            },
            {
                "name": "LLano de La Cruz",
                "countryCode": "NI"
            },
            {
                "name": "La Concordia",
                "countryCode": "NI"
            },
            {
                "name": "Las Praderas",
                "countryCode": "NI"
            },
            {
                "name": "San José de Bocay",
                "countryCode": "NI"
            },
            {
                "name": "San Rafael del Norte",
                "countryCode": "NI"
            },
            {
                "name": "San Sebastián de Yalí",
                "countryCode": "NI"
            },
            {
                "name": "Achuapa",
                "countryCode": "NI"
            },
            {
                "name": "El Jicaral",
                "countryCode": "NI"
            },
            {
                "name": "El Sauce",
                "countryCode": "NI"
            },
            {
                "name": "La Paz Centro",
                "countryCode": "NI"
            },
            {
                "name": "Larreynaga",
                "countryCode": "NI"
            },
            {
                "name": "León",
                "countryCode": "NI"
            },
            {
                "name": "Nagarote",
                "countryCode": "NI"
            },
            {
                "name": "Quezalguaque",
                "countryCode": "NI"
            },
            {
                "name": "Santa Rosa del Peñón",
                "countryCode": "NI"
            },
            {
                "name": "Telica",
                "countryCode": "NI"
            },
            {
                "name": "Las Sabanas",
                "countryCode": "NI"
            },
            {
                "name": "Palacagüina",
                "countryCode": "NI"
            },
            {
                "name": "San José de Cusmapa",
                "countryCode": "NI"
            },
            {
                "name": "San Juan de Río Coco",
                "countryCode": "NI"
            },
            {
                "name": "San Lucas",
                "countryCode": "NI"
            },
            {
                "name": "Somoto",
                "countryCode": "NI"
            },
            {
                "name": "Telpaneca",
                "countryCode": "NI"
            },
            {
                "name": "Totogalpa",
                "countryCode": "NI"
            },
            {
                "name": "Yalagüina",
                "countryCode": "NI"
            },
            {
                "name": "Ciudad Sandino",
                "countryCode": "NI"
            },
            {
                "name": "El Crucero",
                "countryCode": "NI"
            },
            {
                "name": "Managua",
                "countryCode": "NI"
            },
            {
                "name": "Masachapa",
                "countryCode": "NI"
            },
            {
                "name": "San Rafael del Sur",
                "countryCode": "NI"
            },
            {
                "name": "Ticuantepe",
                "countryCode": "NI"
            },
            {
                "name": "Tipitapa",
                "countryCode": "NI"
            },
            {
                "name": "Valle San Francisco",
                "countryCode": "NI"
            },
            {
                "name": "Villa El Carmen",
                "countryCode": "NI"
            },
            {
                "name": "Catarina",
                "countryCode": "NI"
            },
            {
                "name": "La Concepción",
                "countryCode": "NI"
            },
            {
                "name": "Masatepe",
                "countryCode": "NI"
            },
            {
                "name": "Masaya",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Masatepe",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Nandasmo",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Niquinohomo",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de San Juan de Oriente",
                "countryCode": "NI"
            },
            {
                "name": "Nandasmo",
                "countryCode": "NI"
            },
            {
                "name": "Nindirí",
                "countryCode": "NI"
            },
            {
                "name": "Niquinohomo",
                "countryCode": "NI"
            },
            {
                "name": "San Juan de Oriente",
                "countryCode": "NI"
            },
            {
                "name": "Tisma",
                "countryCode": "NI"
            },
            {
                "name": "Ciudad Darío",
                "countryCode": "NI"
            },
            {
                "name": "Matagalpa",
                "countryCode": "NI"
            },
            {
                "name": "Matiguás",
                "countryCode": "NI"
            },
            {
                "name": "Muy Muy",
                "countryCode": "NI"
            },
            {
                "name": "Río Blanco",
                "countryCode": "NI"
            },
            {
                "name": "San Dionisio",
                "countryCode": "NI"
            },
            {
                "name": "San Ramón",
                "countryCode": "NI"
            },
            {
                "name": "Terrabona",
                "countryCode": "NI"
            },
            {
                "name": "Ciudad Antigua",
                "countryCode": "NI"
            },
            {
                "name": "Dipilto",
                "countryCode": "NI"
            },
            {
                "name": "El Jícaro",
                "countryCode": "NI"
            },
            {
                "name": "Jalapa",
                "countryCode": "NI"
            },
            {
                "name": "Macuelizo",
                "countryCode": "NI"
            },
            {
                "name": "Mozonte",
                "countryCode": "NI"
            },
            {
                "name": "Murra",
                "countryCode": "NI"
            },
            {
                "name": "Ocotal",
                "countryCode": "NI"
            },
            {
                "name": "Quilalí",
                "countryCode": "NI"
            },
            {
                "name": "San Fernando",
                "countryCode": "NI"
            },
            {
                "name": "Santa María",
                "countryCode": "NI"
            },
            {
                "name": "Wiwilí",
                "countryCode": "NI"
            },
            {
                "name": "Altagracia",
                "countryCode": "NI"
            },
            {
                "name": "Belén",
                "countryCode": "NI"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "NI"
            },
            {
                "name": "Cárdenas",
                "countryCode": "NI"
            },
            {
                "name": "Moyogalpa",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Altagracia",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Belén",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Buenos Aires",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Cárdenas",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Moyogalpa",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Potosí",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Rivas",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de San Jorge",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de San Juan del Sur",
                "countryCode": "NI"
            },
            {
                "name": "Municipio de Tola",
                "countryCode": "NI"
            },
            {
                "name": "Potosí",
                "countryCode": "NI"
            },
            {
                "name": "Rivas",
                "countryCode": "NI"
            },
            {
                "name": "San Jorge",
                "countryCode": "NI"
            },
            {
                "name": "San Juan del Sur",
                "countryCode": "NI"
            },
            {
                "name": "Tola",
                "countryCode": "NI"
            },
            {
                "name": "El Almendro",
                "countryCode": "NI"
            },
            {
                "name": "Greytown",
                "countryCode": "NI"
            },
            {
                "name": "Morrito",
                "countryCode": "NI"
            },
            {
                "name": "San Carlos",
                "countryCode": "NI"
            },
            {
                "name": "San Miguelito",
                "countryCode": "NI"
            }
        ]
    },
    {
        "name": "Niger",
        "phonecode": "227",
        "cities": [
            {
                "name": "Agadez",
                "countryCode": "NE"
            },
            {
                "name": "Alaghsas",
                "countryCode": "NE"
            },
            {
                "name": "Arlit",
                "countryCode": "NE"
            },
            {
                "name": "Bilma",
                "countryCode": "NE"
            },
            {
                "name": "Département de Bilma",
                "countryCode": "NE"
            },
            {
                "name": "Département de Tchirozérine",
                "countryCode": "NE"
            },
            {
                "name": "Diffa",
                "countryCode": "NE"
            },
            {
                "name": "Département de Diffa",
                "countryCode": "NE"
            },
            {
                "name": "Département de Maïné-Soroa",
                "countryCode": "NE"
            },
            {
                "name": "Département de Nguigmi",
                "countryCode": "NE"
            },
            {
                "name": "Maïné Soroa",
                "countryCode": "NE"
            },
            {
                "name": "Nguigmi",
                "countryCode": "NE"
            },
            {
                "name": "Boboye Department",
                "countryCode": "NE"
            },
            {
                "name": "Dogondoutchi",
                "countryCode": "NE"
            },
            {
                "name": "Dosso",
                "countryCode": "NE"
            },
            {
                "name": "Département de Dogondoutchi",
                "countryCode": "NE"
            },
            {
                "name": "Département de Dosso",
                "countryCode": "NE"
            },
            {
                "name": "Département de Gaya",
                "countryCode": "NE"
            },
            {
                "name": "Département de Loga",
                "countryCode": "NE"
            },
            {
                "name": "Gaya",
                "countryCode": "NE"
            },
            {
                "name": "Aguié",
                "countryCode": "NE"
            },
            {
                "name": "Dakoro",
                "countryCode": "NE"
            },
            {
                "name": "Département de Dakoro",
                "countryCode": "NE"
            },
            {
                "name": "Département de Mayahi",
                "countryCode": "NE"
            },
            {
                "name": "Département de Tessaoua",
                "countryCode": "NE"
            },
            {
                "name": "Département d’Aguié",
                "countryCode": "NE"
            },
            {
                "name": "Guidan Roumdji Department",
                "countryCode": "NE"
            },
            {
                "name": "Madarounfa",
                "countryCode": "NE"
            },
            {
                "name": "Maradi",
                "countryCode": "NE"
            },
            {
                "name": "Mayahi",
                "countryCode": "NE"
            },
            {
                "name": "Tessaoua",
                "countryCode": "NE"
            },
            {
                "name": "Tibiri",
                "countryCode": "NE"
            },
            {
                "name": "Abalak",
                "countryCode": "NE"
            },
            {
                "name": "Birni N Konni",
                "countryCode": "NE"
            },
            {
                "name": "Bouza",
                "countryCode": "NE"
            },
            {
                "name": "Département d' Illéla",
                "countryCode": "NE"
            },
            {
                "name": "Département de Birni Nkonni",
                "countryCode": "NE"
            },
            {
                "name": "Département de Bouza",
                "countryCode": "NE"
            },
            {
                "name": "Département de Keïta",
                "countryCode": "NE"
            },
            {
                "name": "Département de Madaoua",
                "countryCode": "NE"
            },
            {
                "name": "Département de Tahoua",
                "countryCode": "NE"
            },
            {
                "name": "Département de Tchin-Tabaraden",
                "countryCode": "NE"
            },
            {
                "name": "Illéla",
                "countryCode": "NE"
            },
            {
                "name": "Keïta",
                "countryCode": "NE"
            },
            {
                "name": "Madaoua",
                "countryCode": "NE"
            },
            {
                "name": "Tahoua",
                "countryCode": "NE"
            },
            {
                "name": "Tchintabaraden",
                "countryCode": "NE"
            },
            {
                "name": "Ayorou",
                "countryCode": "NE"
            },
            {
                "name": "Balleyara",
                "countryCode": "NE"
            },
            {
                "name": "Département de Filingué",
                "countryCode": "NE"
            },
            {
                "name": "Département de Ouallam",
                "countryCode": "NE"
            },
            {
                "name": "Département de Say",
                "countryCode": "NE"
            },
            {
                "name": "Filingué",
                "countryCode": "NE"
            },
            {
                "name": "Kollo",
                "countryCode": "NE"
            },
            {
                "name": "Kollo Department",
                "countryCode": "NE"
            },
            {
                "name": "Ouallam",
                "countryCode": "NE"
            },
            {
                "name": "Say",
                "countryCode": "NE"
            },
            {
                "name": "Tera Department",
                "countryCode": "NE"
            },
            {
                "name": "Tillaberi Department",
                "countryCode": "NE"
            },
            {
                "name": "Tillabéri",
                "countryCode": "NE"
            },
            {
                "name": "Téra",
                "countryCode": "NE"
            },
            {
                "name": "Département de Gouré",
                "countryCode": "NE"
            },
            {
                "name": "Département de Kantché",
                "countryCode": "NE"
            },
            {
                "name": "Département de Tânout",
                "countryCode": "NE"
            },
            {
                "name": "Gouré",
                "countryCode": "NE"
            },
            {
                "name": "Magaria",
                "countryCode": "NE"
            },
            {
                "name": "Matamey",
                "countryCode": "NE"
            },
            {
                "name": "Mirriah",
                "countryCode": "NE"
            },
            {
                "name": "Tanout",
                "countryCode": "NE"
            },
            {
                "name": "Zinder",
                "countryCode": "NE"
            }
        ]
    },
    {
        "name": "Nigeria",
        "phonecode": "234",
        "cities": [
            {
                "name": "Aba",
                "countryCode": "NG"
            },
            {
                "name": "Amaigbo",
                "countryCode": "NG"
            },
            {
                "name": "Arochukwu",
                "countryCode": "NG"
            },
            {
                "name": "Bende",
                "countryCode": "NG"
            },
            {
                "name": "Ohafia-Ifigh",
                "countryCode": "NG"
            },
            {
                "name": "Umuahia",
                "countryCode": "NG"
            },
            {
                "name": "Ganye",
                "countryCode": "NG"
            },
            {
                "name": "Gombi",
                "countryCode": "NG"
            },
            {
                "name": "Holma",
                "countryCode": "NG"
            },
            {
                "name": "Jimeta",
                "countryCode": "NG"
            },
            {
                "name": "Madagali",
                "countryCode": "NG"
            },
            {
                "name": "Mayo-Belwa",
                "countryCode": "NG"
            },
            {
                "name": "Mubi",
                "countryCode": "NG"
            },
            {
                "name": "Ngurore",
                "countryCode": "NG"
            },
            {
                "name": "Numan",
                "countryCode": "NG"
            },
            {
                "name": "Toungo",
                "countryCode": "NG"
            },
            {
                "name": "Yola",
                "countryCode": "NG"
            },
            {
                "name": "Eket",
                "countryCode": "NG"
            },
            {
                "name": "Esuk Oron",
                "countryCode": "NG"
            },
            {
                "name": "Ikot Ekpene",
                "countryCode": "NG"
            },
            {
                "name": "Itu",
                "countryCode": "NG"
            },
            {
                "name": "Uyo",
                "countryCode": "NG"
            },
            {
                "name": "Agulu",
                "countryCode": "NG"
            },
            {
                "name": "Atani",
                "countryCode": "NG"
            },
            {
                "name": "Awka",
                "countryCode": "NG"
            },
            {
                "name": "Enugu-Ukwu",
                "countryCode": "NG"
            },
            {
                "name": "Igbo-Ukwu",
                "countryCode": "NG"
            },
            {
                "name": "Ihiala",
                "countryCode": "NG"
            },
            {
                "name": "Nkpor",
                "countryCode": "NG"
            },
            {
                "name": "Nnewi",
                "countryCode": "NG"
            },
            {
                "name": "Onitsha",
                "countryCode": "NG"
            },
            {
                "name": "Ozubulu",
                "countryCode": "NG"
            },
            {
                "name": "Uga",
                "countryCode": "NG"
            },
            {
                "name": "Uruobo-Okija",
                "countryCode": "NG"
            },
            {
                "name": "Azare",
                "countryCode": "NG"
            },
            {
                "name": "Bauchi",
                "countryCode": "NG"
            },
            {
                "name": "Boi",
                "countryCode": "NG"
            },
            {
                "name": "Bununu",
                "countryCode": "NG"
            },
            {
                "name": "Darazo",
                "countryCode": "NG"
            },
            {
                "name": "Dass",
                "countryCode": "NG"
            },
            {
                "name": "Dindima",
                "countryCode": "NG"
            },
            {
                "name": "Disina",
                "countryCode": "NG"
            },
            {
                "name": "Gabarin",
                "countryCode": "NG"
            },
            {
                "name": "Gwaram",
                "countryCode": "NG"
            },
            {
                "name": "Kari",
                "countryCode": "NG"
            },
            {
                "name": "Lame",
                "countryCode": "NG"
            },
            {
                "name": "Lere",
                "countryCode": "NG"
            },
            {
                "name": "Madara",
                "countryCode": "NG"
            },
            {
                "name": "Misau",
                "countryCode": "NG"
            },
            {
                "name": "Sade",
                "countryCode": "NG"
            },
            {
                "name": "Yamrat",
                "countryCode": "NG"
            },
            {
                "name": "Yanda Bayo",
                "countryCode": "NG"
            },
            {
                "name": "Yuli",
                "countryCode": "NG"
            },
            {
                "name": "Zadawa",
                "countryCode": "NG"
            },
            {
                "name": "Zalanga",
                "countryCode": "NG"
            },
            {
                "name": "Aliade",
                "countryCode": "NG"
            },
            {
                "name": "Boju",
                "countryCode": "NG"
            },
            {
                "name": "Gboko",
                "countryCode": "NG"
            },
            {
                "name": "Igbor",
                "countryCode": "NG"
            },
            {
                "name": "Makurdi",
                "countryCode": "NG"
            },
            {
                "name": "Ochobo",
                "countryCode": "NG"
            },
            {
                "name": "Otukpa",
                "countryCode": "NG"
            },
            {
                "name": "Takum",
                "countryCode": "NG"
            },
            {
                "name": "Ugbokpo",
                "countryCode": "NG"
            },
            {
                "name": "Yandev",
                "countryCode": "NG"
            },
            {
                "name": "Zaki Biam",
                "countryCode": "NG"
            },
            {
                "name": "Bama",
                "countryCode": "NG"
            },
            {
                "name": "Benisheikh",
                "countryCode": "NG"
            },
            {
                "name": "Biu",
                "countryCode": "NG"
            },
            {
                "name": "Bornu Yassu",
                "countryCode": "NG"
            },
            {
                "name": "Damasak",
                "countryCode": "NG"
            },
            {
                "name": "Damboa",
                "countryCode": "NG"
            },
            {
                "name": "Dikwa",
                "countryCode": "NG"
            },
            {
                "name": "Gamboru",
                "countryCode": "NG"
            },
            {
                "name": "Gwoza",
                "countryCode": "NG"
            },
            {
                "name": "Kukawa",
                "countryCode": "NG"
            },
            {
                "name": "Magumeri",
                "countryCode": "NG"
            },
            {
                "name": "Maiduguri",
                "countryCode": "NG"
            },
            {
                "name": "Marte",
                "countryCode": "NG"
            },
            {
                "name": "Miringa",
                "countryCode": "NG"
            },
            {
                "name": "Monguno",
                "countryCode": "NG"
            },
            {
                "name": "Ngala",
                "countryCode": "NG"
            },
            {
                "name": "Shaffa",
                "countryCode": "NG"
            },
            {
                "name": "Shani",
                "countryCode": "NG"
            },
            {
                "name": "Tokombere",
                "countryCode": "NG"
            },
            {
                "name": "Uba",
                "countryCode": "NG"
            },
            {
                "name": "Wuyo",
                "countryCode": "NG"
            },
            {
                "name": "Yajiwa",
                "countryCode": "NG"
            },
            {
                "name": "Amassoma",
                "countryCode": "NG"
            },
            {
                "name": "Twon-Brass",
                "countryCode": "NG"
            },
            {
                "name": "Yenagoa",
                "countryCode": "NG"
            },
            {
                "name": "Akankpa",
                "countryCode": "NG"
            },
            {
                "name": "Calabar",
                "countryCode": "NG"
            },
            {
                "name": "Gakem",
                "countryCode": "NG"
            },
            {
                "name": "Ikang",
                "countryCode": "NG"
            },
            {
                "name": "Ugep",
                "countryCode": "NG"
            },
            {
                "name": "Abraka",
                "countryCode": "NG"
            },
            {
                "name": "Agbor",
                "countryCode": "NG"
            },
            {
                "name": "Asaba",
                "countryCode": "NG"
            },
            {
                "name": "Bomadi",
                "countryCode": "NG"
            },
            {
                "name": "Burutu",
                "countryCode": "NG"
            },
            {
                "name": "Kwale",
                "countryCode": "NG"
            },
            {
                "name": "Obiaruku",
                "countryCode": "NG"
            },
            {
                "name": "Ogwashi-Uku",
                "countryCode": "NG"
            },
            {
                "name": "Orerokpe",
                "countryCode": "NG"
            },
            {
                "name": "Patani",
                "countryCode": "NG"
            },
            {
                "name": "Sapele",
                "countryCode": "NG"
            },
            {
                "name": "Ughelli",
                "countryCode": "NG"
            },
            {
                "name": "Umunede",
                "countryCode": "NG"
            },
            {
                "name": "Warri",
                "countryCode": "NG"
            },
            {
                "name": "Abakaliki",
                "countryCode": "NG"
            },
            {
                "name": "Afikpo",
                "countryCode": "NG"
            },
            {
                "name": "Effium",
                "countryCode": "NG"
            },
            {
                "name": "Ezza-Ohu",
                "countryCode": "NG"
            },
            {
                "name": "Isieke",
                "countryCode": "NG"
            },
            {
                "name": "Agenebode",
                "countryCode": "NG"
            },
            {
                "name": "Auchi",
                "countryCode": "NG"
            },
            {
                "name": "Benin City",
                "countryCode": "NG"
            },
            {
                "name": "Ekpoma",
                "countryCode": "NG"
            },
            {
                "name": "Igarra",
                "countryCode": "NG"
            },
            {
                "name": "Illushi",
                "countryCode": "NG"
            },
            {
                "name": "Siluko",
                "countryCode": "NG"
            },
            {
                "name": "Ubiaja",
                "countryCode": "NG"
            },
            {
                "name": "Uromi",
                "countryCode": "NG"
            },
            {
                "name": "Ado-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Aramoko-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Efon-Alaaye",
                "countryCode": "NG"
            },
            {
                "name": "Emure-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Ifaki",
                "countryCode": "NG"
            },
            {
                "name": "Igbara-Odo",
                "countryCode": "NG"
            },
            {
                "name": "Igede-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Ijero-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Ikere-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Ipoti",
                "countryCode": "NG"
            },
            {
                "name": "Ise-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Oke Ila",
                "countryCode": "NG"
            },
            {
                "name": "Omuo-Ekiti",
                "countryCode": "NG"
            },
            {
                "name": "Adani",
                "countryCode": "NG"
            },
            {
                "name": "Ake-Eze",
                "countryCode": "NG"
            },
            {
                "name": "Aku",
                "countryCode": "NG"
            },
            {
                "name": "Amagunze",
                "countryCode": "NG"
            },
            {
                "name": "Awgu",
                "countryCode": "NG"
            },
            {
                "name": "Eha Amufu",
                "countryCode": "NG"
            },
            {
                "name": "Enugu",
                "countryCode": "NG"
            },
            {
                "name": "Enugu-Ezike",
                "countryCode": "NG"
            },
            {
                "name": "Ete",
                "countryCode": "NG"
            },
            {
                "name": "Ikem",
                "countryCode": "NG"
            },
            {
                "name": "Mberubu",
                "countryCode": "NG"
            },
            {
                "name": "Nsukka",
                "countryCode": "NG"
            },
            {
                "name": "Obolo-Eke (1)",
                "countryCode": "NG"
            },
            {
                "name": "Opi",
                "countryCode": "NG"
            },
            {
                "name": "Udi",
                "countryCode": "NG"
            },
            {
                "name": "Abuja",
                "countryCode": "NG"
            },
            {
                "name": "Kuje",
                "countryCode": "NG"
            },
            {
                "name": "Kwali",
                "countryCode": "NG"
            },
            {
                "name": "Madala",
                "countryCode": "NG"
            },
            {
                "name": "Akko",
                "countryCode": "NG"
            },
            {
                "name": "Bara",
                "countryCode": "NG"
            },
            {
                "name": "Billiri",
                "countryCode": "NG"
            },
            {
                "name": "Dadiya",
                "countryCode": "NG"
            },
            {
                "name": "Deba",
                "countryCode": "NG"
            },
            {
                "name": "Dukku",
                "countryCode": "NG"
            },
            {
                "name": "Garko",
                "countryCode": "NG"
            },
            {
                "name": "Gombe",
                "countryCode": "NG"
            },
            {
                "name": "Hinna",
                "countryCode": "NG"
            },
            {
                "name": "Kafarati",
                "countryCode": "NG"
            },
            {
                "name": "Kaltungo",
                "countryCode": "NG"
            },
            {
                "name": "Kumo",
                "countryCode": "NG"
            },
            {
                "name": "Nafada",
                "countryCode": "NG"
            },
            {
                "name": "Pindiga",
                "countryCode": "NG"
            },
            {
                "name": "Iho",
                "countryCode": "NG"
            },
            {
                "name": "Oguta",
                "countryCode": "NG"
            },
            {
                "name": "Okigwe",
                "countryCode": "NG"
            },
            {
                "name": "Orlu",
                "countryCode": "NG"
            },
            {
                "name": "Orodo",
                "countryCode": "NG"
            },
            {
                "name": "Owerri",
                "countryCode": "NG"
            },
            {
                "name": "Babura",
                "countryCode": "NG"
            },
            {
                "name": "Birnin Kudu",
                "countryCode": "NG"
            },
            {
                "name": "Birniwa",
                "countryCode": "NG"
            },
            {
                "name": "Dutse",
                "countryCode": "NG"
            },
            {
                "name": "Gagarawa",
                "countryCode": "NG"
            },
            {
                "name": "Gumel",
                "countryCode": "NG"
            },
            {
                "name": "Gwaram",
                "countryCode": "NG"
            },
            {
                "name": "Hadejia",
                "countryCode": "NG"
            },
            {
                "name": "Kafin Hausa",
                "countryCode": "NG"
            },
            {
                "name": "Kazaure",
                "countryCode": "NG"
            },
            {
                "name": "Kiyawa",
                "countryCode": "NG"
            },
            {
                "name": "Mallammaduri",
                "countryCode": "NG"
            },
            {
                "name": "Ringim",
                "countryCode": "NG"
            },
            {
                "name": "Samamiya",
                "countryCode": "NG"
            },
            {
                "name": "Anchau",
                "countryCode": "NG"
            },
            {
                "name": "Burumburum",
                "countryCode": "NG"
            },
            {
                "name": "Dutsen Wai",
                "countryCode": "NG"
            },
            {
                "name": "Hunkuyi",
                "countryCode": "NG"
            },
            {
                "name": "Kachia",
                "countryCode": "NG"
            },
            {
                "name": "Kaduna",
                "countryCode": "NG"
            },
            {
                "name": "Kafanchan",
                "countryCode": "NG"
            },
            {
                "name": "Kagoro",
                "countryCode": "NG"
            },
            {
                "name": "Kajuru",
                "countryCode": "NG"
            },
            {
                "name": "Kujama",
                "countryCode": "NG"
            },
            {
                "name": "Lere",
                "countryCode": "NG"
            },
            {
                "name": "Mando",
                "countryCode": "NG"
            },
            {
                "name": "Saminaka",
                "countryCode": "NG"
            },
            {
                "name": "Soba",
                "countryCode": "NG"
            },
            {
                "name": "Sofo-Birnin-Gwari",
                "countryCode": "NG"
            },
            {
                "name": "Zaria",
                "countryCode": "NG"
            },
            {
                "name": "Argungu",
                "countryCode": "NG"
            },
            {
                "name": "Bagudo",
                "countryCode": "NG"
            },
            {
                "name": "Bena",
                "countryCode": "NG"
            },
            {
                "name": "Bin Yauri",
                "countryCode": "NG"
            },
            {
                "name": "Birnin Kebbi",
                "countryCode": "NG"
            },
            {
                "name": "Dabai",
                "countryCode": "NG"
            },
            {
                "name": "Dakingari",
                "countryCode": "NG"
            },
            {
                "name": "Gulma",
                "countryCode": "NG"
            },
            {
                "name": "Gwandu",
                "countryCode": "NG"
            },
            {
                "name": "Jega",
                "countryCode": "NG"
            },
            {
                "name": "Kamba",
                "countryCode": "NG"
            },
            {
                "name": "Kangiwa",
                "countryCode": "NG"
            },
            {
                "name": "Kende",
                "countryCode": "NG"
            },
            {
                "name": "Mahuta",
                "countryCode": "NG"
            },
            {
                "name": "Maiyama",
                "countryCode": "NG"
            },
            {
                "name": "Shanga",
                "countryCode": "NG"
            },
            {
                "name": "Wasagu",
                "countryCode": "NG"
            },
            {
                "name": "Zuru",
                "countryCode": "NG"
            },
            {
                "name": "Dan Gora",
                "countryCode": "NG"
            },
            {
                "name": "Gaya",
                "countryCode": "NG"
            },
            {
                "name": "Kano",
                "countryCode": "NG"
            },
            {
                "name": "Abocho",
                "countryCode": "NG"
            },
            {
                "name": "Adoru",
                "countryCode": "NG"
            },
            {
                "name": "Ankpa",
                "countryCode": "NG"
            },
            {
                "name": "Bugana",
                "countryCode": "NG"
            },
            {
                "name": "Dekina",
                "countryCode": "NG"
            },
            {
                "name": "Egbe",
                "countryCode": "NG"
            },
            {
                "name": "Icheu",
                "countryCode": "NG"
            },
            {
                "name": "Idah",
                "countryCode": "NG"
            },
            {
                "name": "Isanlu-Itedoijowa",
                "countryCode": "NG"
            },
            {
                "name": "Kabba",
                "countryCode": "NG"
            },
            {
                "name": "Koton-Karfe",
                "countryCode": "NG"
            },
            {
                "name": "Lokoja",
                "countryCode": "NG"
            },
            {
                "name": "Ogaminana",
                "countryCode": "NG"
            },
            {
                "name": "Ogurugu",
                "countryCode": "NG"
            },
            {
                "name": "Okene",
                "countryCode": "NG"
            },
            {
                "name": "Danja",
                "countryCode": "NG"
            },
            {
                "name": "Dankama",
                "countryCode": "NG"
            },
            {
                "name": "Daura",
                "countryCode": "NG"
            },
            {
                "name": "Dutsin-Ma",
                "countryCode": "NG"
            },
            {
                "name": "Funtua",
                "countryCode": "NG"
            },
            {
                "name": "Gora",
                "countryCode": "NG"
            },
            {
                "name": "Jibia",
                "countryCode": "NG"
            },
            {
                "name": "Jikamshi",
                "countryCode": "NG"
            },
            {
                "name": "Kankara",
                "countryCode": "NG"
            },
            {
                "name": "Katsina",
                "countryCode": "NG"
            },
            {
                "name": "Mashi",
                "countryCode": "NG"
            },
            {
                "name": "Ruma",
                "countryCode": "NG"
            },
            {
                "name": "Runka",
                "countryCode": "NG"
            },
            {
                "name": "Wagini",
                "countryCode": "NG"
            },
            {
                "name": "Ajasse Ipo",
                "countryCode": "NG"
            },
            {
                "name": "Bode Saadu",
                "countryCode": "NG"
            },
            {
                "name": "Gwasero",
                "countryCode": "NG"
            },
            {
                "name": "Ilorin",
                "countryCode": "NG"
            },
            {
                "name": "Jebba",
                "countryCode": "NG"
            },
            {
                "name": "Kaiama",
                "countryCode": "NG"
            },
            {
                "name": "Lafiagi",
                "countryCode": "NG"
            },
            {
                "name": "Offa",
                "countryCode": "NG"
            },
            {
                "name": "Okuta",
                "countryCode": "NG"
            },
            {
                "name": "Omu-Aran",
                "countryCode": "NG"
            },
            {
                "name": "Patigi",
                "countryCode": "NG"
            },
            {
                "name": "Suya",
                "countryCode": "NG"
            },
            {
                "name": "Yashikera",
                "countryCode": "NG"
            },
            {
                "name": "Apapa",
                "countryCode": "NG"
            },
            {
                "name": "Badagry",
                "countryCode": "NG"
            },
            {
                "name": "Ebute Ikorodu",
                "countryCode": "NG"
            },
            {
                "name": "Ejirin",
                "countryCode": "NG"
            },
            {
                "name": "Epe",
                "countryCode": "NG"
            },
            {
                "name": "Ikeja",
                "countryCode": "NG"
            },
            {
                "name": "Lagos",
                "countryCode": "NG"
            },
            {
                "name": "Makoko",
                "countryCode": "NG"
            },
            {
                "name": "Buga",
                "countryCode": "NG"
            },
            {
                "name": "Doma",
                "countryCode": "NG"
            },
            {
                "name": "Keffi",
                "countryCode": "NG"
            },
            {
                "name": "Lafia",
                "countryCode": "NG"
            },
            {
                "name": "Nasarawa",
                "countryCode": "NG"
            },
            {
                "name": "Wamba",
                "countryCode": "NG"
            },
            {
                "name": "Auna",
                "countryCode": "NG"
            },
            {
                "name": "Babana",
                "countryCode": "NG"
            },
            {
                "name": "Badeggi",
                "countryCode": "NG"
            },
            {
                "name": "Baro",
                "countryCode": "NG"
            },
            {
                "name": "Bokani",
                "countryCode": "NG"
            },
            {
                "name": "Duku",
                "countryCode": "NG"
            },
            {
                "name": "Ibeto",
                "countryCode": "NG"
            },
            {
                "name": "Konkwesso",
                "countryCode": "NG"
            },
            {
                "name": "Kontagora",
                "countryCode": "NG"
            },
            {
                "name": "Kusheriki",
                "countryCode": "NG"
            },
            {
                "name": "Kuta",
                "countryCode": "NG"
            },
            {
                "name": "Lapai",
                "countryCode": "NG"
            },
            {
                "name": "Minna",
                "countryCode": "NG"
            },
            {
                "name": "New Shagunnu",
                "countryCode": "NG"
            },
            {
                "name": "Suleja",
                "countryCode": "NG"
            },
            {
                "name": "Tegina",
                "countryCode": "NG"
            },
            {
                "name": "Ukata",
                "countryCode": "NG"
            },
            {
                "name": "Wawa",
                "countryCode": "NG"
            },
            {
                "name": "Zungeru",
                "countryCode": "NG"
            },
            {
                "name": "Abeokuta",
                "countryCode": "NG"
            },
            {
                "name": "Ado Odo",
                "countryCode": "NG"
            },
            {
                "name": "Idi Iroko",
                "countryCode": "NG"
            },
            {
                "name": "Ifo",
                "countryCode": "NG"
            },
            {
                "name": "Ijebu-Ife",
                "countryCode": "NG"
            },
            {
                "name": "Ijebu-Igbo",
                "countryCode": "NG"
            },
            {
                "name": "Ijebu-Ode",
                "countryCode": "NG"
            },
            {
                "name": "Ilaro",
                "countryCode": "NG"
            },
            {
                "name": "Imeko",
                "countryCode": "NG"
            },
            {
                "name": "Iperu",
                "countryCode": "NG"
            },
            {
                "name": "Isara",
                "countryCode": "NG"
            },
            {
                "name": "Owode",
                "countryCode": "NG"
            },
            {
                "name": "Agbabu",
                "countryCode": "NG"
            },
            {
                "name": "Akure",
                "countryCode": "NG"
            },
            {
                "name": "Idanre",
                "countryCode": "NG"
            },
            {
                "name": "Ifon",
                "countryCode": "NG"
            },
            {
                "name": "Ilare",
                "countryCode": "NG"
            },
            {
                "name": "Ode",
                "countryCode": "NG"
            },
            {
                "name": "Ondo",
                "countryCode": "NG"
            },
            {
                "name": "Ore",
                "countryCode": "NG"
            },
            {
                "name": "Owo",
                "countryCode": "NG"
            },
            {
                "name": "Apomu",
                "countryCode": "NG"
            },
            {
                "name": "Ejigbo",
                "countryCode": "NG"
            },
            {
                "name": "Gbongan",
                "countryCode": "NG"
            },
            {
                "name": "Ijebu-Jesa",
                "countryCode": "NG"
            },
            {
                "name": "Ikire",
                "countryCode": "NG"
            },
            {
                "name": "Ikirun",
                "countryCode": "NG"
            },
            {
                "name": "Ila Orangun",
                "countryCode": "NG"
            },
            {
                "name": "Ile-Ife",
                "countryCode": "NG"
            },
            {
                "name": "Ilesa",
                "countryCode": "NG"
            },
            {
                "name": "Ilobu",
                "countryCode": "NG"
            },
            {
                "name": "Inisa",
                "countryCode": "NG"
            },
            {
                "name": "Iwo",
                "countryCode": "NG"
            },
            {
                "name": "Modakeke",
                "countryCode": "NG"
            },
            {
                "name": "Oke Mesi",
                "countryCode": "NG"
            },
            {
                "name": "Olupona",
                "countryCode": "NG"
            },
            {
                "name": "Osogbo",
                "countryCode": "NG"
            },
            {
                "name": "Otan Ayegbaju",
                "countryCode": "NG"
            },
            {
                "name": "Oyan",
                "countryCode": "NG"
            },
            {
                "name": "Ago Are",
                "countryCode": "NG"
            },
            {
                "name": "Alapa",
                "countryCode": "NG"
            },
            {
                "name": "Fiditi",
                "countryCode": "NG"
            },
            {
                "name": "Ibadan",
                "countryCode": "NG"
            },
            {
                "name": "Igbeti",
                "countryCode": "NG"
            },
            {
                "name": "Igbo-Ora",
                "countryCode": "NG"
            },
            {
                "name": "Igboho",
                "countryCode": "NG"
            },
            {
                "name": "Kisi",
                "countryCode": "NG"
            },
            {
                "name": "Lalupon",
                "countryCode": "NG"
            },
            {
                "name": "Ogbomoso",
                "countryCode": "NG"
            },
            {
                "name": "Okeho",
                "countryCode": "NG"
            },
            {
                "name": "Orita Eruwa",
                "countryCode": "NG"
            },
            {
                "name": "Oyo",
                "countryCode": "NG"
            },
            {
                "name": "Saki",
                "countryCode": "NG"
            },
            {
                "name": "Amper",
                "countryCode": "NG"
            },
            {
                "name": "Bukuru",
                "countryCode": "NG"
            },
            {
                "name": "Dengi",
                "countryCode": "NG"
            },
            {
                "name": "Jos",
                "countryCode": "NG"
            },
            {
                "name": "Kwolla",
                "countryCode": "NG"
            },
            {
                "name": "Langtang",
                "countryCode": "NG"
            },
            {
                "name": "Pankshin",
                "countryCode": "NG"
            },
            {
                "name": "Panyam",
                "countryCode": "NG"
            },
            {
                "name": "Vom",
                "countryCode": "NG"
            },
            {
                "name": "Yelwa",
                "countryCode": "NG"
            },
            {
                "name": "Abalama",
                "countryCode": "NG"
            },
            {
                "name": "Abonnema",
                "countryCode": "NG"
            },
            {
                "name": "Ahoada",
                "countryCode": "NG"
            },
            {
                "name": "Bori",
                "countryCode": "NG"
            },
            {
                "name": "Buguma",
                "countryCode": "NG"
            },
            {
                "name": "Chokocho",
                "countryCode": "NG"
            },
            {
                "name": "Elele",
                "countryCode": "NG"
            },
            {
                "name": "Obonoma",
                "countryCode": "NG"
            },
            {
                "name": "Omoku",
                "countryCode": "NG"
            },
            {
                "name": "Onne",
                "countryCode": "NG"
            },
            {
                "name": "Opobo",
                "countryCode": "NG"
            },
            {
                "name": "Port Harcourt",
                "countryCode": "NG"
            },
            {
                "name": "Rumuokoro",
                "countryCode": "NG"
            },
            {
                "name": "Binji",
                "countryCode": "NG"
            },
            {
                "name": "Dange",
                "countryCode": "NG"
            },
            {
                "name": "Gandi",
                "countryCode": "NG"
            },
            {
                "name": "Goronyo",
                "countryCode": "NG"
            },
            {
                "name": "Gwadabawa",
                "countryCode": "NG"
            },
            {
                "name": "Illela",
                "countryCode": "NG"
            },
            {
                "name": "Rabah",
                "countryCode": "NG"
            },
            {
                "name": "Sokoto",
                "countryCode": "NG"
            },
            {
                "name": "Tambuwal",
                "countryCode": "NG"
            },
            {
                "name": "Wurno",
                "countryCode": "NG"
            },
            {
                "name": "Baissa",
                "countryCode": "NG"
            },
            {
                "name": "Beli",
                "countryCode": "NG"
            },
            {
                "name": "Gassol",
                "countryCode": "NG"
            },
            {
                "name": "Gembu",
                "countryCode": "NG"
            },
            {
                "name": "Ibi",
                "countryCode": "NG"
            },
            {
                "name": "Jalingo",
                "countryCode": "NG"
            },
            {
                "name": "Lau",
                "countryCode": "NG"
            },
            {
                "name": "Mutum Biyu",
                "countryCode": "NG"
            },
            {
                "name": "Riti",
                "countryCode": "NG"
            },
            {
                "name": "Wukari",
                "countryCode": "NG"
            },
            {
                "name": "Damaturu",
                "countryCode": "NG"
            },
            {
                "name": "Dankalwa",
                "countryCode": "NG"
            },
            {
                "name": "Dapchi",
                "countryCode": "NG"
            },
            {
                "name": "Daura",
                "countryCode": "NG"
            },
            {
                "name": "Fika",
                "countryCode": "NG"
            },
            {
                "name": "Gashua",
                "countryCode": "NG"
            },
            {
                "name": "Geidam",
                "countryCode": "NG"
            },
            {
                "name": "Goniri",
                "countryCode": "NG"
            },
            {
                "name": "Gorgoram",
                "countryCode": "NG"
            },
            {
                "name": "Gujba",
                "countryCode": "NG"
            },
            {
                "name": "Gwio Kura",
                "countryCode": "NG"
            },
            {
                "name": "Kumagunnam",
                "countryCode": "NG"
            },
            {
                "name": "Lajere",
                "countryCode": "NG"
            },
            {
                "name": "Machina",
                "countryCode": "NG"
            },
            {
                "name": "Nguru",
                "countryCode": "NG"
            },
            {
                "name": "Potiskum",
                "countryCode": "NG"
            },
            {
                "name": "Anka",
                "countryCode": "NG"
            },
            {
                "name": "Dan Sadau",
                "countryCode": "NG"
            },
            {
                "name": "Gummi",
                "countryCode": "NG"
            },
            {
                "name": "Gusau",
                "countryCode": "NG"
            },
            {
                "name": "Kaura Namoda",
                "countryCode": "NG"
            },
            {
                "name": "Kwatarkwashi",
                "countryCode": "NG"
            },
            {
                "name": "Maru",
                "countryCode": "NG"
            },
            {
                "name": "Moriki",
                "countryCode": "NG"
            },
            {
                "name": "Sauri",
                "countryCode": "NG"
            },
            {
                "name": "Tsafe",
                "countryCode": "NG"
            }
        ]
    },
    {
        "name": "Niue",
        "phonecode": "683",
        "cities": []
    },
    {
        "name": "Norfolk Island",
        "phonecode": "672",
        "cities": []
    },
    {
        "name": "Northern Mariana Islands",
        "phonecode": "+1-670",
        "cities": []
    },
    {
        "name": "Norway",
        "phonecode": "47",
        "cities": [
            {
                "name": "Aremark",
                "countryCode": "NO"
            },
            {
                "name": "Askim",
                "countryCode": "NO"
            },
            {
                "name": "Eidsberg",
                "countryCode": "NO"
            },
            {
                "name": "Fossby",
                "countryCode": "NO"
            },
            {
                "name": "Fredrikstad",
                "countryCode": "NO"
            },
            {
                "name": "Halden",
                "countryCode": "NO"
            },
            {
                "name": "Hobøl",
                "countryCode": "NO"
            },
            {
                "name": "Hvaler",
                "countryCode": "NO"
            },
            {
                "name": "Karlshus",
                "countryCode": "NO"
            },
            {
                "name": "Knappstad",
                "countryCode": "NO"
            },
            {
                "name": "Larkollen",
                "countryCode": "NO"
            },
            {
                "name": "Lervik",
                "countryCode": "NO"
            },
            {
                "name": "Marker",
                "countryCode": "NO"
            },
            {
                "name": "Moss",
                "countryCode": "NO"
            },
            {
                "name": "Mysen",
                "countryCode": "NO"
            },
            {
                "name": "Rakkestad",
                "countryCode": "NO"
            },
            {
                "name": "Rygge",
                "countryCode": "NO"
            },
            {
                "name": "Ryggebyen",
                "countryCode": "NO"
            },
            {
                "name": "Råde",
                "countryCode": "NO"
            },
            {
                "name": "Rømskog",
                "countryCode": "NO"
            },
            {
                "name": "Sarpsborg",
                "countryCode": "NO"
            },
            {
                "name": "Skiptvet",
                "countryCode": "NO"
            },
            {
                "name": "Skjeberg",
                "countryCode": "NO"
            },
            {
                "name": "Skjærhalden",
                "countryCode": "NO"
            },
            {
                "name": "Spydeberg",
                "countryCode": "NO"
            },
            {
                "name": "Tomter",
                "countryCode": "NO"
            },
            {
                "name": "Trøgstad",
                "countryCode": "NO"
            },
            {
                "name": "Våler",
                "countryCode": "NO"
            },
            {
                "name": "Ørje",
                "countryCode": "NO"
            },
            {
                "name": "Ask",
                "countryCode": "NO"
            },
            {
                "name": "Asker",
                "countryCode": "NO"
            },
            {
                "name": "Auli",
                "countryCode": "NO"
            },
            {
                "name": "Aurskog-Høland",
                "countryCode": "NO"
            },
            {
                "name": "Aursmoen",
                "countryCode": "NO"
            },
            {
                "name": "Billingstad",
                "countryCode": "NO"
            },
            {
                "name": "Bjørkelangen",
                "countryCode": "NO"
            },
            {
                "name": "Blakstad",
                "countryCode": "NO"
            },
            {
                "name": "Bærum",
                "countryCode": "NO"
            },
            {
                "name": "Drøbak",
                "countryCode": "NO"
            },
            {
                "name": "Eidsvoll",
                "countryCode": "NO"
            },
            {
                "name": "Enebakk",
                "countryCode": "NO"
            },
            {
                "name": "Fagerstrand",
                "countryCode": "NO"
            },
            {
                "name": "Fet",
                "countryCode": "NO"
            },
            {
                "name": "Fetsund",
                "countryCode": "NO"
            },
            {
                "name": "Fjellfoten",
                "countryCode": "NO"
            },
            {
                "name": "Flateby",
                "countryCode": "NO"
            },
            {
                "name": "Frogn",
                "countryCode": "NO"
            },
            {
                "name": "Frogner",
                "countryCode": "NO"
            },
            {
                "name": "Gjerdrum",
                "countryCode": "NO"
            },
            {
                "name": "Hurdal",
                "countryCode": "NO"
            },
            {
                "name": "Jessheim",
                "countryCode": "NO"
            },
            {
                "name": "Kløfta",
                "countryCode": "NO"
            },
            {
                "name": "Leirsund",
                "countryCode": "NO"
            },
            {
                "name": "Lillestrøm",
                "countryCode": "NO"
            },
            {
                "name": "Lysaker",
                "countryCode": "NO"
            },
            {
                "name": "Lørenskog",
                "countryCode": "NO"
            },
            {
                "name": "Maura",
                "countryCode": "NO"
            },
            {
                "name": "Nannestad",
                "countryCode": "NO"
            },
            {
                "name": "Nes",
                "countryCode": "NO"
            },
            {
                "name": "Neskollen",
                "countryCode": "NO"
            },
            {
                "name": "Nesodden",
                "countryCode": "NO"
            },
            {
                "name": "Nesoddtangen",
                "countryCode": "NO"
            },
            {
                "name": "Nittedal",
                "countryCode": "NO"
            },
            {
                "name": "Oppegård",
                "countryCode": "NO"
            },
            {
                "name": "Rotnes",
                "countryCode": "NO"
            },
            {
                "name": "Råholt",
                "countryCode": "NO"
            },
            {
                "name": "Rælingen",
                "countryCode": "NO"
            },
            {
                "name": "Skedsmo",
                "countryCode": "NO"
            },
            {
                "name": "Ski",
                "countryCode": "NO"
            },
            {
                "name": "Skui",
                "countryCode": "NO"
            },
            {
                "name": "Sørum",
                "countryCode": "NO"
            },
            {
                "name": "Sørumsand",
                "countryCode": "NO"
            },
            {
                "name": "Ullensaker",
                "countryCode": "NO"
            },
            {
                "name": "Vestby",
                "countryCode": "NO"
            },
            {
                "name": "Åneby",
                "countryCode": "NO"
            },
            {
                "name": "Årnes",
                "countryCode": "NO"
            },
            {
                "name": "Ås",
                "countryCode": "NO"
            },
            {
                "name": "Oslo",
                "countryCode": "NO"
            },
            {
                "name": "Sjølyststranda",
                "countryCode": "NO"
            },
            {
                "name": "Alvdal",
                "countryCode": "NO"
            },
            {
                "name": "Brumunddal",
                "countryCode": "NO"
            },
            {
                "name": "Eidskog",
                "countryCode": "NO"
            },
            {
                "name": "Elverum",
                "countryCode": "NO"
            },
            {
                "name": "Engerdal",
                "countryCode": "NO"
            },
            {
                "name": "Folldal",
                "countryCode": "NO"
            },
            {
                "name": "Grue",
                "countryCode": "NO"
            },
            {
                "name": "Hamar",
                "countryCode": "NO"
            },
            {
                "name": "Innbygda",
                "countryCode": "NO"
            },
            {
                "name": "Kirkenær",
                "countryCode": "NO"
            },
            {
                "name": "Kongsvinger",
                "countryCode": "NO"
            },
            {
                "name": "Koppang",
                "countryCode": "NO"
            },
            {
                "name": "Løten",
                "countryCode": "NO"
            },
            {
                "name": "Moelv",
                "countryCode": "NO"
            },
            {
                "name": "Nord-Odal",
                "countryCode": "NO"
            },
            {
                "name": "Os",
                "countryCode": "NO"
            },
            {
                "name": "Rena",
                "countryCode": "NO"
            },
            {
                "name": "Rendalen",
                "countryCode": "NO"
            },
            {
                "name": "Ringsaker",
                "countryCode": "NO"
            },
            {
                "name": "Sand",
                "countryCode": "NO"
            },
            {
                "name": "Skarnes",
                "countryCode": "NO"
            },
            {
                "name": "Skotterud",
                "countryCode": "NO"
            },
            {
                "name": "Spetalen",
                "countryCode": "NO"
            },
            {
                "name": "Stange",
                "countryCode": "NO"
            },
            {
                "name": "Stor-Elvdal",
                "countryCode": "NO"
            },
            {
                "name": "Sør-Odal",
                "countryCode": "NO"
            },
            {
                "name": "Tolga",
                "countryCode": "NO"
            },
            {
                "name": "Trysil",
                "countryCode": "NO"
            },
            {
                "name": "Tynset",
                "countryCode": "NO"
            },
            {
                "name": "Våler",
                "countryCode": "NO"
            },
            {
                "name": "Åmot",
                "countryCode": "NO"
            },
            {
                "name": "Åsnes",
                "countryCode": "NO"
            },
            {
                "name": "Bagn",
                "countryCode": "NO"
            },
            {
                "name": "Dokka",
                "countryCode": "NO"
            },
            {
                "name": "Dombås",
                "countryCode": "NO"
            },
            {
                "name": "Dovre",
                "countryCode": "NO"
            },
            {
                "name": "Etnedal",
                "countryCode": "NO"
            },
            {
                "name": "Fagernes",
                "countryCode": "NO"
            },
            {
                "name": "Fossbergom",
                "countryCode": "NO"
            },
            {
                "name": "Gausdal",
                "countryCode": "NO"
            },
            {
                "name": "Gjøvik",
                "countryCode": "NO"
            },
            {
                "name": "Gran",
                "countryCode": "NO"
            },
            {
                "name": "Grua",
                "countryCode": "NO"
            },
            {
                "name": "Hov",
                "countryCode": "NO"
            },
            {
                "name": "Hundorp",
                "countryCode": "NO"
            },
            {
                "name": "Jevnaker",
                "countryCode": "NO"
            },
            {
                "name": "Lena",
                "countryCode": "NO"
            },
            {
                "name": "Lesja",
                "countryCode": "NO"
            },
            {
                "name": "Lillehammer",
                "countryCode": "NO"
            },
            {
                "name": "Lom",
                "countryCode": "NO"
            },
            {
                "name": "Lunner",
                "countryCode": "NO"
            },
            {
                "name": "Nord-Aurdal",
                "countryCode": "NO"
            },
            {
                "name": "Nord-Fron",
                "countryCode": "NO"
            },
            {
                "name": "Nordre Land",
                "countryCode": "NO"
            },
            {
                "name": "Otta",
                "countryCode": "NO"
            },
            {
                "name": "Raufoss",
                "countryCode": "NO"
            },
            {
                "name": "Reinsvoll",
                "countryCode": "NO"
            },
            {
                "name": "Ringebu",
                "countryCode": "NO"
            },
            {
                "name": "Sel",
                "countryCode": "NO"
            },
            {
                "name": "Skjåk",
                "countryCode": "NO"
            },
            {
                "name": "Skreia",
                "countryCode": "NO"
            },
            {
                "name": "Søndre Land",
                "countryCode": "NO"
            },
            {
                "name": "Sør-Aurdal",
                "countryCode": "NO"
            },
            {
                "name": "Sør-Fron",
                "countryCode": "NO"
            },
            {
                "name": "Tretten",
                "countryCode": "NO"
            },
            {
                "name": "Vang",
                "countryCode": "NO"
            },
            {
                "name": "Vestre Slidre",
                "countryCode": "NO"
            },
            {
                "name": "Vestre Toten",
                "countryCode": "NO"
            },
            {
                "name": "Vinstra",
                "countryCode": "NO"
            },
            {
                "name": "Vågå",
                "countryCode": "NO"
            },
            {
                "name": "Vågåmo",
                "countryCode": "NO"
            },
            {
                "name": "Østre Toten",
                "countryCode": "NO"
            },
            {
                "name": "Øyer",
                "countryCode": "NO"
            },
            {
                "name": "Øystre Slidre",
                "countryCode": "NO"
            },
            {
                "name": "Drammen",
                "countryCode": "NO"
            },
            {
                "name": "Flesberg",
                "countryCode": "NO"
            },
            {
                "name": "Flå",
                "countryCode": "NO"
            },
            {
                "name": "Geilo",
                "countryCode": "NO"
            },
            {
                "name": "Gol",
                "countryCode": "NO"
            },
            {
                "name": "Hemsedal",
                "countryCode": "NO"
            },
            {
                "name": "Hol",
                "countryCode": "NO"
            },
            {
                "name": "Hole",
                "countryCode": "NO"
            },
            {
                "name": "Hurum",
                "countryCode": "NO"
            },
            {
                "name": "Hvittingfoss",
                "countryCode": "NO"
            },
            {
                "name": "Hønefoss",
                "countryCode": "NO"
            },
            {
                "name": "Kongsberg",
                "countryCode": "NO"
            },
            {
                "name": "Krødsherad",
                "countryCode": "NO"
            },
            {
                "name": "Lier",
                "countryCode": "NO"
            },
            {
                "name": "Modum",
                "countryCode": "NO"
            },
            {
                "name": "Nedre Eiker",
                "countryCode": "NO"
            },
            {
                "name": "Nes",
                "countryCode": "NO"
            },
            {
                "name": "Nesbyen",
                "countryCode": "NO"
            },
            {
                "name": "Nore og Uvdal",
                "countryCode": "NO"
            },
            {
                "name": "Noresund",
                "countryCode": "NO"
            },
            {
                "name": "Prestfoss",
                "countryCode": "NO"
            },
            {
                "name": "Ringerike",
                "countryCode": "NO"
            },
            {
                "name": "Rollag",
                "countryCode": "NO"
            },
            {
                "name": "Røyken",
                "countryCode": "NO"
            },
            {
                "name": "Sigdal",
                "countryCode": "NO"
            },
            {
                "name": "Skoger",
                "countryCode": "NO"
            },
            {
                "name": "Sætre",
                "countryCode": "NO"
            },
            {
                "name": "Tofte",
                "countryCode": "NO"
            },
            {
                "name": "Tranby",
                "countryCode": "NO"
            },
            {
                "name": "Vikersund",
                "countryCode": "NO"
            },
            {
                "name": "Ål",
                "countryCode": "NO"
            },
            {
                "name": "Åros",
                "countryCode": "NO"
            },
            {
                "name": "Øvre Eiker",
                "countryCode": "NO"
            },
            {
                "name": "Barkåker",
                "countryCode": "NO"
            },
            {
                "name": "Berger",
                "countryCode": "NO"
            },
            {
                "name": "Færder",
                "countryCode": "NO"
            },
            {
                "name": "Gullhaug",
                "countryCode": "NO"
            },
            {
                "name": "Holmestrand",
                "countryCode": "NO"
            },
            {
                "name": "Horten",
                "countryCode": "NO"
            },
            {
                "name": "Larvik",
                "countryCode": "NO"
            },
            {
                "name": "Melsomvik",
                "countryCode": "NO"
            },
            {
                "name": "Re",
                "countryCode": "NO"
            },
            {
                "name": "Sande",
                "countryCode": "NO"
            },
            {
                "name": "Sandefjord",
                "countryCode": "NO"
            },
            {
                "name": "Selvik",
                "countryCode": "NO"
            },
            {
                "name": "Sem",
                "countryCode": "NO"
            },
            {
                "name": "Skoppum",
                "countryCode": "NO"
            },
            {
                "name": "Stavern",
                "countryCode": "NO"
            },
            {
                "name": "Stokke",
                "countryCode": "NO"
            },
            {
                "name": "Svelvik",
                "countryCode": "NO"
            },
            {
                "name": "Tjøme",
                "countryCode": "NO"
            },
            {
                "name": "Tønsberg",
                "countryCode": "NO"
            },
            {
                "name": "Årøysund",
                "countryCode": "NO"
            },
            {
                "name": "Åsgårdstrand",
                "countryCode": "NO"
            },
            {
                "name": "Bamble",
                "countryCode": "NO"
            },
            {
                "name": "Bø",
                "countryCode": "NO"
            },
            {
                "name": "Dalen",
                "countryCode": "NO"
            },
            {
                "name": "Drangedal",
                "countryCode": "NO"
            },
            {
                "name": "Fyresdal",
                "countryCode": "NO"
            },
            {
                "name": "Gvarv",
                "countryCode": "NO"
            },
            {
                "name": "Herre",
                "countryCode": "NO"
            },
            {
                "name": "Hjartdal",
                "countryCode": "NO"
            },
            {
                "name": "Kragerø",
                "countryCode": "NO"
            },
            {
                "name": "Kviteseid",
                "countryCode": "NO"
            },
            {
                "name": "Lunde",
                "countryCode": "NO"
            },
            {
                "name": "Nissedal",
                "countryCode": "NO"
            },
            {
                "name": "Nome",
                "countryCode": "NO"
            },
            {
                "name": "Notodden",
                "countryCode": "NO"
            },
            {
                "name": "Porsgrunn",
                "countryCode": "NO"
            },
            {
                "name": "Prestestranda",
                "countryCode": "NO"
            },
            {
                "name": "Rjukan",
                "countryCode": "NO"
            },
            {
                "name": "Sauherad",
                "countryCode": "NO"
            },
            {
                "name": "Seljord",
                "countryCode": "NO"
            },
            {
                "name": "Siljan",
                "countryCode": "NO"
            },
            {
                "name": "Skien",
                "countryCode": "NO"
            },
            {
                "name": "Tinn",
                "countryCode": "NO"
            },
            {
                "name": "Tokke",
                "countryCode": "NO"
            },
            {
                "name": "Ulefoss",
                "countryCode": "NO"
            },
            {
                "name": "Vinje",
                "countryCode": "NO"
            },
            {
                "name": "Audnedal",
                "countryCode": "NO"
            },
            {
                "name": "Farsund",
                "countryCode": "NO"
            },
            {
                "name": "Flekkefjord",
                "countryCode": "NO"
            },
            {
                "name": "Hægebostad",
                "countryCode": "NO"
            },
            {
                "name": "Justvik",
                "countryCode": "NO"
            },
            {
                "name": "Kristiansand",
                "countryCode": "NO"
            },
            {
                "name": "Kvinesdal",
                "countryCode": "NO"
            },
            {
                "name": "Liknes",
                "countryCode": "NO"
            },
            {
                "name": "Lindesnes",
                "countryCode": "NO"
            },
            {
                "name": "Lyngdal",
                "countryCode": "NO"
            },
            {
                "name": "Mandal",
                "countryCode": "NO"
            },
            {
                "name": "Marnardal",
                "countryCode": "NO"
            },
            {
                "name": "Nodeland",
                "countryCode": "NO"
            },
            {
                "name": "Sirdal",
                "countryCode": "NO"
            },
            {
                "name": "Skålevik",
                "countryCode": "NO"
            },
            {
                "name": "Songdalen",
                "countryCode": "NO"
            },
            {
                "name": "Strai",
                "countryCode": "NO"
            },
            {
                "name": "Søgne",
                "countryCode": "NO"
            },
            {
                "name": "Tonstad",
                "countryCode": "NO"
            },
            {
                "name": "Tveit",
                "countryCode": "NO"
            },
            {
                "name": "Vanse",
                "countryCode": "NO"
            },
            {
                "name": "Vennesla",
                "countryCode": "NO"
            },
            {
                "name": "Vestbygd",
                "countryCode": "NO"
            },
            {
                "name": "Vigeland",
                "countryCode": "NO"
            },
            {
                "name": "Åseral",
                "countryCode": "NO"
            },
            {
                "name": "Bjerkreim",
                "countryCode": "NO"
            },
            {
                "name": "Bokn",
                "countryCode": "NO"
            },
            {
                "name": "Bryne",
                "countryCode": "NO"
            },
            {
                "name": "Egersund",
                "countryCode": "NO"
            },
            {
                "name": "Eigersund",
                "countryCode": "NO"
            },
            {
                "name": "Eike",
                "countryCode": "NO"
            },
            {
                "name": "Finnøy",
                "countryCode": "NO"
            },
            {
                "name": "Forsand",
                "countryCode": "NO"
            },
            {
                "name": "Gjesdal",
                "countryCode": "NO"
            },
            {
                "name": "Hauge i Dalane",
                "countryCode": "NO"
            },
            {
                "name": "Haugesund",
                "countryCode": "NO"
            },
            {
                "name": "Hjelmeland",
                "countryCode": "NO"
            },
            {
                "name": "Hommersåk",
                "countryCode": "NO"
            },
            {
                "name": "Hå",
                "countryCode": "NO"
            },
            {
                "name": "Judaberg",
                "countryCode": "NO"
            },
            {
                "name": "Jørpeland",
                "countryCode": "NO"
            },
            {
                "name": "Karmøy",
                "countryCode": "NO"
            },
            {
                "name": "Klepp",
                "countryCode": "NO"
            },
            {
                "name": "Kopervik",
                "countryCode": "NO"
            },
            {
                "name": "Kvitsøy",
                "countryCode": "NO"
            },
            {
                "name": "Lund",
                "countryCode": "NO"
            },
            {
                "name": "Lyefjell",
                "countryCode": "NO"
            },
            {
                "name": "Moi",
                "countryCode": "NO"
            },
            {
                "name": "Nærbø",
                "countryCode": "NO"
            },
            {
                "name": "Randaberg",
                "countryCode": "NO"
            },
            {
                "name": "Rennesøy",
                "countryCode": "NO"
            },
            {
                "name": "Sandnes",
                "countryCode": "NO"
            },
            {
                "name": "Sauda",
                "countryCode": "NO"
            },
            {
                "name": "Skudeneshavn",
                "countryCode": "NO"
            },
            {
                "name": "Sokndal",
                "countryCode": "NO"
            },
            {
                "name": "Sola",
                "countryCode": "NO"
            },
            {
                "name": "Stavanger",
                "countryCode": "NO"
            },
            {
                "name": "Strand",
                "countryCode": "NO"
            },
            {
                "name": "Suldal",
                "countryCode": "NO"
            },
            {
                "name": "Sæveland",
                "countryCode": "NO"
            },
            {
                "name": "Tananger",
                "countryCode": "NO"
            },
            {
                "name": "Tau",
                "countryCode": "NO"
            },
            {
                "name": "Time",
                "countryCode": "NO"
            },
            {
                "name": "Tysvær",
                "countryCode": "NO"
            },
            {
                "name": "Utsira",
                "countryCode": "NO"
            },
            {
                "name": "Varhaug",
                "countryCode": "NO"
            },
            {
                "name": "Vedavågen",
                "countryCode": "NO"
            },
            {
                "name": "Vigrestad",
                "countryCode": "NO"
            },
            {
                "name": "Vikeså",
                "countryCode": "NO"
            },
            {
                "name": "Vikevåg",
                "countryCode": "NO"
            },
            {
                "name": "Vindafjord",
                "countryCode": "NO"
            },
            {
                "name": "Vormedal",
                "countryCode": "NO"
            },
            {
                "name": "Åkrehamn",
                "countryCode": "NO"
            },
            {
                "name": "Ølen",
                "countryCode": "NO"
            },
            {
                "name": "Askøy",
                "countryCode": "NO"
            },
            {
                "name": "Austevoll",
                "countryCode": "NO"
            },
            {
                "name": "Austrheim",
                "countryCode": "NO"
            },
            {
                "name": "Bergen",
                "countryCode": "NO"
            },
            {
                "name": "Bømlo",
                "countryCode": "NO"
            },
            {
                "name": "Dale",
                "countryCode": "NO"
            },
            {
                "name": "Eidfjord",
                "countryCode": "NO"
            },
            {
                "name": "Eikelandsosen",
                "countryCode": "NO"
            },
            {
                "name": "Espeland",
                "countryCode": "NO"
            },
            {
                "name": "Etne",
                "countryCode": "NO"
            },
            {
                "name": "Fedje",
                "countryCode": "NO"
            },
            {
                "name": "Fitjar",
                "countryCode": "NO"
            },
            {
                "name": "Fjell",
                "countryCode": "NO"
            },
            {
                "name": "Frekhaug",
                "countryCode": "NO"
            },
            {
                "name": "Fusa",
                "countryCode": "NO"
            },
            {
                "name": "Granvin",
                "countryCode": "NO"
            },
            {
                "name": "Hagavik",
                "countryCode": "NO"
            },
            {
                "name": "Hylkje",
                "countryCode": "NO"
            },
            {
                "name": "Indre Arna",
                "countryCode": "NO"
            },
            {
                "name": "Jondal",
                "countryCode": "NO"
            },
            {
                "name": "Kinsarvik",
                "countryCode": "NO"
            },
            {
                "name": "Knappskog",
                "countryCode": "NO"
            },
            {
                "name": "Knarvik",
                "countryCode": "NO"
            },
            {
                "name": "Kvam",
                "countryCode": "NO"
            },
            {
                "name": "Kvinnherad",
                "countryCode": "NO"
            },
            {
                "name": "Leirvik",
                "countryCode": "NO"
            },
            {
                "name": "Lindås",
                "countryCode": "NO"
            },
            {
                "name": "Lonevåg",
                "countryCode": "NO"
            },
            {
                "name": "Manger",
                "countryCode": "NO"
            },
            {
                "name": "Masfjorden",
                "countryCode": "NO"
            },
            {
                "name": "Meland",
                "countryCode": "NO"
            },
            {
                "name": "Modalen",
                "countryCode": "NO"
            },
            {
                "name": "Mosterhamn",
                "countryCode": "NO"
            },
            {
                "name": "Norheimsund",
                "countryCode": "NO"
            },
            {
                "name": "Odda",
                "countryCode": "NO"
            },
            {
                "name": "Os",
                "countryCode": "NO"
            },
            {
                "name": "Osterøy",
                "countryCode": "NO"
            },
            {
                "name": "Radøy",
                "countryCode": "NO"
            },
            {
                "name": "Rosendal",
                "countryCode": "NO"
            },
            {
                "name": "Rubbestadneset",
                "countryCode": "NO"
            },
            {
                "name": "Sagvåg",
                "countryCode": "NO"
            },
            {
                "name": "Samnanger",
                "countryCode": "NO"
            },
            {
                "name": "Sandsli",
                "countryCode": "NO"
            },
            {
                "name": "Skogsvågen",
                "countryCode": "NO"
            },
            {
                "name": "Stord",
                "countryCode": "NO"
            },
            {
                "name": "Storebø",
                "countryCode": "NO"
            },
            {
                "name": "Straume",
                "countryCode": "NO"
            },
            {
                "name": "Sund",
                "countryCode": "NO"
            },
            {
                "name": "Sveio",
                "countryCode": "NO"
            },
            {
                "name": "Syfteland",
                "countryCode": "NO"
            },
            {
                "name": "Tysnes",
                "countryCode": "NO"
            },
            {
                "name": "Ullensvang",
                "countryCode": "NO"
            },
            {
                "name": "Ulvik",
                "countryCode": "NO"
            },
            {
                "name": "Vaksdal",
                "countryCode": "NO"
            },
            {
                "name": "Voss",
                "countryCode": "NO"
            },
            {
                "name": "Ytre Arna",
                "countryCode": "NO"
            },
            {
                "name": "Ytrebygda",
                "countryCode": "NO"
            },
            {
                "name": "Ågotnes",
                "countryCode": "NO"
            },
            {
                "name": "Øygarden",
                "countryCode": "NO"
            },
            {
                "name": "Øystese",
                "countryCode": "NO"
            },
            {
                "name": "Askvoll",
                "countryCode": "NO"
            },
            {
                "name": "Aurland",
                "countryCode": "NO"
            },
            {
                "name": "Balestrand",
                "countryCode": "NO"
            },
            {
                "name": "Bremanger",
                "countryCode": "NO"
            },
            {
                "name": "Dale",
                "countryCode": "NO"
            },
            {
                "name": "Eid",
                "countryCode": "NO"
            },
            {
                "name": "Eivindvik",
                "countryCode": "NO"
            },
            {
                "name": "Farnes",
                "countryCode": "NO"
            },
            {
                "name": "Fjaler",
                "countryCode": "NO"
            },
            {
                "name": "Flora",
                "countryCode": "NO"
            },
            {
                "name": "Florø",
                "countryCode": "NO"
            },
            {
                "name": "Førde",
                "countryCode": "NO"
            },
            {
                "name": "Gaular",
                "countryCode": "NO"
            },
            {
                "name": "Gaupne",
                "countryCode": "NO"
            },
            {
                "name": "Gloppen",
                "countryCode": "NO"
            },
            {
                "name": "Gulen",
                "countryCode": "NO"
            },
            {
                "name": "Hardbakke",
                "countryCode": "NO"
            },
            {
                "name": "Hermansverk",
                "countryCode": "NO"
            },
            {
                "name": "Hornindal",
                "countryCode": "NO"
            },
            {
                "name": "Hyllestad",
                "countryCode": "NO"
            },
            {
                "name": "Høyanger",
                "countryCode": "NO"
            },
            {
                "name": "Jølster",
                "countryCode": "NO"
            },
            {
                "name": "Leikanger",
                "countryCode": "NO"
            },
            {
                "name": "Luster",
                "countryCode": "NO"
            },
            {
                "name": "Lærdal",
                "countryCode": "NO"
            },
            {
                "name": "Lærdalsøyri",
                "countryCode": "NO"
            },
            {
                "name": "Måløy",
                "countryCode": "NO"
            },
            {
                "name": "Naustdal",
                "countryCode": "NO"
            },
            {
                "name": "Nordfjordeid",
                "countryCode": "NO"
            },
            {
                "name": "Sandane",
                "countryCode": "NO"
            },
            {
                "name": "Sande",
                "countryCode": "NO"
            },
            {
                "name": "Selje",
                "countryCode": "NO"
            },
            {
                "name": "Sogndal",
                "countryCode": "NO"
            },
            {
                "name": "Solund",
                "countryCode": "NO"
            },
            {
                "name": "Stryn",
                "countryCode": "NO"
            },
            {
                "name": "Svelgen",
                "countryCode": "NO"
            },
            {
                "name": "Vik",
                "countryCode": "NO"
            },
            {
                "name": "Vågsøy",
                "countryCode": "NO"
            },
            {
                "name": "Årdal",
                "countryCode": "NO"
            },
            {
                "name": "Årdalstangen",
                "countryCode": "NO"
            },
            {
                "name": "Aukra",
                "countryCode": "NO"
            },
            {
                "name": "Aure",
                "countryCode": "NO"
            },
            {
                "name": "Averøy",
                "countryCode": "NO"
            },
            {
                "name": "Batnfjordsøra",
                "countryCode": "NO"
            },
            {
                "name": "Brattvåg",
                "countryCode": "NO"
            },
            {
                "name": "Eide",
                "countryCode": "NO"
            },
            {
                "name": "Eidsvåg",
                "countryCode": "NO"
            },
            {
                "name": "Elnesvågen",
                "countryCode": "NO"
            },
            {
                "name": "Fræna",
                "countryCode": "NO"
            },
            {
                "name": "Giske",
                "countryCode": "NO"
            },
            {
                "name": "Gjemnes",
                "countryCode": "NO"
            },
            {
                "name": "Halsa",
                "countryCode": "NO"
            },
            {
                "name": "Haram",
                "countryCode": "NO"
            },
            {
                "name": "Hareid",
                "countryCode": "NO"
            },
            {
                "name": "Herøy",
                "countryCode": "NO"
            },
            {
                "name": "Hjelset",
                "countryCode": "NO"
            },
            {
                "name": "Hopen",
                "countryCode": "NO"
            },
            {
                "name": "Kristiansund",
                "countryCode": "NO"
            },
            {
                "name": "Larsnes",
                "countryCode": "NO"
            },
            {
                "name": "Midsund",
                "countryCode": "NO"
            },
            {
                "name": "Molde",
                "countryCode": "NO"
            },
            {
                "name": "Nesset",
                "countryCode": "NO"
            },
            {
                "name": "Norddal",
                "countryCode": "NO"
            },
            {
                "name": "Nordstranda",
                "countryCode": "NO"
            },
            {
                "name": "Rauma",
                "countryCode": "NO"
            },
            {
                "name": "Rensvik",
                "countryCode": "NO"
            },
            {
                "name": "Sande",
                "countryCode": "NO"
            },
            {
                "name": "Sandøy",
                "countryCode": "NO"
            },
            {
                "name": "Sjøholt",
                "countryCode": "NO"
            },
            {
                "name": "Skodje",
                "countryCode": "NO"
            },
            {
                "name": "Smøla",
                "countryCode": "NO"
            },
            {
                "name": "Steinshamn",
                "countryCode": "NO"
            },
            {
                "name": "Stordal",
                "countryCode": "NO"
            },
            {
                "name": "Stranda",
                "countryCode": "NO"
            },
            {
                "name": "Sula",
                "countryCode": "NO"
            },
            {
                "name": "Sunndal",
                "countryCode": "NO"
            },
            {
                "name": "Sunndalsøra",
                "countryCode": "NO"
            },
            {
                "name": "Surnadal",
                "countryCode": "NO"
            },
            {
                "name": "Sykkylven",
                "countryCode": "NO"
            },
            {
                "name": "Tingvoll",
                "countryCode": "NO"
            },
            {
                "name": "Tomra",
                "countryCode": "NO"
            },
            {
                "name": "Ulstein",
                "countryCode": "NO"
            },
            {
                "name": "Ulsteinvik",
                "countryCode": "NO"
            },
            {
                "name": "Ulsteinvik weather pws station",
                "countryCode": "NO"
            },
            {
                "name": "Vanylven",
                "countryCode": "NO"
            },
            {
                "name": "Vatne",
                "countryCode": "NO"
            },
            {
                "name": "Vestnes",
                "countryCode": "NO"
            },
            {
                "name": "Volda",
                "countryCode": "NO"
            },
            {
                "name": "Ålesund",
                "countryCode": "NO"
            },
            {
                "name": "Åndalsnes",
                "countryCode": "NO"
            },
            {
                "name": "Ørskog",
                "countryCode": "NO"
            },
            {
                "name": "Ørsta",
                "countryCode": "NO"
            },
            {
                "name": "Alstahaug",
                "countryCode": "NO"
            },
            {
                "name": "Andenes",
                "countryCode": "NO"
            },
            {
                "name": "Andøy",
                "countryCode": "NO"
            },
            {
                "name": "Ballangen",
                "countryCode": "NO"
            },
            {
                "name": "Beiarn",
                "countryCode": "NO"
            },
            {
                "name": "Bindal",
                "countryCode": "NO"
            },
            {
                "name": "Bjerkvik",
                "countryCode": "NO"
            },
            {
                "name": "Bodø",
                "countryCode": "NO"
            },
            {
                "name": "Bogen",
                "countryCode": "NO"
            },
            {
                "name": "Brønnøy",
                "countryCode": "NO"
            },
            {
                "name": "Brønnøysund",
                "countryCode": "NO"
            },
            {
                "name": "Bø",
                "countryCode": "NO"
            },
            {
                "name": "Dønna",
                "countryCode": "NO"
            },
            {
                "name": "Evenes",
                "countryCode": "NO"
            },
            {
                "name": "Evjen",
                "countryCode": "NO"
            },
            {
                "name": "Fauske",
                "countryCode": "NO"
            },
            {
                "name": "Flakstad",
                "countryCode": "NO"
            },
            {
                "name": "Gildeskål",
                "countryCode": "NO"
            },
            {
                "name": "Gladstad",
                "countryCode": "NO"
            },
            {
                "name": "Glomfjord",
                "countryCode": "NO"
            },
            {
                "name": "Grane",
                "countryCode": "NO"
            },
            {
                "name": "Gravdal",
                "countryCode": "NO"
            },
            {
                "name": "Hadsel",
                "countryCode": "NO"
            },
            {
                "name": "Hamarøy",
                "countryCode": "NO"
            },
            {
                "name": "Hattfjelldal",
                "countryCode": "NO"
            },
            {
                "name": "Hauknes",
                "countryCode": "NO"
            },
            {
                "name": "Hemnes",
                "countryCode": "NO"
            },
            {
                "name": "Hemnesberget",
                "countryCode": "NO"
            },
            {
                "name": "Herøy",
                "countryCode": "NO"
            },
            {
                "name": "Inndyr",
                "countryCode": "NO"
            },
            {
                "name": "Kabelvåg",
                "countryCode": "NO"
            },
            {
                "name": "Kjøpsvik",
                "countryCode": "NO"
            },
            {
                "name": "Korgen",
                "countryCode": "NO"
            },
            {
                "name": "Leirfjord",
                "countryCode": "NO"
            },
            {
                "name": "Leknes",
                "countryCode": "NO"
            },
            {
                "name": "Leland",
                "countryCode": "NO"
            },
            {
                "name": "Lurøy",
                "countryCode": "NO"
            },
            {
                "name": "Løding",
                "countryCode": "NO"
            },
            {
                "name": "Lødingen",
                "countryCode": "NO"
            },
            {
                "name": "Løpsmarka",
                "countryCode": "NO"
            },
            {
                "name": "Melbu",
                "countryCode": "NO"
            },
            {
                "name": "Meløy",
                "countryCode": "NO"
            },
            {
                "name": "Mo i Rana",
                "countryCode": "NO"
            },
            {
                "name": "Mosjøen",
                "countryCode": "NO"
            },
            {
                "name": "Moskenes",
                "countryCode": "NO"
            },
            {
                "name": "Myre",
                "countryCode": "NO"
            },
            {
                "name": "Narvik",
                "countryCode": "NO"
            },
            {
                "name": "Nesna",
                "countryCode": "NO"
            },
            {
                "name": "Rana",
                "countryCode": "NO"
            },
            {
                "name": "Reine",
                "countryCode": "NO"
            },
            {
                "name": "Rognan",
                "countryCode": "NO"
            },
            {
                "name": "Rødøy",
                "countryCode": "NO"
            },
            {
                "name": "Røst",
                "countryCode": "NO"
            },
            {
                "name": "Saltdal",
                "countryCode": "NO"
            },
            {
                "name": "Sandnessjøen",
                "countryCode": "NO"
            },
            {
                "name": "Sortland",
                "countryCode": "NO"
            },
            {
                "name": "Steigen",
                "countryCode": "NO"
            },
            {
                "name": "Stokmarknes",
                "countryCode": "NO"
            },
            {
                "name": "Straume",
                "countryCode": "NO"
            },
            {
                "name": "Straumen",
                "countryCode": "NO"
            },
            {
                "name": "Svolvær",
                "countryCode": "NO"
            },
            {
                "name": "Sømna",
                "countryCode": "NO"
            },
            {
                "name": "Sørfold",
                "countryCode": "NO"
            },
            {
                "name": "Sørland",
                "countryCode": "NO"
            },
            {
                "name": "Terråk",
                "countryCode": "NO"
            },
            {
                "name": "Tjeldsund",
                "countryCode": "NO"
            },
            {
                "name": "Trofors",
                "countryCode": "NO"
            },
            {
                "name": "Træna",
                "countryCode": "NO"
            },
            {
                "name": "Tysfjord",
                "countryCode": "NO"
            },
            {
                "name": "Vefsn",
                "countryCode": "NO"
            },
            {
                "name": "Vega",
                "countryCode": "NO"
            },
            {
                "name": "Vestvågøy",
                "countryCode": "NO"
            },
            {
                "name": "Vevelstad",
                "countryCode": "NO"
            },
            {
                "name": "Vik",
                "countryCode": "NO"
            },
            {
                "name": "Vågan",
                "countryCode": "NO"
            },
            {
                "name": "Værøy",
                "countryCode": "NO"
            },
            {
                "name": "Øksnes",
                "countryCode": "NO"
            },
            {
                "name": "Ørnes",
                "countryCode": "NO"
            },
            {
                "name": "Balsfjord",
                "countryCode": "NO"
            },
            {
                "name": "Bardu",
                "countryCode": "NO"
            },
            {
                "name": "Berg",
                "countryCode": "NO"
            },
            {
                "name": "Borkenes",
                "countryCode": "NO"
            },
            {
                "name": "Dyrøy",
                "countryCode": "NO"
            },
            {
                "name": "Evenskjer",
                "countryCode": "NO"
            },
            {
                "name": "Finnsnes",
                "countryCode": "NO"
            },
            {
                "name": "Gratangen",
                "countryCode": "NO"
            },
            {
                "name": "Gryllefjord",
                "countryCode": "NO"
            },
            {
                "name": "Hansnes",
                "countryCode": "NO"
            },
            {
                "name": "Harstad",
                "countryCode": "NO"
            },
            {
                "name": "Ibestad",
                "countryCode": "NO"
            },
            {
                "name": "Karlsøy",
                "countryCode": "NO"
            },
            {
                "name": "Kvæfjord",
                "countryCode": "NO"
            },
            {
                "name": "Kvænangen",
                "countryCode": "NO"
            },
            {
                "name": "Kåfjord",
                "countryCode": "NO"
            },
            {
                "name": "Lavangen",
                "countryCode": "NO"
            },
            {
                "name": "Lenvik",
                "countryCode": "NO"
            },
            {
                "name": "Lyngen",
                "countryCode": "NO"
            },
            {
                "name": "Lyngseidet",
                "countryCode": "NO"
            },
            {
                "name": "Moen",
                "countryCode": "NO"
            },
            {
                "name": "Målselv",
                "countryCode": "NO"
            },
            {
                "name": "Nordreisa",
                "countryCode": "NO"
            },
            {
                "name": "Olderdalen",
                "countryCode": "NO"
            },
            {
                "name": "Salangen",
                "countryCode": "NO"
            },
            {
                "name": "Setermoen",
                "countryCode": "NO"
            },
            {
                "name": "Sjøvegan",
                "countryCode": "NO"
            },
            {
                "name": "Skjervøy",
                "countryCode": "NO"
            },
            {
                "name": "Skånland",
                "countryCode": "NO"
            },
            {
                "name": "Storfjord",
                "countryCode": "NO"
            },
            {
                "name": "Storslett",
                "countryCode": "NO"
            },
            {
                "name": "Storsteinnes",
                "countryCode": "NO"
            },
            {
                "name": "Sørreisa",
                "countryCode": "NO"
            },
            {
                "name": "Torsken",
                "countryCode": "NO"
            },
            {
                "name": "Tranøy",
                "countryCode": "NO"
            },
            {
                "name": "Tromsø",
                "countryCode": "NO"
            },
            {
                "name": "Alta",
                "countryCode": "NO"
            },
            {
                "name": "Berlevåg",
                "countryCode": "NO"
            },
            {
                "name": "Bjørnevatn",
                "countryCode": "NO"
            },
            {
                "name": "Båtsfjord",
                "countryCode": "NO"
            },
            {
                "name": "Gamvik",
                "countryCode": "NO"
            },
            {
                "name": "Hammerfest",
                "countryCode": "NO"
            },
            {
                "name": "Hasvik",
                "countryCode": "NO"
            },
            {
                "name": "Honningsvåg",
                "countryCode": "NO"
            },
            {
                "name": "Karasjok",
                "countryCode": "NO"
            },
            {
                "name": "Kautokeino",
                "countryCode": "NO"
            },
            {
                "name": "Kirkenes",
                "countryCode": "NO"
            },
            {
                "name": "Kjøllefjord",
                "countryCode": "NO"
            },
            {
                "name": "Kvalsund",
                "countryCode": "NO"
            },
            {
                "name": "Kárášjohka",
                "countryCode": "NO"
            },
            {
                "name": "Lakselv",
                "countryCode": "NO"
            },
            {
                "name": "Lebesby",
                "countryCode": "NO"
            },
            {
                "name": "Loppa",
                "countryCode": "NO"
            },
            {
                "name": "Mehamn",
                "countryCode": "NO"
            },
            {
                "name": "Måsøy",
                "countryCode": "NO"
            },
            {
                "name": "Nesseby",
                "countryCode": "NO"
            },
            {
                "name": "Nordkapp",
                "countryCode": "NO"
            },
            {
                "name": "Porsanger",
                "countryCode": "NO"
            },
            {
                "name": "Rypefjord",
                "countryCode": "NO"
            },
            {
                "name": "Sør-Varanger",
                "countryCode": "NO"
            },
            {
                "name": "Tana",
                "countryCode": "NO"
            },
            {
                "name": "Vadsø",
                "countryCode": "NO"
            },
            {
                "name": "Vardø",
                "countryCode": "NO"
            },
            {
                "name": "Ávanuorri",
                "countryCode": "NO"
            },
            {
                "name": "Øksfjord",
                "countryCode": "NO"
            },
            {
                "name": "Agdenes",
                "countryCode": "NO"
            },
            {
                "name": "Berkåk",
                "countryCode": "NO"
            },
            {
                "name": "Bjugn",
                "countryCode": "NO"
            },
            {
                "name": "Botngård",
                "countryCode": "NO"
            },
            {
                "name": "Brekstad",
                "countryCode": "NO"
            },
            {
                "name": "Fillan",
                "countryCode": "NO"
            },
            {
                "name": "Flatanger",
                "countryCode": "NO"
            },
            {
                "name": "Fosnes",
                "countryCode": "NO"
            },
            {
                "name": "Frosta",
                "countryCode": "NO"
            },
            {
                "name": "Frøya",
                "countryCode": "NO"
            },
            {
                "name": "Grong",
                "countryCode": "NO"
            },
            {
                "name": "Hemne",
                "countryCode": "NO"
            },
            {
                "name": "Hitra",
                "countryCode": "NO"
            },
            {
                "name": "Holtålen",
                "countryCode": "NO"
            },
            {
                "name": "Hommelvik",
                "countryCode": "NO"
            },
            {
                "name": "Høylandet",
                "countryCode": "NO"
            },
            {
                "name": "Inderøy",
                "countryCode": "NO"
            },
            {
                "name": "Indre Fosen",
                "countryCode": "NO"
            },
            {
                "name": "Klæbu",
                "countryCode": "NO"
            },
            {
                "name": "Kolvereid",
                "countryCode": "NO"
            },
            {
                "name": "Kyrksæterøra",
                "countryCode": "NO"
            },
            {
                "name": "Lauvsnes",
                "countryCode": "NO"
            },
            {
                "name": "Leka",
                "countryCode": "NO"
            },
            {
                "name": "Levanger",
                "countryCode": "NO"
            },
            {
                "name": "Lierne",
                "countryCode": "NO"
            },
            {
                "name": "Lundamo",
                "countryCode": "NO"
            },
            {
                "name": "Malm",
                "countryCode": "NO"
            },
            {
                "name": "Malvik",
                "countryCode": "NO"
            },
            {
                "name": "Meldal",
                "countryCode": "NO"
            },
            {
                "name": "Melhus",
                "countryCode": "NO"
            },
            {
                "name": "Meråker",
                "countryCode": "NO"
            },
            {
                "name": "Midtre Gauldal",
                "countryCode": "NO"
            },
            {
                "name": "Namdalseid",
                "countryCode": "NO"
            },
            {
                "name": "Namsos",
                "countryCode": "NO"
            },
            {
                "name": "Namsskogan",
                "countryCode": "NO"
            },
            {
                "name": "Nærøy",
                "countryCode": "NO"
            },
            {
                "name": "Oppdal",
                "countryCode": "NO"
            },
            {
                "name": "Orkdal",
                "countryCode": "NO"
            },
            {
                "name": "Osen",
                "countryCode": "NO"
            },
            {
                "name": "Overhalla",
                "countryCode": "NO"
            },
            {
                "name": "Raarvihke - Røyrvik",
                "countryCode": "NO"
            },
            {
                "name": "Ranemsletta",
                "countryCode": "NO"
            },
            {
                "name": "Rennebu",
                "countryCode": "NO"
            },
            {
                "name": "Rindal",
                "countryCode": "NO"
            },
            {
                "name": "Roan",
                "countryCode": "NO"
            },
            {
                "name": "Røros",
                "countryCode": "NO"
            },
            {
                "name": "Rørvik",
                "countryCode": "NO"
            },
            {
                "name": "Røyrvik",
                "countryCode": "NO"
            },
            {
                "name": "Selbu",
                "countryCode": "NO"
            },
            {
                "name": "Sistranda",
                "countryCode": "NO"
            },
            {
                "name": "Skaun",
                "countryCode": "NO"
            },
            {
                "name": "Skogn",
                "countryCode": "NO"
            },
            {
                "name": "Snillfjord",
                "countryCode": "NO"
            },
            {
                "name": "Snåase",
                "countryCode": "NO"
            },
            {
                "name": "Snåase - Snåsa",
                "countryCode": "NO"
            },
            {
                "name": "Steinkjer",
                "countryCode": "NO"
            },
            {
                "name": "Stjørdal",
                "countryCode": "NO"
            },
            {
                "name": "Stjørdalshalsen",
                "countryCode": "NO"
            },
            {
                "name": "Straumen",
                "countryCode": "NO"
            },
            {
                "name": "Trondheim",
                "countryCode": "NO"
            },
            {
                "name": "Tydal",
                "countryCode": "NO"
            },
            {
                "name": "Verdal",
                "countryCode": "NO"
            },
            {
                "name": "Verran",
                "countryCode": "NO"
            },
            {
                "name": "Vikna",
                "countryCode": "NO"
            },
            {
                "name": "Å i Åfjord",
                "countryCode": "NO"
            },
            {
                "name": "Åfjord",
                "countryCode": "NO"
            },
            {
                "name": "Ørland",
                "countryCode": "NO"
            }
        ]
    },
    {
        "name": "Oman",
        "phonecode": "968",
        "cities": [
            {
                "name": "Barkā’",
                "countryCode": "OM"
            },
            {
                "name": "Bayt al ‘Awābī",
                "countryCode": "OM"
            },
            {
                "name": "Oman Smart Future City",
                "countryCode": "OM"
            },
            {
                "name": "Rustaq",
                "countryCode": "OM"
            },
            {
                "name": "Al Khābūrah",
                "countryCode": "OM"
            },
            {
                "name": "As Suwayq",
                "countryCode": "OM"
            },
            {
                "name": "Liwá",
                "countryCode": "OM"
            },
            {
                "name": "Shināş",
                "countryCode": "OM"
            },
            {
                "name": "Sohar",
                "countryCode": "OM"
            },
            {
                "name": "Şaḩam",
                "countryCode": "OM"
            },
            {
                "name": "Al Buraymī",
                "countryCode": "OM"
            },
            {
                "name": "Adam",
                "countryCode": "OM"
            },
            {
                "name": "Bahlā’",
                "countryCode": "OM"
            },
            {
                "name": "Bidbid",
                "countryCode": "OM"
            },
            {
                "name": "Izkī",
                "countryCode": "OM"
            },
            {
                "name": "Nizwá",
                "countryCode": "OM"
            },
            {
                "name": "Sufālat Samā’il",
                "countryCode": "OM"
            },
            {
                "name": "Bawshar",
                "countryCode": "OM"
            },
            {
                "name": "Muscat",
                "countryCode": "OM"
            },
            {
                "name": "Seeb",
                "countryCode": "OM"
            },
            {
                "name": "Dib Dibba",
                "countryCode": "OM"
            },
            {
                "name": "Khasab",
                "countryCode": "OM"
            },
            {
                "name": "Madḩā’ al Jadīdah",
                "countryCode": "OM"
            },
            {
                "name": "Sur",
                "countryCode": "OM"
            },
            {
                "name": "Haymā’",
                "countryCode": "OM"
            },
            {
                "name": "Yanqul",
                "countryCode": "OM"
            },
            {
                "name": "‘Ibrī",
                "countryCode": "OM"
            },
            {
                "name": "Şalālah",
                "countryCode": "OM"
            }
        ]
    },
    {
        "name": "Pakistan",
        "phonecode": "92",
        "cities": [
            {
                "name": "Alik Ghund",
                "countryCode": "PK"
            },
            {
                "name": "Awārān District",
                "countryCode": "PK"
            },
            {
                "name": "Barkhan",
                "countryCode": "PK"
            },
            {
                "name": "Bela",
                "countryCode": "PK"
            },
            {
                "name": "Bhag",
                "countryCode": "PK"
            },
            {
                "name": "Bārkhān District",
                "countryCode": "PK"
            },
            {
                "name": "Chaman",
                "countryCode": "PK"
            },
            {
                "name": "Chowki Jamali",
                "countryCode": "PK"
            },
            {
                "name": "Chāgai District",
                "countryCode": "PK"
            },
            {
                "name": "Dadhar",
                "countryCode": "PK"
            },
            {
                "name": "Dalbandin",
                "countryCode": "PK"
            },
            {
                "name": "Dera Bugti",
                "countryCode": "PK"
            },
            {
                "name": "Dera Bugti District",
                "countryCode": "PK"
            },
            {
                "name": "Duki",
                "countryCode": "PK"
            },
            {
                "name": "Gadani",
                "countryCode": "PK"
            },
            {
                "name": "Garhi Khairo",
                "countryCode": "PK"
            },
            {
                "name": "Gwadar",
                "countryCode": "PK"
            },
            {
                "name": "Harnai",
                "countryCode": "PK"
            },
            {
                "name": "Jhal Magsi District",
                "countryCode": "PK"
            },
            {
                "name": "Jiwani",
                "countryCode": "PK"
            },
            {
                "name": "Jāfarābād District",
                "countryCode": "PK"
            },
            {
                "name": "Kalat",
                "countryCode": "PK"
            },
            {
                "name": "Kalāt District",
                "countryCode": "PK"
            },
            {
                "name": "Khadan Khak",
                "countryCode": "PK"
            },
            {
                "name": "Kharan",
                "countryCode": "PK"
            },
            {
                "name": "Khuzdar",
                "countryCode": "PK"
            },
            {
                "name": "Khuzdār District",
                "countryCode": "PK"
            },
            {
                "name": "Khārān District",
                "countryCode": "PK"
            },
            {
                "name": "Kohlu",
                "countryCode": "PK"
            },
            {
                "name": "Kot Malik Barkhurdar",
                "countryCode": "PK"
            },
            {
                "name": "Lasbela District",
                "countryCode": "PK"
            },
            {
                "name": "Loralai",
                "countryCode": "PK"
            },
            {
                "name": "Loralai District",
                "countryCode": "PK"
            },
            {
                "name": "Mach",
                "countryCode": "PK"
            },
            {
                "name": "Mastung",
                "countryCode": "PK"
            },
            {
                "name": "Mastung District",
                "countryCode": "PK"
            },
            {
                "name": "Mehrabpur",
                "countryCode": "PK"
            },
            {
                "name": "Mūsa Khel District",
                "countryCode": "PK"
            },
            {
                "name": "Nasīrābād District",
                "countryCode": "PK"
            },
            {
                "name": "Nushki",
                "countryCode": "PK"
            },
            {
                "name": "Ormara",
                "countryCode": "PK"
            },
            {
                "name": "Panjgūr District",
                "countryCode": "PK"
            },
            {
                "name": "Pasni",
                "countryCode": "PK"
            },
            {
                "name": "Pishin",
                "countryCode": "PK"
            },
            {
                "name": "Qila Saifullāh District",
                "countryCode": "PK"
            },
            {
                "name": "Quetta",
                "countryCode": "PK"
            },
            {
                "name": "Quetta District",
                "countryCode": "PK"
            },
            {
                "name": "Sibi",
                "countryCode": "PK"
            },
            {
                "name": "Sohbatpur",
                "countryCode": "PK"
            },
            {
                "name": "Surab",
                "countryCode": "PK"
            },
            {
                "name": "Turbat",
                "countryCode": "PK"
            },
            {
                "name": "Usta Muhammad",
                "countryCode": "PK"
            },
            {
                "name": "Uthal",
                "countryCode": "PK"
            },
            {
                "name": "Zhob",
                "countryCode": "PK"
            },
            {
                "name": "Zhob District",
                "countryCode": "PK"
            },
            {
                "name": "Ziarat",
                "countryCode": "PK"
            },
            {
                "name": "Ziārat District",
                "countryCode": "PK"
            },
            {
                "name": "Barishal",
                "countryCode": "PK"
            },
            {
                "name": "Gilgit",
                "countryCode": "PK"
            },
            {
                "name": "Skardu",
                "countryCode": "PK"
            },
            {
                "name": "Islamabad",
                "countryCode": "PK"
            },
            {
                "name": "Bhimbar",
                "countryCode": "PK"
            },
            {
                "name": "Kotli",
                "countryCode": "PK"
            },
            {
                "name": "Kotli District",
                "countryCode": "PK"
            },
            {
                "name": "Mirpur District",
                "countryCode": "PK"
            },
            {
                "name": "Muzaffarābād",
                "countryCode": "PK"
            },
            {
                "name": "New Mirpur",
                "countryCode": "PK"
            },
            {
                "name": "Rawala Kot",
                "countryCode": "PK"
            },
            {
                "name": "Abbottabad",
                "countryCode": "PK"
            },
            {
                "name": "Akora",
                "countryCode": "PK"
            },
            {
                "name": "Aman Garh",
                "countryCode": "PK"
            },
            {
                "name": "Amirabad",
                "countryCode": "PK"
            },
            {
                "name": "Ashanagro Koto",
                "countryCode": "PK"
            },
            {
                "name": "Baffa",
                "countryCode": "PK"
            },
            {
                "name": "Bannu",
                "countryCode": "PK"
            },
            {
                "name": "Bat Khela",
                "countryCode": "PK"
            },
            {
                "name": "Battagram",
                "countryCode": "PK"
            },
            {
                "name": "Battagram District",
                "countryCode": "PK"
            },
            {
                "name": "Buner District",
                "countryCode": "PK"
            },
            {
                "name": "Charsadda",
                "countryCode": "PK"
            },
            {
                "name": "Cherat Cantonement",
                "countryCode": "PK"
            },
            {
                "name": "Chitral",
                "countryCode": "PK"
            },
            {
                "name": "Dera Ismail Khan",
                "countryCode": "PK"
            },
            {
                "name": "Dera Ismāīl Khān District",
                "countryCode": "PK"
            },
            {
                "name": "Doaba",
                "countryCode": "PK"
            },
            {
                "name": "Hangu",
                "countryCode": "PK"
            },
            {
                "name": "Haripur",
                "countryCode": "PK"
            },
            {
                "name": "Havelian",
                "countryCode": "PK"
            },
            {
                "name": "Kakad Wari Dir Upper",
                "countryCode": "PK"
            },
            {
                "name": "Karak",
                "countryCode": "PK"
            },
            {
                "name": "Khalabat",
                "countryCode": "PK"
            },
            {
                "name": "Kohat",
                "countryCode": "PK"
            },
            {
                "name": "Kulachi",
                "countryCode": "PK"
            },
            {
                "name": "Lachi",
                "countryCode": "PK"
            },
            {
                "name": "Lakki",
                "countryCode": "PK"
            },
            {
                "name": "Mansehra",
                "countryCode": "PK"
            },
            {
                "name": "Mardan",
                "countryCode": "PK"
            },
            {
                "name": "Mingora",
                "countryCode": "PK"
            },
            {
                "name": "Noorabad",
                "countryCode": "PK"
            },
            {
                "name": "Nowshera",
                "countryCode": "PK"
            },
            {
                "name": "Nowshera Cantonment",
                "countryCode": "PK"
            },
            {
                "name": "Pabbi",
                "countryCode": "PK"
            },
            {
                "name": "Paharpur",
                "countryCode": "PK"
            },
            {
                "name": "Peshawar",
                "countryCode": "PK"
            },
            {
                "name": "Risalpur Cantonment",
                "countryCode": "PK"
            },
            {
                "name": "Sarai Naurang",
                "countryCode": "PK"
            },
            {
                "name": "Shabqadar",
                "countryCode": "PK"
            },
            {
                "name": "Shingli Bala",
                "countryCode": "PK"
            },
            {
                "name": "Shorkot",
                "countryCode": "PK"
            },
            {
                "name": "Swabi",
                "countryCode": "PK"
            },
            {
                "name": "Tangi",
                "countryCode": "PK"
            },
            {
                "name": "Tank",
                "countryCode": "PK"
            },
            {
                "name": "Thal",
                "countryCode": "PK"
            },
            {
                "name": "Topi",
                "countryCode": "PK"
            },
            {
                "name": "Upper Dir",
                "countryCode": "PK"
            },
            {
                "name": "Utmanzai",
                "countryCode": "PK"
            },
            {
                "name": "Zaida",
                "countryCode": "PK"
            },
            {
                "name": "Ahmadpur Sial",
                "countryCode": "PK"
            },
            {
                "name": "Ahmedpur East",
                "countryCode": "PK"
            },
            {
                "name": "Alipur Chatha",
                "countryCode": "PK"
            },
            {
                "name": "Arifwala",
                "countryCode": "PK"
            },
            {
                "name": "Attock Tehsil",
                "countryCode": "PK"
            },
            {
                "name": "Baddomalhi",
                "countryCode": "PK"
            },
            {
                "name": "Bahawalnagar",
                "countryCode": "PK"
            },
            {
                "name": "Bahawalpur",
                "countryCode": "PK"
            },
            {
                "name": "Bakhri Ahmad Khan",
                "countryCode": "PK"
            },
            {
                "name": "Basirpur",
                "countryCode": "PK"
            },
            {
                "name": "Basti Dosa",
                "countryCode": "PK"
            },
            {
                "name": "Begowala",
                "countryCode": "PK"
            },
            {
                "name": "Bhakkar",
                "countryCode": "PK"
            },
            {
                "name": "Bhalwal",
                "countryCode": "PK"
            },
            {
                "name": "Bhawana",
                "countryCode": "PK"
            },
            {
                "name": "Bhera",
                "countryCode": "PK"
            },
            {
                "name": "Bhopalwala",
                "countryCode": "PK"
            },
            {
                "name": "Burewala",
                "countryCode": "PK"
            },
            {
                "name": "Chak Azam Saffo",
                "countryCode": "PK"
            },
            {
                "name": "Chak Jhumra",
                "countryCode": "PK"
            },
            {
                "name": "Chak One Hundred Twenty Nine Left",
                "countryCode": "PK"
            },
            {
                "name": "Chak Thirty-one -Eleven Left",
                "countryCode": "PK"
            },
            {
                "name": "Chak Two Hundred Forty-Nine TDA",
                "countryCode": "PK"
            },
            {
                "name": "Chakwal",
                "countryCode": "PK"
            },
            {
                "name": "Chawinda",
                "countryCode": "PK"
            },
            {
                "name": "Chichawatni",
                "countryCode": "PK"
            },
            {
                "name": "Chiniot",
                "countryCode": "PK"
            },
            {
                "name": "Chishtian",
                "countryCode": "PK"
            },
            {
                "name": "Choa Saidanshah",
                "countryCode": "PK"
            },
            {
                "name": "Chuhar Kana",
                "countryCode": "PK"
            },
            {
                "name": "Chunian",
                "countryCode": "PK"
            },
            {
                "name": "Daira Din Panah",
                "countryCode": "PK"
            },
            {
                "name": "Dajal",
                "countryCode": "PK"
            },
            {
                "name": "Dandot RS",
                "countryCode": "PK"
            },
            {
                "name": "Darya Khan",
                "countryCode": "PK"
            },
            {
                "name": "Daska",
                "countryCode": "PK"
            },
            {
                "name": "Daud Khel",
                "countryCode": "PK"
            },
            {
                "name": "Daultala",
                "countryCode": "PK"
            },
            {
                "name": "Dera Ghazi Khan",
                "countryCode": "PK"
            },
            {
                "name": "Dhanot",
                "countryCode": "PK"
            },
            {
                "name": "Dhaunkal",
                "countryCode": "PK"
            },
            {
                "name": "Dhok Awan",
                "countryCode": "PK"
            },
            {
                "name": "Dijkot",
                "countryCode": "PK"
            },
            {
                "name": "Dinan Bashnoian Wala",
                "countryCode": "PK"
            },
            {
                "name": "Dinga",
                "countryCode": "PK"
            },
            {
                "name": "Dipalpur",
                "countryCode": "PK"
            },
            {
                "name": "Dullewala",
                "countryCode": "PK"
            },
            {
                "name": "Dunga Bunga",
                "countryCode": "PK"
            },
            {
                "name": "Dunyapur",
                "countryCode": "PK"
            },
            {
                "name": "Eminabad",
                "countryCode": "PK"
            },
            {
                "name": "Faisalabad",
                "countryCode": "PK"
            },
            {
                "name": "Faqirwali",
                "countryCode": "PK"
            },
            {
                "name": "Faruka",
                "countryCode": "PK"
            },
            {
                "name": "Fazilpur",
                "countryCode": "PK"
            },
            {
                "name": "Ferozewala",
                "countryCode": "PK"
            },
            {
                "name": "Fort Abbas",
                "countryCode": "PK"
            },
            {
                "name": "Garh Maharaja",
                "countryCode": "PK"
            },
            {
                "name": "Gojra",
                "countryCode": "PK"
            },
            {
                "name": "Gujar Khan",
                "countryCode": "PK"
            },
            {
                "name": "Gujranwala",
                "countryCode": "PK"
            },
            {
                "name": "Gujranwala Division",
                "countryCode": "PK"
            },
            {
                "name": "Gujrat",
                "countryCode": "PK"
            },
            {
                "name": "Hadali",
                "countryCode": "PK"
            },
            {
                "name": "Hafizabad",
                "countryCode": "PK"
            },
            {
                "name": "Harnoli",
                "countryCode": "PK"
            },
            {
                "name": "Harunabad",
                "countryCode": "PK"
            },
            {
                "name": "Hasan Abdal",
                "countryCode": "PK"
            },
            {
                "name": "Hasilpur",
                "countryCode": "PK"
            },
            {
                "name": "Haveli Lakha",
                "countryCode": "PK"
            },
            {
                "name": "Hazro",
                "countryCode": "PK"
            },
            {
                "name": "Hujra Shah Muqeem",
                "countryCode": "PK"
            },
            {
                "name": "Jahanian Shah",
                "countryCode": "PK"
            },
            {
                "name": "Jalalpur Jattan",
                "countryCode": "PK"
            },
            {
                "name": "Jalalpur Pirwala",
                "countryCode": "PK"
            },
            {
                "name": "Jampur",
                "countryCode": "PK"
            },
            {
                "name": "Jand",
                "countryCode": "PK"
            },
            {
                "name": "Jandiala Sher Khan",
                "countryCode": "PK"
            },
            {
                "name": "Jaranwala",
                "countryCode": "PK"
            },
            {
                "name": "Jatoi Shimali",
                "countryCode": "PK"
            },
            {
                "name": "Jauharabad",
                "countryCode": "PK"
            },
            {
                "name": "Jhang",
                "countryCode": "PK"
            },
            {
                "name": "Jhang Sadar",
                "countryCode": "PK"
            },
            {
                "name": "Jhawarian",
                "countryCode": "PK"
            },
            {
                "name": "Jhelum",
                "countryCode": "PK"
            },
            {
                "name": "Kabirwala",
                "countryCode": "PK"
            },
            {
                "name": "Kahna Nau",
                "countryCode": "PK"
            },
            {
                "name": "Kahuta",
                "countryCode": "PK"
            },
            {
                "name": "Kalabagh",
                "countryCode": "PK"
            },
            {
                "name": "Kalaswala",
                "countryCode": "PK"
            },
            {
                "name": "Kaleke Mandi",
                "countryCode": "PK"
            },
            {
                "name": "Kallar Kahar",
                "countryCode": "PK"
            },
            {
                "name": "Kalur Kot",
                "countryCode": "PK"
            },
            {
                "name": "Kamalia",
                "countryCode": "PK"
            },
            {
                "name": "Kamar Mushani",
                "countryCode": "PK"
            },
            {
                "name": "Kamoke",
                "countryCode": "PK"
            },
            {
                "name": "Kamra",
                "countryCode": "PK"
            },
            {
                "name": "Kanganpur",
                "countryCode": "PK"
            },
            {
                "name": "Karor",
                "countryCode": "PK"
            },
            {
                "name": "Kasur",
                "countryCode": "PK"
            },
            {
                "name": "Keshupur",
                "countryCode": "PK"
            },
            {
                "name": "Khairpur Tamiwali",
                "countryCode": "PK"
            },
            {
                "name": "Khandowa",
                "countryCode": "PK"
            },
            {
                "name": "Khanewal",
                "countryCode": "PK"
            },
            {
                "name": "Khanga Dogran",
                "countryCode": "PK"
            },
            {
                "name": "Khangarh",
                "countryCode": "PK"
            },
            {
                "name": "Khanpur",
                "countryCode": "PK"
            },
            {
                "name": "Kharian",
                "countryCode": "PK"
            },
            {
                "name": "Khewra",
                "countryCode": "PK"
            },
            {
                "name": "Khurrianwala",
                "countryCode": "PK"
            },
            {
                "name": "Khushab",
                "countryCode": "PK"
            },
            {
                "name": "Kohror Pakka",
                "countryCode": "PK"
            },
            {
                "name": "Kot Addu Tehsil",
                "countryCode": "PK"
            },
            {
                "name": "Kot Ghulam Muhammad",
                "countryCode": "PK"
            },
            {
                "name": "Kot Mumin",
                "countryCode": "PK"
            },
            {
                "name": "Kot Radha Kishan",
                "countryCode": "PK"
            },
            {
                "name": "Kot Rajkour",
                "countryCode": "PK"
            },
            {
                "name": "Kot Samaba",
                "countryCode": "PK"
            },
            {
                "name": "Kot Sultan",
                "countryCode": "PK"
            },
            {
                "name": "Kotli Loharan",
                "countryCode": "PK"
            },
            {
                "name": "Kundian",
                "countryCode": "PK"
            },
            {
                "name": "Kunjah",
                "countryCode": "PK"
            },
            {
                "name": "Ladhewala Waraich",
                "countryCode": "PK"
            },
            {
                "name": "Lahore",
                "countryCode": "PK"
            },
            {
                "name": "Lala Musa",
                "countryCode": "PK"
            },
            {
                "name": "Lalian",
                "countryCode": "PK"
            },
            {
                "name": "Layyah",
                "countryCode": "PK"
            },
            {
                "name": "Layyah District",
                "countryCode": "PK"
            },
            {
                "name": "Liliani",
                "countryCode": "PK"
            },
            {
                "name": "Lodhran",
                "countryCode": "PK"
            },
            {
                "name": "Mailsi",
                "countryCode": "PK"
            },
            {
                "name": "Malakwal",
                "countryCode": "PK"
            },
            {
                "name": "Malakwal City",
                "countryCode": "PK"
            },
            {
                "name": "Mamu Kanjan",
                "countryCode": "PK"
            },
            {
                "name": "Mananwala",
                "countryCode": "PK"
            },
            {
                "name": "Mandi Bahauddin",
                "countryCode": "PK"
            },
            {
                "name": "Mandi Bahauddin District",
                "countryCode": "PK"
            },
            {
                "name": "Mangla",
                "countryCode": "PK"
            },
            {
                "name": "Mankera",
                "countryCode": "PK"
            },
            {
                "name": "Mehmand Chak",
                "countryCode": "PK"
            },
            {
                "name": "Mian Channun",
                "countryCode": "PK"
            },
            {
                "name": "Mianke Mor",
                "countryCode": "PK"
            },
            {
                "name": "Mianwali",
                "countryCode": "PK"
            },
            {
                "name": "Minchinabad",
                "countryCode": "PK"
            },
            {
                "name": "Mitha Tiwana",
                "countryCode": "PK"
            },
            {
                "name": "Moza Shahwala",
                "countryCode": "PK"
            },
            {
                "name": "Multan",
                "countryCode": "PK"
            },
            {
                "name": "Multan District",
                "countryCode": "PK"
            },
            {
                "name": "Muridke",
                "countryCode": "PK"
            },
            {
                "name": "Murree",
                "countryCode": "PK"
            },
            {
                "name": "Mustafabad",
                "countryCode": "PK"
            },
            {
                "name": "Muzaffargarh",
                "countryCode": "PK"
            },
            {
                "name": "Nankana Sahib",
                "countryCode": "PK"
            },
            {
                "name": "Narang Mandi",
                "countryCode": "PK"
            },
            {
                "name": "Narowal",
                "countryCode": "PK"
            },
            {
                "name": "Naushahra Virkan",
                "countryCode": "PK"
            },
            {
                "name": "Nazir Town",
                "countryCode": "PK"
            },
            {
                "name": "Okara",
                "countryCode": "PK"
            },
            {
                "name": "Pakki Shagwanwali",
                "countryCode": "PK"
            },
            {
                "name": "Pakpattan",
                "countryCode": "PK"
            },
            {
                "name": "Pasrur",
                "countryCode": "PK"
            },
            {
                "name": "Pattoki",
                "countryCode": "PK"
            },
            {
                "name": "Phalia",
                "countryCode": "PK"
            },
            {
                "name": "Pind Dadan Khan",
                "countryCode": "PK"
            },
            {
                "name": "Pindi Bhattian",
                "countryCode": "PK"
            },
            {
                "name": "Pindi Gheb",
                "countryCode": "PK"
            },
            {
                "name": "Pir Mahal",
                "countryCode": "PK"
            },
            {
                "name": "Qadirpur Ran",
                "countryCode": "PK"
            },
            {
                "name": "Qila Didar Singh",
                "countryCode": "PK"
            },
            {
                "name": "Rabwah",
                "countryCode": "PK"
            },
            {
                "name": "Rahim Yar Khan",
                "countryCode": "PK"
            },
            {
                "name": "Rahimyar Khan District",
                "countryCode": "PK"
            },
            {
                "name": "Raiwind",
                "countryCode": "PK"
            },
            {
                "name": "Raja Jang",
                "countryCode": "PK"
            },
            {
                "name": "Rajanpur",
                "countryCode": "PK"
            },
            {
                "name": "Rasulnagar",
                "countryCode": "PK"
            },
            {
                "name": "Rawalpindi",
                "countryCode": "PK"
            },
            {
                "name": "Rawalpindi District",
                "countryCode": "PK"
            },
            {
                "name": "Renala Khurd",
                "countryCode": "PK"
            },
            {
                "name": "Rojhan",
                "countryCode": "PK"
            },
            {
                "name": "Sadiqabad",
                "countryCode": "PK"
            },
            {
                "name": "Sahiwal",
                "countryCode": "PK"
            },
            {
                "name": "Sambrial",
                "countryCode": "PK"
            },
            {
                "name": "Sangla Hill",
                "countryCode": "PK"
            },
            {
                "name": "Sanjwal",
                "countryCode": "PK"
            },
            {
                "name": "Sarai Alamgir",
                "countryCode": "PK"
            },
            {
                "name": "Sarai Sidhu",
                "countryCode": "PK"
            },
            {
                "name": "Sargodha",
                "countryCode": "PK"
            },
            {
                "name": "Shahkot Tehsil",
                "countryCode": "PK"
            },
            {
                "name": "Shahpur",
                "countryCode": "PK"
            },
            {
                "name": "Shahr Sultan",
                "countryCode": "PK"
            },
            {
                "name": "Shakargarh",
                "countryCode": "PK"
            },
            {
                "name": "Sharqpur",
                "countryCode": "PK"
            },
            {
                "name": "Sheikhupura",
                "countryCode": "PK"
            },
            {
                "name": "Shorkot",
                "countryCode": "PK"
            },
            {
                "name": "Shujaabad",
                "countryCode": "PK"
            },
            {
                "name": "Sialkot",
                "countryCode": "PK"
            },
            {
                "name": "Sillanwali",
                "countryCode": "PK"
            },
            {
                "name": "Sodhra",
                "countryCode": "PK"
            },
            {
                "name": "Sukheke Mandi",
                "countryCode": "PK"
            },
            {
                "name": "Surkhpur",
                "countryCode": "PK"
            },
            {
                "name": "Talagang",
                "countryCode": "PK"
            },
            {
                "name": "Talamba",
                "countryCode": "PK"
            },
            {
                "name": "Tandlianwala",
                "countryCode": "PK"
            },
            {
                "name": "Taunsa",
                "countryCode": "PK"
            },
            {
                "name": "Toba Tek Singh",
                "countryCode": "PK"
            },
            {
                "name": "Umerkot",
                "countryCode": "PK"
            },
            {
                "name": "Vihari",
                "countryCode": "PK"
            },
            {
                "name": "Wah",
                "countryCode": "PK"
            },
            {
                "name": "Warburton",
                "countryCode": "PK"
            },
            {
                "name": "Wazirabad",
                "countryCode": "PK"
            },
            {
                "name": "West Punjab",
                "countryCode": "PK"
            },
            {
                "name": "Yazman",
                "countryCode": "PK"
            },
            {
                "name": "Zafarwal",
                "countryCode": "PK"
            },
            {
                "name": "Zahir Pir",
                "countryCode": "PK"
            },
            {
                "name": "Adilpur",
                "countryCode": "PK"
            },
            {
                "name": "Badin",
                "countryCode": "PK"
            },
            {
                "name": "Bagarji",
                "countryCode": "PK"
            },
            {
                "name": "Bandhi",
                "countryCode": "PK"
            },
            {
                "name": "Berani",
                "countryCode": "PK"
            },
            {
                "name": "Bhan",
                "countryCode": "PK"
            },
            {
                "name": "Bhiria",
                "countryCode": "PK"
            },
            {
                "name": "Bhit Shah",
                "countryCode": "PK"
            },
            {
                "name": "Bozdar Wada",
                "countryCode": "PK"
            },
            {
                "name": "Bulri",
                "countryCode": "PK"
            },
            {
                "name": "Chak",
                "countryCode": "PK"
            },
            {
                "name": "Chamber",
                "countryCode": "PK"
            },
            {
                "name": "Chhor",
                "countryCode": "PK"
            },
            {
                "name": "Chuhar Jamali",
                "countryCode": "PK"
            },
            {
                "name": "Dadu",
                "countryCode": "PK"
            },
            {
                "name": "Daromehar",
                "countryCode": "PK"
            },
            {
                "name": "Darya Khan Marri",
                "countryCode": "PK"
            },
            {
                "name": "Daulatpur",
                "countryCode": "PK"
            },
            {
                "name": "Daur",
                "countryCode": "PK"
            },
            {
                "name": "Dhoro Naro",
                "countryCode": "PK"
            },
            {
                "name": "Digri",
                "countryCode": "PK"
            },
            {
                "name": "Diplo",
                "countryCode": "PK"
            },
            {
                "name": "Dokri",
                "countryCode": "PK"
            },
            {
                "name": "Gambat",
                "countryCode": "PK"
            },
            {
                "name": "Garhiyasin",
                "countryCode": "PK"
            },
            {
                "name": "Gharo",
                "countryCode": "PK"
            },
            {
                "name": "Ghauspur",
                "countryCode": "PK"
            },
            {
                "name": "Ghotki",
                "countryCode": "PK"
            },
            {
                "name": "Goth Garelo",
                "countryCode": "PK"
            },
            {
                "name": "Goth Phulji",
                "countryCode": "PK"
            },
            {
                "name": "Goth Radhan",
                "countryCode": "PK"
            },
            {
                "name": "Hala",
                "countryCode": "PK"
            },
            {
                "name": "Hingorja",
                "countryCode": "PK"
            },
            {
                "name": "Hyderabad",
                "countryCode": "PK"
            },
            {
                "name": "Islamkot",
                "countryCode": "PK"
            },
            {
                "name": "Jacobabad",
                "countryCode": "PK"
            },
            {
                "name": "Jamshoro",
                "countryCode": "PK"
            },
            {
                "name": "Jati",
                "countryCode": "PK"
            },
            {
                "name": "Jhol",
                "countryCode": "PK"
            },
            {
                "name": "Johi",
                "countryCode": "PK"
            },
            {
                "name": "Jām Sāhib",
                "countryCode": "PK"
            },
            {
                "name": "Kadhan",
                "countryCode": "PK"
            },
            {
                "name": "Kambar",
                "countryCode": "PK"
            },
            {
                "name": "Kandhkot",
                "countryCode": "PK"
            },
            {
                "name": "Kandiari",
                "countryCode": "PK"
            },
            {
                "name": "Kandiaro",
                "countryCode": "PK"
            },
            {
                "name": "Karachi",
                "countryCode": "PK"
            },
            {
                "name": "Karaundi",
                "countryCode": "PK"
            },
            {
                "name": "Kario Ghanwar",
                "countryCode": "PK"
            },
            {
                "name": "Kashmor",
                "countryCode": "PK"
            },
            {
                "name": "Keti Bandar",
                "countryCode": "PK"
            },
            {
                "name": "Khadro",
                "countryCode": "PK"
            },
            {
                "name": "Khairpur",
                "countryCode": "PK"
            },
            {
                "name": "Khairpur Mir’s",
                "countryCode": "PK"
            },
            {
                "name": "Khairpur Nathan Shah",
                "countryCode": "PK"
            },
            {
                "name": "Khanpur Mahar",
                "countryCode": "PK"
            },
            {
                "name": "Kot Diji",
                "countryCode": "PK"
            },
            {
                "name": "Kotri",
                "countryCode": "PK"
            },
            {
                "name": "Kunri",
                "countryCode": "PK"
            },
            {
                "name": "Lakhi",
                "countryCode": "PK"
            },
            {
                "name": "Larkana",
                "countryCode": "PK"
            },
            {
                "name": "Madeji",
                "countryCode": "PK"
            },
            {
                "name": "Malir Cantonment",
                "countryCode": "PK"
            },
            {
                "name": "Matiari",
                "countryCode": "PK"
            },
            {
                "name": "Matli",
                "countryCode": "PK"
            },
            {
                "name": "Mehar",
                "countryCode": "PK"
            },
            {
                "name": "Miro Khan",
                "countryCode": "PK"
            },
            {
                "name": "Mirpur Bhtoro",
                "countryCode": "PK"
            },
            {
                "name": "Mirpur Khas",
                "countryCode": "PK"
            },
            {
                "name": "Mirpur Mathelo",
                "countryCode": "PK"
            },
            {
                "name": "Mirpur Sakro",
                "countryCode": "PK"
            },
            {
                "name": "Mirwah Gorchani",
                "countryCode": "PK"
            },
            {
                "name": "Mithi",
                "countryCode": "PK"
            },
            {
                "name": "Moro",
                "countryCode": "PK"
            },
            {
                "name": "Nabisar",
                "countryCode": "PK"
            },
            {
                "name": "Nasirabad",
                "countryCode": "PK"
            },
            {
                "name": "Naudero",
                "countryCode": "PK"
            },
            {
                "name": "Naukot",
                "countryCode": "PK"
            },
            {
                "name": "Naushahro Firoz",
                "countryCode": "PK"
            },
            {
                "name": "Nawabshah",
                "countryCode": "PK"
            },
            {
                "name": "New Bādāh",
                "countryCode": "PK"
            },
            {
                "name": "Pad Idan",
                "countryCode": "PK"
            },
            {
                "name": "Pano Aqil",
                "countryCode": "PK"
            },
            {
                "name": "Pir Jo Goth",
                "countryCode": "PK"
            },
            {
                "name": "Pithoro",
                "countryCode": "PK"
            },
            {
                "name": "Rajo Khanani",
                "countryCode": "PK"
            },
            {
                "name": "Ranipur",
                "countryCode": "PK"
            },
            {
                "name": "Ratodero",
                "countryCode": "PK"
            },
            {
                "name": "Rohri",
                "countryCode": "PK"
            },
            {
                "name": "Rustam",
                "countryCode": "PK"
            },
            {
                "name": "Sakrand",
                "countryCode": "PK"
            },
            {
                "name": "Samaro",
                "countryCode": "PK"
            },
            {
                "name": "Sanghar",
                "countryCode": "PK"
            },
            {
                "name": "Sann",
                "countryCode": "PK"
            },
            {
                "name": "Sehwan",
                "countryCode": "PK"
            },
            {
                "name": "Setharja Old",
                "countryCode": "PK"
            },
            {
                "name": "Shahdad Kot",
                "countryCode": "PK"
            },
            {
                "name": "Shahdadpur",
                "countryCode": "PK"
            },
            {
                "name": "Shahpur Chakar",
                "countryCode": "PK"
            },
            {
                "name": "Shikarpur",
                "countryCode": "PK"
            },
            {
                "name": "Sinjhoro",
                "countryCode": "PK"
            },
            {
                "name": "Sobhodero",
                "countryCode": "PK"
            },
            {
                "name": "Sukkur",
                "countryCode": "PK"
            },
            {
                "name": "Sīta Road",
                "countryCode": "PK"
            },
            {
                "name": "Talhar",
                "countryCode": "PK"
            },
            {
                "name": "Tando Adam",
                "countryCode": "PK"
            },
            {
                "name": "Tando Allahyar",
                "countryCode": "PK"
            },
            {
                "name": "Tando Bago",
                "countryCode": "PK"
            },
            {
                "name": "Tando Jam",
                "countryCode": "PK"
            },
            {
                "name": "Tando Mitha Khan",
                "countryCode": "PK"
            },
            {
                "name": "Tando Muhammad Khan",
                "countryCode": "PK"
            },
            {
                "name": "Tangwani",
                "countryCode": "PK"
            },
            {
                "name": "Tharu Shah",
                "countryCode": "PK"
            },
            {
                "name": "Thatta",
                "countryCode": "PK"
            },
            {
                "name": "Thul",
                "countryCode": "PK"
            },
            {
                "name": "Ubauro",
                "countryCode": "PK"
            },
            {
                "name": "Umarkot",
                "countryCode": "PK"
            },
            {
                "name": "Umerkot District",
                "countryCode": "PK"
            },
            {
                "name": "Warah",
                "countryCode": "PK"
            },
            {
                "name": "Alizai",
                "countryCode": "PK"
            },
            {
                "name": "Gulishah Kach",
                "countryCode": "PK"
            },
            {
                "name": "Landi Kotal",
                "countryCode": "PK"
            },
            {
                "name": "Miran Shah",
                "countryCode": "PK"
            },
            {
                "name": "North Wazīristān Agency",
                "countryCode": "PK"
            },
            {
                "name": "Shinpokh",
                "countryCode": "PK"
            },
            {
                "name": "South Wazīristān Agency",
                "countryCode": "PK"
            },
            {
                "name": "Wana",
                "countryCode": "PK"
            }
        ]
    },
    {
        "name": "Palau",
        "phonecode": "680",
        "cities": [
            {
                "name": "Ngchemiangel",
                "countryCode": "PW"
            },
            {
                "name": "Ngetkib",
                "countryCode": "PW"
            },
            {
                "name": "Angaur State",
                "countryCode": "PW"
            },
            {
                "name": "Tobi Village",
                "countryCode": "PW"
            },
            {
                "name": "Kayangel",
                "countryCode": "PW"
            },
            {
                "name": "Koror",
                "countryCode": "PW"
            },
            {
                "name": "Koror Town",
                "countryCode": "PW"
            },
            {
                "name": "Melekeok Village",
                "countryCode": "PW"
            },
            {
                "name": "Ulimang",
                "countryCode": "PW"
            },
            {
                "name": "Mengellang",
                "countryCode": "PW"
            },
            {
                "name": "Ngardmau",
                "countryCode": "PW"
            },
            {
                "name": "Ngchesar Hamlet",
                "countryCode": "PW"
            },
            {
                "name": "Imeong Hamlet",
                "countryCode": "PW"
            },
            {
                "name": "Ngerkeai",
                "countryCode": "PW"
            },
            {
                "name": "Kloulklubed",
                "countryCode": "PW"
            },
            {
                "name": "Sonsorol Village",
                "countryCode": "PW"
            }
        ]
    },
    {
        "name": "Palestinian Territory Occupied",
        "phonecode": "970",
        "cities": []
    },
    {
        "name": "Panama",
        "phonecode": "507",
        "cities": [
            {
                "name": "Almirante",
                "countryCode": "PA"
            },
            {
                "name": "Barranco",
                "countryCode": "PA"
            },
            {
                "name": "Barranco Adentro",
                "countryCode": "PA"
            },
            {
                "name": "Bastimentos",
                "countryCode": "PA"
            },
            {
                "name": "Bocas del Toro",
                "countryCode": "PA"
            },
            {
                "name": "Cauchero",
                "countryCode": "PA"
            },
            {
                "name": "Changuinola",
                "countryCode": "PA"
            },
            {
                "name": "Chiriquí Grande",
                "countryCode": "PA"
            },
            {
                "name": "Distrito Chiriquí Grande",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Bocas del Toro",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Changuinola",
                "countryCode": "PA"
            },
            {
                "name": "El Empalme",
                "countryCode": "PA"
            },
            {
                "name": "El Silencio",
                "countryCode": "PA"
            },
            {
                "name": "Guabito",
                "countryCode": "PA"
            },
            {
                "name": "Isla Bastimentos",
                "countryCode": "PA"
            },
            {
                "name": "La Mesa",
                "countryCode": "PA"
            },
            {
                "name": "Miramar",
                "countryCode": "PA"
            },
            {
                "name": "Punta Laurel",
                "countryCode": "PA"
            },
            {
                "name": "Punta Peña",
                "countryCode": "PA"
            },
            {
                "name": "Punta Róbalo",
                "countryCode": "PA"
            },
            {
                "name": "Valle del Risco",
                "countryCode": "PA"
            },
            {
                "name": "Alto del Espino",
                "countryCode": "PA"
            },
            {
                "name": "Altos de San Francisco",
                "countryCode": "PA"
            },
            {
                "name": "Arenosa",
                "countryCode": "PA"
            },
            {
                "name": "Arosemena",
                "countryCode": "PA"
            },
            {
                "name": "Arraiján",
                "countryCode": "PA"
            },
            {
                "name": "Bejuco",
                "countryCode": "PA"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "PA"
            },
            {
                "name": "Cabuya",
                "countryCode": "PA"
            },
            {
                "name": "Caimito",
                "countryCode": "PA"
            },
            {
                "name": "Capira",
                "countryCode": "PA"
            },
            {
                "name": "Cermeño",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Cama",
                "countryCode": "PA"
            },
            {
                "name": "Chame",
                "countryCode": "PA"
            },
            {
                "name": "Cirí de Los Sotos",
                "countryCode": "PA"
            },
            {
                "name": "Distrito Arraiján",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Capira",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chame",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de La Chorrera",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de San Carlos",
                "countryCode": "PA"
            },
            {
                "name": "El Cacao",
                "countryCode": "PA"
            },
            {
                "name": "El Coco",
                "countryCode": "PA"
            },
            {
                "name": "El Espavé",
                "countryCode": "PA"
            },
            {
                "name": "El Espino",
                "countryCode": "PA"
            },
            {
                "name": "El Higo",
                "countryCode": "PA"
            },
            {
                "name": "El Líbano",
                "countryCode": "PA"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PA"
            },
            {
                "name": "Guayabito",
                "countryCode": "PA"
            },
            {
                "name": "Hato Montaña",
                "countryCode": "PA"
            },
            {
                "name": "La Chorrera",
                "countryCode": "PA"
            },
            {
                "name": "La Ermita",
                "countryCode": "PA"
            },
            {
                "name": "La Herradura",
                "countryCode": "PA"
            },
            {
                "name": "La Laguna",
                "countryCode": "PA"
            },
            {
                "name": "La Mitra",
                "countryCode": "PA"
            },
            {
                "name": "La Pesa",
                "countryCode": "PA"
            },
            {
                "name": "Las Colinas",
                "countryCode": "PA"
            },
            {
                "name": "Las Lajas",
                "countryCode": "PA"
            },
            {
                "name": "Las Uvas",
                "countryCode": "PA"
            },
            {
                "name": "Llano Largo",
                "countryCode": "PA"
            },
            {
                "name": "Los Llanitos",
                "countryCode": "PA"
            },
            {
                "name": "Mendoza",
                "countryCode": "PA"
            },
            {
                "name": "Nueva Arenosa",
                "countryCode": "PA"
            },
            {
                "name": "Nueva Gorgona",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo Arraiján",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo Emperador",
                "countryCode": "PA"
            },
            {
                "name": "Playa Leona",
                "countryCode": "PA"
            },
            {
                "name": "Potrero Grande",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Caimito",
                "countryCode": "PA"
            },
            {
                "name": "Río Congo",
                "countryCode": "PA"
            },
            {
                "name": "San Carlos",
                "countryCode": "PA"
            },
            {
                "name": "San José",
                "countryCode": "PA"
            },
            {
                "name": "San Vicente de Bique",
                "countryCode": "PA"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PA"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PA"
            },
            {
                "name": "Sorá",
                "countryCode": "PA"
            },
            {
                "name": "Veracruz",
                "countryCode": "PA"
            },
            {
                "name": "Villa Carmen",
                "countryCode": "PA"
            },
            {
                "name": "Villa Rosario",
                "countryCode": "PA"
            },
            {
                "name": "Vista Alegre",
                "countryCode": "PA"
            },
            {
                "name": "Aguadulce",
                "countryCode": "PA"
            },
            {
                "name": "Aguas Blancas",
                "countryCode": "PA"
            },
            {
                "name": "Alto de La Estancia",
                "countryCode": "PA"
            },
            {
                "name": "Antón",
                "countryCode": "PA"
            },
            {
                "name": "Barranco Colorado",
                "countryCode": "PA"
            },
            {
                "name": "Caballero",
                "countryCode": "PA"
            },
            {
                "name": "Cabuya",
                "countryCode": "PA"
            },
            {
                "name": "Caimito",
                "countryCode": "PA"
            },
            {
                "name": "Capellanía",
                "countryCode": "PA"
            },
            {
                "name": "Cañaveral",
                "countryCode": "PA"
            },
            {
                "name": "Chigoré",
                "countryCode": "PA"
            },
            {
                "name": "Chiguirí Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Churuquita Chiquita",
                "countryCode": "PA"
            },
            {
                "name": "Churuquita Grande",
                "countryCode": "PA"
            },
            {
                "name": "Coclé",
                "countryCode": "PA"
            },
            {
                "name": "Distrito Penonomé",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Aguadulce",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Antón",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de La Pintada",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Natá",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Olá",
                "countryCode": "PA"
            },
            {
                "name": "El Caño",
                "countryCode": "PA"
            },
            {
                "name": "El Chirú",
                "countryCode": "PA"
            },
            {
                "name": "El Coco",
                "countryCode": "PA"
            },
            {
                "name": "El Copé",
                "countryCode": "PA"
            },
            {
                "name": "El Cortezo",
                "countryCode": "PA"
            },
            {
                "name": "El Cristo",
                "countryCode": "PA"
            },
            {
                "name": "El Potrero",
                "countryCode": "PA"
            },
            {
                "name": "El Retiro",
                "countryCode": "PA"
            },
            {
                "name": "El Roble",
                "countryCode": "PA"
            },
            {
                "name": "Entradero",
                "countryCode": "PA"
            },
            {
                "name": "Farallón",
                "countryCode": "PA"
            },
            {
                "name": "Guzman",
                "countryCode": "PA"
            },
            {
                "name": "Juan Díaz",
                "countryCode": "PA"
            },
            {
                "name": "La Loma",
                "countryCode": "PA"
            },
            {
                "name": "La Pintada",
                "countryCode": "PA"
            },
            {
                "name": "Las Huacas del Quije",
                "countryCode": "PA"
            },
            {
                "name": "Las Marias",
                "countryCode": "PA"
            },
            {
                "name": "Las Minas",
                "countryCode": "PA"
            },
            {
                "name": "Las Sabanas",
                "countryCode": "PA"
            },
            {
                "name": "Llano Grande",
                "countryCode": "PA"
            },
            {
                "name": "Llano Marín",
                "countryCode": "PA"
            },
            {
                "name": "Los Pollos",
                "countryCode": "PA"
            },
            {
                "name": "Natá",
                "countryCode": "PA"
            },
            {
                "name": "Nuestro Amo",
                "countryCode": "PA"
            },
            {
                "name": "Olá",
                "countryCode": "PA"
            },
            {
                "name": "Penonomé",
                "countryCode": "PA"
            },
            {
                "name": "Piedras Gordas",
                "countryCode": "PA"
            },
            {
                "name": "Pocrí",
                "countryCode": "PA"
            },
            {
                "name": "Río Grande",
                "countryCode": "PA"
            },
            {
                "name": "Río Hato",
                "countryCode": "PA"
            },
            {
                "name": "San Juan de Dios",
                "countryCode": "PA"
            },
            {
                "name": "Toabré",
                "countryCode": "PA"
            },
            {
                "name": "Toza",
                "countryCode": "PA"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "PA"
            },
            {
                "name": "Buena Vista",
                "countryCode": "PA"
            },
            {
                "name": "Cativá",
                "countryCode": "PA"
            },
            {
                "name": "Coclé del Norte",
                "countryCode": "PA"
            },
            {
                "name": "Colón",
                "countryCode": "PA"
            },
            {
                "name": "Cristóbal",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chagres",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Colón",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Donoso",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Portobelo",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Santa Isabel",
                "countryCode": "PA"
            },
            {
                "name": "El Giral",
                "countryCode": "PA"
            },
            {
                "name": "El Guabo",
                "countryCode": "PA"
            },
            {
                "name": "El Valle de la Unión",
                "countryCode": "PA"
            },
            {
                "name": "Escobal",
                "countryCode": "PA"
            },
            {
                "name": "Gatuncillo",
                "countryCode": "PA"
            },
            {
                "name": "Gatún",
                "countryCode": "PA"
            },
            {
                "name": "Las Margaritas",
                "countryCode": "PA"
            },
            {
                "name": "María Chiquita",
                "countryCode": "PA"
            },
            {
                "name": "Miramar",
                "countryCode": "PA"
            },
            {
                "name": "Nombre de Dios",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo Chagres",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo San Juan",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo Vigía",
                "countryCode": "PA"
            },
            {
                "name": "Palenque",
                "countryCode": "PA"
            },
            {
                "name": "Palmas Bellas",
                "countryCode": "PA"
            },
            {
                "name": "Palmira",
                "countryCode": "PA"
            },
            {
                "name": "Playa Chiquita",
                "countryCode": "PA"
            },
            {
                "name": "Portobelo",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Escondido",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Pilón",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada Bonita Adentro",
                "countryCode": "PA"
            },
            {
                "name": "Río Alejandro",
                "countryCode": "PA"
            },
            {
                "name": "Río Duque",
                "countryCode": "PA"
            },
            {
                "name": "Río Rita",
                "countryCode": "PA"
            },
            {
                "name": "Sabanitas",
                "countryCode": "PA"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "PA"
            },
            {
                "name": "Santa Rita Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Viento Frío",
                "countryCode": "PA"
            },
            {
                "name": "Alanje",
                "countryCode": "PA"
            },
            {
                "name": "Algarrobos Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Alto Boquete",
                "countryCode": "PA"
            },
            {
                "name": "Aserrío de Gariché",
                "countryCode": "PA"
            },
            {
                "name": "Bajo Boquete",
                "countryCode": "PA"
            },
            {
                "name": "Barrio Guadalupe",
                "countryCode": "PA"
            },
            {
                "name": "Bijagual",
                "countryCode": "PA"
            },
            {
                "name": "Boca Chica",
                "countryCode": "PA"
            },
            {
                "name": "Boca del Monte",
                "countryCode": "PA"
            },
            {
                "name": "Boquerón",
                "countryCode": "PA"
            },
            {
                "name": "Boquete",
                "countryCode": "PA"
            },
            {
                "name": "Breñón",
                "countryCode": "PA"
            },
            {
                "name": "Bugaba",
                "countryCode": "PA"
            },
            {
                "name": "Bugabita Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Bágala",
                "countryCode": "PA"
            },
            {
                "name": "Caimito",
                "countryCode": "PA"
            },
            {
                "name": "Caldera",
                "countryCode": "PA"
            },
            {
                "name": "Cañas Gordas",
                "countryCode": "PA"
            },
            {
                "name": "Celmira",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Punta",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Viejo",
                "countryCode": "PA"
            },
            {
                "name": "Chiriquí",
                "countryCode": "PA"
            },
            {
                "name": "Cochea Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Cordillera",
                "countryCode": "PA"
            },
            {
                "name": "David",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Alanje",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Barú",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Boquerón",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Boquete",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Bugaba",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de David",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Dolega",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Gualaca",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Remedios",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Renacimiento",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de San Félix",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de San Lorenzo",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Tolé",
                "countryCode": "PA"
            },
            {
                "name": "Divalá",
                "countryCode": "PA"
            },
            {
                "name": "Dolega District",
                "countryCode": "PA"
            },
            {
                "name": "El Nancito",
                "countryCode": "PA"
            },
            {
                "name": "El Palmar",
                "countryCode": "PA"
            },
            {
                "name": "El Porvenir",
                "countryCode": "PA"
            },
            {
                "name": "El Tejar",
                "countryCode": "PA"
            },
            {
                "name": "Finca Blanco",
                "countryCode": "PA"
            },
            {
                "name": "Guabal",
                "countryCode": "PA"
            },
            {
                "name": "Guaca Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Gualaca",
                "countryCode": "PA"
            },
            {
                "name": "Guarumal",
                "countryCode": "PA"
            },
            {
                "name": "Guayabal",
                "countryCode": "PA"
            },
            {
                "name": "Gómez",
                "countryCode": "PA"
            },
            {
                "name": "Horconcitos",
                "countryCode": "PA"
            },
            {
                "name": "La Concepción",
                "countryCode": "PA"
            },
            {
                "name": "La Esperanza",
                "countryCode": "PA"
            },
            {
                "name": "La Estrella",
                "countryCode": "PA"
            },
            {
                "name": "Lajas Adentro",
                "countryCode": "PA"
            },
            {
                "name": "Lajas de Tolé",
                "countryCode": "PA"
            },
            {
                "name": "Las Lajas",
                "countryCode": "PA"
            },
            {
                "name": "Las Lomas",
                "countryCode": "PA"
            },
            {
                "name": "Limones",
                "countryCode": "PA"
            },
            {
                "name": "Los Algarrobos",
                "countryCode": "PA"
            },
            {
                "name": "Los Anastacios",
                "countryCode": "PA"
            },
            {
                "name": "Los Naranjos",
                "countryCode": "PA"
            },
            {
                "name": "Los Ángeles",
                "countryCode": "PA"
            },
            {
                "name": "Manaca Civil",
                "countryCode": "PA"
            },
            {
                "name": "Manaca Norte",
                "countryCode": "PA"
            },
            {
                "name": "Mata del Nance",
                "countryCode": "PA"
            },
            {
                "name": "Monte Lirio",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo San Carlitos",
                "countryCode": "PA"
            },
            {
                "name": "Paja de Sombrero",
                "countryCode": "PA"
            },
            {
                "name": "Palmira",
                "countryCode": "PA"
            },
            {
                "name": "Palmira Centro",
                "countryCode": "PA"
            },
            {
                "name": "Paraíso",
                "countryCode": "PA"
            },
            {
                "name": "Paso Canoas Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Pedregal",
                "countryCode": "PA"
            },
            {
                "name": "Plaza de Caisán",
                "countryCode": "PA"
            },
            {
                "name": "Potrerillos Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Potrerillos Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Progreso",
                "countryCode": "PA"
            },
            {
                "name": "Pueblo Viejo",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Armuelles",
                "countryCode": "PA"
            },
            {
                "name": "Punta de Burica",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada de Piedra",
                "countryCode": "PA"
            },
            {
                "name": "Querévalo",
                "countryCode": "PA"
            },
            {
                "name": "Quinteño",
                "countryCode": "PA"
            },
            {
                "name": "Remedios",
                "countryCode": "PA"
            },
            {
                "name": "Rincón",
                "countryCode": "PA"
            },
            {
                "name": "Rovira Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Río Sereno",
                "countryCode": "PA"
            },
            {
                "name": "San Andrés",
                "countryCode": "PA"
            },
            {
                "name": "San Carlos",
                "countryCode": "PA"
            },
            {
                "name": "San Félix",
                "countryCode": "PA"
            },
            {
                "name": "San Isidro",
                "countryCode": "PA"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PA"
            },
            {
                "name": "San Pablo Nuevo Abajo",
                "countryCode": "PA"
            },
            {
                "name": "San Pablo Viejo Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PA"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PA"
            },
            {
                "name": "Santa Marta",
                "countryCode": "PA"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "PA"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PA"
            },
            {
                "name": "Santo Tomás",
                "countryCode": "PA"
            },
            {
                "name": "Sioguí Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Sioguí Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Sortova",
                "countryCode": "PA"
            },
            {
                "name": "Tijeras",
                "countryCode": "PA"
            },
            {
                "name": "Tinajas",
                "countryCode": "PA"
            },
            {
                "name": "Tolé",
                "countryCode": "PA"
            },
            {
                "name": "Veladero",
                "countryCode": "PA"
            },
            {
                "name": "Volcán",
                "countryCode": "PA"
            },
            {
                "name": "Boca de Cupé",
                "countryCode": "PA"
            },
            {
                "name": "Camogantí",
                "countryCode": "PA"
            },
            {
                "name": "Cucunatí",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chepigana",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Pinogana",
                "countryCode": "PA"
            },
            {
                "name": "Garachiné",
                "countryCode": "PA"
            },
            {
                "name": "Jaqué",
                "countryCode": "PA"
            },
            {
                "name": "La Palma",
                "countryCode": "PA"
            },
            {
                "name": "Metetí",
                "countryCode": "PA"
            },
            {
                "name": "Mortí",
                "countryCode": "PA"
            },
            {
                "name": "Púcuro",
                "countryCode": "PA"
            },
            {
                "name": "Santa Fé",
                "countryCode": "PA"
            },
            {
                "name": "Yaviza",
                "countryCode": "PA"
            },
            {
                "name": "Boca de Parita",
                "countryCode": "PA"
            },
            {
                "name": "Cabuya",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Largo",
                "countryCode": "PA"
            },
            {
                "name": "Chitré",
                "countryCode": "PA"
            },
            {
                "name": "Chumical",
                "countryCode": "PA"
            },
            {
                "name": "Chupampa",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chitré",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Las Minas",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Los Pozos",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Ocú",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Parita",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Pesé",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Santa María",
                "countryCode": "PA"
            },
            {
                "name": "El Calabacito",
                "countryCode": "PA"
            },
            {
                "name": "El Capurí",
                "countryCode": "PA"
            },
            {
                "name": "El Cedro",
                "countryCode": "PA"
            },
            {
                "name": "El Pedregoso",
                "countryCode": "PA"
            },
            {
                "name": "El Rincón",
                "countryCode": "PA"
            },
            {
                "name": "El Toro",
                "countryCode": "PA"
            },
            {
                "name": "La Arena",
                "countryCode": "PA"
            },
            {
                "name": "La Trinidad",
                "countryCode": "PA"
            },
            {
                "name": "Las Guabas",
                "countryCode": "PA"
            },
            {
                "name": "Las Minas",
                "countryCode": "PA"
            },
            {
                "name": "Leones Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Limón de Tijeras",
                "countryCode": "PA"
            },
            {
                "name": "Llano Bonito",
                "countryCode": "PA"
            },
            {
                "name": "Llano Grande",
                "countryCode": "PA"
            },
            {
                "name": "Llano de La Cruz",
                "countryCode": "PA"
            },
            {
                "name": "Los Canelos",
                "countryCode": "PA"
            },
            {
                "name": "Los Castillos",
                "countryCode": "PA"
            },
            {
                "name": "Los Cerritos",
                "countryCode": "PA"
            },
            {
                "name": "Los Cerros de Paja",
                "countryCode": "PA"
            },
            {
                "name": "Los Pozos",
                "countryCode": "PA"
            },
            {
                "name": "Monagrillo",
                "countryCode": "PA"
            },
            {
                "name": "Ocú",
                "countryCode": "PA"
            },
            {
                "name": "Parita",
                "countryCode": "PA"
            },
            {
                "name": "París",
                "countryCode": "PA"
            },
            {
                "name": "Pesé",
                "countryCode": "PA"
            },
            {
                "name": "Peñas Chatas",
                "countryCode": "PA"
            },
            {
                "name": "Pitaloza Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Portobelillo",
                "countryCode": "PA"
            },
            {
                "name": "Potuga",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada del Rosario",
                "countryCode": "PA"
            },
            {
                "name": "Rincón Hondo",
                "countryCode": "PA"
            },
            {
                "name": "Sabana Grande",
                "countryCode": "PA"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "PA"
            },
            {
                "name": "Santa María",
                "countryCode": "PA"
            },
            {
                "name": "Agua Buena",
                "countryCode": "PA"
            },
            {
                "name": "Ave María",
                "countryCode": "PA"
            },
            {
                "name": "Bahía Honda",
                "countryCode": "PA"
            },
            {
                "name": "Bajo Corral",
                "countryCode": "PA"
            },
            {
                "name": "Bayano",
                "countryCode": "PA"
            },
            {
                "name": "Bella Vista",
                "countryCode": "PA"
            },
            {
                "name": "Cambutal",
                "countryCode": "PA"
            },
            {
                "name": "Cañas",
                "countryCode": "PA"
            },
            {
                "name": "Cedro Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Guararé",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Las Tablas",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Los Santos",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Macaracas",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Pedasí",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Pocrí",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Tonosí",
                "countryCode": "PA"
            },
            {
                "name": "El Cacao",
                "countryCode": "PA"
            },
            {
                "name": "El Carate",
                "countryCode": "PA"
            },
            {
                "name": "El Cañafístulo",
                "countryCode": "PA"
            },
            {
                "name": "El Cocal",
                "countryCode": "PA"
            },
            {
                "name": "El Cortezo",
                "countryCode": "PA"
            },
            {
                "name": "El Ejido",
                "countryCode": "PA"
            },
            {
                "name": "El Guásimo",
                "countryCode": "PA"
            },
            {
                "name": "El Hato",
                "countryCode": "PA"
            },
            {
                "name": "El Macano",
                "countryCode": "PA"
            },
            {
                "name": "El Manantial",
                "countryCode": "PA"
            },
            {
                "name": "El Muñoz",
                "countryCode": "PA"
            },
            {
                "name": "El Pedregoso",
                "countryCode": "PA"
            },
            {
                "name": "Flores",
                "countryCode": "PA"
            },
            {
                "name": "Guararé",
                "countryCode": "PA"
            },
            {
                "name": "Guararé Arriba",
                "countryCode": "PA"
            },
            {
                "name": "La Colorada",
                "countryCode": "PA"
            },
            {
                "name": "La Enea",
                "countryCode": "PA"
            },
            {
                "name": "La Espigadilla",
                "countryCode": "PA"
            },
            {
                "name": "La Laja",
                "countryCode": "PA"
            },
            {
                "name": "La Mesa",
                "countryCode": "PA"
            },
            {
                "name": "La Miel",
                "countryCode": "PA"
            },
            {
                "name": "La Palma",
                "countryCode": "PA"
            },
            {
                "name": "La Pasera",
                "countryCode": "PA"
            },
            {
                "name": "La Tiza",
                "countryCode": "PA"
            },
            {
                "name": "La Tronosa",
                "countryCode": "PA"
            },
            {
                "name": "Lajamina",
                "countryCode": "PA"
            },
            {
                "name": "Las Cruces",
                "countryCode": "PA"
            },
            {
                "name": "Las Guabas",
                "countryCode": "PA"
            },
            {
                "name": "Las Palmas",
                "countryCode": "PA"
            },
            {
                "name": "Las Palmitas",
                "countryCode": "PA"
            },
            {
                "name": "Las Tablas",
                "countryCode": "PA"
            },
            {
                "name": "Las Trancas",
                "countryCode": "PA"
            },
            {
                "name": "Llano Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Llano Largo",
                "countryCode": "PA"
            },
            {
                "name": "Llano de Piedra",
                "countryCode": "PA"
            },
            {
                "name": "Los Asientos",
                "countryCode": "PA"
            },
            {
                "name": "Los Olivos",
                "countryCode": "PA"
            },
            {
                "name": "Los Santos",
                "countryCode": "PA"
            },
            {
                "name": "Los Ángeles",
                "countryCode": "PA"
            },
            {
                "name": "Macaracas",
                "countryCode": "PA"
            },
            {
                "name": "Mogollón",
                "countryCode": "PA"
            },
            {
                "name": "Nuario",
                "countryCode": "PA"
            },
            {
                "name": "Oria Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Palmira",
                "countryCode": "PA"
            },
            {
                "name": "Paraíso",
                "countryCode": "PA"
            },
            {
                "name": "Paritilla",
                "countryCode": "PA"
            },
            {
                "name": "Pedasí",
                "countryCode": "PA"
            },
            {
                "name": "Perales",
                "countryCode": "PA"
            },
            {
                "name": "Peña Blanca",
                "countryCode": "PA"
            },
            {
                "name": "Pocrí",
                "countryCode": "PA"
            },
            {
                "name": "Río Hondo",
                "countryCode": "PA"
            },
            {
                "name": "Sabana Grande",
                "countryCode": "PA"
            },
            {
                "name": "San José",
                "countryCode": "PA"
            },
            {
                "name": "Santa Ana Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PA"
            },
            {
                "name": "Sesteadero",
                "countryCode": "PA"
            },
            {
                "name": "Tonosí",
                "countryCode": "PA"
            },
            {
                "name": "Tres Quebradas",
                "countryCode": "PA"
            },
            {
                "name": "Valle Rico",
                "countryCode": "PA"
            },
            {
                "name": "Vallerriquito",
                "countryCode": "PA"
            },
            {
                "name": "Villa Lourdes",
                "countryCode": "PA"
            },
            {
                "name": "Alcalde Díaz",
                "countryCode": "PA"
            },
            {
                "name": "Ancón",
                "countryCode": "PA"
            },
            {
                "name": "Brujas",
                "countryCode": "PA"
            },
            {
                "name": "Cabra Número Uno",
                "countryCode": "PA"
            },
            {
                "name": "Caimitillo",
                "countryCode": "PA"
            },
            {
                "name": "Calzada Larga",
                "countryCode": "PA"
            },
            {
                "name": "Cañita",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Azul",
                "countryCode": "PA"
            },
            {
                "name": "Chepillo",
                "countryCode": "PA"
            },
            {
                "name": "Chepo",
                "countryCode": "PA"
            },
            {
                "name": "Chilibre",
                "countryCode": "PA"
            },
            {
                "name": "Chimán",
                "countryCode": "PA"
            },
            {
                "name": "Contadora",
                "countryCode": "PA"
            },
            {
                "name": "Curundú",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Balboa",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chepo",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Chimán",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Panamá",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Taboga",
                "countryCode": "PA"
            },
            {
                "name": "El Chorrillo",
                "countryCode": "PA"
            },
            {
                "name": "El Progreso",
                "countryCode": "PA"
            },
            {
                "name": "Gonzalillo",
                "countryCode": "PA"
            },
            {
                "name": "Howard",
                "countryCode": "PA"
            },
            {
                "name": "Juan Díaz",
                "countryCode": "PA"
            },
            {
                "name": "La Cabima",
                "countryCode": "PA"
            },
            {
                "name": "La Colorada",
                "countryCode": "PA"
            },
            {
                "name": "La Ensenada",
                "countryCode": "PA"
            },
            {
                "name": "La Esmeralda",
                "countryCode": "PA"
            },
            {
                "name": "La Guinea",
                "countryCode": "PA"
            },
            {
                "name": "La Mesa",
                "countryCode": "PA"
            },
            {
                "name": "Las Cumbres",
                "countryCode": "PA"
            },
            {
                "name": "Las Margaritas",
                "countryCode": "PA"
            },
            {
                "name": "Los Lotes",
                "countryCode": "PA"
            },
            {
                "name": "Lídice",
                "countryCode": "PA"
            },
            {
                "name": "Nueva Esperanza",
                "countryCode": "PA"
            },
            {
                "name": "Nuevo Belén",
                "countryCode": "PA"
            },
            {
                "name": "Pacora",
                "countryCode": "PA"
            },
            {
                "name": "Panamá",
                "countryCode": "PA"
            },
            {
                "name": "Parque Lefevre",
                "countryCode": "PA"
            },
            {
                "name": "Pedregal",
                "countryCode": "PA"
            },
            {
                "name": "Pásiga",
                "countryCode": "PA"
            },
            {
                "name": "Río Abajo",
                "countryCode": "PA"
            },
            {
                "name": "San Antonio",
                "countryCode": "PA"
            },
            {
                "name": "San Felipe",
                "countryCode": "PA"
            },
            {
                "name": "San Miguel",
                "countryCode": "PA"
            },
            {
                "name": "San Miguelito",
                "countryCode": "PA"
            },
            {
                "name": "San Vicente",
                "countryCode": "PA"
            },
            {
                "name": "Tocumen",
                "countryCode": "PA"
            },
            {
                "name": "Tortí",
                "countryCode": "PA"
            },
            {
                "name": "Unión Santeña",
                "countryCode": "PA"
            },
            {
                "name": "Unión de Azuero",
                "countryCode": "PA"
            },
            {
                "name": "Villa Unida",
                "countryCode": "PA"
            },
            {
                "name": "Vista Hermosa",
                "countryCode": "PA"
            },
            {
                "name": "Alto de Jesús",
                "countryCode": "PA"
            },
            {
                "name": "Arenas",
                "countryCode": "PA"
            },
            {
                "name": "Atalaya",
                "countryCode": "PA"
            },
            {
                "name": "Barnizal",
                "countryCode": "PA"
            },
            {
                "name": "Bisvalles",
                "countryCode": "PA"
            },
            {
                "name": "Boquerón",
                "countryCode": "PA"
            },
            {
                "name": "Boró",
                "countryCode": "PA"
            },
            {
                "name": "Calabacito",
                "countryCode": "PA"
            },
            {
                "name": "Calidonia",
                "countryCode": "PA"
            },
            {
                "name": "Calobre",
                "countryCode": "PA"
            },
            {
                "name": "Calovébora",
                "countryCode": "PA"
            },
            {
                "name": "Canto del Llano",
                "countryCode": "PA"
            },
            {
                "name": "Cañazas",
                "countryCode": "PA"
            },
            {
                "name": "Corozal",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Atalaya",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Calobre",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Cañazas",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de La Mesa",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Las Palmas",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Montijo",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Río de Jesús",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de San Francisco",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Santa Fé",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Santiago",
                "countryCode": "PA"
            },
            {
                "name": "Distrito de Soná",
                "countryCode": "PA"
            },
            {
                "name": "El Alto",
                "countryCode": "PA"
            },
            {
                "name": "El Barrito",
                "countryCode": "PA"
            },
            {
                "name": "El Coclá",
                "countryCode": "PA"
            },
            {
                "name": "El Espino de Santa Rosa",
                "countryCode": "PA"
            },
            {
                "name": "El Marañón",
                "countryCode": "PA"
            },
            {
                "name": "El María",
                "countryCode": "PA"
            },
            {
                "name": "El Pantano",
                "countryCode": "PA"
            },
            {
                "name": "El Peñón",
                "countryCode": "PA"
            },
            {
                "name": "El Picador",
                "countryCode": "PA"
            },
            {
                "name": "El Potrero",
                "countryCode": "PA"
            },
            {
                "name": "El Pájaro",
                "countryCode": "PA"
            },
            {
                "name": "El Rincón",
                "countryCode": "PA"
            },
            {
                "name": "El Uvito",
                "countryCode": "PA"
            },
            {
                "name": "Guarumal",
                "countryCode": "PA"
            },
            {
                "name": "La Colorada",
                "countryCode": "PA"
            },
            {
                "name": "La Garceana",
                "countryCode": "PA"
            },
            {
                "name": "La Laguna",
                "countryCode": "PA"
            },
            {
                "name": "La Loma",
                "countryCode": "PA"
            },
            {
                "name": "La Mesa",
                "countryCode": "PA"
            },
            {
                "name": "La Montañuela",
                "countryCode": "PA"
            },
            {
                "name": "La Peña",
                "countryCode": "PA"
            },
            {
                "name": "La Raya de Calobre",
                "countryCode": "PA"
            },
            {
                "name": "La Raya de Santa María",
                "countryCode": "PA"
            },
            {
                "name": "La Yeguada",
                "countryCode": "PA"
            },
            {
                "name": "Las Guías Abajo",
                "countryCode": "PA"
            },
            {
                "name": "Las Huacas",
                "countryCode": "PA"
            },
            {
                "name": "Las Palmas",
                "countryCode": "PA"
            },
            {
                "name": "Leones Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Llano Grande",
                "countryCode": "PA"
            },
            {
                "name": "Lolá",
                "countryCode": "PA"
            },
            {
                "name": "Los Algarrobos",
                "countryCode": "PA"
            },
            {
                "name": "Los Castillos",
                "countryCode": "PA"
            },
            {
                "name": "Los Higos",
                "countryCode": "PA"
            },
            {
                "name": "Mariato District",
                "countryCode": "PA"
            },
            {
                "name": "Monjarás",
                "countryCode": "PA"
            },
            {
                "name": "Montijo",
                "countryCode": "PA"
            },
            {
                "name": "Piedras Gordas",
                "countryCode": "PA"
            },
            {
                "name": "Ponuga",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Vidal",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada de Oro",
                "countryCode": "PA"
            },
            {
                "name": "Rodeo Viejo",
                "countryCode": "PA"
            },
            {
                "name": "Río Grande",
                "countryCode": "PA"
            },
            {
                "name": "Río de Jesús",
                "countryCode": "PA"
            },
            {
                "name": "San Bartolo",
                "countryCode": "PA"
            },
            {
                "name": "San Francisco",
                "countryCode": "PA"
            },
            {
                "name": "San José",
                "countryCode": "PA"
            },
            {
                "name": "San Juan",
                "countryCode": "PA"
            },
            {
                "name": "San Marcelo",
                "countryCode": "PA"
            },
            {
                "name": "San Pedro del Espino",
                "countryCode": "PA"
            },
            {
                "name": "Santa Fé",
                "countryCode": "PA"
            },
            {
                "name": "Santiago de Veraguas",
                "countryCode": "PA"
            },
            {
                "name": "Soná",
                "countryCode": "PA"
            },
            {
                "name": "Tebario",
                "countryCode": "PA"
            },
            {
                "name": "Utirá",
                "countryCode": "PA"
            },
            {
                "name": "Viguí",
                "countryCode": "PA"
            },
            {
                "name": "Zapotillo",
                "countryCode": "PA"
            },
            {
                "name": "Bayamón",
                "countryCode": "PA"
            },
            {
                "name": "Corozal",
                "countryCode": "PA"
            },
            {
                "name": "Cémaco",
                "countryCode": "PA"
            },
            {
                "name": "Sambú",
                "countryCode": "PA"
            },
            {
                "name": "Unión Chocó",
                "countryCode": "PA"
            },
            {
                "name": "Yape",
                "countryCode": "PA"
            },
            {
                "name": "Achutupo",
                "countryCode": "PA"
            },
            {
                "name": "Ailigandí",
                "countryCode": "PA"
            },
            {
                "name": "Cartí Sugdup",
                "countryCode": "PA"
            },
            {
                "name": "El Porvenir",
                "countryCode": "PA"
            },
            {
                "name": "Mulatupo",
                "countryCode": "PA"
            },
            {
                "name": "Narganá",
                "countryCode": "PA"
            },
            {
                "name": "Playón Chico",
                "countryCode": "PA"
            },
            {
                "name": "Puerto Obaldía",
                "countryCode": "PA"
            },
            {
                "name": "San Ignacio de Tupile",
                "countryCode": "PA"
            },
            {
                "name": "Tubualá",
                "countryCode": "PA"
            },
            {
                "name": "Ustupo",
                "countryCode": "PA"
            },
            {
                "name": "Bahía Azul",
                "countryCode": "PA"
            },
            {
                "name": "Besiko",
                "countryCode": "PA"
            },
            {
                "name": "Bisira",
                "countryCode": "PA"
            },
            {
                "name": "Boca de Balsa",
                "countryCode": "PA"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "PA"
            },
            {
                "name": "Camarón Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Banco",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Caña",
                "countryCode": "PA"
            },
            {
                "name": "Cerro Plata",
                "countryCode": "PA"
            },
            {
                "name": "Chichica",
                "countryCode": "PA"
            },
            {
                "name": "Guayabito",
                "countryCode": "PA"
            },
            {
                "name": "Hato Chami",
                "countryCode": "PA"
            },
            {
                "name": "Hato Corotú",
                "countryCode": "PA"
            },
            {
                "name": "Kankintú",
                "countryCode": "PA"
            },
            {
                "name": "Kusapín",
                "countryCode": "PA"
            },
            {
                "name": "Lajero Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Llano Ñopo",
                "countryCode": "PA"
            },
            {
                "name": "Loma Yuca",
                "countryCode": "PA"
            },
            {
                "name": "Mirono",
                "countryCode": "PA"
            },
            {
                "name": "Müna",
                "countryCode": "PA"
            },
            {
                "name": "Nole Düima",
                "countryCode": "PA"
            },
            {
                "name": "Nurun",
                "countryCode": "PA"
            },
            {
                "name": "Oma",
                "countryCode": "PA"
            },
            {
                "name": "Paredón Arriba",
                "countryCode": "PA"
            },
            {
                "name": "Plan de Chorcha",
                "countryCode": "PA"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada Canoa",
                "countryCode": "PA"
            },
            {
                "name": "Quebrada Tula",
                "countryCode": "PA"
            },
            {
                "name": "Sitio Prado",
                "countryCode": "PA"
            },
            {
                "name": "Tobobe",
                "countryCode": "PA"
            }
        ]
    },
    {
        "name": "Papua new Guinea",
        "phonecode": "675",
        "cities": [
            {
                "name": "Chuave",
                "countryCode": "PG"
            },
            {
                "name": "Gumine",
                "countryCode": "PG"
            },
            {
                "name": "Karimui Nomane",
                "countryCode": "PG"
            },
            {
                "name": "Kerowagi",
                "countryCode": "PG"
            },
            {
                "name": "Kundiawa",
                "countryCode": "PG"
            },
            {
                "name": "Sinasina Yonggamugl",
                "countryCode": "PG"
            },
            {
                "name": "Abau",
                "countryCode": "PG"
            },
            {
                "name": "Goilala",
                "countryCode": "PG"
            },
            {
                "name": "Kairuku-Hiri",
                "countryCode": "PG"
            },
            {
                "name": "Rigo",
                "countryCode": "PG"
            },
            {
                "name": "Gazelle",
                "countryCode": "PG"
            },
            {
                "name": "Kokopo",
                "countryCode": "PG"
            },
            {
                "name": "Pomio",
                "countryCode": "PG"
            },
            {
                "name": "Rabaul",
                "countryCode": "PG"
            },
            {
                "name": "Daulo",
                "countryCode": "PG"
            },
            {
                "name": "Goroka",
                "countryCode": "PG"
            },
            {
                "name": "Henganofi",
                "countryCode": "PG"
            },
            {
                "name": "Kainantu",
                "countryCode": "PG"
            },
            {
                "name": "Lufa",
                "countryCode": "PG"
            },
            {
                "name": "Obura Wonenara",
                "countryCode": "PG"
            },
            {
                "name": "Okapa",
                "countryCode": "PG"
            },
            {
                "name": "Unggai Bena",
                "countryCode": "PG"
            },
            {
                "name": "Kandep",
                "countryCode": "PG"
            },
            {
                "name": "Kompiam Ambum",
                "countryCode": "PG"
            },
            {
                "name": "Lagaip Porgera",
                "countryCode": "PG"
            },
            {
                "name": "Porgera",
                "countryCode": "PG"
            },
            {
                "name": "Wabag",
                "countryCode": "PG"
            },
            {
                "name": "Wapenamanda",
                "countryCode": "PG"
            },
            {
                "name": "Kerema",
                "countryCode": "PG"
            },
            {
                "name": "Kikori",
                "countryCode": "PG"
            },
            {
                "name": "Komo Margarima",
                "countryCode": "PG"
            },
            {
                "name": "Koroba-Lake Kopiago",
                "countryCode": "PG"
            },
            {
                "name": "Tari",
                "countryCode": "PG"
            },
            {
                "name": "Tari Pori",
                "countryCode": "PG"
            },
            {
                "name": "Angalimp South Wahgi",
                "countryCode": "PG"
            },
            {
                "name": "Jimi",
                "countryCode": "PG"
            },
            {
                "name": "North Wahgi",
                "countryCode": "PG"
            },
            {
                "name": "Alotau",
                "countryCode": "PG"
            },
            {
                "name": "Esa’ala",
                "countryCode": "PG"
            },
            {
                "name": "Kiriwina Goodenough",
                "countryCode": "PG"
            },
            {
                "name": "Samarai",
                "countryCode": "PG"
            },
            {
                "name": "Samarai Murua",
                "countryCode": "PG"
            },
            {
                "name": "Bulolo",
                "countryCode": "PG"
            },
            {
                "name": "Finschhafen",
                "countryCode": "PG"
            },
            {
                "name": "Huon Gulf",
                "countryCode": "PG"
            },
            {
                "name": "Kabwum",
                "countryCode": "PG"
            },
            {
                "name": "Lae",
                "countryCode": "PG"
            },
            {
                "name": "Markham",
                "countryCode": "PG"
            },
            {
                "name": "Menyamya",
                "countryCode": "PG"
            },
            {
                "name": "Nawae",
                "countryCode": "PG"
            },
            {
                "name": "Tewai Siassi",
                "countryCode": "PG"
            },
            {
                "name": "Wau",
                "countryCode": "PG"
            },
            {
                "name": "Bogia",
                "countryCode": "PG"
            },
            {
                "name": "Madang",
                "countryCode": "PG"
            },
            {
                "name": "Middle Ramu",
                "countryCode": "PG"
            },
            {
                "name": "Rai Coast",
                "countryCode": "PG"
            },
            {
                "name": "Sumkar",
                "countryCode": "PG"
            },
            {
                "name": "Usino Bundi",
                "countryCode": "PG"
            },
            {
                "name": "Lorengau",
                "countryCode": "PG"
            },
            {
                "name": "Manus",
                "countryCode": "PG"
            },
            {
                "name": "National Capital District",
                "countryCode": "PG"
            },
            {
                "name": "Port Moresby",
                "countryCode": "PG"
            },
            {
                "name": "Kavieng",
                "countryCode": "PG"
            },
            {
                "name": "Namatanai",
                "countryCode": "PG"
            },
            {
                "name": "Ijivitari",
                "countryCode": "PG"
            },
            {
                "name": "Kokoda",
                "countryCode": "PG"
            },
            {
                "name": "Popondetta",
                "countryCode": "PG"
            },
            {
                "name": "Sohe",
                "countryCode": "PG"
            },
            {
                "name": "Arawa",
                "countryCode": "PG"
            },
            {
                "name": "Central Bougainville",
                "countryCode": "PG"
            },
            {
                "name": "Kieta",
                "countryCode": "PG"
            },
            {
                "name": "North Bougainville",
                "countryCode": "PG"
            },
            {
                "name": "Panguna",
                "countryCode": "PG"
            },
            {
                "name": "South Bougainville",
                "countryCode": "PG"
            },
            {
                "name": "Aitape",
                "countryCode": "PG"
            },
            {
                "name": "Aitape Lumi",
                "countryCode": "PG"
            },
            {
                "name": "Nuku",
                "countryCode": "PG"
            },
            {
                "name": "Telefomin",
                "countryCode": "PG"
            },
            {
                "name": "Vanimo",
                "countryCode": "PG"
            },
            {
                "name": "Vanimo Green",
                "countryCode": "PG"
            },
            {
                "name": "Ialibu",
                "countryCode": "PG"
            },
            {
                "name": "Ialibu Pangia",
                "countryCode": "PG"
            },
            {
                "name": "Imbonggu",
                "countryCode": "PG"
            },
            {
                "name": "Kagua Erave",
                "countryCode": "PG"
            },
            {
                "name": "Mendi",
                "countryCode": "PG"
            },
            {
                "name": "Nipa Kutubu",
                "countryCode": "PG"
            },
            {
                "name": "Kandrian",
                "countryCode": "PG"
            },
            {
                "name": "Kandrian Gloucester",
                "countryCode": "PG"
            },
            {
                "name": "Kimbe",
                "countryCode": "PG"
            },
            {
                "name": "Talasea",
                "countryCode": "PG"
            },
            {
                "name": "Baiyer Mul",
                "countryCode": "PG"
            },
            {
                "name": "Dei",
                "countryCode": "PG"
            },
            {
                "name": "Hagen",
                "countryCode": "PG"
            },
            {
                "name": "Mount Hagen",
                "countryCode": "PG"
            },
            {
                "name": "Tambul Nebilyer",
                "countryCode": "PG"
            },
            {
                "name": "Daru",
                "countryCode": "PG"
            },
            {
                "name": "Kiunga",
                "countryCode": "PG"
            },
            {
                "name": "Middle Fly",
                "countryCode": "PG"
            },
            {
                "name": "Morehead",
                "countryCode": "PG"
            },
            {
                "name": "North Fly",
                "countryCode": "PG"
            },
            {
                "name": "South Fly",
                "countryCode": "PG"
            }
        ]
    },
    {
        "name": "Paraguay",
        "phonecode": "595",
        "cities": [
            {
                "name": "Belén",
                "countryCode": "PY"
            },
            {
                "name": "Concepción",
                "countryCode": "PY"
            },
            {
                "name": "Horqueta",
                "countryCode": "PY"
            },
            {
                "name": "Cedrales",
                "countryCode": "PY"
            },
            {
                "name": "Ciudad del Este",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Minga Porá",
                "countryCode": "PY"
            },
            {
                "name": "Doctor Juan León Mallorquín",
                "countryCode": "PY"
            },
            {
                "name": "Itakyry",
                "countryCode": "PY"
            },
            {
                "name": "Naranjal",
                "countryCode": "PY"
            },
            {
                "name": "Presidente Franco",
                "countryCode": "PY"
            },
            {
                "name": "San Alberto",
                "countryCode": "PY"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PY"
            },
            {
                "name": "Yguazú",
                "countryCode": "PY"
            },
            {
                "name": "Areguá",
                "countryCode": "PY"
            },
            {
                "name": "Capiatá",
                "countryCode": "PY"
            },
            {
                "name": "Fernando de la Mora",
                "countryCode": "PY"
            },
            {
                "name": "Guarambaré",
                "countryCode": "PY"
            },
            {
                "name": "Itauguá",
                "countryCode": "PY"
            },
            {
                "name": "Itá",
                "countryCode": "PY"
            },
            {
                "name": "Lambaré",
                "countryCode": "PY"
            },
            {
                "name": "Limpio",
                "countryCode": "PY"
            },
            {
                "name": "Mariano Roque Alonso",
                "countryCode": "PY"
            },
            {
                "name": "Nemby",
                "countryCode": "PY"
            },
            {
                "name": "Nueva Italia",
                "countryCode": "PY"
            },
            {
                "name": "San Antonio",
                "countryCode": "PY"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PY"
            },
            {
                "name": "Villa Elisa",
                "countryCode": "PY"
            },
            {
                "name": "Ypacarai",
                "countryCode": "PY"
            },
            {
                "name": "Cerrito",
                "countryCode": "PY"
            },
            {
                "name": "General José Eduvigis Díaz",
                "countryCode": "PY"
            },
            {
                "name": "Pilar",
                "countryCode": "PY"
            },
            {
                "name": "Bella Vista",
                "countryCode": "PY"
            },
            {
                "name": "Capitán Bado",
                "countryCode": "PY"
            },
            {
                "name": "Pedro Juan Caballero",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Catuete",
                "countryCode": "PY"
            },
            {
                "name": "Corpus Christi",
                "countryCode": "PY"
            },
            {
                "name": "La Paloma",
                "countryCode": "PY"
            },
            {
                "name": "Nueva Esperanza",
                "countryCode": "PY"
            },
            {
                "name": "Salto del Guairá",
                "countryCode": "PY"
            },
            {
                "name": "San Isidro de Curuguaty",
                "countryCode": "PY"
            },
            {
                "name": "Ygatimi",
                "countryCode": "PY"
            },
            {
                "name": "Benjamín Aceval",
                "countryCode": "PY"
            },
            {
                "name": "Nanawua",
                "countryCode": "PY"
            },
            {
                "name": "Villa Hayes",
                "countryCode": "PY"
            },
            {
                "name": "Capitán Pablo Lagerenza",
                "countryCode": "PY"
            },
            {
                "name": "Fuerte Olimpo",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Menno",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Neuland",
                "countryCode": "PY"
            },
            {
                "name": "Filadelfia",
                "countryCode": "PY"
            },
            {
                "name": "Antequera",
                "countryCode": "PY"
            },
            {
                "name": "Capiíbary",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Nueva Germania",
                "countryCode": "PY"
            },
            {
                "name": "General Elizardo Aquino",
                "countryCode": "PY"
            },
            {
                "name": "Guayaybi",
                "countryCode": "PY"
            },
            {
                "name": "Itacurubí del Rosario",
                "countryCode": "PY"
            },
            {
                "name": "Lima",
                "countryCode": "PY"
            },
            {
                "name": "Puerto Rosario",
                "countryCode": "PY"
            },
            {
                "name": "San Pedro de Ycuamandiyú",
                "countryCode": "PY"
            },
            {
                "name": "Tacuatí",
                "countryCode": "PY"
            },
            {
                "name": "Unión",
                "countryCode": "PY"
            },
            {
                "name": "Yataity del Norte",
                "countryCode": "PY"
            },
            {
                "name": "Altos",
                "countryCode": "PY"
            },
            {
                "name": "Arroyos y Esteros",
                "countryCode": "PY"
            },
            {
                "name": "Atyrá",
                "countryCode": "PY"
            },
            {
                "name": "Caacupé",
                "countryCode": "PY"
            },
            {
                "name": "Caraguatay",
                "countryCode": "PY"
            },
            {
                "name": "Emboscada",
                "countryCode": "PY"
            },
            {
                "name": "Eusebio Ayala",
                "countryCode": "PY"
            },
            {
                "name": "Isla Pucú",
                "countryCode": "PY"
            },
            {
                "name": "Itacurubí de la Cordillera",
                "countryCode": "PY"
            },
            {
                "name": "Piribebuy",
                "countryCode": "PY"
            },
            {
                "name": "San Bernardino",
                "countryCode": "PY"
            },
            {
                "name": "Santa Elena",
                "countryCode": "PY"
            },
            {
                "name": "Tobatí",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Mauricio José Troche",
                "countryCode": "PY"
            },
            {
                "name": "Coronel Martínez",
                "countryCode": "PY"
            },
            {
                "name": "Independencia",
                "countryCode": "PY"
            },
            {
                "name": "Itapé",
                "countryCode": "PY"
            },
            {
                "name": "Iturbe",
                "countryCode": "PY"
            },
            {
                "name": "Mbocayaty",
                "countryCode": "PY"
            },
            {
                "name": "Natalicio Talavera",
                "countryCode": "PY"
            },
            {
                "name": "Villarrica",
                "countryCode": "PY"
            },
            {
                "name": "Caaguazú",
                "countryCode": "PY"
            },
            {
                "name": "Carayaó",
                "countryCode": "PY"
            },
            {
                "name": "Cecilio Baez",
                "countryCode": "PY"
            },
            {
                "name": "Colonia General Alfredo Stroessner",
                "countryCode": "PY"
            },
            {
                "name": "Coronel Oviedo",
                "countryCode": "PY"
            },
            {
                "name": "Doctor Juan Manuel Frutos",
                "countryCode": "PY"
            },
            {
                "name": "Mbutuý",
                "countryCode": "PY"
            },
            {
                "name": "Repatriación",
                "countryCode": "PY"
            },
            {
                "name": "San Joaquín",
                "countryCode": "PY"
            },
            {
                "name": "San José",
                "countryCode": "PY"
            },
            {
                "name": "Yhú",
                "countryCode": "PY"
            },
            {
                "name": "Abaí",
                "countryCode": "PY"
            },
            {
                "name": "Buena Vista",
                "countryCode": "PY"
            },
            {
                "name": "Caazapá",
                "countryCode": "PY"
            },
            {
                "name": "General Higinio Morínigo",
                "countryCode": "PY"
            },
            {
                "name": "San Juan Nepomuceno",
                "countryCode": "PY"
            },
            {
                "name": "Yegros",
                "countryCode": "PY"
            },
            {
                "name": "Yuty",
                "countryCode": "PY"
            },
            {
                "name": "Arquitecto Tomás Romero Pereira",
                "countryCode": "PY"
            },
            {
                "name": "Bella Vista",
                "countryCode": "PY"
            },
            {
                "name": "Capitán Miranda",
                "countryCode": "PY"
            },
            {
                "name": "Carmen del Paraná",
                "countryCode": "PY"
            },
            {
                "name": "Colonia Carlos Antonio López",
                "countryCode": "PY"
            },
            {
                "name": "Colonia San Lorenzo",
                "countryCode": "PY"
            },
            {
                "name": "Edelira",
                "countryCode": "PY"
            },
            {
                "name": "Encarnación",
                "countryCode": "PY"
            },
            {
                "name": "Fram",
                "countryCode": "PY"
            },
            {
                "name": "General Artigas",
                "countryCode": "PY"
            },
            {
                "name": "General Delgado",
                "countryCode": "PY"
            },
            {
                "name": "Hohenau",
                "countryCode": "PY"
            },
            {
                "name": "Jesús",
                "countryCode": "PY"
            },
            {
                "name": "Natalio",
                "countryCode": "PY"
            },
            {
                "name": "Obligado",
                "countryCode": "PY"
            },
            {
                "name": "Pirapó",
                "countryCode": "PY"
            },
            {
                "name": "Puerto Mayor Otaño",
                "countryCode": "PY"
            },
            {
                "name": "San Cosme y Damián",
                "countryCode": "PY"
            },
            {
                "name": "San Juan del Paraná",
                "countryCode": "PY"
            },
            {
                "name": "San Pedro del Paraná",
                "countryCode": "PY"
            },
            {
                "name": "Trinidad",
                "countryCode": "PY"
            },
            {
                "name": "Juan de Ayolas",
                "countryCode": "PY"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "PY"
            },
            {
                "name": "San Miguel",
                "countryCode": "PY"
            },
            {
                "name": "San Patricio",
                "countryCode": "PY"
            },
            {
                "name": "Santa María",
                "countryCode": "PY"
            },
            {
                "name": "Santa Rosa Misiones",
                "countryCode": "PY"
            },
            {
                "name": "Santiago",
                "countryCode": "PY"
            },
            {
                "name": "Villa Florida",
                "countryCode": "PY"
            },
            {
                "name": "Acahay",
                "countryCode": "PY"
            },
            {
                "name": "Caapucú",
                "countryCode": "PY"
            },
            {
                "name": "Carapeguá",
                "countryCode": "PY"
            },
            {
                "name": "La Colmena",
                "countryCode": "PY"
            },
            {
                "name": "Mbuyapey",
                "countryCode": "PY"
            },
            {
                "name": "Paraguarí",
                "countryCode": "PY"
            },
            {
                "name": "Pirayú",
                "countryCode": "PY"
            },
            {
                "name": "Quiindy",
                "countryCode": "PY"
            },
            {
                "name": "Sapucaí",
                "countryCode": "PY"
            },
            {
                "name": "Yaguarón",
                "countryCode": "PY"
            },
            {
                "name": "Ybycuí",
                "countryCode": "PY"
            }
        ]
    },
    {
        "name": "Peru",
        "phonecode": "51",
        "cities": [
            {
                "name": "Bagua Grande",
                "countryCode": "PE"
            },
            {
                "name": "Cajaruro",
                "countryCode": "PE"
            },
            {
                "name": "Chachapoyas",
                "countryCode": "PE"
            },
            {
                "name": "Condorcanqui",
                "countryCode": "PE"
            },
            {
                "name": "La Peca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Bagua",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Bongará",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chachapoyas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Luya",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Rodríguez de Mendoza",
                "countryCode": "PE"
            },
            {
                "name": "Utcubamba",
                "countryCode": "PE"
            },
            {
                "name": "Asuncion",
                "countryCode": "PE"
            },
            {
                "name": "Carhuaz",
                "countryCode": "PE"
            },
            {
                "name": "Carlos Fermin Fitzcarrald",
                "countryCode": "PE"
            },
            {
                "name": "Carás",
                "countryCode": "PE"
            },
            {
                "name": "Chimbote",
                "countryCode": "PE"
            },
            {
                "name": "Coishco",
                "countryCode": "PE"
            },
            {
                "name": "Huaraz",
                "countryCode": "PE"
            },
            {
                "name": "Huarmey",
                "countryCode": "PE"
            },
            {
                "name": "Pomabamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Aija",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Antonio Raymondi",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Bolognesi",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Carhuaz",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Casma",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Corongo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huaraz",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huari",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huarmey",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huaylas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Mariscal Luzuriaga",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pallasca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pomabamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Recuay",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Santa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Sihuas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Yungay",
                "countryCode": "PE"
            },
            {
                "name": "Puerto Santa",
                "countryCode": "PE"
            },
            {
                "name": "Yauya",
                "countryCode": "PE"
            },
            {
                "name": "Yungay",
                "countryCode": "PE"
            },
            {
                "name": "Abancay",
                "countryCode": "PE"
            },
            {
                "name": "Andahuaylas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Abancay",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Andahuaylas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Antabamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Aymaraes",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cotabambas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Grau",
                "countryCode": "PE"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "PE"
            },
            {
                "name": "Talavera",
                "countryCode": "PE"
            },
            {
                "name": "Acarí",
                "countryCode": "PE"
            },
            {
                "name": "Arequipa",
                "countryCode": "PE"
            },
            {
                "name": "Camaná",
                "countryCode": "PE"
            },
            {
                "name": "Ccolo",
                "countryCode": "PE"
            },
            {
                "name": "Chivay",
                "countryCode": "PE"
            },
            {
                "name": "Cocachacra",
                "countryCode": "PE"
            },
            {
                "name": "Cotahuasi",
                "countryCode": "PE"
            },
            {
                "name": "Huarancante",
                "countryCode": "PE"
            },
            {
                "name": "Huarichancara",
                "countryCode": "PE"
            },
            {
                "name": "Jatun Orcochiri",
                "countryCode": "PE"
            },
            {
                "name": "Jayune",
                "countryCode": "PE"
            },
            {
                "name": "Llongasora",
                "countryCode": "PE"
            },
            {
                "name": "Lluta",
                "countryCode": "PE"
            },
            {
                "name": "Mollendo",
                "countryCode": "PE"
            },
            {
                "name": "Orcopampa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Arequipa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Camaná",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Caravelí",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Castilla",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Caylloma",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Condesuyos",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Islay",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de La Unión",
                "countryCode": "PE"
            },
            {
                "name": "Pucara",
                "countryCode": "PE"
            },
            {
                "name": "Punta de Bombón",
                "countryCode": "PE"
            },
            {
                "name": "Vizcachane",
                "countryCode": "PE"
            },
            {
                "name": "Yura",
                "countryCode": "PE"
            },
            {
                "name": "Ayacucho",
                "countryCode": "PE"
            },
            {
                "name": "Ayna",
                "countryCode": "PE"
            },
            {
                "name": "Coracora",
                "countryCode": "PE"
            },
            {
                "name": "Huanta",
                "countryCode": "PE"
            },
            {
                "name": "Paucar Del Sara Sara",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cangallo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huamanga",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huanca Sancos",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huanta",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de La Mar",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Lucanas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Parinacochas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Sucre",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Vilcas Huamán",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Víctor Fajardo",
                "countryCode": "PE"
            },
            {
                "name": "Puquio",
                "countryCode": "PE"
            },
            {
                "name": "San Miguel",
                "countryCode": "PE"
            },
            {
                "name": "Tambo",
                "countryCode": "PE"
            },
            {
                "name": "Bambamarca",
                "countryCode": "PE"
            },
            {
                "name": "Bellavista",
                "countryCode": "PE"
            },
            {
                "name": "Cajabamba",
                "countryCode": "PE"
            },
            {
                "name": "Cajamarca",
                "countryCode": "PE"
            },
            {
                "name": "Celendín",
                "countryCode": "PE"
            },
            {
                "name": "Chota",
                "countryCode": "PE"
            },
            {
                "name": "Jaén",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cajabamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cajamarca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Celendín",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chota",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Contumazá",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cutervo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Hualgayoc",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Jaén",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de San Ignacio",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de San Miguel",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Santa Cruz",
                "countryCode": "PE"
            },
            {
                "name": "San Ignacio",
                "countryCode": "PE"
            },
            {
                "name": "Callao",
                "countryCode": "PE"
            },
            {
                "name": "Anta",
                "countryCode": "PE"
            },
            {
                "name": "Cahuanuyo",
                "countryCode": "PE"
            },
            {
                "name": "Calca",
                "countryCode": "PE"
            },
            {
                "name": "Callanca",
                "countryCode": "PE"
            },
            {
                "name": "Ccaquiracunca",
                "countryCode": "PE"
            },
            {
                "name": "Ccuntuma",
                "countryCode": "PE"
            },
            {
                "name": "Checacupe",
                "countryCode": "PE"
            },
            {
                "name": "Checca",
                "countryCode": "PE"
            },
            {
                "name": "Chignayhua",
                "countryCode": "PE"
            },
            {
                "name": "Chinchero",
                "countryCode": "PE"
            },
            {
                "name": "Combapata",
                "countryCode": "PE"
            },
            {
                "name": "Conchopata",
                "countryCode": "PE"
            },
            {
                "name": "Cullcuyre",
                "countryCode": "PE"
            },
            {
                "name": "Cusco",
                "countryCode": "PE"
            },
            {
                "name": "Huayna Alcalde",
                "countryCode": "PE"
            },
            {
                "name": "Jayobamba",
                "countryCode": "PE"
            },
            {
                "name": "Langui",
                "countryCode": "PE"
            },
            {
                "name": "Layo",
                "countryCode": "PE"
            },
            {
                "name": "Lima Pampa",
                "countryCode": "PE"
            },
            {
                "name": "Maranganí",
                "countryCode": "PE"
            },
            {
                "name": "Maras",
                "countryCode": "PE"
            },
            {
                "name": "Mosoc Cancha",
                "countryCode": "PE"
            },
            {
                "name": "Mosoc Llacta",
                "countryCode": "PE"
            },
            {
                "name": "Ollantaytambo",
                "countryCode": "PE"
            },
            {
                "name": "Oropesa",
                "countryCode": "PE"
            },
            {
                "name": "Pampamarca",
                "countryCode": "PE"
            },
            {
                "name": "Pangoa",
                "countryCode": "PE"
            },
            {
                "name": "Paucartambo",
                "countryCode": "PE"
            },
            {
                "name": "Pisac",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Acomayo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Anta",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Calca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Canas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Canchis",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chumbivilcas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cusco",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Espinar",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de La Convención",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Paruro",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Paucartambo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Quispicanchis",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Urubamba",
                "countryCode": "PE"
            },
            {
                "name": "Qquea",
                "countryCode": "PE"
            },
            {
                "name": "Queromarca",
                "countryCode": "PE"
            },
            {
                "name": "San Pablo",
                "countryCode": "PE"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PE"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PE"
            },
            {
                "name": "Sicuani",
                "countryCode": "PE"
            },
            {
                "name": "Tinta",
                "countryCode": "PE"
            },
            {
                "name": "Tungasuca",
                "countryCode": "PE"
            },
            {
                "name": "Urcos",
                "countryCode": "PE"
            },
            {
                "name": "Urubamba",
                "countryCode": "PE"
            },
            {
                "name": "Yanaoca",
                "countryCode": "PE"
            },
            {
                "name": "Ambo",
                "countryCode": "PE"
            },
            {
                "name": "Huacaybamba",
                "countryCode": "PE"
            },
            {
                "name": "Huánuco",
                "countryCode": "PE"
            },
            {
                "name": "La Unión",
                "countryCode": "PE"
            },
            {
                "name": "Lauricocha",
                "countryCode": "PE"
            },
            {
                "name": "Llata",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Ambo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Dos de Mayo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huamalíes",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huánuco",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Leoncio Prado",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Marañón",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pachitea",
                "countryCode": "PE"
            },
            {
                "name": "Puerto Inca",
                "countryCode": "PE"
            },
            {
                "name": "San Miguel de Cauri",
                "countryCode": "PE"
            },
            {
                "name": "Tingo María",
                "countryCode": "PE"
            },
            {
                "name": "Yanacancha",
                "countryCode": "PE"
            },
            {
                "name": "Yarowilca",
                "countryCode": "PE"
            },
            {
                "name": "Huancavelica",
                "countryCode": "PE"
            },
            {
                "name": "Huaytara",
                "countryCode": "PE"
            },
            {
                "name": "Pampas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Acobamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Angaraes",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Castrovirreyna",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Churcampa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huancavelica",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tayacaja",
                "countryCode": "PE"
            },
            {
                "name": "Chincha Alta",
                "countryCode": "PE"
            },
            {
                "name": "Ica",
                "countryCode": "PE"
            },
            {
                "name": "Los Aquijes",
                "countryCode": "PE"
            },
            {
                "name": "Minas de Marcona",
                "countryCode": "PE"
            },
            {
                "name": "Nazca",
                "countryCode": "PE"
            },
            {
                "name": "Palpa",
                "countryCode": "PE"
            },
            {
                "name": "Paracas",
                "countryCode": "PE"
            },
            {
                "name": "Pisco",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chincha",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Ica",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Nazca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Palpa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pisco",
                "countryCode": "PE"
            },
            {
                "name": "Río Grande",
                "countryCode": "PE"
            },
            {
                "name": "San Clemente",
                "countryCode": "PE"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "PE"
            },
            {
                "name": "Santiago",
                "countryCode": "PE"
            },
            {
                "name": "Subtanjalla",
                "countryCode": "PE"
            },
            {
                "name": "Villa Tupac Amaru",
                "countryCode": "PE"
            },
            {
                "name": "Acolla",
                "countryCode": "PE"
            },
            {
                "name": "Carhuamayo",
                "countryCode": "PE"
            },
            {
                "name": "Chanchamayo",
                "countryCode": "PE"
            },
            {
                "name": "Chupaca",
                "countryCode": "PE"
            },
            {
                "name": "Concepción",
                "countryCode": "PE"
            },
            {
                "name": "Huancayo",
                "countryCode": "PE"
            },
            {
                "name": "Huasahuasi",
                "countryCode": "PE"
            },
            {
                "name": "Huayucachi",
                "countryCode": "PE"
            },
            {
                "name": "Jauja",
                "countryCode": "PE"
            },
            {
                "name": "Junín",
                "countryCode": "PE"
            },
            {
                "name": "La Oroya",
                "countryCode": "PE"
            },
            {
                "name": "Mazamari",
                "countryCode": "PE"
            },
            {
                "name": "Morococha",
                "countryCode": "PE"
            },
            {
                "name": "Orcotuna",
                "countryCode": "PE"
            },
            {
                "name": "Perené",
                "countryCode": "PE"
            },
            {
                "name": "Pilcomay",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chupaca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Concepción",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huancayo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Jauja",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Junín",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Satipo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tarma",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Yauli",
                "countryCode": "PE"
            },
            {
                "name": "San Agustin",
                "countryCode": "PE"
            },
            {
                "name": "San Jerónimo",
                "countryCode": "PE"
            },
            {
                "name": "San Pedro de Cajas",
                "countryCode": "PE"
            },
            {
                "name": "San Ramón",
                "countryCode": "PE"
            },
            {
                "name": "Satipo",
                "countryCode": "PE"
            },
            {
                "name": "Sicaya",
                "countryCode": "PE"
            },
            {
                "name": "Tarma",
                "countryCode": "PE"
            },
            {
                "name": "Ascope",
                "countryCode": "PE"
            },
            {
                "name": "Cascas",
                "countryCode": "PE"
            },
            {
                "name": "Chepen",
                "countryCode": "PE"
            },
            {
                "name": "Chicama",
                "countryCode": "PE"
            },
            {
                "name": "Chocope",
                "countryCode": "PE"
            },
            {
                "name": "Gran Chimu",
                "countryCode": "PE"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PE"
            },
            {
                "name": "Huamachuco",
                "countryCode": "PE"
            },
            {
                "name": "Julcan",
                "countryCode": "PE"
            },
            {
                "name": "Laredo",
                "countryCode": "PE"
            },
            {
                "name": "Moche",
                "countryCode": "PE"
            },
            {
                "name": "Otuzco",
                "countryCode": "PE"
            },
            {
                "name": "Pacanga",
                "countryCode": "PE"
            },
            {
                "name": "Pacasmayo",
                "countryCode": "PE"
            },
            {
                "name": "Paiján",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Bolívar",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Otuzco",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pacasmayo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pataz",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Santiago de Chuco",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Trujillo",
                "countryCode": "PE"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "PE"
            },
            {
                "name": "Quiruvilca",
                "countryCode": "PE"
            },
            {
                "name": "Salaverry",
                "countryCode": "PE"
            },
            {
                "name": "San Pedro de Lloc",
                "countryCode": "PE"
            },
            {
                "name": "Sanchez Carrion",
                "countryCode": "PE"
            },
            {
                "name": "Santiago de Cao",
                "countryCode": "PE"
            },
            {
                "name": "Santiago de Chuco",
                "countryCode": "PE"
            },
            {
                "name": "Trujillo",
                "countryCode": "PE"
            },
            {
                "name": "Viru",
                "countryCode": "PE"
            },
            {
                "name": "Chiclayo",
                "countryCode": "PE"
            },
            {
                "name": "Chongoyape",
                "countryCode": "PE"
            },
            {
                "name": "Eten",
                "countryCode": "PE"
            },
            {
                "name": "Ferreñafe",
                "countryCode": "PE"
            },
            {
                "name": "Jayanca",
                "countryCode": "PE"
            },
            {
                "name": "Lambayeque",
                "countryCode": "PE"
            },
            {
                "name": "Mochumí",
                "countryCode": "PE"
            },
            {
                "name": "Monsefú",
                "countryCode": "PE"
            },
            {
                "name": "Motupe",
                "countryCode": "PE"
            },
            {
                "name": "Mórrope",
                "countryCode": "PE"
            },
            {
                "name": "Olmos",
                "countryCode": "PE"
            },
            {
                "name": "Oyotún",
                "countryCode": "PE"
            },
            {
                "name": "Picsi",
                "countryCode": "PE"
            },
            {
                "name": "Pimentel",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chiclayo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Ferreñafe",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Lambayeque",
                "countryCode": "PE"
            },
            {
                "name": "Pueblo Nuevo",
                "countryCode": "PE"
            },
            {
                "name": "Reque",
                "countryCode": "PE"
            },
            {
                "name": "San José",
                "countryCode": "PE"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "PE"
            },
            {
                "name": "Saña",
                "countryCode": "PE"
            },
            {
                "name": "Túcume",
                "countryCode": "PE"
            },
            {
                "name": "Asentamiento Humano Nicolas de Pierola",
                "countryCode": "PE"
            },
            {
                "name": "Barranca",
                "countryCode": "PE"
            },
            {
                "name": "Caleta de Carquín",
                "countryCode": "PE"
            },
            {
                "name": "Chancay",
                "countryCode": "PE"
            },
            {
                "name": "Chilca",
                "countryCode": "PE"
            },
            {
                "name": "Chosica",
                "countryCode": "PE"
            },
            {
                "name": "Huacho",
                "countryCode": "PE"
            },
            {
                "name": "Hualmay",
                "countryCode": "PE"
            },
            {
                "name": "Huaral",
                "countryCode": "PE"
            },
            {
                "name": "Huaura",
                "countryCode": "PE"
            },
            {
                "name": "Imperial",
                "countryCode": "PE"
            },
            {
                "name": "Independencia",
                "countryCode": "PE"
            },
            {
                "name": "Lima",
                "countryCode": "PE"
            },
            {
                "name": "Mala",
                "countryCode": "PE"
            },
            {
                "name": "Matucana",
                "countryCode": "PE"
            },
            {
                "name": "Nuevo Imperial",
                "countryCode": "PE"
            },
            {
                "name": "Oyon",
                "countryCode": "PE"
            },
            {
                "name": "Paramonga",
                "countryCode": "PE"
            },
            {
                "name": "Pativilca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cajatambo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Canta",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Cañete",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huaral",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huarochirí",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Yauyos",
                "countryCode": "PE"
            },
            {
                "name": "Puerto Supe",
                "countryCode": "PE"
            },
            {
                "name": "Quilmaná",
                "countryCode": "PE"
            },
            {
                "name": "Ricardo Palma",
                "countryCode": "PE"
            },
            {
                "name": "San Bartolo",
                "countryCode": "PE"
            },
            {
                "name": "San Isidro",
                "countryCode": "PE"
            },
            {
                "name": "San Luis",
                "countryCode": "PE"
            },
            {
                "name": "San Vicente de Cañete",
                "countryCode": "PE"
            },
            {
                "name": "Santa María",
                "countryCode": "PE"
            },
            {
                "name": "Santiago de Surco",
                "countryCode": "PE"
            },
            {
                "name": "Sayán",
                "countryCode": "PE"
            },
            {
                "name": "Supe",
                "countryCode": "PE"
            },
            {
                "name": "Urb. Santo Domingo",
                "countryCode": "PE"
            },
            {
                "name": "Végueta",
                "countryCode": "PE"
            },
            {
                "name": "Andoas",
                "countryCode": "PE"
            },
            {
                "name": "Borja",
                "countryCode": "PE"
            },
            {
                "name": "Caballococha",
                "countryCode": "PE"
            },
            {
                "name": "Cape Pantoja",
                "countryCode": "PE"
            },
            {
                "name": "Indiana",
                "countryCode": "PE"
            },
            {
                "name": "Iquitos",
                "countryCode": "PE"
            },
            {
                "name": "Jeberos",
                "countryCode": "PE"
            },
            {
                "name": "Jenaro Herrera",
                "countryCode": "PE"
            },
            {
                "name": "Lagunas",
                "countryCode": "PE"
            },
            {
                "name": "Nauta",
                "countryCode": "PE"
            },
            {
                "name": "Punchana",
                "countryCode": "PE"
            },
            {
                "name": "Requena",
                "countryCode": "PE"
            },
            {
                "name": "San Antonio del Estrecho",
                "countryCode": "PE"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PE"
            },
            {
                "name": "San Pablo",
                "countryCode": "PE"
            },
            {
                "name": "Tamshiyacu",
                "countryCode": "PE"
            },
            {
                "name": "Yurimaguas",
                "countryCode": "PE"
            },
            {
                "name": "Iberia",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Manú",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tahuamanú",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tambopata",
                "countryCode": "PE"
            },
            {
                "name": "Puerto Maldonado",
                "countryCode": "PE"
            },
            {
                "name": "Salvación",
                "countryCode": "PE"
            },
            {
                "name": "Tambopata",
                "countryCode": "PE"
            },
            {
                "name": "Ilo",
                "countryCode": "PE"
            },
            {
                "name": "Moquegua",
                "countryCode": "PE"
            },
            {
                "name": "Pacocha",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de General Sánchez Cerro",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Ilo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Mariscal Nieto",
                "countryCode": "PE"
            },
            {
                "name": "Torata",
                "countryCode": "PE"
            },
            {
                "name": "Cerro de Pasco",
                "countryCode": "PE"
            },
            {
                "name": "Chaupimarca",
                "countryCode": "PE"
            },
            {
                "name": "Oxapampa",
                "countryCode": "PE"
            },
            {
                "name": "Paucartambo",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Daniel Carrión",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Oxapampa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Pasco",
                "countryCode": "PE"
            },
            {
                "name": "Tinyahuarco",
                "countryCode": "PE"
            },
            {
                "name": "Villa Rica",
                "countryCode": "PE"
            },
            {
                "name": "Ayabaca",
                "countryCode": "PE"
            },
            {
                "name": "Bernal",
                "countryCode": "PE"
            },
            {
                "name": "Buenos Aires",
                "countryCode": "PE"
            },
            {
                "name": "Catacaos",
                "countryCode": "PE"
            },
            {
                "name": "Chulucanas",
                "countryCode": "PE"
            },
            {
                "name": "El Alto",
                "countryCode": "PE"
            },
            {
                "name": "Huancabamba",
                "countryCode": "PE"
            },
            {
                "name": "La Breita",
                "countryCode": "PE"
            },
            {
                "name": "La Huaca",
                "countryCode": "PE"
            },
            {
                "name": "La Unión",
                "countryCode": "PE"
            },
            {
                "name": "Las Lomas",
                "countryCode": "PE"
            },
            {
                "name": "Marcavelica",
                "countryCode": "PE"
            },
            {
                "name": "Morropon",
                "countryCode": "PE"
            },
            {
                "name": "Máncora",
                "countryCode": "PE"
            },
            {
                "name": "Paita",
                "countryCode": "PE"
            },
            {
                "name": "Piura",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Ayabaca",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huancabamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Paita",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Piura",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Sullana",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Talara",
                "countryCode": "PE"
            },
            {
                "name": "Querecotillo",
                "countryCode": "PE"
            },
            {
                "name": "Salinera Colán",
                "countryCode": "PE"
            },
            {
                "name": "Salitral",
                "countryCode": "PE"
            },
            {
                "name": "Sechura",
                "countryCode": "PE"
            },
            {
                "name": "Sullana",
                "countryCode": "PE"
            },
            {
                "name": "Talara",
                "countryCode": "PE"
            },
            {
                "name": "Tambo Grande",
                "countryCode": "PE"
            },
            {
                "name": "Vice",
                "countryCode": "PE"
            },
            {
                "name": "Atuncolla",
                "countryCode": "PE"
            },
            {
                "name": "Ayaviri",
                "countryCode": "PE"
            },
            {
                "name": "Azángaro",
                "countryCode": "PE"
            },
            {
                "name": "Desaguadero",
                "countryCode": "PE"
            },
            {
                "name": "El Collao",
                "countryCode": "PE"
            },
            {
                "name": "Hacienda Huancane",
                "countryCode": "PE"
            },
            {
                "name": "Ilave",
                "countryCode": "PE"
            },
            {
                "name": "Juli",
                "countryCode": "PE"
            },
            {
                "name": "Juliaca",
                "countryCode": "PE"
            },
            {
                "name": "La Rinconada",
                "countryCode": "PE"
            },
            {
                "name": "Lampa",
                "countryCode": "PE"
            },
            {
                "name": "Macusani",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Azángaro",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Carabaya",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Chucuito",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huancané",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Lampa",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Melgar",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Puno",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Sandia",
                "countryCode": "PE"
            },
            {
                "name": "Puno",
                "countryCode": "PE"
            },
            {
                "name": "San Antonio De Putina",
                "countryCode": "PE"
            },
            {
                "name": "San Roman",
                "countryCode": "PE"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "PE"
            },
            {
                "name": "Taquile",
                "countryCode": "PE"
            },
            {
                "name": "Yunguyo",
                "countryCode": "PE"
            },
            {
                "name": "Ñuñoa",
                "countryCode": "PE"
            },
            {
                "name": "Bellavista",
                "countryCode": "PE"
            },
            {
                "name": "Chazuta",
                "countryCode": "PE"
            },
            {
                "name": "El Dorado",
                "countryCode": "PE"
            },
            {
                "name": "Huicungo",
                "countryCode": "PE"
            },
            {
                "name": "Jepelacio",
                "countryCode": "PE"
            },
            {
                "name": "Juanjuí",
                "countryCode": "PE"
            },
            {
                "name": "Lamas",
                "countryCode": "PE"
            },
            {
                "name": "Moyobamba",
                "countryCode": "PE"
            },
            {
                "name": "Picota",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Huallaga",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Lamas",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Mariscal Cáceres",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Moyobamba",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Rioja",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de San Martín",
                "countryCode": "PE"
            },
            {
                "name": "Rioja",
                "countryCode": "PE"
            },
            {
                "name": "Saposoa",
                "countryCode": "PE"
            },
            {
                "name": "Sisa",
                "countryCode": "PE"
            },
            {
                "name": "Soritor",
                "countryCode": "PE"
            },
            {
                "name": "Tabalosos",
                "countryCode": "PE"
            },
            {
                "name": "Tarapoto",
                "countryCode": "PE"
            },
            {
                "name": "Tocache",
                "countryCode": "PE"
            },
            {
                "name": "Uchiza",
                "countryCode": "PE"
            },
            {
                "name": "Calana",
                "countryCode": "PE"
            },
            {
                "name": "Candarave",
                "countryCode": "PE"
            },
            {
                "name": "Chipispaya",
                "countryCode": "PE"
            },
            {
                "name": "Chuquitira",
                "countryCode": "PE"
            },
            {
                "name": "Ilabaya",
                "countryCode": "PE"
            },
            {
                "name": "Jorge Basadre",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tacna",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tarata",
                "countryCode": "PE"
            },
            {
                "name": "Pujocucho",
                "countryCode": "PE"
            },
            {
                "name": "Quilahuani",
                "countryCode": "PE"
            },
            {
                "name": "Tacna",
                "countryCode": "PE"
            },
            {
                "name": "Tarata",
                "countryCode": "PE"
            },
            {
                "name": "Totoral",
                "countryCode": "PE"
            },
            {
                "name": "Aguas Verdes",
                "countryCode": "PE"
            },
            {
                "name": "Papayal",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Contralmirante Villar",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Tumbes",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Zarumilla",
                "countryCode": "PE"
            },
            {
                "name": "Tumbes",
                "countryCode": "PE"
            },
            {
                "name": "Zarumilla",
                "countryCode": "PE"
            },
            {
                "name": "Zorritos",
                "countryCode": "PE"
            },
            {
                "name": "Atalaya",
                "countryCode": "PE"
            },
            {
                "name": "Campoverde",
                "countryCode": "PE"
            },
            {
                "name": "Padre Abad",
                "countryCode": "PE"
            },
            {
                "name": "Provincia de Coronel Portillo",
                "countryCode": "PE"
            },
            {
                "name": "Pucallpa",
                "countryCode": "PE"
            },
            {
                "name": "Purus",
                "countryCode": "PE"
            }
        ]
    },
    {
        "name": "Philippines",
        "phonecode": "63",
        "cities": [
            {
                "name": "Acao",
                "countryCode": "PH"
            },
            {
                "name": "Agno",
                "countryCode": "PH"
            },
            {
                "name": "Agoo",
                "countryCode": "PH"
            },
            {
                "name": "Aguilar",
                "countryCode": "PH"
            },
            {
                "name": "Alac",
                "countryCode": "PH"
            },
            {
                "name": "Alaminos",
                "countryCode": "PH"
            },
            {
                "name": "Alcala",
                "countryCode": "PH"
            },
            {
                "name": "Alilem",
                "countryCode": "PH"
            },
            {
                "name": "Allangigan Primero",
                "countryCode": "PH"
            },
            {
                "name": "Aloleng",
                "countryCode": "PH"
            },
            {
                "name": "Amagbagan",
                "countryCode": "PH"
            },
            {
                "name": "Anambongan",
                "countryCode": "PH"
            },
            {
                "name": "Anda",
                "countryCode": "PH"
            },
            {
                "name": "Angatel",
                "countryCode": "PH"
            },
            {
                "name": "Anulid",
                "countryCode": "PH"
            },
            {
                "name": "Aringay",
                "countryCode": "PH"
            },
            {
                "name": "Asingan",
                "countryCode": "PH"
            },
            {
                "name": "Baay",
                "countryCode": "PH"
            },
            {
                "name": "Bacag",
                "countryCode": "PH"
            },
            {
                "name": "Bacarra",
                "countryCode": "PH"
            },
            {
                "name": "Bacnar",
                "countryCode": "PH"
            },
            {
                "name": "Bacnotan",
                "countryCode": "PH"
            },
            {
                "name": "Bactad Proper",
                "countryCode": "PH"
            },
            {
                "name": "Bacundao Weste",
                "countryCode": "PH"
            },
            {
                "name": "Badoc",
                "countryCode": "PH"
            },
            {
                "name": "Bagulin",
                "countryCode": "PH"
            },
            {
                "name": "Bail",
                "countryCode": "PH"
            },
            {
                "name": "Balaoan",
                "countryCode": "PH"
            },
            {
                "name": "Balingasay",
                "countryCode": "PH"
            },
            {
                "name": "Balingueo",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balungao",
                "countryCode": "PH"
            },
            {
                "name": "Baluyot",
                "countryCode": "PH"
            },
            {
                "name": "Banayoyo",
                "countryCode": "PH"
            },
            {
                "name": "Bangan-Oda",
                "countryCode": "PH"
            },
            {
                "name": "Bangar",
                "countryCode": "PH"
            },
            {
                "name": "Bangui",
                "countryCode": "PH"
            },
            {
                "name": "Bani",
                "countryCode": "PH"
            },
            {
                "name": "Banog Sur",
                "countryCode": "PH"
            },
            {
                "name": "Bantay",
                "countryCode": "PH"
            },
            {
                "name": "Bantog",
                "countryCode": "PH"
            },
            {
                "name": "Barangobong",
                "countryCode": "PH"
            },
            {
                "name": "Baro",
                "countryCode": "PH"
            },
            {
                "name": "Barong",
                "countryCode": "PH"
            },
            {
                "name": "Basing",
                "countryCode": "PH"
            },
            {
                "name": "Basista",
                "countryCode": "PH"
            },
            {
                "name": "Batac City",
                "countryCode": "PH"
            },
            {
                "name": "Bataquil",
                "countryCode": "PH"
            },
            {
                "name": "Bauang",
                "countryCode": "PH"
            },
            {
                "name": "Bautista",
                "countryCode": "PH"
            },
            {
                "name": "Bayambang",
                "countryCode": "PH"
            },
            {
                "name": "Bayaoas",
                "countryCode": "PH"
            },
            {
                "name": "Bical Norte",
                "countryCode": "PH"
            },
            {
                "name": "Bil-Loca",
                "countryCode": "PH"
            },
            {
                "name": "Binabalian",
                "countryCode": "PH"
            },
            {
                "name": "Binalonan",
                "countryCode": "PH"
            },
            {
                "name": "Binday",
                "countryCode": "PH"
            },
            {
                "name": "Binmaley",
                "countryCode": "PH"
            },
            {
                "name": "Bobonan",
                "countryCode": "PH"
            },
            {
                "name": "Bogtong",
                "countryCode": "PH"
            },
            {
                "name": "Bolaoit",
                "countryCode": "PH"
            },
            {
                "name": "Bolingit",
                "countryCode": "PH"
            },
            {
                "name": "Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Botao",
                "countryCode": "PH"
            },
            {
                "name": "Boñgalon",
                "countryCode": "PH"
            },
            {
                "name": "Bued",
                "countryCode": "PH"
            },
            {
                "name": "Buenlag",
                "countryCode": "PH"
            },
            {
                "name": "Bugallon",
                "countryCode": "PH"
            },
            {
                "name": "Bulog",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Butubut Norte",
                "countryCode": "PH"
            },
            {
                "name": "Caabiangan",
                "countryCode": "PH"
            },
            {
                "name": "Caba",
                "countryCode": "PH"
            },
            {
                "name": "Cabalaoangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabalitian",
                "countryCode": "PH"
            },
            {
                "name": "Cabittaogan",
                "countryCode": "PH"
            },
            {
                "name": "Cabugao",
                "countryCode": "PH"
            },
            {
                "name": "Cabungan",
                "countryCode": "PH"
            },
            {
                "name": "Calasiao",
                "countryCode": "PH"
            },
            {
                "name": "Calepaan",
                "countryCode": "PH"
            },
            {
                "name": "Callaguip",
                "countryCode": "PH"
            },
            {
                "name": "Calomboyan",
                "countryCode": "PH"
            },
            {
                "name": "Calongbuyan",
                "countryCode": "PH"
            },
            {
                "name": "Calsib",
                "countryCode": "PH"
            },
            {
                "name": "Camaley",
                "countryCode": "PH"
            },
            {
                "name": "Canan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Canaoalan",
                "countryCode": "PH"
            },
            {
                "name": "Candon",
                "countryCode": "PH"
            },
            {
                "name": "Cantoria",
                "countryCode": "PH"
            },
            {
                "name": "Caoayan",
                "countryCode": "PH"
            },
            {
                "name": "Capandanan",
                "countryCode": "PH"
            },
            {
                "name": "Capulaan",
                "countryCode": "PH"
            },
            {
                "name": "Caramutan",
                "countryCode": "PH"
            },
            {
                "name": "Carasi",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Caronoan West",
                "countryCode": "PH"
            },
            {
                "name": "Carot",
                "countryCode": "PH"
            },
            {
                "name": "Carriedo",
                "countryCode": "PH"
            },
            {
                "name": "Carusucan",
                "countryCode": "PH"
            },
            {
                "name": "Catablan",
                "countryCode": "PH"
            },
            {
                "name": "Caterman",
                "countryCode": "PH"
            },
            {
                "name": "Cato",
                "countryCode": "PH"
            },
            {
                "name": "Catuday",
                "countryCode": "PH"
            },
            {
                "name": "Cayanga",
                "countryCode": "PH"
            },
            {
                "name": "Cayungnan",
                "countryCode": "PH"
            },
            {
                "name": "Cervantes",
                "countryCode": "PH"
            },
            {
                "name": "Comillas Norte",
                "countryCode": "PH"
            },
            {
                "name": "Corrooy",
                "countryCode": "PH"
            },
            {
                "name": "Currimao",
                "countryCode": "PH"
            },
            {
                "name": "Dagup",
                "countryCode": "PH"
            },
            {
                "name": "Dagupan",
                "countryCode": "PH"
            },
            {
                "name": "Damortis",
                "countryCode": "PH"
            },
            {
                "name": "Darapidap",
                "countryCode": "PH"
            },
            {
                "name": "Dasol",
                "countryCode": "PH"
            },
            {
                "name": "Davila",
                "countryCode": "PH"
            },
            {
                "name": "Diaz",
                "countryCode": "PH"
            },
            {
                "name": "Dilan",
                "countryCode": "PH"
            },
            {
                "name": "Dingras",
                "countryCode": "PH"
            },
            {
                "name": "Domalanoan",
                "countryCode": "PH"
            },
            {
                "name": "Domampot",
                "countryCode": "PH"
            },
            {
                "name": "Don Pedro",
                "countryCode": "PH"
            },
            {
                "name": "Dorongan Punta",
                "countryCode": "PH"
            },
            {
                "name": "Doyong",
                "countryCode": "PH"
            },
            {
                "name": "Dulig",
                "countryCode": "PH"
            },
            {
                "name": "Dumalneg",
                "countryCode": "PH"
            },
            {
                "name": "Dumpay",
                "countryCode": "PH"
            },
            {
                "name": "Eguia",
                "countryCode": "PH"
            },
            {
                "name": "Esmeralda",
                "countryCode": "PH"
            },
            {
                "name": "Espiritu",
                "countryCode": "PH"
            },
            {
                "name": "Fuerte",
                "countryCode": "PH"
            },
            {
                "name": "Galimuyod",
                "countryCode": "PH"
            },
            {
                "name": "Gayaman",
                "countryCode": "PH"
            },
            {
                "name": "Gregorio del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Guiling",
                "countryCode": "PH"
            },
            {
                "name": "Guiset East",
                "countryCode": "PH"
            },
            {
                "name": "Hacienda",
                "countryCode": "PH"
            },
            {
                "name": "Halog West",
                "countryCode": "PH"
            },
            {
                "name": "Ilioilio",
                "countryCode": "PH"
            },
            {
                "name": "Inabaan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Infanta",
                "countryCode": "PH"
            },
            {
                "name": "Isla",
                "countryCode": "PH"
            },
            {
                "name": "Labayug",
                "countryCode": "PH"
            },
            {
                "name": "Labney",
                "countryCode": "PH"
            },
            {
                "name": "Labrador",
                "countryCode": "PH"
            },
            {
                "name": "Lagasit",
                "countryCode": "PH"
            },
            {
                "name": "Laguit Centro",
                "countryCode": "PH"
            },
            {
                "name": "Laoag",
                "countryCode": "PH"
            },
            {
                "name": "Leones East",
                "countryCode": "PH"
            },
            {
                "name": "Lepa",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Lidlidda",
                "countryCode": "PH"
            },
            {
                "name": "Lingayen",
                "countryCode": "PH"
            },
            {
                "name": "Linmansangan",
                "countryCode": "PH"
            },
            {
                "name": "Lloren",
                "countryCode": "PH"
            },
            {
                "name": "Lobong",
                "countryCode": "PH"
            },
            {
                "name": "Longos",
                "countryCode": "PH"
            },
            {
                "name": "Loqueb Este",
                "countryCode": "PH"
            },
            {
                "name": "Lucap",
                "countryCode": "PH"
            },
            {
                "name": "Lucero",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lunec",
                "countryCode": "PH"
            },
            {
                "name": "Lungog",
                "countryCode": "PH"
            },
            {
                "name": "Lusong",
                "countryCode": "PH"
            },
            {
                "name": "Mabilao",
                "countryCode": "PH"
            },
            {
                "name": "Mabilbila Sur",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabusag",
                "countryCode": "PH"
            },
            {
                "name": "Macabuboni",
                "countryCode": "PH"
            },
            {
                "name": "Macalong",
                "countryCode": "PH"
            },
            {
                "name": "Macalva Norte",
                "countryCode": "PH"
            },
            {
                "name": "Macayug",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Magsingal",
                "countryCode": "PH"
            },
            {
                "name": "Magtaking",
                "countryCode": "PH"
            },
            {
                "name": "Malabago",
                "countryCode": "PH"
            },
            {
                "name": "Malanay",
                "countryCode": "PH"
            },
            {
                "name": "Malasiqui",
                "countryCode": "PH"
            },
            {
                "name": "Malawa",
                "countryCode": "PH"
            },
            {
                "name": "Malibong East",
                "countryCode": "PH"
            },
            {
                "name": "Manaoag",
                "countryCode": "PH"
            },
            {
                "name": "Mangaldan",
                "countryCode": "PH"
            },
            {
                "name": "Mangatarem",
                "countryCode": "PH"
            },
            {
                "name": "Mapandan",
                "countryCode": "PH"
            },
            {
                "name": "Mapolopolo",
                "countryCode": "PH"
            },
            {
                "name": "Marcos",
                "countryCode": "PH"
            },
            {
                "name": "Maticmatic",
                "countryCode": "PH"
            },
            {
                "name": "Minien East",
                "countryCode": "PH"
            },
            {
                "name": "Nagbacalan",
                "countryCode": "PH"
            },
            {
                "name": "Nagsaing",
                "countryCode": "PH"
            },
            {
                "name": "Naguelguel",
                "countryCode": "PH"
            },
            {
                "name": "Naguilayan",
                "countryCode": "PH"
            },
            {
                "name": "Naguilian",
                "countryCode": "PH"
            },
            {
                "name": "Nalsian Norte",
                "countryCode": "PH"
            },
            {
                "name": "Nama",
                "countryCode": "PH"
            },
            {
                "name": "Namboongan",
                "countryCode": "PH"
            },
            {
                "name": "Nancalobasaan",
                "countryCode": "PH"
            },
            {
                "name": "Narvacan",
                "countryCode": "PH"
            },
            {
                "name": "Natividad",
                "countryCode": "PH"
            },
            {
                "name": "Navatat",
                "countryCode": "PH"
            },
            {
                "name": "Nibaliw Central",
                "countryCode": "PH"
            },
            {
                "name": "Nilombot",
                "countryCode": "PH"
            },
            {
                "name": "Ninoy",
                "countryCode": "PH"
            },
            {
                "name": "Nueva Era",
                "countryCode": "PH"
            },
            {
                "name": "Oaqui",
                "countryCode": "PH"
            },
            {
                "name": "Olea",
                "countryCode": "PH"
            },
            {
                "name": "Padong",
                "countryCode": "PH"
            },
            {
                "name": "Pagsanahan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pagudpud",
                "countryCode": "PH"
            },
            {
                "name": "Paitan Este",
                "countryCode": "PH"
            },
            {
                "name": "Palacpalac",
                "countryCode": "PH"
            },
            {
                "name": "Paldit",
                "countryCode": "PH"
            },
            {
                "name": "Palguyod",
                "countryCode": "PH"
            },
            {
                "name": "Panganiban",
                "countryCode": "PH"
            },
            {
                "name": "Pangapisan",
                "countryCode": "PH"
            },
            {
                "name": "Pangascasan",
                "countryCode": "PH"
            },
            {
                "name": "Pangpang",
                "countryCode": "PH"
            },
            {
                "name": "Paoay",
                "countryCode": "PH"
            },
            {
                "name": "Paringao",
                "countryCode": "PH"
            },
            {
                "name": "Parioc Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Pasibi West",
                "countryCode": "PH"
            },
            {
                "name": "Pasuquin",
                "countryCode": "PH"
            },
            {
                "name": "Patayac",
                "countryCode": "PH"
            },
            {
                "name": "Patpata Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Payocpoc Sur",
                "countryCode": "PH"
            },
            {
                "name": "Piddig",
                "countryCode": "PH"
            },
            {
                "name": "Pindangan Centro",
                "countryCode": "PH"
            },
            {
                "name": "Pinili",
                "countryCode": "PH"
            },
            {
                "name": "Pogonsili",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Polong",
                "countryCode": "PH"
            },
            {
                "name": "Polong Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pozorrubio",
                "countryCode": "PH"
            },
            {
                "name": "Province of Ilocos Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Ilocos Sur",
                "countryCode": "PH"
            },
            {
                "name": "Province of La Union",
                "countryCode": "PH"
            },
            {
                "name": "Province of Pangasinan",
                "countryCode": "PH"
            },
            {
                "name": "Pudoc",
                "countryCode": "PH"
            },
            {
                "name": "Pudoc North",
                "countryCode": "PH"
            },
            {
                "name": "Puelay",
                "countryCode": "PH"
            },
            {
                "name": "Pugo",
                "countryCode": "PH"
            },
            {
                "name": "Puro Pinget",
                "countryCode": "PH"
            },
            {
                "name": "Quiling",
                "countryCode": "PH"
            },
            {
                "name": "Quinarayan",
                "countryCode": "PH"
            },
            {
                "name": "Quintong",
                "countryCode": "PH"
            },
            {
                "name": "Quirino",
                "countryCode": "PH"
            },
            {
                "name": "Ranao",
                "countryCode": "PH"
            },
            {
                "name": "Real",
                "countryCode": "PH"
            },
            {
                "name": "Rimus",
                "countryCode": "PH"
            },
            {
                "name": "Rissing",
                "countryCode": "PH"
            },
            {
                "name": "Rosales",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Sablig",
                "countryCode": "PH"
            },
            {
                "name": "Sagud-Bahley",
                "countryCode": "PH"
            },
            {
                "name": "Sagunto",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Samon",
                "countryCode": "PH"
            },
            {
                "name": "San Emilio",
                "countryCode": "PH"
            },
            {
                "name": "San Esteban",
                "countryCode": "PH"
            },
            {
                "name": "San Eugenio",
                "countryCode": "PH"
            },
            {
                "name": "San Fabian",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Gabriel",
                "countryCode": "PH"
            },
            {
                "name": "San Gabriel First",
                "countryCode": "PH"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "PH"
            },
            {
                "name": "San Jacinto",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro Apartado",
                "countryCode": "PH"
            },
            {
                "name": "San Quintin",
                "countryCode": "PH"
            },
            {
                "name": "San Sebastian",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sanlibo",
                "countryCode": "PH"
            },
            {
                "name": "Santa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Santol",
                "countryCode": "PH"
            },
            {
                "name": "Sarrat",
                "countryCode": "PH"
            },
            {
                "name": "Sigay",
                "countryCode": "PH"
            },
            {
                "name": "Sinait",
                "countryCode": "PH"
            },
            {
                "name": "Sison",
                "countryCode": "PH"
            },
            {
                "name": "Solsona",
                "countryCode": "PH"
            },
            {
                "name": "Sonquil",
                "countryCode": "PH"
            },
            {
                "name": "Sual",
                "countryCode": "PH"
            },
            {
                "name": "Subusub",
                "countryCode": "PH"
            },
            {
                "name": "Sudipen",
                "countryCode": "PH"
            },
            {
                "name": "Sugpon",
                "countryCode": "PH"
            },
            {
                "name": "Sumabnit",
                "countryCode": "PH"
            },
            {
                "name": "Suso",
                "countryCode": "PH"
            },
            {
                "name": "Suyo",
                "countryCode": "PH"
            },
            {
                "name": "Tablac",
                "countryCode": "PH"
            },
            {
                "name": "Tabug",
                "countryCode": "PH"
            },
            {
                "name": "Tagudin",
                "countryCode": "PH"
            },
            {
                "name": "Talospatang",
                "countryCode": "PH"
            },
            {
                "name": "Taloy",
                "countryCode": "PH"
            },
            {
                "name": "Tamayo",
                "countryCode": "PH"
            },
            {
                "name": "Tamorong",
                "countryCode": "PH"
            },
            {
                "name": "Tandoc",
                "countryCode": "PH"
            },
            {
                "name": "Tanolong",
                "countryCode": "PH"
            },
            {
                "name": "Tayug",
                "countryCode": "PH"
            },
            {
                "name": "Tebag East",
                "countryCode": "PH"
            },
            {
                "name": "Telbang",
                "countryCode": "PH"
            },
            {
                "name": "Tiep",
                "countryCode": "PH"
            },
            {
                "name": "Toboy",
                "countryCode": "PH"
            },
            {
                "name": "Tobuan",
                "countryCode": "PH"
            },
            {
                "name": "Tococ East",
                "countryCode": "PH"
            },
            {
                "name": "Tocok",
                "countryCode": "PH"
            },
            {
                "name": "Tombod",
                "countryCode": "PH"
            },
            {
                "name": "Tondol",
                "countryCode": "PH"
            },
            {
                "name": "Toritori",
                "countryCode": "PH"
            },
            {
                "name": "Tubao",
                "countryCode": "PH"
            },
            {
                "name": "Umanday Centro",
                "countryCode": "PH"
            },
            {
                "name": "Umingan",
                "countryCode": "PH"
            },
            {
                "name": "Unzad",
                "countryCode": "PH"
            },
            {
                "name": "Urbiztondo",
                "countryCode": "PH"
            },
            {
                "name": "Urdaneta",
                "countryCode": "PH"
            },
            {
                "name": "Uyong",
                "countryCode": "PH"
            },
            {
                "name": "Vigan",
                "countryCode": "PH"
            },
            {
                "name": "Villanueva",
                "countryCode": "PH"
            },
            {
                "name": "Villasis",
                "countryCode": "PH"
            },
            {
                "name": "Vintar",
                "countryCode": "PH"
            },
            {
                "name": "Zaragoza",
                "countryCode": "PH"
            },
            {
                "name": "Abulug",
                "countryCode": "PH"
            },
            {
                "name": "Abut",
                "countryCode": "PH"
            },
            {
                "name": "Accusilian",
                "countryCode": "PH"
            },
            {
                "name": "Afusing Centro",
                "countryCode": "PH"
            },
            {
                "name": "Aggugaddah",
                "countryCode": "PH"
            },
            {
                "name": "Alabug",
                "countryCode": "PH"
            },
            {
                "name": "Alannay",
                "countryCode": "PH"
            },
            {
                "name": "Alcala",
                "countryCode": "PH"
            },
            {
                "name": "Alibago",
                "countryCode": "PH"
            },
            {
                "name": "Alicia",
                "countryCode": "PH"
            },
            {
                "name": "Allacapan",
                "countryCode": "PH"
            },
            {
                "name": "Almaguer North",
                "countryCode": "PH"
            },
            {
                "name": "Amulung",
                "countryCode": "PH"
            },
            {
                "name": "Angadanan",
                "countryCode": "PH"
            },
            {
                "name": "Antagan Segunda",
                "countryCode": "PH"
            },
            {
                "name": "Aparri",
                "countryCode": "PH"
            },
            {
                "name": "Aritao",
                "countryCode": "PH"
            },
            {
                "name": "Atulayan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Awallan",
                "countryCode": "PH"
            },
            {
                "name": "Bacnor East",
                "countryCode": "PH"
            },
            {
                "name": "Baggabag B",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Tanza",
                "countryCode": "PH"
            },
            {
                "name": "Bagu",
                "countryCode": "PH"
            },
            {
                "name": "Bagumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Ballesteros",
                "countryCode": "PH"
            },
            {
                "name": "Bambang",
                "countryCode": "PH"
            },
            {
                "name": "Bangad",
                "countryCode": "PH"
            },
            {
                "name": "Banganan",
                "countryCode": "PH"
            },
            {
                "name": "Banquero",
                "countryCode": "PH"
            },
            {
                "name": "Barucboc Norte",
                "countryCode": "PH"
            },
            {
                "name": "Basco",
                "countryCode": "PH"
            },
            {
                "name": "Batal",
                "countryCode": "PH"
            },
            {
                "name": "Battung",
                "countryCode": "PH"
            },
            {
                "name": "Bauan",
                "countryCode": "PH"
            },
            {
                "name": "Bayombong",
                "countryCode": "PH"
            },
            {
                "name": "Belance",
                "countryCode": "PH"
            },
            {
                "name": "Benito Soliven",
                "countryCode": "PH"
            },
            {
                "name": "Binalan",
                "countryCode": "PH"
            },
            {
                "name": "Binguang",
                "countryCode": "PH"
            },
            {
                "name": "Bintawan",
                "countryCode": "PH"
            },
            {
                "name": "Bitag Grande",
                "countryCode": "PH"
            },
            {
                "name": "Bone South",
                "countryCode": "PH"
            },
            {
                "name": "Buguey",
                "countryCode": "PH"
            },
            {
                "name": "Buliwao",
                "countryCode": "PH"
            },
            {
                "name": "Bulu",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Busilak",
                "countryCode": "PH"
            },
            {
                "name": "Cabagan",
                "countryCode": "PH"
            },
            {
                "name": "Cabannungan Second",
                "countryCode": "PH"
            },
            {
                "name": "Cabaritan East",
                "countryCode": "PH"
            },
            {
                "name": "Cabarroguis",
                "countryCode": "PH"
            },
            {
                "name": "Cabatuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabiraoan",
                "countryCode": "PH"
            },
            {
                "name": "Cabulay",
                "countryCode": "PH"
            },
            {
                "name": "Calamagui East",
                "countryCode": "PH"
            },
            {
                "name": "Calantac",
                "countryCode": "PH"
            },
            {
                "name": "Calaoagan",
                "countryCode": "PH"
            },
            {
                "name": "Calinaoan Malasin",
                "countryCode": "PH"
            },
            {
                "name": "Calog Norte",
                "countryCode": "PH"
            },
            {
                "name": "Camalaniugan",
                "countryCode": "PH"
            },
            {
                "name": "Capissayan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Carig",
                "countryCode": "PH"
            },
            {
                "name": "Casambalangan",
                "countryCode": "PH"
            },
            {
                "name": "Catayauan",
                "countryCode": "PH"
            },
            {
                "name": "Claveria",
                "countryCode": "PH"
            },
            {
                "name": "Cordon",
                "countryCode": "PH"
            },
            {
                "name": "Cullalabo del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Dagupan",
                "countryCode": "PH"
            },
            {
                "name": "Dalaoig",
                "countryCode": "PH"
            },
            {
                "name": "Daragutan",
                "countryCode": "PH"
            },
            {
                "name": "Dassun",
                "countryCode": "PH"
            },
            {
                "name": "Diadi",
                "countryCode": "PH"
            },
            {
                "name": "Diamantina",
                "countryCode": "PH"
            },
            {
                "name": "Dibuluan",
                "countryCode": "PH"
            },
            {
                "name": "Dicabisagan",
                "countryCode": "PH"
            },
            {
                "name": "Dicamay",
                "countryCode": "PH"
            },
            {
                "name": "Diffun",
                "countryCode": "PH"
            },
            {
                "name": "Divisoria",
                "countryCode": "PH"
            },
            {
                "name": "Dodan",
                "countryCode": "PH"
            },
            {
                "name": "Dumabato",
                "countryCode": "PH"
            },
            {
                "name": "Echague",
                "countryCode": "PH"
            },
            {
                "name": "Eden",
                "countryCode": "PH"
            },
            {
                "name": "Enrile",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza East",
                "countryCode": "PH"
            },
            {
                "name": "Estefania",
                "countryCode": "PH"
            },
            {
                "name": "Furao",
                "countryCode": "PH"
            },
            {
                "name": "Gadu",
                "countryCode": "PH"
            },
            {
                "name": "Gammad",
                "countryCode": "PH"
            },
            {
                "name": "Gamu",
                "countryCode": "PH"
            },
            {
                "name": "Ganapi",
                "countryCode": "PH"
            },
            {
                "name": "Gappal",
                "countryCode": "PH"
            },
            {
                "name": "Gattaran",
                "countryCode": "PH"
            },
            {
                "name": "Gonzaga",
                "countryCode": "PH"
            },
            {
                "name": "Guiddam",
                "countryCode": "PH"
            },
            {
                "name": "Ibung",
                "countryCode": "PH"
            },
            {
                "name": "Iguig",
                "countryCode": "PH"
            },
            {
                "name": "Ilagan",
                "countryCode": "PH"
            },
            {
                "name": "Ineangan",
                "countryCode": "PH"
            },
            {
                "name": "Iraga",
                "countryCode": "PH"
            },
            {
                "name": "Itbayat",
                "countryCode": "PH"
            },
            {
                "name": "Ivana",
                "countryCode": "PH"
            },
            {
                "name": "Jones",
                "countryCode": "PH"
            },
            {
                "name": "Kasibu",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lallayug",
                "countryCode": "PH"
            },
            {
                "name": "Lanna",
                "countryCode": "PH"
            },
            {
                "name": "Lapi",
                "countryCode": "PH"
            },
            {
                "name": "Larion Alto",
                "countryCode": "PH"
            },
            {
                "name": "Lasam",
                "countryCode": "PH"
            },
            {
                "name": "Lucban",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Mabasa",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabuttal East",
                "countryCode": "PH"
            },
            {
                "name": "Maddarulug Norte",
                "countryCode": "PH"
            },
            {
                "name": "Maddela",
                "countryCode": "PH"
            },
            {
                "name": "Magalalag",
                "countryCode": "PH"
            },
            {
                "name": "Magapit",
                "countryCode": "PH"
            },
            {
                "name": "Magapit, Aguiguican",
                "countryCode": "PH"
            },
            {
                "name": "Magdalena",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Maguilling",
                "countryCode": "PH"
            },
            {
                "name": "Mahatao",
                "countryCode": "PH"
            },
            {
                "name": "Malasin",
                "countryCode": "PH"
            },
            {
                "name": "Mallig",
                "countryCode": "PH"
            },
            {
                "name": "Maluno Sur",
                "countryCode": "PH"
            },
            {
                "name": "Manaring",
                "countryCode": "PH"
            },
            {
                "name": "Manga",
                "countryCode": "PH"
            },
            {
                "name": "Masaya Sur",
                "countryCode": "PH"
            },
            {
                "name": "Masipi West",
                "countryCode": "PH"
            },
            {
                "name": "Maxingal",
                "countryCode": "PH"
            },
            {
                "name": "Minallo",
                "countryCode": "PH"
            },
            {
                "name": "Minanga Norte",
                "countryCode": "PH"
            },
            {
                "name": "Minante Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Minuri",
                "countryCode": "PH"
            },
            {
                "name": "Mozzozzin Sur",
                "countryCode": "PH"
            },
            {
                "name": "Mungo",
                "countryCode": "PH"
            },
            {
                "name": "Muñoz East",
                "countryCode": "PH"
            },
            {
                "name": "Nabannagan West",
                "countryCode": "PH"
            },
            {
                "name": "Nagrumbuan",
                "countryCode": "PH"
            },
            {
                "name": "Nagtipunan",
                "countryCode": "PH"
            },
            {
                "name": "Naguilian",
                "countryCode": "PH"
            },
            {
                "name": "Namuac",
                "countryCode": "PH"
            },
            {
                "name": "Nattapian",
                "countryCode": "PH"
            },
            {
                "name": "Paddaya",
                "countryCode": "PH"
            },
            {
                "name": "Palagao Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pamplona",
                "countryCode": "PH"
            },
            {
                "name": "Pangal Sur",
                "countryCode": "PH"
            },
            {
                "name": "Pata",
                "countryCode": "PH"
            },
            {
                "name": "Pattao",
                "countryCode": "PH"
            },
            {
                "name": "Peñablanca",
                "countryCode": "PH"
            },
            {
                "name": "Piat",
                "countryCode": "PH"
            },
            {
                "name": "Pilig",
                "countryCode": "PH"
            },
            {
                "name": "Pinoma",
                "countryCode": "PH"
            },
            {
                "name": "Province of Batanes",
                "countryCode": "PH"
            },
            {
                "name": "Province of Cagayan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Isabela",
                "countryCode": "PH"
            },
            {
                "name": "Province of Nueva Vizcaya",
                "countryCode": "PH"
            },
            {
                "name": "Province of Quirino",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quibal",
                "countryCode": "PH"
            },
            {
                "name": "Quirino",
                "countryCode": "PH"
            },
            {
                "name": "Ragan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Ramon",
                "countryCode": "PH"
            },
            {
                "name": "Ramos West",
                "countryCode": "PH"
            },
            {
                "name": "Reina Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Sabtang",
                "countryCode": "PH"
            },
            {
                "name": "Saguday",
                "countryCode": "PH"
            },
            {
                "name": "Salinas",
                "countryCode": "PH"
            },
            {
                "name": "Salinungan Proper",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Bernardo",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Guillermo",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sandiat Centro",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Praxedes",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Siempre Viva",
                "countryCode": "PH"
            },
            {
                "name": "Sillawit",
                "countryCode": "PH"
            },
            {
                "name": "Simanu Sur",
                "countryCode": "PH"
            },
            {
                "name": "Simimbaan",
                "countryCode": "PH"
            },
            {
                "name": "Sinamar",
                "countryCode": "PH"
            },
            {
                "name": "Sindon",
                "countryCode": "PH"
            },
            {
                "name": "Solana",
                "countryCode": "PH"
            },
            {
                "name": "Solano",
                "countryCode": "PH"
            },
            {
                "name": "Soyung",
                "countryCode": "PH"
            },
            {
                "name": "Taguing",
                "countryCode": "PH"
            },
            {
                "name": "Tapel",
                "countryCode": "PH"
            },
            {
                "name": "Tuao",
                "countryCode": "PH"
            },
            {
                "name": "Tuguegarao",
                "countryCode": "PH"
            },
            {
                "name": "Tumauini",
                "countryCode": "PH"
            },
            {
                "name": "Tupang",
                "countryCode": "PH"
            },
            {
                "name": "Uddiawan",
                "countryCode": "PH"
            },
            {
                "name": "Ugac Sur",
                "countryCode": "PH"
            },
            {
                "name": "Ugad",
                "countryCode": "PH"
            },
            {
                "name": "Upi",
                "countryCode": "PH"
            },
            {
                "name": "Uyugan",
                "countryCode": "PH"
            },
            {
                "name": "Yeban Norte",
                "countryCode": "PH"
            },
            {
                "name": "Abucay",
                "countryCode": "PH"
            },
            {
                "name": "Acli",
                "countryCode": "PH"
            },
            {
                "name": "Agbannawag",
                "countryCode": "PH"
            },
            {
                "name": "Akle",
                "countryCode": "PH"
            },
            {
                "name": "Aliaga",
                "countryCode": "PH"
            },
            {
                "name": "Almendras",
                "countryCode": "PH"
            },
            {
                "name": "Alua",
                "countryCode": "PH"
            },
            {
                "name": "Amacalan",
                "countryCode": "PH"
            },
            {
                "name": "Amucao",
                "countryCode": "PH"
            },
            {
                "name": "Amuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Anao",
                "countryCode": "PH"
            },
            {
                "name": "Angat",
                "countryCode": "PH"
            },
            {
                "name": "Angeles City",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Apalit",
                "countryCode": "PH"
            },
            {
                "name": "Arayat",
                "countryCode": "PH"
            },
            {
                "name": "Arenas",
                "countryCode": "PH"
            },
            {
                "name": "Arminia",
                "countryCode": "PH"
            },
            {
                "name": "Babo-Pangulo",
                "countryCode": "PH"
            },
            {
                "name": "Bacabac",
                "countryCode": "PH"
            },
            {
                "name": "Bacolor",
                "countryCode": "PH"
            },
            {
                "name": "Bacsay",
                "countryCode": "PH"
            },
            {
                "name": "Bagac",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Barrio",
                "countryCode": "PH"
            },
            {
                "name": "Bagong-Sikat",
                "countryCode": "PH"
            },
            {
                "name": "Bahay Pare",
                "countryCode": "PH"
            },
            {
                "name": "Bakulong",
                "countryCode": "PH"
            },
            {
                "name": "Balagtas",
                "countryCode": "PH"
            },
            {
                "name": "Balanga",
                "countryCode": "PH"
            },
            {
                "name": "Balaoang",
                "countryCode": "PH"
            },
            {
                "name": "Balas",
                "countryCode": "PH"
            },
            {
                "name": "Balasing",
                "countryCode": "PH"
            },
            {
                "name": "Balayang",
                "countryCode": "PH"
            },
            {
                "name": "Baler",
                "countryCode": "PH"
            },
            {
                "name": "Balibago",
                "countryCode": "PH"
            },
            {
                "name": "Balingcanaway",
                "countryCode": "PH"
            },
            {
                "name": "Balite",
                "countryCode": "PH"
            },
            {
                "name": "Baliuag",
                "countryCode": "PH"
            },
            {
                "name": "Baloc",
                "countryCode": "PH"
            },
            {
                "name": "Baloy",
                "countryCode": "PH"
            },
            {
                "name": "Balsic",
                "countryCode": "PH"
            },
            {
                "name": "Balucuc",
                "countryCode": "PH"
            },
            {
                "name": "Balut",
                "countryCode": "PH"
            },
            {
                "name": "Balutu",
                "countryCode": "PH"
            },
            {
                "name": "Bamban",
                "countryCode": "PH"
            },
            {
                "name": "Banawang",
                "countryCode": "PH"
            },
            {
                "name": "Bangad",
                "countryCode": "PH"
            },
            {
                "name": "Bani",
                "countryCode": "PH"
            },
            {
                "name": "Baquero Norte",
                "countryCode": "PH"
            },
            {
                "name": "Batasan Bata",
                "countryCode": "PH"
            },
            {
                "name": "Batitang",
                "countryCode": "PH"
            },
            {
                "name": "Bayanan",
                "countryCode": "PH"
            },
            {
                "name": "Beddeng",
                "countryCode": "PH"
            },
            {
                "name": "Biay",
                "countryCode": "PH"
            },
            {
                "name": "Bibiclat",
                "countryCode": "PH"
            },
            {
                "name": "Bicos",
                "countryCode": "PH"
            },
            {
                "name": "Biga",
                "countryCode": "PH"
            },
            {
                "name": "Bilad",
                "countryCode": "PH"
            },
            {
                "name": "Bituñgol",
                "countryCode": "PH"
            },
            {
                "name": "Bobon Second",
                "countryCode": "PH"
            },
            {
                "name": "Bocaue",
                "countryCode": "PH"
            },
            {
                "name": "Bodega",
                "countryCode": "PH"
            },
            {
                "name": "Bolitoc",
                "countryCode": "PH"
            },
            {
                "name": "Bongabon",
                "countryCode": "PH"
            },
            {
                "name": "Botolan",
                "countryCode": "PH"
            },
            {
                "name": "Buenlag",
                "countryCode": "PH"
            },
            {
                "name": "Buensuseso",
                "countryCode": "PH"
            },
            {
                "name": "Bulaon",
                "countryCode": "PH"
            },
            {
                "name": "Bularit",
                "countryCode": "PH"
            },
            {
                "name": "Bulawin",
                "countryCode": "PH"
            },
            {
                "name": "Bulihan",
                "countryCode": "PH"
            },
            {
                "name": "Buliran",
                "countryCode": "PH"
            },
            {
                "name": "Buliran Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Bulualto",
                "countryCode": "PH"
            },
            {
                "name": "Bundoc",
                "countryCode": "PH"
            },
            {
                "name": "Bunol",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Bustos",
                "countryCode": "PH"
            },
            {
                "name": "Cabanatuan City",
                "countryCode": "PH"
            },
            {
                "name": "Cabangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabayaoasan",
                "countryCode": "PH"
            },
            {
                "name": "Cabcaben",
                "countryCode": "PH"
            },
            {
                "name": "Cabiao",
                "countryCode": "PH"
            },
            {
                "name": "Cabog",
                "countryCode": "PH"
            },
            {
                "name": "Cafe",
                "countryCode": "PH"
            },
            {
                "name": "Calaba",
                "countryCode": "PH"
            },
            {
                "name": "Calancuasan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Calangain",
                "countryCode": "PH"
            },
            {
                "name": "Calantas",
                "countryCode": "PH"
            },
            {
                "name": "Calayaan",
                "countryCode": "PH"
            },
            {
                "name": "Calibungan",
                "countryCode": "PH"
            },
            {
                "name": "Calibutbut",
                "countryCode": "PH"
            },
            {
                "name": "Calingcuan",
                "countryCode": "PH"
            },
            {
                "name": "Calumpang",
                "countryCode": "PH"
            },
            {
                "name": "Calumpit",
                "countryCode": "PH"
            },
            {
                "name": "Cama Juan",
                "countryCode": "PH"
            },
            {
                "name": "Camachile",
                "countryCode": "PH"
            },
            {
                "name": "Camias",
                "countryCode": "PH"
            },
            {
                "name": "Camiling",
                "countryCode": "PH"
            },
            {
                "name": "Candaba",
                "countryCode": "PH"
            },
            {
                "name": "Candating",
                "countryCode": "PH"
            },
            {
                "name": "Capas",
                "countryCode": "PH"
            },
            {
                "name": "Cardona",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carranglan",
                "countryCode": "PH"
            },
            {
                "name": "Castillejos",
                "countryCode": "PH"
            },
            {
                "name": "Cauayan",
                "countryCode": "PH"
            },
            {
                "name": "Cavite",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan Bugtong",
                "countryCode": "PH"
            },
            {
                "name": "Comillas",
                "countryCode": "PH"
            },
            {
                "name": "Communal",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Conversion",
                "countryCode": "PH"
            },
            {
                "name": "Culianin",
                "countryCode": "PH"
            },
            {
                "name": "Culubasa",
                "countryCode": "PH"
            },
            {
                "name": "Cut-cut Primero",
                "countryCode": "PH"
            },
            {
                "name": "Cuyapo",
                "countryCode": "PH"
            },
            {
                "name": "Dampol",
                "countryCode": "PH"
            },
            {
                "name": "Del Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Digdig",
                "countryCode": "PH"
            },
            {
                "name": "Diliman Primero",
                "countryCode": "PH"
            },
            {
                "name": "Dinalupihan",
                "countryCode": "PH"
            },
            {
                "name": "Dingalan",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Doña Remedios Trinidad",
                "countryCode": "PH"
            },
            {
                "name": "Dumarais",
                "countryCode": "PH"
            },
            {
                "name": "Entablado",
                "countryCode": "PH"
            },
            {
                "name": "Estacion",
                "countryCode": "PH"
            },
            {
                "name": "Estipona",
                "countryCode": "PH"
            },
            {
                "name": "Estrella",
                "countryCode": "PH"
            },
            {
                "name": "Floridablanca",
                "countryCode": "PH"
            },
            {
                "name": "Gabaldon",
                "countryCode": "PH"
            },
            {
                "name": "Gapan",
                "countryCode": "PH"
            },
            {
                "name": "General Luna",
                "countryCode": "PH"
            },
            {
                "name": "General Mamerto Natividad",
                "countryCode": "PH"
            },
            {
                "name": "General Tinio",
                "countryCode": "PH"
            },
            {
                "name": "Gerona",
                "countryCode": "PH"
            },
            {
                "name": "Guagua",
                "countryCode": "PH"
            },
            {
                "name": "Gueset",
                "countryCode": "PH"
            },
            {
                "name": "Guiguinto",
                "countryCode": "PH"
            },
            {
                "name": "Guimba",
                "countryCode": "PH"
            },
            {
                "name": "Guisguis",
                "countryCode": "PH"
            },
            {
                "name": "Gutad",
                "countryCode": "PH"
            },
            {
                "name": "Guyong",
                "countryCode": "PH"
            },
            {
                "name": "Hagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Hermosa",
                "countryCode": "PH"
            },
            {
                "name": "Iba",
                "countryCode": "PH"
            },
            {
                "name": "Jaen",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lambakin",
                "countryCode": "PH"
            },
            {
                "name": "Lanat",
                "countryCode": "PH"
            },
            {
                "name": "Laug",
                "countryCode": "PH"
            },
            {
                "name": "Laur",
                "countryCode": "PH"
            },
            {
                "name": "Lawang Kupang",
                "countryCode": "PH"
            },
            {
                "name": "Lennec",
                "countryCode": "PH"
            },
            {
                "name": "Licab",
                "countryCode": "PH"
            },
            {
                "name": "Liciada",
                "countryCode": "PH"
            },
            {
                "name": "Ligaya",
                "countryCode": "PH"
            },
            {
                "name": "Limay",
                "countryCode": "PH"
            },
            {
                "name": "Liozon",
                "countryCode": "PH"
            },
            {
                "name": "Lipay",
                "countryCode": "PH"
            },
            {
                "name": "Llanera",
                "countryCode": "PH"
            },
            {
                "name": "Loma de Gato",
                "countryCode": "PH"
            },
            {
                "name": "Lomboy",
                "countryCode": "PH"
            },
            {
                "name": "Lourdes",
                "countryCode": "PH"
            },
            {
                "name": "Lubao",
                "countryCode": "PH"
            },
            {
                "name": "Lucapon",
                "countryCode": "PH"
            },
            {
                "name": "Lupao",
                "countryCode": "PH"
            },
            {
                "name": "Maasim",
                "countryCode": "PH"
            },
            {
                "name": "Mababanaba",
                "countryCode": "PH"
            },
            {
                "name": "Mabalacat City",
                "countryCode": "PH"
            },
            {
                "name": "Mabayo",
                "countryCode": "PH"
            },
            {
                "name": "Mabilang",
                "countryCode": "PH"
            },
            {
                "name": "Mabilog",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Macabebe",
                "countryCode": "PH"
            },
            {
                "name": "Macapsing",
                "countryCode": "PH"
            },
            {
                "name": "Macarse",
                "countryCode": "PH"
            },
            {
                "name": "Macatbong",
                "countryCode": "PH"
            },
            {
                "name": "Magalang",
                "countryCode": "PH"
            },
            {
                "name": "Magliman",
                "countryCode": "PH"
            },
            {
                "name": "Magtangol",
                "countryCode": "PH"
            },
            {
                "name": "Maguinao",
                "countryCode": "PH"
            },
            {
                "name": "Malabon",
                "countryCode": "PH"
            },
            {
                "name": "Malacampa",
                "countryCode": "PH"
            },
            {
                "name": "Maligaya",
                "countryCode": "PH"
            },
            {
                "name": "Malino",
                "countryCode": "PH"
            },
            {
                "name": "Malolos",
                "countryCode": "PH"
            },
            {
                "name": "Maloma",
                "countryCode": "PH"
            },
            {
                "name": "Maluid",
                "countryCode": "PH"
            },
            {
                "name": "Malusac",
                "countryCode": "PH"
            },
            {
                "name": "Mambog",
                "countryCode": "PH"
            },
            {
                "name": "Mamonit",
                "countryCode": "PH"
            },
            {
                "name": "Manacsac",
                "countryCode": "PH"
            },
            {
                "name": "Manatal",
                "countryCode": "PH"
            },
            {
                "name": "Mandili",
                "countryCode": "PH"
            },
            {
                "name": "Mangga",
                "countryCode": "PH"
            },
            {
                "name": "Manibaug Pasig",
                "countryCode": "PH"
            },
            {
                "name": "Manogpi",
                "countryCode": "PH"
            },
            {
                "name": "Mapalacsiao",
                "countryCode": "PH"
            },
            {
                "name": "Mapalad",
                "countryCode": "PH"
            },
            {
                "name": "Mapaniqui",
                "countryCode": "PH"
            },
            {
                "name": "Maquiapo",
                "countryCode": "PH"
            },
            {
                "name": "Marawa",
                "countryCode": "PH"
            },
            {
                "name": "Maria Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Marilao",
                "countryCode": "PH"
            },
            {
                "name": "Mariveles",
                "countryCode": "PH"
            },
            {
                "name": "Masalipit",
                "countryCode": "PH"
            },
            {
                "name": "Masantol",
                "countryCode": "PH"
            },
            {
                "name": "Masinloc",
                "countryCode": "PH"
            },
            {
                "name": "Matayumtayum",
                "countryCode": "PH"
            },
            {
                "name": "Maturanoc",
                "countryCode": "PH"
            },
            {
                "name": "Mayantoc",
                "countryCode": "PH"
            },
            {
                "name": "Mexico",
                "countryCode": "PH"
            },
            {
                "name": "Meycauayan",
                "countryCode": "PH"
            },
            {
                "name": "Minalin",
                "countryCode": "PH"
            },
            {
                "name": "Moncada",
                "countryCode": "PH"
            },
            {
                "name": "Moriones",
                "countryCode": "PH"
            },
            {
                "name": "Morong",
                "countryCode": "PH"
            },
            {
                "name": "Motrico",
                "countryCode": "PH"
            },
            {
                "name": "Murcia",
                "countryCode": "PH"
            },
            {
                "name": "Muñoz",
                "countryCode": "PH"
            },
            {
                "name": "Nagpandayan",
                "countryCode": "PH"
            },
            {
                "name": "Nambalan",
                "countryCode": "PH"
            },
            {
                "name": "Nampicuan",
                "countryCode": "PH"
            },
            {
                "name": "Nancamarinan",
                "countryCode": "PH"
            },
            {
                "name": "Nieves",
                "countryCode": "PH"
            },
            {
                "name": "Niugan",
                "countryCode": "PH"
            },
            {
                "name": "Norzagaray",
                "countryCode": "PH"
            },
            {
                "name": "Obando",
                "countryCode": "PH"
            },
            {
                "name": "Olongapo",
                "countryCode": "PH"
            },
            {
                "name": "Orani",
                "countryCode": "PH"
            },
            {
                "name": "Orion",
                "countryCode": "PH"
            },
            {
                "name": "Paco Roman",
                "countryCode": "PH"
            },
            {
                "name": "Padapada",
                "countryCode": "PH"
            },
            {
                "name": "Paitan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Palauig",
                "countryCode": "PH"
            },
            {
                "name": "Palayan City",
                "countryCode": "PH"
            },
            {
                "name": "Palusapis",
                "countryCode": "PH"
            },
            {
                "name": "Pamatawan",
                "countryCode": "PH"
            },
            {
                "name": "Panabingan",
                "countryCode": "PH"
            },
            {
                "name": "Panan",
                "countryCode": "PH"
            },
            {
                "name": "Pance",
                "countryCode": "PH"
            },
            {
                "name": "Pandacaqui",
                "countryCode": "PH"
            },
            {
                "name": "Pandi",
                "countryCode": "PH"
            },
            {
                "name": "Pando",
                "countryCode": "PH"
            },
            {
                "name": "Paniqui",
                "countryCode": "PH"
            },
            {
                "name": "Panlinlang",
                "countryCode": "PH"
            },
            {
                "name": "Pantabangan",
                "countryCode": "PH"
            },
            {
                "name": "Pantubig",
                "countryCode": "PH"
            },
            {
                "name": "Paombong",
                "countryCode": "PH"
            },
            {
                "name": "Papaya",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Parista",
                "countryCode": "PH"
            },
            {
                "name": "Pau",
                "countryCode": "PH"
            },
            {
                "name": "Peñaranda",
                "countryCode": "PH"
            },
            {
                "name": "Pias",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pinambaran",
                "countryCode": "PH"
            },
            {
                "name": "Pio",
                "countryCode": "PH"
            },
            {
                "name": "Piñahan",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Poblacion, San Felipe",
                "countryCode": "PH"
            },
            {
                "name": "Porac",
                "countryCode": "PH"
            },
            {
                "name": "Porais",
                "countryCode": "PH"
            },
            {
                "name": "Prado Siongco",
                "countryCode": "PH"
            },
            {
                "name": "Province of Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Province of Bataan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Bulacan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Nueva Ecija",
                "countryCode": "PH"
            },
            {
                "name": "Province of Pampanga",
                "countryCode": "PH"
            },
            {
                "name": "Province of Tarlac",
                "countryCode": "PH"
            },
            {
                "name": "Province of Zambales",
                "countryCode": "PH"
            },
            {
                "name": "Pulilan",
                "countryCode": "PH"
            },
            {
                "name": "Pulo",
                "countryCode": "PH"
            },
            {
                "name": "Pulong Gubat",
                "countryCode": "PH"
            },
            {
                "name": "Pulong Sampalok",
                "countryCode": "PH"
            },
            {
                "name": "Pulung Santol",
                "countryCode": "PH"
            },
            {
                "name": "Pulungmasle",
                "countryCode": "PH"
            },
            {
                "name": "Puncan",
                "countryCode": "PH"
            },
            {
                "name": "Pura",
                "countryCode": "PH"
            },
            {
                "name": "Purac",
                "countryCode": "PH"
            },
            {
                "name": "Putlod",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Rajal Norte",
                "countryCode": "PH"
            },
            {
                "name": "Ramos",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Sagana",
                "countryCode": "PH"
            },
            {
                "name": "Salapungan",
                "countryCode": "PH"
            },
            {
                "name": "Salaza",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion I",
                "countryCode": "PH"
            },
            {
                "name": "Samal",
                "countryCode": "PH"
            },
            {
                "name": "Sampaloc",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Alejandro",
                "countryCode": "PH"
            },
            {
                "name": "San Andres",
                "countryCode": "PH"
            },
            {
                "name": "San Anton",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Basilio",
                "countryCode": "PH"
            },
            {
                "name": "San Benito",
                "countryCode": "PH"
            },
            {
                "name": "San Carlos",
                "countryCode": "PH"
            },
            {
                "name": "San Casimiro",
                "countryCode": "PH"
            },
            {
                "name": "San Clemente",
                "countryCode": "PH"
            },
            {
                "name": "San Cristobal",
                "countryCode": "PH"
            },
            {
                "name": "San Fabian",
                "countryCode": "PH"
            },
            {
                "name": "San Felipe",
                "countryCode": "PH"
            },
            {
                "name": "San Felipe Old",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jacinto",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Jose del Monte",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Juan de Mata",
                "countryCode": "PH"
            },
            {
                "name": "San Leonardo",
                "countryCode": "PH"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Marcelino",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Narciso",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pascual",
                "countryCode": "PH"
            },
            {
                "name": "San Patricio",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Ricardo",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "San Roque Dau First",
                "countryCode": "PH"
            },
            {
                "name": "San Simon",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "San Vincente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ignacia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ines West",
                "countryCode": "PH"
            },
            {
                "name": "Santa Juliana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Monica",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresa First",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Cristo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Santol",
                "countryCode": "PH"
            },
            {
                "name": "Sapang",
                "countryCode": "PH"
            },
            {
                "name": "Sapang Buho",
                "countryCode": "PH"
            },
            {
                "name": "Sapol",
                "countryCode": "PH"
            },
            {
                "name": "Saysain",
                "countryCode": "PH"
            },
            {
                "name": "Sexmoan",
                "countryCode": "PH"
            },
            {
                "name": "Sibul",
                "countryCode": "PH"
            },
            {
                "name": "Siclong",
                "countryCode": "PH"
            },
            {
                "name": "Sinait",
                "countryCode": "PH"
            },
            {
                "name": "Sinilian First",
                "countryCode": "PH"
            },
            {
                "name": "Soledad",
                "countryCode": "PH"
            },
            {
                "name": "Subic",
                "countryCode": "PH"
            },
            {
                "name": "Suklayin",
                "countryCode": "PH"
            },
            {
                "name": "Sula",
                "countryCode": "PH"
            },
            {
                "name": "Sulucan",
                "countryCode": "PH"
            },
            {
                "name": "Tabacao",
                "countryCode": "PH"
            },
            {
                "name": "Tabon",
                "countryCode": "PH"
            },
            {
                "name": "Tabuating",
                "countryCode": "PH"
            },
            {
                "name": "Tal I Mun Doc",
                "countryCode": "PH"
            },
            {
                "name": "Talaga",
                "countryCode": "PH"
            },
            {
                "name": "Talang",
                "countryCode": "PH"
            },
            {
                "name": "Talavera",
                "countryCode": "PH"
            },
            {
                "name": "Taltal",
                "countryCode": "PH"
            },
            {
                "name": "Talugtug",
                "countryCode": "PH"
            },
            {
                "name": "Tariji",
                "countryCode": "PH"
            },
            {
                "name": "Tarlac City",
                "countryCode": "PH"
            },
            {
                "name": "Tartaro",
                "countryCode": "PH"
            },
            {
                "name": "Tayabo",
                "countryCode": "PH"
            },
            {
                "name": "Telabastagan",
                "countryCode": "PH"
            },
            {
                "name": "Tikiw",
                "countryCode": "PH"
            },
            {
                "name": "Tinang",
                "countryCode": "PH"
            },
            {
                "name": "Tondod",
                "countryCode": "PH"
            },
            {
                "name": "Uacon",
                "countryCode": "PH"
            },
            {
                "name": "Umiray",
                "countryCode": "PH"
            },
            {
                "name": "Upig",
                "countryCode": "PH"
            },
            {
                "name": "Vargas",
                "countryCode": "PH"
            },
            {
                "name": "Villa Aglipay",
                "countryCode": "PH"
            },
            {
                "name": "Villa Isla",
                "countryCode": "PH"
            },
            {
                "name": "Vizal San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "Vizal Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Abuyog",
                "countryCode": "PH"
            },
            {
                "name": "Alangalang",
                "countryCode": "PH"
            },
            {
                "name": "Albuera",
                "countryCode": "PH"
            },
            {
                "name": "Allen",
                "countryCode": "PH"
            },
            {
                "name": "Almagro",
                "countryCode": "PH"
            },
            {
                "name": "Almeria",
                "countryCode": "PH"
            },
            {
                "name": "Alugan",
                "countryCode": "PH"
            },
            {
                "name": "Anahawan",
                "countryCode": "PH"
            },
            {
                "name": "Anito",
                "countryCode": "PH"
            },
            {
                "name": "Arteche",
                "countryCode": "PH"
            },
            {
                "name": "Babatngon",
                "countryCode": "PH"
            },
            {
                "name": "Balagtas",
                "countryCode": "PH"
            },
            {
                "name": "Balagui",
                "countryCode": "PH"
            },
            {
                "name": "Balangiga",
                "countryCode": "PH"
            },
            {
                "name": "Balangkayan",
                "countryCode": "PH"
            },
            {
                "name": "Balinsacayao",
                "countryCode": "PH"
            },
            {
                "name": "Balite",
                "countryCode": "PH"
            },
            {
                "name": "Balocawehay",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balud",
                "countryCode": "PH"
            },
            {
                "name": "Bantayan",
                "countryCode": "PH"
            },
            {
                "name": "Bantiqui",
                "countryCode": "PH"
            },
            {
                "name": "Baras",
                "countryCode": "PH"
            },
            {
                "name": "Barugo",
                "countryCode": "PH"
            },
            {
                "name": "Basey",
                "countryCode": "PH"
            },
            {
                "name": "Basud",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Baybay",
                "countryCode": "PH"
            },
            {
                "name": "Bilwang",
                "countryCode": "PH"
            },
            {
                "name": "Biri",
                "countryCode": "PH"
            },
            {
                "name": "Bislig",
                "countryCode": "PH"
            },
            {
                "name": "Bitanjuan",
                "countryCode": "PH"
            },
            {
                "name": "Bobon",
                "countryCode": "PH"
            },
            {
                "name": "Bontoc",
                "countryCode": "PH"
            },
            {
                "name": "Borongan",
                "countryCode": "PH"
            },
            {
                "name": "Bugho",
                "countryCode": "PH"
            },
            {
                "name": "Bugko",
                "countryCode": "PH"
            },
            {
                "name": "Bunga",
                "countryCode": "PH"
            },
            {
                "name": "Burauen",
                "countryCode": "PH"
            },
            {
                "name": "Butazon",
                "countryCode": "PH"
            },
            {
                "name": "Cabacuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Cabay",
                "countryCode": "PH"
            },
            {
                "name": "Cabodiongan",
                "countryCode": "PH"
            },
            {
                "name": "Cabucgayan",
                "countryCode": "PH"
            },
            {
                "name": "Cagamotan",
                "countryCode": "PH"
            },
            {
                "name": "Caibiran",
                "countryCode": "PH"
            },
            {
                "name": "Calape",
                "countryCode": "PH"
            },
            {
                "name": "Calbayog",
                "countryCode": "PH"
            },
            {
                "name": "Calbiga",
                "countryCode": "PH"
            },
            {
                "name": "Calubian",
                "countryCode": "PH"
            },
            {
                "name": "Can-avid",
                "countryCode": "PH"
            },
            {
                "name": "Canhandugan",
                "countryCode": "PH"
            },
            {
                "name": "Capoocan",
                "countryCode": "PH"
            },
            {
                "name": "Capul",
                "countryCode": "PH"
            },
            {
                "name": "Caraycaray",
                "countryCode": "PH"
            },
            {
                "name": "Caridad",
                "countryCode": "PH"
            },
            {
                "name": "Carigara",
                "countryCode": "PH"
            },
            {
                "name": "Catarman",
                "countryCode": "PH"
            },
            {
                "name": "Catbalogan",
                "countryCode": "PH"
            },
            {
                "name": "Catmon",
                "countryCode": "PH"
            },
            {
                "name": "Catubig",
                "countryCode": "PH"
            },
            {
                "name": "Cavite",
                "countryCode": "PH"
            },
            {
                "name": "Cervantes",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Consuegra",
                "countryCode": "PH"
            },
            {
                "name": "Culaba",
                "countryCode": "PH"
            },
            {
                "name": "Culasian",
                "countryCode": "PH"
            },
            {
                "name": "Dagami",
                "countryCode": "PH"
            },
            {
                "name": "Dao",
                "countryCode": "PH"
            },
            {
                "name": "Dapdap",
                "countryCode": "PH"
            },
            {
                "name": "Daram",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Doos",
                "countryCode": "PH"
            },
            {
                "name": "Dulag",
                "countryCode": "PH"
            },
            {
                "name": "Erenas",
                "countryCode": "PH"
            },
            {
                "name": "Gabas",
                "countryCode": "PH"
            },
            {
                "name": "Gamay",
                "countryCode": "PH"
            },
            {
                "name": "Gandara",
                "countryCode": "PH"
            },
            {
                "name": "General MacArthur",
                "countryCode": "PH"
            },
            {
                "name": "Ginabuyan",
                "countryCode": "PH"
            },
            {
                "name": "Giporlos",
                "countryCode": "PH"
            },
            {
                "name": "Gubang",
                "countryCode": "PH"
            },
            {
                "name": "Guindapunan",
                "countryCode": "PH"
            },
            {
                "name": "Guirang",
                "countryCode": "PH"
            },
            {
                "name": "Guiuan",
                "countryCode": "PH"
            },
            {
                "name": "Hernani",
                "countryCode": "PH"
            },
            {
                "name": "Hilongos",
                "countryCode": "PH"
            },
            {
                "name": "Hinabangan",
                "countryCode": "PH"
            },
            {
                "name": "Hindang",
                "countryCode": "PH"
            },
            {
                "name": "Hingatungan",
                "countryCode": "PH"
            },
            {
                "name": "Hinunangan",
                "countryCode": "PH"
            },
            {
                "name": "Hinundayan",
                "countryCode": "PH"
            },
            {
                "name": "Hipadpad",
                "countryCode": "PH"
            },
            {
                "name": "Hipasngo",
                "countryCode": "PH"
            },
            {
                "name": "Ibarra",
                "countryCode": "PH"
            },
            {
                "name": "Inangatan",
                "countryCode": "PH"
            },
            {
                "name": "Inopacan",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Isabel",
                "countryCode": "PH"
            },
            {
                "name": "Jaro",
                "countryCode": "PH"
            },
            {
                "name": "Javier",
                "countryCode": "PH"
            },
            {
                "name": "Jiabong",
                "countryCode": "PH"
            },
            {
                "name": "Jipapad",
                "countryCode": "PH"
            },
            {
                "name": "Jubasan",
                "countryCode": "PH"
            },
            {
                "name": "Julita",
                "countryCode": "PH"
            },
            {
                "name": "Kabuynan",
                "countryCode": "PH"
            },
            {
                "name": "Kampokpok",
                "countryCode": "PH"
            },
            {
                "name": "Kananga",
                "countryCode": "PH"
            },
            {
                "name": "Kawayan",
                "countryCode": "PH"
            },
            {
                "name": "Kilim",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lalauigan",
                "countryCode": "PH"
            },
            {
                "name": "Lamak",
                "countryCode": "PH"
            },
            {
                "name": "Lao",
                "countryCode": "PH"
            },
            {
                "name": "Laoang",
                "countryCode": "PH"
            },
            {
                "name": "Lapinig",
                "countryCode": "PH"
            },
            {
                "name": "Las Navas",
                "countryCode": "PH"
            },
            {
                "name": "Lavezares",
                "countryCode": "PH"
            },
            {
                "name": "Leyte",
                "countryCode": "PH"
            },
            {
                "name": "Libagon",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Liberty",
                "countryCode": "PH"
            },
            {
                "name": "Liloan",
                "countryCode": "PH"
            },
            {
                "name": "Lim-oo",
                "countryCode": "PH"
            },
            {
                "name": "Limasawa",
                "countryCode": "PH"
            },
            {
                "name": "Limon",
                "countryCode": "PH"
            },
            {
                "name": "Llorente",
                "countryCode": "PH"
            },
            {
                "name": "Looc",
                "countryCode": "PH"
            },
            {
                "name": "Lope de Vega",
                "countryCode": "PH"
            },
            {
                "name": "Lubi",
                "countryCode": "PH"
            },
            {
                "name": "Maasin",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "MacArthur",
                "countryCode": "PH"
            },
            {
                "name": "Macrohon",
                "countryCode": "PH"
            },
            {
                "name": "Mahagnao",
                "countryCode": "PH"
            },
            {
                "name": "Mahaplag",
                "countryCode": "PH"
            },
            {
                "name": "Makiwalo",
                "countryCode": "PH"
            },
            {
                "name": "Malaga",
                "countryCode": "PH"
            },
            {
                "name": "Malajog",
                "countryCode": "PH"
            },
            {
                "name": "Mantang",
                "countryCode": "PH"
            },
            {
                "name": "Mapanas",
                "countryCode": "PH"
            },
            {
                "name": "Marabut",
                "countryCode": "PH"
            },
            {
                "name": "Margen",
                "countryCode": "PH"
            },
            {
                "name": "Maripipi",
                "countryCode": "PH"
            },
            {
                "name": "Masarayao",
                "countryCode": "PH"
            },
            {
                "name": "Maslog",
                "countryCode": "PH"
            },
            {
                "name": "Matag-ob",
                "countryCode": "PH"
            },
            {
                "name": "Matalom",
                "countryCode": "PH"
            },
            {
                "name": "Matlang",
                "countryCode": "PH"
            },
            {
                "name": "Matuguinao",
                "countryCode": "PH"
            },
            {
                "name": "Maydolong",
                "countryCode": "PH"
            },
            {
                "name": "Mayorga",
                "countryCode": "PH"
            },
            {
                "name": "Maypangdan",
                "countryCode": "PH"
            },
            {
                "name": "Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Merida",
                "countryCode": "PH"
            },
            {
                "name": "Mondragon",
                "countryCode": "PH"
            },
            {
                "name": "Motiong",
                "countryCode": "PH"
            },
            {
                "name": "Naghalin",
                "countryCode": "PH"
            },
            {
                "name": "Napuro",
                "countryCode": "PH"
            },
            {
                "name": "Naval",
                "countryCode": "PH"
            },
            {
                "name": "Nena",
                "countryCode": "PH"
            },
            {
                "name": "Nenita",
                "countryCode": "PH"
            },
            {
                "name": "Oras",
                "countryCode": "PH"
            },
            {
                "name": "Ormoc",
                "countryCode": "PH"
            },
            {
                "name": "Padre Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Pagsanghan",
                "countryCode": "PH"
            },
            {
                "name": "Palanit",
                "countryCode": "PH"
            },
            {
                "name": "Palapag",
                "countryCode": "PH"
            },
            {
                "name": "Palaroo",
                "countryCode": "PH"
            },
            {
                "name": "Palhi",
                "countryCode": "PH"
            },
            {
                "name": "Palo",
                "countryCode": "PH"
            },
            {
                "name": "Palompon",
                "countryCode": "PH"
            },
            {
                "name": "Pambujan",
                "countryCode": "PH"
            },
            {
                "name": "Panalanoy",
                "countryCode": "PH"
            },
            {
                "name": "Pangdan",
                "countryCode": "PH"
            },
            {
                "name": "Pangpang",
                "countryCode": "PH"
            },
            {
                "name": "Pasay",
                "countryCode": "PH"
            },
            {
                "name": "Pastrana",
                "countryCode": "PH"
            },
            {
                "name": "Patong",
                "countryCode": "PH"
            },
            {
                "name": "Pawing",
                "countryCode": "PH"
            },
            {
                "name": "Pinabacdao",
                "countryCode": "PH"
            },
            {
                "name": "Pinamopoan",
                "countryCode": "PH"
            },
            {
                "name": "Pinangomhan",
                "countryCode": "PH"
            },
            {
                "name": "Pintuyan",
                "countryCode": "PH"
            },
            {
                "name": "Piña",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Polahongon",
                "countryCode": "PH"
            },
            {
                "name": "Polañge",
                "countryCode": "PH"
            },
            {
                "name": "Puerto Bello",
                "countryCode": "PH"
            },
            {
                "name": "Quinapondan",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Saint Bernard",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Eduardo",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Joaquin",
                "countryCode": "PH"
            },
            {
                "name": "San Jorge",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Jose de Buan",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Julian",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Policarpio",
                "countryCode": "PH"
            },
            {
                "name": "San Policarpo",
                "countryCode": "PH"
            },
            {
                "name": "San Ricardo",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "San Sebastian",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Margarita",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Siguinon",
                "countryCode": "PH"
            },
            {
                "name": "Silago",
                "countryCode": "PH"
            },
            {
                "name": "Silanga",
                "countryCode": "PH"
            },
            {
                "name": "Silvino Lobos",
                "countryCode": "PH"
            },
            {
                "name": "Sogod",
                "countryCode": "PH"
            },
            {
                "name": "Sulangan",
                "countryCode": "PH"
            },
            {
                "name": "Sulat",
                "countryCode": "PH"
            },
            {
                "name": "Tabango",
                "countryCode": "PH"
            },
            {
                "name": "Tabing",
                "countryCode": "PH"
            },
            {
                "name": "Tabontabon",
                "countryCode": "PH"
            },
            {
                "name": "Tacloban",
                "countryCode": "PH"
            },
            {
                "name": "Taft",
                "countryCode": "PH"
            },
            {
                "name": "Tagapul-an",
                "countryCode": "PH"
            },
            {
                "name": "Tagbubungang Diot",
                "countryCode": "PH"
            },
            {
                "name": "Talalora",
                "countryCode": "PH"
            },
            {
                "name": "Talisayan",
                "countryCode": "PH"
            },
            {
                "name": "Tanauan",
                "countryCode": "PH"
            },
            {
                "name": "Tarangnan",
                "countryCode": "PH"
            },
            {
                "name": "Tibur",
                "countryCode": "PH"
            },
            {
                "name": "Tinambacan",
                "countryCode": "PH"
            },
            {
                "name": "Tolosa",
                "countryCode": "PH"
            },
            {
                "name": "Tomas Oppus",
                "countryCode": "PH"
            },
            {
                "name": "Tucdao",
                "countryCode": "PH"
            },
            {
                "name": "Tugbong",
                "countryCode": "PH"
            },
            {
                "name": "Tunga",
                "countryCode": "PH"
            },
            {
                "name": "Tutubigan",
                "countryCode": "PH"
            },
            {
                "name": "Umaganhan",
                "countryCode": "PH"
            },
            {
                "name": "Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Victoria",
                "countryCode": "PH"
            },
            {
                "name": "Viga",
                "countryCode": "PH"
            },
            {
                "name": "Villaba",
                "countryCode": "PH"
            },
            {
                "name": "Villareal",
                "countryCode": "PH"
            },
            {
                "name": "Viriato",
                "countryCode": "PH"
            },
            {
                "name": "Wright",
                "countryCode": "PH"
            },
            {
                "name": "Zumarraga",
                "countryCode": "PH"
            },
            {
                "name": "Alejal",
                "countryCode": "PH"
            },
            {
                "name": "Andili",
                "countryCode": "PH"
            },
            {
                "name": "Andop",
                "countryCode": "PH"
            },
            {
                "name": "Anibongan",
                "countryCode": "PH"
            },
            {
                "name": "Astorga",
                "countryCode": "PH"
            },
            {
                "name": "Babag",
                "countryCode": "PH"
            },
            {
                "name": "Baculin",
                "countryCode": "PH"
            },
            {
                "name": "Baganga",
                "countryCode": "PH"
            },
            {
                "name": "Balagunan",
                "countryCode": "PH"
            },
            {
                "name": "Balangonan",
                "countryCode": "PH"
            },
            {
                "name": "Balutakay",
                "countryCode": "PH"
            },
            {
                "name": "Bansalan",
                "countryCode": "PH"
            },
            {
                "name": "Bantacan",
                "countryCode": "PH"
            },
            {
                "name": "Baon",
                "countryCode": "PH"
            },
            {
                "name": "Baracatan",
                "countryCode": "PH"
            },
            {
                "name": "Basiawan",
                "countryCode": "PH"
            },
            {
                "name": "Batiano",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Batobato",
                "countryCode": "PH"
            },
            {
                "name": "Baylo",
                "countryCode": "PH"
            },
            {
                "name": "Biao",
                "countryCode": "PH"
            },
            {
                "name": "Bincoñgan",
                "countryCode": "PH"
            },
            {
                "name": "Bitaogan",
                "countryCode": "PH"
            },
            {
                "name": "Bobon",
                "countryCode": "PH"
            },
            {
                "name": "Bolila",
                "countryCode": "PH"
            },
            {
                "name": "Boston",
                "countryCode": "PH"
            },
            {
                "name": "Buclad",
                "countryCode": "PH"
            },
            {
                "name": "Buhangin",
                "countryCode": "PH"
            },
            {
                "name": "Bukid",
                "countryCode": "PH"
            },
            {
                "name": "Bulacan",
                "countryCode": "PH"
            },
            {
                "name": "Bungabon",
                "countryCode": "PH"
            },
            {
                "name": "Butulan",
                "countryCode": "PH"
            },
            {
                "name": "Cabayangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabinuangan",
                "countryCode": "PH"
            },
            {
                "name": "Caburan",
                "countryCode": "PH"
            },
            {
                "name": "Cambanugoy",
                "countryCode": "PH"
            },
            {
                "name": "Camudmud",
                "countryCode": "PH"
            },
            {
                "name": "Caraga",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Cateel",
                "countryCode": "PH"
            },
            {
                "name": "Cogon",
                "countryCode": "PH"
            },
            {
                "name": "Compostela",
                "countryCode": "PH"
            },
            {
                "name": "Compostela Valley",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Corocotan",
                "countryCode": "PH"
            },
            {
                "name": "Coronon",
                "countryCode": "PH"
            },
            {
                "name": "Cuambog",
                "countryCode": "PH"
            },
            {
                "name": "Culaman",
                "countryCode": "PH"
            },
            {
                "name": "Dacudao",
                "countryCode": "PH"
            },
            {
                "name": "Davan",
                "countryCode": "PH"
            },
            {
                "name": "Davao",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Digos",
                "countryCode": "PH"
            },
            {
                "name": "Dolo",
                "countryCode": "PH"
            },
            {
                "name": "Dumlan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gabi",
                "countryCode": "PH"
            },
            {
                "name": "Gabuyan",
                "countryCode": "PH"
            },
            {
                "name": "Goma",
                "countryCode": "PH"
            },
            {
                "name": "Guihing Proper",
                "countryCode": "PH"
            },
            {
                "name": "Gumalang",
                "countryCode": "PH"
            },
            {
                "name": "Gupitan",
                "countryCode": "PH"
            },
            {
                "name": "Hagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Hiju, Maco",
                "countryCode": "PH"
            },
            {
                "name": "Ignit",
                "countryCode": "PH"
            },
            {
                "name": "Ilangay",
                "countryCode": "PH"
            },
            {
                "name": "Inawayan",
                "countryCode": "PH"
            },
            {
                "name": "Jovellar",
                "countryCode": "PH"
            },
            {
                "name": "Kalbay",
                "countryCode": "PH"
            },
            {
                "name": "Kalian",
                "countryCode": "PH"
            },
            {
                "name": "Kaligutan",
                "countryCode": "PH"
            },
            {
                "name": "Kapalong",
                "countryCode": "PH"
            },
            {
                "name": "Katipunan",
                "countryCode": "PH"
            },
            {
                "name": "Kiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Kinablangan",
                "countryCode": "PH"
            },
            {
                "name": "Kinamayan",
                "countryCode": "PH"
            },
            {
                "name": "Kinangan",
                "countryCode": "PH"
            },
            {
                "name": "La Libertad",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Union",
                "countryCode": "PH"
            },
            {
                "name": "Lacaron",
                "countryCode": "PH"
            },
            {
                "name": "Lacson",
                "countryCode": "PH"
            },
            {
                "name": "Lais",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan",
                "countryCode": "PH"
            },
            {
                "name": "Lapuan",
                "countryCode": "PH"
            },
            {
                "name": "Lasang",
                "countryCode": "PH"
            },
            {
                "name": "Libuganon",
                "countryCode": "PH"
            },
            {
                "name": "Limao",
                "countryCode": "PH"
            },
            {
                "name": "Limot",
                "countryCode": "PH"
            },
            {
                "name": "Linao",
                "countryCode": "PH"
            },
            {
                "name": "Linoan",
                "countryCode": "PH"
            },
            {
                "name": "Lukatan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lungaog",
                "countryCode": "PH"
            },
            {
                "name": "Lupon",
                "countryCode": "PH"
            },
            {
                "name": "Luzon",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabuhay",
                "countryCode": "PH"
            },
            {
                "name": "Maco",
                "countryCode": "PH"
            },
            {
                "name": "Maduao",
                "countryCode": "PH"
            },
            {
                "name": "Magatos",
                "countryCode": "PH"
            },
            {
                "name": "Magdug",
                "countryCode": "PH"
            },
            {
                "name": "Magnaga",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Magugpo Poblacion",
                "countryCode": "PH"
            },
            {
                "name": "Mahanob",
                "countryCode": "PH"
            },
            {
                "name": "Mahayag",
                "countryCode": "PH"
            },
            {
                "name": "Malagos",
                "countryCode": "PH"
            },
            {
                "name": "Malalag",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malita",
                "countryCode": "PH"
            },
            {
                "name": "Mambago",
                "countryCode": "PH"
            },
            {
                "name": "Managa",
                "countryCode": "PH"
            },
            {
                "name": "Manaloal",
                "countryCode": "PH"
            },
            {
                "name": "Manat",
                "countryCode": "PH"
            },
            {
                "name": "Manay",
                "countryCode": "PH"
            },
            {
                "name": "Mangili",
                "countryCode": "PH"
            },
            {
                "name": "Manikling",
                "countryCode": "PH"
            },
            {
                "name": "Matanao",
                "countryCode": "PH"
            },
            {
                "name": "Mati",
                "countryCode": "PH"
            },
            {
                "name": "Matiao",
                "countryCode": "PH"
            },
            {
                "name": "Matti",
                "countryCode": "PH"
            },
            {
                "name": "Mawab",
                "countryCode": "PH"
            },
            {
                "name": "Mayo",
                "countryCode": "PH"
            },
            {
                "name": "Monkayo",
                "countryCode": "PH"
            },
            {
                "name": "Montevista",
                "countryCode": "PH"
            },
            {
                "name": "Nabunturan",
                "countryCode": "PH"
            },
            {
                "name": "Nangan",
                "countryCode": "PH"
            },
            {
                "name": "Nanyo",
                "countryCode": "PH"
            },
            {
                "name": "New Baclayon",
                "countryCode": "PH"
            },
            {
                "name": "New Bohol",
                "countryCode": "PH"
            },
            {
                "name": "New Corella",
                "countryCode": "PH"
            },
            {
                "name": "New Leyte",
                "countryCode": "PH"
            },
            {
                "name": "New Sibonga",
                "countryCode": "PH"
            },
            {
                "name": "New Visayas",
                "countryCode": "PH"
            },
            {
                "name": "Nuing",
                "countryCode": "PH"
            },
            {
                "name": "Padada",
                "countryCode": "PH"
            },
            {
                "name": "Pag-asa",
                "countryCode": "PH"
            },
            {
                "name": "Pagsabangan",
                "countryCode": "PH"
            },
            {
                "name": "Palma Gil",
                "countryCode": "PH"
            },
            {
                "name": "Panabo",
                "countryCode": "PH"
            },
            {
                "name": "Pandasan",
                "countryCode": "PH"
            },
            {
                "name": "Pangian",
                "countryCode": "PH"
            },
            {
                "name": "Panikian",
                "countryCode": "PH"
            },
            {
                "name": "Pantukan",
                "countryCode": "PH"
            },
            {
                "name": "Pasian",
                "countryCode": "PH"
            },
            {
                "name": "Pondaguitan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Davao Oriental",
                "countryCode": "PH"
            },
            {
                "name": "Province of Davao del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Davao del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Pung-Pang",
                "countryCode": "PH"
            },
            {
                "name": "Samal",
                "countryCode": "PH"
            },
            {
                "name": "Sampao",
                "countryCode": "PH"
            },
            {
                "name": "San Alfonso",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Ignacio",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Remigio",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Sarangani",
                "countryCode": "PH"
            },
            {
                "name": "Sibulan",
                "countryCode": "PH"
            },
            {
                "name": "Sigaboy",
                "countryCode": "PH"
            },
            {
                "name": "Simod",
                "countryCode": "PH"
            },
            {
                "name": "Sinawilan",
                "countryCode": "PH"
            },
            {
                "name": "Sinayawan",
                "countryCode": "PH"
            },
            {
                "name": "Sirib",
                "countryCode": "PH"
            },
            {
                "name": "Sugal",
                "countryCode": "PH"
            },
            {
                "name": "Sulop",
                "countryCode": "PH"
            },
            {
                "name": "Surup",
                "countryCode": "PH"
            },
            {
                "name": "Suz-on",
                "countryCode": "PH"
            },
            {
                "name": "Tagakpan",
                "countryCode": "PH"
            },
            {
                "name": "Tagdanua",
                "countryCode": "PH"
            },
            {
                "name": "Tagnanan",
                "countryCode": "PH"
            },
            {
                "name": "Takub",
                "countryCode": "PH"
            },
            {
                "name": "Talagutong",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Talomo",
                "countryCode": "PH"
            },
            {
                "name": "Tamayong",
                "countryCode": "PH"
            },
            {
                "name": "Tambo",
                "countryCode": "PH"
            },
            {
                "name": "Tamisan",
                "countryCode": "PH"
            },
            {
                "name": "Tamugan",
                "countryCode": "PH"
            },
            {
                "name": "Tanlad",
                "countryCode": "PH"
            },
            {
                "name": "Tapia",
                "countryCode": "PH"
            },
            {
                "name": "Tarragona",
                "countryCode": "PH"
            },
            {
                "name": "Tawan tawan",
                "countryCode": "PH"
            },
            {
                "name": "Taytayan",
                "countryCode": "PH"
            },
            {
                "name": "Tibagon",
                "countryCode": "PH"
            },
            {
                "name": "Tibanbang",
                "countryCode": "PH"
            },
            {
                "name": "Tiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Tombongon",
                "countryCode": "PH"
            },
            {
                "name": "Tubalan",
                "countryCode": "PH"
            },
            {
                "name": "Tuban",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tuganay",
                "countryCode": "PH"
            },
            {
                "name": "Tuli",
                "countryCode": "PH"
            },
            {
                "name": "Ula",
                "countryCode": "PH"
            },
            {
                "name": "Wañgan",
                "countryCode": "PH"
            },
            {
                "name": "Wines",
                "countryCode": "PH"
            },
            {
                "name": "Alabel",
                "countryCode": "PH"
            },
            {
                "name": "Alamada",
                "countryCode": "PH"
            },
            {
                "name": "Amas",
                "countryCode": "PH"
            },
            {
                "name": "Bagontapay",
                "countryCode": "PH"
            },
            {
                "name": "Baguer",
                "countryCode": "PH"
            },
            {
                "name": "Baliton",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Banawa",
                "countryCode": "PH"
            },
            {
                "name": "Banisilan",
                "countryCode": "PH"
            },
            {
                "name": "Bantogon",
                "countryCode": "PH"
            },
            {
                "name": "Barongis",
                "countryCode": "PH"
            },
            {
                "name": "Basak",
                "countryCode": "PH"
            },
            {
                "name": "Batasan",
                "countryCode": "PH"
            },
            {
                "name": "Batutitik",
                "countryCode": "PH"
            },
            {
                "name": "Bau",
                "countryCode": "PH"
            },
            {
                "name": "Bayasong",
                "countryCode": "PH"
            },
            {
                "name": "Bañga",
                "countryCode": "PH"
            },
            {
                "name": "Bialong",
                "countryCode": "PH"
            },
            {
                "name": "Biwang",
                "countryCode": "PH"
            },
            {
                "name": "Blingkong",
                "countryCode": "PH"
            },
            {
                "name": "Buadtasan",
                "countryCode": "PH"
            },
            {
                "name": "Bual",
                "countryCode": "PH"
            },
            {
                "name": "Buawan",
                "countryCode": "PH"
            },
            {
                "name": "Buayan",
                "countryCode": "PH"
            },
            {
                "name": "Bukay Pait",
                "countryCode": "PH"
            },
            {
                "name": "Bulatukan",
                "countryCode": "PH"
            },
            {
                "name": "Burias",
                "countryCode": "PH"
            },
            {
                "name": "Busok",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carpenter Hill",
                "countryCode": "PH"
            },
            {
                "name": "Cebuano",
                "countryCode": "PH"
            },
            {
                "name": "Colongulo",
                "countryCode": "PH"
            },
            {
                "name": "Columbio",
                "countryCode": "PH"
            },
            {
                "name": "Conel",
                "countryCode": "PH"
            },
            {
                "name": "Cotabato City",
                "countryCode": "PH"
            },
            {
                "name": "Daguma",
                "countryCode": "PH"
            },
            {
                "name": "Dahay",
                "countryCode": "PH"
            },
            {
                "name": "Daliao",
                "countryCode": "PH"
            },
            {
                "name": "Damawato",
                "countryCode": "PH"
            },
            {
                "name": "Dansuli",
                "countryCode": "PH"
            },
            {
                "name": "Digkilaan",
                "countryCode": "PH"
            },
            {
                "name": "Dualing",
                "countryCode": "PH"
            },
            {
                "name": "Dukay",
                "countryCode": "PH"
            },
            {
                "name": "Dumaguil",
                "countryCode": "PH"
            },
            {
                "name": "Dunguan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gansing",
                "countryCode": "PH"
            },
            {
                "name": "General Santos",
                "countryCode": "PH"
            },
            {
                "name": "Glad",
                "countryCode": "PH"
            },
            {
                "name": "Glamang",
                "countryCode": "PH"
            },
            {
                "name": "Glan",
                "countryCode": "PH"
            },
            {
                "name": "Glan Peidu",
                "countryCode": "PH"
            },
            {
                "name": "Gocoton",
                "countryCode": "PH"
            },
            {
                "name": "Guinsang-an",
                "countryCode": "PH"
            },
            {
                "name": "Ilaya",
                "countryCode": "PH"
            },
            {
                "name": "Iligan City",
                "countryCode": "PH"
            },
            {
                "name": "Isulan",
                "countryCode": "PH"
            },
            {
                "name": "Kabacan",
                "countryCode": "PH"
            },
            {
                "name": "Kabalen",
                "countryCode": "PH"
            },
            {
                "name": "Kablalan",
                "countryCode": "PH"
            },
            {
                "name": "Kalaisan",
                "countryCode": "PH"
            },
            {
                "name": "Kalamangog",
                "countryCode": "PH"
            },
            {
                "name": "Kalamansig",
                "countryCode": "PH"
            },
            {
                "name": "Kalandagan",
                "countryCode": "PH"
            },
            {
                "name": "Kamanga",
                "countryCode": "PH"
            },
            {
                "name": "Kapatan",
                "countryCode": "PH"
            },
            {
                "name": "Kapaya",
                "countryCode": "PH"
            },
            {
                "name": "Kapingkong",
                "countryCode": "PH"
            },
            {
                "name": "Katangawan",
                "countryCode": "PH"
            },
            {
                "name": "Katubao",
                "countryCode": "PH"
            },
            {
                "name": "Kiamba",
                "countryCode": "PH"
            },
            {
                "name": "Kidapawan",
                "countryCode": "PH"
            },
            {
                "name": "Kipalbig",
                "countryCode": "PH"
            },
            {
                "name": "Kisante",
                "countryCode": "PH"
            },
            {
                "name": "Kiupo",
                "countryCode": "PH"
            },
            {
                "name": "Klinan",
                "countryCode": "PH"
            },
            {
                "name": "Kolumbug",
                "countryCode": "PH"
            },
            {
                "name": "Koronadal",
                "countryCode": "PH"
            },
            {
                "name": "Kudanding",
                "countryCode": "PH"
            },
            {
                "name": "Kulaman",
                "countryCode": "PH"
            },
            {
                "name": "Labu-o",
                "countryCode": "PH"
            },
            {
                "name": "Laguilayan",
                "countryCode": "PH"
            },
            {
                "name": "Lake Sebu",
                "countryCode": "PH"
            },
            {
                "name": "Lala",
                "countryCode": "PH"
            },
            {
                "name": "Lamba",
                "countryCode": "PH"
            },
            {
                "name": "Lambayong",
                "countryCode": "PH"
            },
            {
                "name": "Lambontong",
                "countryCode": "PH"
            },
            {
                "name": "Lamian",
                "countryCode": "PH"
            },
            {
                "name": "Lampari",
                "countryCode": "PH"
            },
            {
                "name": "Lampitak",
                "countryCode": "PH"
            },
            {
                "name": "Lapuz",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Liberty",
                "countryCode": "PH"
            },
            {
                "name": "Libungan",
                "countryCode": "PH"
            },
            {
                "name": "Liliongan",
                "countryCode": "PH"
            },
            {
                "name": "Limbalod",
                "countryCode": "PH"
            },
            {
                "name": "Limulan",
                "countryCode": "PH"
            },
            {
                "name": "Linan",
                "countryCode": "PH"
            },
            {
                "name": "Linao",
                "countryCode": "PH"
            },
            {
                "name": "Lopez Jaena",
                "countryCode": "PH"
            },
            {
                "name": "Lumatil",
                "countryCode": "PH"
            },
            {
                "name": "Lumazal",
                "countryCode": "PH"
            },
            {
                "name": "Lumuyon",
                "countryCode": "PH"
            },
            {
                "name": "Lun Pequeño",
                "countryCode": "PH"
            },
            {
                "name": "Lunen",
                "countryCode": "PH"
            },
            {
                "name": "Maan",
                "countryCode": "PH"
            },
            {
                "name": "Maasim",
                "countryCode": "PH"
            },
            {
                "name": "Mabay",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Magpet",
                "countryCode": "PH"
            },
            {
                "name": "Maguling",
                "countryCode": "PH"
            },
            {
                "name": "Maibu",
                "countryCode": "PH"
            },
            {
                "name": "Maindang",
                "countryCode": "PH"
            },
            {
                "name": "Maitum",
                "countryCode": "PH"
            },
            {
                "name": "Makilala",
                "countryCode": "PH"
            },
            {
                "name": "Malamote",
                "countryCode": "PH"
            },
            {
                "name": "Malandag",
                "countryCode": "PH"
            },
            {
                "name": "Malapag",
                "countryCode": "PH"
            },
            {
                "name": "Malapatan",
                "countryCode": "PH"
            },
            {
                "name": "Malasila",
                "countryCode": "PH"
            },
            {
                "name": "Malbang",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malingao",
                "countryCode": "PH"
            },
            {
                "name": "Malisbeng",
                "countryCode": "PH"
            },
            {
                "name": "Malitubog",
                "countryCode": "PH"
            },
            {
                "name": "Maltana",
                "countryCode": "PH"
            },
            {
                "name": "Maluñgun",
                "countryCode": "PH"
            },
            {
                "name": "Mamali",
                "countryCode": "PH"
            },
            {
                "name": "Manaulanan",
                "countryCode": "PH"
            },
            {
                "name": "Manga",
                "countryCode": "PH"
            },
            {
                "name": "Manuangan",
                "countryCode": "PH"
            },
            {
                "name": "Manuel Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Marbel",
                "countryCode": "PH"
            },
            {
                "name": "Mariano Marcos",
                "countryCode": "PH"
            },
            {
                "name": "Matalam",
                "countryCode": "PH"
            },
            {
                "name": "Matiompong",
                "countryCode": "PH"
            },
            {
                "name": "Midsayap",
                "countryCode": "PH"
            },
            {
                "name": "Minapan",
                "countryCode": "PH"
            },
            {
                "name": "Mindupok",
                "countryCode": "PH"
            },
            {
                "name": "Nalus",
                "countryCode": "PH"
            },
            {
                "name": "New Cebu",
                "countryCode": "PH"
            },
            {
                "name": "New Iloilo",
                "countryCode": "PH"
            },
            {
                "name": "New Lagao",
                "countryCode": "PH"
            },
            {
                "name": "New Panay",
                "countryCode": "PH"
            },
            {
                "name": "Noling",
                "countryCode": "PH"
            },
            {
                "name": "Norala",
                "countryCode": "PH"
            },
            {
                "name": "Nunguan",
                "countryCode": "PH"
            },
            {
                "name": "Osias",
                "countryCode": "PH"
            },
            {
                "name": "Paatan",
                "countryCode": "PH"
            },
            {
                "name": "Pagalungan",
                "countryCode": "PH"
            },
            {
                "name": "Pagangan",
                "countryCode": "PH"
            },
            {
                "name": "Palian",
                "countryCode": "PH"
            },
            {
                "name": "Palimbang",
                "countryCode": "PH"
            },
            {
                "name": "Palkan",
                "countryCode": "PH"
            },
            {
                "name": "Pamantingan",
                "countryCode": "PH"
            },
            {
                "name": "Panay",
                "countryCode": "PH"
            },
            {
                "name": "Pangyan",
                "countryCode": "PH"
            },
            {
                "name": "Paraiso",
                "countryCode": "PH"
            },
            {
                "name": "Patindeguen",
                "countryCode": "PH"
            },
            {
                "name": "Pedtad",
                "countryCode": "PH"
            },
            {
                "name": "Pigcawayan",
                "countryCode": "PH"
            },
            {
                "name": "Pikit",
                "countryCode": "PH"
            },
            {
                "name": "Pimbalayan",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Polomolok",
                "countryCode": "PH"
            },
            {
                "name": "Polonoling",
                "countryCode": "PH"
            },
            {
                "name": "President Quirino",
                "countryCode": "PH"
            },
            {
                "name": "President Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Province of Cotabato",
                "countryCode": "PH"
            },
            {
                "name": "Province of Sarangani",
                "countryCode": "PH"
            },
            {
                "name": "Province of South Cotabato",
                "countryCode": "PH"
            },
            {
                "name": "Province of Sultan Kudarat",
                "countryCode": "PH"
            },
            {
                "name": "Puloypuloy",
                "countryCode": "PH"
            },
            {
                "name": "Punolu",
                "countryCode": "PH"
            },
            {
                "name": "Puricay",
                "countryCode": "PH"
            },
            {
                "name": "Ragandang",
                "countryCode": "PH"
            },
            {
                "name": "Rogongon",
                "countryCode": "PH"
            },
            {
                "name": "Rotonda",
                "countryCode": "PH"
            },
            {
                "name": "Sadsalan",
                "countryCode": "PH"
            },
            {
                "name": "Saguing",
                "countryCode": "PH"
            },
            {
                "name": "Salimbao",
                "countryCode": "PH"
            },
            {
                "name": "Salunayan",
                "countryCode": "PH"
            },
            {
                "name": "Salvador",
                "countryCode": "PH"
            },
            {
                "name": "Sampao",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sangay",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Sapad",
                "countryCode": "PH"
            },
            {
                "name": "Sapu Padidu",
                "countryCode": "PH"
            },
            {
                "name": "Silway 7",
                "countryCode": "PH"
            },
            {
                "name": "Sinolon",
                "countryCode": "PH"
            },
            {
                "name": "Sulit",
                "countryCode": "PH"
            },
            {
                "name": "Surallah",
                "countryCode": "PH"
            },
            {
                "name": "Suyan",
                "countryCode": "PH"
            },
            {
                "name": "Tacurong",
                "countryCode": "PH"
            },
            {
                "name": "Taguisa",
                "countryCode": "PH"
            },
            {
                "name": "Taluya",
                "countryCode": "PH"
            },
            {
                "name": "Tambak",
                "countryCode": "PH"
            },
            {
                "name": "Tambilil",
                "countryCode": "PH"
            },
            {
                "name": "Tamnag",
                "countryCode": "PH"
            },
            {
                "name": "Tamontaka",
                "countryCode": "PH"
            },
            {
                "name": "Tampakan",
                "countryCode": "PH"
            },
            {
                "name": "Tantangan",
                "countryCode": "PH"
            },
            {
                "name": "Tañgo",
                "countryCode": "PH"
            },
            {
                "name": "Telafas",
                "countryCode": "PH"
            },
            {
                "name": "Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Tinagacan",
                "countryCode": "PH"
            },
            {
                "name": "Tinoto",
                "countryCode": "PH"
            },
            {
                "name": "Tomado",
                "countryCode": "PH"
            },
            {
                "name": "Tran",
                "countryCode": "PH"
            },
            {
                "name": "Tuka",
                "countryCode": "PH"
            },
            {
                "name": "Tulunan",
                "countryCode": "PH"
            },
            {
                "name": "Tupi",
                "countryCode": "PH"
            },
            {
                "name": "Tuyan",
                "countryCode": "PH"
            },
            {
                "name": "Upper Klinan",
                "countryCode": "PH"
            },
            {
                "name": "Upper San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "Villamor",
                "countryCode": "PH"
            },
            {
                "name": "Adlay",
                "countryCode": "PH"
            },
            {
                "name": "Agay",
                "countryCode": "PH"
            },
            {
                "name": "Alegria",
                "countryCode": "PH"
            },
            {
                "name": "Alubijid",
                "countryCode": "PH"
            },
            {
                "name": "Amaga",
                "countryCode": "PH"
            },
            {
                "name": "Anticala",
                "countryCode": "PH"
            },
            {
                "name": "Aras-asan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod",
                "countryCode": "PH"
            },
            {
                "name": "Bacuag",
                "countryCode": "PH"
            },
            {
                "name": "Bah-Bah",
                "countryCode": "PH"
            },
            {
                "name": "Balangbalang",
                "countryCode": "PH"
            },
            {
                "name": "Bancasi",
                "countryCode": "PH"
            },
            {
                "name": "Bangonay",
                "countryCode": "PH"
            },
            {
                "name": "Barcelona",
                "countryCode": "PH"
            },
            {
                "name": "Barobo",
                "countryCode": "PH"
            },
            {
                "name": "Basa",
                "countryCode": "PH"
            },
            {
                "name": "Basag",
                "countryCode": "PH"
            },
            {
                "name": "Basilisa",
                "countryCode": "PH"
            },
            {
                "name": "Bayabas",
                "countryCode": "PH"
            },
            {
                "name": "Bayugan",
                "countryCode": "PH"
            },
            {
                "name": "Bigaan",
                "countryCode": "PH"
            },
            {
                "name": "Binucayan",
                "countryCode": "PH"
            },
            {
                "name": "Bislig",
                "countryCode": "PH"
            },
            {
                "name": "Borbon",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Bunawan",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Butuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabadbaran",
                "countryCode": "PH"
            },
            {
                "name": "Cagdianao",
                "countryCode": "PH"
            },
            {
                "name": "Cagwait",
                "countryCode": "PH"
            },
            {
                "name": "Calamba",
                "countryCode": "PH"
            },
            {
                "name": "Caloc-an",
                "countryCode": "PH"
            },
            {
                "name": "Cantapoy",
                "countryCode": "PH"
            },
            {
                "name": "Cantilan",
                "countryCode": "PH"
            },
            {
                "name": "Capalayan",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carrascal",
                "countryCode": "PH"
            },
            {
                "name": "Causwagan",
                "countryCode": "PH"
            },
            {
                "name": "Claver",
                "countryCode": "PH"
            },
            {
                "name": "Comagascas",
                "countryCode": "PH"
            },
            {
                "name": "Cortes",
                "countryCode": "PH"
            },
            {
                "name": "Cuevas",
                "countryCode": "PH"
            },
            {
                "name": "Culit",
                "countryCode": "PH"
            },
            {
                "name": "Dapa",
                "countryCode": "PH"
            },
            {
                "name": "Del Carmen, Surigao del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Dinagat",
                "countryCode": "PH"
            },
            {
                "name": "Dinagat Islands",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gamut",
                "countryCode": "PH"
            },
            {
                "name": "General Luna",
                "countryCode": "PH"
            },
            {
                "name": "Gigaquit",
                "countryCode": "PH"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PH"
            },
            {
                "name": "Guinabsan",
                "countryCode": "PH"
            },
            {
                "name": "Hinatuan",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Jabonga",
                "countryCode": "PH"
            },
            {
                "name": "Jagupit",
                "countryCode": "PH"
            },
            {
                "name": "Javier",
                "countryCode": "PH"
            },
            {
                "name": "Kauswagan",
                "countryCode": "PH"
            },
            {
                "name": "Kinabhangan",
                "countryCode": "PH"
            },
            {
                "name": "Kitcharao",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Union",
                "countryCode": "PH"
            },
            {
                "name": "Lanuza",
                "countryCode": "PH"
            },
            {
                "name": "Lapinigan",
                "countryCode": "PH"
            },
            {
                "name": "Lianga",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Libjo",
                "countryCode": "PH"
            },
            {
                "name": "Lingig",
                "countryCode": "PH"
            },
            {
                "name": "Lombocan",
                "countryCode": "PH"
            },
            {
                "name": "Loreto",
                "countryCode": "PH"
            },
            {
                "name": "Los Angeles",
                "countryCode": "PH"
            },
            {
                "name": "Los Arcos",
                "countryCode": "PH"
            },
            {
                "name": "Loyola",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Mabahin",
                "countryCode": "PH"
            },
            {
                "name": "Mabua",
                "countryCode": "PH"
            },
            {
                "name": "Madrid",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Mainit",
                "countryCode": "PH"
            },
            {
                "name": "Malimono",
                "countryCode": "PH"
            },
            {
                "name": "Manapa",
                "countryCode": "PH"
            },
            {
                "name": "Marihatag",
                "countryCode": "PH"
            },
            {
                "name": "Matabao",
                "countryCode": "PH"
            },
            {
                "name": "Mati",
                "countryCode": "PH"
            },
            {
                "name": "Maygatasan",
                "countryCode": "PH"
            },
            {
                "name": "Nasipit",
                "countryCode": "PH"
            },
            {
                "name": "Panikian",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Patin-ay",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Placer",
                "countryCode": "PH"
            },
            {
                "name": "Prosperidad",
                "countryCode": "PH"
            },
            {
                "name": "Province of Agusan del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Agusan del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Province of Surigao del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Surigao del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Punta",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Benito",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "Sanghan",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Josefa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Monica",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Sibagat",
                "countryCode": "PH"
            },
            {
                "name": "Sinubong",
                "countryCode": "PH"
            },
            {
                "name": "Sison",
                "countryCode": "PH"
            },
            {
                "name": "Socorro",
                "countryCode": "PH"
            },
            {
                "name": "Surigao",
                "countryCode": "PH"
            },
            {
                "name": "Tagana-an",
                "countryCode": "PH"
            },
            {
                "name": "Tagbina",
                "countryCode": "PH"
            },
            {
                "name": "Tagcatong",
                "countryCode": "PH"
            },
            {
                "name": "Tago",
                "countryCode": "PH"
            },
            {
                "name": "Talacogon",
                "countryCode": "PH"
            },
            {
                "name": "Taligaman",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Tandag",
                "countryCode": "PH"
            },
            {
                "name": "Tidman",
                "countryCode": "PH"
            },
            {
                "name": "Tigao",
                "countryCode": "PH"
            },
            {
                "name": "Trento",
                "countryCode": "PH"
            },
            {
                "name": "Tubajon",
                "countryCode": "PH"
            },
            {
                "name": "Tubay",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tungao",
                "countryCode": "PH"
            },
            {
                "name": "Unidad",
                "countryCode": "PH"
            },
            {
                "name": "Union",
                "countryCode": "PH"
            },
            {
                "name": "Veruela",
                "countryCode": "PH"
            },
            {
                "name": "Ampatuan",
                "countryCode": "PH"
            },
            {
                "name": "Andalan",
                "countryCode": "PH"
            },
            {
                "name": "Anuling",
                "countryCode": "PH"
            },
            {
                "name": "Awang",
                "countryCode": "PH"
            },
            {
                "name": "Bacayawan",
                "countryCode": "PH"
            },
            {
                "name": "Badak",
                "countryCode": "PH"
            },
            {
                "name": "Bagan",
                "countryCode": "PH"
            },
            {
                "name": "Baka",
                "countryCode": "PH"
            },
            {
                "name": "Bakung",
                "countryCode": "PH"
            },
            {
                "name": "Balabagan",
                "countryCode": "PH"
            },
            {
                "name": "Balas",
                "countryCode": "PH"
            },
            {
                "name": "Balimbing",
                "countryCode": "PH"
            },
            {
                "name": "Balindong",
                "countryCode": "PH"
            },
            {
                "name": "Bangkal",
                "countryCode": "PH"
            },
            {
                "name": "Bankaw",
                "countryCode": "PH"
            },
            {
                "name": "Barira",
                "countryCode": "PH"
            },
            {
                "name": "Barurao",
                "countryCode": "PH"
            },
            {
                "name": "Bato Bato",
                "countryCode": "PH"
            },
            {
                "name": "Baunu-Timbangan",
                "countryCode": "PH"
            },
            {
                "name": "Bawison",
                "countryCode": "PH"
            },
            {
                "name": "Bayang",
                "countryCode": "PH"
            },
            {
                "name": "Bayanga",
                "countryCode": "PH"
            },
            {
                "name": "Begang",
                "countryCode": "PH"
            },
            {
                "name": "Binidayan",
                "countryCode": "PH"
            },
            {
                "name": "Binuang",
                "countryCode": "PH"
            },
            {
                "name": "Blinsung",
                "countryCode": "PH"
            },
            {
                "name": "Bongao",
                "countryCode": "PH"
            },
            {
                "name": "Bongued",
                "countryCode": "PH"
            },
            {
                "name": "Buadiposo-Buntong",
                "countryCode": "PH"
            },
            {
                "name": "Bualan",
                "countryCode": "PH"
            },
            {
                "name": "Buan",
                "countryCode": "PH"
            },
            {
                "name": "Buansa",
                "countryCode": "PH"
            },
            {
                "name": "Budta",
                "countryCode": "PH"
            },
            {
                "name": "Bugasan",
                "countryCode": "PH"
            },
            {
                "name": "Buldon",
                "countryCode": "PH"
            },
            {
                "name": "Buliok",
                "countryCode": "PH"
            },
            {
                "name": "Bulit",
                "countryCode": "PH"
            },
            {
                "name": "Buluan",
                "countryCode": "PH"
            },
            {
                "name": "Bumbaran",
                "countryCode": "PH"
            },
            {
                "name": "Butig",
                "countryCode": "PH"
            },
            {
                "name": "Calanogas",
                "countryCode": "PH"
            },
            {
                "name": "City of Isabela",
                "countryCode": "PH"
            },
            {
                "name": "Colonia",
                "countryCode": "PH"
            },
            {
                "name": "Cotabato",
                "countryCode": "PH"
            },
            {
                "name": "Dado",
                "countryCode": "PH"
            },
            {
                "name": "Dadus",
                "countryCode": "PH"
            },
            {
                "name": "Dalican",
                "countryCode": "PH"
            },
            {
                "name": "Dalumangcob",
                "countryCode": "PH"
            },
            {
                "name": "Damabalas",
                "countryCode": "PH"
            },
            {
                "name": "Damatulan",
                "countryCode": "PH"
            },
            {
                "name": "Datu Paglas",
                "countryCode": "PH"
            },
            {
                "name": "Datu Piang",
                "countryCode": "PH"
            },
            {
                "name": "Digal",
                "countryCode": "PH"
            },
            {
                "name": "Dinaig",
                "countryCode": "PH"
            },
            {
                "name": "Dinganen",
                "countryCode": "PH"
            },
            {
                "name": "Ebcor Town",
                "countryCode": "PH"
            },
            {
                "name": "Gadung",
                "countryCode": "PH"
            },
            {
                "name": "Ganassi",
                "countryCode": "PH"
            },
            {
                "name": "Gang",
                "countryCode": "PH"
            },
            {
                "name": "Guiong",
                "countryCode": "PH"
            },
            {
                "name": "Idtig",
                "countryCode": "PH"
            },
            {
                "name": "Jolo",
                "countryCode": "PH"
            },
            {
                "name": "Kabasalan",
                "countryCode": "PH"
            },
            {
                "name": "Kagay",
                "countryCode": "PH"
            },
            {
                "name": "Kajatian",
                "countryCode": "PH"
            },
            {
                "name": "Kalang",
                "countryCode": "PH"
            },
            {
                "name": "Kalbugan",
                "countryCode": "PH"
            },
            {
                "name": "Kambing",
                "countryCode": "PH"
            },
            {
                "name": "Kanlagay",
                "countryCode": "PH"
            },
            {
                "name": "Kansipati",
                "countryCode": "PH"
            },
            {
                "name": "Karungdong",
                "countryCode": "PH"
            },
            {
                "name": "Katico",
                "countryCode": "PH"
            },
            {
                "name": "Katidtuan",
                "countryCode": "PH"
            },
            {
                "name": "Katuli",
                "countryCode": "PH"
            },
            {
                "name": "Kauran",
                "countryCode": "PH"
            },
            {
                "name": "Kitango",
                "countryCode": "PH"
            },
            {
                "name": "Kitapak",
                "countryCode": "PH"
            },
            {
                "name": "Kolape",
                "countryCode": "PH"
            },
            {
                "name": "Kulase",
                "countryCode": "PH"
            },
            {
                "name": "Kulay-Kulay",
                "countryCode": "PH"
            },
            {
                "name": "Kulempang",
                "countryCode": "PH"
            },
            {
                "name": "Kungtad",
                "countryCode": "PH"
            },
            {
                "name": "Labuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Laminusa",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan City",
                "countryCode": "PH"
            },
            {
                "name": "Langpas",
                "countryCode": "PH"
            },
            {
                "name": "Languyan",
                "countryCode": "PH"
            },
            {
                "name": "Larap",
                "countryCode": "PH"
            },
            {
                "name": "Latung",
                "countryCode": "PH"
            },
            {
                "name": "Layog",
                "countryCode": "PH"
            },
            {
                "name": "Ligayan",
                "countryCode": "PH"
            },
            {
                "name": "Limbo",
                "countryCode": "PH"
            },
            {
                "name": "Litayan",
                "countryCode": "PH"
            },
            {
                "name": "Lookan",
                "countryCode": "PH"
            },
            {
                "name": "Lu-uk",
                "countryCode": "PH"
            },
            {
                "name": "Lugus",
                "countryCode": "PH"
            },
            {
                "name": "Lumbac",
                "countryCode": "PH"
            },
            {
                "name": "Lumbatan",
                "countryCode": "PH"
            },
            {
                "name": "Lumbayanague",
                "countryCode": "PH"
            },
            {
                "name": "Lutayan",
                "countryCode": "PH"
            },
            {
                "name": "Luuk Datan",
                "countryCode": "PH"
            },
            {
                "name": "Madalum",
                "countryCode": "PH"
            },
            {
                "name": "Madamba",
                "countryCode": "PH"
            },
            {
                "name": "Maganoy",
                "countryCode": "PH"
            },
            {
                "name": "Mahala",
                "countryCode": "PH"
            },
            {
                "name": "Maimbung",
                "countryCode": "PH"
            },
            {
                "name": "Makir",
                "countryCode": "PH"
            },
            {
                "name": "Malabang",
                "countryCode": "PH"
            },
            {
                "name": "Maluso",
                "countryCode": "PH"
            },
            {
                "name": "Manubul",
                "countryCode": "PH"
            },
            {
                "name": "Manuk Mangkaw",
                "countryCode": "PH"
            },
            {
                "name": "Marantao",
                "countryCode": "PH"
            },
            {
                "name": "Marawi City",
                "countryCode": "PH"
            },
            {
                "name": "Marogong",
                "countryCode": "PH"
            },
            {
                "name": "Marsada",
                "countryCode": "PH"
            },
            {
                "name": "Masiu",
                "countryCode": "PH"
            },
            {
                "name": "Matanog",
                "countryCode": "PH"
            },
            {
                "name": "Mataya",
                "countryCode": "PH"
            },
            {
                "name": "Mauboh",
                "countryCode": "PH"
            },
            {
                "name": "Mileb",
                "countryCode": "PH"
            },
            {
                "name": "New Batu Batu",
                "countryCode": "PH"
            },
            {
                "name": "Nuyo",
                "countryCode": "PH"
            },
            {
                "name": "Pagalungan",
                "countryCode": "PH"
            },
            {
                "name": "Pagatin",
                "countryCode": "PH"
            },
            {
                "name": "Paitan",
                "countryCode": "PH"
            },
            {
                "name": "Panabuan",
                "countryCode": "PH"
            },
            {
                "name": "Panadtaban",
                "countryCode": "PH"
            },
            {
                "name": "Pandakan",
                "countryCode": "PH"
            },
            {
                "name": "Pandan Niog",
                "countryCode": "PH"
            },
            {
                "name": "Pang",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Parangan",
                "countryCode": "PH"
            },
            {
                "name": "Parian Dakula",
                "countryCode": "PH"
            },
            {
                "name": "Patikul",
                "countryCode": "PH"
            },
            {
                "name": "Pawak",
                "countryCode": "PH"
            },
            {
                "name": "Payuhan",
                "countryCode": "PH"
            },
            {
                "name": "Pidsandawan",
                "countryCode": "PH"
            },
            {
                "name": "Pinaring",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Polloc",
                "countryCode": "PH"
            },
            {
                "name": "Province of Basilan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Lanao del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Province of Maguindanao",
                "countryCode": "PH"
            },
            {
                "name": "Province of Sulu",
                "countryCode": "PH"
            },
            {
                "name": "Province of Tawi-Tawi",
                "countryCode": "PH"
            },
            {
                "name": "Pualas",
                "countryCode": "PH"
            },
            {
                "name": "Punay",
                "countryCode": "PH"
            },
            {
                "name": "Ramain",
                "countryCode": "PH"
            },
            {
                "name": "Rimpeso",
                "countryCode": "PH"
            },
            {
                "name": "Rominimbang",
                "countryCode": "PH"
            },
            {
                "name": "Sagasa",
                "countryCode": "PH"
            },
            {
                "name": "Saguiaran",
                "countryCode": "PH"
            },
            {
                "name": "Sambuluan",
                "countryCode": "PH"
            },
            {
                "name": "Sanga-Sanga",
                "countryCode": "PH"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PH"
            },
            {
                "name": "Sapa",
                "countryCode": "PH"
            },
            {
                "name": "Sapadun",
                "countryCode": "PH"
            },
            {
                "name": "Satan",
                "countryCode": "PH"
            },
            {
                "name": "Semut",
                "countryCode": "PH"
            },
            {
                "name": "Siasi",
                "countryCode": "PH"
            },
            {
                "name": "Simbahan",
                "countryCode": "PH"
            },
            {
                "name": "Simuay",
                "countryCode": "PH"
            },
            {
                "name": "Simunul",
                "countryCode": "PH"
            },
            {
                "name": "Sionogan",
                "countryCode": "PH"
            },
            {
                "name": "Sitangkai",
                "countryCode": "PH"
            },
            {
                "name": "South Upi",
                "countryCode": "PH"
            },
            {
                "name": "Sultan Kudarat",
                "countryCode": "PH"
            },
            {
                "name": "Sumisip",
                "countryCode": "PH"
            },
            {
                "name": "Tabiauan",
                "countryCode": "PH"
            },
            {
                "name": "Tablas",
                "countryCode": "PH"
            },
            {
                "name": "Taganak",
                "countryCode": "PH"
            },
            {
                "name": "Tairan Camp",
                "countryCode": "PH"
            },
            {
                "name": "Talayan",
                "countryCode": "PH"
            },
            {
                "name": "Talipao",
                "countryCode": "PH"
            },
            {
                "name": "Talipaw",
                "countryCode": "PH"
            },
            {
                "name": "Talitay",
                "countryCode": "PH"
            },
            {
                "name": "Tampakan",
                "countryCode": "PH"
            },
            {
                "name": "Tamparan",
                "countryCode": "PH"
            },
            {
                "name": "Tapayan",
                "countryCode": "PH"
            },
            {
                "name": "Tapikan",
                "countryCode": "PH"
            },
            {
                "name": "Taungoh",
                "countryCode": "PH"
            },
            {
                "name": "Taviran",
                "countryCode": "PH"
            },
            {
                "name": "Tinutulan",
                "countryCode": "PH"
            },
            {
                "name": "Tipo-Tipo",
                "countryCode": "PH"
            },
            {
                "name": "Tongouson",
                "countryCode": "PH"
            },
            {
                "name": "Tubaran",
                "countryCode": "PH"
            },
            {
                "name": "Tuburan",
                "countryCode": "PH"
            },
            {
                "name": "Tugaya",
                "countryCode": "PH"
            },
            {
                "name": "Tumbagaan",
                "countryCode": "PH"
            },
            {
                "name": "Tunggol",
                "countryCode": "PH"
            },
            {
                "name": "Tungol",
                "countryCode": "PH"
            },
            {
                "name": "Ungus-Ungus",
                "countryCode": "PH"
            },
            {
                "name": "Upi",
                "countryCode": "PH"
            },
            {
                "name": "Uyaan",
                "countryCode": "PH"
            },
            {
                "name": "Wao",
                "countryCode": "PH"
            },
            {
                "name": "Agbannawag",
                "countryCode": "PH"
            },
            {
                "name": "Ambuclao",
                "countryCode": "PH"
            },
            {
                "name": "Amlimay",
                "countryCode": "PH"
            },
            {
                "name": "Ampusungan",
                "countryCode": "PH"
            },
            {
                "name": "Angad",
                "countryCode": "PH"
            },
            {
                "name": "Apayao",
                "countryCode": "PH"
            },
            {
                "name": "Atok",
                "countryCode": "PH"
            },
            {
                "name": "Baculongan",
                "countryCode": "PH"
            },
            {
                "name": "Baguinge",
                "countryCode": "PH"
            },
            {
                "name": "Baguio",
                "countryCode": "PH"
            },
            {
                "name": "Bakun",
                "countryCode": "PH"
            },
            {
                "name": "Balbalan",
                "countryCode": "PH"
            },
            {
                "name": "Banaue",
                "countryCode": "PH"
            },
            {
                "name": "Bangao",
                "countryCode": "PH"
            },
            {
                "name": "Bangued",
                "countryCode": "PH"
            },
            {
                "name": "Bantay",
                "countryCode": "PH"
            },
            {
                "name": "Barlig",
                "countryCode": "PH"
            },
            {
                "name": "Bauko",
                "countryCode": "PH"
            },
            {
                "name": "Bayabas",
                "countryCode": "PH"
            },
            {
                "name": "Besao",
                "countryCode": "PH"
            },
            {
                "name": "Betwagan",
                "countryCode": "PH"
            },
            {
                "name": "Bocos",
                "countryCode": "PH"
            },
            {
                "name": "Bokod",
                "countryCode": "PH"
            },
            {
                "name": "Boliney",
                "countryCode": "PH"
            },
            {
                "name": "Bontoc",
                "countryCode": "PH"
            },
            {
                "name": "Bucay",
                "countryCode": "PH"
            },
            {
                "name": "Buguias",
                "countryCode": "PH"
            },
            {
                "name": "Bulalacao",
                "countryCode": "PH"
            },
            {
                "name": "Butigui",
                "countryCode": "PH"
            },
            {
                "name": "Calaba",
                "countryCode": "PH"
            },
            {
                "name": "Calanasan",
                "countryCode": "PH"
            },
            {
                "name": "Conner",
                "countryCode": "PH"
            },
            {
                "name": "Daguioman",
                "countryCode": "PH"
            },
            {
                "name": "Dalipey",
                "countryCode": "PH"
            },
            {
                "name": "Dalupirip",
                "countryCode": "PH"
            },
            {
                "name": "Danglas",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Flora",
                "countryCode": "PH"
            },
            {
                "name": "Gambang",
                "countryCode": "PH"
            },
            {
                "name": "Guinsadan",
                "countryCode": "PH"
            },
            {
                "name": "Hapao",
                "countryCode": "PH"
            },
            {
                "name": "Itogon",
                "countryCode": "PH"
            },
            {
                "name": "Kabugao",
                "countryCode": "PH"
            },
            {
                "name": "Kalinga",
                "countryCode": "PH"
            },
            {
                "name": "Kapangan",
                "countryCode": "PH"
            },
            {
                "name": "Kiangan",
                "countryCode": "PH"
            },
            {
                "name": "Kibungan",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Trinidad",
                "countryCode": "PH"
            },
            {
                "name": "Lagangilang",
                "countryCode": "PH"
            },
            {
                "name": "Lagawe",
                "countryCode": "PH"
            },
            {
                "name": "Lagayan",
                "countryCode": "PH"
            },
            {
                "name": "Lamut",
                "countryCode": "PH"
            },
            {
                "name": "Laya",
                "countryCode": "PH"
            },
            {
                "name": "Licuan",
                "countryCode": "PH"
            },
            {
                "name": "Liwan",
                "countryCode": "PH"
            },
            {
                "name": "Loacan",
                "countryCode": "PH"
            },
            {
                "name": "Luba",
                "countryCode": "PH"
            },
            {
                "name": "Lubuagan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Manabo",
                "countryCode": "PH"
            },
            {
                "name": "Mankayan",
                "countryCode": "PH"
            },
            {
                "name": "Monamon",
                "countryCode": "PH"
            },
            {
                "name": "Mountain Province",
                "countryCode": "PH"
            },
            {
                "name": "Nangalisan",
                "countryCode": "PH"
            },
            {
                "name": "Natonin",
                "countryCode": "PH"
            },
            {
                "name": "Natubleng",
                "countryCode": "PH"
            },
            {
                "name": "Paracelis",
                "countryCode": "PH"
            },
            {
                "name": "Pasil",
                "countryCode": "PH"
            },
            {
                "name": "Peñarrubia",
                "countryCode": "PH"
            },
            {
                "name": "Pidigan",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pinukpuk",
                "countryCode": "PH"
            },
            {
                "name": "Province of Abra",
                "countryCode": "PH"
            },
            {
                "name": "Province of Benguet",
                "countryCode": "PH"
            },
            {
                "name": "Province of Ifugao",
                "countryCode": "PH"
            },
            {
                "name": "Sablan",
                "countryCode": "PH"
            },
            {
                "name": "Sadanga",
                "countryCode": "PH"
            },
            {
                "name": "Sadsadan",
                "countryCode": "PH"
            },
            {
                "name": "Sagada",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Ramon",
                "countryCode": "PH"
            },
            {
                "name": "Santa Marcela",
                "countryCode": "PH"
            },
            {
                "name": "Tabaan",
                "countryCode": "PH"
            },
            {
                "name": "Tabio",
                "countryCode": "PH"
            },
            {
                "name": "Tabuk",
                "countryCode": "PH"
            },
            {
                "name": "Tacadang",
                "countryCode": "PH"
            },
            {
                "name": "Tadian",
                "countryCode": "PH"
            },
            {
                "name": "Taloy",
                "countryCode": "PH"
            },
            {
                "name": "Tanudan",
                "countryCode": "PH"
            },
            {
                "name": "Tayum",
                "countryCode": "PH"
            },
            {
                "name": "Tinglayan",
                "countryCode": "PH"
            },
            {
                "name": "Topdac",
                "countryCode": "PH"
            },
            {
                "name": "Tublay",
                "countryCode": "PH"
            },
            {
                "name": "Tuding",
                "countryCode": "PH"
            },
            {
                "name": "Villarosa",
                "countryCode": "PH"
            },
            {
                "name": "Villaviciosa",
                "countryCode": "PH"
            },
            {
                "name": "Adams",
                "countryCode": "PH"
            },
            {
                "name": "Agno",
                "countryCode": "PH"
            },
            {
                "name": "Agoo",
                "countryCode": "PH"
            },
            {
                "name": "Aguilar",
                "countryCode": "PH"
            },
            {
                "name": "Alac",
                "countryCode": "PH"
            },
            {
                "name": "Alaminos",
                "countryCode": "PH"
            },
            {
                "name": "Alcala",
                "countryCode": "PH"
            },
            {
                "name": "Alilem",
                "countryCode": "PH"
            },
            {
                "name": "Allangigan Primero",
                "countryCode": "PH"
            },
            {
                "name": "Aloleng",
                "countryCode": "PH"
            },
            {
                "name": "Amagbagan",
                "countryCode": "PH"
            },
            {
                "name": "Anambongan",
                "countryCode": "PH"
            },
            {
                "name": "Anda",
                "countryCode": "PH"
            },
            {
                "name": "Angatel",
                "countryCode": "PH"
            },
            {
                "name": "Anulid",
                "countryCode": "PH"
            },
            {
                "name": "Aringay",
                "countryCode": "PH"
            },
            {
                "name": "Asingan",
                "countryCode": "PH"
            },
            {
                "name": "Baay",
                "countryCode": "PH"
            },
            {
                "name": "Bacag",
                "countryCode": "PH"
            },
            {
                "name": "Bacarra",
                "countryCode": "PH"
            },
            {
                "name": "Bacnar",
                "countryCode": "PH"
            },
            {
                "name": "Bacnotan",
                "countryCode": "PH"
            },
            {
                "name": "Bactad Proper",
                "countryCode": "PH"
            },
            {
                "name": "Bacundao Weste",
                "countryCode": "PH"
            },
            {
                "name": "Badoc",
                "countryCode": "PH"
            },
            {
                "name": "Bagulin",
                "countryCode": "PH"
            },
            {
                "name": "Bail",
                "countryCode": "PH"
            },
            {
                "name": "Balaoan",
                "countryCode": "PH"
            },
            {
                "name": "Balingasay",
                "countryCode": "PH"
            },
            {
                "name": "Balingueo",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balungao",
                "countryCode": "PH"
            },
            {
                "name": "Baluyot",
                "countryCode": "PH"
            },
            {
                "name": "Banayoyo",
                "countryCode": "PH"
            },
            {
                "name": "Bangan-Oda",
                "countryCode": "PH"
            },
            {
                "name": "Bangar",
                "countryCode": "PH"
            },
            {
                "name": "Bangui",
                "countryCode": "PH"
            },
            {
                "name": "Bani",
                "countryCode": "PH"
            },
            {
                "name": "Banna",
                "countryCode": "PH"
            },
            {
                "name": "Banog Sur",
                "countryCode": "PH"
            },
            {
                "name": "Bantay",
                "countryCode": "PH"
            },
            {
                "name": "Bantog",
                "countryCode": "PH"
            },
            {
                "name": "Barangobong",
                "countryCode": "PH"
            },
            {
                "name": "Baro",
                "countryCode": "PH"
            },
            {
                "name": "Barong",
                "countryCode": "PH"
            },
            {
                "name": "Basing",
                "countryCode": "PH"
            },
            {
                "name": "Basista",
                "countryCode": "PH"
            },
            {
                "name": "Batac",
                "countryCode": "PH"
            },
            {
                "name": "Bataquil",
                "countryCode": "PH"
            },
            {
                "name": "Bauang",
                "countryCode": "PH"
            },
            {
                "name": "Bautista",
                "countryCode": "PH"
            },
            {
                "name": "Bayambang",
                "countryCode": "PH"
            },
            {
                "name": "Bayaoas",
                "countryCode": "PH"
            },
            {
                "name": "Bical Norte",
                "countryCode": "PH"
            },
            {
                "name": "Bil-Loca",
                "countryCode": "PH"
            },
            {
                "name": "Binabalian",
                "countryCode": "PH"
            },
            {
                "name": "Binalonan",
                "countryCode": "PH"
            },
            {
                "name": "Binday",
                "countryCode": "PH"
            },
            {
                "name": "Binmaley",
                "countryCode": "PH"
            },
            {
                "name": "Bobonan",
                "countryCode": "PH"
            },
            {
                "name": "Bogtong",
                "countryCode": "PH"
            },
            {
                "name": "Bolaoit",
                "countryCode": "PH"
            },
            {
                "name": "Bolinao",
                "countryCode": "PH"
            },
            {
                "name": "Bolingit",
                "countryCode": "PH"
            },
            {
                "name": "Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Botao",
                "countryCode": "PH"
            },
            {
                "name": "Boñgalon",
                "countryCode": "PH"
            },
            {
                "name": "Bued",
                "countryCode": "PH"
            },
            {
                "name": "Buenlag",
                "countryCode": "PH"
            },
            {
                "name": "Bugallon",
                "countryCode": "PH"
            },
            {
                "name": "Bulog",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Butubut Norte",
                "countryCode": "PH"
            },
            {
                "name": "Caabiangan",
                "countryCode": "PH"
            },
            {
                "name": "Caba",
                "countryCode": "PH"
            },
            {
                "name": "Cabalaoangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabalitian",
                "countryCode": "PH"
            },
            {
                "name": "Cabittaogan",
                "countryCode": "PH"
            },
            {
                "name": "Cabugao",
                "countryCode": "PH"
            },
            {
                "name": "Cabungan",
                "countryCode": "PH"
            },
            {
                "name": "Calasiao",
                "countryCode": "PH"
            },
            {
                "name": "Calepaan",
                "countryCode": "PH"
            },
            {
                "name": "Callaguip",
                "countryCode": "PH"
            },
            {
                "name": "Calomboyan",
                "countryCode": "PH"
            },
            {
                "name": "Calongbuyan",
                "countryCode": "PH"
            },
            {
                "name": "Calsib",
                "countryCode": "PH"
            },
            {
                "name": "Camaley",
                "countryCode": "PH"
            },
            {
                "name": "Canan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Canaoalan",
                "countryCode": "PH"
            },
            {
                "name": "Candon",
                "countryCode": "PH"
            },
            {
                "name": "Cantoria",
                "countryCode": "PH"
            },
            {
                "name": "Caoayan",
                "countryCode": "PH"
            },
            {
                "name": "Capandanan",
                "countryCode": "PH"
            },
            {
                "name": "Capulaan",
                "countryCode": "PH"
            },
            {
                "name": "Caramutan",
                "countryCode": "PH"
            },
            {
                "name": "Carasi",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Caronoan West",
                "countryCode": "PH"
            },
            {
                "name": "Carot",
                "countryCode": "PH"
            },
            {
                "name": "Carriedo",
                "countryCode": "PH"
            },
            {
                "name": "Carusucan",
                "countryCode": "PH"
            },
            {
                "name": "Caterman",
                "countryCode": "PH"
            },
            {
                "name": "Cato",
                "countryCode": "PH"
            },
            {
                "name": "Catuday",
                "countryCode": "PH"
            },
            {
                "name": "Cayanga",
                "countryCode": "PH"
            },
            {
                "name": "Cayungnan",
                "countryCode": "PH"
            },
            {
                "name": "Cervantes",
                "countryCode": "PH"
            },
            {
                "name": "City of Batac",
                "countryCode": "PH"
            },
            {
                "name": "City of Candon",
                "countryCode": "PH"
            },
            {
                "name": "City of Urdaneta",
                "countryCode": "PH"
            },
            {
                "name": "City of Vigan",
                "countryCode": "PH"
            },
            {
                "name": "Comillas Norte",
                "countryCode": "PH"
            },
            {
                "name": "Corrooy",
                "countryCode": "PH"
            },
            {
                "name": "Currimao",
                "countryCode": "PH"
            },
            {
                "name": "Dagup",
                "countryCode": "PH"
            },
            {
                "name": "Dagupan",
                "countryCode": "PH"
            },
            {
                "name": "Damortis",
                "countryCode": "PH"
            },
            {
                "name": "Darapidap",
                "countryCode": "PH"
            },
            {
                "name": "Dasol",
                "countryCode": "PH"
            },
            {
                "name": "Davila",
                "countryCode": "PH"
            },
            {
                "name": "Diaz",
                "countryCode": "PH"
            },
            {
                "name": "Dilan",
                "countryCode": "PH"
            },
            {
                "name": "Dingras",
                "countryCode": "PH"
            },
            {
                "name": "Domalanoan",
                "countryCode": "PH"
            },
            {
                "name": "Domampot",
                "countryCode": "PH"
            },
            {
                "name": "Don Pedro",
                "countryCode": "PH"
            },
            {
                "name": "Dorongan Punta",
                "countryCode": "PH"
            },
            {
                "name": "Doyong",
                "countryCode": "PH"
            },
            {
                "name": "Dulig",
                "countryCode": "PH"
            },
            {
                "name": "Dumalneg",
                "countryCode": "PH"
            },
            {
                "name": "Dumpay",
                "countryCode": "PH"
            },
            {
                "name": "Eguia",
                "countryCode": "PH"
            },
            {
                "name": "Esmeralda",
                "countryCode": "PH"
            },
            {
                "name": "Fuerte",
                "countryCode": "PH"
            },
            {
                "name": "Galimuyod",
                "countryCode": "PH"
            },
            {
                "name": "Gayaman",
                "countryCode": "PH"
            },
            {
                "name": "Gregorio del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Guiling",
                "countryCode": "PH"
            },
            {
                "name": "Guiset East",
                "countryCode": "PH"
            },
            {
                "name": "Hacienda",
                "countryCode": "PH"
            },
            {
                "name": "Halog West",
                "countryCode": "PH"
            },
            {
                "name": "Ilioilio",
                "countryCode": "PH"
            },
            {
                "name": "Inabaan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Infanta",
                "countryCode": "PH"
            },
            {
                "name": "Isla",
                "countryCode": "PH"
            },
            {
                "name": "Labayug",
                "countryCode": "PH"
            },
            {
                "name": "Labney",
                "countryCode": "PH"
            },
            {
                "name": "Labrador",
                "countryCode": "PH"
            },
            {
                "name": "Lagasit",
                "countryCode": "PH"
            },
            {
                "name": "Laguit Centro",
                "countryCode": "PH"
            },
            {
                "name": "Laoac",
                "countryCode": "PH"
            },
            {
                "name": "Laoag",
                "countryCode": "PH"
            },
            {
                "name": "Leones East",
                "countryCode": "PH"
            },
            {
                "name": "Lepa",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Lidlidda",
                "countryCode": "PH"
            },
            {
                "name": "Lingayen",
                "countryCode": "PH"
            },
            {
                "name": "Linmansangan",
                "countryCode": "PH"
            },
            {
                "name": "Lloren",
                "countryCode": "PH"
            },
            {
                "name": "Lobong",
                "countryCode": "PH"
            },
            {
                "name": "Longos",
                "countryCode": "PH"
            },
            {
                "name": "Loqueb Este",
                "countryCode": "PH"
            },
            {
                "name": "Lucap",
                "countryCode": "PH"
            },
            {
                "name": "Lucero",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lunec",
                "countryCode": "PH"
            },
            {
                "name": "Lungog",
                "countryCode": "PH"
            },
            {
                "name": "Lusong",
                "countryCode": "PH"
            },
            {
                "name": "Mabilao",
                "countryCode": "PH"
            },
            {
                "name": "Mabilbila Sur",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabusag",
                "countryCode": "PH"
            },
            {
                "name": "Macabuboni",
                "countryCode": "PH"
            },
            {
                "name": "Macalong",
                "countryCode": "PH"
            },
            {
                "name": "Macalva Norte",
                "countryCode": "PH"
            },
            {
                "name": "Macayug",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Magsingal",
                "countryCode": "PH"
            },
            {
                "name": "Magtaking",
                "countryCode": "PH"
            },
            {
                "name": "Malabago",
                "countryCode": "PH"
            },
            {
                "name": "Malanay",
                "countryCode": "PH"
            },
            {
                "name": "Malasiqui",
                "countryCode": "PH"
            },
            {
                "name": "Malawa",
                "countryCode": "PH"
            },
            {
                "name": "Malibong East",
                "countryCode": "PH"
            },
            {
                "name": "Manaoag",
                "countryCode": "PH"
            },
            {
                "name": "Mangaldan",
                "countryCode": "PH"
            },
            {
                "name": "Mangatarem",
                "countryCode": "PH"
            },
            {
                "name": "Mapandan",
                "countryCode": "PH"
            },
            {
                "name": "Mapolopolo",
                "countryCode": "PH"
            },
            {
                "name": "Marcos",
                "countryCode": "PH"
            },
            {
                "name": "Maticmatic",
                "countryCode": "PH"
            },
            {
                "name": "Minien East",
                "countryCode": "PH"
            },
            {
                "name": "Nagbacalan",
                "countryCode": "PH"
            },
            {
                "name": "Nagbukel",
                "countryCode": "PH"
            },
            {
                "name": "Nagsaing",
                "countryCode": "PH"
            },
            {
                "name": "Naguelguel",
                "countryCode": "PH"
            },
            {
                "name": "Naguilayan",
                "countryCode": "PH"
            },
            {
                "name": "Naguilian",
                "countryCode": "PH"
            },
            {
                "name": "Nalsian Norte",
                "countryCode": "PH"
            },
            {
                "name": "Nama",
                "countryCode": "PH"
            },
            {
                "name": "Namboongan",
                "countryCode": "PH"
            },
            {
                "name": "Nancalobasaan",
                "countryCode": "PH"
            },
            {
                "name": "Narvacan",
                "countryCode": "PH"
            },
            {
                "name": "Natividad",
                "countryCode": "PH"
            },
            {
                "name": "Navatat",
                "countryCode": "PH"
            },
            {
                "name": "Nibaliw Central",
                "countryCode": "PH"
            },
            {
                "name": "Nilombot",
                "countryCode": "PH"
            },
            {
                "name": "Ninoy",
                "countryCode": "PH"
            },
            {
                "name": "Nueva Era",
                "countryCode": "PH"
            },
            {
                "name": "Oaqui",
                "countryCode": "PH"
            },
            {
                "name": "Olea",
                "countryCode": "PH"
            },
            {
                "name": "Padong",
                "countryCode": "PH"
            },
            {
                "name": "Pagsanahan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pagudpud",
                "countryCode": "PH"
            },
            {
                "name": "Paitan Este",
                "countryCode": "PH"
            },
            {
                "name": "Palacpalac",
                "countryCode": "PH"
            },
            {
                "name": "Palguyod",
                "countryCode": "PH"
            },
            {
                "name": "Panganiban",
                "countryCode": "PH"
            },
            {
                "name": "Pangapisan",
                "countryCode": "PH"
            },
            {
                "name": "Pangascasan",
                "countryCode": "PH"
            },
            {
                "name": "Pangpang",
                "countryCode": "PH"
            },
            {
                "name": "Paoay",
                "countryCode": "PH"
            },
            {
                "name": "Paringao",
                "countryCode": "PH"
            },
            {
                "name": "Parioc Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Pasibi West",
                "countryCode": "PH"
            },
            {
                "name": "Pasuquin",
                "countryCode": "PH"
            },
            {
                "name": "Patayac",
                "countryCode": "PH"
            },
            {
                "name": "Patpata Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Payocpoc Sur",
                "countryCode": "PH"
            },
            {
                "name": "Piddig",
                "countryCode": "PH"
            },
            {
                "name": "Pindangan Centro",
                "countryCode": "PH"
            },
            {
                "name": "Pinili",
                "countryCode": "PH"
            },
            {
                "name": "Pogonsili",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Polong",
                "countryCode": "PH"
            },
            {
                "name": "Polong Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pozorrubio",
                "countryCode": "PH"
            },
            {
                "name": "Pudoc",
                "countryCode": "PH"
            },
            {
                "name": "Pudoc North",
                "countryCode": "PH"
            },
            {
                "name": "Puelay",
                "countryCode": "PH"
            },
            {
                "name": "Pugo",
                "countryCode": "PH"
            },
            {
                "name": "Puro Pinget",
                "countryCode": "PH"
            },
            {
                "name": "Quiling",
                "countryCode": "PH"
            },
            {
                "name": "Quinarayan",
                "countryCode": "PH"
            },
            {
                "name": "Quintong",
                "countryCode": "PH"
            },
            {
                "name": "Quirino",
                "countryCode": "PH"
            },
            {
                "name": "Ranao",
                "countryCode": "PH"
            },
            {
                "name": "Real",
                "countryCode": "PH"
            },
            {
                "name": "Rimus",
                "countryCode": "PH"
            },
            {
                "name": "Rissing",
                "countryCode": "PH"
            },
            {
                "name": "Rosales",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Sablig",
                "countryCode": "PH"
            },
            {
                "name": "Sagud-Bahley",
                "countryCode": "PH"
            },
            {
                "name": "Sagunto",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Samon",
                "countryCode": "PH"
            },
            {
                "name": "San Carlos",
                "countryCode": "PH"
            },
            {
                "name": "San Emilio",
                "countryCode": "PH"
            },
            {
                "name": "San Esteban",
                "countryCode": "PH"
            },
            {
                "name": "San Eugenio",
                "countryCode": "PH"
            },
            {
                "name": "San Fabian",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando Poblacion",
                "countryCode": "PH"
            },
            {
                "name": "San Gabriel",
                "countryCode": "PH"
            },
            {
                "name": "San Gabriel First",
                "countryCode": "PH"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "PH"
            },
            {
                "name": "San Jacinto",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro Apartado",
                "countryCode": "PH"
            },
            {
                "name": "San Quintin",
                "countryCode": "PH"
            },
            {
                "name": "San Sebastian",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sanlibo",
                "countryCode": "PH"
            },
            {
                "name": "Santa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Santol",
                "countryCode": "PH"
            },
            {
                "name": "Sarrat",
                "countryCode": "PH"
            },
            {
                "name": "Sigay",
                "countryCode": "PH"
            },
            {
                "name": "Sinait",
                "countryCode": "PH"
            },
            {
                "name": "Sison",
                "countryCode": "PH"
            },
            {
                "name": "Solsona",
                "countryCode": "PH"
            },
            {
                "name": "Sonquil",
                "countryCode": "PH"
            },
            {
                "name": "Sual",
                "countryCode": "PH"
            },
            {
                "name": "Subusub",
                "countryCode": "PH"
            },
            {
                "name": "Sudipen",
                "countryCode": "PH"
            },
            {
                "name": "Sugpon",
                "countryCode": "PH"
            },
            {
                "name": "Sumabnit",
                "countryCode": "PH"
            },
            {
                "name": "Suso",
                "countryCode": "PH"
            },
            {
                "name": "Suyo",
                "countryCode": "PH"
            },
            {
                "name": "Tablac",
                "countryCode": "PH"
            },
            {
                "name": "Tabug",
                "countryCode": "PH"
            },
            {
                "name": "Tagudin",
                "countryCode": "PH"
            },
            {
                "name": "Talospatang",
                "countryCode": "PH"
            },
            {
                "name": "Taloy",
                "countryCode": "PH"
            },
            {
                "name": "Tamayo",
                "countryCode": "PH"
            },
            {
                "name": "Tamorong",
                "countryCode": "PH"
            },
            {
                "name": "Tandoc",
                "countryCode": "PH"
            },
            {
                "name": "Tanolong",
                "countryCode": "PH"
            },
            {
                "name": "Tayug",
                "countryCode": "PH"
            },
            {
                "name": "Tebag East",
                "countryCode": "PH"
            },
            {
                "name": "Telbang",
                "countryCode": "PH"
            },
            {
                "name": "Tiep",
                "countryCode": "PH"
            },
            {
                "name": "Toboy",
                "countryCode": "PH"
            },
            {
                "name": "Tobuan",
                "countryCode": "PH"
            },
            {
                "name": "Tococ East",
                "countryCode": "PH"
            },
            {
                "name": "Tocok",
                "countryCode": "PH"
            },
            {
                "name": "Tombod",
                "countryCode": "PH"
            },
            {
                "name": "Tondol",
                "countryCode": "PH"
            },
            {
                "name": "Toritori",
                "countryCode": "PH"
            },
            {
                "name": "Tubao",
                "countryCode": "PH"
            },
            {
                "name": "Umanday Centro",
                "countryCode": "PH"
            },
            {
                "name": "Umingan",
                "countryCode": "PH"
            },
            {
                "name": "Unzad",
                "countryCode": "PH"
            },
            {
                "name": "Urbiztondo",
                "countryCode": "PH"
            },
            {
                "name": "Urdaneta",
                "countryCode": "PH"
            },
            {
                "name": "Uyong",
                "countryCode": "PH"
            },
            {
                "name": "Vigan",
                "countryCode": "PH"
            },
            {
                "name": "Villasis",
                "countryCode": "PH"
            },
            {
                "name": "Vintar",
                "countryCode": "PH"
            },
            {
                "name": "Zaragoza",
                "countryCode": "PH"
            },
            {
                "name": "Abut",
                "countryCode": "PH"
            },
            {
                "name": "Accusilian",
                "countryCode": "PH"
            },
            {
                "name": "Afusing Centro",
                "countryCode": "PH"
            },
            {
                "name": "Aglipay",
                "countryCode": "PH"
            },
            {
                "name": "Alabug",
                "countryCode": "PH"
            },
            {
                "name": "Alannay",
                "countryCode": "PH"
            },
            {
                "name": "Alcala",
                "countryCode": "PH"
            },
            {
                "name": "Alfonso Castañeda",
                "countryCode": "PH"
            },
            {
                "name": "Alicia",
                "countryCode": "PH"
            },
            {
                "name": "Allacapan",
                "countryCode": "PH"
            },
            {
                "name": "Almaguer North",
                "countryCode": "PH"
            },
            {
                "name": "Ambaguio",
                "countryCode": "PH"
            },
            {
                "name": "Amulung",
                "countryCode": "PH"
            },
            {
                "name": "Angadanan",
                "countryCode": "PH"
            },
            {
                "name": "Antagan Segunda",
                "countryCode": "PH"
            },
            {
                "name": "Aparri",
                "countryCode": "PH"
            },
            {
                "name": "Aritao",
                "countryCode": "PH"
            },
            {
                "name": "Atulayan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Awallan",
                "countryCode": "PH"
            },
            {
                "name": "Bacnor East",
                "countryCode": "PH"
            },
            {
                "name": "Bagabag",
                "countryCode": "PH"
            },
            {
                "name": "Baggabag B",
                "countryCode": "PH"
            },
            {
                "name": "Baggao",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Tanza",
                "countryCode": "PH"
            },
            {
                "name": "Bagu",
                "countryCode": "PH"
            },
            {
                "name": "Bagumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Ballesteros",
                "countryCode": "PH"
            },
            {
                "name": "Bambang",
                "countryCode": "PH"
            },
            {
                "name": "Bangad",
                "countryCode": "PH"
            },
            {
                "name": "Banganan",
                "countryCode": "PH"
            },
            {
                "name": "Banquero",
                "countryCode": "PH"
            },
            {
                "name": "Barucboc Norte",
                "countryCode": "PH"
            },
            {
                "name": "Basco",
                "countryCode": "PH"
            },
            {
                "name": "Batal",
                "countryCode": "PH"
            },
            {
                "name": "Battung",
                "countryCode": "PH"
            },
            {
                "name": "Bauan",
                "countryCode": "PH"
            },
            {
                "name": "Bayombong",
                "countryCode": "PH"
            },
            {
                "name": "Belance",
                "countryCode": "PH"
            },
            {
                "name": "Benito Soliven",
                "countryCode": "PH"
            },
            {
                "name": "Binalan",
                "countryCode": "PH"
            },
            {
                "name": "Binguang",
                "countryCode": "PH"
            },
            {
                "name": "Bintawan",
                "countryCode": "PH"
            },
            {
                "name": "Bitag Grande",
                "countryCode": "PH"
            },
            {
                "name": "Bone South",
                "countryCode": "PH"
            },
            {
                "name": "Buguey",
                "countryCode": "PH"
            },
            {
                "name": "Buliwao",
                "countryCode": "PH"
            },
            {
                "name": "Bulu",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Busilak",
                "countryCode": "PH"
            },
            {
                "name": "Cabagan",
                "countryCode": "PH"
            },
            {
                "name": "Cabannungan Second",
                "countryCode": "PH"
            },
            {
                "name": "Cabaritan East",
                "countryCode": "PH"
            },
            {
                "name": "Cabarroguis",
                "countryCode": "PH"
            },
            {
                "name": "Cabatuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabiraoan",
                "countryCode": "PH"
            },
            {
                "name": "Calamagui East",
                "countryCode": "PH"
            },
            {
                "name": "Calantac",
                "countryCode": "PH"
            },
            {
                "name": "Calaoagan",
                "countryCode": "PH"
            },
            {
                "name": "Calayan",
                "countryCode": "PH"
            },
            {
                "name": "Calinaoan Malasin",
                "countryCode": "PH"
            },
            {
                "name": "Calog Norte",
                "countryCode": "PH"
            },
            {
                "name": "Camalaniugan",
                "countryCode": "PH"
            },
            {
                "name": "Capissayan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Carig",
                "countryCode": "PH"
            },
            {
                "name": "Casambalangan",
                "countryCode": "PH"
            },
            {
                "name": "Catayauan",
                "countryCode": "PH"
            },
            {
                "name": "Cauayan",
                "countryCode": "PH"
            },
            {
                "name": "Claveria",
                "countryCode": "PH"
            },
            {
                "name": "Cordon",
                "countryCode": "PH"
            },
            {
                "name": "Cullalabo del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Dagupan",
                "countryCode": "PH"
            },
            {
                "name": "Dalaoig",
                "countryCode": "PH"
            },
            {
                "name": "Daragutan",
                "countryCode": "PH"
            },
            {
                "name": "Dassun",
                "countryCode": "PH"
            },
            {
                "name": "Delfin Albano",
                "countryCode": "PH"
            },
            {
                "name": "Diadi",
                "countryCode": "PH"
            },
            {
                "name": "Diamantina",
                "countryCode": "PH"
            },
            {
                "name": "Dibuluan",
                "countryCode": "PH"
            },
            {
                "name": "Dicabisagan",
                "countryCode": "PH"
            },
            {
                "name": "Dicamay",
                "countryCode": "PH"
            },
            {
                "name": "Diffun",
                "countryCode": "PH"
            },
            {
                "name": "Dinapigui",
                "countryCode": "PH"
            },
            {
                "name": "Divilican",
                "countryCode": "PH"
            },
            {
                "name": "Divisoria",
                "countryCode": "PH"
            },
            {
                "name": "Dodan",
                "countryCode": "PH"
            },
            {
                "name": "Dumabato",
                "countryCode": "PH"
            },
            {
                "name": "Dupax del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Dupax del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Echague (town)",
                "countryCode": "PH"
            },
            {
                "name": "Eden",
                "countryCode": "PH"
            },
            {
                "name": "Enrile",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza East",
                "countryCode": "PH"
            },
            {
                "name": "Estefania",
                "countryCode": "PH"
            },
            {
                "name": "Furao",
                "countryCode": "PH"
            },
            {
                "name": "Gadu",
                "countryCode": "PH"
            },
            {
                "name": "Gammad",
                "countryCode": "PH"
            },
            {
                "name": "Gamu",
                "countryCode": "PH"
            },
            {
                "name": "Ganapi",
                "countryCode": "PH"
            },
            {
                "name": "Gappal",
                "countryCode": "PH"
            },
            {
                "name": "Gattaran",
                "countryCode": "PH"
            },
            {
                "name": "Gonzaga",
                "countryCode": "PH"
            },
            {
                "name": "Guiddam",
                "countryCode": "PH"
            },
            {
                "name": "Ibung",
                "countryCode": "PH"
            },
            {
                "name": "Iguig",
                "countryCode": "PH"
            },
            {
                "name": "Ilagan",
                "countryCode": "PH"
            },
            {
                "name": "Ineangan",
                "countryCode": "PH"
            },
            {
                "name": "Itbayat",
                "countryCode": "PH"
            },
            {
                "name": "Ivana",
                "countryCode": "PH"
            },
            {
                "name": "Jones",
                "countryCode": "PH"
            },
            {
                "name": "Kasibu",
                "countryCode": "PH"
            },
            {
                "name": "Kayapa",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lal-lo",
                "countryCode": "PH"
            },
            {
                "name": "Lallayug",
                "countryCode": "PH"
            },
            {
                "name": "Lanna",
                "countryCode": "PH"
            },
            {
                "name": "Lapi",
                "countryCode": "PH"
            },
            {
                "name": "Larion Alto",
                "countryCode": "PH"
            },
            {
                "name": "Lasam",
                "countryCode": "PH"
            },
            {
                "name": "Lucban",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Mabasa",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabuttal East",
                "countryCode": "PH"
            },
            {
                "name": "Maconacon",
                "countryCode": "PH"
            },
            {
                "name": "Maddarulug Norte",
                "countryCode": "PH"
            },
            {
                "name": "Maddela",
                "countryCode": "PH"
            },
            {
                "name": "Magalalag",
                "countryCode": "PH"
            },
            {
                "name": "Magdalena",
                "countryCode": "PH"
            },
            {
                "name": "Maguilling",
                "countryCode": "PH"
            },
            {
                "name": "Mahatao",
                "countryCode": "PH"
            },
            {
                "name": "Malasin",
                "countryCode": "PH"
            },
            {
                "name": "Mallig",
                "countryCode": "PH"
            },
            {
                "name": "Maluno Sur",
                "countryCode": "PH"
            },
            {
                "name": "Manaring",
                "countryCode": "PH"
            },
            {
                "name": "Manga",
                "countryCode": "PH"
            },
            {
                "name": "Masaya Sur",
                "countryCode": "PH"
            },
            {
                "name": "Masipi West",
                "countryCode": "PH"
            },
            {
                "name": "Maxingal",
                "countryCode": "PH"
            },
            {
                "name": "Minallo",
                "countryCode": "PH"
            },
            {
                "name": "Minanga Norte",
                "countryCode": "PH"
            },
            {
                "name": "Minante Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Minuri",
                "countryCode": "PH"
            },
            {
                "name": "Mozzozzin Sur",
                "countryCode": "PH"
            },
            {
                "name": "Mungo",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Delfin Albano",
                "countryCode": "PH"
            },
            {
                "name": "Muñoz East",
                "countryCode": "PH"
            },
            {
                "name": "Nabannagan West",
                "countryCode": "PH"
            },
            {
                "name": "Nagrumbuan",
                "countryCode": "PH"
            },
            {
                "name": "Nagtipunan",
                "countryCode": "PH"
            },
            {
                "name": "Naguilian",
                "countryCode": "PH"
            },
            {
                "name": "Namuac",
                "countryCode": "PH"
            },
            {
                "name": "Nattapian",
                "countryCode": "PH"
            },
            {
                "name": "Paddaya",
                "countryCode": "PH"
            },
            {
                "name": "Palagao Norte",
                "countryCode": "PH"
            },
            {
                "name": "Palanan",
                "countryCode": "PH"
            },
            {
                "name": "Pamplona",
                "countryCode": "PH"
            },
            {
                "name": "Pangal Sur",
                "countryCode": "PH"
            },
            {
                "name": "Pata",
                "countryCode": "PH"
            },
            {
                "name": "Pattao",
                "countryCode": "PH"
            },
            {
                "name": "Peñablanca",
                "countryCode": "PH"
            },
            {
                "name": "Piat",
                "countryCode": "PH"
            },
            {
                "name": "Pinoma",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quibal",
                "countryCode": "PH"
            },
            {
                "name": "Quirino",
                "countryCode": "PH"
            },
            {
                "name": "Ragan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Ramon (municipal capital)",
                "countryCode": "PH"
            },
            {
                "name": "Ramos West",
                "countryCode": "PH"
            },
            {
                "name": "Reina Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Sabtang",
                "countryCode": "PH"
            },
            {
                "name": "Saguday",
                "countryCode": "PH"
            },
            {
                "name": "Salinas",
                "countryCode": "PH"
            },
            {
                "name": "Salinungan Proper",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Bernardo",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Guillermo",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sanchez Mira",
                "countryCode": "PH"
            },
            {
                "name": "Sandiat Centro",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Praxedes",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Siempre Viva",
                "countryCode": "PH"
            },
            {
                "name": "Sillawit",
                "countryCode": "PH"
            },
            {
                "name": "Simanu Sur",
                "countryCode": "PH"
            },
            {
                "name": "Simimbaan",
                "countryCode": "PH"
            },
            {
                "name": "Sinamar",
                "countryCode": "PH"
            },
            {
                "name": "Sindon",
                "countryCode": "PH"
            },
            {
                "name": "Solana",
                "countryCode": "PH"
            },
            {
                "name": "Solano",
                "countryCode": "PH"
            },
            {
                "name": "Soyung",
                "countryCode": "PH"
            },
            {
                "name": "Taguing",
                "countryCode": "PH"
            },
            {
                "name": "Tapel",
                "countryCode": "PH"
            },
            {
                "name": "Tuao",
                "countryCode": "PH"
            },
            {
                "name": "Tuguegarao",
                "countryCode": "PH"
            },
            {
                "name": "Tuguegarao City",
                "countryCode": "PH"
            },
            {
                "name": "Tumauini",
                "countryCode": "PH"
            },
            {
                "name": "Tupang",
                "countryCode": "PH"
            },
            {
                "name": "Uddiawan",
                "countryCode": "PH"
            },
            {
                "name": "Ugac Sur",
                "countryCode": "PH"
            },
            {
                "name": "Ugad",
                "countryCode": "PH"
            },
            {
                "name": "Upi",
                "countryCode": "PH"
            },
            {
                "name": "Uyugan",
                "countryCode": "PH"
            },
            {
                "name": "Villaverde",
                "countryCode": "PH"
            },
            {
                "name": "Yeban Norte",
                "countryCode": "PH"
            },
            {
                "name": "Acli",
                "countryCode": "PH"
            },
            {
                "name": "Agbannawag",
                "countryCode": "PH"
            },
            {
                "name": "Akle",
                "countryCode": "PH"
            },
            {
                "name": "Aliaga",
                "countryCode": "PH"
            },
            {
                "name": "Alua",
                "countryCode": "PH"
            },
            {
                "name": "Amacalan",
                "countryCode": "PH"
            },
            {
                "name": "Amucao",
                "countryCode": "PH"
            },
            {
                "name": "Amuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Anao",
                "countryCode": "PH"
            },
            {
                "name": "Angat",
                "countryCode": "PH"
            },
            {
                "name": "Angeles",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Apalit",
                "countryCode": "PH"
            },
            {
                "name": "Arayat",
                "countryCode": "PH"
            },
            {
                "name": "Arenas",
                "countryCode": "PH"
            },
            {
                "name": "Arminia",
                "countryCode": "PH"
            },
            {
                "name": "Bacabac",
                "countryCode": "PH"
            },
            {
                "name": "Bacolor",
                "countryCode": "PH"
            },
            {
                "name": "Bacsay",
                "countryCode": "PH"
            },
            {
                "name": "Bagac",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Barrio",
                "countryCode": "PH"
            },
            {
                "name": "Bagong-Sikat",
                "countryCode": "PH"
            },
            {
                "name": "Bahay Pare",
                "countryCode": "PH"
            },
            {
                "name": "Bakulong",
                "countryCode": "PH"
            },
            {
                "name": "Balagtas",
                "countryCode": "PH"
            },
            {
                "name": "Balanga",
                "countryCode": "PH"
            },
            {
                "name": "Balaoang",
                "countryCode": "PH"
            },
            {
                "name": "Balas",
                "countryCode": "PH"
            },
            {
                "name": "Balasing",
                "countryCode": "PH"
            },
            {
                "name": "Balayang",
                "countryCode": "PH"
            },
            {
                "name": "Baler",
                "countryCode": "PH"
            },
            {
                "name": "Balingcanaway",
                "countryCode": "PH"
            },
            {
                "name": "Balite",
                "countryCode": "PH"
            },
            {
                "name": "Baliuag",
                "countryCode": "PH"
            },
            {
                "name": "Baloc",
                "countryCode": "PH"
            },
            {
                "name": "Baloy",
                "countryCode": "PH"
            },
            {
                "name": "Balsic",
                "countryCode": "PH"
            },
            {
                "name": "Balucuc",
                "countryCode": "PH"
            },
            {
                "name": "Balut",
                "countryCode": "PH"
            },
            {
                "name": "Balutu",
                "countryCode": "PH"
            },
            {
                "name": "Bamban",
                "countryCode": "PH"
            },
            {
                "name": "Banawang",
                "countryCode": "PH"
            },
            {
                "name": "Bani",
                "countryCode": "PH"
            },
            {
                "name": "Baquero Norte",
                "countryCode": "PH"
            },
            {
                "name": "Batasan Bata",
                "countryCode": "PH"
            },
            {
                "name": "Batitang",
                "countryCode": "PH"
            },
            {
                "name": "Bayanan",
                "countryCode": "PH"
            },
            {
                "name": "Beddeng",
                "countryCode": "PH"
            },
            {
                "name": "Biay",
                "countryCode": "PH"
            },
            {
                "name": "Bibiclat",
                "countryCode": "PH"
            },
            {
                "name": "Bicos",
                "countryCode": "PH"
            },
            {
                "name": "Biga",
                "countryCode": "PH"
            },
            {
                "name": "Bilad",
                "countryCode": "PH"
            },
            {
                "name": "Bobon Second",
                "countryCode": "PH"
            },
            {
                "name": "Bocaue",
                "countryCode": "PH"
            },
            {
                "name": "Bodega",
                "countryCode": "PH"
            },
            {
                "name": "Bolitoc",
                "countryCode": "PH"
            },
            {
                "name": "Bongabon",
                "countryCode": "PH"
            },
            {
                "name": "Botolan",
                "countryCode": "PH"
            },
            {
                "name": "Buenlag",
                "countryCode": "PH"
            },
            {
                "name": "Buensuseso",
                "countryCode": "PH"
            },
            {
                "name": "Bulakan",
                "countryCode": "PH"
            },
            {
                "name": "Bulaon",
                "countryCode": "PH"
            },
            {
                "name": "Bularit",
                "countryCode": "PH"
            },
            {
                "name": "Bulawin",
                "countryCode": "PH"
            },
            {
                "name": "Bulihan",
                "countryCode": "PH"
            },
            {
                "name": "Buliran",
                "countryCode": "PH"
            },
            {
                "name": "Buliran Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Bulualto",
                "countryCode": "PH"
            },
            {
                "name": "Bundoc",
                "countryCode": "PH"
            },
            {
                "name": "Bunol",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Bustos",
                "countryCode": "PH"
            },
            {
                "name": "Cabanatuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabayaoasan",
                "countryCode": "PH"
            },
            {
                "name": "Cabcaben",
                "countryCode": "PH"
            },
            {
                "name": "Cabiao",
                "countryCode": "PH"
            },
            {
                "name": "Cabog",
                "countryCode": "PH"
            },
            {
                "name": "Cafe",
                "countryCode": "PH"
            },
            {
                "name": "Calaba",
                "countryCode": "PH"
            },
            {
                "name": "Calancuasan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Calangain",
                "countryCode": "PH"
            },
            {
                "name": "Calantas",
                "countryCode": "PH"
            },
            {
                "name": "Calayaan",
                "countryCode": "PH"
            },
            {
                "name": "Calibungan",
                "countryCode": "PH"
            },
            {
                "name": "Calibutbut",
                "countryCode": "PH"
            },
            {
                "name": "Calingcuan",
                "countryCode": "PH"
            },
            {
                "name": "Calumpang",
                "countryCode": "PH"
            },
            {
                "name": "Calumpit",
                "countryCode": "PH"
            },
            {
                "name": "Cama Juan",
                "countryCode": "PH"
            },
            {
                "name": "Camachile",
                "countryCode": "PH"
            },
            {
                "name": "Camias",
                "countryCode": "PH"
            },
            {
                "name": "Camiling",
                "countryCode": "PH"
            },
            {
                "name": "Candaba",
                "countryCode": "PH"
            },
            {
                "name": "Candating",
                "countryCode": "PH"
            },
            {
                "name": "Candelaria",
                "countryCode": "PH"
            },
            {
                "name": "Capas",
                "countryCode": "PH"
            },
            {
                "name": "Cardona",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carranglan",
                "countryCode": "PH"
            },
            {
                "name": "Casiguran",
                "countryCode": "PH"
            },
            {
                "name": "Castillejos",
                "countryCode": "PH"
            },
            {
                "name": "Cauayan",
                "countryCode": "PH"
            },
            {
                "name": "Cavite",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan Bugtong",
                "countryCode": "PH"
            },
            {
                "name": "City of Balanga",
                "countryCode": "PH"
            },
            {
                "name": "City of Gapan",
                "countryCode": "PH"
            },
            {
                "name": "City of Malolos",
                "countryCode": "PH"
            },
            {
                "name": "City of Meycauayan",
                "countryCode": "PH"
            },
            {
                "name": "City of San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "City of San Jose del Monte",
                "countryCode": "PH"
            },
            {
                "name": "Comillas",
                "countryCode": "PH"
            },
            {
                "name": "Communal",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Conversion",
                "countryCode": "PH"
            },
            {
                "name": "Culianin",
                "countryCode": "PH"
            },
            {
                "name": "Culubasa",
                "countryCode": "PH"
            },
            {
                "name": "Cut-cut Primero",
                "countryCode": "PH"
            },
            {
                "name": "Cuyapo",
                "countryCode": "PH"
            },
            {
                "name": "Dampol",
                "countryCode": "PH"
            },
            {
                "name": "Del Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Digdig",
                "countryCode": "PH"
            },
            {
                "name": "Dilasag",
                "countryCode": "PH"
            },
            {
                "name": "Diliman Primero",
                "countryCode": "PH"
            },
            {
                "name": "Dinalongan",
                "countryCode": "PH"
            },
            {
                "name": "Dinalupihan",
                "countryCode": "PH"
            },
            {
                "name": "Dingalan",
                "countryCode": "PH"
            },
            {
                "name": "Dipaculao",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Doña Remedios Trinidad",
                "countryCode": "PH"
            },
            {
                "name": "Entablado",
                "countryCode": "PH"
            },
            {
                "name": "Estipona",
                "countryCode": "PH"
            },
            {
                "name": "Estrella",
                "countryCode": "PH"
            },
            {
                "name": "Floridablanca",
                "countryCode": "PH"
            },
            {
                "name": "Gabaldon",
                "countryCode": "PH"
            },
            {
                "name": "Gapan",
                "countryCode": "PH"
            },
            {
                "name": "General Luna",
                "countryCode": "PH"
            },
            {
                "name": "General Mamerto Natividad",
                "countryCode": "PH"
            },
            {
                "name": "General Tinio",
                "countryCode": "PH"
            },
            {
                "name": "Gerona",
                "countryCode": "PH"
            },
            {
                "name": "Guagua",
                "countryCode": "PH"
            },
            {
                "name": "Gueset",
                "countryCode": "PH"
            },
            {
                "name": "Guiguinto",
                "countryCode": "PH"
            },
            {
                "name": "Guimba",
                "countryCode": "PH"
            },
            {
                "name": "Guisguis",
                "countryCode": "PH"
            },
            {
                "name": "Guyong",
                "countryCode": "PH"
            },
            {
                "name": "Hagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Hermosa",
                "countryCode": "PH"
            },
            {
                "name": "Iba",
                "countryCode": "PH"
            },
            {
                "name": "Jaen",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lambakin",
                "countryCode": "PH"
            },
            {
                "name": "Lanat",
                "countryCode": "PH"
            },
            {
                "name": "Laug",
                "countryCode": "PH"
            },
            {
                "name": "Laur",
                "countryCode": "PH"
            },
            {
                "name": "Lawang Kupang",
                "countryCode": "PH"
            },
            {
                "name": "Lennec",
                "countryCode": "PH"
            },
            {
                "name": "Licab",
                "countryCode": "PH"
            },
            {
                "name": "Ligaya",
                "countryCode": "PH"
            },
            {
                "name": "Limay",
                "countryCode": "PH"
            },
            {
                "name": "Liozon",
                "countryCode": "PH"
            },
            {
                "name": "Lipay",
                "countryCode": "PH"
            },
            {
                "name": "Llanera",
                "countryCode": "PH"
            },
            {
                "name": "Lomboy",
                "countryCode": "PH"
            },
            {
                "name": "Lourdes",
                "countryCode": "PH"
            },
            {
                "name": "Lubao",
                "countryCode": "PH"
            },
            {
                "name": "Lucapon",
                "countryCode": "PH"
            },
            {
                "name": "Lupao",
                "countryCode": "PH"
            },
            {
                "name": "Maasim",
                "countryCode": "PH"
            },
            {
                "name": "Mabalacat",
                "countryCode": "PH"
            },
            {
                "name": "Mabayo",
                "countryCode": "PH"
            },
            {
                "name": "Mabilang",
                "countryCode": "PH"
            },
            {
                "name": "Mabilog",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Macabebe",
                "countryCode": "PH"
            },
            {
                "name": "Macapsing",
                "countryCode": "PH"
            },
            {
                "name": "Macarse",
                "countryCode": "PH"
            },
            {
                "name": "Macatbong",
                "countryCode": "PH"
            },
            {
                "name": "Magalang",
                "countryCode": "PH"
            },
            {
                "name": "Magliman",
                "countryCode": "PH"
            },
            {
                "name": "Magtangol",
                "countryCode": "PH"
            },
            {
                "name": "Maguinao",
                "countryCode": "PH"
            },
            {
                "name": "Malabon",
                "countryCode": "PH"
            },
            {
                "name": "Malacampa",
                "countryCode": "PH"
            },
            {
                "name": "Maligaya",
                "countryCode": "PH"
            },
            {
                "name": "Malino",
                "countryCode": "PH"
            },
            {
                "name": "Malolos",
                "countryCode": "PH"
            },
            {
                "name": "Maloma",
                "countryCode": "PH"
            },
            {
                "name": "Maluid",
                "countryCode": "PH"
            },
            {
                "name": "Malusac",
                "countryCode": "PH"
            },
            {
                "name": "Mambog",
                "countryCode": "PH"
            },
            {
                "name": "Mamonit",
                "countryCode": "PH"
            },
            {
                "name": "Manacsac",
                "countryCode": "PH"
            },
            {
                "name": "Manatal",
                "countryCode": "PH"
            },
            {
                "name": "Mandili",
                "countryCode": "PH"
            },
            {
                "name": "Mangga",
                "countryCode": "PH"
            },
            {
                "name": "Manibaug Pasig",
                "countryCode": "PH"
            },
            {
                "name": "Manogpi",
                "countryCode": "PH"
            },
            {
                "name": "Mapalacsiao",
                "countryCode": "PH"
            },
            {
                "name": "Mapalad",
                "countryCode": "PH"
            },
            {
                "name": "Mapaniqui",
                "countryCode": "PH"
            },
            {
                "name": "Maquiapo",
                "countryCode": "PH"
            },
            {
                "name": "Marawa",
                "countryCode": "PH"
            },
            {
                "name": "Maria Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Marilao",
                "countryCode": "PH"
            },
            {
                "name": "Mariveles",
                "countryCode": "PH"
            },
            {
                "name": "Masalipit",
                "countryCode": "PH"
            },
            {
                "name": "Masantol",
                "countryCode": "PH"
            },
            {
                "name": "Masinloc",
                "countryCode": "PH"
            },
            {
                "name": "Matayumtayum",
                "countryCode": "PH"
            },
            {
                "name": "Maturanoc",
                "countryCode": "PH"
            },
            {
                "name": "Mayantoc",
                "countryCode": "PH"
            },
            {
                "name": "Mexico",
                "countryCode": "PH"
            },
            {
                "name": "Meycauayan",
                "countryCode": "PH"
            },
            {
                "name": "Minalin",
                "countryCode": "PH"
            },
            {
                "name": "Moncada",
                "countryCode": "PH"
            },
            {
                "name": "Moriones",
                "countryCode": "PH"
            },
            {
                "name": "Morong",
                "countryCode": "PH"
            },
            {
                "name": "Motrico",
                "countryCode": "PH"
            },
            {
                "name": "Murcia",
                "countryCode": "PH"
            },
            {
                "name": "Muñoz",
                "countryCode": "PH"
            },
            {
                "name": "Nagpandayan",
                "countryCode": "PH"
            },
            {
                "name": "Nambalan",
                "countryCode": "PH"
            },
            {
                "name": "Nampicuan",
                "countryCode": "PH"
            },
            {
                "name": "Nancamarinan",
                "countryCode": "PH"
            },
            {
                "name": "Nieves",
                "countryCode": "PH"
            },
            {
                "name": "Niugan",
                "countryCode": "PH"
            },
            {
                "name": "Norzagaray",
                "countryCode": "PH"
            },
            {
                "name": "Obando",
                "countryCode": "PH"
            },
            {
                "name": "Olongapo",
                "countryCode": "PH"
            },
            {
                "name": "Orani",
                "countryCode": "PH"
            },
            {
                "name": "Orion",
                "countryCode": "PH"
            },
            {
                "name": "Paco Roman",
                "countryCode": "PH"
            },
            {
                "name": "Padapada",
                "countryCode": "PH"
            },
            {
                "name": "Paitan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Palauig",
                "countryCode": "PH"
            },
            {
                "name": "Palayan",
                "countryCode": "PH"
            },
            {
                "name": "Palusapis",
                "countryCode": "PH"
            },
            {
                "name": "Pamatawan",
                "countryCode": "PH"
            },
            {
                "name": "Panabingan",
                "countryCode": "PH"
            },
            {
                "name": "Panan",
                "countryCode": "PH"
            },
            {
                "name": "Pance",
                "countryCode": "PH"
            },
            {
                "name": "Pandacaqui",
                "countryCode": "PH"
            },
            {
                "name": "Pandi",
                "countryCode": "PH"
            },
            {
                "name": "Pando",
                "countryCode": "PH"
            },
            {
                "name": "Paniqui",
                "countryCode": "PH"
            },
            {
                "name": "Pantabangan",
                "countryCode": "PH"
            },
            {
                "name": "Pantubig",
                "countryCode": "PH"
            },
            {
                "name": "Paombong",
                "countryCode": "PH"
            },
            {
                "name": "Papaya",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Parista",
                "countryCode": "PH"
            },
            {
                "name": "Pau",
                "countryCode": "PH"
            },
            {
                "name": "Peñaranda",
                "countryCode": "PH"
            },
            {
                "name": "Pias",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pinambaran",
                "countryCode": "PH"
            },
            {
                "name": "Pio",
                "countryCode": "PH"
            },
            {
                "name": "Piñahan",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Porac",
                "countryCode": "PH"
            },
            {
                "name": "Porais",
                "countryCode": "PH"
            },
            {
                "name": "Prado Siongco",
                "countryCode": "PH"
            },
            {
                "name": "Pulilan",
                "countryCode": "PH"
            },
            {
                "name": "Pulo",
                "countryCode": "PH"
            },
            {
                "name": "Pulong Gubat",
                "countryCode": "PH"
            },
            {
                "name": "Pulong Sampalok",
                "countryCode": "PH"
            },
            {
                "name": "Pulung Santol",
                "countryCode": "PH"
            },
            {
                "name": "Pulungmasle",
                "countryCode": "PH"
            },
            {
                "name": "Puncan",
                "countryCode": "PH"
            },
            {
                "name": "Pura",
                "countryCode": "PH"
            },
            {
                "name": "Purac",
                "countryCode": "PH"
            },
            {
                "name": "Putlod",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Rajal Norte",
                "countryCode": "PH"
            },
            {
                "name": "Ramos",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Sagana",
                "countryCode": "PH"
            },
            {
                "name": "Salapungan",
                "countryCode": "PH"
            },
            {
                "name": "Salaza",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion I",
                "countryCode": "PH"
            },
            {
                "name": "Samal",
                "countryCode": "PH"
            },
            {
                "name": "Sampaloc",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Alejandro",
                "countryCode": "PH"
            },
            {
                "name": "San Andres",
                "countryCode": "PH"
            },
            {
                "name": "San Anton",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Basilio",
                "countryCode": "PH"
            },
            {
                "name": "San Benito",
                "countryCode": "PH"
            },
            {
                "name": "San Carlos",
                "countryCode": "PH"
            },
            {
                "name": "San Casimiro",
                "countryCode": "PH"
            },
            {
                "name": "San Clemente",
                "countryCode": "PH"
            },
            {
                "name": "San Cristobal",
                "countryCode": "PH"
            },
            {
                "name": "San Fabian",
                "countryCode": "PH"
            },
            {
                "name": "San Felipe",
                "countryCode": "PH"
            },
            {
                "name": "San Felipe Old",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jacinto",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Jose del Monte",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Juan de Mata",
                "countryCode": "PH"
            },
            {
                "name": "San Leonardo",
                "countryCode": "PH"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Manuel",
                "countryCode": "PH"
            },
            {
                "name": "San Marcelino",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Narciso",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pascual",
                "countryCode": "PH"
            },
            {
                "name": "San Patricio",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Ricardo",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "San Roque Dau First",
                "countryCode": "PH"
            },
            {
                "name": "San Simon",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "San Vincente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ignacia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ines West",
                "countryCode": "PH"
            },
            {
                "name": "Santa Juliana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Monica",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresa First",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Cristo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Santol",
                "countryCode": "PH"
            },
            {
                "name": "Sapang",
                "countryCode": "PH"
            },
            {
                "name": "Sapang Buho",
                "countryCode": "PH"
            },
            {
                "name": "Sapol",
                "countryCode": "PH"
            },
            {
                "name": "Sasmuan",
                "countryCode": "PH"
            },
            {
                "name": "Saysain",
                "countryCode": "PH"
            },
            {
                "name": "Sibul",
                "countryCode": "PH"
            },
            {
                "name": "Siclong",
                "countryCode": "PH"
            },
            {
                "name": "Sinait",
                "countryCode": "PH"
            },
            {
                "name": "Sinilian First",
                "countryCode": "PH"
            },
            {
                "name": "Soledad",
                "countryCode": "PH"
            },
            {
                "name": "Subic",
                "countryCode": "PH"
            },
            {
                "name": "Suklayin",
                "countryCode": "PH"
            },
            {
                "name": "Sula",
                "countryCode": "PH"
            },
            {
                "name": "Sulucan",
                "countryCode": "PH"
            },
            {
                "name": "Tabacao",
                "countryCode": "PH"
            },
            {
                "name": "Tabon",
                "countryCode": "PH"
            },
            {
                "name": "Tabuating",
                "countryCode": "PH"
            },
            {
                "name": "Tal I Mun Doc",
                "countryCode": "PH"
            },
            {
                "name": "Talaga",
                "countryCode": "PH"
            },
            {
                "name": "Talang",
                "countryCode": "PH"
            },
            {
                "name": "Talavera",
                "countryCode": "PH"
            },
            {
                "name": "Taltal",
                "countryCode": "PH"
            },
            {
                "name": "Talugtug",
                "countryCode": "PH"
            },
            {
                "name": "Tariji",
                "countryCode": "PH"
            },
            {
                "name": "Tarlac City",
                "countryCode": "PH"
            },
            {
                "name": "Tayabo",
                "countryCode": "PH"
            },
            {
                "name": "Telabastagan",
                "countryCode": "PH"
            },
            {
                "name": "Tikiw",
                "countryCode": "PH"
            },
            {
                "name": "Tinang",
                "countryCode": "PH"
            },
            {
                "name": "Tondod",
                "countryCode": "PH"
            },
            {
                "name": "Uacon",
                "countryCode": "PH"
            },
            {
                "name": "Umiray",
                "countryCode": "PH"
            },
            {
                "name": "Upig",
                "countryCode": "PH"
            },
            {
                "name": "Vargas",
                "countryCode": "PH"
            },
            {
                "name": "Victoria",
                "countryCode": "PH"
            },
            {
                "name": "Villa Aglipay",
                "countryCode": "PH"
            },
            {
                "name": "Villa Isla",
                "countryCode": "PH"
            },
            {
                "name": "Vizal San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "Vizal Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Zaragoza",
                "countryCode": "PH"
            },
            {
                "name": "Aanislag",
                "countryCode": "PH"
            },
            {
                "name": "Abucay",
                "countryCode": "PH"
            },
            {
                "name": "Abuyog",
                "countryCode": "PH"
            },
            {
                "name": "Agos",
                "countryCode": "PH"
            },
            {
                "name": "Aguada",
                "countryCode": "PH"
            },
            {
                "name": "Agupit",
                "countryCode": "PH"
            },
            {
                "name": "Alayao",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Anuling",
                "countryCode": "PH"
            },
            {
                "name": "Apad",
                "countryCode": "PH"
            },
            {
                "name": "Apud",
                "countryCode": "PH"
            },
            {
                "name": "Armenia",
                "countryCode": "PH"
            },
            {
                "name": "Aroroy",
                "countryCode": "PH"
            },
            {
                "name": "Ayugan",
                "countryCode": "PH"
            },
            {
                "name": "Baao",
                "countryCode": "PH"
            },
            {
                "name": "Bacacay",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod",
                "countryCode": "PH"
            },
            {
                "name": "Bacon",
                "countryCode": "PH"
            },
            {
                "name": "Badian",
                "countryCode": "PH"
            },
            {
                "name": "Bagacay",
                "countryCode": "PH"
            },
            {
                "name": "Bagahanlad",
                "countryCode": "PH"
            },
            {
                "name": "Bagamanoc",
                "countryCode": "PH"
            },
            {
                "name": "Bagumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Bahay",
                "countryCode": "PH"
            },
            {
                "name": "Balading",
                "countryCode": "PH"
            },
            {
                "name": "Balaogan",
                "countryCode": "PH"
            },
            {
                "name": "Balatan",
                "countryCode": "PH"
            },
            {
                "name": "Baleno",
                "countryCode": "PH"
            },
            {
                "name": "Balete",
                "countryCode": "PH"
            },
            {
                "name": "Baligang",
                "countryCode": "PH"
            },
            {
                "name": "Balinad",
                "countryCode": "PH"
            },
            {
                "name": "Baliuag Nuevo",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balucawi",
                "countryCode": "PH"
            },
            {
                "name": "Balud",
                "countryCode": "PH"
            },
            {
                "name": "Banag",
                "countryCode": "PH"
            },
            {
                "name": "Bangad",
                "countryCode": "PH"
            },
            {
                "name": "Bangkirohan",
                "countryCode": "PH"
            },
            {
                "name": "Banocboc",
                "countryCode": "PH"
            },
            {
                "name": "Bao",
                "countryCode": "PH"
            },
            {
                "name": "Baras",
                "countryCode": "PH"
            },
            {
                "name": "Barayong",
                "countryCode": "PH"
            },
            {
                "name": "Barcelona",
                "countryCode": "PH"
            },
            {
                "name": "Bariw",
                "countryCode": "PH"
            },
            {
                "name": "Barra",
                "countryCode": "PH"
            },
            {
                "name": "Bascaron",
                "countryCode": "PH"
            },
            {
                "name": "Basiad",
                "countryCode": "PH"
            },
            {
                "name": "Basicao Coastal",
                "countryCode": "PH"
            },
            {
                "name": "Basud",
                "countryCode": "PH"
            },
            {
                "name": "Batana",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Batobalane",
                "countryCode": "PH"
            },
            {
                "name": "Batuan",
                "countryCode": "PH"
            },
            {
                "name": "Baybay",
                "countryCode": "PH"
            },
            {
                "name": "Beberon",
                "countryCode": "PH"
            },
            {
                "name": "Bigaa",
                "countryCode": "PH"
            },
            {
                "name": "Binanwanaan",
                "countryCode": "PH"
            },
            {
                "name": "Binitayan",
                "countryCode": "PH"
            },
            {
                "name": "Binodegahan",
                "countryCode": "PH"
            },
            {
                "name": "Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Bombon",
                "countryCode": "PH"
            },
            {
                "name": "Bonga",
                "countryCode": "PH"
            },
            {
                "name": "Boton",
                "countryCode": "PH"
            },
            {
                "name": "Buang",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Buga",
                "countryCode": "PH"
            },
            {
                "name": "Buhatan",
                "countryCode": "PH"
            },
            {
                "name": "Buhi",
                "countryCode": "PH"
            },
            {
                "name": "Bula",
                "countryCode": "PH"
            },
            {
                "name": "Bulan",
                "countryCode": "PH"
            },
            {
                "name": "Bulo",
                "countryCode": "PH"
            },
            {
                "name": "Buluang",
                "countryCode": "PH"
            },
            {
                "name": "Bulusan",
                "countryCode": "PH"
            },
            {
                "name": "Burabod",
                "countryCode": "PH"
            },
            {
                "name": "Buracan",
                "countryCode": "PH"
            },
            {
                "name": "Busing",
                "countryCode": "PH"
            },
            {
                "name": "Butag",
                "countryCode": "PH"
            },
            {
                "name": "Buyo",
                "countryCode": "PH"
            },
            {
                "name": "Cabcab",
                "countryCode": "PH"
            },
            {
                "name": "Cabiguan",
                "countryCode": "PH"
            },
            {
                "name": "Cabitan",
                "countryCode": "PH"
            },
            {
                "name": "Cabognon",
                "countryCode": "PH"
            },
            {
                "name": "Cabugao",
                "countryCode": "PH"
            },
            {
                "name": "Cabusao",
                "countryCode": "PH"
            },
            {
                "name": "Caditaan",
                "countryCode": "PH"
            },
            {
                "name": "Cadlan",
                "countryCode": "PH"
            },
            {
                "name": "Cagmanaba",
                "countryCode": "PH"
            },
            {
                "name": "Calabaca",
                "countryCode": "PH"
            },
            {
                "name": "Calabanga",
                "countryCode": "PH"
            },
            {
                "name": "Calachuchi",
                "countryCode": "PH"
            },
            {
                "name": "Calasgasan",
                "countryCode": "PH"
            },
            {
                "name": "Calatagan",
                "countryCode": "PH"
            },
            {
                "name": "Calolbon",
                "countryCode": "PH"
            },
            {
                "name": "Camalig",
                "countryCode": "PH"
            },
            {
                "name": "Camaligan",
                "countryCode": "PH"
            },
            {
                "name": "Canaman",
                "countryCode": "PH"
            },
            {
                "name": "Canomoy",
                "countryCode": "PH"
            },
            {
                "name": "Capalonga",
                "countryCode": "PH"
            },
            {
                "name": "Capucnasan",
                "countryCode": "PH"
            },
            {
                "name": "Capuy",
                "countryCode": "PH"
            },
            {
                "name": "Caramoan",
                "countryCode": "PH"
            },
            {
                "name": "Caramoran",
                "countryCode": "PH"
            },
            {
                "name": "Caranan",
                "countryCode": "PH"
            },
            {
                "name": "Caraycayon",
                "countryCode": "PH"
            },
            {
                "name": "Carriedo",
                "countryCode": "PH"
            },
            {
                "name": "Casiguran",
                "countryCode": "PH"
            },
            {
                "name": "Castilla",
                "countryCode": "PH"
            },
            {
                "name": "Castillo",
                "countryCode": "PH"
            },
            {
                "name": "Catabangan",
                "countryCode": "PH"
            },
            {
                "name": "Cataingan",
                "countryCode": "PH"
            },
            {
                "name": "Causip",
                "countryCode": "PH"
            },
            {
                "name": "Claveria",
                "countryCode": "PH"
            },
            {
                "name": "Cotmon",
                "countryCode": "PH"
            },
            {
                "name": "Culacling",
                "countryCode": "PH"
            },
            {
                "name": "Cumadcad",
                "countryCode": "PH"
            },
            {
                "name": "Curry",
                "countryCode": "PH"
            },
            {
                "name": "Daet",
                "countryCode": "PH"
            },
            {
                "name": "Daguit",
                "countryCode": "PH"
            },
            {
                "name": "Dalupaon",
                "countryCode": "PH"
            },
            {
                "name": "Dangcalan",
                "countryCode": "PH"
            },
            {
                "name": "Dapdap",
                "countryCode": "PH"
            },
            {
                "name": "Daraga",
                "countryCode": "PH"
            },
            {
                "name": "Del Gallego",
                "countryCode": "PH"
            },
            {
                "name": "Del Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Dimasalang",
                "countryCode": "PH"
            },
            {
                "name": "Donsol",
                "countryCode": "PH"
            },
            {
                "name": "Dugcal",
                "countryCode": "PH"
            },
            {
                "name": "Dugongan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Estancia",
                "countryCode": "PH"
            },
            {
                "name": "Fabrica",
                "countryCode": "PH"
            },
            {
                "name": "Gabao",
                "countryCode": "PH"
            },
            {
                "name": "Gainza",
                "countryCode": "PH"
            },
            {
                "name": "Gambalidio",
                "countryCode": "PH"
            },
            {
                "name": "Garchitorena",
                "countryCode": "PH"
            },
            {
                "name": "Gatbo",
                "countryCode": "PH"
            },
            {
                "name": "Gibgos",
                "countryCode": "PH"
            },
            {
                "name": "Gigmoto",
                "countryCode": "PH"
            },
            {
                "name": "Goa",
                "countryCode": "PH"
            },
            {
                "name": "Gubat",
                "countryCode": "PH"
            },
            {
                "name": "Guijalo",
                "countryCode": "PH"
            },
            {
                "name": "Guinacotan",
                "countryCode": "PH"
            },
            {
                "name": "Guinobatan",
                "countryCode": "PH"
            },
            {
                "name": "Gumaus",
                "countryCode": "PH"
            },
            {
                "name": "Guruyan",
                "countryCode": "PH"
            },
            {
                "name": "Hamoraon",
                "countryCode": "PH"
            },
            {
                "name": "Herrera",
                "countryCode": "PH"
            },
            {
                "name": "Himaao",
                "countryCode": "PH"
            },
            {
                "name": "Hobo",
                "countryCode": "PH"
            },
            {
                "name": "Imelda",
                "countryCode": "PH"
            },
            {
                "name": "Inapatan",
                "countryCode": "PH"
            },
            {
                "name": "Iraya",
                "countryCode": "PH"
            },
            {
                "name": "Iriga City",
                "countryCode": "PH"
            },
            {
                "name": "Irosin",
                "countryCode": "PH"
            },
            {
                "name": "Joroan",
                "countryCode": "PH"
            },
            {
                "name": "Jose Pañganiban",
                "countryCode": "PH"
            },
            {
                "name": "Jovellar",
                "countryCode": "PH"
            },
            {
                "name": "Juban",
                "countryCode": "PH"
            },
            {
                "name": "Kaliliog",
                "countryCode": "PH"
            },
            {
                "name": "Kinalansan",
                "countryCode": "PH"
            },
            {
                "name": "Labnig",
                "countryCode": "PH"
            },
            {
                "name": "Labo",
                "countryCode": "PH"
            },
            {
                "name": "Lacag",
                "countryCode": "PH"
            },
            {
                "name": "Lagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Lajong",
                "countryCode": "PH"
            },
            {
                "name": "Lanigay",
                "countryCode": "PH"
            },
            {
                "name": "Lantangan",
                "countryCode": "PH"
            },
            {
                "name": "Larap",
                "countryCode": "PH"
            },
            {
                "name": "Legaspi",
                "countryCode": "PH"
            },
            {
                "name": "Libmanan",
                "countryCode": "PH"
            },
            {
                "name": "Libog",
                "countryCode": "PH"
            },
            {
                "name": "Libon",
                "countryCode": "PH"
            },
            {
                "name": "Liboro",
                "countryCode": "PH"
            },
            {
                "name": "Ligao",
                "countryCode": "PH"
            },
            {
                "name": "Limbuhan",
                "countryCode": "PH"
            },
            {
                "name": "Lourdes",
                "countryCode": "PH"
            },
            {
                "name": "Lubigan",
                "countryCode": "PH"
            },
            {
                "name": "Lugui",
                "countryCode": "PH"
            },
            {
                "name": "Luklukan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lupi",
                "countryCode": "PH"
            },
            {
                "name": "Lupi Viejo",
                "countryCode": "PH"
            },
            {
                "name": "Maagnas",
                "countryCode": "PH"
            },
            {
                "name": "Mabiton",
                "countryCode": "PH"
            },
            {
                "name": "Macabugos",
                "countryCode": "PH"
            },
            {
                "name": "Macalaya",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Magarao",
                "countryCode": "PH"
            },
            {
                "name": "Magsalangi",
                "countryCode": "PH"
            },
            {
                "name": "Mahaba",
                "countryCode": "PH"
            },
            {
                "name": "Malabog",
                "countryCode": "PH"
            },
            {
                "name": "Malasugui",
                "countryCode": "PH"
            },
            {
                "name": "Malatap",
                "countryCode": "PH"
            },
            {
                "name": "Malawag",
                "countryCode": "PH"
            },
            {
                "name": "Malbug",
                "countryCode": "PH"
            },
            {
                "name": "Malidong",
                "countryCode": "PH"
            },
            {
                "name": "Malilipot",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malinta",
                "countryCode": "PH"
            },
            {
                "name": "Mambulo",
                "countryCode": "PH"
            },
            {
                "name": "Mampurog",
                "countryCode": "PH"
            },
            {
                "name": "Manamrag",
                "countryCode": "PH"
            },
            {
                "name": "Mandaon",
                "countryCode": "PH"
            },
            {
                "name": "Manga",
                "countryCode": "PH"
            },
            {
                "name": "Manito",
                "countryCode": "PH"
            },
            {
                "name": "Manquiring",
                "countryCode": "PH"
            },
            {
                "name": "Maonon",
                "countryCode": "PH"
            },
            {
                "name": "Marintoc",
                "countryCode": "PH"
            },
            {
                "name": "Marupit",
                "countryCode": "PH"
            },
            {
                "name": "Masaraway",
                "countryCode": "PH"
            },
            {
                "name": "Masbate",
                "countryCode": "PH"
            },
            {
                "name": "Maslog",
                "countryCode": "PH"
            },
            {
                "name": "Masoli",
                "countryCode": "PH"
            },
            {
                "name": "Matacon",
                "countryCode": "PH"
            },
            {
                "name": "Matnog",
                "countryCode": "PH"
            },
            {
                "name": "Mauraro",
                "countryCode": "PH"
            },
            {
                "name": "Mayngaran",
                "countryCode": "PH"
            },
            {
                "name": "Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Miaga",
                "countryCode": "PH"
            },
            {
                "name": "Milagros",
                "countryCode": "PH"
            },
            {
                "name": "Milaor",
                "countryCode": "PH"
            },
            {
                "name": "Miliroc",
                "countryCode": "PH"
            },
            {
                "name": "Minalabac",
                "countryCode": "PH"
            },
            {
                "name": "Mobo",
                "countryCode": "PH"
            },
            {
                "name": "Monbon",
                "countryCode": "PH"
            },
            {
                "name": "Monreal",
                "countryCode": "PH"
            },
            {
                "name": "Muladbucad",
                "countryCode": "PH"
            },
            {
                "name": "Naagas",
                "countryCode": "PH"
            },
            {
                "name": "Nabangig",
                "countryCode": "PH"
            },
            {
                "name": "Nabua",
                "countryCode": "PH"
            },
            {
                "name": "Naga",
                "countryCode": "PH"
            },
            {
                "name": "Naro",
                "countryCode": "PH"
            },
            {
                "name": "Nato",
                "countryCode": "PH"
            },
            {
                "name": "Ocampo",
                "countryCode": "PH"
            },
            {
                "name": "Odicon",
                "countryCode": "PH"
            },
            {
                "name": "Ogod",
                "countryCode": "PH"
            },
            {
                "name": "Osiao",
                "countryCode": "PH"
            },
            {
                "name": "Osmeña",
                "countryCode": "PH"
            },
            {
                "name": "Padang",
                "countryCode": "PH"
            },
            {
                "name": "Palali",
                "countryCode": "PH"
            },
            {
                "name": "Palanas",
                "countryCode": "PH"
            },
            {
                "name": "Palestina",
                "countryCode": "PH"
            },
            {
                "name": "Palsong",
                "countryCode": "PH"
            },
            {
                "name": "Pambuhan",
                "countryCode": "PH"
            },
            {
                "name": "Pamplona",
                "countryCode": "PH"
            },
            {
                "name": "Pandan",
                "countryCode": "PH"
            },
            {
                "name": "Panganiban",
                "countryCode": "PH"
            },
            {
                "name": "Panguiranan",
                "countryCode": "PH"
            },
            {
                "name": "Paniqui",
                "countryCode": "PH"
            },
            {
                "name": "Pantao",
                "countryCode": "PH"
            },
            {
                "name": "Parabcan",
                "countryCode": "PH"
            },
            {
                "name": "Paracale",
                "countryCode": "PH"
            },
            {
                "name": "Pasacao",
                "countryCode": "PH"
            },
            {
                "name": "Paulba",
                "countryCode": "PH"
            },
            {
                "name": "Pawa",
                "countryCode": "PH"
            },
            {
                "name": "Pawican",
                "countryCode": "PH"
            },
            {
                "name": "Pawili",
                "countryCode": "PH"
            },
            {
                "name": "Peña",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pili",
                "countryCode": "PH"
            },
            {
                "name": "Pinit",
                "countryCode": "PH"
            },
            {
                "name": "Pio Duran",
                "countryCode": "PH"
            },
            {
                "name": "Placer",
                "countryCode": "PH"
            },
            {
                "name": "Polangui",
                "countryCode": "PH"
            },
            {
                "name": "Ponso",
                "countryCode": "PH"
            },
            {
                "name": "Potot",
                "countryCode": "PH"
            },
            {
                "name": "Prieto Diaz",
                "countryCode": "PH"
            },
            {
                "name": "Province of Albay",
                "countryCode": "PH"
            },
            {
                "name": "Province of Camarines Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Camarines Sur",
                "countryCode": "PH"
            },
            {
                "name": "Province of Catanduanes",
                "countryCode": "PH"
            },
            {
                "name": "Province of Masbate",
                "countryCode": "PH"
            },
            {
                "name": "Province of Sorsogon",
                "countryCode": "PH"
            },
            {
                "name": "Puro",
                "countryCode": "PH"
            },
            {
                "name": "Putiao",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quitang",
                "countryCode": "PH"
            },
            {
                "name": "Ragay",
                "countryCode": "PH"
            },
            {
                "name": "Rapu-Rapu",
                "countryCode": "PH"
            },
            {
                "name": "Recodo",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Sabang Indan",
                "countryCode": "PH"
            },
            {
                "name": "Sagnay",
                "countryCode": "PH"
            },
            {
                "name": "Sagpon",
                "countryCode": "PH"
            },
            {
                "name": "Sagrada",
                "countryCode": "PH"
            },
            {
                "name": "Sagrada Familia",
                "countryCode": "PH"
            },
            {
                "name": "Sagurong",
                "countryCode": "PH"
            },
            {
                "name": "Salingogan",
                "countryCode": "PH"
            },
            {
                "name": "Salogon",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Andres",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Felipe",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Gabriel",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jacinto",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Lucas",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pascual",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Ramon",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "San Sebastian",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Elena",
                "countryCode": "PH"
            },
            {
                "name": "Santa Justina",
                "countryCode": "PH"
            },
            {
                "name": "Santa Magdalena",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rosa Sur",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Sinuknipan",
                "countryCode": "PH"
            },
            {
                "name": "Sipocot",
                "countryCode": "PH"
            },
            {
                "name": "Siruma",
                "countryCode": "PH"
            },
            {
                "name": "Sogod",
                "countryCode": "PH"
            },
            {
                "name": "Sorsogon",
                "countryCode": "PH"
            },
            {
                "name": "Sugcad",
                "countryCode": "PH"
            },
            {
                "name": "Sugod",
                "countryCode": "PH"
            },
            {
                "name": "Tabaco",
                "countryCode": "PH"
            },
            {
                "name": "Tagas",
                "countryCode": "PH"
            },
            {
                "name": "Tagoytoy",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Talubatib",
                "countryCode": "PH"
            },
            {
                "name": "Tambo",
                "countryCode": "PH"
            },
            {
                "name": "Tara",
                "countryCode": "PH"
            },
            {
                "name": "Tariric",
                "countryCode": "PH"
            },
            {
                "name": "Tigaon",
                "countryCode": "PH"
            },
            {
                "name": "Tigbao",
                "countryCode": "PH"
            },
            {
                "name": "Tigbaw",
                "countryCode": "PH"
            },
            {
                "name": "Tigbinan",
                "countryCode": "PH"
            },
            {
                "name": "Tinago",
                "countryCode": "PH"
            },
            {
                "name": "Tinalmud",
                "countryCode": "PH"
            },
            {
                "name": "Tinambac",
                "countryCode": "PH"
            },
            {
                "name": "Tinampo",
                "countryCode": "PH"
            },
            {
                "name": "Tinawagan",
                "countryCode": "PH"
            },
            {
                "name": "Tiwi",
                "countryCode": "PH"
            },
            {
                "name": "Tubli",
                "countryCode": "PH"
            },
            {
                "name": "Tuburan",
                "countryCode": "PH"
            },
            {
                "name": "Tugos",
                "countryCode": "PH"
            },
            {
                "name": "Tulay na Lupa",
                "countryCode": "PH"
            },
            {
                "name": "Tumalaytay",
                "countryCode": "PH"
            },
            {
                "name": "Umabay",
                "countryCode": "PH"
            },
            {
                "name": "Usab",
                "countryCode": "PH"
            },
            {
                "name": "Uson",
                "countryCode": "PH"
            },
            {
                "name": "Utabi",
                "countryCode": "PH"
            },
            {
                "name": "Viga",
                "countryCode": "PH"
            },
            {
                "name": "Villahermosa",
                "countryCode": "PH"
            },
            {
                "name": "Vinzons",
                "countryCode": "PH"
            },
            {
                "name": "Virac",
                "countryCode": "PH"
            },
            {
                "name": "Abaca",
                "countryCode": "PH"
            },
            {
                "name": "Abangay",
                "countryCode": "PH"
            },
            {
                "name": "Abiera",
                "countryCode": "PH"
            },
            {
                "name": "Abilay",
                "countryCode": "PH"
            },
            {
                "name": "Ag-ambulong",
                "countryCode": "PH"
            },
            {
                "name": "Aganan",
                "countryCode": "PH"
            },
            {
                "name": "Aglalana",
                "countryCode": "PH"
            },
            {
                "name": "Agpangi",
                "countryCode": "PH"
            },
            {
                "name": "Aguisan",
                "countryCode": "PH"
            },
            {
                "name": "Ajuy",
                "countryCode": "PH"
            },
            {
                "name": "Alacaygan",
                "countryCode": "PH"
            },
            {
                "name": "Alegria",
                "countryCode": "PH"
            },
            {
                "name": "Alibunan",
                "countryCode": "PH"
            },
            {
                "name": "Alicante",
                "countryCode": "PH"
            },
            {
                "name": "Alijis",
                "countryCode": "PH"
            },
            {
                "name": "Alim",
                "countryCode": "PH"
            },
            {
                "name": "Alimodian",
                "countryCode": "PH"
            },
            {
                "name": "Alimono",
                "countryCode": "PH"
            },
            {
                "name": "Altavas",
                "countryCode": "PH"
            },
            {
                "name": "Ambulong",
                "countryCode": "PH"
            },
            {
                "name": "Andres Bonifacio",
                "countryCode": "PH"
            },
            {
                "name": "Anini-y",
                "countryCode": "PH"
            },
            {
                "name": "Anoring",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Aquino",
                "countryCode": "PH"
            },
            {
                "name": "Araal",
                "countryCode": "PH"
            },
            {
                "name": "Aranas Sur",
                "countryCode": "PH"
            },
            {
                "name": "Aranda",
                "countryCode": "PH"
            },
            {
                "name": "Arcangel",
                "countryCode": "PH"
            },
            {
                "name": "Asia",
                "countryCode": "PH"
            },
            {
                "name": "Asturga",
                "countryCode": "PH"
            },
            {
                "name": "Atabayan",
                "countryCode": "PH"
            },
            {
                "name": "Atipuluhan",
                "countryCode": "PH"
            },
            {
                "name": "Aurelliana",
                "countryCode": "PH"
            },
            {
                "name": "Avila",
                "countryCode": "PH"
            },
            {
                "name": "Bacalan",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod City",
                "countryCode": "PH"
            },
            {
                "name": "Bacuyangan",
                "countryCode": "PH"
            },
            {
                "name": "Badiangan",
                "countryCode": "PH"
            },
            {
                "name": "Badlan",
                "countryCode": "PH"
            },
            {
                "name": "Bago City",
                "countryCode": "PH"
            },
            {
                "name": "Bagroy",
                "countryCode": "PH"
            },
            {
                "name": "Bagumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Bailan",
                "countryCode": "PH"
            },
            {
                "name": "Balabag",
                "countryCode": "PH"
            },
            {
                "name": "Balasan",
                "countryCode": "PH"
            },
            {
                "name": "Balete",
                "countryCode": "PH"
            },
            {
                "name": "Balibagan Oeste",
                "countryCode": "PH"
            },
            {
                "name": "Baliwagan",
                "countryCode": "PH"
            },
            {
                "name": "Bancal",
                "countryCode": "PH"
            },
            {
                "name": "Banga",
                "countryCode": "PH"
            },
            {
                "name": "Barbaza",
                "countryCode": "PH"
            },
            {
                "name": "Barotac Nuevo",
                "countryCode": "PH"
            },
            {
                "name": "Barotac Viejo",
                "countryCode": "PH"
            },
            {
                "name": "Basiao",
                "countryCode": "PH"
            },
            {
                "name": "Batad",
                "countryCode": "PH"
            },
            {
                "name": "Batan",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Bay-ang",
                "countryCode": "PH"
            },
            {
                "name": "Bayas",
                "countryCode": "PH"
            },
            {
                "name": "Belison",
                "countryCode": "PH"
            },
            {
                "name": "Biao",
                "countryCode": "PH"
            },
            {
                "name": "Bilao",
                "countryCode": "PH"
            },
            {
                "name": "Binabaan",
                "countryCode": "PH"
            },
            {
                "name": "Binalbagan",
                "countryCode": "PH"
            },
            {
                "name": "Binantocan",
                "countryCode": "PH"
            },
            {
                "name": "Bingawan",
                "countryCode": "PH"
            },
            {
                "name": "Binon-an",
                "countryCode": "PH"
            },
            {
                "name": "Binonga",
                "countryCode": "PH"
            },
            {
                "name": "Bitadtun",
                "countryCode": "PH"
            },
            {
                "name": "Bocana",
                "countryCode": "PH"
            },
            {
                "name": "Bolanon",
                "countryCode": "PH"
            },
            {
                "name": "Bolilao",
                "countryCode": "PH"
            },
            {
                "name": "Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Bolong",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. Bachaw Norte, Kalibo",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. Bulwang, Numancia",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. Mabilo, New Washington",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. Nalook, kalibo",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. New Buswang, Kalibo",
                "countryCode": "PH"
            },
            {
                "name": "Brgy. Tinigao, Kalibo",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Buga",
                "countryCode": "PH"
            },
            {
                "name": "Bugang",
                "countryCode": "PH"
            },
            {
                "name": "Bugasong",
                "countryCode": "PH"
            },
            {
                "name": "Bula",
                "countryCode": "PH"
            },
            {
                "name": "Bulad",
                "countryCode": "PH"
            },
            {
                "name": "Bulata",
                "countryCode": "PH"
            },
            {
                "name": "Buluangan",
                "countryCode": "PH"
            },
            {
                "name": "Bungsuan",
                "countryCode": "PH"
            },
            {
                "name": "Buray",
                "countryCode": "PH"
            },
            {
                "name": "Burias",
                "countryCode": "PH"
            },
            {
                "name": "Buruanga",
                "countryCode": "PH"
            },
            {
                "name": "Busay",
                "countryCode": "PH"
            },
            {
                "name": "Buyuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabacungan",
                "countryCode": "PH"
            },
            {
                "name": "Cabadiangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabanbanan",
                "countryCode": "PH"
            },
            {
                "name": "Cabano",
                "countryCode": "PH"
            },
            {
                "name": "Cabatuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabilao",
                "countryCode": "PH"
            },
            {
                "name": "Cabilauan",
                "countryCode": "PH"
            },
            {
                "name": "Cabugao",
                "countryCode": "PH"
            },
            {
                "name": "Cadagmayan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Cadiz",
                "countryCode": "PH"
            },
            {
                "name": "Cagbang",
                "countryCode": "PH"
            },
            {
                "name": "Calampisauan",
                "countryCode": "PH"
            },
            {
                "name": "Calape",
                "countryCode": "PH"
            },
            {
                "name": "Calatrava",
                "countryCode": "PH"
            },
            {
                "name": "Calaya",
                "countryCode": "PH"
            },
            {
                "name": "Calinog",
                "countryCode": "PH"
            },
            {
                "name": "Calizo",
                "countryCode": "PH"
            },
            {
                "name": "Caluya",
                "countryCode": "PH"
            },
            {
                "name": "Camalobalo",
                "countryCode": "PH"
            },
            {
                "name": "Camandag",
                "countryCode": "PH"
            },
            {
                "name": "Camangcamang",
                "countryCode": "PH"
            },
            {
                "name": "Camindangan",
                "countryCode": "PH"
            },
            {
                "name": "Camingawan",
                "countryCode": "PH"
            },
            {
                "name": "Candelaria",
                "countryCode": "PH"
            },
            {
                "name": "Candoni",
                "countryCode": "PH"
            },
            {
                "name": "Caningay",
                "countryCode": "PH"
            },
            {
                "name": "Canroma",
                "countryCode": "PH"
            },
            {
                "name": "Cansilayan",
                "countryCode": "PH"
            },
            {
                "name": "Cansolungon",
                "countryCode": "PH"
            },
            {
                "name": "Canturay",
                "countryCode": "PH"
            },
            {
                "name": "Capaga",
                "countryCode": "PH"
            },
            {
                "name": "Capitan Ramon",
                "countryCode": "PH"
            },
            {
                "name": "Carabalan",
                "countryCode": "PH"
            },
            {
                "name": "Caridad",
                "countryCode": "PH"
            },
            {
                "name": "Carles",
                "countryCode": "PH"
            },
            {
                "name": "Carmelo",
                "countryCode": "PH"
            },
            {
                "name": "Carmen Grande",
                "countryCode": "PH"
            },
            {
                "name": "Cartagena",
                "countryCode": "PH"
            },
            {
                "name": "Cassanayan",
                "countryCode": "PH"
            },
            {
                "name": "Caticlan",
                "countryCode": "PH"
            },
            {
                "name": "Catungan",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan",
                "countryCode": "PH"
            },
            {
                "name": "Cayanguan",
                "countryCode": "PH"
            },
            {
                "name": "Cayhagan",
                "countryCode": "PH"
            },
            {
                "name": "Cervantes",
                "countryCode": "PH"
            },
            {
                "name": "Chambrey",
                "countryCode": "PH"
            },
            {
                "name": "Codcod",
                "countryCode": "PH"
            },
            {
                "name": "Cogon",
                "countryCode": "PH"
            },
            {
                "name": "Colipapa",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Concordia",
                "countryCode": "PH"
            },
            {
                "name": "Consolacion",
                "countryCode": "PH"
            },
            {
                "name": "Constancia",
                "countryCode": "PH"
            },
            {
                "name": "Consuelo",
                "countryCode": "PH"
            },
            {
                "name": "Cordova",
                "countryCode": "PH"
            },
            {
                "name": "Cortez",
                "countryCode": "PH"
            },
            {
                "name": "Cuartero",
                "countryCode": "PH"
            },
            {
                "name": "Culasi",
                "countryCode": "PH"
            },
            {
                "name": "Da-an Sur",
                "countryCode": "PH"
            },
            {
                "name": "Daliciasao",
                "countryCode": "PH"
            },
            {
                "name": "Damayan",
                "countryCode": "PH"
            },
            {
                "name": "Dancalan",
                "countryCode": "PH"
            },
            {
                "name": "Dao",
                "countryCode": "PH"
            },
            {
                "name": "Dapdapan",
                "countryCode": "PH"
            },
            {
                "name": "De la Paz",
                "countryCode": "PH"
            },
            {
                "name": "Dian-ay",
                "countryCode": "PH"
            },
            {
                "name": "Dingle",
                "countryCode": "PH"
            },
            {
                "name": "Dos Hermanas",
                "countryCode": "PH"
            },
            {
                "name": "Dueñas",
                "countryCode": "PH"
            },
            {
                "name": "Dulangan",
                "countryCode": "PH"
            },
            {
                "name": "Dulao",
                "countryCode": "PH"
            },
            {
                "name": "Dumalag",
                "countryCode": "PH"
            },
            {
                "name": "Dumangas",
                "countryCode": "PH"
            },
            {
                "name": "Dumarao",
                "countryCode": "PH"
            },
            {
                "name": "Dungon",
                "countryCode": "PH"
            },
            {
                "name": "Duran",
                "countryCode": "PH"
            },
            {
                "name": "East Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Egaña",
                "countryCode": "PH"
            },
            {
                "name": "Ermita",
                "countryCode": "PH"
            },
            {
                "name": "Escalante",
                "countryCode": "PH"
            },
            {
                "name": "Estancia",
                "countryCode": "PH"
            },
            {
                "name": "Eustaquio Lopez",
                "countryCode": "PH"
            },
            {
                "name": "Feliciano",
                "countryCode": "PH"
            },
            {
                "name": "Gabi",
                "countryCode": "PH"
            },
            {
                "name": "Getulio",
                "countryCode": "PH"
            },
            {
                "name": "Gibato",
                "countryCode": "PH"
            },
            {
                "name": "Gibong",
                "countryCode": "PH"
            },
            {
                "name": "Gines-Patay",
                "countryCode": "PH"
            },
            {
                "name": "Granada",
                "countryCode": "PH"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PH"
            },
            {
                "name": "Guiljungan",
                "countryCode": "PH"
            },
            {
                "name": "Guimbal",
                "countryCode": "PH"
            },
            {
                "name": "Guinoaliuan",
                "countryCode": "PH"
            },
            {
                "name": "Guinticgan",
                "countryCode": "PH"
            },
            {
                "name": "Guintubhan",
                "countryCode": "PH"
            },
            {
                "name": "Guisijan",
                "countryCode": "PH"
            },
            {
                "name": "Hacienda Refugio",
                "countryCode": "PH"
            },
            {
                "name": "Hacienda Santa Rosa",
                "countryCode": "PH"
            },
            {
                "name": "Haguimit",
                "countryCode": "PH"
            },
            {
                "name": "Hamtic",
                "countryCode": "PH"
            },
            {
                "name": "Himamaylan",
                "countryCode": "PH"
            },
            {
                "name": "Himaya",
                "countryCode": "PH"
            },
            {
                "name": "Hinigaran",
                "countryCode": "PH"
            },
            {
                "name": "Hipona",
                "countryCode": "PH"
            },
            {
                "name": "Ibajay",
                "countryCode": "PH"
            },
            {
                "name": "Idio",
                "countryCode": "PH"
            },
            {
                "name": "Igang",
                "countryCode": "PH"
            },
            {
                "name": "Igbaras",
                "countryCode": "PH"
            },
            {
                "name": "Igbon",
                "countryCode": "PH"
            },
            {
                "name": "Igcocolo",
                "countryCode": "PH"
            },
            {
                "name": "Igmaya-an",
                "countryCode": "PH"
            },
            {
                "name": "Ilog",
                "countryCode": "PH"
            },
            {
                "name": "Iloilo",
                "countryCode": "PH"
            },
            {
                "name": "Imbang",
                "countryCode": "PH"
            },
            {
                "name": "Inayauan",
                "countryCode": "PH"
            },
            {
                "name": "Intampilan",
                "countryCode": "PH"
            },
            {
                "name": "Isabela",
                "countryCode": "PH"
            },
            {
                "name": "Ivisan",
                "countryCode": "PH"
            },
            {
                "name": "Jaena",
                "countryCode": "PH"
            },
            {
                "name": "Jaguimitan",
                "countryCode": "PH"
            },
            {
                "name": "Jalaud",
                "countryCode": "PH"
            },
            {
                "name": "Jamabalod",
                "countryCode": "PH"
            },
            {
                "name": "Jamindan",
                "countryCode": "PH"
            },
            {
                "name": "Janiuay",
                "countryCode": "PH"
            },
            {
                "name": "Japitan",
                "countryCode": "PH"
            },
            {
                "name": "Jarigue",
                "countryCode": "PH"
            },
            {
                "name": "Jayubó",
                "countryCode": "PH"
            },
            {
                "name": "Jibao-an",
                "countryCode": "PH"
            },
            {
                "name": "Jordan",
                "countryCode": "PH"
            },
            {
                "name": "Kabankalan",
                "countryCode": "PH"
            },
            {
                "name": "Kabilauan",
                "countryCode": "PH"
            },
            {
                "name": "Kalibo (poblacion)",
                "countryCode": "PH"
            },
            {
                "name": "Kaliling",
                "countryCode": "PH"
            },
            {
                "name": "Kumalisquis",
                "countryCode": "PH"
            },
            {
                "name": "La Carlota",
                "countryCode": "PH"
            },
            {
                "name": "La Castellana",
                "countryCode": "PH"
            },
            {
                "name": "La Granja",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lacaron",
                "countryCode": "PH"
            },
            {
                "name": "Lalab",
                "countryCode": "PH"
            },
            {
                "name": "Lalagsan",
                "countryCode": "PH"
            },
            {
                "name": "Lambunao",
                "countryCode": "PH"
            },
            {
                "name": "Lanot",
                "countryCode": "PH"
            },
            {
                "name": "Lantangan",
                "countryCode": "PH"
            },
            {
                "name": "Lawigan",
                "countryCode": "PH"
            },
            {
                "name": "Lañgub",
                "countryCode": "PH"
            },
            {
                "name": "Leganes",
                "countryCode": "PH"
            },
            {
                "name": "Lemery",
                "countryCode": "PH"
            },
            {
                "name": "Leon",
                "countryCode": "PH"
            },
            {
                "name": "Libacao",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Linabuan",
                "countryCode": "PH"
            },
            {
                "name": "Linabuan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Linaon",
                "countryCode": "PH"
            },
            {
                "name": "Locmayan",
                "countryCode": "PH"
            },
            {
                "name": "Lono",
                "countryCode": "PH"
            },
            {
                "name": "Lonoy",
                "countryCode": "PH"
            },
            {
                "name": "Lopez Jaena",
                "countryCode": "PH"
            },
            {
                "name": "Lucena",
                "countryCode": "PH"
            },
            {
                "name": "Lucero",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lupo",
                "countryCode": "PH"
            },
            {
                "name": "Maao",
                "countryCode": "PH"
            },
            {
                "name": "Maasin",
                "countryCode": "PH"
            },
            {
                "name": "Maayon",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Madalag",
                "countryCode": "PH"
            },
            {
                "name": "Magallon Cadre",
                "countryCode": "PH"
            },
            {
                "name": "Magdalena",
                "countryCode": "PH"
            },
            {
                "name": "Makato",
                "countryCode": "PH"
            },
            {
                "name": "Malabonot",
                "countryCode": "PH"
            },
            {
                "name": "Malabor",
                "countryCode": "PH"
            },
            {
                "name": "Malangabang",
                "countryCode": "PH"
            },
            {
                "name": "Malay",
                "countryCode": "PH"
            },
            {
                "name": "Malayo-an",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malocloc",
                "countryCode": "PH"
            },
            {
                "name": "Maloco",
                "countryCode": "PH"
            },
            {
                "name": "Mambagatan",
                "countryCode": "PH"
            },
            {
                "name": "Mambusao",
                "countryCode": "PH"
            },
            {
                "name": "Manalad",
                "countryCode": "PH"
            },
            {
                "name": "Manapla",
                "countryCode": "PH"
            },
            {
                "name": "Mangoso",
                "countryCode": "PH"
            },
            {
                "name": "Manika",
                "countryCode": "PH"
            },
            {
                "name": "Manjoy",
                "countryCode": "PH"
            },
            {
                "name": "Manlucahoc",
                "countryCode": "PH"
            },
            {
                "name": "Manoc-Manoc",
                "countryCode": "PH"
            },
            {
                "name": "Mansilingan",
                "countryCode": "PH"
            },
            {
                "name": "Manup",
                "countryCode": "PH"
            },
            {
                "name": "Mapili",
                "countryCode": "PH"
            },
            {
                "name": "Maquiling",
                "countryCode": "PH"
            },
            {
                "name": "Marawis",
                "countryCode": "PH"
            },
            {
                "name": "Maribong",
                "countryCode": "PH"
            },
            {
                "name": "Maricalom",
                "countryCode": "PH"
            },
            {
                "name": "Masaling",
                "countryCode": "PH"
            },
            {
                "name": "Masonogan",
                "countryCode": "PH"
            },
            {
                "name": "Miagao",
                "countryCode": "PH"
            },
            {
                "name": "Mianay",
                "countryCode": "PH"
            },
            {
                "name": "Mina",
                "countryCode": "PH"
            },
            {
                "name": "Minapasoc",
                "countryCode": "PH"
            },
            {
                "name": "Minuyan",
                "countryCode": "PH"
            },
            {
                "name": "Miranda",
                "countryCode": "PH"
            },
            {
                "name": "Moises Padilla",
                "countryCode": "PH"
            },
            {
                "name": "Monpon",
                "countryCode": "PH"
            },
            {
                "name": "Montilla",
                "countryCode": "PH"
            },
            {
                "name": "Morales",
                "countryCode": "PH"
            },
            {
                "name": "Morobuan",
                "countryCode": "PH"
            },
            {
                "name": "Murcia",
                "countryCode": "PH"
            },
            {
                "name": "Nabas",
                "countryCode": "PH"
            },
            {
                "name": "Nabulao",
                "countryCode": "PH"
            },
            {
                "name": "Naili",
                "countryCode": "PH"
            },
            {
                "name": "Naisud",
                "countryCode": "PH"
            },
            {
                "name": "Nangka",
                "countryCode": "PH"
            },
            {
                "name": "Napnapan",
                "countryCode": "PH"
            },
            {
                "name": "Napoles",
                "countryCode": "PH"
            },
            {
                "name": "Nato",
                "countryCode": "PH"
            },
            {
                "name": "New Pandanon",
                "countryCode": "PH"
            },
            {
                "name": "New Washington",
                "countryCode": "PH"
            },
            {
                "name": "Nueva Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Numancia",
                "countryCode": "PH"
            },
            {
                "name": "Ochanado",
                "countryCode": "PH"
            },
            {
                "name": "Odiong",
                "countryCode": "PH"
            },
            {
                "name": "Ogtongon",
                "countryCode": "PH"
            },
            {
                "name": "Ondoy",
                "countryCode": "PH"
            },
            {
                "name": "Oracon",
                "countryCode": "PH"
            },
            {
                "name": "Orong",
                "countryCode": "PH"
            },
            {
                "name": "Oton",
                "countryCode": "PH"
            },
            {
                "name": "Pacol",
                "countryCode": "PH"
            },
            {
                "name": "Pakiad",
                "countryCode": "PH"
            },
            {
                "name": "Palampas",
                "countryCode": "PH"
            },
            {
                "name": "Panay",
                "countryCode": "PH"
            },
            {
                "name": "Panayacan",
                "countryCode": "PH"
            },
            {
                "name": "Pandan",
                "countryCode": "PH"
            },
            {
                "name": "Panitan",
                "countryCode": "PH"
            },
            {
                "name": "Paraiso",
                "countryCode": "PH"
            },
            {
                "name": "Parion",
                "countryCode": "PH"
            },
            {
                "name": "Pasil",
                "countryCode": "PH"
            },
            {
                "name": "Passi",
                "countryCode": "PH"
            },
            {
                "name": "Patique",
                "countryCode": "PH"
            },
            {
                "name": "Patnongon",
                "countryCode": "PH"
            },
            {
                "name": "Patonan",
                "countryCode": "PH"
            },
            {
                "name": "Patria",
                "countryCode": "PH"
            },
            {
                "name": "Pavia",
                "countryCode": "PH"
            },
            {
                "name": "Pawa",
                "countryCode": "PH"
            },
            {
                "name": "Payao",
                "countryCode": "PH"
            },
            {
                "name": "Piape I",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pili",
                "countryCode": "PH"
            },
            {
                "name": "Piña",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Platagata",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Polopina",
                "countryCode": "PH"
            },
            {
                "name": "Ponong",
                "countryCode": "PH"
            },
            {
                "name": "Pontevedra",
                "countryCode": "PH"
            },
            {
                "name": "Pototan",
                "countryCode": "PH"
            },
            {
                "name": "President Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Prosperidad",
                "countryCode": "PH"
            },
            {
                "name": "Province of Aklan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Antique",
                "countryCode": "PH"
            },
            {
                "name": "Province of Capiz",
                "countryCode": "PH"
            },
            {
                "name": "Province of Guimaras",
                "countryCode": "PH"
            },
            {
                "name": "Province of Iloilo",
                "countryCode": "PH"
            },
            {
                "name": "Province of Negros Occidental",
                "countryCode": "PH"
            },
            {
                "name": "Pulupandan",
                "countryCode": "PH"
            },
            {
                "name": "Punao",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quinagaringan",
                "countryCode": "PH"
            },
            {
                "name": "Quipot",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Roxas City",
                "countryCode": "PH"
            },
            {
                "name": "Sagang",
                "countryCode": "PH"
            },
            {
                "name": "Sagasa",
                "countryCode": "PH"
            },
            {
                "name": "Sagay",
                "countryCode": "PH"
            },
            {
                "name": "Salamanca",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Dionisio",
                "countryCode": "PH"
            },
            {
                "name": "San Enrique",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Joaquin",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Remigio",
                "countryCode": "PH"
            },
            {
                "name": "San Salvador",
                "countryCode": "PH"
            },
            {
                "name": "Santa Angel",
                "countryCode": "PH"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Monica",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santol",
                "countryCode": "PH"
            },
            {
                "name": "Sapian",
                "countryCode": "PH"
            },
            {
                "name": "Sara",
                "countryCode": "PH"
            },
            {
                "name": "Saravia",
                "countryCode": "PH"
            },
            {
                "name": "Sebaste",
                "countryCode": "PH"
            },
            {
                "name": "Semirara",
                "countryCode": "PH"
            },
            {
                "name": "Sibaguan",
                "countryCode": "PH"
            },
            {
                "name": "Sibalom",
                "countryCode": "PH"
            },
            {
                "name": "Sibucao",
                "countryCode": "PH"
            },
            {
                "name": "Sigma",
                "countryCode": "PH"
            },
            {
                "name": "Sipalay",
                "countryCode": "PH"
            },
            {
                "name": "Suay",
                "countryCode": "PH"
            },
            {
                "name": "Sulangan",
                "countryCode": "PH"
            },
            {
                "name": "Sumag",
                "countryCode": "PH"
            },
            {
                "name": "Tabu",
                "countryCode": "PH"
            },
            {
                "name": "Tabuc Pontevedra",
                "countryCode": "PH"
            },
            {
                "name": "Talaban",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Taloc",
                "countryCode": "PH"
            },
            {
                "name": "Talokgañgan",
                "countryCode": "PH"
            },
            {
                "name": "Talon",
                "countryCode": "PH"
            },
            {
                "name": "Tambac",
                "countryCode": "PH"
            },
            {
                "name": "Tambalisa",
                "countryCode": "PH"
            },
            {
                "name": "Tamlang",
                "countryCode": "PH"
            },
            {
                "name": "Tangalan",
                "countryCode": "PH"
            },
            {
                "name": "Tanza",
                "countryCode": "PH"
            },
            {
                "name": "Tapas",
                "countryCode": "PH"
            },
            {
                "name": "Tarong",
                "countryCode": "PH"
            },
            {
                "name": "Tibiao",
                "countryCode": "PH"
            },
            {
                "name": "Tigbauan",
                "countryCode": "PH"
            },
            {
                "name": "Tiglauigan",
                "countryCode": "PH"
            },
            {
                "name": "Tigum",
                "countryCode": "PH"
            },
            {
                "name": "Tiling",
                "countryCode": "PH"
            },
            {
                "name": "Timpas",
                "countryCode": "PH"
            },
            {
                "name": "Tinogboc",
                "countryCode": "PH"
            },
            {
                "name": "Tinongan",
                "countryCode": "PH"
            },
            {
                "name": "Tiring",
                "countryCode": "PH"
            },
            {
                "name": "Tiwi",
                "countryCode": "PH"
            },
            {
                "name": "Tobias Fornier",
                "countryCode": "PH"
            },
            {
                "name": "Toboso",
                "countryCode": "PH"
            },
            {
                "name": "Tortosa",
                "countryCode": "PH"
            },
            {
                "name": "Trapiche",
                "countryCode": "PH"
            },
            {
                "name": "Tugas",
                "countryCode": "PH"
            },
            {
                "name": "Tumcon Ilawod",
                "countryCode": "PH"
            },
            {
                "name": "Tuyum",
                "countryCode": "PH"
            },
            {
                "name": "Ualog",
                "countryCode": "PH"
            },
            {
                "name": "Ungca",
                "countryCode": "PH"
            },
            {
                "name": "Unidos",
                "countryCode": "PH"
            },
            {
                "name": "Union",
                "countryCode": "PH"
            },
            {
                "name": "Valderrama",
                "countryCode": "PH"
            },
            {
                "name": "Valladolid",
                "countryCode": "PH"
            },
            {
                "name": "Victorias",
                "countryCode": "PH"
            },
            {
                "name": "Viejo Daan Banua",
                "countryCode": "PH"
            },
            {
                "name": "Vista Alegre",
                "countryCode": "PH"
            },
            {
                "name": "Vito",
                "countryCode": "PH"
            },
            {
                "name": "Yapak",
                "countryCode": "PH"
            },
            {
                "name": "Yubo",
                "countryCode": "PH"
            },
            {
                "name": "Zarraga",
                "countryCode": "PH"
            },
            {
                "name": "Abaca",
                "countryCode": "PH"
            },
            {
                "name": "Abis",
                "countryCode": "PH"
            },
            {
                "name": "Abucayan",
                "countryCode": "PH"
            },
            {
                "name": "Adlaon",
                "countryCode": "PH"
            },
            {
                "name": "Agsungot",
                "countryCode": "PH"
            },
            {
                "name": "Aguining",
                "countryCode": "PH"
            },
            {
                "name": "Alangilan",
                "countryCode": "PH"
            },
            {
                "name": "Alangilanan",
                "countryCode": "PH"
            },
            {
                "name": "Alburquerque",
                "countryCode": "PH"
            },
            {
                "name": "Alcantara",
                "countryCode": "PH"
            },
            {
                "name": "Alcoy",
                "countryCode": "PH"
            },
            {
                "name": "Alegria",
                "countryCode": "PH"
            },
            {
                "name": "Alicia",
                "countryCode": "PH"
            },
            {
                "name": "Aloguinsan",
                "countryCode": "PH"
            },
            {
                "name": "Alpaco",
                "countryCode": "PH"
            },
            {
                "name": "Amdos",
                "countryCode": "PH"
            },
            {
                "name": "Amio",
                "countryCode": "PH"
            },
            {
                "name": "Anda",
                "countryCode": "PH"
            },
            {
                "name": "Anonang",
                "countryCode": "PH"
            },
            {
                "name": "Anopog",
                "countryCode": "PH"
            },
            {
                "name": "Antequera",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Apas",
                "countryCode": "PH"
            },
            {
                "name": "Apoya",
                "countryCode": "PH"
            },
            {
                "name": "Argao",
                "countryCode": "PH"
            },
            {
                "name": "Asturias",
                "countryCode": "PH"
            },
            {
                "name": "Atop-atop",
                "countryCode": "PH"
            },
            {
                "name": "Ayungon",
                "countryCode": "PH"
            },
            {
                "name": "Azagra",
                "countryCode": "PH"
            },
            {
                "name": "Bachauan",
                "countryCode": "PH"
            },
            {
                "name": "Baclayon",
                "countryCode": "PH"
            },
            {
                "name": "Bacong",
                "countryCode": "PH"
            },
            {
                "name": "Badian",
                "countryCode": "PH"
            },
            {
                "name": "Bagacay",
                "countryCode": "PH"
            },
            {
                "name": "Bagay",
                "countryCode": "PH"
            },
            {
                "name": "Bagtic",
                "countryCode": "PH"
            },
            {
                "name": "Bairan",
                "countryCode": "PH"
            },
            {
                "name": "Bais",
                "countryCode": "PH"
            },
            {
                "name": "Bal-os",
                "countryCode": "PH"
            },
            {
                "name": "Balamban",
                "countryCode": "PH"
            },
            {
                "name": "Balayong",
                "countryCode": "PH"
            },
            {
                "name": "Balilihan",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balud",
                "countryCode": "PH"
            },
            {
                "name": "Banhigan",
                "countryCode": "PH"
            },
            {
                "name": "Banilad",
                "countryCode": "PH"
            },
            {
                "name": "Bantayan",
                "countryCode": "PH"
            },
            {
                "name": "Barili",
                "countryCode": "PH"
            },
            {
                "name": "Basak",
                "countryCode": "PH"
            },
            {
                "name": "Basay",
                "countryCode": "PH"
            },
            {
                "name": "Basdiot",
                "countryCode": "PH"
            },
            {
                "name": "Bateria",
                "countryCode": "PH"
            },
            {
                "name": "Batuan",
                "countryCode": "PH"
            },
            {
                "name": "Baud",
                "countryCode": "PH"
            },
            {
                "name": "Baugo",
                "countryCode": "PH"
            },
            {
                "name": "Bayawan",
                "countryCode": "PH"
            },
            {
                "name": "Becerril",
                "countryCode": "PH"
            },
            {
                "name": "Biabas",
                "countryCode": "PH"
            },
            {
                "name": "Biasong",
                "countryCode": "PH"
            },
            {
                "name": "Bien Unido",
                "countryCode": "PH"
            },
            {
                "name": "Biking",
                "countryCode": "PH"
            },
            {
                "name": "Bilar",
                "countryCode": "PH"
            },
            {
                "name": "Binlod",
                "countryCode": "PH"
            },
            {
                "name": "Biton",
                "countryCode": "PH"
            },
            {
                "name": "Bitoon",
                "countryCode": "PH"
            },
            {
                "name": "Bogo",
                "countryCode": "PH"
            },
            {
                "name": "Bohol",
                "countryCode": "PH"
            },
            {
                "name": "Bolisong",
                "countryCode": "PH"
            },
            {
                "name": "Boljoon",
                "countryCode": "PH"
            },
            {
                "name": "Bonawon",
                "countryCode": "PH"
            },
            {
                "name": "Bonbon",
                "countryCode": "PH"
            },
            {
                "name": "Bood",
                "countryCode": "PH"
            },
            {
                "name": "Borbon",
                "countryCode": "PH"
            },
            {
                "name": "Botigues",
                "countryCode": "PH"
            },
            {
                "name": "Buagsong",
                "countryCode": "PH"
            },
            {
                "name": "Buanoy",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Bugas",
                "countryCode": "PH"
            },
            {
                "name": "Bugsoc",
                "countryCode": "PH"
            },
            {
                "name": "Bulasa",
                "countryCode": "PH"
            },
            {
                "name": "Bulod",
                "countryCode": "PH"
            },
            {
                "name": "Cabalawan",
                "countryCode": "PH"
            },
            {
                "name": "Cabangahan",
                "countryCode": "PH"
            },
            {
                "name": "Cabul-an",
                "countryCode": "PH"
            },
            {
                "name": "Calamba",
                "countryCode": "PH"
            },
            {
                "name": "Calape",
                "countryCode": "PH"
            },
            {
                "name": "Calero",
                "countryCode": "PH"
            },
            {
                "name": "Calidñgan",
                "countryCode": "PH"
            },
            {
                "name": "Calituban",
                "countryCode": "PH"
            },
            {
                "name": "Calumboyan",
                "countryCode": "PH"
            },
            {
                "name": "Camambugan",
                "countryCode": "PH"
            },
            {
                "name": "Cambanay",
                "countryCode": "PH"
            },
            {
                "name": "Campoyo",
                "countryCode": "PH"
            },
            {
                "name": "Campusong",
                "countryCode": "PH"
            },
            {
                "name": "Can-asujan",
                "countryCode": "PH"
            },
            {
                "name": "Canauay",
                "countryCode": "PH"
            },
            {
                "name": "Candabong",
                "countryCode": "PH"
            },
            {
                "name": "Candijay",
                "countryCode": "PH"
            },
            {
                "name": "Canhaway",
                "countryCode": "PH"
            },
            {
                "name": "Canjulao",
                "countryCode": "PH"
            },
            {
                "name": "Canlaon",
                "countryCode": "PH"
            },
            {
                "name": "Canmaya Diot",
                "countryCode": "PH"
            },
            {
                "name": "Cansuje",
                "countryCode": "PH"
            },
            {
                "name": "Cantao-an",
                "countryCode": "PH"
            },
            {
                "name": "Carcar",
                "countryCode": "PH"
            },
            {
                "name": "Carmelo",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Casala-an",
                "countryCode": "PH"
            },
            {
                "name": "Casay",
                "countryCode": "PH"
            },
            {
                "name": "Catarman",
                "countryCode": "PH"
            },
            {
                "name": "Caticugan",
                "countryCode": "PH"
            },
            {
                "name": "Catigbian",
                "countryCode": "PH"
            },
            {
                "name": "Catmon",
                "countryCode": "PH"
            },
            {
                "name": "Catmondaan",
                "countryCode": "PH"
            },
            {
                "name": "Catungawan Sur",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan",
                "countryCode": "PH"
            },
            {
                "name": "Cayang",
                "countryCode": "PH"
            },
            {
                "name": "Cebu City",
                "countryCode": "PH"
            },
            {
                "name": "Clarin",
                "countryCode": "PH"
            },
            {
                "name": "Cogan",
                "countryCode": "PH"
            },
            {
                "name": "Cogon",
                "countryCode": "PH"
            },
            {
                "name": "Cogon Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Cogtong",
                "countryCode": "PH"
            },
            {
                "name": "Colonia",
                "countryCode": "PH"
            },
            {
                "name": "Compostela",
                "countryCode": "PH"
            },
            {
                "name": "Consolacion",
                "countryCode": "PH"
            },
            {
                "name": "Consuelo",
                "countryCode": "PH"
            },
            {
                "name": "Cordova",
                "countryCode": "PH"
            },
            {
                "name": "Corella",
                "countryCode": "PH"
            },
            {
                "name": "Cortes",
                "countryCode": "PH"
            },
            {
                "name": "Daanbantayan",
                "countryCode": "PH"
            },
            {
                "name": "Dagohoy",
                "countryCode": "PH"
            },
            {
                "name": "Dalaguete",
                "countryCode": "PH"
            },
            {
                "name": "Damolog",
                "countryCode": "PH"
            },
            {
                "name": "Danao",
                "countryCode": "PH"
            },
            {
                "name": "Dapitan",
                "countryCode": "PH"
            },
            {
                "name": "Datagon",
                "countryCode": "PH"
            },
            {
                "name": "Dauin",
                "countryCode": "PH"
            },
            {
                "name": "Dauis",
                "countryCode": "PH"
            },
            {
                "name": "De la Paz",
                "countryCode": "PH"
            },
            {
                "name": "Dimiao",
                "countryCode": "PH"
            },
            {
                "name": "Doljo",
                "countryCode": "PH"
            },
            {
                "name": "Doong",
                "countryCode": "PH"
            },
            {
                "name": "Duero",
                "countryCode": "PH"
            },
            {
                "name": "Dumaguete",
                "countryCode": "PH"
            },
            {
                "name": "Dumanjog",
                "countryCode": "PH"
            },
            {
                "name": "El Pardo",
                "countryCode": "PH"
            },
            {
                "name": "Enrique Villanueva",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Estaca",
                "countryCode": "PH"
            },
            {
                "name": "Gabi",
                "countryCode": "PH"
            },
            {
                "name": "Garcia Hernandez",
                "countryCode": "PH"
            },
            {
                "name": "Giawang",
                "countryCode": "PH"
            },
            {
                "name": "Ginatilan",
                "countryCode": "PH"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PH"
            },
            {
                "name": "Guba",
                "countryCode": "PH"
            },
            {
                "name": "Guibodangan",
                "countryCode": "PH"
            },
            {
                "name": "Guihulñgan",
                "countryCode": "PH"
            },
            {
                "name": "Guindarohan",
                "countryCode": "PH"
            },
            {
                "name": "Guindulman",
                "countryCode": "PH"
            },
            {
                "name": "Guiwanon",
                "countryCode": "PH"
            },
            {
                "name": "Hagdan",
                "countryCode": "PH"
            },
            {
                "name": "Hagnaya",
                "countryCode": "PH"
            },
            {
                "name": "Hibaiyo",
                "countryCode": "PH"
            },
            {
                "name": "Hilantagaan",
                "countryCode": "PH"
            },
            {
                "name": "Hilotongan",
                "countryCode": "PH"
            },
            {
                "name": "Himensulan",
                "countryCode": "PH"
            },
            {
                "name": "Hinlayagan Ilaud",
                "countryCode": "PH"
            },
            {
                "name": "Ilihan",
                "countryCode": "PH"
            },
            {
                "name": "Inabanga",
                "countryCode": "PH"
            },
            {
                "name": "Inayagan",
                "countryCode": "PH"
            },
            {
                "name": "Jaclupan",
                "countryCode": "PH"
            },
            {
                "name": "Jagna",
                "countryCode": "PH"
            },
            {
                "name": "Jampang",
                "countryCode": "PH"
            },
            {
                "name": "Jandayan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Jantianon",
                "countryCode": "PH"
            },
            {
                "name": "Japitan",
                "countryCode": "PH"
            },
            {
                "name": "Jetafe",
                "countryCode": "PH"
            },
            {
                "name": "Jimalalud",
                "countryCode": "PH"
            },
            {
                "name": "Jugno",
                "countryCode": "PH"
            },
            {
                "name": "Kabac",
                "countryCode": "PH"
            },
            {
                "name": "Kabungahan",
                "countryCode": "PH"
            },
            {
                "name": "Kandabong",
                "countryCode": "PH"
            },
            {
                "name": "Kaongkod",
                "countryCode": "PH"
            },
            {
                "name": "Kauit",
                "countryCode": "PH"
            },
            {
                "name": "Kotkot",
                "countryCode": "PH"
            },
            {
                "name": "Kuanos",
                "countryCode": "PH"
            },
            {
                "name": "La Hacienda",
                "countryCode": "PH"
            },
            {
                "name": "La Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Lanao",
                "countryCode": "PH"
            },
            {
                "name": "Lanas",
                "countryCode": "PH"
            },
            {
                "name": "Langob",
                "countryCode": "PH"
            },
            {
                "name": "Langtad",
                "countryCode": "PH"
            },
            {
                "name": "Lapaz",
                "countryCode": "PH"
            },
            {
                "name": "Lapu-Lapu City",
                "countryCode": "PH"
            },
            {
                "name": "Lazi",
                "countryCode": "PH"
            },
            {
                "name": "Lepanto",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Lila",
                "countryCode": "PH"
            },
            {
                "name": "Liloan",
                "countryCode": "PH"
            },
            {
                "name": "Lipayran",
                "countryCode": "PH"
            },
            {
                "name": "Loay",
                "countryCode": "PH"
            },
            {
                "name": "Loboc",
                "countryCode": "PH"
            },
            {
                "name": "Logon",
                "countryCode": "PH"
            },
            {
                "name": "Lombog",
                "countryCode": "PH"
            },
            {
                "name": "Loon",
                "countryCode": "PH"
            },
            {
                "name": "Lugo",
                "countryCode": "PH"
            },
            {
                "name": "Lunas",
                "countryCode": "PH"
            },
            {
                "name": "Lut-od",
                "countryCode": "PH"
            },
            {
                "name": "Maayong Tubig",
                "countryCode": "PH"
            },
            {
                "name": "Mabinay",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Macaas",
                "countryCode": "PH"
            },
            {
                "name": "Madridejos",
                "countryCode": "PH"
            },
            {
                "name": "Magay",
                "countryCode": "PH"
            },
            {
                "name": "Mahayag",
                "countryCode": "PH"
            },
            {
                "name": "Mainit",
                "countryCode": "PH"
            },
            {
                "name": "Malabugas",
                "countryCode": "PH"
            },
            {
                "name": "Malabuyoc",
                "countryCode": "PH"
            },
            {
                "name": "Malaiba",
                "countryCode": "PH"
            },
            {
                "name": "Malbug",
                "countryCode": "PH"
            },
            {
                "name": "Malhiao",
                "countryCode": "PH"
            },
            {
                "name": "Malingin",
                "countryCode": "PH"
            },
            {
                "name": "Maloh",
                "countryCode": "PH"
            },
            {
                "name": "Malusay",
                "countryCode": "PH"
            },
            {
                "name": "Malway",
                "countryCode": "PH"
            },
            {
                "name": "Manalongon",
                "countryCode": "PH"
            },
            {
                "name": "Mancilang",
                "countryCode": "PH"
            },
            {
                "name": "Mandaue City",
                "countryCode": "PH"
            },
            {
                "name": "Maninihon",
                "countryCode": "PH"
            },
            {
                "name": "Mantalongon",
                "countryCode": "PH"
            },
            {
                "name": "Mantiquil",
                "countryCode": "PH"
            },
            {
                "name": "Maravilla",
                "countryCode": "PH"
            },
            {
                "name": "Maribojoc",
                "countryCode": "PH"
            },
            {
                "name": "Maricaban",
                "countryCode": "PH"
            },
            {
                "name": "Masaba",
                "countryCode": "PH"
            },
            {
                "name": "Maya",
                "countryCode": "PH"
            },
            {
                "name": "Mayabon",
                "countryCode": "PH"
            },
            {
                "name": "Mayana",
                "countryCode": "PH"
            },
            {
                "name": "Mayapusi",
                "countryCode": "PH"
            },
            {
                "name": "Maño",
                "countryCode": "PH"
            },
            {
                "name": "McKinley",
                "countryCode": "PH"
            },
            {
                "name": "Medellin",
                "countryCode": "PH"
            },
            {
                "name": "Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Minglanilla",
                "countryCode": "PH"
            },
            {
                "name": "Minolos",
                "countryCode": "PH"
            },
            {
                "name": "Moalboal",
                "countryCode": "PH"
            },
            {
                "name": "Montaneza",
                "countryCode": "PH"
            },
            {
                "name": "Naga",
                "countryCode": "PH"
            },
            {
                "name": "Nagbalaye",
                "countryCode": "PH"
            },
            {
                "name": "Nahawan",
                "countryCode": "PH"
            },
            {
                "name": "Nailong",
                "countryCode": "PH"
            },
            {
                "name": "Nalundan",
                "countryCode": "PH"
            },
            {
                "name": "Nangka",
                "countryCode": "PH"
            },
            {
                "name": "Novallas",
                "countryCode": "PH"
            },
            {
                "name": "Nueva Fuerza",
                "countryCode": "PH"
            },
            {
                "name": "Nueva Vida Sur",
                "countryCode": "PH"
            },
            {
                "name": "Nugas",
                "countryCode": "PH"
            },
            {
                "name": "Obong",
                "countryCode": "PH"
            },
            {
                "name": "Ocaña",
                "countryCode": "PH"
            },
            {
                "name": "Ocoy",
                "countryCode": "PH"
            },
            {
                "name": "Okiot",
                "countryCode": "PH"
            },
            {
                "name": "Oslob",
                "countryCode": "PH"
            },
            {
                "name": "Owak",
                "countryCode": "PH"
            },
            {
                "name": "Padre Zamora",
                "countryCode": "PH"
            },
            {
                "name": "Pajo",
                "countryCode": "PH"
            },
            {
                "name": "Pamplona",
                "countryCode": "PH"
            },
            {
                "name": "Panalipan",
                "countryCode": "PH"
            },
            {
                "name": "Panaytayon",
                "countryCode": "PH"
            },
            {
                "name": "Pangdan",
                "countryCode": "PH"
            },
            {
                "name": "Panglao",
                "countryCode": "PH"
            },
            {
                "name": "Panognawan",
                "countryCode": "PH"
            },
            {
                "name": "Patao",
                "countryCode": "PH"
            },
            {
                "name": "Payabon",
                "countryCode": "PH"
            },
            {
                "name": "Paypay",
                "countryCode": "PH"
            },
            {
                "name": "Perrelos",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pinamungahan",
                "countryCode": "PH"
            },
            {
                "name": "Pinayagan Norte",
                "countryCode": "PH"
            },
            {
                "name": "Pinokawan",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Poro",
                "countryCode": "PH"
            },
            {
                "name": "Province of Cebu",
                "countryCode": "PH"
            },
            {
                "name": "Province of Negros Oriental",
                "countryCode": "PH"
            },
            {
                "name": "Province of Siquijor",
                "countryCode": "PH"
            },
            {
                "name": "Putat",
                "countryCode": "PH"
            },
            {
                "name": "Ronda",
                "countryCode": "PH"
            },
            {
                "name": "Saavedra",
                "countryCode": "PH"
            },
            {
                "name": "Sagbayan",
                "countryCode": "PH"
            },
            {
                "name": "Samboan",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pascual",
                "countryCode": "PH"
            },
            {
                "name": "San Remigio",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "Sandayong Sur",
                "countryCode": "PH"
            },
            {
                "name": "Sandolot",
                "countryCode": "PH"
            },
            {
                "name": "Sangat",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Filomena",
                "countryCode": "PH"
            },
            {
                "name": "Santa Nino",
                "countryCode": "PH"
            },
            {
                "name": "Santander Poblacion",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Sevilla",
                "countryCode": "PH"
            },
            {
                "name": "Siaton",
                "countryCode": "PH"
            },
            {
                "name": "Sibonga",
                "countryCode": "PH"
            },
            {
                "name": "Sibulan",
                "countryCode": "PH"
            },
            {
                "name": "Sierra Bullones",
                "countryCode": "PH"
            },
            {
                "name": "Sikatuna",
                "countryCode": "PH"
            },
            {
                "name": "Silab",
                "countryCode": "PH"
            },
            {
                "name": "Sillon",
                "countryCode": "PH"
            },
            {
                "name": "Simala",
                "countryCode": "PH"
            },
            {
                "name": "Siquijor",
                "countryCode": "PH"
            },
            {
                "name": "Sogod",
                "countryCode": "PH"
            },
            {
                "name": "Songculan",
                "countryCode": "PH"
            },
            {
                "name": "Sulangan",
                "countryCode": "PH"
            },
            {
                "name": "Tabalong",
                "countryCode": "PH"
            },
            {
                "name": "Tabogon",
                "countryCode": "PH"
            },
            {
                "name": "Tabon",
                "countryCode": "PH"
            },
            {
                "name": "Tabonok",
                "countryCode": "PH"
            },
            {
                "name": "Tabuan",
                "countryCode": "PH"
            },
            {
                "name": "Tabuelan",
                "countryCode": "PH"
            },
            {
                "name": "Tabunok",
                "countryCode": "PH"
            },
            {
                "name": "Tagbilaran City",
                "countryCode": "PH"
            },
            {
                "name": "Tagum Norte",
                "countryCode": "PH"
            },
            {
                "name": "Tajao",
                "countryCode": "PH"
            },
            {
                "name": "Talangnan",
                "countryCode": "PH"
            },
            {
                "name": "Talibon",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Tambalan",
                "countryCode": "PH"
            },
            {
                "name": "Tambo",
                "countryCode": "PH"
            },
            {
                "name": "Tambongon",
                "countryCode": "PH"
            },
            {
                "name": "Tamiso",
                "countryCode": "PH"
            },
            {
                "name": "Tampocon",
                "countryCode": "PH"
            },
            {
                "name": "Tandayag",
                "countryCode": "PH"
            },
            {
                "name": "Tangke",
                "countryCode": "PH"
            },
            {
                "name": "Tangnan",
                "countryCode": "PH"
            },
            {
                "name": "Tanjay",
                "countryCode": "PH"
            },
            {
                "name": "Tapilon",
                "countryCode": "PH"
            },
            {
                "name": "Tapon",
                "countryCode": "PH"
            },
            {
                "name": "Tawala",
                "countryCode": "PH"
            },
            {
                "name": "Tayasan",
                "countryCode": "PH"
            },
            {
                "name": "Taytayan",
                "countryCode": "PH"
            },
            {
                "name": "Tayud",
                "countryCode": "PH"
            },
            {
                "name": "Tibigan",
                "countryCode": "PH"
            },
            {
                "name": "Tiguib",
                "countryCode": "PH"
            },
            {
                "name": "Tinaan",
                "countryCode": "PH"
            },
            {
                "name": "Tinaogan",
                "countryCode": "PH"
            },
            {
                "name": "Tindog",
                "countryCode": "PH"
            },
            {
                "name": "Tinubuan",
                "countryCode": "PH"
            },
            {
                "name": "Tipolo",
                "countryCode": "PH"
            },
            {
                "name": "Toledo",
                "countryCode": "PH"
            },
            {
                "name": "Tominhao",
                "countryCode": "PH"
            },
            {
                "name": "Totolan",
                "countryCode": "PH"
            },
            {
                "name": "Trinidad",
                "countryCode": "PH"
            },
            {
                "name": "Tubigagmanoc",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tubod-dugoan",
                "countryCode": "PH"
            },
            {
                "name": "Tuburan",
                "countryCode": "PH"
            },
            {
                "name": "Tudela",
                "countryCode": "PH"
            },
            {
                "name": "Tutay",
                "countryCode": "PH"
            },
            {
                "name": "Ubay",
                "countryCode": "PH"
            },
            {
                "name": "Uling",
                "countryCode": "PH"
            },
            {
                "name": "Union",
                "countryCode": "PH"
            },
            {
                "name": "Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Valle Hermoso",
                "countryCode": "PH"
            },
            {
                "name": "Vallehermoso",
                "countryCode": "PH"
            },
            {
                "name": "Zamboanguita",
                "countryCode": "PH"
            },
            {
                "name": "Adtugan",
                "countryCode": "PH"
            },
            {
                "name": "Aglayan",
                "countryCode": "PH"
            },
            {
                "name": "Agusan",
                "countryCode": "PH"
            },
            {
                "name": "Alae",
                "countryCode": "PH"
            },
            {
                "name": "Alanib",
                "countryCode": "PH"
            },
            {
                "name": "Alubijid",
                "countryCode": "PH"
            },
            {
                "name": "Anakan",
                "countryCode": "PH"
            },
            {
                "name": "Ani-e",
                "countryCode": "PH"
            },
            {
                "name": "Aplaya",
                "countryCode": "PH"
            },
            {
                "name": "Aumbay",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod",
                "countryCode": "PH"
            },
            {
                "name": "Bagakay",
                "countryCode": "PH"
            },
            {
                "name": "Baikingon",
                "countryCode": "PH"
            },
            {
                "name": "Balila",
                "countryCode": "PH"
            },
            {
                "name": "Balili",
                "countryCode": "PH"
            },
            {
                "name": "Balingasag",
                "countryCode": "PH"
            },
            {
                "name": "Balingoan",
                "countryCode": "PH"
            },
            {
                "name": "Baliwagan",
                "countryCode": "PH"
            },
            {
                "name": "Bangahan",
                "countryCode": "PH"
            },
            {
                "name": "Bantuanon",
                "countryCode": "PH"
            },
            {
                "name": "Baroy",
                "countryCode": "PH"
            },
            {
                "name": "Barra",
                "countryCode": "PH"
            },
            {
                "name": "Basak",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Biga",
                "countryCode": "PH"
            },
            {
                "name": "Binitinan",
                "countryCode": "PH"
            },
            {
                "name": "Binuangan",
                "countryCode": "PH"
            },
            {
                "name": "Bolo Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Bonbon",
                "countryCode": "PH"
            },
            {
                "name": "Bonifacio",
                "countryCode": "PH"
            },
            {
                "name": "Boroon",
                "countryCode": "PH"
            },
            {
                "name": "Bugcaon",
                "countryCode": "PH"
            },
            {
                "name": "Bugo",
                "countryCode": "PH"
            },
            {
                "name": "Busdi",
                "countryCode": "PH"
            },
            {
                "name": "Cabangahan",
                "countryCode": "PH"
            },
            {
                "name": "Cabanglasan",
                "countryCode": "PH"
            },
            {
                "name": "Cagayan de Oro",
                "countryCode": "PH"
            },
            {
                "name": "Calabugao",
                "countryCode": "PH"
            },
            {
                "name": "Calamba",
                "countryCode": "PH"
            },
            {
                "name": "Canayan",
                "countryCode": "PH"
            },
            {
                "name": "Candiis",
                "countryCode": "PH"
            },
            {
                "name": "Caromatan",
                "countryCode": "PH"
            },
            {
                "name": "Casisang",
                "countryCode": "PH"
            },
            {
                "name": "Catarman",
                "countryCode": "PH"
            },
            {
                "name": "Clarin",
                "countryCode": "PH"
            },
            {
                "name": "Claveria",
                "countryCode": "PH"
            },
            {
                "name": "Consuelo",
                "countryCode": "PH"
            },
            {
                "name": "Cosina",
                "countryCode": "PH"
            },
            {
                "name": "Dagumba-an",
                "countryCode": "PH"
            },
            {
                "name": "Dalipuga",
                "countryCode": "PH"
            },
            {
                "name": "Dalirig",
                "countryCode": "PH"
            },
            {
                "name": "Dalorong",
                "countryCode": "PH"
            },
            {
                "name": "Dalwangan",
                "countryCode": "PH"
            },
            {
                "name": "Damilag",
                "countryCode": "PH"
            },
            {
                "name": "Damulog",
                "countryCode": "PH"
            },
            {
                "name": "Dancagan",
                "countryCode": "PH"
            },
            {
                "name": "Dimaluna",
                "countryCode": "PH"
            },
            {
                "name": "Dimayon",
                "countryCode": "PH"
            },
            {
                "name": "Dologon",
                "countryCode": "PH"
            },
            {
                "name": "Don Carlos",
                "countryCode": "PH"
            },
            {
                "name": "Dorsalanam",
                "countryCode": "PH"
            },
            {
                "name": "Dumalaguing",
                "countryCode": "PH"
            },
            {
                "name": "El Salvador",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gimampang",
                "countryCode": "PH"
            },
            {
                "name": "Gitagum",
                "countryCode": "PH"
            },
            {
                "name": "Guinisiliban",
                "countryCode": "PH"
            },
            {
                "name": "Halapitan",
                "countryCode": "PH"
            },
            {
                "name": "Hinapalanan",
                "countryCode": "PH"
            },
            {
                "name": "Igpit",
                "countryCode": "PH"
            },
            {
                "name": "Imbatug",
                "countryCode": "PH"
            },
            {
                "name": "Imelda",
                "countryCode": "PH"
            },
            {
                "name": "Impalutao",
                "countryCode": "PH"
            },
            {
                "name": "Indulang",
                "countryCode": "PH"
            },
            {
                "name": "Initao",
                "countryCode": "PH"
            },
            {
                "name": "Inobulan",
                "countryCode": "PH"
            },
            {
                "name": "Jasaan",
                "countryCode": "PH"
            },
            {
                "name": "Jimenez",
                "countryCode": "PH"
            },
            {
                "name": "Kabalantian",
                "countryCode": "PH"
            },
            {
                "name": "Kabulohan",
                "countryCode": "PH"
            },
            {
                "name": "Kadingilan",
                "countryCode": "PH"
            },
            {
                "name": "Kalanganan",
                "countryCode": "PH"
            },
            {
                "name": "Kalilangan",
                "countryCode": "PH"
            },
            {
                "name": "Kalugmanan",
                "countryCode": "PH"
            },
            {
                "name": "Kapatagan",
                "countryCode": "PH"
            },
            {
                "name": "Kauswagan",
                "countryCode": "PH"
            },
            {
                "name": "Kawit",
                "countryCode": "PH"
            },
            {
                "name": "Kibangay",
                "countryCode": "PH"
            },
            {
                "name": "Kibawe",
                "countryCode": "PH"
            },
            {
                "name": "Kibonsod",
                "countryCode": "PH"
            },
            {
                "name": "Kibureau",
                "countryCode": "PH"
            },
            {
                "name": "Kimanuit",
                "countryCode": "PH"
            },
            {
                "name": "Kimaya",
                "countryCode": "PH"
            },
            {
                "name": "Kisolon",
                "countryCode": "PH"
            },
            {
                "name": "Kitaotao",
                "countryCode": "PH"
            },
            {
                "name": "Kitobo",
                "countryCode": "PH"
            },
            {
                "name": "Kolambugan",
                "countryCode": "PH"
            },
            {
                "name": "La Fortuna",
                "countryCode": "PH"
            },
            {
                "name": "La Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Lagindingan",
                "countryCode": "PH"
            },
            {
                "name": "Lagonglong",
                "countryCode": "PH"
            },
            {
                "name": "Laguitas",
                "countryCode": "PH"
            },
            {
                "name": "Langcangan",
                "countryCode": "PH"
            },
            {
                "name": "Lanipao",
                "countryCode": "PH"
            },
            {
                "name": "Lantapan",
                "countryCode": "PH"
            },
            {
                "name": "Lapase",
                "countryCode": "PH"
            },
            {
                "name": "Lapining",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Libona",
                "countryCode": "PH"
            },
            {
                "name": "Liboran",
                "countryCode": "PH"
            },
            {
                "name": "Limbaan",
                "countryCode": "PH"
            },
            {
                "name": "Linabo",
                "countryCode": "PH"
            },
            {
                "name": "Linamon",
                "countryCode": "PH"
            },
            {
                "name": "Lingating",
                "countryCode": "PH"
            },
            {
                "name": "Lingion",
                "countryCode": "PH"
            },
            {
                "name": "Little Baguio",
                "countryCode": "PH"
            },
            {
                "name": "Looc",
                "countryCode": "PH"
            },
            {
                "name": "Lopez Jaena",
                "countryCode": "PH"
            },
            {
                "name": "Lourdes",
                "countryCode": "PH"
            },
            {
                "name": "Lugait",
                "countryCode": "PH"
            },
            {
                "name": "Lumbayao",
                "countryCode": "PH"
            },
            {
                "name": "Lumbia",
                "countryCode": "PH"
            },
            {
                "name": "Lunao",
                "countryCode": "PH"
            },
            {
                "name": "Lurugan",
                "countryCode": "PH"
            },
            {
                "name": "Maanas",
                "countryCode": "PH"
            },
            {
                "name": "Maglamin",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Mahinog",
                "countryCode": "PH"
            },
            {
                "name": "Maigo",
                "countryCode": "PH"
            },
            {
                "name": "Mailag",
                "countryCode": "PH"
            },
            {
                "name": "Malaybalay",
                "countryCode": "PH"
            },
            {
                "name": "Malinaw",
                "countryCode": "PH"
            },
            {
                "name": "Malitbog",
                "countryCode": "PH"
            },
            {
                "name": "Maluko",
                "countryCode": "PH"
            },
            {
                "name": "Mambajao",
                "countryCode": "PH"
            },
            {
                "name": "Mambatangan",
                "countryCode": "PH"
            },
            {
                "name": "Mambayaan",
                "countryCode": "PH"
            },
            {
                "name": "Mamungan",
                "countryCode": "PH"
            },
            {
                "name": "Managok",
                "countryCode": "PH"
            },
            {
                "name": "Mananum",
                "countryCode": "PH"
            },
            {
                "name": "Mandangoa",
                "countryCode": "PH"
            },
            {
                "name": "Manolo Fortich",
                "countryCode": "PH"
            },
            {
                "name": "Mantampay",
                "countryCode": "PH"
            },
            {
                "name": "Manticao",
                "countryCode": "PH"
            },
            {
                "name": "Maputi",
                "countryCode": "PH"
            },
            {
                "name": "Maramag",
                "countryCode": "PH"
            },
            {
                "name": "Maranding",
                "countryCode": "PH"
            },
            {
                "name": "Maria Cristina",
                "countryCode": "PH"
            },
            {
                "name": "Mariano",
                "countryCode": "PH"
            },
            {
                "name": "Mat-i",
                "countryCode": "PH"
            },
            {
                "name": "Matangad",
                "countryCode": "PH"
            },
            {
                "name": "Matungao",
                "countryCode": "PH"
            },
            {
                "name": "Medina",
                "countryCode": "PH"
            },
            {
                "name": "Miaray",
                "countryCode": "PH"
            },
            {
                "name": "Minlagas",
                "countryCode": "PH"
            },
            {
                "name": "Molugan",
                "countryCode": "PH"
            },
            {
                "name": "Moog",
                "countryCode": "PH"
            },
            {
                "name": "Munai",
                "countryCode": "PH"
            },
            {
                "name": "NIA Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Naawan",
                "countryCode": "PH"
            },
            {
                "name": "Napalitan",
                "countryCode": "PH"
            },
            {
                "name": "Natalungan",
                "countryCode": "PH"
            },
            {
                "name": "Nañgka",
                "countryCode": "PH"
            },
            {
                "name": "Odiongan",
                "countryCode": "PH"
            },
            {
                "name": "Opol",
                "countryCode": "PH"
            },
            {
                "name": "Oroquieta",
                "countryCode": "PH"
            },
            {
                "name": "Ozamiz City",
                "countryCode": "PH"
            },
            {
                "name": "Pan-an",
                "countryCode": "PH"
            },
            {
                "name": "Panalo-on",
                "countryCode": "PH"
            },
            {
                "name": "Pandan",
                "countryCode": "PH"
            },
            {
                "name": "Pangabuan",
                "countryCode": "PH"
            },
            {
                "name": "Pantao-Ragat",
                "countryCode": "PH"
            },
            {
                "name": "Pantar",
                "countryCode": "PH"
            },
            {
                "name": "Patrocinio",
                "countryCode": "PH"
            },
            {
                "name": "Pines",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Pongol",
                "countryCode": "PH"
            },
            {
                "name": "Pontian",
                "countryCode": "PH"
            },
            {
                "name": "Province of Bukidnon",
                "countryCode": "PH"
            },
            {
                "name": "Province of Camiguin",
                "countryCode": "PH"
            },
            {
                "name": "Province of Lanao del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Misamis Occidental",
                "countryCode": "PH"
            },
            {
                "name": "Province of Misamis Oriental",
                "countryCode": "PH"
            },
            {
                "name": "Pualas",
                "countryCode": "PH"
            },
            {
                "name": "Punta Silum",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Rebe",
                "countryCode": "PH"
            },
            {
                "name": "Sagay",
                "countryCode": "PH"
            },
            {
                "name": "Salawagan",
                "countryCode": "PH"
            },
            {
                "name": "Salay",
                "countryCode": "PH"
            },
            {
                "name": "Salimbalan",
                "countryCode": "PH"
            },
            {
                "name": "Sampagar",
                "countryCode": "PH"
            },
            {
                "name": "San Carlos",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Martin",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Sankanan",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Sapang Dalaga",
                "countryCode": "PH"
            },
            {
                "name": "Silae",
                "countryCode": "PH"
            },
            {
                "name": "Sinacaban",
                "countryCode": "PH"
            },
            {
                "name": "Sinonoc",
                "countryCode": "PH"
            },
            {
                "name": "Solana",
                "countryCode": "PH"
            },
            {
                "name": "Sugbongkogon",
                "countryCode": "PH"
            },
            {
                "name": "Sumilao",
                "countryCode": "PH"
            },
            {
                "name": "Sumpong",
                "countryCode": "PH"
            },
            {
                "name": "Sungai",
                "countryCode": "PH"
            },
            {
                "name": "Tabid",
                "countryCode": "PH"
            },
            {
                "name": "Taboc",
                "countryCode": "PH"
            },
            {
                "name": "Tacub",
                "countryCode": "PH"
            },
            {
                "name": "Tagoloan",
                "countryCode": "PH"
            },
            {
                "name": "Talakag",
                "countryCode": "PH"
            },
            {
                "name": "Talisayan",
                "countryCode": "PH"
            },
            {
                "name": "Tangub",
                "countryCode": "PH"
            },
            {
                "name": "Taypano",
                "countryCode": "PH"
            },
            {
                "name": "Taytay",
                "countryCode": "PH"
            },
            {
                "name": "Ticala-an",
                "countryCode": "PH"
            },
            {
                "name": "Tignapalan",
                "countryCode": "PH"
            },
            {
                "name": "Tubao",
                "countryCode": "PH"
            },
            {
                "name": "Tubigan",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tudela",
                "countryCode": "PH"
            },
            {
                "name": "Tuod",
                "countryCode": "PH"
            },
            {
                "name": "Tupsan",
                "countryCode": "PH"
            },
            {
                "name": "Unidos",
                "countryCode": "PH"
            },
            {
                "name": "Villanueva",
                "countryCode": "PH"
            },
            {
                "name": "Yumbing",
                "countryCode": "PH"
            },
            {
                "name": "Andili",
                "countryCode": "PH"
            },
            {
                "name": "Andop",
                "countryCode": "PH"
            },
            {
                "name": "Anibongan",
                "countryCode": "PH"
            },
            {
                "name": "Astorga",
                "countryCode": "PH"
            },
            {
                "name": "Asuncion",
                "countryCode": "PH"
            },
            {
                "name": "Babag",
                "countryCode": "PH"
            },
            {
                "name": "Baculin",
                "countryCode": "PH"
            },
            {
                "name": "Baganga",
                "countryCode": "PH"
            },
            {
                "name": "Balagunan",
                "countryCode": "PH"
            },
            {
                "name": "Balangonan",
                "countryCode": "PH"
            },
            {
                "name": "Balutakay",
                "countryCode": "PH"
            },
            {
                "name": "Banaybanay",
                "countryCode": "PH"
            },
            {
                "name": "Bansalan",
                "countryCode": "PH"
            },
            {
                "name": "Bantacan",
                "countryCode": "PH"
            },
            {
                "name": "Baon",
                "countryCode": "PH"
            },
            {
                "name": "Baracatan",
                "countryCode": "PH"
            },
            {
                "name": "Basiawan",
                "countryCode": "PH"
            },
            {
                "name": "Batiano",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Batobato",
                "countryCode": "PH"
            },
            {
                "name": "Baylo",
                "countryCode": "PH"
            },
            {
                "name": "Biao",
                "countryCode": "PH"
            },
            {
                "name": "Bincoñgan",
                "countryCode": "PH"
            },
            {
                "name": "Bitaogan",
                "countryCode": "PH"
            },
            {
                "name": "Bobon",
                "countryCode": "PH"
            },
            {
                "name": "Bolila",
                "countryCode": "PH"
            },
            {
                "name": "Boston",
                "countryCode": "PH"
            },
            {
                "name": "Buclad",
                "countryCode": "PH"
            },
            {
                "name": "Buhangin",
                "countryCode": "PH"
            },
            {
                "name": "Bukid",
                "countryCode": "PH"
            },
            {
                "name": "Bulacan",
                "countryCode": "PH"
            },
            {
                "name": "Bungabon",
                "countryCode": "PH"
            },
            {
                "name": "Butulan",
                "countryCode": "PH"
            },
            {
                "name": "Cabayangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabinuangan",
                "countryCode": "PH"
            },
            {
                "name": "Caburan",
                "countryCode": "PH"
            },
            {
                "name": "Cambanugoy",
                "countryCode": "PH"
            },
            {
                "name": "Camudmud",
                "countryCode": "PH"
            },
            {
                "name": "Caraga",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Cateel",
                "countryCode": "PH"
            },
            {
                "name": "City of Mati",
                "countryCode": "PH"
            },
            {
                "name": "City of Panabo",
                "countryCode": "PH"
            },
            {
                "name": "City of Tagum",
                "countryCode": "PH"
            },
            {
                "name": "Cogon",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Corocotan",
                "countryCode": "PH"
            },
            {
                "name": "Coronon",
                "countryCode": "PH"
            },
            {
                "name": "Cuambog",
                "countryCode": "PH"
            },
            {
                "name": "Culaman",
                "countryCode": "PH"
            },
            {
                "name": "Dacudao",
                "countryCode": "PH"
            },
            {
                "name": "Dakbayan sa Digos",
                "countryCode": "PH"
            },
            {
                "name": "Davan",
                "countryCode": "PH"
            },
            {
                "name": "Davao City",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Digos",
                "countryCode": "PH"
            },
            {
                "name": "Dolo",
                "countryCode": "PH"
            },
            {
                "name": "Don Marcelino",
                "countryCode": "PH"
            },
            {
                "name": "Dumlan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gabi",
                "countryCode": "PH"
            },
            {
                "name": "Gabuyan",
                "countryCode": "PH"
            },
            {
                "name": "Goma",
                "countryCode": "PH"
            },
            {
                "name": "Governor Generoso",
                "countryCode": "PH"
            },
            {
                "name": "Guihing Proper",
                "countryCode": "PH"
            },
            {
                "name": "Gumalang",
                "countryCode": "PH"
            },
            {
                "name": "Gupitan",
                "countryCode": "PH"
            },
            {
                "name": "Hagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Ignit",
                "countryCode": "PH"
            },
            {
                "name": "Ilangay",
                "countryCode": "PH"
            },
            {
                "name": "Inawayan",
                "countryCode": "PH"
            },
            {
                "name": "Island Garden City of Samal",
                "countryCode": "PH"
            },
            {
                "name": "Jose Abad Santos",
                "countryCode": "PH"
            },
            {
                "name": "Jovellar",
                "countryCode": "PH"
            },
            {
                "name": "Kalbay",
                "countryCode": "PH"
            },
            {
                "name": "Kalian",
                "countryCode": "PH"
            },
            {
                "name": "Kaligutan",
                "countryCode": "PH"
            },
            {
                "name": "Kapalong",
                "countryCode": "PH"
            },
            {
                "name": "Katipunan",
                "countryCode": "PH"
            },
            {
                "name": "Kiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Kinablangan",
                "countryCode": "PH"
            },
            {
                "name": "Kinamayan",
                "countryCode": "PH"
            },
            {
                "name": "Kinangan",
                "countryCode": "PH"
            },
            {
                "name": "La Libertad",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Union",
                "countryCode": "PH"
            },
            {
                "name": "Lacaron",
                "countryCode": "PH"
            },
            {
                "name": "Lacson",
                "countryCode": "PH"
            },
            {
                "name": "Lais",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan",
                "countryCode": "PH"
            },
            {
                "name": "Lapuan",
                "countryCode": "PH"
            },
            {
                "name": "Lasang",
                "countryCode": "PH"
            },
            {
                "name": "Libuganon",
                "countryCode": "PH"
            },
            {
                "name": "Limao",
                "countryCode": "PH"
            },
            {
                "name": "Limot",
                "countryCode": "PH"
            },
            {
                "name": "Linao",
                "countryCode": "PH"
            },
            {
                "name": "Linoan",
                "countryCode": "PH"
            },
            {
                "name": "Lukatan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lungaog",
                "countryCode": "PH"
            },
            {
                "name": "Lupon",
                "countryCode": "PH"
            },
            {
                "name": "Luzon",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabuhay",
                "countryCode": "PH"
            },
            {
                "name": "Maco",
                "countryCode": "PH"
            },
            {
                "name": "Maduao",
                "countryCode": "PH"
            },
            {
                "name": "Magatos",
                "countryCode": "PH"
            },
            {
                "name": "Magdug",
                "countryCode": "PH"
            },
            {
                "name": "Magnaga",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Mahanob",
                "countryCode": "PH"
            },
            {
                "name": "Mahayag",
                "countryCode": "PH"
            },
            {
                "name": "Malagos",
                "countryCode": "PH"
            },
            {
                "name": "Malalag",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malita",
                "countryCode": "PH"
            },
            {
                "name": "Mambago",
                "countryCode": "PH"
            },
            {
                "name": "Managa",
                "countryCode": "PH"
            },
            {
                "name": "Manaloal",
                "countryCode": "PH"
            },
            {
                "name": "Manat",
                "countryCode": "PH"
            },
            {
                "name": "Manay",
                "countryCode": "PH"
            },
            {
                "name": "Mangili",
                "countryCode": "PH"
            },
            {
                "name": "Manikling",
                "countryCode": "PH"
            },
            {
                "name": "Matanao",
                "countryCode": "PH"
            },
            {
                "name": "Mati",
                "countryCode": "PH"
            },
            {
                "name": "Matiao",
                "countryCode": "PH"
            },
            {
                "name": "Matti",
                "countryCode": "PH"
            },
            {
                "name": "Mawab",
                "countryCode": "PH"
            },
            {
                "name": "Mayo",
                "countryCode": "PH"
            },
            {
                "name": "Monkayo",
                "countryCode": "PH"
            },
            {
                "name": "Montevista",
                "countryCode": "PH"
            },
            {
                "name": "Nabunturan",
                "countryCode": "PH"
            },
            {
                "name": "Nangan",
                "countryCode": "PH"
            },
            {
                "name": "Nanyo",
                "countryCode": "PH"
            },
            {
                "name": "New Baclayon",
                "countryCode": "PH"
            },
            {
                "name": "New Bataan",
                "countryCode": "PH"
            },
            {
                "name": "New Bohol",
                "countryCode": "PH"
            },
            {
                "name": "New Corella",
                "countryCode": "PH"
            },
            {
                "name": "New Leyte",
                "countryCode": "PH"
            },
            {
                "name": "New Sibonga",
                "countryCode": "PH"
            },
            {
                "name": "New Visayas",
                "countryCode": "PH"
            },
            {
                "name": "Nuing",
                "countryCode": "PH"
            },
            {
                "name": "Padada",
                "countryCode": "PH"
            },
            {
                "name": "Pag-asa",
                "countryCode": "PH"
            },
            {
                "name": "Pagsabangan",
                "countryCode": "PH"
            },
            {
                "name": "Palma Gil",
                "countryCode": "PH"
            },
            {
                "name": "Panabo",
                "countryCode": "PH"
            },
            {
                "name": "Pandasan",
                "countryCode": "PH"
            },
            {
                "name": "Pangian",
                "countryCode": "PH"
            },
            {
                "name": "Panikian",
                "countryCode": "PH"
            },
            {
                "name": "Pantukan",
                "countryCode": "PH"
            },
            {
                "name": "Pasian",
                "countryCode": "PH"
            },
            {
                "name": "Pondaguitan",
                "countryCode": "PH"
            },
            {
                "name": "Pung-Pang",
                "countryCode": "PH"
            },
            {
                "name": "Samal",
                "countryCode": "PH"
            },
            {
                "name": "Sampao",
                "countryCode": "PH"
            },
            {
                "name": "San Alfonso",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Ignacio",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Remigio",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Sarangani",
                "countryCode": "PH"
            },
            {
                "name": "Sibulan",
                "countryCode": "PH"
            },
            {
                "name": "Sigaboy",
                "countryCode": "PH"
            },
            {
                "name": "Simod",
                "countryCode": "PH"
            },
            {
                "name": "Sinawilan",
                "countryCode": "PH"
            },
            {
                "name": "Sinayawan",
                "countryCode": "PH"
            },
            {
                "name": "Sirib",
                "countryCode": "PH"
            },
            {
                "name": "Sugal",
                "countryCode": "PH"
            },
            {
                "name": "Sulop",
                "countryCode": "PH"
            },
            {
                "name": "Surup",
                "countryCode": "PH"
            },
            {
                "name": "Suz-on",
                "countryCode": "PH"
            },
            {
                "name": "Tagakpan",
                "countryCode": "PH"
            },
            {
                "name": "Tagdanua",
                "countryCode": "PH"
            },
            {
                "name": "Tagnanan",
                "countryCode": "PH"
            },
            {
                "name": "Tagum",
                "countryCode": "PH"
            },
            {
                "name": "Takub",
                "countryCode": "PH"
            },
            {
                "name": "Talagutong",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Talomo",
                "countryCode": "PH"
            },
            {
                "name": "Tamayong",
                "countryCode": "PH"
            },
            {
                "name": "Tambo",
                "countryCode": "PH"
            },
            {
                "name": "Tamisan",
                "countryCode": "PH"
            },
            {
                "name": "Tamugan",
                "countryCode": "PH"
            },
            {
                "name": "Tanlad",
                "countryCode": "PH"
            },
            {
                "name": "Tapia",
                "countryCode": "PH"
            },
            {
                "name": "Tarragona",
                "countryCode": "PH"
            },
            {
                "name": "Tawan tawan",
                "countryCode": "PH"
            },
            {
                "name": "Taytayan",
                "countryCode": "PH"
            },
            {
                "name": "Tibagon",
                "countryCode": "PH"
            },
            {
                "name": "Tibanbang",
                "countryCode": "PH"
            },
            {
                "name": "Tiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Tombongon",
                "countryCode": "PH"
            },
            {
                "name": "Tubalan",
                "countryCode": "PH"
            },
            {
                "name": "Tuban",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tuganay",
                "countryCode": "PH"
            },
            {
                "name": "Tuli",
                "countryCode": "PH"
            },
            {
                "name": "Ula",
                "countryCode": "PH"
            },
            {
                "name": "Wañgan",
                "countryCode": "PH"
            },
            {
                "name": "Wines",
                "countryCode": "PH"
            },
            {
                "name": "Abuyog",
                "countryCode": "PH"
            },
            {
                "name": "Alangalang",
                "countryCode": "PH"
            },
            {
                "name": "Albuera",
                "countryCode": "PH"
            },
            {
                "name": "Alegria",
                "countryCode": "PH"
            },
            {
                "name": "Allen",
                "countryCode": "PH"
            },
            {
                "name": "Almagro",
                "countryCode": "PH"
            },
            {
                "name": "Almeria",
                "countryCode": "PH"
            },
            {
                "name": "Alugan",
                "countryCode": "PH"
            },
            {
                "name": "Anahawan",
                "countryCode": "PH"
            },
            {
                "name": "Anito",
                "countryCode": "PH"
            },
            {
                "name": "Arteche",
                "countryCode": "PH"
            },
            {
                "name": "Babatngon",
                "countryCode": "PH"
            },
            {
                "name": "Balagtas",
                "countryCode": "PH"
            },
            {
                "name": "Balagui",
                "countryCode": "PH"
            },
            {
                "name": "Balangiga",
                "countryCode": "PH"
            },
            {
                "name": "Balangkayan",
                "countryCode": "PH"
            },
            {
                "name": "Balinsacayao",
                "countryCode": "PH"
            },
            {
                "name": "Balocawehay",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Balud",
                "countryCode": "PH"
            },
            {
                "name": "Bantayan",
                "countryCode": "PH"
            },
            {
                "name": "Bantiqui",
                "countryCode": "PH"
            },
            {
                "name": "Baras",
                "countryCode": "PH"
            },
            {
                "name": "Barugo",
                "countryCode": "PH"
            },
            {
                "name": "Basey",
                "countryCode": "PH"
            },
            {
                "name": "Basud",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Baybay",
                "countryCode": "PH"
            },
            {
                "name": "Biliran",
                "countryCode": "PH"
            },
            {
                "name": "Bilwang",
                "countryCode": "PH"
            },
            {
                "name": "Biri",
                "countryCode": "PH"
            },
            {
                "name": "Bislig",
                "countryCode": "PH"
            },
            {
                "name": "Bitanjuan",
                "countryCode": "PH"
            },
            {
                "name": "Bobon",
                "countryCode": "PH"
            },
            {
                "name": "Bontoc",
                "countryCode": "PH"
            },
            {
                "name": "Borongan",
                "countryCode": "PH"
            },
            {
                "name": "Bugho",
                "countryCode": "PH"
            },
            {
                "name": "Bugko",
                "countryCode": "PH"
            },
            {
                "name": "Bunga",
                "countryCode": "PH"
            },
            {
                "name": "Burauen",
                "countryCode": "PH"
            },
            {
                "name": "Butazon",
                "countryCode": "PH"
            },
            {
                "name": "Cabacuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Cabatuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabay",
                "countryCode": "PH"
            },
            {
                "name": "Cabodiongan",
                "countryCode": "PH"
            },
            {
                "name": "Cabucgayan",
                "countryCode": "PH"
            },
            {
                "name": "Cagamotan",
                "countryCode": "PH"
            },
            {
                "name": "Caibiran",
                "countryCode": "PH"
            },
            {
                "name": "Calape",
                "countryCode": "PH"
            },
            {
                "name": "Calbayog City",
                "countryCode": "PH"
            },
            {
                "name": "Calbiga",
                "countryCode": "PH"
            },
            {
                "name": "Calubian",
                "countryCode": "PH"
            },
            {
                "name": "Can-Avid",
                "countryCode": "PH"
            },
            {
                "name": "Canhandugan",
                "countryCode": "PH"
            },
            {
                "name": "Capoocan",
                "countryCode": "PH"
            },
            {
                "name": "Capul",
                "countryCode": "PH"
            },
            {
                "name": "Caraycaray",
                "countryCode": "PH"
            },
            {
                "name": "Caridad",
                "countryCode": "PH"
            },
            {
                "name": "Carigara",
                "countryCode": "PH"
            },
            {
                "name": "Catarman",
                "countryCode": "PH"
            },
            {
                "name": "Catbalogan",
                "countryCode": "PH"
            },
            {
                "name": "Catmon",
                "countryCode": "PH"
            },
            {
                "name": "Catubig",
                "countryCode": "PH"
            },
            {
                "name": "Cervantes",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Consuegra",
                "countryCode": "PH"
            },
            {
                "name": "Culaba",
                "countryCode": "PH"
            },
            {
                "name": "Culasian",
                "countryCode": "PH"
            },
            {
                "name": "Dagami",
                "countryCode": "PH"
            },
            {
                "name": "Dao",
                "countryCode": "PH"
            },
            {
                "name": "Dapdap",
                "countryCode": "PH"
            },
            {
                "name": "Daram",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Doos",
                "countryCode": "PH"
            },
            {
                "name": "Dulag",
                "countryCode": "PH"
            },
            {
                "name": "Erenas",
                "countryCode": "PH"
            },
            {
                "name": "Gabas",
                "countryCode": "PH"
            },
            {
                "name": "Gamay",
                "countryCode": "PH"
            },
            {
                "name": "Gandara",
                "countryCode": "PH"
            },
            {
                "name": "General MacArthur",
                "countryCode": "PH"
            },
            {
                "name": "Ginabuyan",
                "countryCode": "PH"
            },
            {
                "name": "Giporlos",
                "countryCode": "PH"
            },
            {
                "name": "Guindapunan",
                "countryCode": "PH"
            },
            {
                "name": "Guirang",
                "countryCode": "PH"
            },
            {
                "name": "Guiuan",
                "countryCode": "PH"
            },
            {
                "name": "Hernani",
                "countryCode": "PH"
            },
            {
                "name": "Hilongos",
                "countryCode": "PH"
            },
            {
                "name": "Hindang",
                "countryCode": "PH"
            },
            {
                "name": "Hingatungan",
                "countryCode": "PH"
            },
            {
                "name": "Hinunangan",
                "countryCode": "PH"
            },
            {
                "name": "Hinundayan",
                "countryCode": "PH"
            },
            {
                "name": "Hipadpad",
                "countryCode": "PH"
            },
            {
                "name": "Hipasngo",
                "countryCode": "PH"
            },
            {
                "name": "Ibarra",
                "countryCode": "PH"
            },
            {
                "name": "Ichon",
                "countryCode": "PH"
            },
            {
                "name": "Inangatan",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Isabel",
                "countryCode": "PH"
            },
            {
                "name": "Jaro",
                "countryCode": "PH"
            },
            {
                "name": "Jiabong",
                "countryCode": "PH"
            },
            {
                "name": "Jubasan",
                "countryCode": "PH"
            },
            {
                "name": "Julita",
                "countryCode": "PH"
            },
            {
                "name": "Kabuynan",
                "countryCode": "PH"
            },
            {
                "name": "Kampokpok",
                "countryCode": "PH"
            },
            {
                "name": "Kananya",
                "countryCode": "PH"
            },
            {
                "name": "Kilim",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "Lalauigan",
                "countryCode": "PH"
            },
            {
                "name": "Lamak",
                "countryCode": "PH"
            },
            {
                "name": "Lao",
                "countryCode": "PH"
            },
            {
                "name": "Laoang",
                "countryCode": "PH"
            },
            {
                "name": "Lapinig",
                "countryCode": "PH"
            },
            {
                "name": "Las Navas",
                "countryCode": "PH"
            },
            {
                "name": "Lavezares",
                "countryCode": "PH"
            },
            {
                "name": "Leyte",
                "countryCode": "PH"
            },
            {
                "name": "Libagon",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Liberty",
                "countryCode": "PH"
            },
            {
                "name": "Liloan",
                "countryCode": "PH"
            },
            {
                "name": "Lim-oo",
                "countryCode": "PH"
            },
            {
                "name": "Limon",
                "countryCode": "PH"
            },
            {
                "name": "Llorente",
                "countryCode": "PH"
            },
            {
                "name": "Looc",
                "countryCode": "PH"
            },
            {
                "name": "Lope de Vega",
                "countryCode": "PH"
            },
            {
                "name": "Maasin",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "MacArthur",
                "countryCode": "PH"
            },
            {
                "name": "Macrohon",
                "countryCode": "PH"
            },
            {
                "name": "Mahaplag",
                "countryCode": "PH"
            },
            {
                "name": "Makiwalo",
                "countryCode": "PH"
            },
            {
                "name": "Malaga",
                "countryCode": "PH"
            },
            {
                "name": "Malajog",
                "countryCode": "PH"
            },
            {
                "name": "Malilinao",
                "countryCode": "PH"
            },
            {
                "name": "Malitbog",
                "countryCode": "PH"
            },
            {
                "name": "Mantang",
                "countryCode": "PH"
            },
            {
                "name": "Mapanas",
                "countryCode": "PH"
            },
            {
                "name": "Margen",
                "countryCode": "PH"
            },
            {
                "name": "Maripipi",
                "countryCode": "PH"
            },
            {
                "name": "Masarayao",
                "countryCode": "PH"
            },
            {
                "name": "Maslog",
                "countryCode": "PH"
            },
            {
                "name": "Matalom",
                "countryCode": "PH"
            },
            {
                "name": "Matlang",
                "countryCode": "PH"
            },
            {
                "name": "Maydolong",
                "countryCode": "PH"
            },
            {
                "name": "Mayorga",
                "countryCode": "PH"
            },
            {
                "name": "Maypangdan",
                "countryCode": "PH"
            },
            {
                "name": "Merida",
                "countryCode": "PH"
            },
            {
                "name": "Mondragon",
                "countryCode": "PH"
            },
            {
                "name": "Motiong",
                "countryCode": "PH"
            },
            {
                "name": "Naghalin",
                "countryCode": "PH"
            },
            {
                "name": "Napuro",
                "countryCode": "PH"
            },
            {
                "name": "Naval",
                "countryCode": "PH"
            },
            {
                "name": "Nena",
                "countryCode": "PH"
            },
            {
                "name": "Nenita",
                "countryCode": "PH"
            },
            {
                "name": "Oras",
                "countryCode": "PH"
            },
            {
                "name": "Ormoc",
                "countryCode": "PH"
            },
            {
                "name": "Padre Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Pagsanghan",
                "countryCode": "PH"
            },
            {
                "name": "Palanit",
                "countryCode": "PH"
            },
            {
                "name": "Palapag",
                "countryCode": "PH"
            },
            {
                "name": "Palaroo",
                "countryCode": "PH"
            },
            {
                "name": "Palhi",
                "countryCode": "PH"
            },
            {
                "name": "Palo",
                "countryCode": "PH"
            },
            {
                "name": "Palompon",
                "countryCode": "PH"
            },
            {
                "name": "Pambujan",
                "countryCode": "PH"
            },
            {
                "name": "Panalanoy",
                "countryCode": "PH"
            },
            {
                "name": "Pangdan",
                "countryCode": "PH"
            },
            {
                "name": "Pangpang",
                "countryCode": "PH"
            },
            {
                "name": "Pastrana",
                "countryCode": "PH"
            },
            {
                "name": "Patong",
                "countryCode": "PH"
            },
            {
                "name": "Pawing",
                "countryCode": "PH"
            },
            {
                "name": "Pinamopoan",
                "countryCode": "PH"
            },
            {
                "name": "Pintuyan",
                "countryCode": "PH"
            },
            {
                "name": "Piña",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Polahongon",
                "countryCode": "PH"
            },
            {
                "name": "Polañge",
                "countryCode": "PH"
            },
            {
                "name": "Province of Eastern Samar",
                "countryCode": "PH"
            },
            {
                "name": "Province of Leyte",
                "countryCode": "PH"
            },
            {
                "name": "Province of Northern Samar",
                "countryCode": "PH"
            },
            {
                "name": "Province of Samar",
                "countryCode": "PH"
            },
            {
                "name": "Province of Southern Leyte",
                "countryCode": "PH"
            },
            {
                "name": "Puerto Bello",
                "countryCode": "PH"
            },
            {
                "name": "Quinapundan",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Saint Bernard",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Eduardo",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Joaquin",
                "countryCode": "PH"
            },
            {
                "name": "San Jorge",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Jose de Buan",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Julian",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Policarpio",
                "countryCode": "PH"
            },
            {
                "name": "San Policarpo",
                "countryCode": "PH"
            },
            {
                "name": "San Ricardo",
                "countryCode": "PH"
            },
            {
                "name": "San Roque",
                "countryCode": "PH"
            },
            {
                "name": "San Sebastian",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Margarita",
                "countryCode": "PH"
            },
            {
                "name": "Santa Paz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rita",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Siguinon",
                "countryCode": "PH"
            },
            {
                "name": "Silago",
                "countryCode": "PH"
            },
            {
                "name": "Silanga",
                "countryCode": "PH"
            },
            {
                "name": "Silvino Lobos",
                "countryCode": "PH"
            },
            {
                "name": "Sogod",
                "countryCode": "PH"
            },
            {
                "name": "Sulangan",
                "countryCode": "PH"
            },
            {
                "name": "Sulat",
                "countryCode": "PH"
            },
            {
                "name": "Tabango",
                "countryCode": "PH"
            },
            {
                "name": "Tabing",
                "countryCode": "PH"
            },
            {
                "name": "Tabonoc",
                "countryCode": "PH"
            },
            {
                "name": "Tabontabon",
                "countryCode": "PH"
            },
            {
                "name": "Tacloban",
                "countryCode": "PH"
            },
            {
                "name": "Taft",
                "countryCode": "PH"
            },
            {
                "name": "Tagapul-an",
                "countryCode": "PH"
            },
            {
                "name": "Tagbubungang Diot",
                "countryCode": "PH"
            },
            {
                "name": "Talalora",
                "countryCode": "PH"
            },
            {
                "name": "Talisayan",
                "countryCode": "PH"
            },
            {
                "name": "Tanauan",
                "countryCode": "PH"
            },
            {
                "name": "Tarangnan",
                "countryCode": "PH"
            },
            {
                "name": "Tinambacan",
                "countryCode": "PH"
            },
            {
                "name": "Tolosa",
                "countryCode": "PH"
            },
            {
                "name": "Tomas Oppus",
                "countryCode": "PH"
            },
            {
                "name": "Tucdao",
                "countryCode": "PH"
            },
            {
                "name": "Tugbong",
                "countryCode": "PH"
            },
            {
                "name": "Tunga",
                "countryCode": "PH"
            },
            {
                "name": "Tutubigan",
                "countryCode": "PH"
            },
            {
                "name": "Umaganhan",
                "countryCode": "PH"
            },
            {
                "name": "Valencia",
                "countryCode": "PH"
            },
            {
                "name": "Victoria",
                "countryCode": "PH"
            },
            {
                "name": "Viga",
                "countryCode": "PH"
            },
            {
                "name": "Villaba",
                "countryCode": "PH"
            },
            {
                "name": "Villareal",
                "countryCode": "PH"
            },
            {
                "name": "Viriato",
                "countryCode": "PH"
            },
            {
                "name": "Wright",
                "countryCode": "PH"
            },
            {
                "name": "Zumarraga",
                "countryCode": "PH"
            },
            {
                "name": "Alamada",
                "countryCode": "PH"
            },
            {
                "name": "Amas",
                "countryCode": "PH"
            },
            {
                "name": "Bagontapay",
                "countryCode": "PH"
            },
            {
                "name": "Baguer",
                "countryCode": "PH"
            },
            {
                "name": "Bagumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Baliton",
                "countryCode": "PH"
            },
            {
                "name": "Balogo",
                "countryCode": "PH"
            },
            {
                "name": "Banawa",
                "countryCode": "PH"
            },
            {
                "name": "Banga",
                "countryCode": "PH"
            },
            {
                "name": "Banisilan",
                "countryCode": "PH"
            },
            {
                "name": "Bantogon",
                "countryCode": "PH"
            },
            {
                "name": "Barongis",
                "countryCode": "PH"
            },
            {
                "name": "Basak",
                "countryCode": "PH"
            },
            {
                "name": "Batasan",
                "countryCode": "PH"
            },
            {
                "name": "Batutitik",
                "countryCode": "PH"
            },
            {
                "name": "Bau",
                "countryCode": "PH"
            },
            {
                "name": "Bayasong",
                "countryCode": "PH"
            },
            {
                "name": "Bialong",
                "countryCode": "PH"
            },
            {
                "name": "Biwang",
                "countryCode": "PH"
            },
            {
                "name": "Blingkong",
                "countryCode": "PH"
            },
            {
                "name": "Buadtasan",
                "countryCode": "PH"
            },
            {
                "name": "Bual",
                "countryCode": "PH"
            },
            {
                "name": "Buayan",
                "countryCode": "PH"
            },
            {
                "name": "Bucay Pait",
                "countryCode": "PH"
            },
            {
                "name": "Bulatukan",
                "countryCode": "PH"
            },
            {
                "name": "Burias",
                "countryCode": "PH"
            },
            {
                "name": "Busok",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carpenter",
                "countryCode": "PH"
            },
            {
                "name": "Cebuano",
                "countryCode": "PH"
            },
            {
                "name": "City of Kidapawan",
                "countryCode": "PH"
            },
            {
                "name": "City of Koronadal",
                "countryCode": "PH"
            },
            {
                "name": "City of Tacurong",
                "countryCode": "PH"
            },
            {
                "name": "Colongolo",
                "countryCode": "PH"
            },
            {
                "name": "Columbio",
                "countryCode": "PH"
            },
            {
                "name": "Cotabato City",
                "countryCode": "PH"
            },
            {
                "name": "Dahay",
                "countryCode": "PH"
            },
            {
                "name": "Daliao",
                "countryCode": "PH"
            },
            {
                "name": "Damawato",
                "countryCode": "PH"
            },
            {
                "name": "Dansuli",
                "countryCode": "PH"
            },
            {
                "name": "Datu Montawal",
                "countryCode": "PH"
            },
            {
                "name": "Dualing",
                "countryCode": "PH"
            },
            {
                "name": "Dukay",
                "countryCode": "PH"
            },
            {
                "name": "Dunguan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gansing",
                "countryCode": "PH"
            },
            {
                "name": "General Santos",
                "countryCode": "PH"
            },
            {
                "name": "Glad",
                "countryCode": "PH"
            },
            {
                "name": "Glamang",
                "countryCode": "PH"
            },
            {
                "name": "Glan",
                "countryCode": "PH"
            },
            {
                "name": "Glan Peidu",
                "countryCode": "PH"
            },
            {
                "name": "Gocoton",
                "countryCode": "PH"
            },
            {
                "name": "Guinsang-an",
                "countryCode": "PH"
            },
            {
                "name": "Ilaya",
                "countryCode": "PH"
            },
            {
                "name": "Isulan",
                "countryCode": "PH"
            },
            {
                "name": "Kabacan",
                "countryCode": "PH"
            },
            {
                "name": "Kabalen",
                "countryCode": "PH"
            },
            {
                "name": "Kablalan",
                "countryCode": "PH"
            },
            {
                "name": "Kalaisan",
                "countryCode": "PH"
            },
            {
                "name": "Kalamangog",
                "countryCode": "PH"
            },
            {
                "name": "Kalamansig",
                "countryCode": "PH"
            },
            {
                "name": "Kamanga",
                "countryCode": "PH"
            },
            {
                "name": "Kapatan",
                "countryCode": "PH"
            },
            {
                "name": "Kapaya",
                "countryCode": "PH"
            },
            {
                "name": "Kapinkong",
                "countryCode": "PH"
            },
            {
                "name": "Katubao",
                "countryCode": "PH"
            },
            {
                "name": "Kiamba",
                "countryCode": "PH"
            },
            {
                "name": "Kidapawan",
                "countryCode": "PH"
            },
            {
                "name": "Kipalbig",
                "countryCode": "PH"
            },
            {
                "name": "Kisante",
                "countryCode": "PH"
            },
            {
                "name": "Kiupo",
                "countryCode": "PH"
            },
            {
                "name": "Klinan",
                "countryCode": "PH"
            },
            {
                "name": "Kling",
                "countryCode": "PH"
            },
            {
                "name": "Koronadal",
                "countryCode": "PH"
            },
            {
                "name": "Kudanding",
                "countryCode": "PH"
            },
            {
                "name": "Kulaman",
                "countryCode": "PH"
            },
            {
                "name": "Labu-o",
                "countryCode": "PH"
            },
            {
                "name": "Laguilayan",
                "countryCode": "PH"
            },
            {
                "name": "Lambayong",
                "countryCode": "PH"
            },
            {
                "name": "Lambo",
                "countryCode": "PH"
            },
            {
                "name": "Lambontong",
                "countryCode": "PH"
            },
            {
                "name": "Lamian",
                "countryCode": "PH"
            },
            {
                "name": "Lampari",
                "countryCode": "PH"
            },
            {
                "name": "Lampitak",
                "countryCode": "PH"
            },
            {
                "name": "Lapuz",
                "countryCode": "PH"
            },
            {
                "name": "Lebak",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Liberty",
                "countryCode": "PH"
            },
            {
                "name": "Libungan",
                "countryCode": "PH"
            },
            {
                "name": "Liliongan",
                "countryCode": "PH"
            },
            {
                "name": "Limbalod",
                "countryCode": "PH"
            },
            {
                "name": "Limulan",
                "countryCode": "PH"
            },
            {
                "name": "Linan (town)",
                "countryCode": "PH"
            },
            {
                "name": "Linao",
                "countryCode": "PH"
            },
            {
                "name": "Lopez Jaena",
                "countryCode": "PH"
            },
            {
                "name": "Lumatil",
                "countryCode": "PH"
            },
            {
                "name": "Lumazal",
                "countryCode": "PH"
            },
            {
                "name": "Lumuyon",
                "countryCode": "PH"
            },
            {
                "name": "Lun Pequeño",
                "countryCode": "PH"
            },
            {
                "name": "Lunen",
                "countryCode": "PH"
            },
            {
                "name": "Lutayan",
                "countryCode": "PH"
            },
            {
                "name": "M'lang",
                "countryCode": "PH"
            },
            {
                "name": "Maan",
                "countryCode": "PH"
            },
            {
                "name": "Maasim",
                "countryCode": "PH"
            },
            {
                "name": "Mabay",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Magpet",
                "countryCode": "PH"
            },
            {
                "name": "Maguling",
                "countryCode": "PH"
            },
            {
                "name": "Maibu",
                "countryCode": "PH"
            },
            {
                "name": "Maindang",
                "countryCode": "PH"
            },
            {
                "name": "Maitum",
                "countryCode": "PH"
            },
            {
                "name": "Makilala",
                "countryCode": "PH"
            },
            {
                "name": "Malamote",
                "countryCode": "PH"
            },
            {
                "name": "Malandag",
                "countryCode": "PH"
            },
            {
                "name": "Malapag",
                "countryCode": "PH"
            },
            {
                "name": "Malapatan",
                "countryCode": "PH"
            },
            {
                "name": "Malasila",
                "countryCode": "PH"
            },
            {
                "name": "Malbang",
                "countryCode": "PH"
            },
            {
                "name": "Malingao",
                "countryCode": "PH"
            },
            {
                "name": "Malisbeng",
                "countryCode": "PH"
            },
            {
                "name": "Malitubog",
                "countryCode": "PH"
            },
            {
                "name": "Maltana",
                "countryCode": "PH"
            },
            {
                "name": "Malungon",
                "countryCode": "PH"
            },
            {
                "name": "Mamali",
                "countryCode": "PH"
            },
            {
                "name": "Manaulanan",
                "countryCode": "PH"
            },
            {
                "name": "Manga",
                "countryCode": "PH"
            },
            {
                "name": "Manuangan",
                "countryCode": "PH"
            },
            {
                "name": "Manuel Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Marbel",
                "countryCode": "PH"
            },
            {
                "name": "Mariano Marcos",
                "countryCode": "PH"
            },
            {
                "name": "Matalam",
                "countryCode": "PH"
            },
            {
                "name": "Matinao",
                "countryCode": "PH"
            },
            {
                "name": "Midsayap",
                "countryCode": "PH"
            },
            {
                "name": "Minapan",
                "countryCode": "PH"
            },
            {
                "name": "Mindupok",
                "countryCode": "PH"
            },
            {
                "name": "Nalus",
                "countryCode": "PH"
            },
            {
                "name": "New Cebu",
                "countryCode": "PH"
            },
            {
                "name": "New Iloilo",
                "countryCode": "PH"
            },
            {
                "name": "New Lagao",
                "countryCode": "PH"
            },
            {
                "name": "New Panay",
                "countryCode": "PH"
            },
            {
                "name": "Noling",
                "countryCode": "PH"
            },
            {
                "name": "Norala",
                "countryCode": "PH"
            },
            {
                "name": "Nunguan",
                "countryCode": "PH"
            },
            {
                "name": "Osias",
                "countryCode": "PH"
            },
            {
                "name": "Paatan",
                "countryCode": "PH"
            },
            {
                "name": "Pagalungan",
                "countryCode": "PH"
            },
            {
                "name": "Pagangan",
                "countryCode": "PH"
            },
            {
                "name": "Palian",
                "countryCode": "PH"
            },
            {
                "name": "Palimbang",
                "countryCode": "PH"
            },
            {
                "name": "Palkan",
                "countryCode": "PH"
            },
            {
                "name": "Pamantingan",
                "countryCode": "PH"
            },
            {
                "name": "Panay",
                "countryCode": "PH"
            },
            {
                "name": "Pangyan",
                "countryCode": "PH"
            },
            {
                "name": "Paraiso",
                "countryCode": "PH"
            },
            {
                "name": "Patindeguen",
                "countryCode": "PH"
            },
            {
                "name": "Pedtad",
                "countryCode": "PH"
            },
            {
                "name": "Pigcawayan",
                "countryCode": "PH"
            },
            {
                "name": "Pikit",
                "countryCode": "PH"
            },
            {
                "name": "Pimbalayan",
                "countryCode": "PH"
            },
            {
                "name": "Polo",
                "countryCode": "PH"
            },
            {
                "name": "Polomolok",
                "countryCode": "PH"
            },
            {
                "name": "Polonoling",
                "countryCode": "PH"
            },
            {
                "name": "President Quirino",
                "countryCode": "PH"
            },
            {
                "name": "President Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Puloypuloy",
                "countryCode": "PH"
            },
            {
                "name": "Punolu",
                "countryCode": "PH"
            },
            {
                "name": "Puricay",
                "countryCode": "PH"
            },
            {
                "name": "Ragandang",
                "countryCode": "PH"
            },
            {
                "name": "Rotunda",
                "countryCode": "PH"
            },
            {
                "name": "Sadsalan",
                "countryCode": "PH"
            },
            {
                "name": "Saguing",
                "countryCode": "PH"
            },
            {
                "name": "Salimbao",
                "countryCode": "PH"
            },
            {
                "name": "Salunayan",
                "countryCode": "PH"
            },
            {
                "name": "Sampao",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Sapad",
                "countryCode": "PH"
            },
            {
                "name": "Sapu Padidu",
                "countryCode": "PH"
            },
            {
                "name": "Sañgay",
                "countryCode": "PH"
            },
            {
                "name": "Sebu",
                "countryCode": "PH"
            },
            {
                "name": "Silway 7",
                "countryCode": "PH"
            },
            {
                "name": "Sinolon",
                "countryCode": "PH"
            },
            {
                "name": "Sulit",
                "countryCode": "PH"
            },
            {
                "name": "Surallah",
                "countryCode": "PH"
            },
            {
                "name": "Suyan",
                "countryCode": "PH"
            },
            {
                "name": "T'boli",
                "countryCode": "PH"
            },
            {
                "name": "Tacurong",
                "countryCode": "PH"
            },
            {
                "name": "Taguisa",
                "countryCode": "PH"
            },
            {
                "name": "Taluya",
                "countryCode": "PH"
            },
            {
                "name": "Tambak",
                "countryCode": "PH"
            },
            {
                "name": "Tambilil",
                "countryCode": "PH"
            },
            {
                "name": "Tamnag",
                "countryCode": "PH"
            },
            {
                "name": "Tampakan",
                "countryCode": "PH"
            },
            {
                "name": "Tantangan",
                "countryCode": "PH"
            },
            {
                "name": "Tañgo",
                "countryCode": "PH"
            },
            {
                "name": "Telafas",
                "countryCode": "PH"
            },
            {
                "name": "Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Tinoto",
                "countryCode": "PH"
            },
            {
                "name": "Tomado",
                "countryCode": "PH"
            },
            {
                "name": "Tran",
                "countryCode": "PH"
            },
            {
                "name": "Tuka",
                "countryCode": "PH"
            },
            {
                "name": "Tulunan",
                "countryCode": "PH"
            },
            {
                "name": "Tupi",
                "countryCode": "PH"
            },
            {
                "name": "Tuyan",
                "countryCode": "PH"
            },
            {
                "name": "Upper Klinan",
                "countryCode": "PH"
            },
            {
                "name": "Upper San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "Villamor",
                "countryCode": "PH"
            },
            {
                "name": "Agay",
                "countryCode": "PH"
            },
            {
                "name": "Alegria",
                "countryCode": "PH"
            },
            {
                "name": "Alubijid",
                "countryCode": "PH"
            },
            {
                "name": "Amaga",
                "countryCode": "PH"
            },
            {
                "name": "Anticala",
                "countryCode": "PH"
            },
            {
                "name": "Aras-asan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod",
                "countryCode": "PH"
            },
            {
                "name": "Bacuag",
                "countryCode": "PH"
            },
            {
                "name": "Bah-Bah",
                "countryCode": "PH"
            },
            {
                "name": "Balangbalang",
                "countryCode": "PH"
            },
            {
                "name": "Bancasi",
                "countryCode": "PH"
            },
            {
                "name": "Bangonay",
                "countryCode": "PH"
            },
            {
                "name": "Barcelona",
                "countryCode": "PH"
            },
            {
                "name": "Barobo",
                "countryCode": "PH"
            },
            {
                "name": "Basa",
                "countryCode": "PH"
            },
            {
                "name": "Basilisa",
                "countryCode": "PH"
            },
            {
                "name": "Bayabas",
                "countryCode": "PH"
            },
            {
                "name": "Bayugan",
                "countryCode": "PH"
            },
            {
                "name": "Bigaan",
                "countryCode": "PH"
            },
            {
                "name": "Binucayan",
                "countryCode": "PH"
            },
            {
                "name": "Bislig",
                "countryCode": "PH"
            },
            {
                "name": "Borbon",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Bunawan",
                "countryCode": "PH"
            },
            {
                "name": "Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Butuan",
                "countryCode": "PH"
            },
            {
                "name": "Cabadbaran",
                "countryCode": "PH"
            },
            {
                "name": "Cagdianao",
                "countryCode": "PH"
            },
            {
                "name": "Cagwait",
                "countryCode": "PH"
            },
            {
                "name": "Calamba",
                "countryCode": "PH"
            },
            {
                "name": "Caloc-an",
                "countryCode": "PH"
            },
            {
                "name": "Cantapoy",
                "countryCode": "PH"
            },
            {
                "name": "Cantilan",
                "countryCode": "PH"
            },
            {
                "name": "Capalayan",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Carrascal",
                "countryCode": "PH"
            },
            {
                "name": "Causwagan",
                "countryCode": "PH"
            },
            {
                "name": "City of Cabadbaran",
                "countryCode": "PH"
            },
            {
                "name": "Claver",
                "countryCode": "PH"
            },
            {
                "name": "Comagascas",
                "countryCode": "PH"
            },
            {
                "name": "Cortes",
                "countryCode": "PH"
            },
            {
                "name": "Cuevas",
                "countryCode": "PH"
            },
            {
                "name": "Culit",
                "countryCode": "PH"
            },
            {
                "name": "Dakbayan sa Bislig",
                "countryCode": "PH"
            },
            {
                "name": "Dapa",
                "countryCode": "PH"
            },
            {
                "name": "Del Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Dinagat",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gamut",
                "countryCode": "PH"
            },
            {
                "name": "General Luna",
                "countryCode": "PH"
            },
            {
                "name": "Gigaquit",
                "countryCode": "PH"
            },
            {
                "name": "Guadalupe",
                "countryCode": "PH"
            },
            {
                "name": "Guinabsan",
                "countryCode": "PH"
            },
            {
                "name": "Hinatuan",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Jabonga",
                "countryCode": "PH"
            },
            {
                "name": "Jagupit",
                "countryCode": "PH"
            },
            {
                "name": "Javier",
                "countryCode": "PH"
            },
            {
                "name": "Kauswagan",
                "countryCode": "PH"
            },
            {
                "name": "Kinabhangan",
                "countryCode": "PH"
            },
            {
                "name": "Kitcharao",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Union",
                "countryCode": "PH"
            },
            {
                "name": "Lanuza",
                "countryCode": "PH"
            },
            {
                "name": "Lapinigan",
                "countryCode": "PH"
            },
            {
                "name": "Las Nieves",
                "countryCode": "PH"
            },
            {
                "name": "Lianga",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Libjo",
                "countryCode": "PH"
            },
            {
                "name": "Lingig",
                "countryCode": "PH"
            },
            {
                "name": "Loreto",
                "countryCode": "PH"
            },
            {
                "name": "Los Angeles",
                "countryCode": "PH"
            },
            {
                "name": "Los Arcos",
                "countryCode": "PH"
            },
            {
                "name": "Loyola",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Mabahin",
                "countryCode": "PH"
            },
            {
                "name": "Mabua",
                "countryCode": "PH"
            },
            {
                "name": "Madrid",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Mainit",
                "countryCode": "PH"
            },
            {
                "name": "Malimono",
                "countryCode": "PH"
            },
            {
                "name": "Manapa",
                "countryCode": "PH"
            },
            {
                "name": "Marihatag",
                "countryCode": "PH"
            },
            {
                "name": "Matabao",
                "countryCode": "PH"
            },
            {
                "name": "Mati",
                "countryCode": "PH"
            },
            {
                "name": "Maygatasan",
                "countryCode": "PH"
            },
            {
                "name": "Nasipit",
                "countryCode": "PH"
            },
            {
                "name": "Panikian",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Patin-ay",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Placer",
                "countryCode": "PH"
            },
            {
                "name": "Prosperidad",
                "countryCode": "PH"
            },
            {
                "name": "Punta",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Benito",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "Sanghan",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Josefa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Monica",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Sibagat",
                "countryCode": "PH"
            },
            {
                "name": "Sinubong",
                "countryCode": "PH"
            },
            {
                "name": "Sison",
                "countryCode": "PH"
            },
            {
                "name": "Socorro",
                "countryCode": "PH"
            },
            {
                "name": "Surigao City",
                "countryCode": "PH"
            },
            {
                "name": "Tagana-an",
                "countryCode": "PH"
            },
            {
                "name": "Tagbina",
                "countryCode": "PH"
            },
            {
                "name": "Tagcatong",
                "countryCode": "PH"
            },
            {
                "name": "Tago",
                "countryCode": "PH"
            },
            {
                "name": "Talacogon",
                "countryCode": "PH"
            },
            {
                "name": "Taligaman",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Tandag",
                "countryCode": "PH"
            },
            {
                "name": "Tidman",
                "countryCode": "PH"
            },
            {
                "name": "Tigao",
                "countryCode": "PH"
            },
            {
                "name": "Trento",
                "countryCode": "PH"
            },
            {
                "name": "Tubajon",
                "countryCode": "PH"
            },
            {
                "name": "Tubay",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Unidad",
                "countryCode": "PH"
            },
            {
                "name": "Union",
                "countryCode": "PH"
            },
            {
                "name": "Veruela",
                "countryCode": "PH"
            },
            {
                "name": "Ampatuan",
                "countryCode": "PH"
            },
            {
                "name": "Andalan",
                "countryCode": "PH"
            },
            {
                "name": "Anuling",
                "countryCode": "PH"
            },
            {
                "name": "Awang",
                "countryCode": "PH"
            },
            {
                "name": "Bacayawan",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod Grande",
                "countryCode": "PH"
            },
            {
                "name": "Bacolod-Kalawi",
                "countryCode": "PH"
            },
            {
                "name": "Badak",
                "countryCode": "PH"
            },
            {
                "name": "Bagan",
                "countryCode": "PH"
            },
            {
                "name": "Baka",
                "countryCode": "PH"
            },
            {
                "name": "Bakung",
                "countryCode": "PH"
            },
            {
                "name": "Balabagan",
                "countryCode": "PH"
            },
            {
                "name": "Balas",
                "countryCode": "PH"
            },
            {
                "name": "Balindong",
                "countryCode": "PH"
            },
            {
                "name": "Bangkal",
                "countryCode": "PH"
            },
            {
                "name": "Banguingui",
                "countryCode": "PH"
            },
            {
                "name": "Bankaw",
                "countryCode": "PH"
            },
            {
                "name": "Barira",
                "countryCode": "PH"
            },
            {
                "name": "Barurao",
                "countryCode": "PH"
            },
            {
                "name": "Bato Bato",
                "countryCode": "PH"
            },
            {
                "name": "Baunu-Timbangan",
                "countryCode": "PH"
            },
            {
                "name": "Bawison",
                "countryCode": "PH"
            },
            {
                "name": "Bayang",
                "countryCode": "PH"
            },
            {
                "name": "Bayanga",
                "countryCode": "PH"
            },
            {
                "name": "Begang",
                "countryCode": "PH"
            },
            {
                "name": "Binidayan",
                "countryCode": "PH"
            },
            {
                "name": "Binuang",
                "countryCode": "PH"
            },
            {
                "name": "Blinsung",
                "countryCode": "PH"
            },
            {
                "name": "Bongao",
                "countryCode": "PH"
            },
            {
                "name": "Bongued",
                "countryCode": "PH"
            },
            {
                "name": "Buadiposo-Buntong",
                "countryCode": "PH"
            },
            {
                "name": "Bualan",
                "countryCode": "PH"
            },
            {
                "name": "Buan",
                "countryCode": "PH"
            },
            {
                "name": "Buansa",
                "countryCode": "PH"
            },
            {
                "name": "Bubong",
                "countryCode": "PH"
            },
            {
                "name": "Budta",
                "countryCode": "PH"
            },
            {
                "name": "Bugasan",
                "countryCode": "PH"
            },
            {
                "name": "Buldon",
                "countryCode": "PH"
            },
            {
                "name": "Buliok",
                "countryCode": "PH"
            },
            {
                "name": "Bulit",
                "countryCode": "PH"
            },
            {
                "name": "Buluan",
                "countryCode": "PH"
            },
            {
                "name": "Butig",
                "countryCode": "PH"
            },
            {
                "name": "Cagayan de Tawi-Tawi",
                "countryCode": "PH"
            },
            {
                "name": "Calanogas",
                "countryCode": "PH"
            },
            {
                "name": "Colonia",
                "countryCode": "PH"
            },
            {
                "name": "Dado",
                "countryCode": "PH"
            },
            {
                "name": "Dadus",
                "countryCode": "PH"
            },
            {
                "name": "Dalican",
                "countryCode": "PH"
            },
            {
                "name": "Dalumangcob",
                "countryCode": "PH"
            },
            {
                "name": "Damabalas",
                "countryCode": "PH"
            },
            {
                "name": "Damatulan",
                "countryCode": "PH"
            },
            {
                "name": "Datu Blah T. Sinsuat",
                "countryCode": "PH"
            },
            {
                "name": "Datu Odin Sinsuat",
                "countryCode": "PH"
            },
            {
                "name": "Datu Paglas",
                "countryCode": "PH"
            },
            {
                "name": "Datu Piang",
                "countryCode": "PH"
            },
            {
                "name": "Digal",
                "countryCode": "PH"
            },
            {
                "name": "Dinaig",
                "countryCode": "PH"
            },
            {
                "name": "Dinganen",
                "countryCode": "PH"
            },
            {
                "name": "Ditsaan-Ramain",
                "countryCode": "PH"
            },
            {
                "name": "Ebcor Town",
                "countryCode": "PH"
            },
            {
                "name": "Gadung",
                "countryCode": "PH"
            },
            {
                "name": "Ganassi",
                "countryCode": "PH"
            },
            {
                "name": "Gang",
                "countryCode": "PH"
            },
            {
                "name": "Guiong",
                "countryCode": "PH"
            },
            {
                "name": "Idtig",
                "countryCode": "PH"
            },
            {
                "name": "Indanan",
                "countryCode": "PH"
            },
            {
                "name": "Isabela",
                "countryCode": "PH"
            },
            {
                "name": "Jolo",
                "countryCode": "PH"
            },
            {
                "name": "Kabasalan",
                "countryCode": "PH"
            },
            {
                "name": "Kabuntalan",
                "countryCode": "PH"
            },
            {
                "name": "Kagay",
                "countryCode": "PH"
            },
            {
                "name": "Kajatian",
                "countryCode": "PH"
            },
            {
                "name": "Kalang",
                "countryCode": "PH"
            },
            {
                "name": "Kalbugan",
                "countryCode": "PH"
            },
            {
                "name": "Kalingalan Caluang",
                "countryCode": "PH"
            },
            {
                "name": "Kambing",
                "countryCode": "PH"
            },
            {
                "name": "Kanlagay",
                "countryCode": "PH"
            },
            {
                "name": "Kansipati",
                "countryCode": "PH"
            },
            {
                "name": "Kapai",
                "countryCode": "PH"
            },
            {
                "name": "Karungdong",
                "countryCode": "PH"
            },
            {
                "name": "Katico",
                "countryCode": "PH"
            },
            {
                "name": "Katidtuan",
                "countryCode": "PH"
            },
            {
                "name": "Katuli",
                "countryCode": "PH"
            },
            {
                "name": "Kauran",
                "countryCode": "PH"
            },
            {
                "name": "Kitango",
                "countryCode": "PH"
            },
            {
                "name": "Kitapak",
                "countryCode": "PH"
            },
            {
                "name": "Kolape",
                "countryCode": "PH"
            },
            {
                "name": "Kulase",
                "countryCode": "PH"
            },
            {
                "name": "Kulay-Kulay",
                "countryCode": "PH"
            },
            {
                "name": "Kulempang",
                "countryCode": "PH"
            },
            {
                "name": "Kungtad",
                "countryCode": "PH"
            },
            {
                "name": "Labuñgan",
                "countryCode": "PH"
            },
            {
                "name": "Laminusa",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan City",
                "countryCode": "PH"
            },
            {
                "name": "Langpas",
                "countryCode": "PH"
            },
            {
                "name": "Languyan",
                "countryCode": "PH"
            },
            {
                "name": "Larap",
                "countryCode": "PH"
            },
            {
                "name": "Latung",
                "countryCode": "PH"
            },
            {
                "name": "Layog",
                "countryCode": "PH"
            },
            {
                "name": "Ligayan",
                "countryCode": "PH"
            },
            {
                "name": "Limbo",
                "countryCode": "PH"
            },
            {
                "name": "Litayan",
                "countryCode": "PH"
            },
            {
                "name": "Lookan",
                "countryCode": "PH"
            },
            {
                "name": "Lugus",
                "countryCode": "PH"
            },
            {
                "name": "Lumba-a-Bayabao",
                "countryCode": "PH"
            },
            {
                "name": "Lumbac",
                "countryCode": "PH"
            },
            {
                "name": "Lumbatan",
                "countryCode": "PH"
            },
            {
                "name": "Lumbayanague",
                "countryCode": "PH"
            },
            {
                "name": "Lutayan",
                "countryCode": "PH"
            },
            {
                "name": "Luuk",
                "countryCode": "PH"
            },
            {
                "name": "Luuk Datan",
                "countryCode": "PH"
            },
            {
                "name": "Madalum",
                "countryCode": "PH"
            },
            {
                "name": "Madamba",
                "countryCode": "PH"
            },
            {
                "name": "Maguing",
                "countryCode": "PH"
            },
            {
                "name": "Mahala",
                "countryCode": "PH"
            },
            {
                "name": "Maimbung",
                "countryCode": "PH"
            },
            {
                "name": "Makir",
                "countryCode": "PH"
            },
            {
                "name": "Malabang",
                "countryCode": "PH"
            },
            {
                "name": "Maluso",
                "countryCode": "PH"
            },
            {
                "name": "Manubul",
                "countryCode": "PH"
            },
            {
                "name": "Manuk Mangkaw",
                "countryCode": "PH"
            },
            {
                "name": "Marantao",
                "countryCode": "PH"
            },
            {
                "name": "Marawi",
                "countryCode": "PH"
            },
            {
                "name": "Marogong",
                "countryCode": "PH"
            },
            {
                "name": "Marsada",
                "countryCode": "PH"
            },
            {
                "name": "Marunggas",
                "countryCode": "PH"
            },
            {
                "name": "Masiu",
                "countryCode": "PH"
            },
            {
                "name": "Matanog",
                "countryCode": "PH"
            },
            {
                "name": "Mataya",
                "countryCode": "PH"
            },
            {
                "name": "Mauboh",
                "countryCode": "PH"
            },
            {
                "name": "Mileb",
                "countryCode": "PH"
            },
            {
                "name": "Molundo",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Indanan",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Lantawan",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Pangutaran",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Sultan Gumander",
                "countryCode": "PH"
            },
            {
                "name": "Municipality of Tongkil",
                "countryCode": "PH"
            },
            {
                "name": "New Batu Batu",
                "countryCode": "PH"
            },
            {
                "name": "New Panamao",
                "countryCode": "PH"
            },
            {
                "name": "Nuyo",
                "countryCode": "PH"
            },
            {
                "name": "Old Panamao",
                "countryCode": "PH"
            },
            {
                "name": "Pagalungan",
                "countryCode": "PH"
            },
            {
                "name": "Pagatin",
                "countryCode": "PH"
            },
            {
                "name": "Pagayawan",
                "countryCode": "PH"
            },
            {
                "name": "Paglat",
                "countryCode": "PH"
            },
            {
                "name": "Paitan",
                "countryCode": "PH"
            },
            {
                "name": "Panabuan",
                "countryCode": "PH"
            },
            {
                "name": "Panadtaban",
                "countryCode": "PH"
            },
            {
                "name": "Pandag",
                "countryCode": "PH"
            },
            {
                "name": "Pandakan",
                "countryCode": "PH"
            },
            {
                "name": "Pandami",
                "countryCode": "PH"
            },
            {
                "name": "Pandan Niog",
                "countryCode": "PH"
            },
            {
                "name": "Pang",
                "countryCode": "PH"
            },
            {
                "name": "Panglima Sugala",
                "countryCode": "PH"
            },
            {
                "name": "Pangutaran",
                "countryCode": "PH"
            },
            {
                "name": "Parang",
                "countryCode": "PH"
            },
            {
                "name": "Parangan",
                "countryCode": "PH"
            },
            {
                "name": "Parian Dakula",
                "countryCode": "PH"
            },
            {
                "name": "Pata",
                "countryCode": "PH"
            },
            {
                "name": "Patikul",
                "countryCode": "PH"
            },
            {
                "name": "Pawak",
                "countryCode": "PH"
            },
            {
                "name": "Payuhan",
                "countryCode": "PH"
            },
            {
                "name": "Piagapo",
                "countryCode": "PH"
            },
            {
                "name": "Pidsandawan",
                "countryCode": "PH"
            },
            {
                "name": "Pinaring",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Poon-a-Bayabao",
                "countryCode": "PH"
            },
            {
                "name": "Pualas",
                "countryCode": "PH"
            },
            {
                "name": "Punay",
                "countryCode": "PH"
            },
            {
                "name": "Rimpeso",
                "countryCode": "PH"
            },
            {
                "name": "Rominimbang",
                "countryCode": "PH"
            },
            {
                "name": "Sagasa",
                "countryCode": "PH"
            },
            {
                "name": "Saguiaran",
                "countryCode": "PH"
            },
            {
                "name": "Sambuluan",
                "countryCode": "PH"
            },
            {
                "name": "Sanga-Sanga",
                "countryCode": "PH"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PH"
            },
            {
                "name": "Sapa",
                "countryCode": "PH"
            },
            {
                "name": "Sapa-Sapa",
                "countryCode": "PH"
            },
            {
                "name": "Sapadun",
                "countryCode": "PH"
            },
            {
                "name": "Satan",
                "countryCode": "PH"
            },
            {
                "name": "Semut",
                "countryCode": "PH"
            },
            {
                "name": "Shariff Aguak",
                "countryCode": "PH"
            },
            {
                "name": "Siasi",
                "countryCode": "PH"
            },
            {
                "name": "Simuay",
                "countryCode": "PH"
            },
            {
                "name": "Simunul",
                "countryCode": "PH"
            },
            {
                "name": "Sionogan",
                "countryCode": "PH"
            },
            {
                "name": "Sitangkai",
                "countryCode": "PH"
            },
            {
                "name": "South Ubian",
                "countryCode": "PH"
            },
            {
                "name": "South Upi",
                "countryCode": "PH"
            },
            {
                "name": "Sultan Kudarat",
                "countryCode": "PH"
            },
            {
                "name": "Sultan Sumagka",
                "countryCode": "PH"
            },
            {
                "name": "Sultan sa Barongis",
                "countryCode": "PH"
            },
            {
                "name": "Sumisip",
                "countryCode": "PH"
            },
            {
                "name": "Tabiauan",
                "countryCode": "PH"
            },
            {
                "name": "Tablas",
                "countryCode": "PH"
            },
            {
                "name": "Tairan Camp",
                "countryCode": "PH"
            },
            {
                "name": "Talayan",
                "countryCode": "PH"
            },
            {
                "name": "Talipao",
                "countryCode": "PH"
            },
            {
                "name": "Talipaw",
                "countryCode": "PH"
            },
            {
                "name": "Tampakan",
                "countryCode": "PH"
            },
            {
                "name": "Tamparan",
                "countryCode": "PH"
            },
            {
                "name": "Tandubas",
                "countryCode": "PH"
            },
            {
                "name": "Tapayan",
                "countryCode": "PH"
            },
            {
                "name": "Tapikan",
                "countryCode": "PH"
            },
            {
                "name": "Tapul",
                "countryCode": "PH"
            },
            {
                "name": "Taraka",
                "countryCode": "PH"
            },
            {
                "name": "Taungoh",
                "countryCode": "PH"
            },
            {
                "name": "Taviran",
                "countryCode": "PH"
            },
            {
                "name": "Tinutulan",
                "countryCode": "PH"
            },
            {
                "name": "Tipo-Tipo",
                "countryCode": "PH"
            },
            {
                "name": "Tongouson",
                "countryCode": "PH"
            },
            {
                "name": "Tubaran",
                "countryCode": "PH"
            },
            {
                "name": "Tuburan",
                "countryCode": "PH"
            },
            {
                "name": "Tugaya",
                "countryCode": "PH"
            },
            {
                "name": "Tumbagaan",
                "countryCode": "PH"
            },
            {
                "name": "Tunggol",
                "countryCode": "PH"
            },
            {
                "name": "Tungol",
                "countryCode": "PH"
            },
            {
                "name": "Turtle Islands",
                "countryCode": "PH"
            },
            {
                "name": "Ungus-Ungus",
                "countryCode": "PH"
            },
            {
                "name": "Upi",
                "countryCode": "PH"
            },
            {
                "name": "Uyaan",
                "countryCode": "PH"
            },
            {
                "name": "Wao",
                "countryCode": "PH"
            },
            {
                "name": "Ambuclao",
                "countryCode": "PH"
            },
            {
                "name": "Amlimay",
                "countryCode": "PH"
            },
            {
                "name": "Ampusungan",
                "countryCode": "PH"
            },
            {
                "name": "Angad",
                "countryCode": "PH"
            },
            {
                "name": "Atok",
                "countryCode": "PH"
            },
            {
                "name": "Baculongan",
                "countryCode": "PH"
            },
            {
                "name": "Baguinge",
                "countryCode": "PH"
            },
            {
                "name": "Baguio",
                "countryCode": "PH"
            },
            {
                "name": "Bakun",
                "countryCode": "PH"
            },
            {
                "name": "Balbalan",
                "countryCode": "PH"
            },
            {
                "name": "Banaue",
                "countryCode": "PH"
            },
            {
                "name": "Bangao",
                "countryCode": "PH"
            },
            {
                "name": "Bangued",
                "countryCode": "PH"
            },
            {
                "name": "Bantay",
                "countryCode": "PH"
            },
            {
                "name": "Barlig",
                "countryCode": "PH"
            },
            {
                "name": "Bauko",
                "countryCode": "PH"
            },
            {
                "name": "Bayabas",
                "countryCode": "PH"
            },
            {
                "name": "Besao",
                "countryCode": "PH"
            },
            {
                "name": "Betwagan",
                "countryCode": "PH"
            },
            {
                "name": "Bocos",
                "countryCode": "PH"
            },
            {
                "name": "Bokod",
                "countryCode": "PH"
            },
            {
                "name": "Boliney",
                "countryCode": "PH"
            },
            {
                "name": "Bontoc",
                "countryCode": "PH"
            },
            {
                "name": "Bucay",
                "countryCode": "PH"
            },
            {
                "name": "Bucloc",
                "countryCode": "PH"
            },
            {
                "name": "Buguias",
                "countryCode": "PH"
            },
            {
                "name": "Bulalacao",
                "countryCode": "PH"
            },
            {
                "name": "Butigui",
                "countryCode": "PH"
            },
            {
                "name": "Calaba",
                "countryCode": "PH"
            },
            {
                "name": "Calanasan",
                "countryCode": "PH"
            },
            {
                "name": "Conner",
                "countryCode": "PH"
            },
            {
                "name": "Daguioman",
                "countryCode": "PH"
            },
            {
                "name": "Dalipey",
                "countryCode": "PH"
            },
            {
                "name": "Dalupirip",
                "countryCode": "PH"
            },
            {
                "name": "Danglas",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Flora",
                "countryCode": "PH"
            },
            {
                "name": "Gambang",
                "countryCode": "PH"
            },
            {
                "name": "Guinsadan",
                "countryCode": "PH"
            },
            {
                "name": "Hapao",
                "countryCode": "PH"
            },
            {
                "name": "Hingyon",
                "countryCode": "PH"
            },
            {
                "name": "Hungduan",
                "countryCode": "PH"
            },
            {
                "name": "Itogon",
                "countryCode": "PH"
            },
            {
                "name": "Kabayan",
                "countryCode": "PH"
            },
            {
                "name": "Kabugao",
                "countryCode": "PH"
            },
            {
                "name": "Kapangan",
                "countryCode": "PH"
            },
            {
                "name": "Kiangan",
                "countryCode": "PH"
            },
            {
                "name": "Kibungan",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Trinidad",
                "countryCode": "PH"
            },
            {
                "name": "Lacub",
                "countryCode": "PH"
            },
            {
                "name": "Lagangilang",
                "countryCode": "PH"
            },
            {
                "name": "Lagawe",
                "countryCode": "PH"
            },
            {
                "name": "Lagayan",
                "countryCode": "PH"
            },
            {
                "name": "Lamut",
                "countryCode": "PH"
            },
            {
                "name": "Langiden",
                "countryCode": "PH"
            },
            {
                "name": "Laya",
                "countryCode": "PH"
            },
            {
                "name": "Licuan",
                "countryCode": "PH"
            },
            {
                "name": "Licuan-Baay",
                "countryCode": "PH"
            },
            {
                "name": "Liwan",
                "countryCode": "PH"
            },
            {
                "name": "Loacan",
                "countryCode": "PH"
            },
            {
                "name": "Luba",
                "countryCode": "PH"
            },
            {
                "name": "Lubuagan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Malibcong",
                "countryCode": "PH"
            },
            {
                "name": "Manabo",
                "countryCode": "PH"
            },
            {
                "name": "Mankayan",
                "countryCode": "PH"
            },
            {
                "name": "Mayoyao",
                "countryCode": "PH"
            },
            {
                "name": "Monamon",
                "countryCode": "PH"
            },
            {
                "name": "Nangalisan",
                "countryCode": "PH"
            },
            {
                "name": "Natonin",
                "countryCode": "PH"
            },
            {
                "name": "Natubleng",
                "countryCode": "PH"
            },
            {
                "name": "Paracelis",
                "countryCode": "PH"
            },
            {
                "name": "Pasil",
                "countryCode": "PH"
            },
            {
                "name": "Peñarrubia",
                "countryCode": "PH"
            },
            {
                "name": "Pidigan",
                "countryCode": "PH"
            },
            {
                "name": "Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Pinukpuk",
                "countryCode": "PH"
            },
            {
                "name": "Potia",
                "countryCode": "PH"
            },
            {
                "name": "Pudtol",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Sabangan",
                "countryCode": "PH"
            },
            {
                "name": "Sablan",
                "countryCode": "PH"
            },
            {
                "name": "Sadanga",
                "countryCode": "PH"
            },
            {
                "name": "Sadsadan",
                "countryCode": "PH"
            },
            {
                "name": "Sagada",
                "countryCode": "PH"
            },
            {
                "name": "Sal-Lapadan",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Quintin",
                "countryCode": "PH"
            },
            {
                "name": "San Ramon",
                "countryCode": "PH"
            },
            {
                "name": "Santa Marcela",
                "countryCode": "PH"
            },
            {
                "name": "Tabaan",
                "countryCode": "PH"
            },
            {
                "name": "Tabio",
                "countryCode": "PH"
            },
            {
                "name": "Tabuk",
                "countryCode": "PH"
            },
            {
                "name": "Tacadang",
                "countryCode": "PH"
            },
            {
                "name": "Tadian",
                "countryCode": "PH"
            },
            {
                "name": "Taloy",
                "countryCode": "PH"
            },
            {
                "name": "Tanudan",
                "countryCode": "PH"
            },
            {
                "name": "Tayum",
                "countryCode": "PH"
            },
            {
                "name": "Tineg",
                "countryCode": "PH"
            },
            {
                "name": "Tinglayan",
                "countryCode": "PH"
            },
            {
                "name": "Tinoc",
                "countryCode": "PH"
            },
            {
                "name": "Topdac",
                "countryCode": "PH"
            },
            {
                "name": "Tuba",
                "countryCode": "PH"
            },
            {
                "name": "Tublay",
                "countryCode": "PH"
            },
            {
                "name": "Tubo",
                "countryCode": "PH"
            },
            {
                "name": "Tuding",
                "countryCode": "PH"
            },
            {
                "name": "Villarosa",
                "countryCode": "PH"
            },
            {
                "name": "Villaviciosa",
                "countryCode": "PH"
            },
            {
                "name": "Andili",
                "countryCode": "PH"
            },
            {
                "name": "Andop",
                "countryCode": "PH"
            },
            {
                "name": "Anibongan",
                "countryCode": "PH"
            },
            {
                "name": "Astorga",
                "countryCode": "PH"
            },
            {
                "name": "Asuncion",
                "countryCode": "PH"
            },
            {
                "name": "Babag",
                "countryCode": "PH"
            },
            {
                "name": "Baculin",
                "countryCode": "PH"
            },
            {
                "name": "Baganga",
                "countryCode": "PH"
            },
            {
                "name": "Balagunan",
                "countryCode": "PH"
            },
            {
                "name": "Balangonan",
                "countryCode": "PH"
            },
            {
                "name": "Balutakay",
                "countryCode": "PH"
            },
            {
                "name": "Banaybanay",
                "countryCode": "PH"
            },
            {
                "name": "Bansalan",
                "countryCode": "PH"
            },
            {
                "name": "Bantacan",
                "countryCode": "PH"
            },
            {
                "name": "Baon",
                "countryCode": "PH"
            },
            {
                "name": "Baracatan",
                "countryCode": "PH"
            },
            {
                "name": "Basiawan",
                "countryCode": "PH"
            },
            {
                "name": "Batiano",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Batobato",
                "countryCode": "PH"
            },
            {
                "name": "Baylo",
                "countryCode": "PH"
            },
            {
                "name": "Biao",
                "countryCode": "PH"
            },
            {
                "name": "Bincoñgan",
                "countryCode": "PH"
            },
            {
                "name": "Bitaogan",
                "countryCode": "PH"
            },
            {
                "name": "Bobon",
                "countryCode": "PH"
            },
            {
                "name": "Bolila",
                "countryCode": "PH"
            },
            {
                "name": "Boston",
                "countryCode": "PH"
            },
            {
                "name": "Buclad",
                "countryCode": "PH"
            },
            {
                "name": "Buhangin",
                "countryCode": "PH"
            },
            {
                "name": "Bukid",
                "countryCode": "PH"
            },
            {
                "name": "Bulacan",
                "countryCode": "PH"
            },
            {
                "name": "Bungabon",
                "countryCode": "PH"
            },
            {
                "name": "Butulan",
                "countryCode": "PH"
            },
            {
                "name": "Cabayangan",
                "countryCode": "PH"
            },
            {
                "name": "Cabinuangan",
                "countryCode": "PH"
            },
            {
                "name": "Caburan",
                "countryCode": "PH"
            },
            {
                "name": "Cambanugoy",
                "countryCode": "PH"
            },
            {
                "name": "Camudmud",
                "countryCode": "PH"
            },
            {
                "name": "Caraga",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Cateel",
                "countryCode": "PH"
            },
            {
                "name": "City of Mati",
                "countryCode": "PH"
            },
            {
                "name": "City of Panabo",
                "countryCode": "PH"
            },
            {
                "name": "City of Tagum",
                "countryCode": "PH"
            },
            {
                "name": "Cogon",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Corocotan",
                "countryCode": "PH"
            },
            {
                "name": "Coronon",
                "countryCode": "PH"
            },
            {
                "name": "Cuambog",
                "countryCode": "PH"
            },
            {
                "name": "Culaman",
                "countryCode": "PH"
            },
            {
                "name": "Dacudao",
                "countryCode": "PH"
            },
            {
                "name": "Dakbayan sa Digos",
                "countryCode": "PH"
            },
            {
                "name": "Davan",
                "countryCode": "PH"
            },
            {
                "name": "Davao City",
                "countryCode": "PH"
            },
            {
                "name": "Del Pilar",
                "countryCode": "PH"
            },
            {
                "name": "Digos",
                "countryCode": "PH"
            },
            {
                "name": "Dolo",
                "countryCode": "PH"
            },
            {
                "name": "Don Marcelino",
                "countryCode": "PH"
            },
            {
                "name": "Dumlan",
                "countryCode": "PH"
            },
            {
                "name": "Esperanza",
                "countryCode": "PH"
            },
            {
                "name": "Gabi",
                "countryCode": "PH"
            },
            {
                "name": "Gabuyan",
                "countryCode": "PH"
            },
            {
                "name": "Goma",
                "countryCode": "PH"
            },
            {
                "name": "Governor Generoso",
                "countryCode": "PH"
            },
            {
                "name": "Guihing Proper",
                "countryCode": "PH"
            },
            {
                "name": "Gumalang",
                "countryCode": "PH"
            },
            {
                "name": "Gupitan",
                "countryCode": "PH"
            },
            {
                "name": "Hagonoy",
                "countryCode": "PH"
            },
            {
                "name": "Ignit",
                "countryCode": "PH"
            },
            {
                "name": "Ilangay",
                "countryCode": "PH"
            },
            {
                "name": "Inawayan",
                "countryCode": "PH"
            },
            {
                "name": "Island Garden City of Samal",
                "countryCode": "PH"
            },
            {
                "name": "Jose Abad Santos",
                "countryCode": "PH"
            },
            {
                "name": "Jovellar",
                "countryCode": "PH"
            },
            {
                "name": "Kalbay",
                "countryCode": "PH"
            },
            {
                "name": "Kalian",
                "countryCode": "PH"
            },
            {
                "name": "Kaligutan",
                "countryCode": "PH"
            },
            {
                "name": "Kapalong",
                "countryCode": "PH"
            },
            {
                "name": "Katipunan",
                "countryCode": "PH"
            },
            {
                "name": "Kiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Kinablangan",
                "countryCode": "PH"
            },
            {
                "name": "Kinamayan",
                "countryCode": "PH"
            },
            {
                "name": "Kinangan",
                "countryCode": "PH"
            },
            {
                "name": "La Libertad",
                "countryCode": "PH"
            },
            {
                "name": "La Paz",
                "countryCode": "PH"
            },
            {
                "name": "La Union",
                "countryCode": "PH"
            },
            {
                "name": "Lacaron",
                "countryCode": "PH"
            },
            {
                "name": "Lacson",
                "countryCode": "PH"
            },
            {
                "name": "Lais",
                "countryCode": "PH"
            },
            {
                "name": "Lamitan",
                "countryCode": "PH"
            },
            {
                "name": "Lapuan",
                "countryCode": "PH"
            },
            {
                "name": "Lasang",
                "countryCode": "PH"
            },
            {
                "name": "Libuganon",
                "countryCode": "PH"
            },
            {
                "name": "Limao",
                "countryCode": "PH"
            },
            {
                "name": "Limot",
                "countryCode": "PH"
            },
            {
                "name": "Linao",
                "countryCode": "PH"
            },
            {
                "name": "Linoan",
                "countryCode": "PH"
            },
            {
                "name": "Lukatan",
                "countryCode": "PH"
            },
            {
                "name": "Luna",
                "countryCode": "PH"
            },
            {
                "name": "Lungaog",
                "countryCode": "PH"
            },
            {
                "name": "Lupon",
                "countryCode": "PH"
            },
            {
                "name": "Luzon",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabuhay",
                "countryCode": "PH"
            },
            {
                "name": "Maco",
                "countryCode": "PH"
            },
            {
                "name": "Maduao",
                "countryCode": "PH"
            },
            {
                "name": "Magatos",
                "countryCode": "PH"
            },
            {
                "name": "Magdug",
                "countryCode": "PH"
            },
            {
                "name": "Magnaga",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Mahanob",
                "countryCode": "PH"
            },
            {
                "name": "Mahayag",
                "countryCode": "PH"
            },
            {
                "name": "Malagos",
                "countryCode": "PH"
            },
            {
                "name": "Malalag",
                "countryCode": "PH"
            },
            {
                "name": "Malinao",
                "countryCode": "PH"
            },
            {
                "name": "Malita",
                "countryCode": "PH"
            },
            {
                "name": "Mambago",
                "countryCode": "PH"
            },
            {
                "name": "Managa",
                "countryCode": "PH"
            },
            {
                "name": "Manaloal",
                "countryCode": "PH"
            },
            {
                "name": "Manat",
                "countryCode": "PH"
            },
            {
                "name": "Manay",
                "countryCode": "PH"
            },
            {
                "name": "Mangili",
                "countryCode": "PH"
            },
            {
                "name": "Manikling",
                "countryCode": "PH"
            },
            {
                "name": "Matanao",
                "countryCode": "PH"
            },
            {
                "name": "Mati",
                "countryCode": "PH"
            },
            {
                "name": "Matiao",
                "countryCode": "PH"
            },
            {
                "name": "Matti",
                "countryCode": "PH"
            },
            {
                "name": "Mawab",
                "countryCode": "PH"
            },
            {
                "name": "Mayo",
                "countryCode": "PH"
            },
            {
                "name": "Monkayo",
                "countryCode": "PH"
            },
            {
                "name": "Montevista",
                "countryCode": "PH"
            },
            {
                "name": "Nabunturan",
                "countryCode": "PH"
            },
            {
                "name": "Nangan",
                "countryCode": "PH"
            },
            {
                "name": "Nanyo",
                "countryCode": "PH"
            },
            {
                "name": "New Baclayon",
                "countryCode": "PH"
            },
            {
                "name": "New Bataan",
                "countryCode": "PH"
            },
            {
                "name": "New Bohol",
                "countryCode": "PH"
            },
            {
                "name": "New Corella",
                "countryCode": "PH"
            },
            {
                "name": "New Leyte",
                "countryCode": "PH"
            },
            {
                "name": "New Sibonga",
                "countryCode": "PH"
            },
            {
                "name": "New Visayas",
                "countryCode": "PH"
            },
            {
                "name": "Nuing",
                "countryCode": "PH"
            },
            {
                "name": "Padada",
                "countryCode": "PH"
            },
            {
                "name": "Pag-asa",
                "countryCode": "PH"
            },
            {
                "name": "Pagsabangan",
                "countryCode": "PH"
            },
            {
                "name": "Palma Gil",
                "countryCode": "PH"
            },
            {
                "name": "Panabo",
                "countryCode": "PH"
            },
            {
                "name": "Pandasan",
                "countryCode": "PH"
            },
            {
                "name": "Pangian",
                "countryCode": "PH"
            },
            {
                "name": "Panikian",
                "countryCode": "PH"
            },
            {
                "name": "Pantukan",
                "countryCode": "PH"
            },
            {
                "name": "Pasian",
                "countryCode": "PH"
            },
            {
                "name": "Pondaguitan",
                "countryCode": "PH"
            },
            {
                "name": "Pung-Pang",
                "countryCode": "PH"
            },
            {
                "name": "Samal",
                "countryCode": "PH"
            },
            {
                "name": "Sampao",
                "countryCode": "PH"
            },
            {
                "name": "San Alfonso",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Ignacio",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Remigio",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Sarangani",
                "countryCode": "PH"
            },
            {
                "name": "Sibulan",
                "countryCode": "PH"
            },
            {
                "name": "Sigaboy",
                "countryCode": "PH"
            },
            {
                "name": "Simod",
                "countryCode": "PH"
            },
            {
                "name": "Sinawilan",
                "countryCode": "PH"
            },
            {
                "name": "Sinayawan",
                "countryCode": "PH"
            },
            {
                "name": "Sirib",
                "countryCode": "PH"
            },
            {
                "name": "Sugal",
                "countryCode": "PH"
            },
            {
                "name": "Sulop",
                "countryCode": "PH"
            },
            {
                "name": "Surup",
                "countryCode": "PH"
            },
            {
                "name": "Suz-on",
                "countryCode": "PH"
            },
            {
                "name": "Tagakpan",
                "countryCode": "PH"
            },
            {
                "name": "Tagdanua",
                "countryCode": "PH"
            },
            {
                "name": "Tagnanan",
                "countryCode": "PH"
            },
            {
                "name": "Tagum",
                "countryCode": "PH"
            },
            {
                "name": "Takub",
                "countryCode": "PH"
            },
            {
                "name": "Talagutong",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Talomo",
                "countryCode": "PH"
            },
            {
                "name": "Tamayong",
                "countryCode": "PH"
            },
            {
                "name": "Tambo",
                "countryCode": "PH"
            },
            {
                "name": "Tamisan",
                "countryCode": "PH"
            },
            {
                "name": "Tamugan",
                "countryCode": "PH"
            },
            {
                "name": "Tanlad",
                "countryCode": "PH"
            },
            {
                "name": "Tapia",
                "countryCode": "PH"
            },
            {
                "name": "Tarragona",
                "countryCode": "PH"
            },
            {
                "name": "Tawan tawan",
                "countryCode": "PH"
            },
            {
                "name": "Taytayan",
                "countryCode": "PH"
            },
            {
                "name": "Tibagon",
                "countryCode": "PH"
            },
            {
                "name": "Tibanbang",
                "countryCode": "PH"
            },
            {
                "name": "Tiblawan",
                "countryCode": "PH"
            },
            {
                "name": "Tombongon",
                "countryCode": "PH"
            },
            {
                "name": "Tubalan",
                "countryCode": "PH"
            },
            {
                "name": "Tuban",
                "countryCode": "PH"
            },
            {
                "name": "Tubod",
                "countryCode": "PH"
            },
            {
                "name": "Tuganay",
                "countryCode": "PH"
            },
            {
                "name": "Tuli",
                "countryCode": "PH"
            },
            {
                "name": "Ula",
                "countryCode": "PH"
            },
            {
                "name": "Wañgan",
                "countryCode": "PH"
            },
            {
                "name": "Wines",
                "countryCode": "PH"
            },
            {
                "name": "Abuyon",
                "countryCode": "PH"
            },
            {
                "name": "Aga",
                "countryCode": "PH"
            },
            {
                "name": "Agdangan",
                "countryCode": "PH"
            },
            {
                "name": "Agoncillo",
                "countryCode": "PH"
            },
            {
                "name": "Alabat",
                "countryCode": "PH"
            },
            {
                "name": "Alaminos",
                "countryCode": "PH"
            },
            {
                "name": "Alfonso",
                "countryCode": "PH"
            },
            {
                "name": "Aliang",
                "countryCode": "PH"
            },
            {
                "name": "Alitagtag",
                "countryCode": "PH"
            },
            {
                "name": "Alupay",
                "countryCode": "PH"
            },
            {
                "name": "Amadeo",
                "countryCode": "PH"
            },
            {
                "name": "Ambulong",
                "countryCode": "PH"
            },
            {
                "name": "Angono",
                "countryCode": "PH"
            },
            {
                "name": "Antipolo",
                "countryCode": "PH"
            },
            {
                "name": "Anuling",
                "countryCode": "PH"
            },
            {
                "name": "Aplaya",
                "countryCode": "PH"
            },
            {
                "name": "Atimonan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Aya",
                "countryCode": "PH"
            },
            {
                "name": "Ayusan Uno",
                "countryCode": "PH"
            },
            {
                "name": "Bacoor",
                "countryCode": "PH"
            },
            {
                "name": "Bagalangit",
                "countryCode": "PH"
            },
            {
                "name": "Bagombong",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Pagasa",
                "countryCode": "PH"
            },
            {
                "name": "Bagupaye",
                "countryCode": "PH"
            },
            {
                "name": "Balagtasin",
                "countryCode": "PH"
            },
            {
                "name": "Balayan",
                "countryCode": "PH"
            },
            {
                "name": "Balele",
                "countryCode": "PH"
            },
            {
                "name": "Balete",
                "countryCode": "PH"
            },
            {
                "name": "Balibago",
                "countryCode": "PH"
            },
            {
                "name": "Balite Segundo",
                "countryCode": "PH"
            },
            {
                "name": "Balitoc",
                "countryCode": "PH"
            },
            {
                "name": "Banaba",
                "countryCode": "PH"
            },
            {
                "name": "Banalo",
                "countryCode": "PH"
            },
            {
                "name": "Banaybanay",
                "countryCode": "PH"
            },
            {
                "name": "Banilad",
                "countryCode": "PH"
            },
            {
                "name": "Bantilan",
                "countryCode": "PH"
            },
            {
                "name": "Banugao",
                "countryCode": "PH"
            },
            {
                "name": "Baras",
                "countryCode": "PH"
            },
            {
                "name": "Batangas",
                "countryCode": "PH"
            },
            {
                "name": "Batas",
                "countryCode": "PH"
            },
            {
                "name": "Bauan",
                "countryCode": "PH"
            },
            {
                "name": "Bautista",
                "countryCode": "PH"
            },
            {
                "name": "Bay",
                "countryCode": "PH"
            },
            {
                "name": "Baybayin",
                "countryCode": "PH"
            },
            {
                "name": "Biga",
                "countryCode": "PH"
            },
            {
                "name": "Bignay Uno",
                "countryCode": "PH"
            },
            {
                "name": "Bilaran",
                "countryCode": "PH"
            },
            {
                "name": "Bilog-Bilog",
                "countryCode": "PH"
            },
            {
                "name": "Binahaan",
                "countryCode": "PH"
            },
            {
                "name": "Binangonan",
                "countryCode": "PH"
            },
            {
                "name": "Binay",
                "countryCode": "PH"
            },
            {
                "name": "Binubusan",
                "countryCode": "PH"
            },
            {
                "name": "Binulasan",
                "countryCode": "PH"
            },
            {
                "name": "Bitangan",
                "countryCode": "PH"
            },
            {
                "name": "Bitin",
                "countryCode": "PH"
            },
            {
                "name": "Biñan",
                "countryCode": "PH"
            },
            {
                "name": "Bolboc",
                "countryCode": "PH"
            },
            {
                "name": "Bolo",
                "countryCode": "PH"
            },
            {
                "name": "Boot",
                "countryCode": "PH"
            },
            {
                "name": "Bosdak",
                "countryCode": "PH"
            },
            {
                "name": "Bugaan",
                "countryCode": "PH"
            },
            {
                "name": "Bukal",
                "countryCode": "PH"
            },
            {
                "name": "Bukal Sur",
                "countryCode": "PH"
            },
            {
                "name": "Bulacnin",
                "countryCode": "PH"
            },
            {
                "name": "Bulihan",
                "countryCode": "PH"
            },
            {
                "name": "Bungahan",
                "countryCode": "PH"
            },
            {
                "name": "Bungoy",
                "countryCode": "PH"
            },
            {
                "name": "Burdeos",
                "countryCode": "PH"
            },
            {
                "name": "Cabanbanan",
                "countryCode": "PH"
            },
            {
                "name": "Cabatang",
                "countryCode": "PH"
            },
            {
                "name": "Cabay",
                "countryCode": "PH"
            },
            {
                "name": "Cabuyao",
                "countryCode": "PH"
            },
            {
                "name": "Cagsiay",
                "countryCode": "PH"
            },
            {
                "name": "Cainta",
                "countryCode": "PH"
            },
            {
                "name": "Calaca",
                "countryCode": "PH"
            },
            {
                "name": "Calamba",
                "countryCode": "PH"
            },
            {
                "name": "Calantas",
                "countryCode": "PH"
            },
            {
                "name": "Calatagan",
                "countryCode": "PH"
            },
            {
                "name": "Calauag",
                "countryCode": "PH"
            },
            {
                "name": "Calauan",
                "countryCode": "PH"
            },
            {
                "name": "Calilayan",
                "countryCode": "PH"
            },
            {
                "name": "Calubcub Dos",
                "countryCode": "PH"
            },
            {
                "name": "Calumpang",
                "countryCode": "PH"
            },
            {
                "name": "Cambuga",
                "countryCode": "PH"
            },
            {
                "name": "Camohaguin",
                "countryCode": "PH"
            },
            {
                "name": "Camp Flora",
                "countryCode": "PH"
            },
            {
                "name": "Candelaria",
                "countryCode": "PH"
            },
            {
                "name": "Capuluan",
                "countryCode": "PH"
            },
            {
                "name": "Cardona",
                "countryCode": "PH"
            },
            {
                "name": "Carmona",
                "countryCode": "PH"
            },
            {
                "name": "Casay",
                "countryCode": "PH"
            },
            {
                "name": "Castañas",
                "countryCode": "PH"
            },
            {
                "name": "Casuguran",
                "countryCode": "PH"
            },
            {
                "name": "Catanauan",
                "countryCode": "PH"
            },
            {
                "name": "Cavinti",
                "countryCode": "PH"
            },
            {
                "name": "Cavite City",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan",
                "countryCode": "PH"
            },
            {
                "name": "Cigaras",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion Ibaba",
                "countryCode": "PH"
            },
            {
                "name": "Cuenca",
                "countryCode": "PH"
            },
            {
                "name": "Dagatan",
                "countryCode": "PH"
            },
            {
                "name": "Daraitan",
                "countryCode": "PH"
            },
            {
                "name": "Dasmariñas",
                "countryCode": "PH"
            },
            {
                "name": "Dayap",
                "countryCode": "PH"
            },
            {
                "name": "Dayapan",
                "countryCode": "PH"
            },
            {
                "name": "Del Monte",
                "countryCode": "PH"
            },
            {
                "name": "Dinahican",
                "countryCode": "PH"
            },
            {
                "name": "Dolores",
                "countryCode": "PH"
            },
            {
                "name": "Famy",
                "countryCode": "PH"
            },
            {
                "name": "General Emilio Aguinaldo",
                "countryCode": "PH"
            },
            {
                "name": "General Luna",
                "countryCode": "PH"
            },
            {
                "name": "General Nakar",
                "countryCode": "PH"
            },
            {
                "name": "General Trias",
                "countryCode": "PH"
            },
            {
                "name": "Guinayangan, Fourth District of Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Guisguis",
                "countryCode": "PH"
            },
            {
                "name": "Gulod",
                "countryCode": "PH"
            },
            {
                "name": "Gumaca",
                "countryCode": "PH"
            },
            {
                "name": "Gumian",
                "countryCode": "PH"
            },
            {
                "name": "Guyam Malaki",
                "countryCode": "PH"
            },
            {
                "name": "Halayhay",
                "countryCode": "PH"
            },
            {
                "name": "Halayhayin",
                "countryCode": "PH"
            },
            {
                "name": "Haligue",
                "countryCode": "PH"
            },
            {
                "name": "Hondagua",
                "countryCode": "PH"
            },
            {
                "name": "Hukay",
                "countryCode": "PH"
            },
            {
                "name": "Ibaan",
                "countryCode": "PH"
            },
            {
                "name": "Ibabang Tayuman",
                "countryCode": "PH"
            },
            {
                "name": "Ilihan",
                "countryCode": "PH"
            },
            {
                "name": "Imus",
                "countryCode": "PH"
            },
            {
                "name": "Indang",
                "countryCode": "PH"
            },
            {
                "name": "Infanta",
                "countryCode": "PH"
            },
            {
                "name": "Inicbulan",
                "countryCode": "PH"
            },
            {
                "name": "Isabang",
                "countryCode": "PH"
            },
            {
                "name": "Jalajala",
                "countryCode": "PH"
            },
            {
                "name": "Janagdong",
                "countryCode": "PH"
            },
            {
                "name": "Janopol",
                "countryCode": "PH"
            },
            {
                "name": "Javalera",
                "countryCode": "PH"
            },
            {
                "name": "Kabulusan",
                "countryCode": "PH"
            },
            {
                "name": "Kalayaan",
                "countryCode": "PH"
            },
            {
                "name": "Kanluran",
                "countryCode": "PH"
            },
            {
                "name": "Kapatalan",
                "countryCode": "PH"
            },
            {
                "name": "Karligan",
                "countryCode": "PH"
            },
            {
                "name": "Kawit",
                "countryCode": "PH"
            },
            {
                "name": "Kaytitinga",
                "countryCode": "PH"
            },
            {
                "name": "Kiloloran",
                "countryCode": "PH"
            },
            {
                "name": "Kinalaglagan",
                "countryCode": "PH"
            },
            {
                "name": "Kinatakutan",
                "countryCode": "PH"
            },
            {
                "name": "Lacdayan",
                "countryCode": "PH"
            },
            {
                "name": "Laiya",
                "countryCode": "PH"
            },
            {
                "name": "Lalig",
                "countryCode": "PH"
            },
            {
                "name": "Lapolapo",
                "countryCode": "PH"
            },
            {
                "name": "Laurel",
                "countryCode": "PH"
            },
            {
                "name": "Lemery",
                "countryCode": "PH"
            },
            {
                "name": "Lian",
                "countryCode": "PH"
            },
            {
                "name": "Libato",
                "countryCode": "PH"
            },
            {
                "name": "Lilio",
                "countryCode": "PH"
            },
            {
                "name": "Lipa City",
                "countryCode": "PH"
            },
            {
                "name": "Lipahan",
                "countryCode": "PH"
            },
            {
                "name": "Lobo",
                "countryCode": "PH"
            },
            {
                "name": "Looc",
                "countryCode": "PH"
            },
            {
                "name": "Lopez",
                "countryCode": "PH"
            },
            {
                "name": "Los Baños",
                "countryCode": "PH"
            },
            {
                "name": "Lucban",
                "countryCode": "PH"
            },
            {
                "name": "Lucena",
                "countryCode": "PH"
            },
            {
                "name": "Lucsuhin",
                "countryCode": "PH"
            },
            {
                "name": "Luisiana",
                "countryCode": "PH"
            },
            {
                "name": "Luksuhin",
                "countryCode": "PH"
            },
            {
                "name": "Lumbang",
                "countryCode": "PH"
            },
            {
                "name": "Lumbangan",
                "countryCode": "PH"
            },
            {
                "name": "Lumil",
                "countryCode": "PH"
            },
            {
                "name": "Luntal",
                "countryCode": "PH"
            },
            {
                "name": "Lusacan",
                "countryCode": "PH"
            },
            {
                "name": "Mabini",
                "countryCode": "PH"
            },
            {
                "name": "Mabitac",
                "countryCode": "PH"
            },
            {
                "name": "Mabunga",
                "countryCode": "PH"
            },
            {
                "name": "Macalamcam A",
                "countryCode": "PH"
            },
            {
                "name": "Macalelon",
                "countryCode": "PH"
            },
            {
                "name": "Madulao",
                "countryCode": "PH"
            },
            {
                "name": "Magallanes",
                "countryCode": "PH"
            },
            {
                "name": "Magdalena",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Maguyam",
                "countryCode": "PH"
            },
            {
                "name": "Mahabang Parang",
                "countryCode": "PH"
            },
            {
                "name": "Mainit Norte",
                "countryCode": "PH"
            },
            {
                "name": "Majayjay",
                "countryCode": "PH"
            },
            {
                "name": "Malabag",
                "countryCode": "PH"
            },
            {
                "name": "Malabanan",
                "countryCode": "PH"
            },
            {
                "name": "Malabanban Norte",
                "countryCode": "PH"
            },
            {
                "name": "Malainen Luma",
                "countryCode": "PH"
            },
            {
                "name": "Malanday",
                "countryCode": "PH"
            },
            {
                "name": "Malaruhatan",
                "countryCode": "PH"
            },
            {
                "name": "Malaya",
                "countryCode": "PH"
            },
            {
                "name": "Malicboy",
                "countryCode": "PH"
            },
            {
                "name": "Malinao Ilaya",
                "countryCode": "PH"
            },
            {
                "name": "Malvar",
                "countryCode": "PH"
            },
            {
                "name": "Mamala",
                "countryCode": "PH"
            },
            {
                "name": "Mamatid",
                "countryCode": "PH"
            },
            {
                "name": "Mangas",
                "countryCode": "PH"
            },
            {
                "name": "Mangero",
                "countryCode": "PH"
            },
            {
                "name": "Manggahan",
                "countryCode": "PH"
            },
            {
                "name": "Mapulo",
                "countryCode": "PH"
            },
            {
                "name": "Mapulot",
                "countryCode": "PH"
            },
            {
                "name": "Maragondon",
                "countryCode": "PH"
            },
            {
                "name": "Marao",
                "countryCode": "PH"
            },
            {
                "name": "Masalukot Uno",
                "countryCode": "PH"
            },
            {
                "name": "Masapang",
                "countryCode": "PH"
            },
            {
                "name": "Masaya",
                "countryCode": "PH"
            },
            {
                "name": "Mataas Na Kahoy",
                "countryCode": "PH"
            },
            {
                "name": "Matagbak",
                "countryCode": "PH"
            },
            {
                "name": "Matala",
                "countryCode": "PH"
            },
            {
                "name": "Mataywanac",
                "countryCode": "PH"
            },
            {
                "name": "Matingain",
                "countryCode": "PH"
            },
            {
                "name": "Mauban",
                "countryCode": "PH"
            },
            {
                "name": "Maugat West",
                "countryCode": "PH"
            },
            {
                "name": "Maulawin",
                "countryCode": "PH"
            },
            {
                "name": "Mendez-Nuñez",
                "countryCode": "PH"
            },
            {
                "name": "Montecillo",
                "countryCode": "PH"
            },
            {
                "name": "Morong",
                "countryCode": "PH"
            },
            {
                "name": "Mozon",
                "countryCode": "PH"
            },
            {
                "name": "Mulanay",
                "countryCode": "PH"
            },
            {
                "name": "Mulauin",
                "countryCode": "PH"
            },
            {
                "name": "Nagcarlan",
                "countryCode": "PH"
            },
            {
                "name": "Naic",
                "countryCode": "PH"
            },
            {
                "name": "Nasugbu",
                "countryCode": "PH"
            },
            {
                "name": "Navotas",
                "countryCode": "PH"
            },
            {
                "name": "Noveleta",
                "countryCode": "PH"
            },
            {
                "name": "Paagahan",
                "countryCode": "PH"
            },
            {
                "name": "Padre Burgos",
                "countryCode": "PH"
            },
            {
                "name": "Padre Garcia",
                "countryCode": "PH"
            },
            {
                "name": "Paete",
                "countryCode": "PH"
            },
            {
                "name": "Pagbilao",
                "countryCode": "PH"
            },
            {
                "name": "Pagsanjan",
                "countryCode": "PH"
            },
            {
                "name": "Pagsañgahan",
                "countryCode": "PH"
            },
            {
                "name": "Paiisa",
                "countryCode": "PH"
            },
            {
                "name": "Pakil",
                "countryCode": "PH"
            },
            {
                "name": "Palahanan Uno",
                "countryCode": "PH"
            },
            {
                "name": "Palangue",
                "countryCode": "PH"
            },
            {
                "name": "Pangao",
                "countryCode": "PH"
            },
            {
                "name": "Pangil",
                "countryCode": "PH"
            },
            {
                "name": "Panikihan",
                "countryCode": "PH"
            },
            {
                "name": "Pansol",
                "countryCode": "PH"
            },
            {
                "name": "Pansoy",
                "countryCode": "PH"
            },
            {
                "name": "Pantay Na Matanda",
                "countryCode": "PH"
            },
            {
                "name": "Pantijan No 2",
                "countryCode": "PH"
            },
            {
                "name": "Panukulan",
                "countryCode": "PH"
            },
            {
                "name": "Paradahan",
                "countryCode": "PH"
            },
            {
                "name": "Pasong Kawayan Primero",
                "countryCode": "PH"
            },
            {
                "name": "Patabog",
                "countryCode": "PH"
            },
            {
                "name": "Patnanungan",
                "countryCode": "PH"
            },
            {
                "name": "Patuto",
                "countryCode": "PH"
            },
            {
                "name": "Payapa",
                "countryCode": "PH"
            },
            {
                "name": "Perez",
                "countryCode": "PH"
            },
            {
                "name": "Pila",
                "countryCode": "PH"
            },
            {
                "name": "Pililla",
                "countryCode": "PH"
            },
            {
                "name": "Pinagsibaan",
                "countryCode": "PH"
            },
            {
                "name": "Pinugay",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Poctol",
                "countryCode": "PH"
            },
            {
                "name": "Polillo",
                "countryCode": "PH"
            },
            {
                "name": "Prinza",
                "countryCode": "PH"
            },
            {
                "name": "Progreso",
                "countryCode": "PH"
            },
            {
                "name": "Province of Batangas",
                "countryCode": "PH"
            },
            {
                "name": "Province of Cavite",
                "countryCode": "PH"
            },
            {
                "name": "Province of Laguna",
                "countryCode": "PH"
            },
            {
                "name": "Province of Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Province of Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Pulangbato",
                "countryCode": "PH"
            },
            {
                "name": "Pulo",
                "countryCode": "PH"
            },
            {
                "name": "Pulong Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Punta",
                "countryCode": "PH"
            },
            {
                "name": "Puting Kahoy",
                "countryCode": "PH"
            },
            {
                "name": "Putingkahoy",
                "countryCode": "PH"
            },
            {
                "name": "Putol",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quilo-quilo",
                "countryCode": "PH"
            },
            {
                "name": "Quipot",
                "countryCode": "PH"
            },
            {
                "name": "Quisao",
                "countryCode": "PH"
            },
            {
                "name": "Real",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Rodriguez",
                "countryCode": "PH"
            },
            {
                "name": "Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Sampaloc",
                "countryCode": "PH"
            },
            {
                "name": "Sampiro",
                "countryCode": "PH"
            },
            {
                "name": "San Andres",
                "countryCode": "PH"
            },
            {
                "name": "San Antonio",
                "countryCode": "PH"
            },
            {
                "name": "San Carlos",
                "countryCode": "PH"
            },
            {
                "name": "San Celestio",
                "countryCode": "PH"
            },
            {
                "name": "San Diego",
                "countryCode": "PH"
            },
            {
                "name": "San Francisco",
                "countryCode": "PH"
            },
            {
                "name": "San Gregorio",
                "countryCode": "PH"
            },
            {
                "name": "San Isidro",
                "countryCode": "PH"
            },
            {
                "name": "San Joaquin",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Luis",
                "countryCode": "PH"
            },
            {
                "name": "San Mateo",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Narciso",
                "countryCode": "PH"
            },
            {
                "name": "San Nicolas",
                "countryCode": "PH"
            },
            {
                "name": "San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "San Pascual",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro One",
                "countryCode": "PH"
            },
            {
                "name": "San Rafael",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina Norte",
                "countryCode": "PH"
            },
            {
                "name": "Santa Catalina Sur",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cecilia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Lucia",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rita Aplaya",
                "countryCode": "PH"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresita",
                "countryCode": "PH"
            },
            {
                "name": "Santiago",
                "countryCode": "PH"
            },
            {
                "name": "Santisimo Rosario",
                "countryCode": "PH"
            },
            {
                "name": "Santo Niño",
                "countryCode": "PH"
            },
            {
                "name": "Santo Tomas",
                "countryCode": "PH"
            },
            {
                "name": "Santor",
                "countryCode": "PH"
            },
            {
                "name": "Sariaya",
                "countryCode": "PH"
            },
            {
                "name": "Sibulan",
                "countryCode": "PH"
            },
            {
                "name": "Sico Uno",
                "countryCode": "PH"
            },
            {
                "name": "Silang",
                "countryCode": "PH"
            },
            {
                "name": "Silongin",
                "countryCode": "PH"
            },
            {
                "name": "Sinala",
                "countryCode": "PH"
            },
            {
                "name": "Siniloan",
                "countryCode": "PH"
            },
            {
                "name": "Sinisian",
                "countryCode": "PH"
            },
            {
                "name": "Solo",
                "countryCode": "PH"
            },
            {
                "name": "Subic",
                "countryCode": "PH"
            },
            {
                "name": "Taal",
                "countryCode": "PH"
            },
            {
                "name": "Tagaytay",
                "countryCode": "PH"
            },
            {
                "name": "Tagbacan Ibaba",
                "countryCode": "PH"
            },
            {
                "name": "Tagkawayan Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Tala",
                "countryCode": "PH"
            },
            {
                "name": "Talaga",
                "countryCode": "PH"
            },
            {
                "name": "Talahib Payap",
                "countryCode": "PH"
            },
            {
                "name": "Talahiban I",
                "countryCode": "PH"
            },
            {
                "name": "Talaibon",
                "countryCode": "PH"
            },
            {
                "name": "Talipan",
                "countryCode": "PH"
            },
            {
                "name": "Talisay",
                "countryCode": "PH"
            },
            {
                "name": "Tanauan",
                "countryCode": "PH"
            },
            {
                "name": "Tanay",
                "countryCode": "PH"
            },
            {
                "name": "Tanza",
                "countryCode": "PH"
            },
            {
                "name": "Tayabas",
                "countryCode": "PH"
            },
            {
                "name": "Tayabas Ibaba",
                "countryCode": "PH"
            },
            {
                "name": "Taysan",
                "countryCode": "PH"
            },
            {
                "name": "Taytay",
                "countryCode": "PH"
            },
            {
                "name": "Taywanak Ilaya",
                "countryCode": "PH"
            },
            {
                "name": "Teresa",
                "countryCode": "PH"
            },
            {
                "name": "Ternate",
                "countryCode": "PH"
            },
            {
                "name": "Tiaong",
                "countryCode": "PH"
            },
            {
                "name": "Tignoan",
                "countryCode": "PH"
            },
            {
                "name": "Tingloy",
                "countryCode": "PH"
            },
            {
                "name": "Tipaz",
                "countryCode": "PH"
            },
            {
                "name": "Toong",
                "countryCode": "PH"
            },
            {
                "name": "Tranca",
                "countryCode": "PH"
            },
            {
                "name": "Tuhian",
                "countryCode": "PH"
            },
            {
                "name": "Tulay",
                "countryCode": "PH"
            },
            {
                "name": "Tumalim",
                "countryCode": "PH"
            },
            {
                "name": "Tuy",
                "countryCode": "PH"
            },
            {
                "name": "Unisan",
                "countryCode": "PH"
            },
            {
                "name": "Victoria",
                "countryCode": "PH"
            },
            {
                "name": "Wawa",
                "countryCode": "PH"
            },
            {
                "name": "Yuni",
                "countryCode": "PH"
            },
            {
                "name": "Aborlan",
                "countryCode": "PH"
            },
            {
                "name": "Abra de Ilog",
                "countryCode": "PH"
            },
            {
                "name": "Adela",
                "countryCode": "PH"
            },
            {
                "name": "Agcogon",
                "countryCode": "PH"
            },
            {
                "name": "Agutaya",
                "countryCode": "PH"
            },
            {
                "name": "Alad",
                "countryCode": "PH"
            },
            {
                "name": "Alcantara",
                "countryCode": "PH"
            },
            {
                "name": "Alemanguan",
                "countryCode": "PH"
            },
            {
                "name": "Algeciras",
                "countryCode": "PH"
            },
            {
                "name": "Alibug",
                "countryCode": "PH"
            },
            {
                "name": "Anilao",
                "countryCode": "PH"
            },
            {
                "name": "Apitong",
                "countryCode": "PH"
            },
            {
                "name": "Apurawan",
                "countryCode": "PH"
            },
            {
                "name": "Araceli",
                "countryCode": "PH"
            },
            {
                "name": "Aramawayan",
                "countryCode": "PH"
            },
            {
                "name": "Aramayuan",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Babug",
                "countryCode": "PH"
            },
            {
                "name": "Baco",
                "countryCode": "PH"
            },
            {
                "name": "Bacungan",
                "countryCode": "PH"
            },
            {
                "name": "Bagong Sikat",
                "countryCode": "PH"
            },
            {
                "name": "Baheli",
                "countryCode": "PH"
            },
            {
                "name": "Balabac",
                "countryCode": "PH"
            },
            {
                "name": "Balanacan",
                "countryCode": "PH"
            },
            {
                "name": "Balatero",
                "countryCode": "PH"
            },
            {
                "name": "Balugo",
                "countryCode": "PH"
            },
            {
                "name": "Banos",
                "countryCode": "PH"
            },
            {
                "name": "Bansud",
                "countryCode": "PH"
            },
            {
                "name": "Barahan",
                "countryCode": "PH"
            },
            {
                "name": "Barong Barong",
                "countryCode": "PH"
            },
            {
                "name": "Batarasa",
                "countryCode": "PH"
            },
            {
                "name": "Batasan",
                "countryCode": "PH"
            },
            {
                "name": "Bato",
                "countryCode": "PH"
            },
            {
                "name": "Bayuin",
                "countryCode": "PH"
            },
            {
                "name": "Bintacay",
                "countryCode": "PH"
            },
            {
                "name": "Boac",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Bulalacao",
                "countryCode": "PH"
            },
            {
                "name": "Buluang",
                "countryCode": "PH"
            },
            {
                "name": "Bunog",
                "countryCode": "PH"
            },
            {
                "name": "Burirao",
                "countryCode": "PH"
            },
            {
                "name": "Buyabod",
                "countryCode": "PH"
            },
            {
                "name": "Cabacao",
                "countryCode": "PH"
            },
            {
                "name": "Cabra",
                "countryCode": "PH"
            },
            {
                "name": "Cagayan",
                "countryCode": "PH"
            },
            {
                "name": "Cagayancillo",
                "countryCode": "PH"
            },
            {
                "name": "Caigangan",
                "countryCode": "PH"
            },
            {
                "name": "Cajidiocan",
                "countryCode": "PH"
            },
            {
                "name": "Cajimos",
                "countryCode": "PH"
            },
            {
                "name": "Calamundingan",
                "countryCode": "PH"
            },
            {
                "name": "Calapan",
                "countryCode": "PH"
            },
            {
                "name": "Calatrava",
                "countryCode": "PH"
            },
            {
                "name": "Calatugas",
                "countryCode": "PH"
            },
            {
                "name": "Calauag",
                "countryCode": "PH"
            },
            {
                "name": "Calintaan",
                "countryCode": "PH"
            },
            {
                "name": "Caminauit",
                "countryCode": "PH"
            },
            {
                "name": "Cantel",
                "countryCode": "PH"
            },
            {
                "name": "Canubing No 2",
                "countryCode": "PH"
            },
            {
                "name": "Caramay",
                "countryCode": "PH"
            },
            {
                "name": "Carmen",
                "countryCode": "PH"
            },
            {
                "name": "Caruray",
                "countryCode": "PH"
            },
            {
                "name": "Casian",
                "countryCode": "PH"
            },
            {
                "name": "Cawayan",
                "countryCode": "PH"
            },
            {
                "name": "Concepcion",
                "countryCode": "PH"
            },
            {
                "name": "Conduaga",
                "countryCode": "PH"
            },
            {
                "name": "Corcuera",
                "countryCode": "PH"
            },
            {
                "name": "Coron",
                "countryCode": "PH"
            },
            {
                "name": "Cuyo",
                "countryCode": "PH"
            },
            {
                "name": "Dapawan",
                "countryCode": "PH"
            },
            {
                "name": "Daykitin",
                "countryCode": "PH"
            },
            {
                "name": "Dobdoban",
                "countryCode": "PH"
            },
            {
                "name": "Dulangan",
                "countryCode": "PH"
            },
            {
                "name": "Dumaran",
                "countryCode": "PH"
            },
            {
                "name": "El Nido",
                "countryCode": "PH"
            },
            {
                "name": "Eraan",
                "countryCode": "PH"
            },
            {
                "name": "España",
                "countryCode": "PH"
            },
            {
                "name": "Estrella",
                "countryCode": "PH"
            },
            {
                "name": "Evangelista",
                "countryCode": "PH"
            },
            {
                "name": "Ferrol",
                "countryCode": "PH"
            },
            {
                "name": "Gabawan",
                "countryCode": "PH"
            },
            {
                "name": "Gasan",
                "countryCode": "PH"
            },
            {
                "name": "Gloria",
                "countryCode": "PH"
            },
            {
                "name": "Guinlo",
                "countryCode": "PH"
            },
            {
                "name": "Harrison",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Ipilan",
                "countryCode": "PH"
            },
            {
                "name": "Irahuan",
                "countryCode": "PH"
            },
            {
                "name": "Iraray",
                "countryCode": "PH"
            },
            {
                "name": "Irirum",
                "countryCode": "PH"
            },
            {
                "name": "Isugod",
                "countryCode": "PH"
            },
            {
                "name": "La Curva",
                "countryCode": "PH"
            },
            {
                "name": "Labasan",
                "countryCode": "PH"
            },
            {
                "name": "Labog",
                "countryCode": "PH"
            },
            {
                "name": "Lanas",
                "countryCode": "PH"
            },
            {
                "name": "Laylay",
                "countryCode": "PH"
            },
            {
                "name": "Leuteboro",
                "countryCode": "PH"
            },
            {
                "name": "Libas",
                "countryCode": "PH"
            },
            {
                "name": "Libertad",
                "countryCode": "PH"
            },
            {
                "name": "Ligaya",
                "countryCode": "PH"
            },
            {
                "name": "Limanancong",
                "countryCode": "PH"
            },
            {
                "name": "Limon",
                "countryCode": "PH"
            },
            {
                "name": "Looc",
                "countryCode": "PH"
            },
            {
                "name": "Lubang",
                "countryCode": "PH"
            },
            {
                "name": "Lumangbayan",
                "countryCode": "PH"
            },
            {
                "name": "Magbay",
                "countryCode": "PH"
            },
            {
                "name": "Magdiwang",
                "countryCode": "PH"
            },
            {
                "name": "Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Mainit",
                "countryCode": "PH"
            },
            {
                "name": "Malamig",
                "countryCode": "PH"
            },
            {
                "name": "Malibago",
                "countryCode": "PH"
            },
            {
                "name": "Maliig",
                "countryCode": "PH"
            },
            {
                "name": "Malitbog",
                "countryCode": "PH"
            },
            {
                "name": "Maluanluan",
                "countryCode": "PH"
            },
            {
                "name": "Mamburao",
                "countryCode": "PH"
            },
            {
                "name": "Manaul",
                "countryCode": "PH"
            },
            {
                "name": "Mangarine",
                "countryCode": "PH"
            },
            {
                "name": "Mansalay",
                "countryCode": "PH"
            },
            {
                "name": "Masaguisi",
                "countryCode": "PH"
            },
            {
                "name": "Masiga",
                "countryCode": "PH"
            },
            {
                "name": "Mauhao",
                "countryCode": "PH"
            },
            {
                "name": "Mogpog",
                "countryCode": "PH"
            },
            {
                "name": "Nagiba",
                "countryCode": "PH"
            },
            {
                "name": "Narra",
                "countryCode": "PH"
            },
            {
                "name": "Naujan",
                "countryCode": "PH"
            },
            {
                "name": "New Agutaya",
                "countryCode": "PH"
            },
            {
                "name": "Odala",
                "countryCode": "PH"
            },
            {
                "name": "Odiong",
                "countryCode": "PH"
            },
            {
                "name": "Odiongan",
                "countryCode": "PH"
            },
            {
                "name": "Osmeña",
                "countryCode": "PH"
            },
            {
                "name": "Paclolo",
                "countryCode": "PH"
            },
            {
                "name": "Paluan",
                "countryCode": "PH"
            },
            {
                "name": "Pambisan",
                "countryCode": "PH"
            },
            {
                "name": "Panacan",
                "countryCode": "PH"
            },
            {
                "name": "Panalingaan",
                "countryCode": "PH"
            },
            {
                "name": "Pancol",
                "countryCode": "PH"
            },
            {
                "name": "Pangulayan",
                "countryCode": "PH"
            },
            {
                "name": "Panique",
                "countryCode": "PH"
            },
            {
                "name": "Panitian",
                "countryCode": "PH"
            },
            {
                "name": "Panlaitan",
                "countryCode": "PH"
            },
            {
                "name": "Pato-o",
                "countryCode": "PH"
            },
            {
                "name": "Pañgobilian",
                "countryCode": "PH"
            },
            {
                "name": "Pili",
                "countryCode": "PH"
            },
            {
                "name": "Pinagsabangan",
                "countryCode": "PH"
            },
            {
                "name": "Pinamalayan",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Plaridel",
                "countryCode": "PH"
            },
            {
                "name": "Pola",
                "countryCode": "PH"
            },
            {
                "name": "Port Barton",
                "countryCode": "PH"
            },
            {
                "name": "Province of Marinduque",
                "countryCode": "PH"
            },
            {
                "name": "Province of Mindoro Occidental",
                "countryCode": "PH"
            },
            {
                "name": "Province of Mindoro Oriental",
                "countryCode": "PH"
            },
            {
                "name": "Province of Palawan",
                "countryCode": "PH"
            },
            {
                "name": "Province of Romblon",
                "countryCode": "PH"
            },
            {
                "name": "Puerto Galera",
                "countryCode": "PH"
            },
            {
                "name": "Puerto Princesa",
                "countryCode": "PH"
            },
            {
                "name": "Punang",
                "countryCode": "PH"
            },
            {
                "name": "Punta",
                "countryCode": "PH"
            },
            {
                "name": "Quezon",
                "countryCode": "PH"
            },
            {
                "name": "Quinabigan",
                "countryCode": "PH"
            },
            {
                "name": "Ransang",
                "countryCode": "PH"
            },
            {
                "name": "Rio Tuba",
                "countryCode": "PH"
            },
            {
                "name": "Rizal",
                "countryCode": "PH"
            },
            {
                "name": "Romblon",
                "countryCode": "PH"
            },
            {
                "name": "Roxas",
                "countryCode": "PH"
            },
            {
                "name": "Saaban",
                "countryCode": "PH"
            },
            {
                "name": "Sabang",
                "countryCode": "PH"
            },
            {
                "name": "Sablayan",
                "countryCode": "PH"
            },
            {
                "name": "Sagana",
                "countryCode": "PH"
            },
            {
                "name": "Salcedo",
                "countryCode": "PH"
            },
            {
                "name": "Salvacion",
                "countryCode": "PH"
            },
            {
                "name": "San Agustin",
                "countryCode": "PH"
            },
            {
                "name": "San Aquilino",
                "countryCode": "PH"
            },
            {
                "name": "San Fernando",
                "countryCode": "PH"
            },
            {
                "name": "San Jose",
                "countryCode": "PH"
            },
            {
                "name": "San Mariano",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pedro",
                "countryCode": "PH"
            },
            {
                "name": "San Teodoro",
                "countryCode": "PH"
            },
            {
                "name": "San Vicente",
                "countryCode": "PH"
            },
            {
                "name": "Santa Brigida",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Santa Fe",
                "countryCode": "PH"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PH"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "PH"
            },
            {
                "name": "Saraza",
                "countryCode": "PH"
            },
            {
                "name": "Socorro",
                "countryCode": "PH"
            },
            {
                "name": "Sogod",
                "countryCode": "PH"
            },
            {
                "name": "Suba",
                "countryCode": "PH"
            },
            {
                "name": "Sumagui",
                "countryCode": "PH"
            },
            {
                "name": "Tabinay",
                "countryCode": "PH"
            },
            {
                "name": "Tabon",
                "countryCode": "PH"
            },
            {
                "name": "Tacligan",
                "countryCode": "PH"
            },
            {
                "name": "Taclobo",
                "countryCode": "PH"
            },
            {
                "name": "Tagbak",
                "countryCode": "PH"
            },
            {
                "name": "Tagbita",
                "countryCode": "PH"
            },
            {
                "name": "Tagburos",
                "countryCode": "PH"
            },
            {
                "name": "Tagusao",
                "countryCode": "PH"
            },
            {
                "name": "Tambong",
                "countryCode": "PH"
            },
            {
                "name": "Tampayan",
                "countryCode": "PH"
            },
            {
                "name": "Tangal",
                "countryCode": "PH"
            },
            {
                "name": "Tarusan",
                "countryCode": "PH"
            },
            {
                "name": "Tayaman",
                "countryCode": "PH"
            },
            {
                "name": "Taytay",
                "countryCode": "PH"
            },
            {
                "name": "Tigui",
                "countryCode": "PH"
            },
            {
                "name": "Tiguion",
                "countryCode": "PH"
            },
            {
                "name": "Tiguisan",
                "countryCode": "PH"
            },
            {
                "name": "Tilik",
                "countryCode": "PH"
            },
            {
                "name": "Tiniguiban",
                "countryCode": "PH"
            },
            {
                "name": "Tomingad",
                "countryCode": "PH"
            },
            {
                "name": "Torrijos",
                "countryCode": "PH"
            },
            {
                "name": "Tuban",
                "countryCode": "PH"
            },
            {
                "name": "Tugdan",
                "countryCode": "PH"
            },
            {
                "name": "Tumarbong",
                "countryCode": "PH"
            },
            {
                "name": "Victoria",
                "countryCode": "PH"
            },
            {
                "name": "Vigo",
                "countryCode": "PH"
            },
            {
                "name": "Wawa",
                "countryCode": "PH"
            },
            {
                "name": "Yook",
                "countryCode": "PH"
            },
            {
                "name": "Caloocan",
                "countryCode": "PH"
            },
            {
                "name": "Calumpang",
                "countryCode": "PH"
            },
            {
                "name": "Dasmariñas Village",
                "countryCode": "PH"
            },
            {
                "name": "Ermita",
                "countryCode": "PH"
            },
            {
                "name": "Intramuros",
                "countryCode": "PH"
            },
            {
                "name": "Las Piñas",
                "countryCode": "PH"
            },
            {
                "name": "Makati",
                "countryCode": "PH"
            },
            {
                "name": "Malabon",
                "countryCode": "PH"
            },
            {
                "name": "Malate",
                "countryCode": "PH"
            },
            {
                "name": "Mandaluyong",
                "countryCode": "PH"
            },
            {
                "name": "Manila",
                "countryCode": "PH"
            },
            {
                "name": "Marikina",
                "countryCode": "PH"
            },
            {
                "name": "Muntinlupa",
                "countryCode": "PH"
            },
            {
                "name": "National Capital Region",
                "countryCode": "PH"
            },
            {
                "name": "Navotas",
                "countryCode": "PH"
            },
            {
                "name": "Niugan",
                "countryCode": "PH"
            },
            {
                "name": "Paco",
                "countryCode": "PH"
            },
            {
                "name": "Pandacan",
                "countryCode": "PH"
            },
            {
                "name": "Parañaque",
                "countryCode": "PH"
            },
            {
                "name": "Pasay",
                "countryCode": "PH"
            },
            {
                "name": "Pasig",
                "countryCode": "PH"
            },
            {
                "name": "Pateros",
                "countryCode": "PH"
            },
            {
                "name": "Port Area",
                "countryCode": "PH"
            },
            {
                "name": "Quezon City",
                "countryCode": "PH"
            },
            {
                "name": "Quiapo",
                "countryCode": "PH"
            },
            {
                "name": "Sambayanihan People's Village",
                "countryCode": "PH"
            },
            {
                "name": "San Juan",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "Santa Ana",
                "countryCode": "PH"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PH"
            },
            {
                "name": "Singkamas",
                "countryCode": "PH"
            },
            {
                "name": "Taguig",
                "countryCode": "PH"
            },
            {
                "name": "Tanza",
                "countryCode": "PH"
            },
            {
                "name": "Tondo",
                "countryCode": "PH"
            },
            {
                "name": "Valenzuela",
                "countryCode": "PH"
            },
            {
                "name": "Alicia",
                "countryCode": "PH"
            },
            {
                "name": "Aurora",
                "countryCode": "PH"
            },
            {
                "name": "Balagon",
                "countryCode": "PH"
            },
            {
                "name": "Barcelona",
                "countryCode": "PH"
            },
            {
                "name": "Batu",
                "countryCode": "PH"
            },
            {
                "name": "Bayog",
                "countryCode": "PH"
            },
            {
                "name": "Binuatan",
                "countryCode": "PH"
            },
            {
                "name": "Bolong",
                "countryCode": "PH"
            },
            {
                "name": "Buenavista",
                "countryCode": "PH"
            },
            {
                "name": "Bunguiao",
                "countryCode": "PH"
            },
            {
                "name": "Buug",
                "countryCode": "PH"
            },
            {
                "name": "Cabaluay",
                "countryCode": "PH"
            },
            {
                "name": "Calabasa",
                "countryCode": "PH"
            },
            {
                "name": "Caracal",
                "countryCode": "PH"
            },
            {
                "name": "Culianan",
                "countryCode": "PH"
            },
            {
                "name": "Curuan",
                "countryCode": "PH"
            },
            {
                "name": "Dalangin",
                "countryCode": "PH"
            },
            {
                "name": "Danlugan",
                "countryCode": "PH"
            },
            {
                "name": "Dapitan",
                "countryCode": "PH"
            },
            {
                "name": "Dawa-Dawa",
                "countryCode": "PH"
            },
            {
                "name": "Del Monte",
                "countryCode": "PH"
            },
            {
                "name": "Dicayong",
                "countryCode": "PH"
            },
            {
                "name": "Dimataling",
                "countryCode": "PH"
            },
            {
                "name": "Diplahan",
                "countryCode": "PH"
            },
            {
                "name": "Dipolo",
                "countryCode": "PH"
            },
            {
                "name": "Dipolog",
                "countryCode": "PH"
            },
            {
                "name": "Disod",
                "countryCode": "PH"
            },
            {
                "name": "Dulian",
                "countryCode": "PH"
            },
            {
                "name": "Dumalinao",
                "countryCode": "PH"
            },
            {
                "name": "Dumingag",
                "countryCode": "PH"
            },
            {
                "name": "East Migpulao",
                "countryCode": "PH"
            },
            {
                "name": "Ganyangan",
                "countryCode": "PH"
            },
            {
                "name": "Gubaan",
                "countryCode": "PH"
            },
            {
                "name": "Guiniculalay",
                "countryCode": "PH"
            },
            {
                "name": "Guipos",
                "countryCode": "PH"
            },
            {
                "name": "Gutalac",
                "countryCode": "PH"
            },
            {
                "name": "Ilaya",
                "countryCode": "PH"
            },
            {
                "name": "Ipil",
                "countryCode": "PH"
            },
            {
                "name": "Irasan",
                "countryCode": "PH"
            },
            {
                "name": "Josefina",
                "countryCode": "PH"
            },
            {
                "name": "Kabasalan",
                "countryCode": "PH"
            },
            {
                "name": "Kagawasan",
                "countryCode": "PH"
            },
            {
                "name": "Kalawit",
                "countryCode": "PH"
            },
            {
                "name": "Kalian",
                "countryCode": "PH"
            },
            {
                "name": "Katipunan",
                "countryCode": "PH"
            },
            {
                "name": "Kawayan",
                "countryCode": "PH"
            },
            {
                "name": "Kipit",
                "countryCode": "PH"
            },
            {
                "name": "Kumalarang",
                "countryCode": "PH"
            },
            {
                "name": "La Dicha",
                "countryCode": "PH"
            },
            {
                "name": "Labangan",
                "countryCode": "PH"
            },
            {
                "name": "Labason",
                "countryCode": "PH"
            },
            {
                "name": "Labuan",
                "countryCode": "PH"
            },
            {
                "name": "Lamisahan",
                "countryCode": "PH"
            },
            {
                "name": "Landang Laum",
                "countryCode": "PH"
            },
            {
                "name": "Langatian",
                "countryCode": "PH"
            },
            {
                "name": "Laparay",
                "countryCode": "PH"
            },
            {
                "name": "Lapuyan",
                "countryCode": "PH"
            },
            {
                "name": "Legrada",
                "countryCode": "PH"
            },
            {
                "name": "Leon Postigo",
                "countryCode": "PH"
            },
            {
                "name": "Liloy",
                "countryCode": "PH"
            },
            {
                "name": "Limaong",
                "countryCode": "PH"
            },
            {
                "name": "Limpapa",
                "countryCode": "PH"
            },
            {
                "name": "Linay",
                "countryCode": "PH"
            },
            {
                "name": "Lingasan",
                "countryCode": "PH"
            },
            {
                "name": "Lintangan",
                "countryCode": "PH"
            },
            {
                "name": "Lumbayan",
                "countryCode": "PH"
            },
            {
                "name": "Lumbog",
                "countryCode": "PH"
            },
            {
                "name": "Mabuhay",
                "countryCode": "PH"
            },
            {
                "name": "Mahayag",
                "countryCode": "PH"
            },
            {
                "name": "Malangas",
                "countryCode": "PH"
            },
            {
                "name": "Malayal",
                "countryCode": "PH"
            },
            {
                "name": "Malim",
                "countryCode": "PH"
            },
            {
                "name": "Mandih",
                "countryCode": "PH"
            },
            {
                "name": "Mangusu",
                "countryCode": "PH"
            },
            {
                "name": "Manicahan",
                "countryCode": "PH"
            },
            {
                "name": "Manukan",
                "countryCode": "PH"
            },
            {
                "name": "Margos",
                "countryCode": "PH"
            },
            {
                "name": "Margosatubig",
                "countryCode": "PH"
            },
            {
                "name": "Mercedes",
                "countryCode": "PH"
            },
            {
                "name": "Midsalip",
                "countryCode": "PH"
            },
            {
                "name": "Molave",
                "countryCode": "PH"
            },
            {
                "name": "Monching",
                "countryCode": "PH"
            },
            {
                "name": "Muricay",
                "countryCode": "PH"
            },
            {
                "name": "Muti",
                "countryCode": "PH"
            },
            {
                "name": "Naga",
                "countryCode": "PH"
            },
            {
                "name": "Olingan",
                "countryCode": "PH"
            },
            {
                "name": "Olutanga",
                "countryCode": "PH"
            },
            {
                "name": "Pagadian",
                "countryCode": "PH"
            },
            {
                "name": "Palomoc",
                "countryCode": "PH"
            },
            {
                "name": "Panubigan",
                "countryCode": "PH"
            },
            {
                "name": "Patawag",
                "countryCode": "PH"
            },
            {
                "name": "Payao",
                "countryCode": "PH"
            },
            {
                "name": "Pitogo",
                "countryCode": "PH"
            },
            {
                "name": "Polanco",
                "countryCode": "PH"
            },
            {
                "name": "Ponot",
                "countryCode": "PH"
            },
            {
                "name": "Province of  Zamboanga del Sur",
                "countryCode": "PH"
            },
            {
                "name": "Province of Zamboanga Sibugay",
                "countryCode": "PH"
            },
            {
                "name": "Province of Zamboanga del Norte",
                "countryCode": "PH"
            },
            {
                "name": "Quinipot",
                "countryCode": "PH"
            },
            {
                "name": "Ramon Magsaysay",
                "countryCode": "PH"
            },
            {
                "name": "Rancheria Payau",
                "countryCode": "PH"
            },
            {
                "name": "Recodo",
                "countryCode": "PH"
            },
            {
                "name": "Robonkon",
                "countryCode": "PH"
            },
            {
                "name": "Sagacad",
                "countryCode": "PH"
            },
            {
                "name": "Salug",
                "countryCode": "PH"
            },
            {
                "name": "San Miguel",
                "countryCode": "PH"
            },
            {
                "name": "San Pablo",
                "countryCode": "PH"
            },
            {
                "name": "Sangali",
                "countryCode": "PH"
            },
            {
                "name": "Seres",
                "countryCode": "PH"
            },
            {
                "name": "Sergio Osmeña Sr",
                "countryCode": "PH"
            },
            {
                "name": "Siari",
                "countryCode": "PH"
            },
            {
                "name": "Siay",
                "countryCode": "PH"
            },
            {
                "name": "Siayan",
                "countryCode": "PH"
            },
            {
                "name": "Sibuco",
                "countryCode": "PH"
            },
            {
                "name": "Sibulao",
                "countryCode": "PH"
            },
            {
                "name": "Sibutao",
                "countryCode": "PH"
            },
            {
                "name": "Sindangan",
                "countryCode": "PH"
            },
            {
                "name": "Sinubong",
                "countryCode": "PH"
            },
            {
                "name": "Siocon",
                "countryCode": "PH"
            },
            {
                "name": "Siraway",
                "countryCode": "PH"
            },
            {
                "name": "Sumalig",
                "countryCode": "PH"
            },
            {
                "name": "Tabina",
                "countryCode": "PH"
            },
            {
                "name": "Tagasilay",
                "countryCode": "PH"
            },
            {
                "name": "Taguitic",
                "countryCode": "PH"
            },
            {
                "name": "Talabaan",
                "countryCode": "PH"
            },
            {
                "name": "Talisayan",
                "countryCode": "PH"
            },
            {
                "name": "Taluksangay",
                "countryCode": "PH"
            },
            {
                "name": "Talusan",
                "countryCode": "PH"
            },
            {
                "name": "Tambulig",
                "countryCode": "PH"
            },
            {
                "name": "Tampilisan",
                "countryCode": "PH"
            },
            {
                "name": "Tawagan",
                "countryCode": "PH"
            },
            {
                "name": "Tigbao",
                "countryCode": "PH"
            },
            {
                "name": "Tigpalay",
                "countryCode": "PH"
            },
            {
                "name": "Tigtabon",
                "countryCode": "PH"
            },
            {
                "name": "Tiguha",
                "countryCode": "PH"
            },
            {
                "name": "Timonan",
                "countryCode": "PH"
            },
            {
                "name": "Tiparak",
                "countryCode": "PH"
            },
            {
                "name": "Titay",
                "countryCode": "PH"
            },
            {
                "name": "Tolosa",
                "countryCode": "PH"
            },
            {
                "name": "Tucuran",
                "countryCode": "PH"
            },
            {
                "name": "Tungawan",
                "countryCode": "PH"
            },
            {
                "name": "Vitali",
                "countryCode": "PH"
            },
            {
                "name": "Zamboanga",
                "countryCode": "PH"
            }
        ]
    },
    {
        "name": "Pitcairn Island",
        "phonecode": "870",
        "cities": []
    },
    {
        "name": "Poland",
        "phonecode": "48",
        "cities": [
            {
                "name": "Bardo",
                "countryCode": "PL"
            },
            {
                "name": "Bielany Wrocławskie",
                "countryCode": "PL"
            },
            {
                "name": "Bielawa",
                "countryCode": "PL"
            },
            {
                "name": "Bierutów",
                "countryCode": "PL"
            },
            {
                "name": "Biskupin",
                "countryCode": "PL"
            },
            {
                "name": "Bogatynia",
                "countryCode": "PL"
            },
            {
                "name": "Boguszów-Gorce",
                "countryCode": "PL"
            },
            {
                "name": "Bolesławiec",
                "countryCode": "PL"
            },
            {
                "name": "Bolków",
                "countryCode": "PL"
            },
            {
                "name": "Bozkow",
                "countryCode": "PL"
            },
            {
                "name": "Brzeg Dolny",
                "countryCode": "PL"
            },
            {
                "name": "Budzów",
                "countryCode": "PL"
            },
            {
                "name": "Bystrzyca",
                "countryCode": "PL"
            },
            {
                "name": "Bystrzyca Kłodzka",
                "countryCode": "PL"
            },
            {
                "name": "Chocianów",
                "countryCode": "PL"
            },
            {
                "name": "Chojnów",
                "countryCode": "PL"
            },
            {
                "name": "Ciechów",
                "countryCode": "PL"
            },
            {
                "name": "Cieplice Śląskie Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Ciepłowody",
                "countryCode": "PL"
            },
            {
                "name": "Cieszków",
                "countryCode": "PL"
            },
            {
                "name": "Czarny Bór",
                "countryCode": "PL"
            },
            {
                "name": "Czernica",
                "countryCode": "PL"
            },
            {
                "name": "Dobroszyce",
                "countryCode": "PL"
            },
            {
                "name": "Duszniki-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Dziadowa Kłoda",
                "countryCode": "PL"
            },
            {
                "name": "Dzierżoniów",
                "countryCode": "PL"
            },
            {
                "name": "Dziećmorowice",
                "countryCode": "PL"
            },
            {
                "name": "Dąbie",
                "countryCode": "PL"
            },
            {
                "name": "Długołęka",
                "countryCode": "PL"
            },
            {
                "name": "Gaworzyce",
                "countryCode": "PL"
            },
            {
                "name": "Gniechowice",
                "countryCode": "PL"
            },
            {
                "name": "Grabiszyn",
                "countryCode": "PL"
            },
            {
                "name": "Grabiszynek",
                "countryCode": "PL"
            },
            {
                "name": "Gromadka",
                "countryCode": "PL"
            },
            {
                "name": "Gryfów Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Grębocice",
                "countryCode": "PL"
            },
            {
                "name": "Góra",
                "countryCode": "PL"
            },
            {
                "name": "Gądów Mały",
                "countryCode": "PL"
            },
            {
                "name": "Głogów",
                "countryCode": "PL"
            },
            {
                "name": "Głuszyca",
                "countryCode": "PL"
            },
            {
                "name": "Głuszyca Górna",
                "countryCode": "PL"
            },
            {
                "name": "Henryków",
                "countryCode": "PL"
            },
            {
                "name": "Janowice Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Jarnołtów-Jerzmanowo",
                "countryCode": "PL"
            },
            {
                "name": "Jawor",
                "countryCode": "PL"
            },
            {
                "name": "Jaworzyna Śląska",
                "countryCode": "PL"
            },
            {
                "name": "Jedlina-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Jelcz",
                "countryCode": "PL"
            },
            {
                "name": "Jelcz Laskowice",
                "countryCode": "PL"
            },
            {
                "name": "Jelenia Góra",
                "countryCode": "PL"
            },
            {
                "name": "Jeżów Sudecki",
                "countryCode": "PL"
            },
            {
                "name": "Jordanów Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Jugów",
                "countryCode": "PL"
            },
            {
                "name": "Kamieniec Wrocławski",
                "countryCode": "PL"
            },
            {
                "name": "Kamieniec Ząbkowicki",
                "countryCode": "PL"
            },
            {
                "name": "Kamienna Góra",
                "countryCode": "PL"
            },
            {
                "name": "Karpacz",
                "countryCode": "PL"
            },
            {
                "name": "Karłowice",
                "countryCode": "PL"
            },
            {
                "name": "Kiełczów",
                "countryCode": "PL"
            },
            {
                "name": "Kobierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Kotla",
                "countryCode": "PL"
            },
            {
                "name": "Kowale-Popiele",
                "countryCode": "PL"
            },
            {
                "name": "Kowary",
                "countryCode": "PL"
            },
            {
                "name": "Kozanów",
                "countryCode": "PL"
            },
            {
                "name": "Krośnice",
                "countryCode": "PL"
            },
            {
                "name": "Krzeszów",
                "countryCode": "PL"
            },
            {
                "name": "Krzyki",
                "countryCode": "PL"
            },
            {
                "name": "Księże Małe-Księże Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Kudowa-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Kunice",
                "countryCode": "PL"
            },
            {
                "name": "Kąty Wrocławskie",
                "countryCode": "PL"
            },
            {
                "name": "Kłodzko",
                "countryCode": "PL"
            },
            {
                "name": "Legnica",
                "countryCode": "PL"
            },
            {
                "name": "Legnickie Pole",
                "countryCode": "PL"
            },
            {
                "name": "Lewin Kłodzki",
                "countryCode": "PL"
            },
            {
                "name": "Leśna",
                "countryCode": "PL"
            },
            {
                "name": "Leśnica-Ratyń-Pustki",
                "countryCode": "PL"
            },
            {
                "name": "Lubawka",
                "countryCode": "PL"
            },
            {
                "name": "Lubań",
                "countryCode": "PL"
            },
            {
                "name": "Lubin",
                "countryCode": "PL"
            },
            {
                "name": "Lubomierz",
                "countryCode": "PL"
            },
            {
                "name": "Ludwikowice Kłodzkie",
                "countryCode": "PL"
            },
            {
                "name": "Lwówek Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Lądek-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Malczyce",
                "countryCode": "PL"
            },
            {
                "name": "Marciszów",
                "countryCode": "PL"
            },
            {
                "name": "Marszowice",
                "countryCode": "PL"
            },
            {
                "name": "Maślice Małe",
                "countryCode": "PL"
            },
            {
                "name": "Maślice Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Mieroszów",
                "countryCode": "PL"
            },
            {
                "name": "Milicz",
                "countryCode": "PL"
            },
            {
                "name": "Mirków",
                "countryCode": "PL"
            },
            {
                "name": "Mirsk",
                "countryCode": "PL"
            },
            {
                "name": "Międzybórz",
                "countryCode": "PL"
            },
            {
                "name": "Międzylesie",
                "countryCode": "PL"
            },
            {
                "name": "Miękinia",
                "countryCode": "PL"
            },
            {
                "name": "Miłkowice",
                "countryCode": "PL"
            },
            {
                "name": "Mrozów",
                "countryCode": "PL"
            },
            {
                "name": "Mysłakowice",
                "countryCode": "PL"
            },
            {
                "name": "Niemcza",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Ruda",
                "countryCode": "PL"
            },
            {
                "name": "Nowogrodziec",
                "countryCode": "PL"
            },
            {
                "name": "Oborniki Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Oleśnica",
                "countryCode": "PL"
            },
            {
                "name": "Olszanica",
                "countryCode": "PL"
            },
            {
                "name": "Olszyna",
                "countryCode": "PL"
            },
            {
                "name": "Osiedle Henrykowskie",
                "countryCode": "PL"
            },
            {
                "name": "Osiedle Kosmonautów",
                "countryCode": "PL"
            },
            {
                "name": "Osiek",
                "countryCode": "PL"
            },
            {
                "name": "Ostroszowice",
                "countryCode": "PL"
            },
            {
                "name": "Oława",
                "countryCode": "PL"
            },
            {
                "name": "Partynice",
                "countryCode": "PL"
            },
            {
                "name": "Paszowice",
                "countryCode": "PL"
            },
            {
                "name": "Pawłowice-Kłokoczyce",
                "countryCode": "PL"
            },
            {
                "name": "Piechowice",
                "countryCode": "PL"
            },
            {
                "name": "Pieszyce",
                "countryCode": "PL"
            },
            {
                "name": "Pieńsk",
                "countryCode": "PL"
            },
            {
                "name": "Pilczyce",
                "countryCode": "PL"
            },
            {
                "name": "Pisarzowice",
                "countryCode": "PL"
            },
            {
                "name": "Piława Górna",
                "countryCode": "PL"
            },
            {
                "name": "Podgórzyn",
                "countryCode": "PL"
            },
            {
                "name": "Polanica-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Polkowice",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bolesławiecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat dzierżoniowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat górowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat głogowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jaworski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jeleniogórski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kamiennogórski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kłodzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat legnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lwówecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat milicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat oleśnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat oławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat polkowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat strzeliński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat trzebnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wałbrzyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wołowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wrocławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zgorzelecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ząbkowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat złotoryjski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat średzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat świdnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powstańców Śląskich Wschód",
                "countryCode": "PL"
            },
            {
                "name": "Powstańców Śląskich Zachód-Centrum Południow",
                "countryCode": "PL"
            },
            {
                "name": "Poświętne",
                "countryCode": "PL"
            },
            {
                "name": "Pracze Odrzańskie-Janówek",
                "countryCode": "PL"
            },
            {
                "name": "Prochowice",
                "countryCode": "PL"
            },
            {
                "name": "Prusice",
                "countryCode": "PL"
            },
            {
                "name": "Przemków",
                "countryCode": "PL"
            },
            {
                "name": "Przeworno",
                "countryCode": "PL"
            },
            {
                "name": "Psary",
                "countryCode": "PL"
            },
            {
                "name": "Psie Pole Południe-Kiełczów",
                "countryCode": "PL"
            },
            {
                "name": "Radków",
                "countryCode": "PL"
            },
            {
                "name": "Radwanice",
                "countryCode": "PL"
            },
            {
                "name": "Ratowice",
                "countryCode": "PL"
            },
            {
                "name": "Rejon alei Kromera",
                "countryCode": "PL"
            },
            {
                "name": "Rejon placu Grunwaldzkiego",
                "countryCode": "PL"
            },
            {
                "name": "Rejon placu Świętego Macieja",
                "countryCode": "PL"
            },
            {
                "name": "Rejon ulicy Borowskiej-Południe",
                "countryCode": "PL"
            },
            {
                "name": "Rejon ulicy Klęczkowskiej",
                "countryCode": "PL"
            },
            {
                "name": "Rejon ulicy Mieleckiej",
                "countryCode": "PL"
            },
            {
                "name": "Rejon ulicy Saperów",
                "countryCode": "PL"
            },
            {
                "name": "Rejon ulicy Traugutta",
                "countryCode": "PL"
            },
            {
                "name": "Rudna",
                "countryCode": "PL"
            },
            {
                "name": "Różanka-Polanka",
                "countryCode": "PL"
            },
            {
                "name": "Siechnice",
                "countryCode": "PL"
            },
            {
                "name": "Siekierczyn",
                "countryCode": "PL"
            },
            {
                "name": "Smolec",
                "countryCode": "PL"
            },
            {
                "name": "Sobótka",
                "countryCode": "PL"
            },
            {
                "name": "Sosnówka",
                "countryCode": "PL"
            },
            {
                "name": "Sołtysowice",
                "countryCode": "PL"
            },
            {
                "name": "Stabłowice",
                "countryCode": "PL"
            },
            {
                "name": "Stanowice",
                "countryCode": "PL"
            },
            {
                "name": "Stara Kamienica",
                "countryCode": "PL"
            },
            {
                "name": "Stare Bogaczowice",
                "countryCode": "PL"
            },
            {
                "name": "Stoszowice",
                "countryCode": "PL"
            },
            {
                "name": "Strachocin-Wojnów",
                "countryCode": "PL"
            },
            {
                "name": "Stronie Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Strzegom",
                "countryCode": "PL"
            },
            {
                "name": "Strzelin",
                "countryCode": "PL"
            },
            {
                "name": "Sulików",
                "countryCode": "PL"
            },
            {
                "name": "Sułów",
                "countryCode": "PL"
            },
            {
                "name": "Swojczyce",
                "countryCode": "PL"
            },
            {
                "name": "Syców",
                "countryCode": "PL"
            },
            {
                "name": "Szczawno-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Szczepanów",
                "countryCode": "PL"
            },
            {
                "name": "Szczytna",
                "countryCode": "PL"
            },
            {
                "name": "Szklarska Poręba",
                "countryCode": "PL"
            },
            {
                "name": "Sępolno",
                "countryCode": "PL"
            },
            {
                "name": "Trzebnica",
                "countryCode": "PL"
            },
            {
                "name": "Twardogóra",
                "countryCode": "PL"
            },
            {
                "name": "Tyniec Mały",
                "countryCode": "PL"
            },
            {
                "name": "Uciechów",
                "countryCode": "PL"
            },
            {
                "name": "Udanin",
                "countryCode": "PL"
            },
            {
                "name": "Walim",
                "countryCode": "PL"
            },
            {
                "name": "Wałbrzych",
                "countryCode": "PL"
            },
            {
                "name": "Widawa-Lipa Piotrowska-Polanowice",
                "countryCode": "PL"
            },
            {
                "name": "Wierzbno",
                "countryCode": "PL"
            },
            {
                "name": "Wilczyce",
                "countryCode": "PL"
            },
            {
                "name": "Wilków",
                "countryCode": "PL"
            },
            {
                "name": "Wiązów",
                "countryCode": "PL"
            },
            {
                "name": "Wińsko",
                "countryCode": "PL"
            },
            {
                "name": "Wleń",
                "countryCode": "PL"
            },
            {
                "name": "Wojcieszów",
                "countryCode": "PL"
            },
            {
                "name": "Wojszyce",
                "countryCode": "PL"
            },
            {
                "name": "Wołów",
                "countryCode": "PL"
            },
            {
                "name": "Wrocław",
                "countryCode": "PL"
            },
            {
                "name": "Wąsosz",
                "countryCode": "PL"
            },
            {
                "name": "Węgliniec",
                "countryCode": "PL"
            },
            {
                "name": "Zacisze",
                "countryCode": "PL"
            },
            {
                "name": "Zagrodno",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzów",
                "countryCode": "PL"
            },
            {
                "name": "Zalesie i Stadion",
                "countryCode": "PL"
            },
            {
                "name": "Zgorzelec",
                "countryCode": "PL"
            },
            {
                "name": "Zgorzelisko",
                "countryCode": "PL"
            },
            {
                "name": "Ziębice",
                "countryCode": "PL"
            },
            {
                "name": "Ząbkowice Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Złotniki",
                "countryCode": "PL"
            },
            {
                "name": "Złotoryja",
                "countryCode": "PL"
            },
            {
                "name": "Złoty Stok",
                "countryCode": "PL"
            },
            {
                "name": "Łagiewniki",
                "countryCode": "PL"
            },
            {
                "name": "Łagów",
                "countryCode": "PL"
            },
            {
                "name": "Ścinawa",
                "countryCode": "PL"
            },
            {
                "name": "Środa Śląska",
                "countryCode": "PL"
            },
            {
                "name": "Świdnica",
                "countryCode": "PL"
            },
            {
                "name": "Świebodzice",
                "countryCode": "PL"
            },
            {
                "name": "Świeradów-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Świerzawa",
                "countryCode": "PL"
            },
            {
                "name": "Święta Katarzyna",
                "countryCode": "PL"
            },
            {
                "name": "Żarów",
                "countryCode": "PL"
            },
            {
                "name": "Żerniki Wrocławskie",
                "countryCode": "PL"
            },
            {
                "name": "Żmigród",
                "countryCode": "PL"
            },
            {
                "name": "Żórawina",
                "countryCode": "PL"
            },
            {
                "name": "Aleksandrów Kujawski",
                "countryCode": "PL"
            },
            {
                "name": "Barcin",
                "countryCode": "PL"
            },
            {
                "name": "Bartniczka",
                "countryCode": "PL"
            },
            {
                "name": "Baruchowo",
                "countryCode": "PL"
            },
            {
                "name": "Białe Błota",
                "countryCode": "PL"
            },
            {
                "name": "Bobrowniki",
                "countryCode": "PL"
            },
            {
                "name": "Bobrowo",
                "countryCode": "PL"
            },
            {
                "name": "Boniewo",
                "countryCode": "PL"
            },
            {
                "name": "Brodnica",
                "countryCode": "PL"
            },
            {
                "name": "Brześć Kujawski",
                "countryCode": "PL"
            },
            {
                "name": "Brzozie",
                "countryCode": "PL"
            },
            {
                "name": "Brzuze",
                "countryCode": "PL"
            },
            {
                "name": "Bukowiec",
                "countryCode": "PL"
            },
            {
                "name": "Bydgoszcz",
                "countryCode": "PL"
            },
            {
                "name": "Bytoń",
                "countryCode": "PL"
            },
            {
                "name": "Cekcyn",
                "countryCode": "PL"
            },
            {
                "name": "Chełmno",
                "countryCode": "PL"
            },
            {
                "name": "Chełmża",
                "countryCode": "PL"
            },
            {
                "name": "Choceń",
                "countryCode": "PL"
            },
            {
                "name": "Chodecz",
                "countryCode": "PL"
            },
            {
                "name": "Chrostkowo",
                "countryCode": "PL"
            },
            {
                "name": "Ciechocin",
                "countryCode": "PL"
            },
            {
                "name": "Ciechocinek",
                "countryCode": "PL"
            },
            {
                "name": "Czerniewice",
                "countryCode": "PL"
            },
            {
                "name": "Czernikowo",
                "countryCode": "PL"
            },
            {
                "name": "Dobre",
                "countryCode": "PL"
            },
            {
                "name": "Dobrzyń nad Wisłą",
                "countryCode": "PL"
            },
            {
                "name": "Drzycim",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa Chełmińska",
                "countryCode": "PL"
            },
            {
                "name": "Dębowa Łąka",
                "countryCode": "PL"
            },
            {
                "name": "Fabianki",
                "countryCode": "PL"
            },
            {
                "name": "Fordon",
                "countryCode": "PL"
            },
            {
                "name": "Gniewkowo",
                "countryCode": "PL"
            },
            {
                "name": "Golub-Dobrzyń",
                "countryCode": "PL"
            },
            {
                "name": "Gostycyn",
                "countryCode": "PL"
            },
            {
                "name": "Grudziądz",
                "countryCode": "PL"
            },
            {
                "name": "Gruta",
                "countryCode": "PL"
            },
            {
                "name": "Górzno",
                "countryCode": "PL"
            },
            {
                "name": "Gąsawa",
                "countryCode": "PL"
            },
            {
                "name": "Inowrocław",
                "countryCode": "PL"
            },
            {
                "name": "Izbica Kujawska",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonowo Pomorskie",
                "countryCode": "PL"
            },
            {
                "name": "Janikowo",
                "countryCode": "PL"
            },
            {
                "name": "Janowiec Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Jeziora Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Jeżewo",
                "countryCode": "PL"
            },
            {
                "name": "Kamień Krajeński",
                "countryCode": "PL"
            },
            {
                "name": "Kcynia",
                "countryCode": "PL"
            },
            {
                "name": "Kikół",
                "countryCode": "PL"
            },
            {
                "name": "Koronowo",
                "countryCode": "PL"
            },
            {
                "name": "Kowal",
                "countryCode": "PL"
            },
            {
                "name": "Kowalewo Pomorskie",
                "countryCode": "PL"
            },
            {
                "name": "Kruszwica",
                "countryCode": "PL"
            },
            {
                "name": "Kęsowo",
                "countryCode": "PL"
            },
            {
                "name": "Lipno",
                "countryCode": "PL"
            },
            {
                "name": "Lisewo",
                "countryCode": "PL"
            },
            {
                "name": "Lniano",
                "countryCode": "PL"
            },
            {
                "name": "Lubanie",
                "countryCode": "PL"
            },
            {
                "name": "Lubicz Dolny",
                "countryCode": "PL"
            },
            {
                "name": "Lubicz Górny",
                "countryCode": "PL"
            },
            {
                "name": "Lubiewo",
                "countryCode": "PL"
            },
            {
                "name": "Lubień Kujawski",
                "countryCode": "PL"
            },
            {
                "name": "Lubraniec",
                "countryCode": "PL"
            },
            {
                "name": "Mogilno",
                "countryCode": "PL"
            },
            {
                "name": "Mrocza",
                "countryCode": "PL"
            },
            {
                "name": "Nakło nad Notecią",
                "countryCode": "PL"
            },
            {
                "name": "Nieszawa",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Wieś Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Nowe",
                "countryCode": "PL"
            },
            {
                "name": "Obrowo",
                "countryCode": "PL"
            },
            {
                "name": "Osie",
                "countryCode": "PL"
            },
            {
                "name": "Osiek",
                "countryCode": "PL"
            },
            {
                "name": "Osielsko",
                "countryCode": "PL"
            },
            {
                "name": "Osięciny",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowite",
                "countryCode": "PL"
            },
            {
                "name": "Pakość",
                "countryCode": "PL"
            },
            {
                "name": "Piotrków Kujawski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat aleksandrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat brodnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bydgoski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat chełmiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat golubsko-dobrzyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat grudziądzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat inowrocławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lipnowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat mogileński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nakielski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat radziejowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rypiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sępoleński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat toruński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tucholski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wąbrzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat włocławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat świecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żniński",
                "countryCode": "PL"
            },
            {
                "name": "Pruszcz",
                "countryCode": "PL"
            },
            {
                "name": "Płużnica",
                "countryCode": "PL"
            },
            {
                "name": "Raciążek",
                "countryCode": "PL"
            },
            {
                "name": "Radomin",
                "countryCode": "PL"
            },
            {
                "name": "Radziejów",
                "countryCode": "PL"
            },
            {
                "name": "Radzyń Chełmiński",
                "countryCode": "PL"
            },
            {
                "name": "Rogowo",
                "countryCode": "PL"
            },
            {
                "name": "Rogóźno",
                "countryCode": "PL"
            },
            {
                "name": "Rypin",
                "countryCode": "PL"
            },
            {
                "name": "Sadki",
                "countryCode": "PL"
            },
            {
                "name": "Sicienko",
                "countryCode": "PL"
            },
            {
                "name": "Skrwilno",
                "countryCode": "PL"
            },
            {
                "name": "Skępe",
                "countryCode": "PL"
            },
            {
                "name": "Solec Kujawski",
                "countryCode": "PL"
            },
            {
                "name": "Sośno",
                "countryCode": "PL"
            },
            {
                "name": "Strzelno",
                "countryCode": "PL"
            },
            {
                "name": "Sypniewo",
                "countryCode": "PL"
            },
            {
                "name": "Szubin",
                "countryCode": "PL"
            },
            {
                "name": "Sępólno Krajeńskie",
                "countryCode": "PL"
            },
            {
                "name": "Topólka",
                "countryCode": "PL"
            },
            {
                "name": "Toruń",
                "countryCode": "PL"
            },
            {
                "name": "Tuchola",
                "countryCode": "PL"
            },
            {
                "name": "Tłuchowo",
                "countryCode": "PL"
            },
            {
                "name": "Unisław",
                "countryCode": "PL"
            },
            {
                "name": "Waganiec",
                "countryCode": "PL"
            },
            {
                "name": "Warlubie",
                "countryCode": "PL"
            },
            {
                "name": "Wielgie",
                "countryCode": "PL"
            },
            {
                "name": "Wielka Nieszawka",
                "countryCode": "PL"
            },
            {
                "name": "Wierzchosławice",
                "countryCode": "PL"
            },
            {
                "name": "Więcbork",
                "countryCode": "PL"
            },
            {
                "name": "Wąbrzeźno",
                "countryCode": "PL"
            },
            {
                "name": "Wąpielsk",
                "countryCode": "PL"
            },
            {
                "name": "Włocławek",
                "countryCode": "PL"
            },
            {
                "name": "Zbójno",
                "countryCode": "PL"
            },
            {
                "name": "Zławieś Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Złotniki Kujawskie",
                "countryCode": "PL"
            },
            {
                "name": "Łabiszyn",
                "countryCode": "PL"
            },
            {
                "name": "Łasin",
                "countryCode": "PL"
            },
            {
                "name": "Łubianka",
                "countryCode": "PL"
            },
            {
                "name": "Łysomice",
                "countryCode": "PL"
            },
            {
                "name": "Ślesin",
                "countryCode": "PL"
            },
            {
                "name": "Śliwice",
                "countryCode": "PL"
            },
            {
                "name": "Świecie",
                "countryCode": "PL"
            },
            {
                "name": "Świecie nad Osą",
                "countryCode": "PL"
            },
            {
                "name": "Świedziebnia",
                "countryCode": "PL"
            },
            {
                "name": "Świekatowo",
                "countryCode": "PL"
            },
            {
                "name": "Żnin",
                "countryCode": "PL"
            },
            {
                "name": "Babimost",
                "countryCode": "PL"
            },
            {
                "name": "Bledzew",
                "countryCode": "PL"
            },
            {
                "name": "Bobrowice",
                "countryCode": "PL"
            },
            {
                "name": "Bogdaniec",
                "countryCode": "PL"
            },
            {
                "name": "Bojadła",
                "countryCode": "PL"
            },
            {
                "name": "Brody",
                "countryCode": "PL"
            },
            {
                "name": "Brójce",
                "countryCode": "PL"
            },
            {
                "name": "Bytnica",
                "countryCode": "PL"
            },
            {
                "name": "Bytom Odrzański",
                "countryCode": "PL"
            },
            {
                "name": "Cybinka",
                "countryCode": "PL"
            },
            {
                "name": "Czerwieńsk",
                "countryCode": "PL"
            },
            {
                "name": "Deszczno",
                "countryCode": "PL"
            },
            {
                "name": "Dobiegniew",
                "countryCode": "PL"
            },
            {
                "name": "Drezdenko",
                "countryCode": "PL"
            },
            {
                "name": "Dąbie",
                "countryCode": "PL"
            },
            {
                "name": "Gorzów Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Gozdnica",
                "countryCode": "PL"
            },
            {
                "name": "Gubin",
                "countryCode": "PL"
            },
            {
                "name": "Górzyca",
                "countryCode": "PL"
            },
            {
                "name": "Iłowa",
                "countryCode": "PL"
            },
            {
                "name": "Jasień",
                "countryCode": "PL"
            },
            {
                "name": "Jenin",
                "countryCode": "PL"
            },
            {
                "name": "Kargowa",
                "countryCode": "PL"
            },
            {
                "name": "Klenica",
                "countryCode": "PL"
            },
            {
                "name": "Kolsko",
                "countryCode": "PL"
            },
            {
                "name": "Konotop",
                "countryCode": "PL"
            },
            {
                "name": "Kostrzyn nad Odrą",
                "countryCode": "PL"
            },
            {
                "name": "Kożuchów",
                "countryCode": "PL"
            },
            {
                "name": "Krosno Odrzańskie",
                "countryCode": "PL"
            },
            {
                "name": "Krzeszyce",
                "countryCode": "PL"
            },
            {
                "name": "Kunice Żarskie",
                "countryCode": "PL"
            },
            {
                "name": "Kłodawa",
                "countryCode": "PL"
            },
            {
                "name": "Lipinki Łużyckie",
                "countryCode": "PL"
            },
            {
                "name": "Lubiszyn",
                "countryCode": "PL"
            },
            {
                "name": "Lubniewice",
                "countryCode": "PL"
            },
            {
                "name": "Lubrza",
                "countryCode": "PL"
            },
            {
                "name": "Lubsko",
                "countryCode": "PL"
            },
            {
                "name": "Maszewo",
                "countryCode": "PL"
            },
            {
                "name": "Małomice",
                "countryCode": "PL"
            },
            {
                "name": "Międzylesie",
                "countryCode": "PL"
            },
            {
                "name": "Międzyrzecz",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Sól",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Miasteczko",
                "countryCode": "PL"
            },
            {
                "name": "Nowogród Bobrzański",
                "countryCode": "PL"
            },
            {
                "name": "Ochla",
                "countryCode": "PL"
            },
            {
                "name": "Otyń",
                "countryCode": "PL"
            },
            {
                "name": "Ośno Lubuskie",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gorzowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krośnieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat międzyrzecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowosolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat strzelecko-drezdenecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sulęciński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat słubicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wschowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zielonogórski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat świebodziński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żagański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żarski",
                "countryCode": "PL"
            },
            {
                "name": "Przewóz",
                "countryCode": "PL"
            },
            {
                "name": "Przyborów",
                "countryCode": "PL"
            },
            {
                "name": "Przytoczna",
                "countryCode": "PL"
            },
            {
                "name": "Pszczew",
                "countryCode": "PL"
            },
            {
                "name": "Rzepin",
                "countryCode": "PL"
            },
            {
                "name": "Santok",
                "countryCode": "PL"
            },
            {
                "name": "Siedlisko",
                "countryCode": "PL"
            },
            {
                "name": "Sieniawa",
                "countryCode": "PL"
            },
            {
                "name": "Sieniawa Żarska",
                "countryCode": "PL"
            },
            {
                "name": "Skwierzyna",
                "countryCode": "PL"
            },
            {
                "name": "Skąpe",
                "countryCode": "PL"
            },
            {
                "name": "Stare Kurowo",
                "countryCode": "PL"
            },
            {
                "name": "Strzelce Krajeńskie",
                "countryCode": "PL"
            },
            {
                "name": "Sulechów",
                "countryCode": "PL"
            },
            {
                "name": "Szczaniec",
                "countryCode": "PL"
            },
            {
                "name": "Szlichtyngowa",
                "countryCode": "PL"
            },
            {
                "name": "Szprotawa",
                "countryCode": "PL"
            },
            {
                "name": "Sława",
                "countryCode": "PL"
            },
            {
                "name": "Słońsk",
                "countryCode": "PL"
            },
            {
                "name": "Słubice",
                "countryCode": "PL"
            },
            {
                "name": "Torzym",
                "countryCode": "PL"
            },
            {
                "name": "Trzciel",
                "countryCode": "PL"
            },
            {
                "name": "Trzebiechów",
                "countryCode": "PL"
            },
            {
                "name": "Trzebiel",
                "countryCode": "PL"
            },
            {
                "name": "Tuplice",
                "countryCode": "PL"
            },
            {
                "name": "Witnica",
                "countryCode": "PL"
            },
            {
                "name": "Wschowa",
                "countryCode": "PL"
            },
            {
                "name": "Wymiarki",
                "countryCode": "PL"
            },
            {
                "name": "Zabór",
                "countryCode": "PL"
            },
            {
                "name": "Zbąszynek",
                "countryCode": "PL"
            },
            {
                "name": "Zielona Góra",
                "countryCode": "PL"
            },
            {
                "name": "Zwierzyń",
                "countryCode": "PL"
            },
            {
                "name": "Łagów",
                "countryCode": "PL"
            },
            {
                "name": "Łęknica",
                "countryCode": "PL"
            },
            {
                "name": "Świdnica",
                "countryCode": "PL"
            },
            {
                "name": "Świebodzin",
                "countryCode": "PL"
            },
            {
                "name": "Żagań",
                "countryCode": "PL"
            },
            {
                "name": "Żary",
                "countryCode": "PL"
            },
            {
                "name": "Aleksandrów",
                "countryCode": "PL"
            },
            {
                "name": "Aleksandrów Łódzki",
                "countryCode": "PL"
            },
            {
                "name": "Andrespol",
                "countryCode": "PL"
            },
            {
                "name": "Bedlno",
                "countryCode": "PL"
            },
            {
                "name": "Bełchatów",
                "countryCode": "PL"
            },
            {
                "name": "Biała Rawska",
                "countryCode": "PL"
            },
            {
                "name": "Białaczów",
                "countryCode": "PL"
            },
            {
                "name": "Bielawy",
                "countryCode": "PL"
            },
            {
                "name": "Bobrowniki",
                "countryCode": "PL"
            },
            {
                "name": "Bolesławiec",
                "countryCode": "PL"
            },
            {
                "name": "Bolimów",
                "countryCode": "PL"
            },
            {
                "name": "Brzeziny",
                "countryCode": "PL"
            },
            {
                "name": "Brzeźnio",
                "countryCode": "PL"
            },
            {
                "name": "Brójce",
                "countryCode": "PL"
            },
            {
                "name": "Brąszewice",
                "countryCode": "PL"
            },
            {
                "name": "Buczek",
                "countryCode": "PL"
            },
            {
                "name": "Budziszewice",
                "countryCode": "PL"
            },
            {
                "name": "Burzenin",
                "countryCode": "PL"
            },
            {
                "name": "Będków",
                "countryCode": "PL"
            },
            {
                "name": "Błaszki",
                "countryCode": "PL"
            },
            {
                "name": "Chąśno",
                "countryCode": "PL"
            },
            {
                "name": "Cielądz",
                "countryCode": "PL"
            },
            {
                "name": "Czarnocin",
                "countryCode": "PL"
            },
            {
                "name": "Czarnożyły",
                "countryCode": "PL"
            },
            {
                "name": "Czastary",
                "countryCode": "PL"
            },
            {
                "name": "Czerniewice",
                "countryCode": "PL"
            },
            {
                "name": "Dalików",
                "countryCode": "PL"
            },
            {
                "name": "Daszyna",
                "countryCode": "PL"
            },
            {
                "name": "Dmosin",
                "countryCode": "PL"
            },
            {
                "name": "Dobroń",
                "countryCode": "PL"
            },
            {
                "name": "Domaniewice",
                "countryCode": "PL"
            },
            {
                "name": "Drużbice",
                "countryCode": "PL"
            },
            {
                "name": "Drzewica",
                "countryCode": "PL"
            },
            {
                "name": "Działoszyn",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowice",
                "countryCode": "PL"
            },
            {
                "name": "Dłutów",
                "countryCode": "PL"
            },
            {
                "name": "Galewice",
                "countryCode": "PL"
            },
            {
                "name": "Gałków Mały",
                "countryCode": "PL"
            },
            {
                "name": "Gidle",
                "countryCode": "PL"
            },
            {
                "name": "Godzianów",
                "countryCode": "PL"
            },
            {
                "name": "Gomunice",
                "countryCode": "PL"
            },
            {
                "name": "Gorzkowice",
                "countryCode": "PL"
            },
            {
                "name": "Goszczanów",
                "countryCode": "PL"
            },
            {
                "name": "Grabica",
                "countryCode": "PL"
            },
            {
                "name": "Grabów",
                "countryCode": "PL"
            },
            {
                "name": "Góra Świętej Małgorzaty",
                "countryCode": "PL"
            },
            {
                "name": "Głowno",
                "countryCode": "PL"
            },
            {
                "name": "Głuchów",
                "countryCode": "PL"
            },
            {
                "name": "Inowłódz",
                "countryCode": "PL"
            },
            {
                "name": "Jeżów",
                "countryCode": "PL"
            },
            {
                "name": "Kamieńsk",
                "countryCode": "PL"
            },
            {
                "name": "Kawęczyn Nowy",
                "countryCode": "PL"
            },
            {
                "name": "Kiernozia",
                "countryCode": "PL"
            },
            {
                "name": "Kleszczów",
                "countryCode": "PL"
            },
            {
                "name": "Klonowa",
                "countryCode": "PL"
            },
            {
                "name": "Kluki",
                "countryCode": "PL"
            },
            {
                "name": "Kocierzew Południowy",
                "countryCode": "PL"
            },
            {
                "name": "Koluszki",
                "countryCode": "PL"
            },
            {
                "name": "Konstantynów Łódzki",
                "countryCode": "PL"
            },
            {
                "name": "Kowiesy",
                "countryCode": "PL"
            },
            {
                "name": "Krośniewice",
                "countryCode": "PL"
            },
            {
                "name": "Krzyżanów",
                "countryCode": "PL"
            },
            {
                "name": "Ksawerów",
                "countryCode": "PL"
            },
            {
                "name": "Kutno",
                "countryCode": "PL"
            },
            {
                "name": "Lgota Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Lipce Reymontowskie",
                "countryCode": "PL"
            },
            {
                "name": "Lubochnia",
                "countryCode": "PL"
            },
            {
                "name": "Lutomiersk",
                "countryCode": "PL"
            },
            {
                "name": "Lututów",
                "countryCode": "PL"
            },
            {
                "name": "Maków",
                "countryCode": "PL"
            },
            {
                "name": "Mniszków",
                "countryCode": "PL"
            },
            {
                "name": "Mokrsko",
                "countryCode": "PL"
            },
            {
                "name": "Moszczenica",
                "countryCode": "PL"
            },
            {
                "name": "Nieborów",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Ostrowy",
                "countryCode": "PL"
            },
            {
                "name": "Opoczno",
                "countryCode": "PL"
            },
            {
                "name": "Oporów",
                "countryCode": "PL"
            },
            {
                "name": "Osjaków",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowy",
                "countryCode": "PL"
            },
            {
                "name": "Ozorków",
                "countryCode": "PL"
            },
            {
                "name": "Ożarów",
                "countryCode": "PL"
            },
            {
                "name": "Pabianice",
                "countryCode": "PL"
            },
            {
                "name": "Pajęczno",
                "countryCode": "PL"
            },
            {
                "name": "Paradyż",
                "countryCode": "PL"
            },
            {
                "name": "Parzęczew",
                "countryCode": "PL"
            },
            {
                "name": "Piotrków Trybunalski",
                "countryCode": "PL"
            },
            {
                "name": "Piątek",
                "countryCode": "PL"
            },
            {
                "name": "Poddębice",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bełchatowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat brzeziński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kutnowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat opoczyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pabianicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pajęczański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat piotrkowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat poddębicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat radomszczański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rawski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sieradzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat skierniewicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tomaszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wieluński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wieruszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zduńskowolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zgierski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łaski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łódzki wschodni",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łęczycki",
                "countryCode": "PL"
            },
            {
                "name": "Poświętne",
                "countryCode": "PL"
            },
            {
                "name": "Przedbórz",
                "countryCode": "PL"
            },
            {
                "name": "Pątnów",
                "countryCode": "PL"
            },
            {
                "name": "Pęczniew",
                "countryCode": "PL"
            },
            {
                "name": "Pławno",
                "countryCode": "PL"
            },
            {
                "name": "Radomsko",
                "countryCode": "PL"
            },
            {
                "name": "Rawa Mazowiecka",
                "countryCode": "PL"
            },
            {
                "name": "Regnów",
                "countryCode": "PL"
            },
            {
                "name": "Rogów",
                "countryCode": "PL"
            },
            {
                "name": "Rokiciny",
                "countryCode": "PL"
            },
            {
                "name": "Rokiciny-Kolonia",
                "countryCode": "PL"
            },
            {
                "name": "Rozprza",
                "countryCode": "PL"
            },
            {
                "name": "Rusiec",
                "countryCode": "PL"
            },
            {
                "name": "Rzeczyca",
                "countryCode": "PL"
            },
            {
                "name": "Rzgów",
                "countryCode": "PL"
            },
            {
                "name": "Ręczno",
                "countryCode": "PL"
            },
            {
                "name": "Sadkowice",
                "countryCode": "PL"
            },
            {
                "name": "Siemkowice",
                "countryCode": "PL"
            },
            {
                "name": "Sieradz",
                "countryCode": "PL"
            },
            {
                "name": "Skierniewice",
                "countryCode": "PL"
            },
            {
                "name": "Skomlin",
                "countryCode": "PL"
            },
            {
                "name": "Sokolniki",
                "countryCode": "PL"
            },
            {
                "name": "Starowa Góra",
                "countryCode": "PL"
            },
            {
                "name": "Stryków",
                "countryCode": "PL"
            },
            {
                "name": "Strzelce",
                "countryCode": "PL"
            },
            {
                "name": "Strzelce Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Sulejów",
                "countryCode": "PL"
            },
            {
                "name": "Sulmierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Szadek",
                "countryCode": "PL"
            },
            {
                "name": "Szczerców",
                "countryCode": "PL"
            },
            {
                "name": "Sędziejowice",
                "countryCode": "PL"
            },
            {
                "name": "Sławno",
                "countryCode": "PL"
            },
            {
                "name": "Słupia",
                "countryCode": "PL"
            },
            {
                "name": "Tomaszów Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Tuszyn",
                "countryCode": "PL"
            },
            {
                "name": "Ujazd",
                "countryCode": "PL"
            },
            {
                "name": "Uniejów",
                "countryCode": "PL"
            },
            {
                "name": "Walichnowy",
                "countryCode": "PL"
            },
            {
                "name": "Warta",
                "countryCode": "PL"
            },
            {
                "name": "Wartkowice",
                "countryCode": "PL"
            },
            {
                "name": "Widawa",
                "countryCode": "PL"
            },
            {
                "name": "Wieluń",
                "countryCode": "PL"
            },
            {
                "name": "Wieruszów",
                "countryCode": "PL"
            },
            {
                "name": "Wierzchlas",
                "countryCode": "PL"
            },
            {
                "name": "Witonia",
                "countryCode": "PL"
            },
            {
                "name": "Wodzierady",
                "countryCode": "PL"
            },
            {
                "name": "Wola Krzysztoporska",
                "countryCode": "PL"
            },
            {
                "name": "Wolbórz",
                "countryCode": "PL"
            },
            {
                "name": "Wróblew",
                "countryCode": "PL"
            },
            {
                "name": "Zadzim",
                "countryCode": "PL"
            },
            {
                "name": "Zapolice",
                "countryCode": "PL"
            },
            {
                "name": "Zduńska Wola",
                "countryCode": "PL"
            },
            {
                "name": "Zelów",
                "countryCode": "PL"
            },
            {
                "name": "Zgierz",
                "countryCode": "PL"
            },
            {
                "name": "Złoczew",
                "countryCode": "PL"
            },
            {
                "name": "powiat Łowicki",
                "countryCode": "PL"
            },
            {
                "name": "Łanięta",
                "countryCode": "PL"
            },
            {
                "name": "Łask",
                "countryCode": "PL"
            },
            {
                "name": "Łowicz",
                "countryCode": "PL"
            },
            {
                "name": "Łubnice",
                "countryCode": "PL"
            },
            {
                "name": "Łyszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Łódź",
                "countryCode": "PL"
            },
            {
                "name": "Łęczyca",
                "countryCode": "PL"
            },
            {
                "name": "Łęki Szlacheckie",
                "countryCode": "PL"
            },
            {
                "name": "Świnice Warckie",
                "countryCode": "PL"
            },
            {
                "name": "Żarnów",
                "countryCode": "PL"
            },
            {
                "name": "Żelechlinek",
                "countryCode": "PL"
            },
            {
                "name": "Żychlin",
                "countryCode": "PL"
            },
            {
                "name": "Abramów",
                "countryCode": "PL"
            },
            {
                "name": "Adamów",
                "countryCode": "PL"
            },
            {
                "name": "Aleksandrów",
                "countryCode": "PL"
            },
            {
                "name": "Annopol",
                "countryCode": "PL"
            },
            {
                "name": "Baranów",
                "countryCode": "PL"
            },
            {
                "name": "Batorz",
                "countryCode": "PL"
            },
            {
                "name": "Bełżec",
                "countryCode": "PL"
            },
            {
                "name": "Bełżyce",
                "countryCode": "PL"
            },
            {
                "name": "Biała Podlaska",
                "countryCode": "PL"
            },
            {
                "name": "Biszcza",
                "countryCode": "PL"
            },
            {
                "name": "Biłgoraj",
                "countryCode": "PL"
            },
            {
                "name": "Borki",
                "countryCode": "PL"
            },
            {
                "name": "Borzechów",
                "countryCode": "PL"
            },
            {
                "name": "Bychawa",
                "countryCode": "PL"
            },
            {
                "name": "Chełm",
                "countryCode": "PL"
            },
            {
                "name": "Chodel",
                "countryCode": "PL"
            },
            {
                "name": "Chrzanów",
                "countryCode": "PL"
            },
            {
                "name": "Cyców",
                "countryCode": "PL"
            },
            {
                "name": "Czemierniki",
                "countryCode": "PL"
            },
            {
                "name": "Dorohusk",
                "countryCode": "PL"
            },
            {
                "name": "Dołhobyczów",
                "countryCode": "PL"
            },
            {
                "name": "Drelów",
                "countryCode": "PL"
            },
            {
                "name": "Dubienka",
                "countryCode": "PL"
            },
            {
                "name": "Dzwola",
                "countryCode": "PL"
            },
            {
                "name": "Dęblin",
                "countryCode": "PL"
            },
            {
                "name": "Dębowa Kłoda",
                "countryCode": "PL"
            },
            {
                "name": "Fajsławice",
                "countryCode": "PL"
            },
            {
                "name": "Firlej",
                "countryCode": "PL"
            },
            {
                "name": "Frampol",
                "countryCode": "PL"
            },
            {
                "name": "Garbów",
                "countryCode": "PL"
            },
            {
                "name": "Godziszów",
                "countryCode": "PL"
            },
            {
                "name": "Godziszów Pierwszy",
                "countryCode": "PL"
            },
            {
                "name": "Goraj",
                "countryCode": "PL"
            },
            {
                "name": "Gorzków",
                "countryCode": "PL"
            },
            {
                "name": "Gościeradów",
                "countryCode": "PL"
            },
            {
                "name": "Grabowiec",
                "countryCode": "PL"
            },
            {
                "name": "Hańsk",
                "countryCode": "PL"
            },
            {
                "name": "Horodło",
                "countryCode": "PL"
            },
            {
                "name": "Hrubieszów",
                "countryCode": "PL"
            },
            {
                "name": "Izbica",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonna",
                "countryCode": "PL"
            },
            {
                "name": "Jabłoń",
                "countryCode": "PL"
            },
            {
                "name": "Jakubowice Murowane",
                "countryCode": "PL"
            },
            {
                "name": "Janowiec",
                "countryCode": "PL"
            },
            {
                "name": "Janów Lubelski",
                "countryCode": "PL"
            },
            {
                "name": "Janów Podlaski",
                "countryCode": "PL"
            },
            {
                "name": "Jarczów",
                "countryCode": "PL"
            },
            {
                "name": "Jastków",
                "countryCode": "PL"
            },
            {
                "name": "Jeziorzany",
                "countryCode": "PL"
            },
            {
                "name": "Józefów",
                "countryCode": "PL"
            },
            {
                "name": "Józefów nad Wisłą",
                "countryCode": "PL"
            },
            {
                "name": "Kamionka",
                "countryCode": "PL"
            },
            {
                "name": "Karczmiska",
                "countryCode": "PL"
            },
            {
                "name": "Kazimierz Dolny",
                "countryCode": "PL"
            },
            {
                "name": "Kock",
                "countryCode": "PL"
            },
            {
                "name": "Kodeń",
                "countryCode": "PL"
            },
            {
                "name": "Komarów-Osada",
                "countryCode": "PL"
            },
            {
                "name": "Komarówka Podlaska",
                "countryCode": "PL"
            },
            {
                "name": "Konstantynów",
                "countryCode": "PL"
            },
            {
                "name": "Kozubszczyzna",
                "countryCode": "PL"
            },
            {
                "name": "Końskowola",
                "countryCode": "PL"
            },
            {
                "name": "Krasnobród",
                "countryCode": "PL"
            },
            {
                "name": "Krasnystaw",
                "countryCode": "PL"
            },
            {
                "name": "Kraśniczyn",
                "countryCode": "PL"
            },
            {
                "name": "Kraśnik",
                "countryCode": "PL"
            },
            {
                "name": "Krynice",
                "countryCode": "PL"
            },
            {
                "name": "Krzczonów",
                "countryCode": "PL"
            },
            {
                "name": "Krzywda",
                "countryCode": "PL"
            },
            {
                "name": "Księżomierz",
                "countryCode": "PL"
            },
            {
                "name": "Księżpol",
                "countryCode": "PL"
            },
            {
                "name": "Kurów",
                "countryCode": "PL"
            },
            {
                "name": "Kłoczew",
                "countryCode": "PL"
            },
            {
                "name": "Leśna Podlaska",
                "countryCode": "PL"
            },
            {
                "name": "Lubartów",
                "countryCode": "PL"
            },
            {
                "name": "Lublin",
                "countryCode": "PL"
            },
            {
                "name": "Lubycza Królewska",
                "countryCode": "PL"
            },
            {
                "name": "Ludwin",
                "countryCode": "PL"
            },
            {
                "name": "Markuszów",
                "countryCode": "PL"
            },
            {
                "name": "Mełgiew",
                "countryCode": "PL"
            },
            {
                "name": "Michałów",
                "countryCode": "PL"
            },
            {
                "name": "Michów",
                "countryCode": "PL"
            },
            {
                "name": "Milanów",
                "countryCode": "PL"
            },
            {
                "name": "Milejów",
                "countryCode": "PL"
            },
            {
                "name": "Mircze",
                "countryCode": "PL"
            },
            {
                "name": "Miączyn",
                "countryCode": "PL"
            },
            {
                "name": "Międzyrzec Podlaski",
                "countryCode": "PL"
            },
            {
                "name": "Modliborzyce",
                "countryCode": "PL"
            },
            {
                "name": "Nałęczów",
                "countryCode": "PL"
            },
            {
                "name": "Niedrzwica Duża",
                "countryCode": "PL"
            },
            {
                "name": "Niedźwiada",
                "countryCode": "PL"
            },
            {
                "name": "Nielisz",
                "countryCode": "PL"
            },
            {
                "name": "Niemce",
                "countryCode": "PL"
            },
            {
                "name": "Nowodwór",
                "countryCode": "PL"
            },
            {
                "name": "Obsza",
                "countryCode": "PL"
            },
            {
                "name": "Opole Lubelskie",
                "countryCode": "PL"
            },
            {
                "name": "Ostrów Lubelski",
                "countryCode": "PL"
            },
            {
                "name": "Ostrówek",
                "countryCode": "PL"
            },
            {
                "name": "Parczew",
                "countryCode": "PL"
            },
            {
                "name": "Piaski",
                "countryCode": "PL"
            },
            {
                "name": "Piszczac",
                "countryCode": "PL"
            },
            {
                "name": "Podedwórze",
                "countryCode": "PL"
            },
            {
                "name": "Pokrówka",
                "countryCode": "PL"
            },
            {
                "name": "Poniatowa",
                "countryCode": "PL"
            },
            {
                "name": "Potok Górny",
                "countryCode": "PL"
            },
            {
                "name": "Potok Wielki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bialski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat biłgorajski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat chełmski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat hrubieszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat janowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krasnostawski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kraśnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubartowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubelski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat opolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat parczewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat puławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat radzyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tomaszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat włodawski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zamojski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łukowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łęczyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat świdnicki",
                "countryCode": "PL"
            },
            {
                "name": "Przytoczno",
                "countryCode": "PL"
            },
            {
                "name": "Puchaczów",
                "countryCode": "PL"
            },
            {
                "name": "Puławy",
                "countryCode": "PL"
            },
            {
                "name": "Rachanie",
                "countryCode": "PL"
            },
            {
                "name": "Radecznica",
                "countryCode": "PL"
            },
            {
                "name": "Radzyń Podlaski",
                "countryCode": "PL"
            },
            {
                "name": "Rejowiec",
                "countryCode": "PL"
            },
            {
                "name": "Rejowiec Fabryczny",
                "countryCode": "PL"
            },
            {
                "name": "Rogóźno",
                "countryCode": "PL"
            },
            {
                "name": "Rokitno",
                "countryCode": "PL"
            },
            {
                "name": "Ruda-Huta",
                "countryCode": "PL"
            },
            {
                "name": "Rudnik",
                "countryCode": "PL"
            },
            {
                "name": "Rybczewice",
                "countryCode": "PL"
            },
            {
                "name": "Ryki",
                "countryCode": "PL"
            },
            {
                "name": "Rzeczyca",
                "countryCode": "PL"
            },
            {
                "name": "Sawin",
                "countryCode": "PL"
            },
            {
                "name": "Serniki",
                "countryCode": "PL"
            },
            {
                "name": "Serokomla",
                "countryCode": "PL"
            },
            {
                "name": "Siemień",
                "countryCode": "PL"
            },
            {
                "name": "Siennica Różana",
                "countryCode": "PL"
            },
            {
                "name": "Sitno",
                "countryCode": "PL"
            },
            {
                "name": "Skierbieszów",
                "countryCode": "PL"
            },
            {
                "name": "Sosnowica",
                "countryCode": "PL"
            },
            {
                "name": "Sosnówka",
                "countryCode": "PL"
            },
            {
                "name": "Spiczyn",
                "countryCode": "PL"
            },
            {
                "name": "Stary Zamość",
                "countryCode": "PL"
            },
            {
                "name": "Stoczek Łukowski",
                "countryCode": "PL"
            },
            {
                "name": "Strzyżowice",
                "countryCode": "PL"
            },
            {
                "name": "Stężyca",
                "countryCode": "PL"
            },
            {
                "name": "Susiec",
                "countryCode": "PL"
            },
            {
                "name": "Sułów",
                "countryCode": "PL"
            },
            {
                "name": "Szastarka",
                "countryCode": "PL"
            },
            {
                "name": "Szczebrzeszyn",
                "countryCode": "PL"
            },
            {
                "name": "Sławatycze",
                "countryCode": "PL"
            },
            {
                "name": "Tarnawatka",
                "countryCode": "PL"
            },
            {
                "name": "Tarnogród",
                "countryCode": "PL"
            },
            {
                "name": "Telatyn",
                "countryCode": "PL"
            },
            {
                "name": "Terespol",
                "countryCode": "PL"
            },
            {
                "name": "Tereszpol",
                "countryCode": "PL"
            },
            {
                "name": "Tomaszów Lubelski",
                "countryCode": "PL"
            },
            {
                "name": "Trawniki",
                "countryCode": "PL"
            },
            {
                "name": "Trzebieszów",
                "countryCode": "PL"
            },
            {
                "name": "Trzydnik Duży",
                "countryCode": "PL"
            },
            {
                "name": "Turobin",
                "countryCode": "PL"
            },
            {
                "name": "Tyszowce",
                "countryCode": "PL"
            },
            {
                "name": "Ulhówek",
                "countryCode": "PL"
            },
            {
                "name": "Urszulin",
                "countryCode": "PL"
            },
            {
                "name": "Urzędów",
                "countryCode": "PL"
            },
            {
                "name": "Ułęż",
                "countryCode": "PL"
            },
            {
                "name": "Uścimów Stary",
                "countryCode": "PL"
            },
            {
                "name": "Werbkowice",
                "countryCode": "PL"
            },
            {
                "name": "Wilkołaz",
                "countryCode": "PL"
            },
            {
                "name": "Wilków",
                "countryCode": "PL"
            },
            {
                "name": "Wisznice",
                "countryCode": "PL"
            },
            {
                "name": "Wohyń",
                "countryCode": "PL"
            },
            {
                "name": "Wojciechów",
                "countryCode": "PL"
            },
            {
                "name": "Wojcieszków",
                "countryCode": "PL"
            },
            {
                "name": "Wojsławice",
                "countryCode": "PL"
            },
            {
                "name": "Wola Sernicka",
                "countryCode": "PL"
            },
            {
                "name": "Wola Uhruska",
                "countryCode": "PL"
            },
            {
                "name": "Wysokie",
                "countryCode": "PL"
            },
            {
                "name": "Wąwolnica",
                "countryCode": "PL"
            },
            {
                "name": "Włodawa",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzew",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzówek",
                "countryCode": "PL"
            },
            {
                "name": "Zamch",
                "countryCode": "PL"
            },
            {
                "name": "Zamość",
                "countryCode": "PL"
            },
            {
                "name": "Zwierzyniec",
                "countryCode": "PL"
            },
            {
                "name": "Łabunie",
                "countryCode": "PL"
            },
            {
                "name": "Łaszczów",
                "countryCode": "PL"
            },
            {
                "name": "Łaziska",
                "countryCode": "PL"
            },
            {
                "name": "Łomazy",
                "countryCode": "PL"
            },
            {
                "name": "Łopiennik Górny",
                "countryCode": "PL"
            },
            {
                "name": "Łukowa",
                "countryCode": "PL"
            },
            {
                "name": "Łuków",
                "countryCode": "PL"
            },
            {
                "name": "Łęczna",
                "countryCode": "PL"
            },
            {
                "name": "Świdnik",
                "countryCode": "PL"
            },
            {
                "name": "Żyrzyn",
                "countryCode": "PL"
            },
            {
                "name": "Żółkiewka",
                "countryCode": "PL"
            },
            {
                "name": "Alwernia",
                "countryCode": "PL"
            },
            {
                "name": "Andrychów",
                "countryCode": "PL"
            },
            {
                "name": "Babice",
                "countryCode": "PL"
            },
            {
                "name": "Bachowice",
                "countryCode": "PL"
            },
            {
                "name": "Balice",
                "countryCode": "PL"
            },
            {
                "name": "Balin",
                "countryCode": "PL"
            },
            {
                "name": "Barwałd Górny",
                "countryCode": "PL"
            },
            {
                "name": "Barwałd Średni",
                "countryCode": "PL"
            },
            {
                "name": "Białka",
                "countryCode": "PL"
            },
            {
                "name": "Białka Tatrzańska",
                "countryCode": "PL"
            },
            {
                "name": "Biały Dunajec",
                "countryCode": "PL"
            },
            {
                "name": "Biecz",
                "countryCode": "PL"
            },
            {
                "name": "Bieńkówka",
                "countryCode": "PL"
            },
            {
                "name": "Binarowa",
                "countryCode": "PL"
            },
            {
                "name": "Biskupice Radłowskie",
                "countryCode": "PL"
            },
            {
                "name": "Bobowa",
                "countryCode": "PL"
            },
            {
                "name": "Bochnia",
                "countryCode": "PL"
            },
            {
                "name": "Bolechowice",
                "countryCode": "PL"
            },
            {
                "name": "Bolesław",
                "countryCode": "PL"
            },
            {
                "name": "Bolęcin",
                "countryCode": "PL"
            },
            {
                "name": "Borek",
                "countryCode": "PL"
            },
            {
                "name": "Borzęcin",
                "countryCode": "PL"
            },
            {
                "name": "Borzęta",
                "countryCode": "PL"
            },
            {
                "name": "Brody",
                "countryCode": "PL"
            },
            {
                "name": "Brodła",
                "countryCode": "PL"
            },
            {
                "name": "Brzesko",
                "countryCode": "PL"
            },
            {
                "name": "Brzeszcze",
                "countryCode": "PL"
            },
            {
                "name": "Brzezinka",
                "countryCode": "PL"
            },
            {
                "name": "Brzeźnica",
                "countryCode": "PL"
            },
            {
                "name": "Brzączowice",
                "countryCode": "PL"
            },
            {
                "name": "Budzów",
                "countryCode": "PL"
            },
            {
                "name": "Bukowina Tatrzańska",
                "countryCode": "PL"
            },
            {
                "name": "Bukowno",
                "countryCode": "PL"
            },
            {
                "name": "Bulowice",
                "countryCode": "PL"
            },
            {
                "name": "Bystra",
                "countryCode": "PL"
            },
            {
                "name": "Bębło",
                "countryCode": "PL"
            },
            {
                "name": "Bęczarka",
                "countryCode": "PL"
            },
            {
                "name": "Chełmek",
                "countryCode": "PL"
            },
            {
                "name": "Chełmiec",
                "countryCode": "PL"
            },
            {
                "name": "Chocznia",
                "countryCode": "PL"
            },
            {
                "name": "Chrzanów",
                "countryCode": "PL"
            },
            {
                "name": "Chyżne",
                "countryCode": "PL"
            },
            {
                "name": "Ciężkowice",
                "countryCode": "PL"
            },
            {
                "name": "Czarna Góra",
                "countryCode": "PL"
            },
            {
                "name": "Czarnochowice",
                "countryCode": "PL"
            },
            {
                "name": "Czarny Dunajec",
                "countryCode": "PL"
            },
            {
                "name": "Czchów",
                "countryCode": "PL"
            },
            {
                "name": "Czernichów",
                "countryCode": "PL"
            },
            {
                "name": "Czułów",
                "countryCode": "PL"
            },
            {
                "name": "Dobczyce",
                "countryCode": "PL"
            },
            {
                "name": "Dobra",
                "countryCode": "PL"
            },
            {
                "name": "Dziewin",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa Tarnowska",
                "countryCode": "PL"
            },
            {
                "name": "Dębno",
                "countryCode": "PL"
            },
            {
                "name": "Filipowice",
                "countryCode": "PL"
            },
            {
                "name": "Frydman",
                "countryCode": "PL"
            },
            {
                "name": "Frydrychowice",
                "countryCode": "PL"
            },
            {
                "name": "Gdów",
                "countryCode": "PL"
            },
            {
                "name": "Giebułtów",
                "countryCode": "PL"
            },
            {
                "name": "Gierałtowice",
                "countryCode": "PL"
            },
            {
                "name": "Gnojnik",
                "countryCode": "PL"
            },
            {
                "name": "Gorenice",
                "countryCode": "PL"
            },
            {
                "name": "Gorlice",
                "countryCode": "PL"
            },
            {
                "name": "Grojec",
                "countryCode": "PL"
            },
            {
                "name": "Gromnik",
                "countryCode": "PL"
            },
            {
                "name": "Gruszów Wielki",
                "countryCode": "PL"
            },
            {
                "name": "Grybów",
                "countryCode": "PL"
            },
            {
                "name": "Grzechynia",
                "countryCode": "PL"
            },
            {
                "name": "Gręboszów",
                "countryCode": "PL"
            },
            {
                "name": "Głogoczów",
                "countryCode": "PL"
            },
            {
                "name": "Harbutowice",
                "countryCode": "PL"
            },
            {
                "name": "Inwałd",
                "countryCode": "PL"
            },
            {
                "name": "Iwkowa",
                "countryCode": "PL"
            },
            {
                "name": "Izdebnik",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonka",
                "countryCode": "PL"
            },
            {
                "name": "Jadowniki",
                "countryCode": "PL"
            },
            {
                "name": "Jadowniki Mokre",
                "countryCode": "PL"
            },
            {
                "name": "Janowice",
                "countryCode": "PL"
            },
            {
                "name": "Jaroszowice",
                "countryCode": "PL"
            },
            {
                "name": "Jasienica",
                "countryCode": "PL"
            },
            {
                "name": "Jasień",
                "countryCode": "PL"
            },
            {
                "name": "Jastrzębia",
                "countryCode": "PL"
            },
            {
                "name": "Jawornik",
                "countryCode": "PL"
            },
            {
                "name": "Jerzmanowice",
                "countryCode": "PL"
            },
            {
                "name": "Jodłówka",
                "countryCode": "PL"
            },
            {
                "name": "Jodłówka-Wałki",
                "countryCode": "PL"
            },
            {
                "name": "Jordanów",
                "countryCode": "PL"
            },
            {
                "name": "Juszczyn",
                "countryCode": "PL"
            },
            {
                "name": "Kalwaria Zebrzydowska",
                "countryCode": "PL"
            },
            {
                "name": "Kamienica",
                "countryCode": "PL"
            },
            {
                "name": "Kamień",
                "countryCode": "PL"
            },
            {
                "name": "Kamionka Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Kasina Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Kaszów",
                "countryCode": "PL"
            },
            {
                "name": "Klecza Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Klikuszowa",
                "countryCode": "PL"
            },
            {
                "name": "Klimontów",
                "countryCode": "PL"
            },
            {
                "name": "Klucze",
                "countryCode": "PL"
            },
            {
                "name": "Kluszkowce",
                "countryCode": "PL"
            },
            {
                "name": "Kobylanka",
                "countryCode": "PL"
            },
            {
                "name": "Kokotów",
                "countryCode": "PL"
            },
            {
                "name": "Korzenna",
                "countryCode": "PL"
            },
            {
                "name": "Koszyce",
                "countryCode": "PL"
            },
            {
                "name": "Koszyce Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Kozłów",
                "countryCode": "PL"
            },
            {
                "name": "Kościelisko",
                "countryCode": "PL"
            },
            {
                "name": "Kraków",
                "countryCode": "PL"
            },
            {
                "name": "Krościenko nad Dunajcem",
                "countryCode": "PL"
            },
            {
                "name": "Krośnica",
                "countryCode": "PL"
            },
            {
                "name": "Krynica-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Kryspinów",
                "countryCode": "PL"
            },
            {
                "name": "Krzczonów",
                "countryCode": "PL"
            },
            {
                "name": "Krzeczów",
                "countryCode": "PL"
            },
            {
                "name": "Krzeszowice",
                "countryCode": "PL"
            },
            {
                "name": "Krzeszów",
                "countryCode": "PL"
            },
            {
                "name": "Krzyszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Krzywaczka",
                "countryCode": "PL"
            },
            {
                "name": "Krzęcin",
                "countryCode": "PL"
            },
            {
                "name": "Kuków",
                "countryCode": "PL"
            },
            {
                "name": "Kwaczała",
                "countryCode": "PL"
            },
            {
                "name": "Kęty",
                "countryCode": "PL"
            },
            {
                "name": "Kłaj",
                "countryCode": "PL"
            },
            {
                "name": "Lachowice",
                "countryCode": "PL"
            },
            {
                "name": "Lanckorona",
                "countryCode": "PL"
            },
            {
                "name": "Lasek",
                "countryCode": "PL"
            },
            {
                "name": "Laskowa",
                "countryCode": "PL"
            },
            {
                "name": "Leńcze",
                "countryCode": "PL"
            },
            {
                "name": "Leśnica",
                "countryCode": "PL"
            },
            {
                "name": "Libertów",
                "countryCode": "PL"
            },
            {
                "name": "Libiąż",
                "countryCode": "PL"
            },
            {
                "name": "Limanowa",
                "countryCode": "PL"
            },
            {
                "name": "Lipinki",
                "countryCode": "PL"
            },
            {
                "name": "Lipnica Mała",
                "countryCode": "PL"
            },
            {
                "name": "Lipnica Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Lipnik",
                "countryCode": "PL"
            },
            {
                "name": "Lisia Góra",
                "countryCode": "PL"
            },
            {
                "name": "Liszki",
                "countryCode": "PL"
            },
            {
                "name": "Lubień",
                "countryCode": "PL"
            },
            {
                "name": "Lubomierz",
                "countryCode": "PL"
            },
            {
                "name": "Ludźmierz",
                "countryCode": "PL"
            },
            {
                "name": "Luszowice",
                "countryCode": "PL"
            },
            {
                "name": "Maków Podhalański",
                "countryCode": "PL"
            },
            {
                "name": "Malec",
                "countryCode": "PL"
            },
            {
                "name": "Maniowy",
                "countryCode": "PL"
            },
            {
                "name": "Maszkienice",
                "countryCode": "PL"
            },
            {
                "name": "Michałowice",
                "countryCode": "PL"
            },
            {
                "name": "Miechów",
                "countryCode": "PL"
            },
            {
                "name": "Miechów Charsznica",
                "countryCode": "PL"
            },
            {
                "name": "Miękinia",
                "countryCode": "PL"
            },
            {
                "name": "Mników",
                "countryCode": "PL"
            },
            {
                "name": "Modlnica",
                "countryCode": "PL"
            },
            {
                "name": "Modlniczka",
                "countryCode": "PL"
            },
            {
                "name": "Mogilany",
                "countryCode": "PL"
            },
            {
                "name": "Moszczenica",
                "countryCode": "PL"
            },
            {
                "name": "Mszana Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Mszana Górna",
                "countryCode": "PL"
            },
            {
                "name": "Muszyna",
                "countryCode": "PL"
            },
            {
                "name": "Myślachowice",
                "countryCode": "PL"
            },
            {
                "name": "Myślenice",
                "countryCode": "PL"
            },
            {
                "name": "Mędrzechów",
                "countryCode": "PL"
            },
            {
                "name": "Mętków",
                "countryCode": "PL"
            },
            {
                "name": "Naprawa",
                "countryCode": "PL"
            },
            {
                "name": "Nawojowa",
                "countryCode": "PL"
            },
            {
                "name": "Nidek",
                "countryCode": "PL"
            },
            {
                "name": "Niedomice",
                "countryCode": "PL"
            },
            {
                "name": "Niedzica",
                "countryCode": "PL"
            },
            {
                "name": "Niedźwiedź",
                "countryCode": "PL"
            },
            {
                "name": "Niepołomice",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Góra",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Brzesko",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Sącz",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Targ",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Wiśnicz",
                "countryCode": "PL"
            },
            {
                "name": "Ochojno",
                "countryCode": "PL"
            },
            {
                "name": "Ochotnica Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Okocim",
                "countryCode": "PL"
            },
            {
                "name": "Olesno",
                "countryCode": "PL"
            },
            {
                "name": "Olkusz",
                "countryCode": "PL"
            },
            {
                "name": "Olszana",
                "countryCode": "PL"
            },
            {
                "name": "Olszówka",
                "countryCode": "PL"
            },
            {
                "name": "Osieczany",
                "countryCode": "PL"
            },
            {
                "name": "Osiek",
                "countryCode": "PL"
            },
            {
                "name": "Osielec",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowsko",
                "countryCode": "PL"
            },
            {
                "name": "Ostrężnica",
                "countryCode": "PL"
            },
            {
                "name": "Ołpiny",
                "countryCode": "PL"
            },
            {
                "name": "Oświęcim",
                "countryCode": "PL"
            },
            {
                "name": "Palcza",
                "countryCode": "PL"
            },
            {
                "name": "Pawlikowice",
                "countryCode": "PL"
            },
            {
                "name": "Pcim",
                "countryCode": "PL"
            },
            {
                "name": "Piekary",
                "countryCode": "PL"
            },
            {
                "name": "Piekielnik",
                "countryCode": "PL"
            },
            {
                "name": "Piwniczna-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Pleśna",
                "countryCode": "PL"
            },
            {
                "name": "Podegrodzie",
                "countryCode": "PL"
            },
            {
                "name": "Podwilk",
                "countryCode": "PL"
            },
            {
                "name": "Podłęże",
                "countryCode": "PL"
            },
            {
                "name": "Pogorzyce",
                "countryCode": "PL"
            },
            {
                "name": "Pogórska Wola",
                "countryCode": "PL"
            },
            {
                "name": "Polanka Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Ponikiew",
                "countryCode": "PL"
            },
            {
                "name": "Poronin",
                "countryCode": "PL"
            },
            {
                "name": "Porąbka Uszewska",
                "countryCode": "PL"
            },
            {
                "name": "Poręba",
                "countryCode": "PL"
            },
            {
                "name": "Poręba Spytkowska",
                "countryCode": "PL"
            },
            {
                "name": "Poręba Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bocheński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat brzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat chrzanowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat dąbrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gorlicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krakowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat limanowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat miechowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat myślenicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowosądecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowotarski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat olkuski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat oświęcimski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat proszowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat suski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tarnowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tatrzański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wadowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wielicki",
                "countryCode": "PL"
            },
            {
                "name": "Proszowice",
                "countryCode": "PL"
            },
            {
                "name": "Przecieszyn",
                "countryCode": "PL"
            },
            {
                "name": "Przeciszów",
                "countryCode": "PL"
            },
            {
                "name": "Przeginia",
                "countryCode": "PL"
            },
            {
                "name": "Przyborów",
                "countryCode": "PL"
            },
            {
                "name": "Przytkowice",
                "countryCode": "PL"
            },
            {
                "name": "Psary",
                "countryCode": "PL"
            },
            {
                "name": "Płaza",
                "countryCode": "PL"
            },
            {
                "name": "Raba Wyżna",
                "countryCode": "PL"
            },
            {
                "name": "Rabka-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Racławice",
                "countryCode": "PL"
            },
            {
                "name": "Radgoszcz",
                "countryCode": "PL"
            },
            {
                "name": "Radocza",
                "countryCode": "PL"
            },
            {
                "name": "Radziszów",
                "countryCode": "PL"
            },
            {
                "name": "Radłów",
                "countryCode": "PL"
            },
            {
                "name": "Rajsko",
                "countryCode": "PL"
            },
            {
                "name": "Regulice",
                "countryCode": "PL"
            },
            {
                "name": "Roczyny",
                "countryCode": "PL"
            },
            {
                "name": "Rokiciny",
                "countryCode": "PL"
            },
            {
                "name": "Ropa",
                "countryCode": "PL"
            },
            {
                "name": "Rudawa",
                "countryCode": "PL"
            },
            {
                "name": "Rudnik",
                "countryCode": "PL"
            },
            {
                "name": "Rusocice",
                "countryCode": "PL"
            },
            {
                "name": "Ryczów",
                "countryCode": "PL"
            },
            {
                "name": "Ryglice",
                "countryCode": "PL"
            },
            {
                "name": "Rytro",
                "countryCode": "PL"
            },
            {
                "name": "Rzepiennik Strzyżewski",
                "countryCode": "PL"
            },
            {
                "name": "Rzeszotary",
                "countryCode": "PL"
            },
            {
                "name": "Rzezawa",
                "countryCode": "PL"
            },
            {
                "name": "Rzozów",
                "countryCode": "PL"
            },
            {
                "name": "Rzyki",
                "countryCode": "PL"
            },
            {
                "name": "Rząska",
                "countryCode": "PL"
            },
            {
                "name": "Rączna",
                "countryCode": "PL"
            },
            {
                "name": "Sanka",
                "countryCode": "PL"
            },
            {
                "name": "Sidzina",
                "countryCode": "PL"
            },
            {
                "name": "Siedliska",
                "countryCode": "PL"
            },
            {
                "name": "Siemiechów",
                "countryCode": "PL"
            },
            {
                "name": "Sieniawa",
                "countryCode": "PL"
            },
            {
                "name": "Siepraw",
                "countryCode": "PL"
            },
            {
                "name": "Sieradza",
                "countryCode": "PL"
            },
            {
                "name": "Skawica",
                "countryCode": "PL"
            },
            {
                "name": "Skawina",
                "countryCode": "PL"
            },
            {
                "name": "Skawinki",
                "countryCode": "PL"
            },
            {
                "name": "Skała",
                "countryCode": "PL"
            },
            {
                "name": "Skomielna Czarna",
                "countryCode": "PL"
            },
            {
                "name": "Skrzydlna",
                "countryCode": "PL"
            },
            {
                "name": "Skrzyszów",
                "countryCode": "PL"
            },
            {
                "name": "Smęgorzów",
                "countryCode": "PL"
            },
            {
                "name": "Sosnowice",
                "countryCode": "PL"
            },
            {
                "name": "Spytkowice",
                "countryCode": "PL"
            },
            {
                "name": "Stanisław Dolny",
                "countryCode": "PL"
            },
            {
                "name": "Stanisław Górny",
                "countryCode": "PL"
            },
            {
                "name": "Stanisławice",
                "countryCode": "PL"
            },
            {
                "name": "Stary Sącz",
                "countryCode": "PL"
            },
            {
                "name": "Stary Wiśnicz",
                "countryCode": "PL"
            },
            {
                "name": "Stronie",
                "countryCode": "PL"
            },
            {
                "name": "Stryszawa",
                "countryCode": "PL"
            },
            {
                "name": "Stryszów",
                "countryCode": "PL"
            },
            {
                "name": "Stróża",
                "countryCode": "PL"
            },
            {
                "name": "Sucha Beskidzka",
                "countryCode": "PL"
            },
            {
                "name": "Sulęcin",
                "countryCode": "PL"
            },
            {
                "name": "Sułkowice",
                "countryCode": "PL"
            },
            {
                "name": "Sułoszowa",
                "countryCode": "PL"
            },
            {
                "name": "Szaflary",
                "countryCode": "PL"
            },
            {
                "name": "Szarów",
                "countryCode": "PL"
            },
            {
                "name": "Szczawnica",
                "countryCode": "PL"
            },
            {
                "name": "Szczucin",
                "countryCode": "PL"
            },
            {
                "name": "Szczurowa",
                "countryCode": "PL"
            },
            {
                "name": "Szerzyny",
                "countryCode": "PL"
            },
            {
                "name": "Szynwałd",
                "countryCode": "PL"
            },
            {
                "name": "Sąspów",
                "countryCode": "PL"
            },
            {
                "name": "Sękowa",
                "countryCode": "PL"
            },
            {
                "name": "Słomniki",
                "countryCode": "PL"
            },
            {
                "name": "Słopnice",
                "countryCode": "PL"
            },
            {
                "name": "Słupiec",
                "countryCode": "PL"
            },
            {
                "name": "Targanice",
                "countryCode": "PL"
            },
            {
                "name": "Targowisko",
                "countryCode": "PL"
            },
            {
                "name": "Tarnowiec",
                "countryCode": "PL"
            },
            {
                "name": "Tarnów",
                "countryCode": "PL"
            },
            {
                "name": "Tenczynek",
                "countryCode": "PL"
            },
            {
                "name": "Tokarnia",
                "countryCode": "PL"
            },
            {
                "name": "Tomaszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Tomice",
                "countryCode": "PL"
            },
            {
                "name": "Toporzysko",
                "countryCode": "PL"
            },
            {
                "name": "Trzciana",
                "countryCode": "PL"
            },
            {
                "name": "Trzebinia",
                "countryCode": "PL"
            },
            {
                "name": "Trzebunia",
                "countryCode": "PL"
            },
            {
                "name": "Trzemeśnia",
                "countryCode": "PL"
            },
            {
                "name": "Trąbki",
                "countryCode": "PL"
            },
            {
                "name": "Tuchów",
                "countryCode": "PL"
            },
            {
                "name": "Tylicz",
                "countryCode": "PL"
            },
            {
                "name": "Tymbark",
                "countryCode": "PL"
            },
            {
                "name": "Uście Gorlickie",
                "countryCode": "PL"
            },
            {
                "name": "Wadowice",
                "countryCode": "PL"
            },
            {
                "name": "Waksmund",
                "countryCode": "PL"
            },
            {
                "name": "Wawrzeńczyce",
                "countryCode": "PL"
            },
            {
                "name": "Wieliczka",
                "countryCode": "PL"
            },
            {
                "name": "Wielka Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Wieprz",
                "countryCode": "PL"
            },
            {
                "name": "Wierzchosławice",
                "countryCode": "PL"
            },
            {
                "name": "Wietrzychowice",
                "countryCode": "PL"
            },
            {
                "name": "Witanowice",
                "countryCode": "PL"
            },
            {
                "name": "Witkowice",
                "countryCode": "PL"
            },
            {
                "name": "Wiśniowa",
                "countryCode": "PL"
            },
            {
                "name": "Wojnicz",
                "countryCode": "PL"
            },
            {
                "name": "Wola Batorska",
                "countryCode": "PL"
            },
            {
                "name": "Wola Dębińska",
                "countryCode": "PL"
            },
            {
                "name": "Wola Filipowska",
                "countryCode": "PL"
            },
            {
                "name": "Wola Radziszowska",
                "countryCode": "PL"
            },
            {
                "name": "Wola Zabierzowska",
                "countryCode": "PL"
            },
            {
                "name": "Wolbrom",
                "countryCode": "PL"
            },
            {
                "name": "Wołowice",
                "countryCode": "PL"
            },
            {
                "name": "Woźniki",
                "countryCode": "PL"
            },
            {
                "name": "Wrząsowice",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka",
                "countryCode": "PL"
            },
            {
                "name": "Węglówka",
                "countryCode": "PL"
            },
            {
                "name": "Węgrzce Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Włosienica",
                "countryCode": "PL"
            },
            {
                "name": "Zabierzów",
                "countryCode": "PL"
            },
            {
                "name": "Zabierzów Bocheński",
                "countryCode": "PL"
            },
            {
                "name": "Zaborze",
                "countryCode": "PL"
            },
            {
                "name": "Zagórnik",
                "countryCode": "PL"
            },
            {
                "name": "Zagórze",
                "countryCode": "PL"
            },
            {
                "name": "Zakliczyn",
                "countryCode": "PL"
            },
            {
                "name": "Zakopane",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzów",
                "countryCode": "PL"
            },
            {
                "name": "Zalas",
                "countryCode": "PL"
            },
            {
                "name": "Zarzecze",
                "countryCode": "PL"
            },
            {
                "name": "Zator",
                "countryCode": "PL"
            },
            {
                "name": "Zawoja",
                "countryCode": "PL"
            },
            {
                "name": "Zebrzydowice",
                "countryCode": "PL"
            },
            {
                "name": "Zembrzyce",
                "countryCode": "PL"
            },
            {
                "name": "Zielonki",
                "countryCode": "PL"
            },
            {
                "name": "Zubrzyca Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Zubrzyca Górna",
                "countryCode": "PL"
            },
            {
                "name": "Złota",
                "countryCode": "PL"
            },
            {
                "name": "Łabowa",
                "countryCode": "PL"
            },
            {
                "name": "Łapanów",
                "countryCode": "PL"
            },
            {
                "name": "Łapczyca",
                "countryCode": "PL"
            },
            {
                "name": "Łapsze Niżne",
                "countryCode": "PL"
            },
            {
                "name": "Łopuszna",
                "countryCode": "PL"
            },
            {
                "name": "Łososina Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Łukowa",
                "countryCode": "PL"
            },
            {
                "name": "Łukowica",
                "countryCode": "PL"
            },
            {
                "name": "Łużna",
                "countryCode": "PL"
            },
            {
                "name": "Łącko",
                "countryCode": "PL"
            },
            {
                "name": "Łączany",
                "countryCode": "PL"
            },
            {
                "name": "Łętownia",
                "countryCode": "PL"
            },
            {
                "name": "Świątniki Górne",
                "countryCode": "PL"
            },
            {
                "name": "Żabno",
                "countryCode": "PL"
            },
            {
                "name": "Żarki",
                "countryCode": "PL"
            },
            {
                "name": "Żegocina",
                "countryCode": "PL"
            },
            {
                "name": "Żurowa",
                "countryCode": "PL"
            },
            {
                "name": "Baboszewo",
                "countryCode": "PL"
            },
            {
                "name": "Baniocha",
                "countryCode": "PL"
            },
            {
                "name": "Baranowo",
                "countryCode": "PL"
            },
            {
                "name": "Bemowo",
                "countryCode": "PL"
            },
            {
                "name": "Biała",
                "countryCode": "PL"
            },
            {
                "name": "Białobrzegi",
                "countryCode": "PL"
            },
            {
                "name": "Białołeka",
                "countryCode": "PL"
            },
            {
                "name": "Bielany",
                "countryCode": "PL"
            },
            {
                "name": "Bielsk",
                "countryCode": "PL"
            },
            {
                "name": "Bieniewice",
                "countryCode": "PL"
            },
            {
                "name": "Bieżuń",
                "countryCode": "PL"
            },
            {
                "name": "Bodzanów",
                "countryCode": "PL"
            },
            {
                "name": "Boguty-Pianki",
                "countryCode": "PL"
            },
            {
                "name": "Borkowice",
                "countryCode": "PL"
            },
            {
                "name": "Borowie",
                "countryCode": "PL"
            },
            {
                "name": "Brańszczyk",
                "countryCode": "PL"
            },
            {
                "name": "Brochów",
                "countryCode": "PL"
            },
            {
                "name": "Brody-Parcele",
                "countryCode": "PL"
            },
            {
                "name": "Brok",
                "countryCode": "PL"
            },
            {
                "name": "Brudzeń Duży",
                "countryCode": "PL"
            },
            {
                "name": "Brwinów",
                "countryCode": "PL"
            },
            {
                "name": "Bulkowo",
                "countryCode": "PL"
            },
            {
                "name": "Błonie",
                "countryCode": "PL"
            },
            {
                "name": "Błędów",
                "countryCode": "PL"
            },
            {
                "name": "Cegłów",
                "countryCode": "PL"
            },
            {
                "name": "Celestynów",
                "countryCode": "PL"
            },
            {
                "name": "Ceranów",
                "countryCode": "PL"
            },
            {
                "name": "Chlewiska",
                "countryCode": "PL"
            },
            {
                "name": "Chorzele",
                "countryCode": "PL"
            },
            {
                "name": "Chotcza",
                "countryCode": "PL"
            },
            {
                "name": "Chynów",
                "countryCode": "PL"
            },
            {
                "name": "Ciechanów",
                "countryCode": "PL"
            },
            {
                "name": "Ciepielów",
                "countryCode": "PL"
            },
            {
                "name": "Czarnia",
                "countryCode": "PL"
            },
            {
                "name": "Czernice Borowe",
                "countryCode": "PL"
            },
            {
                "name": "Czerwin",
                "countryCode": "PL"
            },
            {
                "name": "Czerwińsk Nad Wisłą",
                "countryCode": "PL"
            },
            {
                "name": "Czerwonka",
                "countryCode": "PL"
            },
            {
                "name": "Dobre",
                "countryCode": "PL"
            },
            {
                "name": "Domanice",
                "countryCode": "PL"
            },
            {
                "name": "Drobin",
                "countryCode": "PL"
            },
            {
                "name": "Duczki",
                "countryCode": "PL"
            },
            {
                "name": "Dziekanów Leśny",
                "countryCode": "PL"
            },
            {
                "name": "Dzierzgowo",
                "countryCode": "PL"
            },
            {
                "name": "Dzierzążnia",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrówka",
                "countryCode": "PL"
            },
            {
                "name": "Dębe Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Długosiodło",
                "countryCode": "PL"
            },
            {
                "name": "Garbatka-Letnisko",
                "countryCode": "PL"
            },
            {
                "name": "Garwolin",
                "countryCode": "PL"
            },
            {
                "name": "Gielniów",
                "countryCode": "PL"
            },
            {
                "name": "Glinojeck",
                "countryCode": "PL"
            },
            {
                "name": "Gostynin",
                "countryCode": "PL"
            },
            {
                "name": "Goszczyn",
                "countryCode": "PL"
            },
            {
                "name": "Goworowo",
                "countryCode": "PL"
            },
            {
                "name": "Gozdowo",
                "countryCode": "PL"
            },
            {
                "name": "Gołymin-Ośrodek",
                "countryCode": "PL"
            },
            {
                "name": "Granica",
                "countryCode": "PL"
            },
            {
                "name": "Grodzisk Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Grudusk",
                "countryCode": "PL"
            },
            {
                "name": "Grójec",
                "countryCode": "PL"
            },
            {
                "name": "Grębków",
                "countryCode": "PL"
            },
            {
                "name": "Guzów",
                "countryCode": "PL"
            },
            {
                "name": "Gzy",
                "countryCode": "PL"
            },
            {
                "name": "Góra Kalwaria",
                "countryCode": "PL"
            },
            {
                "name": "Górzno",
                "countryCode": "PL"
            },
            {
                "name": "Gózd",
                "countryCode": "PL"
            },
            {
                "name": "Gąbin",
                "countryCode": "PL"
            },
            {
                "name": "Gąsocin",
                "countryCode": "PL"
            },
            {
                "name": "Halinów",
                "countryCode": "PL"
            },
            {
                "name": "Hornówek",
                "countryCode": "PL"
            },
            {
                "name": "Izabelin",
                "countryCode": "PL"
            },
            {
                "name": "Iłów",
                "countryCode": "PL"
            },
            {
                "name": "Iłża",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonna",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonna Lacka",
                "countryCode": "PL"
            },
            {
                "name": "Jadów",
                "countryCode": "PL"
            },
            {
                "name": "Jakubów",
                "countryCode": "PL"
            },
            {
                "name": "Jasienica",
                "countryCode": "PL"
            },
            {
                "name": "Jasieniec",
                "countryCode": "PL"
            },
            {
                "name": "Jastrząb",
                "countryCode": "PL"
            },
            {
                "name": "Jastrzębia",
                "countryCode": "PL"
            },
            {
                "name": "Jedlińsk",
                "countryCode": "PL"
            },
            {
                "name": "Jedlnia-Letnisko",
                "countryCode": "PL"
            },
            {
                "name": "Jednorożec",
                "countryCode": "PL"
            },
            {
                "name": "Joniec",
                "countryCode": "PL"
            },
            {
                "name": "Józefosław",
                "countryCode": "PL"
            },
            {
                "name": "Józefów",
                "countryCode": "PL"
            },
            {
                "name": "Kabaty",
                "countryCode": "PL"
            },
            {
                "name": "Kadzidło",
                "countryCode": "PL"
            },
            {
                "name": "Kampinos",
                "countryCode": "PL"
            },
            {
                "name": "Karczew",
                "countryCode": "PL"
            },
            {
                "name": "Karniewo",
                "countryCode": "PL"
            },
            {
                "name": "Kazanów",
                "countryCode": "PL"
            },
            {
                "name": "Kałuszyn",
                "countryCode": "PL"
            },
            {
                "name": "Kiełpin",
                "countryCode": "PL"
            },
            {
                "name": "Klembów",
                "countryCode": "PL"
            },
            {
                "name": "Klwów",
                "countryCode": "PL"
            },
            {
                "name": "Kobyłka",
                "countryCode": "PL"
            },
            {
                "name": "Komorów",
                "countryCode": "PL"
            },
            {
                "name": "Konstancin-Jeziorna",
                "countryCode": "PL"
            },
            {
                "name": "Korczew",
                "countryCode": "PL"
            },
            {
                "name": "Korytnica",
                "countryCode": "PL"
            },
            {
                "name": "Kosów Lacki",
                "countryCode": "PL"
            },
            {
                "name": "Kotuń",
                "countryCode": "PL"
            },
            {
                "name": "Kowala",
                "countryCode": "PL"
            },
            {
                "name": "Kozienice",
                "countryCode": "PL"
            },
            {
                "name": "Kołbiel",
                "countryCode": "PL"
            },
            {
                "name": "Krasne",
                "countryCode": "PL"
            },
            {
                "name": "Krasnosielc",
                "countryCode": "PL"
            },
            {
                "name": "Krzynowłoga Mała",
                "countryCode": "PL"
            },
            {
                "name": "Kuczbork-Osada",
                "countryCode": "PL"
            },
            {
                "name": "Latowicz",
                "countryCode": "PL"
            },
            {
                "name": "Legionowo",
                "countryCode": "PL"
            },
            {
                "name": "Leszno",
                "countryCode": "PL"
            },
            {
                "name": "Lesznowola",
                "countryCode": "PL"
            },
            {
                "name": "Lipowiec Kościelny",
                "countryCode": "PL"
            },
            {
                "name": "Lipsko",
                "countryCode": "PL"
            },
            {
                "name": "Lubowidz",
                "countryCode": "PL"
            },
            {
                "name": "Lutocin",
                "countryCode": "PL"
            },
            {
                "name": "Maciejowice",
                "countryCode": "PL"
            },
            {
                "name": "Maków Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Marki",
                "countryCode": "PL"
            },
            {
                "name": "Maszewo Duże",
                "countryCode": "PL"
            },
            {
                "name": "Mała Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Małkinia Górna",
                "countryCode": "PL"
            },
            {
                "name": "Miastków Kościelny",
                "countryCode": "PL"
            },
            {
                "name": "Michałowice",
                "countryCode": "PL"
            },
            {
                "name": "Michałów-Reginów",
                "countryCode": "PL"
            },
            {
                "name": "Miedzna",
                "countryCode": "PL"
            },
            {
                "name": "Milanówek",
                "countryCode": "PL"
            },
            {
                "name": "Miętne",
                "countryCode": "PL"
            },
            {
                "name": "Mińsk Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Mochowo",
                "countryCode": "PL"
            },
            {
                "name": "Mogielnica",
                "countryCode": "PL"
            },
            {
                "name": "Mokobody",
                "countryCode": "PL"
            },
            {
                "name": "Mokotów",
                "countryCode": "PL"
            },
            {
                "name": "Mordy",
                "countryCode": "PL"
            },
            {
                "name": "Mrozy",
                "countryCode": "PL"
            },
            {
                "name": "Mszczonów",
                "countryCode": "PL"
            },
            {
                "name": "Mysiadło",
                "countryCode": "PL"
            },
            {
                "name": "Myszyniec",
                "countryCode": "PL"
            },
            {
                "name": "Mława",
                "countryCode": "PL"
            },
            {
                "name": "Młodzieszyn",
                "countryCode": "PL"
            },
            {
                "name": "Młynarze",
                "countryCode": "PL"
            },
            {
                "name": "Nadarzyn",
                "countryCode": "PL"
            },
            {
                "name": "Naruszewo",
                "countryCode": "PL"
            },
            {
                "name": "Nasielsk",
                "countryCode": "PL"
            },
            {
                "name": "Nieporęt",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Grocholice",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Lipiny",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Miasto",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Miasto nad Pilicą",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Duninów",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Dwór Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Nur",
                "countryCode": "PL"
            },
            {
                "name": "Obryte",
                "countryCode": "PL"
            },
            {
                "name": "Ochota",
                "countryCode": "PL"
            },
            {
                "name": "Odrzywół",
                "countryCode": "PL"
            },
            {
                "name": "Ojrzeń",
                "countryCode": "PL"
            },
            {
                "name": "Opinogóra Górna",
                "countryCode": "PL"
            },
            {
                "name": "Orońsko",
                "countryCode": "PL"
            },
            {
                "name": "Osieck",
                "countryCode": "PL"
            },
            {
                "name": "Ostrołęka",
                "countryCode": "PL"
            },
            {
                "name": "Ostrów Mazowiecka",
                "countryCode": "PL"
            },
            {
                "name": "Ostrówek",
                "countryCode": "PL"
            },
            {
                "name": "Otrębusy",
                "countryCode": "PL"
            },
            {
                "name": "Otwock",
                "countryCode": "PL"
            },
            {
                "name": "Owczarnia",
                "countryCode": "PL"
            },
            {
                "name": "Ożarów Mazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Pacyna",
                "countryCode": "PL"
            },
            {
                "name": "Paprotnia",
                "countryCode": "PL"
            },
            {
                "name": "Parysów",
                "countryCode": "PL"
            },
            {
                "name": "Piaseczno",
                "countryCode": "PL"
            },
            {
                "name": "Piastów",
                "countryCode": "PL"
            },
            {
                "name": "Pilawa",
                "countryCode": "PL"
            },
            {
                "name": "Pionki",
                "countryCode": "PL"
            },
            {
                "name": "Podebłocie",
                "countryCode": "PL"
            },
            {
                "name": "Podkowa Leśna",
                "countryCode": "PL"
            },
            {
                "name": "Pokrzywnica",
                "countryCode": "PL"
            },
            {
                "name": "Policzna",
                "countryCode": "PL"
            },
            {
                "name": "Pomiechówek",
                "countryCode": "PL"
            },
            {
                "name": "Potworów",
                "countryCode": "PL"
            },
            {
                "name": "Powiat białobrzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ciechanowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat garwoliński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gostyniński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat grodziski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat grójecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kozienicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat legionowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lipski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat makowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat miński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat mławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowodworski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostrołęcki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat otwocki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat piaseczyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pruszkowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat przasnyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat przysuski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pułtuski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat płocki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat płoński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat radomski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat siedlecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sierpecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sochaczewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sokołowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat szydłowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat warszawski zachodni",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wołomiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wyszkowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat węgrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zwoleński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łosicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żuromiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żyrardowski",
                "countryCode": "PL"
            },
            {
                "name": "Poświętne",
                "countryCode": "PL"
            },
            {
                "name": "Praga Południe",
                "countryCode": "PL"
            },
            {
                "name": "Praga Północ",
                "countryCode": "PL"
            },
            {
                "name": "Prażmów",
                "countryCode": "PL"
            },
            {
                "name": "Promna",
                "countryCode": "PL"
            },
            {
                "name": "Pruszków",
                "countryCode": "PL"
            },
            {
                "name": "Przasnysz",
                "countryCode": "PL"
            },
            {
                "name": "Przesmyki",
                "countryCode": "PL"
            },
            {
                "name": "Przysucha",
                "countryCode": "PL"
            },
            {
                "name": "Przytyk",
                "countryCode": "PL"
            },
            {
                "name": "Przyłęk",
                "countryCode": "PL"
            },
            {
                "name": "Puszcza Mariańska",
                "countryCode": "PL"
            },
            {
                "name": "Pułtusk",
                "countryCode": "PL"
            },
            {
                "name": "Płock",
                "countryCode": "PL"
            },
            {
                "name": "Płoniawy-Bramura",
                "countryCode": "PL"
            },
            {
                "name": "Płońsk",
                "countryCode": "PL"
            },
            {
                "name": "Raciąż",
                "countryCode": "PL"
            },
            {
                "name": "Radom",
                "countryCode": "PL"
            },
            {
                "name": "Radzanowo",
                "countryCode": "PL"
            },
            {
                "name": "Radzanów",
                "countryCode": "PL"
            },
            {
                "name": "Radziejowice",
                "countryCode": "PL"
            },
            {
                "name": "Radzymin",
                "countryCode": "PL"
            },
            {
                "name": "Raszyn",
                "countryCode": "PL"
            },
            {
                "name": "Regimin",
                "countryCode": "PL"
            },
            {
                "name": "Rembertów",
                "countryCode": "PL"
            },
            {
                "name": "Repki",
                "countryCode": "PL"
            },
            {
                "name": "Rościszewo",
                "countryCode": "PL"
            },
            {
                "name": "Rusinów",
                "countryCode": "PL"
            },
            {
                "name": "Rybie",
                "countryCode": "PL"
            },
            {
                "name": "Rybno",
                "countryCode": "PL"
            },
            {
                "name": "Ryczywół",
                "countryCode": "PL"
            },
            {
                "name": "Rzeczniów",
                "countryCode": "PL"
            },
            {
                "name": "Rzekuń",
                "countryCode": "PL"
            },
            {
                "name": "Rzewnie",
                "countryCode": "PL"
            },
            {
                "name": "Rząśnik",
                "countryCode": "PL"
            },
            {
                "name": "Różan",
                "countryCode": "PL"
            },
            {
                "name": "Sabnie",
                "countryCode": "PL"
            },
            {
                "name": "Sadowne",
                "countryCode": "PL"
            },
            {
                "name": "Sanniki",
                "countryCode": "PL"
            },
            {
                "name": "Sarnaki",
                "countryCode": "PL"
            },
            {
                "name": "Serock",
                "countryCode": "PL"
            },
            {
                "name": "Siedlce",
                "countryCode": "PL"
            },
            {
                "name": "Siemiątkowo",
                "countryCode": "PL"
            },
            {
                "name": "Siennica",
                "countryCode": "PL"
            },
            {
                "name": "Sienno",
                "countryCode": "PL"
            },
            {
                "name": "Sierpc",
                "countryCode": "PL"
            },
            {
                "name": "Skaryszew",
                "countryCode": "PL"
            },
            {
                "name": "Skórzec",
                "countryCode": "PL"
            },
            {
                "name": "Sobienie Jeziory",
                "countryCode": "PL"
            },
            {
                "name": "Sobolew",
                "countryCode": "PL"
            },
            {
                "name": "Sochaczew",
                "countryCode": "PL"
            },
            {
                "name": "Sochocin",
                "countryCode": "PL"
            },
            {
                "name": "Sokołów Podlaski",
                "countryCode": "PL"
            },
            {
                "name": "Solec Nad Wisłą",
                "countryCode": "PL"
            },
            {
                "name": "Sońsk",
                "countryCode": "PL"
            },
            {
                "name": "Stanisławów",
                "countryCode": "PL"
            },
            {
                "name": "Stanisławów Pierwszy",
                "countryCode": "PL"
            },
            {
                "name": "Stara Błotnica",
                "countryCode": "PL"
            },
            {
                "name": "Stara Kornica",
                "countryCode": "PL"
            },
            {
                "name": "Stare Babice",
                "countryCode": "PL"
            },
            {
                "name": "Staroźreby",
                "countryCode": "PL"
            },
            {
                "name": "Sterdyń",
                "countryCode": "PL"
            },
            {
                "name": "Stoczek",
                "countryCode": "PL"
            },
            {
                "name": "Strachówka",
                "countryCode": "PL"
            },
            {
                "name": "Stromiec",
                "countryCode": "PL"
            },
            {
                "name": "Strzegowo",
                "countryCode": "PL"
            },
            {
                "name": "Stupsk",
                "countryCode": "PL"
            },
            {
                "name": "Sucha",
                "countryCode": "PL"
            },
            {
                "name": "Suchożebry",
                "countryCode": "PL"
            },
            {
                "name": "Sulbiny Górne",
                "countryCode": "PL"
            },
            {
                "name": "Sulejówek",
                "countryCode": "PL"
            },
            {
                "name": "Sułkowice",
                "countryCode": "PL"
            },
            {
                "name": "Sypniewo",
                "countryCode": "PL"
            },
            {
                "name": "Szczutowo",
                "countryCode": "PL"
            },
            {
                "name": "Szelków",
                "countryCode": "PL"
            },
            {
                "name": "Szreńsk",
                "countryCode": "PL"
            },
            {
                "name": "Szydłowiec",
                "countryCode": "PL"
            },
            {
                "name": "Szydłowo",
                "countryCode": "PL"
            },
            {
                "name": "Słubice",
                "countryCode": "PL"
            },
            {
                "name": "Słupno",
                "countryCode": "PL"
            },
            {
                "name": "Tarczyn",
                "countryCode": "PL"
            },
            {
                "name": "Targówek",
                "countryCode": "PL"
            },
            {
                "name": "Tczów",
                "countryCode": "PL"
            },
            {
                "name": "Teresin",
                "countryCode": "PL"
            },
            {
                "name": "Trablice",
                "countryCode": "PL"
            },
            {
                "name": "Trojanów",
                "countryCode": "PL"
            },
            {
                "name": "Troszyn",
                "countryCode": "PL"
            },
            {
                "name": "Truskaw",
                "countryCode": "PL"
            },
            {
                "name": "Trąbki",
                "countryCode": "PL"
            },
            {
                "name": "Tłuszcz",
                "countryCode": "PL"
            },
            {
                "name": "Ursus",
                "countryCode": "PL"
            },
            {
                "name": "Ursynów",
                "countryCode": "PL"
            },
            {
                "name": "Warka",
                "countryCode": "PL"
            },
            {
                "name": "Warsaw",
                "countryCode": "PL"
            },
            {
                "name": "Warszawa",
                "countryCode": "PL"
            },
            {
                "name": "Wawer",
                "countryCode": "PL"
            },
            {
                "name": "Wesoła",
                "countryCode": "PL"
            },
            {
                "name": "Wieczfnia Kościelna",
                "countryCode": "PL"
            },
            {
                "name": "Wieliszew",
                "countryCode": "PL"
            },
            {
                "name": "Wieniawa",
                "countryCode": "PL"
            },
            {
                "name": "Wierzbica",
                "countryCode": "PL"
            },
            {
                "name": "Wierzbno",
                "countryCode": "PL"
            },
            {
                "name": "Wilanów",
                "countryCode": "PL"
            },
            {
                "name": "Wilga",
                "countryCode": "PL"
            },
            {
                "name": "Winnica",
                "countryCode": "PL"
            },
            {
                "name": "Wiskitki",
                "countryCode": "PL"
            },
            {
                "name": "Wiśniew",
                "countryCode": "PL"
            },
            {
                "name": "Wiśniewo",
                "countryCode": "PL"
            },
            {
                "name": "Wodynie",
                "countryCode": "PL"
            },
            {
                "name": "Wola",
                "countryCode": "PL"
            },
            {
                "name": "Wola Rębkowska",
                "countryCode": "PL"
            },
            {
                "name": "Wolanów",
                "countryCode": "PL"
            },
            {
                "name": "Wołomin",
                "countryCode": "PL"
            },
            {
                "name": "Wyszków",
                "countryCode": "PL"
            },
            {
                "name": "Wyszogród",
                "countryCode": "PL"
            },
            {
                "name": "Wyśmierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Węgrów",
                "countryCode": "PL"
            },
            {
                "name": "Włochy",
                "countryCode": "PL"
            },
            {
                "name": "Zakroczym",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzew",
                "countryCode": "PL"
            },
            {
                "name": "Zalesie Górne",
                "countryCode": "PL"
            },
            {
                "name": "Zatory",
                "countryCode": "PL"
            },
            {
                "name": "Zawidz",
                "countryCode": "PL"
            },
            {
                "name": "Załuski",
                "countryCode": "PL"
            },
            {
                "name": "Zbuczyn",
                "countryCode": "PL"
            },
            {
                "name": "Zielonka",
                "countryCode": "PL"
            },
            {
                "name": "Zwoleń",
                "countryCode": "PL"
            },
            {
                "name": "Ząbki",
                "countryCode": "PL"
            },
            {
                "name": "Łajski",
                "countryCode": "PL"
            },
            {
                "name": "Łaskarzew",
                "countryCode": "PL"
            },
            {
                "name": "Łochów",
                "countryCode": "PL"
            },
            {
                "name": "Łomianki",
                "countryCode": "PL"
            },
            {
                "name": "Łosice",
                "countryCode": "PL"
            },
            {
                "name": "Łyse",
                "countryCode": "PL"
            },
            {
                "name": "Łąck",
                "countryCode": "PL"
            },
            {
                "name": "Śródmieście",
                "countryCode": "PL"
            },
            {
                "name": "Świercze",
                "countryCode": "PL"
            },
            {
                "name": "Żabia Wola",
                "countryCode": "PL"
            },
            {
                "name": "Żabieniec",
                "countryCode": "PL"
            },
            {
                "name": "Żelechów",
                "countryCode": "PL"
            },
            {
                "name": "Żoliborz",
                "countryCode": "PL"
            },
            {
                "name": "Żuromin",
                "countryCode": "PL"
            },
            {
                "name": "Żyrardów",
                "countryCode": "PL"
            },
            {
                "name": "Baborów",
                "countryCode": "PL"
            },
            {
                "name": "Biała",
                "countryCode": "PL"
            },
            {
                "name": "Bierawa",
                "countryCode": "PL"
            },
            {
                "name": "Bierdzany",
                "countryCode": "PL"
            },
            {
                "name": "Branice",
                "countryCode": "PL"
            },
            {
                "name": "Brzeg",
                "countryCode": "PL"
            },
            {
                "name": "Byczyna",
                "countryCode": "PL"
            },
            {
                "name": "Chmielowice",
                "countryCode": "PL"
            },
            {
                "name": "Chrościna",
                "countryCode": "PL"
            },
            {
                "name": "Chrząstowice",
                "countryCode": "PL"
            },
            {
                "name": "Chróścice",
                "countryCode": "PL"
            },
            {
                "name": "Cisek",
                "countryCode": "PL"
            },
            {
                "name": "Czarnowąsy",
                "countryCode": "PL"
            },
            {
                "name": "Dalachów",
                "countryCode": "PL"
            },
            {
                "name": "Dobrodzień",
                "countryCode": "PL"
            },
            {
                "name": "Dobrzeń Wielki",
                "countryCode": "PL"
            },
            {
                "name": "Domaszowice",
                "countryCode": "PL"
            },
            {
                "name": "Dziergowice",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa",
                "countryCode": "PL"
            },
            {
                "name": "Długomiłowice",
                "countryCode": "PL"
            },
            {
                "name": "Gogolin",
                "countryCode": "PL"
            },
            {
                "name": "Gorzów Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Grodków",
                "countryCode": "PL"
            },
            {
                "name": "Górażdże",
                "countryCode": "PL"
            },
            {
                "name": "Głogówek",
                "countryCode": "PL"
            },
            {
                "name": "Głubczyce",
                "countryCode": "PL"
            },
            {
                "name": "Głuchołazy",
                "countryCode": "PL"
            },
            {
                "name": "Izbicko",
                "countryCode": "PL"
            },
            {
                "name": "Januszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Jemielnica",
                "countryCode": "PL"
            },
            {
                "name": "Kamienica",
                "countryCode": "PL"
            },
            {
                "name": "Kamiennik",
                "countryCode": "PL"
            },
            {
                "name": "Kietrz",
                "countryCode": "PL"
            },
            {
                "name": "Kluczbork",
                "countryCode": "PL"
            },
            {
                "name": "Kolonowskie",
                "countryCode": "PL"
            },
            {
                "name": "Komprachcice",
                "countryCode": "PL"
            },
            {
                "name": "Korfantów",
                "countryCode": "PL"
            },
            {
                "name": "Koźle",
                "countryCode": "PL"
            },
            {
                "name": "Krapkowice",
                "countryCode": "PL"
            },
            {
                "name": "Kup",
                "countryCode": "PL"
            },
            {
                "name": "Kędzierzyn-Koźle",
                "countryCode": "PL"
            },
            {
                "name": "Lewin Brzeski",
                "countryCode": "PL"
            },
            {
                "name": "Leśnica",
                "countryCode": "PL"
            },
            {
                "name": "Lubrza",
                "countryCode": "PL"
            },
            {
                "name": "Lubsza",
                "countryCode": "PL"
            },
            {
                "name": "Moszczanka",
                "countryCode": "PL"
            },
            {
                "name": "Murów",
                "countryCode": "PL"
            },
            {
                "name": "Nakło",
                "countryCode": "PL"
            },
            {
                "name": "Namysłów",
                "countryCode": "PL"
            },
            {
                "name": "Niemodlin",
                "countryCode": "PL"
            },
            {
                "name": "Nysa",
                "countryCode": "PL"
            },
            {
                "name": "Olesno",
                "countryCode": "PL"
            },
            {
                "name": "Olszanka",
                "countryCode": "PL"
            },
            {
                "name": "Opole",
                "countryCode": "PL"
            },
            {
                "name": "Otmuchów",
                "countryCode": "PL"
            },
            {
                "name": "Ozimek",
                "countryCode": "PL"
            },
            {
                "name": "Paczków",
                "countryCode": "PL"
            },
            {
                "name": "Pakosławice",
                "countryCode": "PL"
            },
            {
                "name": "Pawłowiczki",
                "countryCode": "PL"
            },
            {
                "name": "Pokój",
                "countryCode": "PL"
            },
            {
                "name": "Polska Cerekiew",
                "countryCode": "PL"
            },
            {
                "name": "Popielów",
                "countryCode": "PL"
            },
            {
                "name": "Powiat brzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat głubczycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kluczborski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krapkowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kędzierzyńsko-kozielski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat namysłowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat oleski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat opolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat prudnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat strzelecki",
                "countryCode": "PL"
            },
            {
                "name": "Praszka",
                "countryCode": "PL"
            },
            {
                "name": "Prudnik",
                "countryCode": "PL"
            },
            {
                "name": "Prószków",
                "countryCode": "PL"
            },
            {
                "name": "Racławice Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Raszowa",
                "countryCode": "PL"
            },
            {
                "name": "Reńska Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Rozwadza",
                "countryCode": "PL"
            },
            {
                "name": "Sidzina",
                "countryCode": "PL"
            },
            {
                "name": "Skarbimierz Osiedle",
                "countryCode": "PL"
            },
            {
                "name": "Skorogoszcz",
                "countryCode": "PL"
            },
            {
                "name": "Skoroszyce",
                "countryCode": "PL"
            },
            {
                "name": "Strzelce Opolskie",
                "countryCode": "PL"
            },
            {
                "name": "Strzeleczki",
                "countryCode": "PL"
            },
            {
                "name": "Sławięcice",
                "countryCode": "PL"
            },
            {
                "name": "Tarnów Opolski",
                "countryCode": "PL"
            },
            {
                "name": "Tułowice",
                "countryCode": "PL"
            },
            {
                "name": "Twardawa",
                "countryCode": "PL"
            },
            {
                "name": "Ujazd",
                "countryCode": "PL"
            },
            {
                "name": "Walce",
                "countryCode": "PL"
            },
            {
                "name": "Wilków",
                "countryCode": "PL"
            },
            {
                "name": "Większyce",
                "countryCode": "PL"
            },
            {
                "name": "Wołczyn",
                "countryCode": "PL"
            },
            {
                "name": "Węgry",
                "countryCode": "PL"
            },
            {
                "name": "Zawadzkie",
                "countryCode": "PL"
            },
            {
                "name": "Zdzieszowice",
                "countryCode": "PL"
            },
            {
                "name": "Zębowice",
                "countryCode": "PL"
            },
            {
                "name": "Łambinowice",
                "countryCode": "PL"
            },
            {
                "name": "Łosiów",
                "countryCode": "PL"
            },
            {
                "name": "Łubniany",
                "countryCode": "PL"
            },
            {
                "name": "Łąka Prudnicka",
                "countryCode": "PL"
            },
            {
                "name": "Świerczów",
                "countryCode": "PL"
            },
            {
                "name": "Augustów",
                "countryCode": "PL"
            },
            {
                "name": "Białowieża",
                "countryCode": "PL"
            },
            {
                "name": "Białystok",
                "countryCode": "PL"
            },
            {
                "name": "Bielsk Podlaski",
                "countryCode": "PL"
            },
            {
                "name": "Boćki",
                "countryCode": "PL"
            },
            {
                "name": "Brańsk",
                "countryCode": "PL"
            },
            {
                "name": "Choroszcz",
                "countryCode": "PL"
            },
            {
                "name": "Ciechanowiec",
                "countryCode": "PL"
            },
            {
                "name": "Czarna Białostocka",
                "countryCode": "PL"
            },
            {
                "name": "Czeremcha",
                "countryCode": "PL"
            },
            {
                "name": "Czyżew",
                "countryCode": "PL"
            },
            {
                "name": "Dobrzyniewo Duże",
                "countryCode": "PL"
            },
            {
                "name": "Drohiczyn",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa Białostocka",
                "countryCode": "PL"
            },
            {
                "name": "Filipów",
                "countryCode": "PL"
            },
            {
                "name": "Goniadz",
                "countryCode": "PL"
            },
            {
                "name": "Grajewo",
                "countryCode": "PL"
            },
            {
                "name": "Grudki",
                "countryCode": "PL"
            },
            {
                "name": "Hajnówka",
                "countryCode": "PL"
            },
            {
                "name": "Jedwabne",
                "countryCode": "PL"
            },
            {
                "name": "Kleszczele",
                "countryCode": "PL"
            },
            {
                "name": "Knyszyn",
                "countryCode": "PL"
            },
            {
                "name": "Kolno",
                "countryCode": "PL"
            },
            {
                "name": "Krasnopol",
                "countryCode": "PL"
            },
            {
                "name": "Krynki",
                "countryCode": "PL"
            },
            {
                "name": "Kuźnica",
                "countryCode": "PL"
            },
            {
                "name": "Lipsk",
                "countryCode": "PL"
            },
            {
                "name": "Mały Płock",
                "countryCode": "PL"
            },
            {
                "name": "Michałowo",
                "countryCode": "PL"
            },
            {
                "name": "Milejczyce",
                "countryCode": "PL"
            },
            {
                "name": "Mońki",
                "countryCode": "PL"
            },
            {
                "name": "Narew",
                "countryCode": "PL"
            },
            {
                "name": "Nowogród",
                "countryCode": "PL"
            },
            {
                "name": "Nurzec-Stacja",
                "countryCode": "PL"
            },
            {
                "name": "Orla",
                "countryCode": "PL"
            },
            {
                "name": "Piątnica",
                "countryCode": "PL"
            },
            {
                "name": "Powiat augustowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat białostocki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bielski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat grajewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat hajnowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kolneński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat moniecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sejneński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat siemiatycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sokólski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat suwalski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wysokomazowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zambrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łomżyński",
                "countryCode": "PL"
            },
            {
                "name": "Puńsk",
                "countryCode": "PL"
            },
            {
                "name": "Raczki",
                "countryCode": "PL"
            },
            {
                "name": "Radziłów",
                "countryCode": "PL"
            },
            {
                "name": "Rajgród",
                "countryCode": "PL"
            },
            {
                "name": "Rudka",
                "countryCode": "PL"
            },
            {
                "name": "Rutki-Kossaki",
                "countryCode": "PL"
            },
            {
                "name": "Sejny",
                "countryCode": "PL"
            },
            {
                "name": "Siemiatycze",
                "countryCode": "PL"
            },
            {
                "name": "Sokoły",
                "countryCode": "PL"
            },
            {
                "name": "Sokółka",
                "countryCode": "PL"
            },
            {
                "name": "Stawiski",
                "countryCode": "PL"
            },
            {
                "name": "Suchowola",
                "countryCode": "PL"
            },
            {
                "name": "Supraśl",
                "countryCode": "PL"
            },
            {
                "name": "Suraż",
                "countryCode": "PL"
            },
            {
                "name": "Suwałki",
                "countryCode": "PL"
            },
            {
                "name": "Szczuczyn",
                "countryCode": "PL"
            },
            {
                "name": "Szepietowo",
                "countryCode": "PL"
            },
            {
                "name": "Szumowo",
                "countryCode": "PL"
            },
            {
                "name": "Turośń Kościelna",
                "countryCode": "PL"
            },
            {
                "name": "Tykocin",
                "countryCode": "PL"
            },
            {
                "name": "Wasilków",
                "countryCode": "PL"
            },
            {
                "name": "Wizna",
                "countryCode": "PL"
            },
            {
                "name": "Wysokie Mazowieckie",
                "countryCode": "PL"
            },
            {
                "name": "Wyszki",
                "countryCode": "PL"
            },
            {
                "name": "Wąsosz",
                "countryCode": "PL"
            },
            {
                "name": "Zabłudów",
                "countryCode": "PL"
            },
            {
                "name": "Zambrów",
                "countryCode": "PL"
            },
            {
                "name": "Zbójna",
                "countryCode": "PL"
            },
            {
                "name": "Łapy",
                "countryCode": "PL"
            },
            {
                "name": "Łomża",
                "countryCode": "PL"
            },
            {
                "name": "Śniadowo",
                "countryCode": "PL"
            },
            {
                "name": "Adamówka",
                "countryCode": "PL"
            },
            {
                "name": "Albigowa",
                "countryCode": "PL"
            },
            {
                "name": "Babica",
                "countryCode": "PL"
            },
            {
                "name": "Baligród",
                "countryCode": "PL"
            },
            {
                "name": "Baranów Sandomierski",
                "countryCode": "PL"
            },
            {
                "name": "Besko",
                "countryCode": "PL"
            },
            {
                "name": "Białobrzegi",
                "countryCode": "PL"
            },
            {
                "name": "Bieliny",
                "countryCode": "PL"
            },
            {
                "name": "Bircza",
                "countryCode": "PL"
            },
            {
                "name": "Blizne",
                "countryCode": "PL"
            },
            {
                "name": "Boguchwała",
                "countryCode": "PL"
            },
            {
                "name": "Bojanów",
                "countryCode": "PL"
            },
            {
                "name": "Borowa",
                "countryCode": "PL"
            },
            {
                "name": "Brzeźnica",
                "countryCode": "PL"
            },
            {
                "name": "Brzostek",
                "countryCode": "PL"
            },
            {
                "name": "Brzozów",
                "countryCode": "PL"
            },
            {
                "name": "Brzyska",
                "countryCode": "PL"
            },
            {
                "name": "Brzóza Królewska",
                "countryCode": "PL"
            },
            {
                "name": "Brzóza Stadnicka",
                "countryCode": "PL"
            },
            {
                "name": "Bukowsko",
                "countryCode": "PL"
            },
            {
                "name": "Błażowa",
                "countryCode": "PL"
            },
            {
                "name": "Chmielnik",
                "countryCode": "PL"
            },
            {
                "name": "Chorkówka",
                "countryCode": "PL"
            },
            {
                "name": "Chwałowice",
                "countryCode": "PL"
            },
            {
                "name": "Cieszanów",
                "countryCode": "PL"
            },
            {
                "name": "Cisna",
                "countryCode": "PL"
            },
            {
                "name": "Cmolas",
                "countryCode": "PL"
            },
            {
                "name": "Czarna",
                "countryCode": "PL"
            },
            {
                "name": "Czaszyn",
                "countryCode": "PL"
            },
            {
                "name": "Czermin",
                "countryCode": "PL"
            },
            {
                "name": "Czudec",
                "countryCode": "PL"
            },
            {
                "name": "Domaradz",
                "countryCode": "PL"
            },
            {
                "name": "Dubiecko",
                "countryCode": "PL"
            },
            {
                "name": "Dukla",
                "countryCode": "PL"
            },
            {
                "name": "Dydnia",
                "countryCode": "PL"
            },
            {
                "name": "Dynów",
                "countryCode": "PL"
            },
            {
                "name": "Dzikowiec",
                "countryCode": "PL"
            },
            {
                "name": "Dzików Stary",
                "countryCode": "PL"
            },
            {
                "name": "Dębica",
                "countryCode": "PL"
            },
            {
                "name": "Dębno",
                "countryCode": "PL"
            },
            {
                "name": "Dębowiec",
                "countryCode": "PL"
            },
            {
                "name": "Dębów",
                "countryCode": "PL"
            },
            {
                "name": "Długie",
                "countryCode": "PL"
            },
            {
                "name": "Fredropol",
                "countryCode": "PL"
            },
            {
                "name": "Frysztak",
                "countryCode": "PL"
            },
            {
                "name": "Gać",
                "countryCode": "PL"
            },
            {
                "name": "Giedlarowa",
                "countryCode": "PL"
            },
            {
                "name": "Golcowa",
                "countryCode": "PL"
            },
            {
                "name": "Gorliczyna",
                "countryCode": "PL"
            },
            {
                "name": "Gorzyce",
                "countryCode": "PL"
            },
            {
                "name": "Grabownica Starzeńska",
                "countryCode": "PL"
            },
            {
                "name": "Grodzisko Dolne",
                "countryCode": "PL"
            },
            {
                "name": "Grodzisko Górne",
                "countryCode": "PL"
            },
            {
                "name": "Grzęska",
                "countryCode": "PL"
            },
            {
                "name": "Grębów",
                "countryCode": "PL"
            },
            {
                "name": "Gwoźnica Górna",
                "countryCode": "PL"
            },
            {
                "name": "Górki",
                "countryCode": "PL"
            },
            {
                "name": "Górno",
                "countryCode": "PL"
            },
            {
                "name": "Głogów Małopolski",
                "countryCode": "PL"
            },
            {
                "name": "Głuchów",
                "countryCode": "PL"
            },
            {
                "name": "Haczów",
                "countryCode": "PL"
            },
            {
                "name": "Handzlówka",
                "countryCode": "PL"
            },
            {
                "name": "Harasiuki",
                "countryCode": "PL"
            },
            {
                "name": "Horyniec-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Humniska",
                "countryCode": "PL"
            },
            {
                "name": "Husów",
                "countryCode": "PL"
            },
            {
                "name": "Hyżne",
                "countryCode": "PL"
            },
            {
                "name": "Iwierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Iwonicz-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonica Polska",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonka",
                "countryCode": "PL"
            },
            {
                "name": "Jagiełła",
                "countryCode": "PL"
            },
            {
                "name": "Jarocin",
                "countryCode": "PL"
            },
            {
                "name": "Jarosław",
                "countryCode": "PL"
            },
            {
                "name": "Jasienica Rosielna",
                "countryCode": "PL"
            },
            {
                "name": "Jasionów",
                "countryCode": "PL"
            },
            {
                "name": "Jasło",
                "countryCode": "PL"
            },
            {
                "name": "Jawornik",
                "countryCode": "PL"
            },
            {
                "name": "Jawornik Polski",
                "countryCode": "PL"
            },
            {
                "name": "Jaśliska",
                "countryCode": "PL"
            },
            {
                "name": "Jedlicze",
                "countryCode": "PL"
            },
            {
                "name": "Jeżowe",
                "countryCode": "PL"
            },
            {
                "name": "Jodłowa",
                "countryCode": "PL"
            },
            {
                "name": "Jodłówka",
                "countryCode": "PL"
            },
            {
                "name": "Kamień",
                "countryCode": "PL"
            },
            {
                "name": "Kańczuga",
                "countryCode": "PL"
            },
            {
                "name": "Kielanówka",
                "countryCode": "PL"
            },
            {
                "name": "Kolbuszowa",
                "countryCode": "PL"
            },
            {
                "name": "Komańcza",
                "countryCode": "PL"
            },
            {
                "name": "Konieczkowa",
                "countryCode": "PL"
            },
            {
                "name": "Korczyna",
                "countryCode": "PL"
            },
            {
                "name": "Kosina",
                "countryCode": "PL"
            },
            {
                "name": "Kołaczyce",
                "countryCode": "PL"
            },
            {
                "name": "Kończyce",
                "countryCode": "PL"
            },
            {
                "name": "Kraczkowa",
                "countryCode": "PL"
            },
            {
                "name": "Kramarzówka",
                "countryCode": "PL"
            },
            {
                "name": "Krasiczyn",
                "countryCode": "PL"
            },
            {
                "name": "Krasne",
                "countryCode": "PL"
            },
            {
                "name": "Krempna",
                "countryCode": "PL"
            },
            {
                "name": "Krosno",
                "countryCode": "PL"
            },
            {
                "name": "Krościenko Wyżne",
                "countryCode": "PL"
            },
            {
                "name": "Krzeczowice",
                "countryCode": "PL"
            },
            {
                "name": "Krzemienica",
                "countryCode": "PL"
            },
            {
                "name": "Krzeszów",
                "countryCode": "PL"
            },
            {
                "name": "Krzywcza",
                "countryCode": "PL"
            },
            {
                "name": "Kuryłówka",
                "countryCode": "PL"
            },
            {
                "name": "Kępie Żaleszańskie",
                "countryCode": "PL"
            },
            {
                "name": "Laszki",
                "countryCode": "PL"
            },
            {
                "name": "Lecka",
                "countryCode": "PL"
            },
            {
                "name": "Lesko",
                "countryCode": "PL"
            },
            {
                "name": "Leżajsk",
                "countryCode": "PL"
            },
            {
                "name": "Lipnica",
                "countryCode": "PL"
            },
            {
                "name": "Lubaczów",
                "countryCode": "PL"
            },
            {
                "name": "Lubenia",
                "countryCode": "PL"
            },
            {
                "name": "Lutoryż",
                "countryCode": "PL"
            },
            {
                "name": "Lutowiska",
                "countryCode": "PL"
            },
            {
                "name": "Majdan Królewski",
                "countryCode": "PL"
            },
            {
                "name": "Manasterz",
                "countryCode": "PL"
            },
            {
                "name": "Markowa",
                "countryCode": "PL"
            },
            {
                "name": "Medyka",
                "countryCode": "PL"
            },
            {
                "name": "Miejsce Piastowe",
                "countryCode": "PL"
            },
            {
                "name": "Mielec",
                "countryCode": "PL"
            },
            {
                "name": "Mirocin",
                "countryCode": "PL"
            },
            {
                "name": "Narol",
                "countryCode": "PL"
            },
            {
                "name": "Niebieszczany",
                "countryCode": "PL"
            },
            {
                "name": "Niebocko",
                "countryCode": "PL"
            },
            {
                "name": "Niebylec",
                "countryCode": "PL"
            },
            {
                "name": "Niechobrz",
                "countryCode": "PL"
            },
            {
                "name": "Niedźwiada",
                "countryCode": "PL"
            },
            {
                "name": "Nienadowa",
                "countryCode": "PL"
            },
            {
                "name": "Nisko",
                "countryCode": "PL"
            },
            {
                "name": "Niwiska",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Dęba",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Sarzyna",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Sioło",
                "countryCode": "PL"
            },
            {
                "name": "Nowosielce",
                "countryCode": "PL"
            },
            {
                "name": "Nowosielce-Gniewosz",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Żmigród",
                "countryCode": "PL"
            },
            {
                "name": "Nozdrzec",
                "countryCode": "PL"
            },
            {
                "name": "Odrzykoń",
                "countryCode": "PL"
            },
            {
                "name": "Oleszyce",
                "countryCode": "PL"
            },
            {
                "name": "Olszanica",
                "countryCode": "PL"
            },
            {
                "name": "Orzechówka",
                "countryCode": "PL"
            },
            {
                "name": "Orły",
                "countryCode": "PL"
            },
            {
                "name": "Osiek Jasielski",
                "countryCode": "PL"
            },
            {
                "name": "Ostrów",
                "countryCode": "PL"
            },
            {
                "name": "Padew Narodowa",
                "countryCode": "PL"
            },
            {
                "name": "Pantalowice",
                "countryCode": "PL"
            },
            {
                "name": "Pawłosiów",
                "countryCode": "PL"
            },
            {
                "name": "Pilzno",
                "countryCode": "PL"
            },
            {
                "name": "Piskorowice",
                "countryCode": "PL"
            },
            {
                "name": "Polańczyk",
                "countryCode": "PL"
            },
            {
                "name": "Poraż",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bieszczadzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat brzozowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat dębicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jarosławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jasielski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kolbuszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krośnieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat leski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat leżajski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubaczowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat mielecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat niżański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat przemyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat przeworski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ropczycko-sędziszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rzeszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sanocki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat stalowowolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat strzyżowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tarnobrzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łańcucki",
                "countryCode": "PL"
            },
            {
                "name": "Połomia",
                "countryCode": "PL"
            },
            {
                "name": "Pruchnik",
                "countryCode": "PL"
            },
            {
                "name": "Przecław",
                "countryCode": "PL"
            },
            {
                "name": "Przedmieście Dubieckie",
                "countryCode": "PL"
            },
            {
                "name": "Przemyśl",
                "countryCode": "PL"
            },
            {
                "name": "Przeworsk",
                "countryCode": "PL"
            },
            {
                "name": "Przybyszówka",
                "countryCode": "PL"
            },
            {
                "name": "Przędzel",
                "countryCode": "PL"
            },
            {
                "name": "Pysznica",
                "countryCode": "PL"
            },
            {
                "name": "Racławice",
                "countryCode": "PL"
            },
            {
                "name": "Radomyśl",
                "countryCode": "PL"
            },
            {
                "name": "Radomyśl Wielki",
                "countryCode": "PL"
            },
            {
                "name": "Radymno",
                "countryCode": "PL"
            },
            {
                "name": "Rakszawa",
                "countryCode": "PL"
            },
            {
                "name": "Raniżów",
                "countryCode": "PL"
            },
            {
                "name": "Rogóźno",
                "countryCode": "PL"
            },
            {
                "name": "Rokietnica",
                "countryCode": "PL"
            },
            {
                "name": "Ropczyce",
                "countryCode": "PL"
            },
            {
                "name": "Rozbórz",
                "countryCode": "PL"
            },
            {
                "name": "Rudna Mała",
                "countryCode": "PL"
            },
            {
                "name": "Rudna Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Rudnik nad Sanem",
                "countryCode": "PL"
            },
            {
                "name": "Rymanów",
                "countryCode": "PL"
            },
            {
                "name": "Rzepedź",
                "countryCode": "PL"
            },
            {
                "name": "Rzeszów",
                "countryCode": "PL"
            },
            {
                "name": "Sanok",
                "countryCode": "PL"
            },
            {
                "name": "Siedleczka",
                "countryCode": "PL"
            },
            {
                "name": "Siedliska",
                "countryCode": "PL"
            },
            {
                "name": "Sieniawa",
                "countryCode": "PL"
            },
            {
                "name": "Sietesz",
                "countryCode": "PL"
            },
            {
                "name": "Skołyszyn",
                "countryCode": "PL"
            },
            {
                "name": "Sokolniki",
                "countryCode": "PL"
            },
            {
                "name": "Sokołów Małopolski",
                "countryCode": "PL"
            },
            {
                "name": "Sonina",
                "countryCode": "PL"
            },
            {
                "name": "Sośnica",
                "countryCode": "PL"
            },
            {
                "name": "Stalowa Wola",
                "countryCode": "PL"
            },
            {
                "name": "Stara Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Stare Miasto",
                "countryCode": "PL"
            },
            {
                "name": "Strachocina",
                "countryCode": "PL"
            },
            {
                "name": "Straszydle",
                "countryCode": "PL"
            },
            {
                "name": "Strzyżów",
                "countryCode": "PL"
            },
            {
                "name": "Stubno",
                "countryCode": "PL"
            },
            {
                "name": "Sędziszów Małopolski",
                "countryCode": "PL"
            },
            {
                "name": "Słotowa",
                "countryCode": "PL"
            },
            {
                "name": "Tarnobrzeg",
                "countryCode": "PL"
            },
            {
                "name": "Tarnowiec",
                "countryCode": "PL"
            },
            {
                "name": "Tryńcza",
                "countryCode": "PL"
            },
            {
                "name": "Trzciana",
                "countryCode": "PL"
            },
            {
                "name": "Trzcinica",
                "countryCode": "PL"
            },
            {
                "name": "Trzebownisko",
                "countryCode": "PL"
            },
            {
                "name": "Turze Pole",
                "countryCode": "PL"
            },
            {
                "name": "Tyczyn",
                "countryCode": "PL"
            },
            {
                "name": "Tyrawa Wołoska",
                "countryCode": "PL"
            },
            {
                "name": "Uherce Mineralne",
                "countryCode": "PL"
            },
            {
                "name": "Ulanów",
                "countryCode": "PL"
            },
            {
                "name": "Urzejowice",
                "countryCode": "PL"
            },
            {
                "name": "Ustrzyki Dolne",
                "countryCode": "PL"
            },
            {
                "name": "Wadowice Górne",
                "countryCode": "PL"
            },
            {
                "name": "Wesoła",
                "countryCode": "PL"
            },
            {
                "name": "Wielkie Oczy",
                "countryCode": "PL"
            },
            {
                "name": "Wielopole Skrzyńskie",
                "countryCode": "PL"
            },
            {
                "name": "Wierzawice",
                "countryCode": "PL"
            },
            {
                "name": "Wierzbna",
                "countryCode": "PL"
            },
            {
                "name": "Wiązownica",
                "countryCode": "PL"
            },
            {
                "name": "Wiśniowa",
                "countryCode": "PL"
            },
            {
                "name": "Wojaszówka",
                "countryCode": "PL"
            },
            {
                "name": "Wola Żarczycka",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka Głogowska",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka Strzyżowska",
                "countryCode": "PL"
            },
            {
                "name": "Wólka Niedźwiedzka",
                "countryCode": "PL"
            },
            {
                "name": "Wólka Pełkińska",
                "countryCode": "PL"
            },
            {
                "name": "Wólka Podleśna",
                "countryCode": "PL"
            },
            {
                "name": "Wólka Tanewska",
                "countryCode": "PL"
            },
            {
                "name": "Zagórz",
                "countryCode": "PL"
            },
            {
                "name": "Zagórzyce",
                "countryCode": "PL"
            },
            {
                "name": "Zaklików",
                "countryCode": "PL"
            },
            {
                "name": "Zalesie",
                "countryCode": "PL"
            },
            {
                "name": "Zaleszany",
                "countryCode": "PL"
            },
            {
                "name": "Zarszyn",
                "countryCode": "PL"
            },
            {
                "name": "Zarzecze",
                "countryCode": "PL"
            },
            {
                "name": "Zdziechowice Drugie",
                "countryCode": "PL"
            },
            {
                "name": "Zgłobień",
                "countryCode": "PL"
            },
            {
                "name": "Zmiennica",
                "countryCode": "PL"
            },
            {
                "name": "Łańcut",
                "countryCode": "PL"
            },
            {
                "name": "Łopuszka Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Łęki",
                "countryCode": "PL"
            },
            {
                "name": "Łęki Dolne",
                "countryCode": "PL"
            },
            {
                "name": "Łęki Górne",
                "countryCode": "PL"
            },
            {
                "name": "Łętownia",
                "countryCode": "PL"
            },
            {
                "name": "Świlcza",
                "countryCode": "PL"
            },
            {
                "name": "Żołynia",
                "countryCode": "PL"
            },
            {
                "name": "Żurawica",
                "countryCode": "PL"
            },
            {
                "name": "Żurawiczki",
                "countryCode": "PL"
            },
            {
                "name": "Żyraków",
                "countryCode": "PL"
            },
            {
                "name": "Banino",
                "countryCode": "PL"
            },
            {
                "name": "Bobowo",
                "countryCode": "PL"
            },
            {
                "name": "Bojano",
                "countryCode": "PL"
            },
            {
                "name": "Bolszewo",
                "countryCode": "PL"
            },
            {
                "name": "Borzytuchom",
                "countryCode": "PL"
            },
            {
                "name": "Brusy",
                "countryCode": "PL"
            },
            {
                "name": "Bytów",
                "countryCode": "PL"
            },
            {
                "name": "Cedry Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Cewice",
                "countryCode": "PL"
            },
            {
                "name": "Chmielno",
                "countryCode": "PL"
            },
            {
                "name": "Choczewo",
                "countryCode": "PL"
            },
            {
                "name": "Chojnice",
                "countryCode": "PL"
            },
            {
                "name": "Chwaszczyno",
                "countryCode": "PL"
            },
            {
                "name": "Chłapowo",
                "countryCode": "PL"
            },
            {
                "name": "Czarna Dąbrówka",
                "countryCode": "PL"
            },
            {
                "name": "Czarna Woda",
                "countryCode": "PL"
            },
            {
                "name": "Czarne",
                "countryCode": "PL"
            },
            {
                "name": "Czersk",
                "countryCode": "PL"
            },
            {
                "name": "Człuchów",
                "countryCode": "PL"
            },
            {
                "name": "Damnica",
                "countryCode": "PL"
            },
            {
                "name": "Debrzno",
                "countryCode": "PL"
            },
            {
                "name": "Dziemiany",
                "countryCode": "PL"
            },
            {
                "name": "Dzierzgoń",
                "countryCode": "PL"
            },
            {
                "name": "Dębnica Kaszubska",
                "countryCode": "PL"
            },
            {
                "name": "Garcz",
                "countryCode": "PL"
            },
            {
                "name": "Gardeja",
                "countryCode": "PL"
            },
            {
                "name": "Gdańsk",
                "countryCode": "PL"
            },
            {
                "name": "Gdynia",
                "countryCode": "PL"
            },
            {
                "name": "Gniew",
                "countryCode": "PL"
            },
            {
                "name": "Gniewino",
                "countryCode": "PL"
            },
            {
                "name": "Gnieżdżewo",
                "countryCode": "PL"
            },
            {
                "name": "Gościcino",
                "countryCode": "PL"
            },
            {
                "name": "Grabowo Kościerskie",
                "countryCode": "PL"
            },
            {
                "name": "Główczyce",
                "countryCode": "PL"
            },
            {
                "name": "Hel",
                "countryCode": "PL"
            },
            {
                "name": "Jastarnia",
                "countryCode": "PL"
            },
            {
                "name": "Jastrzębia Góra",
                "countryCode": "PL"
            },
            {
                "name": "Kaliska",
                "countryCode": "PL"
            },
            {
                "name": "Karsin",
                "countryCode": "PL"
            },
            {
                "name": "Kartuzy",
                "countryCode": "PL"
            },
            {
                "name": "Kobylnica",
                "countryCode": "PL"
            },
            {
                "name": "Koczała",
                "countryCode": "PL"
            },
            {
                "name": "Kolbudy",
                "countryCode": "PL"
            },
            {
                "name": "Koleczkowo",
                "countryCode": "PL"
            },
            {
                "name": "Kosakowo",
                "countryCode": "PL"
            },
            {
                "name": "Kowale",
                "countryCode": "PL"
            },
            {
                "name": "Kołczygłowy",
                "countryCode": "PL"
            },
            {
                "name": "Kościerzyna",
                "countryCode": "PL"
            },
            {
                "name": "Krokowa",
                "countryCode": "PL"
            },
            {
                "name": "Krynica Morska",
                "countryCode": "PL"
            },
            {
                "name": "Kwidzyn",
                "countryCode": "PL"
            },
            {
                "name": "Kępice",
                "countryCode": "PL"
            },
            {
                "name": "Linia",
                "countryCode": "PL"
            },
            {
                "name": "Liniewo",
                "countryCode": "PL"
            },
            {
                "name": "Lipnica",
                "countryCode": "PL"
            },
            {
                "name": "Lipusz",
                "countryCode": "PL"
            },
            {
                "name": "Lisewo Malborskie",
                "countryCode": "PL"
            },
            {
                "name": "Lubichowo",
                "countryCode": "PL"
            },
            {
                "name": "Lublewo Gdańskie",
                "countryCode": "PL"
            },
            {
                "name": "Luzino",
                "countryCode": "PL"
            },
            {
                "name": "Lębork",
                "countryCode": "PL"
            },
            {
                "name": "Malbork",
                "countryCode": "PL"
            },
            {
                "name": "Miastko",
                "countryCode": "PL"
            },
            {
                "name": "Mikołajki Pomorskie",
                "countryCode": "PL"
            },
            {
                "name": "Miłoradz",
                "countryCode": "PL"
            },
            {
                "name": "Mosty",
                "countryCode": "PL"
            },
            {
                "name": "Mrzezino",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Wieś Lęborska",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Dwór Gdański",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Staw",
                "countryCode": "PL"
            },
            {
                "name": "Orle",
                "countryCode": "PL"
            },
            {
                "name": "Osiek",
                "countryCode": "PL"
            },
            {
                "name": "Ostaszewo",
                "countryCode": "PL"
            },
            {
                "name": "Parchowo",
                "countryCode": "PL"
            },
            {
                "name": "Pelplin",
                "countryCode": "PL"
            },
            {
                "name": "Pogórze",
                "countryCode": "PL"
            },
            {
                "name": "Potęgowo",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bytowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat chojnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat człuchowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gdański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kartuski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kościerski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kwidzyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lęborski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat malborski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowodworski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pucki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat starogardzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sztumski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat słupski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tczewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wejherowski",
                "countryCode": "PL"
            },
            {
                "name": "Prabuty",
                "countryCode": "PL"
            },
            {
                "name": "Pruszcz Gdański",
                "countryCode": "PL"
            },
            {
                "name": "Przechlewo",
                "countryCode": "PL"
            },
            {
                "name": "Przodkowo",
                "countryCode": "PL"
            },
            {
                "name": "Przywidz",
                "countryCode": "PL"
            },
            {
                "name": "Pszczółki",
                "countryCode": "PL"
            },
            {
                "name": "Puck",
                "countryCode": "PL"
            },
            {
                "name": "Reda",
                "countryCode": "PL"
            },
            {
                "name": "Rekowo Dolne",
                "countryCode": "PL"
            },
            {
                "name": "Rotmanka",
                "countryCode": "PL"
            },
            {
                "name": "Rumia",
                "countryCode": "PL"
            },
            {
                "name": "Ryjewo",
                "countryCode": "PL"
            },
            {
                "name": "Rzeczenica",
                "countryCode": "PL"
            },
            {
                "name": "Sadlinki",
                "countryCode": "PL"
            },
            {
                "name": "Sierakowice",
                "countryCode": "PL"
            },
            {
                "name": "Skarszewy",
                "countryCode": "PL"
            },
            {
                "name": "Skórcz",
                "countryCode": "PL"
            },
            {
                "name": "Smołdzino",
                "countryCode": "PL"
            },
            {
                "name": "Smętowo Graniczne",
                "countryCode": "PL"
            },
            {
                "name": "Somonino",
                "countryCode": "PL"
            },
            {
                "name": "Sopot",
                "countryCode": "PL"
            },
            {
                "name": "Stara Kiszewa",
                "countryCode": "PL"
            },
            {
                "name": "Stare Pole",
                "countryCode": "PL"
            },
            {
                "name": "Starogard Gdański",
                "countryCode": "PL"
            },
            {
                "name": "Stary Targ",
                "countryCode": "PL"
            },
            {
                "name": "Stegna",
                "countryCode": "PL"
            },
            {
                "name": "Straszyn",
                "countryCode": "PL"
            },
            {
                "name": "Strzelno",
                "countryCode": "PL"
            },
            {
                "name": "Studzienice",
                "countryCode": "PL"
            },
            {
                "name": "Stężyca",
                "countryCode": "PL"
            },
            {
                "name": "Subkowy",
                "countryCode": "PL"
            },
            {
                "name": "Suchy Dąb",
                "countryCode": "PL"
            },
            {
                "name": "Sulęczyno",
                "countryCode": "PL"
            },
            {
                "name": "Szemud",
                "countryCode": "PL"
            },
            {
                "name": "Szlachta",
                "countryCode": "PL"
            },
            {
                "name": "Sztum",
                "countryCode": "PL"
            },
            {
                "name": "Sztutowo",
                "countryCode": "PL"
            },
            {
                "name": "Słupsk",
                "countryCode": "PL"
            },
            {
                "name": "Tczew",
                "countryCode": "PL"
            },
            {
                "name": "Trzebielino",
                "countryCode": "PL"
            },
            {
                "name": "Trąbki Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Tuchomie",
                "countryCode": "PL"
            },
            {
                "name": "Ustka",
                "countryCode": "PL"
            },
            {
                "name": "Wejherowo",
                "countryCode": "PL"
            },
            {
                "name": "Wielki Kack",
                "countryCode": "PL"
            },
            {
                "name": "Wierzchucino",
                "countryCode": "PL"
            },
            {
                "name": "Władysławowo",
                "countryCode": "PL"
            },
            {
                "name": "Zblewo",
                "countryCode": "PL"
            },
            {
                "name": "Łeba",
                "countryCode": "PL"
            },
            {
                "name": "Łęczyce",
                "countryCode": "PL"
            },
            {
                "name": "Łęgowo",
                "countryCode": "PL"
            },
            {
                "name": "Żelistrzewo",
                "countryCode": "PL"
            },
            {
                "name": "Żukowo",
                "countryCode": "PL"
            },
            {
                "name": "Baćkowice",
                "countryCode": "PL"
            },
            {
                "name": "Bałtów",
                "countryCode": "PL"
            },
            {
                "name": "Bejsce",
                "countryCode": "PL"
            },
            {
                "name": "Bieliny",
                "countryCode": "PL"
            },
            {
                "name": "Bliżyn",
                "countryCode": "PL"
            },
            {
                "name": "Bodzentyn",
                "countryCode": "PL"
            },
            {
                "name": "Bogoria",
                "countryCode": "PL"
            },
            {
                "name": "Brody",
                "countryCode": "PL"
            },
            {
                "name": "Brzeziny",
                "countryCode": "PL"
            },
            {
                "name": "Busko-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Chmielnik",
                "countryCode": "PL"
            },
            {
                "name": "Chęciny",
                "countryCode": "PL"
            },
            {
                "name": "Czarnocin",
                "countryCode": "PL"
            },
            {
                "name": "Daleszyce",
                "countryCode": "PL"
            },
            {
                "name": "Domaszowice",
                "countryCode": "PL"
            },
            {
                "name": "Dwikozy",
                "countryCode": "PL"
            },
            {
                "name": "Działoszyce",
                "countryCode": "PL"
            },
            {
                "name": "Fałków",
                "countryCode": "PL"
            },
            {
                "name": "Gnojno",
                "countryCode": "PL"
            },
            {
                "name": "Gowarczów",
                "countryCode": "PL"
            },
            {
                "name": "Grzybowa Góra",
                "countryCode": "PL"
            },
            {
                "name": "Górno",
                "countryCode": "PL"
            },
            {
                "name": "Imielno",
                "countryCode": "PL"
            },
            {
                "name": "Iwaniska",
                "countryCode": "PL"
            },
            {
                "name": "Jędrzejów",
                "countryCode": "PL"
            },
            {
                "name": "Kaniów",
                "countryCode": "PL"
            },
            {
                "name": "Kazimierza Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Kielce",
                "countryCode": "PL"
            },
            {
                "name": "Kije",
                "countryCode": "PL"
            },
            {
                "name": "Klimontów",
                "countryCode": "PL"
            },
            {
                "name": "Koprzywnica",
                "countryCode": "PL"
            },
            {
                "name": "Kostomłoty Drugie",
                "countryCode": "PL"
            },
            {
                "name": "Kostomłoty Pierwsze",
                "countryCode": "PL"
            },
            {
                "name": "Końskie",
                "countryCode": "PL"
            },
            {
                "name": "Krasocin",
                "countryCode": "PL"
            },
            {
                "name": "Kunów",
                "countryCode": "PL"
            },
            {
                "name": "Lipnik",
                "countryCode": "PL"
            },
            {
                "name": "Masłów",
                "countryCode": "PL"
            },
            {
                "name": "Małogoszcz",
                "countryCode": "PL"
            },
            {
                "name": "Michałów",
                "countryCode": "PL"
            },
            {
                "name": "Miedziana Góra",
                "countryCode": "PL"
            },
            {
                "name": "Mirzec",
                "countryCode": "PL"
            },
            {
                "name": "Mniów",
                "countryCode": "PL"
            },
            {
                "name": "Morawica",
                "countryCode": "PL"
            },
            {
                "name": "Nagłowice",
                "countryCode": "PL"
            },
            {
                "name": "Nowa Słupia",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Korczyn",
                "countryCode": "PL"
            },
            {
                "name": "Obrazów",
                "countryCode": "PL"
            },
            {
                "name": "Oksa",
                "countryCode": "PL"
            },
            {
                "name": "Oleśnica",
                "countryCode": "PL"
            },
            {
                "name": "Opatowiec",
                "countryCode": "PL"
            },
            {
                "name": "Opatów",
                "countryCode": "PL"
            },
            {
                "name": "Osiedle-Nowiny",
                "countryCode": "PL"
            },
            {
                "name": "Osiek",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowiec Świętokrzyski",
                "countryCode": "PL"
            },
            {
                "name": "Ożarów",
                "countryCode": "PL"
            },
            {
                "name": "Pacanów",
                "countryCode": "PL"
            },
            {
                "name": "Pawłów",
                "countryCode": "PL"
            },
            {
                "name": "Piekoszów",
                "countryCode": "PL"
            },
            {
                "name": "Pierzchnica",
                "countryCode": "PL"
            },
            {
                "name": "Pińczów",
                "countryCode": "PL"
            },
            {
                "name": "Powiat buski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jędrzejowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kazimierski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kielecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat konecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat opatowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostrowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pińczowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sandomierski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat skarżyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat starachowicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat staszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat włoszczowski",
                "countryCode": "PL"
            },
            {
                "name": "Połaniec",
                "countryCode": "PL"
            },
            {
                "name": "Radoszyce",
                "countryCode": "PL"
            },
            {
                "name": "Raków",
                "countryCode": "PL"
            },
            {
                "name": "Rogów",
                "countryCode": "PL"
            },
            {
                "name": "Ruda Maleniecka",
                "countryCode": "PL"
            },
            {
                "name": "Rytwiany",
                "countryCode": "PL"
            },
            {
                "name": "Sadowie",
                "countryCode": "PL"
            },
            {
                "name": "Samborzec",
                "countryCode": "PL"
            },
            {
                "name": "Sandomierz",
                "countryCode": "PL"
            },
            {
                "name": "Secemin",
                "countryCode": "PL"
            },
            {
                "name": "Skalbmierz",
                "countryCode": "PL"
            },
            {
                "name": "Skarżysko Kościelne",
                "countryCode": "PL"
            },
            {
                "name": "Skarżysko-Kamienna",
                "countryCode": "PL"
            },
            {
                "name": "Smyków",
                "countryCode": "PL"
            },
            {
                "name": "Sobków",
                "countryCode": "PL"
            },
            {
                "name": "Solec-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Starachowice",
                "countryCode": "PL"
            },
            {
                "name": "Staszów",
                "countryCode": "PL"
            },
            {
                "name": "Stopnica",
                "countryCode": "PL"
            },
            {
                "name": "Strawczyn",
                "countryCode": "PL"
            },
            {
                "name": "Stąporków",
                "countryCode": "PL"
            },
            {
                "name": "Suchedniów",
                "countryCode": "PL"
            },
            {
                "name": "Szydłów",
                "countryCode": "PL"
            },
            {
                "name": "Sędziszów",
                "countryCode": "PL"
            },
            {
                "name": "Słupia",
                "countryCode": "PL"
            },
            {
                "name": "Tarłów",
                "countryCode": "PL"
            },
            {
                "name": "Tuczępy",
                "countryCode": "PL"
            },
            {
                "name": "Waśniów",
                "countryCode": "PL"
            },
            {
                "name": "Wielka Wieś",
                "countryCode": "PL"
            },
            {
                "name": "Wilczyce",
                "countryCode": "PL"
            },
            {
                "name": "Wiślica",
                "countryCode": "PL"
            },
            {
                "name": "Wodzisław",
                "countryCode": "PL"
            },
            {
                "name": "Wojciechowice",
                "countryCode": "PL"
            },
            {
                "name": "Wola Jachowa",
                "countryCode": "PL"
            },
            {
                "name": "Wąchock",
                "countryCode": "PL"
            },
            {
                "name": "Włoszczowa",
                "countryCode": "PL"
            },
            {
                "name": "Zagnańsk",
                "countryCode": "PL"
            },
            {
                "name": "Zawichost",
                "countryCode": "PL"
            },
            {
                "name": "Złota",
                "countryCode": "PL"
            },
            {
                "name": "Ćmielów",
                "countryCode": "PL"
            },
            {
                "name": "Łagów",
                "countryCode": "PL"
            },
            {
                "name": "Łoniów",
                "countryCode": "PL"
            },
            {
                "name": "Łopuszno",
                "countryCode": "PL"
            },
            {
                "name": "Łubnice",
                "countryCode": "PL"
            },
            {
                "name": "Łączna",
                "countryCode": "PL"
            },
            {
                "name": "Bażanowice",
                "countryCode": "PL"
            },
            {
                "name": "Bestwina",
                "countryCode": "PL"
            },
            {
                "name": "Bestwinka",
                "countryCode": "PL"
            },
            {
                "name": "Bełk",
                "countryCode": "PL"
            },
            {
                "name": "Bełsznica",
                "countryCode": "PL"
            },
            {
                "name": "Bielsko-Biala",
                "countryCode": "PL"
            },
            {
                "name": "Bielsko-Biała",
                "countryCode": "PL"
            },
            {
                "name": "Bieruń",
                "countryCode": "PL"
            },
            {
                "name": "Blachownia",
                "countryCode": "PL"
            },
            {
                "name": "Bobrowniki",
                "countryCode": "PL"
            },
            {
                "name": "Bojszowy",
                "countryCode": "PL"
            },
            {
                "name": "Bojszowy Nowe",
                "countryCode": "PL"
            },
            {
                "name": "Boronów",
                "countryCode": "PL"
            },
            {
                "name": "Borowno",
                "countryCode": "PL"
            },
            {
                "name": "Borucin",
                "countryCode": "PL"
            },
            {
                "name": "Brenna",
                "countryCode": "PL"
            },
            {
                "name": "Bronów",
                "countryCode": "PL"
            },
            {
                "name": "Brzeziny Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Buczkowice",
                "countryCode": "PL"
            },
            {
                "name": "Bujaków",
                "countryCode": "PL"
            },
            {
                "name": "Bystra",
                "countryCode": "PL"
            },
            {
                "name": "Bytom",
                "countryCode": "PL"
            },
            {
                "name": "Bąków",
                "countryCode": "PL"
            },
            {
                "name": "Będzin",
                "countryCode": "PL"
            },
            {
                "name": "Chałupki",
                "countryCode": "PL"
            },
            {
                "name": "Chełm Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Choroń",
                "countryCode": "PL"
            },
            {
                "name": "Chorzów",
                "countryCode": "PL"
            },
            {
                "name": "Chruszczobród",
                "countryCode": "PL"
            },
            {
                "name": "Chybie",
                "countryCode": "PL"
            },
            {
                "name": "Ciasna",
                "countryCode": "PL"
            },
            {
                "name": "Cielmice",
                "countryCode": "PL"
            },
            {
                "name": "Cieszyn",
                "countryCode": "PL"
            },
            {
                "name": "Cisiec",
                "countryCode": "PL"
            },
            {
                "name": "Cisownica",
                "countryCode": "PL"
            },
            {
                "name": "Cięcina",
                "countryCode": "PL"
            },
            {
                "name": "Cynków",
                "countryCode": "PL"
            },
            {
                "name": "Czaniec",
                "countryCode": "PL"
            },
            {
                "name": "Czarków",
                "countryCode": "PL"
            },
            {
                "name": "Czechowice-Dziedzice",
                "countryCode": "PL"
            },
            {
                "name": "Czeladź",
                "countryCode": "PL"
            },
            {
                "name": "Czernica",
                "countryCode": "PL"
            },
            {
                "name": "Czernichów",
                "countryCode": "PL"
            },
            {
                "name": "Czerwionka-Leszczyny",
                "countryCode": "PL"
            },
            {
                "name": "Czyżowice",
                "countryCode": "PL"
            },
            {
                "name": "Częstochowa",
                "countryCode": "PL"
            },
            {
                "name": "Dobieszowice",
                "countryCode": "PL"
            },
            {
                "name": "Drogomyśl",
                "countryCode": "PL"
            },
            {
                "name": "Dzięgielów",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa Górnicza",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrowa Zielona",
                "countryCode": "PL"
            },
            {
                "name": "Dębowiec",
                "countryCode": "PL"
            },
            {
                "name": "Frydek",
                "countryCode": "PL"
            },
            {
                "name": "Gardawice",
                "countryCode": "PL"
            },
            {
                "name": "Gaszowice",
                "countryCode": "PL"
            },
            {
                "name": "Gierałtowice",
                "countryCode": "PL"
            },
            {
                "name": "Gilowice",
                "countryCode": "PL"
            },
            {
                "name": "Gliwice",
                "countryCode": "PL"
            },
            {
                "name": "Gniazdów",
                "countryCode": "PL"
            },
            {
                "name": "Goczałkowice Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Godziszka",
                "countryCode": "PL"
            },
            {
                "name": "Godów",
                "countryCode": "PL"
            },
            {
                "name": "Goleszów",
                "countryCode": "PL"
            },
            {
                "name": "Gorzyce",
                "countryCode": "PL"
            },
            {
                "name": "Gorzyczki",
                "countryCode": "PL"
            },
            {
                "name": "Gostyń",
                "countryCode": "PL"
            },
            {
                "name": "Gołkowice",
                "countryCode": "PL"
            },
            {
                "name": "Góra",
                "countryCode": "PL"
            },
            {
                "name": "Górki Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Hażlach",
                "countryCode": "PL"
            },
            {
                "name": "Herby",
                "countryCode": "PL"
            },
            {
                "name": "Huta Stara B",
                "countryCode": "PL"
            },
            {
                "name": "Imielin",
                "countryCode": "PL"
            },
            {
                "name": "Istebna",
                "countryCode": "PL"
            },
            {
                "name": "Jankowice",
                "countryCode": "PL"
            },
            {
                "name": "Jankowice Rybnickie",
                "countryCode": "PL"
            },
            {
                "name": "Janowice",
                "countryCode": "PL"
            },
            {
                "name": "Jasienica",
                "countryCode": "PL"
            },
            {
                "name": "Jastrząb",
                "countryCode": "PL"
            },
            {
                "name": "Jastrzębie Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Jastrzębie-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Jaworze",
                "countryCode": "PL"
            },
            {
                "name": "Jaworzno",
                "countryCode": "PL"
            },
            {
                "name": "Jaworzynka",
                "countryCode": "PL"
            },
            {
                "name": "Jejkowice",
                "countryCode": "PL"
            },
            {
                "name": "Jeleśnia",
                "countryCode": "PL"
            },
            {
                "name": "Juszczyna",
                "countryCode": "PL"
            },
            {
                "name": "Kaczyce",
                "countryCode": "PL"
            },
            {
                "name": "Kalej",
                "countryCode": "PL"
            },
            {
                "name": "Kalety",
                "countryCode": "PL"
            },
            {
                "name": "Kamesznica",
                "countryCode": "PL"
            },
            {
                "name": "Kamienica Polska",
                "countryCode": "PL"
            },
            {
                "name": "Kamyk",
                "countryCode": "PL"
            },
            {
                "name": "Kaniów",
                "countryCode": "PL"
            },
            {
                "name": "Katowice",
                "countryCode": "PL"
            },
            {
                "name": "Knurów",
                "countryCode": "PL"
            },
            {
                "name": "Kobiernice",
                "countryCode": "PL"
            },
            {
                "name": "Kobiór",
                "countryCode": "PL"
            },
            {
                "name": "Kochanowice",
                "countryCode": "PL"
            },
            {
                "name": "Koniaków",
                "countryCode": "PL"
            },
            {
                "name": "Koniecpol",
                "countryCode": "PL"
            },
            {
                "name": "Konopiska",
                "countryCode": "PL"
            },
            {
                "name": "Korbielów",
                "countryCode": "PL"
            },
            {
                "name": "Kornowac",
                "countryCode": "PL"
            },
            {
                "name": "Koszarawa",
                "countryCode": "PL"
            },
            {
                "name": "Koszęcin",
                "countryCode": "PL"
            },
            {
                "name": "Koziegłowy",
                "countryCode": "PL"
            },
            {
                "name": "Kozy",
                "countryCode": "PL"
            },
            {
                "name": "Kończyce Małe",
                "countryCode": "PL"
            },
            {
                "name": "Kończyce Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Kościelec",
                "countryCode": "PL"
            },
            {
                "name": "Kroczyce",
                "countryCode": "PL"
            },
            {
                "name": "Krupski Młyn",
                "countryCode": "PL"
            },
            {
                "name": "Kryry",
                "countryCode": "PL"
            },
            {
                "name": "Krzanowice",
                "countryCode": "PL"
            },
            {
                "name": "Krzepice",
                "countryCode": "PL"
            },
            {
                "name": "Krzyżanowice",
                "countryCode": "PL"
            },
            {
                "name": "Krzyżowa",
                "countryCode": "PL"
            },
            {
                "name": "Krzyżowice",
                "countryCode": "PL"
            },
            {
                "name": "Książenice",
                "countryCode": "PL"
            },
            {
                "name": "Kuźnia Raciborska",
                "countryCode": "PL"
            },
            {
                "name": "Kłobuck",
                "countryCode": "PL"
            },
            {
                "name": "Kłomnice",
                "countryCode": "PL"
            },
            {
                "name": "Laliki",
                "countryCode": "PL"
            },
            {
                "name": "Lelów",
                "countryCode": "PL"
            },
            {
                "name": "Leśna",
                "countryCode": "PL"
            },
            {
                "name": "Ligota",
                "countryCode": "PL"
            },
            {
                "name": "Lipie",
                "countryCode": "PL"
            },
            {
                "name": "Lipowa",
                "countryCode": "PL"
            },
            {
                "name": "Lubliniec",
                "countryCode": "PL"
            },
            {
                "name": "Lubomia",
                "countryCode": "PL"
            },
            {
                "name": "Lyski",
                "countryCode": "PL"
            },
            {
                "name": "Lędziny",
                "countryCode": "PL"
            },
            {
                "name": "Markłowice",
                "countryCode": "PL"
            },
            {
                "name": "Mazańcowice",
                "countryCode": "PL"
            },
            {
                "name": "Miasteczko Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Miedzno",
                "countryCode": "PL"
            },
            {
                "name": "Miedźna",
                "countryCode": "PL"
            },
            {
                "name": "Mierzęcice",
                "countryCode": "PL"
            },
            {
                "name": "Mikołów",
                "countryCode": "PL"
            },
            {
                "name": "Milówka",
                "countryCode": "PL"
            },
            {
                "name": "Międzybrodzie Bialskie",
                "countryCode": "PL"
            },
            {
                "name": "Mnich",
                "countryCode": "PL"
            },
            {
                "name": "Mstów",
                "countryCode": "PL"
            },
            {
                "name": "Mszana",
                "countryCode": "PL"
            },
            {
                "name": "Murcki",
                "countryCode": "PL"
            },
            {
                "name": "Mykanów",
                "countryCode": "PL"
            },
            {
                "name": "Myszków",
                "countryCode": "PL"
            },
            {
                "name": "Mysłowice",
                "countryCode": "PL"
            },
            {
                "name": "Nakło",
                "countryCode": "PL"
            },
            {
                "name": "Niedobczyce",
                "countryCode": "PL"
            },
            {
                "name": "Niegowonice",
                "countryCode": "PL"
            },
            {
                "name": "Nędza",
                "countryCode": "PL"
            },
            {
                "name": "Ochaby",
                "countryCode": "PL"
            },
            {
                "name": "Ogrodzieniec",
                "countryCode": "PL"
            },
            {
                "name": "Olsztyn",
                "countryCode": "PL"
            },
            {
                "name": "Olza",
                "countryCode": "PL"
            },
            {
                "name": "Opatów",
                "countryCode": "PL"
            },
            {
                "name": "Ornontowice",
                "countryCode": "PL"
            },
            {
                "name": "Orzech",
                "countryCode": "PL"
            },
            {
                "name": "Orzesze",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowy nad Okszą",
                "countryCode": "PL"
            },
            {
                "name": "Ożarowice",
                "countryCode": "PL"
            },
            {
                "name": "Paniówki",
                "countryCode": "PL"
            },
            {
                "name": "Panki",
                "countryCode": "PL"
            },
            {
                "name": "Pawonków",
                "countryCode": "PL"
            },
            {
                "name": "Pawłowice",
                "countryCode": "PL"
            },
            {
                "name": "Pewel Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Pewel Ślemieńska",
                "countryCode": "PL"
            },
            {
                "name": "Piasek",
                "countryCode": "PL"
            },
            {
                "name": "Piekary Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Pielgrzymowice",
                "countryCode": "PL"
            },
            {
                "name": "Pierściec",
                "countryCode": "PL"
            },
            {
                "name": "Pietrowice Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Pietrzykowice",
                "countryCode": "PL"
            },
            {
                "name": "Pilchowice",
                "countryCode": "PL"
            },
            {
                "name": "Pilica",
                "countryCode": "PL"
            },
            {
                "name": "Pisarzowice",
                "countryCode": "PL"
            },
            {
                "name": "Pogrzebień",
                "countryCode": "PL"
            },
            {
                "name": "Pogwizdów",
                "countryCode": "PL"
            },
            {
                "name": "Pogórze",
                "countryCode": "PL"
            },
            {
                "name": "Poraj",
                "countryCode": "PL"
            },
            {
                "name": "Porąbka",
                "countryCode": "PL"
            },
            {
                "name": "Poręba",
                "countryCode": "PL"
            },
            {
                "name": "Potok Złoty",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bielski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bieruńsko-lędziński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat będziński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat cieszyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat częstochowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gliwicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kłobucki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lubliniecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat mikołowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat myszkowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pszczyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat raciborski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rybnicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat tarnogórski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wodzisławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat zawierciański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat żywiecki",
                "countryCode": "PL"
            },
            {
                "name": "Połomia",
                "countryCode": "PL"
            },
            {
                "name": "Pruchna",
                "countryCode": "PL"
            },
            {
                "name": "Przyborów",
                "countryCode": "PL"
            },
            {
                "name": "Przyrów",
                "countryCode": "PL"
            },
            {
                "name": "Przystajń",
                "countryCode": "PL"
            },
            {
                "name": "Przyszowice",
                "countryCode": "PL"
            },
            {
                "name": "Psary",
                "countryCode": "PL"
            },
            {
                "name": "Pszczyna",
                "countryCode": "PL"
            },
            {
                "name": "Pszów",
                "countryCode": "PL"
            },
            {
                "name": "Puńców",
                "countryCode": "PL"
            },
            {
                "name": "Pyskowice",
                "countryCode": "PL"
            },
            {
                "name": "Racibórz",
                "countryCode": "PL"
            },
            {
                "name": "Radlin",
                "countryCode": "PL"
            },
            {
                "name": "Radostowice",
                "countryCode": "PL"
            },
            {
                "name": "Radziechowy",
                "countryCode": "PL"
            },
            {
                "name": "Radzionków",
                "countryCode": "PL"
            },
            {
                "name": "Rajcza",
                "countryCode": "PL"
            },
            {
                "name": "Raszczyce",
                "countryCode": "PL"
            },
            {
                "name": "Rogoźnik",
                "countryCode": "PL"
            },
            {
                "name": "Rogów",
                "countryCode": "PL"
            },
            {
                "name": "Rokitno Szlacheckie",
                "countryCode": "PL"
            },
            {
                "name": "Ruda Śląska",
                "countryCode": "PL"
            },
            {
                "name": "Rudnik",
                "countryCode": "PL"
            },
            {
                "name": "Rudniki",
                "countryCode": "PL"
            },
            {
                "name": "Rudy",
                "countryCode": "PL"
            },
            {
                "name": "Rudziczka",
                "countryCode": "PL"
            },
            {
                "name": "Rudziniec",
                "countryCode": "PL"
            },
            {
                "name": "Rybarzowice",
                "countryCode": "PL"
            },
            {
                "name": "Rybnik",
                "countryCode": "PL"
            },
            {
                "name": "Rycerka Dolna",
                "countryCode": "PL"
            },
            {
                "name": "Rycerka Górna",
                "countryCode": "PL"
            },
            {
                "name": "Rydułtowy",
                "countryCode": "PL"
            },
            {
                "name": "Rędziny",
                "countryCode": "PL"
            },
            {
                "name": "Sarnów",
                "countryCode": "PL"
            },
            {
                "name": "Siemianowice Śląskie",
                "countryCode": "PL"
            },
            {
                "name": "Sieraków Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Siewierz",
                "countryCode": "PL"
            },
            {
                "name": "Skoczów",
                "countryCode": "PL"
            },
            {
                "name": "Skrzyszów",
                "countryCode": "PL"
            },
            {
                "name": "Sopotnia Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Sosnowiec",
                "countryCode": "PL"
            },
            {
                "name": "Sośnicowice",
                "countryCode": "PL"
            },
            {
                "name": "Stanowice",
                "countryCode": "PL"
            },
            {
                "name": "Starcza",
                "countryCode": "PL"
            },
            {
                "name": "Starokrzepice",
                "countryCode": "PL"
            },
            {
                "name": "Strumień",
                "countryCode": "PL"
            },
            {
                "name": "Strzebiń",
                "countryCode": "PL"
            },
            {
                "name": "Strzyżowice",
                "countryCode": "PL"
            },
            {
                "name": "Studzionka",
                "countryCode": "PL"
            },
            {
                "name": "Suszec",
                "countryCode": "PL"
            },
            {
                "name": "Syrynia",
                "countryCode": "PL"
            },
            {
                "name": "Szczekociny",
                "countryCode": "PL"
            },
            {
                "name": "Szczerbice",
                "countryCode": "PL"
            },
            {
                "name": "Szczyrk",
                "countryCode": "PL"
            },
            {
                "name": "Sól",
                "countryCode": "PL"
            },
            {
                "name": "Sączów",
                "countryCode": "PL"
            },
            {
                "name": "Sławków",
                "countryCode": "PL"
            },
            {
                "name": "Tarnowskie Góry",
                "countryCode": "PL"
            },
            {
                "name": "Toszek",
                "countryCode": "PL"
            },
            {
                "name": "Truskolasy",
                "countryCode": "PL"
            },
            {
                "name": "Trzebinia",
                "countryCode": "PL"
            },
            {
                "name": "Turza Śląska",
                "countryCode": "PL"
            },
            {
                "name": "Tworków",
                "countryCode": "PL"
            },
            {
                "name": "Tworóg",
                "countryCode": "PL"
            },
            {
                "name": "Tychy",
                "countryCode": "PL"
            },
            {
                "name": "Ujsoły",
                "countryCode": "PL"
            },
            {
                "name": "Ustroń",
                "countryCode": "PL"
            },
            {
                "name": "Wielowieś",
                "countryCode": "PL"
            },
            {
                "name": "Wieprz",
                "countryCode": "PL"
            },
            {
                "name": "Wieszowa",
                "countryCode": "PL"
            },
            {
                "name": "Wilamowice",
                "countryCode": "PL"
            },
            {
                "name": "Wilcza",
                "countryCode": "PL"
            },
            {
                "name": "Wilkowice",
                "countryCode": "PL"
            },
            {
                "name": "Wisła",
                "countryCode": "PL"
            },
            {
                "name": "Wodzisław Śląski",
                "countryCode": "PL"
            },
            {
                "name": "Wojkowice",
                "countryCode": "PL"
            },
            {
                "name": "Wola",
                "countryCode": "PL"
            },
            {
                "name": "Wożniki",
                "countryCode": "PL"
            },
            {
                "name": "Wręczyca Wielka",
                "countryCode": "PL"
            },
            {
                "name": "Wyry",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka",
                "countryCode": "PL"
            },
            {
                "name": "Węgierska Górka",
                "countryCode": "PL"
            },
            {
                "name": "Włodowice",
                "countryCode": "PL"
            },
            {
                "name": "Zaborze",
                "countryCode": "PL"
            },
            {
                "name": "Zabrze",
                "countryCode": "PL"
            },
            {
                "name": "Zabrzeg",
                "countryCode": "PL"
            },
            {
                "name": "Zabłocie",
                "countryCode": "PL"
            },
            {
                "name": "Zamarski",
                "countryCode": "PL"
            },
            {
                "name": "Zarzecze",
                "countryCode": "PL"
            },
            {
                "name": "Zawidów",
                "countryCode": "PL"
            },
            {
                "name": "Zawiercie",
                "countryCode": "PL"
            },
            {
                "name": "Zbrosławice",
                "countryCode": "PL"
            },
            {
                "name": "Zbytków",
                "countryCode": "PL"
            },
            {
                "name": "Zebrzydowice",
                "countryCode": "PL"
            },
            {
                "name": "Łaziska",
                "countryCode": "PL"
            },
            {
                "name": "Łaziska Górne",
                "countryCode": "PL"
            },
            {
                "name": "Łazy",
                "countryCode": "PL"
            },
            {
                "name": "Łobodno",
                "countryCode": "PL"
            },
            {
                "name": "Łodygowice",
                "countryCode": "PL"
            },
            {
                "name": "Łękawica",
                "countryCode": "PL"
            },
            {
                "name": "Ślemień",
                "countryCode": "PL"
            },
            {
                "name": "Świerklaniec",
                "countryCode": "PL"
            },
            {
                "name": "Świerklany Dolne",
                "countryCode": "PL"
            },
            {
                "name": "Świerklany Górne",
                "countryCode": "PL"
            },
            {
                "name": "Świnna",
                "countryCode": "PL"
            },
            {
                "name": "Świętochłowice",
                "countryCode": "PL"
            },
            {
                "name": "Żabnica",
                "countryCode": "PL"
            },
            {
                "name": "Żarki",
                "countryCode": "PL"
            },
            {
                "name": "Żarki-Letnisko",
                "countryCode": "PL"
            },
            {
                "name": "Żernica",
                "countryCode": "PL"
            },
            {
                "name": "Żory",
                "countryCode": "PL"
            },
            {
                "name": "Żywiec",
                "countryCode": "PL"
            },
            {
                "name": "Banie Mazurskie",
                "countryCode": "PL"
            },
            {
                "name": "Barciany",
                "countryCode": "PL"
            },
            {
                "name": "Barczewo",
                "countryCode": "PL"
            },
            {
                "name": "Bartoszyce",
                "countryCode": "PL"
            },
            {
                "name": "Biała Piska",
                "countryCode": "PL"
            },
            {
                "name": "Biskupiec",
                "countryCode": "PL"
            },
            {
                "name": "Bisztynek",
                "countryCode": "PL"
            },
            {
                "name": "Braniewo",
                "countryCode": "PL"
            },
            {
                "name": "Czerwonka",
                "countryCode": "PL"
            },
            {
                "name": "Dobre Miasto",
                "countryCode": "PL"
            },
            {
                "name": "Dywity",
                "countryCode": "PL"
            },
            {
                "name": "Działdowo",
                "countryCode": "PL"
            },
            {
                "name": "Dąbrówno",
                "countryCode": "PL"
            },
            {
                "name": "Dźwierzuty",
                "countryCode": "PL"
            },
            {
                "name": "Elbląg",
                "countryCode": "PL"
            },
            {
                "name": "Ełk",
                "countryCode": "PL"
            },
            {
                "name": "Frombork",
                "countryCode": "PL"
            },
            {
                "name": "Gierzwałd",
                "countryCode": "PL"
            },
            {
                "name": "Gierłoż",
                "countryCode": "PL"
            },
            {
                "name": "Gietrzwałd",
                "countryCode": "PL"
            },
            {
                "name": "Giżycko",
                "countryCode": "PL"
            },
            {
                "name": "Gołdap",
                "countryCode": "PL"
            },
            {
                "name": "Gronowo Elbląskie",
                "countryCode": "PL"
            },
            {
                "name": "Gronowo Górne",
                "countryCode": "PL"
            },
            {
                "name": "Górowo Iławeckie",
                "countryCode": "PL"
            },
            {
                "name": "Iława",
                "countryCode": "PL"
            },
            {
                "name": "Iłowo -Osada",
                "countryCode": "PL"
            },
            {
                "name": "Jedwabno",
                "countryCode": "PL"
            },
            {
                "name": "Jeziorany",
                "countryCode": "PL"
            },
            {
                "name": "Jonkowo",
                "countryCode": "PL"
            },
            {
                "name": "Kisielice",
                "countryCode": "PL"
            },
            {
                "name": "Korsze",
                "countryCode": "PL"
            },
            {
                "name": "Kortowo",
                "countryCode": "PL"
            },
            {
                "name": "Kowale Oleckie",
                "countryCode": "PL"
            },
            {
                "name": "Kozłowo",
                "countryCode": "PL"
            },
            {
                "name": "Kruklanki",
                "countryCode": "PL"
            },
            {
                "name": "Kurzętnik",
                "countryCode": "PL"
            },
            {
                "name": "Kętrzyn",
                "countryCode": "PL"
            },
            {
                "name": "Lelkowo",
                "countryCode": "PL"
            },
            {
                "name": "Lidzbark",
                "countryCode": "PL"
            },
            {
                "name": "Lidzbark Warmiński",
                "countryCode": "PL"
            },
            {
                "name": "Lubawa",
                "countryCode": "PL"
            },
            {
                "name": "Lubomino",
                "countryCode": "PL"
            },
            {
                "name": "Małdyty",
                "countryCode": "PL"
            },
            {
                "name": "Mikołajki",
                "countryCode": "PL"
            },
            {
                "name": "Miłakowo",
                "countryCode": "PL"
            },
            {
                "name": "Miłomłyn",
                "countryCode": "PL"
            },
            {
                "name": "Morąg",
                "countryCode": "PL"
            },
            {
                "name": "Mrągowo",
                "countryCode": "PL"
            },
            {
                "name": "Młynary",
                "countryCode": "PL"
            },
            {
                "name": "Nidzica",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Miasto Lubawskie",
                "countryCode": "PL"
            },
            {
                "name": "Olecko",
                "countryCode": "PL"
            },
            {
                "name": "Olsztyn",
                "countryCode": "PL"
            },
            {
                "name": "Olsztynek",
                "countryCode": "PL"
            },
            {
                "name": "Orneta",
                "countryCode": "PL"
            },
            {
                "name": "Orzysz",
                "countryCode": "PL"
            },
            {
                "name": "Ostróda",
                "countryCode": "PL"
            },
            {
                "name": "Pasym",
                "countryCode": "PL"
            },
            {
                "name": "Pasłęk",
                "countryCode": "PL"
            },
            {
                "name": "Piecki",
                "countryCode": "PL"
            },
            {
                "name": "Pieniężno",
                "countryCode": "PL"
            },
            {
                "name": "Pisz",
                "countryCode": "PL"
            },
            {
                "name": "Powiat bartoszycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat braniewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat działdowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat elbląski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ełcki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat giżycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gołdapski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat iławski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kętrzyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat lidzbarski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat mrągowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nidzicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowomiejski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat olecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat olsztyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostródzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat piski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat szczycieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat węgorzewski",
                "countryCode": "PL"
            },
            {
                "name": "Pozezdrze",
                "countryCode": "PL"
            },
            {
                "name": "Prostki",
                "countryCode": "PL"
            },
            {
                "name": "Purda",
                "countryCode": "PL"
            },
            {
                "name": "Reszel",
                "countryCode": "PL"
            },
            {
                "name": "Rozogi",
                "countryCode": "PL"
            },
            {
                "name": "Ruciane-Nida",
                "countryCode": "PL"
            },
            {
                "name": "Rybno",
                "countryCode": "PL"
            },
            {
                "name": "Ryn",
                "countryCode": "PL"
            },
            {
                "name": "Srokowo",
                "countryCode": "PL"
            },
            {
                "name": "Stare Juchy",
                "countryCode": "PL"
            },
            {
                "name": "Stawiguda",
                "countryCode": "PL"
            },
            {
                "name": "Susz",
                "countryCode": "PL"
            },
            {
                "name": "Swiętajno",
                "countryCode": "PL"
            },
            {
                "name": "Szczytno",
                "countryCode": "PL"
            },
            {
                "name": "Sępopol",
                "countryCode": "PL"
            },
            {
                "name": "Tolkmicko",
                "countryCode": "PL"
            },
            {
                "name": "Wielbark",
                "countryCode": "PL"
            },
            {
                "name": "Wydminy",
                "countryCode": "PL"
            },
            {
                "name": "Węgorzewo",
                "countryCode": "PL"
            },
            {
                "name": "Zalewo",
                "countryCode": "PL"
            },
            {
                "name": "Babiak",
                "countryCode": "PL"
            },
            {
                "name": "Baranowo",
                "countryCode": "PL"
            },
            {
                "name": "Baranów",
                "countryCode": "PL"
            },
            {
                "name": "Białośliwie",
                "countryCode": "PL"
            },
            {
                "name": "Blizanów",
                "countryCode": "PL"
            },
            {
                "name": "Bojanowo",
                "countryCode": "PL"
            },
            {
                "name": "Bojanowo Stare",
                "countryCode": "PL"
            },
            {
                "name": "Borek Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Bralin",
                "countryCode": "PL"
            },
            {
                "name": "Brdów",
                "countryCode": "PL"
            },
            {
                "name": "Brenno",
                "countryCode": "PL"
            },
            {
                "name": "Brodnica",
                "countryCode": "PL"
            },
            {
                "name": "Broniszewice",
                "countryCode": "PL"
            },
            {
                "name": "Brudzew",
                "countryCode": "PL"
            },
            {
                "name": "Budzyń",
                "countryCode": "PL"
            },
            {
                "name": "Buk",
                "countryCode": "PL"
            },
            {
                "name": "Chocz",
                "countryCode": "PL"
            },
            {
                "name": "Chodzież",
                "countryCode": "PL"
            },
            {
                "name": "Chodów",
                "countryCode": "PL"
            },
            {
                "name": "Chrzypsko Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Czajków",
                "countryCode": "PL"
            },
            {
                "name": "Czapury",
                "countryCode": "PL"
            },
            {
                "name": "Czarnków",
                "countryCode": "PL"
            },
            {
                "name": "Czempiń",
                "countryCode": "PL"
            },
            {
                "name": "Czermin",
                "countryCode": "PL"
            },
            {
                "name": "Czerniejewo",
                "countryCode": "PL"
            },
            {
                "name": "Czerwonak",
                "countryCode": "PL"
            },
            {
                "name": "Damasławek",
                "countryCode": "PL"
            },
            {
                "name": "Daszewice",
                "countryCode": "PL"
            },
            {
                "name": "Dobra",
                "countryCode": "PL"
            },
            {
                "name": "Dobrzyca",
                "countryCode": "PL"
            },
            {
                "name": "Dolsk",
                "countryCode": "PL"
            },
            {
                "name": "Dominowo",
                "countryCode": "PL"
            },
            {
                "name": "Dopiewo",
                "countryCode": "PL"
            },
            {
                "name": "Doruchów",
                "countryCode": "PL"
            },
            {
                "name": "Drawsko",
                "countryCode": "PL"
            },
            {
                "name": "Duszniki",
                "countryCode": "PL"
            },
            {
                "name": "Dąbie",
                "countryCode": "PL"
            },
            {
                "name": "Gizałki",
                "countryCode": "PL"
            },
            {
                "name": "Gniezno",
                "countryCode": "PL"
            },
            {
                "name": "Golina",
                "countryCode": "PL"
            },
            {
                "name": "Gorzyce Wielkie",
                "countryCode": "PL"
            },
            {
                "name": "Gostyń",
                "countryCode": "PL"
            },
            {
                "name": "Gołańcz",
                "countryCode": "PL"
            },
            {
                "name": "Gołuchów",
                "countryCode": "PL"
            },
            {
                "name": "Grabów nad Prosną",
                "countryCode": "PL"
            },
            {
                "name": "Granowo",
                "countryCode": "PL"
            },
            {
                "name": "Grodziec",
                "countryCode": "PL"
            },
            {
                "name": "Grodzisk Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Grzegorzew",
                "countryCode": "PL"
            },
            {
                "name": "Jabłonna",
                "countryCode": "PL"
            },
            {
                "name": "Janków Przygodzki",
                "countryCode": "PL"
            },
            {
                "name": "Jaraczewo",
                "countryCode": "PL"
            },
            {
                "name": "Jarocin",
                "countryCode": "PL"
            },
            {
                "name": "Jastrowie",
                "countryCode": "PL"
            },
            {
                "name": "Jutrosin",
                "countryCode": "PL"
            },
            {
                "name": "Kaczory",
                "countryCode": "PL"
            },
            {
                "name": "Kalisz",
                "countryCode": "PL"
            },
            {
                "name": "Kamieniec",
                "countryCode": "PL"
            },
            {
                "name": "Kawęczyn",
                "countryCode": "PL"
            },
            {
                "name": "Kazimierz Biskupi",
                "countryCode": "PL"
            },
            {
                "name": "Kaźmierz",
                "countryCode": "PL"
            },
            {
                "name": "Kiszkowo",
                "countryCode": "PL"
            },
            {
                "name": "Kleczew",
                "countryCode": "PL"
            },
            {
                "name": "Kleszczewo",
                "countryCode": "PL"
            },
            {
                "name": "Kobyla Góra",
                "countryCode": "PL"
            },
            {
                "name": "Kobylin",
                "countryCode": "PL"
            },
            {
                "name": "Kobylnica",
                "countryCode": "PL"
            },
            {
                "name": "Komorniki",
                "countryCode": "PL"
            },
            {
                "name": "Konary",
                "countryCode": "PL"
            },
            {
                "name": "Konin",
                "countryCode": "PL"
            },
            {
                "name": "Kostrzyn",
                "countryCode": "PL"
            },
            {
                "name": "Kotlin",
                "countryCode": "PL"
            },
            {
                "name": "Kołaczkowo",
                "countryCode": "PL"
            },
            {
                "name": "Koło",
                "countryCode": "PL"
            },
            {
                "name": "Kościan",
                "countryCode": "PL"
            },
            {
                "name": "Kościelec",
                "countryCode": "PL"
            },
            {
                "name": "Koźmin Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Koźminek",
                "countryCode": "PL"
            },
            {
                "name": "Krajenka",
                "countryCode": "PL"
            },
            {
                "name": "Kramsk",
                "countryCode": "PL"
            },
            {
                "name": "Kraszewice",
                "countryCode": "PL"
            },
            {
                "name": "Krobia",
                "countryCode": "PL"
            },
            {
                "name": "Krosno",
                "countryCode": "PL"
            },
            {
                "name": "Krotoszyn",
                "countryCode": "PL"
            },
            {
                "name": "Krzemieniewo",
                "countryCode": "PL"
            },
            {
                "name": "Krzykosy",
                "countryCode": "PL"
            },
            {
                "name": "Krzywiń",
                "countryCode": "PL"
            },
            {
                "name": "Krzyż Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Książ Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Kuślin",
                "countryCode": "PL"
            },
            {
                "name": "Kwilcz",
                "countryCode": "PL"
            },
            {
                "name": "Kórnik",
                "countryCode": "PL"
            },
            {
                "name": "Kępno",
                "countryCode": "PL"
            },
            {
                "name": "Kłecko",
                "countryCode": "PL"
            },
            {
                "name": "Kłodawa",
                "countryCode": "PL"
            },
            {
                "name": "Leszno",
                "countryCode": "PL"
            },
            {
                "name": "Licheń Stary",
                "countryCode": "PL"
            },
            {
                "name": "Lipka",
                "countryCode": "PL"
            },
            {
                "name": "Lipno",
                "countryCode": "PL"
            },
            {
                "name": "Lisków",
                "countryCode": "PL"
            },
            {
                "name": "Lubasz",
                "countryCode": "PL"
            },
            {
                "name": "Luboń",
                "countryCode": "PL"
            },
            {
                "name": "Lwówek",
                "countryCode": "PL"
            },
            {
                "name": "Lądek",
                "countryCode": "PL"
            },
            {
                "name": "Malanów",
                "countryCode": "PL"
            },
            {
                "name": "Margonin",
                "countryCode": "PL"
            },
            {
                "name": "Miasteczko Krajeńskie",
                "countryCode": "PL"
            },
            {
                "name": "Miedzichowo",
                "countryCode": "PL"
            },
            {
                "name": "Miejska Górka",
                "countryCode": "PL"
            },
            {
                "name": "Mieleszyn",
                "countryCode": "PL"
            },
            {
                "name": "Mieścisko",
                "countryCode": "PL"
            },
            {
                "name": "Mikstat",
                "countryCode": "PL"
            },
            {
                "name": "Międzychód",
                "countryCode": "PL"
            },
            {
                "name": "Miłosław",
                "countryCode": "PL"
            },
            {
                "name": "Mosina",
                "countryCode": "PL"
            },
            {
                "name": "Murowana Goślina",
                "countryCode": "PL"
            },
            {
                "name": "Nekla",
                "countryCode": "PL"
            },
            {
                "name": "Niechanowo",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Miasto nad Wartą",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Skalmierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Nowy Tomyśl",
                "countryCode": "PL"
            },
            {
                "name": "Oborniki",
                "countryCode": "PL"
            },
            {
                "name": "Obrzycko",
                "countryCode": "PL"
            },
            {
                "name": "Odolanów",
                "countryCode": "PL"
            },
            {
                "name": "Okonek",
                "countryCode": "PL"
            },
            {
                "name": "Olszówka",
                "countryCode": "PL"
            },
            {
                "name": "Opalenica",
                "countryCode": "PL"
            },
            {
                "name": "Opatów",
                "countryCode": "PL"
            },
            {
                "name": "Opatówek",
                "countryCode": "PL"
            },
            {
                "name": "Orchowo",
                "countryCode": "PL"
            },
            {
                "name": "Osieczna",
                "countryCode": "PL"
            },
            {
                "name": "Osiek Mały",
                "countryCode": "PL"
            },
            {
                "name": "Osiek nad Notecią",
                "countryCode": "PL"
            },
            {
                "name": "Ostroróg",
                "countryCode": "PL"
            },
            {
                "name": "Ostrowite",
                "countryCode": "PL"
            },
            {
                "name": "Ostrzeszów",
                "countryCode": "PL"
            },
            {
                "name": "Ostrów Wielkopolski",
                "countryCode": "PL"
            },
            {
                "name": "Pakosław",
                "countryCode": "PL"
            },
            {
                "name": "Pamiątkowo",
                "countryCode": "PL"
            },
            {
                "name": "Pecna",
                "countryCode": "PL"
            },
            {
                "name": "Perzów",
                "countryCode": "PL"
            },
            {
                "name": "Piaski",
                "countryCode": "PL"
            },
            {
                "name": "Piła",
                "countryCode": "PL"
            },
            {
                "name": "Pleszew",
                "countryCode": "PL"
            },
            {
                "name": "Plewiska",
                "countryCode": "PL"
            },
            {
                "name": "Pniewy",
                "countryCode": "PL"
            },
            {
                "name": "Pobiedziska",
                "countryCode": "PL"
            },
            {
                "name": "Pogorzela",
                "countryCode": "PL"
            },
            {
                "name": "Poniec",
                "countryCode": "PL"
            },
            {
                "name": "Powiat chodzieski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat czarnkowsko-trzcianecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gnieźnieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gostyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat grodziski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat jarociński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kaliski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kolski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat koniński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kościański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat krotoszyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kępiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat leszczyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat międzychodzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat nowotomyski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat obornicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostrowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat ostrzeszowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pilski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pleszewski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat poznański",
                "countryCode": "PL"
            },
            {
                "name": "Powiat rawicki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat szamotulski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat słupecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat turecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wolsztyński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wrzesiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wągrowiecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat złotowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat średzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat śremski",
                "countryCode": "PL"
            },
            {
                "name": "Powidz",
                "countryCode": "PL"
            },
            {
                "name": "Poznań",
                "countryCode": "PL"
            },
            {
                "name": "Połajewo",
                "countryCode": "PL"
            },
            {
                "name": "Przedecz",
                "countryCode": "PL"
            },
            {
                "name": "Przemęt",
                "countryCode": "PL"
            },
            {
                "name": "Przygodzice",
                "countryCode": "PL"
            },
            {
                "name": "Przykona",
                "countryCode": "PL"
            },
            {
                "name": "Puszczykowo",
                "countryCode": "PL"
            },
            {
                "name": "Pyzdry",
                "countryCode": "PL"
            },
            {
                "name": "Pępowo",
                "countryCode": "PL"
            },
            {
                "name": "Rakoniewice",
                "countryCode": "PL"
            },
            {
                "name": "Raszków",
                "countryCode": "PL"
            },
            {
                "name": "Rawicz",
                "countryCode": "PL"
            },
            {
                "name": "Rogalinek",
                "countryCode": "PL"
            },
            {
                "name": "Rogoźno",
                "countryCode": "PL"
            },
            {
                "name": "Rokietnica",
                "countryCode": "PL"
            },
            {
                "name": "Rozdrażew",
                "countryCode": "PL"
            },
            {
                "name": "Rychtal",
                "countryCode": "PL"
            },
            {
                "name": "Rychwał",
                "countryCode": "PL"
            },
            {
                "name": "Ryczywół",
                "countryCode": "PL"
            },
            {
                "name": "Rydzyna",
                "countryCode": "PL"
            },
            {
                "name": "Rzgów Pierwszy",
                "countryCode": "PL"
            },
            {
                "name": "Siedlec",
                "countryCode": "PL"
            },
            {
                "name": "Sieraków",
                "countryCode": "PL"
            },
            {
                "name": "Sieroszewice",
                "countryCode": "PL"
            },
            {
                "name": "Skalmierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Skoki",
                "countryCode": "PL"
            },
            {
                "name": "Skulsk",
                "countryCode": "PL"
            },
            {
                "name": "Sompolno",
                "countryCode": "PL"
            },
            {
                "name": "Sośnie",
                "countryCode": "PL"
            },
            {
                "name": "Stare Miasto",
                "countryCode": "PL"
            },
            {
                "name": "Stawiszyn",
                "countryCode": "PL"
            },
            {
                "name": "Strzałkowo",
                "countryCode": "PL"
            },
            {
                "name": "Stęszew",
                "countryCode": "PL"
            },
            {
                "name": "Suchy Las",
                "countryCode": "PL"
            },
            {
                "name": "Sulmierzyce",
                "countryCode": "PL"
            },
            {
                "name": "Swarzędz",
                "countryCode": "PL"
            },
            {
                "name": "Sypniewo",
                "countryCode": "PL"
            },
            {
                "name": "Szamocin",
                "countryCode": "PL"
            },
            {
                "name": "Szamotuły",
                "countryCode": "PL"
            },
            {
                "name": "Szydłowo",
                "countryCode": "PL"
            },
            {
                "name": "Słupca",
                "countryCode": "PL"
            },
            {
                "name": "Słupia pod Kępnem",
                "countryCode": "PL"
            },
            {
                "name": "Tarnowo Podgórne",
                "countryCode": "PL"
            },
            {
                "name": "Tarnówka",
                "countryCode": "PL"
            },
            {
                "name": "Trzcianka",
                "countryCode": "PL"
            },
            {
                "name": "Trzcinica",
                "countryCode": "PL"
            },
            {
                "name": "Trzemeszno",
                "countryCode": "PL"
            },
            {
                "name": "Tuliszków",
                "countryCode": "PL"
            },
            {
                "name": "Turek",
                "countryCode": "PL"
            },
            {
                "name": "Ujście",
                "countryCode": "PL"
            },
            {
                "name": "Wapno",
                "countryCode": "PL"
            },
            {
                "name": "Wieleń",
                "countryCode": "PL"
            },
            {
                "name": "Wielichowo",
                "countryCode": "PL"
            },
            {
                "name": "Wierzbinek",
                "countryCode": "PL"
            },
            {
                "name": "Wijewo",
                "countryCode": "PL"
            },
            {
                "name": "Wilczogóra",
                "countryCode": "PL"
            },
            {
                "name": "Wilczyn",
                "countryCode": "PL"
            },
            {
                "name": "Wilkowice",
                "countryCode": "PL"
            },
            {
                "name": "Witaszyce",
                "countryCode": "PL"
            },
            {
                "name": "Witkowo",
                "countryCode": "PL"
            },
            {
                "name": "Wolsztyn",
                "countryCode": "PL"
            },
            {
                "name": "Wronki",
                "countryCode": "PL"
            },
            {
                "name": "Września",
                "countryCode": "PL"
            },
            {
                "name": "Wyrzysk",
                "countryCode": "PL"
            },
            {
                "name": "Wysoka",
                "countryCode": "PL"
            },
            {
                "name": "Wągrowiec",
                "countryCode": "PL"
            },
            {
                "name": "Władysławów",
                "countryCode": "PL"
            },
            {
                "name": "Włoszakowice",
                "countryCode": "PL"
            },
            {
                "name": "Zagórów",
                "countryCode": "PL"
            },
            {
                "name": "Zakrzewo",
                "countryCode": "PL"
            },
            {
                "name": "Zaniemyśl",
                "countryCode": "PL"
            },
            {
                "name": "Zbąszyń",
                "countryCode": "PL"
            },
            {
                "name": "Zduny",
                "countryCode": "PL"
            },
            {
                "name": "Złotniki",
                "countryCode": "PL"
            },
            {
                "name": "Złotów",
                "countryCode": "PL"
            },
            {
                "name": "Łobżenica",
                "countryCode": "PL"
            },
            {
                "name": "Łubowo",
                "countryCode": "PL"
            },
            {
                "name": "Łęka Opatowska",
                "countryCode": "PL"
            },
            {
                "name": "Ślesin",
                "countryCode": "PL"
            },
            {
                "name": "Śmigiel",
                "countryCode": "PL"
            },
            {
                "name": "Śmiłowo",
                "countryCode": "PL"
            },
            {
                "name": "Śrem",
                "countryCode": "PL"
            },
            {
                "name": "Środa Wielkopolska",
                "countryCode": "PL"
            },
            {
                "name": "Święciechowa",
                "countryCode": "PL"
            },
            {
                "name": "Żelazków",
                "countryCode": "PL"
            },
            {
                "name": "Żerków",
                "countryCode": "PL"
            },
            {
                "name": "Banie",
                "countryCode": "PL"
            },
            {
                "name": "Barlinek",
                "countryCode": "PL"
            },
            {
                "name": "Barwice",
                "countryCode": "PL"
            },
            {
                "name": "Białogard",
                "countryCode": "PL"
            },
            {
                "name": "Biały Bór",
                "countryCode": "PL"
            },
            {
                "name": "Bielice",
                "countryCode": "PL"
            },
            {
                "name": "Bierzwnik",
                "countryCode": "PL"
            },
            {
                "name": "Bobolice",
                "countryCode": "PL"
            },
            {
                "name": "Boleszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Borne Sulinowo",
                "countryCode": "PL"
            },
            {
                "name": "Brojce",
                "countryCode": "PL"
            },
            {
                "name": "Cedynia",
                "countryCode": "PL"
            },
            {
                "name": "Cerkwica",
                "countryCode": "PL"
            },
            {
                "name": "Chociwel",
                "countryCode": "PL"
            },
            {
                "name": "Chojna",
                "countryCode": "PL"
            },
            {
                "name": "Choszczno",
                "countryCode": "PL"
            },
            {
                "name": "Czaplinek",
                "countryCode": "PL"
            },
            {
                "name": "Człopa",
                "countryCode": "PL"
            },
            {
                "name": "Darłowo",
                "countryCode": "PL"
            },
            {
                "name": "Dobra",
                "countryCode": "PL"
            },
            {
                "name": "Dobrzany",
                "countryCode": "PL"
            },
            {
                "name": "Dolice",
                "countryCode": "PL"
            },
            {
                "name": "Drawno",
                "countryCode": "PL"
            },
            {
                "name": "Drawsko Pomorskie",
                "countryCode": "PL"
            },
            {
                "name": "Dygowo",
                "countryCode": "PL"
            },
            {
                "name": "Dziwnów",
                "countryCode": "PL"
            },
            {
                "name": "Dębno",
                "countryCode": "PL"
            },
            {
                "name": "Golczewo",
                "countryCode": "PL"
            },
            {
                "name": "Goleniów",
                "countryCode": "PL"
            },
            {
                "name": "Gościno",
                "countryCode": "PL"
            },
            {
                "name": "Gryfice",
                "countryCode": "PL"
            },
            {
                "name": "Gryfino",
                "countryCode": "PL"
            },
            {
                "name": "Grzmiąca",
                "countryCode": "PL"
            },
            {
                "name": "Grzybowo",
                "countryCode": "PL"
            },
            {
                "name": "Ińsko",
                "countryCode": "PL"
            },
            {
                "name": "Kalisz Pomorski",
                "countryCode": "PL"
            },
            {
                "name": "Kamień Pomorski",
                "countryCode": "PL"
            },
            {
                "name": "Karlino",
                "countryCode": "PL"
            },
            {
                "name": "Karnice",
                "countryCode": "PL"
            },
            {
                "name": "Kobylanka",
                "countryCode": "PL"
            },
            {
                "name": "Koszalin",
                "countryCode": "PL"
            },
            {
                "name": "Kołbaskowo",
                "countryCode": "PL"
            },
            {
                "name": "Kołobrzeg",
                "countryCode": "PL"
            },
            {
                "name": "Krzęcin",
                "countryCode": "PL"
            },
            {
                "name": "Lipiany",
                "countryCode": "PL"
            },
            {
                "name": "Marianowo",
                "countryCode": "PL"
            },
            {
                "name": "Maszewo",
                "countryCode": "PL"
            },
            {
                "name": "Mielno",
                "countryCode": "PL"
            },
            {
                "name": "Mieszkowice",
                "countryCode": "PL"
            },
            {
                "name": "Mirosławiec",
                "countryCode": "PL"
            },
            {
                "name": "Międzyzdroje",
                "countryCode": "PL"
            },
            {
                "name": "Moryń",
                "countryCode": "PL"
            },
            {
                "name": "Mosty",
                "countryCode": "PL"
            },
            {
                "name": "Mrzeżyno",
                "countryCode": "PL"
            },
            {
                "name": "Myślibórz",
                "countryCode": "PL"
            },
            {
                "name": "Nowe Warpno",
                "countryCode": "PL"
            },
            {
                "name": "Nowogard",
                "countryCode": "PL"
            },
            {
                "name": "Nowogródek Pomorski",
                "countryCode": "PL"
            },
            {
                "name": "Osina",
                "countryCode": "PL"
            },
            {
                "name": "Pełczyce",
                "countryCode": "PL"
            },
            {
                "name": "Pobierowo",
                "countryCode": "PL"
            },
            {
                "name": "Polanów",
                "countryCode": "PL"
            },
            {
                "name": "Police",
                "countryCode": "PL"
            },
            {
                "name": "Powiat białogardzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat choszczeński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat drawski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat goleniowski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gryficki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat gryfiński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kamieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat koszaliński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat kołobrzeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat myśliborski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat policki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat pyrzycki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat stargardzki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat szczecinecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat sławieński",
                "countryCode": "PL"
            },
            {
                "name": "Powiat wałecki",
                "countryCode": "PL"
            },
            {
                "name": "Powiat łobeski",
                "countryCode": "PL"
            },
            {
                "name": "Powiat świdwiński",
                "countryCode": "PL"
            },
            {
                "name": "Połczyn-Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Przecław",
                "countryCode": "PL"
            },
            {
                "name": "Przelewice",
                "countryCode": "PL"
            },
            {
                "name": "Przybiernów",
                "countryCode": "PL"
            },
            {
                "name": "Pyrzyce",
                "countryCode": "PL"
            },
            {
                "name": "Płoty",
                "countryCode": "PL"
            },
            {
                "name": "Radowo Małe",
                "countryCode": "PL"
            },
            {
                "name": "Recz",
                "countryCode": "PL"
            },
            {
                "name": "Resko",
                "countryCode": "PL"
            },
            {
                "name": "Rewal",
                "countryCode": "PL"
            },
            {
                "name": "Rymań",
                "countryCode": "PL"
            },
            {
                "name": "Rąbino",
                "countryCode": "PL"
            },
            {
                "name": "Sianów",
                "countryCode": "PL"
            },
            {
                "name": "Stara Dąbrowa",
                "countryCode": "PL"
            },
            {
                "name": "Stare Czarnowo",
                "countryCode": "PL"
            },
            {
                "name": "Stargard",
                "countryCode": "PL"
            },
            {
                "name": "Stepnica",
                "countryCode": "PL"
            },
            {
                "name": "Suchań",
                "countryCode": "PL"
            },
            {
                "name": "Szczecin",
                "countryCode": "PL"
            },
            {
                "name": "Szczecinek",
                "countryCode": "PL"
            },
            {
                "name": "Sławno",
                "countryCode": "PL"
            },
            {
                "name": "Sławoborze",
                "countryCode": "PL"
            },
            {
                "name": "Trzcińsko Zdrój",
                "countryCode": "PL"
            },
            {
                "name": "Trzebiatów",
                "countryCode": "PL"
            },
            {
                "name": "Tuczno",
                "countryCode": "PL"
            },
            {
                "name": "Tychowo",
                "countryCode": "PL"
            },
            {
                "name": "Ustronie Morskie",
                "countryCode": "PL"
            },
            {
                "name": "Warnice",
                "countryCode": "PL"
            },
            {
                "name": "Wałcz",
                "countryCode": "PL"
            },
            {
                "name": "Widuchowa",
                "countryCode": "PL"
            },
            {
                "name": "Wierzchowo",
                "countryCode": "PL"
            },
            {
                "name": "Wolin",
                "countryCode": "PL"
            },
            {
                "name": "Węgorzyno",
                "countryCode": "PL"
            },
            {
                "name": "Złocieniec",
                "countryCode": "PL"
            },
            {
                "name": "Łobez",
                "countryCode": "PL"
            },
            {
                "name": "Łubowo",
                "countryCode": "PL"
            },
            {
                "name": "Świdwin",
                "countryCode": "PL"
            },
            {
                "name": "Świerzno",
                "countryCode": "PL"
            },
            {
                "name": "Świnoujście",
                "countryCode": "PL"
            }
        ]
    },
    {
        "name": "Portugal",
        "phonecode": "351",
        "cities": [
            {
                "name": "Aguada de Cima",
                "countryCode": "PT"
            },
            {
                "name": "Albergaria-a-Velha",
                "countryCode": "PT"
            },
            {
                "name": "Anadia",
                "countryCode": "PT"
            },
            {
                "name": "Aradas",
                "countryCode": "PT"
            },
            {
                "name": "Arcos",
                "countryCode": "PT"
            },
            {
                "name": "Argoncilhe",
                "countryCode": "PT"
            },
            {
                "name": "Arouca",
                "countryCode": "PT"
            },
            {
                "name": "Arrifana",
                "countryCode": "PT"
            },
            {
                "name": "Avanca",
                "countryCode": "PT"
            },
            {
                "name": "Aveiro",
                "countryCode": "PT"
            },
            {
                "name": "Beduido",
                "countryCode": "PT"
            },
            {
                "name": "Branca",
                "countryCode": "PT"
            },
            {
                "name": "Castelo de Paiva",
                "countryCode": "PT"
            },
            {
                "name": "Cortegaça",
                "countryCode": "PT"
            },
            {
                "name": "Cucujães",
                "countryCode": "PT"
            },
            {
                "name": "Eixo",
                "countryCode": "PT"
            },
            {
                "name": "Esgueira",
                "countryCode": "PT"
            },
            {
                "name": "Esmoriz",
                "countryCode": "PT"
            },
            {
                "name": "Espargo",
                "countryCode": "PT"
            },
            {
                "name": "Espinho",
                "countryCode": "PT"
            },
            {
                "name": "Estarreja",
                "countryCode": "PT"
            },
            {
                "name": "Feira",
                "countryCode": "PT"
            },
            {
                "name": "Fiães",
                "countryCode": "PT"
            },
            {
                "name": "Gafanha da Encarnação",
                "countryCode": "PT"
            },
            {
                "name": "Gafanha da Nazaré",
                "countryCode": "PT"
            },
            {
                "name": "Lamas",
                "countryCode": "PT"
            },
            {
                "name": "Lobão",
                "countryCode": "PT"
            },
            {
                "name": "Lourosa",
                "countryCode": "PT"
            },
            {
                "name": "Luso",
                "countryCode": "PT"
            },
            {
                "name": "Macieira de Cambra",
                "countryCode": "PT"
            },
            {
                "name": "Mealhada",
                "countryCode": "PT"
            },
            {
                "name": "Milheirós de Poiares",
                "countryCode": "PT"
            },
            {
                "name": "Mosteirô",
                "countryCode": "PT"
            },
            {
                "name": "Murtosa",
                "countryCode": "PT"
            },
            {
                "name": "Nogueira da Regedoura",
                "countryCode": "PT"
            },
            {
                "name": "Oiã",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira de Azemeis",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira do Bairro",
                "countryCode": "PT"
            },
            {
                "name": "Oliveirinha",
                "countryCode": "PT"
            },
            {
                "name": "Ovar",
                "countryCode": "PT"
            },
            {
                "name": "Pampilhosa do Botão",
                "countryCode": "PT"
            },
            {
                "name": "Pardilhó",
                "countryCode": "PT"
            },
            {
                "name": "Paços de Brandão",
                "countryCode": "PT"
            },
            {
                "name": "Rio Meão",
                "countryCode": "PT"
            },
            {
                "name": "Salreu",
                "countryCode": "PT"
            },
            {
                "name": "Sanfins",
                "countryCode": "PT"
            },
            {
                "name": "Sangalhos",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria da Feira",
                "countryCode": "PT"
            },
            {
                "name": "Sever do Vouga",
                "countryCode": "PT"
            },
            {
                "name": "Silvalde",
                "countryCode": "PT"
            },
            {
                "name": "Souto",
                "countryCode": "PT"
            },
            {
                "name": "São João",
                "countryCode": "PT"
            },
            {
                "name": "São João da Madeira",
                "countryCode": "PT"
            },
            {
                "name": "São João de Ver",
                "countryCode": "PT"
            },
            {
                "name": "São Roque",
                "countryCode": "PT"
            },
            {
                "name": "Vagos",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Cambra",
                "countryCode": "PT"
            },
            {
                "name": "Valongo",
                "countryCode": "PT"
            },
            {
                "name": "Vila Chã",
                "countryCode": "PT"
            },
            {
                "name": "Válega",
                "countryCode": "PT"
            },
            {
                "name": "Águeda",
                "countryCode": "PT"
            },
            {
                "name": "Ílhavo",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Nova de São Bento",
                "countryCode": "PT"
            },
            {
                "name": "Aljustrel",
                "countryCode": "PT"
            },
            {
                "name": "Almodôvar",
                "countryCode": "PT"
            },
            {
                "name": "Almograve",
                "countryCode": "PT"
            },
            {
                "name": "Alvito",
                "countryCode": "PT"
            },
            {
                "name": "Barrancos",
                "countryCode": "PT"
            },
            {
                "name": "Beja",
                "countryCode": "PT"
            },
            {
                "name": "Beringel",
                "countryCode": "PT"
            },
            {
                "name": "Boavista dos Pinheiros",
                "countryCode": "PT"
            },
            {
                "name": "Cabeça Gorda",
                "countryCode": "PT"
            },
            {
                "name": "Castro Verde",
                "countryCode": "PT"
            },
            {
                "name": "Cuba",
                "countryCode": "PT"
            },
            {
                "name": "Ferreira do Alentejo",
                "countryCode": "PT"
            },
            {
                "name": "Minas de São Domingos",
                "countryCode": "PT"
            },
            {
                "name": "Moura",
                "countryCode": "PT"
            },
            {
                "name": "Mértola",
                "countryCode": "PT"
            },
            {
                "name": "Neves",
                "countryCode": "PT"
            },
            {
                "name": "Odemira",
                "countryCode": "PT"
            },
            {
                "name": "Ourique",
                "countryCode": "PT"
            },
            {
                "name": "Penedo Gordo",
                "countryCode": "PT"
            },
            {
                "name": "Salvada",
                "countryCode": "PT"
            },
            {
                "name": "Serpa",
                "countryCode": "PT"
            },
            {
                "name": "São Luis",
                "countryCode": "PT"
            },
            {
                "name": "São Teotónio",
                "countryCode": "PT"
            },
            {
                "name": "Vidigueira",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Milfontes",
                "countryCode": "PT"
            },
            {
                "name": "Zambujeira do Mar",
                "countryCode": "PT"
            },
            {
                "name": "Adaúfe",
                "countryCode": "PT"
            },
            {
                "name": "Amares",
                "countryCode": "PT"
            },
            {
                "name": "Apúlia",
                "countryCode": "PT"
            },
            {
                "name": "Apúlia e Fão",
                "countryCode": "PT"
            },
            {
                "name": "Arcos",
                "countryCode": "PT"
            },
            {
                "name": "Arões",
                "countryCode": "PT"
            },
            {
                "name": "Aveleda",
                "countryCode": "PT"
            },
            {
                "name": "Barcelos",
                "countryCode": "PT"
            },
            {
                "name": "Barqueiros",
                "countryCode": "PT"
            },
            {
                "name": "Braga",
                "countryCode": "PT"
            },
            {
                "name": "Brito",
                "countryCode": "PT"
            },
            {
                "name": "Cabeceiras de Basto",
                "countryCode": "PT"
            },
            {
                "name": "Caldas das Taipas",
                "countryCode": "PT"
            },
            {
                "name": "Caldas de Vizela",
                "countryCode": "PT"
            },
            {
                "name": "Caldelas",
                "countryCode": "PT"
            },
            {
                "name": "Calendário",
                "countryCode": "PT"
            },
            {
                "name": "Candoso",
                "countryCode": "PT"
            },
            {
                "name": "Celorico de Basto",
                "countryCode": "PT"
            },
            {
                "name": "Chavão",
                "countryCode": "PT"
            },
            {
                "name": "Cristelo",
                "countryCode": "PT"
            },
            {
                "name": "Esporões",
                "countryCode": "PT"
            },
            {
                "name": "Esposende",
                "countryCode": "PT"
            },
            {
                "name": "Fafe",
                "countryCode": "PT"
            },
            {
                "name": "Faria",
                "countryCode": "PT"
            },
            {
                "name": "Ferreiros",
                "countryCode": "PT"
            },
            {
                "name": "Fradelos",
                "countryCode": "PT"
            },
            {
                "name": "Frossos",
                "countryCode": "PT"
            },
            {
                "name": "Galegos",
                "countryCode": "PT"
            },
            {
                "name": "Gandra",
                "countryCode": "PT"
            },
            {
                "name": "Gondizalves",
                "countryCode": "PT"
            },
            {
                "name": "Gueral",
                "countryCode": "PT"
            },
            {
                "name": "Guimarães",
                "countryCode": "PT"
            },
            {
                "name": "Guisande",
                "countryCode": "PT"
            },
            {
                "name": "Joane",
                "countryCode": "PT"
            },
            {
                "name": "Lago",
                "countryCode": "PT"
            },
            {
                "name": "Lordelo",
                "countryCode": "PT"
            },
            {
                "name": "Macieira de Rates",
                "countryCode": "PT"
            },
            {
                "name": "Marinhas",
                "countryCode": "PT"
            },
            {
                "name": "Mariz",
                "countryCode": "PT"
            },
            {
                "name": "Merelim",
                "countryCode": "PT"
            },
            {
                "name": "Moreira de Conegos",
                "countryCode": "PT"
            },
            {
                "name": "Negreiros",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira",
                "countryCode": "PT"
            },
            {
                "name": "Perelhal",
                "countryCode": "PT"
            },
            {
                "name": "Ponte",
                "countryCode": "PT"
            },
            {
                "name": "Prado",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Lanhoso",
                "countryCode": "PT"
            },
            {
                "name": "Real",
                "countryCode": "PT"
            },
            {
                "name": "Refojos de Basto",
                "countryCode": "PT"
            },
            {
                "name": "Rendufe",
                "countryCode": "PT"
            },
            {
                "name": "Riba de Ave",
                "countryCode": "PT"
            },
            {
                "name": "Ribeirão",
                "countryCode": "PT"
            },
            {
                "name": "Ronfe",
                "countryCode": "PT"
            },
            {
                "name": "Sande",
                "countryCode": "PT"
            },
            {
                "name": "Selho",
                "countryCode": "PT"
            },
            {
                "name": "Semelhe",
                "countryCode": "PT"
            },
            {
                "name": "Serzedelo",
                "countryCode": "PT"
            },
            {
                "name": "Serzedo",
                "countryCode": "PT"
            },
            {
                "name": "Tebosa",
                "countryCode": "PT"
            },
            {
                "name": "Terras de Bouro",
                "countryCode": "PT"
            },
            {
                "name": "Urgeses",
                "countryCode": "PT"
            },
            {
                "name": "Vieira do Minho",
                "countryCode": "PT"
            },
            {
                "name": "Vila Cova",
                "countryCode": "PT"
            },
            {
                "name": "Vila Frescainha",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Famalicão",
                "countryCode": "PT"
            },
            {
                "name": "Vila Seca",
                "countryCode": "PT"
            },
            {
                "name": "Vila Verde",
                "countryCode": "PT"
            },
            {
                "name": "Vilar de Figos",
                "countryCode": "PT"
            },
            {
                "name": "Vilaça",
                "countryCode": "PT"
            },
            {
                "name": "Vizela",
                "countryCode": "PT"
            },
            {
                "name": "Várzea",
                "countryCode": "PT"
            },
            {
                "name": "Alfândega da Fé",
                "countryCode": "PT"
            },
            {
                "name": "Belver",
                "countryCode": "PT"
            },
            {
                "name": "Bragança Municipality",
                "countryCode": "PT"
            },
            {
                "name": "Carrazeda de Anciães",
                "countryCode": "PT"
            },
            {
                "name": "Carrazeda de Ansiães",
                "countryCode": "PT"
            },
            {
                "name": "Freixo de Espada à Cinta",
                "countryCode": "PT"
            },
            {
                "name": "Macedo de Cavaleiros",
                "countryCode": "PT"
            },
            {
                "name": "Miranda do Douro",
                "countryCode": "PT"
            },
            {
                "name": "Mirandela",
                "countryCode": "PT"
            },
            {
                "name": "Mogadouro",
                "countryCode": "PT"
            },
            {
                "name": "Samil",
                "countryCode": "PT"
            },
            {
                "name": "Sendim",
                "countryCode": "PT"
            },
            {
                "name": "Torre de Moncorvo",
                "countryCode": "PT"
            },
            {
                "name": "Vila Flor",
                "countryCode": "PT"
            },
            {
                "name": "Vimioso",
                "countryCode": "PT"
            },
            {
                "name": "Vinhais",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia de Joanes",
                "countryCode": "PT"
            },
            {
                "name": "Belmonte",
                "countryCode": "PT"
            },
            {
                "name": "Castelo Branco",
                "countryCode": "PT"
            },
            {
                "name": "Covilhã",
                "countryCode": "PT"
            },
            {
                "name": "Fundão",
                "countryCode": "PT"
            },
            {
                "name": "Idanha-A-Nova",
                "countryCode": "PT"
            },
            {
                "name": "Oleiros",
                "countryCode": "PT"
            },
            {
                "name": "Penamacor",
                "countryCode": "PT"
            },
            {
                "name": "Proença-a-Nova",
                "countryCode": "PT"
            },
            {
                "name": "Sertã",
                "countryCode": "PT"
            },
            {
                "name": "Teixoso",
                "countryCode": "PT"
            },
            {
                "name": "Vila Velha de Ródão",
                "countryCode": "PT"
            },
            {
                "name": "Vila de Rei",
                "countryCode": "PT"
            },
            {
                "name": "Alfarelos",
                "countryCode": "PT"
            },
            {
                "name": "Alhadas",
                "countryCode": "PT"
            },
            {
                "name": "Ançã",
                "countryCode": "PT"
            },
            {
                "name": "Arazede",
                "countryCode": "PT"
            },
            {
                "name": "Arganil",
                "countryCode": "PT"
            },
            {
                "name": "Assafarge",
                "countryCode": "PT"
            },
            {
                "name": "Buarcos",
                "countryCode": "PT"
            },
            {
                "name": "Cantanhede",
                "countryCode": "PT"
            },
            {
                "name": "Carapinheira",
                "countryCode": "PT"
            },
            {
                "name": "Coimbra",
                "countryCode": "PT"
            },
            {
                "name": "Condeixa-a-Nova",
                "countryCode": "PT"
            },
            {
                "name": "Figueira da Foz",
                "countryCode": "PT"
            },
            {
                "name": "Góis",
                "countryCode": "PT"
            },
            {
                "name": "Lavos",
                "countryCode": "PT"
            },
            {
                "name": "Lorvão",
                "countryCode": "PT"
            },
            {
                "name": "Lousã",
                "countryCode": "PT"
            },
            {
                "name": "Mira",
                "countryCode": "PT"
            },
            {
                "name": "Miranda do Corvo",
                "countryCode": "PT"
            },
            {
                "name": "Montemor-o-Velho",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira do Hospital",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira do Mondego",
                "countryCode": "PT"
            },
            {
                "name": "Pampilhosa da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Penacova",
                "countryCode": "PT"
            },
            {
                "name": "Penela",
                "countryCode": "PT"
            },
            {
                "name": "Poiares",
                "countryCode": "PT"
            },
            {
                "name": "Santa Clara",
                "countryCode": "PT"
            },
            {
                "name": "Santo António dos Olivais",
                "countryCode": "PT"
            },
            {
                "name": "Soure",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro de Alva",
                "countryCode": "PT"
            },
            {
                "name": "Tavarede",
                "countryCode": "PT"
            },
            {
                "name": "Tocha",
                "countryCode": "PT"
            },
            {
                "name": "Travanca",
                "countryCode": "PT"
            },
            {
                "name": "Tábua",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Poiares",
                "countryCode": "PT"
            },
            {
                "name": "Alandroal",
                "countryCode": "PT"
            },
            {
                "name": "Arraiolos",
                "countryCode": "PT"
            },
            {
                "name": "Borba",
                "countryCode": "PT"
            },
            {
                "name": "Estremoz",
                "countryCode": "PT"
            },
            {
                "name": "Montemor-o-Novo",
                "countryCode": "PT"
            },
            {
                "name": "Mora",
                "countryCode": "PT"
            },
            {
                "name": "Mourão",
                "countryCode": "PT"
            },
            {
                "name": "Portel",
                "countryCode": "PT"
            },
            {
                "name": "Redondo",
                "countryCode": "PT"
            },
            {
                "name": "Reguengos de Monsaraz",
                "countryCode": "PT"
            },
            {
                "name": "Vendas Novas",
                "countryCode": "PT"
            },
            {
                "name": "Viana do Alentejo",
                "countryCode": "PT"
            },
            {
                "name": "Vila Viçosa",
                "countryCode": "PT"
            },
            {
                "name": "Évora",
                "countryCode": "PT"
            },
            {
                "name": "Albufeira",
                "countryCode": "PT"
            },
            {
                "name": "Alcantarilha",
                "countryCode": "PT"
            },
            {
                "name": "Alcoutim",
                "countryCode": "PT"
            },
            {
                "name": "Algoz",
                "countryCode": "PT"
            },
            {
                "name": "Aljezur",
                "countryCode": "PT"
            },
            {
                "name": "Almancil",
                "countryCode": "PT"
            },
            {
                "name": "Altura",
                "countryCode": "PT"
            },
            {
                "name": "Alvor",
                "countryCode": "PT"
            },
            {
                "name": "Armação de Pêra",
                "countryCode": "PT"
            },
            {
                "name": "Bensafrim",
                "countryCode": "PT"
            },
            {
                "name": "Boliqueime",
                "countryCode": "PT"
            },
            {
                "name": "Cabanas de Tavira",
                "countryCode": "PT"
            },
            {
                "name": "Carvoeiro",
                "countryCode": "PT"
            },
            {
                "name": "Castro Marim",
                "countryCode": "PT"
            },
            {
                "name": "Conceição",
                "countryCode": "PT"
            },
            {
                "name": "Estói",
                "countryCode": "PT"
            },
            {
                "name": "Estômbar",
                "countryCode": "PT"
            },
            {
                "name": "Faro",
                "countryCode": "PT"
            },
            {
                "name": "Ferragudo",
                "countryCode": "PT"
            },
            {
                "name": "Ferreiras",
                "countryCode": "PT"
            },
            {
                "name": "Fuzeta",
                "countryCode": "PT"
            },
            {
                "name": "Guia",
                "countryCode": "PT"
            },
            {
                "name": "Lagoa",
                "countryCode": "PT"
            },
            {
                "name": "Lagos",
                "countryCode": "PT"
            },
            {
                "name": "Laranjeiro",
                "countryCode": "PT"
            },
            {
                "name": "Loulé",
                "countryCode": "PT"
            },
            {
                "name": "Luz",
                "countryCode": "PT"
            },
            {
                "name": "Manta Rota",
                "countryCode": "PT"
            },
            {
                "name": "Marmelete",
                "countryCode": "PT"
            },
            {
                "name": "Mexilhoeira Grande",
                "countryCode": "PT"
            },
            {
                "name": "Moncarapacho",
                "countryCode": "PT"
            },
            {
                "name": "Monchique",
                "countryCode": "PT"
            },
            {
                "name": "Monte Gordo",
                "countryCode": "PT"
            },
            {
                "name": "Olhos de Água",
                "countryCode": "PT"
            },
            {
                "name": "Olhão",
                "countryCode": "PT"
            },
            {
                "name": "Paderne",
                "countryCode": "PT"
            },
            {
                "name": "Parchal",
                "countryCode": "PT"
            },
            {
                "name": "Porches",
                "countryCode": "PT"
            },
            {
                "name": "Portimão",
                "countryCode": "PT"
            },
            {
                "name": "Pêra",
                "countryCode": "PT"
            },
            {
                "name": "Quarteira",
                "countryCode": "PT"
            },
            {
                "name": "Sagres",
                "countryCode": "PT"
            },
            {
                "name": "Santa Bárbara de Nexe",
                "countryCode": "PT"
            },
            {
                "name": "Santa Luzia",
                "countryCode": "PT"
            },
            {
                "name": "Senhora da Luz",
                "countryCode": "PT"
            },
            {
                "name": "Silves",
                "countryCode": "PT"
            },
            {
                "name": "São Bartolomeu de Messines",
                "countryCode": "PT"
            },
            {
                "name": "São Brás de Alportel",
                "countryCode": "PT"
            },
            {
                "name": "São Marcos da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Tavira",
                "countryCode": "PT"
            },
            {
                "name": "Tunes",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova De Cacela",
                "countryCode": "PT"
            },
            {
                "name": "Vila Real de Santo António",
                "countryCode": "PT"
            },
            {
                "name": "Vila do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Vilamoura",
                "countryCode": "PT"
            },
            {
                "name": "Abrigada",
                "countryCode": "PT"
            },
            {
                "name": "Adão",
                "countryCode": "PT"
            },
            {
                "name": "Agualva",
                "countryCode": "PT"
            },
            {
                "name": "Aguiar da Beira",
                "countryCode": "PT"
            },
            {
                "name": "Ajuda",
                "countryCode": "PT"
            },
            {
                "name": "Albardo",
                "countryCode": "PT"
            },
            {
                "name": "Alcabideche",
                "countryCode": "PT"
            },
            {
                "name": "Alcains",
                "countryCode": "PT"
            },
            {
                "name": "Alcoentre",
                "countryCode": "PT"
            },
            {
                "name": "Alcântara",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Galega da Merceana",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Gavinha",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Nova, Miranda do Douro",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Velha",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia Viçosa",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia da Ponte",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia da Ribeira",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia de Santo António",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Aldeias",
                "countryCode": "PT"
            },
            {
                "name": "Alenquer",
                "countryCode": "PT"
            },
            {
                "name": "Alfaiates",
                "countryCode": "PT"
            },
            {
                "name": "Alfornelos",
                "countryCode": "PT"
            },
            {
                "name": "Alfragide",
                "countryCode": "PT"
            },
            {
                "name": "Algodres",
                "countryCode": "PT"
            },
            {
                "name": "Alguber",
                "countryCode": "PT"
            },
            {
                "name": "Algueirão",
                "countryCode": "PT"
            },
            {
                "name": "Algueirão–Mem Martins",
                "countryCode": "PT"
            },
            {
                "name": "Algés",
                "countryCode": "PT"
            },
            {
                "name": "Alhandra",
                "countryCode": "PT"
            },
            {
                "name": "Almargem",
                "countryCode": "PT"
            },
            {
                "name": "Almargem do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Almeida",
                "countryCode": "PT"
            },
            {
                "name": "Almendra",
                "countryCode": "PT"
            },
            {
                "name": "Almofala",
                "countryCode": "PT"
            },
            {
                "name": "Alto do Pina",
                "countryCode": "PT"
            },
            {
                "name": "Alvalade",
                "countryCode": "PT"
            },
            {
                "name": "Alvendre",
                "countryCode": "PT"
            },
            {
                "name": "Alverca da Beira",
                "countryCode": "PT"
            },
            {
                "name": "Alverca do Ribatejo",
                "countryCode": "PT"
            },
            {
                "name": "Alvoco da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Amadora",
                "countryCode": "PT"
            },
            {
                "name": "Ameixoeira",
                "countryCode": "PT"
            },
            {
                "name": "Amoreira",
                "countryCode": "PT"
            },
            {
                "name": "Anjos",
                "countryCode": "PT"
            },
            {
                "name": "Apelação",
                "countryCode": "PT"
            },
            {
                "name": "Arcozelo",
                "countryCode": "PT"
            },
            {
                "name": "Arranhó",
                "countryCode": "PT"
            },
            {
                "name": "Arrifana",
                "countryCode": "PT"
            },
            {
                "name": "Arruda Dos Vinhos",
                "countryCode": "PT"
            },
            {
                "name": "Atalaia",
                "countryCode": "PT"
            },
            {
                "name": "Aveiras de Baixo",
                "countryCode": "PT"
            },
            {
                "name": "Aveiras de Cima",
                "countryCode": "PT"
            },
            {
                "name": "Aveloso",
                "countryCode": "PT"
            },
            {
                "name": "Avelãs da Ribeira",
                "countryCode": "PT"
            },
            {
                "name": "Azambuja",
                "countryCode": "PT"
            },
            {
                "name": "Azambuja (town)",
                "countryCode": "PT"
            },
            {
                "name": "Azenhas do Mar",
                "countryCode": "PT"
            },
            {
                "name": "Azevo",
                "countryCode": "PT"
            },
            {
                "name": "Azueira",
                "countryCode": "PT"
            },
            {
                "name": "Açores",
                "countryCode": "PT"
            },
            {
                "name": "Baraçal",
                "countryCode": "PT"
            },
            {
                "name": "Barcarena",
                "countryCode": "PT"
            },
            {
                "name": "Barreira",
                "countryCode": "PT"
            },
            {
                "name": "Beato",
                "countryCode": "PT"
            },
            {
                "name": "Belas",
                "countryCode": "PT"
            },
            {
                "name": "Bendada",
                "countryCode": "PT"
            },
            {
                "name": "Benespera",
                "countryCode": "PT"
            },
            {
                "name": "Benfica",
                "countryCode": "PT"
            },
            {
                "name": "Bismula",
                "countryCode": "PT"
            },
            {
                "name": "Bobadela",
                "countryCode": "PT"
            },
            {
                "name": "Bouça Cova",
                "countryCode": "PT"
            },
            {
                "name": "Brandoa",
                "countryCode": "PT"
            },
            {
                "name": "Bucelas",
                "countryCode": "PT"
            },
            {
                "name": "Buraca",
                "countryCode": "PT"
            },
            {
                "name": "Cabanas de Torres",
                "countryCode": "PT"
            },
            {
                "name": "Cabeça",
                "countryCode": "PT"
            },
            {
                "name": "Cacem",
                "countryCode": "PT"
            },
            {
                "name": "Cachoeiras",
                "countryCode": "PT"
            },
            {
                "name": "Cadafais",
                "countryCode": "PT"
            },
            {
                "name": "Cadafaz",
                "countryCode": "PT"
            },
            {
                "name": "Cadaval",
                "countryCode": "PT"
            },
            {
                "name": "Calhandriz",
                "countryCode": "PT"
            },
            {
                "name": "Camarate",
                "countryCode": "PT"
            },
            {
                "name": "Campelos",
                "countryCode": "PT"
            },
            {
                "name": "Campo Grande",
                "countryCode": "PT"
            },
            {
                "name": "Campolide",
                "countryCode": "PT"
            },
            {
                "name": "Caneças",
                "countryCode": "PT"
            },
            {
                "name": "Carapito",
                "countryCode": "PT"
            },
            {
                "name": "Carcavelos",
                "countryCode": "PT"
            },
            {
                "name": "Cardosas",
                "countryCode": "PT"
            },
            {
                "name": "Carmões",
                "countryCode": "PT"
            },
            {
                "name": "Carnaxide",
                "countryCode": "PT"
            },
            {
                "name": "Carnicães",
                "countryCode": "PT"
            },
            {
                "name": "Carnide",
                "countryCode": "PT"
            },
            {
                "name": "Carnota",
                "countryCode": "PT"
            },
            {
                "name": "Carragozela",
                "countryCode": "PT"
            },
            {
                "name": "Carrapichana",
                "countryCode": "PT"
            },
            {
                "name": "Carregado",
                "countryCode": "PT"
            },
            {
                "name": "Carvalhal",
                "countryCode": "PT"
            },
            {
                "name": "Carvoeira",
                "countryCode": "PT"
            },
            {
                "name": "Casal Vasco",
                "countryCode": "PT"
            },
            {
                "name": "Casal de Cambra",
                "countryCode": "PT"
            },
            {
                "name": "Casal de Cinza",
                "countryCode": "PT"
            },
            {
                "name": "Casas do Soeiro",
                "countryCode": "PT"
            },
            {
                "name": "Cascais",
                "countryCode": "PT"
            },
            {
                "name": "Castanheira",
                "countryCode": "PT"
            },
            {
                "name": "Castanheira do Ribatejo",
                "countryCode": "PT"
            },
            {
                "name": "Casteição",
                "countryCode": "PT"
            },
            {
                "name": "Casteleiro",
                "countryCode": "PT"
            },
            {
                "name": "Castelo (Lisbon)",
                "countryCode": "PT"
            },
            {
                "name": "Castelo Bom",
                "countryCode": "PT"
            },
            {
                "name": "Castelo Melhor",
                "countryCode": "PT"
            },
            {
                "name": "Castelo Rodrigo",
                "countryCode": "PT"
            },
            {
                "name": "Cativelos",
                "countryCode": "PT"
            },
            {
                "name": "Cavadoude",
                "countryCode": "PT"
            },
            {
                "name": "Caxias",
                "countryCode": "PT"
            },
            {
                "name": "Cedovim",
                "countryCode": "PT"
            },
            {
                "name": "Celorico da Beira",
                "countryCode": "PT"
            },
            {
                "name": "Cercal",
                "countryCode": "PT"
            },
            {
                "name": "Cerdeira",
                "countryCode": "PT"
            },
            {
                "name": "Cerejo",
                "countryCode": "PT"
            },
            {
                "name": "Charneca",
                "countryCode": "PT"
            },
            {
                "name": "Cheleiros",
                "countryCode": "PT"
            },
            {
                "name": "Chãs",
                "countryCode": "PT"
            },
            {
                "name": "Codesseiro",
                "countryCode": "PT"
            },
            {
                "name": "Cogula",
                "countryCode": "PT"
            },
            {
                "name": "Colares",
                "countryCode": "PT"
            },
            {
                "name": "Coração de Jesus",
                "countryCode": "PT"
            },
            {
                "name": "Coriscada",
                "countryCode": "PT"
            },
            {
                "name": "Cortiçada",
                "countryCode": "PT"
            },
            {
                "name": "Cortiçô",
                "countryCode": "PT"
            },
            {
                "name": "Cortiçô da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Coruche",
                "countryCode": "PT"
            },
            {
                "name": "Corujeira",
                "countryCode": "PT"
            },
            {
                "name": "Cruz Quebrada - Dafundo",
                "countryCode": "PT"
            },
            {
                "name": "Custoias",
                "countryCode": "PT"
            },
            {
                "name": "Cótimos",
                "countryCode": "PT"
            },
            {
                "name": "Damaia",
                "countryCode": "PT"
            },
            {
                "name": "Dois Portos",
                "countryCode": "PT"
            },
            {
                "name": "Dornelas",
                "countryCode": "PT"
            },
            {
                "name": "Eirado",
                "countryCode": "PT"
            },
            {
                "name": "Encarnação",
                "countryCode": "PT"
            },
            {
                "name": "Enxara do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Ericeira",
                "countryCode": "PT"
            },
            {
                "name": "Ervas Tenras",
                "countryCode": "PT"
            },
            {
                "name": "Ervedosa",
                "countryCode": "PT"
            },
            {
                "name": "Escalhão",
                "countryCode": "PT"
            },
            {
                "name": "Estoril",
                "countryCode": "PT"
            },
            {
                "name": "Faia",
                "countryCode": "PT"
            },
            {
                "name": "Falagueira",
                "countryCode": "PT"
            },
            {
                "name": "Famalicão",
                "countryCode": "PT"
            },
            {
                "name": "Famões",
                "countryCode": "PT"
            },
            {
                "name": "Fanhões",
                "countryCode": "PT"
            },
            {
                "name": "Fernão Joanes",
                "countryCode": "PT"
            },
            {
                "name": "Figueira de Castelo Rodrigo",
                "countryCode": "PT"
            },
            {
                "name": "Figueira do Guincho",
                "countryCode": "PT"
            },
            {
                "name": "Figueiros",
                "countryCode": "PT"
            },
            {
                "name": "Figueiró da Granja",
                "countryCode": "PT"
            },
            {
                "name": "Figueiró da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Fiães",
                "countryCode": "PT"
            },
            {
                "name": "Folgosinho",
                "countryCode": "PT"
            },
            {
                "name": "Folhadosa",
                "countryCode": "PT"
            },
            {
                "name": "Fontanelas",
                "countryCode": "PT"
            },
            {
                "name": "Fonte Longa",
                "countryCode": "PT"
            },
            {
                "name": "Forninhos",
                "countryCode": "PT"
            },
            {
                "name": "Forno Telheiro",
                "countryCode": "PT"
            },
            {
                "name": "Fornos de Algodres",
                "countryCode": "PT"
            },
            {
                "name": "Forte da Casa",
                "countryCode": "PT"
            },
            {
                "name": "Freches",
                "countryCode": "PT"
            },
            {
                "name": "Freineda",
                "countryCode": "PT"
            },
            {
                "name": "Freiria",
                "countryCode": "PT"
            },
            {
                "name": "Freixeda do Torrão",
                "countryCode": "PT"
            },
            {
                "name": "Freixedas",
                "countryCode": "PT"
            },
            {
                "name": "Freixo",
                "countryCode": "PT"
            },
            {
                "name": "Freixo de Numão",
                "countryCode": "PT"
            },
            {
                "name": "Frielas",
                "countryCode": "PT"
            },
            {
                "name": "Fóios",
                "countryCode": "PT"
            },
            {
                "name": "Gagos",
                "countryCode": "PT"
            },
            {
                "name": "Girabolhos",
                "countryCode": "PT"
            },
            {
                "name": "Gonçalo",
                "countryCode": "PT"
            },
            {
                "name": "Gonçalo Bocas",
                "countryCode": "PT"
            },
            {
                "name": "Gouveia",
                "countryCode": "PT"
            },
            {
                "name": "Gradil",
                "countryCode": "PT"
            },
            {
                "name": "Gradiz",
                "countryCode": "PT"
            },
            {
                "name": "Granja",
                "countryCode": "PT"
            },
            {
                "name": "Graça",
                "countryCode": "PT"
            },
            {
                "name": "Guarda",
                "countryCode": "PT"
            },
            {
                "name": "Guilheiro",
                "countryCode": "PT"
            },
            {
                "name": "Horta",
                "countryCode": "PT"
            },
            {
                "name": "Igreja Nova",
                "countryCode": "PT"
            },
            {
                "name": "Infias",
                "countryCode": "PT"
            },
            {
                "name": "Jarmelo São Miguel",
                "countryCode": "PT"
            },
            {
                "name": "Jarmelo São Pedro",
                "countryCode": "PT"
            },
            {
                "name": "João Antão",
                "countryCode": "PT"
            },
            {
                "name": "Juncais",
                "countryCode": "PT"
            },
            {
                "name": "Junça",
                "countryCode": "PT"
            },
            {
                "name": "Lagarinhos",
                "countryCode": "PT"
            },
            {
                "name": "Lajeosa",
                "countryCode": "PT"
            },
            {
                "name": "Lajeosa do Mondego",
                "countryCode": "PT"
            },
            {
                "name": "Lajes",
                "countryCode": "PT"
            },
            {
                "name": "Lamas",
                "countryCode": "PT"
            },
            {
                "name": "Lamegal",
                "countryCode": "PT"
            },
            {
                "name": "Lameiras",
                "countryCode": "PT"
            },
            {
                "name": "Lapa",
                "countryCode": "PT"
            },
            {
                "name": "Lapa Dos Dinheiros",
                "countryCode": "PT"
            },
            {
                "name": "Leomil",
                "countryCode": "PT"
            },
            {
                "name": "Linda-a-Velha",
                "countryCode": "PT"
            },
            {
                "name": "Linhares (Celorico da Beira)",
                "countryCode": "PT"
            },
            {
                "name": "Lisbon",
                "countryCode": "PT"
            },
            {
                "name": "Longroiva",
                "countryCode": "PT"
            },
            {
                "name": "Loriga",
                "countryCode": "PT"
            },
            {
                "name": "Loures",
                "countryCode": "PT"
            },
            {
                "name": "Lourinhã",
                "countryCode": "PT"
            },
            {
                "name": "Lousa",
                "countryCode": "PT"
            },
            {
                "name": "Lumiar",
                "countryCode": "PT"
            },
            {
                "name": "Maceira",
                "countryCode": "PT"
            },
            {
                "name": "Madalena",
                "countryCode": "PT"
            },
            {
                "name": "Mafra",
                "countryCode": "PT"
            },
            {
                "name": "Malcata",
                "countryCode": "PT"
            },
            {
                "name": "Malhada Sorda",
                "countryCode": "PT"
            },
            {
                "name": "Malpartida",
                "countryCode": "PT"
            },
            {
                "name": "Malveira",
                "countryCode": "PT"
            },
            {
                "name": "Mangualde da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Manigoto",
                "countryCode": "PT"
            },
            {
                "name": "Manique do Intendente",
                "countryCode": "PT"
            },
            {
                "name": "Manteigas",
                "countryCode": "PT"
            },
            {
                "name": "Manteigas (São Pedro)",
                "countryCode": "PT"
            },
            {
                "name": "Marialva",
                "countryCode": "PT"
            },
            {
                "name": "Marmeleiro",
                "countryCode": "PT"
            },
            {
                "name": "Marteleira",
                "countryCode": "PT"
            },
            {
                "name": "Marvila",
                "countryCode": "PT"
            },
            {
                "name": "Massamá",
                "countryCode": "PT"
            },
            {
                "name": "Mata de Lobos",
                "countryCode": "PT"
            },
            {
                "name": "Matacães",
                "countryCode": "PT"
            },
            {
                "name": "Matança",
                "countryCode": "PT"
            },
            {
                "name": "Maxial",
                "countryCode": "PT"
            },
            {
                "name": "Maçainhas",
                "countryCode": "PT"
            },
            {
                "name": "Maçal do Chão",
                "countryCode": "PT"
            },
            {
                "name": "Maçussa",
                "countryCode": "PT"
            },
            {
                "name": "Meca",
                "countryCode": "PT"
            },
            {
                "name": "Meios",
                "countryCode": "PT"
            },
            {
                "name": "Melo",
                "countryCode": "PT"
            },
            {
                "name": "Mem Martins",
                "countryCode": "PT"
            },
            {
                "name": "Mercês",
                "countryCode": "PT"
            },
            {
                "name": "Mesquitela",
                "countryCode": "PT"
            },
            {
                "name": "Milharado",
                "countryCode": "PT"
            },
            {
                "name": "Mina",
                "countryCode": "PT"
            },
            {
                "name": "Minhocal",
                "countryCode": "PT"
            },
            {
                "name": "Mira-Sintra",
                "countryCode": "PT"
            },
            {
                "name": "Miragaia",
                "countryCode": "PT"
            },
            {
                "name": "Miuzela",
                "countryCode": "PT"
            },
            {
                "name": "Mizarela",
                "countryCode": "PT"
            },
            {
                "name": "Moimenta da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Moimentinha",
                "countryCode": "PT"
            },
            {
                "name": "Moita",
                "countryCode": "PT"
            },
            {
                "name": "Moita dos Ferreiros",
                "countryCode": "PT"
            },
            {
                "name": "Moledo",
                "countryCode": "PT"
            },
            {
                "name": "Monte Abraão",
                "countryCode": "PT"
            },
            {
                "name": "Monte Estoril",
                "countryCode": "PT"
            },
            {
                "name": "Monte Redondo",
                "countryCode": "PT"
            },
            {
                "name": "Montelavar",
                "countryCode": "PT"
            },
            {
                "name": "Moreira de Rei",
                "countryCode": "PT"
            },
            {
                "name": "Moscavide",
                "countryCode": "PT"
            },
            {
                "name": "Murça",
                "countryCode": "PT"
            },
            {
                "name": "Muxagata",
                "countryCode": "PT"
            },
            {
                "name": "Mártires",
                "countryCode": "PT"
            },
            {
                "name": "Mêda",
                "countryCode": "PT"
            },
            {
                "name": "Mós",
                "countryCode": "PT"
            },
            {
                "name": "Nabais",
                "countryCode": "PT"
            },
            {
                "name": "Nave",
                "countryCode": "PT"
            },
            {
                "name": "Nave de Haver",
                "countryCode": "PT"
            },
            {
                "name": "Nespereira",
                "countryCode": "PT"
            },
            {
                "name": "Nossa Senhora de Fátima",
                "countryCode": "PT"
            },
            {
                "name": "Numão",
                "countryCode": "PT"
            },
            {
                "name": "Odivelas",
                "countryCode": "PT"
            },
            {
                "name": "Odivelas Municipality",
                "countryCode": "PT"
            },
            {
                "name": "Oeiras",
                "countryCode": "PT"
            },
            {
                "name": "Oeiras e São Julião da Barra",
                "countryCode": "PT"
            },
            {
                "name": "Olhalvo",
                "countryCode": "PT"
            },
            {
                "name": "Olival de Basto",
                "countryCode": "PT"
            },
            {
                "name": "Olival do Basto",
                "countryCode": "PT"
            },
            {
                "name": "Ota",
                "countryCode": "PT"
            },
            {
                "name": "Outeiro da Cabeça",
                "countryCode": "PT"
            },
            {
                "name": "Outeiro de Gatos",
                "countryCode": "PT"
            },
            {
                "name": "Painho",
                "countryCode": "PT"
            },
            {
                "name": "Pala",
                "countryCode": "PT"
            },
            {
                "name": "Palhais",
                "countryCode": "PT"
            },
            {
                "name": "Panoias de Cima",
                "countryCode": "PT"
            },
            {
                "name": "Parada",
                "countryCode": "PT"
            },
            {
                "name": "Paranhos",
                "countryCode": "PT"
            },
            {
                "name": "Parede",
                "countryCode": "PT"
            },
            {
                "name": "Paço de Arcos",
                "countryCode": "PT"
            },
            {
                "name": "Paços da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Pega",
                "countryCode": "PT"
            },
            {
                "name": "Pena",
                "countryCode": "PT"
            },
            {
                "name": "Pena Lobo",
                "countryCode": "PT"
            },
            {
                "name": "Pena Verde",
                "countryCode": "PT"
            },
            {
                "name": "Penha de França",
                "countryCode": "PT"
            },
            {
                "name": "Penha de Águia",
                "countryCode": "PT"
            },
            {
                "name": "Peral",
                "countryCode": "PT"
            },
            {
                "name": "Pereiro",
                "countryCode": "PT"
            },
            {
                "name": "Pereiro de Palhacana",
                "countryCode": "PT"
            },
            {
                "name": "Pero Pinheiro",
                "countryCode": "PT"
            },
            {
                "name": "Peva",
                "countryCode": "PT"
            },
            {
                "name": "Pinhanços",
                "countryCode": "PT"
            },
            {
                "name": "Pinheiro",
                "countryCode": "PT"
            },
            {
                "name": "Pinhel",
                "countryCode": "PT"
            },
            {
                "name": "Pomares",
                "countryCode": "PT"
            },
            {
                "name": "Ponte do Rol",
                "countryCode": "PT"
            },
            {
                "name": "Pontinha",
                "countryCode": "PT"
            },
            {
                "name": "Portela",
                "countryCode": "PT"
            },
            {
                "name": "Porto Salvo",
                "countryCode": "PT"
            },
            {
                "name": "Porto da Carne",
                "countryCode": "PT"
            },
            {
                "name": "Pousada",
                "countryCode": "PT"
            },
            {
                "name": "Pousafoles do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Poço do Canto",
                "countryCode": "PT"
            },
            {
                "name": "Prados",
                "countryCode": "PT"
            },
            {
                "name": "Prazeres",
                "countryCode": "PT"
            },
            {
                "name": "Prior Velho",
                "countryCode": "PT"
            },
            {
                "name": "Prova",
                "countryCode": "PT"
            },
            {
                "name": "Pêra do Moço",
                "countryCode": "PT"
            },
            {
                "name": "Pêro Moniz",
                "countryCode": "PT"
            },
            {
                "name": "Pínzio",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Santa Iria",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Santo Adrião",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa do Concelho",
                "countryCode": "PT"
            },
            {
                "name": "Quadrazais",
                "countryCode": "PT"
            },
            {
                "name": "Queijas",
                "countryCode": "PT"
            },
            {
                "name": "Queiriz",
                "countryCode": "PT"
            },
            {
                "name": "Queluz",
                "countryCode": "PT"
            },
            {
                "name": "Quintas de São Bartolomeu",
                "countryCode": "PT"
            },
            {
                "name": "Quintã de Pêro Martins",
                "countryCode": "PT"
            },
            {
                "name": "Rabaçal",
                "countryCode": "PT"
            },
            {
                "name": "Ramada",
                "countryCode": "PT"
            },
            {
                "name": "Ramalhal",
                "countryCode": "PT"
            },
            {
                "name": "Ramela",
                "countryCode": "PT"
            },
            {
                "name": "Ranhados",
                "countryCode": "PT"
            },
            {
                "name": "Rapa",
                "countryCode": "PT"
            },
            {
                "name": "Rapoula do Côa",
                "countryCode": "PT"
            },
            {
                "name": "Ratoeira",
                "countryCode": "PT"
            },
            {
                "name": "Reboleira",
                "countryCode": "PT"
            },
            {
                "name": "Reboleiro",
                "countryCode": "PT"
            },
            {
                "name": "Rebolosa",
                "countryCode": "PT"
            },
            {
                "name": "Reguengo Grande",
                "countryCode": "PT"
            },
            {
                "name": "Reigada",
                "countryCode": "PT"
            },
            {
                "name": "Rendo",
                "countryCode": "PT"
            },
            {
                "name": "Ribafria",
                "countryCode": "PT"
            },
            {
                "name": "Ribamar",
                "countryCode": "PT"
            },
            {
                "name": "Ribamondego",
                "countryCode": "PT"
            },
            {
                "name": "Ribeira Dos Carinhos",
                "countryCode": "PT"
            },
            {
                "name": "Rio Torto",
                "countryCode": "PT"
            },
            {
                "name": "Rio de Mel",
                "countryCode": "PT"
            },
            {
                "name": "Rio de Mouro",
                "countryCode": "PT"
            },
            {
                "name": "Rochoso",
                "countryCode": "PT"
            },
            {
                "name": "Runa",
                "countryCode": "PT"
            },
            {
                "name": "Ruvina",
                "countryCode": "PT"
            },
            {
                "name": "Sabugal",
                "countryCode": "PT"
            },
            {
                "name": "Sabugueiro",
                "countryCode": "PT"
            },
            {
                "name": "Sacavém",
                "countryCode": "PT"
            },
            {
                "name": "Sacramento",
                "countryCode": "PT"
            },
            {
                "name": "Safurdão",
                "countryCode": "PT"
            },
            {
                "name": "Salgueirais",
                "countryCode": "PT"
            },
            {
                "name": "Sameice",
                "countryCode": "PT"
            },
            {
                "name": "Sameiro",
                "countryCode": "PT"
            },
            {
                "name": "Sandomil",
                "countryCode": "PT"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "PT"
            },
            {
                "name": "Santa Catarina",
                "countryCode": "PT"
            },
            {
                "name": "Santa Comba",
                "countryCode": "PT"
            },
            {
                "name": "Santa Engrácia",
                "countryCode": "PT"
            },
            {
                "name": "Santa Eufémia",
                "countryCode": "PT"
            },
            {
                "name": "Santa Eulália",
                "countryCode": "PT"
            },
            {
                "name": "Santa Iria da Azóia",
                "countryCode": "PT"
            },
            {
                "name": "Santa Iria de Azoia",
                "countryCode": "PT"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "PT"
            },
            {
                "name": "Santa Justa",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria de Belém",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria do Castelo e São Miguel",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria dos Olivais",
                "countryCode": "PT"
            },
            {
                "name": "Santa Maria e São Miguel",
                "countryCode": "PT"
            },
            {
                "name": "Santa Marinha",
                "countryCode": "PT"
            },
            {
                "name": "Santana da Azinha",
                "countryCode": "PT"
            },
            {
                "name": "Santiago",
                "countryCode": "PT"
            },
            {
                "name": "Santiago dos Velhos",
                "countryCode": "PT"
            },
            {
                "name": "Santo Antão do Tojal",
                "countryCode": "PT"
            },
            {
                "name": "Santo António dos Cavaleiros",
                "countryCode": "PT"
            },
            {
                "name": "Santo Condestável",
                "countryCode": "PT"
            },
            {
                "name": "Santo Estêvão",
                "countryCode": "PT"
            },
            {
                "name": "Santo Estêvão das Galés",
                "countryCode": "PT"
            },
            {
                "name": "Santo Isidoro",
                "countryCode": "PT"
            },
            {
                "name": "Santo Quintino",
                "countryCode": "PT"
            },
            {
                "name": "Santos-o-Velho",
                "countryCode": "PT"
            },
            {
                "name": "Sapataria",
                "countryCode": "PT"
            },
            {
                "name": "Sazes da Beira",
                "countryCode": "PT"
            },
            {
                "name": "Sebadelhe",
                "countryCode": "PT"
            },
            {
                "name": "Sebadelhe da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Seia",
                "countryCode": "PT"
            },
            {
                "name": "Seixas",
                "countryCode": "PT"
            },
            {
                "name": "Seixo do Côa",
                "countryCode": "PT"
            },
            {
                "name": "Sequeira",
                "countryCode": "PT"
            },
            {
                "name": "Sequeiros",
                "countryCode": "PT"
            },
            {
                "name": "Silveira",
                "countryCode": "PT"
            },
            {
                "name": "Sintra",
                "countryCode": "PT"
            },
            {
                "name": "Sintra (town)",
                "countryCode": "PT"
            },
            {
                "name": "Sobral Pichorro",
                "countryCode": "PT"
            },
            {
                "name": "Sobral da Abelheira",
                "countryCode": "PT"
            },
            {
                "name": "Sobral da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Sobral de Monte Agraço",
                "countryCode": "PT"
            },
            {
                "name": "Sobralinho",
                "countryCode": "PT"
            },
            {
                "name": "Socorro",
                "countryCode": "PT"
            },
            {
                "name": "Sortelha",
                "countryCode": "PT"
            },
            {
                "name": "Souro Pires",
                "countryCode": "PT"
            },
            {
                "name": "Souto",
                "countryCode": "PT"
            },
            {
                "name": "Souto Maior",
                "countryCode": "PT"
            },
            {
                "name": "Souto de Aguiar da Beira",
                "countryCode": "PT"
            },
            {
                "name": "São Bartolomeu",
                "countryCode": "PT"
            },
            {
                "name": "São Bartolomeu dos Galegos",
                "countryCode": "PT"
            },
            {
                "name": "São Brás",
                "countryCode": "PT"
            },
            {
                "name": "São Cristóvão e São Lourenço",
                "countryCode": "PT"
            },
            {
                "name": "São Domingos de Benfica",
                "countryCode": "PT"
            },
            {
                "name": "São Domingos de Rana",
                "countryCode": "PT"
            },
            {
                "name": "São Francisco Xavier",
                "countryCode": "PT"
            },
            {
                "name": "São Jorge de Arroios",
                "countryCode": "PT"
            },
            {
                "name": "São José",
                "countryCode": "PT"
            },
            {
                "name": "São João",
                "countryCode": "PT"
            },
            {
                "name": "São João Dos Montes",
                "countryCode": "PT"
            },
            {
                "name": "São João da Talha",
                "countryCode": "PT"
            },
            {
                "name": "São João das Lampas",
                "countryCode": "PT"
            },
            {
                "name": "São João de Brito",
                "countryCode": "PT"
            },
            {
                "name": "São João de Deus",
                "countryCode": "PT"
            },
            {
                "name": "São Julião",
                "countryCode": "PT"
            },
            {
                "name": "São Julião do Tojal",
                "countryCode": "PT"
            },
            {
                "name": "São Mamede",
                "countryCode": "PT"
            },
            {
                "name": "São Marcos",
                "countryCode": "PT"
            },
            {
                "name": "São Martinho",
                "countryCode": "PT"
            },
            {
                "name": "São Miguel",
                "countryCode": "PT"
            },
            {
                "name": "São Miguel da Guarda",
                "countryCode": "PT"
            },
            {
                "name": "São Miguel de Alcainça",
                "countryCode": "PT"
            },
            {
                "name": "São Nicolau",
                "countryCode": "PT"
            },
            {
                "name": "São Paio",
                "countryCode": "PT"
            },
            {
                "name": "São Paulo",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro da Cadeira",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro de Penaferrim",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro de Rio Seco",
                "countryCode": "PT"
            },
            {
                "name": "São Romão",
                "countryCode": "PT"
            },
            {
                "name": "São Sebastião da Pedreira",
                "countryCode": "PT"
            },
            {
                "name": "São Vicente",
                "countryCode": "PT"
            },
            {
                "name": "São Vicente de Fora",
                "countryCode": "PT"
            },
            {
                "name": "Sé",
                "countryCode": "PT"
            },
            {
                "name": "Tamanhos",
                "countryCode": "PT"
            },
            {
                "name": "Teixeira",
                "countryCode": "PT"
            },
            {
                "name": "Terrenho",
                "countryCode": "PT"
            },
            {
                "name": "Terrugem",
                "countryCode": "PT"
            },
            {
                "name": "Torre do Terrenho",
                "countryCode": "PT"
            },
            {
                "name": "Torres",
                "countryCode": "PT"
            },
            {
                "name": "Torres Vedras",
                "countryCode": "PT"
            },
            {
                "name": "Torrozelo",
                "countryCode": "PT"
            },
            {
                "name": "Tourais",
                "countryCode": "PT"
            },
            {
                "name": "Touça",
                "countryCode": "PT"
            },
            {
                "name": "Trancoso",
                "countryCode": "PT"
            },
            {
                "name": "Travancinha",
                "countryCode": "PT"
            },
            {
                "name": "Triana",
                "countryCode": "PT"
            },
            {
                "name": "Trinta",
                "countryCode": "PT"
            },
            {
                "name": "Turcifal",
                "countryCode": "PT"
            },
            {
                "name": "Unhos",
                "countryCode": "PT"
            },
            {
                "name": "Valbom",
                "countryCode": "PT"
            },
            {
                "name": "Valdujo",
                "countryCode": "PT"
            },
            {
                "name": "Vale Flor",
                "countryCode": "PT"
            },
            {
                "name": "Vale da Mula",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Amoreira",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Azares",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Espinho",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Estrela",
                "countryCode": "PT"
            },
            {
                "name": "Vale do Paraíso",
                "countryCode": "PT"
            },
            {
                "name": "Vale do Seixo",
                "countryCode": "PT"
            },
            {
                "name": "Valezim",
                "countryCode": "PT"
            },
            {
                "name": "Valhelhas",
                "countryCode": "PT"
            },
            {
                "name": "Valverde",
                "countryCode": "PT"
            },
            {
                "name": "Vascoveiro",
                "countryCode": "PT"
            },
            {
                "name": "Vela",
                "countryCode": "PT"
            },
            {
                "name": "Velosa",
                "countryCode": "PT"
            },
            {
                "name": "Venda Nova",
                "countryCode": "PT"
            },
            {
                "name": "Venda do Pinheiro",
                "countryCode": "PT"
            },
            {
                "name": "Venteira",
                "countryCode": "PT"
            },
            {
                "name": "Ventosa",
                "countryCode": "PT"
            },
            {
                "name": "Vermelha",
                "countryCode": "PT"
            },
            {
                "name": "Vermiosa",
                "countryCode": "PT"
            },
            {
                "name": "Vialonga",
                "countryCode": "PT"
            },
            {
                "name": "Vide",
                "countryCode": "PT"
            },
            {
                "name": "Vide Entre Vinhas",
                "countryCode": "PT"
            },
            {
                "name": "Videmonte",
                "countryCode": "PT"
            },
            {
                "name": "Vila Boa",
                "countryCode": "PT"
            },
            {
                "name": "Vila Boa do Mondego",
                "countryCode": "PT"
            },
            {
                "name": "Vila Cortês da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Vila Cortês do Mondego",
                "countryCode": "PT"
            },
            {
                "name": "Vila Cova à Coelheira",
                "countryCode": "PT"
            },
            {
                "name": "Vila Fernando",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca das Naves",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca de Xira",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca do Deão",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca do Rosário",
                "countryCode": "PT"
            },
            {
                "name": "Vila Garcia",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova da Rainha",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Foz Côa",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de São Pedro",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Tazem",
                "countryCode": "PT"
            },
            {
                "name": "Vila Ruiva",
                "countryCode": "PT"
            },
            {
                "name": "Vila Soeiro do Chão",
                "countryCode": "PT"
            },
            {
                "name": "Vila Verde",
                "countryCode": "PT"
            },
            {
                "name": "Vila Verde dos Francos",
                "countryCode": "PT"
            },
            {
                "name": "Vila do Touro",
                "countryCode": "PT"
            },
            {
                "name": "Vilar",
                "countryCode": "PT"
            },
            {
                "name": "Vilar Formoso",
                "countryCode": "PT"
            },
            {
                "name": "Vilar Maior",
                "countryCode": "PT"
            },
            {
                "name": "Vilar Torpim",
                "countryCode": "PT"
            },
            {
                "name": "Vilar de Amargo",
                "countryCode": "PT"
            },
            {
                "name": "Vilares",
                "countryCode": "PT"
            },
            {
                "name": "Vimeiro",
                "countryCode": "PT"
            },
            {
                "name": "Vinhó",
                "countryCode": "PT"
            },
            {
                "name": "Várzea de Meruge",
                "countryCode": "PT"
            },
            {
                "name": "Águas Belas",
                "countryCode": "PT"
            },
            {
                "name": "A dos Francos",
                "countryCode": "PT"
            },
            {
                "name": "Alcobaça",
                "countryCode": "PT"
            },
            {
                "name": "Alfeizerão",
                "countryCode": "PT"
            },
            {
                "name": "Aljubarrota",
                "countryCode": "PT"
            },
            {
                "name": "Alvaiázere",
                "countryCode": "PT"
            },
            {
                "name": "Alvorninha",
                "countryCode": "PT"
            },
            {
                "name": "Amor",
                "countryCode": "PT"
            },
            {
                "name": "Ansião",
                "countryCode": "PT"
            },
            {
                "name": "Atouguia da Baleia",
                "countryCode": "PT"
            },
            {
                "name": "Batalha",
                "countryCode": "PT"
            },
            {
                "name": "Benedita",
                "countryCode": "PT"
            },
            {
                "name": "Bombarral",
                "countryCode": "PT"
            },
            {
                "name": "Caldas da Rainha",
                "countryCode": "PT"
            },
            {
                "name": "Caranguejeira",
                "countryCode": "PT"
            },
            {
                "name": "Castanheira de Pêra",
                "countryCode": "PT"
            },
            {
                "name": "Cela",
                "countryCode": "PT"
            },
            {
                "name": "Famalicão",
                "countryCode": "PT"
            },
            {
                "name": "Ferrel",
                "countryCode": "PT"
            },
            {
                "name": "Figueiró Dos Vinhos",
                "countryCode": "PT"
            },
            {
                "name": "Foz do Arelho",
                "countryCode": "PT"
            },
            {
                "name": "Leiria",
                "countryCode": "PT"
            },
            {
                "name": "Louriçal",
                "countryCode": "PT"
            },
            {
                "name": "Maceira",
                "countryCode": "PT"
            },
            {
                "name": "Marinha Grande",
                "countryCode": "PT"
            },
            {
                "name": "Mira",
                "countryCode": "PT"
            },
            {
                "name": "Monte Real",
                "countryCode": "PT"
            },
            {
                "name": "Monte Redondo",
                "countryCode": "PT"
            },
            {
                "name": "Nadadouro",
                "countryCode": "PT"
            },
            {
                "name": "Nazaré",
                "countryCode": "PT"
            },
            {
                "name": "Pataias",
                "countryCode": "PT"
            },
            {
                "name": "Pedrógão Grande",
                "countryCode": "PT"
            },
            {
                "name": "Peniche",
                "countryCode": "PT"
            },
            {
                "name": "Pombal",
                "countryCode": "PT"
            },
            {
                "name": "Porto de Mós",
                "countryCode": "PT"
            },
            {
                "name": "Salir de Matos",
                "countryCode": "PT"
            },
            {
                "name": "Santa Catarina da Serra",
                "countryCode": "PT"
            },
            {
                "name": "Serra de El-Rei",
                "countryCode": "PT"
            },
            {
                "name": "Souto da Carpalhosa",
                "countryCode": "PT"
            },
            {
                "name": "São Martinho do Porto",
                "countryCode": "PT"
            },
            {
                "name": "Turquel",
                "countryCode": "PT"
            },
            {
                "name": "Valado de Frades",
                "countryCode": "PT"
            },
            {
                "name": "Vestiaria",
                "countryCode": "PT"
            },
            {
                "name": "Vidais",
                "countryCode": "PT"
            },
            {
                "name": "Vieira de Leiria",
                "countryCode": "PT"
            },
            {
                "name": "Óbidos",
                "countryCode": "PT"
            },
            {
                "name": "A dos Cunhados",
                "countryCode": "PT"
            },
            {
                "name": "Abrigada",
                "countryCode": "PT"
            },
            {
                "name": "Alcabideche",
                "countryCode": "PT"
            },
            {
                "name": "Alcoentre",
                "countryCode": "PT"
            },
            {
                "name": "Alenquer",
                "countryCode": "PT"
            },
            {
                "name": "Alfragide",
                "countryCode": "PT"
            },
            {
                "name": "Algueirão",
                "countryCode": "PT"
            },
            {
                "name": "Algés",
                "countryCode": "PT"
            },
            {
                "name": "Alhandra",
                "countryCode": "PT"
            },
            {
                "name": "Almargem",
                "countryCode": "PT"
            },
            {
                "name": "Alvalade",
                "countryCode": "PT"
            },
            {
                "name": "Amadora",
                "countryCode": "PT"
            },
            {
                "name": "Apelação",
                "countryCode": "PT"
            },
            {
                "name": "Arranhó",
                "countryCode": "PT"
            },
            {
                "name": "Arruda dos Vinhos",
                "countryCode": "PT"
            },
            {
                "name": "Aveiras de Cima",
                "countryCode": "PT"
            },
            {
                "name": "Azambuja",
                "countryCode": "PT"
            },
            {
                "name": "Barcarena",
                "countryCode": "PT"
            },
            {
                "name": "Beato António",
                "countryCode": "PT"
            },
            {
                "name": "Belas",
                "countryCode": "PT"
            },
            {
                "name": "Benfica",
                "countryCode": "PT"
            },
            {
                "name": "Bobadela",
                "countryCode": "PT"
            },
            {
                "name": "Bucelas",
                "countryCode": "PT"
            },
            {
                "name": "Cabanas de Torres",
                "countryCode": "PT"
            },
            {
                "name": "Cacém",
                "countryCode": "PT"
            },
            {
                "name": "Cadafais",
                "countryCode": "PT"
            },
            {
                "name": "Cadaval",
                "countryCode": "PT"
            },
            {
                "name": "Camarate",
                "countryCode": "PT"
            },
            {
                "name": "Campelos",
                "countryCode": "PT"
            },
            {
                "name": "Caneças",
                "countryCode": "PT"
            },
            {
                "name": "Carcavelos",
                "countryCode": "PT"
            },
            {
                "name": "Carnaxide",
                "countryCode": "PT"
            },
            {
                "name": "Carregado",
                "countryCode": "PT"
            },
            {
                "name": "Cascais",
                "countryCode": "PT"
            },
            {
                "name": "Castanheira do Ribatejo",
                "countryCode": "PT"
            },
            {
                "name": "Caxias",
                "countryCode": "PT"
            },
            {
                "name": "Charneca",
                "countryCode": "PT"
            },
            {
                "name": "Colares",
                "countryCode": "PT"
            },
            {
                "name": "Ericeira",
                "countryCode": "PT"
            },
            {
                "name": "Estoril",
                "countryCode": "PT"
            },
            {
                "name": "Famões",
                "countryCode": "PT"
            },
            {
                "name": "Fontanelas",
                "countryCode": "PT"
            },
            {
                "name": "Linda-a-Velha",
                "countryCode": "PT"
            },
            {
                "name": "Lisbon",
                "countryCode": "PT"
            },
            {
                "name": "Loures",
                "countryCode": "PT"
            },
            {
                "name": "Lourinhã",
                "countryCode": "PT"
            },
            {
                "name": "Mafra",
                "countryCode": "PT"
            },
            {
                "name": "Malveira",
                "countryCode": "PT"
            },
            {
                "name": "Massamá",
                "countryCode": "PT"
            },
            {
                "name": "Meca",
                "countryCode": "PT"
            },
            {
                "name": "Mem Martins",
                "countryCode": "PT"
            },
            {
                "name": "Milharado",
                "countryCode": "PT"
            },
            {
                "name": "Moita dos Ferreiros",
                "countryCode": "PT"
            },
            {
                "name": "Monte Estoril",
                "countryCode": "PT"
            },
            {
                "name": "Moscavide e Portela",
                "countryCode": "PT"
            },
            {
                "name": "Odivelas",
                "countryCode": "PT"
            },
            {
                "name": "Oeiras",
                "countryCode": "PT"
            },
            {
                "name": "Olivais",
                "countryCode": "PT"
            },
            {
                "name": "Olival do Basto",
                "countryCode": "PT"
            },
            {
                "name": "Ota",
                "countryCode": "PT"
            },
            {
                "name": "Parede",
                "countryCode": "PT"
            },
            {
                "name": "Paço de Arcos",
                "countryCode": "PT"
            },
            {
                "name": "Pero Pinheiro",
                "countryCode": "PT"
            },
            {
                "name": "Pontinha",
                "countryCode": "PT"
            },
            {
                "name": "Porto Salvo",
                "countryCode": "PT"
            },
            {
                "name": "Prior Velho",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Santa Iria",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Santo Adrião",
                "countryCode": "PT"
            },
            {
                "name": "Queijas",
                "countryCode": "PT"
            },
            {
                "name": "Queluz",
                "countryCode": "PT"
            },
            {
                "name": "Ramada",
                "countryCode": "PT"
            },
            {
                "name": "Rio de Mouro",
                "countryCode": "PT"
            },
            {
                "name": "Sacavém",
                "countryCode": "PT"
            },
            {
                "name": "Santa Iria da Azóia",
                "countryCode": "PT"
            },
            {
                "name": "Santo Antão do Tojal",
                "countryCode": "PT"
            },
            {
                "name": "Santo Isidoro",
                "countryCode": "PT"
            },
            {
                "name": "Santos-o-Velho",
                "countryCode": "PT"
            },
            {
                "name": "Silveira",
                "countryCode": "PT"
            },
            {
                "name": "Sintra",
                "countryCode": "PT"
            },
            {
                "name": "Sobral de Monte Agraço",
                "countryCode": "PT"
            },
            {
                "name": "Sobralinho",
                "countryCode": "PT"
            },
            {
                "name": "São Bartolomeu",
                "countryCode": "PT"
            },
            {
                "name": "São Domingos de Rana",
                "countryCode": "PT"
            },
            {
                "name": "São João da Talha",
                "countryCode": "PT"
            },
            {
                "name": "São João das Lampas",
                "countryCode": "PT"
            },
            {
                "name": "São João dos Montes",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro da Cadeira",
                "countryCode": "PT"
            },
            {
                "name": "Terrugem",
                "countryCode": "PT"
            },
            {
                "name": "Torres Vedras",
                "countryCode": "PT"
            },
            {
                "name": "Unhos",
                "countryCode": "PT"
            },
            {
                "name": "Venda do Pinheiro",
                "countryCode": "PT"
            },
            {
                "name": "Ventosa",
                "countryCode": "PT"
            },
            {
                "name": "Vialonga",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca de Xira",
                "countryCode": "PT"
            },
            {
                "name": "Vila Verde",
                "countryCode": "PT"
            },
            {
                "name": "Vimeiro",
                "countryCode": "PT"
            },
            {
                "name": "Alter do Chão",
                "countryCode": "PT"
            },
            {
                "name": "Arronches",
                "countryCode": "PT"
            },
            {
                "name": "Atalaia",
                "countryCode": "PT"
            },
            {
                "name": "Avis",
                "countryCode": "PT"
            },
            {
                "name": "Campo Maior",
                "countryCode": "PT"
            },
            {
                "name": "Castelo de Vide",
                "countryCode": "PT"
            },
            {
                "name": "Crato",
                "countryCode": "PT"
            },
            {
                "name": "Elvas",
                "countryCode": "PT"
            },
            {
                "name": "Fronteira",
                "countryCode": "PT"
            },
            {
                "name": "Gavião",
                "countryCode": "PT"
            },
            {
                "name": "Marvão",
                "countryCode": "PT"
            },
            {
                "name": "Monforte",
                "countryCode": "PT"
            },
            {
                "name": "Montargil",
                "countryCode": "PT"
            },
            {
                "name": "Nisa",
                "countryCode": "PT"
            },
            {
                "name": "Ponte de Sor",
                "countryCode": "PT"
            },
            {
                "name": "Portalegre",
                "countryCode": "PT"
            },
            {
                "name": "Santo André",
                "countryCode": "PT"
            },
            {
                "name": "Sousel",
                "countryCode": "PT"
            },
            {
                "name": "Vale da Amoreira",
                "countryCode": "PT"
            },
            {
                "name": "Aguçadoura",
                "countryCode": "PT"
            },
            {
                "name": "Alfena",
                "countryCode": "PT"
            },
            {
                "name": "Alpendurada",
                "countryCode": "PT"
            },
            {
                "name": "Amarante",
                "countryCode": "PT"
            },
            {
                "name": "Amorim",
                "countryCode": "PT"
            },
            {
                "name": "Anta",
                "countryCode": "PT"
            },
            {
                "name": "Arcozelo",
                "countryCode": "PT"
            },
            {
                "name": "Argivai",
                "countryCode": "PT"
            },
            {
                "name": "Aver-o-Mar",
                "countryCode": "PT"
            },
            {
                "name": "Aves",
                "countryCode": "PT"
            },
            {
                "name": "Avintes",
                "countryCode": "PT"
            },
            {
                "name": "Azenha",
                "countryCode": "PT"
            },
            {
                "name": "Baguim do Monte",
                "countryCode": "PT"
            },
            {
                "name": "Baião",
                "countryCode": "PT"
            },
            {
                "name": "Balazar",
                "countryCode": "PT"
            },
            {
                "name": "Baltar",
                "countryCode": "PT"
            },
            {
                "name": "Barrosas",
                "countryCode": "PT"
            },
            {
                "name": "Beiriz de Baixo",
                "countryCode": "PT"
            },
            {
                "name": "Bougado",
                "countryCode": "PT"
            },
            {
                "name": "Campo",
                "countryCode": "PT"
            },
            {
                "name": "Canelas",
                "countryCode": "PT"
            },
            {
                "name": "Canidelo",
                "countryCode": "PT"
            },
            {
                "name": "Carvalhosa",
                "countryCode": "PT"
            },
            {
                "name": "Castelões de Cepeda",
                "countryCode": "PT"
            },
            {
                "name": "Ermesinde",
                "countryCode": "PT"
            },
            {
                "name": "Estela",
                "countryCode": "PT"
            },
            {
                "name": "Felgueiras",
                "countryCode": "PT"
            },
            {
                "name": "Ferreira",
                "countryCode": "PT"
            },
            {
                "name": "Figueiró",
                "countryCode": "PT"
            },
            {
                "name": "Foz do Douro",
                "countryCode": "PT"
            },
            {
                "name": "Foz do Sousa",
                "countryCode": "PT"
            },
            {
                "name": "Frazão",
                "countryCode": "PT"
            },
            {
                "name": "Freamunde",
                "countryCode": "PT"
            },
            {
                "name": "Fânzeres",
                "countryCode": "PT"
            },
            {
                "name": "Gandra",
                "countryCode": "PT"
            },
            {
                "name": "Gemunde",
                "countryCode": "PT"
            },
            {
                "name": "Gondomar",
                "countryCode": "PT"
            },
            {
                "name": "Grijó",
                "countryCode": "PT"
            },
            {
                "name": "Guifões",
                "countryCode": "PT"
            },
            {
                "name": "Gulpilhares",
                "countryCode": "PT"
            },
            {
                "name": "Jovim",
                "countryCode": "PT"
            },
            {
                "name": "Lavra",
                "countryCode": "PT"
            },
            {
                "name": "Leça da Palmeira",
                "countryCode": "PT"
            },
            {
                "name": "Leça do Bailio",
                "countryCode": "PT"
            },
            {
                "name": "Lordelo",
                "countryCode": "PT"
            },
            {
                "name": "Lousada",
                "countryCode": "PT"
            },
            {
                "name": "Lustosa",
                "countryCode": "PT"
            },
            {
                "name": "Madalena",
                "countryCode": "PT"
            },
            {
                "name": "Maia",
                "countryCode": "PT"
            },
            {
                "name": "Marco de Canaveses",
                "countryCode": "PT"
            },
            {
                "name": "Marco de Canavezes",
                "countryCode": "PT"
            },
            {
                "name": "Margaride",
                "countryCode": "PT"
            },
            {
                "name": "Matosinhos",
                "countryCode": "PT"
            },
            {
                "name": "Meinedo",
                "countryCode": "PT"
            },
            {
                "name": "Melres",
                "countryCode": "PT"
            },
            {
                "name": "Milheirós",
                "countryCode": "PT"
            },
            {
                "name": "Mindelo",
                "countryCode": "PT"
            },
            {
                "name": "Moreira",
                "countryCode": "PT"
            },
            {
                "name": "Negrelos",
                "countryCode": "PT"
            },
            {
                "name": "Nogueira",
                "countryCode": "PT"
            },
            {
                "name": "Olival",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira do Douro",
                "countryCode": "PT"
            },
            {
                "name": "Paredes",
                "countryCode": "PT"
            },
            {
                "name": "Paços de Ferreira",
                "countryCode": "PT"
            },
            {
                "name": "Pedroso",
                "countryCode": "PT"
            },
            {
                "name": "Pedrouços",
                "countryCode": "PT"
            },
            {
                "name": "Penafiel",
                "countryCode": "PT"
            },
            {
                "name": "Perafita",
                "countryCode": "PT"
            },
            {
                "name": "Perozinho",
                "countryCode": "PT"
            },
            {
                "name": "Porto",
                "countryCode": "PT"
            },
            {
                "name": "Póvoa de Varzim",
                "countryCode": "PT"
            },
            {
                "name": "Rebordosa",
                "countryCode": "PT"
            },
            {
                "name": "Rebordões",
                "countryCode": "PT"
            },
            {
                "name": "Recarei",
                "countryCode": "PT"
            },
            {
                "name": "Rio Mau",
                "countryCode": "PT"
            },
            {
                "name": "Rio Tinto",
                "countryCode": "PT"
            },
            {
                "name": "Sandim",
                "countryCode": "PT"
            },
            {
                "name": "Santa Cruz do Bispo",
                "countryCode": "PT"
            },
            {
                "name": "Santo Izidoro",
                "countryCode": "PT"
            },
            {
                "name": "Santo Tirso",
                "countryCode": "PT"
            },
            {
                "name": "Senhora da Hora",
                "countryCode": "PT"
            },
            {
                "name": "Sermonde",
                "countryCode": "PT"
            },
            {
                "name": "Seroa",
                "countryCode": "PT"
            },
            {
                "name": "Serzedo",
                "countryCode": "PT"
            },
            {
                "name": "Sobrado",
                "countryCode": "PT"
            },
            {
                "name": "São Félix da Marinha",
                "countryCode": "PT"
            },
            {
                "name": "São Mamede de Infesta",
                "countryCode": "PT"
            },
            {
                "name": "São Miguel do Couto",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro da Cova",
                "countryCode": "PT"
            },
            {
                "name": "São Romão do Coronado",
                "countryCode": "PT"
            },
            {
                "name": "Telões",
                "countryCode": "PT"
            },
            {
                "name": "Trofa",
                "countryCode": "PT"
            },
            {
                "name": "Vairão",
                "countryCode": "PT"
            },
            {
                "name": "Valadares",
                "countryCode": "PT"
            },
            {
                "name": "Valbom",
                "countryCode": "PT"
            },
            {
                "name": "Valongo",
                "countryCode": "PT"
            },
            {
                "name": "Vila Meã",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova da Telha",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Gaia",
                "countryCode": "PT"
            },
            {
                "name": "Vila do Conde",
                "countryCode": "PT"
            },
            {
                "name": "Vilar de Andorinho",
                "countryCode": "PT"
            },
            {
                "name": "Vilar do Paraíso",
                "countryCode": "PT"
            },
            {
                "name": "Vilarinho",
                "countryCode": "PT"
            },
            {
                "name": "Vizela",
                "countryCode": "PT"
            },
            {
                "name": "Água Longa",
                "countryCode": "PT"
            },
            {
                "name": "Águas Santas",
                "countryCode": "PT"
            },
            {
                "name": "Árvore",
                "countryCode": "PT"
            },
            {
                "name": "Abrantes",
                "countryCode": "PT"
            },
            {
                "name": "Alcanede",
                "countryCode": "PT"
            },
            {
                "name": "Alcanena",
                "countryCode": "PT"
            },
            {
                "name": "Alcanhões",
                "countryCode": "PT"
            },
            {
                "name": "Alferrarede",
                "countryCode": "PT"
            },
            {
                "name": "Almeirim",
                "countryCode": "PT"
            },
            {
                "name": "Alpiarça",
                "countryCode": "PT"
            },
            {
                "name": "Amiães de Baixo",
                "countryCode": "PT"
            },
            {
                "name": "Azinhaga",
                "countryCode": "PT"
            },
            {
                "name": "Bemfica",
                "countryCode": "PT"
            },
            {
                "name": "Benavente",
                "countryCode": "PT"
            },
            {
                "name": "Cartaxo",
                "countryCode": "PT"
            },
            {
                "name": "Chamusca",
                "countryCode": "PT"
            },
            {
                "name": "Constância",
                "countryCode": "PT"
            },
            {
                "name": "Coruche",
                "countryCode": "PT"
            },
            {
                "name": "Entroncamento",
                "countryCode": "PT"
            },
            {
                "name": "Fazendas de Almeirim",
                "countryCode": "PT"
            },
            {
                "name": "Ferreira do Zêzere",
                "countryCode": "PT"
            },
            {
                "name": "Fátima",
                "countryCode": "PT"
            },
            {
                "name": "Golegã",
                "countryCode": "PT"
            },
            {
                "name": "Madalena",
                "countryCode": "PT"
            },
            {
                "name": "Marinhais",
                "countryCode": "PT"
            },
            {
                "name": "Mação",
                "countryCode": "PT"
            },
            {
                "name": "Minde",
                "countryCode": "PT"
            },
            {
                "name": "Monsanto",
                "countryCode": "PT"
            },
            {
                "name": "Montalvinho",
                "countryCode": "PT"
            },
            {
                "name": "Montalvo",
                "countryCode": "PT"
            },
            {
                "name": "Ourém",
                "countryCode": "PT"
            },
            {
                "name": "Paialvo",
                "countryCode": "PT"
            },
            {
                "name": "Pego",
                "countryCode": "PT"
            },
            {
                "name": "Pinheiro Grande",
                "countryCode": "PT"
            },
            {
                "name": "Poceirão",
                "countryCode": "PT"
            },
            {
                "name": "Pontével",
                "countryCode": "PT"
            },
            {
                "name": "Praia do Ribatejo",
                "countryCode": "PT"
            },
            {
                "name": "Riachos",
                "countryCode": "PT"
            },
            {
                "name": "Rio Maior",
                "countryCode": "PT"
            },
            {
                "name": "Salvaterra de Magos",
                "countryCode": "PT"
            },
            {
                "name": "Samora Correia",
                "countryCode": "PT"
            },
            {
                "name": "Santa Margarida da Coutada",
                "countryCode": "PT"
            },
            {
                "name": "Santarém",
                "countryCode": "PT"
            },
            {
                "name": "Sardoal",
                "countryCode": "PT"
            },
            {
                "name": "São Miguel de Rio Torto",
                "countryCode": "PT"
            },
            {
                "name": "São Vicente do Paul",
                "countryCode": "PT"
            },
            {
                "name": "Tomar",
                "countryCode": "PT"
            },
            {
                "name": "Torres Novas",
                "countryCode": "PT"
            },
            {
                "name": "Tramagal",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Figueira",
                "countryCode": "PT"
            },
            {
                "name": "Vale de Santarém",
                "countryCode": "PT"
            },
            {
                "name": "Vila Chã de Ourique",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova da Barquinha",
                "countryCode": "PT"
            },
            {
                "name": "Alcochete",
                "countryCode": "PT"
            },
            {
                "name": "Alcácer do Sal",
                "countryCode": "PT"
            },
            {
                "name": "Aldeia de Paio Pires",
                "countryCode": "PT"
            },
            {
                "name": "Alhos Vedros",
                "countryCode": "PT"
            },
            {
                "name": "Almada",
                "countryCode": "PT"
            },
            {
                "name": "Amora",
                "countryCode": "PT"
            },
            {
                "name": "Arrentela",
                "countryCode": "PT"
            },
            {
                "name": "Azeitão",
                "countryCode": "PT"
            },
            {
                "name": "Barreiro",
                "countryCode": "PT"
            },
            {
                "name": "Cacilhas",
                "countryCode": "PT"
            },
            {
                "name": "Caparica",
                "countryCode": "PT"
            },
            {
                "name": "Cercal",
                "countryCode": "PT"
            },
            {
                "name": "Charneca de Caparica",
                "countryCode": "PT"
            },
            {
                "name": "Comporta",
                "countryCode": "PT"
            },
            {
                "name": "Corroios",
                "countryCode": "PT"
            },
            {
                "name": "Costa de Caparica",
                "countryCode": "PT"
            },
            {
                "name": "Grândola",
                "countryCode": "PT"
            },
            {
                "name": "Laranjeira",
                "countryCode": "PT"
            },
            {
                "name": "Lavradio",
                "countryCode": "PT"
            },
            {
                "name": "Moita",
                "countryCode": "PT"
            },
            {
                "name": "Montijo",
                "countryCode": "PT"
            },
            {
                "name": "Palmela",
                "countryCode": "PT"
            },
            {
                "name": "Piedade",
                "countryCode": "PT"
            },
            {
                "name": "Pinhal Novo",
                "countryCode": "PT"
            },
            {
                "name": "Porto Covo",
                "countryCode": "PT"
            },
            {
                "name": "Pragal",
                "countryCode": "PT"
            },
            {
                "name": "Quinta Do Conde",
                "countryCode": "PT"
            },
            {
                "name": "Quinta do Anjo",
                "countryCode": "PT"
            },
            {
                "name": "Rosairinho",
                "countryCode": "PT"
            },
            {
                "name": "Samouco",
                "countryCode": "PT"
            },
            {
                "name": "Santiago do Cacém",
                "countryCode": "PT"
            },
            {
                "name": "Santo André",
                "countryCode": "PT"
            },
            {
                "name": "Santo António da Charneca",
                "countryCode": "PT"
            },
            {
                "name": "Sarilhos Pequenos",
                "countryCode": "PT"
            },
            {
                "name": "Seixal",
                "countryCode": "PT"
            },
            {
                "name": "Sesimbra",
                "countryCode": "PT"
            },
            {
                "name": "Setúbal",
                "countryCode": "PT"
            },
            {
                "name": "Sines",
                "countryCode": "PT"
            },
            {
                "name": "Sobreda",
                "countryCode": "PT"
            },
            {
                "name": "Trafaria",
                "countryCode": "PT"
            },
            {
                "name": "Arcos de Valdevez",
                "countryCode": "PT"
            },
            {
                "name": "Caminha",
                "countryCode": "PT"
            },
            {
                "name": "Melgaço",
                "countryCode": "PT"
            },
            {
                "name": "Monção",
                "countryCode": "PT"
            },
            {
                "name": "Paredes de Coura",
                "countryCode": "PT"
            },
            {
                "name": "Ponte da Barca",
                "countryCode": "PT"
            },
            {
                "name": "Ponte de Lima",
                "countryCode": "PT"
            },
            {
                "name": "Valença",
                "countryCode": "PT"
            },
            {
                "name": "Viana do Castelo",
                "countryCode": "PT"
            },
            {
                "name": "Alijó",
                "countryCode": "PT"
            },
            {
                "name": "Boticas",
                "countryCode": "PT"
            },
            {
                "name": "Chaves",
                "countryCode": "PT"
            },
            {
                "name": "Favaios",
                "countryCode": "PT"
            },
            {
                "name": "Godim",
                "countryCode": "PT"
            },
            {
                "name": "Mesão Frio",
                "countryCode": "PT"
            },
            {
                "name": "Mondim de Basto",
                "countryCode": "PT"
            },
            {
                "name": "Montalegre",
                "countryCode": "PT"
            },
            {
                "name": "Murça",
                "countryCode": "PT"
            },
            {
                "name": "Peso da Régua",
                "countryCode": "PT"
            },
            {
                "name": "Ribeira de Pena",
                "countryCode": "PT"
            },
            {
                "name": "Sabrosa",
                "countryCode": "PT"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PT"
            },
            {
                "name": "Santa Marta de Penaguião",
                "countryCode": "PT"
            },
            {
                "name": "Sobreira",
                "countryCode": "PT"
            },
            {
                "name": "Valpaços",
                "countryCode": "PT"
            },
            {
                "name": "Vila Pouca de Aguiar",
                "countryCode": "PT"
            },
            {
                "name": "Vila Real",
                "countryCode": "PT"
            },
            {
                "name": "Vilela",
                "countryCode": "PT"
            },
            {
                "name": "Abraveses",
                "countryCode": "PT"
            },
            {
                "name": "Armamar",
                "countryCode": "PT"
            },
            {
                "name": "Cabanas de Viriato",
                "countryCode": "PT"
            },
            {
                "name": "Campo",
                "countryCode": "PT"
            },
            {
                "name": "Campo de Besteiros",
                "countryCode": "PT"
            },
            {
                "name": "Canas de Senhorim",
                "countryCode": "PT"
            },
            {
                "name": "Carregal do Sal",
                "countryCode": "PT"
            },
            {
                "name": "Castro Daire",
                "countryCode": "PT"
            },
            {
                "name": "Cinfães",
                "countryCode": "PT"
            },
            {
                "name": "Ervedosa do Douro",
                "countryCode": "PT"
            },
            {
                "name": "Lamego",
                "countryCode": "PT"
            },
            {
                "name": "Mangualde",
                "countryCode": "PT"
            },
            {
                "name": "Moimenta da Beira",
                "countryCode": "PT"
            },
            {
                "name": "Mortágua",
                "countryCode": "PT"
            },
            {
                "name": "Nelas",
                "countryCode": "PT"
            },
            {
                "name": "Oliveira de Frades",
                "countryCode": "PT"
            },
            {
                "name": "Penalva do Castelo",
                "countryCode": "PT"
            },
            {
                "name": "Penedono",
                "countryCode": "PT"
            },
            {
                "name": "Resende",
                "countryCode": "PT"
            },
            {
                "name": "Rio de Loba",
                "countryCode": "PT"
            },
            {
                "name": "Santa Comba Dão",
                "countryCode": "PT"
            },
            {
                "name": "Sernancelhe",
                "countryCode": "PT"
            },
            {
                "name": "Sátão",
                "countryCode": "PT"
            },
            {
                "name": "São João da Pesqueira",
                "countryCode": "PT"
            },
            {
                "name": "São João de Areias",
                "countryCode": "PT"
            },
            {
                "name": "São Pedro do Sul",
                "countryCode": "PT"
            },
            {
                "name": "Tabuaço",
                "countryCode": "PT"
            },
            {
                "name": "Tarouca",
                "countryCode": "PT"
            },
            {
                "name": "Tondela",
                "countryCode": "PT"
            },
            {
                "name": "Treixedo",
                "countryCode": "PT"
            },
            {
                "name": "Vila Nova de Paiva",
                "countryCode": "PT"
            },
            {
                "name": "Viseu",
                "countryCode": "PT"
            },
            {
                "name": "Vouzela",
                "countryCode": "PT"
            },
            {
                "name": "Angra do Heroísmo",
                "countryCode": "PT"
            },
            {
                "name": "Angústias",
                "countryCode": "PT"
            },
            {
                "name": "Arrifes",
                "countryCode": "PT"
            },
            {
                "name": "Biscoitos",
                "countryCode": "PT"
            },
            {
                "name": "Cabouco",
                "countryCode": "PT"
            },
            {
                "name": "Cais do Pico",
                "countryCode": "PT"
            },
            {
                "name": "Calheta",
                "countryCode": "PT"
            },
            {
                "name": "Calheta de São Jorge",
                "countryCode": "PT"
            },
            {
                "name": "Castelo Branco",
                "countryCode": "PT"
            },
            {
                "name": "Corvo",
                "countryCode": "PT"
            },
            {
                "name": "Fajã de Baixo",
                "countryCode": "PT"
            },
            {
                "name": "Fenais da Ajuda",
                "countryCode": "PT"
            },
            {
                "name": "Fenais da Luz",
                "countryCode": "PT"
            },
            {
                "name": "Feteira",
                "countryCode": "PT"
            },
            {
                "name": "Fonte Bastardo",
                "countryCode": "PT"
            },
            {
                "name": "Furnas",
                "countryCode": "PT"
            },
            {
                "name": "Horta",
                "countryCode": "PT"
            },
            {
                "name": "Lagoa",
                "countryCode": "PT"
            },
            {
                "name": "Lajes",
                "countryCode": "PT"
            },
            {
                "name": "Lajes das Flores",
                "countryCode": "PT"
            },
            {
                "name": "Lajes do Pico",
                "countryCode": "PT"
            },
            {
                "name": "Madalena",
                "countryCode": "PT"
            },
            {
                "name": "Maia",
                "countryCode": "PT"
            },
            {
                "name": "Mosteiros",
                "countryCode": "PT"
            },
            {
                "name": "Nordeste",
                "countryCode": "PT"
            },
            {
                "name": "Ponta Delgada",
                "countryCode": "PT"
            },
            {
                "name": "Ponta Garça",
                "countryCode": "PT"
            },
            {
                "name": "Porto Judeu",
                "countryCode": "PT"
            },
            {
                "name": "Porto Martins",
                "countryCode": "PT"
            },
            {
                "name": "Povoação",
                "countryCode": "PT"
            },
            {
                "name": "Praia da Vitória",
                "countryCode": "PT"
            },
            {
                "name": "Rabo de Peixe",
                "countryCode": "PT"
            },
            {
                "name": "Relva",
                "countryCode": "PT"
            },
            {
                "name": "Ribeira Grande",
                "countryCode": "PT"
            },
            {
                "name": "Ribeira Seca",
                "countryCode": "PT"
            },
            {
                "name": "Ribeirinha",
                "countryCode": "PT"
            },
            {
                "name": "Rosto de Cão",
                "countryCode": "PT"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "PT"
            },
            {
                "name": "Santa Cruz da Graciosa",
                "countryCode": "PT"
            },
            {
                "name": "Santa Cruz das Flores",
                "countryCode": "PT"
            },
            {
                "name": "Senhora do Rosário",
                "countryCode": "PT"
            },
            {
                "name": "São Bartolomeu",
                "countryCode": "PT"
            },
            {
                "name": "São Mateus",
                "countryCode": "PT"
            },
            {
                "name": "São Roque",
                "countryCode": "PT"
            },
            {
                "name": "São Roque do Pico",
                "countryCode": "PT"
            },
            {
                "name": "São Sebastião",
                "countryCode": "PT"
            },
            {
                "name": "São Vicente",
                "countryCode": "PT"
            },
            {
                "name": "Velas",
                "countryCode": "PT"
            },
            {
                "name": "Vila Franca do Campo",
                "countryCode": "PT"
            },
            {
                "name": "Vila do Porto",
                "countryCode": "PT"
            },
            {
                "name": "Água de Pau",
                "countryCode": "PT"
            },
            {
                "name": "Arco da Calheta",
                "countryCode": "PT"
            },
            {
                "name": "Boaventura",
                "countryCode": "PT"
            },
            {
                "name": "Calheta",
                "countryCode": "PT"
            },
            {
                "name": "Camacha",
                "countryCode": "PT"
            },
            {
                "name": "Campanário",
                "countryCode": "PT"
            },
            {
                "name": "Canhas",
                "countryCode": "PT"
            },
            {
                "name": "Caniçal",
                "countryCode": "PT"
            },
            {
                "name": "Caniço",
                "countryCode": "PT"
            },
            {
                "name": "Curral das Freiras",
                "countryCode": "PT"
            },
            {
                "name": "Câmara de Lobos",
                "countryCode": "PT"
            },
            {
                "name": "Estreito da Calheta",
                "countryCode": "PT"
            },
            {
                "name": "Faial",
                "countryCode": "PT"
            },
            {
                "name": "Fajã da Ovelha",
                "countryCode": "PT"
            },
            {
                "name": "Funchal",
                "countryCode": "PT"
            },
            {
                "name": "Machico",
                "countryCode": "PT"
            },
            {
                "name": "Nossa Senhora do Monte",
                "countryCode": "PT"
            },
            {
                "name": "Ponta do Sol",
                "countryCode": "PT"
            },
            {
                "name": "Porto Moniz",
                "countryCode": "PT"
            },
            {
                "name": "Porto Santo",
                "countryCode": "PT"
            },
            {
                "name": "Porto da Cruz",
                "countryCode": "PT"
            },
            {
                "name": "Ribeira Brava",
                "countryCode": "PT"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "PT"
            },
            {
                "name": "Santana",
                "countryCode": "PT"
            },
            {
                "name": "São Jorge",
                "countryCode": "PT"
            },
            {
                "name": "São Martinho",
                "countryCode": "PT"
            },
            {
                "name": "São Roque",
                "countryCode": "PT"
            },
            {
                "name": "São Vicente",
                "countryCode": "PT"
            },
            {
                "name": "Água de Pena",
                "countryCode": "PT"
            }
        ]
    },
    {
        "name": "Puerto Rico",
        "phonecode": "+1-787 and 1-939",
        "cities": []
    },
    {
        "name": "Qatar",
        "phonecode": "974",
        "cities": [
            {
                "name": "Doha",
                "countryCode": "QA"
            },
            {
                "name": "Al Ghuwayrīyah",
                "countryCode": "QA"
            },
            {
                "name": "Al Khawr",
                "countryCode": "QA"
            },
            {
                "name": "Ar Ruways",
                "countryCode": "QA"
            },
            {
                "name": "Fuwayriţ",
                "countryCode": "QA"
            },
            {
                "name": "Madīnat ash Shamāl",
                "countryCode": "QA"
            },
            {
                "name": "Ar Rayyān",
                "countryCode": "QA"
            },
            {
                "name": "Umm Bāb",
                "countryCode": "QA"
            },
            {
                "name": "Al Jumaylīyah",
                "countryCode": "QA"
            },
            {
                "name": "Ash Shīḩānīyah",
                "countryCode": "QA"
            },
            {
                "name": "Dukhān",
                "countryCode": "QA"
            },
            {
                "name": "Umm Şalāl Muḩammad",
                "countryCode": "QA"
            },
            {
                "name": "Al Wakrah",
                "countryCode": "QA"
            },
            {
                "name": "Al Wukayr",
                "countryCode": "QA"
            },
            {
                "name": "Musay‘īd",
                "countryCode": "QA"
            }
        ]
    },
    {
        "name": "Reunion",
        "phonecode": "262",
        "cities": []
    },
    {
        "name": "Romania",
        "phonecode": "40",
        "cities": [
            {
                "name": "Abrud",
                "countryCode": "RO"
            },
            {
                "name": "Abrud-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Aiud",
                "countryCode": "RO"
            },
            {
                "name": "Aiudul de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Alba Iulia",
                "countryCode": "RO"
            },
            {
                "name": "Albac",
                "countryCode": "RO"
            },
            {
                "name": "Almaşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Arieşeni",
                "countryCode": "RO"
            },
            {
                "name": "Avram Iancu",
                "countryCode": "RO"
            },
            {
                "name": "Baia de Arieş",
                "countryCode": "RO"
            },
            {
                "name": "Berghin",
                "countryCode": "RO"
            },
            {
                "name": "Biia",
                "countryCode": "RO"
            },
            {
                "name": "Bistra",
                "countryCode": "RO"
            },
            {
                "name": "Blaj",
                "countryCode": "RO"
            },
            {
                "name": "Blideşti",
                "countryCode": "RO"
            },
            {
                "name": "Blândiana",
                "countryCode": "RO"
            },
            {
                "name": "Bucerdea-Grânoasă",
                "countryCode": "RO"
            },
            {
                "name": "Bucium",
                "countryCode": "RO"
            },
            {
                "name": "Bălcaciu",
                "countryCode": "RO"
            },
            {
                "name": "Bărăbanț",
                "countryCode": "RO"
            },
            {
                "name": "Cenade",
                "countryCode": "RO"
            },
            {
                "name": "Cergău Mare",
                "countryCode": "RO"
            },
            {
                "name": "Cergău Mic",
                "countryCode": "RO"
            },
            {
                "name": "Ceru-Băcăinţi",
                "countryCode": "RO"
            },
            {
                "name": "Cetatea de Baltă",
                "countryCode": "RO"
            },
            {
                "name": "Ciugud",
                "countryCode": "RO"
            },
            {
                "name": "Ciumbrud",
                "countryCode": "RO"
            },
            {
                "name": "Ciuruleasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Almaşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arieşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Avram Iancu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berghin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bistra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blândiana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucerdea-Grânoasă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucium",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cenade",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cergău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceru-Băcăinţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cetatea de Baltă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciugud",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciuruleasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cricău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crăciunelu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cut",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câlnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Daia Română",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Doştat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fărău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galda de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârbova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârda de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hopârta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ighiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jidvei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lopadea Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca Mureşului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lupşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Meteş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihalţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mirăslău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mogoş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Noşlac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ocoliş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ohaba",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pianu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Vadului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ponor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poşaga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşia Montană",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşia de Secaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râmetea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râmeţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scărişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sohodol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stremţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâncel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sântimbru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sãliştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălciua",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săsciori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Moţilor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Lungă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vinţu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Întregalde",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şibot",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şona",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şpring",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şugag",
                "countryCode": "RO"
            },
            {
                "name": "Cricău",
                "countryCode": "RO"
            },
            {
                "name": "Crăciunelu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Cugir",
                "countryCode": "RO"
            },
            {
                "name": "Cut",
                "countryCode": "RO"
            },
            {
                "name": "Câlnic",
                "countryCode": "RO"
            },
            {
                "name": "Câmpeni",
                "countryCode": "RO"
            },
            {
                "name": "Daia Română",
                "countryCode": "RO"
            },
            {
                "name": "Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Doştat",
                "countryCode": "RO"
            },
            {
                "name": "Feisa",
                "countryCode": "RO"
            },
            {
                "name": "Feneș",
                "countryCode": "RO"
            },
            {
                "name": "Fărău",
                "countryCode": "RO"
            },
            {
                "name": "Galda de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Gârbova",
                "countryCode": "RO"
            },
            {
                "name": "Gârda de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Hopârta",
                "countryCode": "RO"
            },
            {
                "name": "Horea",
                "countryCode": "RO"
            },
            {
                "name": "Ighiel",
                "countryCode": "RO"
            },
            {
                "name": "Ighiu",
                "countryCode": "RO"
            },
            {
                "name": "Intregalde",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Jidvei",
                "countryCode": "RO"
            },
            {
                "name": "Lancrăm",
                "countryCode": "RO"
            },
            {
                "name": "Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Lopadea Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Mureşului",
                "countryCode": "RO"
            },
            {
                "name": "Lupşa",
                "countryCode": "RO"
            },
            {
                "name": "Meteş",
                "countryCode": "RO"
            },
            {
                "name": "Micești",
                "countryCode": "RO"
            },
            {
                "name": "Mihalţ",
                "countryCode": "RO"
            },
            {
                "name": "Mirăslău",
                "countryCode": "RO"
            },
            {
                "name": "Mogoș",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Aiud",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Alba Iulia",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Blaj",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Sebeş",
                "countryCode": "RO"
            },
            {
                "name": "Noşlac",
                "countryCode": "RO"
            },
            {
                "name": "Oarda",
                "countryCode": "RO"
            },
            {
                "name": "Ocna Mureş",
                "countryCode": "RO"
            },
            {
                "name": "Ocoliş",
                "countryCode": "RO"
            },
            {
                "name": "Ohaba",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Baia de Arieş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cugir",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Câmpeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ocna Mureş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Teiuş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Zlatna",
                "countryCode": "RO"
            },
            {
                "name": "Oraş abrud",
                "countryCode": "RO"
            },
            {
                "name": "Petrești",
                "countryCode": "RO"
            },
            {
                "name": "Pianu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Pianu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Vadului",
                "countryCode": "RO"
            },
            {
                "name": "Poienile-Mogoş",
                "countryCode": "RO"
            },
            {
                "name": "Ponor",
                "countryCode": "RO"
            },
            {
                "name": "Poşaga de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Rimetea",
                "countryCode": "RO"
            },
            {
                "name": "Roşia de Secaş",
                "countryCode": "RO"
            },
            {
                "name": "Roșia Montană",
                "countryCode": "RO"
            },
            {
                "name": "Rădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Războieni-Cetate",
                "countryCode": "RO"
            },
            {
                "name": "Scărişoara",
                "countryCode": "RO"
            },
            {
                "name": "Sebeş",
                "countryCode": "RO"
            },
            {
                "name": "Sebeșel",
                "countryCode": "RO"
            },
            {
                "name": "Sohodol",
                "countryCode": "RO"
            },
            {
                "name": "Stremţ",
                "countryCode": "RO"
            },
            {
                "name": "Sâncel",
                "countryCode": "RO"
            },
            {
                "name": "Sâncrai",
                "countryCode": "RO"
            },
            {
                "name": "Sântimbru",
                "countryCode": "RO"
            },
            {
                "name": "Sălciua de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Săliştea-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Săliștea",
                "countryCode": "RO"
            },
            {
                "name": "Săsciori",
                "countryCode": "RO"
            },
            {
                "name": "Teiuş",
                "countryCode": "RO"
            },
            {
                "name": "Tiur",
                "countryCode": "RO"
            },
            {
                "name": "Uioara de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Moţilor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Lungă Alba Romania",
                "countryCode": "RO"
            },
            {
                "name": "Veseuș",
                "countryCode": "RO"
            },
            {
                "name": "Veza",
                "countryCode": "RO"
            },
            {
                "name": "Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Vinerea",
                "countryCode": "RO"
            },
            {
                "name": "Vințu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Vurpăr",
                "countryCode": "RO"
            },
            {
                "name": "Zlatna",
                "countryCode": "RO"
            },
            {
                "name": "Şibot",
                "countryCode": "RO"
            },
            {
                "name": "Şona",
                "countryCode": "RO"
            },
            {
                "name": "Şpring",
                "countryCode": "RO"
            },
            {
                "name": "Şugag",
                "countryCode": "RO"
            },
            {
                "name": "Șard",
                "countryCode": "RO"
            },
            {
                "name": "Țelna",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Albeştii Pământeni",
                "countryCode": "RO"
            },
            {
                "name": "Albeștii Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Albota",
                "countryCode": "RO"
            },
            {
                "name": "Albota de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Arefu",
                "countryCode": "RO"
            },
            {
                "name": "Argeșelu",
                "countryCode": "RO"
            },
            {
                "name": "Başcov",
                "countryCode": "RO"
            },
            {
                "name": "Beleţi",
                "countryCode": "RO"
            },
            {
                "name": "Berevoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bogaţi",
                "countryCode": "RO"
            },
            {
                "name": "Boteni",
                "countryCode": "RO"
            },
            {
                "name": "Boţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bradu",
                "countryCode": "RO"
            },
            {
                "name": "Brăduleţ",
                "countryCode": "RO"
            },
            {
                "name": "Bucșenești",
                "countryCode": "RO"
            },
            {
                "name": "Bucșenești-Lotași",
                "countryCode": "RO"
            },
            {
                "name": "Budeasa Mică",
                "countryCode": "RO"
            },
            {
                "name": "Bughea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Bughea de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Buzoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bârla",
                "countryCode": "RO"
            },
            {
                "name": "Bârlogu",
                "countryCode": "RO"
            },
            {
                "name": "Băbana",
                "countryCode": "RO"
            },
            {
                "name": "Bădești",
                "countryCode": "RO"
            },
            {
                "name": "Băiculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Băjești",
                "countryCode": "RO"
            },
            {
                "name": "Bălileşti",
                "countryCode": "RO"
            },
            {
                "name": "Bărăști",
                "countryCode": "RO"
            },
            {
                "name": "Capu Piscului",
                "countryCode": "RO"
            },
            {
                "name": "Ceparii Pământeni",
                "countryCode": "RO"
            },
            {
                "name": "Cetăţeni",
                "countryCode": "RO"
            },
            {
                "name": "Cicănești",
                "countryCode": "RO"
            },
            {
                "name": "Ciofrângeni",
                "countryCode": "RO"
            },
            {
                "name": "Ciomăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Ciulnița",
                "countryCode": "RO"
            },
            {
                "name": "Cocu",
                "countryCode": "RO"
            },
            {
                "name": "Colibași",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeștii de Argeș",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeștii de Muscel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albota",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arefu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Başcov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beleţi-Negreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berevoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bradu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăduleţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budeasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bughea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bughea de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buzoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băbana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băiculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cepari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cetăţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cicănești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciofrângeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciomăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cocu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cotmeana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căldăraru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căteasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Davideşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Domneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragoslavele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăganu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dâmbovicioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Godeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hârseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hârtieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leordeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lereşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca Corbului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Merișani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Miceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mioarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Miroşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Morărești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mozăceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moşoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mușătești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mălureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărăcineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negraşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nucşoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oarja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Lacului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienarii de Argeş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienarii de Muscel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Priboieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Recea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rociu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rucăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schitu-Goleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stolnici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stâlpeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălătrucu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săpata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tigveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Uda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ungheni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Danului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Iaşului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mare-Pravăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şuici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Țițești",
                "countryCode": "RO"
            },
            {
                "name": "Conțești",
                "countryCode": "RO"
            },
            {
                "name": "Corbeni",
                "countryCode": "RO"
            },
            {
                "name": "Corbi",
                "countryCode": "RO"
            },
            {
                "name": "Cornățel",
                "countryCode": "RO"
            },
            {
                "name": "Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cotenești",
                "countryCode": "RO"
            },
            {
                "name": "Cotmeana",
                "countryCode": "RO"
            },
            {
                "name": "Coşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cuca",
                "countryCode": "RO"
            },
            {
                "name": "Curtea de Argeş",
                "countryCode": "RO"
            },
            {
                "name": "Câmpulung",
                "countryCode": "RO"
            },
            {
                "name": "Căldăraru",
                "countryCode": "RO"
            },
            {
                "name": "Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Căpățânenii Pământeni",
                "countryCode": "RO"
            },
            {
                "name": "Căteasca",
                "countryCode": "RO"
            },
            {
                "name": "Davideşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobrotu",
                "countryCode": "RO"
            },
            {
                "name": "Domneşti",
                "countryCode": "RO"
            },
            {
                "name": "Dragoslavele",
                "countryCode": "RO"
            },
            {
                "name": "Drăganu-Olteni",
                "countryCode": "RO"
            },
            {
                "name": "Drăghici",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbovicioara",
                "countryCode": "RO"
            },
            {
                "name": "Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Făgetu",
                "countryCode": "RO"
            },
            {
                "name": "Geamăna",
                "countryCode": "RO"
            },
            {
                "name": "Godeni",
                "countryCode": "RO"
            },
            {
                "name": "Golești",
                "countryCode": "RO"
            },
            {
                "name": "Gorganu",
                "countryCode": "RO"
            },
            {
                "name": "Gălășești",
                "countryCode": "RO"
            },
            {
                "name": "Humele",
                "countryCode": "RO"
            },
            {
                "name": "Hârseşti",
                "countryCode": "RO"
            },
            {
                "name": "Hârtieşti",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Jugur",
                "countryCode": "RO"
            },
            {
                "name": "Jupânești",
                "countryCode": "RO"
            },
            {
                "name": "Leicești",
                "countryCode": "RO"
            },
            {
                "name": "Leordeni",
                "countryCode": "RO"
            },
            {
                "name": "Lereşti",
                "countryCode": "RO"
            },
            {
                "name": "Livezeni",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Corbului",
                "countryCode": "RO"
            },
            {
                "name": "Lăzărești",
                "countryCode": "RO"
            },
            {
                "name": "Mareș",
                "countryCode": "RO"
            },
            {
                "name": "Merişani",
                "countryCode": "RO"
            },
            {
                "name": "Miceşti",
                "countryCode": "RO"
            },
            {
                "name": "Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Mioveni",
                "countryCode": "RO"
            },
            {
                "name": "Miroşi",
                "countryCode": "RO"
            },
            {
                "name": "Morăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Mozăceni",
                "countryCode": "RO"
            },
            {
                "name": "Moşoaia",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Curtea de Argeș",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Câmpulung",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Piteşti",
                "countryCode": "RO"
            },
            {
                "name": "Mușătești",
                "countryCode": "RO"
            },
            {
                "name": "Mârţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Mălureni",
                "countryCode": "RO"
            },
            {
                "name": "Mărăcineni",
                "countryCode": "RO"
            },
            {
                "name": "Mățău",
                "countryCode": "RO"
            },
            {
                "name": "Negraşi",
                "countryCode": "RO"
            },
            {
                "name": "Nucşoara",
                "countryCode": "RO"
            },
            {
                "name": "Oarja",
                "countryCode": "RO"
            },
            {
                "name": "Oarja Sat",
                "countryCode": "RO"
            },
            {
                "name": "Oeștii Pământeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Mioveni",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Topoloveni",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Ştefăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Petrești",
                "countryCode": "RO"
            },
            {
                "name": "Pietroşani",
                "countryCode": "RO"
            },
            {
                "name": "Piteşti",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Lacului",
                "countryCode": "RO"
            },
            {
                "name": "Poienari",
                "countryCode": "RO"
            },
            {
                "name": "Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Priboieni",
                "countryCode": "RO"
            },
            {
                "name": "Purcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Pătuleni",
                "countryCode": "RO"
            },
            {
                "name": "Racovița",
                "countryCode": "RO"
            },
            {
                "name": "Recea",
                "countryCode": "RO"
            },
            {
                "name": "Retevoiești",
                "countryCode": "RO"
            },
            {
                "name": "Rociu",
                "countryCode": "RO"
            },
            {
                "name": "Rucăr",
                "countryCode": "RO"
            },
            {
                "name": "Râca",
                "countryCode": "RO"
            },
            {
                "name": "Rădești",
                "countryCode": "RO"
            },
            {
                "name": "Răteşti",
                "countryCode": "RO"
            },
            {
                "name": "Schitu-Goleşti",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Slănic",
                "countryCode": "RO"
            },
            {
                "name": "Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Stolnici",
                "countryCode": "RO"
            },
            {
                "name": "Stroești",
                "countryCode": "RO"
            },
            {
                "name": "Strâmbeni",
                "countryCode": "RO"
            },
            {
                "name": "Stâlpeni",
                "countryCode": "RO"
            },
            {
                "name": "Surdulești",
                "countryCode": "RO"
            },
            {
                "name": "Suseni",
                "countryCode": "RO"
            },
            {
                "name": "Sălătrucu",
                "countryCode": "RO"
            },
            {
                "name": "Teiu",
                "countryCode": "RO"
            },
            {
                "name": "Tigveni",
                "countryCode": "RO"
            },
            {
                "name": "Titeşti",
                "countryCode": "RO"
            },
            {
                "name": "Toplița",
                "countryCode": "RO"
            },
            {
                "name": "Topoloveni",
                "countryCode": "RO"
            },
            {
                "name": "Tutana",
                "countryCode": "RO"
            },
            {
                "name": "Uda",
                "countryCode": "RO"
            },
            {
                "name": "Ungheni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Caselor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Danului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Iaşului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare Pravăț",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare-Podgoria",
                "countryCode": "RO"
            },
            {
                "name": "Valea Popii",
                "countryCode": "RO"
            },
            {
                "name": "Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Vlădești",
                "countryCode": "RO"
            },
            {
                "name": "Voinești",
                "countryCode": "RO"
            },
            {
                "name": "Vrănești",
                "countryCode": "RO"
            },
            {
                "name": "Vulturești",
                "countryCode": "RO"
            },
            {
                "name": "Văleni-Podgoria",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ştefăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Şuici",
                "countryCode": "RO"
            },
            {
                "name": "Șerboeni",
                "countryCode": "RO"
            },
            {
                "name": "Șerbănești",
                "countryCode": "RO"
            },
            {
                "name": "Adea",
                "countryCode": "RO"
            },
            {
                "name": "Agrișu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Almaş",
                "countryCode": "RO"
            },
            {
                "name": "Andrei Șaguna",
                "countryCode": "RO"
            },
            {
                "name": "Apateu",
                "countryCode": "RO"
            },
            {
                "name": "Arad",
                "countryCode": "RO"
            },
            {
                "name": "Archiş",
                "countryCode": "RO"
            },
            {
                "name": "Bata",
                "countryCode": "RO"
            },
            {
                "name": "Beliu",
                "countryCode": "RO"
            },
            {
                "name": "Birchiş",
                "countryCode": "RO"
            },
            {
                "name": "Bocsig",
                "countryCode": "RO"
            },
            {
                "name": "Brazii",
                "countryCode": "RO"
            },
            {
                "name": "Buteni",
                "countryCode": "RO"
            },
            {
                "name": "Bârsa",
                "countryCode": "RO"
            },
            {
                "name": "Bârzava",
                "countryCode": "RO"
            },
            {
                "name": "Caporal Alexa",
                "countryCode": "RO"
            },
            {
                "name": "Cermei",
                "countryCode": "RO"
            },
            {
                "name": "Chereluș",
                "countryCode": "RO"
            },
            {
                "name": "Chesinț",
                "countryCode": "RO"
            },
            {
                "name": "Chier",
                "countryCode": "RO"
            },
            {
                "name": "Chisindia",
                "countryCode": "RO"
            },
            {
                "name": "Chişineu-Criş",
                "countryCode": "RO"
            },
            {
                "name": "Cintei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Almaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apateu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Archiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beliu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Birchiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bocsig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brazii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârsa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârzava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cermei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chisindia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Conop",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Covăsinţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Craiva",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cărand",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dezna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dieci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorobanți",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Felnac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumuşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghioroc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grăniceri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gurahonţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hălmagiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hălmăgel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hăşmaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Igneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iratoşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livada",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Macea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mişca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peregu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petriş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pilu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pleşcuţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuliş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secusigiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seleuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Semlac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sintea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Socodor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săvârşin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tauţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târnova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ususău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vinga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârfurile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vărădia de Mureş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zerind",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zimandu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăbrani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zădăreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zărand",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şagu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şeitin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şepreuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şicula",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şilindia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şimand",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şiria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şiştarovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şofronea",
                "countryCode": "RO"
            },
            {
                "name": "Conop",
                "countryCode": "RO"
            },
            {
                "name": "Covăsinţ",
                "countryCode": "RO"
            },
            {
                "name": "Craiva",
                "countryCode": "RO"
            },
            {
                "name": "Curtici",
                "countryCode": "RO"
            },
            {
                "name": "Cuvin",
                "countryCode": "RO"
            },
            {
                "name": "Cărand",
                "countryCode": "RO"
            },
            {
                "name": "Dezna",
                "countryCode": "RO"
            },
            {
                "name": "Dieci",
                "countryCode": "RO"
            },
            {
                "name": "Dorgoş",
                "countryCode": "RO"
            },
            {
                "name": "Dorobanți",
                "countryCode": "RO"
            },
            {
                "name": "Drauț",
                "countryCode": "RO"
            },
            {
                "name": "Felnac",
                "countryCode": "RO"
            },
            {
                "name": "Frumușeni",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Galșa",
                "countryCode": "RO"
            },
            {
                "name": "Ghioroc",
                "countryCode": "RO"
            },
            {
                "name": "Grăniceri",
                "countryCode": "RO"
            },
            {
                "name": "Gurahonţ",
                "countryCode": "RO"
            },
            {
                "name": "Gurba",
                "countryCode": "RO"
            },
            {
                "name": "Horia",
                "countryCode": "RO"
            },
            {
                "name": "Hălmagiu",
                "countryCode": "RO"
            },
            {
                "name": "Hălmăgel",
                "countryCode": "RO"
            },
            {
                "name": "Hășmaș",
                "countryCode": "RO"
            },
            {
                "name": "Igneşti",
                "countryCode": "RO"
            },
            {
                "name": "Ineu",
                "countryCode": "RO"
            },
            {
                "name": "Iratoşu",
                "countryCode": "RO"
            },
            {
                "name": "Lipova",
                "countryCode": "RO"
            },
            {
                "name": "Livada",
                "countryCode": "RO"
            },
            {
                "name": "Macea",
                "countryCode": "RO"
            },
            {
                "name": "Mailat",
                "countryCode": "RO"
            },
            {
                "name": "Mișca",
                "countryCode": "RO"
            },
            {
                "name": "Moneasa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Arad",
                "countryCode": "RO"
            },
            {
                "name": "Mândruloc",
                "countryCode": "RO"
            },
            {
                "name": "Mâsca",
                "countryCode": "RO"
            },
            {
                "name": "Măderat",
                "countryCode": "RO"
            },
            {
                "name": "Mănăștur",
                "countryCode": "RO"
            },
            {
                "name": "Nadab",
                "countryCode": "RO"
            },
            {
                "name": "Nadăș",
                "countryCode": "RO"
            },
            {
                "name": "Neudorf",
                "countryCode": "RO"
            },
            {
                "name": "Nădlac",
                "countryCode": "RO"
            },
            {
                "name": "Olari",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Chişineu-Criş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Curtici",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ineu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Lipova",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Nãdlac",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Pâncota",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sebiş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sântana",
                "countryCode": "RO"
            },
            {
                "name": "Pecica",
                "countryCode": "RO"
            },
            {
                "name": "Peregu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Peregu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Petriş",
                "countryCode": "RO"
            },
            {
                "name": "Pilu",
                "countryCode": "RO"
            },
            {
                "name": "Pleşcuţa",
                "countryCode": "RO"
            },
            {
                "name": "Pâncota",
                "countryCode": "RO"
            },
            {
                "name": "Păuliş",
                "countryCode": "RO"
            },
            {
                "name": "Radna",
                "countryCode": "RO"
            },
            {
                "name": "Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Sebiş",
                "countryCode": "RO"
            },
            {
                "name": "Secusigiu",
                "countryCode": "RO"
            },
            {
                "name": "Seleuş",
                "countryCode": "RO"
            },
            {
                "name": "Semlac",
                "countryCode": "RO"
            },
            {
                "name": "Sintea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Socodor",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbăteni",
                "countryCode": "RO"
            },
            {
                "name": "Sânmartin",
                "countryCode": "RO"
            },
            {
                "name": "Sânpetru German",
                "countryCode": "RO"
            },
            {
                "name": "Sântana",
                "countryCode": "RO"
            },
            {
                "name": "Săvârşin",
                "countryCode": "RO"
            },
            {
                "name": "Tauţ",
                "countryCode": "RO"
            },
            {
                "name": "Turnu",
                "countryCode": "RO"
            },
            {
                "name": "Târnova",
                "countryCode": "RO"
            },
            {
                "name": "Ususău",
                "countryCode": "RO"
            },
            {
                "name": "Vinga",
                "countryCode": "RO"
            },
            {
                "name": "Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Vârfurile",
                "countryCode": "RO"
            },
            {
                "name": "Vărădia de Mureş",
                "countryCode": "RO"
            },
            {
                "name": "Zerind",
                "countryCode": "RO"
            },
            {
                "name": "Zimandu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Zăbrani",
                "countryCode": "RO"
            },
            {
                "name": "Zădăreni",
                "countryCode": "RO"
            },
            {
                "name": "Zărand",
                "countryCode": "RO"
            },
            {
                "name": "Şagu",
                "countryCode": "RO"
            },
            {
                "name": "Şeitin",
                "countryCode": "RO"
            },
            {
                "name": "Şicula",
                "countryCode": "RO"
            },
            {
                "name": "Şilindia",
                "countryCode": "RO"
            },
            {
                "name": "Şimand",
                "countryCode": "RO"
            },
            {
                "name": "Şiria",
                "countryCode": "RO"
            },
            {
                "name": "Şiştarovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Şofronea",
                "countryCode": "RO"
            },
            {
                "name": "Șepreuș",
                "countryCode": "RO"
            },
            {
                "name": "Șiclău",
                "countryCode": "RO"
            },
            {
                "name": "Țipar",
                "countryCode": "RO"
            },
            {
                "name": "Bucharest",
                "countryCode": "RO"
            },
            {
                "name": "Sector 1",
                "countryCode": "RO"
            },
            {
                "name": "Sector 2",
                "countryCode": "RO"
            },
            {
                "name": "Sector 3",
                "countryCode": "RO"
            },
            {
                "name": "Sector 4",
                "countryCode": "RO"
            },
            {
                "name": "Sector 5",
                "countryCode": "RO"
            },
            {
                "name": "Sector 6",
                "countryCode": "RO"
            },
            {
                "name": "Agăş",
                "countryCode": "RO"
            },
            {
                "name": "Apa Asău",
                "countryCode": "RO"
            },
            {
                "name": "Ardeoani",
                "countryCode": "RO"
            },
            {
                "name": "Arini",
                "countryCode": "RO"
            },
            {
                "name": "Asău",
                "countryCode": "RO"
            },
            {
                "name": "Bacău",
                "countryCode": "RO"
            },
            {
                "name": "Balcani",
                "countryCode": "RO"
            },
            {
                "name": "Barați",
                "countryCode": "RO"
            },
            {
                "name": "Bereşti-Bistriţa",
                "countryCode": "RO"
            },
            {
                "name": "Bereşti-Tazlău",
                "countryCode": "RO"
            },
            {
                "name": "Berești",
                "countryCode": "RO"
            },
            {
                "name": "Berzunţi",
                "countryCode": "RO"
            },
            {
                "name": "Bijghir",
                "countryCode": "RO"
            },
            {
                "name": "Blăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bolovăniș",
                "countryCode": "RO"
            },
            {
                "name": "Bolătău",
                "countryCode": "RO"
            },
            {
                "name": "Brusturoasa",
                "countryCode": "RO"
            },
            {
                "name": "Brătești",
                "countryCode": "RO"
            },
            {
                "name": "Brătila",
                "countryCode": "RO"
            },
            {
                "name": "Buciumi",
                "countryCode": "RO"
            },
            {
                "name": "Bucșești",
                "countryCode": "RO"
            },
            {
                "name": "Buda",
                "countryCode": "RO"
            },
            {
                "name": "Buhoci",
                "countryCode": "RO"
            },
            {
                "name": "Buhuşi",
                "countryCode": "RO"
            },
            {
                "name": "Bârsăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Băcioiu",
                "countryCode": "RO"
            },
            {
                "name": "Caraclău",
                "countryCode": "RO"
            },
            {
                "name": "Caşin",
                "countryCode": "RO"
            },
            {
                "name": "Cerdac",
                "countryCode": "RO"
            },
            {
                "name": "Cernu",
                "countryCode": "RO"
            },
            {
                "name": "Cireșoaia",
                "countryCode": "RO"
            },
            {
                "name": "Ciugheș",
                "countryCode": "RO"
            },
            {
                "name": "Cleja",
                "countryCode": "RO"
            },
            {
                "name": "Coloneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Agăş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ardeoani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Asău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bereşti-Bistriţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bereşti-Tazlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berzunţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brusturoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buciumi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buhoci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârsăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cleja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coloneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coţofăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căiuţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dealu Morii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dofteana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dămieneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Faraoani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Filipeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Filipeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghimeş-Făget",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gioseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glăvăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Văii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găiceana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Helegiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hemeiuşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Huruieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoru Berheciului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Letea Veche",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lipova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luizi-Cãlugãra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Motoşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mânăstirea Caşin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărgineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Odobeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oituz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Onceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Palanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Parava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Parincea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plopana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podu Turcului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poduri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prăjeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pârgăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pârjol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pănceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răchitoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răcăciuni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sascut",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scorţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secuieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Solonţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Strugari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stănişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sănduleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sărata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săuceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tamaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târgu Trotuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urecheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zemeş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan Cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Corbasca",
                "countryCode": "RO"
            },
            {
                "name": "Cotumba",
                "countryCode": "RO"
            },
            {
                "name": "Coţofăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cucuieți",
                "countryCode": "RO"
            },
            {
                "name": "Cârligi",
                "countryCode": "RO"
            },
            {
                "name": "Căiuţi-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Căiuți",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Morii",
                "countryCode": "RO"
            },
            {
                "name": "Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Dofteana",
                "countryCode": "RO"
            },
            {
                "name": "Dragomir",
                "countryCode": "RO"
            },
            {
                "name": "Drăgugești",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dămieneşti",
                "countryCode": "RO"
            },
            {
                "name": "Dămoc",
                "countryCode": "RO"
            },
            {
                "name": "Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Faraoaní",
                "countryCode": "RO"
            },
            {
                "name": "Ferestrău-Oituz",
                "countryCode": "RO"
            },
            {
                "name": "Filipeni",
                "countryCode": "RO"
            },
            {
                "name": "Filipeşti",
                "countryCode": "RO"
            },
            {
                "name": "Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Frumușelu",
                "countryCode": "RO"
            },
            {
                "name": "Fundu Răcăciuni",
                "countryCode": "RO"
            },
            {
                "name": "Făget",
                "countryCode": "RO"
            },
            {
                "name": "Galbeni",
                "countryCode": "RO"
            },
            {
                "name": "Gheorghe Doja",
                "countryCode": "RO"
            },
            {
                "name": "Ghimeş-Făget",
                "countryCode": "RO"
            },
            {
                "name": "Ghimeș",
                "countryCode": "RO"
            },
            {
                "name": "Gioseni",
                "countryCode": "RO"
            },
            {
                "name": "Glăvăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Gura Văii",
                "countryCode": "RO"
            },
            {
                "name": "Gârleni",
                "countryCode": "RO"
            },
            {
                "name": "Gârlenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Găiceana",
                "countryCode": "RO"
            },
            {
                "name": "Găzărie",
                "countryCode": "RO"
            },
            {
                "name": "Helegiu",
                "countryCode": "RO"
            },
            {
                "name": "Hemeiuș",
                "countryCode": "RO"
            },
            {
                "name": "Holt",
                "countryCode": "RO"
            },
            {
                "name": "Horgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Huruiești",
                "countryCode": "RO"
            },
            {
                "name": "Hăghiac",
                "countryCode": "RO"
            },
            {
                "name": "Iteşti",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru Berheciului",
                "countryCode": "RO"
            },
            {
                "name": "Larga",
                "countryCode": "RO"
            },
            {
                "name": "Lespezi",
                "countryCode": "RO"
            },
            {
                "name": "Letea Veche",
                "countryCode": "RO"
            },
            {
                "name": "Lilieci",
                "countryCode": "RO"
            },
            {
                "name": "Lipova",
                "countryCode": "RO"
            },
            {
                "name": "Livezi",
                "countryCode": "RO"
            },
            {
                "name": "Livezi-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Ludași",
                "countryCode": "RO"
            },
            {
                "name": "Luizi-Călugăra",
                "countryCode": "RO"
            },
            {
                "name": "Lăpoș",
                "countryCode": "RO"
            },
            {
                "name": "Moineşti",
                "countryCode": "RO"
            },
            {
                "name": "Motoşeni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Bacãu",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Moineşti",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Oneşti",
                "countryCode": "RO"
            },
            {
                "name": "Mânăstirea Caşin",
                "countryCode": "RO"
            },
            {
                "name": "Măgireşti",
                "countryCode": "RO"
            },
            {
                "name": "Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Mărgineni",
                "countryCode": "RO"
            },
            {
                "name": "Mărgineni-Munteni",
                "countryCode": "RO"
            },
            {
                "name": "Negri",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Odobești",
                "countryCode": "RO"
            },
            {
                "name": "Oituz",
                "countryCode": "RO"
            },
            {
                "name": "Onceşti",
                "countryCode": "RO"
            },
            {
                "name": "Onesti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Buhuşi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Comãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Dãrmãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Slãnic-Moldova",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Târgu Ocna",
                "countryCode": "RO"
            },
            {
                "name": "Orbeni",
                "countryCode": "RO"
            },
            {
                "name": "Orășa",
                "countryCode": "RO"
            },
            {
                "name": "Osebiți",
                "countryCode": "RO"
            },
            {
                "name": "Palanca",
                "countryCode": "RO"
            },
            {
                "name": "Parava",
                "countryCode": "RO"
            },
            {
                "name": "Parincea",
                "countryCode": "RO"
            },
            {
                "name": "Plopana",
                "countryCode": "RO"
            },
            {
                "name": "Plopu",
                "countryCode": "RO"
            },
            {
                "name": "Podu Turcului",
                "countryCode": "RO"
            },
            {
                "name": "Poduri",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Prohozești",
                "countryCode": "RO"
            },
            {
                "name": "Prăjești",
                "countryCode": "RO"
            },
            {
                "name": "Pustiana",
                "countryCode": "RO"
            },
            {
                "name": "Pârgăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Pârjol",
                "countryCode": "RO"
            },
            {
                "name": "Pănceşti",
                "countryCode": "RO"
            },
            {
                "name": "Racova",
                "countryCode": "RO"
            },
            {
                "name": "Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Răchitoasa",
                "countryCode": "RO"
            },
            {
                "name": "Răcăciuni",
                "countryCode": "RO"
            },
            {
                "name": "Răcăuți",
                "countryCode": "RO"
            },
            {
                "name": "Sascut",
                "countryCode": "RO"
            },
            {
                "name": "Sascut-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Schitu Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Scorţeni",
                "countryCode": "RO"
            },
            {
                "name": "Scurta",
                "countryCode": "RO"
            },
            {
                "name": "Secuieni",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Slănic-Moldova",
                "countryCode": "RO"
            },
            {
                "name": "Solonţ",
                "countryCode": "RO"
            },
            {
                "name": "Somușca",
                "countryCode": "RO"
            },
            {
                "name": "Straja",
                "countryCode": "RO"
            },
            {
                "name": "Strugari",
                "countryCode": "RO"
            },
            {
                "name": "Stănişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Sănduleni",
                "countryCode": "RO"
            },
            {
                "name": "Sărata",
                "countryCode": "RO"
            },
            {
                "name": "Săucești",
                "countryCode": "RO"
            },
            {
                "name": "Tamaşi",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Turluianu",
                "countryCode": "RO"
            },
            {
                "name": "Tuta",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Ocna",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Trotuş",
                "countryCode": "RO"
            },
            {
                "name": "Tătărăști",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Urecheşti",
                "countryCode": "RO"
            },
            {
                "name": "Valea Arinilor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Valea lui Ion",
                "countryCode": "RO"
            },
            {
                "name": "Valea Șoșii",
                "countryCode": "RO"
            },
            {
                "name": "Vermești",
                "countryCode": "RO"
            },
            {
                "name": "Verșești",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vultureni",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Zemeş",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan Cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ștefan Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Abram",
                "countryCode": "RO"
            },
            {
                "name": "Abrămuţ",
                "countryCode": "RO"
            },
            {
                "name": "Albiș",
                "countryCode": "RO"
            },
            {
                "name": "Aleşd",
                "countryCode": "RO"
            },
            {
                "name": "Alparea",
                "countryCode": "RO"
            },
            {
                "name": "Auşeu",
                "countryCode": "RO"
            },
            {
                "name": "Avram Iancu",
                "countryCode": "RO"
            },
            {
                "name": "Aştileu",
                "countryCode": "RO"
            },
            {
                "name": "Balc",
                "countryCode": "RO"
            },
            {
                "name": "Batăr",
                "countryCode": "RO"
            },
            {
                "name": "Beiuş",
                "countryCode": "RO"
            },
            {
                "name": "Beznea",
                "countryCode": "RO"
            },
            {
                "name": "Biharia",
                "countryCode": "RO"
            },
            {
                "name": "Bistra",
                "countryCode": "RO"
            },
            {
                "name": "Bogei",
                "countryCode": "RO"
            },
            {
                "name": "Boianu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Borod",
                "countryCode": "RO"
            },
            {
                "name": "Borş",
                "countryCode": "RO"
            },
            {
                "name": "Bratca",
                "countryCode": "RO"
            },
            {
                "name": "Brusturi",
                "countryCode": "RO"
            },
            {
                "name": "Budureasa",
                "countryCode": "RO"
            },
            {
                "name": "Buduslău",
                "countryCode": "RO"
            },
            {
                "name": "Bulz",
                "countryCode": "RO"
            },
            {
                "name": "Bunteşti",
                "countryCode": "RO"
            },
            {
                "name": "Burzuc",
                "countryCode": "RO"
            },
            {
                "name": "Bălnaca",
                "countryCode": "RO"
            },
            {
                "name": "Cadea",
                "countryCode": "RO"
            },
            {
                "name": "Cefa",
                "countryCode": "RO"
            },
            {
                "name": "Ceica",
                "countryCode": "RO"
            },
            {
                "name": "Cetariu",
                "countryCode": "RO"
            },
            {
                "name": "Cherechiu",
                "countryCode": "RO"
            },
            {
                "name": "Cheșereu",
                "countryCode": "RO"
            },
            {
                "name": "Cheț",
                "countryCode": "RO"
            },
            {
                "name": "Chişlaz",
                "countryCode": "RO"
            },
            {
                "name": "Ciumeghiu",
                "countryCode": "RO"
            },
            {
                "name": "Ciutelec",
                "countryCode": "RO"
            },
            {
                "name": "Cociuba Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Abram",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Abrămuţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Auşeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Avram Iancu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aştileu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Batăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Biharia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boianu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bratca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brusturi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budureasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buduslău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bulz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bunteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cefa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cetariu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cherechiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chişlaz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciumeghiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cociuba Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copăcel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Criștioru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curtuişeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curăţele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câmpani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãpâlna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cărpinet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Derna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Diosig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Finiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gepiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Girişu de Criş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hidişelu De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Holod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Husasău de Tinca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ineu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lazuri de Beiuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lugaşu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăzăreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mădăraş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nojorid",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oşorhei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Paleu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pocola",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pomezeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Remetea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răbăgani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Spinuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suplacu de Barcău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâmbăta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâniob",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmartin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânnicolau-Român",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sântandrei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcădat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălacea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălard",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tarcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tileagd",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tinca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Toboliu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tulca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tãmãşeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tărcaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tăuteu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Uileacu de Beiuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârciorog",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şimian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şinteu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şoimi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şuncuiuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţeţchea",
                "countryCode": "RO"
            },
            {
                "name": "Copăcel",
                "countryCode": "RO"
            },
            {
                "name": "Cordău",
                "countryCode": "RO"
            },
            {
                "name": "Criştioru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Cubulcut",
                "countryCode": "RO"
            },
            {
                "name": "Curtuișeni",
                "countryCode": "RO"
            },
            {
                "name": "Curăţele",
                "countryCode": "RO"
            },
            {
                "name": "Cuzap",
                "countryCode": "RO"
            },
            {
                "name": "Câmpani",
                "countryCode": "RO"
            },
            {
                "name": "Câmpani de Pomezeu",
                "countryCode": "RO"
            },
            {
                "name": "Căbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Călacea",
                "countryCode": "RO"
            },
            {
                "name": "Căpâlna",
                "countryCode": "RO"
            },
            {
                "name": "Cărpinet",
                "countryCode": "RO"
            },
            {
                "name": "Derna",
                "countryCode": "RO"
            },
            {
                "name": "Diosig",
                "countryCode": "RO"
            },
            {
                "name": "Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Finiş",
                "countryCode": "RO"
            },
            {
                "name": "Foglaş",
                "countryCode": "RO"
            },
            {
                "name": "Forău",
                "countryCode": "RO"
            },
            {
                "name": "Galoșpetreu",
                "countryCode": "RO"
            },
            {
                "name": "Gepiu",
                "countryCode": "RO"
            },
            {
                "name": "Ghighișeni",
                "countryCode": "RO"
            },
            {
                "name": "Ghiorac",
                "countryCode": "RO"
            },
            {
                "name": "Girișu de Criș",
                "countryCode": "RO"
            },
            {
                "name": "Groși",
                "countryCode": "RO"
            },
            {
                "name": "Gurbediu",
                "countryCode": "RO"
            },
            {
                "name": "Hidişelu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Holod",
                "countryCode": "RO"
            },
            {
                "name": "Hotar",
                "countryCode": "RO"
            },
            {
                "name": "Husasău de Tinca",
                "countryCode": "RO"
            },
            {
                "name": "Ianoșda",
                "countryCode": "RO"
            },
            {
                "name": "Ineu",
                "countryCode": "RO"
            },
            {
                "name": "Lazuri de Beiuş",
                "countryCode": "RO"
            },
            {
                "name": "Livada de Bihor",
                "countryCode": "RO"
            },
            {
                "name": "Lugaşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Luncșoara",
                "countryCode": "RO"
            },
            {
                "name": "Lăzăreni",
                "countryCode": "RO"
            },
            {
                "name": "Marghita",
                "countryCode": "RO"
            },
            {
                "name": "Meziad",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Mișca",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Beiuş",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Marghita",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Oradea",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Salonta",
                "countryCode": "RO"
            },
            {
                "name": "Mădăraş",
                "countryCode": "RO"
            },
            {
                "name": "Măgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Nojorid",
                "countryCode": "RO"
            },
            {
                "name": "Nucet",
                "countryCode": "RO"
            },
            {
                "name": "Olcea",
                "countryCode": "RO"
            },
            {
                "name": "Oradea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Nucet",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sãcueni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Valea Lui Mihai",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Vaşcãu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ştei",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Aleşd",
                "countryCode": "RO"
            },
            {
                "name": "Oșorhei",
                "countryCode": "RO"
            },
            {
                "name": "Paleu",
                "countryCode": "RO"
            },
            {
                "name": "Petreu",
                "countryCode": "RO"
            },
            {
                "name": "Peștiș",
                "countryCode": "RO"
            },
            {
                "name": "Pietroasa",
                "countryCode": "RO"
            },
            {
                "name": "Pocola",
                "countryCode": "RO"
            },
            {
                "name": "Pomezeu",
                "countryCode": "RO"
            },
            {
                "name": "Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Pădurea Neagră",
                "countryCode": "RO"
            },
            {
                "name": "Remetea",
                "countryCode": "RO"
            },
            {
                "name": "Rieni",
                "countryCode": "RO"
            },
            {
                "name": "Roşia",
                "countryCode": "RO"
            },
            {
                "name": "Roșiori",
                "countryCode": "RO"
            },
            {
                "name": "Răbăgani",
                "countryCode": "RO"
            },
            {
                "name": "Salonta",
                "countryCode": "RO"
            },
            {
                "name": "Spinuş",
                "countryCode": "RO"
            },
            {
                "name": "Suplacu de Barcău",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbăta",
                "countryCode": "RO"
            },
            {
                "name": "Sâniob",
                "countryCode": "RO"
            },
            {
                "name": "Sânnicolau Român",
                "countryCode": "RO"
            },
            {
                "name": "Sântandrei",
                "countryCode": "RO"
            },
            {
                "name": "Sântion",
                "countryCode": "RO"
            },
            {
                "name": "Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Sînmartin",
                "countryCode": "RO"
            },
            {
                "name": "Săcueni",
                "countryCode": "RO"
            },
            {
                "name": "Săcădat",
                "countryCode": "RO"
            },
            {
                "name": "Sălacea",
                "countryCode": "RO"
            },
            {
                "name": "Sălard",
                "countryCode": "RO"
            },
            {
                "name": "Talpoș",
                "countryCode": "RO"
            },
            {
                "name": "Tarcea",
                "countryCode": "RO"
            },
            {
                "name": "Tileagd",
                "countryCode": "RO"
            },
            {
                "name": "Tinca",
                "countryCode": "RO"
            },
            {
                "name": "Toboliu",
                "countryCode": "RO"
            },
            {
                "name": "Tulca",
                "countryCode": "RO"
            },
            {
                "name": "Tămașda",
                "countryCode": "RO"
            },
            {
                "name": "Tămășeu",
                "countryCode": "RO"
            },
            {
                "name": "Tărcaia",
                "countryCode": "RO"
            },
            {
                "name": "Tăut",
                "countryCode": "RO"
            },
            {
                "name": "Tăuteu",
                "countryCode": "RO"
            },
            {
                "name": "Tășad",
                "countryCode": "RO"
            },
            {
                "name": "Uileacu de Beiuș",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Valea lui Mihai",
                "countryCode": "RO"
            },
            {
                "name": "Vaşcău",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vintere",
                "countryCode": "RO"
            },
            {
                "name": "Voivozi",
                "countryCode": "RO"
            },
            {
                "name": "Vârciorog",
                "countryCode": "RO"
            },
            {
                "name": "Vășad",
                "countryCode": "RO"
            },
            {
                "name": "Şimian",
                "countryCode": "RO"
            },
            {
                "name": "Şinteu",
                "countryCode": "RO"
            },
            {
                "name": "Şoimi",
                "countryCode": "RO"
            },
            {
                "name": "Şuncuiuş",
                "countryCode": "RO"
            },
            {
                "name": "Șilindru",
                "countryCode": "RO"
            },
            {
                "name": "Ștei",
                "countryCode": "RO"
            },
            {
                "name": "Agrieș",
                "countryCode": "RO"
            },
            {
                "name": "Anieș",
                "countryCode": "RO"
            },
            {
                "name": "Beclean",
                "countryCode": "RO"
            },
            {
                "name": "Bichigiu",
                "countryCode": "RO"
            },
            {
                "name": "Bistriţa",
                "countryCode": "RO"
            },
            {
                "name": "Bistriţa Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Budacu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Budacu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Chiochiş",
                "countryCode": "RO"
            },
            {
                "name": "Chiuza",
                "countryCode": "RO"
            },
            {
                "name": "Ciceu-Giurgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Ciceu-Mihăiești",
                "countryCode": "RO"
            },
            {
                "name": "Cireșoaia",
                "countryCode": "RO"
            },
            {
                "name": "Cociu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bistriţa Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budacu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiochiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiuza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciceu-Giurgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciceu-Mihăieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşbuc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căianu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumitra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumitriţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Feldru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galaţii Bistriţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilva Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilva Mică",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Josenii Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lechinţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Matei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Miceştii de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Milas",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Monor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgura Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măieru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărişelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negrileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nimigea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nuşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Parva",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petru Rareş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana  Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prundu Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rebra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rebrişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rodna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Romuli",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Runcu Salvei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Războeni-Cetate",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Salva",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Silivaşu De Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Spermezeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmihaiu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Telciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tiha Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târlişua",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Uriu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urmeniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zagra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şanţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şieu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şieu-Măgheruş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şieu-Odorhei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şieuţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şintereag",
                "countryCode": "RO"
            },
            {
                "name": "Cormaia",
                "countryCode": "RO"
            },
            {
                "name": "Coşbuc",
                "countryCode": "RO"
            },
            {
                "name": "Cristeștii Ciceului",
                "countryCode": "RO"
            },
            {
                "name": "Căianu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Dobric",
                "countryCode": "RO"
            },
            {
                "name": "Dumitra",
                "countryCode": "RO"
            },
            {
                "name": "Dumitrița",
                "countryCode": "RO"
            },
            {
                "name": "Feldru",
                "countryCode": "RO"
            },
            {
                "name": "Frunzi",
                "countryCode": "RO"
            },
            {
                "name": "Galaţii Bistriţei",
                "countryCode": "RO"
            },
            {
                "name": "Ilva Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ilva Mică",
                "countryCode": "RO"
            },
            {
                "name": "Josenii Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Lechinţa",
                "countryCode": "RO"
            },
            {
                "name": "Leşu",
                "countryCode": "RO"
            },
            {
                "name": "Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Leșului",
                "countryCode": "RO"
            },
            {
                "name": "Matei",
                "countryCode": "RO"
            },
            {
                "name": "Miceştii de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Mijlocenii Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Milaş",
                "countryCode": "RO"
            },
            {
                "name": "Monor",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Bistriţa",
                "countryCode": "RO"
            },
            {
                "name": "Măgura Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Măieru",
                "countryCode": "RO"
            },
            {
                "name": "Mărişelu",
                "countryCode": "RO"
            },
            {
                "name": "Negrilești",
                "countryCode": "RO"
            },
            {
                "name": "Nepos",
                "countryCode": "RO"
            },
            {
                "name": "Nimigea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Nimigea de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Nuşeni",
                "countryCode": "RO"
            },
            {
                "name": "Năsăud",
                "countryCode": "RO"
            },
            {
                "name": "Ocnița",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Beclean",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Nãsãud",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sângeorz-Bãi",
                "countryCode": "RO"
            },
            {
                "name": "Parva",
                "countryCode": "RO"
            },
            {
                "name": "Petriș",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Ilvei",
                "countryCode": "RO"
            },
            {
                "name": "Prundu Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Rebra",
                "countryCode": "RO"
            },
            {
                "name": "Rebrişoara",
                "countryCode": "RO"
            },
            {
                "name": "Reteag",
                "countryCode": "RO"
            },
            {
                "name": "Rodna",
                "countryCode": "RO"
            },
            {
                "name": "Romuli",
                "countryCode": "RO"
            },
            {
                "name": "Runcu Salvei",
                "countryCode": "RO"
            },
            {
                "name": "Răpănaşu",
                "countryCode": "RO"
            },
            {
                "name": "Salva",
                "countryCode": "RO"
            },
            {
                "name": "Silivașu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Spermezeu",
                "countryCode": "RO"
            },
            {
                "name": "Susenii Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Sângeorz-Băi",
                "countryCode": "RO"
            },
            {
                "name": "Sânmihaiu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Teaca",
                "countryCode": "RO"
            },
            {
                "name": "Telciu",
                "countryCode": "RO"
            },
            {
                "name": "Telcișor",
                "countryCode": "RO"
            },
            {
                "name": "Tiha Bârgăului",
                "countryCode": "RO"
            },
            {
                "name": "Tureac",
                "countryCode": "RO"
            },
            {
                "name": "Târlişua",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Uriu",
                "countryCode": "RO"
            },
            {
                "name": "Urmeniş",
                "countryCode": "RO"
            },
            {
                "name": "Valea Borcutului",
                "countryCode": "RO"
            },
            {
                "name": "Viile Tecii",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Zagra",
                "countryCode": "RO"
            },
            {
                "name": "Şieu",
                "countryCode": "RO"
            },
            {
                "name": "Şieu-Măgheruş",
                "countryCode": "RO"
            },
            {
                "name": "Şieu-Odorhei",
                "countryCode": "RO"
            },
            {
                "name": "Şieuţ",
                "countryCode": "RO"
            },
            {
                "name": "Şintereag",
                "countryCode": "RO"
            },
            {
                "name": "Șanț",
                "countryCode": "RO"
            },
            {
                "name": "Baldovinești",
                "countryCode": "RO"
            },
            {
                "name": "Berteştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Bordei Verde",
                "countryCode": "RO"
            },
            {
                "name": "Brăila",
                "countryCode": "RO"
            },
            {
                "name": "Bărăganul",
                "countryCode": "RO"
            },
            {
                "name": "Cazasu",
                "countryCode": "RO"
            },
            {
                "name": "Chiscani",
                "countryCode": "RO"
            },
            {
                "name": "Ciocile",
                "countryCode": "RO"
            },
            {
                "name": "Cireşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berteştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bordei Verde",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărăganul",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cazasu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiscani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cireşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dudeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frecãţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galbenu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gemenele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gropeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jirlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mircea Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movila Miresii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măraşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măxineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Romanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râmnicelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Salcia Tudor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scorţaru Nou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăncuţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Surdila-Greci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Surdila-Găiseanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tichileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tufeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viziru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vădeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăvoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şuţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Corbu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Dedulești",
                "countryCode": "RO"
            },
            {
                "name": "Dudeşti",
                "countryCode": "RO"
            },
            {
                "name": "Frecăţei",
                "countryCode": "RO"
            },
            {
                "name": "Făurei",
                "countryCode": "RO"
            },
            {
                "name": "Galbenu",
                "countryCode": "RO"
            },
            {
                "name": "Gemenele",
                "countryCode": "RO"
            },
            {
                "name": "Gropeni",
                "countryCode": "RO"
            },
            {
                "name": "Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Ianca",
                "countryCode": "RO"
            },
            {
                "name": "Jirlău",
                "countryCode": "RO"
            },
            {
                "name": "Jugureanu",
                "countryCode": "RO"
            },
            {
                "name": "Lacu Sărat",
                "countryCode": "RO"
            },
            {
                "name": "Lanurile",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Mircea Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Movila Miresii",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Brãila",
                "countryCode": "RO"
            },
            {
                "name": "Măraşu",
                "countryCode": "RO"
            },
            {
                "name": "Măxineni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Fãurei",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ianca",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Însurãţei",
                "countryCode": "RO"
            },
            {
                "name": "Perișoru",
                "countryCode": "RO"
            },
            {
                "name": "Plopu",
                "countryCode": "RO"
            },
            {
                "name": "Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Romanu",
                "countryCode": "RO"
            },
            {
                "name": "Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Râmnicelu",
                "countryCode": "RO"
            },
            {
                "name": "Salcia Tudor",
                "countryCode": "RO"
            },
            {
                "name": "Scorţaru Nou",
                "countryCode": "RO"
            },
            {
                "name": "Scorțaru Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Spiru Haret",
                "countryCode": "RO"
            },
            {
                "name": "Stăncuţa",
                "countryCode": "RO"
            },
            {
                "name": "Surdila-Greci",
                "countryCode": "RO"
            },
            {
                "name": "Surdila-Găiseanca",
                "countryCode": "RO"
            },
            {
                "name": "Tichileşti",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Tufeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tătaru",
                "countryCode": "RO"
            },
            {
                "name": "Ulmu",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Urleasca",
                "countryCode": "RO"
            },
            {
                "name": "Valea Cânepii",
                "countryCode": "RO"
            },
            {
                "name": "Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Viziru",
                "countryCode": "RO"
            },
            {
                "name": "Vişani",
                "countryCode": "RO"
            },
            {
                "name": "Vădeni",
                "countryCode": "RO"
            },
            {
                "name": "Zăvoaia",
                "countryCode": "RO"
            },
            {
                "name": "Însurăţei",
                "countryCode": "RO"
            },
            {
                "name": "Şuţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Adășeni",
                "countryCode": "RO"
            },
            {
                "name": "Alba",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Avrămeni",
                "countryCode": "RO"
            },
            {
                "name": "Bajura",
                "countryCode": "RO"
            },
            {
                "name": "Baranca",
                "countryCode": "RO"
            },
            {
                "name": "Blândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bobulești",
                "countryCode": "RO"
            },
            {
                "name": "Botoşani",
                "countryCode": "RO"
            },
            {
                "name": "Brehuiești",
                "countryCode": "RO"
            },
            {
                "name": "Broscăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bucecea",
                "countryCode": "RO"
            },
            {
                "name": "Bucovineni",
                "countryCode": "RO"
            },
            {
                "name": "Băluşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Avrămeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Broscăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băluşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Conceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copălău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corlăteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşula",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coţuşca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dersca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dimăcheni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobârceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Durneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dângeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumuşica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna George Enescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gorbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Havârna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hilişeu-Horia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hlipiceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hudeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ibăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leorda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lozna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Manoleasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihai Eminescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihălăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mileanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mitoc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pomârla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prăjeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păltiniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ripiceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roma",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Româneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rãdãuţi-Prut",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răchiţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răuseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Santa Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăuceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suharău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Todireni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Truşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tudora",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlăsineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vorniceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vorona",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârfu Câmpului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şendriceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştiubieni",
                "countryCode": "RO"
            },
            {
                "name": "Comună Cordăreni",
                "countryCode": "RO"
            },
            {
                "name": "Conceşti",
                "countryCode": "RO"
            },
            {
                "name": "Copălău",
                "countryCode": "RO"
            },
            {
                "name": "Cordăreni",
                "countryCode": "RO"
            },
            {
                "name": "Corlăteni",
                "countryCode": "RO"
            },
            {
                "name": "Corni",
                "countryCode": "RO"
            },
            {
                "name": "Costești",
                "countryCode": "RO"
            },
            {
                "name": "Coţuşca",
                "countryCode": "RO"
            },
            {
                "name": "Coșula",
                "countryCode": "RO"
            },
            {
                "name": "Cristeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cristineşti",
                "countryCode": "RO"
            },
            {
                "name": "Curteşti",
                "countryCode": "RO"
            },
            {
                "name": "Cândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Cătămărești-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Darabani",
                "countryCode": "RO"
            },
            {
                "name": "Dersca",
                "countryCode": "RO"
            },
            {
                "name": "Dimăcheni",
                "countryCode": "RO"
            },
            {
                "name": "Dobârceni",
                "countryCode": "RO"
            },
            {
                "name": "Dorobanți",
                "countryCode": "RO"
            },
            {
                "name": "Dorohoi",
                "countryCode": "RO"
            },
            {
                "name": "Dracșani",
                "countryCode": "RO"
            },
            {
                "name": "Draxini",
                "countryCode": "RO"
            },
            {
                "name": "Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăvița",
                "countryCode": "RO"
            },
            {
                "name": "Dumeni",
                "countryCode": "RO"
            },
            {
                "name": "Durneşti",
                "countryCode": "RO"
            },
            {
                "name": "Dângeni",
                "countryCode": "RO"
            },
            {
                "name": "Flămânzi",
                "countryCode": "RO"
            },
            {
                "name": "Frumuşica",
                "countryCode": "RO"
            },
            {
                "name": "George Enescu",
                "countryCode": "RO"
            },
            {
                "name": "Gorbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Havârna",
                "countryCode": "RO"
            },
            {
                "name": "Hilişeu-Horia",
                "countryCode": "RO"
            },
            {
                "name": "Hlipiceni",
                "countryCode": "RO"
            },
            {
                "name": "Hudeşti",
                "countryCode": "RO"
            },
            {
                "name": "Hăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Iacobeni",
                "countryCode": "RO"
            },
            {
                "name": "Ibăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Icușeni",
                "countryCode": "RO"
            },
            {
                "name": "Ionășeni",
                "countryCode": "RO"
            },
            {
                "name": "Joldești",
                "countryCode": "RO"
            },
            {
                "name": "Leorda",
                "countryCode": "RO"
            },
            {
                "name": "Lozna",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Manoleasa",
                "countryCode": "RO"
            },
            {
                "name": "Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Mihălăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Mileanca",
                "countryCode": "RO"
            },
            {
                "name": "Miorcani",
                "countryCode": "RO"
            },
            {
                "name": "Mitoc",
                "countryCode": "RO"
            },
            {
                "name": "Mlenăuți",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Botoşani",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Dorohoi",
                "countryCode": "RO"
            },
            {
                "name": "Mândrești",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Nicşeni",
                "countryCode": "RO"
            },
            {
                "name": "Oneaga",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bucecea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Darabani",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Flãmânzi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sãveni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ştefãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oroftiana",
                "countryCode": "RO"
            },
            {
                "name": "Orășeni-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Pomârla",
                "countryCode": "RO"
            },
            {
                "name": "Prăjeni",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Păltiniş",
                "countryCode": "RO"
            },
            {
                "name": "Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Ripiceni",
                "countryCode": "RO"
            },
            {
                "name": "Roma",
                "countryCode": "RO"
            },
            {
                "name": "Românești",
                "countryCode": "RO"
            },
            {
                "name": "Roșiori",
                "countryCode": "RO"
            },
            {
                "name": "Răchiţi",
                "countryCode": "RO"
            },
            {
                "name": "Rădeni",
                "countryCode": "RO"
            },
            {
                "name": "Rădăuți-Prut",
                "countryCode": "RO"
            },
            {
                "name": "Răuseni",
                "countryCode": "RO"
            },
            {
                "name": "Santa Mare",
                "countryCode": "RO"
            },
            {
                "name": "Sarafinești",
                "countryCode": "RO"
            },
            {
                "name": "Storeşti",
                "countryCode": "RO"
            },
            {
                "name": "Stroiești",
                "countryCode": "RO"
            },
            {
                "name": "Străteni",
                "countryCode": "RO"
            },
            {
                "name": "Stâncești",
                "countryCode": "RO"
            },
            {
                "name": "Stăuceni",
                "countryCode": "RO"
            },
            {
                "name": "Suharău",
                "countryCode": "RO"
            },
            {
                "name": "Sulița",
                "countryCode": "RO"
            },
            {
                "name": "Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Săveni",
                "countryCode": "RO"
            },
            {
                "name": "Tocileni",
                "countryCode": "RO"
            },
            {
                "name": "Todireni",
                "countryCode": "RO"
            },
            {
                "name": "Truşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Tudora",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni-Jianu",
                "countryCode": "RO"
            },
            {
                "name": "Unţeni",
                "countryCode": "RO"
            },
            {
                "name": "Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeni-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Vlăsineşti",
                "countryCode": "RO"
            },
            {
                "name": "Vorniceni",
                "countryCode": "RO"
            },
            {
                "name": "Vorona",
                "countryCode": "RO"
            },
            {
                "name": "Vorona Teodoru",
                "countryCode": "RO"
            },
            {
                "name": "Vârfu Câmpului",
                "countryCode": "RO"
            },
            {
                "name": "Văculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Zlătunoaia",
                "countryCode": "RO"
            },
            {
                "name": "Şendriceni",
                "countryCode": "RO"
            },
            {
                "name": "Ştefăneşti-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Ştiubieni",
                "countryCode": "RO"
            },
            {
                "name": "Ștefănești",
                "countryCode": "RO"
            },
            {
                "name": "Șupitca",
                "countryCode": "RO"
            },
            {
                "name": "Acriș",
                "countryCode": "RO"
            },
            {
                "name": "Apaţa",
                "countryCode": "RO"
            },
            {
                "name": "Augustin",
                "countryCode": "RO"
            },
            {
                "name": "Beclean",
                "countryCode": "RO"
            },
            {
                "name": "Bod",
                "countryCode": "RO"
            },
            {
                "name": "Bran",
                "countryCode": "RO"
            },
            {
                "name": "Braşov",
                "countryCode": "RO"
            },
            {
                "name": "Budila",
                "countryCode": "RO"
            },
            {
                "name": "Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Caţa",
                "countryCode": "RO"
            },
            {
                "name": "Cincu",
                "countryCode": "RO"
            },
            {
                "name": "Codlea",
                "countryCode": "RO"
            },
            {
                "name": "Colonia Bod",
                "countryCode": "RO"
            },
            {
                "name": "Comana de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comana de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apaţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Augustin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beclean",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bran",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Caţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cincu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crizbav",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăguş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Feldioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fundata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hoghiz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Holbav",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Homorod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hârşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hălchiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hărman",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jibert",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lisa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moieciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mândra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mãieruş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ormeniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Mărului (Brașov)",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prejmer",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pârâu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Recea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâmbăta de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânpetru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teliu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ticuşu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tărlungeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ucea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ungra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vama Buzăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viştea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulcan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şercaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şinca Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şinca Veche",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şoarş",
                "countryCode": "RO"
            },
            {
                "name": "Cristian",
                "countryCode": "RO"
            },
            {
                "name": "Crivina",
                "countryCode": "RO"
            },
            {
                "name": "Crizbav",
                "countryCode": "RO"
            },
            {
                "name": "Cuciulata",
                "countryCode": "RO"
            },
            {
                "name": "Drăguș",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Feldioara",
                "countryCode": "RO"
            },
            {
                "name": "Fișer",
                "countryCode": "RO"
            },
            {
                "name": "Fundata",
                "countryCode": "RO"
            },
            {
                "name": "Făgăraș",
                "countryCode": "RO"
            },
            {
                "name": "Ghimbav",
                "countryCode": "RO"
            },
            {
                "name": "Hoghiz",
                "countryCode": "RO"
            },
            {
                "name": "Holbav",
                "countryCode": "RO"
            },
            {
                "name": "Homorod",
                "countryCode": "RO"
            },
            {
                "name": "Hârşeni",
                "countryCode": "RO"
            },
            {
                "name": "Hălchiu",
                "countryCode": "RO"
            },
            {
                "name": "Hărman",
                "countryCode": "RO"
            },
            {
                "name": "Jibert",
                "countryCode": "RO"
            },
            {
                "name": "Lisa",
                "countryCode": "RO"
            },
            {
                "name": "Moeciu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Moieciu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul  Codlea",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Braşov",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Fãgãraş",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Sãcele",
                "countryCode": "RO"
            },
            {
                "name": "Mândra",
                "countryCode": "RO"
            },
            {
                "name": "Măieruş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ghimbav",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Predeal",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Rupea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Râşnov",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Zãrneşti",
                "countryCode": "RO"
            },
            {
                "name": "Ormeniş",
                "countryCode": "RO"
            },
            {
                "name": "Perșani",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Mărului (Brașov)",
                "countryCode": "RO"
            },
            {
                "name": "Predeal",
                "countryCode": "RO"
            },
            {
                "name": "Prejmer",
                "countryCode": "RO"
            },
            {
                "name": "Purcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Pârâu",
                "countryCode": "RO"
            },
            {
                "name": "Racoș",
                "countryCode": "RO"
            },
            {
                "name": "Recea",
                "countryCode": "RO"
            },
            {
                "name": "Rupea",
                "countryCode": "RO"
            },
            {
                "name": "Râşnov",
                "countryCode": "RO"
            },
            {
                "name": "Sohodol",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbăta de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Sânpetru",
                "countryCode": "RO"
            },
            {
                "name": "Săcele",
                "countryCode": "RO"
            },
            {
                "name": "Teliu",
                "countryCode": "RO"
            },
            {
                "name": "Ticuşu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Tohanu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Tunelu-Teliu",
                "countryCode": "RO"
            },
            {
                "name": "Tărlungeni",
                "countryCode": "RO"
            },
            {
                "name": "Ucea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ucea de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ungra",
                "countryCode": "RO"
            },
            {
                "name": "Vama Buzăului",
                "countryCode": "RO"
            },
            {
                "name": "Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Viscri",
                "countryCode": "RO"
            },
            {
                "name": "Viștea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Voila",
                "countryCode": "RO"
            },
            {
                "name": "Vulcan",
                "countryCode": "RO"
            },
            {
                "name": "Zizin",
                "countryCode": "RO"
            },
            {
                "name": "Zărnești",
                "countryCode": "RO"
            },
            {
                "name": "Şercaia",
                "countryCode": "RO"
            },
            {
                "name": "Şinca Veche",
                "countryCode": "RO"
            },
            {
                "name": "Şoarş",
                "countryCode": "RO"
            },
            {
                "name": "Șimon",
                "countryCode": "RO"
            },
            {
                "name": "Șinca Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Amara",
                "countryCode": "RO"
            },
            {
                "name": "Amaru",
                "countryCode": "RO"
            },
            {
                "name": "Babeţi",
                "countryCode": "RO"
            },
            {
                "name": "Balta Albă",
                "countryCode": "RO"
            },
            {
                "name": "Beceni",
                "countryCode": "RO"
            },
            {
                "name": "Berca",
                "countryCode": "RO"
            },
            {
                "name": "Bisoca",
                "countryCode": "RO"
            },
            {
                "name": "Blăjani",
                "countryCode": "RO"
            },
            {
                "name": "Boboc",
                "countryCode": "RO"
            },
            {
                "name": "Boldu",
                "countryCode": "RO"
            },
            {
                "name": "Bozioru",
                "countryCode": "RO"
            },
            {
                "name": "Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Brădeanu",
                "countryCode": "RO"
            },
            {
                "name": "Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Buda",
                "countryCode": "RO"
            },
            {
                "name": "Burueneşti",
                "countryCode": "RO"
            },
            {
                "name": "Buzău",
                "countryCode": "RO"
            },
            {
                "name": "Bâsca Chiojdului",
                "countryCode": "RO"
            },
            {
                "name": "Bâsca Rozilei",
                "countryCode": "RO"
            },
            {
                "name": "Bâscenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Bâscenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Băbeni",
                "countryCode": "RO"
            },
            {
                "name": "Bălăceanu",
                "countryCode": "RO"
            },
            {
                "name": "C.a. Rosetti",
                "countryCode": "RO"
            },
            {
                "name": "Calvini",
                "countryCode": "RO"
            },
            {
                "name": "Cașoca",
                "countryCode": "RO"
            },
            {
                "name": "Cernăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Chiliile",
                "countryCode": "RO"
            },
            {
                "name": "Chiojdu",
                "countryCode": "RO"
            },
            {
                "name": "Cilibia",
                "countryCode": "RO"
            },
            {
                "name": "Cislău",
                "countryCode": "RO"
            },
            {
                "name": "Cochirleanca",
                "countryCode": "RO"
            },
            {
                "name": "Colţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Amaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balta Albă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bisoca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blăjani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boldu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bozioru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brădeanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăceanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna C.A. Rosetti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Calvini",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cernăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiliile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiojdu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cilibia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cislău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cochirleanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Colţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cozieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cătina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Florica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghergheasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gherăseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glodeanu-Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glodeanu-Sărat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Greabănu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Teghii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gălbinaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Largu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lopătari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Merei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movila Banului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Murgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mânzăleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărgăriteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărăcineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Năeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Odăile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Padina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pardoşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroasele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podgoria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poşta Câlnãu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Puieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pârscov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pănătău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoviţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Robeasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ruşeţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râmnicelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scorţoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scutelnici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siriu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Smeeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stâlpu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săgeata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săhăteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săpoca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săruleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tisãu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topliceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unguriu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Paşii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Râmnicului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Sălciei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Verneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vintilă Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vipereşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vâlcelele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ziduri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zărneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţinteşti",
                "countryCode": "RO"
            },
            {
                "name": "Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cozieni",
                "countryCode": "RO"
            },
            {
                "name": "Coțatcu",
                "countryCode": "RO"
            },
            {
                "name": "Căldărăști",
                "countryCode": "RO"
            },
            {
                "name": "Căneşti",
                "countryCode": "RO"
            },
            {
                "name": "Căpățânești",
                "countryCode": "RO"
            },
            {
                "name": "Cătina",
                "countryCode": "RO"
            },
            {
                "name": "Dedulești",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbroca",
                "countryCode": "RO"
            },
            {
                "name": "Dăscălești",
                "countryCode": "RO"
            },
            {
                "name": "Florica",
                "countryCode": "RO"
            },
            {
                "name": "Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Furtunești",
                "countryCode": "RO"
            },
            {
                "name": "Ghergheasa",
                "countryCode": "RO"
            },
            {
                "name": "Gherăseni",
                "countryCode": "RO"
            },
            {
                "name": "Glodeanu-Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Glodeanu-Sărat",
                "countryCode": "RO"
            },
            {
                "name": "Grebănu",
                "countryCode": "RO"
            },
            {
                "name": "Greceanca",
                "countryCode": "RO"
            },
            {
                "name": "Grunji",
                "countryCode": "RO"
            },
            {
                "name": "Grăjdana",
                "countryCode": "RO"
            },
            {
                "name": "Gura Teghii",
                "countryCode": "RO"
            },
            {
                "name": "Gălbinaşi",
                "countryCode": "RO"
            },
            {
                "name": "Găvănești",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru Dulce",
                "countryCode": "RO"
            },
            {
                "name": "Largu",
                "countryCode": "RO"
            },
            {
                "name": "Limpeziș",
                "countryCode": "RO"
            },
            {
                "name": "Lipia",
                "countryCode": "RO"
            },
            {
                "name": "Lopătari",
                "countryCode": "RO"
            },
            {
                "name": "Luciu",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Jariștei",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Priporului",
                "countryCode": "RO"
            },
            {
                "name": "Maxenu",
                "countryCode": "RO"
            },
            {
                "name": "Merei",
                "countryCode": "RO"
            },
            {
                "name": "Mihăileşti",
                "countryCode": "RO"
            },
            {
                "name": "Movila Banului",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Buzău",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Râmnicu Sãrat",
                "countryCode": "RO"
            },
            {
                "name": "Murgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Mânzăleşti",
                "countryCode": "RO"
            },
            {
                "name": "Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Mărgăriteşti",
                "countryCode": "RO"
            },
            {
                "name": "Mărunțișu",
                "countryCode": "RO"
            },
            {
                "name": "Mărăcineni",
                "countryCode": "RO"
            },
            {
                "name": "Mătești",
                "countryCode": "RO"
            },
            {
                "name": "Nehoiu",
                "countryCode": "RO"
            },
            {
                "name": "Năeni",
                "countryCode": "RO"
            },
            {
                "name": "Odăile",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Nehoiu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Pogoanele",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Pãtârlagele",
                "countryCode": "RO"
            },
            {
                "name": "Oreavu",
                "countryCode": "RO"
            },
            {
                "name": "Padina",
                "countryCode": "RO"
            },
            {
                "name": "Pardoşi",
                "countryCode": "RO"
            },
            {
                "name": "Pietroasele",
                "countryCode": "RO"
            },
            {
                "name": "Pietrosu",
                "countryCode": "RO"
            },
            {
                "name": "Plevna",
                "countryCode": "RO"
            },
            {
                "name": "Podgoria",
                "countryCode": "RO"
            },
            {
                "name": "Pogoanele",
                "countryCode": "RO"
            },
            {
                "name": "Pogonele",
                "countryCode": "RO"
            },
            {
                "name": "Potoceni",
                "countryCode": "RO"
            },
            {
                "name": "Poşta Câlnău",
                "countryCode": "RO"
            },
            {
                "name": "Puieștii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Pârscov",
                "countryCode": "RO"
            },
            {
                "name": "Păltineni",
                "countryCode": "RO"
            },
            {
                "name": "Pănătău",
                "countryCode": "RO"
            },
            {
                "name": "Pătârlagele",
                "countryCode": "RO"
            },
            {
                "name": "Racoviţeni",
                "countryCode": "RO"
            },
            {
                "name": "Robeasca",
                "countryCode": "RO"
            },
            {
                "name": "Rubla",
                "countryCode": "RO"
            },
            {
                "name": "Ruşeţu",
                "countryCode": "RO"
            },
            {
                "name": "Râmnicelu",
                "countryCode": "RO"
            },
            {
                "name": "Râmnicu Sărat",
                "countryCode": "RO"
            },
            {
                "name": "Scorţoasa",
                "countryCode": "RO"
            },
            {
                "name": "Scurtești",
                "countryCode": "RO"
            },
            {
                "name": "Scutelnici",
                "countryCode": "RO"
            },
            {
                "name": "Smeeni",
                "countryCode": "RO"
            },
            {
                "name": "Stâlpu",
                "countryCode": "RO"
            },
            {
                "name": "Stăncești",
                "countryCode": "RO"
            },
            {
                "name": "Sudiți",
                "countryCode": "RO"
            },
            {
                "name": "Săgeata",
                "countryCode": "RO"
            },
            {
                "name": "Săhăteni",
                "countryCode": "RO"
            },
            {
                "name": "Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Săpoca",
                "countryCode": "RO"
            },
            {
                "name": "Săruleşti",
                "countryCode": "RO"
            },
            {
                "name": "Topliceni",
                "countryCode": "RO"
            },
            {
                "name": "Tăbărăști",
                "countryCode": "RO"
            },
            {
                "name": "Ulmeni",
                "countryCode": "RO"
            },
            {
                "name": "Unguriu",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Paşii",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Sorești",
                "countryCode": "RO"
            },
            {
                "name": "Valea Părului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Râmnicului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Salciei",
                "countryCode": "RO"
            },
            {
                "name": "Verneşti",
                "countryCode": "RO"
            },
            {
                "name": "Vintileanca",
                "countryCode": "RO"
            },
            {
                "name": "Vintilă Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Vipereşti",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcelele",
                "countryCode": "RO"
            },
            {
                "name": "Ziduri",
                "countryCode": "RO"
            },
            {
                "name": "Zorești",
                "countryCode": "RO"
            },
            {
                "name": "Zărneşti",
                "countryCode": "RO"
            },
            {
                "name": "Zărneștii de Slănic",
                "countryCode": "RO"
            },
            {
                "name": "Ţinteşti",
                "countryCode": "RO"
            },
            {
                "name": "Aghireșu",
                "countryCode": "RO"
            },
            {
                "name": "Aghireșu-Fabrici",
                "countryCode": "RO"
            },
            {
                "name": "Aiton",
                "countryCode": "RO"
            },
            {
                "name": "Aluniş",
                "countryCode": "RO"
            },
            {
                "name": "Apahida",
                "countryCode": "RO"
            },
            {
                "name": "Aşchileu Dorna",
                "countryCode": "RO"
            },
            {
                "name": "Baciu",
                "countryCode": "RO"
            },
            {
                "name": "Beliş",
                "countryCode": "RO"
            },
            {
                "name": "Bobâlna",
                "countryCode": "RO"
            },
            {
                "name": "Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bonțida",
                "countryCode": "RO"
            },
            {
                "name": "Borşa",
                "countryCode": "RO"
            },
            {
                "name": "Buza",
                "countryCode": "RO"
            },
            {
                "name": "Băişoara",
                "countryCode": "RO"
            },
            {
                "name": "Ceanu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Chinteni",
                "countryCode": "RO"
            },
            {
                "name": "Chiuiești",
                "countryCode": "RO"
            },
            {
                "name": "Ciucea",
                "countryCode": "RO"
            },
            {
                "name": "Ciurila",
                "countryCode": "RO"
            },
            {
                "name": "Cluj-Napoca",
                "countryCode": "RO"
            },
            {
                "name": "Cojocna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aghireşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aiton",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aluniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apahida",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aşchileu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beliş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bobâlna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bonţida",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceanu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chinteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiuieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciucea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciurila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cojocna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuzdrioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câţcău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãpuşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căianu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călăţele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cămăraşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cătina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căşeiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăbâca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Feleacu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fizeşu Gherlii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Geaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gilău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârbãu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iclod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoru Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jichişu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jucu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mintiu Gherlii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mociu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moldoveneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mânăstireni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mãguri-Rãcãtãu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărgău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărişel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Panticeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petreştii De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ploscoş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pãlatca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Recea Cristur",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râșca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suatu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâncraiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmărtin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânpaul",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sãcuieu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sănduleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săvădisla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tritenii De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unguraş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Ierii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţaga",
                "countryCode": "RO"
            },
            {
                "name": "Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Corneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cuzdrioara",
                "countryCode": "RO"
            },
            {
                "name": "Câmpia Turzii",
                "countryCode": "RO"
            },
            {
                "name": "Câţcău",
                "countryCode": "RO"
            },
            {
                "name": "Căianu",
                "countryCode": "RO"
            },
            {
                "name": "Căianu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Călăţele",
                "countryCode": "RO"
            },
            {
                "name": "Cămăraşu",
                "countryCode": "RO"
            },
            {
                "name": "Căpuşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Cătina",
                "countryCode": "RO"
            },
            {
                "name": "Căşeiu",
                "countryCode": "RO"
            },
            {
                "name": "Dej",
                "countryCode": "RO"
            },
            {
                "name": "Dezmir",
                "countryCode": "RO"
            },
            {
                "name": "Dăbâca",
                "countryCode": "RO"
            },
            {
                "name": "Feleacu",
                "countryCode": "RO"
            },
            {
                "name": "Fizeşu Gherlii",
                "countryCode": "RO"
            },
            {
                "name": "Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Frata",
                "countryCode": "RO"
            },
            {
                "name": "Geaca",
                "countryCode": "RO"
            },
            {
                "name": "Gheorghieni",
                "countryCode": "RO"
            },
            {
                "name": "Gherla",
                "countryCode": "RO"
            },
            {
                "name": "Gilău",
                "countryCode": "RO"
            },
            {
                "name": "Gârbău",
                "countryCode": "RO"
            },
            {
                "name": "Huedin",
                "countryCode": "RO"
            },
            {
                "name": "Iara",
                "countryCode": "RO"
            },
            {
                "name": "Iclod",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Jichişu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Luna",
                "countryCode": "RO"
            },
            {
                "name": "Luna de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Luncani",
                "countryCode": "RO"
            },
            {
                "name": "Mera",
                "countryCode": "RO"
            },
            {
                "name": "Mica",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Mintiu Gherlii",
                "countryCode": "RO"
            },
            {
                "name": "Mociu",
                "countryCode": "RO"
            },
            {
                "name": "Moldoveneşti",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Cluj-Napoca",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Câmpia Turzii",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Dej",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Gherla",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Turda",
                "countryCode": "RO"
            },
            {
                "name": "Mânăstireni",
                "countryCode": "RO"
            },
            {
                "name": "Măguri",
                "countryCode": "RO"
            },
            {
                "name": "Măguri-Răcătău",
                "countryCode": "RO"
            },
            {
                "name": "Mărgău",
                "countryCode": "RO"
            },
            {
                "name": "Mărişel",
                "countryCode": "RO"
            },
            {
                "name": "Negreni",
                "countryCode": "RO"
            },
            {
                "name": "Nireș",
                "countryCode": "RO"
            },
            {
                "name": "Ocna Dejului",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Huedin",
                "countryCode": "RO"
            },
            {
                "name": "Panticeu",
                "countryCode": "RO"
            },
            {
                "name": "Petreştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ploscoș",
                "countryCode": "RO"
            },
            {
                "name": "Poieni",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Pălatca",
                "countryCode": "RO"
            },
            {
                "name": "Recea Cristur",
                "countryCode": "RO"
            },
            {
                "name": "Rugășești",
                "countryCode": "RO"
            },
            {
                "name": "Râșca",
                "countryCode": "RO"
            },
            {
                "name": "Răscruci",
                "countryCode": "RO"
            },
            {
                "name": "Sic",
                "countryCode": "RO"
            },
            {
                "name": "Soporu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Suatu",
                "countryCode": "RO"
            },
            {
                "name": "Suceagu",
                "countryCode": "RO"
            },
            {
                "name": "Sâncraiu",
                "countryCode": "RO"
            },
            {
                "name": "Sânmărghita",
                "countryCode": "RO"
            },
            {
                "name": "Sânmărtin",
                "countryCode": "RO"
            },
            {
                "name": "Sânnicoară",
                "countryCode": "RO"
            },
            {
                "name": "Sânpaul",
                "countryCode": "RO"
            },
            {
                "name": "Săcuieu",
                "countryCode": "RO"
            },
            {
                "name": "Sănduleşti",
                "countryCode": "RO"
            },
            {
                "name": "Săvădisla",
                "countryCode": "RO"
            },
            {
                "name": "Tritenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Tritenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Turda",
                "countryCode": "RO"
            },
            {
                "name": "Tureni",
                "countryCode": "RO"
            },
            {
                "name": "Unguraş",
                "countryCode": "RO"
            },
            {
                "name": "Urca",
                "countryCode": "RO"
            },
            {
                "name": "Vad",
                "countryCode": "RO"
            },
            {
                "name": "Vaida-Cămăraș",
                "countryCode": "RO"
            },
            {
                "name": "Valea Ierii",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vultureni",
                "countryCode": "RO"
            },
            {
                "name": "Ţaga",
                "countryCode": "RO"
            },
            {
                "name": "Alexandru Odobescu",
                "countryCode": "RO"
            },
            {
                "name": "Aprozi",
                "countryCode": "RO"
            },
            {
                "name": "Belciugatele",
                "countryCode": "RO"
            },
            {
                "name": "Borcea",
                "countryCode": "RO"
            },
            {
                "name": "Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Ceacu",
                "countryCode": "RO"
            },
            {
                "name": "Chirnogi",
                "countryCode": "RO"
            },
            {
                "name": "Chiselet",
                "countryCode": "RO"
            },
            {
                "name": "Ciocăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Coconi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alexandru Odobescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Belciugatele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chirnogi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiselet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crivăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căscioarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dichiseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dor Mărunt",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorobanţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragalina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragoş Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumuşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frăsinet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gurbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gălbinaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ileana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jegălia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lehliu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lupşanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mitreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Modelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mânăstirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perişoru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plătăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Radovanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roseţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sohatu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Spanţov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săruleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tãmãdãu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Argovei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vasilaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlad Ţepeş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vâlcelele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şoldanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan Cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Constantin Brâncoveanu",
                "countryCode": "RO"
            },
            {
                "name": "Crivăț",
                "countryCode": "RO"
            },
            {
                "name": "Curcani",
                "countryCode": "RO"
            },
            {
                "name": "Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Căscioarele",
                "countryCode": "RO"
            },
            {
                "name": "Dichiseni",
                "countryCode": "RO"
            },
            {
                "name": "Dor Mărunt",
                "countryCode": "RO"
            },
            {
                "name": "Dorobanţu",
                "countryCode": "RO"
            },
            {
                "name": "Dragalina",
                "countryCode": "RO"
            },
            {
                "name": "Dragoș Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Drajna Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Dâlga-Gară",
                "countryCode": "RO"
            },
            {
                "name": "Frumuşani",
                "countryCode": "RO"
            },
            {
                "name": "Frăsinet",
                "countryCode": "RO"
            },
            {
                "name": "Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Fundulea",
                "countryCode": "RO"
            },
            {
                "name": "Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Gurbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Gâldău",
                "countryCode": "RO"
            },
            {
                "name": "Gălbinași",
                "countryCode": "RO"
            },
            {
                "name": "Iezeru",
                "countryCode": "RO"
            },
            {
                "name": "Ileana",
                "countryCode": "RO"
            },
            {
                "name": "Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Jegălia",
                "countryCode": "RO"
            },
            {
                "name": "Lehliu",
                "countryCode": "RO"
            },
            {
                "name": "Lehliu-Gară",
                "countryCode": "RO"
            },
            {
                "name": "Luica",
                "countryCode": "RO"
            },
            {
                "name": "Lupşanu",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Mitreni",
                "countryCode": "RO"
            },
            {
                "name": "Modelu",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Olteniţa",
                "countryCode": "RO"
            },
            {
                "name": "Mânăstirea",
                "countryCode": "RO"
            },
            {
                "name": "Nana",
                "countryCode": "RO"
            },
            {
                "name": "Negoești",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Olteniţa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Fundulea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Lehliu Garã",
                "countryCode": "RO"
            },
            {
                "name": "Perişoru",
                "countryCode": "RO"
            },
            {
                "name": "Plătăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Potcoava",
                "countryCode": "RO"
            },
            {
                "name": "Progresu",
                "countryCode": "RO"
            },
            {
                "name": "Radovanu",
                "countryCode": "RO"
            },
            {
                "name": "Radu Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Rasa",
                "countryCode": "RO"
            },
            {
                "name": "Roseţi",
                "countryCode": "RO"
            },
            {
                "name": "Răzvani",
                "countryCode": "RO"
            },
            {
                "name": "Sohatu",
                "countryCode": "RO"
            },
            {
                "name": "Spanţov",
                "countryCode": "RO"
            },
            {
                "name": "Stancea",
                "countryCode": "RO"
            },
            {
                "name": "Sultana",
                "countryCode": "RO"
            },
            {
                "name": "Săpunari",
                "countryCode": "RO"
            },
            {
                "name": "Săruleşti",
                "countryCode": "RO"
            },
            {
                "name": "Sărulești-Gară",
                "countryCode": "RO"
            },
            {
                "name": "Tămădău Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ulmeni",
                "countryCode": "RO"
            },
            {
                "name": "Ulmu",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Valea Argovei",
                "countryCode": "RO"
            },
            {
                "name": "Valea Roșie",
                "countryCode": "RO"
            },
            {
                "name": "Vasilaţi",
                "countryCode": "RO"
            },
            {
                "name": "Vlad Ţepeş",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcelele",
                "countryCode": "RO"
            },
            {
                "name": "Vărăști",
                "countryCode": "RO"
            },
            {
                "name": "Şoldanu",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Ștefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Anina",
                "countryCode": "RO"
            },
            {
                "name": "Armeniş",
                "countryCode": "RO"
            },
            {
                "name": "Berlişte",
                "countryCode": "RO"
            },
            {
                "name": "Berzasca",
                "countryCode": "RO"
            },
            {
                "name": "Berzovia",
                "countryCode": "RO"
            },
            {
                "name": "Bocşa",
                "countryCode": "RO"
            },
            {
                "name": "Bolvașnița",
                "countryCode": "RO"
            },
            {
                "name": "Borlova",
                "countryCode": "RO"
            },
            {
                "name": "Bozovici",
                "countryCode": "RO"
            },
            {
                "name": "Brebu",
                "countryCode": "RO"
            },
            {
                "name": "Brebu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Buchin",
                "countryCode": "RO"
            },
            {
                "name": "Bucova",
                "countryCode": "RO"
            },
            {
                "name": "Bucoşniţa",
                "countryCode": "RO"
            },
            {
                "name": "Băile Herculane",
                "countryCode": "RO"
            },
            {
                "name": "Bănia",
                "countryCode": "RO"
            },
            {
                "name": "Băuţar",
                "countryCode": "RO"
            },
            {
                "name": "Caransebeş",
                "countryCode": "RO"
            },
            {
                "name": "Caraşova",
                "countryCode": "RO"
            },
            {
                "name": "Ciclova-Română",
                "countryCode": "RO"
            },
            {
                "name": "Ciuchici",
                "countryCode": "RO"
            },
            {
                "name": "Ciudanoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Clocotici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Armeniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berlişte",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berzasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berzovia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bolvaşniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bozovici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brebu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brebu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buchin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucoşniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bănia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băuţar",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Caraşova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciclova Română",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciuchici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciudanoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Constantin Daicoviciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copăcele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornereva",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cărbunari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dalboşeţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Doclin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dognecea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Domaşnea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Eftimie Murgu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ezeriş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Forotic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fârliug",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glimboca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goruia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iablaniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luncaviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lupac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăpuşnicel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăpuşnicu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Marga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mehadia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mehadica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Naidaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Obreja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ocna De Fier",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pescari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pojejena",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prigor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pãltiniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ramna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rusca Montană",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răcăşdia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sacu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sasca Montană",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sicheviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slatina-Timiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Socol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teregova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ticvaniu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topleţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turnu Rueni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târnova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vermeş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vrani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văliug",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vărădia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zorlenţu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăvoi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şopotu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Constantin Daicoviciu",
                "countryCode": "RO"
            },
            {
                "name": "Copăcele",
                "countryCode": "RO"
            },
            {
                "name": "Cornea",
                "countryCode": "RO"
            },
            {
                "name": "Cornereva",
                "countryCode": "RO"
            },
            {
                "name": "Cornuțel",
                "countryCode": "RO"
            },
            {
                "name": "Coronini",
                "countryCode": "RO"
            },
            {
                "name": "Câlnic",
                "countryCode": "RO"
            },
            {
                "name": "Cărbunari",
                "countryCode": "RO"
            },
            {
                "name": "Dalboșeț",
                "countryCode": "RO"
            },
            {
                "name": "Doclin",
                "countryCode": "RO"
            },
            {
                "name": "Dognecea",
                "countryCode": "RO"
            },
            {
                "name": "Domaşnea",
                "countryCode": "RO"
            },
            {
                "name": "Eftimie Murgu",
                "countryCode": "RO"
            },
            {
                "name": "Ezeriş",
                "countryCode": "RO"
            },
            {
                "name": "Fizeș",
                "countryCode": "RO"
            },
            {
                "name": "Forotic",
                "countryCode": "RO"
            },
            {
                "name": "Fârliug",
                "countryCode": "RO"
            },
            {
                "name": "Gherteniș",
                "countryCode": "RO"
            },
            {
                "name": "Glimboca",
                "countryCode": "RO"
            },
            {
                "name": "Goruia",
                "countryCode": "RO"
            },
            {
                "name": "Greoni",
                "countryCode": "RO"
            },
            {
                "name": "Grădinari",
                "countryCode": "RO"
            },
            {
                "name": "Gârnic",
                "countryCode": "RO"
            },
            {
                "name": "Iablaniţa",
                "countryCode": "RO"
            },
            {
                "name": "Liubcova",
                "countryCode": "RO"
            },
            {
                "name": "Luncaviţa",
                "countryCode": "RO"
            },
            {
                "name": "Lupac",
                "countryCode": "RO"
            },
            {
                "name": "Lăpuşnicel",
                "countryCode": "RO"
            },
            {
                "name": "Lăpuşnicu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Marga",
                "countryCode": "RO"
            },
            {
                "name": "Mehadia",
                "countryCode": "RO"
            },
            {
                "name": "Mehadica",
                "countryCode": "RO"
            },
            {
                "name": "Moldova Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Caransebeş",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Reşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Muntele Mic",
                "countryCode": "RO"
            },
            {
                "name": "Măru",
                "countryCode": "RO"
            },
            {
                "name": "Măureni",
                "countryCode": "RO"
            },
            {
                "name": "Naidăș",
                "countryCode": "RO"
            },
            {
                "name": "Obreja",
                "countryCode": "RO"
            },
            {
                "name": "Oraviţa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Anina",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bocşa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãile Herculane",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Oraviţa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Oţelu Roşu",
                "countryCode": "RO"
            },
            {
                "name": "Oţelu Roşu",
                "countryCode": "RO"
            },
            {
                "name": "Padina Matei",
                "countryCode": "RO"
            },
            {
                "name": "Petroșnița",
                "countryCode": "RO"
            },
            {
                "name": "Plugova",
                "countryCode": "RO"
            },
            {
                "name": "Pojejena",
                "countryCode": "RO"
            },
            {
                "name": "Prigor",
                "countryCode": "RO"
            },
            {
                "name": "Păltiniş",
                "countryCode": "RO"
            },
            {
                "name": "Ramna",
                "countryCode": "RO"
            },
            {
                "name": "Reşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Rusca",
                "countryCode": "RO"
            },
            {
                "name": "Rusca Montană",
                "countryCode": "RO"
            },
            {
                "name": "Răcăşdia",
                "countryCode": "RO"
            },
            {
                "name": "Sacu",
                "countryCode": "RO"
            },
            {
                "name": "Sasca Montană",
                "countryCode": "RO"
            },
            {
                "name": "Sicheviţa",
                "countryCode": "RO"
            },
            {
                "name": "Slatina-Timiş",
                "countryCode": "RO"
            },
            {
                "name": "Socol",
                "countryCode": "RO"
            },
            {
                "name": "Steierdorf",
                "countryCode": "RO"
            },
            {
                "name": "Teregova",
                "countryCode": "RO"
            },
            {
                "name": "Ticvaniu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Topleţ",
                "countryCode": "RO"
            },
            {
                "name": "Turnu Ruieni",
                "countryCode": "RO"
            },
            {
                "name": "Târnova",
                "countryCode": "RO"
            },
            {
                "name": "Valea Bolvașnița",
                "countryCode": "RO"
            },
            {
                "name": "Verendin",
                "countryCode": "RO"
            },
            {
                "name": "Vermeş",
                "countryCode": "RO"
            },
            {
                "name": "Vrani",
                "countryCode": "RO"
            },
            {
                "name": "Vârciorova",
                "countryCode": "RO"
            },
            {
                "name": "Văliug",
                "countryCode": "RO"
            },
            {
                "name": "Vărădia",
                "countryCode": "RO"
            },
            {
                "name": "Zorlenţu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Zăvoi",
                "countryCode": "RO"
            },
            {
                "name": "Şopotu Nou",
                "countryCode": "RO"
            },
            {
                "name": "23 August",
                "countryCode": "RO"
            },
            {
                "name": "Adamclisi",
                "countryCode": "RO"
            },
            {
                "name": "Agigea",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Aliman",
                "countryCode": "RO"
            },
            {
                "name": "Amzacea",
                "countryCode": "RO"
            },
            {
                "name": "Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Bărăganu",
                "countryCode": "RO"
            },
            {
                "name": "Castelu",
                "countryCode": "RO"
            },
            {
                "name": "Cerchezu",
                "countryCode": "RO"
            },
            {
                "name": "Cernavodă",
                "countryCode": "RO"
            },
            {
                "name": "Chirnogeni",
                "countryCode": "RO"
            },
            {
                "name": "Ciobanu",
                "countryCode": "RO"
            },
            {
                "name": "Ciocârlia",
                "countryCode": "RO"
            },
            {
                "name": "Ciocârlia de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Cobadin",
                "countryCode": "RO"
            },
            {
                "name": "Cogealac",
                "countryCode": "RO"
            },
            {
                "name": "Comana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna 23 August",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adamclisi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Agigea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aliman",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Amzacea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărăganu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Castelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerchezu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chirnogeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciobanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocârlia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cobadin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cogealac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crucea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cumpăna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuza Voda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghindăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârliciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ion Corvin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Istria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Limanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lipniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lumina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mereni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mircea Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oltina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ostrov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pantelimon",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pecineaga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peştera",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poarta Albă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rasova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Saligny",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Saraiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seimeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topalu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topraisar",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tortoman",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tuzla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târguşor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valu lui Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulturu",
                "countryCode": "RO"
            },
            {
                "name": "Comună Dobromir",
                "countryCode": "RO"
            },
            {
                "name": "Constanţa",
                "countryCode": "RO"
            },
            {
                "name": "Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Costineşti",
                "countryCode": "RO"
            },
            {
                "name": "Crucea",
                "countryCode": "RO"
            },
            {
                "name": "Culmea",
                "countryCode": "RO"
            },
            {
                "name": "Cumpăna",
                "countryCode": "RO"
            },
            {
                "name": "Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Dobromir",
                "countryCode": "RO"
            },
            {
                "name": "Dorobanțu",
                "countryCode": "RO"
            },
            {
                "name": "Dulcești",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Dunăreni",
                "countryCode": "RO"
            },
            {
                "name": "Eforie Nord",
                "countryCode": "RO"
            },
            {
                "name": "Eforie Sud",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Făclia",
                "countryCode": "RO"
            },
            {
                "name": "Ghindăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Grădina",
                "countryCode": "RO"
            },
            {
                "name": "Gârliciu",
                "countryCode": "RO"
            },
            {
                "name": "Horia",
                "countryCode": "RO"
            },
            {
                "name": "Hârşova",
                "countryCode": "RO"
            },
            {
                "name": "Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Ion Corvin",
                "countryCode": "RO"
            },
            {
                "name": "Istria",
                "countryCode": "RO"
            },
            {
                "name": "Limanu",
                "countryCode": "RO"
            },
            {
                "name": "Lipniţa",
                "countryCode": "RO"
            },
            {
                "name": "Lumina",
                "countryCode": "RO"
            },
            {
                "name": "Mamaia-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Mangalia",
                "countryCode": "RO"
            },
            {
                "name": "Medgidia",
                "countryCode": "RO"
            },
            {
                "name": "Mereni",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Mircea Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Moșneni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Constanţa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Mangalia",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Medgidia",
                "countryCode": "RO"
            },
            {
                "name": "Murfatlar",
                "countryCode": "RO"
            },
            {
                "name": "Negru Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Nisipari",
                "countryCode": "RO"
            },
            {
                "name": "Nuntași",
                "countryCode": "RO"
            },
            {
                "name": "Năvodari",
                "countryCode": "RO"
            },
            {
                "name": "Oltina",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãneasa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cernavodã",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Eforie",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Hârşova",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Murfatlar",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Negru Vodã",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Nãvodari",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ovidiu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Techirghiol",
                "countryCode": "RO"
            },
            {
                "name": "Ostrov",
                "countryCode": "RO"
            },
            {
                "name": "Ovidiu",
                "countryCode": "RO"
            },
            {
                "name": "Palazu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Pantelimon",
                "countryCode": "RO"
            },
            {
                "name": "Pantelimon de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Pecineaga",
                "countryCode": "RO"
            },
            {
                "name": "Peştera",
                "countryCode": "RO"
            },
            {
                "name": "Piatra",
                "countryCode": "RO"
            },
            {
                "name": "Pietreni",
                "countryCode": "RO"
            },
            {
                "name": "Plopeni",
                "countryCode": "RO"
            },
            {
                "name": "Poarta Albă",
                "countryCode": "RO"
            },
            {
                "name": "Rasova",
                "countryCode": "RO"
            },
            {
                "name": "Saligny",
                "countryCode": "RO"
            },
            {
                "name": "Saraiu",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Schitu",
                "countryCode": "RO"
            },
            {
                "name": "Seimeni",
                "countryCode": "RO"
            },
            {
                "name": "Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Siminoc",
                "countryCode": "RO"
            },
            {
                "name": "Săcele",
                "countryCode": "RO"
            },
            {
                "name": "Tariverde",
                "countryCode": "RO"
            },
            {
                "name": "Techirghiol",
                "countryCode": "RO"
            },
            {
                "name": "Topalu",
                "countryCode": "RO"
            },
            {
                "name": "Topraisar",
                "countryCode": "RO"
            },
            {
                "name": "Tortoman",
                "countryCode": "RO"
            },
            {
                "name": "Tuzla",
                "countryCode": "RO"
            },
            {
                "name": "Târguşor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Dacilor",
                "countryCode": "RO"
            },
            {
                "name": "Valu lui Traian",
                "countryCode": "RO"
            },
            {
                "name": "Viile",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vulturu",
                "countryCode": "RO"
            },
            {
                "name": "Aita Mare",
                "countryCode": "RO"
            },
            {
                "name": "Araci",
                "countryCode": "RO"
            },
            {
                "name": "Arcuș",
                "countryCode": "RO"
            },
            {
                "name": "Baraolt",
                "countryCode": "RO"
            },
            {
                "name": "Barcani",
                "countryCode": "RO"
            },
            {
                "name": "Belin",
                "countryCode": "RO"
            },
            {
                "name": "Belin-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Bixad",
                "countryCode": "RO"
            },
            {
                "name": "Bodoc",
                "countryCode": "RO"
            },
            {
                "name": "Boroşneu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Brateş",
                "countryCode": "RO"
            },
            {
                "name": "Breţcu",
                "countryCode": "RO"
            },
            {
                "name": "Brăduţ",
                "countryCode": "RO"
            },
            {
                "name": "Băţanii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Catalina",
                "countryCode": "RO"
            },
            {
                "name": "Cernat",
                "countryCode": "RO"
            },
            {
                "name": "Chichiş",
                "countryCode": "RO"
            },
            {
                "name": "Comandău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aita Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arcuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Barcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Belin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bixad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bodoc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boroşneu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brateş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Breţcu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăduţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băţani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Catalina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chichiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comandău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dalnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobârlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Estelnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghelinţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghidfalău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hăghig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lemnia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malnaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mereni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Micfalău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moacşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ojdula",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ozun",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Reci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sita Buzăului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânzieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârghiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zagon",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăbala",
                "countryCode": "RO"
            },
            {
                "name": "Comună Cernat",
                "countryCode": "RO"
            },
            {
                "name": "Covasna",
                "countryCode": "RO"
            },
            {
                "name": "Căpeni",
                "countryCode": "RO"
            },
            {
                "name": "Dalnic",
                "countryCode": "RO"
            },
            {
                "name": "Doboșeni",
                "countryCode": "RO"
            },
            {
                "name": "Dobârlău",
                "countryCode": "RO"
            },
            {
                "name": "Estelnic",
                "countryCode": "RO"
            },
            {
                "name": "Filia",
                "countryCode": "RO"
            },
            {
                "name": "Floroaia",
                "countryCode": "RO"
            },
            {
                "name": "Ghelinţa",
                "countryCode": "RO"
            },
            {
                "name": "Ghidfalău",
                "countryCode": "RO"
            },
            {
                "name": "Herculian",
                "countryCode": "RO"
            },
            {
                "name": "Hăghig",
                "countryCode": "RO"
            },
            {
                "name": "Ilieni",
                "countryCode": "RO"
            },
            {
                "name": "Lemnia",
                "countryCode": "RO"
            },
            {
                "name": "Lunga",
                "countryCode": "RO"
            },
            {
                "name": "Malnaş",
                "countryCode": "RO"
            },
            {
                "name": "Mereni",
                "countryCode": "RO"
            },
            {
                "name": "Micfalău",
                "countryCode": "RO"
            },
            {
                "name": "Moacşa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Târgu Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Ojdula",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Baraolt",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Covasna",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Întorsura Buzãului",
                "countryCode": "RO"
            },
            {
                "name": "Ozun",
                "countryCode": "RO"
            },
            {
                "name": "Poian",
                "countryCode": "RO"
            },
            {
                "name": "Păpăuți",
                "countryCode": "RO"
            },
            {
                "name": "Reci",
                "countryCode": "RO"
            },
            {
                "name": "Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Sita Buzăului",
                "countryCode": "RO"
            },
            {
                "name": "Sânzieni",
                "countryCode": "RO"
            },
            {
                "name": "Turia",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Valea Crişului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Vârghiş",
                "countryCode": "RO"
            },
            {
                "name": "Zagon",
                "countryCode": "RO"
            },
            {
                "name": "Zăbala",
                "countryCode": "RO"
            },
            {
                "name": "Întorsura Buzăului",
                "countryCode": "RO"
            },
            {
                "name": "Adânca",
                "countryCode": "RO"
            },
            {
                "name": "Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Bezdead",
                "countryCode": "RO"
            },
            {
                "name": "Bilciureşti",
                "countryCode": "RO"
            },
            {
                "name": "Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Brezoaele",
                "countryCode": "RO"
            },
            {
                "name": "Brezoaia",
                "countryCode": "RO"
            },
            {
                "name": "Broșteni",
                "countryCode": "RO"
            },
            {
                "name": "Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Buciumeni",
                "countryCode": "RO"
            },
            {
                "name": "Bucşani",
                "countryCode": "RO"
            },
            {
                "name": "Bungetu",
                "countryCode": "RO"
            },
            {
                "name": "Butimanu",
                "countryCode": "RO"
            },
            {
                "name": "Bâldana",
                "countryCode": "RO"
            },
            {
                "name": "Bădeni",
                "countryCode": "RO"
            },
            {
                "name": "Băleni Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Bălteni",
                "countryCode": "RO"
            },
            {
                "name": "Bărbuleţu",
                "countryCode": "RO"
            },
            {
                "name": "Cazaci",
                "countryCode": "RO"
            },
            {
                "name": "Ciocănari",
                "countryCode": "RO"
            },
            {
                "name": "Ciocăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cojasca",
                "countryCode": "RO"
            },
            {
                "name": "Colacu",
                "countryCode": "RO"
            },
            {
                "name": "Comişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bezdead",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bilciureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brezoaele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buciumeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Butimanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băleni Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărbuleţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cobia de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cojasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Conţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornăţelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costeştii din Vale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crevedia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crângurile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cândeşti Vale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Doiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragodana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Finta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glodeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Foii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Ocniţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Şuţii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hulubeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna I. L. Caragiale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iedera",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lucieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ludeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunguleţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malu cu Flori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mogoşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moroeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Morteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moţãieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mătăsaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Niculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nucet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ocniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Odobeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perşinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietrari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Potlogi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Produleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pucheni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Raciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râu Alb",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rãzvad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răscăeţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia Moara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tărtăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătărani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Lungă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulcana Băi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulcana-Pandele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârfuri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văcăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văleni-Dâmboviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şelaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şotânga",
                "countryCode": "RO"
            },
            {
                "name": "Conțești",
                "countryCode": "RO"
            },
            {
                "name": "Corbii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Corneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cornăţelu",
                "countryCode": "RO"
            },
            {
                "name": "Costeştii din Vale",
                "countryCode": "RO"
            },
            {
                "name": "Costeștii din Deal",
                "countryCode": "RO"
            },
            {
                "name": "Crevedia",
                "countryCode": "RO"
            },
            {
                "name": "Croitori",
                "countryCode": "RO"
            },
            {
                "name": "Crovu",
                "countryCode": "RO"
            },
            {
                "name": "Crângurile de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Cândeşti Vale",
                "countryCode": "RO"
            },
            {
                "name": "Căprioru",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Frumos",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Decindeni",
                "countryCode": "RO"
            },
            {
                "name": "Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Doiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Dragodana",
                "countryCode": "RO"
            },
            {
                "name": "Dragodănești",
                "countryCode": "RO"
            },
            {
                "name": "Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăești Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbovicioara",
                "countryCode": "RO"
            },
            {
                "name": "Dârza",
                "countryCode": "RO"
            },
            {
                "name": "Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Fieni",
                "countryCode": "RO"
            },
            {
                "name": "Fierbinți",
                "countryCode": "RO"
            },
            {
                "name": "Finta Mare",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Gemenea Brătulești",
                "countryCode": "RO"
            },
            {
                "name": "Gheboaia",
                "countryCode": "RO"
            },
            {
                "name": "Gheboieni",
                "countryCode": "RO"
            },
            {
                "name": "Ghinești",
                "countryCode": "RO"
            },
            {
                "name": "Ghirdoveni",
                "countryCode": "RO"
            },
            {
                "name": "Glod",
                "countryCode": "RO"
            },
            {
                "name": "Glodeni",
                "countryCode": "RO"
            },
            {
                "name": "Gorgota",
                "countryCode": "RO"
            },
            {
                "name": "Greci",
                "countryCode": "RO"
            },
            {
                "name": "Grozăvești",
                "countryCode": "RO"
            },
            {
                "name": "Gulia",
                "countryCode": "RO"
            },
            {
                "name": "Gura Foii",
                "countryCode": "RO"
            },
            {
                "name": "Gura Ocniței",
                "countryCode": "RO"
            },
            {
                "name": "Gura Vulcanei",
                "countryCode": "RO"
            },
            {
                "name": "Gura Şuţii",
                "countryCode": "RO"
            },
            {
                "name": "Găeşti",
                "countryCode": "RO"
            },
            {
                "name": "Hulubeşti",
                "countryCode": "RO"
            },
            {
                "name": "Hăbeni",
                "countryCode": "RO"
            },
            {
                "name": "I. L. Caragiale",
                "countryCode": "RO"
            },
            {
                "name": "Iazu",
                "countryCode": "RO"
            },
            {
                "name": "Ibrianu",
                "countryCode": "RO"
            },
            {
                "name": "Iedera de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Iedera de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ionești",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Lazuri",
                "countryCode": "RO"
            },
            {
                "name": "Lucieni",
                "countryCode": "RO"
            },
            {
                "name": "Ludeşti",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Lunguleţu",
                "countryCode": "RO"
            },
            {
                "name": "Lăculețe",
                "countryCode": "RO"
            },
            {
                "name": "Malu cu Flori",
                "countryCode": "RO"
            },
            {
                "name": "Mavrodin",
                "countryCode": "RO"
            },
            {
                "name": "Mija",
                "countryCode": "RO"
            },
            {
                "name": "Mogoşani",
                "countryCode": "RO"
            },
            {
                "name": "Moreni",
                "countryCode": "RO"
            },
            {
                "name": "Moroeni",
                "countryCode": "RO"
            },
            {
                "name": "Morteni",
                "countryCode": "RO"
            },
            {
                "name": "Moțăieni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Moreni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Târgovişte",
                "countryCode": "RO"
            },
            {
                "name": "Măneşti",
                "countryCode": "RO"
            },
            {
                "name": "Mărcești",
                "countryCode": "RO"
            },
            {
                "name": "Mărginenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Mătăsaru",
                "countryCode": "RO"
            },
            {
                "name": "Niculești",
                "countryCode": "RO"
            },
            {
                "name": "Nucet",
                "countryCode": "RO"
            },
            {
                "name": "Ocniţa",
                "countryCode": "RO"
            },
            {
                "name": "Odaia Turcului",
                "countryCode": "RO"
            },
            {
                "name": "Odobeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Fieni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Gãeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Pucioasa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Rãcari",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Titu",
                "countryCode": "RO"
            },
            {
                "name": "Perșinari",
                "countryCode": "RO"
            },
            {
                "name": "Petreşti",
                "countryCode": "RO"
            },
            {
                "name": "Picior de Munte",
                "countryCode": "RO"
            },
            {
                "name": "Pietrari",
                "countryCode": "RO"
            },
            {
                "name": "Pietroşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Pitaru",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Potlogi",
                "countryCode": "RO"
            },
            {
                "name": "Priboiu",
                "countryCode": "RO"
            },
            {
                "name": "Produleşti",
                "countryCode": "RO"
            },
            {
                "name": "Pucheni",
                "countryCode": "RO"
            },
            {
                "name": "Pucioasa",
                "countryCode": "RO"
            },
            {
                "name": "Raciu",
                "countryCode": "RO"
            },
            {
                "name": "Racovița",
                "countryCode": "RO"
            },
            {
                "name": "Românești",
                "countryCode": "RO"
            },
            {
                "name": "Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Râncăciov",
                "countryCode": "RO"
            },
            {
                "name": "Râu Alb de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Răcari",
                "countryCode": "RO"
            },
            {
                "name": "Răscăeți",
                "countryCode": "RO"
            },
            {
                "name": "Răzvad",
                "countryCode": "RO"
            },
            {
                "name": "Scheiu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Scheiu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Serdanu",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia Moara",
                "countryCode": "RO"
            },
            {
                "name": "Speriețeni",
                "countryCode": "RO"
            },
            {
                "name": "Suseni Socetu",
                "countryCode": "RO"
            },
            {
                "name": "Săcueni",
                "countryCode": "RO"
            },
            {
                "name": "Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Sălcuța",
                "countryCode": "RO"
            },
            {
                "name": "Săteni",
                "countryCode": "RO"
            },
            {
                "name": "Teiș",
                "countryCode": "RO"
            },
            {
                "name": "Tețcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Titu",
                "countryCode": "RO"
            },
            {
                "name": "Târgovişte",
                "countryCode": "RO"
            },
            {
                "name": "Tărtăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tătărani",
                "countryCode": "RO"
            },
            {
                "name": "Ulieşti",
                "countryCode": "RO"
            },
            {
                "name": "Ulmi",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Urziceanca",
                "countryCode": "RO"
            },
            {
                "name": "Valea Leurzii",
                "countryCode": "RO"
            },
            {
                "name": "Valea Lungă Ogrea",
                "countryCode": "RO"
            },
            {
                "name": "Valea Lungă-Cricov",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Valea Voievozilor",
                "countryCode": "RO"
            },
            {
                "name": "Viforâta",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vizurești",
                "countryCode": "RO"
            },
            {
                "name": "Vişina",
                "countryCode": "RO"
            },
            {
                "name": "Vişineşti",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Vlăsceni",
                "countryCode": "RO"
            },
            {
                "name": "Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Vulcana Băi",
                "countryCode": "RO"
            },
            {
                "name": "Vulcana de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Vulcana-Pandele",
                "countryCode": "RO"
            },
            {
                "name": "Vârfuri",
                "countryCode": "RO"
            },
            {
                "name": "Văcăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Văleni-Dâmbovița",
                "countryCode": "RO"
            },
            {
                "name": "Zidurile",
                "countryCode": "RO"
            },
            {
                "name": "Zăvoiu",
                "countryCode": "RO"
            },
            {
                "name": "Şelaru",
                "countryCode": "RO"
            },
            {
                "name": "Şotânga",
                "countryCode": "RO"
            },
            {
                "name": "Afumaţi",
                "countryCode": "RO"
            },
            {
                "name": "Almăj",
                "countryCode": "RO"
            },
            {
                "name": "Amărăştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Amărăştii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Apele Vii",
                "countryCode": "RO"
            },
            {
                "name": "Argetoaia",
                "countryCode": "RO"
            },
            {
                "name": "Basarabi",
                "countryCode": "RO"
            },
            {
                "name": "Bechet",
                "countryCode": "RO"
            },
            {
                "name": "Bistreț",
                "countryCode": "RO"
            },
            {
                "name": "Botoşeşti-Paia",
                "countryCode": "RO"
            },
            {
                "name": "Boureni",
                "countryCode": "RO"
            },
            {
                "name": "Brabeți",
                "countryCode": "RO"
            },
            {
                "name": "Brabova",
                "countryCode": "RO"
            },
            {
                "name": "Braloştiţa",
                "countryCode": "RO"
            },
            {
                "name": "Bratovoești",
                "countryCode": "RO"
            },
            {
                "name": "Breasta",
                "countryCode": "RO"
            },
            {
                "name": "Brădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bucovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Bulzeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bâlta",
                "countryCode": "RO"
            },
            {
                "name": "Bârca",
                "countryCode": "RO"
            },
            {
                "name": "Bădoși",
                "countryCode": "RO"
            },
            {
                "name": "Băileşti",
                "countryCode": "RO"
            },
            {
                "name": "Calafat",
                "countryCode": "RO"
            },
            {
                "name": "Calopăr",
                "countryCode": "RO"
            },
            {
                "name": "Caraula",
                "countryCode": "RO"
            },
            {
                "name": "Carpen",
                "countryCode": "RO"
            },
            {
                "name": "Castranova",
                "countryCode": "RO"
            },
            {
                "name": "Castrele Traiane",
                "countryCode": "RO"
            },
            {
                "name": "Catane",
                "countryCode": "RO"
            },
            {
                "name": "Celaru",
                "countryCode": "RO"
            },
            {
                "name": "Cernele",
                "countryCode": "RO"
            },
            {
                "name": "Cernăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Cerăt",
                "countryCode": "RO"
            },
            {
                "name": "Cetate",
                "countryCode": "RO"
            },
            {
                "name": "Cioroiași",
                "countryCode": "RO"
            },
            {
                "name": "Ciupercenii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Ciupercenii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Cleanov",
                "countryCode": "RO"
            },
            {
                "name": "Comoșteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Afumaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Almãj",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Amărăştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Amărăştii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apele Vii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Argetoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bistreţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Botoşeşti-Paia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brabova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Braloştiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bratovoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Breasta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bulzeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Calopăru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Caraula",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Carpen",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Castranova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Catane",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Celaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cernăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerãt",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cetate",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cioroiaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciupercenii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşoveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coţofenii din Dos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coţofenii din Faţă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Desa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dioşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobroteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drãnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăneţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fãrcaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galicea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galiciuica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gherceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghidici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghindeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gighera",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giubega",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giurgiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gogoşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goicea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Greceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gângiova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Işalniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lipovu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Maglavit",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Melineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mischii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moţăţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Murgaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mârşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măceşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măceşu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negoi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orodel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ostroveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perişor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pieleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Piscu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pleniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pleşoi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Predeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Radovan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rast",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Robăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rojişte",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sadova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seaca de Câmp",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seaca de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siliştea Crucii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sopot",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălcuţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teasc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Terpeziţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teslui",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tãlpaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urzicuţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Stanciului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vela",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Verbiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârtop",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârvoru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Întorsura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şimnicu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţuglui",
                "countryCode": "RO"
            },
            {
                "name": "Coşoveni",
                "countryCode": "RO"
            },
            {
                "name": "Coţofenii din Dos",
                "countryCode": "RO"
            },
            {
                "name": "Coțofenii din Față",
                "countryCode": "RO"
            },
            {
                "name": "Craiova",
                "countryCode": "RO"
            },
            {
                "name": "Cârcea",
                "countryCode": "RO"
            },
            {
                "name": "Cârna",
                "countryCode": "RO"
            },
            {
                "name": "Călăraşi",
                "countryCode": "RO"
            },
            {
                "name": "Desa",
                "countryCode": "RO"
            },
            {
                "name": "Dioşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobreşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobridor",
                "countryCode": "RO"
            },
            {
                "name": "Dobrotești",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Drănic",
                "countryCode": "RO"
            },
            {
                "name": "Dăbuleni",
                "countryCode": "RO"
            },
            {
                "name": "Dăneţi",
                "countryCode": "RO"
            },
            {
                "name": "Filiaşi",
                "countryCode": "RO"
            },
            {
                "name": "Fratoștița",
                "countryCode": "RO"
            },
            {
                "name": "Făcăi",
                "countryCode": "RO"
            },
            {
                "name": "Fărcaș",
                "countryCode": "RO"
            },
            {
                "name": "Galicea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Galiciuica",
                "countryCode": "RO"
            },
            {
                "name": "Ghidici",
                "countryCode": "RO"
            },
            {
                "name": "Ghindeni",
                "countryCode": "RO"
            },
            {
                "name": "Ghizdăvești",
                "countryCode": "RO"
            },
            {
                "name": "Gighera",
                "countryCode": "RO"
            },
            {
                "name": "Giubega",
                "countryCode": "RO"
            },
            {
                "name": "Giurgiţa",
                "countryCode": "RO"
            },
            {
                "name": "Gogoşu",
                "countryCode": "RO"
            },
            {
                "name": "Goicea",
                "countryCode": "RO"
            },
            {
                "name": "Goiești",
                "countryCode": "RO"
            },
            {
                "name": "Greceşti",
                "countryCode": "RO"
            },
            {
                "name": "Gângiova",
                "countryCode": "RO"
            },
            {
                "name": "Hunia",
                "countryCode": "RO"
            },
            {
                "name": "Izvoare",
                "countryCode": "RO"
            },
            {
                "name": "Işalniţa",
                "countryCode": "RO"
            },
            {
                "name": "Leu",
                "countryCode": "RO"
            },
            {
                "name": "Lipovu",
                "countryCode": "RO"
            },
            {
                "name": "Lișteava",
                "countryCode": "RO"
            },
            {
                "name": "Maglavit",
                "countryCode": "RO"
            },
            {
                "name": "Malu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Melineşti",
                "countryCode": "RO"
            },
            {
                "name": "Mischii",
                "countryCode": "RO"
            },
            {
                "name": "Mofleni",
                "countryCode": "RO"
            },
            {
                "name": "Moţăţei",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Bãileşti",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Calafat",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Craiova",
                "countryCode": "RO"
            },
            {
                "name": "Murgași",
                "countryCode": "RO"
            },
            {
                "name": "Mârşani",
                "countryCode": "RO"
            },
            {
                "name": "Măceşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Măceşu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Nedeia",
                "countryCode": "RO"
            },
            {
                "name": "Negoi",
                "countryCode": "RO"
            },
            {
                "name": "Negoiești",
                "countryCode": "RO"
            },
            {
                "name": "Ocolna",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bechet",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Dãbuleni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Filiaşi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Segarcea",
                "countryCode": "RO"
            },
            {
                "name": "Orodel",
                "countryCode": "RO"
            },
            {
                "name": "Ostroveni",
                "countryCode": "RO"
            },
            {
                "name": "Padea",
                "countryCode": "RO"
            },
            {
                "name": "Perișor",
                "countryCode": "RO"
            },
            {
                "name": "Pieleştí",
                "countryCode": "RO"
            },
            {
                "name": "Piscu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Piscu Sadovei",
                "countryCode": "RO"
            },
            {
                "name": "Piscu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Pleniţa",
                "countryCode": "RO"
            },
            {
                "name": "Pleșoi",
                "countryCode": "RO"
            },
            {
                "name": "Podari",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Mare",
                "countryCode": "RO"
            },
            {
                "name": "Popoveni",
                "countryCode": "RO"
            },
            {
                "name": "Prapor",
                "countryCode": "RO"
            },
            {
                "name": "Preajba",
                "countryCode": "RO"
            },
            {
                "name": "Predeşti",
                "countryCode": "RO"
            },
            {
                "name": "Puțuri",
                "countryCode": "RO"
            },
            {
                "name": "Radomir",
                "countryCode": "RO"
            },
            {
                "name": "Radovan",
                "countryCode": "RO"
            },
            {
                "name": "Rast",
                "countryCode": "RO"
            },
            {
                "name": "Rojiște",
                "countryCode": "RO"
            },
            {
                "name": "Răcarii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Sadova",
                "countryCode": "RO"
            },
            {
                "name": "Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou Calopăr",
                "countryCode": "RO"
            },
            {
                "name": "Scăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Seaca de Câmp",
                "countryCode": "RO"
            },
            {
                "name": "Seaca de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Secu",
                "countryCode": "RO"
            },
            {
                "name": "Secui",
                "countryCode": "RO"
            },
            {
                "name": "Segarcea",
                "countryCode": "RO"
            },
            {
                "name": "Sfârcea",
                "countryCode": "RO"
            },
            {
                "name": "Siliştea Crucii",
                "countryCode": "RO"
            },
            {
                "name": "Smârdan",
                "countryCode": "RO"
            },
            {
                "name": "Sopot",
                "countryCode": "RO"
            },
            {
                "name": "Sălcuţa",
                "countryCode": "RO"
            },
            {
                "name": "Sărata",
                "countryCode": "RO"
            },
            {
                "name": "Teasc",
                "countryCode": "RO"
            },
            {
                "name": "Terpeziţa",
                "countryCode": "RO"
            },
            {
                "name": "Teslui",
                "countryCode": "RO"
            },
            {
                "name": "Tălpaș",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Urzicuţa",
                "countryCode": "RO"
            },
            {
                "name": "Valea Stanciului",
                "countryCode": "RO"
            },
            {
                "name": "Vela",
                "countryCode": "RO"
            },
            {
                "name": "Verbiţa",
                "countryCode": "RO"
            },
            {
                "name": "Vârtop",
                "countryCode": "RO"
            },
            {
                "name": "Vârvoru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Zănoaga",
                "countryCode": "RO"
            },
            {
                "name": "Zăval",
                "countryCode": "RO"
            },
            {
                "name": "Întorsura",
                "countryCode": "RO"
            },
            {
                "name": "Şimnicu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ţuglui",
                "countryCode": "RO"
            },
            {
                "name": "Albeni",
                "countryCode": "RO"
            },
            {
                "name": "Alimpeşti",
                "countryCode": "RO"
            },
            {
                "name": "Andreești",
                "countryCode": "RO"
            },
            {
                "name": "Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Arcani",
                "countryCode": "RO"
            },
            {
                "name": "Baia de Fier",
                "countryCode": "RO"
            },
            {
                "name": "Bengești",
                "countryCode": "RO"
            },
            {
                "name": "Berleşti",
                "countryCode": "RO"
            },
            {
                "name": "Bolboşi",
                "countryCode": "RO"
            },
            {
                "name": "Borăscu",
                "countryCode": "RO"
            },
            {
                "name": "Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bumbeşti-Jiu",
                "countryCode": "RO"
            },
            {
                "name": "Bustuchin",
                "countryCode": "RO"
            },
            {
                "name": "Bâlta",
                "countryCode": "RO"
            },
            {
                "name": "Bâlteni",
                "countryCode": "RO"
            },
            {
                "name": "Bălcești",
                "countryCode": "RO"
            },
            {
                "name": "Băleşti",
                "countryCode": "RO"
            },
            {
                "name": "Bărbăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Ceauru",
                "countryCode": "RO"
            },
            {
                "name": "Ciuperceni",
                "countryCode": "RO"
            },
            {
                "name": "Cloșani",
                "countryCode": "RO"
            },
            {
                "name": "Cocoreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alimpeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baia de Fier",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bengeşti-Ciocadia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bolboşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borăscu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bumbeşti-Piţic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bustuchin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bâlteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărbăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciuperceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crasna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crușeț",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câlnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căpreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cătunele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drãguţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dănciuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fãrcãşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glogova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Godineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hurezani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jupâneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Licurici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Logreşti-Moşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Muşeteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mătăsari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negomir",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Padeş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peştişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plopşoru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Polovragi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prigoria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşia De Amaradia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Samarineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schela",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scoarţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slivileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stejari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turburea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turcineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urdari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vladimir",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văgiuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţânţăreni",
                "countryCode": "RO"
            },
            {
                "name": "Costești",
                "countryCode": "RO"
            },
            {
                "name": "Covrigi",
                "countryCode": "RO"
            },
            {
                "name": "Crasna",
                "countryCode": "RO"
            },
            {
                "name": "Crușeț",
                "countryCode": "RO"
            },
            {
                "name": "Câlnic",
                "countryCode": "RO"
            },
            {
                "name": "Căpreni",
                "countryCode": "RO"
            },
            {
                "name": "Cărpiniș",
                "countryCode": "RO"
            },
            {
                "name": "Cătunele",
                "countryCode": "RO"
            },
            {
                "name": "Dobrița",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăguţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Dănciuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Florești",
                "countryCode": "RO"
            },
            {
                "name": "Fărcăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Glogova",
                "countryCode": "RO"
            },
            {
                "name": "Godineşti",
                "countryCode": "RO"
            },
            {
                "name": "Hurezani",
                "countryCode": "RO"
            },
            {
                "name": "Ioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Jupâneşti",
                "countryCode": "RO"
            },
            {
                "name": "Leleşti",
                "countryCode": "RO"
            },
            {
                "name": "Licurici",
                "countryCode": "RO"
            },
            {
                "name": "Logreşti",
                "countryCode": "RO"
            },
            {
                "name": "Logrești Moșteni",
                "countryCode": "RO"
            },
            {
                "name": "Motru",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Motru",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Târgu Jiu",
                "countryCode": "RO"
            },
            {
                "name": "Muşeteşti",
                "countryCode": "RO"
            },
            {
                "name": "Mătăsari",
                "countryCode": "RO"
            },
            {
                "name": "Negomir",
                "countryCode": "RO"
            },
            {
                "name": "Novaci",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bumbeşti-Jiu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Rovinari",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Tismana",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Turceni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Târgu Cãrbuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ţicleni",
                "countryCode": "RO"
            },
            {
                "name": "Padeş",
                "countryCode": "RO"
            },
            {
                "name": "Peştişani",
                "countryCode": "RO"
            },
            {
                "name": "Peșteana Jiu",
                "countryCode": "RO"
            },
            {
                "name": "Peșteana de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Piscoiu",
                "countryCode": "RO"
            },
            {
                "name": "Plopşoru",
                "countryCode": "RO"
            },
            {
                "name": "Ploștina",
                "countryCode": "RO"
            },
            {
                "name": "Pociovaliștea",
                "countryCode": "RO"
            },
            {
                "name": "Pocruia",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Pojogeni",
                "countryCode": "RO"
            },
            {
                "name": "Polovragi",
                "countryCode": "RO"
            },
            {
                "name": "Prigoria",
                "countryCode": "RO"
            },
            {
                "name": "Rovinari",
                "countryCode": "RO"
            },
            {
                "name": "Roşia de Amaradia",
                "countryCode": "RO"
            },
            {
                "name": "Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Samarineşti",
                "countryCode": "RO"
            },
            {
                "name": "Scoarţa",
                "countryCode": "RO"
            },
            {
                "name": "Slivileşti",
                "countryCode": "RO"
            },
            {
                "name": "Sohodol",
                "countryCode": "RO"
            },
            {
                "name": "Stejari",
                "countryCode": "RO"
            },
            {
                "name": "Sterpoaia",
                "countryCode": "RO"
            },
            {
                "name": "Stoina",
                "countryCode": "RO"
            },
            {
                "name": "Stăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbotin",
                "countryCode": "RO"
            },
            {
                "name": "Săcelu",
                "countryCode": "RO"
            },
            {
                "name": "Săuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Teleşti",
                "countryCode": "RO"
            },
            {
                "name": "Tismana",
                "countryCode": "RO"
            },
            {
                "name": "Turburea",
                "countryCode": "RO"
            },
            {
                "name": "Turceni",
                "countryCode": "RO"
            },
            {
                "name": "Turcineşti",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Cărbuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Jiu",
                "countryCode": "RO"
            },
            {
                "name": "Urdari",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Vierșani",
                "countryCode": "RO"
            },
            {
                "name": "Vlăduleni",
                "countryCode": "RO"
            },
            {
                "name": "Voiteștii din Vale",
                "countryCode": "RO"
            },
            {
                "name": "Văgiuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Ţicleni",
                "countryCode": "RO"
            },
            {
                "name": "Ţânţăreni",
                "countryCode": "RO"
            },
            {
                "name": "Balintești",
                "countryCode": "RO"
            },
            {
                "name": "Barcea",
                "countryCode": "RO"
            },
            {
                "name": "Bereşti-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Berești",
                "countryCode": "RO"
            },
            {
                "name": "Blânzi",
                "countryCode": "RO"
            },
            {
                "name": "Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Brăhăşeştii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Brăhășești",
                "countryCode": "RO"
            },
            {
                "name": "Bucești",
                "countryCode": "RO"
            },
            {
                "name": "Buciumeni",
                "countryCode": "RO"
            },
            {
                "name": "Băleni",
                "countryCode": "RO"
            },
            {
                "name": "Bălăbănești",
                "countryCode": "RO"
            },
            {
                "name": "Bălăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Cavadineşti",
                "countryCode": "RO"
            },
            {
                "name": "Cerţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Chiraftei",
                "countryCode": "RO"
            },
            {
                "name": "Ciorăști",
                "countryCode": "RO"
            },
            {
                "name": "Cișmele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Barcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bereşti-Meria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăhăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buciumeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cavadineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cosmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costache Negri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cudalbi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Folteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumuşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fârţăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghidigeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gohor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iveşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Matca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Munteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măstăcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negrileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicoreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nămoloasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oancea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pechea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Piscu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Priponeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schela",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scânteieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia Conachi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Smulţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Smârdan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suceveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suhurlui",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tuluceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Umbrăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mãrului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârlezi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şendreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţepu",
                "countryCode": "RO"
            },
            {
                "name": "Corni",
                "countryCode": "RO"
            },
            {
                "name": "Corod",
                "countryCode": "RO"
            },
            {
                "name": "Cosmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cosmeștii-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Costache Negri",
                "countryCode": "RO"
            },
            {
                "name": "Crăiești",
                "countryCode": "RO"
            },
            {
                "name": "Cuca",
                "countryCode": "RO"
            },
            {
                "name": "Cudalbi",
                "countryCode": "RO"
            },
            {
                "name": "Cuza Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Folteşti",
                "countryCode": "RO"
            },
            {
                "name": "Frumuşiţa",
                "countryCode": "RO"
            },
            {
                "name": "Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Furcenii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Fârţăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Galaţi",
                "countryCode": "RO"
            },
            {
                "name": "Ghidigeni",
                "countryCode": "RO"
            },
            {
                "name": "Gohor",
                "countryCode": "RO"
            },
            {
                "name": "Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Gănești",
                "countryCode": "RO"
            },
            {
                "name": "Hanu Conachi",
                "countryCode": "RO"
            },
            {
                "name": "Independenţa",
                "countryCode": "RO"
            },
            {
                "name": "Iveşti",
                "countryCode": "RO"
            },
            {
                "name": "Jorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Lieşti",
                "countryCode": "RO"
            },
            {
                "name": "Matca",
                "countryCode": "RO"
            },
            {
                "name": "Moscu",
                "countryCode": "RO"
            },
            {
                "name": "Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Galaţi",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Tecuci",
                "countryCode": "RO"
            },
            {
                "name": "Munteni",
                "countryCode": "RO"
            },
            {
                "name": "Mândrești",
                "countryCode": "RO"
            },
            {
                "name": "Măstăcani",
                "countryCode": "RO"
            },
            {
                "name": "Negrilești",
                "countryCode": "RO"
            },
            {
                "name": "Nicoreşti",
                "countryCode": "RO"
            },
            {
                "name": "Nămoloasa",
                "countryCode": "RO"
            },
            {
                "name": "Nămoloasa-Sat",
                "countryCode": "RO"
            },
            {
                "name": "Oancea",
                "countryCode": "RO"
            },
            {
                "name": "Odaia Manolache",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bereşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Târgu Bujor",
                "countryCode": "RO"
            },
            {
                "name": "Pechea",
                "countryCode": "RO"
            },
            {
                "name": "Piscu",
                "countryCode": "RO"
            },
            {
                "name": "Podoleni",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Priponeşti",
                "countryCode": "RO"
            },
            {
                "name": "Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Rădești",
                "countryCode": "RO"
            },
            {
                "name": "Schela",
                "countryCode": "RO"
            },
            {
                "name": "Scânteiești",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia Conachi",
                "countryCode": "RO"
            },
            {
                "name": "Smulţi",
                "countryCode": "RO"
            },
            {
                "name": "Smârdan",
                "countryCode": "RO"
            },
            {
                "name": "Suceveni",
                "countryCode": "RO"
            },
            {
                "name": "Suhurlui",
                "countryCode": "RO"
            },
            {
                "name": "Tecuci",
                "countryCode": "RO"
            },
            {
                "name": "Toflea",
                "countryCode": "RO"
            },
            {
                "name": "Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Tuluceşti",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Bujor",
                "countryCode": "RO"
            },
            {
                "name": "Tălpigi",
                "countryCode": "RO"
            },
            {
                "name": "Umbrăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Umbrărești-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mărului",
                "countryCode": "RO"
            },
            {
                "name": "Viile",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Vârlezi",
                "countryCode": "RO"
            },
            {
                "name": "Şendreni",
                "countryCode": "RO"
            },
            {
                "name": "Ţepu",
                "countryCode": "RO"
            },
            {
                "name": "Șivița",
                "countryCode": "RO"
            },
            {
                "name": "Adunații-Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Bolintin Deal",
                "countryCode": "RO"
            },
            {
                "name": "Bolintin Vale",
                "countryCode": "RO"
            },
            {
                "name": "Braniștea",
                "countryCode": "RO"
            },
            {
                "name": "Brăniștari",
                "countryCode": "RO"
            },
            {
                "name": "Bucşani",
                "countryCode": "RO"
            },
            {
                "name": "Bulbucata",
                "countryCode": "RO"
            },
            {
                "name": "Buturugeni",
                "countryCode": "RO"
            },
            {
                "name": "Bâcu",
                "countryCode": "RO"
            },
            {
                "name": "Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Cartojani",
                "countryCode": "RO"
            },
            {
                "name": "Cetatea",
                "countryCode": "RO"
            },
            {
                "name": "Chiriacu",
                "countryCode": "RO"
            },
            {
                "name": "Clejani",
                "countryCode": "RO"
            },
            {
                "name": "Colibaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adunaţii-Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bolintin Deal",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bulbucata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buturugeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Clejani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Colibaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cosoba",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crevedia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călugăreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Daia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghimpaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gogoşari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gostinu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goştinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Greaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gãiseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găujani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Herăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hotarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iepureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Isvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Joiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Letca Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mârşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ogrezeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oinacu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prundu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Putineiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roata De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răsuceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schitu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Singureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săbăreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Toporu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulmi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Dragului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânătorii Mici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comună Floreşti-Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Copaciu",
                "countryCode": "RO"
            },
            {
                "name": "Cosoba",
                "countryCode": "RO"
            },
            {
                "name": "Crevedia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Cucuruzu",
                "countryCode": "RO"
            },
            {
                "name": "Câmpurelu",
                "countryCode": "RO"
            },
            {
                "name": "Călugăreni",
                "countryCode": "RO"
            },
            {
                "name": "Căscioarele",
                "countryCode": "RO"
            },
            {
                "name": "Daia",
                "countryCode": "RO"
            },
            {
                "name": "Dealu",
                "countryCode": "RO"
            },
            {
                "name": "Dobreni",
                "countryCode": "RO"
            },
            {
                "name": "Dărăști-Vlașca",
                "countryCode": "RO"
            },
            {
                "name": "Falaștoaca",
                "countryCode": "RO"
            },
            {
                "name": "Florești",
                "countryCode": "RO"
            },
            {
                "name": "Frăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Ghimpați",
                "countryCode": "RO"
            },
            {
                "name": "Giurgiu",
                "countryCode": "RO"
            },
            {
                "name": "Gogoşari",
                "countryCode": "RO"
            },
            {
                "name": "Gostinari",
                "countryCode": "RO"
            },
            {
                "name": "Gostinu",
                "countryCode": "RO"
            },
            {
                "name": "Goştinari-Văcăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Greaca",
                "countryCode": "RO"
            },
            {
                "name": "Grădinari",
                "countryCode": "RO"
            },
            {
                "name": "Grădiștea",
                "countryCode": "RO"
            },
            {
                "name": "Găiseni",
                "countryCode": "RO"
            },
            {
                "name": "Găujani",
                "countryCode": "RO"
            },
            {
                "name": "Herăști",
                "countryCode": "RO"
            },
            {
                "name": "Hotarele",
                "countryCode": "RO"
            },
            {
                "name": "Hulubești",
                "countryCode": "RO"
            },
            {
                "name": "Icoana",
                "countryCode": "RO"
            },
            {
                "name": "Iepureşti",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Joiţa",
                "countryCode": "RO"
            },
            {
                "name": "Letca Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Letca Veche",
                "countryCode": "RO"
            },
            {
                "name": "Malu",
                "countryCode": "RO"
            },
            {
                "name": "Malu Spart",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Mihăileşti",
                "countryCode": "RO"
            },
            {
                "name": "Milcovățu",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Giurgiu",
                "countryCode": "RO"
            },
            {
                "name": "Mârşa",
                "countryCode": "RO"
            },
            {
                "name": "Naipu",
                "countryCode": "RO"
            },
            {
                "name": "Novaci",
                "countryCode": "RO"
            },
            {
                "name": "Ogrezeni",
                "countryCode": "RO"
            },
            {
                "name": "Oinacu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bolintin-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Mihãileşti",
                "countryCode": "RO"
            },
            {
                "name": "Palanca",
                "countryCode": "RO"
            },
            {
                "name": "Pietrele",
                "countryCode": "RO"
            },
            {
                "name": "Pietrișu",
                "countryCode": "RO"
            },
            {
                "name": "Plopșoru",
                "countryCode": "RO"
            },
            {
                "name": "Popești",
                "countryCode": "RO"
            },
            {
                "name": "Prundu",
                "countryCode": "RO"
            },
            {
                "name": "Puieni",
                "countryCode": "RO"
            },
            {
                "name": "Putineiu",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Remuș",
                "countryCode": "RO"
            },
            {
                "name": "Roata de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Răsuceni",
                "countryCode": "RO"
            },
            {
                "name": "Sadina",
                "countryCode": "RO"
            },
            {
                "name": "Schitu",
                "countryCode": "RO"
            },
            {
                "name": "Singureni",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Stăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Săbăreni",
                "countryCode": "RO"
            },
            {
                "name": "Toporu",
                "countryCode": "RO"
            },
            {
                "name": "Trestieni",
                "countryCode": "RO"
            },
            {
                "name": "Tântava",
                "countryCode": "RO"
            },
            {
                "name": "Ulmi",
                "countryCode": "RO"
            },
            {
                "name": "Uzunu",
                "countryCode": "RO"
            },
            {
                "name": "Valea Dragului",
                "countryCode": "RO"
            },
            {
                "name": "Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Vieru",
                "countryCode": "RO"
            },
            {
                "name": "Vlad Țepeș",
                "countryCode": "RO"
            },
            {
                "name": "Vânătorii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Vânătorii Mici",
                "countryCode": "RO"
            },
            {
                "name": "Vărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Zorile",
                "countryCode": "RO"
            },
            {
                "name": "Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Baia de Criş",
                "countryCode": "RO"
            },
            {
                "name": "Balşa",
                "countryCode": "RO"
            },
            {
                "name": "Baru",
                "countryCode": "RO"
            },
            {
                "name": "Baru Mic",
                "countryCode": "RO"
            },
            {
                "name": "Barza",
                "countryCode": "RO"
            },
            {
                "name": "Beriu",
                "countryCode": "RO"
            },
            {
                "name": "Blăjeni",
                "countryCode": "RO"
            },
            {
                "name": "Boşorod",
                "countryCode": "RO"
            },
            {
                "name": "Brad",
                "countryCode": "RO"
            },
            {
                "name": "Brazi",
                "countryCode": "RO"
            },
            {
                "name": "Bretea Română",
                "countryCode": "RO"
            },
            {
                "name": "Brănişca",
                "countryCode": "RO"
            },
            {
                "name": "Buceş",
                "countryCode": "RO"
            },
            {
                "name": "Bucium-Orlea",
                "countryCode": "RO"
            },
            {
                "name": "Bucureşci",
                "countryCode": "RO"
            },
            {
                "name": "Bulzeștii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Bunila",
                "countryCode": "RO"
            },
            {
                "name": "Burjuc",
                "countryCode": "RO"
            },
            {
                "name": "Băcia",
                "countryCode": "RO"
            },
            {
                "name": "Băiţa",
                "countryCode": "RO"
            },
            {
                "name": "Băniţa",
                "countryCode": "RO"
            },
            {
                "name": "Bătrâna",
                "countryCode": "RO"
            },
            {
                "name": "Cerbăl",
                "countryCode": "RO"
            },
            {
                "name": "Certeju de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baia de Criş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beriu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blăjeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boşorod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bretea Română",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brănişca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buceş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucureşci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bulzeştii De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bunila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Burjuc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băcia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bătrâna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerbăl",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Certeju de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crişcior",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârjiţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Densuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna General Berthelot",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghelari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gurasada",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hărău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lelese",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca Cernii De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luncoiu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lãpugiu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărtineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orăştioara de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pestişu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pui",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rapoltu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ribiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Romos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râu de Mori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răchitova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sarmizegetusa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sântămăria-Orlea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sãlaşu De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teliucu Inferior",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Toteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turdaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vaţa De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Veţel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vorţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vălişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zam",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şoimuş",
                "countryCode": "RO"
            },
            {
                "name": "Cristur",
                "countryCode": "RO"
            },
            {
                "name": "Crişcior",
                "countryCode": "RO"
            },
            {
                "name": "Cârjiţi",
                "countryCode": "RO"
            },
            {
                "name": "Călan",
                "countryCode": "RO"
            },
            {
                "name": "Căstău",
                "countryCode": "RO"
            },
            {
                "name": "Densuş",
                "countryCode": "RO"
            },
            {
                "name": "Deva",
                "countryCode": "RO"
            },
            {
                "name": "Dobra",
                "countryCode": "RO"
            },
            {
                "name": "General Berthelot",
                "countryCode": "RO"
            },
            {
                "name": "Geoagiu",
                "countryCode": "RO"
            },
            {
                "name": "Ghelari",
                "countryCode": "RO"
            },
            {
                "name": "Gurasada",
                "countryCode": "RO"
            },
            {
                "name": "Haţeg",
                "countryCode": "RO"
            },
            {
                "name": "Hunedoara",
                "countryCode": "RO"
            },
            {
                "name": "Hărău",
                "countryCode": "RO"
            },
            {
                "name": "Hărțăgani",
                "countryCode": "RO"
            },
            {
                "name": "Ilia",
                "countryCode": "RO"
            },
            {
                "name": "Iscroni",
                "countryCode": "RO"
            },
            {
                "name": "Jiu-Paroșeni",
                "countryCode": "RO"
            },
            {
                "name": "Lelese",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Cernii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Luncoiu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Lupeni",
                "countryCode": "RO"
            },
            {
                "name": "Lăpugiu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul  Lupeni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul  Vulcan",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Brad",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Deva",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Hunedoara",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Orãştie",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Petroşani",
                "countryCode": "RO"
            },
            {
                "name": "Mărtineşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Aninoasa",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cãlan",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Geoagiu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Haţeg",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Petrila",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Simeria",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Uricani",
                "countryCode": "RO"
            },
            {
                "name": "Orăştie",
                "countryCode": "RO"
            },
            {
                "name": "Orăştioara de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Petrila",
                "countryCode": "RO"
            },
            {
                "name": "Petroşani",
                "countryCode": "RO"
            },
            {
                "name": "Peştişu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Peștișu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Pricaz",
                "countryCode": "RO"
            },
            {
                "name": "Pui",
                "countryCode": "RO"
            },
            {
                "name": "Rapoltu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ribiţa",
                "countryCode": "RO"
            },
            {
                "name": "Romos",
                "countryCode": "RO"
            },
            {
                "name": "Râu de Mori",
                "countryCode": "RO"
            },
            {
                "name": "Răchitova",
                "countryCode": "RO"
            },
            {
                "name": "Sarmizegetusa",
                "countryCode": "RO"
            },
            {
                "name": "Simeria",
                "countryCode": "RO"
            },
            {
                "name": "Sântămăria-Orlea",
                "countryCode": "RO"
            },
            {
                "name": "Sălaşu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Teliucu Inferior",
                "countryCode": "RO"
            },
            {
                "name": "Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Topliţa",
                "countryCode": "RO"
            },
            {
                "name": "Toteşti",
                "countryCode": "RO"
            },
            {
                "name": "Turdaş",
                "countryCode": "RO"
            },
            {
                "name": "Uricani",
                "countryCode": "RO"
            },
            {
                "name": "Vaţa de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Veţel",
                "countryCode": "RO"
            },
            {
                "name": "Vorţa",
                "countryCode": "RO"
            },
            {
                "name": "Vulcan",
                "countryCode": "RO"
            },
            {
                "name": "Vălişoara",
                "countryCode": "RO"
            },
            {
                "name": "Zam",
                "countryCode": "RO"
            },
            {
                "name": "Zdrapți",
                "countryCode": "RO"
            },
            {
                "name": "Şoimuş",
                "countryCode": "RO"
            },
            {
                "name": "Atid",
                "countryCode": "RO"
            },
            {
                "name": "Avrămeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bancu",
                "countryCode": "RO"
            },
            {
                "name": "Bilbor",
                "countryCode": "RO"
            },
            {
                "name": "Borsec",
                "countryCode": "RO"
            },
            {
                "name": "Brădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Băile Tuşnad",
                "countryCode": "RO"
            },
            {
                "name": "Bălan",
                "countryCode": "RO"
            },
            {
                "name": "Ciceu",
                "countryCode": "RO"
            },
            {
                "name": "Ciucani",
                "countryCode": "RO"
            },
            {
                "name": "Ciucsângeorgiu",
                "countryCode": "RO"
            },
            {
                "name": "Ciumani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Atid",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Avrămeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bilbor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciceu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciucsângeorgiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciumani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corund",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cozmeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căpâlniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dealu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ditrău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dârjiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Feliceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gălăuţaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Joseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leliceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lueta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lupeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăzarea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mereşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mugeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mădăraş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărtiniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ocland",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plãieşii De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Porumbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Praid",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pãuleni-Ciuc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Remetea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secuieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siculeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Simoneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Subcetate",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suseni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâncrăieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sândominic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmartin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânsimion",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sântimbru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sărmaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tulgheş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tuşnad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulieş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voşlãbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vărşag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zetea",
                "countryCode": "RO"
            },
            {
                "name": "Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Corund",
                "countryCode": "RO"
            },
            {
                "name": "Cozmeni",
                "countryCode": "RO"
            },
            {
                "name": "Cristuru Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Cârţa",
                "countryCode": "RO"
            },
            {
                "name": "Căpâlniţa",
                "countryCode": "RO"
            },
            {
                "name": "Dealu",
                "countryCode": "RO"
            },
            {
                "name": "Ditrău",
                "countryCode": "RO"
            },
            {
                "name": "Dârjiu",
                "countryCode": "RO"
            },
            {
                "name": "Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Eliseni",
                "countryCode": "RO"
            },
            {
                "name": "Feliceni",
                "countryCode": "RO"
            },
            {
                "name": "Filiaș",
                "countryCode": "RO"
            },
            {
                "name": "Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Gheorgheni",
                "countryCode": "RO"
            },
            {
                "name": "Gălăuţaş",
                "countryCode": "RO"
            },
            {
                "name": "Hodoșa",
                "countryCode": "RO"
            },
            {
                "name": "Imper",
                "countryCode": "RO"
            },
            {
                "name": "Ineu",
                "countryCode": "RO"
            },
            {
                "name": "Joseni",
                "countryCode": "RO"
            },
            {
                "name": "Leliceni",
                "countryCode": "RO"
            },
            {
                "name": "Lueta",
                "countryCode": "RO"
            },
            {
                "name": "Lunca de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Lunca de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Lupeni",
                "countryCode": "RO"
            },
            {
                "name": "Lăzarea",
                "countryCode": "RO"
            },
            {
                "name": "Mereşti",
                "countryCode": "RO"
            },
            {
                "name": "Miercurea-Ciuc",
                "countryCode": "RO"
            },
            {
                "name": "Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Misentea",
                "countryCode": "RO"
            },
            {
                "name": "Mugeni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul  Topliţa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Gheorgheni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Miercurea Ciuc",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Odorheiu Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Mădăraș",
                "countryCode": "RO"
            },
            {
                "name": "Mărtiniş",
                "countryCode": "RO"
            },
            {
                "name": "Nicolești",
                "countryCode": "RO"
            },
            {
                "name": "Ocland",
                "countryCode": "RO"
            },
            {
                "name": "Ocna de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ocna de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Odorheiu Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Borsec",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãile Tuşnad",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãlan",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cristuru Secuiesc",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Vlãhiţa",
                "countryCode": "RO"
            },
            {
                "name": "Porumbenii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Praid",
                "countryCode": "RO"
            },
            {
                "name": "Păuleni-Ciuc",
                "countryCode": "RO"
            },
            {
                "name": "Racu",
                "countryCode": "RO"
            },
            {
                "name": "Remetea",
                "countryCode": "RO"
            },
            {
                "name": "Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Siculeni",
                "countryCode": "RO"
            },
            {
                "name": "Simoneşti",
                "countryCode": "RO"
            },
            {
                "name": "Subcetate",
                "countryCode": "RO"
            },
            {
                "name": "Suseni",
                "countryCode": "RO"
            },
            {
                "name": "Sâncrai",
                "countryCode": "RO"
            },
            {
                "name": "Sâncrăieni",
                "countryCode": "RO"
            },
            {
                "name": "Sândominic",
                "countryCode": "RO"
            },
            {
                "name": "Sânmartin",
                "countryCode": "RO"
            },
            {
                "name": "Sânsimion",
                "countryCode": "RO"
            },
            {
                "name": "Sântimbru",
                "countryCode": "RO"
            },
            {
                "name": "Săcel",
                "countryCode": "RO"
            },
            {
                "name": "Sărmaş",
                "countryCode": "RO"
            },
            {
                "name": "Tomești",
                "countryCode": "RO"
            },
            {
                "name": "Tulgheş",
                "countryCode": "RO"
            },
            {
                "name": "Tuşnad",
                "countryCode": "RO"
            },
            {
                "name": "Tușnadu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Tăureni",
                "countryCode": "RO"
            },
            {
                "name": "Vale",
                "countryCode": "RO"
            },
            {
                "name": "Valea Rece",
                "countryCode": "RO"
            },
            {
                "name": "Valea Strâmbă",
                "countryCode": "RO"
            },
            {
                "name": "Vlăhiţa",
                "countryCode": "RO"
            },
            {
                "name": "Voșlăbeni",
                "countryCode": "RO"
            },
            {
                "name": "Vărşag",
                "countryCode": "RO"
            },
            {
                "name": "Zencani",
                "countryCode": "RO"
            },
            {
                "name": "Zetea",
                "countryCode": "RO"
            },
            {
                "name": "1 Decembrie",
                "countryCode": "RO"
            },
            {
                "name": "Afumaţi",
                "countryCode": "RO"
            },
            {
                "name": "Alunișu",
                "countryCode": "RO"
            },
            {
                "name": "Baloteşti",
                "countryCode": "RO"
            },
            {
                "name": "Berceni",
                "countryCode": "RO"
            },
            {
                "name": "Bragadiru",
                "countryCode": "RO"
            },
            {
                "name": "Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Buciumeni",
                "countryCode": "RO"
            },
            {
                "name": "Buftea",
                "countryCode": "RO"
            },
            {
                "name": "Buriaș",
                "countryCode": "RO"
            },
            {
                "name": "Bălăceanca",
                "countryCode": "RO"
            },
            {
                "name": "Cernica",
                "countryCode": "RO"
            },
            {
                "name": "Chiajna",
                "countryCode": "RO"
            },
            {
                "name": "Chitila",
                "countryCode": "RO"
            },
            {
                "name": "Ciofliceni",
                "countryCode": "RO"
            },
            {
                "name": "Ciolpani",
                "countryCode": "RO"
            },
            {
                "name": "Ciorogârla",
                "countryCode": "RO"
            },
            {
                "name": "Clinceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna 1 Decembrie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Afumaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baloteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cernica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiajna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciolpani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciorogârla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Clinceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbeanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornetu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dascălu Creaţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Domneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragomireşti-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dărăşti-Ilfov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fundeni-Dobroeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gruiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jilava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moara Vlãsiei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mogoşoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nuci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Periş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petrăchioaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Snagov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tunari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefãneştii De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Corbeanca",
                "countryCode": "RO"
            },
            {
                "name": "Cornetu",
                "countryCode": "RO"
            },
            {
                "name": "Crețești",
                "countryCode": "RO"
            },
            {
                "name": "Căciulați",
                "countryCode": "RO"
            },
            {
                "name": "Căldăraru",
                "countryCode": "RO"
            },
            {
                "name": "Cățelu",
                "countryCode": "RO"
            },
            {
                "name": "Dascălu",
                "countryCode": "RO"
            },
            {
                "name": "Dobroeşti",
                "countryCode": "RO"
            },
            {
                "name": "Domneşti-Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Domnești",
                "countryCode": "RO"
            },
            {
                "name": "Dragomireşti-Vale",
                "countryCode": "RO"
            },
            {
                "name": "Dragomirești-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Dârvari",
                "countryCode": "RO"
            },
            {
                "name": "Dărăşti-Ilfov",
                "countryCode": "RO"
            },
            {
                "name": "Fundeni",
                "countryCode": "RO"
            },
            {
                "name": "Ghermănești",
                "countryCode": "RO"
            },
            {
                "name": "Glina",
                "countryCode": "RO"
            },
            {
                "name": "Gruiu",
                "countryCode": "RO"
            },
            {
                "name": "Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Găneasa",
                "countryCode": "RO"
            },
            {
                "name": "Islaz",
                "countryCode": "RO"
            },
            {
                "name": "Jilava",
                "countryCode": "RO"
            },
            {
                "name": "Lipia",
                "countryCode": "RO"
            },
            {
                "name": "Merii Petchii",
                "countryCode": "RO"
            },
            {
                "name": "Moara Vlăsiei",
                "countryCode": "RO"
            },
            {
                "name": "Mogoşoaia",
                "countryCode": "RO"
            },
            {
                "name": "Măgurele",
                "countryCode": "RO"
            },
            {
                "name": "Nuci",
                "countryCode": "RO"
            },
            {
                "name": "Olteni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bragadiru",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Buftea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Chitila",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Mãgurele",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Otopeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Pantelimon",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Popeşti Leordeni",
                "countryCode": "RO"
            },
            {
                "name": "Otopeni",
                "countryCode": "RO"
            },
            {
                "name": "Pantelimon",
                "countryCode": "RO"
            },
            {
                "name": "Periş",
                "countryCode": "RO"
            },
            {
                "name": "Petrăchioaia",
                "countryCode": "RO"
            },
            {
                "name": "Piteasca",
                "countryCode": "RO"
            },
            {
                "name": "Popeşti-Leordeni",
                "countryCode": "RO"
            },
            {
                "name": "Roșu",
                "countryCode": "RO"
            },
            {
                "name": "Rudeni",
                "countryCode": "RO"
            },
            {
                "name": "Siliștea Snagovului",
                "countryCode": "RO"
            },
            {
                "name": "Sintești",
                "countryCode": "RO"
            },
            {
                "name": "Snagov",
                "countryCode": "RO"
            },
            {
                "name": "Tamași",
                "countryCode": "RO"
            },
            {
                "name": "Tunari",
                "countryCode": "RO"
            },
            {
                "name": "Tânganu",
                "countryCode": "RO"
            },
            {
                "name": "Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Voluntari",
                "countryCode": "RO"
            },
            {
                "name": "Voluntari City",
                "countryCode": "RO"
            },
            {
                "name": "Vârteju",
                "countryCode": "RO"
            },
            {
                "name": "Ştefăneştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Adâncata",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Alexeni",
                "countryCode": "RO"
            },
            {
                "name": "Amara",
                "countryCode": "RO"
            },
            {
                "name": "Andrăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Armăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Axintele",
                "countryCode": "RO"
            },
            {
                "name": "Balaciu",
                "countryCode": "RO"
            },
            {
                "name": "Borduşani",
                "countryCode": "RO"
            },
            {
                "name": "Borănești",
                "countryCode": "RO"
            },
            {
                "name": "Broșteni",
                "countryCode": "RO"
            },
            {
                "name": "Bucu",
                "countryCode": "RO"
            },
            {
                "name": "Buești",
                "countryCode": "RO"
            },
            {
                "name": "Buliga",
                "countryCode": "RO"
            },
            {
                "name": "Bărbulești",
                "countryCode": "RO"
            },
            {
                "name": "Bărcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cegani",
                "countryCode": "RO"
            },
            {
                "name": "Ciochina",
                "countryCode": "RO"
            },
            {
                "name": "Ciocârlia",
                "countryCode": "RO"
            },
            {
                "name": "Ciulniţa",
                "countryCode": "RO"
            },
            {
                "name": "Cocora",
                "countryCode": "RO"
            },
            {
                "name": "Colelia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adâncata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alexeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Andrăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Armăşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Axintele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balaciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borduşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bueşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărbuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciochina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocârlia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciulniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cocora",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Colelia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cosâmbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşereni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dridu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Făcăeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gheorghe Doja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gheorghe Lazăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giurgeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grindu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Ialomiţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârbovi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ion Roată",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jilavele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Maia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Manasia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Miloşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moldoveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moviliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Munteni Buzău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ograda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perieţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Platoneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Reviga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşiori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răduleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scânteia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sinteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stelnica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sudiţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sărăţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Ciorii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Măcrişului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Condeești",
                "countryCode": "RO"
            },
            {
                "name": "Cosâmbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Coşereni",
                "countryCode": "RO"
            },
            {
                "name": "Căzăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Dridu",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoeşti-Snagov",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoești",
                "countryCode": "RO"
            },
            {
                "name": "Feteşti",
                "countryCode": "RO"
            },
            {
                "name": "Fetești-Gară",
                "countryCode": "RO"
            },
            {
                "name": "Fierbinţi-Târg",
                "countryCode": "RO"
            },
            {
                "name": "Fierbinții de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Fierbinții de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Făcăeni",
                "countryCode": "RO"
            },
            {
                "name": "Gheorghe Doja",
                "countryCode": "RO"
            },
            {
                "name": "Gheorghe Lazăr",
                "countryCode": "RO"
            },
            {
                "name": "Giurgeni",
                "countryCode": "RO"
            },
            {
                "name": "Grindu",
                "countryCode": "RO"
            },
            {
                "name": "Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Gura Ialomiței",
                "countryCode": "RO"
            },
            {
                "name": "Gârbovi",
                "countryCode": "RO"
            },
            {
                "name": "Iazu",
                "countryCode": "RO"
            },
            {
                "name": "Ion Roată",
                "countryCode": "RO"
            },
            {
                "name": "Jilavele",
                "countryCode": "RO"
            },
            {
                "name": "Luciu",
                "countryCode": "RO"
            },
            {
                "name": "Lăcusteni",
                "countryCode": "RO"
            },
            {
                "name": "Maia",
                "countryCode": "RO"
            },
            {
                "name": "Malu Roșu",
                "countryCode": "RO"
            },
            {
                "name": "Manasia",
                "countryCode": "RO"
            },
            {
                "name": "Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Miloşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Moldoveni",
                "countryCode": "RO"
            },
            {
                "name": "Movila",
                "countryCode": "RO"
            },
            {
                "name": "Moviliţa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Feteşti",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Urziceni",
                "countryCode": "RO"
            },
            {
                "name": "Munteni Buzău",
                "countryCode": "RO"
            },
            {
                "name": "Mărculești",
                "countryCode": "RO"
            },
            {
                "name": "Ograda",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Amara",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cãzãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Fierbinţi-Târg",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ţãndãrei",
                "countryCode": "RO"
            },
            {
                "name": "Patru Frați",
                "countryCode": "RO"
            },
            {
                "name": "Perieţi",
                "countryCode": "RO"
            },
            {
                "name": "Platonești",
                "countryCode": "RO"
            },
            {
                "name": "Rași",
                "countryCode": "RO"
            },
            {
                "name": "Reviga",
                "countryCode": "RO"
            },
            {
                "name": "Rovine",
                "countryCode": "RO"
            },
            {
                "name": "Roșiori",
                "countryCode": "RO"
            },
            {
                "name": "Rădulești",
                "countryCode": "RO"
            },
            {
                "name": "Scânteia",
                "countryCode": "RO"
            },
            {
                "name": "Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Sineşti",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Smirna",
                "countryCode": "RO"
            },
            {
                "name": "Stelnica",
                "countryCode": "RO"
            },
            {
                "name": "Sudiţi",
                "countryCode": "RO"
            },
            {
                "name": "Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Sărățeni",
                "countryCode": "RO"
            },
            {
                "name": "Săveni",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Urziceni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Ciorii",
                "countryCode": "RO"
            },
            {
                "name": "Valea Măcrișului",
                "countryCode": "RO"
            },
            {
                "name": "Vlașca",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Ţăndărei",
                "countryCode": "RO"
            },
            {
                "name": "Alexandru I. Cuza",
                "countryCode": "RO"
            },
            {
                "name": "Andrieşeni",
                "countryCode": "RO"
            },
            {
                "name": "Aroneanu",
                "countryCode": "RO"
            },
            {
                "name": "Balș",
                "countryCode": "RO"
            },
            {
                "name": "Belceşti",
                "countryCode": "RO"
            },
            {
                "name": "Bivolari",
                "countryCode": "RO"
            },
            {
                "name": "Bohotin",
                "countryCode": "RO"
            },
            {
                "name": "Borosoaia",
                "countryCode": "RO"
            },
            {
                "name": "Bosia",
                "countryCode": "RO"
            },
            {
                "name": "Boureni",
                "countryCode": "RO"
            },
            {
                "name": "Breazu",
                "countryCode": "RO"
            },
            {
                "name": "Brădicești",
                "countryCode": "RO"
            },
            {
                "name": "Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Brătești",
                "countryCode": "RO"
            },
            {
                "name": "Buda",
                "countryCode": "RO"
            },
            {
                "name": "Budăi",
                "countryCode": "RO"
            },
            {
                "name": "Buhalnița",
                "countryCode": "RO"
            },
            {
                "name": "Butea",
                "countryCode": "RO"
            },
            {
                "name": "Buznea",
                "countryCode": "RO"
            },
            {
                "name": "Bârnova",
                "countryCode": "RO"
            },
            {
                "name": "Bădeni",
                "countryCode": "RO"
            },
            {
                "name": "Bălţaţi",
                "countryCode": "RO"
            },
            {
                "name": "Cepleniţa",
                "countryCode": "RO"
            },
            {
                "name": "Chișcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Ciohorăni",
                "countryCode": "RO"
            },
            {
                "name": "Ciorteşti",
                "countryCode": "RO"
            },
            {
                "name": "Ciurea",
                "countryCode": "RO"
            },
            {
                "name": "Coarnele Caprei",
                "countryCode": "RO"
            },
            {
                "name": "Cogeasca",
                "countryCode": "RO"
            },
            {
                "name": "Comarna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alexandru I. Cuza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Andrieşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aroneanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Belceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bivolari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Butea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârnova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălţaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cepleniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciohorãni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciorteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciurea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coarnele Caprei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comarna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costuleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cotnari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cozmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cucuteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dagâţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobrovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dolheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Erbiceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Focuri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Golãieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gorban",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grajduri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gropniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grozeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Heleşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Holboca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hălăuceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ion Neculce",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ipatele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lespezi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leţcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lungani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mirceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mironeasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Miroslava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mirosloveşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mogoşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mogoşeşti-Siret",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moşna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moţca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mădârjac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oţeleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plugari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popricani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prisăcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Probota",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rediu-Tătar",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Româneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ruginoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răchiţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răducăneni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scheia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schitu-Duca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scobinţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scânteia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sireţel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stolniceni-Prăjescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Strunga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tansa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Trifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătăruşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ungheni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Lupului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânãtori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şipote",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţibana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţibăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţigănaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţuţora",
                "countryCode": "RO"
            },
            {
                "name": "Conțești",
                "countryCode": "RO"
            },
            {
                "name": "Coropceni",
                "countryCode": "RO"
            },
            {
                "name": "Costești",
                "countryCode": "RO"
            },
            {
                "name": "Costuleni",
                "countryCode": "RO"
            },
            {
                "name": "Cotnari",
                "countryCode": "RO"
            },
            {
                "name": "Covasna",
                "countryCode": "RO"
            },
            {
                "name": "Cozia",
                "countryCode": "RO"
            },
            {
                "name": "Cozmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cristeşti",
                "countryCode": "RO"
            },
            {
                "name": "Crivești",
                "countryCode": "RO"
            },
            {
                "name": "Crucea",
                "countryCode": "RO"
            },
            {
                "name": "Cucuteni",
                "countryCode": "RO"
            },
            {
                "name": "Cârjoaia",
                "countryCode": "RO"
            },
            {
                "name": "Cârniceni",
                "countryCode": "RO"
            },
            {
                "name": "Dagâţa",
                "countryCode": "RO"
            },
            {
                "name": "Dancu",
                "countryCode": "RO"
            },
            {
                "name": "Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Dobrovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Dolheşti",
                "countryCode": "RO"
            },
            {
                "name": "Domnița",
                "countryCode": "RO"
            },
            {
                "name": "Drăgușeni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăvița",
                "countryCode": "RO"
            },
            {
                "name": "Dumeşti",
                "countryCode": "RO"
            },
            {
                "name": "Erbiceni",
                "countryCode": "RO"
            },
            {
                "name": "Fetești",
                "countryCode": "RO"
            },
            {
                "name": "Focuri",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Fărcășeni",
                "countryCode": "RO"
            },
            {
                "name": "Glodenii Gândului",
                "countryCode": "RO"
            },
            {
                "name": "Goești",
                "countryCode": "RO"
            },
            {
                "name": "Golăiești",
                "countryCode": "RO"
            },
            {
                "name": "Gorban",
                "countryCode": "RO"
            },
            {
                "name": "Goruni",
                "countryCode": "RO"
            },
            {
                "name": "Grajduri",
                "countryCode": "RO"
            },
            {
                "name": "Gropniţa",
                "countryCode": "RO"
            },
            {
                "name": "Grozeşti",
                "countryCode": "RO"
            },
            {
                "name": "Gârbești",
                "countryCode": "RO"
            },
            {
                "name": "Heci",
                "countryCode": "RO"
            },
            {
                "name": "Heleșteni",
                "countryCode": "RO"
            },
            {
                "name": "Hodora",
                "countryCode": "RO"
            },
            {
                "name": "Holboca",
                "countryCode": "RO"
            },
            {
                "name": "Horleşti",
                "countryCode": "RO"
            },
            {
                "name": "Hârlău",
                "countryCode": "RO"
            },
            {
                "name": "Hârtoape",
                "countryCode": "RO"
            },
            {
                "name": "Hălăuceşti",
                "countryCode": "RO"
            },
            {
                "name": "Hărmăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Hărmăneștii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Iazu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Iaşi",
                "countryCode": "RO"
            },
            {
                "name": "Ion Neculce",
                "countryCode": "RO"
            },
            {
                "name": "Ipatele",
                "countryCode": "RO"
            },
            {
                "name": "Iugani",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Lespezi",
                "countryCode": "RO"
            },
            {
                "name": "Leţcani",
                "countryCode": "RO"
            },
            {
                "name": "Liteni",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Cetățuii",
                "countryCode": "RO"
            },
            {
                "name": "Lungani",
                "countryCode": "RO"
            },
            {
                "name": "Maxut",
                "countryCode": "RO"
            },
            {
                "name": "Mirceşti",
                "countryCode": "RO"
            },
            {
                "name": "Mironeasa",
                "countryCode": "RO"
            },
            {
                "name": "Miroslava",
                "countryCode": "RO"
            },
            {
                "name": "Miroslovești",
                "countryCode": "RO"
            },
            {
                "name": "Mogoşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Mogoşeşti-Siret",
                "countryCode": "RO"
            },
            {
                "name": "Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Moşna",
                "countryCode": "RO"
            },
            {
                "name": "Moţca",
                "countryCode": "RO"
            },
            {
                "name": "Muncelu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Iaşi",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Paşcani",
                "countryCode": "RO"
            },
            {
                "name": "Munteni",
                "countryCode": "RO"
            },
            {
                "name": "Mădârjac",
                "countryCode": "RO"
            },
            {
                "name": "Mănăstirea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Hârlãu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Podu Iloaiei",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Târgu Frumos",
                "countryCode": "RO"
            },
            {
                "name": "Osoi",
                "countryCode": "RO"
            },
            {
                "name": "Oţeleni",
                "countryCode": "RO"
            },
            {
                "name": "Paşcani",
                "countryCode": "RO"
            },
            {
                "name": "Perieni",
                "countryCode": "RO"
            },
            {
                "name": "Pietrosu",
                "countryCode": "RO"
            },
            {
                "name": "Plugari",
                "countryCode": "RO"
            },
            {
                "name": "Podolenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Podu Iloaiei",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Mărului",
                "countryCode": "RO"
            },
            {
                "name": "Poienile",
                "countryCode": "RO"
            },
            {
                "name": "Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Popricani",
                "countryCode": "RO"
            },
            {
                "name": "Prisăcani",
                "countryCode": "RO"
            },
            {
                "name": "Probota",
                "countryCode": "RO"
            },
            {
                "name": "Pârcovaci",
                "countryCode": "RO"
            },
            {
                "name": "Păușești",
                "countryCode": "RO"
            },
            {
                "name": "Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Româneşti",
                "countryCode": "RO"
            },
            {
                "name": "Roșcani",
                "countryCode": "RO"
            },
            {
                "name": "Ruginoasa",
                "countryCode": "RO"
            },
            {
                "name": "Răchiteni",
                "countryCode": "RO"
            },
            {
                "name": "Răducăneni",
                "countryCode": "RO"
            },
            {
                "name": "Războieni",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Scheia",
                "countryCode": "RO"
            },
            {
                "name": "Schitu-Duca",
                "countryCode": "RO"
            },
            {
                "name": "Scobinţi",
                "countryCode": "RO"
            },
            {
                "name": "Scânteia",
                "countryCode": "RO"
            },
            {
                "name": "Sineşti",
                "countryCode": "RO"
            },
            {
                "name": "Sireţel",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Soci",
                "countryCode": "RO"
            },
            {
                "name": "Sodomeni",
                "countryCode": "RO"
            },
            {
                "name": "Sticlăria",
                "countryCode": "RO"
            },
            {
                "name": "Stolniceni-Prăjescu",
                "countryCode": "RO"
            },
            {
                "name": "Stornești",
                "countryCode": "RO"
            },
            {
                "name": "Strunga",
                "countryCode": "RO"
            },
            {
                "name": "Suhuleț",
                "countryCode": "RO"
            },
            {
                "name": "Tabăra",
                "countryCode": "RO"
            },
            {
                "name": "Tansa",
                "countryCode": "RO"
            },
            {
                "name": "Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Topile",
                "countryCode": "RO"
            },
            {
                "name": "Totoești",
                "countryCode": "RO"
            },
            {
                "name": "Trifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Frumos",
                "countryCode": "RO"
            },
            {
                "name": "Tătăruşi",
                "countryCode": "RO"
            },
            {
                "name": "Uda",
                "countryCode": "RO"
            },
            {
                "name": "Valea Lupului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Verșeni",
                "countryCode": "RO"
            },
            {
                "name": "Victoria",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeni",
                "countryCode": "RO"
            },
            {
                "name": "Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Zagavia",
                "countryCode": "RO"
            },
            {
                "name": "Zmeu",
                "countryCode": "RO"
            },
            {
                "name": "Şipote",
                "countryCode": "RO"
            },
            {
                "name": "Ţibana",
                "countryCode": "RO"
            },
            {
                "name": "Ţibăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Ţigănaşi",
                "countryCode": "RO"
            },
            {
                "name": "Ţuţora",
                "countryCode": "RO"
            },
            {
                "name": "Baia de Aramă",
                "countryCode": "RO"
            },
            {
                "name": "Bala",
                "countryCode": "RO"
            },
            {
                "name": "Balta",
                "countryCode": "RO"
            },
            {
                "name": "Balta Verde",
                "countryCode": "RO"
            },
            {
                "name": "Bistrița",
                "countryCode": "RO"
            },
            {
                "name": "Braniștea",
                "countryCode": "RO"
            },
            {
                "name": "Brezniţa Ocol",
                "countryCode": "RO"
            },
            {
                "name": "Brezniţa-Motru",
                "countryCode": "RO"
            },
            {
                "name": "Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Burila Mare",
                "countryCode": "RO"
            },
            {
                "name": "Butoieşti",
                "countryCode": "RO"
            },
            {
                "name": "Bâcleș",
                "countryCode": "RO"
            },
            {
                "name": "Bâlvăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bălăciţa",
                "countryCode": "RO"
            },
            {
                "name": "Cerneți",
                "countryCode": "RO"
            },
            {
                "name": "Ciochiuța",
                "countryCode": "RO"
            },
            {
                "name": "Cioroboreni",
                "countryCode": "RO"
            },
            {
                "name": "Cireşu",
                "countryCode": "RO"
            },
            {
                "name": "Comanda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bala",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Braniştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brezniţa Ocol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brezniţa-Motru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Burila Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Butoieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bâcleş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bâlvăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăciţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cireşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corcova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corlăţel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cujmir",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căzăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Devesel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dubova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dârvari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Eşelniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Godeanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gogoşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Greci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grozeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gruia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârla Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hinova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Husnicioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iloviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilovăt",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Isverna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoru Bârzii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jiana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malovãţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Obârşia De Câmp",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Obârşia-Cloşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oprişor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Padina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ponoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poroina Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pristol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prunişor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Punghina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pătulele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rogova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Simian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sovarna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stângăceaua",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sviniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tâmna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voloiac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vrata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânjuleţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Corcova",
                "countryCode": "RO"
            },
            {
                "name": "Corlăţel",
                "countryCode": "RO"
            },
            {
                "name": "Cujmir",
                "countryCode": "RO"
            },
            {
                "name": "Căzăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Devesel",
                "countryCode": "RO"
            },
            {
                "name": "Drobeta-Turnu Severin",
                "countryCode": "RO"
            },
            {
                "name": "Dubova",
                "countryCode": "RO"
            },
            {
                "name": "Dudașu",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Dârvari",
                "countryCode": "RO"
            },
            {
                "name": "Dănceu",
                "countryCode": "RO"
            },
            {
                "name": "Eşelniţa",
                "countryCode": "RO"
            },
            {
                "name": "Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Gemeni",
                "countryCode": "RO"
            },
            {
                "name": "Godeanu",
                "countryCode": "RO"
            },
            {
                "name": "Gogoşu",
                "countryCode": "RO"
            },
            {
                "name": "Greci",
                "countryCode": "RO"
            },
            {
                "name": "Grozeşti",
                "countryCode": "RO"
            },
            {
                "name": "Gruia",
                "countryCode": "RO"
            },
            {
                "name": "Gura Văii",
                "countryCode": "RO"
            },
            {
                "name": "Gvardinița",
                "countryCode": "RO"
            },
            {
                "name": "Gârla-Mare",
                "countryCode": "RO"
            },
            {
                "name": "Halânga",
                "countryCode": "RO"
            },
            {
                "name": "Hinova",
                "countryCode": "RO"
            },
            {
                "name": "Husnicioara",
                "countryCode": "RO"
            },
            {
                "name": "Iloviţa",
                "countryCode": "RO"
            },
            {
                "name": "Ilovăț",
                "countryCode": "RO"
            },
            {
                "name": "Isverna",
                "countryCode": "RO"
            },
            {
                "name": "Izimșa",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Izvoru Bârzii",
                "countryCode": "RO"
            },
            {
                "name": "Jiana",
                "countryCode": "RO"
            },
            {
                "name": "Jiana Veche",
                "countryCode": "RO"
            },
            {
                "name": "Jidoștița",
                "countryCode": "RO"
            },
            {
                "name": "Jirov",
                "countryCode": "RO"
            },
            {
                "name": "Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Malovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Drobeta-Turnu Severin",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Orşova",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Obârşia-Cloşani",
                "countryCode": "RO"
            },
            {
                "name": "Obârșia de Câmp",
                "countryCode": "RO"
            },
            {
                "name": "Oprişor",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Baia De Aramã",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Strehaia",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Vânju Mare",
                "countryCode": "RO"
            },
            {
                "name": "Orevița Mare",
                "countryCode": "RO"
            },
            {
                "name": "Orşova",
                "countryCode": "RO"
            },
            {
                "name": "Ostrovu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Padina Mică",
                "countryCode": "RO"
            },
            {
                "name": "Podeni",
                "countryCode": "RO"
            },
            {
                "name": "Ponoarele",
                "countryCode": "RO"
            },
            {
                "name": "Poroina Mare",
                "countryCode": "RO"
            },
            {
                "name": "Pristol",
                "countryCode": "RO"
            },
            {
                "name": "Prunişor",
                "countryCode": "RO"
            },
            {
                "name": "Punghina",
                "countryCode": "RO"
            },
            {
                "name": "Pătulele",
                "countryCode": "RO"
            },
            {
                "name": "Recea",
                "countryCode": "RO"
            },
            {
                "name": "Rogova",
                "countryCode": "RO"
            },
            {
                "name": "Roșiori",
                "countryCode": "RO"
            },
            {
                "name": "Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Strehaia",
                "countryCode": "RO"
            },
            {
                "name": "Stângăceaua",
                "countryCode": "RO"
            },
            {
                "name": "Sviniţa",
                "countryCode": "RO"
            },
            {
                "name": "Tâmna",
                "countryCode": "RO"
            },
            {
                "name": "Vlădaia",
                "countryCode": "RO"
            },
            {
                "name": "Voloiac",
                "countryCode": "RO"
            },
            {
                "name": "Vrata",
                "countryCode": "RO"
            },
            {
                "name": "Vânju-Mare",
                "countryCode": "RO"
            },
            {
                "name": "Vânjuleţ",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Şimian",
                "countryCode": "RO"
            },
            {
                "name": "Şişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Şovarna",
                "countryCode": "RO"
            },
            {
                "name": "Ardusat",
                "countryCode": "RO"
            },
            {
                "name": "Arduzel",
                "countryCode": "RO"
            },
            {
                "name": "Arieșu de Câmp",
                "countryCode": "RO"
            },
            {
                "name": "Arieșu de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Ariniş",
                "countryCode": "RO"
            },
            {
                "name": "Aspra",
                "countryCode": "RO"
            },
            {
                "name": "Asuaju de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Asuaju de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Baba",
                "countryCode": "RO"
            },
            {
                "name": "Baia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Baia Sprie",
                "countryCode": "RO"
            },
            {
                "name": "Berbești",
                "countryCode": "RO"
            },
            {
                "name": "Berchez",
                "countryCode": "RO"
            },
            {
                "name": "Berința",
                "countryCode": "RO"
            },
            {
                "name": "Bicaz",
                "countryCode": "RO"
            },
            {
                "name": "Bistra",
                "countryCode": "RO"
            },
            {
                "name": "Blidari",
                "countryCode": "RO"
            },
            {
                "name": "Bocicoel",
                "countryCode": "RO"
            },
            {
                "name": "Bocicoiu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Bogdan Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Boiereni",
                "countryCode": "RO"
            },
            {
                "name": "Boiu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Bontăieni",
                "countryCode": "RO"
            },
            {
                "name": "Borcut",
                "countryCode": "RO"
            },
            {
                "name": "Borşa",
                "countryCode": "RO"
            },
            {
                "name": "Botiza",
                "countryCode": "RO"
            },
            {
                "name": "Bozânta Mare",
                "countryCode": "RO"
            },
            {
                "name": "Bozânta Mică",
                "countryCode": "RO"
            },
            {
                "name": "Breb",
                "countryCode": "RO"
            },
            {
                "name": "Brebeni",
                "countryCode": "RO"
            },
            {
                "name": "Buciumi",
                "countryCode": "RO"
            },
            {
                "name": "Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Buteasa",
                "countryCode": "RO"
            },
            {
                "name": "Buzești",
                "countryCode": "RO"
            },
            {
                "name": "Bușag",
                "countryCode": "RO"
            },
            {
                "name": "Bârgău",
                "countryCode": "RO"
            },
            {
                "name": "Bârsana",
                "countryCode": "RO"
            },
            {
                "name": "Băile Borșa",
                "countryCode": "RO"
            },
            {
                "name": "Băiuţ",
                "countryCode": "RO"
            },
            {
                "name": "Băiţa de sub Codru",
                "countryCode": "RO"
            },
            {
                "name": "Băița",
                "countryCode": "RO"
            },
            {
                "name": "Băseşti",
                "countryCode": "RO"
            },
            {
                "name": "Cavnic",
                "countryCode": "RO"
            },
            {
                "name": "Cerneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cetățele",
                "countryCode": "RO"
            },
            {
                "name": "Chechiș",
                "countryCode": "RO"
            },
            {
                "name": "Chelința",
                "countryCode": "RO"
            },
            {
                "name": "Chiuzbaia",
                "countryCode": "RO"
            },
            {
                "name": "Cicârlău",
                "countryCode": "RO"
            },
            {
                "name": "Ciocotiș",
                "countryCode": "RO"
            },
            {
                "name": "Ciolt",
                "countryCode": "RO"
            },
            {
                "name": "Ciuta",
                "countryCode": "RO"
            },
            {
                "name": "Coaș",
                "countryCode": "RO"
            },
            {
                "name": "Codru Butesii",
                "countryCode": "RO"
            },
            {
                "name": "Coltău",
                "countryCode": "RO"
            },
            {
                "name": "Colțirea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ardusat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ariniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Asuaju de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bicaz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bistra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bocicoiu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdan Vodă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boiu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Botiza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârsana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băiuţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băiţa de sub Codru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cicârlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coltãu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copalnic Mănăştur",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coroieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cupşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câmpulung la Tisa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fărcaşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Groşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Groşii Ţibleşului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârdani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ieud",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leordina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăpuş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mireşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moisei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oarţa De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ocna Şugatag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Onceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petrova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienile Izei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienile de sub Munte",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Recea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Remetea Chioarului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Remeţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Repedea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rona de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rona de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rozavlea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ruşcova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Satulung",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Strâmtura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suciu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sãpânţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcălăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălsig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sărăsău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Izei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Chioarului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vima Micã",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişeu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şieu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Copalnic",
                "countryCode": "RO"
            },
            {
                "name": "Copalnic Mănăştur",
                "countryCode": "RO"
            },
            {
                "name": "Cornești",
                "countryCode": "RO"
            },
            {
                "name": "Corni",
                "countryCode": "RO"
            },
            {
                "name": "Coroieni",
                "countryCode": "RO"
            },
            {
                "name": "Coruia",
                "countryCode": "RO"
            },
            {
                "name": "Costeni",
                "countryCode": "RO"
            },
            {
                "name": "Coștiui",
                "countryCode": "RO"
            },
            {
                "name": "Crasna Vișeului",
                "countryCode": "RO"
            },
            {
                "name": "Crăciunești",
                "countryCode": "RO"
            },
            {
                "name": "Cufoaia",
                "countryCode": "RO"
            },
            {
                "name": "Culcea",
                "countryCode": "RO"
            },
            {
                "name": "Cupşeni",
                "countryCode": "RO"
            },
            {
                "name": "Curtuiușu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Curtuiușu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Câmpulung la Tisa",
                "countryCode": "RO"
            },
            {
                "name": "Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Cărbunari",
                "countryCode": "RO"
            },
            {
                "name": "Cărpiniș",
                "countryCode": "RO"
            },
            {
                "name": "Cătălina",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Corbului",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Deseşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobricu Lăpușului",
                "countryCode": "RO"
            },
            {
                "name": "Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăghia",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Durușa",
                "countryCode": "RO"
            },
            {
                "name": "Dămăcușeni",
                "countryCode": "RO"
            },
            {
                "name": "Dănești",
                "countryCode": "RO"
            },
            {
                "name": "Dăneștii Chioarului",
                "countryCode": "RO"
            },
            {
                "name": "Ferești",
                "countryCode": "RO"
            },
            {
                "name": "Fericea",
                "countryCode": "RO"
            },
            {
                "name": "Fersig",
                "countryCode": "RO"
            },
            {
                "name": "Finteușu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Finteușu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Firiza",
                "countryCode": "RO"
            },
            {
                "name": "Frâncenii Boiului",
                "countryCode": "RO"
            },
            {
                "name": "Fânațe",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Fărcaşa",
                "countryCode": "RO"
            },
            {
                "name": "Făurești",
                "countryCode": "RO"
            },
            {
                "name": "Giuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Glod",
                "countryCode": "RO"
            },
            {
                "name": "Groape",
                "countryCode": "RO"
            },
            {
                "name": "Groşi",
                "countryCode": "RO"
            },
            {
                "name": "Groșii Țibleșului",
                "countryCode": "RO"
            },
            {
                "name": "Gârdani",
                "countryCode": "RO"
            },
            {
                "name": "Handalu Ilbei",
                "countryCode": "RO"
            },
            {
                "name": "Hideaga",
                "countryCode": "RO"
            },
            {
                "name": "Hoteni",
                "countryCode": "RO"
            },
            {
                "name": "Hovrila",
                "countryCode": "RO"
            },
            {
                "name": "Hărnicești",
                "countryCode": "RO"
            },
            {
                "name": "Iadăra",
                "countryCode": "RO"
            },
            {
                "name": "Iapa",
                "countryCode": "RO"
            },
            {
                "name": "Ieud",
                "countryCode": "RO"
            },
            {
                "name": "Ilba",
                "countryCode": "RO"
            },
            {
                "name": "Inău",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Jugăstreni",
                "countryCode": "RO"
            },
            {
                "name": "Larga",
                "countryCode": "RO"
            },
            {
                "name": "Lazu Baciului",
                "countryCode": "RO"
            },
            {
                "name": "Leordina",
                "countryCode": "RO"
            },
            {
                "name": "Libotin",
                "countryCode": "RO"
            },
            {
                "name": "Lucăcești",
                "countryCode": "RO"
            },
            {
                "name": "Lunca la Tisa",
                "countryCode": "RO"
            },
            {
                "name": "Lăpuş",
                "countryCode": "RO"
            },
            {
                "name": "Lăpușel",
                "countryCode": "RO"
            },
            {
                "name": "Lăschia",
                "countryCode": "RO"
            },
            {
                "name": "Mara",
                "countryCode": "RO"
            },
            {
                "name": "Merișor",
                "countryCode": "RO"
            },
            {
                "name": "Mesteacăn",
                "countryCode": "RO"
            },
            {
                "name": "Mireşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Mocira",
                "countryCode": "RO"
            },
            {
                "name": "Mogoșești",
                "countryCode": "RO"
            },
            {
                "name": "Moisei",
                "countryCode": "RO"
            },
            {
                "name": "Mânău",
                "countryCode": "RO"
            },
            {
                "name": "Măgureni",
                "countryCode": "RO"
            },
            {
                "name": "Mănăstirea",
                "countryCode": "RO"
            },
            {
                "name": "Negreia",
                "countryCode": "RO"
            },
            {
                "name": "Nistru",
                "countryCode": "RO"
            },
            {
                "name": "Nănești",
                "countryCode": "RO"
            },
            {
                "name": "Oarţa de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Oarța de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ocna Şugatag",
                "countryCode": "RO"
            },
            {
                "name": "Ocoliș",
                "countryCode": "RO"
            },
            {
                "name": "Odești",
                "countryCode": "RO"
            },
            {
                "name": "Oncești",
                "countryCode": "RO"
            },
            {
                "name": "Orțița",
                "countryCode": "RO"
            },
            {
                "name": "Peteritea",
                "countryCode": "RO"
            },
            {
                "name": "Petrova",
                "countryCode": "RO"
            },
            {
                "name": "Piatra",
                "countryCode": "RO"
            },
            {
                "name": "Plopiș",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Botizii",
                "countryCode": "RO"
            },
            {
                "name": "Poienile Izei",
                "countryCode": "RO"
            },
            {
                "name": "Poienile de sub Munte",
                "countryCode": "RO"
            },
            {
                "name": "Posta",
                "countryCode": "RO"
            },
            {
                "name": "Preluca Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Preluca Veche",
                "countryCode": "RO"
            },
            {
                "name": "Pribilești",
                "countryCode": "RO"
            },
            {
                "name": "Prislop",
                "countryCode": "RO"
            },
            {
                "name": "Recea",
                "countryCode": "RO"
            },
            {
                "name": "Remecioara",
                "countryCode": "RO"
            },
            {
                "name": "Remetea Chioarului",
                "countryCode": "RO"
            },
            {
                "name": "Remeţi",
                "countryCode": "RO"
            },
            {
                "name": "Remeți pe Someș",
                "countryCode": "RO"
            },
            {
                "name": "Repedea",
                "countryCode": "RO"
            },
            {
                "name": "Rodina",
                "countryCode": "RO"
            },
            {
                "name": "Rogoz",
                "countryCode": "RO"
            },
            {
                "name": "Rohia",
                "countryCode": "RO"
            },
            {
                "name": "Românești",
                "countryCode": "RO"
            },
            {
                "name": "Rona de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Rona de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Rozavlea",
                "countryCode": "RO"
            },
            {
                "name": "Rus",
                "countryCode": "RO"
            },
            {
                "name": "Ruscova",
                "countryCode": "RO"
            },
            {
                "name": "Rușor",
                "countryCode": "RO"
            },
            {
                "name": "Răzoare",
                "countryCode": "RO"
            },
            {
                "name": "Sarasău",
                "countryCode": "RO"
            },
            {
                "name": "Sat-Șugatag",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Satulung",
                "countryCode": "RO"
            },
            {
                "name": "Seini",
                "countryCode": "RO"
            },
            {
                "name": "Sighetu Marmaţiei",
                "countryCode": "RO"
            },
            {
                "name": "Slătioara",
                "countryCode": "RO"
            },
            {
                "name": "Someș-Uileac",
                "countryCode": "RO"
            },
            {
                "name": "Stejera",
                "countryCode": "RO"
            },
            {
                "name": "Stoiceni",
                "countryCode": "RO"
            },
            {
                "name": "Stremț",
                "countryCode": "RO"
            },
            {
                "name": "Strâmbu-Băiuț",
                "countryCode": "RO"
            },
            {
                "name": "Strâmtura",
                "countryCode": "RO"
            },
            {
                "name": "Suciu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Suciu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Sâlța",
                "countryCode": "RO"
            },
            {
                "name": "Sârbi",
                "countryCode": "RO"
            },
            {
                "name": "Săbișa",
                "countryCode": "RO"
            },
            {
                "name": "Săcel",
                "countryCode": "RO"
            },
            {
                "name": "Săcălășeni",
                "countryCode": "RO"
            },
            {
                "name": "Săliştea de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Săliște",
                "countryCode": "RO"
            },
            {
                "name": "Sălnița",
                "countryCode": "RO"
            },
            {
                "name": "Sălsig",
                "countryCode": "RO"
            },
            {
                "name": "Săpânţa",
                "countryCode": "RO"
            },
            {
                "name": "Săsar",
                "countryCode": "RO"
            },
            {
                "name": "Teceu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Tisa",
                "countryCode": "RO"
            },
            {
                "name": "Trestia",
                "countryCode": "RO"
            },
            {
                "name": "Tulghieș",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Lăpuş",
                "countryCode": "RO"
            },
            {
                "name": "Tămaia",
                "countryCode": "RO"
            },
            {
                "name": "Tămășești",
                "countryCode": "RO"
            },
            {
                "name": "Tăuţii-Măgherăuş",
                "countryCode": "RO"
            },
            {
                "name": "Tăuții de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ulmeni",
                "countryCode": "RO"
            },
            {
                "name": "Ulmoasa",
                "countryCode": "RO"
            },
            {
                "name": "Unguraș",
                "countryCode": "RO"
            },
            {
                "name": "Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Urmeniș",
                "countryCode": "RO"
            },
            {
                "name": "Vad",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Izei",
                "countryCode": "RO"
            },
            {
                "name": "Valea Chioarului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Cufundoasă",
                "countryCode": "RO"
            },
            {
                "name": "Valea Hotarului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Neagră",
                "countryCode": "RO"
            },
            {
                "name": "Valea Stejarului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Vișeului",
                "countryCode": "RO"
            },
            {
                "name": "Vicea",
                "countryCode": "RO"
            },
            {
                "name": "Viile Apei",
                "countryCode": "RO"
            },
            {
                "name": "Vima Mare",
                "countryCode": "RO"
            },
            {
                "name": "Vima Mică",
                "countryCode": "RO"
            },
            {
                "name": "Vişeu De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Vişeu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Vișeu de Mijloc",
                "countryCode": "RO"
            },
            {
                "name": "Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Vălenii Lăpușului",
                "countryCode": "RO"
            },
            {
                "name": "Vălenii Șomcutei",
                "countryCode": "RO"
            },
            {
                "name": "Vărai",
                "countryCode": "RO"
            },
            {
                "name": "tohat",
                "countryCode": "RO"
            },
            {
                "name": "Întrerâuri",
                "countryCode": "RO"
            },
            {
                "name": "Şomcuta Mare",
                "countryCode": "RO"
            },
            {
                "name": "Șieu",
                "countryCode": "RO"
            },
            {
                "name": "Șindrești",
                "countryCode": "RO"
            },
            {
                "name": "Șisești",
                "countryCode": "RO"
            },
            {
                "name": "Șugău",
                "countryCode": "RO"
            },
            {
                "name": "Șurdești",
                "countryCode": "RO"
            },
            {
                "name": "Țicău",
                "countryCode": "RO"
            },
            {
                "name": "Abuș",
                "countryCode": "RO"
            },
            {
                "name": "Acățari",
                "countryCode": "RO"
            },
            {
                "name": "Adrian",
                "countryCode": "RO"
            },
            {
                "name": "Adrianu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Adrianu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Adămuș",
                "countryCode": "RO"
            },
            {
                "name": "Agrișteu",
                "countryCode": "RO"
            },
            {
                "name": "Albești",
                "countryCode": "RO"
            },
            {
                "name": "Aluniș",
                "countryCode": "RO"
            },
            {
                "name": "Andreneasa",
                "countryCode": "RO"
            },
            {
                "name": "Angofa",
                "countryCode": "RO"
            },
            {
                "name": "Apalina",
                "countryCode": "RO"
            },
            {
                "name": "Apold",
                "countryCode": "RO"
            },
            {
                "name": "Archita",
                "countryCode": "RO"
            },
            {
                "name": "Arșița",
                "countryCode": "RO"
            },
            {
                "name": "Aurel Vlaicu",
                "countryCode": "RO"
            },
            {
                "name": "Avrămești",
                "countryCode": "RO"
            },
            {
                "name": "Ațintiș",
                "countryCode": "RO"
            },
            {
                "name": "Bahnea",
                "countryCode": "RO"
            },
            {
                "name": "Balda",
                "countryCode": "RO"
            },
            {
                "name": "Band",
                "countryCode": "RO"
            },
            {
                "name": "Batoș",
                "countryCode": "RO"
            },
            {
                "name": "Bedeni",
                "countryCode": "RO"
            },
            {
                "name": "Beica de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Beica de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Bereni",
                "countryCode": "RO"
            },
            {
                "name": "Berghia",
                "countryCode": "RO"
            },
            {
                "name": "Bernadea",
                "countryCode": "RO"
            },
            {
                "name": "Beu",
                "countryCode": "RO"
            },
            {
                "name": "Bezid",
                "countryCode": "RO"
            },
            {
                "name": "Bicașu",
                "countryCode": "RO"
            },
            {
                "name": "Bichiș",
                "countryCode": "RO"
            },
            {
                "name": "Bistra Mureșului",
                "countryCode": "RO"
            },
            {
                "name": "Blidireasa",
                "countryCode": "RO"
            },
            {
                "name": "Bobohalma",
                "countryCode": "RO"
            },
            {
                "name": "Bogata",
                "countryCode": "RO"
            },
            {
                "name": "Boiu",
                "countryCode": "RO"
            },
            {
                "name": "Bolintineni",
                "countryCode": "RO"
            },
            {
                "name": "Bologaia",
                "countryCode": "RO"
            },
            {
                "name": "Bord",
                "countryCode": "RO"
            },
            {
                "name": "Bordoșiu",
                "countryCode": "RO"
            },
            {
                "name": "Borzia",
                "countryCode": "RO"
            },
            {
                "name": "Botei",
                "countryCode": "RO"
            },
            {
                "name": "Botez",
                "countryCode": "RO"
            },
            {
                "name": "Botorca",
                "countryCode": "RO"
            },
            {
                "name": "Bozed",
                "countryCode": "RO"
            },
            {
                "name": "Bozeni",
                "countryCode": "RO"
            },
            {
                "name": "Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Brâncovenești",
                "countryCode": "RO"
            },
            {
                "name": "Brădețelu",
                "countryCode": "RO"
            },
            {
                "name": "Budiu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Bujor",
                "countryCode": "RO"
            },
            {
                "name": "Bujor-Hodaie",
                "countryCode": "RO"
            },
            {
                "name": "Bâra",
                "countryCode": "RO"
            },
            {
                "name": "Bârlibaș",
                "countryCode": "RO"
            },
            {
                "name": "Bârlibășoaia",
                "countryCode": "RO"
            },
            {
                "name": "Bârza",
                "countryCode": "RO"
            },
            {
                "name": "Băgaciu",
                "countryCode": "RO"
            },
            {
                "name": "Băița",
                "countryCode": "RO"
            },
            {
                "name": "Băla",
                "countryCode": "RO"
            },
            {
                "name": "Bălăușeri",
                "countryCode": "RO"
            },
            {
                "name": "Bărboși",
                "countryCode": "RO"
            },
            {
                "name": "Bărdești",
                "countryCode": "RO"
            },
            {
                "name": "Cașva",
                "countryCode": "RO"
            },
            {
                "name": "Cecălaca",
                "countryCode": "RO"
            },
            {
                "name": "Ceie",
                "countryCode": "RO"
            },
            {
                "name": "Cerghid",
                "countryCode": "RO"
            },
            {
                "name": "Cerghizel",
                "countryCode": "RO"
            },
            {
                "name": "Ceuaș",
                "countryCode": "RO"
            },
            {
                "name": "Ceuașu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Chendu",
                "countryCode": "RO"
            },
            {
                "name": "Chețani",
                "countryCode": "RO"
            },
            {
                "name": "Chibed",
                "countryCode": "RO"
            },
            {
                "name": "Chiheru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Chiheru de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Chinari",
                "countryCode": "RO"
            },
            {
                "name": "Chinciuș",
                "countryCode": "RO"
            },
            {
                "name": "Chirileu",
                "countryCode": "RO"
            },
            {
                "name": "Chisălița",
                "countryCode": "RO"
            },
            {
                "name": "Ciba",
                "countryCode": "RO"
            },
            {
                "name": "Cibu",
                "countryCode": "RO"
            },
            {
                "name": "Cinta",
                "countryCode": "RO"
            },
            {
                "name": "Cioarga",
                "countryCode": "RO"
            },
            {
                "name": "Ciobotani",
                "countryCode": "RO"
            },
            {
                "name": "Cipaieni",
                "countryCode": "RO"
            },
            {
                "name": "Cipău",
                "countryCode": "RO"
            },
            {
                "name": "Ciretea",
                "countryCode": "RO"
            },
            {
                "name": "Cirhagău",
                "countryCode": "RO"
            },
            {
                "name": "Ciulea",
                "countryCode": "RO"
            },
            {
                "name": "Ciurgău",
                "countryCode": "RO"
            },
            {
                "name": "Cloașterf",
                "countryCode": "RO"
            },
            {
                "name": "Coasta Grindului",
                "countryCode": "RO"
            },
            {
                "name": "Coasta Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comori",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coroisânmartin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ibănești Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rușii Munți",
                "countryCode": "RO"
            },
            {
                "name": "Corbești",
                "countryCode": "RO"
            },
            {
                "name": "Cordoș",
                "countryCode": "RO"
            },
            {
                "name": "Cornești",
                "countryCode": "RO"
            },
            {
                "name": "Coroi",
                "countryCode": "RO"
            },
            {
                "name": "Corunca",
                "countryCode": "RO"
            },
            {
                "name": "Cotorinau",
                "countryCode": "RO"
            },
            {
                "name": "Cotuș",
                "countryCode": "RO"
            },
            {
                "name": "Cozma",
                "countryCode": "RO"
            },
            {
                "name": "Cristești",
                "countryCode": "RO"
            },
            {
                "name": "Criș",
                "countryCode": "RO"
            },
            {
                "name": "Crăciunești",
                "countryCode": "RO"
            },
            {
                "name": "Crăiești",
                "countryCode": "RO"
            },
            {
                "name": "Cucerdea",
                "countryCode": "RO"
            },
            {
                "name": "Cuci",
                "countryCode": "RO"
            },
            {
                "name": "Cuieșd",
                "countryCode": "RO"
            },
            {
                "name": "Culpiu",
                "countryCode": "RO"
            },
            {
                "name": "Cund",
                "countryCode": "RO"
            },
            {
                "name": "Curețe",
                "countryCode": "RO"
            },
            {
                "name": "Curteni",
                "countryCode": "RO"
            },
            {
                "name": "Cuștelnic",
                "countryCode": "RO"
            },
            {
                "name": "Câmpenița",
                "countryCode": "RO"
            },
            {
                "name": "Câmpu Cetății",
                "countryCode": "RO"
            },
            {
                "name": "Cându",
                "countryCode": "RO"
            },
            {
                "name": "Căciulata",
                "countryCode": "RO"
            },
            {
                "name": "Căcuciu",
                "countryCode": "RO"
            },
            {
                "name": "Călimănești",
                "countryCode": "RO"
            },
            {
                "name": "Călugăreni",
                "countryCode": "RO"
            },
            {
                "name": "Călușeri",
                "countryCode": "RO"
            },
            {
                "name": "Căpeți",
                "countryCode": "RO"
            },
            {
                "name": "Căpușu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Căpâlna de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Daia",
                "countryCode": "RO"
            },
            {
                "name": "Dalu",
                "countryCode": "RO"
            },
            {
                "name": "Daneș",
                "countryCode": "RO"
            },
            {
                "name": "Deag",
                "countryCode": "RO"
            },
            {
                "name": "Deaj",
                "countryCode": "RO"
            },
            {
                "name": "Deda",
                "countryCode": "RO"
            },
            {
                "name": "Dedrad",
                "countryCode": "RO"
            },
            {
                "name": "Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Dileu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Dileu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Drojdii",
                "countryCode": "RO"
            },
            {
                "name": "Drăculea Bandului",
                "countryCode": "RO"
            },
            {
                "name": "Dubiștea de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Dulcea",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăvioara",
                "countryCode": "RO"
            },
            {
                "name": "Dumitreni",
                "countryCode": "RO"
            },
            {
                "name": "Dumitreștii",
                "countryCode": "RO"
            },
            {
                "name": "După Deal",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbu",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbău",
                "countryCode": "RO"
            },
            {
                "name": "Dămieni",
                "countryCode": "RO"
            },
            {
                "name": "Dătășeni",
                "countryCode": "RO"
            },
            {
                "name": "Ercea",
                "countryCode": "RO"
            },
            {
                "name": "Eremieni",
                "countryCode": "RO"
            },
            {
                "name": "Eremitu",
                "countryCode": "RO"
            },
            {
                "name": "Ernei",
                "countryCode": "RO"
            },
            {
                "name": "Feleag",
                "countryCode": "RO"
            },
            {
                "name": "Filea",
                "countryCode": "RO"
            },
            {
                "name": "Filitelnic",
                "countryCode": "RO"
            },
            {
                "name": "Filpișu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Filpișu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Fițcău",
                "countryCode": "RO"
            },
            {
                "name": "Foi",
                "countryCode": "RO"
            },
            {
                "name": "Frunzeni",
                "countryCode": "RO"
            },
            {
                "name": "Fundoaia",
                "countryCode": "RO"
            },
            {
                "name": "Fundătura",
                "countryCode": "RO"
            },
            {
                "name": "Fânațe",
                "countryCode": "RO"
            },
            {
                "name": "Fânațele Mădărașului",
                "countryCode": "RO"
            },
            {
                "name": "Fânațele Socolului",
                "countryCode": "RO"
            },
            {
                "name": "Fântâna Babii",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Fărăgău",
                "countryCode": "RO"
            },
            {
                "name": "Gaura Sângerului",
                "countryCode": "RO"
            },
            {
                "name": "Gheja",
                "countryCode": "RO"
            },
            {
                "name": "Gheorghe Doja",
                "countryCode": "RO"
            },
            {
                "name": "Ghidașteu",
                "countryCode": "RO"
            },
            {
                "name": "Ghindari",
                "countryCode": "RO"
            },
            {
                "name": "Ghinești",
                "countryCode": "RO"
            },
            {
                "name": "Giuluș",
                "countryCode": "RO"
            },
            {
                "name": "Giurgiș",
                "countryCode": "RO"
            },
            {
                "name": "Glodeni",
                "countryCode": "RO"
            },
            {
                "name": "Glăjărie",
                "countryCode": "RO"
            },
            {
                "name": "Gogan",
                "countryCode": "RO"
            },
            {
                "name": "Goreni",
                "countryCode": "RO"
            },
            {
                "name": "Gornești",
                "countryCode": "RO"
            },
            {
                "name": "Grebenișu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Grindeni",
                "countryCode": "RO"
            },
            {
                "name": "Groapa Rădăii",
                "countryCode": "RO"
            },
            {
                "name": "Gruișor",
                "countryCode": "RO"
            },
            {
                "name": "Grâușorul",
                "countryCode": "RO"
            },
            {
                "name": "Grădini",
                "countryCode": "RO"
            },
            {
                "name": "Gurghiu",
                "countryCode": "RO"
            },
            {
                "name": "Gâmbuț",
                "countryCode": "RO"
            },
            {
                "name": "Găiești",
                "countryCode": "RO"
            },
            {
                "name": "Gălești",
                "countryCode": "RO"
            },
            {
                "name": "Gălăoaia",
                "countryCode": "RO"
            },
            {
                "name": "Gălățeni",
                "countryCode": "RO"
            },
            {
                "name": "Gănești",
                "countryCode": "RO"
            },
            {
                "name": "Habic",
                "countryCode": "RO"
            },
            {
                "name": "Hagău",
                "countryCode": "RO"
            },
            {
                "name": "Herepea",
                "countryCode": "RO"
            },
            {
                "name": "Herghelia",
                "countryCode": "RO"
            },
            {
                "name": "Hetiur",
                "countryCode": "RO"
            },
            {
                "name": "Hodac",
                "countryCode": "RO"
            },
            {
                "name": "Hodaia",
                "countryCode": "RO"
            },
            {
                "name": "Hodoșa",
                "countryCode": "RO"
            },
            {
                "name": "Hădăreni",
                "countryCode": "RO"
            },
            {
                "name": "Hărănglab",
                "countryCode": "RO"
            },
            {
                "name": "Hărțău",
                "countryCode": "RO"
            },
            {
                "name": "Iara de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Ibănești-Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Icland",
                "countryCode": "RO"
            },
            {
                "name": "Iclandu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Iclănzel",
                "countryCode": "RO"
            },
            {
                "name": "Ideciu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Idicel-Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Idiciu",
                "countryCode": "RO"
            },
            {
                "name": "Idrifaia",
                "countryCode": "RO"
            },
            {
                "name": "Iernut",
                "countryCode": "RO"
            },
            {
                "name": "Iernuțeni",
                "countryCode": "RO"
            },
            {
                "name": "Ihod",
                "countryCode": "RO"
            },
            {
                "name": "Ilieni",
                "countryCode": "RO"
            },
            {
                "name": "Ilieși",
                "countryCode": "RO"
            },
            {
                "name": "Ilioara",
                "countryCode": "RO"
            },
            {
                "name": "Iod",
                "countryCode": "RO"
            },
            {
                "name": "Isla",
                "countryCode": "RO"
            },
            {
                "name": "Ivănești",
                "countryCode": "RO"
            },
            {
                "name": "Iștan-Tău",
                "countryCode": "RO"
            },
            {
                "name": "Iștihaza",
                "countryCode": "RO"
            },
            {
                "name": "Jabenița",
                "countryCode": "RO"
            },
            {
                "name": "Jacodu",
                "countryCode": "RO"
            },
            {
                "name": "Jacu",
                "countryCode": "RO"
            },
            {
                "name": "Larga",
                "countryCode": "RO"
            },
            {
                "name": "Laslău Mare",
                "countryCode": "RO"
            },
            {
                "name": "Laslău Mic",
                "countryCode": "RO"
            },
            {
                "name": "Lechincioara",
                "countryCode": "RO"
            },
            {
                "name": "Lechința",
                "countryCode": "RO"
            },
            {
                "name": "Lefaia",
                "countryCode": "RO"
            },
            {
                "name": "Leniș",
                "countryCode": "RO"
            },
            {
                "name": "Leordeni",
                "countryCode": "RO"
            },
            {
                "name": "Leorința",
                "countryCode": "RO"
            },
            {
                "name": "Leorința-Șăulia",
                "countryCode": "RO"
            },
            {
                "name": "Lepindea",
                "countryCode": "RO"
            },
            {
                "name": "Linț",
                "countryCode": "RO"
            },
            {
                "name": "Livezeni",
                "countryCode": "RO"
            },
            {
                "name": "Logig",
                "countryCode": "RO"
            },
            {
                "name": "Loțu",
                "countryCode": "RO"
            },
            {
                "name": "Luduș",
                "countryCode": "RO"
            },
            {
                "name": "Luieriu",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Bradului",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Mureșului",
                "countryCode": "RO"
            },
            {
                "name": "Lăpușna",
                "countryCode": "RO"
            },
            {
                "name": "Lăscud",
                "countryCode": "RO"
            },
            {
                "name": "Lăureni",
                "countryCode": "RO"
            },
            {
                "name": "Maia",
                "countryCode": "RO"
            },
            {
                "name": "Maiad",
                "countryCode": "RO"
            },
            {
                "name": "Maiorești",
                "countryCode": "RO"
            },
            {
                "name": "Maldaoci",
                "countryCode": "RO"
            },
            {
                "name": "Malea",
                "countryCode": "RO"
            },
            {
                "name": "Merișor",
                "countryCode": "RO"
            },
            {
                "name": "Merișoru",
                "countryCode": "RO"
            },
            {
                "name": "Meștera",
                "countryCode": "RO"
            },
            {
                "name": "Mica",
                "countryCode": "RO"
            },
            {
                "name": "Miercurea Nirajului",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Viteazu",
                "countryCode": "RO"
            },
            {
                "name": "Miheșu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Milășel",
                "countryCode": "RO"
            },
            {
                "name": "Mirigioaia",
                "countryCode": "RO"
            },
            {
                "name": "Mitrești",
                "countryCode": "RO"
            },
            {
                "name": "Moara de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Mogoaia",
                "countryCode": "RO"
            },
            {
                "name": "Moișa",
                "countryCode": "RO"
            },
            {
                "name": "Morești",
                "countryCode": "RO"
            },
            {
                "name": "Moruț",
                "countryCode": "RO"
            },
            {
                "name": "Morăreni",
                "countryCode": "RO"
            },
            {
                "name": "Moșuni",
                "countryCode": "RO"
            },
            {
                "name": "Mura Mare",
                "countryCode": "RO"
            },
            {
                "name": "Mura Mică",
                "countryCode": "RO"
            },
            {
                "name": "Mureni",
                "countryCode": "RO"
            },
            {
                "name": "Mureșeni",
                "countryCode": "RO"
            },
            {
                "name": "Murgești",
                "countryCode": "RO"
            },
            {
                "name": "Măcicășești",
                "countryCode": "RO"
            },
            {
                "name": "Mădăraș",
                "countryCode": "RO"
            },
            {
                "name": "Mădărășeni",
                "countryCode": "RO"
            },
            {
                "name": "Măgherani",
                "countryCode": "RO"
            },
            {
                "name": "Măgheruș",
                "countryCode": "RO"
            },
            {
                "name": "Mălăești",
                "countryCode": "RO"
            },
            {
                "name": "Mărculeni",
                "countryCode": "RO"
            },
            {
                "name": "Mărășești",
                "countryCode": "RO"
            },
            {
                "name": "Mătrici",
                "countryCode": "RO"
            },
            {
                "name": "Nadeș",
                "countryCode": "RO"
            },
            {
                "name": "Nadășa",
                "countryCode": "RO"
            },
            {
                "name": "Nandra",
                "countryCode": "RO"
            },
            {
                "name": "Nazna",
                "countryCode": "RO"
            },
            {
                "name": "Neagra",
                "countryCode": "RO"
            },
            {
                "name": "Neaua",
                "countryCode": "RO"
            },
            {
                "name": "Negrenii de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Nicolești",
                "countryCode": "RO"
            },
            {
                "name": "Nima Milășelului",
                "countryCode": "RO"
            },
            {
                "name": "Nima Râciului",
                "countryCode": "RO"
            },
            {
                "name": "Oarba de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Obârșie",
                "countryCode": "RO"
            },
            {
                "name": "Odrihei",
                "countryCode": "RO"
            },
            {
                "name": "Ogra",
                "countryCode": "RO"
            },
            {
                "name": "Onuca",
                "countryCode": "RO"
            },
            {
                "name": "Ormeniș",
                "countryCode": "RO"
            },
            {
                "name": "Oroiu",
                "countryCode": "RO"
            },
            {
                "name": "Orosia",
                "countryCode": "RO"
            },
            {
                "name": "Orșova",
                "countryCode": "RO"
            },
            {
                "name": "Ozd",
                "countryCode": "RO"
            },
            {
                "name": "Papiu Ilarian",
                "countryCode": "RO"
            },
            {
                "name": "Periș",
                "countryCode": "RO"
            },
            {
                "name": "Petea",
                "countryCode": "RO"
            },
            {
                "name": "Petelea",
                "countryCode": "RO"
            },
            {
                "name": "Petrilaca",
                "countryCode": "RO"
            },
            {
                "name": "Petrilaca de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Pietriș",
                "countryCode": "RO"
            },
            {
                "name": "Pipea",
                "countryCode": "RO"
            },
            {
                "name": "Poarta",
                "countryCode": "RO"
            },
            {
                "name": "Poduri",
                "countryCode": "RO"
            },
            {
                "name": "Pogăceaua",
                "countryCode": "RO"
            },
            {
                "name": "Poienița",
                "countryCode": "RO"
            },
            {
                "name": "Porumbac",
                "countryCode": "RO"
            },
            {
                "name": "Porumbeni",
                "countryCode": "RO"
            },
            {
                "name": "Pripoare",
                "countryCode": "RO"
            },
            {
                "name": "Pusta",
                "countryCode": "RO"
            },
            {
                "name": "Pârâu Crucii",
                "countryCode": "RO"
            },
            {
                "name": "Pârâu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Păcureni",
                "countryCode": "RO"
            },
            {
                "name": "Pădurea",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Păingeni",
                "countryCode": "RO"
            },
            {
                "name": "Pănet",
                "countryCode": "RO"
            },
            {
                "name": "Păsăreni",
                "countryCode": "RO"
            },
            {
                "name": "Păucișoara",
                "countryCode": "RO"
            },
            {
                "name": "Păuloaia",
                "countryCode": "RO"
            },
            {
                "name": "Racameț",
                "countryCode": "RO"
            },
            {
                "name": "Ranta",
                "countryCode": "RO"
            },
            {
                "name": "Recea",
                "countryCode": "RO"
            },
            {
                "name": "Reghin",
                "countryCode": "RO"
            },
            {
                "name": "Remetea",
                "countryCode": "RO"
            },
            {
                "name": "Rigmani",
                "countryCode": "RO"
            },
            {
                "name": "Rora",
                "countryCode": "RO"
            },
            {
                "name": "Roteni",
                "countryCode": "RO"
            },
            {
                "name": "Roua",
                "countryCode": "RO"
            },
            {
                "name": "Roșiori",
                "countryCode": "RO"
            },
            {
                "name": "Rușii-Munți",
                "countryCode": "RO"
            },
            {
                "name": "Râciu",
                "countryCode": "RO"
            },
            {
                "name": "Râpa de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Răstolița",
                "countryCode": "RO"
            },
            {
                "name": "Răzoare",
                "countryCode": "RO"
            },
            {
                "name": "Saschiz",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Scurta",
                "countryCode": "RO"
            },
            {
                "name": "Sebeș",
                "countryCode": "RO"
            },
            {
                "name": "Seleuș",
                "countryCode": "RO"
            },
            {
                "name": "Senereuș",
                "countryCode": "RO"
            },
            {
                "name": "Seuca",
                "countryCode": "RO"
            },
            {
                "name": "Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Sicele",
                "countryCode": "RO"
            },
            {
                "name": "Sighișoara",
                "countryCode": "RO"
            },
            {
                "name": "Socolu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Solocma",
                "countryCode": "RO"
            },
            {
                "name": "Solovăstru",
                "countryCode": "RO"
            },
            {
                "name": "Sovata",
                "countryCode": "RO"
            },
            {
                "name": "Stejeriș",
                "countryCode": "RO"
            },
            {
                "name": "Stejărenii",
                "countryCode": "RO"
            },
            {
                "name": "Stânceni",
                "countryCode": "RO"
            },
            {
                "name": "Sub Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Suplac",
                "countryCode": "RO"
            },
            {
                "name": "Suseni",
                "countryCode": "RO"
            },
            {
                "name": "Suveica",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbriaș",
                "countryCode": "RO"
            },
            {
                "name": "Sâncraiu de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Sângeorgiu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Sângeorgiu de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Sângeorgiu de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Sânger",
                "countryCode": "RO"
            },
            {
                "name": "Sângeru de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Sâniacob",
                "countryCode": "RO"
            },
            {
                "name": "Sânișor",
                "countryCode": "RO"
            },
            {
                "name": "Sânmihai de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Sânmărghita",
                "countryCode": "RO"
            },
            {
                "name": "Sânmărtinu de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Sânpaul",
                "countryCode": "RO"
            },
            {
                "name": "Sânpetru de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Sânsimion",
                "countryCode": "RO"
            },
            {
                "name": "Sântana de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Sântioana",
                "countryCode": "RO"
            },
            {
                "name": "Sântioana de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Sântu",
                "countryCode": "RO"
            },
            {
                "name": "Sânvăsii",
                "countryCode": "RO"
            },
            {
                "name": "Săbed",
                "countryCode": "RO"
            },
            {
                "name": "Săcalu de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Săcădat",
                "countryCode": "RO"
            },
            {
                "name": "Săcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Sălard",
                "countryCode": "RO"
            },
            {
                "name": "Sălașuri",
                "countryCode": "RO"
            },
            {
                "name": "Sălcud",
                "countryCode": "RO"
            },
            {
                "name": "Sărmașu",
                "countryCode": "RO"
            },
            {
                "name": "Sărmășel",
                "countryCode": "RO"
            },
            {
                "name": "Sărățeni",
                "countryCode": "RO"
            },
            {
                "name": "Teleac",
                "countryCode": "RO"
            },
            {
                "name": "Tireu",
                "countryCode": "RO"
            },
            {
                "name": "Tirimia",
                "countryCode": "RO"
            },
            {
                "name": "Tirimioara",
                "countryCode": "RO"
            },
            {
                "name": "Tisieu",
                "countryCode": "RO"
            },
            {
                "name": "Titiana",
                "countryCode": "RO"
            },
            {
                "name": "Toaca",
                "countryCode": "RO"
            },
            {
                "name": "Tofalău",
                "countryCode": "RO"
            },
            {
                "name": "Toldal",
                "countryCode": "RO"
            },
            {
                "name": "Tonciu",
                "countryCode": "RO"
            },
            {
                "name": "Torba",
                "countryCode": "RO"
            },
            {
                "name": "Trei Sate",
                "countryCode": "RO"
            },
            {
                "name": "Troița",
                "countryCode": "RO"
            },
            {
                "name": "Tușinu",
                "countryCode": "RO"
            },
            {
                "name": "Tâmpa",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Târnăveni",
                "countryCode": "RO"
            },
            {
                "name": "Tăblășeni",
                "countryCode": "RO"
            },
            {
                "name": "Tău",
                "countryCode": "RO"
            },
            {
                "name": "Tăureni",
                "countryCode": "RO"
            },
            {
                "name": "Uila",
                "countryCode": "RO"
            },
            {
                "name": "Ulieș",
                "countryCode": "RO"
            },
            {
                "name": "Ungheni",
                "countryCode": "RO"
            },
            {
                "name": "Uricea",
                "countryCode": "RO"
            },
            {
                "name": "Urisiu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Urisiu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ursoaia",
                "countryCode": "RO"
            },
            {
                "name": "Vadu",
                "countryCode": "RO"
            },
            {
                "name": "Vaidacuta",
                "countryCode": "RO"
            },
            {
                "name": "Vaideiu",
                "countryCode": "RO"
            },
            {
                "name": "Valea",
                "countryCode": "RO"
            },
            {
                "name": "Valea Albeștiului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Dăii",
                "countryCode": "RO"
            },
            {
                "name": "Valea Frăției",
                "countryCode": "RO"
            },
            {
                "name": "Valea Glodului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Iclandului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Izvoarelor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Largă",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Valea Pădurii",
                "countryCode": "RO"
            },
            {
                "name": "Valea Rece",
                "countryCode": "RO"
            },
            {
                "name": "Valea Sasului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Valea Sânmărtinului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Sânpetrului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Ulieșului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Ungurului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Urieșului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Șapartocului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Șurii",
                "countryCode": "RO"
            },
            {
                "name": "Venchi",
                "countryCode": "RO"
            },
            {
                "name": "Veța",
                "countryCode": "RO"
            },
            {
                "name": "Vețca",
                "countryCode": "RO"
            },
            {
                "name": "Vidrasău",
                "countryCode": "RO"
            },
            {
                "name": "Viforoasa",
                "countryCode": "RO"
            },
            {
                "name": "Viilor",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vișinelu",
                "countryCode": "RO"
            },
            {
                "name": "Voiniceni",
                "countryCode": "RO"
            },
            {
                "name": "Voivodeni",
                "countryCode": "RO"
            },
            {
                "name": "Vulcan",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Vădaș",
                "countryCode": "RO"
            },
            {
                "name": "Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Vălenii",
                "countryCode": "RO"
            },
            {
                "name": "Vălenii de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Vălișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vălureni",
                "countryCode": "RO"
            },
            {
                "name": "Vărgata",
                "countryCode": "RO"
            },
            {
                "name": "Vătava",
                "countryCode": "RO"
            },
            {
                "name": "Zagăr",
                "countryCode": "RO"
            },
            {
                "name": "Zau de Câmpie",
                "countryCode": "RO"
            },
            {
                "name": "Zimți",
                "countryCode": "RO"
            },
            {
                "name": "Zăpodea",
                "countryCode": "RO"
            },
            {
                "name": "Șaeș",
                "countryCode": "RO"
            },
            {
                "name": "Șandru",
                "countryCode": "RO"
            },
            {
                "name": "Șapartoc",
                "countryCode": "RO"
            },
            {
                "name": "Șardu Nirajului",
                "countryCode": "RO"
            },
            {
                "name": "Șerbeni",
                "countryCode": "RO"
            },
            {
                "name": "Șeulia de Mureș",
                "countryCode": "RO"
            },
            {
                "name": "Șilea Nirajului",
                "countryCode": "RO"
            },
            {
                "name": "Șincai",
                "countryCode": "RO"
            },
            {
                "name": "Șincai-Fânațe",
                "countryCode": "RO"
            },
            {
                "name": "Șoard",
                "countryCode": "RO"
            },
            {
                "name": "Șoimuș",
                "countryCode": "RO"
            },
            {
                "name": "Șomoștelnic",
                "countryCode": "RO"
            },
            {
                "name": "Șoromiclea",
                "countryCode": "RO"
            },
            {
                "name": "Ștefanca",
                "countryCode": "RO"
            },
            {
                "name": "Ștefăneaca",
                "countryCode": "RO"
            },
            {
                "name": "Șăulia",
                "countryCode": "RO"
            },
            {
                "name": "Șăulița",
                "countryCode": "RO"
            },
            {
                "name": "Șăușa",
                "countryCode": "RO"
            },
            {
                "name": "Țigmandru",
                "countryCode": "RO"
            },
            {
                "name": "Țiptelnic",
                "countryCode": "RO"
            },
            {
                "name": "Țopa",
                "countryCode": "RO"
            },
            {
                "name": "Adjudeni",
                "countryCode": "RO"
            },
            {
                "name": "Agapia",
                "countryCode": "RO"
            },
            {
                "name": "Bahna",
                "countryCode": "RO"
            },
            {
                "name": "Barticești",
                "countryCode": "RO"
            },
            {
                "name": "Bicaz",
                "countryCode": "RO"
            },
            {
                "name": "Bicaz-Chei",
                "countryCode": "RO"
            },
            {
                "name": "Bicazu Ardelean",
                "countryCode": "RO"
            },
            {
                "name": "Bistrița",
                "countryCode": "RO"
            },
            {
                "name": "Boboiești",
                "countryCode": "RO"
            },
            {
                "name": "Bodeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bodeștii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Boghicea",
                "countryCode": "RO"
            },
            {
                "name": "Borca",
                "countryCode": "RO"
            },
            {
                "name": "Borleşti",
                "countryCode": "RO"
            },
            {
                "name": "Botești",
                "countryCode": "RO"
            },
            {
                "name": "Bozieni",
                "countryCode": "RO"
            },
            {
                "name": "Brusturi",
                "countryCode": "RO"
            },
            {
                "name": "Budești",
                "countryCode": "RO"
            },
            {
                "name": "Buruienești",
                "countryCode": "RO"
            },
            {
                "name": "Bâra",
                "countryCode": "RO"
            },
            {
                "name": "Bârgăuani",
                "countryCode": "RO"
            },
            {
                "name": "Bălușești",
                "countryCode": "RO"
            },
            {
                "name": "Bălţăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Bărcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Ceahlău",
                "countryCode": "RO"
            },
            {
                "name": "Chilii",
                "countryCode": "RO"
            },
            {
                "name": "Chintinici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Agapia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alexandru Cel Bun",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bahna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bicaz Chei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bicazu Ardelean",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bodeşti-Precista",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boghicea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Borleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bozieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brusturi-Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bâra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârgãuani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălţăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceahlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cordun",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costişa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crăcăoani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dochia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Doljeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dulceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrava Roşie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dămuc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fãurei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fărcaşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gherăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghindăoani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Girov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grinţieş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grumăzeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gâdinţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârcina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hangu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Icuseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ion Creangă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moldoveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărgineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oniceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petricani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Piatra Şoimului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pipirig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podoleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Teiului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pânceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pângăraţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păstrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Români",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ruginoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rãzboieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răuceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sagna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secuieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săbăoani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săvineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tarcău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tazlău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Taşca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Timişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Trifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tupilaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tămăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urecheni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Ursului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânători Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţibucani",
                "countryCode": "RO"
            },
            {
                "name": "Cordun",
                "countryCode": "RO"
            },
            {
                "name": "Costişa",
                "countryCode": "RO"
            },
            {
                "name": "Cotu Vameș",
                "countryCode": "RO"
            },
            {
                "name": "Cracăul Negru",
                "countryCode": "RO"
            },
            {
                "name": "Crăcăoani",
                "countryCode": "RO"
            },
            {
                "name": "Cuejdiu",
                "countryCode": "RO"
            },
            {
                "name": "Cut",
                "countryCode": "RO"
            },
            {
                "name": "Cândeşti",
                "countryCode": "RO"
            },
            {
                "name": "Dobreni",
                "countryCode": "RO"
            },
            {
                "name": "Dochia",
                "countryCode": "RO"
            },
            {
                "name": "Dodeni",
                "countryCode": "RO"
            },
            {
                "name": "Dolhești",
                "countryCode": "RO"
            },
            {
                "name": "Doljeşti",
                "countryCode": "RO"
            },
            {
                "name": "Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgănești",
                "countryCode": "RO"
            },
            {
                "name": "Dulceşti",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava Roşie",
                "countryCode": "RO"
            },
            {
                "name": "Dămuc",
                "countryCode": "RO"
            },
            {
                "name": "Filioara",
                "countryCode": "RO"
            },
            {
                "name": "Fărcaşa",
                "countryCode": "RO"
            },
            {
                "name": "Făurei",
                "countryCode": "RO"
            },
            {
                "name": "Gherăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Ghindăoani",
                "countryCode": "RO"
            },
            {
                "name": "Girov",
                "countryCode": "RO"
            },
            {
                "name": "Goșmani",
                "countryCode": "RO"
            },
            {
                "name": "Grinţieş",
                "countryCode": "RO"
            },
            {
                "name": "Grumăzeşti",
                "countryCode": "RO"
            },
            {
                "name": "Gâdinţi",
                "countryCode": "RO"
            },
            {
                "name": "Gârcina",
                "countryCode": "RO"
            },
            {
                "name": "Hangu",
                "countryCode": "RO"
            },
            {
                "name": "Hlăpești",
                "countryCode": "RO"
            },
            {
                "name": "Hociungi",
                "countryCode": "RO"
            },
            {
                "name": "Horia",
                "countryCode": "RO"
            },
            {
                "name": "Humulești",
                "countryCode": "RO"
            },
            {
                "name": "Icuseşti",
                "countryCode": "RO"
            },
            {
                "name": "Ion Creangă",
                "countryCode": "RO"
            },
            {
                "name": "Izvoare",
                "countryCode": "RO"
            },
            {
                "name": "Luminiș",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Mastacăn",
                "countryCode": "RO"
            },
            {
                "name": "Miron Costin",
                "countryCode": "RO"
            },
            {
                "name": "Moldoveni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Piatra-Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Roman",
                "countryCode": "RO"
            },
            {
                "name": "Mărgineni",
                "countryCode": "RO"
            },
            {
                "name": "Negrești",
                "countryCode": "RO"
            },
            {
                "name": "Nemțișor",
                "countryCode": "RO"
            },
            {
                "name": "Nisiporești",
                "countryCode": "RO"
            },
            {
                "name": "Oanțu",
                "countryCode": "RO"
            },
            {
                "name": "Oglinzi",
                "countryCode": "RO"
            },
            {
                "name": "Oniceni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bicaz",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Roznov",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Târgu Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Petricani",
                "countryCode": "RO"
            },
            {
                "name": "Piatra Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Piatra Şoimului",
                "countryCode": "RO"
            },
            {
                "name": "Pildești",
                "countryCode": "RO"
            },
            {
                "name": "Pipirig",
                "countryCode": "RO"
            },
            {
                "name": "Podoleni",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Crăcăoani",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Teiului",
                "countryCode": "RO"
            },
            {
                "name": "Poienari",
                "countryCode": "RO"
            },
            {
                "name": "Poieni",
                "countryCode": "RO"
            },
            {
                "name": "Poloboc",
                "countryCode": "RO"
            },
            {
                "name": "Pânceşti",
                "countryCode": "RO"
            },
            {
                "name": "Pângăraţi",
                "countryCode": "RO"
            },
            {
                "name": "Pângărăcior",
                "countryCode": "RO"
            },
            {
                "name": "Păstrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Roman",
                "countryCode": "RO"
            },
            {
                "name": "Români",
                "countryCode": "RO"
            },
            {
                "name": "Rotunda",
                "countryCode": "RO"
            },
            {
                "name": "Roznov",
                "countryCode": "RO"
            },
            {
                "name": "Ruginoasa",
                "countryCode": "RO"
            },
            {
                "name": "Ruseni",
                "countryCode": "RO"
            },
            {
                "name": "Rădeni",
                "countryCode": "RO"
            },
            {
                "name": "Răuceşti",
                "countryCode": "RO"
            },
            {
                "name": "Războienii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Sabasa",
                "countryCode": "RO"
            },
            {
                "name": "Sagna",
                "countryCode": "RO"
            },
            {
                "name": "Secuieni",
                "countryCode": "RO"
            },
            {
                "name": "Siliștea",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Stejaru",
                "countryCode": "RO"
            },
            {
                "name": "Stânca",
                "countryCode": "RO"
            },
            {
                "name": "Stăniţa",
                "countryCode": "RO"
            },
            {
                "name": "Săbăoani",
                "countryCode": "RO"
            },
            {
                "name": "Săvineşti",
                "countryCode": "RO"
            },
            {
                "name": "Tarcău",
                "countryCode": "RO"
            },
            {
                "name": "Tazlău",
                "countryCode": "RO"
            },
            {
                "name": "Taşca",
                "countryCode": "RO"
            },
            {
                "name": "Telec",
                "countryCode": "RO"
            },
            {
                "name": "Timişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Topolița",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Trifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tupilaţi",
                "countryCode": "RO"
            },
            {
                "name": "Târgu Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Tămăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Urecheni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Arini",
                "countryCode": "RO"
            },
            {
                "name": "Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Valea Ursului",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vânători-Neamţ",
                "countryCode": "RO"
            },
            {
                "name": "Vădurele",
                "countryCode": "RO"
            },
            {
                "name": "Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Zăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Ţibucani",
                "countryCode": "RO"
            },
            {
                "name": "Țolici",
                "countryCode": "RO"
            },
            {
                "name": "Alimănești",
                "countryCode": "RO"
            },
            {
                "name": "Alunișu",
                "countryCode": "RO"
            },
            {
                "name": "Bacea",
                "countryCode": "RO"
            },
            {
                "name": "Baldovineşti",
                "countryCode": "RO"
            },
            {
                "name": "Balş",
                "countryCode": "RO"
            },
            {
                "name": "Barza",
                "countryCode": "RO"
            },
            {
                "name": "Bircii",
                "countryCode": "RO"
            },
            {
                "name": "Bobiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Braneț",
                "countryCode": "RO"
            },
            {
                "name": "Brastavățu",
                "countryCode": "RO"
            },
            {
                "name": "Brebeni",
                "countryCode": "RO"
            },
            {
                "name": "Brâncoveni",
                "countryCode": "RO"
            },
            {
                "name": "Bucinişu",
                "countryCode": "RO"
            },
            {
                "name": "Bușca",
                "countryCode": "RO"
            },
            {
                "name": "Băbiciu",
                "countryCode": "RO"
            },
            {
                "name": "Bălteni",
                "countryCode": "RO"
            },
            {
                "name": "Bălănești",
                "countryCode": "RO"
            },
            {
                "name": "Bărăștii de Vede",
                "countryCode": "RO"
            },
            {
                "name": "Caracal",
                "countryCode": "RO"
            },
            {
                "name": "Catanele",
                "countryCode": "RO"
            },
            {
                "name": "Cepari",
                "countryCode": "RO"
            },
            {
                "name": "Cezieni",
                "countryCode": "RO"
            },
            {
                "name": "Cilieni",
                "countryCode": "RO"
            },
            {
                "name": "Coloneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comanca",
                "countryCode": "RO"
            },
            {
                "name": "Comani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baldovineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bobiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brastavăţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brebeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brâncoveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucinişu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bãrãşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băbiciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cezieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cilieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coloneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coteana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crâmpoia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cungrea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curtişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârlogani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãlui",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deveselu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobreţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobrosloveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobroteasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobrun",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăghiceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Făgeţelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fălcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fărcaşele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghimpeţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giuvărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gostavăţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grojdibodu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grãdinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grãdinile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Padinii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârcov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găvăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ianca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iancu Jianu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Icoana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ipotesti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izbiceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Leleasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Milcov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Morunglav",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mărunţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicolae Titulescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oboga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Obârşia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oporelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Optaşi-Mãgura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orlea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Osica de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Osica de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perieţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pleşoiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poboru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Priseaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pârşcoveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Radomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Redea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rotunda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rusăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Schitu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scărişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slătioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Spineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sprâncenata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoicăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Strejeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Studina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâmbureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sârbii-Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teslui",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tufeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urzica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Verguleasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vitomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vişina Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voineasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulpeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vădastra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vădăstriţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şerbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şopârliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Corabia",
                "countryCode": "RO"
            },
            {
                "name": "Corbu",
                "countryCode": "RO"
            },
            {
                "name": "Coteana",
                "countryCode": "RO"
            },
            {
                "name": "Crușovu",
                "countryCode": "RO"
            },
            {
                "name": "Crâmpoia",
                "countryCode": "RO"
            },
            {
                "name": "Crăciunei",
                "countryCode": "RO"
            },
            {
                "name": "Cungrea",
                "countryCode": "RO"
            },
            {
                "name": "Curtişoara",
                "countryCode": "RO"
            },
            {
                "name": "Cârlogani",
                "countryCode": "RO"
            },
            {
                "name": "Călinești",
                "countryCode": "RO"
            },
            {
                "name": "Călui",
                "countryCode": "RO"
            },
            {
                "name": "Dejești",
                "countryCode": "RO"
            },
            {
                "name": "Deveselu",
                "countryCode": "RO"
            },
            {
                "name": "Doanca",
                "countryCode": "RO"
            },
            {
                "name": "Dobreţu",
                "countryCode": "RO"
            },
            {
                "name": "Dobrosloveni",
                "countryCode": "RO"
            },
            {
                "name": "Dobroteasa",
                "countryCode": "RO"
            },
            {
                "name": "Dobrotinet",
                "countryCode": "RO"
            },
            {
                "name": "Dobrun",
                "countryCode": "RO"
            },
            {
                "name": "Dranovățu",
                "countryCode": "RO"
            },
            {
                "name": "Drăghiceni",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti-Olt",
                "countryCode": "RO"
            },
            {
                "name": "Dumitrești",
                "countryCode": "RO"
            },
            {
                "name": "Dăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Făgeţelu",
                "countryCode": "RO"
            },
            {
                "name": "Fălcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Fărcaşele",
                "countryCode": "RO"
            },
            {
                "name": "Fărcașu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ghimpați",
                "countryCode": "RO"
            },
            {
                "name": "Ghimpețeni",
                "countryCode": "RO"
            },
            {
                "name": "Ghioca",
                "countryCode": "RO"
            },
            {
                "name": "Giuvărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Gostavăţu",
                "countryCode": "RO"
            },
            {
                "name": "Greci",
                "countryCode": "RO"
            },
            {
                "name": "Grojdibodu",
                "countryCode": "RO"
            },
            {
                "name": "Gropșani",
                "countryCode": "RO"
            },
            {
                "name": "Grădinari",
                "countryCode": "RO"
            },
            {
                "name": "Gura Căluiu",
                "countryCode": "RO"
            },
            {
                "name": "Gura Padinii",
                "countryCode": "RO"
            },
            {
                "name": "Gârcov",
                "countryCode": "RO"
            },
            {
                "name": "Găneasa",
                "countryCode": "RO"
            },
            {
                "name": "Găvănești",
                "countryCode": "RO"
            },
            {
                "name": "Ianca",
                "countryCode": "RO"
            },
            {
                "name": "Iancu Jianu",
                "countryCode": "RO"
            },
            {
                "name": "Icoana",
                "countryCode": "RO"
            },
            {
                "name": "Ipotești",
                "countryCode": "RO"
            },
            {
                "name": "Izbiceni",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Jieni",
                "countryCode": "RO"
            },
            {
                "name": "Leleasca",
                "countryCode": "RO"
            },
            {
                "name": "Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Morunglav",
                "countryCode": "RO"
            },
            {
                "name": "Movileni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Caracal",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Slatina",
                "countryCode": "RO"
            },
            {
                "name": "Mărgineni Slobozia",
                "countryCode": "RO"
            },
            {
                "name": "Mărunţei",
                "countryCode": "RO"
            },
            {
                "name": "Negreni",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Titulescu",
                "countryCode": "RO"
            },
            {
                "name": "Oboga",
                "countryCode": "RO"
            },
            {
                "name": "Obârşia",
                "countryCode": "RO"
            },
            {
                "name": "Olari",
                "countryCode": "RO"
            },
            {
                "name": "Oporelu",
                "countryCode": "RO"
            },
            {
                "name": "Optaşi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Balş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Corabia",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Drãgãneşti-Olt",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Piatra-Olt",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Potcoava",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Scorniceşti",
                "countryCode": "RO"
            },
            {
                "name": "Orlea",
                "countryCode": "RO"
            },
            {
                "name": "Orlea Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Osica de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Osica de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Perieţi",
                "countryCode": "RO"
            },
            {
                "name": "Piatra",
                "countryCode": "RO"
            },
            {
                "name": "Piatra Olt",
                "countryCode": "RO"
            },
            {
                "name": "Pleşoiu",
                "countryCode": "RO"
            },
            {
                "name": "Poboru",
                "countryCode": "RO"
            },
            {
                "name": "Potcoava",
                "countryCode": "RO"
            },
            {
                "name": "Potcoava Fălcoeni",
                "countryCode": "RO"
            },
            {
                "name": "Potelu",
                "countryCode": "RO"
            },
            {
                "name": "Potlogeni",
                "countryCode": "RO"
            },
            {
                "name": "Priseaca",
                "countryCode": "RO"
            },
            {
                "name": "Pârşcoveni",
                "countryCode": "RO"
            },
            {
                "name": "Radomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Redea",
                "countryCode": "RO"
            },
            {
                "name": "Rotunda",
                "countryCode": "RO"
            },
            {
                "name": "Rusăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Schitu",
                "countryCode": "RO"
            },
            {
                "name": "Scorniceşti",
                "countryCode": "RO"
            },
            {
                "name": "Scărişoara",
                "countryCode": "RO"
            },
            {
                "name": "Seaca",
                "countryCode": "RO"
            },
            {
                "name": "Sinești",
                "countryCode": "RO"
            },
            {
                "name": "Slatina",
                "countryCode": "RO"
            },
            {
                "name": "Slătioara",
                "countryCode": "RO"
            },
            {
                "name": "Slăveni",
                "countryCode": "RO"
            },
            {
                "name": "Sprâncenata",
                "countryCode": "RO"
            },
            {
                "name": "Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Stoicăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Strejeşti",
                "countryCode": "RO"
            },
            {
                "name": "Strejeștii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Studina",
                "countryCode": "RO"
            },
            {
                "name": "Studinița",
                "countryCode": "RO"
            },
            {
                "name": "Stăvaru",
                "countryCode": "RO"
            },
            {
                "name": "Sâmbureşti",
                "countryCode": "RO"
            },
            {
                "name": "Teslui",
                "countryCode": "RO"
            },
            {
                "name": "Tia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Topana",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Tufeni",
                "countryCode": "RO"
            },
            {
                "name": "Tătuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Ursa",
                "countryCode": "RO"
            },
            {
                "name": "Urzica",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Valea Merilor",
                "countryCode": "RO"
            },
            {
                "name": "Verguleasa",
                "countryCode": "RO"
            },
            {
                "name": "Vitomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Vitănești",
                "countryCode": "RO"
            },
            {
                "name": "Vişina",
                "countryCode": "RO"
            },
            {
                "name": "Vișina Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Vlădila",
                "countryCode": "RO"
            },
            {
                "name": "Voineasa",
                "countryCode": "RO"
            },
            {
                "name": "Vulpeni",
                "countryCode": "RO"
            },
            {
                "name": "Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcele",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcelele de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Vădastra",
                "countryCode": "RO"
            },
            {
                "name": "Vădăstriţa",
                "countryCode": "RO"
            },
            {
                "name": "Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Şerbăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Şopârliţa",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Adunaţi",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti-Paleologu",
                "countryCode": "RO"
            },
            {
                "name": "Albești-Muru",
                "countryCode": "RO"
            },
            {
                "name": "Aluniş",
                "countryCode": "RO"
            },
            {
                "name": "Apostolache",
                "countryCode": "RO"
            },
            {
                "name": "Ariceştii-Rahtivani",
                "countryCode": "RO"
            },
            {
                "name": "Ariceștii Zeletin",
                "countryCode": "RO"
            },
            {
                "name": "Azuga",
                "countryCode": "RO"
            },
            {
                "name": "Baba Ana",
                "countryCode": "RO"
            },
            {
                "name": "Balta Doamnei",
                "countryCode": "RO"
            },
            {
                "name": "Berceni",
                "countryCode": "RO"
            },
            {
                "name": "Bertea",
                "countryCode": "RO"
            },
            {
                "name": "Blejoi",
                "countryCode": "RO"
            },
            {
                "name": "Bobolia",
                "countryCode": "RO"
            },
            {
                "name": "Boldeşti",
                "countryCode": "RO"
            },
            {
                "name": "Boldeşti-Scăeni",
                "countryCode": "RO"
            },
            {
                "name": "Bordenii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Brazii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Breaza de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Breaza de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Brebu Megieșesc",
                "countryCode": "RO"
            },
            {
                "name": "Brebu Mânăstirei",
                "countryCode": "RO"
            },
            {
                "name": "Bucov",
                "countryCode": "RO"
            },
            {
                "name": "Buda",
                "countryCode": "RO"
            },
            {
                "name": "Bughea de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Buşteni",
                "countryCode": "RO"
            },
            {
                "name": "Băicoi",
                "countryCode": "RO"
            },
            {
                "name": "Bălțești",
                "countryCode": "RO"
            },
            {
                "name": "Băneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bărcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bătești",
                "countryCode": "RO"
            },
            {
                "name": "Bătrâni",
                "countryCode": "RO"
            },
            {
                "name": "Ceptura de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ceptura de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ceraşu",
                "countryCode": "RO"
            },
            {
                "name": "Chiojdeanca",
                "countryCode": "RO"
            },
            {
                "name": "Chițorani",
                "countryCode": "RO"
            },
            {
                "name": "Cioranii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Cioranii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Coada Izvorului",
                "countryCode": "RO"
            },
            {
                "name": "Cocorăștii Colț",
                "countryCode": "RO"
            },
            {
                "name": "Cocorăștii Mislii",
                "countryCode": "RO"
            },
            {
                "name": "Colceag",
                "countryCode": "RO"
            },
            {
                "name": "Comarnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adunaţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeşti-Paleologu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aluniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apostolache",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ariceştii Zeletin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ariceştii-Rahtivani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baba Ana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balta Doamnei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bertea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blejoi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boldeşti-Gradiştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brazi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brebu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bătrâni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceptura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceraşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiojdeanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciorani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cocorãştii Mislii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cocorăştii-Colţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Colceag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cosminele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călugăreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cărbuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drajna de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrãveşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Filipeştii de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Filipeştii de Târg",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fulga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gherghiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gorgota",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gornet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gornet-Cricov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Vadului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Vitioarei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iordãcheanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jugureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lapoş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lipăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgurele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măneciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plopu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Podenii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Câmpina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poienarii Burchii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Predeal-Sărari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Proviţa de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Proviţa de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Puchenii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păcureţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râfov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scorţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secăria",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Starchiojd",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Surani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sângeru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălciile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Talea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Telega",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tinosu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târgşoru Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Săpat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Călugărească",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Doftanei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vâlcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vărbilău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şirna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şoimari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şotrile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefeşti",
                "countryCode": "RO"
            },
            {
                "name": "Conduratu",
                "countryCode": "RO"
            },
            {
                "name": "Corlătești",
                "countryCode": "RO"
            },
            {
                "name": "Cornu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Cornu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Cosmina de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Crivina",
                "countryCode": "RO"
            },
            {
                "name": "Câmpina",
                "countryCode": "RO"
            },
            {
                "name": "Călugăreni",
                "countryCode": "RO"
            },
            {
                "name": "Cărbuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Cătina",
                "countryCode": "RO"
            },
            {
                "name": "Dițești",
                "countryCode": "RO"
            },
            {
                "name": "Doftana",
                "countryCode": "RO"
            },
            {
                "name": "Drajna de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Drajna de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăveşti",
                "countryCode": "RO"
            },
            {
                "name": "Dâmbu",
                "countryCode": "RO"
            },
            {
                "name": "Filipeştii de Pădure",
                "countryCode": "RO"
            },
            {
                "name": "Filipeştii de Târg",
                "countryCode": "RO"
            },
            {
                "name": "Floreşti",
                "countryCode": "RO"
            },
            {
                "name": "Fulga de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Fulga de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Făgetu",
                "countryCode": "RO"
            },
            {
                "name": "Gheaba",
                "countryCode": "RO"
            },
            {
                "name": "Gherghiţa",
                "countryCode": "RO"
            },
            {
                "name": "Ghioșești",
                "countryCode": "RO"
            },
            {
                "name": "Gorgota",
                "countryCode": "RO"
            },
            {
                "name": "Gornet",
                "countryCode": "RO"
            },
            {
                "name": "Gornet-Cricov",
                "countryCode": "RO"
            },
            {
                "name": "Goruna",
                "countryCode": "RO"
            },
            {
                "name": "Gradiștea",
                "countryCode": "RO"
            },
            {
                "name": "Groșani",
                "countryCode": "RO"
            },
            {
                "name": "Gura Beliei",
                "countryCode": "RO"
            },
            {
                "name": "Gura Vadului",
                "countryCode": "RO"
            },
            {
                "name": "Gura Viţioarei",
                "countryCode": "RO"
            },
            {
                "name": "Găgeni",
                "countryCode": "RO"
            },
            {
                "name": "Homorâciu",
                "countryCode": "RO"
            },
            {
                "name": "Inotești",
                "countryCode": "RO"
            },
            {
                "name": "Iordăcheanu",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Jugureni",
                "countryCode": "RO"
            },
            {
                "name": "Lapoș",
                "countryCode": "RO"
            },
            {
                "name": "Liliești",
                "countryCode": "RO"
            },
            {
                "name": "Lipăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Livadea",
                "countryCode": "RO"
            },
            {
                "name": "Loloiasca",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Prahovei",
                "countryCode": "RO"
            },
            {
                "name": "Magula",
                "countryCode": "RO"
            },
            {
                "name": "Meri",
                "countryCode": "RO"
            },
            {
                "name": "Minieri",
                "countryCode": "RO"
            },
            {
                "name": "Mireșu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Miroslăvești",
                "countryCode": "RO"
            },
            {
                "name": "Mislea",
                "countryCode": "RO"
            },
            {
                "name": "Mizil",
                "countryCode": "RO"
            },
            {
                "name": "Moceşti",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Câmpina",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Ploieşti",
                "countryCode": "RO"
            },
            {
                "name": "Măgurele",
                "countryCode": "RO"
            },
            {
                "name": "Măgureni",
                "countryCode": "RO"
            },
            {
                "name": "Măneciu-Ungureni",
                "countryCode": "RO"
            },
            {
                "name": "Măneşti",
                "countryCode": "RO"
            },
            {
                "name": "Mărginenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Nedelea",
                "countryCode": "RO"
            },
            {
                "name": "Negoiești",
                "countryCode": "RO"
            },
            {
                "name": "Ocina de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ogretin",
                "countryCode": "RO"
            },
            {
                "name": "Olari",
                "countryCode": "RO"
            },
            {
                "name": "Ologeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Azuga",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Boldeşti-Scãeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Buşteni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãicoi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Comarnic",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Mizil",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Plopeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sinaia",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Slãnic",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Urlaţi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Vãlenii De Munte",
                "countryCode": "RO"
            },
            {
                "name": "Ostrovu",
                "countryCode": "RO"
            },
            {
                "name": "Palanca",
                "countryCode": "RO"
            },
            {
                "name": "Pantazi",
                "countryCode": "RO"
            },
            {
                "name": "Parepa-Rușani",
                "countryCode": "RO"
            },
            {
                "name": "Perșunari",
                "countryCode": "RO"
            },
            {
                "name": "Pietriceaua",
                "countryCode": "RO"
            },
            {
                "name": "Pietroșani",
                "countryCode": "RO"
            },
            {
                "name": "Pleașa",
                "countryCode": "RO"
            },
            {
                "name": "Ploieşti",
                "countryCode": "RO"
            },
            {
                "name": "Ploieștiori",
                "countryCode": "RO"
            },
            {
                "name": "Plopeni",
                "countryCode": "RO"
            },
            {
                "name": "Plopu",
                "countryCode": "RO"
            },
            {
                "name": "Podenii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Podenii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Câmpina",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Țapului",
                "countryCode": "RO"
            },
            {
                "name": "Poienarii Apostoli",
                "countryCode": "RO"
            },
            {
                "name": "Poienarii Burchii",
                "countryCode": "RO"
            },
            {
                "name": "Popești",
                "countryCode": "RO"
            },
            {
                "name": "Posada",
                "countryCode": "RO"
            },
            {
                "name": "Poseștii-Pământeni",
                "countryCode": "RO"
            },
            {
                "name": "Potigrafu",
                "countryCode": "RO"
            },
            {
                "name": "Proviţa de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Proviţa de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Prăjani",
                "countryCode": "RO"
            },
            {
                "name": "Puchenii Mari",
                "countryCode": "RO"
            },
            {
                "name": "Puchenii Moșneni",
                "countryCode": "RO"
            },
            {
                "name": "Păcureţi",
                "countryCode": "RO"
            },
            {
                "name": "Păuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Rachieri",
                "countryCode": "RO"
            },
            {
                "name": "Românești",
                "countryCode": "RO"
            },
            {
                "name": "Râfov",
                "countryCode": "RO"
            },
            {
                "name": "Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Schiulești",
                "countryCode": "RO"
            },
            {
                "name": "Scorţeni",
                "countryCode": "RO"
            },
            {
                "name": "Scurtești",
                "countryCode": "RO"
            },
            {
                "name": "Seciu",
                "countryCode": "RO"
            },
            {
                "name": "Secăria",
                "countryCode": "RO"
            },
            {
                "name": "Sinaia",
                "countryCode": "RO"
            },
            {
                "name": "Slon",
                "countryCode": "RO"
            },
            {
                "name": "Slănic",
                "countryCode": "RO"
            },
            {
                "name": "Starchiojd",
                "countryCode": "RO"
            },
            {
                "name": "Stoenești",
                "countryCode": "RO"
            },
            {
                "name": "Strejnicu",
                "countryCode": "RO"
            },
            {
                "name": "Surani",
                "countryCode": "RO"
            },
            {
                "name": "Sângeru",
                "countryCode": "RO"
            },
            {
                "name": "Sălciile",
                "countryCode": "RO"
            },
            {
                "name": "Talea",
                "countryCode": "RO"
            },
            {
                "name": "Teişani",
                "countryCode": "RO"
            },
            {
                "name": "Telega",
                "countryCode": "RO"
            },
            {
                "name": "Teșila",
                "countryCode": "RO"
            },
            {
                "name": "Tinosu",
                "countryCode": "RO"
            },
            {
                "name": "Tomşani",
                "countryCode": "RO"
            },
            {
                "name": "Trăisteni",
                "countryCode": "RO"
            },
            {
                "name": "Tufeni",
                "countryCode": "RO"
            },
            {
                "name": "Târgşoru Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Tăriceni",
                "countryCode": "RO"
            },
            {
                "name": "Tătaru",
                "countryCode": "RO"
            },
            {
                "name": "Tătărani",
                "countryCode": "RO"
            },
            {
                "name": "Urlaţi",
                "countryCode": "RO"
            },
            {
                "name": "Urleta",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Părului",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Săpat",
                "countryCode": "RO"
            },
            {
                "name": "Valea Cucului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Călugărească",
                "countryCode": "RO"
            },
            {
                "name": "Valea Dulce",
                "countryCode": "RO"
            },
            {
                "name": "Valea Târsei",
                "countryCode": "RO"
            },
            {
                "name": "Varnița",
                "countryCode": "RO"
            },
            {
                "name": "Vâlcăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Vălenii de Munte",
                "countryCode": "RO"
            },
            {
                "name": "Vărbila",
                "countryCode": "RO"
            },
            {
                "name": "Vărbilău",
                "countryCode": "RO"
            },
            {
                "name": "Şirna",
                "countryCode": "RO"
            },
            {
                "name": "Şoimari",
                "countryCode": "RO"
            },
            {
                "name": "Şotrile",
                "countryCode": "RO"
            },
            {
                "name": "Ştefeşti",
                "countryCode": "RO"
            },
            {
                "name": "Șipotu",
                "countryCode": "RO"
            },
            {
                "name": "Țintea",
                "countryCode": "RO"
            },
            {
                "name": "Agnita",
                "countryCode": "RO"
            },
            {
                "name": "Agârbiciu",
                "countryCode": "RO"
            },
            {
                "name": "Alma",
                "countryCode": "RO"
            },
            {
                "name": "Alămor",
                "countryCode": "RO"
            },
            {
                "name": "Alțâna",
                "countryCode": "RO"
            },
            {
                "name": "Apoldu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Arpaşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Arpașu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Avrig",
                "countryCode": "RO"
            },
            {
                "name": "Axente Sever",
                "countryCode": "RO"
            },
            {
                "name": "Aţel",
                "countryCode": "RO"
            },
            {
                "name": "Bazna",
                "countryCode": "RO"
            },
            {
                "name": "Biertan",
                "countryCode": "RO"
            },
            {
                "name": "Blăjel",
                "countryCode": "RO"
            },
            {
                "name": "Boian",
                "countryCode": "RO"
            },
            {
                "name": "Boița",
                "countryCode": "RO"
            },
            {
                "name": "Bradu",
                "countryCode": "RO"
            },
            {
                "name": "Brateiu",
                "countryCode": "RO"
            },
            {
                "name": "Bruiu",
                "countryCode": "RO"
            },
            {
                "name": "Brădeni",
                "countryCode": "RO"
            },
            {
                "name": "Bârghiş",
                "countryCode": "RO"
            },
            {
                "name": "Chirpăr",
                "countryCode": "RO"
            },
            {
                "name": "Cisnădie",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alma",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alțâna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apoldu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arpaşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Axente Sever",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Aţel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bazna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Biertan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blăjel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brateiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bruiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brădeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârghiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chirpăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârţişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dârlos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Râului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hoghilag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iacobeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Laslea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Loamneş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ludoş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Marpod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Merghindeal",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Micăsasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moşna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nocrich",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orlat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poplaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Porumbacu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râu Sadului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răşinari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sadu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slimnic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tilişca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turnu Roşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târnava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Viilor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vurpăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şeica Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şeica Mică",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şelimbăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şura Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şura Mică",
                "countryCode": "RO"
            },
            {
                "name": "Copşa Mică",
                "countryCode": "RO"
            },
            {
                "name": "Cristian",
                "countryCode": "RO"
            },
            {
                "name": "Cârţa",
                "countryCode": "RO"
            },
            {
                "name": "Cârţişoara",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Dârlos",
                "countryCode": "RO"
            },
            {
                "name": "Gura Râului",
                "countryCode": "RO"
            },
            {
                "name": "Hoghilag",
                "countryCode": "RO"
            },
            {
                "name": "Iacobeni",
                "countryCode": "RO"
            },
            {
                "name": "Ighișu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Jina",
                "countryCode": "RO"
            },
            {
                "name": "Laslea",
                "countryCode": "RO"
            },
            {
                "name": "Loamneş",
                "countryCode": "RO"
            },
            {
                "name": "Ludoş",
                "countryCode": "RO"
            },
            {
                "name": "Marpod",
                "countryCode": "RO"
            },
            {
                "name": "Mediaş",
                "countryCode": "RO"
            },
            {
                "name": "Merghindeal",
                "countryCode": "RO"
            },
            {
                "name": "Micăsasa",
                "countryCode": "RO"
            },
            {
                "name": "Miercurea Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Mihăileni",
                "countryCode": "RO"
            },
            {
                "name": "Moşna",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Mediaş",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Sibiu",
                "countryCode": "RO"
            },
            {
                "name": "Mârșa",
                "countryCode": "RO"
            },
            {
                "name": "Mălâncrav",
                "countryCode": "RO"
            },
            {
                "name": "Nocrich",
                "countryCode": "RO"
            },
            {
                "name": "Nou",
                "countryCode": "RO"
            },
            {
                "name": "Ocna Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Agnita",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Avrig",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cisnãdie",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Copşa Micã",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Dumbrãveni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Miercurea Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ocna Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sãlişte",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Tãlmaciu",
                "countryCode": "RO"
            },
            {
                "name": "Orlat",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Sibiului",
                "countryCode": "RO"
            },
            {
                "name": "Poplaca",
                "countryCode": "RO"
            },
            {
                "name": "Porumbacu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Păuca",
                "countryCode": "RO"
            },
            {
                "name": "Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Roşia",
                "countryCode": "RO"
            },
            {
                "name": "Ruja",
                "countryCode": "RO"
            },
            {
                "name": "Râu Sadului",
                "countryCode": "RO"
            },
            {
                "name": "Răşinari",
                "countryCode": "RO"
            },
            {
                "name": "Sadu",
                "countryCode": "RO"
            },
            {
                "name": "Sibiu",
                "countryCode": "RO"
            },
            {
                "name": "Slimnic",
                "countryCode": "RO"
            },
            {
                "name": "Sălişte",
                "countryCode": "RO"
            },
            {
                "name": "Tilişca",
                "countryCode": "RO"
            },
            {
                "name": "Turnu Roşu",
                "countryCode": "RO"
            },
            {
                "name": "Târnava",
                "countryCode": "RO"
            },
            {
                "name": "Tălmaciu",
                "countryCode": "RO"
            },
            {
                "name": "Tălmăcel",
                "countryCode": "RO"
            },
            {
                "name": "Valea Viilor",
                "countryCode": "RO"
            },
            {
                "name": "Veștem",
                "countryCode": "RO"
            },
            {
                "name": "Vurpăr",
                "countryCode": "RO"
            },
            {
                "name": "Şeica Mică",
                "countryCode": "RO"
            },
            {
                "name": "Şelimbăr",
                "countryCode": "RO"
            },
            {
                "name": "Şeíca Mare",
                "countryCode": "RO"
            },
            {
                "name": "Şura Mare",
                "countryCode": "RO"
            },
            {
                "name": "Şura Mică",
                "countryCode": "RO"
            },
            {
                "name": "Șaroș pe Târnave",
                "countryCode": "RO"
            },
            {
                "name": "Aghireș",
                "countryCode": "RO"
            },
            {
                "name": "Agrij",
                "countryCode": "RO"
            },
            {
                "name": "Almaşu",
                "countryCode": "RO"
            },
            {
                "name": "Benesat",
                "countryCode": "RO"
            },
            {
                "name": "Bobota",
                "countryCode": "RO"
            },
            {
                "name": "Bocşa",
                "countryCode": "RO"
            },
            {
                "name": "Boghiș",
                "countryCode": "RO"
            },
            {
                "name": "Buciumi",
                "countryCode": "RO"
            },
            {
                "name": "Băbeni",
                "countryCode": "RO"
            },
            {
                "name": "Bălan",
                "countryCode": "RO"
            },
            {
                "name": "Bănişor",
                "countryCode": "RO"
            },
            {
                "name": "Camăr",
                "countryCode": "RO"
            },
            {
                "name": "Carastelec",
                "countryCode": "RO"
            },
            {
                "name": "Cehei",
                "countryCode": "RO"
            },
            {
                "name": "Cehu Silvaniei",
                "countryCode": "RO"
            },
            {
                "name": "Chendrea",
                "countryCode": "RO"
            },
            {
                "name": "Chieşd",
                "countryCode": "RO"
            },
            {
                "name": "Cizer",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Agrij",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Almaşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Benesat",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bobota",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bocşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boghiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buciumi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bănişor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Camăr",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Carastelec",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cehu Silvaniei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chieşd",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cizer",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşeiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crasna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Creaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cristolţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crişeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cuzăplac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobrin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fildu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gâlgău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârbou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Halmăşd",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hereclean",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hida",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horoatu Crasnei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ileanda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ip",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Letca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lozna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Marca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mirşid",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măerişte",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nuşfalău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Năpradea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pericei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plopiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Blenchii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Românaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Someş-Odorhei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Surduc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sâg",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmihaiu Almaşului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălăţig",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Treznea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valcãu De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârşolţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zalha",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zimbor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şamşud",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şimişna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şărmăşag",
                "countryCode": "RO"
            },
            {
                "name": "Comună Meseşenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Coşeiu",
                "countryCode": "RO"
            },
            {
                "name": "Crasna",
                "countryCode": "RO"
            },
            {
                "name": "Creaca",
                "countryCode": "RO"
            },
            {
                "name": "Cristolţ",
                "countryCode": "RO"
            },
            {
                "name": "Crişeni",
                "countryCode": "RO"
            },
            {
                "name": "Cuzăplac",
                "countryCode": "RO"
            },
            {
                "name": "Deja",
                "countryCode": "RO"
            },
            {
                "name": "Derșida",
                "countryCode": "RO"
            },
            {
                "name": "Dioșod",
                "countryCode": "RO"
            },
            {
                "name": "Dobrin",
                "countryCode": "RO"
            },
            {
                "name": "Dragu",
                "countryCode": "RO"
            },
            {
                "name": "Fildu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Giurtelecu Șimleului",
                "countryCode": "RO"
            },
            {
                "name": "Gâlgău",
                "countryCode": "RO"
            },
            {
                "name": "Gâlgău Almaşului",
                "countryCode": "RO"
            },
            {
                "name": "Gârbou",
                "countryCode": "RO"
            },
            {
                "name": "Halmăşd",
                "countryCode": "RO"
            },
            {
                "name": "Hereclean",
                "countryCode": "RO"
            },
            {
                "name": "Hida",
                "countryCode": "RO"
            },
            {
                "name": "Horoatu Crasnei",
                "countryCode": "RO"
            },
            {
                "name": "Ileanda",
                "countryCode": "RO"
            },
            {
                "name": "Ip",
                "countryCode": "RO"
            },
            {
                "name": "Jibou",
                "countryCode": "RO"
            },
            {
                "name": "Letca",
                "countryCode": "RO"
            },
            {
                "name": "Lozna",
                "countryCode": "RO"
            },
            {
                "name": "Mal",
                "countryCode": "RO"
            },
            {
                "name": "Marca",
                "countryCode": "RO"
            },
            {
                "name": "Marin",
                "countryCode": "RO"
            },
            {
                "name": "Meseşenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Mirşid",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Zalãu",
                "countryCode": "RO"
            },
            {
                "name": "Măerişte",
                "countryCode": "RO"
            },
            {
                "name": "Nuşfalău",
                "countryCode": "RO"
            },
            {
                "name": "Năpradea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Şimleu Silvaniei",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Jibou",
                "countryCode": "RO"
            },
            {
                "name": "Peceiu",
                "countryCode": "RO"
            },
            {
                "name": "Pericei",
                "countryCode": "RO"
            },
            {
                "name": "Plopiş",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Blenchii",
                "countryCode": "RO"
            },
            {
                "name": "Pusta",
                "countryCode": "RO"
            },
            {
                "name": "Românaşi",
                "countryCode": "RO"
            },
            {
                "name": "Rus",
                "countryCode": "RO"
            },
            {
                "name": "Someş-Odorhei",
                "countryCode": "RO"
            },
            {
                "name": "Stârciu",
                "countryCode": "RO"
            },
            {
                "name": "Surduc",
                "countryCode": "RO"
            },
            {
                "name": "Sâg",
                "countryCode": "RO"
            },
            {
                "name": "Sânmihaiu Almaşului",
                "countryCode": "RO"
            },
            {
                "name": "Sălăţig",
                "countryCode": "RO"
            },
            {
                "name": "Tihău",
                "countryCode": "RO"
            },
            {
                "name": "Treznea",
                "countryCode": "RO"
            },
            {
                "name": "Valcău de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Vârşolţ",
                "countryCode": "RO"
            },
            {
                "name": "Zalha",
                "countryCode": "RO"
            },
            {
                "name": "Zalău",
                "countryCode": "RO"
            },
            {
                "name": "Zimbor",
                "countryCode": "RO"
            },
            {
                "name": "Zăuan",
                "countryCode": "RO"
            },
            {
                "name": "Şamşud",
                "countryCode": "RO"
            },
            {
                "name": "Şimleu Silvaniei",
                "countryCode": "RO"
            },
            {
                "name": "Şărmăşag",
                "countryCode": "RO"
            },
            {
                "name": "Șimișna",
                "countryCode": "RO"
            },
            {
                "name": "Acâş",
                "countryCode": "RO"
            },
            {
                "name": "Agriș",
                "countryCode": "RO"
            },
            {
                "name": "Andrid",
                "countryCode": "RO"
            },
            {
                "name": "Apa",
                "countryCode": "RO"
            },
            {
                "name": "Ardud",
                "countryCode": "RO"
            },
            {
                "name": "Babța",
                "countryCode": "RO"
            },
            {
                "name": "Beltiug",
                "countryCode": "RO"
            },
            {
                "name": "Berveni",
                "countryCode": "RO"
            },
            {
                "name": "Bixad",
                "countryCode": "RO"
            },
            {
                "name": "Bogdand",
                "countryCode": "RO"
            },
            {
                "name": "Boinești",
                "countryCode": "RO"
            },
            {
                "name": "Botiz",
                "countryCode": "RO"
            },
            {
                "name": "Bârsău de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Bătarci",
                "countryCode": "RO"
            },
            {
                "name": "Carei",
                "countryCode": "RO"
            },
            {
                "name": "Cehal",
                "countryCode": "RO"
            },
            {
                "name": "Certeze",
                "countryCode": "RO"
            },
            {
                "name": "Cidreag",
                "countryCode": "RO"
            },
            {
                "name": "Ciumești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Acâş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Agriş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Andrid",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Apa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beltiug",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bixad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdand",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Botiz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârsău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bătarci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cehal",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Certeze",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciumeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Craidorolţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crucişor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Culciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãlineşti-Oaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãmin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cămărzana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căpleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Căuaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Doba",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorolţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Foieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gherţa Mică",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Halmeu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hodod",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Homoroade",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lazuri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Medieşu Aurit",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Micula",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moftinu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Odoreu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oraşu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Petreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pir",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pişcolt",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pomi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Porumbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pãuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racşa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sanislău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Santău",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Socond",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Supur",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tarna Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Terebeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tiream",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turulung",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Târşolţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urziceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Vinului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vama",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vetiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viile Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Craidorolţ",
                "countryCode": "RO"
            },
            {
                "name": "Crucişor",
                "countryCode": "RO"
            },
            {
                "name": "Culciu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Călineşti-Oaş",
                "countryCode": "RO"
            },
            {
                "name": "Cămin",
                "countryCode": "RO"
            },
            {
                "name": "Cămărzana",
                "countryCode": "RO"
            },
            {
                "name": "Căpleni",
                "countryCode": "RO"
            },
            {
                "name": "Cărășeu",
                "countryCode": "RO"
            },
            {
                "name": "Căuaş",
                "countryCode": "RO"
            },
            {
                "name": "Dara",
                "countryCode": "RO"
            },
            {
                "name": "Decebal",
                "countryCode": "RO"
            },
            {
                "name": "Doba",
                "countryCode": "RO"
            },
            {
                "name": "Dobra",
                "countryCode": "RO"
            },
            {
                "name": "Domănești",
                "countryCode": "RO"
            },
            {
                "name": "Dorolţ",
                "countryCode": "RO"
            },
            {
                "name": "Drăgușeni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Foieni",
                "countryCode": "RO"
            },
            {
                "name": "Gherţa Mică",
                "countryCode": "RO"
            },
            {
                "name": "Gherța Mare",
                "countryCode": "RO"
            },
            {
                "name": "Halmeu",
                "countryCode": "RO"
            },
            {
                "name": "Hodod",
                "countryCode": "RO"
            },
            {
                "name": "Homorodu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Huta Certeze",
                "countryCode": "RO"
            },
            {
                "name": "Iojib",
                "countryCode": "RO"
            },
            {
                "name": "Lazuri",
                "countryCode": "RO"
            },
            {
                "name": "Livada",
                "countryCode": "RO"
            },
            {
                "name": "Lucăceni",
                "countryCode": "RO"
            },
            {
                "name": "Medieşu Aurit",
                "countryCode": "RO"
            },
            {
                "name": "Micula",
                "countryCode": "RO"
            },
            {
                "name": "Moftinu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Moftinu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Moișeni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Carei",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Mădăras",
                "countryCode": "RO"
            },
            {
                "name": "Negreşti-Oaş",
                "countryCode": "RO"
            },
            {
                "name": "Oar",
                "countryCode": "RO"
            },
            {
                "name": "Odoreu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Negreşti-Oaş",
                "countryCode": "RO"
            },
            {
                "name": "Oraşu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Ardud",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Livada",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Tăşnad",
                "countryCode": "RO"
            },
            {
                "name": "Petreşti",
                "countryCode": "RO"
            },
            {
                "name": "Pir",
                "countryCode": "RO"
            },
            {
                "name": "Pişcolt",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Codrului",
                "countryCode": "RO"
            },
            {
                "name": "Pomi",
                "countryCode": "RO"
            },
            {
                "name": "Porumbești",
                "countryCode": "RO"
            },
            {
                "name": "Potău",
                "countryCode": "RO"
            },
            {
                "name": "Păuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Racşa",
                "countryCode": "RO"
            },
            {
                "name": "Sanislău",
                "countryCode": "RO"
            },
            {
                "name": "Santău",
                "countryCode": "RO"
            },
            {
                "name": "Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Socond",
                "countryCode": "RO"
            },
            {
                "name": "Stâna",
                "countryCode": "RO"
            },
            {
                "name": "Supuru de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Săcăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Sărăuad",
                "countryCode": "RO"
            },
            {
                "name": "Sătmărel",
                "countryCode": "RO"
            },
            {
                "name": "Săuca",
                "countryCode": "RO"
            },
            {
                "name": "Tarna Mare",
                "countryCode": "RO"
            },
            {
                "name": "Terebeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tiream",
                "countryCode": "RO"
            },
            {
                "name": "Trip",
                "countryCode": "RO"
            },
            {
                "name": "Tur",
                "countryCode": "RO"
            },
            {
                "name": "Turulung",
                "countryCode": "RO"
            },
            {
                "name": "Turţ",
                "countryCode": "RO"
            },
            {
                "name": "Târşolţ",
                "countryCode": "RO"
            },
            {
                "name": "Târşolţel",
                "countryCode": "RO"
            },
            {
                "name": "Tăşnad",
                "countryCode": "RO"
            },
            {
                "name": "Urziceni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Seacă",
                "countryCode": "RO"
            },
            {
                "name": "Valea Vinului",
                "countryCode": "RO"
            },
            {
                "name": "Vama",
                "countryCode": "RO"
            },
            {
                "name": "Vetiş",
                "countryCode": "RO"
            },
            {
                "name": "Viile Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Adâncata",
                "countryCode": "RO"
            },
            {
                "name": "Arbore",
                "countryCode": "RO"
            },
            {
                "name": "Argel",
                "countryCode": "RO"
            },
            {
                "name": "Arghira",
                "countryCode": "RO"
            },
            {
                "name": "Baia",
                "countryCode": "RO"
            },
            {
                "name": "Basarabi",
                "countryCode": "RO"
            },
            {
                "name": "Berchișești",
                "countryCode": "RO"
            },
            {
                "name": "Bilca",
                "countryCode": "RO"
            },
            {
                "name": "Bivolărie",
                "countryCode": "RO"
            },
            {
                "name": "Bogata",
                "countryCode": "RO"
            },
            {
                "name": "Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Boroaia",
                "countryCode": "RO"
            },
            {
                "name": "Bosanci",
                "countryCode": "RO"
            },
            {
                "name": "Botoşana",
                "countryCode": "RO"
            },
            {
                "name": "Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Brodina",
                "countryCode": "RO"
            },
            {
                "name": "Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Brăiești",
                "countryCode": "RO"
            },
            {
                "name": "Bucșoaia",
                "countryCode": "RO"
            },
            {
                "name": "Budeni",
                "countryCode": "RO"
            },
            {
                "name": "Bunești",
                "countryCode": "RO"
            },
            {
                "name": "Burla",
                "countryCode": "RO"
            },
            {
                "name": "Bursuceni",
                "countryCode": "RO"
            },
            {
                "name": "Bădeuți",
                "countryCode": "RO"
            },
            {
                "name": "Băișești",
                "countryCode": "RO"
            },
            {
                "name": "Bălcăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Bălăceana",
                "countryCode": "RO"
            },
            {
                "name": "Bănești",
                "countryCode": "RO"
            },
            {
                "name": "Cacica",
                "countryCode": "RO"
            },
            {
                "name": "Cajvana",
                "countryCode": "RO"
            },
            {
                "name": "Calafindeşti",
                "countryCode": "RO"
            },
            {
                "name": "Capu Codrului",
                "countryCode": "RO"
            },
            {
                "name": "Capu Câmpului",
                "countryCode": "RO"
            },
            {
                "name": "Ciocănești",
                "countryCode": "RO"
            },
            {
                "name": "Ciprian Porumbescu",
                "countryCode": "RO"
            },
            {
                "name": "Clit",
                "countryCode": "RO"
            },
            {
                "name": "Colacu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Adâncata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arbore",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berchişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bilca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boroaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bosanci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Botoşana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Breaza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brodina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Burla",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălcăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălăceana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cacica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Calafindeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Capu Câmpului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciprian Porumbescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cornu Luncii",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crucea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârlibaba",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dolheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorna Cândrenilor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorna-Arini",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drãgoieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Forăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumosu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frătăuţii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frătăuţii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fundu Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântâna Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grămeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grăniceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gălăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horodnic De Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horodnic De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horodniceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hârtop",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hănţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iacobeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iaslovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ilişeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ipoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele Sucevei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Marginea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mitocu Dragomirnei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moldova Suliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moldoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Muşeniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mânăstirea Humorului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mălini",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ostra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Panaci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Stampei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poieni-Solca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pojorâta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Preuteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Putna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păltinoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Părteştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pătrăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Râşca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rădăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sadova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scheia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siminicea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slatina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Straja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stroieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stulpicani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suceviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Udeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ulma",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vadu Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vama",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vatra Moldoviţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vereşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vicovu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voitinel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Volovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zamostea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zvoriştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şaru Dornei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şerbăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Comănești",
                "countryCode": "RO"
            },
            {
                "name": "Cornu Luncii",
                "countryCode": "RO"
            },
            {
                "name": "Corocăiești",
                "countryCode": "RO"
            },
            {
                "name": "Costâna",
                "countryCode": "RO"
            },
            {
                "name": "Coșna",
                "countryCode": "RO"
            },
            {
                "name": "Crucea",
                "countryCode": "RO"
            },
            {
                "name": "Câmpulung Moldovenesc",
                "countryCode": "RO"
            },
            {
                "name": "Cârlibaba",
                "countryCode": "RO"
            },
            {
                "name": "Călinești",
                "countryCode": "RO"
            },
            {
                "name": "Demacușa",
                "countryCode": "RO"
            },
            {
                "name": "Dolhasca",
                "countryCode": "RO"
            },
            {
                "name": "Dolheștii Mici",
                "countryCode": "RO"
            },
            {
                "name": "Dolheștii-Mari",
                "countryCode": "RO"
            },
            {
                "name": "Dorna Cândrenilor",
                "countryCode": "RO"
            },
            {
                "name": "Dorna-Arini",
                "countryCode": "RO"
            },
            {
                "name": "Dorneşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoiești",
                "countryCode": "RO"
            },
            {
                "name": "Drăguşeni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăvița",
                "countryCode": "RO"
            },
            {
                "name": "Dărmăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Falcău",
                "countryCode": "RO"
            },
            {
                "name": "Forăşti",
                "countryCode": "RO"
            },
            {
                "name": "Frasin",
                "countryCode": "RO"
            },
            {
                "name": "Frumosu",
                "countryCode": "RO"
            },
            {
                "name": "Frătăuţii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Frătăuţii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Fundu Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Fântâna Mare",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Fălticeni",
                "countryCode": "RO"
            },
            {
                "name": "Grămeşti",
                "countryCode": "RO"
            },
            {
                "name": "Grăniceşti",
                "countryCode": "RO"
            },
            {
                "name": "Gulia",
                "countryCode": "RO"
            },
            {
                "name": "Gura Humorului",
                "countryCode": "RO"
            },
            {
                "name": "Gura Putnei",
                "countryCode": "RO"
            },
            {
                "name": "Găinești",
                "countryCode": "RO"
            },
            {
                "name": "Gălăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Herla",
                "countryCode": "RO"
            },
            {
                "name": "Horodnic de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Horodnic de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Horodniceni",
                "countryCode": "RO"
            },
            {
                "name": "Humoreni",
                "countryCode": "RO"
            },
            {
                "name": "Hurjuieni",
                "countryCode": "RO"
            },
            {
                "name": "Hârtop",
                "countryCode": "RO"
            },
            {
                "name": "Hănțești",
                "countryCode": "RO"
            },
            {
                "name": "Iacobeni",
                "countryCode": "RO"
            },
            {
                "name": "Iaslovăț",
                "countryCode": "RO"
            },
            {
                "name": "Ilișești",
                "countryCode": "RO"
            },
            {
                "name": "Ipoteşti",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele Sucevei",
                "countryCode": "RO"
            },
            {
                "name": "Liteni",
                "countryCode": "RO"
            },
            {
                "name": "Lămășeni",
                "countryCode": "RO"
            },
            {
                "name": "Marginea",
                "countryCode": "RO"
            },
            {
                "name": "Mihoveni",
                "countryCode": "RO"
            },
            {
                "name": "Mihăiești",
                "countryCode": "RO"
            },
            {
                "name": "Milişăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Mironu",
                "countryCode": "RO"
            },
            {
                "name": "Mitocu Dragomirnei",
                "countryCode": "RO"
            },
            {
                "name": "Moara Carp",
                "countryCode": "RO"
            },
            {
                "name": "Moara Nica",
                "countryCode": "RO"
            },
            {
                "name": "Moldova Suliţa",
                "countryCode": "RO"
            },
            {
                "name": "Moldoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Câmpulung Moldovenesc",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Fãlticeni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Rãdãuţi",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Suceava",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Vatra Dornei",
                "countryCode": "RO"
            },
            {
                "name": "Muşeniţa",
                "countryCode": "RO"
            },
            {
                "name": "Mălini",
                "countryCode": "RO"
            },
            {
                "name": "Mănăstirea Humorului",
                "countryCode": "RO"
            },
            {
                "name": "Măriței",
                "countryCode": "RO"
            },
            {
                "name": "Măzănăești",
                "countryCode": "RO"
            },
            {
                "name": "Neagra Șarului",
                "countryCode": "RO"
            },
            {
                "name": "Negostina",
                "countryCode": "RO"
            },
            {
                "name": "Negrileasa",
                "countryCode": "RO"
            },
            {
                "name": "Oniceni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cajvana",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Dolhasca",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Frasin",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Gura Humorului",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Liteni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Milişãuţi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Salcea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Siret",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Solca",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Vicovu De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ostra",
                "countryCode": "RO"
            },
            {
                "name": "Panaci",
                "countryCode": "RO"
            },
            {
                "name": "Plopeni",
                "countryCode": "RO"
            },
            {
                "name": "Plutonița",
                "countryCode": "RO"
            },
            {
                "name": "Plăvălari",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Mărului",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Stampei",
                "countryCode": "RO"
            },
            {
                "name": "Poieni-Solca",
                "countryCode": "RO"
            },
            {
                "name": "Pojorâta",
                "countryCode": "RO"
            },
            {
                "name": "Prelipca",
                "countryCode": "RO"
            },
            {
                "name": "Preuteşti",
                "countryCode": "RO"
            },
            {
                "name": "Probota",
                "countryCode": "RO"
            },
            {
                "name": "Putna",
                "countryCode": "RO"
            },
            {
                "name": "Pâraie",
                "countryCode": "RO"
            },
            {
                "name": "Păltinoasa",
                "countryCode": "RO"
            },
            {
                "name": "Părhăuți",
                "countryCode": "RO"
            },
            {
                "name": "Părteştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Pătrăuţi",
                "countryCode": "RO"
            },
            {
                "name": "Rotunda",
                "countryCode": "RO"
            },
            {
                "name": "Roșcani",
                "countryCode": "RO"
            },
            {
                "name": "Râşca",
                "countryCode": "RO"
            },
            {
                "name": "Rădăuți",
                "countryCode": "RO"
            },
            {
                "name": "Rădăşeni",
                "countryCode": "RO"
            },
            {
                "name": "Sadova",
                "countryCode": "RO"
            },
            {
                "name": "Salcea",
                "countryCode": "RO"
            },
            {
                "name": "Satu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Scheia",
                "countryCode": "RO"
            },
            {
                "name": "Siminicea",
                "countryCode": "RO"
            },
            {
                "name": "Siret",
                "countryCode": "RO"
            },
            {
                "name": "Slatina",
                "countryCode": "RO"
            },
            {
                "name": "Solca",
                "countryCode": "RO"
            },
            {
                "name": "Soloneț",
                "countryCode": "RO"
            },
            {
                "name": "Stamate",
                "countryCode": "RO"
            },
            {
                "name": "Straja",
                "countryCode": "RO"
            },
            {
                "name": "Stroiești",
                "countryCode": "RO"
            },
            {
                "name": "Strâmtura",
                "countryCode": "RO"
            },
            {
                "name": "Stulpicani",
                "countryCode": "RO"
            },
            {
                "name": "Suceava",
                "countryCode": "RO"
            },
            {
                "name": "Suceviţa",
                "countryCode": "RO"
            },
            {
                "name": "Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Udeşti",
                "countryCode": "RO"
            },
            {
                "name": "Ulma",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Valea Moldovei",
                "countryCode": "RO"
            },
            {
                "name": "Vama",
                "countryCode": "RO"
            },
            {
                "name": "Vatra Dornei",
                "countryCode": "RO"
            },
            {
                "name": "Vatra Moldoviţei",
                "countryCode": "RO"
            },
            {
                "name": "Vereşti",
                "countryCode": "RO"
            },
            {
                "name": "Vicovu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Vicovu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Voievodeasa",
                "countryCode": "RO"
            },
            {
                "name": "Voitinel",
                "countryCode": "RO"
            },
            {
                "name": "Volovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Văratec",
                "countryCode": "RO"
            },
            {
                "name": "Zamostea",
                "countryCode": "RO"
            },
            {
                "name": "Zvoriştea",
                "countryCode": "RO"
            },
            {
                "name": "Şaru Dornei",
                "countryCode": "RO"
            },
            {
                "name": "Șerbănești",
                "countryCode": "RO"
            },
            {
                "name": "Șerbăuți",
                "countryCode": "RO"
            },
            {
                "name": "Țibeni",
                "countryCode": "RO"
            },
            {
                "name": "Agighiol",
                "countryCode": "RO"
            },
            {
                "name": "Babadag",
                "countryCode": "RO"
            },
            {
                "name": "Baia",
                "countryCode": "RO"
            },
            {
                "name": "Beidaud",
                "countryCode": "RO"
            },
            {
                "name": "Beștepe",
                "countryCode": "RO"
            },
            {
                "name": "C.A. Rosetti",
                "countryCode": "RO"
            },
            {
                "name": "Carcaliu",
                "countryCode": "RO"
            },
            {
                "name": "Casimcea",
                "countryCode": "RO"
            },
            {
                "name": "Cataloi",
                "countryCode": "RO"
            },
            {
                "name": "Ceamurlia de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Ceamurlia de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Ceatalchioi",
                "countryCode": "RO"
            },
            {
                "name": "Cerna",
                "countryCode": "RO"
            },
            {
                "name": "Chilia Veche",
                "countryCode": "RO"
            },
            {
                "name": "Ciucurova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Baia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beidaud",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beştepe",
                "countryCode": "RO"
            },
            {
                "name": "Comuna C.A. Rosetti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Carcaliu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Casimcea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceamurlia de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ceatalchioi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cerna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chilia Veche",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciucurova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crişan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dorobanţu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frecăţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Greci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grindu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hamcearca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Horia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna I.C.Brãtianu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jijila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jurilovca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Luncaviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mahmudia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Maliuc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Murighiol",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nalbant",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Niculiţel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nufăru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ostrov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pardina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peceneaga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sarichioi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slava Cercheză",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Smârdan",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Somova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stejaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topolog",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Turcoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Nucarilor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Teilor",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Văcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Crişan",
                "countryCode": "RO"
            },
            {
                "name": "Dorobanţu",
                "countryCode": "RO"
            },
            {
                "name": "Dăeni",
                "countryCode": "RO"
            },
            {
                "name": "Enisala",
                "countryCode": "RO"
            },
            {
                "name": "Frecăţei",
                "countryCode": "RO"
            },
            {
                "name": "Garvăn",
                "countryCode": "RO"
            },
            {
                "name": "Greci",
                "countryCode": "RO"
            },
            {
                "name": "Grindu",
                "countryCode": "RO"
            },
            {
                "name": "Hamcearca",
                "countryCode": "RO"
            },
            {
                "name": "Horia",
                "countryCode": "RO"
            },
            {
                "name": "I. C. Brătianu",
                "countryCode": "RO"
            },
            {
                "name": "Iazurile",
                "countryCode": "RO"
            },
            {
                "name": "Isaccea",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Jijila",
                "countryCode": "RO"
            },
            {
                "name": "Jurilovca",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Luncaviţa",
                "countryCode": "RO"
            },
            {
                "name": "Mahmudia",
                "countryCode": "RO"
            },
            {
                "name": "Malcoci",
                "countryCode": "RO"
            },
            {
                "name": "Maliuc",
                "countryCode": "RO"
            },
            {
                "name": "Mihai Bravu",
                "countryCode": "RO"
            },
            {
                "name": "Mihail Kogălniceanu",
                "countryCode": "RO"
            },
            {
                "name": "Mineri",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Tulcea",
                "countryCode": "RO"
            },
            {
                "name": "Murighiol",
                "countryCode": "RO"
            },
            {
                "name": "Măcin",
                "countryCode": "RO"
            },
            {
                "name": "Nalbant",
                "countryCode": "RO"
            },
            {
                "name": "Nicolae Bălcescu",
                "countryCode": "RO"
            },
            {
                "name": "Niculiţel",
                "countryCode": "RO"
            },
            {
                "name": "Nufăru",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Babadag",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Isaccea",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Mãcin",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sulina",
                "countryCode": "RO"
            },
            {
                "name": "Ostrov",
                "countryCode": "RO"
            },
            {
                "name": "Pardina",
                "countryCode": "RO"
            },
            {
                "name": "Peceneaga",
                "countryCode": "RO"
            },
            {
                "name": "Rachelu",
                "countryCode": "RO"
            },
            {
                "name": "Sarichioi",
                "countryCode": "RO"
            },
            {
                "name": "Sfântu Gheorghe",
                "countryCode": "RO"
            },
            {
                "name": "Slava Cercheză",
                "countryCode": "RO"
            },
            {
                "name": "Slava Rusă",
                "countryCode": "RO"
            },
            {
                "name": "Smârdan",
                "countryCode": "RO"
            },
            {
                "name": "Somova",
                "countryCode": "RO"
            },
            {
                "name": "Stejaru",
                "countryCode": "RO"
            },
            {
                "name": "Sulina",
                "countryCode": "RO"
            },
            {
                "name": "Sălcioara",
                "countryCode": "RO"
            },
            {
                "name": "Topolog",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Tulcea",
                "countryCode": "RO"
            },
            {
                "name": "Turcoaia",
                "countryCode": "RO"
            },
            {
                "name": "Turda",
                "countryCode": "RO"
            },
            {
                "name": "Valea Nucarilor",
                "countryCode": "RO"
            },
            {
                "name": "Valea Teilor",
                "countryCode": "RO"
            },
            {
                "name": "Văcăreni",
                "countryCode": "RO"
            },
            {
                "name": "Zebil",
                "countryCode": "RO"
            },
            {
                "name": "Bacova",
                "countryCode": "RO"
            },
            {
                "name": "Balinţ",
                "countryCode": "RO"
            },
            {
                "name": "Banloc",
                "countryCode": "RO"
            },
            {
                "name": "Bara",
                "countryCode": "RO"
            },
            {
                "name": "Beba Veche",
                "countryCode": "RO"
            },
            {
                "name": "Becicherecu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Belinţ",
                "countryCode": "RO"
            },
            {
                "name": "Beregsău Mare",
                "countryCode": "RO"
            },
            {
                "name": "Bethausen",
                "countryCode": "RO"
            },
            {
                "name": "Biled",
                "countryCode": "RO"
            },
            {
                "name": "Birda",
                "countryCode": "RO"
            },
            {
                "name": "Bogda",
                "countryCode": "RO"
            },
            {
                "name": "Boldur",
                "countryCode": "RO"
            },
            {
                "name": "Brestovăț",
                "countryCode": "RO"
            },
            {
                "name": "Bucovăț",
                "countryCode": "RO"
            },
            {
                "name": "Bulgăruș",
                "countryCode": "RO"
            },
            {
                "name": "Buziaş",
                "countryCode": "RO"
            },
            {
                "name": "Bârna",
                "countryCode": "RO"
            },
            {
                "name": "Carani",
                "countryCode": "RO"
            },
            {
                "name": "Cenad",
                "countryCode": "RO"
            },
            {
                "name": "Cenei",
                "countryCode": "RO"
            },
            {
                "name": "Checea",
                "countryCode": "RO"
            },
            {
                "name": "Chevereşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Chișoda",
                "countryCode": "RO"
            },
            {
                "name": "Ciacova",
                "countryCode": "RO"
            },
            {
                "name": "Colonia Fabricii",
                "countryCode": "RO"
            },
            {
                "name": "Comloşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balinţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Banloc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beba Veche",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Becicherecu Mic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Belinţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bethausen",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Biled",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Birda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boldur",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brestovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bucovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârna",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cenad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cenei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Checea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chevereşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Comloşu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coşteiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Criciova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Curtea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cărpiniş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Darova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Denta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dudeştii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dudeştii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fibiş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Foeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fârdea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gavojdia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghilad",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghiroda",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghizela",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giarmata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giera",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giroc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Giulvăz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gottlob",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iecea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jamu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jebel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lenauheim",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Liebling",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lovrin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Margina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Maşloc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moraviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moşniţa Nouã",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mănăştiur",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Niţchidorf",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nădrag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ohaba Lungă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orţişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Otelec",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Parţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peciu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Periam",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pesac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pişchia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Remetea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sacoşu Turcesc",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Saravale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Satchinez",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Secaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânandrei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânmihaiu Român",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sânpetru Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săcălaz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Teremia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomnatic",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Topolovãţu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tormac",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian Vuia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Uivar",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Variaş",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Victor Vlad Delamarina",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voiteg",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vălcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şag",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şandra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştiuca",
                "countryCode": "RO"
            },
            {
                "name": "Coşteiu",
                "countryCode": "RO"
            },
            {
                "name": "Criciova",
                "countryCode": "RO"
            },
            {
                "name": "Curtea",
                "countryCode": "RO"
            },
            {
                "name": "Cărpiniş",
                "countryCode": "RO"
            },
            {
                "name": "Darova",
                "countryCode": "RO"
            },
            {
                "name": "Darova Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Denta",
                "countryCode": "RO"
            },
            {
                "name": "Deta",
                "countryCode": "RO"
            },
            {
                "name": "Dudeştii Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Dudeștii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrava",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăviţa",
                "countryCode": "RO"
            },
            {
                "name": "Fibiș",
                "countryCode": "RO"
            },
            {
                "name": "Foeni",
                "countryCode": "RO"
            },
            {
                "name": "Fârdea",
                "countryCode": "RO"
            },
            {
                "name": "Făget",
                "countryCode": "RO"
            },
            {
                "name": "Gavojdia",
                "countryCode": "RO"
            },
            {
                "name": "Gelu",
                "countryCode": "RO"
            },
            {
                "name": "Ghilad",
                "countryCode": "RO"
            },
            {
                "name": "Ghiroda",
                "countryCode": "RO"
            },
            {
                "name": "Ghizela",
                "countryCode": "RO"
            },
            {
                "name": "Giarmata",
                "countryCode": "RO"
            },
            {
                "name": "Giarmata-Vii",
                "countryCode": "RO"
            },
            {
                "name": "Giera",
                "countryCode": "RO"
            },
            {
                "name": "Giroc",
                "countryCode": "RO"
            },
            {
                "name": "Giulvăz",
                "countryCode": "RO"
            },
            {
                "name": "Gottlob",
                "countryCode": "RO"
            },
            {
                "name": "Grabăț",
                "countryCode": "RO"
            },
            {
                "name": "Gătaia",
                "countryCode": "RO"
            },
            {
                "name": "Hodoni",
                "countryCode": "RO"
            },
            {
                "name": "Iecea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Iecea Mică",
                "countryCode": "RO"
            },
            {
                "name": "Igriș",
                "countryCode": "RO"
            },
            {
                "name": "Izvin",
                "countryCode": "RO"
            },
            {
                "name": "Jamu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Jebel",
                "countryCode": "RO"
            },
            {
                "name": "Jimbolia",
                "countryCode": "RO"
            },
            {
                "name": "Lenauheim",
                "countryCode": "RO"
            },
            {
                "name": "Liebling",
                "countryCode": "RO"
            },
            {
                "name": "Livezile",
                "countryCode": "RO"
            },
            {
                "name": "Lovrin",
                "countryCode": "RO"
            },
            {
                "name": "Lugoj",
                "countryCode": "RO"
            },
            {
                "name": "Margina",
                "countryCode": "RO"
            },
            {
                "name": "Maşloc",
                "countryCode": "RO"
            },
            {
                "name": "Moraviţa",
                "countryCode": "RO"
            },
            {
                "name": "Moşniţa Nouă",
                "countryCode": "RO"
            },
            {
                "name": "Moșnița Veche",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Lugoj",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Timişoara",
                "countryCode": "RO"
            },
            {
                "name": "Mănăştiur",
                "countryCode": "RO"
            },
            {
                "name": "Nerău",
                "countryCode": "RO"
            },
            {
                "name": "Niţchidorf",
                "countryCode": "RO"
            },
            {
                "name": "Nădrag",
                "countryCode": "RO"
            },
            {
                "name": "Ohaba Lungă",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Buziaş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ciacova",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Deta",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Fãget",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Gãtaia",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Jimbolia",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Recaş",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Sânnicolau Mare",
                "countryCode": "RO"
            },
            {
                "name": "Orţişoara",
                "countryCode": "RO"
            },
            {
                "name": "Otelec",
                "countryCode": "RO"
            },
            {
                "name": "Parța",
                "countryCode": "RO"
            },
            {
                "name": "Peciu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Periam",
                "countryCode": "RO"
            },
            {
                "name": "Pesac",
                "countryCode": "RO"
            },
            {
                "name": "Pietroasa",
                "countryCode": "RO"
            },
            {
                "name": "Pişchia",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Recaş",
                "countryCode": "RO"
            },
            {
                "name": "Remetea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Răchita",
                "countryCode": "RO"
            },
            {
                "name": "Sacoşu Turcesc",
                "countryCode": "RO"
            },
            {
                "name": "Sacoșu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Saravale",
                "countryCode": "RO"
            },
            {
                "name": "Satchinez",
                "countryCode": "RO"
            },
            {
                "name": "Sculia",
                "countryCode": "RO"
            },
            {
                "name": "Secaş",
                "countryCode": "RO"
            },
            {
                "name": "Sânandrei",
                "countryCode": "RO"
            },
            {
                "name": "Sânmihaiu Român",
                "countryCode": "RO"
            },
            {
                "name": "Sânnicolau Mare",
                "countryCode": "RO"
            },
            {
                "name": "Sânpetru Mare",
                "countryCode": "RO"
            },
            {
                "name": "Săcălaz",
                "countryCode": "RO"
            },
            {
                "name": "Teremia Mare",
                "countryCode": "RO"
            },
            {
                "name": "Timişoara",
                "countryCode": "RO"
            },
            {
                "name": "Tomeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tomnatic",
                "countryCode": "RO"
            },
            {
                "name": "Topolovăţu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Tormac",
                "countryCode": "RO"
            },
            {
                "name": "Traian Vuia",
                "countryCode": "RO"
            },
            {
                "name": "Uivar",
                "countryCode": "RO"
            },
            {
                "name": "Urseni",
                "countryCode": "RO"
            },
            {
                "name": "Utvin",
                "countryCode": "RO"
            },
            {
                "name": "Variaş",
                "countryCode": "RO"
            },
            {
                "name": "Victor Vlad Delamarina",
                "countryCode": "RO"
            },
            {
                "name": "Voiteg",
                "countryCode": "RO"
            },
            {
                "name": "Vălcani",
                "countryCode": "RO"
            },
            {
                "name": "Şag",
                "countryCode": "RO"
            },
            {
                "name": "Şandra",
                "countryCode": "RO"
            },
            {
                "name": "Ştiuca",
                "countryCode": "RO"
            },
            {
                "name": "Alexandria",
                "countryCode": "RO"
            },
            {
                "name": "Baciu",
                "countryCode": "RO"
            },
            {
                "name": "Balaci",
                "countryCode": "RO"
            },
            {
                "name": "Beciu",
                "countryCode": "RO"
            },
            {
                "name": "Beiu",
                "countryCode": "RO"
            },
            {
                "name": "Beuca",
                "countryCode": "RO"
            },
            {
                "name": "Blejeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bogdana",
                "countryCode": "RO"
            },
            {
                "name": "Botoroaga",
                "countryCode": "RO"
            },
            {
                "name": "Bragadiru",
                "countryCode": "RO"
            },
            {
                "name": "Brânceni",
                "countryCode": "RO"
            },
            {
                "name": "Brâncoveanca",
                "countryCode": "RO"
            },
            {
                "name": "Bujoreni",
                "countryCode": "RO"
            },
            {
                "name": "Bujoru",
                "countryCode": "RO"
            },
            {
                "name": "Buzescu",
                "countryCode": "RO"
            },
            {
                "name": "Bâscoveni",
                "countryCode": "RO"
            },
            {
                "name": "Băbăiţa",
                "countryCode": "RO"
            },
            {
                "name": "Băcălești",
                "countryCode": "RO"
            },
            {
                "name": "Băduleasa",
                "countryCode": "RO"
            },
            {
                "name": "Băneasa",
                "countryCode": "RO"
            },
            {
                "name": "Calomfirești",
                "countryCode": "RO"
            },
            {
                "name": "Cernetu",
                "countryCode": "RO"
            },
            {
                "name": "Cervenia",
                "countryCode": "RO"
            },
            {
                "name": "Ciuani",
                "countryCode": "RO"
            },
            {
                "name": "Ciuperceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Balaci",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Beuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blejeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Botoroaga",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bragadiru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Brânceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bujoreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bujoru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buzescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băbăiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cervenia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciolăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciuperceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Conţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cosmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crevenicu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crângeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Crângu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãlmãţuiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cãlmãţuiu De Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dideşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dobroteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dracea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drãcşenei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti de Vede",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgăneşti-Vlaşca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frăsinet",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Furculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gratia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gălăţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Islaz",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lisa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Liţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mavrodin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mereni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mârzãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măldăeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nanov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Necşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nenciuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Năsturelu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orbeasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Peretu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Piatra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietroşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plopii Slăviţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Plosca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poroschia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Purani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Putineiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rãdoieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răsmireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Saelele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scrioaştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scurtu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Seaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Segarcea Vale",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sfinţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Siliștea Gumești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia-Mândra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Smârdioasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stejaru",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Storobăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suhaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sârbeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Săceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Talpa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Traian",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Trivalea-Moşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Troianul",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătărăştii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătărăştii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Uda-Clocociov",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vităneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârtoape",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zâmbreasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţigăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Conţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cosmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Crevenicu",
                "countryCode": "RO"
            },
            {
                "name": "Crângeni",
                "countryCode": "RO"
            },
            {
                "name": "Crângu",
                "countryCode": "RO"
            },
            {
                "name": "Cucueți",
                "countryCode": "RO"
            },
            {
                "name": "Călineşti",
                "countryCode": "RO"
            },
            {
                "name": "Călmăţuiu",
                "countryCode": "RO"
            },
            {
                "name": "Călmățuiu de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Deparați",
                "countryCode": "RO"
            },
            {
                "name": "Didești",
                "countryCode": "RO"
            },
            {
                "name": "Dobreni",
                "countryCode": "RO"
            },
            {
                "name": "Dobroteşti",
                "countryCode": "RO"
            },
            {
                "name": "Dracea",
                "countryCode": "RO"
            },
            {
                "name": "Drăcşani",
                "countryCode": "RO"
            },
            {
                "name": "Drăcșenei",
                "countryCode": "RO"
            },
            {
                "name": "Drăghinești",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti de Vede",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăneşti-Vlaşca",
                "countryCode": "RO"
            },
            {
                "name": "Dudu",
                "countryCode": "RO"
            },
            {
                "name": "Frumoasa",
                "countryCode": "RO"
            },
            {
                "name": "Frăsinet",
                "countryCode": "RO"
            },
            {
                "name": "Furculești",
                "countryCode": "RO"
            },
            {
                "name": "Fântânele",
                "countryCode": "RO"
            },
            {
                "name": "Gratia",
                "countryCode": "RO"
            },
            {
                "name": "Guruieni",
                "countryCode": "RO"
            },
            {
                "name": "Gălăţeni",
                "countryCode": "RO"
            },
            {
                "name": "Gărăgău",
                "countryCode": "RO"
            },
            {
                "name": "Islaz",
                "countryCode": "RO"
            },
            {
                "name": "Izvoarele",
                "countryCode": "RO"
            },
            {
                "name": "Lisa",
                "countryCode": "RO"
            },
            {
                "name": "Liţa",
                "countryCode": "RO"
            },
            {
                "name": "Lunca",
                "countryCode": "RO"
            },
            {
                "name": "Lăceni",
                "countryCode": "RO"
            },
            {
                "name": "Mavrodin",
                "countryCode": "RO"
            },
            {
                "name": "Merenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Merenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Merișani",
                "countryCode": "RO"
            },
            {
                "name": "Moldoveni",
                "countryCode": "RO"
            },
            {
                "name": "Moșteni",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Alexandria",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Roșiorii de Vede",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Turnu Magurele",
                "countryCode": "RO"
            },
            {
                "name": "Mârzăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Măgura",
                "countryCode": "RO"
            },
            {
                "name": "Măldăeni",
                "countryCode": "RO"
            },
            {
                "name": "Nanov",
                "countryCode": "RO"
            },
            {
                "name": "Necşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Nenciulești",
                "countryCode": "RO"
            },
            {
                "name": "Năsturelu",
                "countryCode": "RO"
            },
            {
                "name": "Năvodari",
                "countryCode": "RO"
            },
            {
                "name": "Olteanca",
                "countryCode": "RO"
            },
            {
                "name": "Olteni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Videle",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Zimnicea",
                "countryCode": "RO"
            },
            {
                "name": "Orbeasca de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Orbeasca de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Peretu",
                "countryCode": "RO"
            },
            {
                "name": "Perii Broșteni",
                "countryCode": "RO"
            },
            {
                "name": "Piatra",
                "countryCode": "RO"
            },
            {
                "name": "Pietroşani",
                "countryCode": "RO"
            },
            {
                "name": "Pleașov",
                "countryCode": "RO"
            },
            {
                "name": "Plopii Slăviţeştí",
                "countryCode": "RO"
            },
            {
                "name": "Plosca",
                "countryCode": "RO"
            },
            {
                "name": "Poeni",
                "countryCode": "RO"
            },
            {
                "name": "Poiana",
                "countryCode": "RO"
            },
            {
                "name": "Poporogi",
                "countryCode": "RO"
            },
            {
                "name": "Poroschia",
                "countryCode": "RO"
            },
            {
                "name": "Prundu",
                "countryCode": "RO"
            },
            {
                "name": "Purani",
                "countryCode": "RO"
            },
            {
                "name": "Putineiu",
                "countryCode": "RO"
            },
            {
                "name": "Roșiorii de Vede",
                "countryCode": "RO"
            },
            {
                "name": "Rădoiești-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Răsmireşti",
                "countryCode": "RO"
            },
            {
                "name": "Saelele",
                "countryCode": "RO"
            },
            {
                "name": "Salcia",
                "countryCode": "RO"
            },
            {
                "name": "Scrioaştea",
                "countryCode": "RO"
            },
            {
                "name": "Scurtu Mare",
                "countryCode": "RO"
            },
            {
                "name": "Seaca",
                "countryCode": "RO"
            },
            {
                "name": "Segarcea Vale",
                "countryCode": "RO"
            },
            {
                "name": "Segarcea-Deal",
                "countryCode": "RO"
            },
            {
                "name": "Sfinţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Siliştea",
                "countryCode": "RO"
            },
            {
                "name": "Siliștea Gumești",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia-Mândra",
                "countryCode": "RO"
            },
            {
                "name": "Smârdioasa",
                "countryCode": "RO"
            },
            {
                "name": "Socetu",
                "countryCode": "RO"
            },
            {
                "name": "Spătărei",
                "countryCode": "RO"
            },
            {
                "name": "Stejaru",
                "countryCode": "RO"
            },
            {
                "name": "Storobăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Suhaia",
                "countryCode": "RO"
            },
            {
                "name": "Sârbeni",
                "countryCode": "RO"
            },
            {
                "name": "Săceni",
                "countryCode": "RO"
            },
            {
                "name": "Talpa-Ogrăzile",
                "countryCode": "RO"
            },
            {
                "name": "Talpa-Trivalea",
                "countryCode": "RO"
            },
            {
                "name": "Tecuci",
                "countryCode": "RO"
            },
            {
                "name": "Teleormanu",
                "countryCode": "RO"
            },
            {
                "name": "Traian",
                "countryCode": "RO"
            },
            {
                "name": "Trivalea-Moşteni",
                "countryCode": "RO"
            },
            {
                "name": "Troianul",
                "countryCode": "RO"
            },
            {
                "name": "Tudor Vladimirescu",
                "countryCode": "RO"
            },
            {
                "name": "Turnu Măgurele",
                "countryCode": "RO"
            },
            {
                "name": "Târnava",
                "countryCode": "RO"
            },
            {
                "name": "Tătărăștii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Tătărăștii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Uda-Clocociov",
                "countryCode": "RO"
            },
            {
                "name": "Uda-Paciurea",
                "countryCode": "RO"
            },
            {
                "name": "Udupu",
                "countryCode": "RO"
            },
            {
                "name": "Valea Cireșului",
                "countryCode": "RO"
            },
            {
                "name": "Valea Părului",
                "countryCode": "RO"
            },
            {
                "name": "Vedea",
                "countryCode": "RO"
            },
            {
                "name": "Videle",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vităneşti",
                "countryCode": "RO"
            },
            {
                "name": "Vârtoapele de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Vârtoapele de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Zimnicea",
                "countryCode": "RO"
            },
            {
                "name": "Zimnicele",
                "countryCode": "RO"
            },
            {
                "name": "Zâmbreasca",
                "countryCode": "RO"
            },
            {
                "name": "Ţigăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Alunu",
                "countryCode": "RO"
            },
            {
                "name": "Amărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Batârăşti",
                "countryCode": "RO"
            },
            {
                "name": "Berbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Berislăveşti",
                "countryCode": "RO"
            },
            {
                "name": "Bistrița",
                "countryCode": "RO"
            },
            {
                "name": "Bodești",
                "countryCode": "RO"
            },
            {
                "name": "Boişoara",
                "countryCode": "RO"
            },
            {
                "name": "Brezoi",
                "countryCode": "RO"
            },
            {
                "name": "Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bujoreni",
                "countryCode": "RO"
            },
            {
                "name": "Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Băbeni",
                "countryCode": "RO"
            },
            {
                "name": "Băbeni-Oltețu",
                "countryCode": "RO"
            },
            {
                "name": "Băile Govora",
                "countryCode": "RO"
            },
            {
                "name": "Băile Olăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bălceşti",
                "countryCode": "RO"
            },
            {
                "name": "Bărbăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Cernişoara",
                "countryCode": "RO"
            },
            {
                "name": "Cheia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alunu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Amărăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berislăveşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Budeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bujoreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bărbăteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cernişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Creţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câineni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Diculeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drăgoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dănicei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Frânceşti-Mânăstireni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fârtãţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Făureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Galicea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghioroiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Glăvile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Guşoeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Laloşu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Livezi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lungeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăcusteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lăpuşata",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Malaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mateeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Milcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mitrofani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Muereasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măciuca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mădulari-Beica",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măldăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nicolae Bãlcescu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Orleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oteşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perişani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pesceana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pietrari",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Prundeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuşeşti-Măglaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşiile",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Scundu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slătioara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stoileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stroeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sălătrucel",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tetoiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Titeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tomşani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vaideeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vlădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voineasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zătreni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şirineasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şuşani",
                "countryCode": "RO"
            },
            {
                "name": "Copăcelu",
                "countryCode": "RO"
            },
            {
                "name": "Copăceni",
                "countryCode": "RO"
            },
            {
                "name": "Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Coșani",
                "countryCode": "RO"
            },
            {
                "name": "Creţeni",
                "countryCode": "RO"
            },
            {
                "name": "Câinenii Mici",
                "countryCode": "RO"
            },
            {
                "name": "Călimăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Călina",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Aluniș",
                "countryCode": "RO"
            },
            {
                "name": "Dealu Dănicei",
                "countryCode": "RO"
            },
            {
                "name": "Diculești",
                "countryCode": "RO"
            },
            {
                "name": "Dobrușa",
                "countryCode": "RO"
            },
            {
                "name": "Drăgoeşti",
                "countryCode": "RO"
            },
            {
                "name": "Drăgăşani",
                "countryCode": "RO"
            },
            {
                "name": "Dăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Fărtăţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Făureşti",
                "countryCode": "RO"
            },
            {
                "name": "Galicea",
                "countryCode": "RO"
            },
            {
                "name": "Ghioroiu",
                "countryCode": "RO"
            },
            {
                "name": "Glăvile",
                "countryCode": "RO"
            },
            {
                "name": "Goranu",
                "countryCode": "RO"
            },
            {
                "name": "Greblești",
                "countryCode": "RO"
            },
            {
                "name": "Grădiştea",
                "countryCode": "RO"
            },
            {
                "name": "Gura Suhașului",
                "countryCode": "RO"
            },
            {
                "name": "Guşoeni",
                "countryCode": "RO"
            },
            {
                "name": "Horezu",
                "countryCode": "RO"
            },
            {
                "name": "Igoiu",
                "countryCode": "RO"
            },
            {
                "name": "Ioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Jiblea Veche",
                "countryCode": "RO"
            },
            {
                "name": "Laloşu",
                "countryCode": "RO"
            },
            {
                "name": "Livadia",
                "countryCode": "RO"
            },
            {
                "name": "Livezi",
                "countryCode": "RO"
            },
            {
                "name": "Lungeşti",
                "countryCode": "RO"
            },
            {
                "name": "Lăcusteni",
                "countryCode": "RO"
            },
            {
                "name": "Lădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Malaia",
                "countryCode": "RO"
            },
            {
                "name": "Mateeşti",
                "countryCode": "RO"
            },
            {
                "name": "Mihăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Milcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Mitrofani",
                "countryCode": "RO"
            },
            {
                "name": "Mogești",
                "countryCode": "RO"
            },
            {
                "name": "Muereasca",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Drãgãşani",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Râmnicu Vâlcea",
                "countryCode": "RO"
            },
            {
                "name": "Mădulari",
                "countryCode": "RO"
            },
            {
                "name": "Măldăreşti",
                "countryCode": "RO"
            },
            {
                "name": "Mănăilești",
                "countryCode": "RO"
            },
            {
                "name": "Olanu",
                "countryCode": "RO"
            },
            {
                "name": "Olănești",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Berbeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Brezoi",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãbeni",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãile Govora",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãile Olãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Bãlceşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Cãlimãneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Horezu",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Ocnele Mari",
                "countryCode": "RO"
            },
            {
                "name": "Orleşti",
                "countryCode": "RO"
            },
            {
                "name": "Otetelișu",
                "countryCode": "RO"
            },
            {
                "name": "Oteşani",
                "countryCode": "RO"
            },
            {
                "name": "Oveselu",
                "countryCode": "RO"
            },
            {
                "name": "Perişani",
                "countryCode": "RO"
            },
            {
                "name": "Pesceana",
                "countryCode": "RO"
            },
            {
                "name": "Pietrari",
                "countryCode": "RO"
            },
            {
                "name": "Pietrarii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Prajila",
                "countryCode": "RO"
            },
            {
                "name": "Prundeni",
                "countryCode": "RO"
            },
            {
                "name": "Păuşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Păuşeşti-Măglaşi",
                "countryCode": "RO"
            },
            {
                "name": "Racoviţa",
                "countryCode": "RO"
            },
            {
                "name": "Roeşti",
                "countryCode": "RO"
            },
            {
                "name": "Români",
                "countryCode": "RO"
            },
            {
                "name": "Roşiile",
                "countryCode": "RO"
            },
            {
                "name": "Runcu",
                "countryCode": "RO"
            },
            {
                "name": "Râmești",
                "countryCode": "RO"
            },
            {
                "name": "Râmnicu Vâlcea",
                "countryCode": "RO"
            },
            {
                "name": "Scundu",
                "countryCode": "RO"
            },
            {
                "name": "Sineşti",
                "countryCode": "RO"
            },
            {
                "name": "Stoeneşti",
                "countryCode": "RO"
            },
            {
                "name": "Stoileşti",
                "countryCode": "RO"
            },
            {
                "name": "Stolniceni",
                "countryCode": "RO"
            },
            {
                "name": "Stroeşti",
                "countryCode": "RO"
            },
            {
                "name": "Stănești",
                "countryCode": "RO"
            },
            {
                "name": "Sutești",
                "countryCode": "RO"
            },
            {
                "name": "Sălătrucel",
                "countryCode": "RO"
            },
            {
                "name": "Tetoiu",
                "countryCode": "RO"
            },
            {
                "name": "Titești",
                "countryCode": "RO"
            },
            {
                "name": "Tomşani",
                "countryCode": "RO"
            },
            {
                "name": "Turcești",
                "countryCode": "RO"
            },
            {
                "name": "Urși",
                "countryCode": "RO"
            },
            {
                "name": "Vaideeni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Mare",
                "countryCode": "RO"
            },
            {
                "name": "Vlădeşti",
                "countryCode": "RO"
            },
            {
                "name": "Voiceşti",
                "countryCode": "RO"
            },
            {
                "name": "Voineasa",
                "countryCode": "RO"
            },
            {
                "name": "Zlătărei",
                "countryCode": "RO"
            },
            {
                "name": "Zătreni",
                "countryCode": "RO"
            },
            {
                "name": "Şirineasa",
                "countryCode": "RO"
            },
            {
                "name": "Ştefăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Şuşani",
                "countryCode": "RO"
            },
            {
                "name": "Adjud",
                "countryCode": "RO"
            },
            {
                "name": "Adjudu Vechi",
                "countryCode": "RO"
            },
            {
                "name": "Andreiaşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Anghelești",
                "countryCode": "RO"
            },
            {
                "name": "Biliești",
                "countryCode": "RO"
            },
            {
                "name": "Bogheşti",
                "countryCode": "RO"
            },
            {
                "name": "Bogza",
                "countryCode": "RO"
            },
            {
                "name": "Boloteşti",
                "countryCode": "RO"
            },
            {
                "name": "Bordeasca Veche",
                "countryCode": "RO"
            },
            {
                "name": "Bordeşti",
                "countryCode": "RO"
            },
            {
                "name": "Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Budești",
                "countryCode": "RO"
            },
            {
                "name": "Burca",
                "countryCode": "RO"
            },
            {
                "name": "Bârseşti",
                "countryCode": "RO"
            },
            {
                "name": "Băleşti",
                "countryCode": "RO"
            },
            {
                "name": "Ceardac",
                "countryCode": "RO"
            },
            {
                "name": "Chiojdeni",
                "countryCode": "RO"
            },
            {
                "name": "Ciorani",
                "countryCode": "RO"
            },
            {
                "name": "Ciorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Ciușlea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Andreiaşu de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bilieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boloteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bordeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Broşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bârseşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Chiojdeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Corbiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coteşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câmpineanca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Câmpuri",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cârligele",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumitreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fitioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Garoafa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Goleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gologanu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gugeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gura Caliţei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Homocea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jariştea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Jitia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Mera",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Milcovul",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Moviliţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măicăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Negrileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nereju",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Nistoreşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Năneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Năruja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Obrejiţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Paltin",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ploscuţeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poiana Cristei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Popeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pufeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Păuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Reghiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rugineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răcoasa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Răstoaca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Sihlea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia Bradului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Slobozia-Ciorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Soveja",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Spulber",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Străoane",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Suraia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tulnici",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tâmboeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tãtãranu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tănăsoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Urecheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Valea Sării",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vintileasca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vizantea-Livezi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vrâncioaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vulturu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vârteşcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ţifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comună Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Corbiţa",
                "countryCode": "RO"
            },
            {
                "name": "Cornetu",
                "countryCode": "RO"
            },
            {
                "name": "Coteşti",
                "countryCode": "RO"
            },
            {
                "name": "Câmpineanca",
                "countryCode": "RO"
            },
            {
                "name": "Câmpuri",
                "countryCode": "RO"
            },
            {
                "name": "Câmpurile de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Cârligele",
                "countryCode": "RO"
            },
            {
                "name": "Domnești-Târg",
                "countryCode": "RO"
            },
            {
                "name": "Dragosloveni",
                "countryCode": "RO"
            },
            {
                "name": "Dumbrăveni",
                "countryCode": "RO"
            },
            {
                "name": "Dumitrești",
                "countryCode": "RO"
            },
            {
                "name": "Fitioneşti",
                "countryCode": "RO"
            },
            {
                "name": "Focșani",
                "countryCode": "RO"
            },
            {
                "name": "Garoafa",
                "countryCode": "RO"
            },
            {
                "name": "Goleşti",
                "countryCode": "RO"
            },
            {
                "name": "Gologanu",
                "countryCode": "RO"
            },
            {
                "name": "Gugeşti",
                "countryCode": "RO"
            },
            {
                "name": "Gura Caliţei",
                "countryCode": "RO"
            },
            {
                "name": "Găgești",
                "countryCode": "RO"
            },
            {
                "name": "Homocea",
                "countryCode": "RO"
            },
            {
                "name": "Igești",
                "countryCode": "RO"
            },
            {
                "name": "Irești",
                "countryCode": "RO"
            },
            {
                "name": "Jariştea",
                "countryCode": "RO"
            },
            {
                "name": "Jitia",
                "countryCode": "RO"
            },
            {
                "name": "Jorăști",
                "countryCode": "RO"
            },
            {
                "name": "Lespezi",
                "countryCode": "RO"
            },
            {
                "name": "Mera",
                "countryCode": "RO"
            },
            {
                "name": "Mihălceni",
                "countryCode": "RO"
            },
            {
                "name": "Milcovul",
                "countryCode": "RO"
            },
            {
                "name": "Mirceștii Noi",
                "countryCode": "RO"
            },
            {
                "name": "Moviliţa",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul  Adjud",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Focşani",
                "countryCode": "RO"
            },
            {
                "name": "Măicăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Mărășești",
                "countryCode": "RO"
            },
            {
                "name": "Mătăcina",
                "countryCode": "RO"
            },
            {
                "name": "Negrilești",
                "countryCode": "RO"
            },
            {
                "name": "Nereju",
                "countryCode": "RO"
            },
            {
                "name": "Nereju Mic",
                "countryCode": "RO"
            },
            {
                "name": "Nistoreşti",
                "countryCode": "RO"
            },
            {
                "name": "Năneşti",
                "countryCode": "RO"
            },
            {
                "name": "Năruja",
                "countryCode": "RO"
            },
            {
                "name": "Obrejița",
                "countryCode": "RO"
            },
            {
                "name": "Odobeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oleșești",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Mãrãşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Odobeşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Panciu",
                "countryCode": "RO"
            },
            {
                "name": "Paltin",
                "countryCode": "RO"
            },
            {
                "name": "Panciu",
                "countryCode": "RO"
            },
            {
                "name": "Ploscuțeni",
                "countryCode": "RO"
            },
            {
                "name": "Poiana Cristei",
                "countryCode": "RO"
            },
            {
                "name": "Popești",
                "countryCode": "RO"
            },
            {
                "name": "Pufeşti",
                "countryCode": "RO"
            },
            {
                "name": "Păulești",
                "countryCode": "RO"
            },
            {
                "name": "Păuneşti",
                "countryCode": "RO"
            },
            {
                "name": "Reghiu",
                "countryCode": "RO"
            },
            {
                "name": "Rucăreni",
                "countryCode": "RO"
            },
            {
                "name": "Rugineşti",
                "countryCode": "RO"
            },
            {
                "name": "Râmniceni",
                "countryCode": "RO"
            },
            {
                "name": "Răcoasa",
                "countryCode": "RO"
            },
            {
                "name": "Răstoaca",
                "countryCode": "RO"
            },
            {
                "name": "Sihlea",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia Bradului",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia-Ciorăşti",
                "countryCode": "RO"
            },
            {
                "name": "Slobozia-Câmpineanca",
                "countryCode": "RO"
            },
            {
                "name": "Spulber",
                "countryCode": "RO"
            },
            {
                "name": "Străoane",
                "countryCode": "RO"
            },
            {
                "name": "Suraia",
                "countryCode": "RO"
            },
            {
                "name": "Tulnici",
                "countryCode": "RO"
            },
            {
                "name": "Tâmboeşti",
                "countryCode": "RO"
            },
            {
                "name": "Tănăsoaia",
                "countryCode": "RO"
            },
            {
                "name": "Tătăranu",
                "countryCode": "RO"
            },
            {
                "name": "Unirea",
                "countryCode": "RO"
            },
            {
                "name": "Urecheşti",
                "countryCode": "RO"
            },
            {
                "name": "Vadu Roșca",
                "countryCode": "RO"
            },
            {
                "name": "Valea Sării",
                "countryCode": "RO"
            },
            {
                "name": "Vidra",
                "countryCode": "RO"
            },
            {
                "name": "Viișoara",
                "countryCode": "RO"
            },
            {
                "name": "Vintileasca",
                "countryCode": "RO"
            },
            {
                "name": "Vizantea-Mânăstirească",
                "countryCode": "RO"
            },
            {
                "name": "Voetin",
                "countryCode": "RO"
            },
            {
                "name": "Vrâncioaia",
                "countryCode": "RO"
            },
            {
                "name": "Vulcăneasa",
                "countryCode": "RO"
            },
            {
                "name": "Vulturu",
                "countryCode": "RO"
            },
            {
                "name": "Vânători",
                "countryCode": "RO"
            },
            {
                "name": "Vârteşcoiu",
                "countryCode": "RO"
            },
            {
                "name": "Ţifeşti",
                "countryCode": "RO"
            },
            {
                "name": "Șindrilari",
                "countryCode": "RO"
            },
            {
                "name": "Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Alexandru Vlăhuţă",
                "countryCode": "RO"
            },
            {
                "name": "Arsura",
                "countryCode": "RO"
            },
            {
                "name": "Banca",
                "countryCode": "RO"
            },
            {
                "name": "Berezeni",
                "countryCode": "RO"
            },
            {
                "name": "Blăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Bogdana",
                "countryCode": "RO"
            },
            {
                "name": "Bogdănești",
                "countryCode": "RO"
            },
            {
                "name": "Bogdăniţa",
                "countryCode": "RO"
            },
            {
                "name": "Boţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Brodoc",
                "countryCode": "RO"
            },
            {
                "name": "Buda",
                "countryCode": "RO"
            },
            {
                "name": "Buneşti",
                "countryCode": "RO"
            },
            {
                "name": "Bârlad",
                "countryCode": "RO"
            },
            {
                "name": "Bârzești",
                "countryCode": "RO"
            },
            {
                "name": "Băcani",
                "countryCode": "RO"
            },
            {
                "name": "Băceşti",
                "countryCode": "RO"
            },
            {
                "name": "Bădeana",
                "countryCode": "RO"
            },
            {
                "name": "Băile Drânceni",
                "countryCode": "RO"
            },
            {
                "name": "Bălteni",
                "countryCode": "RO"
            },
            {
                "name": "Chircești",
                "countryCode": "RO"
            },
            {
                "name": "Chițoc",
                "countryCode": "RO"
            },
            {
                "name": "Ciocani",
                "countryCode": "RO"
            },
            {
                "name": "Codăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Albeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Alexandru Vlăhuţă",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Arsura",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Banca",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Berezeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Blăgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bogdăniţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Boţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bunești Averești",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Băceşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Bălteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ciocani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Codăeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Coroieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Cozmeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Creţeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Deleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dimitrie Cantemir",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dodeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Drânceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Duda  Epureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dumeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Epureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fereşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fruntişeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Fălciu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ghergheşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Gârceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Găgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Hoceni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ibăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Iveşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ivăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Laza",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lipovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Lunca Banului",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Micleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Muntenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Muntenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Măluşteni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Olteneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Oşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Perieni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pochidia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pogana",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pogoneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Poieneşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Puieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pungeşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Puşcaşi",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rafaila",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Rebricea",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Roşieşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Soleşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Stănileşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tanacu",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tutova",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tăcuta",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Tătărăni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vetrişoaia",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vinderei",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vutcani",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Vãleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zorleni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Zăpodeni",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Comuna Şuletea",
                "countryCode": "RO"
            },
            {
                "name": "Corni-Albești",
                "countryCode": "RO"
            },
            {
                "name": "Coroieşti",
                "countryCode": "RO"
            },
            {
                "name": "Costeşti",
                "countryCode": "RO"
            },
            {
                "name": "Cozmești",
                "countryCode": "RO"
            },
            {
                "name": "Crețești",
                "countryCode": "RO"
            },
            {
                "name": "Cârja",
                "countryCode": "RO"
            },
            {
                "name": "Deleni",
                "countryCode": "RO"
            },
            {
                "name": "Deleşti",
                "countryCode": "RO"
            },
            {
                "name": "Dodești",
                "countryCode": "RO"
            },
            {
                "name": "Dragomireşti",
                "countryCode": "RO"
            },
            {
                "name": "Drânceni",
                "countryCode": "RO"
            },
            {
                "name": "Duda",
                "countryCode": "RO"
            },
            {
                "name": "Dumeşti",
                "countryCode": "RO"
            },
            {
                "name": "Dăneşti",
                "countryCode": "RO"
            },
            {
                "name": "Emil Racoviță",
                "countryCode": "RO"
            },
            {
                "name": "Epureni",
                "countryCode": "RO"
            },
            {
                "name": "Ferești",
                "countryCode": "RO"
            },
            {
                "name": "Fruntișeni",
                "countryCode": "RO"
            },
            {
                "name": "Fâstâci",
                "countryCode": "RO"
            },
            {
                "name": "Fălciu",
                "countryCode": "RO"
            },
            {
                "name": "Ghergheşti",
                "countryCode": "RO"
            },
            {
                "name": "Ghermănești",
                "countryCode": "RO"
            },
            {
                "name": "Griviţa",
                "countryCode": "RO"
            },
            {
                "name": "Gugești",
                "countryCode": "RO"
            },
            {
                "name": "Gârceni",
                "countryCode": "RO"
            },
            {
                "name": "Găgeşti",
                "countryCode": "RO"
            },
            {
                "name": "Hoceni",
                "countryCode": "RO"
            },
            {
                "name": "Hurdugi",
                "countryCode": "RO"
            },
            {
                "name": "Huşi",
                "countryCode": "RO"
            },
            {
                "name": "Hălărești",
                "countryCode": "RO"
            },
            {
                "name": "Iana",
                "countryCode": "RO"
            },
            {
                "name": "Ibănești",
                "countryCode": "RO"
            },
            {
                "name": "Iveşti",
                "countryCode": "RO"
            },
            {
                "name": "Ivănești",
                "countryCode": "RO"
            },
            {
                "name": "Laza",
                "countryCode": "RO"
            },
            {
                "name": "Lipovăţ",
                "countryCode": "RO"
            },
            {
                "name": "Lunca Banului",
                "countryCode": "RO"
            },
            {
                "name": "Micleşti",
                "countryCode": "RO"
            },
            {
                "name": "Moara Domnească",
                "countryCode": "RO"
            },
            {
                "name": "Moara Grecilor",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Bârlad",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Huşi",
                "countryCode": "RO"
            },
            {
                "name": "Municipiul Vaslui",
                "countryCode": "RO"
            },
            {
                "name": "Muntenii de Jos",
                "countryCode": "RO"
            },
            {
                "name": "Muntenii de Sus",
                "countryCode": "RO"
            },
            {
                "name": "Murgeni",
                "countryCode": "RO"
            },
            {
                "name": "Măluşteni",
                "countryCode": "RO"
            },
            {
                "name": "Negreşti",
                "countryCode": "RO"
            },
            {
                "name": "Olteneşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraş Negreşti",
                "countryCode": "RO"
            },
            {
                "name": "Oraș Murgeni",
                "countryCode": "RO"
            },
            {
                "name": "Oşeşti",
                "countryCode": "RO"
            },
            {
                "name": "Perieni",
                "countryCode": "RO"
            },
            {
                "name": "Pochidia",
                "countryCode": "RO"
            },
            {
                "name": "Pogana",
                "countryCode": "RO"
            },
            {
                "name": "Pogonești",
                "countryCode": "RO"
            },
            {
                "name": "Pogănești",
                "countryCode": "RO"
            },
            {
                "name": "Poieneşti",
                "countryCode": "RO"
            },
            {
                "name": "Popeni",
                "countryCode": "RO"
            },
            {
                "name": "Pribești",
                "countryCode": "RO"
            },
            {
                "name": "Puieşti",
                "countryCode": "RO"
            },
            {
                "name": "Pungeşti",
                "countryCode": "RO"
            },
            {
                "name": "Pușcași",
                "countryCode": "RO"
            },
            {
                "name": "Pădureni",
                "countryCode": "RO"
            },
            {
                "name": "Rafaila",
                "countryCode": "RO"
            },
            {
                "name": "Rebricea",
                "countryCode": "RO"
            },
            {
                "name": "Rediu",
                "countryCode": "RO"
            },
            {
                "name": "Roşieşti",
                "countryCode": "RO"
            },
            {
                "name": "Rânzești",
                "countryCode": "RO"
            },
            {
                "name": "Râșești",
                "countryCode": "RO"
            },
            {
                "name": "Satu Nou",
                "countryCode": "RO"
            },
            {
                "name": "Sauca",
                "countryCode": "RO"
            },
            {
                "name": "Soleşti",
                "countryCode": "RO"
            },
            {
                "name": "Stănileşti",
                "countryCode": "RO"
            },
            {
                "name": "Tanacu",
                "countryCode": "RO"
            },
            {
                "name": "Todireşti",
                "countryCode": "RO"
            },
            {
                "name": "Trestiana",
                "countryCode": "RO"
            },
            {
                "name": "Tutova",
                "countryCode": "RO"
            },
            {
                "name": "Tăcuta",
                "countryCode": "RO"
            },
            {
                "name": "Tătărăni",
                "countryCode": "RO"
            },
            {
                "name": "Valea Grecului",
                "countryCode": "RO"
            },
            {
                "name": "Vaslui",
                "countryCode": "RO"
            },
            {
                "name": "Vetrişoaia",
                "countryCode": "RO"
            },
            {
                "name": "Viişoara",
                "countryCode": "RO"
            },
            {
                "name": "Vinderei",
                "countryCode": "RO"
            },
            {
                "name": "Voineşti",
                "countryCode": "RO"
            },
            {
                "name": "Vultureşti",
                "countryCode": "RO"
            },
            {
                "name": "Vutcani",
                "countryCode": "RO"
            },
            {
                "name": "Văleni",
                "countryCode": "RO"
            },
            {
                "name": "Zorleni",
                "countryCode": "RO"
            },
            {
                "name": "Zăpodeni",
                "countryCode": "RO"
            },
            {
                "name": "Ştefan cel Mare",
                "countryCode": "RO"
            },
            {
                "name": "Şuletea",
                "countryCode": "RO"
            }
        ]
    },
    {
        "name": "Russia",
        "phonecode": "7",
        "cities": [
            {
                "name": "Abadzekhskaya",
                "countryCode": "RU"
            },
            {
                "name": "Adygeysk",
                "countryCode": "RU"
            },
            {
                "name": "Adygeysk Republican Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Beloye",
                "countryCode": "RU"
            },
            {
                "name": "Blechepsin",
                "countryCode": "RU"
            },
            {
                "name": "Dondukovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Enem",
                "countryCode": "RU"
            },
            {
                "name": "Giaginskaya",
                "countryCode": "RU"
            },
            {
                "name": "Giaginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kamennomostskiy",
                "countryCode": "RU"
            },
            {
                "name": "Khanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Khatukay",
                "countryCode": "RU"
            },
            {
                "name": "Khodz’",
                "countryCode": "RU"
            },
            {
                "name": "Koshekhabl’",
                "countryCode": "RU"
            },
            {
                "name": "Koshekhabl’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuzhorskaya",
                "countryCode": "RU"
            },
            {
                "name": "Maykop",
                "countryCode": "RU"
            },
            {
                "name": "Maykop Republican Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Maykopskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Natyrbovo",
                "countryCode": "RU"
            },
            {
                "name": "Ponezhukay",
                "countryCode": "RU"
            },
            {
                "name": "Shovgenovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shovgenovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Takhtamukay",
                "countryCode": "RU"
            },
            {
                "name": "Takhtamukayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Teuchezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tlyustenkhabl’",
                "countryCode": "RU"
            },
            {
                "name": "Yablonovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Aktash",
                "countryCode": "RU"
            },
            {
                "name": "Artybash",
                "countryCode": "RU"
            },
            {
                "name": "Belyashi",
                "countryCode": "RU"
            },
            {
                "name": "Chemal",
                "countryCode": "RU"
            },
            {
                "name": "Cherga",
                "countryCode": "RU"
            },
            {
                "name": "Choya",
                "countryCode": "RU"
            },
            {
                "name": "Elekmonar",
                "countryCode": "RU"
            },
            {
                "name": "Gorno-Altaysk",
                "countryCode": "RU"
            },
            {
                "name": "Inya",
                "countryCode": "RU"
            },
            {
                "name": "Iogach",
                "countryCode": "RU"
            },
            {
                "name": "Kosh-Agach",
                "countryCode": "RU"
            },
            {
                "name": "Manzherok",
                "countryCode": "RU"
            },
            {
                "name": "Mayma",
                "countryCode": "RU"
            },
            {
                "name": "Mayminskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Onguday",
                "countryCode": "RU"
            },
            {
                "name": "Ongudayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shebalino",
                "countryCode": "RU"
            },
            {
                "name": "Shebalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Souzga",
                "countryCode": "RU"
            },
            {
                "name": "Tashanta",
                "countryCode": "RU"
            },
            {
                "name": "Turochak",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kan",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Koksa",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Koksinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Ulagan",
                "countryCode": "RU"
            },
            {
                "name": "Aleysk",
                "countryCode": "RU"
            },
            {
                "name": "Aleyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Altayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Anton’yevka",
                "countryCode": "RU"
            },
            {
                "name": "Aya",
                "countryCode": "RU"
            },
            {
                "name": "Barnaul",
                "countryCode": "RU"
            },
            {
                "name": "Barnaul Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Bastan",
                "countryCode": "RU"
            },
            {
                "name": "Bayevo",
                "countryCode": "RU"
            },
            {
                "name": "Bayunovskiye Klyuchi",
                "countryCode": "RU"
            },
            {
                "name": "Belokurikha",
                "countryCode": "RU"
            },
            {
                "name": "Beloyarsk",
                "countryCode": "RU"
            },
            {
                "name": "Berëzovka",
                "countryCode": "RU"
            },
            {
                "name": "Biysk",
                "countryCode": "RU"
            },
            {
                "name": "Blagoveshchenka",
                "countryCode": "RU"
            },
            {
                "name": "Bobrovka",
                "countryCode": "RU"
            },
            {
                "name": "Borovikha",
                "countryCode": "RU"
            },
            {
                "name": "Burla",
                "countryCode": "RU"
            },
            {
                "name": "Bystryanka",
                "countryCode": "RU"
            },
            {
                "name": "Bystryy Istok",
                "countryCode": "RU"
            },
            {
                "name": "Charyshskoye",
                "countryCode": "RU"
            },
            {
                "name": "Cheremnoye",
                "countryCode": "RU"
            },
            {
                "name": "Gal’bshtadt",
                "countryCode": "RU"
            },
            {
                "name": "Gon’ba",
                "countryCode": "RU"
            },
            {
                "name": "Gornyak",
                "countryCode": "RU"
            },
            {
                "name": "Grishkovka",
                "countryCode": "RU"
            },
            {
                "name": "Inya",
                "countryCode": "RU"
            },
            {
                "name": "Kalmanka",
                "countryCode": "RU"
            },
            {
                "name": "Kalmanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kamen’-na-Obi",
                "countryCode": "RU"
            },
            {
                "name": "Khabarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khabary",
                "countryCode": "RU"
            },
            {
                "name": "Klyuchi",
                "countryCode": "RU"
            },
            {
                "name": "Kosikha",
                "countryCode": "RU"
            },
            {
                "name": "Kosikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoshchekovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Krutikha",
                "countryCode": "RU"
            },
            {
                "name": "Kulunda",
                "countryCode": "RU"
            },
            {
                "name": "Kulundinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kur’inskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kusak",
                "countryCode": "RU"
            },
            {
                "name": "Kytmanovo",
                "countryCode": "RU"
            },
            {
                "name": "Lebyazh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoye",
                "countryCode": "RU"
            },
            {
                "name": "Logovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Loktevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Malinovoye Ozero",
                "countryCode": "RU"
            },
            {
                "name": "Malougrenevo",
                "countryCode": "RU"
            },
            {
                "name": "Mamontovo",
                "countryCode": "RU"
            },
            {
                "name": "Mamontovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Martynovo",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Nagornyy",
                "countryCode": "RU"
            },
            {
                "name": "Nalobikha",
                "countryCode": "RU"
            },
            {
                "name": "Natsional’nyy Rayon Nemetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nauchnyy Gorodok",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevka",
                "countryCode": "RU"
            },
            {
                "name": "Novichikha",
                "countryCode": "RU"
            },
            {
                "name": "Novichikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novikovo",
                "countryCode": "RU"
            },
            {
                "name": "Novoaltaysk",
                "countryCode": "RU"
            },
            {
                "name": "Novosilikatnyy",
                "countryCode": "RU"
            },
            {
                "name": "Novotyryshkino",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Zori",
                "countryCode": "RU"
            },
            {
                "name": "Pankrushikha",
                "countryCode": "RU"
            },
            {
                "name": "Pankrushikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovsk",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Podsosnovo",
                "countryCode": "RU"
            },
            {
                "name": "Pospelikha",
                "countryCode": "RU"
            },
            {
                "name": "Pospelikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rebrikha",
                "countryCode": "RU"
            },
            {
                "name": "Rebrikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rodino",
                "countryCode": "RU"
            },
            {
                "name": "Rodinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Romanovo",
                "countryCode": "RU"
            },
            {
                "name": "Romanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rubtsovsk",
                "countryCode": "RU"
            },
            {
                "name": "Rubtsovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sannikovo",
                "countryCode": "RU"
            },
            {
                "name": "Severka",
                "countryCode": "RU"
            },
            {
                "name": "Shakhi",
                "countryCode": "RU"
            },
            {
                "name": "Shelabolikha",
                "countryCode": "RU"
            },
            {
                "name": "Shelabolikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shipunovo",
                "countryCode": "RU"
            },
            {
                "name": "Shipunovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shubenka",
                "countryCode": "RU"
            },
            {
                "name": "Shul’gin Log",
                "countryCode": "RU"
            },
            {
                "name": "Sibirskiy",
                "countryCode": "RU"
            },
            {
                "name": "Slavgorod",
                "countryCode": "RU"
            },
            {
                "name": "Slavgorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Smolenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Smolenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sokolovo",
                "countryCode": "RU"
            },
            {
                "name": "Soloneshenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Soloneshnoye",
                "countryCode": "RU"
            },
            {
                "name": "Solton",
                "countryCode": "RU"
            },
            {
                "name": "Soltonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sorokino",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskoye",
                "countryCode": "RU"
            },
            {
                "name": "Srostki",
                "countryCode": "RU"
            },
            {
                "name": "Stan-Bekhtemir",
                "countryCode": "RU"
            },
            {
                "name": "Staroaleyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Starobelokurikha",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Togul",
                "countryCode": "RU"
            },
            {
                "name": "Stukovo",
                "countryCode": "RU"
            },
            {
                "name": "Sychëvka",
                "countryCode": "RU"
            },
            {
                "name": "Tabuny",
                "countryCode": "RU"
            },
            {
                "name": "Tal’menka",
                "countryCode": "RU"
            },
            {
                "name": "Tal’menskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Togul",
                "countryCode": "RU"
            },
            {
                "name": "Togul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Topchikha",
                "countryCode": "RU"
            },
            {
                "name": "Topol’noye",
                "countryCode": "RU"
            },
            {
                "name": "Troitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tselinnoye",
                "countryCode": "RU"
            },
            {
                "name": "Tyumentsevo",
                "countryCode": "RU"
            },
            {
                "name": "Tyumentsevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uglovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Charyshskaya Pristan’",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Isha",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kalmanka",
                "countryCode": "RU"
            },
            {
                "name": "Ust’yanka",
                "countryCode": "RU"
            },
            {
                "name": "Usyatskoye",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Katunskoye",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Suetka",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Bekhtemir",
                "countryCode": "RU"
            },
            {
                "name": "Veseloyarsk",
                "countryCode": "RU"
            },
            {
                "name": "Vlasikha",
                "countryCode": "RU"
            },
            {
                "name": "Volchikha",
                "countryCode": "RU"
            },
            {
                "name": "Volchikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yarovoye",
                "countryCode": "RU"
            },
            {
                "name": "Yegor’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yel’tsovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zalesovo",
                "countryCode": "RU"
            },
            {
                "name": "Zarinsk",
                "countryCode": "RU"
            },
            {
                "name": "Zarinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zarya",
                "countryCode": "RU"
            },
            {
                "name": "Zaton",
                "countryCode": "RU"
            },
            {
                "name": "Zav’yalovo",
                "countryCode": "RU"
            },
            {
                "name": "Zav’yalovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zmeinogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Zonal’noye",
                "countryCode": "RU"
            },
            {
                "name": "Zudilovo",
                "countryCode": "RU"
            },
            {
                "name": "Arkhara",
                "countryCode": "RU"
            },
            {
                "name": "Arkharinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Belogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Blagoveshchensk",
                "countryCode": "RU"
            },
            {
                "name": "Blagoveshchenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bureya",
                "countryCode": "RU"
            },
            {
                "name": "Bureyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ekimchan",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Blagoveshchensk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Raychikhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovka",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Magdagachi",
                "countryCode": "RU"
            },
            {
                "name": "Magdagachinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mazanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Never",
                "countryCode": "RU"
            },
            {
                "name": "Novobureyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novoraychikhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ovsyanka",
                "countryCode": "RU"
            },
            {
                "name": "Poyarkovo",
                "countryCode": "RU"
            },
            {
                "name": "Raychikhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Romnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Romny",
                "countryCode": "RU"
            },
            {
                "name": "Selemdzhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Seryshevo",
                "countryCode": "RU"
            },
            {
                "name": "Seryshevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shimanovsk",
                "countryCode": "RU"
            },
            {
                "name": "Shimanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shirokiy",
                "countryCode": "RU"
            },
            {
                "name": "Sivaki",
                "countryCode": "RU"
            },
            {
                "name": "Skovorodino",
                "countryCode": "RU"
            },
            {
                "name": "Skovorodinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Solovjevsk",
                "countryCode": "RU"
            },
            {
                "name": "Srednebelaya",
                "countryCode": "RU"
            },
            {
                "name": "Svobodnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Svobodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Takhtamygda",
                "countryCode": "RU"
            },
            {
                "name": "Talakan",
                "countryCode": "RU"
            },
            {
                "name": "Taldan",
                "countryCode": "RU"
            },
            {
                "name": "Tambovka",
                "countryCode": "RU"
            },
            {
                "name": "Tambovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tokur",
                "countryCode": "RU"
            },
            {
                "name": "Tsiolkovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tygda",
                "countryCode": "RU"
            },
            {
                "name": "Tynda",
                "countryCode": "RU"
            },
            {
                "name": "Tyndinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urusha",
                "countryCode": "RU"
            },
            {
                "name": "Ushumun",
                "countryCode": "RU"
            },
            {
                "name": "Yekaterinoslavka",
                "countryCode": "RU"
            },
            {
                "name": "Yerofey Pavlovich",
                "countryCode": "RU"
            },
            {
                "name": "Zavitinsk",
                "countryCode": "RU"
            },
            {
                "name": "Zavitinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zeya",
                "countryCode": "RU"
            },
            {
                "name": "Zeyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arkhangel’sk",
                "countryCode": "RU"
            },
            {
                "name": "Berëznik",
                "countryCode": "RU"
            },
            {
                "name": "Cheremushskiy",
                "countryCode": "RU"
            },
            {
                "name": "Dvinskoy",
                "countryCode": "RU"
            },
            {
                "name": "Ileza",
                "countryCode": "RU"
            },
            {
                "name": "Il’insko-Podomskoye",
                "countryCode": "RU"
            },
            {
                "name": "Isakogorka",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kargopol’",
                "countryCode": "RU"
            },
            {
                "name": "Kargopol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Karpogory",
                "countryCode": "RU"
            },
            {
                "name": "Katunino",
                "countryCode": "RU"
            },
            {
                "name": "Kharitonovo",
                "countryCode": "RU"
            },
            {
                "name": "Kholmogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kholmogory",
                "countryCode": "RU"
            },
            {
                "name": "Kizema",
                "countryCode": "RU"
            },
            {
                "name": "Kodino",
                "countryCode": "RU"
            },
            {
                "name": "Konosha",
                "countryCode": "RU"
            },
            {
                "name": "Konëvo",
                "countryCode": "RU"
            },
            {
                "name": "Koryazhma",
                "countryCode": "RU"
            },
            {
                "name": "Kotlas",
                "countryCode": "RU"
            },
            {
                "name": "Kotlasskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoborsk",
                "countryCode": "RU"
            },
            {
                "name": "Kuloy",
                "countryCode": "RU"
            },
            {
                "name": "Lenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leshukonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leshukonskoye",
                "countryCode": "RU"
            },
            {
                "name": "Loyga",
                "countryCode": "RU"
            },
            {
                "name": "Lukovetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mezenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mezen’",
                "countryCode": "RU"
            },
            {
                "name": "Mirnyy",
                "countryCode": "RU"
            },
            {
                "name": "Novodvinsk",
                "countryCode": "RU"
            },
            {
                "name": "Nyandoma",
                "countryCode": "RU"
            },
            {
                "name": "Nyandomskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oksovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Onega",
                "countryCode": "RU"
            },
            {
                "name": "Onezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pinega",
                "countryCode": "RU"
            },
            {
                "name": "Pinezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Plesetsk",
                "countryCode": "RU"
            },
            {
                "name": "Podyuga",
                "countryCode": "RU"
            },
            {
                "name": "Primorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Privodino",
                "countryCode": "RU"
            },
            {
                "name": "Puksoozero",
                "countryCode": "RU"
            },
            {
                "name": "Rochegda",
                "countryCode": "RU"
            },
            {
                "name": "Samoded",
                "countryCode": "RU"
            },
            {
                "name": "Severodvinsk",
                "countryCode": "RU"
            },
            {
                "name": "Shalakusha",
                "countryCode": "RU"
            },
            {
                "name": "Shangaly",
                "countryCode": "RU"
            },
            {
                "name": "Shenkursk",
                "countryCode": "RU"
            },
            {
                "name": "Shenkurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shipitsyno",
                "countryCode": "RU"
            },
            {
                "name": "Solginskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sol’vychegodsk",
                "countryCode": "RU"
            },
            {
                "name": "Udimskiy",
                "countryCode": "RU"
            },
            {
                "name": "Urdoma",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Shonosha",
                "countryCode": "RU"
            },
            {
                "name": "Ust’yanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uyemskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vas’kovo",
                "countryCode": "RU"
            },
            {
                "name": "Vel’sk",
                "countryCode": "RU"
            },
            {
                "name": "Vel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Toyma",
                "countryCode": "RU"
            },
            {
                "name": "Voloshka",
                "countryCode": "RU"
            },
            {
                "name": "Vychegodskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yagry",
                "countryCode": "RU"
            },
            {
                "name": "Yarensk",
                "countryCode": "RU"
            },
            {
                "name": "Yemetsk",
                "countryCode": "RU"
            },
            {
                "name": "Yemtsa",
                "countryCode": "RU"
            },
            {
                "name": "Yertsevo",
                "countryCode": "RU"
            },
            {
                "name": "Akhtubinsk",
                "countryCode": "RU"
            },
            {
                "name": "Akhtubinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aksarayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Astrakhan",
                "countryCode": "RU"
            },
            {
                "name": "Chyorny Yar",
                "countryCode": "RU"
            },
            {
                "name": "Ikryanoye",
                "countryCode": "RU"
            },
            {
                "name": "Kamyzyak",
                "countryCode": "RU"
            },
            {
                "name": "Kapustin Yar",
                "countryCode": "RU"
            },
            {
                "name": "Karalat",
                "countryCode": "RU"
            },
            {
                "name": "Kharabali",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyye Barrikady",
                "countryCode": "RU"
            },
            {
                "name": "Liman",
                "countryCode": "RU"
            },
            {
                "name": "Limanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Marfino",
                "countryCode": "RU"
            },
            {
                "name": "Mumra",
                "countryCode": "RU"
            },
            {
                "name": "Nachalovo",
                "countryCode": "RU"
            },
            {
                "name": "Narimanov",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Baskunchak",
                "countryCode": "RU"
            },
            {
                "name": "Oranzherei",
                "countryCode": "RU"
            },
            {
                "name": "Poldnëvoye",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sasykoli",
                "countryCode": "RU"
            },
            {
                "name": "Sizyy Bugor",
                "countryCode": "RU"
            },
            {
                "name": "Solyanka",
                "countryCode": "RU"
            },
            {
                "name": "Starokucherganovka",
                "countryCode": "RU"
            },
            {
                "name": "Tambovka",
                "countryCode": "RU"
            },
            {
                "name": "Trudfront",
                "countryCode": "RU"
            },
            {
                "name": "Tumak",
                "countryCode": "RU"
            },
            {
                "name": "Uvary",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Baskunchak",
                "countryCode": "RU"
            },
            {
                "name": "Volgo-Kaspiyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Volodarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yaksatovo",
                "countryCode": "RU"
            },
            {
                "name": "Yandyki",
                "countryCode": "RU"
            },
            {
                "name": "Yenotayevka",
                "countryCode": "RU"
            },
            {
                "name": "Zenzeli",
                "countryCode": "RU"
            },
            {
                "name": "Znamensk",
                "countryCode": "RU"
            },
            {
                "name": "Abzakovo",
                "countryCode": "RU"
            },
            {
                "name": "Abzelilovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Agidel’",
                "countryCode": "RU"
            },
            {
                "name": "Akhunovo",
                "countryCode": "RU"
            },
            {
                "name": "Aksakovo",
                "countryCode": "RU"
            },
            {
                "name": "Ak”yar",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevka",
                "countryCode": "RU"
            },
            {
                "name": "Al’sheyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Amzya",
                "countryCode": "RU"
            },
            {
                "name": "Asanovo",
                "countryCode": "RU"
            },
            {
                "name": "Askino",
                "countryCode": "RU"
            },
            {
                "name": "Askinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Avdon",
                "countryCode": "RU"
            },
            {
                "name": "Bakalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bakaly",
                "countryCode": "RU"
            },
            {
                "name": "Baymak",
                "countryCode": "RU"
            },
            {
                "name": "Bedeyeva Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Belebey",
                "countryCode": "RU"
            },
            {
                "name": "Beloretsk",
                "countryCode": "RU"
            },
            {
                "name": "Beloretskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Birsk",
                "countryCode": "RU"
            },
            {
                "name": "Bizhbulyak",
                "countryCode": "RU"
            },
            {
                "name": "Blagoveshchensk",
                "countryCode": "RU"
            },
            {
                "name": "Bulgakovo",
                "countryCode": "RU"
            },
            {
                "name": "Burayevo",
                "countryCode": "RU"
            },
            {
                "name": "Buribay",
                "countryCode": "RU"
            },
            {
                "name": "Buzdyak",
                "countryCode": "RU"
            },
            {
                "name": "Buzdyakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chekmagush",
                "countryCode": "RU"
            },
            {
                "name": "Chesnokovka",
                "countryCode": "RU"
            },
            {
                "name": "Chishmy",
                "countryCode": "RU"
            },
            {
                "name": "Davlekanovo",
                "countryCode": "RU"
            },
            {
                "name": "Dmitriyevka",
                "countryCode": "RU"
            },
            {
                "name": "Duvan",
                "countryCode": "RU"
            },
            {
                "name": "Dyurtyuli",
                "countryCode": "RU"
            },
            {
                "name": "Fëdorovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Iglino",
                "countryCode": "RU"
            },
            {
                "name": "Inzer",
                "countryCode": "RU"
            },
            {
                "name": "Ishimbay",
                "countryCode": "RU"
            },
            {
                "name": "Isyangulovo",
                "countryCode": "RU"
            },
            {
                "name": "Kabakovo",
                "countryCode": "RU"
            },
            {
                "name": "Kaltasy",
                "countryCode": "RU"
            },
            {
                "name": "Kandry",
                "countryCode": "RU"
            },
            {
                "name": "Karaidel’",
                "countryCode": "RU"
            },
            {
                "name": "Karmaskaly",
                "countryCode": "RU"
            },
            {
                "name": "Khaybullinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirgiz-Miyaki",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Gorka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokholmskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Klyuch",
                "countryCode": "RU"
            },
            {
                "name": "Kudeyevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kumertau",
                "countryCode": "RU"
            },
            {
                "name": "Kushnarënkovo",
                "countryCode": "RU"
            },
            {
                "name": "Lomovka",
                "countryCode": "RU"
            },
            {
                "name": "Maginsk",
                "countryCode": "RU"
            },
            {
                "name": "Mayachnyy",
                "countryCode": "RU"
            },
            {
                "name": "Mechetlinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Meleuz",
                "countryCode": "RU"
            },
            {
                "name": "Mesyagutovo",
                "countryCode": "RU"
            },
            {
                "name": "Mezgor'e",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovka",
                "countryCode": "RU"
            },
            {
                "name": "Mindyak",
                "countryCode": "RU"
            },
            {
                "name": "Mishkino",
                "countryCode": "RU"
            },
            {
                "name": "Mishkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Miyakinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mrakovo",
                "countryCode": "RU"
            },
            {
                "name": "Mursalimkino",
                "countryCode": "RU"
            },
            {
                "name": "Neftekamsk",
                "countryCode": "RU"
            },
            {
                "name": "Nikolo-Berëzovka",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnetroitskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novobelokatay",
                "countryCode": "RU"
            },
            {
                "name": "Nurimanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovka",
                "countryCode": "RU"
            },
            {
                "name": "Priyutovo",
                "countryCode": "RU"
            },
            {
                "name": "Rayevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Salavat",
                "countryCode": "RU"
            },
            {
                "name": "Semiletka",
                "countryCode": "RU"
            },
            {
                "name": "Serafimovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shafranovo",
                "countryCode": "RU"
            },
            {
                "name": "Sharan",
                "countryCode": "RU"
            },
            {
                "name": "Sibay",
                "countryCode": "RU"
            },
            {
                "name": "Starobaltachevo",
                "countryCode": "RU"
            },
            {
                "name": "Starosubkhangulovo",
                "countryCode": "RU"
            },
            {
                "name": "Sterlibashevo",
                "countryCode": "RU"
            },
            {
                "name": "Sterlitamak",
                "countryCode": "RU"
            },
            {
                "name": "Subkhankulovo",
                "countryCode": "RU"
            },
            {
                "name": "Temyasovo",
                "countryCode": "RU"
            },
            {
                "name": "Tirlyanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tolbazy",
                "countryCode": "RU"
            },
            {
                "name": "Tubinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tukan",
                "countryCode": "RU"
            },
            {
                "name": "Tuymazy",
                "countryCode": "RU"
            },
            {
                "name": "Uchaly",
                "countryCode": "RU"
            },
            {
                "name": "Ufa",
                "countryCode": "RU"
            },
            {
                "name": "Ulu-Telyak",
                "countryCode": "RU"
            },
            {
                "name": "Ulukulevo",
                "countryCode": "RU"
            },
            {
                "name": "Urman",
                "countryCode": "RU"
            },
            {
                "name": "Verkhneyarkeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Avzyan",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniye Kigi",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniye Tatyshly",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yanaul",
                "countryCode": "RU"
            },
            {
                "name": "Yanaul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yazykovo",
                "countryCode": "RU"
            },
            {
                "name": "Yermekeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Yermolayevo",
                "countryCode": "RU"
            },
            {
                "name": "Yumaguzino",
                "countryCode": "RU"
            },
            {
                "name": "Zilair",
                "countryCode": "RU"
            },
            {
                "name": "Zirgan",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevka",
                "countryCode": "RU"
            },
            {
                "name": "Bekhteyevka",
                "countryCode": "RU"
            },
            {
                "name": "Belgorod",
                "countryCode": "RU"
            },
            {
                "name": "Belgorodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bessonovka",
                "countryCode": "RU"
            },
            {
                "name": "Borisovka",
                "countryCode": "RU"
            },
            {
                "name": "Borisovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chernyanka",
                "countryCode": "RU"
            },
            {
                "name": "Chernyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Golovchino",
                "countryCode": "RU"
            },
            {
                "name": "Grayvoron",
                "countryCode": "RU"
            },
            {
                "name": "Grayvoronskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gubkin",
                "countryCode": "RU"
            },
            {
                "name": "Ilovka",
                "countryCode": "RU"
            },
            {
                "name": "Ivnya",
                "countryCode": "RU"
            },
            {
                "name": "Ivnyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Korocha",
                "countryCode": "RU"
            },
            {
                "name": "Korochanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Yaruga",
                "countryCode": "RU"
            },
            {
                "name": "Krasnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyaruzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye",
                "countryCode": "RU"
            },
            {
                "name": "Livenka",
                "countryCode": "RU"
            },
            {
                "name": "Maslova Pristan’",
                "countryCode": "RU"
            },
            {
                "name": "Mayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Tavolzhanka",
                "countryCode": "RU"
            },
            {
                "name": "Novooskol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Oskol",
                "countryCode": "RU"
            },
            {
                "name": "Prokhorovka",
                "countryCode": "RU"
            },
            {
                "name": "Prokhorovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Proletarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pyatnitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Rakitnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rakityanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Razumnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rogovatoye",
                "countryCode": "RU"
            },
            {
                "name": "Roven’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severnyy",
                "countryCode": "RU"
            },
            {
                "name": "Shebekino",
                "countryCode": "RU"
            },
            {
                "name": "Shebekinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Skorodnoye",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Oskol",
                "countryCode": "RU"
            },
            {
                "name": "Stroitel’",
                "countryCode": "RU"
            },
            {
                "name": "Tomarovka",
                "countryCode": "RU"
            },
            {
                "name": "Troitskiy",
                "countryCode": "RU"
            },
            {
                "name": "Urazovo",
                "countryCode": "RU"
            },
            {
                "name": "Valuyki",
                "countryCode": "RU"
            },
            {
                "name": "Valuyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Veydelevka",
                "countryCode": "RU"
            },
            {
                "name": "Volokonovka",
                "countryCode": "RU"
            },
            {
                "name": "Volokonovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevo",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zasosna",
                "countryCode": "RU"
            },
            {
                "name": "Altukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Ardon’",
                "countryCode": "RU"
            },
            {
                "name": "Belyye Berega",
                "countryCode": "RU"
            },
            {
                "name": "Brasovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bryansk",
                "countryCode": "RU"
            },
            {
                "name": "Bryanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bytosh’",
                "countryCode": "RU"
            },
            {
                "name": "Churovichi",
                "countryCode": "RU"
            },
            {
                "name": "Dobrun’",
                "countryCode": "RU"
            },
            {
                "name": "Dubrovka",
                "countryCode": "RU"
            },
            {
                "name": "Dubrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dyat’kovo",
                "countryCode": "RU"
            },
            {
                "name": "Dyat’kovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Glinishchevo",
                "countryCode": "RU"
            },
            {
                "name": "Gordeyevka",
                "countryCode": "RU"
            },
            {
                "name": "Gordeyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Bryansk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Dyat’kovo",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovka",
                "countryCode": "RU"
            },
            {
                "name": "Ivot",
                "countryCode": "RU"
            },
            {
                "name": "Karachev",
                "countryCode": "RU"
            },
            {
                "name": "Kletnya",
                "countryCode": "RU"
            },
            {
                "name": "Kletnyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Klimovo",
                "countryCode": "RU"
            },
            {
                "name": "Klimovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Klintsovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Klintsy",
                "countryCode": "RU"
            },
            {
                "name": "Kokino",
                "countryCode": "RU"
            },
            {
                "name": "Kokorevka",
                "countryCode": "RU"
            },
            {
                "name": "Komarichi",
                "countryCode": "RU"
            },
            {
                "name": "Komarichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Korzhovka-Golubovka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Gora",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lokot’",
                "countryCode": "RU"
            },
            {
                "name": "Lopandino",
                "countryCode": "RU"
            },
            {
                "name": "Lyubokhna",
                "countryCode": "RU"
            },
            {
                "name": "Lyubovsho",
                "countryCode": "RU"
            },
            {
                "name": "Mglin",
                "countryCode": "RU"
            },
            {
                "name": "Mglinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Navlinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Navlya",
                "countryCode": "RU"
            },
            {
                "name": "Net’inka",
                "countryCode": "RU"
            },
            {
                "name": "Novozybkov",
                "countryCode": "RU"
            },
            {
                "name": "Novozybkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Ropsk",
                "countryCode": "RU"
            },
            {
                "name": "Pochep",
                "countryCode": "RU"
            },
            {
                "name": "Pochepskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pogar",
                "countryCode": "RU"
            },
            {
                "name": "Pogarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Putëvka",
                "countryCode": "RU"
            },
            {
                "name": "Raditsa-Krylovka",
                "countryCode": "RU"
            },
            {
                "name": "Rognedino",
                "countryCode": "RU"
            },
            {
                "name": "Rognedinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rzhanitsa",
                "countryCode": "RU"
            },
            {
                "name": "Sachkovichi",
                "countryCode": "RU"
            },
            {
                "name": "Sel’tso",
                "countryCode": "RU"
            },
            {
                "name": "Seshcha",
                "countryCode": "RU"
            },
            {
                "name": "Sevsk",
                "countryCode": "RU"
            },
            {
                "name": "Sevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Starodub",
                "countryCode": "RU"
            },
            {
                "name": "Starodubskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Star’",
                "countryCode": "RU"
            },
            {
                "name": "Suponevo",
                "countryCode": "RU"
            },
            {
                "name": "Surazh",
                "countryCode": "RU"
            },
            {
                "name": "Surazhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Suzemskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Suzëmka",
                "countryCode": "RU"
            },
            {
                "name": "Sven’",
                "countryCode": "RU"
            },
            {
                "name": "Trubchevsk",
                "countryCode": "RU"
            },
            {
                "name": "Trubchevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Unecha",
                "countryCode": "RU"
            },
            {
                "name": "Unechskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vygonichi",
                "countryCode": "RU"
            },
            {
                "name": "Vygonichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vyshkov",
                "countryCode": "RU"
            },
            {
                "name": "Zamishevo",
                "countryCode": "RU"
            },
            {
                "name": "Zaymishche",
                "countryCode": "RU"
            },
            {
                "name": "Zhiryatino",
                "countryCode": "RU"
            },
            {
                "name": "Zhiryatinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovka",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zlynka",
                "countryCode": "RU"
            },
            {
                "name": "Babushkin",
                "countryCode": "RU"
            },
            {
                "name": "Bagdarin",
                "countryCode": "RU"
            },
            {
                "name": "Barguzin",
                "countryCode": "RU"
            },
            {
                "name": "Bayangol",
                "countryCode": "RU"
            },
            {
                "name": "Bichura",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoy Kunaley",
                "countryCode": "RU"
            },
            {
                "name": "Dzhida",
                "countryCode": "RU"
            },
            {
                "name": "Gusinoozyorsk",
                "countryCode": "RU"
            },
            {
                "name": "Gusinoye Ozero",
                "countryCode": "RU"
            },
            {
                "name": "Il’inka",
                "countryCode": "RU"
            },
            {
                "name": "Il’ka",
                "countryCode": "RU"
            },
            {
                "name": "Istok",
                "countryCode": "RU"
            },
            {
                "name": "Ivolginsk",
                "countryCode": "RU"
            },
            {
                "name": "Ivolginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kabansk",
                "countryCode": "RU"
            },
            {
                "name": "Kholtoson",
                "countryCode": "RU"
            },
            {
                "name": "Khonkholoy",
                "countryCode": "RU"
            },
            {
                "name": "Khorinsk",
                "countryCode": "RU"
            },
            {
                "name": "Kichera",
                "countryCode": "RU"
            },
            {
                "name": "Kizhinga",
                "countryCode": "RU"
            },
            {
                "name": "Kudara-Somon",
                "countryCode": "RU"
            },
            {
                "name": "Kurumkan",
                "countryCode": "RU"
            },
            {
                "name": "Kyakhta",
                "countryCode": "RU"
            },
            {
                "name": "Kyren",
                "countryCode": "RU"
            },
            {
                "name": "Mukhorshibirskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mukhorshibir’",
                "countryCode": "RU"
            },
            {
                "name": "Muyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Naushki",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nikol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneangarsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Sayantuy",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Bryan’",
                "countryCode": "RU"
            },
            {
                "name": "Novokizhinginsk",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Uoyan",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Zagan",
                "countryCode": "RU"
            },
            {
                "name": "Okino-Klyuchi",
                "countryCode": "RU"
            },
            {
                "name": "Okinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Onokhoy",
                "countryCode": "RU"
            },
            {
                "name": "Orlik",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovka",
                "countryCode": "RU"
            },
            {
                "name": "Pribaykal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rechka-Vydrino",
                "countryCode": "RU"
            },
            {
                "name": "Sagan-Nur",
                "countryCode": "RU"
            },
            {
                "name": "Selenduma",
                "countryCode": "RU"
            },
            {
                "name": "Selenginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severo-Baykal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severobaykal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Severomuysk",
                "countryCode": "RU"
            },
            {
                "name": "Sharalday",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovo-Ozerskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sotnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Taksimo",
                "countryCode": "RU"
            },
            {
                "name": "Tankhoy",
                "countryCode": "RU"
            },
            {
                "name": "Tarbagatay",
                "countryCode": "RU"
            },
            {
                "name": "Tataurovo",
                "countryCode": "RU"
            },
            {
                "name": "Turka",
                "countryCode": "RU"
            },
            {
                "name": "Turuntayevo",
                "countryCode": "RU"
            },
            {
                "name": "Ulan-Ude",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Barguzin",
                "countryCode": "RU"
            },
            {
                "name": "Vydrino",
                "countryCode": "RU"
            },
            {
                "name": "Zaigrayevo",
                "countryCode": "RU"
            },
            {
                "name": "Zakamensk",
                "countryCode": "RU"
            },
            {
                "name": "Zarechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zhemchug",
                "countryCode": "RU"
            },
            {
                "name": "Achkhoy-Martan",
                "countryCode": "RU"
            },
            {
                "name": "Achkhoy-Martanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Alkhan-Kala",
                "countryCode": "RU"
            },
            {
                "name": "Alkhan-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Alkhazurovo",
                "countryCode": "RU"
            },
            {
                "name": "Alleroy",
                "countryCode": "RU"
            },
            {
                "name": "Alpatovo",
                "countryCode": "RU"
            },
            {
                "name": "Argun",
                "countryCode": "RU"
            },
            {
                "name": "Assinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Avtury",
                "countryCode": "RU"
            },
            {
                "name": "Bachi-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Bamut",
                "countryCode": "RU"
            },
            {
                "name": "Belgatoy",
                "countryCode": "RU"
            },
            {
                "name": "Benoy-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Borzoy",
                "countryCode": "RU"
            },
            {
                "name": "Chechen-Aul",
                "countryCode": "RU"
            },
            {
                "name": "Chiri-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Duba-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Dyshne-Vedeno",
                "countryCode": "RU"
            },
            {
                "name": "Dzhalka",
                "countryCode": "RU"
            },
            {
                "name": "Elin-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Engel’-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Gekhi",
                "countryCode": "RU"
            },
            {
                "name": "Germenchuk",
                "countryCode": "RU"
            },
            {
                "name": "Gerzel’-Aul",
                "countryCode": "RU"
            },
            {
                "name": "Goragorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Goyty",
                "countryCode": "RU"
            },
            {
                "name": "Grebenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Groznenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Groznyy",
                "countryCode": "RU"
            },
            {
                "name": "Gudermes",
                "countryCode": "RU"
            },
            {
                "name": "Gudermesskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ilaskhan-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Ishcherskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ishkhoy-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Itum-Kali",
                "countryCode": "RU"
            },
            {
                "name": "Itum-Kalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kalinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kargalinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Katyr-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Khambi-Irze",
                "countryCode": "RU"
            },
            {
                "name": "Khankala",
                "countryCode": "RU"
            },
            {
                "name": "Kulary",
                "countryCode": "RU"
            },
            {
                "name": "Kurchaloy",
                "countryCode": "RU"
            },
            {
                "name": "Lakha Nëvre",
                "countryCode": "RU"
            },
            {
                "name": "Lakkha Nëvre",
                "countryCode": "RU"
            },
            {
                "name": "Martan-Chu",
                "countryCode": "RU"
            },
            {
                "name": "Mayrtup",
                "countryCode": "RU"
            },
            {
                "name": "Mesker-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Nadterechnyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Naurskaya",
                "countryCode": "RU"
            },
            {
                "name": "Naurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nogamerzin-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Atagi",
                "countryCode": "RU"
            },
            {
                "name": "Nozhay-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Nozhay-Yurtovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oyskhara",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskaya",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Prigorodnoye",
                "countryCode": "RU"
            },
            {
                "name": "Roshni-Chu",
                "countryCode": "RU"
            },
            {
                "name": "Samashki",
                "countryCode": "RU"
            },
            {
                "name": "Sernovodsk",
                "countryCode": "RU"
            },
            {
                "name": "Serzhen’-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Shaami-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Shalazhi",
                "countryCode": "RU"
            },
            {
                "name": "Shali",
                "countryCode": "RU"
            },
            {
                "name": "Shalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shatoy",
                "countryCode": "RU"
            },
            {
                "name": "Shelkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shëlkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Sunzha",
                "countryCode": "RU"
            },
            {
                "name": "Staryye Atagi",
                "countryCode": "RU"
            },
            {
                "name": "Tolstoy-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Tsentoroy",
                "countryCode": "RU"
            },
            {
                "name": "Tsotsin-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Urus-Martan",
                "countryCode": "RU"
            },
            {
                "name": "Urus-Martanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Valerik",
                "countryCode": "RU"
            },
            {
                "name": "Vedeno",
                "countryCode": "RU"
            },
            {
                "name": "Vedenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yalkhoy-Mokhk",
                "countryCode": "RU"
            },
            {
                "name": "Zakan-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Zandak",
                "countryCode": "RU"
            },
            {
                "name": "Znamenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Agapovka",
                "countryCode": "RU"
            },
            {
                "name": "Agapovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Argayash",
                "countryCode": "RU"
            },
            {
                "name": "Asha",
                "countryCode": "RU"
            },
            {
                "name": "Ashinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bakal",
                "countryCode": "RU"
            },
            {
                "name": "Bazhovo",
                "countryCode": "RU"
            },
            {
                "name": "Berdyaush",
                "countryCode": "RU"
            },
            {
                "name": "Bobrovka",
                "countryCode": "RU"
            },
            {
                "name": "Bredy",
                "countryCode": "RU"
            },
            {
                "name": "Brodokalmak",
                "countryCode": "RU"
            },
            {
                "name": "Chebarkul’",
                "countryCode": "RU"
            },
            {
                "name": "Chebarkul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chelyabinsk",
                "countryCode": "RU"
            },
            {
                "name": "Cheremshanka",
                "countryCode": "RU"
            },
            {
                "name": "Chesma",
                "countryCode": "RU"
            },
            {
                "name": "Dolgoderevenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Fershampenuaz",
                "countryCode": "RU"
            },
            {
                "name": "Gornyak",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Chelyabinsk",
                "countryCode": "RU"
            },
            {
                "name": "Kanashevo",
                "countryCode": "RU"
            },
            {
                "name": "Karabash",
                "countryCode": "RU"
            },
            {
                "name": "Kartalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kartaly",
                "countryCode": "RU"
            },
            {
                "name": "Kasli",
                "countryCode": "RU"
            },
            {
                "name": "Katav-Ivanovsk",
                "countryCode": "RU"
            },
            {
                "name": "Katav-Ivanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kizil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kopeysk",
                "countryCode": "RU"
            },
            {
                "name": "Korkino",
                "countryCode": "RU"
            },
            {
                "name": "Koyelga",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kropachëvo",
                "countryCode": "RU"
            },
            {
                "name": "Kunashak",
                "countryCode": "RU"
            },
            {
                "name": "Kusa",
                "countryCode": "RU"
            },
            {
                "name": "Kyshtym",
                "countryCode": "RU"
            },
            {
                "name": "Leninsk",
                "countryCode": "RU"
            },
            {
                "name": "Lokomotivnyy",
                "countryCode": "RU"
            },
            {
                "name": "Magnitka",
                "countryCode": "RU"
            },
            {
                "name": "Magnitogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Mezhevoy",
                "countryCode": "RU"
            },
            {
                "name": "Miass",
                "countryCode": "RU"
            },
            {
                "name": "Miasskoye",
                "countryCode": "RU"
            },
            {
                "name": "Muslyumovo",
                "countryCode": "RU"
            },
            {
                "name": "Nagaybakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Ufaley",
                "countryCode": "RU"
            },
            {
                "name": "Novogornyy",
                "countryCode": "RU"
            },
            {
                "name": "Novosineglazovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nyazepetrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Nyazepetrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ozersk",
                "countryCode": "RU"
            },
            {
                "name": "Parizh",
                "countryCode": "RU"
            },
            {
                "name": "Plast",
                "countryCode": "RU"
            },
            {
                "name": "Poletayevo",
                "countryCode": "RU"
            },
            {
                "name": "Potanino",
                "countryCode": "RU"
            },
            {
                "name": "Roshchino",
                "countryCode": "RU"
            },
            {
                "name": "Roza",
                "countryCode": "RU"
            },
            {
                "name": "Sargazy",
                "countryCode": "RU"
            },
            {
                "name": "Satka",
                "countryCode": "RU"
            },
            {
                "name": "Satkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Selezyan",
                "countryCode": "RU"
            },
            {
                "name": "Sim",
                "countryCode": "RU"
            },
            {
                "name": "Snezhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Starokamyshinsk",
                "countryCode": "RU"
            },
            {
                "name": "Syrostan",
                "countryCode": "RU"
            },
            {
                "name": "Tayginka",
                "countryCode": "RU"
            },
            {
                "name": "Timiryazevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Troitsk",
                "countryCode": "RU"
            },
            {
                "name": "Trëkhgornyy",
                "countryCode": "RU"
            },
            {
                "name": "Turgoyak",
                "countryCode": "RU"
            },
            {
                "name": "Tyubuk",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Bagaryak",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Katav",
                "countryCode": "RU"
            },
            {
                "name": "Uvel’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Uvel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Vakhrushevo",
                "countryCode": "RU"
            },
            {
                "name": "Varna",
                "countryCode": "RU"
            },
            {
                "name": "Varnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Velikopetrovka",
                "countryCode": "RU"
            },
            {
                "name": "Verkhneural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Ufaley",
                "countryCode": "RU"
            },
            {
                "name": "Vishnëvogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Vyazovaya",
                "countryCode": "RU"
            },
            {
                "name": "Yemanzhelinka",
                "countryCode": "RU"
            },
            {
                "name": "Yemanzhelinsk",
                "countryCode": "RU"
            },
            {
                "name": "Yetkul’",
                "countryCode": "RU"
            },
            {
                "name": "Yuryuzan’",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhnoural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznodorozhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zlatoust",
                "countryCode": "RU"
            },
            {
                "name": "Anadyr",
                "countryCode": "RU"
            },
            {
                "name": "Anadyrskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Beringovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Bilibino",
                "countryCode": "RU"
            },
            {
                "name": "Egvekinot",
                "countryCode": "RU"
            },
            {
                "name": "Lavrentiya",
                "countryCode": "RU"
            },
            {
                "name": "Lorino",
                "countryCode": "RU"
            },
            {
                "name": "Pevek",
                "countryCode": "RU"
            },
            {
                "name": "Provideniya",
                "countryCode": "RU"
            },
            {
                "name": "Providenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Alatyrskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Alatyr’",
                "countryCode": "RU"
            },
            {
                "name": "Alikovo",
                "countryCode": "RU"
            },
            {
                "name": "Alikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atlashevo",
                "countryCode": "RU"
            },
            {
                "name": "Batyrevo",
                "countryCode": "RU"
            },
            {
                "name": "Batyrevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Buinsk",
                "countryCode": "RU"
            },
            {
                "name": "Cheboksarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Cheboksary",
                "countryCode": "RU"
            },
            {
                "name": "Ibresi",
                "countryCode": "RU"
            },
            {
                "name": "Ibresinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ishley",
                "countryCode": "RU"
            },
            {
                "name": "Kanash",
                "countryCode": "RU"
            },
            {
                "name": "Kanashskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirya",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol'skoye",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kozlovka",
                "countryCode": "RU"
            },
            {
                "name": "Kozlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnochetayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyye Chetai",
                "countryCode": "RU"
            },
            {
                "name": "Kugesi",
                "countryCode": "RU"
            },
            {
                "name": "Mariinskiy Posad",
                "countryCode": "RU"
            },
            {
                "name": "Mariinsko-Posadskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Morgaushi",
                "countryCode": "RU"
            },
            {
                "name": "Morgaushskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novocheboksarsk",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Lapsary",
                "countryCode": "RU"
            },
            {
                "name": "Poretskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Poretskoye",
                "countryCode": "RU"
            },
            {
                "name": "Shemursha",
                "countryCode": "RU"
            },
            {
                "name": "Shemurshinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shikhazany",
                "countryCode": "RU"
            },
            {
                "name": "Shumerlinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shumerlya",
                "countryCode": "RU"
            },
            {
                "name": "Tsivil’sk",
                "countryCode": "RU"
            },
            {
                "name": "Tsivil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urmarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urmary",
                "countryCode": "RU"
            },
            {
                "name": "Vurnarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vurnary",
                "countryCode": "RU"
            },
            {
                "name": "Yadrin",
                "countryCode": "RU"
            },
            {
                "name": "Yadrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yal’chikskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yantikovo",
                "countryCode": "RU"
            },
            {
                "name": "Yantikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Achisu",
                "countryCode": "RU"
            },
            {
                "name": "Adil’-Yangiyurt",
                "countryCode": "RU"
            },
            {
                "name": "Agul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Agvali",
                "countryCode": "RU"
            },
            {
                "name": "Akhty",
                "countryCode": "RU"
            },
            {
                "name": "Akhtynskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Akhvakhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aknada",
                "countryCode": "RU"
            },
            {
                "name": "Aksay",
                "countryCode": "RU"
            },
            {
                "name": "Akusha",
                "countryCode": "RU"
            },
            {
                "name": "Akushinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Andi",
                "countryCode": "RU"
            },
            {
                "name": "Ansalta",
                "countryCode": "RU"
            },
            {
                "name": "Ashil’ta",
                "countryCode": "RU"
            },
            {
                "name": "Babayurt",
                "countryCode": "RU"
            },
            {
                "name": "Babayurtovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bammatyurt",
                "countryCode": "RU"
            },
            {
                "name": "Bavtugay",
                "countryCode": "RU"
            },
            {
                "name": "Belidzhi",
                "countryCode": "RU"
            },
            {
                "name": "Bezhta",
                "countryCode": "RU"
            },
            {
                "name": "Botashyurt",
                "countryCode": "RU"
            },
            {
                "name": "Botayurt",
                "countryCode": "RU"
            },
            {
                "name": "Botlikhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Burtunay",
                "countryCode": "RU"
            },
            {
                "name": "Buynaksk",
                "countryCode": "RU"
            },
            {
                "name": "Buynakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Charodinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chinar",
                "countryCode": "RU"
            },
            {
                "name": "Chirkey",
                "countryCode": "RU"
            },
            {
                "name": "Chontaul",
                "countryCode": "RU"
            },
            {
                "name": "Dagestanskiye Ogni",
                "countryCode": "RU"
            },
            {
                "name": "Dakhadayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Derbent",
                "countryCode": "RU"
            },
            {
                "name": "Derbentskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dokuzparinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dorgeli",
                "countryCode": "RU"
            },
            {
                "name": "Druzhba",
                "countryCode": "RU"
            },
            {
                "name": "Dubki",
                "countryCode": "RU"
            },
            {
                "name": "Dylym",
                "countryCode": "RU"
            },
            {
                "name": "Endirey",
                "countryCode": "RU"
            },
            {
                "name": "Erpeli",
                "countryCode": "RU"
            },
            {
                "name": "Gagatli",
                "countryCode": "RU"
            },
            {
                "name": "Gaptsakh",
                "countryCode": "RU"
            },
            {
                "name": "Gedzhukh",
                "countryCode": "RU"
            },
            {
                "name": "Gereykhanovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Gerga",
                "countryCode": "RU"
            },
            {
                "name": "Gergebil’",
                "countryCode": "RU"
            },
            {
                "name": "Gergebil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gimry",
                "countryCode": "RU"
            },
            {
                "name": "Gubden",
                "countryCode": "RU"
            },
            {
                "name": "Gumbetovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gunib",
                "countryCode": "RU"
            },
            {
                "name": "Gunibskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gurbuki",
                "countryCode": "RU"
            },
            {
                "name": "Irganay",
                "countryCode": "RU"
            },
            {
                "name": "Izberbash",
                "countryCode": "RU"
            },
            {
                "name": "Kafyr-Kumukh",
                "countryCode": "RU"
            },
            {
                "name": "Kalininaul",
                "countryCode": "RU"
            },
            {
                "name": "Karabudakhkent",
                "countryCode": "RU"
            },
            {
                "name": "Karabudakhkentskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Karamakhi",
                "countryCode": "RU"
            },
            {
                "name": "Karata",
                "countryCode": "RU"
            },
            {
                "name": "Kaspiysk",
                "countryCode": "RU"
            },
            {
                "name": "Kasumkent",
                "countryCode": "RU"
            },
            {
                "name": "Kayakent",
                "countryCode": "RU"
            },
            {
                "name": "Kayakentskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kaytagskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazbekovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khadzhalmakhi",
                "countryCode": "RU"
            },
            {
                "name": "Khamamatyurt",
                "countryCode": "RU"
            },
            {
                "name": "Khasavyurt",
                "countryCode": "RU"
            },
            {
                "name": "Khasavyurtovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khazar",
                "countryCode": "RU"
            },
            {
                "name": "Khebda",
                "countryCode": "RU"
            },
            {
                "name": "Khiv",
                "countryCode": "RU"
            },
            {
                "name": "Khivskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khuchni",
                "countryCode": "RU"
            },
            {
                "name": "Khunzakh",
                "countryCode": "RU"
            },
            {
                "name": "Khunzakhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kishcha",
                "countryCode": "RU"
            },
            {
                "name": "Kizilyurt",
                "countryCode": "RU"
            },
            {
                "name": "Kizilyurtovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kizlyar",
                "countryCode": "RU"
            },
            {
                "name": "Kizlyarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kochubey",
                "countryCode": "RU"
            },
            {
                "name": "Kokrek",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Korkmaskala",
                "countryCode": "RU"
            },
            {
                "name": "Kostek",
                "countryCode": "RU"
            },
            {
                "name": "Kubachi",
                "countryCode": "RU"
            },
            {
                "name": "Kuli",
                "countryCode": "RU"
            },
            {
                "name": "Kulinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kumukh",
                "countryCode": "RU"
            },
            {
                "name": "Kurakh",
                "countryCode": "RU"
            },
            {
                "name": "Kurakhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kurkent",
                "countryCode": "RU"
            },
            {
                "name": "Kurush",
                "countryCode": "RU"
            },
            {
                "name": "Kyakhulay",
                "countryCode": "RU"
            },
            {
                "name": "Lakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leninaul",
                "countryCode": "RU"
            },
            {
                "name": "Leninkent",
                "countryCode": "RU"
            },
            {
                "name": "Levashi",
                "countryCode": "RU"
            },
            {
                "name": "Levashinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lutkun",
                "countryCode": "RU"
            },
            {
                "name": "Madzhalis",
                "countryCode": "RU"
            },
            {
                "name": "Magaramkent",
                "countryCode": "RU"
            },
            {
                "name": "Magaramkentskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Makhachkala",
                "countryCode": "RU"
            },
            {
                "name": "Mamedkala",
                "countryCode": "RU"
            },
            {
                "name": "Manas",
                "countryCode": "RU"
            },
            {
                "name": "Manaskent",
                "countryCode": "RU"
            },
            {
                "name": "Miatli",
                "countryCode": "RU"
            },
            {
                "name": "Miskindzha",
                "countryCode": "RU"
            },
            {
                "name": "Mugi",
                "countryCode": "RU"
            },
            {
                "name": "Mutsalaul",
                "countryCode": "RU"
            },
            {
                "name": "Myurego",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneye Kazanishche",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Dzhengutay",
                "countryCode": "RU"
            },
            {
                "name": "Nogayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Maka",
                "countryCode": "RU"
            },
            {
                "name": "Novogagatli",
                "countryCode": "RU"
            },
            {
                "name": "Novokayakent",
                "countryCode": "RU"
            },
            {
                "name": "Novolakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novolakskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Chirkey",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Khushet",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Kostek",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Sulak",
                "countryCode": "RU"
            },
            {
                "name": "Nuradilovo",
                "countryCode": "RU"
            },
            {
                "name": "Paraul",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Rutul",
                "countryCode": "RU"
            },
            {
                "name": "Rutul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sabnova",
                "countryCode": "RU"
            },
            {
                "name": "Samur",
                "countryCode": "RU"
            },
            {
                "name": "Sergokala",
                "countryCode": "RU"
            },
            {
                "name": "Sergokalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shamil’kala",
                "countryCode": "RU"
            },
            {
                "name": "Shamil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shamkhal",
                "countryCode": "RU"
            },
            {
                "name": "Shamkhal-Termen",
                "countryCode": "RU"
            },
            {
                "name": "Siukh",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sulak",
                "countryCode": "RU"
            },
            {
                "name": "Suleyman-Stal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Syrtych",
                "countryCode": "RU"
            },
            {
                "name": "Tabasaranskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tarki",
                "countryCode": "RU"
            },
            {
                "name": "Tarumovka",
                "countryCode": "RU"
            },
            {
                "name": "Tarumovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Temiraul",
                "countryCode": "RU"
            },
            {
                "name": "Terekli-Mekteb",
                "countryCode": "RU"
            },
            {
                "name": "Tlyarata",
                "countryCode": "RU"
            },
            {
                "name": "Tlyaratinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tpig",
                "countryCode": "RU"
            },
            {
                "name": "Tsumadinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tsuntinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tsurib",
                "countryCode": "RU"
            },
            {
                "name": "Tyube",
                "countryCode": "RU"
            },
            {
                "name": "Uchkent",
                "countryCode": "RU"
            },
            {
                "name": "Ulluaya",
                "countryCode": "RU"
            },
            {
                "name": "Ullubiyaul",
                "countryCode": "RU"
            },
            {
                "name": "Untsukul’",
                "countryCode": "RU"
            },
            {
                "name": "Untsukul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urkarakh",
                "countryCode": "RU"
            },
            {
                "name": "Urma",
                "countryCode": "RU"
            },
            {
                "name": "Usisha",
                "countryCode": "RU"
            },
            {
                "name": "Usukhchay",
                "countryCode": "RU"
            },
            {
                "name": "Utamysh",
                "countryCode": "RU"
            },
            {
                "name": "Utsmiyurt",
                "countryCode": "RU"
            },
            {
                "name": "Velikent",
                "countryCode": "RU"
            },
            {
                "name": "Verkhneye Kazanishche",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhno-Sukhokumsk",
                "countryCode": "RU"
            },
            {
                "name": "Zubutli-Miatli",
                "countryCode": "RU"
            },
            {
                "name": "Ali-Yurt",
                "countryCode": "RU"
            },
            {
                "name": "Alkhan-Churt",
                "countryCode": "RU"
            },
            {
                "name": "Dalakovo",
                "countryCode": "RU"
            },
            {
                "name": "Dzhayrakh",
                "countryCode": "RU"
            },
            {
                "name": "Dzhayrakhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ekazhevo",
                "countryCode": "RU"
            },
            {
                "name": "Galashki",
                "countryCode": "RU"
            },
            {
                "name": "Kantyshevo",
                "countryCode": "RU"
            },
            {
                "name": "Karabulak",
                "countryCode": "RU"
            },
            {
                "name": "Magas",
                "countryCode": "RU"
            },
            {
                "name": "Malgobek",
                "countryCode": "RU"
            },
            {
                "name": "Malgobekskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nazranovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nazran’",
                "countryCode": "RU"
            },
            {
                "name": "Nesterovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniye Achaluki",
                "countryCode": "RU"
            },
            {
                "name": "Psedakh",
                "countryCode": "RU"
            },
            {
                "name": "Sagopshi",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Malgobek",
                "countryCode": "RU"
            },
            {
                "name": "Sunzha",
                "countryCode": "RU"
            },
            {
                "name": "Sunzhenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Surkhakhi",
                "countryCode": "RU"
            },
            {
                "name": "Troitskaya",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniye Achaluki",
                "countryCode": "RU"
            },
            {
                "name": "Voznesenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Arkhipovka",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Dulyapino",
                "countryCode": "RU"
            },
            {
                "name": "Furmanov",
                "countryCode": "RU"
            },
            {
                "name": "Gavrilov Posad",
                "countryCode": "RU"
            },
            {
                "name": "Il’inskoye-Khovanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovo",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kaminskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kineshemskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kineshma",
                "countryCode": "RU"
            },
            {
                "name": "Kitovo",
                "countryCode": "RU"
            },
            {
                "name": "Kokhma",
                "countryCode": "RU"
            },
            {
                "name": "Kolobovo",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kuznechikha",
                "countryCode": "RU"
            },
            {
                "name": "Lezhnevo",
                "countryCode": "RU"
            },
            {
                "name": "Lezhnevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lukh",
                "countryCode": "RU"
            },
            {
                "name": "Lukhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Markovo",
                "countryCode": "RU"
            },
            {
                "name": "Navoloki",
                "countryCode": "RU"
            },
            {
                "name": "Nerl’",
                "countryCode": "RU"
            },
            {
                "name": "Novo-Talitsy",
                "countryCode": "RU"
            },
            {
                "name": "Novopistsovo",
                "countryCode": "RU"
            },
            {
                "name": "Novoye Leushino",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Gorki",
                "countryCode": "RU"
            },
            {
                "name": "Palekh",
                "countryCode": "RU"
            },
            {
                "name": "Palekhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pestyaki",
                "countryCode": "RU"
            },
            {
                "name": "Pestyakovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petrovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pistsovo",
                "countryCode": "RU"
            },
            {
                "name": "Plës",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhsk",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Puchezh",
                "countryCode": "RU"
            },
            {
                "name": "Puchezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rodniki",
                "countryCode": "RU"
            },
            {
                "name": "Rodnikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Savino",
                "countryCode": "RU"
            },
            {
                "name": "Savinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shuya",
                "countryCode": "RU"
            },
            {
                "name": "Shuyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Vichuga",
                "countryCode": "RU"
            },
            {
                "name": "Talitsy",
                "countryCode": "RU"
            },
            {
                "name": "Teykovo",
                "countryCode": "RU"
            },
            {
                "name": "Teykovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnelandekhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Landekh",
                "countryCode": "RU"
            },
            {
                "name": "Vichuga",
                "countryCode": "RU"
            },
            {
                "name": "Vichugskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yur’yevets",
                "countryCode": "RU"
            },
            {
                "name": "Yur’yevetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yuzha",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zarechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zavolzhsk",
                "countryCode": "RU"
            },
            {
                "name": "Zavolzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aleutskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atlasovo",
                "countryCode": "RU"
            },
            {
                "name": "Bystrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Esso",
                "countryCode": "RU"
            },
            {
                "name": "Karaginsky District",
                "countryCode": "RU"
            },
            {
                "name": "Klyuchi",
                "countryCode": "RU"
            },
            {
                "name": "Kozyrëvsk",
                "countryCode": "RU"
            },
            {
                "name": "Mil’kovo",
                "countryCode": "RU"
            },
            {
                "name": "Mil’kovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Olyutorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ossora",
                "countryCode": "RU"
            },
            {
                "name": "Ozernovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Palana",
                "countryCode": "RU"
            },
            {
                "name": "Paratunka",
                "countryCode": "RU"
            },
            {
                "name": "Penzhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovsk-Kamchatsky",
                "countryCode": "RU"
            },
            {
                "name": "Sobolevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tigil’",
                "countryCode": "RU"
            },
            {
                "name": "Tigil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tilichiki",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Bol’sheretskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kamchatsk Staryy",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kamchatskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vilyuchinsk",
                "countryCode": "RU"
            },
            {
                "name": "Yelizovo",
                "countryCode": "RU"
            },
            {
                "name": "Yelizovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Altud",
                "countryCode": "RU"
            },
            {
                "name": "Argudan",
                "countryCode": "RU"
            },
            {
                "name": "Aushiger",
                "countryCode": "RU"
            },
            {
                "name": "Babugent",
                "countryCode": "RU"
            },
            {
                "name": "Baksan",
                "countryCode": "RU"
            },
            {
                "name": "Baksanenok",
                "countryCode": "RU"
            },
            {
                "name": "Baksanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Rechka",
                "countryCode": "RU"
            },
            {
                "name": "Bylym",
                "countryCode": "RU"
            },
            {
                "name": "Chegem",
                "countryCode": "RU"
            },
            {
                "name": "Chegem Vtoroy",
                "countryCode": "RU"
            },
            {
                "name": "Cherekskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Deyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Dugulubgey",
                "countryCode": "RU"
            },
            {
                "name": "El’brus",
                "countryCode": "RU"
            },
            {
                "name": "El’brusskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Germenchik",
                "countryCode": "RU"
            },
            {
                "name": "Gundelen",
                "countryCode": "RU"
            },
            {
                "name": "Islamey",
                "countryCode": "RU"
            },
            {
                "name": "Kakhun",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kamennomostskoye",
                "countryCode": "RU"
            },
            {
                "name": "Karagach",
                "countryCode": "RU"
            },
            {
                "name": "Kashkhatau",
                "countryCode": "RU"
            },
            {
                "name": "Kenzhe",
                "countryCode": "RU"
            },
            {
                "name": "Kishpek",
                "countryCode": "RU"
            },
            {
                "name": "Kotlyarevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kuba-Taba",
                "countryCode": "RU"
            },
            {
                "name": "Kyzburun Pervyy",
                "countryCode": "RU"
            },
            {
                "name": "Lechinkay",
                "countryCode": "RU"
            },
            {
                "name": "Leskenskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Malka",
                "countryCode": "RU"
            },
            {
                "name": "Mayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nal’chik",
                "countryCode": "RU"
            },
            {
                "name": "Nartan",
                "countryCode": "RU"
            },
            {
                "name": "Nartkala",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Cherek",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Kurkuzhin",
                "countryCode": "RU"
            },
            {
                "name": "Planovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Primalkinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Prokhladnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Prokhladnyy",
                "countryCode": "RU"
            },
            {
                "name": "Psygansu",
                "countryCode": "RU"
            },
            {
                "name": "Sarmakovo",
                "countryCode": "RU"
            },
            {
                "name": "Shalushka",
                "countryCode": "RU"
            },
            {
                "name": "Soldatskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Cherek",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Urukh",
                "countryCode": "RU"
            },
            {
                "name": "Terek",
                "countryCode": "RU"
            },
            {
                "name": "Terskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Terskol",
                "countryCode": "RU"
            },
            {
                "name": "Tyrnyauz",
                "countryCode": "RU"
            },
            {
                "name": "Urukh",
                "countryCode": "RU"
            },
            {
                "name": "Urvanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urvan’",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Kurkuzhin",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Balkariya",
                "countryCode": "RU"
            },
            {
                "name": "Vol’nyy Aul",
                "countryCode": "RU"
            },
            {
                "name": "Zalukokoazhe",
                "countryCode": "RU"
            },
            {
                "name": "Zayukovo",
                "countryCode": "RU"
            },
            {
                "name": "Zhankhoteko",
                "countryCode": "RU"
            },
            {
                "name": "Zhemtala",
                "countryCode": "RU"
            },
            {
                "name": "Zol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Adyge-Khabl’",
                "countryCode": "RU"
            },
            {
                "name": "Ali-Berdukovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Besleney",
                "countryCode": "RU"
            },
            {
                "name": "Cherkessk",
                "countryCode": "RU"
            },
            {
                "name": "Druzhba",
                "countryCode": "RU"
            },
            {
                "name": "Ikon-Khalk",
                "countryCode": "RU"
            },
            {
                "name": "Ispravnaya",
                "countryCode": "RU"
            },
            {
                "name": "Karachayevsk",
                "countryCode": "RU"
            },
            {
                "name": "Karachayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kardonikskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kavkazskiy",
                "countryCode": "RU"
            },
            {
                "name": "Khabez",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Kurgan",
                "countryCode": "RU"
            },
            {
                "name": "Kumysh",
                "countryCode": "RU"
            },
            {
                "name": "Kurdzhinovo",
                "countryCode": "RU"
            },
            {
                "name": "Kyzyl-Oktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Malokarachayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mednogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Arkhyz",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Karachay",
                "countryCode": "RU"
            },
            {
                "name": "Ordzhonikidzevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pravokubanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pregradnaya",
                "countryCode": "RU"
            },
            {
                "name": "Prikubanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Psyzh",
                "countryCode": "RU"
            },
            {
                "name": "Sary-Tyuz",
                "countryCode": "RU"
            },
            {
                "name": "Storozhevaya",
                "countryCode": "RU"
            },
            {
                "name": "Tereze",
                "countryCode": "RU"
            },
            {
                "name": "Uchkeken",
                "countryCode": "RU"
            },
            {
                "name": "Uchkulan",
                "countryCode": "RU"
            },
            {
                "name": "Udarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Dzheguta",
                "countryCode": "RU"
            },
            {
                "name": "Zelenchukskaya",
                "countryCode": "RU"
            },
            {
                "name": "Abinsk",
                "countryCode": "RU"
            },
            {
                "name": "Abinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Abrau-Dyurso",
                "countryCode": "RU"
            },
            {
                "name": "Adler",
                "countryCode": "RU"
            },
            {
                "name": "Afipskiy",
                "countryCode": "RU"
            },
            {
                "name": "Agoy",
                "countryCode": "RU"
            },
            {
                "name": "Agronom",
                "countryCode": "RU"
            },
            {
                "name": "Akhtanizovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Akhtyrskiy",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Anapa",
                "countryCode": "RU"
            },
            {
                "name": "Anapskaya",
                "countryCode": "RU"
            },
            {
                "name": "Anastasiyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Apsheronsk",
                "countryCode": "RU"
            },
            {
                "name": "Arkhipo-Osipovka",
                "countryCode": "RU"
            },
            {
                "name": "Armavir",
                "countryCode": "RU"
            },
            {
                "name": "Atamanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Azovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Baturinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Glina",
                "countryCode": "RU"
            },
            {
                "name": "Belorechensk",
                "countryCode": "RU"
            },
            {
                "name": "Belorechenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belozërnyy",
                "countryCode": "RU"
            },
            {
                "name": "Berezanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Besleneyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Besskorbnaya",
                "countryCode": "RU"
            },
            {
                "name": "Blagovetschenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Bryukhovetskaya",
                "countryCode": "RU"
            },
            {
                "name": "Chamlykskaya",
                "countryCode": "RU"
            },
            {
                "name": "Chelbasskaya",
                "countryCode": "RU"
            },
            {
                "name": "Chernomorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Chernoyerkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Dagomys",
                "countryCode": "RU"
            },
            {
                "name": "Dinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Divnomorskoye",
                "countryCode": "RU"
            },
            {
                "name": "Dmitriyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Dneprovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Dolzhanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Dvubratskiy",
                "countryCode": "RU"
            },
            {
                "name": "Dzhiginka",
                "countryCode": "RU"
            },
            {
                "name": "Dzhubga",
                "countryCode": "RU"
            },
            {
                "name": "Fastovetskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gayduk",
                "countryCode": "RU"
            },
            {
                "name": "Gelendzhik",
                "countryCode": "RU"
            },
            {
                "name": "Girey",
                "countryCode": "RU"
            },
            {
                "name": "Glafirovka",
                "countryCode": "RU"
            },
            {
                "name": "Glubokiy",
                "countryCode": "RU"
            },
            {
                "name": "Golubitskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gornoye Loo",
                "countryCode": "RU"
            },
            {
                "name": "Goryachiy Klyuch",
                "countryCode": "RU"
            },
            {
                "name": "Gostagayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Grivenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gubskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gul’kevichi",
                "countryCode": "RU"
            },
            {
                "name": "Il’ich",
                "countryCode": "RU"
            },
            {
                "name": "Il’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Irkliyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kabardinka",
                "countryCode": "RU"
            },
            {
                "name": "Kalininskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kalnibolotskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kamyshevatskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kanelovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kanevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kavkazskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kazanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Khadyzhensk",
                "countryCode": "RU"
            },
            {
                "name": "Kholmskiy",
                "countryCode": "RU"
            },
            {
                "name": "Khosta",
                "countryCode": "RU"
            },
            {
                "name": "Kislyakovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kiyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Konokovo",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kontenko",
                "countryCode": "RU"
            },
            {
                "name": "Kopanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Korenovsk",
                "countryCode": "RU"
            },
            {
                "name": "Korzhevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kovalevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeyskaya",
                "countryCode": "RU"
            },
            {
                "name": "Krasnodar",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye",
                "countryCode": "RU"
            },
            {
                "name": "Kropotkin",
                "countryCode": "RU"
            },
            {
                "name": "Krylovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Krylovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krymsk",
                "countryCode": "RU"
            },
            {
                "name": "Krymskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kubanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kuchugury",
                "countryCode": "RU"
            },
            {
                "name": "Kudepsta",
                "countryCode": "RU"
            },
            {
                "name": "Kurganinsk",
                "countryCode": "RU"
            },
            {
                "name": "Kushchëvskaya",
                "countryCode": "RU"
            },
            {
                "name": "Labinsk",
                "countryCode": "RU"
            },
            {
                "name": "Ladozhskaya",
                "countryCode": "RU"
            },
            {
                "name": "Lazarevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Lenina",
                "countryCode": "RU"
            },
            {
                "name": "Leningradskaya",
                "countryCode": "RU"
            },
            {
                "name": "Lermontovo",
                "countryCode": "RU"
            },
            {
                "name": "Lovlinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Maykopskoye",
                "countryCode": "RU"
            },
            {
                "name": "Medvedovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Mirskoy",
                "countryCode": "RU"
            },
            {
                "name": "Mostovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mostovskoy",
                "countryCode": "RU"
            },
            {
                "name": "Myskhako",
                "countryCode": "RU"
            },
            {
                "name": "Nebug",
                "countryCode": "RU"
            },
            {
                "name": "Neftegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoalekseyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novobeysugskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoderevyankovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novodmitriyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novodzhereliyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novokorsunskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novokubansk",
                "countryCode": "RU"
            },
            {
                "name": "Novokubanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novolabinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoleushkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novomalorossiyskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novomikhaylovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novominskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novomyshastovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoplatnirovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novopokrovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novorossiysk",
                "countryCode": "RU"
            },
            {
                "name": "Novorozhdestvenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoshcherbinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novotitarovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novoukrainskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novoukrainskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novovelichkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ol’ginka",
                "countryCode": "RU"
            },
            {
                "name": "Ol’ginskaya",
                "countryCode": "RU"
            },
            {
                "name": "Orël-Izumrud",
                "countryCode": "RU"
            },
            {
                "name": "Otradnaya",
                "countryCode": "RU"
            },
            {
                "name": "Otrado-Kubanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Parkovyy",
                "countryCode": "RU"
            },
            {
                "name": "Pashkovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Peredovaya",
                "countryCode": "RU"
            },
            {
                "name": "Perepravnaya",
                "countryCode": "RU"
            },
            {
                "name": "Pereyaslovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Petropavlovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Petrovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Plastunovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Platnirovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Poputnaya",
                "countryCode": "RU"
            },
            {
                "name": "Prikubanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Primorsko-Akhtarsk",
                "countryCode": "RU"
            },
            {
                "name": "Prochnookopskaya",
                "countryCode": "RU"
            },
            {
                "name": "Psebay",
                "countryCode": "RU"
            },
            {
                "name": "Pshada",
                "countryCode": "RU"
            },
            {
                "name": "Pshekhskaya",
                "countryCode": "RU"
            },
            {
                "name": "Rayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Rodnikovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Rogovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ryazanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Saratovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Severskaya",
                "countryCode": "RU"
            },
            {
                "name": "Shabel’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Shedok",
                "countryCode": "RU"
            },
            {
                "name": "Shepsi",
                "countryCode": "RU"
            },
            {
                "name": "Shirochanka",
                "countryCode": "RU"
            },
            {
                "name": "Shkurinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Slavyansk-na-Kubani",
                "countryCode": "RU"
            },
            {
                "name": "Slavyansky rayon",
                "countryCode": "RU"
            },
            {
                "name": "Smolenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Sochi",
                "countryCode": "RU"
            },
            {
                "name": "Sokolovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskaya",
                "countryCode": "RU"
            },
            {
                "name": "Sovkhoznyy",
                "countryCode": "RU"
            },
            {
                "name": "Spokoynaya",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Stanitsa",
                "countryCode": "RU"
            },
            {
                "name": "Staroderevyankovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Starodzhereliyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Starokorsunskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staroleushkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Starominskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staromyshastovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staronizhestebliyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staroshcherbinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Starotitarovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Starovelichkovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Strelka",
                "countryCode": "RU"
            },
            {
                "name": "Sukko",
                "countryCode": "RU"
            },
            {
                "name": "Taman’",
                "countryCode": "RU"
            },
            {
                "name": "Tbilisskaya",
                "countryCode": "RU"
            },
            {
                "name": "Temirgoyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Temizhbekskaya",
                "countryCode": "RU"
            },
            {
                "name": "Temruksky rayon",
                "countryCode": "RU"
            },
            {
                "name": "Temryuk",
                "countryCode": "RU"
            },
            {
                "name": "Ternevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Tikhoretsk",
                "countryCode": "RU"
            },
            {
                "name": "Tikhoretskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Timashëvsk",
                "countryCode": "RU"
            },
            {
                "name": "Troitskaya",
                "countryCode": "RU"
            },
            {
                "name": "Trudobelikovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tsibanobalka",
                "countryCode": "RU"
            },
            {
                "name": "Tuapse",
                "countryCode": "RU"
            },
            {
                "name": "Tuapsinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Udobnaya",
                "countryCode": "RU"
            },
            {
                "name": "Upornaya",
                "countryCode": "RU"
            },
            {
                "name": "Uspenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Uspenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Labinsk",
                "countryCode": "RU"
            },
            {
                "name": "Vardane",
                "countryCode": "RU"
            },
            {
                "name": "Varenikovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Vasyurinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Velikovechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Ventsy",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnebakanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vinogradnyy",
                "countryCode": "RU"
            },
            {
                "name": "Vityazevo",
                "countryCode": "RU"
            },
            {
                "name": "Vladimirskaya",
                "countryCode": "RU"
            },
            {
                "name": "Vorontsovka",
                "countryCode": "RU"
            },
            {
                "name": "Voznesenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Vyselki",
                "countryCode": "RU"
            },
            {
                "name": "Vyselkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vyshestebliyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Vysokoye",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavskaya",
                "countryCode": "RU"
            },
            {
                "name": "Yasenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Yelizavetinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Yeysk",
                "countryCode": "RU"
            },
            {
                "name": "Yeyskoye Ukrepleniye",
                "countryCode": "RU"
            },
            {
                "name": "Yurovka",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zavetnyy",
                "countryCode": "RU"
            },
            {
                "name": "Abagur",
                "countryCode": "RU"
            },
            {
                "name": "Anzhero-Sudzhensk",
                "countryCode": "RU"
            },
            {
                "name": "Artyshta",
                "countryCode": "RU"
            },
            {
                "name": "Bachatskiy",
                "countryCode": "RU"
            },
            {
                "name": "Barzas",
                "countryCode": "RU"
            },
            {
                "name": "Belogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Belovo",
                "countryCode": "RU"
            },
            {
                "name": "Berëzovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Borovoy",
                "countryCode": "RU"
            },
            {
                "name": "Chistogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gramoteino",
                "countryCode": "RU"
            },
            {
                "name": "Gur’yevsk",
                "countryCode": "RU"
            },
            {
                "name": "Inskoy",
                "countryCode": "RU"
            },
            {
                "name": "Itatskiy",
                "countryCode": "RU"
            },
            {
                "name": "Izhmorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kaltan",
                "countryCode": "RU"
            },
            {
                "name": "Karagayla",
                "countryCode": "RU"
            },
            {
                "name": "Kaz",
                "countryCode": "RU"
            },
            {
                "name": "Kedrovka",
                "countryCode": "RU"
            },
            {
                "name": "Kemerovo",
                "countryCode": "RU"
            },
            {
                "name": "Kiselëvsk",
                "countryCode": "RU"
            },
            {
                "name": "Krapivinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnobrodskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuzedeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Leninsk-Kuznetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leninsk-Kuznetsky",
                "countryCode": "RU"
            },
            {
                "name": "Listvyagi",
                "countryCode": "RU"
            },
            {
                "name": "Mariinsk",
                "countryCode": "RU"
            },
            {
                "name": "Mezhdurechensk",
                "countryCode": "RU"
            },
            {
                "name": "Mundybash",
                "countryCode": "RU"
            },
            {
                "name": "Myski",
                "countryCode": "RU"
            },
            {
                "name": "Nikitinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novokuznetsk",
                "countryCode": "RU"
            },
            {
                "name": "Novokuznetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novopesterevo",
                "countryCode": "RU"
            },
            {
                "name": "Osinniki",
                "countryCode": "RU"
            },
            {
                "name": "Pioner",
                "countryCode": "RU"
            },
            {
                "name": "Plotnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Polysayevo",
                "countryCode": "RU"
            },
            {
                "name": "Pritomskiy",
                "countryCode": "RU"
            },
            {
                "name": "Prokop’yevsk",
                "countryCode": "RU"
            },
            {
                "name": "Prokop’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Promyshlennaya",
                "countryCode": "RU"
            },
            {
                "name": "Promyshlennovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Rudnichnyy",
                "countryCode": "RU"
            },
            {
                "name": "Salair",
                "countryCode": "RU"
            },
            {
                "name": "Sheregesh",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovka",
                "countryCode": "RU"
            },
            {
                "name": "Spassk",
                "countryCode": "RU"
            },
            {
                "name": "Starobachaty",
                "countryCode": "RU"
            },
            {
                "name": "Staropesterevo",
                "countryCode": "RU"
            },
            {
                "name": "Suslovo",
                "countryCode": "RU"
            },
            {
                "name": "Tashtagol",
                "countryCode": "RU"
            },
            {
                "name": "Tashtagol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tayga",
                "countryCode": "RU"
            },
            {
                "name": "Tayzhina",
                "countryCode": "RU"
            },
            {
                "name": "Temirtau",
                "countryCode": "RU"
            },
            {
                "name": "Tisul’",
                "countryCode": "RU"
            },
            {
                "name": "Topki",
                "countryCode": "RU"
            },
            {
                "name": "Trudarmeyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tyazhinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ursk",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Chebula",
                "countryCode": "RU"
            },
            {
                "name": "Yagunovo",
                "countryCode": "RU"
            },
            {
                "name": "Yagunovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yashkino",
                "countryCode": "RU"
            },
            {
                "name": "Yaya",
                "countryCode": "RU"
            },
            {
                "name": "Yurga",
                "countryCode": "RU"
            },
            {
                "name": "Yurginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zelenogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Bagrationovsk",
                "countryCode": "RU"
            },
            {
                "name": "Baltiysk",
                "countryCode": "RU"
            },
            {
                "name": "Bol'shoe Isakovo",
                "countryCode": "RU"
            },
            {
                "name": "Chernyakhovsk",
                "countryCode": "RU"
            },
            {
                "name": "Donskoye",
                "countryCode": "RU"
            },
            {
                "name": "Gur’yevsk",
                "countryCode": "RU"
            },
            {
                "name": "Gusev",
                "countryCode": "RU"
            },
            {
                "name": "Gvardeysk",
                "countryCode": "RU"
            },
            {
                "name": "Kaliningrad",
                "countryCode": "RU"
            },
            {
                "name": "Khrabrovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoznamensk",
                "countryCode": "RU"
            },
            {
                "name": "Ladushkin",
                "countryCode": "RU"
            },
            {
                "name": "Maloye Isakovo",
                "countryCode": "RU"
            },
            {
                "name": "Mamonovo",
                "countryCode": "RU"
            },
            {
                "name": "Neman",
                "countryCode": "RU"
            },
            {
                "name": "Nemanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nesterov",
                "countryCode": "RU"
            },
            {
                "name": "Nesterovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Otradnoye",
                "countryCode": "RU"
            },
            {
                "name": "Ozerskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ozërsk",
                "countryCode": "RU"
            },
            {
                "name": "Pionerskiy",
                "countryCode": "RU"
            },
            {
                "name": "Polessk",
                "countryCode": "RU"
            },
            {
                "name": "Pravdinsk",
                "countryCode": "RU"
            },
            {
                "name": "Primorsk",
                "countryCode": "RU"
            },
            {
                "name": "Slavsk",
                "countryCode": "RU"
            },
            {
                "name": "Slavskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sovetsk",
                "countryCode": "RU"
            },
            {
                "name": "Svetlogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Svetlyy",
                "countryCode": "RU"
            },
            {
                "name": "Volochayevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Vzmorye",
                "countryCode": "RU"
            },
            {
                "name": "Yantarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zelenogradsk",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznodorozhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Znamensk",
                "countryCode": "RU"
            },
            {
                "name": "Belozërskoye",
                "countryCode": "RU"
            },
            {
                "name": "Dalmatovo",
                "countryCode": "RU"
            },
            {
                "name": "Glyadyanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ikovka",
                "countryCode": "RU"
            },
            {
                "name": "Kataysk",
                "countryCode": "RU"
            },
            {
                "name": "Ketovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Oktyabr’",
                "countryCode": "RU"
            },
            {
                "name": "Kurgan",
                "countryCode": "RU"
            },
            {
                "name": "Kurtamysh",
                "countryCode": "RU"
            },
            {
                "name": "Lebyazh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Lebyazh’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lesnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Makushino",
                "countryCode": "RU"
            },
            {
                "name": "Mishkino",
                "countryCode": "RU"
            },
            {
                "name": "Mokrousovo",
                "countryCode": "RU"
            },
            {
                "name": "Mokrousovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Polovinnoye",
                "countryCode": "RU"
            },
            {
                "name": "Prosvet",
                "countryCode": "RU"
            },
            {
                "name": "Safakulevo",
                "countryCode": "RU"
            },
            {
                "name": "Safakulevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shadrinsk",
                "countryCode": "RU"
            },
            {
                "name": "Shatrovo",
                "countryCode": "RU"
            },
            {
                "name": "Shumikha",
                "countryCode": "RU"
            },
            {
                "name": "Shumikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tselinnoye",
                "countryCode": "RU"
            },
            {
                "name": "Vargashi",
                "countryCode": "RU"
            },
            {
                "name": "Vargashinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vvedenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yurgamysh",
                "countryCode": "RU"
            },
            {
                "name": "Zverinogolovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Amursk",
                "countryCode": "RU"
            },
            {
                "name": "Ayan",
                "countryCode": "RU"
            },
            {
                "name": "Berëzovyy",
                "countryCode": "RU"
            },
            {
                "name": "Bikin",
                "countryCode": "RU"
            },
            {
                "name": "Bikinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chegdomyn",
                "countryCode": "RU"
            },
            {
                "name": "Chumikan",
                "countryCode": "RU"
            },
            {
                "name": "De-Kastri",
                "countryCode": "RU"
            },
            {
                "name": "Gorin",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Poliny Osipenko",
                "countryCode": "RU"
            },
            {
                "name": "Khabarovsk",
                "countryCode": "RU"
            },
            {
                "name": "Khabarovsk Vtoroy",
                "countryCode": "RU"
            },
            {
                "name": "Khor",
                "countryCode": "RU"
            },
            {
                "name": "Khurba",
                "countryCode": "RU"
            },
            {
                "name": "Knyaze-Volkonskoye",
                "countryCode": "RU"
            },
            {
                "name": "Komsomolsk-on-Amur",
                "countryCode": "RU"
            },
            {
                "name": "Korfovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lazarev",
                "countryCode": "RU"
            },
            {
                "name": "Lermontovka",
                "countryCode": "RU"
            },
            {
                "name": "Litovko",
                "countryCode": "RU"
            },
            {
                "name": "Lososina",
                "countryCode": "RU"
            },
            {
                "name": "Mago",
                "countryCode": "RU"
            },
            {
                "name": "Mayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mnogovershinnyy",
                "countryCode": "RU"
            },
            {
                "name": "Mukhen",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovka",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevsk-on-Amure",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Urgal",
                "countryCode": "RU"
            },
            {
                "name": "Okhotsk",
                "countryCode": "RU"
            },
            {
                "name": "Selikhino",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskaya Gavan’",
                "countryCode": "RU"
            },
            {
                "name": "Topolëvo",
                "countryCode": "RU"
            },
            {
                "name": "Tyrma",
                "countryCode": "RU"
            },
            {
                "name": "Vanino",
                "countryCode": "RU"
            },
            {
                "name": "Vyazemskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vysokogornyy",
                "countryCode": "RU"
            },
            {
                "name": "Zavety Il’icha",
                "countryCode": "RU"
            },
            {
                "name": "Agirish",
                "countryCode": "RU"
            },
            {
                "name": "Andra",
                "countryCode": "RU"
            },
            {
                "name": "Barsovo",
                "countryCode": "RU"
            },
            {
                "name": "Beloyarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Berëzovo",
                "countryCode": "RU"
            },
            {
                "name": "Fedorovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gornopravdinsk",
                "countryCode": "RU"
            },
            {
                "name": "Igrim",
                "countryCode": "RU"
            },
            {
                "name": "Izluchinsk",
                "countryCode": "RU"
            },
            {
                "name": "Khanty-Mansiysk",
                "countryCode": "RU"
            },
            {
                "name": "Khulimsunt",
                "countryCode": "RU"
            },
            {
                "name": "Kogalym",
                "countryCode": "RU"
            },
            {
                "name": "Kommunisticheskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kondinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuminskiy",
                "countryCode": "RU"
            },
            {
                "name": "Langepas",
                "countryCode": "RU"
            },
            {
                "name": "Lokosovo",
                "countryCode": "RU"
            },
            {
                "name": "Lyantor",
                "countryCode": "RU"
            },
            {
                "name": "Malinovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Megion",
                "countryCode": "RU"
            },
            {
                "name": "Mezhdurechenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mortka",
                "countryCode": "RU"
            },
            {
                "name": "Nefteyugansk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnesortymskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnevartovsk",
                "countryCode": "RU"
            },
            {
                "name": "Novoagansk",
                "countryCode": "RU"
            },
            {
                "name": "Nyagan",
                "countryCode": "RU"
            },
            {
                "name": "Pionerskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pokachi",
                "countryCode": "RU"
            },
            {
                "name": "Poykovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Priob’ye",
                "countryCode": "RU"
            },
            {
                "name": "Pyt-Yakh",
                "countryCode": "RU"
            },
            {
                "name": "Raduzhny",
                "countryCode": "RU"
            },
            {
                "name": "Russkinskiye",
                "countryCode": "RU"
            },
            {
                "name": "Salym",
                "countryCode": "RU"
            },
            {
                "name": "Saranpaul’",
                "countryCode": "RU"
            },
            {
                "name": "Sherkaly",
                "countryCode": "RU"
            },
            {
                "name": "Singapay",
                "countryCode": "RU"
            },
            {
                "name": "Solnechniy",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Surgut",
                "countryCode": "RU"
            },
            {
                "name": "Uray",
                "countryCode": "RU"
            },
            {
                "name": "Yugorsk",
                "countryCode": "RU"
            },
            {
                "name": "Zelenoborsk",
                "countryCode": "RU"
            },
            {
                "name": "Afanas’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arbazh",
                "countryCode": "RU"
            },
            {
                "name": "Arkul’",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Kholunitsa",
                "countryCode": "RU"
            },
            {
                "name": "Belorechensk",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chërnaya Kholunitsa",
                "countryCode": "RU"
            },
            {
                "name": "Darovskoy",
                "countryCode": "RU"
            },
            {
                "name": "Dem’yanovo",
                "countryCode": "RU"
            },
            {
                "name": "Dubrovka",
                "countryCode": "RU"
            },
            {
                "name": "Falënki",
                "countryCode": "RU"
            },
            {
                "name": "Falënskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kiknur",
                "countryCode": "RU"
            },
            {
                "name": "Kiknurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kil’mez’",
                "countryCode": "RU"
            },
            {
                "name": "Kirov",
                "countryCode": "RU"
            },
            {
                "name": "Kirovo-Chepetsk",
                "countryCode": "RU"
            },
            {
                "name": "Kirovo-Chepetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirs",
                "countryCode": "RU"
            },
            {
                "name": "Kosino",
                "countryCode": "RU"
            },
            {
                "name": "Kostino",
                "countryCode": "RU"
            },
            {
                "name": "Kotel’nich",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Kumëny",
                "countryCode": "RU"
            },
            {
                "name": "Lal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Leninskoye",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy",
                "countryCode": "RU"
            },
            {
                "name": "Lesnyye Polyany",
                "countryCode": "RU"
            },
            {
                "name": "Luza",
                "countryCode": "RU"
            },
            {
                "name": "Luzskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyangasovo",
                "countryCode": "RU"
            },
            {
                "name": "Malmyzh",
                "countryCode": "RU"
            },
            {
                "name": "Malmyzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Maromitsa",
                "countryCode": "RU"
            },
            {
                "name": "Medvedok",
                "countryCode": "RU"
            },
            {
                "name": "Mirnyy",
                "countryCode": "RU"
            },
            {
                "name": "Murashi",
                "countryCode": "RU"
            },
            {
                "name": "Murashinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Murygino",
                "countryCode": "RU"
            },
            {
                "name": "Nagorsk",
                "countryCode": "RU"
            },
            {
                "name": "Nagorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nema",
                "countryCode": "RU"
            },
            {
                "name": "Nemskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneivkino",
                "countryCode": "RU"
            },
            {
                "name": "Nolinsk",
                "countryCode": "RU"
            },
            {
                "name": "Nolinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Omutninsk",
                "countryCode": "RU"
            },
            {
                "name": "Oparino",
                "countryCode": "RU"
            },
            {
                "name": "Orichevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orichi",
                "countryCode": "RU"
            },
            {
                "name": "Orlov",
                "countryCode": "RU"
            },
            {
                "name": "Orlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pasegovo",
                "countryCode": "RU"
            },
            {
                "name": "Peskovka",
                "countryCode": "RU"
            },
            {
                "name": "Pinyug",
                "countryCode": "RU"
            },
            {
                "name": "Pizhanka",
                "countryCode": "RU"
            },
            {
                "name": "Pizhanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Podosinovets",
                "countryCode": "RU"
            },
            {
                "name": "Podosinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Raduzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Rudnichnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sanchursk",
                "countryCode": "RU"
            },
            {
                "name": "Sanchurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shabalinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Slobodskoy",
                "countryCode": "RU"
            },
            {
                "name": "Slobodskoy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovka",
                "countryCode": "RU"
            },
            {
                "name": "Sovetsk",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sozimskiy",
                "countryCode": "RU"
            },
            {
                "name": "Strizhi",
                "countryCode": "RU"
            },
            {
                "name": "Stulovo",
                "countryCode": "RU"
            },
            {
                "name": "Suna",
                "countryCode": "RU"
            },
            {
                "name": "Sunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Svecha",
                "countryCode": "RU"
            },
            {
                "name": "Svetlopolyansk",
                "countryCode": "RU"
            },
            {
                "name": "Torfyanoy",
                "countryCode": "RU"
            },
            {
                "name": "Tuzha",
                "countryCode": "RU"
            },
            {
                "name": "Uni",
                "countryCode": "RU"
            },
            {
                "name": "Uninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urzhum",
                "countryCode": "RU"
            },
            {
                "name": "Vakhrushi",
                "countryCode": "RU"
            },
            {
                "name": "Verkhoshizhem’ye",
                "countryCode": "RU"
            },
            {
                "name": "Vostochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Vyatskiye Polyany",
                "countryCode": "RU"
            },
            {
                "name": "Yaransk",
                "countryCode": "RU"
            },
            {
                "name": "Yaranskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yur’yanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zuyevka",
                "countryCode": "RU"
            },
            {
                "name": "Abakan",
                "countryCode": "RU"
            },
            {
                "name": "Abakan Gorod",
                "countryCode": "RU"
            },
            {
                "name": "Abaza",
                "countryCode": "RU"
            },
            {
                "name": "Altayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Askiz",
                "countryCode": "RU"
            },
            {
                "name": "Belyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Beya",
                "countryCode": "RU"
            },
            {
                "name": "Biskamzha",
                "countryCode": "RU"
            },
            {
                "name": "Bograd",
                "countryCode": "RU"
            },
            {
                "name": "Bogradskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chernogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Cherëmushki",
                "countryCode": "RU"
            },
            {
                "name": "Kommunar",
                "countryCode": "RU"
            },
            {
                "name": "Mayna",
                "countryCode": "RU"
            },
            {
                "name": "Podsineye",
                "countryCode": "RU"
            },
            {
                "name": "Sayanogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Shira",
                "countryCode": "RU"
            },
            {
                "name": "Sorsk",
                "countryCode": "RU"
            },
            {
                "name": "Tashtyp",
                "countryCode": "RU"
            },
            {
                "name": "Tuim",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Abakan",
                "countryCode": "RU"
            },
            {
                "name": "Vershina Tei",
                "countryCode": "RU"
            },
            {
                "name": "Arshan’",
                "countryCode": "RU"
            },
            {
                "name": "Elista",
                "countryCode": "RU"
            },
            {
                "name": "Gorodoviki",
                "countryCode": "RU"
            },
            {
                "name": "Gorodovikovsk",
                "countryCode": "RU"
            },
            {
                "name": "Iki-Burul",
                "countryCode": "RU"
            },
            {
                "name": "Iki-Burul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kaspiyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Komsomol’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Malyye Derbety",
                "countryCode": "RU"
            },
            {
                "name": "Priyutnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sadovoye",
                "countryCode": "RU"
            },
            {
                "name": "Sarpinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskoye",
                "countryCode": "RU"
            },
            {
                "name": "Troitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tsagan Aman",
                "countryCode": "RU"
            },
            {
                "name": "Yashalta",
                "countryCode": "RU"
            },
            {
                "name": "Yashaltinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yashkul’",
                "countryCode": "RU"
            },
            {
                "name": "Babynino",
                "countryCode": "RU"
            },
            {
                "name": "Babyninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Balabanovo",
                "countryCode": "RU"
            },
            {
                "name": "Baryatino",
                "countryCode": "RU"
            },
            {
                "name": "Baryatinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belousovo",
                "countryCode": "RU"
            },
            {
                "name": "Betlitsa",
                "countryCode": "RU"
            },
            {
                "name": "Borovsk",
                "countryCode": "RU"
            },
            {
                "name": "Borovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Deshovki",
                "countryCode": "RU"
            },
            {
                "name": "Detchino",
                "countryCode": "RU"
            },
            {
                "name": "Duminichi",
                "countryCode": "RU"
            },
            {
                "name": "Duminichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dvortsy",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ferzikovo",
                "countryCode": "RU"
            },
            {
                "name": "Ferzikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Iznoski",
                "countryCode": "RU"
            },
            {
                "name": "Iznoskovskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kaluga",
                "countryCode": "RU"
            },
            {
                "name": "Khvastovichi",
                "countryCode": "RU"
            },
            {
                "name": "Khvastovichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirov",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kondrovo",
                "countryCode": "RU"
            },
            {
                "name": "Kozel’sk",
                "countryCode": "RU"
            },
            {
                "name": "Kozel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kremenki",
                "countryCode": "RU"
            },
            {
                "name": "Kudinovo",
                "countryCode": "RU"
            },
            {
                "name": "Kurovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuybyshevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyudinovo",
                "countryCode": "RU"
            },
            {
                "name": "Lyudinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Maloyaroslavets",
                "countryCode": "RU"
            },
            {
                "name": "Maloyaroslavetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Medynskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Medyn’",
                "countryCode": "RU"
            },
            {
                "name": "Meshchovsk",
                "countryCode": "RU"
            },
            {
                "name": "Meshchovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mosal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Mosal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mstikhino",
                "countryCode": "RU"
            },
            {
                "name": "Myatlevo",
                "countryCode": "RU"
            },
            {
                "name": "Nikola-Lenivets",
                "countryCode": "RU"
            },
            {
                "name": "Obninsk",
                "countryCode": "RU"
            },
            {
                "name": "Peremyshl’",
                "countryCode": "RU"
            },
            {
                "name": "Peremyshl’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Polotnyanyy Zavod",
                "countryCode": "RU"
            },
            {
                "name": "Pyatovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Seredeyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sosenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Spas-Demensk",
                "countryCode": "RU"
            },
            {
                "name": "Spas-Demenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sukhinichi",
                "countryCode": "RU"
            },
            {
                "name": "Sukhinichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tarusa",
                "countryCode": "RU"
            },
            {
                "name": "Tarusskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tovarkovo",
                "countryCode": "RU"
            },
            {
                "name": "Ul’yanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vorotynsk",
                "countryCode": "RU"
            },
            {
                "name": "Vysokinichi",
                "countryCode": "RU"
            },
            {
                "name": "Yermolino",
                "countryCode": "RU"
            },
            {
                "name": "Yukhnov",
                "countryCode": "RU"
            },
            {
                "name": "Yukhnovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zarech’ye",
                "countryCode": "RU"
            },
            {
                "name": "Zhiletovo",
                "countryCode": "RU"
            },
            {
                "name": "Zhizdra",
                "countryCode": "RU"
            },
            {
                "name": "Zhizdrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovo",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aykino",
                "countryCode": "RU"
            },
            {
                "name": "Blagoyevo",
                "countryCode": "RU"
            },
            {
                "name": "Borovoy",
                "countryCode": "RU"
            },
            {
                "name": "Ezhva",
                "countryCode": "RU"
            },
            {
                "name": "Inta",
                "countryCode": "RU"
            },
            {
                "name": "Izhma",
                "countryCode": "RU"
            },
            {
                "name": "Knyazhpogostskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kortkeros",
                "countryCode": "RU"
            },
            {
                "name": "Koslan",
                "countryCode": "RU"
            },
            {
                "name": "Koygorodok",
                "countryCode": "RU"
            },
            {
                "name": "Koygorodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kozhva",
                "countryCode": "RU"
            },
            {
                "name": "Letka",
                "countryCode": "RU"
            },
            {
                "name": "Mezhdurechensk",
                "countryCode": "RU"
            },
            {
                "name": "Mikun’",
                "countryCode": "RU"
            },
            {
                "name": "Mordino",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Odes",
                "countryCode": "RU"
            },
            {
                "name": "Parma",
                "countryCode": "RU"
            },
            {
                "name": "Pechora",
                "countryCode": "RU"
            },
            {
                "name": "Priluzskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Promyshlennyy",
                "countryCode": "RU"
            },
            {
                "name": "Puteyets",
                "countryCode": "RU"
            },
            {
                "name": "Sedkyrkeshch",
                "countryCode": "RU"
            },
            {
                "name": "Shudayag",
                "countryCode": "RU"
            },
            {
                "name": "Sindor",
                "countryCode": "RU"
            },
            {
                "name": "Sosnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Syktyvkar",
                "countryCode": "RU"
            },
            {
                "name": "Synya",
                "countryCode": "RU"
            },
            {
                "name": "Troitsko-Pechorsk",
                "countryCode": "RU"
            },
            {
                "name": "Troitsko-Pechorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tsementnozavodskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ukhta",
                "countryCode": "RU"
            },
            {
                "name": "Usinsk",
                "countryCode": "RU"
            },
            {
                "name": "Usogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Ust-Tsilma",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kulom",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Tsilemskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Inta",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Maksakovka",
                "countryCode": "RU"
            },
            {
                "name": "Vizinga",
                "countryCode": "RU"
            },
            {
                "name": "Vodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Vorgashor",
                "countryCode": "RU"
            },
            {
                "name": "Vorkuta",
                "countryCode": "RU"
            },
            {
                "name": "Voyvozh",
                "countryCode": "RU"
            },
            {
                "name": "Vuktyl",
                "countryCode": "RU"
            },
            {
                "name": "Yarega",
                "countryCode": "RU"
            },
            {
                "name": "Yemva",
                "countryCode": "RU"
            },
            {
                "name": "Zapolyarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zelenets",
                "countryCode": "RU"
            },
            {
                "name": "Zheshart",
                "countryCode": "RU"
            },
            {
                "name": "Antropovo",
                "countryCode": "RU"
            },
            {
                "name": "Antropovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bogovarovo",
                "countryCode": "RU"
            },
            {
                "name": "Buy",
                "countryCode": "RU"
            },
            {
                "name": "Buyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chistyye Bory",
                "countryCode": "RU"
            },
            {
                "name": "Chukhloma",
                "countryCode": "RU"
            },
            {
                "name": "Galich",
                "countryCode": "RU"
            },
            {
                "name": "Galichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Georgiyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kadyy",
                "countryCode": "RU"
            },
            {
                "name": "Kadyyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kologriv",
                "countryCode": "RU"
            },
            {
                "name": "Kosmynino",
                "countryCode": "RU"
            },
            {
                "name": "Kostroma",
                "countryCode": "RU"
            },
            {
                "name": "Kostromskoy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnosel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye-na-Volge",
                "countryCode": "RU"
            },
            {
                "name": "Makar’yev",
                "countryCode": "RU"
            },
            {
                "name": "Manturovo",
                "countryCode": "RU"
            },
            {
                "name": "Manturovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nerekhta",
                "countryCode": "RU"
            },
            {
                "name": "Nerekhtskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Neya",
                "countryCode": "RU"
            },
            {
                "name": "Neyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ostrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ostrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Parfen’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Parfen’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pavino",
                "countryCode": "RU"
            },
            {
                "name": "Ponazyrevo",
                "countryCode": "RU"
            },
            {
                "name": "Ponazyrevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pyshchug",
                "countryCode": "RU"
            },
            {
                "name": "Shar’inskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shar’ya",
                "countryCode": "RU"
            },
            {
                "name": "Soligalich",
                "countryCode": "RU"
            },
            {
                "name": "Soligalichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sudislavl’",
                "countryCode": "RU"
            },
            {
                "name": "Sudislavskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Susanino",
                "countryCode": "RU"
            },
            {
                "name": "Vetluzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vokhma",
                "countryCode": "RU"
            },
            {
                "name": "Vokhomskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Volgorechensk",
                "countryCode": "RU"
            },
            {
                "name": "Belomorsk",
                "countryCode": "RU"
            },
            {
                "name": "Borovoy",
                "countryCode": "RU"
            },
            {
                "name": "Chupa",
                "countryCode": "RU"
            },
            {
                "name": "Essoyla",
                "countryCode": "RU"
            },
            {
                "name": "Girvas",
                "countryCode": "RU"
            },
            {
                "name": "Kalevala",
                "countryCode": "RU"
            },
            {
                "name": "Kem’",
                "countryCode": "RU"
            },
            {
                "name": "Kharlu",
                "countryCode": "RU"
            },
            {
                "name": "Khelyulya",
                "countryCode": "RU"
            },
            {
                "name": "Kondopoga",
                "countryCode": "RU"
            },
            {
                "name": "Kostomuksha",
                "countryCode": "RU"
            },
            {
                "name": "Kvartsitnyy",
                "countryCode": "RU"
            },
            {
                "name": "Lakhdenpokh’ya",
                "countryCode": "RU"
            },
            {
                "name": "Ledmozero",
                "countryCode": "RU"
            },
            {
                "name": "Loukhi",
                "countryCode": "RU"
            },
            {
                "name": "Lyaskelya",
                "countryCode": "RU"
            },
            {
                "name": "Matrosy",
                "countryCode": "RU"
            },
            {
                "name": "Medvezh’yegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Muyezerskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nadvoitsy",
                "countryCode": "RU"
            },
            {
                "name": "Olonets",
                "countryCode": "RU"
            },
            {
                "name": "Petrozavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Pindushi",
                "countryCode": "RU"
            },
            {
                "name": "Pitkyaranta",
                "countryCode": "RU"
            },
            {
                "name": "Pitkyarantskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Porosozero",
                "countryCode": "RU"
            },
            {
                "name": "Povenets",
                "countryCode": "RU"
            },
            {
                "name": "Prionezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pryazha",
                "countryCode": "RU"
            },
            {
                "name": "Pudozh",
                "countryCode": "RU"
            },
            {
                "name": "Pyaozerskiy",
                "countryCode": "RU"
            },
            {
                "name": "Rabocheostrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Ruskeala",
                "countryCode": "RU"
            },
            {
                "name": "Salmi",
                "countryCode": "RU"
            },
            {
                "name": "Segezha",
                "countryCode": "RU"
            },
            {
                "name": "Shal’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Sheltozero",
                "countryCode": "RU"
            },
            {
                "name": "Shun’ga",
                "countryCode": "RU"
            },
            {
                "name": "Shuya",
                "countryCode": "RU"
            },
            {
                "name": "Sortavala",
                "countryCode": "RU"
            },
            {
                "name": "Sortaval’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sukkozero",
                "countryCode": "RU"
            },
            {
                "name": "Suoyarvi",
                "countryCode": "RU"
            },
            {
                "name": "Suoyarvskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vyartsilya",
                "countryCode": "RU"
            },
            {
                "name": "Yanishpole",
                "countryCode": "RU"
            },
            {
                "name": "Yanis’yarvi",
                "countryCode": "RU"
            },
            {
                "name": "Yushkozero",
                "countryCode": "RU"
            },
            {
                "name": "Belaya",
                "countryCode": "RU"
            },
            {
                "name": "Cheremisinovo",
                "countryCode": "RU"
            },
            {
                "name": "Cheremisinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chernitsyno",
                "countryCode": "RU"
            },
            {
                "name": "Fatezh",
                "countryCode": "RU"
            },
            {
                "name": "Fatezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Glushkovo",
                "countryCode": "RU"
            },
            {
                "name": "Gorshechenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gorshechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Ivanino",
                "countryCode": "RU"
            },
            {
                "name": "Kastorenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kastornoye",
                "countryCode": "RU"
            },
            {
                "name": "Khomutovka",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Konyshevka",
                "countryCode": "RU"
            },
            {
                "name": "Korenëvo",
                "countryCode": "RU"
            },
            {
                "name": "Kshenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kurchatov",
                "countryCode": "RU"
            },
            {
                "name": "Kursk",
                "countryCode": "RU"
            },
            {
                "name": "Kurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "L’govskiy",
                "countryCode": "RU"
            },
            {
                "name": "L’govskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Manturovo",
                "countryCode": "RU"
            },
            {
                "name": "Medvenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovka",
                "countryCode": "RU"
            },
            {
                "name": "Oboyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oboyan’",
                "countryCode": "RU"
            },
            {
                "name": "Olym",
                "countryCode": "RU"
            },
            {
                "name": "Pervoavgustovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ponyri Vtoryye",
                "countryCode": "RU"
            },
            {
                "name": "Pristenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pristen’",
                "countryCode": "RU"
            },
            {
                "name": "Pryamitsyno",
                "countryCode": "RU"
            },
            {
                "name": "Ryl’sk",
                "countryCode": "RU"
            },
            {
                "name": "Shchigry",
                "countryCode": "RU"
            },
            {
                "name": "Solntsevo",
                "countryCode": "RU"
            },
            {
                "name": "Solntsevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sudzha",
                "countryCode": "RU"
            },
            {
                "name": "Tim",
                "countryCode": "RU"
            },
            {
                "name": "Voroshnëvo",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Zolotukhino",
                "countryCode": "RU"
            },
            {
                "name": "Zolotukhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aban",
                "countryCode": "RU"
            },
            {
                "name": "Abanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Achinsk",
                "countryCode": "RU"
            },
            {
                "name": "Achinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aginskoye",
                "countryCode": "RU"
            },
            {
                "name": "Balakhta",
                "countryCode": "RU"
            },
            {
                "name": "Balakhtinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Baykit",
                "countryCode": "RU"
            },
            {
                "name": "Berezovskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Berëzovka",
                "countryCode": "RU"
            },
            {
                "name": "Birilyusskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bogotol",
                "countryCode": "RU"
            },
            {
                "name": "Bogotol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Boguchanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Boguchany",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shemurtinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’sheuluyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bor",
                "countryCode": "RU"
            },
            {
                "name": "Borodino",
                "countryCode": "RU"
            },
            {
                "name": "Chunoyar",
                "countryCode": "RU"
            },
            {
                "name": "Dikson",
                "countryCode": "RU"
            },
            {
                "name": "Divnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Dubinino",
                "countryCode": "RU"
            },
            {
                "name": "Dudinka",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Evenkiyskiy District",
                "countryCode": "RU"
            },
            {
                "name": "Filimonovo",
                "countryCode": "RU"
            },
            {
                "name": "Idrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Idrinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Igarka",
                "countryCode": "RU"
            },
            {
                "name": "Ilanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ilanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Il’ichevo",
                "countryCode": "RU"
            },
            {
                "name": "Irbeyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Irbeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Irsha",
                "countryCode": "RU"
            },
            {
                "name": "Kansk",
                "countryCode": "RU"
            },
            {
                "name": "Kanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Karatuzskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kayyerkan",
                "countryCode": "RU"
            },
            {
                "name": "Kazachinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazachinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kedrovyy",
                "countryCode": "RU"
            },
            {
                "name": "Khatanga",
                "countryCode": "RU"
            },
            {
                "name": "Kodinsk",
                "countryCode": "RU"
            },
            {
                "name": "Koshurnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokamensk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoturansk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarsk",
                "countryCode": "RU"
            },
            {
                "name": "Kuragino",
                "countryCode": "RU"
            },
            {
                "name": "Lesosibirsk",
                "countryCode": "RU"
            },
            {
                "name": "Manskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Manzya",
                "countryCode": "RU"
            },
            {
                "name": "Minusinsk",
                "countryCode": "RU"
            },
            {
                "name": "Minusinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Motygino",
                "countryCode": "RU"
            },
            {
                "name": "Nazarovo",
                "countryCode": "RU"
            },
            {
                "name": "Nazarovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneingashskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Ingash",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Poyma",
                "countryCode": "RU"
            },
            {
                "name": "Norilsk",
                "countryCode": "RU"
            },
            {
                "name": "Novobirilyussy",
                "countryCode": "RU"
            },
            {
                "name": "Novochernorechenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ovsyanka",
                "countryCode": "RU"
            },
            {
                "name": "Partizanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Partizanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pirovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Predivinsk",
                "countryCode": "RU"
            },
            {
                "name": "Rassvet",
                "countryCode": "RU"
            },
            {
                "name": "Razdolinsk",
                "countryCode": "RU"
            },
            {
                "name": "Sayansk",
                "countryCode": "RU"
            },
            {
                "name": "Sayanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severo-Yeniseyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Severo-Yeniseyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shalinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sharypovo",
                "countryCode": "RU"
            },
            {
                "name": "Sharypovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shushenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shushenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Snezhnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovoborsk",
                "countryCode": "RU"
            },
            {
                "name": "Strelka",
                "countryCode": "RU"
            },
            {
                "name": "Sukhobuzimskoye",
                "countryCode": "RU"
            },
            {
                "name": "Svetlogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Talnakh",
                "countryCode": "RU"
            },
            {
                "name": "Tanzybey",
                "countryCode": "RU"
            },
            {
                "name": "Taseyevo",
                "countryCode": "RU"
            },
            {
                "name": "Taymyrsky Dolgano-Nenetsky District",
                "countryCode": "RU"
            },
            {
                "name": "Teya",
                "countryCode": "RU"
            },
            {
                "name": "Tinskoy",
                "countryCode": "RU"
            },
            {
                "name": "Tura",
                "countryCode": "RU"
            },
            {
                "name": "Turukhansk",
                "countryCode": "RU"
            },
            {
                "name": "Tyukhtet",
                "countryCode": "RU"
            },
            {
                "name": "Ural",
                "countryCode": "RU"
            },
            {
                "name": "Uyar",
                "countryCode": "RU"
            },
            {
                "name": "Uyarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uzhur",
                "countryCode": "RU"
            },
            {
                "name": "Vanavara",
                "countryCode": "RU"
            },
            {
                "name": "Yemel’yanovo",
                "countryCode": "RU"
            },
            {
                "name": "Yemel’yanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yeniseysk",
                "countryCode": "RU"
            },
            {
                "name": "Yeniseyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yermakovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Zelenogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Zelënyy Bor",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Zykovo",
                "countryCode": "RU"
            },
            {
                "name": "Agalatovo",
                "countryCode": "RU"
            },
            {
                "name": "Akademicheskoe",
                "countryCode": "RU"
            },
            {
                "name": "Annino",
                "countryCode": "RU"
            },
            {
                "name": "Begunitsy",
                "countryCode": "RU"
            },
            {
                "name": "Boksitogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Boksitogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shaya Izhora",
                "countryCode": "RU"
            },
            {
                "name": "Borisova Griva",
                "countryCode": "RU"
            },
            {
                "name": "Budogoshch’",
                "countryCode": "RU"
            },
            {
                "name": "Bugry",
                "countryCode": "RU"
            },
            {
                "name": "Chernaya Rechka",
                "countryCode": "RU"
            },
            {
                "name": "Druzhnaya Gorka",
                "countryCode": "RU"
            },
            {
                "name": "Finlyandskiy",
                "countryCode": "RU"
            },
            {
                "name": "Fornosovo",
                "countryCode": "RU"
            },
            {
                "name": "Fëdorovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Garbolovo",
                "countryCode": "RU"
            },
            {
                "name": "Gatchina",
                "countryCode": "RU"
            },
            {
                "name": "Glebychevo",
                "countryCode": "RU"
            },
            {
                "name": "Gorbunki",
                "countryCode": "RU"
            },
            {
                "name": "Gorskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gostilitsy",
                "countryCode": "RU"
            },
            {
                "name": "Il’ichëvo",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Morozova",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Sverdlova",
                "countryCode": "RU"
            },
            {
                "name": "Issad",
                "countryCode": "RU"
            },
            {
                "name": "Ivangorod",
                "countryCode": "RU"
            },
            {
                "name": "Kalininskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kamennogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Khvalovo",
                "countryCode": "RU"
            },
            {
                "name": "Kikerino",
                "countryCode": "RU"
            },
            {
                "name": "Kingisepp",
                "countryCode": "RU"
            },
            {
                "name": "Kingiseppskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kipen’",
                "countryCode": "RU"
            },
            {
                "name": "Kirishi",
                "countryCode": "RU"
            },
            {
                "name": "Kirovsk",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kobrinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kolchanovo",
                "countryCode": "RU"
            },
            {
                "name": "Koltushi",
                "countryCode": "RU"
            },
            {
                "name": "Kommunar",
                "countryCode": "RU"
            },
            {
                "name": "Kopor’ye",
                "countryCode": "RU"
            },
            {
                "name": "Korobitsyno",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Bor",
                "countryCode": "RU"
            },
            {
                "name": "Krestovskiy ostrov",
                "countryCode": "RU"
            },
            {
                "name": "Kurortnyy",
                "countryCode": "RU"
            },
            {
                "name": "Kuyvozi",
                "countryCode": "RU"
            },
            {
                "name": "Kuznechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Lebyazh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Leninskoye",
                "countryCode": "RU"
            },
            {
                "name": "Leskolovo",
                "countryCode": "RU"
            },
            {
                "name": "Lesogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lodeynopol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lodeynoye Pole",
                "countryCode": "RU"
            },
            {
                "name": "Lomonosovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Luga",
                "countryCode": "RU"
            },
            {
                "name": "Luppolovo",
                "countryCode": "RU"
            },
            {
                "name": "Luzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyuban’",
                "countryCode": "RU"
            },
            {
                "name": "Maloye Verevo",
                "countryCode": "RU"
            },
            {
                "name": "Mariyenburg",
                "countryCode": "RU"
            },
            {
                "name": "Mga",
                "countryCode": "RU"
            },
            {
                "name": "Michurinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Mshinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Murino",
                "countryCode": "RU"
            },
            {
                "name": "Nikol’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Ladoga",
                "countryCode": "RU"
            },
            {
                "name": "Novoye Devyatkino",
                "countryCode": "RU"
            },
            {
                "name": "Nurma",
                "countryCode": "RU"
            },
            {
                "name": "Ol’gino",
                "countryCode": "RU"
            },
            {
                "name": "Osel’ki",
                "countryCode": "RU"
            },
            {
                "name": "Otradnoye",
                "countryCode": "RU"
            },
            {
                "name": "Parnas",
                "countryCode": "RU"
            },
            {
                "name": "Pasha",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovo",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pikalëvo",
                "countryCode": "RU"
            },
            {
                "name": "Podporozhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Podporozh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Priladozhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Primorsk",
                "countryCode": "RU"
            },
            {
                "name": "Priozersky",
                "countryCode": "RU"
            },
            {
                "name": "Priozërsk",
                "countryCode": "RU"
            },
            {
                "name": "Romanovka",
                "countryCode": "RU"
            },
            {
                "name": "Roshchino",
                "countryCode": "RU"
            },
            {
                "name": "Rozhdestveno",
                "countryCode": "RU"
            },
            {
                "name": "Russko-Vysotskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ryabovo",
                "countryCode": "RU"
            },
            {
                "name": "Rybatskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sampsonievskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sapernoye",
                "countryCode": "RU"
            },
            {
                "name": "Semiozerje",
                "countryCode": "RU"
            },
            {
                "name": "Sertolovo",
                "countryCode": "RU"
            },
            {
                "name": "Shcheglovo",
                "countryCode": "RU"
            },
            {
                "name": "Shlissel’burg",
                "countryCode": "RU"
            },
            {
                "name": "Sinyavino",
                "countryCode": "RU"
            },
            {
                "name": "Sista-Palkino",
                "countryCode": "RU"
            },
            {
                "name": "Siverskiy",
                "countryCode": "RU"
            },
            {
                "name": "Slantsevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Slantsy",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovo",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovyy Bor",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Staraya",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Ladoga",
                "countryCode": "RU"
            },
            {
                "name": "Svetlanovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Svetogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Syas’stroy",
                "countryCode": "RU"
            },
            {
                "name": "Taytsy",
                "countryCode": "RU"
            },
            {
                "name": "Tikhvin",
                "countryCode": "RU"
            },
            {
                "name": "Tikhvinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Toksovo",
                "countryCode": "RU"
            },
            {
                "name": "Tolmachevo",
                "countryCode": "RU"
            },
            {
                "name": "Tosnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tosno",
                "countryCode": "RU"
            },
            {
                "name": "Udel’naya",
                "countryCode": "RU"
            },
            {
                "name": "Ul’yanovka",
                "countryCode": "RU"
            },
            {
                "name": "Untolovo",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Luga",
                "countryCode": "RU"
            },
            {
                "name": "Vaskelovo",
                "countryCode": "RU"
            },
            {
                "name": "Vazhiny",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniye Osel’ki",
                "countryCode": "RU"
            },
            {
                "name": "Veshchevo",
                "countryCode": "RU"
            },
            {
                "name": "Vinnitsy",
                "countryCode": "RU"
            },
            {
                "name": "Vistino",
                "countryCode": "RU"
            },
            {
                "name": "Volkhov",
                "countryCode": "RU"
            },
            {
                "name": "Volkhovskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Volosovo",
                "countryCode": "RU"
            },
            {
                "name": "Volosovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voyskovitsy",
                "countryCode": "RU"
            },
            {
                "name": "Voznesen’ye",
                "countryCode": "RU"
            },
            {
                "name": "Vsevolozhsk",
                "countryCode": "RU"
            },
            {
                "name": "Vsevolozhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vyborg",
                "countryCode": "RU"
            },
            {
                "name": "Vyritsa",
                "countryCode": "RU"
            },
            {
                "name": "Vysotsk",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevo",
                "countryCode": "RU"
            },
            {
                "name": "Yanino-1",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavichi",
                "countryCode": "RU"
            },
            {
                "name": "Yefimovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yelizavetino",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoy Khomutets",
                "countryCode": "RU"
            },
            {
                "name": "Borinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chaplygin",
                "countryCode": "RU"
            },
            {
                "name": "Chaplyginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dankov",
                "countryCode": "RU"
            },
            {
                "name": "Dankovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dobrinka",
                "countryCode": "RU"
            },
            {
                "name": "Dobrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dobrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dobroye",
                "countryCode": "RU"
            },
            {
                "name": "Dolgorukovo",
                "countryCode": "RU"
            },
            {
                "name": "Dolgorukovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Donskoye",
                "countryCode": "RU"
            },
            {
                "name": "Gryazi",
                "countryCode": "RU"
            },
            {
                "name": "Gryazinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Izmalkovo",
                "countryCode": "RU"
            },
            {
                "name": "Izmalkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kalikino",
                "countryCode": "RU"
            },
            {
                "name": "Kazaki",
                "countryCode": "RU"
            },
            {
                "name": "Kazinka",
                "countryCode": "RU"
            },
            {
                "name": "Khlevenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khlevnoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuz’minskiye Otverzhki",
                "countryCode": "RU"
            },
            {
                "name": "Lebedyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lebedyan’",
                "countryCode": "RU"
            },
            {
                "name": "Lev Tolstoy",
                "countryCode": "RU"
            },
            {
                "name": "Lev-Tolstovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lipetsk",
                "countryCode": "RU"
            },
            {
                "name": "Lipetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petrovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Plekhanovo",
                "countryCode": "RU"
            },
            {
                "name": "Prigorodka",
                "countryCode": "RU"
            },
            {
                "name": "Stanovlyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Stanovoye",
                "countryCode": "RU"
            },
            {
                "name": "Syrskoye",
                "countryCode": "RU"
            },
            {
                "name": "Terbunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Terbuny",
                "countryCode": "RU"
            },
            {
                "name": "Usmanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Usman’",
                "countryCode": "RU"
            },
            {
                "name": "Volovo",
                "countryCode": "RU"
            },
            {
                "name": "Volovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yelets",
                "countryCode": "RU"
            },
            {
                "name": "Yeletskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zadonsk",
                "countryCode": "RU"
            },
            {
                "name": "Zadonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arman’",
                "countryCode": "RU"
            },
            {
                "name": "Dukat",
                "countryCode": "RU"
            },
            {
                "name": "Evensk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Magadan",
                "countryCode": "RU"
            },
            {
                "name": "Kholodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Magadan",
                "countryCode": "RU"
            },
            {
                "name": "Myaundzha",
                "countryCode": "RU"
            },
            {
                "name": "Ola",
                "countryCode": "RU"
            },
            {
                "name": "Ol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Omsukchan",
                "countryCode": "RU"
            },
            {
                "name": "Omsukchanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orotukan",
                "countryCode": "RU"
            },
            {
                "name": "Palatka",
                "countryCode": "RU"
            },
            {
                "name": "Seymchan",
                "countryCode": "RU"
            },
            {
                "name": "Sinegor'ye",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Srednekanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Susuman",
                "countryCode": "RU"
            },
            {
                "name": "Talaya",
                "countryCode": "RU"
            },
            {
                "name": "Uptar",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Omchug",
                "countryCode": "RU"
            },
            {
                "name": "Yagodnoye",
                "countryCode": "RU"
            },
            {
                "name": "Gornomariyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kilemarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kilemary",
                "countryCode": "RU"
            },
            {
                "name": "Koz’modem’yansk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kuzhener",
                "countryCode": "RU"
            },
            {
                "name": "Kuzhenerskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mari-Turek",
                "countryCode": "RU"
            },
            {
                "name": "Mari-Turekskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Medvedevo",
                "countryCode": "RU"
            },
            {
                "name": "Medvedevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mochalishche",
                "countryCode": "RU"
            },
            {
                "name": "Morki",
                "countryCode": "RU"
            },
            {
                "name": "Morkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novotor”yal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orshanka",
                "countryCode": "RU"
            },
            {
                "name": "Orshanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Paran’ga",
                "countryCode": "RU"
            },
            {
                "name": "Paran’ginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pomary",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sernur",
                "countryCode": "RU"
            },
            {
                "name": "Sernurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Surok",
                "countryCode": "RU"
            },
            {
                "name": "Suslonger",
                "countryCode": "RU"
            },
            {
                "name": "Vilovatovo",
                "countryCode": "RU"
            },
            {
                "name": "Volzhsk",
                "countryCode": "RU"
            },
            {
                "name": "Volzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yoshkar-Ola",
                "countryCode": "RU"
            },
            {
                "name": "Yurino",
                "countryCode": "RU"
            },
            {
                "name": "Yurinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zvenigovo",
                "countryCode": "RU"
            },
            {
                "name": "Zvenigovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ardatov",
                "countryCode": "RU"
            },
            {
                "name": "Ardatovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atemar",
                "countryCode": "RU"
            },
            {
                "name": "Atyashevo",
                "countryCode": "RU"
            },
            {
                "name": "Atyashevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atyur’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Barashevo",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shebereznikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chamzinka",
                "countryCode": "RU"
            },
            {
                "name": "Chamzinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Insar",
                "countryCode": "RU"
            },
            {
                "name": "Insarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kadoshkino",
                "countryCode": "RU"
            },
            {
                "name": "Kemlya",
                "countryCode": "RU"
            },
            {
                "name": "Kochkurovo",
                "countryCode": "RU"
            },
            {
                "name": "Kochkurovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kovylkino",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoslobodsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoslobodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lepley",
                "countryCode": "RU"
            },
            {
                "name": "Lukhovka",
                "countryCode": "RU"
            },
            {
                "name": "Lyambirskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyambir’",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevka",
                "countryCode": "RU"
            },
            {
                "name": "Partsa",
                "countryCode": "RU"
            },
            {
                "name": "Romodanovo",
                "countryCode": "RU"
            },
            {
                "name": "Romodanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ruzayevka",
                "countryCode": "RU"
            },
            {
                "name": "Ruzayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Saransk",
                "countryCode": "RU"
            },
            {
                "name": "Shiringushi",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovka",
                "countryCode": "RU"
            },
            {
                "name": "Staroshaygovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tatarskaya Pishlya",
                "countryCode": "RU"
            },
            {
                "name": "Temnikov",
                "countryCode": "RU"
            },
            {
                "name": "Temnikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ten’gushevo",
                "countryCode": "RU"
            },
            {
                "name": "Ten’gushevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Torbeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Turgenevo",
                "countryCode": "RU"
            },
            {
                "name": "Udarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Umet",
                "countryCode": "RU"
            },
            {
                "name": "Vysha",
                "countryCode": "RU"
            },
            {
                "name": "Yavas",
                "countryCode": "RU"
            },
            {
                "name": "Yel’nikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zubova Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Zubovo-Polyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zykovo",
                "countryCode": "RU"
            },
            {
                "name": "Alabushevo",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevka",
                "countryCode": "RU"
            },
            {
                "name": "Andreyevka",
                "countryCode": "RU"
            },
            {
                "name": "Andreyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Annino",
                "countryCode": "RU"
            },
            {
                "name": "Aprelevka",
                "countryCode": "RU"
            },
            {
                "name": "Arkhangel’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Ashitkovo",
                "countryCode": "RU"
            },
            {
                "name": "Ashukino",
                "countryCode": "RU"
            },
            {
                "name": "Ateptsevo",
                "countryCode": "RU"
            },
            {
                "name": "Avsyunino",
                "countryCode": "RU"
            },
            {
                "name": "Avtopoligon",
                "countryCode": "RU"
            },
            {
                "name": "Bakhchivandzhi",
                "countryCode": "RU"
            },
            {
                "name": "Baksheyevo",
                "countryCode": "RU"
            },
            {
                "name": "Balashikha",
                "countryCode": "RU"
            },
            {
                "name": "Balashikha Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Barvikha",
                "countryCode": "RU"
            },
            {
                "name": "Barybino",
                "countryCode": "RU"
            },
            {
                "name": "Beloomut",
                "countryCode": "RU"
            },
            {
                "name": "Beloozërskiy",
                "countryCode": "RU"
            },
            {
                "name": "Belyye Stolby",
                "countryCode": "RU"
            },
            {
                "name": "Biorki",
                "countryCode": "RU"
            },
            {
                "name": "Biryulëvo Zapadnoye",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Bol'shiye Vyazëmy",
                "countryCode": "RU"
            },
            {
                "name": "Bolshevo",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shaya Setun’",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Gryzlovo",
                "countryCode": "RU"
            },
            {
                "name": "Bratovshchina",
                "countryCode": "RU"
            },
            {
                "name": "Bronnitsy",
                "countryCode": "RU"
            },
            {
                "name": "Budenovetc",
                "countryCode": "RU"
            },
            {
                "name": "Bykovo",
                "countryCode": "RU"
            },
            {
                "name": "Chashnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Chekhov",
                "countryCode": "RU"
            },
            {
                "name": "Cherkizovo",
                "countryCode": "RU"
            },
            {
                "name": "Chernogolovka",
                "countryCode": "RU"
            },
            {
                "name": "Cherusti",
                "countryCode": "RU"
            },
            {
                "name": "Chkalovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Chornoye",
                "countryCode": "RU"
            },
            {
                "name": "Chupryakovo",
                "countryCode": "RU"
            },
            {
                "name": "Danki",
                "countryCode": "RU"
            },
            {
                "name": "Davydovo",
                "countryCode": "RU"
            },
            {
                "name": "Dedenëvo",
                "countryCode": "RU"
            },
            {
                "name": "Dedovsk",
                "countryCode": "RU"
            },
            {
                "name": "Demikhovo",
                "countryCode": "RU"
            },
            {
                "name": "Dmitrov",
                "countryCode": "RU"
            },
            {
                "name": "Dmitrovskiy Pogost",
                "countryCode": "RU"
            },
            {
                "name": "Dmitrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dolgoprudnyy",
                "countryCode": "RU"
            },
            {
                "name": "Domodedovo",
                "countryCode": "RU"
            },
            {
                "name": "Domodedovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dorogomilovo",
                "countryCode": "RU"
            },
            {
                "name": "Dorokhovo",
                "countryCode": "RU"
            },
            {
                "name": "Drezna",
                "countryCode": "RU"
            },
            {
                "name": "Druzhba",
                "countryCode": "RU"
            },
            {
                "name": "Dubna",
                "countryCode": "RU"
            },
            {
                "name": "Dubrovitsy",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinsky",
                "countryCode": "RU"
            },
            {
                "name": "Elektrogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Elektrostal’",
                "countryCode": "RU"
            },
            {
                "name": "Elektrougli",
                "countryCode": "RU"
            },
            {
                "name": "Fedurnovo",
                "countryCode": "RU"
            },
            {
                "name": "Fili",
                "countryCode": "RU"
            },
            {
                "name": "Filimonki",
                "countryCode": "RU"
            },
            {
                "name": "Firsanovka",
                "countryCode": "RU"
            },
            {
                "name": "Fosforitnyy",
                "countryCode": "RU"
            },
            {
                "name": "Fryanovo",
                "countryCode": "RU"
            },
            {
                "name": "Fryazevo",
                "countryCode": "RU"
            },
            {
                "name": "Fryazino",
                "countryCode": "RU"
            },
            {
                "name": "Golitsyno",
                "countryCode": "RU"
            },
            {
                "name": "Gorki Vtoryye",
                "countryCode": "RU"
            },
            {
                "name": "Gorki-Leninskiye",
                "countryCode": "RU"
            },
            {
                "name": "Gorshkovo",
                "countryCode": "RU"
            },
            {
                "name": "Grebnevo",
                "countryCode": "RU"
            },
            {
                "name": "Gzhel’",
                "countryCode": "RU"
            },
            {
                "name": "Iksha",
                "countryCode": "RU"
            },
            {
                "name": "Il’inskiy",
                "countryCode": "RU"
            },
            {
                "name": "Il’inskiy Pogost",
                "countryCode": "RU"
            },
            {
                "name": "Il’inskoye",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Tsyurupy",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Vorovskogo",
                "countryCode": "RU"
            },
            {
                "name": "Istra",
                "countryCode": "RU"
            },
            {
                "name": "Istrinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ivanteyevka",
                "countryCode": "RU"
            },
            {
                "name": "Izmaylovo",
                "countryCode": "RU"
            },
            {
                "name": "Kabanovo",
                "countryCode": "RU"
            },
            {
                "name": "Karinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kashira",
                "countryCode": "RU"
            },
            {
                "name": "Kastanayevo",
                "countryCode": "RU"
            },
            {
                "name": "Kerva",
                "countryCode": "RU"
            },
            {
                "name": "Khimki",
                "countryCode": "RU"
            },
            {
                "name": "Khimki Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Khorlovo",
                "countryCode": "RU"
            },
            {
                "name": "Khoroshëvo-Mnevniki",
                "countryCode": "RU"
            },
            {
                "name": "Khot'kovo",
                "countryCode": "RU"
            },
            {
                "name": "Kievskiy",
                "countryCode": "RU"
            },
            {
                "name": "Klimovsk",
                "countryCode": "RU"
            },
            {
                "name": "Klin",
                "countryCode": "RU"
            },
            {
                "name": "Klinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Klyazminskoe Reservoir",
                "countryCode": "RU"
            },
            {
                "name": "Kolomenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kolomna",
                "countryCode": "RU"
            },
            {
                "name": "Kolyubakino",
                "countryCode": "RU"
            },
            {
                "name": "Kommunarka",
                "countryCode": "RU"
            },
            {
                "name": "Konobeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovo",
                "countryCode": "RU"
            },
            {
                "name": "Koptëvo",
                "countryCode": "RU"
            },
            {
                "name": "Korenëvo",
                "countryCode": "RU"
            },
            {
                "name": "Korolev",
                "countryCode": "RU"
            },
            {
                "name": "Korolyov",
                "countryCode": "RU"
            },
            {
                "name": "Kostino",
                "countryCode": "RU"
            },
            {
                "name": "Kostrovo",
                "countryCode": "RU"
            },
            {
                "name": "Kotel’niki",
                "countryCode": "RU"
            },
            {
                "name": "Kozhukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Kraskovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Poyma",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeysk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnozavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoznamensk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Tkach",
                "countryCode": "RU"
            },
            {
                "name": "Kratovo",
                "countryCode": "RU"
            },
            {
                "name": "Krivtsovo",
                "countryCode": "RU"
            },
            {
                "name": "Kubinka",
                "countryCode": "RU"
            },
            {
                "name": "Kupavna",
                "countryCode": "RU"
            },
            {
                "name": "Kurilovo",
                "countryCode": "RU"
            },
            {
                "name": "Kurovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kuskovo",
                "countryCode": "RU"
            },
            {
                "name": "Leninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leninskiye Gory",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy Gorodok",
                "countryCode": "RU"
            },
            {
                "name": "Lesnyye Polyany",
                "countryCode": "RU"
            },
            {
                "name": "Levoberezhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Likino-Dulevo",
                "countryCode": "RU"
            },
            {
                "name": "Lobnya",
                "countryCode": "RU"
            },
            {
                "name": "Lopatinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Losino-Petrovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lotoshino",
                "countryCode": "RU"
            },
            {
                "name": "Lotoshinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lozhki",
                "countryCode": "RU"
            },
            {
                "name": "Lugovaya",
                "countryCode": "RU"
            },
            {
                "name": "Lukhovitsy",
                "countryCode": "RU"
            },
            {
                "name": "Lytkarino",
                "countryCode": "RU"
            },
            {
                "name": "Lyuberetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyubertsy",
                "countryCode": "RU"
            },
            {
                "name": "Lyubuchany",
                "countryCode": "RU"
            },
            {
                "name": "L’vovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Malakhovka",
                "countryCode": "RU"
            },
            {
                "name": "Malaya Dubna",
                "countryCode": "RU"
            },
            {
                "name": "Malino",
                "countryCode": "RU"
            },
            {
                "name": "Malyshevo",
                "countryCode": "RU"
            },
            {
                "name": "Mamontovka",
                "countryCode": "RU"
            },
            {
                "name": "Marfino",
                "countryCode": "RU"
            },
            {
                "name": "Medvezh’i Ozëra",
                "countryCode": "RU"
            },
            {
                "name": "Melikhovo",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevo",
                "countryCode": "RU"
            },
            {
                "name": "Meshcherino",
                "countryCode": "RU"
            },
            {
                "name": "Mikhnëvo",
                "countryCode": "RU"
            },
            {
                "name": "Mikulino",
                "countryCode": "RU"
            },
            {
                "name": "Misheronskiy",
                "countryCode": "RU"
            },
            {
                "name": "Molokovo",
                "countryCode": "RU"
            },
            {
                "name": "Monino",
                "countryCode": "RU"
            },
            {
                "name": "Mosrentgen",
                "countryCode": "RU"
            },
            {
                "name": "Mozhaysk",
                "countryCode": "RU"
            },
            {
                "name": "Mozhayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Muranovo",
                "countryCode": "RU"
            },
            {
                "name": "Mytishchi",
                "countryCode": "RU"
            },
            {
                "name": "Mytishchi Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Nakhabino",
                "countryCode": "RU"
            },
            {
                "name": "Naro-Fominsk",
                "countryCode": "RU"
            },
            {
                "name": "Nazar'yevo",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nemchinovka",
                "countryCode": "RU"
            },
            {
                "name": "Nikolina Gora",
                "countryCode": "RU"
            },
            {
                "name": "Noginsk",
                "countryCode": "RU"
            },
            {
                "name": "Noginsk-9",
                "countryCode": "RU"
            },
            {
                "name": "Noginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novo-Nikol’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Novoivanovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novopetrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novopodrezkovo",
                "countryCode": "RU"
            },
            {
                "name": "Novosin’kovo",
                "countryCode": "RU"
            },
            {
                "name": "Novoye",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Byt",
                "countryCode": "RU"
            },
            {
                "name": "Nudol’",
                "countryCode": "RU"
            },
            {
                "name": "Obolensk",
                "countryCode": "RU"
            },
            {
                "name": "Obukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Odintsovo",
                "countryCode": "RU"
            },
            {
                "name": "Odintsovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Ol'yavidovo",
                "countryCode": "RU"
            },
            {
                "name": "Opalikha",
                "countryCode": "RU"
            },
            {
                "name": "Orekhovo-Zuyevo",
                "countryCode": "RU"
            },
            {
                "name": "Orekhovo-Zuyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orud’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Ostashëvo",
                "countryCode": "RU"
            },
            {
                "name": "Ostrovtsy",
                "countryCode": "RU"
            },
            {
                "name": "Ozherel’ye",
                "countryCode": "RU"
            },
            {
                "name": "Ozyory Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Ozëry",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskaya Sloboda",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Posad",
                "countryCode": "RU"
            },
            {
                "name": "Pavshino",
                "countryCode": "RU"
            },
            {
                "name": "Peresvet",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Peshki",
                "countryCode": "RU"
            },
            {
                "name": "Peski",
                "countryCode": "RU"
            },
            {
                "name": "Petrovo-Dal’neye",
                "countryCode": "RU"
            },
            {
                "name": "Petrovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Pirogovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Podolsk",
                "countryCode": "RU"
            },
            {
                "name": "Podosinki",
                "countryCode": "RU"
            },
            {
                "name": "Popovo",
                "countryCode": "RU"
            },
            {
                "name": "Posëlok Mar’ino",
                "countryCode": "RU"
            },
            {
                "name": "Povarovo",
                "countryCode": "RU"
            },
            {
                "name": "Povedniki",
                "countryCode": "RU"
            },
            {
                "name": "Pravdinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Proletarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Protvino",
                "countryCode": "RU"
            },
            {
                "name": "Purshevo",
                "countryCode": "RU"
            },
            {
                "name": "Pushchino",
                "countryCode": "RU"
            },
            {
                "name": "Pushkino",
                "countryCode": "RU"
            },
            {
                "name": "Pushkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Radovitskiy",
                "countryCode": "RU"
            },
            {
                "name": "Radumlya",
                "countryCode": "RU"
            },
            {
                "name": "Raduzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Rakhmanovo",
                "countryCode": "RU"
            },
            {
                "name": "Ramenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ramenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Razvilka",
                "countryCode": "RU"
            },
            {
                "name": "Rechitsy",
                "countryCode": "RU"
            },
            {
                "name": "Reshetnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Reutov",
                "countryCode": "RU"
            },
            {
                "name": "Rodniki",
                "countryCode": "RU"
            },
            {
                "name": "Rogachëvo",
                "countryCode": "RU"
            },
            {
                "name": "Roshal’",
                "countryCode": "RU"
            },
            {
                "name": "Rozhdestveno",
                "countryCode": "RU"
            },
            {
                "name": "Rumyantsevo",
                "countryCode": "RU"
            },
            {
                "name": "Ruza",
                "countryCode": "RU"
            },
            {
                "name": "Ruzskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rybnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rzhavki",
                "countryCode": "RU"
            },
            {
                "name": "Saltykovka",
                "countryCode": "RU"
            },
            {
                "name": "Savinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Savvinskaya Sloboda",
                "countryCode": "RU"
            },
            {
                "name": "Selyatino",
                "countryCode": "RU"
            },
            {
                "name": "Semkhoz",
                "countryCode": "RU"
            },
            {
                "name": "Semënovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Serebryano-Prudskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Serebryanyye Prudy",
                "countryCode": "RU"
            },
            {
                "name": "Sergiyev Posad",
                "countryCode": "RU"
            },
            {
                "name": "Sergiyevo-Posadskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Serpukhov",
                "countryCode": "RU"
            },
            {
                "name": "Serpukhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severnyy",
                "countryCode": "RU"
            },
            {
                "name": "Shaburnovo",
                "countryCode": "RU"
            },
            {
                "name": "Shakhovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Shakhovskoy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shatura",
                "countryCode": "RU"
            },
            {
                "name": "Shaturtorf",
                "countryCode": "RU"
            },
            {
                "name": "Shchelkovo",
                "countryCode": "RU"
            },
            {
                "name": "Shchëlkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shemëtovo",
                "countryCode": "RU"
            },
            {
                "name": "Sheremet’yevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shevlyakovo",
                "countryCode": "RU"
            },
            {
                "name": "Shishkin Les",
                "countryCode": "RU"
            },
            {
                "name": "Skhodnya",
                "countryCode": "RU"
            },
            {
                "name": "Skolkovo",
                "countryCode": "RU"
            },
            {
                "name": "Skoropuskovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Snegiri",
                "countryCode": "RU"
            },
            {
                "name": "Sofrino",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Spas-Zaulok",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Kupavna",
                "countryCode": "RU"
            },
            {
                "name": "Stolbovaya",
                "countryCode": "RU"
            },
            {
                "name": "Stromyn’",
                "countryCode": "RU"
            },
            {
                "name": "Stupino",
                "countryCode": "RU"
            },
            {
                "name": "Stupinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Svatkovo",
                "countryCode": "RU"
            },
            {
                "name": "Sverdlovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sychëvo",
                "countryCode": "RU"
            },
            {
                "name": "Taldom",
                "countryCode": "RU"
            },
            {
                "name": "Taldomskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tarasovka",
                "countryCode": "RU"
            },
            {
                "name": "Teryayevo",
                "countryCode": "RU"
            },
            {
                "name": "Tomilino",
                "countryCode": "RU"
            },
            {
                "name": "Troitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tuchkovo",
                "countryCode": "RU"
            },
            {
                "name": "Tugolesskiy Bor",
                "countryCode": "RU"
            },
            {
                "name": "Udel’naya",
                "countryCode": "RU"
            },
            {
                "name": "Uvarovka",
                "countryCode": "RU"
            },
            {
                "name": "Uzunovo",
                "countryCode": "RU"
            },
            {
                "name": "Verbilki",
                "countryCode": "RU"
            },
            {
                "name": "Vereya",
                "countryCode": "RU"
            },
            {
                "name": "Vidnoye",
                "countryCode": "RU"
            },
            {
                "name": "Vinogradovo",
                "countryCode": "RU"
            },
            {
                "name": "Vishnyakovskiye Dachi",
                "countryCode": "RU"
            },
            {
                "name": "Vniissok",
                "countryCode": "RU"
            },
            {
                "name": "Volodarskogo",
                "countryCode": "RU"
            },
            {
                "name": "Volokolamsk",
                "countryCode": "RU"
            },
            {
                "name": "Volokolamskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voskresensk",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vostryakovo",
                "countryCode": "RU"
            },
            {
                "name": "Vysokovsk",
                "countryCode": "RU"
            },
            {
                "name": "Yakhroma",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yam",
                "countryCode": "RU"
            },
            {
                "name": "Yamkino",
                "countryCode": "RU"
            },
            {
                "name": "Yaropolets",
                "countryCode": "RU"
            },
            {
                "name": "Yegor’yevsk",
                "countryCode": "RU"
            },
            {
                "name": "Yel’digino",
                "countryCode": "RU"
            },
            {
                "name": "Yermolino",
                "countryCode": "RU"
            },
            {
                "name": "Yershovo",
                "countryCode": "RU"
            },
            {
                "name": "Yubileyny",
                "countryCode": "RU"
            },
            {
                "name": "Zagoryanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zaprudnya",
                "countryCode": "RU"
            },
            {
                "name": "Zaraysk",
                "countryCode": "RU"
            },
            {
                "name": "Zarech’ye",
                "countryCode": "RU"
            },
            {
                "name": "Zarya",
                "countryCode": "RU"
            },
            {
                "name": "Zelenogradskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zhavoronki",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznodorozhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zhilëvo",
                "countryCode": "RU"
            },
            {
                "name": "Zhitnevo",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovka",
                "countryCode": "RU"
            },
            {
                "name": "Zhukovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zvenigorod",
                "countryCode": "RU"
            },
            {
                "name": "Altuf’yevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Amin’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Annino",
                "countryCode": "RU"
            },
            {
                "name": "Babushkin",
                "countryCode": "RU"
            },
            {
                "name": "Bibirevo",
                "countryCode": "RU"
            },
            {
                "name": "Biryulëvo",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Brateyevo",
                "countryCode": "RU"
            },
            {
                "name": "Chertanovo Yuzhnoye",
                "countryCode": "RU"
            },
            {
                "name": "Cherëmushki",
                "countryCode": "RU"
            },
            {
                "name": "Davydkovo",
                "countryCode": "RU"
            },
            {
                "name": "Golovinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gol’yanovo",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kapotnya",
                "countryCode": "RU"
            },
            {
                "name": "Kokoshkino",
                "countryCode": "RU"
            },
            {
                "name": "Kolomenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kotlovka",
                "countryCode": "RU"
            },
            {
                "name": "Kozeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Pahra",
                "countryCode": "RU"
            },
            {
                "name": "Kur’yanovo",
                "countryCode": "RU"
            },
            {
                "name": "Kuz’minki",
                "countryCode": "RU"
            },
            {
                "name": "LMS",
                "countryCode": "RU"
            },
            {
                "name": "Lefortovo",
                "countryCode": "RU"
            },
            {
                "name": "Leonovo",
                "countryCode": "RU"
            },
            {
                "name": "Lianozovo",
                "countryCode": "RU"
            },
            {
                "name": "Likhobory",
                "countryCode": "RU"
            },
            {
                "name": "Luzhniki",
                "countryCode": "RU"
            },
            {
                "name": "Lyublino",
                "countryCode": "RU"
            },
            {
                "name": "Mar’ino",
                "countryCode": "RU"
            },
            {
                "name": "Matveyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Metrogorodok",
                "countryCode": "RU"
            },
            {
                "name": "Mikhalkovo",
                "countryCode": "RU"
            },
            {
                "name": "Moscow",
                "countryCode": "RU"
            },
            {
                "name": "Moskovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nagornyy",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovka",
                "countryCode": "RU"
            },
            {
                "name": "Nikol’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Nikulino",
                "countryCode": "RU"
            },
            {
                "name": "Novo-Peredelkino",
                "countryCode": "RU"
            },
            {
                "name": "Novogireyevo",
                "countryCode": "RU"
            },
            {
                "name": "Novokhovrino",
                "countryCode": "RU"
            },
            {
                "name": "Novokuz’minki",
                "countryCode": "RU"
            },
            {
                "name": "Novovladykino",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Cherëmushki",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Kuz’minki",
                "countryCode": "RU"
            },
            {
                "name": "Ochakovo-Matveyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Orekhovo-Borisovo",
                "countryCode": "RU"
            },
            {
                "name": "Orekhovo-Borisovo Severnoye",
                "countryCode": "RU"
            },
            {
                "name": "Ostankinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Otradnoye",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovskoye-Streshnëvo",
                "countryCode": "RU"
            },
            {
                "name": "Presnenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ramenki",
                "countryCode": "RU"
            },
            {
                "name": "Rostokino",
                "countryCode": "RU"
            },
            {
                "name": "Rublëvo",
                "countryCode": "RU"
            },
            {
                "name": "Ryazanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Setun’",
                "countryCode": "RU"
            },
            {
                "name": "Severnyy",
                "countryCode": "RU"
            },
            {
                "name": "Shcherbinka",
                "countryCode": "RU"
            },
            {
                "name": "Shchukino",
                "countryCode": "RU"
            },
            {
                "name": "Slobodka",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Sokol’niki",
                "countryCode": "RU"
            },
            {
                "name": "Solntsevo",
                "countryCode": "RU"
            },
            {
                "name": "Strogino",
                "countryCode": "RU"
            },
            {
                "name": "Sviblovo",
                "countryCode": "RU"
            },
            {
                "name": "Taganskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tekstil’shchiki",
                "countryCode": "RU"
            },
            {
                "name": "Tolstopal’tsevo",
                "countryCode": "RU"
            },
            {
                "name": "Troitsk",
                "countryCode": "RU"
            },
            {
                "name": "Troparëvo",
                "countryCode": "RU"
            },
            {
                "name": "Tsaritsyno",
                "countryCode": "RU"
            },
            {
                "name": "Tsentral’nyy Administrativnyy Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Tyoply Stan",
                "countryCode": "RU"
            },
            {
                "name": "Vagonoremont",
                "countryCode": "RU"
            },
            {
                "name": "Vatutinki",
                "countryCode": "RU"
            },
            {
                "name": "Vatutino",
                "countryCode": "RU"
            },
            {
                "name": "Veshnyaki",
                "countryCode": "RU"
            },
            {
                "name": "Vnukovo",
                "countryCode": "RU"
            },
            {
                "name": "Vorob’yovo",
                "countryCode": "RU"
            },
            {
                "name": "Vostochnoe Degunino",
                "countryCode": "RU"
            },
            {
                "name": "Vostochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Vykhino-Zhulebino",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yasenevo",
                "countryCode": "RU"
            },
            {
                "name": "Zagor’ye",
                "countryCode": "RU"
            },
            {
                "name": "Zamoskvorech’ye",
                "countryCode": "RU"
            },
            {
                "name": "Zelenograd",
                "countryCode": "RU"
            },
            {
                "name": "Zhulebino",
                "countryCode": "RU"
            },
            {
                "name": "Zyablikovo",
                "countryCode": "RU"
            },
            {
                "name": "Zyuzino",
                "countryCode": "RU"
            },
            {
                "name": "Abram Mys",
                "countryCode": "RU"
            },
            {
                "name": "Afrikanda",
                "countryCode": "RU"
            },
            {
                "name": "Alakurtti",
                "countryCode": "RU"
            },
            {
                "name": "Apatity",
                "countryCode": "RU"
            },
            {
                "name": "Gadzhiyevo",
                "countryCode": "RU"
            },
            {
                "name": "Kandalaksha",
                "countryCode": "RU"
            },
            {
                "name": "Kandalakshskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirovsk",
                "countryCode": "RU"
            },
            {
                "name": "Kola",
                "countryCode": "RU"
            },
            {
                "name": "Kol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kovdor",
                "countryCode": "RU"
            },
            {
                "name": "Kovdorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lovozero",
                "countryCode": "RU"
            },
            {
                "name": "Lovozerskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Molochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Monchegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Murmansk",
                "countryCode": "RU"
            },
            {
                "name": "Murmashi",
                "countryCode": "RU"
            },
            {
                "name": "Nikel",
                "countryCode": "RU"
            },
            {
                "name": "Olenegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Ostrovnoy",
                "countryCode": "RU"
            },
            {
                "name": "Pechenga",
                "countryCode": "RU"
            },
            {
                "name": "Pechengskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Polyarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Polyarnyye Zori",
                "countryCode": "RU"
            },
            {
                "name": "Poyakonda",
                "countryCode": "RU"
            },
            {
                "name": "Revda",
                "countryCode": "RU"
            },
            {
                "name": "Roslyakovo",
                "countryCode": "RU"
            },
            {
                "name": "Safonovo",
                "countryCode": "RU"
            },
            {
                "name": "Severomorsk",
                "countryCode": "RU"
            },
            {
                "name": "Severomorsk-3",
                "countryCode": "RU"
            },
            {
                "name": "Shonguy",
                "countryCode": "RU"
            },
            {
                "name": "Snezhnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Teribërka",
                "countryCode": "RU"
            },
            {
                "name": "Terskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Umba",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnetulomskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vidyayevo",
                "countryCode": "RU"
            },
            {
                "name": "Vysokiy",
                "countryCode": "RU"
            },
            {
                "name": "Zaozërsk",
                "countryCode": "RU"
            },
            {
                "name": "Zapolyarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zelenoborskiy",
                "countryCode": "RU"
            },
            {
                "name": "Iskateley",
                "countryCode": "RU"
            },
            {
                "name": "Nar'yan-Mar",
                "countryCode": "RU"
            },
            {
                "name": "Batetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Batetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Borovichi",
                "countryCode": "RU"
            },
            {
                "name": "Borovichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chudovo",
                "countryCode": "RU"
            },
            {
                "name": "Chudovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Demyansk",
                "countryCode": "RU"
            },
            {
                "name": "Demyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kholm",
                "countryCode": "RU"
            },
            {
                "name": "Kholmskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khvoynaya",
                "countryCode": "RU"
            },
            {
                "name": "Khvoyninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnofarfornyy",
                "countryCode": "RU"
            },
            {
                "name": "Krechevitsy",
                "countryCode": "RU"
            },
            {
                "name": "Krestetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kresttsy",
                "countryCode": "RU"
            },
            {
                "name": "Kulotino",
                "countryCode": "RU"
            },
            {
                "name": "Lyubytino",
                "countryCode": "RU"
            },
            {
                "name": "Lyubytinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Malaya Vishera",
                "countryCode": "RU"
            },
            {
                "name": "Malovisherskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Marevo",
                "countryCode": "RU"
            },
            {
                "name": "Marevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Moshenskoy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Moshenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Nebolchi",
                "countryCode": "RU"
            },
            {
                "name": "Novgorodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Okulovka",
                "countryCode": "RU"
            },
            {
                "name": "Okulovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Opechenskiy Posad",
                "countryCode": "RU"
            },
            {
                "name": "Pankovka",
                "countryCode": "RU"
            },
            {
                "name": "Parfino",
                "countryCode": "RU"
            },
            {
                "name": "Parfinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pestovo",
                "countryCode": "RU"
            },
            {
                "name": "Pestovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Poddorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Poddor’ye",
                "countryCode": "RU"
            },
            {
                "name": "Proletariy",
                "countryCode": "RU"
            },
            {
                "name": "Shimsk",
                "countryCode": "RU"
            },
            {
                "name": "Shimskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Soletskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sol’tsy",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Russa",
                "countryCode": "RU"
            },
            {
                "name": "Starorusskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uglovka",
                "countryCode": "RU"
            },
            {
                "name": "Utorgosh",
                "countryCode": "RU"
            },
            {
                "name": "Valday",
                "countryCode": "RU"
            },
            {
                "name": "Valdayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Velikiy Novgorod",
                "countryCode": "RU"
            },
            {
                "name": "Volkhovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Volot",
                "countryCode": "RU"
            },
            {
                "name": "Volotovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yedrovo",
                "countryCode": "RU"
            },
            {
                "name": "Zarubino",
                "countryCode": "RU"
            },
            {
                "name": "Afonino",
                "countryCode": "RU"
            },
            {
                "name": "Ardatov",
                "countryCode": "RU"
            },
            {
                "name": "Ardatovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arzamas",
                "countryCode": "RU"
            },
            {
                "name": "Arzamasskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ar’ya",
                "countryCode": "RU"
            },
            {
                "name": "Babino",
                "countryCode": "RU"
            },
            {
                "name": "Blizhne-Pesochnoye",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodsk",
                "countryCode": "RU"
            },
            {
                "name": "Bogorodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’sheboldinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Boldino",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Kozino",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Murashkino",
                "countryCode": "RU"
            },
            {
                "name": "Bor",
                "countryCode": "RU"
            },
            {
                "name": "Bor Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Burevestnik",
                "countryCode": "RU"
            },
            {
                "name": "Buturlino",
                "countryCode": "RU"
            },
            {
                "name": "Chernukha",
                "countryCode": "RU"
            },
            {
                "name": "Chistoye",
                "countryCode": "RU"
            },
            {
                "name": "Chkalovsk",
                "countryCode": "RU"
            },
            {
                "name": "Dal’nekonstantinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dal’neye Konstantinovo",
                "countryCode": "RU"
            },
            {
                "name": "Diveyevo",
                "countryCode": "RU"
            },
            {
                "name": "Doschatoye",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Frolishchi",
                "countryCode": "RU"
            },
            {
                "name": "Gagino",
                "countryCode": "RU"
            },
            {
                "name": "Gidrotorf",
                "countryCode": "RU"
            },
            {
                "name": "Gorbatov",
                "countryCode": "RU"
            },
            {
                "name": "Gorbatovka",
                "countryCode": "RU"
            },
            {
                "name": "Gorodets",
                "countryCode": "RU"
            },
            {
                "name": "Gorodetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gremyachevo",
                "countryCode": "RU"
            },
            {
                "name": "Ilyinogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Imeni M. I. Kalinina",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Stepana Razina",
                "countryCode": "RU"
            },
            {
                "name": "Knyaginino",
                "countryCode": "RU"
            },
            {
                "name": "Kovernino",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Gorka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnooktyabr’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyye Baki",
                "countryCode": "RU"
            },
            {
                "name": "Kstovo",
                "countryCode": "RU"
            },
            {
                "name": "Kulebaki",
                "countryCode": "RU"
            },
            {
                "name": "Linda",
                "countryCode": "RU"
            },
            {
                "name": "Lukino",
                "countryCode": "RU"
            },
            {
                "name": "Lukoyanov",
                "countryCode": "RU"
            },
            {
                "name": "Lukoyanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lyskovo",
                "countryCode": "RU"
            },
            {
                "name": "Mukhtolovo",
                "countryCode": "RU"
            },
            {
                "name": "Mulino",
                "countryCode": "RU"
            },
            {
                "name": "Navashino",
                "countryCode": "RU"
            },
            {
                "name": "Neklyudovo",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Novgorod",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Balakhna",
                "countryCode": "RU"
            },
            {
                "name": "Novosmolinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pamyat’ Parizhskoy Kommuny",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovo",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Perevoz",
                "countryCode": "RU"
            },
            {
                "name": "Pervomaysk",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pervoye Maya",
                "countryCode": "RU"
            },
            {
                "name": "Pil’na",
                "countryCode": "RU"
            },
            {
                "name": "Pil’ninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pizhma",
                "countryCode": "RU"
            },
            {
                "name": "Pochinki",
                "countryCode": "RU"
            },
            {
                "name": "Pochinkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Podnov’ye",
                "countryCode": "RU"
            },
            {
                "name": "Prudy",
                "countryCode": "RU"
            },
            {
                "name": "Purekh",
                "countryCode": "RU"
            },
            {
                "name": "Pyra",
                "countryCode": "RU"
            },
            {
                "name": "Rabotki",
                "countryCode": "RU"
            },
            {
                "name": "Reshetikha",
                "countryCode": "RU"
            },
            {
                "name": "Sarov",
                "countryCode": "RU"
            },
            {
                "name": "Satis",
                "countryCode": "RU"
            },
            {
                "name": "Savasleyka",
                "countryCode": "RU"
            },
            {
                "name": "Sechenovo",
                "countryCode": "RU"
            },
            {
                "name": "Sechenovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Semënov",
                "countryCode": "RU"
            },
            {
                "name": "Sergach",
                "countryCode": "RU"
            },
            {
                "name": "Sergachskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shakhun’ya",
                "countryCode": "RU"
            },
            {
                "name": "Sharanga",
                "countryCode": "RU"
            },
            {
                "name": "Sharangskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shatki",
                "countryCode": "RU"
            },
            {
                "name": "Shimorskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sitniki",
                "countryCode": "RU"
            },
            {
                "name": "Smolino",
                "countryCode": "RU"
            },
            {
                "name": "Sokol’skoye",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Spasskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Spasskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sukhobezvodnoye",
                "countryCode": "RU"
            },
            {
                "name": "Surovatikha",
                "countryCode": "RU"
            },
            {
                "name": "Syava",
                "countryCode": "RU"
            },
            {
                "name": "Taremskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tonkino",
                "countryCode": "RU"
            },
            {
                "name": "Tonshayevo",
                "countryCode": "RU"
            },
            {
                "name": "Tsentral’nyy",
                "countryCode": "RU"
            },
            {
                "name": "Tumbotino",
                "countryCode": "RU"
            },
            {
                "name": "Urazovka",
                "countryCode": "RU"
            },
            {
                "name": "Uren’",
                "countryCode": "RU"
            },
            {
                "name": "Vacha",
                "countryCode": "RU"
            },
            {
                "name": "Vachskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vad",
                "countryCode": "RU"
            },
            {
                "name": "Vakhtan",
                "countryCode": "RU"
            },
            {
                "name": "Varnavino",
                "countryCode": "RU"
            },
            {
                "name": "Varnavinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vasil’sursk",
                "countryCode": "RU"
            },
            {
                "name": "Vetluga",
                "countryCode": "RU"
            },
            {
                "name": "Vetluzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vetluzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vilya",
                "countryCode": "RU"
            },
            {
                "name": "Vladimirskoye",
                "countryCode": "RU"
            },
            {
                "name": "Volodarsk",
                "countryCode": "RU"
            },
            {
                "name": "Vorotynets",
                "countryCode": "RU"
            },
            {
                "name": "Vorsma",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Voznesenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Vyksa",
                "countryCode": "RU"
            },
            {
                "name": "Vyyezdnoye",
                "countryCode": "RU"
            },
            {
                "name": "Yuganets",
                "countryCode": "RU"
            },
            {
                "name": "Zavolzh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Zelyony Gorod",
                "countryCode": "RU"
            },
            {
                "name": "Akademgorodok",
                "countryCode": "RU"
            },
            {
                "name": "Bagan",
                "countryCode": "RU"
            },
            {
                "name": "Barabinsk",
                "countryCode": "RU"
            },
            {
                "name": "Baryshevo",
                "countryCode": "RU"
            },
            {
                "name": "Berdsk",
                "countryCode": "RU"
            },
            {
                "name": "Bolotnoye",
                "countryCode": "RU"
            },
            {
                "name": "Chany",
                "countryCode": "RU"
            },
            {
                "name": "Cherepanovo",
                "countryCode": "RU"
            },
            {
                "name": "Chulym",
                "countryCode": "RU"
            },
            {
                "name": "Dorogino",
                "countryCode": "RU"
            },
            {
                "name": "Dubrovino",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy",
                "countryCode": "RU"
            },
            {
                "name": "Iskitim",
                "countryCode": "RU"
            },
            {
                "name": "Iskitimskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Karasuk",
                "countryCode": "RU"
            },
            {
                "name": "Kargat",
                "countryCode": "RU"
            },
            {
                "name": "Kochenëvo",
                "countryCode": "RU"
            },
            {
                "name": "Kochki",
                "countryCode": "RU"
            },
            {
                "name": "Kolyvan’",
                "countryCode": "RU"
            },
            {
                "name": "Kol’tsovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoobsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnozërskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krivodanovka",
                "countryCode": "RU"
            },
            {
                "name": "Kudryashovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kupino",
                "countryCode": "RU"
            },
            {
                "name": "Kuybyshev",
                "countryCode": "RU"
            },
            {
                "name": "Kyshtovka",
                "countryCode": "RU"
            },
            {
                "name": "Linëvo",
                "countryCode": "RU"
            },
            {
                "name": "Listvyanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Maslyanino",
                "countryCode": "RU"
            },
            {
                "name": "Maslyaninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mochishche",
                "countryCode": "RU"
            },
            {
                "name": "Moshkovo",
                "countryCode": "RU"
            },
            {
                "name": "Moshkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novolugovoye",
                "countryCode": "RU"
            },
            {
                "name": "Novosibirsk",
                "countryCode": "RU"
            },
            {
                "name": "Novosibirskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ob’",
                "countryCode": "RU"
            },
            {
                "name": "Ordynskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ordynskoye",
                "countryCode": "RU"
            },
            {
                "name": "Polovinnoye",
                "countryCode": "RU"
            },
            {
                "name": "Prokudskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sadovyy",
                "countryCode": "RU"
            },
            {
                "name": "Severnoye",
                "countryCode": "RU"
            },
            {
                "name": "Severnyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sokur",
                "countryCode": "RU"
            },
            {
                "name": "Stantsionno-Oyashinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Suzun",
                "countryCode": "RU"
            },
            {
                "name": "Tashara",
                "countryCode": "RU"
            },
            {
                "name": "Tatarsk",
                "countryCode": "RU"
            },
            {
                "name": "Tatarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Toguchin",
                "countryCode": "RU"
            },
            {
                "name": "Tolmachëvo",
                "countryCode": "RU"
            },
            {
                "name": "Ubinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Tarka",
                "countryCode": "RU"
            },
            {
                "name": "Vengerovo",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Tula",
                "countryCode": "RU"
            },
            {
                "name": "Yarkovo",
                "countryCode": "RU"
            },
            {
                "name": "Zdvinsk",
                "countryCode": "RU"
            },
            {
                "name": "Azovo",
                "countryCode": "RU"
            },
            {
                "name": "Beregovoy",
                "countryCode": "RU"
            },
            {
                "name": "Bol’sherech’ye",
                "countryCode": "RU"
            },
            {
                "name": "Cherlak",
                "countryCode": "RU"
            },
            {
                "name": "Irtyshskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kalachinsk",
                "countryCode": "RU"
            },
            {
                "name": "Kolosovka",
                "countryCode": "RU"
            },
            {
                "name": "Kormilovka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Krutaya Gorka",
                "countryCode": "RU"
            },
            {
                "name": "Krutinka",
                "countryCode": "RU"
            },
            {
                "name": "Luzino",
                "countryCode": "RU"
            },
            {
                "name": "Lyubinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Muromtsevo",
                "countryCode": "RU"
            },
            {
                "name": "Muromtsevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nazyvayevsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Omka",
                "countryCode": "RU"
            },
            {
                "name": "Novoomskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novovarshavka",
                "countryCode": "RU"
            },
            {
                "name": "Odesskoye",
                "countryCode": "RU"
            },
            {
                "name": "Okoneshnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Omsk",
                "countryCode": "RU"
            },
            {
                "name": "Pavlogradka",
                "countryCode": "RU"
            },
            {
                "name": "Poltavka",
                "countryCode": "RU"
            },
            {
                "name": "Rostovka",
                "countryCode": "RU"
            },
            {
                "name": "Russkaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Sargatskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sedel’nikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sherbakul’",
                "countryCode": "RU"
            },
            {
                "name": "Tara",
                "countryCode": "RU"
            },
            {
                "name": "Tavricheskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tevriz",
                "countryCode": "RU"
            },
            {
                "name": "Troitsk",
                "countryCode": "RU"
            },
            {
                "name": "Tyukalinsk",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Ishim",
                "countryCode": "RU"
            },
            {
                "name": "Vkhodnoy",
                "countryCode": "RU"
            },
            {
                "name": "Znamenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Abdulino",
                "countryCode": "RU"
            },
            {
                "name": "Adamovka",
                "countryCode": "RU"
            },
            {
                "name": "Alandskoye",
                "countryCode": "RU"
            },
            {
                "name": "Asekeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Belyayevka",
                "countryCode": "RU"
            },
            {
                "name": "Buguruslan",
                "countryCode": "RU"
            },
            {
                "name": "Buzuluk",
                "countryCode": "RU"
            },
            {
                "name": "Dombarovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Energetik",
                "countryCode": "RU"
            },
            {
                "name": "Gay",
                "countryCode": "RU"
            },
            {
                "name": "Ilek",
                "countryCode": "RU"
            },
            {
                "name": "Kardailovo",
                "countryCode": "RU"
            },
            {
                "name": "Koltubanovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyets",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokholm",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Kommunar",
                "countryCode": "RU"
            },
            {
                "name": "Kurmanayevka",
                "countryCode": "RU"
            },
            {
                "name": "Kuvandyk",
                "countryCode": "RU"
            },
            {
                "name": "Kvarkeno",
                "countryCode": "RU"
            },
            {
                "name": "Kvarkenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Matveyevka",
                "countryCode": "RU"
            },
            {
                "name": "Mednogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Nezhinka",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnepavlovka",
                "countryCode": "RU"
            },
            {
                "name": "Novoorsk",
                "countryCode": "RU"
            },
            {
                "name": "Novoorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novorudnyy",
                "countryCode": "RU"
            },
            {
                "name": "Novosergiyevka",
                "countryCode": "RU"
            },
            {
                "name": "Novotroitsk",
                "countryCode": "RU"
            },
            {
                "name": "Orenburg",
                "countryCode": "RU"
            },
            {
                "name": "Orsk",
                "countryCode": "RU"
            },
            {
                "name": "Perevolotskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pleshanovo",
                "countryCode": "RU"
            },
            {
                "name": "Podgorodnyaya Pokrovka",
                "countryCode": "RU"
            },
            {
                "name": "Prigorodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sakmara",
                "countryCode": "RU"
            },
            {
                "name": "Saraktash",
                "countryCode": "RU"
            },
            {
                "name": "Saraktashskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Severnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sharlyk",
                "countryCode": "RU"
            },
            {
                "name": "Shil’da",
                "countryCode": "RU"
            },
            {
                "name": "Sol’-Iletsk",
                "countryCode": "RU"
            },
            {
                "name": "Sorochinsk",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Akkermanovka",
                "countryCode": "RU"
            },
            {
                "name": "Svetlyy",
                "countryCode": "RU"
            },
            {
                "name": "Tashla",
                "countryCode": "RU"
            },
            {
                "name": "Tatarskaya Kargala",
                "countryCode": "RU"
            },
            {
                "name": "Totskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tyul’gan",
                "countryCode": "RU"
            },
            {
                "name": "Yasnyy",
                "countryCode": "RU"
            },
            {
                "name": "Yelizavetinka",
                "countryCode": "RU"
            },
            {
                "name": "Bolkhov",
                "countryCode": "RU"
            },
            {
                "name": "Bolkhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dmitrovsk-Orlovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Glazunovka",
                "countryCode": "RU"
            },
            {
                "name": "Khomutovo",
                "countryCode": "RU"
            },
            {
                "name": "Khotynets",
                "countryCode": "RU"
            },
            {
                "name": "Kolpny",
                "countryCode": "RU"
            },
            {
                "name": "Korsakovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Zarya",
                "countryCode": "RU"
            },
            {
                "name": "Kromy",
                "countryCode": "RU"
            },
            {
                "name": "Livny",
                "countryCode": "RU"
            },
            {
                "name": "Maloarkhangel’sk",
                "countryCode": "RU"
            },
            {
                "name": "Mtsensk",
                "countryCode": "RU"
            },
            {
                "name": "Naryshkino",
                "countryCode": "RU"
            },
            {
                "name": "Novosil’",
                "countryCode": "RU"
            },
            {
                "name": "Orël",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Shablykino",
                "countryCode": "RU"
            },
            {
                "name": "Soskovo",
                "countryCode": "RU"
            },
            {
                "name": "Spasskoye-Lutovinovo",
                "countryCode": "RU"
            },
            {
                "name": "Trosna",
                "countryCode": "RU"
            },
            {
                "name": "Verkhov’ye",
                "countryCode": "RU"
            },
            {
                "name": "Vyshneye Dolgoye",
                "countryCode": "RU"
            },
            {
                "name": "Zalegoshch’",
                "countryCode": "RU"
            },
            {
                "name": "Zmiyëvka",
                "countryCode": "RU"
            },
            {
                "name": "Znamenka",
                "countryCode": "RU"
            },
            {
                "name": "Znamenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Barda",
                "countryCode": "RU"
            },
            {
                "name": "Berezniki",
                "countryCode": "RU"
            },
            {
                "name": "Bershet’",
                "countryCode": "RU"
            },
            {
                "name": "Chastyye",
                "countryCode": "RU"
            },
            {
                "name": "Chaykovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Chaykovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Cherdyn’",
                "countryCode": "RU"
            },
            {
                "name": "Chermoz",
                "countryCode": "RU"
            },
            {
                "name": "Chernushka",
                "countryCode": "RU"
            },
            {
                "name": "Chusovoy",
                "countryCode": "RU"
            },
            {
                "name": "Dobryanka",
                "countryCode": "RU"
            },
            {
                "name": "Ferma",
                "countryCode": "RU"
            },
            {
                "name": "Filippovka",
                "countryCode": "RU"
            },
            {
                "name": "Foki",
                "countryCode": "RU"
            },
            {
                "name": "Froly",
                "countryCode": "RU"
            },
            {
                "name": "Gamovo",
                "countryCode": "RU"
            },
            {
                "name": "Gayny",
                "countryCode": "RU"
            },
            {
                "name": "Gornozavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Gremyachinsk",
                "countryCode": "RU"
            },
            {
                "name": "Gubakha",
                "countryCode": "RU"
            },
            {
                "name": "Kalino",
                "countryCode": "RU"
            },
            {
                "name": "Karagay",
                "countryCode": "RU"
            },
            {
                "name": "Kerchevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kizel",
                "countryCode": "RU"
            },
            {
                "name": "Kochevo",
                "countryCode": "RU"
            },
            {
                "name": "Komarikhinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kondratovo",
                "countryCode": "RU"
            },
            {
                "name": "Kosa",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokamsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnovishersk",
                "countryCode": "RU"
            },
            {
                "name": "Kudymkar",
                "countryCode": "RU"
            },
            {
                "name": "Kukushtan",
                "countryCode": "RU"
            },
            {
                "name": "Kultayevo",
                "countryCode": "RU"
            },
            {
                "name": "Kungur",
                "countryCode": "RU"
            },
            {
                "name": "Kuyeda",
                "countryCode": "RU"
            },
            {
                "name": "Lobanovo",
                "countryCode": "RU"
            },
            {
                "name": "Lyamino",
                "countryCode": "RU"
            },
            {
                "name": "Lys’va",
                "countryCode": "RU"
            },
            {
                "name": "Maykor",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevo",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Lyady",
                "countryCode": "RU"
            },
            {
                "name": "Nyrob",
                "countryCode": "RU"
            },
            {
                "name": "Nytva",
                "countryCode": "RU"
            },
            {
                "name": "Ochër",
                "countryCode": "RU"
            },
            {
                "name": "Okhansk",
                "countryCode": "RU"
            },
            {
                "name": "Orda",
                "countryCode": "RU"
            },
            {
                "name": "Osa",
                "countryCode": "RU"
            },
            {
                "name": "Overyata",
                "countryCode": "RU"
            },
            {
                "name": "Pashiya",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Perm",
                "countryCode": "RU"
            },
            {
                "name": "Polazna",
                "countryCode": "RU"
            },
            {
                "name": "Pozhva",
                "countryCode": "RU"
            },
            {
                "name": "Sarany",
                "countryCode": "RU"
            },
            {
                "name": "Sars",
                "countryCode": "RU"
            },
            {
                "name": "Severnyy-Kospashskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shakhta",
                "countryCode": "RU"
            },
            {
                "name": "Shirokovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shumikhinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Siva",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Solikamsk",
                "countryCode": "RU"
            },
            {
                "name": "Suksun",
                "countryCode": "RU"
            },
            {
                "name": "Sylva",
                "countryCode": "RU"
            },
            {
                "name": "Tëplaya Gora",
                "countryCode": "RU"
            },
            {
                "name": "Ugleural’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Uinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Usol’ye",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kachka",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kishert’",
                "countryCode": "RU"
            },
            {
                "name": "Vereshchagino",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnechusovskiye Gorodki",
                "countryCode": "RU"
            },
            {
                "name": "Yayva",
                "countryCode": "RU"
            },
            {
                "name": "Yelovo",
                "countryCode": "RU"
            },
            {
                "name": "Yubileynyy",
                "countryCode": "RU"
            },
            {
                "name": "Yug",
                "countryCode": "RU"
            },
            {
                "name": "Yugo-Kamskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yurla",
                "countryCode": "RU"
            },
            {
                "name": "Yus’vinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhnyy-Kospashskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zvëzdnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zyukayka",
                "countryCode": "RU"
            },
            {
                "name": "Bashmakovo",
                "countryCode": "RU"
            },
            {
                "name": "Bekovo",
                "countryCode": "RU"
            },
            {
                "name": "Belinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Bessonovka",
                "countryCode": "RU"
            },
            {
                "name": "Bogoslovka",
                "countryCode": "RU"
            },
            {
                "name": "Chaadayevka",
                "countryCode": "RU"
            },
            {
                "name": "Chemodanovka",
                "countryCode": "RU"
            },
            {
                "name": "Gorodishche",
                "countryCode": "RU"
            },
            {
                "name": "Grabovo",
                "countryCode": "RU"
            },
            {
                "name": "Inderka",
                "countryCode": "RU"
            },
            {
                "name": "Issa",
                "countryCode": "RU"
            },
            {
                "name": "Issinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kanayevka",
                "countryCode": "RU"
            },
            {
                "name": "Kolyshley",
                "countryCode": "RU"
            },
            {
                "name": "Kondol’",
                "countryCode": "RU"
            },
            {
                "name": "Kuznetsk",
                "countryCode": "RU"
            },
            {
                "name": "Kuznetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lermontovo",
                "countryCode": "RU"
            },
            {
                "name": "Lopatino",
                "countryCode": "RU"
            },
            {
                "name": "Lunino",
                "countryCode": "RU"
            },
            {
                "name": "Makhalino",
                "countryCode": "RU"
            },
            {
                "name": "Malaya Serdoba",
                "countryCode": "RU"
            },
            {
                "name": "Mokshan",
                "countryCode": "RU"
            },
            {
                "name": "Narovchat",
                "countryCode": "RU"
            },
            {
                "name": "Neverkino",
                "countryCode": "RU"
            },
            {
                "name": "Neverkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nikol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Lomov",
                "countryCode": "RU"
            },
            {
                "name": "Pachelma",
                "countryCode": "RU"
            },
            {
                "name": "Penza",
                "countryCode": "RU"
            },
            {
                "name": "Poim",
                "countryCode": "RU"
            },
            {
                "name": "Poselki",
                "countryCode": "RU"
            },
            {
                "name": "Russkiy Kameshkir",
                "countryCode": "RU"
            },
            {
                "name": "Serdobsk",
                "countryCode": "RU"
            },
            {
                "name": "Shemysheyka",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovoborsk",
                "countryCode": "RU"
            },
            {
                "name": "Spassk",
                "countryCode": "RU"
            },
            {
                "name": "Srednyaya Yelyuzan’",
                "countryCode": "RU"
            },
            {
                "name": "Sura",
                "countryCode": "RU"
            },
            {
                "name": "Sursk",
                "countryCode": "RU"
            },
            {
                "name": "Tamala",
                "countryCode": "RU"
            },
            {
                "name": "Vadinsk",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Lomov",
                "countryCode": "RU"
            },
            {
                "name": "Verkhozim",
                "countryCode": "RU"
            },
            {
                "name": "Zarechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zasechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Zemetchino",
                "countryCode": "RU"
            },
            {
                "name": "Anuchino",
                "countryCode": "RU"
            },
            {
                "name": "Anuchinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arsen’yev",
                "countryCode": "RU"
            },
            {
                "name": "Artëm",
                "countryCode": "RU"
            },
            {
                "name": "Barabash",
                "countryCode": "RU"
            },
            {
                "name": "Blagodatnoye",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoy Kamen’",
                "countryCode": "RU"
            },
            {
                "name": "Chernigovka",
                "countryCode": "RU"
            },
            {
                "name": "Chernigovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chernyshëvka",
                "countryCode": "RU"
            },
            {
                "name": "Chkalovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chuguyevka",
                "countryCode": "RU"
            },
            {
                "name": "Dalnegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Dalnerechensk",
                "countryCode": "RU"
            },
            {
                "name": "Dostoyevka",
                "countryCode": "RU"
            },
            {
                "name": "Dunay",
                "countryCode": "RU"
            },
            {
                "name": "Emar",
                "countryCode": "RU"
            },
            {
                "name": "Fokino",
                "countryCode": "RU"
            },
            {
                "name": "Gornorechenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gornyye Klyuchi",
                "countryCode": "RU"
            },
            {
                "name": "Grazhdanka",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovka",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kamen’-Rybolov",
                "countryCode": "RU"
            },
            {
                "name": "Kavalerovo",
                "countryCode": "RU"
            },
            {
                "name": "Khasanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khorol’",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Knevichi",
                "countryCode": "RU"
            },
            {
                "name": "Kraskino",
                "countryCode": "RU"
            },
            {
                "name": "Krasnorechenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lazo",
                "countryCode": "RU"
            },
            {
                "name": "Lazovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lesozavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Lipovtsy",
                "countryCode": "RU"
            },
            {
                "name": "Livadiya",
                "countryCode": "RU"
            },
            {
                "name": "Luchegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Luchki",
                "countryCode": "RU"
            },
            {
                "name": "Lyalichi",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovka",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mnogoudobnoye",
                "countryCode": "RU"
            },
            {
                "name": "Monastyrishche",
                "countryCode": "RU"
            },
            {
                "name": "Nadezhdinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nakhodka",
                "countryCode": "RU"
            },
            {
                "name": "Novonikol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Novopokrovka",
                "countryCode": "RU"
            },
            {
                "name": "Novoshakhtinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novosysoyevka",
                "countryCode": "RU"
            },
            {
                "name": "Novyy",
                "countryCode": "RU"
            },
            {
                "name": "Ol’ga",
                "countryCode": "RU"
            },
            {
                "name": "Partizansk",
                "countryCode": "RU"
            },
            {
                "name": "Plastun",
                "countryCode": "RU"
            },
            {
                "name": "Pogranichnyy",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovka",
                "countryCode": "RU"
            },
            {
                "name": "Popova",
                "countryCode": "RU"
            },
            {
                "name": "Preobrazheniye",
                "countryCode": "RU"
            },
            {
                "name": "Primorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Putyatin",
                "countryCode": "RU"
            },
            {
                "name": "Razdol’noye",
                "countryCode": "RU"
            },
            {
                "name": "Rettikhovka",
                "countryCode": "RU"
            },
            {
                "name": "Romanovka",
                "countryCode": "RU"
            },
            {
                "name": "Rudnaya Pristan’",
                "countryCode": "RU"
            },
            {
                "name": "Rudnyy",
                "countryCode": "RU"
            },
            {
                "name": "Russkiy",
                "countryCode": "RU"
            },
            {
                "name": "Sergeyevka",
                "countryCode": "RU"
            },
            {
                "name": "Shkotovo",
                "countryCode": "RU"
            },
            {
                "name": "Shkotovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shtykovo",
                "countryCode": "RU"
            },
            {
                "name": "Sivakovka",
                "countryCode": "RU"
            },
            {
                "name": "Slavyanka",
                "countryCode": "RU"
            },
            {
                "name": "Smolyaninovo",
                "countryCode": "RU"
            },
            {
                "name": "Spassk-Dal’niy",
                "countryCode": "RU"
            },
            {
                "name": "Spasskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Spasskoye",
                "countryCode": "RU"
            },
            {
                "name": "Svetlaya",
                "countryCode": "RU"
            },
            {
                "name": "Tavrichanka",
                "countryCode": "RU"
            },
            {
                "name": "Terney",
                "countryCode": "RU"
            },
            {
                "name": "Trudovoye",
                "countryCode": "RU"
            },
            {
                "name": "Uglekamensk",
                "countryCode": "RU"
            },
            {
                "name": "Uglovoye",
                "countryCode": "RU"
            },
            {
                "name": "Ussuriysk",
                "countryCode": "RU"
            },
            {
                "name": "Vladimiro-Aleksandrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Vladivostok",
                "countryCode": "RU"
            },
            {
                "name": "Volchanets",
                "countryCode": "RU"
            },
            {
                "name": "Vol’no-Nadezhdinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Vostok",
                "countryCode": "RU"
            },
            {
                "name": "Vozdvizhenka",
                "countryCode": "RU"
            },
            {
                "name": "Vrangel’",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevka",
                "countryCode": "RU"
            },
            {
                "name": "Yakovlevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yekaterinovka",
                "countryCode": "RU"
            },
            {
                "name": "Zarubino",
                "countryCode": "RU"
            },
            {
                "name": "Zavodskoy",
                "countryCode": "RU"
            },
            {
                "name": "Zharikovo",
                "countryCode": "RU"
            },
            {
                "name": "Bezhanitsy",
                "countryCode": "RU"
            },
            {
                "name": "Dedovichi",
                "countryCode": "RU"
            },
            {
                "name": "Dno",
                "countryCode": "RU"
            },
            {
                "name": "Gdov",
                "countryCode": "RU"
            },
            {
                "name": "Gdovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Idritsa",
                "countryCode": "RU"
            },
            {
                "name": "Izborsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorodsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Luch",
                "countryCode": "RU"
            },
            {
                "name": "Kun’inskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Loknya",
                "countryCode": "RU"
            },
            {
                "name": "Nevel’",
                "countryCode": "RU"
            },
            {
                "name": "Novorzhev",
                "countryCode": "RU"
            },
            {
                "name": "Novorzhevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novosokol’nicheskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novosokol’niki",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Izborsk",
                "countryCode": "RU"
            },
            {
                "name": "Opochka",
                "countryCode": "RU"
            },
            {
                "name": "Ostrov",
                "countryCode": "RU"
            },
            {
                "name": "Ostrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Palkino",
                "countryCode": "RU"
            },
            {
                "name": "Palkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pechorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pechory",
                "countryCode": "RU"
            },
            {
                "name": "Plyussa",
                "countryCode": "RU"
            },
            {
                "name": "Porkhov",
                "countryCode": "RU"
            },
            {
                "name": "Porkhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pskov",
                "countryCode": "RU"
            },
            {
                "name": "Pskovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pushkino-Gorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pushkinskiye Gory",
                "countryCode": "RU"
            },
            {
                "name": "Pustoshka",
                "countryCode": "RU"
            },
            {
                "name": "Pustoshkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pytalovo",
                "countryCode": "RU"
            },
            {
                "name": "Pytalovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sebezh",
                "countryCode": "RU"
            },
            {
                "name": "Sebezhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Serëdka",
                "countryCode": "RU"
            },
            {
                "name": "Strugi-Krasnyye",
                "countryCode": "RU"
            },
            {
                "name": "Strugo-Krasnenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Usvyaty",
                "countryCode": "RU"
            },
            {
                "name": "Velikiye Luki",
                "countryCode": "RU"
            },
            {
                "name": "Velikolukskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vybor",
                "countryCode": "RU"
            },
            {
                "name": "Aksay",
                "countryCode": "RU"
            },
            {
                "name": "Almaznyy",
                "countryCode": "RU"
            },
            {
                "name": "Anastasiyevka",
                "countryCode": "RU"
            },
            {
                "name": "Ayutinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Azov",
                "countryCode": "RU"
            },
            {
                "name": "Bagayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Bataysk",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Kalitva",
                "countryCode": "RU"
            },
            {
                "name": "Bokovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Bystrogorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Chaltyr",
                "countryCode": "RU"
            },
            {
                "name": "Chertkovo",
                "countryCode": "RU"
            },
            {
                "name": "Donetsk",
                "countryCode": "RU"
            },
            {
                "name": "Donskoy",
                "countryCode": "RU"
            },
            {
                "name": "Dubovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Gigant",
                "countryCode": "RU"
            },
            {
                "name": "Glubokiy",
                "countryCode": "RU"
            },
            {
                "name": "Gornyatskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy",
                "countryCode": "RU"
            },
            {
                "name": "Grushevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gukovo",
                "countryCode": "RU"
            },
            {
                "name": "Gundorovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kagal’nitskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kalinin",
                "countryCode": "RU"
            },
            {
                "name": "Kamenolomni",
                "countryCode": "RU"
            },
            {
                "name": "Kamensk-Shakhtinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kashary",
                "countryCode": "RU"
            },
            {
                "name": "Kazanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Koksovyy",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnovka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarskaya",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Sulin",
                "countryCode": "RU"
            },
            {
                "name": "Krasyukovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Krivyanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Krym",
                "countryCode": "RU"
            },
            {
                "name": "Kuleshovka",
                "countryCode": "RU"
            },
            {
                "name": "Kuybyshevo",
                "countryCode": "RU"
            },
            {
                "name": "Letnik",
                "countryCode": "RU"
            },
            {
                "name": "Likhovskoy",
                "countryCode": "RU"
            },
            {
                "name": "Likhoy",
                "countryCode": "RU"
            },
            {
                "name": "Martynovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Masalovka",
                "countryCode": "RU"
            },
            {
                "name": "Matveyev Kurgan",
                "countryCode": "RU"
            },
            {
                "name": "Mayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mechetinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Melikhovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Millerovo",
                "countryCode": "RU"
            },
            {
                "name": "Milyutinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Milyutinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Morozovsk",
                "countryCode": "RU"
            },
            {
                "name": "Nedvigovka",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novobataysk",
                "countryCode": "RU"
            },
            {
                "name": "Novobessergenovka",
                "countryCode": "RU"
            },
            {
                "name": "Novocherkassk",
                "countryCode": "RU"
            },
            {
                "name": "Novoshakhtinsk",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Yegorlyk",
                "countryCode": "RU"
            },
            {
                "name": "Oblivskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ol’ginskaya",
                "countryCode": "RU"
            },
            {
                "name": "Orlovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Persianovka",
                "countryCode": "RU"
            },
            {
                "name": "Peschanokopskoye",
                "countryCode": "RU"
            },
            {
                "name": "Peshkovo",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Port-Katon",
                "countryCode": "RU"
            },
            {
                "name": "Primorka",
                "countryCode": "RU"
            },
            {
                "name": "Proletarsk",
                "countryCode": "RU"
            },
            {
                "name": "Rassvet",
                "countryCode": "RU"
            },
            {
                "name": "Remontnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rodionovo-Nesvetaiskoye",
                "countryCode": "RU"
            },
            {
                "name": "Rodionovo-Nesvetayskaya",
                "countryCode": "RU"
            },
            {
                "name": "Romanovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Rostov-na-Donu",
                "countryCode": "RU"
            },
            {
                "name": "Sal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Samarskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sambek",
                "countryCode": "RU"
            },
            {
                "name": "Sandata",
                "countryCode": "RU"
            },
            {
                "name": "Semikarakorsk",
                "countryCode": "RU"
            },
            {
                "name": "Shakhty",
                "countryCode": "RU"
            },
            {
                "name": "Sholokhovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sinegorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sinyavskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sokolovo-Kundryuchenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskaya",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Stanitsa",
                "countryCode": "RU"
            },
            {
                "name": "Starocherkasskaya",
                "countryCode": "RU"
            },
            {
                "name": "Taganrog",
                "countryCode": "RU"
            },
            {
                "name": "Talovyy",
                "countryCode": "RU"
            },
            {
                "name": "Tanais",
                "countryCode": "RU"
            },
            {
                "name": "Tarasovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tatsinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tselina",
                "countryCode": "RU"
            },
            {
                "name": "Tsimlyansk",
                "countryCode": "RU"
            },
            {
                "name": "Uglegorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Uglerodovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Donetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Veshenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Vesëlyy",
                "countryCode": "RU"
            },
            {
                "name": "Volgodonsk",
                "countryCode": "RU"
            },
            {
                "name": "Yegorlykskaya",
                "countryCode": "RU"
            },
            {
                "name": "Zavetnoye",
                "countryCode": "RU"
            },
            {
                "name": "Zernograd",
                "countryCode": "RU"
            },
            {
                "name": "Zhirnov",
                "countryCode": "RU"
            },
            {
                "name": "Zimovniki",
                "countryCode": "RU"
            },
            {
                "name": "Zverevo",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandro-Nevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Bagramovo",
                "countryCode": "RU"
            },
            {
                "name": "Chuchkovo",
                "countryCode": "RU"
            },
            {
                "name": "Gus’-Zheleznyy",
                "countryCode": "RU"
            },
            {
                "name": "Izhevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kadom",
                "countryCode": "RU"
            },
            {
                "name": "Kasimov",
                "countryCode": "RU"
            },
            {
                "name": "Kasimovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kiritsy",
                "countryCode": "RU"
            },
            {
                "name": "Klepikovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Korablino",
                "countryCode": "RU"
            },
            {
                "name": "Lashma",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylov",
                "countryCode": "RU"
            },
            {
                "name": "Miloslavskoye",
                "countryCode": "RU"
            },
            {
                "name": "Murmino",
                "countryCode": "RU"
            },
            {
                "name": "Novomichurinsk",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Pavelets",
                "countryCode": "RU"
            },
            {
                "name": "Pitelino",
                "countryCode": "RU"
            },
            {
                "name": "Polyany",
                "countryCode": "RU"
            },
            {
                "name": "Pronsk",
                "countryCode": "RU"
            },
            {
                "name": "Putyatino",
                "countryCode": "RU"
            },
            {
                "name": "Ryazanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ryazan’",
                "countryCode": "RU"
            },
            {
                "name": "Ryazhsk",
                "countryCode": "RU"
            },
            {
                "name": "Rybnovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rybnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sapozhok",
                "countryCode": "RU"
            },
            {
                "name": "Sarai",
                "countryCode": "RU"
            },
            {
                "name": "Sasovo",
                "countryCode": "RU"
            },
            {
                "name": "Shatsk",
                "countryCode": "RU"
            },
            {
                "name": "Shilovo",
                "countryCode": "RU"
            },
            {
                "name": "Skopin",
                "countryCode": "RU"
            },
            {
                "name": "Solotcha",
                "countryCode": "RU"
            },
            {
                "name": "Spas-Klepiki",
                "countryCode": "RU"
            },
            {
                "name": "Spassk-Ryazanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Starozhilovo",
                "countryCode": "RU"
            },
            {
                "name": "Syntul",
                "countryCode": "RU"
            },
            {
                "name": "Tuma",
                "countryCode": "RU"
            },
            {
                "name": "Ukholovo",
                "countryCode": "RU"
            },
            {
                "name": "Vilenka",
                "countryCode": "RU"
            },
            {
                "name": "Yambirno",
                "countryCode": "RU"
            },
            {
                "name": "Yelat’ma",
                "countryCode": "RU"
            },
            {
                "name": "Yermish’",
                "countryCode": "RU"
            },
            {
                "name": "Zakharovo",
                "countryCode": "RU"
            },
            {
                "name": "Zakharovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zarechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Abyysky District",
                "countryCode": "RU"
            },
            {
                "name": "Aldan",
                "countryCode": "RU"
            },
            {
                "name": "Allaikhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Almaznyy",
                "countryCode": "RU"
            },
            {
                "name": "Amga",
                "countryCode": "RU"
            },
            {
                "name": "Anabarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aykhal",
                "countryCode": "RU"
            },
            {
                "name": "Batagay",
                "countryCode": "RU"
            },
            {
                "name": "Batagay-Alyta",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Gora",
                "countryCode": "RU"
            },
            {
                "name": "Berdigestyakh",
                "countryCode": "RU"
            },
            {
                "name": "Berkakit",
                "countryCode": "RU"
            },
            {
                "name": "Borogontsy",
                "countryCode": "RU"
            },
            {
                "name": "Bulunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chernyshevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Cherskiy",
                "countryCode": "RU"
            },
            {
                "name": "Chokurdakh",
                "countryCode": "RU"
            },
            {
                "name": "Chul’man",
                "countryCode": "RU"
            },
            {
                "name": "Churapcha",
                "countryCode": "RU"
            },
            {
                "name": "Deputatsky",
                "countryCode": "RU"
            },
            {
                "name": "Dzhebariki-Khaya",
                "countryCode": "RU"
            },
            {
                "name": "Eveno-Bytantaysky National District",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kangalassy",
                "countryCode": "RU"
            },
            {
                "name": "Khandyga",
                "countryCode": "RU"
            },
            {
                "name": "Khatassy",
                "countryCode": "RU"
            },
            {
                "name": "Kobyayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kysyl-Syr",
                "countryCode": "RU"
            },
            {
                "name": "Lebedinyy",
                "countryCode": "RU"
            },
            {
                "name": "Leninskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lensk",
                "countryCode": "RU"
            },
            {
                "name": "Magan",
                "countryCode": "RU"
            },
            {
                "name": "Markha",
                "countryCode": "RU"
            },
            {
                "name": "Mayya",
                "countryCode": "RU"
            },
            {
                "name": "Mirninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mirny",
                "countryCode": "RU"
            },
            {
                "name": "Mokhsogollokh",
                "countryCode": "RU"
            },
            {
                "name": "Momsky District",
                "countryCode": "RU"
            },
            {
                "name": "Namskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Namtsy",
                "countryCode": "RU"
            },
            {
                "name": "Neryungri",
                "countryCode": "RU"
            },
            {
                "name": "Nezhdaninskoe",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Bestyakh",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Kuranakh",
                "countryCode": "RU"
            },
            {
                "name": "Nyurba",
                "countryCode": "RU"
            },
            {
                "name": "Nyurbinsky District",
                "countryCode": "RU"
            },
            {
                "name": "Olenyok",
                "countryCode": "RU"
            },
            {
                "name": "Olenyoksky District",
                "countryCode": "RU"
            },
            {
                "name": "Olyokminsk",
                "countryCode": "RU"
            },
            {
                "name": "Olyokminsky District",
                "countryCode": "RU"
            },
            {
                "name": "Oymyakonskiy District",
                "countryCode": "RU"
            },
            {
                "name": "Peleduy",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Sangar",
                "countryCode": "RU"
            },
            {
                "name": "Saskylakh",
                "countryCode": "RU"
            },
            {
                "name": "Serebryanyy Bor",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Srednekolymsk",
                "countryCode": "RU"
            },
            {
                "name": "Srednekolymsky District",
                "countryCode": "RU"
            },
            {
                "name": "Suntar",
                "countryCode": "RU"
            },
            {
                "name": "Suntarskiy District",
                "countryCode": "RU"
            },
            {
                "name": "Tabaga",
                "countryCode": "RU"
            },
            {
                "name": "Tiksi",
                "countryCode": "RU"
            },
            {
                "name": "Tommot",
                "countryCode": "RU"
            },
            {
                "name": "Tomponskiy District",
                "countryCode": "RU"
            },
            {
                "name": "Udachny",
                "countryCode": "RU"
            },
            {
                "name": "Ust-Aldansky District",
                "countryCode": "RU"
            },
            {
                "name": "Ust-Kuyga",
                "countryCode": "RU"
            },
            {
                "name": "Ust-Maya",
                "countryCode": "RU"
            },
            {
                "name": "Ust-Nera",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Yanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnekolymsky District",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnevilyuysk",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnevilyuysky District",
                "countryCode": "RU"
            },
            {
                "name": "Verkhoyansk",
                "countryCode": "RU"
            },
            {
                "name": "Verkhoyansky District",
                "countryCode": "RU"
            },
            {
                "name": "Vilyuysk",
                "countryCode": "RU"
            },
            {
                "name": "Vilyuyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vilyuysky District",
                "countryCode": "RU"
            },
            {
                "name": "Vitim",
                "countryCode": "RU"
            },
            {
                "name": "Yakutsk",
                "countryCode": "RU"
            },
            {
                "name": "Ytyk-Kyuyël’",
                "countryCode": "RU"
            },
            {
                "name": "Zhatay",
                "countryCode": "RU"
            },
            {
                "name": "Zhigansk",
                "countryCode": "RU"
            },
            {
                "name": "Zhigansky District",
                "countryCode": "RU"
            },
            {
                "name": "Zyryanka",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovsk-Sakhalinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Aniva",
                "countryCode": "RU"
            },
            {
                "name": "Boshnyakovo",
                "countryCode": "RU"
            },
            {
                "name": "Bykov",
                "countryCode": "RU"
            },
            {
                "name": "Chekhov",
                "countryCode": "RU"
            },
            {
                "name": "Dolinsk",
                "countryCode": "RU"
            },
            {
                "name": "Gastello",
                "countryCode": "RU"
            },
            {
                "name": "Gornozavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Kholmsk",
                "countryCode": "RU"
            },
            {
                "name": "Korsakov",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Kurilsky District",
                "countryCode": "RU"
            },
            {
                "name": "Kuril’sk",
                "countryCode": "RU"
            },
            {
                "name": "Leonidovo",
                "countryCode": "RU"
            },
            {
                "name": "Makarov",
                "countryCode": "RU"
            },
            {
                "name": "Mgachi",
                "countryCode": "RU"
            },
            {
                "name": "Nevel’sk",
                "countryCode": "RU"
            },
            {
                "name": "Nogliki",
                "countryCode": "RU"
            },
            {
                "name": "Okha",
                "countryCode": "RU"
            },
            {
                "name": "Otrada",
                "countryCode": "RU"
            },
            {
                "name": "Poronaysk",
                "countryCode": "RU"
            },
            {
                "name": "Pravda",
                "countryCode": "RU"
            },
            {
                "name": "Severo-Kuril’sk",
                "countryCode": "RU"
            },
            {
                "name": "Shakhtersk",
                "countryCode": "RU"
            },
            {
                "name": "Shebunino",
                "countryCode": "RU"
            },
            {
                "name": "Shikotan",
                "countryCode": "RU"
            },
            {
                "name": "Sinegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Smirnykh",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Tomari",
                "countryCode": "RU"
            },
            {
                "name": "Troitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Tungor",
                "countryCode": "RU"
            },
            {
                "name": "Tymovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Uglegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Uglegorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uglezavodsk",
                "countryCode": "RU"
            },
            {
                "name": "Vakhrushev",
                "countryCode": "RU"
            },
            {
                "name": "Vostok",
                "countryCode": "RU"
            },
            {
                "name": "Yablochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhno-Kurilsk",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhno-Kurilsky District",
                "countryCode": "RU"
            },
            {
                "name": "Yuzhno-Sakhalinsk",
                "countryCode": "RU"
            },
            {
                "name": "Bakhilovo",
                "countryCode": "RU"
            },
            {
                "name": "Balasheyka",
                "countryCode": "RU"
            },
            {
                "name": "Berëza",
                "countryCode": "RU"
            },
            {
                "name": "Bezenchuk",
                "countryCode": "RU"
            },
            {
                "name": "Bogatoye",
                "countryCode": "RU"
            },
            {
                "name": "Bogatyr’",
                "countryCode": "RU"
            },
            {
                "name": "Borskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chapayevsk",
                "countryCode": "RU"
            },
            {
                "name": "Chelno-Vershiny",
                "countryCode": "RU"
            },
            {
                "name": "Dubovyy Umët",
                "countryCode": "RU"
            },
            {
                "name": "Georgiyevka",
                "countryCode": "RU"
            },
            {
                "name": "Isakly",
                "countryCode": "RU"
            },
            {
                "name": "Kamyshla",
                "countryCode": "RU"
            },
            {
                "name": "Khryashchevka",
                "countryCode": "RU"
            },
            {
                "name": "Khvorostyanka",
                "countryCode": "RU"
            },
            {
                "name": "Kinel’",
                "countryCode": "RU"
            },
            {
                "name": "Kinel’-Cherkassy",
                "countryCode": "RU"
            },
            {
                "name": "Kinel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Klyavlino",
                "countryCode": "RU"
            },
            {
                "name": "Koshki",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoyarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Krotovka",
                "countryCode": "RU"
            },
            {
                "name": "Kurumoch",
                "countryCode": "RU"
            },
            {
                "name": "Mayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Mezhdurechensk",
                "countryCode": "RU"
            },
            {
                "name": "Mirnyy",
                "countryCode": "RU"
            },
            {
                "name": "Neftegorsk",
                "countryCode": "RU"
            },
            {
                "name": "Neftegorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novokuybyshevsk",
                "countryCode": "RU"
            },
            {
                "name": "Novosemeykino",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Buyan",
                "countryCode": "RU"
            },
            {
                "name": "Obsharovka",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’sk",
                "countryCode": "RU"
            },
            {
                "name": "Osinki",
                "countryCode": "RU"
            },
            {
                "name": "Otradnyy",
                "countryCode": "RU"
            },
            {
                "name": "Perevoloki",
                "countryCode": "RU"
            },
            {
                "name": "Pestravka",
                "countryCode": "RU"
            },
            {
                "name": "Petra-Dubrava",
                "countryCode": "RU"
            },
            {
                "name": "Podsolnechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Podstepki",
                "countryCode": "RU"
            },
            {
                "name": "Pokhvistnevo",
                "countryCode": "RU"
            },
            {
                "name": "Povolzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pribrezhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Roschinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Rozhdestveno",
                "countryCode": "RU"
            },
            {
                "name": "Samara",
                "countryCode": "RU"
            },
            {
                "name": "Sernovodsk",
                "countryCode": "RU"
            },
            {
                "name": "Shentala",
                "countryCode": "RU"
            },
            {
                "name": "Shigony",
                "countryCode": "RU"
            },
            {
                "name": "Smyshlyayevka",
                "countryCode": "RU"
            },
            {
                "name": "Spiridonovka",
                "countryCode": "RU"
            },
            {
                "name": "Sukhodol",
                "countryCode": "RU"
            },
            {
                "name": "Surgut",
                "countryCode": "RU"
            },
            {
                "name": "Syzran’",
                "countryCode": "RU"
            },
            {
                "name": "Tol’yatti",
                "countryCode": "RU"
            },
            {
                "name": "Utevka",
                "countryCode": "RU"
            },
            {
                "name": "Varlamovo",
                "countryCode": "RU"
            },
            {
                "name": "Volzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yelkhovka",
                "countryCode": "RU"
            },
            {
                "name": "Zhigulevsk",
                "countryCode": "RU"
            },
            {
                "name": "Zhiguli",
                "countryCode": "RU"
            },
            {
                "name": "Zol’noye",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrov Gay",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevka",
                "countryCode": "RU"
            },
            {
                "name": "Arkadak",
                "countryCode": "RU"
            },
            {
                "name": "Atkarsk",
                "countryCode": "RU"
            },
            {
                "name": "Atkarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Balakovo",
                "countryCode": "RU"
            },
            {
                "name": "Balashov",
                "countryCode": "RU"
            },
            {
                "name": "Baltay",
                "countryCode": "RU"
            },
            {
                "name": "Baltayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bazarno-Karabulakskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bazarnyy Karabulak",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoy Karay",
                "countryCode": "RU"
            },
            {
                "name": "Cherkasskoye",
                "countryCode": "RU"
            },
            {
                "name": "Dergachi",
                "countryCode": "RU"
            },
            {
                "name": "Dukhovnitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Engel’s",
                "countryCode": "RU"
            },
            {
                "name": "Engel’sskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy",
                "countryCode": "RU"
            },
            {
                "name": "Ivanteyevka",
                "countryCode": "RU"
            },
            {
                "name": "Kalininsk",
                "countryCode": "RU"
            },
            {
                "name": "Kamenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Khvalynsk",
                "countryCode": "RU"
            },
            {
                "name": "Khvalynskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khvatovka",
                "countryCode": "RU"
            },
            {
                "name": "Kormëzhka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoarmeysk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Kut",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Oktyabr’",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Lysyye Gory",
                "countryCode": "RU"
            },
            {
                "name": "Marks",
                "countryCode": "RU"
            },
            {
                "name": "Marksovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mokrous",
                "countryCode": "RU"
            },
            {
                "name": "Novouzensk",
                "countryCode": "RU"
            },
            {
                "name": "Novyye Burasy",
                "countryCode": "RU"
            },
            {
                "name": "Perelyub",
                "countryCode": "RU"
            },
            {
                "name": "Petrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Pinerovka",
                "countryCode": "RU"
            },
            {
                "name": "Piterka",
                "countryCode": "RU"
            },
            {
                "name": "Podlesnoye",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhskiy",
                "countryCode": "RU"
            },
            {
                "name": "Privolzhskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pugachev",
                "countryCode": "RU"
            },
            {
                "name": "Pushkino",
                "countryCode": "RU"
            },
            {
                "name": "Repnoye",
                "countryCode": "RU"
            },
            {
                "name": "Romanovka",
                "countryCode": "RU"
            },
            {
                "name": "Rovnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rtishchevo",
                "countryCode": "RU"
            },
            {
                "name": "Samoylovka",
                "countryCode": "RU"
            },
            {
                "name": "Saratov",
                "countryCode": "RU"
            },
            {
                "name": "Saratovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sennoy",
                "countryCode": "RU"
            },
            {
                "name": "Shikhany",
                "countryCode": "RU"
            },
            {
                "name": "Shumeyka",
                "countryCode": "RU"
            },
            {
                "name": "Sinodskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sokolovyy",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskoye",
                "countryCode": "RU"
            },
            {
                "name": "Staryye Ozinki",
                "countryCode": "RU"
            },
            {
                "name": "Stepnoye",
                "countryCode": "RU"
            },
            {
                "name": "Svetlyy",
                "countryCode": "RU"
            },
            {
                "name": "Svobodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Tatishchevo",
                "countryCode": "RU"
            },
            {
                "name": "Tersa",
                "countryCode": "RU"
            },
            {
                "name": "Turki",
                "countryCode": "RU"
            },
            {
                "name": "Vol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Vol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voskresenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yekaterinovka",
                "countryCode": "RU"
            },
            {
                "name": "Yelshanka",
                "countryCode": "RU"
            },
            {
                "name": "Yershov",
                "countryCode": "RU"
            },
            {
                "name": "Alagir",
                "countryCode": "RU"
            },
            {
                "name": "Alagirskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ardon",
                "countryCode": "RU"
            },
            {
                "name": "Arkhonskaya",
                "countryCode": "RU"
            },
            {
                "name": "Beslan",
                "countryCode": "RU"
            },
            {
                "name": "Chermen",
                "countryCode": "RU"
            },
            {
                "name": "Chikola",
                "countryCode": "RU"
            },
            {
                "name": "Dachnoye",
                "countryCode": "RU"
            },
            {
                "name": "Digora",
                "countryCode": "RU"
            },
            {
                "name": "Digorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gizel’",
                "countryCode": "RU"
            },
            {
                "name": "Irafskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kambileyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kardzhin",
                "countryCode": "RU"
            },
            {
                "name": "Khumalag",
                "countryCode": "RU"
            },
            {
                "name": "Kirovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kizlyar",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Mizur",
                "countryCode": "RU"
            },
            {
                "name": "Mozdok",
                "countryCode": "RU"
            },
            {
                "name": "Nogir",
                "countryCode": "RU"
            },
            {
                "name": "Pavlodol’skaya",
                "countryCode": "RU"
            },
            {
                "name": "Pravoberezhnyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Prigorodnyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sunzha",
                "countryCode": "RU"
            },
            {
                "name": "Tarskoye",
                "countryCode": "RU"
            },
            {
                "name": "Terskaya",
                "countryCode": "RU"
            },
            {
                "name": "Troitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Fiagdon",
                "countryCode": "RU"
            },
            {
                "name": "Vladikavkaz",
                "countryCode": "RU"
            },
            {
                "name": "Zmeyskaya",
                "countryCode": "RU"
            },
            {
                "name": "Demidov",
                "countryCode": "RU"
            },
            {
                "name": "Desnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Dorogobuzh",
                "countryCode": "RU"
            },
            {
                "name": "Dukhovshchina",
                "countryCode": "RU"
            },
            {
                "name": "Gagarin",
                "countryCode": "RU"
            },
            {
                "name": "Glinka",
                "countryCode": "RU"
            },
            {
                "name": "Golynki",
                "countryCode": "RU"
            },
            {
                "name": "Kardymovo",
                "countryCode": "RU"
            },
            {
                "name": "Katyn'",
                "countryCode": "RU"
            },
            {
                "name": "Khislavichi",
                "countryCode": "RU"
            },
            {
                "name": "Kholm-Zhirkovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Monastyrshchina",
                "countryCode": "RU"
            },
            {
                "name": "Novodugino",
                "countryCode": "RU"
            },
            {
                "name": "Oster",
                "countryCode": "RU"
            },
            {
                "name": "Ozërnyy",
                "countryCode": "RU"
            },
            {
                "name": "Pechersk",
                "countryCode": "RU"
            },
            {
                "name": "Pochinok",
                "countryCode": "RU"
            },
            {
                "name": "Roslavl’",
                "countryCode": "RU"
            },
            {
                "name": "Rudnya",
                "countryCode": "RU"
            },
            {
                "name": "Safonovo",
                "countryCode": "RU"
            },
            {
                "name": "Safonovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shatalovo",
                "countryCode": "RU"
            },
            {
                "name": "Shumyachi",
                "countryCode": "RU"
            },
            {
                "name": "Smolensk",
                "countryCode": "RU"
            },
            {
                "name": "Stodolishche",
                "countryCode": "RU"
            },
            {
                "name": "Sychëvka",
                "countryCode": "RU"
            },
            {
                "name": "Talashkino",
                "countryCode": "RU"
            },
            {
                "name": "Tëmkino",
                "countryCode": "RU"
            },
            {
                "name": "Ugra",
                "countryCode": "RU"
            },
            {
                "name": "Velizh",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnedneprovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vyaz’ma",
                "countryCode": "RU"
            },
            {
                "name": "Yartsevo",
                "countryCode": "RU"
            },
            {
                "name": "Yekimovichi",
                "countryCode": "RU"
            },
            {
                "name": "Yel’nya",
                "countryCode": "RU"
            },
            {
                "name": "Yershichi",
                "countryCode": "RU"
            },
            {
                "name": "Admiralteisky",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Avtovo",
                "countryCode": "RU"
            },
            {
                "name": "Beloostrov",
                "countryCode": "RU"
            },
            {
                "name": "Centralniy",
                "countryCode": "RU"
            },
            {
                "name": "Dachnoye",
                "countryCode": "RU"
            },
            {
                "name": "Frunzenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gorelovo",
                "countryCode": "RU"
            },
            {
                "name": "Grazhdanka",
                "countryCode": "RU"
            },
            {
                "name": "Kolomyagi",
                "countryCode": "RU"
            },
            {
                "name": "Kolpino",
                "countryCode": "RU"
            },
            {
                "name": "Komarovo",
                "countryCode": "RU"
            },
            {
                "name": "Komendantsky aerodrom",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvargeisky",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoye Selo",
                "countryCode": "RU"
            },
            {
                "name": "Kronshtadt",
                "countryCode": "RU"
            },
            {
                "name": "Kupchino",
                "countryCode": "RU"
            },
            {
                "name": "Kurortnyy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kushelevka",
                "countryCode": "RU"
            },
            {
                "name": "Lakhtinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Levashovo",
                "countryCode": "RU"
            },
            {
                "name": "Lisiy Nos",
                "countryCode": "RU"
            },
            {
                "name": "Lomonosov",
                "countryCode": "RU"
            },
            {
                "name": "Metallostroy",
                "countryCode": "RU"
            },
            {
                "name": "Molodezhnoye",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Derevnya",
                "countryCode": "RU"
            },
            {
                "name": "Obukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Ol’gino",
                "countryCode": "RU"
            },
            {
                "name": "Ozerki",
                "countryCode": "RU"
            },
            {
                "name": "Pargolovo",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovsk",
                "countryCode": "RU"
            },
            {
                "name": "Pesochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Peterhof",
                "countryCode": "RU"
            },
            {
                "name": "Petro-Slavyanka",
                "countryCode": "RU"
            },
            {
                "name": "Petrodvorets",
                "countryCode": "RU"
            },
            {
                "name": "Petrogradka",
                "countryCode": "RU"
            },
            {
                "name": "Pontonnyy",
                "countryCode": "RU"
            },
            {
                "name": "Primorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pushkin",
                "countryCode": "RU"
            },
            {
                "name": "Razliv",
                "countryCode": "RU"
            },
            {
                "name": "Repino",
                "countryCode": "RU"
            },
            {
                "name": "Saint Petersburg",
                "countryCode": "RU"
            },
            {
                "name": "Sapërnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sestroretsk",
                "countryCode": "RU"
            },
            {
                "name": "Shushary",
                "countryCode": "RU"
            },
            {
                "name": "Shuvalovo",
                "countryCode": "RU"
            },
            {
                "name": "Solnechnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovka",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Derevnya",
                "countryCode": "RU"
            },
            {
                "name": "Strel'na",
                "countryCode": "RU"
            },
            {
                "name": "Tarkhovka",
                "countryCode": "RU"
            },
            {
                "name": "Tyarlevo",
                "countryCode": "RU"
            },
            {
                "name": "Uritsk",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Izhora",
                "countryCode": "RU"
            },
            {
                "name": "Vasileostrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vasyl'evsky Ostrov",
                "countryCode": "RU"
            },
            {
                "name": "Zelenogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Achikulak",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandriya",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandriyskaya",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Andzhiyevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Arzgir",
                "countryCode": "RU"
            },
            {
                "name": "Balakhonovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Barsukovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Bekeshevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Beshpagir",
                "countryCode": "RU"
            },
            {
                "name": "Bezopasnoye",
                "countryCode": "RU"
            },
            {
                "name": "Blagodarnyy",
                "countryCode": "RU"
            },
            {
                "name": "Blagodatnoye",
                "countryCode": "RU"
            },
            {
                "name": "Borgustanskaya",
                "countryCode": "RU"
            },
            {
                "name": "Budënnovsk",
                "countryCode": "RU"
            },
            {
                "name": "Burlatskoye",
                "countryCode": "RU"
            },
            {
                "name": "Chernolesskoye",
                "countryCode": "RU"
            },
            {
                "name": "Divnoye",
                "countryCode": "RU"
            },
            {
                "name": "Dmitriyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Donskoye",
                "countryCode": "RU"
            },
            {
                "name": "Edissiya",
                "countryCode": "RU"
            },
            {
                "name": "Etoka",
                "countryCode": "RU"
            },
            {
                "name": "Galyugayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Georgiyevsk",
                "countryCode": "RU"
            },
            {
                "name": "Georgiyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Gofitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Goryachevodskiy",
                "countryCode": "RU"
            },
            {
                "name": "Grazhdanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Grecheskoye",
                "countryCode": "RU"
            },
            {
                "name": "Grigoropolisskaya",
                "countryCode": "RU"
            },
            {
                "name": "Inozemtsevo",
                "countryCode": "RU"
            },
            {
                "name": "Ipatovo",
                "countryCode": "RU"
            },
            {
                "name": "Irgakly",
                "countryCode": "RU"
            },
            {
                "name": "Ivanovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Izobil’nyy",
                "countryCode": "RU"
            },
            {
                "name": "Kalinovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kangly",
                "countryCode": "RU"
            },
            {
                "name": "Kara-Tyube",
                "countryCode": "RU"
            },
            {
                "name": "Kayasula",
                "countryCode": "RU"
            },
            {
                "name": "Kevsala",
                "countryCode": "RU"
            },
            {
                "name": "Kislovodsk",
                "countryCode": "RU"
            },
            {
                "name": "Kochubeyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Komsomolets",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokumskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kruglolesskoye",
                "countryCode": "RU"
            },
            {
                "name": "Kursavka",
                "countryCode": "RU"
            },
            {
                "name": "Kurskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ladovskaya Balka",
                "countryCode": "RU"
            },
            {
                "name": "Lermontov",
                "countryCode": "RU"
            },
            {
                "name": "Letnyaya Stavka",
                "countryCode": "RU"
            },
            {
                "name": "Levokumka",
                "countryCode": "RU"
            },
            {
                "name": "Levokumskoye",
                "countryCode": "RU"
            },
            {
                "name": "Lysogorskaya",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovsk",
                "countryCode": "RU"
            },
            {
                "name": "Mineralnye Vody",
                "countryCode": "RU"
            },
            {
                "name": "Moskovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Nadezhda",
                "countryCode": "RU"
            },
            {
                "name": "Nagutskoye",
                "countryCode": "RU"
            },
            {
                "name": "Neftekumsk",
                "countryCode": "RU"
            },
            {
                "name": "Nevinnomyssk",
                "countryCode": "RU"
            },
            {
                "name": "Nezhinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nezlobnaya",
                "countryCode": "RU"
            },
            {
                "name": "Niny",
                "countryCode": "RU"
            },
            {
                "name": "Novoaleksandrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Novoblagodarnoye",
                "countryCode": "RU"
            },
            {
                "name": "Novopavlovsk",
                "countryCode": "RU"
            },
            {
                "name": "Novoselitskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novoterskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novotroitskaya",
                "countryCode": "RU"
            },
            {
                "name": "Novozavedennoye",
                "countryCode": "RU"
            },
            {
                "name": "Orlovka",
                "countryCode": "RU"
            },
            {
                "name": "Otkaznoye",
                "countryCode": "RU"
            },
            {
                "name": "Ovoshchi",
                "countryCode": "RU"
            },
            {
                "name": "Pelagiada",
                "countryCode": "RU"
            },
            {
                "name": "Podgornaya",
                "countryCode": "RU"
            },
            {
                "name": "Podkumskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pokoynoye",
                "countryCode": "RU"
            },
            {
                "name": "Praskoveya",
                "countryCode": "RU"
            },
            {
                "name": "Pravokumskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pregradnoye",
                "countryCode": "RU"
            },
            {
                "name": "Pyatigorsk",
                "countryCode": "RU"
            },
            {
                "name": "Pyatigorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Rasshevatskaya",
                "countryCode": "RU"
            },
            {
                "name": "Ryzdvyanyy",
                "countryCode": "RU"
            },
            {
                "name": "Severnoye",
                "countryCode": "RU"
            },
            {
                "name": "Soldato-Aleksandrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Solomenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Soluno-Dmitriyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sotnikovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sovetskaya",
                "countryCode": "RU"
            },
            {
                "name": "Spitsevka",
                "countryCode": "RU"
            },
            {
                "name": "Starodubskoye",
                "countryCode": "RU"
            },
            {
                "name": "Staropavlovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Stavropol’",
                "countryCode": "RU"
            },
            {
                "name": "Stepnoye",
                "countryCode": "RU"
            },
            {
                "name": "Sukhaya Buyvola",
                "countryCode": "RU"
            },
            {
                "name": "Suvorovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Svetlograd",
                "countryCode": "RU"
            },
            {
                "name": "Svobody",
                "countryCode": "RU"
            },
            {
                "name": "Tatarka",
                "countryCode": "RU"
            },
            {
                "name": "Temizhbekskaya",
                "countryCode": "RU"
            },
            {
                "name": "Tishchenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Trunovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Urozhaynoye",
                "countryCode": "RU"
            },
            {
                "name": "Vinsady",
                "countryCode": "RU"
            },
            {
                "name": "Vostochny",
                "countryCode": "RU"
            },
            {
                "name": "Vysotskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yasnaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Yelizavetinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Yessentuki",
                "countryCode": "RU"
            },
            {
                "name": "Yessentukskaya",
                "countryCode": "RU"
            },
            {
                "name": "Yutsa",
                "countryCode": "RU"
            },
            {
                "name": "Zaterechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zavetnoye",
                "countryCode": "RU"
            },
            {
                "name": "Zelenokumsk",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznovodsk",
                "countryCode": "RU"
            },
            {
                "name": "Zmeyka",
                "countryCode": "RU"
            },
            {
                "name": "Achit",
                "countryCode": "RU"
            },
            {
                "name": "Alapayevsk",
                "countryCode": "RU"
            },
            {
                "name": "Aramil",
                "countryCode": "RU"
            },
            {
                "name": "Arti",
                "countryCode": "RU"
            },
            {
                "name": "Artëmovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Asbest",
                "countryCode": "RU"
            },
            {
                "name": "Asbestovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Atig",
                "countryCode": "RU"
            },
            {
                "name": "Azanka",
                "countryCode": "RU"
            },
            {
                "name": "Baraba",
                "countryCode": "RU"
            },
            {
                "name": "Baranchinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Baykalovo",
                "countryCode": "RU"
            },
            {
                "name": "Beloyarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Beryozovsky",
                "countryCode": "RU"
            },
            {
                "name": "Bilimbay",
                "countryCode": "RU"
            },
            {
                "name": "Bisert’",
                "countryCode": "RU"
            },
            {
                "name": "Bobrovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Bogdanovich",
                "countryCode": "RU"
            },
            {
                "name": "Bulanash",
                "countryCode": "RU"
            },
            {
                "name": "Butka",
                "countryCode": "RU"
            },
            {
                "name": "Chernoistochinsk",
                "countryCode": "RU"
            },
            {
                "name": "Cherëmukhovo",
                "countryCode": "RU"
            },
            {
                "name": "Degtyarsk",
                "countryCode": "RU"
            },
            {
                "name": "Druzhinino",
                "countryCode": "RU"
            },
            {
                "name": "Dvurechensk",
                "countryCode": "RU"
            },
            {
                "name": "Gari",
                "countryCode": "RU"
            },
            {
                "name": "Gornyy Shchit",
                "countryCode": "RU"
            },
            {
                "name": "Gryaznovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Irbit",
                "countryCode": "RU"
            },
            {
                "name": "Is",
                "countryCode": "RU"
            },
            {
                "name": "Iset’",
                "countryCode": "RU"
            },
            {
                "name": "Istok",
                "countryCode": "RU"
            },
            {
                "name": "Ivdel’",
                "countryCode": "RU"
            },
            {
                "name": "Izumrud",
                "countryCode": "RU"
            },
            {
                "name": "Kachkanar",
                "countryCode": "RU"
            },
            {
                "name": "Kalinovo",
                "countryCode": "RU"
            },
            {
                "name": "Kamensk-Ural’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Kamyshlov",
                "countryCode": "RU"
            },
            {
                "name": "Karpinsk",
                "countryCode": "RU"
            },
            {
                "name": "Karpushikha",
                "countryCode": "RU"
            },
            {
                "name": "Kedrovoye",
                "countryCode": "RU"
            },
            {
                "name": "Kirovgrad",
                "countryCode": "RU"
            },
            {
                "name": "Klyuchevsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogvardeyskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnotur’insk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoufimsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Kushva",
                "countryCode": "RU"
            },
            {
                "name": "Kuzino",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy",
                "countryCode": "RU"
            },
            {
                "name": "Levikha",
                "countryCode": "RU"
            },
            {
                "name": "Lobva",
                "countryCode": "RU"
            },
            {
                "name": "Losinyy",
                "countryCode": "RU"
            },
            {
                "name": "Lugovskoy",
                "countryCode": "RU"
            },
            {
                "name": "Malysheva",
                "countryCode": "RU"
            },
            {
                "name": "Martyush",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovsk",
                "countryCode": "RU"
            },
            {
                "name": "Monetnyy",
                "countryCode": "RU"
            },
            {
                "name": "Nev’yansk",
                "countryCode": "RU"
            },
            {
                "name": "Neyvo-Rudyanka",
                "countryCode": "RU"
            },
            {
                "name": "Neyvo-Shaytanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nikolo-Pavlovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniye Sergi",
                "countryCode": "RU"
            },
            {
                "name": "Nizhny Tagil",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Irga",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Salda",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Tura",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Lyalya",
                "countryCode": "RU"
            },
            {
                "name": "Novoasbest",
                "countryCode": "RU"
            },
            {
                "name": "Novoural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Novoutkinsk",
                "countryCode": "RU"
            },
            {
                "name": "Obukhovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ous",
                "countryCode": "RU"
            },
            {
                "name": "Pelym",
                "countryCode": "RU"
            },
            {
                "name": "Pervoural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Petrokamenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Polevskoy",
                "countryCode": "RU"
            },
            {
                "name": "Polunochnoye",
                "countryCode": "RU"
            },
            {
                "name": "Pyshma",
                "countryCode": "RU"
            },
            {
                "name": "Reftinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Revda",
                "countryCode": "RU"
            },
            {
                "name": "Rezh",
                "countryCode": "RU"
            },
            {
                "name": "Rudnichnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sarana",
                "countryCode": "RU"
            },
            {
                "name": "Serov",
                "countryCode": "RU"
            },
            {
                "name": "Severka",
                "countryCode": "RU"
            },
            {
                "name": "Severoural’sk",
                "countryCode": "RU"
            },
            {
                "name": "Shabrovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Shalya",
                "countryCode": "RU"
            },
            {
                "name": "Shamary",
                "countryCode": "RU"
            },
            {
                "name": "Shchelkun",
                "countryCode": "RU"
            },
            {
                "name": "Shirokaya Rechka",
                "countryCode": "RU"
            },
            {
                "name": "Shuvakish",
                "countryCode": "RU"
            },
            {
                "name": "Sos’va",
                "countryCode": "RU"
            },
            {
                "name": "Sovkhoznyy",
                "countryCode": "RU"
            },
            {
                "name": "Sredneuralsk",
                "countryCode": "RU"
            },
            {
                "name": "Staropyshminsk",
                "countryCode": "RU"
            },
            {
                "name": "Staroutkinsk",
                "countryCode": "RU"
            },
            {
                "name": "Sukhoy Log",
                "countryCode": "RU"
            },
            {
                "name": "Svobodnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sylva",
                "countryCode": "RU"
            },
            {
                "name": "Sysertskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sysert’",
                "countryCode": "RU"
            },
            {
                "name": "Tabory",
                "countryCode": "RU"
            },
            {
                "name": "Talitsa",
                "countryCode": "RU"
            },
            {
                "name": "Tavda",
                "countryCode": "RU"
            },
            {
                "name": "Tretiy Severnyy",
                "countryCode": "RU"
            },
            {
                "name": "Troitskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tsementnyy",
                "countryCode": "RU"
            },
            {
                "name": "Tugulym",
                "countryCode": "RU"
            },
            {
                "name": "Turinsk",
                "countryCode": "RU"
            },
            {
                "name": "Turinskaya Sloboda",
                "countryCode": "RU"
            },
            {
                "name": "Ufimskiy",
                "countryCode": "RU"
            },
            {
                "name": "Uralets",
                "countryCode": "RU"
            },
            {
                "name": "Valerianovsk",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Neyvinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Verkhneye Dubrovo",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Tagil",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniye Sergi",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Pyshma",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Salda",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Sinyachikha",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Sysert’",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Tura",
                "countryCode": "RU"
            },
            {
                "name": "Verkhotur’ye",
                "countryCode": "RU"
            },
            {
                "name": "Visim",
                "countryCode": "RU"
            },
            {
                "name": "Volchansk",
                "countryCode": "RU"
            },
            {
                "name": "Vostochnyy",
                "countryCode": "RU"
            },
            {
                "name": "Yekaterinburg",
                "countryCode": "RU"
            },
            {
                "name": "Yelanskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yertarskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yushala",
                "countryCode": "RU"
            },
            {
                "name": "Yëlkino",
                "countryCode": "RU"
            },
            {
                "name": "Zarechnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zavodouspenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Zaykovo",
                "countryCode": "RU"
            },
            {
                "name": "Zyuzel’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Agryz",
                "countryCode": "RU"
            },
            {
                "name": "Agryzskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aksubayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aktanysh",
                "countryCode": "RU"
            },
            {
                "name": "Aktanyshskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aktyubinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Al’keyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Al’met’yevsk",
                "countryCode": "RU"
            },
            {
                "name": "Al’met’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Apastovo",
                "countryCode": "RU"
            },
            {
                "name": "Apastovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arsk",
                "countryCode": "RU"
            },
            {
                "name": "Arskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aysha",
                "countryCode": "RU"
            },
            {
                "name": "Aznakayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Baltasi",
                "countryCode": "RU"
            },
            {
                "name": "Baltasinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bavlinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bavly",
                "countryCode": "RU"
            },
            {
                "name": "Bazarnyye Mataki",
                "countryCode": "RU"
            },
            {
                "name": "Bilyarsk",
                "countryCode": "RU"
            },
            {
                "name": "Bogatyye Saby",
                "countryCode": "RU"
            },
            {
                "name": "Bolgar",
                "countryCode": "RU"
            },
            {
                "name": "Bugul’ma",
                "countryCode": "RU"
            },
            {
                "name": "Bugul’minskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Buinsk",
                "countryCode": "RU"
            },
            {
                "name": "Buinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Cheremshan",
                "countryCode": "RU"
            },
            {
                "name": "Cheremshanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chistopol’",
                "countryCode": "RU"
            },
            {
                "name": "Chistopol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Drozhzhanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dzhalil’",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Buinsk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Kazan’",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Nizhnekamsk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Yelabuga",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Zainsk",
                "countryCode": "RU"
            },
            {
                "name": "Gorod Zelënodol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Kamsko-Ust’inskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Karabash",
                "countryCode": "RU"
            },
            {
                "name": "Kaybitskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazan",
                "countryCode": "RU"
            },
            {
                "name": "Kukmor",
                "countryCode": "RU"
            },
            {
                "name": "Kukmorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kuybyshevskiy Zaton",
                "countryCode": "RU"
            },
            {
                "name": "Laishevo",
                "countryCode": "RU"
            },
            {
                "name": "Laishevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leninogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Leninogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lubyany",
                "countryCode": "RU"
            },
            {
                "name": "Mamadysh",
                "countryCode": "RU"
            },
            {
                "name": "Mamadyshskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevsk",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Menzelinsk",
                "countryCode": "RU"
            },
            {
                "name": "Menzelinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Muslyumovo",
                "countryCode": "RU"
            },
            {
                "name": "Muslyumovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Naberezhnyye Chelny",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnekamsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnekamskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniye Vyazovyye",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Maktama",
                "countryCode": "RU"
            },
            {
                "name": "Novosheshminsk",
                "countryCode": "RU"
            },
            {
                "name": "Novosheshminskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nurlat",
                "countryCode": "RU"
            },
            {
                "name": "Nurlatskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Osinovo",
                "countryCode": "RU"
            },
            {
                "name": "Pestrechinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pestretsy",
                "countryCode": "RU"
            },
            {
                "name": "Russkiy Aktash",
                "countryCode": "RU"
            },
            {
                "name": "Rybnaya Sloboda",
                "countryCode": "RU"
            },
            {
                "name": "Rybno-Slobodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sabinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sarmanovo",
                "countryCode": "RU"
            },
            {
                "name": "Sarmanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shemordan",
                "countryCode": "RU"
            },
            {
                "name": "Shugurovo",
                "countryCode": "RU"
            },
            {
                "name": "Spasskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Staroye Arakchino",
                "countryCode": "RU"
            },
            {
                "name": "Staroye Drozhzhanoye",
                "countryCode": "RU"
            },
            {
                "name": "Stolbishchi",
                "countryCode": "RU"
            },
            {
                "name": "Sviyazhsk",
                "countryCode": "RU"
            },
            {
                "name": "Tetyushi",
                "countryCode": "RU"
            },
            {
                "name": "Tetyushskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tukayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tyulyachi",
                "countryCode": "RU"
            },
            {
                "name": "Tyulyachinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urussu",
                "countryCode": "RU"
            },
            {
                "name": "Vasil’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Verkhneuslonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Uslon",
                "countryCode": "RU"
            },
            {
                "name": "Vysokaya Gora",
                "countryCode": "RU"
            },
            {
                "name": "Vysokogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yelabuga",
                "countryCode": "RU"
            },
            {
                "name": "Yelabuzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yutazinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zainsk",
                "countryCode": "RU"
            },
            {
                "name": "Zainskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zelenodolsk",
                "countryCode": "RU"
            },
            {
                "name": "Zelenodol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bokino",
                "countryCode": "RU"
            },
            {
                "name": "Bondarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Donskoye",
                "countryCode": "RU"
            },
            {
                "name": "Gavrilovka Vtoraya",
                "countryCode": "RU"
            },
            {
                "name": "Goreloye",
                "countryCode": "RU"
            },
            {
                "name": "Inzhavino",
                "countryCode": "RU"
            },
            {
                "name": "Kirsanov",
                "countryCode": "RU"
            },
            {
                "name": "Kirsanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Komsomolets",
                "countryCode": "RU"
            },
            {
                "name": "Kotovsk",
                "countryCode": "RU"
            },
            {
                "name": "Michurinsk",
                "countryCode": "RU"
            },
            {
                "name": "Mordovo",
                "countryCode": "RU"
            },
            {
                "name": "Morshansk",
                "countryCode": "RU"
            },
            {
                "name": "Muchkapskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Lyada",
                "countryCode": "RU"
            },
            {
                "name": "Novopokrovka",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pichayevo",
                "countryCode": "RU"
            },
            {
                "name": "Platonovka",
                "countryCode": "RU"
            },
            {
                "name": "Pokrovo-Prigorodnoye",
                "countryCode": "RU"
            },
            {
                "name": "Rasskazovo",
                "countryCode": "RU"
            },
            {
                "name": "Rasskazovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rzhaksa",
                "countryCode": "RU"
            },
            {
                "name": "Sampurskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Satinka",
                "countryCode": "RU"
            },
            {
                "name": "Selezni",
                "countryCode": "RU"
            },
            {
                "name": "Sosnovka",
                "countryCode": "RU"
            },
            {
                "name": "Staroyur’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Tambov",
                "countryCode": "RU"
            },
            {
                "name": "Tokarevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uvarovo",
                "countryCode": "RU"
            },
            {
                "name": "Zavoronezhskoye",
                "countryCode": "RU"
            },
            {
                "name": "Zherdevka",
                "countryCode": "RU"
            },
            {
                "name": "Znamenka",
                "countryCode": "RU"
            },
            {
                "name": "Znamenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Asino",
                "countryCode": "RU"
            },
            {
                "name": "Bakchar",
                "countryCode": "RU"
            },
            {
                "name": "Belyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Bogashevo",
                "countryCode": "RU"
            },
            {
                "name": "Kaltay",
                "countryCode": "RU"
            },
            {
                "name": "Kargasok",
                "countryCode": "RU"
            },
            {
                "name": "Kedrovyy",
                "countryCode": "RU"
            },
            {
                "name": "Kolpashevo",
                "countryCode": "RU"
            },
            {
                "name": "Kozhevnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Yar",
                "countryCode": "RU"
            },
            {
                "name": "Krivosheino",
                "countryCode": "RU"
            },
            {
                "name": "Mel’nikovo",
                "countryCode": "RU"
            },
            {
                "name": "Molchanovo",
                "countryCode": "RU"
            },
            {
                "name": "Molchanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Moryakovskiy Zaton",
                "countryCode": "RU"
            },
            {
                "name": "Parabel’",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "RU"
            },
            {
                "name": "Podgornoye",
                "countryCode": "RU"
            },
            {
                "name": "Samus’",
                "countryCode": "RU"
            },
            {
                "name": "Seversk",
                "countryCode": "RU"
            },
            {
                "name": "Strezhevoy",
                "countryCode": "RU"
            },
            {
                "name": "Svetlyy",
                "countryCode": "RU"
            },
            {
                "name": "Tegul’det",
                "countryCode": "RU"
            },
            {
                "name": "Tegul’detskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Timiryazevskoye",
                "countryCode": "RU"
            },
            {
                "name": "Togur",
                "countryCode": "RU"
            },
            {
                "name": "Tomsk",
                "countryCode": "RU"
            },
            {
                "name": "Tomskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zyryanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ageyevo",
                "countryCode": "RU"
            },
            {
                "name": "Aleksin",
                "countryCode": "RU"
            },
            {
                "name": "Aleksinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Arsen’yevo",
                "countryCode": "RU"
            },
            {
                "name": "Barsuki",
                "countryCode": "RU"
            },
            {
                "name": "Begichevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Belëv",
                "countryCode": "RU"
            },
            {
                "name": "Bogoroditsk",
                "countryCode": "RU"
            },
            {
                "name": "Bolokhovo",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Skuratovo",
                "countryCode": "RU"
            },
            {
                "name": "Borodinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Brusyanka",
                "countryCode": "RU"
            },
            {
                "name": "Chekalin",
                "countryCode": "RU"
            },
            {
                "name": "Cherepet’",
                "countryCode": "RU"
            },
            {
                "name": "Chern’",
                "countryCode": "RU"
            },
            {
                "name": "Donskoy",
                "countryCode": "RU"
            },
            {
                "name": "Dubna",
                "countryCode": "RU"
            },
            {
                "name": "Dubovka",
                "countryCode": "RU"
            },
            {
                "name": "Gorelki",
                "countryCode": "RU"
            },
            {
                "name": "Gritsovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kamenetskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kazachka",
                "countryCode": "RU"
            },
            {
                "name": "Khanino",
                "countryCode": "RU"
            },
            {
                "name": "Kimovsk",
                "countryCode": "RU"
            },
            {
                "name": "Kireyevsk",
                "countryCode": "RU"
            },
            {
                "name": "Kosaya Gora",
                "countryCode": "RU"
            },
            {
                "name": "Krapivna",
                "countryCode": "RU"
            },
            {
                "name": "Kurkino",
                "countryCode": "RU"
            },
            {
                "name": "Leninskiy",
                "countryCode": "RU"
            },
            {
                "name": "Lomintsevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novogurovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novomoskovsk",
                "countryCode": "RU"
            },
            {
                "name": "Odoyev",
                "countryCode": "RU"
            },
            {
                "name": "Partizan",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Plavsk",
                "countryCode": "RU"
            },
            {
                "name": "Podlesnyy",
                "countryCode": "RU"
            },
            {
                "name": "Priupskiy",
                "countryCode": "RU"
            },
            {
                "name": "Revyakino",
                "countryCode": "RU"
            },
            {
                "name": "Rozhdestvenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Severo-Zadonsk",
                "countryCode": "RU"
            },
            {
                "name": "Shchëkino",
                "countryCode": "RU"
            },
            {
                "name": "Shvartsevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Skuratovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Slavniy",
                "countryCode": "RU"
            },
            {
                "name": "Suvorov",
                "countryCode": "RU"
            },
            {
                "name": "Suvorovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tovarkovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Tula",
                "countryCode": "RU"
            },
            {
                "name": "Tëplo-Ogarëvskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tëploye",
                "countryCode": "RU"
            },
            {
                "name": "Uzlovaya",
                "countryCode": "RU"
            },
            {
                "name": "Venevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Venëv",
                "countryCode": "RU"
            },
            {
                "name": "Volovo",
                "countryCode": "RU"
            },
            {
                "name": "Yasnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Yasnogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Yefremov",
                "countryCode": "RU"
            },
            {
                "name": "Yepifan’",
                "countryCode": "RU"
            },
            {
                "name": "Zaokskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zaokskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zhdankovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Andreapol’",
                "countryCode": "RU"
            },
            {
                "name": "Andreapol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belyy",
                "countryCode": "RU"
            },
            {
                "name": "Belyy Gorodok",
                "countryCode": "RU"
            },
            {
                "name": "Bel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Berezayka",
                "countryCode": "RU"
            },
            {
                "name": "Bernovo",
                "countryCode": "RU"
            },
            {
                "name": "Bezhetsk",
                "countryCode": "RU"
            },
            {
                "name": "Bezhetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bologovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bologoye",
                "countryCode": "RU"
            },
            {
                "name": "Bologoye-4",
                "countryCode": "RU"
            },
            {
                "name": "Emmaus",
                "countryCode": "RU"
            },
            {
                "name": "Firovo",
                "countryCode": "RU"
            },
            {
                "name": "Firovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gorodnya",
                "countryCode": "RU"
            },
            {
                "name": "Il’inskoye",
                "countryCode": "RU"
            },
            {
                "name": "Izoplit",
                "countryCode": "RU"
            },
            {
                "name": "Kalashnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Kalininskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kalyazin",
                "countryCode": "RU"
            },
            {
                "name": "Kalyazinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kashin",
                "countryCode": "RU"
            },
            {
                "name": "Kashinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kesova Gora",
                "countryCode": "RU"
            },
            {
                "name": "Kesovogorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kimrskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kimry",
                "countryCode": "RU"
            },
            {
                "name": "Konakovo",
                "countryCode": "RU"
            },
            {
                "name": "Konakovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kozlovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokholmskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasnomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Kholm",
                "countryCode": "RU"
            },
            {
                "name": "Kuvshinovo",
                "countryCode": "RU"
            },
            {
                "name": "Kuvshinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kuzhenkino",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Lesnoye",
                "countryCode": "RU"
            },
            {
                "name": "Likhoslavl’",
                "countryCode": "RU"
            },
            {
                "name": "Likhoslavl’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Maksatikha",
                "countryCode": "RU"
            },
            {
                "name": "Maksatikhinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Molokovo",
                "countryCode": "RU"
            },
            {
                "name": "Nelidovo",
                "countryCode": "RU"
            },
            {
                "name": "Novozavidovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Olenino",
                "countryCode": "RU"
            },
            {
                "name": "Orsha",
                "countryCode": "RU"
            },
            {
                "name": "Ostashkov",
                "countryCode": "RU"
            },
            {
                "name": "Ostashkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Peno",
                "countryCode": "RU"
            },
            {
                "name": "Penovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Radchenko",
                "countryCode": "RU"
            },
            {
                "name": "Rameshki",
                "countryCode": "RU"
            },
            {
                "name": "Rameshkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Redkino",
                "countryCode": "RU"
            },
            {
                "name": "Rzhev",
                "countryCode": "RU"
            },
            {
                "name": "Sakharovo",
                "countryCode": "RU"
            },
            {
                "name": "Sandovo",
                "countryCode": "RU"
            },
            {
                "name": "Sandovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Selishche",
                "countryCode": "RU"
            },
            {
                "name": "Selizharovo",
                "countryCode": "RU"
            },
            {
                "name": "Selizharovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sonkovo",
                "countryCode": "RU"
            },
            {
                "name": "Spirovo",
                "countryCode": "RU"
            },
            {
                "name": "Spirovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Stantsia Staritsa",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Toropa",
                "countryCode": "RU"
            },
            {
                "name": "Staritsa",
                "countryCode": "RU"
            },
            {
                "name": "Staritskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Toropets",
                "countryCode": "RU"
            },
            {
                "name": "Toropetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Torzhok",
                "countryCode": "RU"
            },
            {
                "name": "Torzhokskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tver",
                "countryCode": "RU"
            },
            {
                "name": "Udomlya",
                "countryCode": "RU"
            },
            {
                "name": "Vasil’yevskiy Mokh",
                "countryCode": "RU"
            },
            {
                "name": "Velikooktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Ves’yegonsk",
                "countryCode": "RU"
            },
            {
                "name": "Ves’yegonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vydropuzhsk",
                "countryCode": "RU"
            },
            {
                "name": "Vypolzovo",
                "countryCode": "RU"
            },
            {
                "name": "Vyshnevolotskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vyshniy Volochëk",
                "countryCode": "RU"
            },
            {
                "name": "Zapadnaya Dvina",
                "countryCode": "RU"
            },
            {
                "name": "Zavidovo",
                "countryCode": "RU"
            },
            {
                "name": "Zharkovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zubtsov",
                "countryCode": "RU"
            },
            {
                "name": "Zubtsovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ak-Dovurak",
                "countryCode": "RU"
            },
            {
                "name": "Balgazyn",
                "countryCode": "RU"
            },
            {
                "name": "Bay-Khaak",
                "countryCode": "RU"
            },
            {
                "name": "Chadan",
                "countryCode": "RU"
            },
            {
                "name": "Erzin",
                "countryCode": "RU"
            },
            {
                "name": "Kaa-Khem",
                "countryCode": "RU"
            },
            {
                "name": "Khandagayty",
                "countryCode": "RU"
            },
            {
                "name": "Khovu-Aksy",
                "countryCode": "RU"
            },
            {
                "name": "Kungurtug",
                "countryCode": "RU"
            },
            {
                "name": "Kyzyl",
                "countryCode": "RU"
            },
            {
                "name": "Kyzyl-Khaya",
                "countryCode": "RU"
            },
            {
                "name": "Kyzyl-Mazhalyk",
                "countryCode": "RU"
            },
            {
                "name": "Mugur-Aksy",
                "countryCode": "RU"
            },
            {
                "name": "Samagaltay",
                "countryCode": "RU"
            },
            {
                "name": "Saryg-Sep",
                "countryCode": "RU"
            },
            {
                "name": "Shagonar",
                "countryCode": "RU"
            },
            {
                "name": "Sukpak",
                "countryCode": "RU"
            },
            {
                "name": "Sut-Khol’",
                "countryCode": "RU"
            },
            {
                "name": "Teeli",
                "countryCode": "RU"
            },
            {
                "name": "Toora-Khem",
                "countryCode": "RU"
            },
            {
                "name": "Turan",
                "countryCode": "RU"
            },
            {
                "name": "Abalak",
                "countryCode": "RU"
            },
            {
                "name": "Abatskoye",
                "countryCode": "RU"
            },
            {
                "name": "Antipino",
                "countryCode": "RU"
            },
            {
                "name": "Armizonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Armizonskoye",
                "countryCode": "RU"
            },
            {
                "name": "Aromashevo",
                "countryCode": "RU"
            },
            {
                "name": "Berdyuzhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bogandinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Borovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Chervishevo",
                "countryCode": "RU"
            },
            {
                "name": "Golyshmanovo",
                "countryCode": "RU"
            },
            {
                "name": "Golyshmanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Irtyshskiy",
                "countryCode": "RU"
            },
            {
                "name": "Isetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Isetskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ishim",
                "countryCode": "RU"
            },
            {
                "name": "Ishimskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Melioratorov",
                "countryCode": "RU"
            },
            {
                "name": "Mendeleyevo",
                "countryCode": "RU"
            },
            {
                "name": "Moskovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnyaya Tavda",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Zaimka",
                "countryCode": "RU"
            },
            {
                "name": "Novoseleznëvo",
                "countryCode": "RU"
            },
            {
                "name": "Omutinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Omutinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Onokhino",
                "countryCode": "RU"
            },
            {
                "name": "Sladkovo",
                "countryCode": "RU"
            },
            {
                "name": "Sladkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Sumkino",
                "countryCode": "RU"
            },
            {
                "name": "Tobol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Turtas",
                "countryCode": "RU"
            },
            {
                "name": "Tyumen",
                "countryCode": "RU"
            },
            {
                "name": "Uporovo",
                "countryCode": "RU"
            },
            {
                "name": "Uporovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uspenka",
                "countryCode": "RU"
            },
            {
                "name": "Uvat",
                "countryCode": "RU"
            },
            {
                "name": "Vagay",
                "countryCode": "RU"
            },
            {
                "name": "Vagayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vikulovo",
                "countryCode": "RU"
            },
            {
                "name": "Vinzili",
                "countryCode": "RU"
            },
            {
                "name": "Yalutorovsk",
                "countryCode": "RU"
            },
            {
                "name": "Yarkovo",
                "countryCode": "RU"
            },
            {
                "name": "Yurginskoye",
                "countryCode": "RU"
            },
            {
                "name": "Zavodoukovsk",
                "countryCode": "RU"
            },
            {
                "name": "Afanas’yeva",
                "countryCode": "RU"
            },
            {
                "name": "Alarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Alekseyevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Algatuy",
                "countryCode": "RU"
            },
            {
                "name": "Alzamay",
                "countryCode": "RU"
            },
            {
                "name": "Andreyevka",
                "countryCode": "RU"
            },
            {
                "name": "Angarsk",
                "countryCode": "RU"
            },
            {
                "name": "Angarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Atagay",
                "countryCode": "RU"
            },
            {
                "name": "Badar",
                "countryCode": "RU"
            },
            {
                "name": "Baklashi",
                "countryCode": "RU"
            },
            {
                "name": "Balagansk",
                "countryCode": "RU"
            },
            {
                "name": "Balaganskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Balakhninskiy",
                "countryCode": "RU"
            },
            {
                "name": "Balaklava",
                "countryCode": "RU"
            },
            {
                "name": "Balaklava District",
                "countryCode": "RU"
            },
            {
                "name": "Bayanday",
                "countryCode": "RU"
            },
            {
                "name": "Bayandayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Baykal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Bereznyaki",
                "countryCode": "RU"
            },
            {
                "name": "Biryusinsk",
                "countryCode": "RU"
            },
            {
                "name": "Bodaybo",
                "countryCode": "RU"
            },
            {
                "name": "Bokhan",
                "countryCode": "RU"
            },
            {
                "name": "Bokhanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shaya Rechka",
                "countryCode": "RU"
            },
            {
                "name": "Bratsk",
                "countryCode": "RU"
            },
            {
                "name": "Bratskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Budagovo",
                "countryCode": "RU"
            },
            {
                "name": "Cheremkhovo",
                "countryCode": "RU"
            },
            {
                "name": "Cheremkhovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chunskiy",
                "countryCode": "RU"
            },
            {
                "name": "Dzerzhinsk",
                "countryCode": "RU"
            },
            {
                "name": "Ekhirit-Bulagatskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Frontovoye",
                "countryCode": "RU"
            },
            {
                "name": "Gadaley",
                "countryCode": "RU"
            },
            {
                "name": "Guran",
                "countryCode": "RU"
            },
            {
                "name": "Haharinskyi District",
                "countryCode": "RU"
            },
            {
                "name": "Ikey",
                "countryCode": "RU"
            },
            {
                "name": "Ilir",
                "countryCode": "RU"
            },
            {
                "name": "Inkerman",
                "countryCode": "RU"
            },
            {
                "name": "Irkutsk",
                "countryCode": "RU"
            },
            {
                "name": "Irkutskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kacha",
                "countryCode": "RU"
            },
            {
                "name": "Kachug",
                "countryCode": "RU"
            },
            {
                "name": "Kachugskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Katangskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazachinsko-Lenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kazachinskoye",
                "countryCode": "RU"
            },
            {
                "name": "Khomutovo",
                "countryCode": "RU"
            },
            {
                "name": "Khrebtovaya",
                "countryCode": "RU"
            },
            {
                "name": "Khudoyelanskoye",
                "countryCode": "RU"
            },
            {
                "name": "Khuzhir",
                "countryCode": "RU"
            },
            {
                "name": "Kimil’tey",
                "countryCode": "RU"
            },
            {
                "name": "Kirensk",
                "countryCode": "RU"
            },
            {
                "name": "Kirenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kitoy",
                "countryCode": "RU"
            },
            {
                "name": "Kotik",
                "countryCode": "RU"
            },
            {
                "name": "Kropotkin",
                "countryCode": "RU"
            },
            {
                "name": "Kultuk",
                "countryCode": "RU"
            },
            {
                "name": "Kutulik",
                "countryCode": "RU"
            },
            {
                "name": "Kuytun",
                "countryCode": "RU"
            },
            {
                "name": "Kuytunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kvitok",
                "countryCode": "RU"
            },
            {
                "name": "Lenin District",
                "countryCode": "RU"
            },
            {
                "name": "Lesogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Listvyanka",
                "countryCode": "RU"
            },
            {
                "name": "Magistral’nyy",
                "countryCode": "RU"
            },
            {
                "name": "Mama",
                "countryCode": "RU"
            },
            {
                "name": "Mamakan",
                "countryCode": "RU"
            },
            {
                "name": "Mamsko-Chuyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Manzurka",
                "countryCode": "RU"
            },
            {
                "name": "Markova",
                "countryCode": "RU"
            },
            {
                "name": "Markovo",
                "countryCode": "RU"
            },
            {
                "name": "Meget",
                "countryCode": "RU"
            },
            {
                "name": "Mishelevka",
                "countryCode": "RU"
            },
            {
                "name": "Mugun",
                "countryCode": "RU"
            },
            {
                "name": "Nakhimovskiy rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneilimskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneudinsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhneudinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Igirma",
                "countryCode": "RU"
            },
            {
                "name": "Novobiryusinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novonukutskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ol’khonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orlinoye",
                "countryCode": "RU"
            },
            {
                "name": "Osa",
                "countryCode": "RU"
            },
            {
                "name": "Oyëk",
                "countryCode": "RU"
            },
            {
                "name": "Pad’ Mel’nichnaya",
                "countryCode": "RU"
            },
            {
                "name": "Perfilovo",
                "countryCode": "RU"
            },
            {
                "name": "Pivovarikha",
                "countryCode": "RU"
            },
            {
                "name": "Pokosnoye",
                "countryCode": "RU"
            },
            {
                "name": "Polyushko",
                "countryCode": "RU"
            },
            {
                "name": "Rudnogorsk",
                "countryCode": "RU"
            },
            {
                "name": "Sakharnaya Golovka",
                "countryCode": "RU"
            },
            {
                "name": "Sayansk",
                "countryCode": "RU"
            },
            {
                "name": "Sevastopol",
                "countryCode": "RU"
            },
            {
                "name": "Shamanka",
                "countryCode": "RU"
            },
            {
                "name": "Sheberta",
                "countryCode": "RU"
            },
            {
                "name": "Shelekhov",
                "countryCode": "RU"
            },
            {
                "name": "Sheragul",
                "countryCode": "RU"
            },
            {
                "name": "Shestakovo",
                "countryCode": "RU"
            },
            {
                "name": "Shitkino",
                "countryCode": "RU"
            },
            {
                "name": "Shturmovoye",
                "countryCode": "RU"
            },
            {
                "name": "Shumskiy",
                "countryCode": "RU"
            },
            {
                "name": "Slyudyanka",
                "countryCode": "RU"
            },
            {
                "name": "Slyudyanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Smolenshchina",
                "countryCode": "RU"
            },
            {
                "name": "Solnechniy",
                "countryCode": "RU"
            },
            {
                "name": "Sredniy",
                "countryCode": "RU"
            },
            {
                "name": "Svirsk",
                "countryCode": "RU"
            },
            {
                "name": "Tayshet",
                "countryCode": "RU"
            },
            {
                "name": "Tayshetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tayturka",
                "countryCode": "RU"
            },
            {
                "name": "Tel’ma",
                "countryCode": "RU"
            },
            {
                "name": "Ternovka",
                "countryCode": "RU"
            },
            {
                "name": "Tulun",
                "countryCode": "RU"
            },
            {
                "name": "Tulunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tulyushka",
                "countryCode": "RU"
            },
            {
                "name": "Tyret’ Pervaya",
                "countryCode": "RU"
            },
            {
                "name": "Uk",
                "countryCode": "RU"
            },
            {
                "name": "Urik",
                "countryCode": "RU"
            },
            {
                "name": "Usol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Usol’ye-Sibirskoye",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Ilimsk",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kut",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kutskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Ordynskiy",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Uda",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Udinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnesadovoye",
                "countryCode": "RU"
            },
            {
                "name": "Vidim",
                "countryCode": "RU"
            },
            {
                "name": "Vikhorevka",
                "countryCode": "RU"
            },
            {
                "name": "Voznesenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Yantal’",
                "countryCode": "RU"
            },
            {
                "name": "Yedogon",
                "countryCode": "RU"
            },
            {
                "name": "Yelantsy",
                "countryCode": "RU"
            },
            {
                "name": "Yerbogachen",
                "countryCode": "RU"
            },
            {
                "name": "Yurty",
                "countryCode": "RU"
            },
            {
                "name": "Zalari",
                "countryCode": "RU"
            },
            {
                "name": "Zalarinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznodorozhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Zheleznogorsk-Ilimskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zhigalovo",
                "countryCode": "RU"
            },
            {
                "name": "Zhigalovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zima",
                "countryCode": "RU"
            },
            {
                "name": "Ziminskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zvëzdnyy",
                "countryCode": "RU"
            },
            {
                "name": "Alnashi",
                "countryCode": "RU"
            },
            {
                "name": "Balezino",
                "countryCode": "RU"
            },
            {
                "name": "Debesy",
                "countryCode": "RU"
            },
            {
                "name": "Fakel",
                "countryCode": "RU"
            },
            {
                "name": "Glazov",
                "countryCode": "RU"
            },
            {
                "name": "Grakhovo",
                "countryCode": "RU"
            },
            {
                "name": "Igra",
                "countryCode": "RU"
            },
            {
                "name": "Izhevsk",
                "countryCode": "RU"
            },
            {
                "name": "Kama",
                "countryCode": "RU"
            },
            {
                "name": "Kambarka",
                "countryCode": "RU"
            },
            {
                "name": "Karakulino",
                "countryCode": "RU"
            },
            {
                "name": "Karakulinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kez",
                "countryCode": "RU"
            },
            {
                "name": "Khokhryaki",
                "countryCode": "RU"
            },
            {
                "name": "Kiyasovo",
                "countryCode": "RU"
            },
            {
                "name": "Kiyasovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kizner",
                "countryCode": "RU"
            },
            {
                "name": "Krasnogorskoye",
                "countryCode": "RU"
            },
            {
                "name": "Malaya Purga",
                "countryCode": "RU"
            },
            {
                "name": "Malopurginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mozhga",
                "countryCode": "RU"
            },
            {
                "name": "Pirogovo",
                "countryCode": "RU"
            },
            {
                "name": "Pudem",
                "countryCode": "RU"
            },
            {
                "name": "Pychas",
                "countryCode": "RU"
            },
            {
                "name": "Sarapul",
                "countryCode": "RU"
            },
            {
                "name": "Sarapul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Selty",
                "countryCode": "RU"
            },
            {
                "name": "Sharkan",
                "countryCode": "RU"
            },
            {
                "name": "Sigayevo",
                "countryCode": "RU"
            },
            {
                "name": "Syumsi",
                "countryCode": "RU"
            },
            {
                "name": "Uva",
                "countryCode": "RU"
            },
            {
                "name": "Vavozh",
                "countryCode": "RU"
            },
            {
                "name": "Votkinsk",
                "countryCode": "RU"
            },
            {
                "name": "Yar",
                "countryCode": "RU"
            },
            {
                "name": "Yukamenskoye",
                "countryCode": "RU"
            },
            {
                "name": "Zav’yalovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Barysh",
                "countryCode": "RU"
            },
            {
                "name": "Bazarnyy Syzgan",
                "countryCode": "RU"
            },
            {
                "name": "Cherdaklinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Cherdakly",
                "countryCode": "RU"
            },
            {
                "name": "Chufarovo",
                "countryCode": "RU"
            },
            {
                "name": "Dimitrovgrad",
                "countryCode": "RU"
            },
            {
                "name": "Glotovka",
                "countryCode": "RU"
            },
            {
                "name": "Ignatovka",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Vladimira Il’icha Lenina",
                "countryCode": "RU"
            },
            {
                "name": "Inza",
                "countryCode": "RU"
            },
            {
                "name": "Isheyevka",
                "countryCode": "RU"
            },
            {
                "name": "Izmaylovo",
                "countryCode": "RU"
            },
            {
                "name": "Kanadey",
                "countryCode": "RU"
            },
            {
                "name": "Karsun",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Gulyay",
                "countryCode": "RU"
            },
            {
                "name": "Kuzovatovo",
                "countryCode": "RU"
            },
            {
                "name": "Mayna",
                "countryCode": "RU"
            },
            {
                "name": "Maynskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mirnyy",
                "countryCode": "RU"
            },
            {
                "name": "Mullovka",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevka",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Malykla",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Mayna",
                "countryCode": "RU"
            },
            {
                "name": "Novocheremshansk",
                "countryCode": "RU"
            },
            {
                "name": "Novospasskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novoul’yanovsk",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovka",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Radishchevo",
                "countryCode": "RU"
            },
            {
                "name": "Sengiley",
                "countryCode": "RU"
            },
            {
                "name": "Silikatnyy",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Kulatka",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Mayna",
                "countryCode": "RU"
            },
            {
                "name": "Starokulatkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Starotimoshkino",
                "countryCode": "RU"
            },
            {
                "name": "Surskoye",
                "countryCode": "RU"
            },
            {
                "name": "Teren’gul’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tsil’na",
                "countryCode": "RU"
            },
            {
                "name": "Tsil’ninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ulyanovsk",
                "countryCode": "RU"
            },
            {
                "name": "Ulyanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Undory",
                "countryCode": "RU"
            },
            {
                "name": "Veshkayma",
                "countryCode": "RU"
            },
            {
                "name": "Yazykovo",
                "countryCode": "RU"
            },
            {
                "name": "Zhadovka",
                "countryCode": "RU"
            },
            {
                "name": "Alimov-Lyubimovsky",
                "countryCode": "RU"
            },
            {
                "name": "Amochayevsky",
                "countryCode": "RU"
            },
            {
                "name": "Antipovka",
                "countryCode": "RU"
            },
            {
                "name": "Archedino-Chernushinsky",
                "countryCode": "RU"
            },
            {
                "name": "Archedinskaya",
                "countryCode": "RU"
            },
            {
                "name": "Beluzhino-Koldairov",
                "countryCode": "RU"
            },
            {
                "name": "Bereslavka",
                "countryCode": "RU"
            },
            {
                "name": "Bobrovsky 2-y",
                "countryCode": "RU"
            },
            {
                "name": "Burnatsky",
                "countryCode": "RU"
            },
            {
                "name": "Buyerak-Popovsky",
                "countryCode": "RU"
            },
            {
                "name": "Bykovo, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Chernyshkovsky",
                "countryCode": "RU"
            },
            {
                "name": "Chiganaki 1-ye",
                "countryCode": "RU"
            },
            {
                "name": "Chiganaki 2-ye",
                "countryCode": "RU"
            },
            {
                "name": "Danilovka, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Dubovka",
                "countryCode": "RU"
            },
            {
                "name": "Elton",
                "countryCode": "RU"
            },
            {
                "name": "Fastov",
                "countryCode": "RU"
            },
            {
                "name": "Frolovo",
                "countryCode": "RU"
            },
            {
                "name": "Glubokovsky",
                "countryCode": "RU"
            },
            {
                "name": "Gorbatovsky",
                "countryCode": "RU"
            },
            {
                "name": "Gornaya Polyana",
                "countryCode": "RU"
            },
            {
                "name": "Gorodishche, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Ilovlya",
                "countryCode": "RU"
            },
            {
                "name": "Kalach-na-Donu",
                "countryCode": "RU"
            },
            {
                "name": "Kamyshin",
                "countryCode": "RU"
            },
            {
                "name": "Karpovsky",
                "countryCode": "RU"
            },
            {
                "name": "Kletskaya",
                "countryCode": "RU"
            },
            {
                "name": "Kolotsky",
                "countryCode": "RU"
            },
            {
                "name": "Kotelnikovo",
                "countryCode": "RU"
            },
            {
                "name": "Kotovo",
                "countryCode": "RU"
            },
            {
                "name": "Krasnoslobodsk",
                "countryCode": "RU"
            },
            {
                "name": "Krasny Yar, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Kumylzhenskaya",
                "countryCode": "RU"
            },
            {
                "name": "Leninsk",
                "countryCode": "RU"
            },
            {
                "name": "Log",
                "countryCode": "RU"
            },
            {
                "name": "Medveditsky",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovka",
                "countryCode": "RU"
            },
            {
                "name": "Mikhaylovka Urban Okrug",
                "countryCode": "RU"
            },
            {
                "name": "Mitkin",
                "countryCode": "RU"
            },
            {
                "name": "Nekhayevskaya",
                "countryCode": "RU"
            },
            {
                "name": "Nikolayevsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhny Chir",
                "countryCode": "RU"
            },
            {
                "name": "Novoanninsky",
                "countryCode": "RU"
            },
            {
                "name": "Novonikolayevsky, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Novonikolskoe",
                "countryCode": "RU"
            },
            {
                "name": "Novy Rogachik",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabrsky",
                "countryCode": "RU"
            },
            {
                "name": "Olkhovka, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Otruba",
                "countryCode": "RU"
            },
            {
                "name": "Pallasovka",
                "countryCode": "RU"
            },
            {
                "name": "Petrov Val",
                "countryCode": "RU"
            },
            {
                "name": "Poltavsky",
                "countryCode": "RU"
            },
            {
                "name": "Popovsky",
                "countryCode": "RU"
            },
            {
                "name": "Preobrazhenskaya, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Primorsk",
                "countryCode": "RU"
            },
            {
                "name": "Repino",
                "countryCode": "RU"
            },
            {
                "name": "Rogachev",
                "countryCode": "RU"
            },
            {
                "name": "Rubyozhny",
                "countryCode": "RU"
            },
            {
                "name": "Rudnya, Volgograd Oblast",
                "countryCode": "RU"
            },
            {
                "name": "Russko-Osinovsky",
                "countryCode": "RU"
            },
            {
                "name": "Samoylovsky",
                "countryCode": "RU"
            },
            {
                "name": "Satarovsky",
                "countryCode": "RU"
            },
            {
                "name": "Savinka",
                "countryCode": "RU"
            },
            {
                "name": "Sebrovo",
                "countryCode": "RU"
            },
            {
                "name": "Serafimovich",
                "countryCode": "RU"
            },
            {
                "name": "Shirokov",
                "countryCode": "RU"
            },
            {
                "name": "Slashchyovskaya",
                "countryCode": "RU"
            },
            {
                "name": "Sovetsky District",
                "countryCode": "RU"
            },
            {
                "name": "Srednyaya Akhtuba",
                "countryCode": "RU"
            },
            {
                "name": "Staraya Poltavka",
                "countryCode": "RU"
            },
            {
                "name": "Surovikino",
                "countryCode": "RU"
            },
            {
                "name": "Svetly Yar",
                "countryCode": "RU"
            },
            {
                "name": "Tochilkin",
                "countryCode": "RU"
            },
            {
                "name": "Uryupinsk",
                "countryCode": "RU"
            },
            {
                "name": "Vodstroy",
                "countryCode": "RU"
            },
            {
                "name": "Volgograd",
                "countryCode": "RU"
            },
            {
                "name": "Volzhsky",
                "countryCode": "RU"
            },
            {
                "name": "Voroshilovskiy District, Volgograd",
                "countryCode": "RU"
            },
            {
                "name": "Yelan",
                "countryCode": "RU"
            },
            {
                "name": "Zaplavnoye",
                "countryCode": "RU"
            },
            {
                "name": "Zhirnovsk",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrov",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Andreyevo",
                "countryCode": "RU"
            },
            {
                "name": "Anopino",
                "countryCode": "RU"
            },
            {
                "name": "Arsaki",
                "countryCode": "RU"
            },
            {
                "name": "Balakirevo",
                "countryCode": "RU"
            },
            {
                "name": "Bavleny",
                "countryCode": "RU"
            },
            {
                "name": "Belaya Rechka",
                "countryCode": "RU"
            },
            {
                "name": "Bogolyubovo",
                "countryCode": "RU"
            },
            {
                "name": "Dobryatino",
                "countryCode": "RU"
            },
            {
                "name": "Galitsy",
                "countryCode": "RU"
            },
            {
                "name": "Golovino",
                "countryCode": "RU"
            },
            {
                "name": "Gorokhovets",
                "countryCode": "RU"
            },
            {
                "name": "Gusevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gus’-Khrustal’nyy",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Vorovskogo",
                "countryCode": "RU"
            },
            {
                "name": "Ivanishchi",
                "countryCode": "RU"
            },
            {
                "name": "Kameshkovo",
                "countryCode": "RU"
            },
            {
                "name": "Karabanovo",
                "countryCode": "RU"
            },
            {
                "name": "Kideksha",
                "countryCode": "RU"
            },
            {
                "name": "Kirzhach",
                "countryCode": "RU"
            },
            {
                "name": "Kol’chugino",
                "countryCode": "RU"
            },
            {
                "name": "Kol’chuginskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kommunar",
                "countryCode": "RU"
            },
            {
                "name": "Kosterevo",
                "countryCode": "RU"
            },
            {
                "name": "Kovrov",
                "countryCode": "RU"
            },
            {
                "name": "Krasnaya Gorbatka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Bogatyr’",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Oktyabr’",
                "countryCode": "RU"
            },
            {
                "name": "Kurlovo",
                "countryCode": "RU"
            },
            {
                "name": "Lakinsk",
                "countryCode": "RU"
            },
            {
                "name": "Luknovo",
                "countryCode": "RU"
            },
            {
                "name": "Melekhovo",
                "countryCode": "RU"
            },
            {
                "name": "Melenki",
                "countryCode": "RU"
            },
            {
                "name": "Melenkovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Mezinovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Mstera",
                "countryCode": "RU"
            },
            {
                "name": "Murom",
                "countryCode": "RU"
            },
            {
                "name": "Muromskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Muromtsevo",
                "countryCode": "RU"
            },
            {
                "name": "Nikologory",
                "countryCode": "RU"
            },
            {
                "name": "Novki",
                "countryCode": "RU"
            },
            {
                "name": "Novovyazniki",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Orgtrud",
                "countryCode": "RU"
            },
            {
                "name": "Petushinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Petushki",
                "countryCode": "RU"
            },
            {
                "name": "Pokrov",
                "countryCode": "RU"
            },
            {
                "name": "Raduzhnyy",
                "countryCode": "RU"
            },
            {
                "name": "Sobinka",
                "countryCode": "RU"
            },
            {
                "name": "Sobinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Stavrovo",
                "countryCode": "RU"
            },
            {
                "name": "Stepantsevo",
                "countryCode": "RU"
            },
            {
                "name": "Strunino",
                "countryCode": "RU"
            },
            {
                "name": "Sudogda",
                "countryCode": "RU"
            },
            {
                "name": "Sudogodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Suzdal’",
                "countryCode": "RU"
            },
            {
                "name": "Suzdal’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Urshel’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Velikodvorskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vladimir",
                "countryCode": "RU"
            },
            {
                "name": "Vorsha",
                "countryCode": "RU"
            },
            {
                "name": "Vyazniki",
                "countryCode": "RU"
            },
            {
                "name": "Yur’yev-Pol’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Yur’yev-Pol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Zolotkovo",
                "countryCode": "RU"
            },
            {
                "name": "Babayevo",
                "countryCode": "RU"
            },
            {
                "name": "Babayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belozërsk",
                "countryCode": "RU"
            },
            {
                "name": "Belozërskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chagoda",
                "countryCode": "RU"
            },
            {
                "name": "Chagodoshchenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Chebsara",
                "countryCode": "RU"
            },
            {
                "name": "Cherepovets",
                "countryCode": "RU"
            },
            {
                "name": "Cherepovetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ferapontovo",
                "countryCode": "RU"
            },
            {
                "name": "Goritsy",
                "countryCode": "RU"
            },
            {
                "name": "Gryazovets",
                "countryCode": "RU"
            },
            {
                "name": "Gryazovetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Babushkina",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Zhelyabova",
                "countryCode": "RU"
            },
            {
                "name": "Kadnikov",
                "countryCode": "RU"
            },
            {
                "name": "Kaduy",
                "countryCode": "RU"
            },
            {
                "name": "Kaduyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kharovsk",
                "countryCode": "RU"
            },
            {
                "name": "Kharovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Khokhlovo",
                "countryCode": "RU"
            },
            {
                "name": "Kichmengskiy Gorodok",
                "countryCode": "RU"
            },
            {
                "name": "Kichmengsko-Gorodetskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kirillov",
                "countryCode": "RU"
            },
            {
                "name": "Kirillovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Krasavino",
                "countryCode": "RU"
            },
            {
                "name": "Kuzino",
                "countryCode": "RU"
            },
            {
                "name": "Lipin Bor",
                "countryCode": "RU"
            },
            {
                "name": "Mezhdurechenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Molochnoye",
                "countryCode": "RU"
            },
            {
                "name": "Myaksa",
                "countryCode": "RU"
            },
            {
                "name": "Nelazskoye",
                "countryCode": "RU"
            },
            {
                "name": "Nikol’sk",
                "countryCode": "RU"
            },
            {
                "name": "Nyuksenitsa",
                "countryCode": "RU"
            },
            {
                "name": "Sazonovo",
                "countryCode": "RU"
            },
            {
                "name": "Sheksna",
                "countryCode": "RU"
            },
            {
                "name": "Sheksninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shuyskoye",
                "countryCode": "RU"
            },
            {
                "name": "Sokol",
                "countryCode": "RU"
            },
            {
                "name": "Sokol’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Suda",
                "countryCode": "RU"
            },
            {
                "name": "Syamzha",
                "countryCode": "RU"
            },
            {
                "name": "Syamzhenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tarnogskiy Gorodok",
                "countryCode": "RU"
            },
            {
                "name": "Tarnogskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tonshalovo",
                "countryCode": "RU"
            },
            {
                "name": "Totemskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tot’ma",
                "countryCode": "RU"
            },
            {
                "name": "Ustyuzhenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ustyuzhna",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Kubinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ust’ye",
                "countryCode": "RU"
            },
            {
                "name": "Velikiy Ustyug",
                "countryCode": "RU"
            },
            {
                "name": "Velikoustyugskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhovazhskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhovazh’ye",
                "countryCode": "RU"
            },
            {
                "name": "Vokhtoga",
                "countryCode": "RU"
            },
            {
                "name": "Vologda",
                "countryCode": "RU"
            },
            {
                "name": "Vologodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vozhega",
                "countryCode": "RU"
            },
            {
                "name": "Vytegorskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Vytegra",
                "countryCode": "RU"
            },
            {
                "name": "Zubovo",
                "countryCode": "RU"
            },
            {
                "name": "Abramovka",
                "countryCode": "RU"
            },
            {
                "name": "Anna",
                "countryCode": "RU"
            },
            {
                "name": "Anninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Belogor’ye",
                "countryCode": "RU"
            },
            {
                "name": "Bobrov",
                "countryCode": "RU"
            },
            {
                "name": "Bobrovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Boguchar",
                "countryCode": "RU"
            },
            {
                "name": "Borisoglebsk",
                "countryCode": "RU"
            },
            {
                "name": "Buturlinovka",
                "countryCode": "RU"
            },
            {
                "name": "Buturlinovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Davydovka",
                "countryCode": "RU"
            },
            {
                "name": "Devitsa",
                "countryCode": "RU"
            },
            {
                "name": "Drakino",
                "countryCode": "RU"
            },
            {
                "name": "Ertil’",
                "countryCode": "RU"
            },
            {
                "name": "Ertil’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gribanovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Gribanovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Imeni Pervogo Maya",
                "countryCode": "RU"
            },
            {
                "name": "Kalach",
                "countryCode": "RU"
            },
            {
                "name": "Kamenka",
                "countryCode": "RU"
            },
            {
                "name": "Kamenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kantemirovka",
                "countryCode": "RU"
            },
            {
                "name": "Kashirskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Kashirskoye",
                "countryCode": "RU"
            },
            {
                "name": "Khrenovoye",
                "countryCode": "RU"
            },
            {
                "name": "Kolodeznyy",
                "countryCode": "RU"
            },
            {
                "name": "Korotoyak",
                "countryCode": "RU"
            },
            {
                "name": "Kozlovka",
                "countryCode": "RU"
            },
            {
                "name": "Krasnolesnyy",
                "countryCode": "RU"
            },
            {
                "name": "Latnaya",
                "countryCode": "RU"
            },
            {
                "name": "Liski",
                "countryCode": "RU"
            },
            {
                "name": "Liskinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Losevo",
                "countryCode": "RU"
            },
            {
                "name": "Maslovka",
                "countryCode": "RU"
            },
            {
                "name": "Mitrofanovka",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnedevitsk",
                "countryCode": "RU"
            },
            {
                "name": "Nizhnedevitskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Kislyay",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Mamon",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Chigla",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Usman’",
                "countryCode": "RU"
            },
            {
                "name": "Novokhoperskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novokhopërsk",
                "countryCode": "RU"
            },
            {
                "name": "Novousmanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novovoronezh",
                "countryCode": "RU"
            },
            {
                "name": "Novozhivotinnoye",
                "countryCode": "RU"
            },
            {
                "name": "Ol’khovatka",
                "countryCode": "RU"
            },
            {
                "name": "Ol’khovatskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orlovo",
                "countryCode": "RU"
            },
            {
                "name": "Ostrogozhsk",
                "countryCode": "RU"
            },
            {
                "name": "Panino",
                "countryCode": "RU"
            },
            {
                "name": "Paninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovsk",
                "countryCode": "RU"
            },
            {
                "name": "Pavlovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pereleshino",
                "countryCode": "RU"
            },
            {
                "name": "Pereleshinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Podgorenskiy",
                "countryCode": "RU"
            },
            {
                "name": "Podgorenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Podgornoye",
                "countryCode": "RU"
            },
            {
                "name": "Podkletnoye",
                "countryCode": "RU"
            },
            {
                "name": "Povorino",
                "countryCode": "RU"
            },
            {
                "name": "Povorinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pridonskoy",
                "countryCode": "RU"
            },
            {
                "name": "Ramonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ramon’",
                "countryCode": "RU"
            },
            {
                "name": "Rep’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rossoshanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rossosh’",
                "countryCode": "RU"
            },
            {
                "name": "Sadovoye",
                "countryCode": "RU"
            },
            {
                "name": "Semiluki",
                "countryCode": "RU"
            },
            {
                "name": "Semilukskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Shilovo",
                "countryCode": "RU"
            },
            {
                "name": "Sloboda",
                "countryCode": "RU"
            },
            {
                "name": "Somovo",
                "countryCode": "RU"
            },
            {
                "name": "Sredniy Ikorets",
                "countryCode": "RU"
            },
            {
                "name": "Strelitsa",
                "countryCode": "RU"
            },
            {
                "name": "Talovaya",
                "countryCode": "RU"
            },
            {
                "name": "Ternovka",
                "countryCode": "RU"
            },
            {
                "name": "Ternovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uglyanets",
                "countryCode": "RU"
            },
            {
                "name": "Uryv-Pokrovka",
                "countryCode": "RU"
            },
            {
                "name": "Verkhniy Mamon",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Khava",
                "countryCode": "RU"
            },
            {
                "name": "Verkhnyaya Tishanka",
                "countryCode": "RU"
            },
            {
                "name": "Volya",
                "countryCode": "RU"
            },
            {
                "name": "Vorob’yevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Voronezh",
                "countryCode": "RU"
            },
            {
                "name": "Vorontsovka",
                "countryCode": "RU"
            },
            {
                "name": "Yelan’-Koleno",
                "countryCode": "RU"
            },
            {
                "name": "Yelan’-Kolenovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zabolotovka",
                "countryCode": "RU"
            },
            {
                "name": "Zemlyansk",
                "countryCode": "RU"
            },
            {
                "name": "Aksarka",
                "countryCode": "RU"
            },
            {
                "name": "Gubkinskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kharp",
                "countryCode": "RU"
            },
            {
                "name": "Korotchaevo",
                "countryCode": "RU"
            },
            {
                "name": "Labytnangi",
                "countryCode": "RU"
            },
            {
                "name": "Muravlenko",
                "countryCode": "RU"
            },
            {
                "name": "Muzhi",
                "countryCode": "RU"
            },
            {
                "name": "Mys-Kamennyy",
                "countryCode": "RU"
            },
            {
                "name": "Nadym",
                "countryCode": "RU"
            },
            {
                "name": "Nadymskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Urengoy",
                "countryCode": "RU"
            },
            {
                "name": "Noyabrsk",
                "countryCode": "RU"
            },
            {
                "name": "Nyda",
                "countryCode": "RU"
            },
            {
                "name": "Pangody",
                "countryCode": "RU"
            },
            {
                "name": "Priural’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Purovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Purpe",
                "countryCode": "RU"
            },
            {
                "name": "Salekhard",
                "countryCode": "RU"
            },
            {
                "name": "Shuryshkarskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Staryy Nadym",
                "countryCode": "RU"
            },
            {
                "name": "Tarko-Sale",
                "countryCode": "RU"
            },
            {
                "name": "Tazovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Urengoy",
                "countryCode": "RU"
            },
            {
                "name": "Yar-Sale",
                "countryCode": "RU"
            },
            {
                "name": "Berendeyevo",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shesel’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shoye Selo",
                "countryCode": "RU"
            },
            {
                "name": "Borisoglebskiy",
                "countryCode": "RU"
            },
            {
                "name": "Borisoglebskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Borok",
                "countryCode": "RU"
            },
            {
                "name": "Breytovo",
                "countryCode": "RU"
            },
            {
                "name": "Breytovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Burmakino",
                "countryCode": "RU"
            },
            {
                "name": "Danilov",
                "countryCode": "RU"
            },
            {
                "name": "Danilovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Dubki",
                "countryCode": "RU"
            },
            {
                "name": "Gavrilov-Yam",
                "countryCode": "RU"
            },
            {
                "name": "Gavrilov-Yamskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ishnya",
                "countryCode": "RU"
            },
            {
                "name": "Konstantinovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Profintern",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyye Tkachi",
                "countryCode": "RU"
            },
            {
                "name": "Kukoboy",
                "countryCode": "RU"
            },
            {
                "name": "Kurba",
                "countryCode": "RU"
            },
            {
                "name": "Levashevo",
                "countryCode": "RU"
            },
            {
                "name": "Lyubim",
                "countryCode": "RU"
            },
            {
                "name": "Lyubimskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Myshkin",
                "countryCode": "RU"
            },
            {
                "name": "Myshkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nekouzskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nekrasovskoye",
                "countryCode": "RU"
            },
            {
                "name": "Novyy Nekouz",
                "countryCode": "RU"
            },
            {
                "name": "Pereslavl’-Zalesskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pereslavskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Pesochnoye",
                "countryCode": "RU"
            },
            {
                "name": "Petrovsk",
                "countryCode": "RU"
            },
            {
                "name": "Porech’ye-Rybnoye",
                "countryCode": "RU"
            },
            {
                "name": "Poshekhonskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Poshekhon’ye",
                "countryCode": "RU"
            },
            {
                "name": "Prechistoye",
                "countryCode": "RU"
            },
            {
                "name": "Rostov",
                "countryCode": "RU"
            },
            {
                "name": "Rostovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Rybinsk",
                "countryCode": "RU"
            },
            {
                "name": "Rybinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Semibratovo",
                "countryCode": "RU"
            },
            {
                "name": "Sudoverf’",
                "countryCode": "RU"
            },
            {
                "name": "Tunoshna",
                "countryCode": "RU"
            },
            {
                "name": "Tutayev",
                "countryCode": "RU"
            },
            {
                "name": "Tutayevskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Uglich",
                "countryCode": "RU"
            },
            {
                "name": "Uglichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Volga",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavl",
                "countryCode": "RU"
            },
            {
                "name": "Yaroslavskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Amurzet",
                "countryCode": "RU"
            },
            {
                "name": "Babstovo",
                "countryCode": "RU"
            },
            {
                "name": "Bira",
                "countryCode": "RU"
            },
            {
                "name": "Birakan",
                "countryCode": "RU"
            },
            {
                "name": "Birobidzhan",
                "countryCode": "RU"
            },
            {
                "name": "Birobidzhanskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Izvestkovyy",
                "countryCode": "RU"
            },
            {
                "name": "Khingansk",
                "countryCode": "RU"
            },
            {
                "name": "Kul’dur",
                "countryCode": "RU"
            },
            {
                "name": "Leninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Leninskoye",
                "countryCode": "RU"
            },
            {
                "name": "Londoko",
                "countryCode": "RU"
            },
            {
                "name": "Obluchenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Oktyabr’skiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Priamurskiy",
                "countryCode": "RU"
            },
            {
                "name": "Smidovich",
                "countryCode": "RU"
            },
            {
                "name": "Smidovichskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Volochayevka Vtoraya",
                "countryCode": "RU"
            },
            {
                "name": "Aginskoye",
                "countryCode": "RU"
            },
            {
                "name": "Aksha",
                "countryCode": "RU"
            },
            {
                "name": "Akshinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovo-Zavodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Aleksandrovskiy Zavod",
                "countryCode": "RU"
            },
            {
                "name": "Amazar",
                "countryCode": "RU"
            },
            {
                "name": "Atamanovka",
                "countryCode": "RU"
            },
            {
                "name": "Bada",
                "countryCode": "RU"
            },
            {
                "name": "Baley",
                "countryCode": "RU"
            },
            {
                "name": "Baleyskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Balyaga",
                "countryCode": "RU"
            },
            {
                "name": "Bol’shaya Tura",
                "countryCode": "RU"
            },
            {
                "name": "Borzinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Borzya",
                "countryCode": "RU"
            },
            {
                "name": "Bukachacha",
                "countryCode": "RU"
            },
            {
                "name": "Chara",
                "countryCode": "RU"
            },
            {
                "name": "Chernyshevsk",
                "countryCode": "RU"
            },
            {
                "name": "Chita",
                "countryCode": "RU"
            },
            {
                "name": "Chitinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Darasun",
                "countryCode": "RU"
            },
            {
                "name": "Dauriya",
                "countryCode": "RU"
            },
            {
                "name": "Domna",
                "countryCode": "RU"
            },
            {
                "name": "Drovyanaya",
                "countryCode": "RU"
            },
            {
                "name": "Dul’durga",
                "countryCode": "RU"
            },
            {
                "name": "Gazimuro-Zavodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Gazimurskiy Zavod",
                "countryCode": "RU"
            },
            {
                "name": "Kadaya",
                "countryCode": "RU"
            },
            {
                "name": "Kalanguy",
                "countryCode": "RU"
            },
            {
                "name": "Kalga",
                "countryCode": "RU"
            },
            {
                "name": "Karymskoye",
                "countryCode": "RU"
            },
            {
                "name": "Khilok",
                "countryCode": "RU"
            },
            {
                "name": "Kholbon",
                "countryCode": "RU"
            },
            {
                "name": "Klichka",
                "countryCode": "RU"
            },
            {
                "name": "Klyuchevskiy",
                "countryCode": "RU"
            },
            {
                "name": "Kokuy",
                "countryCode": "RU"
            },
            {
                "name": "Krasnokamensk",
                "countryCode": "RU"
            },
            {
                "name": "Krasnyy Chikoy",
                "countryCode": "RU"
            },
            {
                "name": "Kuanda",
                "countryCode": "RU"
            },
            {
                "name": "Kurort-Darasun",
                "countryCode": "RU"
            },
            {
                "name": "Kyra",
                "countryCode": "RU"
            },
            {
                "name": "Makkaveyevo",
                "countryCode": "RU"
            },
            {
                "name": "Mogocha",
                "countryCode": "RU"
            },
            {
                "name": "Mogoytuy",
                "countryCode": "RU"
            },
            {
                "name": "Mogzon",
                "countryCode": "RU"
            },
            {
                "name": "Nerchinsk",
                "countryCode": "RU"
            },
            {
                "name": "Nerchinskiy Zavod",
                "countryCode": "RU"
            },
            {
                "name": "Nerchinsko-Zavodskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Nizhniy Tsasuchey",
                "countryCode": "RU"
            },
            {
                "name": "Novaya Chara",
                "countryCode": "RU"
            },
            {
                "name": "Novokruchininskiy",
                "countryCode": "RU"
            },
            {
                "name": "Novoorlovsk",
                "countryCode": "RU"
            },
            {
                "name": "Novopavlovka",
                "countryCode": "RU"
            },
            {
                "name": "Olovyannaya",
                "countryCode": "RU"
            },
            {
                "name": "Olovyanninskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Orlovskiy",
                "countryCode": "RU"
            },
            {
                "name": "Pervomayskiy",
                "countryCode": "RU"
            },
            {
                "name": "Petrovsk-Zabaykal’skiy",
                "countryCode": "RU"
            },
            {
                "name": "Priargunsk",
                "countryCode": "RU"
            },
            {
                "name": "Priargunskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Priiskovyy",
                "countryCode": "RU"
            },
            {
                "name": "Shelopugino",
                "countryCode": "RU"
            },
            {
                "name": "Shilka",
                "countryCode": "RU"
            },
            {
                "name": "Shilkinskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Smolenka",
                "countryCode": "RU"
            },
            {
                "name": "Sretensk",
                "countryCode": "RU"
            },
            {
                "name": "Sretenskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Tarbagatay",
                "countryCode": "RU"
            },
            {
                "name": "Uletovskiy Rayon",
                "countryCode": "RU"
            },
            {
                "name": "Ulety",
                "countryCode": "RU"
            },
            {
                "name": "Ust’-Karsk",
                "countryCode": "RU"
            },
            {
                "name": "Verkh-Usugli",
                "countryCode": "RU"
            },
            {
                "name": "Vershino-Darasunskiy",
                "countryCode": "RU"
            },
            {
                "name": "Vershino-Shakhtaminskiy",
                "countryCode": "RU"
            },
            {
                "name": "Zabaykal’sk",
                "countryCode": "RU"
            },
            {
                "name": "Zhireken",
                "countryCode": "RU"
            }
        ]
    },
    {
        "name": "Rwanda",
        "phonecode": "250",
        "cities": [
            {
                "name": "Kigali",
                "countryCode": "RW"
            },
            {
                "name": "Kibungo",
                "countryCode": "RW"
            },
            {
                "name": "Rwamagana",
                "countryCode": "RW"
            },
            {
                "name": "Byumba",
                "countryCode": "RW"
            },
            {
                "name": "Musanze",
                "countryCode": "RW"
            },
            {
                "name": "Cyangugu",
                "countryCode": "RW"
            },
            {
                "name": "Gisenyi",
                "countryCode": "RW"
            },
            {
                "name": "Kibuye",
                "countryCode": "RW"
            },
            {
                "name": "Butare",
                "countryCode": "RW"
            },
            {
                "name": "Eglise Catholique, Centrale GIKO",
                "countryCode": "RW"
            },
            {
                "name": "Gitarama",
                "countryCode": "RW"
            },
            {
                "name": "Nzega",
                "countryCode": "RW"
            }
        ]
    },
    {
        "name": "Saint Helena",
        "phonecode": "290",
        "cities": []
    },
    {
        "name": "Saint Kitts And Nevis",
        "phonecode": "+1-869",
        "cities": [
            {
                "name": "Nicola Town",
                "countryCode": "KN"
            },
            {
                "name": "Sandy Point Town",
                "countryCode": "KN"
            },
            {
                "name": "Market Shop",
                "countryCode": "KN"
            },
            {
                "name": "Newcastle",
                "countryCode": "KN"
            },
            {
                "name": "Dieppe Bay Town",
                "countryCode": "KN"
            },
            {
                "name": "Fig Tree",
                "countryCode": "KN"
            },
            {
                "name": "Cayon",
                "countryCode": "KN"
            },
            {
                "name": "Saint Paul’s",
                "countryCode": "KN"
            },
            {
                "name": "Charlestown",
                "countryCode": "KN"
            },
            {
                "name": "Monkey Hill",
                "countryCode": "KN"
            },
            {
                "name": "Cotton Ground",
                "countryCode": "KN"
            },
            {
                "name": "Middle Island",
                "countryCode": "KN"
            },
            {
                "name": "Trinity",
                "countryCode": "KN"
            }
        ]
    },
    {
        "name": "Saint Lucia",
        "phonecode": "+1-758",
        "cities": [
            {
                "name": "Anse La Raye",
                "countryCode": "LC"
            },
            {
                "name": "Au Tabor",
                "countryCode": "LC"
            },
            {
                "name": "Au Tabor Hill",
                "countryCode": "LC"
            },
            {
                "name": "Bois D'Inde",
                "countryCode": "LC"
            },
            {
                "name": "Caico/Millet",
                "countryCode": "LC"
            },
            {
                "name": "Canaries",
                "countryCode": "LC"
            },
            {
                "name": "Champen Estate",
                "countryCode": "LC"
            },
            {
                "name": "Derriere Lagoon",
                "countryCode": "LC"
            },
            {
                "name": "Durandeau",
                "countryCode": "LC"
            },
            {
                "name": "Enbar Pwin",
                "countryCode": "LC"
            },
            {
                "name": "Jacmel",
                "countryCode": "LC"
            },
            {
                "name": "Jean Baptiste",
                "countryCode": "LC"
            },
            {
                "name": "La Trielle",
                "countryCode": "LC"
            },
            {
                "name": "Massacre",
                "countryCode": "LC"
            },
            {
                "name": "Millet",
                "countryCode": "LC"
            },
            {
                "name": "Morne Ciseaux",
                "countryCode": "LC"
            },
            {
                "name": "Morne D'Or",
                "countryCode": "LC"
            },
            {
                "name": "Roseau Valley",
                "countryCode": "LC"
            },
            {
                "name": "St Lawrence",
                "countryCode": "LC"
            },
            {
                "name": "St Lawrence Estate",
                "countryCode": "LC"
            },
            {
                "name": "Tete Chemin",
                "countryCode": "LC"
            },
            {
                "name": "Vanard",
                "countryCode": "LC"
            },
            {
                "name": "Venus",
                "countryCode": "LC"
            },
            {
                "name": "Village",
                "countryCode": "LC"
            },
            {
                "name": "Village/Petite Bourgh",
                "countryCode": "LC"
            },
            {
                "name": "Active Hill",
                "countryCode": "LC"
            },
            {
                "name": "Agard Lands/Morne Dudon",
                "countryCode": "LC"
            },
            {
                "name": "Almondale",
                "countryCode": "LC"
            },
            {
                "name": "Aurendel Hill",
                "countryCode": "LC"
            },
            {
                "name": "Babonneau Proper",
                "countryCode": "LC"
            },
            {
                "name": "Bagatelle",
                "countryCode": "LC"
            },
            {
                "name": "Balata",
                "countryCode": "LC"
            },
            {
                "name": "Banannes Bay",
                "countryCode": "LC"
            },
            {
                "name": "Barnard Hill",
                "countryCode": "LC"
            },
            {
                "name": "Barre Denis",
                "countryCode": "LC"
            },
            {
                "name": "Barre Duchaussee",
                "countryCode": "LC"
            },
            {
                "name": "Barre St.Joseph",
                "countryCode": "LC"
            },
            {
                "name": "Belair",
                "countryCode": "LC"
            },
            {
                "name": "Bella Rosa",
                "countryCode": "LC"
            },
            {
                "name": "Bexon",
                "countryCode": "LC"
            },
            {
                "name": "Bisee",
                "countryCode": "LC"
            },
            {
                "name": "Bishop'S Gap",
                "countryCode": "LC"
            },
            {
                "name": "Bissee",
                "countryCode": "LC"
            },
            {
                "name": "Black Mallet",
                "countryCode": "LC"
            },
            {
                "name": "Bocage",
                "countryCode": "LC"
            },
            {
                "name": "Bois Catchet",
                "countryCode": "LC"
            },
            {
                "name": "Bois Patat",
                "countryCode": "LC"
            },
            {
                "name": "Cabiche/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Cacoa/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Calvary",
                "countryCode": "LC"
            },
            {
                "name": "Capital Hill",
                "countryCode": "LC"
            },
            {
                "name": "Carellie",
                "countryCode": "LC"
            },
            {
                "name": "Castries",
                "countryCode": "LC"
            },
            {
                "name": "Cedars",
                "countryCode": "LC"
            },
            {
                "name": "Chase Gardens",
                "countryCode": "LC"
            },
            {
                "name": "Chassin/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Choppin Ridge/Sarot",
                "countryCode": "LC"
            },
            {
                "name": "Ciceron",
                "countryCode": "LC"
            },
            {
                "name": "City",
                "countryCode": "LC"
            },
            {
                "name": "City Gate/La Clery",
                "countryCode": "LC"
            },
            {
                "name": "Conway/Waterfront",
                "countryCode": "LC"
            },
            {
                "name": "Coolie Town",
                "countryCode": "LC"
            },
            {
                "name": "Coubaril",
                "countryCode": "LC"
            },
            {
                "name": "Crownlands/Marc",
                "countryCode": "LC"
            },
            {
                "name": "Cul De Sac",
                "countryCode": "LC"
            },
            {
                "name": "Darling Road",
                "countryCode": "LC"
            },
            {
                "name": "Deglos",
                "countryCode": "LC"
            },
            {
                "name": "Derierre Fort/Old Victoria Road",
                "countryCode": "LC"
            },
            {
                "name": "Dubrassay",
                "countryCode": "LC"
            },
            {
                "name": "En Pois Doux/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Entrepot",
                "countryCode": "LC"
            },
            {
                "name": "Faux A Chaud",
                "countryCode": "LC"
            },
            {
                "name": "Ferrand",
                "countryCode": "LC"
            },
            {
                "name": "Floissac/Marc",
                "countryCode": "LC"
            },
            {
                "name": "Fond Assau/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Fond Canie",
                "countryCode": "LC"
            },
            {
                "name": "Forestiere",
                "countryCode": "LC"
            },
            {
                "name": "George Charles Boulevard",
                "countryCode": "LC"
            },
            {
                "name": "Girard",
                "countryCode": "LC"
            },
            {
                "name": "Goergeville",
                "countryCode": "LC"
            },
            {
                "name": "Goodlands",
                "countryCode": "LC"
            },
            {
                "name": "Grass Street",
                "countryCode": "LC"
            },
            {
                "name": "Green Gold",
                "countryCode": "LC"
            },
            {
                "name": "Guesneau",
                "countryCode": "LC"
            },
            {
                "name": "Hill 20/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Hillcrest Gardens",
                "countryCode": "LC"
            },
            {
                "name": "Hospital Road",
                "countryCode": "LC"
            },
            {
                "name": "Independence City",
                "countryCode": "LC"
            },
            {
                "name": "John Compton Highway",
                "countryCode": "LC"
            },
            {
                "name": "L'Anse Road",
                "countryCode": "LC"
            },
            {
                "name": "La Carierre",
                "countryCode": "LC"
            },
            {
                "name": "La Clery",
                "countryCode": "LC"
            },
            {
                "name": "La Croix Maingot",
                "countryCode": "LC"
            },
            {
                "name": "La Pansee",
                "countryCode": "LC"
            },
            {
                "name": "La Toc",
                "countryCode": "LC"
            },
            {
                "name": "Labayee",
                "countryCode": "LC"
            },
            {
                "name": "Lastic Hill",
                "countryCode": "LC"
            },
            {
                "name": "Leslie Land",
                "countryCode": "LC"
            },
            {
                "name": "Marc",
                "countryCode": "LC"
            },
            {
                "name": "Marchand",
                "countryCode": "LC"
            },
            {
                "name": "Marigot",
                "countryCode": "LC"
            },
            {
                "name": "Maynard Hill",
                "countryCode": "LC"
            },
            {
                "name": "Miami/Bexon",
                "countryCode": "LC"
            },
            {
                "name": "Monkey Town Ciceron",
                "countryCode": "LC"
            },
            {
                "name": "Morne Assau/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Morne Dudon",
                "countryCode": "LC"
            },
            {
                "name": "Morne Road",
                "countryCode": "LC"
            },
            {
                "name": "Morne Rouge/Marc",
                "countryCode": "LC"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "LC"
            },
            {
                "name": "New Village",
                "countryCode": "LC"
            },
            {
                "name": "Odsan",
                "countryCode": "LC"
            },
            {
                "name": "Parker'S Hill",
                "countryCode": "LC"
            },
            {
                "name": "Patterson'S Gap",
                "countryCode": "LC"
            },
            {
                "name": "Pavee",
                "countryCode": "LC"
            },
            {
                "name": "Peart'S Gap",
                "countryCode": "LC"
            },
            {
                "name": "Perou",
                "countryCode": "LC"
            },
            {
                "name": "Pointe Seraphine",
                "countryCode": "LC"
            },
            {
                "name": "Quarte Chemins",
                "countryCode": "LC"
            },
            {
                "name": "Ravine Chabot",
                "countryCode": "LC"
            },
            {
                "name": "Ravine Poisson",
                "countryCode": "LC"
            },
            {
                "name": "Ravine Touterelle",
                "countryCode": "LC"
            },
            {
                "name": "Resinard/Babonneau",
                "countryCode": "LC"
            },
            {
                "name": "Rock Hall",
                "countryCode": "LC"
            },
            {
                "name": "Rose Hill",
                "countryCode": "LC"
            },
            {
                "name": "San Soucis",
                "countryCode": "LC"
            },
            {
                "name": "Sand De Feu",
                "countryCode": "LC"
            },
            {
                "name": "Sarot",
                "countryCode": "LC"
            },
            {
                "name": "Summersdale",
                "countryCode": "LC"
            },
            {
                "name": "Sunbilt",
                "countryCode": "LC"
            },
            {
                "name": "Sunny Acres",
                "countryCode": "LC"
            },
            {
                "name": "Talvern",
                "countryCode": "LC"
            },
            {
                "name": "Tapion",
                "countryCode": "LC"
            },
            {
                "name": "The Morne",
                "countryCode": "LC"
            },
            {
                "name": "Ti Colon",
                "countryCode": "LC"
            },
            {
                "name": "Ti Rocher",
                "countryCode": "LC"
            },
            {
                "name": "Trois Piton",
                "countryCode": "LC"
            },
            {
                "name": "Trou Cochan/Marc",
                "countryCode": "LC"
            },
            {
                "name": "Trou Florent/Marc",
                "countryCode": "LC"
            },
            {
                "name": "Trou Rouge",
                "countryCode": "LC"
            },
            {
                "name": "Vide Bouteille",
                "countryCode": "LC"
            },
            {
                "name": "Vigie",
                "countryCode": "LC"
            },
            {
                "name": "Waterworks",
                "countryCode": "LC"
            },
            {
                "name": "Wilton'S Yard/Grave Yard",
                "countryCode": "LC"
            },
            {
                "name": "Yorke Hill",
                "countryCode": "LC"
            },
            {
                "name": "Belle Vue",
                "countryCode": "LC"
            },
            {
                "name": "Bois Dinde",
                "countryCode": "LC"
            },
            {
                "name": "Caffiere",
                "countryCode": "LC"
            },
            {
                "name": "Cedars/Chu Tigre",
                "countryCode": "LC"
            },
            {
                "name": "Choiseul",
                "countryCode": "LC"
            },
            {
                "name": "Christian Hill",
                "countryCode": "LC"
            },
            {
                "name": "Dacretin",
                "countryCode": "LC"
            },
            {
                "name": "Debreuil",
                "countryCode": "LC"
            },
            {
                "name": "Delcer",
                "countryCode": "LC"
            },
            {
                "name": "Derriere Morne",
                "countryCode": "LC"
            },
            {
                "name": "Dugard",
                "countryCode": "LC"
            },
            {
                "name": "Dupre",
                "countryCode": "LC"
            },
            {
                "name": "Esperance",
                "countryCode": "LC"
            },
            {
                "name": "Franciou",
                "countryCode": "LC"
            },
            {
                "name": "Industry",
                "countryCode": "LC"
            },
            {
                "name": "La Fargue",
                "countryCode": "LC"
            },
            {
                "name": "La Pointe",
                "countryCode": "LC"
            },
            {
                "name": "Lamaze",
                "countryCode": "LC"
            },
            {
                "name": "Le Riche",
                "countryCode": "LC"
            },
            {
                "name": "Mailly Motete",
                "countryCode": "LC"
            },
            {
                "name": "Martin",
                "countryCode": "LC"
            },
            {
                "name": "Mongouge",
                "countryCode": "LC"
            },
            {
                "name": "Monzie",
                "countryCode": "LC"
            },
            {
                "name": "Morne Jacques",
                "countryCode": "LC"
            },
            {
                "name": "Morne Sion",
                "countryCode": "LC"
            },
            {
                "name": "Motete",
                "countryCode": "LC"
            },
            {
                "name": "New Field/Fiette",
                "countryCode": "LC"
            },
            {
                "name": "Ponyon",
                "countryCode": "LC"
            },
            {
                "name": "Raveneau",
                "countryCode": "LC"
            },
            {
                "name": "Reunion",
                "countryCode": "LC"
            },
            {
                "name": "River Doree",
                "countryCode": "LC"
            },
            {
                "name": "Roblot",
                "countryCode": "LC"
            },
            {
                "name": "Sauzay",
                "countryCode": "LC"
            },
            {
                "name": "Savannesgeorge/Constitution",
                "countryCode": "LC"
            },
            {
                "name": "Victoria",
                "countryCode": "LC"
            },
            {
                "name": "Village",
                "countryCode": "LC"
            },
            {
                "name": "Anse Canot",
                "countryCode": "LC"
            },
            {
                "name": "Athens",
                "countryCode": "LC"
            },
            {
                "name": "Au Leon",
                "countryCode": "LC"
            },
            {
                "name": "Bara Bara",
                "countryCode": "LC"
            },
            {
                "name": "Bazile",
                "countryCode": "LC"
            },
            {
                "name": "Belmont",
                "countryCode": "LC"
            },
            {
                "name": "Bois Joli",
                "countryCode": "LC"
            },
            {
                "name": "Bordelais",
                "countryCode": "LC"
            },
            {
                "name": "Bosquet D'Or",
                "countryCode": "LC"
            },
            {
                "name": "Delaide",
                "countryCode": "LC"
            },
            {
                "name": "Dennery",
                "countryCode": "LC"
            },
            {
                "name": "Dennery By Pass",
                "countryCode": "LC"
            },
            {
                "name": "Dennery By Pass/Green Mountain",
                "countryCode": "LC"
            },
            {
                "name": "Dennery By Pass/Rocky Lane",
                "countryCode": "LC"
            },
            {
                "name": "Dennery By Pass/White Rock Gardens",
                "countryCode": "LC"
            },
            {
                "name": "Dennery Village",
                "countryCode": "LC"
            },
            {
                "name": "Derniere Riviere",
                "countryCode": "LC"
            },
            {
                "name": "Derniere Riviere/Fond Maricient",
                "countryCode": "LC"
            },
            {
                "name": "Derniere Riviere/Mardi Gras/Morne Caca Cochon",
                "countryCode": "LC"
            },
            {
                "name": "Derniere Riviere/Morne Panache",
                "countryCode": "LC"
            },
            {
                "name": "Despinoze",
                "countryCode": "LC"
            },
            {
                "name": "Dubonnaire",
                "countryCode": "LC"
            },
            {
                "name": "Gadette",
                "countryCode": "LC"
            },
            {
                "name": "Grande Ravine",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Des Branch",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/En Leur Morne/Discompere",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Funier",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Morne Caca Cochon",
                "countryCode": "LC"
            },
            {
                "name": "La Caye",
                "countryCode": "LC"
            },
            {
                "name": "La Pelle",
                "countryCode": "LC"
            },
            {
                "name": "La Pointe",
                "countryCode": "LC"
            },
            {
                "name": "La Ressource",
                "countryCode": "LC"
            },
            {
                "name": "Lumiere",
                "countryCode": "LC"
            },
            {
                "name": "Morne Panache",
                "countryCode": "LC"
            },
            {
                "name": "Riche Fond",
                "countryCode": "LC"
            },
            {
                "name": "Riche Fond/La Belle Vie",
                "countryCode": "LC"
            },
            {
                "name": "Riche Fond/New Village",
                "countryCode": "LC"
            },
            {
                "name": "St. Joseph Estate",
                "countryCode": "LC"
            },
            {
                "name": "Thamazo",
                "countryCode": "LC"
            },
            {
                "name": "Beausejour",
                "countryCode": "LC"
            },
            {
                "name": "Beausejour/Fostin'S Development",
                "countryCode": "LC"
            },
            {
                "name": "Beausejour/Ndc",
                "countryCode": "LC"
            },
            {
                "name": "Bella Rosa",
                "countryCode": "LC"
            },
            {
                "name": "Belle Vue",
                "countryCode": "LC"
            },
            {
                "name": "Belle Vue Estate",
                "countryCode": "LC"
            },
            {
                "name": "Boguis",
                "countryCode": "LC"
            },
            {
                "name": "Boguis/Desa Blond",
                "countryCode": "LC"
            },
            {
                "name": "Bois D'Orange",
                "countryCode": "LC"
            },
            {
                "name": "Bois D'Ornange/Trouya",
                "countryCode": "LC"
            },
            {
                "name": "Bonneterre",
                "countryCode": "LC"
            },
            {
                "name": "Bonneterre Gardens",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Becune Point",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Caribbean Park",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Golf Park",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Lower Saline Point",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Mon Du Cap",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Ranch Site",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Saddlec Back",
                "countryCode": "LC"
            },
            {
                "name": "Cap Estate/Upper Saline Point",
                "countryCode": "LC"
            },
            {
                "name": "Cas En Bas",
                "countryCode": "LC"
            },
            {
                "name": "Caye Manje'",
                "countryCode": "LC"
            },
            {
                "name": "Corinth",
                "countryCode": "LC"
            },
            {
                "name": "Corinth Estate",
                "countryCode": "LC"
            },
            {
                "name": "Corinth/La Bel Lair",
                "countryCode": "LC"
            },
            {
                "name": "Dauphin",
                "countryCode": "LC"
            },
            {
                "name": "Des Barras",
                "countryCode": "LC"
            },
            {
                "name": "Des Barras/Cacolie",
                "countryCode": "LC"
            },
            {
                "name": "Desrameaux",
                "countryCode": "LC"
            },
            {
                "name": "Garrand",
                "countryCode": "LC"
            },
            {
                "name": "Grand Riviere",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Assou Canal",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Ingle Woods",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Morne Serpent",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Norbert",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/Piat",
                "countryCode": "LC"
            },
            {
                "name": "Grande Riviere/White Rock",
                "countryCode": "LC"
            },
            {
                "name": "Gros Islet",
                "countryCode": "LC"
            },
            {
                "name": "Gros Islet Town",
                "countryCode": "LC"
            },
            {
                "name": "Gros Islet/Edge Water",
                "countryCode": "LC"
            },
            {
                "name": "L'Hermitage",
                "countryCode": "LC"
            },
            {
                "name": "La Croix Chabourgh",
                "countryCode": "LC"
            },
            {
                "name": "La Guerre",
                "countryCode": "LC"
            },
            {
                "name": "La Guerre/Chicken Back Street",
                "countryCode": "LC"
            },
            {
                "name": "Marisule",
                "countryCode": "LC"
            },
            {
                "name": "Marisule/Bon Air",
                "countryCode": "LC"
            },
            {
                "name": "Marisule/East Winds",
                "countryCode": "LC"
            },
            {
                "name": "Marisule/La Brellotte",
                "countryCode": "LC"
            },
            {
                "name": "Marisule/Top Of The World",
                "countryCode": "LC"
            },
            {
                "name": "Marquis Estate",
                "countryCode": "LC"
            },
            {
                "name": "Massade",
                "countryCode": "LC"
            },
            {
                "name": "Monchy",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Careffe",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Cletus Village",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/La Borne",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/La Borne/Sans Souci",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/La Retraite",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Lafeuillee",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Lawi Fwen",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Malgretoute",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Moulin A Vent",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Ravine Macock",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Riviere Mitan",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Ti Dauphin",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Vieux Sucreic",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Vieux Sucreic/Bois D'Inde",
                "countryCode": "LC"
            },
            {
                "name": "Monchy/Vieux Sucreic/Careffe",
                "countryCode": "LC"
            },
            {
                "name": "Mongiraud",
                "countryCode": "LC"
            },
            {
                "name": "Monier",
                "countryCode": "LC"
            },
            {
                "name": "Morne Citon",
                "countryCode": "LC"
            },
            {
                "name": "Paix Bouche",
                "countryCode": "LC"
            },
            {
                "name": "Pigeon Island",
                "countryCode": "LC"
            },
            {
                "name": "Plateau",
                "countryCode": "LC"
            },
            {
                "name": "Reduit",
                "countryCode": "LC"
            },
            {
                "name": "Reduit Orchard",
                "countryCode": "LC"
            },
            {
                "name": "Reduit Park",
                "countryCode": "LC"
            },
            {
                "name": "Rodney Bay",
                "countryCode": "LC"
            },
            {
                "name": "Rodney Heights",
                "countryCode": "LC"
            },
            {
                "name": "Union",
                "countryCode": "LC"
            },
            {
                "name": "Union Terrace",
                "countryCode": "LC"
            },
            {
                "name": "Union/Ti Morne",
                "countryCode": "LC"
            },
            {
                "name": "Annus",
                "countryCode": "LC"
            },
            {
                "name": "Balca",
                "countryCode": "LC"
            },
            {
                "name": "Balca/En Leur Ba",
                "countryCode": "LC"
            },
            {
                "name": "Balembouche",
                "countryCode": "LC"
            },
            {
                "name": "Banse",
                "countryCode": "LC"
            },
            {
                "name": "Banse La Grace",
                "countryCode": "LC"
            },
            {
                "name": "Bongalo",
                "countryCode": "LC"
            },
            {
                "name": "Daban",
                "countryCode": "LC"
            },
            {
                "name": "Fond Berange",
                "countryCode": "LC"
            },
            {
                "name": "Gayabois",
                "countryCode": "LC"
            },
            {
                "name": "Gentil",
                "countryCode": "LC"
            },
            {
                "name": "Getrine",
                "countryCode": "LC"
            },
            {
                "name": "Giraud",
                "countryCode": "LC"
            },
            {
                "name": "H'Erelle",
                "countryCode": "LC"
            },
            {
                "name": "Kennedy Highway/Laborie Bypass",
                "countryCode": "LC"
            },
            {
                "name": "La Haut",
                "countryCode": "LC"
            },
            {
                "name": "La Perle",
                "countryCode": "LC"
            },
            {
                "name": "Laborie",
                "countryCode": "LC"
            },
            {
                "name": "Londonderry",
                "countryCode": "LC"
            },
            {
                "name": "Macdomel",
                "countryCode": "LC"
            },
            {
                "name": "Morne Gomier",
                "countryCode": "LC"
            },
            {
                "name": "Morne Le Blanc",
                "countryCode": "LC"
            },
            {
                "name": "Olibo",
                "countryCode": "LC"
            },
            {
                "name": "Parc Estate",
                "countryCode": "LC"
            },
            {
                "name": "Piaye",
                "countryCode": "LC"
            },
            {
                "name": "Saltibus",
                "countryCode": "LC"
            },
            {
                "name": "Saphire",
                "countryCode": "LC"
            },
            {
                "name": "Tete Morne",
                "countryCode": "LC"
            },
            {
                "name": "Village",
                "countryCode": "LC"
            },
            {
                "name": "Warwick/Daban",
                "countryCode": "LC"
            },
            {
                "name": "Anse Ger",
                "countryCode": "LC"
            },
            {
                "name": "Beauchamp",
                "countryCode": "LC"
            },
            {
                "name": "Blanchard",
                "countryCode": "LC"
            },
            {
                "name": "Canelles",
                "countryCode": "LC"
            },
            {
                "name": "Cazuca",
                "countryCode": "LC"
            },
            {
                "name": "Chique/Blanchard",
                "countryCode": "LC"
            },
            {
                "name": "Coolie Town",
                "countryCode": "LC"
            },
            {
                "name": "Delomel",
                "countryCode": "LC"
            },
            {
                "name": "Des Blanchard",
                "countryCode": "LC"
            },
            {
                "name": "Desruisseaux",
                "countryCode": "LC"
            },
            {
                "name": "Dugard",
                "countryCode": "LC"
            },
            {
                "name": "Escap",
                "countryCode": "LC"
            },
            {
                "name": "Fond D'Lor/Dugard",
                "countryCode": "LC"
            },
            {
                "name": "Fond Estate",
                "countryCode": "LC"
            },
            {
                "name": "Fond/Desruisseaux",
                "countryCode": "LC"
            },
            {
                "name": "Gomier",
                "countryCode": "LC"
            },
            {
                "name": "Gouette",
                "countryCode": "LC"
            },
            {
                "name": "Honeymoon Beach",
                "countryCode": "LC"
            },
            {
                "name": "L'Eau Mineau",
                "countryCode": "LC"
            },
            {
                "name": "La Courville",
                "countryCode": "LC"
            },
            {
                "name": "La Pointe",
                "countryCode": "LC"
            },
            {
                "name": "La Tille",
                "countryCode": "LC"
            },
            {
                "name": "Lezy",
                "countryCode": "LC"
            },
            {
                "name": "Lombard",
                "countryCode": "LC"
            },
            {
                "name": "Mahaut",
                "countryCode": "LC"
            },
            {
                "name": "Malgretoute",
                "countryCode": "LC"
            },
            {
                "name": "Mamiku",
                "countryCode": "LC"
            },
            {
                "name": "Micoud",
                "countryCode": "LC"
            },
            {
                "name": "Mon Repos",
                "countryCode": "LC"
            },
            {
                "name": "Moreau",
                "countryCode": "LC"
            },
            {
                "name": "Morne Vient",
                "countryCode": "LC"
            },
            {
                "name": "Myette Gardens",
                "countryCode": "LC"
            },
            {
                "name": "New Village",
                "countryCode": "LC"
            },
            {
                "name": "Paix Bouche",
                "countryCode": "LC"
            },
            {
                "name": "Palmiste Estate",
                "countryCode": "LC"
            },
            {
                "name": "Patience",
                "countryCode": "LC"
            },
            {
                "name": "Planard",
                "countryCode": "LC"
            },
            {
                "name": "Praslin",
                "countryCode": "LC"
            },
            {
                "name": "Rameau",
                "countryCode": "LC"
            },
            {
                "name": "Saint Marie Road",
                "countryCode": "LC"
            },
            {
                "name": "Saut",
                "countryCode": "LC"
            },
            {
                "name": "Savannes",
                "countryCode": "LC"
            },
            {
                "name": "St Helen Estate",
                "countryCode": "LC"
            },
            {
                "name": "Ti Riviere",
                "countryCode": "LC"
            },
            {
                "name": "Ti Rocher",
                "countryCode": "LC"
            },
            {
                "name": "Troumassee",
                "countryCode": "LC"
            },
            {
                "name": "Village",
                "countryCode": "LC"
            },
            {
                "name": "Volet",
                "countryCode": "LC"
            },
            {
                "name": "Anse Chastanet",
                "countryCode": "LC"
            },
            {
                "name": "Barons Drive/Coin De L'Anse",
                "countryCode": "LC"
            },
            {
                "name": "Beasejour/Myers Bridge",
                "countryCode": "LC"
            },
            {
                "name": "Belle Plaine",
                "countryCode": "LC"
            },
            {
                "name": "Bellefond",
                "countryCode": "LC"
            },
            {
                "name": "Belvedere",
                "countryCode": "LC"
            },
            {
                "name": "Bois D'Inde",
                "countryCode": "LC"
            },
            {
                "name": "Bouton",
                "countryCode": "LC"
            },
            {
                "name": "Calvary/Calvaire",
                "countryCode": "LC"
            },
            {
                "name": "Cenac",
                "countryCode": "LC"
            },
            {
                "name": "Chateau Belair",
                "countryCode": "LC"
            },
            {
                "name": "Colombette",
                "countryCode": "LC"
            },
            {
                "name": "Compar",
                "countryCode": "LC"
            },
            {
                "name": "Cresslands",
                "countryCode": "LC"
            },
            {
                "name": "Deville",
                "countryCode": "LC"
            },
            {
                "name": "Diamond/Diamond Estate",
                "countryCode": "LC"
            },
            {
                "name": "Esperance",
                "countryCode": "LC"
            },
            {
                "name": "Etangs",
                "countryCode": "LC"
            },
            {
                "name": "Fond Bernier",
                "countryCode": "LC"
            },
            {
                "name": "Fond Cacoa",
                "countryCode": "LC"
            },
            {
                "name": "Fond Doux",
                "countryCode": "LC"
            },
            {
                "name": "Fond Gens Libre",
                "countryCode": "LC"
            },
            {
                "name": "Jalousie",
                "countryCode": "LC"
            },
            {
                "name": "La Haut",
                "countryCode": "LC"
            },
            {
                "name": "La Pearle",
                "countryCode": "LC"
            },
            {
                "name": "Lenny Hill",
                "countryCode": "LC"
            },
            {
                "name": "Malgretoute",
                "countryCode": "LC"
            },
            {
                "name": "Migny",
                "countryCode": "LC"
            },
            {
                "name": "Mocha",
                "countryCode": "LC"
            },
            {
                "name": "Morne Bonin",
                "countryCode": "LC"
            },
            {
                "name": "Morne La Croix",
                "countryCode": "LC"
            },
            {
                "name": "Morne Lastic/Desruisseaux",
                "countryCode": "LC"
            },
            {
                "name": "New Development",
                "countryCode": "LC"
            },
            {
                "name": "Palmiste",
                "countryCode": "LC"
            },
            {
                "name": "Plat Pays",
                "countryCode": "LC"
            },
            {
                "name": "Rabot",
                "countryCode": "LC"
            },
            {
                "name": "Ravine Claire",
                "countryCode": "LC"
            },
            {
                "name": "Ruby Estate",
                "countryCode": "LC"
            },
            {
                "name": "Soufriere Estate",
                "countryCode": "LC"
            },
            {
                "name": "Soufrière",
                "countryCode": "LC"
            },
            {
                "name": "St Phillip",
                "countryCode": "LC"
            },
            {
                "name": "Stonefield",
                "countryCode": "LC"
            },
            {
                "name": "Sulphur Springs",
                "countryCode": "LC"
            },
            {
                "name": "Ti Boug",
                "countryCode": "LC"
            },
            {
                "name": "Ti Delcer",
                "countryCode": "LC"
            },
            {
                "name": "Toraille",
                "countryCode": "LC"
            },
            {
                "name": "Town",
                "countryCode": "LC"
            },
            {
                "name": "Union Vale",
                "countryCode": "LC"
            },
            {
                "name": "Zenon",
                "countryCode": "LC"
            },
            {
                "name": "Augier",
                "countryCode": "LC"
            },
            {
                "name": "Beane Field",
                "countryCode": "LC"
            },
            {
                "name": "Beausejour",
                "countryCode": "LC"
            },
            {
                "name": "Belle Vue",
                "countryCode": "LC"
            },
            {
                "name": "Black Bay",
                "countryCode": "LC"
            },
            {
                "name": "Bruceville/Shanty Town",
                "countryCode": "LC"
            },
            {
                "name": "Cacoa",
                "countryCode": "LC"
            },
            {
                "name": "Cantonement",
                "countryCode": "LC"
            },
            {
                "name": "Carierre",
                "countryCode": "LC"
            },
            {
                "name": "Catin",
                "countryCode": "LC"
            },
            {
                "name": "Cocoa Dan",
                "countryCode": "LC"
            },
            {
                "name": "Coolie Town",
                "countryCode": "LC"
            },
            {
                "name": "De Mailly",
                "countryCode": "LC"
            },
            {
                "name": "Derierre Bois",
                "countryCode": "LC"
            },
            {
                "name": "Derierre Morne",
                "countryCode": "LC"
            },
            {
                "name": "Docamel/La Resource",
                "countryCode": "LC"
            },
            {
                "name": "Eau Piquant/St Urbain",
                "countryCode": "LC"
            },
            {
                "name": "En Bamboo",
                "countryCode": "LC"
            },
            {
                "name": "Esperance",
                "countryCode": "LC"
            },
            {
                "name": "Fond Capeche",
                "countryCode": "LC"
            },
            {
                "name": "Fond Sabot",
                "countryCode": "LC"
            },
            {
                "name": "Grace",
                "countryCode": "LC"
            },
            {
                "name": "Hewanorra Orchard",
                "countryCode": "LC"
            },
            {
                "name": "Hope Estate",
                "countryCode": "LC"
            },
            {
                "name": "Industrial Estate",
                "countryCode": "LC"
            },
            {
                "name": "Joyeux",
                "countryCode": "LC"
            },
            {
                "name": "La Resource",
                "countryCode": "LC"
            },
            {
                "name": "La Retraite",
                "countryCode": "LC"
            },
            {
                "name": "La Tourney/Cedar Heights",
                "countryCode": "LC"
            },
            {
                "name": "Maganier",
                "countryCode": "LC"
            },
            {
                "name": "Morne Cayenne",
                "countryCode": "LC"
            },
            {
                "name": "Morne Vert",
                "countryCode": "LC"
            },
            {
                "name": "Moule A Chique",
                "countryCode": "LC"
            },
            {
                "name": "Obrier",
                "countryCode": "LC"
            },
            {
                "name": "Pierrot",
                "countryCode": "LC"
            },
            {
                "name": "Plut",
                "countryCode": "LC"
            },
            {
                "name": "Pomme",
                "countryCode": "LC"
            },
            {
                "name": "St Jude'S Highway",
                "countryCode": "LC"
            },
            {
                "name": "Tete Morne/Morne Andrew",
                "countryCode": "LC"
            },
            {
                "name": "Town",
                "countryCode": "LC"
            },
            {
                "name": "Vieux Fort",
                "countryCode": "LC"
            },
            {
                "name": "Vieux Fort/Laborie Highway",
                "countryCode": "LC"
            },
            {
                "name": "Vige'",
                "countryCode": "LC"
            },
            {
                "name": "Westall Group/The Mangue",
                "countryCode": "LC"
            },
            {
                "name": "Anse Cochon",
                "countryCode": "LC"
            },
            {
                "name": "Anse Galet",
                "countryCode": "LC"
            },
            {
                "name": "Anse La Verdue",
                "countryCode": "LC"
            },
            {
                "name": "Belvedere",
                "countryCode": "LC"
            },
            {
                "name": "Chalon",
                "countryCode": "LC"
            },
            {
                "name": "Riverside Road",
                "countryCode": "LC"
            },
            {
                "name": "Theodrine",
                "countryCode": "LC"
            },
            {
                "name": "Village",
                "countryCode": "LC"
            }
        ]
    },
    {
        "name": "Saint Pierre and Miquelon",
        "phonecode": "508",
        "cities": []
    },
    {
        "name": "Saint Vincent And The Grenadines",
        "phonecode": "+1-784",
        "cities": [
            {
                "name": "Biabou",
                "countryCode": "VC"
            },
            {
                "name": "Byera Village",
                "countryCode": "VC"
            },
            {
                "name": "Georgetown",
                "countryCode": "VC"
            },
            {
                "name": "Layou",
                "countryCode": "VC"
            },
            {
                "name": "Chateaubelair",
                "countryCode": "VC"
            },
            {
                "name": "Kingstown",
                "countryCode": "VC"
            },
            {
                "name": "Kingstown Park",
                "countryCode": "VC"
            },
            {
                "name": "Barrouallie",
                "countryCode": "VC"
            },
            {
                "name": "Port Elizabeth",
                "countryCode": "VC"
            }
        ]
    },
    {
        "name": "Saint-Barthelemy",
        "phonecode": "590",
        "cities": []
    },
    {
        "name": "Saint-Martin (French part)",
        "phonecode": "590",
        "cities": []
    },
    {
        "name": "Samoa",
        "phonecode": "685",
        "cities": [
            {
                "name": "Fasito‘outa",
                "countryCode": "WS"
            },
            {
                "name": "Leulumoega",
                "countryCode": "WS"
            },
            {
                "name": "Nofoali‘i",
                "countryCode": "WS"
            },
            {
                "name": "Satapuala",
                "countryCode": "WS"
            },
            {
                "name": "Mulifanua",
                "countryCode": "WS"
            },
            {
                "name": "Falefa",
                "countryCode": "WS"
            },
            {
                "name": "Lotofagā",
                "countryCode": "WS"
            },
            {
                "name": "Lufilufi",
                "countryCode": "WS"
            },
            {
                "name": "Solosolo",
                "countryCode": "WS"
            },
            {
                "name": "Matavai",
                "countryCode": "WS"
            },
            {
                "name": "Safotu",
                "countryCode": "WS"
            },
            {
                "name": "Gataivai",
                "countryCode": "WS"
            },
            {
                "name": "Vailoa",
                "countryCode": "WS"
            },
            {
                "name": "Afega",
                "countryCode": "WS"
            },
            {
                "name": "Apia",
                "countryCode": "WS"
            },
            {
                "name": "Malie",
                "countryCode": "WS"
            },
            {
                "name": "Vailima",
                "countryCode": "WS"
            },
            {
                "name": "Vaiusu",
                "countryCode": "WS"
            },
            {
                "name": "Samamea",
                "countryCode": "WS"
            },
            {
                "name": "Asau",
                "countryCode": "WS"
            }
        ]
    },
    {
        "name": "San Marino",
        "phonecode": "378",
        "cities": [
            {
                "name": "Acquaviva",
                "countryCode": "SM"
            },
            {
                "name": "Poggio di Chiesanuova",
                "countryCode": "SM"
            },
            {
                "name": "Domagnano",
                "countryCode": "SM"
            },
            {
                "name": "Faetano",
                "countryCode": "SM"
            },
            {
                "name": "Fiorentino",
                "countryCode": "SM"
            },
            {
                "name": "Borgo Maggiore",
                "countryCode": "SM"
            },
            {
                "name": "San Marino",
                "countryCode": "SM"
            },
            {
                "name": "Monte Giardino",
                "countryCode": "SM"
            },
            {
                "name": "Serravalle",
                "countryCode": "SM"
            }
        ]
    },
    {
        "name": "Sao Tome and Principe",
        "phonecode": "239",
        "cities": [
            {
                "name": "Santo António",
                "countryCode": "ST"
            },
            {
                "name": "Cantagalo District",
                "countryCode": "ST"
            },
            {
                "name": "Caué District",
                "countryCode": "ST"
            },
            {
                "name": "São Tomé",
                "countryCode": "ST"
            },
            {
                "name": "Trindade",
                "countryCode": "ST"
            }
        ]
    },
    {
        "name": "Saudi Arabia",
        "phonecode": "966",
        "cities": [
            {
                "name": "Ad Dawādimī",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dilam",
                "countryCode": "SA"
            },
            {
                "name": "Afif",
                "countryCode": "SA"
            },
            {
                "name": "Ain AlBaraha",
                "countryCode": "SA"
            },
            {
                "name": "Al Arţāwīyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Bir",
                "countryCode": "SA"
            },
            {
                "name": "Al Hair",
                "countryCode": "SA"
            },
            {
                "name": "Al Jurayfah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kharj",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rayn",
                "countryCode": "SA"
            },
            {
                "name": "As Salamiyah",
                "countryCode": "SA"
            },
            {
                "name": "As Sulayyil",
                "countryCode": "SA"
            },
            {
                "name": "Az Zulfī",
                "countryCode": "SA"
            },
            {
                "name": "Dawadmi",
                "countryCode": "SA"
            },
            {
                "name": "Diriyah",
                "countryCode": "SA"
            },
            {
                "name": "Harmah",
                "countryCode": "SA"
            },
            {
                "name": "Jalajil",
                "countryCode": "SA"
            },
            {
                "name": "Layla",
                "countryCode": "SA"
            },
            {
                "name": "Manfuha",
                "countryCode": "SA"
            },
            {
                "name": "Marāt",
                "countryCode": "SA"
            },
            {
                "name": "Najan",
                "countryCode": "SA"
            },
            {
                "name": "Najran",
                "countryCode": "SA"
            },
            {
                "name": "Riyadh",
                "countryCode": "SA"
            },
            {
                "name": "Sājir",
                "countryCode": "SA"
            },
            {
                "name": "Tumayr",
                "countryCode": "SA"
            },
            {
                "name": "shokhaibٍ",
                "countryCode": "SA"
            },
            {
                "name": "Abha",
                "countryCode": "SA"
            },
            {
                "name": "Abu Hisani",
                "countryCode": "SA"
            },
            {
                "name": "Abu Qirfah",
                "countryCode": "SA"
            },
            {
                "name": "Abu Shu`ayb",
                "countryCode": "SA"
            },
            {
                "name": "Abu `Urwah",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dabbah",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dawh",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dur",
                "countryCode": "SA"
            },
            {
                "name": "Al Adl",
                "countryCode": "SA"
            },
            {
                "name": "Al Ashraf",
                "countryCode": "SA"
            },
            {
                "name": "Al Balad",
                "countryCode": "SA"
            },
            {
                "name": "Al Barabir",
                "countryCode": "SA"
            },
            {
                "name": "Al Bi'ar",
                "countryCode": "SA"
            },
            {
                "name": "Al Birk",
                "countryCode": "SA"
            },
            {
                "name": "Al Buraykah",
                "countryCode": "SA"
            },
            {
                "name": "Al Fawwarah",
                "countryCode": "SA"
            },
            {
                "name": "Al Faydah",
                "countryCode": "SA"
            },
            {
                "name": "Al Fazz",
                "countryCode": "SA"
            },
            {
                "name": "Al Gharith",
                "countryCode": "SA"
            },
            {
                "name": "Al Ghassalah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ghulah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hadā",
                "countryCode": "SA"
            },
            {
                "name": "Al Halaqah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hamimah",
                "countryCode": "SA"
            },
            {
                "name": "Al Harra', Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hawiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Iskan",
                "countryCode": "SA"
            },
            {
                "name": "Al Jadidah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jami`ah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jid`",
                "countryCode": "SA"
            },
            {
                "name": "Al Ju`ranah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jumūm",
                "countryCode": "SA"
            },
            {
                "name": "Al Khadra', Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khalas",
                "countryCode": "SA"
            },
            {
                "name": "Al Khamrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khaydar",
                "countryCode": "SA"
            },
            {
                "name": "Al Khayf",
                "countryCode": "SA"
            },
            {
                "name": "Al Khulasah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kidwah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kura`",
                "countryCode": "SA"
            },
            {
                "name": "Al Ma`rash",
                "countryCode": "SA"
            },
            {
                "name": "Al Madiq, Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Maghal",
                "countryCode": "SA"
            },
            {
                "name": "Al Mahjar",
                "countryCode": "SA"
            },
            {
                "name": "Al Majāridah",
                "countryCode": "SA"
            },
            {
                "name": "Al Maqrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Masarrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Masfalah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mashayikh",
                "countryCode": "SA"
            },
            {
                "name": "Al Mathnah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mubarak",
                "countryCode": "SA"
            },
            {
                "name": "Al Mudawwarah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mulayha'",
                "countryCode": "SA"
            },
            {
                "name": "Al Mundassah",
                "countryCode": "SA"
            },
            {
                "name": "Al Muqayti`",
                "countryCode": "SA"
            },
            {
                "name": "Al Muqr",
                "countryCode": "SA"
            },
            {
                "name": "Al Muwayh",
                "countryCode": "SA"
            },
            {
                "name": "Al Qadimah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qahab",
                "countryCode": "SA"
            },
            {
                "name": "Al Qararah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qaryat",
                "countryCode": "SA"
            },
            {
                "name": "Al Qawba`iyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qirshan",
                "countryCode": "SA"
            },
            {
                "name": "Al Qu`tubah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qufayf",
                "countryCode": "SA"
            },
            {
                "name": "Al Qushashiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ukhaydir",
                "countryCode": "SA"
            },
            {
                "name": "Al Waht",
                "countryCode": "SA"
            },
            {
                "name": "Al Ābār",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rabwah as Sufla",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rafah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rawdah ash Shamaliyah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rudaymah",
                "countryCode": "SA"
            },
            {
                "name": "Arya`",
                "countryCode": "SA"
            },
            {
                "name": "As Sadr",
                "countryCode": "SA"
            },
            {
                "name": "As Samd ash Shamali",
                "countryCode": "SA"
            },
            {
                "name": "As Sayl al Kabir",
                "countryCode": "SA"
            },
            {
                "name": "As Sayl as Saghir",
                "countryCode": "SA"
            },
            {
                "name": "As Sifyani",
                "countryCode": "SA"
            },
            {
                "name": "As Sudayrah, Makkah",
                "countryCode": "SA"
            },
            {
                "name": "As Suwadah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shafā",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shajwah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shamiyah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shara'i`",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shaybi",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shi`b",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shishah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shumaysi",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shuwaybit",
                "countryCode": "SA"
            },
            {
                "name": "At Tan`im",
                "countryCode": "SA"
            },
            {
                "name": "At Tarfa'",
                "countryCode": "SA"
            },
            {
                "name": "At Turqi",
                "countryCode": "SA"
            },
            {
                "name": "Az Zaymah",
                "countryCode": "SA"
            },
            {
                "name": "Az Zilal",
                "countryCode": "SA"
            },
            {
                "name": "Az Zughbah",
                "countryCode": "SA"
            },
            {
                "name": "Az Zurra`",
                "countryCode": "SA"
            },
            {
                "name": "Az Zuwayb",
                "countryCode": "SA"
            },
            {
                "name": "Bahrat al Qadimah",
                "countryCode": "SA"
            },
            {
                "name": "Bahwil",
                "countryCode": "SA"
            },
            {
                "name": "Baranah",
                "countryCode": "SA"
            },
            {
                "name": "Barzah",
                "countryCode": "SA"
            },
            {
                "name": "Bashm",
                "countryCode": "SA"
            },
            {
                "name": "Buraykah",
                "countryCode": "SA"
            },
            {
                "name": "Burayman",
                "countryCode": "SA"
            },
            {
                "name": "CITY GHRAN",
                "countryCode": "SA"
            },
            {
                "name": "Dabyah",
                "countryCode": "SA"
            },
            {
                "name": "Dahaban",
                "countryCode": "SA"
            },
            {
                "name": "Dughaybjah",
                "countryCode": "SA"
            },
            {
                "name": "Fayd",
                "countryCode": "SA"
            },
            {
                "name": "Ghran",
                "countryCode": "SA"
            },
            {
                "name": "Hadda'",
                "countryCode": "SA"
            },
            {
                "name": "Haddat ash Sham",
                "countryCode": "SA"
            },
            {
                "name": "Hadhah",
                "countryCode": "SA"
            },
            {
                "name": "Hajur",
                "countryCode": "SA"
            },
            {
                "name": "Halamah",
                "countryCode": "SA"
            },
            {
                "name": "Husnah",
                "countryCode": "SA"
            },
            {
                "name": "Jarwal",
                "countryCode": "SA"
            },
            {
                "name": "Jeddah",
                "countryCode": "SA"
            },
            {
                "name": "Julayyil",
                "countryCode": "SA"
            },
            {
                "name": "Khamis Mushait",
                "countryCode": "SA"
            },
            {
                "name": "Khumrah",
                "countryCode": "SA"
            },
            {
                "name": "Kulakh",
                "countryCode": "SA"
            },
            {
                "name": "Ma`riyah",
                "countryCode": "SA"
            },
            {
                "name": "Madrakah",
                "countryCode": "SA"
            },
            {
                "name": "Mafruq",
                "countryCode": "SA"
            },
            {
                "name": "Malakan",
                "countryCode": "SA"
            },
            {
                "name": "Mashajji",
                "countryCode": "SA"
            },
            {
                "name": "Masihat Mahd al Hayl",
                "countryCode": "SA"
            },
            {
                "name": "Maskar",
                "countryCode": "SA"
            },
            {
                "name": "Matiyah",
                "countryCode": "SA"
            },
            {
                "name": "Mecca",
                "countryCode": "SA"
            },
            {
                "name": "Mifa",
                "countryCode": "SA"
            },
            {
                "name": "Mina",
                "countryCode": "SA"
            },
            {
                "name": "Munayzir",
                "countryCode": "SA"
            },
            {
                "name": "Murshidiyah",
                "countryCode": "SA"
            },
            {
                "name": "Mushrif",
                "countryCode": "SA"
            },
            {
                "name": "Nughayshiyah",
                "countryCode": "SA"
            },
            {
                "name": "Nuzlat al Faqin",
                "countryCode": "SA"
            },
            {
                "name": "Qiya",
                "countryCode": "SA"
            },
            {
                "name": "Quwayzah",
                "countryCode": "SA"
            },
            {
                "name": "Rabwah Ghran",
                "countryCode": "SA"
            },
            {
                "name": "Raqiyah",
                "countryCode": "SA"
            },
            {
                "name": "Rābigh",
                "countryCode": "SA"
            },
            {
                "name": "Sabuhah",
                "countryCode": "SA"
            },
            {
                "name": "Shi`b `amir",
                "countryCode": "SA"
            },
            {
                "name": "Shira`ayn",
                "countryCode": "SA"
            },
            {
                "name": "Sulaym",
                "countryCode": "SA"
            },
            {
                "name": "Sumaymah",
                "countryCode": "SA"
            },
            {
                "name": "Suways",
                "countryCode": "SA"
            },
            {
                "name": "Ta'if",
                "countryCode": "SA"
            },
            {
                "name": "Tabālah",
                "countryCode": "SA"
            },
            {
                "name": "Tharwah",
                "countryCode": "SA"
            },
            {
                "name": "Thuwal",
                "countryCode": "SA"
            },
            {
                "name": "Turabah",
                "countryCode": "SA"
            },
            {
                "name": "Usfan",
                "countryCode": "SA"
            },
            {
                "name": "Wadi al Jalil",
                "countryCode": "SA"
            },
            {
                "name": "Abu Dhiba",
                "countryCode": "SA"
            },
            {
                "name": "Abyar 'Ali",
                "countryCode": "SA"
            },
            {
                "name": "Abū Shayţānah",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dulu`",
                "countryCode": "SA"
            },
            {
                "name": "Al Akhal",
                "countryCode": "SA"
            },
            {
                "name": "Al Bardiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Biqa'",
                "countryCode": "SA"
            },
            {
                "name": "Al Bustan",
                "countryCode": "SA"
            },
            {
                "name": "Al Faqirah",
                "countryCode": "SA"
            },
            {
                "name": "Al Furaysh",
                "countryCode": "SA"
            },
            {
                "name": "Al Fuwayliq",
                "countryCode": "SA"
            },
            {
                "name": "Al Jabriyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jissah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kharma'",
                "countryCode": "SA"
            },
            {
                "name": "Al Madiq, Al Madinah",
                "countryCode": "SA"
            },
            {
                "name": "Al Malbanah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mufrihat",
                "countryCode": "SA"
            },
            {
                "name": "Al Multasa",
                "countryCode": "SA"
            },
            {
                "name": "Al Musayjid",
                "countryCode": "SA"
            },
            {
                "name": "Al Thybiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Wuday",
                "countryCode": "SA"
            },
            {
                "name": "Al `Awali",
                "countryCode": "SA"
            },
            {
                "name": "Al `Uqul",
                "countryCode": "SA"
            },
            {
                "name": "Al-Jafr",
                "countryCode": "SA"
            },
            {
                "name": "Al-Ula",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rass",
                "countryCode": "SA"
            },
            {
                "name": "As Sadayir",
                "countryCode": "SA"
            },
            {
                "name": "As Safra'",
                "countryCode": "SA"
            },
            {
                "name": "As Sumariyah",
                "countryCode": "SA"
            },
            {
                "name": "As Suwayriqiyah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shufayyah",
                "countryCode": "SA"
            },
            {
                "name": "Asira",
                "countryCode": "SA"
            },
            {
                "name": "Badr Ḩunayn",
                "countryCode": "SA"
            },
            {
                "name": "Baq`a'",
                "countryCode": "SA"
            },
            {
                "name": "Bartiyah",
                "countryCode": "SA"
            },
            {
                "name": "Bi'r al Mashi",
                "countryCode": "SA"
            },
            {
                "name": "Birkah",
                "countryCode": "SA"
            },
            {
                "name": "Buraidah",
                "countryCode": "SA"
            },
            {
                "name": "Dukhnah",
                "countryCode": "SA"
            },
            {
                "name": "Far`",
                "countryCode": "SA"
            },
            {
                "name": "Fiji",
                "countryCode": "SA"
            },
            {
                "name": "Harthiyah",
                "countryCode": "SA"
            },
            {
                "name": "Hasa, Al Madinah",
                "countryCode": "SA"
            },
            {
                "name": "Haylat Radi al Baham",
                "countryCode": "SA"
            },
            {
                "name": "Husayniyah",
                "countryCode": "SA"
            },
            {
                "name": "Jadidah",
                "countryCode": "SA"
            },
            {
                "name": "Khayf Fadil",
                "countryCode": "SA"
            },
            {
                "name": "Madsus",
                "countryCode": "SA"
            },
            {
                "name": "Mahattat al Hafah",
                "countryCode": "SA"
            },
            {
                "name": "Maqrah",
                "countryCode": "SA"
            },
            {
                "name": "Maqshush",
                "countryCode": "SA"
            },
            {
                "name": "Masahili",
                "countryCode": "SA"
            },
            {
                "name": "Mastoorah",
                "countryCode": "SA"
            },
            {
                "name": "Mawarah",
                "countryCode": "SA"
            },
            {
                "name": "Medina",
                "countryCode": "SA"
            },
            {
                "name": "Milhah",
                "countryCode": "SA"
            },
            {
                "name": "Nujayl",
                "countryCode": "SA"
            },
            {
                "name": "Qaba'",
                "countryCode": "SA"
            },
            {
                "name": "Qiba",
                "countryCode": "SA"
            },
            {
                "name": "Rayyis",
                "countryCode": "SA"
            },
            {
                "name": "Sha`tha'",
                "countryCode": "SA"
            },
            {
                "name": "Sidi Hamzah",
                "countryCode": "SA"
            },
            {
                "name": "Sulţānah",
                "countryCode": "SA"
            },
            {
                "name": "Suq Suwayq",
                "countryCode": "SA"
            },
            {
                "name": "Suqubiya",
                "countryCode": "SA"
            },
            {
                "name": "Suwadah",
                "countryCode": "SA"
            },
            {
                "name": "Tanūmah",
                "countryCode": "SA"
            },
            {
                "name": "Wasitah",
                "countryCode": "SA"
            },
            {
                "name": "Yanbu",
                "countryCode": "SA"
            },
            {
                "name": "`Ajmiyah",
                "countryCode": "SA"
            },
            {
                "name": "`Alya'",
                "countryCode": "SA"
            },
            {
                "name": "`Ushash",
                "countryCode": "SA"
            },
            {
                "name": "`Ushayrah",
                "countryCode": "SA"
            },
            {
                "name": "Abqaiq",
                "countryCode": "SA"
            },
            {
                "name": "Al Awjām",
                "countryCode": "SA"
            },
            {
                "name": "Al Baţţālīyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hufūf",
                "countryCode": "SA"
            },
            {
                "name": "Al Jafr",
                "countryCode": "SA"
            },
            {
                "name": "Al Jubayl",
                "countryCode": "SA"
            },
            {
                "name": "Al Khafjī",
                "countryCode": "SA"
            },
            {
                "name": "Al Markaz",
                "countryCode": "SA"
            },
            {
                "name": "Al Mubarraz",
                "countryCode": "SA"
            },
            {
                "name": "Al Munayzilah",
                "countryCode": "SA"
            },
            {
                "name": "Al Muţayrifī",
                "countryCode": "SA"
            },
            {
                "name": "Al Qaţīf",
                "countryCode": "SA"
            },
            {
                "name": "Al Qurayn",
                "countryCode": "SA"
            },
            {
                "name": "Al Qārah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ubaylah",
                "countryCode": "SA"
            },
            {
                "name": "Al-Awamiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al-Awjam",
                "countryCode": "SA"
            },
            {
                "name": "Al-Mubarraz",
                "countryCode": "SA"
            },
            {
                "name": "As Saffānīyah",
                "countryCode": "SA"
            },
            {
                "name": "At Tūbī",
                "countryCode": "SA"
            },
            {
                "name": "Aţ Ţaraf",
                "countryCode": "SA"
            },
            {
                "name": "Dammam",
                "countryCode": "SA"
            },
            {
                "name": "Dhahran",
                "countryCode": "SA"
            },
            {
                "name": "Ha'il ",
                "countryCode": "SA"
            },
            {
                "name": "Hafar Al-Batin",
                "countryCode": "SA"
            },
            {
                "name": "Haradh",
                "countryCode": "SA"
            },
            {
                "name": "Jubbah",
                "countryCode": "SA"
            },
            {
                "name": "Julayjilah",
                "countryCode": "SA"
            },
            {
                "name": "Khobar",
                "countryCode": "SA"
            },
            {
                "name": "Mawqaq",
                "countryCode": "SA"
            },
            {
                "name": "Mulayjah",
                "countryCode": "SA"
            },
            {
                "name": "Nariyah",
                "countryCode": "SA"
            },
            {
                "name": "Qaisumah",
                "countryCode": "SA"
            },
            {
                "name": "Qufar",
                "countryCode": "SA"
            },
            {
                "name": "Raḩīmah",
                "countryCode": "SA"
            },
            {
                "name": "Sayhāt",
                "countryCode": "SA"
            },
            {
                "name": "Simira",
                "countryCode": "SA"
            },
            {
                "name": "Tārūt",
                "countryCode": "SA"
            },
            {
                "name": "Udhailiyah",
                "countryCode": "SA"
            },
            {
                "name": "Umm as Sāhik",
                "countryCode": "SA"
            },
            {
                "name": "Uqair",
                "countryCode": "SA"
            },
            {
                "name": "Şafwá",
                "countryCode": "SA"
            },
            {
                "name": "Adh Dhibiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Bukayrīyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Fuwayliq",
                "countryCode": "SA"
            },
            {
                "name": "Al Mithnab",
                "countryCode": "SA"
            },
            {
                "name": "Al Thybiyah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rass",
                "countryCode": "SA"
            },
            {
                "name": "Arar",
                "countryCode": "SA"
            },
            {
                "name": "Buraidah",
                "countryCode": "SA"
            },
            {
                "name": "Buraydah",
                "countryCode": "SA"
            },
            {
                "name": "Dukhnah",
                "countryCode": "SA"
            },
            {
                "name": "Nisab",
                "countryCode": "SA"
            },
            {
                "name": "Qiba",
                "countryCode": "SA"
            },
            {
                "name": "Tanūmah",
                "countryCode": "SA"
            },
            {
                "name": "Umm Radamah",
                "countryCode": "SA"
            },
            {
                "name": "Wed Alnkil",
                "countryCode": "SA"
            },
            {
                "name": "Ha'il",
                "countryCode": "SA"
            },
            {
                "name": "Jubbah",
                "countryCode": "SA"
            },
            {
                "name": "Mawqaq",
                "countryCode": "SA"
            },
            {
                "name": "Qufar",
                "countryCode": "SA"
            },
            {
                "name": "Simira",
                "countryCode": "SA"
            },
            {
                "name": "Al Wajh",
                "countryCode": "SA"
            },
            {
                "name": "Duba",
                "countryCode": "SA"
            },
            {
                "name": "Tabuk",
                "countryCode": "SA"
            },
            {
                "name": "Umm Lajj",
                "countryCode": "SA"
            },
            {
                "name": "Arar",
                "countryCode": "SA"
            },
            {
                "name": "Nisab",
                "countryCode": "SA"
            },
            {
                "name": "Turaif",
                "countryCode": "SA"
            },
            {
                "name": "Umm Radamah",
                "countryCode": "SA"
            },
            {
                "name": "Abu Radif",
                "countryCode": "SA"
            },
            {
                "name": "Abū ‘Arīsh",
                "countryCode": "SA"
            },
            {
                "name": "Ad Darb",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dur`iyah",
                "countryCode": "SA"
            },
            {
                "name": "Adh Dhagharir",
                "countryCode": "SA"
            },
            {
                "name": "Al Badawi",
                "countryCode": "SA"
            },
            {
                "name": "Al Hadrur",
                "countryCode": "SA"
            },
            {
                "name": "Al Hanashah",
                "countryCode": "SA"
            },
            {
                "name": "Al Harani",
                "countryCode": "SA"
            },
            {
                "name": "Al Hasamah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hijfar",
                "countryCode": "SA"
            },
            {
                "name": "Al Jadi",
                "countryCode": "SA"
            },
            {
                "name": "Al Jarādīyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jawah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jirbah",
                "countryCode": "SA"
            },
            {
                "name": "Al Karbus",
                "countryCode": "SA"
            },
            {
                "name": "Al Kawahilah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khadra', Jizan",
                "countryCode": "SA"
            },
            {
                "name": "Al Kharabah, Jizan",
                "countryCode": "SA"
            },
            {
                "name": "Al Kharadilah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khashabiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khubah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kirs",
                "countryCode": "SA"
            },
            {
                "name": "Al Luqiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ma`ayin",
                "countryCode": "SA"
            },
            {
                "name": "Al Madaya",
                "countryCode": "SA"
            },
            {
                "name": "Al Mali",
                "countryCode": "SA"
            },
            {
                "name": "Al Mayasam",
                "countryCode": "SA"
            },
            {
                "name": "Al Qa'im",
                "countryCode": "SA"
            },
            {
                "name": "Al Quful",
                "countryCode": "SA"
            },
            {
                "name": "Al Qurayb",
                "countryCode": "SA"
            },
            {
                "name": "Al Quwah",
                "countryCode": "SA"
            },
            {
                "name": "Al Wasili",
                "countryCode": "SA"
            },
            {
                "name": "Al `Ulayin",
                "countryCode": "SA"
            },
            {
                "name": "Al `Usaylah",
                "countryCode": "SA"
            },
            {
                "name": "An Najamiyah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rukubah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shuqayq",
                "countryCode": "SA"
            },
            {
                "name": "Bakhshat Yamani",
                "countryCode": "SA"
            },
            {
                "name": "Farasān",
                "countryCode": "SA"
            },
            {
                "name": "Ghawiyah",
                "countryCode": "SA"
            },
            {
                "name": "Hamayyah",
                "countryCode": "SA"
            },
            {
                "name": "Hamdah",
                "countryCode": "SA"
            },
            {
                "name": "Jizan",
                "countryCode": "SA"
            },
            {
                "name": "Juha, Saudi Arabia",
                "countryCode": "SA"
            },
            {
                "name": "Ka`lul",
                "countryCode": "SA"
            },
            {
                "name": "Khabath Sa`id",
                "countryCode": "SA"
            },
            {
                "name": "Khalfah",
                "countryCode": "SA"
            },
            {
                "name": "Khatib, Saudi Arabia",
                "countryCode": "SA"
            },
            {
                "name": "Khumsiyah",
                "countryCode": "SA"
            },
            {
                "name": "Khushaym",
                "countryCode": "SA"
            },
            {
                "name": "Mahatah",
                "countryCode": "SA"
            },
            {
                "name": "Malgocta",
                "countryCode": "SA"
            },
            {
                "name": "Mislīyah",
                "countryCode": "SA"
            },
            {
                "name": "Mizhirah",
                "countryCode": "SA"
            },
            {
                "name": "Mukambal",
                "countryCode": "SA"
            },
            {
                "name": "Mundaraq",
                "countryCode": "SA"
            },
            {
                "name": "Muwassam",
                "countryCode": "SA"
            },
            {
                "name": "Qitabir",
                "countryCode": "SA"
            },
            {
                "name": "Quwayda'",
                "countryCode": "SA"
            },
            {
                "name": "Rahwan",
                "countryCode": "SA"
            },
            {
                "name": "Rawkhah",
                "countryCode": "SA"
            },
            {
                "name": "Sadiliyah",
                "countryCode": "SA"
            },
            {
                "name": "Salamah",
                "countryCode": "SA"
            },
            {
                "name": "Şabyā",
                "countryCode": "SA"
            },
            {
                "name": "Şāmitah",
                "countryCode": "SA"
            },
            {
                "name": "Najrān",
                "countryCode": "SA"
            },
            {
                "name": "Abu Hisani",
                "countryCode": "SA"
            },
            {
                "name": "Abu Qirfah",
                "countryCode": "SA"
            },
            {
                "name": "Abu Shu`ayb",
                "countryCode": "SA"
            },
            {
                "name": "Abu `Urwah",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dabbah",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dawh",
                "countryCode": "SA"
            },
            {
                "name": "Ad Dur",
                "countryCode": "SA"
            },
            {
                "name": "Al Adl",
                "countryCode": "SA"
            },
            {
                "name": "Al Ashraf",
                "countryCode": "SA"
            },
            {
                "name": "Al Bahah",
                "countryCode": "SA"
            },
            {
                "name": "Al Balad",
                "countryCode": "SA"
            },
            {
                "name": "Al Barabir",
                "countryCode": "SA"
            },
            {
                "name": "Al Bi'ar",
                "countryCode": "SA"
            },
            {
                "name": "Al Birk",
                "countryCode": "SA"
            },
            {
                "name": "Al Buraykah",
                "countryCode": "SA"
            },
            {
                "name": "Al Fawwarah",
                "countryCode": "SA"
            },
            {
                "name": "Al Faydah",
                "countryCode": "SA"
            },
            {
                "name": "Al Fazz",
                "countryCode": "SA"
            },
            {
                "name": "Al Gharith",
                "countryCode": "SA"
            },
            {
                "name": "Al Ghassalah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ghulah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hada",
                "countryCode": "SA"
            },
            {
                "name": "Al Halaqah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hamimah",
                "countryCode": "SA"
            },
            {
                "name": "Al Harra', Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Hawiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Iskan",
                "countryCode": "SA"
            },
            {
                "name": "Al Jadidah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jami`ah",
                "countryCode": "SA"
            },
            {
                "name": "Al Jid`",
                "countryCode": "SA"
            },
            {
                "name": "Al Ju`ranah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khadra', Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khalas",
                "countryCode": "SA"
            },
            {
                "name": "Al Khamrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Khaydar",
                "countryCode": "SA"
            },
            {
                "name": "Al Khayf",
                "countryCode": "SA"
            },
            {
                "name": "Al Khulasah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kidwah",
                "countryCode": "SA"
            },
            {
                "name": "Al Kura`",
                "countryCode": "SA"
            },
            {
                "name": "Al Ma`rash",
                "countryCode": "SA"
            },
            {
                "name": "Al Madiq, Makkah",
                "countryCode": "SA"
            },
            {
                "name": "Al Maghal",
                "countryCode": "SA"
            },
            {
                "name": "Al Mahjar",
                "countryCode": "SA"
            },
            {
                "name": "Al Maqrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Masarrah",
                "countryCode": "SA"
            },
            {
                "name": "Al Masfalah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mashayikh",
                "countryCode": "SA"
            },
            {
                "name": "Al Mathnah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mindak",
                "countryCode": "SA"
            },
            {
                "name": "Al Mubarak",
                "countryCode": "SA"
            },
            {
                "name": "Al Mudawwarah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mulayha'",
                "countryCode": "SA"
            },
            {
                "name": "Al Mundassah",
                "countryCode": "SA"
            },
            {
                "name": "Al Muqayti`",
                "countryCode": "SA"
            },
            {
                "name": "Al Muqr",
                "countryCode": "SA"
            },
            {
                "name": "Al Muwayh",
                "countryCode": "SA"
            },
            {
                "name": "Al Qadimah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qararah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qaryat",
                "countryCode": "SA"
            },
            {
                "name": "Al Qawba`iyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qirshan",
                "countryCode": "SA"
            },
            {
                "name": "Al Qu`tubah",
                "countryCode": "SA"
            },
            {
                "name": "Al Qufayf",
                "countryCode": "SA"
            },
            {
                "name": "Al Qushashiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al Ukhaydir",
                "countryCode": "SA"
            },
            {
                "name": "Al Waht",
                "countryCode": "SA"
            },
            {
                "name": "Al Ābār",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rabwah as Sufla",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rafah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rawdah ash Shamaliyah",
                "countryCode": "SA"
            },
            {
                "name": "Ar Rudaymah",
                "countryCode": "SA"
            },
            {
                "name": "Arya`",
                "countryCode": "SA"
            },
            {
                "name": "As Sadr",
                "countryCode": "SA"
            },
            {
                "name": "As Samd ash Shamali",
                "countryCode": "SA"
            },
            {
                "name": "As Sayl al Kabir",
                "countryCode": "SA"
            },
            {
                "name": "As Sayl as Saghir",
                "countryCode": "SA"
            },
            {
                "name": "As Sifyani",
                "countryCode": "SA"
            },
            {
                "name": "As Sudayrah, Makkah",
                "countryCode": "SA"
            },
            {
                "name": "As Suwadah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shajwah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shamiyah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shara'i`",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shaybi",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shi`b",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shishah",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shumaysi",
                "countryCode": "SA"
            },
            {
                "name": "Ash Shuwaybit",
                "countryCode": "SA"
            },
            {
                "name": "At Tan`im",
                "countryCode": "SA"
            },
            {
                "name": "At Tarfa'",
                "countryCode": "SA"
            },
            {
                "name": "At Turqi",
                "countryCode": "SA"
            },
            {
                "name": "Az Zaymah",
                "countryCode": "SA"
            },
            {
                "name": "Az Zilal",
                "countryCode": "SA"
            },
            {
                "name": "Az Zughbah",
                "countryCode": "SA"
            },
            {
                "name": "Az Zurra`",
                "countryCode": "SA"
            },
            {
                "name": "Az Zuwayb",
                "countryCode": "SA"
            },
            {
                "name": "Bahrat al Qadimah",
                "countryCode": "SA"
            },
            {
                "name": "Bahwil",
                "countryCode": "SA"
            },
            {
                "name": "Baranah",
                "countryCode": "SA"
            },
            {
                "name": "Barzah",
                "countryCode": "SA"
            },
            {
                "name": "Bashm",
                "countryCode": "SA"
            },
            {
                "name": "Buraykah",
                "countryCode": "SA"
            },
            {
                "name": "Burayman",
                "countryCode": "SA"
            },
            {
                "name": "CITY GHRAN",
                "countryCode": "SA"
            },
            {
                "name": "Dabyah",
                "countryCode": "SA"
            },
            {
                "name": "Dahaban",
                "countryCode": "SA"
            },
            {
                "name": "Dughaybjah",
                "countryCode": "SA"
            },
            {
                "name": "Fayd",
                "countryCode": "SA"
            },
            {
                "name": "Ghran",
                "countryCode": "SA"
            },
            {
                "name": "Hadda'",
                "countryCode": "SA"
            },
            {
                "name": "Haddat ash Sham",
                "countryCode": "SA"
            },
            {
                "name": "Hadhah",
                "countryCode": "SA"
            },
            {
                "name": "Hajrah",
                "countryCode": "SA"
            },
            {
                "name": "Hajur",
                "countryCode": "SA"
            },
            {
                "name": "Halamah",
                "countryCode": "SA"
            },
            {
                "name": "Husnah",
                "countryCode": "SA"
            },
            {
                "name": "Jarwal",
                "countryCode": "SA"
            },
            {
                "name": "Jeddah",
                "countryCode": "SA"
            },
            {
                "name": "Julayyil",
                "countryCode": "SA"
            },
            {
                "name": "Khumrah",
                "countryCode": "SA"
            },
            {
                "name": "Kulakh",
                "countryCode": "SA"
            },
            {
                "name": "Madrakah",
                "countryCode": "SA"
            },
            {
                "name": "Mafruq",
                "countryCode": "SA"
            },
            {
                "name": "Malakan",
                "countryCode": "SA"
            },
            {
                "name": "Mashajji",
                "countryCode": "SA"
            },
            {
                "name": "Masihat Mahd al Hayl",
                "countryCode": "SA"
            },
            {
                "name": "Maskar",
                "countryCode": "SA"
            },
            {
                "name": "Matiyah",
                "countryCode": "SA"
            },
            {
                "name": "Mecca",
                "countryCode": "SA"
            },
            {
                "name": "Mina",
                "countryCode": "SA"
            },
            {
                "name": "Murshidiyah",
                "countryCode": "SA"
            },
            {
                "name": "Mushrif",
                "countryCode": "SA"
            },
            {
                "name": "Nughayshiyah",
                "countryCode": "SA"
            },
            {
                "name": "Nuzlat al Faqin",
                "countryCode": "SA"
            },
            {
                "name": "Qiya",
                "countryCode": "SA"
            },
            {
                "name": "Quwayzah",
                "countryCode": "SA"
            },
            {
                "name": "Rabwah Ghran",
                "countryCode": "SA"
            },
            {
                "name": "Raqiyah",
                "countryCode": "SA"
            },
            {
                "name": "Sabuhah",
                "countryCode": "SA"
            },
            {
                "name": "Shi`b `amir",
                "countryCode": "SA"
            },
            {
                "name": "Shira`ayn",
                "countryCode": "SA"
            },
            {
                "name": "Sulaym",
                "countryCode": "SA"
            },
            {
                "name": "Sumaymah",
                "countryCode": "SA"
            },
            {
                "name": "Suways",
                "countryCode": "SA"
            },
            {
                "name": "Ta'if",
                "countryCode": "SA"
            },
            {
                "name": "Tharwah",
                "countryCode": "SA"
            },
            {
                "name": "Thuwal",
                "countryCode": "SA"
            },
            {
                "name": "Usfan",
                "countryCode": "SA"
            },
            {
                "name": "Wadi al Jalil",
                "countryCode": "SA"
            },
            {
                "name": "Al Isawiyah",
                "countryCode": "SA"
            },
            {
                "name": "Al-Haditha",
                "countryCode": "SA"
            },
            {
                "name": "Halat Ammar",
                "countryCode": "SA"
            },
            {
                "name": "Qurayyat",
                "countryCode": "SA"
            },
            {
                "name": "Sakakah",
                "countryCode": "SA"
            },
            {
                "name": "Tabarjal",
                "countryCode": "SA"
            },
            {
                "name": "Şuwayr",
                "countryCode": "SA"
            },
            {
                "name": "Ţubarjal",
                "countryCode": "SA"
            },
            {
                "name": "Abha",
                "countryCode": "SA"
            },
            {
                "name": "Al Bahah",
                "countryCode": "SA"
            },
            {
                "name": "Al Majāridah",
                "countryCode": "SA"
            },
            {
                "name": "Al Mindak",
                "countryCode": "SA"
            },
            {
                "name": "Al Qahab",
                "countryCode": "SA"
            },
            {
                "name": "An Nimāş",
                "countryCode": "SA"
            },
            {
                "name": "Hajrah",
                "countryCode": "SA"
            },
            {
                "name": "Khamis Mushait",
                "countryCode": "SA"
            },
            {
                "name": "Ma`riyah",
                "countryCode": "SA"
            },
            {
                "name": "Mifa",
                "countryCode": "SA"
            },
            {
                "name": "Munayzir",
                "countryCode": "SA"
            },
            {
                "name": "Qal‘at Bīshah",
                "countryCode": "SA"
            },
            {
                "name": "Sabt Al Alayah",
                "countryCode": "SA"
            },
            {
                "name": "Tabālah",
                "countryCode": "SA"
            }
        ]
    },
    {
        "name": "Senegal",
        "phonecode": "221",
        "cities": [
            {
                "name": "Mbacké",
                "countryCode": "SN"
            },
            {
                "name": "Mbaké",
                "countryCode": "SN"
            },
            {
                "name": "Tiébo",
                "countryCode": "SN"
            },
            {
                "name": "Touba",
                "countryCode": "SN"
            },
            {
                "name": "Dakar",
                "countryCode": "SN"
            },
            {
                "name": "Dakar Department",
                "countryCode": "SN"
            },
            {
                "name": "Guédiawaye Department",
                "countryCode": "SN"
            },
            {
                "name": "Mermoz Boabab",
                "countryCode": "SN"
            },
            {
                "name": "N’diareme limamoulaye",
                "countryCode": "SN"
            },
            {
                "name": "Pikine",
                "countryCode": "SN"
            },
            {
                "name": "Pikine Department",
                "countryCode": "SN"
            },
            {
                "name": "Rufisque Department",
                "countryCode": "SN"
            },
            {
                "name": "Diofior",
                "countryCode": "SN"
            },
            {
                "name": "Fatick Department",
                "countryCode": "SN"
            },
            {
                "name": "Foundiougne",
                "countryCode": "SN"
            },
            {
                "name": "Guinguinéo",
                "countryCode": "SN"
            },
            {
                "name": "Passi",
                "countryCode": "SN"
            },
            {
                "name": "Pourham",
                "countryCode": "SN"
            },
            {
                "name": "Sokone",
                "countryCode": "SN"
            },
            {
                "name": "Kaffrine",
                "countryCode": "SN"
            },
            {
                "name": "Koungheul",
                "countryCode": "SN"
            },
            {
                "name": "Kolda",
                "countryCode": "SN"
            },
            {
                "name": "Kolda Department",
                "countryCode": "SN"
            },
            {
                "name": "Marsassoum",
                "countryCode": "SN"
            },
            {
                "name": "Vélingara",
                "countryCode": "SN"
            },
            {
                "name": "Département de Salémata",
                "countryCode": "SN"
            },
            {
                "name": "Kédougou",
                "countryCode": "SN"
            },
            {
                "name": "Kédougou Department",
                "countryCode": "SN"
            },
            {
                "name": "Saraya",
                "countryCode": "SN"
            },
            {
                "name": "Gandiaye",
                "countryCode": "SN"
            },
            {
                "name": "Kaolack",
                "countryCode": "SN"
            },
            {
                "name": "Ndofane",
                "countryCode": "SN"
            },
            {
                "name": "Nioro du Rip",
                "countryCode": "SN"
            },
            {
                "name": "Dara",
                "countryCode": "SN"
            },
            {
                "name": "Guéoul",
                "countryCode": "SN"
            },
            {
                "name": "Linguere Department",
                "countryCode": "SN"
            },
            {
                "name": "Louga",
                "countryCode": "SN"
            },
            {
                "name": "Ndibène Dahra",
                "countryCode": "SN"
            },
            {
                "name": "Diawara",
                "countryCode": "SN"
            },
            {
                "name": "Kanel",
                "countryCode": "SN"
            },
            {
                "name": "Matam",
                "countryCode": "SN"
            },
            {
                "name": "Matam Department",
                "countryCode": "SN"
            },
            {
                "name": "Ouro Sogui",
                "countryCode": "SN"
            },
            {
                "name": "Ranérou",
                "countryCode": "SN"
            },
            {
                "name": "Sémé",
                "countryCode": "SN"
            },
            {
                "name": "Waoundé",
                "countryCode": "SN"
            },
            {
                "name": "Goudomp Department",
                "countryCode": "SN"
            },
            {
                "name": "Sédhiou",
                "countryCode": "SN"
            },
            {
                "name": "Goléré",
                "countryCode": "SN"
            },
            {
                "name": "Ndioum",
                "countryCode": "SN"
            },
            {
                "name": "Polel Diaoubé",
                "countryCode": "SN"
            },
            {
                "name": "Richard-Toll",
                "countryCode": "SN"
            },
            {
                "name": "Rosso",
                "countryCode": "SN"
            },
            {
                "name": "Saint-Louis",
                "countryCode": "SN"
            },
            {
                "name": "Tambacounda",
                "countryCode": "SN"
            },
            {
                "name": "Tambacounda Department",
                "countryCode": "SN"
            },
            {
                "name": "Joal-Fadiout",
                "countryCode": "SN"
            },
            {
                "name": "Kayar",
                "countryCode": "SN"
            },
            {
                "name": "Khombole",
                "countryCode": "SN"
            },
            {
                "name": "Mbour",
                "countryCode": "SN"
            },
            {
                "name": "Mékhé",
                "countryCode": "SN"
            },
            {
                "name": "Nguékhokh",
                "countryCode": "SN"
            },
            {
                "name": "Pout",
                "countryCode": "SN"
            },
            {
                "name": "Thiès",
                "countryCode": "SN"
            },
            {
                "name": "Thiès Nones",
                "countryCode": "SN"
            },
            {
                "name": "Tiadiaye",
                "countryCode": "SN"
            },
            {
                "name": "Tivaouane",
                "countryCode": "SN"
            },
            {
                "name": "Warang",
                "countryCode": "SN"
            },
            {
                "name": "Adéane",
                "countryCode": "SN"
            },
            {
                "name": "Bignona",
                "countryCode": "SN"
            },
            {
                "name": "Oussouye",
                "countryCode": "SN"
            },
            {
                "name": "Tionk Essil",
                "countryCode": "SN"
            },
            {
                "name": "Ziguinchor",
                "countryCode": "SN"
            }
        ]
    },
    {
        "name": "Serbia",
        "phonecode": "381",
        "cities": [
            {
                "name": "Aranđelovac",
                "countryCode": "RS"
            },
            {
                "name": "Arilje",
                "countryCode": "RS"
            },
            {
                "name": "Badovinci",
                "countryCode": "RS"
            },
            {
                "name": "Bajina Bašta",
                "countryCode": "RS"
            },
            {
                "name": "Banovo Polje",
                "countryCode": "RS"
            },
            {
                "name": "Barič",
                "countryCode": "RS"
            },
            {
                "name": "Belgrade",
                "countryCode": "RS"
            },
            {
                "name": "Belotić",
                "countryCode": "RS"
            },
            {
                "name": "Bečmen",
                "countryCode": "RS"
            },
            {
                "name": "Biljača",
                "countryCode": "RS"
            },
            {
                "name": "Bogatić",
                "countryCode": "RS"
            },
            {
                "name": "Bogosavac",
                "countryCode": "RS"
            },
            {
                "name": "Boljevci",
                "countryCode": "RS"
            },
            {
                "name": "Bor",
                "countryCode": "RS"
            },
            {
                "name": "Brdarica",
                "countryCode": "RS"
            },
            {
                "name": "Bukor",
                "countryCode": "RS"
            },
            {
                "name": "Crna Bara",
                "countryCode": "RS"
            },
            {
                "name": "Dobanovci",
                "countryCode": "RS"
            },
            {
                "name": "Dobrić",
                "countryCode": "RS"
            },
            {
                "name": "Donji Dobrić",
                "countryCode": "RS"
            },
            {
                "name": "Donji Milanovac",
                "countryCode": "RS"
            },
            {
                "name": "Draginje",
                "countryCode": "RS"
            },
            {
                "name": "Drenovac",
                "countryCode": "RS"
            },
            {
                "name": "Dublje",
                "countryCode": "RS"
            },
            {
                "name": "Duboka",
                "countryCode": "RS"
            },
            {
                "name": "Glušci",
                "countryCode": "RS"
            },
            {
                "name": "Golubac",
                "countryCode": "RS"
            },
            {
                "name": "Gornja Bukovica",
                "countryCode": "RS"
            },
            {
                "name": "Gornji Milanovac",
                "countryCode": "RS"
            },
            {
                "name": "Grabovac",
                "countryCode": "RS"
            },
            {
                "name": "Jablanica",
                "countryCode": "RS"
            },
            {
                "name": "Jadranska Lešnica",
                "countryCode": "RS"
            },
            {
                "name": "Jagodina",
                "countryCode": "RS"
            },
            {
                "name": "Jarebice",
                "countryCode": "RS"
            },
            {
                "name": "Jelenča",
                "countryCode": "RS"
            },
            {
                "name": "Jevremovac",
                "countryCode": "RS"
            },
            {
                "name": "Joševa",
                "countryCode": "RS"
            },
            {
                "name": "Kamenica",
                "countryCode": "RS"
            },
            {
                "name": "Klenje",
                "countryCode": "RS"
            },
            {
                "name": "Knjazevac",
                "countryCode": "RS"
            },
            {
                "name": "Kolubara",
                "countryCode": "RS"
            },
            {
                "name": "Kozjak",
                "countryCode": "RS"
            },
            {
                "name": "Kragujevac",
                "countryCode": "RS"
            },
            {
                "name": "Kraljevo",
                "countryCode": "RS"
            },
            {
                "name": "Krivaja",
                "countryCode": "RS"
            },
            {
                "name": "Krupanj",
                "countryCode": "RS"
            },
            {
                "name": "Kruševac",
                "countryCode": "RS"
            },
            {
                "name": "Lagja e Poshtme",
                "countryCode": "RS"
            },
            {
                "name": "Lapovo",
                "countryCode": "RS"
            },
            {
                "name": "Lazarevac",
                "countryCode": "RS"
            },
            {
                "name": "Leskovac",
                "countryCode": "RS"
            },
            {
                "name": "Lešnica",
                "countryCode": "RS"
            },
            {
                "name": "Lipnički Šor",
                "countryCode": "RS"
            },
            {
                "name": "Lipolist",
                "countryCode": "RS"
            },
            {
                "name": "Ljig",
                "countryCode": "RS"
            },
            {
                "name": "Ljubovija",
                "countryCode": "RS"
            },
            {
                "name": "Lugavčina",
                "countryCode": "RS"
            },
            {
                "name": "Majdanpek",
                "countryCode": "RS"
            },
            {
                "name": "Majur",
                "countryCode": "RS"
            },
            {
                "name": "Mala Moštanica",
                "countryCode": "RS"
            },
            {
                "name": "Mali Zvornik",
                "countryCode": "RS"
            },
            {
                "name": "Mačva",
                "countryCode": "RS"
            },
            {
                "name": "Metković",
                "countryCode": "RS"
            },
            {
                "name": "Miratovac",
                "countryCode": "RS"
            },
            {
                "name": "Morava",
                "countryCode": "RS"
            },
            {
                "name": "Negotin",
                "countryCode": "RS"
            },
            {
                "name": "Niš",
                "countryCode": "RS"
            },
            {
                "name": "Nišava",
                "countryCode": "RS"
            },
            {
                "name": "Novi Pazar",
                "countryCode": "RS"
            },
            {
                "name": "Novo Selo",
                "countryCode": "RS"
            },
            {
                "name": "Obrenovac",
                "countryCode": "RS"
            },
            {
                "name": "Osečina",
                "countryCode": "RS"
            },
            {
                "name": "Osječenik",
                "countryCode": "RS"
            },
            {
                "name": "Ostružnica",
                "countryCode": "RS"
            },
            {
                "name": "Ovča",
                "countryCode": "RS"
            },
            {
                "name": "Paraćin",
                "countryCode": "RS"
            },
            {
                "name": "Petkovica",
                "countryCode": "RS"
            },
            {
                "name": "Pirot",
                "countryCode": "RS"
            },
            {
                "name": "Pocerski Pričinović",
                "countryCode": "RS"
            },
            {
                "name": "Podunavlje District",
                "countryCode": "RS"
            },
            {
                "name": "Pomoravlje",
                "countryCode": "RS"
            },
            {
                "name": "Požarevac",
                "countryCode": "RS"
            },
            {
                "name": "Priboj",
                "countryCode": "RS"
            },
            {
                "name": "Prijepolje",
                "countryCode": "RS"
            },
            {
                "name": "Prislonica",
                "countryCode": "RS"
            },
            {
                "name": "Prnjavor",
                "countryCode": "RS"
            },
            {
                "name": "Prokuplje",
                "countryCode": "RS"
            },
            {
                "name": "Pčinja",
                "countryCode": "RS"
            },
            {
                "name": "Radenka",
                "countryCode": "RS"
            },
            {
                "name": "Radovnica",
                "countryCode": "RS"
            },
            {
                "name": "Rajince",
                "countryCode": "RS"
            },
            {
                "name": "Rasina",
                "countryCode": "RS"
            },
            {
                "name": "Raška",
                "countryCode": "RS"
            },
            {
                "name": "Ribari",
                "countryCode": "RS"
            },
            {
                "name": "Rumska",
                "countryCode": "RS"
            },
            {
                "name": "Rušanj",
                "countryCode": "RS"
            },
            {
                "name": "Salaš Crnobarski",
                "countryCode": "RS"
            },
            {
                "name": "Samoljica",
                "countryCode": "RS"
            },
            {
                "name": "Sinošević",
                "countryCode": "RS"
            },
            {
                "name": "Sjenica",
                "countryCode": "RS"
            },
            {
                "name": "Smederevo",
                "countryCode": "RS"
            },
            {
                "name": "Smederevska Palanka",
                "countryCode": "RS"
            },
            {
                "name": "Sokolovica",
                "countryCode": "RS"
            },
            {
                "name": "Sokolovo Brdo",
                "countryCode": "RS"
            },
            {
                "name": "Sremčica",
                "countryCode": "RS"
            },
            {
                "name": "Stepojevac",
                "countryCode": "RS"
            },
            {
                "name": "Stubline",
                "countryCode": "RS"
            },
            {
                "name": "Sumulicë",
                "countryCode": "RS"
            },
            {
                "name": "Surčin",
                "countryCode": "RS"
            },
            {
                "name": "Tabanović",
                "countryCode": "RS"
            },
            {
                "name": "Toplica",
                "countryCode": "RS"
            },
            {
                "name": "Trstenik",
                "countryCode": "RS"
            },
            {
                "name": "Tršić",
                "countryCode": "RS"
            },
            {
                "name": "Turija",
                "countryCode": "RS"
            },
            {
                "name": "Tutin",
                "countryCode": "RS"
            },
            {
                "name": "Ugrinovci",
                "countryCode": "RS"
            },
            {
                "name": "Umka",
                "countryCode": "RS"
            },
            {
                "name": "Uzveće",
                "countryCode": "RS"
            },
            {
                "name": "Užice",
                "countryCode": "RS"
            },
            {
                "name": "Valjevo",
                "countryCode": "RS"
            },
            {
                "name": "Varna",
                "countryCode": "RS"
            },
            {
                "name": "Velika Moštanica",
                "countryCode": "RS"
            },
            {
                "name": "Vladimirci",
                "countryCode": "RS"
            },
            {
                "name": "Vranić",
                "countryCode": "RS"
            },
            {
                "name": "Vranje",
                "countryCode": "RS"
            },
            {
                "name": "Vrnjačka Banja",
                "countryCode": "RS"
            },
            {
                "name": "Zaječar",
                "countryCode": "RS"
            },
            {
                "name": "Zemun",
                "countryCode": "RS"
            },
            {
                "name": "Zlatibor",
                "countryCode": "RS"
            },
            {
                "name": "Zminjak",
                "countryCode": "RS"
            },
            {
                "name": "Zvečka",
                "countryCode": "RS"
            },
            {
                "name": "Ðurići",
                "countryCode": "RS"
            },
            {
                "name": "Ćićevac",
                "countryCode": "RS"
            },
            {
                "name": "Ćuprija",
                "countryCode": "RS"
            },
            {
                "name": "Čajetina",
                "countryCode": "RS"
            },
            {
                "name": "Čačak",
                "countryCode": "RS"
            },
            {
                "name": "Čokešina",
                "countryCode": "RS"
            },
            {
                "name": "Šabac",
                "countryCode": "RS"
            },
            {
                "name": "Ševarice",
                "countryCode": "RS"
            },
            {
                "name": "Ševica",
                "countryCode": "RS"
            },
            {
                "name": "Štitar",
                "countryCode": "RS"
            },
            {
                "name": "Šumadija",
                "countryCode": "RS"
            },
            {
                "name": "Žujince",
                "countryCode": "RS"
            },
            {
                "name": "Adorjan",
                "countryCode": "RS"
            },
            {
                "name": "Aleksandrovo",
                "countryCode": "RS"
            },
            {
                "name": "Alibunar",
                "countryCode": "RS"
            },
            {
                "name": "Apatin",
                "countryCode": "RS"
            },
            {
                "name": "Aradac",
                "countryCode": "RS"
            },
            {
                "name": "Banatska Topola",
                "countryCode": "RS"
            },
            {
                "name": "Banatski Despotovac",
                "countryCode": "RS"
            },
            {
                "name": "Banatski Dvor",
                "countryCode": "RS"
            },
            {
                "name": "Banatski Karlovac",
                "countryCode": "RS"
            },
            {
                "name": "Banatsko Karađorđevo",
                "countryCode": "RS"
            },
            {
                "name": "Banatsko Veliko Selo",
                "countryCode": "RS"
            },
            {
                "name": "Baranda",
                "countryCode": "RS"
            },
            {
                "name": "Barice",
                "countryCode": "RS"
            },
            {
                "name": "Bačka Palanka",
                "countryCode": "RS"
            },
            {
                "name": "Bačka Topola",
                "countryCode": "RS"
            },
            {
                "name": "Bački Breg",
                "countryCode": "RS"
            },
            {
                "name": "Bački Petrovac",
                "countryCode": "RS"
            },
            {
                "name": "Bačko Gradište",
                "countryCode": "RS"
            },
            {
                "name": "Bačko Petrovo Selo",
                "countryCode": "RS"
            },
            {
                "name": "Bašaid",
                "countryCode": "RS"
            },
            {
                "name": "Bela Crkva",
                "countryCode": "RS"
            },
            {
                "name": "Belegiš",
                "countryCode": "RS"
            },
            {
                "name": "Belo Blato",
                "countryCode": "RS"
            },
            {
                "name": "Bečej",
                "countryCode": "RS"
            },
            {
                "name": "Beška",
                "countryCode": "RS"
            },
            {
                "name": "Bogojevo",
                "countryCode": "RS"
            },
            {
                "name": "Boka",
                "countryCode": "RS"
            },
            {
                "name": "Bosut",
                "countryCode": "RS"
            },
            {
                "name": "Botoš",
                "countryCode": "RS"
            },
            {
                "name": "Bočar",
                "countryCode": "RS"
            },
            {
                "name": "Buđanovci",
                "countryCode": "RS"
            },
            {
                "name": "Crepaja",
                "countryCode": "RS"
            },
            {
                "name": "Debeljača",
                "countryCode": "RS"
            },
            {
                "name": "Despotovo",
                "countryCode": "RS"
            },
            {
                "name": "Dobrica",
                "countryCode": "RS"
            },
            {
                "name": "Doroslovo",
                "countryCode": "RS"
            },
            {
                "name": "Elemir",
                "countryCode": "RS"
            },
            {
                "name": "Ečka",
                "countryCode": "RS"
            },
            {
                "name": "Farkaždin",
                "countryCode": "RS"
            },
            {
                "name": "Gakovo",
                "countryCode": "RS"
            },
            {
                "name": "Gardinovci",
                "countryCode": "RS"
            },
            {
                "name": "Gložan",
                "countryCode": "RS"
            },
            {
                "name": "Golubinci",
                "countryCode": "RS"
            },
            {
                "name": "Gornji Breg",
                "countryCode": "RS"
            },
            {
                "name": "Grabovci",
                "countryCode": "RS"
            },
            {
                "name": "Gudurica",
                "countryCode": "RS"
            },
            {
                "name": "Hajdučica",
                "countryCode": "RS"
            },
            {
                "name": "Hetin",
                "countryCode": "RS"
            },
            {
                "name": "Hrtkovci",
                "countryCode": "RS"
            },
            {
                "name": "Idvor",
                "countryCode": "RS"
            },
            {
                "name": "Ilandža",
                "countryCode": "RS"
            },
            {
                "name": "Inđija",
                "countryCode": "RS"
            },
            {
                "name": "Irig",
                "countryCode": "RS"
            },
            {
                "name": "Izbište",
                "countryCode": "RS"
            },
            {
                "name": "Janošik",
                "countryCode": "RS"
            },
            {
                "name": "Jarak",
                "countryCode": "RS"
            },
            {
                "name": "Jarkovac",
                "countryCode": "RS"
            },
            {
                "name": "Jazovo",
                "countryCode": "RS"
            },
            {
                "name": "Jaša Tomić",
                "countryCode": "RS"
            },
            {
                "name": "Jermenovci",
                "countryCode": "RS"
            },
            {
                "name": "Kanjiža",
                "countryCode": "RS"
            },
            {
                "name": "Kikinda",
                "countryCode": "RS"
            },
            {
                "name": "Kisač",
                "countryCode": "RS"
            },
            {
                "name": "Klek",
                "countryCode": "RS"
            },
            {
                "name": "Klenak",
                "countryCode": "RS"
            },
            {
                "name": "Knićanin",
                "countryCode": "RS"
            },
            {
                "name": "Kolut",
                "countryCode": "RS"
            },
            {
                "name": "Konak",
                "countryCode": "RS"
            },
            {
                "name": "Kovačica",
                "countryCode": "RS"
            },
            {
                "name": "Kovilj",
                "countryCode": "RS"
            },
            {
                "name": "Kovin",
                "countryCode": "RS"
            },
            {
                "name": "Kozjak",
                "countryCode": "RS"
            },
            {
                "name": "Krajišnik",
                "countryCode": "RS"
            },
            {
                "name": "Krčedin",
                "countryCode": "RS"
            },
            {
                "name": "Kulpin",
                "countryCode": "RS"
            },
            {
                "name": "Kumane",
                "countryCode": "RS"
            },
            {
                "name": "Kupinovo",
                "countryCode": "RS"
            },
            {
                "name": "Kupusina",
                "countryCode": "RS"
            },
            {
                "name": "Kuštilj",
                "countryCode": "RS"
            },
            {
                "name": "Lazarevo",
                "countryCode": "RS"
            },
            {
                "name": "Ljukovo",
                "countryCode": "RS"
            },
            {
                "name": "Lok",
                "countryCode": "RS"
            },
            {
                "name": "Lokve",
                "countryCode": "RS"
            },
            {
                "name": "Lukićevo",
                "countryCode": "RS"
            },
            {
                "name": "Maglić",
                "countryCode": "RS"
            },
            {
                "name": "Margita",
                "countryCode": "RS"
            },
            {
                "name": "Mačvanska Mitrovica",
                "countryCode": "RS"
            },
            {
                "name": "Melenci",
                "countryCode": "RS"
            },
            {
                "name": "Međa",
                "countryCode": "RS"
            },
            {
                "name": "Mihajlovo",
                "countryCode": "RS"
            },
            {
                "name": "Mokrin",
                "countryCode": "RS"
            },
            {
                "name": "Mol",
                "countryCode": "RS"
            },
            {
                "name": "Mošorin",
                "countryCode": "RS"
            },
            {
                "name": "Nakovo",
                "countryCode": "RS"
            },
            {
                "name": "Neuzina",
                "countryCode": "RS"
            },
            {
                "name": "Nikinci",
                "countryCode": "RS"
            },
            {
                "name": "Nikolinci",
                "countryCode": "RS"
            },
            {
                "name": "Nova Crnja",
                "countryCode": "RS"
            },
            {
                "name": "Nova Pazova",
                "countryCode": "RS"
            },
            {
                "name": "Novi Banovci",
                "countryCode": "RS"
            },
            {
                "name": "Novi Itebej",
                "countryCode": "RS"
            },
            {
                "name": "Novi Karlovci",
                "countryCode": "RS"
            },
            {
                "name": "Novi Kneževac",
                "countryCode": "RS"
            },
            {
                "name": "Novi Kozarci",
                "countryCode": "RS"
            },
            {
                "name": "Novi Sad",
                "countryCode": "RS"
            },
            {
                "name": "Novi Slankamen",
                "countryCode": "RS"
            },
            {
                "name": "Novo Miloševo",
                "countryCode": "RS"
            },
            {
                "name": "Obrovac",
                "countryCode": "RS"
            },
            {
                "name": "Opovo",
                "countryCode": "RS"
            },
            {
                "name": "Orlovat",
                "countryCode": "RS"
            },
            {
                "name": "Ostojićevo",
                "countryCode": "RS"
            },
            {
                "name": "Padej",
                "countryCode": "RS"
            },
            {
                "name": "Padina",
                "countryCode": "RS"
            },
            {
                "name": "Pančevo",
                "countryCode": "RS"
            },
            {
                "name": "Pavliš",
                "countryCode": "RS"
            },
            {
                "name": "Perlez",
                "countryCode": "RS"
            },
            {
                "name": "Petrovaradin",
                "countryCode": "RS"
            },
            {
                "name": "Pećinci",
                "countryCode": "RS"
            },
            {
                "name": "Plandište",
                "countryCode": "RS"
            },
            {
                "name": "Platičevo",
                "countryCode": "RS"
            },
            {
                "name": "Prigrevica",
                "countryCode": "RS"
            },
            {
                "name": "Putinci",
                "countryCode": "RS"
            },
            {
                "name": "Radenković",
                "countryCode": "RS"
            },
            {
                "name": "Radojevo",
                "countryCode": "RS"
            },
            {
                "name": "Ravni Topolovac",
                "countryCode": "RS"
            },
            {
                "name": "Ravnje",
                "countryCode": "RS"
            },
            {
                "name": "Ravno Selo",
                "countryCode": "RS"
            },
            {
                "name": "Riđica",
                "countryCode": "RS"
            },
            {
                "name": "Ruma",
                "countryCode": "RS"
            },
            {
                "name": "Rumenka",
                "countryCode": "RS"
            },
            {
                "name": "Rusko Selo",
                "countryCode": "RS"
            },
            {
                "name": "Sajan",
                "countryCode": "RS"
            },
            {
                "name": "Sakule",
                "countryCode": "RS"
            },
            {
                "name": "Salaš Noćajski",
                "countryCode": "RS"
            },
            {
                "name": "Samoš",
                "countryCode": "RS"
            },
            {
                "name": "Sanad",
                "countryCode": "RS"
            },
            {
                "name": "Sefkerin",
                "countryCode": "RS"
            },
            {
                "name": "Seleuš",
                "countryCode": "RS"
            },
            {
                "name": "Senta",
                "countryCode": "RS"
            },
            {
                "name": "Sečanj",
                "countryCode": "RS"
            },
            {
                "name": "Sombor",
                "countryCode": "RS"
            },
            {
                "name": "Sonta",
                "countryCode": "RS"
            },
            {
                "name": "Sremska Mitrovica",
                "countryCode": "RS"
            },
            {
                "name": "Sremski Karlovci",
                "countryCode": "RS"
            },
            {
                "name": "Srpska Crnja",
                "countryCode": "RS"
            },
            {
                "name": "Srpski Itebej",
                "countryCode": "RS"
            },
            {
                "name": "Stajićevo",
                "countryCode": "RS"
            },
            {
                "name": "Stanišić",
                "countryCode": "RS"
            },
            {
                "name": "Stara Pazova",
                "countryCode": "RS"
            },
            {
                "name": "Stari Banovci",
                "countryCode": "RS"
            },
            {
                "name": "Stari Lec",
                "countryCode": "RS"
            },
            {
                "name": "Stepanovićevo",
                "countryCode": "RS"
            },
            {
                "name": "Subotica",
                "countryCode": "RS"
            },
            {
                "name": "Surduk",
                "countryCode": "RS"
            },
            {
                "name": "Sutjeska",
                "countryCode": "RS"
            },
            {
                "name": "Taraš",
                "countryCode": "RS"
            },
            {
                "name": "Tiszahegyes",
                "countryCode": "RS"
            },
            {
                "name": "Titel",
                "countryCode": "RS"
            },
            {
                "name": "Toba",
                "countryCode": "RS"
            },
            {
                "name": "Tomaševac",
                "countryCode": "RS"
            },
            {
                "name": "Torak",
                "countryCode": "RS"
            },
            {
                "name": "Torda",
                "countryCode": "RS"
            },
            {
                "name": "Uljma",
                "countryCode": "RS"
            },
            {
                "name": "Uzdin",
                "countryCode": "RS"
            },
            {
                "name": "Velika Greda",
                "countryCode": "RS"
            },
            {
                "name": "Veliki Gaj",
                "countryCode": "RS"
            },
            {
                "name": "Veliko Središte",
                "countryCode": "RS"
            },
            {
                "name": "Veternik",
                "countryCode": "RS"
            },
            {
                "name": "Vilovo",
                "countryCode": "RS"
            },
            {
                "name": "Višnjićevo",
                "countryCode": "RS"
            },
            {
                "name": "Vladimirovac",
                "countryCode": "RS"
            },
            {
                "name": "Vlajkovac",
                "countryCode": "RS"
            },
            {
                "name": "Vojka",
                "countryCode": "RS"
            },
            {
                "name": "Vojvoda Stepa",
                "countryCode": "RS"
            },
            {
                "name": "Vrbas",
                "countryCode": "RS"
            },
            {
                "name": "Vrdnik",
                "countryCode": "RS"
            },
            {
                "name": "Vršac",
                "countryCode": "RS"
            },
            {
                "name": "Zmajevo",
                "countryCode": "RS"
            },
            {
                "name": "Zrenjanin",
                "countryCode": "RS"
            },
            {
                "name": "Ðurđevo",
                "countryCode": "RS"
            },
            {
                "name": "Čelarevo",
                "countryCode": "RS"
            },
            {
                "name": "Čenta",
                "countryCode": "RS"
            },
            {
                "name": "Čestereg",
                "countryCode": "RS"
            },
            {
                "name": "Čoka",
                "countryCode": "RS"
            },
            {
                "name": "Čortanovci",
                "countryCode": "RS"
            },
            {
                "name": "Čurug",
                "countryCode": "RS"
            },
            {
                "name": "Šajkaš",
                "countryCode": "RS"
            },
            {
                "name": "Šašinci",
                "countryCode": "RS"
            },
            {
                "name": "Šimanovci",
                "countryCode": "RS"
            },
            {
                "name": "Žabalj",
                "countryCode": "RS"
            },
            {
                "name": "Žitište",
                "countryCode": "RS"
            }
        ]
    },
    {
        "name": "Seychelles",
        "phonecode": "248",
        "cities": [
            {
                "name": "Anse Boileau",
                "countryCode": "SC"
            },
            {
                "name": "Anse Royale",
                "countryCode": "SC"
            },
            {
                "name": "Beau Vallon",
                "countryCode": "SC"
            },
            {
                "name": "Bel Ombre",
                "countryCode": "SC"
            },
            {
                "name": "Cascade",
                "countryCode": "SC"
            },
            {
                "name": "Victoria",
                "countryCode": "SC"
            },
            {
                "name": "Port Glaud",
                "countryCode": "SC"
            },
            {
                "name": "Takamaka",
                "countryCode": "SC"
            }
        ]
    },
    {
        "name": "Sierra Leone",
        "phonecode": "232",
        "cities": [
            {
                "name": "Barma",
                "countryCode": "SL"
            },
            {
                "name": "Blama",
                "countryCode": "SL"
            },
            {
                "name": "Boajibu",
                "countryCode": "SL"
            },
            {
                "name": "Buedu",
                "countryCode": "SL"
            },
            {
                "name": "Bunumbu",
                "countryCode": "SL"
            },
            {
                "name": "Daru",
                "countryCode": "SL"
            },
            {
                "name": "Giehun",
                "countryCode": "SL"
            },
            {
                "name": "Gorahun",
                "countryCode": "SL"
            },
            {
                "name": "Hangha",
                "countryCode": "SL"
            },
            {
                "name": "Jojoima",
                "countryCode": "SL"
            },
            {
                "name": "Kailahun",
                "countryCode": "SL"
            },
            {
                "name": "Kailahun District",
                "countryCode": "SL"
            },
            {
                "name": "Kayima",
                "countryCode": "SL"
            },
            {
                "name": "Kenema",
                "countryCode": "SL"
            },
            {
                "name": "Kenema District",
                "countryCode": "SL"
            },
            {
                "name": "Koidu",
                "countryCode": "SL"
            },
            {
                "name": "Kono District",
                "countryCode": "SL"
            },
            {
                "name": "Koyima",
                "countryCode": "SL"
            },
            {
                "name": "Manowa",
                "countryCode": "SL"
            },
            {
                "name": "Mobai",
                "countryCode": "SL"
            },
            {
                "name": "Motema",
                "countryCode": "SL"
            },
            {
                "name": "Panguma",
                "countryCode": "SL"
            },
            {
                "name": "Pendembu",
                "countryCode": "SL"
            },
            {
                "name": "Segbwema",
                "countryCode": "SL"
            },
            {
                "name": "Simbakoro",
                "countryCode": "SL"
            },
            {
                "name": "Tefeya",
                "countryCode": "SL"
            },
            {
                "name": "Tombodu",
                "countryCode": "SL"
            },
            {
                "name": "Tombu",
                "countryCode": "SL"
            },
            {
                "name": "Wima",
                "countryCode": "SL"
            },
            {
                "name": "Yengema",
                "countryCode": "SL"
            },
            {
                "name": "Alikalia",
                "countryCode": "SL"
            },
            {
                "name": "Bindi",
                "countryCode": "SL"
            },
            {
                "name": "Binkolo",
                "countryCode": "SL"
            },
            {
                "name": "Bombali District",
                "countryCode": "SL"
            },
            {
                "name": "Bumbuna",
                "countryCode": "SL"
            },
            {
                "name": "Gberia Fotombu",
                "countryCode": "SL"
            },
            {
                "name": "Kabala",
                "countryCode": "SL"
            },
            {
                "name": "Kamakwie",
                "countryCode": "SL"
            },
            {
                "name": "Kambia",
                "countryCode": "SL"
            },
            {
                "name": "Kassiri",
                "countryCode": "SL"
            },
            {
                "name": "Koinadugu District",
                "countryCode": "SL"
            },
            {
                "name": "Konakridee",
                "countryCode": "SL"
            },
            {
                "name": "Kukuna",
                "countryCode": "SL"
            },
            {
                "name": "Loma",
                "countryCode": "SL"
            },
            {
                "name": "Lunsar",
                "countryCode": "SL"
            },
            {
                "name": "Magburaka",
                "countryCode": "SL"
            },
            {
                "name": "Makali",
                "countryCode": "SL"
            },
            {
                "name": "Makeni",
                "countryCode": "SL"
            },
            {
                "name": "Mambolo",
                "countryCode": "SL"
            },
            {
                "name": "Mange",
                "countryCode": "SL"
            },
            {
                "name": "Masaka",
                "countryCode": "SL"
            },
            {
                "name": "Masingbi",
                "countryCode": "SL"
            },
            {
                "name": "Masoyila",
                "countryCode": "SL"
            },
            {
                "name": "Pepel",
                "countryCode": "SL"
            },
            {
                "name": "Rokupr",
                "countryCode": "SL"
            },
            {
                "name": "Sawkta",
                "countryCode": "SL"
            },
            {
                "name": "Seidu",
                "countryCode": "SL"
            },
            {
                "name": "Tintafor",
                "countryCode": "SL"
            },
            {
                "name": "Tonkolili District",
                "countryCode": "SL"
            },
            {
                "name": "Yonibana",
                "countryCode": "SL"
            },
            {
                "name": "Baiima",
                "countryCode": "SL"
            },
            {
                "name": "Baoma",
                "countryCode": "SL"
            },
            {
                "name": "Bo",
                "countryCode": "SL"
            },
            {
                "name": "Bo District",
                "countryCode": "SL"
            },
            {
                "name": "Bomi",
                "countryCode": "SL"
            },
            {
                "name": "Bonthe",
                "countryCode": "SL"
            },
            {
                "name": "Bonthe District",
                "countryCode": "SL"
            },
            {
                "name": "Bumpe",
                "countryCode": "SL"
            },
            {
                "name": "Foindu",
                "countryCode": "SL"
            },
            {
                "name": "Gandorhun",
                "countryCode": "SL"
            },
            {
                "name": "Gbewebu",
                "countryCode": "SL"
            },
            {
                "name": "Koribundu",
                "countryCode": "SL"
            },
            {
                "name": "Largo",
                "countryCode": "SL"
            },
            {
                "name": "Mamboma",
                "countryCode": "SL"
            },
            {
                "name": "Mogbwemo",
                "countryCode": "SL"
            },
            {
                "name": "Moyamba",
                "countryCode": "SL"
            },
            {
                "name": "Moyamba District",
                "countryCode": "SL"
            },
            {
                "name": "Palima",
                "countryCode": "SL"
            },
            {
                "name": "Potoru",
                "countryCode": "SL"
            },
            {
                "name": "Pujehun",
                "countryCode": "SL"
            },
            {
                "name": "Pujehun District",
                "countryCode": "SL"
            },
            {
                "name": "Rotifunk",
                "countryCode": "SL"
            },
            {
                "name": "Serabu",
                "countryCode": "SL"
            },
            {
                "name": "Sumbuya",
                "countryCode": "SL"
            },
            {
                "name": "Tongole",
                "countryCode": "SL"
            },
            {
                "name": "Zimmi",
                "countryCode": "SL"
            },
            {
                "name": "Freetown",
                "countryCode": "SL"
            },
            {
                "name": "Hastings",
                "countryCode": "SL"
            },
            {
                "name": "Kent",
                "countryCode": "SL"
            },
            {
                "name": "Waterloo",
                "countryCode": "SL"
            }
        ]
    },
    {
        "name": "Singapore",
        "phonecode": "65",
        "cities": [
            {
                "name": "Singapore",
                "countryCode": "SG"
            },
            {
                "name": "Woodlands",
                "countryCode": "SG"
            }
        ]
    },
    {
        "name": "Slovakia",
        "phonecode": "421",
        "cities": [
            {
                "name": "Banská Bystrica",
                "countryCode": "SK"
            },
            {
                "name": "Banská Štiavnica",
                "countryCode": "SK"
            },
            {
                "name": "Brezno",
                "countryCode": "SK"
            },
            {
                "name": "Detva",
                "countryCode": "SK"
            },
            {
                "name": "Dudince",
                "countryCode": "SK"
            },
            {
                "name": "Fiľakovo",
                "countryCode": "SK"
            },
            {
                "name": "Hriňová",
                "countryCode": "SK"
            },
            {
                "name": "Hrochoť,Slovakia",
                "countryCode": "SK"
            },
            {
                "name": "Kováčová",
                "countryCode": "SK"
            },
            {
                "name": "Kremnica",
                "countryCode": "SK"
            },
            {
                "name": "Krupina",
                "countryCode": "SK"
            },
            {
                "name": "Lučenec",
                "countryCode": "SK"
            },
            {
                "name": "Nová Baňa",
                "countryCode": "SK"
            },
            {
                "name": "Okres Banská Bystrica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Banská Štiavnica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Brezno",
                "countryCode": "SK"
            },
            {
                "name": "Okres Detva",
                "countryCode": "SK"
            },
            {
                "name": "Okres Krupina",
                "countryCode": "SK"
            },
            {
                "name": "Okres Lučenec",
                "countryCode": "SK"
            },
            {
                "name": "Okres Poltár",
                "countryCode": "SK"
            },
            {
                "name": "Okres Revúca",
                "countryCode": "SK"
            },
            {
                "name": "Okres Veľký Krtíš",
                "countryCode": "SK"
            },
            {
                "name": "Okres Zvolen",
                "countryCode": "SK"
            },
            {
                "name": "Okres Žarnovica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Žiar nad Hronom",
                "countryCode": "SK"
            },
            {
                "name": "Poltár",
                "countryCode": "SK"
            },
            {
                "name": "Revúca",
                "countryCode": "SK"
            },
            {
                "name": "Rimavská Sobota",
                "countryCode": "SK"
            },
            {
                "name": "Svätý Anton",
                "countryCode": "SK"
            },
            {
                "name": "Tisovec",
                "countryCode": "SK"
            },
            {
                "name": "Veľký Krtíš",
                "countryCode": "SK"
            },
            {
                "name": "Zvolen",
                "countryCode": "SK"
            },
            {
                "name": "Čierny Balog",
                "countryCode": "SK"
            },
            {
                "name": "Žarnovica",
                "countryCode": "SK"
            },
            {
                "name": "Žiar nad Hronom",
                "countryCode": "SK"
            },
            {
                "name": "Bratislava",
                "countryCode": "SK"
            },
            {
                "name": "Bratislava - Vajnory",
                "countryCode": "SK"
            },
            {
                "name": "Dunajská Lužná",
                "countryCode": "SK"
            },
            {
                "name": "Ivanka pri Dunaji",
                "countryCode": "SK"
            },
            {
                "name": "Malacky",
                "countryCode": "SK"
            },
            {
                "name": "Marianka",
                "countryCode": "SK"
            },
            {
                "name": "Modra",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bratislava I",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bratislava II",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bratislava III",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bratislava IV",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bratislava V",
                "countryCode": "SK"
            },
            {
                "name": "Okres Malacky",
                "countryCode": "SK"
            },
            {
                "name": "Okres Pezinok",
                "countryCode": "SK"
            },
            {
                "name": "Okres Senec",
                "countryCode": "SK"
            },
            {
                "name": "Pezinok",
                "countryCode": "SK"
            },
            {
                "name": "Senec",
                "countryCode": "SK"
            },
            {
                "name": "Stupava",
                "countryCode": "SK"
            },
            {
                "name": "Svätý Jur",
                "countryCode": "SK"
            },
            {
                "name": "Vinosady",
                "countryCode": "SK"
            },
            {
                "name": "Dobšiná",
                "countryCode": "SK"
            },
            {
                "name": "Gelnica",
                "countryCode": "SK"
            },
            {
                "name": "Kavečany",
                "countryCode": "SK"
            },
            {
                "name": "Košice",
                "countryCode": "SK"
            },
            {
                "name": "Košice I",
                "countryCode": "SK"
            },
            {
                "name": "Košice II",
                "countryCode": "SK"
            },
            {
                "name": "Košice III",
                "countryCode": "SK"
            },
            {
                "name": "Košice IV",
                "countryCode": "SK"
            },
            {
                "name": "Krompachy",
                "countryCode": "SK"
            },
            {
                "name": "Medzev",
                "countryCode": "SK"
            },
            {
                "name": "Michalovce",
                "countryCode": "SK"
            },
            {
                "name": "Moldava nad Bodvou",
                "countryCode": "SK"
            },
            {
                "name": "Okres Gelnica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Kosice-okolie",
                "countryCode": "SK"
            },
            {
                "name": "Okres Michalovce",
                "countryCode": "SK"
            },
            {
                "name": "Okres Rožňava",
                "countryCode": "SK"
            },
            {
                "name": "Okres Sobrance",
                "countryCode": "SK"
            },
            {
                "name": "Okres Spišská Nová Ves",
                "countryCode": "SK"
            },
            {
                "name": "Okres Trebišov",
                "countryCode": "SK"
            },
            {
                "name": "Rožňava",
                "countryCode": "SK"
            },
            {
                "name": "Sečovce",
                "countryCode": "SK"
            },
            {
                "name": "Sobrance",
                "countryCode": "SK"
            },
            {
                "name": "Spišská Nová Ves",
                "countryCode": "SK"
            },
            {
                "name": "Strážske",
                "countryCode": "SK"
            },
            {
                "name": "Trebišov",
                "countryCode": "SK"
            },
            {
                "name": "Vinné",
                "countryCode": "SK"
            },
            {
                "name": "Čierna nad Tisou",
                "countryCode": "SK"
            },
            {
                "name": "Žehra",
                "countryCode": "SK"
            },
            {
                "name": "Hurbanovo",
                "countryCode": "SK"
            },
            {
                "name": "Kolárovo",
                "countryCode": "SK"
            },
            {
                "name": "Komárno",
                "countryCode": "SK"
            },
            {
                "name": "Levice",
                "countryCode": "SK"
            },
            {
                "name": "Nitra",
                "countryCode": "SK"
            },
            {
                "name": "Nové Zámky",
                "countryCode": "SK"
            },
            {
                "name": "Okres Komárno",
                "countryCode": "SK"
            },
            {
                "name": "Okres Levice",
                "countryCode": "SK"
            },
            {
                "name": "Okres Nitra",
                "countryCode": "SK"
            },
            {
                "name": "Okres Nové Zámky",
                "countryCode": "SK"
            },
            {
                "name": "Okres Topoľčany",
                "countryCode": "SK"
            },
            {
                "name": "Okres Zlaté Moravce",
                "countryCode": "SK"
            },
            {
                "name": "Okres Šaľa",
                "countryCode": "SK"
            },
            {
                "name": "Svodín",
                "countryCode": "SK"
            },
            {
                "name": "Tlmače",
                "countryCode": "SK"
            },
            {
                "name": "Topoľčany",
                "countryCode": "SK"
            },
            {
                "name": "Vráble",
                "countryCode": "SK"
            },
            {
                "name": "Zlaté Moravce",
                "countryCode": "SK"
            },
            {
                "name": "Šahy",
                "countryCode": "SK"
            },
            {
                "name": "Šaľa",
                "countryCode": "SK"
            },
            {
                "name": "Štúrovo",
                "countryCode": "SK"
            },
            {
                "name": "Šurany",
                "countryCode": "SK"
            },
            {
                "name": "Želiezovce",
                "countryCode": "SK"
            },
            {
                "name": "Bardejov",
                "countryCode": "SK"
            },
            {
                "name": "Chlmec",
                "countryCode": "SK"
            },
            {
                "name": "Giraltovce",
                "countryCode": "SK"
            },
            {
                "name": "Humenné",
                "countryCode": "SK"
            },
            {
                "name": "Kežmarok",
                "countryCode": "SK"
            },
            {
                "name": "Levoča",
                "countryCode": "SK"
            },
            {
                "name": "Lipany",
                "countryCode": "SK"
            },
            {
                "name": "Medzilaborce",
                "countryCode": "SK"
            },
            {
                "name": "Nová Lesná",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bardejov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Humenné",
                "countryCode": "SK"
            },
            {
                "name": "Okres Kežmarok",
                "countryCode": "SK"
            },
            {
                "name": "Okres Levoča",
                "countryCode": "SK"
            },
            {
                "name": "Okres Medzilaborce",
                "countryCode": "SK"
            },
            {
                "name": "Okres Poprad",
                "countryCode": "SK"
            },
            {
                "name": "Okres Prešov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Sabinov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Snina",
                "countryCode": "SK"
            },
            {
                "name": "Okres Stará Ĺubovňa",
                "countryCode": "SK"
            },
            {
                "name": "Okres Stropkov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Svidník",
                "countryCode": "SK"
            },
            {
                "name": "Okres Vranov nad Topľou",
                "countryCode": "SK"
            },
            {
                "name": "Podolínec",
                "countryCode": "SK"
            },
            {
                "name": "Poprad",
                "countryCode": "SK"
            },
            {
                "name": "Prešov",
                "countryCode": "SK"
            },
            {
                "name": "Sabinov",
                "countryCode": "SK"
            },
            {
                "name": "Snina",
                "countryCode": "SK"
            },
            {
                "name": "Spišská Belá",
                "countryCode": "SK"
            },
            {
                "name": "Spišské Podhradie",
                "countryCode": "SK"
            },
            {
                "name": "Stará Ľubovňa",
                "countryCode": "SK"
            },
            {
                "name": "Stropkov",
                "countryCode": "SK"
            },
            {
                "name": "Svidník",
                "countryCode": "SK"
            },
            {
                "name": "Svit",
                "countryCode": "SK"
            },
            {
                "name": "Vranov nad Topľou",
                "countryCode": "SK"
            },
            {
                "name": "Vrbov",
                "countryCode": "SK"
            },
            {
                "name": "Vysoké Tatry",
                "countryCode": "SK"
            },
            {
                "name": "Vyšné Ružbachy",
                "countryCode": "SK"
            },
            {
                "name": "Ľubica",
                "countryCode": "SK"
            },
            {
                "name": "Štrba",
                "countryCode": "SK"
            },
            {
                "name": "Ždiar",
                "countryCode": "SK"
            },
            {
                "name": "Dunajská Streda",
                "countryCode": "SK"
            },
            {
                "name": "Gabčíkovo",
                "countryCode": "SK"
            },
            {
                "name": "Galanta",
                "countryCode": "SK"
            },
            {
                "name": "Gbely",
                "countryCode": "SK"
            },
            {
                "name": "Hlohovec",
                "countryCode": "SK"
            },
            {
                "name": "Holíč",
                "countryCode": "SK"
            },
            {
                "name": "Leopoldov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Dunajská Streda",
                "countryCode": "SK"
            },
            {
                "name": "Okres Galanta",
                "countryCode": "SK"
            },
            {
                "name": "Okres Hlohovec",
                "countryCode": "SK"
            },
            {
                "name": "Okres Piešťany",
                "countryCode": "SK"
            },
            {
                "name": "Okres Senica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Skalica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Trnava",
                "countryCode": "SK"
            },
            {
                "name": "Piešťany",
                "countryCode": "SK"
            },
            {
                "name": "Senica",
                "countryCode": "SK"
            },
            {
                "name": "Skalica",
                "countryCode": "SK"
            },
            {
                "name": "Sládkovičovo",
                "countryCode": "SK"
            },
            {
                "name": "Smolenice",
                "countryCode": "SK"
            },
            {
                "name": "Trnava",
                "countryCode": "SK"
            },
            {
                "name": "Veľký Meder",
                "countryCode": "SK"
            },
            {
                "name": "Vrbové",
                "countryCode": "SK"
            },
            {
                "name": "Šamorín",
                "countryCode": "SK"
            },
            {
                "name": "Bojnice",
                "countryCode": "SK"
            },
            {
                "name": "Brezová pod Bradlom",
                "countryCode": "SK"
            },
            {
                "name": "Bánovce nad Bebravou",
                "countryCode": "SK"
            },
            {
                "name": "Dubnica nad Váhom",
                "countryCode": "SK"
            },
            {
                "name": "Handlová",
                "countryCode": "SK"
            },
            {
                "name": "Ilava",
                "countryCode": "SK"
            },
            {
                "name": "Lehota pod Vtáčnikom",
                "countryCode": "SK"
            },
            {
                "name": "Myjava",
                "countryCode": "SK"
            },
            {
                "name": "Nemšová",
                "countryCode": "SK"
            },
            {
                "name": "Nová Dubnica",
                "countryCode": "SK"
            },
            {
                "name": "Nováky",
                "countryCode": "SK"
            },
            {
                "name": "Nové Mesto nad Váhom",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bánovce nad Bebravou",
                "countryCode": "SK"
            },
            {
                "name": "Okres Ilava",
                "countryCode": "SK"
            },
            {
                "name": "Okres Myjava",
                "countryCode": "SK"
            },
            {
                "name": "Okres Nové Mesto nad Váhom",
                "countryCode": "SK"
            },
            {
                "name": "Okres Partizánske",
                "countryCode": "SK"
            },
            {
                "name": "Okres Považská Bystrica",
                "countryCode": "SK"
            },
            {
                "name": "Okres Prievidza",
                "countryCode": "SK"
            },
            {
                "name": "Okres Púchov",
                "countryCode": "SK"
            },
            {
                "name": "Okres Trenčín",
                "countryCode": "SK"
            },
            {
                "name": "Partizánske",
                "countryCode": "SK"
            },
            {
                "name": "Považská Bystrica",
                "countryCode": "SK"
            },
            {
                "name": "Prievidza",
                "countryCode": "SK"
            },
            {
                "name": "Púchov",
                "countryCode": "SK"
            },
            {
                "name": "Stará Turá",
                "countryCode": "SK"
            },
            {
                "name": "Trenčianske Teplice",
                "countryCode": "SK"
            },
            {
                "name": "Trenčín",
                "countryCode": "SK"
            },
            {
                "name": "Čachtice",
                "countryCode": "SK"
            },
            {
                "name": "Bytča",
                "countryCode": "SK"
            },
            {
                "name": "Dolný Kubín",
                "countryCode": "SK"
            },
            {
                "name": "Hybe",
                "countryCode": "SK"
            },
            {
                "name": "Krasňany",
                "countryCode": "SK"
            },
            {
                "name": "Kysucké Nové Mesto",
                "countryCode": "SK"
            },
            {
                "name": "Liptovský Hrádok",
                "countryCode": "SK"
            },
            {
                "name": "Liptovský Mikuláš",
                "countryCode": "SK"
            },
            {
                "name": "Lúčky",
                "countryCode": "SK"
            },
            {
                "name": "Martin",
                "countryCode": "SK"
            },
            {
                "name": "Nižná",
                "countryCode": "SK"
            },
            {
                "name": "Námestovo",
                "countryCode": "SK"
            },
            {
                "name": "Okres Bytča",
                "countryCode": "SK"
            },
            {
                "name": "Okres Dolný Kubín",
                "countryCode": "SK"
            },
            {
                "name": "Okres Kysucké Nové Mesto",
                "countryCode": "SK"
            },
            {
                "name": "Okres Liptovský Mikuláš",
                "countryCode": "SK"
            },
            {
                "name": "Okres Martin",
                "countryCode": "SK"
            },
            {
                "name": "Okres Namestovo",
                "countryCode": "SK"
            },
            {
                "name": "Okres Ružomberok",
                "countryCode": "SK"
            },
            {
                "name": "Okres Turčianske Teplice",
                "countryCode": "SK"
            },
            {
                "name": "Okres Tvrdošín",
                "countryCode": "SK"
            },
            {
                "name": "Okres Čadca",
                "countryCode": "SK"
            },
            {
                "name": "Okres Žilina",
                "countryCode": "SK"
            },
            {
                "name": "Oravská Lesná",
                "countryCode": "SK"
            },
            {
                "name": "Oravský Podzámok",
                "countryCode": "SK"
            },
            {
                "name": "Pribylina",
                "countryCode": "SK"
            },
            {
                "name": "Rajec",
                "countryCode": "SK"
            },
            {
                "name": "Ružomberok",
                "countryCode": "SK"
            },
            {
                "name": "Terchová",
                "countryCode": "SK"
            },
            {
                "name": "Trstená",
                "countryCode": "SK"
            },
            {
                "name": "Turzovka",
                "countryCode": "SK"
            },
            {
                "name": "Turčianske Teplice",
                "countryCode": "SK"
            },
            {
                "name": "Tvrdošín",
                "countryCode": "SK"
            },
            {
                "name": "Vrútky",
                "countryCode": "SK"
            },
            {
                "name": "Čadca",
                "countryCode": "SK"
            },
            {
                "name": "Žilina",
                "countryCode": "SK"
            }
        ]
    },
    {
        "name": "Slovenia",
        "phonecode": "386",
        "cities": [
            {
                "name": "Ajdovščina",
                "countryCode": "SI"
            },
            {
                "name": "Cirkulane",
                "countryCode": "SI"
            },
            {
                "name": "Lokavec",
                "countryCode": "SI"
            },
            {
                "name": "Beltinci",
                "countryCode": "SI"
            },
            {
                "name": "Gančani",
                "countryCode": "SI"
            },
            {
                "name": "Lipovci",
                "countryCode": "SI"
            },
            {
                "name": "Zgornje Gorje",
                "countryCode": "SI"
            },
            {
                "name": "Bled",
                "countryCode": "SI"
            },
            {
                "name": "Kostanjevica na Krki",
                "countryCode": "SI"
            },
            {
                "name": "Zasip",
                "countryCode": "SI"
            },
            {
                "name": "Bohinjska Bistrica",
                "countryCode": "SI"
            },
            {
                "name": "Dragomer",
                "countryCode": "SI"
            },
            {
                "name": "Log pri Brezovici",
                "countryCode": "SI"
            },
            {
                "name": "Borovnica",
                "countryCode": "SI"
            },
            {
                "name": "Makole",
                "countryCode": "SI"
            },
            {
                "name": "Bovec",
                "countryCode": "SI"
            },
            {
                "name": "Mirna",
                "countryCode": "SI"
            },
            {
                "name": "Dobrovo",
                "countryCode": "SI"
            },
            {
                "name": "Mokronog",
                "countryCode": "SI"
            },
            {
                "name": "Brezovica pri Ljubljani",
                "countryCode": "SI"
            },
            {
                "name": "Notranje Gorice",
                "countryCode": "SI"
            },
            {
                "name": "Opština Ljubljana-Vič-Rudnik",
                "countryCode": "SI"
            },
            {
                "name": "Rečica ob Savinji",
                "countryCode": "SI"
            },
            {
                "name": "Vnanje Gorice",
                "countryCode": "SI"
            },
            {
                "name": "Brežice",
                "countryCode": "SI"
            },
            {
                "name": "Poljčane",
                "countryCode": "SI"
            },
            {
                "name": "Tišina",
                "countryCode": "SI"
            },
            {
                "name": "Celje",
                "countryCode": "SI"
            },
            {
                "name": "Ljubečna",
                "countryCode": "SI"
            },
            {
                "name": "Trnovlje pri Celju",
                "countryCode": "SI"
            },
            {
                "name": "Cerklje na Gorenjskem",
                "countryCode": "SI"
            },
            {
                "name": "Cerknica",
                "countryCode": "SI"
            },
            {
                "name": "Rakek",
                "countryCode": "SI"
            },
            {
                "name": "Cerkno",
                "countryCode": "SI"
            },
            {
                "name": "Črenšovci",
                "countryCode": "SI"
            },
            {
                "name": "Črna na Koroškem",
                "countryCode": "SI"
            },
            {
                "name": "Črnomelj",
                "countryCode": "SI"
            },
            {
                "name": "Destrnik",
                "countryCode": "SI"
            },
            {
                "name": "Divača",
                "countryCode": "SI"
            },
            {
                "name": "Videm",
                "countryCode": "SI"
            },
            {
                "name": "Dobrova",
                "countryCode": "SI"
            },
            {
                "name": "Dol pri Ljubljani",
                "countryCode": "SI"
            },
            {
                "name": "Dob",
                "countryCode": "SI"
            },
            {
                "name": "Domžale",
                "countryCode": "SI"
            },
            {
                "name": "Radomlje",
                "countryCode": "SI"
            },
            {
                "name": "Vir",
                "countryCode": "SI"
            },
            {
                "name": "Dornava",
                "countryCode": "SI"
            },
            {
                "name": "Dravograd",
                "countryCode": "SI"
            },
            {
                "name": "Spodnji Duplek",
                "countryCode": "SI"
            },
            {
                "name": "Zgornji Duplek",
                "countryCode": "SI"
            },
            {
                "name": "Gorenja Vas",
                "countryCode": "SI"
            },
            {
                "name": "Gorišnica",
                "countryCode": "SI"
            },
            {
                "name": "Gornja Radgona",
                "countryCode": "SI"
            },
            {
                "name": "Gornji Grad",
                "countryCode": "SI"
            },
            {
                "name": "Gornji Petrovci",
                "countryCode": "SI"
            },
            {
                "name": "Grosuplje",
                "countryCode": "SI"
            },
            {
                "name": "Šmarje-Sap",
                "countryCode": "SI"
            },
            {
                "name": "Šalovci",
                "countryCode": "SI"
            },
            {
                "name": "Dol pri Hrastniku",
                "countryCode": "SI"
            },
            {
                "name": "Hrastnik",
                "countryCode": "SI"
            },
            {
                "name": "Kozina",
                "countryCode": "SI"
            },
            {
                "name": "Idrija",
                "countryCode": "SI"
            },
            {
                "name": "Spodnja Idrija",
                "countryCode": "SI"
            },
            {
                "name": "Ig",
                "countryCode": "SI"
            },
            {
                "name": "Ilirska Bistrica",
                "countryCode": "SI"
            },
            {
                "name": "Ivančna Gorica",
                "countryCode": "SI"
            },
            {
                "name": "Šentvid pri Stični",
                "countryCode": "SI"
            },
            {
                "name": "Izola",
                "countryCode": "SI"
            },
            {
                "name": "Jagodje",
                "countryCode": "SI"
            },
            {
                "name": "Hrušica",
                "countryCode": "SI"
            },
            {
                "name": "Jesenice",
                "countryCode": "SI"
            },
            {
                "name": "Koroška Bela",
                "countryCode": "SI"
            },
            {
                "name": "Slovenski Javornik",
                "countryCode": "SI"
            },
            {
                "name": "Juršinci",
                "countryCode": "SI"
            },
            {
                "name": "Kamnik",
                "countryCode": "SI"
            },
            {
                "name": "Mekinje",
                "countryCode": "SI"
            },
            {
                "name": "Šmarca",
                "countryCode": "SI"
            },
            {
                "name": "Deskle",
                "countryCode": "SI"
            },
            {
                "name": "Kanal",
                "countryCode": "SI"
            },
            {
                "name": "Kidričevo",
                "countryCode": "SI"
            },
            {
                "name": "Kobarid",
                "countryCode": "SI"
            },
            {
                "name": "Kobilje",
                "countryCode": "SI"
            },
            {
                "name": "Kočevje",
                "countryCode": "SI"
            },
            {
                "name": "Komen",
                "countryCode": "SI"
            },
            {
                "name": "Dekani",
                "countryCode": "SI"
            },
            {
                "name": "Hrvatini",
                "countryCode": "SI"
            },
            {
                "name": "Koper",
                "countryCode": "SI"
            },
            {
                "name": "Pobegi",
                "countryCode": "SI"
            },
            {
                "name": "Prade",
                "countryCode": "SI"
            },
            {
                "name": "Spodnje Škofije",
                "countryCode": "SI"
            },
            {
                "name": "Sv. Anton",
                "countryCode": "SI"
            },
            {
                "name": "Kozje",
                "countryCode": "SI"
            },
            {
                "name": "Britof",
                "countryCode": "SI"
            },
            {
                "name": "Golnik",
                "countryCode": "SI"
            },
            {
                "name": "Kokrica",
                "countryCode": "SI"
            },
            {
                "name": "Kranj",
                "countryCode": "SI"
            },
            {
                "name": "Mlaka pri Kranju",
                "countryCode": "SI"
            },
            {
                "name": "Zgornje Bitnje",
                "countryCode": "SI"
            },
            {
                "name": "Kranjska Gora",
                "countryCode": "SI"
            },
            {
                "name": "Mojstrana",
                "countryCode": "SI"
            },
            {
                "name": "Krško",
                "countryCode": "SI"
            },
            {
                "name": "Leskovec pri Krškem",
                "countryCode": "SI"
            },
            {
                "name": "Senovo",
                "countryCode": "SI"
            },
            {
                "name": "Zgornja Kungota",
                "countryCode": "SI"
            },
            {
                "name": "Kuzma",
                "countryCode": "SI"
            },
            {
                "name": "Laško",
                "countryCode": "SI"
            },
            {
                "name": "Lenart v Slov. Goricah",
                "countryCode": "SI"
            },
            {
                "name": "Lendava",
                "countryCode": "SI"
            },
            {
                "name": "Litija",
                "countryCode": "SI"
            },
            {
                "name": "Dravlje District",
                "countryCode": "SI"
            },
            {
                "name": "Jarše District",
                "countryCode": "SI"
            },
            {
                "name": "Ljubljana",
                "countryCode": "SI"
            },
            {
                "name": "Opčina Ljubljana-Bežigrad",
                "countryCode": "SI"
            },
            {
                "name": "Opština Ljubljana-Center",
                "countryCode": "SI"
            },
            {
                "name": "Opština Ljubljana-Moste-Polje",
                "countryCode": "SI"
            },
            {
                "name": "Rožnik District",
                "countryCode": "SI"
            },
            {
                "name": "Sostro District",
                "countryCode": "SI"
            },
            {
                "name": "Trnovo District",
                "countryCode": "SI"
            },
            {
                "name": "Vič District",
                "countryCode": "SI"
            },
            {
                "name": "Šentvid District",
                "countryCode": "SI"
            },
            {
                "name": "Ljubno ob Savinji",
                "countryCode": "SI"
            },
            {
                "name": "Ljutomer",
                "countryCode": "SI"
            },
            {
                "name": "Logatec",
                "countryCode": "SI"
            },
            {
                "name": "Leskova Dolina",
                "countryCode": "SI"
            },
            {
                "name": "Hrib-Loški Potok",
                "countryCode": "SI"
            },
            {
                "name": "Luče",
                "countryCode": "SI"
            },
            {
                "name": "Lukovica pri Domžalah",
                "countryCode": "SI"
            },
            {
                "name": "Majšperk",
                "countryCode": "SI"
            },
            {
                "name": "Bresternica",
                "countryCode": "SI"
            },
            {
                "name": "Kamnica",
                "countryCode": "SI"
            },
            {
                "name": "Limbuš",
                "countryCode": "SI"
            },
            {
                "name": "Maribor",
                "countryCode": "SI"
            },
            {
                "name": "Pekre",
                "countryCode": "SI"
            },
            {
                "name": "Razvanje",
                "countryCode": "SI"
            },
            {
                "name": "Medvode",
                "countryCode": "SI"
            },
            {
                "name": "Opština [historical] Ljubljana-Šiška",
                "countryCode": "SI"
            },
            {
                "name": "Zgornje Pirniče",
                "countryCode": "SI"
            },
            {
                "name": "Mengeš",
                "countryCode": "SI"
            },
            {
                "name": "Preserje pri Radomljah",
                "countryCode": "SI"
            },
            {
                "name": "Metlika",
                "countryCode": "SI"
            },
            {
                "name": "Mežica",
                "countryCode": "SI"
            },
            {
                "name": "Bilje",
                "countryCode": "SI"
            },
            {
                "name": "Miren",
                "countryCode": "SI"
            },
            {
                "name": "Mislinja",
                "countryCode": "SI"
            },
            {
                "name": "Moravče",
                "countryCode": "SI"
            },
            {
                "name": "Moravske Toplice",
                "countryCode": "SI"
            },
            {
                "name": "Mozirje",
                "countryCode": "SI"
            },
            {
                "name": "Bakovci",
                "countryCode": "SI"
            },
            {
                "name": "Krog",
                "countryCode": "SI"
            },
            {
                "name": "Murska Sobota",
                "countryCode": "SI"
            },
            {
                "name": "Rakičan",
                "countryCode": "SI"
            },
            {
                "name": "Černelavci",
                "countryCode": "SI"
            },
            {
                "name": "Muta",
                "countryCode": "SI"
            },
            {
                "name": "Naklo",
                "countryCode": "SI"
            },
            {
                "name": "Nazarje",
                "countryCode": "SI"
            },
            {
                "name": "Kromberk",
                "countryCode": "SI"
            },
            {
                "name": "Nova Gorica",
                "countryCode": "SI"
            },
            {
                "name": "Prvačina",
                "countryCode": "SI"
            },
            {
                "name": "Solkan",
                "countryCode": "SI"
            },
            {
                "name": "Šempas",
                "countryCode": "SI"
            },
            {
                "name": "Novo Mesto",
                "countryCode": "SI"
            },
            {
                "name": "Odranci",
                "countryCode": "SI"
            },
            {
                "name": "Ormož",
                "countryCode": "SI"
            },
            {
                "name": "Osilnica",
                "countryCode": "SI"
            },
            {
                "name": "Pesnica pri Mariboru",
                "countryCode": "SI"
            },
            {
                "name": "Lucija",
                "countryCode": "SI"
            },
            {
                "name": "Piran",
                "countryCode": "SI"
            },
            {
                "name": "Portorož",
                "countryCode": "SI"
            },
            {
                "name": "Seča",
                "countryCode": "SI"
            },
            {
                "name": "Pivka",
                "countryCode": "SI"
            },
            {
                "name": "Podčetrtek",
                "countryCode": "SI"
            },
            {
                "name": "Podvelka",
                "countryCode": "SI"
            },
            {
                "name": "Postojna",
                "countryCode": "SI"
            },
            {
                "name": "Preddvor",
                "countryCode": "SI"
            },
            {
                "name": "Ptuj",
                "countryCode": "SI"
            },
            {
                "name": "Puconci",
                "countryCode": "SI"
            },
            {
                "name": "Fram",
                "countryCode": "SI"
            },
            {
                "name": "Morje",
                "countryCode": "SI"
            },
            {
                "name": "Rače",
                "countryCode": "SI"
            },
            {
                "name": "Radeče",
                "countryCode": "SI"
            },
            {
                "name": "Radenci",
                "countryCode": "SI"
            },
            {
                "name": "Radlje ob Dravi",
                "countryCode": "SI"
            },
            {
                "name": "Lesce",
                "countryCode": "SI"
            },
            {
                "name": "Radovljica",
                "countryCode": "SI"
            },
            {
                "name": "Kotlje",
                "countryCode": "SI"
            },
            {
                "name": "Ravne na Koroškem",
                "countryCode": "SI"
            },
            {
                "name": "Ribnica",
                "countryCode": "SI"
            },
            {
                "name": "Rogašovci",
                "countryCode": "SI"
            },
            {
                "name": "Rogaška Slatina",
                "countryCode": "SI"
            },
            {
                "name": "Rogatec",
                "countryCode": "SI"
            },
            {
                "name": "Bistrica ob Dravi",
                "countryCode": "SI"
            },
            {
                "name": "Ruše",
                "countryCode": "SI"
            },
            {
                "name": "Semič",
                "countryCode": "SI"
            },
            {
                "name": "Sevnica",
                "countryCode": "SI"
            },
            {
                "name": "Sežana",
                "countryCode": "SI"
            },
            {
                "name": "Legen",
                "countryCode": "SI"
            },
            {
                "name": "Pameče",
                "countryCode": "SI"
            },
            {
                "name": "Slovenj Gradec",
                "countryCode": "SI"
            },
            {
                "name": "Šmartno pri Slovenj Gradcu",
                "countryCode": "SI"
            },
            {
                "name": "Pragersko",
                "countryCode": "SI"
            },
            {
                "name": "Slovenska Bistrica",
                "countryCode": "SI"
            },
            {
                "name": "Zgornja Polskava",
                "countryCode": "SI"
            },
            {
                "name": "Slovenske Konjice",
                "countryCode": "SI"
            },
            {
                "name": "Starše",
                "countryCode": "SI"
            },
            {
                "name": "Hrastje",
                "countryCode": "SI"
            },
            {
                "name": "Šenčur",
                "countryCode": "SI"
            },
            {
                "name": "Selnica ob Muri",
                "countryCode": "SI"
            },
            {
                "name": "Šentilj v Slov. Goricah",
                "countryCode": "SI"
            },
            {
                "name": "Šentjernej",
                "countryCode": "SI"
            },
            {
                "name": "Šentjur",
                "countryCode": "SI"
            },
            {
                "name": "Škocjan",
                "countryCode": "SI"
            },
            {
                "name": "Sv. Duh",
                "countryCode": "SI"
            },
            {
                "name": "Škofja Loka",
                "countryCode": "SI"
            },
            {
                "name": "Lavrica",
                "countryCode": "SI"
            },
            {
                "name": "Škofljica",
                "countryCode": "SI"
            },
            {
                "name": "Šmarje pri Jelšah",
                "countryCode": "SI"
            },
            {
                "name": "Šmartno ob Paki",
                "countryCode": "SI"
            },
            {
                "name": "Ravne",
                "countryCode": "SI"
            },
            {
                "name": "Topolšica",
                "countryCode": "SI"
            },
            {
                "name": "Šoštanj",
                "countryCode": "SI"
            },
            {
                "name": "Štore",
                "countryCode": "SI"
            },
            {
                "name": "Tolmin",
                "countryCode": "SI"
            },
            {
                "name": "Trbovlje",
                "countryCode": "SI"
            },
            {
                "name": "Trebnje",
                "countryCode": "SI"
            },
            {
                "name": "Bistrica pri Tržiču",
                "countryCode": "SI"
            },
            {
                "name": "Tržič",
                "countryCode": "SI"
            },
            {
                "name": "Turnišče",
                "countryCode": "SI"
            },
            {
                "name": "Velike Lašče",
                "countryCode": "SI"
            },
            {
                "name": "Videm pri Ptuju",
                "countryCode": "SI"
            },
            {
                "name": "Vipava",
                "countryCode": "SI"
            },
            {
                "name": "Vitanje",
                "countryCode": "SI"
            },
            {
                "name": "Vodice",
                "countryCode": "SI"
            },
            {
                "name": "Vojnik",
                "countryCode": "SI"
            },
            {
                "name": "Verd",
                "countryCode": "SI"
            },
            {
                "name": "Vrhnika",
                "countryCode": "SI"
            },
            {
                "name": "Vuzenica",
                "countryCode": "SI"
            },
            {
                "name": "Izlake",
                "countryCode": "SI"
            },
            {
                "name": "Kisovec",
                "countryCode": "SI"
            },
            {
                "name": "Zagorje ob Savi",
                "countryCode": "SI"
            },
            {
                "name": "Zavrč",
                "countryCode": "SI"
            },
            {
                "name": "Zreče",
                "countryCode": "SI"
            },
            {
                "name": "Železniki",
                "countryCode": "SI"
            },
            {
                "name": "Žiri",
                "countryCode": "SI"
            },
            {
                "name": "Benedikt",
                "countryCode": "SI"
            },
            {
                "name": "Bistrica ob Sotli",
                "countryCode": "SI"
            },
            {
                "name": "Nova Vas",
                "countryCode": "SI"
            },
            {
                "name": "Braslovče",
                "countryCode": "SI"
            },
            {
                "name": "Cankova",
                "countryCode": "SI"
            },
            {
                "name": "Cerkvenjak",
                "countryCode": "SI"
            },
            {
                "name": "Dobje pri Planini",
                "countryCode": "SI"
            },
            {
                "name": "Dobrna",
                "countryCode": "SI"
            },
            {
                "name": "Dobrovnik",
                "countryCode": "SI"
            },
            {
                "name": "Dolenjske Toplice",
                "countryCode": "SI"
            },
            {
                "name": "Grad",
                "countryCode": "SI"
            },
            {
                "name": "Spodnja Hajdina",
                "countryCode": "SI"
            },
            {
                "name": "Hotinja Vas",
                "countryCode": "SI"
            },
            {
                "name": "Radizel",
                "countryCode": "SI"
            },
            {
                "name": "Rogoza",
                "countryCode": "SI"
            },
            {
                "name": "Spodnje Hoče",
                "countryCode": "SI"
            },
            {
                "name": "Hodoš",
                "countryCode": "SI"
            },
            {
                "name": "Horjul",
                "countryCode": "SI"
            },
            {
                "name": "Zgornje Jezersko",
                "countryCode": "SI"
            },
            {
                "name": "Komenda",
                "countryCode": "SI"
            },
            {
                "name": "Moste",
                "countryCode": "SI"
            },
            {
                "name": "Kostel",
                "countryCode": "SI"
            },
            {
                "name": "Križevci pri Ljutomeru",
                "countryCode": "SI"
            },
            {
                "name": "Lovrenc na Pohorju",
                "countryCode": "SI"
            },
            {
                "name": "Markovci",
                "countryCode": "SI"
            },
            {
                "name": "Miklavž na Dravskem Polju",
                "countryCode": "SI"
            },
            {
                "name": "Mirna Peč",
                "countryCode": "SI"
            },
            {
                "name": "Oplotnica",
                "countryCode": "SI"
            },
            {
                "name": "Podlehnik",
                "countryCode": "SI"
            },
            {
                "name": "Polzela",
                "countryCode": "SI"
            },
            {
                "name": "Prebold",
                "countryCode": "SI"
            },
            {
                "name": "Prevalje",
                "countryCode": "SI"
            },
            {
                "name": "Razkrižje",
                "countryCode": "SI"
            },
            {
                "name": "Ribnica na Pohorju",
                "countryCode": "SI"
            },
            {
                "name": "Selnica ob Dravi",
                "countryCode": "SI"
            },
            {
                "name": "Sodražica",
                "countryCode": "SI"
            },
            {
                "name": "Solčava",
                "countryCode": "SI"
            },
            {
                "name": "Sv. Ana v Slov. Goricah",
                "countryCode": "SI"
            },
            {
                "name": "Vitomarci",
                "countryCode": "SI"
            },
            {
                "name": "Rožna Dolina",
                "countryCode": "SI"
            },
            {
                "name": "Vrtojba",
                "countryCode": "SI"
            },
            {
                "name": "Šempeter pri Gorici",
                "countryCode": "SI"
            },
            {
                "name": "Tabor",
                "countryCode": "SI"
            },
            {
                "name": "Trnovska Vas",
                "countryCode": "SI"
            },
            {
                "name": "Trzin",
                "countryCode": "SI"
            },
            {
                "name": "Črnuče District",
                "countryCode": "SI"
            },
            {
                "name": "Velika Polana",
                "countryCode": "SI"
            },
            {
                "name": "Veržej",
                "countryCode": "SI"
            },
            {
                "name": "Vransko",
                "countryCode": "SI"
            },
            {
                "name": "Gotovlje",
                "countryCode": "SI"
            },
            {
                "name": "Zabukovica",
                "countryCode": "SI"
            },
            {
                "name": "Šempeter v Savinj. Dolini",
                "countryCode": "SI"
            },
            {
                "name": "Žalec",
                "countryCode": "SI"
            },
            {
                "name": "Žetale",
                "countryCode": "SI"
            },
            {
                "name": "Žirovnica",
                "countryCode": "SI"
            },
            {
                "name": "Žužemberk",
                "countryCode": "SI"
            },
            {
                "name": "Šmartno pri Litiji",
                "countryCode": "SI"
            },
            {
                "name": "Apače",
                "countryCode": "SI"
            },
            {
                "name": "Renče",
                "countryCode": "SI"
            },
            {
                "name": "Volčja Draga",
                "countryCode": "SI"
            },
            {
                "name": "Središče ob Dravi",
                "countryCode": "SI"
            },
            {
                "name": "Straža",
                "countryCode": "SI"
            },
            {
                "name": "Sv. Trojica v Slov. Goricah",
                "countryCode": "SI"
            },
            {
                "name": "Sveti Tomaž",
                "countryCode": "SI"
            },
            {
                "name": "Šmarjeta",
                "countryCode": "SI"
            },
            {
                "name": "Sveti Jurij ob Ščavnici",
                "countryCode": "SI"
            },
            {
                "name": "Šentrupert",
                "countryCode": "SI"
            },
            {
                "name": "Ankaran",
                "countryCode": "SI"
            }
        ]
    },
    {
        "name": "Solomon Islands",
        "phonecode": "677",
        "cities": [
            {
                "name": "Tulagi",
                "countryCode": "SB"
            },
            {
                "name": "Honiara",
                "countryCode": "SB"
            },
            {
                "name": "Buala",
                "countryCode": "SB"
            },
            {
                "name": "Kirakira",
                "countryCode": "SB"
            },
            {
                "name": "Auki",
                "countryCode": "SB"
            },
            {
                "name": "Lata",
                "countryCode": "SB"
            },
            {
                "name": "Gizo",
                "countryCode": "SB"
            }
        ]
    },
    {
        "name": "Somalia",
        "phonecode": "252",
        "cities": [
            {
                "name": "Tayeeglow",
                "countryCode": "SO"
            },
            {
                "name": "Waajid",
                "countryCode": "SO"
            },
            {
                "name": "Xuddur",
                "countryCode": "SO"
            },
            {
                "name": "Yeed",
                "countryCode": "SO"
            },
            {
                "name": "Mogadishu",
                "countryCode": "SO"
            },
            {
                "name": "Bandarbeyla",
                "countryCode": "SO"
            },
            {
                "name": "Bargaal",
                "countryCode": "SO"
            },
            {
                "name": "Bereeda",
                "countryCode": "SO"
            },
            {
                "name": "Bosaso",
                "countryCode": "SO"
            },
            {
                "name": "Caluula",
                "countryCode": "SO"
            },
            {
                "name": "Iskushuban",
                "countryCode": "SO"
            },
            {
                "name": "Qandala",
                "countryCode": "SO"
            },
            {
                "name": "Baidoa",
                "countryCode": "SO"
            },
            {
                "name": "Buurhakaba",
                "countryCode": "SO"
            },
            {
                "name": "Ceelbuur",
                "countryCode": "SO"
            },
            {
                "name": "Ceeldheer",
                "countryCode": "SO"
            },
            {
                "name": "Dhuusamarreeb",
                "countryCode": "SO"
            },
            {
                "name": "Baardheere",
                "countryCode": "SO"
            },
            {
                "name": "Garbahaarrey",
                "countryCode": "SO"
            },
            {
                "name": "Luuq",
                "countryCode": "SO"
            },
            {
                "name": "Beledweyne",
                "countryCode": "SO"
            },
            {
                "name": "Buulobarde",
                "countryCode": "SO"
            },
            {
                "name": "Jalalaqsi",
                "countryCode": "SO"
            },
            {
                "name": "Dujuuma",
                "countryCode": "SO"
            },
            {
                "name": "Jilib",
                "countryCode": "SO"
            },
            {
                "name": "Saacow",
                "countryCode": "SO"
            },
            {
                "name": "Buur Gaabo",
                "countryCode": "SO"
            },
            {
                "name": "Jamaame",
                "countryCode": "SO"
            },
            {
                "name": "Kismayo",
                "countryCode": "SO"
            },
            {
                "name": "Gaalkacyo",
                "countryCode": "SO"
            },
            {
                "name": "Hobyo",
                "countryCode": "SO"
            },
            {
                "name": "Xarardheere",
                "countryCode": "SO"
            },
            {
                "name": "Eyl",
                "countryCode": "SO"
            },
            {
                "name": "Garoowe",
                "countryCode": "SO"
            },
            {
                "name": "Ceerigaabo",
                "countryCode": "SO"
            },
            {
                "name": "Las Khorey",
                "countryCode": "SO"
            },
            {
                "name": "Cadale",
                "countryCode": "SO"
            },
            {
                "name": "Jawhar",
                "countryCode": "SO"
            },
            {
                "name": "Mahaddayweyne",
                "countryCode": "SO"
            },
            {
                "name": "Afgooye",
                "countryCode": "SO"
            },
            {
                "name": "Marka",
                "countryCode": "SO"
            },
            {
                "name": "Qoryooley",
                "countryCode": "SO"
            },
            {
                "name": "Wanlaweyn",
                "countryCode": "SO"
            },
            {
                "name": "Burao",
                "countryCode": "SO"
            },
            {
                "name": "Ceek",
                "countryCode": "SO"
            },
            {
                "name": "Oodweyne",
                "countryCode": "SO"
            }
        ]
    },
    {
        "name": "South Africa",
        "phonecode": "27",
        "cities": [
            {
                "name": "Adelaide",
                "countryCode": "ZA"
            },
            {
                "name": "Alfred Nzo District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Alice",
                "countryCode": "ZA"
            },
            {
                "name": "Aliwal North",
                "countryCode": "ZA"
            },
            {
                "name": "Amathole District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Bhisho",
                "countryCode": "ZA"
            },
            {
                "name": "Buffalo City Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Burgersdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Butterworth",
                "countryCode": "ZA"
            },
            {
                "name": "Cacadu District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Chris Hani District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Cradock",
                "countryCode": "ZA"
            },
            {
                "name": "Dordrecht",
                "countryCode": "ZA"
            },
            {
                "name": "East London",
                "countryCode": "ZA"
            },
            {
                "name": "Elliot",
                "countryCode": "ZA"
            },
            {
                "name": "Fort Beaufort",
                "countryCode": "ZA"
            },
            {
                "name": "Graaff-Reinet",
                "countryCode": "ZA"
            },
            {
                "name": "Grahamstown",
                "countryCode": "ZA"
            },
            {
                "name": "Ilinge",
                "countryCode": "ZA"
            },
            {
                "name": "Joe Gqabi District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Kirkwood",
                "countryCode": "ZA"
            },
            {
                "name": "Kruisfontein",
                "countryCode": "ZA"
            },
            {
                "name": "Lady Frere",
                "countryCode": "ZA"
            },
            {
                "name": "Middelburg",
                "countryCode": "ZA"
            },
            {
                "name": "Molteno",
                "countryCode": "ZA"
            },
            {
                "name": "Mthatha",
                "countryCode": "ZA"
            },
            {
                "name": "Nelson Mandela Bay Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "OR Tambo District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Port Alfred",
                "countryCode": "ZA"
            },
            {
                "name": "Port Elizabeth",
                "countryCode": "ZA"
            },
            {
                "name": "Port Saint John’s",
                "countryCode": "ZA"
            },
            {
                "name": "Queensdale",
                "countryCode": "ZA"
            },
            {
                "name": "Queenstown",
                "countryCode": "ZA"
            },
            {
                "name": "Somerset East",
                "countryCode": "ZA"
            },
            {
                "name": "Stutterheim",
                "countryCode": "ZA"
            },
            {
                "name": "Uitenhage",
                "countryCode": "ZA"
            },
            {
                "name": "Whittlesea",
                "countryCode": "ZA"
            },
            {
                "name": "Willowmore",
                "countryCode": "ZA"
            },
            {
                "name": "Allanridge",
                "countryCode": "ZA"
            },
            {
                "name": "Bethlehem",
                "countryCode": "ZA"
            },
            {
                "name": "Bloemfontein",
                "countryCode": "ZA"
            },
            {
                "name": "Bothaville",
                "countryCode": "ZA"
            },
            {
                "name": "Botshabelo",
                "countryCode": "ZA"
            },
            {
                "name": "Brandfort",
                "countryCode": "ZA"
            },
            {
                "name": "Clocolan",
                "countryCode": "ZA"
            },
            {
                "name": "Deneysville",
                "countryCode": "ZA"
            },
            {
                "name": "Fezile Dabi District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Frankfort",
                "countryCode": "ZA"
            },
            {
                "name": "Harrismith",
                "countryCode": "ZA"
            },
            {
                "name": "Heilbron",
                "countryCode": "ZA"
            },
            {
                "name": "Hennenman",
                "countryCode": "ZA"
            },
            {
                "name": "Hoopstad",
                "countryCode": "ZA"
            },
            {
                "name": "Koppies",
                "countryCode": "ZA"
            },
            {
                "name": "Kroonstad",
                "countryCode": "ZA"
            },
            {
                "name": "Kutloanong",
                "countryCode": "ZA"
            },
            {
                "name": "Ladybrand",
                "countryCode": "ZA"
            },
            {
                "name": "Lejweleputswa District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Lindley",
                "countryCode": "ZA"
            },
            {
                "name": "Mangaung Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Marquard",
                "countryCode": "ZA"
            },
            {
                "name": "Parys",
                "countryCode": "ZA"
            },
            {
                "name": "Phuthaditjhaba",
                "countryCode": "ZA"
            },
            {
                "name": "Reitz",
                "countryCode": "ZA"
            },
            {
                "name": "Sasolburg",
                "countryCode": "ZA"
            },
            {
                "name": "Senekal",
                "countryCode": "ZA"
            },
            {
                "name": "Thaba Nchu",
                "countryCode": "ZA"
            },
            {
                "name": "Thabo Mofutsanyana District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Theunissen",
                "countryCode": "ZA"
            },
            {
                "name": "Ventersburg",
                "countryCode": "ZA"
            },
            {
                "name": "Viljoenskroon",
                "countryCode": "ZA"
            },
            {
                "name": "Villiers",
                "countryCode": "ZA"
            },
            {
                "name": "Virginia",
                "countryCode": "ZA"
            },
            {
                "name": "Vrede",
                "countryCode": "ZA"
            },
            {
                "name": "Vredefort",
                "countryCode": "ZA"
            },
            {
                "name": "Welkom",
                "countryCode": "ZA"
            },
            {
                "name": "Wesselsbron",
                "countryCode": "ZA"
            },
            {
                "name": "Winburg",
                "countryCode": "ZA"
            },
            {
                "name": "Xhariep District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Zastron",
                "countryCode": "ZA"
            },
            {
                "name": "Alberton",
                "countryCode": "ZA"
            },
            {
                "name": "Benoni",
                "countryCode": "ZA"
            },
            {
                "name": "Boksburg",
                "countryCode": "ZA"
            },
            {
                "name": "Brakpan",
                "countryCode": "ZA"
            },
            {
                "name": "Bronkhorstspruit",
                "countryCode": "ZA"
            },
            {
                "name": "Carletonville",
                "countryCode": "ZA"
            },
            {
                "name": "Centurion",
                "countryCode": "ZA"
            },
            {
                "name": "City of Johannesburg Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "City of Tshwane Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Cullinan",
                "countryCode": "ZA"
            },
            {
                "name": "Diepsloot",
                "countryCode": "ZA"
            },
            {
                "name": "Eastleigh",
                "countryCode": "ZA"
            },
            {
                "name": "Eden Glen",
                "countryCode": "ZA"
            },
            {
                "name": "Eden Glen Ext 60",
                "countryCode": "ZA"
            },
            {
                "name": "Edenvale",
                "countryCode": "ZA"
            },
            {
                "name": "Ekangala",
                "countryCode": "ZA"
            },
            {
                "name": "Ekurhuleni Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Heidelberg",
                "countryCode": "ZA"
            },
            {
                "name": "Johannesburg",
                "countryCode": "ZA"
            },
            {
                "name": "Krugersdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Mabopane",
                "countryCode": "ZA"
            },
            {
                "name": "Midrand",
                "countryCode": "ZA"
            },
            {
                "name": "Midstream",
                "countryCode": "ZA"
            },
            {
                "name": "Modderfontein",
                "countryCode": "ZA"
            },
            {
                "name": "Muldersdriseloop",
                "countryCode": "ZA"
            },
            {
                "name": "Nigel",
                "countryCode": "ZA"
            },
            {
                "name": "Orange Farm",
                "countryCode": "ZA"
            },
            {
                "name": "Pretoria",
                "countryCode": "ZA"
            },
            {
                "name": "Randburg",
                "countryCode": "ZA"
            },
            {
                "name": "Randfontein",
                "countryCode": "ZA"
            },
            {
                "name": "Roodepoort",
                "countryCode": "ZA"
            },
            {
                "name": "Sedibeng District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Soweto",
                "countryCode": "ZA"
            },
            {
                "name": "Springs",
                "countryCode": "ZA"
            },
            {
                "name": "Tembisa",
                "countryCode": "ZA"
            },
            {
                "name": "Vanderbijlpark",
                "countryCode": "ZA"
            },
            {
                "name": "Vereeniging",
                "countryCode": "ZA"
            },
            {
                "name": "West Rand District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Westonaria",
                "countryCode": "ZA"
            },
            {
                "name": "Amajuba District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Ballito",
                "countryCode": "ZA"
            },
            {
                "name": "Berea",
                "countryCode": "ZA"
            },
            {
                "name": "Dundee",
                "countryCode": "ZA"
            },
            {
                "name": "Durban",
                "countryCode": "ZA"
            },
            {
                "name": "Ekuvukeni",
                "countryCode": "ZA"
            },
            {
                "name": "Empangeni",
                "countryCode": "ZA"
            },
            {
                "name": "Eshowe",
                "countryCode": "ZA"
            },
            {
                "name": "Glencoe",
                "countryCode": "ZA"
            },
            {
                "name": "Greytown",
                "countryCode": "ZA"
            },
            {
                "name": "Hluhluwe",
                "countryCode": "ZA"
            },
            {
                "name": "Howick",
                "countryCode": "ZA"
            },
            {
                "name": "Kokstad",
                "countryCode": "ZA"
            },
            {
                "name": "KwaDukuza",
                "countryCode": "ZA"
            },
            {
                "name": "Margate",
                "countryCode": "ZA"
            },
            {
                "name": "Mondlo",
                "countryCode": "ZA"
            },
            {
                "name": "Mooirivier",
                "countryCode": "ZA"
            },
            {
                "name": "Mpophomeni",
                "countryCode": "ZA"
            },
            {
                "name": "Mpumalanga",
                "countryCode": "ZA"
            },
            {
                "name": "Mtubatuba",
                "countryCode": "ZA"
            },
            {
                "name": "Ndwedwe",
                "countryCode": "ZA"
            },
            {
                "name": "Newcastle",
                "countryCode": "ZA"
            },
            {
                "name": "Pietermaritzburg",
                "countryCode": "ZA"
            },
            {
                "name": "Port Shepstone",
                "countryCode": "ZA"
            },
            {
                "name": "Richards Bay",
                "countryCode": "ZA"
            },
            {
                "name": "Richmond",
                "countryCode": "ZA"
            },
            {
                "name": "Scottburgh",
                "countryCode": "ZA"
            },
            {
                "name": "Sisonke District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Sundumbili",
                "countryCode": "ZA"
            },
            {
                "name": "Ugu District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Ulundi",
                "countryCode": "ZA"
            },
            {
                "name": "Utrecht",
                "countryCode": "ZA"
            },
            {
                "name": "Vryheid",
                "countryCode": "ZA"
            },
            {
                "name": "Zululand District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "eMkhomazi",
                "countryCode": "ZA"
            },
            {
                "name": "eSikhaleni",
                "countryCode": "ZA"
            },
            {
                "name": "eThekwini Metropolitan Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "iLembe District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "uMgungundlovu District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "uMkhanyakude District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "uMzinyathi District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "uThukela District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "uThungulu District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Bochum",
                "countryCode": "ZA"
            },
            {
                "name": "Capricorn District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Duiwelskloof",
                "countryCode": "ZA"
            },
            {
                "name": "Ga-Kgapane",
                "countryCode": "ZA"
            },
            {
                "name": "Giyani",
                "countryCode": "ZA"
            },
            {
                "name": "Lebowakgomo",
                "countryCode": "ZA"
            },
            {
                "name": "Louis Trichardt",
                "countryCode": "ZA"
            },
            {
                "name": "Mankoeng",
                "countryCode": "ZA"
            },
            {
                "name": "Modimolle",
                "countryCode": "ZA"
            },
            {
                "name": "Mokopane",
                "countryCode": "ZA"
            },
            {
                "name": "Mopani District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Musina",
                "countryCode": "ZA"
            },
            {
                "name": "Nkowakowa",
                "countryCode": "ZA"
            },
            {
                "name": "Phalaborwa",
                "countryCode": "ZA"
            },
            {
                "name": "Polokwane",
                "countryCode": "ZA"
            },
            {
                "name": "Sekhukhune District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Thabazimbi",
                "countryCode": "ZA"
            },
            {
                "name": "Thohoyandou",
                "countryCode": "ZA"
            },
            {
                "name": "Thulamahashi",
                "countryCode": "ZA"
            },
            {
                "name": "Tzaneen",
                "countryCode": "ZA"
            },
            {
                "name": "Vhembe District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Warmbaths",
                "countryCode": "ZA"
            },
            {
                "name": "Waterberg District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Balfour",
                "countryCode": "ZA"
            },
            {
                "name": "Barberton",
                "countryCode": "ZA"
            },
            {
                "name": "Belfast",
                "countryCode": "ZA"
            },
            {
                "name": "Bethal",
                "countryCode": "ZA"
            },
            {
                "name": "Breyten",
                "countryCode": "ZA"
            },
            {
                "name": "Carolina",
                "countryCode": "ZA"
            },
            {
                "name": "Delmas",
                "countryCode": "ZA"
            },
            {
                "name": "Driefontein",
                "countryCode": "ZA"
            },
            {
                "name": "Ehlanzeni District",
                "countryCode": "ZA"
            },
            {
                "name": "Ermelo",
                "countryCode": "ZA"
            },
            {
                "name": "Gert Sibande District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Hendrina",
                "countryCode": "ZA"
            },
            {
                "name": "Komatipoort",
                "countryCode": "ZA"
            },
            {
                "name": "Kriel",
                "countryCode": "ZA"
            },
            {
                "name": "Lydenburg",
                "countryCode": "ZA"
            },
            {
                "name": "Middelburg",
                "countryCode": "ZA"
            },
            {
                "name": "Nelspruit",
                "countryCode": "ZA"
            },
            {
                "name": "Nkangala District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Piet Retief",
                "countryCode": "ZA"
            },
            {
                "name": "Secunda",
                "countryCode": "ZA"
            },
            {
                "name": "Siyabuswa",
                "countryCode": "ZA"
            },
            {
                "name": "Standerton",
                "countryCode": "ZA"
            },
            {
                "name": "Volksrust",
                "countryCode": "ZA"
            },
            {
                "name": "White River",
                "countryCode": "ZA"
            },
            {
                "name": "Witbank",
                "countryCode": "ZA"
            },
            {
                "name": "eMbalenhle",
                "countryCode": "ZA"
            },
            {
                "name": "Barkly West",
                "countryCode": "ZA"
            },
            {
                "name": "Brandvlei",
                "countryCode": "ZA"
            },
            {
                "name": "Calvinia",
                "countryCode": "ZA"
            },
            {
                "name": "Carnarvon",
                "countryCode": "ZA"
            },
            {
                "name": "Colesberg",
                "countryCode": "ZA"
            },
            {
                "name": "Daniëlskuil",
                "countryCode": "ZA"
            },
            {
                "name": "De Aar",
                "countryCode": "ZA"
            },
            {
                "name": "Frances Baard District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Fraserburg",
                "countryCode": "ZA"
            },
            {
                "name": "John Taolo Gaetsewe District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Kathu",
                "countryCode": "ZA"
            },
            {
                "name": "Kenhardt",
                "countryCode": "ZA"
            },
            {
                "name": "Kimberley",
                "countryCode": "ZA"
            },
            {
                "name": "Kuruman",
                "countryCode": "ZA"
            },
            {
                "name": "Namakwa District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Noupoort",
                "countryCode": "ZA"
            },
            {
                "name": "Orania",
                "countryCode": "ZA"
            },
            {
                "name": "Pampierstad",
                "countryCode": "ZA"
            },
            {
                "name": "Pixley ka Seme District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Pofadder",
                "countryCode": "ZA"
            },
            {
                "name": "Postmasburg",
                "countryCode": "ZA"
            },
            {
                "name": "Prieska",
                "countryCode": "ZA"
            },
            {
                "name": "Ritchie",
                "countryCode": "ZA"
            },
            {
                "name": "Siyanda District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Springbok",
                "countryCode": "ZA"
            },
            {
                "name": "Upington",
                "countryCode": "ZA"
            },
            {
                "name": "Van Wyksvlei",
                "countryCode": "ZA"
            },
            {
                "name": "Warrenton",
                "countryCode": "ZA"
            },
            {
                "name": "Bloemhof",
                "countryCode": "ZA"
            },
            {
                "name": "Bojanala Platinum District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Brits",
                "countryCode": "ZA"
            },
            {
                "name": "Christiana",
                "countryCode": "ZA"
            },
            {
                "name": "Dr Kenneth Kaunda District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Dr Ruth Segomotsi Mompati District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Fochville",
                "countryCode": "ZA"
            },
            {
                "name": "Ga-Rankuwa",
                "countryCode": "ZA"
            },
            {
                "name": "Jan Kempdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Klerksdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Koster",
                "countryCode": "ZA"
            },
            {
                "name": "Lichtenburg",
                "countryCode": "ZA"
            },
            {
                "name": "Mahikeng",
                "countryCode": "ZA"
            },
            {
                "name": "Maile",
                "countryCode": "ZA"
            },
            {
                "name": "Mmabatho",
                "countryCode": "ZA"
            },
            {
                "name": "Ngaka Modiri Molema District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Orkney",
                "countryCode": "ZA"
            },
            {
                "name": "Potchefstroom",
                "countryCode": "ZA"
            },
            {
                "name": "Rustenburg",
                "countryCode": "ZA"
            },
            {
                "name": "Schweizer-Reneke",
                "countryCode": "ZA"
            },
            {
                "name": "Stilfontein",
                "countryCode": "ZA"
            },
            {
                "name": "Vryburg",
                "countryCode": "ZA"
            },
            {
                "name": "Wolmaransstad",
                "countryCode": "ZA"
            },
            {
                "name": "Zeerust",
                "countryCode": "ZA"
            },
            {
                "name": "Albertina",
                "countryCode": "ZA"
            },
            {
                "name": "Arniston",
                "countryCode": "ZA"
            },
            {
                "name": "Atlantis",
                "countryCode": "ZA"
            },
            {
                "name": "Beaufort West",
                "countryCode": "ZA"
            },
            {
                "name": "Bergvliet",
                "countryCode": "ZA"
            },
            {
                "name": "Bredasdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Caledon",
                "countryCode": "ZA"
            },
            {
                "name": "Calitzdorp",
                "countryCode": "ZA"
            },
            {
                "name": "Cape Town",
                "countryCode": "ZA"
            },
            {
                "name": "Cape Winelands District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Central Karoo District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Ceres",
                "countryCode": "ZA"
            },
            {
                "name": "City of Cape Town",
                "countryCode": "ZA"
            },
            {
                "name": "Clanwilliam",
                "countryCode": "ZA"
            },
            {
                "name": "Claremont",
                "countryCode": "ZA"
            },
            {
                "name": "Constantia",
                "countryCode": "ZA"
            },
            {
                "name": "De Rust",
                "countryCode": "ZA"
            },
            {
                "name": "Eden District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "George",
                "countryCode": "ZA"
            },
            {
                "name": "Grabouw",
                "countryCode": "ZA"
            },
            {
                "name": "Hardys Memories of Africa",
                "countryCode": "ZA"
            },
            {
                "name": "Hermanus",
                "countryCode": "ZA"
            },
            {
                "name": "Knysna",
                "countryCode": "ZA"
            },
            {
                "name": "Kraaifontein",
                "countryCode": "ZA"
            },
            {
                "name": "Ladismith",
                "countryCode": "ZA"
            },
            {
                "name": "Lansdowne",
                "countryCode": "ZA"
            },
            {
                "name": "Malmesbury",
                "countryCode": "ZA"
            },
            {
                "name": "Montagu",
                "countryCode": "ZA"
            },
            {
                "name": "Moorreesburg",
                "countryCode": "ZA"
            },
            {
                "name": "Mossel Bay",
                "countryCode": "ZA"
            },
            {
                "name": "Newlands",
                "countryCode": "ZA"
            },
            {
                "name": "Oudtshoorn",
                "countryCode": "ZA"
            },
            {
                "name": "Overberg District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Paarl",
                "countryCode": "ZA"
            },
            {
                "name": "Piketberg",
                "countryCode": "ZA"
            },
            {
                "name": "Plettenberg Bay",
                "countryCode": "ZA"
            },
            {
                "name": "Prince Albert",
                "countryCode": "ZA"
            },
            {
                "name": "Retreat",
                "countryCode": "ZA"
            },
            {
                "name": "Riversdale",
                "countryCode": "ZA"
            },
            {
                "name": "Robertson",
                "countryCode": "ZA"
            },
            {
                "name": "Rondebosch",
                "countryCode": "ZA"
            },
            {
                "name": "Rosebank",
                "countryCode": "ZA"
            },
            {
                "name": "Saldanha",
                "countryCode": "ZA"
            },
            {
                "name": "Stellenbosch",
                "countryCode": "ZA"
            },
            {
                "name": "Sunset Beach",
                "countryCode": "ZA"
            },
            {
                "name": "Swellendam",
                "countryCode": "ZA"
            },
            {
                "name": "Vredenburg",
                "countryCode": "ZA"
            },
            {
                "name": "Vredendal",
                "countryCode": "ZA"
            },
            {
                "name": "Wellington",
                "countryCode": "ZA"
            },
            {
                "name": "West Coast District Municipality",
                "countryCode": "ZA"
            },
            {
                "name": "Worcester",
                "countryCode": "ZA"
            },
            {
                "name": "Zoar",
                "countryCode": "ZA"
            }
        ]
    },
    {
        "name": "South Georgia",
        "phonecode": "500",
        "cities": []
    },
    {
        "name": "South Sudan",
        "phonecode": "211",
        "cities": [
            {
                "name": "Yirol",
                "countryCode": "SS"
            }
        ]
    },
    {
        "name": "Spain",
        "phonecode": "34",
        "cities": [
            {
                "name": "Abla",
                "countryCode": "ES"
            },
            {
                "name": "Abrucena",
                "countryCode": "ES"
            },
            {
                "name": "Adamuz",
                "countryCode": "ES"
            },
            {
                "name": "Adra",
                "countryCode": "ES"
            },
            {
                "name": "Agrón",
                "countryCode": "ES"
            },
            {
                "name": "Aguadulce",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar",
                "countryCode": "ES"
            },
            {
                "name": "Alameda",
                "countryCode": "ES"
            },
            {
                "name": "Alamedilla",
                "countryCode": "ES"
            },
            {
                "name": "Alanís",
                "countryCode": "ES"
            },
            {
                "name": "Albaida del Aljarafe",
                "countryCode": "ES"
            },
            {
                "name": "Albolote",
                "countryCode": "ES"
            },
            {
                "name": "Albondón",
                "countryCode": "ES"
            },
            {
                "name": "Albox",
                "countryCode": "ES"
            },
            {
                "name": "Albuñol",
                "countryCode": "ES"
            },
            {
                "name": "Albuñuelas",
                "countryCode": "ES"
            },
            {
                "name": "Albuñán",
                "countryCode": "ES"
            },
            {
                "name": "Albánchez",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de Guadaira",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de los Gazules",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá del Río",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá la Real",
                "countryCode": "ES"
            },
            {
                "name": "Alcaracejos",
                "countryCode": "ES"
            },
            {
                "name": "Alcaucín",
                "countryCode": "ES"
            },
            {
                "name": "Alcaudete",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea del Río",
                "countryCode": "ES"
            },
            {
                "name": "Alcudia de Monteagud",
                "countryCode": "ES"
            },
            {
                "name": "Alcóntar",
                "countryCode": "ES"
            },
            {
                "name": "Aldeaquemada",
                "countryCode": "ES"
            },
            {
                "name": "Aldeire",
                "countryCode": "ES"
            },
            {
                "name": "Alfacar",
                "countryCode": "ES"
            },
            {
                "name": "Alfarnate",
                "countryCode": "ES"
            },
            {
                "name": "Alfarnatejo",
                "countryCode": "ES"
            },
            {
                "name": "Algar",
                "countryCode": "ES"
            },
            {
                "name": "Algarinejo",
                "countryCode": "ES"
            },
            {
                "name": "Algarrobo",
                "countryCode": "ES"
            },
            {
                "name": "Algatocín",
                "countryCode": "ES"
            },
            {
                "name": "Algeciras",
                "countryCode": "ES"
            },
            {
                "name": "Algodonales",
                "countryCode": "ES"
            },
            {
                "name": "Algámitas",
                "countryCode": "ES"
            },
            {
                "name": "Alhabia",
                "countryCode": "ES"
            },
            {
                "name": "Alhama de Almería",
                "countryCode": "ES"
            },
            {
                "name": "Alhama de Granada",
                "countryCode": "ES"
            },
            {
                "name": "Alhaurín de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Alhaurín el Grande",
                "countryCode": "ES"
            },
            {
                "name": "Alhendín",
                "countryCode": "ES"
            },
            {
                "name": "Alicún",
                "countryCode": "ES"
            },
            {
                "name": "Alicún de Ortega",
                "countryCode": "ES"
            },
            {
                "name": "Aljaraque",
                "countryCode": "ES"
            },
            {
                "name": "Almadén de la Plata",
                "countryCode": "ES"
            },
            {
                "name": "Almargen",
                "countryCode": "ES"
            },
            {
                "name": "Almedinilla",
                "countryCode": "ES"
            },
            {
                "name": "Almegíjar",
                "countryCode": "ES"
            },
            {
                "name": "Almensilla",
                "countryCode": "ES"
            },
            {
                "name": "Almería",
                "countryCode": "ES"
            },
            {
                "name": "Almodóvar del Río",
                "countryCode": "ES"
            },
            {
                "name": "Almogía",
                "countryCode": "ES"
            },
            {
                "name": "Almonte",
                "countryCode": "ES"
            },
            {
                "name": "Almuñécar",
                "countryCode": "ES"
            },
            {
                "name": "Almáchar",
                "countryCode": "ES"
            },
            {
                "name": "Almócita",
                "countryCode": "ES"
            },
            {
                "name": "Alora",
                "countryCode": "ES"
            },
            {
                "name": "Alosno",
                "countryCode": "ES"
            },
            {
                "name": "Alozaina",
                "countryCode": "ES"
            },
            {
                "name": "Alpandeire",
                "countryCode": "ES"
            },
            {
                "name": "Alquife",
                "countryCode": "ES"
            },
            {
                "name": "Alsodux",
                "countryCode": "ES"
            },
            {
                "name": "Alájar",
                "countryCode": "ES"
            },
            {
                "name": "Andújar",
                "countryCode": "ES"
            },
            {
                "name": "Antas",
                "countryCode": "ES"
            },
            {
                "name": "Antequera",
                "countryCode": "ES"
            },
            {
                "name": "Aracena",
                "countryCode": "ES"
            },
            {
                "name": "Arboleas",
                "countryCode": "ES"
            },
            {
                "name": "Archidona",
                "countryCode": "ES"
            },
            {
                "name": "Arcos de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Ardales",
                "countryCode": "ES"
            },
            {
                "name": "Arenas",
                "countryCode": "ES"
            },
            {
                "name": "Arenas del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Arjona",
                "countryCode": "ES"
            },
            {
                "name": "Arjonilla",
                "countryCode": "ES"
            },
            {
                "name": "Armilla",
                "countryCode": "ES"
            },
            {
                "name": "Armuña de Almanzora",
                "countryCode": "ES"
            },
            {
                "name": "Aroche",
                "countryCode": "ES"
            },
            {
                "name": "Arquillos",
                "countryCode": "ES"
            },
            {
                "name": "Arriate",
                "countryCode": "ES"
            },
            {
                "name": "Arroyo del Ojanco",
                "countryCode": "ES"
            },
            {
                "name": "Arroyomolinos de León",
                "countryCode": "ES"
            },
            {
                "name": "Atajate",
                "countryCode": "ES"
            },
            {
                "name": "Atarfe",
                "countryCode": "ES"
            },
            {
                "name": "Ayamonte",
                "countryCode": "ES"
            },
            {
                "name": "Aznalcázar",
                "countryCode": "ES"
            },
            {
                "name": "Aznalcóllar",
                "countryCode": "ES"
            },
            {
                "name": "Añora",
                "countryCode": "ES"
            },
            {
                "name": "Badolatosa",
                "countryCode": "ES"
            },
            {
                "name": "Baena",
                "countryCode": "ES"
            },
            {
                "name": "Baeza",
                "countryCode": "ES"
            },
            {
                "name": "Bailén",
                "countryCode": "ES"
            },
            {
                "name": "Barbate",
                "countryCode": "ES"
            },
            {
                "name": "Bayarque",
                "countryCode": "ES"
            },
            {
                "name": "Bayárcal",
                "countryCode": "ES"
            },
            {
                "name": "Baza",
                "countryCode": "ES"
            },
            {
                "name": "Baños de la Encina",
                "countryCode": "ES"
            },
            {
                "name": "Beas",
                "countryCode": "ES"
            },
            {
                "name": "Beas de Granada",
                "countryCode": "ES"
            },
            {
                "name": "Beas de Guadix",
                "countryCode": "ES"
            },
            {
                "name": "Beas de Segura",
                "countryCode": "ES"
            },
            {
                "name": "Begíjar",
                "countryCode": "ES"
            },
            {
                "name": "Beires",
                "countryCode": "ES"
            },
            {
                "name": "Belalcázar",
                "countryCode": "ES"
            },
            {
                "name": "Benacazón",
                "countryCode": "ES"
            },
            {
                "name": "Benahadux",
                "countryCode": "ES"
            },
            {
                "name": "Benahavís",
                "countryCode": "ES"
            },
            {
                "name": "Benalauría",
                "countryCode": "ES"
            },
            {
                "name": "Benalmádena",
                "countryCode": "ES"
            },
            {
                "name": "Benalup-Casas Viejas",
                "countryCode": "ES"
            },
            {
                "name": "Benalúa de Guadix",
                "countryCode": "ES"
            },
            {
                "name": "Benalúa de las Villas",
                "countryCode": "ES"
            },
            {
                "name": "Benamargosa",
                "countryCode": "ES"
            },
            {
                "name": "Benamaurel",
                "countryCode": "ES"
            },
            {
                "name": "Benamejí",
                "countryCode": "ES"
            },
            {
                "name": "Benamocarra",
                "countryCode": "ES"
            },
            {
                "name": "Benaocaz",
                "countryCode": "ES"
            },
            {
                "name": "Benaoján",
                "countryCode": "ES"
            },
            {
                "name": "Benarrabá",
                "countryCode": "ES"
            },
            {
                "name": "Benatae",
                "countryCode": "ES"
            },
            {
                "name": "Benitagla",
                "countryCode": "ES"
            },
            {
                "name": "Benizalón",
                "countryCode": "ES"
            },
            {
                "name": "Bentarique",
                "countryCode": "ES"
            },
            {
                "name": "Berja",
                "countryCode": "ES"
            },
            {
                "name": "Berrocal",
                "countryCode": "ES"
            },
            {
                "name": "Bollullos de la Mitación",
                "countryCode": "ES"
            },
            {
                "name": "Bollullos par del Condado",
                "countryCode": "ES"
            },
            {
                "name": "Bonares",
                "countryCode": "ES"
            },
            {
                "name": "Bormujos",
                "countryCode": "ES"
            },
            {
                "name": "Bornos",
                "countryCode": "ES"
            },
            {
                "name": "Brenes",
                "countryCode": "ES"
            },
            {
                "name": "Bubión",
                "countryCode": "ES"
            },
            {
                "name": "Bujalance",
                "countryCode": "ES"
            },
            {
                "name": "Burguillos",
                "countryCode": "ES"
            },
            {
                "name": "Busquístar",
                "countryCode": "ES"
            },
            {
                "name": "Bédar",
                "countryCode": "ES"
            },
            {
                "name": "Bélmez",
                "countryCode": "ES"
            },
            {
                "name": "Bélmez de la Moraleda",
                "countryCode": "ES"
            },
            {
                "name": "Bérchules",
                "countryCode": "ES"
            },
            {
                "name": "Cabezas Rubias",
                "countryCode": "ES"
            },
            {
                "name": "Cabra",
                "countryCode": "ES"
            },
            {
                "name": "Cabra del Santo Cristo",
                "countryCode": "ES"
            },
            {
                "name": "Cadiz",
                "countryCode": "ES"
            },
            {
                "name": "Cala",
                "countryCode": "ES"
            },
            {
                "name": "Calañas",
                "countryCode": "ES"
            },
            {
                "name": "Calicasas",
                "countryCode": "ES"
            },
            {
                "name": "Camas",
                "countryCode": "ES"
            },
            {
                "name": "Cambil",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Arenas",
                "countryCode": "ES"
            },
            {
                "name": "Campillos",
                "countryCode": "ES"
            },
            {
                "name": "Campiña",
                "countryCode": "ES"
            },
            {
                "name": "Campotéjar",
                "countryCode": "ES"
            },
            {
                "name": "Canena",
                "countryCode": "ES"
            },
            {
                "name": "Caniles",
                "countryCode": "ES"
            },
            {
                "name": "Canillas de Aceituno",
                "countryCode": "ES"
            },
            {
                "name": "Canillas de Albaida",
                "countryCode": "ES"
            },
            {
                "name": "Canjáyar",
                "countryCode": "ES"
            },
            {
                "name": "Cantillana",
                "countryCode": "ES"
            },
            {
                "name": "Cantoria",
                "countryCode": "ES"
            },
            {
                "name": "Capileira",
                "countryCode": "ES"
            },
            {
                "name": "Carataunas",
                "countryCode": "ES"
            },
            {
                "name": "Carboneras",
                "countryCode": "ES"
            },
            {
                "name": "Carboneros",
                "countryCode": "ES"
            },
            {
                "name": "Carcabuey",
                "countryCode": "ES"
            },
            {
                "name": "Cardeña",
                "countryCode": "ES"
            },
            {
                "name": "Carmona",
                "countryCode": "ES"
            },
            {
                "name": "Carratraca",
                "countryCode": "ES"
            },
            {
                "name": "Carrión de los Céspedes",
                "countryCode": "ES"
            },
            {
                "name": "Cartajima",
                "countryCode": "ES"
            },
            {
                "name": "Cartaya",
                "countryCode": "ES"
            },
            {
                "name": "Casabermeja",
                "countryCode": "ES"
            },
            {
                "name": "Casarabonela",
                "countryCode": "ES"
            },
            {
                "name": "Casares",
                "countryCode": "ES"
            },
            {
                "name": "Casariche",
                "countryCode": "ES"
            },
            {
                "name": "Castell de Ferro",
                "countryCode": "ES"
            },
            {
                "name": "Castellar de Santisteban",
                "countryCode": "ES"
            },
            {
                "name": "Castellar de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Castilblanco de los Arroyos",
                "countryCode": "ES"
            },
            {
                "name": "Castilleja de Guzmán",
                "countryCode": "ES"
            },
            {
                "name": "Castilleja de la Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "Castilleja del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Castillo de Locubín",
                "countryCode": "ES"
            },
            {
                "name": "Castilléjar",
                "countryCode": "ES"
            },
            {
                "name": "Castril",
                "countryCode": "ES"
            },
            {
                "name": "Castro de Filabres",
                "countryCode": "ES"
            },
            {
                "name": "Castro del Río",
                "countryCode": "ES"
            },
            {
                "name": "Cazalilla",
                "countryCode": "ES"
            },
            {
                "name": "Cazalla de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Cazorla",
                "countryCode": "ES"
            },
            {
                "name": "Cañada Rosal",
                "countryCode": "ES"
            },
            {
                "name": "Cañaveral de León",
                "countryCode": "ES"
            },
            {
                "name": "Cañete de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Cañete la Real",
                "countryCode": "ES"
            },
            {
                "name": "Cenes de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Chauchina",
                "countryCode": "ES"
            },
            {
                "name": "Chercos",
                "countryCode": "ES"
            },
            {
                "name": "Chiclana de Segura",
                "countryCode": "ES"
            },
            {
                "name": "Chiclana de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Chilluévar",
                "countryCode": "ES"
            },
            {
                "name": "Chimeneas",
                "countryCode": "ES"
            },
            {
                "name": "Chipiona",
                "countryCode": "ES"
            },
            {
                "name": "Chirivel",
                "countryCode": "ES"
            },
            {
                "name": "Chucena",
                "countryCode": "ES"
            },
            {
                "name": "Churriana de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Cijuela",
                "countryCode": "ES"
            },
            {
                "name": "Cogollos de Guadix",
                "countryCode": "ES"
            },
            {
                "name": "Colmenar",
                "countryCode": "ES"
            },
            {
                "name": "Colomera",
                "countryCode": "ES"
            },
            {
                "name": "Comares",
                "countryCode": "ES"
            },
            {
                "name": "Conil de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Conquista",
                "countryCode": "ES"
            },
            {
                "name": "Constantina",
                "countryCode": "ES"
            },
            {
                "name": "Coria del Río",
                "countryCode": "ES"
            },
            {
                "name": "Coripe",
                "countryCode": "ES"
            },
            {
                "name": "Corteconcepción",
                "countryCode": "ES"
            },
            {
                "name": "Cortelazor",
                "countryCode": "ES"
            },
            {
                "name": "Cortes de Baza",
                "countryCode": "ES"
            },
            {
                "name": "Cortes de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Costacabana",
                "countryCode": "ES"
            },
            {
                "name": "Coín",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas Bajas",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas de San Marcos",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas del Almanzora",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas del Becerro",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Cumbres Mayores",
                "countryCode": "ES"
            },
            {
                "name": "Cádiar",
                "countryCode": "ES"
            },
            {
                "name": "Cájar",
                "countryCode": "ES"
            },
            {
                "name": "Cártama",
                "countryCode": "ES"
            },
            {
                "name": "Cástaras",
                "countryCode": "ES"
            },
            {
                "name": "Cáñar",
                "countryCode": "ES"
            },
            {
                "name": "Cóbdar",
                "countryCode": "ES"
            },
            {
                "name": "Cómpeta",
                "countryCode": "ES"
            },
            {
                "name": "Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Cúllar-Vega",
                "countryCode": "ES"
            },
            {
                "name": "Cútar",
                "countryCode": "ES"
            },
            {
                "name": "Dalías",
                "countryCode": "ES"
            },
            {
                "name": "Darro",
                "countryCode": "ES"
            },
            {
                "name": "Dehesas de Guadix",
                "countryCode": "ES"
            },
            {
                "name": "Deifontes",
                "countryCode": "ES"
            },
            {
                "name": "Diezma",
                "countryCode": "ES"
            },
            {
                "name": "Domingo Pérez",
                "countryCode": "ES"
            },
            {
                "name": "Dos Hermanas",
                "countryCode": "ES"
            },
            {
                "name": "Dos Torres",
                "countryCode": "ES"
            },
            {
                "name": "Doña Mencía",
                "countryCode": "ES"
            },
            {
                "name": "Dílar",
                "countryCode": "ES"
            },
            {
                "name": "Dúdar",
                "countryCode": "ES"
            },
            {
                "name": "Dúrcal",
                "countryCode": "ES"
            },
            {
                "name": "El Arahal",
                "countryCode": "ES"
            },
            {
                "name": "El Bosque",
                "countryCode": "ES"
            },
            {
                "name": "El Carpio",
                "countryCode": "ES"
            },
            {
                "name": "El Castillo de las Guardas",
                "countryCode": "ES"
            },
            {
                "name": "El Cerro de Andévalo",
                "countryCode": "ES"
            },
            {
                "name": "El Coronil",
                "countryCode": "ES"
            },
            {
                "name": "El Cuervo",
                "countryCode": "ES"
            },
            {
                "name": "El Ejido",
                "countryCode": "ES"
            },
            {
                "name": "El Gastor",
                "countryCode": "ES"
            },
            {
                "name": "El Puerto de Santa María",
                "countryCode": "ES"
            },
            {
                "name": "El Rompido",
                "countryCode": "ES"
            },
            {
                "name": "El Ronquillo",
                "countryCode": "ES"
            },
            {
                "name": "El Rubio",
                "countryCode": "ES"
            },
            {
                "name": "El Saucejo",
                "countryCode": "ES"
            },
            {
                "name": "El Varadero",
                "countryCode": "ES"
            },
            {
                "name": "El Viso",
                "countryCode": "ES"
            },
            {
                "name": "El Viso del Alcor",
                "countryCode": "ES"
            },
            {
                "name": "Encinas Reales",
                "countryCode": "ES"
            },
            {
                "name": "Encinasola",
                "countryCode": "ES"
            },
            {
                "name": "Enix",
                "countryCode": "ES"
            },
            {
                "name": "Escacena del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Escañuela",
                "countryCode": "ES"
            },
            {
                "name": "Escúzar",
                "countryCode": "ES"
            },
            {
                "name": "Espartinas",
                "countryCode": "ES"
            },
            {
                "name": "Espejo",
                "countryCode": "ES"
            },
            {
                "name": "Espelúy",
                "countryCode": "ES"
            },
            {
                "name": "Espera",
                "countryCode": "ES"
            },
            {
                "name": "Espiel",
                "countryCode": "ES"
            },
            {
                "name": "Estación de Cártama",
                "countryCode": "ES"
            },
            {
                "name": "Estepa",
                "countryCode": "ES"
            },
            {
                "name": "Estepona",
                "countryCode": "ES"
            },
            {
                "name": "Faraján",
                "countryCode": "ES"
            },
            {
                "name": "Fernán-Núñez",
                "countryCode": "ES"
            },
            {
                "name": "Ferreira",
                "countryCode": "ES"
            },
            {
                "name": "Fines",
                "countryCode": "ES"
            },
            {
                "name": "Fiñana",
                "countryCode": "ES"
            },
            {
                "name": "Fondón",
                "countryCode": "ES"
            },
            {
                "name": "Fonelas",
                "countryCode": "ES"
            },
            {
                "name": "Frailes",
                "countryCode": "ES"
            },
            {
                "name": "Freila",
                "countryCode": "ES"
            },
            {
                "name": "Frigiliana",
                "countryCode": "ES"
            },
            {
                "name": "Fuengirola",
                "countryCode": "ES"
            },
            {
                "name": "Fuensanta de Martos",
                "countryCode": "ES"
            },
            {
                "name": "Fuente Obejuna",
                "countryCode": "ES"
            },
            {
                "name": "Fuente Palmera",
                "countryCode": "ES"
            },
            {
                "name": "Fuente Vaqueros",
                "countryCode": "ES"
            },
            {
                "name": "Fuente de Piedra",
                "countryCode": "ES"
            },
            {
                "name": "Fuente la Lancha",
                "countryCode": "ES"
            },
            {
                "name": "Fuente-Tójar",
                "countryCode": "ES"
            },
            {
                "name": "Fuenteheridos",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Andalucía",
                "countryCode": "ES"
            },
            {
                "name": "Fuerte del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Galaroza",
                "countryCode": "ES"
            },
            {
                "name": "Galera",
                "countryCode": "ES"
            },
            {
                "name": "Garrucha",
                "countryCode": "ES"
            },
            {
                "name": "Gaucín",
                "countryCode": "ES"
            },
            {
                "name": "Gelves",
                "countryCode": "ES"
            },
            {
                "name": "Genalguacil",
                "countryCode": "ES"
            },
            {
                "name": "Gerena",
                "countryCode": "ES"
            },
            {
                "name": "Gibraleón",
                "countryCode": "ES"
            },
            {
                "name": "Gilena",
                "countryCode": "ES"
            },
            {
                "name": "Gines",
                "countryCode": "ES"
            },
            {
                "name": "Gobernador",
                "countryCode": "ES"
            },
            {
                "name": "Gor",
                "countryCode": "ES"
            },
            {
                "name": "Granada",
                "countryCode": "ES"
            },
            {
                "name": "Grazalema",
                "countryCode": "ES"
            },
            {
                "name": "Guadahortuna",
                "countryCode": "ES"
            },
            {
                "name": "Guadalcanal",
                "countryCode": "ES"
            },
            {
                "name": "Guadalcázar",
                "countryCode": "ES"
            },
            {
                "name": "Guadix",
                "countryCode": "ES"
            },
            {
                "name": "Gualchos",
                "countryCode": "ES"
            },
            {
                "name": "Guaro",
                "countryCode": "ES"
            },
            {
                "name": "Guarromán",
                "countryCode": "ES"
            },
            {
                "name": "Guillena",
                "countryCode": "ES"
            },
            {
                "name": "Gádor",
                "countryCode": "ES"
            },
            {
                "name": "Génave",
                "countryCode": "ES"
            },
            {
                "name": "Gérgal",
                "countryCode": "ES"
            },
            {
                "name": "Gójar",
                "countryCode": "ES"
            },
            {
                "name": "Güevéjar",
                "countryCode": "ES"
            },
            {
                "name": "Güéjar-Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Herrera",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de Arjona",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Hinojales",
                "countryCode": "ES"
            },
            {
                "name": "Hinojares",
                "countryCode": "ES"
            },
            {
                "name": "Hinojos",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa del Duque",
                "countryCode": "ES"
            },
            {
                "name": "Hornachuelos",
                "countryCode": "ES"
            },
            {
                "name": "Huelma",
                "countryCode": "ES"
            },
            {
                "name": "Huelva",
                "countryCode": "ES"
            },
            {
                "name": "Huesa",
                "countryCode": "ES"
            },
            {
                "name": "Humilladero",
                "countryCode": "ES"
            },
            {
                "name": "Huécija",
                "countryCode": "ES"
            },
            {
                "name": "Huélago",
                "countryCode": "ES"
            },
            {
                "name": "Huéneja",
                "countryCode": "ES"
            },
            {
                "name": "Huércal de Almería",
                "countryCode": "ES"
            },
            {
                "name": "Huércal-Overa",
                "countryCode": "ES"
            },
            {
                "name": "Huéscar",
                "countryCode": "ES"
            },
            {
                "name": "Huétor Santillán",
                "countryCode": "ES"
            },
            {
                "name": "Huétor Vega",
                "countryCode": "ES"
            },
            {
                "name": "Huétor-Tájar",
                "countryCode": "ES"
            },
            {
                "name": "Ibros",
                "countryCode": "ES"
            },
            {
                "name": "Igualeja",
                "countryCode": "ES"
            },
            {
                "name": "Illar",
                "countryCode": "ES"
            },
            {
                "name": "Illora",
                "countryCode": "ES"
            },
            {
                "name": "Instinción",
                "countryCode": "ES"
            },
            {
                "name": "Isla Cristina",
                "countryCode": "ES"
            },
            {
                "name": "Islantilla",
                "countryCode": "ES"
            },
            {
                "name": "Istán",
                "countryCode": "ES"
            },
            {
                "name": "Iznalloz",
                "countryCode": "ES"
            },
            {
                "name": "Iznate",
                "countryCode": "ES"
            },
            {
                "name": "Iznatoraf",
                "countryCode": "ES"
            },
            {
                "name": "Iznájar",
                "countryCode": "ES"
            },
            {
                "name": "Jabalquinto",
                "countryCode": "ES"
            },
            {
                "name": "Jabugo",
                "countryCode": "ES"
            },
            {
                "name": "Jamilena",
                "countryCode": "ES"
            },
            {
                "name": "Jayena",
                "countryCode": "ES"
            },
            {
                "name": "Jaén",
                "countryCode": "ES"
            },
            {
                "name": "Jerez de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Jimena",
                "countryCode": "ES"
            },
            {
                "name": "Jimena de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Jimera de Líbar",
                "countryCode": "ES"
            },
            {
                "name": "Jubrique",
                "countryCode": "ES"
            },
            {
                "name": "Jun",
                "countryCode": "ES"
            },
            {
                "name": "Juviles",
                "countryCode": "ES"
            },
            {
                "name": "Jérez del Marquesado",
                "countryCode": "ES"
            },
            {
                "name": "Jódar",
                "countryCode": "ES"
            },
            {
                "name": "Júzcar",
                "countryCode": "ES"
            },
            {
                "name": "La Algaba",
                "countryCode": "ES"
            },
            {
                "name": "La Antilla",
                "countryCode": "ES"
            },
            {
                "name": "La Campana",
                "countryCode": "ES"
            },
            {
                "name": "La Carlota",
                "countryCode": "ES"
            },
            {
                "name": "La Carolina",
                "countryCode": "ES"
            },
            {
                "name": "La Gangosa Vistasol",
                "countryCode": "ES"
            },
            {
                "name": "La Guardia de Jaén",
                "countryCode": "ES"
            },
            {
                "name": "La Herradura",
                "countryCode": "ES"
            },
            {
                "name": "La Iruela",
                "countryCode": "ES"
            },
            {
                "name": "La Lantejuela",
                "countryCode": "ES"
            },
            {
                "name": "La Luisiana",
                "countryCode": "ES"
            },
            {
                "name": "La Línea de la Concepción",
                "countryCode": "ES"
            },
            {
                "name": "La Mojonera",
                "countryCode": "ES"
            },
            {
                "name": "La Palma del Condado",
                "countryCode": "ES"
            },
            {
                "name": "La Puebla de Cazalla",
                "countryCode": "ES"
            },
            {
                "name": "La Puebla de los Infantes",
                "countryCode": "ES"
            },
            {
                "name": "La Puebla del Río",
                "countryCode": "ES"
            },
            {
                "name": "La Puerta de Segura",
                "countryCode": "ES"
            },
            {
                "name": "La Rambla",
                "countryCode": "ES"
            },
            {
                "name": "La Rinconada",
                "countryCode": "ES"
            },
            {
                "name": "La Roda de Andalucía",
                "countryCode": "ES"
            },
            {
                "name": "La Victoria",
                "countryCode": "ES"
            },
            {
                "name": "Lanjarón",
                "countryCode": "ES"
            },
            {
                "name": "Lanteira",
                "countryCode": "ES"
            },
            {
                "name": "Laroya",
                "countryCode": "ES"
            },
            {
                "name": "Larva",
                "countryCode": "ES"
            },
            {
                "name": "Las Cabezas de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Las Gabias",
                "countryCode": "ES"
            },
            {
                "name": "Laujar de Andarax",
                "countryCode": "ES"
            },
            {
                "name": "Lebrija",
                "countryCode": "ES"
            },
            {
                "name": "Lentegí",
                "countryCode": "ES"
            },
            {
                "name": "Lepe",
                "countryCode": "ES"
            },
            {
                "name": "Linares",
                "countryCode": "ES"
            },
            {
                "name": "Linares de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Lobras",
                "countryCode": "ES"
            },
            {
                "name": "Loja",
                "countryCode": "ES"
            },
            {
                "name": "Lopera",
                "countryCode": "ES"
            },
            {
                "name": "Lora de Estepa",
                "countryCode": "ES"
            },
            {
                "name": "Lora del Río",
                "countryCode": "ES"
            },
            {
                "name": "Los Barrios",
                "countryCode": "ES"
            },
            {
                "name": "Los Corrales",
                "countryCode": "ES"
            },
            {
                "name": "Los Molares",
                "countryCode": "ES"
            },
            {
                "name": "Los Palacios y Villafranca",
                "countryCode": "ES"
            },
            {
                "name": "Los Villares",
                "countryCode": "ES"
            },
            {
                "name": "Lubrín",
                "countryCode": "ES"
            },
            {
                "name": "Lucainena de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Lucena",
                "countryCode": "ES"
            },
            {
                "name": "Lucena del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "Lugros",
                "countryCode": "ES"
            },
            {
                "name": "Lupión",
                "countryCode": "ES"
            },
            {
                "name": "Luque",
                "countryCode": "ES"
            },
            {
                "name": "Láchar",
                "countryCode": "ES"
            },
            {
                "name": "Lúcar",
                "countryCode": "ES"
            },
            {
                "name": "Lújar",
                "countryCode": "ES"
            },
            {
                "name": "Macael",
                "countryCode": "ES"
            },
            {
                "name": "Mairena del Alcor",
                "countryCode": "ES"
            },
            {
                "name": "Mairena del Aljarafe",
                "countryCode": "ES"
            },
            {
                "name": "Mancha Real",
                "countryCode": "ES"
            },
            {
                "name": "Manilva",
                "countryCode": "ES"
            },
            {
                "name": "Manzanilla",
                "countryCode": "ES"
            },
            {
                "name": "Maracena",
                "countryCode": "ES"
            },
            {
                "name": "Marbella",
                "countryCode": "ES"
            },
            {
                "name": "Marchal",
                "countryCode": "ES"
            },
            {
                "name": "Marchena",
                "countryCode": "ES"
            },
            {
                "name": "Marinaleda",
                "countryCode": "ES"
            },
            {
                "name": "Marmolejo",
                "countryCode": "ES"
            },
            {
                "name": "Martos",
                "countryCode": "ES"
            },
            {
                "name": "Martín de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "María",
                "countryCode": "ES"
            },
            {
                "name": "Mazagón",
                "countryCode": "ES"
            },
            {
                "name": "Medina Sidonia",
                "countryCode": "ES"
            },
            {
                "name": "Mengibar",
                "countryCode": "ES"
            },
            {
                "name": "Mijas",
                "countryCode": "ES"
            },
            {
                "name": "Moclinejo",
                "countryCode": "ES"
            },
            {
                "name": "Moclín",
                "countryCode": "ES"
            },
            {
                "name": "Moguer",
                "countryCode": "ES"
            },
            {
                "name": "Mojacar",
                "countryCode": "ES"
            },
            {
                "name": "Mollina",
                "countryCode": "ES"
            },
            {
                "name": "Molvízar",
                "countryCode": "ES"
            },
            {
                "name": "Monachil",
                "countryCode": "ES"
            },
            {
                "name": "Monda",
                "countryCode": "ES"
            },
            {
                "name": "Montalbán de Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Montefrío",
                "countryCode": "ES"
            },
            {
                "name": "Montejaque",
                "countryCode": "ES"
            },
            {
                "name": "Montejicar",
                "countryCode": "ES"
            },
            {
                "name": "Montellano",
                "countryCode": "ES"
            },
            {
                "name": "Montemayor",
                "countryCode": "ES"
            },
            {
                "name": "Montilla",
                "countryCode": "ES"
            },
            {
                "name": "Montillana",
                "countryCode": "ES"
            },
            {
                "name": "Montizón",
                "countryCode": "ES"
            },
            {
                "name": "Montoro",
                "countryCode": "ES"
            },
            {
                "name": "Monturque",
                "countryCode": "ES"
            },
            {
                "name": "Moraleda de Zafayona",
                "countryCode": "ES"
            },
            {
                "name": "Moriles",
                "countryCode": "ES"
            },
            {
                "name": "Morón de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Motril",
                "countryCode": "ES"
            },
            {
                "name": "Murtas",
                "countryCode": "ES"
            },
            {
                "name": "Málaga",
                "countryCode": "ES"
            },
            {
                "name": "Navas de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Nerja",
                "countryCode": "ES"
            },
            {
                "name": "Nerva",
                "countryCode": "ES"
            },
            {
                "name": "Niebla",
                "countryCode": "ES"
            },
            {
                "name": "Nigüelas",
                "countryCode": "ES"
            },
            {
                "name": "Noalejo",
                "countryCode": "ES"
            },
            {
                "name": "Nueva-Carteya",
                "countryCode": "ES"
            },
            {
                "name": "Níjar",
                "countryCode": "ES"
            },
            {
                "name": "Nívar",
                "countryCode": "ES"
            },
            {
                "name": "Obejo",
                "countryCode": "ES"
            },
            {
                "name": "Ogíjares",
                "countryCode": "ES"
            },
            {
                "name": "Ohanes",
                "countryCode": "ES"
            },
            {
                "name": "Ojén",
                "countryCode": "ES"
            },
            {
                "name": "Olivares",
                "countryCode": "ES"
            },
            {
                "name": "Olula de Castro",
                "countryCode": "ES"
            },
            {
                "name": "Olula del Río",
                "countryCode": "ES"
            },
            {
                "name": "Olvera",
                "countryCode": "ES"
            },
            {
                "name": "Orce",
                "countryCode": "ES"
            },
            {
                "name": "Orcera",
                "countryCode": "ES"
            },
            {
                "name": "Oria",
                "countryCode": "ES"
            },
            {
                "name": "Osuna",
                "countryCode": "ES"
            },
            {
                "name": "Otura",
                "countryCode": "ES"
            },
            {
                "name": "Otívar",
                "countryCode": "ES"
            },
            {
                "name": "Padul",
                "countryCode": "ES"
            },
            {
                "name": "Padules",
                "countryCode": "ES"
            },
            {
                "name": "Palenciana",
                "countryCode": "ES"
            },
            {
                "name": "Palma del Río",
                "countryCode": "ES"
            },
            {
                "name": "Palomares del Río",
                "countryCode": "ES"
            },
            {
                "name": "Palos de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Pampaneira",
                "countryCode": "ES"
            },
            {
                "name": "Paradas",
                "countryCode": "ES"
            },
            {
                "name": "Parauta",
                "countryCode": "ES"
            },
            {
                "name": "Partaloa",
                "countryCode": "ES"
            },
            {
                "name": "Paterna de Rivera",
                "countryCode": "ES"
            },
            {
                "name": "Paterna del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Paterna del Río",
                "countryCode": "ES"
            },
            {
                "name": "Paymogo",
                "countryCode": "ES"
            },
            {
                "name": "Peal de Becerro",
                "countryCode": "ES"
            },
            {
                "name": "Pedrera",
                "countryCode": "ES"
            },
            {
                "name": "Pedro Abad",
                "countryCode": "ES"
            },
            {
                "name": "Pedro Martínez",
                "countryCode": "ES"
            },
            {
                "name": "Pedroche",
                "countryCode": "ES"
            },
            {
                "name": "Pegalajar",
                "countryCode": "ES"
            },
            {
                "name": "Peligros",
                "countryCode": "ES"
            },
            {
                "name": "Periana",
                "countryCode": "ES"
            },
            {
                "name": "Peñaflor",
                "countryCode": "ES"
            },
            {
                "name": "Peñarroya-Pueblonuevo",
                "countryCode": "ES"
            },
            {
                "name": "Pilas",
                "countryCode": "ES"
            },
            {
                "name": "Pinos Genil",
                "countryCode": "ES"
            },
            {
                "name": "Pinos Puente",
                "countryCode": "ES"
            },
            {
                "name": "Pizarra",
                "countryCode": "ES"
            },
            {
                "name": "Piñar",
                "countryCode": "ES"
            },
            {
                "name": "Polopos",
                "countryCode": "ES"
            },
            {
                "name": "Polícar",
                "countryCode": "ES"
            },
            {
                "name": "Porcuna",
                "countryCode": "ES"
            },
            {
                "name": "Posadas",
                "countryCode": "ES"
            },
            {
                "name": "Pozo Alcón",
                "countryCode": "ES"
            },
            {
                "name": "Pozoblanco",
                "countryCode": "ES"
            },
            {
                "name": "Prado del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Priego de Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Province of Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Cádiz",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Granada",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Huelva",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Jaén",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Málaga",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Sevilla",
                "countryCode": "ES"
            },
            {
                "name": "Pruna",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Don Fadrique",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Guzmán",
                "countryCode": "ES"
            },
            {
                "name": "Puente de Génave",
                "countryCode": "ES"
            },
            {
                "name": "Puente-Genil",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Real",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Serrano",
                "countryCode": "ES"
            },
            {
                "name": "Pujerra",
                "countryCode": "ES"
            },
            {
                "name": "Pulianas",
                "countryCode": "ES"
            },
            {
                "name": "Pulpí",
                "countryCode": "ES"
            },
            {
                "name": "Punta Umbría",
                "countryCode": "ES"
            },
            {
                "name": "Purchena",
                "countryCode": "ES"
            },
            {
                "name": "Purullena",
                "countryCode": "ES"
            },
            {
                "name": "Pórtugos",
                "countryCode": "ES"
            },
            {
                "name": "Quesada",
                "countryCode": "ES"
            },
            {
                "name": "Quéntar",
                "countryCode": "ES"
            },
            {
                "name": "Retamar",
                "countryCode": "ES"
            },
            {
                "name": "Rincón de la Victoria",
                "countryCode": "ES"
            },
            {
                "name": "Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Ronda",
                "countryCode": "ES"
            },
            {
                "name": "Roquetas de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Rosal de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Rota",
                "countryCode": "ES"
            },
            {
                "name": "Rubite",
                "countryCode": "ES"
            },
            {
                "name": "Rus",
                "countryCode": "ES"
            },
            {
                "name": "Rute",
                "countryCode": "ES"
            },
            {
                "name": "Rágol",
                "countryCode": "ES"
            },
            {
                "name": "Ríogordo",
                "countryCode": "ES"
            },
            {
                "name": "Sabiote",
                "countryCode": "ES"
            },
            {
                "name": "Salar",
                "countryCode": "ES"
            },
            {
                "name": "Salares",
                "countryCode": "ES"
            },
            {
                "name": "Salobreña",
                "countryCode": "ES"
            },
            {
                "name": "Salteras",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "San Enrique de Guadiaro",
                "countryCode": "ES"
            },
            {
                "name": "San Fernando",
                "countryCode": "ES"
            },
            {
                "name": "San José",
                "countryCode": "ES"
            },
            {
                "name": "San José del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de Aznalfarache",
                "countryCode": "ES"
            },
            {
                "name": "San Juan del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "San Nicolás del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "San Roque",
                "countryCode": "ES"
            },
            {
                "name": "San Sebastián de los Ballesteros",
                "countryCode": "ES"
            },
            {
                "name": "San Silvestre de Guzmán",
                "countryCode": "ES"
            },
            {
                "name": "Sanlúcar de Barrameda",
                "countryCode": "ES"
            },
            {
                "name": "Sanlúcar de Guadiana",
                "countryCode": "ES"
            },
            {
                "name": "Sanlúcar la Mayor",
                "countryCode": "ES"
            },
            {
                "name": "Santa Ana la Real",
                "countryCode": "ES"
            },
            {
                "name": "Santa Bárbara de Casa",
                "countryCode": "ES"
            },
            {
                "name": "Santa Elena",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eufemia",
                "countryCode": "ES"
            },
            {
                "name": "Santa Fe de Mondújar",
                "countryCode": "ES"
            },
            {
                "name": "Santa Olalla del Cala",
                "countryCode": "ES"
            },
            {
                "name": "Santaella",
                "countryCode": "ES"
            },
            {
                "name": "Santafé",
                "countryCode": "ES"
            },
            {
                "name": "Santiago de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Santiponce",
                "countryCode": "ES"
            },
            {
                "name": "Santisteban del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "Santo Tomé",
                "countryCode": "ES"
            },
            {
                "name": "Sayalonga",
                "countryCode": "ES"
            },
            {
                "name": "Sedella",
                "countryCode": "ES"
            },
            {
                "name": "Segura de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Serón",
                "countryCode": "ES"
            },
            {
                "name": "Setenil de las Bodegas",
                "countryCode": "ES"
            },
            {
                "name": "Sevilla",
                "countryCode": "ES"
            },
            {
                "name": "Sierra de Yeguas",
                "countryCode": "ES"
            },
            {
                "name": "Sierro",
                "countryCode": "ES"
            },
            {
                "name": "Siles",
                "countryCode": "ES"
            },
            {
                "name": "Somontín",
                "countryCode": "ES"
            },
            {
                "name": "Soportújar",
                "countryCode": "ES"
            },
            {
                "name": "Sorbas",
                "countryCode": "ES"
            },
            {
                "name": "Sorihuela del Guadalimar",
                "countryCode": "ES"
            },
            {
                "name": "Sorvilán",
                "countryCode": "ES"
            },
            {
                "name": "Suflí",
                "countryCode": "ES"
            },
            {
                "name": "Tabernas",
                "countryCode": "ES"
            },
            {
                "name": "Tahal",
                "countryCode": "ES"
            },
            {
                "name": "Tarifa",
                "countryCode": "ES"
            },
            {
                "name": "Teba",
                "countryCode": "ES"
            },
            {
                "name": "Terque",
                "countryCode": "ES"
            },
            {
                "name": "Tocina",
                "countryCode": "ES"
            },
            {
                "name": "Tolox",
                "countryCode": "ES"
            },
            {
                "name": "Tomares",
                "countryCode": "ES"
            },
            {
                "name": "Torre Alháquime",
                "countryCode": "ES"
            },
            {
                "name": "Torre del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Torre del Mar",
                "countryCode": "ES"
            },
            {
                "name": "Torre-Cardela",
                "countryCode": "ES"
            },
            {
                "name": "Torreblascopedro",
                "countryCode": "ES"
            },
            {
                "name": "Torrecampo",
                "countryCode": "ES"
            },
            {
                "name": "Torredonjimeno",
                "countryCode": "ES"
            },
            {
                "name": "Torremolinos",
                "countryCode": "ES"
            },
            {
                "name": "Torrenueva",
                "countryCode": "ES"
            },
            {
                "name": "Torreperogil",
                "countryCode": "ES"
            },
            {
                "name": "Torres",
                "countryCode": "ES"
            },
            {
                "name": "Torrox",
                "countryCode": "ES"
            },
            {
                "name": "Totalán",
                "countryCode": "ES"
            },
            {
                "name": "Trebujena",
                "countryCode": "ES"
            },
            {
                "name": "Trevélez",
                "countryCode": "ES"
            },
            {
                "name": "Trigueros",
                "countryCode": "ES"
            },
            {
                "name": "Turre",
                "countryCode": "ES"
            },
            {
                "name": "Turrillas",
                "countryCode": "ES"
            },
            {
                "name": "Tíjola",
                "countryCode": "ES"
            },
            {
                "name": "Ubrique",
                "countryCode": "ES"
            },
            {
                "name": "Ugíjar",
                "countryCode": "ES"
            },
            {
                "name": "Uleila del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Umbrete",
                "countryCode": "ES"
            },
            {
                "name": "Urrácal",
                "countryCode": "ES"
            },
            {
                "name": "Utrera",
                "countryCode": "ES"
            },
            {
                "name": "Valdelarco",
                "countryCode": "ES"
            },
            {
                "name": "Valdepeñas de Jaén",
                "countryCode": "ES"
            },
            {
                "name": "Valderrubio",
                "countryCode": "ES"
            },
            {
                "name": "Valdezorras",
                "countryCode": "ES"
            },
            {
                "name": "Valdés",
                "countryCode": "ES"
            },
            {
                "name": "Valencina de la Concepción",
                "countryCode": "ES"
            },
            {
                "name": "Valenzuela",
                "countryCode": "ES"
            },
            {
                "name": "Valverde del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Vejer de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Velefique",
                "countryCode": "ES"
            },
            {
                "name": "Velez Rubio",
                "countryCode": "ES"
            },
            {
                "name": "Ventas de Huelma",
                "countryCode": "ES"
            },
            {
                "name": "Vera",
                "countryCode": "ES"
            },
            {
                "name": "Viator",
                "countryCode": "ES"
            },
            {
                "name": "Vilches",
                "countryCode": "ES"
            },
            {
                "name": "Villa del Río",
                "countryCode": "ES"
            },
            {
                "name": "Villablanca",
                "countryCode": "ES"
            },
            {
                "name": "Villacarrillo",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Villaharta",
                "countryCode": "ES"
            },
            {
                "name": "Villalba del Alcor",
                "countryCode": "ES"
            },
            {
                "name": "Villaluenga del Rosario",
                "countryCode": "ES"
            },
            {
                "name": "Villamanrique de la Condesa",
                "countryCode": "ES"
            },
            {
                "name": "Villamartín",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Algaidas",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Tapia",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de las Cruces",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de los Castillejos",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Ariscal",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Arzobispo",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Duque",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Rosario",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Río y Minas",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Trabuco",
                "countryCode": "ES"
            },
            {
                "name": "Villaralto",
                "countryCode": "ES"
            },
            {
                "name": "Villardompardo",
                "countryCode": "ES"
            },
            {
                "name": "Villarrasa",
                "countryCode": "ES"
            },
            {
                "name": "Villarrubia",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde del Río",
                "countryCode": "ES"
            },
            {
                "name": "Villaviciosa de Córdoba",
                "countryCode": "ES"
            },
            {
                "name": "Viñuela",
                "countryCode": "ES"
            },
            {
                "name": "Válor",
                "countryCode": "ES"
            },
            {
                "name": "Vélez de Benaudalla",
                "countryCode": "ES"
            },
            {
                "name": "Vélez-Blanco",
                "countryCode": "ES"
            },
            {
                "name": "Vélez-Málaga",
                "countryCode": "ES"
            },
            {
                "name": "Vícar",
                "countryCode": "ES"
            },
            {
                "name": "Víznar",
                "countryCode": "ES"
            },
            {
                "name": "Yunquera",
                "countryCode": "ES"
            },
            {
                "name": "Zafarraya",
                "countryCode": "ES"
            },
            {
                "name": "Zagra",
                "countryCode": "ES"
            },
            {
                "name": "Zahara",
                "countryCode": "ES"
            },
            {
                "name": "Zahara de los Atunes",
                "countryCode": "ES"
            },
            {
                "name": "Zalamea la Real",
                "countryCode": "ES"
            },
            {
                "name": "Zubia",
                "countryCode": "ES"
            },
            {
                "name": "Zufre",
                "countryCode": "ES"
            },
            {
                "name": "Zuheros",
                "countryCode": "ES"
            },
            {
                "name": "Zurgena",
                "countryCode": "ES"
            },
            {
                "name": "Zújar",
                "countryCode": "ES"
            },
            {
                "name": "Árchez",
                "countryCode": "ES"
            },
            {
                "name": "Écija",
                "countryCode": "ES"
            },
            {
                "name": "Ítrabo",
                "countryCode": "ES"
            },
            {
                "name": "Órgiva",
                "countryCode": "ES"
            },
            {
                "name": "Úbeda",
                "countryCode": "ES"
            },
            {
                "name": "Ababuj",
                "countryCode": "ES"
            },
            {
                "name": "Abanto",
                "countryCode": "ES"
            },
            {
                "name": "Abiego",
                "countryCode": "ES"
            },
            {
                "name": "Abizanda",
                "countryCode": "ES"
            },
            {
                "name": "Adahuesca",
                "countryCode": "ES"
            },
            {
                "name": "Aguarón",
                "countryCode": "ES"
            },
            {
                "name": "Aguatón",
                "countryCode": "ES"
            },
            {
                "name": "Aguaviva",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar del Alfambra",
                "countryCode": "ES"
            },
            {
                "name": "Aguilón",
                "countryCode": "ES"
            },
            {
                "name": "Agón",
                "countryCode": "ES"
            },
            {
                "name": "Agüero",
                "countryCode": "ES"
            },
            {
                "name": "Ainzón",
                "countryCode": "ES"
            },
            {
                "name": "Alacón",
                "countryCode": "ES"
            },
            {
                "name": "Aladrén",
                "countryCode": "ES"
            },
            {
                "name": "Alagón",
                "countryCode": "ES"
            },
            {
                "name": "Alarba",
                "countryCode": "ES"
            },
            {
                "name": "Alba",
                "countryCode": "ES"
            },
            {
                "name": "Albalate de Cinca",
                "countryCode": "ES"
            },
            {
                "name": "Albalate del Arzobispo",
                "countryCode": "ES"
            },
            {
                "name": "Albalatillo",
                "countryCode": "ES"
            },
            {
                "name": "Albarracín",
                "countryCode": "ES"
            },
            {
                "name": "Albelda",
                "countryCode": "ES"
            },
            {
                "name": "Alberite de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Albero Alto",
                "countryCode": "ES"
            },
            {
                "name": "Albero Bajo",
                "countryCode": "ES"
            },
            {
                "name": "Alberuela de Tubo",
                "countryCode": "ES"
            },
            {
                "name": "Albeta",
                "countryCode": "ES"
            },
            {
                "name": "Alborge",
                "countryCode": "ES"
            },
            {
                "name": "Alcaine",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de Gurrea",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de Moncayo",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de la Selva",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá del Obispo",
                "countryCode": "ES"
            },
            {
                "name": "Alcañiz",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea de Cinca",
                "countryCode": "ES"
            },
            {
                "name": "Alconchel de Ariza",
                "countryCode": "ES"
            },
            {
                "name": "Alcorisa",
                "countryCode": "ES"
            },
            {
                "name": "Alcubierre",
                "countryCode": "ES"
            },
            {
                "name": "Aldehuela de Liestos",
                "countryCode": "ES"
            },
            {
                "name": "Alerre",
                "countryCode": "ES"
            },
            {
                "name": "Alfajarín",
                "countryCode": "ES"
            },
            {
                "name": "Alfambra",
                "countryCode": "ES"
            },
            {
                "name": "Alfamén",
                "countryCode": "ES"
            },
            {
                "name": "Alforque",
                "countryCode": "ES"
            },
            {
                "name": "Alfántega",
                "countryCode": "ES"
            },
            {
                "name": "Alhama de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Aliaga",
                "countryCode": "ES"
            },
            {
                "name": "Allepuz",
                "countryCode": "ES"
            },
            {
                "name": "Alloza",
                "countryCode": "ES"
            },
            {
                "name": "Allueva",
                "countryCode": "ES"
            },
            {
                "name": "Almochuel",
                "countryCode": "ES"
            },
            {
                "name": "Almohaja",
                "countryCode": "ES"
            },
            {
                "name": "Almonacid de la Cuba",
                "countryCode": "ES"
            },
            {
                "name": "Almonacid de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Almozara",
                "countryCode": "ES"
            },
            {
                "name": "Almudévar",
                "countryCode": "ES"
            },
            {
                "name": "Almuniente",
                "countryCode": "ES"
            },
            {
                "name": "Alobras",
                "countryCode": "ES"
            },
            {
                "name": "Alpartir",
                "countryCode": "ES"
            },
            {
                "name": "Alpeñés",
                "countryCode": "ES"
            },
            {
                "name": "Alquézar",
                "countryCode": "ES"
            },
            {
                "name": "Ambel",
                "countryCode": "ES"
            },
            {
                "name": "Anadón",
                "countryCode": "ES"
            },
            {
                "name": "Andorra",
                "countryCode": "ES"
            },
            {
                "name": "Anento",
                "countryCode": "ES"
            },
            {
                "name": "Angüés",
                "countryCode": "ES"
            },
            {
                "name": "Ansó",
                "countryCode": "ES"
            },
            {
                "name": "Antillón",
                "countryCode": "ES"
            },
            {
                "name": "Aragüés del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "Aranda de Moncayo",
                "countryCode": "ES"
            },
            {
                "name": "Arcos de las Salinas",
                "countryCode": "ES"
            },
            {
                "name": "Arenys de Lledó / Arens de Lledó",
                "countryCode": "ES"
            },
            {
                "name": "Argavieso",
                "countryCode": "ES"
            },
            {
                "name": "Argente",
                "countryCode": "ES"
            },
            {
                "name": "Arguis",
                "countryCode": "ES"
            },
            {
                "name": "Ariza",
                "countryCode": "ES"
            },
            {
                "name": "Ariño",
                "countryCode": "ES"
            },
            {
                "name": "Artieda",
                "countryCode": "ES"
            },
            {
                "name": "Arándiga",
                "countryCode": "ES"
            },
            {
                "name": "Atea",
                "countryCode": "ES"
            },
            {
                "name": "Ateca",
                "countryCode": "ES"
            },
            {
                "name": "Ayerbe",
                "countryCode": "ES"
            },
            {
                "name": "Azara",
                "countryCode": "ES"
            },
            {
                "name": "Azlor",
                "countryCode": "ES"
            },
            {
                "name": "Azuara",
                "countryCode": "ES"
            },
            {
                "name": "Aínsa",
                "countryCode": "ES"
            },
            {
                "name": "Badules",
                "countryCode": "ES"
            },
            {
                "name": "Baells",
                "countryCode": "ES"
            },
            {
                "name": "Bagüés",
                "countryCode": "ES"
            },
            {
                "name": "Bailo",
                "countryCode": "ES"
            },
            {
                "name": "Balconchán",
                "countryCode": "ES"
            },
            {
                "name": "Ballobar",
                "countryCode": "ES"
            },
            {
                "name": "Banastás",
                "countryCode": "ES"
            },
            {
                "name": "Barbastro",
                "countryCode": "ES"
            },
            {
                "name": "Barbués",
                "countryCode": "ES"
            },
            {
                "name": "Barbuñales",
                "countryCode": "ES"
            },
            {
                "name": "Bardallur",
                "countryCode": "ES"
            },
            {
                "name": "Barrachina",
                "countryCode": "ES"
            },
            {
                "name": "Bañón",
                "countryCode": "ES"
            },
            {
                "name": "Bea",
                "countryCode": "ES"
            },
            {
                "name": "Belchite",
                "countryCode": "ES"
            },
            {
                "name": "Bello",
                "countryCode": "ES"
            },
            {
                "name": "Belver de Cinca",
                "countryCode": "ES"
            },
            {
                "name": "Benasque",
                "countryCode": "ES"
            },
            {
                "name": "Benavarri / Benabarre",
                "countryCode": "ES"
            },
            {
                "name": "Berbegal",
                "countryCode": "ES"
            },
            {
                "name": "Berdejo",
                "countryCode": "ES"
            },
            {
                "name": "Berge",
                "countryCode": "ES"
            },
            {
                "name": "Bezas",
                "countryCode": "ES"
            },
            {
                "name": "Biel",
                "countryCode": "ES"
            },
            {
                "name": "Bielsa",
                "countryCode": "ES"
            },
            {
                "name": "Bierge",
                "countryCode": "ES"
            },
            {
                "name": "Bijuesca",
                "countryCode": "ES"
            },
            {
                "name": "Binaced",
                "countryCode": "ES"
            },
            {
                "name": "Binéfar",
                "countryCode": "ES"
            },
            {
                "name": "Biota",
                "countryCode": "ES"
            },
            {
                "name": "Bisaurri",
                "countryCode": "ES"
            },
            {
                "name": "Biscarrués",
                "countryCode": "ES"
            },
            {
                "name": "Bisimbre",
                "countryCode": "ES"
            },
            {
                "name": "Blancas",
                "countryCode": "ES"
            },
            {
                "name": "Blesa",
                "countryCode": "ES"
            },
            {
                "name": "Boltaña",
                "countryCode": "ES"
            },
            {
                "name": "Bonansa",
                "countryCode": "ES"
            },
            {
                "name": "Boquiñeni",
                "countryCode": "ES"
            },
            {
                "name": "Borau",
                "countryCode": "ES"
            },
            {
                "name": "Bordalba",
                "countryCode": "ES"
            },
            {
                "name": "Bordón",
                "countryCode": "ES"
            },
            {
                "name": "Borja",
                "countryCode": "ES"
            },
            {
                "name": "Botorrita",
                "countryCode": "ES"
            },
            {
                "name": "Brea de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Bronchales",
                "countryCode": "ES"
            },
            {
                "name": "Broto",
                "countryCode": "ES"
            },
            {
                "name": "Bubierca",
                "countryCode": "ES"
            },
            {
                "name": "Bueña",
                "countryCode": "ES"
            },
            {
                "name": "Bujaraloz",
                "countryCode": "ES"
            },
            {
                "name": "Bulbuente",
                "countryCode": "ES"
            },
            {
                "name": "Burbáguena",
                "countryCode": "ES"
            },
            {
                "name": "Bureta",
                "countryCode": "ES"
            },
            {
                "name": "Bádenas",
                "countryCode": "ES"
            },
            {
                "name": "Báguena",
                "countryCode": "ES"
            },
            {
                "name": "Bárboles",
                "countryCode": "ES"
            },
            {
                "name": "Bárcabo",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Cabolafuente",
                "countryCode": "ES"
            },
            {
                "name": "Cabra de Mora",
                "countryCode": "ES"
            },
            {
                "name": "Cadrete",
                "countryCode": "ES"
            },
            {
                "name": "Calaceite",
                "countryCode": "ES"
            },
            {
                "name": "Calamocha",
                "countryCode": "ES"
            },
            {
                "name": "Calanda",
                "countryCode": "ES"
            },
            {
                "name": "Calatayud",
                "countryCode": "ES"
            },
            {
                "name": "Calatorao",
                "countryCode": "ES"
            },
            {
                "name": "Calcena",
                "countryCode": "ES"
            },
            {
                "name": "Calmarza",
                "countryCode": "ES"
            },
            {
                "name": "Calomarde",
                "countryCode": "ES"
            },
            {
                "name": "Camarena de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Camarillas",
                "countryCode": "ES"
            },
            {
                "name": "Camañas",
                "countryCode": "ES"
            },
            {
                "name": "Caminreal",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Camporrells",
                "countryCode": "ES"
            },
            {
                "name": "Candasnos",
                "countryCode": "ES"
            },
            {
                "name": "Canfranc",
                "countryCode": "ES"
            },
            {
                "name": "Cantavieja",
                "countryCode": "ES"
            },
            {
                "name": "Capdesaso",
                "countryCode": "ES"
            },
            {
                "name": "Capella",
                "countryCode": "ES"
            },
            {
                "name": "Carenas",
                "countryCode": "ES"
            },
            {
                "name": "Cariñena",
                "countryCode": "ES"
            },
            {
                "name": "Casbas de Huesca",
                "countryCode": "ES"
            },
            {
                "name": "Cascante del Río",
                "countryCode": "ES"
            },
            {
                "name": "Caspe",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Alarba",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Monegros",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Sos",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Tornos",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Valdejasa",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de las Armas",
                "countryCode": "ES"
            },
            {
                "name": "Castejón del Puente",
                "countryCode": "ES"
            },
            {
                "name": "Castel de Cabra",
                "countryCode": "ES"
            },
            {
                "name": "Castelflorite",
                "countryCode": "ES"
            },
            {
                "name": "Castellote",
                "countryCode": "ES"
            },
            {
                "name": "Castelnou",
                "countryCode": "ES"
            },
            {
                "name": "Castelserás",
                "countryCode": "ES"
            },
            {
                "name": "Castiello de Jaca",
                "countryCode": "ES"
            },
            {
                "name": "Castigaleu",
                "countryCode": "ES"
            },
            {
                "name": "Castiliscar",
                "countryCode": "ES"
            },
            {
                "name": "Castillazuelo",
                "countryCode": "ES"
            },
            {
                "name": "Cañada Vellida",
                "countryCode": "ES"
            },
            {
                "name": "Cañada de Benatanduz",
                "countryCode": "ES"
            },
            {
                "name": "Cañizar del Olivar",
                "countryCode": "ES"
            },
            {
                "name": "Cedrillas",
                "countryCode": "ES"
            },
            {
                "name": "Cella",
                "countryCode": "ES"
            },
            {
                "name": "Cervera de la Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Cerveruela",
                "countryCode": "ES"
            },
            {
                "name": "Cetina",
                "countryCode": "ES"
            },
            {
                "name": "Chalamera",
                "countryCode": "ES"
            },
            {
                "name": "Chimillas",
                "countryCode": "ES"
            },
            {
                "name": "Chiprana",
                "countryCode": "ES"
            },
            {
                "name": "Chodes",
                "countryCode": "ES"
            },
            {
                "name": "Chía",
                "countryCode": "ES"
            },
            {
                "name": "Cimballa",
                "countryCode": "ES"
            },
            {
                "name": "Cinco Olivas",
                "countryCode": "ES"
            },
            {
                "name": "Clarés de Ribota",
                "countryCode": "ES"
            },
            {
                "name": "Codos",
                "countryCode": "ES"
            },
            {
                "name": "Colungo",
                "countryCode": "ES"
            },
            {
                "name": "Contamina",
                "countryCode": "ES"
            },
            {
                "name": "Corbalán",
                "countryCode": "ES"
            },
            {
                "name": "Cortes de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Cosa",
                "countryCode": "ES"
            },
            {
                "name": "Cosuenda",
                "countryCode": "ES"
            },
            {
                "name": "Crivillén",
                "countryCode": "ES"
            },
            {
                "name": "Cuarte de Huerva",
                "countryCode": "ES"
            },
            {
                "name": "Cubel",
                "countryCode": "ES"
            },
            {
                "name": "Cubla",
                "countryCode": "ES"
            },
            {
                "name": "Cucalón",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas Labradas",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas de Almudén",
                "countryCode": "ES"
            },
            {
                "name": "Daroca",
                "countryCode": "ES"
            },
            {
                "name": "Delicias",
                "countryCode": "ES"
            },
            {
                "name": "Echo",
                "countryCode": "ES"
            },
            {
                "name": "Ejea de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Ejulve",
                "countryCode": "ES"
            },
            {
                "name": "El Burgo de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "El Castellar",
                "countryCode": "ES"
            },
            {
                "name": "Embid de Ariza",
                "countryCode": "ES"
            },
            {
                "name": "Encinacorba",
                "countryCode": "ES"
            },
            {
                "name": "Erla",
                "countryCode": "ES"
            },
            {
                "name": "Escatrón",
                "countryCode": "ES"
            },
            {
                "name": "Escorihuela",
                "countryCode": "ES"
            },
            {
                "name": "Escucha",
                "countryCode": "ES"
            },
            {
                "name": "Esplús",
                "countryCode": "ES"
            },
            {
                "name": "Estada",
                "countryCode": "ES"
            },
            {
                "name": "Estadilla",
                "countryCode": "ES"
            },
            {
                "name": "Estercuel",
                "countryCode": "ES"
            },
            {
                "name": "Fabara",
                "countryCode": "ES"
            },
            {
                "name": "Fago",
                "countryCode": "ES"
            },
            {
                "name": "Farlete",
                "countryCode": "ES"
            },
            {
                "name": "Fayón",
                "countryCode": "ES"
            },
            {
                "name": "Ferreruela de Huerva",
                "countryCode": "ES"
            },
            {
                "name": "Figueruelas",
                "countryCode": "ES"
            },
            {
                "name": "Fiscal",
                "countryCode": "ES"
            },
            {
                "name": "Fombuena",
                "countryCode": "ES"
            },
            {
                "name": "Fonfría",
                "countryCode": "ES"
            },
            {
                "name": "Fonz",
                "countryCode": "ES"
            },
            {
                "name": "Formiche Alto",
                "countryCode": "ES"
            },
            {
                "name": "Fortanete",
                "countryCode": "ES"
            },
            {
                "name": "Foz-Calanda",
                "countryCode": "ES"
            },
            {
                "name": "Fraga",
                "countryCode": "ES"
            },
            {
                "name": "Fréscano",
                "countryCode": "ES"
            },
            {
                "name": "Frías de Albarracín",
                "countryCode": "ES"
            },
            {
                "name": "Fuendejalón",
                "countryCode": "ES"
            },
            {
                "name": "Fuendetodos",
                "countryCode": "ES"
            },
            {
                "name": "Fuenferrada",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes Calientes",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes Claras",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Jiloca",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Rubielos",
                "countryCode": "ES"
            },
            {
                "name": "Fuentespalda",
                "countryCode": "ES"
            },
            {
                "name": "Fórnoles",
                "countryCode": "ES"
            },
            {
                "name": "Gallocanta",
                "countryCode": "ES"
            },
            {
                "name": "Gallur",
                "countryCode": "ES"
            },
            {
                "name": "Galve",
                "countryCode": "ES"
            },
            {
                "name": "Gargallo",
                "countryCode": "ES"
            },
            {
                "name": "Gea de Albarracín",
                "countryCode": "ES"
            },
            {
                "name": "Gelsa",
                "countryCode": "ES"
            },
            {
                "name": "Gistaín",
                "countryCode": "ES"
            },
            {
                "name": "Godojos",
                "countryCode": "ES"
            },
            {
                "name": "Gotor",
                "countryCode": "ES"
            },
            {
                "name": "Graus",
                "countryCode": "ES"
            },
            {
                "name": "Grañén",
                "countryCode": "ES"
            },
            {
                "name": "Griegos",
                "countryCode": "ES"
            },
            {
                "name": "Grisel",
                "countryCode": "ES"
            },
            {
                "name": "Grisén",
                "countryCode": "ES"
            },
            {
                "name": "Guadalaviar",
                "countryCode": "ES"
            },
            {
                "name": "Gurrea de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Gúdar",
                "countryCode": "ES"
            },
            {
                "name": "Herrera de los Navarros",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa de Jarque",
                "countryCode": "ES"
            },
            {
                "name": "Hoz de Jaca",
                "countryCode": "ES"
            },
            {
                "name": "Huerto",
                "countryCode": "ES"
            },
            {
                "name": "Huesa del Común",
                "countryCode": "ES"
            },
            {
                "name": "Huesca",
                "countryCode": "ES"
            },
            {
                "name": "Híjar",
                "countryCode": "ES"
            },
            {
                "name": "Ibdes",
                "countryCode": "ES"
            },
            {
                "name": "Ibieca",
                "countryCode": "ES"
            },
            {
                "name": "Igriés",
                "countryCode": "ES"
            },
            {
                "name": "Ilche",
                "countryCode": "ES"
            },
            {
                "name": "Illueca",
                "countryCode": "ES"
            },
            {
                "name": "Isuerre",
                "countryCode": "ES"
            },
            {
                "name": "Jabaloyas",
                "countryCode": "ES"
            },
            {
                "name": "Jaca",
                "countryCode": "ES"
            },
            {
                "name": "Jaraba",
                "countryCode": "ES"
            },
            {
                "name": "Jarque",
                "countryCode": "ES"
            },
            {
                "name": "Jarque de la Val",
                "countryCode": "ES"
            },
            {
                "name": "Jasa",
                "countryCode": "ES"
            },
            {
                "name": "Jatiel",
                "countryCode": "ES"
            },
            {
                "name": "Jaulín",
                "countryCode": "ES"
            },
            {
                "name": "Jorcas",
                "countryCode": "ES"
            },
            {
                "name": "Josa",
                "countryCode": "ES"
            },
            {
                "name": "La Almunia de Doña Godina",
                "countryCode": "ES"
            },
            {
                "name": "La Ginebrosa",
                "countryCode": "ES"
            },
            {
                "name": "La Iglesuela del Cid",
                "countryCode": "ES"
            },
            {
                "name": "Labuerda",
                "countryCode": "ES"
            },
            {
                "name": "Lagata",
                "countryCode": "ES"
            },
            {
                "name": "Lagueruela",
                "countryCode": "ES"
            },
            {
                "name": "Laluenga",
                "countryCode": "ES"
            },
            {
                "name": "Lalueza",
                "countryCode": "ES"
            },
            {
                "name": "Lanaja",
                "countryCode": "ES"
            },
            {
                "name": "Langa del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Lanzuela",
                "countryCode": "ES"
            },
            {
                "name": "Laperdiguera",
                "countryCode": "ES"
            },
            {
                "name": "Lascuarre",
                "countryCode": "ES"
            },
            {
                "name": "Laspaúles",
                "countryCode": "ES"
            },
            {
                "name": "Laspuña",
                "countryCode": "ES"
            },
            {
                "name": "Layana",
                "countryCode": "ES"
            },
            {
                "name": "Lechón",
                "countryCode": "ES"
            },
            {
                "name": "Leciñena",
                "countryCode": "ES"
            },
            {
                "name": "Letux",
                "countryCode": "ES"
            },
            {
                "name": "Libros",
                "countryCode": "ES"
            },
            {
                "name": "Lidón",
                "countryCode": "ES"
            },
            {
                "name": "Linares de Mora",
                "countryCode": "ES"
            },
            {
                "name": "Litago",
                "countryCode": "ES"
            },
            {
                "name": "Lituénigo",
                "countryCode": "ES"
            },
            {
                "name": "Lledó",
                "countryCode": "ES"
            },
            {
                "name": "Loarre",
                "countryCode": "ES"
            },
            {
                "name": "Lobera de Onsella",
                "countryCode": "ES"
            },
            {
                "name": "Longares",
                "countryCode": "ES"
            },
            {
                "name": "Longás",
                "countryCode": "ES"
            },
            {
                "name": "Loporzano",
                "countryCode": "ES"
            },
            {
                "name": "Loscorrales",
                "countryCode": "ES"
            },
            {
                "name": "Loscos",
                "countryCode": "ES"
            },
            {
                "name": "Lucena de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Luceni",
                "countryCode": "ES"
            },
            {
                "name": "Luesia",
                "countryCode": "ES"
            },
            {
                "name": "Luesma",
                "countryCode": "ES"
            },
            {
                "name": "Lumpiaque",
                "countryCode": "ES"
            },
            {
                "name": "Luna",
                "countryCode": "ES"
            },
            {
                "name": "Lécera",
                "countryCode": "ES"
            },
            {
                "name": "Maella",
                "countryCode": "ES"
            },
            {
                "name": "Magallón",
                "countryCode": "ES"
            },
            {
                "name": "Maicas",
                "countryCode": "ES"
            },
            {
                "name": "Mainar",
                "countryCode": "ES"
            },
            {
                "name": "Malanquilla",
                "countryCode": "ES"
            },
            {
                "name": "Maleján",
                "countryCode": "ES"
            },
            {
                "name": "Mallén",
                "countryCode": "ES"
            },
            {
                "name": "Maluenda",
                "countryCode": "ES"
            },
            {
                "name": "Malón",
                "countryCode": "ES"
            },
            {
                "name": "Manchones",
                "countryCode": "ES"
            },
            {
                "name": "Manzanera",
                "countryCode": "ES"
            },
            {
                "name": "Mara",
                "countryCode": "ES"
            },
            {
                "name": "Marracos",
                "countryCode": "ES"
            },
            {
                "name": "Martín del Río",
                "countryCode": "ES"
            },
            {
                "name": "María de Huerva",
                "countryCode": "ES"
            },
            {
                "name": "Mas de las Matas",
                "countryCode": "ES"
            },
            {
                "name": "Mazaleón",
                "countryCode": "ES"
            },
            {
                "name": "Mequinensa / Mequinenza",
                "countryCode": "ES"
            },
            {
                "name": "Mesones de Isuela",
                "countryCode": "ES"
            },
            {
                "name": "Mezalocha",
                "countryCode": "ES"
            },
            {
                "name": "Mezquita de Jarque",
                "countryCode": "ES"
            },
            {
                "name": "Mianos",
                "countryCode": "ES"
            },
            {
                "name": "Mirambel",
                "countryCode": "ES"
            },
            {
                "name": "Molinos",
                "countryCode": "ES"
            },
            {
                "name": "Monegrillo",
                "countryCode": "ES"
            },
            {
                "name": "Moneva",
                "countryCode": "ES"
            },
            {
                "name": "Monforte de Moyuela",
                "countryCode": "ES"
            },
            {
                "name": "Monreal de Ariza",
                "countryCode": "ES"
            },
            {
                "name": "Monreal del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Monroyo",
                "countryCode": "ES"
            },
            {
                "name": "Montalbán",
                "countryCode": "ES"
            },
            {
                "name": "Monteagudo del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Montecanal",
                "countryCode": "ES"
            },
            {
                "name": "Monterde",
                "countryCode": "ES"
            },
            {
                "name": "Monterde de Albarracín",
                "countryCode": "ES"
            },
            {
                "name": "Montón",
                "countryCode": "ES"
            },
            {
                "name": "Monzón",
                "countryCode": "ES"
            },
            {
                "name": "Mora de Rubielos",
                "countryCode": "ES"
            },
            {
                "name": "Morata de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Morata de Jiloca",
                "countryCode": "ES"
            },
            {
                "name": "Moros",
                "countryCode": "ES"
            },
            {
                "name": "Morés",
                "countryCode": "ES"
            },
            {
                "name": "Moscardón",
                "countryCode": "ES"
            },
            {
                "name": "Mosqueruela",
                "countryCode": "ES"
            },
            {
                "name": "Moyuela",
                "countryCode": "ES"
            },
            {
                "name": "Mozota",
                "countryCode": "ES"
            },
            {
                "name": "Muel",
                "countryCode": "ES"
            },
            {
                "name": "Muniesa",
                "countryCode": "ES"
            },
            {
                "name": "Munébrega",
                "countryCode": "ES"
            },
            {
                "name": "Murero",
                "countryCode": "ES"
            },
            {
                "name": "Naval",
                "countryCode": "ES"
            },
            {
                "name": "Navardún",
                "countryCode": "ES"
            },
            {
                "name": "Nigüella",
                "countryCode": "ES"
            },
            {
                "name": "Nogueras",
                "countryCode": "ES"
            },
            {
                "name": "Nogueruelas",
                "countryCode": "ES"
            },
            {
                "name": "Nombrevilla",
                "countryCode": "ES"
            },
            {
                "name": "Nonaspe",
                "countryCode": "ES"
            },
            {
                "name": "Novales",
                "countryCode": "ES"
            },
            {
                "name": "Novallas",
                "countryCode": "ES"
            },
            {
                "name": "Novillas",
                "countryCode": "ES"
            },
            {
                "name": "Nueno",
                "countryCode": "ES"
            },
            {
                "name": "Nuez de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Nuévalos",
                "countryCode": "ES"
            },
            {
                "name": "Obón",
                "countryCode": "ES"
            },
            {
                "name": "Odón",
                "countryCode": "ES"
            },
            {
                "name": "Ojos Negros",
                "countryCode": "ES"
            },
            {
                "name": "Olba",
                "countryCode": "ES"
            },
            {
                "name": "Oliete",
                "countryCode": "ES"
            },
            {
                "name": "Oliver-Valdefierro",
                "countryCode": "ES"
            },
            {
                "name": "Olvés",
                "countryCode": "ES"
            },
            {
                "name": "Ontiñena",
                "countryCode": "ES"
            },
            {
                "name": "Orcajo",
                "countryCode": "ES"
            },
            {
                "name": "Orera",
                "countryCode": "ES"
            },
            {
                "name": "Orihuela del Tremedal",
                "countryCode": "ES"
            },
            {
                "name": "Orrios",
                "countryCode": "ES"
            },
            {
                "name": "Orés",
                "countryCode": "ES"
            },
            {
                "name": "Oseja",
                "countryCode": "ES"
            },
            {
                "name": "Palo",
                "countryCode": "ES"
            },
            {
                "name": "Palomar de Arroyos",
                "countryCode": "ES"
            },
            {
                "name": "Pancrudo",
                "countryCode": "ES"
            },
            {
                "name": "Paniza",
                "countryCode": "ES"
            },
            {
                "name": "Paracuellos de Jiloca",
                "countryCode": "ES"
            },
            {
                "name": "Paracuellos de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Pedrola",
                "countryCode": "ES"
            },
            {
                "name": "Peracense",
                "countryCode": "ES"
            },
            {
                "name": "Peralejos",
                "countryCode": "ES"
            },
            {
                "name": "Perales del Alfambra",
                "countryCode": "ES"
            },
            {
                "name": "Peraltilla",
                "countryCode": "ES"
            },
            {
                "name": "Perdiguera",
                "countryCode": "ES"
            },
            {
                "name": "Pertusa",
                "countryCode": "ES"
            },
            {
                "name": "Peñalba",
                "countryCode": "ES"
            },
            {
                "name": "Piedratajada",
                "countryCode": "ES"
            },
            {
                "name": "Pina de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Pinseque",
                "countryCode": "ES"
            },
            {
                "name": "Piracés",
                "countryCode": "ES"
            },
            {
                "name": "Pitarque",
                "countryCode": "ES"
            },
            {
                "name": "Plan",
                "countryCode": "ES"
            },
            {
                "name": "Plasencia de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Pleitas",
                "countryCode": "ES"
            },
            {
                "name": "Plenas",
                "countryCode": "ES"
            },
            {
                "name": "Plou",
                "countryCode": "ES"
            },
            {
                "name": "Poleñino",
                "countryCode": "ES"
            },
            {
                "name": "Pomer",
                "countryCode": "ES"
            },
            {
                "name": "Pozondón",
                "countryCode": "ES"
            },
            {
                "name": "Pozuel de Ariza",
                "countryCode": "ES"
            },
            {
                "name": "Pozuel del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Pozán de Vero",
                "countryCode": "ES"
            },
            {
                "name": "Pradilla de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Huesca",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Teruel",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Zaragoza",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Albortón",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Alfindén",
                "countryCode": "ES"
            },
            {
                "name": "Puente la Reina de Jaca",
                "countryCode": "ES"
            },
            {
                "name": "Puertomingalvo",
                "countryCode": "ES"
            },
            {
                "name": "Pueyo de Santa Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Purujosa",
                "countryCode": "ES"
            },
            {
                "name": "Puértolas",
                "countryCode": "ES"
            },
            {
                "name": "Quicena",
                "countryCode": "ES"
            },
            {
                "name": "Quinto",
                "countryCode": "ES"
            },
            {
                "name": "Remolinos",
                "countryCode": "ES"
            },
            {
                "name": "Retascón",
                "countryCode": "ES"
            },
            {
                "name": "Ricla",
                "countryCode": "ES"
            },
            {
                "name": "Rillo",
                "countryCode": "ES"
            },
            {
                "name": "Riodeva",
                "countryCode": "ES"
            },
            {
                "name": "Robres",
                "countryCode": "ES"
            },
            {
                "name": "Romanos",
                "countryCode": "ES"
            },
            {
                "name": "Royuela",
                "countryCode": "ES"
            },
            {
                "name": "Rubiales",
                "countryCode": "ES"
            },
            {
                "name": "Rubielos de Mora",
                "countryCode": "ES"
            },
            {
                "name": "Rubielos de la Cérida",
                "countryCode": "ES"
            },
            {
                "name": "Ruesca",
                "countryCode": "ES"
            },
            {
                "name": "Ráfales",
                "countryCode": "ES"
            },
            {
                "name": "Ródenas",
                "countryCode": "ES"
            },
            {
                "name": "Sabiñánigo",
                "countryCode": "ES"
            },
            {
                "name": "Sahún",
                "countryCode": "ES"
            },
            {
                "name": "Salas Altas",
                "countryCode": "ES"
            },
            {
                "name": "Salas Bajas",
                "countryCode": "ES"
            },
            {
                "name": "Salcedillo",
                "countryCode": "ES"
            },
            {
                "name": "Saldón",
                "countryCode": "ES"
            },
            {
                "name": "Salillas",
                "countryCode": "ES"
            },
            {
                "name": "Salillas de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Sallent de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Salvatierra de Esca",
                "countryCode": "ES"
            },
            {
                "name": "Samper de Calanda",
                "countryCode": "ES"
            },
            {
                "name": "Samper del Salz",
                "countryCode": "ES"
            },
            {
                "name": "San Agustín",
                "countryCode": "ES"
            },
            {
                "name": "San Martín del Río",
                "countryCode": "ES"
            },
            {
                "name": "San Mateo de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Sangarrén",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Grío",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Moncayo",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Nogueras",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de la Serós",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eulalia",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eulalia de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Santed",
                "countryCode": "ES"
            },
            {
                "name": "Sariñena",
                "countryCode": "ES"
            },
            {
                "name": "Sarrión",
                "countryCode": "ES"
            },
            {
                "name": "Secastilla",
                "countryCode": "ES"
            },
            {
                "name": "Sediles",
                "countryCode": "ES"
            },
            {
                "name": "Segura de los Baños",
                "countryCode": "ES"
            },
            {
                "name": "Seira",
                "countryCode": "ES"
            },
            {
                "name": "Seno",
                "countryCode": "ES"
            },
            {
                "name": "Senés de Alcubierre",
                "countryCode": "ES"
            },
            {
                "name": "Sesa",
                "countryCode": "ES"
            },
            {
                "name": "Sestrica",
                "countryCode": "ES"
            },
            {
                "name": "Sesué",
                "countryCode": "ES"
            },
            {
                "name": "Sierra de Luna",
                "countryCode": "ES"
            },
            {
                "name": "Sigüés",
                "countryCode": "ES"
            },
            {
                "name": "Singra",
                "countryCode": "ES"
            },
            {
                "name": "Sisamón",
                "countryCode": "ES"
            },
            {
                "name": "Siétamo",
                "countryCode": "ES"
            },
            {
                "name": "Sobradiel",
                "countryCode": "ES"
            },
            {
                "name": "Sos del Rey Católico",
                "countryCode": "ES"
            },
            {
                "name": "Sádaba",
                "countryCode": "ES"
            },
            {
                "name": "Sástago",
                "countryCode": "ES"
            },
            {
                "name": "Tabuenca",
                "countryCode": "ES"
            },
            {
                "name": "Talamantes",
                "countryCode": "ES"
            },
            {
                "name": "Tamarit de Llitera / Tamarite de Litera",
                "countryCode": "ES"
            },
            {
                "name": "Tarazona",
                "countryCode": "ES"
            },
            {
                "name": "Tardienta",
                "countryCode": "ES"
            },
            {
                "name": "Tauste",
                "countryCode": "ES"
            },
            {
                "name": "Terrer",
                "countryCode": "ES"
            },
            {
                "name": "Terriente",
                "countryCode": "ES"
            },
            {
                "name": "Teruel",
                "countryCode": "ES"
            },
            {
                "name": "Tierz",
                "countryCode": "ES"
            },
            {
                "name": "Tobed",
                "countryCode": "ES"
            },
            {
                "name": "Tormón",
                "countryCode": "ES"
            },
            {
                "name": "Tornos",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de Ribota",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de los Frailes",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de los Sisones",
                "countryCode": "ES"
            },
            {
                "name": "Torralbilla",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Arcas",
                "countryCode": "ES"
            },
            {
                "name": "Torre de las Arcas",
                "countryCode": "ES"
            },
            {
                "name": "Torre del Compte",
                "countryCode": "ES"
            },
            {
                "name": "Torre los Negros",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de Alcañiz",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla del Rebollar",
                "countryCode": "ES"
            },
            {
                "name": "Torrehermosa",
                "countryCode": "ES"
            },
            {
                "name": "Torrelapaja",
                "countryCode": "ES"
            },
            {
                "name": "Torrellas",
                "countryCode": "ES"
            },
            {
                "name": "Torres de Albarracín",
                "countryCode": "ES"
            },
            {
                "name": "Torres de Alcanadre",
                "countryCode": "ES"
            },
            {
                "name": "Torres de Barbués",
                "countryCode": "ES"
            },
            {
                "name": "Torres de Berrellén",
                "countryCode": "ES"
            },
            {
                "name": "Torrevelilla",
                "countryCode": "ES"
            },
            {
                "name": "Torrijas",
                "countryCode": "ES"
            },
            {
                "name": "Torrijo de la Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Torrijo del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Tosos",
                "countryCode": "ES"
            },
            {
                "name": "Tramacastiel",
                "countryCode": "ES"
            },
            {
                "name": "Tramacastilla",
                "countryCode": "ES"
            },
            {
                "name": "Tramaced",
                "countryCode": "ES"
            },
            {
                "name": "Trasmoz",
                "countryCode": "ES"
            },
            {
                "name": "Trasobares",
                "countryCode": "ES"
            },
            {
                "name": "Tronchón",
                "countryCode": "ES"
            },
            {
                "name": "Uncastillo",
                "countryCode": "ES"
            },
            {
                "name": "Undués de Lerda",
                "countryCode": "ES"
            },
            {
                "name": "Urrea de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Urriés",
                "countryCode": "ES"
            },
            {
                "name": "Used",
                "countryCode": "ES"
            },
            {
                "name": "Utebo",
                "countryCode": "ES"
            },
            {
                "name": "Utrillas",
                "countryCode": "ES"
            },
            {
                "name": "Val de San Martín",
                "countryCode": "ES"
            },
            {
                "name": "Valacloche",
                "countryCode": "ES"
            },
            {
                "name": "Valbona",
                "countryCode": "ES"
            },
            {
                "name": "Valdealgorfa",
                "countryCode": "ES"
            },
            {
                "name": "Valdecuenca",
                "countryCode": "ES"
            },
            {
                "name": "Valdehorna",
                "countryCode": "ES"
            },
            {
                "name": "Valdelinares",
                "countryCode": "ES"
            },
            {
                "name": "Valdeltormo",
                "countryCode": "ES"
            },
            {
                "name": "Valderrobres",
                "countryCode": "ES"
            },
            {
                "name": "Valfarta",
                "countryCode": "ES"
            },
            {
                "name": "Valjunquera",
                "countryCode": "ES"
            },
            {
                "name": "Valmadrid",
                "countryCode": "ES"
            },
            {
                "name": "Valpalmas",
                "countryCode": "ES"
            },
            {
                "name": "Valtorres",
                "countryCode": "ES"
            },
            {
                "name": "Veguillas de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Velilla de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Velilla de Jiloca",
                "countryCode": "ES"
            },
            {
                "name": "Vera de Moncayo",
                "countryCode": "ES"
            },
            {
                "name": "Vierlas",
                "countryCode": "ES"
            },
            {
                "name": "Villadoz",
                "countryCode": "ES"
            },
            {
                "name": "Villafeliche",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villahermosa del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de Perejil",
                "countryCode": "ES"
            },
            {
                "name": "Villalengua",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Gállego",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Jiloca",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Sigena",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Rebollar de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villanúa",
                "countryCode": "ES"
            },
            {
                "name": "Villar de los Navarros",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Cobo",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Salz",
                "countryCode": "ES"
            },
            {
                "name": "Villarluengo",
                "countryCode": "ES"
            },
            {
                "name": "Villarquemado",
                "countryCode": "ES"
            },
            {
                "name": "Villarreal de Huerva",
                "countryCode": "ES"
            },
            {
                "name": "Villarroya de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villarroya de los Pinares",
                "countryCode": "ES"
            },
            {
                "name": "Villarroya del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villastar",
                "countryCode": "ES"
            },
            {
                "name": "Villel",
                "countryCode": "ES"
            },
            {
                "name": "Visiedo",
                "countryCode": "ES"
            },
            {
                "name": "Vistabella",
                "countryCode": "ES"
            },
            {
                "name": "Vivel del Río Martín",
                "countryCode": "ES"
            },
            {
                "name": "Yebra de Basa",
                "countryCode": "ES"
            },
            {
                "name": "Yésero",
                "countryCode": "ES"
            },
            {
                "name": "Zaidín",
                "countryCode": "ES"
            },
            {
                "name": "Zaragoza",
                "countryCode": "ES"
            },
            {
                "name": "Zuera",
                "countryCode": "ES"
            },
            {
                "name": "el Torricó / Altorricon",
                "countryCode": "ES"
            },
            {
                "name": "Épila",
                "countryCode": "ES"
            },
            {
                "name": "Ampuero",
                "countryCode": "ES"
            },
            {
                "name": "Arenas de Iguña",
                "countryCode": "ES"
            },
            {
                "name": "Argoños",
                "countryCode": "ES"
            },
            {
                "name": "Arnuero",
                "countryCode": "ES"
            },
            {
                "name": "Arredondo",
                "countryCode": "ES"
            },
            {
                "name": "Bareyo",
                "countryCode": "ES"
            },
            {
                "name": "Bárcena de Cicero",
                "countryCode": "ES"
            },
            {
                "name": "Bárcena de Pie de Concha",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón de Liébana",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón de la Sal",
                "countryCode": "ES"
            },
            {
                "name": "Camargo",
                "countryCode": "ES"
            },
            {
                "name": "Cartes",
                "countryCode": "ES"
            },
            {
                "name": "Castro-Urdiales",
                "countryCode": "ES"
            },
            {
                "name": "Colindres",
                "countryCode": "ES"
            },
            {
                "name": "Comillas",
                "countryCode": "ES"
            },
            {
                "name": "El Astillero",
                "countryCode": "ES"
            },
            {
                "name": "Escalante",
                "countryCode": "ES"
            },
            {
                "name": "Hazas de Cesto",
                "countryCode": "ES"
            },
            {
                "name": "Laredo",
                "countryCode": "ES"
            },
            {
                "name": "Limpias",
                "countryCode": "ES"
            },
            {
                "name": "Los Corrales de Buelna",
                "countryCode": "ES"
            },
            {
                "name": "Luzmela",
                "countryCode": "ES"
            },
            {
                "name": "Mataporquera",
                "countryCode": "ES"
            },
            {
                "name": "Miengo",
                "countryCode": "ES"
            },
            {
                "name": "Molledo",
                "countryCode": "ES"
            },
            {
                "name": "Noja",
                "countryCode": "ES"
            },
            {
                "name": "Penagos",
                "countryCode": "ES"
            },
            {
                "name": "Pesquera",
                "countryCode": "ES"
            },
            {
                "name": "Polanco",
                "countryCode": "ES"
            },
            {
                "name": "Potes",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Cantabria",
                "countryCode": "ES"
            },
            {
                "name": "Puente Viesgo",
                "countryCode": "ES"
            },
            {
                "name": "Ramales de la Victoria",
                "countryCode": "ES"
            },
            {
                "name": "Rasines",
                "countryCode": "ES"
            },
            {
                "name": "Reinosa",
                "countryCode": "ES"
            },
            {
                "name": "Reocín",
                "countryCode": "ES"
            },
            {
                "name": "Ruente",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Elines",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Aguayo",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Meruelo",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro del Romeral",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente de la Barquera",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Bezana",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de Cayón",
                "countryCode": "ES"
            },
            {
                "name": "Santander",
                "countryCode": "ES"
            },
            {
                "name": "Santillana",
                "countryCode": "ES"
            },
            {
                "name": "Santiurde de Reinosa",
                "countryCode": "ES"
            },
            {
                "name": "Santiurde de Toranzo",
                "countryCode": "ES"
            },
            {
                "name": "Santoña",
                "countryCode": "ES"
            },
            {
                "name": "Saro",
                "countryCode": "ES"
            },
            {
                "name": "Selaya",
                "countryCode": "ES"
            },
            {
                "name": "Solórzano",
                "countryCode": "ES"
            },
            {
                "name": "Suances",
                "countryCode": "ES"
            },
            {
                "name": "Torrelavega",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Pas",
                "countryCode": "ES"
            },
            {
                "name": "Villacarriedo",
                "countryCode": "ES"
            },
            {
                "name": "Villaescusa",
                "countryCode": "ES"
            },
            {
                "name": "Villafufre",
                "countryCode": "ES"
            },
            {
                "name": "Benzú",
                "countryCode": "ES"
            },
            {
                "name": "Ceuta",
                "countryCode": "ES"
            },
            {
                "name": "Abengibre",
                "countryCode": "ES"
            },
            {
                "name": "Abenójar",
                "countryCode": "ES"
            },
            {
                "name": "Ablanque",
                "countryCode": "ES"
            },
            {
                "name": "Abánades",
                "countryCode": "ES"
            },
            {
                "name": "Adobes",
                "countryCode": "ES"
            },
            {
                "name": "Agudo",
                "countryCode": "ES"
            },
            {
                "name": "Ajofrín",
                "countryCode": "ES"
            },
            {
                "name": "Alamillo",
                "countryCode": "ES"
            },
            {
                "name": "Alaminos",
                "countryCode": "ES"
            },
            {
                "name": "Alarcón",
                "countryCode": "ES"
            },
            {
                "name": "Alarilla",
                "countryCode": "ES"
            },
            {
                "name": "Alatoz",
                "countryCode": "ES"
            },
            {
                "name": "Albacete",
                "countryCode": "ES"
            },
            {
                "name": "Albaladejo",
                "countryCode": "ES"
            },
            {
                "name": "Albaladejo del Cuende",
                "countryCode": "ES"
            },
            {
                "name": "Albalate de Zorita",
                "countryCode": "ES"
            },
            {
                "name": "Albalate de las Nogueras",
                "countryCode": "ES"
            },
            {
                "name": "Albares",
                "countryCode": "ES"
            },
            {
                "name": "Albarreal de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Albatana",
                "countryCode": "ES"
            },
            {
                "name": "Albendea",
                "countryCode": "ES"
            },
            {
                "name": "Albendiego",
                "countryCode": "ES"
            },
            {
                "name": "Alborea",
                "countryCode": "ES"
            },
            {
                "name": "Alcabón",
                "countryCode": "ES"
            },
            {
                "name": "Alcadozo",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá del Júcar",
                "countryCode": "ES"
            },
            {
                "name": "Alcantud",
                "countryCode": "ES"
            },
            {
                "name": "Alcaraz",
                "countryCode": "ES"
            },
            {
                "name": "Alcañizo",
                "countryCode": "ES"
            },
            {
                "name": "Alcoba",
                "countryCode": "ES"
            },
            {
                "name": "Alcocer",
                "countryCode": "ES"
            },
            {
                "name": "Alcohujate",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea de las Peñas",
                "countryCode": "ES"
            },
            {
                "name": "Alcolea del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Alconchel de la Estrella",
                "countryCode": "ES"
            },
            {
                "name": "Alcoroches",
                "countryCode": "ES"
            },
            {
                "name": "Alcubillas",
                "countryCode": "ES"
            },
            {
                "name": "Alcázar de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Alcázar del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Aldea del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de Barbarroya",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de Guadalajara",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de San Bartolomé",
                "countryCode": "ES"
            },
            {
                "name": "Algar de Mesa",
                "countryCode": "ES"
            },
            {
                "name": "Algarra",
                "countryCode": "ES"
            },
            {
                "name": "Algora",
                "countryCode": "ES"
            },
            {
                "name": "Alhambra",
                "countryCode": "ES"
            },
            {
                "name": "Alhóndiga",
                "countryCode": "ES"
            },
            {
                "name": "Aliaguilla",
                "countryCode": "ES"
            },
            {
                "name": "Alique",
                "countryCode": "ES"
            },
            {
                "name": "Almadrones",
                "countryCode": "ES"
            },
            {
                "name": "Almadén",
                "countryCode": "ES"
            },
            {
                "name": "Almagro",
                "countryCode": "ES"
            },
            {
                "name": "Almansa",
                "countryCode": "ES"
            },
            {
                "name": "Almedina",
                "countryCode": "ES"
            },
            {
                "name": "Almendral de la Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Almodóvar del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Almodóvar del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Almoguera",
                "countryCode": "ES"
            },
            {
                "name": "Almonacid de Zorita",
                "countryCode": "ES"
            },
            {
                "name": "Almonacid del Marquesado",
                "countryCode": "ES"
            },
            {
                "name": "Almorox",
                "countryCode": "ES"
            },
            {
                "name": "Alocén",
                "countryCode": "ES"
            },
            {
                "name": "Alovera",
                "countryCode": "ES"
            },
            {
                "name": "Alpera",
                "countryCode": "ES"
            },
            {
                "name": "Altarejos",
                "countryCode": "ES"
            },
            {
                "name": "Alustante",
                "countryCode": "ES"
            },
            {
                "name": "Anchuras",
                "countryCode": "ES"
            },
            {
                "name": "Anguita",
                "countryCode": "ES"
            },
            {
                "name": "Anquela del Ducado",
                "countryCode": "ES"
            },
            {
                "name": "Anquela del Pedregal",
                "countryCode": "ES"
            },
            {
                "name": "Arandilla del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Aranzueque",
                "countryCode": "ES"
            },
            {
                "name": "Arbancón",
                "countryCode": "ES"
            },
            {
                "name": "Arbeteta",
                "countryCode": "ES"
            },
            {
                "name": "Arcicóllar",
                "countryCode": "ES"
            },
            {
                "name": "Arcos de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Arenas de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Argamasilla de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Argamasilla de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Argecilla",
                "countryCode": "ES"
            },
            {
                "name": "Arguisuelas",
                "countryCode": "ES"
            },
            {
                "name": "Armallones",
                "countryCode": "ES"
            },
            {
                "name": "Armuña de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Arrancacepas",
                "countryCode": "ES"
            },
            {
                "name": "Arroyo de las Fraguas",
                "countryCode": "ES"
            },
            {
                "name": "Atalaya del Cañavate",
                "countryCode": "ES"
            },
            {
                "name": "Atanzón",
                "countryCode": "ES"
            },
            {
                "name": "Atienza",
                "countryCode": "ES"
            },
            {
                "name": "Auñón",
                "countryCode": "ES"
            },
            {
                "name": "Ayna",
                "countryCode": "ES"
            },
            {
                "name": "Azuqueca de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Azután",
                "countryCode": "ES"
            },
            {
                "name": "Añover de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Baides",
                "countryCode": "ES"
            },
            {
                "name": "Balazote",
                "countryCode": "ES"
            },
            {
                "name": "Ballesteros de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Balsa de Ves",
                "countryCode": "ES"
            },
            {
                "name": "Barajas de Melo",
                "countryCode": "ES"
            },
            {
                "name": "Barchín del Hoyo",
                "countryCode": "ES"
            },
            {
                "name": "Barcience",
                "countryCode": "ES"
            },
            {
                "name": "Bargas",
                "countryCode": "ES"
            },
            {
                "name": "Barrax",
                "countryCode": "ES"
            },
            {
                "name": "Barriopedro",
                "countryCode": "ES"
            },
            {
                "name": "Bascuñana de San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Bañuelos",
                "countryCode": "ES"
            },
            {
                "name": "Beamud",
                "countryCode": "ES"
            },
            {
                "name": "Belinchón",
                "countryCode": "ES"
            },
            {
                "name": "Belmonte",
                "countryCode": "ES"
            },
            {
                "name": "Belmontejo",
                "countryCode": "ES"
            },
            {
                "name": "Belvis de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Berninches",
                "countryCode": "ES"
            },
            {
                "name": "Beteta",
                "countryCode": "ES"
            },
            {
                "name": "Bienservida",
                "countryCode": "ES"
            },
            {
                "name": "Bogarra",
                "countryCode": "ES"
            },
            {
                "name": "Bolaños de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Bonete",
                "countryCode": "ES"
            },
            {
                "name": "Boniches",
                "countryCode": "ES"
            },
            {
                "name": "Borox",
                "countryCode": "ES"
            },
            {
                "name": "Brazatortas",
                "countryCode": "ES"
            },
            {
                "name": "Brihuega",
                "countryCode": "ES"
            },
            {
                "name": "Buciegas",
                "countryCode": "ES"
            },
            {
                "name": "Budia",
                "countryCode": "ES"
            },
            {
                "name": "Buenache de Alarcón",
                "countryCode": "ES"
            },
            {
                "name": "Buenaventura",
                "countryCode": "ES"
            },
            {
                "name": "Buendía",
                "countryCode": "ES"
            },
            {
                "name": "Bujalaro",
                "countryCode": "ES"
            },
            {
                "name": "Burguillos de Toledo",
                "countryCode": "ES"
            },
            {
                "name": "Burujón",
                "countryCode": "ES"
            },
            {
                "name": "Bustares",
                "countryCode": "ES"
            },
            {
                "name": "Cabanillas del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas de Yepes",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas de la Sagra",
                "countryCode": "ES"
            },
            {
                "name": "Cabezamesada",
                "countryCode": "ES"
            },
            {
                "name": "Cabezarados",
                "countryCode": "ES"
            },
            {
                "name": "Calera y Chozas",
                "countryCode": "ES"
            },
            {
                "name": "Caleruela",
                "countryCode": "ES"
            },
            {
                "name": "Camarena",
                "countryCode": "ES"
            },
            {
                "name": "Camarenilla",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Altobuey",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Dueñas",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Ranas",
                "countryCode": "ES"
            },
            {
                "name": "Campillos-Paravientos",
                "countryCode": "ES"
            },
            {
                "name": "Campillos-Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Campisábalos",
                "countryCode": "ES"
            },
            {
                "name": "Campo de Criptana",
                "countryCode": "ES"
            },
            {
                "name": "Camuñas",
                "countryCode": "ES"
            },
            {
                "name": "Canalejas del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Canredondo",
                "countryCode": "ES"
            },
            {
                "name": "Cantalojas",
                "countryCode": "ES"
            },
            {
                "name": "Carboneras de Guadazaón",
                "countryCode": "ES"
            },
            {
                "name": "Carcelén",
                "countryCode": "ES"
            },
            {
                "name": "Cardenete",
                "countryCode": "ES"
            },
            {
                "name": "Cardiel de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Carmena",
                "countryCode": "ES"
            },
            {
                "name": "Carranque",
                "countryCode": "ES"
            },
            {
                "name": "Carrascosa",
                "countryCode": "ES"
            },
            {
                "name": "Carrascosa de Haro",
                "countryCode": "ES"
            },
            {
                "name": "Carriches",
                "countryCode": "ES"
            },
            {
                "name": "Carrizosa",
                "countryCode": "ES"
            },
            {
                "name": "Carrión de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Casa de Uceda",
                "countryCode": "ES"
            },
            {
                "name": "Casarrubios del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Casas Ibáñez",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Benítez",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Fernando Alonso",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Garcimolina",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Guijarro",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Haro",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Juan Núñez",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Lázaro",
                "countryCode": "ES"
            },
            {
                "name": "Casas de San Galindo",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Ves",
                "countryCode": "ES"
            },
            {
                "name": "Casas de los Pinos",
                "countryCode": "ES"
            },
            {
                "name": "Casasbuenas",
                "countryCode": "ES"
            },
            {
                "name": "Casasimarro",
                "countryCode": "ES"
            },
            {
                "name": "Caspueñas",
                "countryCode": "ES"
            },
            {
                "name": "Castejón de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Castellar de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "Castellar de la Muela",
                "countryCode": "ES"
            },
            {
                "name": "Castilforte",
                "countryCode": "ES"
            },
            {
                "name": "Castillejo de Iniesta",
                "countryCode": "ES"
            },
            {
                "name": "Castillejo-Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Castillo de Bayuela",
                "countryCode": "ES"
            },
            {
                "name": "Castillo de Garcimuñoz",
                "countryCode": "ES"
            },
            {
                "name": "Castillo-Albaráñez",
                "countryCode": "ES"
            },
            {
                "name": "Castilnuevo",
                "countryCode": "ES"
            },
            {
                "name": "Caudete",
                "countryCode": "ES"
            },
            {
                "name": "Cazalegas",
                "countryCode": "ES"
            },
            {
                "name": "Cañada de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Cañada del Hoyo",
                "countryCode": "ES"
            },
            {
                "name": "Cañamares",
                "countryCode": "ES"
            },
            {
                "name": "Cañaveras",
                "countryCode": "ES"
            },
            {
                "name": "Cañaveruelas",
                "countryCode": "ES"
            },
            {
                "name": "Cañete",
                "countryCode": "ES"
            },
            {
                "name": "Cañizar",
                "countryCode": "ES"
            },
            {
                "name": "Cañizares",
                "countryCode": "ES"
            },
            {
                "name": "Cebolla",
                "countryCode": "ES"
            },
            {
                "name": "Cedillo del Condado",
                "countryCode": "ES"
            },
            {
                "name": "Cendejas de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Cenizate",
                "countryCode": "ES"
            },
            {
                "name": "Centenera",
                "countryCode": "ES"
            },
            {
                "name": "Cervera de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Cervera del Llano",
                "countryCode": "ES"
            },
            {
                "name": "Checa",
                "countryCode": "ES"
            },
            {
                "name": "Chequilla",
                "countryCode": "ES"
            },
            {
                "name": "Chillarón de Cuenca",
                "countryCode": "ES"
            },
            {
                "name": "Chillarón del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Chiloeches",
                "countryCode": "ES"
            },
            {
                "name": "Chinchilla de Monte Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Chozas de Canales",
                "countryCode": "ES"
            },
            {
                "name": "Chueca",
                "countryCode": "ES"
            },
            {
                "name": "Chumillas",
                "countryCode": "ES"
            },
            {
                "name": "Cifuentes",
                "countryCode": "ES"
            },
            {
                "name": "Cincovillas",
                "countryCode": "ES"
            },
            {
                "name": "Ciruelas",
                "countryCode": "ES"
            },
            {
                "name": "Ciruelos",
                "countryCode": "ES"
            },
            {
                "name": "Ciudad Real",
                "countryCode": "ES"
            },
            {
                "name": "Cobeja",
                "countryCode": "ES"
            },
            {
                "name": "Cobeta",
                "countryCode": "ES"
            },
            {
                "name": "Cobisa",
                "countryCode": "ES"
            },
            {
                "name": "Cogollor",
                "countryCode": "ES"
            },
            {
                "name": "Cogolludo",
                "countryCode": "ES"
            },
            {
                "name": "Condemios de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Condemios de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Congostrina",
                "countryCode": "ES"
            },
            {
                "name": "Consuegra",
                "countryCode": "ES"
            },
            {
                "name": "Copernal",
                "countryCode": "ES"
            },
            {
                "name": "Corduente",
                "countryCode": "ES"
            },
            {
                "name": "Corral de Almaguer",
                "countryCode": "ES"
            },
            {
                "name": "Corral de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Corral-Rubio",
                "countryCode": "ES"
            },
            {
                "name": "Cotillas",
                "countryCode": "ES"
            },
            {
                "name": "Cuenca",
                "countryCode": "ES"
            },
            {
                "name": "Cuerva",
                "countryCode": "ES"
            },
            {
                "name": "Cueva del Hierro",
                "countryCode": "ES"
            },
            {
                "name": "Cózar",
                "countryCode": "ES"
            },
            {
                "name": "Daimiel",
                "countryCode": "ES"
            },
            {
                "name": "Domingo Pérez",
                "countryCode": "ES"
            },
            {
                "name": "Dosbarrios",
                "countryCode": "ES"
            },
            {
                "name": "Driebes",
                "countryCode": "ES"
            },
            {
                "name": "Durón",
                "countryCode": "ES"
            },
            {
                "name": "El Bonillo",
                "countryCode": "ES"
            },
            {
                "name": "El Carpio de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "El Casar de Escalona",
                "countryCode": "ES"
            },
            {
                "name": "El Pedernoso",
                "countryCode": "ES"
            },
            {
                "name": "El Provencio",
                "countryCode": "ES"
            },
            {
                "name": "El Puente del Arzobispo",
                "countryCode": "ES"
            },
            {
                "name": "El Real de San Vicente",
                "countryCode": "ES"
            },
            {
                "name": "El Robledo",
                "countryCode": "ES"
            },
            {
                "name": "El Toboso",
                "countryCode": "ES"
            },
            {
                "name": "El Viso de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Elche de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Embid",
                "countryCode": "ES"
            },
            {
                "name": "Enguídanos",
                "countryCode": "ES"
            },
            {
                "name": "Erustes",
                "countryCode": "ES"
            },
            {
                "name": "Escalona",
                "countryCode": "ES"
            },
            {
                "name": "Escalonilla",
                "countryCode": "ES"
            },
            {
                "name": "Escamilla",
                "countryCode": "ES"
            },
            {
                "name": "Escariche",
                "countryCode": "ES"
            },
            {
                "name": "Escopete",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Espinoso del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Esplegares",
                "countryCode": "ES"
            },
            {
                "name": "Esquivias",
                "countryCode": "ES"
            },
            {
                "name": "Establés",
                "countryCode": "ES"
            },
            {
                "name": "Estriégana",
                "countryCode": "ES"
            },
            {
                "name": "Fontanar",
                "countryCode": "ES"
            },
            {
                "name": "Fontanarejo",
                "countryCode": "ES"
            },
            {
                "name": "Fresneda de Altarejos",
                "countryCode": "ES"
            },
            {
                "name": "Fresneda de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Fuembellida",
                "countryCode": "ES"
            },
            {
                "name": "Fuencaliente",
                "countryCode": "ES"
            },
            {
                "name": "Fuencemillán",
                "countryCode": "ES"
            },
            {
                "name": "Fuenllana",
                "countryCode": "ES"
            },
            {
                "name": "Fuensalida",
                "countryCode": "ES"
            },
            {
                "name": "Fuensanta",
                "countryCode": "ES"
            },
            {
                "name": "Fuente de Pedro Naharro",
                "countryCode": "ES"
            },
            {
                "name": "Fuente el Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Fuente-Álamo",
                "countryCode": "ES"
            },
            {
                "name": "Fuentealbilla",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelahiguera de Albatages",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelencina",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelespino de Haro",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelespino de Moya",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelsaz",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelviejo",
                "countryCode": "ES"
            },
            {
                "name": "Fuentenovilla",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes",
                "countryCode": "ES"
            },
            {
                "name": "Fuertescusa",
                "countryCode": "ES"
            },
            {
                "name": "Férez",
                "countryCode": "ES"
            },
            {
                "name": "Gabaldón",
                "countryCode": "ES"
            },
            {
                "name": "Gajanejos",
                "countryCode": "ES"
            },
            {
                "name": "Galve de Sorbe",
                "countryCode": "ES"
            },
            {
                "name": "Galápagos",
                "countryCode": "ES"
            },
            {
                "name": "Garaballa",
                "countryCode": "ES"
            },
            {
                "name": "Gascueña",
                "countryCode": "ES"
            },
            {
                "name": "Gascueña de Bornova",
                "countryCode": "ES"
            },
            {
                "name": "Gerindote",
                "countryCode": "ES"
            },
            {
                "name": "Golosalvo",
                "countryCode": "ES"
            },
            {
                "name": "Graja de Campalbo",
                "countryCode": "ES"
            },
            {
                "name": "Graja de Iniesta",
                "countryCode": "ES"
            },
            {
                "name": "Guadalajara",
                "countryCode": "ES"
            },
            {
                "name": "Guadalmez",
                "countryCode": "ES"
            },
            {
                "name": "Guadamur",
                "countryCode": "ES"
            },
            {
                "name": "Gálvez",
                "countryCode": "ES"
            },
            {
                "name": "Hellín",
                "countryCode": "ES"
            },
            {
                "name": "Henarejos",
                "countryCode": "ES"
            },
            {
                "name": "Henche",
                "countryCode": "ES"
            },
            {
                "name": "Herencia",
                "countryCode": "ES"
            },
            {
                "name": "Herreruela de Oropesa",
                "countryCode": "ES"
            },
            {
                "name": "Herrería",
                "countryCode": "ES"
            },
            {
                "name": "Hiendelaencina",
                "countryCode": "ES"
            },
            {
                "name": "Higueruela",
                "countryCode": "ES"
            },
            {
                "name": "Hijes",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa de San Vicente",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosas de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Hita",
                "countryCode": "ES"
            },
            {
                "name": "Hombrados",
                "countryCode": "ES"
            },
            {
                "name": "Honrubia",
                "countryCode": "ES"
            },
            {
                "name": "Hontanar",
                "countryCode": "ES"
            },
            {
                "name": "Hontanaya",
                "countryCode": "ES"
            },
            {
                "name": "Hontecillas",
                "countryCode": "ES"
            },
            {
                "name": "Hontoba",
                "countryCode": "ES"
            },
            {
                "name": "Horcajo de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "Horche",
                "countryCode": "ES"
            },
            {
                "name": "Hormigos",
                "countryCode": "ES"
            },
            {
                "name": "Hoya-Gonzalo",
                "countryCode": "ES"
            },
            {
                "name": "Huecas",
                "countryCode": "ES"
            },
            {
                "name": "Huelves",
                "countryCode": "ES"
            },
            {
                "name": "Huerta de Valdecarábanos",
                "countryCode": "ES"
            },
            {
                "name": "Huerta de la Obispalía",
                "countryCode": "ES"
            },
            {
                "name": "Huerta del Marquesado",
                "countryCode": "ES"
            },
            {
                "name": "Huertahernando",
                "countryCode": "ES"
            },
            {
                "name": "Huete",
                "countryCode": "ES"
            },
            {
                "name": "Hueva",
                "countryCode": "ES"
            },
            {
                "name": "Humanes",
                "countryCode": "ES"
            },
            {
                "name": "Huélamo",
                "countryCode": "ES"
            },
            {
                "name": "Huérguina",
                "countryCode": "ES"
            },
            {
                "name": "Huérmeces del Cerro",
                "countryCode": "ES"
            },
            {
                "name": "Illana",
                "countryCode": "ES"
            },
            {
                "name": "Illescas",
                "countryCode": "ES"
            },
            {
                "name": "Illán de Vacas",
                "countryCode": "ES"
            },
            {
                "name": "Infantes",
                "countryCode": "ES"
            },
            {
                "name": "Iniesta",
                "countryCode": "ES"
            },
            {
                "name": "Iniéstola",
                "countryCode": "ES"
            },
            {
                "name": "Irueste",
                "countryCode": "ES"
            },
            {
                "name": "Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Jirueque",
                "countryCode": "ES"
            },
            {
                "name": "Jorquera",
                "countryCode": "ES"
            },
            {
                "name": "La Alameda de la Sagra",
                "countryCode": "ES"
            },
            {
                "name": "La Alberca de Záncara",
                "countryCode": "ES"
            },
            {
                "name": "La Calzada de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "La Frontera",
                "countryCode": "ES"
            },
            {
                "name": "La Gineta",
                "countryCode": "ES"
            },
            {
                "name": "La Guardia",
                "countryCode": "ES"
            },
            {
                "name": "La Puebla de Almoradiel",
                "countryCode": "ES"
            },
            {
                "name": "La Puebla de Montalbán",
                "countryCode": "ES"
            },
            {
                "name": "La Pueblanueva",
                "countryCode": "ES"
            },
            {
                "name": "La Roda",
                "countryCode": "ES"
            },
            {
                "name": "La Solana",
                "countryCode": "ES"
            },
            {
                "name": "La Torre de Esteban Hambrán",
                "countryCode": "ES"
            },
            {
                "name": "Lagartera",
                "countryCode": "ES"
            },
            {
                "name": "Laguna del Marquesado",
                "countryCode": "ES"
            },
            {
                "name": "Lagunaseca",
                "countryCode": "ES"
            },
            {
                "name": "Landete",
                "countryCode": "ES"
            },
            {
                "name": "Las Mesas",
                "countryCode": "ES"
            },
            {
                "name": "Las Pedroñeras",
                "countryCode": "ES"
            },
            {
                "name": "Las Ventas de Retamosa",
                "countryCode": "ES"
            },
            {
                "name": "Layos",
                "countryCode": "ES"
            },
            {
                "name": "Ledanca",
                "countryCode": "ES"
            },
            {
                "name": "Ledaña",
                "countryCode": "ES"
            },
            {
                "name": "Leganiel",
                "countryCode": "ES"
            },
            {
                "name": "Letur",
                "countryCode": "ES"
            },
            {
                "name": "Lezuza",
                "countryCode": "ES"
            },
            {
                "name": "Lillo",
                "countryCode": "ES"
            },
            {
                "name": "Liétor",
                "countryCode": "ES"
            },
            {
                "name": "Lominchar",
                "countryCode": "ES"
            },
            {
                "name": "Loranca de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Los Hinojosos",
                "countryCode": "ES"
            },
            {
                "name": "Los Navalmorales",
                "countryCode": "ES"
            },
            {
                "name": "Los Navalucillos",
                "countryCode": "ES"
            },
            {
                "name": "Los Yébenes",
                "countryCode": "ES"
            },
            {
                "name": "Lucillos",
                "countryCode": "ES"
            },
            {
                "name": "Lupiana",
                "countryCode": "ES"
            },
            {
                "name": "Luzaga",
                "countryCode": "ES"
            },
            {
                "name": "Luzón",
                "countryCode": "ES"
            },
            {
                "name": "Madridejos",
                "countryCode": "ES"
            },
            {
                "name": "Madrigueras",
                "countryCode": "ES"
            },
            {
                "name": "Magán",
                "countryCode": "ES"
            },
            {
                "name": "Mahora",
                "countryCode": "ES"
            },
            {
                "name": "Majaelrayo",
                "countryCode": "ES"
            },
            {
                "name": "Malaguilla",
                "countryCode": "ES"
            },
            {
                "name": "Malagón",
                "countryCode": "ES"
            },
            {
                "name": "Malpica",
                "countryCode": "ES"
            },
            {
                "name": "Mandayona",
                "countryCode": "ES"
            },
            {
                "name": "Mantiel",
                "countryCode": "ES"
            },
            {
                "name": "Manzanares",
                "countryCode": "ES"
            },
            {
                "name": "Manzaneque",
                "countryCode": "ES"
            },
            {
                "name": "Maqueda",
                "countryCode": "ES"
            },
            {
                "name": "Maranchón",
                "countryCode": "ES"
            },
            {
                "name": "Marchamalo",
                "countryCode": "ES"
            },
            {
                "name": "Mariana",
                "countryCode": "ES"
            },
            {
                "name": "Marjaliza",
                "countryCode": "ES"
            },
            {
                "name": "Mascaraque",
                "countryCode": "ES"
            },
            {
                "name": "Masegosa",
                "countryCode": "ES"
            },
            {
                "name": "Masegoso",
                "countryCode": "ES"
            },
            {
                "name": "Masegoso de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Matarrubia",
                "countryCode": "ES"
            },
            {
                "name": "Matillas",
                "countryCode": "ES"
            },
            {
                "name": "Mazarambroz",
                "countryCode": "ES"
            },
            {
                "name": "Mazarete",
                "countryCode": "ES"
            },
            {
                "name": "Mazuecos",
                "countryCode": "ES"
            },
            {
                "name": "Medranda",
                "countryCode": "ES"
            },
            {
                "name": "Megina",
                "countryCode": "ES"
            },
            {
                "name": "Mejorada",
                "countryCode": "ES"
            },
            {
                "name": "Membrilla",
                "countryCode": "ES"
            },
            {
                "name": "Membrillera",
                "countryCode": "ES"
            },
            {
                "name": "Menasalbas",
                "countryCode": "ES"
            },
            {
                "name": "Mestanza",
                "countryCode": "ES"
            },
            {
                "name": "Miedes de Atienza",
                "countryCode": "ES"
            },
            {
                "name": "Miguel Esteban",
                "countryCode": "ES"
            },
            {
                "name": "Miguelturra",
                "countryCode": "ES"
            },
            {
                "name": "Millana",
                "countryCode": "ES"
            },
            {
                "name": "Milmarcos",
                "countryCode": "ES"
            },
            {
                "name": "Minaya",
                "countryCode": "ES"
            },
            {
                "name": "Minglanilla",
                "countryCode": "ES"
            },
            {
                "name": "Mira",
                "countryCode": "ES"
            },
            {
                "name": "Mirabueno",
                "countryCode": "ES"
            },
            {
                "name": "Miralrío",
                "countryCode": "ES"
            },
            {
                "name": "Mocejón",
                "countryCode": "ES"
            },
            {
                "name": "Mochales",
                "countryCode": "ES"
            },
            {
                "name": "Mohedas de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Mohernando",
                "countryCode": "ES"
            },
            {
                "name": "Molina de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Molinicos",
                "countryCode": "ES"
            },
            {
                "name": "Monasterio",
                "countryCode": "ES"
            },
            {
                "name": "Mondéjar",
                "countryCode": "ES"
            },
            {
                "name": "Monreal del Llano",
                "countryCode": "ES"
            },
            {
                "name": "Montalbanejo",
                "countryCode": "ES"
            },
            {
                "name": "Montalbo",
                "countryCode": "ES"
            },
            {
                "name": "Montalvos",
                "countryCode": "ES"
            },
            {
                "name": "Montarrón",
                "countryCode": "ES"
            },
            {
                "name": "Monteagudo de las Salinas",
                "countryCode": "ES"
            },
            {
                "name": "Montealegre del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Montearagón",
                "countryCode": "ES"
            },
            {
                "name": "Montesclaros",
                "countryCode": "ES"
            },
            {
                "name": "Montiel",
                "countryCode": "ES"
            },
            {
                "name": "Mora",
                "countryCode": "ES"
            },
            {
                "name": "Moral de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Moratilla de los Meleros",
                "countryCode": "ES"
            },
            {
                "name": "Morenilla",
                "countryCode": "ES"
            },
            {
                "name": "Mota de Altarejos",
                "countryCode": "ES"
            },
            {
                "name": "Mota del Cuervo",
                "countryCode": "ES"
            },
            {
                "name": "Motilla del Palancar",
                "countryCode": "ES"
            },
            {
                "name": "Motilleja",
                "countryCode": "ES"
            },
            {
                "name": "Moya",
                "countryCode": "ES"
            },
            {
                "name": "Muduex",
                "countryCode": "ES"
            },
            {
                "name": "Munera",
                "countryCode": "ES"
            },
            {
                "name": "Málaga del Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Méntrida",
                "countryCode": "ES"
            },
            {
                "name": "Nambroca",
                "countryCode": "ES"
            },
            {
                "name": "Narboneta",
                "countryCode": "ES"
            },
            {
                "name": "Navahermosa",
                "countryCode": "ES"
            },
            {
                "name": "Navalcán",
                "countryCode": "ES"
            },
            {
                "name": "Navalmoralejo",
                "countryCode": "ES"
            },
            {
                "name": "Navalpino",
                "countryCode": "ES"
            },
            {
                "name": "Navamorcuende",
                "countryCode": "ES"
            },
            {
                "name": "Navas de Estena",
                "countryCode": "ES"
            },
            {
                "name": "Navas de Jorquera",
                "countryCode": "ES"
            },
            {
                "name": "Negredo",
                "countryCode": "ES"
            },
            {
                "name": "Nerpio",
                "countryCode": "ES"
            },
            {
                "name": "Noblejas",
                "countryCode": "ES"
            },
            {
                "name": "Noez",
                "countryCode": "ES"
            },
            {
                "name": "Nombela",
                "countryCode": "ES"
            },
            {
                "name": "Novés",
                "countryCode": "ES"
            },
            {
                "name": "Numancia de la Sagra",
                "countryCode": "ES"
            },
            {
                "name": "Nuño Gómez",
                "countryCode": "ES"
            },
            {
                "name": "Ocaña",
                "countryCode": "ES"
            },
            {
                "name": "Ocentejo",
                "countryCode": "ES"
            },
            {
                "name": "Olivares de Júcar",
                "countryCode": "ES"
            },
            {
                "name": "Olmeda de Cobeta",
                "countryCode": "ES"
            },
            {
                "name": "Olmeda de la Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "Olmeda del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Olmedilla de Alarcón",
                "countryCode": "ES"
            },
            {
                "name": "Olmedilla de Eliz",
                "countryCode": "ES"
            },
            {
                "name": "Olías del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Ontur",
                "countryCode": "ES"
            },
            {
                "name": "Ontígola",
                "countryCode": "ES"
            },
            {
                "name": "Orea",
                "countryCode": "ES"
            },
            {
                "name": "Orgaz",
                "countryCode": "ES"
            },
            {
                "name": "Oropesa",
                "countryCode": "ES"
            },
            {
                "name": "Osa de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Ossa de Montiel",
                "countryCode": "ES"
            },
            {
                "name": "Otero",
                "countryCode": "ES"
            },
            {
                "name": "Pajaroncillo",
                "countryCode": "ES"
            },
            {
                "name": "Pajarón",
                "countryCode": "ES"
            },
            {
                "name": "Palomares del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Palomeque",
                "countryCode": "ES"
            },
            {
                "name": "Pantoja",
                "countryCode": "ES"
            },
            {
                "name": "Paracuellos",
                "countryCode": "ES"
            },
            {
                "name": "Paredes",
                "countryCode": "ES"
            },
            {
                "name": "Paredes de Escalona",
                "countryCode": "ES"
            },
            {
                "name": "Paredes de Sigüenza",
                "countryCode": "ES"
            },
            {
                "name": "Pareja",
                "countryCode": "ES"
            },
            {
                "name": "Parrillas",
                "countryCode": "ES"
            },
            {
                "name": "Pastrana",
                "countryCode": "ES"
            },
            {
                "name": "Paterna del Madera",
                "countryCode": "ES"
            },
            {
                "name": "Pedro Muñoz",
                "countryCode": "ES"
            },
            {
                "name": "Pelahustán",
                "countryCode": "ES"
            },
            {
                "name": "Peralejos de las Truchas",
                "countryCode": "ES"
            },
            {
                "name": "Peralveche",
                "countryCode": "ES"
            },
            {
                "name": "Peñalver",
                "countryCode": "ES"
            },
            {
                "name": "Peñalén",
                "countryCode": "ES"
            },
            {
                "name": "Peñas de San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Picón",
                "countryCode": "ES"
            },
            {
                "name": "Piedrabuena",
                "countryCode": "ES"
            },
            {
                "name": "Pinarejo",
                "countryCode": "ES"
            },
            {
                "name": "Pineda de Gigüela",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla de Molina",
                "countryCode": "ES"
            },
            {
                "name": "Pioz",
                "countryCode": "ES"
            },
            {
                "name": "Piqueras",
                "countryCode": "ES"
            },
            {
                "name": "Piqueras del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Poblete",
                "countryCode": "ES"
            },
            {
                "name": "Polán",
                "countryCode": "ES"
            },
            {
                "name": "Portilla",
                "countryCode": "ES"
            },
            {
                "name": "Portillo de Toledo",
                "countryCode": "ES"
            },
            {
                "name": "Porzuna",
                "countryCode": "ES"
            },
            {
                "name": "Poveda de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Povedilla",
                "countryCode": "ES"
            },
            {
                "name": "Pozo de Almoguera",
                "countryCode": "ES"
            },
            {
                "name": "Pozo de Guadalajara",
                "countryCode": "ES"
            },
            {
                "name": "Pozo-Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Pozoamargo",
                "countryCode": "ES"
            },
            {
                "name": "Pozohondo",
                "countryCode": "ES"
            },
            {
                "name": "Pozorrubio",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Prados Redondos",
                "countryCode": "ES"
            },
            {
                "name": "Priego",
                "countryCode": "ES"
            },
            {
                "name": "Province of Toledo",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Albacete",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Ciudad Real",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Cuenca",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Guadalajara",
                "countryCode": "ES"
            },
            {
                "name": "Prádena de Atienza",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Almenara",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Beleña",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Don Rodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Puebla del Príncipe",
                "countryCode": "ES"
            },
            {
                "name": "Puebla del Salvador",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Lápice",
                "countryCode": "ES"
            },
            {
                "name": "Puerto de San Vicente",
                "countryCode": "ES"
            },
            {
                "name": "Puertollano",
                "countryCode": "ES"
            },
            {
                "name": "Pulgar",
                "countryCode": "ES"
            },
            {
                "name": "Pálmaces de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Pétrola",
                "countryCode": "ES"
            },
            {
                "name": "Quer",
                "countryCode": "ES"
            },
            {
                "name": "Quero",
                "countryCode": "ES"
            },
            {
                "name": "Quintanar de la Orden",
                "countryCode": "ES"
            },
            {
                "name": "Quintanar del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Quismondo",
                "countryCode": "ES"
            },
            {
                "name": "Rada de Haro",
                "countryCode": "ES"
            },
            {
                "name": "Rebollosa de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Recas",
                "countryCode": "ES"
            },
            {
                "name": "Renera",
                "countryCode": "ES"
            },
            {
                "name": "Retiendas",
                "countryCode": "ES"
            },
            {
                "name": "Retuerta de Bullaque",
                "countryCode": "ES"
            },
            {
                "name": "Reíllo",
                "countryCode": "ES"
            },
            {
                "name": "Riba de Saelices",
                "countryCode": "ES"
            },
            {
                "name": "Rielves",
                "countryCode": "ES"
            },
            {
                "name": "Rillo de Gallo",
                "countryCode": "ES"
            },
            {
                "name": "Riópar",
                "countryCode": "ES"
            },
            {
                "name": "Robledillo de Mohernando",
                "countryCode": "ES"
            },
            {
                "name": "Robledo",
                "countryCode": "ES"
            },
            {
                "name": "Robledo de Corpes",
                "countryCode": "ES"
            },
            {
                "name": "Romanillos de Atienza",
                "countryCode": "ES"
            },
            {
                "name": "Romanones",
                "countryCode": "ES"
            },
            {
                "name": "Rozalén del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Rueda de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Ruidera",
                "countryCode": "ES"
            },
            {
                "name": "Sacecorbo",
                "countryCode": "ES"
            },
            {
                "name": "Saceda-Trasierra",
                "countryCode": "ES"
            },
            {
                "name": "Sacedón",
                "countryCode": "ES"
            },
            {
                "name": "Saceruela",
                "countryCode": "ES"
            },
            {
                "name": "Saelices",
                "countryCode": "ES"
            },
            {
                "name": "Saelices de la Sal",
                "countryCode": "ES"
            },
            {
                "name": "Salinas del Manzano",
                "countryCode": "ES"
            },
            {
                "name": "Salmerón",
                "countryCode": "ES"
            },
            {
                "name": "Salobre",
                "countryCode": "ES"
            },
            {
                "name": "Salvacañete",
                "countryCode": "ES"
            },
            {
                "name": "San Andrés del Congosto",
                "countryCode": "ES"
            },
            {
                "name": "San Andrés del Rey",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé de las Abiertas",
                "countryCode": "ES"
            },
            {
                "name": "San Carlos del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Clemente",
                "countryCode": "ES"
            },
            {
                "name": "San Lorenzo de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "San Lorenzo de la Parrilla",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Boniches",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Montalbán",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Pusa",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro Palmiches",
                "countryCode": "ES"
            },
            {
                "name": "Santa Ana de Pusa",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Moya",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Mudela",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de la Zarza",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de los Cáñamos",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz del Retamar",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Campo Rus",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Val",
                "countryCode": "ES"
            },
            {
                "name": "Santa Olalla",
                "countryCode": "ES"
            },
            {
                "name": "Santiuste",
                "countryCode": "ES"
            },
            {
                "name": "Sartajada",
                "countryCode": "ES"
            },
            {
                "name": "Sayatón",
                "countryCode": "ES"
            },
            {
                "name": "Saúca",
                "countryCode": "ES"
            },
            {
                "name": "Segurilla",
                "countryCode": "ES"
            },
            {
                "name": "Selas",
                "countryCode": "ES"
            },
            {
                "name": "Semillas",
                "countryCode": "ES"
            },
            {
                "name": "Seseña",
                "countryCode": "ES"
            },
            {
                "name": "Setiles",
                "countryCode": "ES"
            },
            {
                "name": "Sevilleja de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Sienes",
                "countryCode": "ES"
            },
            {
                "name": "Sigüenza",
                "countryCode": "ES"
            },
            {
                "name": "Sisante",
                "countryCode": "ES"
            },
            {
                "name": "Socovos",
                "countryCode": "ES"
            },
            {
                "name": "Socuéllamos",
                "countryCode": "ES"
            },
            {
                "name": "Solanillos del Extremo",
                "countryCode": "ES"
            },
            {
                "name": "Somolinos",
                "countryCode": "ES"
            },
            {
                "name": "Sonseca",
                "countryCode": "ES"
            },
            {
                "name": "Sotillo de las Palomas",
                "countryCode": "ES"
            },
            {
                "name": "Sotodosos",
                "countryCode": "ES"
            },
            {
                "name": "Talavera de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Tamajón",
                "countryCode": "ES"
            },
            {
                "name": "Taragudo",
                "countryCode": "ES"
            },
            {
                "name": "Tarancón",
                "countryCode": "ES"
            },
            {
                "name": "Taravilla",
                "countryCode": "ES"
            },
            {
                "name": "Tarazona de la Mancha",
                "countryCode": "ES"
            },
            {
                "name": "Tartanedo",
                "countryCode": "ES"
            },
            {
                "name": "Tejadillos",
                "countryCode": "ES"
            },
            {
                "name": "Tembleque",
                "countryCode": "ES"
            },
            {
                "name": "Tendilla",
                "countryCode": "ES"
            },
            {
                "name": "Terrinches",
                "countryCode": "ES"
            },
            {
                "name": "Terzaga",
                "countryCode": "ES"
            },
            {
                "name": "Tierzo",
                "countryCode": "ES"
            },
            {
                "name": "Tinajas",
                "countryCode": "ES"
            },
            {
                "name": "Tobarra",
                "countryCode": "ES"
            },
            {
                "name": "Toledo",
                "countryCode": "ES"
            },
            {
                "name": "Tomelloso",
                "countryCode": "ES"
            },
            {
                "name": "Tordellego",
                "countryCode": "ES"
            },
            {
                "name": "Tordelrábano",
                "countryCode": "ES"
            },
            {
                "name": "Tordesilos",
                "countryCode": "ES"
            },
            {
                "name": "Torija",
                "countryCode": "ES"
            },
            {
                "name": "Torralba",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Torralba de Oropesa",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Juan Abad",
                "countryCode": "ES"
            },
            {
                "name": "Torre del Burgo",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Torrecuadrada de Molina",
                "countryCode": "ES"
            },
            {
                "name": "Torrecuadradilla",
                "countryCode": "ES"
            },
            {
                "name": "Torrejoncillo del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Torrejón del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Torremocha de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Torremocha del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Torremocha del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Torremochuela",
                "countryCode": "ES"
            },
            {
                "name": "Torrenueva",
                "countryCode": "ES"
            },
            {
                "name": "Torrico",
                "countryCode": "ES"
            },
            {
                "name": "Torrijos",
                "countryCode": "ES"
            },
            {
                "name": "Torrubia",
                "countryCode": "ES"
            },
            {
                "name": "Torrubia del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Torrubia del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Tortuera",
                "countryCode": "ES"
            },
            {
                "name": "Tortuero",
                "countryCode": "ES"
            },
            {
                "name": "Totanés",
                "countryCode": "ES"
            },
            {
                "name": "Tresjuncos",
                "countryCode": "ES"
            },
            {
                "name": "Tribaldos",
                "countryCode": "ES"
            },
            {
                "name": "Trijueque",
                "countryCode": "ES"
            },
            {
                "name": "Trillo",
                "countryCode": "ES"
            },
            {
                "name": "Turleque",
                "countryCode": "ES"
            },
            {
                "name": "Tébar",
                "countryCode": "ES"
            },
            {
                "name": "Tórtola de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Uceda",
                "countryCode": "ES"
            },
            {
                "name": "Uclés",
                "countryCode": "ES"
            },
            {
                "name": "Ugena",
                "countryCode": "ES"
            },
            {
                "name": "Ujados",
                "countryCode": "ES"
            },
            {
                "name": "Urda",
                "countryCode": "ES"
            },
            {
                "name": "Utande",
                "countryCode": "ES"
            },
            {
                "name": "Uña",
                "countryCode": "ES"
            },
            {
                "name": "Valdarachas",
                "countryCode": "ES"
            },
            {
                "name": "Valdearenas",
                "countryCode": "ES"
            },
            {
                "name": "Valdeavellano",
                "countryCode": "ES"
            },
            {
                "name": "Valdeaveruelo",
                "countryCode": "ES"
            },
            {
                "name": "Valdeconcha",
                "countryCode": "ES"
            },
            {
                "name": "Valdeganga",
                "countryCode": "ES"
            },
            {
                "name": "Valdegrudas",
                "countryCode": "ES"
            },
            {
                "name": "Valdelcubo",
                "countryCode": "ES"
            },
            {
                "name": "Valdemanco del Esteras",
                "countryCode": "ES"
            },
            {
                "name": "Valdemeca",
                "countryCode": "ES"
            },
            {
                "name": "Valdemorillo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Valdemoro-Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Valdeolivas",
                "countryCode": "ES"
            },
            {
                "name": "Valdepeñas",
                "countryCode": "ES"
            },
            {
                "name": "Valdepeñas de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Valderrebollo",
                "countryCode": "ES"
            },
            {
                "name": "Valdesotos",
                "countryCode": "ES"
            },
            {
                "name": "Valdeverdeja",
                "countryCode": "ES"
            },
            {
                "name": "Valenzuela de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Valfermoso de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Valhermoso",
                "countryCode": "ES"
            },
            {
                "name": "Valhermoso de la Fuente",
                "countryCode": "ES"
            },
            {
                "name": "Valmojado",
                "countryCode": "ES"
            },
            {
                "name": "Valsalobre",
                "countryCode": "ES"
            },
            {
                "name": "Valtablado del Río",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Júcar",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de los Arroyos",
                "countryCode": "ES"
            },
            {
                "name": "Valverdejo",
                "countryCode": "ES"
            },
            {
                "name": "Vara de Rey",
                "countryCode": "ES"
            },
            {
                "name": "Vega del Codorno",
                "countryCode": "ES"
            },
            {
                "name": "Velada",
                "countryCode": "ES"
            },
            {
                "name": "Vellisca",
                "countryCode": "ES"
            },
            {
                "name": "Ventas con Peña Aguilera",
                "countryCode": "ES"
            },
            {
                "name": "Viana de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Villa de Don Fadrique",
                "countryCode": "ES"
            },
            {
                "name": "Villa de Ves",
                "countryCode": "ES"
            },
            {
                "name": "Villacañas",
                "countryCode": "ES"
            },
            {
                "name": "Villaconejos de Trabaque",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Villagarcía del Llano",
                "countryCode": "ES"
            },
            {
                "name": "Villagordo del Júcar",
                "countryCode": "ES"
            },
            {
                "name": "Villahermosa",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villalba del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Villalgordo del Marquesado",
                "countryCode": "ES"
            },
            {
                "name": "Villalpardo",
                "countryCode": "ES"
            },
            {
                "name": "Villamalea",
                "countryCode": "ES"
            },
            {
                "name": "Villamanrique",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Calatrava",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "Villamiel de Toledo",
                "countryCode": "ES"
            },
            {
                "name": "Villaminaya",
                "countryCode": "ES"
            },
            {
                "name": "Villamuelas",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Alcardete",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Alcorón",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Argecilla",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Bogas",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de San Carlos",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Fuente",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Villapalacios",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Cañas",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Domingo García",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Olalla",
                "countryCode": "ES"
            },
            {
                "name": "Villar de la Encina",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Humo",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Infantado",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Pozo",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo de Fuentes",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo de Montalbán",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo de la Peñuela",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo-Periesteban",
                "countryCode": "ES"
            },
            {
                "name": "Villares de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Villares del Saz",
                "countryCode": "ES"
            },
            {
                "name": "Villarrobledo",
                "countryCode": "ES"
            },
            {
                "name": "Villarrubia de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "Villarrubia de los Ojos",
                "countryCode": "ES"
            },
            {
                "name": "Villarrubio",
                "countryCode": "ES"
            },
            {
                "name": "Villarta",
                "countryCode": "ES"
            },
            {
                "name": "Villarta de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Villaseca de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Villaseca de Uceda",
                "countryCode": "ES"
            },
            {
                "name": "Villaseca de la Sagra",
                "countryCode": "ES"
            },
            {
                "name": "Villasequilla de Yepes",
                "countryCode": "ES"
            },
            {
                "name": "Villatobas",
                "countryCode": "ES"
            },
            {
                "name": "Villatoya",
                "countryCode": "ES"
            },
            {
                "name": "Villavaliente",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde de Guadalimar",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde y Pasaconsol",
                "countryCode": "ES"
            },
            {
                "name": "Villel de Mesa",
                "countryCode": "ES"
            },
            {
                "name": "Vindel",
                "countryCode": "ES"
            },
            {
                "name": "Viso del Marqués",
                "countryCode": "ES"
            },
            {
                "name": "Viveros",
                "countryCode": "ES"
            },
            {
                "name": "Viñuelas",
                "countryCode": "ES"
            },
            {
                "name": "Yebes",
                "countryCode": "ES"
            },
            {
                "name": "Yebra",
                "countryCode": "ES"
            },
            {
                "name": "Yeles",
                "countryCode": "ES"
            },
            {
                "name": "Yepes",
                "countryCode": "ES"
            },
            {
                "name": "Yeste",
                "countryCode": "ES"
            },
            {
                "name": "Yuncler",
                "countryCode": "ES"
            },
            {
                "name": "Yuncos",
                "countryCode": "ES"
            },
            {
                "name": "Yunquera de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Yélamos de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Yélamos de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Yémeda",
                "countryCode": "ES"
            },
            {
                "name": "Zafra de Záncara",
                "countryCode": "ES"
            },
            {
                "name": "Zafrilla",
                "countryCode": "ES"
            },
            {
                "name": "Zaorejas",
                "countryCode": "ES"
            },
            {
                "name": "Zarza de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Zarzuela",
                "countryCode": "ES"
            },
            {
                "name": "Zarzuela de Jadraque",
                "countryCode": "ES"
            },
            {
                "name": "Zorita de los Canes",
                "countryCode": "ES"
            },
            {
                "name": "Adeje",
                "countryCode": "ES"
            },
            {
                "name": "Agaete",
                "countryCode": "ES"
            },
            {
                "name": "Agulo",
                "countryCode": "ES"
            },
            {
                "name": "Agüimes",
                "countryCode": "ES"
            },
            {
                "name": "Alajeró",
                "countryCode": "ES"
            },
            {
                "name": "Antigua",
                "countryCode": "ES"
            },
            {
                "name": "Arafo",
                "countryCode": "ES"
            },
            {
                "name": "Arona",
                "countryCode": "ES"
            },
            {
                "name": "Arrecife",
                "countryCode": "ES"
            },
            {
                "name": "Artenara",
                "countryCode": "ES"
            },
            {
                "name": "Arucas",
                "countryCode": "ES"
            },
            {
                "name": "Barlovento",
                "countryCode": "ES"
            },
            {
                "name": "Breña Alta",
                "countryCode": "ES"
            },
            {
                "name": "Breña Baja",
                "countryCode": "ES"
            },
            {
                "name": "Buenavista del Norte",
                "countryCode": "ES"
            },
            {
                "name": "Candelaria",
                "countryCode": "ES"
            },
            {
                "name": "Carrizal",
                "countryCode": "ES"
            },
            {
                "name": "Corralejo",
                "countryCode": "ES"
            },
            {
                "name": "Costa Calma",
                "countryCode": "ES"
            },
            {
                "name": "Costa Teguise",
                "countryCode": "ES"
            },
            {
                "name": "Cruce de Arinaga",
                "countryCode": "ES"
            },
            {
                "name": "El Cotillo",
                "countryCode": "ES"
            },
            {
                "name": "El Paso",
                "countryCode": "ES"
            },
            {
                "name": "Fasnia",
                "countryCode": "ES"
            },
            {
                "name": "Firgas",
                "countryCode": "ES"
            },
            {
                "name": "Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Fuencaliente de la Palma",
                "countryCode": "ES"
            },
            {
                "name": "Garachico",
                "countryCode": "ES"
            },
            {
                "name": "Garafía",
                "countryCode": "ES"
            },
            {
                "name": "Granadilla de Abona",
                "countryCode": "ES"
            },
            {
                "name": "Guia",
                "countryCode": "ES"
            },
            {
                "name": "Guía de Isora",
                "countryCode": "ES"
            },
            {
                "name": "Gáldar",
                "countryCode": "ES"
            },
            {
                "name": "Güimar",
                "countryCode": "ES"
            },
            {
                "name": "Haría",
                "countryCode": "ES"
            },
            {
                "name": "Hermigua",
                "countryCode": "ES"
            },
            {
                "name": "Icod de los Vinos",
                "countryCode": "ES"
            },
            {
                "name": "Ingenio",
                "countryCode": "ES"
            },
            {
                "name": "La Guancha",
                "countryCode": "ES"
            },
            {
                "name": "La Laguna",
                "countryCode": "ES"
            },
            {
                "name": "La Matanza de Acentejo",
                "countryCode": "ES"
            },
            {
                "name": "La Oliva",
                "countryCode": "ES"
            },
            {
                "name": "La Orotava",
                "countryCode": "ES"
            },
            {
                "name": "La Victoria de Acentejo",
                "countryCode": "ES"
            },
            {
                "name": "Las Palmas de Gran Canaria",
                "countryCode": "ES"
            },
            {
                "name": "Las Rosas",
                "countryCode": "ES"
            },
            {
                "name": "Lomo de Arico",
                "countryCode": "ES"
            },
            {
                "name": "Los Gigantes",
                "countryCode": "ES"
            },
            {
                "name": "Los Llanos de Aridane",
                "countryCode": "ES"
            },
            {
                "name": "Los Realejos",
                "countryCode": "ES"
            },
            {
                "name": "Los Silos",
                "countryCode": "ES"
            },
            {
                "name": "Maspalomas",
                "countryCode": "ES"
            },
            {
                "name": "Mazo",
                "countryCode": "ES"
            },
            {
                "name": "Mogán",
                "countryCode": "ES"
            },
            {
                "name": "Moya",
                "countryCode": "ES"
            },
            {
                "name": "Mácher",
                "countryCode": "ES"
            },
            {
                "name": "Nazaret",
                "countryCode": "ES"
            },
            {
                "name": "Playa Blanca",
                "countryCode": "ES"
            },
            {
                "name": "Playa de las Américas",
                "countryCode": "ES"
            },
            {
                "name": "Playa del Ingles",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Las Palmas",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Santa Cruz de Tenerife",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Rico",
                "countryCode": "ES"
            },
            {
                "name": "Puerto de la Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Puerto del Carmen",
                "countryCode": "ES"
            },
            {
                "name": "Puerto del Rosario",
                "countryCode": "ES"
            },
            {
                "name": "Punta de Mujeres",
                "countryCode": "ES"
            },
            {
                "name": "Puntagorda",
                "countryCode": "ES"
            },
            {
                "name": "Puntallana",
                "countryCode": "ES"
            },
            {
                "name": "Pájara",
                "countryCode": "ES"
            },
            {
                "name": "Realejo Alto",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé de Tirajana",
                "countryCode": "ES"
            },
            {
                "name": "San Isidro",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de la Rambla",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel De Abona",
                "countryCode": "ES"
            },
            {
                "name": "San Nicolás",
                "countryCode": "ES"
            },
            {
                "name": "San Sebastián de la Gomera",
                "countryCode": "ES"
            },
            {
                "name": "Santa Brígida",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Tenerife",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de la Palma",
                "countryCode": "ES"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "ES"
            },
            {
                "name": "Santa Úrsula",
                "countryCode": "ES"
            },
            {
                "name": "Santiago del Teide",
                "countryCode": "ES"
            },
            {
                "name": "Sauzal",
                "countryCode": "ES"
            },
            {
                "name": "Tacoronte",
                "countryCode": "ES"
            },
            {
                "name": "Tanque",
                "countryCode": "ES"
            },
            {
                "name": "Tazacorte",
                "countryCode": "ES"
            },
            {
                "name": "Tegueste",
                "countryCode": "ES"
            },
            {
                "name": "Teguise",
                "countryCode": "ES"
            },
            {
                "name": "Tejeda",
                "countryCode": "ES"
            },
            {
                "name": "Telde",
                "countryCode": "ES"
            },
            {
                "name": "Teror",
                "countryCode": "ES"
            },
            {
                "name": "Tijarafe",
                "countryCode": "ES"
            },
            {
                "name": "Tinajo",
                "countryCode": "ES"
            },
            {
                "name": "Tuineje",
                "countryCode": "ES"
            },
            {
                "name": "Tías",
                "countryCode": "ES"
            },
            {
                "name": "Vallehermosa",
                "countryCode": "ES"
            },
            {
                "name": "Valleseco",
                "countryCode": "ES"
            },
            {
                "name": "Valsequillo de Gran Canaria",
                "countryCode": "ES"
            },
            {
                "name": "Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Vecindario",
                "countryCode": "ES"
            },
            {
                "name": "Vega de San Mateo",
                "countryCode": "ES"
            },
            {
                "name": "Vilaflor",
                "countryCode": "ES"
            },
            {
                "name": "Yaiza",
                "countryCode": "ES"
            },
            {
                "name": "Abrera",
                "countryCode": "ES"
            },
            {
                "name": "Agramunt",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar de Segarra",
                "countryCode": "ES"
            },
            {
                "name": "Agullana",
                "countryCode": "ES"
            },
            {
                "name": "Aiguafreda",
                "countryCode": "ES"
            },
            {
                "name": "Aiguaviva",
                "countryCode": "ES"
            },
            {
                "name": "Aitona",
                "countryCode": "ES"
            },
            {
                "name": "Albesa",
                "countryCode": "ES"
            },
            {
                "name": "Albons",
                "countryCode": "ES"
            },
            {
                "name": "Alcanar",
                "countryCode": "ES"
            },
            {
                "name": "Alcanó",
                "countryCode": "ES"
            },
            {
                "name": "Alcarràs",
                "countryCode": "ES"
            },
            {
                "name": "Alcoletge",
                "countryCode": "ES"
            },
            {
                "name": "Alcover",
                "countryCode": "ES"
            },
            {
                "name": "Alella",
                "countryCode": "ES"
            },
            {
                "name": "Alfarràs",
                "countryCode": "ES"
            },
            {
                "name": "Alforja",
                "countryCode": "ES"
            },
            {
                "name": "Alfés",
                "countryCode": "ES"
            },
            {
                "name": "Algerri",
                "countryCode": "ES"
            },
            {
                "name": "Alguaire",
                "countryCode": "ES"
            },
            {
                "name": "Alió",
                "countryCode": "ES"
            },
            {
                "name": "Almacelles",
                "countryCode": "ES"
            },
            {
                "name": "Almenar",
                "countryCode": "ES"
            },
            {
                "name": "Almoster",
                "countryCode": "ES"
            },
            {
                "name": "Alpens",
                "countryCode": "ES"
            },
            {
                "name": "Alpicat",
                "countryCode": "ES"
            },
            {
                "name": "Altafulla",
                "countryCode": "ES"
            },
            {
                "name": "Alàs i Cerc",
                "countryCode": "ES"
            },
            {
                "name": "Amposta",
                "countryCode": "ES"
            },
            {
                "name": "Anglesola",
                "countryCode": "ES"
            },
            {
                "name": "Anglès",
                "countryCode": "ES"
            },
            {
                "name": "Arbeca",
                "countryCode": "ES"
            },
            {
                "name": "Arbúcies",
                "countryCode": "ES"
            },
            {
                "name": "Arenys de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Arenys de Munt",
                "countryCode": "ES"
            },
            {
                "name": "Argelaguer",
                "countryCode": "ES"
            },
            {
                "name": "Argentona",
                "countryCode": "ES"
            },
            {
                "name": "Artesa de Segre",
                "countryCode": "ES"
            },
            {
                "name": "Artés",
                "countryCode": "ES"
            },
            {
                "name": "Ascó",
                "countryCode": "ES"
            },
            {
                "name": "Aspa",
                "countryCode": "ES"
            },
            {
                "name": "Avinyó",
                "countryCode": "ES"
            },
            {
                "name": "Badalona",
                "countryCode": "ES"
            },
            {
                "name": "Bagà",
                "countryCode": "ES"
            },
            {
                "name": "Balaguer",
                "countryCode": "ES"
            },
            {
                "name": "Balsareny",
                "countryCode": "ES"
            },
            {
                "name": "Banyoles",
                "countryCode": "ES"
            },
            {
                "name": "Barberà del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Barcelona",
                "countryCode": "ES"
            },
            {
                "name": "Barri Gòtic",
                "countryCode": "ES"
            },
            {
                "name": "Barri de Sant Andreu",
                "countryCode": "ES"
            },
            {
                "name": "Barri de les Corts",
                "countryCode": "ES"
            },
            {
                "name": "Baró de Viver",
                "countryCode": "ES"
            },
            {
                "name": "Batea",
                "countryCode": "ES"
            },
            {
                "name": "Begues",
                "countryCode": "ES"
            },
            {
                "name": "Begur",
                "countryCode": "ES"
            },
            {
                "name": "Bellcaire d'Urgell",
                "countryCode": "ES"
            },
            {
                "name": "Bellprat",
                "countryCode": "ES"
            },
            {
                "name": "Bellpuig",
                "countryCode": "ES"
            },
            {
                "name": "Bellver de Cerdanya",
                "countryCode": "ES"
            },
            {
                "name": "Bellvís",
                "countryCode": "ES"
            },
            {
                "name": "Benifallet",
                "countryCode": "ES"
            },
            {
                "name": "Berga",
                "countryCode": "ES"
            },
            {
                "name": "Besalú",
                "countryCode": "ES"
            },
            {
                "name": "Bescanó",
                "countryCode": "ES"
            },
            {
                "name": "Beuda",
                "countryCode": "ES"
            },
            {
                "name": "Bigues i Riells",
                "countryCode": "ES"
            },
            {
                "name": "Blancafort",
                "countryCode": "ES"
            },
            {
                "name": "Blanes",
                "countryCode": "ES"
            },
            {
                "name": "Bolvir",
                "countryCode": "ES"
            },
            {
                "name": "Bonastre",
                "countryCode": "ES"
            },
            {
                "name": "Bordils",
                "countryCode": "ES"
            },
            {
                "name": "Borrassà",
                "countryCode": "ES"
            },
            {
                "name": "Bot",
                "countryCode": "ES"
            },
            {
                "name": "Botarell",
                "countryCode": "ES"
            },
            {
                "name": "Breda",
                "countryCode": "ES"
            },
            {
                "name": "Bàscara",
                "countryCode": "ES"
            },
            {
                "name": "Cabanelles",
                "countryCode": "ES"
            },
            {
                "name": "Cabrera de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Cabrils",
                "countryCode": "ES"
            },
            {
                "name": "Cadaqués",
                "countryCode": "ES"
            },
            {
                "name": "Calaf",
                "countryCode": "ES"
            },
            {
                "name": "Calafell",
                "countryCode": "ES"
            },
            {
                "name": "Caldes de Montbui",
                "countryCode": "ES"
            },
            {
                "name": "Calella",
                "countryCode": "ES"
            },
            {
                "name": "Calonge",
                "countryCode": "ES"
            },
            {
                "name": "Camarasa",
                "countryCode": "ES"
            },
            {
                "name": "Cambrils",
                "countryCode": "ES"
            },
            {
                "name": "Campdevànol",
                "countryCode": "ES"
            },
            {
                "name": "Campins",
                "countryCode": "ES"
            },
            {
                "name": "Camprodon",
                "countryCode": "ES"
            },
            {
                "name": "Camós",
                "countryCode": "ES"
            },
            {
                "name": "Can Baró",
                "countryCode": "ES"
            },
            {
                "name": "Can Peguera",
                "countryCode": "ES"
            },
            {
                "name": "Canet de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Canovelles",
                "countryCode": "ES"
            },
            {
                "name": "Cantallops",
                "countryCode": "ES"
            },
            {
                "name": "Canyelles",
                "countryCode": "ES"
            },
            {
                "name": "Capafonts",
                "countryCode": "ES"
            },
            {
                "name": "Capellades",
                "countryCode": "ES"
            },
            {
                "name": "Capmany",
                "countryCode": "ES"
            },
            {
                "name": "Cardedeu",
                "countryCode": "ES"
            },
            {
                "name": "Cardona",
                "countryCode": "ES"
            },
            {
                "name": "Cassà de la Selva",
                "countryCode": "ES"
            },
            {
                "name": "Castell-Platja d'Aro",
                "countryCode": "ES"
            },
            {
                "name": "Castellar del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Castellbisbal",
                "countryCode": "ES"
            },
            {
                "name": "Castellcir",
                "countryCode": "ES"
            },
            {
                "name": "Castelldefels",
                "countryCode": "ES"
            },
            {
                "name": "Castellet",
                "countryCode": "ES"
            },
            {
                "name": "Castellfollit de Riubregós",
                "countryCode": "ES"
            },
            {
                "name": "Castellnou de Seana",
                "countryCode": "ES"
            },
            {
                "name": "Castellolí",
                "countryCode": "ES"
            },
            {
                "name": "Castellserà",
                "countryCode": "ES"
            },
            {
                "name": "Castellví de Rosanes",
                "countryCode": "ES"
            },
            {
                "name": "Castelló d'Empúries",
                "countryCode": "ES"
            },
            {
                "name": "Celrà",
                "countryCode": "ES"
            },
            {
                "name": "Centelles",
                "countryCode": "ES"
            },
            {
                "name": "Cerdanyola del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Cervelló",
                "countryCode": "ES"
            },
            {
                "name": "Cervera",
                "countryCode": "ES"
            },
            {
                "name": "Cervià de Ter",
                "countryCode": "ES"
            },
            {
                "name": "Cistella",
                "countryCode": "ES"
            },
            {
                "name": "Ciutadilla",
                "countryCode": "ES"
            },
            {
                "name": "Ciutat Meridiana",
                "countryCode": "ES"
            },
            {
                "name": "Ciutat Vella",
                "countryCode": "ES"
            },
            {
                "name": "Colera",
                "countryCode": "ES"
            },
            {
                "name": "Coll de Nargó",
                "countryCode": "ES"
            },
            {
                "name": "Collbató",
                "countryCode": "ES"
            },
            {
                "name": "Colldejou",
                "countryCode": "ES"
            },
            {
                "name": "Collsuspina",
                "countryCode": "ES"
            },
            {
                "name": "Coma-ruga",
                "countryCode": "ES"
            },
            {
                "name": "Conesa",
                "countryCode": "ES"
            },
            {
                "name": "Constantí",
                "countryCode": "ES"
            },
            {
                "name": "Corbera de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Corbins",
                "countryCode": "ES"
            },
            {
                "name": "Cornellà de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Cornellà del Terri",
                "countryCode": "ES"
            },
            {
                "name": "Creixell",
                "countryCode": "ES"
            },
            {
                "name": "Crespià",
                "countryCode": "ES"
            },
            {
                "name": "Cruïlles",
                "countryCode": "ES"
            },
            {
                "name": "Cubelles",
                "countryCode": "ES"
            },
            {
                "name": "Cubells",
                "countryCode": "ES"
            },
            {
                "name": "Cunit",
                "countryCode": "ES"
            },
            {
                "name": "Cànoves i Samalús",
                "countryCode": "ES"
            },
            {
                "name": "Darnius",
                "countryCode": "ES"
            },
            {
                "name": "Deltebre",
                "countryCode": "ES"
            },
            {
                "name": "Diagonal Mar",
                "countryCode": "ES"
            },
            {
                "name": "Dosrius",
                "countryCode": "ES"
            },
            {
                "name": "Dreta de l'Eixample",
                "countryCode": "ES"
            },
            {
                "name": "Eixample",
                "countryCode": "ES"
            },
            {
                "name": "El Carmel",
                "countryCode": "ES"
            },
            {
                "name": "El Masnou",
                "countryCode": "ES"
            },
            {
                "name": "El Perelló",
                "countryCode": "ES"
            },
            {
                "name": "El Pla de Santa Maria",
                "countryCode": "ES"
            },
            {
                "name": "El Prat de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "El Vendrell",
                "countryCode": "ES"
            },
            {
                "name": "Empuriabrava",
                "countryCode": "ES"
            },
            {
                "name": "Esparreguera",
                "countryCode": "ES"
            },
            {
                "name": "Esplugues de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Espolla",
                "countryCode": "ES"
            },
            {
                "name": "Esponellà",
                "countryCode": "ES"
            },
            {
                "name": "Espot",
                "countryCode": "ES"
            },
            {
                "name": "Falset",
                "countryCode": "ES"
            },
            {
                "name": "Farrera",
                "countryCode": "ES"
            },
            {
                "name": "Figaró",
                "countryCode": "ES"
            },
            {
                "name": "Figueres",
                "countryCode": "ES"
            },
            {
                "name": "Flix",
                "countryCode": "ES"
            },
            {
                "name": "Foixà",
                "countryCode": "ES"
            },
            {
                "name": "Fonollosa",
                "countryCode": "ES"
            },
            {
                "name": "Fornells de la Selva",
                "countryCode": "ES"
            },
            {
                "name": "Fort Pienc",
                "countryCode": "ES"
            },
            {
                "name": "Fortià",
                "countryCode": "ES"
            },
            {
                "name": "Forès",
                "countryCode": "ES"
            },
            {
                "name": "Fulleda",
                "countryCode": "ES"
            },
            {
                "name": "Gallifa",
                "countryCode": "ES"
            },
            {
                "name": "Gandesa",
                "countryCode": "ES"
            },
            {
                "name": "Garcia",
                "countryCode": "ES"
            },
            {
                "name": "Garriguella",
                "countryCode": "ES"
            },
            {
                "name": "Garrigàs",
                "countryCode": "ES"
            },
            {
                "name": "Gavà",
                "countryCode": "ES"
            },
            {
                "name": "Gelida",
                "countryCode": "ES"
            },
            {
                "name": "Ginestar",
                "countryCode": "ES"
            },
            {
                "name": "Girona",
                "countryCode": "ES"
            },
            {
                "name": "Gironella",
                "countryCode": "ES"
            },
            {
                "name": "Golmés",
                "countryCode": "ES"
            },
            {
                "name": "Granera",
                "countryCode": "ES"
            },
            {
                "name": "Granollers",
                "countryCode": "ES"
            },
            {
                "name": "Gràcia",
                "countryCode": "ES"
            },
            {
                "name": "Gualta",
                "countryCode": "ES"
            },
            {
                "name": "Guimerà",
                "countryCode": "ES"
            },
            {
                "name": "Gurb",
                "countryCode": "ES"
            },
            {
                "name": "Gósol",
                "countryCode": "ES"
            },
            {
                "name": "Horta",
                "countryCode": "ES"
            },
            {
                "name": "Horta-Guinardó",
                "countryCode": "ES"
            },
            {
                "name": "Hostafrancs",
                "countryCode": "ES"
            },
            {
                "name": "Hostalric",
                "countryCode": "ES"
            },
            {
                "name": "Igualada",
                "countryCode": "ES"
            },
            {
                "name": "Ivorra",
                "countryCode": "ES"
            },
            {
                "name": "Jafre",
                "countryCode": "ES"
            },
            {
                "name": "Jorba",
                "countryCode": "ES"
            },
            {
                "name": "Juià",
                "countryCode": "ES"
            },
            {
                "name": "Juncosa",
                "countryCode": "ES"
            },
            {
                "name": "Juneda",
                "countryCode": "ES"
            },
            {
                "name": "L'Ametlla del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "L'Ampolla",
                "countryCode": "ES"
            },
            {
                "name": "L'Hospitalet de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "La Bonanova",
                "countryCode": "ES"
            },
            {
                "name": "La Bordeta",
                "countryCode": "ES"
            },
            {
                "name": "La Canonja",
                "countryCode": "ES"
            },
            {
                "name": "La Fuliola",
                "countryCode": "ES"
            },
            {
                "name": "La Granada",
                "countryCode": "ES"
            },
            {
                "name": "La Llagosta",
                "countryCode": "ES"
            },
            {
                "name": "La Pineda",
                "countryCode": "ES"
            },
            {
                "name": "La Pobla de Claramunt",
                "countryCode": "ES"
            },
            {
                "name": "La Roca del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "La Sagrera",
                "countryCode": "ES"
            },
            {
                "name": "La Secuita",
                "countryCode": "ES"
            },
            {
                "name": "La Selva del Camp",
                "countryCode": "ES"
            },
            {
                "name": "La Seu d'Urgell",
                "countryCode": "ES"
            },
            {
                "name": "Las Tres Torres",
                "countryCode": "ES"
            },
            {
                "name": "Les",
                "countryCode": "ES"
            },
            {
                "name": "Les Cases d'Alcanar",
                "countryCode": "ES"
            },
            {
                "name": "Les Corts",
                "countryCode": "ES"
            },
            {
                "name": "Linyola",
                "countryCode": "ES"
            },
            {
                "name": "Lladó",
                "countryCode": "ES"
            },
            {
                "name": "Llagostera",
                "countryCode": "ES"
            },
            {
                "name": "Llambilles",
                "countryCode": "ES"
            },
            {
                "name": "Llançà",
                "countryCode": "ES"
            },
            {
                "name": "Llefià",
                "countryCode": "ES"
            },
            {
                "name": "Lleida",
                "countryCode": "ES"
            },
            {
                "name": "Llers",
                "countryCode": "ES"
            },
            {
                "name": "Lles de Cerdanya",
                "countryCode": "ES"
            },
            {
                "name": "Llimiana",
                "countryCode": "ES"
            },
            {
                "name": "Llinars del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Lliçà d'Amunt",
                "countryCode": "ES"
            },
            {
                "name": "Lloret de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Madremanya",
                "countryCode": "ES"
            },
            {
                "name": "Malgrat de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Manlleu",
                "countryCode": "ES"
            },
            {
                "name": "Manresa",
                "countryCode": "ES"
            },
            {
                "name": "Margalef",
                "countryCode": "ES"
            },
            {
                "name": "Martorell",
                "countryCode": "ES"
            },
            {
                "name": "Mas de Barberans",
                "countryCode": "ES"
            },
            {
                "name": "Masdenverge",
                "countryCode": "ES"
            },
            {
                "name": "Masquefa",
                "countryCode": "ES"
            },
            {
                "name": "Massanes",
                "countryCode": "ES"
            },
            {
                "name": "Matadepera",
                "countryCode": "ES"
            },
            {
                "name": "Mataró",
                "countryCode": "ES"
            },
            {
                "name": "Mediona",
                "countryCode": "ES"
            },
            {
                "name": "Miralcamp",
                "countryCode": "ES"
            },
            {
                "name": "Miravet",
                "countryCode": "ES"
            },
            {
                "name": "Moià",
                "countryCode": "ES"
            },
            {
                "name": "Molins de Rei",
                "countryCode": "ES"
            },
            {
                "name": "Mollerussa",
                "countryCode": "ES"
            },
            {
                "name": "Mollet de Peralada",
                "countryCode": "ES"
            },
            {
                "name": "Mollet del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Monistrol de Montserrat",
                "countryCode": "ES"
            },
            {
                "name": "Mont-roig del Camp",
                "countryCode": "ES"
            },
            {
                "name": "Montbau",
                "countryCode": "ES"
            },
            {
                "name": "Montblanc",
                "countryCode": "ES"
            },
            {
                "name": "Montcada i Reixac",
                "countryCode": "ES"
            },
            {
                "name": "Montferri",
                "countryCode": "ES"
            },
            {
                "name": "Montgat",
                "countryCode": "ES"
            },
            {
                "name": "Montmeló",
                "countryCode": "ES"
            },
            {
                "name": "Montornès del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Mura",
                "countryCode": "ES"
            },
            {
                "name": "Navarcles",
                "countryCode": "ES"
            },
            {
                "name": "Navas",
                "countryCode": "ES"
            },
            {
                "name": "Navata",
                "countryCode": "ES"
            },
            {
                "name": "Nou Barris",
                "countryCode": "ES"
            },
            {
                "name": "Olesa de Bonesvalls",
                "countryCode": "ES"
            },
            {
                "name": "Olesa de Montserrat",
                "countryCode": "ES"
            },
            {
                "name": "Oliana",
                "countryCode": "ES"
            },
            {
                "name": "Olius",
                "countryCode": "ES"
            },
            {
                "name": "Olivella",
                "countryCode": "ES"
            },
            {
                "name": "Olost",
                "countryCode": "ES"
            },
            {
                "name": "Olot",
                "countryCode": "ES"
            },
            {
                "name": "Ordis",
                "countryCode": "ES"
            },
            {
                "name": "Oristà",
                "countryCode": "ES"
            },
            {
                "name": "Orpí",
                "countryCode": "ES"
            },
            {
                "name": "Orís",
                "countryCode": "ES"
            },
            {
                "name": "Os de Balaguer",
                "countryCode": "ES"
            },
            {
                "name": "Ossó de Sió",
                "countryCode": "ES"
            },
            {
                "name": "Palafrugell",
                "countryCode": "ES"
            },
            {
                "name": "Palamós",
                "countryCode": "ES"
            },
            {
                "name": "Palau-sator",
                "countryCode": "ES"
            },
            {
                "name": "Pallejà",
                "countryCode": "ES"
            },
            {
                "name": "Palol de Revardit",
                "countryCode": "ES"
            },
            {
                "name": "Pals",
                "countryCode": "ES"
            },
            {
                "name": "Parets del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Pau",
                "countryCode": "ES"
            },
            {
                "name": "Pedralbes",
                "countryCode": "ES"
            },
            {
                "name": "Piera",
                "countryCode": "ES"
            },
            {
                "name": "Pineda de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Planoles",
                "countryCode": "ES"
            },
            {
                "name": "Poble Sec",
                "countryCode": "ES"
            },
            {
                "name": "Polinyà",
                "countryCode": "ES"
            },
            {
                "name": "Pont de Molins",
                "countryCode": "ES"
            },
            {
                "name": "Pontils",
                "countryCode": "ES"
            },
            {
                "name": "Ponts",
                "countryCode": "ES"
            },
            {
                "name": "Pontós",
                "countryCode": "ES"
            },
            {
                "name": "Porta",
                "countryCode": "ES"
            },
            {
                "name": "Portbou",
                "countryCode": "ES"
            },
            {
                "name": "Premià de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Provenals del Poblenou",
                "countryCode": "ES"
            },
            {
                "name": "Província de Barcelona",
                "countryCode": "ES"
            },
            {
                "name": "Província de Girona",
                "countryCode": "ES"
            },
            {
                "name": "Província de Lleida",
                "countryCode": "ES"
            },
            {
                "name": "Província de Tarragona",
                "countryCode": "ES"
            },
            {
                "name": "Puigcerdà",
                "countryCode": "ES"
            },
            {
                "name": "Puigpelat",
                "countryCode": "ES"
            },
            {
                "name": "Pujalt",
                "countryCode": "ES"
            },
            {
                "name": "Quart d’Onyar",
                "countryCode": "ES"
            },
            {
                "name": "Querol",
                "countryCode": "ES"
            },
            {
                "name": "Rabós",
                "countryCode": "ES"
            },
            {
                "name": "Rajadell",
                "countryCode": "ES"
            },
            {
                "name": "Rasquera",
                "countryCode": "ES"
            },
            {
                "name": "Regencós",
                "countryCode": "ES"
            },
            {
                "name": "Renau",
                "countryCode": "ES"
            },
            {
                "name": "Reus",
                "countryCode": "ES"
            },
            {
                "name": "Ribes de Freser",
                "countryCode": "ES"
            },
            {
                "name": "Ripoll",
                "countryCode": "ES"
            },
            {
                "name": "Ripollet",
                "countryCode": "ES"
            },
            {
                "name": "Riudecanyes",
                "countryCode": "ES"
            },
            {
                "name": "Riudecols",
                "countryCode": "ES"
            },
            {
                "name": "Riudellots de la Selva",
                "countryCode": "ES"
            },
            {
                "name": "Riudoms",
                "countryCode": "ES"
            },
            {
                "name": "Riumors",
                "countryCode": "ES"
            },
            {
                "name": "Roda de Barà",
                "countryCode": "ES"
            },
            {
                "name": "Roses",
                "countryCode": "ES"
            },
            {
                "name": "Rubí",
                "countryCode": "ES"
            },
            {
                "name": "Rupià",
                "countryCode": "ES"
            },
            {
                "name": "S'Agaró",
                "countryCode": "ES"
            },
            {
                "name": "Sabadell",
                "countryCode": "ES"
            },
            {
                "name": "Sagrada Família",
                "countryCode": "ES"
            },
            {
                "name": "Sagàs",
                "countryCode": "ES"
            },
            {
                "name": "Sales de Llierca",
                "countryCode": "ES"
            },
            {
                "name": "Sallent",
                "countryCode": "ES"
            },
            {
                "name": "Salomó",
                "countryCode": "ES"
            },
            {
                "name": "Salou",
                "countryCode": "ES"
            },
            {
                "name": "Salt",
                "countryCode": "ES"
            },
            {
                "name": "Sant Adrià de Besòs",
                "countryCode": "ES"
            },
            {
                "name": "Sant Andreu",
                "countryCode": "ES"
            },
            {
                "name": "Sant Andreu Salou",
                "countryCode": "ES"
            },
            {
                "name": "Sant Andreu de Llavaneres",
                "countryCode": "ES"
            },
            {
                "name": "Sant Andreu de la Barca",
                "countryCode": "ES"
            },
            {
                "name": "Sant Antoni",
                "countryCode": "ES"
            },
            {
                "name": "Sant Boi de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Sant Carles de la Ràpita",
                "countryCode": "ES"
            },
            {
                "name": "Sant Celoni",
                "countryCode": "ES"
            },
            {
                "name": "Sant Cristòfol de les Fonts",
                "countryCode": "ES"
            },
            {
                "name": "Sant Cugat del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Sant Esteve d'en Bas",
                "countryCode": "ES"
            },
            {
                "name": "Sant Feliu de Guíxols",
                "countryCode": "ES"
            },
            {
                "name": "Sant Feliu de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Sant Ferriol",
                "countryCode": "ES"
            },
            {
                "name": "Sant Genís dels Agudells",
                "countryCode": "ES"
            },
            {
                "name": "Sant Gervasi - Galvany",
                "countryCode": "ES"
            },
            {
                "name": "Sant Hilari Sacalm",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan Despí",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan de Mediona",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan de Vilatorrada",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan de les Abadesses",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan les Fonts",
                "countryCode": "ES"
            },
            {
                "name": "Sant Jordi Desvalls",
                "countryCode": "ES"
            },
            {
                "name": "Sant Julià de Cerdanyola",
                "countryCode": "ES"
            },
            {
                "name": "Sant Just Desvern",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí Sarroca",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí Vell",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí de Centelles",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí de Provençals",
                "countryCode": "ES"
            },
            {
                "name": "Sant Martí de Tous",
                "countryCode": "ES"
            },
            {
                "name": "Sant Miquel de Campmajor",
                "countryCode": "ES"
            },
            {
                "name": "Sant Pere Pescador",
                "countryCode": "ES"
            },
            {
                "name": "Sant Pere de Ribes",
                "countryCode": "ES"
            },
            {
                "name": "Sant Pere de Riudebitlles",
                "countryCode": "ES"
            },
            {
                "name": "Sant Pere, Santa Caterina i La Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Sant Pol de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Sant Quirze del Vallès",
                "countryCode": "ES"
            },
            {
                "name": "Sant Sadurní d'Anoia",
                "countryCode": "ES"
            },
            {
                "name": "Sant Salvador de Guardiola",
                "countryCode": "ES"
            },
            {
                "name": "Sant Vicenç de Castellet",
                "countryCode": "ES"
            },
            {
                "name": "Sant Vicenç de Montalt",
                "countryCode": "ES"
            },
            {
                "name": "Sant Vicenç dels Horts",
                "countryCode": "ES"
            },
            {
                "name": "Santa Bárbara",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cecília de Voltregà",
                "countryCode": "ES"
            },
            {
                "name": "Santa Coloma de Cervelló",
                "countryCode": "ES"
            },
            {
                "name": "Santa Coloma de Farners",
                "countryCode": "ES"
            },
            {
                "name": "Santa Coloma de Gramenet",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cristina d'Aro",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eugènia de Berga",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eulàlia de Ronçana",
                "countryCode": "ES"
            },
            {
                "name": "Santa Maria d'Oló",
                "countryCode": "ES"
            },
            {
                "name": "Santa Maria de Corcó",
                "countryCode": "ES"
            },
            {
                "name": "Santa Maria de Palautordera",
                "countryCode": "ES"
            },
            {
                "name": "Santa Oliva",
                "countryCode": "ES"
            },
            {
                "name": "Santa Pau",
                "countryCode": "ES"
            },
            {
                "name": "Santa Perpètua de Mogoda",
                "countryCode": "ES"
            },
            {
                "name": "Santa Susanna",
                "countryCode": "ES"
            },
            {
                "name": "Sants",
                "countryCode": "ES"
            },
            {
                "name": "Sants - Badal",
                "countryCode": "ES"
            },
            {
                "name": "Sants-Montjuïc",
                "countryCode": "ES"
            },
            {
                "name": "Sarrià",
                "countryCode": "ES"
            },
            {
                "name": "Sarrià de Ter",
                "countryCode": "ES"
            },
            {
                "name": "Sarrià-Sant Gervasi",
                "countryCode": "ES"
            },
            {
                "name": "Sentmenat",
                "countryCode": "ES"
            },
            {
                "name": "Serra de Daró",
                "countryCode": "ES"
            },
            {
                "name": "Seròs",
                "countryCode": "ES"
            },
            {
                "name": "Seva",
                "countryCode": "ES"
            },
            {
                "name": "Sidamon",
                "countryCode": "ES"
            },
            {
                "name": "Sils",
                "countryCode": "ES"
            },
            {
                "name": "Sitges",
                "countryCode": "ES"
            },
            {
                "name": "Siurana",
                "countryCode": "ES"
            },
            {
                "name": "Solsona",
                "countryCode": "ES"
            },
            {
                "name": "Sort",
                "countryCode": "ES"
            },
            {
                "name": "Soses",
                "countryCode": "ES"
            },
            {
                "name": "Subirats",
                "countryCode": "ES"
            },
            {
                "name": "Susqueda",
                "countryCode": "ES"
            },
            {
                "name": "Súria",
                "countryCode": "ES"
            },
            {
                "name": "Tagamanent",
                "countryCode": "ES"
            },
            {
                "name": "Talamanca",
                "countryCode": "ES"
            },
            {
                "name": "Taradell",
                "countryCode": "ES"
            },
            {
                "name": "Tarragona",
                "countryCode": "ES"
            },
            {
                "name": "Tavertet",
                "countryCode": "ES"
            },
            {
                "name": "Teià",
                "countryCode": "ES"
            },
            {
                "name": "Terrassa",
                "countryCode": "ES"
            },
            {
                "name": "Tiana",
                "countryCode": "ES"
            },
            {
                "name": "Tiurana",
                "countryCode": "ES"
            },
            {
                "name": "Tivenys",
                "countryCode": "ES"
            },
            {
                "name": "Toloriu",
                "countryCode": "ES"
            },
            {
                "name": "Tona",
                "countryCode": "ES"
            },
            {
                "name": "Tordera",
                "countryCode": "ES"
            },
            {
                "name": "Torelló",
                "countryCode": "ES"
            },
            {
                "name": "Tornabous",
                "countryCode": "ES"
            },
            {
                "name": "Torre Baró",
                "countryCode": "ES"
            },
            {
                "name": "Torredembarra",
                "countryCode": "ES"
            },
            {
                "name": "Torrefarrera",
                "countryCode": "ES"
            },
            {
                "name": "Torrelles de Llobregat",
                "countryCode": "ES"
            },
            {
                "name": "Torrent",
                "countryCode": "ES"
            },
            {
                "name": "Torres de Segre",
                "countryCode": "ES"
            },
            {
                "name": "Torroella de Fluvià",
                "countryCode": "ES"
            },
            {
                "name": "Torroella de Montgrí",
                "countryCode": "ES"
            },
            {
                "name": "Tortosa",
                "countryCode": "ES"
            },
            {
                "name": "Torà de Riubregós",
                "countryCode": "ES"
            },
            {
                "name": "Tossa de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Tremp",
                "countryCode": "ES"
            },
            {
                "name": "Tàrrega",
                "countryCode": "ES"
            },
            {
                "name": "Térmens",
                "countryCode": "ES"
            },
            {
                "name": "Ullastrell",
                "countryCode": "ES"
            },
            {
                "name": "Ulldecona",
                "countryCode": "ES"
            },
            {
                "name": "Ulldemolins",
                "countryCode": "ES"
            },
            {
                "name": "Ullà",
                "countryCode": "ES"
            },
            {
                "name": "Ultramort",
                "countryCode": "ES"
            },
            {
                "name": "Urús",
                "countryCode": "ES"
            },
            {
                "name": "Vallbona",
                "countryCode": "ES"
            },
            {
                "name": "Vallbona de les Monges",
                "countryCode": "ES"
            },
            {
                "name": "Vallcarca",
                "countryCode": "ES"
            },
            {
                "name": "Vallclara",
                "countryCode": "ES"
            },
            {
                "name": "Vallfogona de Balaguer",
                "countryCode": "ES"
            },
            {
                "name": "Vallgorguina",
                "countryCode": "ES"
            },
            {
                "name": "Vallirana",
                "countryCode": "ES"
            },
            {
                "name": "Vallmoll",
                "countryCode": "ES"
            },
            {
                "name": "Valls",
                "countryCode": "ES"
            },
            {
                "name": "Vallvidrera, el Tibidabo i les Planes",
                "countryCode": "ES"
            },
            {
                "name": "Ventalló",
                "countryCode": "ES"
            },
            {
                "name": "Verdun",
                "countryCode": "ES"
            },
            {
                "name": "Verdú",
                "countryCode": "ES"
            },
            {
                "name": "Verges",
                "countryCode": "ES"
            },
            {
                "name": "Vic",
                "countryCode": "ES"
            },
            {
                "name": "Vidreres",
                "countryCode": "ES"
            },
            {
                "name": "Vidrà",
                "countryCode": "ES"
            },
            {
                "name": "Vielha",
                "countryCode": "ES"
            },
            {
                "name": "Vila-seca",
                "countryCode": "ES"
            },
            {
                "name": "Vilabella",
                "countryCode": "ES"
            },
            {
                "name": "Vilabertran",
                "countryCode": "ES"
            },
            {
                "name": "Vilablareix",
                "countryCode": "ES"
            },
            {
                "name": "Viladasens",
                "countryCode": "ES"
            },
            {
                "name": "Viladecans",
                "countryCode": "ES"
            },
            {
                "name": "Vilademuls",
                "countryCode": "ES"
            },
            {
                "name": "Viladrau",
                "countryCode": "ES"
            },
            {
                "name": "Vilafant",
                "countryCode": "ES"
            },
            {
                "name": "Vilafranca del Penedès",
                "countryCode": "ES"
            },
            {
                "name": "Vilajuïga",
                "countryCode": "ES"
            },
            {
                "name": "Vilamacolum",
                "countryCode": "ES"
            },
            {
                "name": "Vilamalla",
                "countryCode": "ES"
            },
            {
                "name": "Vilamaniscle",
                "countryCode": "ES"
            },
            {
                "name": "Vilanant",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova d'Escornalbou",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova de Bellpuig",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova de Prades",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova de Sau",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova del Camí",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova i la Geltrú",
                "countryCode": "ES"
            },
            {
                "name": "Vilapicina i la Torre Llobeta",
                "countryCode": "ES"
            },
            {
                "name": "Vilaplana",
                "countryCode": "ES"
            },
            {
                "name": "Vilaseca",
                "countryCode": "ES"
            },
            {
                "name": "Vilassar de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Vilopriu",
                "countryCode": "ES"
            },
            {
                "name": "Vimbodí",
                "countryCode": "ES"
            },
            {
                "name": "Vinaixa",
                "countryCode": "ES"
            },
            {
                "name": "Vinebre",
                "countryCode": "ES"
            },
            {
                "name": "el Baix Guinardó",
                "countryCode": "ES"
            },
            {
                "name": "el Besòs i el Maresme",
                "countryCode": "ES"
            },
            {
                "name": "el Bon Pastor",
                "countryCode": "ES"
            },
            {
                "name": "el Camp d'en Grassot i Gràcia Nova",
                "countryCode": "ES"
            },
            {
                "name": "el Camp de l'Arpa del Clot",
                "countryCode": "ES"
            },
            {
                "name": "el Catllar",
                "countryCode": "ES"
            },
            {
                "name": "el Clot",
                "countryCode": "ES"
            },
            {
                "name": "el Cogul",
                "countryCode": "ES"
            },
            {
                "name": "el Coll",
                "countryCode": "ES"
            },
            {
                "name": "el Congrés i els Indians",
                "countryCode": "ES"
            },
            {
                "name": "el Guinardó",
                "countryCode": "ES"
            },
            {
                "name": "el Masroig",
                "countryCode": "ES"
            },
            {
                "name": "el Parc i la Llacuna del Poblenou",
                "countryCode": "ES"
            },
            {
                "name": "el Poblenou",
                "countryCode": "ES"
            },
            {
                "name": "el Putxet i el Farró",
                "countryCode": "ES"
            },
            {
                "name": "el Raval",
                "countryCode": "ES"
            },
            {
                "name": "el Turó de la Peira",
                "countryCode": "ES"
            },
            {
                "name": "els Pallaresos",
                "countryCode": "ES"
            },
            {
                "name": "l'Antiga Esquerra de l'Eixample",
                "countryCode": "ES"
            },
            {
                "name": "l'Escala",
                "countryCode": "ES"
            },
            {
                "name": "l'Estartit",
                "countryCode": "ES"
            },
            {
                "name": "la Barceloneta",
                "countryCode": "ES"
            },
            {
                "name": "la Bisbal d'Empordà",
                "countryCode": "ES"
            },
            {
                "name": "la Cellera de Ter",
                "countryCode": "ES"
            },
            {
                "name": "la Fatarella",
                "countryCode": "ES"
            },
            {
                "name": "la Font d'en Fargues",
                "countryCode": "ES"
            },
            {
                "name": "la Font de la Guatlla",
                "countryCode": "ES"
            },
            {
                "name": "la Garriga",
                "countryCode": "ES"
            },
            {
                "name": "la Granadella",
                "countryCode": "ES"
            },
            {
                "name": "la Granja d'Escarp",
                "countryCode": "ES"
            },
            {
                "name": "la Guineueta",
                "countryCode": "ES"
            },
            {
                "name": "la Marina de Port",
                "countryCode": "ES"
            },
            {
                "name": "la Marina del Prat Vermell",
                "countryCode": "ES"
            },
            {
                "name": "la Maternitat i Sant Ramon",
                "countryCode": "ES"
            },
            {
                "name": "la Morera de Montsant",
                "countryCode": "ES"
            },
            {
                "name": "la Nova Esquerra de l'Eixample",
                "countryCode": "ES"
            },
            {
                "name": "la Pobla de Mafumet",
                "countryCode": "ES"
            },
            {
                "name": "la Prosperitat",
                "countryCode": "ES"
            },
            {
                "name": "la Salut",
                "countryCode": "ES"
            },
            {
                "name": "la Teixonera",
                "countryCode": "ES"
            },
            {
                "name": "la Trinitat Nova",
                "countryCode": "ES"
            },
            {
                "name": "la Trinitat Vella",
                "countryCode": "ES"
            },
            {
                "name": "la Vall d'Hebron",
                "countryCode": "ES"
            },
            {
                "name": "la Verneda i la Pau",
                "countryCode": "ES"
            },
            {
                "name": "la Vila Olímpica del Poblenou",
                "countryCode": "ES"
            },
            {
                "name": "la Vila de Gràcia",
                "countryCode": "ES"
            },
            {
                "name": "les Borges del Camp",
                "countryCode": "ES"
            },
            {
                "name": "les Llosses",
                "countryCode": "ES"
            },
            {
                "name": "les Planes d'Hostoles",
                "countryCode": "ES"
            },
            {
                "name": "les Roquetes",
                "countryCode": "ES"
            },
            {
                "name": "Àger",
                "countryCode": "ES"
            },
            {
                "name": "Éller",
                "countryCode": "ES"
            },
            {
                "name": "Òdena",
                "countryCode": "ES"
            },
            {
                "name": "Òrrius",
                "countryCode": "ES"
            },
            {
                "name": "Abadía",
                "countryCode": "ES"
            },
            {
                "name": "Abertura",
                "countryCode": "ES"
            },
            {
                "name": "Acebo",
                "countryCode": "ES"
            },
            {
                "name": "Acedera",
                "countryCode": "ES"
            },
            {
                "name": "Aceituna",
                "countryCode": "ES"
            },
            {
                "name": "Aceuchal",
                "countryCode": "ES"
            },
            {
                "name": "Ahigal",
                "countryCode": "ES"
            },
            {
                "name": "Ahillones",
                "countryCode": "ES"
            },
            {
                "name": "Alange",
                "countryCode": "ES"
            },
            {
                "name": "Albalá",
                "countryCode": "ES"
            },
            {
                "name": "Alburquerque",
                "countryCode": "ES"
            },
            {
                "name": "Alcollarín",
                "countryCode": "ES"
            },
            {
                "name": "Alconchel",
                "countryCode": "ES"
            },
            {
                "name": "Alconera",
                "countryCode": "ES"
            },
            {
                "name": "Alcuéscar",
                "countryCode": "ES"
            },
            {
                "name": "Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "Aldea del Cano",
                "countryCode": "ES"
            },
            {
                "name": "Aldeacentenera",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Aliseda",
                "countryCode": "ES"
            },
            {
                "name": "Aljucén",
                "countryCode": "ES"
            },
            {
                "name": "Almaraz",
                "countryCode": "ES"
            },
            {
                "name": "Almendral",
                "countryCode": "ES"
            },
            {
                "name": "Almendralejo",
                "countryCode": "ES"
            },
            {
                "name": "Almoharín",
                "countryCode": "ES"
            },
            {
                "name": "Alía",
                "countryCode": "ES"
            },
            {
                "name": "Arroyo de San Serván",
                "countryCode": "ES"
            },
            {
                "name": "Arroyo de la Luz",
                "countryCode": "ES"
            },
            {
                "name": "Arroyomolinos de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Atalaya",
                "countryCode": "ES"
            },
            {
                "name": "Azuaga",
                "countryCode": "ES"
            },
            {
                "name": "Badajoz",
                "countryCode": "ES"
            },
            {
                "name": "Barcarrota",
                "countryCode": "ES"
            },
            {
                "name": "Barrado",
                "countryCode": "ES"
            },
            {
                "name": "Baterno",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Montemayor",
                "countryCode": "ES"
            },
            {
                "name": "Benquerencia",
                "countryCode": "ES"
            },
            {
                "name": "Berlanga",
                "countryCode": "ES"
            },
            {
                "name": "Berrocalejo",
                "countryCode": "ES"
            },
            {
                "name": "Bienvenida",
                "countryCode": "ES"
            },
            {
                "name": "Bodonal de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Bohonal de Ibor",
                "countryCode": "ES"
            },
            {
                "name": "Botija",
                "countryCode": "ES"
            },
            {
                "name": "Brozas",
                "countryCode": "ES"
            },
            {
                "name": "Burguillos del Cerro",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Cabeza del Buey",
                "countryCode": "ES"
            },
            {
                "name": "Cabeza la Vaca",
                "countryCode": "ES"
            },
            {
                "name": "Cabezabellosa",
                "countryCode": "ES"
            },
            {
                "name": "Cabezuela del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Cabrero",
                "countryCode": "ES"
            },
            {
                "name": "Cachorrilla",
                "countryCode": "ES"
            },
            {
                "name": "Cadalso",
                "countryCode": "ES"
            },
            {
                "name": "Calamonte",
                "countryCode": "ES"
            },
            {
                "name": "Calera de León",
                "countryCode": "ES"
            },
            {
                "name": "Calzadilla",
                "countryCode": "ES"
            },
            {
                "name": "Caminomorisco",
                "countryCode": "ES"
            },
            {
                "name": "Campanario",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Deleitosa",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Llerena",
                "countryCode": "ES"
            },
            {
                "name": "Capilla",
                "countryCode": "ES"
            },
            {
                "name": "Carbajo",
                "countryCode": "ES"
            },
            {
                "name": "Carcaboso",
                "countryCode": "ES"
            },
            {
                "name": "Carmonita",
                "countryCode": "ES"
            },
            {
                "name": "Carrascalejo",
                "countryCode": "ES"
            },
            {
                "name": "Casar de Cáceres",
                "countryCode": "ES"
            },
            {
                "name": "Casar de Palomero",
                "countryCode": "ES"
            },
            {
                "name": "Casares de las Hurdes",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Don Antonio",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Don Gómez",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Don Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Millán",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Miravete",
                "countryCode": "ES"
            },
            {
                "name": "Casas de Reina",
                "countryCode": "ES"
            },
            {
                "name": "Casas del Castañar",
                "countryCode": "ES"
            },
            {
                "name": "Casas del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Casatejada",
                "countryCode": "ES"
            },
            {
                "name": "Casillas de Coria",
                "countryCode": "ES"
            },
            {
                "name": "Castañar de Ibor",
                "countryCode": "ES"
            },
            {
                "name": "Castilblanco",
                "countryCode": "ES"
            },
            {
                "name": "Castuera",
                "countryCode": "ES"
            },
            {
                "name": "Cañamero",
                "countryCode": "ES"
            },
            {
                "name": "Cañaveral",
                "countryCode": "ES"
            },
            {
                "name": "Ceclavín",
                "countryCode": "ES"
            },
            {
                "name": "Cedillo",
                "countryCode": "ES"
            },
            {
                "name": "Cerezo",
                "countryCode": "ES"
            },
            {
                "name": "Cheles",
                "countryCode": "ES"
            },
            {
                "name": "Cilleros",
                "countryCode": "ES"
            },
            {
                "name": "Collado",
                "countryCode": "ES"
            },
            {
                "name": "Conquista de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Cordobilla de Lácara",
                "countryCode": "ES"
            },
            {
                "name": "Coria",
                "countryCode": "ES"
            },
            {
                "name": "Corte de Peleas",
                "countryCode": "ES"
            },
            {
                "name": "Cristina",
                "countryCode": "ES"
            },
            {
                "name": "Cáceres",
                "countryCode": "ES"
            },
            {
                "name": "Deleitosa",
                "countryCode": "ES"
            },
            {
                "name": "Descargamaría",
                "countryCode": "ES"
            },
            {
                "name": "Don Benito",
                "countryCode": "ES"
            },
            {
                "name": "Don Álvaro",
                "countryCode": "ES"
            },
            {
                "name": "El Casar",
                "countryCode": "ES"
            },
            {
                "name": "Eljas",
                "countryCode": "ES"
            },
            {
                "name": "Entrín Bajo",
                "countryCode": "ES"
            },
            {
                "name": "Escurial",
                "countryCode": "ES"
            },
            {
                "name": "Esparragalejo",
                "countryCode": "ES"
            },
            {
                "name": "Esparragosa de Lares",
                "countryCode": "ES"
            },
            {
                "name": "Esparragosa de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Feria",
                "countryCode": "ES"
            },
            {
                "name": "Fregenal de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Fresnedoso de Ibor",
                "countryCode": "ES"
            },
            {
                "name": "Fuenlabrada de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Fuente de Cantos",
                "countryCode": "ES"
            },
            {
                "name": "Fuente del Maestre",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de León",
                "countryCode": "ES"
            },
            {
                "name": "Galisteo",
                "countryCode": "ES"
            },
            {
                "name": "Garbayuela",
                "countryCode": "ES"
            },
            {
                "name": "Garganta la Olla",
                "countryCode": "ES"
            },
            {
                "name": "Gargantilla",
                "countryCode": "ES"
            },
            {
                "name": "Gargüera",
                "countryCode": "ES"
            },
            {
                "name": "Garlitos",
                "countryCode": "ES"
            },
            {
                "name": "Garrovillas",
                "countryCode": "ES"
            },
            {
                "name": "Garvín",
                "countryCode": "ES"
            },
            {
                "name": "Gata",
                "countryCode": "ES"
            },
            {
                "name": "Granja de Torrehermosa",
                "countryCode": "ES"
            },
            {
                "name": "Guadalupe",
                "countryCode": "ES"
            },
            {
                "name": "Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Guijo de Coria",
                "countryCode": "ES"
            },
            {
                "name": "Guijo de Galisteo",
                "countryCode": "ES"
            },
            {
                "name": "Guijo de Granadilla",
                "countryCode": "ES"
            },
            {
                "name": "Guijo de Santa Bárbara",
                "countryCode": "ES"
            },
            {
                "name": "Herguijuela",
                "countryCode": "ES"
            },
            {
                "name": "Hernán-Pérez",
                "countryCode": "ES"
            },
            {
                "name": "Herrera de Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "Herrera del Duque",
                "countryCode": "ES"
            },
            {
                "name": "Hervás",
                "countryCode": "ES"
            },
            {
                "name": "Higuera",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de Vargas",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Higuera la Real",
                "countryCode": "ES"
            },
            {
                "name": "Hinojal",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Holguera",
                "countryCode": "ES"
            },
            {
                "name": "Hornachos",
                "countryCode": "ES"
            },
            {
                "name": "Hoyos",
                "countryCode": "ES"
            },
            {
                "name": "Huélaga",
                "countryCode": "ES"
            },
            {
                "name": "Ibahernando",
                "countryCode": "ES"
            },
            {
                "name": "Jaraicejo",
                "countryCode": "ES"
            },
            {
                "name": "Jarandilla de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Jaraíz de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Jerez de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Jerte",
                "countryCode": "ES"
            },
            {
                "name": "La Albuera",
                "countryCode": "ES"
            },
            {
                "name": "La Codosera",
                "countryCode": "ES"
            },
            {
                "name": "La Coronada",
                "countryCode": "ES"
            },
            {
                "name": "La Cumbre",
                "countryCode": "ES"
            },
            {
                "name": "La Garrovilla",
                "countryCode": "ES"
            },
            {
                "name": "La Haba",
                "countryCode": "ES"
            },
            {
                "name": "La Nava de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "La Parra",
                "countryCode": "ES"
            },
            {
                "name": "La Pesga",
                "countryCode": "ES"
            },
            {
                "name": "La Roca de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Ladrillar",
                "countryCode": "ES"
            },
            {
                "name": "Llera",
                "countryCode": "ES"
            },
            {
                "name": "Llerena",
                "countryCode": "ES"
            },
            {
                "name": "Lobón",
                "countryCode": "ES"
            },
            {
                "name": "Logrosán",
                "countryCode": "ES"
            },
            {
                "name": "Los Santos de Maimona",
                "countryCode": "ES"
            },
            {
                "name": "Losar de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Madrigal de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Madrigalejo",
                "countryCode": "ES"
            },
            {
                "name": "Madroñera",
                "countryCode": "ES"
            },
            {
                "name": "Magacela",
                "countryCode": "ES"
            },
            {
                "name": "Maguilla",
                "countryCode": "ES"
            },
            {
                "name": "Majadas",
                "countryCode": "ES"
            },
            {
                "name": "Malcocinado",
                "countryCode": "ES"
            },
            {
                "name": "Malpartida de Cáceres",
                "countryCode": "ES"
            },
            {
                "name": "Malpartida de Plasencia",
                "countryCode": "ES"
            },
            {
                "name": "Malpartida de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Manchita",
                "countryCode": "ES"
            },
            {
                "name": "Marchagaz",
                "countryCode": "ES"
            },
            {
                "name": "Mata de Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "Medellín",
                "countryCode": "ES"
            },
            {
                "name": "Medina de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Mengabril",
                "countryCode": "ES"
            },
            {
                "name": "Mesas de Ibor",
                "countryCode": "ES"
            },
            {
                "name": "Miajadas",
                "countryCode": "ES"
            },
            {
                "name": "Millanes",
                "countryCode": "ES"
            },
            {
                "name": "Mirabel",
                "countryCode": "ES"
            },
            {
                "name": "Mirandilla",
                "countryCode": "ES"
            },
            {
                "name": "Monesterio",
                "countryCode": "ES"
            },
            {
                "name": "Montehermoso",
                "countryCode": "ES"
            },
            {
                "name": "Montemolín",
                "countryCode": "ES"
            },
            {
                "name": "Monterrubio de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Montijo",
                "countryCode": "ES"
            },
            {
                "name": "Montánchez",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja",
                "countryCode": "ES"
            },
            {
                "name": "Morcillo",
                "countryCode": "ES"
            },
            {
                "name": "Mérida",
                "countryCode": "ES"
            },
            {
                "name": "Navaconcejo",
                "countryCode": "ES"
            },
            {
                "name": "Navalmoral de la Mata",
                "countryCode": "ES"
            },
            {
                "name": "Navalvillar de Ibor",
                "countryCode": "ES"
            },
            {
                "name": "Navalvillar de Pela",
                "countryCode": "ES"
            },
            {
                "name": "Navas del Madroño",
                "countryCode": "ES"
            },
            {
                "name": "Navezuelas",
                "countryCode": "ES"
            },
            {
                "name": "Nogales",
                "countryCode": "ES"
            },
            {
                "name": "Nuñomoral",
                "countryCode": "ES"
            },
            {
                "name": "Oliva de Mérida",
                "countryCode": "ES"
            },
            {
                "name": "Oliva de Plasencia",
                "countryCode": "ES"
            },
            {
                "name": "Oliva de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Olivenza",
                "countryCode": "ES"
            },
            {
                "name": "Orellana la Vieja",
                "countryCode": "ES"
            },
            {
                "name": "Palomas",
                "countryCode": "ES"
            },
            {
                "name": "Palomero",
                "countryCode": "ES"
            },
            {
                "name": "Pedroso de Acim",
                "countryCode": "ES"
            },
            {
                "name": "Peraleda de San Román",
                "countryCode": "ES"
            },
            {
                "name": "Peraleda de la Mata",
                "countryCode": "ES"
            },
            {
                "name": "Perales del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "Pescueza",
                "countryCode": "ES"
            },
            {
                "name": "Peñalsordo",
                "countryCode": "ES"
            },
            {
                "name": "Piedras Albas",
                "countryCode": "ES"
            },
            {
                "name": "Pinofranqueado",
                "countryCode": "ES"
            },
            {
                "name": "Piornal",
                "countryCode": "ES"
            },
            {
                "name": "Plasencia",
                "countryCode": "ES"
            },
            {
                "name": "Plasenzuela",
                "countryCode": "ES"
            },
            {
                "name": "Portaje",
                "countryCode": "ES"
            },
            {
                "name": "Portezuelo",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de Zarzón",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Badajoz",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Cáceres",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Alcocer",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Obando",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Sancho Pérez",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de la Calzada",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Puebla del Maestre",
                "countryCode": "ES"
            },
            {
                "name": "Puebla del Prior",
                "countryCode": "ES"
            },
            {
                "name": "Puerto de Santa Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Quintana de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Reina",
                "countryCode": "ES"
            },
            {
                "name": "Rena",
                "countryCode": "ES"
            },
            {
                "name": "Ribera del Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Robledillo de Gata",
                "countryCode": "ES"
            },
            {
                "name": "Robledillo de Trujillo",
                "countryCode": "ES"
            },
            {
                "name": "Robledillo de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Robledollano",
                "countryCode": "ES"
            },
            {
                "name": "Romangordo",
                "countryCode": "ES"
            },
            {
                "name": "Rosalejo",
                "countryCode": "ES"
            },
            {
                "name": "Ruanes",
                "countryCode": "ES"
            },
            {
                "name": "Ríolobos",
                "countryCode": "ES"
            },
            {
                "name": "Salorino",
                "countryCode": "ES"
            },
            {
                "name": "Salvaleón",
                "countryCode": "ES"
            },
            {
                "name": "Salvatierra de Santiago",
                "countryCode": "ES"
            },
            {
                "name": "Salvatierra de los Barros",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Trevejo",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Mérida",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente de Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "Santa Amalia",
                "countryCode": "ES"
            },
            {
                "name": "Santa Ana",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Paniagua",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marta",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marta de Magasca",
                "countryCode": "ES"
            },
            {
                "name": "Santiago del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez el Alto",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez el Bajo",
                "countryCode": "ES"
            },
            {
                "name": "Saucedilla",
                "countryCode": "ES"
            },
            {
                "name": "Segura de León",
                "countryCode": "ES"
            },
            {
                "name": "Segura de Toro",
                "countryCode": "ES"
            },
            {
                "name": "Serradilla",
                "countryCode": "ES"
            },
            {
                "name": "Serrejón",
                "countryCode": "ES"
            },
            {
                "name": "Sierra de Fuentes",
                "countryCode": "ES"
            },
            {
                "name": "Siruela",
                "countryCode": "ES"
            },
            {
                "name": "Solana de los Barros",
                "countryCode": "ES"
            },
            {
                "name": "Talarrubias",
                "countryCode": "ES"
            },
            {
                "name": "Talavera La Real",
                "countryCode": "ES"
            },
            {
                "name": "Talaván",
                "countryCode": "ES"
            },
            {
                "name": "Talayuela",
                "countryCode": "ES"
            },
            {
                "name": "Tamurejo",
                "countryCode": "ES"
            },
            {
                "name": "Tejeda de Tiétar",
                "countryCode": "ES"
            },
            {
                "name": "Toril",
                "countryCode": "ES"
            },
            {
                "name": "Tornavacas",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Don Miguel",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Miguel Sesmero",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Santa María",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de los Ángeles",
                "countryCode": "ES"
            },
            {
                "name": "Torrecillas de la Tiesa",
                "countryCode": "ES"
            },
            {
                "name": "Torrejoncillo",
                "countryCode": "ES"
            },
            {
                "name": "Torrejón el Rubio",
                "countryCode": "ES"
            },
            {
                "name": "Torremayor",
                "countryCode": "ES"
            },
            {
                "name": "Torremegía",
                "countryCode": "ES"
            },
            {
                "name": "Torremenga",
                "countryCode": "ES"
            },
            {
                "name": "Torremocha",
                "countryCode": "ES"
            },
            {
                "name": "Torreorgaz",
                "countryCode": "ES"
            },
            {
                "name": "Torrequemada",
                "countryCode": "ES"
            },
            {
                "name": "Trasierra",
                "countryCode": "ES"
            },
            {
                "name": "Trujillanos",
                "countryCode": "ES"
            },
            {
                "name": "Trujillo",
                "countryCode": "ES"
            },
            {
                "name": "Táliga",
                "countryCode": "ES"
            },
            {
                "name": "Usagre",
                "countryCode": "ES"
            },
            {
                "name": "Valdastillas",
                "countryCode": "ES"
            },
            {
                "name": "Valdecaballeros",
                "countryCode": "ES"
            },
            {
                "name": "Valdecañas de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Valdefuentes",
                "countryCode": "ES"
            },
            {
                "name": "Valdehúncar",
                "countryCode": "ES"
            },
            {
                "name": "Valdelacalzada",
                "countryCode": "ES"
            },
            {
                "name": "Valdelacasa de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Valdemorales",
                "countryCode": "ES"
            },
            {
                "name": "Valdeobispo",
                "countryCode": "ES"
            },
            {
                "name": "Valdetorres",
                "countryCode": "ES"
            },
            {
                "name": "Valencia de Alcántara",
                "countryCode": "ES"
            },
            {
                "name": "Valencia de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Valencia del Mombuey",
                "countryCode": "ES"
            },
            {
                "name": "Valencia del Ventoso",
                "countryCode": "ES"
            },
            {
                "name": "Valle de Matamoros",
                "countryCode": "ES"
            },
            {
                "name": "Valle de Santa Ana",
                "countryCode": "ES"
            },
            {
                "name": "Valle de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Burguillos",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Leganés",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Llerena",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Mérida",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Valverde del Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Viandar de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Villa del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villa del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de los Barros",
                "countryCode": "ES"
            },
            {
                "name": "Villagarcía de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Villagonzalo",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de los Barros",
                "countryCode": "ES"
            },
            {
                "name": "Villamesías",
                "countryCode": "ES"
            },
            {
                "name": "Villamiel",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Vera",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Plasencia",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Rena",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Pedroso",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Villarta de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Villasbuenas de Gata",
                "countryCode": "ES"
            },
            {
                "name": "Zafra",
                "countryCode": "ES"
            },
            {
                "name": "Zahinos",
                "countryCode": "ES"
            },
            {
                "name": "Zalamea de la Serena",
                "countryCode": "ES"
            },
            {
                "name": "Zarza de Alange",
                "countryCode": "ES"
            },
            {
                "name": "Zarza de Granadilla",
                "countryCode": "ES"
            },
            {
                "name": "Zarza de Montánchez",
                "countryCode": "ES"
            },
            {
                "name": "Zarza la Mayor",
                "countryCode": "ES"
            },
            {
                "name": "Zorita",
                "countryCode": "ES"
            },
            {
                "name": "A Baña",
                "countryCode": "ES"
            },
            {
                "name": "A Coruña",
                "countryCode": "ES"
            },
            {
                "name": "A Estrada",
                "countryCode": "ES"
            },
            {
                "name": "A Pobra do Brollon",
                "countryCode": "ES"
            },
            {
                "name": "A Pobra do Caramiñal",
                "countryCode": "ES"
            },
            {
                "name": "A Rúa",
                "countryCode": "ES"
            },
            {
                "name": "Abadín",
                "countryCode": "ES"
            },
            {
                "name": "Abegondo",
                "countryCode": "ES"
            },
            {
                "name": "Allariz",
                "countryCode": "ES"
            },
            {
                "name": "Ambía",
                "countryCode": "ES"
            },
            {
                "name": "Amoeiro",
                "countryCode": "ES"
            },
            {
                "name": "Amés",
                "countryCode": "ES"
            },
            {
                "name": "Aranga",
                "countryCode": "ES"
            },
            {
                "name": "Arbo",
                "countryCode": "ES"
            },
            {
                "name": "Ares",
                "countryCode": "ES"
            },
            {
                "name": "Arteixo",
                "countryCode": "ES"
            },
            {
                "name": "Arzúa",
                "countryCode": "ES"
            },
            {
                "name": "As Pontes de García Rodríguez",
                "countryCode": "ES"
            },
            {
                "name": "Avión",
                "countryCode": "ES"
            },
            {
                "name": "Baiona",
                "countryCode": "ES"
            },
            {
                "name": "Baltar",
                "countryCode": "ES"
            },
            {
                "name": "Bande",
                "countryCode": "ES"
            },
            {
                "name": "Baralla",
                "countryCode": "ES"
            },
            {
                "name": "Barreiros",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Molgas",
                "countryCode": "ES"
            },
            {
                "name": "Beade",
                "countryCode": "ES"
            },
            {
                "name": "Becerreá",
                "countryCode": "ES"
            },
            {
                "name": "Begonte",
                "countryCode": "ES"
            },
            {
                "name": "Bergondo",
                "countryCode": "ES"
            },
            {
                "name": "Betanzos",
                "countryCode": "ES"
            },
            {
                "name": "Blancos",
                "countryCode": "ES"
            },
            {
                "name": "Boborás",
                "countryCode": "ES"
            },
            {
                "name": "Boimorto",
                "countryCode": "ES"
            },
            {
                "name": "Boiro",
                "countryCode": "ES"
            },
            {
                "name": "Bueu",
                "countryCode": "ES"
            },
            {
                "name": "Burela de Cabo",
                "countryCode": "ES"
            },
            {
                "name": "Caldas de Reis",
                "countryCode": "ES"
            },
            {
                "name": "Calvos",
                "countryCode": "ES"
            },
            {
                "name": "Camariñas",
                "countryCode": "ES"
            },
            {
                "name": "Cambados",
                "countryCode": "ES"
            },
            {
                "name": "Cambre",
                "countryCode": "ES"
            },
            {
                "name": "Cangas do Morrazo",
                "countryCode": "ES"
            },
            {
                "name": "Carballeda de Avia",
                "countryCode": "ES"
            },
            {
                "name": "Carballo",
                "countryCode": "ES"
            },
            {
                "name": "Cariño",
                "countryCode": "ES"
            },
            {
                "name": "Carnota",
                "countryCode": "ES"
            },
            {
                "name": "Carral",
                "countryCode": "ES"
            },
            {
                "name": "Cartelle",
                "countryCode": "ES"
            },
            {
                "name": "Castrelo de Miño",
                "countryCode": "ES"
            },
            {
                "name": "Castro",
                "countryCode": "ES"
            },
            {
                "name": "Castro Caldelas",
                "countryCode": "ES"
            },
            {
                "name": "Castro de Rei",
                "countryCode": "ES"
            },
            {
                "name": "Castroverde",
                "countryCode": "ES"
            },
            {
                "name": "Catoira",
                "countryCode": "ES"
            },
            {
                "name": "Cea",
                "countryCode": "ES"
            },
            {
                "name": "Cedeira",
                "countryCode": "ES"
            },
            {
                "name": "Cee",
                "countryCode": "ES"
            },
            {
                "name": "Celanova",
                "countryCode": "ES"
            },
            {
                "name": "Cenlle",
                "countryCode": "ES"
            },
            {
                "name": "Cerdido",
                "countryCode": "ES"
            },
            {
                "name": "Cervo",
                "countryCode": "ES"
            },
            {
                "name": "Cesuras",
                "countryCode": "ES"
            },
            {
                "name": "Chantada",
                "countryCode": "ES"
            },
            {
                "name": "Coirós",
                "countryCode": "ES"
            },
            {
                "name": "Coles",
                "countryCode": "ES"
            },
            {
                "name": "Combarro",
                "countryCode": "ES"
            },
            {
                "name": "Corcubión",
                "countryCode": "ES"
            },
            {
                "name": "Corgo",
                "countryCode": "ES"
            },
            {
                "name": "Coristanco",
                "countryCode": "ES"
            },
            {
                "name": "Covelo",
                "countryCode": "ES"
            },
            {
                "name": "Cualedro",
                "countryCode": "ES"
            },
            {
                "name": "Culleredo",
                "countryCode": "ES"
            },
            {
                "name": "Cuntis",
                "countryCode": "ES"
            },
            {
                "name": "Curtis",
                "countryCode": "ES"
            },
            {
                "name": "Dozón",
                "countryCode": "ES"
            },
            {
                "name": "Dumbría",
                "countryCode": "ES"
            },
            {
                "name": "Entrimo",
                "countryCode": "ES"
            },
            {
                "name": "Esgos",
                "countryCode": "ES"
            },
            {
                "name": "Fene",
                "countryCode": "ES"
            },
            {
                "name": "Ferrol",
                "countryCode": "ES"
            },
            {
                "name": "Fisterra",
                "countryCode": "ES"
            },
            {
                "name": "Fonsagrada",
                "countryCode": "ES"
            },
            {
                "name": "Fornelos de Montes",
                "countryCode": "ES"
            },
            {
                "name": "Foz",
                "countryCode": "ES"
            },
            {
                "name": "Friol",
                "countryCode": "ES"
            },
            {
                "name": "Gondomar",
                "countryCode": "ES"
            },
            {
                "name": "Guitiriz",
                "countryCode": "ES"
            },
            {
                "name": "Illa de Arousa",
                "countryCode": "ES"
            },
            {
                "name": "Irixoa",
                "countryCode": "ES"
            },
            {
                "name": "Lalín",
                "countryCode": "ES"
            },
            {
                "name": "Lama",
                "countryCode": "ES"
            },
            {
                "name": "Laracha",
                "countryCode": "ES"
            },
            {
                "name": "Laza",
                "countryCode": "ES"
            },
            {
                "name": "Lobios",
                "countryCode": "ES"
            },
            {
                "name": "Lousame",
                "countryCode": "ES"
            },
            {
                "name": "Lugo",
                "countryCode": "ES"
            },
            {
                "name": "Luintra",
                "countryCode": "ES"
            },
            {
                "name": "Láncara",
                "countryCode": "ES"
            },
            {
                "name": "Maceda",
                "countryCode": "ES"
            },
            {
                "name": "Malpica",
                "countryCode": "ES"
            },
            {
                "name": "Manzaneda",
                "countryCode": "ES"
            },
            {
                "name": "Marín",
                "countryCode": "ES"
            },
            {
                "name": "Maside",
                "countryCode": "ES"
            },
            {
                "name": "Mazaricos",
                "countryCode": "ES"
            },
            {
                "name": "Meaño",
                "countryCode": "ES"
            },
            {
                "name": "Meira",
                "countryCode": "ES"
            },
            {
                "name": "Meis",
                "countryCode": "ES"
            },
            {
                "name": "Melón",
                "countryCode": "ES"
            },
            {
                "name": "Mesia",
                "countryCode": "ES"
            },
            {
                "name": "Miño",
                "countryCode": "ES"
            },
            {
                "name": "Moaña",
                "countryCode": "ES"
            },
            {
                "name": "Moeche",
                "countryCode": "ES"
            },
            {
                "name": "Mondariz",
                "countryCode": "ES"
            },
            {
                "name": "Mondariz-Balneario",
                "countryCode": "ES"
            },
            {
                "name": "Mondoñedo",
                "countryCode": "ES"
            },
            {
                "name": "Monfero",
                "countryCode": "ES"
            },
            {
                "name": "Monforte de Lemos",
                "countryCode": "ES"
            },
            {
                "name": "Montederramo",
                "countryCode": "ES"
            },
            {
                "name": "Monterroso",
                "countryCode": "ES"
            },
            {
                "name": "Moraña",
                "countryCode": "ES"
            },
            {
                "name": "Mos",
                "countryCode": "ES"
            },
            {
                "name": "Mugardos",
                "countryCode": "ES"
            },
            {
                "name": "Mugia",
                "countryCode": "ES"
            },
            {
                "name": "Muiños",
                "countryCode": "ES"
            },
            {
                "name": "Muras",
                "countryCode": "ES"
            },
            {
                "name": "Muros",
                "countryCode": "ES"
            },
            {
                "name": "Narón",
                "countryCode": "ES"
            },
            {
                "name": "Navia de Suarna",
                "countryCode": "ES"
            },
            {
                "name": "Negreira",
                "countryCode": "ES"
            },
            {
                "name": "Nigrán",
                "countryCode": "ES"
            },
            {
                "name": "Nogueira de Ramuín",
                "countryCode": "ES"
            },
            {
                "name": "O Barco de Valdeorras",
                "countryCode": "ES"
            },
            {
                "name": "O Carballiño",
                "countryCode": "ES"
            },
            {
                "name": "O Grove",
                "countryCode": "ES"
            },
            {
                "name": "O Incio",
                "countryCode": "ES"
            },
            {
                "name": "O Páramo",
                "countryCode": "ES"
            },
            {
                "name": "O Rosal",
                "countryCode": "ES"
            },
            {
                "name": "Oia",
                "countryCode": "ES"
            },
            {
                "name": "Oimbra",
                "countryCode": "ES"
            },
            {
                "name": "Oleiros",
                "countryCode": "ES"
            },
            {
                "name": "Ordes",
                "countryCode": "ES"
            },
            {
                "name": "Oroso",
                "countryCode": "ES"
            },
            {
                "name": "Ourense",
                "countryCode": "ES"
            },
            {
                "name": "Ourol",
                "countryCode": "ES"
            },
            {
                "name": "Outeiro",
                "countryCode": "ES"
            },
            {
                "name": "Outeiro de Rei",
                "countryCode": "ES"
            },
            {
                "name": "Outes",
                "countryCode": "ES"
            },
            {
                "name": "Padrenda",
                "countryCode": "ES"
            },
            {
                "name": "Padrón",
                "countryCode": "ES"
            },
            {
                "name": "Palmeira",
                "countryCode": "ES"
            },
            {
                "name": "Pantón",
                "countryCode": "ES"
            },
            {
                "name": "Paradela",
                "countryCode": "ES"
            },
            {
                "name": "Pastoriza",
                "countryCode": "ES"
            },
            {
                "name": "Pereiro de Aguiar",
                "countryCode": "ES"
            },
            {
                "name": "Petín",
                "countryCode": "ES"
            },
            {
                "name": "Piñor",
                "countryCode": "ES"
            },
            {
                "name": "Pobra de Trives",
                "countryCode": "ES"
            },
            {
                "name": "Poio",
                "countryCode": "ES"
            },
            {
                "name": "Ponteareas",
                "countryCode": "ES"
            },
            {
                "name": "Pontevedra",
                "countryCode": "ES"
            },
            {
                "name": "Porqueira",
                "countryCode": "ES"
            },
            {
                "name": "Porriño",
                "countryCode": "ES"
            },
            {
                "name": "Portas",
                "countryCode": "ES"
            },
            {
                "name": "Porto do Son",
                "countryCode": "ES"
            },
            {
                "name": "Portomarín",
                "countryCode": "ES"
            },
            {
                "name": "Portonovo",
                "countryCode": "ES"
            },
            {
                "name": "Provincia da Coruña",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Lugo",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Ourense",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Pontevedra",
                "countryCode": "ES"
            },
            {
                "name": "Puente Nuevo",
                "countryCode": "ES"
            },
            {
                "name": "Quintela de Leirado",
                "countryCode": "ES"
            },
            {
                "name": "Quiroga",
                "countryCode": "ES"
            },
            {
                "name": "Rairiz de Veiga",
                "countryCode": "ES"
            },
            {
                "name": "Redondela",
                "countryCode": "ES"
            },
            {
                "name": "Rianxo",
                "countryCode": "ES"
            },
            {
                "name": "Ribadavia",
                "countryCode": "ES"
            },
            {
                "name": "Ribadeo",
                "countryCode": "ES"
            },
            {
                "name": "Ribadumia",
                "countryCode": "ES"
            },
            {
                "name": "Ribeira",
                "countryCode": "ES"
            },
            {
                "name": "Riós",
                "countryCode": "ES"
            },
            {
                "name": "Rodeiro",
                "countryCode": "ES"
            },
            {
                "name": "Rábade",
                "countryCode": "ES"
            },
            {
                "name": "Ríotorto",
                "countryCode": "ES"
            },
            {
                "name": "Sada",
                "countryCode": "ES"
            },
            {
                "name": "Salvatierra de Miño",
                "countryCode": "ES"
            },
            {
                "name": "Samos",
                "countryCode": "ES"
            },
            {
                "name": "San Amaro",
                "countryCode": "ES"
            },
            {
                "name": "San Román",
                "countryCode": "ES"
            },
            {
                "name": "Santa Comba",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marta de Ortigueira",
                "countryCode": "ES"
            },
            {
                "name": "Santa Uxía de Ribeira",
                "countryCode": "ES"
            },
            {
                "name": "Santiago de Compostela",
                "countryCode": "ES"
            },
            {
                "name": "Santiso",
                "countryCode": "ES"
            },
            {
                "name": "Sanxenxo",
                "countryCode": "ES"
            },
            {
                "name": "Sarreaus",
                "countryCode": "ES"
            },
            {
                "name": "Sarria",
                "countryCode": "ES"
            },
            {
                "name": "Silleda",
                "countryCode": "ES"
            },
            {
                "name": "Sober",
                "countryCode": "ES"
            },
            {
                "name": "Sobradelo",
                "countryCode": "ES"
            },
            {
                "name": "Sobrado",
                "countryCode": "ES"
            },
            {
                "name": "Somozas",
                "countryCode": "ES"
            },
            {
                "name": "Taboada",
                "countryCode": "ES"
            },
            {
                "name": "Taboadela",
                "countryCode": "ES"
            },
            {
                "name": "Teo",
                "countryCode": "ES"
            },
            {
                "name": "Tomiño",
                "countryCode": "ES"
            },
            {
                "name": "Touro",
                "countryCode": "ES"
            },
            {
                "name": "Toén",
                "countryCode": "ES"
            },
            {
                "name": "Trabada",
                "countryCode": "ES"
            },
            {
                "name": "Trasmiras",
                "countryCode": "ES"
            },
            {
                "name": "Trazo",
                "countryCode": "ES"
            },
            {
                "name": "Tui",
                "countryCode": "ES"
            },
            {
                "name": "Valdoviño",
                "countryCode": "ES"
            },
            {
                "name": "Valga",
                "countryCode": "ES"
            },
            {
                "name": "Vedra",
                "countryCode": "ES"
            },
            {
                "name": "Verea",
                "countryCode": "ES"
            },
            {
                "name": "Verín",
                "countryCode": "ES"
            },
            {
                "name": "Vigo",
                "countryCode": "ES"
            },
            {
                "name": "Vilagarcía de Arousa",
                "countryCode": "ES"
            },
            {
                "name": "Vilalba",
                "countryCode": "ES"
            },
            {
                "name": "Vilanova de Arousa",
                "countryCode": "ES"
            },
            {
                "name": "Vilasantar",
                "countryCode": "ES"
            },
            {
                "name": "Vilaxoán",
                "countryCode": "ES"
            },
            {
                "name": "Vimianzo",
                "countryCode": "ES"
            },
            {
                "name": "Viveiro",
                "countryCode": "ES"
            },
            {
                "name": "Xinzo de Limia",
                "countryCode": "ES"
            },
            {
                "name": "Zas",
                "countryCode": "ES"
            },
            {
                "name": "Abades",
                "countryCode": "ES"
            },
            {
                "name": "Abajas",
                "countryCode": "ES"
            },
            {
                "name": "Abejar",
                "countryCode": "ES"
            },
            {
                "name": "Abezames",
                "countryCode": "ES"
            },
            {
                "name": "Abia de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Abusejo",
                "countryCode": "ES"
            },
            {
                "name": "Acebedo",
                "countryCode": "ES"
            },
            {
                "name": "Adalia",
                "countryCode": "ES"
            },
            {
                "name": "Adanero",
                "countryCode": "ES"
            },
            {
                "name": "Adrada de Haza",
                "countryCode": "ES"
            },
            {
                "name": "Adrada de Pirón",
                "countryCode": "ES"
            },
            {
                "name": "Adradas",
                "countryCode": "ES"
            },
            {
                "name": "Adrados",
                "countryCode": "ES"
            },
            {
                "name": "Agallas",
                "countryCode": "ES"
            },
            {
                "name": "Aguasal",
                "countryCode": "ES"
            },
            {
                "name": "Aguilafuente",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar de Campoo",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Ahigal de Villarino",
                "countryCode": "ES"
            },
            {
                "name": "Ahigal de los Aceiteros",
                "countryCode": "ES"
            },
            {
                "name": "Alaejos",
                "countryCode": "ES"
            },
            {
                "name": "Alar del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Alaraz",
                "countryCode": "ES"
            },
            {
                "name": "Alba de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Alba de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Alba de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Albillos",
                "countryCode": "ES"
            },
            {
                "name": "Albornos",
                "countryCode": "ES"
            },
            {
                "name": "Alcazarén",
                "countryCode": "ES"
            },
            {
                "name": "Alcañices",
                "countryCode": "ES"
            },
            {
                "name": "Alcocero de Mola",
                "countryCode": "ES"
            },
            {
                "name": "Alconaba",
                "countryCode": "ES"
            },
            {
                "name": "Alconada",
                "countryCode": "ES"
            },
            {
                "name": "Alconada de Maderuelo",
                "countryCode": "ES"
            },
            {
                "name": "Alcubilla de Avellaneda",
                "countryCode": "ES"
            },
            {
                "name": "Alcubilla de Nogales",
                "countryCode": "ES"
            },
            {
                "name": "Alcubilla de las Peñas",
                "countryCode": "ES"
            },
            {
                "name": "Aldea Real",
                "countryCode": "ES"
            },
            {
                "name": "Aldea de San Miguel",
                "countryCode": "ES"
            },
            {
                "name": "Aldea del Obispo",
                "countryCode": "ES"
            },
            {
                "name": "Aldeacipreste",
                "countryCode": "ES"
            },
            {
                "name": "Aldeadávila de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Aldealafuente",
                "countryCode": "ES"
            },
            {
                "name": "Aldealcorvo",
                "countryCode": "ES"
            },
            {
                "name": "Aldealengua",
                "countryCode": "ES"
            },
            {
                "name": "Aldealengua de Santa María",
                "countryCode": "ES"
            },
            {
                "name": "Aldealpozo",
                "countryCode": "ES"
            },
            {
                "name": "Aldealseñor",
                "countryCode": "ES"
            },
            {
                "name": "Aldeamayor de San Martín",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de Figueroa",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de Santa Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva del Codonal",
                "countryCode": "ES"
            },
            {
                "name": "Aldearrodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Aldearrubia",
                "countryCode": "ES"
            },
            {
                "name": "Aldeaseca",
                "countryCode": "ES"
            },
            {
                "name": "Aldeaseca de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Aldeaseca de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Aldeasoña",
                "countryCode": "ES"
            },
            {
                "name": "Aldeatejada",
                "countryCode": "ES"
            },
            {
                "name": "Aldeavieja de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Aldehuela de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Aldehuela de la Bóveda",
                "countryCode": "ES"
            },
            {
                "name": "Aldehuela del Codonal",
                "countryCode": "ES"
            },
            {
                "name": "Aldeonte",
                "countryCode": "ES"
            },
            {
                "name": "Alentisque",
                "countryCode": "ES"
            },
            {
                "name": "Algadefe",
                "countryCode": "ES"
            },
            {
                "name": "Algodre",
                "countryCode": "ES"
            },
            {
                "name": "Aliud",
                "countryCode": "ES"
            },
            {
                "name": "Almajano",
                "countryCode": "ES"
            },
            {
                "name": "Almaluez",
                "countryCode": "ES"
            },
            {
                "name": "Almanza",
                "countryCode": "ES"
            },
            {
                "name": "Almaraz de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Almarza",
                "countryCode": "ES"
            },
            {
                "name": "Almazul",
                "countryCode": "ES"
            },
            {
                "name": "Almazán",
                "countryCode": "ES"
            },
            {
                "name": "Almenar de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Almenara de Adaja",
                "countryCode": "ES"
            },
            {
                "name": "Almenara de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Almendra",
                "countryCode": "ES"
            },
            {
                "name": "Alpanseque",
                "countryCode": "ES"
            },
            {
                "name": "Altable",
                "countryCode": "ES"
            },
            {
                "name": "Amavida",
                "countryCode": "ES"
            },
            {
                "name": "Amayuelas de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Ameyugo",
                "countryCode": "ES"
            },
            {
                "name": "Ampudia",
                "countryCode": "ES"
            },
            {
                "name": "Amusco",
                "countryCode": "ES"
            },
            {
                "name": "Amusquillo",
                "countryCode": "ES"
            },
            {
                "name": "Anaya",
                "countryCode": "ES"
            },
            {
                "name": "Anaya de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Anguix",
                "countryCode": "ES"
            },
            {
                "name": "Antigüedad",
                "countryCode": "ES"
            },
            {
                "name": "Arahuetes",
                "countryCode": "ES"
            },
            {
                "name": "Arancón",
                "countryCode": "ES"
            },
            {
                "name": "Aranda de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Arandilla",
                "countryCode": "ES"
            },
            {
                "name": "Arapiles",
                "countryCode": "ES"
            },
            {
                "name": "Arauzo de Miel",
                "countryCode": "ES"
            },
            {
                "name": "Arauzo de Salce",
                "countryCode": "ES"
            },
            {
                "name": "Arauzo de Torre",
                "countryCode": "ES"
            },
            {
                "name": "Arcediano",
                "countryCode": "ES"
            },
            {
                "name": "Arcenillas",
                "countryCode": "ES"
            },
            {
                "name": "Arconada",
                "countryCode": "ES"
            },
            {
                "name": "Arcones",
                "countryCode": "ES"
            },
            {
                "name": "Arcos",
                "countryCode": "ES"
            },
            {
                "name": "Arcos de Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Arcos de la Polvorosa",
                "countryCode": "ES"
            },
            {
                "name": "Ardón",
                "countryCode": "ES"
            },
            {
                "name": "Arenas de San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Arenillas",
                "countryCode": "ES"
            },
            {
                "name": "Arevalillo de Cega",
                "countryCode": "ES"
            },
            {
                "name": "Arganza",
                "countryCode": "ES"
            },
            {
                "name": "Argañín",
                "countryCode": "ES"
            },
            {
                "name": "Argujillo",
                "countryCode": "ES"
            },
            {
                "name": "Arija",
                "countryCode": "ES"
            },
            {
                "name": "Arlanzón",
                "countryCode": "ES"
            },
            {
                "name": "Armenteros",
                "countryCode": "ES"
            },
            {
                "name": "Armuña",
                "countryCode": "ES"
            },
            {
                "name": "Arquillinos",
                "countryCode": "ES"
            },
            {
                "name": "Arrabalde",
                "countryCode": "ES"
            },
            {
                "name": "Arraya de Oca",
                "countryCode": "ES"
            },
            {
                "name": "Arroyo de la Encomienda",
                "countryCode": "ES"
            },
            {
                "name": "Arévalo",
                "countryCode": "ES"
            },
            {
                "name": "Arévalo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Aspariegos",
                "countryCode": "ES"
            },
            {
                "name": "Astorga",
                "countryCode": "ES"
            },
            {
                "name": "Astudillo",
                "countryCode": "ES"
            },
            {
                "name": "Asturianos",
                "countryCode": "ES"
            },
            {
                "name": "Atapuerca",
                "countryCode": "ES"
            },
            {
                "name": "Ataquines",
                "countryCode": "ES"
            },
            {
                "name": "Ausejo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Autilla del Pino",
                "countryCode": "ES"
            },
            {
                "name": "Autillo de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Aveinte",
                "countryCode": "ES"
            },
            {
                "name": "Avellaneda",
                "countryCode": "ES"
            },
            {
                "name": "Avellanosa de Muñó",
                "countryCode": "ES"
            },
            {
                "name": "Ayllón",
                "countryCode": "ES"
            },
            {
                "name": "Ayoó de Vidriales",
                "countryCode": "ES"
            },
            {
                "name": "Ayuela",
                "countryCode": "ES"
            },
            {
                "name": "Añe",
                "countryCode": "ES"
            },
            {
                "name": "Añover de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Babilafuente",
                "countryCode": "ES"
            },
            {
                "name": "Bahabón",
                "countryCode": "ES"
            },
            {
                "name": "Bahabón de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Balboa",
                "countryCode": "ES"
            },
            {
                "name": "Baltanás",
                "countryCode": "ES"
            },
            {
                "name": "Baquerín de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Barbadillo de Herreros",
                "countryCode": "ES"
            },
            {
                "name": "Barbadillo del Mercado",
                "countryCode": "ES"
            },
            {
                "name": "Barbadillo del Pez",
                "countryCode": "ES"
            },
            {
                "name": "Barbalos",
                "countryCode": "ES"
            },
            {
                "name": "Barbolla",
                "countryCode": "ES"
            },
            {
                "name": "Barca",
                "countryCode": "ES"
            },
            {
                "name": "Barceo",
                "countryCode": "ES"
            },
            {
                "name": "Barcial de la Loma",
                "countryCode": "ES"
            },
            {
                "name": "Barcial del Barco",
                "countryCode": "ES"
            },
            {
                "name": "Barcones",
                "countryCode": "ES"
            },
            {
                "name": "Barjas",
                "countryCode": "ES"
            },
            {
                "name": "Barraco",
                "countryCode": "ES"
            },
            {
                "name": "Barrio de Muñó",
                "countryCode": "ES"
            },
            {
                "name": "Barrios de Colina",
                "countryCode": "ES"
            },
            {
                "name": "Barromán",
                "countryCode": "ES"
            },
            {
                "name": "Barruecopardo",
                "countryCode": "ES"
            },
            {
                "name": "Barruelo de Santullán",
                "countryCode": "ES"
            },
            {
                "name": "Basardilla",
                "countryCode": "ES"
            },
            {
                "name": "Basconcillos del Tozo",
                "countryCode": "ES"
            },
            {
                "name": "Bascuñana",
                "countryCode": "ES"
            },
            {
                "name": "Bayubas de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Bayubas de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Bañobárez",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Valdearados",
                "countryCode": "ES"
            },
            {
                "name": "Bañuelos de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Becedas",
                "countryCode": "ES"
            },
            {
                "name": "Becedillas",
                "countryCode": "ES"
            },
            {
                "name": "Becerril de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Becilla de Valderaduey",
                "countryCode": "ES"
            },
            {
                "name": "Belbimbre",
                "countryCode": "ES"
            },
            {
                "name": "Beleña",
                "countryCode": "ES"
            },
            {
                "name": "Belmonte de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Belorado",
                "countryCode": "ES"
            },
            {
                "name": "Belver de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Bembibre",
                "countryCode": "ES"
            },
            {
                "name": "Benafarces",
                "countryCode": "ES"
            },
            {
                "name": "Benavente",
                "countryCode": "ES"
            },
            {
                "name": "Benavides",
                "countryCode": "ES"
            },
            {
                "name": "Benegiles",
                "countryCode": "ES"
            },
            {
                "name": "Benuza",
                "countryCode": "ES"
            },
            {
                "name": "Beratón",
                "countryCode": "ES"
            },
            {
                "name": "Berberana",
                "countryCode": "ES"
            },
            {
                "name": "Bercero",
                "countryCode": "ES"
            },
            {
                "name": "Berceruelo",
                "countryCode": "ES"
            },
            {
                "name": "Bercial",
                "countryCode": "ES"
            },
            {
                "name": "Bercial de Zapardiel",
                "countryCode": "ES"
            },
            {
                "name": "Bercianos del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Bercianos del Real Camino",
                "countryCode": "ES"
            },
            {
                "name": "Bercimuel",
                "countryCode": "ES"
            },
            {
                "name": "Berlanga de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Berlanga del Bierzo",
                "countryCode": "ES"
            },
            {
                "name": "Berlangas de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Bermellar",
                "countryCode": "ES"
            },
            {
                "name": "Bermillo de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Bernardos",
                "countryCode": "ES"
            },
            {
                "name": "Berrocal de Huebra",
                "countryCode": "ES"
            },
            {
                "name": "Berrocal de Salvatierra",
                "countryCode": "ES"
            },
            {
                "name": "Berrocalejo de Aragona",
                "countryCode": "ES"
            },
            {
                "name": "Berrueces",
                "countryCode": "ES"
            },
            {
                "name": "Berzosa de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Berzosilla",
                "countryCode": "ES"
            },
            {
                "name": "Blacos",
                "countryCode": "ES"
            },
            {
                "name": "Blascomillán",
                "countryCode": "ES"
            },
            {
                "name": "Blascosancho",
                "countryCode": "ES"
            },
            {
                "name": "Bliecos",
                "countryCode": "ES"
            },
            {
                "name": "Boada",
                "countryCode": "ES"
            },
            {
                "name": "Boada de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Boadilla del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Bobadilla del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Boca de Huérgano",
                "countryCode": "ES"
            },
            {
                "name": "Boceguillas",
                "countryCode": "ES"
            },
            {
                "name": "Bocigas",
                "countryCode": "ES"
            },
            {
                "name": "Bocos de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Boecillo",
                "countryCode": "ES"
            },
            {
                "name": "Bogajo",
                "countryCode": "ES"
            },
            {
                "name": "Bohoyo",
                "countryCode": "ES"
            },
            {
                "name": "Bolaños de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Bonilla de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Borjabad",
                "countryCode": "ES"
            },
            {
                "name": "Borobia",
                "countryCode": "ES"
            },
            {
                "name": "Borrenes",
                "countryCode": "ES"
            },
            {
                "name": "Boñar",
                "countryCode": "ES"
            },
            {
                "name": "Brabos",
                "countryCode": "ES"
            },
            {
                "name": "Brazacorta",
                "countryCode": "ES"
            },
            {
                "name": "Brazuelo",
                "countryCode": "ES"
            },
            {
                "name": "Brañosera",
                "countryCode": "ES"
            },
            {
                "name": "Bretocino",
                "countryCode": "ES"
            },
            {
                "name": "Bretó",
                "countryCode": "ES"
            },
            {
                "name": "Brieva",
                "countryCode": "ES"
            },
            {
                "name": "Brime de Sog",
                "countryCode": "ES"
            },
            {
                "name": "Brime de Urz",
                "countryCode": "ES"
            },
            {
                "name": "Brincones",
                "countryCode": "ES"
            },
            {
                "name": "Briviesca",
                "countryCode": "ES"
            },
            {
                "name": "Buberos",
                "countryCode": "ES"
            },
            {
                "name": "Buenamadre",
                "countryCode": "ES"
            },
            {
                "name": "Buenavista",
                "countryCode": "ES"
            },
            {
                "name": "Buenavista de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Bugedo",
                "countryCode": "ES"
            },
            {
                "name": "Buitrago",
                "countryCode": "ES"
            },
            {
                "name": "Buniel",
                "countryCode": "ES"
            },
            {
                "name": "Burganes de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Burgohondo",
                "countryCode": "ES"
            },
            {
                "name": "Burgos",
                "countryCode": "ES"
            },
            {
                "name": "Burón",
                "countryCode": "ES"
            },
            {
                "name": "Bustillo de Chaves",
                "countryCode": "ES"
            },
            {
                "name": "Bustillo de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Bustillo del Oro",
                "countryCode": "ES"
            },
            {
                "name": "Bustillo del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Bustillo del Páramo de Carrión",
                "countryCode": "ES"
            },
            {
                "name": "Busto de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Bárcena de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Báscones de Ojeda",
                "countryCode": "ES"
            },
            {
                "name": "Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Bóveda del Río Almar",
                "countryCode": "ES"
            },
            {
                "name": "Caballar",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas de Polendos",
                "countryCode": "ES"
            },
            {
                "name": "Cabañas de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Cabañes de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Cabeza del Caballo",
                "countryCode": "ES"
            },
            {
                "name": "Cabezabellosa de la Calzada",
                "countryCode": "ES"
            },
            {
                "name": "Cabezas de Alambre",
                "countryCode": "ES"
            },
            {
                "name": "Cabezas del Pozo",
                "countryCode": "ES"
            },
            {
                "name": "Cabezas del Villar",
                "countryCode": "ES"
            },
            {
                "name": "Cabezuela",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón de Valderaduey",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Cabizuela",
                "countryCode": "ES"
            },
            {
                "name": "Cabrejas del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Cabrejas del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Cabrerizos",
                "countryCode": "ES"
            },
            {
                "name": "Cabreros del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Cabreros del Río",
                "countryCode": "ES"
            },
            {
                "name": "Cabrillanes",
                "countryCode": "ES"
            },
            {
                "name": "Cabrillas",
                "countryCode": "ES"
            },
            {
                "name": "Cacabelos",
                "countryCode": "ES"
            },
            {
                "name": "Calahorra de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "Calatañazor",
                "countryCode": "ES"
            },
            {
                "name": "Caleruega",
                "countryCode": "ES"
            },
            {
                "name": "Caltojar",
                "countryCode": "ES"
            },
            {
                "name": "Calvarrasa de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Calvarrasa de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Calzada de Don Diego",
                "countryCode": "ES"
            },
            {
                "name": "Calzada de Valdunciel",
                "countryCode": "ES"
            },
            {
                "name": "Calzada de los Molinos",
                "countryCode": "ES"
            },
            {
                "name": "Calzada del Coto",
                "countryCode": "ES"
            },
            {
                "name": "Calzadilla de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Camarzana de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Campaspero",
                "countryCode": "ES"
            },
            {
                "name": "Campazas",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Aranda",
                "countryCode": "ES"
            },
            {
                "name": "Campillo de Azaba",
                "countryCode": "ES"
            },
            {
                "name": "Campo de San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Campo de Villavidel",
                "countryCode": "ES"
            },
            {
                "name": "Campolara",
                "countryCode": "ES"
            },
            {
                "name": "Camponaraya",
                "countryCode": "ES"
            },
            {
                "name": "Camporredondo",
                "countryCode": "ES"
            },
            {
                "name": "Canalejas de Peñafiel",
                "countryCode": "ES"
            },
            {
                "name": "Canales",
                "countryCode": "ES"
            },
            {
                "name": "Candelario",
                "countryCode": "ES"
            },
            {
                "name": "Candeleda",
                "countryCode": "ES"
            },
            {
                "name": "Candilichera",
                "countryCode": "ES"
            },
            {
                "name": "Candín",
                "countryCode": "ES"
            },
            {
                "name": "Canicosa de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Canillas de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Canillas de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Cantabrana",
                "countryCode": "ES"
            },
            {
                "name": "Cantagallo",
                "countryCode": "ES"
            },
            {
                "name": "Cantalapiedra",
                "countryCode": "ES"
            },
            {
                "name": "Cantalejo",
                "countryCode": "ES"
            },
            {
                "name": "Cantalpino",
                "countryCode": "ES"
            },
            {
                "name": "Cantaracillo",
                "countryCode": "ES"
            },
            {
                "name": "Cantimpalos",
                "countryCode": "ES"
            },
            {
                "name": "Cantiveros",
                "countryCode": "ES"
            },
            {
                "name": "Capillas",
                "countryCode": "ES"
            },
            {
                "name": "Carabantes",
                "countryCode": "ES"
            },
            {
                "name": "Caracena",
                "countryCode": "ES"
            },
            {
                "name": "Carazo",
                "countryCode": "ES"
            },
            {
                "name": "Carbajales de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Carbajosa de la Sagrada",
                "countryCode": "ES"
            },
            {
                "name": "Carbellino",
                "countryCode": "ES"
            },
            {
                "name": "Carbonero el Mayor",
                "countryCode": "ES"
            },
            {
                "name": "Carcedo de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Carcedo de Burgos",
                "countryCode": "ES"
            },
            {
                "name": "Cardeñadijo",
                "countryCode": "ES"
            },
            {
                "name": "Cardeñajimeno",
                "countryCode": "ES"
            },
            {
                "name": "Cardeñosa",
                "countryCode": "ES"
            },
            {
                "name": "Cardeñosa de Volpejera",
                "countryCode": "ES"
            },
            {
                "name": "Carpio",
                "countryCode": "ES"
            },
            {
                "name": "Carpio de Azaba",
                "countryCode": "ES"
            },
            {
                "name": "Carracedelo",
                "countryCode": "ES"
            },
            {
                "name": "Carrascal de Barregas",
                "countryCode": "ES"
            },
            {
                "name": "Carrascal del Obispo",
                "countryCode": "ES"
            },
            {
                "name": "Carrascosa de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Carrascosa de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Carrias",
                "countryCode": "ES"
            },
            {
                "name": "Carrizo de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Carrión de los Condes",
                "countryCode": "ES"
            },
            {
                "name": "Carrocera",
                "countryCode": "ES"
            },
            {
                "name": "Carucedo",
                "countryCode": "ES"
            },
            {
                "name": "Casafranca",
                "countryCode": "ES"
            },
            {
                "name": "Casarejos",
                "countryCode": "ES"
            },
            {
                "name": "Casaseca de Campeán",
                "countryCode": "ES"
            },
            {
                "name": "Casaseca de las Chanas",
                "countryCode": "ES"
            },
            {
                "name": "Casasola de Arión",
                "countryCode": "ES"
            },
            {
                "name": "Casavieja",
                "countryCode": "ES"
            },
            {
                "name": "Cascajares de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Cascajares de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Casillas",
                "countryCode": "ES"
            },
            {
                "name": "Casillas de Flores",
                "countryCode": "ES"
            },
            {
                "name": "Casla",
                "countryCode": "ES"
            },
            {
                "name": "Castellanos de Castro",
                "countryCode": "ES"
            },
            {
                "name": "Castellanos de Moriscos",
                "countryCode": "ES"
            },
            {
                "name": "Castellanos de Villiquera",
                "countryCode": "ES"
            },
            {
                "name": "Castellanos de Zapardiel",
                "countryCode": "ES"
            },
            {
                "name": "Castil de Peones",
                "countryCode": "ES"
            },
            {
                "name": "Castil de Vela",
                "countryCode": "ES"
            },
            {
                "name": "Castildelgado",
                "countryCode": "ES"
            },
            {
                "name": "Castilfalé",
                "countryCode": "ES"
            },
            {
                "name": "Castilfrío de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Castillejo de Martín Viejo",
                "countryCode": "ES"
            },
            {
                "name": "Castillejo de Mesleón",
                "countryCode": "ES"
            },
            {
                "name": "Castillejo de Robledo",
                "countryCode": "ES"
            },
            {
                "name": "Castilruiz",
                "countryCode": "ES"
            },
            {
                "name": "Castraz",
                "countryCode": "ES"
            },
            {
                "name": "Castrejón de la Peña",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de Cabrera",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de Don Juan",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de Onielo",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de Villavega",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de la Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de la Valduerna",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo del Val",
                "countryCode": "ES"
            },
            {
                "name": "Castrillo-Tejeriego",
                "countryCode": "ES"
            },
            {
                "name": "Castro de Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Castrobol",
                "countryCode": "ES"
            },
            {
                "name": "Castrocalbón",
                "countryCode": "ES"
            },
            {
                "name": "Castrocontrigo",
                "countryCode": "ES"
            },
            {
                "name": "Castrodeza",
                "countryCode": "ES"
            },
            {
                "name": "Castrogonzalo",
                "countryCode": "ES"
            },
            {
                "name": "Castrojimeno",
                "countryCode": "ES"
            },
            {
                "name": "Castromembibre",
                "countryCode": "ES"
            },
            {
                "name": "Castromocho",
                "countryCode": "ES"
            },
            {
                "name": "Castromonte",
                "countryCode": "ES"
            },
            {
                "name": "Castronuevo",
                "countryCode": "ES"
            },
            {
                "name": "Castronuevo de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Castronuño",
                "countryCode": "ES"
            },
            {
                "name": "Castropodame",
                "countryCode": "ES"
            },
            {
                "name": "Castroponce",
                "countryCode": "ES"
            },
            {
                "name": "Castroserna de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Castroserracín",
                "countryCode": "ES"
            },
            {
                "name": "Castroverde de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Castroverde de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Cayuela",
                "countryCode": "ES"
            },
            {
                "name": "Cazurra",
                "countryCode": "ES"
            },
            {
                "name": "Cañamaque",
                "countryCode": "ES"
            },
            {
                "name": "Cañizal",
                "countryCode": "ES"
            },
            {
                "name": "Cañizo",
                "countryCode": "ES"
            },
            {
                "name": "Cebanico",
                "countryCode": "ES"
            },
            {
                "name": "Cebrecos",
                "countryCode": "ES"
            },
            {
                "name": "Cebreros",
                "countryCode": "ES"
            },
            {
                "name": "Cebrones del Río",
                "countryCode": "ES"
            },
            {
                "name": "Cedillo de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Ceinos de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Celada del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Centenera de Andaluz",
                "countryCode": "ES"
            },
            {
                "name": "Cepeda",
                "countryCode": "ES"
            },
            {
                "name": "Cepeda la Mora",
                "countryCode": "ES"
            },
            {
                "name": "Cerbón",
                "countryCode": "ES"
            },
            {
                "name": "Cereceda de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Cerecinos de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Cerecinos del Carrizal",
                "countryCode": "ES"
            },
            {
                "name": "Cerezal de Peñahorcada",
                "countryCode": "ES"
            },
            {
                "name": "Cerezo de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Cerezo de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Cernadilla",
                "countryCode": "ES"
            },
            {
                "name": "Cerralbo",
                "countryCode": "ES"
            },
            {
                "name": "Cerratón de Juarros",
                "countryCode": "ES"
            },
            {
                "name": "Cervatos de la Cueza",
                "countryCode": "ES"
            },
            {
                "name": "Cervera de Pisuerga",
                "countryCode": "ES"
            },
            {
                "name": "Cervillego de la Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Cevico Navero",
                "countryCode": "ES"
            },
            {
                "name": "Cevico de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Chamartín",
                "countryCode": "ES"
            },
            {
                "name": "Chañe",
                "countryCode": "ES"
            },
            {
                "name": "Chozas de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Ciadoncha",
                "countryCode": "ES"
            },
            {
                "name": "Cidones",
                "countryCode": "ES"
            },
            {
                "name": "Cigales",
                "countryCode": "ES"
            },
            {
                "name": "Cigudosa",
                "countryCode": "ES"
            },
            {
                "name": "Ciguñuela",
                "countryCode": "ES"
            },
            {
                "name": "Cihuela",
                "countryCode": "ES"
            },
            {
                "name": "Cilleros de la Bastida",
                "countryCode": "ES"
            },
            {
                "name": "Cilleruelo de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Cilleruelo de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Cilleruelo de San Mamés",
                "countryCode": "ES"
            },
            {
                "name": "Cillán",
                "countryCode": "ES"
            },
            {
                "name": "Cimanes de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Cimanes del Tejar",
                "countryCode": "ES"
            },
            {
                "name": "Cipérez",
                "countryCode": "ES"
            },
            {
                "name": "Ciria",
                "countryCode": "ES"
            },
            {
                "name": "Ciruelos de Cervera",
                "countryCode": "ES"
            },
            {
                "name": "Cirujales del Río",
                "countryCode": "ES"
            },
            {
                "name": "Cisla",
                "countryCode": "ES"
            },
            {
                "name": "Cisneros",
                "countryCode": "ES"
            },
            {
                "name": "Cistierna",
                "countryCode": "ES"
            },
            {
                "name": "Cistérniga",
                "countryCode": "ES"
            },
            {
                "name": "Ciudad Rodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Cobos de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Cobos de Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Cobreros",
                "countryCode": "ES"
            },
            {
                "name": "Coca",
                "countryCode": "ES"
            },
            {
                "name": "Coca de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Codorniz",
                "countryCode": "ES"
            },
            {
                "name": "Cogeces del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Cogollos",
                "countryCode": "ES"
            },
            {
                "name": "Collado Hermoso",
                "countryCode": "ES"
            },
            {
                "name": "Collado de Contreras",
                "countryCode": "ES"
            },
            {
                "name": "Collado del Mirón",
                "countryCode": "ES"
            },
            {
                "name": "Collazos de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "Colmenar de Montemayor",
                "countryCode": "ES"
            },
            {
                "name": "Congosto",
                "countryCode": "ES"
            },
            {
                "name": "Congosto de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Constanzana",
                "countryCode": "ES"
            },
            {
                "name": "Contreras",
                "countryCode": "ES"
            },
            {
                "name": "Coomonte",
                "countryCode": "ES"
            },
            {
                "name": "Corbillos de los Oteros",
                "countryCode": "ES"
            },
            {
                "name": "Corcos",
                "countryCode": "ES"
            },
            {
                "name": "Cordovilla",
                "countryCode": "ES"
            },
            {
                "name": "Cordovilla la Real",
                "countryCode": "ES"
            },
            {
                "name": "Coreses",
                "countryCode": "ES"
            },
            {
                "name": "Corral de Ayllón",
                "countryCode": "ES"
            },
            {
                "name": "Corrales",
                "countryCode": "ES"
            },
            {
                "name": "Corrales de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Corullón",
                "countryCode": "ES"
            },
            {
                "name": "Coruña del Conde",
                "countryCode": "ES"
            },
            {
                "name": "Coscurita",
                "countryCode": "ES"
            },
            {
                "name": "Covaleda",
                "countryCode": "ES"
            },
            {
                "name": "Covarrubias",
                "countryCode": "ES"
            },
            {
                "name": "Cozuelos de Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Crespos",
                "countryCode": "ES"
            },
            {
                "name": "Cristóbal",
                "countryCode": "ES"
            },
            {
                "name": "Crémenes",
                "countryCode": "ES"
            },
            {
                "name": "Cuadros",
                "countryCode": "ES"
            },
            {
                "name": "Cubilla",
                "countryCode": "ES"
            },
            {
                "name": "Cubillas de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Cubillas de Rueda",
                "countryCode": "ES"
            },
            {
                "name": "Cubillas de Santa Marta",
                "countryCode": "ES"
            },
            {
                "name": "Cubillas de los Oteros",
                "countryCode": "ES"
            },
            {
                "name": "Cubillo",
                "countryCode": "ES"
            },
            {
                "name": "Cubillo del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Cubillos",
                "countryCode": "ES"
            },
            {
                "name": "Cubillos del Sil",
                "countryCode": "ES"
            },
            {
                "name": "Cubo de Benavente",
                "countryCode": "ES"
            },
            {
                "name": "Cubo de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Cubo de la Solana",
                "countryCode": "ES"
            },
            {
                "name": "Cuelgamures",
                "countryCode": "ES"
            },
            {
                "name": "Cuenca de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Cueva de Ágreda",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas de Provanco",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas de San Clemente",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Curiel de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Cuéllar",
                "countryCode": "ES"
            },
            {
                "name": "Cármenes",
                "countryCode": "ES"
            },
            {
                "name": "Dehesa de Montejo",
                "countryCode": "ES"
            },
            {
                "name": "Dehesa de Romanos",
                "countryCode": "ES"
            },
            {
                "name": "Destriana",
                "countryCode": "ES"
            },
            {
                "name": "Deza",
                "countryCode": "ES"
            },
            {
                "name": "Dios le Guarde",
                "countryCode": "ES"
            },
            {
                "name": "Domingo García",
                "countryCode": "ES"
            },
            {
                "name": "Donhierro",
                "countryCode": "ES"
            },
            {
                "name": "Donjimeno",
                "countryCode": "ES"
            },
            {
                "name": "Donvidas",
                "countryCode": "ES"
            },
            {
                "name": "Doñinos de Ledesma",
                "countryCode": "ES"
            },
            {
                "name": "Doñinos de Salamanca",
                "countryCode": "ES"
            },
            {
                "name": "Dueñas",
                "countryCode": "ES"
            },
            {
                "name": "Duruelo",
                "countryCode": "ES"
            },
            {
                "name": "Duruelo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Ejeme",
                "countryCode": "ES"
            },
            {
                "name": "El Barco de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "El Burgo de Osma",
                "countryCode": "ES"
            },
            {
                "name": "El Hoyo de Pinares",
                "countryCode": "ES"
            },
            {
                "name": "El Tiemblo",
                "countryCode": "ES"
            },
            {
                "name": "Encina de San Silvestre",
                "countryCode": "ES"
            },
            {
                "name": "Encinas",
                "countryCode": "ES"
            },
            {
                "name": "Encinas de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Encinas de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Encinas de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Encinasola de los Comendadores",
                "countryCode": "ES"
            },
            {
                "name": "Encinedo",
                "countryCode": "ES"
            },
            {
                "name": "Encinillas",
                "countryCode": "ES"
            },
            {
                "name": "Encío",
                "countryCode": "ES"
            },
            {
                "name": "Endrinal",
                "countryCode": "ES"
            },
            {
                "name": "Entrala",
                "countryCode": "ES"
            },
            {
                "name": "Escalona del Prado",
                "countryCode": "ES"
            },
            {
                "name": "Escarabajosa de Cabezas",
                "countryCode": "ES"
            },
            {
                "name": "Escobar de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Escobar de Polendos",
                "countryCode": "ES"
            },
            {
                "name": "Escobosa de Almazán",
                "countryCode": "ES"
            },
            {
                "name": "Escurial de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Esguevillas de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Espadaña",
                "countryCode": "ES"
            },
            {
                "name": "Espadañedo",
                "countryCode": "ES"
            },
            {
                "name": "Espeja",
                "countryCode": "ES"
            },
            {
                "name": "Espeja de San Marcelino",
                "countryCode": "ES"
            },
            {
                "name": "Espejón",
                "countryCode": "ES"
            },
            {
                "name": "Espino de la Orbada",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa de Cervera",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa de Villagonzalo",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa de los Monteros",
                "countryCode": "ES"
            },
            {
                "name": "Espinosa del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Espirdo",
                "countryCode": "ES"
            },
            {
                "name": "Estepa de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Estépar",
                "countryCode": "ES"
            },
            {
                "name": "Fabero",
                "countryCode": "ES"
            },
            {
                "name": "Faramontanos de Tábara",
                "countryCode": "ES"
            },
            {
                "name": "Fariza",
                "countryCode": "ES"
            },
            {
                "name": "Fermoselle",
                "countryCode": "ES"
            },
            {
                "name": "Ferreras de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Ferreras de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Ferreruela",
                "countryCode": "ES"
            },
            {
                "name": "Figueruela de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Flores de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Florida de Liébana",
                "countryCode": "ES"
            },
            {
                "name": "Folgoso de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Fombellida",
                "countryCode": "ES"
            },
            {
                "name": "Fompedraza",
                "countryCode": "ES"
            },
            {
                "name": "Fonfría",
                "countryCode": "ES"
            },
            {
                "name": "Fontihoyuelo",
                "countryCode": "ES"
            },
            {
                "name": "Fontioso",
                "countryCode": "ES"
            },
            {
                "name": "Fontiveros",
                "countryCode": "ES"
            },
            {
                "name": "Forfoleda",
                "countryCode": "ES"
            },
            {
                "name": "Frades de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Frandovínez",
                "countryCode": "ES"
            },
            {
                "name": "Frechilla",
                "countryCode": "ES"
            },
            {
                "name": "Frechilla de Almazán",
                "countryCode": "ES"
            },
            {
                "name": "Fresneda de Cuéllar",
                "countryCode": "ES"
            },
            {
                "name": "Fresneda de la Sierra Tirón",
                "countryCode": "ES"
            },
            {
                "name": "Fresnedilla",
                "countryCode": "ES"
            },
            {
                "name": "Fresnedoso",
                "countryCode": "ES"
            },
            {
                "name": "Fresneña",
                "countryCode": "ES"
            },
            {
                "name": "Fresnillo de las Dueñas",
                "countryCode": "ES"
            },
            {
                "name": "Fresno El Viejo",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de Cantespino",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de Caracena",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de Rodilla",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de la Fuente",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de la Polvorosa",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Fresno del Río",
                "countryCode": "ES"
            },
            {
                "name": "Friera de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Frumales",
                "countryCode": "ES"
            },
            {
                "name": "Frías",
                "countryCode": "ES"
            },
            {
                "name": "Frómista",
                "countryCode": "ES"
            },
            {
                "name": "Fuensaldaña",
                "countryCode": "ES"
            },
            {
                "name": "Fuente Encalada",
                "countryCode": "ES"
            },
            {
                "name": "Fuente de Santa Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Fuente el Olmo de Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Fuente el Sol",
                "countryCode": "ES"
            },
            {
                "name": "Fuentearmegil",
                "countryCode": "ES"
            },
            {
                "name": "Fuentebureba",
                "countryCode": "ES"
            },
            {
                "name": "Fuentecambrón",
                "countryCode": "ES"
            },
            {
                "name": "Fuentecantos",
                "countryCode": "ES"
            },
            {
                "name": "Fuentecén",
                "countryCode": "ES"
            },
            {
                "name": "Fuenteguinaldo",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelapeña",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelcésped",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelisendo",
                "countryCode": "ES"
            },
            {
                "name": "Fuentelsaz de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Fuentemolinos",
                "countryCode": "ES"
            },
            {
                "name": "Fuentenebro",
                "countryCode": "ES"
            },
            {
                "name": "Fuentepelayo",
                "countryCode": "ES"
            },
            {
                "name": "Fuentepinilla",
                "countryCode": "ES"
            },
            {
                "name": "Fuentepiñel",
                "countryCode": "ES"
            },
            {
                "name": "Fuenterroble de Salvatierra",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Año",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Carbajal",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Magaña",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Nava",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Oñoro",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Ropel",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Valdepero",
                "countryCode": "ES"
            },
            {
                "name": "Fuentesaúco",
                "countryCode": "ES"
            },
            {
                "name": "Fuentesaúco de Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Fuentesecas",
                "countryCode": "ES"
            },
            {
                "name": "Fuentesoto",
                "countryCode": "ES"
            },
            {
                "name": "Fuentespina",
                "countryCode": "ES"
            },
            {
                "name": "Fuentespreadas",
                "countryCode": "ES"
            },
            {
                "name": "Fuentestrún",
                "countryCode": "ES"
            },
            {
                "name": "Fuentidueña",
                "countryCode": "ES"
            },
            {
                "name": "Gajates",
                "countryCode": "ES"
            },
            {
                "name": "Galbarros",
                "countryCode": "ES"
            },
            {
                "name": "Galende",
                "countryCode": "ES"
            },
            {
                "name": "Galindo y Perahuy",
                "countryCode": "ES"
            },
            {
                "name": "Galinduste",
                "countryCode": "ES"
            },
            {
                "name": "Galisancho",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos de Argañán",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos de Hornija",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos de Sobrinos",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos del Pan",
                "countryCode": "ES"
            },
            {
                "name": "Gallegos del Río",
                "countryCode": "ES"
            },
            {
                "name": "Gamonal",
                "countryCode": "ES"
            },
            {
                "name": "Gamones",
                "countryCode": "ES"
            },
            {
                "name": "Garcibuey",
                "countryCode": "ES"
            },
            {
                "name": "Garcihernández",
                "countryCode": "ES"
            },
            {
                "name": "Garcillán",
                "countryCode": "ES"
            },
            {
                "name": "Garcirrey",
                "countryCode": "ES"
            },
            {
                "name": "Garganta del Villar",
                "countryCode": "ES"
            },
            {
                "name": "Garrafe de Torío",
                "countryCode": "ES"
            },
            {
                "name": "Garray",
                "countryCode": "ES"
            },
            {
                "name": "Gatón de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Gavilanes",
                "countryCode": "ES"
            },
            {
                "name": "Gejuelo del Barro",
                "countryCode": "ES"
            },
            {
                "name": "Gema",
                "countryCode": "ES"
            },
            {
                "name": "Gemuño",
                "countryCode": "ES"
            },
            {
                "name": "Geria",
                "countryCode": "ES"
            },
            {
                "name": "Gimialcón",
                "countryCode": "ES"
            },
            {
                "name": "Golmayo",
                "countryCode": "ES"
            },
            {
                "name": "Golpejas",
                "countryCode": "ES"
            },
            {
                "name": "Gomecello",
                "countryCode": "ES"
            },
            {
                "name": "Gordaliza del Pino",
                "countryCode": "ES"
            },
            {
                "name": "Gordoncillo",
                "countryCode": "ES"
            },
            {
                "name": "Gormaz",
                "countryCode": "ES"
            },
            {
                "name": "Gotarrendura",
                "countryCode": "ES"
            },
            {
                "name": "Gradefes",
                "countryCode": "ES"
            },
            {
                "name": "Grajal de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Grajera",
                "countryCode": "ES"
            },
            {
                "name": "Granja de Moreruela",
                "countryCode": "ES"
            },
            {
                "name": "Granucillo",
                "countryCode": "ES"
            },
            {
                "name": "Grijalba",
                "countryCode": "ES"
            },
            {
                "name": "Grijota",
                "countryCode": "ES"
            },
            {
                "name": "Grisaleña",
                "countryCode": "ES"
            },
            {
                "name": "Guadramiro",
                "countryCode": "ES"
            },
            {
                "name": "Guardo",
                "countryCode": "ES"
            },
            {
                "name": "Guaza de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Guijo de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Guijuelo",
                "countryCode": "ES"
            },
            {
                "name": "Guisando",
                "countryCode": "ES"
            },
            {
                "name": "Gusendos de los Oteros",
                "countryCode": "ES"
            },
            {
                "name": "Gutierre-Muñoz",
                "countryCode": "ES"
            },
            {
                "name": "Gómara",
                "countryCode": "ES"
            },
            {
                "name": "Hacinas",
                "countryCode": "ES"
            },
            {
                "name": "Haza",
                "countryCode": "ES"
            },
            {
                "name": "Herguijuela de Ciudad Rodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Herguijuela de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Herguijuela del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Hermisende",
                "countryCode": "ES"
            },
            {
                "name": "Hernansancho",
                "countryCode": "ES"
            },
            {
                "name": "Herrera de Pisuerga",
                "countryCode": "ES"
            },
            {
                "name": "Herrera de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Herrera de Valdecañas",
                "countryCode": "ES"
            },
            {
                "name": "Herreros de Suso",
                "countryCode": "ES"
            },
            {
                "name": "Herrín de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Higuera de las Dueñas",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Hinojosa del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Honrubia de la Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "Hontalbilla",
                "countryCode": "ES"
            },
            {
                "name": "Hontanares de Eresma",
                "countryCode": "ES"
            },
            {
                "name": "Hontanas",
                "countryCode": "ES"
            },
            {
                "name": "Hontangas",
                "countryCode": "ES"
            },
            {
                "name": "Hontoria de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Hontoria de Valdearados",
                "countryCode": "ES"
            },
            {
                "name": "Hontoria de la Cantera",
                "countryCode": "ES"
            },
            {
                "name": "Hontoria del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Horcajo de Montemayor",
                "countryCode": "ES"
            },
            {
                "name": "Horcajo de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Hornillos de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Hornillos del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Hortigüela",
                "countryCode": "ES"
            },
            {
                "name": "Hospital de Órbigo",
                "countryCode": "ES"
            },
            {
                "name": "Hoyales de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Hoyocasero",
                "countryCode": "ES"
            },
            {
                "name": "Hoyorredondo",
                "countryCode": "ES"
            },
            {
                "name": "Hoyos de Miguel Muñoz",
                "countryCode": "ES"
            },
            {
                "name": "Hoyos del Collado",
                "countryCode": "ES"
            },
            {
                "name": "Hoyos del Espino",
                "countryCode": "ES"
            },
            {
                "name": "Huerta de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Huerta del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Humada",
                "countryCode": "ES"
            },
            {
                "name": "Hurones",
                "countryCode": "ES"
            },
            {
                "name": "Hurtumpascual",
                "countryCode": "ES"
            },
            {
                "name": "Husillos",
                "countryCode": "ES"
            },
            {
                "name": "Huérmeces",
                "countryCode": "ES"
            },
            {
                "name": "Hérmedes de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Ibeas de Juarros",
                "countryCode": "ES"
            },
            {
                "name": "Ibrillos",
                "countryCode": "ES"
            },
            {
                "name": "Iglesiarrubia",
                "countryCode": "ES"
            },
            {
                "name": "Iglesias",
                "countryCode": "ES"
            },
            {
                "name": "Igüeña",
                "countryCode": "ES"
            },
            {
                "name": "Iruelos",
                "countryCode": "ES"
            },
            {
                "name": "Isar",
                "countryCode": "ES"
            },
            {
                "name": "Iscar",
                "countryCode": "ES"
            },
            {
                "name": "Itero de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Itero del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Ituero de Azaba",
                "countryCode": "ES"
            },
            {
                "name": "Ituero y Lama",
                "countryCode": "ES"
            },
            {
                "name": "Izagre",
                "countryCode": "ES"
            },
            {
                "name": "Jambrina",
                "countryCode": "ES"
            },
            {
                "name": "Jaramillo Quemado",
                "countryCode": "ES"
            },
            {
                "name": "Jaramillo de la Fuente",
                "countryCode": "ES"
            },
            {
                "name": "Joarilla de las Matas",
                "countryCode": "ES"
            },
            {
                "name": "Juarros de Voltoya",
                "countryCode": "ES"
            },
            {
                "name": "Junciana",
                "countryCode": "ES"
            },
            {
                "name": "Justel",
                "countryCode": "ES"
            },
            {
                "name": "Juzbado",
                "countryCode": "ES"
            },
            {
                "name": "La Adrada",
                "countryCode": "ES"
            },
            {
                "name": "La Alberca",
                "countryCode": "ES"
            },
            {
                "name": "La Bañeza",
                "countryCode": "ES"
            },
            {
                "name": "La Bouza",
                "countryCode": "ES"
            },
            {
                "name": "La Carrera",
                "countryCode": "ES"
            },
            {
                "name": "La Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "La Fuente de San Esteban",
                "countryCode": "ES"
            },
            {
                "name": "La Lastrilla",
                "countryCode": "ES"
            },
            {
                "name": "La Pedraja de Portillo",
                "countryCode": "ES"
            },
            {
                "name": "La Pola de Gordón",
                "countryCode": "ES"
            },
            {
                "name": "La Robla",
                "countryCode": "ES"
            },
            {
                "name": "La Seca",
                "countryCode": "ES"
            },
            {
                "name": "Labajos",
                "countryCode": "ES"
            },
            {
                "name": "Lagartos",
                "countryCode": "ES"
            },
            {
                "name": "Laguna Dalga",
                "countryCode": "ES"
            },
            {
                "name": "Laguna de Contreras",
                "countryCode": "ES"
            },
            {
                "name": "Laguna de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Laguna de Negrillos",
                "countryCode": "ES"
            },
            {
                "name": "Lagunilla",
                "countryCode": "ES"
            },
            {
                "name": "Langa",
                "countryCode": "ES"
            },
            {
                "name": "Langa de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Langayo",
                "countryCode": "ES"
            },
            {
                "name": "Languilla",
                "countryCode": "ES"
            },
            {
                "name": "Lantadilla",
                "countryCode": "ES"
            },
            {
                "name": "Larrodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Las Navas del Marqués",
                "countryCode": "ES"
            },
            {
                "name": "Lastras de Cuéllar",
                "countryCode": "ES"
            },
            {
                "name": "Lastras del Pozo",
                "countryCode": "ES"
            },
            {
                "name": "Ledesma",
                "countryCode": "ES"
            },
            {
                "name": "Ledigos",
                "countryCode": "ES"
            },
            {
                "name": "Ledrada",
                "countryCode": "ES"
            },
            {
                "name": "Lerma",
                "countryCode": "ES"
            },
            {
                "name": "León",
                "countryCode": "ES"
            },
            {
                "name": "Liceras",
                "countryCode": "ES"
            },
            {
                "name": "Linares de Riofrío",
                "countryCode": "ES"
            },
            {
                "name": "Llamas de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Llano de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Llano de Olmedo",
                "countryCode": "ES"
            },
            {
                "name": "Lomas",
                "countryCode": "ES"
            },
            {
                "name": "Losacino",
                "countryCode": "ES"
            },
            {
                "name": "Losacio",
                "countryCode": "ES"
            },
            {
                "name": "Lubián",
                "countryCode": "ES"
            },
            {
                "name": "Luelmo",
                "countryCode": "ES"
            },
            {
                "name": "Lumbrales",
                "countryCode": "ES"
            },
            {
                "name": "Luyego",
                "countryCode": "ES"
            },
            {
                "name": "Machacón",
                "countryCode": "ES"
            },
            {
                "name": "Macotera",
                "countryCode": "ES"
            },
            {
                "name": "Maderuelo",
                "countryCode": "ES"
            },
            {
                "name": "Madridanos",
                "countryCode": "ES"
            },
            {
                "name": "Madrigal de las Altas Torres",
                "countryCode": "ES"
            },
            {
                "name": "Madrigal del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Madrigalejo del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Madroñal",
                "countryCode": "ES"
            },
            {
                "name": "Maello",
                "countryCode": "ES"
            },
            {
                "name": "Magaz de Cepeda",
                "countryCode": "ES"
            },
            {
                "name": "Magaña",
                "countryCode": "ES"
            },
            {
                "name": "Mahamud",
                "countryCode": "ES"
            },
            {
                "name": "Mahide",
                "countryCode": "ES"
            },
            {
                "name": "Maire de Castroponce",
                "countryCode": "ES"
            },
            {
                "name": "Maján",
                "countryCode": "ES"
            },
            {
                "name": "Malpartida",
                "countryCode": "ES"
            },
            {
                "name": "Malpartida de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "Malva",
                "countryCode": "ES"
            },
            {
                "name": "Mamblas",
                "countryCode": "ES"
            },
            {
                "name": "Mambrilla de Castrejón",
                "countryCode": "ES"
            },
            {
                "name": "Mambrillas de Lara",
                "countryCode": "ES"
            },
            {
                "name": "Mamolar",
                "countryCode": "ES"
            },
            {
                "name": "Mancera de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Mancera de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Manciles",
                "countryCode": "ES"
            },
            {
                "name": "Manganeses de la Lampreana",
                "countryCode": "ES"
            },
            {
                "name": "Manganeses de la Polvorosa",
                "countryCode": "ES"
            },
            {
                "name": "Manjabálago",
                "countryCode": "ES"
            },
            {
                "name": "Manquillos",
                "countryCode": "ES"
            },
            {
                "name": "Mansilla Mayor",
                "countryCode": "ES"
            },
            {
                "name": "Mansilla de las Mulas",
                "countryCode": "ES"
            },
            {
                "name": "Mantinos",
                "countryCode": "ES"
            },
            {
                "name": "Manzanal de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Manzanal de los Infantes",
                "countryCode": "ES"
            },
            {
                "name": "Manzanal del Barco",
                "countryCode": "ES"
            },
            {
                "name": "Manzanillo",
                "countryCode": "ES"
            },
            {
                "name": "Marazoleja",
                "countryCode": "ES"
            },
            {
                "name": "Marazuela",
                "countryCode": "ES"
            },
            {
                "name": "Maraña",
                "countryCode": "ES"
            },
            {
                "name": "Marcilla de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Martiago",
                "countryCode": "ES"
            },
            {
                "name": "Martiherrero",
                "countryCode": "ES"
            },
            {
                "name": "Martinamor",
                "countryCode": "ES"
            },
            {
                "name": "Martín Miguel",
                "countryCode": "ES"
            },
            {
                "name": "Martín Muñoz de la Dehesa",
                "countryCode": "ES"
            },
            {
                "name": "Martín Muñoz de las Posadas",
                "countryCode": "ES"
            },
            {
                "name": "Martín de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Martínez",
                "countryCode": "ES"
            },
            {
                "name": "Marugán",
                "countryCode": "ES"
            },
            {
                "name": "Marzales",
                "countryCode": "ES"
            },
            {
                "name": "Masueco",
                "countryCode": "ES"
            },
            {
                "name": "Mata de Cuéllar",
                "countryCode": "ES"
            },
            {
                "name": "Matabuena",
                "countryCode": "ES"
            },
            {
                "name": "Matadeón de los Oteros",
                "countryCode": "ES"
            },
            {
                "name": "Matalebreras",
                "countryCode": "ES"
            },
            {
                "name": "Matallana de Torío",
                "countryCode": "ES"
            },
            {
                "name": "Matamala de Almazán",
                "countryCode": "ES"
            },
            {
                "name": "Matapozuelos",
                "countryCode": "ES"
            },
            {
                "name": "Matilla de Arzón",
                "countryCode": "ES"
            },
            {
                "name": "Matilla de los Caños",
                "countryCode": "ES"
            },
            {
                "name": "Matilla de los Caños del Río",
                "countryCode": "ES"
            },
            {
                "name": "Matilla la Seca",
                "countryCode": "ES"
            },
            {
                "name": "Mayalde",
                "countryCode": "ES"
            },
            {
                "name": "Mayorga",
                "countryCode": "ES"
            },
            {
                "name": "Mazariegos",
                "countryCode": "ES"
            },
            {
                "name": "Mazuecos de Valdeginate",
                "countryCode": "ES"
            },
            {
                "name": "Mazuela",
                "countryCode": "ES"
            },
            {
                "name": "Mecerreyes",
                "countryCode": "ES"
            },
            {
                "name": "Mediana de Voltoya",
                "countryCode": "ES"
            },
            {
                "name": "Medina de Pomar",
                "countryCode": "ES"
            },
            {
                "name": "Medina de Ríoseco",
                "countryCode": "ES"
            },
            {
                "name": "Medina del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Medinaceli",
                "countryCode": "ES"
            },
            {
                "name": "Medinilla",
                "countryCode": "ES"
            },
            {
                "name": "Megeces",
                "countryCode": "ES"
            },
            {
                "name": "Melgar de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Melgar de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Melgar de Fernamental",
                "countryCode": "ES"
            },
            {
                "name": "Melgar de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Melgar de Yuso",
                "countryCode": "ES"
            },
            {
                "name": "Membibre de la Hoz",
                "countryCode": "ES"
            },
            {
                "name": "Membribe de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Meneses de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Mengamuñoz",
                "countryCode": "ES"
            },
            {
                "name": "Mesegar de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "Micereces de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Micieces de Ojeda",
                "countryCode": "ES"
            },
            {
                "name": "Mieza",
                "countryCode": "ES"
            },
            {
                "name": "Migueláñez",
                "countryCode": "ES"
            },
            {
                "name": "Mijares",
                "countryCode": "ES"
            },
            {
                "name": "Milagros",
                "countryCode": "ES"
            },
            {
                "name": "Milles de la Polvorosa",
                "countryCode": "ES"
            },
            {
                "name": "Mingorría",
                "countryCode": "ES"
            },
            {
                "name": "Miranda de Azán",
                "countryCode": "ES"
            },
            {
                "name": "Miranda de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Miranda del Castañar",
                "countryCode": "ES"
            },
            {
                "name": "Miraveche",
                "countryCode": "ES"
            },
            {
                "name": "Mironcillo",
                "countryCode": "ES"
            },
            {
                "name": "Miño de San Esteban",
                "countryCode": "ES"
            },
            {
                "name": "Modúbar de la Emparedada",
                "countryCode": "ES"
            },
            {
                "name": "Mogarraz",
                "countryCode": "ES"
            },
            {
                "name": "Mojados",
                "countryCode": "ES"
            },
            {
                "name": "Molacillos",
                "countryCode": "ES"
            },
            {
                "name": "Molezuelas de la Carballeda",
                "countryCode": "ES"
            },
            {
                "name": "Molinaseca",
                "countryCode": "ES"
            },
            {
                "name": "Molinillo",
                "countryCode": "ES"
            },
            {
                "name": "Molinos de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Mombeltrán",
                "countryCode": "ES"
            },
            {
                "name": "Momblona",
                "countryCode": "ES"
            },
            {
                "name": "Mombuey",
                "countryCode": "ES"
            },
            {
                "name": "Monasterio de Rodilla",
                "countryCode": "ES"
            },
            {
                "name": "Monasterio de Vega",
                "countryCode": "ES"
            },
            {
                "name": "Monasterio de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Moncalvillo",
                "countryCode": "ES"
            },
            {
                "name": "Monfarracinos",
                "countryCode": "ES"
            },
            {
                "name": "Monforte de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Monleras",
                "countryCode": "ES"
            },
            {
                "name": "Monleón",
                "countryCode": "ES"
            },
            {
                "name": "Monsagro",
                "countryCode": "ES"
            },
            {
                "name": "Monsalupe",
                "countryCode": "ES"
            },
            {
                "name": "Montamarta",
                "countryCode": "ES"
            },
            {
                "name": "Monteagudo de las Vicarías",
                "countryCode": "ES"
            },
            {
                "name": "Montejo",
                "countryCode": "ES"
            },
            {
                "name": "Montejo de Arévalo",
                "countryCode": "ES"
            },
            {
                "name": "Montejo de Tiermes",
                "countryCode": "ES"
            },
            {
                "name": "Montejo de la Vega de la Serrezuela",
                "countryCode": "ES"
            },
            {
                "name": "Montemayor de Pililla",
                "countryCode": "ES"
            },
            {
                "name": "Montemayor del Río",
                "countryCode": "ES"
            },
            {
                "name": "Montenegro de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Monterrubio",
                "countryCode": "ES"
            },
            {
                "name": "Monterrubio de Armuña",
                "countryCode": "ES"
            },
            {
                "name": "Monterrubio de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Montorio",
                "countryCode": "ES"
            },
            {
                "name": "Monzón de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Moradillo de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Moral de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Moral de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja de Matacabras",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja de las Panaderas",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja del Vino",
                "countryCode": "ES"
            },
            {
                "name": "Morales de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Morales de Toro",
                "countryCode": "ES"
            },
            {
                "name": "Morales de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Morales del Vino",
                "countryCode": "ES"
            },
            {
                "name": "Moralina",
                "countryCode": "ES"
            },
            {
                "name": "Morasverdes",
                "countryCode": "ES"
            },
            {
                "name": "Moratinos",
                "countryCode": "ES"
            },
            {
                "name": "Moreruela de Tábara",
                "countryCode": "ES"
            },
            {
                "name": "Moreruela de los Infanzones",
                "countryCode": "ES"
            },
            {
                "name": "Morille",
                "countryCode": "ES"
            },
            {
                "name": "Moriscos",
                "countryCode": "ES"
            },
            {
                "name": "Moronta",
                "countryCode": "ES"
            },
            {
                "name": "Morón de Almazán",
                "countryCode": "ES"
            },
            {
                "name": "Mota del Marqués",
                "countryCode": "ES"
            },
            {
                "name": "Mozoncillo",
                "countryCode": "ES"
            },
            {
                "name": "Mozárbez",
                "countryCode": "ES"
            },
            {
                "name": "Mucientes",
                "countryCode": "ES"
            },
            {
                "name": "Mudá",
                "countryCode": "ES"
            },
            {
                "name": "Muelas de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Muga de Sayago",
                "countryCode": "ES"
            },
            {
                "name": "Murias de Paredes",
                "countryCode": "ES"
            },
            {
                "name": "Muriel Viejo",
                "countryCode": "ES"
            },
            {
                "name": "Muriel de la Fuente",
                "countryCode": "ES"
            },
            {
                "name": "Muñana",
                "countryCode": "ES"
            },
            {
                "name": "Muñico",
                "countryCode": "ES"
            },
            {
                "name": "Muñogalindo",
                "countryCode": "ES"
            },
            {
                "name": "Muñogrande",
                "countryCode": "ES"
            },
            {
                "name": "Muñomer del Peco",
                "countryCode": "ES"
            },
            {
                "name": "Muñopedro",
                "countryCode": "ES"
            },
            {
                "name": "Muñopepe",
                "countryCode": "ES"
            },
            {
                "name": "Muñosancho",
                "countryCode": "ES"
            },
            {
                "name": "Muñotello",
                "countryCode": "ES"
            },
            {
                "name": "Muñoveros",
                "countryCode": "ES"
            },
            {
                "name": "Nafría de Ucero",
                "countryCode": "ES"
            },
            {
                "name": "Narrillos del Rebollar",
                "countryCode": "ES"
            },
            {
                "name": "Narrillos del Álamo",
                "countryCode": "ES"
            },
            {
                "name": "Narros",
                "countryCode": "ES"
            },
            {
                "name": "Narros de Matalayegua",
                "countryCode": "ES"
            },
            {
                "name": "Narros de Saldueña",
                "countryCode": "ES"
            },
            {
                "name": "Narros del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Narros del Puerto",
                "countryCode": "ES"
            },
            {
                "name": "Nava de Arévalo",
                "countryCode": "ES"
            },
            {
                "name": "Nava de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Nava de Francia",
                "countryCode": "ES"
            },
            {
                "name": "Nava de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Nava de Sotrobal",
                "countryCode": "ES"
            },
            {
                "name": "Nava de la Asunción",
                "countryCode": "ES"
            },
            {
                "name": "Nava del Barco",
                "countryCode": "ES"
            },
            {
                "name": "Nava del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Navacarros",
                "countryCode": "ES"
            },
            {
                "name": "Navacepedilla de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "Navadijos",
                "countryCode": "ES"
            },
            {
                "name": "Navaescurial",
                "countryCode": "ES"
            },
            {
                "name": "Navafría",
                "countryCode": "ES"
            },
            {
                "name": "Navahondilla",
                "countryCode": "ES"
            },
            {
                "name": "Navalacruz",
                "countryCode": "ES"
            },
            {
                "name": "Navaleno",
                "countryCode": "ES"
            },
            {
                "name": "Navales",
                "countryCode": "ES"
            },
            {
                "name": "Navalilla",
                "countryCode": "ES"
            },
            {
                "name": "Navalmanzano",
                "countryCode": "ES"
            },
            {
                "name": "Navalmoral",
                "countryCode": "ES"
            },
            {
                "name": "Navalmoral de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Navalosa",
                "countryCode": "ES"
            },
            {
                "name": "Navalperal de Pinares",
                "countryCode": "ES"
            },
            {
                "name": "Navalperal de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Navaluenga",
                "countryCode": "ES"
            },
            {
                "name": "Navamorales",
                "countryCode": "ES"
            },
            {
                "name": "Navaquesera",
                "countryCode": "ES"
            },
            {
                "name": "Navares de Ayuso",
                "countryCode": "ES"
            },
            {
                "name": "Navares de Enmedio",
                "countryCode": "ES"
            },
            {
                "name": "Navares de las Cuevas",
                "countryCode": "ES"
            },
            {
                "name": "Navarredonda de Gredos",
                "countryCode": "ES"
            },
            {
                "name": "Navarredonda de la Rinconada",
                "countryCode": "ES"
            },
            {
                "name": "Navarredondilla",
                "countryCode": "ES"
            },
            {
                "name": "Navarrevisca",
                "countryCode": "ES"
            },
            {
                "name": "Navas de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Navas de Oro",
                "countryCode": "ES"
            },
            {
                "name": "Navasfrías",
                "countryCode": "ES"
            },
            {
                "name": "Navatalgordo",
                "countryCode": "ES"
            },
            {
                "name": "Navatejares",
                "countryCode": "ES"
            },
            {
                "name": "Navianos de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Nebreda",
                "countryCode": "ES"
            },
            {
                "name": "Negrilla de Palencia",
                "countryCode": "ES"
            },
            {
                "name": "Neila",
                "countryCode": "ES"
            },
            {
                "name": "Neila de San Miguel",
                "countryCode": "ES"
            },
            {
                "name": "Nepas",
                "countryCode": "ES"
            },
            {
                "name": "Nieva",
                "countryCode": "ES"
            },
            {
                "name": "Niharra",
                "countryCode": "ES"
            },
            {
                "name": "Nogal de las Huertas",
                "countryCode": "ES"
            },
            {
                "name": "Nolay",
                "countryCode": "ES"
            },
            {
                "name": "Noviercas",
                "countryCode": "ES"
            },
            {
                "name": "Nueva Villa de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Oencia",
                "countryCode": "ES"
            },
            {
                "name": "Ojos-Albos",
                "countryCode": "ES"
            },
            {
                "name": "Olea de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "Olivares de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Olmedillo de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Olmedo",
                "countryCode": "ES"
            },
            {
                "name": "Olmedo de Camaces",
                "countryCode": "ES"
            },
            {
                "name": "Olmillos de Castro",
                "countryCode": "ES"
            },
            {
                "name": "Olmillos de Muñó",
                "countryCode": "ES"
            },
            {
                "name": "Olmos de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Olmos de Ojeda",
                "countryCode": "ES"
            },
            {
                "name": "Olmos de Peñafiel",
                "countryCode": "ES"
            },
            {
                "name": "Olombrada",
                "countryCode": "ES"
            },
            {
                "name": "Olvega",
                "countryCode": "ES"
            },
            {
                "name": "Oncala",
                "countryCode": "ES"
            },
            {
                "name": "Onzonilla",
                "countryCode": "ES"
            },
            {
                "name": "Oquillas",
                "countryCode": "ES"
            },
            {
                "name": "Orbita",
                "countryCode": "ES"
            },
            {
                "name": "Ortigosa de Pestaño",
                "countryCode": "ES"
            },
            {
                "name": "Ortigosa del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Oseja de Sajambre",
                "countryCode": "ES"
            },
            {
                "name": "Osornillo",
                "countryCode": "ES"
            },
            {
                "name": "Otero de Bodas",
                "countryCode": "ES"
            },
            {
                "name": "Otero de Herreros",
                "countryCode": "ES"
            },
            {
                "name": "Oña",
                "countryCode": "ES"
            },
            {
                "name": "Padiernos",
                "countryCode": "ES"
            },
            {
                "name": "Padilla de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Padilla de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Padrones de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Pajarejos",
                "countryCode": "ES"
            },
            {
                "name": "Pajares de Adaja",
                "countryCode": "ES"
            },
            {
                "name": "Pajares de la Laguna",
                "countryCode": "ES"
            },
            {
                "name": "Pajares de la Lampreana",
                "countryCode": "ES"
            },
            {
                "name": "Pajares de los Oteros",
                "countryCode": "ES"
            },
            {
                "name": "Palacios de Goda",
                "countryCode": "ES"
            },
            {
                "name": "Palacios de Sanabria",
                "countryCode": "ES"
            },
            {
                "name": "Palacios de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Palacios de la Valduerna",
                "countryCode": "ES"
            },
            {
                "name": "Palacios del Arzobispo",
                "countryCode": "ES"
            },
            {
                "name": "Palacios del Pan",
                "countryCode": "ES"
            },
            {
                "name": "Palacios del Sil",
                "countryCode": "ES"
            },
            {
                "name": "Palaciosrubios",
                "countryCode": "ES"
            },
            {
                "name": "Palazuelo de Vedija",
                "countryCode": "ES"
            },
            {
                "name": "Palazuelos de Eresma",
                "countryCode": "ES"
            },
            {
                "name": "Palazuelos de Muñó",
                "countryCode": "ES"
            },
            {
                "name": "Palazuelos de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Palencia",
                "countryCode": "ES"
            },
            {
                "name": "Palencia de Negrilla",
                "countryCode": "ES"
            },
            {
                "name": "Palenzuela",
                "countryCode": "ES"
            },
            {
                "name": "Pampliega",
                "countryCode": "ES"
            },
            {
                "name": "Pancorbo",
                "countryCode": "ES"
            },
            {
                "name": "Papatrigo",
                "countryCode": "ES"
            },
            {
                "name": "Parada de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Parada de Rubiales",
                "countryCode": "ES"
            },
            {
                "name": "Paradinas de San Juan",
                "countryCode": "ES"
            },
            {
                "name": "Pardilla",
                "countryCode": "ES"
            },
            {
                "name": "Paredes de Nava",
                "countryCode": "ES"
            },
            {
                "name": "Pastores",
                "countryCode": "ES"
            },
            {
                "name": "Payo de Ojeda",
                "countryCode": "ES"
            },
            {
                "name": "Pedrajas de San Esteban",
                "countryCode": "ES"
            },
            {
                "name": "Pedraza",
                "countryCode": "ES"
            },
            {
                "name": "Pedraza de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Pedraza de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Pedro Bernardo",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosa de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosa de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosa del Príncipe",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosa del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosa del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosillo de Alba",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosillo de los Aires",
                "countryCode": "ES"
            },
            {
                "name": "Pedrosillo el Ralo",
                "countryCode": "ES"
            },
            {
                "name": "Peguerinos",
                "countryCode": "ES"
            },
            {
                "name": "Pelabravo",
                "countryCode": "ES"
            },
            {
                "name": "Pelarrodríguez",
                "countryCode": "ES"
            },
            {
                "name": "Pelayos",
                "countryCode": "ES"
            },
            {
                "name": "Pelayos del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Peleagonzalo",
                "countryCode": "ES"
            },
            {
                "name": "Peleas de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Peque",
                "countryCode": "ES"
            },
            {
                "name": "Peral de Arlanza",
                "countryCode": "ES"
            },
            {
                "name": "Peralejos de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Peralejos de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Perales",
                "countryCode": "ES"
            },
            {
                "name": "Peranzanes",
                "countryCode": "ES"
            },
            {
                "name": "Pereruela",
                "countryCode": "ES"
            },
            {
                "name": "Perilla de Castro",
                "countryCode": "ES"
            },
            {
                "name": "Peromingo",
                "countryCode": "ES"
            },
            {
                "name": "Perosillo",
                "countryCode": "ES"
            },
            {
                "name": "Peñacaballera",
                "countryCode": "ES"
            },
            {
                "name": "Peñafiel",
                "countryCode": "ES"
            },
            {
                "name": "Peñaflor de Hornija",
                "countryCode": "ES"
            },
            {
                "name": "Peñalba de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Peñaparda",
                "countryCode": "ES"
            },
            {
                "name": "Peñaranda de Bracamonte",
                "countryCode": "ES"
            },
            {
                "name": "Peñaranda de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Peñarandilla",
                "countryCode": "ES"
            },
            {
                "name": "Peñausende",
                "countryCode": "ES"
            },
            {
                "name": "Piedrahita de Castro",
                "countryCode": "ES"
            },
            {
                "name": "Piedralaves",
                "countryCode": "ES"
            },
            {
                "name": "Pinarejos",
                "countryCode": "ES"
            },
            {
                "name": "Pinarnegrillo",
                "countryCode": "ES"
            },
            {
                "name": "Pineda de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Pinedas",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla de Toro",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla de los Barruecos",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla de los Moros",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Pino del Río",
                "countryCode": "ES"
            },
            {
                "name": "Pitiegua",
                "countryCode": "ES"
            },
            {
                "name": "Pizarral",
                "countryCode": "ES"
            },
            {
                "name": "Piérnigas",
                "countryCode": "ES"
            },
            {
                "name": "Piña de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Piña de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Piñel de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Piñel de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Población de Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Población de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Población de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Pobladura de Pelayo García",
                "countryCode": "ES"
            },
            {
                "name": "Pobladura de Valderaduey",
                "countryCode": "ES"
            },
            {
                "name": "Pobladura del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Polentinos",
                "countryCode": "ES"
            },
            {
                "name": "Pollos",
                "countryCode": "ES"
            },
            {
                "name": "Pomar de Valdivia",
                "countryCode": "ES"
            },
            {
                "name": "Ponferrada",
                "countryCode": "ES"
            },
            {
                "name": "Portillo",
                "countryCode": "ES"
            },
            {
                "name": "Portillo de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Porto",
                "countryCode": "ES"
            },
            {
                "name": "Posada de Valdeón",
                "countryCode": "ES"
            },
            {
                "name": "Poveda",
                "countryCode": "ES"
            },
            {
                "name": "Poveda de las Cintas",
                "countryCode": "ES"
            },
            {
                "name": "Poyales del Hoyo",
                "countryCode": "ES"
            },
            {
                "name": "Poza de la Sal",
                "countryCode": "ES"
            },
            {
                "name": "Poza de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Pozal de Gallinas",
                "countryCode": "ES"
            },
            {
                "name": "Pozalmuro",
                "countryCode": "ES"
            },
            {
                "name": "Pozanco",
                "countryCode": "ES"
            },
            {
                "name": "Pozo de Urama",
                "countryCode": "ES"
            },
            {
                "name": "Pozoantiguo",
                "countryCode": "ES"
            },
            {
                "name": "Pozos de Hinojo",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de Tábara",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de la Orden",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Pradales",
                "countryCode": "ES"
            },
            {
                "name": "Prado",
                "countryCode": "ES"
            },
            {
                "name": "Prado de la Guzpeña",
                "countryCode": "ES"
            },
            {
                "name": "Pradoluengo",
                "countryCode": "ES"
            },
            {
                "name": "Pradosegar",
                "countryCode": "ES"
            },
            {
                "name": "Presencio",
                "countryCode": "ES"
            },
            {
                "name": "Priaranza del Bierzo",
                "countryCode": "ES"
            },
            {
                "name": "Prioro",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Burgos",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de León",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Palencia",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Salamanca",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Segovia",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Valladolid",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Zamora",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Prádanos de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Prádanos de Ojeda",
                "countryCode": "ES"
            },
            {
                "name": "Prádena",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Azaba",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Lillo",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Pedraza",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de San Medel",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Sanabria",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Pueblica de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Puente de Domingo Flórez",
                "countryCode": "ES"
            },
            {
                "name": "Puente del Congosto",
                "countryCode": "ES"
            },
            {
                "name": "Puentedura",
                "countryCode": "ES"
            },
            {
                "name": "Puertas",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Castilla",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Seguro",
                "countryCode": "ES"
            },
            {
                "name": "Puerto de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Puras",
                "countryCode": "ES"
            },
            {
                "name": "Páramo de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "Páramo del Sil",
                "countryCode": "ES"
            },
            {
                "name": "Pías",
                "countryCode": "ES"
            },
            {
                "name": "Quemada",
                "countryCode": "ES"
            },
            {
                "name": "Quintana Redonda",
                "countryCode": "ES"
            },
            {
                "name": "Quintana del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Quintana del Marco",
                "countryCode": "ES"
            },
            {
                "name": "Quintana del Pidio",
                "countryCode": "ES"
            },
            {
                "name": "Quintana del Puente",
                "countryCode": "ES"
            },
            {
                "name": "Quintana y Congosto",
                "countryCode": "ES"
            },
            {
                "name": "Quintanabureba",
                "countryCode": "ES"
            },
            {
                "name": "Quintanaortuño",
                "countryCode": "ES"
            },
            {
                "name": "Quintanapalla",
                "countryCode": "ES"
            },
            {
                "name": "Quintanar de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Quintanas de Gormaz",
                "countryCode": "ES"
            },
            {
                "name": "Quintanavides",
                "countryCode": "ES"
            },
            {
                "name": "Quintanaélez",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de Onsoña",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de Onésimo",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de Trigueros",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de Urz",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla de la Mata",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla del Coco",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla del Molar",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Quintanilla del Olmo",
                "countryCode": "ES"
            },
            {
                "name": "Quiruelas de Vidriales",
                "countryCode": "ES"
            },
            {
                "name": "Rabanales",
                "countryCode": "ES"
            },
            {
                "name": "Rabanera del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Rabé de las Calzadas",
                "countryCode": "ES"
            },
            {
                "name": "Ramiro",
                "countryCode": "ES"
            },
            {
                "name": "Rapariegos",
                "countryCode": "ES"
            },
            {
                "name": "Rasueros",
                "countryCode": "ES"
            },
            {
                "name": "Rebolledo de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Rebollo",
                "countryCode": "ES"
            },
            {
                "name": "Recuerda",
                "countryCode": "ES"
            },
            {
                "name": "Redecilla del Camino",
                "countryCode": "ES"
            },
            {
                "name": "Redecilla del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Regueras de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Regumiel de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Reinoso",
                "countryCode": "ES"
            },
            {
                "name": "Reinoso de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Rello",
                "countryCode": "ES"
            },
            {
                "name": "Remondo",
                "countryCode": "ES"
            },
            {
                "name": "Renedo de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Renieblas",
                "countryCode": "ES"
            },
            {
                "name": "Requena de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Respenda de la Peña",
                "countryCode": "ES"
            },
            {
                "name": "Retortillo",
                "countryCode": "ES"
            },
            {
                "name": "Retortillo de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Retuerta",
                "countryCode": "ES"
            },
            {
                "name": "Revellinos",
                "countryCode": "ES"
            },
            {
                "name": "Revenga de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Revilla de Collazos",
                "countryCode": "ES"
            },
            {
                "name": "Revilla del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Revillarruz",
                "countryCode": "ES"
            },
            {
                "name": "Reyero",
                "countryCode": "ES"
            },
            {
                "name": "Rezmondo",
                "countryCode": "ES"
            },
            {
                "name": "Reznos",
                "countryCode": "ES"
            },
            {
                "name": "Riaguas de San Bartolomé",
                "countryCode": "ES"
            },
            {
                "name": "Riaza",
                "countryCode": "ES"
            },
            {
                "name": "Riaño",
                "countryCode": "ES"
            },
            {
                "name": "Ribas de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Riberos de la Cueza",
                "countryCode": "ES"
            },
            {
                "name": "Ribota",
                "countryCode": "ES"
            },
            {
                "name": "Riego de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Riocavado de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Rivilla de Barajas",
                "countryCode": "ES"
            },
            {
                "name": "Roa",
                "countryCode": "ES"
            },
            {
                "name": "Roales",
                "countryCode": "ES"
            },
            {
                "name": "Robladillo",
                "countryCode": "ES"
            },
            {
                "name": "Robleda",
                "countryCode": "ES"
            },
            {
                "name": "Robleda-Cervantes",
                "countryCode": "ES"
            },
            {
                "name": "Robliza de Cojos",
                "countryCode": "ES"
            },
            {
                "name": "Roda de Eresma",
                "countryCode": "ES"
            },
            {
                "name": "Rojas",
                "countryCode": "ES"
            },
            {
                "name": "Rollamienta",
                "countryCode": "ES"
            },
            {
                "name": "Rollán",
                "countryCode": "ES"
            },
            {
                "name": "Roperuelos del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Roturas",
                "countryCode": "ES"
            },
            {
                "name": "Royuela de Río Franco",
                "countryCode": "ES"
            },
            {
                "name": "Rubena",
                "countryCode": "ES"
            },
            {
                "name": "Rublacedo de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Rubí de Bracamonte",
                "countryCode": "ES"
            },
            {
                "name": "Rucandio",
                "countryCode": "ES"
            },
            {
                "name": "Rueda",
                "countryCode": "ES"
            },
            {
                "name": "Ruesga",
                "countryCode": "ES"
            },
            {
                "name": "Rábano",
                "countryCode": "ES"
            },
            {
                "name": "Rábano de Aliste",
                "countryCode": "ES"
            },
            {
                "name": "Rábanos",
                "countryCode": "ES"
            },
            {
                "name": "Rágama",
                "countryCode": "ES"
            },
            {
                "name": "Ríofrío de Aliste",
                "countryCode": "ES"
            },
            {
                "name": "Sabero",
                "countryCode": "ES"
            },
            {
                "name": "Sacramenia",
                "countryCode": "ES"
            },
            {
                "name": "Saelices de Mayorga",
                "countryCode": "ES"
            },
            {
                "name": "Saelices el Chico",
                "countryCode": "ES"
            },
            {
                "name": "Sahagún",
                "countryCode": "ES"
            },
            {
                "name": "Salamanca",
                "countryCode": "ES"
            },
            {
                "name": "Salas de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Salas de los Infantes",
                "countryCode": "ES"
            },
            {
                "name": "Salce",
                "countryCode": "ES"
            },
            {
                "name": "Saldaña",
                "countryCode": "ES"
            },
            {
                "name": "Saldaña de Burgos",
                "countryCode": "ES"
            },
            {
                "name": "Saldeana",
                "countryCode": "ES"
            },
            {
                "name": "Salduero",
                "countryCode": "ES"
            },
            {
                "name": "Salinas de Pisuerga",
                "countryCode": "ES"
            },
            {
                "name": "Salinillas de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Salmoral",
                "countryCode": "ES"
            },
            {
                "name": "Salobral",
                "countryCode": "ES"
            },
            {
                "name": "Salvadiós",
                "countryCode": "ES"
            },
            {
                "name": "Salvador de Zapardiel",
                "countryCode": "ES"
            },
            {
                "name": "Salvatierra de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Samboal",
                "countryCode": "ES"
            },
            {
                "name": "Samir de los Caños",
                "countryCode": "ES"
            },
            {
                "name": "San Adrián de Juarros",
                "countryCode": "ES"
            },
            {
                "name": "San Adrián del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Agustín del Pozo",
                "countryCode": "ES"
            },
            {
                "name": "San Andrés del Rabanedo",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "San Bartolomé de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "San Cebrián de Campos",
                "countryCode": "ES"
            },
            {
                "name": "San Cebrián de Castro",
                "countryCode": "ES"
            },
            {
                "name": "San Cebrián de Mazote",
                "countryCode": "ES"
            },
            {
                "name": "San Cebrián de Mudá",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de Cuéllar",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de Entreviñas",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de Segovia",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de la Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de la Polantera",
                "countryCode": "ES"
            },
            {
                "name": "San Cristóbal de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "San Emiliano",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban de Gormaz",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban de Nogales",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban de los Patos",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban del Molar",
                "countryCode": "ES"
            },
            {
                "name": "San Esteban del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Felices de los Gallegos",
                "countryCode": "ES"
            },
            {
                "name": "San García de Ingelmos",
                "countryCode": "ES"
            },
            {
                "name": "San Ildefonso",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de la Encinilla",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de la Nava",
                "countryCode": "ES"
            },
            {
                "name": "San Juan del Molinillo",
                "countryCode": "ES"
            },
            {
                "name": "San Juan del Monte",
                "countryCode": "ES"
            },
            {
                "name": "San Justo",
                "countryCode": "ES"
            },
            {
                "name": "San Justo de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "San Leonardo de Yagüe",
                "countryCode": "ES"
            },
            {
                "name": "San Llorente",
                "countryCode": "ES"
            },
            {
                "name": "San Lorenzo de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "San Mamés de Burgos",
                "countryCode": "ES"
            },
            {
                "name": "San Mamés de Campos",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Rubiales",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Valderaduey",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Valvení",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de la Vega del Alberche",
                "countryCode": "ES"
            },
            {
                "name": "San Martín del Castañar",
                "countryCode": "ES"
            },
            {
                "name": "San Martín del Pimpollar",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Serrezuela",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Valero",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel del Pino",
                "countryCode": "ES"
            },
            {
                "name": "San Millán de Lara",
                "countryCode": "ES"
            },
            {
                "name": "San Millán de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "San Morales",
                "countryCode": "ES"
            },
            {
                "name": "San Muñoz",
                "countryCode": "ES"
            },
            {
                "name": "San Pablo de la Moraleja",
                "countryCode": "ES"
            },
            {
                "name": "San Pascual",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro Bercianos",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro Manrique",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Ceque",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Gaíllos",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Latarce",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro de Rozados",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Pelayo",
                "countryCode": "ES"
            },
            {
                "name": "San Pelayo de Guareña",
                "countryCode": "ES"
            },
            {
                "name": "San Román de Hornija",
                "countryCode": "ES"
            },
            {
                "name": "San Román de la Cuba",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente de Arévalo",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente de la Cabeza",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente del Palacio",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente del Valle",
                "countryCode": "ES"
            },
            {
                "name": "San Vitero",
                "countryCode": "ES"
            },
            {
                "name": "Sancedo",
                "countryCode": "ES"
            },
            {
                "name": "Sanchidrián",
                "countryCode": "ES"
            },
            {
                "name": "Sanchonuño",
                "countryCode": "ES"
            },
            {
                "name": "Sanchorreja",
                "countryCode": "ES"
            },
            {
                "name": "Sanchotello",
                "countryCode": "ES"
            },
            {
                "name": "Sanchón de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Sanchón de la Sagrada",
                "countryCode": "ES"
            },
            {
                "name": "Sando",
                "countryCode": "ES"
            },
            {
                "name": "Sangarcía",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cecilia",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cecilia del Alcor",
                "countryCode": "ES"
            },
            {
                "name": "Santa Clara de Avedillo",
                "countryCode": "ES"
            },
            {
                "name": "Santa Colomba de Curueño",
                "countryCode": "ES"
            },
            {
                "name": "Santa Colomba de Somoza",
                "countryCode": "ES"
            },
            {
                "name": "Santa Colomba de las Monjas",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cristina de Valmadrigal",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cristina de la Polvorosa",
                "countryCode": "ES"
            },
            {
                "name": "Santa Croya de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Boedo",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Pinares",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de Yanguas",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz de la Salceda",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz del Valle Urbión",
                "countryCode": "ES"
            },
            {
                "name": "Santa Elena de Jamuz",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eufemia del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eufemia del Barco",
                "countryCode": "ES"
            },
            {
                "name": "Santa Gadea del Cid",
                "countryCode": "ES"
            },
            {
                "name": "Santa Inés",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marina del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marta de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Santa Marta del Cerro",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de Huerta",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de Ordás",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de Sando",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de la Isla",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de las Hoyas",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Berrocal",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Invierno",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Monte de Cea",
                "countryCode": "ES"
            },
            {
                "name": "Santa María del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Santa María la Real de Nieva",
                "countryCode": "ES"
            },
            {
                "name": "Santa Olalla de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Santas Martas",
                "countryCode": "ES"
            },
            {
                "name": "Santervás de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Santervás de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Santiago Millas",
                "countryCode": "ES"
            },
            {
                "name": "Santiago de la Puebla",
                "countryCode": "ES"
            },
            {
                "name": "Santiago del Collado",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de Béjar",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de Ecla",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de Valcorba",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de Vidriales",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de la Peña",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Santibáñez del Val",
                "countryCode": "ES"
            },
            {
                "name": "Santiuste de San Juan Bautista",
                "countryCode": "ES"
            },
            {
                "name": "Santiz",
                "countryCode": "ES"
            },
            {
                "name": "Santo Domingo de Pirón",
                "countryCode": "ES"
            },
            {
                "name": "Santo Domingo de Silos",
                "countryCode": "ES"
            },
            {
                "name": "Santo Domingo de las Posadas",
                "countryCode": "ES"
            },
            {
                "name": "Santo Tomé de Zabarcos",
                "countryCode": "ES"
            },
            {
                "name": "Santovenia",
                "countryCode": "ES"
            },
            {
                "name": "Santovenia de Pisuerga",
                "countryCode": "ES"
            },
            {
                "name": "Santoyo",
                "countryCode": "ES"
            },
            {
                "name": "Sanzoles",
                "countryCode": "ES"
            },
            {
                "name": "Sardón de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Sardón de los Frailes",
                "countryCode": "ES"
            },
            {
                "name": "Sargentes de la Lora",
                "countryCode": "ES"
            },
            {
                "name": "Sariegos",
                "countryCode": "ES"
            },
            {
                "name": "Sarracín",
                "countryCode": "ES"
            },
            {
                "name": "Sasamón",
                "countryCode": "ES"
            },
            {
                "name": "Saucelle",
                "countryCode": "ES"
            },
            {
                "name": "Sauquillo de Cabezas",
                "countryCode": "ES"
            },
            {
                "name": "Sebúlcor",
                "countryCode": "ES"
            },
            {
                "name": "Segovia",
                "countryCode": "ES"
            },
            {
                "name": "Sena de Luna",
                "countryCode": "ES"
            },
            {
                "name": "Sepúlveda",
                "countryCode": "ES"
            },
            {
                "name": "Sequera de Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Sequeros",
                "countryCode": "ES"
            },
            {
                "name": "Serrada",
                "countryCode": "ES"
            },
            {
                "name": "Serradilla del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Serradilla del Llano",
                "countryCode": "ES"
            },
            {
                "name": "Serranillos",
                "countryCode": "ES"
            },
            {
                "name": "Siete Iglesias de Trabancos",
                "countryCode": "ES"
            },
            {
                "name": "Sigeres",
                "countryCode": "ES"
            },
            {
                "name": "Simancas",
                "countryCode": "ES"
            },
            {
                "name": "Sinlabajos",
                "countryCode": "ES"
            },
            {
                "name": "Sobradillo",
                "countryCode": "ES"
            },
            {
                "name": "Sobrado",
                "countryCode": "ES"
            },
            {
                "name": "Solarana",
                "countryCode": "ES"
            },
            {
                "name": "Soliedra",
                "countryCode": "ES"
            },
            {
                "name": "Solosancho",
                "countryCode": "ES"
            },
            {
                "name": "Sordillos",
                "countryCode": "ES"
            },
            {
                "name": "Soria",
                "countryCode": "ES"
            },
            {
                "name": "Sorihuela",
                "countryCode": "ES"
            },
            {
                "name": "Sotalbo",
                "countryCode": "ES"
            },
            {
                "name": "Sotillo",
                "countryCode": "ES"
            },
            {
                "name": "Sotillo de la Adrada",
                "countryCode": "ES"
            },
            {
                "name": "Sotillo de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Sotillo del Rincón",
                "countryCode": "ES"
            },
            {
                "name": "Soto de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Soto de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Soto y Amío",
                "countryCode": "ES"
            },
            {
                "name": "Sotobañado y Priorato",
                "countryCode": "ES"
            },
            {
                "name": "Sotosalbos",
                "countryCode": "ES"
            },
            {
                "name": "Sotoserrano",
                "countryCode": "ES"
            },
            {
                "name": "Sotragero",
                "countryCode": "ES"
            },
            {
                "name": "Sotresgudo",
                "countryCode": "ES"
            },
            {
                "name": "Suellacabras",
                "countryCode": "ES"
            },
            {
                "name": "Susinos del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Tabanera de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Tabanera de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Tabanera la Luenga",
                "countryCode": "ES"
            },
            {
                "name": "Tabera de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Tajahuerce",
                "countryCode": "ES"
            },
            {
                "name": "Tajueco",
                "countryCode": "ES"
            },
            {
                "name": "Tamames",
                "countryCode": "ES"
            },
            {
                "name": "Tamariz de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Tamarón",
                "countryCode": "ES"
            },
            {
                "name": "Tapioles",
                "countryCode": "ES"
            },
            {
                "name": "Tarazona de Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Tardajos",
                "countryCode": "ES"
            },
            {
                "name": "Tardelcuende",
                "countryCode": "ES"
            },
            {
                "name": "Tardáguila",
                "countryCode": "ES"
            },
            {
                "name": "Taroda",
                "countryCode": "ES"
            },
            {
                "name": "Tejada",
                "countryCode": "ES"
            },
            {
                "name": "Tejado",
                "countryCode": "ES"
            },
            {
                "name": "Tejeda y Segoyuela",
                "countryCode": "ES"
            },
            {
                "name": "Tenebrón",
                "countryCode": "ES"
            },
            {
                "name": "Terradillos",
                "countryCode": "ES"
            },
            {
                "name": "Terradillos de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Tiedra",
                "countryCode": "ES"
            },
            {
                "name": "Tiñosillos",
                "countryCode": "ES"
            },
            {
                "name": "Tobar",
                "countryCode": "ES"
            },
            {
                "name": "Tolbaños",
                "countryCode": "ES"
            },
            {
                "name": "Tolocirio",
                "countryCode": "ES"
            },
            {
                "name": "Topas",
                "countryCode": "ES"
            },
            {
                "name": "Toral de los Guzmanes",
                "countryCode": "ES"
            },
            {
                "name": "Tordehumos",
                "countryCode": "ES"
            },
            {
                "name": "Tordesillas",
                "countryCode": "ES"
            },
            {
                "name": "Tordillos",
                "countryCode": "ES"
            },
            {
                "name": "Tordómar",
                "countryCode": "ES"
            },
            {
                "name": "Toreno",
                "countryCode": "ES"
            },
            {
                "name": "Torlengua",
                "countryCode": "ES"
            },
            {
                "name": "Tormellas",
                "countryCode": "ES"
            },
            {
                "name": "Tornadizos de Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Toro",
                "countryCode": "ES"
            },
            {
                "name": "Torquemada",
                "countryCode": "ES"
            },
            {
                "name": "Torre Val de San Pedro",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Torre de Peñafiel",
                "countryCode": "ES"
            },
            {
                "name": "Torre del Bierzo",
                "countryCode": "ES"
            },
            {
                "name": "Torreadrada",
                "countryCode": "ES"
            },
            {
                "name": "Torreblacos",
                "countryCode": "ES"
            },
            {
                "name": "Torrecaballeros",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de la Abadesa",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de la Orden",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla de la Torre",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Torregalindo",
                "countryCode": "ES"
            },
            {
                "name": "Torregamones",
                "countryCode": "ES"
            },
            {
                "name": "Torreiglesias",
                "countryCode": "ES"
            },
            {
                "name": "Torrelara",
                "countryCode": "ES"
            },
            {
                "name": "Torrelobatón",
                "countryCode": "ES"
            },
            {
                "name": "Torremormojón",
                "countryCode": "ES"
            },
            {
                "name": "Torres del Carrizal",
                "countryCode": "ES"
            },
            {
                "name": "Torresandino",
                "countryCode": "ES"
            },
            {
                "name": "Torrescárcela",
                "countryCode": "ES"
            },
            {
                "name": "Torresmenudas",
                "countryCode": "ES"
            },
            {
                "name": "Torrubia de Soria",
                "countryCode": "ES"
            },
            {
                "name": "Tosantos",
                "countryCode": "ES"
            },
            {
                "name": "Trabadelo",
                "countryCode": "ES"
            },
            {
                "name": "Trabanca",
                "countryCode": "ES"
            },
            {
                "name": "Trabazos",
                "countryCode": "ES"
            },
            {
                "name": "Traspinedo",
                "countryCode": "ES"
            },
            {
                "name": "Trefacio",
                "countryCode": "ES"
            },
            {
                "name": "Tremedal de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Trescasas",
                "countryCode": "ES"
            },
            {
                "name": "Trespaderne",
                "countryCode": "ES"
            },
            {
                "name": "Trigueros del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Triollo",
                "countryCode": "ES"
            },
            {
                "name": "Truchas",
                "countryCode": "ES"
            },
            {
                "name": "Tubilla del Agua",
                "countryCode": "ES"
            },
            {
                "name": "Tubilla del Lago",
                "countryCode": "ES"
            },
            {
                "name": "Tudela de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Turcia",
                "countryCode": "ES"
            },
            {
                "name": "Turégano",
                "countryCode": "ES"
            },
            {
                "name": "Tábara",
                "countryCode": "ES"
            },
            {
                "name": "Tórtoles",
                "countryCode": "ES"
            },
            {
                "name": "Ucero",
                "countryCode": "ES"
            },
            {
                "name": "Umbrías",
                "countryCode": "ES"
            },
            {
                "name": "Urdiales del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Urones de Castroponce",
                "countryCode": "ES"
            },
            {
                "name": "Urueña",
                "countryCode": "ES"
            },
            {
                "name": "Urueñas",
                "countryCode": "ES"
            },
            {
                "name": "Uña de Quintana",
                "countryCode": "ES"
            },
            {
                "name": "Vadillo",
                "countryCode": "ES"
            },
            {
                "name": "Vadillo de la Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Vadillo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Vadocondes",
                "countryCode": "ES"
            },
            {
                "name": "Val de San Lorenzo",
                "countryCode": "ES"
            },
            {
                "name": "Valbuena de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Valbuena de Pisuerga",
                "countryCode": "ES"
            },
            {
                "name": "Valcabado",
                "countryCode": "ES"
            },
            {
                "name": "Valdeande",
                "countryCode": "ES"
            },
            {
                "name": "Valdearcos de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Valdeavellano de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Valdecarros",
                "countryCode": "ES"
            },
            {
                "name": "Valdecasa",
                "countryCode": "ES"
            },
            {
                "name": "Valdefinjas",
                "countryCode": "ES"
            },
            {
                "name": "Valdefresno",
                "countryCode": "ES"
            },
            {
                "name": "Valdefuentes de Sangusín",
                "countryCode": "ES"
            },
            {
                "name": "Valdefuentes del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Valdehijaderos",
                "countryCode": "ES"
            },
            {
                "name": "Valdelacasa",
                "countryCode": "ES"
            },
            {
                "name": "Valdelageve",
                "countryCode": "ES"
            },
            {
                "name": "Valdelagua del Cerro",
                "countryCode": "ES"
            },
            {
                "name": "Valdelosa",
                "countryCode": "ES"
            },
            {
                "name": "Valdemaluque",
                "countryCode": "ES"
            },
            {
                "name": "Valdemierque",
                "countryCode": "ES"
            },
            {
                "name": "Valdemora",
                "countryCode": "ES"
            },
            {
                "name": "Valdenebro",
                "countryCode": "ES"
            },
            {
                "name": "Valdenebro de los Valles",
                "countryCode": "ES"
            },
            {
                "name": "Valdeolmillos",
                "countryCode": "ES"
            },
            {
                "name": "Valdepiélago",
                "countryCode": "ES"
            },
            {
                "name": "Valdepolo",
                "countryCode": "ES"
            },
            {
                "name": "Valdeprado",
                "countryCode": "ES"
            },
            {
                "name": "Valdeprados",
                "countryCode": "ES"
            },
            {
                "name": "Valderas",
                "countryCode": "ES"
            },
            {
                "name": "Valderrey",
                "countryCode": "ES"
            },
            {
                "name": "Valderrodilla",
                "countryCode": "ES"
            },
            {
                "name": "Valderrodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Valderrueda",
                "countryCode": "ES"
            },
            {
                "name": "Valderrábano",
                "countryCode": "ES"
            },
            {
                "name": "Valdesamario",
                "countryCode": "ES"
            },
            {
                "name": "Valdescorriel",
                "countryCode": "ES"
            },
            {
                "name": "Valdestillas",
                "countryCode": "ES"
            },
            {
                "name": "Valdevacas de Montejo",
                "countryCode": "ES"
            },
            {
                "name": "Valdevimbre",
                "countryCode": "ES"
            },
            {
                "name": "Valdezate",
                "countryCode": "ES"
            },
            {
                "name": "Valdorros",
                "countryCode": "ES"
            },
            {
                "name": "Valdunciel",
                "countryCode": "ES"
            },
            {
                "name": "Valdunquillo",
                "countryCode": "ES"
            },
            {
                "name": "Valencia de Don Juan",
                "countryCode": "ES"
            },
            {
                "name": "Valero",
                "countryCode": "ES"
            },
            {
                "name": "Valladolid",
                "countryCode": "ES"
            },
            {
                "name": "Vallarta de Bureba",
                "countryCode": "ES"
            },
            {
                "name": "Valle de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Valle de Tabladillo",
                "countryCode": "ES"
            },
            {
                "name": "Vallecillo",
                "countryCode": "ES"
            },
            {
                "name": "Vallejera de Riofrío",
                "countryCode": "ES"
            },
            {
                "name": "Vallelado",
                "countryCode": "ES"
            },
            {
                "name": "Valleruela de Pedraza",
                "countryCode": "ES"
            },
            {
                "name": "Valleruela de Sepúlveda",
                "countryCode": "ES"
            },
            {
                "name": "Valles de Palenzuela",
                "countryCode": "ES"
            },
            {
                "name": "Vallesa de la Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Valluércanes",
                "countryCode": "ES"
            },
            {
                "name": "Valmala",
                "countryCode": "ES"
            },
            {
                "name": "Valoria la Buena",
                "countryCode": "ES"
            },
            {
                "name": "Valsalabroso",
                "countryCode": "ES"
            },
            {
                "name": "Valseca",
                "countryCode": "ES"
            },
            {
                "name": "Valtajeros",
                "countryCode": "ES"
            },
            {
                "name": "Valtiendas",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Valdelacasa",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de la Virgen",
                "countryCode": "ES"
            },
            {
                "name": "Valverde del Majano",
                "countryCode": "ES"
            },
            {
                "name": "Valverde-Enrique",
                "countryCode": "ES"
            },
            {
                "name": "Valverdón",
                "countryCode": "ES"
            },
            {
                "name": "Vecinos",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Espinareda",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Infanzones",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Ruiponce",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Santa María",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Tera",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Tirados",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Valcarce",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Valdetronco",
                "countryCode": "ES"
            },
            {
                "name": "Vega de Villalobos",
                "countryCode": "ES"
            },
            {
                "name": "Vegacervera",
                "countryCode": "ES"
            },
            {
                "name": "Vegalatrave",
                "countryCode": "ES"
            },
            {
                "name": "Veganzones",
                "countryCode": "ES"
            },
            {
                "name": "Vegaquemada",
                "countryCode": "ES"
            },
            {
                "name": "Vegas de Matute",
                "countryCode": "ES"
            },
            {
                "name": "Velamazán",
                "countryCode": "ES"
            },
            {
                "name": "Velascálvaro",
                "countryCode": "ES"
            },
            {
                "name": "Velayos",
                "countryCode": "ES"
            },
            {
                "name": "Velilla",
                "countryCode": "ES"
            },
            {
                "name": "Velilla de los Ajos",
                "countryCode": "ES"
            },
            {
                "name": "Velilla del Río Carrión",
                "countryCode": "ES"
            },
            {
                "name": "Velliza",
                "countryCode": "ES"
            },
            {
                "name": "Venialbo",
                "countryCode": "ES"
            },
            {
                "name": "Venta de Baños",
                "countryCode": "ES"
            },
            {
                "name": "Ventosa de la Cuesta",
                "countryCode": "ES"
            },
            {
                "name": "Ventosa del Río Almar",
                "countryCode": "ES"
            },
            {
                "name": "Vertavillo",
                "countryCode": "ES"
            },
            {
                "name": "Vezdemarbán",
                "countryCode": "ES"
            },
            {
                "name": "Viana de Cega",
                "countryCode": "ES"
            },
            {
                "name": "Viana de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Vidayanes",
                "countryCode": "ES"
            },
            {
                "name": "Videmala",
                "countryCode": "ES"
            },
            {
                "name": "Vileña",
                "countryCode": "ES"
            },
            {
                "name": "Villabaruz de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villablino",
                "countryCode": "ES"
            },
            {
                "name": "Villabraz",
                "countryCode": "ES"
            },
            {
                "name": "Villabrágima",
                "countryCode": "ES"
            },
            {
                "name": "Villabrázaro",
                "countryCode": "ES"
            },
            {
                "name": "Villabuena del Puente",
                "countryCode": "ES"
            },
            {
                "name": "Villabáñez",
                "countryCode": "ES"
            },
            {
                "name": "Villacarralón",
                "countryCode": "ES"
            },
            {
                "name": "Villacastín",
                "countryCode": "ES"
            },
            {
                "name": "Villacid de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villacidaler",
                "countryCode": "ES"
            },
            {
                "name": "Villaciervos",
                "countryCode": "ES"
            },
            {
                "name": "Villaco",
                "countryCode": "ES"
            },
            {
                "name": "Villaconancio",
                "countryCode": "ES"
            },
            {
                "name": "Villada",
                "countryCode": "ES"
            },
            {
                "name": "Villadangos del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Villadecanes",
                "countryCode": "ES"
            },
            {
                "name": "Villademor de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Villadepera",
                "countryCode": "ES"
            },
            {
                "name": "Villadiego",
                "countryCode": "ES"
            },
            {
                "name": "Villaeles de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Villaescusa",
                "countryCode": "ES"
            },
            {
                "name": "Villaescusa de Roa",
                "countryCode": "ES"
            },
            {
                "name": "Villaescusa la Sombría",
                "countryCode": "ES"
            },
            {
                "name": "Villaespasa",
                "countryCode": "ES"
            },
            {
                "name": "Villaferrueña",
                "countryCode": "ES"
            },
            {
                "name": "Villaflor",
                "countryCode": "ES"
            },
            {
                "name": "Villaflores",
                "countryCode": "ES"
            },
            {
                "name": "Villafrades de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca del Bierzo",
                "countryCode": "ES"
            },
            {
                "name": "Villafrechós",
                "countryCode": "ES"
            },
            {
                "name": "Villafruela",
                "countryCode": "ES"
            },
            {
                "name": "Villafuerte",
                "countryCode": "ES"
            },
            {
                "name": "Villafáfila",
                "countryCode": "ES"
            },
            {
                "name": "Villagalijo",
                "countryCode": "ES"
            },
            {
                "name": "Villagarcía de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villagatón",
                "countryCode": "ES"
            },
            {
                "name": "Villageriz",
                "countryCode": "ES"
            },
            {
                "name": "Villagonzalo de Tormes",
                "countryCode": "ES"
            },
            {
                "name": "Villagonzalo-Pedernales",
                "countryCode": "ES"
            },
            {
                "name": "Villagómez la Nueva",
                "countryCode": "ES"
            },
            {
                "name": "Villaherreros",
                "countryCode": "ES"
            },
            {
                "name": "Villahoz",
                "countryCode": "ES"
            },
            {
                "name": "Villahán",
                "countryCode": "ES"
            },
            {
                "name": "Villalaco",
                "countryCode": "ES"
            },
            {
                "name": "Villalar de los Comuneros",
                "countryCode": "ES"
            },
            {
                "name": "Villalazán",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de Guardo",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de la Lampreana",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de la Loma",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de los Alcores",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de los Llanos",
                "countryCode": "ES"
            },
            {
                "name": "Villalbarba",
                "countryCode": "ES"
            },
            {
                "name": "Villalbilla de Burgos",
                "countryCode": "ES"
            },
            {
                "name": "Villalbilla de Gumiel",
                "countryCode": "ES"
            },
            {
                "name": "Villalcampo",
                "countryCode": "ES"
            },
            {
                "name": "Villalcázar de Sirga",
                "countryCode": "ES"
            },
            {
                "name": "Villalcón",
                "countryCode": "ES"
            },
            {
                "name": "Villaldemiro",
                "countryCode": "ES"
            },
            {
                "name": "Villalmanzo",
                "countryCode": "ES"
            },
            {
                "name": "Villalobos",
                "countryCode": "ES"
            },
            {
                "name": "Villalobón",
                "countryCode": "ES"
            },
            {
                "name": "Villalonso",
                "countryCode": "ES"
            },
            {
                "name": "Villalpando",
                "countryCode": "ES"
            },
            {
                "name": "Villalube",
                "countryCode": "ES"
            },
            {
                "name": "Villaluenga de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "Villalán de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villalón de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villamandos",
                "countryCode": "ES"
            },
            {
                "name": "Villamanín",
                "countryCode": "ES"
            },
            {
                "name": "Villamartín de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villamartín de Don Sancho",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Treviño",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Villamañán",
                "countryCode": "ES"
            },
            {
                "name": "Villamediana",
                "countryCode": "ES"
            },
            {
                "name": "Villamedianilla",
                "countryCode": "ES"
            },
            {
                "name": "Villamejil",
                "countryCode": "ES"
            },
            {
                "name": "Villameriel",
                "countryCode": "ES"
            },
            {
                "name": "Villamiel de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Villamol",
                "countryCode": "ES"
            },
            {
                "name": "Villamontán de la Valduerna",
                "countryCode": "ES"
            },
            {
                "name": "Villamor de los Escuderos",
                "countryCode": "ES"
            },
            {
                "name": "Villamoratiel de las Matas",
                "countryCode": "ES"
            },
            {
                "name": "Villamoronta",
                "countryCode": "ES"
            },
            {
                "name": "Villamuera de la Cueza",
                "countryCode": "ES"
            },
            {
                "name": "Villamuriel de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villamuriel de Cerrato",
                "countryCode": "ES"
            },
            {
                "name": "Villangómez",
                "countryCode": "ES"
            },
            {
                "name": "Villanubla",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Argaño",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Azoague",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Campeán",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Carazo",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Duero",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Gormaz",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Gumiel",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Gómez",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de San Mancio",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Teba",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Condesa",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de las Manzanas",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de las Peras",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Aceral",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Campillo",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Conde",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Rebollar",
                "countryCode": "ES"
            },
            {
                "name": "Villanuño de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Villanázar",
                "countryCode": "ES"
            },
            {
                "name": "Villaobispo de Otero",
                "countryCode": "ES"
            },
            {
                "name": "Villaprovedo",
                "countryCode": "ES"
            },
            {
                "name": "Villaquejida",
                "countryCode": "ES"
            },
            {
                "name": "Villaquilambre",
                "countryCode": "ES"
            },
            {
                "name": "Villaquirán de la Puebla",
                "countryCode": "ES"
            },
            {
                "name": "Villaquirán de los Infantes",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Ciervo",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Corneja",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Fallaves",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Gallimazo",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Peralonso",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Samaniego",
                "countryCode": "ES"
            },
            {
                "name": "Villar de la Yegua",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Ala",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Buey",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Río",
                "countryCode": "ES"
            },
            {
                "name": "Villaralbo",
                "countryCode": "ES"
            },
            {
                "name": "Villardeciervos",
                "countryCode": "ES"
            },
            {
                "name": "Villardefrades",
                "countryCode": "ES"
            },
            {
                "name": "Villardiegua de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Villardondiego",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo de Órbigo",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Villares de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Villares de la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Villares de Órbigo",
                "countryCode": "ES"
            },
            {
                "name": "Villariezo",
                "countryCode": "ES"
            },
            {
                "name": "Villarino de los Aires",
                "countryCode": "ES"
            },
            {
                "name": "Villarmayor",
                "countryCode": "ES"
            },
            {
                "name": "Villarmentero de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villarmentero de Esgueva",
                "countryCode": "ES"
            },
            {
                "name": "Villarmuerto",
                "countryCode": "ES"
            },
            {
                "name": "Villarrabé",
                "countryCode": "ES"
            },
            {
                "name": "Villarramiel",
                "countryCode": "ES"
            },
            {
                "name": "Villarrín de Campos",
                "countryCode": "ES"
            },
            {
                "name": "Villasabariego",
                "countryCode": "ES"
            },
            {
                "name": "Villasana de Mena",
                "countryCode": "ES"
            },
            {
                "name": "Villasandino",
                "countryCode": "ES"
            },
            {
                "name": "Villasarracino",
                "countryCode": "ES"
            },
            {
                "name": "Villasayas",
                "countryCode": "ES"
            },
            {
                "name": "Villasbuenas",
                "countryCode": "ES"
            },
            {
                "name": "Villasdardo",
                "countryCode": "ES"
            },
            {
                "name": "Villaseca de Arciel",
                "countryCode": "ES"
            },
            {
                "name": "Villaseco de los Gamitos",
                "countryCode": "ES"
            },
            {
                "name": "Villaseco de los Reyes",
                "countryCode": "ES"
            },
            {
                "name": "Villaselán",
                "countryCode": "ES"
            },
            {
                "name": "Villasexmir",
                "countryCode": "ES"
            },
            {
                "name": "Villasila de Valdavia",
                "countryCode": "ES"
            },
            {
                "name": "Villasrubias",
                "countryCode": "ES"
            },
            {
                "name": "Villatoro",
                "countryCode": "ES"
            },
            {
                "name": "Villatuelda",
                "countryCode": "ES"
            },
            {
                "name": "Villaturde",
                "countryCode": "ES"
            },
            {
                "name": "Villaturiel",
                "countryCode": "ES"
            },
            {
                "name": "Villaumbrales",
                "countryCode": "ES"
            },
            {
                "name": "Villavaquerín",
                "countryCode": "ES"
            },
            {
                "name": "Villavellid",
                "countryCode": "ES"
            },
            {
                "name": "Villavendimio",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde de Guareña",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde de Medina",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde de Montejo",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde-Mogina",
                "countryCode": "ES"
            },
            {
                "name": "Villaveza de Valverde",
                "countryCode": "ES"
            },
            {
                "name": "Villaveza del Agua",
                "countryCode": "ES"
            },
            {
                "name": "Villavicencio de los Caballeros",
                "countryCode": "ES"
            },
            {
                "name": "Villavieja de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Villaviudas",
                "countryCode": "ES"
            },
            {
                "name": "Villazala",
                "countryCode": "ES"
            },
            {
                "name": "Villazanzo de Valderaduey",
                "countryCode": "ES"
            },
            {
                "name": "Villazopeque",
                "countryCode": "ES"
            },
            {
                "name": "Villegas",
                "countryCode": "ES"
            },
            {
                "name": "Villeguillo",
                "countryCode": "ES"
            },
            {
                "name": "Villodre",
                "countryCode": "ES"
            },
            {
                "name": "Villodrigo",
                "countryCode": "ES"
            },
            {
                "name": "Villoldo",
                "countryCode": "ES"
            },
            {
                "name": "Villoruebo",
                "countryCode": "ES"
            },
            {
                "name": "Villoruela",
                "countryCode": "ES"
            },
            {
                "name": "Villoría",
                "countryCode": "ES"
            },
            {
                "name": "Villota del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Villovieco",
                "countryCode": "ES"
            },
            {
                "name": "Villán de Tordesillas",
                "countryCode": "ES"
            },
            {
                "name": "Villárdiga",
                "countryCode": "ES"
            },
            {
                "name": "Viloria",
                "countryCode": "ES"
            },
            {
                "name": "Viloria de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Vilvestre",
                "countryCode": "ES"
            },
            {
                "name": "Vilviestre del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Vinuesa",
                "countryCode": "ES"
            },
            {
                "name": "Vita",
                "countryCode": "ES"
            },
            {
                "name": "Vitigudino",
                "countryCode": "ES"
            },
            {
                "name": "Vizcaínos",
                "countryCode": "ES"
            },
            {
                "name": "Vizmanos",
                "countryCode": "ES"
            },
            {
                "name": "Viñegra de Moraña",
                "countryCode": "ES"
            },
            {
                "name": "Vozmediano",
                "countryCode": "ES"
            },
            {
                "name": "Wamba",
                "countryCode": "ES"
            },
            {
                "name": "Yanguas",
                "countryCode": "ES"
            },
            {
                "name": "Yanguas de Eresma",
                "countryCode": "ES"
            },
            {
                "name": "Yecla de Yeltes",
                "countryCode": "ES"
            },
            {
                "name": "Yelo",
                "countryCode": "ES"
            },
            {
                "name": "Zael",
                "countryCode": "ES"
            },
            {
                "name": "Zamarra",
                "countryCode": "ES"
            },
            {
                "name": "Zamayón",
                "countryCode": "ES"
            },
            {
                "name": "Zamora",
                "countryCode": "ES"
            },
            {
                "name": "Zapardiel de la Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Zapardiel de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Zarapicos",
                "countryCode": "ES"
            },
            {
                "name": "Zaratán",
                "countryCode": "ES"
            },
            {
                "name": "Zarzuela del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Zarzuela del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Zazuar",
                "countryCode": "ES"
            },
            {
                "name": "Zorita de la Frontera",
                "countryCode": "ES"
            },
            {
                "name": "Zotes del Páramo",
                "countryCode": "ES"
            },
            {
                "name": "Zuñeda",
                "countryCode": "ES"
            },
            {
                "name": "Ágreda",
                "countryCode": "ES"
            },
            {
                "name": "Ávila",
                "countryCode": "ES"
            },
            {
                "name": "Abanilla",
                "countryCode": "ES"
            },
            {
                "name": "Abarán",
                "countryCode": "ES"
            },
            {
                "name": "Albudeite",
                "countryCode": "ES"
            },
            {
                "name": "Alcantarilla",
                "countryCode": "ES"
            },
            {
                "name": "Aledo",
                "countryCode": "ES"
            },
            {
                "name": "Alguazas",
                "countryCode": "ES"
            },
            {
                "name": "Alhama de Murcia",
                "countryCode": "ES"
            },
            {
                "name": "Archena",
                "countryCode": "ES"
            },
            {
                "name": "Atamaría",
                "countryCode": "ES"
            },
            {
                "name": "Barrio de la Concepción",
                "countryCode": "ES"
            },
            {
                "name": "Beniel",
                "countryCode": "ES"
            },
            {
                "name": "Blanca",
                "countryCode": "ES"
            },
            {
                "name": "Bullas",
                "countryCode": "ES"
            },
            {
                "name": "Calasparra",
                "countryCode": "ES"
            },
            {
                "name": "Campos del Río",
                "countryCode": "ES"
            },
            {
                "name": "Caravaca",
                "countryCode": "ES"
            },
            {
                "name": "Cartagena",
                "countryCode": "ES"
            },
            {
                "name": "Cehegín",
                "countryCode": "ES"
            },
            {
                "name": "Ceuti",
                "countryCode": "ES"
            },
            {
                "name": "Cieza",
                "countryCode": "ES"
            },
            {
                "name": "Cobatillas",
                "countryCode": "ES"
            },
            {
                "name": "El Plan",
                "countryCode": "ES"
            },
            {
                "name": "Esparragal",
                "countryCode": "ES"
            },
            {
                "name": "Fortuna",
                "countryCode": "ES"
            },
            {
                "name": "Fuente-Álamo de Murcia",
                "countryCode": "ES"
            },
            {
                "name": "Jumilla",
                "countryCode": "ES"
            },
            {
                "name": "La Manga del Mar Menor",
                "countryCode": "ES"
            },
            {
                "name": "La Torrecilla",
                "countryCode": "ES"
            },
            {
                "name": "La Unión",
                "countryCode": "ES"
            },
            {
                "name": "Las Torres de Cotillas",
                "countryCode": "ES"
            },
            {
                "name": "Librilla",
                "countryCode": "ES"
            },
            {
                "name": "Lo Pagán",
                "countryCode": "ES"
            },
            {
                "name": "Lorca",
                "countryCode": "ES"
            },
            {
                "name": "Lorquí",
                "countryCode": "ES"
            },
            {
                "name": "Los Alcázares",
                "countryCode": "ES"
            },
            {
                "name": "Los Gabatos",
                "countryCode": "ES"
            },
            {
                "name": "Los Martínez",
                "countryCode": "ES"
            },
            {
                "name": "Mazarrón",
                "countryCode": "ES"
            },
            {
                "name": "Molina de Segura",
                "countryCode": "ES"
            },
            {
                "name": "Moratalla",
                "countryCode": "ES"
            },
            {
                "name": "Mula",
                "countryCode": "ES"
            },
            {
                "name": "Murcia",
                "countryCode": "ES"
            },
            {
                "name": "Ojós",
                "countryCode": "ES"
            },
            {
                "name": "Pliego",
                "countryCode": "ES"
            },
            {
                "name": "Puerto Lumbreras",
                "countryCode": "ES"
            },
            {
                "name": "Ricote",
                "countryCode": "ES"
            },
            {
                "name": "San Javier",
                "countryCode": "ES"
            },
            {
                "name": "San Pedro del Pinatar",
                "countryCode": "ES"
            },
            {
                "name": "Sangonera la Verde",
                "countryCode": "ES"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "ES"
            },
            {
                "name": "Santiago de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Santomera",
                "countryCode": "ES"
            },
            {
                "name": "Torre-Pacheco",
                "countryCode": "ES"
            },
            {
                "name": "Totana",
                "countryCode": "ES"
            },
            {
                "name": "Ulea",
                "countryCode": "ES"
            },
            {
                "name": "Yecla",
                "countryCode": "ES"
            },
            {
                "name": "Águilas",
                "countryCode": "ES"
            },
            {
                "name": "Ajalvir",
                "countryCode": "ES"
            },
            {
                "name": "Alameda del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Alcalá de Henares",
                "countryCode": "ES"
            },
            {
                "name": "Alcobendas",
                "countryCode": "ES"
            },
            {
                "name": "Alcorcón",
                "countryCode": "ES"
            },
            {
                "name": "Aldea del Fresno",
                "countryCode": "ES"
            },
            {
                "name": "Algete",
                "countryCode": "ES"
            },
            {
                "name": "Alpedrete",
                "countryCode": "ES"
            },
            {
                "name": "Ambite",
                "countryCode": "ES"
            },
            {
                "name": "Anchuelo",
                "countryCode": "ES"
            },
            {
                "name": "Aranjuez",
                "countryCode": "ES"
            },
            {
                "name": "Arganda",
                "countryCode": "ES"
            },
            {
                "name": "Arganzuela",
                "countryCode": "ES"
            },
            {
                "name": "Arroyomolinos",
                "countryCode": "ES"
            },
            {
                "name": "Barajas de Madrid",
                "countryCode": "ES"
            },
            {
                "name": "Batres",
                "countryCode": "ES"
            },
            {
                "name": "Becerril de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Belmonte de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Berzosa del Lozoya",
                "countryCode": "ES"
            },
            {
                "name": "Boadilla del Monte",
                "countryCode": "ES"
            },
            {
                "name": "Boalo",
                "countryCode": "ES"
            },
            {
                "name": "Braojos",
                "countryCode": "ES"
            },
            {
                "name": "Brea de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Brunete",
                "countryCode": "ES"
            },
            {
                "name": "Buitrago del Lozoya",
                "countryCode": "ES"
            },
            {
                "name": "Bustarviejo",
                "countryCode": "ES"
            },
            {
                "name": "Cabanillas de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Cadalso de los Vidrios",
                "countryCode": "ES"
            },
            {
                "name": "Camarma de Esteruelas",
                "countryCode": "ES"
            },
            {
                "name": "Campo Real",
                "countryCode": "ES"
            },
            {
                "name": "Canencia",
                "countryCode": "ES"
            },
            {
                "name": "Carabanchel",
                "countryCode": "ES"
            },
            {
                "name": "Carabaña",
                "countryCode": "ES"
            },
            {
                "name": "Casarrubuelos",
                "countryCode": "ES"
            },
            {
                "name": "Cenicientos",
                "countryCode": "ES"
            },
            {
                "name": "Cercedilla",
                "countryCode": "ES"
            },
            {
                "name": "Cervera de Buitrago",
                "countryCode": "ES"
            },
            {
                "name": "Chamartín",
                "countryCode": "ES"
            },
            {
                "name": "Chamberí",
                "countryCode": "ES"
            },
            {
                "name": "Chapinería",
                "countryCode": "ES"
            },
            {
                "name": "Chinchón",
                "countryCode": "ES"
            },
            {
                "name": "Ciempozuelos",
                "countryCode": "ES"
            },
            {
                "name": "City Center",
                "countryCode": "ES"
            },
            {
                "name": "Ciudad Lineal",
                "countryCode": "ES"
            },
            {
                "name": "Cobeña",
                "countryCode": "ES"
            },
            {
                "name": "Collado Mediano",
                "countryCode": "ES"
            },
            {
                "name": "Collado-Villalba",
                "countryCode": "ES"
            },
            {
                "name": "Colmenar Viejo",
                "countryCode": "ES"
            },
            {
                "name": "Colmenar de Oreja",
                "countryCode": "ES"
            },
            {
                "name": "Colmenar del Arroyo",
                "countryCode": "ES"
            },
            {
                "name": "Colmenarejo",
                "countryCode": "ES"
            },
            {
                "name": "Corpa",
                "countryCode": "ES"
            },
            {
                "name": "Coslada",
                "countryCode": "ES"
            },
            {
                "name": "Cubas",
                "countryCode": "ES"
            },
            {
                "name": "Daganzo de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "El Escorial",
                "countryCode": "ES"
            },
            {
                "name": "El Molar",
                "countryCode": "ES"
            },
            {
                "name": "El Pardo",
                "countryCode": "ES"
            },
            {
                "name": "El Vellón",
                "countryCode": "ES"
            },
            {
                "name": "El Álamo",
                "countryCode": "ES"
            },
            {
                "name": "Estremera",
                "countryCode": "ES"
            },
            {
                "name": "Fresnedillas",
                "countryCode": "ES"
            },
            {
                "name": "Fresno de Torote",
                "countryCode": "ES"
            },
            {
                "name": "Fuencarral-El Pardo",
                "countryCode": "ES"
            },
            {
                "name": "Fuenlabrada",
                "countryCode": "ES"
            },
            {
                "name": "Fuente el Saz",
                "countryCode": "ES"
            },
            {
                "name": "Fuentidueña de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Galapagar",
                "countryCode": "ES"
            },
            {
                "name": "Garganta de los Montes",
                "countryCode": "ES"
            },
            {
                "name": "Gascones",
                "countryCode": "ES"
            },
            {
                "name": "Getafe",
                "countryCode": "ES"
            },
            {
                "name": "Griñón",
                "countryCode": "ES"
            },
            {
                "name": "Guadalix de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Guadarrama",
                "countryCode": "ES"
            },
            {
                "name": "Horcajo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Horcajuelo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Hortaleza",
                "countryCode": "ES"
            },
            {
                "name": "Hoyo de Manzanares",
                "countryCode": "ES"
            },
            {
                "name": "Humanes de Madrid",
                "countryCode": "ES"
            },
            {
                "name": "La Cabrera",
                "countryCode": "ES"
            },
            {
                "name": "Las Matas",
                "countryCode": "ES"
            },
            {
                "name": "Las Rozas de Madrid",
                "countryCode": "ES"
            },
            {
                "name": "Las Tablas",
                "countryCode": "ES"
            },
            {
                "name": "Latina",
                "countryCode": "ES"
            },
            {
                "name": "Leganés",
                "countryCode": "ES"
            },
            {
                "name": "Loeches",
                "countryCode": "ES"
            },
            {
                "name": "Los Molinos",
                "countryCode": "ES"
            },
            {
                "name": "Los Santos de la Humosa",
                "countryCode": "ES"
            },
            {
                "name": "Lozoya",
                "countryCode": "ES"
            },
            {
                "name": "Madrid",
                "countryCode": "ES"
            },
            {
                "name": "Majadahonda",
                "countryCode": "ES"
            },
            {
                "name": "Manzanares el Real",
                "countryCode": "ES"
            },
            {
                "name": "Meco",
                "countryCode": "ES"
            },
            {
                "name": "Mejorada del Campo",
                "countryCode": "ES"
            },
            {
                "name": "Miraflores de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Moncloa-Aravaca",
                "countryCode": "ES"
            },
            {
                "name": "Montejo de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Moraleja de Enmedio",
                "countryCode": "ES"
            },
            {
                "name": "Moralzarzal",
                "countryCode": "ES"
            },
            {
                "name": "Morata de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Moratalaz",
                "countryCode": "ES"
            },
            {
                "name": "Móstoles",
                "countryCode": "ES"
            },
            {
                "name": "Navacerrada",
                "countryCode": "ES"
            },
            {
                "name": "Navalafuente",
                "countryCode": "ES"
            },
            {
                "name": "Navalagamella",
                "countryCode": "ES"
            },
            {
                "name": "Navalcarnero",
                "countryCode": "ES"
            },
            {
                "name": "Navalquejigo",
                "countryCode": "ES"
            },
            {
                "name": "Navas del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Nuevo Baztán",
                "countryCode": "ES"
            },
            {
                "name": "Paracuellos de Jarama",
                "countryCode": "ES"
            },
            {
                "name": "Parla",
                "countryCode": "ES"
            },
            {
                "name": "Patones",
                "countryCode": "ES"
            },
            {
                "name": "Pedrezuela",
                "countryCode": "ES"
            },
            {
                "name": "Pelayos de la Presa",
                "countryCode": "ES"
            },
            {
                "name": "Perales de Tajuña",
                "countryCode": "ES"
            },
            {
                "name": "Pezuela de las Torres",
                "countryCode": "ES"
            },
            {
                "name": "Pinar de Chamartín",
                "countryCode": "ES"
            },
            {
                "name": "Pinilla del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Pinto",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo de Alarcón",
                "countryCode": "ES"
            },
            {
                "name": "Pozuelo del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Madrid",
                "countryCode": "ES"
            },
            {
                "name": "Prádena del Rincón",
                "countryCode": "ES"
            },
            {
                "name": "Puente de Vallecas",
                "countryCode": "ES"
            },
            {
                "name": "Quijorna",
                "countryCode": "ES"
            },
            {
                "name": "Rascafría",
                "countryCode": "ES"
            },
            {
                "name": "Redueña",
                "countryCode": "ES"
            },
            {
                "name": "Retiro",
                "countryCode": "ES"
            },
            {
                "name": "Ribatejada",
                "countryCode": "ES"
            },
            {
                "name": "Rivas-Vaciamadrid",
                "countryCode": "ES"
            },
            {
                "name": "Robledillo de la Jara",
                "countryCode": "ES"
            },
            {
                "name": "Robledo de Chavela",
                "countryCode": "ES"
            },
            {
                "name": "Rozas de Puerto Real",
                "countryCode": "ES"
            },
            {
                "name": "Salamanca",
                "countryCode": "ES"
            },
            {
                "name": "San Agustín del Guadalix",
                "countryCode": "ES"
            },
            {
                "name": "San Blas-Canillejas",
                "countryCode": "ES"
            },
            {
                "name": "San Fernando de Henares",
                "countryCode": "ES"
            },
            {
                "name": "San Lorenzo de El Escorial",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Valdeiglesias",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de la Vega",
                "countryCode": "ES"
            },
            {
                "name": "San Sebastián de los Reyes",
                "countryCode": "ES"
            },
            {
                "name": "Santa María de la Alameda",
                "countryCode": "ES"
            },
            {
                "name": "Santorcaz",
                "countryCode": "ES"
            },
            {
                "name": "Serranillos del Valle",
                "countryCode": "ES"
            },
            {
                "name": "Sevilla La Nueva",
                "countryCode": "ES"
            },
            {
                "name": "Talamanca de Jarama",
                "countryCode": "ES"
            },
            {
                "name": "Tetuán de las Victorias",
                "countryCode": "ES"
            },
            {
                "name": "Tielmes",
                "countryCode": "ES"
            },
            {
                "name": "Titulcia",
                "countryCode": "ES"
            },
            {
                "name": "Torrejón de Ardoz",
                "countryCode": "ES"
            },
            {
                "name": "Torrejón de Velasco",
                "countryCode": "ES"
            },
            {
                "name": "Torrejón de la Calzada",
                "countryCode": "ES"
            },
            {
                "name": "Torrelaguna",
                "countryCode": "ES"
            },
            {
                "name": "Torrelodones",
                "countryCode": "ES"
            },
            {
                "name": "Torremocha de Jarama",
                "countryCode": "ES"
            },
            {
                "name": "Torres de la Alameda",
                "countryCode": "ES"
            },
            {
                "name": "Tres Cantos",
                "countryCode": "ES"
            },
            {
                "name": "Usera",
                "countryCode": "ES"
            },
            {
                "name": "Valdaracete",
                "countryCode": "ES"
            },
            {
                "name": "Valdeavero",
                "countryCode": "ES"
            },
            {
                "name": "Valdemanco",
                "countryCode": "ES"
            },
            {
                "name": "Valdemaqueda",
                "countryCode": "ES"
            },
            {
                "name": "Valdemorillo",
                "countryCode": "ES"
            },
            {
                "name": "Valdemoro",
                "countryCode": "ES"
            },
            {
                "name": "Valdeolmos",
                "countryCode": "ES"
            },
            {
                "name": "Valdepiélagos",
                "countryCode": "ES"
            },
            {
                "name": "Valdetorres de Jarama",
                "countryCode": "ES"
            },
            {
                "name": "Valdilecha",
                "countryCode": "ES"
            },
            {
                "name": "Valverde de Alcalá",
                "countryCode": "ES"
            },
            {
                "name": "Velilla de San Antonio",
                "countryCode": "ES"
            },
            {
                "name": "Venturada",
                "countryCode": "ES"
            },
            {
                "name": "Vicálvaro",
                "countryCode": "ES"
            },
            {
                "name": "Villa de Vallecas",
                "countryCode": "ES"
            },
            {
                "name": "Villa del Prado",
                "countryCode": "ES"
            },
            {
                "name": "Villaconejos",
                "countryCode": "ES"
            },
            {
                "name": "Villalbilla",
                "countryCode": "ES"
            },
            {
                "name": "Villamanrique de Tajo",
                "countryCode": "ES"
            },
            {
                "name": "Villamanta",
                "countryCode": "ES"
            },
            {
                "name": "Villamantilla",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Perales",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de la Cañada",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva del Pardillo",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Olmo",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo de Salvanés",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde",
                "countryCode": "ES"
            },
            {
                "name": "Villaviciosa de Odón",
                "countryCode": "ES"
            },
            {
                "name": "Villavieja del Lozoya",
                "countryCode": "ES"
            },
            {
                "name": "Zarzalejo",
                "countryCode": "ES"
            },
            {
                "name": "Melilla",
                "countryCode": "ES"
            },
            {
                "name": "Abáigar",
                "countryCode": "ES"
            },
            {
                "name": "Adiós",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar de Codés",
                "countryCode": "ES"
            },
            {
                "name": "Allo",
                "countryCode": "ES"
            },
            {
                "name": "Altsasu",
                "countryCode": "ES"
            },
            {
                "name": "Ancín",
                "countryCode": "ES"
            },
            {
                "name": "Andosilla",
                "countryCode": "ES"
            },
            {
                "name": "Aoiz",
                "countryCode": "ES"
            },
            {
                "name": "Aranarache",
                "countryCode": "ES"
            },
            {
                "name": "Arano",
                "countryCode": "ES"
            },
            {
                "name": "Aras",
                "countryCode": "ES"
            },
            {
                "name": "Arbizu",
                "countryCode": "ES"
            },
            {
                "name": "Arellano",
                "countryCode": "ES"
            },
            {
                "name": "Areso",
                "countryCode": "ES"
            },
            {
                "name": "Arguedas",
                "countryCode": "ES"
            },
            {
                "name": "Aria",
                "countryCode": "ES"
            },
            {
                "name": "Armañanzas",
                "countryCode": "ES"
            },
            {
                "name": "Arruazu",
                "countryCode": "ES"
            },
            {
                "name": "Arróniz",
                "countryCode": "ES"
            },
            {
                "name": "Artajona",
                "countryCode": "ES"
            },
            {
                "name": "Artazu",
                "countryCode": "ES"
            },
            {
                "name": "Atarrabia",
                "countryCode": "ES"
            },
            {
                "name": "Ayegui",
                "countryCode": "ES"
            },
            {
                "name": "Azagra",
                "countryCode": "ES"
            },
            {
                "name": "Azuelo",
                "countryCode": "ES"
            },
            {
                "name": "Añorbe",
                "countryCode": "ES"
            },
            {
                "name": "Bakaiku",
                "countryCode": "ES"
            },
            {
                "name": "Barañáin",
                "countryCode": "ES"
            },
            {
                "name": "Bargota",
                "countryCode": "ES"
            },
            {
                "name": "Barillas",
                "countryCode": "ES"
            },
            {
                "name": "Barásoain",
                "countryCode": "ES"
            },
            {
                "name": "Beire",
                "countryCode": "ES"
            },
            {
                "name": "Belascoáin",
                "countryCode": "ES"
            },
            {
                "name": "Bera",
                "countryCode": "ES"
            },
            {
                "name": "Berbinzana",
                "countryCode": "ES"
            },
            {
                "name": "Beriáin",
                "countryCode": "ES"
            },
            {
                "name": "Berriobeiti",
                "countryCode": "ES"
            },
            {
                "name": "Berriozar",
                "countryCode": "ES"
            },
            {
                "name": "Betelu",
                "countryCode": "ES"
            },
            {
                "name": "Burlata",
                "countryCode": "ES"
            },
            {
                "name": "Buñuel",
                "countryCode": "ES"
            },
            {
                "name": "Cabredo",
                "countryCode": "ES"
            },
            {
                "name": "Cadreita",
                "countryCode": "ES"
            },
            {
                "name": "Caparroso",
                "countryCode": "ES"
            },
            {
                "name": "Carcastillo",
                "countryCode": "ES"
            },
            {
                "name": "Cascante",
                "countryCode": "ES"
            },
            {
                "name": "Castejón",
                "countryCode": "ES"
            },
            {
                "name": "Castillonuevo",
                "countryCode": "ES"
            },
            {
                "name": "Cintruénigo",
                "countryCode": "ES"
            },
            {
                "name": "Cirauqui",
                "countryCode": "ES"
            },
            {
                "name": "Ciriza",
                "countryCode": "ES"
            },
            {
                "name": "Cizur Mayor",
                "countryCode": "ES"
            },
            {
                "name": "Corella",
                "countryCode": "ES"
            },
            {
                "name": "Cárcar",
                "countryCode": "ES"
            },
            {
                "name": "Cáseda",
                "countryCode": "ES"
            },
            {
                "name": "Desojo",
                "countryCode": "ES"
            },
            {
                "name": "Dicastillo",
                "countryCode": "ES"
            },
            {
                "name": "Doneztebe",
                "countryCode": "ES"
            },
            {
                "name": "Echarri",
                "countryCode": "ES"
            },
            {
                "name": "Echarri-Aranaz",
                "countryCode": "ES"
            },
            {
                "name": "Elgorriaga",
                "countryCode": "ES"
            },
            {
                "name": "Elorz",
                "countryCode": "ES"
            },
            {
                "name": "Enériz",
                "countryCode": "ES"
            },
            {
                "name": "Ermitagaña",
                "countryCode": "ES"
            },
            {
                "name": "Eslava",
                "countryCode": "ES"
            },
            {
                "name": "Espronceda",
                "countryCode": "ES"
            },
            {
                "name": "Estella-Lizarra",
                "countryCode": "ES"
            },
            {
                "name": "Etayo",
                "countryCode": "ES"
            },
            {
                "name": "Eulate",
                "countryCode": "ES"
            },
            {
                "name": "Falces",
                "countryCode": "ES"
            },
            {
                "name": "Fitero",
                "countryCode": "ES"
            },
            {
                "name": "Fontellas",
                "countryCode": "ES"
            },
            {
                "name": "Funes",
                "countryCode": "ES"
            },
            {
                "name": "Fustiñana",
                "countryCode": "ES"
            },
            {
                "name": "Galar",
                "countryCode": "ES"
            },
            {
                "name": "Gallipienzo",
                "countryCode": "ES"
            },
            {
                "name": "Garde",
                "countryCode": "ES"
            },
            {
                "name": "Garralda",
                "countryCode": "ES"
            },
            {
                "name": "Garínoain",
                "countryCode": "ES"
            },
            {
                "name": "Genevilla",
                "countryCode": "ES"
            },
            {
                "name": "Goizueta",
                "countryCode": "ES"
            },
            {
                "name": "Goñi",
                "countryCode": "ES"
            },
            {
                "name": "Guirguillano",
                "countryCode": "ES"
            },
            {
                "name": "Huarte-Uharte",
                "countryCode": "ES"
            },
            {
                "name": "Igantzi",
                "countryCode": "ES"
            },
            {
                "name": "Igúzquiza",
                "countryCode": "ES"
            },
            {
                "name": "Irañeta",
                "countryCode": "ES"
            },
            {
                "name": "Ituren",
                "countryCode": "ES"
            },
            {
                "name": "Iturmendi",
                "countryCode": "ES"
            },
            {
                "name": "Iturrama",
                "countryCode": "ES"
            },
            {
                "name": "Javier",
                "countryCode": "ES"
            },
            {
                "name": "Lapoblación",
                "countryCode": "ES"
            },
            {
                "name": "Larraga",
                "countryCode": "ES"
            },
            {
                "name": "Larraona",
                "countryCode": "ES"
            },
            {
                "name": "Lazagurría",
                "countryCode": "ES"
            },
            {
                "name": "Leache",
                "countryCode": "ES"
            },
            {
                "name": "Legarda",
                "countryCode": "ES"
            },
            {
                "name": "Legaria",
                "countryCode": "ES"
            },
            {
                "name": "Lerga",
                "countryCode": "ES"
            },
            {
                "name": "Lerín",
                "countryCode": "ES"
            },
            {
                "name": "Lezáun",
                "countryCode": "ES"
            },
            {
                "name": "Lizoáin",
                "countryCode": "ES"
            },
            {
                "name": "Liédena",
                "countryCode": "ES"
            },
            {
                "name": "Lodosa",
                "countryCode": "ES"
            },
            {
                "name": "Los Arcos",
                "countryCode": "ES"
            },
            {
                "name": "Lumbier",
                "countryCode": "ES"
            },
            {
                "name": "Marañón",
                "countryCode": "ES"
            },
            {
                "name": "Marcilla",
                "countryCode": "ES"
            },
            {
                "name": "Mañeru",
                "countryCode": "ES"
            },
            {
                "name": "Mendavia",
                "countryCode": "ES"
            },
            {
                "name": "Mendigorría",
                "countryCode": "ES"
            },
            {
                "name": "Metauten",
                "countryCode": "ES"
            },
            {
                "name": "Milagro",
                "countryCode": "ES"
            },
            {
                "name": "Mirafuentes",
                "countryCode": "ES"
            },
            {
                "name": "Miranda de Arga",
                "countryCode": "ES"
            },
            {
                "name": "Monreal",
                "countryCode": "ES"
            },
            {
                "name": "Morentin",
                "countryCode": "ES"
            },
            {
                "name": "Murchante",
                "countryCode": "ES"
            },
            {
                "name": "Murieta",
                "countryCode": "ES"
            },
            {
                "name": "Murillo el Fruto",
                "countryCode": "ES"
            },
            {
                "name": "Muruzábal",
                "countryCode": "ES"
            },
            {
                "name": "Mélida",
                "countryCode": "ES"
            },
            {
                "name": "Navascués",
                "countryCode": "ES"
            },
            {
                "name": "Nazar",
                "countryCode": "ES"
            },
            {
                "name": "Obanos",
                "countryCode": "ES"
            },
            {
                "name": "Oco",
                "countryCode": "ES"
            },
            {
                "name": "Olazagutía",
                "countryCode": "ES"
            },
            {
                "name": "Olejua",
                "countryCode": "ES"
            },
            {
                "name": "Olite",
                "countryCode": "ES"
            },
            {
                "name": "Oltza",
                "countryCode": "ES"
            },
            {
                "name": "Olóriz",
                "countryCode": "ES"
            },
            {
                "name": "Orbara",
                "countryCode": "ES"
            },
            {
                "name": "Orkoien",
                "countryCode": "ES"
            },
            {
                "name": "Oronz",
                "countryCode": "ES"
            },
            {
                "name": "Orísoain",
                "countryCode": "ES"
            },
            {
                "name": "Oteiza",
                "countryCode": "ES"
            },
            {
                "name": "Pamplona",
                "countryCode": "ES"
            },
            {
                "name": "Peralta",
                "countryCode": "ES"
            },
            {
                "name": "Petilla de Aragón",
                "countryCode": "ES"
            },
            {
                "name": "Piedramillera",
                "countryCode": "ES"
            },
            {
                "name": "Pitillas",
                "countryCode": "ES"
            },
            {
                "name": "Primer Ensanche",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Navarra",
                "countryCode": "ES"
            },
            {
                "name": "Puente la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Pueyo",
                "countryCode": "ES"
            },
            {
                "name": "Ribaforada",
                "countryCode": "ES"
            },
            {
                "name": "Saldías",
                "countryCode": "ES"
            },
            {
                "name": "Salinas de Oro",
                "countryCode": "ES"
            },
            {
                "name": "San Adrián",
                "countryCode": "ES"
            },
            {
                "name": "San Martín de Unx",
                "countryCode": "ES"
            },
            {
                "name": "Sangüesa/Zangoza",
                "countryCode": "ES"
            },
            {
                "name": "Sansol",
                "countryCode": "ES"
            },
            {
                "name": "Santacara",
                "countryCode": "ES"
            },
            {
                "name": "Sarriguren",
                "countryCode": "ES"
            },
            {
                "name": "Sartaguda",
                "countryCode": "ES"
            },
            {
                "name": "Segundo Ensanche",
                "countryCode": "ES"
            },
            {
                "name": "Sesma",
                "countryCode": "ES"
            },
            {
                "name": "Sorlada",
                "countryCode": "ES"
            },
            {
                "name": "Tafalla",
                "countryCode": "ES"
            },
            {
                "name": "Tirapu",
                "countryCode": "ES"
            },
            {
                "name": "Torres del Río",
                "countryCode": "ES"
            },
            {
                "name": "Tudela",
                "countryCode": "ES"
            },
            {
                "name": "Tulebras",
                "countryCode": "ES"
            },
            {
                "name": "Ujué",
                "countryCode": "ES"
            },
            {
                "name": "Unzué",
                "countryCode": "ES"
            },
            {
                "name": "Urzainqui",
                "countryCode": "ES"
            },
            {
                "name": "Uterga",
                "countryCode": "ES"
            },
            {
                "name": "Valtierra",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca",
                "countryCode": "ES"
            },
            {
                "name": "Villamayor de Monjardín",
                "countryCode": "ES"
            },
            {
                "name": "Villatuerta",
                "countryCode": "ES"
            },
            {
                "name": "Yerri",
                "countryCode": "ES"
            },
            {
                "name": "Yesa",
                "countryCode": "ES"
            },
            {
                "name": "Zubieta",
                "countryCode": "ES"
            },
            {
                "name": "Zugarramurdi",
                "countryCode": "ES"
            },
            {
                "name": "Zúñiga",
                "countryCode": "ES"
            },
            {
                "name": "Alaior",
                "countryCode": "ES"
            },
            {
                "name": "Alaró",
                "countryCode": "ES"
            },
            {
                "name": "Alcúdia",
                "countryCode": "ES"
            },
            {
                "name": "Algaida",
                "countryCode": "ES"
            },
            {
                "name": "Andratx",
                "countryCode": "ES"
            },
            {
                "name": "Ariany",
                "countryCode": "ES"
            },
            {
                "name": "Artà",
                "countryCode": "ES"
            },
            {
                "name": "Banyalbufar",
                "countryCode": "ES"
            },
            {
                "name": "Binissalem",
                "countryCode": "ES"
            },
            {
                "name": "Bunyola",
                "countryCode": "ES"
            },
            {
                "name": "Búger",
                "countryCode": "ES"
            },
            {
                "name": "Cala Rajada",
                "countryCode": "ES"
            },
            {
                "name": "Cala d'Or",
                "countryCode": "ES"
            },
            {
                "name": "Calonge",
                "countryCode": "ES"
            },
            {
                "name": "Calvià",
                "countryCode": "ES"
            },
            {
                "name": "Camp de Mar",
                "countryCode": "ES"
            },
            {
                "name": "Campanet",
                "countryCode": "ES"
            },
            {
                "name": "Campos",
                "countryCode": "ES"
            },
            {
                "name": "Can Pastilla",
                "countryCode": "ES"
            },
            {
                "name": "Can Picafort",
                "countryCode": "ES"
            },
            {
                "name": "Capdepera",
                "countryCode": "ES"
            },
            {
                "name": "Ciutadella",
                "countryCode": "ES"
            },
            {
                "name": "Coll d'en Rabassa",
                "countryCode": "ES"
            },
            {
                "name": "Colònia de Sant Jordi",
                "countryCode": "ES"
            },
            {
                "name": "Consell",
                "countryCode": "ES"
            },
            {
                "name": "Costitx",
                "countryCode": "ES"
            },
            {
                "name": "Deià",
                "countryCode": "ES"
            },
            {
                "name": "El Toro",
                "countryCode": "ES"
            },
            {
                "name": "Es Castell",
                "countryCode": "ES"
            },
            {
                "name": "Es Molinar",
                "countryCode": "ES"
            },
            {
                "name": "Escorca",
                "countryCode": "ES"
            },
            {
                "name": "Esporles",
                "countryCode": "ES"
            },
            {
                "name": "Estellencs",
                "countryCode": "ES"
            },
            {
                "name": "Felanitx",
                "countryCode": "ES"
            },
            {
                "name": "Ferreries",
                "countryCode": "ES"
            },
            {
                "name": "Fornalutx",
                "countryCode": "ES"
            },
            {
                "name": "Ibiza",
                "countryCode": "ES"
            },
            {
                "name": "Illes Balears",
                "countryCode": "ES"
            },
            {
                "name": "Inca",
                "countryCode": "ES"
            },
            {
                "name": "Lloret de Vistalegre",
                "countryCode": "ES"
            },
            {
                "name": "Lloseta",
                "countryCode": "ES"
            },
            {
                "name": "Llubí",
                "countryCode": "ES"
            },
            {
                "name": "Llucmajor",
                "countryCode": "ES"
            },
            {
                "name": "Magaluf",
                "countryCode": "ES"
            },
            {
                "name": "Manacor",
                "countryCode": "ES"
            },
            {
                "name": "Mancor de la Vall",
                "countryCode": "ES"
            },
            {
                "name": "Maria de la Salut",
                "countryCode": "ES"
            },
            {
                "name": "Marratxí",
                "countryCode": "ES"
            },
            {
                "name": "Maó",
                "countryCode": "ES"
            },
            {
                "name": "Mercadal",
                "countryCode": "ES"
            },
            {
                "name": "Montuïri",
                "countryCode": "ES"
            },
            {
                "name": "Muro",
                "countryCode": "ES"
            },
            {
                "name": "Palma",
                "countryCode": "ES"
            },
            {
                "name": "Palmanova",
                "countryCode": "ES"
            },
            {
                "name": "Peguera",
                "countryCode": "ES"
            },
            {
                "name": "Petra",
                "countryCode": "ES"
            },
            {
                "name": "Pollença",
                "countryCode": "ES"
            },
            {
                "name": "Porreres",
                "countryCode": "ES"
            },
            {
                "name": "Port d'Alcúdia",
                "countryCode": "ES"
            },
            {
                "name": "Port de Pollença",
                "countryCode": "ES"
            },
            {
                "name": "Port de Sóller",
                "countryCode": "ES"
            },
            {
                "name": "Porto Cristo",
                "countryCode": "ES"
            },
            {
                "name": "Portocolom",
                "countryCode": "ES"
            },
            {
                "name": "Puigpunyent",
                "countryCode": "ES"
            },
            {
                "name": "Sant Antoni de Portmany",
                "countryCode": "ES"
            },
            {
                "name": "Sant Francesc de Formentera",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan",
                "countryCode": "ES"
            },
            {
                "name": "Sant Joan de Labritja",
                "countryCode": "ES"
            },
            {
                "name": "Sant Josep de sa Talaia",
                "countryCode": "ES"
            },
            {
                "name": "Sant Llorenç des Cardassar",
                "countryCode": "ES"
            },
            {
                "name": "Sant Lluís",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eugènia",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eulària des Riu",
                "countryCode": "ES"
            },
            {
                "name": "Santa Margalida",
                "countryCode": "ES"
            },
            {
                "name": "Santa Ponsa",
                "countryCode": "ES"
            },
            {
                "name": "Santanyí",
                "countryCode": "ES"
            },
            {
                "name": "Selva",
                "countryCode": "ES"
            },
            {
                "name": "Sencelles",
                "countryCode": "ES"
            },
            {
                "name": "Ses Salines",
                "countryCode": "ES"
            },
            {
                "name": "Sineu",
                "countryCode": "ES"
            },
            {
                "name": "Son Ferrer",
                "countryCode": "ES"
            },
            {
                "name": "Son Servera",
                "countryCode": "ES"
            },
            {
                "name": "Sóller",
                "countryCode": "ES"
            },
            {
                "name": "Valldemossa",
                "countryCode": "ES"
            },
            {
                "name": "Vilafranca de Bonany",
                "countryCode": "ES"
            },
            {
                "name": "s'Arenal",
                "countryCode": "ES"
            },
            {
                "name": "sa Pobla",
                "countryCode": "ES"
            },
            {
                "name": "Aduna",
                "countryCode": "ES"
            },
            {
                "name": "Agurain / Salvatierra",
                "countryCode": "ES"
            },
            {
                "name": "Aia",
                "countryCode": "ES"
            },
            {
                "name": "Aizarnazabal",
                "countryCode": "ES"
            },
            {
                "name": "Albiztur",
                "countryCode": "ES"
            },
            {
                "name": "Algorta",
                "countryCode": "ES"
            },
            {
                "name": "Alkiza",
                "countryCode": "ES"
            },
            {
                "name": "Alonsotegi",
                "countryCode": "ES"
            },
            {
                "name": "Amezketa",
                "countryCode": "ES"
            },
            {
                "name": "Amorebieta",
                "countryCode": "ES"
            },
            {
                "name": "Amoroto",
                "countryCode": "ES"
            },
            {
                "name": "Amurrio",
                "countryCode": "ES"
            },
            {
                "name": "Andoain",
                "countryCode": "ES"
            },
            {
                "name": "Anoeta",
                "countryCode": "ES"
            },
            {
                "name": "Antzuola",
                "countryCode": "ES"
            },
            {
                "name": "Araba / Álava",
                "countryCode": "ES"
            },
            {
                "name": "Araia",
                "countryCode": "ES"
            },
            {
                "name": "Arama",
                "countryCode": "ES"
            },
            {
                "name": "Aretxabaleta",
                "countryCode": "ES"
            },
            {
                "name": "Armiñón",
                "countryCode": "ES"
            },
            {
                "name": "Arrasate / Mondragón",
                "countryCode": "ES"
            },
            {
                "name": "Arrigorriaga",
                "countryCode": "ES"
            },
            {
                "name": "Asteasu",
                "countryCode": "ES"
            },
            {
                "name": "Astigarraga",
                "countryCode": "ES"
            },
            {
                "name": "Ataun",
                "countryCode": "ES"
            },
            {
                "name": "Aulesti",
                "countryCode": "ES"
            },
            {
                "name": "Avellaneda",
                "countryCode": "ES"
            },
            {
                "name": "Axpe de Busturia",
                "countryCode": "ES"
            },
            {
                "name": "Azkoitia",
                "countryCode": "ES"
            },
            {
                "name": "Azpeitia",
                "countryCode": "ES"
            },
            {
                "name": "Bakio",
                "countryCode": "ES"
            },
            {
                "name": "Baliarrain",
                "countryCode": "ES"
            },
            {
                "name": "Barakaldo",
                "countryCode": "ES"
            },
            {
                "name": "Basauri",
                "countryCode": "ES"
            },
            {
                "name": "Bastida / Labastida",
                "countryCode": "ES"
            },
            {
                "name": "Beasain",
                "countryCode": "ES"
            },
            {
                "name": "Beizama",
                "countryCode": "ES"
            },
            {
                "name": "Berango",
                "countryCode": "ES"
            },
            {
                "name": "Berantevilla",
                "countryCode": "ES"
            },
            {
                "name": "Bergara",
                "countryCode": "ES"
            },
            {
                "name": "Bermeo",
                "countryCode": "ES"
            },
            {
                "name": "Berriatua",
                "countryCode": "ES"
            },
            {
                "name": "Berriz",
                "countryCode": "ES"
            },
            {
                "name": "Berrobi",
                "countryCode": "ES"
            },
            {
                "name": "Bilbao",
                "countryCode": "ES"
            },
            {
                "name": "Bizkaia",
                "countryCode": "ES"
            },
            {
                "name": "Deba",
                "countryCode": "ES"
            },
            {
                "name": "Derio",
                "countryCode": "ES"
            },
            {
                "name": "Donostia / San Sebastián",
                "countryCode": "ES"
            },
            {
                "name": "Durango",
                "countryCode": "ES"
            },
            {
                "name": "Ea",
                "countryCode": "ES"
            },
            {
                "name": "Eibar",
                "countryCode": "ES"
            },
            {
                "name": "Elciego",
                "countryCode": "ES"
            },
            {
                "name": "Elexalde",
                "countryCode": "ES"
            },
            {
                "name": "Elgoibar",
                "countryCode": "ES"
            },
            {
                "name": "Elorrio",
                "countryCode": "ES"
            },
            {
                "name": "Erandio",
                "countryCode": "ES"
            },
            {
                "name": "Ermua",
                "countryCode": "ES"
            },
            {
                "name": "Errenteria",
                "countryCode": "ES"
            },
            {
                "name": "Errigoiti",
                "countryCode": "ES"
            },
            {
                "name": "Eskoriatza",
                "countryCode": "ES"
            },
            {
                "name": "Eskuernaga / Villabuena de Álava",
                "countryCode": "ES"
            },
            {
                "name": "Forua",
                "countryCode": "ES"
            },
            {
                "name": "Galdakao",
                "countryCode": "ES"
            },
            {
                "name": "Gasteiz / Vitoria",
                "countryCode": "ES"
            },
            {
                "name": "Gatika",
                "countryCode": "ES"
            },
            {
                "name": "Gaztelu",
                "countryCode": "ES"
            },
            {
                "name": "Gernika-Lumo",
                "countryCode": "ES"
            },
            {
                "name": "Getaria",
                "countryCode": "ES"
            },
            {
                "name": "Getxo",
                "countryCode": "ES"
            },
            {
                "name": "Gipuzkoa",
                "countryCode": "ES"
            },
            {
                "name": "Güeñes",
                "countryCode": "ES"
            },
            {
                "name": "Hernani",
                "countryCode": "ES"
            },
            {
                "name": "Hernialde",
                "countryCode": "ES"
            },
            {
                "name": "Hondarribia",
                "countryCode": "ES"
            },
            {
                "name": "Ibarrangelu",
                "countryCode": "ES"
            },
            {
                "name": "Idiazabal",
                "countryCode": "ES"
            },
            {
                "name": "Irun",
                "countryCode": "ES"
            },
            {
                "name": "Irura",
                "countryCode": "ES"
            },
            {
                "name": "Izurtza",
                "countryCode": "ES"
            },
            {
                "name": "Lagrán",
                "countryCode": "ES"
            },
            {
                "name": "Laguardia",
                "countryCode": "ES"
            },
            {
                "name": "Landa",
                "countryCode": "ES"
            },
            {
                "name": "Lanestosa",
                "countryCode": "ES"
            },
            {
                "name": "Lapuebla de Labarca",
                "countryCode": "ES"
            },
            {
                "name": "Larraul",
                "countryCode": "ES"
            },
            {
                "name": "Lasarte",
                "countryCode": "ES"
            },
            {
                "name": "Laudio / Llodio",
                "countryCode": "ES"
            },
            {
                "name": "Leaburu",
                "countryCode": "ES"
            },
            {
                "name": "Legorreta",
                "countryCode": "ES"
            },
            {
                "name": "Leintz-Gatzaga",
                "countryCode": "ES"
            },
            {
                "name": "Leioa",
                "countryCode": "ES"
            },
            {
                "name": "Lekeitio",
                "countryCode": "ES"
            },
            {
                "name": "Leza",
                "countryCode": "ES"
            },
            {
                "name": "Lezama",
                "countryCode": "ES"
            },
            {
                "name": "Lezo",
                "countryCode": "ES"
            },
            {
                "name": "Lizartza",
                "countryCode": "ES"
            },
            {
                "name": "Markina-Xemein",
                "countryCode": "ES"
            },
            {
                "name": "Mañaria",
                "countryCode": "ES"
            },
            {
                "name": "Mendaro",
                "countryCode": "ES"
            },
            {
                "name": "Mendexa",
                "countryCode": "ES"
            },
            {
                "name": "Moreda Araba / Moreda de Álava",
                "countryCode": "ES"
            },
            {
                "name": "Mundaka",
                "countryCode": "ES"
            },
            {
                "name": "Mungia",
                "countryCode": "ES"
            },
            {
                "name": "Murgia",
                "countryCode": "ES"
            },
            {
                "name": "Mutiloa",
                "countryCode": "ES"
            },
            {
                "name": "Mutriku",
                "countryCode": "ES"
            },
            {
                "name": "Navaridas",
                "countryCode": "ES"
            },
            {
                "name": "Oion / Oyón",
                "countryCode": "ES"
            },
            {
                "name": "Olaberria",
                "countryCode": "ES"
            },
            {
                "name": "Ondarroa",
                "countryCode": "ES"
            },
            {
                "name": "Ordizia",
                "countryCode": "ES"
            },
            {
                "name": "Orendain",
                "countryCode": "ES"
            },
            {
                "name": "Orexa",
                "countryCode": "ES"
            },
            {
                "name": "Oria",
                "countryCode": "ES"
            },
            {
                "name": "Orio",
                "countryCode": "ES"
            },
            {
                "name": "Ormaiztegi",
                "countryCode": "ES"
            },
            {
                "name": "Ortuella",
                "countryCode": "ES"
            },
            {
                "name": "Oñati",
                "countryCode": "ES"
            },
            {
                "name": "Pasaia",
                "countryCode": "ES"
            },
            {
                "name": "Plentzia",
                "countryCode": "ES"
            },
            {
                "name": "Portugalete",
                "countryCode": "ES"
            },
            {
                "name": "Samaniego",
                "countryCode": "ES"
            },
            {
                "name": "Santurtzi",
                "countryCode": "ES"
            },
            {
                "name": "Santutxu",
                "countryCode": "ES"
            },
            {
                "name": "Segura",
                "countryCode": "ES"
            },
            {
                "name": "Sestao",
                "countryCode": "ES"
            },
            {
                "name": "Sondika",
                "countryCode": "ES"
            },
            {
                "name": "Sopela",
                "countryCode": "ES"
            },
            {
                "name": "Sopuerta",
                "countryCode": "ES"
            },
            {
                "name": "Tolosa",
                "countryCode": "ES"
            },
            {
                "name": "Urduña / Orduña",
                "countryCode": "ES"
            },
            {
                "name": "Urnieta",
                "countryCode": "ES"
            },
            {
                "name": "Usurbil",
                "countryCode": "ES"
            },
            {
                "name": "Villabona",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Valdegovía",
                "countryCode": "ES"
            },
            {
                "name": "Zaldibar",
                "countryCode": "ES"
            },
            {
                "name": "Zalla",
                "countryCode": "ES"
            },
            {
                "name": "Zambrana",
                "countryCode": "ES"
            },
            {
                "name": "Zamudio",
                "countryCode": "ES"
            },
            {
                "name": "Zaratamo",
                "countryCode": "ES"
            },
            {
                "name": "Zarautz",
                "countryCode": "ES"
            },
            {
                "name": "Zeanuri",
                "countryCode": "ES"
            },
            {
                "name": "Zegama",
                "countryCode": "ES"
            },
            {
                "name": "Zestoa",
                "countryCode": "ES"
            },
            {
                "name": "Zierbena",
                "countryCode": "ES"
            },
            {
                "name": "Zizurkil",
                "countryCode": "ES"
            },
            {
                "name": "Zumaia",
                "countryCode": "ES"
            },
            {
                "name": "Zumarraga",
                "countryCode": "ES"
            },
            {
                "name": "Agoncillo",
                "countryCode": "ES"
            },
            {
                "name": "Aguilar del Río Alhama",
                "countryCode": "ES"
            },
            {
                "name": "Ajamil",
                "countryCode": "ES"
            },
            {
                "name": "Albelda de Iregua",
                "countryCode": "ES"
            },
            {
                "name": "Alberite",
                "countryCode": "ES"
            },
            {
                "name": "Alcanadre",
                "countryCode": "ES"
            },
            {
                "name": "Aldeanueva de Ebro",
                "countryCode": "ES"
            },
            {
                "name": "Alesanco",
                "countryCode": "ES"
            },
            {
                "name": "Alesón",
                "countryCode": "ES"
            },
            {
                "name": "Alfaro",
                "countryCode": "ES"
            },
            {
                "name": "Almarza de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Anguciana",
                "countryCode": "ES"
            },
            {
                "name": "Anguiano",
                "countryCode": "ES"
            },
            {
                "name": "Arenzana de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Arenzana de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Arnedillo",
                "countryCode": "ES"
            },
            {
                "name": "Arnedo",
                "countryCode": "ES"
            },
            {
                "name": "Arrúbal",
                "countryCode": "ES"
            },
            {
                "name": "Ausejo",
                "countryCode": "ES"
            },
            {
                "name": "Autol",
                "countryCode": "ES"
            },
            {
                "name": "Azofra",
                "countryCode": "ES"
            },
            {
                "name": "Badarán",
                "countryCode": "ES"
            },
            {
                "name": "Bañares",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Baños de Río Tobía",
                "countryCode": "ES"
            },
            {
                "name": "Berceo",
                "countryCode": "ES"
            },
            {
                "name": "Bergasa",
                "countryCode": "ES"
            },
            {
                "name": "Bergasillas Bajera",
                "countryCode": "ES"
            },
            {
                "name": "Bobadilla",
                "countryCode": "ES"
            },
            {
                "name": "Brieva de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Briones",
                "countryCode": "ES"
            },
            {
                "name": "Briñas",
                "countryCode": "ES"
            },
            {
                "name": "Cabezón de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Calahorra",
                "countryCode": "ES"
            },
            {
                "name": "Canales de la Sierra",
                "countryCode": "ES"
            },
            {
                "name": "Canillas de Río Tuerto",
                "countryCode": "ES"
            },
            {
                "name": "Casalarreina",
                "countryCode": "ES"
            },
            {
                "name": "Castañares de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Cañas",
                "countryCode": "ES"
            },
            {
                "name": "Cellorigo",
                "countryCode": "ES"
            },
            {
                "name": "Cenicero",
                "countryCode": "ES"
            },
            {
                "name": "Cervera del Río Alhama",
                "countryCode": "ES"
            },
            {
                "name": "Cidamón",
                "countryCode": "ES"
            },
            {
                "name": "Cihuri",
                "countryCode": "ES"
            },
            {
                "name": "Cirueña",
                "countryCode": "ES"
            },
            {
                "name": "Clavijo",
                "countryCode": "ES"
            },
            {
                "name": "Cordovín",
                "countryCode": "ES"
            },
            {
                "name": "Corera",
                "countryCode": "ES"
            },
            {
                "name": "Cornago",
                "countryCode": "ES"
            },
            {
                "name": "Corporales",
                "countryCode": "ES"
            },
            {
                "name": "Cárdenas",
                "countryCode": "ES"
            },
            {
                "name": "Enciso",
                "countryCode": "ES"
            },
            {
                "name": "Entrena",
                "countryCode": "ES"
            },
            {
                "name": "Estollo",
                "countryCode": "ES"
            },
            {
                "name": "Ezcaray",
                "countryCode": "ES"
            },
            {
                "name": "Foncea",
                "countryCode": "ES"
            },
            {
                "name": "Fonzaleche",
                "countryCode": "ES"
            },
            {
                "name": "Fuenmayor",
                "countryCode": "ES"
            },
            {
                "name": "Galbárruli",
                "countryCode": "ES"
            },
            {
                "name": "Galilea",
                "countryCode": "ES"
            },
            {
                "name": "Gallinero de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Gimileo",
                "countryCode": "ES"
            },
            {
                "name": "Grañón",
                "countryCode": "ES"
            },
            {
                "name": "Grávalos",
                "countryCode": "ES"
            },
            {
                "name": "Haro",
                "countryCode": "ES"
            },
            {
                "name": "Herce",
                "countryCode": "ES"
            },
            {
                "name": "Herramélluri",
                "countryCode": "ES"
            },
            {
                "name": "Hervías",
                "countryCode": "ES"
            },
            {
                "name": "Hormilla",
                "countryCode": "ES"
            },
            {
                "name": "Hormilleja",
                "countryCode": "ES"
            },
            {
                "name": "Hornillos de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Hornos de Moncalvillo",
                "countryCode": "ES"
            },
            {
                "name": "Huércanos",
                "countryCode": "ES"
            },
            {
                "name": "Igea",
                "countryCode": "ES"
            },
            {
                "name": "Jalón de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Laguna de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Lagunilla del Jubera",
                "countryCode": "ES"
            },
            {
                "name": "Lardero",
                "countryCode": "ES"
            },
            {
                "name": "Ledesma de la Cogolla",
                "countryCode": "ES"
            },
            {
                "name": "Leiva",
                "countryCode": "ES"
            },
            {
                "name": "Leza de Río Leza",
                "countryCode": "ES"
            },
            {
                "name": "Logroño",
                "countryCode": "ES"
            },
            {
                "name": "Lumbreras",
                "countryCode": "ES"
            },
            {
                "name": "Manjarrés",
                "countryCode": "ES"
            },
            {
                "name": "Manzanares de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Matute",
                "countryCode": "ES"
            },
            {
                "name": "Medrano",
                "countryCode": "ES"
            },
            {
                "name": "Munilla",
                "countryCode": "ES"
            },
            {
                "name": "Murillo de Río Leza",
                "countryCode": "ES"
            },
            {
                "name": "Muro de Aguas",
                "countryCode": "ES"
            },
            {
                "name": "Muro en Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Nalda",
                "countryCode": "ES"
            },
            {
                "name": "Navajún",
                "countryCode": "ES"
            },
            {
                "name": "Nieva de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Nájera",
                "countryCode": "ES"
            },
            {
                "name": "Ochánduri",
                "countryCode": "ES"
            },
            {
                "name": "Ocón",
                "countryCode": "ES"
            },
            {
                "name": "Ojacastro",
                "countryCode": "ES"
            },
            {
                "name": "Ollauri",
                "countryCode": "ES"
            },
            {
                "name": "Pazuengos",
                "countryCode": "ES"
            },
            {
                "name": "Pedroso",
                "countryCode": "ES"
            },
            {
                "name": "Pinillos",
                "countryCode": "ES"
            },
            {
                "name": "Pradejón",
                "countryCode": "ES"
            },
            {
                "name": "Pradillo",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de La Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Préjano",
                "countryCode": "ES"
            },
            {
                "name": "Quel",
                "countryCode": "ES"
            },
            {
                "name": "Rabanera",
                "countryCode": "ES"
            },
            {
                "name": "Rincón de Soto",
                "countryCode": "ES"
            },
            {
                "name": "Robres del Castillo",
                "countryCode": "ES"
            },
            {
                "name": "Rodezno",
                "countryCode": "ES"
            },
            {
                "name": "Sajazarra",
                "countryCode": "ES"
            },
            {
                "name": "San Asensio",
                "countryCode": "ES"
            },
            {
                "name": "San Millán de Yécora",
                "countryCode": "ES"
            },
            {
                "name": "San Millán de la Cogolla",
                "countryCode": "ES"
            },
            {
                "name": "San Román de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "San Torcuato",
                "countryCode": "ES"
            },
            {
                "name": "San Vicente de la Sonsierra",
                "countryCode": "ES"
            },
            {
                "name": "Santa Coloma",
                "countryCode": "ES"
            },
            {
                "name": "Santa Eulalia Bajera",
                "countryCode": "ES"
            },
            {
                "name": "Santo Domingo de la Calzada",
                "countryCode": "ES"
            },
            {
                "name": "Santurdejo",
                "countryCode": "ES"
            },
            {
                "name": "Sojuela",
                "countryCode": "ES"
            },
            {
                "name": "Sorzano",
                "countryCode": "ES"
            },
            {
                "name": "Soto en Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Terroba",
                "countryCode": "ES"
            },
            {
                "name": "Tirgo",
                "countryCode": "ES"
            },
            {
                "name": "Tobía",
                "countryCode": "ES"
            },
            {
                "name": "Tormantos",
                "countryCode": "ES"
            },
            {
                "name": "Torre en Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla en Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Torrecilla sobre Alesanco",
                "countryCode": "ES"
            },
            {
                "name": "Torremontalbo",
                "countryCode": "ES"
            },
            {
                "name": "Treviana",
                "countryCode": "ES"
            },
            {
                "name": "Tricio",
                "countryCode": "ES"
            },
            {
                "name": "Tudelilla",
                "countryCode": "ES"
            },
            {
                "name": "Uruñuela",
                "countryCode": "ES"
            },
            {
                "name": "Valdemadera",
                "countryCode": "ES"
            },
            {
                "name": "Valgañón",
                "countryCode": "ES"
            },
            {
                "name": "Ventosa",
                "countryCode": "ES"
            },
            {
                "name": "Ventrosa",
                "countryCode": "ES"
            },
            {
                "name": "Viguera",
                "countryCode": "ES"
            },
            {
                "name": "Villalba de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Villalobar de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Villamediana de Iregua",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Villar de Torre",
                "countryCode": "ES"
            },
            {
                "name": "Villarejo",
                "countryCode": "ES"
            },
            {
                "name": "Villarroya",
                "countryCode": "ES"
            },
            {
                "name": "Villarta-Quintana",
                "countryCode": "ES"
            },
            {
                "name": "Villavelayo",
                "countryCode": "ES"
            },
            {
                "name": "Villaverde de Rioja",
                "countryCode": "ES"
            },
            {
                "name": "Villoslada de Cameros",
                "countryCode": "ES"
            },
            {
                "name": "Viniegra de Abajo",
                "countryCode": "ES"
            },
            {
                "name": "Viniegra de Arriba",
                "countryCode": "ES"
            },
            {
                "name": "Zarratón",
                "countryCode": "ES"
            },
            {
                "name": "Zarzosa",
                "countryCode": "ES"
            },
            {
                "name": "Zorraquín",
                "countryCode": "ES"
            },
            {
                "name": "Ábalos",
                "countryCode": "ES"
            },
            {
                "name": "Ademuz",
                "countryCode": "ES"
            },
            {
                "name": "Ador",
                "countryCode": "ES"
            },
            {
                "name": "Adsubia",
                "countryCode": "ES"
            },
            {
                "name": "Adzaneta",
                "countryCode": "ES"
            },
            {
                "name": "Agost",
                "countryCode": "ES"
            },
            {
                "name": "Agres",
                "countryCode": "ES"
            },
            {
                "name": "Agullent",
                "countryCode": "ES"
            },
            {
                "name": "Aielo de Malferit",
                "countryCode": "ES"
            },
            {
                "name": "Alaquàs",
                "countryCode": "ES"
            },
            {
                "name": "Albaida",
                "countryCode": "ES"
            },
            {
                "name": "Albal",
                "countryCode": "ES"
            },
            {
                "name": "Albalat de la Ribera",
                "countryCode": "ES"
            },
            {
                "name": "Albalat dels Sorells",
                "countryCode": "ES"
            },
            {
                "name": "Albalat dels Tarongers",
                "countryCode": "ES"
            },
            {
                "name": "Albatera",
                "countryCode": "ES"
            },
            {
                "name": "Alberic",
                "countryCode": "ES"
            },
            {
                "name": "Albocàsser",
                "countryCode": "ES"
            },
            {
                "name": "Alborache",
                "countryCode": "ES"
            },
            {
                "name": "Alboraya",
                "countryCode": "ES"
            },
            {
                "name": "Albuixech",
                "countryCode": "ES"
            },
            {
                "name": "Alcalalí",
                "countryCode": "ES"
            },
            {
                "name": "Alcalà de Xivert",
                "countryCode": "ES"
            },
            {
                "name": "Alcocer de Planes",
                "countryCode": "ES"
            },
            {
                "name": "Alcocéber",
                "countryCode": "ES"
            },
            {
                "name": "Alcoy",
                "countryCode": "ES"
            },
            {
                "name": "Alcublas",
                "countryCode": "ES"
            },
            {
                "name": "Alcudia de Veo",
                "countryCode": "ES"
            },
            {
                "name": "Alcàntera de Xúquer",
                "countryCode": "ES"
            },
            {
                "name": "Alcàsser",
                "countryCode": "ES"
            },
            {
                "name": "Aldaia",
                "countryCode": "ES"
            },
            {
                "name": "Alfafar",
                "countryCode": "ES"
            },
            {
                "name": "Alfafara",
                "countryCode": "ES"
            },
            {
                "name": "Alfara de Algimia",
                "countryCode": "ES"
            },
            {
                "name": "Alfara del Patriarca",
                "countryCode": "ES"
            },
            {
                "name": "Alfarp",
                "countryCode": "ES"
            },
            {
                "name": "Alfarrasí",
                "countryCode": "ES"
            },
            {
                "name": "Alfauir",
                "countryCode": "ES"
            },
            {
                "name": "Alfondeguilla",
                "countryCode": "ES"
            },
            {
                "name": "Algemesí",
                "countryCode": "ES"
            },
            {
                "name": "Algimia de Alfara",
                "countryCode": "ES"
            },
            {
                "name": "Algimia de Almonacid",
                "countryCode": "ES"
            },
            {
                "name": "Alginet",
                "countryCode": "ES"
            },
            {
                "name": "Algorfa",
                "countryCode": "ES"
            },
            {
                "name": "Algueña",
                "countryCode": "ES"
            },
            {
                "name": "Alicante",
                "countryCode": "ES"
            },
            {
                "name": "Almassora",
                "countryCode": "ES"
            },
            {
                "name": "Almedíjar",
                "countryCode": "ES"
            },
            {
                "name": "Almenara",
                "countryCode": "ES"
            },
            {
                "name": "Almiserà",
                "countryCode": "ES"
            },
            {
                "name": "Almoines",
                "countryCode": "ES"
            },
            {
                "name": "Almoradí",
                "countryCode": "ES"
            },
            {
                "name": "Almudaina",
                "countryCode": "ES"
            },
            {
                "name": "Almussafes",
                "countryCode": "ES"
            },
            {
                "name": "Alpuente",
                "countryCode": "ES"
            },
            {
                "name": "Alquerías del Niño Perdido",
                "countryCode": "ES"
            },
            {
                "name": "Altea",
                "countryCode": "ES"
            },
            {
                "name": "Altura",
                "countryCode": "ES"
            },
            {
                "name": "Alzira",
                "countryCode": "ES"
            },
            {
                "name": "Andilla",
                "countryCode": "ES"
            },
            {
                "name": "Anna",
                "countryCode": "ES"
            },
            {
                "name": "Antella",
                "countryCode": "ES"
            },
            {
                "name": "Arañuel",
                "countryCode": "ES"
            },
            {
                "name": "Ares del Maestre",
                "countryCode": "ES"
            },
            {
                "name": "Argelita",
                "countryCode": "ES"
            },
            {
                "name": "Artana",
                "countryCode": "ES"
            },
            {
                "name": "Aspe",
                "countryCode": "ES"
            },
            {
                "name": "Atzeneta d'Albaida",
                "countryCode": "ES"
            },
            {
                "name": "Ayora",
                "countryCode": "ES"
            },
            {
                "name": "Ayódar",
                "countryCode": "ES"
            },
            {
                "name": "Azuébar",
                "countryCode": "ES"
            },
            {
                "name": "Balones",
                "countryCode": "ES"
            },
            {
                "name": "Barracas",
                "countryCode": "ES"
            },
            {
                "name": "Barx",
                "countryCode": "ES"
            },
            {
                "name": "Barxeta",
                "countryCode": "ES"
            },
            {
                "name": "Bellreguard",
                "countryCode": "ES"
            },
            {
                "name": "Bellús",
                "countryCode": "ES"
            },
            {
                "name": "Benafigos",
                "countryCode": "ES"
            },
            {
                "name": "Benaguasil",
                "countryCode": "ES"
            },
            {
                "name": "Benagéber",
                "countryCode": "ES"
            },
            {
                "name": "Benasau",
                "countryCode": "ES"
            },
            {
                "name": "Benassal",
                "countryCode": "ES"
            },
            {
                "name": "Benavites",
                "countryCode": "ES"
            },
            {
                "name": "Beneixama",
                "countryCode": "ES"
            },
            {
                "name": "Beneixida",
                "countryCode": "ES"
            },
            {
                "name": "Benejúzar",
                "countryCode": "ES"
            },
            {
                "name": "Benetússer",
                "countryCode": "ES"
            },
            {
                "name": "Benferri",
                "countryCode": "ES"
            },
            {
                "name": "Beniarbeig",
                "countryCode": "ES"
            },
            {
                "name": "Beniardá",
                "countryCode": "ES"
            },
            {
                "name": "Beniarjó",
                "countryCode": "ES"
            },
            {
                "name": "Beniarrés",
                "countryCode": "ES"
            },
            {
                "name": "Beniatjar",
                "countryCode": "ES"
            },
            {
                "name": "Benicarló",
                "countryCode": "ES"
            },
            {
                "name": "Benicolet",
                "countryCode": "ES"
            },
            {
                "name": "Benicàssim",
                "countryCode": "ES"
            },
            {
                "name": "Benidoleig",
                "countryCode": "ES"
            },
            {
                "name": "Benidorm",
                "countryCode": "ES"
            },
            {
                "name": "Benifairó de les Valls",
                "countryCode": "ES"
            },
            {
                "name": "Benifaió",
                "countryCode": "ES"
            },
            {
                "name": "Benifallim",
                "countryCode": "ES"
            },
            {
                "name": "Beniflá",
                "countryCode": "ES"
            },
            {
                "name": "Benigànim",
                "countryCode": "ES"
            },
            {
                "name": "Benijofar",
                "countryCode": "ES"
            },
            {
                "name": "Benilloba",
                "countryCode": "ES"
            },
            {
                "name": "Benillup",
                "countryCode": "ES"
            },
            {
                "name": "Benimantell",
                "countryCode": "ES"
            },
            {
                "name": "Benimarfull",
                "countryCode": "ES"
            },
            {
                "name": "Benimassot",
                "countryCode": "ES"
            },
            {
                "name": "Benimeli",
                "countryCode": "ES"
            },
            {
                "name": "Benimodo",
                "countryCode": "ES"
            },
            {
                "name": "Benimuslem",
                "countryCode": "ES"
            },
            {
                "name": "Beniparrell",
                "countryCode": "ES"
            },
            {
                "name": "Benirredrà",
                "countryCode": "ES"
            },
            {
                "name": "Benisanó",
                "countryCode": "ES"
            },
            {
                "name": "Benissa",
                "countryCode": "ES"
            },
            {
                "name": "Benissoda",
                "countryCode": "ES"
            },
            {
                "name": "Benisuera",
                "countryCode": "ES"
            },
            {
                "name": "Benitachell",
                "countryCode": "ES"
            },
            {
                "name": "Benlloch",
                "countryCode": "ES"
            },
            {
                "name": "Betxí",
                "countryCode": "ES"
            },
            {
                "name": "Biar",
                "countryCode": "ES"
            },
            {
                "name": "Bicorp",
                "countryCode": "ES"
            },
            {
                "name": "Bigastro",
                "countryCode": "ES"
            },
            {
                "name": "Bocairent",
                "countryCode": "ES"
            },
            {
                "name": "Bolbaite",
                "countryCode": "ES"
            },
            {
                "name": "Bolulla",
                "countryCode": "ES"
            },
            {
                "name": "Borriol",
                "countryCode": "ES"
            },
            {
                "name": "Bufali",
                "countryCode": "ES"
            },
            {
                "name": "Bugarra",
                "countryCode": "ES"
            },
            {
                "name": "Burjassot",
                "countryCode": "ES"
            },
            {
                "name": "Burriana",
                "countryCode": "ES"
            },
            {
                "name": "Busot",
                "countryCode": "ES"
            },
            {
                "name": "Buñol",
                "countryCode": "ES"
            },
            {
                "name": "Bèlgida",
                "countryCode": "ES"
            },
            {
                "name": "Bétera",
                "countryCode": "ES"
            },
            {
                "name": "Cabanes",
                "countryCode": "ES"
            },
            {
                "name": "Calles",
                "countryCode": "ES"
            },
            {
                "name": "Callosa de Segura",
                "countryCode": "ES"
            },
            {
                "name": "Calp",
                "countryCode": "ES"
            },
            {
                "name": "Camporrobles",
                "countryCode": "ES"
            },
            {
                "name": "Canals",
                "countryCode": "ES"
            },
            {
                "name": "Canet d'En Berenguer",
                "countryCode": "ES"
            },
            {
                "name": "Canet lo Roig",
                "countryCode": "ES"
            },
            {
                "name": "Carcaixent",
                "countryCode": "ES"
            },
            {
                "name": "Carlet",
                "countryCode": "ES"
            },
            {
                "name": "Carrícola",
                "countryCode": "ES"
            },
            {
                "name": "Casas Altas",
                "countryCode": "ES"
            },
            {
                "name": "Casas Bajas",
                "countryCode": "ES"
            },
            {
                "name": "Casinos",
                "countryCode": "ES"
            },
            {
                "name": "Castalla",
                "countryCode": "ES"
            },
            {
                "name": "Castell de Cabres",
                "countryCode": "ES"
            },
            {
                "name": "Castell de Castells",
                "countryCode": "ES"
            },
            {
                "name": "Castellfort",
                "countryCode": "ES"
            },
            {
                "name": "Castellnovo",
                "countryCode": "ES"
            },
            {
                "name": "Castellonet de la Conquesta",
                "countryCode": "ES"
            },
            {
                "name": "Castelló de Rugat",
                "countryCode": "ES"
            },
            {
                "name": "Castelló de la Plana",
                "countryCode": "ES"
            },
            {
                "name": "Castielfabib",
                "countryCode": "ES"
            },
            {
                "name": "Castillo de Villamalefa",
                "countryCode": "ES"
            },
            {
                "name": "Catadau",
                "countryCode": "ES"
            },
            {
                "name": "Catarroja",
                "countryCode": "ES"
            },
            {
                "name": "Catral",
                "countryCode": "ES"
            },
            {
                "name": "Catí",
                "countryCode": "ES"
            },
            {
                "name": "Caudete de las Fuentes",
                "countryCode": "ES"
            },
            {
                "name": "Cerdà",
                "countryCode": "ES"
            },
            {
                "name": "Cervera del Maestre",
                "countryCode": "ES"
            },
            {
                "name": "Chella",
                "countryCode": "ES"
            },
            {
                "name": "Chelva",
                "countryCode": "ES"
            },
            {
                "name": "Chera",
                "countryCode": "ES"
            },
            {
                "name": "Chert/Xert",
                "countryCode": "ES"
            },
            {
                "name": "Cheste",
                "countryCode": "ES"
            },
            {
                "name": "Chilches",
                "countryCode": "ES"
            },
            {
                "name": "Chiva",
                "countryCode": "ES"
            },
            {
                "name": "Chulilla",
                "countryCode": "ES"
            },
            {
                "name": "Chóvar",
                "countryCode": "ES"
            },
            {
                "name": "Cinctorres",
                "countryCode": "ES"
            },
            {
                "name": "Cirat",
                "countryCode": "ES"
            },
            {
                "name": "Cocentaina",
                "countryCode": "ES"
            },
            {
                "name": "Cofrentes",
                "countryCode": "ES"
            },
            {
                "name": "Confrides",
                "countryCode": "ES"
            },
            {
                "name": "Corbera",
                "countryCode": "ES"
            },
            {
                "name": "Cortes de Arenoso",
                "countryCode": "ES"
            },
            {
                "name": "Cortes de Pallás",
                "countryCode": "ES"
            },
            {
                "name": "Costur",
                "countryCode": "ES"
            },
            {
                "name": "Cotes",
                "countryCode": "ES"
            },
            {
                "name": "Cox",
                "countryCode": "ES"
            },
            {
                "name": "Crevillente",
                "countryCode": "ES"
            },
            {
                "name": "Cuevas de Vinromá",
                "countryCode": "ES"
            },
            {
                "name": "Culla",
                "countryCode": "ES"
            },
            {
                "name": "Cullera",
                "countryCode": "ES"
            },
            {
                "name": "Càlig",
                "countryCode": "ES"
            },
            {
                "name": "Càrcer",
                "countryCode": "ES"
            },
            {
                "name": "Daimús",
                "countryCode": "ES"
            },
            {
                "name": "Daya Nueva",
                "countryCode": "ES"
            },
            {
                "name": "Daya Vieja",
                "countryCode": "ES"
            },
            {
                "name": "Denia",
                "countryCode": "ES"
            },
            {
                "name": "Dolores",
                "countryCode": "ES"
            },
            {
                "name": "Domeño",
                "countryCode": "ES"
            },
            {
                "name": "Dos Aguas",
                "countryCode": "ES"
            },
            {
                "name": "El Campello",
                "countryCode": "ES"
            },
            {
                "name": "El Grao",
                "countryCode": "ES"
            },
            {
                "name": "El Perelló",
                "countryCode": "ES"
            },
            {
                "name": "Elche",
                "countryCode": "ES"
            },
            {
                "name": "Elda",
                "countryCode": "ES"
            },
            {
                "name": "Emperador",
                "countryCode": "ES"
            },
            {
                "name": "Enguera",
                "countryCode": "ES"
            },
            {
                "name": "Eslida",
                "countryCode": "ES"
            },
            {
                "name": "Espadilla",
                "countryCode": "ES"
            },
            {
                "name": "Estivella",
                "countryCode": "ES"
            },
            {
                "name": "Estubeny",
                "countryCode": "ES"
            },
            {
                "name": "Facheca",
                "countryCode": "ES"
            },
            {
                "name": "Famorca",
                "countryCode": "ES"
            },
            {
                "name": "Fanzara",
                "countryCode": "ES"
            },
            {
                "name": "Faura",
                "countryCode": "ES"
            },
            {
                "name": "Favara",
                "countryCode": "ES"
            },
            {
                "name": "Figueroles",
                "countryCode": "ES"
            },
            {
                "name": "Finestrat",
                "countryCode": "ES"
            },
            {
                "name": "Foios",
                "countryCode": "ES"
            },
            {
                "name": "Fontanars dels Alforins",
                "countryCode": "ES"
            },
            {
                "name": "Forcall",
                "countryCode": "ES"
            },
            {
                "name": "Formentera de Segura",
                "countryCode": "ES"
            },
            {
                "name": "Fortaleny",
                "countryCode": "ES"
            },
            {
                "name": "Fuente la Reina",
                "countryCode": "ES"
            },
            {
                "name": "Fuenterrobles",
                "countryCode": "ES"
            },
            {
                "name": "Fuentes de Ayódar",
                "countryCode": "ES"
            },
            {
                "name": "Gaibiel",
                "countryCode": "ES"
            },
            {
                "name": "Gandia",
                "countryCode": "ES"
            },
            {
                "name": "Gata de Gorgos",
                "countryCode": "ES"
            },
            {
                "name": "Gavarda",
                "countryCode": "ES"
            },
            {
                "name": "Geldo",
                "countryCode": "ES"
            },
            {
                "name": "Genovés",
                "countryCode": "ES"
            },
            {
                "name": "Gestalgar",
                "countryCode": "ES"
            },
            {
                "name": "Gilet",
                "countryCode": "ES"
            },
            {
                "name": "Godella",
                "countryCode": "ES"
            },
            {
                "name": "Godelleta",
                "countryCode": "ES"
            },
            {
                "name": "Gorga",
                "countryCode": "ES"
            },
            {
                "name": "Granja de Rocamora",
                "countryCode": "ES"
            },
            {
                "name": "Grao de Murviedro",
                "countryCode": "ES"
            },
            {
                "name": "Guadasequies",
                "countryCode": "ES"
            },
            {
                "name": "Guadassuar",
                "countryCode": "ES"
            },
            {
                "name": "Guardamar del Segura",
                "countryCode": "ES"
            },
            {
                "name": "Herbés",
                "countryCode": "ES"
            },
            {
                "name": "Higueras",
                "countryCode": "ES"
            },
            {
                "name": "Higueruelas",
                "countryCode": "ES"
            },
            {
                "name": "Hondón de las Nieves",
                "countryCode": "ES"
            },
            {
                "name": "Hondón de los Frailes",
                "countryCode": "ES"
            },
            {
                "name": "Ibi",
                "countryCode": "ES"
            },
            {
                "name": "Jacarilla",
                "countryCode": "ES"
            },
            {
                "name": "Jalance",
                "countryCode": "ES"
            },
            {
                "name": "Jalón",
                "countryCode": "ES"
            },
            {
                "name": "Jarafuel",
                "countryCode": "ES"
            },
            {
                "name": "Javea",
                "countryCode": "ES"
            },
            {
                "name": "Jijona",
                "countryCode": "ES"
            },
            {
                "name": "Jérica",
                "countryCode": "ES"
            },
            {
                "name": "L'Alcúdia",
                "countryCode": "ES"
            },
            {
                "name": "L'Alcúdia de Crespìns",
                "countryCode": "ES"
            },
            {
                "name": "L'Alqueria de la Comtessa",
                "countryCode": "ES"
            },
            {
                "name": "L'Eliana",
                "countryCode": "ES"
            },
            {
                "name": "L'Olleria",
                "countryCode": "ES"
            },
            {
                "name": "La Font de la Figuera",
                "countryCode": "ES"
            },
            {
                "name": "La Pobla Llarga",
                "countryCode": "ES"
            },
            {
                "name": "La Pobla de Farnals",
                "countryCode": "ES"
            },
            {
                "name": "La Pobla de Vallbona",
                "countryCode": "ES"
            },
            {
                "name": "La Romana",
                "countryCode": "ES"
            },
            {
                "name": "La Yesa",
                "countryCode": "ES"
            },
            {
                "name": "Llanera de Ranes",
                "countryCode": "ES"
            },
            {
                "name": "Llaurí",
                "countryCode": "ES"
            },
            {
                "name": "Llocnou de Sant Jeroni",
                "countryCode": "ES"
            },
            {
                "name": "Llombai",
                "countryCode": "ES"
            },
            {
                "name": "Llosa de Ranes",
                "countryCode": "ES"
            },
            {
                "name": "Llutxent",
                "countryCode": "ES"
            },
            {
                "name": "Llíria",
                "countryCode": "ES"
            },
            {
                "name": "Loriguilla",
                "countryCode": "ES"
            },
            {
                "name": "Los Montesinos",
                "countryCode": "ES"
            },
            {
                "name": "Losa del Obispo",
                "countryCode": "ES"
            },
            {
                "name": "Lucena del Cid",
                "countryCode": "ES"
            },
            {
                "name": "Ludiente",
                "countryCode": "ES"
            },
            {
                "name": "Macastre",
                "countryCode": "ES"
            },
            {
                "name": "Manises",
                "countryCode": "ES"
            },
            {
                "name": "Manuel",
                "countryCode": "ES"
            },
            {
                "name": "Marines",
                "countryCode": "ES"
            },
            {
                "name": "Masalavés",
                "countryCode": "ES"
            },
            {
                "name": "Massamagrell",
                "countryCode": "ES"
            },
            {
                "name": "Matet",
                "countryCode": "ES"
            },
            {
                "name": "Meliana",
                "countryCode": "ES"
            },
            {
                "name": "Millares",
                "countryCode": "ES"
            },
            {
                "name": "Millena",
                "countryCode": "ES"
            },
            {
                "name": "Miramar",
                "countryCode": "ES"
            },
            {
                "name": "Mislata",
                "countryCode": "ES"
            },
            {
                "name": "Mogente",
                "countryCode": "ES"
            },
            {
                "name": "Moncada",
                "countryCode": "ES"
            },
            {
                "name": "Moncofa",
                "countryCode": "ES"
            },
            {
                "name": "Monforte del Cid",
                "countryCode": "ES"
            },
            {
                "name": "Monserrat",
                "countryCode": "ES"
            },
            {
                "name": "Montaberner",
                "countryCode": "ES"
            },
            {
                "name": "Montanejos",
                "countryCode": "ES"
            },
            {
                "name": "Montesa",
                "countryCode": "ES"
            },
            {
                "name": "Montichelvo",
                "countryCode": "ES"
            },
            {
                "name": "Montroy",
                "countryCode": "ES"
            },
            {
                "name": "Montán",
                "countryCode": "ES"
            },
            {
                "name": "Monóvar",
                "countryCode": "ES"
            },
            {
                "name": "Moraira",
                "countryCode": "ES"
            },
            {
                "name": "Morella",
                "countryCode": "ES"
            },
            {
                "name": "Murla",
                "countryCode": "ES"
            },
            {
                "name": "Muro del Alcoy",
                "countryCode": "ES"
            },
            {
                "name": "Museros",
                "countryCode": "ES"
            },
            {
                "name": "Mutxamel",
                "countryCode": "ES"
            },
            {
                "name": "Navajas",
                "countryCode": "ES"
            },
            {
                "name": "Navarrés",
                "countryCode": "ES"
            },
            {
                "name": "Novelda",
                "countryCode": "ES"
            },
            {
                "name": "Nules",
                "countryCode": "ES"
            },
            {
                "name": "Náquera",
                "countryCode": "ES"
            },
            {
                "name": "Oliva",
                "countryCode": "ES"
            },
            {
                "name": "Olocau",
                "countryCode": "ES"
            },
            {
                "name": "Olocau del Rey",
                "countryCode": "ES"
            },
            {
                "name": "Onda",
                "countryCode": "ES"
            },
            {
                "name": "Ondara",
                "countryCode": "ES"
            },
            {
                "name": "Onil",
                "countryCode": "ES"
            },
            {
                "name": "Ontinyent",
                "countryCode": "ES"
            },
            {
                "name": "Orba",
                "countryCode": "ES"
            },
            {
                "name": "Orcheta",
                "countryCode": "ES"
            },
            {
                "name": "Orihuela",
                "countryCode": "ES"
            },
            {
                "name": "Oropesa del Mar",
                "countryCode": "ES"
            },
            {
                "name": "Otos",
                "countryCode": "ES"
            },
            {
                "name": "Paiporta",
                "countryCode": "ES"
            },
            {
                "name": "Palanques",
                "countryCode": "ES"
            },
            {
                "name": "Palma de Gandía",
                "countryCode": "ES"
            },
            {
                "name": "Palmera",
                "countryCode": "ES"
            },
            {
                "name": "Parcent",
                "countryCode": "ES"
            },
            {
                "name": "Paterna",
                "countryCode": "ES"
            },
            {
                "name": "Pavías",
                "countryCode": "ES"
            },
            {
                "name": "Pedralba",
                "countryCode": "ES"
            },
            {
                "name": "Pedreguer",
                "countryCode": "ES"
            },
            {
                "name": "Pego",
                "countryCode": "ES"
            },
            {
                "name": "Peníscola",
                "countryCode": "ES"
            },
            {
                "name": "Petrés",
                "countryCode": "ES"
            },
            {
                "name": "Picanya",
                "countryCode": "ES"
            },
            {
                "name": "Picassent",
                "countryCode": "ES"
            },
            {
                "name": "Pilar de la Horadada",
                "countryCode": "ES"
            },
            {
                "name": "Piles",
                "countryCode": "ES"
            },
            {
                "name": "Pina de Montalgrao",
                "countryCode": "ES"
            },
            {
                "name": "Pinet",
                "countryCode": "ES"
            },
            {
                "name": "Pinoso",
                "countryCode": "ES"
            },
            {
                "name": "Planes",
                "countryCode": "ES"
            },
            {
                "name": "Polinyà de Xúquer",
                "countryCode": "ES"
            },
            {
                "name": "Polop",
                "countryCode": "ES"
            },
            {
                "name": "Portell de Morella",
                "countryCode": "ES"
            },
            {
                "name": "Potríes",
                "countryCode": "ES"
            },
            {
                "name": "Provincia de Alicante",
                "countryCode": "ES"
            },
            {
                "name": "Província de Castelló",
                "countryCode": "ES"
            },
            {
                "name": "Província de València",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de Arenoso",
                "countryCode": "ES"
            },
            {
                "name": "Puebla de San Miguel",
                "countryCode": "ES"
            },
            {
                "name": "Puig",
                "countryCode": "ES"
            },
            {
                "name": "Puçol",
                "countryCode": "ES"
            },
            {
                "name": "Quart de Poblet",
                "countryCode": "ES"
            },
            {
                "name": "Quart de les Valls",
                "countryCode": "ES"
            },
            {
                "name": "Quartell",
                "countryCode": "ES"
            },
            {
                "name": "Quatretonda",
                "countryCode": "ES"
            },
            {
                "name": "Quesa",
                "countryCode": "ES"
            },
            {
                "name": "Rafal",
                "countryCode": "ES"
            },
            {
                "name": "Rafelcofer",
                "countryCode": "ES"
            },
            {
                "name": "Rafelguaraf",
                "countryCode": "ES"
            },
            {
                "name": "Real de Gandía",
                "countryCode": "ES"
            },
            {
                "name": "Real de Montroi",
                "countryCode": "ES"
            },
            {
                "name": "Redován",
                "countryCode": "ES"
            },
            {
                "name": "Relleu",
                "countryCode": "ES"
            },
            {
                "name": "Requena",
                "countryCode": "ES"
            },
            {
                "name": "Ribarroja del Turia",
                "countryCode": "ES"
            },
            {
                "name": "Ribesalbes",
                "countryCode": "ES"
            },
            {
                "name": "Riola",
                "countryCode": "ES"
            },
            {
                "name": "Rocafort",
                "countryCode": "ES"
            },
            {
                "name": "Rojales",
                "countryCode": "ES"
            },
            {
                "name": "Rosell",
                "countryCode": "ES"
            },
            {
                "name": "Rotglá y Corbera",
                "countryCode": "ES"
            },
            {
                "name": "Rugat",
                "countryCode": "ES"
            },
            {
                "name": "Ráfol de Salem",
                "countryCode": "ES"
            },
            {
                "name": "Rótova",
                "countryCode": "ES"
            },
            {
                "name": "Sacañet",
                "countryCode": "ES"
            },
            {
                "name": "Sagra",
                "countryCode": "ES"
            },
            {
                "name": "Sagunto",
                "countryCode": "ES"
            },
            {
                "name": "Salinas",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de Alicante",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de Moró",
                "countryCode": "ES"
            },
            {
                "name": "San Juan de Énova",
                "countryCode": "ES"
            },
            {
                "name": "San Miguel de Salinas",
                "countryCode": "ES"
            },
            {
                "name": "San Vicent del Raspeig",
                "countryCode": "ES"
            },
            {
                "name": "Sanet y Negrals",
                "countryCode": "ES"
            },
            {
                "name": "Sant Jordi",
                "countryCode": "ES"
            },
            {
                "name": "Santa Magdalena de Pulpis",
                "countryCode": "ES"
            },
            {
                "name": "Santa Pola",
                "countryCode": "ES"
            },
            {
                "name": "Sarratella",
                "countryCode": "ES"
            },
            {
                "name": "Sax",
                "countryCode": "ES"
            },
            {
                "name": "Sedaví",
                "countryCode": "ES"
            },
            {
                "name": "Segart",
                "countryCode": "ES"
            },
            {
                "name": "Segorbe",
                "countryCode": "ES"
            },
            {
                "name": "Sella",
                "countryCode": "ES"
            },
            {
                "name": "Sellent",
                "countryCode": "ES"
            },
            {
                "name": "Sempere",
                "countryCode": "ES"
            },
            {
                "name": "Senija",
                "countryCode": "ES"
            },
            {
                "name": "Senyera",
                "countryCode": "ES"
            },
            {
                "name": "Serra",
                "countryCode": "ES"
            },
            {
                "name": "Sierra-Engarcerán",
                "countryCode": "ES"
            },
            {
                "name": "Siete Aguas",
                "countryCode": "ES"
            },
            {
                "name": "Silla",
                "countryCode": "ES"
            },
            {
                "name": "Simat de la Valldigna",
                "countryCode": "ES"
            },
            {
                "name": "Sinarcas",
                "countryCode": "ES"
            },
            {
                "name": "Sollana",
                "countryCode": "ES"
            },
            {
                "name": "Soneja",
                "countryCode": "ES"
            },
            {
                "name": "Sot de Chera",
                "countryCode": "ES"
            },
            {
                "name": "Sot de Ferrer",
                "countryCode": "ES"
            },
            {
                "name": "Sueca",
                "countryCode": "ES"
            },
            {
                "name": "Sumacàrcer",
                "countryCode": "ES"
            },
            {
                "name": "Tales",
                "countryCode": "ES"
            },
            {
                "name": "Tavernes Blanques",
                "countryCode": "ES"
            },
            {
                "name": "Tavernes de la Valldigna",
                "countryCode": "ES"
            },
            {
                "name": "Terrateig",
                "countryCode": "ES"
            },
            {
                "name": "Teulada",
                "countryCode": "ES"
            },
            {
                "name": "Tibi",
                "countryCode": "ES"
            },
            {
                "name": "Titaguas",
                "countryCode": "ES"
            },
            {
                "name": "Todolella",
                "countryCode": "ES"
            },
            {
                "name": "Toga",
                "countryCode": "ES"
            },
            {
                "name": "Tollos",
                "countryCode": "ES"
            },
            {
                "name": "Tormos",
                "countryCode": "ES"
            },
            {
                "name": "Torralba del Pinar",
                "countryCode": "ES"
            },
            {
                "name": "Torre de la Horadada",
                "countryCode": "ES"
            },
            {
                "name": "Torreblanca",
                "countryCode": "ES"
            },
            {
                "name": "Torrechiva",
                "countryCode": "ES"
            },
            {
                "name": "Torrella",
                "countryCode": "ES"
            },
            {
                "name": "Torrent",
                "countryCode": "ES"
            },
            {
                "name": "Torrevieja",
                "countryCode": "ES"
            },
            {
                "name": "Torás",
                "countryCode": "ES"
            },
            {
                "name": "Tous",
                "countryCode": "ES"
            },
            {
                "name": "Traiguera",
                "countryCode": "ES"
            },
            {
                "name": "Turís",
                "countryCode": "ES"
            },
            {
                "name": "Tuéjar",
                "countryCode": "ES"
            },
            {
                "name": "Utiel",
                "countryCode": "ES"
            },
            {
                "name": "Valencia",
                "countryCode": "ES"
            },
            {
                "name": "Vall de Almonacid",
                "countryCode": "ES"
            },
            {
                "name": "Vall de Ebo",
                "countryCode": "ES"
            },
            {
                "name": "Vallada",
                "countryCode": "ES"
            },
            {
                "name": "Vallanca",
                "countryCode": "ES"
            },
            {
                "name": "Vallibona",
                "countryCode": "ES"
            },
            {
                "name": "Vallés",
                "countryCode": "ES"
            },
            {
                "name": "Venta del Moro",
                "countryCode": "ES"
            },
            {
                "name": "Vergel",
                "countryCode": "ES"
            },
            {
                "name": "Vila-real",
                "countryCode": "ES"
            },
            {
                "name": "Vilamarxant",
                "countryCode": "ES"
            },
            {
                "name": "Villafamés",
                "countryCode": "ES"
            },
            {
                "name": "Villafranca del Cid",
                "countryCode": "ES"
            },
            {
                "name": "Villahermosa del Río",
                "countryCode": "ES"
            },
            {
                "name": "Villajoyosa",
                "countryCode": "ES"
            },
            {
                "name": "Villalonga",
                "countryCode": "ES"
            },
            {
                "name": "Villamalur",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Castellón",
                "countryCode": "ES"
            },
            {
                "name": "Villanueva de Viver",
                "countryCode": "ES"
            },
            {
                "name": "Villar del Arzobispo",
                "countryCode": "ES"
            },
            {
                "name": "Villargordo del Cabriel",
                "countryCode": "ES"
            },
            {
                "name": "Villavieja",
                "countryCode": "ES"
            },
            {
                "name": "Villena",
                "countryCode": "ES"
            },
            {
                "name": "Villores",
                "countryCode": "ES"
            },
            {
                "name": "Vinalesa",
                "countryCode": "ES"
            },
            {
                "name": "Vinaròs",
                "countryCode": "ES"
            },
            {
                "name": "Vistabella del Maestrazgo",
                "countryCode": "ES"
            },
            {
                "name": "Viver",
                "countryCode": "ES"
            },
            {
                "name": "Xeraco,Jaraco",
                "countryCode": "ES"
            },
            {
                "name": "Xeresa",
                "countryCode": "ES"
            },
            {
                "name": "Xirivella",
                "countryCode": "ES"
            },
            {
                "name": "Xàtiva",
                "countryCode": "ES"
            },
            {
                "name": "Yátova",
                "countryCode": "ES"
            },
            {
                "name": "Zarra",
                "countryCode": "ES"
            },
            {
                "name": "Zorita del Maestrazgo",
                "countryCode": "ES"
            },
            {
                "name": "Zucaina",
                "countryCode": "ES"
            },
            {
                "name": "els Poblets",
                "countryCode": "ES"
            },
            {
                "name": "l'Alcora",
                "countryCode": "ES"
            },
            {
                "name": "l'Alfàs del Pi",
                "countryCode": "ES"
            },
            {
                "name": "la Nucia",
                "countryCode": "ES"
            }
        ]
    },
    {
        "name": "Sudan",
        "phonecode": "249",
        "cities": [
            {
                "name": "Zalingei",
                "countryCode": "SD"
            },
            {
                "name": "El Daein",
                "countryCode": "SD"
            },
            {
                "name": "El Fasher",
                "countryCode": "SD"
            },
            {
                "name": "Kutum",
                "countryCode": "SD"
            },
            {
                "name": "Umm Kaddadah",
                "countryCode": "SD"
            },
            {
                "name": "Gereida",
                "countryCode": "SD"
            },
            {
                "name": "Nyala",
                "countryCode": "SD"
            },
            {
                "name": "Geneina",
                "countryCode": "SD"
            },
            {
                "name": "Al Qadarif",
                "countryCode": "SD"
            },
            {
                "name": "Al Ḩawātah",
                "countryCode": "SD"
            },
            {
                "name": "Doka",
                "countryCode": "SD"
            },
            {
                "name": "Abū Zabad",
                "countryCode": "SD"
            },
            {
                "name": "Al Lagowa",
                "countryCode": "SD"
            },
            {
                "name": "Al Mijlad",
                "countryCode": "SD"
            },
            {
                "name": "An Nuhūd",
                "countryCode": "SD"
            },
            {
                "name": "Al Hasaheisa",
                "countryCode": "SD"
            },
            {
                "name": "Al Hilāliyya",
                "countryCode": "SD"
            },
            {
                "name": "Al Kiremit al ‘Arakiyyīn",
                "countryCode": "SD"
            },
            {
                "name": "Al Manāqil",
                "countryCode": "SD"
            },
            {
                "name": "Al Masallamiyya",
                "countryCode": "SD"
            },
            {
                "name": "Wad Medani",
                "countryCode": "SD"
            },
            {
                "name": "Wad Rāwah",
                "countryCode": "SD"
            },
            {
                "name": "Aroma",
                "countryCode": "SD"
            },
            {
                "name": "Kassala",
                "countryCode": "SD"
            },
            {
                "name": "Wagar",
                "countryCode": "SD"
            },
            {
                "name": "Khartoum",
                "countryCode": "SD"
            },
            {
                "name": "Omdurman",
                "countryCode": "SD"
            },
            {
                "name": "Ar Rahad",
                "countryCode": "SD"
            },
            {
                "name": "Bārah",
                "countryCode": "SD"
            },
            {
                "name": "El Obeid",
                "countryCode": "SD"
            },
            {
                "name": "Umm Ruwaba",
                "countryCode": "SD"
            },
            {
                "name": "Abu Jibeha",
                "countryCode": "SD"
            },
            {
                "name": "Al Fūlah",
                "countryCode": "SD"
            },
            {
                "name": "Dilling",
                "countryCode": "SD"
            },
            {
                "name": "Kadugli",
                "countryCode": "SD"
            },
            {
                "name": "Talodi",
                "countryCode": "SD"
            },
            {
                "name": "Ad-Damazin",
                "countryCode": "SD"
            },
            {
                "name": "Ar Ruseris",
                "countryCode": "SD"
            },
            {
                "name": "Kurmuk",
                "countryCode": "SD"
            },
            {
                "name": "Ad Dabbah",
                "countryCode": "SD"
            },
            {
                "name": "Argo",
                "countryCode": "SD"
            },
            {
                "name": "Dongola",
                "countryCode": "SD"
            },
            {
                "name": "Karmah an Nuzul",
                "countryCode": "SD"
            },
            {
                "name": "Kuraymah",
                "countryCode": "SD"
            },
            {
                "name": "Merowe",
                "countryCode": "SD"
            },
            {
                "name": "Atbara",
                "countryCode": "SD"
            },
            {
                "name": "Berber",
                "countryCode": "SD"
            },
            {
                "name": "Ed Damer",
                "countryCode": "SD"
            },
            {
                "name": "El Bauga",
                "countryCode": "SD"
            },
            {
                "name": "El Matama",
                "countryCode": "SD"
            },
            {
                "name": "Shendi",
                "countryCode": "SD"
            },
            {
                "name": "Ad Douiem",
                "countryCode": "SD"
            },
            {
                "name": "Al Kawa",
                "countryCode": "SD"
            },
            {
                "name": "Al Qiţena",
                "countryCode": "SD"
            },
            {
                "name": "Kosti",
                "countryCode": "SD"
            },
            {
                "name": "Marabba",
                "countryCode": "SD"
            },
            {
                "name": "Rabak",
                "countryCode": "SD"
            },
            {
                "name": "Tandaltī",
                "countryCode": "SD"
            },
            {
                "name": "Um Jar Al Gharbiyya",
                "countryCode": "SD"
            },
            {
                "name": "Wad az Zāki",
                "countryCode": "SD"
            },
            {
                "name": "Gebeit",
                "countryCode": "SD"
            },
            {
                "name": "Port Sudan",
                "countryCode": "SD"
            },
            {
                "name": "Sawākin",
                "countryCode": "SD"
            },
            {
                "name": "Tokār",
                "countryCode": "SD"
            },
            {
                "name": "Ad Dindar",
                "countryCode": "SD"
            },
            {
                "name": "As Sūkī",
                "countryCode": "SD"
            },
            {
                "name": "Jalqani",
                "countryCode": "SD"
            },
            {
                "name": "Kināna",
                "countryCode": "SD"
            },
            {
                "name": "Maiurno",
                "countryCode": "SD"
            },
            {
                "name": "Singa",
                "countryCode": "SD"
            },
            {
                "name": "Sinnar",
                "countryCode": "SD"
            }
        ]
    },
    {
        "name": "Suriname",
        "phonecode": "597",
        "cities": [
            {
                "name": "Brokopondo",
                "countryCode": "SR"
            },
            {
                "name": "Brownsweg",
                "countryCode": "SR"
            },
            {
                "name": "Mariënburg",
                "countryCode": "SR"
            },
            {
                "name": "Nieuw Amsterdam",
                "countryCode": "SR"
            },
            {
                "name": "Totness",
                "countryCode": "SR"
            },
            {
                "name": "Albina",
                "countryCode": "SR"
            },
            {
                "name": "Moengo",
                "countryCode": "SR"
            },
            {
                "name": "Nieuw Nickerie",
                "countryCode": "SR"
            },
            {
                "name": "Wageningen",
                "countryCode": "SR"
            },
            {
                "name": "Paramaribo",
                "countryCode": "SR"
            },
            {
                "name": "Onverwacht",
                "countryCode": "SR"
            },
            {
                "name": "Groningen",
                "countryCode": "SR"
            },
            {
                "name": "Lelydorp",
                "countryCode": "SR"
            }
        ]
    },
    {
        "name": "Svalbard And Jan Mayen Islands",
        "phonecode": "47",
        "cities": []
    },
    {
        "name": "Swaziland",
        "phonecode": "268",
        "cities": [
            {
                "name": "Bulembu",
                "countryCode": "SZ"
            },
            {
                "name": "Hhukwini",
                "countryCode": "SZ"
            },
            {
                "name": "Lobamba",
                "countryCode": "SZ"
            },
            {
                "name": "Mbabane",
                "countryCode": "SZ"
            },
            {
                "name": "Nkhaba",
                "countryCode": "SZ"
            },
            {
                "name": "Piggs Peak",
                "countryCode": "SZ"
            },
            {
                "name": "Big Bend",
                "countryCode": "SZ"
            },
            {
                "name": "Dvokodvweni Inkhundla",
                "countryCode": "SZ"
            },
            {
                "name": "Lomashasha",
                "countryCode": "SZ"
            },
            {
                "name": "Mhlume",
                "countryCode": "SZ"
            },
            {
                "name": "Nsoko",
                "countryCode": "SZ"
            },
            {
                "name": "Siteki",
                "countryCode": "SZ"
            },
            {
                "name": "Tshaneni",
                "countryCode": "SZ"
            },
            {
                "name": "Vuvulane",
                "countryCode": "SZ"
            },
            {
                "name": "Bhunya",
                "countryCode": "SZ"
            },
            {
                "name": "Ekukhanyeni",
                "countryCode": "SZ"
            },
            {
                "name": "Kwaluseni",
                "countryCode": "SZ"
            },
            {
                "name": "Malkerns",
                "countryCode": "SZ"
            },
            {
                "name": "Manzini",
                "countryCode": "SZ"
            },
            {
                "name": "Manzini South",
                "countryCode": "SZ"
            },
            {
                "name": "Mhlambanyatsi",
                "countryCode": "SZ"
            },
            {
                "name": "Ngwempisi",
                "countryCode": "SZ"
            },
            {
                "name": "Ntondozi",
                "countryCode": "SZ"
            },
            {
                "name": "Sidvokodvo",
                "countryCode": "SZ"
            },
            {
                "name": "Hlatikulu",
                "countryCode": "SZ"
            },
            {
                "name": "Hluti",
                "countryCode": "SZ"
            },
            {
                "name": "Kubuta",
                "countryCode": "SZ"
            },
            {
                "name": "Lavumisa",
                "countryCode": "SZ"
            },
            {
                "name": "Matsanjeni",
                "countryCode": "SZ"
            },
            {
                "name": "Ngudzeni",
                "countryCode": "SZ"
            },
            {
                "name": "Nhlangano",
                "countryCode": "SZ"
            },
            {
                "name": "Nkwene",
                "countryCode": "SZ"
            },
            {
                "name": "Sigwe Inkhundla",
                "countryCode": "SZ"
            },
            {
                "name": "Zombodze Ikhundla",
                "countryCode": "SZ"
            }
        ]
    },
    {
        "name": "Sweden",
        "phonecode": "46",
        "cities": [
            {
                "name": "Akalla",
                "countryCode": "SE"
            },
            {
                "name": "Alby",
                "countryCode": "SE"
            },
            {
                "name": "Arnö",
                "countryCode": "SE"
            },
            {
                "name": "Bergshamra",
                "countryCode": "SE"
            },
            {
                "name": "Bollmora",
                "countryCode": "SE"
            },
            {
                "name": "Boo",
                "countryCode": "SE"
            },
            {
                "name": "Brevik",
                "countryCode": "SE"
            },
            {
                "name": "Bro",
                "countryCode": "SE"
            },
            {
                "name": "Bromma",
                "countryCode": "SE"
            },
            {
                "name": "Brunn",
                "countryCode": "SE"
            },
            {
                "name": "Brunna",
                "countryCode": "SE"
            },
            {
                "name": "Dalarö",
                "countryCode": "SE"
            },
            {
                "name": "Djursholm",
                "countryCode": "SE"
            },
            {
                "name": "Djurö",
                "countryCode": "SE"
            },
            {
                "name": "Ekerö",
                "countryCode": "SE"
            },
            {
                "name": "Enebyberg",
                "countryCode": "SE"
            },
            {
                "name": "Eriksberg",
                "countryCode": "SE"
            },
            {
                "name": "Fisksätra",
                "countryCode": "SE"
            },
            {
                "name": "Fittja",
                "countryCode": "SE"
            },
            {
                "name": "Gamla Stan",
                "countryCode": "SE"
            },
            {
                "name": "Gustavsberg",
                "countryCode": "SE"
            },
            {
                "name": "Hallstavik",
                "countryCode": "SE"
            },
            {
                "name": "Handen",
                "countryCode": "SE"
            },
            {
                "name": "Haninge",
                "countryCode": "SE"
            },
            {
                "name": "Hemmesta",
                "countryCode": "SE"
            },
            {
                "name": "Huddinge",
                "countryCode": "SE"
            },
            {
                "name": "Hölö",
                "countryCode": "SE"
            },
            {
                "name": "Jakobsberg",
                "countryCode": "SE"
            },
            {
                "name": "Jordbro",
                "countryCode": "SE"
            },
            {
                "name": "Järna",
                "countryCode": "SE"
            },
            {
                "name": "Kista",
                "countryCode": "SE"
            },
            {
                "name": "Kopparmora",
                "countryCode": "SE"
            },
            {
                "name": "Kummelnäs",
                "countryCode": "SE"
            },
            {
                "name": "Kungsholmen",
                "countryCode": "SE"
            },
            {
                "name": "Kungsängen",
                "countryCode": "SE"
            },
            {
                "name": "Lidingö",
                "countryCode": "SE"
            },
            {
                "name": "Lindholmen",
                "countryCode": "SE"
            },
            {
                "name": "Långvik",
                "countryCode": "SE"
            },
            {
                "name": "Märsta",
                "countryCode": "SE"
            },
            {
                "name": "Mölnbo",
                "countryCode": "SE"
            },
            {
                "name": "Mörtnäs",
                "countryCode": "SE"
            },
            {
                "name": "Nacka",
                "countryCode": "SE"
            },
            {
                "name": "Norrtälje",
                "countryCode": "SE"
            },
            {
                "name": "Nykvarn",
                "countryCode": "SE"
            },
            {
                "name": "Nynäshamn",
                "countryCode": "SE"
            },
            {
                "name": "Pershagen",
                "countryCode": "SE"
            },
            {
                "name": "Resarö",
                "countryCode": "SE"
            },
            {
                "name": "Rimbo",
                "countryCode": "SE"
            },
            {
                "name": "Rindö",
                "countryCode": "SE"
            },
            {
                "name": "Rosersberg",
                "countryCode": "SE"
            },
            {
                "name": "Råsunda",
                "countryCode": "SE"
            },
            {
                "name": "Rönninge",
                "countryCode": "SE"
            },
            {
                "name": "Saltsjöbaden",
                "countryCode": "SE"
            },
            {
                "name": "Segeltorp",
                "countryCode": "SE"
            },
            {
                "name": "Sigtuna",
                "countryCode": "SE"
            },
            {
                "name": "Skänninge",
                "countryCode": "SE"
            },
            {
                "name": "Sollentuna",
                "countryCode": "SE"
            },
            {
                "name": "Solna",
                "countryCode": "SE"
            },
            {
                "name": "Stavsnäs",
                "countryCode": "SE"
            },
            {
                "name": "Stenhamra",
                "countryCode": "SE"
            },
            {
                "name": "Stockholm",
                "countryCode": "SE"
            },
            {
                "name": "Strömma",
                "countryCode": "SE"
            },
            {
                "name": "Sundbyberg",
                "countryCode": "SE"
            },
            {
                "name": "Södermalm",
                "countryCode": "SE"
            },
            {
                "name": "Södertälje",
                "countryCode": "SE"
            },
            {
                "name": "Tensta",
                "countryCode": "SE"
            },
            {
                "name": "Tullinge",
                "countryCode": "SE"
            },
            {
                "name": "Tumba",
                "countryCode": "SE"
            },
            {
                "name": "Upplands Väsby",
                "countryCode": "SE"
            },
            {
                "name": "Vallentuna",
                "countryCode": "SE"
            },
            {
                "name": "Vasastan",
                "countryCode": "SE"
            },
            {
                "name": "Vaxholm",
                "countryCode": "SE"
            },
            {
                "name": "Vårby",
                "countryCode": "SE"
            },
            {
                "name": "Vårsta",
                "countryCode": "SE"
            },
            {
                "name": "Älmsta",
                "countryCode": "SE"
            },
            {
                "name": "Älta",
                "countryCode": "SE"
            },
            {
                "name": "Åkersberga",
                "countryCode": "SE"
            },
            {
                "name": "Årsta",
                "countryCode": "SE"
            },
            {
                "name": "Ösmo",
                "countryCode": "SE"
            },
            {
                "name": "Östermalm",
                "countryCode": "SE"
            },
            {
                "name": "Backa",
                "countryCode": "SE"
            },
            {
                "name": "Bjurholm",
                "countryCode": "SE"
            },
            {
                "name": "Boliden",
                "countryCode": "SE"
            },
            {
                "name": "Bureå",
                "countryCode": "SE"
            },
            {
                "name": "Burträsk",
                "countryCode": "SE"
            },
            {
                "name": "Byske",
                "countryCode": "SE"
            },
            {
                "name": "Dorotea",
                "countryCode": "SE"
            },
            {
                "name": "Ersmark",
                "countryCode": "SE"
            },
            {
                "name": "Forsbacka",
                "countryCode": "SE"
            },
            {
                "name": "Holmsund",
                "countryCode": "SE"
            },
            {
                "name": "Hörnefors",
                "countryCode": "SE"
            },
            {
                "name": "Insjön",
                "countryCode": "SE"
            },
            {
                "name": "Kåge",
                "countryCode": "SE"
            },
            {
                "name": "Lycksele",
                "countryCode": "SE"
            },
            {
                "name": "Långsele",
                "countryCode": "SE"
            },
            {
                "name": "Malå",
                "countryCode": "SE"
            },
            {
                "name": "Nordmaling",
                "countryCode": "SE"
            },
            {
                "name": "Norsjö",
                "countryCode": "SE"
            },
            {
                "name": "Obbola",
                "countryCode": "SE"
            },
            {
                "name": "Robertsfors",
                "countryCode": "SE"
            },
            {
                "name": "Röbäck",
                "countryCode": "SE"
            },
            {
                "name": "Skelleftehamn",
                "countryCode": "SE"
            },
            {
                "name": "Skellefteå",
                "countryCode": "SE"
            },
            {
                "name": "Sorsele",
                "countryCode": "SE"
            },
            {
                "name": "Storuman",
                "countryCode": "SE"
            },
            {
                "name": "Storvik",
                "countryCode": "SE"
            },
            {
                "name": "Sävar",
                "countryCode": "SE"
            },
            {
                "name": "Söderfors",
                "countryCode": "SE"
            },
            {
                "name": "Täfteå",
                "countryCode": "SE"
            },
            {
                "name": "Umeå",
                "countryCode": "SE"
            },
            {
                "name": "Ursviken",
                "countryCode": "SE"
            },
            {
                "name": "Viken",
                "countryCode": "SE"
            },
            {
                "name": "Vilhelmina",
                "countryCode": "SE"
            },
            {
                "name": "Vindeln",
                "countryCode": "SE"
            },
            {
                "name": "Vännäs",
                "countryCode": "SE"
            },
            {
                "name": "Vännäsby",
                "countryCode": "SE"
            },
            {
                "name": "Åsele",
                "countryCode": "SE"
            },
            {
                "name": "Arjeplog",
                "countryCode": "SE"
            },
            {
                "name": "Arvidsjaur",
                "countryCode": "SE"
            },
            {
                "name": "Bergnäset",
                "countryCode": "SE"
            },
            {
                "name": "Bergsviken",
                "countryCode": "SE"
            },
            {
                "name": "Björkskatan",
                "countryCode": "SE"
            },
            {
                "name": "Boden",
                "countryCode": "SE"
            },
            {
                "name": "Gammelstad",
                "countryCode": "SE"
            },
            {
                "name": "Gällivare",
                "countryCode": "SE"
            },
            {
                "name": "Haparanda",
                "countryCode": "SE"
            },
            {
                "name": "Hortlax",
                "countryCode": "SE"
            },
            {
                "name": "Jokkmokk",
                "countryCode": "SE"
            },
            {
                "name": "Kalix",
                "countryCode": "SE"
            },
            {
                "name": "Kiruna",
                "countryCode": "SE"
            },
            {
                "name": "Luleå",
                "countryCode": "SE"
            },
            {
                "name": "Malmberget",
                "countryCode": "SE"
            },
            {
                "name": "Marielund",
                "countryCode": "SE"
            },
            {
                "name": "Norrfjärden",
                "countryCode": "SE"
            },
            {
                "name": "Pajala",
                "countryCode": "SE"
            },
            {
                "name": "Piteå",
                "countryCode": "SE"
            },
            {
                "name": "Roknäs",
                "countryCode": "SE"
            },
            {
                "name": "Rolfs",
                "countryCode": "SE"
            },
            {
                "name": "Rosvik",
                "countryCode": "SE"
            },
            {
                "name": "Råneå",
                "countryCode": "SE"
            },
            {
                "name": "Sävast",
                "countryCode": "SE"
            },
            {
                "name": "Södra Sunderbyn",
                "countryCode": "SE"
            },
            {
                "name": "Töre",
                "countryCode": "SE"
            },
            {
                "name": "Älvsbyn",
                "countryCode": "SE"
            },
            {
                "name": "Överkalix",
                "countryCode": "SE"
            },
            {
                "name": "Övertorneå",
                "countryCode": "SE"
            },
            {
                "name": "Alsike",
                "countryCode": "SE"
            },
            {
                "name": "Alunda",
                "countryCode": "SE"
            },
            {
                "name": "Anneberg",
                "countryCode": "SE"
            },
            {
                "name": "Björklinge",
                "countryCode": "SE"
            },
            {
                "name": "Bälinge",
                "countryCode": "SE"
            },
            {
                "name": "Bålsta",
                "countryCode": "SE"
            },
            {
                "name": "Enköping",
                "countryCode": "SE"
            },
            {
                "name": "Gamla Uppsala",
                "countryCode": "SE"
            },
            {
                "name": "Gimo",
                "countryCode": "SE"
            },
            {
                "name": "Grillby",
                "countryCode": "SE"
            },
            {
                "name": "Heby",
                "countryCode": "SE"
            },
            {
                "name": "Irsta",
                "countryCode": "SE"
            },
            {
                "name": "Karlholmsbruk",
                "countryCode": "SE"
            },
            {
                "name": "Knivsta",
                "countryCode": "SE"
            },
            {
                "name": "Lövstalöt",
                "countryCode": "SE"
            },
            {
                "name": "Marieberg",
                "countryCode": "SE"
            },
            {
                "name": "Morgongåva",
                "countryCode": "SE"
            },
            {
                "name": "Skutskär",
                "countryCode": "SE"
            },
            {
                "name": "Storvreta",
                "countryCode": "SE"
            },
            {
                "name": "Sävja",
                "countryCode": "SE"
            },
            {
                "name": "Söderfors",
                "countryCode": "SE"
            },
            {
                "name": "Tierp",
                "countryCode": "SE"
            },
            {
                "name": "Tärnsjö",
                "countryCode": "SE"
            },
            {
                "name": "Uppsala",
                "countryCode": "SE"
            },
            {
                "name": "Vattholma",
                "countryCode": "SE"
            },
            {
                "name": "Älvkarleby",
                "countryCode": "SE"
            },
            {
                "name": "Örbyhus",
                "countryCode": "SE"
            },
            {
                "name": "Öregrund",
                "countryCode": "SE"
            },
            {
                "name": "Örsundsbro",
                "countryCode": "SE"
            },
            {
                "name": "Österbybruk",
                "countryCode": "SE"
            },
            {
                "name": "Östervåla",
                "countryCode": "SE"
            },
            {
                "name": "Östhammar",
                "countryCode": "SE"
            },
            {
                "name": "Arnö",
                "countryCode": "SE"
            },
            {
                "name": "Bara",
                "countryCode": "SE"
            },
            {
                "name": "Eskilstuna",
                "countryCode": "SE"
            },
            {
                "name": "Flen",
                "countryCode": "SE"
            },
            {
                "name": "Gnesta",
                "countryCode": "SE"
            },
            {
                "name": "Hällbybrunn",
                "countryCode": "SE"
            },
            {
                "name": "Hälleforsnäs",
                "countryCode": "SE"
            },
            {
                "name": "Katrineholm",
                "countryCode": "SE"
            },
            {
                "name": "Kvicksund",
                "countryCode": "SE"
            },
            {
                "name": "Malmköping",
                "countryCode": "SE"
            },
            {
                "name": "Mariefred",
                "countryCode": "SE"
            },
            {
                "name": "Nyköping",
                "countryCode": "SE"
            },
            {
                "name": "Olstorp",
                "countryCode": "SE"
            },
            {
                "name": "Oxelösund",
                "countryCode": "SE"
            },
            {
                "name": "Skogstorp",
                "countryCode": "SE"
            },
            {
                "name": "Stallarholmen",
                "countryCode": "SE"
            },
            {
                "name": "Stigtomta",
                "countryCode": "SE"
            },
            {
                "name": "Strängnäs",
                "countryCode": "SE"
            },
            {
                "name": "Svalsta",
                "countryCode": "SE"
            },
            {
                "name": "Torshälla",
                "countryCode": "SE"
            },
            {
                "name": "Trosa",
                "countryCode": "SE"
            },
            {
                "name": "Vagnhärad",
                "countryCode": "SE"
            },
            {
                "name": "Valla",
                "countryCode": "SE"
            },
            {
                "name": "Vingåker",
                "countryCode": "SE"
            },
            {
                "name": "Ärla",
                "countryCode": "SE"
            },
            {
                "name": "Åkers Styckebruk",
                "countryCode": "SE"
            },
            {
                "name": "Berg",
                "countryCode": "SE"
            },
            {
                "name": "Borensberg",
                "countryCode": "SE"
            },
            {
                "name": "Boxholm",
                "countryCode": "SE"
            },
            {
                "name": "Ekängen",
                "countryCode": "SE"
            },
            {
                "name": "Finspång",
                "countryCode": "SE"
            },
            {
                "name": "Grebo",
                "countryCode": "SE"
            },
            {
                "name": "Gusum",
                "countryCode": "SE"
            },
            {
                "name": "Herrestad",
                "countryCode": "SE"
            },
            {
                "name": "Jursla",
                "countryCode": "SE"
            },
            {
                "name": "Kimstad",
                "countryCode": "SE"
            },
            {
                "name": "Kisa",
                "countryCode": "SE"
            },
            {
                "name": "Krokek",
                "countryCode": "SE"
            },
            {
                "name": "Lindö",
                "countryCode": "SE"
            },
            {
                "name": "Linghem",
                "countryCode": "SE"
            },
            {
                "name": "Linköping",
                "countryCode": "SE"
            },
            {
                "name": "Ljungsbro",
                "countryCode": "SE"
            },
            {
                "name": "Malmslätt",
                "countryCode": "SE"
            },
            {
                "name": "Mantorp",
                "countryCode": "SE"
            },
            {
                "name": "Mjölby",
                "countryCode": "SE"
            },
            {
                "name": "Motala",
                "countryCode": "SE"
            },
            {
                "name": "Norrköping",
                "countryCode": "SE"
            },
            {
                "name": "Rimforsa",
                "countryCode": "SE"
            },
            {
                "name": "Ryd",
                "countryCode": "SE"
            },
            {
                "name": "Skänninge",
                "countryCode": "SE"
            },
            {
                "name": "Skärblacka",
                "countryCode": "SE"
            },
            {
                "name": "Stenstorp",
                "countryCode": "SE"
            },
            {
                "name": "Sturefors",
                "countryCode": "SE"
            },
            {
                "name": "Svärtinge",
                "countryCode": "SE"
            },
            {
                "name": "Söderköping",
                "countryCode": "SE"
            },
            {
                "name": "Tallboda",
                "countryCode": "SE"
            },
            {
                "name": "Vadstena",
                "countryCode": "SE"
            },
            {
                "name": "Valdemarsvik",
                "countryCode": "SE"
            },
            {
                "name": "Vikingstad",
                "countryCode": "SE"
            },
            {
                "name": "Åby",
                "countryCode": "SE"
            },
            {
                "name": "Åtvidaberg",
                "countryCode": "SE"
            },
            {
                "name": "Ödeshög",
                "countryCode": "SE"
            },
            {
                "name": "Österbymo",
                "countryCode": "SE"
            },
            {
                "name": "Anderstorp",
                "countryCode": "SE"
            },
            {
                "name": "Aneby",
                "countryCode": "SE"
            },
            {
                "name": "Bankeryd",
                "countryCode": "SE"
            },
            {
                "name": "Bodafors",
                "countryCode": "SE"
            },
            {
                "name": "Bor",
                "countryCode": "SE"
            },
            {
                "name": "Bredaryd",
                "countryCode": "SE"
            },
            {
                "name": "Ekenässjön",
                "countryCode": "SE"
            },
            {
                "name": "Eksjö",
                "countryCode": "SE"
            },
            {
                "name": "Forserum",
                "countryCode": "SE"
            },
            {
                "name": "Forsheda",
                "countryCode": "SE"
            },
            {
                "name": "Gislaved",
                "countryCode": "SE"
            },
            {
                "name": "Gnosjö",
                "countryCode": "SE"
            },
            {
                "name": "Gränna",
                "countryCode": "SE"
            },
            {
                "name": "Habo",
                "countryCode": "SE"
            },
            {
                "name": "Hestra",
                "countryCode": "SE"
            },
            {
                "name": "Hillerstorp",
                "countryCode": "SE"
            },
            {
                "name": "Huskvarna",
                "countryCode": "SE"
            },
            {
                "name": "Jönköping",
                "countryCode": "SE"
            },
            {
                "name": "Kaxholmen",
                "countryCode": "SE"
            },
            {
                "name": "Landsbro",
                "countryCode": "SE"
            },
            {
                "name": "Malmbäck",
                "countryCode": "SE"
            },
            {
                "name": "Mariannelund",
                "countryCode": "SE"
            },
            {
                "name": "Marieholm",
                "countryCode": "SE"
            },
            {
                "name": "Mullsjö",
                "countryCode": "SE"
            },
            {
                "name": "Nässjö",
                "countryCode": "SE"
            },
            {
                "name": "Odensjö",
                "countryCode": "SE"
            },
            {
                "name": "Reftele",
                "countryCode": "SE"
            },
            {
                "name": "Rydaholm",
                "countryCode": "SE"
            },
            {
                "name": "Skillingaryd",
                "countryCode": "SE"
            },
            {
                "name": "Smålandsstenar",
                "countryCode": "SE"
            },
            {
                "name": "Stockaryd",
                "countryCode": "SE"
            },
            {
                "name": "Sävsjö",
                "countryCode": "SE"
            },
            {
                "name": "Taberg",
                "countryCode": "SE"
            },
            {
                "name": "Tenhult",
                "countryCode": "SE"
            },
            {
                "name": "Tranås",
                "countryCode": "SE"
            },
            {
                "name": "Vaggeryd",
                "countryCode": "SE"
            },
            {
                "name": "Vetlanda",
                "countryCode": "SE"
            },
            {
                "name": "Vrigstad",
                "countryCode": "SE"
            },
            {
                "name": "Värnamo",
                "countryCode": "SE"
            },
            {
                "name": "Alvesta",
                "countryCode": "SE"
            },
            {
                "name": "Braås",
                "countryCode": "SE"
            },
            {
                "name": "Gemla",
                "countryCode": "SE"
            },
            {
                "name": "Hovmantorp",
                "countryCode": "SE"
            },
            {
                "name": "Ingelstad",
                "countryCode": "SE"
            },
            {
                "name": "Lagan",
                "countryCode": "SE"
            },
            {
                "name": "Lammhult",
                "countryCode": "SE"
            },
            {
                "name": "Lenhovda",
                "countryCode": "SE"
            },
            {
                "name": "Lessebo",
                "countryCode": "SE"
            },
            {
                "name": "Ljungby",
                "countryCode": "SE"
            },
            {
                "name": "Markaryd",
                "countryCode": "SE"
            },
            {
                "name": "Moheda",
                "countryCode": "SE"
            },
            {
                "name": "Rottne",
                "countryCode": "SE"
            },
            {
                "name": "Ryd",
                "countryCode": "SE"
            },
            {
                "name": "Strömsnäsbruk",
                "countryCode": "SE"
            },
            {
                "name": "Tingsryd",
                "countryCode": "SE"
            },
            {
                "name": "Vislanda",
                "countryCode": "SE"
            },
            {
                "name": "Växjö",
                "countryCode": "SE"
            },
            {
                "name": "Älmhult",
                "countryCode": "SE"
            },
            {
                "name": "Åseda",
                "countryCode": "SE"
            },
            {
                "name": "Ankarsrum",
                "countryCode": "SE"
            },
            {
                "name": "Bergkvara",
                "countryCode": "SE"
            },
            {
                "name": "Blomstermåla",
                "countryCode": "SE"
            },
            {
                "name": "Borgholm",
                "countryCode": "SE"
            },
            {
                "name": "Emmaboda",
                "countryCode": "SE"
            },
            {
                "name": "Färjestaden",
                "countryCode": "SE"
            },
            {
                "name": "Gamleby",
                "countryCode": "SE"
            },
            {
                "name": "Gunnebo",
                "countryCode": "SE"
            },
            {
                "name": "Hultsfred",
                "countryCode": "SE"
            },
            {
                "name": "Högsby",
                "countryCode": "SE"
            },
            {
                "name": "Kalmar",
                "countryCode": "SE"
            },
            {
                "name": "Kristdala",
                "countryCode": "SE"
            },
            {
                "name": "Lindsdal",
                "countryCode": "SE"
            },
            {
                "name": "Ljungbyholm",
                "countryCode": "SE"
            },
            {
                "name": "Målilla",
                "countryCode": "SE"
            },
            {
                "name": "Mönsterås",
                "countryCode": "SE"
            },
            {
                "name": "Mörbylånga",
                "countryCode": "SE"
            },
            {
                "name": "Nybro",
                "countryCode": "SE"
            },
            {
                "name": "Oskarshamn",
                "countryCode": "SE"
            },
            {
                "name": "Påskallavik",
                "countryCode": "SE"
            },
            {
                "name": "Rinkabyholm",
                "countryCode": "SE"
            },
            {
                "name": "Smedby",
                "countryCode": "SE"
            },
            {
                "name": "Storebro",
                "countryCode": "SE"
            },
            {
                "name": "Södra Sandby",
                "countryCode": "SE"
            },
            {
                "name": "Södra Vi",
                "countryCode": "SE"
            },
            {
                "name": "Timmernabben",
                "countryCode": "SE"
            },
            {
                "name": "Torsås",
                "countryCode": "SE"
            },
            {
                "name": "Trekanten",
                "countryCode": "SE"
            },
            {
                "name": "Vimmerby",
                "countryCode": "SE"
            },
            {
                "name": "Virserum",
                "countryCode": "SE"
            },
            {
                "name": "Västervik",
                "countryCode": "SE"
            },
            {
                "name": "Överum",
                "countryCode": "SE"
            },
            {
                "name": "Gotland",
                "countryCode": "SE"
            },
            {
                "name": "Hemse",
                "countryCode": "SE"
            },
            {
                "name": "Klintehamn",
                "countryCode": "SE"
            },
            {
                "name": "Vibble",
                "countryCode": "SE"
            },
            {
                "name": "Visby",
                "countryCode": "SE"
            },
            {
                "name": "Bräkne-Hoby",
                "countryCode": "SE"
            },
            {
                "name": "Hasslö",
                "countryCode": "SE"
            },
            {
                "name": "Hällevik",
                "countryCode": "SE"
            },
            {
                "name": "Jämjö",
                "countryCode": "SE"
            },
            {
                "name": "Jämshög",
                "countryCode": "SE"
            },
            {
                "name": "Kallinge",
                "countryCode": "SE"
            },
            {
                "name": "Karlshamn",
                "countryCode": "SE"
            },
            {
                "name": "Karlskrona",
                "countryCode": "SE"
            },
            {
                "name": "Mjällby",
                "countryCode": "SE"
            },
            {
                "name": "Mörrum",
                "countryCode": "SE"
            },
            {
                "name": "Nättraby",
                "countryCode": "SE"
            },
            {
                "name": "Olofström",
                "countryCode": "SE"
            },
            {
                "name": "Ronneby",
                "countryCode": "SE"
            },
            {
                "name": "Rödeby",
                "countryCode": "SE"
            },
            {
                "name": "Sturkö",
                "countryCode": "SE"
            },
            {
                "name": "Svängsta",
                "countryCode": "SE"
            },
            {
                "name": "Sölvesborg",
                "countryCode": "SE"
            },
            {
                "name": "Anderslöv",
                "countryCode": "SE"
            },
            {
                "name": "Arlöv",
                "countryCode": "SE"
            },
            {
                "name": "Asmundtorp",
                "countryCode": "SE"
            },
            {
                "name": "Bara",
                "countryCode": "SE"
            },
            {
                "name": "Billeberga",
                "countryCode": "SE"
            },
            {
                "name": "Billesholm",
                "countryCode": "SE"
            },
            {
                "name": "Bjuv",
                "countryCode": "SE"
            },
            {
                "name": "Bjärnum",
                "countryCode": "SE"
            },
            {
                "name": "Bjärred",
                "countryCode": "SE"
            },
            {
                "name": "Blentarp",
                "countryCode": "SE"
            },
            {
                "name": "Broby",
                "countryCode": "SE"
            },
            {
                "name": "Bromölla",
                "countryCode": "SE"
            },
            {
                "name": "Bunkeflostrand",
                "countryCode": "SE"
            },
            {
                "name": "Bårslöv",
                "countryCode": "SE"
            },
            {
                "name": "Båstad",
                "countryCode": "SE"
            },
            {
                "name": "Dalby",
                "countryCode": "SE"
            },
            {
                "name": "Degeberga",
                "countryCode": "SE"
            },
            {
                "name": "Ekeby",
                "countryCode": "SE"
            },
            {
                "name": "Eslöv",
                "countryCode": "SE"
            },
            {
                "name": "Fjälkinge",
                "countryCode": "SE"
            },
            {
                "name": "Furulund",
                "countryCode": "SE"
            },
            {
                "name": "Färlöv",
                "countryCode": "SE"
            },
            {
                "name": "Förslöv",
                "countryCode": "SE"
            },
            {
                "name": "Gantofta",
                "countryCode": "SE"
            },
            {
                "name": "Genarp",
                "countryCode": "SE"
            },
            {
                "name": "Glimåkra",
                "countryCode": "SE"
            },
            {
                "name": "Glumslöv",
                "countryCode": "SE"
            },
            {
                "name": "Gärsnäs",
                "countryCode": "SE"
            },
            {
                "name": "Hammar",
                "countryCode": "SE"
            },
            {
                "name": "Hanaskog",
                "countryCode": "SE"
            },
            {
                "name": "Helsingborg",
                "countryCode": "SE"
            },
            {
                "name": "Hittarp",
                "countryCode": "SE"
            },
            {
                "name": "Hjärnarp",
                "countryCode": "SE"
            },
            {
                "name": "Hjärup",
                "countryCode": "SE"
            },
            {
                "name": "Hofterup",
                "countryCode": "SE"
            },
            {
                "name": "Hyllinge",
                "countryCode": "SE"
            },
            {
                "name": "Häljarp",
                "countryCode": "SE"
            },
            {
                "name": "Hässleholm",
                "countryCode": "SE"
            },
            {
                "name": "Hästveda",
                "countryCode": "SE"
            },
            {
                "name": "Höganäs",
                "countryCode": "SE"
            },
            {
                "name": "Hököpinge",
                "countryCode": "SE"
            },
            {
                "name": "Höllviken",
                "countryCode": "SE"
            },
            {
                "name": "Hörby",
                "countryCode": "SE"
            },
            {
                "name": "Höör",
                "countryCode": "SE"
            },
            {
                "name": "Jonstorp",
                "countryCode": "SE"
            },
            {
                "name": "Klippan",
                "countryCode": "SE"
            },
            {
                "name": "Klågerup",
                "countryCode": "SE"
            },
            {
                "name": "Knislinge",
                "countryCode": "SE"
            },
            {
                "name": "Kristianstad",
                "countryCode": "SE"
            },
            {
                "name": "Kvidinge",
                "countryCode": "SE"
            },
            {
                "name": "Kävlinge",
                "countryCode": "SE"
            },
            {
                "name": "Kågeröd",
                "countryCode": "SE"
            },
            {
                "name": "Köpingebro",
                "countryCode": "SE"
            },
            {
                "name": "Landskrona",
                "countryCode": "SE"
            },
            {
                "name": "Ljungbyhed",
                "countryCode": "SE"
            },
            {
                "name": "Ljunghusen",
                "countryCode": "SE"
            },
            {
                "name": "Lomma",
                "countryCode": "SE"
            },
            {
                "name": "Lund",
                "countryCode": "SE"
            },
            {
                "name": "Löberöd",
                "countryCode": "SE"
            },
            {
                "name": "Löddeköpinge",
                "countryCode": "SE"
            },
            {
                "name": "Lönsboda",
                "countryCode": "SE"
            },
            {
                "name": "Malmö",
                "countryCode": "SE"
            },
            {
                "name": "Marieholm",
                "countryCode": "SE"
            },
            {
                "name": "Munka-Ljungby",
                "countryCode": "SE"
            },
            {
                "name": "Mörarp",
                "countryCode": "SE"
            },
            {
                "name": "Norra Åsum",
                "countryCode": "SE"
            },
            {
                "name": "Näsum",
                "countryCode": "SE"
            },
            {
                "name": "Osby",
                "countryCode": "SE"
            },
            {
                "name": "Oxie",
                "countryCode": "SE"
            },
            {
                "name": "Perstorp",
                "countryCode": "SE"
            },
            {
                "name": "Påarp",
                "countryCode": "SE"
            },
            {
                "name": "Rydebäck",
                "countryCode": "SE"
            },
            {
                "name": "Rydsgård",
                "countryCode": "SE"
            },
            {
                "name": "Saxtorpsskogen",
                "countryCode": "SE"
            },
            {
                "name": "Sibbhult",
                "countryCode": "SE"
            },
            {
                "name": "Simrishamn",
                "countryCode": "SE"
            },
            {
                "name": "Sjöbo",
                "countryCode": "SE"
            },
            {
                "name": "Skanör med Falsterbo",
                "countryCode": "SE"
            },
            {
                "name": "Skegrie",
                "countryCode": "SE"
            },
            {
                "name": "Skivarp",
                "countryCode": "SE"
            },
            {
                "name": "Skurup",
                "countryCode": "SE"
            },
            {
                "name": "Skåre",
                "countryCode": "SE"
            },
            {
                "name": "Smygehamn",
                "countryCode": "SE"
            },
            {
                "name": "Staffanstorp",
                "countryCode": "SE"
            },
            {
                "name": "Stehag",
                "countryCode": "SE"
            },
            {
                "name": "Strövelstorp",
                "countryCode": "SE"
            },
            {
                "name": "Stångby",
                "countryCode": "SE"
            },
            {
                "name": "Svalöv",
                "countryCode": "SE"
            },
            {
                "name": "Svedala",
                "countryCode": "SE"
            },
            {
                "name": "Sätofta",
                "countryCode": "SE"
            },
            {
                "name": "Södra Sandby",
                "countryCode": "SE"
            },
            {
                "name": "Sösdala",
                "countryCode": "SE"
            },
            {
                "name": "Teckomatorp",
                "countryCode": "SE"
            },
            {
                "name": "Tollarp",
                "countryCode": "SE"
            },
            {
                "name": "Tollarp1",
                "countryCode": "SE"
            },
            {
                "name": "Tomelilla",
                "countryCode": "SE"
            },
            {
                "name": "Torekov",
                "countryCode": "SE"
            },
            {
                "name": "Tormestorp",
                "countryCode": "SE"
            },
            {
                "name": "Trelleborg",
                "countryCode": "SE"
            },
            {
                "name": "Tygelsjö",
                "countryCode": "SE"
            },
            {
                "name": "Tyringe",
                "countryCode": "SE"
            },
            {
                "name": "Valje",
                "countryCode": "SE"
            },
            {
                "name": "Veberöd",
                "countryCode": "SE"
            },
            {
                "name": "Vejbystrand",
                "countryCode": "SE"
            },
            {
                "name": "Vellinge",
                "countryCode": "SE"
            },
            {
                "name": "Viken",
                "countryCode": "SE"
            },
            {
                "name": "Vinslöv",
                "countryCode": "SE"
            },
            {
                "name": "Vittsjö",
                "countryCode": "SE"
            },
            {
                "name": "Ystad",
                "countryCode": "SE"
            },
            {
                "name": "Ängelholm",
                "countryCode": "SE"
            },
            {
                "name": "Åhus",
                "countryCode": "SE"
            },
            {
                "name": "Åkarp",
                "countryCode": "SE"
            },
            {
                "name": "Åstorp",
                "countryCode": "SE"
            },
            {
                "name": "Ödåkra",
                "countryCode": "SE"
            },
            {
                "name": "Önnestad",
                "countryCode": "SE"
            },
            {
                "name": "Örkelljunga",
                "countryCode": "SE"
            },
            {
                "name": "Östra Ljungby",
                "countryCode": "SE"
            },
            {
                "name": "Bua",
                "countryCode": "SE"
            },
            {
                "name": "Falkenberg",
                "countryCode": "SE"
            },
            {
                "name": "Fjärås kyrkby",
                "countryCode": "SE"
            },
            {
                "name": "Frillesås",
                "countryCode": "SE"
            },
            {
                "name": "Frösakull",
                "countryCode": "SE"
            },
            {
                "name": "Fyllinge",
                "countryCode": "SE"
            },
            {
                "name": "Getinge",
                "countryCode": "SE"
            },
            {
                "name": "Glommen",
                "countryCode": "SE"
            },
            {
                "name": "Gullbrandstorp",
                "countryCode": "SE"
            },
            {
                "name": "Halmstad",
                "countryCode": "SE"
            },
            {
                "name": "Harplinge",
                "countryCode": "SE"
            },
            {
                "name": "Haverdal",
                "countryCode": "SE"
            },
            {
                "name": "Hyltebruk",
                "countryCode": "SE"
            },
            {
                "name": "Knäred",
                "countryCode": "SE"
            },
            {
                "name": "Kungsbacka",
                "countryCode": "SE"
            },
            {
                "name": "Laholm",
                "countryCode": "SE"
            },
            {
                "name": "Mellbystrand",
                "countryCode": "SE"
            },
            {
                "name": "Mjällby",
                "countryCode": "SE"
            },
            {
                "name": "Onsala",
                "countryCode": "SE"
            },
            {
                "name": "Oskarström",
                "countryCode": "SE"
            },
            {
                "name": "Skrea",
                "countryCode": "SE"
            },
            {
                "name": "Steninge",
                "countryCode": "SE"
            },
            {
                "name": "Särö",
                "countryCode": "SE"
            },
            {
                "name": "Torup",
                "countryCode": "SE"
            },
            {
                "name": "Träslövsläge",
                "countryCode": "SE"
            },
            {
                "name": "Trönninge",
                "countryCode": "SE"
            },
            {
                "name": "Tvååker",
                "countryCode": "SE"
            },
            {
                "name": "Vallda",
                "countryCode": "SE"
            },
            {
                "name": "Varberg",
                "countryCode": "SE"
            },
            {
                "name": "Veddige",
                "countryCode": "SE"
            },
            {
                "name": "Veinge",
                "countryCode": "SE"
            },
            {
                "name": "Västra Hagen",
                "countryCode": "SE"
            },
            {
                "name": "Åled",
                "countryCode": "SE"
            },
            {
                "name": "Åsa",
                "countryCode": "SE"
            },
            {
                "name": "Alafors",
                "countryCode": "SE"
            },
            {
                "name": "Alingsås",
                "countryCode": "SE"
            },
            {
                "name": "Andalen",
                "countryCode": "SE"
            },
            {
                "name": "Anderstorp",
                "countryCode": "SE"
            },
            {
                "name": "Angered",
                "countryCode": "SE"
            },
            {
                "name": "Annelund",
                "countryCode": "SE"
            },
            {
                "name": "Axvall",
                "countryCode": "SE"
            },
            {
                "name": "Bengtsfors",
                "countryCode": "SE"
            },
            {
                "name": "Billdal",
                "countryCode": "SE"
            },
            {
                "name": "Billingsfors",
                "countryCode": "SE"
            },
            {
                "name": "Björboholm",
                "countryCode": "SE"
            },
            {
                "name": "Björkö",
                "countryCode": "SE"
            },
            {
                "name": "Björlanda",
                "countryCode": "SE"
            },
            {
                "name": "Bollebygd",
                "countryCode": "SE"
            },
            {
                "name": "Borås",
                "countryCode": "SE"
            },
            {
                "name": "Brastad",
                "countryCode": "SE"
            },
            {
                "name": "Brålanda",
                "countryCode": "SE"
            },
            {
                "name": "Dals Långed",
                "countryCode": "SE"
            },
            {
                "name": "Dalsjöfors",
                "countryCode": "SE"
            },
            {
                "name": "Diseröd",
                "countryCode": "SE"
            },
            {
                "name": "Donsö",
                "countryCode": "SE"
            },
            {
                "name": "Ed",
                "countryCode": "SE"
            },
            {
                "name": "Ellös",
                "countryCode": "SE"
            },
            {
                "name": "Eriksbo",
                "countryCode": "SE"
            },
            {
                "name": "Falköping",
                "countryCode": "SE"
            },
            {
                "name": "Floby",
                "countryCode": "SE"
            },
            {
                "name": "Fristad",
                "countryCode": "SE"
            },
            {
                "name": "Fritsla",
                "countryCode": "SE"
            },
            {
                "name": "Furulund",
                "countryCode": "SE"
            },
            {
                "name": "Färgelanda",
                "countryCode": "SE"
            },
            {
                "name": "Grebbestad",
                "countryCode": "SE"
            },
            {
                "name": "Grästorp",
                "countryCode": "SE"
            },
            {
                "name": "Gråbo",
                "countryCode": "SE"
            },
            {
                "name": "Gullspång",
                "countryCode": "SE"
            },
            {
                "name": "Gånghester",
                "countryCode": "SE"
            },
            {
                "name": "Gårdsten",
                "countryCode": "SE"
            },
            {
                "name": "Göta",
                "countryCode": "SE"
            },
            {
                "name": "Göteborg",
                "countryCode": "SE"
            },
            {
                "name": "Göteborgs stad",
                "countryCode": "SE"
            },
            {
                "name": "Götene",
                "countryCode": "SE"
            },
            {
                "name": "Hammarkullen",
                "countryCode": "SE"
            },
            {
                "name": "Henån",
                "countryCode": "SE"
            },
            {
                "name": "Herrljunga",
                "countryCode": "SE"
            },
            {
                "name": "Hindås",
                "countryCode": "SE"
            },
            {
                "name": "Hjo",
                "countryCode": "SE"
            },
            {
                "name": "Hjuvik",
                "countryCode": "SE"
            },
            {
                "name": "Horred",
                "countryCode": "SE"
            },
            {
                "name": "Hova",
                "countryCode": "SE"
            },
            {
                "name": "Hunnebostrand",
                "countryCode": "SE"
            },
            {
                "name": "Hönö",
                "countryCode": "SE"
            },
            {
                "name": "Höviksnäs",
                "countryCode": "SE"
            },
            {
                "name": "Ingared",
                "countryCode": "SE"
            },
            {
                "name": "Jonstorp",
                "countryCode": "SE"
            },
            {
                "name": "Jörlanda",
                "countryCode": "SE"
            },
            {
                "name": "Karlsborg",
                "countryCode": "SE"
            },
            {
                "name": "Kinna",
                "countryCode": "SE"
            },
            {
                "name": "Kode",
                "countryCode": "SE"
            },
            {
                "name": "Kungshamn",
                "countryCode": "SE"
            },
            {
                "name": "Kungälv",
                "countryCode": "SE"
            },
            {
                "name": "Kvänum",
                "countryCode": "SE"
            },
            {
                "name": "Källby",
                "countryCode": "SE"
            },
            {
                "name": "Kållered",
                "countryCode": "SE"
            },
            {
                "name": "Landvetter",
                "countryCode": "SE"
            },
            {
                "name": "Lerum",
                "countryCode": "SE"
            },
            {
                "name": "Lidköping",
                "countryCode": "SE"
            },
            {
                "name": "Lilla Edet",
                "countryCode": "SE"
            },
            {
                "name": "Limmared",
                "countryCode": "SE"
            },
            {
                "name": "Lindome",
                "countryCode": "SE"
            },
            {
                "name": "Ljungskile",
                "countryCode": "SE"
            },
            {
                "name": "Lysekil",
                "countryCode": "SE"
            },
            {
                "name": "Länghem",
                "countryCode": "SE"
            },
            {
                "name": "Lödöse",
                "countryCode": "SE"
            },
            {
                "name": "Lövgärdet",
                "countryCode": "SE"
            },
            {
                "name": "Majorna",
                "countryCode": "SE"
            },
            {
                "name": "Mariestad",
                "countryCode": "SE"
            },
            {
                "name": "Mellerud",
                "countryCode": "SE"
            },
            {
                "name": "Munkedal",
                "countryCode": "SE"
            },
            {
                "name": "Myggenäs",
                "countryCode": "SE"
            },
            {
                "name": "Mölltorp",
                "countryCode": "SE"
            },
            {
                "name": "Mölndal",
                "countryCode": "SE"
            },
            {
                "name": "Mölnlycke",
                "countryCode": "SE"
            },
            {
                "name": "Nolvik",
                "countryCode": "SE"
            },
            {
                "name": "Nossebro",
                "countryCode": "SE"
            },
            {
                "name": "Olofstorp",
                "countryCode": "SE"
            },
            {
                "name": "Olstorp",
                "countryCode": "SE"
            },
            {
                "name": "Orust",
                "countryCode": "SE"
            },
            {
                "name": "Partille",
                "countryCode": "SE"
            },
            {
                "name": "Rannebergen",
                "countryCode": "SE"
            },
            {
                "name": "Rävlanda",
                "countryCode": "SE"
            },
            {
                "name": "Rönnäng",
                "countryCode": "SE"
            },
            {
                "name": "Sandared",
                "countryCode": "SE"
            },
            {
                "name": "Sjuntorp",
                "countryCode": "SE"
            },
            {
                "name": "Sjömarken",
                "countryCode": "SE"
            },
            {
                "name": "Skara",
                "countryCode": "SE"
            },
            {
                "name": "Skepplanda",
                "countryCode": "SE"
            },
            {
                "name": "Skultorp",
                "countryCode": "SE"
            },
            {
                "name": "Skärhamn",
                "countryCode": "SE"
            },
            {
                "name": "Skövde",
                "countryCode": "SE"
            },
            {
                "name": "Smögen",
                "countryCode": "SE"
            },
            {
                "name": "Sollebrunn",
                "countryCode": "SE"
            },
            {
                "name": "Stenkullen",
                "countryCode": "SE"
            },
            {
                "name": "Stenstorp",
                "countryCode": "SE"
            },
            {
                "name": "Stenungsund",
                "countryCode": "SE"
            },
            {
                "name": "Stora Höga",
                "countryCode": "SE"
            },
            {
                "name": "Strömstad",
                "countryCode": "SE"
            },
            {
                "name": "Styrsö",
                "countryCode": "SE"
            },
            {
                "name": "Stöpen",
                "countryCode": "SE"
            },
            {
                "name": "Surte",
                "countryCode": "SE"
            },
            {
                "name": "Svanesund",
                "countryCode": "SE"
            },
            {
                "name": "Svenljunga",
                "countryCode": "SE"
            },
            {
                "name": "Sätila",
                "countryCode": "SE"
            },
            {
                "name": "Sålanda",
                "countryCode": "SE"
            },
            {
                "name": "Tanumshede",
                "countryCode": "SE"
            },
            {
                "name": "Tibro",
                "countryCode": "SE"
            },
            {
                "name": "Tidaholm",
                "countryCode": "SE"
            },
            {
                "name": "Tjuvkil",
                "countryCode": "SE"
            },
            {
                "name": "Torslanda",
                "countryCode": "SE"
            },
            {
                "name": "Tranemo",
                "countryCode": "SE"
            },
            {
                "name": "Trollhättan",
                "countryCode": "SE"
            },
            {
                "name": "Töreboda",
                "countryCode": "SE"
            },
            {
                "name": "Uddevalla",
                "countryCode": "SE"
            },
            {
                "name": "Ulricehamn",
                "countryCode": "SE"
            },
            {
                "name": "Vara",
                "countryCode": "SE"
            },
            {
                "name": "Vargön",
                "countryCode": "SE"
            },
            {
                "name": "Vinninga",
                "countryCode": "SE"
            },
            {
                "name": "Viskafors",
                "countryCode": "SE"
            },
            {
                "name": "Vänersborg",
                "countryCode": "SE"
            },
            {
                "name": "Västra Frölunda",
                "countryCode": "SE"
            },
            {
                "name": "Vårgårda",
                "countryCode": "SE"
            },
            {
                "name": "Älvängen",
                "countryCode": "SE"
            },
            {
                "name": "Åkarp",
                "countryCode": "SE"
            },
            {
                "name": "Åmål",
                "countryCode": "SE"
            },
            {
                "name": "Öckerö",
                "countryCode": "SE"
            },
            {
                "name": "Ödsmål",
                "countryCode": "SE"
            },
            {
                "name": "Öjersjö",
                "countryCode": "SE"
            },
            {
                "name": "Arvika",
                "countryCode": "SE"
            },
            {
                "name": "Björneborg",
                "countryCode": "SE"
            },
            {
                "name": "Charlottenberg",
                "countryCode": "SE"
            },
            {
                "name": "Deje",
                "countryCode": "SE"
            },
            {
                "name": "Ekshärad",
                "countryCode": "SE"
            },
            {
                "name": "Filipstad",
                "countryCode": "SE"
            },
            {
                "name": "Forshaga",
                "countryCode": "SE"
            },
            {
                "name": "Grums",
                "countryCode": "SE"
            },
            {
                "name": "Hagfors",
                "countryCode": "SE"
            },
            {
                "name": "Karlstad",
                "countryCode": "SE"
            },
            {
                "name": "Kil",
                "countryCode": "SE"
            },
            {
                "name": "Kristinehamn",
                "countryCode": "SE"
            },
            {
                "name": "Molkom",
                "countryCode": "SE"
            },
            {
                "name": "Munkfors",
                "countryCode": "SE"
            },
            {
                "name": "Råtorp",
                "countryCode": "SE"
            },
            {
                "name": "Skattkärr",
                "countryCode": "SE"
            },
            {
                "name": "Skoghall",
                "countryCode": "SE"
            },
            {
                "name": "Skåre",
                "countryCode": "SE"
            },
            {
                "name": "Slottsbron",
                "countryCode": "SE"
            },
            {
                "name": "Storfors",
                "countryCode": "SE"
            },
            {
                "name": "Sunne",
                "countryCode": "SE"
            },
            {
                "name": "Säffle",
                "countryCode": "SE"
            },
            {
                "name": "Torsby",
                "countryCode": "SE"
            },
            {
                "name": "Töcksfors",
                "countryCode": "SE"
            },
            {
                "name": "Vålberg",
                "countryCode": "SE"
            },
            {
                "name": "Åmotfors",
                "countryCode": "SE"
            },
            {
                "name": "Årjäng",
                "countryCode": "SE"
            },
            {
                "name": "Askersund",
                "countryCode": "SE"
            },
            {
                "name": "Degerfors",
                "countryCode": "SE"
            },
            {
                "name": "Ekeby-Almby",
                "countryCode": "SE"
            },
            {
                "name": "Fellingsbro",
                "countryCode": "SE"
            },
            {
                "name": "Fjugesta",
                "countryCode": "SE"
            },
            {
                "name": "Frövi",
                "countryCode": "SE"
            },
            {
                "name": "Garphyttan",
                "countryCode": "SE"
            },
            {
                "name": "Hallsberg",
                "countryCode": "SE"
            },
            {
                "name": "Hovsta",
                "countryCode": "SE"
            },
            {
                "name": "Hällabrottet",
                "countryCode": "SE"
            },
            {
                "name": "Hällefors",
                "countryCode": "SE"
            },
            {
                "name": "Karlskoga",
                "countryCode": "SE"
            },
            {
                "name": "Kopparberg",
                "countryCode": "SE"
            },
            {
                "name": "Kumla",
                "countryCode": "SE"
            },
            {
                "name": "Laxå",
                "countryCode": "SE"
            },
            {
                "name": "Lindesberg",
                "countryCode": "SE"
            },
            {
                "name": "Nora",
                "countryCode": "SE"
            },
            {
                "name": "Odensbacken",
                "countryCode": "SE"
            },
            {
                "name": "Pålsboda",
                "countryCode": "SE"
            },
            {
                "name": "Sköllersta",
                "countryCode": "SE"
            },
            {
                "name": "Storå",
                "countryCode": "SE"
            },
            {
                "name": "Vintrosa",
                "countryCode": "SE"
            },
            {
                "name": "Åsbro",
                "countryCode": "SE"
            },
            {
                "name": "Örebro",
                "countryCode": "SE"
            },
            {
                "name": "Arboga",
                "countryCode": "SE"
            },
            {
                "name": "Barkarö",
                "countryCode": "SE"
            },
            {
                "name": "Dingtuna",
                "countryCode": "SE"
            },
            {
                "name": "Enhagen-Ekbacken",
                "countryCode": "SE"
            },
            {
                "name": "Fagersta",
                "countryCode": "SE"
            },
            {
                "name": "Hallstahammar",
                "countryCode": "SE"
            },
            {
                "name": "Hökåsen",
                "countryCode": "SE"
            },
            {
                "name": "Irsta",
                "countryCode": "SE"
            },
            {
                "name": "Kolbäck",
                "countryCode": "SE"
            },
            {
                "name": "Kolsva",
                "countryCode": "SE"
            },
            {
                "name": "Kungsör",
                "countryCode": "SE"
            },
            {
                "name": "Köping",
                "countryCode": "SE"
            },
            {
                "name": "Norberg",
                "countryCode": "SE"
            },
            {
                "name": "Ramnäs",
                "countryCode": "SE"
            },
            {
                "name": "Sala",
                "countryCode": "SE"
            },
            {
                "name": "Skinnskatteberg",
                "countryCode": "SE"
            },
            {
                "name": "Skultuna",
                "countryCode": "SE"
            },
            {
                "name": "Surahammar",
                "countryCode": "SE"
            },
            {
                "name": "Tillberga",
                "countryCode": "SE"
            },
            {
                "name": "Västerås",
                "countryCode": "SE"
            },
            {
                "name": "Abborrberget",
                "countryCode": "SE"
            },
            {
                "name": "Avesta",
                "countryCode": "SE"
            },
            {
                "name": "Bjursås",
                "countryCode": "SE"
            },
            {
                "name": "Borlänge",
                "countryCode": "SE"
            },
            {
                "name": "Brunna",
                "countryCode": "SE"
            },
            {
                "name": "Djurås",
                "countryCode": "SE"
            },
            {
                "name": "Enbacka",
                "countryCode": "SE"
            },
            {
                "name": "Falun",
                "countryCode": "SE"
            },
            {
                "name": "Gagnef",
                "countryCode": "SE"
            },
            {
                "name": "Grycksbo",
                "countryCode": "SE"
            },
            {
                "name": "Grängesberg",
                "countryCode": "SE"
            },
            {
                "name": "Hedemora",
                "countryCode": "SE"
            },
            {
                "name": "Horndal",
                "countryCode": "SE"
            },
            {
                "name": "Insjön",
                "countryCode": "SE"
            },
            {
                "name": "Järna",
                "countryCode": "SE"
            },
            {
                "name": "Krylbo",
                "countryCode": "SE"
            },
            {
                "name": "Leksand",
                "countryCode": "SE"
            },
            {
                "name": "Ludvika",
                "countryCode": "SE"
            },
            {
                "name": "Långshyttan",
                "countryCode": "SE"
            },
            {
                "name": "Malung",
                "countryCode": "SE"
            },
            {
                "name": "Mockfjärd",
                "countryCode": "SE"
            },
            {
                "name": "Mora",
                "countryCode": "SE"
            },
            {
                "name": "Nyhammar",
                "countryCode": "SE"
            },
            {
                "name": "Ornäs",
                "countryCode": "SE"
            },
            {
                "name": "Orsa",
                "countryCode": "SE"
            },
            {
                "name": "Romme",
                "countryCode": "SE"
            },
            {
                "name": "Rättvik",
                "countryCode": "SE"
            },
            {
                "name": "Siljansnäs",
                "countryCode": "SE"
            },
            {
                "name": "Smedby",
                "countryCode": "SE"
            },
            {
                "name": "Smedjebacken",
                "countryCode": "SE"
            },
            {
                "name": "Svärdsjö",
                "countryCode": "SE"
            },
            {
                "name": "Säter",
                "countryCode": "SE"
            },
            {
                "name": "Vansbro",
                "countryCode": "SE"
            },
            {
                "name": "Vikarbyn",
                "countryCode": "SE"
            },
            {
                "name": "Älvdalen",
                "countryCode": "SE"
            },
            {
                "name": "Alfta",
                "countryCode": "SE"
            },
            {
                "name": "Arbrå",
                "countryCode": "SE"
            },
            {
                "name": "Bergby",
                "countryCode": "SE"
            },
            {
                "name": "Bergsjö",
                "countryCode": "SE"
            },
            {
                "name": "Bollnäs",
                "countryCode": "SE"
            },
            {
                "name": "Delsbo",
                "countryCode": "SE"
            },
            {
                "name": "Edsbyn",
                "countryCode": "SE"
            },
            {
                "name": "Forsbacka",
                "countryCode": "SE"
            },
            {
                "name": "Färila",
                "countryCode": "SE"
            },
            {
                "name": "Gnarp",
                "countryCode": "SE"
            },
            {
                "name": "Gävle",
                "countryCode": "SE"
            },
            {
                "name": "Hedesunda",
                "countryCode": "SE"
            },
            {
                "name": "Hofors",
                "countryCode": "SE"
            },
            {
                "name": "Hudiksvall",
                "countryCode": "SE"
            },
            {
                "name": "Iggesund",
                "countryCode": "SE"
            },
            {
                "name": "Järbo",
                "countryCode": "SE"
            },
            {
                "name": "Järvsö",
                "countryCode": "SE"
            },
            {
                "name": "Kilafors",
                "countryCode": "SE"
            },
            {
                "name": "Ljusdal",
                "countryCode": "SE"
            },
            {
                "name": "Ljusne",
                "countryCode": "SE"
            },
            {
                "name": "Ockelbo",
                "countryCode": "SE"
            },
            {
                "name": "Sandarne",
                "countryCode": "SE"
            },
            {
                "name": "Sandviken",
                "countryCode": "SE"
            },
            {
                "name": "Storvik",
                "countryCode": "SE"
            },
            {
                "name": "Söderhamn",
                "countryCode": "SE"
            },
            {
                "name": "Sörforsa",
                "countryCode": "SE"
            },
            {
                "name": "Valbo",
                "countryCode": "SE"
            },
            {
                "name": "Årsunda",
                "countryCode": "SE"
            },
            {
                "name": "Bergeforsen",
                "countryCode": "SE"
            },
            {
                "name": "Bjästa",
                "countryCode": "SE"
            },
            {
                "name": "Bollstabruk",
                "countryCode": "SE"
            },
            {
                "name": "Bredbyn",
                "countryCode": "SE"
            },
            {
                "name": "Fränsta",
                "countryCode": "SE"
            },
            {
                "name": "Husum",
                "countryCode": "SE"
            },
            {
                "name": "Härnösand",
                "countryCode": "SE"
            },
            {
                "name": "Johannedal",
                "countryCode": "SE"
            },
            {
                "name": "Kramfors",
                "countryCode": "SE"
            },
            {
                "name": "Kvissleby",
                "countryCode": "SE"
            },
            {
                "name": "Köpmanholmen",
                "countryCode": "SE"
            },
            {
                "name": "Långsele",
                "countryCode": "SE"
            },
            {
                "name": "Matfors",
                "countryCode": "SE"
            },
            {
                "name": "Njurundabommen",
                "countryCode": "SE"
            },
            {
                "name": "Nolby",
                "countryCode": "SE"
            },
            {
                "name": "Skottsund",
                "countryCode": "SE"
            },
            {
                "name": "Sollefteå",
                "countryCode": "SE"
            },
            {
                "name": "Stockvik",
                "countryCode": "SE"
            },
            {
                "name": "Sundsbruk",
                "countryCode": "SE"
            },
            {
                "name": "Sundsvall",
                "countryCode": "SE"
            },
            {
                "name": "Söråker",
                "countryCode": "SE"
            },
            {
                "name": "Timrå",
                "countryCode": "SE"
            },
            {
                "name": "Vi",
                "countryCode": "SE"
            },
            {
                "name": "Ånge",
                "countryCode": "SE"
            },
            {
                "name": "Ås",
                "countryCode": "SE"
            },
            {
                "name": "Örnsköldsvik",
                "countryCode": "SE"
            }
        ]
    },
    {
        "name": "Switzerland",
        "phonecode": "41",
        "cities": [
            {
                "name": "Aarau",
                "countryCode": "CH"
            },
            {
                "name": "Aarburg",
                "countryCode": "CH"
            },
            {
                "name": "Aristau",
                "countryCode": "CH"
            },
            {
                "name": "Auw",
                "countryCode": "CH"
            },
            {
                "name": "Bad Zurzach",
                "countryCode": "CH"
            },
            {
                "name": "Baden",
                "countryCode": "CH"
            },
            {
                "name": "Berikon",
                "countryCode": "CH"
            },
            {
                "name": "Besenbüren",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Aarau",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Baden",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Bremgarten",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Brugg",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Kulm",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Laufenburg",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Lenzburg",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Muri",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Rheinfelden",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Zofingen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Zurzach",
                "countryCode": "CH"
            },
            {
                "name": "Birmenstorf",
                "countryCode": "CH"
            },
            {
                "name": "Birr",
                "countryCode": "CH"
            },
            {
                "name": "Boniswil",
                "countryCode": "CH"
            },
            {
                "name": "Bremgarten",
                "countryCode": "CH"
            },
            {
                "name": "Brittnau",
                "countryCode": "CH"
            },
            {
                "name": "Brugg",
                "countryCode": "CH"
            },
            {
                "name": "Buchs",
                "countryCode": "CH"
            },
            {
                "name": "Buttwil",
                "countryCode": "CH"
            },
            {
                "name": "Densbüren",
                "countryCode": "CH"
            },
            {
                "name": "Dottikon",
                "countryCode": "CH"
            },
            {
                "name": "Dürrenäsch",
                "countryCode": "CH"
            },
            {
                "name": "Egliswil",
                "countryCode": "CH"
            },
            {
                "name": "Ehrendingen",
                "countryCode": "CH"
            },
            {
                "name": "Eiken",
                "countryCode": "CH"
            },
            {
                "name": "Endingen",
                "countryCode": "CH"
            },
            {
                "name": "Frick",
                "countryCode": "CH"
            },
            {
                "name": "Gebenstorf",
                "countryCode": "CH"
            },
            {
                "name": "Gipf-Oberfrick",
                "countryCode": "CH"
            },
            {
                "name": "Gontenschwil",
                "countryCode": "CH"
            },
            {
                "name": "Gränichen",
                "countryCode": "CH"
            },
            {
                "name": "Hausen",
                "countryCode": "CH"
            },
            {
                "name": "Hägglingen",
                "countryCode": "CH"
            },
            {
                "name": "Jonen",
                "countryCode": "CH"
            },
            {
                "name": "Kaiseraugst",
                "countryCode": "CH"
            },
            {
                "name": "Kaisten",
                "countryCode": "CH"
            },
            {
                "name": "Killwangen",
                "countryCode": "CH"
            },
            {
                "name": "Klingnau",
                "countryCode": "CH"
            },
            {
                "name": "Koblenz",
                "countryCode": "CH"
            },
            {
                "name": "Kölliken",
                "countryCode": "CH"
            },
            {
                "name": "Künten",
                "countryCode": "CH"
            },
            {
                "name": "Küttigen",
                "countryCode": "CH"
            },
            {
                "name": "Laufenburg",
                "countryCode": "CH"
            },
            {
                "name": "Lauffohr (Brugg)",
                "countryCode": "CH"
            },
            {
                "name": "Leibstadt",
                "countryCode": "CH"
            },
            {
                "name": "Lenzburg",
                "countryCode": "CH"
            },
            {
                "name": "Magden",
                "countryCode": "CH"
            },
            {
                "name": "Meisterschwanden",
                "countryCode": "CH"
            },
            {
                "name": "Mellingen",
                "countryCode": "CH"
            },
            {
                "name": "Menziken",
                "countryCode": "CH"
            },
            {
                "name": "Merenschwand",
                "countryCode": "CH"
            },
            {
                "name": "Muhen",
                "countryCode": "CH"
            },
            {
                "name": "Mumpf",
                "countryCode": "CH"
            },
            {
                "name": "Murgenthal",
                "countryCode": "CH"
            },
            {
                "name": "Muri",
                "countryCode": "CH"
            },
            {
                "name": "Möhlin",
                "countryCode": "CH"
            },
            {
                "name": "Neuenhof",
                "countryCode": "CH"
            },
            {
                "name": "Niederlenz",
                "countryCode": "CH"
            },
            {
                "name": "Niederrohrdorf",
                "countryCode": "CH"
            },
            {
                "name": "Oberentfelden",
                "countryCode": "CH"
            },
            {
                "name": "Oberlunkhofen",
                "countryCode": "CH"
            },
            {
                "name": "Oberrohrdorf",
                "countryCode": "CH"
            },
            {
                "name": "Oberrüti",
                "countryCode": "CH"
            },
            {
                "name": "Obersiggenthal",
                "countryCode": "CH"
            },
            {
                "name": "Oftringen",
                "countryCode": "CH"
            },
            {
                "name": "Othmarsingen",
                "countryCode": "CH"
            },
            {
                "name": "Reinach",
                "countryCode": "CH"
            },
            {
                "name": "Rheinfelden",
                "countryCode": "CH"
            },
            {
                "name": "Rothrist",
                "countryCode": "CH"
            },
            {
                "name": "Rudolfstetten",
                "countryCode": "CH"
            },
            {
                "name": "Rupperswil",
                "countryCode": "CH"
            },
            {
                "name": "Safenwil",
                "countryCode": "CH"
            },
            {
                "name": "Sarmenstorf",
                "countryCode": "CH"
            },
            {
                "name": "Schafisheim",
                "countryCode": "CH"
            },
            {
                "name": "Schinznach Bad",
                "countryCode": "CH"
            },
            {
                "name": "Schinznach Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Schwaderloch",
                "countryCode": "CH"
            },
            {
                "name": "Schöftland",
                "countryCode": "CH"
            },
            {
                "name": "Seengen",
                "countryCode": "CH"
            },
            {
                "name": "Seon",
                "countryCode": "CH"
            },
            {
                "name": "Sins",
                "countryCode": "CH"
            },
            {
                "name": "Spreitenbach",
                "countryCode": "CH"
            },
            {
                "name": "Staffelbach",
                "countryCode": "CH"
            },
            {
                "name": "Staufen",
                "countryCode": "CH"
            },
            {
                "name": "Stein",
                "countryCode": "CH"
            },
            {
                "name": "Strengelbach",
                "countryCode": "CH"
            },
            {
                "name": "Suhr",
                "countryCode": "CH"
            },
            {
                "name": "Sulz",
                "countryCode": "CH"
            },
            {
                "name": "Tegerfelden",
                "countryCode": "CH"
            },
            {
                "name": "Teufenthal",
                "countryCode": "CH"
            },
            {
                "name": "Turgi",
                "countryCode": "CH"
            },
            {
                "name": "Uerkheim",
                "countryCode": "CH"
            },
            {
                "name": "Unterkulm",
                "countryCode": "CH"
            },
            {
                "name": "Unterlunkhofen",
                "countryCode": "CH"
            },
            {
                "name": "Untersiggenthal",
                "countryCode": "CH"
            },
            {
                "name": "Veltheim",
                "countryCode": "CH"
            },
            {
                "name": "Villigen",
                "countryCode": "CH"
            },
            {
                "name": "Villmergen",
                "countryCode": "CH"
            },
            {
                "name": "Villnachern",
                "countryCode": "CH"
            },
            {
                "name": "Vordemwald",
                "countryCode": "CH"
            },
            {
                "name": "Waltenschwil",
                "countryCode": "CH"
            },
            {
                "name": "Wegenstetten",
                "countryCode": "CH"
            },
            {
                "name": "Wettingen",
                "countryCode": "CH"
            },
            {
                "name": "Windisch",
                "countryCode": "CH"
            },
            {
                "name": "Wittnau",
                "countryCode": "CH"
            },
            {
                "name": "Wohlen",
                "countryCode": "CH"
            },
            {
                "name": "Wölflinswil",
                "countryCode": "CH"
            },
            {
                "name": "Würenlingen",
                "countryCode": "CH"
            },
            {
                "name": "Würenlos",
                "countryCode": "CH"
            },
            {
                "name": "Zofingen",
                "countryCode": "CH"
            },
            {
                "name": "Appenzell",
                "countryCode": "CH"
            },
            {
                "name": "Gonten",
                "countryCode": "CH"
            },
            {
                "name": "Haslen",
                "countryCode": "CH"
            },
            {
                "name": "Oberegg",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Hinterland",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Mittelland",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Vorderland",
                "countryCode": "CH"
            },
            {
                "name": "Bühler",
                "countryCode": "CH"
            },
            {
                "name": "Gais",
                "countryCode": "CH"
            },
            {
                "name": "Heiden",
                "countryCode": "CH"
            },
            {
                "name": "Herisau",
                "countryCode": "CH"
            },
            {
                "name": "Rehetobel",
                "countryCode": "CH"
            },
            {
                "name": "Schwellbrunn",
                "countryCode": "CH"
            },
            {
                "name": "Speicher",
                "countryCode": "CH"
            },
            {
                "name": "Teufen",
                "countryCode": "CH"
            },
            {
                "name": "Trogen",
                "countryCode": "CH"
            },
            {
                "name": "Urnäsch",
                "countryCode": "CH"
            },
            {
                "name": "Waldstatt",
                "countryCode": "CH"
            },
            {
                "name": "Walzenhausen",
                "countryCode": "CH"
            },
            {
                "name": "Aarberg",
                "countryCode": "CH"
            },
            {
                "name": "Aarwangen",
                "countryCode": "CH"
            },
            {
                "name": "Adelboden",
                "countryCode": "CH"
            },
            {
                "name": "Aeschi b. Spiez",
                "countryCode": "CH"
            },
            {
                "name": "Arch",
                "countryCode": "CH"
            },
            {
                "name": "Attiswil",
                "countryCode": "CH"
            },
            {
                "name": "Beatenberg",
                "countryCode": "CH"
            },
            {
                "name": "Bellmund",
                "countryCode": "CH"
            },
            {
                "name": "Belp",
                "countryCode": "CH"
            },
            {
                "name": "Bern",
                "countryCode": "CH"
            },
            {
                "name": "Bern-Mittelland District",
                "countryCode": "CH"
            },
            {
                "name": "Biel/Bienne",
                "countryCode": "CH"
            },
            {
                "name": "Biel/Bienne District",
                "countryCode": "CH"
            },
            {
                "name": "Biglen",
                "countryCode": "CH"
            },
            {
                "name": "Blumenstein",
                "countryCode": "CH"
            },
            {
                "name": "Bolligen",
                "countryCode": "CH"
            },
            {
                "name": "Boltigen",
                "countryCode": "CH"
            },
            {
                "name": "Bowil",
                "countryCode": "CH"
            },
            {
                "name": "Brienz",
                "countryCode": "CH"
            },
            {
                "name": "Brügg",
                "countryCode": "CH"
            },
            {
                "name": "Buchholterberg",
                "countryCode": "CH"
            },
            {
                "name": "Burgdorf",
                "countryCode": "CH"
            },
            {
                "name": "Burgistein",
                "countryCode": "CH"
            },
            {
                "name": "Bäriswil",
                "countryCode": "CH"
            },
            {
                "name": "Bätterkinden",
                "countryCode": "CH"
            },
            {
                "name": "Bévilard",
                "countryCode": "CH"
            },
            {
                "name": "Bönigen",
                "countryCode": "CH"
            },
            {
                "name": "Corgémont",
                "countryCode": "CH"
            },
            {
                "name": "Court",
                "countryCode": "CH"
            },
            {
                "name": "Courtelary",
                "countryCode": "CH"
            },
            {
                "name": "Diemtigen",
                "countryCode": "CH"
            },
            {
                "name": "Därligen",
                "countryCode": "CH"
            },
            {
                "name": "Dürrenroth",
                "countryCode": "CH"
            },
            {
                "name": "Eggiwil",
                "countryCode": "CH"
            },
            {
                "name": "Emmental District",
                "countryCode": "CH"
            },
            {
                "name": "Eriswil",
                "countryCode": "CH"
            },
            {
                "name": "Erlach",
                "countryCode": "CH"
            },
            {
                "name": "Erlenbach im Simmental",
                "countryCode": "CH"
            },
            {
                "name": "Ersigen",
                "countryCode": "CH"
            },
            {
                "name": "Evilard",
                "countryCode": "CH"
            },
            {
                "name": "Ferenbalm",
                "countryCode": "CH"
            },
            {
                "name": "Fraubrunnen",
                "countryCode": "CH"
            },
            {
                "name": "Frauenkappelen",
                "countryCode": "CH"
            },
            {
                "name": "Frutigen",
                "countryCode": "CH"
            },
            {
                "name": "Frutigen-Niedersimmental District",
                "countryCode": "CH"
            },
            {
                "name": "Grindelwald",
                "countryCode": "CH"
            },
            {
                "name": "Grossaffoltern",
                "countryCode": "CH"
            },
            {
                "name": "Gstaad",
                "countryCode": "CH"
            },
            {
                "name": "Guggisberg",
                "countryCode": "CH"
            },
            {
                "name": "Heimberg",
                "countryCode": "CH"
            },
            {
                "name": "Heimiswil",
                "countryCode": "CH"
            },
            {
                "name": "Hermiswil",
                "countryCode": "CH"
            },
            {
                "name": "Herzogenbuchsee",
                "countryCode": "CH"
            },
            {
                "name": "Hilterfingen",
                "countryCode": "CH"
            },
            {
                "name": "Hindelbank",
                "countryCode": "CH"
            },
            {
                "name": "Huttwil",
                "countryCode": "CH"
            },
            {
                "name": "Ins",
                "countryCode": "CH"
            },
            {
                "name": "Interlaken",
                "countryCode": "CH"
            },
            {
                "name": "Interlaken-Oberhasli District",
                "countryCode": "CH"
            },
            {
                "name": "Jegenstorf",
                "countryCode": "CH"
            },
            {
                "name": "Jura bernois",
                "countryCode": "CH"
            },
            {
                "name": "Kallnach",
                "countryCode": "CH"
            },
            {
                "name": "Kandersteg",
                "countryCode": "CH"
            },
            {
                "name": "Kappelen",
                "countryCode": "CH"
            },
            {
                "name": "Kehrsatz",
                "countryCode": "CH"
            },
            {
                "name": "Kirchberg",
                "countryCode": "CH"
            },
            {
                "name": "Kirchlindach",
                "countryCode": "CH"
            },
            {
                "name": "Konolfingen",
                "countryCode": "CH"
            },
            {
                "name": "Koppigen",
                "countryCode": "CH"
            },
            {
                "name": "Krauchthal",
                "countryCode": "CH"
            },
            {
                "name": "Köniz",
                "countryCode": "CH"
            },
            {
                "name": "La Neuveville",
                "countryCode": "CH"
            },
            {
                "name": "Langenthal",
                "countryCode": "CH"
            },
            {
                "name": "Langnau",
                "countryCode": "CH"
            },
            {
                "name": "Laupen",
                "countryCode": "CH"
            },
            {
                "name": "Lauperswil",
                "countryCode": "CH"
            },
            {
                "name": "Lauterbrunnen",
                "countryCode": "CH"
            },
            {
                "name": "Lengnau",
                "countryCode": "CH"
            },
            {
                "name": "Lenk",
                "countryCode": "CH"
            },
            {
                "name": "Leuzigen",
                "countryCode": "CH"
            },
            {
                "name": "Linden",
                "countryCode": "CH"
            },
            {
                "name": "Lotzwil",
                "countryCode": "CH"
            },
            {
                "name": "Lyss",
                "countryCode": "CH"
            },
            {
                "name": "Lyssach",
                "countryCode": "CH"
            },
            {
                "name": "Lützelflüh",
                "countryCode": "CH"
            },
            {
                "name": "Madiswil",
                "countryCode": "CH"
            },
            {
                "name": "Malleray",
                "countryCode": "CH"
            },
            {
                "name": "Matten",
                "countryCode": "CH"
            },
            {
                "name": "Meinisberg",
                "countryCode": "CH"
            },
            {
                "name": "Meiringen",
                "countryCode": "CH"
            },
            {
                "name": "Melchnau",
                "countryCode": "CH"
            },
            {
                "name": "Moutier",
                "countryCode": "CH"
            },
            {
                "name": "Muri",
                "countryCode": "CH"
            },
            {
                "name": "Mühleberg",
                "countryCode": "CH"
            },
            {
                "name": "Mühlethurnen",
                "countryCode": "CH"
            },
            {
                "name": "Münchenbuchsee",
                "countryCode": "CH"
            },
            {
                "name": "Münchenwiler",
                "countryCode": "CH"
            },
            {
                "name": "Münsingen",
                "countryCode": "CH"
            },
            {
                "name": "Müntschemier",
                "countryCode": "CH"
            },
            {
                "name": "Nidau",
                "countryCode": "CH"
            },
            {
                "name": "Niederbipp",
                "countryCode": "CH"
            },
            {
                "name": "Oberaargau",
                "countryCode": "CH"
            },
            {
                "name": "Oberbipp",
                "countryCode": "CH"
            },
            {
                "name": "Oberburg",
                "countryCode": "CH"
            },
            {
                "name": "Oberdiessbach",
                "countryCode": "CH"
            },
            {
                "name": "Obersimmental-Saanen District",
                "countryCode": "CH"
            },
            {
                "name": "Orpund",
                "countryCode": "CH"
            },
            {
                "name": "Orvin",
                "countryCode": "CH"
            },
            {
                "name": "Pieterlen",
                "countryCode": "CH"
            },
            {
                "name": "Péry",
                "countryCode": "CH"
            },
            {
                "name": "Radelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Reconvilier",
                "countryCode": "CH"
            },
            {
                "name": "Riggisberg",
                "countryCode": "CH"
            },
            {
                "name": "Ringgenberg",
                "countryCode": "CH"
            },
            {
                "name": "Roggwil",
                "countryCode": "CH"
            },
            {
                "name": "Rohrbach",
                "countryCode": "CH"
            },
            {
                "name": "Rubigen",
                "countryCode": "CH"
            },
            {
                "name": "Rüderswil",
                "countryCode": "CH"
            },
            {
                "name": "Rüeggisberg",
                "countryCode": "CH"
            },
            {
                "name": "Rüschegg",
                "countryCode": "CH"
            },
            {
                "name": "Saanen",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Imier",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Stephan",
                "countryCode": "CH"
            },
            {
                "name": "Schüpfen",
                "countryCode": "CH"
            },
            {
                "name": "Seeberg",
                "countryCode": "CH"
            },
            {
                "name": "Seedorf",
                "countryCode": "CH"
            },
            {
                "name": "Seeland District",
                "countryCode": "CH"
            },
            {
                "name": "Seftigen",
                "countryCode": "CH"
            },
            {
                "name": "Signau",
                "countryCode": "CH"
            },
            {
                "name": "Sigriswil",
                "countryCode": "CH"
            },
            {
                "name": "Spiez",
                "countryCode": "CH"
            },
            {
                "name": "Steffisburg",
                "countryCode": "CH"
            },
            {
                "name": "Stettlen",
                "countryCode": "CH"
            },
            {
                "name": "Sumiswald",
                "countryCode": "CH"
            },
            {
                "name": "Tavannes",
                "countryCode": "CH"
            },
            {
                "name": "Thierachern",
                "countryCode": "CH"
            },
            {
                "name": "Thun",
                "countryCode": "CH"
            },
            {
                "name": "Thun District",
                "countryCode": "CH"
            },
            {
                "name": "Toffen",
                "countryCode": "CH"
            },
            {
                "name": "Trachselwald",
                "countryCode": "CH"
            },
            {
                "name": "Tramelan",
                "countryCode": "CH"
            },
            {
                "name": "Trub",
                "countryCode": "CH"
            },
            {
                "name": "Trubschachen",
                "countryCode": "CH"
            },
            {
                "name": "Täuffelen",
                "countryCode": "CH"
            },
            {
                "name": "Uetendorf",
                "countryCode": "CH"
            },
            {
                "name": "Unterseen",
                "countryCode": "CH"
            },
            {
                "name": "Urtenen",
                "countryCode": "CH"
            },
            {
                "name": "Uttigen",
                "countryCode": "CH"
            },
            {
                "name": "Utzenstorf",
                "countryCode": "CH"
            },
            {
                "name": "Vechigen",
                "countryCode": "CH"
            },
            {
                "name": "Walkringen",
                "countryCode": "CH"
            },
            {
                "name": "Wangen an der Aare",
                "countryCode": "CH"
            },
            {
                "name": "Wattenwil",
                "countryCode": "CH"
            },
            {
                "name": "Wichtrach",
                "countryCode": "CH"
            },
            {
                "name": "Wiedlisbach",
                "countryCode": "CH"
            },
            {
                "name": "Wilderswil",
                "countryCode": "CH"
            },
            {
                "name": "Wimmis",
                "countryCode": "CH"
            },
            {
                "name": "Wohlen",
                "countryCode": "CH"
            },
            {
                "name": "Worb",
                "countryCode": "CH"
            },
            {
                "name": "Worben",
                "countryCode": "CH"
            },
            {
                "name": "Wynau",
                "countryCode": "CH"
            },
            {
                "name": "Wynigen",
                "countryCode": "CH"
            },
            {
                "name": "Wyssachen",
                "countryCode": "CH"
            },
            {
                "name": "Zollikofen",
                "countryCode": "CH"
            },
            {
                "name": "Zweisimmen",
                "countryCode": "CH"
            },
            {
                "name": "Zäziwil",
                "countryCode": "CH"
            },
            {
                "name": "Aesch",
                "countryCode": "CH"
            },
            {
                "name": "Allschwil",
                "countryCode": "CH"
            },
            {
                "name": "Arisdorf",
                "countryCode": "CH"
            },
            {
                "name": "Arlesheim",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Arlesheim",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Laufen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Liestal",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Sissach",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Waldenburg",
                "countryCode": "CH"
            },
            {
                "name": "Binningen",
                "countryCode": "CH"
            },
            {
                "name": "Birsfelden",
                "countryCode": "CH"
            },
            {
                "name": "Bottmingen",
                "countryCode": "CH"
            },
            {
                "name": "Brislach",
                "countryCode": "CH"
            },
            {
                "name": "Bubendorf",
                "countryCode": "CH"
            },
            {
                "name": "Buus",
                "countryCode": "CH"
            },
            {
                "name": "Diegten",
                "countryCode": "CH"
            },
            {
                "name": "Ettingen",
                "countryCode": "CH"
            },
            {
                "name": "Frenkendorf",
                "countryCode": "CH"
            },
            {
                "name": "Füllinsdorf",
                "countryCode": "CH"
            },
            {
                "name": "Gelterkinden",
                "countryCode": "CH"
            },
            {
                "name": "Grellingen",
                "countryCode": "CH"
            },
            {
                "name": "Hölstein",
                "countryCode": "CH"
            },
            {
                "name": "Itingen",
                "countryCode": "CH"
            },
            {
                "name": "Langenbruck",
                "countryCode": "CH"
            },
            {
                "name": "Laufen",
                "countryCode": "CH"
            },
            {
                "name": "Lausen",
                "countryCode": "CH"
            },
            {
                "name": "Liesberg",
                "countryCode": "CH"
            },
            {
                "name": "Liestal",
                "countryCode": "CH"
            },
            {
                "name": "Läufelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Muttenz",
                "countryCode": "CH"
            },
            {
                "name": "Münchenstein",
                "countryCode": "CH"
            },
            {
                "name": "Oberdorf",
                "countryCode": "CH"
            },
            {
                "name": "Oberwil",
                "countryCode": "CH"
            },
            {
                "name": "Ormalingen",
                "countryCode": "CH"
            },
            {
                "name": "Pfeffingen",
                "countryCode": "CH"
            },
            {
                "name": "Pratteln",
                "countryCode": "CH"
            },
            {
                "name": "Reigoldswil",
                "countryCode": "CH"
            },
            {
                "name": "Röschenz",
                "countryCode": "CH"
            },
            {
                "name": "Schönenbuch",
                "countryCode": "CH"
            },
            {
                "name": "Seltisberg",
                "countryCode": "CH"
            },
            {
                "name": "Sissach",
                "countryCode": "CH"
            },
            {
                "name": "Therwil",
                "countryCode": "CH"
            },
            {
                "name": "Wahlen",
                "countryCode": "CH"
            },
            {
                "name": "Waldenburg",
                "countryCode": "CH"
            },
            {
                "name": "Zunzgen",
                "countryCode": "CH"
            },
            {
                "name": "Zwingen",
                "countryCode": "CH"
            },
            {
                "name": "Basel",
                "countryCode": "CH"
            },
            {
                "name": "Bettingen",
                "countryCode": "CH"
            },
            {
                "name": "Riehen",
                "countryCode": "CH"
            },
            {
                "name": "Alterswil",
                "countryCode": "CH"
            },
            {
                "name": "Attalens",
                "countryCode": "CH"
            },
            {
                "name": "Avry-sur-Matran",
                "countryCode": "CH"
            },
            {
                "name": "Bas-Vully",
                "countryCode": "CH"
            },
            {
                "name": "Belfaux",
                "countryCode": "CH"
            },
            {
                "name": "Broc",
                "countryCode": "CH"
            },
            {
                "name": "Broye District",
                "countryCode": "CH"
            },
            {
                "name": "Bulle",
                "countryCode": "CH"
            },
            {
                "name": "Bösingen",
                "countryCode": "CH"
            },
            {
                "name": "Charmey",
                "countryCode": "CH"
            },
            {
                "name": "Châtel-Saint-Denis",
                "countryCode": "CH"
            },
            {
                "name": "Corminboeuf",
                "countryCode": "CH"
            },
            {
                "name": "Courgevaux",
                "countryCode": "CH"
            },
            {
                "name": "Cugy",
                "countryCode": "CH"
            },
            {
                "name": "Domdidier",
                "countryCode": "CH"
            },
            {
                "name": "Düdingen",
                "countryCode": "CH"
            },
            {
                "name": "Ecublens",
                "countryCode": "CH"
            },
            {
                "name": "Ependes",
                "countryCode": "CH"
            },
            {
                "name": "Estavayer-le-Lac",
                "countryCode": "CH"
            },
            {
                "name": "Flamatt",
                "countryCode": "CH"
            },
            {
                "name": "Fribourg",
                "countryCode": "CH"
            },
            {
                "name": "Giffers",
                "countryCode": "CH"
            },
            {
                "name": "Givisiez",
                "countryCode": "CH"
            },
            {
                "name": "Glâne District",
                "countryCode": "CH"
            },
            {
                "name": "Grolley",
                "countryCode": "CH"
            },
            {
                "name": "Gruyère District",
                "countryCode": "CH"
            },
            {
                "name": "Gruyères",
                "countryCode": "CH"
            },
            {
                "name": "Heitenried",
                "countryCode": "CH"
            },
            {
                "name": "Kerzers",
                "countryCode": "CH"
            },
            {
                "name": "La Roche",
                "countryCode": "CH"
            },
            {
                "name": "La Tour-de-Trême",
                "countryCode": "CH"
            },
            {
                "name": "Lake District",
                "countryCode": "CH"
            },
            {
                "name": "Marly",
                "countryCode": "CH"
            },
            {
                "name": "Marsens",
                "countryCode": "CH"
            },
            {
                "name": "Murten/Morat",
                "countryCode": "CH"
            },
            {
                "name": "Mézières",
                "countryCode": "CH"
            },
            {
                "name": "Oberschrot",
                "countryCode": "CH"
            },
            {
                "name": "Plaffeien",
                "countryCode": "CH"
            },
            {
                "name": "Praroman",
                "countryCode": "CH"
            },
            {
                "name": "Rechthalten",
                "countryCode": "CH"
            },
            {
                "name": "Riaz",
                "countryCode": "CH"
            },
            {
                "name": "Romont",
                "countryCode": "CH"
            },
            {
                "name": "Rue",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Antoni",
                "countryCode": "CH"
            },
            {
                "name": "Sarine District",
                "countryCode": "CH"
            },
            {
                "name": "Schmitten",
                "countryCode": "CH"
            },
            {
                "name": "Sense District",
                "countryCode": "CH"
            },
            {
                "name": "Sâles",
                "countryCode": "CH"
            },
            {
                "name": "Tafers",
                "countryCode": "CH"
            },
            {
                "name": "Treyvaux",
                "countryCode": "CH"
            },
            {
                "name": "Ueberstorf",
                "countryCode": "CH"
            },
            {
                "name": "Veveyse District",
                "countryCode": "CH"
            },
            {
                "name": "Villars-sur-Glâne",
                "countryCode": "CH"
            },
            {
                "name": "Villaz-Saint-Pierre",
                "countryCode": "CH"
            },
            {
                "name": "Vuadens",
                "countryCode": "CH"
            },
            {
                "name": "Aire-la-Ville",
                "countryCode": "CH"
            },
            {
                "name": "Anières",
                "countryCode": "CH"
            },
            {
                "name": "Bellevue",
                "countryCode": "CH"
            },
            {
                "name": "Bernex",
                "countryCode": "CH"
            },
            {
                "name": "Carouge",
                "countryCode": "CH"
            },
            {
                "name": "Chancy",
                "countryCode": "CH"
            },
            {
                "name": "Chêne-Bougeries",
                "countryCode": "CH"
            },
            {
                "name": "Chêne-Bourg",
                "countryCode": "CH"
            },
            {
                "name": "Confignon",
                "countryCode": "CH"
            },
            {
                "name": "Corsier",
                "countryCode": "CH"
            },
            {
                "name": "Dardagny",
                "countryCode": "CH"
            },
            {
                "name": "Genève",
                "countryCode": "CH"
            },
            {
                "name": "Jussy",
                "countryCode": "CH"
            },
            {
                "name": "Lancy",
                "countryCode": "CH"
            },
            {
                "name": "Le Grand-Saconnex",
                "countryCode": "CH"
            },
            {
                "name": "Les Avanchets",
                "countryCode": "CH"
            },
            {
                "name": "Meinier",
                "countryCode": "CH"
            },
            {
                "name": "Meyrin",
                "countryCode": "CH"
            },
            {
                "name": "Onex",
                "countryCode": "CH"
            },
            {
                "name": "Plan-les-Ouates",
                "countryCode": "CH"
            },
            {
                "name": "Puplinge",
                "countryCode": "CH"
            },
            {
                "name": "Satigny",
                "countryCode": "CH"
            },
            {
                "name": "Thônex",
                "countryCode": "CH"
            },
            {
                "name": "Troinex",
                "countryCode": "CH"
            },
            {
                "name": "Vandœuvres",
                "countryCode": "CH"
            },
            {
                "name": "Vernier",
                "countryCode": "CH"
            },
            {
                "name": "Versoix",
                "countryCode": "CH"
            },
            {
                "name": "Veyrier",
                "countryCode": "CH"
            },
            {
                "name": "Bilten",
                "countryCode": "CH"
            },
            {
                "name": "Ennenda",
                "countryCode": "CH"
            },
            {
                "name": "Glarus",
                "countryCode": "CH"
            },
            {
                "name": "Linthal",
                "countryCode": "CH"
            },
            {
                "name": "Luchsingen",
                "countryCode": "CH"
            },
            {
                "name": "Mollis",
                "countryCode": "CH"
            },
            {
                "name": "Netstal",
                "countryCode": "CH"
            },
            {
                "name": "Niederurnen",
                "countryCode": "CH"
            },
            {
                "name": "Näfels",
                "countryCode": "CH"
            },
            {
                "name": "Oberurnen",
                "countryCode": "CH"
            },
            {
                "name": "Schwanden",
                "countryCode": "CH"
            },
            {
                "name": "Arosa",
                "countryCode": "CH"
            },
            {
                "name": "Arvigo",
                "countryCode": "CH"
            },
            {
                "name": "Bonaduz",
                "countryCode": "CH"
            },
            {
                "name": "Breíl",
                "countryCode": "CH"
            },
            {
                "name": "Brusio",
                "countryCode": "CH"
            },
            {
                "name": "Buseno",
                "countryCode": "CH"
            },
            {
                "name": "Cauco",
                "countryCode": "CH"
            },
            {
                "name": "Cazis",
                "countryCode": "CH"
            },
            {
                "name": "Celerina",
                "countryCode": "CH"
            },
            {
                "name": "Chur",
                "countryCode": "CH"
            },
            {
                "name": "Churwalden",
                "countryCode": "CH"
            },
            {
                "name": "Davos",
                "countryCode": "CH"
            },
            {
                "name": "Disentis",
                "countryCode": "CH"
            },
            {
                "name": "Domat",
                "countryCode": "CH"
            },
            {
                "name": "Felsberg",
                "countryCode": "CH"
            },
            {
                "name": "Flims",
                "countryCode": "CH"
            },
            {
                "name": "Grüsch",
                "countryCode": "CH"
            },
            {
                "name": "Hinterrhein",
                "countryCode": "CH"
            },
            {
                "name": "Igis",
                "countryCode": "CH"
            },
            {
                "name": "Ilanz",
                "countryCode": "CH"
            },
            {
                "name": "Jenaz",
                "countryCode": "CH"
            },
            {
                "name": "Klosters Serneus",
                "countryCode": "CH"
            },
            {
                "name": "La Punt Chamues-ch",
                "countryCode": "CH"
            },
            {
                "name": "Laax",
                "countryCode": "CH"
            },
            {
                "name": "Landquart",
                "countryCode": "CH"
            },
            {
                "name": "Lenzerheide",
                "countryCode": "CH"
            },
            {
                "name": "Luzein",
                "countryCode": "CH"
            },
            {
                "name": "Maienfeld",
                "countryCode": "CH"
            },
            {
                "name": "Malans",
                "countryCode": "CH"
            },
            {
                "name": "Mesocco",
                "countryCode": "CH"
            },
            {
                "name": "Pontresina",
                "countryCode": "CH"
            },
            {
                "name": "Poschiavo",
                "countryCode": "CH"
            },
            {
                "name": "Region Albula",
                "countryCode": "CH"
            },
            {
                "name": "Region Bernina",
                "countryCode": "CH"
            },
            {
                "name": "Region Engiadina Bassa / Val Müstair",
                "countryCode": "CH"
            },
            {
                "name": "Region Imboden",
                "countryCode": "CH"
            },
            {
                "name": "Region Landquart",
                "countryCode": "CH"
            },
            {
                "name": "Region Maloja",
                "countryCode": "CH"
            },
            {
                "name": "Region Moesa",
                "countryCode": "CH"
            },
            {
                "name": "Region Plessur",
                "countryCode": "CH"
            },
            {
                "name": "Region Prättigau / Davos",
                "countryCode": "CH"
            },
            {
                "name": "Region Surselva",
                "countryCode": "CH"
            },
            {
                "name": "Region Viamala",
                "countryCode": "CH"
            },
            {
                "name": "Rhäzüns",
                "countryCode": "CH"
            },
            {
                "name": "Samedan",
                "countryCode": "CH"
            },
            {
                "name": "Scharans",
                "countryCode": "CH"
            },
            {
                "name": "Schiers",
                "countryCode": "CH"
            },
            {
                "name": "Scuol",
                "countryCode": "CH"
            },
            {
                "name": "Silvaplana",
                "countryCode": "CH"
            },
            {
                "name": "St. Moritz",
                "countryCode": "CH"
            },
            {
                "name": "Tamins",
                "countryCode": "CH"
            },
            {
                "name": "Thusis",
                "countryCode": "CH"
            },
            {
                "name": "Tiefencastel",
                "countryCode": "CH"
            },
            {
                "name": "Trimmis",
                "countryCode": "CH"
            },
            {
                "name": "Trun",
                "countryCode": "CH"
            },
            {
                "name": "Untervaz",
                "countryCode": "CH"
            },
            {
                "name": "Vals Platz",
                "countryCode": "CH"
            },
            {
                "name": "Zernez",
                "countryCode": "CH"
            },
            {
                "name": "Zizers",
                "countryCode": "CH"
            },
            {
                "name": "Zuoz",
                "countryCode": "CH"
            },
            {
                "name": "Alle",
                "countryCode": "CH"
            },
            {
                "name": "Bassecourt",
                "countryCode": "CH"
            },
            {
                "name": "Boncourt",
                "countryCode": "CH"
            },
            {
                "name": "Courfaivre",
                "countryCode": "CH"
            },
            {
                "name": "Courgenay",
                "countryCode": "CH"
            },
            {
                "name": "Courrendlin",
                "countryCode": "CH"
            },
            {
                "name": "Courroux",
                "countryCode": "CH"
            },
            {
                "name": "Courtételle",
                "countryCode": "CH"
            },
            {
                "name": "Delémont",
                "countryCode": "CH"
            },
            {
                "name": "Delémont District",
                "countryCode": "CH"
            },
            {
                "name": "Fontenais",
                "countryCode": "CH"
            },
            {
                "name": "Franches-Montagnes District",
                "countryCode": "CH"
            },
            {
                "name": "Glovelier",
                "countryCode": "CH"
            },
            {
                "name": "Le Noirmont",
                "countryCode": "CH"
            },
            {
                "name": "Les Bois",
                "countryCode": "CH"
            },
            {
                "name": "Les Breuleux",
                "countryCode": "CH"
            },
            {
                "name": "Porrentruy",
                "countryCode": "CH"
            },
            {
                "name": "Porrentruy District",
                "countryCode": "CH"
            },
            {
                "name": "Saignelégier",
                "countryCode": "CH"
            },
            {
                "name": "Vicques",
                "countryCode": "CH"
            },
            {
                "name": "Adligenswil",
                "countryCode": "CH"
            },
            {
                "name": "Altishofen",
                "countryCode": "CH"
            },
            {
                "name": "Ballwil",
                "countryCode": "CH"
            },
            {
                "name": "Beromünster",
                "countryCode": "CH"
            },
            {
                "name": "Buchrain",
                "countryCode": "CH"
            },
            {
                "name": "Buttisholz",
                "countryCode": "CH"
            },
            {
                "name": "Büron",
                "countryCode": "CH"
            },
            {
                "name": "Dagmersellen",
                "countryCode": "CH"
            },
            {
                "name": "Ebikon",
                "countryCode": "CH"
            },
            {
                "name": "Eich",
                "countryCode": "CH"
            },
            {
                "name": "Emmen",
                "countryCode": "CH"
            },
            {
                "name": "Entlebuch",
                "countryCode": "CH"
            },
            {
                "name": "Entlebuch District",
                "countryCode": "CH"
            },
            {
                "name": "Escholzmatt",
                "countryCode": "CH"
            },
            {
                "name": "Ettiswil",
                "countryCode": "CH"
            },
            {
                "name": "Flühli",
                "countryCode": "CH"
            },
            {
                "name": "Geuensee",
                "countryCode": "CH"
            },
            {
                "name": "Grosswangen",
                "countryCode": "CH"
            },
            {
                "name": "Gunzwil",
                "countryCode": "CH"
            },
            {
                "name": "Hasle",
                "countryCode": "CH"
            },
            {
                "name": "Hildisrieden",
                "countryCode": "CH"
            },
            {
                "name": "Hitzkirch",
                "countryCode": "CH"
            },
            {
                "name": "Hochdorf",
                "countryCode": "CH"
            },
            {
                "name": "Hochdorf District",
                "countryCode": "CH"
            },
            {
                "name": "Hohenrain",
                "countryCode": "CH"
            },
            {
                "name": "Horw",
                "countryCode": "CH"
            },
            {
                "name": "Inwil",
                "countryCode": "CH"
            },
            {
                "name": "Knutwil",
                "countryCode": "CH"
            },
            {
                "name": "Kriens",
                "countryCode": "CH"
            },
            {
                "name": "Littau",
                "countryCode": "CH"
            },
            {
                "name": "Lucerne-Land District",
                "countryCode": "CH"
            },
            {
                "name": "Lucerne-Stadt District",
                "countryCode": "CH"
            },
            {
                "name": "Luthern",
                "countryCode": "CH"
            },
            {
                "name": "Luzern",
                "countryCode": "CH"
            },
            {
                "name": "Malters",
                "countryCode": "CH"
            },
            {
                "name": "Mauensee",
                "countryCode": "CH"
            },
            {
                "name": "Meggen",
                "countryCode": "CH"
            },
            {
                "name": "Meierskappel",
                "countryCode": "CH"
            },
            {
                "name": "Menznau",
                "countryCode": "CH"
            },
            {
                "name": "Nebikon",
                "countryCode": "CH"
            },
            {
                "name": "Neudorf",
                "countryCode": "CH"
            },
            {
                "name": "Neuenkirch",
                "countryCode": "CH"
            },
            {
                "name": "Nottwil",
                "countryCode": "CH"
            },
            {
                "name": "Oberkirch",
                "countryCode": "CH"
            },
            {
                "name": "Pfaffnau",
                "countryCode": "CH"
            },
            {
                "name": "Reiden",
                "countryCode": "CH"
            },
            {
                "name": "Root",
                "countryCode": "CH"
            },
            {
                "name": "Ruswil",
                "countryCode": "CH"
            },
            {
                "name": "Römerswil",
                "countryCode": "CH"
            },
            {
                "name": "Schenkon",
                "countryCode": "CH"
            },
            {
                "name": "Schwarzenberg",
                "countryCode": "CH"
            },
            {
                "name": "Schötz",
                "countryCode": "CH"
            },
            {
                "name": "Schüpfheim",
                "countryCode": "CH"
            },
            {
                "name": "Sempach",
                "countryCode": "CH"
            },
            {
                "name": "Sursee",
                "countryCode": "CH"
            },
            {
                "name": "Sursee District",
                "countryCode": "CH"
            },
            {
                "name": "Triengen",
                "countryCode": "CH"
            },
            {
                "name": "Udligenswil",
                "countryCode": "CH"
            },
            {
                "name": "Vitznau",
                "countryCode": "CH"
            },
            {
                "name": "Wauwil",
                "countryCode": "CH"
            },
            {
                "name": "Weggis",
                "countryCode": "CH"
            },
            {
                "name": "Werthenstein",
                "countryCode": "CH"
            },
            {
                "name": "Wikon",
                "countryCode": "CH"
            },
            {
                "name": "Willisau",
                "countryCode": "CH"
            },
            {
                "name": "Willisau District",
                "countryCode": "CH"
            },
            {
                "name": "Wolhusen",
                "countryCode": "CH"
            },
            {
                "name": "Zell",
                "countryCode": "CH"
            },
            {
                "name": "Auvernier",
                "countryCode": "CH"
            },
            {
                "name": "Bevaix",
                "countryCode": "CH"
            },
            {
                "name": "Boudry",
                "countryCode": "CH"
            },
            {
                "name": "Boudry District",
                "countryCode": "CH"
            },
            {
                "name": "Cernier",
                "countryCode": "CH"
            },
            {
                "name": "Chézard-Saint-Martin",
                "countryCode": "CH"
            },
            {
                "name": "Cornaux",
                "countryCode": "CH"
            },
            {
                "name": "Cortaillod",
                "countryCode": "CH"
            },
            {
                "name": "Couvet",
                "countryCode": "CH"
            },
            {
                "name": "Dombresson",
                "countryCode": "CH"
            },
            {
                "name": "Fleurier",
                "countryCode": "CH"
            },
            {
                "name": "Fontainemelon",
                "countryCode": "CH"
            },
            {
                "name": "Gorgier",
                "countryCode": "CH"
            },
            {
                "name": "Grand-Savagnier",
                "countryCode": "CH"
            },
            {
                "name": "La Chaux-de-Fonds",
                "countryCode": "CH"
            },
            {
                "name": "La Chaux-de-Fonds District",
                "countryCode": "CH"
            },
            {
                "name": "Le Landeron",
                "countryCode": "CH"
            },
            {
                "name": "Le Locle",
                "countryCode": "CH"
            },
            {
                "name": "Le Locle District",
                "countryCode": "CH"
            },
            {
                "name": "Les Brenets",
                "countryCode": "CH"
            },
            {
                "name": "Les Geneveys-sur-Coffrane",
                "countryCode": "CH"
            },
            {
                "name": "Les Ponts-de-Martel",
                "countryCode": "CH"
            },
            {
                "name": "Marin-Epagnier",
                "countryCode": "CH"
            },
            {
                "name": "Neuchâtel",
                "countryCode": "CH"
            },
            {
                "name": "Neuchâtel District",
                "countryCode": "CH"
            },
            {
                "name": "Peseux",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Aubin-Sauges",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Blaise",
                "countryCode": "CH"
            },
            {
                "name": "Travers",
                "countryCode": "CH"
            },
            {
                "name": "Val-de-Ruz District",
                "countryCode": "CH"
            },
            {
                "name": "Val-de-Travers District",
                "countryCode": "CH"
            },
            {
                "name": "Beckenried",
                "countryCode": "CH"
            },
            {
                "name": "Buochs",
                "countryCode": "CH"
            },
            {
                "name": "Dallenwil",
                "countryCode": "CH"
            },
            {
                "name": "Emmetten",
                "countryCode": "CH"
            },
            {
                "name": "Ennetbürgen",
                "countryCode": "CH"
            },
            {
                "name": "Hergiswil",
                "countryCode": "CH"
            },
            {
                "name": "Stans",
                "countryCode": "CH"
            },
            {
                "name": "Stansstad",
                "countryCode": "CH"
            },
            {
                "name": "Wolfenschiessen",
                "countryCode": "CH"
            },
            {
                "name": "Alpnach",
                "countryCode": "CH"
            },
            {
                "name": "Engelberg",
                "countryCode": "CH"
            },
            {
                "name": "Giswil",
                "countryCode": "CH"
            },
            {
                "name": "Kerns",
                "countryCode": "CH"
            },
            {
                "name": "Lungern",
                "countryCode": "CH"
            },
            {
                "name": "Sachseln",
                "countryCode": "CH"
            },
            {
                "name": "Sarnen",
                "countryCode": "CH"
            },
            {
                "name": "Altstätten",
                "countryCode": "CH"
            },
            {
                "name": "Amden",
                "countryCode": "CH"
            },
            {
                "name": "Andwil",
                "countryCode": "CH"
            },
            {
                "name": "Au",
                "countryCode": "CH"
            },
            {
                "name": "Bad Ragaz",
                "countryCode": "CH"
            },
            {
                "name": "Balgach",
                "countryCode": "CH"
            },
            {
                "name": "Benken",
                "countryCode": "CH"
            },
            {
                "name": "Bronschhofen",
                "countryCode": "CH"
            },
            {
                "name": "Buchs",
                "countryCode": "CH"
            },
            {
                "name": "Bütschwil",
                "countryCode": "CH"
            },
            {
                "name": "Degersheim",
                "countryCode": "CH"
            },
            {
                "name": "Diepoldsau",
                "countryCode": "CH"
            },
            {
                "name": "Ebnat-Kappel",
                "countryCode": "CH"
            },
            {
                "name": "Eggersriet",
                "countryCode": "CH"
            },
            {
                "name": "Eichberg",
                "countryCode": "CH"
            },
            {
                "name": "Eschenbach",
                "countryCode": "CH"
            },
            {
                "name": "Flawil",
                "countryCode": "CH"
            },
            {
                "name": "Flums",
                "countryCode": "CH"
            },
            {
                "name": "Gams",
                "countryCode": "CH"
            },
            {
                "name": "Ganterschwil",
                "countryCode": "CH"
            },
            {
                "name": "Goldach",
                "countryCode": "CH"
            },
            {
                "name": "Goldingen",
                "countryCode": "CH"
            },
            {
                "name": "Gommiswald",
                "countryCode": "CH"
            },
            {
                "name": "Gossau",
                "countryCode": "CH"
            },
            {
                "name": "Grabs",
                "countryCode": "CH"
            },
            {
                "name": "Haag (Rheintal)",
                "countryCode": "CH"
            },
            {
                "name": "Horn",
                "countryCode": "CH"
            },
            {
                "name": "Häggenschwil",
                "countryCode": "CH"
            },
            {
                "name": "Jona",
                "countryCode": "CH"
            },
            {
                "name": "Jonschwil",
                "countryCode": "CH"
            },
            {
                "name": "Kaltbrunn",
                "countryCode": "CH"
            },
            {
                "name": "Kirchberg",
                "countryCode": "CH"
            },
            {
                "name": "Krummenau",
                "countryCode": "CH"
            },
            {
                "name": "Lichtensteig",
                "countryCode": "CH"
            },
            {
                "name": "Lütisburg",
                "countryCode": "CH"
            },
            {
                "name": "Mogelsberg",
                "countryCode": "CH"
            },
            {
                "name": "Mosnang",
                "countryCode": "CH"
            },
            {
                "name": "Muolen",
                "countryCode": "CH"
            },
            {
                "name": "Mörschwil",
                "countryCode": "CH"
            },
            {
                "name": "Niederbüren",
                "countryCode": "CH"
            },
            {
                "name": "Niederhelfenschwil",
                "countryCode": "CH"
            },
            {
                "name": "Oberhelfenschwil",
                "countryCode": "CH"
            },
            {
                "name": "Oberriet",
                "countryCode": "CH"
            },
            {
                "name": "Oberuzwil",
                "countryCode": "CH"
            },
            {
                "name": "Quarten",
                "countryCode": "CH"
            },
            {
                "name": "Rapperswil",
                "countryCode": "CH"
            },
            {
                "name": "Rebstein",
                "countryCode": "CH"
            },
            {
                "name": "Rheineck",
                "countryCode": "CH"
            },
            {
                "name": "Rorschach",
                "countryCode": "CH"
            },
            {
                "name": "Rüthi",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Gallen",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Gallenkappel",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Margrethen",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Peterzell",
                "countryCode": "CH"
            },
            {
                "name": "Sargans",
                "countryCode": "CH"
            },
            {
                "name": "Schmerikon",
                "countryCode": "CH"
            },
            {
                "name": "Schänis",
                "countryCode": "CH"
            },
            {
                "name": "Sennwald",
                "countryCode": "CH"
            },
            {
                "name": "Sevelen",
                "countryCode": "CH"
            },
            {
                "name": "Thal",
                "countryCode": "CH"
            },
            {
                "name": "Uznach",
                "countryCode": "CH"
            },
            {
                "name": "Uzwil",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Rheintal",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Rorschach",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Sarganserland",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis See-Gaster",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis St. Gallen",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Toggenburg",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Werdenberg",
                "countryCode": "CH"
            },
            {
                "name": "Wahlkreis Wil",
                "countryCode": "CH"
            },
            {
                "name": "Waldkirch",
                "countryCode": "CH"
            },
            {
                "name": "Walenstadt",
                "countryCode": "CH"
            },
            {
                "name": "Wattwil",
                "countryCode": "CH"
            },
            {
                "name": "Weesen",
                "countryCode": "CH"
            },
            {
                "name": "Wil",
                "countryCode": "CH"
            },
            {
                "name": "Wildhaus",
                "countryCode": "CH"
            },
            {
                "name": "Wittenbach",
                "countryCode": "CH"
            },
            {
                "name": "Zuzwil",
                "countryCode": "CH"
            },
            {
                "name": "Beringen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Oberklettgau",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Reiat",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Schaffhausen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Schleitheim",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Stein",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Unterklettgau",
                "countryCode": "CH"
            },
            {
                "name": "Hallau",
                "countryCode": "CH"
            },
            {
                "name": "Löhningen",
                "countryCode": "CH"
            },
            {
                "name": "Neuhausen",
                "countryCode": "CH"
            },
            {
                "name": "Neunkirch",
                "countryCode": "CH"
            },
            {
                "name": "Ramsen",
                "countryCode": "CH"
            },
            {
                "name": "Schaffhausen",
                "countryCode": "CH"
            },
            {
                "name": "Schleitheim",
                "countryCode": "CH"
            },
            {
                "name": "Stein am Rhein",
                "countryCode": "CH"
            },
            {
                "name": "Stetten",
                "countryCode": "CH"
            },
            {
                "name": "Thayngen",
                "countryCode": "CH"
            },
            {
                "name": "Wilchingen",
                "countryCode": "CH"
            },
            {
                "name": "Balsthal",
                "countryCode": "CH"
            },
            {
                "name": "Bettlach",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Bucheggberg",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Dorneck",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Gäu",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Gösgen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Lebern",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Olten",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Solothurn",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Thal",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Thierstein",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Wasseramt",
                "countryCode": "CH"
            },
            {
                "name": "Biberist",
                "countryCode": "CH"
            },
            {
                "name": "Breitenbach",
                "countryCode": "CH"
            },
            {
                "name": "Büsserach",
                "countryCode": "CH"
            },
            {
                "name": "Deitingen",
                "countryCode": "CH"
            },
            {
                "name": "Derendingen",
                "countryCode": "CH"
            },
            {
                "name": "Dornach",
                "countryCode": "CH"
            },
            {
                "name": "Egerkingen",
                "countryCode": "CH"
            },
            {
                "name": "Erlinsbach",
                "countryCode": "CH"
            },
            {
                "name": "Fulenbach",
                "countryCode": "CH"
            },
            {
                "name": "Gerlafingen",
                "countryCode": "CH"
            },
            {
                "name": "Grenchen",
                "countryCode": "CH"
            },
            {
                "name": "Gunzgen",
                "countryCode": "CH"
            },
            {
                "name": "Himmelried",
                "countryCode": "CH"
            },
            {
                "name": "Hochwald",
                "countryCode": "CH"
            },
            {
                "name": "Hägendorf",
                "countryCode": "CH"
            },
            {
                "name": "Kleinlützel",
                "countryCode": "CH"
            },
            {
                "name": "Kriegstetten",
                "countryCode": "CH"
            },
            {
                "name": "Langendorf",
                "countryCode": "CH"
            },
            {
                "name": "Laupersdorf",
                "countryCode": "CH"
            },
            {
                "name": "Lostorf",
                "countryCode": "CH"
            },
            {
                "name": "Luterbach",
                "countryCode": "CH"
            },
            {
                "name": "Matzendorf",
                "countryCode": "CH"
            },
            {
                "name": "Messen",
                "countryCode": "CH"
            },
            {
                "name": "Niedergösgen",
                "countryCode": "CH"
            },
            {
                "name": "Nunningen",
                "countryCode": "CH"
            },
            {
                "name": "Oberbuchsiten",
                "countryCode": "CH"
            },
            {
                "name": "Obergösgen",
                "countryCode": "CH"
            },
            {
                "name": "Oensingen",
                "countryCode": "CH"
            },
            {
                "name": "Olten",
                "countryCode": "CH"
            },
            {
                "name": "Riedholz",
                "countryCode": "CH"
            },
            {
                "name": "Rodersdorf",
                "countryCode": "CH"
            },
            {
                "name": "Rohr",
                "countryCode": "CH"
            },
            {
                "name": "Schönenwerd",
                "countryCode": "CH"
            },
            {
                "name": "Selzach",
                "countryCode": "CH"
            },
            {
                "name": "Solothurn",
                "countryCode": "CH"
            },
            {
                "name": "Subingen",
                "countryCode": "CH"
            },
            {
                "name": "Trimbach",
                "countryCode": "CH"
            },
            {
                "name": "Wangen",
                "countryCode": "CH"
            },
            {
                "name": "Welschenrohr",
                "countryCode": "CH"
            },
            {
                "name": "Wolfwil",
                "countryCode": "CH"
            },
            {
                "name": "Zuchwil",
                "countryCode": "CH"
            },
            {
                "name": "Altendorf",
                "countryCode": "CH"
            },
            {
                "name": "Arth",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Einsiedeln",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Gersau",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Höfe",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Küssnacht",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk March",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Schwyz",
                "countryCode": "CH"
            },
            {
                "name": "Bäch",
                "countryCode": "CH"
            },
            {
                "name": "Einsiedeln",
                "countryCode": "CH"
            },
            {
                "name": "Feusisberg",
                "countryCode": "CH"
            },
            {
                "name": "Freienbach",
                "countryCode": "CH"
            },
            {
                "name": "Gersau",
                "countryCode": "CH"
            },
            {
                "name": "Goldau",
                "countryCode": "CH"
            },
            {
                "name": "Ibach",
                "countryCode": "CH"
            },
            {
                "name": "Ingenbohl",
                "countryCode": "CH"
            },
            {
                "name": "Küssnacht",
                "countryCode": "CH"
            },
            {
                "name": "Lachen",
                "countryCode": "CH"
            },
            {
                "name": "Muotathal",
                "countryCode": "CH"
            },
            {
                "name": "Pfäffikon",
                "countryCode": "CH"
            },
            {
                "name": "Reichenburg",
                "countryCode": "CH"
            },
            {
                "name": "Rothenthurm",
                "countryCode": "CH"
            },
            {
                "name": "Sattel",
                "countryCode": "CH"
            },
            {
                "name": "Schindellegi",
                "countryCode": "CH"
            },
            {
                "name": "Schwyz",
                "countryCode": "CH"
            },
            {
                "name": "Schübelbach",
                "countryCode": "CH"
            },
            {
                "name": "Siebnen",
                "countryCode": "CH"
            },
            {
                "name": "Steinen",
                "countryCode": "CH"
            },
            {
                "name": "Tuggen",
                "countryCode": "CH"
            },
            {
                "name": "Unteriberg",
                "countryCode": "CH"
            },
            {
                "name": "Vorderthal",
                "countryCode": "CH"
            },
            {
                "name": "Wollerau",
                "countryCode": "CH"
            },
            {
                "name": "Aadorf",
                "countryCode": "CH"
            },
            {
                "name": "Affeltrangen",
                "countryCode": "CH"
            },
            {
                "name": "Altnau",
                "countryCode": "CH"
            },
            {
                "name": "Amriswil",
                "countryCode": "CH"
            },
            {
                "name": "Arbon",
                "countryCode": "CH"
            },
            {
                "name": "Arbon District",
                "countryCode": "CH"
            },
            {
                "name": "Berg",
                "countryCode": "CH"
            },
            {
                "name": "Bottighofen",
                "countryCode": "CH"
            },
            {
                "name": "Bürglen",
                "countryCode": "CH"
            },
            {
                "name": "Diessenhofen",
                "countryCode": "CH"
            },
            {
                "name": "Egnach",
                "countryCode": "CH"
            },
            {
                "name": "Erlen",
                "countryCode": "CH"
            },
            {
                "name": "Ermatingen",
                "countryCode": "CH"
            },
            {
                "name": "Eschenz",
                "countryCode": "CH"
            },
            {
                "name": "Eschlikon",
                "countryCode": "CH"
            },
            {
                "name": "Fischingen",
                "countryCode": "CH"
            },
            {
                "name": "Frauenfeld",
                "countryCode": "CH"
            },
            {
                "name": "Frauenfeld District",
                "countryCode": "CH"
            },
            {
                "name": "Gachnang",
                "countryCode": "CH"
            },
            {
                "name": "Gottlieben",
                "countryCode": "CH"
            },
            {
                "name": "Güttingen",
                "countryCode": "CH"
            },
            {
                "name": "Homburg",
                "countryCode": "CH"
            },
            {
                "name": "Hüttwilen",
                "countryCode": "CH"
            },
            {
                "name": "Kreuzlingen",
                "countryCode": "CH"
            },
            {
                "name": "Kreuzlingen District",
                "countryCode": "CH"
            },
            {
                "name": "Langrickenbach",
                "countryCode": "CH"
            },
            {
                "name": "Matzingen",
                "countryCode": "CH"
            },
            {
                "name": "Märstetten-Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Müllheim",
                "countryCode": "CH"
            },
            {
                "name": "Münchwilen",
                "countryCode": "CH"
            },
            {
                "name": "Münchwilen District",
                "countryCode": "CH"
            },
            {
                "name": "Münsterlingen",
                "countryCode": "CH"
            },
            {
                "name": "Pfyn",
                "countryCode": "CH"
            },
            {
                "name": "Rickenbach bei Wil",
                "countryCode": "CH"
            },
            {
                "name": "Roggwil",
                "countryCode": "CH"
            },
            {
                "name": "Romanshorn",
                "countryCode": "CH"
            },
            {
                "name": "Salmsach",
                "countryCode": "CH"
            },
            {
                "name": "Sirnach",
                "countryCode": "CH"
            },
            {
                "name": "Steckborn",
                "countryCode": "CH"
            },
            {
                "name": "Stettfurt",
                "countryCode": "CH"
            },
            {
                "name": "Sulgen",
                "countryCode": "CH"
            },
            {
                "name": "Thundorf",
                "countryCode": "CH"
            },
            {
                "name": "Tägerwilen",
                "countryCode": "CH"
            },
            {
                "name": "Uttwil",
                "countryCode": "CH"
            },
            {
                "name": "Wagenhausen",
                "countryCode": "CH"
            },
            {
                "name": "Weinfelden",
                "countryCode": "CH"
            },
            {
                "name": "Weinfelden District",
                "countryCode": "CH"
            },
            {
                "name": "Wigoltingen",
                "countryCode": "CH"
            },
            {
                "name": "Wängi",
                "countryCode": "CH"
            },
            {
                "name": "Acquarossa",
                "countryCode": "CH"
            },
            {
                "name": "Agno",
                "countryCode": "CH"
            },
            {
                "name": "Airolo",
                "countryCode": "CH"
            },
            {
                "name": "Arzo",
                "countryCode": "CH"
            },
            {
                "name": "Ascona",
                "countryCode": "CH"
            },
            {
                "name": "Balerna",
                "countryCode": "CH"
            },
            {
                "name": "Bellinzona",
                "countryCode": "CH"
            },
            {
                "name": "Bellinzona District",
                "countryCode": "CH"
            },
            {
                "name": "Biasca",
                "countryCode": "CH"
            },
            {
                "name": "Bioggio",
                "countryCode": "CH"
            },
            {
                "name": "Blenio District",
                "countryCode": "CH"
            },
            {
                "name": "Bodio",
                "countryCode": "CH"
            },
            {
                "name": "Brissago",
                "countryCode": "CH"
            },
            {
                "name": "Cadempino",
                "countryCode": "CH"
            },
            {
                "name": "Cadenazzo",
                "countryCode": "CH"
            },
            {
                "name": "Cadro",
                "countryCode": "CH"
            },
            {
                "name": "Camorino",
                "countryCode": "CH"
            },
            {
                "name": "Canobbio",
                "countryCode": "CH"
            },
            {
                "name": "Caslano",
                "countryCode": "CH"
            },
            {
                "name": "Castel San Pietro",
                "countryCode": "CH"
            },
            {
                "name": "Cevio",
                "countryCode": "CH"
            },
            {
                "name": "Chiasso",
                "countryCode": "CH"
            },
            {
                "name": "Claro",
                "countryCode": "CH"
            },
            {
                "name": "Comano",
                "countryCode": "CH"
            },
            {
                "name": "Cugnasco",
                "countryCode": "CH"
            },
            {
                "name": "Faido",
                "countryCode": "CH"
            },
            {
                "name": "Giubiasco",
                "countryCode": "CH"
            },
            {
                "name": "Gordola",
                "countryCode": "CH"
            },
            {
                "name": "Gravesano",
                "countryCode": "CH"
            },
            {
                "name": "Lavertezzo",
                "countryCode": "CH"
            },
            {
                "name": "Leventina District",
                "countryCode": "CH"
            },
            {
                "name": "Ligornetto",
                "countryCode": "CH"
            },
            {
                "name": "Locarno",
                "countryCode": "CH"
            },
            {
                "name": "Locarno District",
                "countryCode": "CH"
            },
            {
                "name": "Lodrino",
                "countryCode": "CH"
            },
            {
                "name": "Losone",
                "countryCode": "CH"
            },
            {
                "name": "Lugano",
                "countryCode": "CH"
            },
            {
                "name": "Lugano District",
                "countryCode": "CH"
            },
            {
                "name": "Lumino",
                "countryCode": "CH"
            },
            {
                "name": "Magadino",
                "countryCode": "CH"
            },
            {
                "name": "Malvaglia",
                "countryCode": "CH"
            },
            {
                "name": "Massagno",
                "countryCode": "CH"
            },
            {
                "name": "Melano",
                "countryCode": "CH"
            },
            {
                "name": "Melide",
                "countryCode": "CH"
            },
            {
                "name": "Mendrisio",
                "countryCode": "CH"
            },
            {
                "name": "Mendrisio District",
                "countryCode": "CH"
            },
            {
                "name": "Minusio",
                "countryCode": "CH"
            },
            {
                "name": "Montagnola",
                "countryCode": "CH"
            },
            {
                "name": "Monte Carasso",
                "countryCode": "CH"
            },
            {
                "name": "Morbio Inferiore",
                "countryCode": "CH"
            },
            {
                "name": "Muralto",
                "countryCode": "CH"
            },
            {
                "name": "Novazzano",
                "countryCode": "CH"
            },
            {
                "name": "Pregassona",
                "countryCode": "CH"
            },
            {
                "name": "Pura",
                "countryCode": "CH"
            },
            {
                "name": "Riva San Vitale",
                "countryCode": "CH"
            },
            {
                "name": "Riviera District",
                "countryCode": "CH"
            },
            {
                "name": "Sementina",
                "countryCode": "CH"
            },
            {
                "name": "Sorengo",
                "countryCode": "CH"
            },
            {
                "name": "Stabio",
                "countryCode": "CH"
            },
            {
                "name": "Tesserete",
                "countryCode": "CH"
            },
            {
                "name": "Vallemaggia District",
                "countryCode": "CH"
            },
            {
                "name": "Verscio",
                "countryCode": "CH"
            },
            {
                "name": "Viganello",
                "countryCode": "CH"
            },
            {
                "name": "Altdorf",
                "countryCode": "CH"
            },
            {
                "name": "Andermatt",
                "countryCode": "CH"
            },
            {
                "name": "Attinghausen",
                "countryCode": "CH"
            },
            {
                "name": "Bauen",
                "countryCode": "CH"
            },
            {
                "name": "Bürglen",
                "countryCode": "CH"
            },
            {
                "name": "Erstfeld",
                "countryCode": "CH"
            },
            {
                "name": "Flüelen",
                "countryCode": "CH"
            },
            {
                "name": "Schattdorf",
                "countryCode": "CH"
            },
            {
                "name": "Seedorf",
                "countryCode": "CH"
            },
            {
                "name": "Silenen",
                "countryCode": "CH"
            },
            {
                "name": "Aigle",
                "countryCode": "CH"
            },
            {
                "name": "Aigle District",
                "countryCode": "CH"
            },
            {
                "name": "Apples",
                "countryCode": "CH"
            },
            {
                "name": "Arzier",
                "countryCode": "CH"
            },
            {
                "name": "Aubonne",
                "countryCode": "CH"
            },
            {
                "name": "Avenches",
                "countryCode": "CH"
            },
            {
                "name": "Ballens",
                "countryCode": "CH"
            },
            {
                "name": "Bavois",
                "countryCode": "CH"
            },
            {
                "name": "Begnins",
                "countryCode": "CH"
            },
            {
                "name": "Belmont-sur-Lausanne",
                "countryCode": "CH"
            },
            {
                "name": "Berolle",
                "countryCode": "CH"
            },
            {
                "name": "Bex",
                "countryCode": "CH"
            },
            {
                "name": "Bière",
                "countryCode": "CH"
            },
            {
                "name": "Blonay",
                "countryCode": "CH"
            },
            {
                "name": "Blécherette",
                "countryCode": "CH"
            },
            {
                "name": "Bottens",
                "countryCode": "CH"
            },
            {
                "name": "Broye-Vully District",
                "countryCode": "CH"
            },
            {
                "name": "Bussigny",
                "countryCode": "CH"
            },
            {
                "name": "Caux",
                "countryCode": "CH"
            },
            {
                "name": "Chardonne",
                "countryCode": "CH"
            },
            {
                "name": "Chavannes",
                "countryCode": "CH"
            },
            {
                "name": "Chavannes-le-Veyron",
                "countryCode": "CH"
            },
            {
                "name": "Chavornay",
                "countryCode": "CH"
            },
            {
                "name": "Cheseaux",
                "countryCode": "CH"
            },
            {
                "name": "Chevilly",
                "countryCode": "CH"
            },
            {
                "name": "Chexbres",
                "countryCode": "CH"
            },
            {
                "name": "Château-d'Oex",
                "countryCode": "CH"
            },
            {
                "name": "Colombier",
                "countryCode": "CH"
            },
            {
                "name": "Coppet",
                "countryCode": "CH"
            },
            {
                "name": "Cossonay",
                "countryCode": "CH"
            },
            {
                "name": "Crissier",
                "countryCode": "CH"
            },
            {
                "name": "Cuarnens",
                "countryCode": "CH"
            },
            {
                "name": "Cully",
                "countryCode": "CH"
            },
            {
                "name": "Echallens",
                "countryCode": "CH"
            },
            {
                "name": "Ecublens",
                "countryCode": "CH"
            },
            {
                "name": "Epalinges",
                "countryCode": "CH"
            },
            {
                "name": "Ferreyres",
                "countryCode": "CH"
            },
            {
                "name": "Founex",
                "countryCode": "CH"
            },
            {
                "name": "Froideville",
                "countryCode": "CH"
            },
            {
                "name": "Genolier",
                "countryCode": "CH"
            },
            {
                "name": "Gimel",
                "countryCode": "CH"
            },
            {
                "name": "Gland",
                "countryCode": "CH"
            },
            {
                "name": "Grancy",
                "countryCode": "CH"
            },
            {
                "name": "Grandson",
                "countryCode": "CH"
            },
            {
                "name": "Gros-de-Vaud District",
                "countryCode": "CH"
            },
            {
                "name": "Gryon",
                "countryCode": "CH"
            },
            {
                "name": "Jongny",
                "countryCode": "CH"
            },
            {
                "name": "Jura-Nord vaudois District",
                "countryCode": "CH"
            },
            {
                "name": "La Sarraz",
                "countryCode": "CH"
            },
            {
                "name": "La Tour-de-Peilz",
                "countryCode": "CH"
            },
            {
                "name": "Lausanne",
                "countryCode": "CH"
            },
            {
                "name": "Lausanne District",
                "countryCode": "CH"
            },
            {
                "name": "Lavaux-Oron District",
                "countryCode": "CH"
            },
            {
                "name": "Le Chenit",
                "countryCode": "CH"
            },
            {
                "name": "Le Mont-sur-Lausanne",
                "countryCode": "CH"
            },
            {
                "name": "Le Vaud",
                "countryCode": "CH"
            },
            {
                "name": "Leysin",
                "countryCode": "CH"
            },
            {
                "name": "Lucens",
                "countryCode": "CH"
            },
            {
                "name": "Lutry",
                "countryCode": "CH"
            },
            {
                "name": "Mauraz",
                "countryCode": "CH"
            },
            {
                "name": "Mollens",
                "countryCode": "CH"
            },
            {
                "name": "Montagny",
                "countryCode": "CH"
            },
            {
                "name": "Montreux",
                "countryCode": "CH"
            },
            {
                "name": "Morges",
                "countryCode": "CH"
            },
            {
                "name": "Morges District",
                "countryCode": "CH"
            },
            {
                "name": "Moudon",
                "countryCode": "CH"
            },
            {
                "name": "Nyon",
                "countryCode": "CH"
            },
            {
                "name": "Nyon District",
                "countryCode": "CH"
            },
            {
                "name": "Ollon",
                "countryCode": "CH"
            },
            {
                "name": "Orbe",
                "countryCode": "CH"
            },
            {
                "name": "Oron-la-Ville",
                "countryCode": "CH"
            },
            {
                "name": "Ouest Lausannois District",
                "countryCode": "CH"
            },
            {
                "name": "Pailly",
                "countryCode": "CH"
            },
            {
                "name": "Palézieux",
                "countryCode": "CH"
            },
            {
                "name": "Pampigny",
                "countryCode": "CH"
            },
            {
                "name": "Paudex",
                "countryCode": "CH"
            },
            {
                "name": "Payerne",
                "countryCode": "CH"
            },
            {
                "name": "Penthalaz",
                "countryCode": "CH"
            },
            {
                "name": "Penthéréaz",
                "countryCode": "CH"
            },
            {
                "name": "Perroy",
                "countryCode": "CH"
            },
            {
                "name": "Pompaples",
                "countryCode": "CH"
            },
            {
                "name": "Prangins",
                "countryCode": "CH"
            },
            {
                "name": "Prilly",
                "countryCode": "CH"
            },
            {
                "name": "Préverenges",
                "countryCode": "CH"
            },
            {
                "name": "Puidoux",
                "countryCode": "CH"
            },
            {
                "name": "Pully",
                "countryCode": "CH"
            },
            {
                "name": "Renens",
                "countryCode": "CH"
            },
            {
                "name": "Riviera-Pays-d'Enhaut District",
                "countryCode": "CH"
            },
            {
                "name": "Rolle",
                "countryCode": "CH"
            },
            {
                "name": "Romanel-sur-Lausanne",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Cergue",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Livres",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Prex",
                "countryCode": "CH"
            },
            {
                "name": "Sainte-Croix",
                "countryCode": "CH"
            },
            {
                "name": "Saubraz",
                "countryCode": "CH"
            },
            {
                "name": "Savigny",
                "countryCode": "CH"
            },
            {
                "name": "Sottens",
                "countryCode": "CH"
            },
            {
                "name": "Trélex",
                "countryCode": "CH"
            },
            {
                "name": "Vallorbe",
                "countryCode": "CH"
            },
            {
                "name": "Vevey",
                "countryCode": "CH"
            },
            {
                "name": "Villars-sur-Ollon",
                "countryCode": "CH"
            },
            {
                "name": "Villeneuve",
                "countryCode": "CH"
            },
            {
                "name": "Vuarrens",
                "countryCode": "CH"
            },
            {
                "name": "Yverdon-les-Bains",
                "countryCode": "CH"
            },
            {
                "name": "Yvonand",
                "countryCode": "CH"
            },
            {
                "name": "Ardon",
                "countryCode": "CH"
            },
            {
                "name": "Ayent",
                "countryCode": "CH"
            },
            {
                "name": "Bagnes",
                "countryCode": "CH"
            },
            {
                "name": "Baltschieder",
                "countryCode": "CH"
            },
            {
                "name": "Basse-Nendaz",
                "countryCode": "CH"
            },
            {
                "name": "Brig",
                "countryCode": "CH"
            },
            {
                "name": "Brig District",
                "countryCode": "CH"
            },
            {
                "name": "Chalais",
                "countryCode": "CH"
            },
            {
                "name": "Chamoson",
                "countryCode": "CH"
            },
            {
                "name": "Champéry",
                "countryCode": "CH"
            },
            {
                "name": "Charrat",
                "countryCode": "CH"
            },
            {
                "name": "Chermignon-d’en Haut",
                "countryCode": "CH"
            },
            {
                "name": "Chippis",
                "countryCode": "CH"
            },
            {
                "name": "Collombey",
                "countryCode": "CH"
            },
            {
                "name": "Conthey",
                "countryCode": "CH"
            },
            {
                "name": "Conthey District",
                "countryCode": "CH"
            },
            {
                "name": "Entremont District",
                "countryCode": "CH"
            },
            {
                "name": "Evionnaz",
                "countryCode": "CH"
            },
            {
                "name": "Evolène",
                "countryCode": "CH"
            },
            {
                "name": "Fiesch",
                "countryCode": "CH"
            },
            {
                "name": "Fully",
                "countryCode": "CH"
            },
            {
                "name": "Gampel",
                "countryCode": "CH"
            },
            {
                "name": "Goms District",
                "countryCode": "CH"
            },
            {
                "name": "Grimisuat",
                "countryCode": "CH"
            },
            {
                "name": "Grächen",
                "countryCode": "CH"
            },
            {
                "name": "Grône",
                "countryCode": "CH"
            },
            {
                "name": "Hérens District",
                "countryCode": "CH"
            },
            {
                "name": "Hérémence",
                "countryCode": "CH"
            },
            {
                "name": "Lens",
                "countryCode": "CH"
            },
            {
                "name": "Leuk",
                "countryCode": "CH"
            },
            {
                "name": "Leuk District",
                "countryCode": "CH"
            },
            {
                "name": "Leukerbad",
                "countryCode": "CH"
            },
            {
                "name": "Leytron",
                "countryCode": "CH"
            },
            {
                "name": "Martigny District",
                "countryCode": "CH"
            },
            {
                "name": "Martigny-Combe",
                "countryCode": "CH"
            },
            {
                "name": "Martigny-Ville",
                "countryCode": "CH"
            },
            {
                "name": "Montana",
                "countryCode": "CH"
            },
            {
                "name": "Monthey",
                "countryCode": "CH"
            },
            {
                "name": "Monthey District",
                "countryCode": "CH"
            },
            {
                "name": "Naters",
                "countryCode": "CH"
            },
            {
                "name": "Orsières",
                "countryCode": "CH"
            },
            {
                "name": "Randogne",
                "countryCode": "CH"
            },
            {
                "name": "Raron",
                "countryCode": "CH"
            },
            {
                "name": "Raron District",
                "countryCode": "CH"
            },
            {
                "name": "Riddes",
                "countryCode": "CH"
            },
            {
                "name": "Saas-Fee",
                "countryCode": "CH"
            },
            {
                "name": "Saas-Grund",
                "countryCode": "CH"
            },
            {
                "name": "Saillon",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Léonard",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Maurice",
                "countryCode": "CH"
            },
            {
                "name": "Saint-Maurice District",
                "countryCode": "CH"
            },
            {
                "name": "Salgesch",
                "countryCode": "CH"
            },
            {
                "name": "Salvan",
                "countryCode": "CH"
            },
            {
                "name": "Sankt Niklaus",
                "countryCode": "CH"
            },
            {
                "name": "Savièse",
                "countryCode": "CH"
            },
            {
                "name": "Saxon",
                "countryCode": "CH"
            },
            {
                "name": "Sierre",
                "countryCode": "CH"
            },
            {
                "name": "Sierre District",
                "countryCode": "CH"
            },
            {
                "name": "Sion District",
                "countryCode": "CH"
            },
            {
                "name": "Sitten",
                "countryCode": "CH"
            },
            {
                "name": "Stalden",
                "countryCode": "CH"
            },
            {
                "name": "Tanay",
                "countryCode": "CH"
            },
            {
                "name": "Troistorrents",
                "countryCode": "CH"
            },
            {
                "name": "Turtmann",
                "countryCode": "CH"
            },
            {
                "name": "Varen",
                "countryCode": "CH"
            },
            {
                "name": "Verbier",
                "countryCode": "CH"
            },
            {
                "name": "Vernayaz",
                "countryCode": "CH"
            },
            {
                "name": "Vex",
                "countryCode": "CH"
            },
            {
                "name": "Vionnaz",
                "countryCode": "CH"
            },
            {
                "name": "Visp",
                "countryCode": "CH"
            },
            {
                "name": "Visp District",
                "countryCode": "CH"
            },
            {
                "name": "Visperterminen",
                "countryCode": "CH"
            },
            {
                "name": "Vouvry",
                "countryCode": "CH"
            },
            {
                "name": "Vétroz",
                "countryCode": "CH"
            },
            {
                "name": "Zermatt",
                "countryCode": "CH"
            },
            {
                "name": "Baar",
                "countryCode": "CH"
            },
            {
                "name": "Cham",
                "countryCode": "CH"
            },
            {
                "name": "Hünenberg",
                "countryCode": "CH"
            },
            {
                "name": "Menzingen",
                "countryCode": "CH"
            },
            {
                "name": "Rotkreuz",
                "countryCode": "CH"
            },
            {
                "name": "Steinhausen",
                "countryCode": "CH"
            },
            {
                "name": "Unterägeri",
                "countryCode": "CH"
            },
            {
                "name": "Walchwil",
                "countryCode": "CH"
            },
            {
                "name": "Zug",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Adliswil (Stadtkern)",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Hündli-Zopf",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Oberleimbach",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Sonnenberg",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Sood",
                "countryCode": "CH"
            },
            {
                "name": "Adliswil / Tal",
                "countryCode": "CH"
            },
            {
                "name": "Aesch",
                "countryCode": "CH"
            },
            {
                "name": "Affoltern / Hasenbüel",
                "countryCode": "CH"
            },
            {
                "name": "Affoltern / Oberdorf",
                "countryCode": "CH"
            },
            {
                "name": "Affoltern / Sonnenberg",
                "countryCode": "CH"
            },
            {
                "name": "Affoltern / Unterdorf",
                "countryCode": "CH"
            },
            {
                "name": "Affoltern am Albis",
                "countryCode": "CH"
            },
            {
                "name": "Andelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Au",
                "countryCode": "CH"
            },
            {
                "name": "Au / Mittel-Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Au / Unter-Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Bachenbülach",
                "countryCode": "CH"
            },
            {
                "name": "Bassersdorf",
                "countryCode": "CH"
            },
            {
                "name": "Bauma",
                "countryCode": "CH"
            },
            {
                "name": "Benglen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Affoltern",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Andelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Bülach",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Dielsdorf",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Dietikon",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Hinwil",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Horgen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Meilen",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Pfäffikon",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Uster",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Winterthur",
                "countryCode": "CH"
            },
            {
                "name": "Bezirk Zürich",
                "countryCode": "CH"
            },
            {
                "name": "Binz",
                "countryCode": "CH"
            },
            {
                "name": "Binzikon",
                "countryCode": "CH"
            },
            {
                "name": "Birchwil",
                "countryCode": "CH"
            },
            {
                "name": "Birmensdorf",
                "countryCode": "CH"
            },
            {
                "name": "Bonstetten",
                "countryCode": "CH"
            },
            {
                "name": "Brütten",
                "countryCode": "CH"
            },
            {
                "name": "Brüttisellen",
                "countryCode": "CH"
            },
            {
                "name": "Bubikon",
                "countryCode": "CH"
            },
            {
                "name": "Bäretswil",
                "countryCode": "CH"
            },
            {
                "name": "Bülach",
                "countryCode": "CH"
            },
            {
                "name": "Bülach / Gstückt",
                "countryCode": "CH"
            },
            {
                "name": "Bülach / Seematt",
                "countryCode": "CH"
            },
            {
                "name": "Bülach / Soligänter",
                "countryCode": "CH"
            },
            {
                "name": "Dachsen",
                "countryCode": "CH"
            },
            {
                "name": "Dielsdorf",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Almend",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Guggenbühl",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Hofacker",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Kreuzacker",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Oberdorf",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Schönenwerd",
                "countryCode": "CH"
            },
            {
                "name": "Dietikon / Vorstadt",
                "countryCode": "CH"
            },
            {
                "name": "Dietlikon / Dietlikon (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Dietlikon / Eichwiesen",
                "countryCode": "CH"
            },
            {
                "name": "Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Dällikon / Dällikon (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Dänikon",
                "countryCode": "CH"
            },
            {
                "name": "Dübendorf",
                "countryCode": "CH"
            },
            {
                "name": "Dübendorf / Kunklerstrasse",
                "countryCode": "CH"
            },
            {
                "name": "Dübendorf / Sonnenberg",
                "countryCode": "CH"
            },
            {
                "name": "Dübendorf / Vogelquartier",
                "countryCode": "CH"
            },
            {
                "name": "Dübendorf / Wasserfurren",
                "countryCode": "CH"
            },
            {
                "name": "Dürnten",
                "countryCode": "CH"
            },
            {
                "name": "Ebmatingen",
                "countryCode": "CH"
            },
            {
                "name": "Effretikon",
                "countryCode": "CH"
            },
            {
                "name": "Effretikon / Rappenhalde-Bannhalde",
                "countryCode": "CH"
            },
            {
                "name": "Effretikon / Rikon",
                "countryCode": "CH"
            },
            {
                "name": "Effretikon / Watt",
                "countryCode": "CH"
            },
            {
                "name": "Egg",
                "countryCode": "CH"
            },
            {
                "name": "Elgg",
                "countryCode": "CH"
            },
            {
                "name": "Elgg / Städtchen und Umgebung",
                "countryCode": "CH"
            },
            {
                "name": "Elsau-Räterschen",
                "countryCode": "CH"
            },
            {
                "name": "Elsau-Räterschen / Räterschen",
                "countryCode": "CH"
            },
            {
                "name": "Embrach",
                "countryCode": "CH"
            },
            {
                "name": "Embrach / Embrach (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Embrach / Kellersacker",
                "countryCode": "CH"
            },
            {
                "name": "Erlenbach",
                "countryCode": "CH"
            },
            {
                "name": "Erlenbach / links des Dorfbachs oberhalb Bahnlinie",
                "countryCode": "CH"
            },
            {
                "name": "Erlenbach / rechts des Dorfbachs oberhalb Bahnlinie",
                "countryCode": "CH"
            },
            {
                "name": "Esslingen",
                "countryCode": "CH"
            },
            {
                "name": "Fehraltorf",
                "countryCode": "CH"
            },
            {
                "name": "Feldmeilen",
                "countryCode": "CH"
            },
            {
                "name": "Feuerthalen",
                "countryCode": "CH"
            },
            {
                "name": "Flurlingen",
                "countryCode": "CH"
            },
            {
                "name": "Freienstein",
                "countryCode": "CH"
            },
            {
                "name": "Fällanden",
                "countryCode": "CH"
            },
            {
                "name": "Gattikon",
                "countryCode": "CH"
            },
            {
                "name": "Geroldswil",
                "countryCode": "CH"
            },
            {
                "name": "Glattbrugg",
                "countryCode": "CH"
            },
            {
                "name": "Glattbrugg / Rohr/Platten-Balsberg",
                "countryCode": "CH"
            },
            {
                "name": "Glattbrugg / Wydacker/Bettacker/Lättenwiesen",
                "countryCode": "CH"
            },
            {
                "name": "Glattfelden",
                "countryCode": "CH"
            },
            {
                "name": "Gockhausen",
                "countryCode": "CH"
            },
            {
                "name": "Gossau",
                "countryCode": "CH"
            },
            {
                "name": "Greifensee",
                "countryCode": "CH"
            },
            {
                "name": "Greifensee / Müllerwis / Seilerwis",
                "countryCode": "CH"
            },
            {
                "name": "Greifensee / Pfisterhölzli",
                "countryCode": "CH"
            },
            {
                "name": "Grossacker/Opfikon",
                "countryCode": "CH"
            },
            {
                "name": "Grüt",
                "countryCode": "CH"
            },
            {
                "name": "Gutenswil",
                "countryCode": "CH"
            },
            {
                "name": "Hadlikon",
                "countryCode": "CH"
            },
            {
                "name": "Hausen am Albis / Hausen (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Hedingen",
                "countryCode": "CH"
            },
            {
                "name": "Hegnau",
                "countryCode": "CH"
            },
            {
                "name": "Hegnau / Dammboden-Grindel",
                "countryCode": "CH"
            },
            {
                "name": "Hegnau / Sunnebüel-Eich",
                "countryCode": "CH"
            },
            {
                "name": "Henggart",
                "countryCode": "CH"
            },
            {
                "name": "Herrliberg",
                "countryCode": "CH"
            },
            {
                "name": "Hettlingen",
                "countryCode": "CH"
            },
            {
                "name": "Hinteregg",
                "countryCode": "CH"
            },
            {
                "name": "Hinwil",
                "countryCode": "CH"
            },
            {
                "name": "Hittnau / Hittnau (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Hochfelden",
                "countryCode": "CH"
            },
            {
                "name": "Hofstetten",
                "countryCode": "CH"
            },
            {
                "name": "Hombrechtikon",
                "countryCode": "CH"
            },
            {
                "name": "Horgen",
                "countryCode": "CH"
            },
            {
                "name": "Horgen / Allmend",
                "countryCode": "CH"
            },
            {
                "name": "Horgen / Horgen (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Horgen / Oberdorf",
                "countryCode": "CH"
            },
            {
                "name": "Horgen / Scheller-Stockerstrasse",
                "countryCode": "CH"
            },
            {
                "name": "Höri",
                "countryCode": "CH"
            },
            {
                "name": "Illnau",
                "countryCode": "CH"
            },
            {
                "name": "Illnau / Unter-Illnau",
                "countryCode": "CH"
            },
            {
                "name": "Kilchberg",
                "countryCode": "CH"
            },
            {
                "name": "Kilchberg / Bächler-Stocken",
                "countryCode": "CH"
            },
            {
                "name": "Kilchberg / Kilchberg (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Kindhausen / Kindhausen (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Kleinandelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Kloten",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Balsberg",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Freienberg (Chanzler-Chlini Chaseren)",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Geissberg",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Holberg",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Horainli",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Hostrass",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Kloten (Zentrum)",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Rütlen",
                "countryCode": "CH"
            },
            {
                "name": "Kloten / Spitz",
                "countryCode": "CH"
            },
            {
                "name": "Knonau",
                "countryCode": "CH"
            },
            {
                "name": "Kollbrunn",
                "countryCode": "CH"
            },
            {
                "name": "Kollbrunn / Kollbrunn (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht / Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht / Goldbach",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht / Heslibach",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht / Itschnach",
                "countryCode": "CH"
            },
            {
                "name": "Küsnacht / Schiedhalden",
                "countryCode": "CH"
            },
            {
                "name": "Langnau / Langnau (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Langnau / Vitaquartier",
                "countryCode": "CH"
            },
            {
                "name": "Langnau am Albis",
                "countryCode": "CH"
            },
            {
                "name": "Marthalen",
                "countryCode": "CH"
            },
            {
                "name": "Mattenbach (Kreis 7)",
                "countryCode": "CH"
            },
            {
                "name": "Mattenbach (Kreis 7) / Deutweg",
                "countryCode": "CH"
            },
            {
                "name": "Mattenbach (Kreis 7) / Endliker",
                "countryCode": "CH"
            },
            {
                "name": "Mattenbach (Kreis 7) / Gutschick",
                "countryCode": "CH"
            },
            {
                "name": "Meilen",
                "countryCode": "CH"
            },
            {
                "name": "Mettmenstetten",
                "countryCode": "CH"
            },
            {
                "name": "Männedorf",
                "countryCode": "CH"
            },
            {
                "name": "Männedorf / Ausserfeld",
                "countryCode": "CH"
            },
            {
                "name": "Männedorf / Dorfkern",
                "countryCode": "CH"
            },
            {
                "name": "Mönchaltorf",
                "countryCode": "CH"
            },
            {
                "name": "Mönchaltorf / Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Mühlehalde",
                "countryCode": "CH"
            },
            {
                "name": "Neerach",
                "countryCode": "CH"
            },
            {
                "name": "Neftenbach / Dorf Neftenbach",
                "countryCode": "CH"
            },
            {
                "name": "Niederglatt",
                "countryCode": "CH"
            },
            {
                "name": "Niederglatt / Niederglatt (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Niederhasli",
                "countryCode": "CH"
            },
            {
                "name": "Nänikon",
                "countryCode": "CH"
            },
            {
                "name": "Nänikon / Nänikon (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Nürensdorf",
                "countryCode": "CH"
            },
            {
                "name": "Oberengstringen",
                "countryCode": "CH"
            },
            {
                "name": "Oberengstringen / Rauchacher",
                "countryCode": "CH"
            },
            {
                "name": "Oberengstringen / Sonnenberg",
                "countryCode": "CH"
            },
            {
                "name": "Oberengstringen / Zentrum",
                "countryCode": "CH"
            },
            {
                "name": "Oberglatt",
                "countryCode": "CH"
            },
            {
                "name": "Oberglatt / Bahnhofquartier",
                "countryCode": "CH"
            },
            {
                "name": "Oberglatt / Oberglatt (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Obermeilen",
                "countryCode": "CH"
            },
            {
                "name": "Oberrieden",
                "countryCode": "CH"
            },
            {
                "name": "Oberrieden / Berg",
                "countryCode": "CH"
            },
            {
                "name": "Oberrieden / Mitte",
                "countryCode": "CH"
            },
            {
                "name": "Oberwinterthur (Kreis 2)",
                "countryCode": "CH"
            },
            {
                "name": "Oberwinterthur (Kreis 2) / Guggenbühl",
                "countryCode": "CH"
            },
            {
                "name": "Oberwinterthur (Kreis 2) / Hegi",
                "countryCode": "CH"
            },
            {
                "name": "Oberwinterthur (Kreis 2) / Talacker",
                "countryCode": "CH"
            },
            {
                "name": "Oberwinterthur (Kreis 2) / Zinzikon",
                "countryCode": "CH"
            },
            {
                "name": "Obfelden",
                "countryCode": "CH"
            },
            {
                "name": "Obfelden / Oberlunnern",
                "countryCode": "CH"
            },
            {
                "name": "Obfelden / Toussen",
                "countryCode": "CH"
            },
            {
                "name": "Oetwil",
                "countryCode": "CH"
            },
            {
                "name": "Opfikon",
                "countryCode": "CH"
            },
            {
                "name": "Otelfingen",
                "countryCode": "CH"
            },
            {
                "name": "Ottenbach",
                "countryCode": "CH"
            },
            {
                "name": "Pfaffhausen",
                "countryCode": "CH"
            },
            {
                "name": "Pfungen",
                "countryCode": "CH"
            },
            {
                "name": "Pfäffikon",
                "countryCode": "CH"
            },
            {
                "name": "Pfäffikon / Irgenhausen",
                "countryCode": "CH"
            },
            {
                "name": "Pfäffikon / Pfäffikon (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Rafz",
                "countryCode": "CH"
            },
            {
                "name": "Regensdorf",
                "countryCode": "CH"
            },
            {
                "name": "Regensdorf / Feldblumen-Riedthofstrasse",
                "countryCode": "CH"
            },
            {
                "name": "Regensdorf / Hofacher-Geeren",
                "countryCode": "CH"
            },
            {
                "name": "Regensdorf / Obstgarten",
                "countryCode": "CH"
            },
            {
                "name": "Richterswil",
                "countryCode": "CH"
            },
            {
                "name": "Richterswil / Burghalde",
                "countryCode": "CH"
            },
            {
                "name": "Richterswil / Dorfkern",
                "countryCode": "CH"
            },
            {
                "name": "Richterswil / Richterswil (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Rorbas",
                "countryCode": "CH"
            },
            {
                "name": "Russikon",
                "countryCode": "CH"
            },
            {
                "name": "Rümlang",
                "countryCode": "CH"
            },
            {
                "name": "Rümlang / Rümlang (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Rüschlikon",
                "countryCode": "CH"
            },
            {
                "name": "Rüti",
                "countryCode": "CH"
            },
            {
                "name": "Rüti / Dorfzentrum, Südl. Teil",
                "countryCode": "CH"
            },
            {
                "name": "Rüti / Oberdorf",
                "countryCode": "CH"
            },
            {
                "name": "Rüti / Westlicher Dorfteil",
                "countryCode": "CH"
            },
            {
                "name": "Samstagern",
                "countryCode": "CH"
            },
            {
                "name": "Schachen",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Boden",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Engstingerquartier",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Freiestrasse",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Kamp",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Spital",
                "countryCode": "CH"
            },
            {
                "name": "Schlieren / Zentrum",
                "countryCode": "CH"
            },
            {
                "name": "Schwerzenbach",
                "countryCode": "CH"
            },
            {
                "name": "Schwerzenbach / Blatten",
                "countryCode": "CH"
            },
            {
                "name": "Schwerzenbach / Chimli",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3)",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3) / Büelwiesen",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3) / Ganzenbühl",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3) / Oberseen",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3) / Waldegg",
                "countryCode": "CH"
            },
            {
                "name": "Seen (Kreis 3) / Waser",
                "countryCode": "CH"
            },
            {
                "name": "Seglingen",
                "countryCode": "CH"
            },
            {
                "name": "Sellenbüren",
                "countryCode": "CH"
            },
            {
                "name": "Seuzach / Seuzach (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Seuzach Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Seuzach Dorf / Breite-Weid",
                "countryCode": "CH"
            },
            {
                "name": "Sonnhalde",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1)",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Altstadt",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Brühlberg",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Heiligberg",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Lind",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Neuwiesen",
                "countryCode": "CH"
            },
            {
                "name": "Stadt Winterthur (Kreis 1) / Tössfeld",
                "countryCode": "CH"
            },
            {
                "name": "Steinmaur",
                "countryCode": "CH"
            },
            {
                "name": "Stäfa",
                "countryCode": "CH"
            },
            {
                "name": "Sulz",
                "countryCode": "CH"
            },
            {
                "name": "Tagelswangen",
                "countryCode": "CH"
            },
            {
                "name": "Tann",
                "countryCode": "CH"
            },
            {
                "name": "Tann / Tann (Dorfkern)",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil / Berg",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil / Dorfkern",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil / Nord",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil / See",
                "countryCode": "CH"
            },
            {
                "name": "Thalwil / Süd",
                "countryCode": "CH"
            },
            {
                "name": "Turbenthal",
                "countryCode": "CH"
            },
            {
                "name": "Töss (Kreis 4)",
                "countryCode": "CH"
            },
            {
                "name": "Töss (Kreis 4) / Eichliacker",
                "countryCode": "CH"
            },
            {
                "name": "Töss (Kreis 4) / Schlosstal",
                "countryCode": "CH"
            },
            {
                "name": "Töss (Kreis 4) / Vorder-Dättnau",
                "countryCode": "CH"
            },
            {
                "name": "Uerikon",
                "countryCode": "CH"
            },
            {
                "name": "Uetikon",
                "countryCode": "CH"
            },
            {
                "name": "Uetikon / Grossdorf",
                "countryCode": "CH"
            },
            {
                "name": "Uhwiesen",
                "countryCode": "CH"
            },
            {
                "name": "Uitikon",
                "countryCode": "CH"
            },
            {
                "name": "Unterengstringen",
                "countryCode": "CH"
            },
            {
                "name": "Urdorf",
                "countryCode": "CH"
            },
            {
                "name": "Urdorf / Bodenfeld",
                "countryCode": "CH"
            },
            {
                "name": "Urdorf / Moos",
                "countryCode": "CH"
            },
            {
                "name": "Urdorf / Oberurdorf",
                "countryCode": "CH"
            },
            {
                "name": "Uster",
                "countryCode": "CH"
            },
            {
                "name": "Uster / Gschwader",
                "countryCode": "CH"
            },
            {
                "name": "Uster / Kirch-Uster",
                "countryCode": "CH"
            },
            {
                "name": "Uster / Nieder-Uster",
                "countryCode": "CH"
            },
            {
                "name": "Uster / Nossikon",
                "countryCode": "CH"
            },
            {
                "name": "Uster / Ober-Uster",
                "countryCode": "CH"
            },
            {
                "name": "Veltheim (Kreis 5)",
                "countryCode": "CH"
            },
            {
                "name": "Veltheim (Kreis 5) / Blumenau",
                "countryCode": "CH"
            },
            {
                "name": "Veltheim (Kreis 5) / Rosenberg",
                "countryCode": "CH"
            },
            {
                "name": "Volketswil / Volketswil (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Wald",
                "countryCode": "CH"
            },
            {
                "name": "Wallisellen",
                "countryCode": "CH"
            },
            {
                "name": "Wallisellen / Rieden",
                "countryCode": "CH"
            },
            {
                "name": "Wallisellen / Wallisellen-Ost",
                "countryCode": "CH"
            },
            {
                "name": "Wallisellen / Wallisellen-West",
                "countryCode": "CH"
            },
            {
                "name": "Wangen",
                "countryCode": "CH"
            },
            {
                "name": "Weiningen",
                "countryCode": "CH"
            },
            {
                "name": "Weisslingen",
                "countryCode": "CH"
            },
            {
                "name": "Wettswil / Ausser-Dorf",
                "countryCode": "CH"
            },
            {
                "name": "Wettswil / Wettswil (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Wetzikon",
                "countryCode": "CH"
            },
            {
                "name": "Wetzikon / Kempten",
                "countryCode": "CH"
            },
            {
                "name": "Wetzikon / Ober-Wetzikon",
                "countryCode": "CH"
            },
            {
                "name": "Wetzikon / Robenhausen",
                "countryCode": "CH"
            },
            {
                "name": "Wetzikon / Unter-Wetzikon",
                "countryCode": "CH"
            },
            {
                "name": "Wiesendangen / Wiesendangen (Dorf)",
                "countryCode": "CH"
            },
            {
                "name": "Wila",
                "countryCode": "CH"
            },
            {
                "name": "Winterthur",
                "countryCode": "CH"
            },
            {
                "name": "Wolfhausen",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Boller-Giessen",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Büelen",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Dorf (Wädenswil)",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Eichweid",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Hangenmoos",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Leihof-Mühlebach",
                "countryCode": "CH"
            },
            {
                "name": "Wädenswil / Untermosen-Fuhr",
                "countryCode": "CH"
            },
            {
                "name": "Wülflingen (Kreis 6)",
                "countryCode": "CH"
            },
            {
                "name": "Wülflingen (Kreis 6) / Härti",
                "countryCode": "CH"
            },
            {
                "name": "Wülflingen (Kreis 6) / Lindenplatz",
                "countryCode": "CH"
            },
            {
                "name": "Wülflingen (Kreis 6) / Niederfeld",
                "countryCode": "CH"
            },
            {
                "name": "Wülflingen (Kreis 6) / Oberfeld",
                "countryCode": "CH"
            },
            {
                "name": "Zollikerberg",
                "countryCode": "CH"
            },
            {
                "name": "Zollikon",
                "countryCode": "CH"
            },
            {
                "name": "Zumikon",
                "countryCode": "CH"
            },
            {
                "name": "Zürich",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 1)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 1) / City",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 1) / Lindenhof",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 1) / Rathaus",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 10)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 10) / Höngg",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 10) / Rütihof",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 10) / Wipkingen",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 11)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 11) / Affoltern",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 11) / Oerlikon",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 11) / Schwandenholz",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 11) / Seebach",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 12)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 12) / Auzelg",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 12) / Hirzenbach",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 12) / Saatlen",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 12) / Schwamendingen-Mitte",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 2)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 2) / Enge",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 2) / Mittel-Leimbach",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 2) / Unter-Leimbach",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 2) / Wollishofen",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 3)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 3) / Alt-Wiedikon",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 3) / Friesenberg",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 3) / Sihlfeld",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 4) / Aussersihl",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 4) / Hard",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 4) / Langstrasse",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 4) / Werd",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 5)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 5) / Escher-Wyss",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 5) / Gewerbeschule",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 6)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 6) / Oberstrass",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 6) / Unterstrass",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 7)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 7) / Fluntern",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 7) / Hirslanden",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 7) / Hottingen",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 7) / Witikon",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 8)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 8) / Mühlebach",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 8) / Seefeld",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 8) / Weinegg",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 9)",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 9) / Albisrieden",
                "countryCode": "CH"
            },
            {
                "name": "Zürich (Kreis 9) / Altstetten",
                "countryCode": "CH"
            }
        ]
    },
    {
        "name": "Syria",
        "phonecode": "963",
        "cities": [
            {
                "name": "Damascus",
                "countryCode": "SY"
            },
            {
                "name": "Al Muzayrīb",
                "countryCode": "SY"
            },
            {
                "name": "Al Ḩarāk",
                "countryCode": "SY"
            },
            {
                "name": "Al-Sanamayn District",
                "countryCode": "SY"
            },
            {
                "name": "Ash Shaykh Miskīn",
                "countryCode": "SY"
            },
            {
                "name": "Aş Şanamayn",
                "countryCode": "SY"
            },
            {
                "name": "Buşrá ash Shām",
                "countryCode": "SY"
            },
            {
                "name": "Dar‘ā",
                "countryCode": "SY"
            },
            {
                "name": "Ghabāghib",
                "countryCode": "SY"
            },
            {
                "name": "Inkhil",
                "countryCode": "SY"
            },
            {
                "name": "Izra District",
                "countryCode": "SY"
            },
            {
                "name": "Izra‘",
                "countryCode": "SY"
            },
            {
                "name": "Jāsim",
                "countryCode": "SY"
            },
            {
                "name": "Nawá",
                "countryCode": "SY"
            },
            {
                "name": "Tasīl",
                "countryCode": "SY"
            },
            {
                "name": "Ţafas",
                "countryCode": "SY"
            },
            {
                "name": "Al Mayādīn",
                "countryCode": "SY"
            },
            {
                "name": "Deir ez-Zor",
                "countryCode": "SY"
            },
            {
                "name": "Hajīn",
                "countryCode": "SY"
            },
            {
                "name": "Subaykhān",
                "countryCode": "SY"
            },
            {
                "name": "Ālbū Kamāl",
                "countryCode": "SY"
            },
            {
                "name": "Ad Darbāsīyah",
                "countryCode": "SY"
            },
            {
                "name": "Al Mālikīyah",
                "countryCode": "SY"
            },
            {
                "name": "Al Qāmishlī",
                "countryCode": "SY"
            },
            {
                "name": "Al Ḩasakah",
                "countryCode": "SY"
            },
            {
                "name": "Al-Malikiyah District",
                "countryCode": "SY"
            },
            {
                "name": "Amude",
                "countryCode": "SY"
            },
            {
                "name": "Al Ghanţū",
                "countryCode": "SY"
            },
            {
                "name": "Al Qaryatayn",
                "countryCode": "SY"
            },
            {
                "name": "Al Quşayr",
                "countryCode": "SY"
            },
            {
                "name": "Al-Rastan District",
                "countryCode": "SY"
            },
            {
                "name": "Ar Rastan",
                "countryCode": "SY"
            },
            {
                "name": "Hisya",
                "countryCode": "SY"
            },
            {
                "name": "Homs",
                "countryCode": "SY"
            },
            {
                "name": "Kafr Lāhā",
                "countryCode": "SY"
            },
            {
                "name": "Mukharram al Fawqānī",
                "countryCode": "SY"
            },
            {
                "name": "Tadmur",
                "countryCode": "SY"
            },
            {
                "name": "Tadmur District",
                "countryCode": "SY"
            },
            {
                "name": "Tallbīsah",
                "countryCode": "SY"
            },
            {
                "name": "Tallkalakh",
                "countryCode": "SY"
            },
            {
                "name": "Şadad",
                "countryCode": "SY"
            },
            {
                "name": "Afrin District",
                "countryCode": "SY"
            },
            {
                "name": "Al Atārib",
                "countryCode": "SY"
            },
            {
                "name": "Al Bāb",
                "countryCode": "SY"
            },
            {
                "name": "Al-Bab District",
                "countryCode": "SY"
            },
            {
                "name": "Aleppo",
                "countryCode": "SY"
            },
            {
                "name": "As Safīrah",
                "countryCode": "SY"
            },
            {
                "name": "Azaz District",
                "countryCode": "SY"
            },
            {
                "name": "Dayr Ḩāfir",
                "countryCode": "SY"
            },
            {
                "name": "I‘zāz",
                "countryCode": "SY"
            },
            {
                "name": "Jarābulus",
                "countryCode": "SY"
            },
            {
                "name": "Kafr Şaghīr",
                "countryCode": "SY"
            },
            {
                "name": "Khanāşir",
                "countryCode": "SY"
            },
            {
                "name": "Manbij",
                "countryCode": "SY"
            },
            {
                "name": "Manbij District",
                "countryCode": "SY"
            },
            {
                "name": "Mount Simeon District",
                "countryCode": "SY"
            },
            {
                "name": "Nubl",
                "countryCode": "SY"
            },
            {
                "name": "Tall Rif‘at",
                "countryCode": "SY"
            },
            {
                "name": "Tādif",
                "countryCode": "SY"
            },
            {
                "name": "Şūrān",
                "countryCode": "SY"
            },
            {
                "name": "‘Afrīn",
                "countryCode": "SY"
            },
            {
                "name": "‘Ayn al ‘Arab",
                "countryCode": "SY"
            },
            {
                "name": "Al-Salamiyah District",
                "countryCode": "SY"
            },
            {
                "name": "As Salamīyah",
                "countryCode": "SY"
            },
            {
                "name": "As Suqaylibīyah",
                "countryCode": "SY"
            },
            {
                "name": "Hama District",
                "countryCode": "SY"
            },
            {
                "name": "Kafr Zaytā",
                "countryCode": "SY"
            },
            {
                "name": "Masyaf District",
                "countryCode": "SY"
            },
            {
                "name": "Maşyāf",
                "countryCode": "SY"
            },
            {
                "name": "Mūrak",
                "countryCode": "SY"
            },
            {
                "name": "Souran",
                "countryCode": "SY"
            },
            {
                "name": "Tall Salḩab",
                "countryCode": "SY"
            },
            {
                "name": "Tremseh",
                "countryCode": "SY"
            },
            {
                "name": "Ţayyibat al Imām",
                "countryCode": "SY"
            },
            {
                "name": "Ḩalfāyā",
                "countryCode": "SY"
            },
            {
                "name": "Ḩamāh",
                "countryCode": "SY"
            },
            {
                "name": "Ad Dānā",
                "countryCode": "SY"
            },
            {
                "name": "Armanāz",
                "countryCode": "SY"
            },
            {
                "name": "Arīḩā",
                "countryCode": "SY"
            },
            {
                "name": "Binnish",
                "countryCode": "SY"
            },
            {
                "name": "Darkūsh",
                "countryCode": "SY"
            },
            {
                "name": "Harem District",
                "countryCode": "SY"
            },
            {
                "name": "Idlib",
                "countryCode": "SY"
            },
            {
                "name": "Jisr al-Shughur District",
                "countryCode": "SY"
            },
            {
                "name": "Jisr ash Shughūr",
                "countryCode": "SY"
            },
            {
                "name": "Kafr Takhārīm",
                "countryCode": "SY"
            },
            {
                "name": "Kafranbel",
                "countryCode": "SY"
            },
            {
                "name": "Khān Shaykhūn",
                "countryCode": "SY"
            },
            {
                "name": "Maarrat al-Nu'man District",
                "countryCode": "SY"
            },
            {
                "name": "Ma‘arratmişrīn",
                "countryCode": "SY"
            },
            {
                "name": "Salqīn",
                "countryCode": "SY"
            },
            {
                "name": "Sarmīn",
                "countryCode": "SY"
            },
            {
                "name": "Sarāqib",
                "countryCode": "SY"
            },
            {
                "name": "Taftanāz",
                "countryCode": "SY"
            },
            {
                "name": "Ḩārim",
                "countryCode": "SY"
            },
            {
                "name": "Al-Haffah District",
                "countryCode": "SY"
            },
            {
                "name": "Jablah",
                "countryCode": "SY"
            },
            {
                "name": "Jableh District",
                "countryCode": "SY"
            },
            {
                "name": "Kassab",
                "countryCode": "SY"
            },
            {
                "name": "Latakia",
                "countryCode": "SY"
            },
            {
                "name": "Latakia District",
                "countryCode": "SY"
            },
            {
                "name": "Qardaha District",
                "countryCode": "SY"
            },
            {
                "name": "Şlinfah",
                "countryCode": "SY"
            },
            {
                "name": "Al Qunayţirah",
                "countryCode": "SY"
            },
            {
                "name": "Al-Thawrah District",
                "countryCode": "SY"
            },
            {
                "name": "Ar Raqqah",
                "countryCode": "SY"
            },
            {
                "name": "Ar-Raqqah District",
                "countryCode": "SY"
            },
            {
                "name": "Ath Thawrah",
                "countryCode": "SY"
            },
            {
                "name": "Tall Abyaḑ",
                "countryCode": "SY"
            },
            {
                "name": "Tell Abyad District",
                "countryCode": "SY"
            },
            {
                "name": "Al Kiswah",
                "countryCode": "SY"
            },
            {
                "name": "Al Quţayfah",
                "countryCode": "SY"
            },
            {
                "name": "Al-Zabadani District",
                "countryCode": "SY"
            },
            {
                "name": "An Nabk",
                "countryCode": "SY"
            },
            {
                "name": "At Tall",
                "countryCode": "SY"
            },
            {
                "name": "Az Zabadānī",
                "countryCode": "SY"
            },
            {
                "name": "Dayr al ‘Aşāfīr",
                "countryCode": "SY"
            },
            {
                "name": "Douma",
                "countryCode": "SY"
            },
            {
                "name": "Dārayyā",
                "countryCode": "SY"
            },
            {
                "name": "Jaramānā",
                "countryCode": "SY"
            },
            {
                "name": "Jayrūd",
                "countryCode": "SY"
            },
            {
                "name": "Ma‘lūlā",
                "countryCode": "SY"
            },
            {
                "name": "Medaya",
                "countryCode": "SY"
            },
            {
                "name": "Qaţanā",
                "countryCode": "SY"
            },
            {
                "name": "Qārah",
                "countryCode": "SY"
            },
            {
                "name": "Yabrūd",
                "countryCode": "SY"
            },
            {
                "name": "Şaydnāyā",
                "countryCode": "SY"
            },
            {
                "name": "Ḩarastā",
                "countryCode": "SY"
            },
            {
                "name": "‘Irbīn",
                "countryCode": "SY"
            },
            {
                "name": "As-Suwayda",
                "countryCode": "SY"
            },
            {
                "name": "As-Suwayda District",
                "countryCode": "SY"
            },
            {
                "name": "Salkhad District",
                "countryCode": "SY"
            },
            {
                "name": "Shahba District",
                "countryCode": "SY"
            },
            {
                "name": "Shahbā",
                "countryCode": "SY"
            },
            {
                "name": "Şalkhad",
                "countryCode": "SY"
            },
            {
                "name": "Şalākhid",
                "countryCode": "SY"
            },
            {
                "name": "Ad Duraykīsh",
                "countryCode": "SY"
            },
            {
                "name": "Bāniyās",
                "countryCode": "SY"
            },
            {
                "name": "Kaff al-Jaa",
                "countryCode": "SY"
            },
            {
                "name": "Safita District",
                "countryCode": "SY"
            },
            {
                "name": "Satita",
                "countryCode": "SY"
            },
            {
                "name": "Tartouss",
                "countryCode": "SY"
            }
        ]
    },
    {
        "name": "Taiwan",
        "phonecode": "886",
        "cities": [
            {
                "name": "Changhua",
                "countryCode": "TW"
            },
            {
                "name": "Yuanlin",
                "countryCode": "TW"
            },
            {
                "name": "Chiayi",
                "countryCode": "TW"
            },
            {
                "name": "Pizitou",
                "countryCode": "TW"
            },
            {
                "name": "Chiayi County",
                "countryCode": "TW"
            },
            {
                "name": "Hsinchu",
                "countryCode": "TW"
            },
            {
                "name": "Hsinchu County",
                "countryCode": "TW"
            },
            {
                "name": "Hualien",
                "countryCode": "TW"
            },
            {
                "name": "Hualien City",
                "countryCode": "TW"
            },
            {
                "name": "Yilan",
                "countryCode": "TW"
            },
            {
                "name": "Kaohsiung",
                "countryCode": "TW"
            },
            {
                "name": "Jincheng",
                "countryCode": "TW"
            },
            {
                "name": "Kinmen County",
                "countryCode": "TW"
            },
            {
                "name": "Lienchiang",
                "countryCode": "TW"
            },
            {
                "name": "Nangan",
                "countryCode": "TW"
            },
            {
                "name": "Miaoli",
                "countryCode": "TW"
            },
            {
                "name": "Lugu",
                "countryCode": "TW"
            },
            {
                "name": "Nantou",
                "countryCode": "TW"
            },
            {
                "name": "Puli",
                "countryCode": "TW"
            },
            {
                "name": "Zhongxing New Village",
                "countryCode": "TW"
            },
            {
                "name": "Magong",
                "countryCode": "TW"
            },
            {
                "name": "Penghu County",
                "countryCode": "TW"
            },
            {
                "name": "Donggang",
                "countryCode": "TW"
            },
            {
                "name": "Hengchun",
                "countryCode": "TW"
            },
            {
                "name": "Pingtung",
                "countryCode": "TW"
            },
            {
                "name": "Daxi",
                "countryCode": "TW"
            },
            {
                "name": "Taoyuan",
                "countryCode": "TW"
            },
            {
                "name": "Taoyuan City",
                "countryCode": "TW"
            },
            {
                "name": "Tainan",
                "countryCode": "TW"
            },
            {
                "name": "Yujing",
                "countryCode": "TW"
            },
            {
                "name": "Banqiao",
                "countryCode": "TW"
            },
            {
                "name": "Jiufen",
                "countryCode": "TW"
            },
            {
                "name": "Taipei",
                "countryCode": "TW"
            },
            {
                "name": "Taipei City",
                "countryCode": "TW"
            },
            {
                "name": "Taitung",
                "countryCode": "TW"
            },
            {
                "name": "Taitung City",
                "countryCode": "TW"
            },
            {
                "name": "Taichung",
                "countryCode": "TW"
            },
            {
                "name": "Taichung City",
                "countryCode": "TW"
            },
            {
                "name": "Douliu",
                "countryCode": "TW"
            },
            {
                "name": "Yunlin",
                "countryCode": "TW"
            }
        ]
    },
    {
        "name": "Tajikistan",
        "phonecode": "992",
        "cities": [
            {
                "name": "Ishqoshim",
                "countryCode": "TJ"
            },
            {
                "name": "Khorugh",
                "countryCode": "TJ"
            },
            {
                "name": "Murghob",
                "countryCode": "TJ"
            },
            {
                "name": "Nohiyai Shughnon",
                "countryCode": "TJ"
            },
            {
                "name": "Abdurahmoni Jomí",
                "countryCode": "TJ"
            },
            {
                "name": "Boshchorbogh",
                "countryCode": "TJ"
            },
            {
                "name": "Bŭstonqal’a",
                "countryCode": "TJ"
            },
            {
                "name": "Chubek",
                "countryCode": "TJ"
            },
            {
                "name": "Danghara",
                "countryCode": "TJ"
            },
            {
                "name": "Dŭstí",
                "countryCode": "TJ"
            },
            {
                "name": "Farkhor",
                "countryCode": "TJ"
            },
            {
                "name": "Gharavŭtí",
                "countryCode": "TJ"
            },
            {
                "name": "Jilikŭl",
                "countryCode": "TJ"
            },
            {
                "name": "Kirov",
                "countryCode": "TJ"
            },
            {
                "name": "Kolkhozobod",
                "countryCode": "TJ"
            },
            {
                "name": "Kŭlob",
                "countryCode": "TJ"
            },
            {
                "name": "Moskovskiy",
                "countryCode": "TJ"
            },
            {
                "name": "Mŭ’minobod",
                "countryCode": "TJ"
            },
            {
                "name": "Nohiyai Kolkhozobod",
                "countryCode": "TJ"
            },
            {
                "name": "Nohiyai Panj",
                "countryCode": "TJ"
            },
            {
                "name": "Nohiyai Vakhsh",
                "countryCode": "TJ"
            },
            {
                "name": "Norak",
                "countryCode": "TJ"
            },
            {
                "name": "Orzu",
                "countryCode": "TJ"
            },
            {
                "name": "Panj",
                "countryCode": "TJ"
            },
            {
                "name": "Qŭrghonteppa",
                "countryCode": "TJ"
            },
            {
                "name": "Shahritus",
                "countryCode": "TJ"
            },
            {
                "name": "Sovet",
                "countryCode": "TJ"
            },
            {
                "name": "Tartiki",
                "countryCode": "TJ"
            },
            {
                "name": "Vakhsh",
                "countryCode": "TJ"
            },
            {
                "name": "Vose’",
                "countryCode": "TJ"
            },
            {
                "name": "Yovon",
                "countryCode": "TJ"
            },
            {
                "name": "Darband",
                "countryCode": "TJ"
            },
            {
                "name": "Hisor",
                "countryCode": "TJ"
            },
            {
                "name": "Karakenja",
                "countryCode": "TJ"
            },
            {
                "name": "Khodzha-Maston",
                "countryCode": "TJ"
            },
            {
                "name": "Novobod",
                "countryCode": "TJ"
            },
            {
                "name": "Obigarm",
                "countryCode": "TJ"
            },
            {
                "name": "Rasht",
                "countryCode": "TJ"
            },
            {
                "name": "Roghun",
                "countryCode": "TJ"
            },
            {
                "name": "Shahrinav",
                "countryCode": "TJ"
            },
            {
                "name": "Tagob",
                "countryCode": "TJ"
            },
            {
                "name": "Tursunzoda",
                "countryCode": "TJ"
            },
            {
                "name": "Vahdat",
                "countryCode": "TJ"
            },
            {
                "name": "Vahdat District",
                "countryCode": "TJ"
            },
            {
                "name": "Varzob",
                "countryCode": "TJ"
            },
            {
                "name": "Varzob District",
                "countryCode": "TJ"
            },
            {
                "name": "Adrasmon",
                "countryCode": "TJ"
            },
            {
                "name": "Ayní",
                "countryCode": "TJ"
            },
            {
                "name": "Bŭston",
                "countryCode": "TJ"
            },
            {
                "name": "Chkalov",
                "countryCode": "TJ"
            },
            {
                "name": "Ghafurov",
                "countryCode": "TJ"
            },
            {
                "name": "Isfara",
                "countryCode": "TJ"
            },
            {
                "name": "Istaravshan",
                "countryCode": "TJ"
            },
            {
                "name": "Khŭjand",
                "countryCode": "TJ"
            },
            {
                "name": "Kim",
                "countryCode": "TJ"
            },
            {
                "name": "Konibodom",
                "countryCode": "TJ"
            },
            {
                "name": "Konsoy",
                "countryCode": "TJ"
            },
            {
                "name": "Neftobod",
                "countryCode": "TJ"
            },
            {
                "name": "Nohiyai Konibodom",
                "countryCode": "TJ"
            },
            {
                "name": "Nov",
                "countryCode": "TJ"
            },
            {
                "name": "Oltintopkan",
                "countryCode": "TJ"
            },
            {
                "name": "Pakhtakoron",
                "countryCode": "TJ"
            },
            {
                "name": "Palos",
                "countryCode": "TJ"
            },
            {
                "name": "Panjakent",
                "countryCode": "TJ"
            },
            {
                "name": "Proletar",
                "countryCode": "TJ"
            },
            {
                "name": "Quruqsoy",
                "countryCode": "TJ"
            },
            {
                "name": "Shaydon",
                "countryCode": "TJ"
            },
            {
                "name": "Shŭrob",
                "countryCode": "TJ"
            },
            {
                "name": "Taboshar",
                "countryCode": "TJ"
            },
            {
                "name": "Vorukh",
                "countryCode": "TJ"
            }
        ]
    },
    {
        "name": "Tanzania",
        "phonecode": "255",
        "cities": [
            {
                "name": "Arusha",
                "countryCode": "TZ"
            },
            {
                "name": "Kingori",
                "countryCode": "TZ"
            },
            {
                "name": "Kiratu",
                "countryCode": "TZ"
            },
            {
                "name": "Longido",
                "countryCode": "TZ"
            },
            {
                "name": "Mbuguni",
                "countryCode": "TZ"
            },
            {
                "name": "Merelani",
                "countryCode": "TZ"
            },
            {
                "name": "Meru",
                "countryCode": "TZ"
            },
            {
                "name": "Monduli",
                "countryCode": "TZ"
            },
            {
                "name": "Mto wa Mbu",
                "countryCode": "TZ"
            },
            {
                "name": "Namanga",
                "countryCode": "TZ"
            },
            {
                "name": "Ngorongoro",
                "countryCode": "TZ"
            },
            {
                "name": "Nkoaranga",
                "countryCode": "TZ"
            },
            {
                "name": "Poli",
                "countryCode": "TZ"
            },
            {
                "name": "Usa River",
                "countryCode": "TZ"
            },
            {
                "name": "Dar es Salaam",
                "countryCode": "TZ"
            },
            {
                "name": "Magomeni",
                "countryCode": "TZ"
            },
            {
                "name": "Dodoma",
                "countryCode": "TZ"
            },
            {
                "name": "Kibakwe",
                "countryCode": "TZ"
            },
            {
                "name": "Kisasa",
                "countryCode": "TZ"
            },
            {
                "name": "Kondoa",
                "countryCode": "TZ"
            },
            {
                "name": "Kongwa",
                "countryCode": "TZ"
            },
            {
                "name": "Mpwapwa",
                "countryCode": "TZ"
            },
            {
                "name": "Msanga",
                "countryCode": "TZ"
            },
            {
                "name": "Ilula",
                "countryCode": "TZ"
            },
            {
                "name": "Iringa",
                "countryCode": "TZ"
            },
            {
                "name": "Izazi",
                "countryCode": "TZ"
            },
            {
                "name": "Mafinga",
                "countryCode": "TZ"
            },
            {
                "name": "Makungu",
                "countryCode": "TZ"
            },
            {
                "name": "Malangali",
                "countryCode": "TZ"
            },
            {
                "name": "Biharamulo",
                "countryCode": "TZ"
            },
            {
                "name": "Bugarama",
                "countryCode": "TZ"
            },
            {
                "name": "Bugene",
                "countryCode": "TZ"
            },
            {
                "name": "Bukoba",
                "countryCode": "TZ"
            },
            {
                "name": "Kabanga",
                "countryCode": "TZ"
            },
            {
                "name": "Kamachumu",
                "countryCode": "TZ"
            },
            {
                "name": "Katerero",
                "countryCode": "TZ"
            },
            {
                "name": "Katoro",
                "countryCode": "TZ"
            },
            {
                "name": "Kyaka",
                "countryCode": "TZ"
            },
            {
                "name": "Ngara",
                "countryCode": "TZ"
            },
            {
                "name": "Nshamba",
                "countryCode": "TZ"
            },
            {
                "name": "Nsunga",
                "countryCode": "TZ"
            },
            {
                "name": "Nyakahanga",
                "countryCode": "TZ"
            },
            {
                "name": "Rulenge",
                "countryCode": "TZ"
            },
            {
                "name": "Konde",
                "countryCode": "TZ"
            },
            {
                "name": "Micheweni",
                "countryCode": "TZ"
            },
            {
                "name": "Wete",
                "countryCode": "TZ"
            },
            {
                "name": "Gamba",
                "countryCode": "TZ"
            },
            {
                "name": "Kijini",
                "countryCode": "TZ"
            },
            {
                "name": "Kiwengwa",
                "countryCode": "TZ"
            },
            {
                "name": "Mkokotoni",
                "countryCode": "TZ"
            },
            {
                "name": "Nungwi",
                "countryCode": "TZ"
            },
            {
                "name": "Kakonko",
                "countryCode": "TZ"
            },
            {
                "name": "Kasulu",
                "countryCode": "TZ"
            },
            {
                "name": "Kibondo",
                "countryCode": "TZ"
            },
            {
                "name": "Kigoma",
                "countryCode": "TZ"
            },
            {
                "name": "Mabamba",
                "countryCode": "TZ"
            },
            {
                "name": "Mwandiga",
                "countryCode": "TZ"
            },
            {
                "name": "Nguruka",
                "countryCode": "TZ"
            },
            {
                "name": "Uvinza",
                "countryCode": "TZ"
            },
            {
                "name": "Hedaru",
                "countryCode": "TZ"
            },
            {
                "name": "Kihurio",
                "countryCode": "TZ"
            },
            {
                "name": "Kisiwani",
                "countryCode": "TZ"
            },
            {
                "name": "Kwakoa",
                "countryCode": "TZ"
            },
            {
                "name": "Lembeni",
                "countryCode": "TZ"
            },
            {
                "name": "Makanya",
                "countryCode": "TZ"
            },
            {
                "name": "Moshi",
                "countryCode": "TZ"
            },
            {
                "name": "Mwembe",
                "countryCode": "TZ"
            },
            {
                "name": "Ndungu",
                "countryCode": "TZ"
            },
            {
                "name": "Same",
                "countryCode": "TZ"
            },
            {
                "name": "Chake Chake",
                "countryCode": "TZ"
            },
            {
                "name": "Mtambile",
                "countryCode": "TZ"
            },
            {
                "name": "Uwelini",
                "countryCode": "TZ"
            },
            {
                "name": "Koani",
                "countryCode": "TZ"
            },
            {
                "name": "Koani Ndogo",
                "countryCode": "TZ"
            },
            {
                "name": "Mahonda",
                "countryCode": "TZ"
            },
            {
                "name": "Nganane",
                "countryCode": "TZ"
            },
            {
                "name": "Sokoni",
                "countryCode": "TZ"
            },
            {
                "name": "Lindi",
                "countryCode": "TZ"
            },
            {
                "name": "Mbekenyera",
                "countryCode": "TZ"
            },
            {
                "name": "Mingoyo",
                "countryCode": "TZ"
            },
            {
                "name": "Mtama",
                "countryCode": "TZ"
            },
            {
                "name": "Nachingwea",
                "countryCode": "TZ"
            },
            {
                "name": "Nyangao",
                "countryCode": "TZ"
            },
            {
                "name": "Ruangwa",
                "countryCode": "TZ"
            },
            {
                "name": "Bukonyo",
                "countryCode": "TZ"
            },
            {
                "name": "Butiama",
                "countryCode": "TZ"
            },
            {
                "name": "Issenye",
                "countryCode": "TZ"
            },
            {
                "name": "Kibara",
                "countryCode": "TZ"
            },
            {
                "name": "Mugango",
                "countryCode": "TZ"
            },
            {
                "name": "Mugumu",
                "countryCode": "TZ"
            },
            {
                "name": "Muriti",
                "countryCode": "TZ"
            },
            {
                "name": "Musoma",
                "countryCode": "TZ"
            },
            {
                "name": "Nakatunguru",
                "countryCode": "TZ"
            },
            {
                "name": "Nansio",
                "countryCode": "TZ"
            },
            {
                "name": "Nyamuswa",
                "countryCode": "TZ"
            },
            {
                "name": "Sirari",
                "countryCode": "TZ"
            },
            {
                "name": "Chimala",
                "countryCode": "TZ"
            },
            {
                "name": "Hedaru",
                "countryCode": "TZ"
            },
            {
                "name": "Ibaba",
                "countryCode": "TZ"
            },
            {
                "name": "Ikama",
                "countryCode": "TZ"
            },
            {
                "name": "Ikinga",
                "countryCode": "TZ"
            },
            {
                "name": "Ikolo",
                "countryCode": "TZ"
            },
            {
                "name": "Ikuti",
                "countryCode": "TZ"
            },
            {
                "name": "Ilembo",
                "countryCode": "TZ"
            },
            {
                "name": "Ipinda",
                "countryCode": "TZ"
            },
            {
                "name": "Kabula",
                "countryCode": "TZ"
            },
            {
                "name": "Kahe, Tanzania",
                "countryCode": "TZ"
            },
            {
                "name": "Kandete",
                "countryCode": "TZ"
            },
            {
                "name": "Katumba",
                "countryCode": "TZ"
            },
            {
                "name": "Kihurio",
                "countryCode": "TZ"
            },
            {
                "name": "Kisiwani",
                "countryCode": "TZ"
            },
            {
                "name": "Kiwira",
                "countryCode": "TZ"
            },
            {
                "name": "Kwakoa",
                "countryCode": "TZ"
            },
            {
                "name": "Lembeni",
                "countryCode": "TZ"
            },
            {
                "name": "Lupata",
                "countryCode": "TZ"
            },
            {
                "name": "Lusungo",
                "countryCode": "TZ"
            },
            {
                "name": "Machame",
                "countryCode": "TZ"
            },
            {
                "name": "Makanya",
                "countryCode": "TZ"
            },
            {
                "name": "Marangu",
                "countryCode": "TZ"
            },
            {
                "name": "Masukulu",
                "countryCode": "TZ"
            },
            {
                "name": "Mbeya",
                "countryCode": "TZ"
            },
            {
                "name": "Mlowo",
                "countryCode": "TZ"
            },
            {
                "name": "Moshi",
                "countryCode": "TZ"
            },
            {
                "name": "Mpuguso",
                "countryCode": "TZ"
            },
            {
                "name": "Mwansanga",
                "countryCode": "TZ"
            },
            {
                "name": "Mwaya",
                "countryCode": "TZ"
            },
            {
                "name": "Mwembe",
                "countryCode": "TZ"
            },
            {
                "name": "Same",
                "countryCode": "TZ"
            },
            {
                "name": "Tukuyu",
                "countryCode": "TZ"
            },
            {
                "name": "Tunduma",
                "countryCode": "TZ"
            },
            {
                "name": "Ugweno",
                "countryCode": "TZ"
            },
            {
                "name": "Ulanga",
                "countryCode": "TZ"
            },
            {
                "name": "Zanzibar",
                "countryCode": "TZ"
            },
            {
                "name": "Geiro",
                "countryCode": "TZ"
            },
            {
                "name": "Ifakara",
                "countryCode": "TZ"
            },
            {
                "name": "Kidatu",
                "countryCode": "TZ"
            },
            {
                "name": "Kidodi",
                "countryCode": "TZ"
            },
            {
                "name": "Kilosa",
                "countryCode": "TZ"
            },
            {
                "name": "Kimamba",
                "countryCode": "TZ"
            },
            {
                "name": "Kisanga",
                "countryCode": "TZ"
            },
            {
                "name": "Lupiro",
                "countryCode": "TZ"
            },
            {
                "name": "Magole",
                "countryCode": "TZ"
            },
            {
                "name": "Mahenge",
                "countryCode": "TZ"
            },
            {
                "name": "Malinyi",
                "countryCode": "TZ"
            },
            {
                "name": "Mikumi",
                "countryCode": "TZ"
            },
            {
                "name": "Mlimba",
                "countryCode": "TZ"
            },
            {
                "name": "Morogoro",
                "countryCode": "TZ"
            },
            {
                "name": "Msowero",
                "countryCode": "TZ"
            },
            {
                "name": "Mtimbira",
                "countryCode": "TZ"
            },
            {
                "name": "Mvomero District",
                "countryCode": "TZ"
            },
            {
                "name": "Ngerengere",
                "countryCode": "TZ"
            },
            {
                "name": "Chiungutwa",
                "countryCode": "TZ"
            },
            {
                "name": "Kitama",
                "countryCode": "TZ"
            },
            {
                "name": "Kitangari",
                "countryCode": "TZ"
            },
            {
                "name": "Luchingu",
                "countryCode": "TZ"
            },
            {
                "name": "Lukuledi",
                "countryCode": "TZ"
            },
            {
                "name": "Lulindi",
                "countryCode": "TZ"
            },
            {
                "name": "Madimba",
                "countryCode": "TZ"
            },
            {
                "name": "Mahuta",
                "countryCode": "TZ"
            },
            {
                "name": "Masasi",
                "countryCode": "TZ"
            },
            {
                "name": "Masuguru",
                "countryCode": "TZ"
            },
            {
                "name": "Mtwara",
                "countryCode": "TZ"
            },
            {
                "name": "Namalenga",
                "countryCode": "TZ"
            },
            {
                "name": "Namikupa",
                "countryCode": "TZ"
            },
            {
                "name": "Nanganga",
                "countryCode": "TZ"
            },
            {
                "name": "Nangomba",
                "countryCode": "TZ"
            },
            {
                "name": "Nanhyanga",
                "countryCode": "TZ"
            },
            {
                "name": "Nanyamba",
                "countryCode": "TZ"
            },
            {
                "name": "Newala Kisimani",
                "countryCode": "TZ"
            },
            {
                "name": "Tandahimba",
                "countryCode": "TZ"
            },
            {
                "name": "Ilemela District",
                "countryCode": "TZ"
            },
            {
                "name": "Kihangara",
                "countryCode": "TZ"
            },
            {
                "name": "Malya",
                "countryCode": "TZ"
            },
            {
                "name": "Misasi",
                "countryCode": "TZ"
            },
            {
                "name": "Misungwi",
                "countryCode": "TZ"
            },
            {
                "name": "Mwanza",
                "countryCode": "TZ"
            },
            {
                "name": "Ngudu",
                "countryCode": "TZ"
            },
            {
                "name": "Nyanguge",
                "countryCode": "TZ"
            },
            {
                "name": "Usagara",
                "countryCode": "TZ"
            },
            {
                "name": "Bagamoyo",
                "countryCode": "TZ"
            },
            {
                "name": "Bungu",
                "countryCode": "TZ"
            },
            {
                "name": "Chalinze",
                "countryCode": "TZ"
            },
            {
                "name": "Ikwiriri",
                "countryCode": "TZ"
            },
            {
                "name": "Kibaha",
                "countryCode": "TZ"
            },
            {
                "name": "Kibiti",
                "countryCode": "TZ"
            },
            {
                "name": "Kilindoni",
                "countryCode": "TZ"
            },
            {
                "name": "Kisarawe",
                "countryCode": "TZ"
            },
            {
                "name": "Lugoba",
                "countryCode": "TZ"
            },
            {
                "name": "Maneromango",
                "countryCode": "TZ"
            },
            {
                "name": "Mbumi",
                "countryCode": "TZ"
            },
            {
                "name": "Mkuranga",
                "countryCode": "TZ"
            },
            {
                "name": "Mlandizi",
                "countryCode": "TZ"
            },
            {
                "name": "Mvomero",
                "countryCode": "TZ"
            },
            {
                "name": "Utete",
                "countryCode": "TZ"
            },
            {
                "name": "Vikindu",
                "countryCode": "TZ"
            },
            {
                "name": "Chala",
                "countryCode": "TZ"
            },
            {
                "name": "Kirando",
                "countryCode": "TZ"
            },
            {
                "name": "Laela",
                "countryCode": "TZ"
            },
            {
                "name": "Matai",
                "countryCode": "TZ"
            },
            {
                "name": "Namanyere",
                "countryCode": "TZ"
            },
            {
                "name": "Nkove",
                "countryCode": "TZ"
            },
            {
                "name": "Sumbawanga",
                "countryCode": "TZ"
            },
            {
                "name": "Kigonsera",
                "countryCode": "TZ"
            },
            {
                "name": "Liuli",
                "countryCode": "TZ"
            },
            {
                "name": "Mahanje",
                "countryCode": "TZ"
            },
            {
                "name": "Maposeni",
                "countryCode": "TZ"
            },
            {
                "name": "Matiri",
                "countryCode": "TZ"
            },
            {
                "name": "Mbamba Bay",
                "countryCode": "TZ"
            },
            {
                "name": "Mbinga",
                "countryCode": "TZ"
            },
            {
                "name": "Songea",
                "countryCode": "TZ"
            },
            {
                "name": "Tingi",
                "countryCode": "TZ"
            },
            {
                "name": "Isaka",
                "countryCode": "TZ"
            },
            {
                "name": "Kahama",
                "countryCode": "TZ"
            },
            {
                "name": "Kishapu",
                "countryCode": "TZ"
            },
            {
                "name": "Mhango",
                "countryCode": "TZ"
            },
            {
                "name": "Mwadui",
                "countryCode": "TZ"
            },
            {
                "name": "Old Shinyanga",
                "countryCode": "TZ"
            },
            {
                "name": "Shinyanga",
                "countryCode": "TZ"
            },
            {
                "name": "Songwa",
                "countryCode": "TZ"
            },
            {
                "name": "Tinde",
                "countryCode": "TZ"
            },
            {
                "name": "Igugunu",
                "countryCode": "TZ"
            },
            {
                "name": "Ikungi",
                "countryCode": "TZ"
            },
            {
                "name": "Ilongero",
                "countryCode": "TZ"
            },
            {
                "name": "Itigi",
                "countryCode": "TZ"
            },
            {
                "name": "Kilimatinde",
                "countryCode": "TZ"
            },
            {
                "name": "Kintinku",
                "countryCode": "TZ"
            },
            {
                "name": "Kiomboi",
                "countryCode": "TZ"
            },
            {
                "name": "Mgandu",
                "countryCode": "TZ"
            },
            {
                "name": "Mtinko",
                "countryCode": "TZ"
            },
            {
                "name": "Mungaa",
                "countryCode": "TZ"
            },
            {
                "name": "Ndago",
                "countryCode": "TZ"
            },
            {
                "name": "Puma",
                "countryCode": "TZ"
            },
            {
                "name": "Sepuka",
                "countryCode": "TZ"
            },
            {
                "name": "Shelui",
                "countryCode": "TZ"
            },
            {
                "name": "Singida",
                "countryCode": "TZ"
            },
            {
                "name": "Bukene",
                "countryCode": "TZ"
            },
            {
                "name": "Igurubi",
                "countryCode": "TZ"
            },
            {
                "name": "Kaliua",
                "countryCode": "TZ"
            },
            {
                "name": "Mabama",
                "countryCode": "TZ"
            },
            {
                "name": "Sikonge",
                "countryCode": "TZ"
            },
            {
                "name": "Tabora",
                "countryCode": "TZ"
            },
            {
                "name": "Tumbi",
                "countryCode": "TZ"
            },
            {
                "name": "Usoke",
                "countryCode": "TZ"
            },
            {
                "name": "Chanika",
                "countryCode": "TZ"
            },
            {
                "name": "Lushoto",
                "countryCode": "TZ"
            },
            {
                "name": "Magomeni",
                "countryCode": "TZ"
            },
            {
                "name": "Majengo",
                "countryCode": "TZ"
            },
            {
                "name": "Makuyuni",
                "countryCode": "TZ"
            },
            {
                "name": "Maramba",
                "countryCode": "TZ"
            },
            {
                "name": "Matui",
                "countryCode": "TZ"
            },
            {
                "name": "Mazinde",
                "countryCode": "TZ"
            },
            {
                "name": "Mlalo",
                "countryCode": "TZ"
            },
            {
                "name": "Muheza",
                "countryCode": "TZ"
            },
            {
                "name": "Mwanga",
                "countryCode": "TZ"
            },
            {
                "name": "Pangani",
                "countryCode": "TZ"
            },
            {
                "name": "Soni",
                "countryCode": "TZ"
            },
            {
                "name": "Tanga",
                "countryCode": "TZ"
            },
            {
                "name": "Babati",
                "countryCode": "TZ"
            },
            {
                "name": "Bashanet",
                "countryCode": "TZ"
            },
            {
                "name": "Basotu",
                "countryCode": "TZ"
            },
            {
                "name": "Dareda",
                "countryCode": "TZ"
            },
            {
                "name": "Dongobesh",
                "countryCode": "TZ"
            },
            {
                "name": "Endasak",
                "countryCode": "TZ"
            },
            {
                "name": "Galappo",
                "countryCode": "TZ"
            },
            {
                "name": "Katesh",
                "countryCode": "TZ"
            },
            {
                "name": "Kibaya",
                "countryCode": "TZ"
            },
            {
                "name": "Kirya",
                "countryCode": "TZ"
            },
            {
                "name": "Magugu",
                "countryCode": "TZ"
            },
            {
                "name": "Mbulu",
                "countryCode": "TZ"
            },
            {
                "name": "Naberera",
                "countryCode": "TZ"
            },
            {
                "name": "Nangwa",
                "countryCode": "TZ"
            },
            {
                "name": "Buseresere",
                "countryCode": "TZ"
            },
            {
                "name": "Chato",
                "countryCode": "TZ"
            },
            {
                "name": "Geita",
                "countryCode": "TZ"
            },
            {
                "name": "Kasamwa",
                "countryCode": "TZ"
            },
            {
                "name": "Katoro",
                "countryCode": "TZ"
            },
            {
                "name": "Masumbwe",
                "countryCode": "TZ"
            },
            {
                "name": "Ushirombo",
                "countryCode": "TZ"
            },
            {
                "name": "Uyovu",
                "countryCode": "TZ"
            },
            {
                "name": "Inyonga",
                "countryCode": "TZ"
            },
            {
                "name": "Karema",
                "countryCode": "TZ"
            },
            {
                "name": "Mpanda",
                "countryCode": "TZ"
            },
            {
                "name": "Usevia",
                "countryCode": "TZ"
            },
            {
                "name": "Ilembula",
                "countryCode": "TZ"
            },
            {
                "name": "Makumbako",
                "countryCode": "TZ"
            },
            {
                "name": "Manda",
                "countryCode": "TZ"
            },
            {
                "name": "Matamba",
                "countryCode": "TZ"
            },
            {
                "name": "Mlangali",
                "countryCode": "TZ"
            },
            {
                "name": "Mtwango",
                "countryCode": "TZ"
            },
            {
                "name": "Njombe",
                "countryCode": "TZ"
            },
            {
                "name": "Bariadi",
                "countryCode": "TZ"
            },
            {
                "name": "Kisesa",
                "countryCode": "TZ"
            },
            {
                "name": "Lalago",
                "countryCode": "TZ"
            },
            {
                "name": "Malampaka",
                "countryCode": "TZ"
            },
            {
                "name": "Maswa",
                "countryCode": "TZ"
            },
            {
                "name": "Matonga",
                "countryCode": "TZ"
            },
            {
                "name": "Nyakabindi",
                "countryCode": "TZ"
            },
            {
                "name": "Nyalikungu",
                "countryCode": "TZ"
            },
            {
                "name": "Somanda",
                "countryCode": "TZ"
            }
        ]
    },
    {
        "name": "Thailand",
        "phonecode": "66",
        "cities": [
            {
                "name": "Bang Bon",
                "countryCode": "TH"
            },
            {
                "name": "Bang Kapi",
                "countryCode": "TH"
            },
            {
                "name": "Bang Khae",
                "countryCode": "TH"
            },
            {
                "name": "Bang Khen",
                "countryCode": "TH"
            },
            {
                "name": "Bang Kho laen",
                "countryCode": "TH"
            },
            {
                "name": "Bang Khun thain",
                "countryCode": "TH"
            },
            {
                "name": "Bang Na",
                "countryCode": "TH"
            },
            {
                "name": "Bang Phlat",
                "countryCode": "TH"
            },
            {
                "name": "Bang Rak",
                "countryCode": "TH"
            },
            {
                "name": "Bang Sue",
                "countryCode": "TH"
            },
            {
                "name": "Bangkok",
                "countryCode": "TH"
            },
            {
                "name": "Bangkok Noi",
                "countryCode": "TH"
            },
            {
                "name": "Bangkok Yai",
                "countryCode": "TH"
            },
            {
                "name": "Bueng Kum",
                "countryCode": "TH"
            },
            {
                "name": "Chatu Chak",
                "countryCode": "TH"
            },
            {
                "name": "Chom Thong",
                "countryCode": "TH"
            },
            {
                "name": "Din Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Don Mueang",
                "countryCode": "TH"
            },
            {
                "name": "Dusit",
                "countryCode": "TH"
            },
            {
                "name": "Huai Khwang",
                "countryCode": "TH"
            },
            {
                "name": "Khan Na Yao",
                "countryCode": "TH"
            },
            {
                "name": "Khlong Sam Wa",
                "countryCode": "TH"
            },
            {
                "name": "Khlong San",
                "countryCode": "TH"
            },
            {
                "name": "Khlong Toei",
                "countryCode": "TH"
            },
            {
                "name": "Lak Si",
                "countryCode": "TH"
            },
            {
                "name": "Lat Krabang",
                "countryCode": "TH"
            },
            {
                "name": "Lat Phrao",
                "countryCode": "TH"
            },
            {
                "name": "Min Buri",
                "countryCode": "TH"
            },
            {
                "name": "Nong Chok",
                "countryCode": "TH"
            },
            {
                "name": "Nong Khaem",
                "countryCode": "TH"
            },
            {
                "name": "Parthum Wan",
                "countryCode": "TH"
            },
            {
                "name": "Phasi Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Phaya Thai",
                "countryCode": "TH"
            },
            {
                "name": "Phra Khanong",
                "countryCode": "TH"
            },
            {
                "name": "Phra Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Pom Prap Sattru Phai",
                "countryCode": "TH"
            },
            {
                "name": "Pra Wet",
                "countryCode": "TH"
            },
            {
                "name": "Rat Burana",
                "countryCode": "TH"
            },
            {
                "name": "Ratchathewi",
                "countryCode": "TH"
            },
            {
                "name": "Sai Mai",
                "countryCode": "TH"
            },
            {
                "name": "Samphanthawong",
                "countryCode": "TH"
            },
            {
                "name": "Saphan Sung",
                "countryCode": "TH"
            },
            {
                "name": "Sathon",
                "countryCode": "TH"
            },
            {
                "name": "Suanluang",
                "countryCode": "TH"
            },
            {
                "name": "Taling Chan",
                "countryCode": "TH"
            },
            {
                "name": "Thawi Watthana",
                "countryCode": "TH"
            },
            {
                "name": "Thon buri",
                "countryCode": "TH"
            },
            {
                "name": "Thung khru",
                "countryCode": "TH"
            },
            {
                "name": "Vadhana",
                "countryCode": "TH"
            },
            {
                "name": "Wang Thong Lang",
                "countryCode": "TH"
            },
            {
                "name": "Yan na wa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Bo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Phli",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Sao Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Samut Prakan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Pradaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Samut Chedi",
                "countryCode": "TH"
            },
            {
                "name": "Ban Khlong Bang Sao Thong",
                "countryCode": "TH"
            },
            {
                "name": "Bang Bo District",
                "countryCode": "TH"
            },
            {
                "name": "Phra Pradaeng",
                "countryCode": "TH"
            },
            {
                "name": "Samut Prakan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Bua Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Kruai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nonthaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Kret",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Noi",
                "countryCode": "TH"
            },
            {
                "name": "Bang Bua Thong",
                "countryCode": "TH"
            },
            {
                "name": "Bang Kruai",
                "countryCode": "TH"
            },
            {
                "name": "Bang Yai",
                "countryCode": "TH"
            },
            {
                "name": "Mueang Nonthaburi",
                "countryCode": "TH"
            },
            {
                "name": "Pak Kret",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lam Luk Ka",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lat Lum Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Pathum Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Suea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thanyaburi",
                "countryCode": "TH"
            },
            {
                "name": "Ban Lam Luk Ka",
                "countryCode": "TH"
            },
            {
                "name": "Khlong Luang",
                "countryCode": "TH"
            },
            {
                "name": "Pathum Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Phraek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Ban",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Pa-in",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Pahan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lat Bua Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Maha Rat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nakhon Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phachi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phak Hai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Nakhon Si Ayutthaya",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sena",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Ruea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Uthai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Noi",
                "countryCode": "TH"
            },
            {
                "name": "Ban Bang Kadi Pathum Thani",
                "countryCode": "TH"
            },
            {
                "name": "Bang Ban",
                "countryCode": "TH"
            },
            {
                "name": "Bang Pa-in",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Luang",
                "countryCode": "TH"
            },
            {
                "name": "Phak Hai",
                "countryCode": "TH"
            },
            {
                "name": "Phra Nakhon Si Ayutthaya",
                "countryCode": "TH"
            },
            {
                "name": "Tha Ruea",
                "countryCode": "TH"
            },
            {
                "name": "Wang Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaiyo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Ang Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Mok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawaeng Ha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiset Chai Chan",
                "countryCode": "TH"
            },
            {
                "name": "Ang Thong",
                "countryCode": "TH"
            },
            {
                "name": "Ban Thai Tan",
                "countryCode": "TH"
            },
            {
                "name": "Chaiyo",
                "countryCode": "TH"
            },
            {
                "name": "Pho Thong",
                "countryCode": "TH"
            },
            {
                "name": "Wiset Chaichan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Mi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chai Badan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Samrong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lam Sonthi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phatthana Nikhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sa Bot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Wung",
                "countryCode": "TH"
            },
            {
                "name": "Chai Badan",
                "countryCode": "TH"
            },
            {
                "name": "Lop Buri",
                "countryCode": "TH"
            },
            {
                "name": "Muang Lop Buri District",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Rachan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe In Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khai Bang Rachan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sing Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrom Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Chang",
                "countryCode": "TH"
            },
            {
                "name": "Bang Racham",
                "countryCode": "TH"
            },
            {
                "name": "In Buri",
                "countryCode": "TH"
            },
            {
                "name": "Sing Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hankha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Manorom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chainat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Noen Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Mamong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sankhaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sapphaya",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wat Sing",
                "countryCode": "TH"
            },
            {
                "name": "Chai Nat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Mo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaloem Phra Kiat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Phut",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kaeng Khoi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Muak Lek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sara Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Don",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Khae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Saeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Phutthabat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sao Hai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wihan Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Ban Mo",
                "countryCode": "TH"
            },
            {
                "name": "Kaeng Khoi",
                "countryCode": "TH"
            },
            {
                "name": "Nong Khae",
                "countryCode": "TH"
            },
            {
                "name": "Phra Phutthabat",
                "countryCode": "TH"
            },
            {
                "name": "Saraburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bo Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Chan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Si Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phan Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanat Nikhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sattahip",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Racha",
                "countryCode": "TH"
            },
            {
                "name": "Ban Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Ban Ko Lan",
                "countryCode": "TH"
            },
            {
                "name": "Ban Talat Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Bang Lamung",
                "countryCode": "TH"
            },
            {
                "name": "Bang Lamung District",
                "countryCode": "TH"
            },
            {
                "name": "Chon Buri",
                "countryCode": "TH"
            },
            {
                "name": "Ko Si Chang",
                "countryCode": "TH"
            },
            {
                "name": "Mueang Chonburi District",
                "countryCode": "TH"
            },
            {
                "name": "Pattaya",
                "countryCode": "TH"
            },
            {
                "name": "Phan Thong",
                "countryCode": "TH"
            },
            {
                "name": "Phanat Nikhom",
                "countryCode": "TH"
            },
            {
                "name": "Sattahip",
                "countryCode": "TH"
            },
            {
                "name": "Si Racha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Khai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Chamao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Klaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Rayong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nikhom Phattana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pluak Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Chan",
                "countryCode": "TH"
            },
            {
                "name": "Ban Chang",
                "countryCode": "TH"
            },
            {
                "name": "Ban Phe",
                "countryCode": "TH"
            },
            {
                "name": "Klaeng",
                "countryCode": "TH"
            },
            {
                "name": "Rayong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kaeng Hang Maeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Khitchakut",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Laem Sing",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Makham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chanthaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Yai Am",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pong Nam Ron",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Soi Dao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Mai",
                "countryCode": "TH"
            },
            {
                "name": "Chanthaburi",
                "countryCode": "TH"
            },
            {
                "name": "Khlung",
                "countryCode": "TH"
            },
            {
                "name": "Laem Sing",
                "countryCode": "TH"
            },
            {
                "name": "Pong Nam Ron",
                "countryCode": "TH"
            },
            {
                "name": "Tha Mai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bo Rai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Saming",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Kut",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Laem Ngop",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Trat",
                "countryCode": "TH"
            },
            {
                "name": "Bo Rai",
                "countryCode": "TH"
            },
            {
                "name": "Khlong Yai",
                "countryCode": "TH"
            },
            {
                "name": "Ko Chang Tai",
                "countryCode": "TH"
            },
            {
                "name": "Trat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Pho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Khla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Nam Priao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Pakong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Khuean",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chachoengsao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanom Sarakham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Plaeng Yao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ratchasan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sanam Chai Khet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Takiap",
                "countryCode": "TH"
            },
            {
                "name": "Bang Khla",
                "countryCode": "TH"
            },
            {
                "name": "Bang Nam Priao",
                "countryCode": "TH"
            },
            {
                "name": "Bang Pakong",
                "countryCode": "TH"
            },
            {
                "name": "Chachoengsao",
                "countryCode": "TH"
            },
            {
                "name": "Khlong Khuean",
                "countryCode": "TH"
            },
            {
                "name": "Phanom Sarakham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kabin Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Prachin Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Di",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prachantakham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Maha Phot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Mahosot",
                "countryCode": "TH"
            },
            {
                "name": "Kabin Buri",
                "countryCode": "TH"
            },
            {
                "name": "Prachin Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Na",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Nayok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ongkharak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Phli",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Nayok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Aranyaprathet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Chakan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Hat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Sung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sa Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ta Phraya",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Nam Yen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Sombun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Watthana Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Aranyaprathet",
                "countryCode": "TH"
            },
            {
                "name": "Sa Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Wang Nam Yen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Lueam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bua Lai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bua Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chakkarat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaloem Phra Kiat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chok Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chum Phuang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dan Khun Thot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Thalaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kaeng Sanam Nang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kham Sakae Saeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kham Thale So",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khon Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lam Thamen Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Ratchasima",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Yang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Sung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Thai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Bun Mak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Chong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Thong Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phimai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Thong Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prathai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sida",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sikhio",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sikhiu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Soeng Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sung Noen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thepharak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Nam Khiao",
                "countryCode": "TH"
            },
            {
                "name": "Ban Huai Thalaeng",
                "countryCode": "TH"
            },
            {
                "name": "Bua Yai",
                "countryCode": "TH"
            },
            {
                "name": "Chok Chai",
                "countryCode": "TH"
            },
            {
                "name": "Dan Khun Thot",
                "countryCode": "TH"
            },
            {
                "name": "Kham Sakae Saeng",
                "countryCode": "TH"
            },
            {
                "name": "Khon Buri",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Ratchasima",
                "countryCode": "TH"
            },
            {
                "name": "Non Sung",
                "countryCode": "TH"
            },
            {
                "name": "Non Thai",
                "countryCode": "TH"
            },
            {
                "name": "Pak Chong",
                "countryCode": "TH"
            },
            {
                "name": "Pak Thong Chai",
                "countryCode": "TH"
            },
            {
                "name": "Phimai",
                "countryCode": "TH"
            },
            {
                "name": "Soeng Sang",
                "countryCode": "TH"
            },
            {
                "name": "Sung Noen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Dan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Kruat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Mai Chaiyaphot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaloem Phra Kiat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chamni",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Rat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khaen Dong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khu Mueang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Krasang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lahan Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lam Plai Mat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Buriram",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Pho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nang Rong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Din Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Suwan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Hong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Ki",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phlapphla Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phutthaisong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prakhon Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Satuek",
                "countryCode": "TH"
            },
            {
                "name": "Buri Ram",
                "countryCode": "TH"
            },
            {
                "name": "Nang Rong",
                "countryCode": "TH"
            },
            {
                "name": "Nong Ki",
                "countryCode": "TH"
            },
            {
                "name": "Prakhon Chai",
                "countryCode": "TH"
            },
            {
                "name": "Satuek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bua Chet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chom Phra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chumphon Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kap Choeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khwao Sinarin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lamduan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Surin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Narai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanom Dong Rak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prasat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rattanaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Samrong Thap",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sangkha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Khoraphum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Narong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Tum",
                "countryCode": "TH"
            },
            {
                "name": "Surin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Benchalak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bueng Bun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Thap Than",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kantharalak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kanthararom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khukhan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khun Han",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sisaket",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Kliang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Khun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phayu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Si Suwan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrai Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Sing",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prang Ku",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rasi Salai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Rattana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sila Lat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Uthumphon Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Hin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yang Chum Noi",
                "countryCode": "TH"
            },
            {
                "name": "Kantharalak",
                "countryCode": "TH"
            },
            {
                "name": "Phrai Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Si Sa Ket",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Buntharik",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Det Udom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Mot Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khemmarat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khong Chiam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khueang Nai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kut Khaopun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lao Suea Kok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Muang Sam Sip",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Ubon Ratchathani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Chaluai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Tan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Yia",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Khun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Yuen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phibun Mangsahan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Samrong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawang Wirawong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Mueang Mai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sirindhorn",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tan Sum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Si Udom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Trakan Phuet Phon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Warin Chamrap",
                "countryCode": "TH"
            },
            {
                "name": "Det Udom",
                "countryCode": "TH"
            },
            {
                "name": "Phibun Mangsahan",
                "countryCode": "TH"
            },
            {
                "name": "Sawang Wirawong",
                "countryCode": "TH"
            },
            {
                "name": "Ubon Ratchathani",
                "countryCode": "TH"
            },
            {
                "name": "Warin Chamrap",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kham Khuan Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kho Wang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kut Chum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Loeng Nok Tha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Maha Chana Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Yasothon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Tio",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Mun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thai Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Yasothon",
                "countryCode": "TH"
            },
            {
                "name": "Bamnet Narong",
                "countryCode": "TH"
            },
            {
                "name": "Chaiyaphum",
                "countryCode": "TH"
            },
            {
                "name": "Kaeng Khro",
                "countryCode": "TH"
            },
            {
                "name": "Kaset Sombun",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Nayok",
                "countryCode": "TH"
            },
            {
                "name": "Phu Khiao",
                "countryCode": "TH"
            },
            {
                "name": "Amnat Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chanuman",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hua Taphan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lue Amnat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Amnat Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pathum Ratchawongsa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Senangkhanikhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bueng Khong Long",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bung Khla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Bueng Kan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Khat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Seka",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Wilai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe So Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Bueng Kan",
                "countryCode": "TH"
            },
            {
                "name": "Seka",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nong Bua Lamphu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Klang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Wang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Bun Rueang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Suwannakhuha",
                "countryCode": "TH"
            },
            {
                "name": "Na Klang",
                "countryCode": "TH"
            },
            {
                "name": "Non Sang",
                "countryCode": "TH"
            },
            {
                "name": "Nong Bua Lamphu",
                "countryCode": "TH"
            },
            {
                "name": "Si Bun Rueang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Fang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Haet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Phai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chonnabot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chum Phae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Suan Kwang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Pho Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kranuan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mancha Khiri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Khon Kaen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Phong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Sila",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Na Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Ruea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Song Hong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Yuen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Pha Man",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Wiang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pueai Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sam Sung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Chom Phu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ubolratana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Waeng Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Waeng Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Kao",
                "countryCode": "TH"
            },
            {
                "name": "Ban Phai",
                "countryCode": "TH"
            },
            {
                "name": "Chum Phae",
                "countryCode": "TH"
            },
            {
                "name": "Khon Kaen",
                "countryCode": "TH"
            },
            {
                "name": "Kranuan",
                "countryCode": "TH"
            },
            {
                "name": "Phon",
                "countryCode": "TH"
            },
            {
                "name": "Pueai Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Dung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Phue",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chai Wan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ku Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kumphawapi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kut Chap",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Udon Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Yung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Som",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Non Sa-at",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Han",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Saeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Wua So",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phibun Rak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Prachak Sinlapakhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sang Khom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si That",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Fon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Sam Mo",
                "countryCode": "TH"
            },
            {
                "name": "Ban Dung",
                "countryCode": "TH"
            },
            {
                "name": "Ban Nong Wua So",
                "countryCode": "TH"
            },
            {
                "name": "Ban Phan Don",
                "countryCode": "TH"
            },
            {
                "name": "Kumphawapi",
                "countryCode": "TH"
            },
            {
                "name": "Kut Chap",
                "countryCode": "TH"
            },
            {
                "name": "Nam Som",
                "countryCode": "TH"
            },
            {
                "name": "Nong Wua So",
                "countryCode": "TH"
            },
            {
                "name": "Udon Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Khan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dan Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Erawan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Loei",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Duang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Haeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Hin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Chom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pha Khao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Kradueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Ruea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Li",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Saphung",
                "countryCode": "TH"
            },
            {
                "name": "Loei",
                "countryCode": "TH"
            },
            {
                "name": "Phu Kradueng",
                "countryCode": "TH"
            },
            {
                "name": "Wang Saphung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Fao Rai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nong Khai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Tak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phonphisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rattanawapi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sa Khrai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sangkhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Chiang Mai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Bo",
                "countryCode": "TH"
            },
            {
                "name": "Nong Khai",
                "countryCode": "TH"
            },
            {
                "name": "Phon Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Sangkhom",
                "countryCode": "TH"
            },
            {
                "name": "Si Chiang Mai",
                "countryCode": "TH"
            },
            {
                "name": "Tha Bo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Borabue",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Yuen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chuen Chom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kae Dam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kantharawichai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kosum Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kut Rang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Maha Sarakham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Chueak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Dun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phayakkhaphum Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wapi Pathum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yang Si Surat",
                "countryCode": "TH"
            },
            {
                "name": "Kosum Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Maha Sarakham",
                "countryCode": "TH"
            },
            {
                "name": "Phayakkhaphum Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Wapi Pathum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe At Samat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Changhan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaturaphak Phiman",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Khwan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kaset Wisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Moeiwadi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Roi Et",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Suang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Hi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Phok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pathum Rat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanom Phrai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Selaphum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Somdet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Suwannaphum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thawatchaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Khao Luang",
                "countryCode": "TH"
            },
            {
                "name": "Ban Selaphum",
                "countryCode": "TH"
            },
            {
                "name": "Kaset Wisai",
                "countryCode": "TH"
            },
            {
                "name": "Moeiwadi",
                "countryCode": "TH"
            },
            {
                "name": "Pho Chai",
                "countryCode": "TH"
            },
            {
                "name": "Roi Et",
                "countryCode": "TH"
            },
            {
                "name": "Suwannaphum",
                "countryCode": "TH"
            },
            {
                "name": "Waeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Chan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Mek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Phueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kamalasai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kham Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Wong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khong Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kuchinarai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Kalasin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Khu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Mon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Kung Si",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rong Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sahatsakhan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sam Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Somdet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Khantho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yang Talat",
                "countryCode": "TH"
            },
            {
                "name": "Huai Mek",
                "countryCode": "TH"
            },
            {
                "name": "Kalasin",
                "countryCode": "TH"
            },
            {
                "name": "Kamalasai",
                "countryCode": "TH"
            },
            {
                "name": "Khao Wong",
                "countryCode": "TH"
            },
            {
                "name": "Kuchinarai",
                "countryCode": "TH"
            },
            {
                "name": "Nong Kung Si",
                "countryCode": "TH"
            },
            {
                "name": "Yang Talat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Akat Amnuai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Charoen Sin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kham Ta Kla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Si Suphan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kusuman",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kut Bak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sakon Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nikhom Nam Un",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phang Khon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanna Nikhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon Na Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Phan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawang Daen Din",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Song Dao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tao Ngoi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wanon Niwat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Waritchaphum",
                "countryCode": "TH"
            },
            {
                "name": "Sakon Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Sawang Daen Din",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Phaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Phanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Kae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Thom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Wa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phon Sawan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pla Pak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Renu Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Songkhram",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Uthen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe That Phanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wangyang",
                "countryCode": "TH"
            },
            {
                "name": "Na Wa",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Phanom",
                "countryCode": "TH"
            },
            {
                "name": "That Phanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Tan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dong Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khamcha-i",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Mukdahan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nikhom Kham Soi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Sung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wan Yai",
                "countryCode": "TH"
            },
            {
                "name": "Mukdahan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chai Prakan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Dao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chom Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Doi Lo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Doi Saket",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Doi Tao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Fang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Galyani Vadhana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hang Dong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Ai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Chaem",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae On",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Rim",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Taeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Wang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chiang Mai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Om Koi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Samoeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe San Kamphaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe San Pa Tong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe San Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Saraphi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Haeng",
                "countryCode": "TH"
            },
            {
                "name": "Chai Prakan",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Mai",
                "countryCode": "TH"
            },
            {
                "name": "Chom Thong",
                "countryCode": "TH"
            },
            {
                "name": "Fang",
                "countryCode": "TH"
            },
            {
                "name": "Hang Dong",
                "countryCode": "TH"
            },
            {
                "name": "Pai",
                "countryCode": "TH"
            },
            {
                "name": "San Kamphaeng",
                "countryCode": "TH"
            },
            {
                "name": "San Pa Tong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Hong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Thi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Li",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Tha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Lamphun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Hua Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Nong Long",
                "countryCode": "TH"
            },
            {
                "name": "Lamphun",
                "countryCode": "TH"
            },
            {
                "name": "Mae Tha",
                "countryCode": "TH"
            },
            {
                "name": "Pa Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chae Hom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hang Chat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Kha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Mo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Phrik",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Tha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Lampang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Pan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ngao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Soem Ngam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sop Prap",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Nuea",
                "countryCode": "TH"
            },
            {
                "name": "Lampang",
                "countryCode": "TH"
            },
            {
                "name": "Thoen",
                "countryCode": "TH"
            },
            {
                "name": "Wang Nuea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Khok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Fak Tha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lap Lae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Uttaradit",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Pat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phichai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Pla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thong Saen Khan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tron",
                "countryCode": "TH"
            },
            {
                "name": "Uttaradit",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Den Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Long",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phrae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Muang Khai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rong Kwang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Song",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sung Men",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Chin",
                "countryCode": "TH"
            },
            {
                "name": "Den Chai",
                "countryCode": "TH"
            },
            {
                "name": "Long",
                "countryCode": "TH"
            },
            {
                "name": "Phrae",
                "countryCode": "TH"
            },
            {
                "name": "Rong Kwang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bo Kluea",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaloem Phra Kiat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Klang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Charim",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Muen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Phiang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pua",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Santi Suk",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Song Khwae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Wang Pha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Sa",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Klang",
                "countryCode": "TH"
            },
            {
                "name": "Nan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Kham",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Muan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dok Kham Tai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phayao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Kam Yao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phu Sang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pong",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Kham",
                "countryCode": "TH"
            },
            {
                "name": "Dok Kham Tai",
                "countryCode": "TH"
            },
            {
                "name": "Mae Chai",
                "countryCode": "TH"
            },
            {
                "name": "Phayao",
                "countryCode": "TH"
            },
            {
                "name": "Pong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Khong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chiang Saen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Doi Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khun Tan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Chan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Fa Luang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Lao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Suai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chiang Rai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Daet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phaya Mengrai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thoeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Chai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Chiang Rung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Kaen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Pa Pao",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Khong",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Rai",
                "countryCode": "TH"
            },
            {
                "name": "Chiang Saen",
                "countryCode": "TH"
            },
            {
                "name": "Mae Chan",
                "countryCode": "TH"
            },
            {
                "name": "Mae Sai",
                "countryCode": "TH"
            },
            {
                "name": "Pa Daet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khun Yuam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae La Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Sariang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Mae Hong Son",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pang Mapha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sop Moei",
                "countryCode": "TH"
            },
            {
                "name": "Mae Hong Son",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Banphot Phisai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chum Ta Bong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chumsaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kao Liao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Krok Phra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lat Yao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Poen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Wong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Sawan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Bua",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phai Sali",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phayuha Khiri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tak Fa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Takhli",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Tako",
                "countryCode": "TH"
            },
            {
                "name": "Kao Liao",
                "countryCode": "TH"
            },
            {
                "name": "Lat Yao",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Sawan",
                "countryCode": "TH"
            },
            {
                "name": "Nong Bua",
                "countryCode": "TH"
            },
            {
                "name": "Phai Sali",
                "countryCode": "TH"
            },
            {
                "name": "Phayuha Khiri",
                "countryCode": "TH"
            },
            {
                "name": "Takhli",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Rai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Khot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lan Sak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Uthai Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Khayang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawang Arom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thap Than",
                "countryCode": "TH"
            },
            {
                "name": "Huai Khot",
                "countryCode": "TH"
            },
            {
                "name": "Lan Sak",
                "countryCode": "TH"
            },
            {
                "name": "Thap Than",
                "countryCode": "TH"
            },
            {
                "name": "Uthai Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bueng Samakkhi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khanu Woralaksaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Khlung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Lan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kosamphi Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lan Krabue",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Kamphaeng Phet",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pang Sila Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phran Kratai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Ngam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Thong Watthana",
                "countryCode": "TH"
            },
            {
                "name": "Kamphaeng Phet",
                "countryCode": "TH"
            },
            {
                "name": "Khanu Woralaksaburi",
                "countryCode": "TH"
            },
            {
                "name": "Lan Krabue",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Tak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Ramat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Sot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Tak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phop Phra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Song Yang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Umphang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Chao",
                "countryCode": "TH"
            },
            {
                "name": "Ban Tak",
                "countryCode": "TH"
            },
            {
                "name": "Mae Ramat",
                "countryCode": "TH"
            },
            {
                "name": "Mae Sot",
                "countryCode": "TH"
            },
            {
                "name": "Tak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Dan Lan Hoi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khiri Mat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kong Krailat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Sukhothai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawankhalok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Nakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Samrong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Satchanalai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Saliam",
                "countryCode": "TH"
            },
            {
                "name": "Ban Dan Lan Hoi",
                "countryCode": "TH"
            },
            {
                "name": "Ban Na",
                "countryCode": "TH"
            },
            {
                "name": "Khiri Mat",
                "countryCode": "TH"
            },
            {
                "name": "Sawankhalok",
                "countryCode": "TH"
            },
            {
                "name": "Si Satchanalai",
                "countryCode": "TH"
            },
            {
                "name": "Sukhothai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Krathum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Rakam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chat Trakan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phitsanulok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nakhon Thai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Noen Maprang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrom Phiram",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Thong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wat Bot",
                "countryCode": "TH"
            },
            {
                "name": "Bang Krathum",
                "countryCode": "TH"
            },
            {
                "name": "Bang Rakam",
                "countryCode": "TH"
            },
            {
                "name": "Chat Trakan",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Thai",
                "countryCode": "TH"
            },
            {
                "name": "Phitsanulok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Mun Nak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bueng Na Rang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dong Charoen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phichit",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Prathap Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pho Thale",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sak Lek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Taphan Hin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thap Khlo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wachira Barami",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Sai Phun",
                "countryCode": "TH"
            },
            {
                "name": "Bang Mun Nak",
                "countryCode": "TH"
            },
            {
                "name": "Bueng Na Rang",
                "countryCode": "TH"
            },
            {
                "name": "Phichit",
                "countryCode": "TH"
            },
            {
                "name": "Taphan Hin",
                "countryCode": "TH"
            },
            {
                "name": "Thap Khlo",
                "countryCode": "TH"
            },
            {
                "name": "Wang Sai Phun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bueng Sam Phan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chon Daen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Kho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lom Kao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lom Sak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phetchabun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nam Nao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Phai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Thep",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Pong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wichian Buri",
                "countryCode": "TH"
            },
            {
                "name": "Chon Daen",
                "countryCode": "TH"
            },
            {
                "name": "Lom Sak",
                "countryCode": "TH"
            },
            {
                "name": "Nong Phai",
                "countryCode": "TH"
            },
            {
                "name": "Phetchabun",
                "countryCode": "TH"
            },
            {
                "name": "Wichian Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Kha",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Pong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Phae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chom Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Damnoen Saduak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Ratchaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Tho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Photharam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Suan Phueng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wat Phleng",
                "countryCode": "TH"
            },
            {
                "name": "Ban Pong",
                "countryCode": "TH"
            },
            {
                "name": "Bang Phae",
                "countryCode": "TH"
            },
            {
                "name": "Chom Bueng",
                "countryCode": "TH"
            },
            {
                "name": "Damnoen Saduak",
                "countryCode": "TH"
            },
            {
                "name": "Photharam",
                "countryCode": "TH"
            },
            {
                "name": "Ratchaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bo Phloi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dan Makham Tia",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Krachao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lao Khwan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Kanchanaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Prue",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanom Thuan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Yok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sangkhla Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Sawat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Maka",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thong Pha Phum",
                "countryCode": "TH"
            },
            {
                "name": "Bo Phloi",
                "countryCode": "TH"
            },
            {
                "name": "Kanchanaburi",
                "countryCode": "TH"
            },
            {
                "name": "Phanom Thuan",
                "countryCode": "TH"
            },
            {
                "name": "Sangkhla Buri",
                "countryCode": "TH"
            },
            {
                "name": "Tha Maka",
                "countryCode": "TH"
            },
            {
                "name": "Tha Muang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Pla Ma",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Dan Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Doembang Nangbuat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Chedi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Suphan Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Yasai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Prachan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Song Phi Nong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe U Thong",
                "countryCode": "TH"
            },
            {
                "name": "Ban Sam Chuk",
                "countryCode": "TH"
            },
            {
                "name": "Doembang Nangbuat",
                "countryCode": "TH"
            },
            {
                "name": "Suphan Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Len",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Tum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Pathom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nakhon Chai Si",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phutthamonthon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sam Phran",
                "countryCode": "TH"
            },
            {
                "name": "Bang Len",
                "countryCode": "TH"
            },
            {
                "name": "Kamphaeng Saen District",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Pathom",
                "countryCode": "TH"
            },
            {
                "name": "Sam Phran",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Phaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Krathum Baen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Samut Sakhon",
                "countryCode": "TH"
            },
            {
                "name": "Ban Phaeo",
                "countryCode": "TH"
            },
            {
                "name": "Krathum Baen",
                "countryCode": "TH"
            },
            {
                "name": "Samut Sakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Amphawa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Khonthi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Samut Songkhram",
                "countryCode": "TH"
            },
            {
                "name": "Samut Songkhram",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Laem",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Lat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Cha-am",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kaeng Krachan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Yoi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phetchaburi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Ya Plong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Yang",
                "countryCode": "TH"
            },
            {
                "name": "Cha-am",
                "countryCode": "TH"
            },
            {
                "name": "Khao Yoi",
                "countryCode": "TH"
            },
            {
                "name": "Phetchaburi",
                "countryCode": "TH"
            },
            {
                "name": "Tha Yang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Saphan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Saphan Noi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hua Hin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kui Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Prachuap Khiri Khan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pran Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sam Roi Yot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thap Sakae",
                "countryCode": "TH"
            },
            {
                "name": "Bang Saphan",
                "countryCode": "TH"
            },
            {
                "name": "Hua Hin",
                "countryCode": "TH"
            },
            {
                "name": "Kui Buri",
                "countryCode": "TH"
            },
            {
                "name": "Prachuap Khiri Khan",
                "countryCode": "TH"
            },
            {
                "name": "Pran Buri",
                "countryCode": "TH"
            },
            {
                "name": "Sam Roi Yot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Khan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Cha-uat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaloem Phra Kiat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chang Klang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chawang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chian Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chulabhorn",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hua Sai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lan Saka",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Nakhon Si Thammarat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Bon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nopphitam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Phanang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phipun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phra Phrom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrom Khiri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ron Phibun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sichon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Sala",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tham Phannara",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Song",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Yai",
                "countryCode": "TH"
            },
            {
                "name": "Chawang",
                "countryCode": "TH"
            },
            {
                "name": "Nakhon Si Thammarat",
                "countryCode": "TH"
            },
            {
                "name": "Pak Phanang",
                "countryCode": "TH"
            },
            {
                "name": "Ron Phibun",
                "countryCode": "TH"
            },
            {
                "name": "Tham Phannara",
                "countryCode": "TH"
            },
            {
                "name": "Thung Song",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ao Luek",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Phanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Thom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Lanta",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lam Thap",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Krabi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nuea Khlong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Plai Phraya",
                "countryCode": "TH"
            },
            {
                "name": "Ao Luek",
                "countryCode": "TH"
            },
            {
                "name": "Ko Lanta",
                "countryCode": "TH"
            },
            {
                "name": "Krabi",
                "countryCode": "TH"
            },
            {
                "name": "Nuea Khlong",
                "countryCode": "TH"
            },
            {
                "name": "Saladan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kapong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khura Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Yao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phangnga",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Takua Pa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Takua Thung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thai Mueang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thap Put",
                "countryCode": "TH"
            },
            {
                "name": "Ban Ao Nang",
                "countryCode": "TH"
            },
            {
                "name": "Ban Khao Lak",
                "countryCode": "TH"
            },
            {
                "name": "Ban Phru Nai",
                "countryCode": "TH"
            },
            {
                "name": "Phang Nga",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kathu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phuket",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thalang",
                "countryCode": "TH"
            },
            {
                "name": "Ban Chalong",
                "countryCode": "TH"
            },
            {
                "name": "Ban Karon",
                "countryCode": "TH"
            },
            {
                "name": "Ban Kata",
                "countryCode": "TH"
            },
            {
                "name": "Ban Ko Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Ban Mai Khao",
                "countryCode": "TH"
            },
            {
                "name": "Ban Ratsada",
                "countryCode": "TH"
            },
            {
                "name": "Ban Talat Nua",
                "countryCode": "TH"
            },
            {
                "name": "Ban Talat Yai",
                "countryCode": "TH"
            },
            {
                "name": "Kathu",
                "countryCode": "TH"
            },
            {
                "name": "Nai Harn",
                "countryCode": "TH"
            },
            {
                "name": "Patong",
                "countryCode": "TH"
            },
            {
                "name": "Phuket",
                "countryCode": "TH"
            },
            {
                "name": "Rawai",
                "countryCode": "TH"
            },
            {
                "name": "Wichit",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Na Doem",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Na San",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ban Takhun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chai Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chaiya",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Don Sak",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kanchanadit",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khian Sa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khiri Rat Nikhom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Pha-ngan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ko Samui",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Surat Thani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phanom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phrasaeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phunphin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Chana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Chang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiang Sa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wiphawadi",
                "countryCode": "TH"
            },
            {
                "name": "Ban Na San",
                "countryCode": "TH"
            },
            {
                "name": "Chai Buri",
                "countryCode": "TH"
            },
            {
                "name": "Chaiya",
                "countryCode": "TH"
            },
            {
                "name": "Don Sak",
                "countryCode": "TH"
            },
            {
                "name": "Kanchanadit",
                "countryCode": "TH"
            },
            {
                "name": "Ko Pha Ngan",
                "countryCode": "TH"
            },
            {
                "name": "Ko Samui",
                "countryCode": "TH"
            },
            {
                "name": "Koh Tao",
                "countryCode": "TH"
            },
            {
                "name": "Surat Thani",
                "countryCode": "TH"
            },
            {
                "name": "Tha Kham",
                "countryCode": "TH"
            },
            {
                "name": "Tha Khanon",
                "countryCode": "TH"
            },
            {
                "name": "Wiang Sa",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kapoe",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kra Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe La-un",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Ranong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Suk Samran",
                "countryCode": "TH"
            },
            {
                "name": "Ranong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lamae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Lang Suan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Chumphon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pathio",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Phato",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sawi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Sae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Tako",
                "countryCode": "TH"
            },
            {
                "name": "Ban Nam Yuen",
                "countryCode": "TH"
            },
            {
                "name": "Chumphon",
                "countryCode": "TH"
            },
            {
                "name": "Lang Suan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Klam",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chana",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hat Yai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khlong Hoi Khong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khuan Niang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Krasae Sin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Songkhla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Mom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Thawi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ranot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rattaphum",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Saba Yoi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sadao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sathing Phra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Singhanakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thepha",
                "countryCode": "TH"
            },
            {
                "name": "Ban Mai",
                "countryCode": "TH"
            },
            {
                "name": "Hat Yai",
                "countryCode": "TH"
            },
            {
                "name": "Na Mom",
                "countryCode": "TH"
            },
            {
                "name": "Ranot",
                "countryCode": "TH"
            },
            {
                "name": "Sadao",
                "countryCode": "TH"
            },
            {
                "name": "Songkhla",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khuan Don",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khuan Kalong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe La-Ngu",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Manang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Satun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tha Phae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Wa",
                "countryCode": "TH"
            },
            {
                "name": "Satun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Hat Samran",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Huai Yot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kantang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Trang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Na Yong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Palian",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ratsada",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sikao",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Wang Wiset",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yan Ta Khao",
                "countryCode": "TH"
            },
            {
                "name": "Huai Yot",
                "countryCode": "TH"
            },
            {
                "name": "Kantang",
                "countryCode": "TH"
            },
            {
                "name": "Trang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bang Kaeo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khao Chaison",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khuan Khanun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kong Ra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Phatthalung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Bon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pa Phayom",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Pak Phayun",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Banphot",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Srinagarindra",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tamot",
                "countryCode": "TH"
            },
            {
                "name": "Phatthalung",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kapho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Khok Pho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mae Lan",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mai Kaen",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mayo",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Pattani",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Nong Chik",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Panare",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sai Buri",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Thung Yang Daeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yarang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yaring",
                "countryCode": "TH"
            },
            {
                "name": "Nong Chik",
                "countryCode": "TH"
            },
            {
                "name": "Pattani",
                "countryCode": "TH"
            },
            {
                "name": "Sai Buri",
                "countryCode": "TH"
            },
            {
                "name": "Yaring",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Bannang Sata",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Betong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Kabang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Krong Pinang",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Yala",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Raman",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Than To",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yaha",
                "countryCode": "TH"
            },
            {
                "name": "Betong",
                "countryCode": "TH"
            },
            {
                "name": "Yala",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ba Cho",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Chanae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Cho-airong",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Mueang Narathiwat",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Ra-ngae",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Rueso",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Si Sakhon",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Su-ngai Kolok",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Su-ngai Padi",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Sukhirin",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Tak Bai",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Waeng",
                "countryCode": "TH"
            },
            {
                "name": "Amphoe Yi-ngo",
                "countryCode": "TH"
            },
            {
                "name": "Ban Su-ngai Pa Di",
                "countryCode": "TH"
            },
            {
                "name": "Narathiwat",
                "countryCode": "TH"
            },
            {
                "name": "Ra-ngae",
                "countryCode": "TH"
            },
            {
                "name": "Su-ngai Kolok",
                "countryCode": "TH"
            },
            {
                "name": "Tak Bai",
                "countryCode": "TH"
            }
        ]
    },
    {
        "name": "Togo",
        "phonecode": "228",
        "cities": [
            {
                "name": "Sokodé",
                "countryCode": "TG"
            },
            {
                "name": "Sotouboua",
                "countryCode": "TG"
            },
            {
                "name": "Tchamba",
                "countryCode": "TG"
            },
            {
                "name": "Bafilo",
                "countryCode": "TG"
            },
            {
                "name": "Bassar",
                "countryCode": "TG"
            },
            {
                "name": "Kandé",
                "countryCode": "TG"
            },
            {
                "name": "Kara",
                "countryCode": "TG"
            },
            {
                "name": "Niamtougou",
                "countryCode": "TG"
            },
            {
                "name": "Pagouda",
                "countryCode": "TG"
            },
            {
                "name": "Préfecture de Bassar",
                "countryCode": "TG"
            },
            {
                "name": "Aného",
                "countryCode": "TG"
            },
            {
                "name": "Lomé",
                "countryCode": "TG"
            },
            {
                "name": "Tabligbo",
                "countryCode": "TG"
            },
            {
                "name": "Tsévié",
                "countryCode": "TG"
            },
            {
                "name": "Vogan",
                "countryCode": "TG"
            },
            {
                "name": "Amlamé",
                "countryCode": "TG"
            },
            {
                "name": "Atakpamé",
                "countryCode": "TG"
            },
            {
                "name": "Badou",
                "countryCode": "TG"
            },
            {
                "name": "Kpalimé",
                "countryCode": "TG"
            },
            {
                "name": "Notsé",
                "countryCode": "TG"
            },
            {
                "name": "Dapaong",
                "countryCode": "TG"
            },
            {
                "name": "Sansanné-Mango",
                "countryCode": "TG"
            }
        ]
    },
    {
        "name": "Tokelau",
        "phonecode": "690",
        "cities": []
    },
    {
        "name": "Tonga",
        "phonecode": "676",
        "cities": [
            {
                "name": "‘Ohonua",
                "countryCode": "TO"
            },
            {
                "name": "Pangai",
                "countryCode": "TO"
            },
            {
                "name": "Hihifo",
                "countryCode": "TO"
            },
            {
                "name": "Haveluloto",
                "countryCode": "TO"
            },
            {
                "name": "Kolonga",
                "countryCode": "TO"
            },
            {
                "name": "Nuku‘alofa",
                "countryCode": "TO"
            },
            {
                "name": "Vaini",
                "countryCode": "TO"
            },
            {
                "name": "Neiafu",
                "countryCode": "TO"
            }
        ]
    },
    {
        "name": "Trinidad And Tobago",
        "phonecode": "+1-868",
        "cities": [
            {
                "name": "Arima",
                "countryCode": "TT"
            },
            {
                "name": "Chaguanas",
                "countryCode": "TT"
            },
            {
                "name": "Ward of Chaguanas",
                "countryCode": "TT"
            },
            {
                "name": "Couva",
                "countryCode": "TT"
            },
            {
                "name": "Tabaquite",
                "countryCode": "TT"
            },
            {
                "name": "Petit Valley",
                "countryCode": "TT"
            },
            {
                "name": "Ward of Diego Martin",
                "countryCode": "TT"
            },
            {
                "name": "Scarborough",
                "countryCode": "TT"
            },
            {
                "name": "Debe",
                "countryCode": "TT"
            },
            {
                "name": "Peñal",
                "countryCode": "TT"
            },
            {
                "name": "Mucurapo",
                "countryCode": "TT"
            },
            {
                "name": "Port of Spain",
                "countryCode": "TT"
            },
            {
                "name": "Princes Town",
                "countryCode": "TT"
            },
            {
                "name": "Point Fortin",
                "countryCode": "TT"
            },
            {
                "name": "Marabella",
                "countryCode": "TT"
            },
            {
                "name": "Mon Repos",
                "countryCode": "TT"
            },
            {
                "name": "San Fernando",
                "countryCode": "TT"
            },
            {
                "name": "Sangre Grande",
                "countryCode": "TT"
            },
            {
                "name": "Siparia",
                "countryCode": "TT"
            },
            {
                "name": "Ward of Siparia",
                "countryCode": "TT"
            },
            {
                "name": "Laventille",
                "countryCode": "TT"
            },
            {
                "name": "Arouca",
                "countryCode": "TT"
            },
            {
                "name": "Paradise",
                "countryCode": "TT"
            },
            {
                "name": "Tunapuna",
                "countryCode": "TT"
            },
            {
                "name": "Rio Claro",
                "countryCode": "TT"
            }
        ]
    },
    {
        "name": "Tunisia",
        "phonecode": "216",
        "cities": [
            {
                "name": "Al Marsá",
                "countryCode": "TN"
            },
            {
                "name": "Carthage",
                "countryCode": "TN"
            },
            {
                "name": "La Goulette",
                "countryCode": "TN"
            },
            {
                "name": "La Mohammedia",
                "countryCode": "TN"
            },
            {
                "name": "Sidi Bou Saïd",
                "countryCode": "TN"
            },
            {
                "name": "Tunis",
                "countryCode": "TN"
            },
            {
                "name": "Ariana",
                "countryCode": "TN"
            },
            {
                "name": "Galaat el Andeless",
                "countryCode": "TN"
            },
            {
                "name": "Ben Arous",
                "countryCode": "TN"
            },
            {
                "name": "Hammam Lif",
                "countryCode": "TN"
            },
            {
                "name": "Hammam-Lif",
                "countryCode": "TN"
            },
            {
                "name": "La Sebala du Mornag",
                "countryCode": "TN"
            },
            {
                "name": "Radès",
                "countryCode": "TN"
            },
            {
                "name": "El Battan",
                "countryCode": "TN"
            },
            {
                "name": "Manouba",
                "countryCode": "TN"
            },
            {
                "name": "Mu‘tamadīyat Manūbah",
                "countryCode": "TN"
            },
            {
                "name": "Oued Lill",
                "countryCode": "TN"
            },
            {
                "name": "El Fahs",
                "countryCode": "TN"
            },
            {
                "name": "Zaghouan",
                "countryCode": "TN"
            },
            {
                "name": "Al Matlīn",
                "countryCode": "TN"
            },
            {
                "name": "Bizerte",
                "countryCode": "TN"
            },
            {
                "name": "Bizerte Sud",
                "countryCode": "TN"
            },
            {
                "name": "Douar Tindja",
                "countryCode": "TN"
            },
            {
                "name": "El Alia",
                "countryCode": "TN"
            },
            {
                "name": "Mateur",
                "countryCode": "TN"
            },
            {
                "name": "Menzel Abderhaman",
                "countryCode": "TN"
            },
            {
                "name": "Menzel Bourguiba",
                "countryCode": "TN"
            },
            {
                "name": "Menzel Jemil",
                "countryCode": "TN"
            },
            {
                "name": "Rafrāf",
                "countryCode": "TN"
            },
            {
                "name": "Rhar el Melah",
                "countryCode": "TN"
            },
            {
                "name": "Sejenane",
                "countryCode": "TN"
            },
            {
                "name": "Zahānah",
                "countryCode": "TN"
            },
            {
                "name": "Béja",
                "countryCode": "TN"
            },
            {
                "name": "Délégation de Béja Nord",
                "countryCode": "TN"
            },
            {
                "name": "Goubellat",
                "countryCode": "TN"
            },
            {
                "name": "Medjez el Bab",
                "countryCode": "TN"
            },
            {
                "name": "Tabursuq",
                "countryCode": "TN"
            },
            {
                "name": "Testour",
                "countryCode": "TN"
            },
            {
                "name": "Fernana",
                "countryCode": "TN"
            },
            {
                "name": "Jendouba",
                "countryCode": "TN"
            },
            {
                "name": "Oued Meliz",
                "countryCode": "TN"
            },
            {
                "name": "Tabarka",
                "countryCode": "TN"
            },
            {
                "name": "As Sars",
                "countryCode": "TN"
            },
            {
                "name": "El Kef",
                "countryCode": "TN"
            },
            {
                "name": "El Ksour",
                "countryCode": "TN"
            },
            {
                "name": "Menzel Salem",
                "countryCode": "TN"
            },
            {
                "name": "Nibbar",
                "countryCode": "TN"
            },
            {
                "name": "Sakiet Sidi Youssef",
                "countryCode": "TN"
            },
            {
                "name": "Tajerouine",
                "countryCode": "TN"
            },
            {
                "name": "Bou Arada",
                "countryCode": "TN"
            },
            {
                "name": "Gafour",
                "countryCode": "TN"
            },
            {
                "name": "Kesra",
                "countryCode": "TN"
            },
            {
                "name": "Le Krib",
                "countryCode": "TN"
            },
            {
                "name": "Maktar",
                "countryCode": "TN"
            },
            {
                "name": "Siliana",
                "countryCode": "TN"
            },
            {
                "name": "Haffouz",
                "countryCode": "TN"
            },
            {
                "name": "Kairouan",
                "countryCode": "TN"
            },
            {
                "name": "Sbikha",
                "countryCode": "TN"
            },
            {
                "name": "Kasserine",
                "countryCode": "TN"
            },
            {
                "name": "Rohia",
                "countryCode": "TN"
            },
            {
                "name": "Sbiba",
                "countryCode": "TN"
            },
            {
                "name": "Thala",
                "countryCode": "TN"
            },
            {
                "name": "Bir el Hafey",
                "countryCode": "TN"
            },
            {
                "name": "Er Regueb",
                "countryCode": "TN"
            },
            {
                "name": "Jilma",
                "countryCode": "TN"
            },
            {
                "name": "Mezzouna",
                "countryCode": "TN"
            },
            {
                "name": "Sidi Bouzid",
                "countryCode": "TN"
            },
            {
                "name": "Akouda",
                "countryCode": "TN"
            },
            {
                "name": "Hammam Sousse",
                "countryCode": "TN"
            },
            {
                "name": "Harqalah",
                "countryCode": "TN"
            },
            {
                "name": "Msaken",
                "countryCode": "TN"
            },
            {
                "name": "Port el Kantaoui",
                "countryCode": "TN"
            },
            {
                "name": "Sidi Bou Ali",
                "countryCode": "TN"
            },
            {
                "name": "Sidi el Hani",
                "countryCode": "TN"
            },
            {
                "name": "Sousse",
                "countryCode": "TN"
            },
            {
                "name": "Banbalah",
                "countryCode": "TN"
            },
            {
                "name": "Bekalta",
                "countryCode": "TN"
            },
            {
                "name": "Beni Hassane",
                "countryCode": "TN"
            },
            {
                "name": "Djemmal",
                "countryCode": "TN"
            },
            {
                "name": "Ksar Hellal",
                "countryCode": "TN"
            },
            {
                "name": "Ksibet el Mediouni",
                "countryCode": "TN"
            },
            {
                "name": "Lemta",
                "countryCode": "TN"
            },
            {
                "name": "Menzel Kamel",
                "countryCode": "TN"
            },
            {
                "name": "Mesdour",
                "countryCode": "TN"
            },
            {
                "name": "Monastir",
                "countryCode": "TN"
            },
            {
                "name": "Ouardenine",
                "countryCode": "TN"
            },
            {
                "name": "Sahline",
                "countryCode": "TN"
            },
            {
                "name": "Seïada",
                "countryCode": "TN"
            },
            {
                "name": "Sidi Ben Nour",
                "countryCode": "TN"
            },
            {
                "name": "Skanes",
                "countryCode": "TN"
            },
            {
                "name": "Touza",
                "countryCode": "TN"
            },
            {
                "name": "Chebba",
                "countryCode": "TN"
            },
            {
                "name": "Chorbane",
                "countryCode": "TN"
            },
            {
                "name": "El Jem",
                "countryCode": "TN"
            },
            {
                "name": "Ksour Essaf",
                "countryCode": "TN"
            },
            {
                "name": "Mahdia",
                "countryCode": "TN"
            },
            {
                "name": "Melloulèche",
                "countryCode": "TN"
            },
            {
                "name": "Salakta",
                "countryCode": "TN"
            },
            {
                "name": "Sidi Alouane",
                "countryCode": "TN"
            },
            {
                "name": "Zouila",
                "countryCode": "TN"
            },
            {
                "name": "Agareb",
                "countryCode": "TN"
            },
            {
                "name": "Bir Ali Ben Khalifa",
                "countryCode": "TN"
            },
            {
                "name": "Djebeniana",
                "countryCode": "TN"
            },
            {
                "name": "Gremda",
                "countryCode": "TN"
            },
            {
                "name": "Sfax",
                "countryCode": "TN"
            },
            {
                "name": "Skhira",
                "countryCode": "TN"
            },
            {
                "name": "Ar Rudayyif",
                "countryCode": "TN"
            },
            {
                "name": "As Sanad",
                "countryCode": "TN"
            },
            {
                "name": "Gafsa",
                "countryCode": "TN"
            },
            {
                "name": "Metlaoui",
                "countryCode": "TN"
            },
            {
                "name": "Mu‘tamadīyat ar Rudayyif",
                "countryCode": "TN"
            },
            {
                "name": "Chebika",
                "countryCode": "TN"
            },
            {
                "name": "Degache",
                "countryCode": "TN"
            },
            {
                "name": "Nefta",
                "countryCode": "TN"
            },
            {
                "name": "Tamaghzah",
                "countryCode": "TN"
            },
            {
                "name": "Tozeur",
                "countryCode": "TN"
            },
            {
                "name": "Douz",
                "countryCode": "TN"
            },
            {
                "name": "El Golaa",
                "countryCode": "TN"
            },
            {
                "name": "Jemna",
                "countryCode": "TN"
            },
            {
                "name": "Kebili",
                "countryCode": "TN"
            },
            {
                "name": "Bou Attouche",
                "countryCode": "TN"
            },
            {
                "name": "El Hamma",
                "countryCode": "TN"
            },
            {
                "name": "Gabès",
                "countryCode": "TN"
            },
            {
                "name": "Matmata",
                "countryCode": "TN"
            },
            {
                "name": "Ben Gardane",
                "countryCode": "TN"
            },
            {
                "name": "Beni Kheddache",
                "countryCode": "TN"
            },
            {
                "name": "Erriadh",
                "countryCode": "TN"
            },
            {
                "name": "Houmt El Souk",
                "countryCode": "TN"
            },
            {
                "name": "Jerba Midoun",
                "countryCode": "TN"
            },
            {
                "name": "Medenine",
                "countryCode": "TN"
            },
            {
                "name": "Midoun",
                "countryCode": "TN"
            },
            {
                "name": "Zarzis",
                "countryCode": "TN"
            },
            {
                "name": "Remada",
                "countryCode": "TN"
            },
            {
                "name": "Tataouine",
                "countryCode": "TN"
            }
        ]
    },
    {
        "name": "Turkey",
        "phonecode": "90",
        "cities": [
            {
                "name": "Adana",
                "countryCode": "TR"
            },
            {
                "name": "Aladağ",
                "countryCode": "TR"
            },
            {
                "name": "Bahçe",
                "countryCode": "TR"
            },
            {
                "name": "Ceyhan",
                "countryCode": "TR"
            },
            {
                "name": "Feke",
                "countryCode": "TR"
            },
            {
                "name": "Karaisalı",
                "countryCode": "TR"
            },
            {
                "name": "Karataş",
                "countryCode": "TR"
            },
            {
                "name": "Kozan",
                "countryCode": "TR"
            },
            {
                "name": "Pozantı",
                "countryCode": "TR"
            },
            {
                "name": "Saimbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Sarıçam",
                "countryCode": "TR"
            },
            {
                "name": "Seyhan",
                "countryCode": "TR"
            },
            {
                "name": "Tufanbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Yumurtalık",
                "countryCode": "TR"
            },
            {
                "name": "Yüreğir",
                "countryCode": "TR"
            },
            {
                "name": "Çukurova",
                "countryCode": "TR"
            },
            {
                "name": "İmamoğlu",
                "countryCode": "TR"
            },
            {
                "name": "Adıyaman",
                "countryCode": "TR"
            },
            {
                "name": "Aralık İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Besni",
                "countryCode": "TR"
            },
            {
                "name": "Gerger",
                "countryCode": "TR"
            },
            {
                "name": "Gölbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Kâhta",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Samsat",
                "countryCode": "TR"
            },
            {
                "name": "Sincik",
                "countryCode": "TR"
            },
            {
                "name": "Tut",
                "countryCode": "TR"
            },
            {
                "name": "Çelikhan",
                "countryCode": "TR"
            },
            {
                "name": "Afyonkarahisar",
                "countryCode": "TR"
            },
            {
                "name": "Bayat",
                "countryCode": "TR"
            },
            {
                "name": "Başmakçı",
                "countryCode": "TR"
            },
            {
                "name": "Bolvadin",
                "countryCode": "TR"
            },
            {
                "name": "Dazkırı",
                "countryCode": "TR"
            },
            {
                "name": "Dinar",
                "countryCode": "TR"
            },
            {
                "name": "Emirdağ",
                "countryCode": "TR"
            },
            {
                "name": "Evciler",
                "countryCode": "TR"
            },
            {
                "name": "Hocalar",
                "countryCode": "TR"
            },
            {
                "name": "Işıklar",
                "countryCode": "TR"
            },
            {
                "name": "Kızılören",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Sandıklı",
                "countryCode": "TR"
            },
            {
                "name": "Sinanpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Sultandağı",
                "countryCode": "TR"
            },
            {
                "name": "Çay",
                "countryCode": "TR"
            },
            {
                "name": "Çobanlar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İhsaniye",
                "countryCode": "TR"
            },
            {
                "name": "İscehisar",
                "countryCode": "TR"
            },
            {
                "name": "Şuhut",
                "countryCode": "TR"
            },
            {
                "name": "Ağrı",
                "countryCode": "TR"
            },
            {
                "name": "Diyadin",
                "countryCode": "TR"
            },
            {
                "name": "Doğubayazıt",
                "countryCode": "TR"
            },
            {
                "name": "Eleşkirt",
                "countryCode": "TR"
            },
            {
                "name": "Hamur",
                "countryCode": "TR"
            },
            {
                "name": "Patnos",
                "countryCode": "TR"
            },
            {
                "name": "Taşlıçay",
                "countryCode": "TR"
            },
            {
                "name": "Tutak İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Amasya",
                "countryCode": "TR"
            },
            {
                "name": "Dedeköy",
                "countryCode": "TR"
            },
            {
                "name": "Göynücek",
                "countryCode": "TR"
            },
            {
                "name": "Gümüşhacıköy",
                "countryCode": "TR"
            },
            {
                "name": "Hamamözü İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Merzifon İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Suluova",
                "countryCode": "TR"
            },
            {
                "name": "Taşova",
                "countryCode": "TR"
            },
            {
                "name": "Akyurt",
                "countryCode": "TR"
            },
            {
                "name": "Altpınar",
                "countryCode": "TR"
            },
            {
                "name": "Altındağ",
                "countryCode": "TR"
            },
            {
                "name": "Ankara",
                "countryCode": "TR"
            },
            {
                "name": "Ayaş",
                "countryCode": "TR"
            },
            {
                "name": "Bala İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Batikent",
                "countryCode": "TR"
            },
            {
                "name": "Beypazari",
                "countryCode": "TR"
            },
            {
                "name": "Beypazarı",
                "countryCode": "TR"
            },
            {
                "name": "Elmadağ",
                "countryCode": "TR"
            },
            {
                "name": "Etimesgut İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Evren",
                "countryCode": "TR"
            },
            {
                "name": "Gölbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Güdül",
                "countryCode": "TR"
            },
            {
                "name": "Haymana",
                "countryCode": "TR"
            },
            {
                "name": "Kahramankazan",
                "countryCode": "TR"
            },
            {
                "name": "Kalecik",
                "countryCode": "TR"
            },
            {
                "name": "Kazan",
                "countryCode": "TR"
            },
            {
                "name": "Keçiören",
                "countryCode": "TR"
            },
            {
                "name": "Kızılcahamam",
                "countryCode": "TR"
            },
            {
                "name": "Mamak İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Nallıhan",
                "countryCode": "TR"
            },
            {
                "name": "Polatlı",
                "countryCode": "TR"
            },
            {
                "name": "Pursaklar",
                "countryCode": "TR"
            },
            {
                "name": "Sincan İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Yenimahalle",
                "countryCode": "TR"
            },
            {
                "name": "Çamlıdere",
                "countryCode": "TR"
            },
            {
                "name": "Çankaya",
                "countryCode": "TR"
            },
            {
                "name": "Çubuk",
                "countryCode": "TR"
            },
            {
                "name": "Şereflikoçhisar",
                "countryCode": "TR"
            },
            {
                "name": "Akseki",
                "countryCode": "TR"
            },
            {
                "name": "Aksu",
                "countryCode": "TR"
            },
            {
                "name": "Alanya",
                "countryCode": "TR"
            },
            {
                "name": "Antalya",
                "countryCode": "TR"
            },
            {
                "name": "Avsallar",
                "countryCode": "TR"
            },
            {
                "name": "Belek",
                "countryCode": "TR"
            },
            {
                "name": "Beykonak",
                "countryCode": "TR"
            },
            {
                "name": "Boğazkent",
                "countryCode": "TR"
            },
            {
                "name": "Demre",
                "countryCode": "TR"
            },
            {
                "name": "Döşemealtı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Eksere",
                "countryCode": "TR"
            },
            {
                "name": "Elmalı",
                "countryCode": "TR"
            },
            {
                "name": "Evrenseki",
                "countryCode": "TR"
            },
            {
                "name": "Finike",
                "countryCode": "TR"
            },
            {
                "name": "Gazipaşa",
                "countryCode": "TR"
            },
            {
                "name": "Göynük",
                "countryCode": "TR"
            },
            {
                "name": "Gündoğmuş İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kalkan",
                "countryCode": "TR"
            },
            {
                "name": "Kaş",
                "countryCode": "TR"
            },
            {
                "name": "Kemer",
                "countryCode": "TR"
            },
            {
                "name": "Kepez İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Konyaaltı",
                "countryCode": "TR"
            },
            {
                "name": "Korkuteli",
                "countryCode": "TR"
            },
            {
                "name": "Kumköy",
                "countryCode": "TR"
            },
            {
                "name": "Kumluca",
                "countryCode": "TR"
            },
            {
                "name": "Kızılağaç",
                "countryCode": "TR"
            },
            {
                "name": "Mahmutlar",
                "countryCode": "TR"
            },
            {
                "name": "Manavgat",
                "countryCode": "TR"
            },
            {
                "name": "Muratpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Okurcalar",
                "countryCode": "TR"
            },
            {
                "name": "Payallar",
                "countryCode": "TR"
            },
            {
                "name": "Serik",
                "countryCode": "TR"
            },
            {
                "name": "Side",
                "countryCode": "TR"
            },
            {
                "name": "Tekirova",
                "countryCode": "TR"
            },
            {
                "name": "Turunçova",
                "countryCode": "TR"
            },
            {
                "name": "Türkler",
                "countryCode": "TR"
            },
            {
                "name": "İbradı",
                "countryCode": "TR"
            },
            {
                "name": "Ardanuç",
                "countryCode": "TR"
            },
            {
                "name": "Arhavi",
                "countryCode": "TR"
            },
            {
                "name": "Artvin",
                "countryCode": "TR"
            },
            {
                "name": "Borçka",
                "countryCode": "TR"
            },
            {
                "name": "Hopa",
                "countryCode": "TR"
            },
            {
                "name": "Kemalpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Murgul",
                "countryCode": "TR"
            },
            {
                "name": "Seyitler",
                "countryCode": "TR"
            },
            {
                "name": "Yusufeli",
                "countryCode": "TR"
            },
            {
                "name": "Şavşat",
                "countryCode": "TR"
            },
            {
                "name": "Acarlar",
                "countryCode": "TR"
            },
            {
                "name": "Atça",
                "countryCode": "TR"
            },
            {
                "name": "Aydın",
                "countryCode": "TR"
            },
            {
                "name": "Bozdoğan",
                "countryCode": "TR"
            },
            {
                "name": "Buharkent",
                "countryCode": "TR"
            },
            {
                "name": "Dalama",
                "countryCode": "TR"
            },
            {
                "name": "Davutlar",
                "countryCode": "TR"
            },
            {
                "name": "Didim",
                "countryCode": "TR"
            },
            {
                "name": "Efeler",
                "countryCode": "TR"
            },
            {
                "name": "Germencik",
                "countryCode": "TR"
            },
            {
                "name": "Karacasu",
                "countryCode": "TR"
            },
            {
                "name": "Karpuzlu",
                "countryCode": "TR"
            },
            {
                "name": "Koçarlı",
                "countryCode": "TR"
            },
            {
                "name": "Kuyucak",
                "countryCode": "TR"
            },
            {
                "name": "Kuşadası",
                "countryCode": "TR"
            },
            {
                "name": "Köşk",
                "countryCode": "TR"
            },
            {
                "name": "Nazilli",
                "countryCode": "TR"
            },
            {
                "name": "Sarıkemer",
                "countryCode": "TR"
            },
            {
                "name": "Sultanhisar",
                "countryCode": "TR"
            },
            {
                "name": "Söke",
                "countryCode": "TR"
            },
            {
                "name": "Tepecik",
                "countryCode": "TR"
            },
            {
                "name": "Yenipazar",
                "countryCode": "TR"
            },
            {
                "name": "Çine",
                "countryCode": "TR"
            },
            {
                "name": "İncirliova",
                "countryCode": "TR"
            },
            {
                "name": "İsabeyli",
                "countryCode": "TR"
            },
            {
                "name": "Altıeylül İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Altınoluk",
                "countryCode": "TR"
            },
            {
                "name": "Ayvalık",
                "countryCode": "TR"
            },
            {
                "name": "Balya",
                "countryCode": "TR"
            },
            {
                "name": "Balıkesir",
                "countryCode": "TR"
            },
            {
                "name": "Bandırma",
                "countryCode": "TR"
            },
            {
                "name": "Bigadiç",
                "countryCode": "TR"
            },
            {
                "name": "Burhaniye",
                "countryCode": "TR"
            },
            {
                "name": "Dursunbey",
                "countryCode": "TR"
            },
            {
                "name": "Edremit",
                "countryCode": "TR"
            },
            {
                "name": "Erdek",
                "countryCode": "TR"
            },
            {
                "name": "Gömeç",
                "countryCode": "TR"
            },
            {
                "name": "Gönen",
                "countryCode": "TR"
            },
            {
                "name": "Havran",
                "countryCode": "TR"
            },
            {
                "name": "Kepsut",
                "countryCode": "TR"
            },
            {
                "name": "Manyas",
                "countryCode": "TR"
            },
            {
                "name": "Marmara",
                "countryCode": "TR"
            },
            {
                "name": "Savaştepe",
                "countryCode": "TR"
            },
            {
                "name": "Susurluk",
                "countryCode": "TR"
            },
            {
                "name": "Sındırgı",
                "countryCode": "TR"
            },
            {
                "name": "İvrindi",
                "countryCode": "TR"
            },
            {
                "name": "Bilecik",
                "countryCode": "TR"
            },
            {
                "name": "Bozüyük",
                "countryCode": "TR"
            },
            {
                "name": "Dodurga",
                "countryCode": "TR"
            },
            {
                "name": "Gölpazarı",
                "countryCode": "TR"
            },
            {
                "name": "Küplü",
                "countryCode": "TR"
            },
            {
                "name": "Osmaneli",
                "countryCode": "TR"
            },
            {
                "name": "Pazaryeri",
                "countryCode": "TR"
            },
            {
                "name": "Söğüt İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Vezirhan",
                "countryCode": "TR"
            },
            {
                "name": "Yenipazar",
                "countryCode": "TR"
            },
            {
                "name": "İnhisar",
                "countryCode": "TR"
            },
            {
                "name": "Adaklı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Bingöl",
                "countryCode": "TR"
            },
            {
                "name": "Genç",
                "countryCode": "TR"
            },
            {
                "name": "Karlıova",
                "countryCode": "TR"
            },
            {
                "name": "Kiğı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Solhan",
                "countryCode": "TR"
            },
            {
                "name": "Yayladere",
                "countryCode": "TR"
            },
            {
                "name": "Yedisu",
                "countryCode": "TR"
            },
            {
                "name": "Adilcevaz",
                "countryCode": "TR"
            },
            {
                "name": "Ahlat",
                "countryCode": "TR"
            },
            {
                "name": "Beğendik",
                "countryCode": "TR"
            },
            {
                "name": "Bitlis",
                "countryCode": "TR"
            },
            {
                "name": "Güroymak",
                "countryCode": "TR"
            },
            {
                "name": "Hizan",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Mutki",
                "countryCode": "TR"
            },
            {
                "name": "Tatvan",
                "countryCode": "TR"
            },
            {
                "name": "Bolu",
                "countryCode": "TR"
            },
            {
                "name": "Dörtdivan",
                "countryCode": "TR"
            },
            {
                "name": "Gerede",
                "countryCode": "TR"
            },
            {
                "name": "Göynük",
                "countryCode": "TR"
            },
            {
                "name": "Kıbrıscık",
                "countryCode": "TR"
            },
            {
                "name": "Mengen",
                "countryCode": "TR"
            },
            {
                "name": "Mudurnu",
                "countryCode": "TR"
            },
            {
                "name": "Seben",
                "countryCode": "TR"
            },
            {
                "name": "Yeniçağa",
                "countryCode": "TR"
            },
            {
                "name": "Altınyayla",
                "countryCode": "TR"
            },
            {
                "name": "Ağlasun",
                "countryCode": "TR"
            },
            {
                "name": "Bucak",
                "countryCode": "TR"
            },
            {
                "name": "Burdur",
                "countryCode": "TR"
            },
            {
                "name": "Gölhisar",
                "countryCode": "TR"
            },
            {
                "name": "Karamanlı",
                "countryCode": "TR"
            },
            {
                "name": "Kemer",
                "countryCode": "TR"
            },
            {
                "name": "Kocaaliler",
                "countryCode": "TR"
            },
            {
                "name": "Kızılkaya",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Tefenni",
                "countryCode": "TR"
            },
            {
                "name": "Yeşilova",
                "countryCode": "TR"
            },
            {
                "name": "Çavdır İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çeltikçi İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Alanyurt",
                "countryCode": "TR"
            },
            {
                "name": "Balarim",
                "countryCode": "TR"
            },
            {
                "name": "Boyalıca",
                "countryCode": "TR"
            },
            {
                "name": "Bursa",
                "countryCode": "TR"
            },
            {
                "name": "Büyükorhan İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Cerrah",
                "countryCode": "TR"
            },
            {
                "name": "Demirtaş",
                "countryCode": "TR"
            },
            {
                "name": "Elbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Gemlik",
                "countryCode": "TR"
            },
            {
                "name": "Gürsu",
                "countryCode": "TR"
            },
            {
                "name": "Hamzabey",
                "countryCode": "TR"
            },
            {
                "name": "Harmancık İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Karacabey",
                "countryCode": "TR"
            },
            {
                "name": "Karıncalı",
                "countryCode": "TR"
            },
            {
                "name": "Keles",
                "countryCode": "TR"
            },
            {
                "name": "Kestel",
                "countryCode": "TR"
            },
            {
                "name": "Kirazlı",
                "countryCode": "TR"
            },
            {
                "name": "Kurşunlu",
                "countryCode": "TR"
            },
            {
                "name": "Küçükkumla",
                "countryCode": "TR"
            },
            {
                "name": "Mudanya",
                "countryCode": "TR"
            },
            {
                "name": "Mustafakemalpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Narlıca",
                "countryCode": "TR"
            },
            {
                "name": "Nilüfer",
                "countryCode": "TR"
            },
            {
                "name": "Orhaneli",
                "countryCode": "TR"
            },
            {
                "name": "Orhangazi",
                "countryCode": "TR"
            },
            {
                "name": "Osmangazi",
                "countryCode": "TR"
            },
            {
                "name": "Sölöz",
                "countryCode": "TR"
            },
            {
                "name": "Tacir",
                "countryCode": "TR"
            },
            {
                "name": "Tahtaköprü",
                "countryCode": "TR"
            },
            {
                "name": "Tatkavaklı",
                "countryCode": "TR"
            },
            {
                "name": "Umurbey",
                "countryCode": "TR"
            },
            {
                "name": "Yenice",
                "countryCode": "TR"
            },
            {
                "name": "Yeniköy",
                "countryCode": "TR"
            },
            {
                "name": "Yenişehir",
                "countryCode": "TR"
            },
            {
                "name": "Yıldırım İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çakırca",
                "countryCode": "TR"
            },
            {
                "name": "Çakırlı",
                "countryCode": "TR"
            },
            {
                "name": "İnegol",
                "countryCode": "TR"
            },
            {
                "name": "İznik",
                "countryCode": "TR"
            },
            {
                "name": "Ayvacık",
                "countryCode": "TR"
            },
            {
                "name": "Bayramiç İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Behram",
                "countryCode": "TR"
            },
            {
                "name": "Biga",
                "countryCode": "TR"
            },
            {
                "name": "Bozcaada",
                "countryCode": "TR"
            },
            {
                "name": "Eceabat",
                "countryCode": "TR"
            },
            {
                "name": "Ezine",
                "countryCode": "TR"
            },
            {
                "name": "Gelibolu",
                "countryCode": "TR"
            },
            {
                "name": "Geyikli",
                "countryCode": "TR"
            },
            {
                "name": "Gökçeada",
                "countryCode": "TR"
            },
            {
                "name": "Hamdibey",
                "countryCode": "TR"
            },
            {
                "name": "Kalkım",
                "countryCode": "TR"
            },
            {
                "name": "Lapseki",
                "countryCode": "TR"
            },
            {
                "name": "Lâpseki İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Yenice",
                "countryCode": "TR"
            },
            {
                "name": "Çan",
                "countryCode": "TR"
            },
            {
                "name": "Çanakkale",
                "countryCode": "TR"
            },
            {
                "name": "Atkaracalar",
                "countryCode": "TR"
            },
            {
                "name": "Bayramören",
                "countryCode": "TR"
            },
            {
                "name": "Eldivan",
                "countryCode": "TR"
            },
            {
                "name": "Ilgaz",
                "countryCode": "TR"
            },
            {
                "name": "Khanjarah",
                "countryCode": "TR"
            },
            {
                "name": "Korgun",
                "countryCode": "TR"
            },
            {
                "name": "Kurşunlu",
                "countryCode": "TR"
            },
            {
                "name": "Kızılırmak",
                "countryCode": "TR"
            },
            {
                "name": "Orta",
                "countryCode": "TR"
            },
            {
                "name": "Yapraklı",
                "countryCode": "TR"
            },
            {
                "name": "Çerkeş",
                "countryCode": "TR"
            },
            {
                "name": "Şabanözü",
                "countryCode": "TR"
            },
            {
                "name": "Alaca",
                "countryCode": "TR"
            },
            {
                "name": "Bayat",
                "countryCode": "TR"
            },
            {
                "name": "Boğazkale İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Dodurga",
                "countryCode": "TR"
            },
            {
                "name": "Kargı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Laçin",
                "countryCode": "TR"
            },
            {
                "name": "Mecitözü",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Ortaköy",
                "countryCode": "TR"
            },
            {
                "name": "Osmancık",
                "countryCode": "TR"
            },
            {
                "name": "Oğuzlar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Sungurlu",
                "countryCode": "TR"
            },
            {
                "name": "Uğurludağ",
                "countryCode": "TR"
            },
            {
                "name": "Çorum",
                "countryCode": "TR"
            },
            {
                "name": "İskilip",
                "countryCode": "TR"
            },
            {
                "name": "Acıpayam",
                "countryCode": "TR"
            },
            {
                "name": "Akkent",
                "countryCode": "TR"
            },
            {
                "name": "Akköy",
                "countryCode": "TR"
            },
            {
                "name": "Babadağ",
                "countryCode": "TR"
            },
            {
                "name": "Baklan",
                "countryCode": "TR"
            },
            {
                "name": "Bekilli",
                "countryCode": "TR"
            },
            {
                "name": "Beyağaç İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Bozkurt",
                "countryCode": "TR"
            },
            {
                "name": "Buldan",
                "countryCode": "TR"
            },
            {
                "name": "Denizli",
                "countryCode": "TR"
            },
            {
                "name": "Gözler",
                "countryCode": "TR"
            },
            {
                "name": "Güney İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Honaz",
                "countryCode": "TR"
            },
            {
                "name": "Kale",
                "countryCode": "TR"
            },
            {
                "name": "Merkezefendi",
                "countryCode": "TR"
            },
            {
                "name": "Nikfer",
                "countryCode": "TR"
            },
            {
                "name": "Pamukkale",
                "countryCode": "TR"
            },
            {
                "name": "Sarayköy",
                "countryCode": "TR"
            },
            {
                "name": "Serinhisar",
                "countryCode": "TR"
            },
            {
                "name": "Tavas",
                "countryCode": "TR"
            },
            {
                "name": "Çal İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çameli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çardak",
                "countryCode": "TR"
            },
            {
                "name": "Çivril",
                "countryCode": "TR"
            },
            {
                "name": "Alacakaya İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Altınkum",
                "countryCode": "TR"
            },
            {
                "name": "Ambar",
                "countryCode": "TR"
            },
            {
                "name": "Aralık",
                "countryCode": "TR"
            },
            {
                "name": "Bağlar",
                "countryCode": "TR"
            },
            {
                "name": "Bismil",
                "countryCode": "TR"
            },
            {
                "name": "Dicle",
                "countryCode": "TR"
            },
            {
                "name": "Diyarbakır",
                "countryCode": "TR"
            },
            {
                "name": "Ergani",
                "countryCode": "TR"
            },
            {
                "name": "Eğil",
                "countryCode": "TR"
            },
            {
                "name": "Hani",
                "countryCode": "TR"
            },
            {
                "name": "Hazro",
                "countryCode": "TR"
            },
            {
                "name": "Kayapınar",
                "countryCode": "TR"
            },
            {
                "name": "Kazancı",
                "countryCode": "TR"
            },
            {
                "name": "Kerh",
                "countryCode": "TR"
            },
            {
                "name": "Kocaköy",
                "countryCode": "TR"
            },
            {
                "name": "Kulp",
                "countryCode": "TR"
            },
            {
                "name": "Köseli",
                "countryCode": "TR"
            },
            {
                "name": "Lice",
                "countryCode": "TR"
            },
            {
                "name": "Salat",
                "countryCode": "TR"
            },
            {
                "name": "Silvan",
                "countryCode": "TR"
            },
            {
                "name": "Sinanköy",
                "countryCode": "TR"
            },
            {
                "name": "Sur",
                "countryCode": "TR"
            },
            {
                "name": "Yaprakbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Yenişehir",
                "countryCode": "TR"
            },
            {
                "name": "Çermik",
                "countryCode": "TR"
            },
            {
                "name": "Çüngüş",
                "countryCode": "TR"
            },
            {
                "name": "Çınar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Edirne",
                "countryCode": "TR"
            },
            {
                "name": "Enez",
                "countryCode": "TR"
            },
            {
                "name": "Havsa",
                "countryCode": "TR"
            },
            {
                "name": "Keşan",
                "countryCode": "TR"
            },
            {
                "name": "Küplü",
                "countryCode": "TR"
            },
            {
                "name": "Lâlapaşa",
                "countryCode": "TR"
            },
            {
                "name": "Meriç",
                "countryCode": "TR"
            },
            {
                "name": "Süloğlu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Uzunköprü",
                "countryCode": "TR"
            },
            {
                "name": "İpsala",
                "countryCode": "TR"
            },
            {
                "name": "Arıcak",
                "countryCode": "TR"
            },
            {
                "name": "Ağın",
                "countryCode": "TR"
            },
            {
                "name": "Baskil",
                "countryCode": "TR"
            },
            {
                "name": "Elazığ",
                "countryCode": "TR"
            },
            {
                "name": "Karakoçan",
                "countryCode": "TR"
            },
            {
                "name": "Karakoçan / Elazığ",
                "countryCode": "TR"
            },
            {
                "name": "Keban",
                "countryCode": "TR"
            },
            {
                "name": "Kovancılar",
                "countryCode": "TR"
            },
            {
                "name": "Maden İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Palu",
                "countryCode": "TR"
            },
            {
                "name": "Sivrice",
                "countryCode": "TR"
            },
            {
                "name": "Turluk",
                "countryCode": "TR"
            },
            {
                "name": "Cimin",
                "countryCode": "TR"
            },
            {
                "name": "Erzincan",
                "countryCode": "TR"
            },
            {
                "name": "Kemah",
                "countryCode": "TR"
            },
            {
                "name": "Kemaliye",
                "countryCode": "TR"
            },
            {
                "name": "Otlukbeli",
                "countryCode": "TR"
            },
            {
                "name": "Refahiye",
                "countryCode": "TR"
            },
            {
                "name": "Tercan",
                "countryCode": "TR"
            },
            {
                "name": "Çayırlı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Üzümlü İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İliç İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Aziziye",
                "countryCode": "TR"
            },
            {
                "name": "Aşkale",
                "countryCode": "TR"
            },
            {
                "name": "Erzurum",
                "countryCode": "TR"
            },
            {
                "name": "Horasan",
                "countryCode": "TR"
            },
            {
                "name": "Hınıs",
                "countryCode": "TR"
            },
            {
                "name": "Ilıca",
                "countryCode": "TR"
            },
            {
                "name": "Karayazı",
                "countryCode": "TR"
            },
            {
                "name": "Karaçoban",
                "countryCode": "TR"
            },
            {
                "name": "Köprüköy İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Narman",
                "countryCode": "TR"
            },
            {
                "name": "Oltu",
                "countryCode": "TR"
            },
            {
                "name": "Olur",
                "countryCode": "TR"
            },
            {
                "name": "Palandöken",
                "countryCode": "TR"
            },
            {
                "name": "Pasinler",
                "countryCode": "TR"
            },
            {
                "name": "Pazaryolu",
                "countryCode": "TR"
            },
            {
                "name": "Tekman",
                "countryCode": "TR"
            },
            {
                "name": "Tortum",
                "countryCode": "TR"
            },
            {
                "name": "Uzundere",
                "countryCode": "TR"
            },
            {
                "name": "Yakutiye",
                "countryCode": "TR"
            },
            {
                "name": "Çat İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İspir",
                "countryCode": "TR"
            },
            {
                "name": "Şenkaya",
                "countryCode": "TR"
            },
            {
                "name": "Alpu",
                "countryCode": "TR"
            },
            {
                "name": "Beylikova",
                "countryCode": "TR"
            },
            {
                "name": "Bozan",
                "countryCode": "TR"
            },
            {
                "name": "Eskişehir",
                "countryCode": "TR"
            },
            {
                "name": "Günyüzü İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Han",
                "countryCode": "TR"
            },
            {
                "name": "Kırka",
                "countryCode": "TR"
            },
            {
                "name": "Mahmudiye İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Mihalgazi",
                "countryCode": "TR"
            },
            {
                "name": "Mihalıçcık İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Odunpazarı",
                "countryCode": "TR"
            },
            {
                "name": "Sarıcakaya İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Sevinç",
                "countryCode": "TR"
            },
            {
                "name": "Seyitgazi",
                "countryCode": "TR"
            },
            {
                "name": "Sivrihisar",
                "countryCode": "TR"
            },
            {
                "name": "Tepebaşı",
                "countryCode": "TR"
            },
            {
                "name": "Çifteler",
                "countryCode": "TR"
            },
            {
                "name": "İnönü",
                "countryCode": "TR"
            },
            {
                "name": "Araban",
                "countryCode": "TR"
            },
            {
                "name": "Doğanpınar",
                "countryCode": "TR"
            },
            {
                "name": "Gaziantep",
                "countryCode": "TR"
            },
            {
                "name": "Karkamış",
                "countryCode": "TR"
            },
            {
                "name": "Nizip",
                "countryCode": "TR"
            },
            {
                "name": "Nurdağı",
                "countryCode": "TR"
            },
            {
                "name": "Oğuzeli",
                "countryCode": "TR"
            },
            {
                "name": "Sekili",
                "countryCode": "TR"
            },
            {
                "name": "Uluyatır",
                "countryCode": "TR"
            },
            {
                "name": "Yavuzeli",
                "countryCode": "TR"
            },
            {
                "name": "Yeşildere",
                "countryCode": "TR"
            },
            {
                "name": "İkizce",
                "countryCode": "TR"
            },
            {
                "name": "İslahiye İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Şahinbey İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Şehitkamil",
                "countryCode": "TR"
            },
            {
                "name": "Alucra İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Bulancak",
                "countryCode": "TR"
            },
            {
                "name": "Dereli",
                "countryCode": "TR"
            },
            {
                "name": "Doğankent",
                "countryCode": "TR"
            },
            {
                "name": "Espiye",
                "countryCode": "TR"
            },
            {
                "name": "Eynesil",
                "countryCode": "TR"
            },
            {
                "name": "Giresun",
                "countryCode": "TR"
            },
            {
                "name": "Görele",
                "countryCode": "TR"
            },
            {
                "name": "Güce İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Keşap",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Piraziz",
                "countryCode": "TR"
            },
            {
                "name": "Tirebolu",
                "countryCode": "TR"
            },
            {
                "name": "Yağlıdere İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çamoluk İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çanakçı",
                "countryCode": "TR"
            },
            {
                "name": "Şebin Karahisar",
                "countryCode": "TR"
            },
            {
                "name": "Evren",
                "countryCode": "TR"
            },
            {
                "name": "Gumushkhane",
                "countryCode": "TR"
            },
            {
                "name": "Kelkit",
                "countryCode": "TR"
            },
            {
                "name": "Köse",
                "countryCode": "TR"
            },
            {
                "name": "Kürtün",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Torul",
                "countryCode": "TR"
            },
            {
                "name": "Yağlıdere",
                "countryCode": "TR"
            },
            {
                "name": "Şiran",
                "countryCode": "TR"
            },
            {
                "name": "Hakkâri",
                "countryCode": "TR"
            },
            {
                "name": "Karsani",
                "countryCode": "TR"
            },
            {
                "name": "Yüksekova",
                "countryCode": "TR"
            },
            {
                "name": "Çukurca",
                "countryCode": "TR"
            },
            {
                "name": "Şemdinli",
                "countryCode": "TR"
            },
            {
                "name": "Şemdinni İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Altınözü",
                "countryCode": "TR"
            },
            {
                "name": "Anayazı",
                "countryCode": "TR"
            },
            {
                "name": "Antakya",
                "countryCode": "TR"
            },
            {
                "name": "Arsuz",
                "countryCode": "TR"
            },
            {
                "name": "Açıkdere",
                "countryCode": "TR"
            },
            {
                "name": "Aşağı Karafakılı",
                "countryCode": "TR"
            },
            {
                "name": "Aşağıokçular",
                "countryCode": "TR"
            },
            {
                "name": "Balıklıdere",
                "countryCode": "TR"
            },
            {
                "name": "Belen",
                "countryCode": "TR"
            },
            {
                "name": "Boynuyoğun",
                "countryCode": "TR"
            },
            {
                "name": "Büyük Dalyan",
                "countryCode": "TR"
            },
            {
                "name": "Büyükçat",
                "countryCode": "TR"
            },
            {
                "name": "Defne",
                "countryCode": "TR"
            },
            {
                "name": "Denizciler",
                "countryCode": "TR"
            },
            {
                "name": "Dursunlu",
                "countryCode": "TR"
            },
            {
                "name": "Dörtyol",
                "countryCode": "TR"
            },
            {
                "name": "Erzin",
                "countryCode": "TR"
            },
            {
                "name": "Gümüşgöze",
                "countryCode": "TR"
            },
            {
                "name": "Günyazı",
                "countryCode": "TR"
            },
            {
                "name": "Hacıpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Hassa",
                "countryCode": "TR"
            },
            {
                "name": "Karasüleymanlı",
                "countryCode": "TR"
            },
            {
                "name": "Kastal",
                "countryCode": "TR"
            },
            {
                "name": "Kumlu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kuzeytepe",
                "countryCode": "TR"
            },
            {
                "name": "Kırıkhan",
                "countryCode": "TR"
            },
            {
                "name": "Kızkalesi",
                "countryCode": "TR"
            },
            {
                "name": "Kışlak",
                "countryCode": "TR"
            },
            {
                "name": "Mahmutlar",
                "countryCode": "TR"
            },
            {
                "name": "Payas",
                "countryCode": "TR"
            },
            {
                "name": "Reyhanlı",
                "countryCode": "TR"
            },
            {
                "name": "Samankaya",
                "countryCode": "TR"
            },
            {
                "name": "Serinyol",
                "countryCode": "TR"
            },
            {
                "name": "Tepehan",
                "countryCode": "TR"
            },
            {
                "name": "Toygarlı",
                "countryCode": "TR"
            },
            {
                "name": "Uzunbağ",
                "countryCode": "TR"
            },
            {
                "name": "Uzunkavak",
                "countryCode": "TR"
            },
            {
                "name": "Yayladağı",
                "countryCode": "TR"
            },
            {
                "name": "Çırtıman",
                "countryCode": "TR"
            },
            {
                "name": "İskenderun",
                "countryCode": "TR"
            },
            {
                "name": "Aksu",
                "countryCode": "TR"
            },
            {
                "name": "Anamas",
                "countryCode": "TR"
            },
            {
                "name": "Atabey",
                "countryCode": "TR"
            },
            {
                "name": "Eğirdir",
                "countryCode": "TR"
            },
            {
                "name": "Gelendost",
                "countryCode": "TR"
            },
            {
                "name": "Gönen",
                "countryCode": "TR"
            },
            {
                "name": "Isparta",
                "countryCode": "TR"
            },
            {
                "name": "Keçiborlu",
                "countryCode": "TR"
            },
            {
                "name": "Pavlu Cebel",
                "countryCode": "TR"
            },
            {
                "name": "Senirkent",
                "countryCode": "TR"
            },
            {
                "name": "Sütçüler İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Uluborlu",
                "countryCode": "TR"
            },
            {
                "name": "Yalvaç",
                "countryCode": "TR"
            },
            {
                "name": "Yenişarbademli",
                "countryCode": "TR"
            },
            {
                "name": "Şarkîkaraağaç",
                "countryCode": "TR"
            },
            {
                "name": "Akdeniz",
                "countryCode": "TR"
            },
            {
                "name": "Akdere",
                "countryCode": "TR"
            },
            {
                "name": "Anamur",
                "countryCode": "TR"
            },
            {
                "name": "Aydıncık",
                "countryCode": "TR"
            },
            {
                "name": "Bozyazı",
                "countryCode": "TR"
            },
            {
                "name": "Elvanlı",
                "countryCode": "TR"
            },
            {
                "name": "Erdemli",
                "countryCode": "TR"
            },
            {
                "name": "Gülnar",
                "countryCode": "TR"
            },
            {
                "name": "Kocahasanlı",
                "countryCode": "TR"
            },
            {
                "name": "Mersin",
                "countryCode": "TR"
            },
            {
                "name": "Mezitli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Mut",
                "countryCode": "TR"
            },
            {
                "name": "Pınarbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Silifke",
                "countryCode": "TR"
            },
            {
                "name": "Tarsus",
                "countryCode": "TR"
            },
            {
                "name": "Toroslar",
                "countryCode": "TR"
            },
            {
                "name": "Yenişehir",
                "countryCode": "TR"
            },
            {
                "name": "Çamlıyayla İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Adalar",
                "countryCode": "TR"
            },
            {
                "name": "Arnavutköy",
                "countryCode": "TR"
            },
            {
                "name": "Ataşehir",
                "countryCode": "TR"
            },
            {
                "name": "Avcılar",
                "countryCode": "TR"
            },
            {
                "name": "Bahçelievler",
                "countryCode": "TR"
            },
            {
                "name": "Bakırköy",
                "countryCode": "TR"
            },
            {
                "name": "Bayrampaşa",
                "countryCode": "TR"
            },
            {
                "name": "Bağcılar",
                "countryCode": "TR"
            },
            {
                "name": "Başakşehir",
                "countryCode": "TR"
            },
            {
                "name": "Beykoz",
                "countryCode": "TR"
            },
            {
                "name": "Beylikdüzü",
                "countryCode": "TR"
            },
            {
                "name": "Beyoğlu",
                "countryCode": "TR"
            },
            {
                "name": "Beşiktaş",
                "countryCode": "TR"
            },
            {
                "name": "Büyükçekmece",
                "countryCode": "TR"
            },
            {
                "name": "Eminönü",
                "countryCode": "TR"
            },
            {
                "name": "Esenler",
                "countryCode": "TR"
            },
            {
                "name": "Esenyurt",
                "countryCode": "TR"
            },
            {
                "name": "Eyüpsultan",
                "countryCode": "TR"
            },
            {
                "name": "Fatih",
                "countryCode": "TR"
            },
            {
                "name": "Gaziosmanpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Güngören",
                "countryCode": "TR"
            },
            {
                "name": "Kadıköy",
                "countryCode": "TR"
            },
            {
                "name": "Kartal",
                "countryCode": "TR"
            },
            {
                "name": "Kâğıthane",
                "countryCode": "TR"
            },
            {
                "name": "Küçükçekmece",
                "countryCode": "TR"
            },
            {
                "name": "Maltepe",
                "countryCode": "TR"
            },
            {
                "name": "Pendik",
                "countryCode": "TR"
            },
            {
                "name": "Sancaktepe",
                "countryCode": "TR"
            },
            {
                "name": "Sarıyer",
                "countryCode": "TR"
            },
            {
                "name": "Silivri",
                "countryCode": "TR"
            },
            {
                "name": "Sultanbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Sultangazi",
                "countryCode": "TR"
            },
            {
                "name": "Tuzla",
                "countryCode": "TR"
            },
            {
                "name": "Umraniye",
                "countryCode": "TR"
            },
            {
                "name": "Zeytinburnu",
                "countryCode": "TR"
            },
            {
                "name": "Çatalca",
                "countryCode": "TR"
            },
            {
                "name": "Çekmeköy",
                "countryCode": "TR"
            },
            {
                "name": "Üsküdar",
                "countryCode": "TR"
            },
            {
                "name": "Şile",
                "countryCode": "TR"
            },
            {
                "name": "Şişli",
                "countryCode": "TR"
            },
            {
                "name": "Alaçatı",
                "countryCode": "TR"
            },
            {
                "name": "Aliağa",
                "countryCode": "TR"
            },
            {
                "name": "Bademli",
                "countryCode": "TR"
            },
            {
                "name": "Balçova",
                "countryCode": "TR"
            },
            {
                "name": "Bayraklı",
                "countryCode": "TR"
            },
            {
                "name": "Bayındır",
                "countryCode": "TR"
            },
            {
                "name": "Belevi",
                "countryCode": "TR"
            },
            {
                "name": "Bergama",
                "countryCode": "TR"
            },
            {
                "name": "Beydağ",
                "countryCode": "TR"
            },
            {
                "name": "Bornova",
                "countryCode": "TR"
            },
            {
                "name": "Buca",
                "countryCode": "TR"
            },
            {
                "name": "Dikili",
                "countryCode": "TR"
            },
            {
                "name": "Foça",
                "countryCode": "TR"
            },
            {
                "name": "Gaziemir",
                "countryCode": "TR"
            },
            {
                "name": "Gökçen",
                "countryCode": "TR"
            },
            {
                "name": "Güzelbahçe",
                "countryCode": "TR"
            },
            {
                "name": "Karabağlar",
                "countryCode": "TR"
            },
            {
                "name": "Karaburun",
                "countryCode": "TR"
            },
            {
                "name": "Karşıyaka İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kaymakçı",
                "countryCode": "TR"
            },
            {
                "name": "Kemalpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Kiraz",
                "countryCode": "TR"
            },
            {
                "name": "Konak",
                "countryCode": "TR"
            },
            {
                "name": "Konaklı",
                "countryCode": "TR"
            },
            {
                "name": "Kınık",
                "countryCode": "TR"
            },
            {
                "name": "Menderes",
                "countryCode": "TR"
            },
            {
                "name": "Menemen",
                "countryCode": "TR"
            },
            {
                "name": "Narlıdere",
                "countryCode": "TR"
            },
            {
                "name": "Seferihisar",
                "countryCode": "TR"
            },
            {
                "name": "Selçuk",
                "countryCode": "TR"
            },
            {
                "name": "Tire",
                "countryCode": "TR"
            },
            {
                "name": "Torbalı",
                "countryCode": "TR"
            },
            {
                "name": "Urla",
                "countryCode": "TR"
            },
            {
                "name": "Yenifoça",
                "countryCode": "TR"
            },
            {
                "name": "Yenişakran",
                "countryCode": "TR"
            },
            {
                "name": "Zeytindağ",
                "countryCode": "TR"
            },
            {
                "name": "Çandarlı",
                "countryCode": "TR"
            },
            {
                "name": "Çaylı",
                "countryCode": "TR"
            },
            {
                "name": "Çeşme",
                "countryCode": "TR"
            },
            {
                "name": "Çiğli",
                "countryCode": "TR"
            },
            {
                "name": "Ödemiş",
                "countryCode": "TR"
            },
            {
                "name": "Özdere",
                "countryCode": "TR"
            },
            {
                "name": "İzmir",
                "countryCode": "TR"
            },
            {
                "name": "Akyaka",
                "countryCode": "TR"
            },
            {
                "name": "Arpaçay",
                "countryCode": "TR"
            },
            {
                "name": "Digor İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kars",
                "countryCode": "TR"
            },
            {
                "name": "Kağızman",
                "countryCode": "TR"
            },
            {
                "name": "Sarıkamış",
                "countryCode": "TR"
            },
            {
                "name": "Selim",
                "countryCode": "TR"
            },
            {
                "name": "Susuz",
                "countryCode": "TR"
            },
            {
                "name": "Abana",
                "countryCode": "TR"
            },
            {
                "name": "Araç",
                "countryCode": "TR"
            },
            {
                "name": "Azdavay",
                "countryCode": "TR"
            },
            {
                "name": "Ağlı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Bozkurt",
                "countryCode": "TR"
            },
            {
                "name": "Cide",
                "countryCode": "TR"
            },
            {
                "name": "Daday",
                "countryCode": "TR"
            },
            {
                "name": "Devrekani",
                "countryCode": "TR"
            },
            {
                "name": "Doğanyurt",
                "countryCode": "TR"
            },
            {
                "name": "Hanönü",
                "countryCode": "TR"
            },
            {
                "name": "Kastamonu",
                "countryCode": "TR"
            },
            {
                "name": "Küre",
                "countryCode": "TR"
            },
            {
                "name": "Pınarbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Seydiler",
                "countryCode": "TR"
            },
            {
                "name": "Taşköprü",
                "countryCode": "TR"
            },
            {
                "name": "Tosya",
                "countryCode": "TR"
            },
            {
                "name": "Çatalzeytin İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İhsangazi",
                "countryCode": "TR"
            },
            {
                "name": "İnebolu",
                "countryCode": "TR"
            },
            {
                "name": "Şenpazar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Akkışla",
                "countryCode": "TR"
            },
            {
                "name": "Bünyan",
                "countryCode": "TR"
            },
            {
                "name": "Develi",
                "countryCode": "TR"
            },
            {
                "name": "Felahiye",
                "countryCode": "TR"
            },
            {
                "name": "Hacılar",
                "countryCode": "TR"
            },
            {
                "name": "Incesu",
                "countryCode": "TR"
            },
            {
                "name": "Kayseri",
                "countryCode": "TR"
            },
            {
                "name": "Kocasinan",
                "countryCode": "TR"
            },
            {
                "name": "Melikgazi",
                "countryCode": "TR"
            },
            {
                "name": "Pınarbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Sarıoğlan",
                "countryCode": "TR"
            },
            {
                "name": "Sarız",
                "countryCode": "TR"
            },
            {
                "name": "Talas",
                "countryCode": "TR"
            },
            {
                "name": "Tomarza",
                "countryCode": "TR"
            },
            {
                "name": "Yahyali",
                "countryCode": "TR"
            },
            {
                "name": "Yahyalı",
                "countryCode": "TR"
            },
            {
                "name": "Yesilhisar",
                "countryCode": "TR"
            },
            {
                "name": "Özvatan İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Babaeski",
                "countryCode": "TR"
            },
            {
                "name": "Demirköy İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kofçaz",
                "countryCode": "TR"
            },
            {
                "name": "Kırklareli",
                "countryCode": "TR"
            },
            {
                "name": "Lüleburgaz İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Pehlivanköy",
                "countryCode": "TR"
            },
            {
                "name": "Pınarhisar",
                "countryCode": "TR"
            },
            {
                "name": "Vize",
                "countryCode": "TR"
            },
            {
                "name": "Üsküp",
                "countryCode": "TR"
            },
            {
                "name": "Akpınar",
                "countryCode": "TR"
            },
            {
                "name": "Akçakent İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Boztepe İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kaman",
                "countryCode": "TR"
            },
            {
                "name": "Kırşehir",
                "countryCode": "TR"
            },
            {
                "name": "Mucur",
                "countryCode": "TR"
            },
            {
                "name": "Çiçekdağı",
                "countryCode": "TR"
            },
            {
                "name": "Bahçecik",
                "countryCode": "TR"
            },
            {
                "name": "Balçık",
                "countryCode": "TR"
            },
            {
                "name": "Başiskele",
                "countryCode": "TR"
            },
            {
                "name": "Darıca",
                "countryCode": "TR"
            },
            {
                "name": "Derince",
                "countryCode": "TR"
            },
            {
                "name": "Dilovası",
                "countryCode": "TR"
            },
            {
                "name": "Gebze",
                "countryCode": "TR"
            },
            {
                "name": "Gölcük",
                "countryCode": "TR"
            },
            {
                "name": "Halıdere",
                "countryCode": "TR"
            },
            {
                "name": "Kandıra",
                "countryCode": "TR"
            },
            {
                "name": "Karamürsel",
                "countryCode": "TR"
            },
            {
                "name": "Kartepe",
                "countryCode": "TR"
            },
            {
                "name": "Karşıyaka",
                "countryCode": "TR"
            },
            {
                "name": "Kefken",
                "countryCode": "TR"
            },
            {
                "name": "Kullar",
                "countryCode": "TR"
            },
            {
                "name": "Körfez",
                "countryCode": "TR"
            },
            {
                "name": "Köseköy",
                "countryCode": "TR"
            },
            {
                "name": "Tavşancıl",
                "countryCode": "TR"
            },
            {
                "name": "Tavşanlı",
                "countryCode": "TR"
            },
            {
                "name": "Ulaşlı",
                "countryCode": "TR"
            },
            {
                "name": "Yalakdere",
                "countryCode": "TR"
            },
            {
                "name": "Yuvacık",
                "countryCode": "TR"
            },
            {
                "name": "Çayırova",
                "countryCode": "TR"
            },
            {
                "name": "İhsaniye",
                "countryCode": "TR"
            },
            {
                "name": "İzmit",
                "countryCode": "TR"
            },
            {
                "name": "Ahırlı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Akören İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Akşehir",
                "countryCode": "TR"
            },
            {
                "name": "Altınekin",
                "countryCode": "TR"
            },
            {
                "name": "Beyşehir",
                "countryCode": "TR"
            },
            {
                "name": "Bozkır",
                "countryCode": "TR"
            },
            {
                "name": "Cihanbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Cihanbeyli District",
                "countryCode": "TR"
            },
            {
                "name": "Derbent",
                "countryCode": "TR"
            },
            {
                "name": "Derebucak",
                "countryCode": "TR"
            },
            {
                "name": "Doğanhisar",
                "countryCode": "TR"
            },
            {
                "name": "Emirgazi",
                "countryCode": "TR"
            },
            {
                "name": "Ereğli",
                "countryCode": "TR"
            },
            {
                "name": "Güneysınır İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Hadim",
                "countryCode": "TR"
            },
            {
                "name": "Halkapınar",
                "countryCode": "TR"
            },
            {
                "name": "Hüyük",
                "countryCode": "TR"
            },
            {
                "name": "Ilgın",
                "countryCode": "TR"
            },
            {
                "name": "Kadınhanı",
                "countryCode": "TR"
            },
            {
                "name": "Karapınar",
                "countryCode": "TR"
            },
            {
                "name": "Karatay",
                "countryCode": "TR"
            },
            {
                "name": "Konya",
                "countryCode": "TR"
            },
            {
                "name": "Kulu",
                "countryCode": "TR"
            },
            {
                "name": "Kuyulusebil",
                "countryCode": "TR"
            },
            {
                "name": "Meram İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Ovakavağı",
                "countryCode": "TR"
            },
            {
                "name": "Sarayönü",
                "countryCode": "TR"
            },
            {
                "name": "Selcuklu",
                "countryCode": "TR"
            },
            {
                "name": "Seydişehir",
                "countryCode": "TR"
            },
            {
                "name": "Taşkent",
                "countryCode": "TR"
            },
            {
                "name": "Tuzlukçu",
                "countryCode": "TR"
            },
            {
                "name": "Yalıhüyük",
                "countryCode": "TR"
            },
            {
                "name": "Yarma",
                "countryCode": "TR"
            },
            {
                "name": "Yeniceoba",
                "countryCode": "TR"
            },
            {
                "name": "Yunak",
                "countryCode": "TR"
            },
            {
                "name": "Çatalhöyük",
                "countryCode": "TR"
            },
            {
                "name": "Çeltik İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çumra",
                "countryCode": "TR"
            },
            {
                "name": "Altıntaş İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Aslanapa",
                "countryCode": "TR"
            },
            {
                "name": "Domaniç",
                "countryCode": "TR"
            },
            {
                "name": "Dumlupınar",
                "countryCode": "TR"
            },
            {
                "name": "Emet",
                "countryCode": "TR"
            },
            {
                "name": "Gediz",
                "countryCode": "TR"
            },
            {
                "name": "Hisarcık İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kütahya",
                "countryCode": "TR"
            },
            {
                "name": "Pazarlar",
                "countryCode": "TR"
            },
            {
                "name": "Simav",
                "countryCode": "TR"
            },
            {
                "name": "Tavşanlı",
                "countryCode": "TR"
            },
            {
                "name": "Çavdarhisar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Şaphane",
                "countryCode": "TR"
            },
            {
                "name": "Akçadağ",
                "countryCode": "TR"
            },
            {
                "name": "Arapgir",
                "countryCode": "TR"
            },
            {
                "name": "Arguvan",
                "countryCode": "TR"
            },
            {
                "name": "Battalgazi",
                "countryCode": "TR"
            },
            {
                "name": "Darende",
                "countryCode": "TR"
            },
            {
                "name": "Doğanyol",
                "countryCode": "TR"
            },
            {
                "name": "Doğanşehir",
                "countryCode": "TR"
            },
            {
                "name": "Hekimhan",
                "countryCode": "TR"
            },
            {
                "name": "Kale",
                "countryCode": "TR"
            },
            {
                "name": "Kuluncak",
                "countryCode": "TR"
            },
            {
                "name": "Malatya",
                "countryCode": "TR"
            },
            {
                "name": "Pütürge",
                "countryCode": "TR"
            },
            {
                "name": "Yazıhan",
                "countryCode": "TR"
            },
            {
                "name": "Yeşilyurt",
                "countryCode": "TR"
            },
            {
                "name": "Ahmetli",
                "countryCode": "TR"
            },
            {
                "name": "Akhisar",
                "countryCode": "TR"
            },
            {
                "name": "Alaşehir",
                "countryCode": "TR"
            },
            {
                "name": "Demirci",
                "countryCode": "TR"
            },
            {
                "name": "Gölmarmara",
                "countryCode": "TR"
            },
            {
                "name": "Gördes",
                "countryCode": "TR"
            },
            {
                "name": "Kula",
                "countryCode": "TR"
            },
            {
                "name": "Köprübaşı",
                "countryCode": "TR"
            },
            {
                "name": "Kırkağaç",
                "countryCode": "TR"
            },
            {
                "name": "Manisa",
                "countryCode": "TR"
            },
            {
                "name": "Salihli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Saruhanlı",
                "countryCode": "TR"
            },
            {
                "name": "Sarıgöl",
                "countryCode": "TR"
            },
            {
                "name": "Selendi",
                "countryCode": "TR"
            },
            {
                "name": "Soma",
                "countryCode": "TR"
            },
            {
                "name": "Turgutlu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Yunusemre",
                "countryCode": "TR"
            },
            {
                "name": "Zeytinliova",
                "countryCode": "TR"
            },
            {
                "name": "Şehzadeler",
                "countryCode": "TR"
            },
            {
                "name": "Afşin",
                "countryCode": "TR"
            },
            {
                "name": "Andırın",
                "countryCode": "TR"
            },
            {
                "name": "Celeyke",
                "countryCode": "TR"
            },
            {
                "name": "Dulkadiroğlu",
                "countryCode": "TR"
            },
            {
                "name": "Ekinözü İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Elbistan",
                "countryCode": "TR"
            },
            {
                "name": "Göksun",
                "countryCode": "TR"
            },
            {
                "name": "Kahramanmaraş",
                "countryCode": "TR"
            },
            {
                "name": "Nurhak",
                "countryCode": "TR"
            },
            {
                "name": "Onikişubat",
                "countryCode": "TR"
            },
            {
                "name": "Pazarcık",
                "countryCode": "TR"
            },
            {
                "name": "Süleymanlı",
                "countryCode": "TR"
            },
            {
                "name": "Türkoğlu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çağlayancerit",
                "countryCode": "TR"
            },
            {
                "name": "Acırlı",
                "countryCode": "TR"
            },
            {
                "name": "Akarsu",
                "countryCode": "TR"
            },
            {
                "name": "Alakamış",
                "countryCode": "TR"
            },
            {
                "name": "Aran",
                "countryCode": "TR"
            },
            {
                "name": "Artuklu",
                "countryCode": "TR"
            },
            {
                "name": "Avine",
                "countryCode": "TR"
            },
            {
                "name": "Barıştepe",
                "countryCode": "TR"
            },
            {
                "name": "Bağlıca",
                "countryCode": "TR"
            },
            {
                "name": "Başkavak",
                "countryCode": "TR"
            },
            {
                "name": "Cinatamiho",
                "countryCode": "TR"
            },
            {
                "name": "Dara",
                "countryCode": "TR"
            },
            {
                "name": "Dargeçit",
                "countryCode": "TR"
            },
            {
                "name": "Dereyanı",
                "countryCode": "TR"
            },
            {
                "name": "Derik İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Duruca",
                "countryCode": "TR"
            },
            {
                "name": "Ebish",
                "countryCode": "TR"
            },
            {
                "name": "Eymirli",
                "countryCode": "TR"
            },
            {
                "name": "Eşme",
                "countryCode": "TR"
            },
            {
                "name": "Gelinkaya",
                "countryCode": "TR"
            },
            {
                "name": "Gyundyukoru",
                "countryCode": "TR"
            },
            {
                "name": "Gülveren",
                "countryCode": "TR"
            },
            {
                "name": "Haberli",
                "countryCode": "TR"
            },
            {
                "name": "Kabala",
                "countryCode": "TR"
            },
            {
                "name": "Karalar",
                "countryCode": "TR"
            },
            {
                "name": "Kavsan",
                "countryCode": "TR"
            },
            {
                "name": "Kayalıpınar",
                "countryCode": "TR"
            },
            {
                "name": "Kaynakkaya",
                "countryCode": "TR"
            },
            {
                "name": "Kindirip",
                "countryCode": "TR"
            },
            {
                "name": "Kumlu",
                "countryCode": "TR"
            },
            {
                "name": "Kutlubey",
                "countryCode": "TR"
            },
            {
                "name": "Kılavuz",
                "countryCode": "TR"
            },
            {
                "name": "Kızıltepe",
                "countryCode": "TR"
            },
            {
                "name": "Mardin",
                "countryCode": "TR"
            },
            {
                "name": "Mardin Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Mazıdağı",
                "countryCode": "TR"
            },
            {
                "name": "Midyat",
                "countryCode": "TR"
            },
            {
                "name": "Nusaybin",
                "countryCode": "TR"
            },
            {
                "name": "Ortaca",
                "countryCode": "TR"
            },
            {
                "name": "Ortaköy",
                "countryCode": "TR"
            },
            {
                "name": "Oyalı",
                "countryCode": "TR"
            },
            {
                "name": "Pınardere",
                "countryCode": "TR"
            },
            {
                "name": "Reshidi",
                "countryCode": "TR"
            },
            {
                "name": "Savur",
                "countryCode": "TR"
            },
            {
                "name": "Selah",
                "countryCode": "TR"
            },
            {
                "name": "Seri",
                "countryCode": "TR"
            },
            {
                "name": "Serkan",
                "countryCode": "TR"
            },
            {
                "name": "Sivrice",
                "countryCode": "TR"
            },
            {
                "name": "Teffi",
                "countryCode": "TR"
            },
            {
                "name": "Telminar",
                "countryCode": "TR"
            },
            {
                "name": "Tepealtı",
                "countryCode": "TR"
            },
            {
                "name": "Toptepe",
                "countryCode": "TR"
            },
            {
                "name": "Yaylı",
                "countryCode": "TR"
            },
            {
                "name": "Yayvantepe",
                "countryCode": "TR"
            },
            {
                "name": "Yeşilalan",
                "countryCode": "TR"
            },
            {
                "name": "Yeşilli",
                "countryCode": "TR"
            },
            {
                "name": "Yolbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Çalpınar",
                "countryCode": "TR"
            },
            {
                "name": "Çavuşlu",
                "countryCode": "TR"
            },
            {
                "name": "Çınaraltı",
                "countryCode": "TR"
            },
            {
                "name": "Çıplak",
                "countryCode": "TR"
            },
            {
                "name": "Ömerli",
                "countryCode": "TR"
            },
            {
                "name": "Özbek",
                "countryCode": "TR"
            },
            {
                "name": "Şenocak",
                "countryCode": "TR"
            },
            {
                "name": "Şenyurt",
                "countryCode": "TR"
            },
            {
                "name": "Bayır",
                "countryCode": "TR"
            },
            {
                "name": "Bodrum",
                "countryCode": "TR"
            },
            {
                "name": "Bozarmut",
                "countryCode": "TR"
            },
            {
                "name": "Dalaman",
                "countryCode": "TR"
            },
            {
                "name": "Dalyan",
                "countryCode": "TR"
            },
            {
                "name": "Datça",
                "countryCode": "TR"
            },
            {
                "name": "Fethiye",
                "countryCode": "TR"
            },
            {
                "name": "Göcek",
                "countryCode": "TR"
            },
            {
                "name": "Güvercinlik",
                "countryCode": "TR"
            },
            {
                "name": "Kargı",
                "countryCode": "TR"
            },
            {
                "name": "Kavaklıdere",
                "countryCode": "TR"
            },
            {
                "name": "Köyceğiz İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Marmaris",
                "countryCode": "TR"
            },
            {
                "name": "Menteşe",
                "countryCode": "TR"
            },
            {
                "name": "Milas",
                "countryCode": "TR"
            },
            {
                "name": "Muğla",
                "countryCode": "TR"
            },
            {
                "name": "Ortaca",
                "countryCode": "TR"
            },
            {
                "name": "Sarigerme",
                "countryCode": "TR"
            },
            {
                "name": "Seydikemer",
                "countryCode": "TR"
            },
            {
                "name": "Turgut",
                "countryCode": "TR"
            },
            {
                "name": "Turgutreis",
                "countryCode": "TR"
            },
            {
                "name": "Ula",
                "countryCode": "TR"
            },
            {
                "name": "Yalıkavak",
                "countryCode": "TR"
            },
            {
                "name": "Yaniklar",
                "countryCode": "TR"
            },
            {
                "name": "Yatağan",
                "countryCode": "TR"
            },
            {
                "name": "Ölüdeniz",
                "countryCode": "TR"
            },
            {
                "name": "Bulanık",
                "countryCode": "TR"
            },
            {
                "name": "Hasköy",
                "countryCode": "TR"
            },
            {
                "name": "Korkut",
                "countryCode": "TR"
            },
            {
                "name": "Malazgirt",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Muş",
                "countryCode": "TR"
            },
            {
                "name": "Varto",
                "countryCode": "TR"
            },
            {
                "name": "Acıgöl",
                "countryCode": "TR"
            },
            {
                "name": "Avanos",
                "countryCode": "TR"
            },
            {
                "name": "Derinkuyu",
                "countryCode": "TR"
            },
            {
                "name": "Göreme",
                "countryCode": "TR"
            },
            {
                "name": "Gülşehir",
                "countryCode": "TR"
            },
            {
                "name": "Hacıbektaş",
                "countryCode": "TR"
            },
            {
                "name": "Kozaklı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Nevşehir",
                "countryCode": "TR"
            },
            {
                "name": "Ürgüp",
                "countryCode": "TR"
            },
            {
                "name": "Altunhisar",
                "countryCode": "TR"
            },
            {
                "name": "Bor",
                "countryCode": "TR"
            },
            {
                "name": "Niğde",
                "countryCode": "TR"
            },
            {
                "name": "Ulukisla",
                "countryCode": "TR"
            },
            {
                "name": "Çamardı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çiftlik",
                "countryCode": "TR"
            },
            {
                "name": "Akkuş",
                "countryCode": "TR"
            },
            {
                "name": "Altınordu",
                "countryCode": "TR"
            },
            {
                "name": "Aybastı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Fatsa",
                "countryCode": "TR"
            },
            {
                "name": "Gölköy İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gülyalı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gürgentepe",
                "countryCode": "TR"
            },
            {
                "name": "Kabadüz",
                "countryCode": "TR"
            },
            {
                "name": "Kabataş",
                "countryCode": "TR"
            },
            {
                "name": "Korgan",
                "countryCode": "TR"
            },
            {
                "name": "Kumru",
                "countryCode": "TR"
            },
            {
                "name": "Mesudiye",
                "countryCode": "TR"
            },
            {
                "name": "Ordu",
                "countryCode": "TR"
            },
            {
                "name": "Perşembe",
                "countryCode": "TR"
            },
            {
                "name": "Piraziz İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Ulubey",
                "countryCode": "TR"
            },
            {
                "name": "Çamaş",
                "countryCode": "TR"
            },
            {
                "name": "Çatalpınar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çaybaşı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Ünye İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İkizce",
                "countryCode": "TR"
            },
            {
                "name": "Ardeşen",
                "countryCode": "TR"
            },
            {
                "name": "Fındıklı",
                "countryCode": "TR"
            },
            {
                "name": "Güneysu",
                "countryCode": "TR"
            },
            {
                "name": "Hemşin İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kalkandere",
                "countryCode": "TR"
            },
            {
                "name": "Nurluca",
                "countryCode": "TR"
            },
            {
                "name": "Pazar",
                "countryCode": "TR"
            },
            {
                "name": "Rize",
                "countryCode": "TR"
            },
            {
                "name": "Çayeli",
                "countryCode": "TR"
            },
            {
                "name": "İkizdere İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "İyidere",
                "countryCode": "TR"
            },
            {
                "name": "Adapazarı",
                "countryCode": "TR"
            },
            {
                "name": "Akyazı",
                "countryCode": "TR"
            },
            {
                "name": "Arifiye",
                "countryCode": "TR"
            },
            {
                "name": "Erenler",
                "countryCode": "TR"
            },
            {
                "name": "Ferizli",
                "countryCode": "TR"
            },
            {
                "name": "Geyve",
                "countryCode": "TR"
            },
            {
                "name": "Hendek",
                "countryCode": "TR"
            },
            {
                "name": "Karapürçek",
                "countryCode": "TR"
            },
            {
                "name": "Karasu Mahallesi",
                "countryCode": "TR"
            },
            {
                "name": "Karasu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kaynarca",
                "countryCode": "TR"
            },
            {
                "name": "Kocaali",
                "countryCode": "TR"
            },
            {
                "name": "Pamukova",
                "countryCode": "TR"
            },
            {
                "name": "Sapanca",
                "countryCode": "TR"
            },
            {
                "name": "Serdivan",
                "countryCode": "TR"
            },
            {
                "name": "Söğütlü",
                "countryCode": "TR"
            },
            {
                "name": "Taraklı",
                "countryCode": "TR"
            },
            {
                "name": "Alaçam İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Asarcık",
                "countryCode": "TR"
            },
            {
                "name": "Atakum",
                "countryCode": "TR"
            },
            {
                "name": "Ayvacık",
                "countryCode": "TR"
            },
            {
                "name": "Bafra",
                "countryCode": "TR"
            },
            {
                "name": "Canik",
                "countryCode": "TR"
            },
            {
                "name": "Havza",
                "countryCode": "TR"
            },
            {
                "name": "Kavak",
                "countryCode": "TR"
            },
            {
                "name": "Ladik",
                "countryCode": "TR"
            },
            {
                "name": "Ondokuzmayıs İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Salıpazarı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Samsun",
                "countryCode": "TR"
            },
            {
                "name": "Tekkeköy",
                "countryCode": "TR"
            },
            {
                "name": "Terme",
                "countryCode": "TR"
            },
            {
                "name": "Vezirköprü",
                "countryCode": "TR"
            },
            {
                "name": "Yakakent",
                "countryCode": "TR"
            },
            {
                "name": "Çarşamba",
                "countryCode": "TR"
            },
            {
                "name": "İlkadım",
                "countryCode": "TR"
            },
            {
                "name": "Baykan",
                "countryCode": "TR"
            },
            {
                "name": "Civankan",
                "countryCode": "TR"
            },
            {
                "name": "Doğanca",
                "countryCode": "TR"
            },
            {
                "name": "Doğanköy",
                "countryCode": "TR"
            },
            {
                "name": "Eruh",
                "countryCode": "TR"
            },
            {
                "name": "Gökbudak",
                "countryCode": "TR"
            },
            {
                "name": "Gökçekoru",
                "countryCode": "TR"
            },
            {
                "name": "Gölgelikonak",
                "countryCode": "TR"
            },
            {
                "name": "Gözpınar",
                "countryCode": "TR"
            },
            {
                "name": "Güleçler",
                "countryCode": "TR"
            },
            {
                "name": "Kayabağlar",
                "countryCode": "TR"
            },
            {
                "name": "Kurtalan",
                "countryCode": "TR"
            },
            {
                "name": "Lodi",
                "countryCode": "TR"
            },
            {
                "name": "Okçular",
                "countryCode": "TR"
            },
            {
                "name": "Palamutlu",
                "countryCode": "TR"
            },
            {
                "name": "Pervari",
                "countryCode": "TR"
            },
            {
                "name": "Serhatta",
                "countryCode": "TR"
            },
            {
                "name": "Siirt",
                "countryCode": "TR"
            },
            {
                "name": "Taliban",
                "countryCode": "TR"
            },
            {
                "name": "Taşlı",
                "countryCode": "TR"
            },
            {
                "name": "Tillo",
                "countryCode": "TR"
            },
            {
                "name": "Şirvan",
                "countryCode": "TR"
            },
            {
                "name": "Boyabat",
                "countryCode": "TR"
            },
            {
                "name": "Dikmen",
                "countryCode": "TR"
            },
            {
                "name": "Durağan",
                "countryCode": "TR"
            },
            {
                "name": "Erfelek",
                "countryCode": "TR"
            },
            {
                "name": "Gerze",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Saraydüzü",
                "countryCode": "TR"
            },
            {
                "name": "Sinop",
                "countryCode": "TR"
            },
            {
                "name": "Türkeli",
                "countryCode": "TR"
            },
            {
                "name": "Aksu",
                "countryCode": "TR"
            },
            {
                "name": "Akıncılar",
                "countryCode": "TR"
            },
            {
                "name": "Altınyayla",
                "countryCode": "TR"
            },
            {
                "name": "Divriği",
                "countryCode": "TR"
            },
            {
                "name": "Doğanşar",
                "countryCode": "TR"
            },
            {
                "name": "Gemerek",
                "countryCode": "TR"
            },
            {
                "name": "Gölova",
                "countryCode": "TR"
            },
            {
                "name": "Gürün",
                "countryCode": "TR"
            },
            {
                "name": "Hafik",
                "countryCode": "TR"
            },
            {
                "name": "Kangal",
                "countryCode": "TR"
            },
            {
                "name": "Koyulhisar",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Sivas",
                "countryCode": "TR"
            },
            {
                "name": "Suşehri",
                "countryCode": "TR"
            },
            {
                "name": "Ulaş",
                "countryCode": "TR"
            },
            {
                "name": "Yıldızeli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Zara",
                "countryCode": "TR"
            },
            {
                "name": "İmranlı",
                "countryCode": "TR"
            },
            {
                "name": "Şarkışla",
                "countryCode": "TR"
            },
            {
                "name": "Ergene",
                "countryCode": "TR"
            },
            {
                "name": "Hayrabolu",
                "countryCode": "TR"
            },
            {
                "name": "Kapaklı",
                "countryCode": "TR"
            },
            {
                "name": "Kumbağ",
                "countryCode": "TR"
            },
            {
                "name": "Malkara",
                "countryCode": "TR"
            },
            {
                "name": "Marmara Ereğlisi",
                "countryCode": "TR"
            },
            {
                "name": "Marmaracık",
                "countryCode": "TR"
            },
            {
                "name": "Muratlı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Saray",
                "countryCode": "TR"
            },
            {
                "name": "Sultanköy",
                "countryCode": "TR"
            },
            {
                "name": "Süleymanpaşa",
                "countryCode": "TR"
            },
            {
                "name": "Tekirdağ",
                "countryCode": "TR"
            },
            {
                "name": "Velimeşe",
                "countryCode": "TR"
            },
            {
                "name": "Çerkezköy",
                "countryCode": "TR"
            },
            {
                "name": "Çorlu",
                "countryCode": "TR"
            },
            {
                "name": "Şarköy İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Almus",
                "countryCode": "TR"
            },
            {
                "name": "Artova",
                "countryCode": "TR"
            },
            {
                "name": "Başçiftlik",
                "countryCode": "TR"
            },
            {
                "name": "Erbaa",
                "countryCode": "TR"
            },
            {
                "name": "Niksar",
                "countryCode": "TR"
            },
            {
                "name": "Pazar",
                "countryCode": "TR"
            },
            {
                "name": "Reşadiye",
                "countryCode": "TR"
            },
            {
                "name": "Sulusaray",
                "countryCode": "TR"
            },
            {
                "name": "Tokat",
                "countryCode": "TR"
            },
            {
                "name": "Turhal",
                "countryCode": "TR"
            },
            {
                "name": "Yeşilyurt İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Zile",
                "countryCode": "TR"
            },
            {
                "name": "Akçaabat",
                "countryCode": "TR"
            },
            {
                "name": "Araklı",
                "countryCode": "TR"
            },
            {
                "name": "Arsin",
                "countryCode": "TR"
            },
            {
                "name": "Beşikdüzü",
                "countryCode": "TR"
            },
            {
                "name": "Dernekpazarı",
                "countryCode": "TR"
            },
            {
                "name": "Düzköy",
                "countryCode": "TR"
            },
            {
                "name": "Hayrat",
                "countryCode": "TR"
            },
            {
                "name": "Köprübaşı",
                "countryCode": "TR"
            },
            {
                "name": "Maçka",
                "countryCode": "TR"
            },
            {
                "name": "Of",
                "countryCode": "TR"
            },
            {
                "name": "Ortahisar",
                "countryCode": "TR"
            },
            {
                "name": "Sürmene",
                "countryCode": "TR"
            },
            {
                "name": "Tonya",
                "countryCode": "TR"
            },
            {
                "name": "Trabzon",
                "countryCode": "TR"
            },
            {
                "name": "Vakfıkebir",
                "countryCode": "TR"
            },
            {
                "name": "Yomra",
                "countryCode": "TR"
            },
            {
                "name": "Çarşıbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Çaykara",
                "countryCode": "TR"
            },
            {
                "name": "Şalpazarı",
                "countryCode": "TR"
            },
            {
                "name": "Hozat",
                "countryCode": "TR"
            },
            {
                "name": "Mazgirt",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Nazimiye",
                "countryCode": "TR"
            },
            {
                "name": "Nazımiye İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Ovacık",
                "countryCode": "TR"
            },
            {
                "name": "Pertek",
                "countryCode": "TR"
            },
            {
                "name": "Pulumer",
                "countryCode": "TR"
            },
            {
                "name": "Pülümür İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Tunceli",
                "countryCode": "TR"
            },
            {
                "name": "Çemişgezek İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Akdiken",
                "countryCode": "TR"
            },
            {
                "name": "Akçakale",
                "countryCode": "TR"
            },
            {
                "name": "Aşağı Beğdeş",
                "countryCode": "TR"
            },
            {
                "name": "Birecik",
                "countryCode": "TR"
            },
            {
                "name": "Bozova",
                "countryCode": "TR"
            },
            {
                "name": "Bulutlu",
                "countryCode": "TR"
            },
            {
                "name": "Ceylanpınar",
                "countryCode": "TR"
            },
            {
                "name": "Dorumali",
                "countryCode": "TR"
            },
            {
                "name": "Eyyübiye",
                "countryCode": "TR"
            },
            {
                "name": "Güneren",
                "countryCode": "TR"
            },
            {
                "name": "Halfeti",
                "countryCode": "TR"
            },
            {
                "name": "Haliliye",
                "countryCode": "TR"
            },
            {
                "name": "Hanköy",
                "countryCode": "TR"
            },
            {
                "name": "Harran",
                "countryCode": "TR"
            },
            {
                "name": "Hilvan",
                "countryCode": "TR"
            },
            {
                "name": "Işıklar",
                "countryCode": "TR"
            },
            {
                "name": "Karaköprü",
                "countryCode": "TR"
            },
            {
                "name": "Koyunluca",
                "countryCode": "TR"
            },
            {
                "name": "Küçükkendirci",
                "countryCode": "TR"
            },
            {
                "name": "Mağaralı",
                "countryCode": "TR"
            },
            {
                "name": "Meydankapı",
                "countryCode": "TR"
            },
            {
                "name": "Minare",
                "countryCode": "TR"
            },
            {
                "name": "Muratlı",
                "countryCode": "TR"
            },
            {
                "name": "Pekmezli",
                "countryCode": "TR"
            },
            {
                "name": "Seksenören",
                "countryCode": "TR"
            },
            {
                "name": "Siverek",
                "countryCode": "TR"
            },
            {
                "name": "Suruç",
                "countryCode": "TR"
            },
            {
                "name": "Viranşehir",
                "countryCode": "TR"
            },
            {
                "name": "Yukarı Taşyalak",
                "countryCode": "TR"
            },
            {
                "name": "Öncül",
                "countryCode": "TR"
            },
            {
                "name": "Şanlıurfa",
                "countryCode": "TR"
            },
            {
                "name": "Banaz",
                "countryCode": "TR"
            },
            {
                "name": "Bölme",
                "countryCode": "TR"
            },
            {
                "name": "Eşme",
                "countryCode": "TR"
            },
            {
                "name": "Güllü",
                "countryCode": "TR"
            },
            {
                "name": "Karahallı",
                "countryCode": "TR"
            },
            {
                "name": "Kızılcasöğüt",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Selçikler",
                "countryCode": "TR"
            },
            {
                "name": "Sivaslı",
                "countryCode": "TR"
            },
            {
                "name": "Ulubey",
                "countryCode": "TR"
            },
            {
                "name": "Uşak",
                "countryCode": "TR"
            },
            {
                "name": "İlyaslı",
                "countryCode": "TR"
            },
            {
                "name": "Bahçesaray",
                "countryCode": "TR"
            },
            {
                "name": "Başkale",
                "countryCode": "TR"
            },
            {
                "name": "Edremit",
                "countryCode": "TR"
            },
            {
                "name": "Erciş",
                "countryCode": "TR"
            },
            {
                "name": "Gevaş",
                "countryCode": "TR"
            },
            {
                "name": "Gürpınar",
                "countryCode": "TR"
            },
            {
                "name": "Konalga",
                "countryCode": "TR"
            },
            {
                "name": "Muradiye",
                "countryCode": "TR"
            },
            {
                "name": "Saray",
                "countryCode": "TR"
            },
            {
                "name": "Tuşpa",
                "countryCode": "TR"
            },
            {
                "name": "Van",
                "countryCode": "TR"
            },
            {
                "name": "Çaldıran",
                "countryCode": "TR"
            },
            {
                "name": "Çatak İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Özalp",
                "countryCode": "TR"
            },
            {
                "name": "İpekyolu",
                "countryCode": "TR"
            },
            {
                "name": "Akdağmadeni",
                "countryCode": "TR"
            },
            {
                "name": "Aydıncık",
                "countryCode": "TR"
            },
            {
                "name": "Boğazlıyan İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kadışehri",
                "countryCode": "TR"
            },
            {
                "name": "Saraykent",
                "countryCode": "TR"
            },
            {
                "name": "Sarıkaya",
                "countryCode": "TR"
            },
            {
                "name": "Sorgun",
                "countryCode": "TR"
            },
            {
                "name": "Yenifakılı",
                "countryCode": "TR"
            },
            {
                "name": "Yerköy",
                "countryCode": "TR"
            },
            {
                "name": "Yozgat",
                "countryCode": "TR"
            },
            {
                "name": "Çandır İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çayıralan",
                "countryCode": "TR"
            },
            {
                "name": "Çekerek",
                "countryCode": "TR"
            },
            {
                "name": "Şefaatli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Şefaatlı",
                "countryCode": "TR"
            },
            {
                "name": "Alaplı",
                "countryCode": "TR"
            },
            {
                "name": "Devrek",
                "countryCode": "TR"
            },
            {
                "name": "Ereğli",
                "countryCode": "TR"
            },
            {
                "name": "Gökçebey İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kilimli",
                "countryCode": "TR"
            },
            {
                "name": "Kozlu",
                "countryCode": "TR"
            },
            {
                "name": "Zonguldak",
                "countryCode": "TR"
            },
            {
                "name": "Çaycuma",
                "countryCode": "TR"
            },
            {
                "name": "Aksaray",
                "countryCode": "TR"
            },
            {
                "name": "Ağaçören",
                "countryCode": "TR"
            },
            {
                "name": "Eskil İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gülağaç İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Güzelyurt",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Ortaköy",
                "countryCode": "TR"
            },
            {
                "name": "Sarıyahşi",
                "countryCode": "TR"
            },
            {
                "name": "Sultanhanı",
                "countryCode": "TR"
            },
            {
                "name": "Aydıntepe",
                "countryCode": "TR"
            },
            {
                "name": "Bayburt",
                "countryCode": "TR"
            },
            {
                "name": "Demirözü İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Pulur",
                "countryCode": "TR"
            },
            {
                "name": "Ayrancı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Başyayla",
                "countryCode": "TR"
            },
            {
                "name": "Ermenek",
                "countryCode": "TR"
            },
            {
                "name": "Karaman",
                "countryCode": "TR"
            },
            {
                "name": "Kazımkarabekir",
                "countryCode": "TR"
            },
            {
                "name": "Sarıveliler",
                "countryCode": "TR"
            },
            {
                "name": "İnönü",
                "countryCode": "TR"
            },
            {
                "name": "Bahşılı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Balışeyh",
                "countryCode": "TR"
            },
            {
                "name": "Delice",
                "countryCode": "TR"
            },
            {
                "name": "Karakeçili",
                "countryCode": "TR"
            },
            {
                "name": "Keskin",
                "countryCode": "TR"
            },
            {
                "name": "Kırıkkale",
                "countryCode": "TR"
            },
            {
                "name": "Sulakyurt",
                "countryCode": "TR"
            },
            {
                "name": "Yahşihan İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çelebi İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Aviski",
                "countryCode": "TR"
            },
            {
                "name": "Aydınkonak",
                "countryCode": "TR"
            },
            {
                "name": "Balpınar",
                "countryCode": "TR"
            },
            {
                "name": "Batman",
                "countryCode": "TR"
            },
            {
                "name": "Beşiri",
                "countryCode": "TR"
            },
            {
                "name": "Beşpınar",
                "countryCode": "TR"
            },
            {
                "name": "Binatlı",
                "countryCode": "TR"
            },
            {
                "name": "Demiryol",
                "countryCode": "TR"
            },
            {
                "name": "Doğankavak",
                "countryCode": "TR"
            },
            {
                "name": "Erköklü",
                "countryCode": "TR"
            },
            {
                "name": "Gercüş İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gerdzhyush",
                "countryCode": "TR"
            },
            {
                "name": "Hasankeyf",
                "countryCode": "TR"
            },
            {
                "name": "Hisar",
                "countryCode": "TR"
            },
            {
                "name": "Kayapınar",
                "countryCode": "TR"
            },
            {
                "name": "Kozluk İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Merkez",
                "countryCode": "TR"
            },
            {
                "name": "Oymataş",
                "countryCode": "TR"
            },
            {
                "name": "Sason",
                "countryCode": "TR"
            },
            {
                "name": "Yeniköy",
                "countryCode": "TR"
            },
            {
                "name": "Yenipınar",
                "countryCode": "TR"
            },
            {
                "name": "Yolağzı",
                "countryCode": "TR"
            },
            {
                "name": "Çevrimova",
                "countryCode": "TR"
            },
            {
                "name": "Andaç",
                "countryCode": "TR"
            },
            {
                "name": "Ballı",
                "countryCode": "TR"
            },
            {
                "name": "Balveren",
                "countryCode": "TR"
            },
            {
                "name": "Baraniferho",
                "countryCode": "TR"
            },
            {
                "name": "Bağlıca",
                "countryCode": "TR"
            },
            {
                "name": "Başaran",
                "countryCode": "TR"
            },
            {
                "name": "Becuh",
                "countryCode": "TR"
            },
            {
                "name": "Beytüşşebap",
                "countryCode": "TR"
            },
            {
                "name": "Bisbin",
                "countryCode": "TR"
            },
            {
                "name": "Bostancı",
                "countryCode": "TR"
            },
            {
                "name": "Bozalan",
                "countryCode": "TR"
            },
            {
                "name": "Boğazören",
                "countryCode": "TR"
            },
            {
                "name": "Cizre",
                "countryCode": "TR"
            },
            {
                "name": "Dicle",
                "countryCode": "TR"
            },
            {
                "name": "Doruklu",
                "countryCode": "TR"
            },
            {
                "name": "Düzova",
                "countryCode": "TR"
            },
            {
                "name": "Girikbedro",
                "countryCode": "TR"
            },
            {
                "name": "Güçlükonak İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Hilal",
                "countryCode": "TR"
            },
            {
                "name": "Kumçatı",
                "countryCode": "TR"
            },
            {
                "name": "Kızılsu",
                "countryCode": "TR"
            },
            {
                "name": "Mezraa",
                "countryCode": "TR"
            },
            {
                "name": "Ortabağ",
                "countryCode": "TR"
            },
            {
                "name": "Ortaköy",
                "countryCode": "TR"
            },
            {
                "name": "Pınarbaşı",
                "countryCode": "TR"
            },
            {
                "name": "Razvaliny Ayinvan",
                "countryCode": "TR"
            },
            {
                "name": "Silopi",
                "countryCode": "TR"
            },
            {
                "name": "Sulak",
                "countryCode": "TR"
            },
            {
                "name": "Tililan",
                "countryCode": "TR"
            },
            {
                "name": "Uludere",
                "countryCode": "TR"
            },
            {
                "name": "Uzungeçit",
                "countryCode": "TR"
            },
            {
                "name": "Yemişli",
                "countryCode": "TR"
            },
            {
                "name": "Yeniköy",
                "countryCode": "TR"
            },
            {
                "name": "Çalışkan",
                "countryCode": "TR"
            },
            {
                "name": "Çardaklı",
                "countryCode": "TR"
            },
            {
                "name": "İdil",
                "countryCode": "TR"
            },
            {
                "name": "Şenoba",
                "countryCode": "TR"
            },
            {
                "name": "Şırnak",
                "countryCode": "TR"
            },
            {
                "name": "Amasra",
                "countryCode": "TR"
            },
            {
                "name": "Bartın",
                "countryCode": "TR"
            },
            {
                "name": "Kurucaşile",
                "countryCode": "TR"
            },
            {
                "name": "Ulus İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Ardahan",
                "countryCode": "TR"
            },
            {
                "name": "Damal",
                "countryCode": "TR"
            },
            {
                "name": "Göle",
                "countryCode": "TR"
            },
            {
                "name": "Hanak",
                "countryCode": "TR"
            },
            {
                "name": "Posof",
                "countryCode": "TR"
            },
            {
                "name": "Çıldır İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Iğdır",
                "countryCode": "TR"
            },
            {
                "name": "Karakoyunlu",
                "countryCode": "TR"
            },
            {
                "name": "Tuzluca İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Altınova",
                "countryCode": "TR"
            },
            {
                "name": "Armutlu İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kadıköy",
                "countryCode": "TR"
            },
            {
                "name": "Kaytazdere",
                "countryCode": "TR"
            },
            {
                "name": "Kocadere",
                "countryCode": "TR"
            },
            {
                "name": "Koruköy",
                "countryCode": "TR"
            },
            {
                "name": "Kılıç",
                "countryCode": "TR"
            },
            {
                "name": "Taşköprü",
                "countryCode": "TR"
            },
            {
                "name": "Termal İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Yalova",
                "countryCode": "TR"
            },
            {
                "name": "Çiftlikköy",
                "countryCode": "TR"
            },
            {
                "name": "Çınarcık",
                "countryCode": "TR"
            },
            {
                "name": "Eflani",
                "countryCode": "TR"
            },
            {
                "name": "Eskipazar İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gözyeri",
                "countryCode": "TR"
            },
            {
                "name": "Karabük",
                "countryCode": "TR"
            },
            {
                "name": "Ovacık İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Safranbolu",
                "countryCode": "TR"
            },
            {
                "name": "Yenice",
                "countryCode": "TR"
            },
            {
                "name": "Elbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Kilis",
                "countryCode": "TR"
            },
            {
                "name": "Musabeyli",
                "countryCode": "TR"
            },
            {
                "name": "Polateli İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Bahçe İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Düziçi İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Hasanbeyli",
                "countryCode": "TR"
            },
            {
                "name": "Kadirli",
                "countryCode": "TR"
            },
            {
                "name": "Osmaniye",
                "countryCode": "TR"
            },
            {
                "name": "Sumbas İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Toprakkale",
                "countryCode": "TR"
            },
            {
                "name": "Akçakoca",
                "countryCode": "TR"
            },
            {
                "name": "Cumayeri İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Düzce",
                "countryCode": "TR"
            },
            {
                "name": "Gölyaka İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Gümüşova İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Kaynaşlı İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Yığılca İlçesi",
                "countryCode": "TR"
            },
            {
                "name": "Çilimli İlçesi",
                "countryCode": "TR"
            }
        ]
    },
    {
        "name": "Turkmenistan",
        "phonecode": "993",
        "cities": [
            {
                "name": "Abadan",
                "countryCode": "TM"
            },
            {
                "name": "Annau",
                "countryCode": "TM"
            },
            {
                "name": "Arçabil",
                "countryCode": "TM"
            },
            {
                "name": "Baharly",
                "countryCode": "TM"
            },
            {
                "name": "Kaka",
                "countryCode": "TM"
            },
            {
                "name": "Tejen",
                "countryCode": "TM"
            },
            {
                "name": "Balkanabat",
                "countryCode": "TM"
            },
            {
                "name": "Bereket",
                "countryCode": "TM"
            },
            {
                "name": "Gumdag",
                "countryCode": "TM"
            },
            {
                "name": "Magtymguly",
                "countryCode": "TM"
            },
            {
                "name": "Serdar",
                "countryCode": "TM"
            },
            {
                "name": "Türkmenbaşy",
                "countryCode": "TM"
            },
            {
                "name": "Akdepe",
                "countryCode": "TM"
            },
            {
                "name": "Boldumsaz",
                "countryCode": "TM"
            },
            {
                "name": "Daşoguz",
                "countryCode": "TM"
            },
            {
                "name": "Köneürgench",
                "countryCode": "TM"
            },
            {
                "name": "Tagta",
                "countryCode": "TM"
            },
            {
                "name": "Yylanly",
                "countryCode": "TM"
            },
            {
                "name": "Atamyrat",
                "countryCode": "TM"
            },
            {
                "name": "Farap",
                "countryCode": "TM"
            },
            {
                "name": "Gazojak",
                "countryCode": "TM"
            },
            {
                "name": "Gowurdak",
                "countryCode": "TM"
            },
            {
                "name": "Saýat",
                "countryCode": "TM"
            },
            {
                "name": "Türkmenabat",
                "countryCode": "TM"
            },
            {
                "name": "Bayramaly",
                "countryCode": "TM"
            },
            {
                "name": "Mary",
                "countryCode": "TM"
            },
            {
                "name": "Serhetabat",
                "countryCode": "TM"
            },
            {
                "name": "Seydi",
                "countryCode": "TM"
            },
            {
                "name": "Yolöten",
                "countryCode": "TM"
            },
            {
                "name": "Ashgabat",
                "countryCode": "TM"
            }
        ]
    },
    {
        "name": "Turks And Caicos Islands",
        "phonecode": "+1-649",
        "cities": []
    },
    {
        "name": "Tuvalu",
        "phonecode": "688",
        "cities": [
            {
                "name": "Alapi Village",
                "countryCode": "TV"
            },
            {
                "name": "Fakaifou Village",
                "countryCode": "TV"
            },
            {
                "name": "Funafuti",
                "countryCode": "TV"
            },
            {
                "name": "Kulia Village",
                "countryCode": "TV"
            },
            {
                "name": "Niulakita",
                "countryCode": "TV"
            },
            {
                "name": "Savave Village",
                "countryCode": "TV"
            },
            {
                "name": "Toga Village",
                "countryCode": "TV"
            },
            {
                "name": "Tanrake Village",
                "countryCode": "TV"
            },
            {
                "name": "Asau Village",
                "countryCode": "TV"
            }
        ]
    },
    {
        "name": "Uganda",
        "phonecode": "256",
        "cities": [
            {
                "name": "Bukomansimbi District",
                "countryCode": "UG"
            },
            {
                "name": "Buvuma District",
                "countryCode": "UG"
            },
            {
                "name": "Bweyogerere",
                "countryCode": "UG"
            },
            {
                "name": "Byakabanda",
                "countryCode": "UG"
            },
            {
                "name": "Entebbe",
                "countryCode": "UG"
            },
            {
                "name": "Gomba District",
                "countryCode": "UG"
            },
            {
                "name": "Kajansi",
                "countryCode": "UG"
            },
            {
                "name": "Kampala",
                "countryCode": "UG"
            },
            {
                "name": "Kampala District",
                "countryCode": "UG"
            },
            {
                "name": "Kanoni",
                "countryCode": "UG"
            },
            {
                "name": "Kayunga",
                "countryCode": "UG"
            },
            {
                "name": "Kiboga",
                "countryCode": "UG"
            },
            {
                "name": "Kireka",
                "countryCode": "UG"
            },
            {
                "name": "Kyotera",
                "countryCode": "UG"
            },
            {
                "name": "Lugazi",
                "countryCode": "UG"
            },
            {
                "name": "Luwero",
                "countryCode": "UG"
            },
            {
                "name": "Lyantonde",
                "countryCode": "UG"
            },
            {
                "name": "Masaka",
                "countryCode": "UG"
            },
            {
                "name": "Mityana",
                "countryCode": "UG"
            },
            {
                "name": "Mpigi",
                "countryCode": "UG"
            },
            {
                "name": "Mubende",
                "countryCode": "UG"
            },
            {
                "name": "Mubende District",
                "countryCode": "UG"
            },
            {
                "name": "Mukono",
                "countryCode": "UG"
            },
            {
                "name": "Nakasongola",
                "countryCode": "UG"
            },
            {
                "name": "Namasuba",
                "countryCode": "UG"
            },
            {
                "name": "Njeru",
                "countryCode": "UG"
            },
            {
                "name": "Sembabule",
                "countryCode": "UG"
            },
            {
                "name": "Wakiso",
                "countryCode": "UG"
            },
            {
                "name": "Wakiso District",
                "countryCode": "UG"
            },
            {
                "name": "Wobulenzi",
                "countryCode": "UG"
            },
            {
                "name": "Bugembe",
                "countryCode": "UG"
            },
            {
                "name": "Bugiri",
                "countryCode": "UG"
            },
            {
                "name": "Bukwa District",
                "countryCode": "UG"
            },
            {
                "name": "Bulambuli District",
                "countryCode": "UG"
            },
            {
                "name": "Busembatia",
                "countryCode": "UG"
            },
            {
                "name": "Busia",
                "countryCode": "UG"
            },
            {
                "name": "Buwenge",
                "countryCode": "UG"
            },
            {
                "name": "Iganga",
                "countryCode": "UG"
            },
            {
                "name": "Jinja",
                "countryCode": "UG"
            },
            {
                "name": "Kamuli",
                "countryCode": "UG"
            },
            {
                "name": "Kapchorwa",
                "countryCode": "UG"
            },
            {
                "name": "Kibuku District",
                "countryCode": "UG"
            },
            {
                "name": "Kumi",
                "countryCode": "UG"
            },
            {
                "name": "Mayuge",
                "countryCode": "UG"
            },
            {
                "name": "Mbale",
                "countryCode": "UG"
            },
            {
                "name": "Pallisa",
                "countryCode": "UG"
            },
            {
                "name": "Sironko",
                "countryCode": "UG"
            },
            {
                "name": "Soroti",
                "countryCode": "UG"
            },
            {
                "name": "Tororo",
                "countryCode": "UG"
            },
            {
                "name": "Adjumani",
                "countryCode": "UG"
            },
            {
                "name": "Amudat",
                "countryCode": "UG"
            },
            {
                "name": "Apac",
                "countryCode": "UG"
            },
            {
                "name": "Arua",
                "countryCode": "UG"
            },
            {
                "name": "Gulu",
                "countryCode": "UG"
            },
            {
                "name": "Kitgum",
                "countryCode": "UG"
            },
            {
                "name": "Kotido",
                "countryCode": "UG"
            },
            {
                "name": "Lira",
                "countryCode": "UG"
            },
            {
                "name": "Moroto",
                "countryCode": "UG"
            },
            {
                "name": "Moyo",
                "countryCode": "UG"
            },
            {
                "name": "Nebbi",
                "countryCode": "UG"
            },
            {
                "name": "Otuke District",
                "countryCode": "UG"
            },
            {
                "name": "Oyam District",
                "countryCode": "UG"
            },
            {
                "name": "Pader",
                "countryCode": "UG"
            },
            {
                "name": "Pader Palwo",
                "countryCode": "UG"
            },
            {
                "name": "Paidha",
                "countryCode": "UG"
            },
            {
                "name": "Yumbe",
                "countryCode": "UG"
            },
            {
                "name": "Bundibugyo",
                "countryCode": "UG"
            },
            {
                "name": "Bwizibwera",
                "countryCode": "UG"
            },
            {
                "name": "Fort Portal",
                "countryCode": "UG"
            },
            {
                "name": "Hoima",
                "countryCode": "UG"
            },
            {
                "name": "Ibanda",
                "countryCode": "UG"
            },
            {
                "name": "Ibanda District",
                "countryCode": "UG"
            },
            {
                "name": "Kabale",
                "countryCode": "UG"
            },
            {
                "name": "Kagadi",
                "countryCode": "UG"
            },
            {
                "name": "Kamwenge",
                "countryCode": "UG"
            },
            {
                "name": "Kanungu",
                "countryCode": "UG"
            },
            {
                "name": "Kasese",
                "countryCode": "UG"
            },
            {
                "name": "Kibale",
                "countryCode": "UG"
            },
            {
                "name": "Kigorobya",
                "countryCode": "UG"
            },
            {
                "name": "Kilembe",
                "countryCode": "UG"
            },
            {
                "name": "Kiruhura",
                "countryCode": "UG"
            },
            {
                "name": "Kisoro",
                "countryCode": "UG"
            },
            {
                "name": "Kyenjojo",
                "countryCode": "UG"
            },
            {
                "name": "Margherita",
                "countryCode": "UG"
            },
            {
                "name": "Masindi",
                "countryCode": "UG"
            },
            {
                "name": "Masindi Port",
                "countryCode": "UG"
            },
            {
                "name": "Mbarara",
                "countryCode": "UG"
            },
            {
                "name": "Muhororo",
                "countryCode": "UG"
            },
            {
                "name": "Ntungamo",
                "countryCode": "UG"
            },
            {
                "name": "Nyachera",
                "countryCode": "UG"
            },
            {
                "name": "Rukungiri",
                "countryCode": "UG"
            }
        ]
    },
    {
        "name": "Ukraine",
        "phonecode": "380",
        "cities": [
            {
                "name": "Bar",
                "countryCode": "UA"
            },
            {
                "name": "Barskiy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Bershad",
                "countryCode": "UA"
            },
            {
                "name": "Brailiv",
                "countryCode": "UA"
            },
            {
                "name": "Bratslav",
                "countryCode": "UA"
            },
            {
                "name": "Chechelnyk",
                "countryCode": "UA"
            },
            {
                "name": "Chernivets’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Chernivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Dashiv",
                "countryCode": "UA"
            },
            {
                "name": "Haisyn",
                "countryCode": "UA"
            },
            {
                "name": "Illintsi",
                "countryCode": "UA"
            },
            {
                "name": "Kalynivka",
                "countryCode": "UA"
            },
            {
                "name": "Khmilnyk",
                "countryCode": "UA"
            },
            {
                "name": "Klembivka",
                "countryCode": "UA"
            },
            {
                "name": "Kopayhorod",
                "countryCode": "UA"
            },
            {
                "name": "Kozyatyn",
                "countryCode": "UA"
            },
            {
                "name": "Kryzhopil’",
                "countryCode": "UA"
            },
            {
                "name": "Ladyzhyn",
                "countryCode": "UA"
            },
            {
                "name": "Lityn",
                "countryCode": "UA"
            },
            {
                "name": "Lityns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Lypovets’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Mohyliv-Podilskyi",
                "countryCode": "UA"
            },
            {
                "name": "Murafa",
                "countryCode": "UA"
            },
            {
                "name": "Murovani Kurylivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Nemyriv",
                "countryCode": "UA"
            },
            {
                "name": "Nova Pryluka",
                "countryCode": "UA"
            },
            {
                "name": "Obodivka",
                "countryCode": "UA"
            },
            {
                "name": "Orativ",
                "countryCode": "UA"
            },
            {
                "name": "Pavlivka",
                "countryCode": "UA"
            },
            {
                "name": "Pohrebyshche",
                "countryCode": "UA"
            },
            {
                "name": "Pohrebyshchens’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Serebriya",
                "countryCode": "UA"
            },
            {
                "name": "Sharhorod",
                "countryCode": "UA"
            },
            {
                "name": "Sobolivka",
                "countryCode": "UA"
            },
            {
                "name": "Sutysky",
                "countryCode": "UA"
            },
            {
                "name": "Teplyk",
                "countryCode": "UA"
            },
            {
                "name": "Tomashpil’",
                "countryCode": "UA"
            },
            {
                "name": "Torkanivka",
                "countryCode": "UA"
            },
            {
                "name": "Tsybulevka",
                "countryCode": "UA"
            },
            {
                "name": "Tulchyn",
                "countryCode": "UA"
            },
            {
                "name": "Turbiv",
                "countryCode": "UA"
            },
            {
                "name": "Tyvriv",
                "countryCode": "UA"
            },
            {
                "name": "Ulaniv",
                "countryCode": "UA"
            },
            {
                "name": "Vapnyarka",
                "countryCode": "UA"
            },
            {
                "name": "Vendychany",
                "countryCode": "UA"
            },
            {
                "name": "Vinnitskiy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Vinnytsia",
                "countryCode": "UA"
            },
            {
                "name": "Viytivka",
                "countryCode": "UA"
            },
            {
                "name": "Voronovytsya",
                "countryCode": "UA"
            },
            {
                "name": "Yampil’",
                "countryCode": "UA"
            },
            {
                "name": "Zhmerynka",
                "countryCode": "UA"
            },
            {
                "name": "Berestechko",
                "countryCode": "UA"
            },
            {
                "name": "Blahodatne",
                "countryCode": "UA"
            },
            {
                "name": "Horokhiv",
                "countryCode": "UA"
            },
            {
                "name": "Hołoby",
                "countryCode": "UA"
            },
            {
                "name": "Kamin-Kashyrskyi",
                "countryCode": "UA"
            },
            {
                "name": "Kivertsi",
                "countryCode": "UA"
            },
            {
                "name": "Kovel",
                "countryCode": "UA"
            },
            {
                "name": "Kovel’s’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Liuboml",
                "countryCode": "UA"
            },
            {
                "name": "Lokachi",
                "countryCode": "UA"
            },
            {
                "name": "Lukiv",
                "countryCode": "UA"
            },
            {
                "name": "Lutsk",
                "countryCode": "UA"
            },
            {
                "name": "Lyubeshivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Lyuboml’s’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Manevychi",
                "countryCode": "UA"
            },
            {
                "name": "Manevyts’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Novovolyns’k",
                "countryCode": "UA"
            },
            {
                "name": "Nuyno",
                "countryCode": "UA"
            },
            {
                "name": "Olyka",
                "countryCode": "UA"
            },
            {
                "name": "Pishcha",
                "countryCode": "UA"
            },
            {
                "name": "Rakiv Lis",
                "countryCode": "UA"
            },
            {
                "name": "Ratne",
                "countryCode": "UA"
            },
            {
                "name": "Ratnivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Rozhyshche",
                "countryCode": "UA"
            },
            {
                "name": "Shats’k",
                "countryCode": "UA"
            },
            {
                "name": "Shats’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Stara Vyzhivka",
                "countryCode": "UA"
            },
            {
                "name": "Svityaz’",
                "countryCode": "UA"
            },
            {
                "name": "Volodymyr-Volynskyi",
                "countryCode": "UA"
            },
            {
                "name": "Alchevs’k",
                "countryCode": "UA"
            },
            {
                "name": "Alchevs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Antratsyt",
                "countryCode": "UA"
            },
            {
                "name": "Antratsytivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Artemivs’k",
                "countryCode": "UA"
            },
            {
                "name": "Bayrachky",
                "countryCode": "UA"
            },
            {
                "name": "Bile",
                "countryCode": "UA"
            },
            {
                "name": "Bilohorivka",
                "countryCode": "UA"
            },
            {
                "name": "Bilokurakyne",
                "countryCode": "UA"
            },
            {
                "name": "Bilovods'k",
                "countryCode": "UA"
            },
            {
                "name": "Biryukove",
                "countryCode": "UA"
            },
            {
                "name": "Bryanka",
                "countryCode": "UA"
            },
            {
                "name": "Buran",
                "countryCode": "UA"
            },
            {
                "name": "Chervonopartyzans’k",
                "countryCode": "UA"
            },
            {
                "name": "Chornukhyne",
                "countryCode": "UA"
            },
            {
                "name": "Dovzhanskyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Hirs’ke",
                "countryCode": "UA"
            },
            {
                "name": "Kadiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Kirovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Kirovs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Klenovyy",
                "countryCode": "UA"
            },
            {
                "name": "Krasnyy Kut",
                "countryCode": "UA"
            },
            {
                "name": "Krasnyy Luch",
                "countryCode": "UA"
            },
            {
                "name": "Kreminna",
                "countryCode": "UA"
            },
            {
                "name": "Kripens’kyy",
                "countryCode": "UA"
            },
            {
                "name": "Lenina",
                "countryCode": "UA"
            },
            {
                "name": "Lozno-Oleksandrivka",
                "countryCode": "UA"
            },
            {
                "name": "Luhansk",
                "countryCode": "UA"
            },
            {
                "name": "Luhans’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Lutuhyne",
                "countryCode": "UA"
            },
            {
                "name": "Lutuhyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Lysychans’k",
                "countryCode": "UA"
            },
            {
                "name": "Makariv Yar",
                "countryCode": "UA"
            },
            {
                "name": "Markivka",
                "countryCode": "UA"
            },
            {
                "name": "Millerovo",
                "countryCode": "UA"
            },
            {
                "name": "Milove",
                "countryCode": "UA"
            },
            {
                "name": "Miusyns’k",
                "countryCode": "UA"
            },
            {
                "name": "Molodohvardiys’k",
                "countryCode": "UA"
            },
            {
                "name": "Novopskov",
                "countryCode": "UA"
            },
            {
                "name": "Nyzhnya Duvanka",
                "countryCode": "UA"
            },
            {
                "name": "Pavlivka",
                "countryCode": "UA"
            },
            {
                "name": "Pereval’s’k",
                "countryCode": "UA"
            },
            {
                "name": "Pervomays’k",
                "countryCode": "UA"
            },
            {
                "name": "Popasna",
                "countryCode": "UA"
            },
            {
                "name": "Pryvillya",
                "countryCode": "UA"
            },
            {
                "name": "Roven’ky",
                "countryCode": "UA"
            },
            {
                "name": "Rozkishne",
                "countryCode": "UA"
            },
            {
                "name": "Rubizhans’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Rubizhne",
                "countryCode": "UA"
            },
            {
                "name": "Shchastya",
                "countryCode": "UA"
            },
            {
                "name": "Simeykyne",
                "countryCode": "UA"
            },
            {
                "name": "Slov`yanoserbsk",
                "countryCode": "UA"
            },
            {
                "name": "Sorokyne",
                "countryCode": "UA"
            },
            {
                "name": "Sorokyns'kyi Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Stanytsya Luhans’ka",
                "countryCode": "UA"
            },
            {
                "name": "Starobil’s’k",
                "countryCode": "UA"
            },
            {
                "name": "Svatove",
                "countryCode": "UA"
            },
            {
                "name": "Sverdlovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Sverdlovs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Syevyerodonets’k",
                "countryCode": "UA"
            },
            {
                "name": "Teple",
                "countryCode": "UA"
            },
            {
                "name": "Toshkivka",
                "countryCode": "UA"
            },
            {
                "name": "Tr’okhizbenka",
                "countryCode": "UA"
            },
            {
                "name": "Uralo-Kavkaz",
                "countryCode": "UA"
            },
            {
                "name": "Uspenka",
                "countryCode": "UA"
            },
            {
                "name": "Zalesnoye",
                "countryCode": "UA"
            },
            {
                "name": "Zoryns’k",
                "countryCode": "UA"
            },
            {
                "name": "Zymohiria",
                "countryCode": "UA"
            },
            {
                "name": "Apostolove",
                "countryCode": "UA"
            },
            {
                "name": "Auly",
                "countryCode": "UA"
            },
            {
                "name": "Aviatorske",
                "countryCode": "UA"
            },
            {
                "name": "Chervonohryhorivka",
                "countryCode": "UA"
            },
            {
                "name": "Chortomlyk",
                "countryCode": "UA"
            },
            {
                "name": "Dnipro",
                "countryCode": "UA"
            },
            {
                "name": "Dnipro Raion",
                "countryCode": "UA"
            },
            {
                "name": "Dniprovs’ka Mis’ka Rada",
                "countryCode": "UA"
            },
            {
                "name": "Hubynykha",
                "countryCode": "UA"
            },
            {
                "name": "Hupalivka",
                "countryCode": "UA"
            },
            {
                "name": "Kamianske",
                "countryCode": "UA"
            },
            {
                "name": "Kaydaki",
                "countryCode": "UA"
            },
            {
                "name": "Krynychky",
                "countryCode": "UA"
            },
            {
                "name": "Kryvoriz’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Kryvyi Rih",
                "countryCode": "UA"
            },
            {
                "name": "Lykhivka",
                "countryCode": "UA"
            },
            {
                "name": "Mahdalynivka",
                "countryCode": "UA"
            },
            {
                "name": "Mahdalynivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Marhanets’",
                "countryCode": "UA"
            },
            {
                "name": "Melioratyvne",
                "countryCode": "UA"
            },
            {
                "name": "Mezhova",
                "countryCode": "UA"
            },
            {
                "name": "Nikopol",
                "countryCode": "UA"
            },
            {
                "name": "Novomoskovsk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Novomoskovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Novomykolayivka",
                "countryCode": "UA"
            },
            {
                "name": "Novopokrovka",
                "countryCode": "UA"
            },
            {
                "name": "Ordzhonikidze",
                "countryCode": "UA"
            },
            {
                "name": "Partyzans’ke",
                "countryCode": "UA"
            },
            {
                "name": "Pavlohrad",
                "countryCode": "UA"
            },
            {
                "name": "Petropavlivka",
                "countryCode": "UA"
            },
            {
                "name": "Petrykivka",
                "countryCode": "UA"
            },
            {
                "name": "Petrykivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Piatykhatky",
                "countryCode": "UA"
            },
            {
                "name": "Pidhorodne",
                "countryCode": "UA"
            },
            {
                "name": "Sholokhove",
                "countryCode": "UA"
            },
            {
                "name": "Shyroke",
                "countryCode": "UA"
            },
            {
                "name": "Shyroke Raion",
                "countryCode": "UA"
            },
            {
                "name": "Sofiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Solone",
                "countryCode": "UA"
            },
            {
                "name": "Solone Raion",
                "countryCode": "UA"
            },
            {
                "name": "Spas’ke",
                "countryCode": "UA"
            },
            {
                "name": "Surs’ko-Mykhaylivka",
                "countryCode": "UA"
            },
            {
                "name": "Synel’nykove",
                "countryCode": "UA"
            },
            {
                "name": "Tomakivka",
                "countryCode": "UA"
            },
            {
                "name": "Tsarychanka",
                "countryCode": "UA"
            },
            {
                "name": "Tsarychans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Verkhn’odniprovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Vil’ne",
                "countryCode": "UA"
            },
            {
                "name": "Vyshchetarasivka",
                "countryCode": "UA"
            },
            {
                "name": "Vyshneve",
                "countryCode": "UA"
            },
            {
                "name": "Yur”yivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Zhovti Vody",
                "countryCode": "UA"
            },
            {
                "name": "Amvrosiivka Raion",
                "countryCode": "UA"
            },
            {
                "name": "Amvrosiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Avdiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Bakhmut",
                "countryCode": "UA"
            },
            {
                "name": "Bakhmut Raion",
                "countryCode": "UA"
            },
            {
                "name": "Bezimenne",
                "countryCode": "UA"
            },
            {
                "name": "Bilyts’ke",
                "countryCode": "UA"
            },
            {
                "name": "Blahodatne",
                "countryCode": "UA"
            },
            {
                "name": "Boykivske",
                "countryCode": "UA"
            },
            {
                "name": "Boykivske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Chasiv Yar",
                "countryCode": "UA"
            },
            {
                "name": "Chystyakove",
                "countryCode": "UA"
            },
            {
                "name": "Debal’tseve",
                "countryCode": "UA"
            },
            {
                "name": "Dmytrivka",
                "countryCode": "UA"
            },
            {
                "name": "Dobropillia Raion",
                "countryCode": "UA"
            },
            {
                "name": "Dobropillya",
                "countryCode": "UA"
            },
            {
                "name": "Dokuchayevs’k",
                "countryCode": "UA"
            },
            {
                "name": "Donetsk",
                "countryCode": "UA"
            },
            {
                "name": "Druzhkivka",
                "countryCode": "UA"
            },
            {
                "name": "Horlivka",
                "countryCode": "UA"
            },
            {
                "name": "Ilovays’k",
                "countryCode": "UA"
            },
            {
                "name": "Khartsyz’k",
                "countryCode": "UA"
            },
            {
                "name": "Kostiantynivka Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kostyantynivka",
                "countryCode": "UA"
            },
            {
                "name": "Kramators’k",
                "countryCode": "UA"
            },
            {
                "name": "Krasnotorka",
                "countryCode": "UA"
            },
            {
                "name": "Krinichnaya",
                "countryCode": "UA"
            },
            {
                "name": "Kurakhovo",
                "countryCode": "UA"
            },
            {
                "name": "Kuteynykove",
                "countryCode": "UA"
            },
            {
                "name": "Luhans’ke",
                "countryCode": "UA"
            },
            {
                "name": "Lyman",
                "countryCode": "UA"
            },
            {
                "name": "Lyman Raion",
                "countryCode": "UA"
            },
            {
                "name": "Makiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Makiyivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Manhush",
                "countryCode": "UA"
            },
            {
                "name": "Mar'yinka",
                "countryCode": "UA"
            },
            {
                "name": "Mariupol",
                "countryCode": "UA"
            },
            {
                "name": "Melekyne",
                "countryCode": "UA"
            },
            {
                "name": "Mospyne",
                "countryCode": "UA"
            },
            {
                "name": "Myrnohrad",
                "countryCode": "UA"
            },
            {
                "name": "Nikolske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Novoazovs'k",
                "countryCode": "UA"
            },
            {
                "name": "Novodonets’ke",
                "countryCode": "UA"
            },
            {
                "name": "Novyy Svit",
                "countryCode": "UA"
            },
            {
                "name": "Nyzhnya Krynka",
                "countryCode": "UA"
            },
            {
                "name": "P'yatypill'ya",
                "countryCode": "UA"
            },
            {
                "name": "Pokrovsk",
                "countryCode": "UA"
            },
            {
                "name": "Pokrovsk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Rodyns’ke",
                "countryCode": "UA"
            },
            {
                "name": "Rozsypne",
                "countryCode": "UA"
            },
            {
                "name": "Sartana",
                "countryCode": "UA"
            },
            {
                "name": "Selydivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Selydove",
                "countryCode": "UA"
            },
            {
                "name": "Serebryanka",
                "countryCode": "UA"
            },
            {
                "name": "Shakhtars’k",
                "countryCode": "UA"
            },
            {
                "name": "Shcherbynivka",
                "countryCode": "UA"
            },
            {
                "name": "Shyroke",
                "countryCode": "UA"
            },
            {
                "name": "Sivers’k",
                "countryCode": "UA"
            },
            {
                "name": "Sloviansk",
                "countryCode": "UA"
            },
            {
                "name": "Sloviansk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Snizhne",
                "countryCode": "UA"
            },
            {
                "name": "Soledar",
                "countryCode": "UA"
            },
            {
                "name": "Starobesheve",
                "countryCode": "UA"
            },
            {
                "name": "Staryy Krym",
                "countryCode": "UA"
            },
            {
                "name": "Stizhkivs’ke",
                "countryCode": "UA"
            },
            {
                "name": "Svetlodarsk",
                "countryCode": "UA"
            },
            {
                "name": "Svyatogorsk",
                "countryCode": "UA"
            },
            {
                "name": "Syedove",
                "countryCode": "UA"
            },
            {
                "name": "Temriuk",
                "countryCode": "UA"
            },
            {
                "name": "Toretsk",
                "countryCode": "UA"
            },
            {
                "name": "Urzuf",
                "countryCode": "UA"
            },
            {
                "name": "Volnovakha",
                "countryCode": "UA"
            },
            {
                "name": "Vuhlehirs’k",
                "countryCode": "UA"
            },
            {
                "name": "Yalta",
                "countryCode": "UA"
            },
            {
                "name": "Yasynuvata",
                "countryCode": "UA"
            },
            {
                "name": "Yasynuvata Raion",
                "countryCode": "UA"
            },
            {
                "name": "Yenakiyeve",
                "countryCode": "UA"
            },
            {
                "name": "Zuhres",
                "countryCode": "UA"
            },
            {
                "name": "Andrushivka",
                "countryCode": "UA"
            },
            {
                "name": "Andrushivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Baranivka",
                "countryCode": "UA"
            },
            {
                "name": "Berdychiv",
                "countryCode": "UA"
            },
            {
                "name": "Berdychivskyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Bila Krynytsya",
                "countryCode": "UA"
            },
            {
                "name": "Brusyliv",
                "countryCode": "UA"
            },
            {
                "name": "Brusylivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Bykivka",
                "countryCode": "UA"
            },
            {
                "name": "Cherniakhiv",
                "countryCode": "UA"
            },
            {
                "name": "Chernyakhivskyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Chervone",
                "countryCode": "UA"
            },
            {
                "name": "Chopovychi",
                "countryCode": "UA"
            },
            {
                "name": "Chornorudka",
                "countryCode": "UA"
            },
            {
                "name": "Chudniv",
                "countryCode": "UA"
            },
            {
                "name": "Chudniv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Denyshi",
                "countryCode": "UA"
            },
            {
                "name": "Dovbysh",
                "countryCode": "UA"
            },
            {
                "name": "Holovyne",
                "countryCode": "UA"
            },
            {
                "name": "Horodnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Horodok",
                "countryCode": "UA"
            },
            {
                "name": "Hryshkivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Khoroshiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kornyn",
                "countryCode": "UA"
            },
            {
                "name": "Korosten’",
                "countryCode": "UA"
            },
            {
                "name": "Korostyshiv",
                "countryCode": "UA"
            },
            {
                "name": "Korostyshiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Liubar",
                "countryCode": "UA"
            },
            {
                "name": "Luhyny",
                "countryCode": "UA"
            },
            {
                "name": "Luhyny Raion",
                "countryCode": "UA"
            },
            {
                "name": "Malyn",
                "countryCode": "UA"
            },
            {
                "name": "Malyn Raion",
                "countryCode": "UA"
            },
            {
                "name": "Narodychi",
                "countryCode": "UA"
            },
            {
                "name": "Narodychi Raion",
                "countryCode": "UA"
            },
            {
                "name": "Nova Borova",
                "countryCode": "UA"
            },
            {
                "name": "Novi Bilokorovychi",
                "countryCode": "UA"
            },
            {
                "name": "Novohrad-Volynskyi",
                "countryCode": "UA"
            },
            {
                "name": "Olevs’k",
                "countryCode": "UA"
            },
            {
                "name": "Ovruch",
                "countryCode": "UA"
            },
            {
                "name": "Ovruch Raion",
                "countryCode": "UA"
            },
            {
                "name": "Ozerne",
                "countryCode": "UA"
            },
            {
                "name": "Pershotravneve",
                "countryCode": "UA"
            },
            {
                "name": "Polyanka",
                "countryCode": "UA"
            },
            {
                "name": "Popilnia",
                "countryCode": "UA"
            },
            {
                "name": "Popilnia Raion",
                "countryCode": "UA"
            },
            {
                "name": "Pulyny Raion",
                "countryCode": "UA"
            },
            {
                "name": "Radomyshl",
                "countryCode": "UA"
            },
            {
                "name": "Radomyshl Raion",
                "countryCode": "UA"
            },
            {
                "name": "Romaniv",
                "countryCode": "UA"
            },
            {
                "name": "Ruzhyn",
                "countryCode": "UA"
            },
            {
                "name": "Stavyshche",
                "countryCode": "UA"
            },
            {
                "name": "Topory",
                "countryCode": "UA"
            },
            {
                "name": "Yemil’chyne",
                "countryCode": "UA"
            },
            {
                "name": "Zhytomyr",
                "countryCode": "UA"
            },
            {
                "name": "Zhytomyr Raion",
                "countryCode": "UA"
            },
            {
                "name": "Batiovo",
                "countryCode": "UA"
            },
            {
                "name": "Berehivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Berehove",
                "countryCode": "UA"
            },
            {
                "name": "Berehove Raion",
                "countryCode": "UA"
            },
            {
                "name": "Bereznyky",
                "countryCode": "UA"
            },
            {
                "name": "Berezovo",
                "countryCode": "UA"
            },
            {
                "name": "Bilky",
                "countryCode": "UA"
            },
            {
                "name": "Bobovo",
                "countryCode": "UA"
            },
            {
                "name": "Bohdan",
                "countryCode": "UA"
            },
            {
                "name": "Bushtyno",
                "countryCode": "UA"
            },
            {
                "name": "Chop",
                "countryCode": "UA"
            },
            {
                "name": "Chynadiyovo",
                "countryCode": "UA"
            },
            {
                "name": "Dovhe",
                "countryCode": "UA"
            },
            {
                "name": "Drahovo",
                "countryCode": "UA"
            },
            {
                "name": "Dubove",
                "countryCode": "UA"
            },
            {
                "name": "Horinchovo",
                "countryCode": "UA"
            },
            {
                "name": "Irshava",
                "countryCode": "UA"
            },
            {
                "name": "Irshava Raion",
                "countryCode": "UA"
            },
            {
                "name": "Iza",
                "countryCode": "UA"
            },
            {
                "name": "Kalyny",
                "countryCode": "UA"
            },
            {
                "name": "Kamianytsia",
                "countryCode": "UA"
            },
            {
                "name": "Khust",
                "countryCode": "UA"
            },
            {
                "name": "Khust Raion",
                "countryCode": "UA"
            },
            {
                "name": "Khusts’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Kolochava",
                "countryCode": "UA"
            },
            {
                "name": "Kopashnovo",
                "countryCode": "UA"
            },
            {
                "name": "Korolevo",
                "countryCode": "UA"
            },
            {
                "name": "Koson’",
                "countryCode": "UA"
            },
            {
                "name": "Kryva",
                "countryCode": "UA"
            },
            {
                "name": "Kushnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Kvasy",
                "countryCode": "UA"
            },
            {
                "name": "Lazeshchyna",
                "countryCode": "UA"
            },
            {
                "name": "Lazi",
                "countryCode": "UA"
            },
            {
                "name": "Lopukhiv",
                "countryCode": "UA"
            },
            {
                "name": "Lypcha",
                "countryCode": "UA"
            },
            {
                "name": "Lysychovo",
                "countryCode": "UA"
            },
            {
                "name": "Mizhhirya",
                "countryCode": "UA"
            },
            {
                "name": "Mizhhirya Raion",
                "countryCode": "UA"
            },
            {
                "name": "Mukacheve",
                "countryCode": "UA"
            },
            {
                "name": "Mukachevo Raion",
                "countryCode": "UA"
            },
            {
                "name": "Muzhiyevo",
                "countryCode": "UA"
            },
            {
                "name": "Mynay",
                "countryCode": "UA"
            },
            {
                "name": "Nelipyno",
                "countryCode": "UA"
            },
            {
                "name": "Neresnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Nevyts’ke",
                "countryCode": "UA"
            },
            {
                "name": "Nove Davydkovo",
                "countryCode": "UA"
            },
            {
                "name": "Nyzhnye Selyshche",
                "countryCode": "UA"
            },
            {
                "name": "Oleshnyk",
                "countryCode": "UA"
            },
            {
                "name": "Onokivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Perechyn",
                "countryCode": "UA"
            },
            {
                "name": "Perechyn Raion",
                "countryCode": "UA"
            },
            {
                "name": "Pidvynohradiv",
                "countryCode": "UA"
            },
            {
                "name": "Poroshkovo",
                "countryCode": "UA"
            },
            {
                "name": "Pylypets",
                "countryCode": "UA"
            },
            {
                "name": "Rakhiv",
                "countryCode": "UA"
            },
            {
                "name": "Rakhiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Rakoshyno",
                "countryCode": "UA"
            },
            {
                "name": "Rokosovo",
                "countryCode": "UA"
            },
            {
                "name": "Serednye",
                "countryCode": "UA"
            },
            {
                "name": "Serednye Vodyane",
                "countryCode": "UA"
            },
            {
                "name": "Solotvyno",
                "countryCode": "UA"
            },
            {
                "name": "Storozhnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Strabychovo",
                "countryCode": "UA"
            },
            {
                "name": "Svaliava Raion",
                "countryCode": "UA"
            },
            {
                "name": "Svalyava",
                "countryCode": "UA"
            },
            {
                "name": "Synevyr",
                "countryCode": "UA"
            },
            {
                "name": "Synevyrska Poliana",
                "countryCode": "UA"
            },
            {
                "name": "Tiachiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Tyachiv",
                "countryCode": "UA"
            },
            {
                "name": "Uzhgorod",
                "countryCode": "UA"
            },
            {
                "name": "Uzhhorod Raion",
                "countryCode": "UA"
            },
            {
                "name": "Vary",
                "countryCode": "UA"
            },
            {
                "name": "Velyki Berehy",
                "countryCode": "UA"
            },
            {
                "name": "Velykyi Bereznyi",
                "countryCode": "UA"
            },
            {
                "name": "Velykyi Bereznyi Raion",
                "countryCode": "UA"
            },
            {
                "name": "Volovets",
                "countryCode": "UA"
            },
            {
                "name": "Volovets Raion",
                "countryCode": "UA"
            },
            {
                "name": "Vylok",
                "countryCode": "UA"
            },
            {
                "name": "Vynohradiv",
                "countryCode": "UA"
            },
            {
                "name": "Vynohradiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Vyshkovo",
                "countryCode": "UA"
            },
            {
                "name": "Yasinya",
                "countryCode": "UA"
            },
            {
                "name": "Balky",
                "countryCode": "UA"
            },
            {
                "name": "Berdiansk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Berdyansk",
                "countryCode": "UA"
            },
            {
                "name": "Berdyans’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Chapayevka",
                "countryCode": "UA"
            },
            {
                "name": "Chernihivka Raion",
                "countryCode": "UA"
            },
            {
                "name": "Dniprorudne",
                "countryCode": "UA"
            },
            {
                "name": "Dniprovka",
                "countryCode": "UA"
            },
            {
                "name": "Energodar",
                "countryCode": "UA"
            },
            {
                "name": "Hulyaypole",
                "countryCode": "UA"
            },
            {
                "name": "Kirove",
                "countryCode": "UA"
            },
            {
                "name": "Komysh-Zorya",
                "countryCode": "UA"
            },
            {
                "name": "Komyshuvakha",
                "countryCode": "UA"
            },
            {
                "name": "Kostyantynivka",
                "countryCode": "UA"
            },
            {
                "name": "Kushuhum",
                "countryCode": "UA"
            },
            {
                "name": "Kuybysheve",
                "countryCode": "UA"
            },
            {
                "name": "Kyrylivka",
                "countryCode": "UA"
            },
            {
                "name": "Mala Bilozerka",
                "countryCode": "UA"
            },
            {
                "name": "Mala Tokmachka",
                "countryCode": "UA"
            },
            {
                "name": "Malokaterynivka",
                "countryCode": "UA"
            },
            {
                "name": "Matviyivka",
                "countryCode": "UA"
            },
            {
                "name": "Melitopol",
                "countryCode": "UA"
            },
            {
                "name": "Melitopol’s’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Mykhaylivka",
                "countryCode": "UA"
            },
            {
                "name": "Mykhaylivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Novobohdanivka",
                "countryCode": "UA"
            },
            {
                "name": "Novomykolayivka",
                "countryCode": "UA"
            },
            {
                "name": "Novovasylivka",
                "countryCode": "UA"
            },
            {
                "name": "Orikhiv",
                "countryCode": "UA"
            },
            {
                "name": "Osypenko",
                "countryCode": "UA"
            },
            {
                "name": "Polohy",
                "countryCode": "UA"
            },
            {
                "name": "Preobrazhenka",
                "countryCode": "UA"
            },
            {
                "name": "Pryazovske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Prymorsk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Prymors’k",
                "countryCode": "UA"
            },
            {
                "name": "Pryshyb",
                "countryCode": "UA"
            },
            {
                "name": "Rozivka",
                "countryCode": "UA"
            },
            {
                "name": "Rozivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Ternuvate",
                "countryCode": "UA"
            },
            {
                "name": "Terpinnya",
                "countryCode": "UA"
            },
            {
                "name": "Tokmak",
                "countryCode": "UA"
            },
            {
                "name": "Tokmak Raion",
                "countryCode": "UA"
            },
            {
                "name": "Tymoshivka",
                "countryCode": "UA"
            },
            {
                "name": "Vasylivka",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Bilozerka",
                "countryCode": "UA"
            },
            {
                "name": "Velykobilozers’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Vesele",
                "countryCode": "UA"
            },
            {
                "name": "Vilniansk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Vodyane",
                "countryCode": "UA"
            },
            {
                "name": "Voznesenka",
                "countryCode": "UA"
            },
            {
                "name": "Yakymivka",
                "countryCode": "UA"
            },
            {
                "name": "Yakymivka Raion",
                "countryCode": "UA"
            },
            {
                "name": "Zaliznychne",
                "countryCode": "UA"
            },
            {
                "name": "Zaporizhia",
                "countryCode": "UA"
            },
            {
                "name": "Zaporiz’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Bili Oslavy",
                "countryCode": "UA"
            },
            {
                "name": "Bilshivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Bohorodchans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Bohorodchany",
                "countryCode": "UA"
            },
            {
                "name": "Bolekhiv",
                "countryCode": "UA"
            },
            {
                "name": "Bolekhivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Borshchevskiy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Broshniv-Osada",
                "countryCode": "UA"
            },
            {
                "name": "Burshtyn",
                "countryCode": "UA"
            },
            {
                "name": "Burshtyns’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Bystrytsya",
                "countryCode": "UA"
            },
            {
                "name": "Bytkiv",
                "countryCode": "UA"
            },
            {
                "name": "Chernelytsya",
                "countryCode": "UA"
            },
            {
                "name": "Cherniyiv",
                "countryCode": "UA"
            },
            {
                "name": "Chernyatyn",
                "countryCode": "UA"
            },
            {
                "name": "Chornoliztsi",
                "countryCode": "UA"
            },
            {
                "name": "Delyatyn",
                "countryCode": "UA"
            },
            {
                "name": "Dolyna",
                "countryCode": "UA"
            },
            {
                "name": "Dolyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Halych",
                "countryCode": "UA"
            },
            {
                "name": "Halyts’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Horodenka",
                "countryCode": "UA"
            },
            {
                "name": "Horodenkivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Hvizd",
                "countryCode": "UA"
            },
            {
                "name": "Ivano-Frankivsk",
                "countryCode": "UA"
            },
            {
                "name": "Ivano-Frankivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Kalush",
                "countryCode": "UA"
            },
            {
                "name": "Kalus’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Khymchyn",
                "countryCode": "UA"
            },
            {
                "name": "Kolomyia",
                "countryCode": "UA"
            },
            {
                "name": "Kolomyys’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Kosiv",
                "countryCode": "UA"
            },
            {
                "name": "Kosivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Kosmach",
                "countryCode": "UA"
            },
            {
                "name": "Krasna",
                "countryCode": "UA"
            },
            {
                "name": "Kuty",
                "countryCode": "UA"
            },
            {
                "name": "Lanchyn",
                "countryCode": "UA"
            },
            {
                "name": "Manyava",
                "countryCode": "UA"
            },
            {
                "name": "Markova",
                "countryCode": "UA"
            },
            {
                "name": "Nadvirna",
                "countryCode": "UA"
            },
            {
                "name": "Nadvirnyans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Nebyliv",
                "countryCode": "UA"
            },
            {
                "name": "Novytsya",
                "countryCode": "UA"
            },
            {
                "name": "Obertyn",
                "countryCode": "UA"
            },
            {
                "name": "Otyniya",
                "countryCode": "UA"
            },
            {
                "name": "Pasichna",
                "countryCode": "UA"
            },
            {
                "name": "Pechenizhyn",
                "countryCode": "UA"
            },
            {
                "name": "Petranka",
                "countryCode": "UA"
            },
            {
                "name": "Pniv",
                "countryCode": "UA"
            },
            {
                "name": "Porohy",
                "countryCode": "UA"
            },
            {
                "name": "Radcha",
                "countryCode": "UA"
            },
            {
                "name": "Rechka",
                "countryCode": "UA"
            },
            {
                "name": "Rohatyn",
                "countryCode": "UA"
            },
            {
                "name": "Rohatyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Rozhniativ",
                "countryCode": "UA"
            },
            {
                "name": "Rozhniv",
                "countryCode": "UA"
            },
            {
                "name": "Rozhnyativs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Sadzhavka",
                "countryCode": "UA"
            },
            {
                "name": "Sheshory",
                "countryCode": "UA"
            },
            {
                "name": "Sniatyn",
                "countryCode": "UA"
            },
            {
                "name": "Snyatyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Solotvyn",
                "countryCode": "UA"
            },
            {
                "name": "Spas",
                "countryCode": "UA"
            },
            {
                "name": "Stari Bohorodchany",
                "countryCode": "UA"
            },
            {
                "name": "Stari Kuty",
                "countryCode": "UA"
            },
            {
                "name": "Stetseva",
                "countryCode": "UA"
            },
            {
                "name": "Svarychiv",
                "countryCode": "UA"
            },
            {
                "name": "Tatariv",
                "countryCode": "UA"
            },
            {
                "name": "Tlumach",
                "countryCode": "UA"
            },
            {
                "name": "Tlumats’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Tysmenychany",
                "countryCode": "UA"
            },
            {
                "name": "Tysmenytsya",
                "countryCode": "UA"
            },
            {
                "name": "Tysmenyts’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Uhryniv",
                "countryCode": "UA"
            },
            {
                "name": "Velykyy Klyuchiv",
                "countryCode": "UA"
            },
            {
                "name": "Verkhniy Yasenov",
                "countryCode": "UA"
            },
            {
                "name": "Verkhovyna",
                "countryCode": "UA"
            },
            {
                "name": "Vorokhta",
                "countryCode": "UA"
            },
            {
                "name": "Yabluniv",
                "countryCode": "UA"
            },
            {
                "name": "Yablunytsya",
                "countryCode": "UA"
            },
            {
                "name": "Yamnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Yaremchans’ks Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Yaremche",
                "countryCode": "UA"
            },
            {
                "name": "Zabolotiv",
                "countryCode": "UA"
            },
            {
                "name": "Zahvizdya",
                "countryCode": "UA"
            },
            {
                "name": "Zarichchya",
                "countryCode": "UA"
            },
            {
                "name": "Darnytsia Raion",
                "countryCode": "UA"
            },
            {
                "name": "Desnyans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Dnipro Raion",
                "countryCode": "UA"
            },
            {
                "name": "Holosiiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kotsyubyns’ke",
                "countryCode": "UA"
            },
            {
                "name": "Kyiv",
                "countryCode": "UA"
            },
            {
                "name": "Pechersk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Podil’s’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Prolisky",
                "countryCode": "UA"
            },
            {
                "name": "Shevchenkivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Solomianka Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Svyatoshyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Baryshivka",
                "countryCode": "UA"
            },
            {
                "name": "Baryshivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Bila Tserkva",
                "countryCode": "UA"
            },
            {
                "name": "Bila Tserkva Raion",
                "countryCode": "UA"
            },
            {
                "name": "Bohuslav",
                "countryCode": "UA"
            },
            {
                "name": "Borispol’skiy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Borodianka",
                "countryCode": "UA"
            },
            {
                "name": "Borodyans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Borova",
                "countryCode": "UA"
            },
            {
                "name": "Boryspil’",
                "countryCode": "UA"
            },
            {
                "name": "Boyarka",
                "countryCode": "UA"
            },
            {
                "name": "Brovary",
                "countryCode": "UA"
            },
            {
                "name": "Brovary Raion",
                "countryCode": "UA"
            },
            {
                "name": "Bucha",
                "countryCode": "UA"
            },
            {
                "name": "Chabany",
                "countryCode": "UA"
            },
            {
                "name": "Chernyakhivka",
                "countryCode": "UA"
            },
            {
                "name": "Dymer",
                "countryCode": "UA"
            },
            {
                "name": "Fastiv",
                "countryCode": "UA"
            },
            {
                "name": "Fastiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Hlevakha",
                "countryCode": "UA"
            },
            {
                "name": "Hnidyn",
                "countryCode": "UA"
            },
            {
                "name": "Horenka",
                "countryCode": "UA"
            },
            {
                "name": "Hostomel",
                "countryCode": "UA"
            },
            {
                "name": "Irpin",
                "countryCode": "UA"
            },
            {
                "name": "Irpins’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Ivankiv",
                "countryCode": "UA"
            },
            {
                "name": "Ivankiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kaharlyk",
                "countryCode": "UA"
            },
            {
                "name": "Kaharlyk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kalynove",
                "countryCode": "UA"
            },
            {
                "name": "Karapyshi",
                "countryCode": "UA"
            },
            {
                "name": "Katyuzhanka",
                "countryCode": "UA"
            },
            {
                "name": "Khotiv",
                "countryCode": "UA"
            },
            {
                "name": "Kirove",
                "countryCode": "UA"
            },
            {
                "name": "Klavdiyevo-Tarasove",
                "countryCode": "UA"
            },
            {
                "name": "Knyazhichi",
                "countryCode": "UA"
            },
            {
                "name": "Kodra",
                "countryCode": "UA"
            },
            {
                "name": "Korniyivka",
                "countryCode": "UA"
            },
            {
                "name": "Kozhanka",
                "countryCode": "UA"
            },
            {
                "name": "Krasyatychi",
                "countryCode": "UA"
            },
            {
                "name": "Kriukivschina",
                "countryCode": "UA"
            },
            {
                "name": "Kryva Hora",
                "countryCode": "UA"
            },
            {
                "name": "Kyyevo-Svyatoshyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Liutizh",
                "countryCode": "UA"
            },
            {
                "name": "Makariv",
                "countryCode": "UA"
            },
            {
                "name": "Makariv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Mirovka",
                "countryCode": "UA"
            },
            {
                "name": "Motovylivka",
                "countryCode": "UA"
            },
            {
                "name": "Myronivka",
                "countryCode": "UA"
            },
            {
                "name": "Novi Petrivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Obukhiv",
                "countryCode": "UA"
            },
            {
                "name": "Obukhiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Obukhivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Pereiaslav-Khmelnytskyi",
                "countryCode": "UA"
            },
            {
                "name": "Pereiaslav-Khmelnytskyi Raion",
                "countryCode": "UA"
            },
            {
                "name": "Piskivka",
                "countryCode": "UA"
            },
            {
                "name": "Pohreby",
                "countryCode": "UA"
            },
            {
                "name": "Pukhivka",
                "countryCode": "UA"
            },
            {
                "name": "Rokytne",
                "countryCode": "UA"
            },
            {
                "name": "Rokytne Raion",
                "countryCode": "UA"
            },
            {
                "name": "Roskoshnyy",
                "countryCode": "UA"
            },
            {
                "name": "Rzhyshchiv",
                "countryCode": "UA"
            },
            {
                "name": "Semypolky",
                "countryCode": "UA"
            },
            {
                "name": "Skvyra",
                "countryCode": "UA"
            },
            {
                "name": "Skvyrs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Slavutych",
                "countryCode": "UA"
            },
            {
                "name": "Sofiyivska Borschagivka",
                "countryCode": "UA"
            },
            {
                "name": "Stavyshche",
                "countryCode": "UA"
            },
            {
                "name": "Stayki",
                "countryCode": "UA"
            },
            {
                "name": "Tarashcha",
                "countryCode": "UA"
            },
            {
                "name": "Tetiiv",
                "countryCode": "UA"
            },
            {
                "name": "Trebukhiv",
                "countryCode": "UA"
            },
            {
                "name": "Trypillia",
                "countryCode": "UA"
            },
            {
                "name": "Tsybli",
                "countryCode": "UA"
            },
            {
                "name": "Ukrainka",
                "countryCode": "UA"
            },
            {
                "name": "Uzyn",
                "countryCode": "UA"
            },
            {
                "name": "Vasylkiv",
                "countryCode": "UA"
            },
            {
                "name": "Vasylkiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Dymerka",
                "countryCode": "UA"
            },
            {
                "name": "Volodarka",
                "countryCode": "UA"
            },
            {
                "name": "Voronkov",
                "countryCode": "UA"
            },
            {
                "name": "Vorzel’",
                "countryCode": "UA"
            },
            {
                "name": "Vyshgorodskiy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Vyshhorod",
                "countryCode": "UA"
            },
            {
                "name": "Vyshneve",
                "countryCode": "UA"
            },
            {
                "name": "Yahotyn",
                "countryCode": "UA"
            },
            {
                "name": "Yahotyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Zgurovka",
                "countryCode": "UA"
            },
            {
                "name": "Z·hurivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Adzhamka",
                "countryCode": "UA"
            },
            {
                "name": "Blahovishchenske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Bobrynets",
                "countryCode": "UA"
            },
            {
                "name": "Dmytrivka",
                "countryCode": "UA"
            },
            {
                "name": "Dobrovelychkivka",
                "countryCode": "UA"
            },
            {
                "name": "Dobrovelychkivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Dolyns'ka",
                "countryCode": "UA"
            },
            {
                "name": "Dolyns’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Hayvoron",
                "countryCode": "UA"
            },
            {
                "name": "Kapitanivka",
                "countryCode": "UA"
            },
            {
                "name": "Kirovohrads’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Kompaniyivka",
                "countryCode": "UA"
            },
            {
                "name": "Kropyvnytskyi",
                "countryCode": "UA"
            },
            {
                "name": "Lozuvatka",
                "countryCode": "UA"
            },
            {
                "name": "Lypnyazhka",
                "countryCode": "UA"
            },
            {
                "name": "Mala Vyska",
                "countryCode": "UA"
            },
            {
                "name": "Malovyskivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Molodizhne",
                "countryCode": "UA"
            },
            {
                "name": "Nova Praha",
                "countryCode": "UA"
            },
            {
                "name": "Novhorodka",
                "countryCode": "UA"
            },
            {
                "name": "Novoarkhanhel’s’k",
                "countryCode": "UA"
            },
            {
                "name": "Novomyrhorod",
                "countryCode": "UA"
            },
            {
                "name": "Novoukrayinka",
                "countryCode": "UA"
            },
            {
                "name": "Novyy Starodub",
                "countryCode": "UA"
            },
            {
                "name": "Oleksandrivka",
                "countryCode": "UA"
            },
            {
                "name": "Oleksandriya",
                "countryCode": "UA"
            },
            {
                "name": "Oleksandriys’ke",
                "countryCode": "UA"
            },
            {
                "name": "Onufriyivka",
                "countryCode": "UA"
            },
            {
                "name": "Pantayivka",
                "countryCode": "UA"
            },
            {
                "name": "Pavlysh",
                "countryCode": "UA"
            },
            {
                "name": "Perehonivka",
                "countryCode": "UA"
            },
            {
                "name": "Petrove",
                "countryCode": "UA"
            },
            {
                "name": "Pobugskoye",
                "countryCode": "UA"
            },
            {
                "name": "Pomichna",
                "countryCode": "UA"
            },
            {
                "name": "Pryyutivka",
                "countryCode": "UA"
            },
            {
                "name": "Rivne",
                "countryCode": "UA"
            },
            {
                "name": "Subottsi",
                "countryCode": "UA"
            },
            {
                "name": "Svitlovods’k",
                "countryCode": "UA"
            },
            {
                "name": "Tyshkivka",
                "countryCode": "UA"
            },
            {
                "name": "Ustynivka",
                "countryCode": "UA"
            },
            {
                "name": "Yelyzavethradka",
                "countryCode": "UA"
            },
            {
                "name": "Zavallya",
                "countryCode": "UA"
            },
            {
                "name": "Zlynka",
                "countryCode": "UA"
            },
            {
                "name": "Znomenka",
                "countryCode": "UA"
            },
            {
                "name": "Abrikosovka",
                "countryCode": "UA"
            },
            {
                "name": "Abrikosovo",
                "countryCode": "UA"
            },
            {
                "name": "Aeroflotskiy",
                "countryCode": "UA"
            },
            {
                "name": "Agrarnoye",
                "countryCode": "UA"
            },
            {
                "name": "Akimovka",
                "countryCode": "UA"
            },
            {
                "name": "Aleksandrovka",
                "countryCode": "UA"
            },
            {
                "name": "Alekseyevka",
                "countryCode": "UA"
            },
            {
                "name": "Alupka",
                "countryCode": "UA"
            },
            {
                "name": "Alushta",
                "countryCode": "UA"
            },
            {
                "name": "Amurskoye",
                "countryCode": "UA"
            },
            {
                "name": "Armyansk",
                "countryCode": "UA"
            },
            {
                "name": "Aromatnoye",
                "countryCode": "UA"
            },
            {
                "name": "Azovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Baherove",
                "countryCode": "UA"
            },
            {
                "name": "Bakhchysarai",
                "countryCode": "UA"
            },
            {
                "name": "Bakhchysarai Raion",
                "countryCode": "UA"
            },
            {
                "name": "Batal’noye",
                "countryCode": "UA"
            },
            {
                "name": "Belinskoye",
                "countryCode": "UA"
            },
            {
                "name": "Beloglinka",
                "countryCode": "UA"
            },
            {
                "name": "Belogorskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Beregovoye",
                "countryCode": "UA"
            },
            {
                "name": "Berezovka",
                "countryCode": "UA"
            },
            {
                "name": "Bilohirsk",
                "countryCode": "UA"
            },
            {
                "name": "Blizhneye",
                "countryCode": "UA"
            },
            {
                "name": "Bogatoye",
                "countryCode": "UA"
            },
            {
                "name": "Botanicheskoye",
                "countryCode": "UA"
            },
            {
                "name": "Bratskoye",
                "countryCode": "UA"
            },
            {
                "name": "Chapayevka",
                "countryCode": "UA"
            },
            {
                "name": "Chaykino",
                "countryCode": "UA"
            },
            {
                "name": "Chelyadinovo",
                "countryCode": "UA"
            },
            {
                "name": "Chernomorskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Chernomorskoye",
                "countryCode": "UA"
            },
            {
                "name": "Chernopolye",
                "countryCode": "UA"
            },
            {
                "name": "Chernovo",
                "countryCode": "UA"
            },
            {
                "name": "Chernozemnoye",
                "countryCode": "UA"
            },
            {
                "name": "Chernyshevo",
                "countryCode": "UA"
            },
            {
                "name": "Chervonoye",
                "countryCode": "UA"
            },
            {
                "name": "Chisten’koye",
                "countryCode": "UA"
            },
            {
                "name": "Chistopolye",
                "countryCode": "UA"
            },
            {
                "name": "Chkalovo",
                "countryCode": "UA"
            },
            {
                "name": "Dachnoye",
                "countryCode": "UA"
            },
            {
                "name": "Dalekoye",
                "countryCode": "UA"
            },
            {
                "name": "Dmitrovka",
                "countryCode": "UA"
            },
            {
                "name": "Dneprovka",
                "countryCode": "UA"
            },
            {
                "name": "Dobroye",
                "countryCode": "UA"
            },
            {
                "name": "Dobrushino",
                "countryCode": "UA"
            },
            {
                "name": "Dolinnoye",
                "countryCode": "UA"
            },
            {
                "name": "Donskoye",
                "countryCode": "UA"
            },
            {
                "name": "Drofino",
                "countryCode": "UA"
            },
            {
                "name": "Dubki",
                "countryCode": "UA"
            },
            {
                "name": "Dzhankoy",
                "countryCode": "UA"
            },
            {
                "name": "Dzhankoyskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Feodosiya",
                "countryCode": "UA"
            },
            {
                "name": "Filatovka",
                "countryCode": "UA"
            },
            {
                "name": "Fontany",
                "countryCode": "UA"
            },
            {
                "name": "Foros",
                "countryCode": "UA"
            },
            {
                "name": "Frunze",
                "countryCode": "UA"
            },
            {
                "name": "Gaspra",
                "countryCode": "UA"
            },
            {
                "name": "Geroyskoye",
                "countryCode": "UA"
            },
            {
                "name": "Glazovka",
                "countryCode": "UA"
            },
            {
                "name": "Golubinka",
                "countryCode": "UA"
            },
            {
                "name": "Gornostayevka",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Alushta",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Armyansk",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Dzhankoy",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Feodosiya",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Krasnoperekopsk",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Saki",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Simferopol",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Sudak",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Yalta",
                "countryCode": "UA"
            },
            {
                "name": "Gorodskoy okrug Yevpatoriya",
                "countryCode": "UA"
            },
            {
                "name": "Gresovskiy",
                "countryCode": "UA"
            },
            {
                "name": "Grishino",
                "countryCode": "UA"
            },
            {
                "name": "Grushevka",
                "countryCode": "UA"
            },
            {
                "name": "Gurzuf",
                "countryCode": "UA"
            },
            {
                "name": "Hvardiiske",
                "countryCode": "UA"
            },
            {
                "name": "Ilychyovo",
                "countryCode": "UA"
            },
            {
                "name": "Ilyichevo",
                "countryCode": "UA"
            },
            {
                "name": "Ilyinka",
                "countryCode": "UA"
            },
            {
                "name": "Ishun’",
                "countryCode": "UA"
            },
            {
                "name": "Ivanovka",
                "countryCode": "UA"
            },
            {
                "name": "Izobil’noye",
                "countryCode": "UA"
            },
            {
                "name": "Izumrudnoye",
                "countryCode": "UA"
            },
            {
                "name": "Izyumovka",
                "countryCode": "UA"
            },
            {
                "name": "Kalinino",
                "countryCode": "UA"
            },
            {
                "name": "Kalinovka",
                "countryCode": "UA"
            },
            {
                "name": "Kamenolomnya",
                "countryCode": "UA"
            },
            {
                "name": "Kashtanovoye",
                "countryCode": "UA"
            },
            {
                "name": "Kashtany",
                "countryCode": "UA"
            },
            {
                "name": "Kerch",
                "countryCode": "UA"
            },
            {
                "name": "Kerchens'ka Mis'ka Rada",
                "countryCode": "UA"
            },
            {
                "name": "Kholmovka",
                "countryCode": "UA"
            },
            {
                "name": "Kirovo",
                "countryCode": "UA"
            },
            {
                "name": "Kirovske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kirovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Klepinino",
                "countryCode": "UA"
            },
            {
                "name": "Koktebel",
                "countryCode": "UA"
            },
            {
                "name": "Kolodeznoye",
                "countryCode": "UA"
            },
            {
                "name": "Koloski",
                "countryCode": "UA"
            },
            {
                "name": "Kol’chugino",
                "countryCode": "UA"
            },
            {
                "name": "Kol’tsovo",
                "countryCode": "UA"
            },
            {
                "name": "Komsomol’skoye",
                "countryCode": "UA"
            },
            {
                "name": "Kondratyevo",
                "countryCode": "UA"
            },
            {
                "name": "Konstantinovka",
                "countryCode": "UA"
            },
            {
                "name": "Koreiz",
                "countryCode": "UA"
            },
            {
                "name": "Kormovoye",
                "countryCode": "UA"
            },
            {
                "name": "Kostochkovka",
                "countryCode": "UA"
            },
            {
                "name": "Kotel’nikovo",
                "countryCode": "UA"
            },
            {
                "name": "Kovyl’noye",
                "countryCode": "UA"
            },
            {
                "name": "Krasna Zor'ka",
                "countryCode": "UA"
            },
            {
                "name": "Krasnaya Polyana",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoarmeyskoye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoflotskoye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnogorka",
                "countryCode": "UA"
            },
            {
                "name": "Krasnogvardeyskoye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnohvardiiske Raion",
                "countryCode": "UA"
            },
            {
                "name": "Krasnokamenka",
                "countryCode": "UA"
            },
            {
                "name": "Krasnolesye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoperekopsk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoperekops’k",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoyarskoye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoye",
                "countryCode": "UA"
            },
            {
                "name": "Krasnoznamenka",
                "countryCode": "UA"
            },
            {
                "name": "Krasnyi Mak",
                "countryCode": "UA"
            },
            {
                "name": "Krayneye",
                "countryCode": "UA"
            },
            {
                "name": "Krest’yanovka",
                "countryCode": "UA"
            },
            {
                "name": "Krinichnoye",
                "countryCode": "UA"
            },
            {
                "name": "Krymka",
                "countryCode": "UA"
            },
            {
                "name": "Krymskaya Roza",
                "countryCode": "UA"
            },
            {
                "name": "Krymskoye",
                "countryCode": "UA"
            },
            {
                "name": "Kuibyshevo",
                "countryCode": "UA"
            },
            {
                "name": "Kukushkino",
                "countryCode": "UA"
            },
            {
                "name": "Kurskoye",
                "countryCode": "UA"
            },
            {
                "name": "Lekarstvennoye",
                "countryCode": "UA"
            },
            {
                "name": "Lenine Raion",
                "countryCode": "UA"
            },
            {
                "name": "Lenino",
                "countryCode": "UA"
            },
            {
                "name": "Leninskoye",
                "countryCode": "UA"
            },
            {
                "name": "Lesnovka",
                "countryCode": "UA"
            },
            {
                "name": "Levadki",
                "countryCode": "UA"
            },
            {
                "name": "Listvennoye",
                "countryCode": "UA"
            },
            {
                "name": "Litvinenkovo",
                "countryCode": "UA"
            },
            {
                "name": "Livadia",
                "countryCode": "UA"
            },
            {
                "name": "Lobanovo",
                "countryCode": "UA"
            },
            {
                "name": "Lozovoye",
                "countryCode": "UA"
            },
            {
                "name": "Luchistoye",
                "countryCode": "UA"
            },
            {
                "name": "Luganskoye",
                "countryCode": "UA"
            },
            {
                "name": "Lugovoye",
                "countryCode": "UA"
            },
            {
                "name": "L’govskoye",
                "countryCode": "UA"
            },
            {
                "name": "Magazinka",
                "countryCode": "UA"
            },
            {
                "name": "Malen’koye",
                "countryCode": "UA"
            },
            {
                "name": "Malorechenskoye",
                "countryCode": "UA"
            },
            {
                "name": "Maly Mayak",
                "countryCode": "UA"
            },
            {
                "name": "Marfovka",
                "countryCode": "UA"
            },
            {
                "name": "Maryevka",
                "countryCode": "UA"
            },
            {
                "name": "Mar’yanovka",
                "countryCode": "UA"
            },
            {
                "name": "Maslovo",
                "countryCode": "UA"
            },
            {
                "name": "Massandra",
                "countryCode": "UA"
            },
            {
                "name": "Mayskoye",
                "countryCode": "UA"
            },
            {
                "name": "Mazanka",
                "countryCode": "UA"
            },
            {
                "name": "Medvedevka",
                "countryCode": "UA"
            },
            {
                "name": "Medvedevo",
                "countryCode": "UA"
            },
            {
                "name": "Mel’nichnoye",
                "countryCode": "UA"
            },
            {
                "name": "Mezhvodnoye",
                "countryCode": "UA"
            },
            {
                "name": "Michurinskoye",
                "countryCode": "UA"
            },
            {
                "name": "Mikhaylovka",
                "countryCode": "UA"
            },
            {
                "name": "Mirnovka",
                "countryCode": "UA"
            },
            {
                "name": "Mirnoye",
                "countryCode": "UA"
            },
            {
                "name": "Mirny",
                "countryCode": "UA"
            },
            {
                "name": "Mitrofanovka",
                "countryCode": "UA"
            },
            {
                "name": "Mityayevo",
                "countryCode": "UA"
            },
            {
                "name": "Molochnoye",
                "countryCode": "UA"
            },
            {
                "name": "Molodyozhnoye",
                "countryCode": "UA"
            },
            {
                "name": "Morskoye",
                "countryCode": "UA"
            },
            {
                "name": "Muromskoye",
                "countryCode": "UA"
            },
            {
                "name": "Muskatnoye",
                "countryCode": "UA"
            },
            {
                "name": "Mysovoe",
                "countryCode": "UA"
            },
            {
                "name": "Nasypnoe",
                "countryCode": "UA"
            },
            {
                "name": "Naydenovka",
                "countryCode": "UA"
            },
            {
                "name": "Nekrasovka",
                "countryCode": "UA"
            },
            {
                "name": "Nekrasovo",
                "countryCode": "UA"
            },
            {
                "name": "Nikita",
                "countryCode": "UA"
            },
            {
                "name": "Nikolayevka",
                "countryCode": "UA"
            },
            {
                "name": "Nizhnegorskiy",
                "countryCode": "UA"
            },
            {
                "name": "Nizhnegorskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Novoandreyevka",
                "countryCode": "UA"
            },
            {
                "name": "Novoestoniya",
                "countryCode": "UA"
            },
            {
                "name": "Novofedorovka",
                "countryCode": "UA"
            },
            {
                "name": "Novogrigoryevka",
                "countryCode": "UA"
            },
            {
                "name": "Novoivanovka",
                "countryCode": "UA"
            },
            {
                "name": "Novokrymskoye",
                "countryCode": "UA"
            },
            {
                "name": "Novonikolayevka",
                "countryCode": "UA"
            },
            {
                "name": "Novoozyornoye",
                "countryCode": "UA"
            },
            {
                "name": "Novopavlovka",
                "countryCode": "UA"
            },
            {
                "name": "Novopokrovka",
                "countryCode": "UA"
            },
            {
                "name": "Novoselovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Novosel’skoye",
                "countryCode": "UA"
            },
            {
                "name": "Novostepnoye",
                "countryCode": "UA"
            },
            {
                "name": "Novosyolovka",
                "countryCode": "UA"
            },
            {
                "name": "Novozhilovka",
                "countryCode": "UA"
            },
            {
                "name": "Novy Svet",
                "countryCode": "UA"
            },
            {
                "name": "Okhotnikovo",
                "countryCode": "UA"
            },
            {
                "name": "Okhotskoye",
                "countryCode": "UA"
            },
            {
                "name": "Oktyabr'skoye",
                "countryCode": "UA"
            },
            {
                "name": "Oktyabr’skoye",
                "countryCode": "UA"
            },
            {
                "name": "Okunevka",
                "countryCode": "UA"
            },
            {
                "name": "Olenevka",
                "countryCode": "UA"
            },
            {
                "name": "Ordzhonikidze",
                "countryCode": "UA"
            },
            {
                "name": "Orekhovo",
                "countryCode": "UA"
            },
            {
                "name": "Orlovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Ostanino",
                "countryCode": "UA"
            },
            {
                "name": "Ostrovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Pakharevka",
                "countryCode": "UA"
            },
            {
                "name": "Partenit",
                "countryCode": "UA"
            },
            {
                "name": "Partizanskoye",
                "countryCode": "UA"
            },
            {
                "name": "Partizany",
                "countryCode": "UA"
            },
            {
                "name": "Pereval'noye",
                "countryCode": "UA"
            },
            {
                "name": "Perovo",
                "countryCode": "UA"
            },
            {
                "name": "Pervomayskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Pervomayskoye",
                "countryCode": "UA"
            },
            {
                "name": "Peschanoye",
                "countryCode": "UA"
            },
            {
                "name": "Petrovka",
                "countryCode": "UA"
            },
            {
                "name": "Pionerskoye",
                "countryCode": "UA"
            },
            {
                "name": "Plodovoye",
                "countryCode": "UA"
            },
            {
                "name": "Pobednoye",
                "countryCode": "UA"
            },
            {
                "name": "Pochetnoye",
                "countryCode": "UA"
            },
            {
                "name": "Poltavka",
                "countryCode": "UA"
            },
            {
                "name": "Poshtove",
                "countryCode": "UA"
            },
            {
                "name": "Pozharskoye",
                "countryCode": "UA"
            },
            {
                "name": "Pravda",
                "countryCode": "UA"
            },
            {
                "name": "Primorskiy",
                "countryCode": "UA"
            },
            {
                "name": "Priozyornoye",
                "countryCode": "UA"
            },
            {
                "name": "Privetnoye",
                "countryCode": "UA"
            },
            {
                "name": "Prostornoye",
                "countryCode": "UA"
            },
            {
                "name": "Prudovoye",
                "countryCode": "UA"
            },
            {
                "name": "Prudy",
                "countryCode": "UA"
            },
            {
                "name": "Pshenichnoye",
                "countryCode": "UA"
            },
            {
                "name": "Pushkino",
                "countryCode": "UA"
            },
            {
                "name": "Pyatikhatka",
                "countryCode": "UA"
            },
            {
                "name": "Razdol’nenskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Razdol’noye",
                "countryCode": "UA"
            },
            {
                "name": "Rodnikovo",
                "countryCode": "UA"
            },
            {
                "name": "Romashkino",
                "countryCode": "UA"
            },
            {
                "name": "Roshchino",
                "countryCode": "UA"
            },
            {
                "name": "Roskoshnoye",
                "countryCode": "UA"
            },
            {
                "name": "Rovnoye",
                "countryCode": "UA"
            },
            {
                "name": "Ruch’i",
                "countryCode": "UA"
            },
            {
                "name": "Rusakovka",
                "countryCode": "UA"
            },
            {
                "name": "Rybach'e",
                "countryCode": "UA"
            },
            {
                "name": "Sadovoye",
                "countryCode": "UA"
            },
            {
                "name": "Saki",
                "countryCode": "UA"
            },
            {
                "name": "Sakskiy rayon",
                "countryCode": "UA"
            },
            {
                "name": "Sary-Bash",
                "countryCode": "UA"
            },
            {
                "name": "Semisotka",
                "countryCode": "UA"
            },
            {
                "name": "Senokosnoye",
                "countryCode": "UA"
            },
            {
                "name": "Serebryanka",
                "countryCode": "UA"
            },
            {
                "name": "Shchebetovka",
                "countryCode": "UA"
            },
            {
                "name": "Shchyolkino",
                "countryCode": "UA"
            },
            {
                "name": "Shelkovichnoye",
                "countryCode": "UA"
            },
            {
                "name": "Shirokoye",
                "countryCode": "UA"
            },
            {
                "name": "Shkol’noye",
                "countryCode": "UA"
            },
            {
                "name": "Shtormovoye",
                "countryCode": "UA"
            },
            {
                "name": "Simeiz",
                "countryCode": "UA"
            },
            {
                "name": "Simferopol",
                "countryCode": "UA"
            },
            {
                "name": "Simferopol Raion",
                "countryCode": "UA"
            },
            {
                "name": "Sinitsyno",
                "countryCode": "UA"
            },
            {
                "name": "Sizovka",
                "countryCode": "UA"
            },
            {
                "name": "Skalistoye",
                "countryCode": "UA"
            },
            {
                "name": "Skvortsovo",
                "countryCode": "UA"
            },
            {
                "name": "Slavnoye",
                "countryCode": "UA"
            },
            {
                "name": "Slavyanskoye",
                "countryCode": "UA"
            },
            {
                "name": "Sofiivka",
                "countryCode": "UA"
            },
            {
                "name": "Sokolinoye",
                "countryCode": "UA"
            },
            {
                "name": "Solnechnaya Dolina",
                "countryCode": "UA"
            },
            {
                "name": "Solnechnogorskoye",
                "countryCode": "UA"
            },
            {
                "name": "Solnechnoye",
                "countryCode": "UA"
            },
            {
                "name": "Sovetskiy",
                "countryCode": "UA"
            },
            {
                "name": "Sovietskyi Raion",
                "countryCode": "UA"
            },
            {
                "name": "Sovkhoznoye",
                "countryCode": "UA"
            },
            {
                "name": "Stakhanovka",
                "countryCode": "UA"
            },
            {
                "name": "Stal’noye",
                "countryCode": "UA"
            },
            {
                "name": "Stary Krym",
                "countryCode": "UA"
            },
            {
                "name": "Stepnoye",
                "countryCode": "UA"
            },
            {
                "name": "Stolbovoye",
                "countryCode": "UA"
            },
            {
                "name": "Strogonovka",
                "countryCode": "UA"
            },
            {
                "name": "Sudak",
                "countryCode": "UA"
            },
            {
                "name": "Susanino",
                "countryCode": "UA"
            },
            {
                "name": "Suvorovo",
                "countryCode": "UA"
            },
            {
                "name": "Suvorovskoye",
                "countryCode": "UA"
            },
            {
                "name": "Svetloye",
                "countryCode": "UA"
            },
            {
                "name": "Tabachnoye",
                "countryCode": "UA"
            },
            {
                "name": "Tankovoye",
                "countryCode": "UA"
            },
            {
                "name": "Tenistoye",
                "countryCode": "UA"
            },
            {
                "name": "Teplovka",
                "countryCode": "UA"
            },
            {
                "name": "Tokarevo",
                "countryCode": "UA"
            },
            {
                "name": "Trudovoye",
                "countryCode": "UA"
            },
            {
                "name": "Tselinnoye",
                "countryCode": "UA"
            },
            {
                "name": "Tsvetochnoye",
                "countryCode": "UA"
            },
            {
                "name": "Turgenevka",
                "countryCode": "UA"
            },
            {
                "name": "Uglovoye",
                "countryCode": "UA"
            },
            {
                "name": "Ukrainka",
                "countryCode": "UA"
            },
            {
                "name": "Ukromnoye",
                "countryCode": "UA"
            },
            {
                "name": "Urozhaynoye",
                "countryCode": "UA"
            },
            {
                "name": "Uvarovka",
                "countryCode": "UA"
            },
            {
                "name": "Uvarovo",
                "countryCode": "UA"
            },
            {
                "name": "Uyutnoye",
                "countryCode": "UA"
            },
            {
                "name": "Vasilyevka",
                "countryCode": "UA"
            },
            {
                "name": "Veresayevo",
                "countryCode": "UA"
            },
            {
                "name": "Verkhorechye",
                "countryCode": "UA"
            },
            {
                "name": "Vesele",
                "countryCode": "UA"
            },
            {
                "name": "Veselovka",
                "countryCode": "UA"
            },
            {
                "name": "Vilino",
                "countryCode": "UA"
            },
            {
                "name": "Vinnitskoye",
                "countryCode": "UA"
            },
            {
                "name": "Vinogradnoye",
                "countryCode": "UA"
            },
            {
                "name": "Vinogradovo",
                "countryCode": "UA"
            },
            {
                "name": "Vishennoye",
                "countryCode": "UA"
            },
            {
                "name": "Vishnyovka",
                "countryCode": "UA"
            },
            {
                "name": "Vladimirovka",
                "countryCode": "UA"
            },
            {
                "name": "Vladislavovka",
                "countryCode": "UA"
            },
            {
                "name": "Voinka",
                "countryCode": "UA"
            },
            {
                "name": "Vol’noye",
                "countryCode": "UA"
            },
            {
                "name": "Vorobyovo",
                "countryCode": "UA"
            },
            {
                "name": "Voskhod",
                "countryCode": "UA"
            },
            {
                "name": "Voykovo",
                "countryCode": "UA"
            },
            {
                "name": "Yalta",
                "countryCode": "UA"
            },
            {
                "name": "Yantarnoye",
                "countryCode": "UA"
            },
            {
                "name": "Yarkoye",
                "countryCode": "UA"
            },
            {
                "name": "Yarkoye Pole",
                "countryCode": "UA"
            },
            {
                "name": "Yasnopolyanskoye",
                "countryCode": "UA"
            },
            {
                "name": "Yemelyanovka",
                "countryCode": "UA"
            },
            {
                "name": "Yermakovo",
                "countryCode": "UA"
            },
            {
                "name": "Yevpatoriya",
                "countryCode": "UA"
            },
            {
                "name": "Zalesye",
                "countryCode": "UA"
            },
            {
                "name": "Zaozyornoye",
                "countryCode": "UA"
            },
            {
                "name": "Zarechnoye",
                "countryCode": "UA"
            },
            {
                "name": "Zavet-Leninskiy",
                "countryCode": "UA"
            },
            {
                "name": "Zavetnoye",
                "countryCode": "UA"
            },
            {
                "name": "Zelenogorskoye",
                "countryCode": "UA"
            },
            {
                "name": "Zelyonoye",
                "countryCode": "UA"
            },
            {
                "name": "Zemlyanichnoye",
                "countryCode": "UA"
            },
            {
                "name": "Zernovoye",
                "countryCode": "UA"
            },
            {
                "name": "Zheleznodorozhnoye",
                "countryCode": "UA"
            },
            {
                "name": "Zhelyabovka",
                "countryCode": "UA"
            },
            {
                "name": "Zhemchuzhina",
                "countryCode": "UA"
            },
            {
                "name": "Zhuravki",
                "countryCode": "UA"
            },
            {
                "name": "Zhuravli",
                "countryCode": "UA"
            },
            {
                "name": "Zhuravlyovka",
                "countryCode": "UA"
            },
            {
                "name": "Zimino",
                "countryCode": "UA"
            },
            {
                "name": "Zolotoye Pole",
                "countryCode": "UA"
            },
            {
                "name": "Zorkino",
                "countryCode": "UA"
            },
            {
                "name": "Zuya",
                "countryCode": "UA"
            },
            {
                "name": "Zybiny",
                "countryCode": "UA"
            },
            {
                "name": "Belz",
                "countryCode": "UA"
            },
            {
                "name": "Bibrka",
                "countryCode": "UA"
            },
            {
                "name": "Boryslav",
                "countryCode": "UA"
            },
            {
                "name": "Brody",
                "countryCode": "UA"
            },
            {
                "name": "Bryukhovychi",
                "countryCode": "UA"
            },
            {
                "name": "Chervonohrad",
                "countryCode": "UA"
            },
            {
                "name": "Demnya",
                "countryCode": "UA"
            },
            {
                "name": "Dobromyl’",
                "countryCode": "UA"
            },
            {
                "name": "Drohobych",
                "countryCode": "UA"
            },
            {
                "name": "Dublyany",
                "countryCode": "UA"
            },
            {
                "name": "Duliby",
                "countryCode": "UA"
            },
            {
                "name": "Dzvinogrud",
                "countryCode": "UA"
            },
            {
                "name": "Hlyns'k",
                "countryCode": "UA"
            },
            {
                "name": "Horodok",
                "countryCode": "UA"
            },
            {
                "name": "Ivano-Frankove",
                "countryCode": "UA"
            },
            {
                "name": "Khodoriv",
                "countryCode": "UA"
            },
            {
                "name": "Khyriv",
                "countryCode": "UA"
            },
            {
                "name": "Komarno",
                "countryCode": "UA"
            },
            {
                "name": "Krasne",
                "countryCode": "UA"
            },
            {
                "name": "Kulykiv",
                "countryCode": "UA"
            },
            {
                "name": "Lopatyn",
                "countryCode": "UA"
            },
            {
                "name": "Lviv",
                "countryCode": "UA"
            },
            {
                "name": "Medenychi",
                "countryCode": "UA"
            },
            {
                "name": "Morshyn",
                "countryCode": "UA"
            },
            {
                "name": "Mostys'ka",
                "countryCode": "UA"
            },
            {
                "name": "Murovane",
                "countryCode": "UA"
            },
            {
                "name": "Mykolaiv",
                "countryCode": "UA"
            },
            {
                "name": "Navaria",
                "countryCode": "UA"
            },
            {
                "name": "Novoyavorivs'k",
                "countryCode": "UA"
            },
            {
                "name": "Novyi Yarychiv",
                "countryCode": "UA"
            },
            {
                "name": "Obroshyne",
                "countryCode": "UA"
            },
            {
                "name": "Olesko",
                "countryCode": "UA"
            },
            {
                "name": "Peremyshlyany",
                "countryCode": "UA"
            },
            {
                "name": "Pidbuzh",
                "countryCode": "UA"
            },
            {
                "name": "Pidhirtsi",
                "countryCode": "UA"
            },
            {
                "name": "Pidkamin’",
                "countryCode": "UA"
            },
            {
                "name": "Pnikut",
                "countryCode": "UA"
            },
            {
                "name": "Pomoriany",
                "countryCode": "UA"
            },
            {
                "name": "Potelych",
                "countryCode": "UA"
            },
            {
                "name": "Pustomyty",
                "countryCode": "UA"
            },
            {
                "name": "Ralivka",
                "countryCode": "UA"
            },
            {
                "name": "Rava-Rus’ka",
                "countryCode": "UA"
            },
            {
                "name": "Richky",
                "countryCode": "UA"
            },
            {
                "name": "Rodatychi",
                "countryCode": "UA"
            },
            {
                "name": "Rozdil",
                "countryCode": "UA"
            },
            {
                "name": "Rozvadiv",
                "countryCode": "UA"
            },
            {
                "name": "Rudky",
                "countryCode": "UA"
            },
            {
                "name": "Rudne",
                "countryCode": "UA"
            },
            {
                "name": "Rykhtychi",
                "countryCode": "UA"
            },
            {
                "name": "Sambir",
                "countryCode": "UA"
            },
            {
                "name": "Shchyrets",
                "countryCode": "UA"
            },
            {
                "name": "Shklo",
                "countryCode": "UA"
            },
            {
                "name": "Silets’",
                "countryCode": "UA"
            },
            {
                "name": "Skelivka",
                "countryCode": "UA"
            },
            {
                "name": "Skhidnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Skole",
                "countryCode": "UA"
            },
            {
                "name": "Slavske",
                "countryCode": "UA"
            },
            {
                "name": "Sokal’",
                "countryCode": "UA"
            },
            {
                "name": "Solonka",
                "countryCode": "UA"
            },
            {
                "name": "Staroye Selo",
                "countryCode": "UA"
            },
            {
                "name": "Starychi",
                "countryCode": "UA"
            },
            {
                "name": "Staryy Dobrotvir",
                "countryCode": "UA"
            },
            {
                "name": "Staryy Sambir",
                "countryCode": "UA"
            },
            {
                "name": "Stebnyk",
                "countryCode": "UA"
            },
            {
                "name": "Stryi",
                "countryCode": "UA"
            },
            {
                "name": "Sudova Vyshnya",
                "countryCode": "UA"
            },
            {
                "name": "Sukhovolya",
                "countryCode": "UA"
            },
            {
                "name": "Truskavets’",
                "countryCode": "UA"
            },
            {
                "name": "Tukhlia",
                "countryCode": "UA"
            },
            {
                "name": "Turka",
                "countryCode": "UA"
            },
            {
                "name": "Uhniv",
                "countryCode": "UA"
            },
            {
                "name": "Ulychne",
                "countryCode": "UA"
            },
            {
                "name": "Verblyany",
                "countryCode": "UA"
            },
            {
                "name": "Verchnia Rozhanka",
                "countryCode": "UA"
            },
            {
                "name": "Verkhnie Synevydne",
                "countryCode": "UA"
            },
            {
                "name": "Verkhnyaya Belka",
                "countryCode": "UA"
            },
            {
                "name": "Voyutychi",
                "countryCode": "UA"
            },
            {
                "name": "Yavoriv",
                "countryCode": "UA"
            },
            {
                "name": "Zhovkva",
                "countryCode": "UA"
            },
            {
                "name": "Zhydachiv",
                "countryCode": "UA"
            },
            {
                "name": "Zolochiv",
                "countryCode": "UA"
            },
            {
                "name": "Arbuzynka",
                "countryCode": "UA"
            },
            {
                "name": "Bashtanka",
                "countryCode": "UA"
            },
            {
                "name": "Berezanka",
                "countryCode": "UA"
            },
            {
                "name": "Bereznehuvate",
                "countryCode": "UA"
            },
            {
                "name": "Domanivka",
                "countryCode": "UA"
            },
            {
                "name": "Kazanka",
                "countryCode": "UA"
            },
            {
                "name": "Kobleve",
                "countryCode": "UA"
            },
            {
                "name": "Kostyantynivka",
                "countryCode": "UA"
            },
            {
                "name": "Kryve Ozero",
                "countryCode": "UA"
            },
            {
                "name": "Lysa Hora",
                "countryCode": "UA"
            },
            {
                "name": "Myhiya",
                "countryCode": "UA"
            },
            {
                "name": "Mykolayiv",
                "countryCode": "UA"
            },
            {
                "name": "Nova Odesa",
                "countryCode": "UA"
            },
            {
                "name": "Novyy Buh",
                "countryCode": "UA"
            },
            {
                "name": "Ochakiv",
                "countryCode": "UA"
            },
            {
                "name": "Ol'shanskoye",
                "countryCode": "UA"
            },
            {
                "name": "Oleksandrivka",
                "countryCode": "UA"
            },
            {
                "name": "Parutyne",
                "countryCode": "UA"
            },
            {
                "name": "Pervomays’k",
                "countryCode": "UA"
            },
            {
                "name": "Pidhorodna",
                "countryCode": "UA"
            },
            {
                "name": "Rybakivka",
                "countryCode": "UA"
            },
            {
                "name": "Shevchenkove",
                "countryCode": "UA"
            },
            {
                "name": "Snihurivka",
                "countryCode": "UA"
            },
            {
                "name": "Veselynove",
                "countryCode": "UA"
            },
            {
                "name": "Voznesensk",
                "countryCode": "UA"
            },
            {
                "name": "Vradiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Yuzhnoukrains'k",
                "countryCode": "UA"
            },
            {
                "name": "Artsyz",
                "countryCode": "UA"
            },
            {
                "name": "Balta",
                "countryCode": "UA"
            },
            {
                "name": "Balts’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Berezivka",
                "countryCode": "UA"
            },
            {
                "name": "Berezyne",
                "countryCode": "UA"
            },
            {
                "name": "Bilhorod-Dnistrovskyi",
                "countryCode": "UA"
            },
            {
                "name": "Bilyayivka",
                "countryCode": "UA"
            },
            {
                "name": "Bolhrad",
                "countryCode": "UA"
            },
            {
                "name": "Borodino",
                "countryCode": "UA"
            },
            {
                "name": "Broska",
                "countryCode": "UA"
            },
            {
                "name": "Chornomors’k",
                "countryCode": "UA"
            },
            {
                "name": "Dmytrivka",
                "countryCode": "UA"
            },
            {
                "name": "Fontanka",
                "countryCode": "UA"
            },
            {
                "name": "Horodnye",
                "countryCode": "UA"
            },
            {
                "name": "Ivanivka",
                "countryCode": "UA"
            },
            {
                "name": "Izmayil",
                "countryCode": "UA"
            },
            {
                "name": "Kalcheva",
                "countryCode": "UA"
            },
            {
                "name": "Kamyshevka Vtoraya",
                "countryCode": "UA"
            },
            {
                "name": "Karolino-Buhaz",
                "countryCode": "UA"
            },
            {
                "name": "Kiliya",
                "countryCode": "UA"
            },
            {
                "name": "Kodyma",
                "countryCode": "UA"
            },
            {
                "name": "Kodyms’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Krasnosilka",
                "countryCode": "UA"
            },
            {
                "name": "Kremidivka",
                "countryCode": "UA"
            },
            {
                "name": "Kryzhanivka",
                "countryCode": "UA"
            },
            {
                "name": "Kuchurhan",
                "countryCode": "UA"
            },
            {
                "name": "Kulevcha",
                "countryCode": "UA"
            },
            {
                "name": "Kurisove",
                "countryCode": "UA"
            },
            {
                "name": "Lymanske",
                "countryCode": "UA"
            },
            {
                "name": "Lyubashivka",
                "countryCode": "UA"
            },
            {
                "name": "Mayaky",
                "countryCode": "UA"
            },
            {
                "name": "Mykolayivka",
                "countryCode": "UA"
            },
            {
                "name": "Mykolayivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Novi Troyany",
                "countryCode": "UA"
            },
            {
                "name": "Odesa",
                "countryCode": "UA"
            },
            {
                "name": "Okny",
                "countryCode": "UA"
            },
            {
                "name": "Oleksandrivka",
                "countryCode": "UA"
            },
            {
                "name": "Ovidiopol",
                "countryCode": "UA"
            },
            {
                "name": "Ozerne",
                "countryCode": "UA"
            },
            {
                "name": "Peschana",
                "countryCode": "UA"
            },
            {
                "name": "Plakhtiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Podilsk Raion",
                "countryCode": "UA"
            },
            {
                "name": "Podil’s’k",
                "countryCode": "UA"
            },
            {
                "name": "Reni",
                "countryCode": "UA"
            },
            {
                "name": "Rozdil’na",
                "countryCode": "UA"
            },
            {
                "name": "Salhany",
                "countryCode": "UA"
            },
            {
                "name": "Sarata",
                "countryCode": "UA"
            },
            {
                "name": "Savran",
                "countryCode": "UA"
            },
            {
                "name": "Serhiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Serpneve",
                "countryCode": "UA"
            },
            {
                "name": "Shabo",
                "countryCode": "UA"
            },
            {
                "name": "Shevchenkove",
                "countryCode": "UA"
            },
            {
                "name": "Shyryayeve",
                "countryCode": "UA"
            },
            {
                "name": "Slobidka",
                "countryCode": "UA"
            },
            {
                "name": "Starokozache",
                "countryCode": "UA"
            },
            {
                "name": "Stepanivka",
                "countryCode": "UA"
            },
            {
                "name": "Stepove",
                "countryCode": "UA"
            },
            {
                "name": "Tarutyne",
                "countryCode": "UA"
            },
            {
                "name": "Tatarbunary",
                "countryCode": "UA"
            },
            {
                "name": "Tayirove",
                "countryCode": "UA"
            },
            {
                "name": "Teplodar",
                "countryCode": "UA"
            },
            {
                "name": "Usatove",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Mykhaylivka",
                "countryCode": "UA"
            },
            {
                "name": "Velykoploske",
                "countryCode": "UA"
            },
            {
                "name": "Vylkove",
                "countryCode": "UA"
            },
            {
                "name": "Vynohradivka",
                "countryCode": "UA"
            },
            {
                "name": "Yuzhne",
                "countryCode": "UA"
            },
            {
                "name": "Zagnitkiv",
                "countryCode": "UA"
            },
            {
                "name": "Zakharivka",
                "countryCode": "UA"
            },
            {
                "name": "Zaliznychne",
                "countryCode": "UA"
            },
            {
                "name": "Zatoka",
                "countryCode": "UA"
            },
            {
                "name": "Zatyshshya",
                "countryCode": "UA"
            },
            {
                "name": "Zorya",
                "countryCode": "UA"
            },
            {
                "name": "Abramok",
                "countryCode": "UA"
            },
            {
                "name": "Adamivka",
                "countryCode": "UA"
            },
            {
                "name": "Adamove",
                "countryCode": "UA"
            },
            {
                "name": "Agativka",
                "countryCode": "UA"
            },
            {
                "name": "Andriyivka",
                "countryCode": "UA"
            },
            {
                "name": "Andrushivka",
                "countryCode": "UA"
            },
            {
                "name": "Autozavodskoy Raion",
                "countryCode": "UA"
            },
            {
                "name": "Avratin",
                "countryCode": "UA"
            },
            {
                "name": "Bairak",
                "countryCode": "UA"
            },
            {
                "name": "Baliasne",
                "countryCode": "UA"
            },
            {
                "name": "Baranivka",
                "countryCode": "UA"
            },
            {
                "name": "Barashi",
                "countryCode": "UA"
            },
            {
                "name": "Bazar",
                "countryCode": "UA"
            },
            {
                "name": "Bekhi",
                "countryCode": "UA"
            },
            {
                "name": "Berdychiv",
                "countryCode": "UA"
            },
            {
                "name": "Berestivka",
                "countryCode": "UA"
            },
            {
                "name": "Berestovets",
                "countryCode": "UA"
            },
            {
                "name": "Berezianka",
                "countryCode": "UA"
            },
            {
                "name": "Berezovii Grud",
                "countryCode": "UA"
            },
            {
                "name": "Bereztsi",
                "countryCode": "UA"
            },
            {
                "name": "Bicheva",
                "countryCode": "UA"
            },
            {
                "name": "Bigun",
                "countryCode": "UA"
            },
            {
                "name": "Bikiv",
                "countryCode": "UA"
            },
            {
                "name": "Bila Krynytsia",
                "countryCode": "UA"
            },
            {
                "name": "Bilii Bereg",
                "countryCode": "UA"
            },
            {
                "name": "Bilka",
                "countryCode": "UA"
            },
            {
                "name": "Bilylivka",
                "countryCode": "UA"
            },
            {
                "name": "Bistrik",
                "countryCode": "UA"
            },
            {
                "name": "Bistriyivka",
                "countryCode": "UA"
            },
            {
                "name": "Borisivka",
                "countryCode": "UA"
            },
            {
                "name": "Broniki",
                "countryCode": "UA"
            },
            {
                "name": "Bronitsia",
                "countryCode": "UA"
            },
            {
                "name": "Bronitska Guta",
                "countryCode": "UA"
            },
            {
                "name": "Brovki Pershi",
                "countryCode": "UA"
            },
            {
                "name": "Brusyliv",
                "countryCode": "UA"
            },
            {
                "name": "Buchmany",
                "countryCode": "UA"
            },
            {
                "name": "Buki",
                "countryCode": "UA"
            },
            {
                "name": "Buldichiv",
                "countryCode": "UA"
            },
            {
                "name": "Buriaki",
                "countryCode": "UA"
            },
            {
                "name": "Burkivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Bykivka",
                "countryCode": "UA"
            },
            {
                "name": "Chernechii Iar",
                "countryCode": "UA"
            },
            {
                "name": "Cherniakhiv",
                "countryCode": "UA"
            },
            {
                "name": "Chervone",
                "countryCode": "UA"
            },
            {
                "name": "Chopovychi",
                "countryCode": "UA"
            },
            {
                "name": "Chornukhy",
                "countryCode": "UA"
            },
            {
                "name": "Chudniv",
                "countryCode": "UA"
            },
            {
                "name": "Chutove",
                "countryCode": "UA"
            },
            {
                "name": "Dashynka",
                "countryCode": "UA"
            },
            {
                "name": "Davidivka",
                "countryCode": "UA"
            },
            {
                "name": "Davydky",
                "countryCode": "UA"
            },
            {
                "name": "Denyshi",
                "countryCode": "UA"
            },
            {
                "name": "Derhanivka",
                "countryCode": "UA"
            },
            {
                "name": "Dibrova",
                "countryCode": "UA"
            },
            {
                "name": "Didkovichi",
                "countryCode": "UA"
            },
            {
                "name": "Didovichi",
                "countryCode": "UA"
            },
            {
                "name": "Divochki",
                "countryCode": "UA"
            },
            {
                "name": "Dovbysh",
                "countryCode": "UA"
            },
            {
                "name": "Druzhba",
                "countryCode": "UA"
            },
            {
                "name": "Dubivka",
                "countryCode": "UA"
            },
            {
                "name": "Dubnyki",
                "countryCode": "UA"
            },
            {
                "name": "Dubrivka",
                "countryCode": "UA"
            },
            {
                "name": "Dvorishche",
                "countryCode": "UA"
            },
            {
                "name": "Dykanka",
                "countryCode": "UA"
            },
            {
                "name": "Elivka",
                "countryCode": "UA"
            },
            {
                "name": "Fedorivka",
                "countryCode": "UA"
            },
            {
                "name": "Filenkove",
                "countryCode": "UA"
            },
            {
                "name": "Glinivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Godikha",
                "countryCode": "UA"
            },
            {
                "name": "Golovenka",
                "countryCode": "UA"
            },
            {
                "name": "Golovki",
                "countryCode": "UA"
            },
            {
                "name": "Golubyatin",
                "countryCode": "UA"
            },
            {
                "name": "Gorbuliv",
                "countryCode": "UA"
            },
            {
                "name": "Gordiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Gorianshchina",
                "countryCode": "UA"
            },
            {
                "name": "Gorodkivka",
                "countryCode": "UA"
            },
            {
                "name": "Gorodok",
                "countryCode": "UA"
            },
            {
                "name": "Gorodske",
                "countryCode": "UA"
            },
            {
                "name": "Goropayi",
                "countryCode": "UA"
            },
            {
                "name": "Goshiv",
                "countryCode": "UA"
            },
            {
                "name": "Gromada",
                "countryCode": "UA"
            },
            {
                "name": "Grozyne",
                "countryCode": "UA"
            },
            {
                "name": "Grushki",
                "countryCode": "UA"
            },
            {
                "name": "Gubske",
                "countryCode": "UA"
            },
            {
                "name": "Gulsk",
                "countryCode": "UA"
            },
            {
                "name": "Gumenniki",
                "countryCode": "UA"
            },
            {
                "name": "Guta-Potiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Guto-Mariatin",
                "countryCode": "UA"
            },
            {
                "name": "Hadiach",
                "countryCode": "UA"
            },
            {
                "name": "Hlobyne",
                "countryCode": "UA"
            },
            {
                "name": "Holovyne",
                "countryCode": "UA"
            },
            {
                "name": "Holubivka",
                "countryCode": "UA"
            },
            {
                "name": "Horishni Plavni",
                "countryCode": "UA"
            },
            {
                "name": "Horodets",
                "countryCode": "UA"
            },
            {
                "name": "Horodnytsia",
                "countryCode": "UA"
            },
            {
                "name": "Hradyzk",
                "countryCode": "UA"
            },
            {
                "name": "Hranitne",
                "countryCode": "UA"
            },
            {
                "name": "Hrebinka",
                "countryCode": "UA"
            },
            {
                "name": "Hryshkivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Iemilivka",
                "countryCode": "UA"
            },
            {
                "name": "Ievgenivka",
                "countryCode": "UA"
            },
            {
                "name": "Irshansk",
                "countryCode": "UA"
            },
            {
                "name": "Ivanivka",
                "countryCode": "UA"
            },
            {
                "name": "Ivankiv",
                "countryCode": "UA"
            },
            {
                "name": "Ivanopil",
                "countryCode": "UA"
            },
            {
                "name": "Kamiani Potoky",
                "countryCode": "UA"
            },
            {
                "name": "Kardashivka",
                "countryCode": "UA"
            },
            {
                "name": "Karlivka",
                "countryCode": "UA"
            },
            {
                "name": "Khodaky",
                "countryCode": "UA"
            },
            {
                "name": "Khorol",
                "countryCode": "UA"
            },
            {
                "name": "Khoroshiv",
                "countryCode": "UA"
            },
            {
                "name": "Kobeliaky",
                "countryCode": "UA"
            },
            {
                "name": "Kononenki",
                "countryCode": "UA"
            },
            {
                "name": "Korchak",
                "countryCode": "UA"
            },
            {
                "name": "Kornyn",
                "countryCode": "UA"
            },
            {
                "name": "Korosten",
                "countryCode": "UA"
            },
            {
                "name": "Korostyshiv",
                "countryCode": "UA"
            },
            {
                "name": "Kotelva",
                "countryCode": "UA"
            },
            {
                "name": "Kozelshchyna",
                "countryCode": "UA"
            },
            {
                "name": "Kratova Govtva",
                "countryCode": "UA"
            },
            {
                "name": "Kremenchuk",
                "countryCode": "UA"
            },
            {
                "name": "Krukovskiy Raion",
                "countryCode": "UA"
            },
            {
                "name": "Kucherivka",
                "countryCode": "UA"
            },
            {
                "name": "Kupech",
                "countryCode": "UA"
            },
            {
                "name": "Kvitneve",
                "countryCode": "UA"
            },
            {
                "name": "Landari",
                "countryCode": "UA"
            },
            {
                "name": "Lani",
                "countryCode": "UA"
            },
            {
                "name": "Lasky",
                "countryCode": "UA"
            },
            {
                "name": "Lazarivka",
                "countryCode": "UA"
            },
            {
                "name": "Lisogirka",
                "countryCode": "UA"
            },
            {
                "name": "Liubar",
                "countryCode": "UA"
            },
            {
                "name": "Lokhvytsia",
                "countryCode": "UA"
            },
            {
                "name": "Lubny",
                "countryCode": "UA"
            },
            {
                "name": "Luhyny",
                "countryCode": "UA"
            },
            {
                "name": "Lutovynivka",
                "countryCode": "UA"
            },
            {
                "name": "Mala Rudka",
                "countryCode": "UA"
            },
            {
                "name": "Malyn",
                "countryCode": "UA"
            },
            {
                "name": "Marchenki",
                "countryCode": "UA"
            },
            {
                "name": "Markivka",
                "countryCode": "UA"
            },
            {
                "name": "Maschiwka",
                "countryCode": "UA"
            },
            {
                "name": "Morozivka",
                "countryCode": "UA"
            },
            {
                "name": "Myrhorod",
                "countryCode": "UA"
            },
            {
                "name": "Myropil",
                "countryCode": "UA"
            },
            {
                "name": "Narodychi",
                "countryCode": "UA"
            },
            {
                "name": "Nedoharky",
                "countryCode": "UA"
            },
            {
                "name": "Nemyryntsi",
                "countryCode": "UA"
            },
            {
                "name": "Nova Borova",
                "countryCode": "UA"
            },
            {
                "name": "Nova Chortoriia",
                "countryCode": "UA"
            },
            {
                "name": "Novi Bilokorovychi",
                "countryCode": "UA"
            },
            {
                "name": "Novi Sanzhary",
                "countryCode": "UA"
            },
            {
                "name": "Novi Vorobyi",
                "countryCode": "UA"
            },
            {
                "name": "Novohrad-Volynskyi",
                "countryCode": "UA"
            },
            {
                "name": "Novoozerianka",
                "countryCode": "UA"
            },
            {
                "name": "Nyvky",
                "countryCode": "UA"
            },
            {
                "name": "Odariukivka",
                "countryCode": "UA"
            },
            {
                "name": "Olefirshchina",
                "countryCode": "UA"
            },
            {
                "name": "Olevsk",
                "countryCode": "UA"
            },
            {
                "name": "Onatski",
                "countryCode": "UA"
            },
            {
                "name": "Opishnia",
                "countryCode": "UA"
            },
            {
                "name": "Orzhytsia",
                "countryCode": "UA"
            },
            {
                "name": "Ovruch",
                "countryCode": "UA"
            },
            {
                "name": "Ozerne",
                "countryCode": "UA"
            },
            {
                "name": "Pavoloch",
                "countryCode": "UA"
            },
            {
                "name": "Pershotravensk",
                "countryCode": "UA"
            },
            {
                "name": "Pershotravneve",
                "countryCode": "UA"
            },
            {
                "name": "Petrenki",
                "countryCode": "UA"
            },
            {
                "name": "Pisarivshchina",
                "countryCode": "UA"
            },
            {
                "name": "Pishchiv",
                "countryCode": "UA"
            },
            {
                "name": "Polianka",
                "countryCode": "UA"
            },
            {
                "name": "Poltava",
                "countryCode": "UA"
            },
            {
                "name": "Popilnia",
                "countryCode": "UA"
            },
            {
                "name": "Popivka",
                "countryCode": "UA"
            },
            {
                "name": "Potiivka",
                "countryCode": "UA"
            },
            {
                "name": "Proni",
                "countryCode": "UA"
            },
            {
                "name": "Pyriatyn",
                "countryCode": "UA"
            },
            {
                "name": "Radomyshl",
                "countryCode": "UA"
            },
            {
                "name": "Reshetylivka",
                "countryCode": "UA"
            },
            {
                "name": "Rohachi",
                "countryCode": "UA"
            },
            {
                "name": "Romaniv",
                "countryCode": "UA"
            },
            {
                "name": "Romodan",
                "countryCode": "UA"
            },
            {
                "name": "Ruzhyn (settlement)",
                "countryCode": "UA"
            },
            {
                "name": "Schyschaky",
                "countryCode": "UA"
            },
            {
                "name": "Semenivka",
                "countryCode": "UA"
            },
            {
                "name": "Sencha",
                "countryCode": "UA"
            },
            {
                "name": "Shumsk",
                "countryCode": "UA"
            },
            {
                "name": "Slovechne",
                "countryCode": "UA"
            },
            {
                "name": "Smolovivshchina",
                "countryCode": "UA"
            },
            {
                "name": "Sokhatska Balka",
                "countryCode": "UA"
            },
            {
                "name": "Stepanivka",
                "countryCode": "UA"
            },
            {
                "name": "Sudivka",
                "countryCode": "UA"
            },
            {
                "name": "Tabory",
                "countryCode": "UA"
            },
            {
                "name": "Taraschanka",
                "countryCode": "UA"
            },
            {
                "name": "Topory",
                "countryCode": "UA"
            },
            {
                "name": "Travneve",
                "countryCode": "UA"
            },
            {
                "name": "Troiany",
                "countryCode": "UA"
            },
            {
                "name": "Vasilivka",
                "countryCode": "UA"
            },
            {
                "name": "Velika Rudka",
                "countryCode": "UA"
            },
            {
                "name": "Veliki Budishcha",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Bahachka",
                "countryCode": "UA"
            },
            {
                "name": "Velyki Korovyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Velyki Sorochyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Veresna",
                "countryCode": "UA"
            },
            {
                "name": "Veselivka",
                "countryCode": "UA"
            },
            {
                "name": "Virlya",
                "countryCode": "UA"
            },
            {
                "name": "Vodiana Balka",
                "countryCode": "UA"
            },
            {
                "name": "Yablunets",
                "countryCode": "UA"
            },
            {
                "name": "Yarun",
                "countryCode": "UA"
            },
            {
                "name": "Yemilchyne",
                "countryCode": "UA"
            },
            {
                "name": "Zabaro-Davidivka",
                "countryCode": "UA"
            },
            {
                "name": "Zadovga",
                "countryCode": "UA"
            },
            {
                "name": "Zakrinichchia",
                "countryCode": "UA"
            },
            {
                "name": "Zalissia",
                "countryCode": "UA"
            },
            {
                "name": "Zaliznia",
                "countryCode": "UA"
            },
            {
                "name": "Zaluzhne",
                "countryCode": "UA"
            },
            {
                "name": "Zapadnia",
                "countryCode": "UA"
            },
            {
                "name": "Zarichchia",
                "countryCode": "UA"
            },
            {
                "name": "Zarudintsi",
                "countryCode": "UA"
            },
            {
                "name": "Zavodske",
                "countryCode": "UA"
            },
            {
                "name": "Zbranki",
                "countryCode": "UA"
            },
            {
                "name": "Zdorovets",
                "countryCode": "UA"
            },
            {
                "name": "Zherdeli",
                "countryCode": "UA"
            },
            {
                "name": "Zhitintsi",
                "countryCode": "UA"
            },
            {
                "name": "Zhovte",
                "countryCode": "UA"
            },
            {
                "name": "Zhovtii Brid",
                "countryCode": "UA"
            },
            {
                "name": "Zhupanivka",
                "countryCode": "UA"
            },
            {
                "name": "Zhurbintsi",
                "countryCode": "UA"
            },
            {
                "name": "Zhytomyr",
                "countryCode": "UA"
            },
            {
                "name": "Zinkiv",
                "countryCode": "UA"
            },
            {
                "name": "Zlobichi",
                "countryCode": "UA"
            },
            {
                "name": "Zoriane",
                "countryCode": "UA"
            },
            {
                "name": "Zorokiv",
                "countryCode": "UA"
            },
            {
                "name": "Zosimivka",
                "countryCode": "UA"
            },
            {
                "name": "Zubivshchina",
                "countryCode": "UA"
            },
            {
                "name": "Zvizdal",
                "countryCode": "UA"
            },
            {
                "name": "Demydivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Dubno",
                "countryCode": "UA"
            },
            {
                "name": "Dubrovytsya",
                "countryCode": "UA"
            },
            {
                "name": "Horodyshche",
                "countryCode": "UA"
            },
            {
                "name": "Hoshcha",
                "countryCode": "UA"
            },
            {
                "name": "Klevan",
                "countryCode": "UA"
            },
            {
                "name": "Kolodenka",
                "countryCode": "UA"
            },
            {
                "name": "Koloniya Zastav’ye",
                "countryCode": "UA"
            },
            {
                "name": "Korets",
                "countryCode": "UA"
            },
            {
                "name": "Kostopil’",
                "countryCode": "UA"
            },
            {
                "name": "Mizoch",
                "countryCode": "UA"
            },
            {
                "name": "Mlyniv",
                "countryCode": "UA"
            },
            {
                "name": "Mlynivs’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Orzhiv",
                "countryCode": "UA"
            },
            {
                "name": "Ostroh",
                "countryCode": "UA"
            },
            {
                "name": "Ostroz’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Radyvyliv",
                "countryCode": "UA"
            },
            {
                "name": "Rivne",
                "countryCode": "UA"
            },
            {
                "name": "Rivnens’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Rokytne",
                "countryCode": "UA"
            },
            {
                "name": "Sarny",
                "countryCode": "UA"
            },
            {
                "name": "Tarakaniv",
                "countryCode": "UA"
            },
            {
                "name": "Tynne",
                "countryCode": "UA"
            },
            {
                "name": "Varash",
                "countryCode": "UA"
            },
            {
                "name": "Velyki Mezhyrichi",
                "countryCode": "UA"
            },
            {
                "name": "Volodymyrets’",
                "countryCode": "UA"
            },
            {
                "name": "Zarichne",
                "countryCode": "UA"
            },
            {
                "name": "Zdolbuniv",
                "countryCode": "UA"
            },
            {
                "name": "Zdovbytsya",
                "countryCode": "UA"
            },
            {
                "name": "Bilopillya",
                "countryCode": "UA"
            },
            {
                "name": "Boromlya",
                "countryCode": "UA"
            },
            {
                "name": "Buryn’",
                "countryCode": "UA"
            },
            {
                "name": "Druzhba",
                "countryCode": "UA"
            },
            {
                "name": "Hlukhiv",
                "countryCode": "UA"
            },
            {
                "name": "Konotop",
                "countryCode": "UA"
            },
            {
                "name": "Krasnopillya",
                "countryCode": "UA"
            },
            {
                "name": "Krolevets’",
                "countryCode": "UA"
            },
            {
                "name": "Kyrykivka",
                "countryCode": "UA"
            },
            {
                "name": "Lebedyn",
                "countryCode": "UA"
            },
            {
                "name": "Lypova Dolyna",
                "countryCode": "UA"
            },
            {
                "name": "Mezenivka",
                "countryCode": "UA"
            },
            {
                "name": "Nedryhayliv",
                "countryCode": "UA"
            },
            {
                "name": "Nyzy",
                "countryCode": "UA"
            },
            {
                "name": "Okhtyrka",
                "countryCode": "UA"
            },
            {
                "name": "Putyvl’",
                "countryCode": "UA"
            },
            {
                "name": "Romens’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Romny",
                "countryCode": "UA"
            },
            {
                "name": "Seredyna-Buda",
                "countryCode": "UA"
            },
            {
                "name": "Shalyhyne",
                "countryCode": "UA"
            },
            {
                "name": "Shostka",
                "countryCode": "UA"
            },
            {
                "name": "Sloboda",
                "countryCode": "UA"
            },
            {
                "name": "Sumy",
                "countryCode": "UA"
            },
            {
                "name": "Terny",
                "countryCode": "UA"
            },
            {
                "name": "Trostyanets’",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Pysarivka",
                "countryCode": "UA"
            },
            {
                "name": "Verkhnya Syrovatka",
                "countryCode": "UA"
            },
            {
                "name": "Vorozhba",
                "countryCode": "UA"
            },
            {
                "name": "Yampil’",
                "countryCode": "UA"
            },
            {
                "name": "Yesman’",
                "countryCode": "UA"
            },
            {
                "name": "Belaya",
                "countryCode": "UA"
            },
            {
                "name": "Borshchiv",
                "countryCode": "UA"
            },
            {
                "name": "Buchach",
                "countryCode": "UA"
            },
            {
                "name": "Chortkiv",
                "countryCode": "UA"
            },
            {
                "name": "Druzhba",
                "countryCode": "UA"
            },
            {
                "name": "Hrymayliv",
                "countryCode": "UA"
            },
            {
                "name": "Husiatyn",
                "countryCode": "UA"
            },
            {
                "name": "Khorostkiv",
                "countryCode": "UA"
            },
            {
                "name": "Kopychyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Kozova",
                "countryCode": "UA"
            },
            {
                "name": "Kremenets",
                "countryCode": "UA"
            },
            {
                "name": "Lanivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Mikulintsy",
                "countryCode": "UA"
            },
            {
                "name": "Nyrkiv",
                "countryCode": "UA"
            },
            {
                "name": "Ozerna",
                "countryCode": "UA"
            },
            {
                "name": "Petrikov",
                "countryCode": "UA"
            },
            {
                "name": "Pidhaytsi",
                "countryCode": "UA"
            },
            {
                "name": "Pidvolochysk",
                "countryCode": "UA"
            },
            {
                "name": "Pochaiv",
                "countryCode": "UA"
            },
            {
                "name": "Skala-Podil’s’ka",
                "countryCode": "UA"
            },
            {
                "name": "Skalat",
                "countryCode": "UA"
            },
            {
                "name": "Terebovlya",
                "countryCode": "UA"
            },
            {
                "name": "Ternopil",
                "countryCode": "UA"
            },
            {
                "name": "Tovste",
                "countryCode": "UA"
            },
            {
                "name": "Velikiye Borki",
                "countryCode": "UA"
            },
            {
                "name": "Yahil’nytsya",
                "countryCode": "UA"
            },
            {
                "name": "Zalishchyky",
                "countryCode": "UA"
            },
            {
                "name": "Zaliztsi",
                "countryCode": "UA"
            },
            {
                "name": "Zbarazh",
                "countryCode": "UA"
            },
            {
                "name": "Zboriv",
                "countryCode": "UA"
            },
            {
                "name": "Zolotyy Potik",
                "countryCode": "UA"
            },
            {
                "name": "Бережани",
                "countryCode": "UA"
            },
            {
                "name": "Balakliya",
                "countryCode": "UA"
            },
            {
                "name": "Barvinkove",
                "countryCode": "UA"
            },
            {
                "name": "Berezivka",
                "countryCode": "UA"
            },
            {
                "name": "Bezlyudivka",
                "countryCode": "UA"
            },
            {
                "name": "Blyznyuky",
                "countryCode": "UA"
            },
            {
                "name": "Bohodukhiv",
                "countryCode": "UA"
            },
            {
                "name": "Borova",
                "countryCode": "UA"
            },
            {
                "name": "Budy",
                "countryCode": "UA"
            },
            {
                "name": "Chapayeve",
                "countryCode": "UA"
            },
            {
                "name": "Chervonyy Oskil",
                "countryCode": "UA"
            },
            {
                "name": "Chuhuyiv",
                "countryCode": "UA"
            },
            {
                "name": "Derhachi",
                "countryCode": "UA"
            },
            {
                "name": "Dvorichna",
                "countryCode": "UA"
            },
            {
                "name": "Eskhar",
                "countryCode": "UA"
            },
            {
                "name": "Huty",
                "countryCode": "UA"
            },
            {
                "name": "Izyum",
                "countryCode": "UA"
            },
            {
                "name": "Kehychivka",
                "countryCode": "UA"
            },
            {
                "name": "Kharkiv",
                "countryCode": "UA"
            },
            {
                "name": "Khorosheve",
                "countryCode": "UA"
            },
            {
                "name": "Kivsharivka",
                "countryCode": "UA"
            },
            {
                "name": "Kochetok",
                "countryCode": "UA"
            },
            {
                "name": "Kolomak",
                "countryCode": "UA"
            },
            {
                "name": "Korotych",
                "countryCode": "UA"
            },
            {
                "name": "Krasnohrad",
                "countryCode": "UA"
            },
            {
                "name": "Krasnokuts’k",
                "countryCode": "UA"
            },
            {
                "name": "Krasnopavlivka",
                "countryCode": "UA"
            },
            {
                "name": "Kulynychi",
                "countryCode": "UA"
            },
            {
                "name": "Kupjansk",
                "countryCode": "UA"
            },
            {
                "name": "Lozova",
                "countryCode": "UA"
            },
            {
                "name": "Lyman",
                "countryCode": "UA"
            },
            {
                "name": "Lyubotyn",
                "countryCode": "UA"
            },
            {
                "name": "Mala Danylivka",
                "countryCode": "UA"
            },
            {
                "name": "Malynivka",
                "countryCode": "UA"
            },
            {
                "name": "Manchenky",
                "countryCode": "UA"
            },
            {
                "name": "Merefa",
                "countryCode": "UA"
            },
            {
                "name": "Nova Vodolaha",
                "countryCode": "UA"
            },
            {
                "name": "Novopokrovka",
                "countryCode": "UA"
            },
            {
                "name": "Panyutyne",
                "countryCode": "UA"
            },
            {
                "name": "Peresichna",
                "countryCode": "UA"
            },
            {
                "name": "Pervomaiskyi Raion",
                "countryCode": "UA"
            },
            {
                "name": "Pervomays’kyy",
                "countryCode": "UA"
            },
            {
                "name": "Petrivs’ke",
                "countryCode": "UA"
            },
            {
                "name": "Pivdenne",
                "countryCode": "UA"
            },
            {
                "name": "Pokotylivka",
                "countryCode": "UA"
            },
            {
                "name": "Prudyanka",
                "countryCode": "UA"
            },
            {
                "name": "Prykolotne",
                "countryCode": "UA"
            },
            {
                "name": "Sakhnovshchyna",
                "countryCode": "UA"
            },
            {
                "name": "Savyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Shevchenkove",
                "countryCode": "UA"
            },
            {
                "name": "Slatyne",
                "countryCode": "UA"
            },
            {
                "name": "Slobozhans’ke",
                "countryCode": "UA"
            },
            {
                "name": "Solonytsivka",
                "countryCode": "UA"
            },
            {
                "name": "Staryy Merchyk",
                "countryCode": "UA"
            },
            {
                "name": "Staryy Saltiv",
                "countryCode": "UA"
            },
            {
                "name": "Studenok",
                "countryCode": "UA"
            },
            {
                "name": "Utkivka",
                "countryCode": "UA"
            },
            {
                "name": "Valky",
                "countryCode": "UA"
            },
            {
                "name": "Vasyshcheve",
                "countryCode": "UA"
            },
            {
                "name": "Velykyy Burluk",
                "countryCode": "UA"
            },
            {
                "name": "Verbivka",
                "countryCode": "UA"
            },
            {
                "name": "Vovchans’k",
                "countryCode": "UA"
            },
            {
                "name": "Vvedenka",
                "countryCode": "UA"
            },
            {
                "name": "Vysokyy",
                "countryCode": "UA"
            },
            {
                "name": "Zachepylivka",
                "countryCode": "UA"
            },
            {
                "name": "Zmiyiv",
                "countryCode": "UA"
            },
            {
                "name": "Zolochiv",
                "countryCode": "UA"
            },
            {
                "name": "Askaniya-Nova",
                "countryCode": "UA"
            },
            {
                "name": "Bekhtery",
                "countryCode": "UA"
            },
            {
                "name": "Beryslav",
                "countryCode": "UA"
            },
            {
                "name": "Bilozerka",
                "countryCode": "UA"
            },
            {
                "name": "Brylivka",
                "countryCode": "UA"
            },
            {
                "name": "Chaplynka",
                "countryCode": "UA"
            },
            {
                "name": "Chornobayivka",
                "countryCode": "UA"
            },
            {
                "name": "Chornyanka",
                "countryCode": "UA"
            },
            {
                "name": "Chulakivka",
                "countryCode": "UA"
            },
            {
                "name": "Dnipryany",
                "countryCode": "UA"
            },
            {
                "name": "Dudchany",
                "countryCode": "UA"
            },
            {
                "name": "Heniches’k",
                "countryCode": "UA"
            },
            {
                "name": "Heniches’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Hola Prystan’",
                "countryCode": "UA"
            },
            {
                "name": "Hornostayivka",
                "countryCode": "UA"
            },
            {
                "name": "Ivanivka",
                "countryCode": "UA"
            },
            {
                "name": "Kakhovka",
                "countryCode": "UA"
            },
            {
                "name": "Kalanchak",
                "countryCode": "UA"
            },
            {
                "name": "Kayiry",
                "countryCode": "UA"
            },
            {
                "name": "Kherson",
                "countryCode": "UA"
            },
            {
                "name": "Komyshany",
                "countryCode": "UA"
            },
            {
                "name": "Kozachi Laheri",
                "countryCode": "UA"
            },
            {
                "name": "Krasne",
                "countryCode": "UA"
            },
            {
                "name": "Lazurne",
                "countryCode": "UA"
            },
            {
                "name": "Lyubymivka",
                "countryCode": "UA"
            },
            {
                "name": "Malokakhovka",
                "countryCode": "UA"
            },
            {
                "name": "Nova Kakhovka",
                "countryCode": "UA"
            },
            {
                "name": "Nova Mayachka",
                "countryCode": "UA"
            },
            {
                "name": "Novofedorivka",
                "countryCode": "UA"
            },
            {
                "name": "Novooleksiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Novovorontsovka",
                "countryCode": "UA"
            },
            {
                "name": "Nyzhni Sirohozy",
                "countryCode": "UA"
            },
            {
                "name": "Partyzany",
                "countryCode": "UA"
            },
            {
                "name": "Radens’k",
                "countryCode": "UA"
            },
            {
                "name": "Skadovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Stanislav",
                "countryCode": "UA"
            },
            {
                "name": "Tsyurupyns’k",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Lepetykha",
                "countryCode": "UA"
            },
            {
                "name": "Velyka Oleksandrivka",
                "countryCode": "UA"
            },
            {
                "name": "Velyki Kopani",
                "countryCode": "UA"
            },
            {
                "name": "Verkhniy Rohachyk",
                "countryCode": "UA"
            },
            {
                "name": "Vynohradove",
                "countryCode": "UA"
            },
            {
                "name": "Vysokopillya",
                "countryCode": "UA"
            },
            {
                "name": "Zaliznyy Port",
                "countryCode": "UA"
            },
            {
                "name": "Antoniny",
                "countryCode": "UA"
            },
            {
                "name": "Bazaliya",
                "countryCode": "UA"
            },
            {
                "name": "Derazhnya",
                "countryCode": "UA"
            },
            {
                "name": "Derazhnyans’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Dunaivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Horodok",
                "countryCode": "UA"
            },
            {
                "name": "Izyaslav",
                "countryCode": "UA"
            },
            {
                "name": "Kamianets-Podilskyi",
                "countryCode": "UA"
            },
            {
                "name": "Khmelnytskyi",
                "countryCode": "UA"
            },
            {
                "name": "Khmel’nyts’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Krasyliv",
                "countryCode": "UA"
            },
            {
                "name": "Letychiv",
                "countryCode": "UA"
            },
            {
                "name": "Makiv",
                "countryCode": "UA"
            },
            {
                "name": "Medzhybizh",
                "countryCode": "UA"
            },
            {
                "name": "Narkevychi",
                "countryCode": "UA"
            },
            {
                "name": "Netishyn",
                "countryCode": "UA"
            },
            {
                "name": "Nova Ushytsya",
                "countryCode": "UA"
            },
            {
                "name": "Novoselytsya",
                "countryCode": "UA"
            },
            {
                "name": "Polonne",
                "countryCode": "UA"
            },
            {
                "name": "Poninka",
                "countryCode": "UA"
            },
            {
                "name": "Shepetivka",
                "countryCode": "UA"
            },
            {
                "name": "Slavuta",
                "countryCode": "UA"
            },
            {
                "name": "Smotrych",
                "countryCode": "UA"
            },
            {
                "name": "Smyga",
                "countryCode": "UA"
            },
            {
                "name": "Stara Syniava",
                "countryCode": "UA"
            },
            {
                "name": "Starokostiantyniv",
                "countryCode": "UA"
            },
            {
                "name": "Yarmolyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Zakupne",
                "countryCode": "UA"
            },
            {
                "name": "Zhvanets",
                "countryCode": "UA"
            },
            {
                "name": "Babanka",
                "countryCode": "UA"
            },
            {
                "name": "Buky",
                "countryCode": "UA"
            },
            {
                "name": "Cherkasy",
                "countryCode": "UA"
            },
            {
                "name": "Chervona Sloboda",
                "countryCode": "UA"
            },
            {
                "name": "Chornobay",
                "countryCode": "UA"
            },
            {
                "name": "Chyhyryn",
                "countryCode": "UA"
            },
            {
                "name": "Drabiv",
                "countryCode": "UA"
            },
            {
                "name": "Horodyshche",
                "countryCode": "UA"
            },
            {
                "name": "Kamianka",
                "countryCode": "UA"
            },
            {
                "name": "Kaniv",
                "countryCode": "UA"
            },
            {
                "name": "Khrystynivka",
                "countryCode": "UA"
            },
            {
                "name": "Korsun-Shevchenkivskyi",
                "countryCode": "UA"
            },
            {
                "name": "Lebedyn",
                "countryCode": "UA"
            },
            {
                "name": "Lysianka",
                "countryCode": "UA"
            },
            {
                "name": "Mankivka",
                "countryCode": "UA"
            },
            {
                "name": "Mliiv",
                "countryCode": "UA"
            },
            {
                "name": "Monastyryshche",
                "countryCode": "UA"
            },
            {
                "name": "Moshny",
                "countryCode": "UA"
            },
            {
                "name": "Nove-Misto",
                "countryCode": "UA"
            },
            {
                "name": "Ruska Poliana",
                "countryCode": "UA"
            },
            {
                "name": "Shpola",
                "countryCode": "UA"
            },
            {
                "name": "Smila",
                "countryCode": "UA"
            },
            {
                "name": "Stebliv",
                "countryCode": "UA"
            },
            {
                "name": "Talne",
                "countryCode": "UA"
            },
            {
                "name": "Tsibulev",
                "countryCode": "UA"
            },
            {
                "name": "Tsvitkove",
                "countryCode": "UA"
            },
            {
                "name": "Uman",
                "countryCode": "UA"
            },
            {
                "name": "Vatutine",
                "countryCode": "UA"
            },
            {
                "name": "Verkhniachka",
                "countryCode": "UA"
            },
            {
                "name": "Yerky",
                "countryCode": "UA"
            },
            {
                "name": "Yurkivka",
                "countryCode": "UA"
            },
            {
                "name": "Zhashkiv",
                "countryCode": "UA"
            },
            {
                "name": "Zolotonosha",
                "countryCode": "UA"
            },
            {
                "name": "Zvenihorodka",
                "countryCode": "UA"
            },
            {
                "name": "Avdiyivka",
                "countryCode": "UA"
            },
            {
                "name": "Bakhmach",
                "countryCode": "UA"
            },
            {
                "name": "Baturyn",
                "countryCode": "UA"
            },
            {
                "name": "Berezna",
                "countryCode": "UA"
            },
            {
                "name": "Bobrovytsya",
                "countryCode": "UA"
            },
            {
                "name": "Borzna",
                "countryCode": "UA"
            },
            {
                "name": "Chernihiv",
                "countryCode": "UA"
            },
            {
                "name": "Chernihiv Raion",
                "countryCode": "UA"
            },
            {
                "name": "Chernihivs’ka Mis’krada",
                "countryCode": "UA"
            },
            {
                "name": "Desna",
                "countryCode": "UA"
            },
            {
                "name": "Dihtyari",
                "countryCode": "UA"
            },
            {
                "name": "Dobryanka",
                "countryCode": "UA"
            },
            {
                "name": "Horodnya",
                "countryCode": "UA"
            },
            {
                "name": "Ichnya",
                "countryCode": "UA"
            },
            {
                "name": "Kholmy",
                "countryCode": "UA"
            },
            {
                "name": "Kobyzhcha",
                "countryCode": "UA"
            },
            {
                "name": "Korop",
                "countryCode": "UA"
            },
            {
                "name": "Koryukivka",
                "countryCode": "UA"
            },
            {
                "name": "Kozelets’",
                "countryCode": "UA"
            },
            {
                "name": "Kruty",
                "countryCode": "UA"
            },
            {
                "name": "Kulykivka",
                "countryCode": "UA"
            },
            {
                "name": "Ladan",
                "countryCode": "UA"
            },
            {
                "name": "Lisovi Sorochyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Lyubech",
                "countryCode": "UA"
            },
            {
                "name": "Mena",
                "countryCode": "UA"
            },
            {
                "name": "Mens’kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Nizhyn",
                "countryCode": "UA"
            },
            {
                "name": "Nosivka",
                "countryCode": "UA"
            },
            {
                "name": "Novhorod-Sivers’kyy",
                "countryCode": "UA"
            },
            {
                "name": "Olyshivka",
                "countryCode": "UA"
            },
            {
                "name": "Oster",
                "countryCode": "UA"
            },
            {
                "name": "Pryluky",
                "countryCode": "UA"
            },
            {
                "name": "Ripky",
                "countryCode": "UA"
            },
            {
                "name": "Sedniv",
                "countryCode": "UA"
            },
            {
                "name": "Semenivka",
                "countryCode": "UA"
            },
            {
                "name": "Shchors",
                "countryCode": "UA"
            },
            {
                "name": "Snovs'kyy Rayon",
                "countryCode": "UA"
            },
            {
                "name": "Sosnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Sribne",
                "countryCode": "UA"
            },
            {
                "name": "Varva",
                "countryCode": "UA"
            },
            {
                "name": "Zhuravka",
                "countryCode": "UA"
            },
            {
                "name": "Banyliv",
                "countryCode": "UA"
            },
            {
                "name": "Berehomet",
                "countryCode": "UA"
            },
            {
                "name": "Boyany",
                "countryCode": "UA"
            },
            {
                "name": "Chernivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Chernivtsi Municipality",
                "countryCode": "UA"
            },
            {
                "name": "Chudey",
                "countryCode": "UA"
            },
            {
                "name": "Davydivka",
                "countryCode": "UA"
            },
            {
                "name": "Hlyboka",
                "countryCode": "UA"
            },
            {
                "name": "Ispas",
                "countryCode": "UA"
            },
            {
                "name": "Kadubivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Khotyn",
                "countryCode": "UA"
            },
            {
                "name": "Klishkivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Kostryzhivka",
                "countryCode": "UA"
            },
            {
                "name": "Luzhany",
                "countryCode": "UA"
            },
            {
                "name": "Marshintsy",
                "countryCode": "UA"
            },
            {
                "name": "Molodiya",
                "countryCode": "UA"
            },
            {
                "name": "Myhove",
                "countryCode": "UA"
            },
            {
                "name": "Novodnistrovs’k",
                "countryCode": "UA"
            },
            {
                "name": "Novoselytsya",
                "countryCode": "UA"
            },
            {
                "name": "Nyzhni Petrivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Putyla Raion",
                "countryCode": "UA"
            },
            {
                "name": "Ropcha",
                "countryCode": "UA"
            },
            {
                "name": "Shypyntsi",
                "countryCode": "UA"
            },
            {
                "name": "Sokyryany",
                "countryCode": "UA"
            },
            {
                "name": "Vashkivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Verenchanka",
                "countryCode": "UA"
            },
            {
                "name": "Voloka",
                "countryCode": "UA"
            },
            {
                "name": "Vyzhnytsya",
                "countryCode": "UA"
            },
            {
                "name": "Yizhivtsi",
                "countryCode": "UA"
            },
            {
                "name": "Zastavna",
                "countryCode": "UA"
            }
        ]
    },
    {
        "name": "United Arab Emirates",
        "phonecode": "971",
        "cities": [
            {
                "name": "Ajman",
                "countryCode": "AE"
            },
            {
                "name": "Ajman City",
                "countryCode": "AE"
            },
            {
                "name": "Manama",
                "countryCode": "AE"
            },
            {
                "name": "Masfout",
                "countryCode": "AE"
            },
            {
                "name": "Abu Dhabi Island and Internal Islands City",
                "countryCode": "AE"
            },
            {
                "name": "Abu Dhabi Municipality",
                "countryCode": "AE"
            },
            {
                "name": "Al Ain City",
                "countryCode": "AE"
            },
            {
                "name": "Al Ain Municipality",
                "countryCode": "AE"
            },
            {
                "name": "Al Dhafra",
                "countryCode": "AE"
            },
            {
                "name": "Al Shamkhah City",
                "countryCode": "AE"
            },
            {
                "name": "Ar Ruways",
                "countryCode": "AE"
            },
            {
                "name": "Bani Yas City",
                "countryCode": "AE"
            },
            {
                "name": "Khalifah A City",
                "countryCode": "AE"
            },
            {
                "name": "Musaffah",
                "countryCode": "AE"
            },
            {
                "name": "Muzayri‘",
                "countryCode": "AE"
            },
            {
                "name": "Zayed City",
                "countryCode": "AE"
            },
            {
                "name": "Dubai",
                "countryCode": "AE"
            },
            {
                "name": "Al Fujairah City",
                "countryCode": "AE"
            },
            {
                "name": "Al Fujairah Municipality",
                "countryCode": "AE"
            },
            {
                "name": "Dibba Al Fujairah Municipality",
                "countryCode": "AE"
            },
            {
                "name": "Dibba Al-Fujairah",
                "countryCode": "AE"
            },
            {
                "name": "Dibba Al-Hisn",
                "countryCode": "AE"
            },
            {
                "name": "Reef Al Fujairah City",
                "countryCode": "AE"
            },
            {
                "name": "Ras Al Khaimah",
                "countryCode": "AE"
            },
            {
                "name": "Ras Al Khaimah City",
                "countryCode": "AE"
            },
            {
                "name": "Adh Dhayd",
                "countryCode": "AE"
            },
            {
                "name": "Al Batayih",
                "countryCode": "AE"
            },
            {
                "name": "Al Hamriyah",
                "countryCode": "AE"
            },
            {
                "name": "Al Madam",
                "countryCode": "AE"
            },
            {
                "name": "Dhaid",
                "countryCode": "AE"
            },
            {
                "name": "Dibba Al Hesn",
                "countryCode": "AE"
            },
            {
                "name": "Kalba",
                "countryCode": "AE"
            },
            {
                "name": "Khawr Fakkān",
                "countryCode": "AE"
            },
            {
                "name": "Khor Fakkan",
                "countryCode": "AE"
            },
            {
                "name": "Milehah",
                "countryCode": "AE"
            },
            {
                "name": "Murbaḩ",
                "countryCode": "AE"
            },
            {
                "name": "Sharjah",
                "countryCode": "AE"
            },
            {
                "name": "Umm AL Quwain",
                "countryCode": "AE"
            },
            {
                "name": "Umm Al Quwain City",
                "countryCode": "AE"
            }
        ]
    },
    {
        "name": "United Kingdom",
        "phonecode": "44",
        "cities": [
            {
                "name": "Abbey Wood",
                "countryCode": "GB"
            },
            {
                "name": "Abbots Bromley",
                "countryCode": "GB"
            },
            {
                "name": "Abbots Langley",
                "countryCode": "GB"
            },
            {
                "name": "Abbotskerswell",
                "countryCode": "GB"
            },
            {
                "name": "Abbotts Ann",
                "countryCode": "GB"
            },
            {
                "name": "Aberford",
                "countryCode": "GB"
            },
            {
                "name": "Abingdon",
                "countryCode": "GB"
            },
            {
                "name": "Abram",
                "countryCode": "GB"
            },
            {
                "name": "Abridge",
                "countryCode": "GB"
            },
            {
                "name": "Accrington",
                "countryCode": "GB"
            },
            {
                "name": "Acklington",
                "countryCode": "GB"
            },
            {
                "name": "Acle",
                "countryCode": "GB"
            },
            {
                "name": "Acocks Green",
                "countryCode": "GB"
            },
            {
                "name": "Acomb",
                "countryCode": "GB"
            },
            {
                "name": "Acton",
                "countryCode": "GB"
            },
            {
                "name": "Adderbury",
                "countryCode": "GB"
            },
            {
                "name": "Addingham",
                "countryCode": "GB"
            },
            {
                "name": "Addlestone",
                "countryCode": "GB"
            },
            {
                "name": "Adlington",
                "countryCode": "GB"
            },
            {
                "name": "Adwick le Street",
                "countryCode": "GB"
            },
            {
                "name": "Airmyn",
                "countryCode": "GB"
            },
            {
                "name": "Albrighton",
                "countryCode": "GB"
            },
            {
                "name": "Alcester",
                "countryCode": "GB"
            },
            {
                "name": "Alconbury",
                "countryCode": "GB"
            },
            {
                "name": "Aldbourne",
                "countryCode": "GB"
            },
            {
                "name": "Aldbrough",
                "countryCode": "GB"
            },
            {
                "name": "Aldeburgh",
                "countryCode": "GB"
            },
            {
                "name": "Alderbury",
                "countryCode": "GB"
            },
            {
                "name": "Alderholt",
                "countryCode": "GB"
            },
            {
                "name": "Alderley Edge",
                "countryCode": "GB"
            },
            {
                "name": "Aldershot",
                "countryCode": "GB"
            },
            {
                "name": "Aldford",
                "countryCode": "GB"
            },
            {
                "name": "Aldridge",
                "countryCode": "GB"
            },
            {
                "name": "Alford",
                "countryCode": "GB"
            },
            {
                "name": "Alfreton",
                "countryCode": "GB"
            },
            {
                "name": "Allhallows",
                "countryCode": "GB"
            },
            {
                "name": "Almondsbury",
                "countryCode": "GB"
            },
            {
                "name": "Alnwick",
                "countryCode": "GB"
            },
            {
                "name": "Alresford",
                "countryCode": "GB"
            },
            {
                "name": "Alrewas",
                "countryCode": "GB"
            },
            {
                "name": "Alsager",
                "countryCode": "GB"
            },
            {
                "name": "Alston",
                "countryCode": "GB"
            },
            {
                "name": "Althorne",
                "countryCode": "GB"
            },
            {
                "name": "Alton",
                "countryCode": "GB"
            },
            {
                "name": "Altrincham",
                "countryCode": "GB"
            },
            {
                "name": "Alvechurch",
                "countryCode": "GB"
            },
            {
                "name": "Alveley",
                "countryCode": "GB"
            },
            {
                "name": "Alveston",
                "countryCode": "GB"
            },
            {
                "name": "Amble",
                "countryCode": "GB"
            },
            {
                "name": "Ambleside",
                "countryCode": "GB"
            },
            {
                "name": "Ambrosden",
                "countryCode": "GB"
            },
            {
                "name": "Amersham",
                "countryCode": "GB"
            },
            {
                "name": "Amersham on the Hill",
                "countryCode": "GB"
            },
            {
                "name": "Amesbury",
                "countryCode": "GB"
            },
            {
                "name": "Ampthill",
                "countryCode": "GB"
            },
            {
                "name": "Ancaster",
                "countryCode": "GB"
            },
            {
                "name": "Andover",
                "countryCode": "GB"
            },
            {
                "name": "Anna Valley",
                "countryCode": "GB"
            },
            {
                "name": "Annfield Plain",
                "countryCode": "GB"
            },
            {
                "name": "Anstey",
                "countryCode": "GB"
            },
            {
                "name": "Appleby",
                "countryCode": "GB"
            },
            {
                "name": "Appleby-in-Westmorland",
                "countryCode": "GB"
            },
            {
                "name": "Appledore",
                "countryCode": "GB"
            },
            {
                "name": "Appleton Thorn",
                "countryCode": "GB"
            },
            {
                "name": "Appley Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Archway",
                "countryCode": "GB"
            },
            {
                "name": "Ardingly",
                "countryCode": "GB"
            },
            {
                "name": "Arlesey",
                "countryCode": "GB"
            },
            {
                "name": "Armitage",
                "countryCode": "GB"
            },
            {
                "name": "Armthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Arnold",
                "countryCode": "GB"
            },
            {
                "name": "Arnside",
                "countryCode": "GB"
            },
            {
                "name": "Arundel",
                "countryCode": "GB"
            },
            {
                "name": "Ascot",
                "countryCode": "GB"
            },
            {
                "name": "Asfordby",
                "countryCode": "GB"
            },
            {
                "name": "Ash",
                "countryCode": "GB"
            },
            {
                "name": "Ashbourne",
                "countryCode": "GB"
            },
            {
                "name": "Ashburton",
                "countryCode": "GB"
            },
            {
                "name": "Ashby de la Zouch",
                "countryCode": "GB"
            },
            {
                "name": "Ashford",
                "countryCode": "GB"
            },
            {
                "name": "Ashill",
                "countryCode": "GB"
            },
            {
                "name": "Ashington",
                "countryCode": "GB"
            },
            {
                "name": "Ashtead",
                "countryCode": "GB"
            },
            {
                "name": "Ashton Keynes",
                "countryCode": "GB"
            },
            {
                "name": "Ashton in Makerfield",
                "countryCode": "GB"
            },
            {
                "name": "Ashton-under-Lyne",
                "countryCode": "GB"
            },
            {
                "name": "Ashurst",
                "countryCode": "GB"
            },
            {
                "name": "Ashwell",
                "countryCode": "GB"
            },
            {
                "name": "Askam in Furness",
                "countryCode": "GB"
            },
            {
                "name": "Askern",
                "countryCode": "GB"
            },
            {
                "name": "Aslockton",
                "countryCode": "GB"
            },
            {
                "name": "Aspatria",
                "countryCode": "GB"
            },
            {
                "name": "Aston",
                "countryCode": "GB"
            },
            {
                "name": "Aston Clinton",
                "countryCode": "GB"
            },
            {
                "name": "Aston-on-Trent",
                "countryCode": "GB"
            },
            {
                "name": "Astwood Bank",
                "countryCode": "GB"
            },
            {
                "name": "Atherstone",
                "countryCode": "GB"
            },
            {
                "name": "Atherton",
                "countryCode": "GB"
            },
            {
                "name": "Attleborough",
                "countryCode": "GB"
            },
            {
                "name": "Atworth",
                "countryCode": "GB"
            },
            {
                "name": "Auckley",
                "countryCode": "GB"
            },
            {
                "name": "Audlem",
                "countryCode": "GB"
            },
            {
                "name": "Audley",
                "countryCode": "GB"
            },
            {
                "name": "Aveley",
                "countryCode": "GB"
            },
            {
                "name": "Avening",
                "countryCode": "GB"
            },
            {
                "name": "Awsworth",
                "countryCode": "GB"
            },
            {
                "name": "Axbridge",
                "countryCode": "GB"
            },
            {
                "name": "Axminster",
                "countryCode": "GB"
            },
            {
                "name": "Aylesbury",
                "countryCode": "GB"
            },
            {
                "name": "Aylesford",
                "countryCode": "GB"
            },
            {
                "name": "Aylesham",
                "countryCode": "GB"
            },
            {
                "name": "Aylsham",
                "countryCode": "GB"
            },
            {
                "name": "Babworth",
                "countryCode": "GB"
            },
            {
                "name": "Backworth",
                "countryCode": "GB"
            },
            {
                "name": "Bacton",
                "countryCode": "GB"
            },
            {
                "name": "Bacup",
                "countryCode": "GB"
            },
            {
                "name": "Badsey",
                "countryCode": "GB"
            },
            {
                "name": "Badsworth",
                "countryCode": "GB"
            },
            {
                "name": "Bagshot",
                "countryCode": "GB"
            },
            {
                "name": "Bagworth",
                "countryCode": "GB"
            },
            {
                "name": "Baildon",
                "countryCode": "GB"
            },
            {
                "name": "Bakewell",
                "countryCode": "GB"
            },
            {
                "name": "Balcombe",
                "countryCode": "GB"
            },
            {
                "name": "Baldock",
                "countryCode": "GB"
            },
            {
                "name": "Balsall Common",
                "countryCode": "GB"
            },
            {
                "name": "Balsham",
                "countryCode": "GB"
            },
            {
                "name": "Bamburgh",
                "countryCode": "GB"
            },
            {
                "name": "Bampton",
                "countryCode": "GB"
            },
            {
                "name": "Banbury",
                "countryCode": "GB"
            },
            {
                "name": "Banham",
                "countryCode": "GB"
            },
            {
                "name": "Banks",
                "countryCode": "GB"
            },
            {
                "name": "Banstead",
                "countryCode": "GB"
            },
            {
                "name": "Banwell",
                "countryCode": "GB"
            },
            {
                "name": "Bar Hill",
                "countryCode": "GB"
            },
            {
                "name": "Barbican",
                "countryCode": "GB"
            },
            {
                "name": "Bardney",
                "countryCode": "GB"
            },
            {
                "name": "Bardsey",
                "countryCode": "GB"
            },
            {
                "name": "Barham",
                "countryCode": "GB"
            },
            {
                "name": "Barking",
                "countryCode": "GB"
            },
            {
                "name": "Barkisland",
                "countryCode": "GB"
            },
            {
                "name": "Barlaston",
                "countryCode": "GB"
            },
            {
                "name": "Barlborough",
                "countryCode": "GB"
            },
            {
                "name": "Barlby",
                "countryCode": "GB"
            },
            {
                "name": "Barlestone",
                "countryCode": "GB"
            },
            {
                "name": "Barmby on the Marsh",
                "countryCode": "GB"
            },
            {
                "name": "Barnack",
                "countryCode": "GB"
            },
            {
                "name": "Barnard Castle",
                "countryCode": "GB"
            },
            {
                "name": "Barnburgh",
                "countryCode": "GB"
            },
            {
                "name": "Barnet",
                "countryCode": "GB"
            },
            {
                "name": "Barnetby le Wold",
                "countryCode": "GB"
            },
            {
                "name": "Barnham",
                "countryCode": "GB"
            },
            {
                "name": "Barnoldswick",
                "countryCode": "GB"
            },
            {
                "name": "Barnsbury",
                "countryCode": "GB"
            },
            {
                "name": "Barnsley",
                "countryCode": "GB"
            },
            {
                "name": "Barnstaple",
                "countryCode": "GB"
            },
            {
                "name": "Barnt Green",
                "countryCode": "GB"
            },
            {
                "name": "Barnwood",
                "countryCode": "GB"
            },
            {
                "name": "Barrow in Furness",
                "countryCode": "GB"
            },
            {
                "name": "Barrow upon Humber",
                "countryCode": "GB"
            },
            {
                "name": "Barrow upon Soar",
                "countryCode": "GB"
            },
            {
                "name": "Barrowby",
                "countryCode": "GB"
            },
            {
                "name": "Barrowford",
                "countryCode": "GB"
            },
            {
                "name": "Bartley Green",
                "countryCode": "GB"
            },
            {
                "name": "Barton under Needwood",
                "countryCode": "GB"
            },
            {
                "name": "Barton upon Humber",
                "countryCode": "GB"
            },
            {
                "name": "Barton-le-Clay",
                "countryCode": "GB"
            },
            {
                "name": "Baschurch",
                "countryCode": "GB"
            },
            {
                "name": "Basford",
                "countryCode": "GB"
            },
            {
                "name": "Basildon",
                "countryCode": "GB"
            },
            {
                "name": "Basingstoke",
                "countryCode": "GB"
            },
            {
                "name": "Baslow",
                "countryCode": "GB"
            },
            {
                "name": "Bassingbourn",
                "countryCode": "GB"
            },
            {
                "name": "Bassingham",
                "countryCode": "GB"
            },
            {
                "name": "Baston",
                "countryCode": "GB"
            },
            {
                "name": "Bath",
                "countryCode": "GB"
            },
            {
                "name": "Bath and North East Somerset",
                "countryCode": "GB"
            },
            {
                "name": "Batley",
                "countryCode": "GB"
            },
            {
                "name": "Battersea",
                "countryCode": "GB"
            },
            {
                "name": "Battle",
                "countryCode": "GB"
            },
            {
                "name": "Bawtry",
                "countryCode": "GB"
            },
            {
                "name": "Bay Horse",
                "countryCode": "GB"
            },
            {
                "name": "Bayston Hill",
                "countryCode": "GB"
            },
            {
                "name": "Bayswater",
                "countryCode": "GB"
            },
            {
                "name": "Beaconsfield",
                "countryCode": "GB"
            },
            {
                "name": "Beadnell",
                "countryCode": "GB"
            },
            {
                "name": "Beaminster",
                "countryCode": "GB"
            },
            {
                "name": "Bebington",
                "countryCode": "GB"
            },
            {
                "name": "Beccles",
                "countryCode": "GB"
            },
            {
                "name": "Beckenham",
                "countryCode": "GB"
            },
            {
                "name": "Beckingham",
                "countryCode": "GB"
            },
            {
                "name": "Becontree",
                "countryCode": "GB"
            },
            {
                "name": "Bedale",
                "countryCode": "GB"
            },
            {
                "name": "Bedford",
                "countryCode": "GB"
            },
            {
                "name": "Bedlington",
                "countryCode": "GB"
            },
            {
                "name": "Bedworth",
                "countryCode": "GB"
            },
            {
                "name": "Beeford",
                "countryCode": "GB"
            },
            {
                "name": "Beighton",
                "countryCode": "GB"
            },
            {
                "name": "Belbroughton",
                "countryCode": "GB"
            },
            {
                "name": "Belford",
                "countryCode": "GB"
            },
            {
                "name": "Bellingham",
                "countryCode": "GB"
            },
            {
                "name": "Belmont",
                "countryCode": "GB"
            },
            {
                "name": "Belper",
                "countryCode": "GB"
            },
            {
                "name": "Belsize Park",
                "countryCode": "GB"
            },
            {
                "name": "Belton",
                "countryCode": "GB"
            },
            {
                "name": "Belvedere",
                "countryCode": "GB"
            },
            {
                "name": "Bembridge",
                "countryCode": "GB"
            },
            {
                "name": "Bempton",
                "countryCode": "GB"
            },
            {
                "name": "Benson",
                "countryCode": "GB"
            },
            {
                "name": "Bentley",
                "countryCode": "GB"
            },
            {
                "name": "Benwell",
                "countryCode": "GB"
            },
            {
                "name": "Bere Alston",
                "countryCode": "GB"
            },
            {
                "name": "Bere Regis",
                "countryCode": "GB"
            },
            {
                "name": "Berkeley",
                "countryCode": "GB"
            },
            {
                "name": "Berkhamsted",
                "countryCode": "GB"
            },
            {
                "name": "Berwick-Upon-Tweed",
                "countryCode": "GB"
            },
            {
                "name": "Bethnal Green",
                "countryCode": "GB"
            },
            {
                "name": "Betley",
                "countryCode": "GB"
            },
            {
                "name": "Beverley",
                "countryCode": "GB"
            },
            {
                "name": "Bewbush",
                "countryCode": "GB"
            },
            {
                "name": "Bewdley",
                "countryCode": "GB"
            },
            {
                "name": "Bexhill-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Bexley",
                "countryCode": "GB"
            },
            {
                "name": "Bicester",
                "countryCode": "GB"
            },
            {
                "name": "Bicknacre",
                "countryCode": "GB"
            },
            {
                "name": "Bicton",
                "countryCode": "GB"
            },
            {
                "name": "Biddenden",
                "countryCode": "GB"
            },
            {
                "name": "Biddestone",
                "countryCode": "GB"
            },
            {
                "name": "Biddulph",
                "countryCode": "GB"
            },
            {
                "name": "Bideford",
                "countryCode": "GB"
            },
            {
                "name": "Bidford-on-Avon",
                "countryCode": "GB"
            },
            {
                "name": "Biggin Hill",
                "countryCode": "GB"
            },
            {
                "name": "Biggleswade",
                "countryCode": "GB"
            },
            {
                "name": "Bildeston",
                "countryCode": "GB"
            },
            {
                "name": "Billericay",
                "countryCode": "GB"
            },
            {
                "name": "Billingborough",
                "countryCode": "GB"
            },
            {
                "name": "Billinge",
                "countryCode": "GB"
            },
            {
                "name": "Billingham",
                "countryCode": "GB"
            },
            {
                "name": "Billinghay",
                "countryCode": "GB"
            },
            {
                "name": "Billingshurst",
                "countryCode": "GB"
            },
            {
                "name": "Billington",
                "countryCode": "GB"
            },
            {
                "name": "Bilsdale",
                "countryCode": "GB"
            },
            {
                "name": "Bilsthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Bilston",
                "countryCode": "GB"
            },
            {
                "name": "Bingham",
                "countryCode": "GB"
            },
            {
                "name": "Bingley",
                "countryCode": "GB"
            },
            {
                "name": "Birchington-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Bircotes",
                "countryCode": "GB"
            },
            {
                "name": "Birdham",
                "countryCode": "GB"
            },
            {
                "name": "Birdwell",
                "countryCode": "GB"
            },
            {
                "name": "Birkenhead",
                "countryCode": "GB"
            },
            {
                "name": "Birmingham",
                "countryCode": "GB"
            },
            {
                "name": "Bishop Auckland",
                "countryCode": "GB"
            },
            {
                "name": "Bishop Middleham",
                "countryCode": "GB"
            },
            {
                "name": "Bishop Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Bishop's Castle",
                "countryCode": "GB"
            },
            {
                "name": "Bishops Cleeve",
                "countryCode": "GB"
            },
            {
                "name": "Bishops Lydeard",
                "countryCode": "GB"
            },
            {
                "name": "Bishops Stortford",
                "countryCode": "GB"
            },
            {
                "name": "Bishops Waltham",
                "countryCode": "GB"
            },
            {
                "name": "Bishopsteignton",
                "countryCode": "GB"
            },
            {
                "name": "Bishopstoke",
                "countryCode": "GB"
            },
            {
                "name": "Bishopstone",
                "countryCode": "GB"
            },
            {
                "name": "Bishopsworth",
                "countryCode": "GB"
            },
            {
                "name": "Bishopthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Bishopton",
                "countryCode": "GB"
            },
            {
                "name": "Bitton",
                "countryCode": "GB"
            },
            {
                "name": "Blaby",
                "countryCode": "GB"
            },
            {
                "name": "Black Notley",
                "countryCode": "GB"
            },
            {
                "name": "Blackburn",
                "countryCode": "GB"
            },
            {
                "name": "Blackburn with Darwen",
                "countryCode": "GB"
            },
            {
                "name": "Blackheath",
                "countryCode": "GB"
            },
            {
                "name": "Blackley",
                "countryCode": "GB"
            },
            {
                "name": "Blackmoorfoot",
                "countryCode": "GB"
            },
            {
                "name": "Blackpool",
                "countryCode": "GB"
            },
            {
                "name": "Blackrod",
                "countryCode": "GB"
            },
            {
                "name": "Blackwell",
                "countryCode": "GB"
            },
            {
                "name": "Blacon",
                "countryCode": "GB"
            },
            {
                "name": "Blagdon",
                "countryCode": "GB"
            },
            {
                "name": "Blandford Forum",
                "countryCode": "GB"
            },
            {
                "name": "Blaydon-on-Tyne",
                "countryCode": "GB"
            },
            {
                "name": "Bleadon",
                "countryCode": "GB"
            },
            {
                "name": "Blean",
                "countryCode": "GB"
            },
            {
                "name": "Bletchingley",
                "countryCode": "GB"
            },
            {
                "name": "Bletchley",
                "countryCode": "GB"
            },
            {
                "name": "Blewbury",
                "countryCode": "GB"
            },
            {
                "name": "Blidworth",
                "countryCode": "GB"
            },
            {
                "name": "Blindley Heath",
                "countryCode": "GB"
            },
            {
                "name": "Blisworth",
                "countryCode": "GB"
            },
            {
                "name": "Blockley",
                "countryCode": "GB"
            },
            {
                "name": "Bloxham",
                "countryCode": "GB"
            },
            {
                "name": "Bloxwich",
                "countryCode": "GB"
            },
            {
                "name": "Blunham",
                "countryCode": "GB"
            },
            {
                "name": "Bluntisham",
                "countryCode": "GB"
            },
            {
                "name": "Blyth",
                "countryCode": "GB"
            },
            {
                "name": "Blyton",
                "countryCode": "GB"
            },
            {
                "name": "Bodle Street",
                "countryCode": "GB"
            },
            {
                "name": "Bodmin",
                "countryCode": "GB"
            },
            {
                "name": "Bognor Regis",
                "countryCode": "GB"
            },
            {
                "name": "Bollington",
                "countryCode": "GB"
            },
            {
                "name": "Bolsover",
                "countryCode": "GB"
            },
            {
                "name": "Bolton",
                "countryCode": "GB"
            },
            {
                "name": "Bolton le Sands",
                "countryCode": "GB"
            },
            {
                "name": "Bolton upon Dearne",
                "countryCode": "GB"
            },
            {
                "name": "Boosbeck",
                "countryCode": "GB"
            },
            {
                "name": "Bootle",
                "countryCode": "GB"
            },
            {
                "name": "Bordon",
                "countryCode": "GB"
            },
            {
                "name": "Boreham",
                "countryCode": "GB"
            },
            {
                "name": "Borehamwood",
                "countryCode": "GB"
            },
            {
                "name": "Borough Green",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Bolton",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Bury",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Halton",
                "countryCode": "GB"
            },
            {
                "name": "Borough of North Tyneside",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Oldham",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Rochdale",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Stockport",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Swindon",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Tameside",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Thurrock",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Torbay",
                "countryCode": "GB"
            },
            {
                "name": "Borough of Wigan",
                "countryCode": "GB"
            },
            {
                "name": "Boroughbridge",
                "countryCode": "GB"
            },
            {
                "name": "Borrowash",
                "countryCode": "GB"
            },
            {
                "name": "Bosham",
                "countryCode": "GB"
            },
            {
                "name": "Boston",
                "countryCode": "GB"
            },
            {
                "name": "Boston Spa",
                "countryCode": "GB"
            },
            {
                "name": "Botesdale",
                "countryCode": "GB"
            },
            {
                "name": "Botley",
                "countryCode": "GB"
            },
            {
                "name": "Bottesford",
                "countryCode": "GB"
            },
            {
                "name": "Bottisham",
                "countryCode": "GB"
            },
            {
                "name": "Boughton",
                "countryCode": "GB"
            },
            {
                "name": "Bourne",
                "countryCode": "GB"
            },
            {
                "name": "Bourne End",
                "countryCode": "GB"
            },
            {
                "name": "Bournemouth",
                "countryCode": "GB"
            },
            {
                "name": "Bourton",
                "countryCode": "GB"
            },
            {
                "name": "Bourton on the Water",
                "countryCode": "GB"
            },
            {
                "name": "Bovey Tracey",
                "countryCode": "GB"
            },
            {
                "name": "Bovingdon",
                "countryCode": "GB"
            },
            {
                "name": "Bovington Camp",
                "countryCode": "GB"
            },
            {
                "name": "Bow",
                "countryCode": "GB"
            },
            {
                "name": "Bow Brickhill",
                "countryCode": "GB"
            },
            {
                "name": "Bowburn",
                "countryCode": "GB"
            },
            {
                "name": "Bowdon",
                "countryCode": "GB"
            },
            {
                "name": "Bowthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Box",
                "countryCode": "GB"
            },
            {
                "name": "Boxgrove",
                "countryCode": "GB"
            },
            {
                "name": "Boxted",
                "countryCode": "GB"
            },
            {
                "name": "Bozeat",
                "countryCode": "GB"
            },
            {
                "name": "Bracebridge Heath",
                "countryCode": "GB"
            },
            {
                "name": "Brackley",
                "countryCode": "GB"
            },
            {
                "name": "Bracknell",
                "countryCode": "GB"
            },
            {
                "name": "Bracknell Forest",
                "countryCode": "GB"
            },
            {
                "name": "Bradfield",
                "countryCode": "GB"
            },
            {
                "name": "Bradford",
                "countryCode": "GB"
            },
            {
                "name": "Bradford-on-Avon",
                "countryCode": "GB"
            },
            {
                "name": "Brading",
                "countryCode": "GB"
            },
            {
                "name": "Bradley Cross",
                "countryCode": "GB"
            },
            {
                "name": "Bradninch",
                "countryCode": "GB"
            },
            {
                "name": "Bradwell",
                "countryCode": "GB"
            },
            {
                "name": "Braintree",
                "countryCode": "GB"
            },
            {
                "name": "Bramford",
                "countryCode": "GB"
            },
            {
                "name": "Bramhall",
                "countryCode": "GB"
            },
            {
                "name": "Bramham",
                "countryCode": "GB"
            },
            {
                "name": "Bramhope",
                "countryCode": "GB"
            },
            {
                "name": "Bramley",
                "countryCode": "GB"
            },
            {
                "name": "Brampton",
                "countryCode": "GB"
            },
            {
                "name": "Brandesburton",
                "countryCode": "GB"
            },
            {
                "name": "Brandon",
                "countryCode": "GB"
            },
            {
                "name": "Bransgore",
                "countryCode": "GB"
            },
            {
                "name": "Branston",
                "countryCode": "GB"
            },
            {
                "name": "Bratton",
                "countryCode": "GB"
            },
            {
                "name": "Braunston",
                "countryCode": "GB"
            },
            {
                "name": "Braunton",
                "countryCode": "GB"
            },
            {
                "name": "Brayton",
                "countryCode": "GB"
            },
            {
                "name": "Bream",
                "countryCode": "GB"
            },
            {
                "name": "Bredbury",
                "countryCode": "GB"
            },
            {
                "name": "Bredon",
                "countryCode": "GB"
            },
            {
                "name": "Brenchley",
                "countryCode": "GB"
            },
            {
                "name": "Brent",
                "countryCode": "GB"
            },
            {
                "name": "Brent Knoll",
                "countryCode": "GB"
            },
            {
                "name": "Brentford",
                "countryCode": "GB"
            },
            {
                "name": "Brentwood",
                "countryCode": "GB"
            },
            {
                "name": "Brewood",
                "countryCode": "GB"
            },
            {
                "name": "Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Bridgnorth",
                "countryCode": "GB"
            },
            {
                "name": "Bridgwater",
                "countryCode": "GB"
            },
            {
                "name": "Bridlington",
                "countryCode": "GB"
            },
            {
                "name": "Bridport",
                "countryCode": "GB"
            },
            {
                "name": "Brierfield",
                "countryCode": "GB"
            },
            {
                "name": "Brierley Hill",
                "countryCode": "GB"
            },
            {
                "name": "Brigg",
                "countryCode": "GB"
            },
            {
                "name": "Brighouse",
                "countryCode": "GB"
            },
            {
                "name": "Brighstone",
                "countryCode": "GB"
            },
            {
                "name": "Brightlingsea",
                "countryCode": "GB"
            },
            {
                "name": "Brighton",
                "countryCode": "GB"
            },
            {
                "name": "Brighton and Hove",
                "countryCode": "GB"
            },
            {
                "name": "Brigstock",
                "countryCode": "GB"
            },
            {
                "name": "Brill",
                "countryCode": "GB"
            },
            {
                "name": "Brimscombe",
                "countryCode": "GB"
            },
            {
                "name": "Brinklow",
                "countryCode": "GB"
            },
            {
                "name": "Brinscall",
                "countryCode": "GB"
            },
            {
                "name": "Bristol",
                "countryCode": "GB"
            },
            {
                "name": "Briston",
                "countryCode": "GB"
            },
            {
                "name": "Brixham",
                "countryCode": "GB"
            },
            {
                "name": "Brixton",
                "countryCode": "GB"
            },
            {
                "name": "Brixton Hill",
                "countryCode": "GB"
            },
            {
                "name": "Brixworth",
                "countryCode": "GB"
            },
            {
                "name": "Broad Blunsdon",
                "countryCode": "GB"
            },
            {
                "name": "Broadfield",
                "countryCode": "GB"
            },
            {
                "name": "Broadstairs",
                "countryCode": "GB"
            },
            {
                "name": "Broadstone",
                "countryCode": "GB"
            },
            {
                "name": "Broadwater",
                "countryCode": "GB"
            },
            {
                "name": "Broadway",
                "countryCode": "GB"
            },
            {
                "name": "Brockenhurst",
                "countryCode": "GB"
            },
            {
                "name": "Brockley",
                "countryCode": "GB"
            },
            {
                "name": "Bromborough",
                "countryCode": "GB"
            },
            {
                "name": "Bromham",
                "countryCode": "GB"
            },
            {
                "name": "Brompton",
                "countryCode": "GB"
            },
            {
                "name": "Bromsgrove",
                "countryCode": "GB"
            },
            {
                "name": "Bromyard",
                "countryCode": "GB"
            },
            {
                "name": "Brooke",
                "countryCode": "GB"
            },
            {
                "name": "Broseley",
                "countryCode": "GB"
            },
            {
                "name": "Brotton",
                "countryCode": "GB"
            },
            {
                "name": "Brough",
                "countryCode": "GB"
            },
            {
                "name": "Broughton",
                "countryCode": "GB"
            },
            {
                "name": "Broughton Astley",
                "countryCode": "GB"
            },
            {
                "name": "Brownhills",
                "countryCode": "GB"
            },
            {
                "name": "Broxbourne",
                "countryCode": "GB"
            },
            {
                "name": "Brundall",
                "countryCode": "GB"
            },
            {
                "name": "Bruton",
                "countryCode": "GB"
            },
            {
                "name": "Bubwith",
                "countryCode": "GB"
            },
            {
                "name": "Buckden",
                "countryCode": "GB"
            },
            {
                "name": "Buckfastleigh",
                "countryCode": "GB"
            },
            {
                "name": "Buckhurst Hill",
                "countryCode": "GB"
            },
            {
                "name": "Buckingham",
                "countryCode": "GB"
            },
            {
                "name": "Buckinghamshire",
                "countryCode": "GB"
            },
            {
                "name": "Bucknell",
                "countryCode": "GB"
            },
            {
                "name": "Bude",
                "countryCode": "GB"
            },
            {
                "name": "Budleigh Salterton",
                "countryCode": "GB"
            },
            {
                "name": "Bugbrooke",
                "countryCode": "GB"
            },
            {
                "name": "Bugle",
                "countryCode": "GB"
            },
            {
                "name": "Bulford",
                "countryCode": "GB"
            },
            {
                "name": "Bulkington",
                "countryCode": "GB"
            },
            {
                "name": "Bulphan",
                "countryCode": "GB"
            },
            {
                "name": "Bunbury",
                "countryCode": "GB"
            },
            {
                "name": "Bungay",
                "countryCode": "GB"
            },
            {
                "name": "Buntingford",
                "countryCode": "GB"
            },
            {
                "name": "Burbage",
                "countryCode": "GB"
            },
            {
                "name": "Bures Saint Mary",
                "countryCode": "GB"
            },
            {
                "name": "Burford",
                "countryCode": "GB"
            },
            {
                "name": "Burgess Hill",
                "countryCode": "GB"
            },
            {
                "name": "Burgh le Marsh",
                "countryCode": "GB"
            },
            {
                "name": "Burham",
                "countryCode": "GB"
            },
            {
                "name": "Burley",
                "countryCode": "GB"
            },
            {
                "name": "Burley in Wharfedale",
                "countryCode": "GB"
            },
            {
                "name": "Burnage",
                "countryCode": "GB"
            },
            {
                "name": "Burneside",
                "countryCode": "GB"
            },
            {
                "name": "Burngreave",
                "countryCode": "GB"
            },
            {
                "name": "Burnham-on-Crouch",
                "countryCode": "GB"
            },
            {
                "name": "Burnham-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Burniston",
                "countryCode": "GB"
            },
            {
                "name": "Burnley",
                "countryCode": "GB"
            },
            {
                "name": "Burnopfield",
                "countryCode": "GB"
            },
            {
                "name": "Burntwood",
                "countryCode": "GB"
            },
            {
                "name": "Burringham",
                "countryCode": "GB"
            },
            {
                "name": "Burrington",
                "countryCode": "GB"
            },
            {
                "name": "Burscough",
                "countryCode": "GB"
            },
            {
                "name": "Bursledon",
                "countryCode": "GB"
            },
            {
                "name": "Burstwick",
                "countryCode": "GB"
            },
            {
                "name": "Burton",
                "countryCode": "GB"
            },
            {
                "name": "Burton Joyce",
                "countryCode": "GB"
            },
            {
                "name": "Burton Latimer",
                "countryCode": "GB"
            },
            {
                "name": "Burton Pidsea",
                "countryCode": "GB"
            },
            {
                "name": "Burton on the Wolds",
                "countryCode": "GB"
            },
            {
                "name": "Burton upon Stather",
                "countryCode": "GB"
            },
            {
                "name": "Burton upon Trent",
                "countryCode": "GB"
            },
            {
                "name": "Burtonwood",
                "countryCode": "GB"
            },
            {
                "name": "Burwash",
                "countryCode": "GB"
            },
            {
                "name": "Burwell",
                "countryCode": "GB"
            },
            {
                "name": "Bury",
                "countryCode": "GB"
            },
            {
                "name": "Bury St Edmunds",
                "countryCode": "GB"
            },
            {
                "name": "Bushey",
                "countryCode": "GB"
            },
            {
                "name": "Butterwick",
                "countryCode": "GB"
            },
            {
                "name": "Buxted",
                "countryCode": "GB"
            },
            {
                "name": "Buxton",
                "countryCode": "GB"
            },
            {
                "name": "Byfield",
                "countryCode": "GB"
            },
            {
                "name": "Byram",
                "countryCode": "GB"
            },
            {
                "name": "Caddington",
                "countryCode": "GB"
            },
            {
                "name": "Cadnam",
                "countryCode": "GB"
            },
            {
                "name": "Caister-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Caistor",
                "countryCode": "GB"
            },
            {
                "name": "Calcot",
                "countryCode": "GB"
            },
            {
                "name": "Calderdale",
                "countryCode": "GB"
            },
            {
                "name": "Callington",
                "countryCode": "GB"
            },
            {
                "name": "Calne",
                "countryCode": "GB"
            },
            {
                "name": "Calverton",
                "countryCode": "GB"
            },
            {
                "name": "Camber",
                "countryCode": "GB"
            },
            {
                "name": "Camberley",
                "countryCode": "GB"
            },
            {
                "name": "Camblesforth",
                "countryCode": "GB"
            },
            {
                "name": "Camborne",
                "countryCode": "GB"
            },
            {
                "name": "Cambourne",
                "countryCode": "GB"
            },
            {
                "name": "Cambridge",
                "countryCode": "GB"
            },
            {
                "name": "Cambridgeshire",
                "countryCode": "GB"
            },
            {
                "name": "Camden Town",
                "countryCode": "GB"
            },
            {
                "name": "Cameley",
                "countryCode": "GB"
            },
            {
                "name": "Camelford",
                "countryCode": "GB"
            },
            {
                "name": "Campsall",
                "countryCode": "GB"
            },
            {
                "name": "Canary Wharf",
                "countryCode": "GB"
            },
            {
                "name": "Canewdon",
                "countryCode": "GB"
            },
            {
                "name": "Canford Heath",
                "countryCode": "GB"
            },
            {
                "name": "Cannock",
                "countryCode": "GB"
            },
            {
                "name": "Canterbury",
                "countryCode": "GB"
            },
            {
                "name": "Canvey Island",
                "countryCode": "GB"
            },
            {
                "name": "Capel",
                "countryCode": "GB"
            },
            {
                "name": "Capel Saint Mary",
                "countryCode": "GB"
            },
            {
                "name": "Capel le Ferne",
                "countryCode": "GB"
            },
            {
                "name": "Carcroft",
                "countryCode": "GB"
            },
            {
                "name": "Cardington",
                "countryCode": "GB"
            },
            {
                "name": "Carlisle",
                "countryCode": "GB"
            },
            {
                "name": "Carlton",
                "countryCode": "GB"
            },
            {
                "name": "Carnforth",
                "countryCode": "GB"
            },
            {
                "name": "Carshalton",
                "countryCode": "GB"
            },
            {
                "name": "Carterton",
                "countryCode": "GB"
            },
            {
                "name": "Castle Cary",
                "countryCode": "GB"
            },
            {
                "name": "Castle Donington",
                "countryCode": "GB"
            },
            {
                "name": "Castle Hedingham",
                "countryCode": "GB"
            },
            {
                "name": "Castle Vale",
                "countryCode": "GB"
            },
            {
                "name": "Castleford",
                "countryCode": "GB"
            },
            {
                "name": "Castleside",
                "countryCode": "GB"
            },
            {
                "name": "Castor",
                "countryCode": "GB"
            },
            {
                "name": "Catcliffe",
                "countryCode": "GB"
            },
            {
                "name": "Caterham",
                "countryCode": "GB"
            },
            {
                "name": "Caton",
                "countryCode": "GB"
            },
            {
                "name": "Catterall",
                "countryCode": "GB"
            },
            {
                "name": "Catterick",
                "countryCode": "GB"
            },
            {
                "name": "Catterick Garrison",
                "countryCode": "GB"
            },
            {
                "name": "Cawood",
                "countryCode": "GB"
            },
            {
                "name": "Cawston",
                "countryCode": "GB"
            },
            {
                "name": "Cawthorne",
                "countryCode": "GB"
            },
            {
                "name": "Caythorpe",
                "countryCode": "GB"
            },
            {
                "name": "Central Bedfordshire",
                "countryCode": "GB"
            },
            {
                "name": "Chacewater",
                "countryCode": "GB"
            },
            {
                "name": "Chadwell Heath",
                "countryCode": "GB"
            },
            {
                "name": "Chadwell St Mary",
                "countryCode": "GB"
            },
            {
                "name": "Chafford Hundred",
                "countryCode": "GB"
            },
            {
                "name": "Chagford",
                "countryCode": "GB"
            },
            {
                "name": "Chalfont Saint Peter",
                "countryCode": "GB"
            },
            {
                "name": "Chalfont St Giles",
                "countryCode": "GB"
            },
            {
                "name": "Chalford",
                "countryCode": "GB"
            },
            {
                "name": "Chalgrove",
                "countryCode": "GB"
            },
            {
                "name": "Chalton",
                "countryCode": "GB"
            },
            {
                "name": "Chapel Allerton",
                "countryCode": "GB"
            },
            {
                "name": "Chapel Saint Leonards",
                "countryCode": "GB"
            },
            {
                "name": "Chapel en le Frith",
                "countryCode": "GB"
            },
            {
                "name": "Chapeltown",
                "countryCode": "GB"
            },
            {
                "name": "Chapmanslade",
                "countryCode": "GB"
            },
            {
                "name": "Chard",
                "countryCode": "GB"
            },
            {
                "name": "Charfield",
                "countryCode": "GB"
            },
            {
                "name": "Charing",
                "countryCode": "GB"
            },
            {
                "name": "Charlbury",
                "countryCode": "GB"
            },
            {
                "name": "Charlton Kings",
                "countryCode": "GB"
            },
            {
                "name": "Charlton Marshall",
                "countryCode": "GB"
            },
            {
                "name": "Charminster",
                "countryCode": "GB"
            },
            {
                "name": "Charmouth",
                "countryCode": "GB"
            },
            {
                "name": "Chartham",
                "countryCode": "GB"
            },
            {
                "name": "Charvil",
                "countryCode": "GB"
            },
            {
                "name": "Chasetown",
                "countryCode": "GB"
            },
            {
                "name": "Chatburn",
                "countryCode": "GB"
            },
            {
                "name": "Chatham",
                "countryCode": "GB"
            },
            {
                "name": "Chatteris",
                "countryCode": "GB"
            },
            {
                "name": "Cheadle",
                "countryCode": "GB"
            },
            {
                "name": "Cheadle Heath",
                "countryCode": "GB"
            },
            {
                "name": "Cheadle Hulme",
                "countryCode": "GB"
            },
            {
                "name": "Cheam",
                "countryCode": "GB"
            },
            {
                "name": "Cheddar",
                "countryCode": "GB"
            },
            {
                "name": "Cheddington",
                "countryCode": "GB"
            },
            {
                "name": "Cheddleton",
                "countryCode": "GB"
            },
            {
                "name": "Cheetham Hill",
                "countryCode": "GB"
            },
            {
                "name": "Chelford",
                "countryCode": "GB"
            },
            {
                "name": "Chelmsford",
                "countryCode": "GB"
            },
            {
                "name": "Chelmsley Wood",
                "countryCode": "GB"
            },
            {
                "name": "Chelsea",
                "countryCode": "GB"
            },
            {
                "name": "Cheltenham",
                "countryCode": "GB"
            },
            {
                "name": "Cherry Burton",
                "countryCode": "GB"
            },
            {
                "name": "Chertsey",
                "countryCode": "GB"
            },
            {
                "name": "Chesham",
                "countryCode": "GB"
            },
            {
                "name": "Cheshire East",
                "countryCode": "GB"
            },
            {
                "name": "Cheshire West and Chester",
                "countryCode": "GB"
            },
            {
                "name": "Cheshunt",
                "countryCode": "GB"
            },
            {
                "name": "Chessington",
                "countryCode": "GB"
            },
            {
                "name": "Chester",
                "countryCode": "GB"
            },
            {
                "name": "Chester-le-Street",
                "countryCode": "GB"
            },
            {
                "name": "Chesterfield",
                "countryCode": "GB"
            },
            {
                "name": "Chew Magna",
                "countryCode": "GB"
            },
            {
                "name": "Chichester",
                "countryCode": "GB"
            },
            {
                "name": "Chickerell",
                "countryCode": "GB"
            },
            {
                "name": "Chicksands",
                "countryCode": "GB"
            },
            {
                "name": "Chiddingfold",
                "countryCode": "GB"
            },
            {
                "name": "Chigwell",
                "countryCode": "GB"
            },
            {
                "name": "Chilcompton",
                "countryCode": "GB"
            },
            {
                "name": "Child Okeford",
                "countryCode": "GB"
            },
            {
                "name": "Chilton Foliat",
                "countryCode": "GB"
            },
            {
                "name": "Chilworth",
                "countryCode": "GB"
            },
            {
                "name": "Chinley",
                "countryCode": "GB"
            },
            {
                "name": "Chinnor",
                "countryCode": "GB"
            },
            {
                "name": "Chippenham",
                "countryCode": "GB"
            },
            {
                "name": "Chipping Campden",
                "countryCode": "GB"
            },
            {
                "name": "Chipping Norton",
                "countryCode": "GB"
            },
            {
                "name": "Chipping Ongar",
                "countryCode": "GB"
            },
            {
                "name": "Chipping Sodbury",
                "countryCode": "GB"
            },
            {
                "name": "Chiseldon",
                "countryCode": "GB"
            },
            {
                "name": "Chislehurst",
                "countryCode": "GB"
            },
            {
                "name": "Chobham",
                "countryCode": "GB"
            },
            {
                "name": "Cholsey",
                "countryCode": "GB"
            },
            {
                "name": "Choppington",
                "countryCode": "GB"
            },
            {
                "name": "Chopwell",
                "countryCode": "GB"
            },
            {
                "name": "Chorley",
                "countryCode": "GB"
            },
            {
                "name": "Chorleywood",
                "countryCode": "GB"
            },
            {
                "name": "Chorlton",
                "countryCode": "GB"
            },
            {
                "name": "Chorlton cum Hardy",
                "countryCode": "GB"
            },
            {
                "name": "Christchurch",
                "countryCode": "GB"
            },
            {
                "name": "Chudleigh",
                "countryCode": "GB"
            },
            {
                "name": "Chudleigh Knighton",
                "countryCode": "GB"
            },
            {
                "name": "Chulmleigh",
                "countryCode": "GB"
            },
            {
                "name": "Church",
                "countryCode": "GB"
            },
            {
                "name": "Church Fenton",
                "countryCode": "GB"
            },
            {
                "name": "Church Stretton",
                "countryCode": "GB"
            },
            {
                "name": "Churchdown",
                "countryCode": "GB"
            },
            {
                "name": "Churchill",
                "countryCode": "GB"
            },
            {
                "name": "Churt",
                "countryCode": "GB"
            },
            {
                "name": "Cinderford",
                "countryCode": "GB"
            },
            {
                "name": "Cirencester",
                "countryCode": "GB"
            },
            {
                "name": "City and Borough of Birmingham",
                "countryCode": "GB"
            },
            {
                "name": "City and Borough of Leeds",
                "countryCode": "GB"
            },
            {
                "name": "City and Borough of Salford",
                "countryCode": "GB"
            },
            {
                "name": "City and Borough of Wakefield",
                "countryCode": "GB"
            },
            {
                "name": "City of Bristol",
                "countryCode": "GB"
            },
            {
                "name": "City of Kingston upon Hull",
                "countryCode": "GB"
            },
            {
                "name": "City of Leicester",
                "countryCode": "GB"
            },
            {
                "name": "City of London",
                "countryCode": "GB"
            },
            {
                "name": "City of Westminster",
                "countryCode": "GB"
            },
            {
                "name": "City of York",
                "countryCode": "GB"
            },
            {
                "name": "Clacton-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Clapham",
                "countryCode": "GB"
            },
            {
                "name": "Clarborough",
                "countryCode": "GB"
            },
            {
                "name": "Clare",
                "countryCode": "GB"
            },
            {
                "name": "Claydon",
                "countryCode": "GB"
            },
            {
                "name": "Claypole",
                "countryCode": "GB"
            },
            {
                "name": "Clayton West",
                "countryCode": "GB"
            },
            {
                "name": "Clayton le Moors",
                "countryCode": "GB"
            },
            {
                "name": "Clayton-le-Woods",
                "countryCode": "GB"
            },
            {
                "name": "Cleator Moor",
                "countryCode": "GB"
            },
            {
                "name": "Cleckheaton",
                "countryCode": "GB"
            },
            {
                "name": "Cleethorpes",
                "countryCode": "GB"
            },
            {
                "name": "Clenchwarton",
                "countryCode": "GB"
            },
            {
                "name": "Cleobury Mortimer",
                "countryCode": "GB"
            },
            {
                "name": "Clerkenwell",
                "countryCode": "GB"
            },
            {
                "name": "Clevedon",
                "countryCode": "GB"
            },
            {
                "name": "Cleveleys",
                "countryCode": "GB"
            },
            {
                "name": "Cliffe",
                "countryCode": "GB"
            },
            {
                "name": "Clifton",
                "countryCode": "GB"
            },
            {
                "name": "Clitheroe",
                "countryCode": "GB"
            },
            {
                "name": "Clive",
                "countryCode": "GB"
            },
            {
                "name": "Clophill",
                "countryCode": "GB"
            },
            {
                "name": "Clowne",
                "countryCode": "GB"
            },
            {
                "name": "Clutton",
                "countryCode": "GB"
            },
            {
                "name": "Coalville",
                "countryCode": "GB"
            },
            {
                "name": "Coates",
                "countryCode": "GB"
            },
            {
                "name": "Cobham",
                "countryCode": "GB"
            },
            {
                "name": "Cockermouth",
                "countryCode": "GB"
            },
            {
                "name": "Cockfield",
                "countryCode": "GB"
            },
            {
                "name": "Cockington",
                "countryCode": "GB"
            },
            {
                "name": "Codicote",
                "countryCode": "GB"
            },
            {
                "name": "Codsall",
                "countryCode": "GB"
            },
            {
                "name": "Cogenhoe",
                "countryCode": "GB"
            },
            {
                "name": "Coggeshall",
                "countryCode": "GB"
            },
            {
                "name": "Colchester",
                "countryCode": "GB"
            },
            {
                "name": "Cold Ash",
                "countryCode": "GB"
            },
            {
                "name": "Cold Norton",
                "countryCode": "GB"
            },
            {
                "name": "Colden Common",
                "countryCode": "GB"
            },
            {
                "name": "Coleford",
                "countryCode": "GB"
            },
            {
                "name": "Colerne",
                "countryCode": "GB"
            },
            {
                "name": "Collier Row",
                "countryCode": "GB"
            },
            {
                "name": "Collingbourne Kingston",
                "countryCode": "GB"
            },
            {
                "name": "Collingham",
                "countryCode": "GB"
            },
            {
                "name": "Colnbrook",
                "countryCode": "GB"
            },
            {
                "name": "Colne",
                "countryCode": "GB"
            },
            {
                "name": "Colsterworth",
                "countryCode": "GB"
            },
            {
                "name": "Coltishall",
                "countryCode": "GB"
            },
            {
                "name": "Colwich",
                "countryCode": "GB"
            },
            {
                "name": "Colyton",
                "countryCode": "GB"
            },
            {
                "name": "Combe Martin",
                "countryCode": "GB"
            },
            {
                "name": "Comberton",
                "countryCode": "GB"
            },
            {
                "name": "Compton",
                "countryCode": "GB"
            },
            {
                "name": "Compton Martin",
                "countryCode": "GB"
            },
            {
                "name": "Congleton",
                "countryCode": "GB"
            },
            {
                "name": "Congresbury",
                "countryCode": "GB"
            },
            {
                "name": "Coningsby",
                "countryCode": "GB"
            },
            {
                "name": "Conisbrough",
                "countryCode": "GB"
            },
            {
                "name": "Consett",
                "countryCode": "GB"
            },
            {
                "name": "Cookham",
                "countryCode": "GB"
            },
            {
                "name": "Cookley",
                "countryCode": "GB"
            },
            {
                "name": "Cople",
                "countryCode": "GB"
            },
            {
                "name": "Copmanthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Copplestone",
                "countryCode": "GB"
            },
            {
                "name": "Coppull",
                "countryCode": "GB"
            },
            {
                "name": "Copthorne",
                "countryCode": "GB"
            },
            {
                "name": "Corbridge",
                "countryCode": "GB"
            },
            {
                "name": "Corby",
                "countryCode": "GB"
            },
            {
                "name": "Corby Glen",
                "countryCode": "GB"
            },
            {
                "name": "Corfe Castle",
                "countryCode": "GB"
            },
            {
                "name": "Cornholme",
                "countryCode": "GB"
            },
            {
                "name": "Cornwall",
                "countryCode": "GB"
            },
            {
                "name": "Corse",
                "countryCode": "GB"
            },
            {
                "name": "Corsham",
                "countryCode": "GB"
            },
            {
                "name": "Corston",
                "countryCode": "GB"
            },
            {
                "name": "Cosby",
                "countryCode": "GB"
            },
            {
                "name": "Cosham",
                "countryCode": "GB"
            },
            {
                "name": "Costessey",
                "countryCode": "GB"
            },
            {
                "name": "Cotgrave",
                "countryCode": "GB"
            },
            {
                "name": "Cottenham",
                "countryCode": "GB"
            },
            {
                "name": "Cottesmore",
                "countryCode": "GB"
            },
            {
                "name": "Cottingham",
                "countryCode": "GB"
            },
            {
                "name": "Coulsdon",
                "countryCode": "GB"
            },
            {
                "name": "Coundon",
                "countryCode": "GB"
            },
            {
                "name": "Countesthorpe",
                "countryCode": "GB"
            },
            {
                "name": "County Durham",
                "countryCode": "GB"
            },
            {
                "name": "Coven",
                "countryCode": "GB"
            },
            {
                "name": "Coventry",
                "countryCode": "GB"
            },
            {
                "name": "Cowbit",
                "countryCode": "GB"
            },
            {
                "name": "Cowes",
                "countryCode": "GB"
            },
            {
                "name": "Cowfold",
                "countryCode": "GB"
            },
            {
                "name": "Cowley",
                "countryCode": "GB"
            },
            {
                "name": "Cowplain",
                "countryCode": "GB"
            },
            {
                "name": "Coxhoe",
                "countryCode": "GB"
            },
            {
                "name": "Cradley Heath",
                "countryCode": "GB"
            },
            {
                "name": "Cramlington",
                "countryCode": "GB"
            },
            {
                "name": "Cranbrook",
                "countryCode": "GB"
            },
            {
                "name": "Cranfield",
                "countryCode": "GB"
            },
            {
                "name": "Cranham",
                "countryCode": "GB"
            },
            {
                "name": "Cranleigh",
                "countryCode": "GB"
            },
            {
                "name": "Cranwell",
                "countryCode": "GB"
            },
            {
                "name": "Craven Arms",
                "countryCode": "GB"
            },
            {
                "name": "Crawley",
                "countryCode": "GB"
            },
            {
                "name": "Crawley Down",
                "countryCode": "GB"
            },
            {
                "name": "Credenhill",
                "countryCode": "GB"
            },
            {
                "name": "Crediton",
                "countryCode": "GB"
            },
            {
                "name": "Creech Saint Michael",
                "countryCode": "GB"
            },
            {
                "name": "Creswell",
                "countryCode": "GB"
            },
            {
                "name": "Crewe",
                "countryCode": "GB"
            },
            {
                "name": "Crewkerne",
                "countryCode": "GB"
            },
            {
                "name": "Crick",
                "countryCode": "GB"
            },
            {
                "name": "Cricklade",
                "countryCode": "GB"
            },
            {
                "name": "Cringleford",
                "countryCode": "GB"
            },
            {
                "name": "Croft",
                "countryCode": "GB"
            },
            {
                "name": "Crofton",
                "countryCode": "GB"
            },
            {
                "name": "Cromer",
                "countryCode": "GB"
            },
            {
                "name": "Cromford",
                "countryCode": "GB"
            },
            {
                "name": "Crondall",
                "countryCode": "GB"
            },
            {
                "name": "Crook",
                "countryCode": "GB"
            },
            {
                "name": "Cropwell Bishop",
                "countryCode": "GB"
            },
            {
                "name": "Crosby",
                "countryCode": "GB"
            },
            {
                "name": "Cross Hills",
                "countryCode": "GB"
            },
            {
                "name": "Croston",
                "countryCode": "GB"
            },
            {
                "name": "Crouch End",
                "countryCode": "GB"
            },
            {
                "name": "Crowborough",
                "countryCode": "GB"
            },
            {
                "name": "Crowland",
                "countryCode": "GB"
            },
            {
                "name": "Crowle",
                "countryCode": "GB"
            },
            {
                "name": "Crowthorne",
                "countryCode": "GB"
            },
            {
                "name": "Croydon",
                "countryCode": "GB"
            },
            {
                "name": "Crumpsall",
                "countryCode": "GB"
            },
            {
                "name": "Cuckfield",
                "countryCode": "GB"
            },
            {
                "name": "Cuddington",
                "countryCode": "GB"
            },
            {
                "name": "Cudworth",
                "countryCode": "GB"
            },
            {
                "name": "Cuffley",
                "countryCode": "GB"
            },
            {
                "name": "Culcheth",
                "countryCode": "GB"
            },
            {
                "name": "Cullingworth",
                "countryCode": "GB"
            },
            {
                "name": "Cullompton",
                "countryCode": "GB"
            },
            {
                "name": "Culverstone Green",
                "countryCode": "GB"
            },
            {
                "name": "Cumbria",
                "countryCode": "GB"
            },
            {
                "name": "Curdworth",
                "countryCode": "GB"
            },
            {
                "name": "Curry Rivel",
                "countryCode": "GB"
            },
            {
                "name": "Cuxton",
                "countryCode": "GB"
            },
            {
                "name": "Dagenham",
                "countryCode": "GB"
            },
            {
                "name": "Dalston",
                "countryCode": "GB"
            },
            {
                "name": "Dalton in Furness",
                "countryCode": "GB"
            },
            {
                "name": "Danbury",
                "countryCode": "GB"
            },
            {
                "name": "Danby",
                "countryCode": "GB"
            },
            {
                "name": "Darenth",
                "countryCode": "GB"
            },
            {
                "name": "Daresbury",
                "countryCode": "GB"
            },
            {
                "name": "Darfield",
                "countryCode": "GB"
            },
            {
                "name": "Darlaston",
                "countryCode": "GB"
            },
            {
                "name": "Darlington",
                "countryCode": "GB"
            },
            {
                "name": "Darras Hall",
                "countryCode": "GB"
            },
            {
                "name": "Darrington",
                "countryCode": "GB"
            },
            {
                "name": "Dartford",
                "countryCode": "GB"
            },
            {
                "name": "Dartmouth",
                "countryCode": "GB"
            },
            {
                "name": "Darton",
                "countryCode": "GB"
            },
            {
                "name": "Darwen",
                "countryCode": "GB"
            },
            {
                "name": "Datchet",
                "countryCode": "GB"
            },
            {
                "name": "Datchworth",
                "countryCode": "GB"
            },
            {
                "name": "Daventry",
                "countryCode": "GB"
            },
            {
                "name": "Dawlish",
                "countryCode": "GB"
            },
            {
                "name": "Deal",
                "countryCode": "GB"
            },
            {
                "name": "Deanshanger",
                "countryCode": "GB"
            },
            {
                "name": "Dearham",
                "countryCode": "GB"
            },
            {
                "name": "Debenham",
                "countryCode": "GB"
            },
            {
                "name": "Deddington",
                "countryCode": "GB"
            },
            {
                "name": "Dedham",
                "countryCode": "GB"
            },
            {
                "name": "Delabole",
                "countryCode": "GB"
            },
            {
                "name": "Delph",
                "countryCode": "GB"
            },
            {
                "name": "Denby Dale",
                "countryCode": "GB"
            },
            {
                "name": "Denham",
                "countryCode": "GB"
            },
            {
                "name": "Denholme",
                "countryCode": "GB"
            },
            {
                "name": "Denmead",
                "countryCode": "GB"
            },
            {
                "name": "Denton",
                "countryCode": "GB"
            },
            {
                "name": "Denton Holme",
                "countryCode": "GB"
            },
            {
                "name": "Derby",
                "countryCode": "GB"
            },
            {
                "name": "Derbyshire",
                "countryCode": "GB"
            },
            {
                "name": "Dersingham",
                "countryCode": "GB"
            },
            {
                "name": "Desborough",
                "countryCode": "GB"
            },
            {
                "name": "Desford",
                "countryCode": "GB"
            },
            {
                "name": "Devizes",
                "countryCode": "GB"
            },
            {
                "name": "Devon",
                "countryCode": "GB"
            },
            {
                "name": "Dewsbury",
                "countryCode": "GB"
            },
            {
                "name": "Dickens Heath",
                "countryCode": "GB"
            },
            {
                "name": "Dickleburgh",
                "countryCode": "GB"
            },
            {
                "name": "Didcot",
                "countryCode": "GB"
            },
            {
                "name": "Didsbury",
                "countryCode": "GB"
            },
            {
                "name": "Diggle",
                "countryCode": "GB"
            },
            {
                "name": "Dinnington",
                "countryCode": "GB"
            },
            {
                "name": "Dinton",
                "countryCode": "GB"
            },
            {
                "name": "Disley",
                "countryCode": "GB"
            },
            {
                "name": "Diss",
                "countryCode": "GB"
            },
            {
                "name": "Distington",
                "countryCode": "GB"
            },
            {
                "name": "District of Rutland",
                "countryCode": "GB"
            },
            {
                "name": "Ditchingham",
                "countryCode": "GB"
            },
            {
                "name": "Ditchling",
                "countryCode": "GB"
            },
            {
                "name": "Ditton Hill",
                "countryCode": "GB"
            },
            {
                "name": "Dobwalls",
                "countryCode": "GB"
            },
            {
                "name": "Doddington",
                "countryCode": "GB"
            },
            {
                "name": "Dodworth",
                "countryCode": "GB"
            },
            {
                "name": "Doncaster",
                "countryCode": "GB"
            },
            {
                "name": "Donington",
                "countryCode": "GB"
            },
            {
                "name": "Donisthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Donnington",
                "countryCode": "GB"
            },
            {
                "name": "Dorchester",
                "countryCode": "GB"
            },
            {
                "name": "Dorking",
                "countryCode": "GB"
            },
            {
                "name": "Dormansland",
                "countryCode": "GB"
            },
            {
                "name": "Dorridge",
                "countryCode": "GB"
            },
            {
                "name": "Dorset",
                "countryCode": "GB"
            },
            {
                "name": "Dorstone",
                "countryCode": "GB"
            },
            {
                "name": "Dove Holes",
                "countryCode": "GB"
            },
            {
                "name": "Dover",
                "countryCode": "GB"
            },
            {
                "name": "Dovercourt",
                "countryCode": "GB"
            },
            {
                "name": "Doveridge",
                "countryCode": "GB"
            },
            {
                "name": "Downham Market",
                "countryCode": "GB"
            },
            {
                "name": "Downton",
                "countryCode": "GB"
            },
            {
                "name": "Draycott",
                "countryCode": "GB"
            },
            {
                "name": "Driffield",
                "countryCode": "GB"
            },
            {
                "name": "Droitwich",
                "countryCode": "GB"
            },
            {
                "name": "Dronfield",
                "countryCode": "GB"
            },
            {
                "name": "Droylsden",
                "countryCode": "GB"
            },
            {
                "name": "Drybrook",
                "countryCode": "GB"
            },
            {
                "name": "Ducklington",
                "countryCode": "GB"
            },
            {
                "name": "Dudley",
                "countryCode": "GB"
            },
            {
                "name": "Duffield",
                "countryCode": "GB"
            },
            {
                "name": "Dukinfield",
                "countryCode": "GB"
            },
            {
                "name": "Dulverton",
                "countryCode": "GB"
            },
            {
                "name": "Dunchurch",
                "countryCode": "GB"
            },
            {
                "name": "Dundry",
                "countryCode": "GB"
            },
            {
                "name": "Dunholme",
                "countryCode": "GB"
            },
            {
                "name": "Dunkeswell",
                "countryCode": "GB"
            },
            {
                "name": "Dunnington",
                "countryCode": "GB"
            },
            {
                "name": "Dunstable",
                "countryCode": "GB"
            },
            {
                "name": "Dunswell",
                "countryCode": "GB"
            },
            {
                "name": "Durham",
                "countryCode": "GB"
            },
            {
                "name": "Dursley",
                "countryCode": "GB"
            },
            {
                "name": "Duxford",
                "countryCode": "GB"
            },
            {
                "name": "Dymchurch",
                "countryCode": "GB"
            },
            {
                "name": "Eaglescliffe",
                "countryCode": "GB"
            },
            {
                "name": "Earby",
                "countryCode": "GB"
            },
            {
                "name": "Earith",
                "countryCode": "GB"
            },
            {
                "name": "Earl Shilton",
                "countryCode": "GB"
            },
            {
                "name": "Earls Barton",
                "countryCode": "GB"
            },
            {
                "name": "Earls Colne",
                "countryCode": "GB"
            },
            {
                "name": "Earlsfield",
                "countryCode": "GB"
            },
            {
                "name": "Easington",
                "countryCode": "GB"
            },
            {
                "name": "Easingwold",
                "countryCode": "GB"
            },
            {
                "name": "East Ayton",
                "countryCode": "GB"
            },
            {
                "name": "East Bergholt",
                "countryCode": "GB"
            },
            {
                "name": "East Boldon",
                "countryCode": "GB"
            },
            {
                "name": "East Bridgford",
                "countryCode": "GB"
            },
            {
                "name": "East Chevington",
                "countryCode": "GB"
            },
            {
                "name": "East Cowes",
                "countryCode": "GB"
            },
            {
                "name": "East Dean",
                "countryCode": "GB"
            },
            {
                "name": "East Dereham",
                "countryCode": "GB"
            },
            {
                "name": "East Grinstead",
                "countryCode": "GB"
            },
            {
                "name": "East Hanney",
                "countryCode": "GB"
            },
            {
                "name": "East Harling",
                "countryCode": "GB"
            },
            {
                "name": "East Harptree",
                "countryCode": "GB"
            },
            {
                "name": "East Horsley",
                "countryCode": "GB"
            },
            {
                "name": "East Keswick",
                "countryCode": "GB"
            },
            {
                "name": "East Leake",
                "countryCode": "GB"
            },
            {
                "name": "East Markham",
                "countryCode": "GB"
            },
            {
                "name": "East Molesey",
                "countryCode": "GB"
            },
            {
                "name": "East Peckham",
                "countryCode": "GB"
            },
            {
                "name": "East Rainton",
                "countryCode": "GB"
            },
            {
                "name": "East Riding of Yorkshire",
                "countryCode": "GB"
            },
            {
                "name": "East Sussex",
                "countryCode": "GB"
            },
            {
                "name": "East Tilbury",
                "countryCode": "GB"
            },
            {
                "name": "East Wittering",
                "countryCode": "GB"
            },
            {
                "name": "Eastbourne",
                "countryCode": "GB"
            },
            {
                "name": "Eastchurch",
                "countryCode": "GB"
            },
            {
                "name": "Eastington",
                "countryCode": "GB"
            },
            {
                "name": "Eastleigh",
                "countryCode": "GB"
            },
            {
                "name": "Eastoft",
                "countryCode": "GB"
            },
            {
                "name": "Easton",
                "countryCode": "GB"
            },
            {
                "name": "Easton on the Hill",
                "countryCode": "GB"
            },
            {
                "name": "Easton-in-Gordano",
                "countryCode": "GB"
            },
            {
                "name": "Eastrington",
                "countryCode": "GB"
            },
            {
                "name": "Eastry",
                "countryCode": "GB"
            },
            {
                "name": "Eastwood",
                "countryCode": "GB"
            },
            {
                "name": "Eaton Bray",
                "countryCode": "GB"
            },
            {
                "name": "Eaton Socon",
                "countryCode": "GB"
            },
            {
                "name": "Eattington",
                "countryCode": "GB"
            },
            {
                "name": "Eccles",
                "countryCode": "GB"
            },
            {
                "name": "Eccleshall",
                "countryCode": "GB"
            },
            {
                "name": "Eccleston",
                "countryCode": "GB"
            },
            {
                "name": "Eckington",
                "countryCode": "GB"
            },
            {
                "name": "Edenbridge",
                "countryCode": "GB"
            },
            {
                "name": "Edenfield",
                "countryCode": "GB"
            },
            {
                "name": "Edgmond",
                "countryCode": "GB"
            },
            {
                "name": "Edgware",
                "countryCode": "GB"
            },
            {
                "name": "Edgworth",
                "countryCode": "GB"
            },
            {
                "name": "Edington",
                "countryCode": "GB"
            },
            {
                "name": "Edith Weston",
                "countryCode": "GB"
            },
            {
                "name": "Edwinstowe",
                "countryCode": "GB"
            },
            {
                "name": "Egham",
                "countryCode": "GB"
            },
            {
                "name": "Egremont",
                "countryCode": "GB"
            },
            {
                "name": "Eight Ash Green",
                "countryCode": "GB"
            },
            {
                "name": "Elland",
                "countryCode": "GB"
            },
            {
                "name": "Ellerker",
                "countryCode": "GB"
            },
            {
                "name": "Ellerton",
                "countryCode": "GB"
            },
            {
                "name": "Ellesmere",
                "countryCode": "GB"
            },
            {
                "name": "Ellesmere Port",
                "countryCode": "GB"
            },
            {
                "name": "Elm Park",
                "countryCode": "GB"
            },
            {
                "name": "Elmstead Market",
                "countryCode": "GB"
            },
            {
                "name": "Elmswell",
                "countryCode": "GB"
            },
            {
                "name": "Elsenham",
                "countryCode": "GB"
            },
            {
                "name": "Elstead",
                "countryCode": "GB"
            },
            {
                "name": "Elstree",
                "countryCode": "GB"
            },
            {
                "name": "Elswick",
                "countryCode": "GB"
            },
            {
                "name": "Elvington",
                "countryCode": "GB"
            },
            {
                "name": "Elwick",
                "countryCode": "GB"
            },
            {
                "name": "Ely",
                "countryCode": "GB"
            },
            {
                "name": "Emberton",
                "countryCode": "GB"
            },
            {
                "name": "Embleton",
                "countryCode": "GB"
            },
            {
                "name": "Embsay",
                "countryCode": "GB"
            },
            {
                "name": "Emley",
                "countryCode": "GB"
            },
            {
                "name": "Emneth",
                "countryCode": "GB"
            },
            {
                "name": "Empingham",
                "countryCode": "GB"
            },
            {
                "name": "Emsworth",
                "countryCode": "GB"
            },
            {
                "name": "Enderby",
                "countryCode": "GB"
            },
            {
                "name": "Enfield Town",
                "countryCode": "GB"
            },
            {
                "name": "Epping",
                "countryCode": "GB"
            },
            {
                "name": "Epsom",
                "countryCode": "GB"
            },
            {
                "name": "Epworth",
                "countryCode": "GB"
            },
            {
                "name": "Erith",
                "countryCode": "GB"
            },
            {
                "name": "Esher",
                "countryCode": "GB"
            },
            {
                "name": "Essendine",
                "countryCode": "GB"
            },
            {
                "name": "Essex",
                "countryCode": "GB"
            },
            {
                "name": "Essington",
                "countryCode": "GB"
            },
            {
                "name": "Eton",
                "countryCode": "GB"
            },
            {
                "name": "Eton Wick",
                "countryCode": "GB"
            },
            {
                "name": "Etton",
                "countryCode": "GB"
            },
            {
                "name": "Etwall",
                "countryCode": "GB"
            },
            {
                "name": "Euxton",
                "countryCode": "GB"
            },
            {
                "name": "Evenwood",
                "countryCode": "GB"
            },
            {
                "name": "Evercreech",
                "countryCode": "GB"
            },
            {
                "name": "Eversholt",
                "countryCode": "GB"
            },
            {
                "name": "Eversley",
                "countryCode": "GB"
            },
            {
                "name": "Everton",
                "countryCode": "GB"
            },
            {
                "name": "Evesham",
                "countryCode": "GB"
            },
            {
                "name": "Ewell",
                "countryCode": "GB"
            },
            {
                "name": "Ewhurst",
                "countryCode": "GB"
            },
            {
                "name": "Ewyas Harold",
                "countryCode": "GB"
            },
            {
                "name": "Exeter",
                "countryCode": "GB"
            },
            {
                "name": "Exhall",
                "countryCode": "GB"
            },
            {
                "name": "Exminster",
                "countryCode": "GB"
            },
            {
                "name": "Exmouth",
                "countryCode": "GB"
            },
            {
                "name": "Exning",
                "countryCode": "GB"
            },
            {
                "name": "Exton",
                "countryCode": "GB"
            },
            {
                "name": "Eye",
                "countryCode": "GB"
            },
            {
                "name": "Eynsford",
                "countryCode": "GB"
            },
            {
                "name": "Eynsham",
                "countryCode": "GB"
            },
            {
                "name": "Eythorne",
                "countryCode": "GB"
            },
            {
                "name": "Failsworth",
                "countryCode": "GB"
            },
            {
                "name": "Fairford",
                "countryCode": "GB"
            },
            {
                "name": "Fairlands",
                "countryCode": "GB"
            },
            {
                "name": "Fairlight",
                "countryCode": "GB"
            },
            {
                "name": "Fakenham",
                "countryCode": "GB"
            },
            {
                "name": "Fallowfield",
                "countryCode": "GB"
            },
            {
                "name": "Falmouth",
                "countryCode": "GB"
            },
            {
                "name": "Fareham",
                "countryCode": "GB"
            },
            {
                "name": "Faringdon",
                "countryCode": "GB"
            },
            {
                "name": "Farnborough",
                "countryCode": "GB"
            },
            {
                "name": "Farndon",
                "countryCode": "GB"
            },
            {
                "name": "Farnham",
                "countryCode": "GB"
            },
            {
                "name": "Farnham Royal",
                "countryCode": "GB"
            },
            {
                "name": "Farnsfield",
                "countryCode": "GB"
            },
            {
                "name": "Farnworth",
                "countryCode": "GB"
            },
            {
                "name": "Faversham",
                "countryCode": "GB"
            },
            {
                "name": "Fazeley",
                "countryCode": "GB"
            },
            {
                "name": "Featherstone",
                "countryCode": "GB"
            },
            {
                "name": "Felixstowe",
                "countryCode": "GB"
            },
            {
                "name": "Felling",
                "countryCode": "GB"
            },
            {
                "name": "Feltham",
                "countryCode": "GB"
            },
            {
                "name": "Felton",
                "countryCode": "GB"
            },
            {
                "name": "Feltwell",
                "countryCode": "GB"
            },
            {
                "name": "Fenstanton",
                "countryCode": "GB"
            },
            {
                "name": "Ferndown",
                "countryCode": "GB"
            },
            {
                "name": "Fernhill Heath",
                "countryCode": "GB"
            },
            {
                "name": "Fernhurst",
                "countryCode": "GB"
            },
            {
                "name": "Ferrybridge",
                "countryCode": "GB"
            },
            {
                "name": "Ferryhill",
                "countryCode": "GB"
            },
            {
                "name": "Filey",
                "countryCode": "GB"
            },
            {
                "name": "Finchampstead",
                "countryCode": "GB"
            },
            {
                "name": "Findern",
                "countryCode": "GB"
            },
            {
                "name": "Findon",
                "countryCode": "GB"
            },
            {
                "name": "Finedon",
                "countryCode": "GB"
            },
            {
                "name": "Finningley",
                "countryCode": "GB"
            },
            {
                "name": "Fishburn",
                "countryCode": "GB"
            },
            {
                "name": "Fishtoft",
                "countryCode": "GB"
            },
            {
                "name": "Fitzwilliam",
                "countryCode": "GB"
            },
            {
                "name": "Five Oak Green",
                "countryCode": "GB"
            },
            {
                "name": "Flamborough",
                "countryCode": "GB"
            },
            {
                "name": "Fleckney",
                "countryCode": "GB"
            },
            {
                "name": "Fleet",
                "countryCode": "GB"
            },
            {
                "name": "Fleetwood",
                "countryCode": "GB"
            },
            {
                "name": "Flexbury",
                "countryCode": "GB"
            },
            {
                "name": "Flimby",
                "countryCode": "GB"
            },
            {
                "name": "Flimwell",
                "countryCode": "GB"
            },
            {
                "name": "Flitwick",
                "countryCode": "GB"
            },
            {
                "name": "Flockton",
                "countryCode": "GB"
            },
            {
                "name": "Flookburgh",
                "countryCode": "GB"
            },
            {
                "name": "Flore",
                "countryCode": "GB"
            },
            {
                "name": "Folkestone",
                "countryCode": "GB"
            },
            {
                "name": "Fontwell",
                "countryCode": "GB"
            },
            {
                "name": "Ford",
                "countryCode": "GB"
            },
            {
                "name": "Fordham",
                "countryCode": "GB"
            },
            {
                "name": "Fordingbridge",
                "countryCode": "GB"
            },
            {
                "name": "Forest Row",
                "countryCode": "GB"
            },
            {
                "name": "Formby",
                "countryCode": "GB"
            },
            {
                "name": "Fortuneswell",
                "countryCode": "GB"
            },
            {
                "name": "Foulridge",
                "countryCode": "GB"
            },
            {
                "name": "Foulsham",
                "countryCode": "GB"
            },
            {
                "name": "Four Lanes",
                "countryCode": "GB"
            },
            {
                "name": "Four Marks",
                "countryCode": "GB"
            },
            {
                "name": "Fowey",
                "countryCode": "GB"
            },
            {
                "name": "Fowlmere",
                "countryCode": "GB"
            },
            {
                "name": "Framlingham",
                "countryCode": "GB"
            },
            {
                "name": "Frampton on Severn",
                "countryCode": "GB"
            },
            {
                "name": "Freckleton",
                "countryCode": "GB"
            },
            {
                "name": "Fremington",
                "countryCode": "GB"
            },
            {
                "name": "Freshwater",
                "countryCode": "GB"
            },
            {
                "name": "Frimley",
                "countryCode": "GB"
            },
            {
                "name": "Frinton-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Friston",
                "countryCode": "GB"
            },
            {
                "name": "Frizington",
                "countryCode": "GB"
            },
            {
                "name": "Frodsham",
                "countryCode": "GB"
            },
            {
                "name": "Frome",
                "countryCode": "GB"
            },
            {
                "name": "Fulbourn",
                "countryCode": "GB"
            },
            {
                "name": "Full Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Furnace Green",
                "countryCode": "GB"
            },
            {
                "name": "Fylde",
                "countryCode": "GB"
            },
            {
                "name": "Gainford",
                "countryCode": "GB"
            },
            {
                "name": "Gainsborough",
                "countryCode": "GB"
            },
            {
                "name": "Galgate",
                "countryCode": "GB"
            },
            {
                "name": "Gamlingay",
                "countryCode": "GB"
            },
            {
                "name": "Garforth",
                "countryCode": "GB"
            },
            {
                "name": "Gargrave",
                "countryCode": "GB"
            },
            {
                "name": "Garsington",
                "countryCode": "GB"
            },
            {
                "name": "Garstang",
                "countryCode": "GB"
            },
            {
                "name": "Gateshead",
                "countryCode": "GB"
            },
            {
                "name": "Geddington",
                "countryCode": "GB"
            },
            {
                "name": "Gedney Hill",
                "countryCode": "GB"
            },
            {
                "name": "Germoe",
                "countryCode": "GB"
            },
            {
                "name": "Gerrards Cross",
                "countryCode": "GB"
            },
            {
                "name": "Gilberdyke",
                "countryCode": "GB"
            },
            {
                "name": "Gillingham",
                "countryCode": "GB"
            },
            {
                "name": "Girton",
                "countryCode": "GB"
            },
            {
                "name": "Glapwell",
                "countryCode": "GB"
            },
            {
                "name": "Glastonbury",
                "countryCode": "GB"
            },
            {
                "name": "Glazebury",
                "countryCode": "GB"
            },
            {
                "name": "Glemsford",
                "countryCode": "GB"
            },
            {
                "name": "Glenfield",
                "countryCode": "GB"
            },
            {
                "name": "Glinton",
                "countryCode": "GB"
            },
            {
                "name": "Glossop",
                "countryCode": "GB"
            },
            {
                "name": "Gloucester",
                "countryCode": "GB"
            },
            {
                "name": "Gloucestershire",
                "countryCode": "GB"
            },
            {
                "name": "Glusburn",
                "countryCode": "GB"
            },
            {
                "name": "Gnosall",
                "countryCode": "GB"
            },
            {
                "name": "Gobowen",
                "countryCode": "GB"
            },
            {
                "name": "Godalming",
                "countryCode": "GB"
            },
            {
                "name": "Godmanchester",
                "countryCode": "GB"
            },
            {
                "name": "Godshill",
                "countryCode": "GB"
            },
            {
                "name": "Godstone",
                "countryCode": "GB"
            },
            {
                "name": "Golborne",
                "countryCode": "GB"
            },
            {
                "name": "Goole",
                "countryCode": "GB"
            },
            {
                "name": "Goosnargh",
                "countryCode": "GB"
            },
            {
                "name": "Goostrey",
                "countryCode": "GB"
            },
            {
                "name": "Goring",
                "countryCode": "GB"
            },
            {
                "name": "Goring-by-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Gorleston-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Gosberton",
                "countryCode": "GB"
            },
            {
                "name": "Gosfield",
                "countryCode": "GB"
            },
            {
                "name": "Gosforth",
                "countryCode": "GB"
            },
            {
                "name": "Gosport",
                "countryCode": "GB"
            },
            {
                "name": "Gossops Green",
                "countryCode": "GB"
            },
            {
                "name": "Gotham",
                "countryCode": "GB"
            },
            {
                "name": "Goudhurst",
                "countryCode": "GB"
            },
            {
                "name": "Goxhill",
                "countryCode": "GB"
            },
            {
                "name": "Grain",
                "countryCode": "GB"
            },
            {
                "name": "Grange Hill",
                "countryCode": "GB"
            },
            {
                "name": "Grange-over-Sands",
                "countryCode": "GB"
            },
            {
                "name": "Grantham",
                "countryCode": "GB"
            },
            {
                "name": "Grappenhall",
                "countryCode": "GB"
            },
            {
                "name": "Grassington",
                "countryCode": "GB"
            },
            {
                "name": "Gravesend",
                "countryCode": "GB"
            },
            {
                "name": "Grays",
                "countryCode": "GB"
            },
            {
                "name": "Greasby",
                "countryCode": "GB"
            },
            {
                "name": "Great Amwell",
                "countryCode": "GB"
            },
            {
                "name": "Great Ayton",
                "countryCode": "GB"
            },
            {
                "name": "Great Bardfield",
                "countryCode": "GB"
            },
            {
                "name": "Great Barford",
                "countryCode": "GB"
            },
            {
                "name": "Great Barton",
                "countryCode": "GB"
            },
            {
                "name": "Great Bedwyn",
                "countryCode": "GB"
            },
            {
                "name": "Great Bentley",
                "countryCode": "GB"
            },
            {
                "name": "Great Bookham",
                "countryCode": "GB"
            },
            {
                "name": "Great Chesterford",
                "countryCode": "GB"
            },
            {
                "name": "Great Dunmow",
                "countryCode": "GB"
            },
            {
                "name": "Great Eccleston",
                "countryCode": "GB"
            },
            {
                "name": "Great Glen",
                "countryCode": "GB"
            },
            {
                "name": "Great Gonerby",
                "countryCode": "GB"
            },
            {
                "name": "Great Gransden",
                "countryCode": "GB"
            },
            {
                "name": "Great Hanwood",
                "countryCode": "GB"
            },
            {
                "name": "Great Harwood",
                "countryCode": "GB"
            },
            {
                "name": "Great Haywood",
                "countryCode": "GB"
            },
            {
                "name": "Great Horkesley",
                "countryCode": "GB"
            },
            {
                "name": "Great Horwood",
                "countryCode": "GB"
            },
            {
                "name": "Great Houghton",
                "countryCode": "GB"
            },
            {
                "name": "Great Leighs",
                "countryCode": "GB"
            },
            {
                "name": "Great Malvern",
                "countryCode": "GB"
            },
            {
                "name": "Great Marton",
                "countryCode": "GB"
            },
            {
                "name": "Great Missenden",
                "countryCode": "GB"
            },
            {
                "name": "Great Paxton",
                "countryCode": "GB"
            },
            {
                "name": "Great Sankey",
                "countryCode": "GB"
            },
            {
                "name": "Great Torrington",
                "countryCode": "GB"
            },
            {
                "name": "Great Wakering",
                "countryCode": "GB"
            },
            {
                "name": "Great Waldingfield",
                "countryCode": "GB"
            },
            {
                "name": "Great Wyrley",
                "countryCode": "GB"
            },
            {
                "name": "Great Yarmouth",
                "countryCode": "GB"
            },
            {
                "name": "Great Yeldham",
                "countryCode": "GB"
            },
            {
                "name": "Greater London",
                "countryCode": "GB"
            },
            {
                "name": "Greatham",
                "countryCode": "GB"
            },
            {
                "name": "Greenfield",
                "countryCode": "GB"
            },
            {
                "name": "Greenford",
                "countryCode": "GB"
            },
            {
                "name": "Greenhill",
                "countryCode": "GB"
            },
            {
                "name": "Greenhithe",
                "countryCode": "GB"
            },
            {
                "name": "Greetham",
                "countryCode": "GB"
            },
            {
                "name": "Grimethorpe",
                "countryCode": "GB"
            },
            {
                "name": "Grimsby",
                "countryCode": "GB"
            },
            {
                "name": "Grimston",
                "countryCode": "GB"
            },
            {
                "name": "Griston",
                "countryCode": "GB"
            },
            {
                "name": "Groombridge",
                "countryCode": "GB"
            },
            {
                "name": "Grove",
                "countryCode": "GB"
            },
            {
                "name": "Grundisburgh",
                "countryCode": "GB"
            },
            {
                "name": "Guilden Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Guildford",
                "countryCode": "GB"
            },
            {
                "name": "Guisborough",
                "countryCode": "GB"
            },
            {
                "name": "Guiseley",
                "countryCode": "GB"
            },
            {
                "name": "Gunness",
                "countryCode": "GB"
            },
            {
                "name": "Gunnislake",
                "countryCode": "GB"
            },
            {
                "name": "Hackleton",
                "countryCode": "GB"
            },
            {
                "name": "Haddenham",
                "countryCode": "GB"
            },
            {
                "name": "Hadleigh",
                "countryCode": "GB"
            },
            {
                "name": "Hadley",
                "countryCode": "GB"
            },
            {
                "name": "Hadley Wood",
                "countryCode": "GB"
            },
            {
                "name": "Hadlow",
                "countryCode": "GB"
            },
            {
                "name": "Hadston",
                "countryCode": "GB"
            },
            {
                "name": "Hagley",
                "countryCode": "GB"
            },
            {
                "name": "Hailsham",
                "countryCode": "GB"
            },
            {
                "name": "Hainault",
                "countryCode": "GB"
            },
            {
                "name": "Hale",
                "countryCode": "GB"
            },
            {
                "name": "Halesowen",
                "countryCode": "GB"
            },
            {
                "name": "Halesworth",
                "countryCode": "GB"
            },
            {
                "name": "Halifax",
                "countryCode": "GB"
            },
            {
                "name": "Halling",
                "countryCode": "GB"
            },
            {
                "name": "Hallow",
                "countryCode": "GB"
            },
            {
                "name": "Halstead",
                "countryCode": "GB"
            },
            {
                "name": "Halton",
                "countryCode": "GB"
            },
            {
                "name": "Haltwhistle",
                "countryCode": "GB"
            },
            {
                "name": "Hamble-le-Rice",
                "countryCode": "GB"
            },
            {
                "name": "Hambleton",
                "countryCode": "GB"
            },
            {
                "name": "Hameldon Hill",
                "countryCode": "GB"
            },
            {
                "name": "Hampshire",
                "countryCode": "GB"
            },
            {
                "name": "Hampton",
                "countryCode": "GB"
            },
            {
                "name": "Hampton in Arden",
                "countryCode": "GB"
            },
            {
                "name": "Handcross",
                "countryCode": "GB"
            },
            {
                "name": "Hannington",
                "countryCode": "GB"
            },
            {
                "name": "Hanslope",
                "countryCode": "GB"
            },
            {
                "name": "Hapton",
                "countryCode": "GB"
            },
            {
                "name": "Harbury",
                "countryCode": "GB"
            },
            {
                "name": "Hardingstone",
                "countryCode": "GB"
            },
            {
                "name": "Hardwick Village",
                "countryCode": "GB"
            },
            {
                "name": "Harefield",
                "countryCode": "GB"
            },
            {
                "name": "Harleston",
                "countryCode": "GB"
            },
            {
                "name": "Harlington",
                "countryCode": "GB"
            },
            {
                "name": "Harlow",
                "countryCode": "GB"
            },
            {
                "name": "Harold Wood",
                "countryCode": "GB"
            },
            {
                "name": "Harpenden",
                "countryCode": "GB"
            },
            {
                "name": "Harpole",
                "countryCode": "GB"
            },
            {
                "name": "Harrietsham",
                "countryCode": "GB"
            },
            {
                "name": "Harringay",
                "countryCode": "GB"
            },
            {
                "name": "Harrogate",
                "countryCode": "GB"
            },
            {
                "name": "Harrold",
                "countryCode": "GB"
            },
            {
                "name": "Harrow on the Hill",
                "countryCode": "GB"
            },
            {
                "name": "Harston",
                "countryCode": "GB"
            },
            {
                "name": "Harthill",
                "countryCode": "GB"
            },
            {
                "name": "Hartlebury",
                "countryCode": "GB"
            },
            {
                "name": "Hartlepool",
                "countryCode": "GB"
            },
            {
                "name": "Hartley",
                "countryCode": "GB"
            },
            {
                "name": "Hartley Wintney",
                "countryCode": "GB"
            },
            {
                "name": "Hartshill",
                "countryCode": "GB"
            },
            {
                "name": "Hartwell",
                "countryCode": "GB"
            },
            {
                "name": "Harvington",
                "countryCode": "GB"
            },
            {
                "name": "Harwell",
                "countryCode": "GB"
            },
            {
                "name": "Harwich",
                "countryCode": "GB"
            },
            {
                "name": "Haslemere",
                "countryCode": "GB"
            },
            {
                "name": "Haslingden",
                "countryCode": "GB"
            },
            {
                "name": "Haslingfield",
                "countryCode": "GB"
            },
            {
                "name": "Hassocks",
                "countryCode": "GB"
            },
            {
                "name": "Hastings",
                "countryCode": "GB"
            },
            {
                "name": "Haswell",
                "countryCode": "GB"
            },
            {
                "name": "Hatfield",
                "countryCode": "GB"
            },
            {
                "name": "Hatfield Heath",
                "countryCode": "GB"
            },
            {
                "name": "Hatfield Peverel",
                "countryCode": "GB"
            },
            {
                "name": "Hatherleigh",
                "countryCode": "GB"
            },
            {
                "name": "Hathern",
                "countryCode": "GB"
            },
            {
                "name": "Hathersage",
                "countryCode": "GB"
            },
            {
                "name": "Hatton",
                "countryCode": "GB"
            },
            {
                "name": "Haughley",
                "countryCode": "GB"
            },
            {
                "name": "Haughton Green",
                "countryCode": "GB"
            },
            {
                "name": "Havant",
                "countryCode": "GB"
            },
            {
                "name": "Haverhill",
                "countryCode": "GB"
            },
            {
                "name": "Haverigg",
                "countryCode": "GB"
            },
            {
                "name": "Hawkhurst",
                "countryCode": "GB"
            },
            {
                "name": "Hawkinge",
                "countryCode": "GB"
            },
            {
                "name": "Haworth",
                "countryCode": "GB"
            },
            {
                "name": "Hawthorn",
                "countryCode": "GB"
            },
            {
                "name": "Haxby",
                "countryCode": "GB"
            },
            {
                "name": "Haxey",
                "countryCode": "GB"
            },
            {
                "name": "Haydock",
                "countryCode": "GB"
            },
            {
                "name": "Haydon Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Hayes",
                "countryCode": "GB"
            },
            {
                "name": "Hayfield",
                "countryCode": "GB"
            },
            {
                "name": "Hayle",
                "countryCode": "GB"
            },
            {
                "name": "Hayling Island",
                "countryCode": "GB"
            },
            {
                "name": "Haynes",
                "countryCode": "GB"
            },
            {
                "name": "Hayton",
                "countryCode": "GB"
            },
            {
                "name": "Haywards Heath",
                "countryCode": "GB"
            },
            {
                "name": "Hazel Grove",
                "countryCode": "GB"
            },
            {
                "name": "Hazlerigg",
                "countryCode": "GB"
            },
            {
                "name": "Heacham",
                "countryCode": "GB"
            },
            {
                "name": "Headcorn",
                "countryCode": "GB"
            },
            {
                "name": "Heage",
                "countryCode": "GB"
            },
            {
                "name": "Healing",
                "countryCode": "GB"
            },
            {
                "name": "Heanor",
                "countryCode": "GB"
            },
            {
                "name": "Heath and Reach",
                "countryCode": "GB"
            },
            {
                "name": "Heathfield",
                "countryCode": "GB"
            },
            {
                "name": "Heaton Chapel",
                "countryCode": "GB"
            },
            {
                "name": "Heavitree",
                "countryCode": "GB"
            },
            {
                "name": "Hebburn",
                "countryCode": "GB"
            },
            {
                "name": "Hebden Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Heckington",
                "countryCode": "GB"
            },
            {
                "name": "Heckmondwike",
                "countryCode": "GB"
            },
            {
                "name": "Heddon on the Wall",
                "countryCode": "GB"
            },
            {
                "name": "Hedge End",
                "countryCode": "GB"
            },
            {
                "name": "Hedon",
                "countryCode": "GB"
            },
            {
                "name": "Heighington",
                "countryCode": "GB"
            },
            {
                "name": "Hellaby",
                "countryCode": "GB"
            },
            {
                "name": "Hellifield",
                "countryCode": "GB"
            },
            {
                "name": "Helmsley",
                "countryCode": "GB"
            },
            {
                "name": "Helpston",
                "countryCode": "GB"
            },
            {
                "name": "Helsby",
                "countryCode": "GB"
            },
            {
                "name": "Helston",
                "countryCode": "GB"
            },
            {
                "name": "Hemel Hempstead",
                "countryCode": "GB"
            },
            {
                "name": "Hemingbrough",
                "countryCode": "GB"
            },
            {
                "name": "Hemingford Grey",
                "countryCode": "GB"
            },
            {
                "name": "Hemsby",
                "countryCode": "GB"
            },
            {
                "name": "Hemsworth",
                "countryCode": "GB"
            },
            {
                "name": "Hemyock",
                "countryCode": "GB"
            },
            {
                "name": "Henfield",
                "countryCode": "GB"
            },
            {
                "name": "Henley in Arden",
                "countryCode": "GB"
            },
            {
                "name": "Henley-on-Thames",
                "countryCode": "GB"
            },
            {
                "name": "Henlow",
                "countryCode": "GB"
            },
            {
                "name": "Henstridge",
                "countryCode": "GB"
            },
            {
                "name": "Hereford",
                "countryCode": "GB"
            },
            {
                "name": "Herefordshire",
                "countryCode": "GB"
            },
            {
                "name": "Hermitage",
                "countryCode": "GB"
            },
            {
                "name": "Herne Bay",
                "countryCode": "GB"
            },
            {
                "name": "Herstmonceux",
                "countryCode": "GB"
            },
            {
                "name": "Hertford",
                "countryCode": "GB"
            },
            {
                "name": "Hertfordshire",
                "countryCode": "GB"
            },
            {
                "name": "Heswall",
                "countryCode": "GB"
            },
            {
                "name": "Hethersett",
                "countryCode": "GB"
            },
            {
                "name": "Hetton-Le-Hole",
                "countryCode": "GB"
            },
            {
                "name": "Hexham",
                "countryCode": "GB"
            },
            {
                "name": "Heysham",
                "countryCode": "GB"
            },
            {
                "name": "Heywood",
                "countryCode": "GB"
            },
            {
                "name": "Hibaldstow",
                "countryCode": "GB"
            },
            {
                "name": "High Barnet",
                "countryCode": "GB"
            },
            {
                "name": "High Bentham",
                "countryCode": "GB"
            },
            {
                "name": "High Etherley",
                "countryCode": "GB"
            },
            {
                "name": "High Halden",
                "countryCode": "GB"
            },
            {
                "name": "High Halstow",
                "countryCode": "GB"
            },
            {
                "name": "High Legh",
                "countryCode": "GB"
            },
            {
                "name": "High Ongar",
                "countryCode": "GB"
            },
            {
                "name": "High Peak",
                "countryCode": "GB"
            },
            {
                "name": "High Wycombe",
                "countryCode": "GB"
            },
            {
                "name": "Higham Ferrers",
                "countryCode": "GB"
            },
            {
                "name": "Highbridge",
                "countryCode": "GB"
            },
            {
                "name": "Highclere",
                "countryCode": "GB"
            },
            {
                "name": "Highley",
                "countryCode": "GB"
            },
            {
                "name": "Hightown",
                "countryCode": "GB"
            },
            {
                "name": "Highworth",
                "countryCode": "GB"
            },
            {
                "name": "Hilton",
                "countryCode": "GB"
            },
            {
                "name": "Hinchley Wood",
                "countryCode": "GB"
            },
            {
                "name": "Hinckley",
                "countryCode": "GB"
            },
            {
                "name": "Hindhead",
                "countryCode": "GB"
            },
            {
                "name": "Hindley",
                "countryCode": "GB"
            },
            {
                "name": "Hindon",
                "countryCode": "GB"
            },
            {
                "name": "Hingham",
                "countryCode": "GB"
            },
            {
                "name": "Hinton",
                "countryCode": "GB"
            },
            {
                "name": "Histon",
                "countryCode": "GB"
            },
            {
                "name": "Hitchin",
                "countryCode": "GB"
            },
            {
                "name": "Hockley",
                "countryCode": "GB"
            },
            {
                "name": "Hockley Heath",
                "countryCode": "GB"
            },
            {
                "name": "Hockliffe",
                "countryCode": "GB"
            },
            {
                "name": "Hockwold cum Wilton",
                "countryCode": "GB"
            },
            {
                "name": "Hoddesdon",
                "countryCode": "GB"
            },
            {
                "name": "Holbeach",
                "countryCode": "GB"
            },
            {
                "name": "Holbeck",
                "countryCode": "GB"
            },
            {
                "name": "Holbrook",
                "countryCode": "GB"
            },
            {
                "name": "Hollingworth",
                "countryCode": "GB"
            },
            {
                "name": "Holloway",
                "countryCode": "GB"
            },
            {
                "name": "Hollym",
                "countryCode": "GB"
            },
            {
                "name": "Holmes Chapel",
                "countryCode": "GB"
            },
            {
                "name": "Holmfirth",
                "countryCode": "GB"
            },
            {
                "name": "Holmwood",
                "countryCode": "GB"
            },
            {
                "name": "Holsworthy",
                "countryCode": "GB"
            },
            {
                "name": "Holt",
                "countryCode": "GB"
            },
            {
                "name": "Holtby",
                "countryCode": "GB"
            },
            {
                "name": "Holton le Clay",
                "countryCode": "GB"
            },
            {
                "name": "Holywell Green",
                "countryCode": "GB"
            },
            {
                "name": "Honeybourne",
                "countryCode": "GB"
            },
            {
                "name": "Honiton",
                "countryCode": "GB"
            },
            {
                "name": "Hoo",
                "countryCode": "GB"
            },
            {
                "name": "Hook",
                "countryCode": "GB"
            },
            {
                "name": "Hook Norton",
                "countryCode": "GB"
            },
            {
                "name": "Hoole",
                "countryCode": "GB"
            },
            {
                "name": "Hope Valley",
                "countryCode": "GB"
            },
            {
                "name": "Hopton on Sea",
                "countryCode": "GB"
            },
            {
                "name": "Horam",
                "countryCode": "GB"
            },
            {
                "name": "Horbury",
                "countryCode": "GB"
            },
            {
                "name": "Horley",
                "countryCode": "GB"
            },
            {
                "name": "Horncastle",
                "countryCode": "GB"
            },
            {
                "name": "Hornchurch",
                "countryCode": "GB"
            },
            {
                "name": "Horndon on the Hill",
                "countryCode": "GB"
            },
            {
                "name": "Horning",
                "countryCode": "GB"
            },
            {
                "name": "Hornsea",
                "countryCode": "GB"
            },
            {
                "name": "Horrabridge",
                "countryCode": "GB"
            },
            {
                "name": "Horsford",
                "countryCode": "GB"
            },
            {
                "name": "Horsforth",
                "countryCode": "GB"
            },
            {
                "name": "Horsham",
                "countryCode": "GB"
            },
            {
                "name": "Horsmonden",
                "countryCode": "GB"
            },
            {
                "name": "Horsted Keynes",
                "countryCode": "GB"
            },
            {
                "name": "Horton",
                "countryCode": "GB"
            },
            {
                "name": "Horton Kirby",
                "countryCode": "GB"
            },
            {
                "name": "Horwich",
                "countryCode": "GB"
            },
            {
                "name": "Hotham",
                "countryCode": "GB"
            },
            {
                "name": "Houghton",
                "countryCode": "GB"
            },
            {
                "name": "Houghton Conquest",
                "countryCode": "GB"
            },
            {
                "name": "Houghton on the Hill",
                "countryCode": "GB"
            },
            {
                "name": "Houghton-Le-Spring",
                "countryCode": "GB"
            },
            {
                "name": "Hounslow",
                "countryCode": "GB"
            },
            {
                "name": "Hove",
                "countryCode": "GB"
            },
            {
                "name": "Hoveton",
                "countryCode": "GB"
            },
            {
                "name": "Howden",
                "countryCode": "GB"
            },
            {
                "name": "Hoylake",
                "countryCode": "GB"
            },
            {
                "name": "Hoyland Nether",
                "countryCode": "GB"
            },
            {
                "name": "Hucknall",
                "countryCode": "GB"
            },
            {
                "name": "Huddersfield",
                "countryCode": "GB"
            },
            {
                "name": "Hugh Town",
                "countryCode": "GB"
            },
            {
                "name": "Hulme",
                "countryCode": "GB"
            },
            {
                "name": "Humberston",
                "countryCode": "GB"
            },
            {
                "name": "Hungerford",
                "countryCode": "GB"
            },
            {
                "name": "Hunmanby",
                "countryCode": "GB"
            },
            {
                "name": "Hunstanton",
                "countryCode": "GB"
            },
            {
                "name": "Huntingdon",
                "countryCode": "GB"
            },
            {
                "name": "Huntley",
                "countryCode": "GB"
            },
            {
                "name": "Huntspill",
                "countryCode": "GB"
            },
            {
                "name": "Hunwick",
                "countryCode": "GB"
            },
            {
                "name": "Hurst",
                "countryCode": "GB"
            },
            {
                "name": "Hurstpierpoint",
                "countryCode": "GB"
            },
            {
                "name": "Husbands Bosworth",
                "countryCode": "GB"
            },
            {
                "name": "Husborne Crawley",
                "countryCode": "GB"
            },
            {
                "name": "Huyton",
                "countryCode": "GB"
            },
            {
                "name": "Hyde",
                "countryCode": "GB"
            },
            {
                "name": "Hyde Heath",
                "countryCode": "GB"
            },
            {
                "name": "Hythe",
                "countryCode": "GB"
            },
            {
                "name": "Ibstock",
                "countryCode": "GB"
            },
            {
                "name": "Ilchester",
                "countryCode": "GB"
            },
            {
                "name": "Ilfracombe",
                "countryCode": "GB"
            },
            {
                "name": "Ilkeston",
                "countryCode": "GB"
            },
            {
                "name": "Ilkley",
                "countryCode": "GB"
            },
            {
                "name": "Ilminster",
                "countryCode": "GB"
            },
            {
                "name": "Immingham",
                "countryCode": "GB"
            },
            {
                "name": "Ince Blundell",
                "countryCode": "GB"
            },
            {
                "name": "Ince-in-Makerfield",
                "countryCode": "GB"
            },
            {
                "name": "Ingatestone",
                "countryCode": "GB"
            },
            {
                "name": "Ingleby Greenhow",
                "countryCode": "GB"
            },
            {
                "name": "Ingleton",
                "countryCode": "GB"
            },
            {
                "name": "Ingoldmells",
                "countryCode": "GB"
            },
            {
                "name": "Ingrave",
                "countryCode": "GB"
            },
            {
                "name": "Inkberrow",
                "countryCode": "GB"
            },
            {
                "name": "Ipplepen",
                "countryCode": "GB"
            },
            {
                "name": "Ipswich",
                "countryCode": "GB"
            },
            {
                "name": "Irchester",
                "countryCode": "GB"
            },
            {
                "name": "Irlam",
                "countryCode": "GB"
            },
            {
                "name": "Ironbridge",
                "countryCode": "GB"
            },
            {
                "name": "Irthlingborough",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Wight",
                "countryCode": "GB"
            },
            {
                "name": "Isleham",
                "countryCode": "GB"
            },
            {
                "name": "Isles of Scilly",
                "countryCode": "GB"
            },
            {
                "name": "Isleworth",
                "countryCode": "GB"
            },
            {
                "name": "Islington",
                "countryCode": "GB"
            },
            {
                "name": "Iver",
                "countryCode": "GB"
            },
            {
                "name": "Iver Heath",
                "countryCode": "GB"
            },
            {
                "name": "Ivinghoe",
                "countryCode": "GB"
            },
            {
                "name": "Ivybridge",
                "countryCode": "GB"
            },
            {
                "name": "Iwade",
                "countryCode": "GB"
            },
            {
                "name": "Ixworth",
                "countryCode": "GB"
            },
            {
                "name": "Jarrow",
                "countryCode": "GB"
            },
            {
                "name": "Keadby",
                "countryCode": "GB"
            },
            {
                "name": "Kearsley",
                "countryCode": "GB"
            },
            {
                "name": "Kedington",
                "countryCode": "GB"
            },
            {
                "name": "Keelby",
                "countryCode": "GB"
            },
            {
                "name": "Keele",
                "countryCode": "GB"
            },
            {
                "name": "Kegworth",
                "countryCode": "GB"
            },
            {
                "name": "Keighley",
                "countryCode": "GB"
            },
            {
                "name": "Kelloe",
                "countryCode": "GB"
            },
            {
                "name": "Kelsall",
                "countryCode": "GB"
            },
            {
                "name": "Kelvedon",
                "countryCode": "GB"
            },
            {
                "name": "Kelvedon Hatch",
                "countryCode": "GB"
            },
            {
                "name": "Kempsey",
                "countryCode": "GB"
            },
            {
                "name": "Kempston",
                "countryCode": "GB"
            },
            {
                "name": "Kempston Hardwick",
                "countryCode": "GB"
            },
            {
                "name": "Kemsing",
                "countryCode": "GB"
            },
            {
                "name": "Kendal",
                "countryCode": "GB"
            },
            {
                "name": "Kenilworth",
                "countryCode": "GB"
            },
            {
                "name": "Kennington",
                "countryCode": "GB"
            },
            {
                "name": "Kensworth",
                "countryCode": "GB"
            },
            {
                "name": "Kent",
                "countryCode": "GB"
            },
            {
                "name": "Kenton",
                "countryCode": "GB"
            },
            {
                "name": "Keresley",
                "countryCode": "GB"
            },
            {
                "name": "Kesgrave",
                "countryCode": "GB"
            },
            {
                "name": "Kessingland",
                "countryCode": "GB"
            },
            {
                "name": "Keswick",
                "countryCode": "GB"
            },
            {
                "name": "Kettering",
                "countryCode": "GB"
            },
            {
                "name": "Ketton",
                "countryCode": "GB"
            },
            {
                "name": "Keyingham",
                "countryCode": "GB"
            },
            {
                "name": "Keynsham",
                "countryCode": "GB"
            },
            {
                "name": "Keyworth",
                "countryCode": "GB"
            },
            {
                "name": "Kibworth Harcourt",
                "countryCode": "GB"
            },
            {
                "name": "Kidderminster",
                "countryCode": "GB"
            },
            {
                "name": "Kidlington",
                "countryCode": "GB"
            },
            {
                "name": "Kidsgrove",
                "countryCode": "GB"
            },
            {
                "name": "Kilburn",
                "countryCode": "GB"
            },
            {
                "name": "Kilham",
                "countryCode": "GB"
            },
            {
                "name": "Killamarsh",
                "countryCode": "GB"
            },
            {
                "name": "Kilsby",
                "countryCode": "GB"
            },
            {
                "name": "Kimberley",
                "countryCode": "GB"
            },
            {
                "name": "Kimbolton",
                "countryCode": "GB"
            },
            {
                "name": "Kimpton",
                "countryCode": "GB"
            },
            {
                "name": "Kineton",
                "countryCode": "GB"
            },
            {
                "name": "King's Clipstone",
                "countryCode": "GB"
            },
            {
                "name": "King's Lynn",
                "countryCode": "GB"
            },
            {
                "name": "Kings Hill",
                "countryCode": "GB"
            },
            {
                "name": "Kings Langley",
                "countryCode": "GB"
            },
            {
                "name": "Kings Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Kings Worthy",
                "countryCode": "GB"
            },
            {
                "name": "Kingsbridge",
                "countryCode": "GB"
            },
            {
                "name": "Kingsbury",
                "countryCode": "GB"
            },
            {
                "name": "Kingsclere",
                "countryCode": "GB"
            },
            {
                "name": "Kingskerswell",
                "countryCode": "GB"
            },
            {
                "name": "Kingsland",
                "countryCode": "GB"
            },
            {
                "name": "Kingsley",
                "countryCode": "GB"
            },
            {
                "name": "Kingsteignton",
                "countryCode": "GB"
            },
            {
                "name": "Kingston Bagpuize",
                "countryCode": "GB"
            },
            {
                "name": "Kingston Seymour",
                "countryCode": "GB"
            },
            {
                "name": "Kingston upon Hull",
                "countryCode": "GB"
            },
            {
                "name": "Kingston upon Thames",
                "countryCode": "GB"
            },
            {
                "name": "Kingswinford",
                "countryCode": "GB"
            },
            {
                "name": "Kingswood",
                "countryCode": "GB"
            },
            {
                "name": "Kington",
                "countryCode": "GB"
            },
            {
                "name": "Kintbury",
                "countryCode": "GB"
            },
            {
                "name": "Kinvere",
                "countryCode": "GB"
            },
            {
                "name": "Kippax",
                "countryCode": "GB"
            },
            {
                "name": "Kirby Muxloe",
                "countryCode": "GB"
            },
            {
                "name": "Kirk Sandall",
                "countryCode": "GB"
            },
            {
                "name": "Kirkburton",
                "countryCode": "GB"
            },
            {
                "name": "Kirkby",
                "countryCode": "GB"
            },
            {
                "name": "Kirkby Lonsdale",
                "countryCode": "GB"
            },
            {
                "name": "Kirkby Stephen",
                "countryCode": "GB"
            },
            {
                "name": "Kirkby in Ashfield",
                "countryCode": "GB"
            },
            {
                "name": "Kirkbymoorside",
                "countryCode": "GB"
            },
            {
                "name": "Kirkham",
                "countryCode": "GB"
            },
            {
                "name": "Kirklees",
                "countryCode": "GB"
            },
            {
                "name": "Kirton",
                "countryCode": "GB"
            },
            {
                "name": "Kirton in Lindsey",
                "countryCode": "GB"
            },
            {
                "name": "Kislingbury",
                "countryCode": "GB"
            },
            {
                "name": "Kiveton Park",
                "countryCode": "GB"
            },
            {
                "name": "Knaphill",
                "countryCode": "GB"
            },
            {
                "name": "Knaresborough",
                "countryCode": "GB"
            },
            {
                "name": "Knebworth",
                "countryCode": "GB"
            },
            {
                "name": "Knottingley",
                "countryCode": "GB"
            },
            {
                "name": "Knowle",
                "countryCode": "GB"
            },
            {
                "name": "Knowsley",
                "countryCode": "GB"
            },
            {
                "name": "Knutsford",
                "countryCode": "GB"
            },
            {
                "name": "Laceby",
                "countryCode": "GB"
            },
            {
                "name": "Lacock",
                "countryCode": "GB"
            },
            {
                "name": "Lakenheath",
                "countryCode": "GB"
            },
            {
                "name": "Lamberhurst",
                "countryCode": "GB"
            },
            {
                "name": "Lambeth",
                "countryCode": "GB"
            },
            {
                "name": "Lambourn",
                "countryCode": "GB"
            },
            {
                "name": "Lamesley",
                "countryCode": "GB"
            },
            {
                "name": "Lancashire",
                "countryCode": "GB"
            },
            {
                "name": "Lancaster",
                "countryCode": "GB"
            },
            {
                "name": "Lanchester",
                "countryCode": "GB"
            },
            {
                "name": "Lancing",
                "countryCode": "GB"
            },
            {
                "name": "Landrake",
                "countryCode": "GB"
            },
            {
                "name": "Langford",
                "countryCode": "GB"
            },
            {
                "name": "Langham",
                "countryCode": "GB"
            },
            {
                "name": "Langho",
                "countryCode": "GB"
            },
            {
                "name": "Langley Green",
                "countryCode": "GB"
            },
            {
                "name": "Langley Park",
                "countryCode": "GB"
            },
            {
                "name": "Langport",
                "countryCode": "GB"
            },
            {
                "name": "Langtoft",
                "countryCode": "GB"
            },
            {
                "name": "Lark Hill",
                "countryCode": "GB"
            },
            {
                "name": "Larkfield",
                "countryCode": "GB"
            },
            {
                "name": "Latchingdon and Snoreham",
                "countryCode": "GB"
            },
            {
                "name": "Launceston",
                "countryCode": "GB"
            },
            {
                "name": "Lavendon",
                "countryCode": "GB"
            },
            {
                "name": "Lavenham",
                "countryCode": "GB"
            },
            {
                "name": "Layer de la Haye",
                "countryCode": "GB"
            },
            {
                "name": "Leasingham",
                "countryCode": "GB"
            },
            {
                "name": "Leatherhead",
                "countryCode": "GB"
            },
            {
                "name": "Lechlade",
                "countryCode": "GB"
            },
            {
                "name": "Leconfield",
                "countryCode": "GB"
            },
            {
                "name": "Ledbury",
                "countryCode": "GB"
            },
            {
                "name": "Ledsham",
                "countryCode": "GB"
            },
            {
                "name": "Lee-on-the-Solent",
                "countryCode": "GB"
            },
            {
                "name": "Leeds",
                "countryCode": "GB"
            },
            {
                "name": "Leek",
                "countryCode": "GB"
            },
            {
                "name": "Leek Wootton",
                "countryCode": "GB"
            },
            {
                "name": "Leicester",
                "countryCode": "GB"
            },
            {
                "name": "Leicestershire",
                "countryCode": "GB"
            },
            {
                "name": "Leigh",
                "countryCode": "GB"
            },
            {
                "name": "Leighton Buzzard",
                "countryCode": "GB"
            },
            {
                "name": "Leiston",
                "countryCode": "GB"
            },
            {
                "name": "Lenham",
                "countryCode": "GB"
            },
            {
                "name": "Leominster",
                "countryCode": "GB"
            },
            {
                "name": "Lesbury",
                "countryCode": "GB"
            },
            {
                "name": "Letchworth",
                "countryCode": "GB"
            },
            {
                "name": "Letchworth Garden City",
                "countryCode": "GB"
            },
            {
                "name": "Leven",
                "countryCode": "GB"
            },
            {
                "name": "Lewes",
                "countryCode": "GB"
            },
            {
                "name": "Leyburn",
                "countryCode": "GB"
            },
            {
                "name": "Leyland",
                "countryCode": "GB"
            },
            {
                "name": "Leysdown-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Lichfield",
                "countryCode": "GB"
            },
            {
                "name": "Lidlington",
                "countryCode": "GB"
            },
            {
                "name": "Lifton",
                "countryCode": "GB"
            },
            {
                "name": "Lightwater",
                "countryCode": "GB"
            },
            {
                "name": "Limpley Stoke",
                "countryCode": "GB"
            },
            {
                "name": "Lincoln",
                "countryCode": "GB"
            },
            {
                "name": "Lincolnshire",
                "countryCode": "GB"
            },
            {
                "name": "Lingdale",
                "countryCode": "GB"
            },
            {
                "name": "Lingfield",
                "countryCode": "GB"
            },
            {
                "name": "Lingwood",
                "countryCode": "GB"
            },
            {
                "name": "Linthwaite",
                "countryCode": "GB"
            },
            {
                "name": "Linton",
                "countryCode": "GB"
            },
            {
                "name": "Linton upon Ouse",
                "countryCode": "GB"
            },
            {
                "name": "Liphook",
                "countryCode": "GB"
            },
            {
                "name": "Liskeard",
                "countryCode": "GB"
            },
            {
                "name": "Liss",
                "countryCode": "GB"
            },
            {
                "name": "Litherland",
                "countryCode": "GB"
            },
            {
                "name": "Little Amwell",
                "countryCode": "GB"
            },
            {
                "name": "Little Chalfont",
                "countryCode": "GB"
            },
            {
                "name": "Little Clacton",
                "countryCode": "GB"
            },
            {
                "name": "Little Dunmow",
                "countryCode": "GB"
            },
            {
                "name": "Little Eaton",
                "countryCode": "GB"
            },
            {
                "name": "Little Hallingbury",
                "countryCode": "GB"
            },
            {
                "name": "Little Hulton",
                "countryCode": "GB"
            },
            {
                "name": "Little Lever",
                "countryCode": "GB"
            },
            {
                "name": "Little Paxton",
                "countryCode": "GB"
            },
            {
                "name": "Little Weighton",
                "countryCode": "GB"
            },
            {
                "name": "Littleborough",
                "countryCode": "GB"
            },
            {
                "name": "Littlebourne",
                "countryCode": "GB"
            },
            {
                "name": "Littlehampton",
                "countryCode": "GB"
            },
            {
                "name": "Littleport",
                "countryCode": "GB"
            },
            {
                "name": "Liverpool",
                "countryCode": "GB"
            },
            {
                "name": "Liversedge",
                "countryCode": "GB"
            },
            {
                "name": "Locking",
                "countryCode": "GB"
            },
            {
                "name": "Lockington",
                "countryCode": "GB"
            },
            {
                "name": "Loddon",
                "countryCode": "GB"
            },
            {
                "name": "Lofthouse",
                "countryCode": "GB"
            },
            {
                "name": "Loftus",
                "countryCode": "GB"
            },
            {
                "name": "London",
                "countryCode": "GB"
            },
            {
                "name": "Long Ashton",
                "countryCode": "GB"
            },
            {
                "name": "Long Bennington",
                "countryCode": "GB"
            },
            {
                "name": "Long Buckby",
                "countryCode": "GB"
            },
            {
                "name": "Long Clawson",
                "countryCode": "GB"
            },
            {
                "name": "Long Crendon",
                "countryCode": "GB"
            },
            {
                "name": "Long Eaton",
                "countryCode": "GB"
            },
            {
                "name": "Long Itchington",
                "countryCode": "GB"
            },
            {
                "name": "Long Lawford",
                "countryCode": "GB"
            },
            {
                "name": "Long Melford",
                "countryCode": "GB"
            },
            {
                "name": "Long Stratton",
                "countryCode": "GB"
            },
            {
                "name": "Long Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Long Whatton",
                "countryCode": "GB"
            },
            {
                "name": "Longdendale",
                "countryCode": "GB"
            },
            {
                "name": "Longfield",
                "countryCode": "GB"
            },
            {
                "name": "Longhope",
                "countryCode": "GB"
            },
            {
                "name": "Longhorsley",
                "countryCode": "GB"
            },
            {
                "name": "Longhoughton",
                "countryCode": "GB"
            },
            {
                "name": "Longridge",
                "countryCode": "GB"
            },
            {
                "name": "Longsight",
                "countryCode": "GB"
            },
            {
                "name": "Longstanton",
                "countryCode": "GB"
            },
            {
                "name": "Longton",
                "countryCode": "GB"
            },
            {
                "name": "Longtown",
                "countryCode": "GB"
            },
            {
                "name": "Longwick",
                "countryCode": "GB"
            },
            {
                "name": "Looe",
                "countryCode": "GB"
            },
            {
                "name": "Lostwithiel",
                "countryCode": "GB"
            },
            {
                "name": "Loughborough",
                "countryCode": "GB"
            },
            {
                "name": "Louth",
                "countryCode": "GB"
            },
            {
                "name": "Low Ackworth",
                "countryCode": "GB"
            },
            {
                "name": "Low Bradley",
                "countryCode": "GB"
            },
            {
                "name": "Low Etherley",
                "countryCode": "GB"
            },
            {
                "name": "Lowdham",
                "countryCode": "GB"
            },
            {
                "name": "Lower Brailes",
                "countryCode": "GB"
            },
            {
                "name": "Lower Broadheath",
                "countryCode": "GB"
            },
            {
                "name": "Lower Earley",
                "countryCode": "GB"
            },
            {
                "name": "Lower Halstow",
                "countryCode": "GB"
            },
            {
                "name": "Lower Kingswood",
                "countryCode": "GB"
            },
            {
                "name": "Lowestoft",
                "countryCode": "GB"
            },
            {
                "name": "Lowick",
                "countryCode": "GB"
            },
            {
                "name": "Luckington",
                "countryCode": "GB"
            },
            {
                "name": "Luddenden Foot",
                "countryCode": "GB"
            },
            {
                "name": "Ludgershall",
                "countryCode": "GB"
            },
            {
                "name": "Ludlow",
                "countryCode": "GB"
            },
            {
                "name": "Lugwardine",
                "countryCode": "GB"
            },
            {
                "name": "Lund",
                "countryCode": "GB"
            },
            {
                "name": "Luton",
                "countryCode": "GB"
            },
            {
                "name": "Lutterworth",
                "countryCode": "GB"
            },
            {
                "name": "Lydbrook",
                "countryCode": "GB"
            },
            {
                "name": "Lydd",
                "countryCode": "GB"
            },
            {
                "name": "Lydiard Millicent",
                "countryCode": "GB"
            },
            {
                "name": "Lydney",
                "countryCode": "GB"
            },
            {
                "name": "Lyme Regis",
                "countryCode": "GB"
            },
            {
                "name": "Lyminge",
                "countryCode": "GB"
            },
            {
                "name": "Lymington",
                "countryCode": "GB"
            },
            {
                "name": "Lymm",
                "countryCode": "GB"
            },
            {
                "name": "Lympne",
                "countryCode": "GB"
            },
            {
                "name": "Lympstone",
                "countryCode": "GB"
            },
            {
                "name": "Lyndhurst",
                "countryCode": "GB"
            },
            {
                "name": "Lyneham",
                "countryCode": "GB"
            },
            {
                "name": "Lynemouth",
                "countryCode": "GB"
            },
            {
                "name": "Lynton",
                "countryCode": "GB"
            },
            {
                "name": "Lytchett Matravers",
                "countryCode": "GB"
            },
            {
                "name": "Lytham St Annes",
                "countryCode": "GB"
            },
            {
                "name": "Mablethorpe",
                "countryCode": "GB"
            },
            {
                "name": "Macclesfield",
                "countryCode": "GB"
            },
            {
                "name": "Madeley",
                "countryCode": "GB"
            },
            {
                "name": "Maghull",
                "countryCode": "GB"
            },
            {
                "name": "Maiden Newton",
                "countryCode": "GB"
            },
            {
                "name": "Maidenbower",
                "countryCode": "GB"
            },
            {
                "name": "Maidenhead",
                "countryCode": "GB"
            },
            {
                "name": "Maidstone",
                "countryCode": "GB"
            },
            {
                "name": "Maldon",
                "countryCode": "GB"
            },
            {
                "name": "Malmesbury",
                "countryCode": "GB"
            },
            {
                "name": "Malpas",
                "countryCode": "GB"
            },
            {
                "name": "Maltby",
                "countryCode": "GB"
            },
            {
                "name": "Malton",
                "countryCode": "GB"
            },
            {
                "name": "Manby",
                "countryCode": "GB"
            },
            {
                "name": "Manchester",
                "countryCode": "GB"
            },
            {
                "name": "Manea",
                "countryCode": "GB"
            },
            {
                "name": "Mangotsfield",
                "countryCode": "GB"
            },
            {
                "name": "Manningtree",
                "countryCode": "GB"
            },
            {
                "name": "Mansfield",
                "countryCode": "GB"
            },
            {
                "name": "Mansfield Woodhouse",
                "countryCode": "GB"
            },
            {
                "name": "Manston",
                "countryCode": "GB"
            },
            {
                "name": "Manton",
                "countryCode": "GB"
            },
            {
                "name": "Maple Cross",
                "countryCode": "GB"
            },
            {
                "name": "Marazion",
                "countryCode": "GB"
            },
            {
                "name": "March",
                "countryCode": "GB"
            },
            {
                "name": "Marcham",
                "countryCode": "GB"
            },
            {
                "name": "Marchwood",
                "countryCode": "GB"
            },
            {
                "name": "Marden",
                "countryCode": "GB"
            },
            {
                "name": "Margate",
                "countryCode": "GB"
            },
            {
                "name": "Market Bosworth",
                "countryCode": "GB"
            },
            {
                "name": "Market Deeping",
                "countryCode": "GB"
            },
            {
                "name": "Market Drayton",
                "countryCode": "GB"
            },
            {
                "name": "Market Harborough",
                "countryCode": "GB"
            },
            {
                "name": "Market Lavington",
                "countryCode": "GB"
            },
            {
                "name": "Market Overton",
                "countryCode": "GB"
            },
            {
                "name": "Market Rasen",
                "countryCode": "GB"
            },
            {
                "name": "Market Warsop",
                "countryCode": "GB"
            },
            {
                "name": "Market Weighton",
                "countryCode": "GB"
            },
            {
                "name": "Markfield",
                "countryCode": "GB"
            },
            {
                "name": "Marks Tey",
                "countryCode": "GB"
            },
            {
                "name": "Markyate",
                "countryCode": "GB"
            },
            {
                "name": "Marlborough",
                "countryCode": "GB"
            },
            {
                "name": "Marldon",
                "countryCode": "GB"
            },
            {
                "name": "Marlow",
                "countryCode": "GB"
            },
            {
                "name": "Marnhull",
                "countryCode": "GB"
            },
            {
                "name": "Marple",
                "countryCode": "GB"
            },
            {
                "name": "Marr",
                "countryCode": "GB"
            },
            {
                "name": "Marsden",
                "countryCode": "GB"
            },
            {
                "name": "Marshfield",
                "countryCode": "GB"
            },
            {
                "name": "Marske-by-the-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Marston Moretaine",
                "countryCode": "GB"
            },
            {
                "name": "Martham",
                "countryCode": "GB"
            },
            {
                "name": "Martock",
                "countryCode": "GB"
            },
            {
                "name": "Maryport",
                "countryCode": "GB"
            },
            {
                "name": "Masham",
                "countryCode": "GB"
            },
            {
                "name": "Matlock",
                "countryCode": "GB"
            },
            {
                "name": "Mattishall",
                "countryCode": "GB"
            },
            {
                "name": "Maulden",
                "countryCode": "GB"
            },
            {
                "name": "Mayfield",
                "countryCode": "GB"
            },
            {
                "name": "Mayland",
                "countryCode": "GB"
            },
            {
                "name": "Measham",
                "countryCode": "GB"
            },
            {
                "name": "Medway",
                "countryCode": "GB"
            },
            {
                "name": "Melbourn",
                "countryCode": "GB"
            },
            {
                "name": "Melbourne",
                "countryCode": "GB"
            },
            {
                "name": "Meldreth",
                "countryCode": "GB"
            },
            {
                "name": "Melksham",
                "countryCode": "GB"
            },
            {
                "name": "Meltham",
                "countryCode": "GB"
            },
            {
                "name": "Meltham Mills",
                "countryCode": "GB"
            },
            {
                "name": "Melton Mowbray",
                "countryCode": "GB"
            },
            {
                "name": "Mendip",
                "countryCode": "GB"
            },
            {
                "name": "Menston",
                "countryCode": "GB"
            },
            {
                "name": "Meopham",
                "countryCode": "GB"
            },
            {
                "name": "Meppershall",
                "countryCode": "GB"
            },
            {
                "name": "Mere",
                "countryCode": "GB"
            },
            {
                "name": "Meriden",
                "countryCode": "GB"
            },
            {
                "name": "Merriott",
                "countryCode": "GB"
            },
            {
                "name": "Messingham",
                "countryCode": "GB"
            },
            {
                "name": "Metheringham",
                "countryCode": "GB"
            },
            {
                "name": "Methley",
                "countryCode": "GB"
            },
            {
                "name": "Metropolitan Borough of Wirral",
                "countryCode": "GB"
            },
            {
                "name": "Mevagissey",
                "countryCode": "GB"
            },
            {
                "name": "Mexborough",
                "countryCode": "GB"
            },
            {
                "name": "Mickle Trafford",
                "countryCode": "GB"
            },
            {
                "name": "Mickleton",
                "countryCode": "GB"
            },
            {
                "name": "Middle Rasen",
                "countryCode": "GB"
            },
            {
                "name": "Middle Winterslow",
                "countryCode": "GB"
            },
            {
                "name": "Middlesbrough",
                "countryCode": "GB"
            },
            {
                "name": "Middlestown",
                "countryCode": "GB"
            },
            {
                "name": "Middleton",
                "countryCode": "GB"
            },
            {
                "name": "Middlewich",
                "countryCode": "GB"
            },
            {
                "name": "Midhurst",
                "countryCode": "GB"
            },
            {
                "name": "Midsomer Norton",
                "countryCode": "GB"
            },
            {
                "name": "Milborne Port",
                "countryCode": "GB"
            },
            {
                "name": "Milborne St Andrew",
                "countryCode": "GB"
            },
            {
                "name": "Mildenhall",
                "countryCode": "GB"
            },
            {
                "name": "Milford",
                "countryCode": "GB"
            },
            {
                "name": "Milford on Sea",
                "countryCode": "GB"
            },
            {
                "name": "Millbrook",
                "countryCode": "GB"
            },
            {
                "name": "Millom",
                "countryCode": "GB"
            },
            {
                "name": "Milnrow",
                "countryCode": "GB"
            },
            {
                "name": "Milnthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Milton Keynes",
                "countryCode": "GB"
            },
            {
                "name": "Milverton",
                "countryCode": "GB"
            },
            {
                "name": "Minchinhampton",
                "countryCode": "GB"
            },
            {
                "name": "Minehead",
                "countryCode": "GB"
            },
            {
                "name": "Minety",
                "countryCode": "GB"
            },
            {
                "name": "Minster Lovell",
                "countryCode": "GB"
            },
            {
                "name": "Minsterley",
                "countryCode": "GB"
            },
            {
                "name": "Mirfield",
                "countryCode": "GB"
            },
            {
                "name": "Misterton",
                "countryCode": "GB"
            },
            {
                "name": "Mistley",
                "countryCode": "GB"
            },
            {
                "name": "Mitcham",
                "countryCode": "GB"
            },
            {
                "name": "Mitcheldean",
                "countryCode": "GB"
            },
            {
                "name": "Mobberley",
                "countryCode": "GB"
            },
            {
                "name": "Modbury",
                "countryCode": "GB"
            },
            {
                "name": "Moira",
                "countryCode": "GB"
            },
            {
                "name": "Mollington",
                "countryCode": "GB"
            },
            {
                "name": "Monk Fryston",
                "countryCode": "GB"
            },
            {
                "name": "Morchard Bishop",
                "countryCode": "GB"
            },
            {
                "name": "Morcott",
                "countryCode": "GB"
            },
            {
                "name": "Morden",
                "countryCode": "GB"
            },
            {
                "name": "Morecambe",
                "countryCode": "GB"
            },
            {
                "name": "Moreton",
                "countryCode": "GB"
            },
            {
                "name": "Moreton in Marsh",
                "countryCode": "GB"
            },
            {
                "name": "Moretonhampstead",
                "countryCode": "GB"
            },
            {
                "name": "Morley",
                "countryCode": "GB"
            },
            {
                "name": "Morpeth",
                "countryCode": "GB"
            },
            {
                "name": "Moss",
                "countryCode": "GB"
            },
            {
                "name": "Mossley",
                "countryCode": "GB"
            },
            {
                "name": "Motcombe",
                "countryCode": "GB"
            },
            {
                "name": "Mouldsworth",
                "countryCode": "GB"
            },
            {
                "name": "Moulton Chapel",
                "countryCode": "GB"
            },
            {
                "name": "Mount Hawke",
                "countryCode": "GB"
            },
            {
                "name": "Mountsorrel",
                "countryCode": "GB"
            },
            {
                "name": "Much Hadham",
                "countryCode": "GB"
            },
            {
                "name": "Much Wenlock",
                "countryCode": "GB"
            },
            {
                "name": "Mulbarton",
                "countryCode": "GB"
            },
            {
                "name": "Mullion",
                "countryCode": "GB"
            },
            {
                "name": "Mundesley",
                "countryCode": "GB"
            },
            {
                "name": "Mundford",
                "countryCode": "GB"
            },
            {
                "name": "Murton",
                "countryCode": "GB"
            },
            {
                "name": "Mylor Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Mytholmroyd",
                "countryCode": "GB"
            },
            {
                "name": "Nafferton",
                "countryCode": "GB"
            },
            {
                "name": "Nailsea",
                "countryCode": "GB"
            },
            {
                "name": "Nailsworth",
                "countryCode": "GB"
            },
            {
                "name": "Nanpean",
                "countryCode": "GB"
            },
            {
                "name": "Nantwich",
                "countryCode": "GB"
            },
            {
                "name": "Narborough",
                "countryCode": "GB"
            },
            {
                "name": "Navenby",
                "countryCode": "GB"
            },
            {
                "name": "Necton",
                "countryCode": "GB"
            },
            {
                "name": "Needham Market",
                "countryCode": "GB"
            },
            {
                "name": "Needingworth",
                "countryCode": "GB"
            },
            {
                "name": "Nelson",
                "countryCode": "GB"
            },
            {
                "name": "Neston",
                "countryCode": "GB"
            },
            {
                "name": "Nether Heyford",
                "countryCode": "GB"
            },
            {
                "name": "Nether Poppleton",
                "countryCode": "GB"
            },
            {
                "name": "Nether Stowey",
                "countryCode": "GB"
            },
            {
                "name": "Netheravon",
                "countryCode": "GB"
            },
            {
                "name": "Netherton",
                "countryCode": "GB"
            },
            {
                "name": "Netley",
                "countryCode": "GB"
            },
            {
                "name": "Nettleham",
                "countryCode": "GB"
            },
            {
                "name": "New Alresford",
                "countryCode": "GB"
            },
            {
                "name": "New Basford",
                "countryCode": "GB"
            },
            {
                "name": "New Ferry",
                "countryCode": "GB"
            },
            {
                "name": "New Malden",
                "countryCode": "GB"
            },
            {
                "name": "New Marske",
                "countryCode": "GB"
            },
            {
                "name": "New Mills",
                "countryCode": "GB"
            },
            {
                "name": "New Milton",
                "countryCode": "GB"
            },
            {
                "name": "New Romney",
                "countryCode": "GB"
            },
            {
                "name": "Newark on Trent",
                "countryCode": "GB"
            },
            {
                "name": "Newbiggin-by-the-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Newbold Verdon",
                "countryCode": "GB"
            },
            {
                "name": "Newburn",
                "countryCode": "GB"
            },
            {
                "name": "Newbury",
                "countryCode": "GB"
            },
            {
                "name": "Newcastle under Lyme",
                "countryCode": "GB"
            },
            {
                "name": "Newcastle upon Tyne",
                "countryCode": "GB"
            },
            {
                "name": "Newchurch",
                "countryCode": "GB"
            },
            {
                "name": "Newent",
                "countryCode": "GB"
            },
            {
                "name": "Newhaven",
                "countryCode": "GB"
            },
            {
                "name": "Newick",
                "countryCode": "GB"
            },
            {
                "name": "Newington",
                "countryCode": "GB"
            },
            {
                "name": "Newmarket",
                "countryCode": "GB"
            },
            {
                "name": "Newport",
                "countryCode": "GB"
            },
            {
                "name": "Newport Pagnell",
                "countryCode": "GB"
            },
            {
                "name": "Newquay",
                "countryCode": "GB"
            },
            {
                "name": "Newton Abbot",
                "countryCode": "GB"
            },
            {
                "name": "Newton Aycliffe",
                "countryCode": "GB"
            },
            {
                "name": "Newton Ferrers",
                "countryCode": "GB"
            },
            {
                "name": "Newton Longville",
                "countryCode": "GB"
            },
            {
                "name": "Newton Poppleford",
                "countryCode": "GB"
            },
            {
                "name": "Newton-le-Willows",
                "countryCode": "GB"
            },
            {
                "name": "Ninfield",
                "countryCode": "GB"
            },
            {
                "name": "Niton",
                "countryCode": "GB"
            },
            {
                "name": "Norfolk",
                "countryCode": "GB"
            },
            {
                "name": "Normandy",
                "countryCode": "GB"
            },
            {
                "name": "North Baddesley",
                "countryCode": "GB"
            },
            {
                "name": "North Cave",
                "countryCode": "GB"
            },
            {
                "name": "North Collingham",
                "countryCode": "GB"
            },
            {
                "name": "North Duffield",
                "countryCode": "GB"
            },
            {
                "name": "North East Lincolnshire",
                "countryCode": "GB"
            },
            {
                "name": "North Elmham",
                "countryCode": "GB"
            },
            {
                "name": "North Ferriby",
                "countryCode": "GB"
            },
            {
                "name": "North Leigh",
                "countryCode": "GB"
            },
            {
                "name": "North Lincolnshire",
                "countryCode": "GB"
            },
            {
                "name": "North Luffenham",
                "countryCode": "GB"
            },
            {
                "name": "North Newbald",
                "countryCode": "GB"
            },
            {
                "name": "North Petherton",
                "countryCode": "GB"
            },
            {
                "name": "North Shields",
                "countryCode": "GB"
            },
            {
                "name": "North Somercotes",
                "countryCode": "GB"
            },
            {
                "name": "North Somerset",
                "countryCode": "GB"
            },
            {
                "name": "North Sunderland",
                "countryCode": "GB"
            },
            {
                "name": "North Tawton",
                "countryCode": "GB"
            },
            {
                "name": "North Thoresby",
                "countryCode": "GB"
            },
            {
                "name": "North Walsham",
                "countryCode": "GB"
            },
            {
                "name": "North Yorkshire",
                "countryCode": "GB"
            },
            {
                "name": "Northallerton",
                "countryCode": "GB"
            },
            {
                "name": "Northam",
                "countryCode": "GB"
            },
            {
                "name": "Northampton",
                "countryCode": "GB"
            },
            {
                "name": "Northamptonshire",
                "countryCode": "GB"
            },
            {
                "name": "Northborough",
                "countryCode": "GB"
            },
            {
                "name": "Northiam",
                "countryCode": "GB"
            },
            {
                "name": "Northleach",
                "countryCode": "GB"
            },
            {
                "name": "Northolt",
                "countryCode": "GB"
            },
            {
                "name": "Northorpe",
                "countryCode": "GB"
            },
            {
                "name": "Northumberland",
                "countryCode": "GB"
            },
            {
                "name": "Northwich",
                "countryCode": "GB"
            },
            {
                "name": "Norton",
                "countryCode": "GB"
            },
            {
                "name": "Norton Canes",
                "countryCode": "GB"
            },
            {
                "name": "Norwich",
                "countryCode": "GB"
            },
            {
                "name": "Nottingham",
                "countryCode": "GB"
            },
            {
                "name": "Nottinghamshire",
                "countryCode": "GB"
            },
            {
                "name": "Notton",
                "countryCode": "GB"
            },
            {
                "name": "Nuneaton",
                "countryCode": "GB"
            },
            {
                "name": "Oadby",
                "countryCode": "GB"
            },
            {
                "name": "Oakengates",
                "countryCode": "GB"
            },
            {
                "name": "Oakham",
                "countryCode": "GB"
            },
            {
                "name": "Oakington",
                "countryCode": "GB"
            },
            {
                "name": "Oakley",
                "countryCode": "GB"
            },
            {
                "name": "Odiham",
                "countryCode": "GB"
            },
            {
                "name": "Okehampton",
                "countryCode": "GB"
            },
            {
                "name": "Old Basing",
                "countryCode": "GB"
            },
            {
                "name": "Old Harlow",
                "countryCode": "GB"
            },
            {
                "name": "Old Leake",
                "countryCode": "GB"
            },
            {
                "name": "Old Windsor",
                "countryCode": "GB"
            },
            {
                "name": "Oldbury",
                "countryCode": "GB"
            },
            {
                "name": "Oldham",
                "countryCode": "GB"
            },
            {
                "name": "Olney",
                "countryCode": "GB"
            },
            {
                "name": "Olveston",
                "countryCode": "GB"
            },
            {
                "name": "Orleton",
                "countryCode": "GB"
            },
            {
                "name": "Ormesby St Margaret",
                "countryCode": "GB"
            },
            {
                "name": "Ormskirk",
                "countryCode": "GB"
            },
            {
                "name": "Orpington",
                "countryCode": "GB"
            },
            {
                "name": "Orsett",
                "countryCode": "GB"
            },
            {
                "name": "Orwell",
                "countryCode": "GB"
            },
            {
                "name": "Ossett",
                "countryCode": "GB"
            },
            {
                "name": "Oswestry",
                "countryCode": "GB"
            },
            {
                "name": "Otford",
                "countryCode": "GB"
            },
            {
                "name": "Otley",
                "countryCode": "GB"
            },
            {
                "name": "Otterburn",
                "countryCode": "GB"
            },
            {
                "name": "Ottershaw",
                "countryCode": "GB"
            },
            {
                "name": "Ottery St Mary",
                "countryCode": "GB"
            },
            {
                "name": "Ottringham",
                "countryCode": "GB"
            },
            {
                "name": "Oughtibridge",
                "countryCode": "GB"
            },
            {
                "name": "Oundle",
                "countryCode": "GB"
            },
            {
                "name": "Outwell",
                "countryCode": "GB"
            },
            {
                "name": "Over",
                "countryCode": "GB"
            },
            {
                "name": "Overcombe",
                "countryCode": "GB"
            },
            {
                "name": "Overstrand",
                "countryCode": "GB"
            },
            {
                "name": "Overton",
                "countryCode": "GB"
            },
            {
                "name": "Owston Ferry",
                "countryCode": "GB"
            },
            {
                "name": "Oxenhope",
                "countryCode": "GB"
            },
            {
                "name": "Oxford",
                "countryCode": "GB"
            },
            {
                "name": "Oxfordshire",
                "countryCode": "GB"
            },
            {
                "name": "Oxted",
                "countryCode": "GB"
            },
            {
                "name": "Paddock Wood",
                "countryCode": "GB"
            },
            {
                "name": "Padiham",
                "countryCode": "GB"
            },
            {
                "name": "Padstow",
                "countryCode": "GB"
            },
            {
                "name": "Paignton",
                "countryCode": "GB"
            },
            {
                "name": "Painswick",
                "countryCode": "GB"
            },
            {
                "name": "Pangbourne",
                "countryCode": "GB"
            },
            {
                "name": "Pannal",
                "countryCode": "GB"
            },
            {
                "name": "Pant",
                "countryCode": "GB"
            },
            {
                "name": "Papworth Everard",
                "countryCode": "GB"
            },
            {
                "name": "Par",
                "countryCode": "GB"
            },
            {
                "name": "Parbold",
                "countryCode": "GB"
            },
            {
                "name": "Parkstone",
                "countryCode": "GB"
            },
            {
                "name": "Partington",
                "countryCode": "GB"
            },
            {
                "name": "Partridge Green",
                "countryCode": "GB"
            },
            {
                "name": "Pateley Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Patrington",
                "countryCode": "GB"
            },
            {
                "name": "Pattingham",
                "countryCode": "GB"
            },
            {
                "name": "Paulton",
                "countryCode": "GB"
            },
            {
                "name": "Peacehaven",
                "countryCode": "GB"
            },
            {
                "name": "Peasedown Saint John",
                "countryCode": "GB"
            },
            {
                "name": "Peaslake",
                "countryCode": "GB"
            },
            {
                "name": "Peckham",
                "countryCode": "GB"
            },
            {
                "name": "Pegswood",
                "countryCode": "GB"
            },
            {
                "name": "Pelsall",
                "countryCode": "GB"
            },
            {
                "name": "Pelton",
                "countryCode": "GB"
            },
            {
                "name": "Pembury",
                "countryCode": "GB"
            },
            {
                "name": "Penistone",
                "countryCode": "GB"
            },
            {
                "name": "Penkridge",
                "countryCode": "GB"
            },
            {
                "name": "Penrith",
                "countryCode": "GB"
            },
            {
                "name": "Penryn",
                "countryCode": "GB"
            },
            {
                "name": "Pensilva",
                "countryCode": "GB"
            },
            {
                "name": "Penzance",
                "countryCode": "GB"
            },
            {
                "name": "Perranporth",
                "countryCode": "GB"
            },
            {
                "name": "Perranwell",
                "countryCode": "GB"
            },
            {
                "name": "Pershore",
                "countryCode": "GB"
            },
            {
                "name": "Peterborough",
                "countryCode": "GB"
            },
            {
                "name": "Peterlee",
                "countryCode": "GB"
            },
            {
                "name": "Petersfield",
                "countryCode": "GB"
            },
            {
                "name": "Petworth",
                "countryCode": "GB"
            },
            {
                "name": "Pevensey",
                "countryCode": "GB"
            },
            {
                "name": "Pevensey Bay",
                "countryCode": "GB"
            },
            {
                "name": "Pewsey",
                "countryCode": "GB"
            },
            {
                "name": "Pickering",
                "countryCode": "GB"
            },
            {
                "name": "Pilning",
                "countryCode": "GB"
            },
            {
                "name": "Pilsley",
                "countryCode": "GB"
            },
            {
                "name": "Pimperne",
                "countryCode": "GB"
            },
            {
                "name": "Pinchbeck",
                "countryCode": "GB"
            },
            {
                "name": "Pinner",
                "countryCode": "GB"
            },
            {
                "name": "Pinxton",
                "countryCode": "GB"
            },
            {
                "name": "Pirton",
                "countryCode": "GB"
            },
            {
                "name": "Pitsea",
                "countryCode": "GB"
            },
            {
                "name": "Pitstone",
                "countryCode": "GB"
            },
            {
                "name": "Plumpton Green",
                "countryCode": "GB"
            },
            {
                "name": "Plymouth",
                "countryCode": "GB"
            },
            {
                "name": "Plympton",
                "countryCode": "GB"
            },
            {
                "name": "Plymstock",
                "countryCode": "GB"
            },
            {
                "name": "Pocklington",
                "countryCode": "GB"
            },
            {
                "name": "Podington",
                "countryCode": "GB"
            },
            {
                "name": "Polesworth",
                "countryCode": "GB"
            },
            {
                "name": "Pollington",
                "countryCode": "GB"
            },
            {
                "name": "Polperro",
                "countryCode": "GB"
            },
            {
                "name": "Polzeath",
                "countryCode": "GB"
            },
            {
                "name": "Pontefract",
                "countryCode": "GB"
            },
            {
                "name": "Ponteland",
                "countryCode": "GB"
            },
            {
                "name": "Pontesbury",
                "countryCode": "GB"
            },
            {
                "name": "Pool",
                "countryCode": "GB"
            },
            {
                "name": "Poole",
                "countryCode": "GB"
            },
            {
                "name": "Poringland",
                "countryCode": "GB"
            },
            {
                "name": "Porlock",
                "countryCode": "GB"
            },
            {
                "name": "Porthleven",
                "countryCode": "GB"
            },
            {
                "name": "Portishead",
                "countryCode": "GB"
            },
            {
                "name": "Portland",
                "countryCode": "GB"
            },
            {
                "name": "Portscatho",
                "countryCode": "GB"
            },
            {
                "name": "Portslade",
                "countryCode": "GB"
            },
            {
                "name": "Portsmouth",
                "countryCode": "GB"
            },
            {
                "name": "Potterne",
                "countryCode": "GB"
            },
            {
                "name": "Potters Bar",
                "countryCode": "GB"
            },
            {
                "name": "Potterspury",
                "countryCode": "GB"
            },
            {
                "name": "Potton",
                "countryCode": "GB"
            },
            {
                "name": "Poulton-le-Fylde",
                "countryCode": "GB"
            },
            {
                "name": "Poynton",
                "countryCode": "GB"
            },
            {
                "name": "Prees",
                "countryCode": "GB"
            },
            {
                "name": "Preesall",
                "countryCode": "GB"
            },
            {
                "name": "Prenton",
                "countryCode": "GB"
            },
            {
                "name": "Prescot",
                "countryCode": "GB"
            },
            {
                "name": "Prestbury",
                "countryCode": "GB"
            },
            {
                "name": "Preston",
                "countryCode": "GB"
            },
            {
                "name": "Prestwich",
                "countryCode": "GB"
            },
            {
                "name": "Princes Risborough",
                "countryCode": "GB"
            },
            {
                "name": "Princetown",
                "countryCode": "GB"
            },
            {
                "name": "Priston",
                "countryCode": "GB"
            },
            {
                "name": "Probus",
                "countryCode": "GB"
            },
            {
                "name": "Prudhoe",
                "countryCode": "GB"
            },
            {
                "name": "Publow",
                "countryCode": "GB"
            },
            {
                "name": "Puckeridge",
                "countryCode": "GB"
            },
            {
                "name": "Pucklechurch",
                "countryCode": "GB"
            },
            {
                "name": "Puddletown",
                "countryCode": "GB"
            },
            {
                "name": "Pudsey",
                "countryCode": "GB"
            },
            {
                "name": "Pulborough",
                "countryCode": "GB"
            },
            {
                "name": "Pulloxhill",
                "countryCode": "GB"
            },
            {
                "name": "Purfleet",
                "countryCode": "GB"
            },
            {
                "name": "Puriton",
                "countryCode": "GB"
            },
            {
                "name": "Purley",
                "countryCode": "GB"
            },
            {
                "name": "Purton",
                "countryCode": "GB"
            },
            {
                "name": "Queenborough",
                "countryCode": "GB"
            },
            {
                "name": "Queensbury",
                "countryCode": "GB"
            },
            {
                "name": "Queniborough",
                "countryCode": "GB"
            },
            {
                "name": "Quorndon",
                "countryCode": "GB"
            },
            {
                "name": "Rackheath",
                "countryCode": "GB"
            },
            {
                "name": "Radcliffe",
                "countryCode": "GB"
            },
            {
                "name": "Radcliffe on Trent",
                "countryCode": "GB"
            },
            {
                "name": "Radlett",
                "countryCode": "GB"
            },
            {
                "name": "Radley",
                "countryCode": "GB"
            },
            {
                "name": "Radstock",
                "countryCode": "GB"
            },
            {
                "name": "Rainford",
                "countryCode": "GB"
            },
            {
                "name": "Rainham",
                "countryCode": "GB"
            },
            {
                "name": "Rainworth",
                "countryCode": "GB"
            },
            {
                "name": "Ramsbottom",
                "countryCode": "GB"
            },
            {
                "name": "Ramsbury",
                "countryCode": "GB"
            },
            {
                "name": "Ramsey",
                "countryCode": "GB"
            },
            {
                "name": "Ramsgate",
                "countryCode": "GB"
            },
            {
                "name": "Ranskill",
                "countryCode": "GB"
            },
            {
                "name": "Rastrick",
                "countryCode": "GB"
            },
            {
                "name": "Ratby",
                "countryCode": "GB"
            },
            {
                "name": "Raunds",
                "countryCode": "GB"
            },
            {
                "name": "Ravenshead",
                "countryCode": "GB"
            },
            {
                "name": "Ravenstone",
                "countryCode": "GB"
            },
            {
                "name": "Rawcliffe",
                "countryCode": "GB"
            },
            {
                "name": "Rawmarsh",
                "countryCode": "GB"
            },
            {
                "name": "Rawtenstall",
                "countryCode": "GB"
            },
            {
                "name": "Rayleigh",
                "countryCode": "GB"
            },
            {
                "name": "Rayne",
                "countryCode": "GB"
            },
            {
                "name": "Reading",
                "countryCode": "GB"
            },
            {
                "name": "Redbourn",
                "countryCode": "GB"
            },
            {
                "name": "Redbourne",
                "countryCode": "GB"
            },
            {
                "name": "Redcar",
                "countryCode": "GB"
            },
            {
                "name": "Redcar and Cleveland",
                "countryCode": "GB"
            },
            {
                "name": "Redditch",
                "countryCode": "GB"
            },
            {
                "name": "Redhill",
                "countryCode": "GB"
            },
            {
                "name": "Redlynch",
                "countryCode": "GB"
            },
            {
                "name": "Redruth",
                "countryCode": "GB"
            },
            {
                "name": "Reedham",
                "countryCode": "GB"
            },
            {
                "name": "Reepham",
                "countryCode": "GB"
            },
            {
                "name": "Reigate",
                "countryCode": "GB"
            },
            {
                "name": "Rendlesham",
                "countryCode": "GB"
            },
            {
                "name": "Repton",
                "countryCode": "GB"
            },
            {
                "name": "Retford",
                "countryCode": "GB"
            },
            {
                "name": "Riccall",
                "countryCode": "GB"
            },
            {
                "name": "Richmond",
                "countryCode": "GB"
            },
            {
                "name": "Rickinghall",
                "countryCode": "GB"
            },
            {
                "name": "Rickmansworth",
                "countryCode": "GB"
            },
            {
                "name": "Ridgmont",
                "countryCode": "GB"
            },
            {
                "name": "Rillington",
                "countryCode": "GB"
            },
            {
                "name": "Ringmer",
                "countryCode": "GB"
            },
            {
                "name": "Ringstead",
                "countryCode": "GB"
            },
            {
                "name": "Ringwood",
                "countryCode": "GB"
            },
            {
                "name": "Ripley",
                "countryCode": "GB"
            },
            {
                "name": "Ripon",
                "countryCode": "GB"
            },
            {
                "name": "Ripponden",
                "countryCode": "GB"
            },
            {
                "name": "Riseley",
                "countryCode": "GB"
            },
            {
                "name": "Rishton",
                "countryCode": "GB"
            },
            {
                "name": "Roade",
                "countryCode": "GB"
            },
            {
                "name": "Robertsbridge",
                "countryCode": "GB"
            },
            {
                "name": "Rochdale",
                "countryCode": "GB"
            },
            {
                "name": "Roche",
                "countryCode": "GB"
            },
            {
                "name": "Rochester",
                "countryCode": "GB"
            },
            {
                "name": "Rochford",
                "countryCode": "GB"
            },
            {
                "name": "Rock",
                "countryCode": "GB"
            },
            {
                "name": "Rode",
                "countryCode": "GB"
            },
            {
                "name": "Rode Heath",
                "countryCode": "GB"
            },
            {
                "name": "Romford",
                "countryCode": "GB"
            },
            {
                "name": "Romney Marsh",
                "countryCode": "GB"
            },
            {
                "name": "Romsey",
                "countryCode": "GB"
            },
            {
                "name": "Romsley",
                "countryCode": "GB"
            },
            {
                "name": "Roos",
                "countryCode": "GB"
            },
            {
                "name": "Ross on Wye",
                "countryCode": "GB"
            },
            {
                "name": "Rossendale",
                "countryCode": "GB"
            },
            {
                "name": "Rothbury",
                "countryCode": "GB"
            },
            {
                "name": "Rotherfield Peppard",
                "countryCode": "GB"
            },
            {
                "name": "Rotherham",
                "countryCode": "GB"
            },
            {
                "name": "Rothley",
                "countryCode": "GB"
            },
            {
                "name": "Rothwell",
                "countryCode": "GB"
            },
            {
                "name": "Rottingdean",
                "countryCode": "GB"
            },
            {
                "name": "Rowde",
                "countryCode": "GB"
            },
            {
                "name": "Rowhedge",
                "countryCode": "GB"
            },
            {
                "name": "Rowlands Gill",
                "countryCode": "GB"
            },
            {
                "name": "Rowley Regis",
                "countryCode": "GB"
            },
            {
                "name": "Roxton",
                "countryCode": "GB"
            },
            {
                "name": "Royal Borough of Windsor and Maidenhead",
                "countryCode": "GB"
            },
            {
                "name": "Royal Leamington Spa",
                "countryCode": "GB"
            },
            {
                "name": "Royal Tunbridge Wells",
                "countryCode": "GB"
            },
            {
                "name": "Royal Wootton Bassett",
                "countryCode": "GB"
            },
            {
                "name": "Roydon",
                "countryCode": "GB"
            },
            {
                "name": "Royston",
                "countryCode": "GB"
            },
            {
                "name": "Royton",
                "countryCode": "GB"
            },
            {
                "name": "Ruardean",
                "countryCode": "GB"
            },
            {
                "name": "Ruddington",
                "countryCode": "GB"
            },
            {
                "name": "Rudgwick",
                "countryCode": "GB"
            },
            {
                "name": "Rufford",
                "countryCode": "GB"
            },
            {
                "name": "Rugby",
                "countryCode": "GB"
            },
            {
                "name": "Rugeley",
                "countryCode": "GB"
            },
            {
                "name": "Ruislip",
                "countryCode": "GB"
            },
            {
                "name": "Runcorn",
                "countryCode": "GB"
            },
            {
                "name": "Rushden",
                "countryCode": "GB"
            },
            {
                "name": "Ruskington",
                "countryCode": "GB"
            },
            {
                "name": "Rusthall",
                "countryCode": "GB"
            },
            {
                "name": "Rustington",
                "countryCode": "GB"
            },
            {
                "name": "Ruyton-XI-Towns",
                "countryCode": "GB"
            },
            {
                "name": "Ryde",
                "countryCode": "GB"
            },
            {
                "name": "Rye",
                "countryCode": "GB"
            },
            {
                "name": "Ryhall",
                "countryCode": "GB"
            },
            {
                "name": "Ryhill",
                "countryCode": "GB"
            },
            {
                "name": "Ryhope",
                "countryCode": "GB"
            },
            {
                "name": "Ryton",
                "countryCode": "GB"
            },
            {
                "name": "Ryton on Dunsmore",
                "countryCode": "GB"
            },
            {
                "name": "Sabden",
                "countryCode": "GB"
            },
            {
                "name": "Sacriston",
                "countryCode": "GB"
            },
            {
                "name": "Saffron Walden",
                "countryCode": "GB"
            },
            {
                "name": "Saint Agnes",
                "countryCode": "GB"
            },
            {
                "name": "Saint Bees",
                "countryCode": "GB"
            },
            {
                "name": "Saint Columb Major",
                "countryCode": "GB"
            },
            {
                "name": "Saint Dennis",
                "countryCode": "GB"
            },
            {
                "name": "Saint Leonards-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Saint Neots",
                "countryCode": "GB"
            },
            {
                "name": "Saint Osyth",
                "countryCode": "GB"
            },
            {
                "name": "Saint Peters",
                "countryCode": "GB"
            },
            {
                "name": "Saint Stephen",
                "countryCode": "GB"
            },
            {
                "name": "Salcombe",
                "countryCode": "GB"
            },
            {
                "name": "Sale",
                "countryCode": "GB"
            },
            {
                "name": "Salford",
                "countryCode": "GB"
            },
            {
                "name": "Salfords",
                "countryCode": "GB"
            },
            {
                "name": "Salisbury",
                "countryCode": "GB"
            },
            {
                "name": "Saltash",
                "countryCode": "GB"
            },
            {
                "name": "Saltburn-by-the-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Saltford",
                "countryCode": "GB"
            },
            {
                "name": "Sampford Peverell",
                "countryCode": "GB"
            },
            {
                "name": "Sandbach",
                "countryCode": "GB"
            },
            {
                "name": "Sandford",
                "countryCode": "GB"
            },
            {
                "name": "Sandown",
                "countryCode": "GB"
            },
            {
                "name": "Sandwell",
                "countryCode": "GB"
            },
            {
                "name": "Sandwich",
                "countryCode": "GB"
            },
            {
                "name": "Sandy",
                "countryCode": "GB"
            },
            {
                "name": "Sapcote",
                "countryCode": "GB"
            },
            {
                "name": "Saughall",
                "countryCode": "GB"
            },
            {
                "name": "Sawbridgeworth",
                "countryCode": "GB"
            },
            {
                "name": "Sawston",
                "countryCode": "GB"
            },
            {
                "name": "Sawtry",
                "countryCode": "GB"
            },
            {
                "name": "Saxilby",
                "countryCode": "GB"
            },
            {
                "name": "Saxmundham",
                "countryCode": "GB"
            },
            {
                "name": "Scalby",
                "countryCode": "GB"
            },
            {
                "name": "Scarborough",
                "countryCode": "GB"
            },
            {
                "name": "Scarcroft",
                "countryCode": "GB"
            },
            {
                "name": "Scawby",
                "countryCode": "GB"
            },
            {
                "name": "Scholes",
                "countryCode": "GB"
            },
            {
                "name": "Scole",
                "countryCode": "GB"
            },
            {
                "name": "Scorton",
                "countryCode": "GB"
            },
            {
                "name": "Scotby",
                "countryCode": "GB"
            },
            {
                "name": "Scotter",
                "countryCode": "GB"
            },
            {
                "name": "Scunthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Seaford",
                "countryCode": "GB"
            },
            {
                "name": "Seaham",
                "countryCode": "GB"
            },
            {
                "name": "Seahouses",
                "countryCode": "GB"
            },
            {
                "name": "Seascale",
                "countryCode": "GB"
            },
            {
                "name": "Seaton",
                "countryCode": "GB"
            },
            {
                "name": "Seaton Delaval",
                "countryCode": "GB"
            },
            {
                "name": "Seaview",
                "countryCode": "GB"
            },
            {
                "name": "Sedbergh",
                "countryCode": "GB"
            },
            {
                "name": "Sedgefield",
                "countryCode": "GB"
            },
            {
                "name": "Seend",
                "countryCode": "GB"
            },
            {
                "name": "Seer Green",
                "countryCode": "GB"
            },
            {
                "name": "Sefton",
                "countryCode": "GB"
            },
            {
                "name": "Seghill",
                "countryCode": "GB"
            },
            {
                "name": "Selby",
                "countryCode": "GB"
            },
            {
                "name": "Selsey",
                "countryCode": "GB"
            },
            {
                "name": "Send",
                "countryCode": "GB"
            },
            {
                "name": "Settle",
                "countryCode": "GB"
            },
            {
                "name": "Sevenoaks",
                "countryCode": "GB"
            },
            {
                "name": "Severn Beach",
                "countryCode": "GB"
            },
            {
                "name": "Shadoxhurst",
                "countryCode": "GB"
            },
            {
                "name": "Shadwell",
                "countryCode": "GB"
            },
            {
                "name": "Shaftesbury",
                "countryCode": "GB"
            },
            {
                "name": "Shalbourne",
                "countryCode": "GB"
            },
            {
                "name": "Shalfleet",
                "countryCode": "GB"
            },
            {
                "name": "Shanklin",
                "countryCode": "GB"
            },
            {
                "name": "Shap",
                "countryCode": "GB"
            },
            {
                "name": "Sharlston",
                "countryCode": "GB"
            },
            {
                "name": "Sharnbrook",
                "countryCode": "GB"
            },
            {
                "name": "Sharpness",
                "countryCode": "GB"
            },
            {
                "name": "Shaw",
                "countryCode": "GB"
            },
            {
                "name": "Shawbury",
                "countryCode": "GB"
            },
            {
                "name": "Sheerness",
                "countryCode": "GB"
            },
            {
                "name": "Sheffield",
                "countryCode": "GB"
            },
            {
                "name": "Shefford",
                "countryCode": "GB"
            },
            {
                "name": "Shelley",
                "countryCode": "GB"
            },
            {
                "name": "Shenley",
                "countryCode": "GB"
            },
            {
                "name": "Shenstone",
                "countryCode": "GB"
            },
            {
                "name": "Shepherdswell",
                "countryCode": "GB"
            },
            {
                "name": "Shepley",
                "countryCode": "GB"
            },
            {
                "name": "Shepperton",
                "countryCode": "GB"
            },
            {
                "name": "Shepshed",
                "countryCode": "GB"
            },
            {
                "name": "Shepton Mallet",
                "countryCode": "GB"
            },
            {
                "name": "Sherborne",
                "countryCode": "GB"
            },
            {
                "name": "Sherborne St John",
                "countryCode": "GB"
            },
            {
                "name": "Sherburn",
                "countryCode": "GB"
            },
            {
                "name": "Sherburn Hill",
                "countryCode": "GB"
            },
            {
                "name": "Sherburn in Elmet",
                "countryCode": "GB"
            },
            {
                "name": "Sheriff Hutton",
                "countryCode": "GB"
            },
            {
                "name": "Sheringham",
                "countryCode": "GB"
            },
            {
                "name": "Sherington",
                "countryCode": "GB"
            },
            {
                "name": "Sherston",
                "countryCode": "GB"
            },
            {
                "name": "Shevington",
                "countryCode": "GB"
            },
            {
                "name": "Shifnal",
                "countryCode": "GB"
            },
            {
                "name": "Shilbottle",
                "countryCode": "GB"
            },
            {
                "name": "Shildon",
                "countryCode": "GB"
            },
            {
                "name": "Shillingstone",
                "countryCode": "GB"
            },
            {
                "name": "Shillington",
                "countryCode": "GB"
            },
            {
                "name": "Shipdham",
                "countryCode": "GB"
            },
            {
                "name": "Shipham",
                "countryCode": "GB"
            },
            {
                "name": "Shipley",
                "countryCode": "GB"
            },
            {
                "name": "Shipston on Stour",
                "countryCode": "GB"
            },
            {
                "name": "Shipton under Wychwood",
                "countryCode": "GB"
            },
            {
                "name": "Shirebrook",
                "countryCode": "GB"
            },
            {
                "name": "Shiremoor",
                "countryCode": "GB"
            },
            {
                "name": "Shirland",
                "countryCode": "GB"
            },
            {
                "name": "Shirley",
                "countryCode": "GB"
            },
            {
                "name": "Shoreham-by-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Shortlands",
                "countryCode": "GB"
            },
            {
                "name": "Shotley Gate",
                "countryCode": "GB"
            },
            {
                "name": "Shrewsbury",
                "countryCode": "GB"
            },
            {
                "name": "Shrewton",
                "countryCode": "GB"
            },
            {
                "name": "Shrivenham",
                "countryCode": "GB"
            },
            {
                "name": "Shropshire",
                "countryCode": "GB"
            },
            {
                "name": "Shurdington",
                "countryCode": "GB"
            },
            {
                "name": "Sible Hedingham",
                "countryCode": "GB"
            },
            {
                "name": "Sibsey",
                "countryCode": "GB"
            },
            {
                "name": "Sidcup",
                "countryCode": "GB"
            },
            {
                "name": "Sidmouth",
                "countryCode": "GB"
            },
            {
                "name": "Sileby",
                "countryCode": "GB"
            },
            {
                "name": "Silkstone",
                "countryCode": "GB"
            },
            {
                "name": "Silloth",
                "countryCode": "GB"
            },
            {
                "name": "Silsden",
                "countryCode": "GB"
            },
            {
                "name": "Silsoe",
                "countryCode": "GB"
            },
            {
                "name": "Silver End",
                "countryCode": "GB"
            },
            {
                "name": "Silverdale",
                "countryCode": "GB"
            },
            {
                "name": "Silverstone",
                "countryCode": "GB"
            },
            {
                "name": "Silverton",
                "countryCode": "GB"
            },
            {
                "name": "Sinfin",
                "countryCode": "GB"
            },
            {
                "name": "Sissinghurst",
                "countryCode": "GB"
            },
            {
                "name": "Sittingbourne",
                "countryCode": "GB"
            },
            {
                "name": "Skegness",
                "countryCode": "GB"
            },
            {
                "name": "Skellingthorpe",
                "countryCode": "GB"
            },
            {
                "name": "Skelmersdale",
                "countryCode": "GB"
            },
            {
                "name": "Skelton",
                "countryCode": "GB"
            },
            {
                "name": "Skidby",
                "countryCode": "GB"
            },
            {
                "name": "Skinningrove",
                "countryCode": "GB"
            },
            {
                "name": "Skipsea",
                "countryCode": "GB"
            },
            {
                "name": "Skipton",
                "countryCode": "GB"
            },
            {
                "name": "Slaley",
                "countryCode": "GB"
            },
            {
                "name": "Sleaford",
                "countryCode": "GB"
            },
            {
                "name": "Sleights",
                "countryCode": "GB"
            },
            {
                "name": "Slinfold",
                "countryCode": "GB"
            },
            {
                "name": "Slough",
                "countryCode": "GB"
            },
            {
                "name": "Smethwick",
                "countryCode": "GB"
            },
            {
                "name": "Snaith",
                "countryCode": "GB"
            },
            {
                "name": "Snettisham",
                "countryCode": "GB"
            },
            {
                "name": "Snodland",
                "countryCode": "GB"
            },
            {
                "name": "Soham",
                "countryCode": "GB"
            },
            {
                "name": "Solihull",
                "countryCode": "GB"
            },
            {
                "name": "Somerset",
                "countryCode": "GB"
            },
            {
                "name": "Somersham",
                "countryCode": "GB"
            },
            {
                "name": "Somerton",
                "countryCode": "GB"
            },
            {
                "name": "Sonning Common",
                "countryCode": "GB"
            },
            {
                "name": "South Benfleet",
                "countryCode": "GB"
            },
            {
                "name": "South Brent",
                "countryCode": "GB"
            },
            {
                "name": "South Cave",
                "countryCode": "GB"
            },
            {
                "name": "South Cerney",
                "countryCode": "GB"
            },
            {
                "name": "South Chailey",
                "countryCode": "GB"
            },
            {
                "name": "South Collingham",
                "countryCode": "GB"
            },
            {
                "name": "South Croydon",
                "countryCode": "GB"
            },
            {
                "name": "South Elmsall",
                "countryCode": "GB"
            },
            {
                "name": "South Gloucestershire",
                "countryCode": "GB"
            },
            {
                "name": "South Harting",
                "countryCode": "GB"
            },
            {
                "name": "South Hayling",
                "countryCode": "GB"
            },
            {
                "name": "South Hetton",
                "countryCode": "GB"
            },
            {
                "name": "South Littleton",
                "countryCode": "GB"
            },
            {
                "name": "South Luffenham",
                "countryCode": "GB"
            },
            {
                "name": "South Milford",
                "countryCode": "GB"
            },
            {
                "name": "South Molton",
                "countryCode": "GB"
            },
            {
                "name": "South Nutfield",
                "countryCode": "GB"
            },
            {
                "name": "South Ockendon",
                "countryCode": "GB"
            },
            {
                "name": "South Petherton",
                "countryCode": "GB"
            },
            {
                "name": "South Shields",
                "countryCode": "GB"
            },
            {
                "name": "South Tyneside",
                "countryCode": "GB"
            },
            {
                "name": "South Wingfield",
                "countryCode": "GB"
            },
            {
                "name": "South Witham",
                "countryCode": "GB"
            },
            {
                "name": "Southall",
                "countryCode": "GB"
            },
            {
                "name": "Southam",
                "countryCode": "GB"
            },
            {
                "name": "Southampton",
                "countryCode": "GB"
            },
            {
                "name": "Southchurch Village",
                "countryCode": "GB"
            },
            {
                "name": "Southend-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Southery",
                "countryCode": "GB"
            },
            {
                "name": "Southminster",
                "countryCode": "GB"
            },
            {
                "name": "Southowram",
                "countryCode": "GB"
            },
            {
                "name": "Southport",
                "countryCode": "GB"
            },
            {
                "name": "Southsea",
                "countryCode": "GB"
            },
            {
                "name": "Southwater",
                "countryCode": "GB"
            },
            {
                "name": "Southwell",
                "countryCode": "GB"
            },
            {
                "name": "Southwick",
                "countryCode": "GB"
            },
            {
                "name": "Southwold",
                "countryCode": "GB"
            },
            {
                "name": "Sowerby Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Spalding",
                "countryCode": "GB"
            },
            {
                "name": "Speldhurst",
                "countryCode": "GB"
            },
            {
                "name": "Spennymoor",
                "countryCode": "GB"
            },
            {
                "name": "Spilsby",
                "countryCode": "GB"
            },
            {
                "name": "Spixworth",
                "countryCode": "GB"
            },
            {
                "name": "Spofforth",
                "countryCode": "GB"
            },
            {
                "name": "Spratton",
                "countryCode": "GB"
            },
            {
                "name": "Sproatley",
                "countryCode": "GB"
            },
            {
                "name": "St Albans",
                "countryCode": "GB"
            },
            {
                "name": "St Austell",
                "countryCode": "GB"
            },
            {
                "name": "St Helens",
                "countryCode": "GB"
            },
            {
                "name": "St Ives",
                "countryCode": "GB"
            },
            {
                "name": "St Just",
                "countryCode": "GB"
            },
            {
                "name": "St Leonards",
                "countryCode": "GB"
            },
            {
                "name": "St Mary's",
                "countryCode": "GB"
            },
            {
                "name": "St Mary's Bay",
                "countryCode": "GB"
            },
            {
                "name": "St. Day",
                "countryCode": "GB"
            },
            {
                "name": "St. Helens",
                "countryCode": "GB"
            },
            {
                "name": "Stafford",
                "countryCode": "GB"
            },
            {
                "name": "Staffordshire",
                "countryCode": "GB"
            },
            {
                "name": "Stagsden",
                "countryCode": "GB"
            },
            {
                "name": "Stainburn",
                "countryCode": "GB"
            },
            {
                "name": "Staindrop",
                "countryCode": "GB"
            },
            {
                "name": "Staines",
                "countryCode": "GB"
            },
            {
                "name": "Stainforth",
                "countryCode": "GB"
            },
            {
                "name": "Stakeford",
                "countryCode": "GB"
            },
            {
                "name": "Stalbridge",
                "countryCode": "GB"
            },
            {
                "name": "Stalham",
                "countryCode": "GB"
            },
            {
                "name": "Stallingborough",
                "countryCode": "GB"
            },
            {
                "name": "Stalybridge",
                "countryCode": "GB"
            },
            {
                "name": "Stamford",
                "countryCode": "GB"
            },
            {
                "name": "Stamford Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Stanbridge",
                "countryCode": "GB"
            },
            {
                "name": "Standlake",
                "countryCode": "GB"
            },
            {
                "name": "Standon",
                "countryCode": "GB"
            },
            {
                "name": "Stanford in the Vale",
                "countryCode": "GB"
            },
            {
                "name": "Stanford-le-Hope",
                "countryCode": "GB"
            },
            {
                "name": "Stanhope",
                "countryCode": "GB"
            },
            {
                "name": "Stanley",
                "countryCode": "GB"
            },
            {
                "name": "Stanmore",
                "countryCode": "GB"
            },
            {
                "name": "Stannington",
                "countryCode": "GB"
            },
            {
                "name": "Stansted Mountfitchet",
                "countryCode": "GB"
            },
            {
                "name": "Stanwick",
                "countryCode": "GB"
            },
            {
                "name": "Staplehurst",
                "countryCode": "GB"
            },
            {
                "name": "Starcross",
                "countryCode": "GB"
            },
            {
                "name": "Startforth",
                "countryCode": "GB"
            },
            {
                "name": "Staveley",
                "countryCode": "GB"
            },
            {
                "name": "Steeple Bumpstead",
                "countryCode": "GB"
            },
            {
                "name": "Steeple Claydon",
                "countryCode": "GB"
            },
            {
                "name": "Steeton",
                "countryCode": "GB"
            },
            {
                "name": "Stevenage",
                "countryCode": "GB"
            },
            {
                "name": "Steventon",
                "countryCode": "GB"
            },
            {
                "name": "Stevington",
                "countryCode": "GB"
            },
            {
                "name": "Stewartby",
                "countryCode": "GB"
            },
            {
                "name": "Stewkley",
                "countryCode": "GB"
            },
            {
                "name": "Steyning",
                "countryCode": "GB"
            },
            {
                "name": "Stickney",
                "countryCode": "GB"
            },
            {
                "name": "Stillington",
                "countryCode": "GB"
            },
            {
                "name": "Stilton",
                "countryCode": "GB"
            },
            {
                "name": "Stithians",
                "countryCode": "GB"
            },
            {
                "name": "Stock",
                "countryCode": "GB"
            },
            {
                "name": "Stockport",
                "countryCode": "GB"
            },
            {
                "name": "Stocksbridge",
                "countryCode": "GB"
            },
            {
                "name": "Stocksfield",
                "countryCode": "GB"
            },
            {
                "name": "Stockton",
                "countryCode": "GB"
            },
            {
                "name": "Stockton Heath",
                "countryCode": "GB"
            },
            {
                "name": "Stockton-on-Tees",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Ferry",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Gabriel",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Gifford",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Golding",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Goldington",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Poges",
                "countryCode": "GB"
            },
            {
                "name": "Stoke Prior",
                "countryCode": "GB"
            },
            {
                "name": "Stoke-on-Trent",
                "countryCode": "GB"
            },
            {
                "name": "Stoke-sub-Hamdon",
                "countryCode": "GB"
            },
            {
                "name": "Stokenchurch",
                "countryCode": "GB"
            },
            {
                "name": "Stokesley",
                "countryCode": "GB"
            },
            {
                "name": "Stone",
                "countryCode": "GB"
            },
            {
                "name": "Stonehouse",
                "countryCode": "GB"
            },
            {
                "name": "Stonesfield",
                "countryCode": "GB"
            },
            {
                "name": "Stoney Stanton",
                "countryCode": "GB"
            },
            {
                "name": "Storrington",
                "countryCode": "GB"
            },
            {
                "name": "Stotfold",
                "countryCode": "GB"
            },
            {
                "name": "Stourbridge",
                "countryCode": "GB"
            },
            {
                "name": "Stourport-on-Severn",
                "countryCode": "GB"
            },
            {
                "name": "Stow on the Wold",
                "countryCode": "GB"
            },
            {
                "name": "Stowmarket",
                "countryCode": "GB"
            },
            {
                "name": "Stradbroke",
                "countryCode": "GB"
            },
            {
                "name": "Stratfield Mortimer",
                "countryCode": "GB"
            },
            {
                "name": "Stratford-upon-Avon",
                "countryCode": "GB"
            },
            {
                "name": "Stratton",
                "countryCode": "GB"
            },
            {
                "name": "Streatley",
                "countryCode": "GB"
            },
            {
                "name": "Street",
                "countryCode": "GB"
            },
            {
                "name": "Streetly",
                "countryCode": "GB"
            },
            {
                "name": "Strensall",
                "countryCode": "GB"
            },
            {
                "name": "Stretford",
                "countryCode": "GB"
            },
            {
                "name": "Stretham",
                "countryCode": "GB"
            },
            {
                "name": "Strood",
                "countryCode": "GB"
            },
            {
                "name": "Stroud",
                "countryCode": "GB"
            },
            {
                "name": "Studley",
                "countryCode": "GB"
            },
            {
                "name": "Sturminster Marshall",
                "countryCode": "GB"
            },
            {
                "name": "Sturminster Newton",
                "countryCode": "GB"
            },
            {
                "name": "Sturry",
                "countryCode": "GB"
            },
            {
                "name": "Sudbrooke",
                "countryCode": "GB"
            },
            {
                "name": "Sudbury",
                "countryCode": "GB"
            },
            {
                "name": "Suffolk",
                "countryCode": "GB"
            },
            {
                "name": "Sunbury-on-Thames",
                "countryCode": "GB"
            },
            {
                "name": "Sunderland",
                "countryCode": "GB"
            },
            {
                "name": "Sundridge",
                "countryCode": "GB"
            },
            {
                "name": "Surbiton",
                "countryCode": "GB"
            },
            {
                "name": "Surrey",
                "countryCode": "GB"
            },
            {
                "name": "Sutterton",
                "countryCode": "GB"
            },
            {
                "name": "Sutton",
                "countryCode": "GB"
            },
            {
                "name": "Sutton Benger",
                "countryCode": "GB"
            },
            {
                "name": "Sutton Bonington",
                "countryCode": "GB"
            },
            {
                "name": "Sutton Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Sutton Coldfield",
                "countryCode": "GB"
            },
            {
                "name": "Sutton Courtenay",
                "countryCode": "GB"
            },
            {
                "name": "Sutton in Ashfield",
                "countryCode": "GB"
            },
            {
                "name": "Sutton on Trent",
                "countryCode": "GB"
            },
            {
                "name": "Sutton upon Derwent",
                "countryCode": "GB"
            },
            {
                "name": "Swadlincote",
                "countryCode": "GB"
            },
            {
                "name": "Swaffham",
                "countryCode": "GB"
            },
            {
                "name": "Swanage",
                "countryCode": "GB"
            },
            {
                "name": "Swanley",
                "countryCode": "GB"
            },
            {
                "name": "Swanmore",
                "countryCode": "GB"
            },
            {
                "name": "Swanscombe",
                "countryCode": "GB"
            },
            {
                "name": "Swarthmoor",
                "countryCode": "GB"
            },
            {
                "name": "Swavesey",
                "countryCode": "GB"
            },
            {
                "name": "Sway",
                "countryCode": "GB"
            },
            {
                "name": "Swillington",
                "countryCode": "GB"
            },
            {
                "name": "Swindon",
                "countryCode": "GB"
            },
            {
                "name": "Swineshead",
                "countryCode": "GB"
            },
            {
                "name": "Swinton",
                "countryCode": "GB"
            },
            {
                "name": "Syston",
                "countryCode": "GB"
            },
            {
                "name": "Sywell",
                "countryCode": "GB"
            },
            {
                "name": "Tadcaster",
                "countryCode": "GB"
            },
            {
                "name": "Tadley",
                "countryCode": "GB"
            },
            {
                "name": "Tadworth",
                "countryCode": "GB"
            },
            {
                "name": "Takeley",
                "countryCode": "GB"
            },
            {
                "name": "Tamworth",
                "countryCode": "GB"
            },
            {
                "name": "Tanfield",
                "countryCode": "GB"
            },
            {
                "name": "Tangmere",
                "countryCode": "GB"
            },
            {
                "name": "Tankerton",
                "countryCode": "GB"
            },
            {
                "name": "Tansley",
                "countryCode": "GB"
            },
            {
                "name": "Tarleton",
                "countryCode": "GB"
            },
            {
                "name": "Tarporley",
                "countryCode": "GB"
            },
            {
                "name": "Tarvin",
                "countryCode": "GB"
            },
            {
                "name": "Tattenhall",
                "countryCode": "GB"
            },
            {
                "name": "Taunton",
                "countryCode": "GB"
            },
            {
                "name": "Tavistock",
                "countryCode": "GB"
            },
            {
                "name": "Teddington",
                "countryCode": "GB"
            },
            {
                "name": "Teignmouth",
                "countryCode": "GB"
            },
            {
                "name": "Telford",
                "countryCode": "GB"
            },
            {
                "name": "Telford and Wrekin",
                "countryCode": "GB"
            },
            {
                "name": "Templecombe",
                "countryCode": "GB"
            },
            {
                "name": "Tempsford",
                "countryCode": "GB"
            },
            {
                "name": "Tenbury Wells",
                "countryCode": "GB"
            },
            {
                "name": "Tenterden",
                "countryCode": "GB"
            },
            {
                "name": "Terrington Saint John",
                "countryCode": "GB"
            },
            {
                "name": "Terrington St Clement",
                "countryCode": "GB"
            },
            {
                "name": "Tetbury",
                "countryCode": "GB"
            },
            {
                "name": "Tetney",
                "countryCode": "GB"
            },
            {
                "name": "Tewkesbury",
                "countryCode": "GB"
            },
            {
                "name": "Teynham",
                "countryCode": "GB"
            },
            {
                "name": "Thame",
                "countryCode": "GB"
            },
            {
                "name": "Thames Ditton",
                "countryCode": "GB"
            },
            {
                "name": "Thatcham",
                "countryCode": "GB"
            },
            {
                "name": "Thaxted",
                "countryCode": "GB"
            },
            {
                "name": "The Boldons",
                "countryCode": "GB"
            },
            {
                "name": "Theale",
                "countryCode": "GB"
            },
            {
                "name": "Thetford",
                "countryCode": "GB"
            },
            {
                "name": "Theydon Bois",
                "countryCode": "GB"
            },
            {
                "name": "Thirsk",
                "countryCode": "GB"
            },
            {
                "name": "Thornaby-on-Tees",
                "countryCode": "GB"
            },
            {
                "name": "Thornbury",
                "countryCode": "GB"
            },
            {
                "name": "Thorne",
                "countryCode": "GB"
            },
            {
                "name": "Thorner",
                "countryCode": "GB"
            },
            {
                "name": "Thorney",
                "countryCode": "GB"
            },
            {
                "name": "Thorngumbald",
                "countryCode": "GB"
            },
            {
                "name": "Thornley",
                "countryCode": "GB"
            },
            {
                "name": "Thornton Dale",
                "countryCode": "GB"
            },
            {
                "name": "Thornton Heath",
                "countryCode": "GB"
            },
            {
                "name": "Thornton-Cleveleys",
                "countryCode": "GB"
            },
            {
                "name": "Thorp Arch",
                "countryCode": "GB"
            },
            {
                "name": "Thorpe Hamlet",
                "countryCode": "GB"
            },
            {
                "name": "Thorpe le Soken",
                "countryCode": "GB"
            },
            {
                "name": "Thrapston",
                "countryCode": "GB"
            },
            {
                "name": "Three Legged Cross",
                "countryCode": "GB"
            },
            {
                "name": "Thurlby",
                "countryCode": "GB"
            },
            {
                "name": "Thurlton",
                "countryCode": "GB"
            },
            {
                "name": "Thurston",
                "countryCode": "GB"
            },
            {
                "name": "Tibshelf",
                "countryCode": "GB"
            },
            {
                "name": "Ticehurst",
                "countryCode": "GB"
            },
            {
                "name": "Tickhill",
                "countryCode": "GB"
            },
            {
                "name": "Tickton",
                "countryCode": "GB"
            },
            {
                "name": "Tideswell",
                "countryCode": "GB"
            },
            {
                "name": "Tidworth",
                "countryCode": "GB"
            },
            {
                "name": "Tilbury",
                "countryCode": "GB"
            },
            {
                "name": "Timperley",
                "countryCode": "GB"
            },
            {
                "name": "Timsbury",
                "countryCode": "GB"
            },
            {
                "name": "Tingewick",
                "countryCode": "GB"
            },
            {
                "name": "Tintagel",
                "countryCode": "GB"
            },
            {
                "name": "Tipton",
                "countryCode": "GB"
            },
            {
                "name": "Tiptree",
                "countryCode": "GB"
            },
            {
                "name": "Tisbury",
                "countryCode": "GB"
            },
            {
                "name": "Tiverton",
                "countryCode": "GB"
            },
            {
                "name": "Toddington",
                "countryCode": "GB"
            },
            {
                "name": "Todmorden",
                "countryCode": "GB"
            },
            {
                "name": "Todwick",
                "countryCode": "GB"
            },
            {
                "name": "Tollesbury",
                "countryCode": "GB"
            },
            {
                "name": "Tolleshunt Knights",
                "countryCode": "GB"
            },
            {
                "name": "Tonbridge",
                "countryCode": "GB"
            },
            {
                "name": "Topsham",
                "countryCode": "GB"
            },
            {
                "name": "Torpoint",
                "countryCode": "GB"
            },
            {
                "name": "Torquay",
                "countryCode": "GB"
            },
            {
                "name": "Totnes",
                "countryCode": "GB"
            },
            {
                "name": "Totternhoe",
                "countryCode": "GB"
            },
            {
                "name": "Tottington",
                "countryCode": "GB"
            },
            {
                "name": "Totton",
                "countryCode": "GB"
            },
            {
                "name": "Tow Law",
                "countryCode": "GB"
            },
            {
                "name": "Towcester",
                "countryCode": "GB"
            },
            {
                "name": "Town Row",
                "countryCode": "GB"
            },
            {
                "name": "Trafford",
                "countryCode": "GB"
            },
            {
                "name": "Trafford Park",
                "countryCode": "GB"
            },
            {
                "name": "Treeton",
                "countryCode": "GB"
            },
            {
                "name": "Trimdon",
                "countryCode": "GB"
            },
            {
                "name": "Trimdon Grange",
                "countryCode": "GB"
            },
            {
                "name": "Tring",
                "countryCode": "GB"
            },
            {
                "name": "Trowbridge",
                "countryCode": "GB"
            },
            {
                "name": "Truro",
                "countryCode": "GB"
            },
            {
                "name": "Tunstall",
                "countryCode": "GB"
            },
            {
                "name": "Tuxford",
                "countryCode": "GB"
            },
            {
                "name": "Twyford",
                "countryCode": "GB"
            },
            {
                "name": "Tyldesley",
                "countryCode": "GB"
            },
            {
                "name": "Tynemouth",
                "countryCode": "GB"
            },
            {
                "name": "Tytherington",
                "countryCode": "GB"
            },
            {
                "name": "Uckfield",
                "countryCode": "GB"
            },
            {
                "name": "Uffculme",
                "countryCode": "GB"
            },
            {
                "name": "Ulceby",
                "countryCode": "GB"
            },
            {
                "name": "Ulrome",
                "countryCode": "GB"
            },
            {
                "name": "Ulverston",
                "countryCode": "GB"
            },
            {
                "name": "Uny Lelant",
                "countryCode": "GB"
            },
            {
                "name": "Upchurch",
                "countryCode": "GB"
            },
            {
                "name": "Upminster",
                "countryCode": "GB"
            },
            {
                "name": "Upper Basildon",
                "countryCode": "GB"
            },
            {
                "name": "Upper Langwith",
                "countryCode": "GB"
            },
            {
                "name": "Upper Poppleton",
                "countryCode": "GB"
            },
            {
                "name": "Uppingham",
                "countryCode": "GB"
            },
            {
                "name": "Upton",
                "countryCode": "GB"
            },
            {
                "name": "Upton Scudamore",
                "countryCode": "GB"
            },
            {
                "name": "Upton upon Severn",
                "countryCode": "GB"
            },
            {
                "name": "Upwell",
                "countryCode": "GB"
            },
            {
                "name": "Urmston",
                "countryCode": "GB"
            },
            {
                "name": "Ushaw Moor",
                "countryCode": "GB"
            },
            {
                "name": "Uttoxeter",
                "countryCode": "GB"
            },
            {
                "name": "Ventnor",
                "countryCode": "GB"
            },
            {
                "name": "Verwood",
                "countryCode": "GB"
            },
            {
                "name": "Virginia Water",
                "countryCode": "GB"
            },
            {
                "name": "Waddesdon",
                "countryCode": "GB"
            },
            {
                "name": "Waddington",
                "countryCode": "GB"
            },
            {
                "name": "Wadebridge",
                "countryCode": "GB"
            },
            {
                "name": "Wadhurst",
                "countryCode": "GB"
            },
            {
                "name": "Wadworth",
                "countryCode": "GB"
            },
            {
                "name": "Wainfleet All Saints",
                "countryCode": "GB"
            },
            {
                "name": "Wakefield",
                "countryCode": "GB"
            },
            {
                "name": "Walberton",
                "countryCode": "GB"
            },
            {
                "name": "Wales",
                "countryCode": "GB"
            },
            {
                "name": "Walkden",
                "countryCode": "GB"
            },
            {
                "name": "Walkern",
                "countryCode": "GB"
            },
            {
                "name": "Walkington",
                "countryCode": "GB"
            },
            {
                "name": "Wallasey",
                "countryCode": "GB"
            },
            {
                "name": "Wallingford",
                "countryCode": "GB"
            },
            {
                "name": "Wallsend",
                "countryCode": "GB"
            },
            {
                "name": "Walsall",
                "countryCode": "GB"
            },
            {
                "name": "Walsden",
                "countryCode": "GB"
            },
            {
                "name": "Waltham",
                "countryCode": "GB"
            },
            {
                "name": "Waltham Abbey",
                "countryCode": "GB"
            },
            {
                "name": "Waltham Cross",
                "countryCode": "GB"
            },
            {
                "name": "Walton",
                "countryCode": "GB"
            },
            {
                "name": "Walton-on-Thames",
                "countryCode": "GB"
            },
            {
                "name": "Walton-on-the-Naze",
                "countryCode": "GB"
            },
            {
                "name": "Wanborough",
                "countryCode": "GB"
            },
            {
                "name": "Wansford",
                "countryCode": "GB"
            },
            {
                "name": "Wantage",
                "countryCode": "GB"
            },
            {
                "name": "Warboys",
                "countryCode": "GB"
            },
            {
                "name": "Wardle",
                "countryCode": "GB"
            },
            {
                "name": "Ware",
                "countryCode": "GB"
            },
            {
                "name": "Wareham",
                "countryCode": "GB"
            },
            {
                "name": "Wargrave",
                "countryCode": "GB"
            },
            {
                "name": "Warkworth",
                "countryCode": "GB"
            },
            {
                "name": "Warlingham",
                "countryCode": "GB"
            },
            {
                "name": "Warminster",
                "countryCode": "GB"
            },
            {
                "name": "Warnham",
                "countryCode": "GB"
            },
            {
                "name": "Warrington",
                "countryCode": "GB"
            },
            {
                "name": "Warsop",
                "countryCode": "GB"
            },
            {
                "name": "Warton",
                "countryCode": "GB"
            },
            {
                "name": "Warwick",
                "countryCode": "GB"
            },
            {
                "name": "Warwickshire",
                "countryCode": "GB"
            },
            {
                "name": "Washingborough",
                "countryCode": "GB"
            },
            {
                "name": "Washington",
                "countryCode": "GB"
            },
            {
                "name": "Washwood Heath",
                "countryCode": "GB"
            },
            {
                "name": "Watchet",
                "countryCode": "GB"
            },
            {
                "name": "Water Eaton",
                "countryCode": "GB"
            },
            {
                "name": "Water Orton",
                "countryCode": "GB"
            },
            {
                "name": "Waterbeach",
                "countryCode": "GB"
            },
            {
                "name": "Wateringbury",
                "countryCode": "GB"
            },
            {
                "name": "Waterloo",
                "countryCode": "GB"
            },
            {
                "name": "Waterlooville",
                "countryCode": "GB"
            },
            {
                "name": "Watford",
                "countryCode": "GB"
            },
            {
                "name": "Wath upon Dearne",
                "countryCode": "GB"
            },
            {
                "name": "Watlington",
                "countryCode": "GB"
            },
            {
                "name": "Wattisham",
                "countryCode": "GB"
            },
            {
                "name": "Watton",
                "countryCode": "GB"
            },
            {
                "name": "Watton at Stone",
                "countryCode": "GB"
            },
            {
                "name": "Weaverham",
                "countryCode": "GB"
            },
            {
                "name": "Wedmore",
                "countryCode": "GB"
            },
            {
                "name": "Wednesbury",
                "countryCode": "GB"
            },
            {
                "name": "Wednesfield",
                "countryCode": "GB"
            },
            {
                "name": "Weedon Beck",
                "countryCode": "GB"
            },
            {
                "name": "Weeting",
                "countryCode": "GB"
            },
            {
                "name": "Welford",
                "countryCode": "GB"
            },
            {
                "name": "Wellesbourne",
                "countryCode": "GB"
            },
            {
                "name": "Wellesbourne Mountford",
                "countryCode": "GB"
            },
            {
                "name": "Welling",
                "countryCode": "GB"
            },
            {
                "name": "Wellingborough",
                "countryCode": "GB"
            },
            {
                "name": "Wellington",
                "countryCode": "GB"
            },
            {
                "name": "Wellow",
                "countryCode": "GB"
            },
            {
                "name": "Wells",
                "countryCode": "GB"
            },
            {
                "name": "Wells-next-the-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Welwyn",
                "countryCode": "GB"
            },
            {
                "name": "Welwyn Garden City",
                "countryCode": "GB"
            },
            {
                "name": "Wem",
                "countryCode": "GB"
            },
            {
                "name": "Wembley",
                "countryCode": "GB"
            },
            {
                "name": "Wembury",
                "countryCode": "GB"
            },
            {
                "name": "Wendover",
                "countryCode": "GB"
            },
            {
                "name": "Wentworth",
                "countryCode": "GB"
            },
            {
                "name": "Weobley",
                "countryCode": "GB"
            },
            {
                "name": "West Bergholt",
                "countryCode": "GB"
            },
            {
                "name": "West Berkshire",
                "countryCode": "GB"
            },
            {
                "name": "West Bridgford",
                "countryCode": "GB"
            },
            {
                "name": "West Bromwich",
                "countryCode": "GB"
            },
            {
                "name": "West Byfleet",
                "countryCode": "GB"
            },
            {
                "name": "West Clandon",
                "countryCode": "GB"
            },
            {
                "name": "West Coker",
                "countryCode": "GB"
            },
            {
                "name": "West Cornforth",
                "countryCode": "GB"
            },
            {
                "name": "West Drayton",
                "countryCode": "GB"
            },
            {
                "name": "West End",
                "countryCode": "GB"
            },
            {
                "name": "West End of London",
                "countryCode": "GB"
            },
            {
                "name": "West Haddon",
                "countryCode": "GB"
            },
            {
                "name": "West Hallam",
                "countryCode": "GB"
            },
            {
                "name": "West Horsley",
                "countryCode": "GB"
            },
            {
                "name": "West Ilsley",
                "countryCode": "GB"
            },
            {
                "name": "West Kingsdown",
                "countryCode": "GB"
            },
            {
                "name": "West Kirby",
                "countryCode": "GB"
            },
            {
                "name": "West Malling",
                "countryCode": "GB"
            },
            {
                "name": "West Mersea",
                "countryCode": "GB"
            },
            {
                "name": "West Molesey",
                "countryCode": "GB"
            },
            {
                "name": "West Rainton",
                "countryCode": "GB"
            },
            {
                "name": "West Sussex",
                "countryCode": "GB"
            },
            {
                "name": "West Thurrock",
                "countryCode": "GB"
            },
            {
                "name": "West Walton",
                "countryCode": "GB"
            },
            {
                "name": "West Wellow",
                "countryCode": "GB"
            },
            {
                "name": "West Wickham",
                "countryCode": "GB"
            },
            {
                "name": "Westbury",
                "countryCode": "GB"
            },
            {
                "name": "Westcliff-on-Sea",
                "countryCode": "GB"
            },
            {
                "name": "Westcott",
                "countryCode": "GB"
            },
            {
                "name": "Westergate",
                "countryCode": "GB"
            },
            {
                "name": "Westerham",
                "countryCode": "GB"
            },
            {
                "name": "Westfield",
                "countryCode": "GB"
            },
            {
                "name": "Westgate on Sea",
                "countryCode": "GB"
            },
            {
                "name": "Westhoughton",
                "countryCode": "GB"
            },
            {
                "name": "Weston",
                "countryCode": "GB"
            },
            {
                "name": "Weston Turville",
                "countryCode": "GB"
            },
            {
                "name": "Weston-super-Mare",
                "countryCode": "GB"
            },
            {
                "name": "Westoning",
                "countryCode": "GB"
            },
            {
                "name": "Westonzoyland",
                "countryCode": "GB"
            },
            {
                "name": "Westwood",
                "countryCode": "GB"
            },
            {
                "name": "Wetheral",
                "countryCode": "GB"
            },
            {
                "name": "Wetherby",
                "countryCode": "GB"
            },
            {
                "name": "Wetwang",
                "countryCode": "GB"
            },
            {
                "name": "Weybridge",
                "countryCode": "GB"
            },
            {
                "name": "Weymouth",
                "countryCode": "GB"
            },
            {
                "name": "Whaley Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Whalley",
                "countryCode": "GB"
            },
            {
                "name": "Whaplode",
                "countryCode": "GB"
            },
            {
                "name": "Wheathampstead",
                "countryCode": "GB"
            },
            {
                "name": "Wheatley",
                "countryCode": "GB"
            },
            {
                "name": "Wheaton Aston",
                "countryCode": "GB"
            },
            {
                "name": "Wheldrake",
                "countryCode": "GB"
            },
            {
                "name": "Whickham",
                "countryCode": "GB"
            },
            {
                "name": "Whimple",
                "countryCode": "GB"
            },
            {
                "name": "Whitburn",
                "countryCode": "GB"
            },
            {
                "name": "Whitby",
                "countryCode": "GB"
            },
            {
                "name": "Whitchurch",
                "countryCode": "GB"
            },
            {
                "name": "White Waltham",
                "countryCode": "GB"
            },
            {
                "name": "Whitefield",
                "countryCode": "GB"
            },
            {
                "name": "Whitehaven",
                "countryCode": "GB"
            },
            {
                "name": "Whiteparish",
                "countryCode": "GB"
            },
            {
                "name": "Whitley",
                "countryCode": "GB"
            },
            {
                "name": "Whitley Bay",
                "countryCode": "GB"
            },
            {
                "name": "Whitstable",
                "countryCode": "GB"
            },
            {
                "name": "Whittingham",
                "countryCode": "GB"
            },
            {
                "name": "Whittington",
                "countryCode": "GB"
            },
            {
                "name": "Whittlesey",
                "countryCode": "GB"
            },
            {
                "name": "Whittlesford",
                "countryCode": "GB"
            },
            {
                "name": "Whitwell",
                "countryCode": "GB"
            },
            {
                "name": "Whitworth",
                "countryCode": "GB"
            },
            {
                "name": "Whyteleafe",
                "countryCode": "GB"
            },
            {
                "name": "Wick",
                "countryCode": "GB"
            },
            {
                "name": "Wickford",
                "countryCode": "GB"
            },
            {
                "name": "Wickham",
                "countryCode": "GB"
            },
            {
                "name": "Wickham Bishops",
                "countryCode": "GB"
            },
            {
                "name": "Wickham Market",
                "countryCode": "GB"
            },
            {
                "name": "Wickwar",
                "countryCode": "GB"
            },
            {
                "name": "Wideopen",
                "countryCode": "GB"
            },
            {
                "name": "Widnes",
                "countryCode": "GB"
            },
            {
                "name": "Wigan",
                "countryCode": "GB"
            },
            {
                "name": "Wigmore",
                "countryCode": "GB"
            },
            {
                "name": "Wigston Magna",
                "countryCode": "GB"
            },
            {
                "name": "Wigton",
                "countryCode": "GB"
            },
            {
                "name": "Wilberfoss",
                "countryCode": "GB"
            },
            {
                "name": "Wilburton",
                "countryCode": "GB"
            },
            {
                "name": "Willand",
                "countryCode": "GB"
            },
            {
                "name": "Willaston",
                "countryCode": "GB"
            },
            {
                "name": "Willenhall",
                "countryCode": "GB"
            },
            {
                "name": "Willingham",
                "countryCode": "GB"
            },
            {
                "name": "Willington",
                "countryCode": "GB"
            },
            {
                "name": "Williton",
                "countryCode": "GB"
            },
            {
                "name": "Wilmcote",
                "countryCode": "GB"
            },
            {
                "name": "Wilmslow",
                "countryCode": "GB"
            },
            {
                "name": "Wilsden",
                "countryCode": "GB"
            },
            {
                "name": "Wilstead",
                "countryCode": "GB"
            },
            {
                "name": "Wilton",
                "countryCode": "GB"
            },
            {
                "name": "Wiltshire",
                "countryCode": "GB"
            },
            {
                "name": "Wimblington",
                "countryCode": "GB"
            },
            {
                "name": "Wimborne Minster",
                "countryCode": "GB"
            },
            {
                "name": "Wincanton",
                "countryCode": "GB"
            },
            {
                "name": "Winchcombe",
                "countryCode": "GB"
            },
            {
                "name": "Winchelsea Beach",
                "countryCode": "GB"
            },
            {
                "name": "Winchester",
                "countryCode": "GB"
            },
            {
                "name": "Windermere",
                "countryCode": "GB"
            },
            {
                "name": "Windlesham",
                "countryCode": "GB"
            },
            {
                "name": "Windsor",
                "countryCode": "GB"
            },
            {
                "name": "Winford",
                "countryCode": "GB"
            },
            {
                "name": "Wing",
                "countryCode": "GB"
            },
            {
                "name": "Wingate",
                "countryCode": "GB"
            },
            {
                "name": "Wingerworth",
                "countryCode": "GB"
            },
            {
                "name": "Wingham",
                "countryCode": "GB"
            },
            {
                "name": "Wingrave",
                "countryCode": "GB"
            },
            {
                "name": "Winkleigh",
                "countryCode": "GB"
            },
            {
                "name": "Winscombe",
                "countryCode": "GB"
            },
            {
                "name": "Winsford",
                "countryCode": "GB"
            },
            {
                "name": "Winslow",
                "countryCode": "GB"
            },
            {
                "name": "Winterbourne",
                "countryCode": "GB"
            },
            {
                "name": "Winterton",
                "countryCode": "GB"
            },
            {
                "name": "Winwick",
                "countryCode": "GB"
            },
            {
                "name": "Wirksworth",
                "countryCode": "GB"
            },
            {
                "name": "Wisbech",
                "countryCode": "GB"
            },
            {
                "name": "Witchford",
                "countryCode": "GB"
            },
            {
                "name": "Witham",
                "countryCode": "GB"
            },
            {
                "name": "Witheridge",
                "countryCode": "GB"
            },
            {
                "name": "Withernsea",
                "countryCode": "GB"
            },
            {
                "name": "Witley",
                "countryCode": "GB"
            },
            {
                "name": "Witney",
                "countryCode": "GB"
            },
            {
                "name": "Wittering",
                "countryCode": "GB"
            },
            {
                "name": "Witton Gilbert",
                "countryCode": "GB"
            },
            {
                "name": "Wiveliscombe",
                "countryCode": "GB"
            },
            {
                "name": "Wivelsfield Green",
                "countryCode": "GB"
            },
            {
                "name": "Wivenhoe",
                "countryCode": "GB"
            },
            {
                "name": "Woburn",
                "countryCode": "GB"
            },
            {
                "name": "Woburn Sands",
                "countryCode": "GB"
            },
            {
                "name": "Woking",
                "countryCode": "GB"
            },
            {
                "name": "Wokingham",
                "countryCode": "GB"
            },
            {
                "name": "Wold Newton",
                "countryCode": "GB"
            },
            {
                "name": "Woldingham",
                "countryCode": "GB"
            },
            {
                "name": "Wollaston",
                "countryCode": "GB"
            },
            {
                "name": "Wolsingham",
                "countryCode": "GB"
            },
            {
                "name": "Wolston",
                "countryCode": "GB"
            },
            {
                "name": "Wolvercote",
                "countryCode": "GB"
            },
            {
                "name": "Wolverhampton",
                "countryCode": "GB"
            },
            {
                "name": "Wombourn",
                "countryCode": "GB"
            },
            {
                "name": "Wombwell",
                "countryCode": "GB"
            },
            {
                "name": "Wood Street Village",
                "countryCode": "GB"
            },
            {
                "name": "Woodborough",
                "countryCode": "GB"
            },
            {
                "name": "Woodbridge",
                "countryCode": "GB"
            },
            {
                "name": "Woodbury",
                "countryCode": "GB"
            },
            {
                "name": "Woodchurch",
                "countryCode": "GB"
            },
            {
                "name": "Woodcote",
                "countryCode": "GB"
            },
            {
                "name": "Woodford",
                "countryCode": "GB"
            },
            {
                "name": "Woodford Green",
                "countryCode": "GB"
            },
            {
                "name": "Woodhall Spa",
                "countryCode": "GB"
            },
            {
                "name": "Woodsetts",
                "countryCode": "GB"
            },
            {
                "name": "Woodstock",
                "countryCode": "GB"
            },
            {
                "name": "Wool",
                "countryCode": "GB"
            },
            {
                "name": "Woolavington",
                "countryCode": "GB"
            },
            {
                "name": "Wooler",
                "countryCode": "GB"
            },
            {
                "name": "Woolley",
                "countryCode": "GB"
            },
            {
                "name": "Woolpit",
                "countryCode": "GB"
            },
            {
                "name": "Wootton",
                "countryCode": "GB"
            },
            {
                "name": "Worcester",
                "countryCode": "GB"
            },
            {
                "name": "Worcester Park",
                "countryCode": "GB"
            },
            {
                "name": "Worcestershire",
                "countryCode": "GB"
            },
            {
                "name": "Workington",
                "countryCode": "GB"
            },
            {
                "name": "Worksop",
                "countryCode": "GB"
            },
            {
                "name": "Worlaby",
                "countryCode": "GB"
            },
            {
                "name": "Wormley",
                "countryCode": "GB"
            },
            {
                "name": "Worthing",
                "countryCode": "GB"
            },
            {
                "name": "Worton",
                "countryCode": "GB"
            },
            {
                "name": "Wotton-under-Edge",
                "countryCode": "GB"
            },
            {
                "name": "Wouldham",
                "countryCode": "GB"
            },
            {
                "name": "Wragby",
                "countryCode": "GB"
            },
            {
                "name": "Wrawby",
                "countryCode": "GB"
            },
            {
                "name": "Wrea Green",
                "countryCode": "GB"
            },
            {
                "name": "Wrington",
                "countryCode": "GB"
            },
            {
                "name": "Writtle",
                "countryCode": "GB"
            },
            {
                "name": "Wrotham",
                "countryCode": "GB"
            },
            {
                "name": "Wroughton",
                "countryCode": "GB"
            },
            {
                "name": "Wroxall",
                "countryCode": "GB"
            },
            {
                "name": "Wychbold",
                "countryCode": "GB"
            },
            {
                "name": "Wye",
                "countryCode": "GB"
            },
            {
                "name": "Wylam",
                "countryCode": "GB"
            },
            {
                "name": "Wymeswold",
                "countryCode": "GB"
            },
            {
                "name": "Yalding",
                "countryCode": "GB"
            },
            {
                "name": "Yapton",
                "countryCode": "GB"
            },
            {
                "name": "Yarm",
                "countryCode": "GB"
            },
            {
                "name": "Yarmouth",
                "countryCode": "GB"
            },
            {
                "name": "Yarnton",
                "countryCode": "GB"
            },
            {
                "name": "Yate",
                "countryCode": "GB"
            },
            {
                "name": "Yateley",
                "countryCode": "GB"
            },
            {
                "name": "Yatton",
                "countryCode": "GB"
            },
            {
                "name": "Yaxley",
                "countryCode": "GB"
            },
            {
                "name": "Yeadon",
                "countryCode": "GB"
            },
            {
                "name": "Yealmpton",
                "countryCode": "GB"
            },
            {
                "name": "Yelverton",
                "countryCode": "GB"
            },
            {
                "name": "Yeovil",
                "countryCode": "GB"
            },
            {
                "name": "Yetminster",
                "countryCode": "GB"
            },
            {
                "name": "York",
                "countryCode": "GB"
            },
            {
                "name": "Youlgreave",
                "countryCode": "GB"
            },
            {
                "name": "Yoxall",
                "countryCode": "GB"
            },
            {
                "name": "Ahoghill",
                "countryCode": "GB"
            },
            {
                "name": "Annahilt",
                "countryCode": "GB"
            },
            {
                "name": "Annalong",
                "countryCode": "GB"
            },
            {
                "name": "Antrim",
                "countryCode": "GB"
            },
            {
                "name": "Antrim and Newtownabbey",
                "countryCode": "GB"
            },
            {
                "name": "Ardglass",
                "countryCode": "GB"
            },
            {
                "name": "Ards and North Down",
                "countryCode": "GB"
            },
            {
                "name": "Armagh",
                "countryCode": "GB"
            },
            {
                "name": "Armagh City Banbridge and Craigavon",
                "countryCode": "GB"
            },
            {
                "name": "Ballinamallard",
                "countryCode": "GB"
            },
            {
                "name": "Ballintoy Harbour",
                "countryCode": "GB"
            },
            {
                "name": "Ballycastle",
                "countryCode": "GB"
            },
            {
                "name": "Ballyclare",
                "countryCode": "GB"
            },
            {
                "name": "Ballygowan",
                "countryCode": "GB"
            },
            {
                "name": "Ballykelly",
                "countryCode": "GB"
            },
            {
                "name": "Ballymena",
                "countryCode": "GB"
            },
            {
                "name": "Ballymoney",
                "countryCode": "GB"
            },
            {
                "name": "Ballynahinch",
                "countryCode": "GB"
            },
            {
                "name": "Ballypatrick",
                "countryCode": "GB"
            },
            {
                "name": "Ballywalter",
                "countryCode": "GB"
            },
            {
                "name": "Banbridge",
                "countryCode": "GB"
            },
            {
                "name": "Bangor",
                "countryCode": "GB"
            },
            {
                "name": "Belfast",
                "countryCode": "GB"
            },
            {
                "name": "Bellaghy",
                "countryCode": "GB"
            },
            {
                "name": "Broughshane",
                "countryCode": "GB"
            },
            {
                "name": "Bushmills",
                "countryCode": "GB"
            },
            {
                "name": "Carnlough",
                "countryCode": "GB"
            },
            {
                "name": "Carnmoney",
                "countryCode": "GB"
            },
            {
                "name": "Carrickfergus",
                "countryCode": "GB"
            },
            {
                "name": "Carryduff",
                "countryCode": "GB"
            },
            {
                "name": "Castledawson",
                "countryCode": "GB"
            },
            {
                "name": "Castlederg",
                "countryCode": "GB"
            },
            {
                "name": "Castlereagh",
                "countryCode": "GB"
            },
            {
                "name": "Castlerock",
                "countryCode": "GB"
            },
            {
                "name": "Castlewellan",
                "countryCode": "GB"
            },
            {
                "name": "Causeway Coast and Glens",
                "countryCode": "GB"
            },
            {
                "name": "City of Belfast",
                "countryCode": "GB"
            },
            {
                "name": "Coalisland",
                "countryCode": "GB"
            },
            {
                "name": "Coleraine",
                "countryCode": "GB"
            },
            {
                "name": "Comber",
                "countryCode": "GB"
            },
            {
                "name": "Connor",
                "countryCode": "GB"
            },
            {
                "name": "Cookstown",
                "countryCode": "GB"
            },
            {
                "name": "Craigavon",
                "countryCode": "GB"
            },
            {
                "name": "Crossgar",
                "countryCode": "GB"
            },
            {
                "name": "Crossmaglen",
                "countryCode": "GB"
            },
            {
                "name": "Crumlin",
                "countryCode": "GB"
            },
            {
                "name": "Cullybackey",
                "countryCode": "GB"
            },
            {
                "name": "Culmore",
                "countryCode": "GB"
            },
            {
                "name": "Cushendall",
                "countryCode": "GB"
            },
            {
                "name": "Derry",
                "countryCode": "GB"
            },
            {
                "name": "Derry City and Strabane",
                "countryCode": "GB"
            },
            {
                "name": "Doagh",
                "countryCode": "GB"
            },
            {
                "name": "Donaghadee",
                "countryCode": "GB"
            },
            {
                "name": "Downpatrick",
                "countryCode": "GB"
            },
            {
                "name": "Draperstown",
                "countryCode": "GB"
            },
            {
                "name": "Dromore",
                "countryCode": "GB"
            },
            {
                "name": "Dundonald",
                "countryCode": "GB"
            },
            {
                "name": "Dundrum",
                "countryCode": "GB"
            },
            {
                "name": "Dungannon",
                "countryCode": "GB"
            },
            {
                "name": "Dungiven",
                "countryCode": "GB"
            },
            {
                "name": "Dunloy",
                "countryCode": "GB"
            },
            {
                "name": "Eglinton",
                "countryCode": "GB"
            },
            {
                "name": "Enniskillen",
                "countryCode": "GB"
            },
            {
                "name": "Fermanagh and Omagh",
                "countryCode": "GB"
            },
            {
                "name": "Fintona",
                "countryCode": "GB"
            },
            {
                "name": "Fivemiletown",
                "countryCode": "GB"
            },
            {
                "name": "Garvagh",
                "countryCode": "GB"
            },
            {
                "name": "Gilford",
                "countryCode": "GB"
            },
            {
                "name": "Glenariff",
                "countryCode": "GB"
            },
            {
                "name": "Glenavy",
                "countryCode": "GB"
            },
            {
                "name": "Greenisland",
                "countryCode": "GB"
            },
            {
                "name": "Greyabbey",
                "countryCode": "GB"
            },
            {
                "name": "Hillsborough",
                "countryCode": "GB"
            },
            {
                "name": "Holywood",
                "countryCode": "GB"
            },
            {
                "name": "Irvinestown",
                "countryCode": "GB"
            },
            {
                "name": "Jordanstown",
                "countryCode": "GB"
            },
            {
                "name": "Keady",
                "countryCode": "GB"
            },
            {
                "name": "Kilkeel",
                "countryCode": "GB"
            },
            {
                "name": "Killyleagh",
                "countryCode": "GB"
            },
            {
                "name": "Kilrea",
                "countryCode": "GB"
            },
            {
                "name": "Kircubbin",
                "countryCode": "GB"
            },
            {
                "name": "Larne",
                "countryCode": "GB"
            },
            {
                "name": "Limavady",
                "countryCode": "GB"
            },
            {
                "name": "Lisburn",
                "countryCode": "GB"
            },
            {
                "name": "Lisburn and Castlereagh",
                "countryCode": "GB"
            },
            {
                "name": "Lisnaskea",
                "countryCode": "GB"
            },
            {
                "name": "Londonderry County Borough",
                "countryCode": "GB"
            },
            {
                "name": "Maghera",
                "countryCode": "GB"
            },
            {
                "name": "Magherafelt",
                "countryCode": "GB"
            },
            {
                "name": "Magheralin",
                "countryCode": "GB"
            },
            {
                "name": "Mid Ulster",
                "countryCode": "GB"
            },
            {
                "name": "Mid and East Antrim",
                "countryCode": "GB"
            },
            {
                "name": "Millisle",
                "countryCode": "GB"
            },
            {
                "name": "Moira",
                "countryCode": "GB"
            },
            {
                "name": "Moneymore",
                "countryCode": "GB"
            },
            {
                "name": "Moy",
                "countryCode": "GB"
            },
            {
                "name": "Newcastle",
                "countryCode": "GB"
            },
            {
                "name": "Newry",
                "countryCode": "GB"
            },
            {
                "name": "Newry Mourne and Down",
                "countryCode": "GB"
            },
            {
                "name": "Newtownabbey",
                "countryCode": "GB"
            },
            {
                "name": "Newtownards",
                "countryCode": "GB"
            },
            {
                "name": "Newtownstewart",
                "countryCode": "GB"
            },
            {
                "name": "Omagh",
                "countryCode": "GB"
            },
            {
                "name": "Portadown",
                "countryCode": "GB"
            },
            {
                "name": "Portaferry",
                "countryCode": "GB"
            },
            {
                "name": "Portavogie",
                "countryCode": "GB"
            },
            {
                "name": "Portglenone",
                "countryCode": "GB"
            },
            {
                "name": "Portrush",
                "countryCode": "GB"
            },
            {
                "name": "Portstewart",
                "countryCode": "GB"
            },
            {
                "name": "Randalstown",
                "countryCode": "GB"
            },
            {
                "name": "Rathfriland",
                "countryCode": "GB"
            },
            {
                "name": "Rostrevor",
                "countryCode": "GB"
            },
            {
                "name": "Saintfield",
                "countryCode": "GB"
            },
            {
                "name": "Sion Mills",
                "countryCode": "GB"
            },
            {
                "name": "Strabane",
                "countryCode": "GB"
            },
            {
                "name": "Tandragee",
                "countryCode": "GB"
            },
            {
                "name": "Templepatrick",
                "countryCode": "GB"
            },
            {
                "name": "Waringstown",
                "countryCode": "GB"
            },
            {
                "name": "Warrenpoint",
                "countryCode": "GB"
            },
            {
                "name": "Whitehead",
                "countryCode": "GB"
            },
            {
                "name": "Aberchirder",
                "countryCode": "GB"
            },
            {
                "name": "Aberdeen",
                "countryCode": "GB"
            },
            {
                "name": "Aberdeen City",
                "countryCode": "GB"
            },
            {
                "name": "Aberdeenshire",
                "countryCode": "GB"
            },
            {
                "name": "Aberdour",
                "countryCode": "GB"
            },
            {
                "name": "Aberfeldy",
                "countryCode": "GB"
            },
            {
                "name": "Aberlady",
                "countryCode": "GB"
            },
            {
                "name": "Abernethy",
                "countryCode": "GB"
            },
            {
                "name": "Aboyne",
                "countryCode": "GB"
            },
            {
                "name": "Addiebrownhill",
                "countryCode": "GB"
            },
            {
                "name": "Airdrie",
                "countryCode": "GB"
            },
            {
                "name": "Airth",
                "countryCode": "GB"
            },
            {
                "name": "Alexandria",
                "countryCode": "GB"
            },
            {
                "name": "Alford",
                "countryCode": "GB"
            },
            {
                "name": "Allanton",
                "countryCode": "GB"
            },
            {
                "name": "Alloa",
                "countryCode": "GB"
            },
            {
                "name": "Almondbank",
                "countryCode": "GB"
            },
            {
                "name": "Alness",
                "countryCode": "GB"
            },
            {
                "name": "Alva",
                "countryCode": "GB"
            },
            {
                "name": "Alyth",
                "countryCode": "GB"
            },
            {
                "name": "Angus",
                "countryCode": "GB"
            },
            {
                "name": "Annan",
                "countryCode": "GB"
            },
            {
                "name": "Anstruther",
                "countryCode": "GB"
            },
            {
                "name": "Arbroath",
                "countryCode": "GB"
            },
            {
                "name": "Ardersier",
                "countryCode": "GB"
            },
            {
                "name": "Ardrishaig",
                "countryCode": "GB"
            },
            {
                "name": "Ardrossan",
                "countryCode": "GB"
            },
            {
                "name": "Argyll and Bute",
                "countryCode": "GB"
            },
            {
                "name": "Armadale",
                "countryCode": "GB"
            },
            {
                "name": "Ashgill",
                "countryCode": "GB"
            },
            {
                "name": "Auchinleck",
                "countryCode": "GB"
            },
            {
                "name": "Auchterarder",
                "countryCode": "GB"
            },
            {
                "name": "Auchtermuchty",
                "countryCode": "GB"
            },
            {
                "name": "Aviemore",
                "countryCode": "GB"
            },
            {
                "name": "Ayr",
                "countryCode": "GB"
            },
            {
                "name": "Balerno",
                "countryCode": "GB"
            },
            {
                "name": "Balfron",
                "countryCode": "GB"
            },
            {
                "name": "Balintore",
                "countryCode": "GB"
            },
            {
                "name": "Ballater",
                "countryCode": "GB"
            },
            {
                "name": "Ballingry",
                "countryCode": "GB"
            },
            {
                "name": "Balloch",
                "countryCode": "GB"
            },
            {
                "name": "Balmedie",
                "countryCode": "GB"
            },
            {
                "name": "Balmullo",
                "countryCode": "GB"
            },
            {
                "name": "Banchory",
                "countryCode": "GB"
            },
            {
                "name": "Banff",
                "countryCode": "GB"
            },
            {
                "name": "Bankfoot",
                "countryCode": "GB"
            },
            {
                "name": "Banknock",
                "countryCode": "GB"
            },
            {
                "name": "Bannockburn",
                "countryCode": "GB"
            },
            {
                "name": "Bargeddie",
                "countryCode": "GB"
            },
            {
                "name": "Barra",
                "countryCode": "GB"
            },
            {
                "name": "Barrhead",
                "countryCode": "GB"
            },
            {
                "name": "Bathgate",
                "countryCode": "GB"
            },
            {
                "name": "Bearsden",
                "countryCode": "GB"
            },
            {
                "name": "Beauly",
                "countryCode": "GB"
            },
            {
                "name": "Beith",
                "countryCode": "GB"
            },
            {
                "name": "Bellsbank",
                "countryCode": "GB"
            },
            {
                "name": "Bellshill",
                "countryCode": "GB"
            },
            {
                "name": "Benbecula",
                "countryCode": "GB"
            },
            {
                "name": "Biggar",
                "countryCode": "GB"
            },
            {
                "name": "Bilston",
                "countryCode": "GB"
            },
            {
                "name": "Bishopbriggs",
                "countryCode": "GB"
            },
            {
                "name": "Bishopton",
                "countryCode": "GB"
            },
            {
                "name": "Blackburn",
                "countryCode": "GB"
            },
            {
                "name": "Blackridge",
                "countryCode": "GB"
            },
            {
                "name": "Blackwood",
                "countryCode": "GB"
            },
            {
                "name": "Blairgowrie",
                "countryCode": "GB"
            },
            {
                "name": "Blantyre",
                "countryCode": "GB"
            },
            {
                "name": "Boddam",
                "countryCode": "GB"
            },
            {
                "name": "Bonhill",
                "countryCode": "GB"
            },
            {
                "name": "Bonnybridge",
                "countryCode": "GB"
            },
            {
                "name": "Bonnyrigg",
                "countryCode": "GB"
            },
            {
                "name": "Bothwell",
                "countryCode": "GB"
            },
            {
                "name": "Bo’ness",
                "countryCode": "GB"
            },
            {
                "name": "Brechin",
                "countryCode": "GB"
            },
            {
                "name": "Bridge of Allan",
                "countryCode": "GB"
            },
            {
                "name": "Bridge of Earn",
                "countryCode": "GB"
            },
            {
                "name": "Bridge of Weir",
                "countryCode": "GB"
            },
            {
                "name": "Brightons",
                "countryCode": "GB"
            },
            {
                "name": "Brora",
                "countryCode": "GB"
            },
            {
                "name": "Broxburn",
                "countryCode": "GB"
            },
            {
                "name": "Buckhaven",
                "countryCode": "GB"
            },
            {
                "name": "Buckie",
                "countryCode": "GB"
            },
            {
                "name": "Burghead",
                "countryCode": "GB"
            },
            {
                "name": "Burntisland",
                "countryCode": "GB"
            },
            {
                "name": "Busby",
                "countryCode": "GB"
            },
            {
                "name": "Cairneyhill",
                "countryCode": "GB"
            },
            {
                "name": "Cairnryan",
                "countryCode": "GB"
            },
            {
                "name": "Calderbank",
                "countryCode": "GB"
            },
            {
                "name": "Caldercruix",
                "countryCode": "GB"
            },
            {
                "name": "Callander",
                "countryCode": "GB"
            },
            {
                "name": "Cambuslang",
                "countryCode": "GB"
            },
            {
                "name": "Campbeltown",
                "countryCode": "GB"
            },
            {
                "name": "Caol",
                "countryCode": "GB"
            },
            {
                "name": "Cardenden",
                "countryCode": "GB"
            },
            {
                "name": "Cardross",
                "countryCode": "GB"
            },
            {
                "name": "Carfin",
                "countryCode": "GB"
            },
            {
                "name": "Carluke",
                "countryCode": "GB"
            },
            {
                "name": "Carmunnock",
                "countryCode": "GB"
            },
            {
                "name": "Carnoustie",
                "countryCode": "GB"
            },
            {
                "name": "Carnwath",
                "countryCode": "GB"
            },
            {
                "name": "Carron",
                "countryCode": "GB"
            },
            {
                "name": "Carronshore",
                "countryCode": "GB"
            },
            {
                "name": "Castle Douglas",
                "countryCode": "GB"
            },
            {
                "name": "Catrine",
                "countryCode": "GB"
            },
            {
                "name": "Chapelhall",
                "countryCode": "GB"
            },
            {
                "name": "Charlestown of Aberlour",
                "countryCode": "GB"
            },
            {
                "name": "Chirnside",
                "countryCode": "GB"
            },
            {
                "name": "Chryston",
                "countryCode": "GB"
            },
            {
                "name": "City of Edinburgh",
                "countryCode": "GB"
            },
            {
                "name": "Clackmannan",
                "countryCode": "GB"
            },
            {
                "name": "Clackmannanshire",
                "countryCode": "GB"
            },
            {
                "name": "Clarkston",
                "countryCode": "GB"
            },
            {
                "name": "Cleland",
                "countryCode": "GB"
            },
            {
                "name": "Clydebank",
                "countryCode": "GB"
            },
            {
                "name": "Coalburn",
                "countryCode": "GB"
            },
            {
                "name": "Coaltown of Balgonie",
                "countryCode": "GB"
            },
            {
                "name": "Coatbridge",
                "countryCode": "GB"
            },
            {
                "name": "Cockenzie",
                "countryCode": "GB"
            },
            {
                "name": "Coldstream",
                "countryCode": "GB"
            },
            {
                "name": "Colinton",
                "countryCode": "GB"
            },
            {
                "name": "Comrie",
                "countryCode": "GB"
            },
            {
                "name": "Conon Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Coupar Angus",
                "countryCode": "GB"
            },
            {
                "name": "Cove",
                "countryCode": "GB"
            },
            {
                "name": "Cowdenbeath",
                "countryCode": "GB"
            },
            {
                "name": "Cowie",
                "countryCode": "GB"
            },
            {
                "name": "Coylton",
                "countryCode": "GB"
            },
            {
                "name": "Crail",
                "countryCode": "GB"
            },
            {
                "name": "Crieff",
                "countryCode": "GB"
            },
            {
                "name": "Crossford",
                "countryCode": "GB"
            },
            {
                "name": "Crossgates",
                "countryCode": "GB"
            },
            {
                "name": "Crosshouse",
                "countryCode": "GB"
            },
            {
                "name": "Croy",
                "countryCode": "GB"
            },
            {
                "name": "Cruden Bay",
                "countryCode": "GB"
            },
            {
                "name": "Cullen",
                "countryCode": "GB"
            },
            {
                "name": "Culloden",
                "countryCode": "GB"
            },
            {
                "name": "Cults",
                "countryCode": "GB"
            },
            {
                "name": "Cumbernauld",
                "countryCode": "GB"
            },
            {
                "name": "Cumnock",
                "countryCode": "GB"
            },
            {
                "name": "Cupar",
                "countryCode": "GB"
            },
            {
                "name": "Currie",
                "countryCode": "GB"
            },
            {
                "name": "Dalbeattie",
                "countryCode": "GB"
            },
            {
                "name": "Dalgety Bay",
                "countryCode": "GB"
            },
            {
                "name": "Dalkeith",
                "countryCode": "GB"
            },
            {
                "name": "Dalmellington",
                "countryCode": "GB"
            },
            {
                "name": "Dalry",
                "countryCode": "GB"
            },
            {
                "name": "Dalrymple",
                "countryCode": "GB"
            },
            {
                "name": "Dalserf",
                "countryCode": "GB"
            },
            {
                "name": "Danderhall",
                "countryCode": "GB"
            },
            {
                "name": "Darvel",
                "countryCode": "GB"
            },
            {
                "name": "Denny",
                "countryCode": "GB"
            },
            {
                "name": "Dennyloanhead",
                "countryCode": "GB"
            },
            {
                "name": "Dingwall",
                "countryCode": "GB"
            },
            {
                "name": "Dollar",
                "countryCode": "GB"
            },
            {
                "name": "Dornoch",
                "countryCode": "GB"
            },
            {
                "name": "Douglas",
                "countryCode": "GB"
            },
            {
                "name": "Doune",
                "countryCode": "GB"
            },
            {
                "name": "Dreghorn",
                "countryCode": "GB"
            },
            {
                "name": "Drongan",
                "countryCode": "GB"
            },
            {
                "name": "Drumnadrochit",
                "countryCode": "GB"
            },
            {
                "name": "Dufftown",
                "countryCode": "GB"
            },
            {
                "name": "Dumbarton",
                "countryCode": "GB"
            },
            {
                "name": "Dumfries",
                "countryCode": "GB"
            },
            {
                "name": "Dumfries and Galloway",
                "countryCode": "GB"
            },
            {
                "name": "Dunbar",
                "countryCode": "GB"
            },
            {
                "name": "Dunblane",
                "countryCode": "GB"
            },
            {
                "name": "Dundee",
                "countryCode": "GB"
            },
            {
                "name": "Dundee City",
                "countryCode": "GB"
            },
            {
                "name": "Dundonald",
                "countryCode": "GB"
            },
            {
                "name": "Dunfermline",
                "countryCode": "GB"
            },
            {
                "name": "Dunipace",
                "countryCode": "GB"
            },
            {
                "name": "Dunlop",
                "countryCode": "GB"
            },
            {
                "name": "Dunoon",
                "countryCode": "GB"
            },
            {
                "name": "Duns",
                "countryCode": "GB"
            },
            {
                "name": "Duntocher",
                "countryCode": "GB"
            },
            {
                "name": "Dyce",
                "countryCode": "GB"
            },
            {
                "name": "Eaglesham",
                "countryCode": "GB"
            },
            {
                "name": "Earlston",
                "countryCode": "GB"
            },
            {
                "name": "East Ayrshire",
                "countryCode": "GB"
            },
            {
                "name": "East Calder",
                "countryCode": "GB"
            },
            {
                "name": "East Dunbartonshire",
                "countryCode": "GB"
            },
            {
                "name": "East Kilbride",
                "countryCode": "GB"
            },
            {
                "name": "East Linton",
                "countryCode": "GB"
            },
            {
                "name": "East Lothian",
                "countryCode": "GB"
            },
            {
                "name": "East Renfrewshire",
                "countryCode": "GB"
            },
            {
                "name": "East Wemyss",
                "countryCode": "GB"
            },
            {
                "name": "East Whitburn",
                "countryCode": "GB"
            },
            {
                "name": "Eastriggs",
                "countryCode": "GB"
            },
            {
                "name": "Edinburgh",
                "countryCode": "GB"
            },
            {
                "name": "Eilean Siar",
                "countryCode": "GB"
            },
            {
                "name": "Elderslie",
                "countryCode": "GB"
            },
            {
                "name": "Elgin",
                "countryCode": "GB"
            },
            {
                "name": "Ellon",
                "countryCode": "GB"
            },
            {
                "name": "Errol",
                "countryCode": "GB"
            },
            {
                "name": "Erskine",
                "countryCode": "GB"
            },
            {
                "name": "Evanton",
                "countryCode": "GB"
            },
            {
                "name": "Eyemouth",
                "countryCode": "GB"
            },
            {
                "name": "Faifley",
                "countryCode": "GB"
            },
            {
                "name": "Fairlie",
                "countryCode": "GB"
            },
            {
                "name": "Falkirk",
                "countryCode": "GB"
            },
            {
                "name": "Falkland",
                "countryCode": "GB"
            },
            {
                "name": "Fallin",
                "countryCode": "GB"
            },
            {
                "name": "Fauldhouse",
                "countryCode": "GB"
            },
            {
                "name": "Fenwick",
                "countryCode": "GB"
            },
            {
                "name": "Fife",
                "countryCode": "GB"
            },
            {
                "name": "Findochty",
                "countryCode": "GB"
            },
            {
                "name": "Fochabers",
                "countryCode": "GB"
            },
            {
                "name": "Forfar",
                "countryCode": "GB"
            },
            {
                "name": "Forres",
                "countryCode": "GB"
            },
            {
                "name": "Fort William",
                "countryCode": "GB"
            },
            {
                "name": "Forth",
                "countryCode": "GB"
            },
            {
                "name": "Fortrose",
                "countryCode": "GB"
            },
            {
                "name": "Fraserburgh",
                "countryCode": "GB"
            },
            {
                "name": "Freuchie",
                "countryCode": "GB"
            },
            {
                "name": "Friockheim",
                "countryCode": "GB"
            },
            {
                "name": "Galashiels",
                "countryCode": "GB"
            },
            {
                "name": "Galston",
                "countryCode": "GB"
            },
            {
                "name": "Garelochhead",
                "countryCode": "GB"
            },
            {
                "name": "Gartcosh",
                "countryCode": "GB"
            },
            {
                "name": "Giffnock",
                "countryCode": "GB"
            },
            {
                "name": "Girvan",
                "countryCode": "GB"
            },
            {
                "name": "Glasgow",
                "countryCode": "GB"
            },
            {
                "name": "Glasgow City",
                "countryCode": "GB"
            },
            {
                "name": "Glenboig",
                "countryCode": "GB"
            },
            {
                "name": "Glenmavis",
                "countryCode": "GB"
            },
            {
                "name": "Glenrothes",
                "countryCode": "GB"
            },
            {
                "name": "Golspie",
                "countryCode": "GB"
            },
            {
                "name": "Gorebridge",
                "countryCode": "GB"
            },
            {
                "name": "Gourock",
                "countryCode": "GB"
            },
            {
                "name": "Grangemouth",
                "countryCode": "GB"
            },
            {
                "name": "Grantown on Spey",
                "countryCode": "GB"
            },
            {
                "name": "Greenhill",
                "countryCode": "GB"
            },
            {
                "name": "Greenock",
                "countryCode": "GB"
            },
            {
                "name": "Gretna",
                "countryCode": "GB"
            },
            {
                "name": "Gullane",
                "countryCode": "GB"
            },
            {
                "name": "Haddington",
                "countryCode": "GB"
            },
            {
                "name": "Halkirk",
                "countryCode": "GB"
            },
            {
                "name": "Hallglen",
                "countryCode": "GB"
            },
            {
                "name": "Hamilton",
                "countryCode": "GB"
            },
            {
                "name": "Harthill",
                "countryCode": "GB"
            },
            {
                "name": "Hawick",
                "countryCode": "GB"
            },
            {
                "name": "Head of Muir",
                "countryCode": "GB"
            },
            {
                "name": "Helensburgh",
                "countryCode": "GB"
            },
            {
                "name": "High Blantyre",
                "countryCode": "GB"
            },
            {
                "name": "High Valleyfield",
                "countryCode": "GB"
            },
            {
                "name": "Highland",
                "countryCode": "GB"
            },
            {
                "name": "Hillside",
                "countryCode": "GB"
            },
            {
                "name": "Holytown",
                "countryCode": "GB"
            },
            {
                "name": "Hopeman",
                "countryCode": "GB"
            },
            {
                "name": "Houston",
                "countryCode": "GB"
            },
            {
                "name": "Howwood",
                "countryCode": "GB"
            },
            {
                "name": "Huntly",
                "countryCode": "GB"
            },
            {
                "name": "Inchinnan",
                "countryCode": "GB"
            },
            {
                "name": "Inchture",
                "countryCode": "GB"
            },
            {
                "name": "Innerleithen",
                "countryCode": "GB"
            },
            {
                "name": "Insch",
                "countryCode": "GB"
            },
            {
                "name": "Inverbervie",
                "countryCode": "GB"
            },
            {
                "name": "Inverclyde",
                "countryCode": "GB"
            },
            {
                "name": "Invergordon",
                "countryCode": "GB"
            },
            {
                "name": "Invergowrie",
                "countryCode": "GB"
            },
            {
                "name": "Inverkeithing",
                "countryCode": "GB"
            },
            {
                "name": "Inverkip",
                "countryCode": "GB"
            },
            {
                "name": "Inverness",
                "countryCode": "GB"
            },
            {
                "name": "Inverurie",
                "countryCode": "GB"
            },
            {
                "name": "Irvine",
                "countryCode": "GB"
            },
            {
                "name": "Isle Of Mull",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Arran",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Bute",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Cumbrae",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Islay",
                "countryCode": "GB"
            },
            {
                "name": "Isle of Lewis",
                "countryCode": "GB"
            },
            {
                "name": "Isle of North Uist",
                "countryCode": "GB"
            },
            {
                "name": "Isle of South Uist",
                "countryCode": "GB"
            },
            {
                "name": "Jedburgh",
                "countryCode": "GB"
            },
            {
                "name": "Johnstone",
                "countryCode": "GB"
            },
            {
                "name": "Keith",
                "countryCode": "GB"
            },
            {
                "name": "Kelso",
                "countryCode": "GB"
            },
            {
                "name": "Kelty",
                "countryCode": "GB"
            },
            {
                "name": "Kemnay",
                "countryCode": "GB"
            },
            {
                "name": "Kennoway",
                "countryCode": "GB"
            },
            {
                "name": "Kilbarchan",
                "countryCode": "GB"
            },
            {
                "name": "Kilbirnie",
                "countryCode": "GB"
            },
            {
                "name": "Kilcreggan",
                "countryCode": "GB"
            },
            {
                "name": "Killearn",
                "countryCode": "GB"
            },
            {
                "name": "Kilmacolm",
                "countryCode": "GB"
            },
            {
                "name": "Kilmarnock",
                "countryCode": "GB"
            },
            {
                "name": "Kilmaurs",
                "countryCode": "GB"
            },
            {
                "name": "Kilsyth",
                "countryCode": "GB"
            },
            {
                "name": "Kilwinning",
                "countryCode": "GB"
            },
            {
                "name": "Kincardine",
                "countryCode": "GB"
            },
            {
                "name": "Kinghorn",
                "countryCode": "GB"
            },
            {
                "name": "Kinglassie",
                "countryCode": "GB"
            },
            {
                "name": "Kingskettle",
                "countryCode": "GB"
            },
            {
                "name": "Kingswells",
                "countryCode": "GB"
            },
            {
                "name": "Kingussie",
                "countryCode": "GB"
            },
            {
                "name": "Kinloss",
                "countryCode": "GB"
            },
            {
                "name": "Kinross",
                "countryCode": "GB"
            },
            {
                "name": "Kintore",
                "countryCode": "GB"
            },
            {
                "name": "Kippen",
                "countryCode": "GB"
            },
            {
                "name": "Kirkcaldy",
                "countryCode": "GB"
            },
            {
                "name": "Kirkconnel",
                "countryCode": "GB"
            },
            {
                "name": "Kirkcudbright",
                "countryCode": "GB"
            },
            {
                "name": "Kirkintilloch",
                "countryCode": "GB"
            },
            {
                "name": "Kirkliston",
                "countryCode": "GB"
            },
            {
                "name": "Kirknewton",
                "countryCode": "GB"
            },
            {
                "name": "Kirkwall",
                "countryCode": "GB"
            },
            {
                "name": "Kirriemuir",
                "countryCode": "GB"
            },
            {
                "name": "Ladybank",
                "countryCode": "GB"
            },
            {
                "name": "Lamlash",
                "countryCode": "GB"
            },
            {
                "name": "Lanark",
                "countryCode": "GB"
            },
            {
                "name": "Langholm",
                "countryCode": "GB"
            },
            {
                "name": "Larbert",
                "countryCode": "GB"
            },
            {
                "name": "Largs",
                "countryCode": "GB"
            },
            {
                "name": "Larkhall",
                "countryCode": "GB"
            },
            {
                "name": "Lauder",
                "countryCode": "GB"
            },
            {
                "name": "Laurencekirk",
                "countryCode": "GB"
            },
            {
                "name": "Laurieston",
                "countryCode": "GB"
            },
            {
                "name": "Law",
                "countryCode": "GB"
            },
            {
                "name": "Lennoxtown",
                "countryCode": "GB"
            },
            {
                "name": "Lenzie",
                "countryCode": "GB"
            },
            {
                "name": "Lerwick",
                "countryCode": "GB"
            },
            {
                "name": "Leslie",
                "countryCode": "GB"
            },
            {
                "name": "Lesmahagow",
                "countryCode": "GB"
            },
            {
                "name": "Letham",
                "countryCode": "GB"
            },
            {
                "name": "Leuchars",
                "countryCode": "GB"
            },
            {
                "name": "Leven",
                "countryCode": "GB"
            },
            {
                "name": "Lhanbryde",
                "countryCode": "GB"
            },
            {
                "name": "Limekilns",
                "countryCode": "GB"
            },
            {
                "name": "Linlithgow",
                "countryCode": "GB"
            },
            {
                "name": "Linwood",
                "countryCode": "GB"
            },
            {
                "name": "Livingston",
                "countryCode": "GB"
            },
            {
                "name": "Loanhead",
                "countryCode": "GB"
            },
            {
                "name": "Locharbriggs",
                "countryCode": "GB"
            },
            {
                "name": "Lochgelly",
                "countryCode": "GB"
            },
            {
                "name": "Lochgilphead",
                "countryCode": "GB"
            },
            {
                "name": "Lochmaben",
                "countryCode": "GB"
            },
            {
                "name": "Lochwinnoch",
                "countryCode": "GB"
            },
            {
                "name": "Lockerbie",
                "countryCode": "GB"
            },
            {
                "name": "Logan",
                "countryCode": "GB"
            },
            {
                "name": "Longforgan",
                "countryCode": "GB"
            },
            {
                "name": "Longniddry",
                "countryCode": "GB"
            },
            {
                "name": "Lossiemouth",
                "countryCode": "GB"
            },
            {
                "name": "Luncarty",
                "countryCode": "GB"
            },
            {
                "name": "Lundin Links",
                "countryCode": "GB"
            },
            {
                "name": "Macduff",
                "countryCode": "GB"
            },
            {
                "name": "Macmerry",
                "countryCode": "GB"
            },
            {
                "name": "Maddiston",
                "countryCode": "GB"
            },
            {
                "name": "Markinch",
                "countryCode": "GB"
            },
            {
                "name": "Maryburgh",
                "countryCode": "GB"
            },
            {
                "name": "Mauchline",
                "countryCode": "GB"
            },
            {
                "name": "Maxwellheugh",
                "countryCode": "GB"
            },
            {
                "name": "Maybole",
                "countryCode": "GB"
            },
            {
                "name": "Mayfield",
                "countryCode": "GB"
            },
            {
                "name": "Melrose",
                "countryCode": "GB"
            },
            {
                "name": "Menstrie",
                "countryCode": "GB"
            },
            {
                "name": "Methil",
                "countryCode": "GB"
            },
            {
                "name": "Methven",
                "countryCode": "GB"
            },
            {
                "name": "Mid Calder",
                "countryCode": "GB"
            },
            {
                "name": "Midlothian",
                "countryCode": "GB"
            },
            {
                "name": "Millport",
                "countryCode": "GB"
            },
            {
                "name": "Milltimber",
                "countryCode": "GB"
            },
            {
                "name": "Milnathort",
                "countryCode": "GB"
            },
            {
                "name": "Milngavie",
                "countryCode": "GB"
            },
            {
                "name": "Milton of Campsie",
                "countryCode": "GB"
            },
            {
                "name": "Milton of Leys",
                "countryCode": "GB"
            },
            {
                "name": "Mintlaw",
                "countryCode": "GB"
            },
            {
                "name": "Moffat",
                "countryCode": "GB"
            },
            {
                "name": "Monifieth",
                "countryCode": "GB"
            },
            {
                "name": "Montrose",
                "countryCode": "GB"
            },
            {
                "name": "Moodiesburn",
                "countryCode": "GB"
            },
            {
                "name": "Moray",
                "countryCode": "GB"
            },
            {
                "name": "Mossblown",
                "countryCode": "GB"
            },
            {
                "name": "Motherwell",
                "countryCode": "GB"
            },
            {
                "name": "Muir of Ord",
                "countryCode": "GB"
            },
            {
                "name": "Muirhead",
                "countryCode": "GB"
            },
            {
                "name": "Muirkirk",
                "countryCode": "GB"
            },
            {
                "name": "Musselburgh",
                "countryCode": "GB"
            },
            {
                "name": "Nairn",
                "countryCode": "GB"
            },
            {
                "name": "Neilston",
                "countryCode": "GB"
            },
            {
                "name": "Netherlee",
                "countryCode": "GB"
            },
            {
                "name": "New Cumnock",
                "countryCode": "GB"
            },
            {
                "name": "New Pitsligo",
                "countryCode": "GB"
            },
            {
                "name": "New Stevenston",
                "countryCode": "GB"
            },
            {
                "name": "Newarthill",
                "countryCode": "GB"
            },
            {
                "name": "Newbridge",
                "countryCode": "GB"
            },
            {
                "name": "Newburgh",
                "countryCode": "GB"
            },
            {
                "name": "Newmacher",
                "countryCode": "GB"
            },
            {
                "name": "Newmains",
                "countryCode": "GB"
            },
            {
                "name": "Newmilns",
                "countryCode": "GB"
            },
            {
                "name": "Newport-on-Tay",
                "countryCode": "GB"
            },
            {
                "name": "Newton Mearns",
                "countryCode": "GB"
            },
            {
                "name": "Newton Stewart",
                "countryCode": "GB"
            },
            {
                "name": "Newtonhill",
                "countryCode": "GB"
            },
            {
                "name": "Newtonmore",
                "countryCode": "GB"
            },
            {
                "name": "Newtown St Boswells",
                "countryCode": "GB"
            },
            {
                "name": "North Ayrshire",
                "countryCode": "GB"
            },
            {
                "name": "North Berwick",
                "countryCode": "GB"
            },
            {
                "name": "North Lanarkshire",
                "countryCode": "GB"
            },
            {
                "name": "North Queensferry",
                "countryCode": "GB"
            },
            {
                "name": "Oakley",
                "countryCode": "GB"
            },
            {
                "name": "Oban",
                "countryCode": "GB"
            },
            {
                "name": "Ochiltree",
                "countryCode": "GB"
            },
            {
                "name": "Old Kilpatrick",
                "countryCode": "GB"
            },
            {
                "name": "Oldmeldrum",
                "countryCode": "GB"
            },
            {
                "name": "Orkney",
                "countryCode": "GB"
            },
            {
                "name": "Orkney Islands",
                "countryCode": "GB"
            },
            {
                "name": "Ormiston",
                "countryCode": "GB"
            },
            {
                "name": "Overtown",
                "countryCode": "GB"
            },
            {
                "name": "Paisley",
                "countryCode": "GB"
            },
            {
                "name": "Patna",
                "countryCode": "GB"
            },
            {
                "name": "Peebles",
                "countryCode": "GB"
            },
            {
                "name": "Pencaitland",
                "countryCode": "GB"
            },
            {
                "name": "Penicuik",
                "countryCode": "GB"
            },
            {
                "name": "Perth",
                "countryCode": "GB"
            },
            {
                "name": "Perth and Kinross",
                "countryCode": "GB"
            },
            {
                "name": "Peterculter",
                "countryCode": "GB"
            },
            {
                "name": "Peterhead",
                "countryCode": "GB"
            },
            {
                "name": "Pitlochry",
                "countryCode": "GB"
            },
            {
                "name": "Pitmedden",
                "countryCode": "GB"
            },
            {
                "name": "Pittenweem",
                "countryCode": "GB"
            },
            {
                "name": "Plains",
                "countryCode": "GB"
            },
            {
                "name": "Plean",
                "countryCode": "GB"
            },
            {
                "name": "Polbeth",
                "countryCode": "GB"
            },
            {
                "name": "Polmont",
                "countryCode": "GB"
            },
            {
                "name": "Port Bannatyne",
                "countryCode": "GB"
            },
            {
                "name": "Port Erroll",
                "countryCode": "GB"
            },
            {
                "name": "Port Glasgow",
                "countryCode": "GB"
            },
            {
                "name": "Portknockie",
                "countryCode": "GB"
            },
            {
                "name": "Portlethen",
                "countryCode": "GB"
            },
            {
                "name": "Portree",
                "countryCode": "GB"
            },
            {
                "name": "Portsoy",
                "countryCode": "GB"
            },
            {
                "name": "Prestonpans",
                "countryCode": "GB"
            },
            {
                "name": "Prestwick",
                "countryCode": "GB"
            },
            {
                "name": "Queensferry",
                "countryCode": "GB"
            },
            {
                "name": "Ratho",
                "countryCode": "GB"
            },
            {
                "name": "Ratho Station",
                "countryCode": "GB"
            },
            {
                "name": "Redding",
                "countryCode": "GB"
            },
            {
                "name": "Reddingmuirhead",
                "countryCode": "GB"
            },
            {
                "name": "Renfrew",
                "countryCode": "GB"
            },
            {
                "name": "Renfrewshire",
                "countryCode": "GB"
            },
            {
                "name": "Renton",
                "countryCode": "GB"
            },
            {
                "name": "Rhu",
                "countryCode": "GB"
            },
            {
                "name": "Rosehearty",
                "countryCode": "GB"
            },
            {
                "name": "Rosewell",
                "countryCode": "GB"
            },
            {
                "name": "Roslin",
                "countryCode": "GB"
            },
            {
                "name": "Rosneath",
                "countryCode": "GB"
            },
            {
                "name": "Rosyth",
                "countryCode": "GB"
            },
            {
                "name": "Rothes",
                "countryCode": "GB"
            },
            {
                "name": "Rothesay",
                "countryCode": "GB"
            },
            {
                "name": "Rothienorman",
                "countryCode": "GB"
            },
            {
                "name": "Rutherglen",
                "countryCode": "GB"
            },
            {
                "name": "Saint Andrews",
                "countryCode": "GB"
            },
            {
                "name": "Saint Boswells",
                "countryCode": "GB"
            },
            {
                "name": "Saint Cyrus",
                "countryCode": "GB"
            },
            {
                "name": "Saint Monans",
                "countryCode": "GB"
            },
            {
                "name": "Saline",
                "countryCode": "GB"
            },
            {
                "name": "Salsburgh",
                "countryCode": "GB"
            },
            {
                "name": "Saltcoats",
                "countryCode": "GB"
            },
            {
                "name": "Sandbank",
                "countryCode": "GB"
            },
            {
                "name": "Sandwick",
                "countryCode": "GB"
            },
            {
                "name": "Sanquhar",
                "countryCode": "GB"
            },
            {
                "name": "Scalloway",
                "countryCode": "GB"
            },
            {
                "name": "Scone",
                "countryCode": "GB"
            },
            {
                "name": "Seafield",
                "countryCode": "GB"
            },
            {
                "name": "Selkirk",
                "countryCode": "GB"
            },
            {
                "name": "Shetland Islands",
                "countryCode": "GB"
            },
            {
                "name": "Shieldhill",
                "countryCode": "GB"
            },
            {
                "name": "Shotts",
                "countryCode": "GB"
            },
            {
                "name": "Skelmorlie",
                "countryCode": "GB"
            },
            {
                "name": "Slamannan",
                "countryCode": "GB"
            },
            {
                "name": "Smithton",
                "countryCode": "GB"
            },
            {
                "name": "South Ayrshire",
                "countryCode": "GB"
            },
            {
                "name": "South Lanarkshire",
                "countryCode": "GB"
            },
            {
                "name": "Springside",
                "countryCode": "GB"
            },
            {
                "name": "Stanley",
                "countryCode": "GB"
            },
            {
                "name": "Stenhousemuir",
                "countryCode": "GB"
            },
            {
                "name": "Stepps",
                "countryCode": "GB"
            },
            {
                "name": "Stevenston",
                "countryCode": "GB"
            },
            {
                "name": "Stewarton",
                "countryCode": "GB"
            },
            {
                "name": "Stirling",
                "countryCode": "GB"
            },
            {
                "name": "Stonehaven",
                "countryCode": "GB"
            },
            {
                "name": "Stonehouse",
                "countryCode": "GB"
            },
            {
                "name": "Stoneyburn",
                "countryCode": "GB"
            },
            {
                "name": "Stornoway",
                "countryCode": "GB"
            },
            {
                "name": "Stranraer",
                "countryCode": "GB"
            },
            {
                "name": "Strathaven",
                "countryCode": "GB"
            },
            {
                "name": "Strathblane",
                "countryCode": "GB"
            },
            {
                "name": "Strathpeffer",
                "countryCode": "GB"
            },
            {
                "name": "Stromness",
                "countryCode": "GB"
            },
            {
                "name": "Symington",
                "countryCode": "GB"
            },
            {
                "name": "Tain",
                "countryCode": "GB"
            },
            {
                "name": "Tarbert",
                "countryCode": "GB"
            },
            {
                "name": "Tarbolton",
                "countryCode": "GB"
            },
            {
                "name": "Tayport",
                "countryCode": "GB"
            },
            {
                "name": "The Scottish Borders",
                "countryCode": "GB"
            },
            {
                "name": "Thornhill",
                "countryCode": "GB"
            },
            {
                "name": "Thornliebank",
                "countryCode": "GB"
            },
            {
                "name": "Thornton",
                "countryCode": "GB"
            },
            {
                "name": "Thurso",
                "countryCode": "GB"
            },
            {
                "name": "Tillicoultry",
                "countryCode": "GB"
            },
            {
                "name": "Tobermory",
                "countryCode": "GB"
            },
            {
                "name": "Torphins",
                "countryCode": "GB"
            },
            {
                "name": "Torrance",
                "countryCode": "GB"
            },
            {
                "name": "Townhill",
                "countryCode": "GB"
            },
            {
                "name": "Tranent",
                "countryCode": "GB"
            },
            {
                "name": "Troon",
                "countryCode": "GB"
            },
            {
                "name": "Tullibody",
                "countryCode": "GB"
            },
            {
                "name": "Turriff",
                "countryCode": "GB"
            },
            {
                "name": "Twechar",
                "countryCode": "GB"
            },
            {
                "name": "Tweedbank",
                "countryCode": "GB"
            },
            {
                "name": "Uddingston",
                "countryCode": "GB"
            },
            {
                "name": "Ullapool",
                "countryCode": "GB"
            },
            {
                "name": "Vale of Leven",
                "countryCode": "GB"
            },
            {
                "name": "Viewpark",
                "countryCode": "GB"
            },
            {
                "name": "Waterfoot",
                "countryCode": "GB"
            },
            {
                "name": "Wemyss Bay",
                "countryCode": "GB"
            },
            {
                "name": "West Calder",
                "countryCode": "GB"
            },
            {
                "name": "West Dunbartonshire",
                "countryCode": "GB"
            },
            {
                "name": "West Kilbride",
                "countryCode": "GB"
            },
            {
                "name": "West Linton",
                "countryCode": "GB"
            },
            {
                "name": "West Lothian",
                "countryCode": "GB"
            },
            {
                "name": "Westhill",
                "countryCode": "GB"
            },
            {
                "name": "Westquarter",
                "countryCode": "GB"
            },
            {
                "name": "Whitburn",
                "countryCode": "GB"
            },
            {
                "name": "Whitecraig",
                "countryCode": "GB"
            },
            {
                "name": "Whitehills",
                "countryCode": "GB"
            },
            {
                "name": "Wick",
                "countryCode": "GB"
            },
            {
                "name": "Winchburgh",
                "countryCode": "GB"
            },
            {
                "name": "Windygates",
                "countryCode": "GB"
            },
            {
                "name": "Wishaw",
                "countryCode": "GB"
            },
            {
                "name": "Aberaeron",
                "countryCode": "GB"
            },
            {
                "name": "Abercanaid",
                "countryCode": "GB"
            },
            {
                "name": "Abercarn",
                "countryCode": "GB"
            },
            {
                "name": "Abercynon",
                "countryCode": "GB"
            },
            {
                "name": "Aberdare",
                "countryCode": "GB"
            },
            {
                "name": "Aberfan",
                "countryCode": "GB"
            },
            {
                "name": "Abergavenny",
                "countryCode": "GB"
            },
            {
                "name": "Abergele",
                "countryCode": "GB"
            },
            {
                "name": "Aberkenfig",
                "countryCode": "GB"
            },
            {
                "name": "Aberporth",
                "countryCode": "GB"
            },
            {
                "name": "Abertillery",
                "countryCode": "GB"
            },
            {
                "name": "Abertridwr",
                "countryCode": "GB"
            },
            {
                "name": "Aberystwyth",
                "countryCode": "GB"
            },
            {
                "name": "Amlwch",
                "countryCode": "GB"
            },
            {
                "name": "Ammanford",
                "countryCode": "GB"
            },
            {
                "name": "Anglesey",
                "countryCode": "GB"
            },
            {
                "name": "Bagillt",
                "countryCode": "GB"
            },
            {
                "name": "Bala",
                "countryCode": "GB"
            },
            {
                "name": "Bangor",
                "countryCode": "GB"
            },
            {
                "name": "Bargoed",
                "countryCode": "GB"
            },
            {
                "name": "Barmouth",
                "countryCode": "GB"
            },
            {
                "name": "Barry",
                "countryCode": "GB"
            },
            {
                "name": "Beaumaris",
                "countryCode": "GB"
            },
            {
                "name": "Beddau",
                "countryCode": "GB"
            },
            {
                "name": "Bedlinog",
                "countryCode": "GB"
            },
            {
                "name": "Bedwas",
                "countryCode": "GB"
            },
            {
                "name": "Benllech",
                "countryCode": "GB"
            },
            {
                "name": "Bethesda",
                "countryCode": "GB"
            },
            {
                "name": "Betws",
                "countryCode": "GB"
            },
            {
                "name": "Betws-y-Coed",
                "countryCode": "GB"
            },
            {
                "name": "Bishopston",
                "countryCode": "GB"
            },
            {
                "name": "Blackwood",
                "countryCode": "GB"
            },
            {
                "name": "Blaenau Gwent",
                "countryCode": "GB"
            },
            {
                "name": "Blaenau-Ffestiniog",
                "countryCode": "GB"
            },
            {
                "name": "Blaenavon",
                "countryCode": "GB"
            },
            {
                "name": "Blaengwynfi",
                "countryCode": "GB"
            },
            {
                "name": "Bodedern",
                "countryCode": "GB"
            },
            {
                "name": "Bodelwyddan",
                "countryCode": "GB"
            },
            {
                "name": "Borth",
                "countryCode": "GB"
            },
            {
                "name": "Bow Street",
                "countryCode": "GB"
            },
            {
                "name": "Brecon",
                "countryCode": "GB"
            },
            {
                "name": "Bridgend",
                "countryCode": "GB"
            },
            {
                "name": "Bridgend county borough",
                "countryCode": "GB"
            },
            {
                "name": "Briton Ferry",
                "countryCode": "GB"
            },
            {
                "name": "Broughton",
                "countryCode": "GB"
            },
            {
                "name": "Brymbo",
                "countryCode": "GB"
            },
            {
                "name": "Bryn",
                "countryCode": "GB"
            },
            {
                "name": "Brynamman",
                "countryCode": "GB"
            },
            {
                "name": "Brynmawr",
                "countryCode": "GB"
            },
            {
                "name": "Brynna",
                "countryCode": "GB"
            },
            {
                "name": "Buckley",
                "countryCode": "GB"
            },
            {
                "name": "Builth Wells",
                "countryCode": "GB"
            },
            {
                "name": "Burry Port",
                "countryCode": "GB"
            },
            {
                "name": "Caergwrle",
                "countryCode": "GB"
            },
            {
                "name": "Caerleon",
                "countryCode": "GB"
            },
            {
                "name": "Caernarfon",
                "countryCode": "GB"
            },
            {
                "name": "Caerphilly",
                "countryCode": "GB"
            },
            {
                "name": "Caerphilly County Borough",
                "countryCode": "GB"
            },
            {
                "name": "Caerwent",
                "countryCode": "GB"
            },
            {
                "name": "Caldicot",
                "countryCode": "GB"
            },
            {
                "name": "Cardiff",
                "countryCode": "GB"
            },
            {
                "name": "Cardigan",
                "countryCode": "GB"
            },
            {
                "name": "Carmarthen",
                "countryCode": "GB"
            },
            {
                "name": "Carmarthenshire",
                "countryCode": "GB"
            },
            {
                "name": "Cefn Cribwr",
                "countryCode": "GB"
            },
            {
                "name": "Cemaes Bay",
                "countryCode": "GB"
            },
            {
                "name": "Chepstow",
                "countryCode": "GB"
            },
            {
                "name": "Chirk",
                "countryCode": "GB"
            },
            {
                "name": "City and County of Swansea",
                "countryCode": "GB"
            },
            {
                "name": "Clydach",
                "countryCode": "GB"
            },
            {
                "name": "Coedpoeth",
                "countryCode": "GB"
            },
            {
                "name": "Coity",
                "countryCode": "GB"
            },
            {
                "name": "Colwyn Bay",
                "countryCode": "GB"
            },
            {
                "name": "Conwy",
                "countryCode": "GB"
            },
            {
                "name": "County of Ceredigion",
                "countryCode": "GB"
            },
            {
                "name": "County of Flintshire",
                "countryCode": "GB"
            },
            {
                "name": "Cowbridge",
                "countryCode": "GB"
            },
            {
                "name": "Criccieth",
                "countryCode": "GB"
            },
            {
                "name": "Crickhowell",
                "countryCode": "GB"
            },
            {
                "name": "Croeserw",
                "countryCode": "GB"
            },
            {
                "name": "Crofty",
                "countryCode": "GB"
            },
            {
                "name": "Cross Hands",
                "countryCode": "GB"
            },
            {
                "name": "Crumlin",
                "countryCode": "GB"
            },
            {
                "name": "Crymych",
                "countryCode": "GB"
            },
            {
                "name": "Crynant",
                "countryCode": "GB"
            },
            {
                "name": "Cwm",
                "countryCode": "GB"
            },
            {
                "name": "Cwmafan",
                "countryCode": "GB"
            },
            {
                "name": "Cwmbach",
                "countryCode": "GB"
            },
            {
                "name": "Cwmbran",
                "countryCode": "GB"
            },
            {
                "name": "Deeside",
                "countryCode": "GB"
            },
            {
                "name": "Deganwy",
                "countryCode": "GB"
            },
            {
                "name": "Deiniolen",
                "countryCode": "GB"
            },
            {
                "name": "Denbigh",
                "countryCode": "GB"
            },
            {
                "name": "Denbighshire",
                "countryCode": "GB"
            },
            {
                "name": "Deri",
                "countryCode": "GB"
            },
            {
                "name": "Dinas Powys",
                "countryCode": "GB"
            },
            {
                "name": "Dolgellau",
                "countryCode": "GB"
            },
            {
                "name": "Dyffryn Ardudwy",
                "countryCode": "GB"
            },
            {
                "name": "Dyserth",
                "countryCode": "GB"
            },
            {
                "name": "Ebbw Vale",
                "countryCode": "GB"
            },
            {
                "name": "Ferndale",
                "countryCode": "GB"
            },
            {
                "name": "Fishguard",
                "countryCode": "GB"
            },
            {
                "name": "Flint",
                "countryCode": "GB"
            },
            {
                "name": "Fochriw",
                "countryCode": "GB"
            },
            {
                "name": "Four Crosses",
                "countryCode": "GB"
            },
            {
                "name": "Gaerwen",
                "countryCode": "GB"
            },
            {
                "name": "Gelligaer",
                "countryCode": "GB"
            },
            {
                "name": "Gilfach Goch",
                "countryCode": "GB"
            },
            {
                "name": "Gilwern",
                "countryCode": "GB"
            },
            {
                "name": "Glanamman",
                "countryCode": "GB"
            },
            {
                "name": "Glandwr",
                "countryCode": "GB"
            },
            {
                "name": "Glyn-neath",
                "countryCode": "GB"
            },
            {
                "name": "Glyncorrwg",
                "countryCode": "GB"
            },
            {
                "name": "Goodwick",
                "countryCode": "GB"
            },
            {
                "name": "Gorseinon",
                "countryCode": "GB"
            },
            {
                "name": "Govilon",
                "countryCode": "GB"
            },
            {
                "name": "Greenfield",
                "countryCode": "GB"
            },
            {
                "name": "Gresford",
                "countryCode": "GB"
            },
            {
                "name": "Gronant",
                "countryCode": "GB"
            },
            {
                "name": "Grovesend",
                "countryCode": "GB"
            },
            {
                "name": "Guilsfield",
                "countryCode": "GB"
            },
            {
                "name": "Gwynedd",
                "countryCode": "GB"
            },
            {
                "name": "Harlech",
                "countryCode": "GB"
            },
            {
                "name": "Haverfordwest",
                "countryCode": "GB"
            },
            {
                "name": "Hawarden",
                "countryCode": "GB"
            },
            {
                "name": "Hay",
                "countryCode": "GB"
            },
            {
                "name": "Hengoed",
                "countryCode": "GB"
            },
            {
                "name": "Henllan",
                "countryCode": "GB"
            },
            {
                "name": "Hirwaun",
                "countryCode": "GB"
            },
            {
                "name": "Holyhead",
                "countryCode": "GB"
            },
            {
                "name": "Holywell",
                "countryCode": "GB"
            },
            {
                "name": "Hook",
                "countryCode": "GB"
            },
            {
                "name": "Hope",
                "countryCode": "GB"
            },
            {
                "name": "Hundleton",
                "countryCode": "GB"
            },
            {
                "name": "Johnston",
                "countryCode": "GB"
            },
            {
                "name": "Kidwelly",
                "countryCode": "GB"
            },
            {
                "name": "Kilgetty",
                "countryCode": "GB"
            },
            {
                "name": "Knighton",
                "countryCode": "GB"
            },
            {
                "name": "Lampeter",
                "countryCode": "GB"
            },
            {
                "name": "Leeswood",
                "countryCode": "GB"
            },
            {
                "name": "Letterston",
                "countryCode": "GB"
            },
            {
                "name": "Llanarth",
                "countryCode": "GB"
            },
            {
                "name": "Llanbedr",
                "countryCode": "GB"
            },
            {
                "name": "Llanberis",
                "countryCode": "GB"
            },
            {
                "name": "Llanbradach",
                "countryCode": "GB"
            },
            {
                "name": "Llandeilo",
                "countryCode": "GB"
            },
            {
                "name": "Llandovery",
                "countryCode": "GB"
            },
            {
                "name": "Llandrindod Wells",
                "countryCode": "GB"
            },
            {
                "name": "Llandudno",
                "countryCode": "GB"
            },
            {
                "name": "Llandybie",
                "countryCode": "GB"
            },
            {
                "name": "Llandysul",
                "countryCode": "GB"
            },
            {
                "name": "Llanelli",
                "countryCode": "GB"
            },
            {
                "name": "Llanerchymedd",
                "countryCode": "GB"
            },
            {
                "name": "Llanfair Caereinion",
                "countryCode": "GB"
            },
            {
                "name": "Llanfairfechan",
                "countryCode": "GB"
            },
            {
                "name": "Llanfairpwllgwyngyll",
                "countryCode": "GB"
            },
            {
                "name": "Llanfyllin",
                "countryCode": "GB"
            },
            {
                "name": "Llangefni",
                "countryCode": "GB"
            },
            {
                "name": "Llangoed",
                "countryCode": "GB"
            },
            {
                "name": "Llangollen",
                "countryCode": "GB"
            },
            {
                "name": "Llangwm",
                "countryCode": "GB"
            },
            {
                "name": "Llangybi",
                "countryCode": "GB"
            },
            {
                "name": "Llangynidr",
                "countryCode": "GB"
            },
            {
                "name": "Llanharan",
                "countryCode": "GB"
            },
            {
                "name": "Llanharry",
                "countryCode": "GB"
            },
            {
                "name": "Llanidloes",
                "countryCode": "GB"
            },
            {
                "name": "Llanilar",
                "countryCode": "GB"
            },
            {
                "name": "Llanrhaeadr-ym-Mochnant",
                "countryCode": "GB"
            },
            {
                "name": "Llanrug",
                "countryCode": "GB"
            },
            {
                "name": "Llanrwst",
                "countryCode": "GB"
            },
            {
                "name": "Llansantffraid Glan Conwy",
                "countryCode": "GB"
            },
            {
                "name": "Llansteffan",
                "countryCode": "GB"
            },
            {
                "name": "Llantrisant",
                "countryCode": "GB"
            },
            {
                "name": "Llantwit Fardre",
                "countryCode": "GB"
            },
            {
                "name": "Llantwit Major",
                "countryCode": "GB"
            },
            {
                "name": "Llwynypia",
                "countryCode": "GB"
            },
            {
                "name": "Machen",
                "countryCode": "GB"
            },
            {
                "name": "Machynlleth",
                "countryCode": "GB"
            },
            {
                "name": "Maesteg",
                "countryCode": "GB"
            },
            {
                "name": "Magor",
                "countryCode": "GB"
            },
            {
                "name": "Marchwiel",
                "countryCode": "GB"
            },
            {
                "name": "Marshfield",
                "countryCode": "GB"
            },
            {
                "name": "Menai Bridge",
                "countryCode": "GB"
            },
            {
                "name": "Merthyr Tydfil",
                "countryCode": "GB"
            },
            {
                "name": "Merthyr Tydfil County Borough",
                "countryCode": "GB"
            },
            {
                "name": "Milford Haven",
                "countryCode": "GB"
            },
            {
                "name": "Moelfre",
                "countryCode": "GB"
            },
            {
                "name": "Mold",
                "countryCode": "GB"
            },
            {
                "name": "Monmouth",
                "countryCode": "GB"
            },
            {
                "name": "Monmouthshire",
                "countryCode": "GB"
            },
            {
                "name": "Mostyn",
                "countryCode": "GB"
            },
            {
                "name": "Mountain Ash",
                "countryCode": "GB"
            },
            {
                "name": "Narberth",
                "countryCode": "GB"
            },
            {
                "name": "Neath",
                "countryCode": "GB"
            },
            {
                "name": "Neath Port Talbot",
                "countryCode": "GB"
            },
            {
                "name": "Nefyn",
                "countryCode": "GB"
            },
            {
                "name": "Nelson",
                "countryCode": "GB"
            },
            {
                "name": "New Quay",
                "countryCode": "GB"
            },
            {
                "name": "New Tredegar",
                "countryCode": "GB"
            },
            {
                "name": "Newbridge",
                "countryCode": "GB"
            },
            {
                "name": "Newcastle Emlyn",
                "countryCode": "GB"
            },
            {
                "name": "Newport",
                "countryCode": "GB"
            },
            {
                "name": "Newtown",
                "countryCode": "GB"
            },
            {
                "name": "Neyland",
                "countryCode": "GB"
            },
            {
                "name": "Northop",
                "countryCode": "GB"
            },
            {
                "name": "Ogmore Vale",
                "countryCode": "GB"
            },
            {
                "name": "Overton",
                "countryCode": "GB"
            },
            {
                "name": "Pembroke",
                "countryCode": "GB"
            },
            {
                "name": "Pembroke Dock",
                "countryCode": "GB"
            },
            {
                "name": "Pembrokeshire",
                "countryCode": "GB"
            },
            {
                "name": "Pen-clawdd",
                "countryCode": "GB"
            },
            {
                "name": "Penally",
                "countryCode": "GB"
            },
            {
                "name": "Penarth",
                "countryCode": "GB"
            },
            {
                "name": "Pencader",
                "countryCode": "GB"
            },
            {
                "name": "Pencoed",
                "countryCode": "GB"
            },
            {
                "name": "Penmaenmawr",
                "countryCode": "GB"
            },
            {
                "name": "Penparcau",
                "countryCode": "GB"
            },
            {
                "name": "Penrhyndeudraeth",
                "countryCode": "GB"
            },
            {
                "name": "Pentre",
                "countryCode": "GB"
            },
            {
                "name": "Pentyrch",
                "countryCode": "GB"
            },
            {
                "name": "Penyffordd",
                "countryCode": "GB"
            },
            {
                "name": "Penygroes",
                "countryCode": "GB"
            },
            {
                "name": "Pont Rhyd-y-cyff",
                "countryCode": "GB"
            },
            {
                "name": "Pontarddulais",
                "countryCode": "GB"
            },
            {
                "name": "Pontlliw",
                "countryCode": "GB"
            },
            {
                "name": "Pontyates",
                "countryCode": "GB"
            },
            {
                "name": "Pontyberem",
                "countryCode": "GB"
            },
            {
                "name": "Pontyclun",
                "countryCode": "GB"
            },
            {
                "name": "Pontycymer",
                "countryCode": "GB"
            },
            {
                "name": "Pontypool",
                "countryCode": "GB"
            },
            {
                "name": "Pontypridd",
                "countryCode": "GB"
            },
            {
                "name": "Port Talbot",
                "countryCode": "GB"
            },
            {
                "name": "Porthcawl",
                "countryCode": "GB"
            },
            {
                "name": "Porthmadog",
                "countryCode": "GB"
            },
            {
                "name": "Prestatyn",
                "countryCode": "GB"
            },
            {
                "name": "Presteigne",
                "countryCode": "GB"
            },
            {
                "name": "Price Town",
                "countryCode": "GB"
            },
            {
                "name": "Pwllheli",
                "countryCode": "GB"
            },
            {
                "name": "Pyle",
                "countryCode": "GB"
            },
            {
                "name": "Radyr",
                "countryCode": "GB"
            },
            {
                "name": "Raglan",
                "countryCode": "GB"
            },
            {
                "name": "Resolven",
                "countryCode": "GB"
            },
            {
                "name": "Rhayader",
                "countryCode": "GB"
            },
            {
                "name": "Rhondda",
                "countryCode": "GB"
            },
            {
                "name": "Rhondda Cynon Taf",
                "countryCode": "GB"
            },
            {
                "name": "Rhoose",
                "countryCode": "GB"
            },
            {
                "name": "Rhosllanerchrugog",
                "countryCode": "GB"
            },
            {
                "name": "Rhosneigr",
                "countryCode": "GB"
            },
            {
                "name": "Rhuddlan",
                "countryCode": "GB"
            },
            {
                "name": "Rhyl",
                "countryCode": "GB"
            },
            {
                "name": "Rhymney",
                "countryCode": "GB"
            },
            {
                "name": "Risca",
                "countryCode": "GB"
            },
            {
                "name": "Rogiet",
                "countryCode": "GB"
            },
            {
                "name": "Rossett",
                "countryCode": "GB"
            },
            {
                "name": "Ruabon",
                "countryCode": "GB"
            },
            {
                "name": "Ruthin",
                "countryCode": "GB"
            },
            {
                "name": "Saint Asaph",
                "countryCode": "GB"
            },
            {
                "name": "Saint Clears",
                "countryCode": "GB"
            },
            {
                "name": "Saint Davids",
                "countryCode": "GB"
            },
            {
                "name": "Saundersfoot",
                "countryCode": "GB"
            },
            {
                "name": "Seven Sisters",
                "countryCode": "GB"
            },
            {
                "name": "Sir Powys",
                "countryCode": "GB"
            },
            {
                "name": "Southgate",
                "countryCode": "GB"
            },
            {
                "name": "Spittal",
                "countryCode": "GB"
            },
            {
                "name": "Steynton",
                "countryCode": "GB"
            },
            {
                "name": "Swansea",
                "countryCode": "GB"
            },
            {
                "name": "Taibach",
                "countryCode": "GB"
            },
            {
                "name": "Tal-y-bont",
                "countryCode": "GB"
            },
            {
                "name": "Talgarth",
                "countryCode": "GB"
            },
            {
                "name": "Talysarn",
                "countryCode": "GB"
            },
            {
                "name": "Templeton",
                "countryCode": "GB"
            },
            {
                "name": "Tenby",
                "countryCode": "GB"
            },
            {
                "name": "Three Crosses",
                "countryCode": "GB"
            },
            {
                "name": "Tintern",
                "countryCode": "GB"
            },
            {
                "name": "Tonypandy",
                "countryCode": "GB"
            },
            {
                "name": "Tonyrefail",
                "countryCode": "GB"
            },
            {
                "name": "Torfaen County Borough",
                "countryCode": "GB"
            },
            {
                "name": "Trawsfynydd",
                "countryCode": "GB"
            },
            {
                "name": "Tredegar",
                "countryCode": "GB"
            },
            {
                "name": "Trefnant",
                "countryCode": "GB"
            },
            {
                "name": "Tregaron",
                "countryCode": "GB"
            },
            {
                "name": "Tregarth",
                "countryCode": "GB"
            },
            {
                "name": "Treharris",
                "countryCode": "GB"
            },
            {
                "name": "Treorchy",
                "countryCode": "GB"
            },
            {
                "name": "Treuddyn",
                "countryCode": "GB"
            },
            {
                "name": "Trimsaran",
                "countryCode": "GB"
            },
            {
                "name": "Tumble",
                "countryCode": "GB"
            },
            {
                "name": "Tycroes",
                "countryCode": "GB"
            },
            {
                "name": "Tywyn",
                "countryCode": "GB"
            },
            {
                "name": "Undy",
                "countryCode": "GB"
            },
            {
                "name": "Usk",
                "countryCode": "GB"
            },
            {
                "name": "Vale of Glamorgan",
                "countryCode": "GB"
            },
            {
                "name": "Valley",
                "countryCode": "GB"
            },
            {
                "name": "Victoria",
                "countryCode": "GB"
            },
            {
                "name": "Welshpool",
                "countryCode": "GB"
            },
            {
                "name": "Wenvoe",
                "countryCode": "GB"
            },
            {
                "name": "Whitland",
                "countryCode": "GB"
            },
            {
                "name": "Wick",
                "countryCode": "GB"
            },
            {
                "name": "Wrexham",
                "countryCode": "GB"
            },
            {
                "name": "Y Felinheli",
                "countryCode": "GB"
            },
            {
                "name": "Ynysybwl",
                "countryCode": "GB"
            },
            {
                "name": "Ystalyfera",
                "countryCode": "GB"
            }
        ]
    },
    {
        "name": "United States",
        "phonecode": "1",
        "cities": [
            {
                "name": "Akutan",
                "countryCode": "US"
            },
            {
                "name": "Aleutians East Borough",
                "countryCode": "US"
            },
            {
                "name": "Aleutians West Census Area",
                "countryCode": "US"
            },
            {
                "name": "Anchor Point",
                "countryCode": "US"
            },
            {
                "name": "Anchorage",
                "countryCode": "US"
            },
            {
                "name": "Anchorage Municipality",
                "countryCode": "US"
            },
            {
                "name": "Badger",
                "countryCode": "US"
            },
            {
                "name": "Barrow",
                "countryCode": "US"
            },
            {
                "name": "Bear Creek",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Bethel Census Area",
                "countryCode": "US"
            },
            {
                "name": "Big Lake",
                "countryCode": "US"
            },
            {
                "name": "Bristol Bay Borough",
                "countryCode": "US"
            },
            {
                "name": "Butte",
                "countryCode": "US"
            },
            {
                "name": "Chevak",
                "countryCode": "US"
            },
            {
                "name": "City and Borough of Wrangell",
                "countryCode": "US"
            },
            {
                "name": "Cohoe",
                "countryCode": "US"
            },
            {
                "name": "College",
                "countryCode": "US"
            },
            {
                "name": "Cordova",
                "countryCode": "US"
            },
            {
                "name": "Craig",
                "countryCode": "US"
            },
            {
                "name": "Deltana",
                "countryCode": "US"
            },
            {
                "name": "Denali Borough",
                "countryCode": "US"
            },
            {
                "name": "Diamond Ridge",
                "countryCode": "US"
            },
            {
                "name": "Dillingham",
                "countryCode": "US"
            },
            {
                "name": "Dillingham Census Area",
                "countryCode": "US"
            },
            {
                "name": "Dutch Harbor",
                "countryCode": "US"
            },
            {
                "name": "Eagle River",
                "countryCode": "US"
            },
            {
                "name": "Eielson Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Elmendorf Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Ester",
                "countryCode": "US"
            },
            {
                "name": "Fairbanks",
                "countryCode": "US"
            },
            {
                "name": "Fairbanks North Star Borough",
                "countryCode": "US"
            },
            {
                "name": "Farm Loop",
                "countryCode": "US"
            },
            {
                "name": "Farmers Loop",
                "countryCode": "US"
            },
            {
                "name": "Fishhook",
                "countryCode": "US"
            },
            {
                "name": "Fritz Creek",
                "countryCode": "US"
            },
            {
                "name": "Gateway",
                "countryCode": "US"
            },
            {
                "name": "Girdwood",
                "countryCode": "US"
            },
            {
                "name": "Haines",
                "countryCode": "US"
            },
            {
                "name": "Haines Borough",
                "countryCode": "US"
            },
            {
                "name": "Healy",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Hoonah-Angoon Census Area",
                "countryCode": "US"
            },
            {
                "name": "Hooper Bay",
                "countryCode": "US"
            },
            {
                "name": "Houston",
                "countryCode": "US"
            },
            {
                "name": "Juneau",
                "countryCode": "US"
            },
            {
                "name": "Kalifornsky",
                "countryCode": "US"
            },
            {
                "name": "Kenai",
                "countryCode": "US"
            },
            {
                "name": "Kenai Peninsula Borough",
                "countryCode": "US"
            },
            {
                "name": "Ketchikan",
                "countryCode": "US"
            },
            {
                "name": "Ketchikan Gateway Borough",
                "countryCode": "US"
            },
            {
                "name": "King Cove",
                "countryCode": "US"
            },
            {
                "name": "Knik-Fairview",
                "countryCode": "US"
            },
            {
                "name": "Kodiak",
                "countryCode": "US"
            },
            {
                "name": "Kodiak Island Borough",
                "countryCode": "US"
            },
            {
                "name": "Kodiak Station",
                "countryCode": "US"
            },
            {
                "name": "Kotzebue",
                "countryCode": "US"
            },
            {
                "name": "Lake and Peninsula Borough",
                "countryCode": "US"
            },
            {
                "name": "Lakes",
                "countryCode": "US"
            },
            {
                "name": "Lazy Mountain",
                "countryCode": "US"
            },
            {
                "name": "Matanuska-Susitna Borough",
                "countryCode": "US"
            },
            {
                "name": "Meadow Lakes",
                "countryCode": "US"
            },
            {
                "name": "Metlakatla",
                "countryCode": "US"
            },
            {
                "name": "Nikiski",
                "countryCode": "US"
            },
            {
                "name": "Nome",
                "countryCode": "US"
            },
            {
                "name": "Nome Census Area",
                "countryCode": "US"
            },
            {
                "name": "North Pole",
                "countryCode": "US"
            },
            {
                "name": "North Slope Borough",
                "countryCode": "US"
            },
            {
                "name": "Northwest Arctic Borough",
                "countryCode": "US"
            },
            {
                "name": "Palmer",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Petersburg Borough",
                "countryCode": "US"
            },
            {
                "name": "Prudhoe Bay",
                "countryCode": "US"
            },
            {
                "name": "Ridgeway",
                "countryCode": "US"
            },
            {
                "name": "Salcha",
                "countryCode": "US"
            },
            {
                "name": "Sand Point",
                "countryCode": "US"
            },
            {
                "name": "Seward",
                "countryCode": "US"
            },
            {
                "name": "Sitka",
                "countryCode": "US"
            },
            {
                "name": "Sitka City and Borough",
                "countryCode": "US"
            },
            {
                "name": "Skagway Municipality",
                "countryCode": "US"
            },
            {
                "name": "Soldotna",
                "countryCode": "US"
            },
            {
                "name": "Southeast Fairbanks Census Area",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Sutton-Alpine",
                "countryCode": "US"
            },
            {
                "name": "Tanaina",
                "countryCode": "US"
            },
            {
                "name": "Tok",
                "countryCode": "US"
            },
            {
                "name": "Unalaska",
                "countryCode": "US"
            },
            {
                "name": "Valdez",
                "countryCode": "US"
            },
            {
                "name": "Valdez-Cordova Census Area",
                "countryCode": "US"
            },
            {
                "name": "Wasilla",
                "countryCode": "US"
            },
            {
                "name": "Willow",
                "countryCode": "US"
            },
            {
                "name": "Wrangell",
                "countryCode": "US"
            },
            {
                "name": "Y",
                "countryCode": "US"
            },
            {
                "name": "Yakutat City and Borough",
                "countryCode": "US"
            },
            {
                "name": "Yukon-Koyukuk Census Area",
                "countryCode": "US"
            },
            {
                "name": "Abbeville",
                "countryCode": "US"
            },
            {
                "name": "Adamsville",
                "countryCode": "US"
            },
            {
                "name": "Alabaster",
                "countryCode": "US"
            },
            {
                "name": "Albertville",
                "countryCode": "US"
            },
            {
                "name": "Alexander City",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Aliceville",
                "countryCode": "US"
            },
            {
                "name": "Andalusia",
                "countryCode": "US"
            },
            {
                "name": "Anniston",
                "countryCode": "US"
            },
            {
                "name": "Arab",
                "countryCode": "US"
            },
            {
                "name": "Argo",
                "countryCode": "US"
            },
            {
                "name": "Ashford",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Ashville",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atmore",
                "countryCode": "US"
            },
            {
                "name": "Attalla",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Autauga County",
                "countryCode": "US"
            },
            {
                "name": "Baldwin County",
                "countryCode": "US"
            },
            {
                "name": "Ballplay",
                "countryCode": "US"
            },
            {
                "name": "Barbour County",
                "countryCode": "US"
            },
            {
                "name": "Bay Minette",
                "countryCode": "US"
            },
            {
                "name": "Bayou La Batre",
                "countryCode": "US"
            },
            {
                "name": "Bear Creek",
                "countryCode": "US"
            },
            {
                "name": "Berry",
                "countryCode": "US"
            },
            {
                "name": "Bessemer",
                "countryCode": "US"
            },
            {
                "name": "Bibb County",
                "countryCode": "US"
            },
            {
                "name": "Birmingham",
                "countryCode": "US"
            },
            {
                "name": "Blount County",
                "countryCode": "US"
            },
            {
                "name": "Blountsville",
                "countryCode": "US"
            },
            {
                "name": "Blue Ridge",
                "countryCode": "US"
            },
            {
                "name": "Boaz",
                "countryCode": "US"
            },
            {
                "name": "Brent",
                "countryCode": "US"
            },
            {
                "name": "Brewton",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Brook Highland",
                "countryCode": "US"
            },
            {
                "name": "Brookside",
                "countryCode": "US"
            },
            {
                "name": "Brookwood",
                "countryCode": "US"
            },
            {
                "name": "Brundidge",
                "countryCode": "US"
            },
            {
                "name": "Bullock County",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Bynum",
                "countryCode": "US"
            },
            {
                "name": "Cahaba Heights",
                "countryCode": "US"
            },
            {
                "name": "Calera",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Carbon Hill",
                "countryCode": "US"
            },
            {
                "name": "Carlisle-Rockledge",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Cedar Bluff",
                "countryCode": "US"
            },
            {
                "name": "Center Point",
                "countryCode": "US"
            },
            {
                "name": "Centre",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Chalkville",
                "countryCode": "US"
            },
            {
                "name": "Chambers County",
                "countryCode": "US"
            },
            {
                "name": "Chatom",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Cherokee",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Chickasaw",
                "countryCode": "US"
            },
            {
                "name": "Childersburg",
                "countryCode": "US"
            },
            {
                "name": "Chilton County",
                "countryCode": "US"
            },
            {
                "name": "Choccolocco",
                "countryCode": "US"
            },
            {
                "name": "Choctaw County",
                "countryCode": "US"
            },
            {
                "name": "Citronelle",
                "countryCode": "US"
            },
            {
                "name": "Clanton",
                "countryCode": "US"
            },
            {
                "name": "Clarke County",
                "countryCode": "US"
            },
            {
                "name": "Clay",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Cleburne County",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clio",
                "countryCode": "US"
            },
            {
                "name": "Coaling",
                "countryCode": "US"
            },
            {
                "name": "Coffee County",
                "countryCode": "US"
            },
            {
                "name": "Colbert County",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Columbiana",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Conecuh County",
                "countryCode": "US"
            },
            {
                "name": "Coosa County",
                "countryCode": "US"
            },
            {
                "name": "Coosada",
                "countryCode": "US"
            },
            {
                "name": "Cordova",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood",
                "countryCode": "US"
            },
            {
                "name": "Covington County",
                "countryCode": "US"
            },
            {
                "name": "Cowarts",
                "countryCode": "US"
            },
            {
                "name": "Crenshaw County",
                "countryCode": "US"
            },
            {
                "name": "Creola",
                "countryCode": "US"
            },
            {
                "name": "Crossville",
                "countryCode": "US"
            },
            {
                "name": "Cullman",
                "countryCode": "US"
            },
            {
                "name": "Cullman County",
                "countryCode": "US"
            },
            {
                "name": "Dadeville",
                "countryCode": "US"
            },
            {
                "name": "Dale County",
                "countryCode": "US"
            },
            {
                "name": "Daleville",
                "countryCode": "US"
            },
            {
                "name": "Dallas County",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Daphne",
                "countryCode": "US"
            },
            {
                "name": "Dauphin Island",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "Deatsville",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Demopolis",
                "countryCode": "US"
            },
            {
                "name": "Dixiana",
                "countryCode": "US"
            },
            {
                "name": "Dora",
                "countryCode": "US"
            },
            {
                "name": "Dothan",
                "countryCode": "US"
            },
            {
                "name": "Double Springs",
                "countryCode": "US"
            },
            {
                "name": "East Brewton",
                "countryCode": "US"
            },
            {
                "name": "East Florence",
                "countryCode": "US"
            },
            {
                "name": "Eclectic",
                "countryCode": "US"
            },
            {
                "name": "Elba",
                "countryCode": "US"
            },
            {
                "name": "Elberta",
                "countryCode": "US"
            },
            {
                "name": "Elmore",
                "countryCode": "US"
            },
            {
                "name": "Elmore County",
                "countryCode": "US"
            },
            {
                "name": "Emerald Mountain",
                "countryCode": "US"
            },
            {
                "name": "Enterprise",
                "countryCode": "US"
            },
            {
                "name": "Escambia County",
                "countryCode": "US"
            },
            {
                "name": "Etowah County",
                "countryCode": "US"
            },
            {
                "name": "Eufaula",
                "countryCode": "US"
            },
            {
                "name": "Eutaw",
                "countryCode": "US"
            },
            {
                "name": "Evergreen",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairhope",
                "countryCode": "US"
            },
            {
                "name": "Falkville",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Flint City",
                "countryCode": "US"
            },
            {
                "name": "Flomaton",
                "countryCode": "US"
            },
            {
                "name": "Florala",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Foley",
                "countryCode": "US"
            },
            {
                "name": "Forestdale",
                "countryCode": "US"
            },
            {
                "name": "Fort Deposit",
                "countryCode": "US"
            },
            {
                "name": "Fort Payne",
                "countryCode": "US"
            },
            {
                "name": "Fort Rucker",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frisco City",
                "countryCode": "US"
            },
            {
                "name": "Fultondale",
                "countryCode": "US"
            },
            {
                "name": "Fyffe",
                "countryCode": "US"
            },
            {
                "name": "Gadsden",
                "countryCode": "US"
            },
            {
                "name": "Gardendale",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Geneva County",
                "countryCode": "US"
            },
            {
                "name": "Georgiana",
                "countryCode": "US"
            },
            {
                "name": "Glencoe",
                "countryCode": "US"
            },
            {
                "name": "Good Hope",
                "countryCode": "US"
            },
            {
                "name": "Goodwater",
                "countryCode": "US"
            },
            {
                "name": "Gordo",
                "countryCode": "US"
            },
            {
                "name": "Grand Bay",
                "countryCode": "US"
            },
            {
                "name": "Grayson Valley",
                "countryCode": "US"
            },
            {
                "name": "Graysville",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greensboro",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Grove Hill",
                "countryCode": "US"
            },
            {
                "name": "Guin",
                "countryCode": "US"
            },
            {
                "name": "Gulf Shores",
                "countryCode": "US"
            },
            {
                "name": "Guntersville",
                "countryCode": "US"
            },
            {
                "name": "Hackleburg",
                "countryCode": "US"
            },
            {
                "name": "Hale County",
                "countryCode": "US"
            },
            {
                "name": "Haleyville",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hanceville",
                "countryCode": "US"
            },
            {
                "name": "Harpersville",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hartselle",
                "countryCode": "US"
            },
            {
                "name": "Harvest",
                "countryCode": "US"
            },
            {
                "name": "Hayden",
                "countryCode": "US"
            },
            {
                "name": "Hayneville",
                "countryCode": "US"
            },
            {
                "name": "Hazel Green",
                "countryCode": "US"
            },
            {
                "name": "Headland",
                "countryCode": "US"
            },
            {
                "name": "Heflin",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Henagar",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Highland Lakes",
                "countryCode": "US"
            },
            {
                "name": "Hokes Bluff",
                "countryCode": "US"
            },
            {
                "name": "Holt",
                "countryCode": "US"
            },
            {
                "name": "Holtville",
                "countryCode": "US"
            },
            {
                "name": "Homewood",
                "countryCode": "US"
            },
            {
                "name": "Hoover",
                "countryCode": "US"
            },
            {
                "name": "Houston County",
                "countryCode": "US"
            },
            {
                "name": "Hueytown",
                "countryCode": "US"
            },
            {
                "name": "Huguley",
                "countryCode": "US"
            },
            {
                "name": "Huntsville",
                "countryCode": "US"
            },
            {
                "name": "Indian Springs Village",
                "countryCode": "US"
            },
            {
                "name": "Inverness",
                "countryCode": "US"
            },
            {
                "name": "Irondale",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jemison",
                "countryCode": "US"
            },
            {
                "name": "Kimberly",
                "countryCode": "US"
            },
            {
                "name": "Kinsey",
                "countryCode": "US"
            },
            {
                "name": "Ladonia",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lake Purdy",
                "countryCode": "US"
            },
            {
                "name": "Lake View",
                "countryCode": "US"
            },
            {
                "name": "Lamar County",
                "countryCode": "US"
            },
            {
                "name": "Lanett",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale County",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leeds",
                "countryCode": "US"
            },
            {
                "name": "Leesburg",
                "countryCode": "US"
            },
            {
                "name": "Level Plains",
                "countryCode": "US"
            },
            {
                "name": "Limestone County",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Lineville",
                "countryCode": "US"
            },
            {
                "name": "Lipscomb",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Locust Fork",
                "countryCode": "US"
            },
            {
                "name": "Lowndes County",
                "countryCode": "US"
            },
            {
                "name": "Loxley",
                "countryCode": "US"
            },
            {
                "name": "Luverne",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Malvern",
                "countryCode": "US"
            },
            {
                "name": "Marbury",
                "countryCode": "US"
            },
            {
                "name": "Marengo County",
                "countryCode": "US"
            },
            {
                "name": "Margaret",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Meadowbrook",
                "countryCode": "US"
            },
            {
                "name": "Meridianville",
                "countryCode": "US"
            },
            {
                "name": "Midfield",
                "countryCode": "US"
            },
            {
                "name": "Midland City",
                "countryCode": "US"
            },
            {
                "name": "Mignon",
                "countryCode": "US"
            },
            {
                "name": "Millbrook",
                "countryCode": "US"
            },
            {
                "name": "Minor",
                "countryCode": "US"
            },
            {
                "name": "Mobile",
                "countryCode": "US"
            },
            {
                "name": "Mobile County",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monroeville",
                "countryCode": "US"
            },
            {
                "name": "Montevallo",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Moody",
                "countryCode": "US"
            },
            {
                "name": "Moores Mill",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morris",
                "countryCode": "US"
            },
            {
                "name": "Moulton",
                "countryCode": "US"
            },
            {
                "name": "Moundville",
                "countryCode": "US"
            },
            {
                "name": "Mount Olive",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mountain Brook",
                "countryCode": "US"
            },
            {
                "name": "Munford",
                "countryCode": "US"
            },
            {
                "name": "Muscle Shoals",
                "countryCode": "US"
            },
            {
                "name": "New Brockton",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "New Market",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "North Bibb",
                "countryCode": "US"
            },
            {
                "name": "Northport",
                "countryCode": "US"
            },
            {
                "name": "Odenville",
                "countryCode": "US"
            },
            {
                "name": "Ohatchee",
                "countryCode": "US"
            },
            {
                "name": "Oneonta",
                "countryCode": "US"
            },
            {
                "name": "Opelika",
                "countryCode": "US"
            },
            {
                "name": "Opp",
                "countryCode": "US"
            },
            {
                "name": "Orange Beach",
                "countryCode": "US"
            },
            {
                "name": "Owens Cross Roads",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Ozark",
                "countryCode": "US"
            },
            {
                "name": "Pelham",
                "countryCode": "US"
            },
            {
                "name": "Pell City",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Phenix City",
                "countryCode": "US"
            },
            {
                "name": "Phil Campbell",
                "countryCode": "US"
            },
            {
                "name": "Pickens County",
                "countryCode": "US"
            },
            {
                "name": "Piedmont",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pike Road",
                "countryCode": "US"
            },
            {
                "name": "Pine Level",
                "countryCode": "US"
            },
            {
                "name": "Pinson",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Grove",
                "countryCode": "US"
            },
            {
                "name": "Point Clear",
                "countryCode": "US"
            },
            {
                "name": "Prattville",
                "countryCode": "US"
            },
            {
                "name": "Priceville",
                "countryCode": "US"
            },
            {
                "name": "Prichard",
                "countryCode": "US"
            },
            {
                "name": "Ragland",
                "countryCode": "US"
            },
            {
                "name": "Rainbow City",
                "countryCode": "US"
            },
            {
                "name": "Rainsville",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Red Bay",
                "countryCode": "US"
            },
            {
                "name": "Redstone Arsenal",
                "countryCode": "US"
            },
            {
                "name": "Reform",
                "countryCode": "US"
            },
            {
                "name": "Rehobeth",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Roanoke",
                "countryCode": "US"
            },
            {
                "name": "Robertsdale",
                "countryCode": "US"
            },
            {
                "name": "Rock Creek",
                "countryCode": "US"
            },
            {
                "name": "Rockford",
                "countryCode": "US"
            },
            {
                "name": "Rogersville",
                "countryCode": "US"
            },
            {
                "name": "Russell County",
                "countryCode": "US"
            },
            {
                "name": "Russellville",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair County",
                "countryCode": "US"
            },
            {
                "name": "Saks",
                "countryCode": "US"
            },
            {
                "name": "Samson",
                "countryCode": "US"
            },
            {
                "name": "Saraland",
                "countryCode": "US"
            },
            {
                "name": "Sardis City",
                "countryCode": "US"
            },
            {
                "name": "Satsuma",
                "countryCode": "US"
            },
            {
                "name": "Scottsboro",
                "countryCode": "US"
            },
            {
                "name": "Selma",
                "countryCode": "US"
            },
            {
                "name": "Selmont-West Selmont",
                "countryCode": "US"
            },
            {
                "name": "Semmes",
                "countryCode": "US"
            },
            {
                "name": "Sheffield",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shoal Creek",
                "countryCode": "US"
            },
            {
                "name": "Slocomb",
                "countryCode": "US"
            },
            {
                "name": "Smiths Station",
                "countryCode": "US"
            },
            {
                "name": "Smoke Rise",
                "countryCode": "US"
            },
            {
                "name": "Southside",
                "countryCode": "US"
            },
            {
                "name": "Spanish Fort",
                "countryCode": "US"
            },
            {
                "name": "Springville",
                "countryCode": "US"
            },
            {
                "name": "Steele",
                "countryCode": "US"
            },
            {
                "name": "Stevenson",
                "countryCode": "US"
            },
            {
                "name": "Stewartville",
                "countryCode": "US"
            },
            {
                "name": "Sulligent",
                "countryCode": "US"
            },
            {
                "name": "Sumiton",
                "countryCode": "US"
            },
            {
                "name": "Summerdale",
                "countryCode": "US"
            },
            {
                "name": "Sumter County",
                "countryCode": "US"
            },
            {
                "name": "Sylacauga",
                "countryCode": "US"
            },
            {
                "name": "Sylvan Springs",
                "countryCode": "US"
            },
            {
                "name": "Sylvania",
                "countryCode": "US"
            },
            {
                "name": "Talladega",
                "countryCode": "US"
            },
            {
                "name": "Talladega County",
                "countryCode": "US"
            },
            {
                "name": "Tallapoosa County",
                "countryCode": "US"
            },
            {
                "name": "Tallassee",
                "countryCode": "US"
            },
            {
                "name": "Tarrant",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Theodore",
                "countryCode": "US"
            },
            {
                "name": "Thomasville",
                "countryCode": "US"
            },
            {
                "name": "Thorsby",
                "countryCode": "US"
            },
            {
                "name": "Tillmans Corner",
                "countryCode": "US"
            },
            {
                "name": "Town Creek",
                "countryCode": "US"
            },
            {
                "name": "Trinity",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Trussville",
                "countryCode": "US"
            },
            {
                "name": "Tuscaloosa",
                "countryCode": "US"
            },
            {
                "name": "Tuscaloosa County",
                "countryCode": "US"
            },
            {
                "name": "Tuscumbia",
                "countryCode": "US"
            },
            {
                "name": "Tuskegee",
                "countryCode": "US"
            },
            {
                "name": "Underwood-Petersville",
                "countryCode": "US"
            },
            {
                "name": "Union Springs",
                "countryCode": "US"
            },
            {
                "name": "Uniontown",
                "countryCode": "US"
            },
            {
                "name": "Valley",
                "countryCode": "US"
            },
            {
                "name": "Valley Grande",
                "countryCode": "US"
            },
            {
                "name": "Vance",
                "countryCode": "US"
            },
            {
                "name": "Vandiver",
                "countryCode": "US"
            },
            {
                "name": "Vernon",
                "countryCode": "US"
            },
            {
                "name": "Vestavia Hills",
                "countryCode": "US"
            },
            {
                "name": "Vincent",
                "countryCode": "US"
            },
            {
                "name": "Walker County",
                "countryCode": "US"
            },
            {
                "name": "Warrior",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Weaver",
                "countryCode": "US"
            },
            {
                "name": "Webb",
                "countryCode": "US"
            },
            {
                "name": "Wedowee",
                "countryCode": "US"
            },
            {
                "name": "West Blocton",
                "countryCode": "US"
            },
            {
                "name": "West End-Cobb Town",
                "countryCode": "US"
            },
            {
                "name": "Westover",
                "countryCode": "US"
            },
            {
                "name": "Wetumpka",
                "countryCode": "US"
            },
            {
                "name": "Whitesboro",
                "countryCode": "US"
            },
            {
                "name": "Wilcox County",
                "countryCode": "US"
            },
            {
                "name": "Wilsonville",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Winston County",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "York",
                "countryCode": "US"
            },
            {
                "name": "Alexander",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Arkadelphia",
                "countryCode": "US"
            },
            {
                "name": "Arkansas City",
                "countryCode": "US"
            },
            {
                "name": "Arkansas County",
                "countryCode": "US"
            },
            {
                "name": "Ash Flat",
                "countryCode": "US"
            },
            {
                "name": "Ashdown",
                "countryCode": "US"
            },
            {
                "name": "Ashley County",
                "countryCode": "US"
            },
            {
                "name": "Atkins",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Austin",
                "countryCode": "US"
            },
            {
                "name": "Bald Knob",
                "countryCode": "US"
            },
            {
                "name": "Barling",
                "countryCode": "US"
            },
            {
                "name": "Batesville",
                "countryCode": "US"
            },
            {
                "name": "Baxter County",
                "countryCode": "US"
            },
            {
                "name": "Bay",
                "countryCode": "US"
            },
            {
                "name": "Beebe",
                "countryCode": "US"
            },
            {
                "name": "Bella Vista",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Bentonville",
                "countryCode": "US"
            },
            {
                "name": "Berryville",
                "countryCode": "US"
            },
            {
                "name": "Bethel Heights",
                "countryCode": "US"
            },
            {
                "name": "Blytheville",
                "countryCode": "US"
            },
            {
                "name": "Bono",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Booneville",
                "countryCode": "US"
            },
            {
                "name": "Bradley County",
                "countryCode": "US"
            },
            {
                "name": "Brinkley",
                "countryCode": "US"
            },
            {
                "name": "Brookland",
                "countryCode": "US"
            },
            {
                "name": "Bryant",
                "countryCode": "US"
            },
            {
                "name": "Bull Shoals",
                "countryCode": "US"
            },
            {
                "name": "Cabot",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Calico Rock",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Caraway",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Cave City",
                "countryCode": "US"
            },
            {
                "name": "Cave Springs",
                "countryCode": "US"
            },
            {
                "name": "Cedarville",
                "countryCode": "US"
            },
            {
                "name": "Centerton",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Cherokee Village",
                "countryCode": "US"
            },
            {
                "name": "Chicot County",
                "countryCode": "US"
            },
            {
                "name": "Clarendon",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Cleburne County",
                "countryCode": "US"
            },
            {
                "name": "Cleveland County",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Coal Hill",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Conway County",
                "countryCode": "US"
            },
            {
                "name": "Corning",
                "countryCode": "US"
            },
            {
                "name": "Craighead County",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crittenden County",
                "countryCode": "US"
            },
            {
                "name": "Cross County",
                "countryCode": "US"
            },
            {
                "name": "Crossett",
                "countryCode": "US"
            },
            {
                "name": "Dallas County",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Dardanelle",
                "countryCode": "US"
            },
            {
                "name": "De Queen",
                "countryCode": "US"
            },
            {
                "name": "De Witt",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Dermott",
                "countryCode": "US"
            },
            {
                "name": "Des Arc",
                "countryCode": "US"
            },
            {
                "name": "Desha County",
                "countryCode": "US"
            },
            {
                "name": "Diaz",
                "countryCode": "US"
            },
            {
                "name": "Dierks",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Drew County",
                "countryCode": "US"
            },
            {
                "name": "Dumas",
                "countryCode": "US"
            },
            {
                "name": "Earle",
                "countryCode": "US"
            },
            {
                "name": "East End",
                "countryCode": "US"
            },
            {
                "name": "El Dorado",
                "countryCode": "US"
            },
            {
                "name": "Elkins",
                "countryCode": "US"
            },
            {
                "name": "Elm Springs",
                "countryCode": "US"
            },
            {
                "name": "England",
                "countryCode": "US"
            },
            {
                "name": "Eudora",
                "countryCode": "US"
            },
            {
                "name": "Eureka Springs",
                "countryCode": "US"
            },
            {
                "name": "Fairfield Bay",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Faulkner County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Flippin",
                "countryCode": "US"
            },
            {
                "name": "Fordyce",
                "countryCode": "US"
            },
            {
                "name": "Forrest City",
                "countryCode": "US"
            },
            {
                "name": "Fort Smith",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Garland County",
                "countryCode": "US"
            },
            {
                "name": "Gassville",
                "countryCode": "US"
            },
            {
                "name": "Gentry",
                "countryCode": "US"
            },
            {
                "name": "Gibson",
                "countryCode": "US"
            },
            {
                "name": "Glenwood",
                "countryCode": "US"
            },
            {
                "name": "Goshen",
                "countryCode": "US"
            },
            {
                "name": "Gosnell",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Gravel Ridge",
                "countryCode": "US"
            },
            {
                "name": "Gravette",
                "countryCode": "US"
            },
            {
                "name": "Green Forest",
                "countryCode": "US"
            },
            {
                "name": "Greenbrier",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenland",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Gurdon",
                "countryCode": "US"
            },
            {
                "name": "Hamburg",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Haskell",
                "countryCode": "US"
            },
            {
                "name": "Hazen",
                "countryCode": "US"
            },
            {
                "name": "Heber Springs",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Helena-West Helena",
                "countryCode": "US"
            },
            {
                "name": "Hempstead County",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Holiday Island",
                "countryCode": "US"
            },
            {
                "name": "Hope",
                "countryCode": "US"
            },
            {
                "name": "Horatio",
                "countryCode": "US"
            },
            {
                "name": "Horseshoe Bend",
                "countryCode": "US"
            },
            {
                "name": "Hot Spring County",
                "countryCode": "US"
            },
            {
                "name": "Hot Springs",
                "countryCode": "US"
            },
            {
                "name": "Hot Springs National Park",
                "countryCode": "US"
            },
            {
                "name": "Hot Springs Village",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Hoxie",
                "countryCode": "US"
            },
            {
                "name": "Hughes",
                "countryCode": "US"
            },
            {
                "name": "Huntsville",
                "countryCode": "US"
            },
            {
                "name": "Independence County",
                "countryCode": "US"
            },
            {
                "name": "Izard County",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Johnson",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Jonesboro",
                "countryCode": "US"
            },
            {
                "name": "Judsonia",
                "countryCode": "US"
            },
            {
                "name": "Kensett",
                "countryCode": "US"
            },
            {
                "name": "Lafayette County",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Lake Village",
                "countryCode": "US"
            },
            {
                "name": "Lamar",
                "countryCode": "US"
            },
            {
                "name": "Landmark",
                "countryCode": "US"
            },
            {
                "name": "Lavaca",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Leachville",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Lepanto",
                "countryCode": "US"
            },
            {
                "name": "Lewisville",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Little Flock",
                "countryCode": "US"
            },
            {
                "name": "Little River County",
                "countryCode": "US"
            },
            {
                "name": "Little Rock",
                "countryCode": "US"
            },
            {
                "name": "Little Rock Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "London",
                "countryCode": "US"
            },
            {
                "name": "Lonoke",
                "countryCode": "US"
            },
            {
                "name": "Lonoke County",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Luxora",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Magnolia",
                "countryCode": "US"
            },
            {
                "name": "Malvern",
                "countryCode": "US"
            },
            {
                "name": "Manila",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Marianna",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marked Tree",
                "countryCode": "US"
            },
            {
                "name": "Marmaduke",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marvell",
                "countryCode": "US"
            },
            {
                "name": "Maumelle",
                "countryCode": "US"
            },
            {
                "name": "Mayflower",
                "countryCode": "US"
            },
            {
                "name": "McAlmont",
                "countryCode": "US"
            },
            {
                "name": "McCrory",
                "countryCode": "US"
            },
            {
                "name": "McGehee",
                "countryCode": "US"
            },
            {
                "name": "Melbourne",
                "countryCode": "US"
            },
            {
                "name": "Mena",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Miller County",
                "countryCode": "US"
            },
            {
                "name": "Mineral Springs",
                "countryCode": "US"
            },
            {
                "name": "Mississippi County",
                "countryCode": "US"
            },
            {
                "name": "Monette",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morrilton",
                "countryCode": "US"
            },
            {
                "name": "Mount Ida",
                "countryCode": "US"
            },
            {
                "name": "Mountain Home",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Mulberry",
                "countryCode": "US"
            },
            {
                "name": "Murfreesboro",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Nevada County",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "North Crossett",
                "countryCode": "US"
            },
            {
                "name": "North Little Rock",
                "countryCode": "US"
            },
            {
                "name": "Ola",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Ouachita County",
                "countryCode": "US"
            },
            {
                "name": "Ozark",
                "countryCode": "US"
            },
            {
                "name": "Paragould",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Parkin",
                "countryCode": "US"
            },
            {
                "name": "Pea Ridge",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Perryville",
                "countryCode": "US"
            },
            {
                "name": "Phillips County",
                "countryCode": "US"
            },
            {
                "name": "Piggott",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pine Bluff",
                "countryCode": "US"
            },
            {
                "name": "Piney",
                "countryCode": "US"
            },
            {
                "name": "Pocahontas",
                "countryCode": "US"
            },
            {
                "name": "Poinsett County",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Pope County",
                "countryCode": "US"
            },
            {
                "name": "Pottsville",
                "countryCode": "US"
            },
            {
                "name": "Prairie County",
                "countryCode": "US"
            },
            {
                "name": "Prairie Creek",
                "countryCode": "US"
            },
            {
                "name": "Prairie Grove",
                "countryCode": "US"
            },
            {
                "name": "Prescott",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Rector",
                "countryCode": "US"
            },
            {
                "name": "Redfield",
                "countryCode": "US"
            },
            {
                "name": "Rison",
                "countryCode": "US"
            },
            {
                "name": "Rockwell",
                "countryCode": "US"
            },
            {
                "name": "Rogers",
                "countryCode": "US"
            },
            {
                "name": "Russellville",
                "countryCode": "US"
            },
            {
                "name": "Saint Francis County",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Saline County",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Searcy",
                "countryCode": "US"
            },
            {
                "name": "Searcy County",
                "countryCode": "US"
            },
            {
                "name": "Sebastian County",
                "countryCode": "US"
            },
            {
                "name": "Sevier County",
                "countryCode": "US"
            },
            {
                "name": "Shannon Hills",
                "countryCode": "US"
            },
            {
                "name": "Sharp County",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sherwood",
                "countryCode": "US"
            },
            {
                "name": "Siloam Springs",
                "countryCode": "US"
            },
            {
                "name": "Smackover",
                "countryCode": "US"
            },
            {
                "name": "Southside",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Stamps",
                "countryCode": "US"
            },
            {
                "name": "Star City",
                "countryCode": "US"
            },
            {
                "name": "Stone County",
                "countryCode": "US"
            },
            {
                "name": "Stuttgart",
                "countryCode": "US"
            },
            {
                "name": "Sulphur Springs",
                "countryCode": "US"
            },
            {
                "name": "Texarkana",
                "countryCode": "US"
            },
            {
                "name": "Tontitown",
                "countryCode": "US"
            },
            {
                "name": "Trumann",
                "countryCode": "US"
            },
            {
                "name": "Tuckerman",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Van Buren",
                "countryCode": "US"
            },
            {
                "name": "Van Buren County",
                "countryCode": "US"
            },
            {
                "name": "Vilonia",
                "countryCode": "US"
            },
            {
                "name": "Waldo",
                "countryCode": "US"
            },
            {
                "name": "Waldron",
                "countryCode": "US"
            },
            {
                "name": "Walnut Ridge",
                "countryCode": "US"
            },
            {
                "name": "Ward",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "West Crossett",
                "countryCode": "US"
            },
            {
                "name": "West Fork",
                "countryCode": "US"
            },
            {
                "name": "West Helena",
                "countryCode": "US"
            },
            {
                "name": "West Memphis",
                "countryCode": "US"
            },
            {
                "name": "White County",
                "countryCode": "US"
            },
            {
                "name": "White Hall",
                "countryCode": "US"
            },
            {
                "name": "Woodruff County",
                "countryCode": "US"
            },
            {
                "name": "Wrightsville",
                "countryCode": "US"
            },
            {
                "name": "Wynne",
                "countryCode": "US"
            },
            {
                "name": "Yell County",
                "countryCode": "US"
            },
            {
                "name": "Yellville",
                "countryCode": "US"
            },
            {
                "name": "Ahwatukee Foothills",
                "countryCode": "US"
            },
            {
                "name": "Ajo",
                "countryCode": "US"
            },
            {
                "name": "Alhambra",
                "countryCode": "US"
            },
            {
                "name": "Anthem",
                "countryCode": "US"
            },
            {
                "name": "Apache County",
                "countryCode": "US"
            },
            {
                "name": "Apache Junction",
                "countryCode": "US"
            },
            {
                "name": "Arivaca Junction",
                "countryCode": "US"
            },
            {
                "name": "Arizona City",
                "countryCode": "US"
            },
            {
                "name": "Avenue B and C",
                "countryCode": "US"
            },
            {
                "name": "Avondale",
                "countryCode": "US"
            },
            {
                "name": "Avra Valley",
                "countryCode": "US"
            },
            {
                "name": "Bagdad",
                "countryCode": "US"
            },
            {
                "name": "Beaver Dam",
                "countryCode": "US"
            },
            {
                "name": "Benson",
                "countryCode": "US"
            },
            {
                "name": "Big Park",
                "countryCode": "US"
            },
            {
                "name": "Bisbee",
                "countryCode": "US"
            },
            {
                "name": "Black Canyon City",
                "countryCode": "US"
            },
            {
                "name": "Blackwater",
                "countryCode": "US"
            },
            {
                "name": "Buckeye",
                "countryCode": "US"
            },
            {
                "name": "Bullhead City",
                "countryCode": "US"
            },
            {
                "name": "Bylas",
                "countryCode": "US"
            },
            {
                "name": "Cactus Flat",
                "countryCode": "US"
            },
            {
                "name": "Camp Verde",
                "countryCode": "US"
            },
            {
                "name": "Canyon Day",
                "countryCode": "US"
            },
            {
                "name": "Carefree",
                "countryCode": "US"
            },
            {
                "name": "Casa Blanca",
                "countryCode": "US"
            },
            {
                "name": "Casa Grande",
                "countryCode": "US"
            },
            {
                "name": "Casas Adobes",
                "countryCode": "US"
            },
            {
                "name": "Catalina",
                "countryCode": "US"
            },
            {
                "name": "Catalina Foothills",
                "countryCode": "US"
            },
            {
                "name": "Cave Creek",
                "countryCode": "US"
            },
            {
                "name": "Centennial Park",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Central Heights-Midland City",
                "countryCode": "US"
            },
            {
                "name": "Chandler",
                "countryCode": "US"
            },
            {
                "name": "Chinle",
                "countryCode": "US"
            },
            {
                "name": "Chino Valley",
                "countryCode": "US"
            },
            {
                "name": "Cibecue",
                "countryCode": "US"
            },
            {
                "name": "Cienega Springs",
                "countryCode": "US"
            },
            {
                "name": "Citrus Park",
                "countryCode": "US"
            },
            {
                "name": "Clarkdale",
                "countryCode": "US"
            },
            {
                "name": "Claypool",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Cochise County",
                "countryCode": "US"
            },
            {
                "name": "Coconino County",
                "countryCode": "US"
            },
            {
                "name": "Colorado City",
                "countryCode": "US"
            },
            {
                "name": "Congress",
                "countryCode": "US"
            },
            {
                "name": "Coolidge",
                "countryCode": "US"
            },
            {
                "name": "Cordes Lakes",
                "countryCode": "US"
            },
            {
                "name": "Cornville",
                "countryCode": "US"
            },
            {
                "name": "Corona de Tucson",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood",
                "countryCode": "US"
            },
            {
                "name": "Deer Valley",
                "countryCode": "US"
            },
            {
                "name": "Desert Hills",
                "countryCode": "US"
            },
            {
                "name": "Dewey-Humboldt",
                "countryCode": "US"
            },
            {
                "name": "Dilkon",
                "countryCode": "US"
            },
            {
                "name": "Dolan Springs",
                "countryCode": "US"
            },
            {
                "name": "Donovan Estates",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Drexel Heights",
                "countryCode": "US"
            },
            {
                "name": "Eagar",
                "countryCode": "US"
            },
            {
                "name": "East Sahuarita",
                "countryCode": "US"
            },
            {
                "name": "Ehrenberg",
                "countryCode": "US"
            },
            {
                "name": "El Mirage",
                "countryCode": "US"
            },
            {
                "name": "Eloy",
                "countryCode": "US"
            },
            {
                "name": "Encanto",
                "countryCode": "US"
            },
            {
                "name": "First Mesa",
                "countryCode": "US"
            },
            {
                "name": "Flagstaff",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Flowing Wells",
                "countryCode": "US"
            },
            {
                "name": "Fort Defiance",
                "countryCode": "US"
            },
            {
                "name": "Fortuna Foothills",
                "countryCode": "US"
            },
            {
                "name": "Fountain Hills",
                "countryCode": "US"
            },
            {
                "name": "Fredonia",
                "countryCode": "US"
            },
            {
                "name": "Ganado",
                "countryCode": "US"
            },
            {
                "name": "Gila Bend",
                "countryCode": "US"
            },
            {
                "name": "Gila County",
                "countryCode": "US"
            },
            {
                "name": "Gilbert",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Globe",
                "countryCode": "US"
            },
            {
                "name": "Gold Camp",
                "countryCode": "US"
            },
            {
                "name": "Gold Canyon",
                "countryCode": "US"
            },
            {
                "name": "Golden Shores",
                "countryCode": "US"
            },
            {
                "name": "Golden Valley",
                "countryCode": "US"
            },
            {
                "name": "Goodyear",
                "countryCode": "US"
            },
            {
                "name": "Graham County",
                "countryCode": "US"
            },
            {
                "name": "Grand Canyon",
                "countryCode": "US"
            },
            {
                "name": "Grand Canyon Village",
                "countryCode": "US"
            },
            {
                "name": "Green Valley",
                "countryCode": "US"
            },
            {
                "name": "Greenlee County",
                "countryCode": "US"
            },
            {
                "name": "Guadalupe",
                "countryCode": "US"
            },
            {
                "name": "Heber-Overgaard",
                "countryCode": "US"
            },
            {
                "name": "Holbrook",
                "countryCode": "US"
            },
            {
                "name": "Houck",
                "countryCode": "US"
            },
            {
                "name": "Huachuca City",
                "countryCode": "US"
            },
            {
                "name": "Joseph City",
                "countryCode": "US"
            },
            {
                "name": "Kachina Village",
                "countryCode": "US"
            },
            {
                "name": "Kaibito",
                "countryCode": "US"
            },
            {
                "name": "Kayenta",
                "countryCode": "US"
            },
            {
                "name": "Kearny",
                "countryCode": "US"
            },
            {
                "name": "Kingman",
                "countryCode": "US"
            },
            {
                "name": "La Paz County",
                "countryCode": "US"
            },
            {
                "name": "Lake Havasu City",
                "countryCode": "US"
            },
            {
                "name": "Lake Montezuma",
                "countryCode": "US"
            },
            {
                "name": "Lake of the Woods",
                "countryCode": "US"
            },
            {
                "name": "Laveen",
                "countryCode": "US"
            },
            {
                "name": "LeChee",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Litchfield Park",
                "countryCode": "US"
            },
            {
                "name": "Lukachukai",
                "countryCode": "US"
            },
            {
                "name": "Mammoth",
                "countryCode": "US"
            },
            {
                "name": "Many Farms",
                "countryCode": "US"
            },
            {
                "name": "Marana",
                "countryCode": "US"
            },
            {
                "name": "Maricopa",
                "countryCode": "US"
            },
            {
                "name": "Maricopa County",
                "countryCode": "US"
            },
            {
                "name": "Maryvale",
                "countryCode": "US"
            },
            {
                "name": "Mayer",
                "countryCode": "US"
            },
            {
                "name": "Meadview",
                "countryCode": "US"
            },
            {
                "name": "Mesa",
                "countryCode": "US"
            },
            {
                "name": "Mescal",
                "countryCode": "US"
            },
            {
                "name": "Miami",
                "countryCode": "US"
            },
            {
                "name": "Mohave County",
                "countryCode": "US"
            },
            {
                "name": "Mohave Valley",
                "countryCode": "US"
            },
            {
                "name": "Morenci",
                "countryCode": "US"
            },
            {
                "name": "Mountainaire",
                "countryCode": "US"
            },
            {
                "name": "Naco",
                "countryCode": "US"
            },
            {
                "name": "Navajo County",
                "countryCode": "US"
            },
            {
                "name": "New Kingman-Butler",
                "countryCode": "US"
            },
            {
                "name": "New River",
                "countryCode": "US"
            },
            {
                "name": "Nogales",
                "countryCode": "US"
            },
            {
                "name": "North Fork",
                "countryCode": "US"
            },
            {
                "name": "Oracle",
                "countryCode": "US"
            },
            {
                "name": "Oro Valley",
                "countryCode": "US"
            },
            {
                "name": "Page",
                "countryCode": "US"
            },
            {
                "name": "Paradise Valley",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Parks",
                "countryCode": "US"
            },
            {
                "name": "Paulden",
                "countryCode": "US"
            },
            {
                "name": "Payson",
                "countryCode": "US"
            },
            {
                "name": "Peach Springs",
                "countryCode": "US"
            },
            {
                "name": "Peoria",
                "countryCode": "US"
            },
            {
                "name": "Peridot",
                "countryCode": "US"
            },
            {
                "name": "Phoenix",
                "countryCode": "US"
            },
            {
                "name": "Picture Rocks",
                "countryCode": "US"
            },
            {
                "name": "Pima",
                "countryCode": "US"
            },
            {
                "name": "Pima County",
                "countryCode": "US"
            },
            {
                "name": "Pinal County",
                "countryCode": "US"
            },
            {
                "name": "Pine",
                "countryCode": "US"
            },
            {
                "name": "Pinetop-Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Pirtleville",
                "countryCode": "US"
            },
            {
                "name": "Prescott",
                "countryCode": "US"
            },
            {
                "name": "Prescott Valley",
                "countryCode": "US"
            },
            {
                "name": "Quartzsite",
                "countryCode": "US"
            },
            {
                "name": "Queen Creek",
                "countryCode": "US"
            },
            {
                "name": "Rio Rico",
                "countryCode": "US"
            },
            {
                "name": "Rio Verde",
                "countryCode": "US"
            },
            {
                "name": "Sacaton",
                "countryCode": "US"
            },
            {
                "name": "Saddle Brooke",
                "countryCode": "US"
            },
            {
                "name": "Safford",
                "countryCode": "US"
            },
            {
                "name": "Sahuarita",
                "countryCode": "US"
            },
            {
                "name": "Saint David",
                "countryCode": "US"
            },
            {
                "name": "Saint Johns",
                "countryCode": "US"
            },
            {
                "name": "Saint Michaels",
                "countryCode": "US"
            },
            {
                "name": "Salome",
                "countryCode": "US"
            },
            {
                "name": "San Carlos",
                "countryCode": "US"
            },
            {
                "name": "San Luis",
                "countryCode": "US"
            },
            {
                "name": "San Manuel",
                "countryCode": "US"
            },
            {
                "name": "San Tan Valley",
                "countryCode": "US"
            },
            {
                "name": "Santa Cruz County",
                "countryCode": "US"
            },
            {
                "name": "Scenic",
                "countryCode": "US"
            },
            {
                "name": "Scottsdale",
                "countryCode": "US"
            },
            {
                "name": "Sedona",
                "countryCode": "US"
            },
            {
                "name": "Sells",
                "countryCode": "US"
            },
            {
                "name": "Show Low",
                "countryCode": "US"
            },
            {
                "name": "Sierra Vista",
                "countryCode": "US"
            },
            {
                "name": "Sierra Vista Southeast",
                "countryCode": "US"
            },
            {
                "name": "Six Shooter Canyon",
                "countryCode": "US"
            },
            {
                "name": "Snowflake",
                "countryCode": "US"
            },
            {
                "name": "Somerton",
                "countryCode": "US"
            },
            {
                "name": "South Tucson",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Springerville",
                "countryCode": "US"
            },
            {
                "name": "Star Valley",
                "countryCode": "US"
            },
            {
                "name": "Summit",
                "countryCode": "US"
            },
            {
                "name": "Sun City",
                "countryCode": "US"
            },
            {
                "name": "Sun City West",
                "countryCode": "US"
            },
            {
                "name": "Sun Lakes",
                "countryCode": "US"
            },
            {
                "name": "Sun Valley",
                "countryCode": "US"
            },
            {
                "name": "Superior",
                "countryCode": "US"
            },
            {
                "name": "Surprise",
                "countryCode": "US"
            },
            {
                "name": "Swift Trail Junction",
                "countryCode": "US"
            },
            {
                "name": "Tanque Verde",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Tempe",
                "countryCode": "US"
            },
            {
                "name": "Tempe Junction",
                "countryCode": "US"
            },
            {
                "name": "Thatcher",
                "countryCode": "US"
            },
            {
                "name": "Three Points",
                "countryCode": "US"
            },
            {
                "name": "Tolleson",
                "countryCode": "US"
            },
            {
                "name": "Tombstone",
                "countryCode": "US"
            },
            {
                "name": "Tonto Basin",
                "countryCode": "US"
            },
            {
                "name": "Tortolita",
                "countryCode": "US"
            },
            {
                "name": "Tsaile",
                "countryCode": "US"
            },
            {
                "name": "Tuba City",
                "countryCode": "US"
            },
            {
                "name": "Tubac",
                "countryCode": "US"
            },
            {
                "name": "Tucson",
                "countryCode": "US"
            },
            {
                "name": "Tucson Estates",
                "countryCode": "US"
            },
            {
                "name": "Vail",
                "countryCode": "US"
            },
            {
                "name": "Valencia West",
                "countryCode": "US"
            },
            {
                "name": "Valle Vista",
                "countryCode": "US"
            },
            {
                "name": "Verde Village",
                "countryCode": "US"
            },
            {
                "name": "Village of Oak Creek (Big Park)",
                "countryCode": "US"
            },
            {
                "name": "Wellton",
                "countryCode": "US"
            },
            {
                "name": "West Sedona",
                "countryCode": "US"
            },
            {
                "name": "Whetstone",
                "countryCode": "US"
            },
            {
                "name": "White Mountain Lake",
                "countryCode": "US"
            },
            {
                "name": "Whiteriver",
                "countryCode": "US"
            },
            {
                "name": "Wickenburg",
                "countryCode": "US"
            },
            {
                "name": "Willcox",
                "countryCode": "US"
            },
            {
                "name": "Williams",
                "countryCode": "US"
            },
            {
                "name": "Williamson",
                "countryCode": "US"
            },
            {
                "name": "Willow Valley",
                "countryCode": "US"
            },
            {
                "name": "Window Rock",
                "countryCode": "US"
            },
            {
                "name": "Winslow",
                "countryCode": "US"
            },
            {
                "name": "Yavapai County",
                "countryCode": "US"
            },
            {
                "name": "Youngtown",
                "countryCode": "US"
            },
            {
                "name": "Yuma",
                "countryCode": "US"
            },
            {
                "name": "Yuma County",
                "countryCode": "US"
            },
            {
                "name": "Acalanes Ridge",
                "countryCode": "US"
            },
            {
                "name": "Acton",
                "countryCode": "US"
            },
            {
                "name": "Adelanto",
                "countryCode": "US"
            },
            {
                "name": "Agoura",
                "countryCode": "US"
            },
            {
                "name": "Agoura Hills",
                "countryCode": "US"
            },
            {
                "name": "Agua Dulce",
                "countryCode": "US"
            },
            {
                "name": "Aguanga",
                "countryCode": "US"
            },
            {
                "name": "Ahwahnee",
                "countryCode": "US"
            },
            {
                "name": "Alameda",
                "countryCode": "US"
            },
            {
                "name": "Alameda County",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Alhambra",
                "countryCode": "US"
            },
            {
                "name": "Aliso Viejo",
                "countryCode": "US"
            },
            {
                "name": "Allendale",
                "countryCode": "US"
            },
            {
                "name": "Alondra Park",
                "countryCode": "US"
            },
            {
                "name": "Alpaugh",
                "countryCode": "US"
            },
            {
                "name": "Alpine",
                "countryCode": "US"
            },
            {
                "name": "Alpine County",
                "countryCode": "US"
            },
            {
                "name": "Alta Sierra",
                "countryCode": "US"
            },
            {
                "name": "Altadena",
                "countryCode": "US"
            },
            {
                "name": "Alturas",
                "countryCode": "US"
            },
            {
                "name": "Alum Rock",
                "countryCode": "US"
            },
            {
                "name": "Amador County",
                "countryCode": "US"
            },
            {
                "name": "American Canyon",
                "countryCode": "US"
            },
            {
                "name": "Amesti",
                "countryCode": "US"
            },
            {
                "name": "Anaheim",
                "countryCode": "US"
            },
            {
                "name": "Anderson",
                "countryCode": "US"
            },
            {
                "name": "Angels Camp",
                "countryCode": "US"
            },
            {
                "name": "Angwin",
                "countryCode": "US"
            },
            {
                "name": "Antelope",
                "countryCode": "US"
            },
            {
                "name": "Antioch",
                "countryCode": "US"
            },
            {
                "name": "Anza",
                "countryCode": "US"
            },
            {
                "name": "Apple Valley",
                "countryCode": "US"
            },
            {
                "name": "Aptos",
                "countryCode": "US"
            },
            {
                "name": "Aptos Hills-Larkin Valley",
                "countryCode": "US"
            },
            {
                "name": "Arbuckle",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Arcata",
                "countryCode": "US"
            },
            {
                "name": "Arden-Arcade",
                "countryCode": "US"
            },
            {
                "name": "Armona",
                "countryCode": "US"
            },
            {
                "name": "Arnold",
                "countryCode": "US"
            },
            {
                "name": "Aromas",
                "countryCode": "US"
            },
            {
                "name": "Arroyo Grande",
                "countryCode": "US"
            },
            {
                "name": "Artesia",
                "countryCode": "US"
            },
            {
                "name": "Arvin",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atascadero",
                "countryCode": "US"
            },
            {
                "name": "Atherton",
                "countryCode": "US"
            },
            {
                "name": "Atwater",
                "countryCode": "US"
            },
            {
                "name": "Auberry",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Auburn Lake Trails",
                "countryCode": "US"
            },
            {
                "name": "August",
                "countryCode": "US"
            },
            {
                "name": "Avalon",
                "countryCode": "US"
            },
            {
                "name": "Avenal",
                "countryCode": "US"
            },
            {
                "name": "Avila Beach",
                "countryCode": "US"
            },
            {
                "name": "Avocado Heights",
                "countryCode": "US"
            },
            {
                "name": "Azusa",
                "countryCode": "US"
            },
            {
                "name": "Bakersfield",
                "countryCode": "US"
            },
            {
                "name": "Baldwin Park",
                "countryCode": "US"
            },
            {
                "name": "Banning",
                "countryCode": "US"
            },
            {
                "name": "Barstow",
                "countryCode": "US"
            },
            {
                "name": "Barstow Heights",
                "countryCode": "US"
            },
            {
                "name": "Bay Point",
                "countryCode": "US"
            },
            {
                "name": "Bayside",
                "countryCode": "US"
            },
            {
                "name": "Bayview",
                "countryCode": "US"
            },
            {
                "name": "Beale Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Bear Valley Springs",
                "countryCode": "US"
            },
            {
                "name": "Beaumont",
                "countryCode": "US"
            },
            {
                "name": "Bell",
                "countryCode": "US"
            },
            {
                "name": "Bell Gardens",
                "countryCode": "US"
            },
            {
                "name": "Bella Vista",
                "countryCode": "US"
            },
            {
                "name": "Bellflower",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Belvedere",
                "countryCode": "US"
            },
            {
                "name": "Ben Lomond",
                "countryCode": "US"
            },
            {
                "name": "Benicia",
                "countryCode": "US"
            },
            {
                "name": "Berkeley",
                "countryCode": "US"
            },
            {
                "name": "Bermuda Dunes",
                "countryCode": "US"
            },
            {
                "name": "Berry Creek",
                "countryCode": "US"
            },
            {
                "name": "Bertsch-Oceanview",
                "countryCode": "US"
            },
            {
                "name": "Bethel Island",
                "countryCode": "US"
            },
            {
                "name": "Beverly Hills",
                "countryCode": "US"
            },
            {
                "name": "Big Bear City",
                "countryCode": "US"
            },
            {
                "name": "Big Bear Lake",
                "countryCode": "US"
            },
            {
                "name": "Big Pine",
                "countryCode": "US"
            },
            {
                "name": "Big River",
                "countryCode": "US"
            },
            {
                "name": "Biggs",
                "countryCode": "US"
            },
            {
                "name": "Biola",
                "countryCode": "US"
            },
            {
                "name": "Bishop",
                "countryCode": "US"
            },
            {
                "name": "Black Point-Green Point",
                "countryCode": "US"
            },
            {
                "name": "Blackhawk",
                "countryCode": "US"
            },
            {
                "name": "Bloomington",
                "countryCode": "US"
            },
            {
                "name": "Blue Lake",
                "countryCode": "US"
            },
            {
                "name": "Blythe",
                "countryCode": "US"
            },
            {
                "name": "Bodega Bay",
                "countryCode": "US"
            },
            {
                "name": "Bodfish",
                "countryCode": "US"
            },
            {
                "name": "Bolinas",
                "countryCode": "US"
            },
            {
                "name": "Bonadelle Ranchos-Madera Ranchos",
                "countryCode": "US"
            },
            {
                "name": "Bonita",
                "countryCode": "US"
            },
            {
                "name": "Bonny Doon",
                "countryCode": "US"
            },
            {
                "name": "Bonsall",
                "countryCode": "US"
            },
            {
                "name": "Boonville",
                "countryCode": "US"
            },
            {
                "name": "Boron",
                "countryCode": "US"
            },
            {
                "name": "Boronda",
                "countryCode": "US"
            },
            {
                "name": "Borrego Springs",
                "countryCode": "US"
            },
            {
                "name": "Bostonia",
                "countryCode": "US"
            },
            {
                "name": "Boulder Creek",
                "countryCode": "US"
            },
            {
                "name": "Boyes Hot Springs",
                "countryCode": "US"
            },
            {
                "name": "Boyle Heights",
                "countryCode": "US"
            },
            {
                "name": "Bradbury",
                "countryCode": "US"
            },
            {
                "name": "Brawley",
                "countryCode": "US"
            },
            {
                "name": "Brea",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Bret Harte",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Brisbane",
                "countryCode": "US"
            },
            {
                "name": "Broadmoor",
                "countryCode": "US"
            },
            {
                "name": "Brookdale",
                "countryCode": "US"
            },
            {
                "name": "Brooktrails",
                "countryCode": "US"
            },
            {
                "name": "Buckhorn",
                "countryCode": "US"
            },
            {
                "name": "Buellton",
                "countryCode": "US"
            },
            {
                "name": "Buena Park",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Burbank",
                "countryCode": "US"
            },
            {
                "name": "Burlingame",
                "countryCode": "US"
            },
            {
                "name": "Burney",
                "countryCode": "US"
            },
            {
                "name": "Butte County",
                "countryCode": "US"
            },
            {
                "name": "Buttonwillow",
                "countryCode": "US"
            },
            {
                "name": "Byron",
                "countryCode": "US"
            },
            {
                "name": "Bystrom",
                "countryCode": "US"
            },
            {
                "name": "Cabazon",
                "countryCode": "US"
            },
            {
                "name": "Calabasas",
                "countryCode": "US"
            },
            {
                "name": "Calaveras County",
                "countryCode": "US"
            },
            {
                "name": "Calexico",
                "countryCode": "US"
            },
            {
                "name": "California City",
                "countryCode": "US"
            },
            {
                "name": "Calimesa",
                "countryCode": "US"
            },
            {
                "name": "Calipatria",
                "countryCode": "US"
            },
            {
                "name": "Calistoga",
                "countryCode": "US"
            },
            {
                "name": "Callender",
                "countryCode": "US"
            },
            {
                "name": "Camarillo",
                "countryCode": "US"
            },
            {
                "name": "Cambria",
                "countryCode": "US"
            },
            {
                "name": "Cambrian Park",
                "countryCode": "US"
            },
            {
                "name": "Cameron Park",
                "countryCode": "US"
            },
            {
                "name": "Camino",
                "countryCode": "US"
            },
            {
                "name": "Camp Meeker",
                "countryCode": "US"
            },
            {
                "name": "Camp Pendleton North",
                "countryCode": "US"
            },
            {
                "name": "Camp Pendleton South",
                "countryCode": "US"
            },
            {
                "name": "Campbell",
                "countryCode": "US"
            },
            {
                "name": "Campo",
                "countryCode": "US"
            },
            {
                "name": "Canoga Park",
                "countryCode": "US"
            },
            {
                "name": "Canyon Lake",
                "countryCode": "US"
            },
            {
                "name": "Capitola",
                "countryCode": "US"
            },
            {
                "name": "Carlsbad",
                "countryCode": "US"
            },
            {
                "name": "Carmel Valley Village",
                "countryCode": "US"
            },
            {
                "name": "Carmel-by-the-Sea",
                "countryCode": "US"
            },
            {
                "name": "Carmichael",
                "countryCode": "US"
            },
            {
                "name": "Carpinteria",
                "countryCode": "US"
            },
            {
                "name": "Carson",
                "countryCode": "US"
            },
            {
                "name": "Caruthers",
                "countryCode": "US"
            },
            {
                "name": "Casa Conejo",
                "countryCode": "US"
            },
            {
                "name": "Casa de Oro-Mount Helix",
                "countryCode": "US"
            },
            {
                "name": "Castaic",
                "countryCode": "US"
            },
            {
                "name": "Castro Valley",
                "countryCode": "US"
            },
            {
                "name": "Castroville",
                "countryCode": "US"
            },
            {
                "name": "Cathedral City",
                "countryCode": "US"
            },
            {
                "name": "Cayucos",
                "countryCode": "US"
            },
            {
                "name": "Cedar Ridge",
                "countryCode": "US"
            },
            {
                "name": "Central Valley (historical)",
                "countryCode": "US"
            },
            {
                "name": "Century City",
                "countryCode": "US"
            },
            {
                "name": "Ceres",
                "countryCode": "US"
            },
            {
                "name": "Cerritos",
                "countryCode": "US"
            },
            {
                "name": "Challenge-Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Channel Islands Beach",
                "countryCode": "US"
            },
            {
                "name": "Charter Oak",
                "countryCode": "US"
            },
            {
                "name": "Chatsworth",
                "countryCode": "US"
            },
            {
                "name": "Cherry Valley",
                "countryCode": "US"
            },
            {
                "name": "Cherryland",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chico",
                "countryCode": "US"
            },
            {
                "name": "China Lake Acres",
                "countryCode": "US"
            },
            {
                "name": "Chinatown",
                "countryCode": "US"
            },
            {
                "name": "Chino",
                "countryCode": "US"
            },
            {
                "name": "Chino Hills",
                "countryCode": "US"
            },
            {
                "name": "Chowchilla",
                "countryCode": "US"
            },
            {
                "name": "Chualar",
                "countryCode": "US"
            },
            {
                "name": "Chula Vista",
                "countryCode": "US"
            },
            {
                "name": "Citrus",
                "countryCode": "US"
            },
            {
                "name": "Citrus Heights",
                "countryCode": "US"
            },
            {
                "name": "City and County of San Francisco",
                "countryCode": "US"
            },
            {
                "name": "Claremont",
                "countryCode": "US"
            },
            {
                "name": "Clay",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake Riviera",
                "countryCode": "US"
            },
            {
                "name": "Clearlake",
                "countryCode": "US"
            },
            {
                "name": "Clearlake Oaks",
                "countryCode": "US"
            },
            {
                "name": "Cloverdale",
                "countryCode": "US"
            },
            {
                "name": "Clovis",
                "countryCode": "US"
            },
            {
                "name": "Coachella",
                "countryCode": "US"
            },
            {
                "name": "Coalinga",
                "countryCode": "US"
            },
            {
                "name": "Coarsegold",
                "countryCode": "US"
            },
            {
                "name": "Cobb",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "Collierville",
                "countryCode": "US"
            },
            {
                "name": "Colma",
                "countryCode": "US"
            },
            {
                "name": "Colton",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Colusa",
                "countryCode": "US"
            },
            {
                "name": "Colusa County",
                "countryCode": "US"
            },
            {
                "name": "Commerce",
                "countryCode": "US"
            },
            {
                "name": "Compton",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Contra Costa Centre",
                "countryCode": "US"
            },
            {
                "name": "Contra Costa County",
                "countryCode": "US"
            },
            {
                "name": "Cool",
                "countryCode": "US"
            },
            {
                "name": "Copperopolis",
                "countryCode": "US"
            },
            {
                "name": "Corcoran",
                "countryCode": "US"
            },
            {
                "name": "Corning",
                "countryCode": "US"
            },
            {
                "name": "Corona",
                "countryCode": "US"
            },
            {
                "name": "Coronado",
                "countryCode": "US"
            },
            {
                "name": "Corralitos",
                "countryCode": "US"
            },
            {
                "name": "Corte Madera",
                "countryCode": "US"
            },
            {
                "name": "Costa Mesa",
                "countryCode": "US"
            },
            {
                "name": "Cotati",
                "countryCode": "US"
            },
            {
                "name": "Coto De Caza",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood",
                "countryCode": "US"
            },
            {
                "name": "Country Club",
                "countryCode": "US"
            },
            {
                "name": "Covelo",
                "countryCode": "US"
            },
            {
                "name": "Covina",
                "countryCode": "US"
            },
            {
                "name": "Crescent City",
                "countryCode": "US"
            },
            {
                "name": "Crest",
                "countryCode": "US"
            },
            {
                "name": "Crestline",
                "countryCode": "US"
            },
            {
                "name": "Crockett",
                "countryCode": "US"
            },
            {
                "name": "Cudahy",
                "countryCode": "US"
            },
            {
                "name": "Culver City",
                "countryCode": "US"
            },
            {
                "name": "Cupertino",
                "countryCode": "US"
            },
            {
                "name": "Cutler",
                "countryCode": "US"
            },
            {
                "name": "Cutten",
                "countryCode": "US"
            },
            {
                "name": "Cypress",
                "countryCode": "US"
            },
            {
                "name": "Daly City",
                "countryCode": "US"
            },
            {
                "name": "Dana Point",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Davis",
                "countryCode": "US"
            },
            {
                "name": "Day Valley",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Del Aire",
                "countryCode": "US"
            },
            {
                "name": "Del Mar",
                "countryCode": "US"
            },
            {
                "name": "Del Monte Forest",
                "countryCode": "US"
            },
            {
                "name": "Del Norte County",
                "countryCode": "US"
            },
            {
                "name": "Del Rey",
                "countryCode": "US"
            },
            {
                "name": "Del Rey Oaks",
                "countryCode": "US"
            },
            {
                "name": "Del Rio",
                "countryCode": "US"
            },
            {
                "name": "Delano",
                "countryCode": "US"
            },
            {
                "name": "Delhi",
                "countryCode": "US"
            },
            {
                "name": "Denair",
                "countryCode": "US"
            },
            {
                "name": "Descanso",
                "countryCode": "US"
            },
            {
                "name": "Desert Edge",
                "countryCode": "US"
            },
            {
                "name": "Desert Hot Springs",
                "countryCode": "US"
            },
            {
                "name": "Desert Shores",
                "countryCode": "US"
            },
            {
                "name": "Desert View Highlands",
                "countryCode": "US"
            },
            {
                "name": "Diablo",
                "countryCode": "US"
            },
            {
                "name": "Diamond Bar",
                "countryCode": "US"
            },
            {
                "name": "Diamond Springs",
                "countryCode": "US"
            },
            {
                "name": "Dinuba",
                "countryCode": "US"
            },
            {
                "name": "Discovery Bay",
                "countryCode": "US"
            },
            {
                "name": "Dixon",
                "countryCode": "US"
            },
            {
                "name": "Dixon Lane-Meadow Creek",
                "countryCode": "US"
            },
            {
                "name": "Dogtown",
                "countryCode": "US"
            },
            {
                "name": "Dollar Point",
                "countryCode": "US"
            },
            {
                "name": "Dos Palos",
                "countryCode": "US"
            },
            {
                "name": "Downey",
                "countryCode": "US"
            },
            {
                "name": "Downieville",
                "countryCode": "US"
            },
            {
                "name": "Duarte",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Dunnigan",
                "countryCode": "US"
            },
            {
                "name": "Dunsmuir",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "Earlimart",
                "countryCode": "US"
            },
            {
                "name": "East Foothills",
                "countryCode": "US"
            },
            {
                "name": "East Hemet",
                "countryCode": "US"
            },
            {
                "name": "East La Mirada",
                "countryCode": "US"
            },
            {
                "name": "East Los Angeles",
                "countryCode": "US"
            },
            {
                "name": "East Oakdale",
                "countryCode": "US"
            },
            {
                "name": "East Palo Alto",
                "countryCode": "US"
            },
            {
                "name": "East Pasadena",
                "countryCode": "US"
            },
            {
                "name": "East Porterville",
                "countryCode": "US"
            },
            {
                "name": "East Quincy",
                "countryCode": "US"
            },
            {
                "name": "East Rancho Dominguez",
                "countryCode": "US"
            },
            {
                "name": "East Richmond Heights",
                "countryCode": "US"
            },
            {
                "name": "East San Gabriel",
                "countryCode": "US"
            },
            {
                "name": "East Sonora",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Eastvale",
                "countryCode": "US"
            },
            {
                "name": "Echo Park",
                "countryCode": "US"
            },
            {
                "name": "Edwards Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "El Cajon",
                "countryCode": "US"
            },
            {
                "name": "El Centro",
                "countryCode": "US"
            },
            {
                "name": "El Cerrito",
                "countryCode": "US"
            },
            {
                "name": "El Cerrito Corona",
                "countryCode": "US"
            },
            {
                "name": "El Dorado County",
                "countryCode": "US"
            },
            {
                "name": "El Dorado Hills",
                "countryCode": "US"
            },
            {
                "name": "El Granada",
                "countryCode": "US"
            },
            {
                "name": "El Monte",
                "countryCode": "US"
            },
            {
                "name": "El Rio",
                "countryCode": "US"
            },
            {
                "name": "El Segundo",
                "countryCode": "US"
            },
            {
                "name": "El Sobrante",
                "countryCode": "US"
            },
            {
                "name": "El Verano",
                "countryCode": "US"
            },
            {
                "name": "Eldridge",
                "countryCode": "US"
            },
            {
                "name": "Elk Grove",
                "countryCode": "US"
            },
            {
                "name": "Elkhorn",
                "countryCode": "US"
            },
            {
                "name": "Elverta",
                "countryCode": "US"
            },
            {
                "name": "Emerald Lake Hills",
                "countryCode": "US"
            },
            {
                "name": "Emeryville",
                "countryCode": "US"
            },
            {
                "name": "Empire",
                "countryCode": "US"
            },
            {
                "name": "Encinitas",
                "countryCode": "US"
            },
            {
                "name": "Encino",
                "countryCode": "US"
            },
            {
                "name": "Escalon",
                "countryCode": "US"
            },
            {
                "name": "Escondido",
                "countryCode": "US"
            },
            {
                "name": "Esparto",
                "countryCode": "US"
            },
            {
                "name": "Eucalyptus Hills",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Exeter",
                "countryCode": "US"
            },
            {
                "name": "Fair Oaks",
                "countryCode": "US"
            },
            {
                "name": "Fairbanks Ranch",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairmead",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fallbrook",
                "countryCode": "US"
            },
            {
                "name": "Farmersville",
                "countryCode": "US"
            },
            {
                "name": "Felton",
                "countryCode": "US"
            },
            {
                "name": "Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Fetters Hot Springs-Agua Caliente",
                "countryCode": "US"
            },
            {
                "name": "Fillmore",
                "countryCode": "US"
            },
            {
                "name": "Firebaugh",
                "countryCode": "US"
            },
            {
                "name": "Florence-Graham",
                "countryCode": "US"
            },
            {
                "name": "Florin",
                "countryCode": "US"
            },
            {
                "name": "Folsom",
                "countryCode": "US"
            },
            {
                "name": "Fontana",
                "countryCode": "US"
            },
            {
                "name": "Foothill Farms",
                "countryCode": "US"
            },
            {
                "name": "Foothill Ranch",
                "countryCode": "US"
            },
            {
                "name": "Ford City",
                "countryCode": "US"
            },
            {
                "name": "Forest Meadows",
                "countryCode": "US"
            },
            {
                "name": "Forest Ranch",
                "countryCode": "US"
            },
            {
                "name": "Foresthill",
                "countryCode": "US"
            },
            {
                "name": "Forestville",
                "countryCode": "US"
            },
            {
                "name": "Fort Bragg",
                "countryCode": "US"
            },
            {
                "name": "Fort Irwin",
                "countryCode": "US"
            },
            {
                "name": "Fortuna",
                "countryCode": "US"
            },
            {
                "name": "Foster City",
                "countryCode": "US"
            },
            {
                "name": "Fountain Valley",
                "countryCode": "US"
            },
            {
                "name": "Fowler",
                "countryCode": "US"
            },
            {
                "name": "Frazier Park",
                "countryCode": "US"
            },
            {
                "name": "Freedom",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "French Camp",
                "countryCode": "US"
            },
            {
                "name": "Fresno",
                "countryCode": "US"
            },
            {
                "name": "Fresno County",
                "countryCode": "US"
            },
            {
                "name": "Fruitridge Pocket",
                "countryCode": "US"
            },
            {
                "name": "Fullerton",
                "countryCode": "US"
            },
            {
                "name": "Galt",
                "countryCode": "US"
            },
            {
                "name": "Garden Acres",
                "countryCode": "US"
            },
            {
                "name": "Garden Grove",
                "countryCode": "US"
            },
            {
                "name": "Gardena",
                "countryCode": "US"
            },
            {
                "name": "Garnet",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Gerber",
                "countryCode": "US"
            },
            {
                "name": "Gilroy",
                "countryCode": "US"
            },
            {
                "name": "Glen Avon",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Glendora",
                "countryCode": "US"
            },
            {
                "name": "Glenn County",
                "countryCode": "US"
            },
            {
                "name": "Gold River",
                "countryCode": "US"
            },
            {
                "name": "Golden Hills",
                "countryCode": "US"
            },
            {
                "name": "Goleta",
                "countryCode": "US"
            },
            {
                "name": "Gonzales",
                "countryCode": "US"
            },
            {
                "name": "Good Hope",
                "countryCode": "US"
            },
            {
                "name": "Goshen",
                "countryCode": "US"
            },
            {
                "name": "Grand Terrace",
                "countryCode": "US"
            },
            {
                "name": "Granite Bay",
                "countryCode": "US"
            },
            {
                "name": "Granite Hills",
                "countryCode": "US"
            },
            {
                "name": "Grass Valley",
                "countryCode": "US"
            },
            {
                "name": "Graton",
                "countryCode": "US"
            },
            {
                "name": "Green Acres",
                "countryCode": "US"
            },
            {
                "name": "Green Valley",
                "countryCode": "US"
            },
            {
                "name": "Greenacres",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Gridley",
                "countryCode": "US"
            },
            {
                "name": "Grover Beach",
                "countryCode": "US"
            },
            {
                "name": "Guadalupe",
                "countryCode": "US"
            },
            {
                "name": "Guerneville",
                "countryCode": "US"
            },
            {
                "name": "Gustine",
                "countryCode": "US"
            },
            {
                "name": "Hacienda Heights",
                "countryCode": "US"
            },
            {
                "name": "Half Moon Bay",
                "countryCode": "US"
            },
            {
                "name": "Hamilton City",
                "countryCode": "US"
            },
            {
                "name": "Hanford",
                "countryCode": "US"
            },
            {
                "name": "Happy Camp",
                "countryCode": "US"
            },
            {
                "name": "Harbison Canyon",
                "countryCode": "US"
            },
            {
                "name": "Hartley",
                "countryCode": "US"
            },
            {
                "name": "Hawaiian Gardens",
                "countryCode": "US"
            },
            {
                "name": "Hawthorne",
                "countryCode": "US"
            },
            {
                "name": "Hayfork",
                "countryCode": "US"
            },
            {
                "name": "Hayward",
                "countryCode": "US"
            },
            {
                "name": "Healdsburg",
                "countryCode": "US"
            },
            {
                "name": "Heber",
                "countryCode": "US"
            },
            {
                "name": "Hemet",
                "countryCode": "US"
            },
            {
                "name": "Herald",
                "countryCode": "US"
            },
            {
                "name": "Hercules",
                "countryCode": "US"
            },
            {
                "name": "Hermosa Beach",
                "countryCode": "US"
            },
            {
                "name": "Hesperia",
                "countryCode": "US"
            },
            {
                "name": "Hidden Hills",
                "countryCode": "US"
            },
            {
                "name": "Hidden Meadows",
                "countryCode": "US"
            },
            {
                "name": "Hidden Valley Lake",
                "countryCode": "US"
            },
            {
                "name": "Highgrove",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Highlands-Baywood Park",
                "countryCode": "US"
            },
            {
                "name": "Hillsborough",
                "countryCode": "US"
            },
            {
                "name": "Hilmar-Irwin",
                "countryCode": "US"
            },
            {
                "name": "Hollister",
                "countryCode": "US"
            },
            {
                "name": "Hollywood",
                "countryCode": "US"
            },
            {
                "name": "Holtville",
                "countryCode": "US"
            },
            {
                "name": "Home Garden",
                "countryCode": "US"
            },
            {
                "name": "Home Gardens",
                "countryCode": "US"
            },
            {
                "name": "Homeland",
                "countryCode": "US"
            },
            {
                "name": "Hughson",
                "countryCode": "US"
            },
            {
                "name": "Humboldt County",
                "countryCode": "US"
            },
            {
                "name": "Humboldt Hill",
                "countryCode": "US"
            },
            {
                "name": "Huntington Beach",
                "countryCode": "US"
            },
            {
                "name": "Huntington Park",
                "countryCode": "US"
            },
            {
                "name": "Huron",
                "countryCode": "US"
            },
            {
                "name": "Hydesville",
                "countryCode": "US"
            },
            {
                "name": "Idyllwild",
                "countryCode": "US"
            },
            {
                "name": "Idyllwild-Pine Cove",
                "countryCode": "US"
            },
            {
                "name": "Imperial",
                "countryCode": "US"
            },
            {
                "name": "Imperial Beach",
                "countryCode": "US"
            },
            {
                "name": "Imperial County",
                "countryCode": "US"
            },
            {
                "name": "Indian Wells",
                "countryCode": "US"
            },
            {
                "name": "Indio",
                "countryCode": "US"
            },
            {
                "name": "Inglewood",
                "countryCode": "US"
            },
            {
                "name": "Interlaken",
                "countryCode": "US"
            },
            {
                "name": "Inverness",
                "countryCode": "US"
            },
            {
                "name": "Inyo County",
                "countryCode": "US"
            },
            {
                "name": "Inyokern",
                "countryCode": "US"
            },
            {
                "name": "Ione",
                "countryCode": "US"
            },
            {
                "name": "Irvine",
                "countryCode": "US"
            },
            {
                "name": "Irwindale",
                "countryCode": "US"
            },
            {
                "name": "Isla Vista",
                "countryCode": "US"
            },
            {
                "name": "Ivanhoe",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jamul",
                "countryCode": "US"
            },
            {
                "name": "Janesville",
                "countryCode": "US"
            },
            {
                "name": "Johnstonville",
                "countryCode": "US"
            },
            {
                "name": "Joshua Tree",
                "countryCode": "US"
            },
            {
                "name": "Julian",
                "countryCode": "US"
            },
            {
                "name": "Jurupa Valley",
                "countryCode": "US"
            },
            {
                "name": "Kelseyville",
                "countryCode": "US"
            },
            {
                "name": "Kennedy",
                "countryCode": "US"
            },
            {
                "name": "Kensington",
                "countryCode": "US"
            },
            {
                "name": "Kentfield",
                "countryCode": "US"
            },
            {
                "name": "Kenwood",
                "countryCode": "US"
            },
            {
                "name": "Kerman",
                "countryCode": "US"
            },
            {
                "name": "Kern County",
                "countryCode": "US"
            },
            {
                "name": "Kernville",
                "countryCode": "US"
            },
            {
                "name": "Kettleman City",
                "countryCode": "US"
            },
            {
                "name": "Keyes",
                "countryCode": "US"
            },
            {
                "name": "King City",
                "countryCode": "US"
            },
            {
                "name": "Kings Beach",
                "countryCode": "US"
            },
            {
                "name": "Kings County",
                "countryCode": "US"
            },
            {
                "name": "Kingsburg",
                "countryCode": "US"
            },
            {
                "name": "Knightsen",
                "countryCode": "US"
            },
            {
                "name": "Koreatown",
                "countryCode": "US"
            },
            {
                "name": "La Cañada Flintridge",
                "countryCode": "US"
            },
            {
                "name": "La Crescenta-Montrose",
                "countryCode": "US"
            },
            {
                "name": "La Habra",
                "countryCode": "US"
            },
            {
                "name": "La Habra Heights",
                "countryCode": "US"
            },
            {
                "name": "La Jolla",
                "countryCode": "US"
            },
            {
                "name": "La Mesa",
                "countryCode": "US"
            },
            {
                "name": "La Mirada",
                "countryCode": "US"
            },
            {
                "name": "La Palma",
                "countryCode": "US"
            },
            {
                "name": "La Presa",
                "countryCode": "US"
            },
            {
                "name": "La Puente",
                "countryCode": "US"
            },
            {
                "name": "La Quinta",
                "countryCode": "US"
            },
            {
                "name": "La Riviera",
                "countryCode": "US"
            },
            {
                "name": "La Selva Beach",
                "countryCode": "US"
            },
            {
                "name": "La Verne",
                "countryCode": "US"
            },
            {
                "name": "Ladera",
                "countryCode": "US"
            },
            {
                "name": "Ladera Heights",
                "countryCode": "US"
            },
            {
                "name": "Ladera Ranch",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Laguna",
                "countryCode": "US"
            },
            {
                "name": "Laguna Beach",
                "countryCode": "US"
            },
            {
                "name": "Laguna Hills",
                "countryCode": "US"
            },
            {
                "name": "Laguna Niguel",
                "countryCode": "US"
            },
            {
                "name": "Laguna Woods",
                "countryCode": "US"
            },
            {
                "name": "Lagunitas-Forest Knolls",
                "countryCode": "US"
            },
            {
                "name": "Lake Arrowhead",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Elsinore",
                "countryCode": "US"
            },
            {
                "name": "Lake Forest",
                "countryCode": "US"
            },
            {
                "name": "Lake Isabella",
                "countryCode": "US"
            },
            {
                "name": "Lake Los Angeles",
                "countryCode": "US"
            },
            {
                "name": "Lake Nacimiento",
                "countryCode": "US"
            },
            {
                "name": "Lake San Marcos",
                "countryCode": "US"
            },
            {
                "name": "Lake Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Lake of the Pines",
                "countryCode": "US"
            },
            {
                "name": "Lakeland Village",
                "countryCode": "US"
            },
            {
                "name": "Lakeport",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lamont",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Larkfield-Wikiup",
                "countryCode": "US"
            },
            {
                "name": "Larkspur",
                "countryCode": "US"
            },
            {
                "name": "Las Flores",
                "countryCode": "US"
            },
            {
                "name": "Las Lomas",
                "countryCode": "US"
            },
            {
                "name": "Lassen County",
                "countryCode": "US"
            },
            {
                "name": "Lathrop",
                "countryCode": "US"
            },
            {
                "name": "Laton",
                "countryCode": "US"
            },
            {
                "name": "Lawndale",
                "countryCode": "US"
            },
            {
                "name": "Laytonville",
                "countryCode": "US"
            },
            {
                "name": "Le Grand",
                "countryCode": "US"
            },
            {
                "name": "Lebec",
                "countryCode": "US"
            },
            {
                "name": "Lemon Grove",
                "countryCode": "US"
            },
            {
                "name": "Lemoore",
                "countryCode": "US"
            },
            {
                "name": "Lemoore Station",
                "countryCode": "US"
            },
            {
                "name": "Lennox",
                "countryCode": "US"
            },
            {
                "name": "Lenwood",
                "countryCode": "US"
            },
            {
                "name": "Leona Valley",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Lexington Hills",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Village",
                "countryCode": "US"
            },
            {
                "name": "Linda",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Lindsay",
                "countryCode": "US"
            },
            {
                "name": "Littlerock",
                "countryCode": "US"
            },
            {
                "name": "Live Oak",
                "countryCode": "US"
            },
            {
                "name": "Livermore",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Lockeford",
                "countryCode": "US"
            },
            {
                "name": "Lodi",
                "countryCode": "US"
            },
            {
                "name": "Loma Linda",
                "countryCode": "US"
            },
            {
                "name": "Loma Rica",
                "countryCode": "US"
            },
            {
                "name": "Lomita",
                "countryCode": "US"
            },
            {
                "name": "Lompico",
                "countryCode": "US"
            },
            {
                "name": "Lompoc",
                "countryCode": "US"
            },
            {
                "name": "London",
                "countryCode": "US"
            },
            {
                "name": "Lone Pine",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Loomis",
                "countryCode": "US"
            },
            {
                "name": "Los Alamitos",
                "countryCode": "US"
            },
            {
                "name": "Los Alamos",
                "countryCode": "US"
            },
            {
                "name": "Los Altos",
                "countryCode": "US"
            },
            {
                "name": "Los Altos Hills",
                "countryCode": "US"
            },
            {
                "name": "Los Angeles",
                "countryCode": "US"
            },
            {
                "name": "Los Angeles County",
                "countryCode": "US"
            },
            {
                "name": "Los Banos",
                "countryCode": "US"
            },
            {
                "name": "Los Gatos",
                "countryCode": "US"
            },
            {
                "name": "Los Molinos",
                "countryCode": "US"
            },
            {
                "name": "Los Olivos",
                "countryCode": "US"
            },
            {
                "name": "Los Osos",
                "countryCode": "US"
            },
            {
                "name": "Los Serranos",
                "countryCode": "US"
            },
            {
                "name": "Lost Hills",
                "countryCode": "US"
            },
            {
                "name": "Lower Lake",
                "countryCode": "US"
            },
            {
                "name": "Loyola",
                "countryCode": "US"
            },
            {
                "name": "Lucas Valley-Marinwood",
                "countryCode": "US"
            },
            {
                "name": "Lucerne",
                "countryCode": "US"
            },
            {
                "name": "Lucerne Valley",
                "countryCode": "US"
            },
            {
                "name": "Lynwood",
                "countryCode": "US"
            },
            {
                "name": "Madera",
                "countryCode": "US"
            },
            {
                "name": "Madera Acres",
                "countryCode": "US"
            },
            {
                "name": "Madera County",
                "countryCode": "US"
            },
            {
                "name": "Magalia",
                "countryCode": "US"
            },
            {
                "name": "Malibu",
                "countryCode": "US"
            },
            {
                "name": "Mammoth Lakes",
                "countryCode": "US"
            },
            {
                "name": "Manhattan Beach",
                "countryCode": "US"
            },
            {
                "name": "Manteca",
                "countryCode": "US"
            },
            {
                "name": "March Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Maricopa",
                "countryCode": "US"
            },
            {
                "name": "Marin City",
                "countryCode": "US"
            },
            {
                "name": "Marin County",
                "countryCode": "US"
            },
            {
                "name": "Marina",
                "countryCode": "US"
            },
            {
                "name": "Marina del Rey",
                "countryCode": "US"
            },
            {
                "name": "Mariposa",
                "countryCode": "US"
            },
            {
                "name": "Mariposa County",
                "countryCode": "US"
            },
            {
                "name": "Martinez",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "Matheny",
                "countryCode": "US"
            },
            {
                "name": "Maxwell",
                "countryCode": "US"
            },
            {
                "name": "Mayflower Village",
                "countryCode": "US"
            },
            {
                "name": "Maywood",
                "countryCode": "US"
            },
            {
                "name": "McCloud",
                "countryCode": "US"
            },
            {
                "name": "McFarland",
                "countryCode": "US"
            },
            {
                "name": "McKinleyville",
                "countryCode": "US"
            },
            {
                "name": "Mead Valley",
                "countryCode": "US"
            },
            {
                "name": "Meadow Vista",
                "countryCode": "US"
            },
            {
                "name": "Meadowbrook",
                "countryCode": "US"
            },
            {
                "name": "Mecca",
                "countryCode": "US"
            },
            {
                "name": "Meiners Oaks",
                "countryCode": "US"
            },
            {
                "name": "Mendocino County",
                "countryCode": "US"
            },
            {
                "name": "Mendota",
                "countryCode": "US"
            },
            {
                "name": "Menifee",
                "countryCode": "US"
            },
            {
                "name": "Menlo Park",
                "countryCode": "US"
            },
            {
                "name": "Mentone",
                "countryCode": "US"
            },
            {
                "name": "Merced",
                "countryCode": "US"
            },
            {
                "name": "Merced County",
                "countryCode": "US"
            },
            {
                "name": "Mesa Verde",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Midpines",
                "countryCode": "US"
            },
            {
                "name": "Midway City",
                "countryCode": "US"
            },
            {
                "name": "Mill Valley",
                "countryCode": "US"
            },
            {
                "name": "Millbrae",
                "countryCode": "US"
            },
            {
                "name": "Milpitas",
                "countryCode": "US"
            },
            {
                "name": "Minkler",
                "countryCode": "US"
            },
            {
                "name": "Mira Mesa",
                "countryCode": "US"
            },
            {
                "name": "Mira Monte",
                "countryCode": "US"
            },
            {
                "name": "Mission Canyon",
                "countryCode": "US"
            },
            {
                "name": "Mission District",
                "countryCode": "US"
            },
            {
                "name": "Mission Hills",
                "countryCode": "US"
            },
            {
                "name": "Mission Viejo",
                "countryCode": "US"
            },
            {
                "name": "Modesto",
                "countryCode": "US"
            },
            {
                "name": "Modoc County",
                "countryCode": "US"
            },
            {
                "name": "Mojave",
                "countryCode": "US"
            },
            {
                "name": "Mono County",
                "countryCode": "US"
            },
            {
                "name": "Mono Vista",
                "countryCode": "US"
            },
            {
                "name": "Monrovia",
                "countryCode": "US"
            },
            {
                "name": "Montague",
                "countryCode": "US"
            },
            {
                "name": "Montalvin",
                "countryCode": "US"
            },
            {
                "name": "Montara",
                "countryCode": "US"
            },
            {
                "name": "Montclair",
                "countryCode": "US"
            },
            {
                "name": "Monte Rio",
                "countryCode": "US"
            },
            {
                "name": "Monte Sereno",
                "countryCode": "US"
            },
            {
                "name": "Montebello",
                "countryCode": "US"
            },
            {
                "name": "Montecito",
                "countryCode": "US"
            },
            {
                "name": "Monterey",
                "countryCode": "US"
            },
            {
                "name": "Monterey County",
                "countryCode": "US"
            },
            {
                "name": "Monterey Park",
                "countryCode": "US"
            },
            {
                "name": "Monument Hills",
                "countryCode": "US"
            },
            {
                "name": "Moorpark",
                "countryCode": "US"
            },
            {
                "name": "Morada",
                "countryCode": "US"
            },
            {
                "name": "Moraga",
                "countryCode": "US"
            },
            {
                "name": "Moreno Valley",
                "countryCode": "US"
            },
            {
                "name": "Morgan Hill",
                "countryCode": "US"
            },
            {
                "name": "Morongo Valley",
                "countryCode": "US"
            },
            {
                "name": "Morro Bay",
                "countryCode": "US"
            },
            {
                "name": "Moss Beach",
                "countryCode": "US"
            },
            {
                "name": "Mount Hermon",
                "countryCode": "US"
            },
            {
                "name": "Mount Shasta",
                "countryCode": "US"
            },
            {
                "name": "Mountain House",
                "countryCode": "US"
            },
            {
                "name": "Mountain Ranch",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Mountain View Acres",
                "countryCode": "US"
            },
            {
                "name": "Murphys",
                "countryCode": "US"
            },
            {
                "name": "Murrieta",
                "countryCode": "US"
            },
            {
                "name": "Murrieta Hot Springs",
                "countryCode": "US"
            },
            {
                "name": "Muscoy",
                "countryCode": "US"
            },
            {
                "name": "Myrtletown",
                "countryCode": "US"
            },
            {
                "name": "Napa",
                "countryCode": "US"
            },
            {
                "name": "Napa County",
                "countryCode": "US"
            },
            {
                "name": "National City",
                "countryCode": "US"
            },
            {
                "name": "Needles",
                "countryCode": "US"
            },
            {
                "name": "Nevada City",
                "countryCode": "US"
            },
            {
                "name": "Nevada County",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newcastle",
                "countryCode": "US"
            },
            {
                "name": "Newman",
                "countryCode": "US"
            },
            {
                "name": "Newport Beach",
                "countryCode": "US"
            },
            {
                "name": "Nice",
                "countryCode": "US"
            },
            {
                "name": "Niland",
                "countryCode": "US"
            },
            {
                "name": "Nipomo",
                "countryCode": "US"
            },
            {
                "name": "Noe Valley",
                "countryCode": "US"
            },
            {
                "name": "Norco",
                "countryCode": "US"
            },
            {
                "name": "North Auburn",
                "countryCode": "US"
            },
            {
                "name": "North Edwards",
                "countryCode": "US"
            },
            {
                "name": "North El Monte",
                "countryCode": "US"
            },
            {
                "name": "North Fair Oaks",
                "countryCode": "US"
            },
            {
                "name": "North Highlands",
                "countryCode": "US"
            },
            {
                "name": "North Hills",
                "countryCode": "US"
            },
            {
                "name": "North Hollywood",
                "countryCode": "US"
            },
            {
                "name": "North Lakeport",
                "countryCode": "US"
            },
            {
                "name": "North Richmond",
                "countryCode": "US"
            },
            {
                "name": "North Tustin",
                "countryCode": "US"
            },
            {
                "name": "Northridge",
                "countryCode": "US"
            },
            {
                "name": "Norwalk",
                "countryCode": "US"
            },
            {
                "name": "Novato",
                "countryCode": "US"
            },
            {
                "name": "Nuevo",
                "countryCode": "US"
            },
            {
                "name": "Oak Hills",
                "countryCode": "US"
            },
            {
                "name": "Oak Park",
                "countryCode": "US"
            },
            {
                "name": "Oak View",
                "countryCode": "US"
            },
            {
                "name": "Oakdale",
                "countryCode": "US"
            },
            {
                "name": "Oakhurst",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oakley",
                "countryCode": "US"
            },
            {
                "name": "Oasis",
                "countryCode": "US"
            },
            {
                "name": "Occidental",
                "countryCode": "US"
            },
            {
                "name": "Oceano",
                "countryCode": "US"
            },
            {
                "name": "Oceanside",
                "countryCode": "US"
            },
            {
                "name": "Oildale",
                "countryCode": "US"
            },
            {
                "name": "Ojai",
                "countryCode": "US"
            },
            {
                "name": "Old Fig Garden",
                "countryCode": "US"
            },
            {
                "name": "Olivehurst",
                "countryCode": "US"
            },
            {
                "name": "Ontario",
                "countryCode": "US"
            },
            {
                "name": "Opal Cliffs",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orange Cove",
                "countryCode": "US"
            },
            {
                "name": "Orangevale",
                "countryCode": "US"
            },
            {
                "name": "Orcutt",
                "countryCode": "US"
            },
            {
                "name": "Orinda",
                "countryCode": "US"
            },
            {
                "name": "Orland",
                "countryCode": "US"
            },
            {
                "name": "Orosi",
                "countryCode": "US"
            },
            {
                "name": "Oroville",
                "countryCode": "US"
            },
            {
                "name": "Oroville East",
                "countryCode": "US"
            },
            {
                "name": "Oxnard",
                "countryCode": "US"
            },
            {
                "name": "Pacheco",
                "countryCode": "US"
            },
            {
                "name": "Pacific Grove",
                "countryCode": "US"
            },
            {
                "name": "Pacifica",
                "countryCode": "US"
            },
            {
                "name": "Pajaro",
                "countryCode": "US"
            },
            {
                "name": "Palermo",
                "countryCode": "US"
            },
            {
                "name": "Palm Desert",
                "countryCode": "US"
            },
            {
                "name": "Palm Springs",
                "countryCode": "US"
            },
            {
                "name": "Palmdale",
                "countryCode": "US"
            },
            {
                "name": "Palo Alto",
                "countryCode": "US"
            },
            {
                "name": "Palo Cedro",
                "countryCode": "US"
            },
            {
                "name": "Palos Verdes Estates",
                "countryCode": "US"
            },
            {
                "name": "Paradise",
                "countryCode": "US"
            },
            {
                "name": "Paramount",
                "countryCode": "US"
            },
            {
                "name": "Parksdale",
                "countryCode": "US"
            },
            {
                "name": "Parkway",
                "countryCode": "US"
            },
            {
                "name": "Parkwood",
                "countryCode": "US"
            },
            {
                "name": "Parlier",
                "countryCode": "US"
            },
            {
                "name": "Pasadena",
                "countryCode": "US"
            },
            {
                "name": "Pasatiempo",
                "countryCode": "US"
            },
            {
                "name": "Paso Robles",
                "countryCode": "US"
            },
            {
                "name": "Patterson",
                "countryCode": "US"
            },
            {
                "name": "Patterson Tract",
                "countryCode": "US"
            },
            {
                "name": "Pedley",
                "countryCode": "US"
            },
            {
                "name": "Penn Valley",
                "countryCode": "US"
            },
            {
                "name": "Penngrove",
                "countryCode": "US"
            },
            {
                "name": "Perris",
                "countryCode": "US"
            },
            {
                "name": "Petaluma",
                "countryCode": "US"
            },
            {
                "name": "Phelan",
                "countryCode": "US"
            },
            {
                "name": "Phoenix Lake",
                "countryCode": "US"
            },
            {
                "name": "Pico Rivera",
                "countryCode": "US"
            },
            {
                "name": "Piedmont",
                "countryCode": "US"
            },
            {
                "name": "Pine Grove",
                "countryCode": "US"
            },
            {
                "name": "Pine Hills",
                "countryCode": "US"
            },
            {
                "name": "Pine Mountain Club",
                "countryCode": "US"
            },
            {
                "name": "Pine Valley",
                "countryCode": "US"
            },
            {
                "name": "Pinole",
                "countryCode": "US"
            },
            {
                "name": "Pioneer",
                "countryCode": "US"
            },
            {
                "name": "Piru",
                "countryCode": "US"
            },
            {
                "name": "Pismo Beach",
                "countryCode": "US"
            },
            {
                "name": "Pittsburg",
                "countryCode": "US"
            },
            {
                "name": "Pixley",
                "countryCode": "US"
            },
            {
                "name": "Piñon Hills",
                "countryCode": "US"
            },
            {
                "name": "Placentia",
                "countryCode": "US"
            },
            {
                "name": "Placer County",
                "countryCode": "US"
            },
            {
                "name": "Placerville",
                "countryCode": "US"
            },
            {
                "name": "Planada",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Pleasanton",
                "countryCode": "US"
            },
            {
                "name": "Plumas County",
                "countryCode": "US"
            },
            {
                "name": "Plumas Lake",
                "countryCode": "US"
            },
            {
                "name": "Pollock Pines",
                "countryCode": "US"
            },
            {
                "name": "Pomona",
                "countryCode": "US"
            },
            {
                "name": "Poplar-Cotton Center",
                "countryCode": "US"
            },
            {
                "name": "Port Hueneme",
                "countryCode": "US"
            },
            {
                "name": "Porterville",
                "countryCode": "US"
            },
            {
                "name": "Portola",
                "countryCode": "US"
            },
            {
                "name": "Portola Hills",
                "countryCode": "US"
            },
            {
                "name": "Portola Valley",
                "countryCode": "US"
            },
            {
                "name": "Poway",
                "countryCode": "US"
            },
            {
                "name": "Prunedale",
                "countryCode": "US"
            },
            {
                "name": "Quail Valley",
                "countryCode": "US"
            },
            {
                "name": "Quartz Hill",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Rainbow",
                "countryCode": "US"
            },
            {
                "name": "Ramona",
                "countryCode": "US"
            },
            {
                "name": "Rancho Calaveras",
                "countryCode": "US"
            },
            {
                "name": "Rancho Cordova",
                "countryCode": "US"
            },
            {
                "name": "Rancho Cucamonga",
                "countryCode": "US"
            },
            {
                "name": "Rancho Mirage",
                "countryCode": "US"
            },
            {
                "name": "Rancho Murieta",
                "countryCode": "US"
            },
            {
                "name": "Rancho Palos Verdes",
                "countryCode": "US"
            },
            {
                "name": "Rancho Penasquitos",
                "countryCode": "US"
            },
            {
                "name": "Rancho San Diego",
                "countryCode": "US"
            },
            {
                "name": "Rancho Santa Fe",
                "countryCode": "US"
            },
            {
                "name": "Rancho Santa Margarita",
                "countryCode": "US"
            },
            {
                "name": "Rancho Tehama Reserve",
                "countryCode": "US"
            },
            {
                "name": "Red Bluff",
                "countryCode": "US"
            },
            {
                "name": "Red Corral",
                "countryCode": "US"
            },
            {
                "name": "Redding",
                "countryCode": "US"
            },
            {
                "name": "Redlands",
                "countryCode": "US"
            },
            {
                "name": "Redondo Beach",
                "countryCode": "US"
            },
            {
                "name": "Redway",
                "countryCode": "US"
            },
            {
                "name": "Redwood City",
                "countryCode": "US"
            },
            {
                "name": "Redwood Shores",
                "countryCode": "US"
            },
            {
                "name": "Redwood Valley",
                "countryCode": "US"
            },
            {
                "name": "Reedley",
                "countryCode": "US"
            },
            {
                "name": "Rialto",
                "countryCode": "US"
            },
            {
                "name": "Richgrove",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Ridgecrest",
                "countryCode": "US"
            },
            {
                "name": "Ridgemark",
                "countryCode": "US"
            },
            {
                "name": "Rio Del Mar",
                "countryCode": "US"
            },
            {
                "name": "Rio Dell",
                "countryCode": "US"
            },
            {
                "name": "Rio Linda",
                "countryCode": "US"
            },
            {
                "name": "Rio Vista",
                "countryCode": "US"
            },
            {
                "name": "Ripon",
                "countryCode": "US"
            },
            {
                "name": "Riverbank",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Riverdale Park",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Riverside County",
                "countryCode": "US"
            },
            {
                "name": "Rocklin",
                "countryCode": "US"
            },
            {
                "name": "Rodeo",
                "countryCode": "US"
            },
            {
                "name": "Rohnert Park",
                "countryCode": "US"
            },
            {
                "name": "Rolling Hills",
                "countryCode": "US"
            },
            {
                "name": "Rolling Hills Estates",
                "countryCode": "US"
            },
            {
                "name": "Rollingwood",
                "countryCode": "US"
            },
            {
                "name": "Romoland",
                "countryCode": "US"
            },
            {
                "name": "Rosamond",
                "countryCode": "US"
            },
            {
                "name": "Rosedale",
                "countryCode": "US"
            },
            {
                "name": "Roseland",
                "countryCode": "US"
            },
            {
                "name": "Rosemead",
                "countryCode": "US"
            },
            {
                "name": "Rosemont",
                "countryCode": "US"
            },
            {
                "name": "Roseville",
                "countryCode": "US"
            },
            {
                "name": "Ross",
                "countryCode": "US"
            },
            {
                "name": "Rossmoor",
                "countryCode": "US"
            },
            {
                "name": "Rowland Heights",
                "countryCode": "US"
            },
            {
                "name": "Rubidoux",
                "countryCode": "US"
            },
            {
                "name": "Running Springs",
                "countryCode": "US"
            },
            {
                "name": "Sacramento",
                "countryCode": "US"
            },
            {
                "name": "Sacramento County",
                "countryCode": "US"
            },
            {
                "name": "Saint Helena",
                "countryCode": "US"
            },
            {
                "name": "Salida",
                "countryCode": "US"
            },
            {
                "name": "Salinas",
                "countryCode": "US"
            },
            {
                "name": "Salton City",
                "countryCode": "US"
            },
            {
                "name": "San Andreas",
                "countryCode": "US"
            },
            {
                "name": "San Anselmo",
                "countryCode": "US"
            },
            {
                "name": "San Antonio Heights",
                "countryCode": "US"
            },
            {
                "name": "San Benito County",
                "countryCode": "US"
            },
            {
                "name": "San Bernardino",
                "countryCode": "US"
            },
            {
                "name": "San Bernardino County",
                "countryCode": "US"
            },
            {
                "name": "San Bruno",
                "countryCode": "US"
            },
            {
                "name": "San Carlos",
                "countryCode": "US"
            },
            {
                "name": "San Clemente",
                "countryCode": "US"
            },
            {
                "name": "San Diego",
                "countryCode": "US"
            },
            {
                "name": "San Diego Country Estates",
                "countryCode": "US"
            },
            {
                "name": "San Diego County",
                "countryCode": "US"
            },
            {
                "name": "San Dimas",
                "countryCode": "US"
            },
            {
                "name": "San Fernando",
                "countryCode": "US"
            },
            {
                "name": "San Francisco",
                "countryCode": "US"
            },
            {
                "name": "San Gabriel",
                "countryCode": "US"
            },
            {
                "name": "San Jacinto",
                "countryCode": "US"
            },
            {
                "name": "San Joaquin",
                "countryCode": "US"
            },
            {
                "name": "San Joaquin County",
                "countryCode": "US"
            },
            {
                "name": "San Joaquin Hills",
                "countryCode": "US"
            },
            {
                "name": "San Jose",
                "countryCode": "US"
            },
            {
                "name": "San Juan Bautista",
                "countryCode": "US"
            },
            {
                "name": "San Juan Capistrano",
                "countryCode": "US"
            },
            {
                "name": "San Leandro",
                "countryCode": "US"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "US"
            },
            {
                "name": "San Luis Obispo",
                "countryCode": "US"
            },
            {
                "name": "San Luis Obispo County",
                "countryCode": "US"
            },
            {
                "name": "San Marcos",
                "countryCode": "US"
            },
            {
                "name": "San Marino",
                "countryCode": "US"
            },
            {
                "name": "San Martin",
                "countryCode": "US"
            },
            {
                "name": "San Mateo",
                "countryCode": "US"
            },
            {
                "name": "San Mateo County",
                "countryCode": "US"
            },
            {
                "name": "San Miguel",
                "countryCode": "US"
            },
            {
                "name": "San Pablo",
                "countryCode": "US"
            },
            {
                "name": "San Pasqual",
                "countryCode": "US"
            },
            {
                "name": "San Pedro",
                "countryCode": "US"
            },
            {
                "name": "San Rafael",
                "countryCode": "US"
            },
            {
                "name": "San Ramon",
                "countryCode": "US"
            },
            {
                "name": "Sanger",
                "countryCode": "US"
            },
            {
                "name": "Santa Ana",
                "countryCode": "US"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "US"
            },
            {
                "name": "Santa Barbara County",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara County",
                "countryCode": "US"
            },
            {
                "name": "Santa Clarita",
                "countryCode": "US"
            },
            {
                "name": "Santa Cruz",
                "countryCode": "US"
            },
            {
                "name": "Santa Cruz County",
                "countryCode": "US"
            },
            {
                "name": "Santa Fe Springs",
                "countryCode": "US"
            },
            {
                "name": "Santa Margarita",
                "countryCode": "US"
            },
            {
                "name": "Santa Maria",
                "countryCode": "US"
            },
            {
                "name": "Santa Monica",
                "countryCode": "US"
            },
            {
                "name": "Santa Paula",
                "countryCode": "US"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "US"
            },
            {
                "name": "Santa Susana",
                "countryCode": "US"
            },
            {
                "name": "Santa Venetia",
                "countryCode": "US"
            },
            {
                "name": "Santa Ynez",
                "countryCode": "US"
            },
            {
                "name": "Santee",
                "countryCode": "US"
            },
            {
                "name": "Saranap",
                "countryCode": "US"
            },
            {
                "name": "Saratoga",
                "countryCode": "US"
            },
            {
                "name": "Saticoy",
                "countryCode": "US"
            },
            {
                "name": "Sausalito",
                "countryCode": "US"
            },
            {
                "name": "Scotts Valley",
                "countryCode": "US"
            },
            {
                "name": "Sea Ranch",
                "countryCode": "US"
            },
            {
                "name": "Seacliff",
                "countryCode": "US"
            },
            {
                "name": "Seal Beach",
                "countryCode": "US"
            },
            {
                "name": "Searles Valley",
                "countryCode": "US"
            },
            {
                "name": "Seaside",
                "countryCode": "US"
            },
            {
                "name": "Sebastopol",
                "countryCode": "US"
            },
            {
                "name": "Sedco Hills",
                "countryCode": "US"
            },
            {
                "name": "Seeley",
                "countryCode": "US"
            },
            {
                "name": "Selma",
                "countryCode": "US"
            },
            {
                "name": "Seven Trees",
                "countryCode": "US"
            },
            {
                "name": "Shackelford",
                "countryCode": "US"
            },
            {
                "name": "Shadow Hills",
                "countryCode": "US"
            },
            {
                "name": "Shafter",
                "countryCode": "US"
            },
            {
                "name": "Shandon",
                "countryCode": "US"
            },
            {
                "name": "Shasta",
                "countryCode": "US"
            },
            {
                "name": "Shasta County",
                "countryCode": "US"
            },
            {
                "name": "Shasta Lake",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sherman Oaks",
                "countryCode": "US"
            },
            {
                "name": "Shingle Springs",
                "countryCode": "US"
            },
            {
                "name": "Shingletown",
                "countryCode": "US"
            },
            {
                "name": "Sierra County",
                "countryCode": "US"
            },
            {
                "name": "Sierra Madre",
                "countryCode": "US"
            },
            {
                "name": "Signal Hill",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Silver Lakes",
                "countryCode": "US"
            },
            {
                "name": "Simi Valley",
                "countryCode": "US"
            },
            {
                "name": "Siskiyou County",
                "countryCode": "US"
            },
            {
                "name": "Sky Valley",
                "countryCode": "US"
            },
            {
                "name": "Sleepy Hollow",
                "countryCode": "US"
            },
            {
                "name": "Soda Bay",
                "countryCode": "US"
            },
            {
                "name": "Solana Beach",
                "countryCode": "US"
            },
            {
                "name": "Solano County",
                "countryCode": "US"
            },
            {
                "name": "Soledad",
                "countryCode": "US"
            },
            {
                "name": "Solvang",
                "countryCode": "US"
            },
            {
                "name": "Sonoma",
                "countryCode": "US"
            },
            {
                "name": "Sonoma County",
                "countryCode": "US"
            },
            {
                "name": "Sonora",
                "countryCode": "US"
            },
            {
                "name": "Soquel",
                "countryCode": "US"
            },
            {
                "name": "Sorrento Valley",
                "countryCode": "US"
            },
            {
                "name": "Soulsbyville",
                "countryCode": "US"
            },
            {
                "name": "South Dos Palos",
                "countryCode": "US"
            },
            {
                "name": "South El Monte",
                "countryCode": "US"
            },
            {
                "name": "South Gate",
                "countryCode": "US"
            },
            {
                "name": "South Lake Tahoe",
                "countryCode": "US"
            },
            {
                "name": "South Oroville",
                "countryCode": "US"
            },
            {
                "name": "South Pasadena",
                "countryCode": "US"
            },
            {
                "name": "South San Francisco",
                "countryCode": "US"
            },
            {
                "name": "South San Gabriel",
                "countryCode": "US"
            },
            {
                "name": "South San Jose Hills",
                "countryCode": "US"
            },
            {
                "name": "South Taft",
                "countryCode": "US"
            },
            {
                "name": "South Whittier",
                "countryCode": "US"
            },
            {
                "name": "South Yuba City",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley Lake",
                "countryCode": "US"
            },
            {
                "name": "Squaw Valley",
                "countryCode": "US"
            },
            {
                "name": "Stallion Springs",
                "countryCode": "US"
            },
            {
                "name": "Stanford",
                "countryCode": "US"
            },
            {
                "name": "Stanislaus County",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Stevenson Ranch",
                "countryCode": "US"
            },
            {
                "name": "Stockton",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Strathmore",
                "countryCode": "US"
            },
            {
                "name": "Strawberry",
                "countryCode": "US"
            },
            {
                "name": "Studio City",
                "countryCode": "US"
            },
            {
                "name": "Suisun",
                "countryCode": "US"
            },
            {
                "name": "Summerland",
                "countryCode": "US"
            },
            {
                "name": "Sun City",
                "countryCode": "US"
            },
            {
                "name": "Sun Village",
                "countryCode": "US"
            },
            {
                "name": "Sunland",
                "countryCode": "US"
            },
            {
                "name": "Sunnyside",
                "countryCode": "US"
            },
            {
                "name": "Sunnyside-Tahoe City",
                "countryCode": "US"
            },
            {
                "name": "Sunnyslope",
                "countryCode": "US"
            },
            {
                "name": "Sunnyvale",
                "countryCode": "US"
            },
            {
                "name": "Susanville",
                "countryCode": "US"
            },
            {
                "name": "Sutter",
                "countryCode": "US"
            },
            {
                "name": "Sutter County",
                "countryCode": "US"
            },
            {
                "name": "Sutter Creek",
                "countryCode": "US"
            },
            {
                "name": "Taft",
                "countryCode": "US"
            },
            {
                "name": "Taft Heights",
                "countryCode": "US"
            },
            {
                "name": "Taft Mosswood",
                "countryCode": "US"
            },
            {
                "name": "Tahoe Vista",
                "countryCode": "US"
            },
            {
                "name": "Tahoma",
                "countryCode": "US"
            },
            {
                "name": "Talmage",
                "countryCode": "US"
            },
            {
                "name": "Tamalpais Valley",
                "countryCode": "US"
            },
            {
                "name": "Tamalpais-Homestead Valley",
                "countryCode": "US"
            },
            {
                "name": "Tara Hills",
                "countryCode": "US"
            },
            {
                "name": "Tarpey Village",
                "countryCode": "US"
            },
            {
                "name": "Tehachapi",
                "countryCode": "US"
            },
            {
                "name": "Tehama County",
                "countryCode": "US"
            },
            {
                "name": "Temecula",
                "countryCode": "US"
            },
            {
                "name": "Temelec",
                "countryCode": "US"
            },
            {
                "name": "Temple City",
                "countryCode": "US"
            },
            {
                "name": "Templeton",
                "countryCode": "US"
            },
            {
                "name": "Terra Bella",
                "countryCode": "US"
            },
            {
                "name": "Teviston",
                "countryCode": "US"
            },
            {
                "name": "Thermal",
                "countryCode": "US"
            },
            {
                "name": "Thermalito",
                "countryCode": "US"
            },
            {
                "name": "Thornton",
                "countryCode": "US"
            },
            {
                "name": "Thousand Oaks",
                "countryCode": "US"
            },
            {
                "name": "Thousand Palms",
                "countryCode": "US"
            },
            {
                "name": "Three Rivers",
                "countryCode": "US"
            },
            {
                "name": "Tiburon",
                "countryCode": "US"
            },
            {
                "name": "Tierra Buena",
                "countryCode": "US"
            },
            {
                "name": "Tipton",
                "countryCode": "US"
            },
            {
                "name": "Topanga",
                "countryCode": "US"
            },
            {
                "name": "Toro Canyon",
                "countryCode": "US"
            },
            {
                "name": "Torrance",
                "countryCode": "US"
            },
            {
                "name": "Trabuco Canyon",
                "countryCode": "US"
            },
            {
                "name": "Tracy",
                "countryCode": "US"
            },
            {
                "name": "Trinity County",
                "countryCode": "US"
            },
            {
                "name": "Truckee",
                "countryCode": "US"
            },
            {
                "name": "Tujunga",
                "countryCode": "US"
            },
            {
                "name": "Tulare",
                "countryCode": "US"
            },
            {
                "name": "Tulare County",
                "countryCode": "US"
            },
            {
                "name": "Tuolumne City",
                "countryCode": "US"
            },
            {
                "name": "Tuolumne County",
                "countryCode": "US"
            },
            {
                "name": "Turlock",
                "countryCode": "US"
            },
            {
                "name": "Tustin",
                "countryCode": "US"
            },
            {
                "name": "Twain Harte",
                "countryCode": "US"
            },
            {
                "name": "Twentynine Palms",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Ukiah",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Universal City",
                "countryCode": "US"
            },
            {
                "name": "Upland",
                "countryCode": "US"
            },
            {
                "name": "Upper Lake",
                "countryCode": "US"
            },
            {
                "name": "Vacaville",
                "countryCode": "US"
            },
            {
                "name": "Val Verde",
                "countryCode": "US"
            },
            {
                "name": "Valencia",
                "countryCode": "US"
            },
            {
                "name": "Valinda",
                "countryCode": "US"
            },
            {
                "name": "Valle Vista",
                "countryCode": "US"
            },
            {
                "name": "Vallejo",
                "countryCode": "US"
            },
            {
                "name": "Valley Center",
                "countryCode": "US"
            },
            {
                "name": "Valley Glen",
                "countryCode": "US"
            },
            {
                "name": "Valley Springs",
                "countryCode": "US"
            },
            {
                "name": "Van Nuys",
                "countryCode": "US"
            },
            {
                "name": "Vandenberg Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Vandenberg Village",
                "countryCode": "US"
            },
            {
                "name": "Venice",
                "countryCode": "US"
            },
            {
                "name": "Ventura",
                "countryCode": "US"
            },
            {
                "name": "Ventura County",
                "countryCode": "US"
            },
            {
                "name": "Victorville",
                "countryCode": "US"
            },
            {
                "name": "View Park-Windsor Hills",
                "countryCode": "US"
            },
            {
                "name": "Villa Park",
                "countryCode": "US"
            },
            {
                "name": "Vincent",
                "countryCode": "US"
            },
            {
                "name": "Vine Hill",
                "countryCode": "US"
            },
            {
                "name": "Vineyard",
                "countryCode": "US"
            },
            {
                "name": "Visalia",
                "countryCode": "US"
            },
            {
                "name": "Visitacion Valley",
                "countryCode": "US"
            },
            {
                "name": "Vista",
                "countryCode": "US"
            },
            {
                "name": "Vista Santa Rosa",
                "countryCode": "US"
            },
            {
                "name": "Waldon",
                "countryCode": "US"
            },
            {
                "name": "Walnut",
                "countryCode": "US"
            },
            {
                "name": "Walnut Creek",
                "countryCode": "US"
            },
            {
                "name": "Walnut Grove",
                "countryCode": "US"
            },
            {
                "name": "Walnut Park",
                "countryCode": "US"
            },
            {
                "name": "Wasco",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Watsonville",
                "countryCode": "US"
            },
            {
                "name": "Weaverville",
                "countryCode": "US"
            },
            {
                "name": "Weed",
                "countryCode": "US"
            },
            {
                "name": "Weedpatch",
                "countryCode": "US"
            },
            {
                "name": "Weldon",
                "countryCode": "US"
            },
            {
                "name": "West Athens",
                "countryCode": "US"
            },
            {
                "name": "West Bishop",
                "countryCode": "US"
            },
            {
                "name": "West Carson",
                "countryCode": "US"
            },
            {
                "name": "West Covina",
                "countryCode": "US"
            },
            {
                "name": "West Hills",
                "countryCode": "US"
            },
            {
                "name": "West Hollywood",
                "countryCode": "US"
            },
            {
                "name": "West Menlo Park",
                "countryCode": "US"
            },
            {
                "name": "West Modesto",
                "countryCode": "US"
            },
            {
                "name": "West Park",
                "countryCode": "US"
            },
            {
                "name": "West Puente Valley",
                "countryCode": "US"
            },
            {
                "name": "West Rancho Dominguez",
                "countryCode": "US"
            },
            {
                "name": "West Sacramento",
                "countryCode": "US"
            },
            {
                "name": "West Whittier-Los Nietos",
                "countryCode": "US"
            },
            {
                "name": "Westhaven-Moonstone",
                "countryCode": "US"
            },
            {
                "name": "Westlake Village",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Westmont",
                "countryCode": "US"
            },
            {
                "name": "Westmorland",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Wheatland",
                "countryCode": "US"
            },
            {
                "name": "Whittier",
                "countryCode": "US"
            },
            {
                "name": "Wildomar",
                "countryCode": "US"
            },
            {
                "name": "Williams",
                "countryCode": "US"
            },
            {
                "name": "Willits",
                "countryCode": "US"
            },
            {
                "name": "Willow Creek",
                "countryCode": "US"
            },
            {
                "name": "Willowbrook",
                "countryCode": "US"
            },
            {
                "name": "Willows",
                "countryCode": "US"
            },
            {
                "name": "Wilton",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Winter Gardens",
                "countryCode": "US"
            },
            {
                "name": "Winters",
                "countryCode": "US"
            },
            {
                "name": "Winton",
                "countryCode": "US"
            },
            {
                "name": "Wofford Heights",
                "countryCode": "US"
            },
            {
                "name": "Woodacre",
                "countryCode": "US"
            },
            {
                "name": "Woodbridge",
                "countryCode": "US"
            },
            {
                "name": "Woodcrest",
                "countryCode": "US"
            },
            {
                "name": "Woodlake",
                "countryCode": "US"
            },
            {
                "name": "Woodland",
                "countryCode": "US"
            },
            {
                "name": "Woodland Hills",
                "countryCode": "US"
            },
            {
                "name": "Woodside",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Wrightwood",
                "countryCode": "US"
            },
            {
                "name": "Yolo County",
                "countryCode": "US"
            },
            {
                "name": "Yorba Linda",
                "countryCode": "US"
            },
            {
                "name": "Yosemite Lakes",
                "countryCode": "US"
            },
            {
                "name": "Yosemite Valley",
                "countryCode": "US"
            },
            {
                "name": "Yountville",
                "countryCode": "US"
            },
            {
                "name": "Yreka",
                "countryCode": "US"
            },
            {
                "name": "Yuba City",
                "countryCode": "US"
            },
            {
                "name": "Yuba County",
                "countryCode": "US"
            },
            {
                "name": "Yucaipa",
                "countryCode": "US"
            },
            {
                "name": "Yucca Valley",
                "countryCode": "US"
            },
            {
                "name": "Acres Green",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Air Force Academy",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Alamosa",
                "countryCode": "US"
            },
            {
                "name": "Alamosa County",
                "countryCode": "US"
            },
            {
                "name": "Alamosa East",
                "countryCode": "US"
            },
            {
                "name": "Applewood",
                "countryCode": "US"
            },
            {
                "name": "Arapahoe County",
                "countryCode": "US"
            },
            {
                "name": "Archuleta County",
                "countryCode": "US"
            },
            {
                "name": "Aristocrat Ranchettes",
                "countryCode": "US"
            },
            {
                "name": "Arvada",
                "countryCode": "US"
            },
            {
                "name": "Aspen",
                "countryCode": "US"
            },
            {
                "name": "Ault",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Baca County",
                "countryCode": "US"
            },
            {
                "name": "Basalt",
                "countryCode": "US"
            },
            {
                "name": "Battlement Mesa",
                "countryCode": "US"
            },
            {
                "name": "Bayfield",
                "countryCode": "US"
            },
            {
                "name": "Bennett",
                "countryCode": "US"
            },
            {
                "name": "Bent County",
                "countryCode": "US"
            },
            {
                "name": "Berkley",
                "countryCode": "US"
            },
            {
                "name": "Berthoud",
                "countryCode": "US"
            },
            {
                "name": "Black Forest",
                "countryCode": "US"
            },
            {
                "name": "Boulder",
                "countryCode": "US"
            },
            {
                "name": "Boulder County",
                "countryCode": "US"
            },
            {
                "name": "Breckenridge",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Broomfield",
                "countryCode": "US"
            },
            {
                "name": "Broomfield County",
                "countryCode": "US"
            },
            {
                "name": "Brush",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Byers",
                "countryCode": "US"
            },
            {
                "name": "Campion",
                "countryCode": "US"
            },
            {
                "name": "Carbondale",
                "countryCode": "US"
            },
            {
                "name": "Carriage Club",
                "countryCode": "US"
            },
            {
                "name": "Cascade-Chipita Park",
                "countryCode": "US"
            },
            {
                "name": "Castle Pines",
                "countryCode": "US"
            },
            {
                "name": "Castle Pines North",
                "countryCode": "US"
            },
            {
                "name": "Castle Rock",
                "countryCode": "US"
            },
            {
                "name": "Castlewood",
                "countryCode": "US"
            },
            {
                "name": "Cañon City",
                "countryCode": "US"
            },
            {
                "name": "Cedaredge",
                "countryCode": "US"
            },
            {
                "name": "Centennial",
                "countryCode": "US"
            },
            {
                "name": "Center",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Chaffee County",
                "countryCode": "US"
            },
            {
                "name": "Cherry Creek",
                "countryCode": "US"
            },
            {
                "name": "Cherry Hills Village",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne County",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne Wells",
                "countryCode": "US"
            },
            {
                "name": "Cimarron Hills",
                "countryCode": "US"
            },
            {
                "name": "Clear Creek County",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Coal Creek",
                "countryCode": "US"
            },
            {
                "name": "Colorado City",
                "countryCode": "US"
            },
            {
                "name": "Colorado Springs",
                "countryCode": "US"
            },
            {
                "name": "Columbine",
                "countryCode": "US"
            },
            {
                "name": "Columbine Valley",
                "countryCode": "US"
            },
            {
                "name": "Commerce City",
                "countryCode": "US"
            },
            {
                "name": "Conejos",
                "countryCode": "US"
            },
            {
                "name": "Conejos County",
                "countryCode": "US"
            },
            {
                "name": "Cortez",
                "countryCode": "US"
            },
            {
                "name": "Costilla County",
                "countryCode": "US"
            },
            {
                "name": "Craig",
                "countryCode": "US"
            },
            {
                "name": "Creede",
                "countryCode": "US"
            },
            {
                "name": "Crested Butte",
                "countryCode": "US"
            },
            {
                "name": "Cripple Creek",
                "countryCode": "US"
            },
            {
                "name": "Crowley County",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Dacono",
                "countryCode": "US"
            },
            {
                "name": "Del Norte",
                "countryCode": "US"
            },
            {
                "name": "Delta",
                "countryCode": "US"
            },
            {
                "name": "Delta County",
                "countryCode": "US"
            },
            {
                "name": "Denver",
                "countryCode": "US"
            },
            {
                "name": "Denver County",
                "countryCode": "US"
            },
            {
                "name": "Derby",
                "countryCode": "US"
            },
            {
                "name": "Dolores County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Dove Creek",
                "countryCode": "US"
            },
            {
                "name": "Dove Valley",
                "countryCode": "US"
            },
            {
                "name": "Durango",
                "countryCode": "US"
            },
            {
                "name": "Eads",
                "countryCode": "US"
            },
            {
                "name": "Eagle",
                "countryCode": "US"
            },
            {
                "name": "Eagle County",
                "countryCode": "US"
            },
            {
                "name": "Eaton",
                "countryCode": "US"
            },
            {
                "name": "Edgewater",
                "countryCode": "US"
            },
            {
                "name": "Edwards",
                "countryCode": "US"
            },
            {
                "name": "El Jebel",
                "countryCode": "US"
            },
            {
                "name": "El Paso County",
                "countryCode": "US"
            },
            {
                "name": "Elbert County",
                "countryCode": "US"
            },
            {
                "name": "Elizabeth",
                "countryCode": "US"
            },
            {
                "name": "Ellicott",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Erie",
                "countryCode": "US"
            },
            {
                "name": "Estes Park",
                "countryCode": "US"
            },
            {
                "name": "Evans",
                "countryCode": "US"
            },
            {
                "name": "Evergreen",
                "countryCode": "US"
            },
            {
                "name": "Fairplay",
                "countryCode": "US"
            },
            {
                "name": "Federal Heights",
                "countryCode": "US"
            },
            {
                "name": "Firestone",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Fort Carson",
                "countryCode": "US"
            },
            {
                "name": "Fort Collins",
                "countryCode": "US"
            },
            {
                "name": "Fort Lupton",
                "countryCode": "US"
            },
            {
                "name": "Fort Morgan",
                "countryCode": "US"
            },
            {
                "name": "Fountain",
                "countryCode": "US"
            },
            {
                "name": "Fowler",
                "countryCode": "US"
            },
            {
                "name": "Fraser",
                "countryCode": "US"
            },
            {
                "name": "Frederick",
                "countryCode": "US"
            },
            {
                "name": "Fremont County",
                "countryCode": "US"
            },
            {
                "name": "Frisco",
                "countryCode": "US"
            },
            {
                "name": "Fruita",
                "countryCode": "US"
            },
            {
                "name": "Fruitvale",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Genesee",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Gilcrest",
                "countryCode": "US"
            },
            {
                "name": "Gilpin County",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Gleneagle",
                "countryCode": "US"
            },
            {
                "name": "Glenwood Springs",
                "countryCode": "US"
            },
            {
                "name": "Golden",
                "countryCode": "US"
            },
            {
                "name": "Granby",
                "countryCode": "US"
            },
            {
                "name": "Grand County",
                "countryCode": "US"
            },
            {
                "name": "Grand Junction",
                "countryCode": "US"
            },
            {
                "name": "Greeley",
                "countryCode": "US"
            },
            {
                "name": "Greenwood Village",
                "countryCode": "US"
            },
            {
                "name": "Gunbarrel",
                "countryCode": "US"
            },
            {
                "name": "Gunnison",
                "countryCode": "US"
            },
            {
                "name": "Gunnison County",
                "countryCode": "US"
            },
            {
                "name": "Gypsum",
                "countryCode": "US"
            },
            {
                "name": "Hayden",
                "countryCode": "US"
            },
            {
                "name": "Highlands Ranch",
                "countryCode": "US"
            },
            {
                "name": "Hinsdale County",
                "countryCode": "US"
            },
            {
                "name": "Holly Hills",
                "countryCode": "US"
            },
            {
                "name": "Holyoke",
                "countryCode": "US"
            },
            {
                "name": "Hot Sulphur Springs",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Huerfano County",
                "countryCode": "US"
            },
            {
                "name": "Hugo",
                "countryCode": "US"
            },
            {
                "name": "Idaho Springs",
                "countryCode": "US"
            },
            {
                "name": "Indian Hills",
                "countryCode": "US"
            },
            {
                "name": "Inverness",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Johnstown",
                "countryCode": "US"
            },
            {
                "name": "Julesburg",
                "countryCode": "US"
            },
            {
                "name": "Keenesburg",
                "countryCode": "US"
            },
            {
                "name": "Ken Caryl",
                "countryCode": "US"
            },
            {
                "name": "Kersey",
                "countryCode": "US"
            },
            {
                "name": "Keystone",
                "countryCode": "US"
            },
            {
                "name": "Kiowa",
                "countryCode": "US"
            },
            {
                "name": "Kiowa County",
                "countryCode": "US"
            },
            {
                "name": "Kit Carson County",
                "countryCode": "US"
            },
            {
                "name": "Kittredge",
                "countryCode": "US"
            },
            {
                "name": "Kremmling",
                "countryCode": "US"
            },
            {
                "name": "La Junta",
                "countryCode": "US"
            },
            {
                "name": "La Plata County",
                "countryCode": "US"
            },
            {
                "name": "La Salle",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lamar",
                "countryCode": "US"
            },
            {
                "name": "Laporte",
                "countryCode": "US"
            },
            {
                "name": "Larimer County",
                "countryCode": "US"
            },
            {
                "name": "Las Animas",
                "countryCode": "US"
            },
            {
                "name": "Las Animas County",
                "countryCode": "US"
            },
            {
                "name": "Leadville",
                "countryCode": "US"
            },
            {
                "name": "Leadville North",
                "countryCode": "US"
            },
            {
                "name": "Limon",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Littleton",
                "countryCode": "US"
            },
            {
                "name": "Lochbuie",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Loma",
                "countryCode": "US"
            },
            {
                "name": "Lone Tree",
                "countryCode": "US"
            },
            {
                "name": "Longmont",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Loveland",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Mancos",
                "countryCode": "US"
            },
            {
                "name": "Manitou Springs",
                "countryCode": "US"
            },
            {
                "name": "Mead",
                "countryCode": "US"
            },
            {
                "name": "Meeker",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Mesa County",
                "countryCode": "US"
            },
            {
                "name": "Milliken",
                "countryCode": "US"
            },
            {
                "name": "Mineral County",
                "countryCode": "US"
            },
            {
                "name": "Minturn",
                "countryCode": "US"
            },
            {
                "name": "Moffat County",
                "countryCode": "US"
            },
            {
                "name": "Monte Vista",
                "countryCode": "US"
            },
            {
                "name": "Montezuma County",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Montrose County",
                "countryCode": "US"
            },
            {
                "name": "Monument",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Mountain Village",
                "countryCode": "US"
            },
            {
                "name": "Nederland",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "Niwot",
                "countryCode": "US"
            },
            {
                "name": "Northglenn",
                "countryCode": "US"
            },
            {
                "name": "Olathe",
                "countryCode": "US"
            },
            {
                "name": "Orchard City",
                "countryCode": "US"
            },
            {
                "name": "Orchard Mesa",
                "countryCode": "US"
            },
            {
                "name": "Ordway",
                "countryCode": "US"
            },
            {
                "name": "Otero County",
                "countryCode": "US"
            },
            {
                "name": "Ouray",
                "countryCode": "US"
            },
            {
                "name": "Ouray County",
                "countryCode": "US"
            },
            {
                "name": "Pagosa Springs",
                "countryCode": "US"
            },
            {
                "name": "Palisade",
                "countryCode": "US"
            },
            {
                "name": "Palmer Lake",
                "countryCode": "US"
            },
            {
                "name": "Paonia",
                "countryCode": "US"
            },
            {
                "name": "Parachute",
                "countryCode": "US"
            },
            {
                "name": "Park County",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Penrose",
                "countryCode": "US"
            },
            {
                "name": "Perry Park",
                "countryCode": "US"
            },
            {
                "name": "Phillips County",
                "countryCode": "US"
            },
            {
                "name": "Pitkin County",
                "countryCode": "US"
            },
            {
                "name": "Platteville",
                "countryCode": "US"
            },
            {
                "name": "Ponderosa Park",
                "countryCode": "US"
            },
            {
                "name": "Prowers County",
                "countryCode": "US"
            },
            {
                "name": "Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Pueblo County",
                "countryCode": "US"
            },
            {
                "name": "Pueblo West",
                "countryCode": "US"
            },
            {
                "name": "Rangely",
                "countryCode": "US"
            },
            {
                "name": "Redlands",
                "countryCode": "US"
            },
            {
                "name": "Rifle",
                "countryCode": "US"
            },
            {
                "name": "Rio Blanco County",
                "countryCode": "US"
            },
            {
                "name": "Rio Grande County",
                "countryCode": "US"
            },
            {
                "name": "Rocky Ford",
                "countryCode": "US"
            },
            {
                "name": "Routt County",
                "countryCode": "US"
            },
            {
                "name": "Roxborough Park",
                "countryCode": "US"
            },
            {
                "name": "Saguache",
                "countryCode": "US"
            },
            {
                "name": "Saguache County",
                "countryCode": "US"
            },
            {
                "name": "Salida",
                "countryCode": "US"
            },
            {
                "name": "San Juan County",
                "countryCode": "US"
            },
            {
                "name": "San Luis",
                "countryCode": "US"
            },
            {
                "name": "San Miguel County",
                "countryCode": "US"
            },
            {
                "name": "Security-Widefield",
                "countryCode": "US"
            },
            {
                "name": "Sedgwick County",
                "countryCode": "US"
            },
            {
                "name": "Severance",
                "countryCode": "US"
            },
            {
                "name": "Shaw Heights",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sherrelwood",
                "countryCode": "US"
            },
            {
                "name": "Silt",
                "countryCode": "US"
            },
            {
                "name": "Silverthorne",
                "countryCode": "US"
            },
            {
                "name": "Silverton",
                "countryCode": "US"
            },
            {
                "name": "Snowmass Village",
                "countryCode": "US"
            },
            {
                "name": "Southglenn",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Steamboat Springs",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Stonegate",
                "countryCode": "US"
            },
            {
                "name": "Strasburg",
                "countryCode": "US"
            },
            {
                "name": "Stratmoor",
                "countryCode": "US"
            },
            {
                "name": "Summit County",
                "countryCode": "US"
            },
            {
                "name": "Superior",
                "countryCode": "US"
            },
            {
                "name": "Teller County",
                "countryCode": "US"
            },
            {
                "name": "Telluride",
                "countryCode": "US"
            },
            {
                "name": "The Pinery",
                "countryCode": "US"
            },
            {
                "name": "Thornton",
                "countryCode": "US"
            },
            {
                "name": "Todd Creek",
                "countryCode": "US"
            },
            {
                "name": "Towaoc",
                "countryCode": "US"
            },
            {
                "name": "Trinidad",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Upper Bear Creek",
                "countryCode": "US"
            },
            {
                "name": "Vail",
                "countryCode": "US"
            },
            {
                "name": "Walden",
                "countryCode": "US"
            },
            {
                "name": "Walsenburg",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Welby",
                "countryCode": "US"
            },
            {
                "name": "Weld County",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "West Pleasant View",
                "countryCode": "US"
            },
            {
                "name": "Westcliffe",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Wheat Ridge",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Woodland Park",
                "countryCode": "US"
            },
            {
                "name": "Woodmoor",
                "countryCode": "US"
            },
            {
                "name": "Wray",
                "countryCode": "US"
            },
            {
                "name": "Yuma",
                "countryCode": "US"
            },
            {
                "name": "Yuma County",
                "countryCode": "US"
            },
            {
                "name": "Ansonia",
                "countryCode": "US"
            },
            {
                "name": "Baltic",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Bethlehem Village",
                "countryCode": "US"
            },
            {
                "name": "Blue Hills",
                "countryCode": "US"
            },
            {
                "name": "Branford",
                "countryCode": "US"
            },
            {
                "name": "Branford Center",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Byram",
                "countryCode": "US"
            },
            {
                "name": "Canaan",
                "countryCode": "US"
            },
            {
                "name": "Canton Valley",
                "countryCode": "US"
            },
            {
                "name": "Central Waterford",
                "countryCode": "US"
            },
            {
                "name": "Cheshire",
                "countryCode": "US"
            },
            {
                "name": "Cheshire Village",
                "countryCode": "US"
            },
            {
                "name": "Chester Center",
                "countryCode": "US"
            },
            {
                "name": "City of Milford (balance)",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Colchester",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Conning Towers-Nautilus Park",
                "countryCode": "US"
            },
            {
                "name": "Cos Cob",
                "countryCode": "US"
            },
            {
                "name": "Coventry Lake",
                "countryCode": "US"
            },
            {
                "name": "Cromwell",
                "countryCode": "US"
            },
            {
                "name": "Crystal Lake",
                "countryCode": "US"
            },
            {
                "name": "Danbury",
                "countryCode": "US"
            },
            {
                "name": "Danielson",
                "countryCode": "US"
            },
            {
                "name": "Darien",
                "countryCode": "US"
            },
            {
                "name": "Deep River Center",
                "countryCode": "US"
            },
            {
                "name": "Derby",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "East Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "East Haddam",
                "countryCode": "US"
            },
            {
                "name": "East Hampton",
                "countryCode": "US"
            },
            {
                "name": "East Hartford",
                "countryCode": "US"
            },
            {
                "name": "East Haven",
                "countryCode": "US"
            },
            {
                "name": "East Norwalk",
                "countryCode": "US"
            },
            {
                "name": "East Windsor",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Ellington",
                "countryCode": "US"
            },
            {
                "name": "Enfield",
                "countryCode": "US"
            },
            {
                "name": "Essex Village",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairfield County",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Gales Ferry",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Glastonbury",
                "countryCode": "US"
            },
            {
                "name": "Glastonbury Center",
                "countryCode": "US"
            },
            {
                "name": "Glenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwich",
                "countryCode": "US"
            },
            {
                "name": "Groton",
                "countryCode": "US"
            },
            {
                "name": "Guilford",
                "countryCode": "US"
            },
            {
                "name": "Guilford Center",
                "countryCode": "US"
            },
            {
                "name": "Hamden",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hartford County",
                "countryCode": "US"
            },
            {
                "name": "Hazardville",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Heritage Village",
                "countryCode": "US"
            },
            {
                "name": "Higganum",
                "countryCode": "US"
            },
            {
                "name": "Jewett City",
                "countryCode": "US"
            },
            {
                "name": "Kensington",
                "countryCode": "US"
            },
            {
                "name": "Kent",
                "countryCode": "US"
            },
            {
                "name": "Killingly Center",
                "countryCode": "US"
            },
            {
                "name": "Lake Pocotopaug",
                "countryCode": "US"
            },
            {
                "name": "Ledyard",
                "countryCode": "US"
            },
            {
                "name": "Lisbon",
                "countryCode": "US"
            },
            {
                "name": "Litchfield",
                "countryCode": "US"
            },
            {
                "name": "Litchfield County",
                "countryCode": "US"
            },
            {
                "name": "Long Hill",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison Center",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Mansfield City",
                "countryCode": "US"
            },
            {
                "name": "Meriden",
                "countryCode": "US"
            },
            {
                "name": "Middlebury",
                "countryCode": "US"
            },
            {
                "name": "Middlesex County",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Montville Center",
                "countryCode": "US"
            },
            {
                "name": "Moodus",
                "countryCode": "US"
            },
            {
                "name": "Moosup",
                "countryCode": "US"
            },
            {
                "name": "Mystic",
                "countryCode": "US"
            },
            {
                "name": "Naugatuck",
                "countryCode": "US"
            },
            {
                "name": "New Britain",
                "countryCode": "US"
            },
            {
                "name": "New Canaan",
                "countryCode": "US"
            },
            {
                "name": "New Fairfield",
                "countryCode": "US"
            },
            {
                "name": "New Hartford Center",
                "countryCode": "US"
            },
            {
                "name": "New Haven",
                "countryCode": "US"
            },
            {
                "name": "New Haven County",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New London County",
                "countryCode": "US"
            },
            {
                "name": "New Milford",
                "countryCode": "US"
            },
            {
                "name": "New Preston",
                "countryCode": "US"
            },
            {
                "name": "Newington",
                "countryCode": "US"
            },
            {
                "name": "Newtown",
                "countryCode": "US"
            },
            {
                "name": "Niantic",
                "countryCode": "US"
            },
            {
                "name": "Noank",
                "countryCode": "US"
            },
            {
                "name": "North Branford",
                "countryCode": "US"
            },
            {
                "name": "North Granby",
                "countryCode": "US"
            },
            {
                "name": "North Grosvenor Dale",
                "countryCode": "US"
            },
            {
                "name": "North Haven",
                "countryCode": "US"
            },
            {
                "name": "North Stamford",
                "countryCode": "US"
            },
            {
                "name": "Northwest Harwinton",
                "countryCode": "US"
            },
            {
                "name": "Norwalk",
                "countryCode": "US"
            },
            {
                "name": "Norwich",
                "countryCode": "US"
            },
            {
                "name": "Oakville",
                "countryCode": "US"
            },
            {
                "name": "Old Greenwich",
                "countryCode": "US"
            },
            {
                "name": "Old Mystic",
                "countryCode": "US"
            },
            {
                "name": "Old Saybrook",
                "countryCode": "US"
            },
            {
                "name": "Old Saybrook Center",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Oxoboxo River",
                "countryCode": "US"
            },
            {
                "name": "Pawcatuck",
                "countryCode": "US"
            },
            {
                "name": "Pemberwick",
                "countryCode": "US"
            },
            {
                "name": "Plainfield",
                "countryCode": "US"
            },
            {
                "name": "Plainfield Village",
                "countryCode": "US"
            },
            {
                "name": "Plainville",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Poquonock Bridge",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Preston City",
                "countryCode": "US"
            },
            {
                "name": "Prospect",
                "countryCode": "US"
            },
            {
                "name": "Putnam",
                "countryCode": "US"
            },
            {
                "name": "Quinebaug",
                "countryCode": "US"
            },
            {
                "name": "Ridgefield",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Rockville",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salmon Brook",
                "countryCode": "US"
            },
            {
                "name": "Saybrook Manor",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Shelton",
                "countryCode": "US"
            },
            {
                "name": "Sherman",
                "countryCode": "US"
            },
            {
                "name": "Sherwood Manor",
                "countryCode": "US"
            },
            {
                "name": "Simsbury Center",
                "countryCode": "US"
            },
            {
                "name": "Somers",
                "countryCode": "US"
            },
            {
                "name": "South Coventry",
                "countryCode": "US"
            },
            {
                "name": "South Windham",
                "countryCode": "US"
            },
            {
                "name": "South Windsor",
                "countryCode": "US"
            },
            {
                "name": "South Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Southbury",
                "countryCode": "US"
            },
            {
                "name": "Southport",
                "countryCode": "US"
            },
            {
                "name": "Southwood Acres",
                "countryCode": "US"
            },
            {
                "name": "Stafford",
                "countryCode": "US"
            },
            {
                "name": "Stafford Springs",
                "countryCode": "US"
            },
            {
                "name": "Stamford",
                "countryCode": "US"
            },
            {
                "name": "Storrs",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Suffield Depot",
                "countryCode": "US"
            },
            {
                "name": "Tariffville",
                "countryCode": "US"
            },
            {
                "name": "Terramuggus",
                "countryCode": "US"
            },
            {
                "name": "Terryville",
                "countryCode": "US"
            },
            {
                "name": "Thomaston",
                "countryCode": "US"
            },
            {
                "name": "Thompson",
                "countryCode": "US"
            },
            {
                "name": "Thompsonville",
                "countryCode": "US"
            },
            {
                "name": "Tolland",
                "countryCode": "US"
            },
            {
                "name": "Tolland County",
                "countryCode": "US"
            },
            {
                "name": "Torrington",
                "countryCode": "US"
            },
            {
                "name": "Trumbull",
                "countryCode": "US"
            },
            {
                "name": "Uncasville",
                "countryCode": "US"
            },
            {
                "name": "Wallingford",
                "countryCode": "US"
            },
            {
                "name": "Wallingford Center",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Waterbury",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Wauregan",
                "countryCode": "US"
            },
            {
                "name": "Weatogue",
                "countryCode": "US"
            },
            {
                "name": "West Hartford",
                "countryCode": "US"
            },
            {
                "name": "West Haven",
                "countryCode": "US"
            },
            {
                "name": "West Simsbury",
                "countryCode": "US"
            },
            {
                "name": "West Torrington",
                "countryCode": "US"
            },
            {
                "name": "Westbrook Center",
                "countryCode": "US"
            },
            {
                "name": "Westport",
                "countryCode": "US"
            },
            {
                "name": "Wethersfield",
                "countryCode": "US"
            },
            {
                "name": "Willimantic",
                "countryCode": "US"
            },
            {
                "name": "Wilton",
                "countryCode": "US"
            },
            {
                "name": "Winchester Center",
                "countryCode": "US"
            },
            {
                "name": "Windham",
                "countryCode": "US"
            },
            {
                "name": "Windham County",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Windsor Locks",
                "countryCode": "US"
            },
            {
                "name": "Winsted",
                "countryCode": "US"
            },
            {
                "name": "Wolcott",
                "countryCode": "US"
            },
            {
                "name": "Woodbridge",
                "countryCode": "US"
            },
            {
                "name": "Woodbury",
                "countryCode": "US"
            },
            {
                "name": "Woodbury Center",
                "countryCode": "US"
            },
            {
                "name": "Woodmont",
                "countryCode": "US"
            },
            {
                "name": "Adams Morgan",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Chevy Chase",
                "countryCode": "US"
            },
            {
                "name": "Shaw",
                "countryCode": "US"
            },
            {
                "name": "Washington, D.C.",
                "countryCode": "US"
            },
            {
                "name": "Bear",
                "countryCode": "US"
            },
            {
                "name": "Bellefonte",
                "countryCode": "US"
            },
            {
                "name": "Bethany Beach",
                "countryCode": "US"
            },
            {
                "name": "Blades",
                "countryCode": "US"
            },
            {
                "name": "Bridgeville",
                "countryCode": "US"
            },
            {
                "name": "Brookside",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Cheswold",
                "countryCode": "US"
            },
            {
                "name": "Claymont",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Delaware City",
                "countryCode": "US"
            },
            {
                "name": "Delmar",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dover Base Housing",
                "countryCode": "US"
            },
            {
                "name": "Edgemoor",
                "countryCode": "US"
            },
            {
                "name": "Elsmere",
                "countryCode": "US"
            },
            {
                "name": "Felton",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Glasgow",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Harrington",
                "countryCode": "US"
            },
            {
                "name": "Highland Acres",
                "countryCode": "US"
            },
            {
                "name": "Hockessin",
                "countryCode": "US"
            },
            {
                "name": "Kent Acres",
                "countryCode": "US"
            },
            {
                "name": "Kent County",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Lewes",
                "countryCode": "US"
            },
            {
                "name": "Long Neck",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millsboro",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "New Castle County",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "North Star",
                "countryCode": "US"
            },
            {
                "name": "Ocean View",
                "countryCode": "US"
            },
            {
                "name": "Pike Creek",
                "countryCode": "US"
            },
            {
                "name": "Pike Creek Valley",
                "countryCode": "US"
            },
            {
                "name": "Rehoboth Beach",
                "countryCode": "US"
            },
            {
                "name": "Rising Sun-Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Riverview",
                "countryCode": "US"
            },
            {
                "name": "Rodney Village",
                "countryCode": "US"
            },
            {
                "name": "Seaford",
                "countryCode": "US"
            },
            {
                "name": "Selbyville",
                "countryCode": "US"
            },
            {
                "name": "Smyrna",
                "countryCode": "US"
            },
            {
                "name": "Sussex County",
                "countryCode": "US"
            },
            {
                "name": "Townsend",
                "countryCode": "US"
            },
            {
                "name": "Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Wilmington Manor",
                "countryCode": "US"
            },
            {
                "name": "Woodside East",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Alachua",
                "countryCode": "US"
            },
            {
                "name": "Alachua County",
                "countryCode": "US"
            },
            {
                "name": "Alafaya",
                "countryCode": "US"
            },
            {
                "name": "Allapattah",
                "countryCode": "US"
            },
            {
                "name": "Altamonte Springs",
                "countryCode": "US"
            },
            {
                "name": "Alturas",
                "countryCode": "US"
            },
            {
                "name": "Alva",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Anna Maria",
                "countryCode": "US"
            },
            {
                "name": "Apalachicola",
                "countryCode": "US"
            },
            {
                "name": "Apollo Beach",
                "countryCode": "US"
            },
            {
                "name": "Apopka",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Archer",
                "countryCode": "US"
            },
            {
                "name": "Asbury Lake",
                "countryCode": "US"
            },
            {
                "name": "Astatula",
                "countryCode": "US"
            },
            {
                "name": "Astor",
                "countryCode": "US"
            },
            {
                "name": "Atlantic Beach",
                "countryCode": "US"
            },
            {
                "name": "Atlantis",
                "countryCode": "US"
            },
            {
                "name": "Auburndale",
                "countryCode": "US"
            },
            {
                "name": "Aventura",
                "countryCode": "US"
            },
            {
                "name": "Avon Park",
                "countryCode": "US"
            },
            {
                "name": "Azalea Park",
                "countryCode": "US"
            },
            {
                "name": "Babson Park",
                "countryCode": "US"
            },
            {
                "name": "Bagdad",
                "countryCode": "US"
            },
            {
                "name": "Baker County",
                "countryCode": "US"
            },
            {
                "name": "Bal Harbour",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Balm",
                "countryCode": "US"
            },
            {
                "name": "Bartow",
                "countryCode": "US"
            },
            {
                "name": "Bay County",
                "countryCode": "US"
            },
            {
                "name": "Bay Harbor Islands",
                "countryCode": "US"
            },
            {
                "name": "Bay Hill",
                "countryCode": "US"
            },
            {
                "name": "Bay Pines",
                "countryCode": "US"
            },
            {
                "name": "Bayonet Point",
                "countryCode": "US"
            },
            {
                "name": "Bayshore Gardens",
                "countryCode": "US"
            },
            {
                "name": "Beacon Square",
                "countryCode": "US"
            },
            {
                "name": "Bee Ridge",
                "countryCode": "US"
            },
            {
                "name": "Bellair-Meadowbrook Terrace",
                "countryCode": "US"
            },
            {
                "name": "Belle Glade",
                "countryCode": "US"
            },
            {
                "name": "Belle Glade Camp",
                "countryCode": "US"
            },
            {
                "name": "Belle Isle",
                "countryCode": "US"
            },
            {
                "name": "Belleair",
                "countryCode": "US"
            },
            {
                "name": "Belleair Beach",
                "countryCode": "US"
            },
            {
                "name": "Belleair Bluffs",
                "countryCode": "US"
            },
            {
                "name": "Belleview",
                "countryCode": "US"
            },
            {
                "name": "Bellview",
                "countryCode": "US"
            },
            {
                "name": "Beverly Hills",
                "countryCode": "US"
            },
            {
                "name": "Big Coppitt Key",
                "countryCode": "US"
            },
            {
                "name": "Big Pine Key",
                "countryCode": "US"
            },
            {
                "name": "Biscayne Park",
                "countryCode": "US"
            },
            {
                "name": "Bithlo",
                "countryCode": "US"
            },
            {
                "name": "Black Diamond",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Blountstown",
                "countryCode": "US"
            },
            {
                "name": "Boca Del Mar",
                "countryCode": "US"
            },
            {
                "name": "Boca Pointe",
                "countryCode": "US"
            },
            {
                "name": "Boca Raton",
                "countryCode": "US"
            },
            {
                "name": "Bokeelia",
                "countryCode": "US"
            },
            {
                "name": "Bonifay",
                "countryCode": "US"
            },
            {
                "name": "Bonita Springs",
                "countryCode": "US"
            },
            {
                "name": "Boulevard Gardens",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Boyette",
                "countryCode": "US"
            },
            {
                "name": "Boynton Beach",
                "countryCode": "US"
            },
            {
                "name": "Bradenton",
                "countryCode": "US"
            },
            {
                "name": "Bradenton Beach",
                "countryCode": "US"
            },
            {
                "name": "Bradford County",
                "countryCode": "US"
            },
            {
                "name": "Brandon",
                "countryCode": "US"
            },
            {
                "name": "Brent",
                "countryCode": "US"
            },
            {
                "name": "Brevard County",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Broadview Park",
                "countryCode": "US"
            },
            {
                "name": "Bronson",
                "countryCode": "US"
            },
            {
                "name": "Brookridge",
                "countryCode": "US"
            },
            {
                "name": "Brooksville",
                "countryCode": "US"
            },
            {
                "name": "Broward County",
                "countryCode": "US"
            },
            {
                "name": "Broward Estates",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Buckhead Ridge",
                "countryCode": "US"
            },
            {
                "name": "Buckingham",
                "countryCode": "US"
            },
            {
                "name": "Buenaventura Lakes",
                "countryCode": "US"
            },
            {
                "name": "Bunche Park",
                "countryCode": "US"
            },
            {
                "name": "Bunnell",
                "countryCode": "US"
            },
            {
                "name": "Burnt Store Marina",
                "countryCode": "US"
            },
            {
                "name": "Bushnell",
                "countryCode": "US"
            },
            {
                "name": "Butler Beach",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Callahan",
                "countryCode": "US"
            },
            {
                "name": "Callaway",
                "countryCode": "US"
            },
            {
                "name": "Campbell",
                "countryCode": "US"
            },
            {
                "name": "Cantonment",
                "countryCode": "US"
            },
            {
                "name": "Cape Canaveral",
                "countryCode": "US"
            },
            {
                "name": "Cape Coral",
                "countryCode": "US"
            },
            {
                "name": "Carol City",
                "countryCode": "US"
            },
            {
                "name": "Carrabelle",
                "countryCode": "US"
            },
            {
                "name": "Carrollwood",
                "countryCode": "US"
            },
            {
                "name": "Carrollwood Village",
                "countryCode": "US"
            },
            {
                "name": "Carver Ranches",
                "countryCode": "US"
            },
            {
                "name": "Casselberry",
                "countryCode": "US"
            },
            {
                "name": "Cedar Grove",
                "countryCode": "US"
            },
            {
                "name": "Celebration",
                "countryCode": "US"
            },
            {
                "name": "Center Hill",
                "countryCode": "US"
            },
            {
                "name": "Century",
                "countryCode": "US"
            },
            {
                "name": "Charlotte County",
                "countryCode": "US"
            },
            {
                "name": "Charlotte Harbor",
                "countryCode": "US"
            },
            {
                "name": "Charlotte Park",
                "countryCode": "US"
            },
            {
                "name": "Chattahoochee",
                "countryCode": "US"
            },
            {
                "name": "Cheval",
                "countryCode": "US"
            },
            {
                "name": "Chiefland",
                "countryCode": "US"
            },
            {
                "name": "Chipley",
                "countryCode": "US"
            },
            {
                "name": "Christmas",
                "countryCode": "US"
            },
            {
                "name": "Chuluota",
                "countryCode": "US"
            },
            {
                "name": "Citra",
                "countryCode": "US"
            },
            {
                "name": "Citrus County",
                "countryCode": "US"
            },
            {
                "name": "Citrus Hills",
                "countryCode": "US"
            },
            {
                "name": "Citrus Park",
                "countryCode": "US"
            },
            {
                "name": "Citrus Ridge",
                "countryCode": "US"
            },
            {
                "name": "Citrus Springs",
                "countryCode": "US"
            },
            {
                "name": "Clarcona",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clearwater",
                "countryCode": "US"
            },
            {
                "name": "Clermont",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clewiston",
                "countryCode": "US"
            },
            {
                "name": "Cocoa",
                "countryCode": "US"
            },
            {
                "name": "Cocoa Beach",
                "countryCode": "US"
            },
            {
                "name": "Cocoa West",
                "countryCode": "US"
            },
            {
                "name": "Coconut Creek",
                "countryCode": "US"
            },
            {
                "name": "Coconut Grove",
                "countryCode": "US"
            },
            {
                "name": "Collier County",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Combee Settlement",
                "countryCode": "US"
            },
            {
                "name": "Connerton",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Cooper City",
                "countryCode": "US"
            },
            {
                "name": "Coral Gables",
                "countryCode": "US"
            },
            {
                "name": "Coral Springs",
                "countryCode": "US"
            },
            {
                "name": "Coral Terrace",
                "countryCode": "US"
            },
            {
                "name": "Cortez",
                "countryCode": "US"
            },
            {
                "name": "Country Club",
                "countryCode": "US"
            },
            {
                "name": "Country Walk",
                "countryCode": "US"
            },
            {
                "name": "Crawfordville",
                "countryCode": "US"
            },
            {
                "name": "Crescent City",
                "countryCode": "US"
            },
            {
                "name": "Crestview",
                "countryCode": "US"
            },
            {
                "name": "Crooked Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Cross City",
                "countryCode": "US"
            },
            {
                "name": "Crystal Lake",
                "countryCode": "US"
            },
            {
                "name": "Crystal River",
                "countryCode": "US"
            },
            {
                "name": "Crystal Springs",
                "countryCode": "US"
            },
            {
                "name": "Cudjoe Key",
                "countryCode": "US"
            },
            {
                "name": "Cutler",
                "countryCode": "US"
            },
            {
                "name": "Cutler Bay",
                "countryCode": "US"
            },
            {
                "name": "Cutler Ridge",
                "countryCode": "US"
            },
            {
                "name": "Cypress Gardens",
                "countryCode": "US"
            },
            {
                "name": "Cypress Lake",
                "countryCode": "US"
            },
            {
                "name": "Cypress Quarters",
                "countryCode": "US"
            },
            {
                "name": "Dade City",
                "countryCode": "US"
            },
            {
                "name": "Dade City North",
                "countryCode": "US"
            },
            {
                "name": "Dania Beach",
                "countryCode": "US"
            },
            {
                "name": "Davenport",
                "countryCode": "US"
            },
            {
                "name": "Davie",
                "countryCode": "US"
            },
            {
                "name": "Daytona Beach",
                "countryCode": "US"
            },
            {
                "name": "Daytona Beach Shores",
                "countryCode": "US"
            },
            {
                "name": "De Land Southwest",
                "countryCode": "US"
            },
            {
                "name": "De Leon Springs",
                "countryCode": "US"
            },
            {
                "name": "DeBary",
                "countryCode": "US"
            },
            {
                "name": "DeFuniak Springs",
                "countryCode": "US"
            },
            {
                "name": "DeLand",
                "countryCode": "US"
            },
            {
                "name": "DeSoto County",
                "countryCode": "US"
            },
            {
                "name": "Deerfield Beach",
                "countryCode": "US"
            },
            {
                "name": "Delray Beach",
                "countryCode": "US"
            },
            {
                "name": "Deltona",
                "countryCode": "US"
            },
            {
                "name": "Desoto Lakes",
                "countryCode": "US"
            },
            {
                "name": "Destin",
                "countryCode": "US"
            },
            {
                "name": "Dixie County",
                "countryCode": "US"
            },
            {
                "name": "Doctor Phillips",
                "countryCode": "US"
            },
            {
                "name": "Doral",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dundee",
                "countryCode": "US"
            },
            {
                "name": "Dunedin",
                "countryCode": "US"
            },
            {
                "name": "Dunnellon",
                "countryCode": "US"
            },
            {
                "name": "Duval County",
                "countryCode": "US"
            },
            {
                "name": "Eagle Lake",
                "countryCode": "US"
            },
            {
                "name": "East Bronson",
                "countryCode": "US"
            },
            {
                "name": "East Lake",
                "countryCode": "US"
            },
            {
                "name": "East Lake-Orient Park",
                "countryCode": "US"
            },
            {
                "name": "East Milton",
                "countryCode": "US"
            },
            {
                "name": "East Naples",
                "countryCode": "US"
            },
            {
                "name": "East Palatka",
                "countryCode": "US"
            },
            {
                "name": "East Pensacola Heights",
                "countryCode": "US"
            },
            {
                "name": "East Perrine",
                "countryCode": "US"
            },
            {
                "name": "Eastpoint",
                "countryCode": "US"
            },
            {
                "name": "Eatonville",
                "countryCode": "US"
            },
            {
                "name": "Edgewater",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Eglin Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Eglin Village",
                "countryCode": "US"
            },
            {
                "name": "Egypt Lake-Leto",
                "countryCode": "US"
            },
            {
                "name": "El Portal",
                "countryCode": "US"
            },
            {
                "name": "Elfers",
                "countryCode": "US"
            },
            {
                "name": "Ellenton",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Ensley",
                "countryCode": "US"
            },
            {
                "name": "Escambia County",
                "countryCode": "US"
            },
            {
                "name": "Estero",
                "countryCode": "US"
            },
            {
                "name": "Eustis",
                "countryCode": "US"
            },
            {
                "name": "Fairview Shores",
                "countryCode": "US"
            },
            {
                "name": "Feather Sound",
                "countryCode": "US"
            },
            {
                "name": "Fellsmere",
                "countryCode": "US"
            },
            {
                "name": "Fern Park",
                "countryCode": "US"
            },
            {
                "name": "Fernandina Beach",
                "countryCode": "US"
            },
            {
                "name": "Ferry Pass",
                "countryCode": "US"
            },
            {
                "name": "Fish Hawk",
                "countryCode": "US"
            },
            {
                "name": "Five Points",
                "countryCode": "US"
            },
            {
                "name": "Flagami",
                "countryCode": "US"
            },
            {
                "name": "Flagler Beach",
                "countryCode": "US"
            },
            {
                "name": "Flagler County",
                "countryCode": "US"
            },
            {
                "name": "Flagler Estates",
                "countryCode": "US"
            },
            {
                "name": "Fleming Island",
                "countryCode": "US"
            },
            {
                "name": "Floral City",
                "countryCode": "US"
            },
            {
                "name": "Florida City",
                "countryCode": "US"
            },
            {
                "name": "Florida Ridge",
                "countryCode": "US"
            },
            {
                "name": "Forest City",
                "countryCode": "US"
            },
            {
                "name": "Fort Lauderdale",
                "countryCode": "US"
            },
            {
                "name": "Fort Meade",
                "countryCode": "US"
            },
            {
                "name": "Fort Myers",
                "countryCode": "US"
            },
            {
                "name": "Fort Myers Beach",
                "countryCode": "US"
            },
            {
                "name": "Fort Myers Shores",
                "countryCode": "US"
            },
            {
                "name": "Fort Pierce",
                "countryCode": "US"
            },
            {
                "name": "Fort Pierce North",
                "countryCode": "US"
            },
            {
                "name": "Fort Pierce South",
                "countryCode": "US"
            },
            {
                "name": "Fort Walton Beach",
                "countryCode": "US"
            },
            {
                "name": "Fountainebleau",
                "countryCode": "US"
            },
            {
                "name": "Four Corners",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Frostproof",
                "countryCode": "US"
            },
            {
                "name": "Fruit Cove",
                "countryCode": "US"
            },
            {
                "name": "Fruitland Park",
                "countryCode": "US"
            },
            {
                "name": "Fruitville",
                "countryCode": "US"
            },
            {
                "name": "Fuller Heights",
                "countryCode": "US"
            },
            {
                "name": "Fussels Corner",
                "countryCode": "US"
            },
            {
                "name": "Gadsden County",
                "countryCode": "US"
            },
            {
                "name": "Gainesville",
                "countryCode": "US"
            },
            {
                "name": "Gandy",
                "countryCode": "US"
            },
            {
                "name": "Gateway",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Gibsonia",
                "countryCode": "US"
            },
            {
                "name": "Gibsonton",
                "countryCode": "US"
            },
            {
                "name": "Gifford",
                "countryCode": "US"
            },
            {
                "name": "Gilchrist County",
                "countryCode": "US"
            },
            {
                "name": "Glades County",
                "countryCode": "US"
            },
            {
                "name": "Gladeview",
                "countryCode": "US"
            },
            {
                "name": "Glencoe",
                "countryCode": "US"
            },
            {
                "name": "Glenvar Heights",
                "countryCode": "US"
            },
            {
                "name": "Golden Gate",
                "countryCode": "US"
            },
            {
                "name": "Golden Glades",
                "countryCode": "US"
            },
            {
                "name": "Goldenrod",
                "countryCode": "US"
            },
            {
                "name": "Gonzalez",
                "countryCode": "US"
            },
            {
                "name": "Gotha",
                "countryCode": "US"
            },
            {
                "name": "Goulding",
                "countryCode": "US"
            },
            {
                "name": "Goulds",
                "countryCode": "US"
            },
            {
                "name": "Graceville",
                "countryCode": "US"
            },
            {
                "name": "Grant-Valkaria",
                "countryCode": "US"
            },
            {
                "name": "Greater Northdale",
                "countryCode": "US"
            },
            {
                "name": "Green Cove Springs",
                "countryCode": "US"
            },
            {
                "name": "Greenacres City",
                "countryCode": "US"
            },
            {
                "name": "Greenbriar",
                "countryCode": "US"
            },
            {
                "name": "Gretna",
                "countryCode": "US"
            },
            {
                "name": "Grove City",
                "countryCode": "US"
            },
            {
                "name": "Groveland",
                "countryCode": "US"
            },
            {
                "name": "Gulf Breeze",
                "countryCode": "US"
            },
            {
                "name": "Gulf County",
                "countryCode": "US"
            },
            {
                "name": "Gulf Gate Estates",
                "countryCode": "US"
            },
            {
                "name": "Gulfport",
                "countryCode": "US"
            },
            {
                "name": "Haines City",
                "countryCode": "US"
            },
            {
                "name": "Hallandale Beach",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Harbor Bluffs",
                "countryCode": "US"
            },
            {
                "name": "Harbour Heights",
                "countryCode": "US"
            },
            {
                "name": "Hardee County",
                "countryCode": "US"
            },
            {
                "name": "Harlem",
                "countryCode": "US"
            },
            {
                "name": "Harlem Heights",
                "countryCode": "US"
            },
            {
                "name": "Havana",
                "countryCode": "US"
            },
            {
                "name": "Haverhill",
                "countryCode": "US"
            },
            {
                "name": "Hawthorne",
                "countryCode": "US"
            },
            {
                "name": "Heathrow",
                "countryCode": "US"
            },
            {
                "name": "Hendry County",
                "countryCode": "US"
            },
            {
                "name": "Heritage Pines",
                "countryCode": "US"
            },
            {
                "name": "Hernando",
                "countryCode": "US"
            },
            {
                "name": "Hernando Beach",
                "countryCode": "US"
            },
            {
                "name": "Hernando County",
                "countryCode": "US"
            },
            {
                "name": "Hialeah",
                "countryCode": "US"
            },
            {
                "name": "Hialeah Gardens",
                "countryCode": "US"
            },
            {
                "name": "High Point",
                "countryCode": "US"
            },
            {
                "name": "High Springs",
                "countryCode": "US"
            },
            {
                "name": "Highland Beach",
                "countryCode": "US"
            },
            {
                "name": "Highland City",
                "countryCode": "US"
            },
            {
                "name": "Highlands County",
                "countryCode": "US"
            },
            {
                "name": "Hiland Park",
                "countryCode": "US"
            },
            {
                "name": "Hill 'n Dale",
                "countryCode": "US"
            },
            {
                "name": "Hilliard",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro Beach",
                "countryCode": "US"
            },
            {
                "name": "Hillsborough County",
                "countryCode": "US"
            },
            {
                "name": "Hobe Sound",
                "countryCode": "US"
            },
            {
                "name": "Holden Heights",
                "countryCode": "US"
            },
            {
                "name": "Holiday",
                "countryCode": "US"
            },
            {
                "name": "Holley",
                "countryCode": "US"
            },
            {
                "name": "Holly Hill",
                "countryCode": "US"
            },
            {
                "name": "Hollywood",
                "countryCode": "US"
            },
            {
                "name": "Holmes Beach",
                "countryCode": "US"
            },
            {
                "name": "Holmes County",
                "countryCode": "US"
            },
            {
                "name": "Homestead",
                "countryCode": "US"
            },
            {
                "name": "Homosassa",
                "countryCode": "US"
            },
            {
                "name": "Homosassa Springs",
                "countryCode": "US"
            },
            {
                "name": "Horizon West",
                "countryCode": "US"
            },
            {
                "name": "Howey-in-the-Hills",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hunters Creek",
                "countryCode": "US"
            },
            {
                "name": "Hutchinson Island South",
                "countryCode": "US"
            },
            {
                "name": "Hypoluxo",
                "countryCode": "US"
            },
            {
                "name": "Immokalee",
                "countryCode": "US"
            },
            {
                "name": "Indialantic",
                "countryCode": "US"
            },
            {
                "name": "Indian Harbour Beach",
                "countryCode": "US"
            },
            {
                "name": "Indian River County",
                "countryCode": "US"
            },
            {
                "name": "Indian River Estates",
                "countryCode": "US"
            },
            {
                "name": "Indian River Shores",
                "countryCode": "US"
            },
            {
                "name": "Indian Rocks Beach",
                "countryCode": "US"
            },
            {
                "name": "Indian Shores",
                "countryCode": "US"
            },
            {
                "name": "Indiantown",
                "countryCode": "US"
            },
            {
                "name": "Inglis",
                "countryCode": "US"
            },
            {
                "name": "Interlachen",
                "countryCode": "US"
            },
            {
                "name": "Inverness",
                "countryCode": "US"
            },
            {
                "name": "Inverness Highlands North",
                "countryCode": "US"
            },
            {
                "name": "Inverness Highlands South",
                "countryCode": "US"
            },
            {
                "name": "Inwood",
                "countryCode": "US"
            },
            {
                "name": "Iona",
                "countryCode": "US"
            },
            {
                "name": "Islamorada",
                "countryCode": "US"
            },
            {
                "name": "Island Walk",
                "countryCode": "US"
            },
            {
                "name": "Isle of Normandy",
                "countryCode": "US"
            },
            {
                "name": "Ives Estates",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville Beach",
                "countryCode": "US"
            },
            {
                "name": "Jan-Phyl Village",
                "countryCode": "US"
            },
            {
                "name": "Jasmine Estates",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jensen Beach",
                "countryCode": "US"
            },
            {
                "name": "June Park",
                "countryCode": "US"
            },
            {
                "name": "Juno Beach",
                "countryCode": "US"
            },
            {
                "name": "Jupiter",
                "countryCode": "US"
            },
            {
                "name": "Kathleen",
                "countryCode": "US"
            },
            {
                "name": "Kendale Lakes",
                "countryCode": "US"
            },
            {
                "name": "Kendall",
                "countryCode": "US"
            },
            {
                "name": "Kendall Green",
                "countryCode": "US"
            },
            {
                "name": "Kendall West",
                "countryCode": "US"
            },
            {
                "name": "Kenneth City",
                "countryCode": "US"
            },
            {
                "name": "Kensington Park",
                "countryCode": "US"
            },
            {
                "name": "Key Biscayne",
                "countryCode": "US"
            },
            {
                "name": "Key Largo",
                "countryCode": "US"
            },
            {
                "name": "Key Vista",
                "countryCode": "US"
            },
            {
                "name": "Key West",
                "countryCode": "US"
            },
            {
                "name": "Keystone",
                "countryCode": "US"
            },
            {
                "name": "Keystone Heights",
                "countryCode": "US"
            },
            {
                "name": "Kings Point",
                "countryCode": "US"
            },
            {
                "name": "Kissimmee",
                "countryCode": "US"
            },
            {
                "name": "LaBelle",
                "countryCode": "US"
            },
            {
                "name": "Lacoochee",
                "countryCode": "US"
            },
            {
                "name": "Lady Lake",
                "countryCode": "US"
            },
            {
                "name": "Lafayette County",
                "countryCode": "US"
            },
            {
                "name": "Laguna Beach",
                "countryCode": "US"
            },
            {
                "name": "Lake Alfred",
                "countryCode": "US"
            },
            {
                "name": "Lake Belvedere Estates",
                "countryCode": "US"
            },
            {
                "name": "Lake Butler",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake Clarke Shores",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Forest",
                "countryCode": "US"
            },
            {
                "name": "Lake Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Lake Helen",
                "countryCode": "US"
            },
            {
                "name": "Lake Lorraine",
                "countryCode": "US"
            },
            {
                "name": "Lake Lucerne",
                "countryCode": "US"
            },
            {
                "name": "Lake Mack-Forest Hills",
                "countryCode": "US"
            },
            {
                "name": "Lake Magdalene",
                "countryCode": "US"
            },
            {
                "name": "Lake Mary",
                "countryCode": "US"
            },
            {
                "name": "Lake Panasoffkee",
                "countryCode": "US"
            },
            {
                "name": "Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Lake Placid",
                "countryCode": "US"
            },
            {
                "name": "Lake Sarasota",
                "countryCode": "US"
            },
            {
                "name": "Lake Wales",
                "countryCode": "US"
            },
            {
                "name": "Lake Worth",
                "countryCode": "US"
            },
            {
                "name": "Lake Worth Corridor",
                "countryCode": "US"
            },
            {
                "name": "Lakeland",
                "countryCode": "US"
            },
            {
                "name": "Lakeland Highlands",
                "countryCode": "US"
            },
            {
                "name": "Lakes by the Bay",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lakewood Park",
                "countryCode": "US"
            },
            {
                "name": "Land O' Lakes",
                "countryCode": "US"
            },
            {
                "name": "Lantana",
                "countryCode": "US"
            },
            {
                "name": "Largo",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale Lakes",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale-by-the-Sea",
                "countryCode": "US"
            },
            {
                "name": "Lauderhill",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Lealman",
                "countryCode": "US"
            },
            {
                "name": "Lecanto",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leesburg",
                "countryCode": "US"
            },
            {
                "name": "Lehigh Acres",
                "countryCode": "US"
            },
            {
                "name": "Leisure City",
                "countryCode": "US"
            },
            {
                "name": "Lely",
                "countryCode": "US"
            },
            {
                "name": "Lely Resort",
                "countryCode": "US"
            },
            {
                "name": "Leon County",
                "countryCode": "US"
            },
            {
                "name": "Levy County",
                "countryCode": "US"
            },
            {
                "name": "Liberty County",
                "countryCode": "US"
            },
            {
                "name": "Lighthouse Point",
                "countryCode": "US"
            },
            {
                "name": "Limestone Creek",
                "countryCode": "US"
            },
            {
                "name": "Live Oak",
                "countryCode": "US"
            },
            {
                "name": "Lochmoor Waterway Estates",
                "countryCode": "US"
            },
            {
                "name": "Lockhart",
                "countryCode": "US"
            },
            {
                "name": "Longboat Key",
                "countryCode": "US"
            },
            {
                "name": "Longwood",
                "countryCode": "US"
            },
            {
                "name": "Loughman",
                "countryCode": "US"
            },
            {
                "name": "Lower Grand Lagoon",
                "countryCode": "US"
            },
            {
                "name": "Loxahatchee Groves",
                "countryCode": "US"
            },
            {
                "name": "Lutz",
                "countryCode": "US"
            },
            {
                "name": "Lynn Haven",
                "countryCode": "US"
            },
            {
                "name": "Macclenny",
                "countryCode": "US"
            },
            {
                "name": "Madeira Beach",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Maitland",
                "countryCode": "US"
            },
            {
                "name": "Malabar",
                "countryCode": "US"
            },
            {
                "name": "Malone",
                "countryCode": "US"
            },
            {
                "name": "Manasota Key",
                "countryCode": "US"
            },
            {
                "name": "Manatee County",
                "countryCode": "US"
            },
            {
                "name": "Manatee Road",
                "countryCode": "US"
            },
            {
                "name": "Mango",
                "countryCode": "US"
            },
            {
                "name": "Mangonia Park",
                "countryCode": "US"
            },
            {
                "name": "Marathon",
                "countryCode": "US"
            },
            {
                "name": "Marco",
                "countryCode": "US"
            },
            {
                "name": "Marco Island",
                "countryCode": "US"
            },
            {
                "name": "Margate",
                "countryCode": "US"
            },
            {
                "name": "Marianna",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Mary Esther",
                "countryCode": "US"
            },
            {
                "name": "Masaryktown",
                "countryCode": "US"
            },
            {
                "name": "Mascotte",
                "countryCode": "US"
            },
            {
                "name": "Mayo",
                "countryCode": "US"
            },
            {
                "name": "McGregor",
                "countryCode": "US"
            },
            {
                "name": "Meadow Oaks",
                "countryCode": "US"
            },
            {
                "name": "Meadow Woods",
                "countryCode": "US"
            },
            {
                "name": "Medulla",
                "countryCode": "US"
            },
            {
                "name": "Melbourne",
                "countryCode": "US"
            },
            {
                "name": "Melbourne Beach",
                "countryCode": "US"
            },
            {
                "name": "Melrose Park",
                "countryCode": "US"
            },
            {
                "name": "Memphis",
                "countryCode": "US"
            },
            {
                "name": "Merritt Island",
                "countryCode": "US"
            },
            {
                "name": "Mexico Beach",
                "countryCode": "US"
            },
            {
                "name": "Miami",
                "countryCode": "US"
            },
            {
                "name": "Miami Beach",
                "countryCode": "US"
            },
            {
                "name": "Miami Gardens",
                "countryCode": "US"
            },
            {
                "name": "Miami Lakes",
                "countryCode": "US"
            },
            {
                "name": "Miami Shores",
                "countryCode": "US"
            },
            {
                "name": "Miami Springs",
                "countryCode": "US"
            },
            {
                "name": "Miami-Dade County",
                "countryCode": "US"
            },
            {
                "name": "Micco",
                "countryCode": "US"
            },
            {
                "name": "Middleburg",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Mims",
                "countryCode": "US"
            },
            {
                "name": "Minneola",
                "countryCode": "US"
            },
            {
                "name": "Miramar",
                "countryCode": "US"
            },
            {
                "name": "Miramar Beach",
                "countryCode": "US"
            },
            {
                "name": "Molino",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Montverde",
                "countryCode": "US"
            },
            {
                "name": "Moore Haven",
                "countryCode": "US"
            },
            {
                "name": "Mount Dora",
                "countryCode": "US"
            },
            {
                "name": "Mount Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Mulberry",
                "countryCode": "US"
            },
            {
                "name": "Myrtle Grove",
                "countryCode": "US"
            },
            {
                "name": "Naples",
                "countryCode": "US"
            },
            {
                "name": "Naples Manor",
                "countryCode": "US"
            },
            {
                "name": "Naples Park",
                "countryCode": "US"
            },
            {
                "name": "Naranja",
                "countryCode": "US"
            },
            {
                "name": "Nassau County",
                "countryCode": "US"
            },
            {
                "name": "Nassau Village-Ratliff",
                "countryCode": "US"
            },
            {
                "name": "Navarre",
                "countryCode": "US"
            },
            {
                "name": "Neptune Beach",
                "countryCode": "US"
            },
            {
                "name": "New Port Richey",
                "countryCode": "US"
            },
            {
                "name": "New Port Richey East",
                "countryCode": "US"
            },
            {
                "name": "New Smyrna Beach",
                "countryCode": "US"
            },
            {
                "name": "Newberry",
                "countryCode": "US"
            },
            {
                "name": "Niceville",
                "countryCode": "US"
            },
            {
                "name": "Nocatee",
                "countryCode": "US"
            },
            {
                "name": "Nokomis",
                "countryCode": "US"
            },
            {
                "name": "Norland",
                "countryCode": "US"
            },
            {
                "name": "North Andrews Gardens",
                "countryCode": "US"
            },
            {
                "name": "North Bay Village",
                "countryCode": "US"
            },
            {
                "name": "North Brooksville",
                "countryCode": "US"
            },
            {
                "name": "North DeLand",
                "countryCode": "US"
            },
            {
                "name": "North Fort Myers",
                "countryCode": "US"
            },
            {
                "name": "North Key Largo",
                "countryCode": "US"
            },
            {
                "name": "North Lauderdale",
                "countryCode": "US"
            },
            {
                "name": "North Miami",
                "countryCode": "US"
            },
            {
                "name": "North Miami Beach",
                "countryCode": "US"
            },
            {
                "name": "North Palm Beach",
                "countryCode": "US"
            },
            {
                "name": "North Port",
                "countryCode": "US"
            },
            {
                "name": "North Redington Beach",
                "countryCode": "US"
            },
            {
                "name": "North River Shores",
                "countryCode": "US"
            },
            {
                "name": "North Sarasota",
                "countryCode": "US"
            },
            {
                "name": "North Weeki Wachee",
                "countryCode": "US"
            },
            {
                "name": "Northdale",
                "countryCode": "US"
            },
            {
                "name": "Oak Hill",
                "countryCode": "US"
            },
            {
                "name": "Oak Ridge",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oakland Park",
                "countryCode": "US"
            },
            {
                "name": "Oakleaf Plantation",
                "countryCode": "US"
            },
            {
                "name": "Ocala",
                "countryCode": "US"
            },
            {
                "name": "Ocean City",
                "countryCode": "US"
            },
            {
                "name": "Ocean Ridge",
                "countryCode": "US"
            },
            {
                "name": "Ocoee",
                "countryCode": "US"
            },
            {
                "name": "Odessa",
                "countryCode": "US"
            },
            {
                "name": "Ojus",
                "countryCode": "US"
            },
            {
                "name": "Okaloosa County",
                "countryCode": "US"
            },
            {
                "name": "Okeechobee",
                "countryCode": "US"
            },
            {
                "name": "Okeechobee County",
                "countryCode": "US"
            },
            {
                "name": "Oldsmar",
                "countryCode": "US"
            },
            {
                "name": "Olga",
                "countryCode": "US"
            },
            {
                "name": "Olympia Heights",
                "countryCode": "US"
            },
            {
                "name": "Opa-locka",
                "countryCode": "US"
            },
            {
                "name": "Orange City",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orange Park",
                "countryCode": "US"
            },
            {
                "name": "Orangetree",
                "countryCode": "US"
            },
            {
                "name": "Oriole Beach",
                "countryCode": "US"
            },
            {
                "name": "Orlando",
                "countryCode": "US"
            },
            {
                "name": "Orlovista",
                "countryCode": "US"
            },
            {
                "name": "Ormond Beach",
                "countryCode": "US"
            },
            {
                "name": "Ormond-by-the-Sea",
                "countryCode": "US"
            },
            {
                "name": "Osceola County",
                "countryCode": "US"
            },
            {
                "name": "Osprey",
                "countryCode": "US"
            },
            {
                "name": "Oviedo",
                "countryCode": "US"
            },
            {
                "name": "Pace",
                "countryCode": "US"
            },
            {
                "name": "Pahokee",
                "countryCode": "US"
            },
            {
                "name": "Palatka",
                "countryCode": "US"
            },
            {
                "name": "Palm Aire",
                "countryCode": "US"
            },
            {
                "name": "Palm Bay",
                "countryCode": "US"
            },
            {
                "name": "Palm Beach",
                "countryCode": "US"
            },
            {
                "name": "Palm Beach County",
                "countryCode": "US"
            },
            {
                "name": "Palm Beach Gardens",
                "countryCode": "US"
            },
            {
                "name": "Palm Beach Shores",
                "countryCode": "US"
            },
            {
                "name": "Palm City",
                "countryCode": "US"
            },
            {
                "name": "Palm Coast",
                "countryCode": "US"
            },
            {
                "name": "Palm Harbor",
                "countryCode": "US"
            },
            {
                "name": "Palm River-Clair Mel",
                "countryCode": "US"
            },
            {
                "name": "Palm Springs",
                "countryCode": "US"
            },
            {
                "name": "Palm Springs North",
                "countryCode": "US"
            },
            {
                "name": "Palm Valley",
                "countryCode": "US"
            },
            {
                "name": "Palmetto",
                "countryCode": "US"
            },
            {
                "name": "Palmetto Bay",
                "countryCode": "US"
            },
            {
                "name": "Palmetto Estates",
                "countryCode": "US"
            },
            {
                "name": "Palmona Park",
                "countryCode": "US"
            },
            {
                "name": "Panama City",
                "countryCode": "US"
            },
            {
                "name": "Panama City Beach",
                "countryCode": "US"
            },
            {
                "name": "Paradise Heights",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Parkland",
                "countryCode": "US"
            },
            {
                "name": "Pasadena Hills",
                "countryCode": "US"
            },
            {
                "name": "Pasco County",
                "countryCode": "US"
            },
            {
                "name": "Pebble Creek",
                "countryCode": "US"
            },
            {
                "name": "Pelican Bay",
                "countryCode": "US"
            },
            {
                "name": "Pembroke Park",
                "countryCode": "US"
            },
            {
                "name": "Pembroke Pines",
                "countryCode": "US"
            },
            {
                "name": "Pensacola",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Pierson",
                "countryCode": "US"
            },
            {
                "name": "Pine Castle",
                "countryCode": "US"
            },
            {
                "name": "Pine Hills",
                "countryCode": "US"
            },
            {
                "name": "Pine Island Center",
                "countryCode": "US"
            },
            {
                "name": "Pine Island Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pine Manor",
                "countryCode": "US"
            },
            {
                "name": "Pine Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pinecrest",
                "countryCode": "US"
            },
            {
                "name": "Pinellas County",
                "countryCode": "US"
            },
            {
                "name": "Pinellas Park",
                "countryCode": "US"
            },
            {
                "name": "Pinewood",
                "countryCode": "US"
            },
            {
                "name": "Placid Lakes",
                "countryCode": "US"
            },
            {
                "name": "Plant City",
                "countryCode": "US"
            },
            {
                "name": "Plantation",
                "countryCode": "US"
            },
            {
                "name": "Plantation Mobile Home Park",
                "countryCode": "US"
            },
            {
                "name": "Poinciana",
                "countryCode": "US"
            },
            {
                "name": "Point Baker",
                "countryCode": "US"
            },
            {
                "name": "Polk City",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Pompano Beach",
                "countryCode": "US"
            },
            {
                "name": "Pompano Beach Highlands",
                "countryCode": "US"
            },
            {
                "name": "Ponce Inlet",
                "countryCode": "US"
            },
            {
                "name": "Ponte Vedra Beach",
                "countryCode": "US"
            },
            {
                "name": "Port Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Port LaBelle",
                "countryCode": "US"
            },
            {
                "name": "Port Orange",
                "countryCode": "US"
            },
            {
                "name": "Port Richey",
                "countryCode": "US"
            },
            {
                "name": "Port Saint Joe",
                "countryCode": "US"
            },
            {
                "name": "Port Saint John",
                "countryCode": "US"
            },
            {
                "name": "Port Saint Lucie",
                "countryCode": "US"
            },
            {
                "name": "Port Salerno",
                "countryCode": "US"
            },
            {
                "name": "Pretty Bayou",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Progress Village",
                "countryCode": "US"
            },
            {
                "name": "Punta Gorda",
                "countryCode": "US"
            },
            {
                "name": "Punta Gorda Isles",
                "countryCode": "US"
            },
            {
                "name": "Punta Rassa",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Quail Ridge",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Redington Beach",
                "countryCode": "US"
            },
            {
                "name": "Redington Shores",
                "countryCode": "US"
            },
            {
                "name": "Richmond Heights",
                "countryCode": "US"
            },
            {
                "name": "Richmond West",
                "countryCode": "US"
            },
            {
                "name": "Ridge Manor",
                "countryCode": "US"
            },
            {
                "name": "Ridge Wood Heights",
                "countryCode": "US"
            },
            {
                "name": "Ridgecrest",
                "countryCode": "US"
            },
            {
                "name": "River Park",
                "countryCode": "US"
            },
            {
                "name": "Riverview",
                "countryCode": "US"
            },
            {
                "name": "Riviera Beach",
                "countryCode": "US"
            },
            {
                "name": "Rock Island",
                "countryCode": "US"
            },
            {
                "name": "Rockledge",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt Gardens",
                "countryCode": "US"
            },
            {
                "name": "Roseland",
                "countryCode": "US"
            },
            {
                "name": "Rotonda West",
                "countryCode": "US"
            },
            {
                "name": "Royal Palm Beach",
                "countryCode": "US"
            },
            {
                "name": "Royal Palm Estates",
                "countryCode": "US"
            },
            {
                "name": "Ruskin",
                "countryCode": "US"
            },
            {
                "name": "Safety Harbor",
                "countryCode": "US"
            },
            {
                "name": "Saint Augustine",
                "countryCode": "US"
            },
            {
                "name": "Saint Augustine Beach",
                "countryCode": "US"
            },
            {
                "name": "Saint Augustine Shores",
                "countryCode": "US"
            },
            {
                "name": "Saint Augustine South",
                "countryCode": "US"
            },
            {
                "name": "Saint Cloud",
                "countryCode": "US"
            },
            {
                "name": "Saint George",
                "countryCode": "US"
            },
            {
                "name": "Saint James City",
                "countryCode": "US"
            },
            {
                "name": "Saint Johns County",
                "countryCode": "US"
            },
            {
                "name": "Saint Leo",
                "countryCode": "US"
            },
            {
                "name": "Saint Lucie County",
                "countryCode": "US"
            },
            {
                "name": "Saint Pete Beach",
                "countryCode": "US"
            },
            {
                "name": "Samoset",
                "countryCode": "US"
            },
            {
                "name": "Samsula-Spruce Creek",
                "countryCode": "US"
            },
            {
                "name": "San Antonio",
                "countryCode": "US"
            },
            {
                "name": "San Carlos Park",
                "countryCode": "US"
            },
            {
                "name": "Sandalfoot Cove",
                "countryCode": "US"
            },
            {
                "name": "Sanford",
                "countryCode": "US"
            },
            {
                "name": "Sanibel",
                "countryCode": "US"
            },
            {
                "name": "Santa Rosa County",
                "countryCode": "US"
            },
            {
                "name": "Sarasota",
                "countryCode": "US"
            },
            {
                "name": "Sarasota County",
                "countryCode": "US"
            },
            {
                "name": "Sarasota Springs",
                "countryCode": "US"
            },
            {
                "name": "Satellite Beach",
                "countryCode": "US"
            },
            {
                "name": "Sawgrass",
                "countryCode": "US"
            },
            {
                "name": "Schall Circle",
                "countryCode": "US"
            },
            {
                "name": "Scott Lake",
                "countryCode": "US"
            },
            {
                "name": "Seaside",
                "countryCode": "US"
            },
            {
                "name": "Sebastian",
                "countryCode": "US"
            },
            {
                "name": "Sebring",
                "countryCode": "US"
            },
            {
                "name": "Seffner",
                "countryCode": "US"
            },
            {
                "name": "Seminole",
                "countryCode": "US"
            },
            {
                "name": "Seminole County",
                "countryCode": "US"
            },
            {
                "name": "Seminole Manor",
                "countryCode": "US"
            },
            {
                "name": "Sewall's Point",
                "countryCode": "US"
            },
            {
                "name": "Shady Hills",
                "countryCode": "US"
            },
            {
                "name": "Sharpes",
                "countryCode": "US"
            },
            {
                "name": "Siesta Key",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Silver Springs",
                "countryCode": "US"
            },
            {
                "name": "Silver Springs Shores",
                "countryCode": "US"
            },
            {
                "name": "Sky Lake",
                "countryCode": "US"
            },
            {
                "name": "Sneads",
                "countryCode": "US"
            },
            {
                "name": "South Apopka",
                "countryCode": "US"
            },
            {
                "name": "South Bay",
                "countryCode": "US"
            },
            {
                "name": "South Beach",
                "countryCode": "US"
            },
            {
                "name": "South Bradenton",
                "countryCode": "US"
            },
            {
                "name": "South Brooksville",
                "countryCode": "US"
            },
            {
                "name": "South Daytona",
                "countryCode": "US"
            },
            {
                "name": "South Gate Ridge",
                "countryCode": "US"
            },
            {
                "name": "South Highpoint",
                "countryCode": "US"
            },
            {
                "name": "South Miami",
                "countryCode": "US"
            },
            {
                "name": "South Miami Heights",
                "countryCode": "US"
            },
            {
                "name": "South Palm Beach",
                "countryCode": "US"
            },
            {
                "name": "South Pasadena",
                "countryCode": "US"
            },
            {
                "name": "South Patrick Shores",
                "countryCode": "US"
            },
            {
                "name": "South Sarasota",
                "countryCode": "US"
            },
            {
                "name": "South Venice",
                "countryCode": "US"
            },
            {
                "name": "Southchase",
                "countryCode": "US"
            },
            {
                "name": "Southeast Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Southgate",
                "countryCode": "US"
            },
            {
                "name": "Southwest Ranches",
                "countryCode": "US"
            },
            {
                "name": "Spring Hill",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "St. Johns",
                "countryCode": "US"
            },
            {
                "name": "St. Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Starke",
                "countryCode": "US"
            },
            {
                "name": "Steinhatchee",
                "countryCode": "US"
            },
            {
                "name": "Stock Island",
                "countryCode": "US"
            },
            {
                "name": "Stuart",
                "countryCode": "US"
            },
            {
                "name": "Sugarmill Woods",
                "countryCode": "US"
            },
            {
                "name": "Sumter County",
                "countryCode": "US"
            },
            {
                "name": "Sun City Center",
                "countryCode": "US"
            },
            {
                "name": "Suncoast Estates",
                "countryCode": "US"
            },
            {
                "name": "Sunny Isles Beach",
                "countryCode": "US"
            },
            {
                "name": "Sunrise",
                "countryCode": "US"
            },
            {
                "name": "Sunset",
                "countryCode": "US"
            },
            {
                "name": "Sunshine Ranches",
                "countryCode": "US"
            },
            {
                "name": "Surfside",
                "countryCode": "US"
            },
            {
                "name": "Suwannee County",
                "countryCode": "US"
            },
            {
                "name": "Sweetwater",
                "countryCode": "US"
            },
            {
                "name": "Taft",
                "countryCode": "US"
            },
            {
                "name": "Tallahassee",
                "countryCode": "US"
            },
            {
                "name": "Tamarac",
                "countryCode": "US"
            },
            {
                "name": "Tamiami",
                "countryCode": "US"
            },
            {
                "name": "Tampa",
                "countryCode": "US"
            },
            {
                "name": "Tangelo Park",
                "countryCode": "US"
            },
            {
                "name": "Tangerine",
                "countryCode": "US"
            },
            {
                "name": "Tarpon Springs",
                "countryCode": "US"
            },
            {
                "name": "Tavares",
                "countryCode": "US"
            },
            {
                "name": "Tavernier",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Taylor Creek",
                "countryCode": "US"
            },
            {
                "name": "Tedder",
                "countryCode": "US"
            },
            {
                "name": "Temple Terrace",
                "countryCode": "US"
            },
            {
                "name": "Tequesta",
                "countryCode": "US"
            },
            {
                "name": "Terra Mar",
                "countryCode": "US"
            },
            {
                "name": "The Acreage",
                "countryCode": "US"
            },
            {
                "name": "The Crossings",
                "countryCode": "US"
            },
            {
                "name": "The Hammocks",
                "countryCode": "US"
            },
            {
                "name": "The Meadows",
                "countryCode": "US"
            },
            {
                "name": "The Villages",
                "countryCode": "US"
            },
            {
                "name": "Thonotosassa",
                "countryCode": "US"
            },
            {
                "name": "Three Lakes",
                "countryCode": "US"
            },
            {
                "name": "Three Oaks",
                "countryCode": "US"
            },
            {
                "name": "Tice",
                "countryCode": "US"
            },
            {
                "name": "Tierra Verde",
                "countryCode": "US"
            },
            {
                "name": "Tiger Point",
                "countryCode": "US"
            },
            {
                "name": "Timber Pines",
                "countryCode": "US"
            },
            {
                "name": "Titusville",
                "countryCode": "US"
            },
            {
                "name": "Town 'n' Country",
                "countryCode": "US"
            },
            {
                "name": "Treasure Island",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Trinity",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Tyndall Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Umatilla",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Union Park",
                "countryCode": "US"
            },
            {
                "name": "University",
                "countryCode": "US"
            },
            {
                "name": "University Park",
                "countryCode": "US"
            },
            {
                "name": "Upper Grand Lagoon",
                "countryCode": "US"
            },
            {
                "name": "Valparaiso",
                "countryCode": "US"
            },
            {
                "name": "Valrico",
                "countryCode": "US"
            },
            {
                "name": "Vamo",
                "countryCode": "US"
            },
            {
                "name": "Venice",
                "countryCode": "US"
            },
            {
                "name": "Venice Gardens",
                "countryCode": "US"
            },
            {
                "name": "Vero Beach",
                "countryCode": "US"
            },
            {
                "name": "Vero Beach South",
                "countryCode": "US"
            },
            {
                "name": "Verona Walk",
                "countryCode": "US"
            },
            {
                "name": "Viera East",
                "countryCode": "US"
            },
            {
                "name": "Viera West",
                "countryCode": "US"
            },
            {
                "name": "Villages of Oriole",
                "countryCode": "US"
            },
            {
                "name": "Villano Beach",
                "countryCode": "US"
            },
            {
                "name": "Villas",
                "countryCode": "US"
            },
            {
                "name": "Vineyards",
                "countryCode": "US"
            },
            {
                "name": "Virginia Gardens",
                "countryCode": "US"
            },
            {
                "name": "Volusia County",
                "countryCode": "US"
            },
            {
                "name": "Wabasso Beach",
                "countryCode": "US"
            },
            {
                "name": "Wahneta",
                "countryCode": "US"
            },
            {
                "name": "Wakulla County",
                "countryCode": "US"
            },
            {
                "name": "Waldo",
                "countryCode": "US"
            },
            {
                "name": "Wallace",
                "countryCode": "US"
            },
            {
                "name": "Walton County",
                "countryCode": "US"
            },
            {
                "name": "Warm Mineral Springs",
                "countryCode": "US"
            },
            {
                "name": "Warrington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washington Park",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Wauchula",
                "countryCode": "US"
            },
            {
                "name": "Wedgefield",
                "countryCode": "US"
            },
            {
                "name": "Weeki Wachee Gardens",
                "countryCode": "US"
            },
            {
                "name": "Wekiwa Springs",
                "countryCode": "US"
            },
            {
                "name": "Wellborn",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "Wesley Chapel",
                "countryCode": "US"
            },
            {
                "name": "West Bradenton",
                "countryCode": "US"
            },
            {
                "name": "West DeLand",
                "countryCode": "US"
            },
            {
                "name": "West Gate",
                "countryCode": "US"
            },
            {
                "name": "West Hollywood",
                "countryCode": "US"
            },
            {
                "name": "West Little River",
                "countryCode": "US"
            },
            {
                "name": "West Melbourne",
                "countryCode": "US"
            },
            {
                "name": "West Miami",
                "countryCode": "US"
            },
            {
                "name": "West Palm Beach",
                "countryCode": "US"
            },
            {
                "name": "West Park",
                "countryCode": "US"
            },
            {
                "name": "West Pensacola",
                "countryCode": "US"
            },
            {
                "name": "West Perrine",
                "countryCode": "US"
            },
            {
                "name": "West Samoset",
                "countryCode": "US"
            },
            {
                "name": "West Vero Corridor",
                "countryCode": "US"
            },
            {
                "name": "West and East Lealman",
                "countryCode": "US"
            },
            {
                "name": "Westchase",
                "countryCode": "US"
            },
            {
                "name": "Westchester",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Westview",
                "countryCode": "US"
            },
            {
                "name": "Westwood Lake",
                "countryCode": "US"
            },
            {
                "name": "Wewahitchka",
                "countryCode": "US"
            },
            {
                "name": "Whiskey Creek",
                "countryCode": "US"
            },
            {
                "name": "White City",
                "countryCode": "US"
            },
            {
                "name": "Whitfield",
                "countryCode": "US"
            },
            {
                "name": "Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williston",
                "countryCode": "US"
            },
            {
                "name": "Williston Highlands",
                "countryCode": "US"
            },
            {
                "name": "Willow Oak",
                "countryCode": "US"
            },
            {
                "name": "Wilton Manors",
                "countryCode": "US"
            },
            {
                "name": "Wimauma",
                "countryCode": "US"
            },
            {
                "name": "Windermere",
                "countryCode": "US"
            },
            {
                "name": "Winston",
                "countryCode": "US"
            },
            {
                "name": "Winter Beach",
                "countryCode": "US"
            },
            {
                "name": "Winter Garden",
                "countryCode": "US"
            },
            {
                "name": "Winter Haven",
                "countryCode": "US"
            },
            {
                "name": "Winter Park",
                "countryCode": "US"
            },
            {
                "name": "Winter Springs",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn Beach",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Wright",
                "countryCode": "US"
            },
            {
                "name": "Yalaha",
                "countryCode": "US"
            },
            {
                "name": "Youngstown",
                "countryCode": "US"
            },
            {
                "name": "Yulee",
                "countryCode": "US"
            },
            {
                "name": "Zellwood",
                "countryCode": "US"
            },
            {
                "name": "Zephyrhills",
                "countryCode": "US"
            },
            {
                "name": "Zephyrhills North",
                "countryCode": "US"
            },
            {
                "name": "Zephyrhills South",
                "countryCode": "US"
            },
            {
                "name": "Zephyrhills West",
                "countryCode": "US"
            },
            {
                "name": "Zolfo Springs",
                "countryCode": "US"
            },
            {
                "name": "Abbeville",
                "countryCode": "US"
            },
            {
                "name": "Acworth",
                "countryCode": "US"
            },
            {
                "name": "Adairsville",
                "countryCode": "US"
            },
            {
                "name": "Adel",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Alpharetta",
                "countryCode": "US"
            },
            {
                "name": "Alto",
                "countryCode": "US"
            },
            {
                "name": "Americus",
                "countryCode": "US"
            },
            {
                "name": "Appling County",
                "countryCode": "US"
            },
            {
                "name": "Aragon",
                "countryCode": "US"
            },
            {
                "name": "Arcade",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Ashburn",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atkinson County",
                "countryCode": "US"
            },
            {
                "name": "Atlanta",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Austell",
                "countryCode": "US"
            },
            {
                "name": "Avondale Estates",
                "countryCode": "US"
            },
            {
                "name": "Bacon County",
                "countryCode": "US"
            },
            {
                "name": "Bainbridge",
                "countryCode": "US"
            },
            {
                "name": "Baker County",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Baldwin County",
                "countryCode": "US"
            },
            {
                "name": "Ball Ground",
                "countryCode": "US"
            },
            {
                "name": "Banks County",
                "countryCode": "US"
            },
            {
                "name": "Barnesville",
                "countryCode": "US"
            },
            {
                "name": "Barrow County",
                "countryCode": "US"
            },
            {
                "name": "Bartow County",
                "countryCode": "US"
            },
            {
                "name": "Baxley",
                "countryCode": "US"
            },
            {
                "name": "Belvedere Park",
                "countryCode": "US"
            },
            {
                "name": "Ben Hill County",
                "countryCode": "US"
            },
            {
                "name": "Berkeley Lake",
                "countryCode": "US"
            },
            {
                "name": "Berrien County",
                "countryCode": "US"
            },
            {
                "name": "Bibb County",
                "countryCode": "US"
            },
            {
                "name": "Blackshear",
                "countryCode": "US"
            },
            {
                "name": "Blairsville",
                "countryCode": "US"
            },
            {
                "name": "Blakely",
                "countryCode": "US"
            },
            {
                "name": "Bleckley County",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Blue Ridge",
                "countryCode": "US"
            },
            {
                "name": "Bogart",
                "countryCode": "US"
            },
            {
                "name": "Bonanza",
                "countryCode": "US"
            },
            {
                "name": "Boston",
                "countryCode": "US"
            },
            {
                "name": "Bowdon",
                "countryCode": "US"
            },
            {
                "name": "Brantley County",
                "countryCode": "US"
            },
            {
                "name": "Braselton",
                "countryCode": "US"
            },
            {
                "name": "Bremen",
                "countryCode": "US"
            },
            {
                "name": "Brookhaven",
                "countryCode": "US"
            },
            {
                "name": "Brooklet",
                "countryCode": "US"
            },
            {
                "name": "Brooks County",
                "countryCode": "US"
            },
            {
                "name": "Broxton",
                "countryCode": "US"
            },
            {
                "name": "Brunswick",
                "countryCode": "US"
            },
            {
                "name": "Bryan County",
                "countryCode": "US"
            },
            {
                "name": "Buchanan",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Buford",
                "countryCode": "US"
            },
            {
                "name": "Bulloch County",
                "countryCode": "US"
            },
            {
                "name": "Burke County",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Butts County",
                "countryCode": "US"
            },
            {
                "name": "Byron",
                "countryCode": "US"
            },
            {
                "name": "Cairo",
                "countryCode": "US"
            },
            {
                "name": "Calhoun",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Camden County",
                "countryCode": "US"
            },
            {
                "name": "Camilla",
                "countryCode": "US"
            },
            {
                "name": "Candler County",
                "countryCode": "US"
            },
            {
                "name": "Candler-McAfee",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Carnesville",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Cartersville",
                "countryCode": "US"
            },
            {
                "name": "Catoosa County",
                "countryCode": "US"
            },
            {
                "name": "Cave Spring",
                "countryCode": "US"
            },
            {
                "name": "Cedartown",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Chamblee",
                "countryCode": "US"
            },
            {
                "name": "Charlton County",
                "countryCode": "US"
            },
            {
                "name": "Chatham County",
                "countryCode": "US"
            },
            {
                "name": "Chatsworth",
                "countryCode": "US"
            },
            {
                "name": "Chattahoochee County",
                "countryCode": "US"
            },
            {
                "name": "Chattahoochee Hills",
                "countryCode": "US"
            },
            {
                "name": "Chattanooga Valley",
                "countryCode": "US"
            },
            {
                "name": "Chattooga County",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chickamauga",
                "countryCode": "US"
            },
            {
                "name": "Clarke County",
                "countryCode": "US"
            },
            {
                "name": "Clarkesville",
                "countryCode": "US"
            },
            {
                "name": "Clarkston",
                "countryCode": "US"
            },
            {
                "name": "Claxton",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clayton County",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clinch County",
                "countryCode": "US"
            },
            {
                "name": "Cobb County",
                "countryCode": "US"
            },
            {
                "name": "Cochran",
                "countryCode": "US"
            },
            {
                "name": "Coffee County",
                "countryCode": "US"
            },
            {
                "name": "College Park",
                "countryCode": "US"
            },
            {
                "name": "Colquitt",
                "countryCode": "US"
            },
            {
                "name": "Colquitt County",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Comer",
                "countryCode": "US"
            },
            {
                "name": "Commerce",
                "countryCode": "US"
            },
            {
                "name": "Conley",
                "countryCode": "US"
            },
            {
                "name": "Conyers",
                "countryCode": "US"
            },
            {
                "name": "Cook County",
                "countryCode": "US"
            },
            {
                "name": "Cordele",
                "countryCode": "US"
            },
            {
                "name": "Cornelia",
                "countryCode": "US"
            },
            {
                "name": "Country Club Estates",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Coweta County",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crawfordville",
                "countryCode": "US"
            },
            {
                "name": "Crisp County",
                "countryCode": "US"
            },
            {
                "name": "Cumming",
                "countryCode": "US"
            },
            {
                "name": "Cusseta",
                "countryCode": "US"
            },
            {
                "name": "Cuthbert",
                "countryCode": "US"
            },
            {
                "name": "Dacula",
                "countryCode": "US"
            },
            {
                "name": "Dade County",
                "countryCode": "US"
            },
            {
                "name": "Dahlonega",
                "countryCode": "US"
            },
            {
                "name": "Dallas",
                "countryCode": "US"
            },
            {
                "name": "Dalton",
                "countryCode": "US"
            },
            {
                "name": "Danielsville",
                "countryCode": "US"
            },
            {
                "name": "Darien",
                "countryCode": "US"
            },
            {
                "name": "Davisboro",
                "countryCode": "US"
            },
            {
                "name": "Dawson",
                "countryCode": "US"
            },
            {
                "name": "Dawson County",
                "countryCode": "US"
            },
            {
                "name": "Dawsonville",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Decatur County",
                "countryCode": "US"
            },
            {
                "name": "Deenwood",
                "countryCode": "US"
            },
            {
                "name": "Demorest",
                "countryCode": "US"
            },
            {
                "name": "Dock Junction",
                "countryCode": "US"
            },
            {
                "name": "Dodge County",
                "countryCode": "US"
            },
            {
                "name": "Donalsonville",
                "countryCode": "US"
            },
            {
                "name": "Dooly County",
                "countryCode": "US"
            },
            {
                "name": "Doraville",
                "countryCode": "US"
            },
            {
                "name": "Dougherty County",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Douglasville",
                "countryCode": "US"
            },
            {
                "name": "Druid Hills",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Duluth",
                "countryCode": "US"
            },
            {
                "name": "Dunwoody",
                "countryCode": "US"
            },
            {
                "name": "Early County",
                "countryCode": "US"
            },
            {
                "name": "East Dublin",
                "countryCode": "US"
            },
            {
                "name": "East Griffin",
                "countryCode": "US"
            },
            {
                "name": "East Newnan",
                "countryCode": "US"
            },
            {
                "name": "East Point",
                "countryCode": "US"
            },
            {
                "name": "Eastman",
                "countryCode": "US"
            },
            {
                "name": "Eatonton",
                "countryCode": "US"
            },
            {
                "name": "Echols County",
                "countryCode": "US"
            },
            {
                "name": "Edison",
                "countryCode": "US"
            },
            {
                "name": "Effingham County",
                "countryCode": "US"
            },
            {
                "name": "Elbert County",
                "countryCode": "US"
            },
            {
                "name": "Elberton",
                "countryCode": "US"
            },
            {
                "name": "Ellaville",
                "countryCode": "US"
            },
            {
                "name": "Ellijay",
                "countryCode": "US"
            },
            {
                "name": "Emanuel County",
                "countryCode": "US"
            },
            {
                "name": "Emerson",
                "countryCode": "US"
            },
            {
                "name": "Enigma",
                "countryCode": "US"
            },
            {
                "name": "Euharlee",
                "countryCode": "US"
            },
            {
                "name": "Evans",
                "countryCode": "US"
            },
            {
                "name": "Evans County",
                "countryCode": "US"
            },
            {
                "name": "Experiment",
                "countryCode": "US"
            },
            {
                "name": "Fair Oaks",
                "countryCode": "US"
            },
            {
                "name": "Fairburn",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fannin County",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Firing Range",
                "countryCode": "US"
            },
            {
                "name": "Fitzgerald",
                "countryCode": "US"
            },
            {
                "name": "Flowery Branch",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Folkston",
                "countryCode": "US"
            },
            {
                "name": "Forest Park",
                "countryCode": "US"
            },
            {
                "name": "Forsyth",
                "countryCode": "US"
            },
            {
                "name": "Forsyth County",
                "countryCode": "US"
            },
            {
                "name": "Fort Gaines",
                "countryCode": "US"
            },
            {
                "name": "Fort Oglethorpe",
                "countryCode": "US"
            },
            {
                "name": "Fort Stewart",
                "countryCode": "US"
            },
            {
                "name": "Fort Valley",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklin Springs",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Gainesville",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Gibson",
                "countryCode": "US"
            },
            {
                "name": "Gilmer County",
                "countryCode": "US"
            },
            {
                "name": "Glascock County",
                "countryCode": "US"
            },
            {
                "name": "Glennville",
                "countryCode": "US"
            },
            {
                "name": "Glynn County",
                "countryCode": "US"
            },
            {
                "name": "Gordon",
                "countryCode": "US"
            },
            {
                "name": "Gordon County",
                "countryCode": "US"
            },
            {
                "name": "Grady County",
                "countryCode": "US"
            },
            {
                "name": "Grantville",
                "countryCode": "US"
            },
            {
                "name": "Gray",
                "countryCode": "US"
            },
            {
                "name": "Grayson",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greensboro",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Gresham Park",
                "countryCode": "US"
            },
            {
                "name": "Griffin",
                "countryCode": "US"
            },
            {
                "name": "Grovetown",
                "countryCode": "US"
            },
            {
                "name": "Gumlog",
                "countryCode": "US"
            },
            {
                "name": "Guyton",
                "countryCode": "US"
            },
            {
                "name": "Gwinnett County",
                "countryCode": "US"
            },
            {
                "name": "Habersham County",
                "countryCode": "US"
            },
            {
                "name": "Hahira",
                "countryCode": "US"
            },
            {
                "name": "Hall County",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hannahs Mill",
                "countryCode": "US"
            },
            {
                "name": "Hapeville",
                "countryCode": "US"
            },
            {
                "name": "Haralson County",
                "countryCode": "US"
            },
            {
                "name": "Hardwick",
                "countryCode": "US"
            },
            {
                "name": "Harlem",
                "countryCode": "US"
            },
            {
                "name": "Harris County",
                "countryCode": "US"
            },
            {
                "name": "Hart County",
                "countryCode": "US"
            },
            {
                "name": "Hartwell",
                "countryCode": "US"
            },
            {
                "name": "Hawkinsville",
                "countryCode": "US"
            },
            {
                "name": "Hazlehurst",
                "countryCode": "US"
            },
            {
                "name": "Heard County",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Hephzibah",
                "countryCode": "US"
            },
            {
                "name": "Hiawassee",
                "countryCode": "US"
            },
            {
                "name": "Hinesville",
                "countryCode": "US"
            },
            {
                "name": "Hiram",
                "countryCode": "US"
            },
            {
                "name": "Hogansville",
                "countryCode": "US"
            },
            {
                "name": "Holly Springs",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Homerville",
                "countryCode": "US"
            },
            {
                "name": "Hoschton",
                "countryCode": "US"
            },
            {
                "name": "Houston County",
                "countryCode": "US"
            },
            {
                "name": "Indian Springs",
                "countryCode": "US"
            },
            {
                "name": "Irondale",
                "countryCode": "US"
            },
            {
                "name": "Irwin County",
                "countryCode": "US"
            },
            {
                "name": "Irwinton",
                "countryCode": "US"
            },
            {
                "name": "Isle of Hope",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jeff Davis County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jeffersonville",
                "countryCode": "US"
            },
            {
                "name": "Jenkins County",
                "countryCode": "US"
            },
            {
                "name": "Jesup",
                "countryCode": "US"
            },
            {
                "name": "Johns Creek",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Jonesboro",
                "countryCode": "US"
            },
            {
                "name": "Kennesaw",
                "countryCode": "US"
            },
            {
                "name": "Kings Bay Base",
                "countryCode": "US"
            },
            {
                "name": "Kingsland",
                "countryCode": "US"
            },
            {
                "name": "Knoxville",
                "countryCode": "US"
            },
            {
                "name": "LaFayette",
                "countryCode": "US"
            },
            {
                "name": "LaGrange",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lakeland",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lakeview Estates",
                "countryCode": "US"
            },
            {
                "name": "Lamar County",
                "countryCode": "US"
            },
            {
                "name": "Lanier County",
                "countryCode": "US"
            },
            {
                "name": "Laurens County",
                "countryCode": "US"
            },
            {
                "name": "Lavonia",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceville",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leesburg",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty County",
                "countryCode": "US"
            },
            {
                "name": "Lilburn",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lincolnton",
                "countryCode": "US"
            },
            {
                "name": "Lindale",
                "countryCode": "US"
            },
            {
                "name": "Lithia Springs",
                "countryCode": "US"
            },
            {
                "name": "Lithonia",
                "countryCode": "US"
            },
            {
                "name": "Locust Grove",
                "countryCode": "US"
            },
            {
                "name": "Loganville",
                "countryCode": "US"
            },
            {
                "name": "Long County",
                "countryCode": "US"
            },
            {
                "name": "Lookout Mountain",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Lovejoy",
                "countryCode": "US"
            },
            {
                "name": "Lowndes County",
                "countryCode": "US"
            },
            {
                "name": "Ludowici",
                "countryCode": "US"
            },
            {
                "name": "Lula",
                "countryCode": "US"
            },
            {
                "name": "Lumber City",
                "countryCode": "US"
            },
            {
                "name": "Lumpkin",
                "countryCode": "US"
            },
            {
                "name": "Lumpkin County",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Mableton",
                "countryCode": "US"
            },
            {
                "name": "Macon",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Marietta",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshallville",
                "countryCode": "US"
            },
            {
                "name": "Martinez",
                "countryCode": "US"
            },
            {
                "name": "Maysville",
                "countryCode": "US"
            },
            {
                "name": "McCaysville",
                "countryCode": "US"
            },
            {
                "name": "McDonough",
                "countryCode": "US"
            },
            {
                "name": "McDuffie County",
                "countryCode": "US"
            },
            {
                "name": "McIntosh County",
                "countryCode": "US"
            },
            {
                "name": "McRae",
                "countryCode": "US"
            },
            {
                "name": "Meigs",
                "countryCode": "US"
            },
            {
                "name": "Meriwether County",
                "countryCode": "US"
            },
            {
                "name": "Metter",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Milledgeville",
                "countryCode": "US"
            },
            {
                "name": "Millen",
                "countryCode": "US"
            },
            {
                "name": "Miller County",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Mitchell County",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montezuma",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morgan",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morrow",
                "countryCode": "US"
            },
            {
                "name": "Moultrie",
                "countryCode": "US"
            },
            {
                "name": "Mount Airy",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mount Zion",
                "countryCode": "US"
            },
            {
                "name": "Mountain City",
                "countryCode": "US"
            },
            {
                "name": "Mountain Park",
                "countryCode": "US"
            },
            {
                "name": "Murray County",
                "countryCode": "US"
            },
            {
                "name": "Muscogee County",
                "countryCode": "US"
            },
            {
                "name": "Nahunta",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Nelson",
                "countryCode": "US"
            },
            {
                "name": "Newnan",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "Nicholls",
                "countryCode": "US"
            },
            {
                "name": "Nicholson",
                "countryCode": "US"
            },
            {
                "name": "Norcross",
                "countryCode": "US"
            },
            {
                "name": "North Atlanta",
                "countryCode": "US"
            },
            {
                "name": "North Decatur",
                "countryCode": "US"
            },
            {
                "name": "North Druid Hills",
                "countryCode": "US"
            },
            {
                "name": "Oakwood",
                "countryCode": "US"
            },
            {
                "name": "Ocilla",
                "countryCode": "US"
            },
            {
                "name": "Oconee County",
                "countryCode": "US"
            },
            {
                "name": "Oglethorpe",
                "countryCode": "US"
            },
            {
                "name": "Oglethorpe County",
                "countryCode": "US"
            },
            {
                "name": "Omega",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Palmetto",
                "countryCode": "US"
            },
            {
                "name": "Panthersville",
                "countryCode": "US"
            },
            {
                "name": "Paulding County",
                "countryCode": "US"
            },
            {
                "name": "Peach County",
                "countryCode": "US"
            },
            {
                "name": "Peachtree City",
                "countryCode": "US"
            },
            {
                "name": "Peachtree Corners",
                "countryCode": "US"
            },
            {
                "name": "Pearson",
                "countryCode": "US"
            },
            {
                "name": "Pelham",
                "countryCode": "US"
            },
            {
                "name": "Pembroke",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Pickens County",
                "countryCode": "US"
            },
            {
                "name": "Pierce County",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pine Mountain",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Pooler",
                "countryCode": "US"
            },
            {
                "name": "Port Wentworth",
                "countryCode": "US"
            },
            {
                "name": "Porterdale",
                "countryCode": "US"
            },
            {
                "name": "Powder Springs",
                "countryCode": "US"
            },
            {
                "name": "Preston",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Putney",
                "countryCode": "US"
            },
            {
                "name": "Quitman",
                "countryCode": "US"
            },
            {
                "name": "Quitman County",
                "countryCode": "US"
            },
            {
                "name": "Rabun County",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Raoul",
                "countryCode": "US"
            },
            {
                "name": "Ray City",
                "countryCode": "US"
            },
            {
                "name": "Redan",
                "countryCode": "US"
            },
            {
                "name": "Reed Creek",
                "countryCode": "US"
            },
            {
                "name": "Reidsville",
                "countryCode": "US"
            },
            {
                "name": "Remerton",
                "countryCode": "US"
            },
            {
                "name": "Reynolds",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Richmond County",
                "countryCode": "US"
            },
            {
                "name": "Richmond Hill",
                "countryCode": "US"
            },
            {
                "name": "Rincon",
                "countryCode": "US"
            },
            {
                "name": "Ringgold",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Robins Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Rochelle",
                "countryCode": "US"
            },
            {
                "name": "Rockdale County",
                "countryCode": "US"
            },
            {
                "name": "Rockmart",
                "countryCode": "US"
            },
            {
                "name": "Rome",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Roswell",
                "countryCode": "US"
            },
            {
                "name": "Royston",
                "countryCode": "US"
            },
            {
                "name": "Russell",
                "countryCode": "US"
            },
            {
                "name": "Rydal",
                "countryCode": "US"
            },
            {
                "name": "Saint Simon Mills",
                "countryCode": "US"
            },
            {
                "name": "Saint Simons Island",
                "countryCode": "US"
            },
            {
                "name": "Sandersville",
                "countryCode": "US"
            },
            {
                "name": "Sandy Springs",
                "countryCode": "US"
            },
            {
                "name": "Sardis",
                "countryCode": "US"
            },
            {
                "name": "Savannah",
                "countryCode": "US"
            },
            {
                "name": "Schley County",
                "countryCode": "US"
            },
            {
                "name": "Scottdale",
                "countryCode": "US"
            },
            {
                "name": "Screven County",
                "countryCode": "US"
            },
            {
                "name": "Seminole County",
                "countryCode": "US"
            },
            {
                "name": "Senoia",
                "countryCode": "US"
            },
            {
                "name": "Shannon",
                "countryCode": "US"
            },
            {
                "name": "Skidaway Island",
                "countryCode": "US"
            },
            {
                "name": "Smyrna",
                "countryCode": "US"
            },
            {
                "name": "Snellville",
                "countryCode": "US"
            },
            {
                "name": "Social Circle",
                "countryCode": "US"
            },
            {
                "name": "Soperton",
                "countryCode": "US"
            },
            {
                "name": "Spalding County",
                "countryCode": "US"
            },
            {
                "name": "Sparks",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "St. Marys",
                "countryCode": "US"
            },
            {
                "name": "Statenville",
                "countryCode": "US"
            },
            {
                "name": "Statesboro",
                "countryCode": "US"
            },
            {
                "name": "Statham",
                "countryCode": "US"
            },
            {
                "name": "Stephens County",
                "countryCode": "US"
            },
            {
                "name": "Stewart County",
                "countryCode": "US"
            },
            {
                "name": "Stockbridge",
                "countryCode": "US"
            },
            {
                "name": "Stone Mountain",
                "countryCode": "US"
            },
            {
                "name": "Stonecrest",
                "countryCode": "US"
            },
            {
                "name": "Sugar Hill",
                "countryCode": "US"
            },
            {
                "name": "Summerville",
                "countryCode": "US"
            },
            {
                "name": "Sumter County",
                "countryCode": "US"
            },
            {
                "name": "Sunnyside",
                "countryCode": "US"
            },
            {
                "name": "Suwanee",
                "countryCode": "US"
            },
            {
                "name": "Swainsboro",
                "countryCode": "US"
            },
            {
                "name": "Sylvania",
                "countryCode": "US"
            },
            {
                "name": "Sylvester",
                "countryCode": "US"
            },
            {
                "name": "Talbot County",
                "countryCode": "US"
            },
            {
                "name": "Talbotton",
                "countryCode": "US"
            },
            {
                "name": "Taliaferro County",
                "countryCode": "US"
            },
            {
                "name": "Tallapoosa",
                "countryCode": "US"
            },
            {
                "name": "Tattnall County",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Telfair County",
                "countryCode": "US"
            },
            {
                "name": "Temple",
                "countryCode": "US"
            },
            {
                "name": "Tennille",
                "countryCode": "US"
            },
            {
                "name": "Terrell County",
                "countryCode": "US"
            },
            {
                "name": "Thomas County",
                "countryCode": "US"
            },
            {
                "name": "Thomaston",
                "countryCode": "US"
            },
            {
                "name": "Thomasville",
                "countryCode": "US"
            },
            {
                "name": "Thomson",
                "countryCode": "US"
            },
            {
                "name": "Thunderbolt",
                "countryCode": "US"
            },
            {
                "name": "Tift County",
                "countryCode": "US"
            },
            {
                "name": "Tifton",
                "countryCode": "US"
            },
            {
                "name": "Toccoa",
                "countryCode": "US"
            },
            {
                "name": "Toombs County",
                "countryCode": "US"
            },
            {
                "name": "Towns County",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Treutlen County",
                "countryCode": "US"
            },
            {
                "name": "Trion",
                "countryCode": "US"
            },
            {
                "name": "Troup County",
                "countryCode": "US"
            },
            {
                "name": "Tucker",
                "countryCode": "US"
            },
            {
                "name": "Turner County",
                "countryCode": "US"
            },
            {
                "name": "Twiggs County",
                "countryCode": "US"
            },
            {
                "name": "Twin City",
                "countryCode": "US"
            },
            {
                "name": "Tybee Island",
                "countryCode": "US"
            },
            {
                "name": "Tyrone",
                "countryCode": "US"
            },
            {
                "name": "Unadilla",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Union Point",
                "countryCode": "US"
            },
            {
                "name": "Unionville",
                "countryCode": "US"
            },
            {
                "name": "Upson County",
                "countryCode": "US"
            },
            {
                "name": "Valdosta",
                "countryCode": "US"
            },
            {
                "name": "Varnell",
                "countryCode": "US"
            },
            {
                "name": "Vidalia",
                "countryCode": "US"
            },
            {
                "name": "Vienna",
                "countryCode": "US"
            },
            {
                "name": "Villa Rica",
                "countryCode": "US"
            },
            {
                "name": "Vinings",
                "countryCode": "US"
            },
            {
                "name": "Wadley",
                "countryCode": "US"
            },
            {
                "name": "Walker County",
                "countryCode": "US"
            },
            {
                "name": "Walnut Grove",
                "countryCode": "US"
            },
            {
                "name": "Walthourville",
                "countryCode": "US"
            },
            {
                "name": "Walton County",
                "countryCode": "US"
            },
            {
                "name": "Ware County",
                "countryCode": "US"
            },
            {
                "name": "Warner Robins",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Watkinsville",
                "countryCode": "US"
            },
            {
                "name": "Waycross",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "West Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Wheeler County",
                "countryCode": "US"
            },
            {
                "name": "White County",
                "countryCode": "US"
            },
            {
                "name": "Whitemarsh Island",
                "countryCode": "US"
            },
            {
                "name": "Whitfield County",
                "countryCode": "US"
            },
            {
                "name": "Wilcox County",
                "countryCode": "US"
            },
            {
                "name": "Wilkes County",
                "countryCode": "US"
            },
            {
                "name": "Wilkinson County",
                "countryCode": "US"
            },
            {
                "name": "Willacoochee",
                "countryCode": "US"
            },
            {
                "name": "Wilmington Island",
                "countryCode": "US"
            },
            {
                "name": "Winder",
                "countryCode": "US"
            },
            {
                "name": "Winterville",
                "countryCode": "US"
            },
            {
                "name": "Woodbine",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Worth County",
                "countryCode": "US"
            },
            {
                "name": "Wrens",
                "countryCode": "US"
            },
            {
                "name": "Wrightsville",
                "countryCode": "US"
            },
            {
                "name": "Young Harris",
                "countryCode": "US"
            },
            {
                "name": "Zebulon",
                "countryCode": "US"
            },
            {
                "name": "Ainaloa",
                "countryCode": "US"
            },
            {
                "name": "Anahola",
                "countryCode": "US"
            },
            {
                "name": "Captain Cook",
                "countryCode": "US"
            },
            {
                "name": "East Honolulu",
                "countryCode": "US"
            },
            {
                "name": "Fern Acres",
                "countryCode": "US"
            },
            {
                "name": "Haiku-Pauwela",
                "countryCode": "US"
            },
            {
                "name": "Hale‘iwa",
                "countryCode": "US"
            },
            {
                "name": "Hana",
                "countryCode": "US"
            },
            {
                "name": "Hanamā‘ulu",
                "countryCode": "US"
            },
            {
                "name": "Hanapēpē",
                "countryCode": "US"
            },
            {
                "name": "Hanapēpē Heights",
                "countryCode": "US"
            },
            {
                "name": "Hau‘ula",
                "countryCode": "US"
            },
            {
                "name": "Hawaii County",
                "countryCode": "US"
            },
            {
                "name": "Hawaiian Acres",
                "countryCode": "US"
            },
            {
                "name": "Hawaiian Beaches",
                "countryCode": "US"
            },
            {
                "name": "Hawaiian Ocean View",
                "countryCode": "US"
            },
            {
                "name": "Hawaiian Paradise Park",
                "countryCode": "US"
            },
            {
                "name": "He‘eia",
                "countryCode": "US"
            },
            {
                "name": "Hickam Field",
                "countryCode": "US"
            },
            {
                "name": "Hilo",
                "countryCode": "US"
            },
            {
                "name": "Honalo",
                "countryCode": "US"
            },
            {
                "name": "Honaunau-Napoopoo",
                "countryCode": "US"
            },
            {
                "name": "Honoka‘a",
                "countryCode": "US"
            },
            {
                "name": "Honolulu",
                "countryCode": "US"
            },
            {
                "name": "Honolulu County",
                "countryCode": "US"
            },
            {
                "name": "Hālawa",
                "countryCode": "US"
            },
            {
                "name": "Hālawa Heights",
                "countryCode": "US"
            },
            {
                "name": "Hāwī",
                "countryCode": "US"
            },
            {
                "name": "Hōlualoa",
                "countryCode": "US"
            },
            {
                "name": "Iroquois Point",
                "countryCode": "US"
            },
            {
                "name": "Kaanapali Landing",
                "countryCode": "US"
            },
            {
                "name": "Kahaluu-Keauhou",
                "countryCode": "US"
            },
            {
                "name": "Kahalu‘u",
                "countryCode": "US"
            },
            {
                "name": "Kahuku",
                "countryCode": "US"
            },
            {
                "name": "Kahului",
                "countryCode": "US"
            },
            {
                "name": "Kailua",
                "countryCode": "US"
            },
            {
                "name": "Kailua-Kona",
                "countryCode": "US"
            },
            {
                "name": "Kalaoa",
                "countryCode": "US"
            },
            {
                "name": "Kalawao County",
                "countryCode": "US"
            },
            {
                "name": "Kalāheo",
                "countryCode": "US"
            },
            {
                "name": "Kaneohe",
                "countryCode": "US"
            },
            {
                "name": "Kapaau",
                "countryCode": "US"
            },
            {
                "name": "Kapa‘a",
                "countryCode": "US"
            },
            {
                "name": "Kapolei",
                "countryCode": "US"
            },
            {
                "name": "Kauai County",
                "countryCode": "US"
            },
            {
                "name": "Kaunakakai",
                "countryCode": "US"
            },
            {
                "name": "Ka‘a‘awa",
                "countryCode": "US"
            },
            {
                "name": "Kealakekua",
                "countryCode": "US"
            },
            {
                "name": "Kea‘au",
                "countryCode": "US"
            },
            {
                "name": "Kekaha",
                "countryCode": "US"
            },
            {
                "name": "Ko Olina",
                "countryCode": "US"
            },
            {
                "name": "Koloa",
                "countryCode": "US"
            },
            {
                "name": "Kualapu‘u",
                "countryCode": "US"
            },
            {
                "name": "Kula",
                "countryCode": "US"
            },
            {
                "name": "Kurtistown",
                "countryCode": "US"
            },
            {
                "name": "Kā‘anapali",
                "countryCode": "US"
            },
            {
                "name": "Kēōkea",
                "countryCode": "US"
            },
            {
                "name": "Kīhei",
                "countryCode": "US"
            },
            {
                "name": "Kīlauea",
                "countryCode": "US"
            },
            {
                "name": "Lahaina",
                "countryCode": "US"
            },
            {
                "name": "Lanai City",
                "countryCode": "US"
            },
            {
                "name": "Lawai",
                "countryCode": "US"
            },
            {
                "name": "Leilani Estates",
                "countryCode": "US"
            },
            {
                "name": "Lihue",
                "countryCode": "US"
            },
            {
                "name": "Lā‘ie",
                "countryCode": "US"
            },
            {
                "name": "Makakilo City",
                "countryCode": "US"
            },
            {
                "name": "Makawao",
                "countryCode": "US"
            },
            {
                "name": "Marine Corps Base Hawaii - MCBH",
                "countryCode": "US"
            },
            {
                "name": "Maui County",
                "countryCode": "US"
            },
            {
                "name": "Maunawili",
                "countryCode": "US"
            },
            {
                "name": "Mililani Town",
                "countryCode": "US"
            },
            {
                "name": "Mokulēia",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Mākaha",
                "countryCode": "US"
            },
            {
                "name": "Mākaha Valley",
                "countryCode": "US"
            },
            {
                "name": "Mā‘ili",
                "countryCode": "US"
            },
            {
                "name": "Nanawale Estates",
                "countryCode": "US"
            },
            {
                "name": "Napili-Honokowai",
                "countryCode": "US"
            },
            {
                "name": "Nānākuli",
                "countryCode": "US"
            },
            {
                "name": "Ocean Pointe",
                "countryCode": "US"
            },
            {
                "name": "Orchidlands Estates",
                "countryCode": "US"
            },
            {
                "name": "Paia",
                "countryCode": "US"
            },
            {
                "name": "Pearl City",
                "countryCode": "US"
            },
            {
                "name": "Pepeekeo",
                "countryCode": "US"
            },
            {
                "name": "Princeville",
                "countryCode": "US"
            },
            {
                "name": "Puhi",
                "countryCode": "US"
            },
            {
                "name": "Pukalani",
                "countryCode": "US"
            },
            {
                "name": "Punalu‘u",
                "countryCode": "US"
            },
            {
                "name": "Pupukea",
                "countryCode": "US"
            },
            {
                "name": "Pāhala",
                "countryCode": "US"
            },
            {
                "name": "Pāpa‘ikou",
                "countryCode": "US"
            },
            {
                "name": "Royal Kunia",
                "countryCode": "US"
            },
            {
                "name": "Schofield Barracks",
                "countryCode": "US"
            },
            {
                "name": "Village Park",
                "countryCode": "US"
            },
            {
                "name": "Volcano",
                "countryCode": "US"
            },
            {
                "name": "Wahiawā",
                "countryCode": "US"
            },
            {
                "name": "Waialua",
                "countryCode": "US"
            },
            {
                "name": "Waianae",
                "countryCode": "US"
            },
            {
                "name": "Waihee-Waiehu",
                "countryCode": "US"
            },
            {
                "name": "Waikapū",
                "countryCode": "US"
            },
            {
                "name": "Waikoloa",
                "countryCode": "US"
            },
            {
                "name": "Wailea",
                "countryCode": "US"
            },
            {
                "name": "Wailua",
                "countryCode": "US"
            },
            {
                "name": "Wailua Homesteads",
                "countryCode": "US"
            },
            {
                "name": "Wailuku",
                "countryCode": "US"
            },
            {
                "name": "Waimalu",
                "countryCode": "US"
            },
            {
                "name": "Waimanalo",
                "countryCode": "US"
            },
            {
                "name": "Waimea",
                "countryCode": "US"
            },
            {
                "name": "Waimānalo Beach",
                "countryCode": "US"
            },
            {
                "name": "Wainaku",
                "countryCode": "US"
            },
            {
                "name": "Waipahu",
                "countryCode": "US"
            },
            {
                "name": "Waipio",
                "countryCode": "US"
            },
            {
                "name": "Waipi‘o Acres",
                "countryCode": "US"
            },
            {
                "name": "Whitmore Village",
                "countryCode": "US"
            },
            {
                "name": "‘Aiea",
                "countryCode": "US"
            },
            {
                "name": "‘Ele‘ele",
                "countryCode": "US"
            },
            {
                "name": "‘Ewa Beach",
                "countryCode": "US"
            },
            {
                "name": "‘Ewa Gentry",
                "countryCode": "US"
            },
            {
                "name": "‘Ewa Villages",
                "countryCode": "US"
            },
            {
                "name": "‘Āhuimanu",
                "countryCode": "US"
            },
            {
                "name": "‘Ōma‘o",
                "countryCode": "US"
            },
            {
                "name": "Ackley",
                "countryCode": "US"
            },
            {
                "name": "Adair County",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Adel",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Albia",
                "countryCode": "US"
            },
            {
                "name": "Algona",
                "countryCode": "US"
            },
            {
                "name": "Allamakee County",
                "countryCode": "US"
            },
            {
                "name": "Allison",
                "countryCode": "US"
            },
            {
                "name": "Alta",
                "countryCode": "US"
            },
            {
                "name": "Alton",
                "countryCode": "US"
            },
            {
                "name": "Altoona",
                "countryCode": "US"
            },
            {
                "name": "Ames",
                "countryCode": "US"
            },
            {
                "name": "Anamosa",
                "countryCode": "US"
            },
            {
                "name": "Ankeny",
                "countryCode": "US"
            },
            {
                "name": "Aplington",
                "countryCode": "US"
            },
            {
                "name": "Appanoose County",
                "countryCode": "US"
            },
            {
                "name": "Arnolds Park",
                "countryCode": "US"
            },
            {
                "name": "Asbury",
                "countryCode": "US"
            },
            {
                "name": "Atkins",
                "countryCode": "US"
            },
            {
                "name": "Atlantic",
                "countryCode": "US"
            },
            {
                "name": "Audubon",
                "countryCode": "US"
            },
            {
                "name": "Audubon County",
                "countryCode": "US"
            },
            {
                "name": "Avoca",
                "countryCode": "US"
            },
            {
                "name": "Baxter",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Belle Plaine",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Belmond",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Bettendorf",
                "countryCode": "US"
            },
            {
                "name": "Black Hawk County",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Blue Grass",
                "countryCode": "US"
            },
            {
                "name": "Bondurant",
                "countryCode": "US"
            },
            {
                "name": "Boone",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Bremer County",
                "countryCode": "US"
            },
            {
                "name": "Britt",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Buchanan County",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Buffalo (historical)",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Camanche",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carroll",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carter Lake",
                "countryCode": "US"
            },
            {
                "name": "Cascade",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Cedar County",
                "countryCode": "US"
            },
            {
                "name": "Cedar Falls",
                "countryCode": "US"
            },
            {
                "name": "Cedar Rapids",
                "countryCode": "US"
            },
            {
                "name": "Center Point",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Cerro Gordo County",
                "countryCode": "US"
            },
            {
                "name": "Chariton",
                "countryCode": "US"
            },
            {
                "name": "Charles City",
                "countryCode": "US"
            },
            {
                "name": "Cherokee",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Chickasaw County",
                "countryCode": "US"
            },
            {
                "name": "Clarinda",
                "countryCode": "US"
            },
            {
                "name": "Clarion",
                "countryCode": "US"
            },
            {
                "name": "Clarke County",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clayton County",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Clive",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "Columbus Junction",
                "countryCode": "US"
            },
            {
                "name": "Conrad",
                "countryCode": "US"
            },
            {
                "name": "Coon Rapids",
                "countryCode": "US"
            },
            {
                "name": "Coralville",
                "countryCode": "US"
            },
            {
                "name": "Corning",
                "countryCode": "US"
            },
            {
                "name": "Corydon",
                "countryCode": "US"
            },
            {
                "name": "Council Bluffs",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Cresco",
                "countryCode": "US"
            },
            {
                "name": "Creston",
                "countryCode": "US"
            },
            {
                "name": "Dakota City",
                "countryCode": "US"
            },
            {
                "name": "Dallas Center",
                "countryCode": "US"
            },
            {
                "name": "Dallas County",
                "countryCode": "US"
            },
            {
                "name": "Davenport",
                "countryCode": "US"
            },
            {
                "name": "Davis County",
                "countryCode": "US"
            },
            {
                "name": "De Soto",
                "countryCode": "US"
            },
            {
                "name": "De Witt",
                "countryCode": "US"
            },
            {
                "name": "Decatur County",
                "countryCode": "US"
            },
            {
                "name": "Decorah",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Denison",
                "countryCode": "US"
            },
            {
                "name": "Denver",
                "countryCode": "US"
            },
            {
                "name": "Des Moines",
                "countryCode": "US"
            },
            {
                "name": "Des Moines County",
                "countryCode": "US"
            },
            {
                "name": "Dickinson County",
                "countryCode": "US"
            },
            {
                "name": "Dike",
                "countryCode": "US"
            },
            {
                "name": "Dubuque",
                "countryCode": "US"
            },
            {
                "name": "Dubuque County",
                "countryCode": "US"
            },
            {
                "name": "Durant",
                "countryCode": "US"
            },
            {
                "name": "Dyersville",
                "countryCode": "US"
            },
            {
                "name": "Dysart",
                "countryCode": "US"
            },
            {
                "name": "Eagle Grove",
                "countryCode": "US"
            },
            {
                "name": "Earlham",
                "countryCode": "US"
            },
            {
                "name": "Eddyville",
                "countryCode": "US"
            },
            {
                "name": "Eldora",
                "countryCode": "US"
            },
            {
                "name": "Eldridge",
                "countryCode": "US"
            },
            {
                "name": "Elk Run Heights",
                "countryCode": "US"
            },
            {
                "name": "Elkader",
                "countryCode": "US"
            },
            {
                "name": "Ely",
                "countryCode": "US"
            },
            {
                "name": "Emmet County",
                "countryCode": "US"
            },
            {
                "name": "Emmetsburg",
                "countryCode": "US"
            },
            {
                "name": "Epworth",
                "countryCode": "US"
            },
            {
                "name": "Estherville",
                "countryCode": "US"
            },
            {
                "name": "Evansdale",
                "countryCode": "US"
            },
            {
                "name": "Fairbank",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Farley",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Forest City",
                "countryCode": "US"
            },
            {
                "name": "Fort Dodge",
                "countryCode": "US"
            },
            {
                "name": "Fort Madison",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fremont County",
                "countryCode": "US"
            },
            {
                "name": "Garner",
                "countryCode": "US"
            },
            {
                "name": "George",
                "countryCode": "US"
            },
            {
                "name": "Gilbert",
                "countryCode": "US"
            },
            {
                "name": "Glenwood",
                "countryCode": "US"
            },
            {
                "name": "Glidden",
                "countryCode": "US"
            },
            {
                "name": "Granger",
                "countryCode": "US"
            },
            {
                "name": "Greene",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Grimes",
                "countryCode": "US"
            },
            {
                "name": "Grinnell",
                "countryCode": "US"
            },
            {
                "name": "Grundy Center",
                "countryCode": "US"
            },
            {
                "name": "Grundy County",
                "countryCode": "US"
            },
            {
                "name": "Guthrie Center",
                "countryCode": "US"
            },
            {
                "name": "Guthrie County",
                "countryCode": "US"
            },
            {
                "name": "Guttenberg",
                "countryCode": "US"
            },
            {
                "name": "Hamburg",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Harlan",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Hartley",
                "countryCode": "US"
            },
            {
                "name": "Hawarden",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Hiawatha",
                "countryCode": "US"
            },
            {
                "name": "Holstein",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hull",
                "countryCode": "US"
            },
            {
                "name": "Humboldt",
                "countryCode": "US"
            },
            {
                "name": "Humboldt County",
                "countryCode": "US"
            },
            {
                "name": "Huxley",
                "countryCode": "US"
            },
            {
                "name": "Ida County",
                "countryCode": "US"
            },
            {
                "name": "Ida Grove",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Indianola",
                "countryCode": "US"
            },
            {
                "name": "Iowa City",
                "countryCode": "US"
            },
            {
                "name": "Iowa County",
                "countryCode": "US"
            },
            {
                "name": "Iowa Falls",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jesup",
                "countryCode": "US"
            },
            {
                "name": "Jewell",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Johnston",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Kalona",
                "countryCode": "US"
            },
            {
                "name": "Keokuk",
                "countryCode": "US"
            },
            {
                "name": "Keokuk County",
                "countryCode": "US"
            },
            {
                "name": "Keosauqua",
                "countryCode": "US"
            },
            {
                "name": "Kingsley",
                "countryCode": "US"
            },
            {
                "name": "Knoxville",
                "countryCode": "US"
            },
            {
                "name": "Kossuth County",
                "countryCode": "US"
            },
            {
                "name": "La Porte City",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake Mills",
                "countryCode": "US"
            },
            {
                "name": "Lake Panorama",
                "countryCode": "US"
            },
            {
                "name": "Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Lake View",
                "countryCode": "US"
            },
            {
                "name": "Lamoni",
                "countryCode": "US"
            },
            {
                "name": "Laurens",
                "countryCode": "US"
            },
            {
                "name": "Le Claire",
                "countryCode": "US"
            },
            {
                "name": "Le Mars",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Lenox",
                "countryCode": "US"
            },
            {
                "name": "Leon",
                "countryCode": "US"
            },
            {
                "name": "Linn County",
                "countryCode": "US"
            },
            {
                "name": "Lisbon",
                "countryCode": "US"
            },
            {
                "name": "Logan",
                "countryCode": "US"
            },
            {
                "name": "Lone Tree",
                "countryCode": "US"
            },
            {
                "name": "Louisa County",
                "countryCode": "US"
            },
            {
                "name": "Lucas County",
                "countryCode": "US"
            },
            {
                "name": "Lyon County",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Madrid",
                "countryCode": "US"
            },
            {
                "name": "Maharishi Vedic City",
                "countryCode": "US"
            },
            {
                "name": "Mahaska County",
                "countryCode": "US"
            },
            {
                "name": "Malvern",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manly",
                "countryCode": "US"
            },
            {
                "name": "Manning",
                "countryCode": "US"
            },
            {
                "name": "Manson",
                "countryCode": "US"
            },
            {
                "name": "Mapleton",
                "countryCode": "US"
            },
            {
                "name": "Maquoketa",
                "countryCode": "US"
            },
            {
                "name": "Marcus",
                "countryCode": "US"
            },
            {
                "name": "Marengo",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Marshalltown",
                "countryCode": "US"
            },
            {
                "name": "Mason City",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsville",
                "countryCode": "US"
            },
            {
                "name": "Mediapolis",
                "countryCode": "US"
            },
            {
                "name": "Melcher-Dallas",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Mills County",
                "countryCode": "US"
            },
            {
                "name": "Missouri Valley",
                "countryCode": "US"
            },
            {
                "name": "Mitchell County",
                "countryCode": "US"
            },
            {
                "name": "Mitchellville",
                "countryCode": "US"
            },
            {
                "name": "Monona",
                "countryCode": "US"
            },
            {
                "name": "Monona County",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montezuma",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Mount Ayr",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Moville",
                "countryCode": "US"
            },
            {
                "name": "Muscatine",
                "countryCode": "US"
            },
            {
                "name": "Muscatine County",
                "countryCode": "US"
            },
            {
                "name": "Nashua",
                "countryCode": "US"
            },
            {
                "name": "Nevada",
                "countryCode": "US"
            },
            {
                "name": "New Hampton",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New Sharon",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Nora Springs",
                "countryCode": "US"
            },
            {
                "name": "North English",
                "countryCode": "US"
            },
            {
                "name": "North Liberty",
                "countryCode": "US"
            },
            {
                "name": "Northwood",
                "countryCode": "US"
            },
            {
                "name": "Norwalk",
                "countryCode": "US"
            },
            {
                "name": "O'Brien County",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oelwein",
                "countryCode": "US"
            },
            {
                "name": "Ogden",
                "countryCode": "US"
            },
            {
                "name": "Onawa",
                "countryCode": "US"
            },
            {
                "name": "Orange City",
                "countryCode": "US"
            },
            {
                "name": "Osage",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Osceola County",
                "countryCode": "US"
            },
            {
                "name": "Oskaloosa",
                "countryCode": "US"
            },
            {
                "name": "Ottumwa",
                "countryCode": "US"
            },
            {
                "name": "Page County",
                "countryCode": "US"
            },
            {
                "name": "Palo",
                "countryCode": "US"
            },
            {
                "name": "Palo Alto County",
                "countryCode": "US"
            },
            {
                "name": "Panora",
                "countryCode": "US"
            },
            {
                "name": "Park View",
                "countryCode": "US"
            },
            {
                "name": "Parkersburg",
                "countryCode": "US"
            },
            {
                "name": "Paullina",
                "countryCode": "US"
            },
            {
                "name": "Pella",
                "countryCode": "US"
            },
            {
                "name": "Peosta",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Pleasantville",
                "countryCode": "US"
            },
            {
                "name": "Plymouth County",
                "countryCode": "US"
            },
            {
                "name": "Pocahontas",
                "countryCode": "US"
            },
            {
                "name": "Pocahontas County",
                "countryCode": "US"
            },
            {
                "name": "Polk City",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Postville",
                "countryCode": "US"
            },
            {
                "name": "Pottawattamie County",
                "countryCode": "US"
            },
            {
                "name": "Poweshiek County",
                "countryCode": "US"
            },
            {
                "name": "Prairie City",
                "countryCode": "US"
            },
            {
                "name": "Preston",
                "countryCode": "US"
            },
            {
                "name": "Primghar",
                "countryCode": "US"
            },
            {
                "name": "Red Oak",
                "countryCode": "US"
            },
            {
                "name": "Reinbeck",
                "countryCode": "US"
            },
            {
                "name": "Remsen",
                "countryCode": "US"
            },
            {
                "name": "Ringgold County",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Robins",
                "countryCode": "US"
            },
            {
                "name": "Rock Rapids",
                "countryCode": "US"
            },
            {
                "name": "Rock Valley",
                "countryCode": "US"
            },
            {
                "name": "Rockwell",
                "countryCode": "US"
            },
            {
                "name": "Rockwell City",
                "countryCode": "US"
            },
            {
                "name": "Roland",
                "countryCode": "US"
            },
            {
                "name": "Sac City",
                "countryCode": "US"
            },
            {
                "name": "Sac County",
                "countryCode": "US"
            },
            {
                "name": "Saint Ansgar",
                "countryCode": "US"
            },
            {
                "name": "Sanborn",
                "countryCode": "US"
            },
            {
                "name": "Saylorville",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Sergeant Bluff",
                "countryCode": "US"
            },
            {
                "name": "Sheffield",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Sheldon",
                "countryCode": "US"
            },
            {
                "name": "Shell Rock",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah",
                "countryCode": "US"
            },
            {
                "name": "Sibley",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Sigourney",
                "countryCode": "US"
            },
            {
                "name": "Sioux Center",
                "countryCode": "US"
            },
            {
                "name": "Sioux City",
                "countryCode": "US"
            },
            {
                "name": "Sioux County",
                "countryCode": "US"
            },
            {
                "name": "Slater",
                "countryCode": "US"
            },
            {
                "name": "Solon",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Spirit Lake",
                "countryCode": "US"
            },
            {
                "name": "Springville",
                "countryCode": "US"
            },
            {
                "name": "State Center",
                "countryCode": "US"
            },
            {
                "name": "Storm Lake",
                "countryCode": "US"
            },
            {
                "name": "Story City",
                "countryCode": "US"
            },
            {
                "name": "Story County",
                "countryCode": "US"
            },
            {
                "name": "Strawberry Point",
                "countryCode": "US"
            },
            {
                "name": "Stuart",
                "countryCode": "US"
            },
            {
                "name": "Sumner",
                "countryCode": "US"
            },
            {
                "name": "Tama",
                "countryCode": "US"
            },
            {
                "name": "Tama County",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Tiffin",
                "countryCode": "US"
            },
            {
                "name": "Tipton",
                "countryCode": "US"
            },
            {
                "name": "Toledo",
                "countryCode": "US"
            },
            {
                "name": "Traer",
                "countryCode": "US"
            },
            {
                "name": "Tripoli",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "University Heights",
                "countryCode": "US"
            },
            {
                "name": "Urbana",
                "countryCode": "US"
            },
            {
                "name": "Urbandale",
                "countryCode": "US"
            },
            {
                "name": "Van Buren County",
                "countryCode": "US"
            },
            {
                "name": "Van Meter",
                "countryCode": "US"
            },
            {
                "name": "Villisca",
                "countryCode": "US"
            },
            {
                "name": "Vinton",
                "countryCode": "US"
            },
            {
                "name": "Walcott",
                "countryCode": "US"
            },
            {
                "name": "Walford",
                "countryCode": "US"
            },
            {
                "name": "Wapello",
                "countryCode": "US"
            },
            {
                "name": "Wapello County",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Waukee",
                "countryCode": "US"
            },
            {
                "name": "Waukon",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webster City",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "Wellman",
                "countryCode": "US"
            },
            {
                "name": "West Branch",
                "countryCode": "US"
            },
            {
                "name": "West Burlington",
                "countryCode": "US"
            },
            {
                "name": "West Des Moines",
                "countryCode": "US"
            },
            {
                "name": "West Liberty",
                "countryCode": "US"
            },
            {
                "name": "West Union",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Wilton",
                "countryCode": "US"
            },
            {
                "name": "Windsor Heights",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Winnebago County",
                "countryCode": "US"
            },
            {
                "name": "Winneshiek County",
                "countryCode": "US"
            },
            {
                "name": "Winterset",
                "countryCode": "US"
            },
            {
                "name": "Woodbine",
                "countryCode": "US"
            },
            {
                "name": "Woodbury County",
                "countryCode": "US"
            },
            {
                "name": "Woodward",
                "countryCode": "US"
            },
            {
                "name": "Worth County",
                "countryCode": "US"
            },
            {
                "name": "Wright County",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Ada County",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "American Falls",
                "countryCode": "US"
            },
            {
                "name": "Ammon",
                "countryCode": "US"
            },
            {
                "name": "Arco",
                "countryCode": "US"
            },
            {
                "name": "Ashton",
                "countryCode": "US"
            },
            {
                "name": "Bannock County",
                "countryCode": "US"
            },
            {
                "name": "Bear Lake County",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Benewah County",
                "countryCode": "US"
            },
            {
                "name": "Bingham County",
                "countryCode": "US"
            },
            {
                "name": "Blackfoot",
                "countryCode": "US"
            },
            {
                "name": "Blaine County",
                "countryCode": "US"
            },
            {
                "name": "Boise",
                "countryCode": "US"
            },
            {
                "name": "Boise County",
                "countryCode": "US"
            },
            {
                "name": "Bonner County",
                "countryCode": "US"
            },
            {
                "name": "Bonners Ferry",
                "countryCode": "US"
            },
            {
                "name": "Bonneville County",
                "countryCode": "US"
            },
            {
                "name": "Boundary County",
                "countryCode": "US"
            },
            {
                "name": "Buhl",
                "countryCode": "US"
            },
            {
                "name": "Burley",
                "countryCode": "US"
            },
            {
                "name": "Butte County",
                "countryCode": "US"
            },
            {
                "name": "Caldwell",
                "countryCode": "US"
            },
            {
                "name": "Camas County",
                "countryCode": "US"
            },
            {
                "name": "Canyon County",
                "countryCode": "US"
            },
            {
                "name": "Caribou County",
                "countryCode": "US"
            },
            {
                "name": "Cascade",
                "countryCode": "US"
            },
            {
                "name": "Cassia County",
                "countryCode": "US"
            },
            {
                "name": "Challis",
                "countryCode": "US"
            },
            {
                "name": "Chubbuck",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clearwater County",
                "countryCode": "US"
            },
            {
                "name": "Coeur d'Alene",
                "countryCode": "US"
            },
            {
                "name": "Council",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Dalton Gardens",
                "countryCode": "US"
            },
            {
                "name": "Driggs",
                "countryCode": "US"
            },
            {
                "name": "Dubois",
                "countryCode": "US"
            },
            {
                "name": "Eagle",
                "countryCode": "US"
            },
            {
                "name": "Elmore County",
                "countryCode": "US"
            },
            {
                "name": "Emmett",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Filer",
                "countryCode": "US"
            },
            {
                "name": "Fort Hall",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fremont County",
                "countryCode": "US"
            },
            {
                "name": "Fruitland",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Gem County",
                "countryCode": "US"
            },
            {
                "name": "Glenns Ferry",
                "countryCode": "US"
            },
            {
                "name": "Gooding",
                "countryCode": "US"
            },
            {
                "name": "Gooding County",
                "countryCode": "US"
            },
            {
                "name": "Grangeville",
                "countryCode": "US"
            },
            {
                "name": "Hailey",
                "countryCode": "US"
            },
            {
                "name": "Hansen",
                "countryCode": "US"
            },
            {
                "name": "Hayden",
                "countryCode": "US"
            },
            {
                "name": "Heyburn",
                "countryCode": "US"
            },
            {
                "name": "Hidden Spring",
                "countryCode": "US"
            },
            {
                "name": "Homedale",
                "countryCode": "US"
            },
            {
                "name": "Idaho City",
                "countryCode": "US"
            },
            {
                "name": "Idaho County",
                "countryCode": "US"
            },
            {
                "name": "Idaho Falls",
                "countryCode": "US"
            },
            {
                "name": "Iona",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jerome",
                "countryCode": "US"
            },
            {
                "name": "Jerome County",
                "countryCode": "US"
            },
            {
                "name": "Kamiah",
                "countryCode": "US"
            },
            {
                "name": "Kellogg",
                "countryCode": "US"
            },
            {
                "name": "Ketchum",
                "countryCode": "US"
            },
            {
                "name": "Kimberly",
                "countryCode": "US"
            },
            {
                "name": "Kootenai County",
                "countryCode": "US"
            },
            {
                "name": "Kuna",
                "countryCode": "US"
            },
            {
                "name": "Lapwai",
                "countryCode": "US"
            },
            {
                "name": "Latah County",
                "countryCode": "US"
            },
            {
                "name": "Lemhi County",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Lewiston Orchards",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Malad City",
                "countryCode": "US"
            },
            {
                "name": "Marsing",
                "countryCode": "US"
            },
            {
                "name": "McCall",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Middleton",
                "countryCode": "US"
            },
            {
                "name": "Minidoka County",
                "countryCode": "US"
            },
            {
                "name": "Montpelier",
                "countryCode": "US"
            },
            {
                "name": "Moreland",
                "countryCode": "US"
            },
            {
                "name": "Moscow",
                "countryCode": "US"
            },
            {
                "name": "Mountain Home",
                "countryCode": "US"
            },
            {
                "name": "Murphy",
                "countryCode": "US"
            },
            {
                "name": "Nampa",
                "countryCode": "US"
            },
            {
                "name": "New Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Nez Perce County",
                "countryCode": "US"
            },
            {
                "name": "Nezperce",
                "countryCode": "US"
            },
            {
                "name": "Oneida County",
                "countryCode": "US"
            },
            {
                "name": "Orofino",
                "countryCode": "US"
            },
            {
                "name": "Osburn",
                "countryCode": "US"
            },
            {
                "name": "Owyhee County",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Parma",
                "countryCode": "US"
            },
            {
                "name": "Paul",
                "countryCode": "US"
            },
            {
                "name": "Payette",
                "countryCode": "US"
            },
            {
                "name": "Payette County",
                "countryCode": "US"
            },
            {
                "name": "Pinehurst",
                "countryCode": "US"
            },
            {
                "name": "Plummer",
                "countryCode": "US"
            },
            {
                "name": "Pocatello",
                "countryCode": "US"
            },
            {
                "name": "Ponderay",
                "countryCode": "US"
            },
            {
                "name": "Post Falls",
                "countryCode": "US"
            },
            {
                "name": "Power County",
                "countryCode": "US"
            },
            {
                "name": "Preston",
                "countryCode": "US"
            },
            {
                "name": "Priest River",
                "countryCode": "US"
            },
            {
                "name": "Rathdrum",
                "countryCode": "US"
            },
            {
                "name": "Rexburg",
                "countryCode": "US"
            },
            {
                "name": "Rigby",
                "countryCode": "US"
            },
            {
                "name": "Rupert",
                "countryCode": "US"
            },
            {
                "name": "Saint Anthony",
                "countryCode": "US"
            },
            {
                "name": "Saint Maries",
                "countryCode": "US"
            },
            {
                "name": "Salmon",
                "countryCode": "US"
            },
            {
                "name": "Sandpoint",
                "countryCode": "US"
            },
            {
                "name": "Shelley",
                "countryCode": "US"
            },
            {
                "name": "Shoshone",
                "countryCode": "US"
            },
            {
                "name": "Shoshone County",
                "countryCode": "US"
            },
            {
                "name": "Soda Springs",
                "countryCode": "US"
            },
            {
                "name": "Spirit Lake",
                "countryCode": "US"
            },
            {
                "name": "Star",
                "countryCode": "US"
            },
            {
                "name": "Sugar City",
                "countryCode": "US"
            },
            {
                "name": "Sun Valley",
                "countryCode": "US"
            },
            {
                "name": "Teton County",
                "countryCode": "US"
            },
            {
                "name": "Twin Falls",
                "countryCode": "US"
            },
            {
                "name": "Twin Falls County",
                "countryCode": "US"
            },
            {
                "name": "Tyhee",
                "countryCode": "US"
            },
            {
                "name": "Ucon",
                "countryCode": "US"
            },
            {
                "name": "Valley County",
                "countryCode": "US"
            },
            {
                "name": "Victor",
                "countryCode": "US"
            },
            {
                "name": "Wallace",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Weiser",
                "countryCode": "US"
            },
            {
                "name": "Wendell",
                "countryCode": "US"
            },
            {
                "name": "Wilder",
                "countryCode": "US"
            },
            {
                "name": "Abingdon",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Albany Park",
                "countryCode": "US"
            },
            {
                "name": "Albers",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Aledo",
                "countryCode": "US"
            },
            {
                "name": "Alexander County",
                "countryCode": "US"
            },
            {
                "name": "Algonquin",
                "countryCode": "US"
            },
            {
                "name": "Alorton",
                "countryCode": "US"
            },
            {
                "name": "Alsip",
                "countryCode": "US"
            },
            {
                "name": "Altamont",
                "countryCode": "US"
            },
            {
                "name": "Alton",
                "countryCode": "US"
            },
            {
                "name": "Amboy",
                "countryCode": "US"
            },
            {
                "name": "Andalusia",
                "countryCode": "US"
            },
            {
                "name": "Anna",
                "countryCode": "US"
            },
            {
                "name": "Antioch",
                "countryCode": "US"
            },
            {
                "name": "Arcola",
                "countryCode": "US"
            },
            {
                "name": "Arlington Heights",
                "countryCode": "US"
            },
            {
                "name": "Arthur",
                "countryCode": "US"
            },
            {
                "name": "Ashburn",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Assumption",
                "countryCode": "US"
            },
            {
                "name": "Astoria",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atlanta",
                "countryCode": "US"
            },
            {
                "name": "Atwood",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Auburn Gresham",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Aviston",
                "countryCode": "US"
            },
            {
                "name": "Avondale",
                "countryCode": "US"
            },
            {
                "name": "Bannockburn",
                "countryCode": "US"
            },
            {
                "name": "Barrington",
                "countryCode": "US"
            },
            {
                "name": "Barrington Hills",
                "countryCode": "US"
            },
            {
                "name": "Barry",
                "countryCode": "US"
            },
            {
                "name": "Bartlett",
                "countryCode": "US"
            },
            {
                "name": "Bartonville",
                "countryCode": "US"
            },
            {
                "name": "Batavia",
                "countryCode": "US"
            },
            {
                "name": "Beach Park",
                "countryCode": "US"
            },
            {
                "name": "Beardstown",
                "countryCode": "US"
            },
            {
                "name": "Beckemeyer",
                "countryCode": "US"
            },
            {
                "name": "Beecher",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Bellwood",
                "countryCode": "US"
            },
            {
                "name": "Belmont Cragin",
                "countryCode": "US"
            },
            {
                "name": "Belvidere",
                "countryCode": "US"
            },
            {
                "name": "Bement",
                "countryCode": "US"
            },
            {
                "name": "Benld",
                "countryCode": "US"
            },
            {
                "name": "Bensenville",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Berkeley",
                "countryCode": "US"
            },
            {
                "name": "Berwyn",
                "countryCode": "US"
            },
            {
                "name": "Bethalto",
                "countryCode": "US"
            },
            {
                "name": "Bethany",
                "countryCode": "US"
            },
            {
                "name": "Big Rock",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Bloomington",
                "countryCode": "US"
            },
            {
                "name": "Blue Island",
                "countryCode": "US"
            },
            {
                "name": "Blue Mound",
                "countryCode": "US"
            },
            {
                "name": "Bolingbrook",
                "countryCode": "US"
            },
            {
                "name": "Bond County",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Boulder Hill",
                "countryCode": "US"
            },
            {
                "name": "Bourbonnais",
                "countryCode": "US"
            },
            {
                "name": "Bradley",
                "countryCode": "US"
            },
            {
                "name": "Braidwood",
                "countryCode": "US"
            },
            {
                "name": "Breese",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Bridgeview",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Brighton Park",
                "countryCode": "US"
            },
            {
                "name": "Broadview",
                "countryCode": "US"
            },
            {
                "name": "Brookfield",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo Grove",
                "countryCode": "US"
            },
            {
                "name": "Bull Valley",
                "countryCode": "US"
            },
            {
                "name": "Bunker Hill",
                "countryCode": "US"
            },
            {
                "name": "Burbank",
                "countryCode": "US"
            },
            {
                "name": "Bureau County",
                "countryCode": "US"
            },
            {
                "name": "Burnham",
                "countryCode": "US"
            },
            {
                "name": "Burr Ridge",
                "countryCode": "US"
            },
            {
                "name": "Bushnell",
                "countryCode": "US"
            },
            {
                "name": "Byron",
                "countryCode": "US"
            },
            {
                "name": "Cahokia",
                "countryCode": "US"
            },
            {
                "name": "Cairo",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Calumet City",
                "countryCode": "US"
            },
            {
                "name": "Calumet Park",
                "countryCode": "US"
            },
            {
                "name": "Cambria",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Camp Point",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Capron",
                "countryCode": "US"
            },
            {
                "name": "Carbon Cliff",
                "countryCode": "US"
            },
            {
                "name": "Carbondale",
                "countryCode": "US"
            },
            {
                "name": "Carlinville",
                "countryCode": "US"
            },
            {
                "name": "Carlyle",
                "countryCode": "US"
            },
            {
                "name": "Carmi",
                "countryCode": "US"
            },
            {
                "name": "Carol Stream",
                "countryCode": "US"
            },
            {
                "name": "Carpentersville",
                "countryCode": "US"
            },
            {
                "name": "Carrier Mills",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carterville",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Cary",
                "countryCode": "US"
            },
            {
                "name": "Casey",
                "countryCode": "US"
            },
            {
                "name": "Caseyville",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Catlin",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Centralia",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Cerro Gordo",
                "countryCode": "US"
            },
            {
                "name": "Champaign",
                "countryCode": "US"
            },
            {
                "name": "Champaign County",
                "countryCode": "US"
            },
            {
                "name": "Channahon",
                "countryCode": "US"
            },
            {
                "name": "Channel Lake",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Chatham",
                "countryCode": "US"
            },
            {
                "name": "Chatsworth",
                "countryCode": "US"
            },
            {
                "name": "Chebanse",
                "countryCode": "US"
            },
            {
                "name": "Chenoa",
                "countryCode": "US"
            },
            {
                "name": "Cherry Valley",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chicago",
                "countryCode": "US"
            },
            {
                "name": "Chicago Heights",
                "countryCode": "US"
            },
            {
                "name": "Chicago Lawn",
                "countryCode": "US"
            },
            {
                "name": "Chicago Loop",
                "countryCode": "US"
            },
            {
                "name": "Chicago Ridge",
                "countryCode": "US"
            },
            {
                "name": "Chillicothe",
                "countryCode": "US"
            },
            {
                "name": "Chrisman",
                "countryCode": "US"
            },
            {
                "name": "Christian County",
                "countryCode": "US"
            },
            {
                "name": "Christopher",
                "countryCode": "US"
            },
            {
                "name": "Cicero",
                "countryCode": "US"
            },
            {
                "name": "Clarendon Hills",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Coal City",
                "countryCode": "US"
            },
            {
                "name": "Coal Valley",
                "countryCode": "US"
            },
            {
                "name": "Cobden",
                "countryCode": "US"
            },
            {
                "name": "Colchester",
                "countryCode": "US"
            },
            {
                "name": "Coles County",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Colona",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Cook County",
                "countryCode": "US"
            },
            {
                "name": "Cortland",
                "countryCode": "US"
            },
            {
                "name": "Country Club Hills",
                "countryCode": "US"
            },
            {
                "name": "Countryside",
                "countryCode": "US"
            },
            {
                "name": "Crainville",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crest Hill",
                "countryCode": "US"
            },
            {
                "name": "Crestwood",
                "countryCode": "US"
            },
            {
                "name": "Crete",
                "countryCode": "US"
            },
            {
                "name": "Creve Coeur",
                "countryCode": "US"
            },
            {
                "name": "Crystal Lake",
                "countryCode": "US"
            },
            {
                "name": "Crystal Lawns",
                "countryCode": "US"
            },
            {
                "name": "Cuba",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Danvers",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Darien",
                "countryCode": "US"
            },
            {
                "name": "Davis Junction",
                "countryCode": "US"
            },
            {
                "name": "De Soto",
                "countryCode": "US"
            },
            {
                "name": "De Witt County",
                "countryCode": "US"
            },
            {
                "name": "DeKalb",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Deerfield",
                "countryCode": "US"
            },
            {
                "name": "Delavan",
                "countryCode": "US"
            },
            {
                "name": "Depue",
                "countryCode": "US"
            },
            {
                "name": "Des Plaines",
                "countryCode": "US"
            },
            {
                "name": "Diamond",
                "countryCode": "US"
            },
            {
                "name": "Divernon",
                "countryCode": "US"
            },
            {
                "name": "Dixmoor",
                "countryCode": "US"
            },
            {
                "name": "Dixon",
                "countryCode": "US"
            },
            {
                "name": "Dolton",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Downers Grove",
                "countryCode": "US"
            },
            {
                "name": "Du Quoin",
                "countryCode": "US"
            },
            {
                "name": "DuPage County",
                "countryCode": "US"
            },
            {
                "name": "Dunlap",
                "countryCode": "US"
            },
            {
                "name": "Dupo",
                "countryCode": "US"
            },
            {
                "name": "Durand",
                "countryCode": "US"
            },
            {
                "name": "Dwight",
                "countryCode": "US"
            },
            {
                "name": "Earlville",
                "countryCode": "US"
            },
            {
                "name": "East Alton",
                "countryCode": "US"
            },
            {
                "name": "East Dubuque",
                "countryCode": "US"
            },
            {
                "name": "East Dundee",
                "countryCode": "US"
            },
            {
                "name": "East Garfield Park",
                "countryCode": "US"
            },
            {
                "name": "East Hazel Crest",
                "countryCode": "US"
            },
            {
                "name": "East Moline",
                "countryCode": "US"
            },
            {
                "name": "East Peoria",
                "countryCode": "US"
            },
            {
                "name": "East Saint Louis",
                "countryCode": "US"
            },
            {
                "name": "Edgar County",
                "countryCode": "US"
            },
            {
                "name": "Edgewater",
                "countryCode": "US"
            },
            {
                "name": "Edinburg",
                "countryCode": "US"
            },
            {
                "name": "Edwards County",
                "countryCode": "US"
            },
            {
                "name": "Edwardsville",
                "countryCode": "US"
            },
            {
                "name": "Effingham",
                "countryCode": "US"
            },
            {
                "name": "Effingham County",
                "countryCode": "US"
            },
            {
                "name": "El Paso",
                "countryCode": "US"
            },
            {
                "name": "Elburn",
                "countryCode": "US"
            },
            {
                "name": "Eldorado",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Elizabethtown",
                "countryCode": "US"
            },
            {
                "name": "Elk Grove Village",
                "countryCode": "US"
            },
            {
                "name": "Elmhurst",
                "countryCode": "US"
            },
            {
                "name": "Elmwood",
                "countryCode": "US"
            },
            {
                "name": "Elmwood Park",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Energy",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Erie",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Evanston",
                "countryCode": "US"
            },
            {
                "name": "Evergreen Park",
                "countryCode": "US"
            },
            {
                "name": "Fairbury",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairmont",
                "countryCode": "US"
            },
            {
                "name": "Fairmont City",
                "countryCode": "US"
            },
            {
                "name": "Fairview Heights",
                "countryCode": "US"
            },
            {
                "name": "Farmer City",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fisher",
                "countryCode": "US"
            },
            {
                "name": "Flanagan",
                "countryCode": "US"
            },
            {
                "name": "Flora",
                "countryCode": "US"
            },
            {
                "name": "Flossmoor",
                "countryCode": "US"
            },
            {
                "name": "Ford County",
                "countryCode": "US"
            },
            {
                "name": "Ford Heights",
                "countryCode": "US"
            },
            {
                "name": "Forest Lake",
                "countryCode": "US"
            },
            {
                "name": "Forest Park",
                "countryCode": "US"
            },
            {
                "name": "Forrest",
                "countryCode": "US"
            },
            {
                "name": "Forreston",
                "countryCode": "US"
            },
            {
                "name": "Forsyth",
                "countryCode": "US"
            },
            {
                "name": "Fox Lake",
                "countryCode": "US"
            },
            {
                "name": "Fox Lake Hills",
                "countryCode": "US"
            },
            {
                "name": "Fox River Grove",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Frankfort Square",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklin Park",
                "countryCode": "US"
            },
            {
                "name": "Freeburg",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Gage Park",
                "countryCode": "US"
            },
            {
                "name": "Gages Lake",
                "countryCode": "US"
            },
            {
                "name": "Galena",
                "countryCode": "US"
            },
            {
                "name": "Galesburg",
                "countryCode": "US"
            },
            {
                "name": "Gallatin County",
                "countryCode": "US"
            },
            {
                "name": "Galva",
                "countryCode": "US"
            },
            {
                "name": "Gardner",
                "countryCode": "US"
            },
            {
                "name": "Geneseo",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Genoa",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Germantown",
                "countryCode": "US"
            },
            {
                "name": "Germantown Hills",
                "countryCode": "US"
            },
            {
                "name": "Gibson City",
                "countryCode": "US"
            },
            {
                "name": "Gifford",
                "countryCode": "US"
            },
            {
                "name": "Gilberts",
                "countryCode": "US"
            },
            {
                "name": "Gillespie",
                "countryCode": "US"
            },
            {
                "name": "Gilman",
                "countryCode": "US"
            },
            {
                "name": "Girard",
                "countryCode": "US"
            },
            {
                "name": "Glasford",
                "countryCode": "US"
            },
            {
                "name": "Glen Carbon",
                "countryCode": "US"
            },
            {
                "name": "Glen Ellyn",
                "countryCode": "US"
            },
            {
                "name": "Glencoe",
                "countryCode": "US"
            },
            {
                "name": "Glendale Heights",
                "countryCode": "US"
            },
            {
                "name": "Glenview",
                "countryCode": "US"
            },
            {
                "name": "Glenwood",
                "countryCode": "US"
            },
            {
                "name": "Godfrey",
                "countryCode": "US"
            },
            {
                "name": "Golconda",
                "countryCode": "US"
            },
            {
                "name": "Goodings Grove",
                "countryCode": "US"
            },
            {
                "name": "Goreville",
                "countryCode": "US"
            },
            {
                "name": "Grand Boulevard",
                "countryCode": "US"
            },
            {
                "name": "Grandview",
                "countryCode": "US"
            },
            {
                "name": "Grandwood Park",
                "countryCode": "US"
            },
            {
                "name": "Granite City",
                "countryCode": "US"
            },
            {
                "name": "Grant Park",
                "countryCode": "US"
            },
            {
                "name": "Granville",
                "countryCode": "US"
            },
            {
                "name": "Grayslake",
                "countryCode": "US"
            },
            {
                "name": "Grayville",
                "countryCode": "US"
            },
            {
                "name": "Greater Grand Crossing",
                "countryCode": "US"
            },
            {
                "name": "Green Oaks",
                "countryCode": "US"
            },
            {
                "name": "Green Rock",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenup",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Gridley",
                "countryCode": "US"
            },
            {
                "name": "Griggsville",
                "countryCode": "US"
            },
            {
                "name": "Grundy County",
                "countryCode": "US"
            },
            {
                "name": "Gurnee",
                "countryCode": "US"
            },
            {
                "name": "Hainesville",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hampshire",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hanna City",
                "countryCode": "US"
            },
            {
                "name": "Hanover Park",
                "countryCode": "US"
            },
            {
                "name": "Hardin",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Harristown",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Harvard",
                "countryCode": "US"
            },
            {
                "name": "Harvey",
                "countryCode": "US"
            },
            {
                "name": "Harwood Heights",
                "countryCode": "US"
            },
            {
                "name": "Havana",
                "countryCode": "US"
            },
            {
                "name": "Hawthorn Woods",
                "countryCode": "US"
            },
            {
                "name": "Hazel Crest",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Henderson County",
                "countryCode": "US"
            },
            {
                "name": "Hennepin",
                "countryCode": "US"
            },
            {
                "name": "Henry",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Heritage Lake",
                "countryCode": "US"
            },
            {
                "name": "Herrin",
                "countryCode": "US"
            },
            {
                "name": "Herscher",
                "countryCode": "US"
            },
            {
                "name": "Heyworth",
                "countryCode": "US"
            },
            {
                "name": "Hickory Hills",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Highland Park",
                "countryCode": "US"
            },
            {
                "name": "Highwood",
                "countryCode": "US"
            },
            {
                "name": "Hillcrest",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hillside",
                "countryCode": "US"
            },
            {
                "name": "Hinckley",
                "countryCode": "US"
            },
            {
                "name": "Hinsdale",
                "countryCode": "US"
            },
            {
                "name": "Hodgkins",
                "countryCode": "US"
            },
            {
                "name": "Hoffman Estates",
                "countryCode": "US"
            },
            {
                "name": "Holiday Shores",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Homer Glen",
                "countryCode": "US"
            },
            {
                "name": "Hometown",
                "countryCode": "US"
            },
            {
                "name": "Homewood",
                "countryCode": "US"
            },
            {
                "name": "Hoopeston",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Huntley",
                "countryCode": "US"
            },
            {
                "name": "Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "Ina",
                "countryCode": "US"
            },
            {
                "name": "Indian Head Park",
                "countryCode": "US"
            },
            {
                "name": "Ingalls Park",
                "countryCode": "US"
            },
            {
                "name": "Inverness",
                "countryCode": "US"
            },
            {
                "name": "Iroquois County",
                "countryCode": "US"
            },
            {
                "name": "Irving Park",
                "countryCode": "US"
            },
            {
                "name": "Island Lake",
                "countryCode": "US"
            },
            {
                "name": "Itasca",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jerome",
                "countryCode": "US"
            },
            {
                "name": "Jersey County",
                "countryCode": "US"
            },
            {
                "name": "Jerseyville",
                "countryCode": "US"
            },
            {
                "name": "Jo Daviess County",
                "countryCode": "US"
            },
            {
                "name": "Johnsburg",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Johnston City",
                "countryCode": "US"
            },
            {
                "name": "Joliet",
                "countryCode": "US"
            },
            {
                "name": "Jonesboro",
                "countryCode": "US"
            },
            {
                "name": "Justice",
                "countryCode": "US"
            },
            {
                "name": "Kane County",
                "countryCode": "US"
            },
            {
                "name": "Kankakee",
                "countryCode": "US"
            },
            {
                "name": "Kankakee County",
                "countryCode": "US"
            },
            {
                "name": "Kendall County",
                "countryCode": "US"
            },
            {
                "name": "Kenilworth",
                "countryCode": "US"
            },
            {
                "name": "Kenwood",
                "countryCode": "US"
            },
            {
                "name": "Kewanee",
                "countryCode": "US"
            },
            {
                "name": "Kildeer",
                "countryCode": "US"
            },
            {
                "name": "Kincaid",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kirkland",
                "countryCode": "US"
            },
            {
                "name": "Knollwood",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "Knoxville",
                "countryCode": "US"
            },
            {
                "name": "La Grange",
                "countryCode": "US"
            },
            {
                "name": "La Grange Park",
                "countryCode": "US"
            },
            {
                "name": "La Harpe",
                "countryCode": "US"
            },
            {
                "name": "La Salle",
                "countryCode": "US"
            },
            {
                "name": "LaSalle County",
                "countryCode": "US"
            },
            {
                "name": "Lacon",
                "countryCode": "US"
            },
            {
                "name": "Ladd",
                "countryCode": "US"
            },
            {
                "name": "Lake Barrington",
                "countryCode": "US"
            },
            {
                "name": "Lake Bluff",
                "countryCode": "US"
            },
            {
                "name": "Lake Camelot",
                "countryCode": "US"
            },
            {
                "name": "Lake Catherine",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Forest",
                "countryCode": "US"
            },
            {
                "name": "Lake Holiday",
                "countryCode": "US"
            },
            {
                "name": "Lake Summerset",
                "countryCode": "US"
            },
            {
                "name": "Lake Villa",
                "countryCode": "US"
            },
            {
                "name": "Lake Zurich",
                "countryCode": "US"
            },
            {
                "name": "Lake in the Hills",
                "countryCode": "US"
            },
            {
                "name": "Lake of the Woods",
                "countryCode": "US"
            },
            {
                "name": "Lakemoor",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lakewood Shores",
                "countryCode": "US"
            },
            {
                "name": "Lanark",
                "countryCode": "US"
            },
            {
                "name": "Lansing",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceville",
                "countryCode": "US"
            },
            {
                "name": "Le Roy",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leland Grove",
                "countryCode": "US"
            },
            {
                "name": "Lemont",
                "countryCode": "US"
            },
            {
                "name": "Lena",
                "countryCode": "US"
            },
            {
                "name": "Lewistown",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Libertyville",
                "countryCode": "US"
            },
            {
                "name": "Lily Lake",
                "countryCode": "US"
            },
            {
                "name": "Limestone",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Square",
                "countryCode": "US"
            },
            {
                "name": "Lincolnshire",
                "countryCode": "US"
            },
            {
                "name": "Lincolnwood",
                "countryCode": "US"
            },
            {
                "name": "Lindenhurst",
                "countryCode": "US"
            },
            {
                "name": "Lisle",
                "countryCode": "US"
            },
            {
                "name": "Litchfield",
                "countryCode": "US"
            },
            {
                "name": "Livingston County",
                "countryCode": "US"
            },
            {
                "name": "Lockport",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Logan Square",
                "countryCode": "US"
            },
            {
                "name": "Lombard",
                "countryCode": "US"
            },
            {
                "name": "Long Creek",
                "countryCode": "US"
            },
            {
                "name": "Long Grove",
                "countryCode": "US"
            },
            {
                "name": "Long Lake",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Loves Park",
                "countryCode": "US"
            },
            {
                "name": "Lovington",
                "countryCode": "US"
            },
            {
                "name": "Lower West Side",
                "countryCode": "US"
            },
            {
                "name": "Lynwood",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Machesney Park",
                "countryCode": "US"
            },
            {
                "name": "Mackinaw",
                "countryCode": "US"
            },
            {
                "name": "Macomb",
                "countryCode": "US"
            },
            {
                "name": "Macon",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Macoupin County",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Mahomet",
                "countryCode": "US"
            },
            {
                "name": "Malta",
                "countryCode": "US"
            },
            {
                "name": "Manhattan",
                "countryCode": "US"
            },
            {
                "name": "Manito",
                "countryCode": "US"
            },
            {
                "name": "Manteno",
                "countryCode": "US"
            },
            {
                "name": "Maple Park",
                "countryCode": "US"
            },
            {
                "name": "Marengo",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marissa",
                "countryCode": "US"
            },
            {
                "name": "Markham",
                "countryCode": "US"
            },
            {
                "name": "Maroa",
                "countryCode": "US"
            },
            {
                "name": "Marquette Heights",
                "countryCode": "US"
            },
            {
                "name": "Marseilles",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martinsville",
                "countryCode": "US"
            },
            {
                "name": "Maryville",
                "countryCode": "US"
            },
            {
                "name": "Mascoutah",
                "countryCode": "US"
            },
            {
                "name": "Mason City",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "Massac County",
                "countryCode": "US"
            },
            {
                "name": "Matteson",
                "countryCode": "US"
            },
            {
                "name": "Mattoon",
                "countryCode": "US"
            },
            {
                "name": "Maywood",
                "countryCode": "US"
            },
            {
                "name": "McCullom Lake",
                "countryCode": "US"
            },
            {
                "name": "McDonough County",
                "countryCode": "US"
            },
            {
                "name": "McHenry",
                "countryCode": "US"
            },
            {
                "name": "McHenry County",
                "countryCode": "US"
            },
            {
                "name": "McKinley Park",
                "countryCode": "US"
            },
            {
                "name": "McLean County",
                "countryCode": "US"
            },
            {
                "name": "McLeansboro",
                "countryCode": "US"
            },
            {
                "name": "Melrose Park",
                "countryCode": "US"
            },
            {
                "name": "Menard County",
                "countryCode": "US"
            },
            {
                "name": "Mendota",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Meredosia",
                "countryCode": "US"
            },
            {
                "name": "Merrionette Park",
                "countryCode": "US"
            },
            {
                "name": "Metamora",
                "countryCode": "US"
            },
            {
                "name": "Metropolis",
                "countryCode": "US"
            },
            {
                "name": "Midlothian",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millstadt",
                "countryCode": "US"
            },
            {
                "name": "Minier",
                "countryCode": "US"
            },
            {
                "name": "Minonk",
                "countryCode": "US"
            },
            {
                "name": "Minooka",
                "countryCode": "US"
            },
            {
                "name": "Mitchell",
                "countryCode": "US"
            },
            {
                "name": "Mokena",
                "countryCode": "US"
            },
            {
                "name": "Moline",
                "countryCode": "US"
            },
            {
                "name": "Momence",
                "countryCode": "US"
            },
            {
                "name": "Monee",
                "countryCode": "US"
            },
            {
                "name": "Monmouth",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morgan Park",
                "countryCode": "US"
            },
            {
                "name": "Morris",
                "countryCode": "US"
            },
            {
                "name": "Morrison",
                "countryCode": "US"
            },
            {
                "name": "Morrisonville",
                "countryCode": "US"
            },
            {
                "name": "Morton",
                "countryCode": "US"
            },
            {
                "name": "Morton Grove",
                "countryCode": "US"
            },
            {
                "name": "Moultrie County",
                "countryCode": "US"
            },
            {
                "name": "Mound City",
                "countryCode": "US"
            },
            {
                "name": "Mount Carmel",
                "countryCode": "US"
            },
            {
                "name": "Mount Carroll",
                "countryCode": "US"
            },
            {
                "name": "Mount Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Mount Morris",
                "countryCode": "US"
            },
            {
                "name": "Mount Olive",
                "countryCode": "US"
            },
            {
                "name": "Mount Prospect",
                "countryCode": "US"
            },
            {
                "name": "Mount Pulaski",
                "countryCode": "US"
            },
            {
                "name": "Mount Sterling",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mount Zion",
                "countryCode": "US"
            },
            {
                "name": "Moweaqua",
                "countryCode": "US"
            },
            {
                "name": "Mundelein",
                "countryCode": "US"
            },
            {
                "name": "Murphysboro",
                "countryCode": "US"
            },
            {
                "name": "Naperville",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Nauvoo",
                "countryCode": "US"
            },
            {
                "name": "Near North Side",
                "countryCode": "US"
            },
            {
                "name": "Near South Side",
                "countryCode": "US"
            },
            {
                "name": "Neoga",
                "countryCode": "US"
            },
            {
                "name": "New Athens",
                "countryCode": "US"
            },
            {
                "name": "New Baden",
                "countryCode": "US"
            },
            {
                "name": "New Berlin",
                "countryCode": "US"
            },
            {
                "name": "New City",
                "countryCode": "US"
            },
            {
                "name": "New Lenox",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Niles",
                "countryCode": "US"
            },
            {
                "name": "Nokomis",
                "countryCode": "US"
            },
            {
                "name": "Normal",
                "countryCode": "US"
            },
            {
                "name": "Norridge",
                "countryCode": "US"
            },
            {
                "name": "Norris City",
                "countryCode": "US"
            },
            {
                "name": "North Aurora",
                "countryCode": "US"
            },
            {
                "name": "North Barrington",
                "countryCode": "US"
            },
            {
                "name": "North Center",
                "countryCode": "US"
            },
            {
                "name": "North Chicago",
                "countryCode": "US"
            },
            {
                "name": "North Lawndale",
                "countryCode": "US"
            },
            {
                "name": "North Pekin",
                "countryCode": "US"
            },
            {
                "name": "North Peoria",
                "countryCode": "US"
            },
            {
                "name": "North Riverside",
                "countryCode": "US"
            },
            {
                "name": "Northbrook",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Northlake",
                "countryCode": "US"
            },
            {
                "name": "O'Fallon",
                "countryCode": "US"
            },
            {
                "name": "Oak Brook",
                "countryCode": "US"
            },
            {
                "name": "Oak Forest",
                "countryCode": "US"
            },
            {
                "name": "Oak Lawn",
                "countryCode": "US"
            },
            {
                "name": "Oak Park",
                "countryCode": "US"
            },
            {
                "name": "Oakbrook Terrace",
                "countryCode": "US"
            },
            {
                "name": "Oakwood",
                "countryCode": "US"
            },
            {
                "name": "Oakwood Hills",
                "countryCode": "US"
            },
            {
                "name": "Oblong",
                "countryCode": "US"
            },
            {
                "name": "Odin",
                "countryCode": "US"
            },
            {
                "name": "Ogle County",
                "countryCode": "US"
            },
            {
                "name": "Oglesby",
                "countryCode": "US"
            },
            {
                "name": "Okawville",
                "countryCode": "US"
            },
            {
                "name": "Olney",
                "countryCode": "US"
            },
            {
                "name": "Olympia Fields",
                "countryCode": "US"
            },
            {
                "name": "Onarga",
                "countryCode": "US"
            },
            {
                "name": "Oquawka",
                "countryCode": "US"
            },
            {
                "name": "Oregon",
                "countryCode": "US"
            },
            {
                "name": "Orion",
                "countryCode": "US"
            },
            {
                "name": "Orland Hills",
                "countryCode": "US"
            },
            {
                "name": "Orland Park",
                "countryCode": "US"
            },
            {
                "name": "Oswego",
                "countryCode": "US"
            },
            {
                "name": "Ottawa",
                "countryCode": "US"
            },
            {
                "name": "Palatine",
                "countryCode": "US"
            },
            {
                "name": "Palestine",
                "countryCode": "US"
            },
            {
                "name": "Palos Heights",
                "countryCode": "US"
            },
            {
                "name": "Palos Hills",
                "countryCode": "US"
            },
            {
                "name": "Palos Park",
                "countryCode": "US"
            },
            {
                "name": "Pana",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Park City",
                "countryCode": "US"
            },
            {
                "name": "Park Forest",
                "countryCode": "US"
            },
            {
                "name": "Park Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pawnee",
                "countryCode": "US"
            },
            {
                "name": "Paxton",
                "countryCode": "US"
            },
            {
                "name": "Payson",
                "countryCode": "US"
            },
            {
                "name": "Pecatonica",
                "countryCode": "US"
            },
            {
                "name": "Pekin",
                "countryCode": "US"
            },
            {
                "name": "Peoria",
                "countryCode": "US"
            },
            {
                "name": "Peoria County",
                "countryCode": "US"
            },
            {
                "name": "Peoria Heights",
                "countryCode": "US"
            },
            {
                "name": "Peotone",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Peru",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Philo",
                "countryCode": "US"
            },
            {
                "name": "Phoenix",
                "countryCode": "US"
            },
            {
                "name": "Piatt County",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pinckneyville",
                "countryCode": "US"
            },
            {
                "name": "Pingree Grove",
                "countryCode": "US"
            },
            {
                "name": "Pistakee Highlands",
                "countryCode": "US"
            },
            {
                "name": "Pittsfield",
                "countryCode": "US"
            },
            {
                "name": "Plainfield",
                "countryCode": "US"
            },
            {
                "name": "Plano",
                "countryCode": "US"
            },
            {
                "name": "Polo",
                "countryCode": "US"
            },
            {
                "name": "Pontiac",
                "countryCode": "US"
            },
            {
                "name": "Pontoon Beach",
                "countryCode": "US"
            },
            {
                "name": "Pope County",
                "countryCode": "US"
            },
            {
                "name": "Poplar Grove",
                "countryCode": "US"
            },
            {
                "name": "Port Barrington",
                "countryCode": "US"
            },
            {
                "name": "Port Byron",
                "countryCode": "US"
            },
            {
                "name": "Portage Park",
                "countryCode": "US"
            },
            {
                "name": "Posen",
                "countryCode": "US"
            },
            {
                "name": "Prairie Grove",
                "countryCode": "US"
            },
            {
                "name": "Prestbury",
                "countryCode": "US"
            },
            {
                "name": "Preston Heights",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Princeville",
                "countryCode": "US"
            },
            {
                "name": "Prophetstown",
                "countryCode": "US"
            },
            {
                "name": "Prospect Heights",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Ramsey",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Rantoul",
                "countryCode": "US"
            },
            {
                "name": "Red Bud",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Richton Park",
                "countryCode": "US"
            },
            {
                "name": "River Forest",
                "countryCode": "US"
            },
            {
                "name": "River Grove",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Riverton",
                "countryCode": "US"
            },
            {
                "name": "Riverwoods",
                "countryCode": "US"
            },
            {
                "name": "Roanoke",
                "countryCode": "US"
            },
            {
                "name": "Robbins",
                "countryCode": "US"
            },
            {
                "name": "Robinson",
                "countryCode": "US"
            },
            {
                "name": "Rochelle",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rock Falls",
                "countryCode": "US"
            },
            {
                "name": "Rock Island",
                "countryCode": "US"
            },
            {
                "name": "Rock Island County",
                "countryCode": "US"
            },
            {
                "name": "Rockdale",
                "countryCode": "US"
            },
            {
                "name": "Rockford",
                "countryCode": "US"
            },
            {
                "name": "Rockton",
                "countryCode": "US"
            },
            {
                "name": "Rogers Park",
                "countryCode": "US"
            },
            {
                "name": "Rolling Meadows",
                "countryCode": "US"
            },
            {
                "name": "Rome",
                "countryCode": "US"
            },
            {
                "name": "Romeoville",
                "countryCode": "US"
            },
            {
                "name": "Roodhouse",
                "countryCode": "US"
            },
            {
                "name": "Roscoe",
                "countryCode": "US"
            },
            {
                "name": "Roselle",
                "countryCode": "US"
            },
            {
                "name": "Rosemont",
                "countryCode": "US"
            },
            {
                "name": "Rosewood Heights",
                "countryCode": "US"
            },
            {
                "name": "Rosiclare",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Round Lake",
                "countryCode": "US"
            },
            {
                "name": "Round Lake Beach",
                "countryCode": "US"
            },
            {
                "name": "Round Lake Heights",
                "countryCode": "US"
            },
            {
                "name": "Round Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Roxana",
                "countryCode": "US"
            },
            {
                "name": "Royalton",
                "countryCode": "US"
            },
            {
                "name": "Rushville",
                "countryCode": "US"
            },
            {
                "name": "Saint Anne",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair County",
                "countryCode": "US"
            },
            {
                "name": "Saint Elmo",
                "countryCode": "US"
            },
            {
                "name": "Saint Jacob",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Saline County",
                "countryCode": "US"
            },
            {
                "name": "Sandoval",
                "countryCode": "US"
            },
            {
                "name": "Sandwich",
                "countryCode": "US"
            },
            {
                "name": "Sangamon County",
                "countryCode": "US"
            },
            {
                "name": "Sauk Village",
                "countryCode": "US"
            },
            {
                "name": "Savanna",
                "countryCode": "US"
            },
            {
                "name": "Savoy",
                "countryCode": "US"
            },
            {
                "name": "Schaumburg",
                "countryCode": "US"
            },
            {
                "name": "Schiller Park",
                "countryCode": "US"
            },
            {
                "name": "Schuyler County",
                "countryCode": "US"
            },
            {
                "name": "Scott Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Seneca",
                "countryCode": "US"
            },
            {
                "name": "Sesser",
                "countryCode": "US"
            },
            {
                "name": "Shawneetown",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shelbyville",
                "countryCode": "US"
            },
            {
                "name": "Sheldon",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sherman",
                "countryCode": "US"
            },
            {
                "name": "Shiloh",
                "countryCode": "US"
            },
            {
                "name": "Shorewood",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Silvis",
                "countryCode": "US"
            },
            {
                "name": "Skokie",
                "countryCode": "US"
            },
            {
                "name": "Sleepy Hollow",
                "countryCode": "US"
            },
            {
                "name": "Smithton",
                "countryCode": "US"
            },
            {
                "name": "Somonauk",
                "countryCode": "US"
            },
            {
                "name": "South Barrington",
                "countryCode": "US"
            },
            {
                "name": "South Beloit",
                "countryCode": "US"
            },
            {
                "name": "South Chicago",
                "countryCode": "US"
            },
            {
                "name": "South Chicago Heights",
                "countryCode": "US"
            },
            {
                "name": "South Elgin",
                "countryCode": "US"
            },
            {
                "name": "South Holland",
                "countryCode": "US"
            },
            {
                "name": "South Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "South Lawndale",
                "countryCode": "US"
            },
            {
                "name": "South Pekin",
                "countryCode": "US"
            },
            {
                "name": "South Roxana",
                "countryCode": "US"
            },
            {
                "name": "South Shore",
                "countryCode": "US"
            },
            {
                "name": "Southern View",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spring Grove",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stark County",
                "countryCode": "US"
            },
            {
                "name": "Staunton",
                "countryCode": "US"
            },
            {
                "name": "Steeleville",
                "countryCode": "US"
            },
            {
                "name": "Steger",
                "countryCode": "US"
            },
            {
                "name": "Stephenson County",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Stickney",
                "countryCode": "US"
            },
            {
                "name": "Stillman Valley",
                "countryCode": "US"
            },
            {
                "name": "Stockton",
                "countryCode": "US"
            },
            {
                "name": "Stone Park",
                "countryCode": "US"
            },
            {
                "name": "Streamwood",
                "countryCode": "US"
            },
            {
                "name": "Streator",
                "countryCode": "US"
            },
            {
                "name": "Sugar Grove",
                "countryCode": "US"
            },
            {
                "name": "Sullivan",
                "countryCode": "US"
            },
            {
                "name": "Summit",
                "countryCode": "US"
            },
            {
                "name": "Sumner",
                "countryCode": "US"
            },
            {
                "name": "Swansea",
                "countryCode": "US"
            },
            {
                "name": "Sycamore",
                "countryCode": "US"
            },
            {
                "name": "Taylorville",
                "countryCode": "US"
            },
            {
                "name": "Tazewell County",
                "countryCode": "US"
            },
            {
                "name": "Teutopolis",
                "countryCode": "US"
            },
            {
                "name": "The Galena Territory",
                "countryCode": "US"
            },
            {
                "name": "Third Lake",
                "countryCode": "US"
            },
            {
                "name": "Thomasboro",
                "countryCode": "US"
            },
            {
                "name": "Thornton",
                "countryCode": "US"
            },
            {
                "name": "Tilton",
                "countryCode": "US"
            },
            {
                "name": "Tinley Park",
                "countryCode": "US"
            },
            {
                "name": "Toledo",
                "countryCode": "US"
            },
            {
                "name": "Tolono",
                "countryCode": "US"
            },
            {
                "name": "Toluca",
                "countryCode": "US"
            },
            {
                "name": "Toulon",
                "countryCode": "US"
            },
            {
                "name": "Tower Lake",
                "countryCode": "US"
            },
            {
                "name": "Tremont",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tuscola",
                "countryCode": "US"
            },
            {
                "name": "Twin Grove",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "University Park",
                "countryCode": "US"
            },
            {
                "name": "Upper Alton",
                "countryCode": "US"
            },
            {
                "name": "Uptown",
                "countryCode": "US"
            },
            {
                "name": "Urbana",
                "countryCode": "US"
            },
            {
                "name": "Valmeyer",
                "countryCode": "US"
            },
            {
                "name": "Vandalia",
                "countryCode": "US"
            },
            {
                "name": "Venetian Village",
                "countryCode": "US"
            },
            {
                "name": "Venice",
                "countryCode": "US"
            },
            {
                "name": "Vermilion County",
                "countryCode": "US"
            },
            {
                "name": "Vernon Hills",
                "countryCode": "US"
            },
            {
                "name": "Vienna",
                "countryCode": "US"
            },
            {
                "name": "Villa Grove",
                "countryCode": "US"
            },
            {
                "name": "Villa Park",
                "countryCode": "US"
            },
            {
                "name": "Village of Campton Hills",
                "countryCode": "US"
            },
            {
                "name": "Virden",
                "countryCode": "US"
            },
            {
                "name": "Virginia",
                "countryCode": "US"
            },
            {
                "name": "Volo",
                "countryCode": "US"
            },
            {
                "name": "Wabash County",
                "countryCode": "US"
            },
            {
                "name": "Wadsworth",
                "countryCode": "US"
            },
            {
                "name": "Walnut",
                "countryCode": "US"
            },
            {
                "name": "Wamac",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrensburg",
                "countryCode": "US"
            },
            {
                "name": "Warrenville",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Wasco",
                "countryCode": "US"
            },
            {
                "name": "Washburn",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washington Park",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Waterman",
                "countryCode": "US"
            },
            {
                "name": "Watseka",
                "countryCode": "US"
            },
            {
                "name": "Wauconda",
                "countryCode": "US"
            },
            {
                "name": "Waukegan",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wayne City",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "West Chicago",
                "countryCode": "US"
            },
            {
                "name": "West Dundee",
                "countryCode": "US"
            },
            {
                "name": "West Elsdon",
                "countryCode": "US"
            },
            {
                "name": "West Englewood",
                "countryCode": "US"
            },
            {
                "name": "West Frankfort",
                "countryCode": "US"
            },
            {
                "name": "West Garfield Park",
                "countryCode": "US"
            },
            {
                "name": "West Lawn",
                "countryCode": "US"
            },
            {
                "name": "West Peoria",
                "countryCode": "US"
            },
            {
                "name": "West Ridge",
                "countryCode": "US"
            },
            {
                "name": "West Town",
                "countryCode": "US"
            },
            {
                "name": "Westchester",
                "countryCode": "US"
            },
            {
                "name": "Western Springs",
                "countryCode": "US"
            },
            {
                "name": "Westmont",
                "countryCode": "US"
            },
            {
                "name": "Westville",
                "countryCode": "US"
            },
            {
                "name": "Wheaton",
                "countryCode": "US"
            },
            {
                "name": "Wheeling",
                "countryCode": "US"
            },
            {
                "name": "White County",
                "countryCode": "US"
            },
            {
                "name": "White Hall",
                "countryCode": "US"
            },
            {
                "name": "Whiteside County",
                "countryCode": "US"
            },
            {
                "name": "Will County",
                "countryCode": "US"
            },
            {
                "name": "Williamson County",
                "countryCode": "US"
            },
            {
                "name": "Williamsville",
                "countryCode": "US"
            },
            {
                "name": "Willow Springs",
                "countryCode": "US"
            },
            {
                "name": "Willowbrook",
                "countryCode": "US"
            },
            {
                "name": "Wilmette",
                "countryCode": "US"
            },
            {
                "name": "Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Winnebago",
                "countryCode": "US"
            },
            {
                "name": "Winnebago County",
                "countryCode": "US"
            },
            {
                "name": "Winnetka",
                "countryCode": "US"
            },
            {
                "name": "Winthrop Harbor",
                "countryCode": "US"
            },
            {
                "name": "Wonder Lake",
                "countryCode": "US"
            },
            {
                "name": "Wood Dale",
                "countryCode": "US"
            },
            {
                "name": "Wood River",
                "countryCode": "US"
            },
            {
                "name": "Woodford County",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn",
                "countryCode": "US"
            },
            {
                "name": "Woodridge",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Worden",
                "countryCode": "US"
            },
            {
                "name": "Worth",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Yorkville",
                "countryCode": "US"
            },
            {
                "name": "Zeigler",
                "countryCode": "US"
            },
            {
                "name": "Zion",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Allen County",
                "countryCode": "US"
            },
            {
                "name": "Anderson",
                "countryCode": "US"
            },
            {
                "name": "Andrews",
                "countryCode": "US"
            },
            {
                "name": "Angola",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Argos",
                "countryCode": "US"
            },
            {
                "name": "Attica",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Austin",
                "countryCode": "US"
            },
            {
                "name": "Avilla",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Bargersville",
                "countryCode": "US"
            },
            {
                "name": "Bartholomew County",
                "countryCode": "US"
            },
            {
                "name": "Bass Lake",
                "countryCode": "US"
            },
            {
                "name": "Batesville",
                "countryCode": "US"
            },
            {
                "name": "Battle Ground",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Beech Grove",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Berne",
                "countryCode": "US"
            },
            {
                "name": "Bicknell",
                "countryCode": "US"
            },
            {
                "name": "Blackford County",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Bloomington",
                "countryCode": "US"
            },
            {
                "name": "Bluffton",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Boonville",
                "countryCode": "US"
            },
            {
                "name": "Bourbon",
                "countryCode": "US"
            },
            {
                "name": "Brazil",
                "countryCode": "US"
            },
            {
                "name": "Bremen",
                "countryCode": "US"
            },
            {
                "name": "Bright",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Broad Ripple",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Brookston",
                "countryCode": "US"
            },
            {
                "name": "Brookville",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Brownsburg",
                "countryCode": "US"
            },
            {
                "name": "Brownstown",
                "countryCode": "US"
            },
            {
                "name": "Burns Harbor",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Cambridge City",
                "countryCode": "US"
            },
            {
                "name": "Cannelton",
                "countryCode": "US"
            },
            {
                "name": "Carmel",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Cayuga",
                "countryCode": "US"
            },
            {
                "name": "Cedar Lake",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Chandler",
                "countryCode": "US"
            },
            {
                "name": "Charlestown",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield",
                "countryCode": "US"
            },
            {
                "name": "Chesterton",
                "countryCode": "US"
            },
            {
                "name": "Churubusco",
                "countryCode": "US"
            },
            {
                "name": "Cicero",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clermont",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Cloverdale",
                "countryCode": "US"
            },
            {
                "name": "Columbia City",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Connersville",
                "countryCode": "US"
            },
            {
                "name": "Converse",
                "countryCode": "US"
            },
            {
                "name": "Cordry Sweetwater Lakes",
                "countryCode": "US"
            },
            {
                "name": "Corydon",
                "countryCode": "US"
            },
            {
                "name": "Country Squire Lakes",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crawfordsville",
                "countryCode": "US"
            },
            {
                "name": "Crothersville",
                "countryCode": "US"
            },
            {
                "name": "Crown Point",
                "countryCode": "US"
            },
            {
                "name": "Culver",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Dale",
                "countryCode": "US"
            },
            {
                "name": "Daleville",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Darmstadt",
                "countryCode": "US"
            },
            {
                "name": "Daviess County",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "DeMotte",
                "countryCode": "US"
            },
            {
                "name": "Dearborn County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Decatur County",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Delphi",
                "countryCode": "US"
            },
            {
                "name": "Dillsboro",
                "countryCode": "US"
            },
            {
                "name": "Dubois County",
                "countryCode": "US"
            },
            {
                "name": "Dunkirk",
                "countryCode": "US"
            },
            {
                "name": "Dunlap",
                "countryCode": "US"
            },
            {
                "name": "Dyer",
                "countryCode": "US"
            },
            {
                "name": "East Chicago",
                "countryCode": "US"
            },
            {
                "name": "Eaton",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edinburgh",
                "countryCode": "US"
            },
            {
                "name": "Elkhart",
                "countryCode": "US"
            },
            {
                "name": "Elkhart County",
                "countryCode": "US"
            },
            {
                "name": "Ellettsville",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "English",
                "countryCode": "US"
            },
            {
                "name": "Evansville",
                "countryCode": "US"
            },
            {
                "name": "Fairfield Heights",
                "countryCode": "US"
            },
            {
                "name": "Fairmount",
                "countryCode": "US"
            },
            {
                "name": "Fairview Park",
                "countryCode": "US"
            },
            {
                "name": "Farmersburg",
                "countryCode": "US"
            },
            {
                "name": "Farmland",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Ferdinand",
                "countryCode": "US"
            },
            {
                "name": "Fish Lake",
                "countryCode": "US"
            },
            {
                "name": "Fishers",
                "countryCode": "US"
            },
            {
                "name": "Flora",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Fort Branch",
                "countryCode": "US"
            },
            {
                "name": "Fort Wayne",
                "countryCode": "US"
            },
            {
                "name": "Fortville",
                "countryCode": "US"
            },
            {
                "name": "Fountain County",
                "countryCode": "US"
            },
            {
                "name": "Fowler",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frankton",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "French Lick",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Galena",
                "countryCode": "US"
            },
            {
                "name": "Galveston",
                "countryCode": "US"
            },
            {
                "name": "Garrett",
                "countryCode": "US"
            },
            {
                "name": "Gary",
                "countryCode": "US"
            },
            {
                "name": "Gas City",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Gibson County",
                "countryCode": "US"
            },
            {
                "name": "Goodland",
                "countryCode": "US"
            },
            {
                "name": "Goshen",
                "countryCode": "US"
            },
            {
                "name": "Grabill",
                "countryCode": "US"
            },
            {
                "name": "Granger",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Greencastle",
                "countryCode": "US"
            },
            {
                "name": "Greendale",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greentown",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Griffith",
                "countryCode": "US"
            },
            {
                "name": "Grissom Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Gulivoire Park",
                "countryCode": "US"
            },
            {
                "name": "Hagerstown",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hammond",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harlan",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Hartford City",
                "countryCode": "US"
            },
            {
                "name": "Haubstadt",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Hendricks County",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Henryville",
                "countryCode": "US"
            },
            {
                "name": "Heritage Lake",
                "countryCode": "US"
            },
            {
                "name": "Hidden Valley",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Hobart",
                "countryCode": "US"
            },
            {
                "name": "Hope",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Hudson Lake",
                "countryCode": "US"
            },
            {
                "name": "Huntertown",
                "countryCode": "US"
            },
            {
                "name": "Huntingburg",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Huntington County",
                "countryCode": "US"
            },
            {
                "name": "Indian Heights",
                "countryCode": "US"
            },
            {
                "name": "Indianapolis",
                "countryCode": "US"
            },
            {
                "name": "Ingalls",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jasonville",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jay County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jeffersonville",
                "countryCode": "US"
            },
            {
                "name": "Jennings County",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Jonesboro",
                "countryCode": "US"
            },
            {
                "name": "Kendallville",
                "countryCode": "US"
            },
            {
                "name": "Kentland",
                "countryCode": "US"
            },
            {
                "name": "Kingsford Heights",
                "countryCode": "US"
            },
            {
                "name": "Knightstown",
                "countryCode": "US"
            },
            {
                "name": "Knox",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "Kokomo",
                "countryCode": "US"
            },
            {
                "name": "Koontz Lake",
                "countryCode": "US"
            },
            {
                "name": "Kosciusko County",
                "countryCode": "US"
            },
            {
                "name": "Kouts",
                "countryCode": "US"
            },
            {
                "name": "La Porte",
                "countryCode": "US"
            },
            {
                "name": "LaGrange County",
                "countryCode": "US"
            },
            {
                "name": "LaPorte",
                "countryCode": "US"
            },
            {
                "name": "LaPorte County",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lagrange",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Dalecarlia",
                "countryCode": "US"
            },
            {
                "name": "Lake Station",
                "countryCode": "US"
            },
            {
                "name": "Lakes of the Four Seasons",
                "countryCode": "US"
            },
            {
                "name": "Lapel",
                "countryCode": "US"
            },
            {
                "name": "Lawrence",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceburg",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Leo-Cedarville",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Ligonier",
                "countryCode": "US"
            },
            {
                "name": "Linton",
                "countryCode": "US"
            },
            {
                "name": "Logansport",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Loogootee",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Lynn",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Markle",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Martinsville",
                "countryCode": "US"
            },
            {
                "name": "McCordsville",
                "countryCode": "US"
            },
            {
                "name": "Melody Hill",
                "countryCode": "US"
            },
            {
                "name": "Meridian Hills",
                "countryCode": "US"
            },
            {
                "name": "Merrillville",
                "countryCode": "US"
            },
            {
                "name": "Miami County",
                "countryCode": "US"
            },
            {
                "name": "Michigan City",
                "countryCode": "US"
            },
            {
                "name": "Middlebury",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Mishawaka",
                "countryCode": "US"
            },
            {
                "name": "Mitchell",
                "countryCode": "US"
            },
            {
                "name": "Monon",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monroeville",
                "countryCode": "US"
            },
            {
                "name": "Monrovia",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Montpelier",
                "countryCode": "US"
            },
            {
                "name": "Mooresville",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morocco",
                "countryCode": "US"
            },
            {
                "name": "Morristown",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mulberry",
                "countryCode": "US"
            },
            {
                "name": "Muncie",
                "countryCode": "US"
            },
            {
                "name": "Munster",
                "countryCode": "US"
            },
            {
                "name": "Nappanee",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "New Albany",
                "countryCode": "US"
            },
            {
                "name": "New Carlisle",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "New Chicago",
                "countryCode": "US"
            },
            {
                "name": "New Haven",
                "countryCode": "US"
            },
            {
                "name": "New Palestine",
                "countryCode": "US"
            },
            {
                "name": "New Paris",
                "countryCode": "US"
            },
            {
                "name": "New Pekin",
                "countryCode": "US"
            },
            {
                "name": "New Whiteland",
                "countryCode": "US"
            },
            {
                "name": "Newburgh",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "Noble County",
                "countryCode": "US"
            },
            {
                "name": "Noblesville",
                "countryCode": "US"
            },
            {
                "name": "North Judson",
                "countryCode": "US"
            },
            {
                "name": "North Liberty",
                "countryCode": "US"
            },
            {
                "name": "North Madison",
                "countryCode": "US"
            },
            {
                "name": "North Manchester",
                "countryCode": "US"
            },
            {
                "name": "North Terre Haute",
                "countryCode": "US"
            },
            {
                "name": "North Vernon",
                "countryCode": "US"
            },
            {
                "name": "North Webster",
                "countryCode": "US"
            },
            {
                "name": "Notre Dame",
                "countryCode": "US"
            },
            {
                "name": "Oak Park",
                "countryCode": "US"
            },
            {
                "name": "Oakland City",
                "countryCode": "US"
            },
            {
                "name": "Odon",
                "countryCode": "US"
            },
            {
                "name": "Ogden Dunes",
                "countryCode": "US"
            },
            {
                "name": "Ohio County",
                "countryCode": "US"
            },
            {
                "name": "Oolitic",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orleans",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Osgood",
                "countryCode": "US"
            },
            {
                "name": "Ossian",
                "countryCode": "US"
            },
            {
                "name": "Otterbein",
                "countryCode": "US"
            },
            {
                "name": "Owen County",
                "countryCode": "US"
            },
            {
                "name": "Owensville",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Paoli",
                "countryCode": "US"
            },
            {
                "name": "Parke County",
                "countryCode": "US"
            },
            {
                "name": "Parker City",
                "countryCode": "US"
            },
            {
                "name": "Pendleton",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Peru",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Pierceton",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pittsboro",
                "countryCode": "US"
            },
            {
                "name": "Plainfield",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Portage",
                "countryCode": "US"
            },
            {
                "name": "Porter",
                "countryCode": "US"
            },
            {
                "name": "Porter County",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Posey County",
                "countryCode": "US"
            },
            {
                "name": "Poseyville",
                "countryCode": "US"
            },
            {
                "name": "Princes Lakes",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Redkey",
                "countryCode": "US"
            },
            {
                "name": "Remington",
                "countryCode": "US"
            },
            {
                "name": "Rensselaer",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Ripley County",
                "countryCode": "US"
            },
            {
                "name": "Rising Sun",
                "countryCode": "US"
            },
            {
                "name": "Roanoke",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rockport",
                "countryCode": "US"
            },
            {
                "name": "Rockville",
                "countryCode": "US"
            },
            {
                "name": "Rome City",
                "countryCode": "US"
            },
            {
                "name": "Roselawn",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Rush County",
                "countryCode": "US"
            },
            {
                "name": "Rushville",
                "countryCode": "US"
            },
            {
                "name": "Russiaville",
                "countryCode": "US"
            },
            {
                "name": "Saint John",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph County",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Santa Claus",
                "countryCode": "US"
            },
            {
                "name": "Schererville",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Scottsburg",
                "countryCode": "US"
            },
            {
                "name": "Seelyville",
                "countryCode": "US"
            },
            {
                "name": "Sellersburg",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Shadeland",
                "countryCode": "US"
            },
            {
                "name": "Shelburn",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shelbyville",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Shoals",
                "countryCode": "US"
            },
            {
                "name": "Shorewood Forest",
                "countryCode": "US"
            },
            {
                "name": "Simonton Lake",
                "countryCode": "US"
            },
            {
                "name": "Smithville-Sanders",
                "countryCode": "US"
            },
            {
                "name": "South Bend",
                "countryCode": "US"
            },
            {
                "name": "South Haven",
                "countryCode": "US"
            },
            {
                "name": "South Whitley",
                "countryCode": "US"
            },
            {
                "name": "Southport",
                "countryCode": "US"
            },
            {
                "name": "Speedway",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Spencer County",
                "countryCode": "US"
            },
            {
                "name": "Starke County",
                "countryCode": "US"
            },
            {
                "name": "Steuben County",
                "countryCode": "US"
            },
            {
                "name": "Sullivan",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Sunman",
                "countryCode": "US"
            },
            {
                "name": "Sweetser",
                "countryCode": "US"
            },
            {
                "name": "Switzerland County",
                "countryCode": "US"
            },
            {
                "name": "Syracuse",
                "countryCode": "US"
            },
            {
                "name": "Tell City",
                "countryCode": "US"
            },
            {
                "name": "Terre Haute",
                "countryCode": "US"
            },
            {
                "name": "Thorntown",
                "countryCode": "US"
            },
            {
                "name": "Tippecanoe County",
                "countryCode": "US"
            },
            {
                "name": "Tipton",
                "countryCode": "US"
            },
            {
                "name": "Tipton County",
                "countryCode": "US"
            },
            {
                "name": "Topeka",
                "countryCode": "US"
            },
            {
                "name": "Trafalgar",
                "countryCode": "US"
            },
            {
                "name": "Trail Creek",
                "countryCode": "US"
            },
            {
                "name": "Tri-Lakes",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Upland",
                "countryCode": "US"
            },
            {
                "name": "Valparaiso",
                "countryCode": "US"
            },
            {
                "name": "Vanderburgh County",
                "countryCode": "US"
            },
            {
                "name": "Veedersburg",
                "countryCode": "US"
            },
            {
                "name": "Vermillion County",
                "countryCode": "US"
            },
            {
                "name": "Versailles",
                "countryCode": "US"
            },
            {
                "name": "Vevay",
                "countryCode": "US"
            },
            {
                "name": "Vigo County",
                "countryCode": "US"
            },
            {
                "name": "Vincennes",
                "countryCode": "US"
            },
            {
                "name": "Wabash",
                "countryCode": "US"
            },
            {
                "name": "Wabash County",
                "countryCode": "US"
            },
            {
                "name": "Wakarusa",
                "countryCode": "US"
            },
            {
                "name": "Walkerton",
                "countryCode": "US"
            },
            {
                "name": "Walton",
                "countryCode": "US"
            },
            {
                "name": "Wanatah",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warren Park",
                "countryCode": "US"
            },
            {
                "name": "Warrick County",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Wells County",
                "countryCode": "US"
            },
            {
                "name": "West Lafayette",
                "countryCode": "US"
            },
            {
                "name": "West Terre Haute",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Westport",
                "countryCode": "US"
            },
            {
                "name": "Westville",
                "countryCode": "US"
            },
            {
                "name": "White County",
                "countryCode": "US"
            },
            {
                "name": "Whiteland",
                "countryCode": "US"
            },
            {
                "name": "Whitestown",
                "countryCode": "US"
            },
            {
                "name": "Whiting",
                "countryCode": "US"
            },
            {
                "name": "Whitley County",
                "countryCode": "US"
            },
            {
                "name": "Williamsport",
                "countryCode": "US"
            },
            {
                "name": "Winamac",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Winona Lake",
                "countryCode": "US"
            },
            {
                "name": "Wolcottville",
                "countryCode": "US"
            },
            {
                "name": "Woodburn",
                "countryCode": "US"
            },
            {
                "name": "Worthington",
                "countryCode": "US"
            },
            {
                "name": "Yorktown",
                "countryCode": "US"
            },
            {
                "name": "Zionsville",
                "countryCode": "US"
            },
            {
                "name": "Abilene",
                "countryCode": "US"
            },
            {
                "name": "Allen County",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Altamont",
                "countryCode": "US"
            },
            {
                "name": "Anderson County",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Anthony",
                "countryCode": "US"
            },
            {
                "name": "Arkansas City",
                "countryCode": "US"
            },
            {
                "name": "Arma",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atchison",
                "countryCode": "US"
            },
            {
                "name": "Atchison County",
                "countryCode": "US"
            },
            {
                "name": "Atwood",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Baldwin City",
                "countryCode": "US"
            },
            {
                "name": "Barber County",
                "countryCode": "US"
            },
            {
                "name": "Barton County",
                "countryCode": "US"
            },
            {
                "name": "Basehor",
                "countryCode": "US"
            },
            {
                "name": "Baxter Springs",
                "countryCode": "US"
            },
            {
                "name": "Bellaire",
                "countryCode": "US"
            },
            {
                "name": "Belle Plaine",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Beloit",
                "countryCode": "US"
            },
            {
                "name": "Bonner Springs",
                "countryCode": "US"
            },
            {
                "name": "Bourbon County",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Buhler",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Caldwell",
                "countryCode": "US"
            },
            {
                "name": "Caney",
                "countryCode": "US"
            },
            {
                "name": "Carbondale",
                "countryCode": "US"
            },
            {
                "name": "Chanute",
                "countryCode": "US"
            },
            {
                "name": "Chapman",
                "countryCode": "US"
            },
            {
                "name": "Chase County",
                "countryCode": "US"
            },
            {
                "name": "Chautauqua County",
                "countryCode": "US"
            },
            {
                "name": "Cheney",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Cherryvale",
                "countryCode": "US"
            },
            {
                "name": "Chetopa",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne County",
                "countryCode": "US"
            },
            {
                "name": "Cimarron",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clay Center",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clearwater",
                "countryCode": "US"
            },
            {
                "name": "Cloud County",
                "countryCode": "US"
            },
            {
                "name": "Coffey County",
                "countryCode": "US"
            },
            {
                "name": "Coffeyville",
                "countryCode": "US"
            },
            {
                "name": "Colby",
                "countryCode": "US"
            },
            {
                "name": "Coldwater",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Colwich",
                "countryCode": "US"
            },
            {
                "name": "Comanche County",
                "countryCode": "US"
            },
            {
                "name": "Concordia",
                "countryCode": "US"
            },
            {
                "name": "Conway Springs",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood Falls",
                "countryCode": "US"
            },
            {
                "name": "Council Grove",
                "countryCode": "US"
            },
            {
                "name": "Cowley County",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "De Soto",
                "countryCode": "US"
            },
            {
                "name": "Decatur County",
                "countryCode": "US"
            },
            {
                "name": "Derby",
                "countryCode": "US"
            },
            {
                "name": "Dickinson County",
                "countryCode": "US"
            },
            {
                "name": "Dighton",
                "countryCode": "US"
            },
            {
                "name": "Dodge City",
                "countryCode": "US"
            },
            {
                "name": "Doniphan County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Douglass",
                "countryCode": "US"
            },
            {
                "name": "Edgerton",
                "countryCode": "US"
            },
            {
                "name": "Edwards County",
                "countryCode": "US"
            },
            {
                "name": "Edwardsville",
                "countryCode": "US"
            },
            {
                "name": "El Dorado",
                "countryCode": "US"
            },
            {
                "name": "Elk County",
                "countryCode": "US"
            },
            {
                "name": "Elkhart",
                "countryCode": "US"
            },
            {
                "name": "Ellinwood",
                "countryCode": "US"
            },
            {
                "name": "Ellis",
                "countryCode": "US"
            },
            {
                "name": "Ellis County",
                "countryCode": "US"
            },
            {
                "name": "Ellsworth",
                "countryCode": "US"
            },
            {
                "name": "Ellsworth County",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Emporia",
                "countryCode": "US"
            },
            {
                "name": "Erie",
                "countryCode": "US"
            },
            {
                "name": "Eudora",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Fairway",
                "countryCode": "US"
            },
            {
                "name": "Finney County",
                "countryCode": "US"
            },
            {
                "name": "Ford County",
                "countryCode": "US"
            },
            {
                "name": "Fort Riley North",
                "countryCode": "US"
            },
            {
                "name": "Fort Scott",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fredonia",
                "countryCode": "US"
            },
            {
                "name": "Frontenac",
                "countryCode": "US"
            },
            {
                "name": "Galena",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Gardner",
                "countryCode": "US"
            },
            {
                "name": "Garnett",
                "countryCode": "US"
            },
            {
                "name": "Geary County",
                "countryCode": "US"
            },
            {
                "name": "Girard",
                "countryCode": "US"
            },
            {
                "name": "Goddard",
                "countryCode": "US"
            },
            {
                "name": "Goodland",
                "countryCode": "US"
            },
            {
                "name": "Gove",
                "countryCode": "US"
            },
            {
                "name": "Gove County",
                "countryCode": "US"
            },
            {
                "name": "Graham County",
                "countryCode": "US"
            },
            {
                "name": "Grandview Plaza",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Gray County",
                "countryCode": "US"
            },
            {
                "name": "Great Bend",
                "countryCode": "US"
            },
            {
                "name": "Greeley County",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greenwood County",
                "countryCode": "US"
            },
            {
                "name": "Halstead",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Harper",
                "countryCode": "US"
            },
            {
                "name": "Harper County",
                "countryCode": "US"
            },
            {
                "name": "Harvey County",
                "countryCode": "US"
            },
            {
                "name": "Haskell County",
                "countryCode": "US"
            },
            {
                "name": "Haven",
                "countryCode": "US"
            },
            {
                "name": "Hays",
                "countryCode": "US"
            },
            {
                "name": "Haysville",
                "countryCode": "US"
            },
            {
                "name": "Herington",
                "countryCode": "US"
            },
            {
                "name": "Hesston",
                "countryCode": "US"
            },
            {
                "name": "Hiawatha",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Hill City",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hodgeman County",
                "countryCode": "US"
            },
            {
                "name": "Hoisington",
                "countryCode": "US"
            },
            {
                "name": "Holcomb",
                "countryCode": "US"
            },
            {
                "name": "Holton",
                "countryCode": "US"
            },
            {
                "name": "Horton",
                "countryCode": "US"
            },
            {
                "name": "Howard",
                "countryCode": "US"
            },
            {
                "name": "Hoxie",
                "countryCode": "US"
            },
            {
                "name": "Hugoton",
                "countryCode": "US"
            },
            {
                "name": "Humboldt",
                "countryCode": "US"
            },
            {
                "name": "Hutchinson",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Inman",
                "countryCode": "US"
            },
            {
                "name": "Iola",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jetmore",
                "countryCode": "US"
            },
            {
                "name": "Jewell County",
                "countryCode": "US"
            },
            {
                "name": "Johnson",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Junction City",
                "countryCode": "US"
            },
            {
                "name": "Kansas City",
                "countryCode": "US"
            },
            {
                "name": "Kearny County",
                "countryCode": "US"
            },
            {
                "name": "Kechi",
                "countryCode": "US"
            },
            {
                "name": "Kingman",
                "countryCode": "US"
            },
            {
                "name": "Kingman County",
                "countryCode": "US"
            },
            {
                "name": "Kinsley",
                "countryCode": "US"
            },
            {
                "name": "Kiowa",
                "countryCode": "US"
            },
            {
                "name": "Kiowa County",
                "countryCode": "US"
            },
            {
                "name": "La Crosse",
                "countryCode": "US"
            },
            {
                "name": "La Cygne",
                "countryCode": "US"
            },
            {
                "name": "Labette County",
                "countryCode": "US"
            },
            {
                "name": "Lakin",
                "countryCode": "US"
            },
            {
                "name": "Lane County",
                "countryCode": "US"
            },
            {
                "name": "Lansing",
                "countryCode": "US"
            },
            {
                "name": "Larned",
                "countryCode": "US"
            },
            {
                "name": "Lawrence",
                "countryCode": "US"
            },
            {
                "name": "Leavenworth",
                "countryCode": "US"
            },
            {
                "name": "Leavenworth County",
                "countryCode": "US"
            },
            {
                "name": "Leawood",
                "countryCode": "US"
            },
            {
                "name": "Lenexa",
                "countryCode": "US"
            },
            {
                "name": "Leoti",
                "countryCode": "US"
            },
            {
                "name": "Liberal",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lindsborg",
                "countryCode": "US"
            },
            {
                "name": "Linn County",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Louisburg",
                "countryCode": "US"
            },
            {
                "name": "Lyndon",
                "countryCode": "US"
            },
            {
                "name": "Lyon County",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Maize",
                "countryCode": "US"
            },
            {
                "name": "Manhattan",
                "countryCode": "US"
            },
            {
                "name": "Mankato",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "McConnell AFB",
                "countryCode": "US"
            },
            {
                "name": "McPherson",
                "countryCode": "US"
            },
            {
                "name": "McPherson County",
                "countryCode": "US"
            },
            {
                "name": "Meade",
                "countryCode": "US"
            },
            {
                "name": "Meade County",
                "countryCode": "US"
            },
            {
                "name": "Medicine Lodge",
                "countryCode": "US"
            },
            {
                "name": "Merriam",
                "countryCode": "US"
            },
            {
                "name": "Miami County",
                "countryCode": "US"
            },
            {
                "name": "Minneapolis",
                "countryCode": "US"
            },
            {
                "name": "Mission",
                "countryCode": "US"
            },
            {
                "name": "Mission Hills",
                "countryCode": "US"
            },
            {
                "name": "Mitchell County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Morris County",
                "countryCode": "US"
            },
            {
                "name": "Morton County",
                "countryCode": "US"
            },
            {
                "name": "Mound City",
                "countryCode": "US"
            },
            {
                "name": "Moundridge",
                "countryCode": "US"
            },
            {
                "name": "Mulvane",
                "countryCode": "US"
            },
            {
                "name": "Nemaha County",
                "countryCode": "US"
            },
            {
                "name": "Neodesha",
                "countryCode": "US"
            },
            {
                "name": "Neosho County",
                "countryCode": "US"
            },
            {
                "name": "Ness City",
                "countryCode": "US"
            },
            {
                "name": "Ness County",
                "countryCode": "US"
            },
            {
                "name": "New Century, KS",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Nickerson",
                "countryCode": "US"
            },
            {
                "name": "North Newton",
                "countryCode": "US"
            },
            {
                "name": "Norton",
                "countryCode": "US"
            },
            {
                "name": "Norton County",
                "countryCode": "US"
            },
            {
                "name": "Oakley",
                "countryCode": "US"
            },
            {
                "name": "Oberlin",
                "countryCode": "US"
            },
            {
                "name": "Ogden",
                "countryCode": "US"
            },
            {
                "name": "Olathe",
                "countryCode": "US"
            },
            {
                "name": "Osage City",
                "countryCode": "US"
            },
            {
                "name": "Osage County",
                "countryCode": "US"
            },
            {
                "name": "Osawatomie",
                "countryCode": "US"
            },
            {
                "name": "Osborne",
                "countryCode": "US"
            },
            {
                "name": "Osborne County",
                "countryCode": "US"
            },
            {
                "name": "Oskaloosa",
                "countryCode": "US"
            },
            {
                "name": "Oswego",
                "countryCode": "US"
            },
            {
                "name": "Ottawa",
                "countryCode": "US"
            },
            {
                "name": "Ottawa County",
                "countryCode": "US"
            },
            {
                "name": "Overbrook",
                "countryCode": "US"
            },
            {
                "name": "Overland Park",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Paola",
                "countryCode": "US"
            },
            {
                "name": "Park City",
                "countryCode": "US"
            },
            {
                "name": "Parsons",
                "countryCode": "US"
            },
            {
                "name": "Pawnee County",
                "countryCode": "US"
            },
            {
                "name": "Peabody",
                "countryCode": "US"
            },
            {
                "name": "Phillips County",
                "countryCode": "US"
            },
            {
                "name": "Phillipsburg",
                "countryCode": "US"
            },
            {
                "name": "Pittsburg",
                "countryCode": "US"
            },
            {
                "name": "Plains",
                "countryCode": "US"
            },
            {
                "name": "Plainville",
                "countryCode": "US"
            },
            {
                "name": "Pleasanton",
                "countryCode": "US"
            },
            {
                "name": "Pottawatomie County",
                "countryCode": "US"
            },
            {
                "name": "Prairie Village",
                "countryCode": "US"
            },
            {
                "name": "Pratt",
                "countryCode": "US"
            },
            {
                "name": "Pratt County",
                "countryCode": "US"
            },
            {
                "name": "Rawlins County",
                "countryCode": "US"
            },
            {
                "name": "Reno County",
                "countryCode": "US"
            },
            {
                "name": "Republic County",
                "countryCode": "US"
            },
            {
                "name": "Rice County",
                "countryCode": "US"
            },
            {
                "name": "Riley County",
                "countryCode": "US"
            },
            {
                "name": "Roeland Park",
                "countryCode": "US"
            },
            {
                "name": "Rooks County",
                "countryCode": "US"
            },
            {
                "name": "Rose Hill",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Rush County",
                "countryCode": "US"
            },
            {
                "name": "Russell",
                "countryCode": "US"
            },
            {
                "name": "Russell County",
                "countryCode": "US"
            },
            {
                "name": "Sabetha",
                "countryCode": "US"
            },
            {
                "name": "Saint Francis",
                "countryCode": "US"
            },
            {
                "name": "Saint John",
                "countryCode": "US"
            },
            {
                "name": "Saint Marys",
                "countryCode": "US"
            },
            {
                "name": "Salina",
                "countryCode": "US"
            },
            {
                "name": "Saline County",
                "countryCode": "US"
            },
            {
                "name": "Satanta",
                "countryCode": "US"
            },
            {
                "name": "Scott City",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Sedan",
                "countryCode": "US"
            },
            {
                "name": "Sedgwick",
                "countryCode": "US"
            },
            {
                "name": "Sedgwick County",
                "countryCode": "US"
            },
            {
                "name": "Seneca",
                "countryCode": "US"
            },
            {
                "name": "Seward County",
                "countryCode": "US"
            },
            {
                "name": "Sharon Springs",
                "countryCode": "US"
            },
            {
                "name": "Shawnee",
                "countryCode": "US"
            },
            {
                "name": "Shawnee County",
                "countryCode": "US"
            },
            {
                "name": "Sheridan County",
                "countryCode": "US"
            },
            {
                "name": "Sherman County",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Smith Center",
                "countryCode": "US"
            },
            {
                "name": "Smith County",
                "countryCode": "US"
            },
            {
                "name": "Solomon",
                "countryCode": "US"
            },
            {
                "name": "South Hutchinson",
                "countryCode": "US"
            },
            {
                "name": "Spring Hill",
                "countryCode": "US"
            },
            {
                "name": "Stafford County",
                "countryCode": "US"
            },
            {
                "name": "Stanton County",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Stevens County",
                "countryCode": "US"
            },
            {
                "name": "Stockton",
                "countryCode": "US"
            },
            {
                "name": "Sublette",
                "countryCode": "US"
            },
            {
                "name": "Sumner County",
                "countryCode": "US"
            },
            {
                "name": "Syracuse",
                "countryCode": "US"
            },
            {
                "name": "Thomas County",
                "countryCode": "US"
            },
            {
                "name": "Tonganoxie",
                "countryCode": "US"
            },
            {
                "name": "Topeka",
                "countryCode": "US"
            },
            {
                "name": "Towanda",
                "countryCode": "US"
            },
            {
                "name": "Trego County",
                "countryCode": "US"
            },
            {
                "name": "Tribune",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Ulysses",
                "countryCode": "US"
            },
            {
                "name": "Valley Center",
                "countryCode": "US"
            },
            {
                "name": "Valley Falls",
                "countryCode": "US"
            },
            {
                "name": "Victoria",
                "countryCode": "US"
            },
            {
                "name": "WaKeeney",
                "countryCode": "US"
            },
            {
                "name": "Wabaunsee County",
                "countryCode": "US"
            },
            {
                "name": "Wallace County",
                "countryCode": "US"
            },
            {
                "name": "Wamego",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Wathena",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "Wellsville",
                "countryCode": "US"
            },
            {
                "name": "Westmoreland",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Wichita",
                "countryCode": "US"
            },
            {
                "name": "Wichita County",
                "countryCode": "US"
            },
            {
                "name": "Wilson County",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Woodson County",
                "countryCode": "US"
            },
            {
                "name": "Wyandotte County",
                "countryCode": "US"
            },
            {
                "name": "Yates Center",
                "countryCode": "US"
            },
            {
                "name": "Adair County",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Allen County",
                "countryCode": "US"
            },
            {
                "name": "Anchorage",
                "countryCode": "US"
            },
            {
                "name": "Anderson County",
                "countryCode": "US"
            },
            {
                "name": "Annville",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Audubon Park",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Ballard County",
                "countryCode": "US"
            },
            {
                "name": "Barbourmeade",
                "countryCode": "US"
            },
            {
                "name": "Barbourville",
                "countryCode": "US"
            },
            {
                "name": "Bardstown",
                "countryCode": "US"
            },
            {
                "name": "Bardwell",
                "countryCode": "US"
            },
            {
                "name": "Barren County",
                "countryCode": "US"
            },
            {
                "name": "Bath County",
                "countryCode": "US"
            },
            {
                "name": "Beattyville",
                "countryCode": "US"
            },
            {
                "name": "Beaver Dam",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Beechwood Village",
                "countryCode": "US"
            },
            {
                "name": "Bell County",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Berea",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Booneville",
                "countryCode": "US"
            },
            {
                "name": "Bourbon County",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Boyd County",
                "countryCode": "US"
            },
            {
                "name": "Boyle County",
                "countryCode": "US"
            },
            {
                "name": "Bracken County",
                "countryCode": "US"
            },
            {
                "name": "Brandenburg",
                "countryCode": "US"
            },
            {
                "name": "Breathitt County",
                "countryCode": "US"
            },
            {
                "name": "Breckinridge Center",
                "countryCode": "US"
            },
            {
                "name": "Breckinridge County",
                "countryCode": "US"
            },
            {
                "name": "Brodhead",
                "countryCode": "US"
            },
            {
                "name": "Brooks",
                "countryCode": "US"
            },
            {
                "name": "Brooksville",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Buckner",
                "countryCode": "US"
            },
            {
                "name": "Buechel",
                "countryCode": "US"
            },
            {
                "name": "Bullitt County",
                "countryCode": "US"
            },
            {
                "name": "Burkesville",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Cadiz",
                "countryCode": "US"
            },
            {
                "name": "Caldwell County",
                "countryCode": "US"
            },
            {
                "name": "Calhoun",
                "countryCode": "US"
            },
            {
                "name": "Calloway County",
                "countryCode": "US"
            },
            {
                "name": "Calvert City",
                "countryCode": "US"
            },
            {
                "name": "Camargo",
                "countryCode": "US"
            },
            {
                "name": "Campbell County",
                "countryCode": "US"
            },
            {
                "name": "Campbellsville",
                "countryCode": "US"
            },
            {
                "name": "Campton",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carlisle County",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carter County",
                "countryCode": "US"
            },
            {
                "name": "Casey County",
                "countryCode": "US"
            },
            {
                "name": "Catlettsburg",
                "countryCode": "US"
            },
            {
                "name": "Cave City",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Christian County",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Claryville",
                "countryCode": "US"
            },
            {
                "name": "Clay",
                "countryCode": "US"
            },
            {
                "name": "Clay City",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Cloverport",
                "countryCode": "US"
            },
            {
                "name": "Coal Run Village",
                "countryCode": "US"
            },
            {
                "name": "Cold Spring",
                "countryCode": "US"
            },
            {
                "name": "Coldstream",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Corbin",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Crescent Springs",
                "countryCode": "US"
            },
            {
                "name": "Crestview Hills",
                "countryCode": "US"
            },
            {
                "name": "Crestwood",
                "countryCode": "US"
            },
            {
                "name": "Crittenden",
                "countryCode": "US"
            },
            {
                "name": "Crittenden County",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Cynthiana",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Daviess County",
                "countryCode": "US"
            },
            {
                "name": "Dawson Springs",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Dixon",
                "countryCode": "US"
            },
            {
                "name": "Doe Valley",
                "countryCode": "US"
            },
            {
                "name": "Douglass Hills",
                "countryCode": "US"
            },
            {
                "name": "Dry Ridge",
                "countryCode": "US"
            },
            {
                "name": "Earlington",
                "countryCode": "US"
            },
            {
                "name": "Eddyville",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edmonson County",
                "countryCode": "US"
            },
            {
                "name": "Edmonton",
                "countryCode": "US"
            },
            {
                "name": "Elizabethtown",
                "countryCode": "US"
            },
            {
                "name": "Elk Creek",
                "countryCode": "US"
            },
            {
                "name": "Elkfork",
                "countryCode": "US"
            },
            {
                "name": "Elkton",
                "countryCode": "US"
            },
            {
                "name": "Elliott County",
                "countryCode": "US"
            },
            {
                "name": "Elsmere",
                "countryCode": "US"
            },
            {
                "name": "Eminence",
                "countryCode": "US"
            },
            {
                "name": "Erlanger",
                "countryCode": "US"
            },
            {
                "name": "Estill County",
                "countryCode": "US"
            },
            {
                "name": "Fairdale",
                "countryCode": "US"
            },
            {
                "name": "Falmouth",
                "countryCode": "US"
            },
            {
                "name": "Farley",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fern Creek",
                "countryCode": "US"
            },
            {
                "name": "Flatwoods",
                "countryCode": "US"
            },
            {
                "name": "Fleming County",
                "countryCode": "US"
            },
            {
                "name": "Flemingsburg",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Fort Campbell North",
                "countryCode": "US"
            },
            {
                "name": "Fort Knox",
                "countryCode": "US"
            },
            {
                "name": "Fort Mitchell",
                "countryCode": "US"
            },
            {
                "name": "Fort Thomas",
                "countryCode": "US"
            },
            {
                "name": "Fort Wright",
                "countryCode": "US"
            },
            {
                "name": "Francisville",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frenchburg",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Gallatin County",
                "countryCode": "US"
            },
            {
                "name": "Garrard County",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Glasgow",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Graves County",
                "countryCode": "US"
            },
            {
                "name": "Graymoor-Devondale",
                "countryCode": "US"
            },
            {
                "name": "Grayson",
                "countryCode": "US"
            },
            {
                "name": "Grayson County",
                "countryCode": "US"
            },
            {
                "name": "Green County",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greenup",
                "countryCode": "US"
            },
            {
                "name": "Greenup County",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Guthrie",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Hardinsburg",
                "countryCode": "US"
            },
            {
                "name": "Harlan",
                "countryCode": "US"
            },
            {
                "name": "Harlan County",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Harrodsburg",
                "countryCode": "US"
            },
            {
                "name": "Hart County",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hawesville",
                "countryCode": "US"
            },
            {
                "name": "Hazard",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Hebron Estates",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Henderson County",
                "countryCode": "US"
            },
            {
                "name": "Hendron",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Heritage Creek",
                "countryCode": "US"
            },
            {
                "name": "Hickman",
                "countryCode": "US"
            },
            {
                "name": "Hickman County",
                "countryCode": "US"
            },
            {
                "name": "Highland Heights",
                "countryCode": "US"
            },
            {
                "name": "Highview",
                "countryCode": "US"
            },
            {
                "name": "Hillview",
                "countryCode": "US"
            },
            {
                "name": "Hindman",
                "countryCode": "US"
            },
            {
                "name": "Hodgenville",
                "countryCode": "US"
            },
            {
                "name": "Hopkins County",
                "countryCode": "US"
            },
            {
                "name": "Hopkinsville",
                "countryCode": "US"
            },
            {
                "name": "Horse Cave",
                "countryCode": "US"
            },
            {
                "name": "Hurstbourne",
                "countryCode": "US"
            },
            {
                "name": "Hurstbourne Acres",
                "countryCode": "US"
            },
            {
                "name": "Hyden",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Indian Hills",
                "countryCode": "US"
            },
            {
                "name": "Indian Hills Cherokee Section",
                "countryCode": "US"
            },
            {
                "name": "Inez",
                "countryCode": "US"
            },
            {
                "name": "Ironville",
                "countryCode": "US"
            },
            {
                "name": "Irvine",
                "countryCode": "US"
            },
            {
                "name": "Irvington",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jeffersontown",
                "countryCode": "US"
            },
            {
                "name": "Jeffersonville",
                "countryCode": "US"
            },
            {
                "name": "Jenkins",
                "countryCode": "US"
            },
            {
                "name": "Jessamine County",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Junction City",
                "countryCode": "US"
            },
            {
                "name": "Kenton County",
                "countryCode": "US"
            },
            {
                "name": "Knott County",
                "countryCode": "US"
            },
            {
                "name": "Knottsville",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "La Center",
                "countryCode": "US"
            },
            {
                "name": "La Grange",
                "countryCode": "US"
            },
            {
                "name": "Lakeside Park",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Larue County",
                "countryCode": "US"
            },
            {
                "name": "Laurel County",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceburg",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lebanon Junction",
                "countryCode": "US"
            },
            {
                "name": "Ledbetter",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leitchfield",
                "countryCode": "US"
            },
            {
                "name": "Leslie County",
                "countryCode": "US"
            },
            {
                "name": "Letcher County",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewisport",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lexington-Fayette",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Livermore",
                "countryCode": "US"
            },
            {
                "name": "Livingston County",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "London",
                "countryCode": "US"
            },
            {
                "name": "Louisa",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Ludlow",
                "countryCode": "US"
            },
            {
                "name": "Lyndon",
                "countryCode": "US"
            },
            {
                "name": "Lyon County",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Madisonville",
                "countryCode": "US"
            },
            {
                "name": "Magoffin County",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "Masonville",
                "countryCode": "US"
            },
            {
                "name": "Massac",
                "countryCode": "US"
            },
            {
                "name": "Mayfield",
                "countryCode": "US"
            },
            {
                "name": "Maysville",
                "countryCode": "US"
            },
            {
                "name": "McCracken County",
                "countryCode": "US"
            },
            {
                "name": "McCreary County",
                "countryCode": "US"
            },
            {
                "name": "McKee",
                "countryCode": "US"
            },
            {
                "name": "McLean County",
                "countryCode": "US"
            },
            {
                "name": "Meade County",
                "countryCode": "US"
            },
            {
                "name": "Meads",
                "countryCode": "US"
            },
            {
                "name": "Menifee County",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Metcalfe County",
                "countryCode": "US"
            },
            {
                "name": "Middlesboro",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morehead",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morganfield",
                "countryCode": "US"
            },
            {
                "name": "Morgantown",
                "countryCode": "US"
            },
            {
                "name": "Mount Olivet",
                "countryCode": "US"
            },
            {
                "name": "Mount Sterling",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mount Washington",
                "countryCode": "US"
            },
            {
                "name": "Muhlenberg County",
                "countryCode": "US"
            },
            {
                "name": "Munfordville",
                "countryCode": "US"
            },
            {
                "name": "Murray",
                "countryCode": "US"
            },
            {
                "name": "Nelson County",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "Newburg",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Nicholas County",
                "countryCode": "US"
            },
            {
                "name": "Nicholasville",
                "countryCode": "US"
            },
            {
                "name": "North Corbin",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Nortonville",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oakbrook",
                "countryCode": "US"
            },
            {
                "name": "Ohio County",
                "countryCode": "US"
            },
            {
                "name": "Okolona",
                "countryCode": "US"
            },
            {
                "name": "Oldham County",
                "countryCode": "US"
            },
            {
                "name": "Olive Hill",
                "countryCode": "US"
            },
            {
                "name": "Orchard Grass Hills",
                "countryCode": "US"
            },
            {
                "name": "Owen County",
                "countryCode": "US"
            },
            {
                "name": "Owensboro",
                "countryCode": "US"
            },
            {
                "name": "Owenton",
                "countryCode": "US"
            },
            {
                "name": "Owingsville",
                "countryCode": "US"
            },
            {
                "name": "Owsley County",
                "countryCode": "US"
            },
            {
                "name": "Paducah",
                "countryCode": "US"
            },
            {
                "name": "Paintsville",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Park Hills",
                "countryCode": "US"
            },
            {
                "name": "Pendleton County",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Pewee Valley",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pikeville",
                "countryCode": "US"
            },
            {
                "name": "Pine Knot",
                "countryCode": "US"
            },
            {
                "name": "Pineville",
                "countryCode": "US"
            },
            {
                "name": "Pioneer Village",
                "countryCode": "US"
            },
            {
                "name": "Plano",
                "countryCode": "US"
            },
            {
                "name": "Pleasure Ridge Park",
                "countryCode": "US"
            },
            {
                "name": "Powell County",
                "countryCode": "US"
            },
            {
                "name": "Prestonsburg",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Prospect",
                "countryCode": "US"
            },
            {
                "name": "Providence",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Raceland",
                "countryCode": "US"
            },
            {
                "name": "Radcliff",
                "countryCode": "US"
            },
            {
                "name": "Reidland",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Robertson County",
                "countryCode": "US"
            },
            {
                "name": "Rockcastle County",
                "countryCode": "US"
            },
            {
                "name": "Rowan County",
                "countryCode": "US"
            },
            {
                "name": "Russell",
                "countryCode": "US"
            },
            {
                "name": "Russell County",
                "countryCode": "US"
            },
            {
                "name": "Russell Springs",
                "countryCode": "US"
            },
            {
                "name": "Russellville",
                "countryCode": "US"
            },
            {
                "name": "Ryland Heights",
                "countryCode": "US"
            },
            {
                "name": "Saint Dennis",
                "countryCode": "US"
            },
            {
                "name": "Saint Matthews",
                "countryCode": "US"
            },
            {
                "name": "Saint Regis Park",
                "countryCode": "US"
            },
            {
                "name": "Salyersville",
                "countryCode": "US"
            },
            {
                "name": "Sandy Hook",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Scottsville",
                "countryCode": "US"
            },
            {
                "name": "Sebree",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shelbyville",
                "countryCode": "US"
            },
            {
                "name": "Shepherdsville",
                "countryCode": "US"
            },
            {
                "name": "Shively",
                "countryCode": "US"
            },
            {
                "name": "Silver Grove",
                "countryCode": "US"
            },
            {
                "name": "Simpson County",
                "countryCode": "US"
            },
            {
                "name": "Simpsonville",
                "countryCode": "US"
            },
            {
                "name": "Smithland",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "South Shore",
                "countryCode": "US"
            },
            {
                "name": "Southgate",
                "countryCode": "US"
            },
            {
                "name": "Spencer County",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stanford",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Stearns",
                "countryCode": "US"
            },
            {
                "name": "Sturgis",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Taylor Mill",
                "countryCode": "US"
            },
            {
                "name": "Taylorsville",
                "countryCode": "US"
            },
            {
                "name": "Todd County",
                "countryCode": "US"
            },
            {
                "name": "Tompkinsville",
                "countryCode": "US"
            },
            {
                "name": "Trigg County",
                "countryCode": "US"
            },
            {
                "name": "Trimble County",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Valley Station",
                "countryCode": "US"
            },
            {
                "name": "Van Lear",
                "countryCode": "US"
            },
            {
                "name": "Vanceburg",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Versailles",
                "countryCode": "US"
            },
            {
                "name": "Villa Hills",
                "countryCode": "US"
            },
            {
                "name": "Vine Grove",
                "countryCode": "US"
            },
            {
                "name": "Walton",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Watterson Park",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "West Buechel",
                "countryCode": "US"
            },
            {
                "name": "West Liberty",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Whitesburg",
                "countryCode": "US"
            },
            {
                "name": "Whitley City",
                "countryCode": "US"
            },
            {
                "name": "Whitley County",
                "countryCode": "US"
            },
            {
                "name": "Wickliffe",
                "countryCode": "US"
            },
            {
                "name": "Wilder",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Wilmore",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windy Hills",
                "countryCode": "US"
            },
            {
                "name": "Wolfe County",
                "countryCode": "US"
            },
            {
                "name": "Woodford County",
                "countryCode": "US"
            },
            {
                "name": "Worthington",
                "countryCode": "US"
            },
            {
                "name": "Worthington Hills",
                "countryCode": "US"
            },
            {
                "name": "Wurtland",
                "countryCode": "US"
            },
            {
                "name": "Abbeville",
                "countryCode": "US"
            },
            {
                "name": "Abita Springs",
                "countryCode": "US"
            },
            {
                "name": "Acadia Parish",
                "countryCode": "US"
            },
            {
                "name": "Addis",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Allen Parish",
                "countryCode": "US"
            },
            {
                "name": "Ama",
                "countryCode": "US"
            },
            {
                "name": "Amelia",
                "countryCode": "US"
            },
            {
                "name": "Amite",
                "countryCode": "US"
            },
            {
                "name": "Arabi",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Arnaudville",
                "countryCode": "US"
            },
            {
                "name": "Ascension Parish",
                "countryCode": "US"
            },
            {
                "name": "Assumption Parish",
                "countryCode": "US"
            },
            {
                "name": "Avondale",
                "countryCode": "US"
            },
            {
                "name": "Avoyelles Parish",
                "countryCode": "US"
            },
            {
                "name": "Baker",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Ball",
                "countryCode": "US"
            },
            {
                "name": "Banks Springs",
                "countryCode": "US"
            },
            {
                "name": "Barataria",
                "countryCode": "US"
            },
            {
                "name": "Basile",
                "countryCode": "US"
            },
            {
                "name": "Bastrop",
                "countryCode": "US"
            },
            {
                "name": "Baton Rouge",
                "countryCode": "US"
            },
            {
                "name": "Bawcomville",
                "countryCode": "US"
            },
            {
                "name": "Bayou Cane",
                "countryCode": "US"
            },
            {
                "name": "Bayou Gauche",
                "countryCode": "US"
            },
            {
                "name": "Bayou Vista",
                "countryCode": "US"
            },
            {
                "name": "Beauregard Parish",
                "countryCode": "US"
            },
            {
                "name": "Belle Chasse",
                "countryCode": "US"
            },
            {
                "name": "Belle Rose",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Bernice",
                "countryCode": "US"
            },
            {
                "name": "Berwick",
                "countryCode": "US"
            },
            {
                "name": "Bienville Parish",
                "countryCode": "US"
            },
            {
                "name": "Blanchard",
                "countryCode": "US"
            },
            {
                "name": "Bogalusa",
                "countryCode": "US"
            },
            {
                "name": "Bossier City",
                "countryCode": "US"
            },
            {
                "name": "Bossier Parish",
                "countryCode": "US"
            },
            {
                "name": "Bourg",
                "countryCode": "US"
            },
            {
                "name": "Boutte",
                "countryCode": "US"
            },
            {
                "name": "Breaux Bridge",
                "countryCode": "US"
            },
            {
                "name": "Bridge City",
                "countryCode": "US"
            },
            {
                "name": "Broussard",
                "countryCode": "US"
            },
            {
                "name": "Brownsfield",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Brusly",
                "countryCode": "US"
            },
            {
                "name": "Bunkie",
                "countryCode": "US"
            },
            {
                "name": "Caddo Parish",
                "countryCode": "US"
            },
            {
                "name": "Cade",
                "countryCode": "US"
            },
            {
                "name": "Calcasieu Parish",
                "countryCode": "US"
            },
            {
                "name": "Caldwell Parish",
                "countryCode": "US"
            },
            {
                "name": "Cameron",
                "countryCode": "US"
            },
            {
                "name": "Cameron Parish",
                "countryCode": "US"
            },
            {
                "name": "Campti",
                "countryCode": "US"
            },
            {
                "name": "Carencro",
                "countryCode": "US"
            },
            {
                "name": "Carlyss",
                "countryCode": "US"
            },
            {
                "name": "Carville",
                "countryCode": "US"
            },
            {
                "name": "Catahoula",
                "countryCode": "US"
            },
            {
                "name": "Catahoula Parish",
                "countryCode": "US"
            },
            {
                "name": "Cecilia",
                "countryCode": "US"
            },
            {
                "name": "Central",
                "countryCode": "US"
            },
            {
                "name": "Chackbay",
                "countryCode": "US"
            },
            {
                "name": "Chalmette",
                "countryCode": "US"
            },
            {
                "name": "Charenton",
                "countryCode": "US"
            },
            {
                "name": "Chauvin",
                "countryCode": "US"
            },
            {
                "name": "Church Point",
                "countryCode": "US"
            },
            {
                "name": "Claiborne",
                "countryCode": "US"
            },
            {
                "name": "Claiborne Parish",
                "countryCode": "US"
            },
            {
                "name": "Clarks",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Concordia Parish",
                "countryCode": "US"
            },
            {
                "name": "Convent",
                "countryCode": "US"
            },
            {
                "name": "Cottonport",
                "countryCode": "US"
            },
            {
                "name": "Coushatta",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Crowley",
                "countryCode": "US"
            },
            {
                "name": "Cullen",
                "countryCode": "US"
            },
            {
                "name": "Cut Off",
                "countryCode": "US"
            },
            {
                "name": "De Soto Parish",
                "countryCode": "US"
            },
            {
                "name": "DeQuincy",
                "countryCode": "US"
            },
            {
                "name": "DeRidder",
                "countryCode": "US"
            },
            {
                "name": "Delcambre",
                "countryCode": "US"
            },
            {
                "name": "Delhi",
                "countryCode": "US"
            },
            {
                "name": "Denham Springs",
                "countryCode": "US"
            },
            {
                "name": "Des Allemands",
                "countryCode": "US"
            },
            {
                "name": "Destrehan",
                "countryCode": "US"
            },
            {
                "name": "Deville",
                "countryCode": "US"
            },
            {
                "name": "Donaldsonville",
                "countryCode": "US"
            },
            {
                "name": "Dulac",
                "countryCode": "US"
            },
            {
                "name": "Duson",
                "countryCode": "US"
            },
            {
                "name": "East Baton Rouge Parish",
                "countryCode": "US"
            },
            {
                "name": "East Carroll Parish",
                "countryCode": "US"
            },
            {
                "name": "East Feliciana Parish",
                "countryCode": "US"
            },
            {
                "name": "Eastwood",
                "countryCode": "US"
            },
            {
                "name": "Eden Isle",
                "countryCode": "US"
            },
            {
                "name": "Edgard",
                "countryCode": "US"
            },
            {
                "name": "Elmwood",
                "countryCode": "US"
            },
            {
                "name": "Elton",
                "countryCode": "US"
            },
            {
                "name": "Erath",
                "countryCode": "US"
            },
            {
                "name": "Erwinville",
                "countryCode": "US"
            },
            {
                "name": "Estelle",
                "countryCode": "US"
            },
            {
                "name": "Eunice",
                "countryCode": "US"
            },
            {
                "name": "Evangeline Parish",
                "countryCode": "US"
            },
            {
                "name": "Farmerville",
                "countryCode": "US"
            },
            {
                "name": "Ferriday",
                "countryCode": "US"
            },
            {
                "name": "Fort Polk North",
                "countryCode": "US"
            },
            {
                "name": "Fort Polk South",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin Parish",
                "countryCode": "US"
            },
            {
                "name": "Franklinton",
                "countryCode": "US"
            },
            {
                "name": "French Settlement",
                "countryCode": "US"
            },
            {
                "name": "Galliano",
                "countryCode": "US"
            },
            {
                "name": "Gardere",
                "countryCode": "US"
            },
            {
                "name": "Garyville",
                "countryCode": "US"
            },
            {
                "name": "Glenmora",
                "countryCode": "US"
            },
            {
                "name": "Golden Meadow",
                "countryCode": "US"
            },
            {
                "name": "Gonzales",
                "countryCode": "US"
            },
            {
                "name": "Grambling",
                "countryCode": "US"
            },
            {
                "name": "Gramercy",
                "countryCode": "US"
            },
            {
                "name": "Grand Isle",
                "countryCode": "US"
            },
            {
                "name": "Grand Point",
                "countryCode": "US"
            },
            {
                "name": "Grant Parish",
                "countryCode": "US"
            },
            {
                "name": "Gray",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Gretna",
                "countryCode": "US"
            },
            {
                "name": "Gueydan",
                "countryCode": "US"
            },
            {
                "name": "Hackberry",
                "countryCode": "US"
            },
            {
                "name": "Hahnville",
                "countryCode": "US"
            },
            {
                "name": "Hammond",
                "countryCode": "US"
            },
            {
                "name": "Harahan",
                "countryCode": "US"
            },
            {
                "name": "Harrisonburg",
                "countryCode": "US"
            },
            {
                "name": "Harvey",
                "countryCode": "US"
            },
            {
                "name": "Haughton",
                "countryCode": "US"
            },
            {
                "name": "Haynesville",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Houma",
                "countryCode": "US"
            },
            {
                "name": "Iberia Parish",
                "countryCode": "US"
            },
            {
                "name": "Iberville Parish",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Inniswold",
                "countryCode": "US"
            },
            {
                "name": "Iota",
                "countryCode": "US"
            },
            {
                "name": "Iowa",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson Parish",
                "countryCode": "US"
            },
            {
                "name": "Jean Lafitte",
                "countryCode": "US"
            },
            {
                "name": "Jeanerette",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Davis Parish",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Parish",
                "countryCode": "US"
            },
            {
                "name": "Jena",
                "countryCode": "US"
            },
            {
                "name": "Jennings",
                "countryCode": "US"
            },
            {
                "name": "Jonesboro",
                "countryCode": "US"
            },
            {
                "name": "Jonesville",
                "countryCode": "US"
            },
            {
                "name": "Kaplan",
                "countryCode": "US"
            },
            {
                "name": "Kenner",
                "countryCode": "US"
            },
            {
                "name": "Kentwood",
                "countryCode": "US"
            },
            {
                "name": "Killian",
                "countryCode": "US"
            },
            {
                "name": "Kinder",
                "countryCode": "US"
            },
            {
                "name": "Krotz Springs",
                "countryCode": "US"
            },
            {
                "name": "La Salle Parish",
                "countryCode": "US"
            },
            {
                "name": "Labadieville",
                "countryCode": "US"
            },
            {
                "name": "Lacombe",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lafayette Parish",
                "countryCode": "US"
            },
            {
                "name": "Lafourche Parish",
                "countryCode": "US"
            },
            {
                "name": "Lake Arthur",
                "countryCode": "US"
            },
            {
                "name": "Lake Charles",
                "countryCode": "US"
            },
            {
                "name": "Lake Providence",
                "countryCode": "US"
            },
            {
                "name": "Lakeshore",
                "countryCode": "US"
            },
            {
                "name": "Laplace",
                "countryCode": "US"
            },
            {
                "name": "Larose",
                "countryCode": "US"
            },
            {
                "name": "Lawtell",
                "countryCode": "US"
            },
            {
                "name": "Lecompte",
                "countryCode": "US"
            },
            {
                "name": "Leesville",
                "countryCode": "US"
            },
            {
                "name": "Leonville",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Parish",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Livingston Parish",
                "countryCode": "US"
            },
            {
                "name": "Livonia",
                "countryCode": "US"
            },
            {
                "name": "Lockport",
                "countryCode": "US"
            },
            {
                "name": "Lockport Heights",
                "countryCode": "US"
            },
            {
                "name": "Logansport",
                "countryCode": "US"
            },
            {
                "name": "Luling",
                "countryCode": "US"
            },
            {
                "name": "Lutcher",
                "countryCode": "US"
            },
            {
                "name": "Madison Parish",
                "countryCode": "US"
            },
            {
                "name": "Mamou",
                "countryCode": "US"
            },
            {
                "name": "Mandeville",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Mansura",
                "countryCode": "US"
            },
            {
                "name": "Many",
                "countryCode": "US"
            },
            {
                "name": "Maringouin",
                "countryCode": "US"
            },
            {
                "name": "Marksville",
                "countryCode": "US"
            },
            {
                "name": "Marrero",
                "countryCode": "US"
            },
            {
                "name": "Mathews",
                "countryCode": "US"
            },
            {
                "name": "Maurice",
                "countryCode": "US"
            },
            {
                "name": "Melville",
                "countryCode": "US"
            },
            {
                "name": "Meraux",
                "countryCode": "US"
            },
            {
                "name": "Merrydale",
                "countryCode": "US"
            },
            {
                "name": "Merryville",
                "countryCode": "US"
            },
            {
                "name": "Metairie",
                "countryCode": "US"
            },
            {
                "name": "Metairie Terrace",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Minden",
                "countryCode": "US"
            },
            {
                "name": "Minorca",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Montegut",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Montz",
                "countryCode": "US"
            },
            {
                "name": "Morehouse Parish",
                "countryCode": "US"
            },
            {
                "name": "Morgan City",
                "countryCode": "US"
            },
            {
                "name": "Moss Bluff",
                "countryCode": "US"
            },
            {
                "name": "Napoleonville",
                "countryCode": "US"
            },
            {
                "name": "Natalbany",
                "countryCode": "US"
            },
            {
                "name": "Natchitoches",
                "countryCode": "US"
            },
            {
                "name": "Natchitoches Parish",
                "countryCode": "US"
            },
            {
                "name": "New Iberia",
                "countryCode": "US"
            },
            {
                "name": "New Llano",
                "countryCode": "US"
            },
            {
                "name": "New Orleans",
                "countryCode": "US"
            },
            {
                "name": "New Roads",
                "countryCode": "US"
            },
            {
                "name": "New Sarpy",
                "countryCode": "US"
            },
            {
                "name": "Newellton",
                "countryCode": "US"
            },
            {
                "name": "Norco",
                "countryCode": "US"
            },
            {
                "name": "North Vacherie",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oak Hills Place",
                "countryCode": "US"
            },
            {
                "name": "Oakdale",
                "countryCode": "US"
            },
            {
                "name": "Oberlin",
                "countryCode": "US"
            },
            {
                "name": "Old Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Olla",
                "countryCode": "US"
            },
            {
                "name": "Opelousas",
                "countryCode": "US"
            },
            {
                "name": "Orleans Parish",
                "countryCode": "US"
            },
            {
                "name": "Ossun",
                "countryCode": "US"
            },
            {
                "name": "Ouachita Parish",
                "countryCode": "US"
            },
            {
                "name": "Paradis",
                "countryCode": "US"
            },
            {
                "name": "Patterson",
                "countryCode": "US"
            },
            {
                "name": "Paulina",
                "countryCode": "US"
            },
            {
                "name": "Pearl River",
                "countryCode": "US"
            },
            {
                "name": "Pierre Part",
                "countryCode": "US"
            },
            {
                "name": "Pine Prairie",
                "countryCode": "US"
            },
            {
                "name": "Pineville",
                "countryCode": "US"
            },
            {
                "name": "Plaquemine",
                "countryCode": "US"
            },
            {
                "name": "Plaquemines Parish",
                "countryCode": "US"
            },
            {
                "name": "Pointe Coupee Parish",
                "countryCode": "US"
            },
            {
                "name": "Ponchatoula",
                "countryCode": "US"
            },
            {
                "name": "Port Allen",
                "countryCode": "US"
            },
            {
                "name": "Port Barre",
                "countryCode": "US"
            },
            {
                "name": "Port Sulphur",
                "countryCode": "US"
            },
            {
                "name": "Poydras",
                "countryCode": "US"
            },
            {
                "name": "Prairieville",
                "countryCode": "US"
            },
            {
                "name": "Presquille",
                "countryCode": "US"
            },
            {
                "name": "Prien",
                "countryCode": "US"
            },
            {
                "name": "Raceland",
                "countryCode": "US"
            },
            {
                "name": "Rapides Parish",
                "countryCode": "US"
            },
            {
                "name": "Rayne",
                "countryCode": "US"
            },
            {
                "name": "Rayville",
                "countryCode": "US"
            },
            {
                "name": "Red Chute",
                "countryCode": "US"
            },
            {
                "name": "Red River Parish",
                "countryCode": "US"
            },
            {
                "name": "Reserve",
                "countryCode": "US"
            },
            {
                "name": "Richland Parish",
                "countryCode": "US"
            },
            {
                "name": "Richwood",
                "countryCode": "US"
            },
            {
                "name": "Ringgold",
                "countryCode": "US"
            },
            {
                "name": "River Ridge",
                "countryCode": "US"
            },
            {
                "name": "Roseland",
                "countryCode": "US"
            },
            {
                "name": "Rosepine",
                "countryCode": "US"
            },
            {
                "name": "Ruston",
                "countryCode": "US"
            },
            {
                "name": "Sabine Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Bernard Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Francisville",
                "countryCode": "US"
            },
            {
                "name": "Saint Gabriel",
                "countryCode": "US"
            },
            {
                "name": "Saint Helena Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint James Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint John the Baptist Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "US"
            },
            {
                "name": "Saint Landry Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Martin Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Martinville",
                "countryCode": "US"
            },
            {
                "name": "Saint Mary Parish",
                "countryCode": "US"
            },
            {
                "name": "Saint Rose",
                "countryCode": "US"
            },
            {
                "name": "Saint Tammany Parish",
                "countryCode": "US"
            },
            {
                "name": "Schriever",
                "countryCode": "US"
            },
            {
                "name": "Scott",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah",
                "countryCode": "US"
            },
            {
                "name": "Shreveport",
                "countryCode": "US"
            },
            {
                "name": "Sibley",
                "countryCode": "US"
            },
            {
                "name": "Simmesport",
                "countryCode": "US"
            },
            {
                "name": "Slidell",
                "countryCode": "US"
            },
            {
                "name": "Sorrento",
                "countryCode": "US"
            },
            {
                "name": "South Vacherie",
                "countryCode": "US"
            },
            {
                "name": "Springhill",
                "countryCode": "US"
            },
            {
                "name": "Sterlington",
                "countryCode": "US"
            },
            {
                "name": "Stonewall",
                "countryCode": "US"
            },
            {
                "name": "Sulphur",
                "countryCode": "US"
            },
            {
                "name": "Sunset",
                "countryCode": "US"
            },
            {
                "name": "Supreme",
                "countryCode": "US"
            },
            {
                "name": "Swartz",
                "countryCode": "US"
            },
            {
                "name": "Tallulah",
                "countryCode": "US"
            },
            {
                "name": "Tangipahoa Parish",
                "countryCode": "US"
            },
            {
                "name": "Tensas Parish",
                "countryCode": "US"
            },
            {
                "name": "Terrebonne Parish",
                "countryCode": "US"
            },
            {
                "name": "Terrytown",
                "countryCode": "US"
            },
            {
                "name": "Thibodaux",
                "countryCode": "US"
            },
            {
                "name": "Timberlane",
                "countryCode": "US"
            },
            {
                "name": "Union Parish",
                "countryCode": "US"
            },
            {
                "name": "Urania",
                "countryCode": "US"
            },
            {
                "name": "Vermilion Parish",
                "countryCode": "US"
            },
            {
                "name": "Vernon Parish",
                "countryCode": "US"
            },
            {
                "name": "Vidalia",
                "countryCode": "US"
            },
            {
                "name": "Vienna Bend",
                "countryCode": "US"
            },
            {
                "name": "Village Saint George",
                "countryCode": "US"
            },
            {
                "name": "Ville Platte",
                "countryCode": "US"
            },
            {
                "name": "Vinton",
                "countryCode": "US"
            },
            {
                "name": "Violet",
                "countryCode": "US"
            },
            {
                "name": "Vivian",
                "countryCode": "US"
            },
            {
                "name": "Waggaman",
                "countryCode": "US"
            },
            {
                "name": "Walker",
                "countryCode": "US"
            },
            {
                "name": "Washington Parish",
                "countryCode": "US"
            },
            {
                "name": "Watson",
                "countryCode": "US"
            },
            {
                "name": "Webster Parish",
                "countryCode": "US"
            },
            {
                "name": "Welsh",
                "countryCode": "US"
            },
            {
                "name": "West Baton Rouge Parish",
                "countryCode": "US"
            },
            {
                "name": "West Carroll Parish",
                "countryCode": "US"
            },
            {
                "name": "West Feliciana Parish",
                "countryCode": "US"
            },
            {
                "name": "West Ferriday",
                "countryCode": "US"
            },
            {
                "name": "West Monroe",
                "countryCode": "US"
            },
            {
                "name": "Westlake",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Westwego",
                "countryCode": "US"
            },
            {
                "name": "White Castle",
                "countryCode": "US"
            },
            {
                "name": "Winn Parish",
                "countryCode": "US"
            },
            {
                "name": "Winnfield",
                "countryCode": "US"
            },
            {
                "name": "Winnsboro",
                "countryCode": "US"
            },
            {
                "name": "Woodmere",
                "countryCode": "US"
            },
            {
                "name": "Woodworth",
                "countryCode": "US"
            },
            {
                "name": "Youngsville",
                "countryCode": "US"
            },
            {
                "name": "Zachary",
                "countryCode": "US"
            },
            {
                "name": "Zwolle",
                "countryCode": "US"
            },
            {
                "name": "Abington",
                "countryCode": "US"
            },
            {
                "name": "Acton",
                "countryCode": "US"
            },
            {
                "name": "Acushnet",
                "countryCode": "US"
            },
            {
                "name": "Acushnet Center",
                "countryCode": "US"
            },
            {
                "name": "Adams",
                "countryCode": "US"
            },
            {
                "name": "Agawam",
                "countryCode": "US"
            },
            {
                "name": "Amesbury",
                "countryCode": "US"
            },
            {
                "name": "Amherst",
                "countryCode": "US"
            },
            {
                "name": "Amherst Center",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Ashburnham",
                "countryCode": "US"
            },
            {
                "name": "Ashby",
                "countryCode": "US"
            },
            {
                "name": "Ashfield",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Assonet",
                "countryCode": "US"
            },
            {
                "name": "Athol",
                "countryCode": "US"
            },
            {
                "name": "Attleboro",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Ayer",
                "countryCode": "US"
            },
            {
                "name": "Baldwinville",
                "countryCode": "US"
            },
            {
                "name": "Barnstable",
                "countryCode": "US"
            },
            {
                "name": "Barnstable County",
                "countryCode": "US"
            },
            {
                "name": "Barre",
                "countryCode": "US"
            },
            {
                "name": "Becket",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Belchertown",
                "countryCode": "US"
            },
            {
                "name": "Bellingham",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Berkley",
                "countryCode": "US"
            },
            {
                "name": "Berkshire County",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Bernardston",
                "countryCode": "US"
            },
            {
                "name": "Beverly",
                "countryCode": "US"
            },
            {
                "name": "Beverly Cove",
                "countryCode": "US"
            },
            {
                "name": "Billerica",
                "countryCode": "US"
            },
            {
                "name": "Blackstone",
                "countryCode": "US"
            },
            {
                "name": "Bliss Corner",
                "countryCode": "US"
            },
            {
                "name": "Bolton",
                "countryCode": "US"
            },
            {
                "name": "Bondsville",
                "countryCode": "US"
            },
            {
                "name": "Boston",
                "countryCode": "US"
            },
            {
                "name": "Bourne",
                "countryCode": "US"
            },
            {
                "name": "Boxborough",
                "countryCode": "US"
            },
            {
                "name": "Boxford",
                "countryCode": "US"
            },
            {
                "name": "Boylston",
                "countryCode": "US"
            },
            {
                "name": "Braintree",
                "countryCode": "US"
            },
            {
                "name": "Brewster",
                "countryCode": "US"
            },
            {
                "name": "Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "Brimfield",
                "countryCode": "US"
            },
            {
                "name": "Bristol County",
                "countryCode": "US"
            },
            {
                "name": "Brockton",
                "countryCode": "US"
            },
            {
                "name": "Brookline",
                "countryCode": "US"
            },
            {
                "name": "Buckland",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Buzzards Bay",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carver",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Charlemont",
                "countryCode": "US"
            },
            {
                "name": "Charlton",
                "countryCode": "US"
            },
            {
                "name": "Chatham",
                "countryCode": "US"
            },
            {
                "name": "Chelmsford",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield",
                "countryCode": "US"
            },
            {
                "name": "Chicopee",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Cochituate",
                "countryCode": "US"
            },
            {
                "name": "Cohasset",
                "countryCode": "US"
            },
            {
                "name": "Colrain",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Cordaville",
                "countryCode": "US"
            },
            {
                "name": "Cotuit",
                "countryCode": "US"
            },
            {
                "name": "Dalton",
                "countryCode": "US"
            },
            {
                "name": "Danvers",
                "countryCode": "US"
            },
            {
                "name": "Dartmouth",
                "countryCode": "US"
            },
            {
                "name": "Dedham",
                "countryCode": "US"
            },
            {
                "name": "Dennis",
                "countryCode": "US"
            },
            {
                "name": "Dennis Port",
                "countryCode": "US"
            },
            {
                "name": "Devens",
                "countryCode": "US"
            },
            {
                "name": "Dighton",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dracut",
                "countryCode": "US"
            },
            {
                "name": "Dudley",
                "countryCode": "US"
            },
            {
                "name": "Dukes County",
                "countryCode": "US"
            },
            {
                "name": "Dunstable",
                "countryCode": "US"
            },
            {
                "name": "Duxbury",
                "countryCode": "US"
            },
            {
                "name": "East Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "East Brookfield",
                "countryCode": "US"
            },
            {
                "name": "East Dennis",
                "countryCode": "US"
            },
            {
                "name": "East Douglas",
                "countryCode": "US"
            },
            {
                "name": "East Falmouth",
                "countryCode": "US"
            },
            {
                "name": "East Harwich",
                "countryCode": "US"
            },
            {
                "name": "East Longmeadow",
                "countryCode": "US"
            },
            {
                "name": "East Pepperell",
                "countryCode": "US"
            },
            {
                "name": "East Sandwich",
                "countryCode": "US"
            },
            {
                "name": "Eastham",
                "countryCode": "US"
            },
            {
                "name": "Easthampton",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Edgartown",
                "countryCode": "US"
            },
            {
                "name": "Erving",
                "countryCode": "US"
            },
            {
                "name": "Essex",
                "countryCode": "US"
            },
            {
                "name": "Essex County",
                "countryCode": "US"
            },
            {
                "name": "Everett",
                "countryCode": "US"
            },
            {
                "name": "Fairhaven",
                "countryCode": "US"
            },
            {
                "name": "Fall River",
                "countryCode": "US"
            },
            {
                "name": "Falmouth",
                "countryCode": "US"
            },
            {
                "name": "Fiskdale",
                "countryCode": "US"
            },
            {
                "name": "Fitchburg",
                "countryCode": "US"
            },
            {
                "name": "Forestdale",
                "countryCode": "US"
            },
            {
                "name": "Foxborough",
                "countryCode": "US"
            },
            {
                "name": "Framingham",
                "countryCode": "US"
            },
            {
                "name": "Framingham Center",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Freetown",
                "countryCode": "US"
            },
            {
                "name": "Gardner",
                "countryCode": "US"
            },
            {
                "name": "Gill",
                "countryCode": "US"
            },
            {
                "name": "Gloucester",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Granby",
                "countryCode": "US"
            },
            {
                "name": "Granville",
                "countryCode": "US"
            },
            {
                "name": "Great Barrington",
                "countryCode": "US"
            },
            {
                "name": "Green Harbor-Cedar Crest",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Groton",
                "countryCode": "US"
            },
            {
                "name": "Groveland",
                "countryCode": "US"
            },
            {
                "name": "Hadley",
                "countryCode": "US"
            },
            {
                "name": "Halifax",
                "countryCode": "US"
            },
            {
                "name": "Hamilton Worcester",
                "countryCode": "US"
            },
            {
                "name": "Hampden",
                "countryCode": "US"
            },
            {
                "name": "Hampden County",
                "countryCode": "US"
            },
            {
                "name": "Hampshire County",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Hanson",
                "countryCode": "US"
            },
            {
                "name": "Hardwick",
                "countryCode": "US"
            },
            {
                "name": "Harvard",
                "countryCode": "US"
            },
            {
                "name": "Harwich",
                "countryCode": "US"
            },
            {
                "name": "Harwich Center",
                "countryCode": "US"
            },
            {
                "name": "Harwich Port",
                "countryCode": "US"
            },
            {
                "name": "Hatfield",
                "countryCode": "US"
            },
            {
                "name": "Haverhill",
                "countryCode": "US"
            },
            {
                "name": "Head of Westport",
                "countryCode": "US"
            },
            {
                "name": "Hingham",
                "countryCode": "US"
            },
            {
                "name": "Hinsdale",
                "countryCode": "US"
            },
            {
                "name": "Holbrook",
                "countryCode": "US"
            },
            {
                "name": "Holden",
                "countryCode": "US"
            },
            {
                "name": "Holland",
                "countryCode": "US"
            },
            {
                "name": "Holliston",
                "countryCode": "US"
            },
            {
                "name": "Holyoke",
                "countryCode": "US"
            },
            {
                "name": "Hopedale",
                "countryCode": "US"
            },
            {
                "name": "Hopkinton",
                "countryCode": "US"
            },
            {
                "name": "Housatonic",
                "countryCode": "US"
            },
            {
                "name": "Hubbardston",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hull",
                "countryCode": "US"
            },
            {
                "name": "Hyannis",
                "countryCode": "US"
            },
            {
                "name": "Ipswich",
                "countryCode": "US"
            },
            {
                "name": "Jamaica Plain",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lanesborough",
                "countryCode": "US"
            },
            {
                "name": "Lawrence",
                "countryCode": "US"
            },
            {
                "name": "Lee",
                "countryCode": "US"
            },
            {
                "name": "Leicester",
                "countryCode": "US"
            },
            {
                "name": "Lenox",
                "countryCode": "US"
            },
            {
                "name": "Leominster",
                "countryCode": "US"
            },
            {
                "name": "Leverett",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Littleton Common",
                "countryCode": "US"
            },
            {
                "name": "Longmeadow",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Ludlow",
                "countryCode": "US"
            },
            {
                "name": "Lunenburg",
                "countryCode": "US"
            },
            {
                "name": "Lynn",
                "countryCode": "US"
            },
            {
                "name": "Lynnfield",
                "countryCode": "US"
            },
            {
                "name": "Malden",
                "countryCode": "US"
            },
            {
                "name": "Manchester-by-the-Sea",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Mansfield Center",
                "countryCode": "US"
            },
            {
                "name": "Marblehead",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion Center",
                "countryCode": "US"
            },
            {
                "name": "Marlborough",
                "countryCode": "US"
            },
            {
                "name": "Marshfield",
                "countryCode": "US"
            },
            {
                "name": "Marshfield Hills",
                "countryCode": "US"
            },
            {
                "name": "Marstons Mills",
                "countryCode": "US"
            },
            {
                "name": "Mashpee",
                "countryCode": "US"
            },
            {
                "name": "Mattapoisett",
                "countryCode": "US"
            },
            {
                "name": "Mattapoisett Center",
                "countryCode": "US"
            },
            {
                "name": "Maynard",
                "countryCode": "US"
            },
            {
                "name": "Medfield",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Medway",
                "countryCode": "US"
            },
            {
                "name": "Melrose",
                "countryCode": "US"
            },
            {
                "name": "Mendon",
                "countryCode": "US"
            },
            {
                "name": "Merrimac",
                "countryCode": "US"
            },
            {
                "name": "Methuen",
                "countryCode": "US"
            },
            {
                "name": "Middleborough",
                "countryCode": "US"
            },
            {
                "name": "Middleborough Center",
                "countryCode": "US"
            },
            {
                "name": "Middlesex County",
                "countryCode": "US"
            },
            {
                "name": "Middleton",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millbury",
                "countryCode": "US"
            },
            {
                "name": "Millers Falls",
                "countryCode": "US"
            },
            {
                "name": "Millis",
                "countryCode": "US"
            },
            {
                "name": "Millis-Clicquot",
                "countryCode": "US"
            },
            {
                "name": "Millville",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Monson",
                "countryCode": "US"
            },
            {
                "name": "Monson Center",
                "countryCode": "US"
            },
            {
                "name": "Montague",
                "countryCode": "US"
            },
            {
                "name": "Monument Beach",
                "countryCode": "US"
            },
            {
                "name": "Nahant",
                "countryCode": "US"
            },
            {
                "name": "Nantucket",
                "countryCode": "US"
            },
            {
                "name": "Nantucket County",
                "countryCode": "US"
            },
            {
                "name": "Natick",
                "countryCode": "US"
            },
            {
                "name": "Needham",
                "countryCode": "US"
            },
            {
                "name": "New Bedford",
                "countryCode": "US"
            },
            {
                "name": "New Marlborough",
                "countryCode": "US"
            },
            {
                "name": "Newburyport",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Norfolk",
                "countryCode": "US"
            },
            {
                "name": "Norfolk County",
                "countryCode": "US"
            },
            {
                "name": "North Adams",
                "countryCode": "US"
            },
            {
                "name": "North Amherst",
                "countryCode": "US"
            },
            {
                "name": "North Andover",
                "countryCode": "US"
            },
            {
                "name": "North Attleborough Center",
                "countryCode": "US"
            },
            {
                "name": "North Brookfield",
                "countryCode": "US"
            },
            {
                "name": "North Chicopee",
                "countryCode": "US"
            },
            {
                "name": "North Eastham",
                "countryCode": "US"
            },
            {
                "name": "North Falmouth",
                "countryCode": "US"
            },
            {
                "name": "North Lakeville",
                "countryCode": "US"
            },
            {
                "name": "North Pembroke",
                "countryCode": "US"
            },
            {
                "name": "North Plymouth",
                "countryCode": "US"
            },
            {
                "name": "North Reading",
                "countryCode": "US"
            },
            {
                "name": "North Scituate",
                "countryCode": "US"
            },
            {
                "name": "North Seekonk",
                "countryCode": "US"
            },
            {
                "name": "North Westport",
                "countryCode": "US"
            },
            {
                "name": "Northampton",
                "countryCode": "US"
            },
            {
                "name": "Northborough",
                "countryCode": "US"
            },
            {
                "name": "Northbridge",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Northwest Harwich",
                "countryCode": "US"
            },
            {
                "name": "Norton",
                "countryCode": "US"
            },
            {
                "name": "Norton Center",
                "countryCode": "US"
            },
            {
                "name": "Norwell",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Oak Bluffs",
                "countryCode": "US"
            },
            {
                "name": "Oakham",
                "countryCode": "US"
            },
            {
                "name": "Ocean Bluff-Brant Rock",
                "countryCode": "US"
            },
            {
                "name": "Ocean Grove",
                "countryCode": "US"
            },
            {
                "name": "Onset",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Orleans",
                "countryCode": "US"
            },
            {
                "name": "Osterville",
                "countryCode": "US"
            },
            {
                "name": "Otis",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Palmer",
                "countryCode": "US"
            },
            {
                "name": "Paxton",
                "countryCode": "US"
            },
            {
                "name": "Peabody",
                "countryCode": "US"
            },
            {
                "name": "Pelham",
                "countryCode": "US"
            },
            {
                "name": "Pepperell",
                "countryCode": "US"
            },
            {
                "name": "Phillipston",
                "countryCode": "US"
            },
            {
                "name": "Pinehurst",
                "countryCode": "US"
            },
            {
                "name": "Pittsfield",
                "countryCode": "US"
            },
            {
                "name": "Plainville",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Plymouth County",
                "countryCode": "US"
            },
            {
                "name": "Plympton",
                "countryCode": "US"
            },
            {
                "name": "Pocasset",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Provincetown",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Raynham",
                "countryCode": "US"
            },
            {
                "name": "Raynham Center",
                "countryCode": "US"
            },
            {
                "name": "Reading",
                "countryCode": "US"
            },
            {
                "name": "Rehoboth",
                "countryCode": "US"
            },
            {
                "name": "Revere",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rockland",
                "countryCode": "US"
            },
            {
                "name": "Rockport",
                "countryCode": "US"
            },
            {
                "name": "Rowley",
                "countryCode": "US"
            },
            {
                "name": "Royalston",
                "countryCode": "US"
            },
            {
                "name": "Rutland",
                "countryCode": "US"
            },
            {
                "name": "Sagamore",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Sandwich",
                "countryCode": "US"
            },
            {
                "name": "Saugus",
                "countryCode": "US"
            },
            {
                "name": "Scituate",
                "countryCode": "US"
            },
            {
                "name": "Seekonk",
                "countryCode": "US"
            },
            {
                "name": "Sharon",
                "countryCode": "US"
            },
            {
                "name": "Sheffield",
                "countryCode": "US"
            },
            {
                "name": "Shelburne",
                "countryCode": "US"
            },
            {
                "name": "Shelburne Falls",
                "countryCode": "US"
            },
            {
                "name": "Sherborn",
                "countryCode": "US"
            },
            {
                "name": "Shirley",
                "countryCode": "US"
            },
            {
                "name": "Shrewsbury",
                "countryCode": "US"
            },
            {
                "name": "Shutesbury",
                "countryCode": "US"
            },
            {
                "name": "Smith Mills",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "Somerville",
                "countryCode": "US"
            },
            {
                "name": "South Amherst",
                "countryCode": "US"
            },
            {
                "name": "South Ashburnham",
                "countryCode": "US"
            },
            {
                "name": "South Boston",
                "countryCode": "US"
            },
            {
                "name": "South Deerfield",
                "countryCode": "US"
            },
            {
                "name": "South Dennis",
                "countryCode": "US"
            },
            {
                "name": "South Duxbury",
                "countryCode": "US"
            },
            {
                "name": "South Hadley",
                "countryCode": "US"
            },
            {
                "name": "South Lancaster",
                "countryCode": "US"
            },
            {
                "name": "South Peabody",
                "countryCode": "US"
            },
            {
                "name": "South Yarmouth",
                "countryCode": "US"
            },
            {
                "name": "Southampton",
                "countryCode": "US"
            },
            {
                "name": "Southborough",
                "countryCode": "US"
            },
            {
                "name": "Southbridge",
                "countryCode": "US"
            },
            {
                "name": "Southwick",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Stockbridge",
                "countryCode": "US"
            },
            {
                "name": "Stoneham",
                "countryCode": "US"
            },
            {
                "name": "Stoughton",
                "countryCode": "US"
            },
            {
                "name": "Stow",
                "countryCode": "US"
            },
            {
                "name": "Sturbridge",
                "countryCode": "US"
            },
            {
                "name": "Sudbury",
                "countryCode": "US"
            },
            {
                "name": "Suffolk County",
                "countryCode": "US"
            },
            {
                "name": "Sunderland",
                "countryCode": "US"
            },
            {
                "name": "Sutton",
                "countryCode": "US"
            },
            {
                "name": "Swampscott",
                "countryCode": "US"
            },
            {
                "name": "Swansea",
                "countryCode": "US"
            },
            {
                "name": "Taunton",
                "countryCode": "US"
            },
            {
                "name": "Teaticket",
                "countryCode": "US"
            },
            {
                "name": "Templeton",
                "countryCode": "US"
            },
            {
                "name": "Tewksbury",
                "countryCode": "US"
            },
            {
                "name": "Three Rivers",
                "countryCode": "US"
            },
            {
                "name": "Topsfield",
                "countryCode": "US"
            },
            {
                "name": "Townsend",
                "countryCode": "US"
            },
            {
                "name": "Truro",
                "countryCode": "US"
            },
            {
                "name": "Turners Falls",
                "countryCode": "US"
            },
            {
                "name": "Tyngsboro",
                "countryCode": "US"
            },
            {
                "name": "Upton",
                "countryCode": "US"
            },
            {
                "name": "Uxbridge",
                "countryCode": "US"
            },
            {
                "name": "Vineyard Haven",
                "countryCode": "US"
            },
            {
                "name": "Wakefield",
                "countryCode": "US"
            },
            {
                "name": "Wales",
                "countryCode": "US"
            },
            {
                "name": "Walpole",
                "countryCode": "US"
            },
            {
                "name": "Waltham",
                "countryCode": "US"
            },
            {
                "name": "Ware",
                "countryCode": "US"
            },
            {
                "name": "Wareham Center",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Wayland",
                "countryCode": "US"
            },
            {
                "name": "Webster",
                "countryCode": "US"
            },
            {
                "name": "Wellesley",
                "countryCode": "US"
            },
            {
                "name": "Wellfleet",
                "countryCode": "US"
            },
            {
                "name": "Wendell",
                "countryCode": "US"
            },
            {
                "name": "Wenham",
                "countryCode": "US"
            },
            {
                "name": "West Barnstable",
                "countryCode": "US"
            },
            {
                "name": "West Boylston",
                "countryCode": "US"
            },
            {
                "name": "West Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "West Brookfield",
                "countryCode": "US"
            },
            {
                "name": "West Chatham",
                "countryCode": "US"
            },
            {
                "name": "West Concord",
                "countryCode": "US"
            },
            {
                "name": "West Dennis",
                "countryCode": "US"
            },
            {
                "name": "West Falmouth",
                "countryCode": "US"
            },
            {
                "name": "West Newbury",
                "countryCode": "US"
            },
            {
                "name": "West Springfield",
                "countryCode": "US"
            },
            {
                "name": "West Stockbridge",
                "countryCode": "US"
            },
            {
                "name": "West Tisbury",
                "countryCode": "US"
            },
            {
                "name": "West Wareham",
                "countryCode": "US"
            },
            {
                "name": "West Yarmouth",
                "countryCode": "US"
            },
            {
                "name": "Westborough",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Westford",
                "countryCode": "US"
            },
            {
                "name": "Westhampton",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Weweantic",
                "countryCode": "US"
            },
            {
                "name": "Weymouth",
                "countryCode": "US"
            },
            {
                "name": "Whately",
                "countryCode": "US"
            },
            {
                "name": "White Island Shores",
                "countryCode": "US"
            },
            {
                "name": "Whitinsville",
                "countryCode": "US"
            },
            {
                "name": "Whitman",
                "countryCode": "US"
            },
            {
                "name": "Wilbraham",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Winchendon",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Winthrop",
                "countryCode": "US"
            },
            {
                "name": "Woburn",
                "countryCode": "US"
            },
            {
                "name": "Worcester",
                "countryCode": "US"
            },
            {
                "name": "Worcester County",
                "countryCode": "US"
            },
            {
                "name": "Wrentham",
                "countryCode": "US"
            },
            {
                "name": "Yarmouth",
                "countryCode": "US"
            },
            {
                "name": "Yarmouth Port",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen Proving Ground",
                "countryCode": "US"
            },
            {
                "name": "Accokeek",
                "countryCode": "US"
            },
            {
                "name": "Adamstown",
                "countryCode": "US"
            },
            {
                "name": "Adelphi",
                "countryCode": "US"
            },
            {
                "name": "Algonquin",
                "countryCode": "US"
            },
            {
                "name": "Allegany County",
                "countryCode": "US"
            },
            {
                "name": "Andrews AFB",
                "countryCode": "US"
            },
            {
                "name": "Annapolis",
                "countryCode": "US"
            },
            {
                "name": "Anne Arundel County",
                "countryCode": "US"
            },
            {
                "name": "Arbutus",
                "countryCode": "US"
            },
            {
                "name": "Arden on the Severn",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Arnold",
                "countryCode": "US"
            },
            {
                "name": "Ashton-Sandy Spring",
                "countryCode": "US"
            },
            {
                "name": "Aspen Hill",
                "countryCode": "US"
            },
            {
                "name": "Baden",
                "countryCode": "US"
            },
            {
                "name": "Ballenger Creek",
                "countryCode": "US"
            },
            {
                "name": "Baltimore",
                "countryCode": "US"
            },
            {
                "name": "Baltimore County",
                "countryCode": "US"
            },
            {
                "name": "Baltimore Highlands",
                "countryCode": "US"
            },
            {
                "name": "Bartonsville",
                "countryCode": "US"
            },
            {
                "name": "Bel Air",
                "countryCode": "US"
            },
            {
                "name": "Bel Air North",
                "countryCode": "US"
            },
            {
                "name": "Bel Air South",
                "countryCode": "US"
            },
            {
                "name": "Beltsville",
                "countryCode": "US"
            },
            {
                "name": "Bennsville",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Berwyn Heights",
                "countryCode": "US"
            },
            {
                "name": "Bethesda",
                "countryCode": "US"
            },
            {
                "name": "Bladensburg",
                "countryCode": "US"
            },
            {
                "name": "Boonsboro",
                "countryCode": "US"
            },
            {
                "name": "Bowie",
                "countryCode": "US"
            },
            {
                "name": "Bowleys Quarters",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Braddock Heights",
                "countryCode": "US"
            },
            {
                "name": "Brandywine",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Brock Hall",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn Park",
                "countryCode": "US"
            },
            {
                "name": "Brookmont",
                "countryCode": "US"
            },
            {
                "name": "Brunswick",
                "countryCode": "US"
            },
            {
                "name": "Bryans Road",
                "countryCode": "US"
            },
            {
                "name": "Buckeystown",
                "countryCode": "US"
            },
            {
                "name": "Burtonsville",
                "countryCode": "US"
            },
            {
                "name": "Butcher's Hill",
                "countryCode": "US"
            },
            {
                "name": "Cabin John",
                "countryCode": "US"
            },
            {
                "name": "California",
                "countryCode": "US"
            },
            {
                "name": "Calvert County",
                "countryCode": "US"
            },
            {
                "name": "Calverton",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Camp Springs",
                "countryCode": "US"
            },
            {
                "name": "Cape Saint Claire",
                "countryCode": "US"
            },
            {
                "name": "Capitol Heights",
                "countryCode": "US"
            },
            {
                "name": "Carney",
                "countryCode": "US"
            },
            {
                "name": "Caroline County",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Catonsville",
                "countryCode": "US"
            },
            {
                "name": "Cavetown",
                "countryCode": "US"
            },
            {
                "name": "Cecil County",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Charles County",
                "countryCode": "US"
            },
            {
                "name": "Charles Village",
                "countryCode": "US"
            },
            {
                "name": "Charlestown",
                "countryCode": "US"
            },
            {
                "name": "Charlotte Hall",
                "countryCode": "US"
            },
            {
                "name": "Chesapeake Beach",
                "countryCode": "US"
            },
            {
                "name": "Chesapeake Ranch Estates",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chestertown",
                "countryCode": "US"
            },
            {
                "name": "Cheverly",
                "countryCode": "US"
            },
            {
                "name": "Chevy Chase",
                "countryCode": "US"
            },
            {
                "name": "Chevy Chase Village",
                "countryCode": "US"
            },
            {
                "name": "Chillum",
                "countryCode": "US"
            },
            {
                "name": "City of Baltimore",
                "countryCode": "US"
            },
            {
                "name": "Clarksburg",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clover Hill",
                "countryCode": "US"
            },
            {
                "name": "Cloverly",
                "countryCode": "US"
            },
            {
                "name": "Cobb Island",
                "countryCode": "US"
            },
            {
                "name": "Cockeysville",
                "countryCode": "US"
            },
            {
                "name": "Colesville",
                "countryCode": "US"
            },
            {
                "name": "College Park",
                "countryCode": "US"
            },
            {
                "name": "Colmar Manor",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Coral Hills",
                "countryCode": "US"
            },
            {
                "name": "Cottage City",
                "countryCode": "US"
            },
            {
                "name": "Cresaptown",
                "countryCode": "US"
            },
            {
                "name": "Crisfield",
                "countryCode": "US"
            },
            {
                "name": "Crofton",
                "countryCode": "US"
            },
            {
                "name": "Croom",
                "countryCode": "US"
            },
            {
                "name": "Crownsville",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Damascus",
                "countryCode": "US"
            },
            {
                "name": "Darnestown",
                "countryCode": "US"
            },
            {
                "name": "Davidsonville",
                "countryCode": "US"
            },
            {
                "name": "Deale",
                "countryCode": "US"
            },
            {
                "name": "Denton",
                "countryCode": "US"
            },
            {
                "name": "Derwood",
                "countryCode": "US"
            },
            {
                "name": "District Heights",
                "countryCode": "US"
            },
            {
                "name": "Dorchester County",
                "countryCode": "US"
            },
            {
                "name": "Drum Point",
                "countryCode": "US"
            },
            {
                "name": "Dundalk",
                "countryCode": "US"
            },
            {
                "name": "Dunkirk",
                "countryCode": "US"
            },
            {
                "name": "Dunkirk Town Center",
                "countryCode": "US"
            },
            {
                "name": "East Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Edgemere",
                "countryCode": "US"
            },
            {
                "name": "Edgewater",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edmonston",
                "countryCode": "US"
            },
            {
                "name": "Eldersburg",
                "countryCode": "US"
            },
            {
                "name": "Elkridge",
                "countryCode": "US"
            },
            {
                "name": "Elkton",
                "countryCode": "US"
            },
            {
                "name": "Ellicott City",
                "countryCode": "US"
            },
            {
                "name": "Emmitsburg",
                "countryCode": "US"
            },
            {
                "name": "Essex",
                "countryCode": "US"
            },
            {
                "name": "Fairland",
                "countryCode": "US"
            },
            {
                "name": "Fairmount Heights",
                "countryCode": "US"
            },
            {
                "name": "Fairwood",
                "countryCode": "US"
            },
            {
                "name": "Fallston",
                "countryCode": "US"
            },
            {
                "name": "Federalsburg",
                "countryCode": "US"
            },
            {
                "name": "Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Forest Glen",
                "countryCode": "US"
            },
            {
                "name": "Forest Heights",
                "countryCode": "US"
            },
            {
                "name": "Forestville",
                "countryCode": "US"
            },
            {
                "name": "Fort George G Mead Junction",
                "countryCode": "US"
            },
            {
                "name": "Fort Meade",
                "countryCode": "US"
            },
            {
                "name": "Fort Washington",
                "countryCode": "US"
            },
            {
                "name": "Fountainhead-Orchard Hills",
                "countryCode": "US"
            },
            {
                "name": "Four Corners",
                "countryCode": "US"
            },
            {
                "name": "Frederick",
                "countryCode": "US"
            },
            {
                "name": "Frederick County",
                "countryCode": "US"
            },
            {
                "name": "Friendly",
                "countryCode": "US"
            },
            {
                "name": "Friendship Village",
                "countryCode": "US"
            },
            {
                "name": "Frostburg",
                "countryCode": "US"
            },
            {
                "name": "Fruitland",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Gaithersburg",
                "countryCode": "US"
            },
            {
                "name": "Gambrills",
                "countryCode": "US"
            },
            {
                "name": "Garrett County",
                "countryCode": "US"
            },
            {
                "name": "Garrett Park",
                "countryCode": "US"
            },
            {
                "name": "Garrison",
                "countryCode": "US"
            },
            {
                "name": "Germantown",
                "countryCode": "US"
            },
            {
                "name": "Glassmanor",
                "countryCode": "US"
            },
            {
                "name": "Glen Burnie",
                "countryCode": "US"
            },
            {
                "name": "Glenarden",
                "countryCode": "US"
            },
            {
                "name": "Glenmont",
                "countryCode": "US"
            },
            {
                "name": "Glenn Dale",
                "countryCode": "US"
            },
            {
                "name": "Goddard",
                "countryCode": "US"
            },
            {
                "name": "Golden Beach",
                "countryCode": "US"
            },
            {
                "name": "Grasonville",
                "countryCode": "US"
            },
            {
                "name": "Greater Upper Marlboro",
                "countryCode": "US"
            },
            {
                "name": "Green Haven",
                "countryCode": "US"
            },
            {
                "name": "Green Valley",
                "countryCode": "US"
            },
            {
                "name": "Greenbelt",
                "countryCode": "US"
            },
            {
                "name": "Greensboro",
                "countryCode": "US"
            },
            {
                "name": "Hagerstown",
                "countryCode": "US"
            },
            {
                "name": "Halfway",
                "countryCode": "US"
            },
            {
                "name": "Hampstead",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hancock",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harford County",
                "countryCode": "US"
            },
            {
                "name": "Havre de Grace",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Herald Harbor",
                "countryCode": "US"
            },
            {
                "name": "Highfield-Cascade",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Hillandale",
                "countryCode": "US"
            },
            {
                "name": "Hillcrest Heights",
                "countryCode": "US"
            },
            {
                "name": "Hillsmere Shores",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Hughesville",
                "countryCode": "US"
            },
            {
                "name": "Hunt Valley",
                "countryCode": "US"
            },
            {
                "name": "Huntingtown",
                "countryCode": "US"
            },
            {
                "name": "Huntingtown Town Center",
                "countryCode": "US"
            },
            {
                "name": "Hurlock",
                "countryCode": "US"
            },
            {
                "name": "Hyattsville",
                "countryCode": "US"
            },
            {
                "name": "Ilchester",
                "countryCode": "US"
            },
            {
                "name": "Indian Head",
                "countryCode": "US"
            },
            {
                "name": "Irvington",
                "countryCode": "US"
            },
            {
                "name": "Jarrettsville",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jessup",
                "countryCode": "US"
            },
            {
                "name": "Joppatowne",
                "countryCode": "US"
            },
            {
                "name": "Keedysville",
                "countryCode": "US"
            },
            {
                "name": "Kemp Mill",
                "countryCode": "US"
            },
            {
                "name": "Kensington",
                "countryCode": "US"
            },
            {
                "name": "Kent County",
                "countryCode": "US"
            },
            {
                "name": "Kettering",
                "countryCode": "US"
            },
            {
                "name": "Kingstown",
                "countryCode": "US"
            },
            {
                "name": "Kingsville",
                "countryCode": "US"
            },
            {
                "name": "La Plata",
                "countryCode": "US"
            },
            {
                "name": "La Vale",
                "countryCode": "US"
            },
            {
                "name": "Lake Arbor",
                "countryCode": "US"
            },
            {
                "name": "Lake Shore",
                "countryCode": "US"
            },
            {
                "name": "Landover",
                "countryCode": "US"
            },
            {
                "name": "Landover Hills",
                "countryCode": "US"
            },
            {
                "name": "Langley Park",
                "countryCode": "US"
            },
            {
                "name": "Lanham",
                "countryCode": "US"
            },
            {
                "name": "Lanham-Seabrook",
                "countryCode": "US"
            },
            {
                "name": "Lansdowne",
                "countryCode": "US"
            },
            {
                "name": "Largo",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Layhill",
                "countryCode": "US"
            },
            {
                "name": "Leisure World",
                "countryCode": "US"
            },
            {
                "name": "Leonardtown",
                "countryCode": "US"
            },
            {
                "name": "Lexington Park",
                "countryCode": "US"
            },
            {
                "name": "Linganore",
                "countryCode": "US"
            },
            {
                "name": "Linthicum",
                "countryCode": "US"
            },
            {
                "name": "Lochearn",
                "countryCode": "US"
            },
            {
                "name": "Lonaconing",
                "countryCode": "US"
            },
            {
                "name": "Londontowne",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Lusby",
                "countryCode": "US"
            },
            {
                "name": "Lutherville",
                "countryCode": "US"
            },
            {
                "name": "Lutherville-Timonium",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Marlboro Meadows",
                "countryCode": "US"
            },
            {
                "name": "Marlboro Village",
                "countryCode": "US"
            },
            {
                "name": "Marlow Heights",
                "countryCode": "US"
            },
            {
                "name": "Marlton",
                "countryCode": "US"
            },
            {
                "name": "Maryland City",
                "countryCode": "US"
            },
            {
                "name": "Maugansville",
                "countryCode": "US"
            },
            {
                "name": "Mayo",
                "countryCode": "US"
            },
            {
                "name": "Mays Chapel",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsville",
                "countryCode": "US"
            },
            {
                "name": "Mellwood",
                "countryCode": "US"
            },
            {
                "name": "Middle River",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Milford Mill",
                "countryCode": "US"
            },
            {
                "name": "Mitchellville",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery Village",
                "countryCode": "US"
            },
            {
                "name": "Morningside",
                "countryCode": "US"
            },
            {
                "name": "Mount Airy",
                "countryCode": "US"
            },
            {
                "name": "Mount Rainier",
                "countryCode": "US"
            },
            {
                "name": "Mountain Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Myersville",
                "countryCode": "US"
            },
            {
                "name": "National Harbor",
                "countryCode": "US"
            },
            {
                "name": "Naval Academy",
                "countryCode": "US"
            },
            {
                "name": "New Carrollton",
                "countryCode": "US"
            },
            {
                "name": "New Windsor",
                "countryCode": "US"
            },
            {
                "name": "North Beach",
                "countryCode": "US"
            },
            {
                "name": "North Bel Air",
                "countryCode": "US"
            },
            {
                "name": "North Bethesda",
                "countryCode": "US"
            },
            {
                "name": "North East",
                "countryCode": "US"
            },
            {
                "name": "North Kensington",
                "countryCode": "US"
            },
            {
                "name": "North Laurel",
                "countryCode": "US"
            },
            {
                "name": "North Potomac",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Ocean City",
                "countryCode": "US"
            },
            {
                "name": "Ocean Pines",
                "countryCode": "US"
            },
            {
                "name": "Odenton",
                "countryCode": "US"
            },
            {
                "name": "Olney",
                "countryCode": "US"
            },
            {
                "name": "Overlea",
                "countryCode": "US"
            },
            {
                "name": "Owings",
                "countryCode": "US"
            },
            {
                "name": "Owings Mills",
                "countryCode": "US"
            },
            {
                "name": "Oxon Hill",
                "countryCode": "US"
            },
            {
                "name": "Oxon Hill-Glassmanor",
                "countryCode": "US"
            },
            {
                "name": "Paramount-Long Meadow",
                "countryCode": "US"
            },
            {
                "name": "Parkville",
                "countryCode": "US"
            },
            {
                "name": "Parole",
                "countryCode": "US"
            },
            {
                "name": "Pasadena",
                "countryCode": "US"
            },
            {
                "name": "Peppermill Village",
                "countryCode": "US"
            },
            {
                "name": "Perry Hall",
                "countryCode": "US"
            },
            {
                "name": "Perryman",
                "countryCode": "US"
            },
            {
                "name": "Perryville",
                "countryCode": "US"
            },
            {
                "name": "Pikesville",
                "countryCode": "US"
            },
            {
                "name": "Pittsville",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hills",
                "countryCode": "US"
            },
            {
                "name": "Pocomoke City",
                "countryCode": "US"
            },
            {
                "name": "Point of Rocks",
                "countryCode": "US"
            },
            {
                "name": "Poolesville",
                "countryCode": "US"
            },
            {
                "name": "Potomac",
                "countryCode": "US"
            },
            {
                "name": "Potomac Heights",
                "countryCode": "US"
            },
            {
                "name": "Potomac Park",
                "countryCode": "US"
            },
            {
                "name": "Prince Frederick",
                "countryCode": "US"
            },
            {
                "name": "Prince George's County",
                "countryCode": "US"
            },
            {
                "name": "Princess Anne",
                "countryCode": "US"
            },
            {
                "name": "Pumphrey",
                "countryCode": "US"
            },
            {
                "name": "Queen Anne",
                "countryCode": "US"
            },
            {
                "name": "Queen Anne's County",
                "countryCode": "US"
            },
            {
                "name": "Queenland",
                "countryCode": "US"
            },
            {
                "name": "Randallstown",
                "countryCode": "US"
            },
            {
                "name": "Redland",
                "countryCode": "US"
            },
            {
                "name": "Reisterstown",
                "countryCode": "US"
            },
            {
                "name": "Ridgely",
                "countryCode": "US"
            },
            {
                "name": "Rising Sun",
                "countryCode": "US"
            },
            {
                "name": "Riva",
                "countryCode": "US"
            },
            {
                "name": "Riverdale Park",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Riviera Beach",
                "countryCode": "US"
            },
            {
                "name": "Robinwood",
                "countryCode": "US"
            },
            {
                "name": "Rock Hall",
                "countryCode": "US"
            },
            {
                "name": "Rockville",
                "countryCode": "US"
            },
            {
                "name": "Rosaryville",
                "countryCode": "US"
            },
            {
                "name": "Rosedale",
                "countryCode": "US"
            },
            {
                "name": "Rossmoor",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles",
                "countryCode": "US"
            },
            {
                "name": "Saint James",
                "countryCode": "US"
            },
            {
                "name": "Saint Mary's County",
                "countryCode": "US"
            },
            {
                "name": "Saint Michaels",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Savage",
                "countryCode": "US"
            },
            {
                "name": "Scaggsville",
                "countryCode": "US"
            },
            {
                "name": "Seabrook",
                "countryCode": "US"
            },
            {
                "name": "Seat Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Selby-on-the-Bay",
                "countryCode": "US"
            },
            {
                "name": "Severn",
                "countryCode": "US"
            },
            {
                "name": "Severna Park",
                "countryCode": "US"
            },
            {
                "name": "Shady Side",
                "countryCode": "US"
            },
            {
                "name": "Silver Hill",
                "countryCode": "US"
            },
            {
                "name": "Silver Spring",
                "countryCode": "US"
            },
            {
                "name": "Smithsburg",
                "countryCode": "US"
            },
            {
                "name": "Snow Hill",
                "countryCode": "US"
            },
            {
                "name": "Solomons",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "Somerset County",
                "countryCode": "US"
            },
            {
                "name": "South Bel Air",
                "countryCode": "US"
            },
            {
                "name": "South Gate",
                "countryCode": "US"
            },
            {
                "name": "South Kensington",
                "countryCode": "US"
            },
            {
                "name": "South Laurel",
                "countryCode": "US"
            },
            {
                "name": "Spencerville",
                "countryCode": "US"
            },
            {
                "name": "Spring Ridge",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Stevensville",
                "countryCode": "US"
            },
            {
                "name": "Suitland",
                "countryCode": "US"
            },
            {
                "name": "Suitland-Silver Hill",
                "countryCode": "US"
            },
            {
                "name": "Summerfield",
                "countryCode": "US"
            },
            {
                "name": "Sykesville",
                "countryCode": "US"
            },
            {
                "name": "Takoma Park",
                "countryCode": "US"
            },
            {
                "name": "Talbot County",
                "countryCode": "US"
            },
            {
                "name": "Taneytown",
                "countryCode": "US"
            },
            {
                "name": "Temple Hills",
                "countryCode": "US"
            },
            {
                "name": "Thurmont",
                "countryCode": "US"
            },
            {
                "name": "Timonium",
                "countryCode": "US"
            },
            {
                "name": "Towson",
                "countryCode": "US"
            },
            {
                "name": "Trappe",
                "countryCode": "US"
            },
            {
                "name": "Travilah",
                "countryCode": "US"
            },
            {
                "name": "University Park",
                "countryCode": "US"
            },
            {
                "name": "Upper Marlboro",
                "countryCode": "US"
            },
            {
                "name": "Urbana",
                "countryCode": "US"
            },
            {
                "name": "Waldorf",
                "countryCode": "US"
            },
            {
                "name": "Walker Mill",
                "countryCode": "US"
            },
            {
                "name": "Walkersville",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "West Elkridge",
                "countryCode": "US"
            },
            {
                "name": "West Laurel",
                "countryCode": "US"
            },
            {
                "name": "West Ocean City",
                "countryCode": "US"
            },
            {
                "name": "Westernport",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Westphalia",
                "countryCode": "US"
            },
            {
                "name": "Wheaton",
                "countryCode": "US"
            },
            {
                "name": "White Marsh",
                "countryCode": "US"
            },
            {
                "name": "White Oak",
                "countryCode": "US"
            },
            {
                "name": "Wicomico County",
                "countryCode": "US"
            },
            {
                "name": "Williamsport",
                "countryCode": "US"
            },
            {
                "name": "Wilson-Conococheague",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn",
                "countryCode": "US"
            },
            {
                "name": "Woodmore",
                "countryCode": "US"
            },
            {
                "name": "Woodsboro",
                "countryCode": "US"
            },
            {
                "name": "Worcester County",
                "countryCode": "US"
            },
            {
                "name": "Acton",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alfred",
                "countryCode": "US"
            },
            {
                "name": "Androscoggin County",
                "countryCode": "US"
            },
            {
                "name": "Appleton",
                "countryCode": "US"
            },
            {
                "name": "Aroostook County",
                "countryCode": "US"
            },
            {
                "name": "Arundel",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Bangor",
                "countryCode": "US"
            },
            {
                "name": "Bar Harbor",
                "countryCode": "US"
            },
            {
                "name": "Bath",
                "countryCode": "US"
            },
            {
                "name": "Belfast",
                "countryCode": "US"
            },
            {
                "name": "Belgrade",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Berwick",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Biddeford",
                "countryCode": "US"
            },
            {
                "name": "Boothbay",
                "countryCode": "US"
            },
            {
                "name": "Boothbay Harbor",
                "countryCode": "US"
            },
            {
                "name": "Bradford",
                "countryCode": "US"
            },
            {
                "name": "Bradley",
                "countryCode": "US"
            },
            {
                "name": "Brewer",
                "countryCode": "US"
            },
            {
                "name": "Bridgton",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Brooks",
                "countryCode": "US"
            },
            {
                "name": "Brownfield",
                "countryCode": "US"
            },
            {
                "name": "Brownville",
                "countryCode": "US"
            },
            {
                "name": "Brunswick",
                "countryCode": "US"
            },
            {
                "name": "Buckfield",
                "countryCode": "US"
            },
            {
                "name": "Bucksport",
                "countryCode": "US"
            },
            {
                "name": "Burnham",
                "countryCode": "US"
            },
            {
                "name": "Buxton",
                "countryCode": "US"
            },
            {
                "name": "Calais",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Canaan",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Cape Neddick",
                "countryCode": "US"
            },
            {
                "name": "Caribou",
                "countryCode": "US"
            },
            {
                "name": "Carmel",
                "countryCode": "US"
            },
            {
                "name": "Castine",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Cherryfield",
                "countryCode": "US"
            },
            {
                "name": "Chesterville",
                "countryCode": "US"
            },
            {
                "name": "China",
                "countryCode": "US"
            },
            {
                "name": "Chisholm",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Corinna",
                "countryCode": "US"
            },
            {
                "name": "Cornish",
                "countryCode": "US"
            },
            {
                "name": "Cornville",
                "countryCode": "US"
            },
            {
                "name": "Cumberland Center",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Cushing",
                "countryCode": "US"
            },
            {
                "name": "Damariscotta",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Dedham",
                "countryCode": "US"
            },
            {
                "name": "Deer Isle",
                "countryCode": "US"
            },
            {
                "name": "Denmark",
                "countryCode": "US"
            },
            {
                "name": "Dexter",
                "countryCode": "US"
            },
            {
                "name": "Dixfield",
                "countryCode": "US"
            },
            {
                "name": "Dixmont",
                "countryCode": "US"
            },
            {
                "name": "Dover-Foxcroft",
                "countryCode": "US"
            },
            {
                "name": "East Machias",
                "countryCode": "US"
            },
            {
                "name": "East Millinocket",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Eastport",
                "countryCode": "US"
            },
            {
                "name": "Eddington",
                "countryCode": "US"
            },
            {
                "name": "Edgecomb",
                "countryCode": "US"
            },
            {
                "name": "Eliot",
                "countryCode": "US"
            },
            {
                "name": "Ellsworth",
                "countryCode": "US"
            },
            {
                "name": "Enfield",
                "countryCode": "US"
            },
            {
                "name": "Etna",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Falmouth",
                "countryCode": "US"
            },
            {
                "name": "Falmouth Foreside",
                "countryCode": "US"
            },
            {
                "name": "Farmingdale",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Fort Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fort Kent",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Frenchville",
                "countryCode": "US"
            },
            {
                "name": "Friendship",
                "countryCode": "US"
            },
            {
                "name": "Fryeburg",
                "countryCode": "US"
            },
            {
                "name": "Gardiner",
                "countryCode": "US"
            },
            {
                "name": "Garland",
                "countryCode": "US"
            },
            {
                "name": "Gorham",
                "countryCode": "US"
            },
            {
                "name": "Gouldsboro",
                "countryCode": "US"
            },
            {
                "name": "Greenbush",
                "countryCode": "US"
            },
            {
                "name": "Greene",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Hallowell",
                "countryCode": "US"
            },
            {
                "name": "Hampden",
                "countryCode": "US"
            },
            {
                "name": "Hancock",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Harpswell Center",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Hermon",
                "countryCode": "US"
            },
            {
                "name": "Hiram",
                "countryCode": "US"
            },
            {
                "name": "Hodgdon",
                "countryCode": "US"
            },
            {
                "name": "Holden",
                "countryCode": "US"
            },
            {
                "name": "Hollis Center",
                "countryCode": "US"
            },
            {
                "name": "Hope",
                "countryCode": "US"
            },
            {
                "name": "Houlton",
                "countryCode": "US"
            },
            {
                "name": "Howland",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Jay",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jonesport",
                "countryCode": "US"
            },
            {
                "name": "Kenduskeag",
                "countryCode": "US"
            },
            {
                "name": "Kennebec County",
                "countryCode": "US"
            },
            {
                "name": "Kennebunk",
                "countryCode": "US"
            },
            {
                "name": "Kennebunkport",
                "countryCode": "US"
            },
            {
                "name": "Kingfield",
                "countryCode": "US"
            },
            {
                "name": "Kittery",
                "countryCode": "US"
            },
            {
                "name": "Kittery Point",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "Lake Arrowhead",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Leeds",
                "countryCode": "US"
            },
            {
                "name": "Levant",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Limerick",
                "countryCode": "US"
            },
            {
                "name": "Limestone",
                "countryCode": "US"
            },
            {
                "name": "Limington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lincolnville",
                "countryCode": "US"
            },
            {
                "name": "Lisbon",
                "countryCode": "US"
            },
            {
                "name": "Lisbon Falls",
                "countryCode": "US"
            },
            {
                "name": "Livermore",
                "countryCode": "US"
            },
            {
                "name": "Livermore Falls",
                "countryCode": "US"
            },
            {
                "name": "Lovell",
                "countryCode": "US"
            },
            {
                "name": "Machias",
                "countryCode": "US"
            },
            {
                "name": "Machiasport",
                "countryCode": "US"
            },
            {
                "name": "Madawaska",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Mechanic Falls",
                "countryCode": "US"
            },
            {
                "name": "Medway",
                "countryCode": "US"
            },
            {
                "name": "Mexico",
                "countryCode": "US"
            },
            {
                "name": "Milbridge",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millinocket",
                "countryCode": "US"
            },
            {
                "name": "Milo",
                "countryCode": "US"
            },
            {
                "name": "Minot",
                "countryCode": "US"
            },
            {
                "name": "Monmouth",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "New Gloucester",
                "countryCode": "US"
            },
            {
                "name": "New Sharon",
                "countryCode": "US"
            },
            {
                "name": "Newfield",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Nobleboro",
                "countryCode": "US"
            },
            {
                "name": "Norridgewock",
                "countryCode": "US"
            },
            {
                "name": "North Bath",
                "countryCode": "US"
            },
            {
                "name": "North Berwick",
                "countryCode": "US"
            },
            {
                "name": "North Windham",
                "countryCode": "US"
            },
            {
                "name": "Northport",
                "countryCode": "US"
            },
            {
                "name": "Norway",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Ogunquit",
                "countryCode": "US"
            },
            {
                "name": "Old Orchard Beach",
                "countryCode": "US"
            },
            {
                "name": "Old Town",
                "countryCode": "US"
            },
            {
                "name": "Orland",
                "countryCode": "US"
            },
            {
                "name": "Orono",
                "countryCode": "US"
            },
            {
                "name": "Orrington",
                "countryCode": "US"
            },
            {
                "name": "Owls Head",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Oxford County",
                "countryCode": "US"
            },
            {
                "name": "Palermo",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Parsonsfield",
                "countryCode": "US"
            },
            {
                "name": "Patten",
                "countryCode": "US"
            },
            {
                "name": "Penobscot",
                "countryCode": "US"
            },
            {
                "name": "Penobscot County",
                "countryCode": "US"
            },
            {
                "name": "Peru",
                "countryCode": "US"
            },
            {
                "name": "Phillips",
                "countryCode": "US"
            },
            {
                "name": "Phippsburg",
                "countryCode": "US"
            },
            {
                "name": "Piscataquis County",
                "countryCode": "US"
            },
            {
                "name": "Pittsfield",
                "countryCode": "US"
            },
            {
                "name": "Pittston",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Poland",
                "countryCode": "US"
            },
            {
                "name": "Porter",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Presque Isle",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Raymond",
                "countryCode": "US"
            },
            {
                "name": "Readfield",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Rockland",
                "countryCode": "US"
            },
            {
                "name": "Rockport",
                "countryCode": "US"
            },
            {
                "name": "Rome",
                "countryCode": "US"
            },
            {
                "name": "Rumford",
                "countryCode": "US"
            },
            {
                "name": "Sabattus",
                "countryCode": "US"
            },
            {
                "name": "Saco",
                "countryCode": "US"
            },
            {
                "name": "Sagadahoc County",
                "countryCode": "US"
            },
            {
                "name": "Saint Albans",
                "countryCode": "US"
            },
            {
                "name": "Saint George",
                "countryCode": "US"
            },
            {
                "name": "Sanford",
                "countryCode": "US"
            },
            {
                "name": "Sangerville",
                "countryCode": "US"
            },
            {
                "name": "Scarborough",
                "countryCode": "US"
            },
            {
                "name": "Searsmont",
                "countryCode": "US"
            },
            {
                "name": "Sedgwick",
                "countryCode": "US"
            },
            {
                "name": "Shapleigh",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Skowhegan",
                "countryCode": "US"
            },
            {
                "name": "Somerset County",
                "countryCode": "US"
            },
            {
                "name": "South Berwick",
                "countryCode": "US"
            },
            {
                "name": "South Eliot",
                "countryCode": "US"
            },
            {
                "name": "South Paris",
                "countryCode": "US"
            },
            {
                "name": "South Portland",
                "countryCode": "US"
            },
            {
                "name": "South Portland Gardens",
                "countryCode": "US"
            },
            {
                "name": "South Sanford",
                "countryCode": "US"
            },
            {
                "name": "South Thomaston",
                "countryCode": "US"
            },
            {
                "name": "South Windham",
                "countryCode": "US"
            },
            {
                "name": "Springvale",
                "countryCode": "US"
            },
            {
                "name": "Steep Falls",
                "countryCode": "US"
            },
            {
                "name": "Stetson",
                "countryCode": "US"
            },
            {
                "name": "Steuben",
                "countryCode": "US"
            },
            {
                "name": "Stockton Springs",
                "countryCode": "US"
            },
            {
                "name": "Stonington",
                "countryCode": "US"
            },
            {
                "name": "Strong",
                "countryCode": "US"
            },
            {
                "name": "Sullivan",
                "countryCode": "US"
            },
            {
                "name": "Surry",
                "countryCode": "US"
            },
            {
                "name": "Swanville",
                "countryCode": "US"
            },
            {
                "name": "Thomaston",
                "countryCode": "US"
            },
            {
                "name": "Topsham",
                "countryCode": "US"
            },
            {
                "name": "Tremont",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Turner",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Van Buren",
                "countryCode": "US"
            },
            {
                "name": "Vassalboro",
                "countryCode": "US"
            },
            {
                "name": "Veazie",
                "countryCode": "US"
            },
            {
                "name": "Vinalhaven",
                "countryCode": "US"
            },
            {
                "name": "Waldo County",
                "countryCode": "US"
            },
            {
                "name": "Waldoboro",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterboro",
                "countryCode": "US"
            },
            {
                "name": "Waterville",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wells Beach Station",
                "countryCode": "US"
            },
            {
                "name": "West Kennebunk",
                "countryCode": "US"
            },
            {
                "name": "West Paris",
                "countryCode": "US"
            },
            {
                "name": "West Scarborough",
                "countryCode": "US"
            },
            {
                "name": "Westbrook",
                "countryCode": "US"
            },
            {
                "name": "Whitefield",
                "countryCode": "US"
            },
            {
                "name": "Wilton",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Winslow",
                "countryCode": "US"
            },
            {
                "name": "Winterport",
                "countryCode": "US"
            },
            {
                "name": "Winthrop",
                "countryCode": "US"
            },
            {
                "name": "Wiscasset",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Woolwich",
                "countryCode": "US"
            },
            {
                "name": "Yarmouth",
                "countryCode": "US"
            },
            {
                "name": "York Beach",
                "countryCode": "US"
            },
            {
                "name": "York County",
                "countryCode": "US"
            },
            {
                "name": "York Harbor",
                "countryCode": "US"
            },
            {
                "name": "Adrian",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alcona County",
                "countryCode": "US"
            },
            {
                "name": "Alger County",
                "countryCode": "US"
            },
            {
                "name": "Algonac",
                "countryCode": "US"
            },
            {
                "name": "Allegan",
                "countryCode": "US"
            },
            {
                "name": "Allegan County",
                "countryCode": "US"
            },
            {
                "name": "Allen Park",
                "countryCode": "US"
            },
            {
                "name": "Allendale",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Almont",
                "countryCode": "US"
            },
            {
                "name": "Alpena",
                "countryCode": "US"
            },
            {
                "name": "Alpena County",
                "countryCode": "US"
            },
            {
                "name": "Ann Arbor",
                "countryCode": "US"
            },
            {
                "name": "Antrim County",
                "countryCode": "US"
            },
            {
                "name": "Arenac County",
                "countryCode": "US"
            },
            {
                "name": "Argentine",
                "countryCode": "US"
            },
            {
                "name": "Armada",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atlanta",
                "countryCode": "US"
            },
            {
                "name": "Au Sable",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Auburn Hills",
                "countryCode": "US"
            },
            {
                "name": "Bad Axe",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Bangor",
                "countryCode": "US"
            },
            {
                "name": "Baraga",
                "countryCode": "US"
            },
            {
                "name": "Baraga County",
                "countryCode": "US"
            },
            {
                "name": "Barnes Lake-Millers Lake",
                "countryCode": "US"
            },
            {
                "name": "Barry County",
                "countryCode": "US"
            },
            {
                "name": "Bath",
                "countryCode": "US"
            },
            {
                "name": "Battle Creek",
                "countryCode": "US"
            },
            {
                "name": "Bay City",
                "countryCode": "US"
            },
            {
                "name": "Bay County",
                "countryCode": "US"
            },
            {
                "name": "Bay Harbor",
                "countryCode": "US"
            },
            {
                "name": "Beaverton",
                "countryCode": "US"
            },
            {
                "name": "Beecher",
                "countryCode": "US"
            },
            {
                "name": "Beechwood",
                "countryCode": "US"
            },
            {
                "name": "Belding",
                "countryCode": "US"
            },
            {
                "name": "Bellaire",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Benton Harbor",
                "countryCode": "US"
            },
            {
                "name": "Benton Heights",
                "countryCode": "US"
            },
            {
                "name": "Benzie County",
                "countryCode": "US"
            },
            {
                "name": "Berkley",
                "countryCode": "US"
            },
            {
                "name": "Berrien County",
                "countryCode": "US"
            },
            {
                "name": "Berrien Springs",
                "countryCode": "US"
            },
            {
                "name": "Bessemer",
                "countryCode": "US"
            },
            {
                "name": "Beulah",
                "countryCode": "US"
            },
            {
                "name": "Beverly Hills",
                "countryCode": "US"
            },
            {
                "name": "Big Rapids",
                "countryCode": "US"
            },
            {
                "name": "Bingham Farms",
                "countryCode": "US"
            },
            {
                "name": "Birch Run",
                "countryCode": "US"
            },
            {
                "name": "Birmingham",
                "countryCode": "US"
            },
            {
                "name": "Blissfield",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield Hills",
                "countryCode": "US"
            },
            {
                "name": "Boyne City",
                "countryCode": "US"
            },
            {
                "name": "Branch County",
                "countryCode": "US"
            },
            {
                "name": "Breckenridge",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Bridgman",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Bronson",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Brown City",
                "countryCode": "US"
            },
            {
                "name": "Brownlee Park",
                "countryCode": "US"
            },
            {
                "name": "Buchanan",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Burt",
                "countryCode": "US"
            },
            {
                "name": "Burton",
                "countryCode": "US"
            },
            {
                "name": "Byron Center",
                "countryCode": "US"
            },
            {
                "name": "Cadillac",
                "countryCode": "US"
            },
            {
                "name": "Caledonia",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Canadian Lakes",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Capac",
                "countryCode": "US"
            },
            {
                "name": "Carleton",
                "countryCode": "US"
            },
            {
                "name": "Caro",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carson City",
                "countryCode": "US"
            },
            {
                "name": "Cass City",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Cassopolis",
                "countryCode": "US"
            },
            {
                "name": "Cedar Springs",
                "countryCode": "US"
            },
            {
                "name": "Center Line",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Charlevoix",
                "countryCode": "US"
            },
            {
                "name": "Charlevoix County",
                "countryCode": "US"
            },
            {
                "name": "Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Cheboygan",
                "countryCode": "US"
            },
            {
                "name": "Cheboygan County",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Chesaning",
                "countryCode": "US"
            },
            {
                "name": "Chippewa County",
                "countryCode": "US"
            },
            {
                "name": "Clare",
                "countryCode": "US"
            },
            {
                "name": "Clare County",
                "countryCode": "US"
            },
            {
                "name": "Clarkston",
                "countryCode": "US"
            },
            {
                "name": "Clawson",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Clinton Township",
                "countryCode": "US"
            },
            {
                "name": "Clio",
                "countryCode": "US"
            },
            {
                "name": "Coldwater",
                "countryCode": "US"
            },
            {
                "name": "Coleman",
                "countryCode": "US"
            },
            {
                "name": "Coloma",
                "countryCode": "US"
            },
            {
                "name": "Colon",
                "countryCode": "US"
            },
            {
                "name": "Comstock Northwest",
                "countryCode": "US"
            },
            {
                "name": "Comstock Park",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Constantine",
                "countryCode": "US"
            },
            {
                "name": "Coopersville",
                "countryCode": "US"
            },
            {
                "name": "Corunna",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Croswell",
                "countryCode": "US"
            },
            {
                "name": "Crystal Falls",
                "countryCode": "US"
            },
            {
                "name": "Cutlerville",
                "countryCode": "US"
            },
            {
                "name": "Davison",
                "countryCode": "US"
            },
            {
                "name": "DeWitt",
                "countryCode": "US"
            },
            {
                "name": "Dearborn",
                "countryCode": "US"
            },
            {
                "name": "Dearborn Heights",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Delta County",
                "countryCode": "US"
            },
            {
                "name": "Detroit",
                "countryCode": "US"
            },
            {
                "name": "Detroit Beach",
                "countryCode": "US"
            },
            {
                "name": "Dexter",
                "countryCode": "US"
            },
            {
                "name": "Dickinson County",
                "countryCode": "US"
            },
            {
                "name": "Dimondale",
                "countryCode": "US"
            },
            {
                "name": "Dollar Bay",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Dowagiac",
                "countryCode": "US"
            },
            {
                "name": "Dundee",
                "countryCode": "US"
            },
            {
                "name": "Durand",
                "countryCode": "US"
            },
            {
                "name": "Eagle River",
                "countryCode": "US"
            },
            {
                "name": "East Grand Rapids",
                "countryCode": "US"
            },
            {
                "name": "East Jordan",
                "countryCode": "US"
            },
            {
                "name": "East Lansing",
                "countryCode": "US"
            },
            {
                "name": "East Tawas",
                "countryCode": "US"
            },
            {
                "name": "Eastpointe",
                "countryCode": "US"
            },
            {
                "name": "Eastwood",
                "countryCode": "US"
            },
            {
                "name": "Eaton County",
                "countryCode": "US"
            },
            {
                "name": "Eaton Rapids",
                "countryCode": "US"
            },
            {
                "name": "Ecorse",
                "countryCode": "US"
            },
            {
                "name": "Edgemont Park",
                "countryCode": "US"
            },
            {
                "name": "Edmore",
                "countryCode": "US"
            },
            {
                "name": "Edwardsburg",
                "countryCode": "US"
            },
            {
                "name": "Elk Rapids",
                "countryCode": "US"
            },
            {
                "name": "Emmet County",
                "countryCode": "US"
            },
            {
                "name": "Escanaba",
                "countryCode": "US"
            },
            {
                "name": "Essexville",
                "countryCode": "US"
            },
            {
                "name": "Evart",
                "countryCode": "US"
            },
            {
                "name": "Fair Plain",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Farmington Hills",
                "countryCode": "US"
            },
            {
                "name": "Fennville",
                "countryCode": "US"
            },
            {
                "name": "Fenton",
                "countryCode": "US"
            },
            {
                "name": "Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Ferrysburg",
                "countryCode": "US"
            },
            {
                "name": "Flat Rock",
                "countryCode": "US"
            },
            {
                "name": "Flint",
                "countryCode": "US"
            },
            {
                "name": "Flushing",
                "countryCode": "US"
            },
            {
                "name": "Forest Hills",
                "countryCode": "US"
            },
            {
                "name": "Fowler",
                "countryCode": "US"
            },
            {
                "name": "Fowlerville",
                "countryCode": "US"
            },
            {
                "name": "Frankenmuth",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Fraser",
                "countryCode": "US"
            },
            {
                "name": "Freeland",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "Fruitport",
                "countryCode": "US"
            },
            {
                "name": "Galesburg",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Gaylord",
                "countryCode": "US"
            },
            {
                "name": "Genesee County",
                "countryCode": "US"
            },
            {
                "name": "Gibraltar",
                "countryCode": "US"
            },
            {
                "name": "Gladstone",
                "countryCode": "US"
            },
            {
                "name": "Gladwin",
                "countryCode": "US"
            },
            {
                "name": "Gladwin County",
                "countryCode": "US"
            },
            {
                "name": "Gogebic County",
                "countryCode": "US"
            },
            {
                "name": "Goodrich",
                "countryCode": "US"
            },
            {
                "name": "Grand Blanc",
                "countryCode": "US"
            },
            {
                "name": "Grand Haven",
                "countryCode": "US"
            },
            {
                "name": "Grand Ledge",
                "countryCode": "US"
            },
            {
                "name": "Grand Rapids",
                "countryCode": "US"
            },
            {
                "name": "Grand Traverse County",
                "countryCode": "US"
            },
            {
                "name": "Grandville",
                "countryCode": "US"
            },
            {
                "name": "Grass Lake",
                "countryCode": "US"
            },
            {
                "name": "Gratiot County",
                "countryCode": "US"
            },
            {
                "name": "Grayling",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greilickville",
                "countryCode": "US"
            },
            {
                "name": "Grosse Ile",
                "countryCode": "US"
            },
            {
                "name": "Grosse Pointe",
                "countryCode": "US"
            },
            {
                "name": "Grosse Pointe Farms",
                "countryCode": "US"
            },
            {
                "name": "Grosse Pointe Park",
                "countryCode": "US"
            },
            {
                "name": "Grosse Pointe Shores",
                "countryCode": "US"
            },
            {
                "name": "Grosse Pointe Woods",
                "countryCode": "US"
            },
            {
                "name": "Gwinn",
                "countryCode": "US"
            },
            {
                "name": "Hamtramck",
                "countryCode": "US"
            },
            {
                "name": "Hancock",
                "countryCode": "US"
            },
            {
                "name": "Harbor Beach",
                "countryCode": "US"
            },
            {
                "name": "Harbor Springs",
                "countryCode": "US"
            },
            {
                "name": "Harper Woods",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Harrisville",
                "countryCode": "US"
            },
            {
                "name": "Hart",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Harvey",
                "countryCode": "US"
            },
            {
                "name": "Haslett",
                "countryCode": "US"
            },
            {
                "name": "Hastings",
                "countryCode": "US"
            },
            {
                "name": "Hazel Park",
                "countryCode": "US"
            },
            {
                "name": "Hemlock",
                "countryCode": "US"
            },
            {
                "name": "Highland Park",
                "countryCode": "US"
            },
            {
                "name": "Hillsdale",
                "countryCode": "US"
            },
            {
                "name": "Hillsdale County",
                "countryCode": "US"
            },
            {
                "name": "Holland",
                "countryCode": "US"
            },
            {
                "name": "Holly",
                "countryCode": "US"
            },
            {
                "name": "Holt",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Houghton",
                "countryCode": "US"
            },
            {
                "name": "Houghton County",
                "countryCode": "US"
            },
            {
                "name": "Houghton Lake",
                "countryCode": "US"
            },
            {
                "name": "Howard City",
                "countryCode": "US"
            },
            {
                "name": "Howell",
                "countryCode": "US"
            },
            {
                "name": "Hubbard Lake",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hudsonville",
                "countryCode": "US"
            },
            {
                "name": "Huntington Woods",
                "countryCode": "US"
            },
            {
                "name": "Huron County",
                "countryCode": "US"
            },
            {
                "name": "Imlay City",
                "countryCode": "US"
            },
            {
                "name": "Indian River",
                "countryCode": "US"
            },
            {
                "name": "Ingham County",
                "countryCode": "US"
            },
            {
                "name": "Inkster",
                "countryCode": "US"
            },
            {
                "name": "Ionia",
                "countryCode": "US"
            },
            {
                "name": "Ionia County",
                "countryCode": "US"
            },
            {
                "name": "Iosco County",
                "countryCode": "US"
            },
            {
                "name": "Iron County",
                "countryCode": "US"
            },
            {
                "name": "Iron Mountain",
                "countryCode": "US"
            },
            {
                "name": "Iron River",
                "countryCode": "US"
            },
            {
                "name": "Ironwood",
                "countryCode": "US"
            },
            {
                "name": "Isabella County",
                "countryCode": "US"
            },
            {
                "name": "Ishpeming",
                "countryCode": "US"
            },
            {
                "name": "Ithaca",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jenison",
                "countryCode": "US"
            },
            {
                "name": "Jonesville",
                "countryCode": "US"
            },
            {
                "name": "K. I. Sawyer Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Kalamazoo",
                "countryCode": "US"
            },
            {
                "name": "Kalamazoo County",
                "countryCode": "US"
            },
            {
                "name": "Kalkaska",
                "countryCode": "US"
            },
            {
                "name": "Kalkaska County",
                "countryCode": "US"
            },
            {
                "name": "Keego Harbor",
                "countryCode": "US"
            },
            {
                "name": "Kent City",
                "countryCode": "US"
            },
            {
                "name": "Kent County",
                "countryCode": "US"
            },
            {
                "name": "Kentwood",
                "countryCode": "US"
            },
            {
                "name": "Keweenaw County",
                "countryCode": "US"
            },
            {
                "name": "Kilmanagh",
                "countryCode": "US"
            },
            {
                "name": "Kingsford",
                "countryCode": "US"
            },
            {
                "name": "Kingsley",
                "countryCode": "US"
            },
            {
                "name": "L'Anse",
                "countryCode": "US"
            },
            {
                "name": "Laingsburg",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Fenton",
                "countryCode": "US"
            },
            {
                "name": "Lake Isabella",
                "countryCode": "US"
            },
            {
                "name": "Lake Michigan Beach",
                "countryCode": "US"
            },
            {
                "name": "Lake Odessa",
                "countryCode": "US"
            },
            {
                "name": "Lake Orion",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lakewood Club",
                "countryCode": "US"
            },
            {
                "name": "Lambertville",
                "countryCode": "US"
            },
            {
                "name": "Lansing",
                "countryCode": "US"
            },
            {
                "name": "Lapeer",
                "countryCode": "US"
            },
            {
                "name": "Lapeer County",
                "countryCode": "US"
            },
            {
                "name": "Lathrup Village",
                "countryCode": "US"
            },
            {
                "name": "Laurium",
                "countryCode": "US"
            },
            {
                "name": "Lawton",
                "countryCode": "US"
            },
            {
                "name": "Leelanau County",
                "countryCode": "US"
            },
            {
                "name": "Leland",
                "countryCode": "US"
            },
            {
                "name": "Lenawee County",
                "countryCode": "US"
            },
            {
                "name": "Leslie",
                "countryCode": "US"
            },
            {
                "name": "Level Park-Oak Park",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Litchfield",
                "countryCode": "US"
            },
            {
                "name": "Livingston County",
                "countryCode": "US"
            },
            {
                "name": "Livonia",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Luce County",
                "countryCode": "US"
            },
            {
                "name": "Ludington",
                "countryCode": "US"
            },
            {
                "name": "Luna Pier",
                "countryCode": "US"
            },
            {
                "name": "Mackinac County",
                "countryCode": "US"
            },
            {
                "name": "Macomb County",
                "countryCode": "US"
            },
            {
                "name": "Madison Heights",
                "countryCode": "US"
            },
            {
                "name": "Mancelona",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manistee",
                "countryCode": "US"
            },
            {
                "name": "Manistee County",
                "countryCode": "US"
            },
            {
                "name": "Manistique",
                "countryCode": "US"
            },
            {
                "name": "Manitou Beach-Devils Lake",
                "countryCode": "US"
            },
            {
                "name": "Manton",
                "countryCode": "US"
            },
            {
                "name": "Marcellus",
                "countryCode": "US"
            },
            {
                "name": "Marine City",
                "countryCode": "US"
            },
            {
                "name": "Marlette",
                "countryCode": "US"
            },
            {
                "name": "Marquette",
                "countryCode": "US"
            },
            {
                "name": "Marquette County",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "Mason",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "Mattawan",
                "countryCode": "US"
            },
            {
                "name": "Mecosta County",
                "countryCode": "US"
            },
            {
                "name": "Melvindale",
                "countryCode": "US"
            },
            {
                "name": "Memphis",
                "countryCode": "US"
            },
            {
                "name": "Menominee",
                "countryCode": "US"
            },
            {
                "name": "Menominee County",
                "countryCode": "US"
            },
            {
                "name": "Michigan Center",
                "countryCode": "US"
            },
            {
                "name": "Middleville",
                "countryCode": "US"
            },
            {
                "name": "Midland",
                "countryCode": "US"
            },
            {
                "name": "Midland County",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millington",
                "countryCode": "US"
            },
            {
                "name": "Mio",
                "countryCode": "US"
            },
            {
                "name": "Missaukee County",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montague",
                "countryCode": "US"
            },
            {
                "name": "Montcalm County",
                "countryCode": "US"
            },
            {
                "name": "Montmorency County",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Morenci",
                "countryCode": "US"
            },
            {
                "name": "Mount Clemens",
                "countryCode": "US"
            },
            {
                "name": "Mount Morris",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Munising",
                "countryCode": "US"
            },
            {
                "name": "Muskegon",
                "countryCode": "US"
            },
            {
                "name": "Muskegon County",
                "countryCode": "US"
            },
            {
                "name": "Muskegon Heights",
                "countryCode": "US"
            },
            {
                "name": "Napoleon",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Negaunee",
                "countryCode": "US"
            },
            {
                "name": "New Baltimore",
                "countryCode": "US"
            },
            {
                "name": "New Buffalo",
                "countryCode": "US"
            },
            {
                "name": "New Haven",
                "countryCode": "US"
            },
            {
                "name": "Newaygo",
                "countryCode": "US"
            },
            {
                "name": "Newaygo County",
                "countryCode": "US"
            },
            {
                "name": "Newberry",
                "countryCode": "US"
            },
            {
                "name": "Niles",
                "countryCode": "US"
            },
            {
                "name": "North Branch",
                "countryCode": "US"
            },
            {
                "name": "North Muskegon",
                "countryCode": "US"
            },
            {
                "name": "Northview",
                "countryCode": "US"
            },
            {
                "name": "Northville",
                "countryCode": "US"
            },
            {
                "name": "Norton Shores",
                "countryCode": "US"
            },
            {
                "name": "Norway",
                "countryCode": "US"
            },
            {
                "name": "Novi",
                "countryCode": "US"
            },
            {
                "name": "Oak Park",
                "countryCode": "US"
            },
            {
                "name": "Oakland County",
                "countryCode": "US"
            },
            {
                "name": "Oceana County",
                "countryCode": "US"
            },
            {
                "name": "Ogemaw County",
                "countryCode": "US"
            },
            {
                "name": "Okemos",
                "countryCode": "US"
            },
            {
                "name": "Olivet",
                "countryCode": "US"
            },
            {
                "name": "Ontonagon",
                "countryCode": "US"
            },
            {
                "name": "Ontonagon County",
                "countryCode": "US"
            },
            {
                "name": "Orchard Lake",
                "countryCode": "US"
            },
            {
                "name": "Ortonville",
                "countryCode": "US"
            },
            {
                "name": "Osceola County",
                "countryCode": "US"
            },
            {
                "name": "Oscoda County",
                "countryCode": "US"
            },
            {
                "name": "Otsego",
                "countryCode": "US"
            },
            {
                "name": "Otsego County",
                "countryCode": "US"
            },
            {
                "name": "Ottawa County",
                "countryCode": "US"
            },
            {
                "name": "Ovid",
                "countryCode": "US"
            },
            {
                "name": "Owosso",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Parchment",
                "countryCode": "US"
            },
            {
                "name": "Paw Paw",
                "countryCode": "US"
            },
            {
                "name": "Paw Paw Lake",
                "countryCode": "US"
            },
            {
                "name": "Pearl Beach",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Petoskey",
                "countryCode": "US"
            },
            {
                "name": "Pigeon",
                "countryCode": "US"
            },
            {
                "name": "Pinckney",
                "countryCode": "US"
            },
            {
                "name": "Pinconning",
                "countryCode": "US"
            },
            {
                "name": "Plainwell",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Ridge",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Pontiac",
                "countryCode": "US"
            },
            {
                "name": "Port Huron",
                "countryCode": "US"
            },
            {
                "name": "Portage",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Potterville",
                "countryCode": "US"
            },
            {
                "name": "Presque Isle County",
                "countryCode": "US"
            },
            {
                "name": "Prudenville",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Quinnesec",
                "countryCode": "US"
            },
            {
                "name": "Rapid City",
                "countryCode": "US"
            },
            {
                "name": "Ravenna",
                "countryCode": "US"
            },
            {
                "name": "Reading",
                "countryCode": "US"
            },
            {
                "name": "Redford",
                "countryCode": "US"
            },
            {
                "name": "Reed City",
                "countryCode": "US"
            },
            {
                "name": "Reese",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "River Rouge",
                "countryCode": "US"
            },
            {
                "name": "Riverview",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rochester Hills",
                "countryCode": "US"
            },
            {
                "name": "Rockford",
                "countryCode": "US"
            },
            {
                "name": "Rockwood",
                "countryCode": "US"
            },
            {
                "name": "Rogers City",
                "countryCode": "US"
            },
            {
                "name": "Romeo",
                "countryCode": "US"
            },
            {
                "name": "Romulus",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt Park",
                "countryCode": "US"
            },
            {
                "name": "Roscommon",
                "countryCode": "US"
            },
            {
                "name": "Roscommon County",
                "countryCode": "US"
            },
            {
                "name": "Roseville",
                "countryCode": "US"
            },
            {
                "name": "Royal Oak",
                "countryCode": "US"
            },
            {
                "name": "Saginaw",
                "countryCode": "US"
            },
            {
                "name": "Saginaw County",
                "countryCode": "US"
            },
            {
                "name": "Saginaw Township North",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair County",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair Shores",
                "countryCode": "US"
            },
            {
                "name": "Saint Helen",
                "countryCode": "US"
            },
            {
                "name": "Saint Ignace",
                "countryCode": "US"
            },
            {
                "name": "Saint Johns",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph County",
                "countryCode": "US"
            },
            {
                "name": "Saint Louis",
                "countryCode": "US"
            },
            {
                "name": "Saline",
                "countryCode": "US"
            },
            {
                "name": "Sand Lake",
                "countryCode": "US"
            },
            {
                "name": "Sandusky",
                "countryCode": "US"
            },
            {
                "name": "Sanilac County",
                "countryCode": "US"
            },
            {
                "name": "Saranac",
                "countryCode": "US"
            },
            {
                "name": "Sault Ste. Marie",
                "countryCode": "US"
            },
            {
                "name": "Schoolcraft",
                "countryCode": "US"
            },
            {
                "name": "Schoolcraft County",
                "countryCode": "US"
            },
            {
                "name": "Scottville",
                "countryCode": "US"
            },
            {
                "name": "Sebewaing",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Shepherd",
                "countryCode": "US"
            },
            {
                "name": "Shiawassee County",
                "countryCode": "US"
            },
            {
                "name": "Shields",
                "countryCode": "US"
            },
            {
                "name": "Shorewood-Tower Hills-Harbert",
                "countryCode": "US"
            },
            {
                "name": "Skidway Lake",
                "countryCode": "US"
            },
            {
                "name": "South Gull Lake",
                "countryCode": "US"
            },
            {
                "name": "South Haven",
                "countryCode": "US"
            },
            {
                "name": "South Lyon",
                "countryCode": "US"
            },
            {
                "name": "South Monroe",
                "countryCode": "US"
            },
            {
                "name": "South Rockwood",
                "countryCode": "US"
            },
            {
                "name": "Southfield",
                "countryCode": "US"
            },
            {
                "name": "Southgate",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spring Arbor",
                "countryCode": "US"
            },
            {
                "name": "Spring Lake",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stambaugh, Iron River",
                "countryCode": "US"
            },
            {
                "name": "Standish",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Sterling Heights",
                "countryCode": "US"
            },
            {
                "name": "Stevensville",
                "countryCode": "US"
            },
            {
                "name": "Stockbridge",
                "countryCode": "US"
            },
            {
                "name": "Stony Point",
                "countryCode": "US"
            },
            {
                "name": "Sturgis",
                "countryCode": "US"
            },
            {
                "name": "Swartz Creek",
                "countryCode": "US"
            },
            {
                "name": "Sylvan Lake",
                "countryCode": "US"
            },
            {
                "name": "Tawas City",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Tecumseh",
                "countryCode": "US"
            },
            {
                "name": "Temperance",
                "countryCode": "US"
            },
            {
                "name": "Three Oaks",
                "countryCode": "US"
            },
            {
                "name": "Three Rivers",
                "countryCode": "US"
            },
            {
                "name": "Traverse City",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Trowbridge Park",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tuscola County",
                "countryCode": "US"
            },
            {
                "name": "Twin Lake",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Utica",
                "countryCode": "US"
            },
            {
                "name": "Van Buren County",
                "countryCode": "US"
            },
            {
                "name": "Vandercook Lake",
                "countryCode": "US"
            },
            {
                "name": "Vassar",
                "countryCode": "US"
            },
            {
                "name": "Vicksburg",
                "countryCode": "US"
            },
            {
                "name": "Wacousta",
                "countryCode": "US"
            },
            {
                "name": "Wakefield",
                "countryCode": "US"
            },
            {
                "name": "Walker",
                "countryCode": "US"
            },
            {
                "name": "Walled Lake",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Washtenaw County",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Watervliet",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayland",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webberville",
                "countryCode": "US"
            },
            {
                "name": "West Bloomfield Township",
                "countryCode": "US"
            },
            {
                "name": "West Branch",
                "countryCode": "US"
            },
            {
                "name": "West Ishpeming",
                "countryCode": "US"
            },
            {
                "name": "West Monroe",
                "countryCode": "US"
            },
            {
                "name": "Westland",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Wexford County",
                "countryCode": "US"
            },
            {
                "name": "White Cloud",
                "countryCode": "US"
            },
            {
                "name": "White Pigeon",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Whitmore Lake",
                "countryCode": "US"
            },
            {
                "name": "Williamston",
                "countryCode": "US"
            },
            {
                "name": "Wixom",
                "countryCode": "US"
            },
            {
                "name": "Wolf Lake",
                "countryCode": "US"
            },
            {
                "name": "Wolverine Lake",
                "countryCode": "US"
            },
            {
                "name": "Woodhaven",
                "countryCode": "US"
            },
            {
                "name": "Woodland Beach",
                "countryCode": "US"
            },
            {
                "name": "Wyandotte",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Yale",
                "countryCode": "US"
            },
            {
                "name": "Ypsilanti",
                "countryCode": "US"
            },
            {
                "name": "Zeeland",
                "countryCode": "US"
            },
            {
                "name": "Zilwaukee",
                "countryCode": "US"
            },
            {
                "name": "Ada",
                "countryCode": "US"
            },
            {
                "name": "Adrian",
                "countryCode": "US"
            },
            {
                "name": "Afton",
                "countryCode": "US"
            },
            {
                "name": "Aitkin",
                "countryCode": "US"
            },
            {
                "name": "Aitkin County",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Albert Lea",
                "countryCode": "US"
            },
            {
                "name": "Albertville",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Annandale",
                "countryCode": "US"
            },
            {
                "name": "Anoka",
                "countryCode": "US"
            },
            {
                "name": "Anoka County",
                "countryCode": "US"
            },
            {
                "name": "Apple Valley",
                "countryCode": "US"
            },
            {
                "name": "Appleton",
                "countryCode": "US"
            },
            {
                "name": "Arden Hills",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Arnold",
                "countryCode": "US"
            },
            {
                "name": "Atwater",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Austin",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Babbitt",
                "countryCode": "US"
            },
            {
                "name": "Bagley",
                "countryCode": "US"
            },
            {
                "name": "Barnesville",
                "countryCode": "US"
            },
            {
                "name": "Baudette",
                "countryCode": "US"
            },
            {
                "name": "Baxter",
                "countryCode": "US"
            },
            {
                "name": "Bayport",
                "countryCode": "US"
            },
            {
                "name": "Becker",
                "countryCode": "US"
            },
            {
                "name": "Becker County",
                "countryCode": "US"
            },
            {
                "name": "Belle Plaine",
                "countryCode": "US"
            },
            {
                "name": "Beltrami County",
                "countryCode": "US"
            },
            {
                "name": "Bemidji",
                "countryCode": "US"
            },
            {
                "name": "Benson",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Big Lake",
                "countryCode": "US"
            },
            {
                "name": "Big Stone County",
                "countryCode": "US"
            },
            {
                "name": "Birchwood",
                "countryCode": "US"
            },
            {
                "name": "Blaine",
                "countryCode": "US"
            },
            {
                "name": "Blooming Prairie",
                "countryCode": "US"
            },
            {
                "name": "Bloomington",
                "countryCode": "US"
            },
            {
                "name": "Blue Earth",
                "countryCode": "US"
            },
            {
                "name": "Blue Earth County",
                "countryCode": "US"
            },
            {
                "name": "Braham",
                "countryCode": "US"
            },
            {
                "name": "Brainerd",
                "countryCode": "US"
            },
            {
                "name": "Branch",
                "countryCode": "US"
            },
            {
                "name": "Breckenridge",
                "countryCode": "US"
            },
            {
                "name": "Breezy Point",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn Center",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn Park",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Burnsville",
                "countryCode": "US"
            },
            {
                "name": "Byron",
                "countryCode": "US"
            },
            {
                "name": "Caledonia",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Canby",
                "countryCode": "US"
            },
            {
                "name": "Cannon Falls",
                "countryCode": "US"
            },
            {
                "name": "Carlton",
                "countryCode": "US"
            },
            {
                "name": "Carlton County",
                "countryCode": "US"
            },
            {
                "name": "Carver",
                "countryCode": "US"
            },
            {
                "name": "Carver County",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Center City",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Champlin",
                "countryCode": "US"
            },
            {
                "name": "Chanhassen",
                "countryCode": "US"
            },
            {
                "name": "Chaska",
                "countryCode": "US"
            },
            {
                "name": "Chatfield",
                "countryCode": "US"
            },
            {
                "name": "Chippewa County",
                "countryCode": "US"
            },
            {
                "name": "Chisago City",
                "countryCode": "US"
            },
            {
                "name": "Chisago County",
                "countryCode": "US"
            },
            {
                "name": "Chisholm",
                "countryCode": "US"
            },
            {
                "name": "Circle Pines",
                "countryCode": "US"
            },
            {
                "name": "Clara City",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clearwater",
                "countryCode": "US"
            },
            {
                "name": "Clearwater County",
                "countryCode": "US"
            },
            {
                "name": "Cloquet",
                "countryCode": "US"
            },
            {
                "name": "Cohasset",
                "countryCode": "US"
            },
            {
                "name": "Cokato",
                "countryCode": "US"
            },
            {
                "name": "Cold Spring",
                "countryCode": "US"
            },
            {
                "name": "Coleraine",
                "countryCode": "US"
            },
            {
                "name": "Collegeville",
                "countryCode": "US"
            },
            {
                "name": "Cologne",
                "countryCode": "US"
            },
            {
                "name": "Columbia Heights",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Cook County",
                "countryCode": "US"
            },
            {
                "name": "Coon Rapids",
                "countryCode": "US"
            },
            {
                "name": "Corcoran",
                "countryCode": "US"
            },
            {
                "name": "Cottage Grove",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood County",
                "countryCode": "US"
            },
            {
                "name": "Crookston",
                "countryCode": "US"
            },
            {
                "name": "Crosby",
                "countryCode": "US"
            },
            {
                "name": "Cross Lake",
                "countryCode": "US"
            },
            {
                "name": "Crow Wing County",
                "countryCode": "US"
            },
            {
                "name": "Crystal",
                "countryCode": "US"
            },
            {
                "name": "Dakota County",
                "countryCode": "US"
            },
            {
                "name": "Dassel",
                "countryCode": "US"
            },
            {
                "name": "Dawson",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Deephaven",
                "countryCode": "US"
            },
            {
                "name": "Delano",
                "countryCode": "US"
            },
            {
                "name": "Dellwood",
                "countryCode": "US"
            },
            {
                "name": "Detroit Lakes",
                "countryCode": "US"
            },
            {
                "name": "Dilworth",
                "countryCode": "US"
            },
            {
                "name": "Dodge Center",
                "countryCode": "US"
            },
            {
                "name": "Dodge County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Duluth",
                "countryCode": "US"
            },
            {
                "name": "Dundas",
                "countryCode": "US"
            },
            {
                "name": "Eagan",
                "countryCode": "US"
            },
            {
                "name": "Eagle Lake",
                "countryCode": "US"
            },
            {
                "name": "East Bethel",
                "countryCode": "US"
            },
            {
                "name": "East Grand Forks",
                "countryCode": "US"
            },
            {
                "name": "East Gull Lake",
                "countryCode": "US"
            },
            {
                "name": "Eden Prairie",
                "countryCode": "US"
            },
            {
                "name": "Eden Valley",
                "countryCode": "US"
            },
            {
                "name": "Edgerton",
                "countryCode": "US"
            },
            {
                "name": "Edina",
                "countryCode": "US"
            },
            {
                "name": "Elbow Lake",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Elk River",
                "countryCode": "US"
            },
            {
                "name": "Elko New Market",
                "countryCode": "US"
            },
            {
                "name": "Ely",
                "countryCode": "US"
            },
            {
                "name": "Esko",
                "countryCode": "US"
            },
            {
                "name": "Eveleth",
                "countryCode": "US"
            },
            {
                "name": "Excelsior",
                "countryCode": "US"
            },
            {
                "name": "Eyota",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairmont",
                "countryCode": "US"
            },
            {
                "name": "Falcon Heights",
                "countryCode": "US"
            },
            {
                "name": "Faribault",
                "countryCode": "US"
            },
            {
                "name": "Faribault County",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fergus Falls",
                "countryCode": "US"
            },
            {
                "name": "Fillmore County",
                "countryCode": "US"
            },
            {
                "name": "Foley",
                "countryCode": "US"
            },
            {
                "name": "Forest Lake",
                "countryCode": "US"
            },
            {
                "name": "Fosston",
                "countryCode": "US"
            },
            {
                "name": "Frazee",
                "countryCode": "US"
            },
            {
                "name": "Freeborn County",
                "countryCode": "US"
            },
            {
                "name": "Fridley",
                "countryCode": "US"
            },
            {
                "name": "Fulda",
                "countryCode": "US"
            },
            {
                "name": "Gaylord",
                "countryCode": "US"
            },
            {
                "name": "Gilbert",
                "countryCode": "US"
            },
            {
                "name": "Glencoe",
                "countryCode": "US"
            },
            {
                "name": "Glenwood",
                "countryCode": "US"
            },
            {
                "name": "Glyndon",
                "countryCode": "US"
            },
            {
                "name": "Golden Valley",
                "countryCode": "US"
            },
            {
                "name": "Goodhue",
                "countryCode": "US"
            },
            {
                "name": "Goodhue County",
                "countryCode": "US"
            },
            {
                "name": "Goodview",
                "countryCode": "US"
            },
            {
                "name": "Grand Marais",
                "countryCode": "US"
            },
            {
                "name": "Grand Meadow",
                "countryCode": "US"
            },
            {
                "name": "Grand Rapids",
                "countryCode": "US"
            },
            {
                "name": "Granite Falls",
                "countryCode": "US"
            },
            {
                "name": "Grant",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Hallock",
                "countryCode": "US"
            },
            {
                "name": "Ham Lake",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harris",
                "countryCode": "US"
            },
            {
                "name": "Hastings",
                "countryCode": "US"
            },
            {
                "name": "Hawley",
                "countryCode": "US"
            },
            {
                "name": "Hayfield",
                "countryCode": "US"
            },
            {
                "name": "Hector",
                "countryCode": "US"
            },
            {
                "name": "Hennepin County",
                "countryCode": "US"
            },
            {
                "name": "Hermantown",
                "countryCode": "US"
            },
            {
                "name": "Hibbing",
                "countryCode": "US"
            },
            {
                "name": "Hinckley",
                "countryCode": "US"
            },
            {
                "name": "Hopkins",
                "countryCode": "US"
            },
            {
                "name": "Houston County",
                "countryCode": "US"
            },
            {
                "name": "Howard Lake",
                "countryCode": "US"
            },
            {
                "name": "Hoyt Lakes",
                "countryCode": "US"
            },
            {
                "name": "Hubbard County",
                "countryCode": "US"
            },
            {
                "name": "Hugo",
                "countryCode": "US"
            },
            {
                "name": "Hutchinson",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "International Falls",
                "countryCode": "US"
            },
            {
                "name": "Inver Grove Heights",
                "countryCode": "US"
            },
            {
                "name": "Isanti",
                "countryCode": "US"
            },
            {
                "name": "Isanti County",
                "countryCode": "US"
            },
            {
                "name": "Itasca County",
                "countryCode": "US"
            },
            {
                "name": "Ivanhoe",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Janesville",
                "countryCode": "US"
            },
            {
                "name": "Jordan",
                "countryCode": "US"
            },
            {
                "name": "Kanabec County",
                "countryCode": "US"
            },
            {
                "name": "Kandiyohi County",
                "countryCode": "US"
            },
            {
                "name": "Kasson",
                "countryCode": "US"
            },
            {
                "name": "Keewatin",
                "countryCode": "US"
            },
            {
                "name": "Kenyon",
                "countryCode": "US"
            },
            {
                "name": "Kittson County",
                "countryCode": "US"
            },
            {
                "name": "Koochiching County",
                "countryCode": "US"
            },
            {
                "name": "La Crescent",
                "countryCode": "US"
            },
            {
                "name": "Lac qui Parle County",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Crystal",
                "countryCode": "US"
            },
            {
                "name": "Lake Elmo",
                "countryCode": "US"
            },
            {
                "name": "Lake Saint Croix Beach",
                "countryCode": "US"
            },
            {
                "name": "Lake Shore",
                "countryCode": "US"
            },
            {
                "name": "Lake of the Woods County",
                "countryCode": "US"
            },
            {
                "name": "Lakefield",
                "countryCode": "US"
            },
            {
                "name": "Lakeland",
                "countryCode": "US"
            },
            {
                "name": "Lakeville",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale",
                "countryCode": "US"
            },
            {
                "name": "Le Center",
                "countryCode": "US"
            },
            {
                "name": "Le Sueur",
                "countryCode": "US"
            },
            {
                "name": "Le Sueur County",
                "countryCode": "US"
            },
            {
                "name": "Lester Prairie",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lindstrom",
                "countryCode": "US"
            },
            {
                "name": "Lino Lakes",
                "countryCode": "US"
            },
            {
                "name": "Litchfield",
                "countryCode": "US"
            },
            {
                "name": "Little Canada",
                "countryCode": "US"
            },
            {
                "name": "Little Falls",
                "countryCode": "US"
            },
            {
                "name": "Little Rock",
                "countryCode": "US"
            },
            {
                "name": "Long Lake",
                "countryCode": "US"
            },
            {
                "name": "Long Prairie",
                "countryCode": "US"
            },
            {
                "name": "Lonsdale",
                "countryCode": "US"
            },
            {
                "name": "Luverne",
                "countryCode": "US"
            },
            {
                "name": "Lyon County",
                "countryCode": "US"
            },
            {
                "name": "Madelia",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison Lake",
                "countryCode": "US"
            },
            {
                "name": "Mahnomen",
                "countryCode": "US"
            },
            {
                "name": "Mahnomen County",
                "countryCode": "US"
            },
            {
                "name": "Mahtomedi",
                "countryCode": "US"
            },
            {
                "name": "Mankato",
                "countryCode": "US"
            },
            {
                "name": "Mantorville",
                "countryCode": "US"
            },
            {
                "name": "Maple Grove",
                "countryCode": "US"
            },
            {
                "name": "Maple Lake",
                "countryCode": "US"
            },
            {
                "name": "Maple Plain",
                "countryCode": "US"
            },
            {
                "name": "Mapleton",
                "countryCode": "US"
            },
            {
                "name": "Maplewood",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Mayer",
                "countryCode": "US"
            },
            {
                "name": "McLeod County",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Meeker County",
                "countryCode": "US"
            },
            {
                "name": "Melrose",
                "countryCode": "US"
            },
            {
                "name": "Menahga",
                "countryCode": "US"
            },
            {
                "name": "Mendota Heights",
                "countryCode": "US"
            },
            {
                "name": "Milaca",
                "countryCode": "US"
            },
            {
                "name": "Mille Lacs County",
                "countryCode": "US"
            },
            {
                "name": "Minneapolis",
                "countryCode": "US"
            },
            {
                "name": "Minneota",
                "countryCode": "US"
            },
            {
                "name": "Minnetonka",
                "countryCode": "US"
            },
            {
                "name": "Minnetonka Mills",
                "countryCode": "US"
            },
            {
                "name": "Minnetrista",
                "countryCode": "US"
            },
            {
                "name": "Montevideo",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Moorhead",
                "countryCode": "US"
            },
            {
                "name": "Moose Lake",
                "countryCode": "US"
            },
            {
                "name": "Mora",
                "countryCode": "US"
            },
            {
                "name": "Morris",
                "countryCode": "US"
            },
            {
                "name": "Morrison County",
                "countryCode": "US"
            },
            {
                "name": "Mound",
                "countryCode": "US"
            },
            {
                "name": "Mounds View",
                "countryCode": "US"
            },
            {
                "name": "Mountain Iron",
                "countryCode": "US"
            },
            {
                "name": "Mountain Lake",
                "countryCode": "US"
            },
            {
                "name": "Mower County",
                "countryCode": "US"
            },
            {
                "name": "Murray County",
                "countryCode": "US"
            },
            {
                "name": "New Brighton",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New Prague",
                "countryCode": "US"
            },
            {
                "name": "New Richland",
                "countryCode": "US"
            },
            {
                "name": "New Ulm",
                "countryCode": "US"
            },
            {
                "name": "New York Mills",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Nicollet",
                "countryCode": "US"
            },
            {
                "name": "Nicollet County",
                "countryCode": "US"
            },
            {
                "name": "Nisswa",
                "countryCode": "US"
            },
            {
                "name": "Nobles County",
                "countryCode": "US"
            },
            {
                "name": "Norman County",
                "countryCode": "US"
            },
            {
                "name": "North Branch",
                "countryCode": "US"
            },
            {
                "name": "North Mankato",
                "countryCode": "US"
            },
            {
                "name": "North Oaks",
                "countryCode": "US"
            },
            {
                "name": "North Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Norwood (historical)",
                "countryCode": "US"
            },
            {
                "name": "Norwood Young America",
                "countryCode": "US"
            },
            {
                "name": "Nowthen",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oak Park Heights",
                "countryCode": "US"
            },
            {
                "name": "Oakdale",
                "countryCode": "US"
            },
            {
                "name": "Oakport",
                "countryCode": "US"
            },
            {
                "name": "Olivia",
                "countryCode": "US"
            },
            {
                "name": "Olmsted County",
                "countryCode": "US"
            },
            {
                "name": "Orono",
                "countryCode": "US"
            },
            {
                "name": "Oronoco",
                "countryCode": "US"
            },
            {
                "name": "Ortonville",
                "countryCode": "US"
            },
            {
                "name": "Osakis",
                "countryCode": "US"
            },
            {
                "name": "Osseo",
                "countryCode": "US"
            },
            {
                "name": "Otsego",
                "countryCode": "US"
            },
            {
                "name": "Otter Tail County",
                "countryCode": "US"
            },
            {
                "name": "Owatonna",
                "countryCode": "US"
            },
            {
                "name": "Park Rapids",
                "countryCode": "US"
            },
            {
                "name": "Parkers Prairie",
                "countryCode": "US"
            },
            {
                "name": "Parkville",
                "countryCode": "US"
            },
            {
                "name": "Paynesville",
                "countryCode": "US"
            },
            {
                "name": "Pelican Rapids",
                "countryCode": "US"
            },
            {
                "name": "Pennington County",
                "countryCode": "US"
            },
            {
                "name": "Pequot Lakes",
                "countryCode": "US"
            },
            {
                "name": "Perham",
                "countryCode": "US"
            },
            {
                "name": "Pierz",
                "countryCode": "US"
            },
            {
                "name": "Pine City",
                "countryCode": "US"
            },
            {
                "name": "Pine County",
                "countryCode": "US"
            },
            {
                "name": "Pine Island",
                "countryCode": "US"
            },
            {
                "name": "Pipestone",
                "countryCode": "US"
            },
            {
                "name": "Pipestone County",
                "countryCode": "US"
            },
            {
                "name": "Plainview",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Pope County",
                "countryCode": "US"
            },
            {
                "name": "Preston",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Prior Lake",
                "countryCode": "US"
            },
            {
                "name": "Proctor",
                "countryCode": "US"
            },
            {
                "name": "Ramsey",
                "countryCode": "US"
            },
            {
                "name": "Ramsey County",
                "countryCode": "US"
            },
            {
                "name": "Red Lake",
                "countryCode": "US"
            },
            {
                "name": "Red Lake County",
                "countryCode": "US"
            },
            {
                "name": "Red Lake Falls",
                "countryCode": "US"
            },
            {
                "name": "Red Wing",
                "countryCode": "US"
            },
            {
                "name": "Redby",
                "countryCode": "US"
            },
            {
                "name": "Redwood County",
                "countryCode": "US"
            },
            {
                "name": "Redwood Falls",
                "countryCode": "US"
            },
            {
                "name": "Renville",
                "countryCode": "US"
            },
            {
                "name": "Renville County",
                "countryCode": "US"
            },
            {
                "name": "Rice",
                "countryCode": "US"
            },
            {
                "name": "Rice County",
                "countryCode": "US"
            },
            {
                "name": "Richfield",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Robbinsdale",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rock County",
                "countryCode": "US"
            },
            {
                "name": "Rock Creek",
                "countryCode": "US"
            },
            {
                "name": "Rockford",
                "countryCode": "US"
            },
            {
                "name": "Rockville",
                "countryCode": "US"
            },
            {
                "name": "Rogers",
                "countryCode": "US"
            },
            {
                "name": "Roseau",
                "countryCode": "US"
            },
            {
                "name": "Roseau County",
                "countryCode": "US"
            },
            {
                "name": "Rosemount",
                "countryCode": "US"
            },
            {
                "name": "Roseville",
                "countryCode": "US"
            },
            {
                "name": "Royalton",
                "countryCode": "US"
            },
            {
                "name": "Rush City",
                "countryCode": "US"
            },
            {
                "name": "Rushford",
                "countryCode": "US"
            },
            {
                "name": "Saint Anthony",
                "countryCode": "US"
            },
            {
                "name": "Saint Augusta",
                "countryCode": "US"
            },
            {
                "name": "Saint Bonifacius",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles",
                "countryCode": "US"
            },
            {
                "name": "Saint Cloud",
                "countryCode": "US"
            },
            {
                "name": "Saint Francis",
                "countryCode": "US"
            },
            {
                "name": "Saint James",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "US"
            },
            {
                "name": "Saint Louis County",
                "countryCode": "US"
            },
            {
                "name": "Saint Louis Park",
                "countryCode": "US"
            },
            {
                "name": "Saint Michael",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul Park",
                "countryCode": "US"
            },
            {
                "name": "Saint Peter",
                "countryCode": "US"
            },
            {
                "name": "Sandstone",
                "countryCode": "US"
            },
            {
                "name": "Sartell",
                "countryCode": "US"
            },
            {
                "name": "Sauk Centre",
                "countryCode": "US"
            },
            {
                "name": "Sauk Rapids",
                "countryCode": "US"
            },
            {
                "name": "Savage",
                "countryCode": "US"
            },
            {
                "name": "Scandia",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Shafer",
                "countryCode": "US"
            },
            {
                "name": "Shakopee",
                "countryCode": "US"
            },
            {
                "name": "Sherburn",
                "countryCode": "US"
            },
            {
                "name": "Sherburne County",
                "countryCode": "US"
            },
            {
                "name": "Shoreview",
                "countryCode": "US"
            },
            {
                "name": "Shorewood",
                "countryCode": "US"
            },
            {
                "name": "Sibley County",
                "countryCode": "US"
            },
            {
                "name": "Silver Bay",
                "countryCode": "US"
            },
            {
                "name": "Slayton",
                "countryCode": "US"
            },
            {
                "name": "Sleepy Eye",
                "countryCode": "US"
            },
            {
                "name": "South Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Spicer",
                "countryCode": "US"
            },
            {
                "name": "Spring Grove",
                "countryCode": "US"
            },
            {
                "name": "Spring Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Spring Park",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stacy",
                "countryCode": "US"
            },
            {
                "name": "Staples",
                "countryCode": "US"
            },
            {
                "name": "Starbuck",
                "countryCode": "US"
            },
            {
                "name": "Stearns County",
                "countryCode": "US"
            },
            {
                "name": "Steele County",
                "countryCode": "US"
            },
            {
                "name": "Stevens County",
                "countryCode": "US"
            },
            {
                "name": "Stewartville",
                "countryCode": "US"
            },
            {
                "name": "Stillwater",
                "countryCode": "US"
            },
            {
                "name": "Swift County",
                "countryCode": "US"
            },
            {
                "name": "Taylors Falls",
                "countryCode": "US"
            },
            {
                "name": "Thief River Falls",
                "countryCode": "US"
            },
            {
                "name": "Todd County",
                "countryCode": "US"
            },
            {
                "name": "Tonka Bay",
                "countryCode": "US"
            },
            {
                "name": "Tracy",
                "countryCode": "US"
            },
            {
                "name": "Traverse County",
                "countryCode": "US"
            },
            {
                "name": "Truman",
                "countryCode": "US"
            },
            {
                "name": "Two Harbors",
                "countryCode": "US"
            },
            {
                "name": "Tyler",
                "countryCode": "US"
            },
            {
                "name": "Vadnais Heights",
                "countryCode": "US"
            },
            {
                "name": "Victoria",
                "countryCode": "US"
            },
            {
                "name": "Vineland",
                "countryCode": "US"
            },
            {
                "name": "Virginia",
                "countryCode": "US"
            },
            {
                "name": "Wabasha",
                "countryCode": "US"
            },
            {
                "name": "Wabasha County",
                "countryCode": "US"
            },
            {
                "name": "Waconia",
                "countryCode": "US"
            },
            {
                "name": "Wadena",
                "countryCode": "US"
            },
            {
                "name": "Wadena County",
                "countryCode": "US"
            },
            {
                "name": "Waite Park",
                "countryCode": "US"
            },
            {
                "name": "Walker",
                "countryCode": "US"
            },
            {
                "name": "Wanamingo",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warroad",
                "countryCode": "US"
            },
            {
                "name": "Waseca",
                "countryCode": "US"
            },
            {
                "name": "Waseca County",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Waterville",
                "countryCode": "US"
            },
            {
                "name": "Watonwan County",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayzata",
                "countryCode": "US"
            },
            {
                "name": "Wells",
                "countryCode": "US"
            },
            {
                "name": "West Coon Rapids",
                "countryCode": "US"
            },
            {
                "name": "West Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Wheaton",
                "countryCode": "US"
            },
            {
                "name": "White Bear Lake",
                "countryCode": "US"
            },
            {
                "name": "Wilkin County",
                "countryCode": "US"
            },
            {
                "name": "Willmar",
                "countryCode": "US"
            },
            {
                "name": "Windom",
                "countryCode": "US"
            },
            {
                "name": "Winnebago",
                "countryCode": "US"
            },
            {
                "name": "Winona",
                "countryCode": "US"
            },
            {
                "name": "Winona County",
                "countryCode": "US"
            },
            {
                "name": "Winsted",
                "countryCode": "US"
            },
            {
                "name": "Winthrop",
                "countryCode": "US"
            },
            {
                "name": "Woodbury",
                "countryCode": "US"
            },
            {
                "name": "Worthington",
                "countryCode": "US"
            },
            {
                "name": "Wright County",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Yellow Medicine County",
                "countryCode": "US"
            },
            {
                "name": "Young America (historical)",
                "countryCode": "US"
            },
            {
                "name": "Zimmerman",
                "countryCode": "US"
            },
            {
                "name": "Zumbrota",
                "countryCode": "US"
            },
            {
                "name": "Adair County",
                "countryCode": "US"
            },
            {
                "name": "Adrian",
                "countryCode": "US"
            },
            {
                "name": "Advance",
                "countryCode": "US"
            },
            {
                "name": "Affton",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Alton",
                "countryCode": "US"
            },
            {
                "name": "Anderson",
                "countryCode": "US"
            },
            {
                "name": "Andrew County",
                "countryCode": "US"
            },
            {
                "name": "Appleton City",
                "countryCode": "US"
            },
            {
                "name": "Archie",
                "countryCode": "US"
            },
            {
                "name": "Arnold",
                "countryCode": "US"
            },
            {
                "name": "Ash Grove",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atchison County",
                "countryCode": "US"
            },
            {
                "name": "Audrain County",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Ava",
                "countryCode": "US"
            },
            {
                "name": "Ballwin",
                "countryCode": "US"
            },
            {
                "name": "Barnhart",
                "countryCode": "US"
            },
            {
                "name": "Barry County",
                "countryCode": "US"
            },
            {
                "name": "Barton County",
                "countryCode": "US"
            },
            {
                "name": "Bates County",
                "countryCode": "US"
            },
            {
                "name": "Battlefield",
                "countryCode": "US"
            },
            {
                "name": "Bel-Nor",
                "countryCode": "US"
            },
            {
                "name": "Bel-Ridge",
                "countryCode": "US"
            },
            {
                "name": "Belle",
                "countryCode": "US"
            },
            {
                "name": "Bellefontaine Neighbors",
                "countryCode": "US"
            },
            {
                "name": "Belton",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Berkeley",
                "countryCode": "US"
            },
            {
                "name": "Bernie",
                "countryCode": "US"
            },
            {
                "name": "Bethany",
                "countryCode": "US"
            },
            {
                "name": "Billings",
                "countryCode": "US"
            },
            {
                "name": "Bismarck",
                "countryCode": "US"
            },
            {
                "name": "Black Jack",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Blue Springs",
                "countryCode": "US"
            },
            {
                "name": "Bolivar",
                "countryCode": "US"
            },
            {
                "name": "Bollinger County",
                "countryCode": "US"
            },
            {
                "name": "Bonne Terre",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Boonville",
                "countryCode": "US"
            },
            {
                "name": "Bourbon",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Branson",
                "countryCode": "US"
            },
            {
                "name": "Breckenridge Hills",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Bridgeton",
                "countryCode": "US"
            },
            {
                "name": "Brookfield",
                "countryCode": "US"
            },
            {
                "name": "Buchanan County",
                "countryCode": "US"
            },
            {
                "name": "Buckner",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Byrnes Mill",
                "countryCode": "US"
            },
            {
                "name": "Cabool",
                "countryCode": "US"
            },
            {
                "name": "Caldwell County",
                "countryCode": "US"
            },
            {
                "name": "California",
                "countryCode": "US"
            },
            {
                "name": "Callaway County",
                "countryCode": "US"
            },
            {
                "name": "Calverton Park",
                "countryCode": "US"
            },
            {
                "name": "Camden County",
                "countryCode": "US"
            },
            {
                "name": "Camdenton",
                "countryCode": "US"
            },
            {
                "name": "Cameron",
                "countryCode": "US"
            },
            {
                "name": "Campbell",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Cape Girardeau",
                "countryCode": "US"
            },
            {
                "name": "Cape Girardeau County",
                "countryCode": "US"
            },
            {
                "name": "Carl Junction",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carter County",
                "countryCode": "US"
            },
            {
                "name": "Carterville",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Caruthersville",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Cassville",
                "countryCode": "US"
            },
            {
                "name": "Castle Point",
                "countryCode": "US"
            },
            {
                "name": "Cedar County",
                "countryCode": "US"
            },
            {
                "name": "Cedar Hill",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Centralia",
                "countryCode": "US"
            },
            {
                "name": "Chaffee",
                "countryCode": "US"
            },
            {
                "name": "Chariton County",
                "countryCode": "US"
            },
            {
                "name": "Charlack",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield",
                "countryCode": "US"
            },
            {
                "name": "Chillicothe",
                "countryCode": "US"
            },
            {
                "name": "Christian County",
                "countryCode": "US"
            },
            {
                "name": "City of Saint Louis",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clarkson Valley",
                "countryCode": "US"
            },
            {
                "name": "Clarkton",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Claycomo",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clever",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Cole Camp",
                "countryCode": "US"
            },
            {
                "name": "Cole County",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Concordia",
                "countryCode": "US"
            },
            {
                "name": "Cool Valley",
                "countryCode": "US"
            },
            {
                "name": "Cooper County",
                "countryCode": "US"
            },
            {
                "name": "Cottleville",
                "countryCode": "US"
            },
            {
                "name": "Country Club Hills",
                "countryCode": "US"
            },
            {
                "name": "Country Club Village",
                "countryCode": "US"
            },
            {
                "name": "Crane",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crestwood",
                "countryCode": "US"
            },
            {
                "name": "Creve Coeur",
                "countryCode": "US"
            },
            {
                "name": "Crocker",
                "countryCode": "US"
            },
            {
                "name": "Crystal City",
                "countryCode": "US"
            },
            {
                "name": "Cuba",
                "countryCode": "US"
            },
            {
                "name": "Dade County",
                "countryCode": "US"
            },
            {
                "name": "Dallas County",
                "countryCode": "US"
            },
            {
                "name": "Dardenne Prairie",
                "countryCode": "US"
            },
            {
                "name": "Daviess County",
                "countryCode": "US"
            },
            {
                "name": "De Soto",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "Dellwood",
                "countryCode": "US"
            },
            {
                "name": "Dent County",
                "countryCode": "US"
            },
            {
                "name": "Des Peres",
                "countryCode": "US"
            },
            {
                "name": "Desloge",
                "countryCode": "US"
            },
            {
                "name": "Dexter",
                "countryCode": "US"
            },
            {
                "name": "Dixon",
                "countryCode": "US"
            },
            {
                "name": "Doniphan",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Duenweg",
                "countryCode": "US"
            },
            {
                "name": "Dunklin County",
                "countryCode": "US"
            },
            {
                "name": "Duquesne",
                "countryCode": "US"
            },
            {
                "name": "East Independence",
                "countryCode": "US"
            },
            {
                "name": "East Prairie",
                "countryCode": "US"
            },
            {
                "name": "Edina",
                "countryCode": "US"
            },
            {
                "name": "El Dorado Springs",
                "countryCode": "US"
            },
            {
                "name": "Eldon",
                "countryCode": "US"
            },
            {
                "name": "Ellisville",
                "countryCode": "US"
            },
            {
                "name": "Elsberry",
                "countryCode": "US"
            },
            {
                "name": "Elvins",
                "countryCode": "US"
            },
            {
                "name": "Eminence",
                "countryCode": "US"
            },
            {
                "name": "Esther",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Excelsior Springs",
                "countryCode": "US"
            },
            {
                "name": "Fair Grove",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Fenton",
                "countryCode": "US"
            },
            {
                "name": "Ferguson",
                "countryCode": "US"
            },
            {
                "name": "Festus",
                "countryCode": "US"
            },
            {
                "name": "Flat River",
                "countryCode": "US"
            },
            {
                "name": "Florissant",
                "countryCode": "US"
            },
            {
                "name": "Forsyth",
                "countryCode": "US"
            },
            {
                "name": "Fort Leonard Wood",
                "countryCode": "US"
            },
            {
                "name": "Four Seasons",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fredericktown",
                "countryCode": "US"
            },
            {
                "name": "Frontenac",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Gainesville",
                "countryCode": "US"
            },
            {
                "name": "Galena",
                "countryCode": "US"
            },
            {
                "name": "Gallatin",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Gasconade County",
                "countryCode": "US"
            },
            {
                "name": "Gentry County",
                "countryCode": "US"
            },
            {
                "name": "Gerald",
                "countryCode": "US"
            },
            {
                "name": "Gideon",
                "countryCode": "US"
            },
            {
                "name": "Gladstone",
                "countryCode": "US"
            },
            {
                "name": "Glasgow",
                "countryCode": "US"
            },
            {
                "name": "Glasgow Village",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Goodman",
                "countryCode": "US"
            },
            {
                "name": "Gower",
                "countryCode": "US"
            },
            {
                "name": "Grain Valley",
                "countryCode": "US"
            },
            {
                "name": "Granby",
                "countryCode": "US"
            },
            {
                "name": "Grandview",
                "countryCode": "US"
            },
            {
                "name": "Grant City",
                "countryCode": "US"
            },
            {
                "name": "Gray Summit",
                "countryCode": "US"
            },
            {
                "name": "Green Park",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Grundy County",
                "countryCode": "US"
            },
            {
                "name": "Hallsville",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hanley Hills",
                "countryCode": "US"
            },
            {
                "name": "Hannibal",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Harrisonville",
                "countryCode": "US"
            },
            {
                "name": "Hartville",
                "countryCode": "US"
            },
            {
                "name": "Hayti",
                "countryCode": "US"
            },
            {
                "name": "Hazelwood",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Herculaneum",
                "countryCode": "US"
            },
            {
                "name": "Hermann",
                "countryCode": "US"
            },
            {
                "name": "Hermitage",
                "countryCode": "US"
            },
            {
                "name": "Hickory County",
                "countryCode": "US"
            },
            {
                "name": "Higginsville",
                "countryCode": "US"
            },
            {
                "name": "High Ridge",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hillsdale",
                "countryCode": "US"
            },
            {
                "name": "Holden",
                "countryCode": "US"
            },
            {
                "name": "Hollister",
                "countryCode": "US"
            },
            {
                "name": "Holt County",
                "countryCode": "US"
            },
            {
                "name": "Holts Summit",
                "countryCode": "US"
            },
            {
                "name": "Houston",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Howell County",
                "countryCode": "US"
            },
            {
                "name": "Humansville",
                "countryCode": "US"
            },
            {
                "name": "Huntsville",
                "countryCode": "US"
            },
            {
                "name": "Imperial",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Iron County",
                "countryCode": "US"
            },
            {
                "name": "Ironton",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson City",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jennings",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Joplin",
                "countryCode": "US"
            },
            {
                "name": "Kahoka",
                "countryCode": "US"
            },
            {
                "name": "Kansas City",
                "countryCode": "US"
            },
            {
                "name": "Kearney",
                "countryCode": "US"
            },
            {
                "name": "Kennett",
                "countryCode": "US"
            },
            {
                "name": "Keytesville",
                "countryCode": "US"
            },
            {
                "name": "Kimberling City",
                "countryCode": "US"
            },
            {
                "name": "King City",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kirksville",
                "countryCode": "US"
            },
            {
                "name": "Kirkwood",
                "countryCode": "US"
            },
            {
                "name": "Kissee Mills",
                "countryCode": "US"
            },
            {
                "name": "Knob Noster",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "La Monte",
                "countryCode": "US"
            },
            {
                "name": "La Plata",
                "countryCode": "US"
            },
            {
                "name": "LaBarque Creek",
                "countryCode": "US"
            },
            {
                "name": "Laclede County",
                "countryCode": "US"
            },
            {
                "name": "Ladue",
                "countryCode": "US"
            },
            {
                "name": "Lafayette County",
                "countryCode": "US"
            },
            {
                "name": "Lake Lotawana",
                "countryCode": "US"
            },
            {
                "name": "Lake Ozark",
                "countryCode": "US"
            },
            {
                "name": "Lake Saint Louis",
                "countryCode": "US"
            },
            {
                "name": "Lake Winnebago",
                "countryCode": "US"
            },
            {
                "name": "Lakeshire",
                "countryCode": "US"
            },
            {
                "name": "Lamar",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lathrop",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawson",
                "countryCode": "US"
            },
            {
                "name": "Leadwood",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lee's Summit",
                "countryCode": "US"
            },
            {
                "name": "Lemay",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Licking",
                "countryCode": "US"
            },
            {
                "name": "Lilbourn",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Linn",
                "countryCode": "US"
            },
            {
                "name": "Linn County",
                "countryCode": "US"
            },
            {
                "name": "Linneus",
                "countryCode": "US"
            },
            {
                "name": "Livingston County",
                "countryCode": "US"
            },
            {
                "name": "Lone Jack",
                "countryCode": "US"
            },
            {
                "name": "Louisiana",
                "countryCode": "US"
            },
            {
                "name": "Macon",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Malden",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Maplewood",
                "countryCode": "US"
            },
            {
                "name": "Marble Hill",
                "countryCode": "US"
            },
            {
                "name": "Marceline",
                "countryCode": "US"
            },
            {
                "name": "Maries County",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marionville",
                "countryCode": "US"
            },
            {
                "name": "Marlborough",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marshfield",
                "countryCode": "US"
            },
            {
                "name": "Marthasville",
                "countryCode": "US"
            },
            {
                "name": "Maryland Heights",
                "countryCode": "US"
            },
            {
                "name": "Maryville",
                "countryCode": "US"
            },
            {
                "name": "Maysville",
                "countryCode": "US"
            },
            {
                "name": "McDonald County",
                "countryCode": "US"
            },
            {
                "name": "Mehlville",
                "countryCode": "US"
            },
            {
                "name": "Memphis",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Merriam Woods",
                "countryCode": "US"
            },
            {
                "name": "Mexico",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Miller County",
                "countryCode": "US"
            },
            {
                "name": "Mississippi County",
                "countryCode": "US"
            },
            {
                "name": "Moberly",
                "countryCode": "US"
            },
            {
                "name": "Moline Acres",
                "countryCode": "US"
            },
            {
                "name": "Monett",
                "countryCode": "US"
            },
            {
                "name": "Moniteau County",
                "countryCode": "US"
            },
            {
                "name": "Monroe City",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery City",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Moscow Mills",
                "countryCode": "US"
            },
            {
                "name": "Mound City",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mountain Grove",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Murphy",
                "countryCode": "US"
            },
            {
                "name": "Neosho",
                "countryCode": "US"
            },
            {
                "name": "Nevada",
                "countryCode": "US"
            },
            {
                "name": "New Franklin",
                "countryCode": "US"
            },
            {
                "name": "New Haven",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New Madrid",
                "countryCode": "US"
            },
            {
                "name": "New Madrid County",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "Nixa",
                "countryCode": "US"
            },
            {
                "name": "Nodaway County",
                "countryCode": "US"
            },
            {
                "name": "Noel",
                "countryCode": "US"
            },
            {
                "name": "Normandy",
                "countryCode": "US"
            },
            {
                "name": "North Kansas City",
                "countryCode": "US"
            },
            {
                "name": "Northwoods",
                "countryCode": "US"
            },
            {
                "name": "O'Fallon",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oakville",
                "countryCode": "US"
            },
            {
                "name": "Odessa",
                "countryCode": "US"
            },
            {
                "name": "Old Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Olivette",
                "countryCode": "US"
            },
            {
                "name": "Oran",
                "countryCode": "US"
            },
            {
                "name": "Oregon",
                "countryCode": "US"
            },
            {
                "name": "Oregon County",
                "countryCode": "US"
            },
            {
                "name": "Oronogo",
                "countryCode": "US"
            },
            {
                "name": "Osage Beach",
                "countryCode": "US"
            },
            {
                "name": "Osage County",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Overland",
                "countryCode": "US"
            },
            {
                "name": "Owensville",
                "countryCode": "US"
            },
            {
                "name": "Ozark",
                "countryCode": "US"
            },
            {
                "name": "Ozark County",
                "countryCode": "US"
            },
            {
                "name": "Pacific",
                "countryCode": "US"
            },
            {
                "name": "Pagedale",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Park Hills",
                "countryCode": "US"
            },
            {
                "name": "Parkville",
                "countryCode": "US"
            },
            {
                "name": "Peculiar",
                "countryCode": "US"
            },
            {
                "name": "Pemiscot County",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Perryville",
                "countryCode": "US"
            },
            {
                "name": "Pettis County",
                "countryCode": "US"
            },
            {
                "name": "Pevely",
                "countryCode": "US"
            },
            {
                "name": "Phelps County",
                "countryCode": "US"
            },
            {
                "name": "Piedmont",
                "countryCode": "US"
            },
            {
                "name": "Pierce City",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pine Lawn",
                "countryCode": "US"
            },
            {
                "name": "Pineville",
                "countryCode": "US"
            },
            {
                "name": "Platte City",
                "countryCode": "US"
            },
            {
                "name": "Platte County",
                "countryCode": "US"
            },
            {
                "name": "Plattsburg",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Valley",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Poplar Bluff",
                "countryCode": "US"
            },
            {
                "name": "Portageville",
                "countryCode": "US"
            },
            {
                "name": "Potosi",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Purdy",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Ralls County",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Ray County",
                "countryCode": "US"
            },
            {
                "name": "Raymore",
                "countryCode": "US"
            },
            {
                "name": "Raytown",
                "countryCode": "US"
            },
            {
                "name": "Republic",
                "countryCode": "US"
            },
            {
                "name": "Reynolds County",
                "countryCode": "US"
            },
            {
                "name": "Rich Hill",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Richmond Heights",
                "countryCode": "US"
            },
            {
                "name": "Ripley County",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Riverview",
                "countryCode": "US"
            },
            {
                "name": "Rock Hill",
                "countryCode": "US"
            },
            {
                "name": "Rock Port",
                "countryCode": "US"
            },
            {
                "name": "Rogersville",
                "countryCode": "US"
            },
            {
                "name": "Rolla",
                "countryCode": "US"
            },
            {
                "name": "Saint Ann",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles",
                "countryCode": "US"
            },
            {
                "name": "Saint Charles County",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair County",
                "countryCode": "US"
            },
            {
                "name": "Saint Francois County",
                "countryCode": "US"
            },
            {
                "name": "Saint George",
                "countryCode": "US"
            },
            {
                "name": "Saint James",
                "countryCode": "US"
            },
            {
                "name": "Saint John",
                "countryCode": "US"
            },
            {
                "name": "Saint Johns",
                "countryCode": "US"
            },
            {
                "name": "Saint Joseph",
                "countryCode": "US"
            },
            {
                "name": "Saint Louis County",
                "countryCode": "US"
            },
            {
                "name": "Saint Martins",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Saint Peters",
                "countryCode": "US"
            },
            {
                "name": "Saint Robert",
                "countryCode": "US"
            },
            {
                "name": "Sainte Genevieve",
                "countryCode": "US"
            },
            {
                "name": "Sainte Genevieve County",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Saline County",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Sappington",
                "countryCode": "US"
            },
            {
                "name": "Sarcoxie",
                "countryCode": "US"
            },
            {
                "name": "Savannah",
                "countryCode": "US"
            },
            {
                "name": "Schuyler County",
                "countryCode": "US"
            },
            {
                "name": "Scotland County",
                "countryCode": "US"
            },
            {
                "name": "Scott City",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Sedalia",
                "countryCode": "US"
            },
            {
                "name": "Senath",
                "countryCode": "US"
            },
            {
                "name": "Seneca",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Shannon County",
                "countryCode": "US"
            },
            {
                "name": "Shelbina",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shelbyville",
                "countryCode": "US"
            },
            {
                "name": "Shell Knob",
                "countryCode": "US"
            },
            {
                "name": "Shrewsbury",
                "countryCode": "US"
            },
            {
                "name": "Sikeston",
                "countryCode": "US"
            },
            {
                "name": "Slater",
                "countryCode": "US"
            },
            {
                "name": "Smithville",
                "countryCode": "US"
            },
            {
                "name": "Spanish Lake",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "St. Louis",
                "countryCode": "US"
            },
            {
                "name": "Stanberry",
                "countryCode": "US"
            },
            {
                "name": "Steele",
                "countryCode": "US"
            },
            {
                "name": "Steelville",
                "countryCode": "US"
            },
            {
                "name": "Stockton",
                "countryCode": "US"
            },
            {
                "name": "Stoddard County",
                "countryCode": "US"
            },
            {
                "name": "Stone County",
                "countryCode": "US"
            },
            {
                "name": "Stover",
                "countryCode": "US"
            },
            {
                "name": "Strafford",
                "countryCode": "US"
            },
            {
                "name": "Sugar Creek",
                "countryCode": "US"
            },
            {
                "name": "Sullivan",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Sunset Hills",
                "countryCode": "US"
            },
            {
                "name": "Sweet Springs",
                "countryCode": "US"
            },
            {
                "name": "Taney County",
                "countryCode": "US"
            },
            {
                "name": "Taos",
                "countryCode": "US"
            },
            {
                "name": "Tarkio",
                "countryCode": "US"
            },
            {
                "name": "Terre Haute",
                "countryCode": "US"
            },
            {
                "name": "Terre du Lac",
                "countryCode": "US"
            },
            {
                "name": "Texas County",
                "countryCode": "US"
            },
            {
                "name": "Thayer",
                "countryCode": "US"
            },
            {
                "name": "Tipton",
                "countryCode": "US"
            },
            {
                "name": "Town and Country",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tuscumbia",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Unionville",
                "countryCode": "US"
            },
            {
                "name": "University City",
                "countryCode": "US"
            },
            {
                "name": "Valley Park",
                "countryCode": "US"
            },
            {
                "name": "Van Buren",
                "countryCode": "US"
            },
            {
                "name": "Vandalia",
                "countryCode": "US"
            },
            {
                "name": "Velda Village",
                "countryCode": "US"
            },
            {
                "name": "Velda Village Hills",
                "countryCode": "US"
            },
            {
                "name": "Vernon County",
                "countryCode": "US"
            },
            {
                "name": "Versailles",
                "countryCode": "US"
            },
            {
                "name": "Vienna",
                "countryCode": "US"
            },
            {
                "name": "Villa Ridge",
                "countryCode": "US"
            },
            {
                "name": "Vinita Park",
                "countryCode": "US"
            },
            {
                "name": "Wardsville",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrensburg",
                "countryCode": "US"
            },
            {
                "name": "Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Warson Woods",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesville",
                "countryCode": "US"
            },
            {
                "name": "Weatherby Lake",
                "countryCode": "US"
            },
            {
                "name": "Webb City",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "Webster Groves",
                "countryCode": "US"
            },
            {
                "name": "Weldon Spring",
                "countryCode": "US"
            },
            {
                "name": "Wellston",
                "countryCode": "US"
            },
            {
                "name": "Wellsville",
                "countryCode": "US"
            },
            {
                "name": "Wentzville",
                "countryCode": "US"
            },
            {
                "name": "West Plains",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Whiteman Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Willard",
                "countryCode": "US"
            },
            {
                "name": "Willow Springs",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Winona",
                "countryCode": "US"
            },
            {
                "name": "Woodson Terrace",
                "countryCode": "US"
            },
            {
                "name": "Worth County",
                "countryCode": "US"
            },
            {
                "name": "Wright City",
                "countryCode": "US"
            },
            {
                "name": "Wright County",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Ackerman",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Alcorn County",
                "countryCode": "US"
            },
            {
                "name": "Amite County",
                "countryCode": "US"
            },
            {
                "name": "Amory",
                "countryCode": "US"
            },
            {
                "name": "Arnold Line",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Attala County",
                "countryCode": "US"
            },
            {
                "name": "Baldwyn",
                "countryCode": "US"
            },
            {
                "name": "Batesville",
                "countryCode": "US"
            },
            {
                "name": "Bay Saint Louis",
                "countryCode": "US"
            },
            {
                "name": "Bay Springs",
                "countryCode": "US"
            },
            {
                "name": "Beechwood",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Belzoni",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Biloxi",
                "countryCode": "US"
            },
            {
                "name": "Bolivar County",
                "countryCode": "US"
            },
            {
                "name": "Booneville",
                "countryCode": "US"
            },
            {
                "name": "Brandon",
                "countryCode": "US"
            },
            {
                "name": "Brookhaven",
                "countryCode": "US"
            },
            {
                "name": "Brooksville",
                "countryCode": "US"
            },
            {
                "name": "Bruce",
                "countryCode": "US"
            },
            {
                "name": "Bude",
                "countryCode": "US"
            },
            {
                "name": "Byhalia",
                "countryCode": "US"
            },
            {
                "name": "Byram",
                "countryCode": "US"
            },
            {
                "name": "Caledonia",
                "countryCode": "US"
            },
            {
                "name": "Calhoun City",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Carriere",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Chickasaw County",
                "countryCode": "US"
            },
            {
                "name": "Choctaw County",
                "countryCode": "US"
            },
            {
                "name": "Claiborne County",
                "countryCode": "US"
            },
            {
                "name": "Clarke County",
                "countryCode": "US"
            },
            {
                "name": "Clarksdale",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Cleary",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Coahoma County",
                "countryCode": "US"
            },
            {
                "name": "Coldwater",
                "countryCode": "US"
            },
            {
                "name": "Collins",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Columbus Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Como",
                "countryCode": "US"
            },
            {
                "name": "Conehatta",
                "countryCode": "US"
            },
            {
                "name": "Copiah County",
                "countryCode": "US"
            },
            {
                "name": "Corinth",
                "countryCode": "US"
            },
            {
                "name": "Covington County",
                "countryCode": "US"
            },
            {
                "name": "Crystal Springs",
                "countryCode": "US"
            },
            {
                "name": "D'Iberville",
                "countryCode": "US"
            },
            {
                "name": "De Kalb",
                "countryCode": "US"
            },
            {
                "name": "De Lisle",
                "countryCode": "US"
            },
            {
                "name": "De Soto County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Derma",
                "countryCode": "US"
            },
            {
                "name": "Diamondhead",
                "countryCode": "US"
            },
            {
                "name": "Drew",
                "countryCode": "US"
            },
            {
                "name": "Duck Hill",
                "countryCode": "US"
            },
            {
                "name": "Durant",
                "countryCode": "US"
            },
            {
                "name": "Edwards",
                "countryCode": "US"
            },
            {
                "name": "Ellisville",
                "countryCode": "US"
            },
            {
                "name": "Escatawpa",
                "countryCode": "US"
            },
            {
                "name": "Eupora",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Flora",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Flowood",
                "countryCode": "US"
            },
            {
                "name": "Forest",
                "countryCode": "US"
            },
            {
                "name": "Forrest County",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Friars Point",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Gautier",
                "countryCode": "US"
            },
            {
                "name": "George County",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Goodman",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Grenada",
                "countryCode": "US"
            },
            {
                "name": "Grenada County",
                "countryCode": "US"
            },
            {
                "name": "Gulf Hills",
                "countryCode": "US"
            },
            {
                "name": "Gulf Park Estates",
                "countryCode": "US"
            },
            {
                "name": "Gulfport",
                "countryCode": "US"
            },
            {
                "name": "Guntown",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Hattiesburg",
                "countryCode": "US"
            },
            {
                "name": "Hazlehurst",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Hernando",
                "countryCode": "US"
            },
            {
                "name": "Hickory Hills",
                "countryCode": "US"
            },
            {
                "name": "Hide-A-Way Lake",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hinds County",
                "countryCode": "US"
            },
            {
                "name": "Hollandale",
                "countryCode": "US"
            },
            {
                "name": "Holly Springs",
                "countryCode": "US"
            },
            {
                "name": "Holmes County",
                "countryCode": "US"
            },
            {
                "name": "Horn Lake",
                "countryCode": "US"
            },
            {
                "name": "Houston",
                "countryCode": "US"
            },
            {
                "name": "Humphreys County",
                "countryCode": "US"
            },
            {
                "name": "Hurley",
                "countryCode": "US"
            },
            {
                "name": "Indianola",
                "countryCode": "US"
            },
            {
                "name": "Issaquena County",
                "countryCode": "US"
            },
            {
                "name": "Itawamba County",
                "countryCode": "US"
            },
            {
                "name": "Itta Bena",
                "countryCode": "US"
            },
            {
                "name": "Iuka",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Davis County",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Jonestown",
                "countryCode": "US"
            },
            {
                "name": "Kearney Park",
                "countryCode": "US"
            },
            {
                "name": "Kemper County",
                "countryCode": "US"
            },
            {
                "name": "Kiln",
                "countryCode": "US"
            },
            {
                "name": "Kosciusko",
                "countryCode": "US"
            },
            {
                "name": "Lafayette County",
                "countryCode": "US"
            },
            {
                "name": "Lamar County",
                "countryCode": "US"
            },
            {
                "name": "Lambert",
                "countryCode": "US"
            },
            {
                "name": "Latimer",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale County",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Leake County",
                "countryCode": "US"
            },
            {
                "name": "Leakesville",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leflore County",
                "countryCode": "US"
            },
            {
                "name": "Leland",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Lowndes County",
                "countryCode": "US"
            },
            {
                "name": "Lucedale",
                "countryCode": "US"
            },
            {
                "name": "Lumberton",
                "countryCode": "US"
            },
            {
                "name": "Lyman",
                "countryCode": "US"
            },
            {
                "name": "Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "Macon",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Magee",
                "countryCode": "US"
            },
            {
                "name": "Magnolia",
                "countryCode": "US"
            },
            {
                "name": "Mantachie",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marks",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Mayersville",
                "countryCode": "US"
            },
            {
                "name": "McComb",
                "countryCode": "US"
            },
            {
                "name": "Meadville",
                "countryCode": "US"
            },
            {
                "name": "Mendenhall",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Meridian Station",
                "countryCode": "US"
            },
            {
                "name": "Metcalfe",
                "countryCode": "US"
            },
            {
                "name": "Mikoma",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Moorhead",
                "countryCode": "US"
            },
            {
                "name": "Morgantown",
                "countryCode": "US"
            },
            {
                "name": "Morton",
                "countryCode": "US"
            },
            {
                "name": "Moss Point",
                "countryCode": "US"
            },
            {
                "name": "Mound Bayou",
                "countryCode": "US"
            },
            {
                "name": "Natchez",
                "countryCode": "US"
            },
            {
                "name": "Nellieburg",
                "countryCode": "US"
            },
            {
                "name": "Neshoba County",
                "countryCode": "US"
            },
            {
                "name": "Nettleton",
                "countryCode": "US"
            },
            {
                "name": "New Albany",
                "countryCode": "US"
            },
            {
                "name": "New Augusta",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "Nicholson",
                "countryCode": "US"
            },
            {
                "name": "North Tunica",
                "countryCode": "US"
            },
            {
                "name": "Noxubee County",
                "countryCode": "US"
            },
            {
                "name": "Ocean Springs",
                "countryCode": "US"
            },
            {
                "name": "Okolona",
                "countryCode": "US"
            },
            {
                "name": "Oktibbeha County",
                "countryCode": "US"
            },
            {
                "name": "Olive Branch",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Panola County",
                "countryCode": "US"
            },
            {
                "name": "Pascagoula",
                "countryCode": "US"
            },
            {
                "name": "Pass Christian",
                "countryCode": "US"
            },
            {
                "name": "Pearl",
                "countryCode": "US"
            },
            {
                "name": "Pearl River",
                "countryCode": "US"
            },
            {
                "name": "Pearl River County",
                "countryCode": "US"
            },
            {
                "name": "Pearlington",
                "countryCode": "US"
            },
            {
                "name": "Pelahatchie",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Petal",
                "countryCode": "US"
            },
            {
                "name": "Philadelphia",
                "countryCode": "US"
            },
            {
                "name": "Picayune",
                "countryCode": "US"
            },
            {
                "name": "Pickens",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pittsboro",
                "countryCode": "US"
            },
            {
                "name": "Plantersville",
                "countryCode": "US"
            },
            {
                "name": "Pontotoc",
                "countryCode": "US"
            },
            {
                "name": "Pontotoc County",
                "countryCode": "US"
            },
            {
                "name": "Poplarville",
                "countryCode": "US"
            },
            {
                "name": "Port Gibson",
                "countryCode": "US"
            },
            {
                "name": "Prentiss",
                "countryCode": "US"
            },
            {
                "name": "Prentiss County",
                "countryCode": "US"
            },
            {
                "name": "Purvis",
                "countryCode": "US"
            },
            {
                "name": "Quitman",
                "countryCode": "US"
            },
            {
                "name": "Quitman County",
                "countryCode": "US"
            },
            {
                "name": "Raleigh",
                "countryCode": "US"
            },
            {
                "name": "Rankin County",
                "countryCode": "US"
            },
            {
                "name": "Rawls Springs",
                "countryCode": "US"
            },
            {
                "name": "Raymond",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Richton",
                "countryCode": "US"
            },
            {
                "name": "Ridgeland",
                "countryCode": "US"
            },
            {
                "name": "Ripley",
                "countryCode": "US"
            },
            {
                "name": "Rolling Fork",
                "countryCode": "US"
            },
            {
                "name": "Rosedale",
                "countryCode": "US"
            },
            {
                "name": "Ruleville",
                "countryCode": "US"
            },
            {
                "name": "Saint Martin",
                "countryCode": "US"
            },
            {
                "name": "Saltillo",
                "countryCode": "US"
            },
            {
                "name": "Sardis",
                "countryCode": "US"
            },
            {
                "name": "Saucier",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Senatobia",
                "countryCode": "US"
            },
            {
                "name": "Shannon",
                "countryCode": "US"
            },
            {
                "name": "Sharkey County",
                "countryCode": "US"
            },
            {
                "name": "Sharon",
                "countryCode": "US"
            },
            {
                "name": "Shaw",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Simpson County",
                "countryCode": "US"
            },
            {
                "name": "Smith County",
                "countryCode": "US"
            },
            {
                "name": "Southaven",
                "countryCode": "US"
            },
            {
                "name": "Starkville",
                "countryCode": "US"
            },
            {
                "name": "Stone County",
                "countryCode": "US"
            },
            {
                "name": "Stonewall",
                "countryCode": "US"
            },
            {
                "name": "Summit",
                "countryCode": "US"
            },
            {
                "name": "Sumrall",
                "countryCode": "US"
            },
            {
                "name": "Sunflower",
                "countryCode": "US"
            },
            {
                "name": "Sunflower County",
                "countryCode": "US"
            },
            {
                "name": "Tallahatchie County",
                "countryCode": "US"
            },
            {
                "name": "Tate County",
                "countryCode": "US"
            },
            {
                "name": "Taylorsville",
                "countryCode": "US"
            },
            {
                "name": "Tchula",
                "countryCode": "US"
            },
            {
                "name": "Terry",
                "countryCode": "US"
            },
            {
                "name": "Tippah County",
                "countryCode": "US"
            },
            {
                "name": "Tishomingo County",
                "countryCode": "US"
            },
            {
                "name": "Tunica",
                "countryCode": "US"
            },
            {
                "name": "Tunica County",
                "countryCode": "US"
            },
            {
                "name": "Tunica Resorts",
                "countryCode": "US"
            },
            {
                "name": "Tupelo",
                "countryCode": "US"
            },
            {
                "name": "Tutwiler",
                "countryCode": "US"
            },
            {
                "name": "Tylertown",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "University",
                "countryCode": "US"
            },
            {
                "name": "Vancleave",
                "countryCode": "US"
            },
            {
                "name": "Vardaman",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Vicksburg",
                "countryCode": "US"
            },
            {
                "name": "Wade",
                "countryCode": "US"
            },
            {
                "name": "Walls",
                "countryCode": "US"
            },
            {
                "name": "Walnut Grove",
                "countryCode": "US"
            },
            {
                "name": "Walthall",
                "countryCode": "US"
            },
            {
                "name": "Walthall County",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Water Valley",
                "countryCode": "US"
            },
            {
                "name": "Waveland",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "Wesson",
                "countryCode": "US"
            },
            {
                "name": "West Gulfport",
                "countryCode": "US"
            },
            {
                "name": "West Hattiesburg",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "Wiggins",
                "countryCode": "US"
            },
            {
                "name": "Wilkinson County",
                "countryCode": "US"
            },
            {
                "name": "Winona",
                "countryCode": "US"
            },
            {
                "name": "Winston County",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Yalobusha County",
                "countryCode": "US"
            },
            {
                "name": "Yazoo City",
                "countryCode": "US"
            },
            {
                "name": "Yazoo County",
                "countryCode": "US"
            },
            {
                "name": "Absarokee",
                "countryCode": "US"
            },
            {
                "name": "Anaconda",
                "countryCode": "US"
            },
            {
                "name": "Baker",
                "countryCode": "US"
            },
            {
                "name": "Beaverhead County",
                "countryCode": "US"
            },
            {
                "name": "Belgrade",
                "countryCode": "US"
            },
            {
                "name": "Big Horn County",
                "countryCode": "US"
            },
            {
                "name": "Big Sky",
                "countryCode": "US"
            },
            {
                "name": "Big Timber",
                "countryCode": "US"
            },
            {
                "name": "Bigfork",
                "countryCode": "US"
            },
            {
                "name": "Billings",
                "countryCode": "US"
            },
            {
                "name": "Blaine County",
                "countryCode": "US"
            },
            {
                "name": "Bonner-West Riverside",
                "countryCode": "US"
            },
            {
                "name": "Boulder",
                "countryCode": "US"
            },
            {
                "name": "Bozeman",
                "countryCode": "US"
            },
            {
                "name": "Broadus",
                "countryCode": "US"
            },
            {
                "name": "Broadwater County",
                "countryCode": "US"
            },
            {
                "name": "Browning",
                "countryCode": "US"
            },
            {
                "name": "Butte",
                "countryCode": "US"
            },
            {
                "name": "Butte-Silver Bow (Balance)",
                "countryCode": "US"
            },
            {
                "name": "Carbon County",
                "countryCode": "US"
            },
            {
                "name": "Carter County",
                "countryCode": "US"
            },
            {
                "name": "Cascade County",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chinook",
                "countryCode": "US"
            },
            {
                "name": "Choteau",
                "countryCode": "US"
            },
            {
                "name": "Chouteau County",
                "countryCode": "US"
            },
            {
                "name": "Circle",
                "countryCode": "US"
            },
            {
                "name": "Clancy",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Colstrip",
                "countryCode": "US"
            },
            {
                "name": "Columbia Falls",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Conrad",
                "countryCode": "US"
            },
            {
                "name": "Crow Agency",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Cut Bank",
                "countryCode": "US"
            },
            {
                "name": "Daniels County",
                "countryCode": "US"
            },
            {
                "name": "Dawson County",
                "countryCode": "US"
            },
            {
                "name": "Deer Lodge",
                "countryCode": "US"
            },
            {
                "name": "Deer Lodge County",
                "countryCode": "US"
            },
            {
                "name": "Dillon",
                "countryCode": "US"
            },
            {
                "name": "East Helena",
                "countryCode": "US"
            },
            {
                "name": "East Missoula",
                "countryCode": "US"
            },
            {
                "name": "Ekalaka",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Evergreen",
                "countryCode": "US"
            },
            {
                "name": "Fallon County",
                "countryCode": "US"
            },
            {
                "name": "Fergus County",
                "countryCode": "US"
            },
            {
                "name": "Flathead County",
                "countryCode": "US"
            },
            {
                "name": "Forsyth",
                "countryCode": "US"
            },
            {
                "name": "Fort Belknap Agency",
                "countryCode": "US"
            },
            {
                "name": "Fort Benton",
                "countryCode": "US"
            },
            {
                "name": "Four Corners",
                "countryCode": "US"
            },
            {
                "name": "Frenchtown",
                "countryCode": "US"
            },
            {
                "name": "Gallatin County",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Glacier County",
                "countryCode": "US"
            },
            {
                "name": "Glasgow",
                "countryCode": "US"
            },
            {
                "name": "Glendive",
                "countryCode": "US"
            },
            {
                "name": "Golden Valley County",
                "countryCode": "US"
            },
            {
                "name": "Granite County",
                "countryCode": "US"
            },
            {
                "name": "Great Falls",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hardin",
                "countryCode": "US"
            },
            {
                "name": "Harlowton",
                "countryCode": "US"
            },
            {
                "name": "Havre",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Helena Valley Northeast",
                "countryCode": "US"
            },
            {
                "name": "Helena Valley Northwest",
                "countryCode": "US"
            },
            {
                "name": "Helena Valley Southeast",
                "countryCode": "US"
            },
            {
                "name": "Helena Valley West Central",
                "countryCode": "US"
            },
            {
                "name": "Helena West Side",
                "countryCode": "US"
            },
            {
                "name": "Hill County",
                "countryCode": "US"
            },
            {
                "name": "Hysham",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jordan",
                "countryCode": "US"
            },
            {
                "name": "Judith Basin County",
                "countryCode": "US"
            },
            {
                "name": "Kalispell",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lame Deer",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Lewis and Clark County",
                "countryCode": "US"
            },
            {
                "name": "Lewistown",
                "countryCode": "US"
            },
            {
                "name": "Libby",
                "countryCode": "US"
            },
            {
                "name": "Liberty County",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Lockwood",
                "countryCode": "US"
            },
            {
                "name": "Lolo",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Malmstrom Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Malta",
                "countryCode": "US"
            },
            {
                "name": "Manhattan",
                "countryCode": "US"
            },
            {
                "name": "McCone County",
                "countryCode": "US"
            },
            {
                "name": "Meagher County",
                "countryCode": "US"
            },
            {
                "name": "Miles City",
                "countryCode": "US"
            },
            {
                "name": "Mineral County",
                "countryCode": "US"
            },
            {
                "name": "Missoula",
                "countryCode": "US"
            },
            {
                "name": "Missoula County",
                "countryCode": "US"
            },
            {
                "name": "Montana City",
                "countryCode": "US"
            },
            {
                "name": "Musselshell County",
                "countryCode": "US"
            },
            {
                "name": "North Browning",
                "countryCode": "US"
            },
            {
                "name": "Orchard Homes",
                "countryCode": "US"
            },
            {
                "name": "Pablo",
                "countryCode": "US"
            },
            {
                "name": "Park County",
                "countryCode": "US"
            },
            {
                "name": "Petroleum County",
                "countryCode": "US"
            },
            {
                "name": "Philipsburg",
                "countryCode": "US"
            },
            {
                "name": "Phillips County",
                "countryCode": "US"
            },
            {
                "name": "Plains",
                "countryCode": "US"
            },
            {
                "name": "Plentywood",
                "countryCode": "US"
            },
            {
                "name": "Polson",
                "countryCode": "US"
            },
            {
                "name": "Pondera County",
                "countryCode": "US"
            },
            {
                "name": "Powder River County",
                "countryCode": "US"
            },
            {
                "name": "Powell County",
                "countryCode": "US"
            },
            {
                "name": "Prairie County",
                "countryCode": "US"
            },
            {
                "name": "Ravalli County",
                "countryCode": "US"
            },
            {
                "name": "Red Lodge",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Ronan",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt County",
                "countryCode": "US"
            },
            {
                "name": "Rosebud County",
                "countryCode": "US"
            },
            {
                "name": "Roundup",
                "countryCode": "US"
            },
            {
                "name": "Ryegate",
                "countryCode": "US"
            },
            {
                "name": "Sanders County",
                "countryCode": "US"
            },
            {
                "name": "Scobey",
                "countryCode": "US"
            },
            {
                "name": "Seeley Lake",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Sheridan County",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Silver Bow County",
                "countryCode": "US"
            },
            {
                "name": "Somers",
                "countryCode": "US"
            },
            {
                "name": "South Browning",
                "countryCode": "US"
            },
            {
                "name": "Stanford",
                "countryCode": "US"
            },
            {
                "name": "Stevensville",
                "countryCode": "US"
            },
            {
                "name": "Stillwater County",
                "countryCode": "US"
            },
            {
                "name": "Sun Prairie",
                "countryCode": "US"
            },
            {
                "name": "Superior",
                "countryCode": "US"
            },
            {
                "name": "Sweet Grass County",
                "countryCode": "US"
            },
            {
                "name": "Terry",
                "countryCode": "US"
            },
            {
                "name": "Teton County",
                "countryCode": "US"
            },
            {
                "name": "Thompson Falls",
                "countryCode": "US"
            },
            {
                "name": "Three Forks",
                "countryCode": "US"
            },
            {
                "name": "Toole County",
                "countryCode": "US"
            },
            {
                "name": "Townsend",
                "countryCode": "US"
            },
            {
                "name": "Treasure County",
                "countryCode": "US"
            },
            {
                "name": "Valley County",
                "countryCode": "US"
            },
            {
                "name": "Virginia City",
                "countryCode": "US"
            },
            {
                "name": "Warm Springs",
                "countryCode": "US"
            },
            {
                "name": "West Glendive",
                "countryCode": "US"
            },
            {
                "name": "West Yellowstone",
                "countryCode": "US"
            },
            {
                "name": "Wheatland County",
                "countryCode": "US"
            },
            {
                "name": "White Sulphur Springs",
                "countryCode": "US"
            },
            {
                "name": "Whitefish",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Wibaux",
                "countryCode": "US"
            },
            {
                "name": "Wibaux County",
                "countryCode": "US"
            },
            {
                "name": "Winnett",
                "countryCode": "US"
            },
            {
                "name": "Wolf Point",
                "countryCode": "US"
            },
            {
                "name": "Yellowstone County",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Advance",
                "countryCode": "US"
            },
            {
                "name": "Ahoskie",
                "countryCode": "US"
            },
            {
                "name": "Alamance County",
                "countryCode": "US"
            },
            {
                "name": "Albemarle",
                "countryCode": "US"
            },
            {
                "name": "Alexander County",
                "countryCode": "US"
            },
            {
                "name": "Alleghany County",
                "countryCode": "US"
            },
            {
                "name": "Andrews",
                "countryCode": "US"
            },
            {
                "name": "Angier",
                "countryCode": "US"
            },
            {
                "name": "Anson County",
                "countryCode": "US"
            },
            {
                "name": "Apex",
                "countryCode": "US"
            },
            {
                "name": "Archdale",
                "countryCode": "US"
            },
            {
                "name": "Archer Lodge",
                "countryCode": "US"
            },
            {
                "name": "Ashe County",
                "countryCode": "US"
            },
            {
                "name": "Asheboro",
                "countryCode": "US"
            },
            {
                "name": "Asheville",
                "countryCode": "US"
            },
            {
                "name": "Atlantic Beach",
                "countryCode": "US"
            },
            {
                "name": "Avery County",
                "countryCode": "US"
            },
            {
                "name": "Avery Creek",
                "countryCode": "US"
            },
            {
                "name": "Ayden",
                "countryCode": "US"
            },
            {
                "name": "Badin",
                "countryCode": "US"
            },
            {
                "name": "Bakersville",
                "countryCode": "US"
            },
            {
                "name": "Balfour",
                "countryCode": "US"
            },
            {
                "name": "Banner Elk",
                "countryCode": "US"
            },
            {
                "name": "Barker Heights",
                "countryCode": "US"
            },
            {
                "name": "Bayboro",
                "countryCode": "US"
            },
            {
                "name": "Bayshore",
                "countryCode": "US"
            },
            {
                "name": "Beaufort",
                "countryCode": "US"
            },
            {
                "name": "Beaufort County",
                "countryCode": "US"
            },
            {
                "name": "Belhaven",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Belville",
                "countryCode": "US"
            },
            {
                "name": "Benson",
                "countryCode": "US"
            },
            {
                "name": "Bent Creek",
                "countryCode": "US"
            },
            {
                "name": "Bermuda Run",
                "countryCode": "US"
            },
            {
                "name": "Bertie County",
                "countryCode": "US"
            },
            {
                "name": "Bessemer City",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Bethlehem",
                "countryCode": "US"
            },
            {
                "name": "Beulaville",
                "countryCode": "US"
            },
            {
                "name": "Biltmore Forest",
                "countryCode": "US"
            },
            {
                "name": "Biscoe",
                "countryCode": "US"
            },
            {
                "name": "Black Mountain",
                "countryCode": "US"
            },
            {
                "name": "Bladen County",
                "countryCode": "US"
            },
            {
                "name": "Bladenboro",
                "countryCode": "US"
            },
            {
                "name": "Blowing Rock",
                "countryCode": "US"
            },
            {
                "name": "Boiling Spring Lakes",
                "countryCode": "US"
            },
            {
                "name": "Boiling Springs",
                "countryCode": "US"
            },
            {
                "name": "Bolivia",
                "countryCode": "US"
            },
            {
                "name": "Boone",
                "countryCode": "US"
            },
            {
                "name": "Boonville",
                "countryCode": "US"
            },
            {
                "name": "Brevard",
                "countryCode": "US"
            },
            {
                "name": "Brices Creek",
                "countryCode": "US"
            },
            {
                "name": "Broad Creek",
                "countryCode": "US"
            },
            {
                "name": "Broadway",
                "countryCode": "US"
            },
            {
                "name": "Brogden",
                "countryCode": "US"
            },
            {
                "name": "Brunswick",
                "countryCode": "US"
            },
            {
                "name": "Brunswick County",
                "countryCode": "US"
            },
            {
                "name": "Bryson City",
                "countryCode": "US"
            },
            {
                "name": "Buies Creek",
                "countryCode": "US"
            },
            {
                "name": "Buncombe County",
                "countryCode": "US"
            },
            {
                "name": "Burgaw",
                "countryCode": "US"
            },
            {
                "name": "Burke County",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Burnsville",
                "countryCode": "US"
            },
            {
                "name": "Butner",
                "countryCode": "US"
            },
            {
                "name": "Buxton",
                "countryCode": "US"
            },
            {
                "name": "Cabarrus County",
                "countryCode": "US"
            },
            {
                "name": "Cajahs Mountain",
                "countryCode": "US"
            },
            {
                "name": "Calabash",
                "countryCode": "US"
            },
            {
                "name": "Caldwell County",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Camden County",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Cape Carteret",
                "countryCode": "US"
            },
            {
                "name": "Carolina Beach",
                "countryCode": "US"
            },
            {
                "name": "Carolina Shores",
                "countryCode": "US"
            },
            {
                "name": "Carrboro",
                "countryCode": "US"
            },
            {
                "name": "Carteret County",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Cary",
                "countryCode": "US"
            },
            {
                "name": "Castle Hayne",
                "countryCode": "US"
            },
            {
                "name": "Caswell County",
                "countryCode": "US"
            },
            {
                "name": "Catawba County",
                "countryCode": "US"
            },
            {
                "name": "Cedar Point",
                "countryCode": "US"
            },
            {
                "name": "Chadbourn",
                "countryCode": "US"
            },
            {
                "name": "Chapel Hill",
                "countryCode": "US"
            },
            {
                "name": "Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Chatham County",
                "countryCode": "US"
            },
            {
                "name": "Cherokee",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Cherryville",
                "countryCode": "US"
            },
            {
                "name": "China Grove",
                "countryCode": "US"
            },
            {
                "name": "Chowan County",
                "countryCode": "US"
            },
            {
                "name": "Claremont",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clemmons",
                "countryCode": "US"
            },
            {
                "name": "Cleveland County",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clyde",
                "countryCode": "US"
            },
            {
                "name": "Coats",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Columbus County",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Connelly Springs",
                "countryCode": "US"
            },
            {
                "name": "Conover",
                "countryCode": "US"
            },
            {
                "name": "Cordova",
                "countryCode": "US"
            },
            {
                "name": "Cornelius",
                "countryCode": "US"
            },
            {
                "name": "Cove Creek",
                "countryCode": "US"
            },
            {
                "name": "Cramerton",
                "countryCode": "US"
            },
            {
                "name": "Craven County",
                "countryCode": "US"
            },
            {
                "name": "Creedmoor",
                "countryCode": "US"
            },
            {
                "name": "Cricket",
                "countryCode": "US"
            },
            {
                "name": "Cullowhee",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Currituck",
                "countryCode": "US"
            },
            {
                "name": "Currituck County",
                "countryCode": "US"
            },
            {
                "name": "Dallas",
                "countryCode": "US"
            },
            {
                "name": "Dana",
                "countryCode": "US"
            },
            {
                "name": "Danbury",
                "countryCode": "US"
            },
            {
                "name": "Dare County",
                "countryCode": "US"
            },
            {
                "name": "Davidson",
                "countryCode": "US"
            },
            {
                "name": "Davidson County",
                "countryCode": "US"
            },
            {
                "name": "Davie County",
                "countryCode": "US"
            },
            {
                "name": "Denton",
                "countryCode": "US"
            },
            {
                "name": "Denver",
                "countryCode": "US"
            },
            {
                "name": "Dobson",
                "countryCode": "US"
            },
            {
                "name": "Drexel",
                "countryCode": "US"
            },
            {
                "name": "Dunn",
                "countryCode": "US"
            },
            {
                "name": "Duplin County",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "Durham County",
                "countryCode": "US"
            },
            {
                "name": "East Flat Rock",
                "countryCode": "US"
            },
            {
                "name": "East Rockingham",
                "countryCode": "US"
            },
            {
                "name": "East Spencer",
                "countryCode": "US"
            },
            {
                "name": "Eastover",
                "countryCode": "US"
            },
            {
                "name": "Eden",
                "countryCode": "US"
            },
            {
                "name": "Edenton",
                "countryCode": "US"
            },
            {
                "name": "Edgecombe County",
                "countryCode": "US"
            },
            {
                "name": "Edneyville",
                "countryCode": "US"
            },
            {
                "name": "Elizabeth City",
                "countryCode": "US"
            },
            {
                "name": "Elizabethtown",
                "countryCode": "US"
            },
            {
                "name": "Elkin",
                "countryCode": "US"
            },
            {
                "name": "Ellerbe",
                "countryCode": "US"
            },
            {
                "name": "Elm City",
                "countryCode": "US"
            },
            {
                "name": "Elon",
                "countryCode": "US"
            },
            {
                "name": "Elroy",
                "countryCode": "US"
            },
            {
                "name": "Emerald Isle",
                "countryCode": "US"
            },
            {
                "name": "Enfield",
                "countryCode": "US"
            },
            {
                "name": "Enochville",
                "countryCode": "US"
            },
            {
                "name": "Erwin",
                "countryCode": "US"
            },
            {
                "name": "Etowah",
                "countryCode": "US"
            },
            {
                "name": "Fairfield Harbour",
                "countryCode": "US"
            },
            {
                "name": "Fairmont",
                "countryCode": "US"
            },
            {
                "name": "Fairplains",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Farmville",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Fearrington Village",
                "countryCode": "US"
            },
            {
                "name": "Flat Rock",
                "countryCode": "US"
            },
            {
                "name": "Fletcher",
                "countryCode": "US"
            },
            {
                "name": "Forest City",
                "countryCode": "US"
            },
            {
                "name": "Forest Oaks",
                "countryCode": "US"
            },
            {
                "name": "Forsyth County",
                "countryCode": "US"
            },
            {
                "name": "Fort Bragg",
                "countryCode": "US"
            },
            {
                "name": "Foscoe",
                "countryCode": "US"
            },
            {
                "name": "Four Oaks",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklinton",
                "countryCode": "US"
            },
            {
                "name": "Franklinville",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "Fruitland",
                "countryCode": "US"
            },
            {
                "name": "Fuquay-Varina",
                "countryCode": "US"
            },
            {
                "name": "Gamewell",
                "countryCode": "US"
            },
            {
                "name": "Garner",
                "countryCode": "US"
            },
            {
                "name": "Gaston",
                "countryCode": "US"
            },
            {
                "name": "Gaston County",
                "countryCode": "US"
            },
            {
                "name": "Gastonia",
                "countryCode": "US"
            },
            {
                "name": "Gates County",
                "countryCode": "US"
            },
            {
                "name": "Gatesville",
                "countryCode": "US"
            },
            {
                "name": "Gibsonville",
                "countryCode": "US"
            },
            {
                "name": "Glen Alpine",
                "countryCode": "US"
            },
            {
                "name": "Glen Raven",
                "countryCode": "US"
            },
            {
                "name": "Goldsboro",
                "countryCode": "US"
            },
            {
                "name": "Gorman",
                "countryCode": "US"
            },
            {
                "name": "Graham",
                "countryCode": "US"
            },
            {
                "name": "Graham County",
                "countryCode": "US"
            },
            {
                "name": "Granite Falls",
                "countryCode": "US"
            },
            {
                "name": "Granite Quarry",
                "countryCode": "US"
            },
            {
                "name": "Granville County",
                "countryCode": "US"
            },
            {
                "name": "Green Level",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greensboro",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Grifton",
                "countryCode": "US"
            },
            {
                "name": "Guilford County",
                "countryCode": "US"
            },
            {
                "name": "Half Moon",
                "countryCode": "US"
            },
            {
                "name": "Halifax",
                "countryCode": "US"
            },
            {
                "name": "Halifax County",
                "countryCode": "US"
            },
            {
                "name": "Hamlet",
                "countryCode": "US"
            },
            {
                "name": "Hampstead",
                "countryCode": "US"
            },
            {
                "name": "Harkers Island",
                "countryCode": "US"
            },
            {
                "name": "Harnett County",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Havelock",
                "countryCode": "US"
            },
            {
                "name": "Haw River",
                "countryCode": "US"
            },
            {
                "name": "Hayesville",
                "countryCode": "US"
            },
            {
                "name": "Hays",
                "countryCode": "US"
            },
            {
                "name": "Haywood County",
                "countryCode": "US"
            },
            {
                "name": "Hazelwood",
                "countryCode": "US"
            },
            {
                "name": "Hemby Bridge",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Henderson County",
                "countryCode": "US"
            },
            {
                "name": "Hendersonville",
                "countryCode": "US"
            },
            {
                "name": "Hertford",
                "countryCode": "US"
            },
            {
                "name": "Hertford County",
                "countryCode": "US"
            },
            {
                "name": "Hickory",
                "countryCode": "US"
            },
            {
                "name": "High Point",
                "countryCode": "US"
            },
            {
                "name": "Hildebran",
                "countryCode": "US"
            },
            {
                "name": "Hillsborough",
                "countryCode": "US"
            },
            {
                "name": "Hoke County",
                "countryCode": "US"
            },
            {
                "name": "Holly Ridge",
                "countryCode": "US"
            },
            {
                "name": "Holly Springs",
                "countryCode": "US"
            },
            {
                "name": "Hoopers Creek",
                "countryCode": "US"
            },
            {
                "name": "Hope Mills",
                "countryCode": "US"
            },
            {
                "name": "Horse Shoe",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Huntersville",
                "countryCode": "US"
            },
            {
                "name": "Hyde County",
                "countryCode": "US"
            },
            {
                "name": "Icard",
                "countryCode": "US"
            },
            {
                "name": "Indian Trail",
                "countryCode": "US"
            },
            {
                "name": "Iredell County",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "James City",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Johnston County",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Jonesville",
                "countryCode": "US"
            },
            {
                "name": "Kannapolis",
                "countryCode": "US"
            },
            {
                "name": "Kenansville",
                "countryCode": "US"
            },
            {
                "name": "Kenly",
                "countryCode": "US"
            },
            {
                "name": "Kernersville",
                "countryCode": "US"
            },
            {
                "name": "Kill Devil Hills",
                "countryCode": "US"
            },
            {
                "name": "King",
                "countryCode": "US"
            },
            {
                "name": "Kings Grant",
                "countryCode": "US"
            },
            {
                "name": "Kings Mountain",
                "countryCode": "US"
            },
            {
                "name": "Kinston",
                "countryCode": "US"
            },
            {
                "name": "Kitty Hawk",
                "countryCode": "US"
            },
            {
                "name": "Knightdale",
                "countryCode": "US"
            },
            {
                "name": "Kure Beach",
                "countryCode": "US"
            },
            {
                "name": "La Grange",
                "countryCode": "US"
            },
            {
                "name": "Lake Junaluska",
                "countryCode": "US"
            },
            {
                "name": "Lake Lure",
                "countryCode": "US"
            },
            {
                "name": "Lake Norman of Catawba",
                "countryCode": "US"
            },
            {
                "name": "Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Lake Waccamaw",
                "countryCode": "US"
            },
            {
                "name": "Landis",
                "countryCode": "US"
            },
            {
                "name": "Laurel Hill",
                "countryCode": "US"
            },
            {
                "name": "Laurel Park",
                "countryCode": "US"
            },
            {
                "name": "Laurinburg",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leland",
                "countryCode": "US"
            },
            {
                "name": "Lenoir",
                "countryCode": "US"
            },
            {
                "name": "Lenoir County",
                "countryCode": "US"
            },
            {
                "name": "Lewisville",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lillington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lincolnton",
                "countryCode": "US"
            },
            {
                "name": "Locust",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Longview",
                "countryCode": "US"
            },
            {
                "name": "Louisburg",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Lowesville",
                "countryCode": "US"
            },
            {
                "name": "Lucama",
                "countryCode": "US"
            },
            {
                "name": "Lumberton",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Maggie Valley",
                "countryCode": "US"
            },
            {
                "name": "Maiden",
                "countryCode": "US"
            },
            {
                "name": "Manteo",
                "countryCode": "US"
            },
            {
                "name": "Mar-Mac",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Mars Hill",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marshville",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Marvin",
                "countryCode": "US"
            },
            {
                "name": "Masonboro",
                "countryCode": "US"
            },
            {
                "name": "Matthews",
                "countryCode": "US"
            },
            {
                "name": "Maury",
                "countryCode": "US"
            },
            {
                "name": "Maxton",
                "countryCode": "US"
            },
            {
                "name": "Mayodan",
                "countryCode": "US"
            },
            {
                "name": "Maysville",
                "countryCode": "US"
            },
            {
                "name": "McDowell County",
                "countryCode": "US"
            },
            {
                "name": "McLeansville",
                "countryCode": "US"
            },
            {
                "name": "Mebane",
                "countryCode": "US"
            },
            {
                "name": "Mecklenburg County",
                "countryCode": "US"
            },
            {
                "name": "Midland",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Millers Creek",
                "countryCode": "US"
            },
            {
                "name": "Mills River",
                "countryCode": "US"
            },
            {
                "name": "Mineral Springs",
                "countryCode": "US"
            },
            {
                "name": "Mint Hill",
                "countryCode": "US"
            },
            {
                "name": "Mitchell County",
                "countryCode": "US"
            },
            {
                "name": "Mocksville",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Moore County",
                "countryCode": "US"
            },
            {
                "name": "Mooresville",
                "countryCode": "US"
            },
            {
                "name": "Moravian Falls",
                "countryCode": "US"
            },
            {
                "name": "Morehead City",
                "countryCode": "US"
            },
            {
                "name": "Morganton",
                "countryCode": "US"
            },
            {
                "name": "Morrisville",
                "countryCode": "US"
            },
            {
                "name": "Mount Airy",
                "countryCode": "US"
            },
            {
                "name": "Mount Gilead",
                "countryCode": "US"
            },
            {
                "name": "Mount Holly",
                "countryCode": "US"
            },
            {
                "name": "Mount Olive",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mountain Home",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Moyock",
                "countryCode": "US"
            },
            {
                "name": "Mulberry",
                "countryCode": "US"
            },
            {
                "name": "Murfreesboro",
                "countryCode": "US"
            },
            {
                "name": "Murphy",
                "countryCode": "US"
            },
            {
                "name": "Murraysville",
                "countryCode": "US"
            },
            {
                "name": "Myrtle Grove",
                "countryCode": "US"
            },
            {
                "name": "Nags Head",
                "countryCode": "US"
            },
            {
                "name": "Nash County",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Navassa",
                "countryCode": "US"
            },
            {
                "name": "Neuse Forest",
                "countryCode": "US"
            },
            {
                "name": "New Bern",
                "countryCode": "US"
            },
            {
                "name": "New Hanover County",
                "countryCode": "US"
            },
            {
                "name": "Newland",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Norlina",
                "countryCode": "US"
            },
            {
                "name": "North Wilkesboro",
                "countryCode": "US"
            },
            {
                "name": "Northampton County",
                "countryCode": "US"
            },
            {
                "name": "Northchase",
                "countryCode": "US"
            },
            {
                "name": "Northlakes",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Oak Island",
                "countryCode": "US"
            },
            {
                "name": "Oak Ridge",
                "countryCode": "US"
            },
            {
                "name": "Oakboro",
                "countryCode": "US"
            },
            {
                "name": "Ogden",
                "countryCode": "US"
            },
            {
                "name": "Onslow County",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Pamlico County",
                "countryCode": "US"
            },
            {
                "name": "Pasquotank County",
                "countryCode": "US"
            },
            {
                "name": "Pembroke",
                "countryCode": "US"
            },
            {
                "name": "Pender County",
                "countryCode": "US"
            },
            {
                "name": "Perquimans County",
                "countryCode": "US"
            },
            {
                "name": "Person County",
                "countryCode": "US"
            },
            {
                "name": "Pilot Mountain",
                "countryCode": "US"
            },
            {
                "name": "Pine Knoll Shores",
                "countryCode": "US"
            },
            {
                "name": "Pine Level",
                "countryCode": "US"
            },
            {
                "name": "Pinebluff",
                "countryCode": "US"
            },
            {
                "name": "Pinehurst",
                "countryCode": "US"
            },
            {
                "name": "Pinetops",
                "countryCode": "US"
            },
            {
                "name": "Pineville",
                "countryCode": "US"
            },
            {
                "name": "Piney Green",
                "countryCode": "US"
            },
            {
                "name": "Pitt County",
                "countryCode": "US"
            },
            {
                "name": "Pittsboro",
                "countryCode": "US"
            },
            {
                "name": "Plain View",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Garden",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Polkton",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Princeville",
                "countryCode": "US"
            },
            {
                "name": "Pumpkin Center",
                "countryCode": "US"
            },
            {
                "name": "Raeford",
                "countryCode": "US"
            },
            {
                "name": "Raleigh",
                "countryCode": "US"
            },
            {
                "name": "Ramseur",
                "countryCode": "US"
            },
            {
                "name": "Randleman",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Ranlo",
                "countryCode": "US"
            },
            {
                "name": "Red Oak",
                "countryCode": "US"
            },
            {
                "name": "Red Springs",
                "countryCode": "US"
            },
            {
                "name": "Reidsville",
                "countryCode": "US"
            },
            {
                "name": "Rhodhiss",
                "countryCode": "US"
            },
            {
                "name": "Richlands",
                "countryCode": "US"
            },
            {
                "name": "Richmond County",
                "countryCode": "US"
            },
            {
                "name": "River Bend",
                "countryCode": "US"
            },
            {
                "name": "River Road",
                "countryCode": "US"
            },
            {
                "name": "Roanoke Rapids",
                "countryCode": "US"
            },
            {
                "name": "Robbins",
                "countryCode": "US"
            },
            {
                "name": "Robbinsville",
                "countryCode": "US"
            },
            {
                "name": "Robersonville",
                "countryCode": "US"
            },
            {
                "name": "Robeson County",
                "countryCode": "US"
            },
            {
                "name": "Rockfish",
                "countryCode": "US"
            },
            {
                "name": "Rockingham",
                "countryCode": "US"
            },
            {
                "name": "Rockingham County",
                "countryCode": "US"
            },
            {
                "name": "Rockwell",
                "countryCode": "US"
            },
            {
                "name": "Rocky Mount",
                "countryCode": "US"
            },
            {
                "name": "Rocky Point",
                "countryCode": "US"
            },
            {
                "name": "Rolesville",
                "countryCode": "US"
            },
            {
                "name": "Rose Hill",
                "countryCode": "US"
            },
            {
                "name": "Roseboro",
                "countryCode": "US"
            },
            {
                "name": "Rowan County",
                "countryCode": "US"
            },
            {
                "name": "Rowland",
                "countryCode": "US"
            },
            {
                "name": "Roxboro",
                "countryCode": "US"
            },
            {
                "name": "Royal Pines",
                "countryCode": "US"
            },
            {
                "name": "Rural Hall",
                "countryCode": "US"
            },
            {
                "name": "Rutherford College",
                "countryCode": "US"
            },
            {
                "name": "Rutherford County",
                "countryCode": "US"
            },
            {
                "name": "Rutherfordton",
                "countryCode": "US"
            },
            {
                "name": "Saint James",
                "countryCode": "US"
            },
            {
                "name": "Saint Pauls",
                "countryCode": "US"
            },
            {
                "name": "Saint Stephens",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Sampson County",
                "countryCode": "US"
            },
            {
                "name": "Sanford",
                "countryCode": "US"
            },
            {
                "name": "Sawmills",
                "countryCode": "US"
            },
            {
                "name": "Saxapahaw",
                "countryCode": "US"
            },
            {
                "name": "Scotland County",
                "countryCode": "US"
            },
            {
                "name": "Scotland Neck",
                "countryCode": "US"
            },
            {
                "name": "Scotts Mill",
                "countryCode": "US"
            },
            {
                "name": "Sea Breeze",
                "countryCode": "US"
            },
            {
                "name": "Seagate",
                "countryCode": "US"
            },
            {
                "name": "Selma",
                "countryCode": "US"
            },
            {
                "name": "Seven Lakes",
                "countryCode": "US"
            },
            {
                "name": "Shallotte",
                "countryCode": "US"
            },
            {
                "name": "Sharpsburg",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Sherrills Ford",
                "countryCode": "US"
            },
            {
                "name": "Siler City",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Skippers Corner",
                "countryCode": "US"
            },
            {
                "name": "Smithfield",
                "countryCode": "US"
            },
            {
                "name": "Sneads Ferry",
                "countryCode": "US"
            },
            {
                "name": "Snow Hill",
                "countryCode": "US"
            },
            {
                "name": "South Gastonia",
                "countryCode": "US"
            },
            {
                "name": "South Henderson",
                "countryCode": "US"
            },
            {
                "name": "South Rosemary",
                "countryCode": "US"
            },
            {
                "name": "Southern Pines",
                "countryCode": "US"
            },
            {
                "name": "Southern Shores",
                "countryCode": "US"
            },
            {
                "name": "Southmont",
                "countryCode": "US"
            },
            {
                "name": "Southport",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Spindale",
                "countryCode": "US"
            },
            {
                "name": "Spring Hope",
                "countryCode": "US"
            },
            {
                "name": "Spring Lake",
                "countryCode": "US"
            },
            {
                "name": "Spruce Pine",
                "countryCode": "US"
            },
            {
                "name": "Stallings",
                "countryCode": "US"
            },
            {
                "name": "Stanfield",
                "countryCode": "US"
            },
            {
                "name": "Stanley",
                "countryCode": "US"
            },
            {
                "name": "Stanly County",
                "countryCode": "US"
            },
            {
                "name": "Statesville",
                "countryCode": "US"
            },
            {
                "name": "Stedman",
                "countryCode": "US"
            },
            {
                "name": "Stokes County",
                "countryCode": "US"
            },
            {
                "name": "Stokesdale",
                "countryCode": "US"
            },
            {
                "name": "Stoneville",
                "countryCode": "US"
            },
            {
                "name": "Stony Point",
                "countryCode": "US"
            },
            {
                "name": "Summerfield",
                "countryCode": "US"
            },
            {
                "name": "Sunset Beach",
                "countryCode": "US"
            },
            {
                "name": "Surf City",
                "countryCode": "US"
            },
            {
                "name": "Surry County",
                "countryCode": "US"
            },
            {
                "name": "Swain County",
                "countryCode": "US"
            },
            {
                "name": "Swannanoa",
                "countryCode": "US"
            },
            {
                "name": "Swanquarter",
                "countryCode": "US"
            },
            {
                "name": "Swansboro",
                "countryCode": "US"
            },
            {
                "name": "Swepsonville",
                "countryCode": "US"
            },
            {
                "name": "Sylva",
                "countryCode": "US"
            },
            {
                "name": "Tabor City",
                "countryCode": "US"
            },
            {
                "name": "Tarboro",
                "countryCode": "US"
            },
            {
                "name": "Taylorsville",
                "countryCode": "US"
            },
            {
                "name": "Thomasville",
                "countryCode": "US"
            },
            {
                "name": "Thurmond",
                "countryCode": "US"
            },
            {
                "name": "Toast",
                "countryCode": "US"
            },
            {
                "name": "Tobaccoville",
                "countryCode": "US"
            },
            {
                "name": "Transylvania County",
                "countryCode": "US"
            },
            {
                "name": "Trent Woods",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Trinity",
                "countryCode": "US"
            },
            {
                "name": "Troutman",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tryon",
                "countryCode": "US"
            },
            {
                "name": "Tyro",
                "countryCode": "US"
            },
            {
                "name": "Tyrrell County",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Unionville",
                "countryCode": "US"
            },
            {
                "name": "Valdese",
                "countryCode": "US"
            },
            {
                "name": "Valley Hill",
                "countryCode": "US"
            },
            {
                "name": "Vance County",
                "countryCode": "US"
            },
            {
                "name": "Vander",
                "countryCode": "US"
            },
            {
                "name": "Wadesboro",
                "countryCode": "US"
            },
            {
                "name": "Wake County",
                "countryCode": "US"
            },
            {
                "name": "Wake Forest",
                "countryCode": "US"
            },
            {
                "name": "Walkertown",
                "countryCode": "US"
            },
            {
                "name": "Wallace",
                "countryCode": "US"
            },
            {
                "name": "Wallburg",
                "countryCode": "US"
            },
            {
                "name": "Walnut Cove",
                "countryCode": "US"
            },
            {
                "name": "Wanchese",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Watauga County",
                "countryCode": "US"
            },
            {
                "name": "Waxhaw",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesville",
                "countryCode": "US"
            },
            {
                "name": "Weaverville",
                "countryCode": "US"
            },
            {
                "name": "Weddington",
                "countryCode": "US"
            },
            {
                "name": "Welcome",
                "countryCode": "US"
            },
            {
                "name": "Weldon",
                "countryCode": "US"
            },
            {
                "name": "Wendell",
                "countryCode": "US"
            },
            {
                "name": "Wentworth",
                "countryCode": "US"
            },
            {
                "name": "Wesley Chapel",
                "countryCode": "US"
            },
            {
                "name": "West Canton",
                "countryCode": "US"
            },
            {
                "name": "West Jefferson",
                "countryCode": "US"
            },
            {
                "name": "West Marion",
                "countryCode": "US"
            },
            {
                "name": "West Raleigh",
                "countryCode": "US"
            },
            {
                "name": "Westport",
                "countryCode": "US"
            },
            {
                "name": "Whispering Pines",
                "countryCode": "US"
            },
            {
                "name": "White Plains",
                "countryCode": "US"
            },
            {
                "name": "Whiteville",
                "countryCode": "US"
            },
            {
                "name": "Wilkes County",
                "countryCode": "US"
            },
            {
                "name": "Wilkesboro",
                "countryCode": "US"
            },
            {
                "name": "Williamston",
                "countryCode": "US"
            },
            {
                "name": "Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Wilson",
                "countryCode": "US"
            },
            {
                "name": "Wilson County",
                "countryCode": "US"
            },
            {
                "name": "Wilsons Mills",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Wingate",
                "countryCode": "US"
            },
            {
                "name": "Winston-Salem",
                "countryCode": "US"
            },
            {
                "name": "Winterville",
                "countryCode": "US"
            },
            {
                "name": "Winton",
                "countryCode": "US"
            },
            {
                "name": "Woodfin",
                "countryCode": "US"
            },
            {
                "name": "Wrightsboro",
                "countryCode": "US"
            },
            {
                "name": "Wrightsville Beach",
                "countryCode": "US"
            },
            {
                "name": "Yadkin County",
                "countryCode": "US"
            },
            {
                "name": "Yadkinville",
                "countryCode": "US"
            },
            {
                "name": "Yancey County",
                "countryCode": "US"
            },
            {
                "name": "Yanceyville",
                "countryCode": "US"
            },
            {
                "name": "Youngsville",
                "countryCode": "US"
            },
            {
                "name": "Zebulon",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Amidon",
                "countryCode": "US"
            },
            {
                "name": "Ashley",
                "countryCode": "US"
            },
            {
                "name": "Barnes County",
                "countryCode": "US"
            },
            {
                "name": "Beach",
                "countryCode": "US"
            },
            {
                "name": "Belcourt",
                "countryCode": "US"
            },
            {
                "name": "Belfield",
                "countryCode": "US"
            },
            {
                "name": "Benson County",
                "countryCode": "US"
            },
            {
                "name": "Beulah",
                "countryCode": "US"
            },
            {
                "name": "Billings County",
                "countryCode": "US"
            },
            {
                "name": "Bismarck",
                "countryCode": "US"
            },
            {
                "name": "Bottineau",
                "countryCode": "US"
            },
            {
                "name": "Bottineau County",
                "countryCode": "US"
            },
            {
                "name": "Bowbells",
                "countryCode": "US"
            },
            {
                "name": "Bowman",
                "countryCode": "US"
            },
            {
                "name": "Bowman County",
                "countryCode": "US"
            },
            {
                "name": "Burke County",
                "countryCode": "US"
            },
            {
                "name": "Burleigh County",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Cando",
                "countryCode": "US"
            },
            {
                "name": "Carrington",
                "countryCode": "US"
            },
            {
                "name": "Carson",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Casselton",
                "countryCode": "US"
            },
            {
                "name": "Cavalier",
                "countryCode": "US"
            },
            {
                "name": "Cavalier County",
                "countryCode": "US"
            },
            {
                "name": "Center",
                "countryCode": "US"
            },
            {
                "name": "Cooperstown",
                "countryCode": "US"
            },
            {
                "name": "Crosby",
                "countryCode": "US"
            },
            {
                "name": "Devils Lake",
                "countryCode": "US"
            },
            {
                "name": "Dickey County",
                "countryCode": "US"
            },
            {
                "name": "Dickinson",
                "countryCode": "US"
            },
            {
                "name": "Divide County",
                "countryCode": "US"
            },
            {
                "name": "Dunn County",
                "countryCode": "US"
            },
            {
                "name": "Eddy County",
                "countryCode": "US"
            },
            {
                "name": "Ellendale",
                "countryCode": "US"
            },
            {
                "name": "Emmons County",
                "countryCode": "US"
            },
            {
                "name": "Fargo",
                "countryCode": "US"
            },
            {
                "name": "Fessenden",
                "countryCode": "US"
            },
            {
                "name": "Finley",
                "countryCode": "US"
            },
            {
                "name": "Forman",
                "countryCode": "US"
            },
            {
                "name": "Fort Totten",
                "countryCode": "US"
            },
            {
                "name": "Fort Yates",
                "countryCode": "US"
            },
            {
                "name": "Foster County",
                "countryCode": "US"
            },
            {
                "name": "Garrison",
                "countryCode": "US"
            },
            {
                "name": "Golden Valley County",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Grand Forks",
                "countryCode": "US"
            },
            {
                "name": "Grand Forks Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Grand Forks County",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Griggs County",
                "countryCode": "US"
            },
            {
                "name": "Harvey",
                "countryCode": "US"
            },
            {
                "name": "Hazen",
                "countryCode": "US"
            },
            {
                "name": "Hettinger",
                "countryCode": "US"
            },
            {
                "name": "Hettinger County",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Horace",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Kenmare",
                "countryCode": "US"
            },
            {
                "name": "Kidder County",
                "countryCode": "US"
            },
            {
                "name": "Killdeer",
                "countryCode": "US"
            },
            {
                "name": "LaMoure County",
                "countryCode": "US"
            },
            {
                "name": "Lakota",
                "countryCode": "US"
            },
            {
                "name": "Langdon",
                "countryCode": "US"
            },
            {
                "name": "Larimore",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Linton",
                "countryCode": "US"
            },
            {
                "name": "Lisbon",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Mandan",
                "countryCode": "US"
            },
            {
                "name": "Manning",
                "countryCode": "US"
            },
            {
                "name": "Mayville",
                "countryCode": "US"
            },
            {
                "name": "McClusky",
                "countryCode": "US"
            },
            {
                "name": "McHenry County",
                "countryCode": "US"
            },
            {
                "name": "McIntosh County",
                "countryCode": "US"
            },
            {
                "name": "McKenzie County",
                "countryCode": "US"
            },
            {
                "name": "McLean County",
                "countryCode": "US"
            },
            {
                "name": "Medora",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Minnewaukan",
                "countryCode": "US"
            },
            {
                "name": "Minot",
                "countryCode": "US"
            },
            {
                "name": "Minot Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Mohall",
                "countryCode": "US"
            },
            {
                "name": "Morton County",
                "countryCode": "US"
            },
            {
                "name": "Mott",
                "countryCode": "US"
            },
            {
                "name": "Mountrail County",
                "countryCode": "US"
            },
            {
                "name": "Napoleon",
                "countryCode": "US"
            },
            {
                "name": "Nelson County",
                "countryCode": "US"
            },
            {
                "name": "New Rockford",
                "countryCode": "US"
            },
            {
                "name": "New Town",
                "countryCode": "US"
            },
            {
                "name": "Oakes",
                "countryCode": "US"
            },
            {
                "name": "Oliver County",
                "countryCode": "US"
            },
            {
                "name": "Park River",
                "countryCode": "US"
            },
            {
                "name": "Parshall",
                "countryCode": "US"
            },
            {
                "name": "Pembina County",
                "countryCode": "US"
            },
            {
                "name": "Pierce County",
                "countryCode": "US"
            },
            {
                "name": "Ramsey County",
                "countryCode": "US"
            },
            {
                "name": "Ransom County",
                "countryCode": "US"
            },
            {
                "name": "Renville County",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Rolette County",
                "countryCode": "US"
            },
            {
                "name": "Rolla",
                "countryCode": "US"
            },
            {
                "name": "Rugby",
                "countryCode": "US"
            },
            {
                "name": "Sargent County",
                "countryCode": "US"
            },
            {
                "name": "Sheldon",
                "countryCode": "US"
            },
            {
                "name": "Shell Valley",
                "countryCode": "US"
            },
            {
                "name": "Sheridan County",
                "countryCode": "US"
            },
            {
                "name": "Sioux County",
                "countryCode": "US"
            },
            {
                "name": "Slope County",
                "countryCode": "US"
            },
            {
                "name": "Stanley",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Stark County",
                "countryCode": "US"
            },
            {
                "name": "Steele",
                "countryCode": "US"
            },
            {
                "name": "Steele County",
                "countryCode": "US"
            },
            {
                "name": "Stutsman County",
                "countryCode": "US"
            },
            {
                "name": "Surrey",
                "countryCode": "US"
            },
            {
                "name": "Thompson",
                "countryCode": "US"
            },
            {
                "name": "Tioga",
                "countryCode": "US"
            },
            {
                "name": "Towner",
                "countryCode": "US"
            },
            {
                "name": "Towner County",
                "countryCode": "US"
            },
            {
                "name": "Traill County",
                "countryCode": "US"
            },
            {
                "name": "Valley City",
                "countryCode": "US"
            },
            {
                "name": "Velva",
                "countryCode": "US"
            },
            {
                "name": "Wahpeton",
                "countryCode": "US"
            },
            {
                "name": "Walsh County",
                "countryCode": "US"
            },
            {
                "name": "Ward County",
                "countryCode": "US"
            },
            {
                "name": "Washburn",
                "countryCode": "US"
            },
            {
                "name": "Watford City",
                "countryCode": "US"
            },
            {
                "name": "Wells County",
                "countryCode": "US"
            },
            {
                "name": "West Fargo",
                "countryCode": "US"
            },
            {
                "name": "Williams County",
                "countryCode": "US"
            },
            {
                "name": "Williston",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Ainsworth",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alliance",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Antelope County",
                "countryCode": "US"
            },
            {
                "name": "Arapahoe",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Arthur",
                "countryCode": "US"
            },
            {
                "name": "Arthur County",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atkinson",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Banner County",
                "countryCode": "US"
            },
            {
                "name": "Bartlett",
                "countryCode": "US"
            },
            {
                "name": "Bassett",
                "countryCode": "US"
            },
            {
                "name": "Battle Creek",
                "countryCode": "US"
            },
            {
                "name": "Bayard",
                "countryCode": "US"
            },
            {
                "name": "Beatrice",
                "countryCode": "US"
            },
            {
                "name": "Beaver City",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Benkelman",
                "countryCode": "US"
            },
            {
                "name": "Bennington",
                "countryCode": "US"
            },
            {
                "name": "Blaine County",
                "countryCode": "US"
            },
            {
                "name": "Blair",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Box Butte County",
                "countryCode": "US"
            },
            {
                "name": "Boyd County",
                "countryCode": "US"
            },
            {
                "name": "Brewster",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Broken Bow",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo County",
                "countryCode": "US"
            },
            {
                "name": "Burt County",
                "countryCode": "US"
            },
            {
                "name": "Burwell",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Butte",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Cedar County",
                "countryCode": "US"
            },
            {
                "name": "Center",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Chadron",
                "countryCode": "US"
            },
            {
                "name": "Chalco",
                "countryCode": "US"
            },
            {
                "name": "Chappell",
                "countryCode": "US"
            },
            {
                "name": "Chase County",
                "countryCode": "US"
            },
            {
                "name": "Cherry County",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne County",
                "countryCode": "US"
            },
            {
                "name": "Clay Center",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Colfax County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Cozad",
                "countryCode": "US"
            },
            {
                "name": "Creighton",
                "countryCode": "US"
            },
            {
                "name": "Crete",
                "countryCode": "US"
            },
            {
                "name": "Cuming County",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Dakota City",
                "countryCode": "US"
            },
            {
                "name": "Dakota County",
                "countryCode": "US"
            },
            {
                "name": "David City",
                "countryCode": "US"
            },
            {
                "name": "Dawes County",
                "countryCode": "US"
            },
            {
                "name": "Dawson County",
                "countryCode": "US"
            },
            {
                "name": "Deuel County",
                "countryCode": "US"
            },
            {
                "name": "Dixon County",
                "countryCode": "US"
            },
            {
                "name": "Dodge County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Dundy County",
                "countryCode": "US"
            },
            {
                "name": "Eagle",
                "countryCode": "US"
            },
            {
                "name": "Elkhorn",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Fairbury",
                "countryCode": "US"
            },
            {
                "name": "Falls City",
                "countryCode": "US"
            },
            {
                "name": "Fillmore County",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "Friend",
                "countryCode": "US"
            },
            {
                "name": "Frontier County",
                "countryCode": "US"
            },
            {
                "name": "Fullerton",
                "countryCode": "US"
            },
            {
                "name": "Furnas County",
                "countryCode": "US"
            },
            {
                "name": "Gage County",
                "countryCode": "US"
            },
            {
                "name": "Garden County",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Gering",
                "countryCode": "US"
            },
            {
                "name": "Gibbon",
                "countryCode": "US"
            },
            {
                "name": "Gordon",
                "countryCode": "US"
            },
            {
                "name": "Gosper County",
                "countryCode": "US"
            },
            {
                "name": "Gothenburg",
                "countryCode": "US"
            },
            {
                "name": "Grand Island",
                "countryCode": "US"
            },
            {
                "name": "Grant",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Greeley",
                "countryCode": "US"
            },
            {
                "name": "Greeley County",
                "countryCode": "US"
            },
            {
                "name": "Gretna",
                "countryCode": "US"
            },
            {
                "name": "Hall County",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Harlan County",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Hartington",
                "countryCode": "US"
            },
            {
                "name": "Hastings",
                "countryCode": "US"
            },
            {
                "name": "Hayes Center",
                "countryCode": "US"
            },
            {
                "name": "Hayes County",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Hickman",
                "countryCode": "US"
            },
            {
                "name": "Hitchcock County",
                "countryCode": "US"
            },
            {
                "name": "Holdrege",
                "countryCode": "US"
            },
            {
                "name": "Holt County",
                "countryCode": "US"
            },
            {
                "name": "Hooker County",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Hyannis",
                "countryCode": "US"
            },
            {
                "name": "Imperial",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Kearney",
                "countryCode": "US"
            },
            {
                "name": "Kearney County",
                "countryCode": "US"
            },
            {
                "name": "Keith County",
                "countryCode": "US"
            },
            {
                "name": "Keya Paha County",
                "countryCode": "US"
            },
            {
                "name": "Kimball",
                "countryCode": "US"
            },
            {
                "name": "Kimball County",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "La Vista",
                "countryCode": "US"
            },
            {
                "name": "Lancaster County",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Loup City",
                "countryCode": "US"
            },
            {
                "name": "Loup County",
                "countryCode": "US"
            },
            {
                "name": "Macy",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "McCook",
                "countryCode": "US"
            },
            {
                "name": "McPherson County",
                "countryCode": "US"
            },
            {
                "name": "Merrick County",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Minden",
                "countryCode": "US"
            },
            {
                "name": "Mitchell",
                "countryCode": "US"
            },
            {
                "name": "Morrill County",
                "countryCode": "US"
            },
            {
                "name": "Mullen",
                "countryCode": "US"
            },
            {
                "name": "Nance County",
                "countryCode": "US"
            },
            {
                "name": "Nebraska City",
                "countryCode": "US"
            },
            {
                "name": "Neligh",
                "countryCode": "US"
            },
            {
                "name": "Nelson",
                "countryCode": "US"
            },
            {
                "name": "Nemaha County",
                "countryCode": "US"
            },
            {
                "name": "Norfolk",
                "countryCode": "US"
            },
            {
                "name": "North Bend",
                "countryCode": "US"
            },
            {
                "name": "North Platte",
                "countryCode": "US"
            },
            {
                "name": "Nuckolls County",
                "countryCode": "US"
            },
            {
                "name": "O'Neill",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Offutt Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Ogallala",
                "countryCode": "US"
            },
            {
                "name": "Omaha",
                "countryCode": "US"
            },
            {
                "name": "Ord",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Oshkosh",
                "countryCode": "US"
            },
            {
                "name": "Otoe County",
                "countryCode": "US"
            },
            {
                "name": "Papillion",
                "countryCode": "US"
            },
            {
                "name": "Pawnee City",
                "countryCode": "US"
            },
            {
                "name": "Pawnee County",
                "countryCode": "US"
            },
            {
                "name": "Pender",
                "countryCode": "US"
            },
            {
                "name": "Perkins County",
                "countryCode": "US"
            },
            {
                "name": "Phelps County",
                "countryCode": "US"
            },
            {
                "name": "Pierce",
                "countryCode": "US"
            },
            {
                "name": "Pierce County",
                "countryCode": "US"
            },
            {
                "name": "Plainview",
                "countryCode": "US"
            },
            {
                "name": "Platte County",
                "countryCode": "US"
            },
            {
                "name": "Plattsmouth",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Ponca",
                "countryCode": "US"
            },
            {
                "name": "Ralston",
                "countryCode": "US"
            },
            {
                "name": "Ravenna",
                "countryCode": "US"
            },
            {
                "name": "Red Cloud",
                "countryCode": "US"
            },
            {
                "name": "Red Willow County",
                "countryCode": "US"
            },
            {
                "name": "Richardson County",
                "countryCode": "US"
            },
            {
                "name": "Rock County",
                "countryCode": "US"
            },
            {
                "name": "Rushville",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Saline County",
                "countryCode": "US"
            },
            {
                "name": "Sarpy County",
                "countryCode": "US"
            },
            {
                "name": "Saunders County",
                "countryCode": "US"
            },
            {
                "name": "Schuyler",
                "countryCode": "US"
            },
            {
                "name": "Scotts Bluff County",
                "countryCode": "US"
            },
            {
                "name": "Scottsbluff",
                "countryCode": "US"
            },
            {
                "name": "Seward",
                "countryCode": "US"
            },
            {
                "name": "Seward County",
                "countryCode": "US"
            },
            {
                "name": "Shelton",
                "countryCode": "US"
            },
            {
                "name": "Sheridan County",
                "countryCode": "US"
            },
            {
                "name": "Sherman County",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Sioux County",
                "countryCode": "US"
            },
            {
                "name": "South Sioux City",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Springview",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Stanton County",
                "countryCode": "US"
            },
            {
                "name": "Stapleton",
                "countryCode": "US"
            },
            {
                "name": "Stockville",
                "countryCode": "US"
            },
            {
                "name": "Stromsburg",
                "countryCode": "US"
            },
            {
                "name": "Superior",
                "countryCode": "US"
            },
            {
                "name": "Sutherland",
                "countryCode": "US"
            },
            {
                "name": "Sutton",
                "countryCode": "US"
            },
            {
                "name": "Syracuse",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Tecumseh",
                "countryCode": "US"
            },
            {
                "name": "Tekamah",
                "countryCode": "US"
            },
            {
                "name": "Terrytown",
                "countryCode": "US"
            },
            {
                "name": "Thayer County",
                "countryCode": "US"
            },
            {
                "name": "Thedford",
                "countryCode": "US"
            },
            {
                "name": "Thomas County",
                "countryCode": "US"
            },
            {
                "name": "Thurston County",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Tryon",
                "countryCode": "US"
            },
            {
                "name": "Valentine",
                "countryCode": "US"
            },
            {
                "name": "Valley",
                "countryCode": "US"
            },
            {
                "name": "Valley County",
                "countryCode": "US"
            },
            {
                "name": "Wahoo",
                "countryCode": "US"
            },
            {
                "name": "Wakefield",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "Weeping Water",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "Wheeler County",
                "countryCode": "US"
            },
            {
                "name": "Wilber",
                "countryCode": "US"
            },
            {
                "name": "Wisner",
                "countryCode": "US"
            },
            {
                "name": "Wood River",
                "countryCode": "US"
            },
            {
                "name": "Wymore",
                "countryCode": "US"
            },
            {
                "name": "York",
                "countryCode": "US"
            },
            {
                "name": "York County",
                "countryCode": "US"
            },
            {
                "name": "Yutan",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Alstead",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Antrim",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atkinson",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Barnstead",
                "countryCode": "US"
            },
            {
                "name": "Barrington",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Belknap County",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Boscawen",
                "countryCode": "US"
            },
            {
                "name": "Bow Bog",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Brookline",
                "countryCode": "US"
            },
            {
                "name": "Candia",
                "countryCode": "US"
            },
            {
                "name": "Canterbury",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Center Harbor",
                "countryCode": "US"
            },
            {
                "name": "Charlestown",
                "countryCode": "US"
            },
            {
                "name": "Cheshire County",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield",
                "countryCode": "US"
            },
            {
                "name": "Chichester",
                "countryCode": "US"
            },
            {
                "name": "Claremont",
                "countryCode": "US"
            },
            {
                "name": "Colebrook",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Contoocook",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Coos County",
                "countryCode": "US"
            },
            {
                "name": "Danbury",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Deerfield",
                "countryCode": "US"
            },
            {
                "name": "Deering",
                "countryCode": "US"
            },
            {
                "name": "Derry",
                "countryCode": "US"
            },
            {
                "name": "Derry Village",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "East Concord",
                "countryCode": "US"
            },
            {
                "name": "East Kingston",
                "countryCode": "US"
            },
            {
                "name": "East Merrimack",
                "countryCode": "US"
            },
            {
                "name": "Effingham",
                "countryCode": "US"
            },
            {
                "name": "Enfield",
                "countryCode": "US"
            },
            {
                "name": "Epping",
                "countryCode": "US"
            },
            {
                "name": "Epsom",
                "countryCode": "US"
            },
            {
                "name": "Exeter",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Fitzwilliam",
                "countryCode": "US"
            },
            {
                "name": "Francestown",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Freedom",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "Gilford",
                "countryCode": "US"
            },
            {
                "name": "Gilmanton",
                "countryCode": "US"
            },
            {
                "name": "Goffstown",
                "countryCode": "US"
            },
            {
                "name": "Gorham",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Grafton County",
                "countryCode": "US"
            },
            {
                "name": "Grantham",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenland",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Groveton",
                "countryCode": "US"
            },
            {
                "name": "Hampstead",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hampton Beach",
                "countryCode": "US"
            },
            {
                "name": "Hampton Falls",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harrisville",
                "countryCode": "US"
            },
            {
                "name": "Haverhill",
                "countryCode": "US"
            },
            {
                "name": "Henniker",
                "countryCode": "US"
            },
            {
                "name": "Hill",
                "countryCode": "US"
            },
            {
                "name": "Hillsborough",
                "countryCode": "US"
            },
            {
                "name": "Hillsborough County",
                "countryCode": "US"
            },
            {
                "name": "Hinsdale",
                "countryCode": "US"
            },
            {
                "name": "Holderness",
                "countryCode": "US"
            },
            {
                "name": "Hollis",
                "countryCode": "US"
            },
            {
                "name": "Hooksett",
                "countryCode": "US"
            },
            {
                "name": "Hopkinton",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Jaffrey",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Keene",
                "countryCode": "US"
            },
            {
                "name": "Kensington",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Laconia",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lee",
                "countryCode": "US"
            },
            {
                "name": "Lempster",
                "countryCode": "US"
            },
            {
                "name": "Litchfield",
                "countryCode": "US"
            },
            {
                "name": "Littleton",
                "countryCode": "US"
            },
            {
                "name": "Londonderry",
                "countryCode": "US"
            },
            {
                "name": "Lyme",
                "countryCode": "US"
            },
            {
                "name": "Lyndeborough",
                "countryCode": "US"
            },
            {
                "name": "Madbury",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Marlborough",
                "countryCode": "US"
            },
            {
                "name": "Mason",
                "countryCode": "US"
            },
            {
                "name": "Meredith",
                "countryCode": "US"
            },
            {
                "name": "Merrimack",
                "countryCode": "US"
            },
            {
                "name": "Merrimack County",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Mont Vernon",
                "countryCode": "US"
            },
            {
                "name": "Moultonborough",
                "countryCode": "US"
            },
            {
                "name": "Nashua",
                "countryCode": "US"
            },
            {
                "name": "New Boston",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "New Durham",
                "countryCode": "US"
            },
            {
                "name": "New Ipswich",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "Newbury",
                "countryCode": "US"
            },
            {
                "name": "Newmarket",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "North Conway",
                "countryCode": "US"
            },
            {
                "name": "North Hampton",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Northumberland",
                "countryCode": "US"
            },
            {
                "name": "Northwood",
                "countryCode": "US"
            },
            {
                "name": "Nottingham",
                "countryCode": "US"
            },
            {
                "name": "Orford",
                "countryCode": "US"
            },
            {
                "name": "Ossipee",
                "countryCode": "US"
            },
            {
                "name": "Pelham",
                "countryCode": "US"
            },
            {
                "name": "Pembroke",
                "countryCode": "US"
            },
            {
                "name": "Peterborough",
                "countryCode": "US"
            },
            {
                "name": "Pinardville",
                "countryCode": "US"
            },
            {
                "name": "Pittsfield",
                "countryCode": "US"
            },
            {
                "name": "Plaistow",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "Raymond",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Rindge",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rockingham County",
                "countryCode": "US"
            },
            {
                "name": "Rollinsford",
                "countryCode": "US"
            },
            {
                "name": "Rumney",
                "countryCode": "US"
            },
            {
                "name": "Rye",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Sanbornton",
                "countryCode": "US"
            },
            {
                "name": "Sanbornville",
                "countryCode": "US"
            },
            {
                "name": "Sandown",
                "countryCode": "US"
            },
            {
                "name": "Sandwich",
                "countryCode": "US"
            },
            {
                "name": "Seabrook",
                "countryCode": "US"
            },
            {
                "name": "Somersworth",
                "countryCode": "US"
            },
            {
                "name": "South Hooksett",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Strafford",
                "countryCode": "US"
            },
            {
                "name": "Strafford County",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Stratham Station",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Sunapee",
                "countryCode": "US"
            },
            {
                "name": "Suncook",
                "countryCode": "US"
            },
            {
                "name": "Sutton",
                "countryCode": "US"
            },
            {
                "name": "Swanzey",
                "countryCode": "US"
            },
            {
                "name": "Tamworth",
                "countryCode": "US"
            },
            {
                "name": "Temple",
                "countryCode": "US"
            },
            {
                "name": "Thornton",
                "countryCode": "US"
            },
            {
                "name": "Tilton",
                "countryCode": "US"
            },
            {
                "name": "Tilton-Northfield",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tuftonboro",
                "countryCode": "US"
            },
            {
                "name": "Unity",
                "countryCode": "US"
            },
            {
                "name": "Wakefield",
                "countryCode": "US"
            },
            {
                "name": "Weare",
                "countryCode": "US"
            },
            {
                "name": "Webster",
                "countryCode": "US"
            },
            {
                "name": "West Swanzey",
                "countryCode": "US"
            },
            {
                "name": "Westmoreland",
                "countryCode": "US"
            },
            {
                "name": "Whitefield",
                "countryCode": "US"
            },
            {
                "name": "Wilmot",
                "countryCode": "US"
            },
            {
                "name": "Wilton",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windham",
                "countryCode": "US"
            },
            {
                "name": "Wolfeboro",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Woodsville",
                "countryCode": "US"
            },
            {
                "name": "Absecon",
                "countryCode": "US"
            },
            {
                "name": "Allendale",
                "countryCode": "US"
            },
            {
                "name": "Allentown",
                "countryCode": "US"
            },
            {
                "name": "Alloway",
                "countryCode": "US"
            },
            {
                "name": "Alpha",
                "countryCode": "US"
            },
            {
                "name": "Alpine",
                "countryCode": "US"
            },
            {
                "name": "Annandale",
                "countryCode": "US"
            },
            {
                "name": "Asbury Park",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atco",
                "countryCode": "US"
            },
            {
                "name": "Atlantic City",
                "countryCode": "US"
            },
            {
                "name": "Atlantic County",
                "countryCode": "US"
            },
            {
                "name": "Atlantic Highlands",
                "countryCode": "US"
            },
            {
                "name": "Audubon",
                "countryCode": "US"
            },
            {
                "name": "Audubon Park",
                "countryCode": "US"
            },
            {
                "name": "Avalon",
                "countryCode": "US"
            },
            {
                "name": "Avenel",
                "countryCode": "US"
            },
            {
                "name": "Avon-by-the-Sea",
                "countryCode": "US"
            },
            {
                "name": "Barnegat",
                "countryCode": "US"
            },
            {
                "name": "Barrington",
                "countryCode": "US"
            },
            {
                "name": "Bayonne",
                "countryCode": "US"
            },
            {
                "name": "Bayville",
                "countryCode": "US"
            },
            {
                "name": "Beach Haven",
                "countryCode": "US"
            },
            {
                "name": "Beach Haven West",
                "countryCode": "US"
            },
            {
                "name": "Beachwood",
                "countryCode": "US"
            },
            {
                "name": "Beattystown",
                "countryCode": "US"
            },
            {
                "name": "Beckett",
                "countryCode": "US"
            },
            {
                "name": "Bedminster",
                "countryCode": "US"
            },
            {
                "name": "Belford",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Bellmawr",
                "countryCode": "US"
            },
            {
                "name": "Belmar",
                "countryCode": "US"
            },
            {
                "name": "Belvidere",
                "countryCode": "US"
            },
            {
                "name": "Bergen County",
                "countryCode": "US"
            },
            {
                "name": "Bergenfield",
                "countryCode": "US"
            },
            {
                "name": "Berkeley Heights",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Bernardsville",
                "countryCode": "US"
            },
            {
                "name": "Beverly",
                "countryCode": "US"
            },
            {
                "name": "Blackwood",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Bogota",
                "countryCode": "US"
            },
            {
                "name": "Boonton",
                "countryCode": "US"
            },
            {
                "name": "Bordentown",
                "countryCode": "US"
            },
            {
                "name": "Bound Brook",
                "countryCode": "US"
            },
            {
                "name": "Bradley Beach",
                "countryCode": "US"
            },
            {
                "name": "Bradley Gardens",
                "countryCode": "US"
            },
            {
                "name": "Brass Castle",
                "countryCode": "US"
            },
            {
                "name": "Bridgeton",
                "countryCode": "US"
            },
            {
                "name": "Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "Brielle",
                "countryCode": "US"
            },
            {
                "name": "Brigantine",
                "countryCode": "US"
            },
            {
                "name": "Brookdale",
                "countryCode": "US"
            },
            {
                "name": "Brooklawn",
                "countryCode": "US"
            },
            {
                "name": "Browns Mills",
                "countryCode": "US"
            },
            {
                "name": "Brownville",
                "countryCode": "US"
            },
            {
                "name": "Budd Lake",
                "countryCode": "US"
            },
            {
                "name": "Buena",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Burlington County",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Caldwell",
                "countryCode": "US"
            },
            {
                "name": "Califon",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Camden County",
                "countryCode": "US"
            },
            {
                "name": "Cape May",
                "countryCode": "US"
            },
            {
                "name": "Cape May County",
                "countryCode": "US"
            },
            {
                "name": "Cape May Court House",
                "countryCode": "US"
            },
            {
                "name": "Carlstadt",
                "countryCode": "US"
            },
            {
                "name": "Carneys Point",
                "countryCode": "US"
            },
            {
                "name": "Carteret",
                "countryCode": "US"
            },
            {
                "name": "Cedar Glen Lakes",
                "countryCode": "US"
            },
            {
                "name": "Cedar Glen West",
                "countryCode": "US"
            },
            {
                "name": "Cedar Grove",
                "countryCode": "US"
            },
            {
                "name": "Chatham",
                "countryCode": "US"
            },
            {
                "name": "Cherry Hill",
                "countryCode": "US"
            },
            {
                "name": "Cherry Hill Mall",
                "countryCode": "US"
            },
            {
                "name": "Chesilhurst",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Cinnaminson",
                "countryCode": "US"
            },
            {
                "name": "Clark",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clearbrook Park",
                "countryCode": "US"
            },
            {
                "name": "Clementon",
                "countryCode": "US"
            },
            {
                "name": "Cliffside Park",
                "countryCode": "US"
            },
            {
                "name": "Cliffwood Beach",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Closter",
                "countryCode": "US"
            },
            {
                "name": "Collings Lakes",
                "countryCode": "US"
            },
            {
                "name": "Collingswood",
                "countryCode": "US"
            },
            {
                "name": "Colonia",
                "countryCode": "US"
            },
            {
                "name": "Concordia",
                "countryCode": "US"
            },
            {
                "name": "Country Lake Estates",
                "countryCode": "US"
            },
            {
                "name": "Cranbury",
                "countryCode": "US"
            },
            {
                "name": "Crandon Lakes",
                "countryCode": "US"
            },
            {
                "name": "Cranford",
                "countryCode": "US"
            },
            {
                "name": "Cresskill",
                "countryCode": "US"
            },
            {
                "name": "Crestwood Village",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Delanco",
                "countryCode": "US"
            },
            {
                "name": "Demarest",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dover Beaches North",
                "countryCode": "US"
            },
            {
                "name": "Dover Beaches South",
                "countryCode": "US"
            },
            {
                "name": "Dumont",
                "countryCode": "US"
            },
            {
                "name": "Dunellen",
                "countryCode": "US"
            },
            {
                "name": "East Brunswick",
                "countryCode": "US"
            },
            {
                "name": "East Franklin",
                "countryCode": "US"
            },
            {
                "name": "East Freehold",
                "countryCode": "US"
            },
            {
                "name": "East Hanover",
                "countryCode": "US"
            },
            {
                "name": "East Newark",
                "countryCode": "US"
            },
            {
                "name": "East Orange",
                "countryCode": "US"
            },
            {
                "name": "East Rutherford",
                "countryCode": "US"
            },
            {
                "name": "Eatontown",
                "countryCode": "US"
            },
            {
                "name": "Echelon",
                "countryCode": "US"
            },
            {
                "name": "Edgewater",
                "countryCode": "US"
            },
            {
                "name": "Edgewater Park",
                "countryCode": "US"
            },
            {
                "name": "Edison",
                "countryCode": "US"
            },
            {
                "name": "Egg Harbor City",
                "countryCode": "US"
            },
            {
                "name": "Elizabeth",
                "countryCode": "US"
            },
            {
                "name": "Ellisburg",
                "countryCode": "US"
            },
            {
                "name": "Elmer",
                "countryCode": "US"
            },
            {
                "name": "Elmwood Park",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Emerson",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Englewood Cliffs",
                "countryCode": "US"
            },
            {
                "name": "Englishtown",
                "countryCode": "US"
            },
            {
                "name": "Erma",
                "countryCode": "US"
            },
            {
                "name": "Essex County",
                "countryCode": "US"
            },
            {
                "name": "Essex Fells",
                "countryCode": "US"
            },
            {
                "name": "Estell Manor",
                "countryCode": "US"
            },
            {
                "name": "Ewing",
                "countryCode": "US"
            },
            {
                "name": "Fair Haven",
                "countryCode": "US"
            },
            {
                "name": "Fair Lawn",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairton",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fanwood",
                "countryCode": "US"
            },
            {
                "name": "Farmingdale",
                "countryCode": "US"
            },
            {
                "name": "Finderne",
                "countryCode": "US"
            },
            {
                "name": "Flemington",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Florham Park",
                "countryCode": "US"
            },
            {
                "name": "Folsom",
                "countryCode": "US"
            },
            {
                "name": "Fords",
                "countryCode": "US"
            },
            {
                "name": "Forked River",
                "countryCode": "US"
            },
            {
                "name": "Fort Dix",
                "countryCode": "US"
            },
            {
                "name": "Fort Lee",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin Center",
                "countryCode": "US"
            },
            {
                "name": "Franklin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Franklin Park",
                "countryCode": "US"
            },
            {
                "name": "Freehold",
                "countryCode": "US"
            },
            {
                "name": "Frenchtown",
                "countryCode": "US"
            },
            {
                "name": "Garfield",
                "countryCode": "US"
            },
            {
                "name": "Garwood",
                "countryCode": "US"
            },
            {
                "name": "Gibbsboro",
                "countryCode": "US"
            },
            {
                "name": "Gibbstown",
                "countryCode": "US"
            },
            {
                "name": "Gladstone",
                "countryCode": "US"
            },
            {
                "name": "Glassboro",
                "countryCode": "US"
            },
            {
                "name": "Glen Gardner",
                "countryCode": "US"
            },
            {
                "name": "Glen Ridge",
                "countryCode": "US"
            },
            {
                "name": "Glen Rock",
                "countryCode": "US"
            },
            {
                "name": "Glendora",
                "countryCode": "US"
            },
            {
                "name": "Gloucester City",
                "countryCode": "US"
            },
            {
                "name": "Gloucester County",
                "countryCode": "US"
            },
            {
                "name": "Golden Triangle",
                "countryCode": "US"
            },
            {
                "name": "Green Knoll",
                "countryCode": "US"
            },
            {
                "name": "Greentree",
                "countryCode": "US"
            },
            {
                "name": "Groveville",
                "countryCode": "US"
            },
            {
                "name": "Guttenberg",
                "countryCode": "US"
            },
            {
                "name": "Hackensack",
                "countryCode": "US"
            },
            {
                "name": "Hackettstown",
                "countryCode": "US"
            },
            {
                "name": "Haddon Heights",
                "countryCode": "US"
            },
            {
                "name": "Haddonfield",
                "countryCode": "US"
            },
            {
                "name": "Haledon",
                "countryCode": "US"
            },
            {
                "name": "Hamburg",
                "countryCode": "US"
            },
            {
                "name": "Hamilton Square",
                "countryCode": "US"
            },
            {
                "name": "Hammonton",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Hardwick",
                "countryCode": "US"
            },
            {
                "name": "Harrington Park",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Hasbrouck Heights",
                "countryCode": "US"
            },
            {
                "name": "Haworth",
                "countryCode": "US"
            },
            {
                "name": "Hawthorne",
                "countryCode": "US"
            },
            {
                "name": "Hazlet Township",
                "countryCode": "US"
            },
            {
                "name": "Heathcote",
                "countryCode": "US"
            },
            {
                "name": "Helmetta",
                "countryCode": "US"
            },
            {
                "name": "High Bridge",
                "countryCode": "US"
            },
            {
                "name": "Highland Lake",
                "countryCode": "US"
            },
            {
                "name": "Highland Park",
                "countryCode": "US"
            },
            {
                "name": "Highlands",
                "countryCode": "US"
            },
            {
                "name": "Hightstown",
                "countryCode": "US"
            },
            {
                "name": "Hillsdale",
                "countryCode": "US"
            },
            {
                "name": "Hillside",
                "countryCode": "US"
            },
            {
                "name": "Ho-Ho-Kus",
                "countryCode": "US"
            },
            {
                "name": "Hoboken",
                "countryCode": "US"
            },
            {
                "name": "Holiday City South",
                "countryCode": "US"
            },
            {
                "name": "Holiday City-Berkeley",
                "countryCode": "US"
            },
            {
                "name": "Holiday Heights",
                "countryCode": "US"
            },
            {
                "name": "Hopatcong",
                "countryCode": "US"
            },
            {
                "name": "Hopatcong Hills",
                "countryCode": "US"
            },
            {
                "name": "Hopewell",
                "countryCode": "US"
            },
            {
                "name": "Hudson County",
                "countryCode": "US"
            },
            {
                "name": "Hunterdon County",
                "countryCode": "US"
            },
            {
                "name": "Irvington",
                "countryCode": "US"
            },
            {
                "name": "Iselin",
                "countryCode": "US"
            },
            {
                "name": "Island Heights",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jamesburg",
                "countryCode": "US"
            },
            {
                "name": "Jersey City",
                "countryCode": "US"
            },
            {
                "name": "Keansburg",
                "countryCode": "US"
            },
            {
                "name": "Kearny",
                "countryCode": "US"
            },
            {
                "name": "Kendall Park",
                "countryCode": "US"
            },
            {
                "name": "Kenilworth",
                "countryCode": "US"
            },
            {
                "name": "Kenvil",
                "countryCode": "US"
            },
            {
                "name": "Keyport",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kingston Estates",
                "countryCode": "US"
            },
            {
                "name": "Kinnelon",
                "countryCode": "US"
            },
            {
                "name": "Lake Como",
                "countryCode": "US"
            },
            {
                "name": "Lake Mohawk",
                "countryCode": "US"
            },
            {
                "name": "Lake Telemark",
                "countryCode": "US"
            },
            {
                "name": "Lakehurst",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lambertville",
                "countryCode": "US"
            },
            {
                "name": "Landing",
                "countryCode": "US"
            },
            {
                "name": "Laurel Lake",
                "countryCode": "US"
            },
            {
                "name": "Laurel Springs",
                "countryCode": "US"
            },
            {
                "name": "Laurence Harbor",
                "countryCode": "US"
            },
            {
                "name": "Lavallette",
                "countryCode": "US"
            },
            {
                "name": "Lawnside",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceville",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Leisure Knoll",
                "countryCode": "US"
            },
            {
                "name": "Leisure Village",
                "countryCode": "US"
            },
            {
                "name": "Leisure Village East",
                "countryCode": "US"
            },
            {
                "name": "Leisure Village West-Pine Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Leisuretowne",
                "countryCode": "US"
            },
            {
                "name": "Leonardo",
                "countryCode": "US"
            },
            {
                "name": "Leonia",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Lincroft",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Lindenwold",
                "countryCode": "US"
            },
            {
                "name": "Linwood",
                "countryCode": "US"
            },
            {
                "name": "Little Falls",
                "countryCode": "US"
            },
            {
                "name": "Little Ferry",
                "countryCode": "US"
            },
            {
                "name": "Little Silver",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Lodi",
                "countryCode": "US"
            },
            {
                "name": "Long Branch",
                "countryCode": "US"
            },
            {
                "name": "Long Valley",
                "countryCode": "US"
            },
            {
                "name": "Lyndhurst",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison Park",
                "countryCode": "US"
            },
            {
                "name": "Magnolia",
                "countryCode": "US"
            },
            {
                "name": "Mahwah",
                "countryCode": "US"
            },
            {
                "name": "Manahawkin",
                "countryCode": "US"
            },
            {
                "name": "Manasquan",
                "countryCode": "US"
            },
            {
                "name": "Mantua Township",
                "countryCode": "US"
            },
            {
                "name": "Manville",
                "countryCode": "US"
            },
            {
                "name": "Maple Shade",
                "countryCode": "US"
            },
            {
                "name": "Maplewood",
                "countryCode": "US"
            },
            {
                "name": "Margate City",
                "countryCode": "US"
            },
            {
                "name": "Marlboro",
                "countryCode": "US"
            },
            {
                "name": "Marlton",
                "countryCode": "US"
            },
            {
                "name": "Martinsville",
                "countryCode": "US"
            },
            {
                "name": "Matawan",
                "countryCode": "US"
            },
            {
                "name": "Mays Landing",
                "countryCode": "US"
            },
            {
                "name": "Maywood",
                "countryCode": "US"
            },
            {
                "name": "McGuire AFB",
                "countryCode": "US"
            },
            {
                "name": "Medford Lakes",
                "countryCode": "US"
            },
            {
                "name": "Mendham",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Mercerville",
                "countryCode": "US"
            },
            {
                "name": "Mercerville-Hamilton Square",
                "countryCode": "US"
            },
            {
                "name": "Merchantville",
                "countryCode": "US"
            },
            {
                "name": "Metuchen",
                "countryCode": "US"
            },
            {
                "name": "Middlebush",
                "countryCode": "US"
            },
            {
                "name": "Middlesex",
                "countryCode": "US"
            },
            {
                "name": "Middlesex County",
                "countryCode": "US"
            },
            {
                "name": "Midland Park",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Milltown",
                "countryCode": "US"
            },
            {
                "name": "Millville",
                "countryCode": "US"
            },
            {
                "name": "Monmouth Beach",
                "countryCode": "US"
            },
            {
                "name": "Monmouth County",
                "countryCode": "US"
            },
            {
                "name": "Monmouth Junction",
                "countryCode": "US"
            },
            {
                "name": "Montclair",
                "countryCode": "US"
            },
            {
                "name": "Montvale",
                "countryCode": "US"
            },
            {
                "name": "Moonachie",
                "countryCode": "US"
            },
            {
                "name": "Moorestown-Lenola",
                "countryCode": "US"
            },
            {
                "name": "Morganville",
                "countryCode": "US"
            },
            {
                "name": "Morris County",
                "countryCode": "US"
            },
            {
                "name": "Morris Plains",
                "countryCode": "US"
            },
            {
                "name": "Morristown",
                "countryCode": "US"
            },
            {
                "name": "Mount Arlington",
                "countryCode": "US"
            },
            {
                "name": "Mount Ephraim",
                "countryCode": "US"
            },
            {
                "name": "Mount Holly",
                "countryCode": "US"
            },
            {
                "name": "Mount Laurel",
                "countryCode": "US"
            },
            {
                "name": "Mountain Lakes",
                "countryCode": "US"
            },
            {
                "name": "Mountainside",
                "countryCode": "US"
            },
            {
                "name": "Mullica Hill",
                "countryCode": "US"
            },
            {
                "name": "Mystic Island",
                "countryCode": "US"
            },
            {
                "name": "National Park",
                "countryCode": "US"
            },
            {
                "name": "Navesink",
                "countryCode": "US"
            },
            {
                "name": "Neptune City",
                "countryCode": "US"
            },
            {
                "name": "Netcong",
                "countryCode": "US"
            },
            {
                "name": "New Brunswick",
                "countryCode": "US"
            },
            {
                "name": "New Egypt",
                "countryCode": "US"
            },
            {
                "name": "New Milford",
                "countryCode": "US"
            },
            {
                "name": "New Providence",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newfield",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "North Arlington",
                "countryCode": "US"
            },
            {
                "name": "North Beach Haven",
                "countryCode": "US"
            },
            {
                "name": "North Bergen",
                "countryCode": "US"
            },
            {
                "name": "North Caldwell",
                "countryCode": "US"
            },
            {
                "name": "North Cape May",
                "countryCode": "US"
            },
            {
                "name": "North Haledon",
                "countryCode": "US"
            },
            {
                "name": "North Middletown",
                "countryCode": "US"
            },
            {
                "name": "North Plainfield",
                "countryCode": "US"
            },
            {
                "name": "North Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Northvale",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Nutley",
                "countryCode": "US"
            },
            {
                "name": "Oak Valley",
                "countryCode": "US"
            },
            {
                "name": "Oakhurst",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oaklyn",
                "countryCode": "US"
            },
            {
                "name": "Ocean Acres",
                "countryCode": "US"
            },
            {
                "name": "Ocean City",
                "countryCode": "US"
            },
            {
                "name": "Ocean County",
                "countryCode": "US"
            },
            {
                "name": "Ocean Gate",
                "countryCode": "US"
            },
            {
                "name": "Ocean Grove",
                "countryCode": "US"
            },
            {
                "name": "Oceanport",
                "countryCode": "US"
            },
            {
                "name": "Ogdensburg",
                "countryCode": "US"
            },
            {
                "name": "Old Bridge",
                "countryCode": "US"
            },
            {
                "name": "Old Tappan",
                "countryCode": "US"
            },
            {
                "name": "Olivet",
                "countryCode": "US"
            },
            {
                "name": "Oradell",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Palisades Park",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Paramus",
                "countryCode": "US"
            },
            {
                "name": "Park Ridge",
                "countryCode": "US"
            },
            {
                "name": "Parsippany",
                "countryCode": "US"
            },
            {
                "name": "Passaic",
                "countryCode": "US"
            },
            {
                "name": "Passaic County",
                "countryCode": "US"
            },
            {
                "name": "Paterson",
                "countryCode": "US"
            },
            {
                "name": "Paulsboro",
                "countryCode": "US"
            },
            {
                "name": "Peapack",
                "countryCode": "US"
            },
            {
                "name": "Pemberton",
                "countryCode": "US"
            },
            {
                "name": "Pemberton Heights",
                "countryCode": "US"
            },
            {
                "name": "Pennington",
                "countryCode": "US"
            },
            {
                "name": "Penns Grove",
                "countryCode": "US"
            },
            {
                "name": "Pennsauken",
                "countryCode": "US"
            },
            {
                "name": "Pennsville",
                "countryCode": "US"
            },
            {
                "name": "Perth Amboy",
                "countryCode": "US"
            },
            {
                "name": "Phillipsburg",
                "countryCode": "US"
            },
            {
                "name": "Pine Beach",
                "countryCode": "US"
            },
            {
                "name": "Pine Hill",
                "countryCode": "US"
            },
            {
                "name": "Pine Lake Park",
                "countryCode": "US"
            },
            {
                "name": "Pine Ridge at Crestwood",
                "countryCode": "US"
            },
            {
                "name": "Piscataway",
                "countryCode": "US"
            },
            {
                "name": "Pitman",
                "countryCode": "US"
            },
            {
                "name": "Plainfield",
                "countryCode": "US"
            },
            {
                "name": "Plainsboro Center",
                "countryCode": "US"
            },
            {
                "name": "Pleasantville",
                "countryCode": "US"
            },
            {
                "name": "Point Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Point Pleasant Beach",
                "countryCode": "US"
            },
            {
                "name": "Pomona",
                "countryCode": "US"
            },
            {
                "name": "Pompton Lakes",
                "countryCode": "US"
            },
            {
                "name": "Port Monmouth",
                "countryCode": "US"
            },
            {
                "name": "Port Norris",
                "countryCode": "US"
            },
            {
                "name": "Port Reading",
                "countryCode": "US"
            },
            {
                "name": "Port Republic",
                "countryCode": "US"
            },
            {
                "name": "Presidential Lakes Estates",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Princeton Junction",
                "countryCode": "US"
            },
            {
                "name": "Princeton Meadows",
                "countryCode": "US"
            },
            {
                "name": "Prospect Park",
                "countryCode": "US"
            },
            {
                "name": "Rahway",
                "countryCode": "US"
            },
            {
                "name": "Ramblewood",
                "countryCode": "US"
            },
            {
                "name": "Ramsey",
                "countryCode": "US"
            },
            {
                "name": "Ramtown",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Raritan",
                "countryCode": "US"
            },
            {
                "name": "Red Bank",
                "countryCode": "US"
            },
            {
                "name": "Richwood",
                "countryCode": "US"
            },
            {
                "name": "Ridgefield",
                "countryCode": "US"
            },
            {
                "name": "Ridgefield Park",
                "countryCode": "US"
            },
            {
                "name": "Ridgewood",
                "countryCode": "US"
            },
            {
                "name": "Ringwood",
                "countryCode": "US"
            },
            {
                "name": "Rio Grande",
                "countryCode": "US"
            },
            {
                "name": "River Edge",
                "countryCode": "US"
            },
            {
                "name": "River Vale",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Riverton",
                "countryCode": "US"
            },
            {
                "name": "Robbinsville",
                "countryCode": "US"
            },
            {
                "name": "Robertsville",
                "countryCode": "US"
            },
            {
                "name": "Rochelle Park",
                "countryCode": "US"
            },
            {
                "name": "Rockaway",
                "countryCode": "US"
            },
            {
                "name": "Roebling",
                "countryCode": "US"
            },
            {
                "name": "Roseland",
                "countryCode": "US"
            },
            {
                "name": "Roselle",
                "countryCode": "US"
            },
            {
                "name": "Roselle Park",
                "countryCode": "US"
            },
            {
                "name": "Rosenhayn",
                "countryCode": "US"
            },
            {
                "name": "Rossmoor",
                "countryCode": "US"
            },
            {
                "name": "Rumson",
                "countryCode": "US"
            },
            {
                "name": "Runnemede",
                "countryCode": "US"
            },
            {
                "name": "Rutherford",
                "countryCode": "US"
            },
            {
                "name": "Saddle Brook",
                "countryCode": "US"
            },
            {
                "name": "Saddle River",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salem County",
                "countryCode": "US"
            },
            {
                "name": "Sayreville",
                "countryCode": "US"
            },
            {
                "name": "Sayreville Junction",
                "countryCode": "US"
            },
            {
                "name": "Scotch Plains",
                "countryCode": "US"
            },
            {
                "name": "Sea Bright",
                "countryCode": "US"
            },
            {
                "name": "Sea Girt",
                "countryCode": "US"
            },
            {
                "name": "Sea Isle City",
                "countryCode": "US"
            },
            {
                "name": "Seabrook Farms",
                "countryCode": "US"
            },
            {
                "name": "Seaside Heights",
                "countryCode": "US"
            },
            {
                "name": "Seaside Park",
                "countryCode": "US"
            },
            {
                "name": "Secaucus",
                "countryCode": "US"
            },
            {
                "name": "Sewaren",
                "countryCode": "US"
            },
            {
                "name": "Shark River Hills",
                "countryCode": "US"
            },
            {
                "name": "Ship Bottom",
                "countryCode": "US"
            },
            {
                "name": "Short Hills",
                "countryCode": "US"
            },
            {
                "name": "Shrewsbury",
                "countryCode": "US"
            },
            {
                "name": "Sicklerville",
                "countryCode": "US"
            },
            {
                "name": "Silver Ridge",
                "countryCode": "US"
            },
            {
                "name": "Singac",
                "countryCode": "US"
            },
            {
                "name": "Sixmile Run",
                "countryCode": "US"
            },
            {
                "name": "Smithville",
                "countryCode": "US"
            },
            {
                "name": "Society Hill",
                "countryCode": "US"
            },
            {
                "name": "Somerdale",
                "countryCode": "US"
            },
            {
                "name": "Somers Point",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "Somerset County",
                "countryCode": "US"
            },
            {
                "name": "Somerville",
                "countryCode": "US"
            },
            {
                "name": "South Amboy",
                "countryCode": "US"
            },
            {
                "name": "South Belmar",
                "countryCode": "US"
            },
            {
                "name": "South Bound Brook",
                "countryCode": "US"
            },
            {
                "name": "South Old Bridge",
                "countryCode": "US"
            },
            {
                "name": "South Orange",
                "countryCode": "US"
            },
            {
                "name": "South Plainfield",
                "countryCode": "US"
            },
            {
                "name": "South River",
                "countryCode": "US"
            },
            {
                "name": "South Toms River",
                "countryCode": "US"
            },
            {
                "name": "South Vineland",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spotswood",
                "countryCode": "US"
            },
            {
                "name": "Spring Lake",
                "countryCode": "US"
            },
            {
                "name": "Spring Lake Heights",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stanhope",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Strathmore",
                "countryCode": "US"
            },
            {
                "name": "Succasunna",
                "countryCode": "US"
            },
            {
                "name": "Summit",
                "countryCode": "US"
            },
            {
                "name": "Surf City",
                "countryCode": "US"
            },
            {
                "name": "Sussex",
                "countryCode": "US"
            },
            {
                "name": "Sussex County",
                "countryCode": "US"
            },
            {
                "name": "Swedesboro",
                "countryCode": "US"
            },
            {
                "name": "Teaneck",
                "countryCode": "US"
            },
            {
                "name": "Ten Mile Run",
                "countryCode": "US"
            },
            {
                "name": "Tenafly",
                "countryCode": "US"
            },
            {
                "name": "Tinton Falls",
                "countryCode": "US"
            },
            {
                "name": "Toms River",
                "countryCode": "US"
            },
            {
                "name": "Totowa",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Tuckerton",
                "countryCode": "US"
            },
            {
                "name": "Turnersville",
                "countryCode": "US"
            },
            {
                "name": "Twin Rivers",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union Beach",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Upper Montclair",
                "countryCode": "US"
            },
            {
                "name": "Upper Pohatcong",
                "countryCode": "US"
            },
            {
                "name": "Upper Saddle River",
                "countryCode": "US"
            },
            {
                "name": "Ventnor City",
                "countryCode": "US"
            },
            {
                "name": "Vernon Center",
                "countryCode": "US"
            },
            {
                "name": "Vernon Valley",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Victory Gardens",
                "countryCode": "US"
            },
            {
                "name": "Victory Lakes",
                "countryCode": "US"
            },
            {
                "name": "Villas",
                "countryCode": "US"
            },
            {
                "name": "Vincentown",
                "countryCode": "US"
            },
            {
                "name": "Vineland",
                "countryCode": "US"
            },
            {
                "name": "Vista Center",
                "countryCode": "US"
            },
            {
                "name": "Voorhees",
                "countryCode": "US"
            },
            {
                "name": "Waldwick",
                "countryCode": "US"
            },
            {
                "name": "Wallington",
                "countryCode": "US"
            },
            {
                "name": "Wanamassa",
                "countryCode": "US"
            },
            {
                "name": "Wanaque",
                "countryCode": "US"
            },
            {
                "name": "Waretown",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warren Township",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Watchung",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Weehawken",
                "countryCode": "US"
            },
            {
                "name": "Wenonah",
                "countryCode": "US"
            },
            {
                "name": "West Belmar",
                "countryCode": "US"
            },
            {
                "name": "West Cape May",
                "countryCode": "US"
            },
            {
                "name": "West Freehold",
                "countryCode": "US"
            },
            {
                "name": "West Long Branch",
                "countryCode": "US"
            },
            {
                "name": "West Milford",
                "countryCode": "US"
            },
            {
                "name": "West New York",
                "countryCode": "US"
            },
            {
                "name": "West Orange",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Westville",
                "countryCode": "US"
            },
            {
                "name": "Westwood",
                "countryCode": "US"
            },
            {
                "name": "Wharton",
                "countryCode": "US"
            },
            {
                "name": "White Horse",
                "countryCode": "US"
            },
            {
                "name": "White Meadow Lake",
                "countryCode": "US"
            },
            {
                "name": "Whitehouse Station",
                "countryCode": "US"
            },
            {
                "name": "Whitesboro",
                "countryCode": "US"
            },
            {
                "name": "Whitesboro-Burleigh",
                "countryCode": "US"
            },
            {
                "name": "Whittingham",
                "countryCode": "US"
            },
            {
                "name": "Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Wildwood Crest",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Willingboro",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Wood-Lynne",
                "countryCode": "US"
            },
            {
                "name": "Wood-Ridge",
                "countryCode": "US"
            },
            {
                "name": "Woodbine",
                "countryCode": "US"
            },
            {
                "name": "Woodbridge",
                "countryCode": "US"
            },
            {
                "name": "Woodbury",
                "countryCode": "US"
            },
            {
                "name": "Woodbury Heights",
                "countryCode": "US"
            },
            {
                "name": "Woodcliff Lake",
                "countryCode": "US"
            },
            {
                "name": "Woodland Park",
                "countryCode": "US"
            },
            {
                "name": "Woodstown",
                "countryCode": "US"
            },
            {
                "name": "Wyckoff",
                "countryCode": "US"
            },
            {
                "name": "Yardville",
                "countryCode": "US"
            },
            {
                "name": "Yorketown",
                "countryCode": "US"
            },
            {
                "name": "Agua Fria",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Alamogordo",
                "countryCode": "US"
            },
            {
                "name": "Albuquerque",
                "countryCode": "US"
            },
            {
                "name": "Angel Fire",
                "countryCode": "US"
            },
            {
                "name": "Anthony",
                "countryCode": "US"
            },
            {
                "name": "Arenas Valley",
                "countryCode": "US"
            },
            {
                "name": "Arroyo Seco",
                "countryCode": "US"
            },
            {
                "name": "Artesia",
                "countryCode": "US"
            },
            {
                "name": "Atoka",
                "countryCode": "US"
            },
            {
                "name": "Aztec",
                "countryCode": "US"
            },
            {
                "name": "Bayard",
                "countryCode": "US"
            },
            {
                "name": "Belen",
                "countryCode": "US"
            },
            {
                "name": "Berino",
                "countryCode": "US"
            },
            {
                "name": "Bernalillo",
                "countryCode": "US"
            },
            {
                "name": "Bernalillo County",
                "countryCode": "US"
            },
            {
                "name": "Black Rock",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Boles Acres",
                "countryCode": "US"
            },
            {
                "name": "Bosque Farms",
                "countryCode": "US"
            },
            {
                "name": "Cannon Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Capitan",
                "countryCode": "US"
            },
            {
                "name": "Carlsbad",
                "countryCode": "US"
            },
            {
                "name": "Carnuel",
                "countryCode": "US"
            },
            {
                "name": "Carrizozo",
                "countryCode": "US"
            },
            {
                "name": "Catron County",
                "countryCode": "US"
            },
            {
                "name": "Chama",
                "countryCode": "US"
            },
            {
                "name": "Chaparral",
                "countryCode": "US"
            },
            {
                "name": "Chaves County",
                "countryCode": "US"
            },
            {
                "name": "Chimayo",
                "countryCode": "US"
            },
            {
                "name": "Church Rock",
                "countryCode": "US"
            },
            {
                "name": "Cibola County",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clovis",
                "countryCode": "US"
            },
            {
                "name": "Colfax County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Corrales",
                "countryCode": "US"
            },
            {
                "name": "Crownpoint",
                "countryCode": "US"
            },
            {
                "name": "Curry County",
                "countryCode": "US"
            },
            {
                "name": "De Baca County",
                "countryCode": "US"
            },
            {
                "name": "Deming",
                "countryCode": "US"
            },
            {
                "name": "Dexter",
                "countryCode": "US"
            },
            {
                "name": "Doña Ana",
                "countryCode": "US"
            },
            {
                "name": "Doña Ana County",
                "countryCode": "US"
            },
            {
                "name": "Dulce",
                "countryCode": "US"
            },
            {
                "name": "Eddy County",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "El Cerro",
                "countryCode": "US"
            },
            {
                "name": "El Cerro Mission",
                "countryCode": "US"
            },
            {
                "name": "El Rancho",
                "countryCode": "US"
            },
            {
                "name": "El Valle de Arroyo Seco",
                "countryCode": "US"
            },
            {
                "name": "Eldorado at Santa Fe",
                "countryCode": "US"
            },
            {
                "name": "Elephant Butte",
                "countryCode": "US"
            },
            {
                "name": "Enchanted Hills",
                "countryCode": "US"
            },
            {
                "name": "Española",
                "countryCode": "US"
            },
            {
                "name": "Estancia",
                "countryCode": "US"
            },
            {
                "name": "Eunice",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Flora Vista",
                "countryCode": "US"
            },
            {
                "name": "Fort Sumner",
                "countryCode": "US"
            },
            {
                "name": "Gallup",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Grants",
                "countryCode": "US"
            },
            {
                "name": "Guadalupe County",
                "countryCode": "US"
            },
            {
                "name": "Hagerman",
                "countryCode": "US"
            },
            {
                "name": "Harding County",
                "countryCode": "US"
            },
            {
                "name": "Hatch",
                "countryCode": "US"
            },
            {
                "name": "Hidalgo County",
                "countryCode": "US"
            },
            {
                "name": "Hobbs",
                "countryCode": "US"
            },
            {
                "name": "Holloman Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Hurley",
                "countryCode": "US"
            },
            {
                "name": "Jal",
                "countryCode": "US"
            },
            {
                "name": "Jarales",
                "countryCode": "US"
            },
            {
                "name": "Jemez Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Keeler Farm",
                "countryCode": "US"
            },
            {
                "name": "Kirtland",
                "countryCode": "US"
            },
            {
                "name": "La Cienega",
                "countryCode": "US"
            },
            {
                "name": "La Huerta",
                "countryCode": "US"
            },
            {
                "name": "La Luz",
                "countryCode": "US"
            },
            {
                "name": "La Mesilla",
                "countryCode": "US"
            },
            {
                "name": "La Puebla",
                "countryCode": "US"
            },
            {
                "name": "La Union",
                "countryCode": "US"
            },
            {
                "name": "Laguna",
                "countryCode": "US"
            },
            {
                "name": "Las Cruces",
                "countryCode": "US"
            },
            {
                "name": "Las Maravillas",
                "countryCode": "US"
            },
            {
                "name": "Las Vegas",
                "countryCode": "US"
            },
            {
                "name": "Lea County",
                "countryCode": "US"
            },
            {
                "name": "Lee Acres",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lordsburg",
                "countryCode": "US"
            },
            {
                "name": "Los Alamos",
                "countryCode": "US"
            },
            {
                "name": "Los Alamos County",
                "countryCode": "US"
            },
            {
                "name": "Los Chavez",
                "countryCode": "US"
            },
            {
                "name": "Los Lunas",
                "countryCode": "US"
            },
            {
                "name": "Los Ranchos de Albuquerque",
                "countryCode": "US"
            },
            {
                "name": "Loving",
                "countryCode": "US"
            },
            {
                "name": "Lovington",
                "countryCode": "US"
            },
            {
                "name": "Luna County",
                "countryCode": "US"
            },
            {
                "name": "McIntosh",
                "countryCode": "US"
            },
            {
                "name": "McKinley County",
                "countryCode": "US"
            },
            {
                "name": "Meadow Lake",
                "countryCode": "US"
            },
            {
                "name": "Mescalero",
                "countryCode": "US"
            },
            {
                "name": "Mesilla",
                "countryCode": "US"
            },
            {
                "name": "Mesquite",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Monterey Park",
                "countryCode": "US"
            },
            {
                "name": "Mora",
                "countryCode": "US"
            },
            {
                "name": "Mora County",
                "countryCode": "US"
            },
            {
                "name": "Moriarty",
                "countryCode": "US"
            },
            {
                "name": "Mosquero",
                "countryCode": "US"
            },
            {
                "name": "Nambe",
                "countryCode": "US"
            },
            {
                "name": "Navajo",
                "countryCode": "US"
            },
            {
                "name": "North Valley",
                "countryCode": "US"
            },
            {
                "name": "Ohkay Owingeh",
                "countryCode": "US"
            },
            {
                "name": "Otero County",
                "countryCode": "US"
            },
            {
                "name": "Paradise Hills",
                "countryCode": "US"
            },
            {
                "name": "Pecos",
                "countryCode": "US"
            },
            {
                "name": "Peralta",
                "countryCode": "US"
            },
            {
                "name": "Placitas",
                "countryCode": "US"
            },
            {
                "name": "Pojoaque",
                "countryCode": "US"
            },
            {
                "name": "Ponderosa Pine",
                "countryCode": "US"
            },
            {
                "name": "Portales",
                "countryCode": "US"
            },
            {
                "name": "Quay County",
                "countryCode": "US"
            },
            {
                "name": "Questa",
                "countryCode": "US"
            },
            {
                "name": "Radium Springs",
                "countryCode": "US"
            },
            {
                "name": "Ranchos de Taos",
                "countryCode": "US"
            },
            {
                "name": "Raton",
                "countryCode": "US"
            },
            {
                "name": "Reserve",
                "countryCode": "US"
            },
            {
                "name": "Rio Arriba County",
                "countryCode": "US"
            },
            {
                "name": "Rio Communities",
                "countryCode": "US"
            },
            {
                "name": "Rio Rancho",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt County",
                "countryCode": "US"
            },
            {
                "name": "Roswell",
                "countryCode": "US"
            },
            {
                "name": "Ruidoso",
                "countryCode": "US"
            },
            {
                "name": "Ruidoso Downs",
                "countryCode": "US"
            },
            {
                "name": "San Felipe Pueblo",
                "countryCode": "US"
            },
            {
                "name": "San Juan County",
                "countryCode": "US"
            },
            {
                "name": "San Miguel",
                "countryCode": "US"
            },
            {
                "name": "San Miguel County",
                "countryCode": "US"
            },
            {
                "name": "San Ysidro",
                "countryCode": "US"
            },
            {
                "name": "Sandia Heights",
                "countryCode": "US"
            },
            {
                "name": "Sandia Knolls",
                "countryCode": "US"
            },
            {
                "name": "Sandoval County",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Santa Fe",
                "countryCode": "US"
            },
            {
                "name": "Santa Fe County",
                "countryCode": "US"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "US"
            },
            {
                "name": "Santa Teresa",
                "countryCode": "US"
            },
            {
                "name": "Santo Domingo Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Shiprock",
                "countryCode": "US"
            },
            {
                "name": "Sierra County",
                "countryCode": "US"
            },
            {
                "name": "Silver City",
                "countryCode": "US"
            },
            {
                "name": "Skyline-Ganipa",
                "countryCode": "US"
            },
            {
                "name": "Socorro",
                "countryCode": "US"
            },
            {
                "name": "Socorro County",
                "countryCode": "US"
            },
            {
                "name": "South Valley",
                "countryCode": "US"
            },
            {
                "name": "Spencerville",
                "countryCode": "US"
            },
            {
                "name": "Sunland Park",
                "countryCode": "US"
            },
            {
                "name": "Taos",
                "countryCode": "US"
            },
            {
                "name": "Taos County",
                "countryCode": "US"
            },
            {
                "name": "Taos Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Texico",
                "countryCode": "US"
            },
            {
                "name": "Thoreau",
                "countryCode": "US"
            },
            {
                "name": "Tierra Amarilla",
                "countryCode": "US"
            },
            {
                "name": "Tome",
                "countryCode": "US"
            },
            {
                "name": "Torrance County",
                "countryCode": "US"
            },
            {
                "name": "Truth or Consequences",
                "countryCode": "US"
            },
            {
                "name": "Tucumcari",
                "countryCode": "US"
            },
            {
                "name": "Tularosa",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "University Park",
                "countryCode": "US"
            },
            {
                "name": "Upper Fruitland",
                "countryCode": "US"
            },
            {
                "name": "Vado",
                "countryCode": "US"
            },
            {
                "name": "Valencia",
                "countryCode": "US"
            },
            {
                "name": "Valencia County",
                "countryCode": "US"
            },
            {
                "name": "Waterflow",
                "countryCode": "US"
            },
            {
                "name": "West Hammond",
                "countryCode": "US"
            },
            {
                "name": "White Rock",
                "countryCode": "US"
            },
            {
                "name": "White Sands",
                "countryCode": "US"
            },
            {
                "name": "Zuni Pueblo",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Battle Mountain",
                "countryCode": "US"
            },
            {
                "name": "Beatty",
                "countryCode": "US"
            },
            {
                "name": "Boulder City",
                "countryCode": "US"
            },
            {
                "name": "Bunkerville",
                "countryCode": "US"
            },
            {
                "name": "Caliente",
                "countryCode": "US"
            },
            {
                "name": "Carlin",
                "countryCode": "US"
            },
            {
                "name": "Carson City",
                "countryCode": "US"
            },
            {
                "name": "Churchill County",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Cold Springs",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "East Valley",
                "countryCode": "US"
            },
            {
                "name": "Elko",
                "countryCode": "US"
            },
            {
                "name": "Elko County",
                "countryCode": "US"
            },
            {
                "name": "Ely",
                "countryCode": "US"
            },
            {
                "name": "Enterprise",
                "countryCode": "US"
            },
            {
                "name": "Esmeralda County",
                "countryCode": "US"
            },
            {
                "name": "Eureka",
                "countryCode": "US"
            },
            {
                "name": "Eureka County",
                "countryCode": "US"
            },
            {
                "name": "Fallon",
                "countryCode": "US"
            },
            {
                "name": "Fernley",
                "countryCode": "US"
            },
            {
                "name": "Gardnerville",
                "countryCode": "US"
            },
            {
                "name": "Gardnerville Ranchos",
                "countryCode": "US"
            },
            {
                "name": "Golden Valley",
                "countryCode": "US"
            },
            {
                "name": "Goldfield",
                "countryCode": "US"
            },
            {
                "name": "Hawthorne",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Humboldt County",
                "countryCode": "US"
            },
            {
                "name": "Incline Village",
                "countryCode": "US"
            },
            {
                "name": "Indian Hills",
                "countryCode": "US"
            },
            {
                "name": "Jackpot",
                "countryCode": "US"
            },
            {
                "name": "Johnson Lane",
                "countryCode": "US"
            },
            {
                "name": "Kingsbury",
                "countryCode": "US"
            },
            {
                "name": "Lander County",
                "countryCode": "US"
            },
            {
                "name": "Las Vegas",
                "countryCode": "US"
            },
            {
                "name": "Laughlin",
                "countryCode": "US"
            },
            {
                "name": "Lemmon Valley",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lovelock",
                "countryCode": "US"
            },
            {
                "name": "Lyon County",
                "countryCode": "US"
            },
            {
                "name": "McGill",
                "countryCode": "US"
            },
            {
                "name": "Mesquite",
                "countryCode": "US"
            },
            {
                "name": "Minden",
                "countryCode": "US"
            },
            {
                "name": "Mineral County",
                "countryCode": "US"
            },
            {
                "name": "Moapa Town",
                "countryCode": "US"
            },
            {
                "name": "Moapa Valley",
                "countryCode": "US"
            },
            {
                "name": "Mogul",
                "countryCode": "US"
            },
            {
                "name": "Nellis Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "North Las Vegas",
                "countryCode": "US"
            },
            {
                "name": "Nye County",
                "countryCode": "US"
            },
            {
                "name": "Pahrump",
                "countryCode": "US"
            },
            {
                "name": "Paradise",
                "countryCode": "US"
            },
            {
                "name": "Pershing County",
                "countryCode": "US"
            },
            {
                "name": "Pioche",
                "countryCode": "US"
            },
            {
                "name": "Reno",
                "countryCode": "US"
            },
            {
                "name": "Sandy Valley",
                "countryCode": "US"
            },
            {
                "name": "Silver Springs",
                "countryCode": "US"
            },
            {
                "name": "Smith",
                "countryCode": "US"
            },
            {
                "name": "Smith Valley",
                "countryCode": "US"
            },
            {
                "name": "Spanish Springs",
                "countryCode": "US"
            },
            {
                "name": "Sparks",
                "countryCode": "US"
            },
            {
                "name": "Spring Creek",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Stagecoach",
                "countryCode": "US"
            },
            {
                "name": "Storey County",
                "countryCode": "US"
            },
            {
                "name": "Summerlin South",
                "countryCode": "US"
            },
            {
                "name": "Sun Valley",
                "countryCode": "US"
            },
            {
                "name": "Sunrise Manor",
                "countryCode": "US"
            },
            {
                "name": "Tonopah",
                "countryCode": "US"
            },
            {
                "name": "Topaz Ranch Estates",
                "countryCode": "US"
            },
            {
                "name": "Verdi",
                "countryCode": "US"
            },
            {
                "name": "Virginia City",
                "countryCode": "US"
            },
            {
                "name": "Washoe County",
                "countryCode": "US"
            },
            {
                "name": "Wells",
                "countryCode": "US"
            },
            {
                "name": "West Wendover",
                "countryCode": "US"
            },
            {
                "name": "White Pine County",
                "countryCode": "US"
            },
            {
                "name": "Whitney",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Winnemucca",
                "countryCode": "US"
            },
            {
                "name": "Yerington",
                "countryCode": "US"
            },
            {
                "name": "Adams",
                "countryCode": "US"
            },
            {
                "name": "Adams Center",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Airmont",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Alabama",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Albany County",
                "countryCode": "US"
            },
            {
                "name": "Albertson",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alden",
                "countryCode": "US"
            },
            {
                "name": "Alexandria Bay",
                "countryCode": "US"
            },
            {
                "name": "Alfred",
                "countryCode": "US"
            },
            {
                "name": "Allegany",
                "countryCode": "US"
            },
            {
                "name": "Allegany County",
                "countryCode": "US"
            },
            {
                "name": "Altamont",
                "countryCode": "US"
            },
            {
                "name": "Amagansett",
                "countryCode": "US"
            },
            {
                "name": "Amherst",
                "countryCode": "US"
            },
            {
                "name": "Amityville",
                "countryCode": "US"
            },
            {
                "name": "Amsterdam",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Angola",
                "countryCode": "US"
            },
            {
                "name": "Angola on the Lake",
                "countryCode": "US"
            },
            {
                "name": "Apalachin",
                "countryCode": "US"
            },
            {
                "name": "Aquebogue",
                "countryCode": "US"
            },
            {
                "name": "Arcade",
                "countryCode": "US"
            },
            {
                "name": "Ardsley",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Armonk",
                "countryCode": "US"
            },
            {
                "name": "Arrochar",
                "countryCode": "US"
            },
            {
                "name": "Arverne",
                "countryCode": "US"
            },
            {
                "name": "Astoria",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atlantic Beach",
                "countryCode": "US"
            },
            {
                "name": "Attica",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Averill Park",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Babylon",
                "countryCode": "US"
            },
            {
                "name": "Bainbridge",
                "countryCode": "US"
            },
            {
                "name": "Baiting Hollow",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Baldwin Harbor",
                "countryCode": "US"
            },
            {
                "name": "Baldwinsville",
                "countryCode": "US"
            },
            {
                "name": "Ballston Spa",
                "countryCode": "US"
            },
            {
                "name": "Balmville",
                "countryCode": "US"
            },
            {
                "name": "Bardonia",
                "countryCode": "US"
            },
            {
                "name": "Barnum Island",
                "countryCode": "US"
            },
            {
                "name": "Batavia",
                "countryCode": "US"
            },
            {
                "name": "Bath",
                "countryCode": "US"
            },
            {
                "name": "Bath Beach",
                "countryCode": "US"
            },
            {
                "name": "Baxter Estates",
                "countryCode": "US"
            },
            {
                "name": "Bay Park",
                "countryCode": "US"
            },
            {
                "name": "Bay Shore",
                "countryCode": "US"
            },
            {
                "name": "Bay Wood",
                "countryCode": "US"
            },
            {
                "name": "Baychester",
                "countryCode": "US"
            },
            {
                "name": "Bayport",
                "countryCode": "US"
            },
            {
                "name": "Bayside",
                "countryCode": "US"
            },
            {
                "name": "Bayville",
                "countryCode": "US"
            },
            {
                "name": "Beacon",
                "countryCode": "US"
            },
            {
                "name": "Beaver Dam Lake",
                "countryCode": "US"
            },
            {
                "name": "Beaverdam Lake-Salisbury Mills",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Bedford Hills",
                "countryCode": "US"
            },
            {
                "name": "Bellaire",
                "countryCode": "US"
            },
            {
                "name": "Belle Harbor",
                "countryCode": "US"
            },
            {
                "name": "Bellerose",
                "countryCode": "US"
            },
            {
                "name": "Bellerose Terrace",
                "countryCode": "US"
            },
            {
                "name": "Bellmore",
                "countryCode": "US"
            },
            {
                "name": "Bellport",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Bensonhurst",
                "countryCode": "US"
            },
            {
                "name": "Bergen",
                "countryCode": "US"
            },
            {
                "name": "Bergen Beach",
                "countryCode": "US"
            },
            {
                "name": "Bethpage",
                "countryCode": "US"
            },
            {
                "name": "Big Flats",
                "countryCode": "US"
            },
            {
                "name": "Billington Heights",
                "countryCode": "US"
            },
            {
                "name": "Binghamton",
                "countryCode": "US"
            },
            {
                "name": "Black River",
                "countryCode": "US"
            },
            {
                "name": "Blasdell",
                "countryCode": "US"
            },
            {
                "name": "Blauvelt",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Blue Point",
                "countryCode": "US"
            },
            {
                "name": "Bohemia",
                "countryCode": "US"
            },
            {
                "name": "Bolivar",
                "countryCode": "US"
            },
            {
                "name": "Boonville",
                "countryCode": "US"
            },
            {
                "name": "Borough Park",
                "countryCode": "US"
            },
            {
                "name": "Boston",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Brewerton",
                "countryCode": "US"
            },
            {
                "name": "Brewster",
                "countryCode": "US"
            },
            {
                "name": "Brewster Hill",
                "countryCode": "US"
            },
            {
                "name": "Briarcliff Manor",
                "countryCode": "US"
            },
            {
                "name": "Briarwood",
                "countryCode": "US"
            },
            {
                "name": "Bridgehampton",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Brighton Beach",
                "countryCode": "US"
            },
            {
                "name": "Brightwaters",
                "countryCode": "US"
            },
            {
                "name": "Brinckerhoff",
                "countryCode": "US"
            },
            {
                "name": "Broad Channel",
                "countryCode": "US"
            },
            {
                "name": "Broadalbin",
                "countryCode": "US"
            },
            {
                "name": "Brockport",
                "countryCode": "US"
            },
            {
                "name": "Brocton",
                "countryCode": "US"
            },
            {
                "name": "Bronx",
                "countryCode": "US"
            },
            {
                "name": "Bronxville",
                "countryCode": "US"
            },
            {
                "name": "Brookhaven",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn Heights",
                "countryCode": "US"
            },
            {
                "name": "Brookville",
                "countryCode": "US"
            },
            {
                "name": "Broome County",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Brownville",
                "countryCode": "US"
            },
            {
                "name": "Buchanan",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Bushwick",
                "countryCode": "US"
            },
            {
                "name": "Cairo",
                "countryCode": "US"
            },
            {
                "name": "Calcium",
                "countryCode": "US"
            },
            {
                "name": "Caledonia",
                "countryCode": "US"
            },
            {
                "name": "Calverton",
                "countryCode": "US"
            },
            {
                "name": "Cambria Heights",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Camillus",
                "countryCode": "US"
            },
            {
                "name": "Canajoharie",
                "countryCode": "US"
            },
            {
                "name": "Canandaigua",
                "countryCode": "US"
            },
            {
                "name": "Canarsie",
                "countryCode": "US"
            },
            {
                "name": "Canastota",
                "countryCode": "US"
            },
            {
                "name": "Canisteo",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Carle Place",
                "countryCode": "US"
            },
            {
                "name": "Carmel",
                "countryCode": "US"
            },
            {
                "name": "Carmel Hamlet",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Castleton-on-Hudson",
                "countryCode": "US"
            },
            {
                "name": "Catskill",
                "countryCode": "US"
            },
            {
                "name": "Cattaraugus County",
                "countryCode": "US"
            },
            {
                "name": "Cayuga County",
                "countryCode": "US"
            },
            {
                "name": "Cayuga Heights",
                "countryCode": "US"
            },
            {
                "name": "Cazenovia",
                "countryCode": "US"
            },
            {
                "name": "Cedarhurst",
                "countryCode": "US"
            },
            {
                "name": "Celoron",
                "countryCode": "US"
            },
            {
                "name": "Center Moriches",
                "countryCode": "US"
            },
            {
                "name": "Centereach",
                "countryCode": "US"
            },
            {
                "name": "Centerport",
                "countryCode": "US"
            },
            {
                "name": "Central Islip",
                "countryCode": "US"
            },
            {
                "name": "Central Square",
                "countryCode": "US"
            },
            {
                "name": "Central Valley",
                "countryCode": "US"
            },
            {
                "name": "Chadwicks",
                "countryCode": "US"
            },
            {
                "name": "Champlain",
                "countryCode": "US"
            },
            {
                "name": "Chappaqua",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Chatham",
                "countryCode": "US"
            },
            {
                "name": "Chautauqua County",
                "countryCode": "US"
            },
            {
                "name": "Cheektowaga",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Chemung County",
                "countryCode": "US"
            },
            {
                "name": "Chenango Bridge",
                "countryCode": "US"
            },
            {
                "name": "Chenango County",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chestnut Ridge",
                "countryCode": "US"
            },
            {
                "name": "Chittenango",
                "countryCode": "US"
            },
            {
                "name": "Churchville",
                "countryCode": "US"
            },
            {
                "name": "City Island",
                "countryCode": "US"
            },
            {
                "name": "Clarence",
                "countryCode": "US"
            },
            {
                "name": "Clarence Center",
                "countryCode": "US"
            },
            {
                "name": "Clark Mills",
                "countryCode": "US"
            },
            {
                "name": "Clarkson",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Clifton Springs",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Clintondale",
                "countryCode": "US"
            },
            {
                "name": "Clyde",
                "countryCode": "US"
            },
            {
                "name": "Clymer",
                "countryCode": "US"
            },
            {
                "name": "Cobleskill",
                "countryCode": "US"
            },
            {
                "name": "Cohoes",
                "countryCode": "US"
            },
            {
                "name": "Cold Spring",
                "countryCode": "US"
            },
            {
                "name": "Cold Spring Harbor",
                "countryCode": "US"
            },
            {
                "name": "College Point",
                "countryCode": "US"
            },
            {
                "name": "Colonie",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Commack",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Coney Island",
                "countryCode": "US"
            },
            {
                "name": "Congers",
                "countryCode": "US"
            },
            {
                "name": "Constantia",
                "countryCode": "US"
            },
            {
                "name": "Cooperstown",
                "countryCode": "US"
            },
            {
                "name": "Copiague",
                "countryCode": "US"
            },
            {
                "name": "Coram",
                "countryCode": "US"
            },
            {
                "name": "Corinth",
                "countryCode": "US"
            },
            {
                "name": "Corning",
                "countryCode": "US"
            },
            {
                "name": "Cornwall",
                "countryCode": "US"
            },
            {
                "name": "Corona",
                "countryCode": "US"
            },
            {
                "name": "Cortland",
                "countryCode": "US"
            },
            {
                "name": "Cortland County",
                "countryCode": "US"
            },
            {
                "name": "Cortland West",
                "countryCode": "US"
            },
            {
                "name": "Country Knolls",
                "countryCode": "US"
            },
            {
                "name": "Coxsackie",
                "countryCode": "US"
            },
            {
                "name": "Crompond",
                "countryCode": "US"
            },
            {
                "name": "Croton-on-Hudson",
                "countryCode": "US"
            },
            {
                "name": "Crown Heights",
                "countryCode": "US"
            },
            {
                "name": "Crugers",
                "countryCode": "US"
            },
            {
                "name": "Cuba",
                "countryCode": "US"
            },
            {
                "name": "Cumberland Head",
                "countryCode": "US"
            },
            {
                "name": "Cutchogue",
                "countryCode": "US"
            },
            {
                "name": "Cypress Hills",
                "countryCode": "US"
            },
            {
                "name": "Dannemora",
                "countryCode": "US"
            },
            {
                "name": "Dansville",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Delevan",
                "countryCode": "US"
            },
            {
                "name": "Delhi",
                "countryCode": "US"
            },
            {
                "name": "Delmar",
                "countryCode": "US"
            },
            {
                "name": "Depew",
                "countryCode": "US"
            },
            {
                "name": "Deposit",
                "countryCode": "US"
            },
            {
                "name": "Dexter",
                "countryCode": "US"
            },
            {
                "name": "Dix Hills",
                "countryCode": "US"
            },
            {
                "name": "Dobbs Ferry",
                "countryCode": "US"
            },
            {
                "name": "Dolgeville",
                "countryCode": "US"
            },
            {
                "name": "Dongan Hills",
                "countryCode": "US"
            },
            {
                "name": "Douglaston",
                "countryCode": "US"
            },
            {
                "name": "Dover Plains",
                "countryCode": "US"
            },
            {
                "name": "Dryden",
                "countryCode": "US"
            },
            {
                "name": "Dundee",
                "countryCode": "US"
            },
            {
                "name": "Dunkirk",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "Dutchess County",
                "countryCode": "US"
            },
            {
                "name": "Dyker Heights",
                "countryCode": "US"
            },
            {
                "name": "East Atlantic Beach",
                "countryCode": "US"
            },
            {
                "name": "East Aurora",
                "countryCode": "US"
            },
            {
                "name": "East Elmhurst",
                "countryCode": "US"
            },
            {
                "name": "East Farmingdale",
                "countryCode": "US"
            },
            {
                "name": "East Flatbush",
                "countryCode": "US"
            },
            {
                "name": "East Garden City",
                "countryCode": "US"
            },
            {
                "name": "East Glenville",
                "countryCode": "US"
            },
            {
                "name": "East Greenbush",
                "countryCode": "US"
            },
            {
                "name": "East Hampton",
                "countryCode": "US"
            },
            {
                "name": "East Hampton North",
                "countryCode": "US"
            },
            {
                "name": "East Harlem",
                "countryCode": "US"
            },
            {
                "name": "East Hills",
                "countryCode": "US"
            },
            {
                "name": "East Islip",
                "countryCode": "US"
            },
            {
                "name": "East Ithaca",
                "countryCode": "US"
            },
            {
                "name": "East Massapequa",
                "countryCode": "US"
            },
            {
                "name": "East Meadow",
                "countryCode": "US"
            },
            {
                "name": "East Moriches",
                "countryCode": "US"
            },
            {
                "name": "East New York",
                "countryCode": "US"
            },
            {
                "name": "East Northport",
                "countryCode": "US"
            },
            {
                "name": "East Norwich",
                "countryCode": "US"
            },
            {
                "name": "East Patchogue",
                "countryCode": "US"
            },
            {
                "name": "East Quogue",
                "countryCode": "US"
            },
            {
                "name": "East Rochester",
                "countryCode": "US"
            },
            {
                "name": "East Rockaway",
                "countryCode": "US"
            },
            {
                "name": "East Setauket",
                "countryCode": "US"
            },
            {
                "name": "East Shoreham",
                "countryCode": "US"
            },
            {
                "name": "East Syracuse",
                "countryCode": "US"
            },
            {
                "name": "East Tremont",
                "countryCode": "US"
            },
            {
                "name": "East Village",
                "countryCode": "US"
            },
            {
                "name": "East Williston",
                "countryCode": "US"
            },
            {
                "name": "Eastchester",
                "countryCode": "US"
            },
            {
                "name": "Eastport",
                "countryCode": "US"
            },
            {
                "name": "Eatons Neck",
                "countryCode": "US"
            },
            {
                "name": "Eden",
                "countryCode": "US"
            },
            {
                "name": "Edgemere",
                "countryCode": "US"
            },
            {
                "name": "Edinburg",
                "countryCode": "US"
            },
            {
                "name": "Eggertsville",
                "countryCode": "US"
            },
            {
                "name": "Elbridge",
                "countryCode": "US"
            },
            {
                "name": "Elizabethtown",
                "countryCode": "US"
            },
            {
                "name": "Ellenville",
                "countryCode": "US"
            },
            {
                "name": "Elma Center",
                "countryCode": "US"
            },
            {
                "name": "Elmhurst",
                "countryCode": "US"
            },
            {
                "name": "Elmira",
                "countryCode": "US"
            },
            {
                "name": "Elmira Heights",
                "countryCode": "US"
            },
            {
                "name": "Elmont",
                "countryCode": "US"
            },
            {
                "name": "Elmsford",
                "countryCode": "US"
            },
            {
                "name": "Eltingville",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Emerson Hill",
                "countryCode": "US"
            },
            {
                "name": "Endicott",
                "countryCode": "US"
            },
            {
                "name": "Endwell",
                "countryCode": "US"
            },
            {
                "name": "Erie County",
                "countryCode": "US"
            },
            {
                "name": "Essex County",
                "countryCode": "US"
            },
            {
                "name": "Fairmount",
                "countryCode": "US"
            },
            {
                "name": "Fairport",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Falconer",
                "countryCode": "US"
            },
            {
                "name": "Fallsburg",
                "countryCode": "US"
            },
            {
                "name": "Far Rockaway",
                "countryCode": "US"
            },
            {
                "name": "Farmingdale",
                "countryCode": "US"
            },
            {
                "name": "Farmingville",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Financial District",
                "countryCode": "US"
            },
            {
                "name": "Firthcliffe",
                "countryCode": "US"
            },
            {
                "name": "Fishkill",
                "countryCode": "US"
            },
            {
                "name": "Flanders",
                "countryCode": "US"
            },
            {
                "name": "Flatbush",
                "countryCode": "US"
            },
            {
                "name": "Flatlands",
                "countryCode": "US"
            },
            {
                "name": "Floral Park",
                "countryCode": "US"
            },
            {
                "name": "Florida",
                "countryCode": "US"
            },
            {
                "name": "Flower Hill",
                "countryCode": "US"
            },
            {
                "name": "Fonda",
                "countryCode": "US"
            },
            {
                "name": "Fordham",
                "countryCode": "US"
            },
            {
                "name": "Forest Hills",
                "countryCode": "US"
            },
            {
                "name": "Fort Covington Hamlet",
                "countryCode": "US"
            },
            {
                "name": "Fort Drum",
                "countryCode": "US"
            },
            {
                "name": "Fort Edward",
                "countryCode": "US"
            },
            {
                "name": "Fort Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Fort Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Fort Plain",
                "countryCode": "US"
            },
            {
                "name": "Fort Salonga",
                "countryCode": "US"
            },
            {
                "name": "Fort Wadsworth",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklin Square",
                "countryCode": "US"
            },
            {
                "name": "Franklinville",
                "countryCode": "US"
            },
            {
                "name": "Fredonia",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Fresh Meadows",
                "countryCode": "US"
            },
            {
                "name": "Frewsburg",
                "countryCode": "US"
            },
            {
                "name": "Friendship",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Galeville",
                "countryCode": "US"
            },
            {
                "name": "Gang Mills",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Garden City Park",
                "countryCode": "US"
            },
            {
                "name": "Garden City South",
                "countryCode": "US"
            },
            {
                "name": "Gardnertown",
                "countryCode": "US"
            },
            {
                "name": "Gasport",
                "countryCode": "US"
            },
            {
                "name": "Gates-North Gates",
                "countryCode": "US"
            },
            {
                "name": "Genesee County",
                "countryCode": "US"
            },
            {
                "name": "Geneseo",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Glasco",
                "countryCode": "US"
            },
            {
                "name": "Glen Cove",
                "countryCode": "US"
            },
            {
                "name": "Glen Head",
                "countryCode": "US"
            },
            {
                "name": "Glen Oaks",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Glens Falls",
                "countryCode": "US"
            },
            {
                "name": "Glens Falls North",
                "countryCode": "US"
            },
            {
                "name": "Glenwood Landing",
                "countryCode": "US"
            },
            {
                "name": "Gloversville",
                "countryCode": "US"
            },
            {
                "name": "Goldens Bridge",
                "countryCode": "US"
            },
            {
                "name": "Gordon Heights",
                "countryCode": "US"
            },
            {
                "name": "Goshen",
                "countryCode": "US"
            },
            {
                "name": "Gouverneur",
                "countryCode": "US"
            },
            {
                "name": "Gowanda",
                "countryCode": "US"
            },
            {
                "name": "Gramercy Park",
                "countryCode": "US"
            },
            {
                "name": "Grand Island",
                "countryCode": "US"
            },
            {
                "name": "Grandyle Village",
                "countryCode": "US"
            },
            {
                "name": "Graniteville",
                "countryCode": "US"
            },
            {
                "name": "Grant City",
                "countryCode": "US"
            },
            {
                "name": "Granville",
                "countryCode": "US"
            },
            {
                "name": "Gravesend",
                "countryCode": "US"
            },
            {
                "name": "Great Kills",
                "countryCode": "US"
            },
            {
                "name": "Great Neck",
                "countryCode": "US"
            },
            {
                "name": "Great Neck Estates",
                "countryCode": "US"
            },
            {
                "name": "Great Neck Gardens",
                "countryCode": "US"
            },
            {
                "name": "Great Neck Plaza",
                "countryCode": "US"
            },
            {
                "name": "Great River",
                "countryCode": "US"
            },
            {
                "name": "Greece",
                "countryCode": "US"
            },
            {
                "name": "Green Island",
                "countryCode": "US"
            },
            {
                "name": "Greenburgh",
                "countryCode": "US"
            },
            {
                "name": "Greene",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenlawn",
                "countryCode": "US"
            },
            {
                "name": "Greenpoint",
                "countryCode": "US"
            },
            {
                "name": "Greenport",
                "countryCode": "US"
            },
            {
                "name": "Greenport West",
                "countryCode": "US"
            },
            {
                "name": "Greenvale",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwich",
                "countryCode": "US"
            },
            {
                "name": "Greenwood Lake",
                "countryCode": "US"
            },
            {
                "name": "Groton",
                "countryCode": "US"
            },
            {
                "name": "Grymes Hill",
                "countryCode": "US"
            },
            {
                "name": "Hadley",
                "countryCode": "US"
            },
            {
                "name": "Hagaman",
                "countryCode": "US"
            },
            {
                "name": "Halesite",
                "countryCode": "US"
            },
            {
                "name": "Hamburg",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hamlin",
                "countryCode": "US"
            },
            {
                "name": "Hampton Bays",
                "countryCode": "US"
            },
            {
                "name": "Hampton Manor",
                "countryCode": "US"
            },
            {
                "name": "Hannawa Falls",
                "countryCode": "US"
            },
            {
                "name": "Harbor Isle",
                "countryCode": "US"
            },
            {
                "name": "Harlem",
                "countryCode": "US"
            },
            {
                "name": "Harriman",
                "countryCode": "US"
            },
            {
                "name": "Harris Hill",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hartsdale",
                "countryCode": "US"
            },
            {
                "name": "Hastings-on-Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hauppauge",
                "countryCode": "US"
            },
            {
                "name": "Haverstraw",
                "countryCode": "US"
            },
            {
                "name": "Haviland",
                "countryCode": "US"
            },
            {
                "name": "Hawthorne",
                "countryCode": "US"
            },
            {
                "name": "Head of the Harbor",
                "countryCode": "US"
            },
            {
                "name": "Hell's Kitchen",
                "countryCode": "US"
            },
            {
                "name": "Hempstead",
                "countryCode": "US"
            },
            {
                "name": "Heritage Hills",
                "countryCode": "US"
            },
            {
                "name": "Herkimer",
                "countryCode": "US"
            },
            {
                "name": "Herkimer County",
                "countryCode": "US"
            },
            {
                "name": "Herricks",
                "countryCode": "US"
            },
            {
                "name": "Hewlett",
                "countryCode": "US"
            },
            {
                "name": "Hewlett Harbor",
                "countryCode": "US"
            },
            {
                "name": "Hicksville",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Highland Falls",
                "countryCode": "US"
            },
            {
                "name": "Highland Mills",
                "countryCode": "US"
            },
            {
                "name": "Hillcrest",
                "countryCode": "US"
            },
            {
                "name": "Hillside",
                "countryCode": "US"
            },
            {
                "name": "Hillside Lake",
                "countryCode": "US"
            },
            {
                "name": "Hilton",
                "countryCode": "US"
            },
            {
                "name": "Holbrook",
                "countryCode": "US"
            },
            {
                "name": "Holcomb",
                "countryCode": "US"
            },
            {
                "name": "Holland",
                "countryCode": "US"
            },
            {
                "name": "Holley",
                "countryCode": "US"
            },
            {
                "name": "Hollis",
                "countryCode": "US"
            },
            {
                "name": "Holtsville",
                "countryCode": "US"
            },
            {
                "name": "Homer",
                "countryCode": "US"
            },
            {
                "name": "Honeoye Falls",
                "countryCode": "US"
            },
            {
                "name": "Hoosick Falls",
                "countryCode": "US"
            },
            {
                "name": "Hornell",
                "countryCode": "US"
            },
            {
                "name": "Horseheads",
                "countryCode": "US"
            },
            {
                "name": "Horseheads North",
                "countryCode": "US"
            },
            {
                "name": "Houghton",
                "countryCode": "US"
            },
            {
                "name": "Howard Beach",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hudson Falls",
                "countryCode": "US"
            },
            {
                "name": "Huguenot",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Huntington Bay",
                "countryCode": "US"
            },
            {
                "name": "Huntington Station",
                "countryCode": "US"
            },
            {
                "name": "Hunts Point",
                "countryCode": "US"
            },
            {
                "name": "Hurley",
                "countryCode": "US"
            },
            {
                "name": "Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "Ilion",
                "countryCode": "US"
            },
            {
                "name": "Inwood",
                "countryCode": "US"
            },
            {
                "name": "Irondequoit",
                "countryCode": "US"
            },
            {
                "name": "Irvington",
                "countryCode": "US"
            },
            {
                "name": "Island Park",
                "countryCode": "US"
            },
            {
                "name": "Islandia",
                "countryCode": "US"
            },
            {
                "name": "Islip",
                "countryCode": "US"
            },
            {
                "name": "Islip Terrace",
                "countryCode": "US"
            },
            {
                "name": "Ithaca",
                "countryCode": "US"
            },
            {
                "name": "Jackson Heights",
                "countryCode": "US"
            },
            {
                "name": "Jamaica",
                "countryCode": "US"
            },
            {
                "name": "Jamesport",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jamestown West",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Heights",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Valley-Yorktown",
                "countryCode": "US"
            },
            {
                "name": "Jericho",
                "countryCode": "US"
            },
            {
                "name": "Johnson City",
                "countryCode": "US"
            },
            {
                "name": "Johnstown",
                "countryCode": "US"
            },
            {
                "name": "Jordan",
                "countryCode": "US"
            },
            {
                "name": "Kaser",
                "countryCode": "US"
            },
            {
                "name": "Katonah",
                "countryCode": "US"
            },
            {
                "name": "Keeseville",
                "countryCode": "US"
            },
            {
                "name": "Kenmore",
                "countryCode": "US"
            },
            {
                "name": "Kensington",
                "countryCode": "US"
            },
            {
                "name": "Kerhonkson",
                "countryCode": "US"
            },
            {
                "name": "Keuka Park",
                "countryCode": "US"
            },
            {
                "name": "Kew Gardens",
                "countryCode": "US"
            },
            {
                "name": "Kew Gardens Hills",
                "countryCode": "US"
            },
            {
                "name": "Kiantone",
                "countryCode": "US"
            },
            {
                "name": "Kinderhook",
                "countryCode": "US"
            },
            {
                "name": "Kings Bridge",
                "countryCode": "US"
            },
            {
                "name": "Kings County",
                "countryCode": "US"
            },
            {
                "name": "Kings Park",
                "countryCode": "US"
            },
            {
                "name": "Kings Point",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kiryas Joel",
                "countryCode": "US"
            },
            {
                "name": "Lackawanna",
                "countryCode": "US"
            },
            {
                "name": "Lake Carmel",
                "countryCode": "US"
            },
            {
                "name": "Lake Erie Beach",
                "countryCode": "US"
            },
            {
                "name": "Lake Grove",
                "countryCode": "US"
            },
            {
                "name": "Lake Katrine",
                "countryCode": "US"
            },
            {
                "name": "Lake Luzerne",
                "countryCode": "US"
            },
            {
                "name": "Lake Mohegan",
                "countryCode": "US"
            },
            {
                "name": "Lake Placid",
                "countryCode": "US"
            },
            {
                "name": "Lake Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Lake Ronkonkoma",
                "countryCode": "US"
            },
            {
                "name": "Lake Success",
                "countryCode": "US"
            },
            {
                "name": "Lakeland",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lansing",
                "countryCode": "US"
            },
            {
                "name": "Larchmont",
                "countryCode": "US"
            },
            {
                "name": "Latham",
                "countryCode": "US"
            },
            {
                "name": "Lattingtown",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Laurel Hollow",
                "countryCode": "US"
            },
            {
                "name": "Laurelton",
                "countryCode": "US"
            },
            {
                "name": "Lawrence",
                "countryCode": "US"
            },
            {
                "name": "Le Roy",
                "countryCode": "US"
            },
            {
                "name": "Levittown",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lido Beach",
                "countryCode": "US"
            },
            {
                "name": "Lima",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Lincolndale",
                "countryCode": "US"
            },
            {
                "name": "Lindenhurst",
                "countryCode": "US"
            },
            {
                "name": "Lindley",
                "countryCode": "US"
            },
            {
                "name": "Little Falls",
                "countryCode": "US"
            },
            {
                "name": "Little Neck",
                "countryCode": "US"
            },
            {
                "name": "Little Valley",
                "countryCode": "US"
            },
            {
                "name": "Liverpool",
                "countryCode": "US"
            },
            {
                "name": "Livingston County",
                "countryCode": "US"
            },
            {
                "name": "Livingston Manor",
                "countryCode": "US"
            },
            {
                "name": "Livonia",
                "countryCode": "US"
            },
            {
                "name": "Lloyd Harbor",
                "countryCode": "US"
            },
            {
                "name": "Lockport",
                "countryCode": "US"
            },
            {
                "name": "Locust Valley",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Long Island City",
                "countryCode": "US"
            },
            {
                "name": "Lorenz Park",
                "countryCode": "US"
            },
            {
                "name": "Lowville",
                "countryCode": "US"
            },
            {
                "name": "Lynbrook",
                "countryCode": "US"
            },
            {
                "name": "Lyncourt",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Macedon",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Mahopac",
                "countryCode": "US"
            },
            {
                "name": "Malone",
                "countryCode": "US"
            },
            {
                "name": "Malverne",
                "countryCode": "US"
            },
            {
                "name": "Mamaroneck",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manhasset",
                "countryCode": "US"
            },
            {
                "name": "Manhasset Hills",
                "countryCode": "US"
            },
            {
                "name": "Manhattan",
                "countryCode": "US"
            },
            {
                "name": "Manlius",
                "countryCode": "US"
            },
            {
                "name": "Manorhaven",
                "countryCode": "US"
            },
            {
                "name": "Manorville",
                "countryCode": "US"
            },
            {
                "name": "Marbletown",
                "countryCode": "US"
            },
            {
                "name": "Marcellus",
                "countryCode": "US"
            },
            {
                "name": "Mariners Harbor",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marlboro",
                "countryCode": "US"
            },
            {
                "name": "Maspeth",
                "countryCode": "US"
            },
            {
                "name": "Massapequa",
                "countryCode": "US"
            },
            {
                "name": "Massapequa Park",
                "countryCode": "US"
            },
            {
                "name": "Massena",
                "countryCode": "US"
            },
            {
                "name": "Mastic",
                "countryCode": "US"
            },
            {
                "name": "Mastic Beach",
                "countryCode": "US"
            },
            {
                "name": "Mattituck",
                "countryCode": "US"
            },
            {
                "name": "Mattydale",
                "countryCode": "US"
            },
            {
                "name": "Maybrook",
                "countryCode": "US"
            },
            {
                "name": "Mayville",
                "countryCode": "US"
            },
            {
                "name": "McGraw",
                "countryCode": "US"
            },
            {
                "name": "McKownville",
                "countryCode": "US"
            },
            {
                "name": "Mechanicstown",
                "countryCode": "US"
            },
            {
                "name": "Mechanicville",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Melrose",
                "countryCode": "US"
            },
            {
                "name": "Melrose Park",
                "countryCode": "US"
            },
            {
                "name": "Melville",
                "countryCode": "US"
            },
            {
                "name": "Menands",
                "countryCode": "US"
            },
            {
                "name": "Merrick",
                "countryCode": "US"
            },
            {
                "name": "Merritt Park",
                "countryCode": "US"
            },
            {
                "name": "Mexico",
                "countryCode": "US"
            },
            {
                "name": "Middle Island",
                "countryCode": "US"
            },
            {
                "name": "Middle Village",
                "countryCode": "US"
            },
            {
                "name": "Middleburgh",
                "countryCode": "US"
            },
            {
                "name": "Middleport",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Midland Beach",
                "countryCode": "US"
            },
            {
                "name": "Mill Neck",
                "countryCode": "US"
            },
            {
                "name": "Millbrook",
                "countryCode": "US"
            },
            {
                "name": "Miller Place",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Mineola",
                "countryCode": "US"
            },
            {
                "name": "Minetto",
                "countryCode": "US"
            },
            {
                "name": "Mineville",
                "countryCode": "US"
            },
            {
                "name": "Minoa",
                "countryCode": "US"
            },
            {
                "name": "Mohawk",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monsey",
                "countryCode": "US"
            },
            {
                "name": "Montauk",
                "countryCode": "US"
            },
            {
                "name": "Montebello",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Montour Falls",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Moravia",
                "countryCode": "US"
            },
            {
                "name": "Moriches",
                "countryCode": "US"
            },
            {
                "name": "Morningside Heights",
                "countryCode": "US"
            },
            {
                "name": "Morris Heights",
                "countryCode": "US"
            },
            {
                "name": "Morris Park",
                "countryCode": "US"
            },
            {
                "name": "Morrisania",
                "countryCode": "US"
            },
            {
                "name": "Morrisonville",
                "countryCode": "US"
            },
            {
                "name": "Morrisville",
                "countryCode": "US"
            },
            {
                "name": "Mott Haven",
                "countryCode": "US"
            },
            {
                "name": "Mount Ivy",
                "countryCode": "US"
            },
            {
                "name": "Mount Kisco",
                "countryCode": "US"
            },
            {
                "name": "Mount Morris",
                "countryCode": "US"
            },
            {
                "name": "Mount Sinai",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mountain Lodge Park",
                "countryCode": "US"
            },
            {
                "name": "Munsey Park",
                "countryCode": "US"
            },
            {
                "name": "Munsons Corners",
                "countryCode": "US"
            },
            {
                "name": "Muttontown",
                "countryCode": "US"
            },
            {
                "name": "Myers Corner",
                "countryCode": "US"
            },
            {
                "name": "Nanuet",
                "countryCode": "US"
            },
            {
                "name": "Napanoch",
                "countryCode": "US"
            },
            {
                "name": "Naples",
                "countryCode": "US"
            },
            {
                "name": "Nassau",
                "countryCode": "US"
            },
            {
                "name": "Nassau County",
                "countryCode": "US"
            },
            {
                "name": "Nedrow",
                "countryCode": "US"
            },
            {
                "name": "Neponsit",
                "countryCode": "US"
            },
            {
                "name": "Nesconset",
                "countryCode": "US"
            },
            {
                "name": "New Brighton",
                "countryCode": "US"
            },
            {
                "name": "New Cassel",
                "countryCode": "US"
            },
            {
                "name": "New City",
                "countryCode": "US"
            },
            {
                "name": "New Dorp",
                "countryCode": "US"
            },
            {
                "name": "New Dorp Beach",
                "countryCode": "US"
            },
            {
                "name": "New Hartford",
                "countryCode": "US"
            },
            {
                "name": "New Hempstead",
                "countryCode": "US"
            },
            {
                "name": "New Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "New Paltz",
                "countryCode": "US"
            },
            {
                "name": "New Rochelle",
                "countryCode": "US"
            },
            {
                "name": "New Springville",
                "countryCode": "US"
            },
            {
                "name": "New Square",
                "countryCode": "US"
            },
            {
                "name": "New Windsor",
                "countryCode": "US"
            },
            {
                "name": "New York City",
                "countryCode": "US"
            },
            {
                "name": "New York County",
                "countryCode": "US"
            },
            {
                "name": "New York Mills",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newburgh",
                "countryCode": "US"
            },
            {
                "name": "Newfane",
                "countryCode": "US"
            },
            {
                "name": "Niagara County",
                "countryCode": "US"
            },
            {
                "name": "Niagara Falls",
                "countryCode": "US"
            },
            {
                "name": "Niskayuna",
                "countryCode": "US"
            },
            {
                "name": "Nissequogue",
                "countryCode": "US"
            },
            {
                "name": "Niverville",
                "countryCode": "US"
            },
            {
                "name": "Norfolk",
                "countryCode": "US"
            },
            {
                "name": "North Amityville",
                "countryCode": "US"
            },
            {
                "name": "North Babylon",
                "countryCode": "US"
            },
            {
                "name": "North Ballston Spa",
                "countryCode": "US"
            },
            {
                "name": "North Bay Shore",
                "countryCode": "US"
            },
            {
                "name": "North Bellmore",
                "countryCode": "US"
            },
            {
                "name": "North Bellport",
                "countryCode": "US"
            },
            {
                "name": "North Boston",
                "countryCode": "US"
            },
            {
                "name": "North Castle",
                "countryCode": "US"
            },
            {
                "name": "North Collins",
                "countryCode": "US"
            },
            {
                "name": "North Elba",
                "countryCode": "US"
            },
            {
                "name": "North Gates",
                "countryCode": "US"
            },
            {
                "name": "North Great River",
                "countryCode": "US"
            },
            {
                "name": "North Hills",
                "countryCode": "US"
            },
            {
                "name": "North Lindenhurst",
                "countryCode": "US"
            },
            {
                "name": "North Massapequa",
                "countryCode": "US"
            },
            {
                "name": "North Merrick",
                "countryCode": "US"
            },
            {
                "name": "North New Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "North Patchogue",
                "countryCode": "US"
            },
            {
                "name": "North Sea",
                "countryCode": "US"
            },
            {
                "name": "North Syracuse",
                "countryCode": "US"
            },
            {
                "name": "North Tonawanda",
                "countryCode": "US"
            },
            {
                "name": "North Valley Stream",
                "countryCode": "US"
            },
            {
                "name": "North Wantagh",
                "countryCode": "US"
            },
            {
                "name": "Northeast Ithaca",
                "countryCode": "US"
            },
            {
                "name": "Northport",
                "countryCode": "US"
            },
            {
                "name": "Northumberland",
                "countryCode": "US"
            },
            {
                "name": "Northville",
                "countryCode": "US"
            },
            {
                "name": "Northwest Harbor",
                "countryCode": "US"
            },
            {
                "name": "Northwest Ithaca",
                "countryCode": "US"
            },
            {
                "name": "Norwich",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Noyack",
                "countryCode": "US"
            },
            {
                "name": "Nunda",
                "countryCode": "US"
            },
            {
                "name": "Nyack",
                "countryCode": "US"
            },
            {
                "name": "Oakdale",
                "countryCode": "US"
            },
            {
                "name": "Oakfield",
                "countryCode": "US"
            },
            {
                "name": "Oakwood",
                "countryCode": "US"
            },
            {
                "name": "Oceanside",
                "countryCode": "US"
            },
            {
                "name": "Ogdensburg",
                "countryCode": "US"
            },
            {
                "name": "Olcott",
                "countryCode": "US"
            },
            {
                "name": "Old Bethpage",
                "countryCode": "US"
            },
            {
                "name": "Old Brookville",
                "countryCode": "US"
            },
            {
                "name": "Old Westbury",
                "countryCode": "US"
            },
            {
                "name": "Olean",
                "countryCode": "US"
            },
            {
                "name": "Oneida",
                "countryCode": "US"
            },
            {
                "name": "Oneida County",
                "countryCode": "US"
            },
            {
                "name": "Oneonta",
                "countryCode": "US"
            },
            {
                "name": "Onondaga County",
                "countryCode": "US"
            },
            {
                "name": "Ontario",
                "countryCode": "US"
            },
            {
                "name": "Ontario County",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orange Lake",
                "countryCode": "US"
            },
            {
                "name": "Orangeburg",
                "countryCode": "US"
            },
            {
                "name": "Orchard Park",
                "countryCode": "US"
            },
            {
                "name": "Oriskany",
                "countryCode": "US"
            },
            {
                "name": "Orleans County",
                "countryCode": "US"
            },
            {
                "name": "Ossining",
                "countryCode": "US"
            },
            {
                "name": "Oswego",
                "countryCode": "US"
            },
            {
                "name": "Oswego County",
                "countryCode": "US"
            },
            {
                "name": "Otisville",
                "countryCode": "US"
            },
            {
                "name": "Otsego County",
                "countryCode": "US"
            },
            {
                "name": "Owego",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Oyster Bay",
                "countryCode": "US"
            },
            {
                "name": "Oyster Bay Cove",
                "countryCode": "US"
            },
            {
                "name": "Ozone Park",
                "countryCode": "US"
            },
            {
                "name": "Painted Post",
                "countryCode": "US"
            },
            {
                "name": "Palenville",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Park Slope",
                "countryCode": "US"
            },
            {
                "name": "Parkchester",
                "countryCode": "US"
            },
            {
                "name": "Patchogue",
                "countryCode": "US"
            },
            {
                "name": "Pawling",
                "countryCode": "US"
            },
            {
                "name": "Peach Lake",
                "countryCode": "US"
            },
            {
                "name": "Pearl River",
                "countryCode": "US"
            },
            {
                "name": "Peekskill",
                "countryCode": "US"
            },
            {
                "name": "Pelham",
                "countryCode": "US"
            },
            {
                "name": "Pelham Manor",
                "countryCode": "US"
            },
            {
                "name": "Penn Yan",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Perth",
                "countryCode": "US"
            },
            {
                "name": "Peru",
                "countryCode": "US"
            },
            {
                "name": "Phelps",
                "countryCode": "US"
            },
            {
                "name": "Philadelphia",
                "countryCode": "US"
            },
            {
                "name": "Philmont",
                "countryCode": "US"
            },
            {
                "name": "Phoenix",
                "countryCode": "US"
            },
            {
                "name": "Piermont",
                "countryCode": "US"
            },
            {
                "name": "Pine Bush",
                "countryCode": "US"
            },
            {
                "name": "Pine Plains",
                "countryCode": "US"
            },
            {
                "name": "Pittsford",
                "countryCode": "US"
            },
            {
                "name": "Plainedge",
                "countryCode": "US"
            },
            {
                "name": "Plainview",
                "countryCode": "US"
            },
            {
                "name": "Plandome",
                "countryCode": "US"
            },
            {
                "name": "Plandome Heights",
                "countryCode": "US"
            },
            {
                "name": "Plattekill",
                "countryCode": "US"
            },
            {
                "name": "Plattsburgh",
                "countryCode": "US"
            },
            {
                "name": "Plattsburgh West",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Valley",
                "countryCode": "US"
            },
            {
                "name": "Pleasantville",
                "countryCode": "US"
            },
            {
                "name": "Poestenkill",
                "countryCode": "US"
            },
            {
                "name": "Point Lookout",
                "countryCode": "US"
            },
            {
                "name": "Pomona",
                "countryCode": "US"
            },
            {
                "name": "Port Byron",
                "countryCode": "US"
            },
            {
                "name": "Port Chester",
                "countryCode": "US"
            },
            {
                "name": "Port Dickinson",
                "countryCode": "US"
            },
            {
                "name": "Port Ewen",
                "countryCode": "US"
            },
            {
                "name": "Port Henry",
                "countryCode": "US"
            },
            {
                "name": "Port Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Port Jefferson Station",
                "countryCode": "US"
            },
            {
                "name": "Port Jervis",
                "countryCode": "US"
            },
            {
                "name": "Port Morris",
                "countryCode": "US"
            },
            {
                "name": "Port Richmond",
                "countryCode": "US"
            },
            {
                "name": "Port Washington",
                "countryCode": "US"
            },
            {
                "name": "Port Washington North",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Potsdam",
                "countryCode": "US"
            },
            {
                "name": "Poughkeepsie",
                "countryCode": "US"
            },
            {
                "name": "Pound Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pulaski",
                "countryCode": "US"
            },
            {
                "name": "Purchase",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Putnam Lake",
                "countryCode": "US"
            },
            {
                "name": "Queens",
                "countryCode": "US"
            },
            {
                "name": "Queens County",
                "countryCode": "US"
            },
            {
                "name": "Queens Village",
                "countryCode": "US"
            },
            {
                "name": "Queensbury",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Ransomville",
                "countryCode": "US"
            },
            {
                "name": "Rapids",
                "countryCode": "US"
            },
            {
                "name": "Ravena",
                "countryCode": "US"
            },
            {
                "name": "Red Hook",
                "countryCode": "US"
            },
            {
                "name": "Red Oaks Mill",
                "countryCode": "US"
            },
            {
                "name": "Rego Park",
                "countryCode": "US"
            },
            {
                "name": "Remsenburg-Speonk",
                "countryCode": "US"
            },
            {
                "name": "Rensselaer",
                "countryCode": "US"
            },
            {
                "name": "Rensselaer County",
                "countryCode": "US"
            },
            {
                "name": "Rhinebeck",
                "countryCode": "US"
            },
            {
                "name": "Richfield Springs",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Richmond County",
                "countryCode": "US"
            },
            {
                "name": "Richmond Hill",
                "countryCode": "US"
            },
            {
                "name": "Ridge",
                "countryCode": "US"
            },
            {
                "name": "Ridgewood",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Riverhead",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rock Hill",
                "countryCode": "US"
            },
            {
                "name": "Rockaway Point",
                "countryCode": "US"
            },
            {
                "name": "Rockland County",
                "countryCode": "US"
            },
            {
                "name": "Rockville Centre",
                "countryCode": "US"
            },
            {
                "name": "Rocky Point",
                "countryCode": "US"
            },
            {
                "name": "Roessleville",
                "countryCode": "US"
            },
            {
                "name": "Rome",
                "countryCode": "US"
            },
            {
                "name": "Ronkonkoma",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt",
                "countryCode": "US"
            },
            {
                "name": "Rosebank",
                "countryCode": "US"
            },
            {
                "name": "Rosedale",
                "countryCode": "US"
            },
            {
                "name": "Rosendale Village",
                "countryCode": "US"
            },
            {
                "name": "Roslyn",
                "countryCode": "US"
            },
            {
                "name": "Roslyn Estates",
                "countryCode": "US"
            },
            {
                "name": "Roslyn Harbor",
                "countryCode": "US"
            },
            {
                "name": "Roslyn Heights",
                "countryCode": "US"
            },
            {
                "name": "Rossville",
                "countryCode": "US"
            },
            {
                "name": "Rotterdam",
                "countryCode": "US"
            },
            {
                "name": "Rouses Point",
                "countryCode": "US"
            },
            {
                "name": "Rye",
                "countryCode": "US"
            },
            {
                "name": "Rye Brook",
                "countryCode": "US"
            },
            {
                "name": "Sackets Harbor",
                "countryCode": "US"
            },
            {
                "name": "Sag Harbor",
                "countryCode": "US"
            },
            {
                "name": "Saint Bonaventure",
                "countryCode": "US"
            },
            {
                "name": "Saint James",
                "countryCode": "US"
            },
            {
                "name": "Saint Johnsville",
                "countryCode": "US"
            },
            {
                "name": "Salamanca",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "Sanborn",
                "countryCode": "US"
            },
            {
                "name": "Sands Point",
                "countryCode": "US"
            },
            {
                "name": "Saranac Lake",
                "countryCode": "US"
            },
            {
                "name": "Saratoga County",
                "countryCode": "US"
            },
            {
                "name": "Saratoga Springs",
                "countryCode": "US"
            },
            {
                "name": "Saugerties",
                "countryCode": "US"
            },
            {
                "name": "Saugerties South",
                "countryCode": "US"
            },
            {
                "name": "Sayville",
                "countryCode": "US"
            },
            {
                "name": "Scarsdale",
                "countryCode": "US"
            },
            {
                "name": "Schenectady",
                "countryCode": "US"
            },
            {
                "name": "Schenectady County",
                "countryCode": "US"
            },
            {
                "name": "Schoharie",
                "countryCode": "US"
            },
            {
                "name": "Schoharie County",
                "countryCode": "US"
            },
            {
                "name": "Schuyler County",
                "countryCode": "US"
            },
            {
                "name": "Schuylerville",
                "countryCode": "US"
            },
            {
                "name": "Scotchtown",
                "countryCode": "US"
            },
            {
                "name": "Scotia",
                "countryCode": "US"
            },
            {
                "name": "Scottsville",
                "countryCode": "US"
            },
            {
                "name": "Sea Cliff",
                "countryCode": "US"
            },
            {
                "name": "Seaford",
                "countryCode": "US"
            },
            {
                "name": "Searingtown",
                "countryCode": "US"
            },
            {
                "name": "Seaside",
                "countryCode": "US"
            },
            {
                "name": "Selden",
                "countryCode": "US"
            },
            {
                "name": "Seneca County",
                "countryCode": "US"
            },
            {
                "name": "Seneca Falls",
                "countryCode": "US"
            },
            {
                "name": "Seneca Knolls",
                "countryCode": "US"
            },
            {
                "name": "Setauket-East Setauket",
                "countryCode": "US"
            },
            {
                "name": "Sheepshead Bay",
                "countryCode": "US"
            },
            {
                "name": "Shelter Island",
                "countryCode": "US"
            },
            {
                "name": "Shelter Island Heights",
                "countryCode": "US"
            },
            {
                "name": "Shenorock",
                "countryCode": "US"
            },
            {
                "name": "Sherburne",
                "countryCode": "US"
            },
            {
                "name": "Sherrill",
                "countryCode": "US"
            },
            {
                "name": "Shinnecock Hills",
                "countryCode": "US"
            },
            {
                "name": "Shirley",
                "countryCode": "US"
            },
            {
                "name": "Shokan",
                "countryCode": "US"
            },
            {
                "name": "Shortsville",
                "countryCode": "US"
            },
            {
                "name": "Shrub Oak",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Silver Creek",
                "countryCode": "US"
            },
            {
                "name": "Skaneateles",
                "countryCode": "US"
            },
            {
                "name": "Sleepy Hollow",
                "countryCode": "US"
            },
            {
                "name": "Sloan",
                "countryCode": "US"
            },
            {
                "name": "Sloatsburg",
                "countryCode": "US"
            },
            {
                "name": "Smithtown",
                "countryCode": "US"
            },
            {
                "name": "Sodus",
                "countryCode": "US"
            },
            {
                "name": "Solvay",
                "countryCode": "US"
            },
            {
                "name": "Sound Beach",
                "countryCode": "US"
            },
            {
                "name": "South Beach",
                "countryCode": "US"
            },
            {
                "name": "South Blooming Grove",
                "countryCode": "US"
            },
            {
                "name": "South Corning",
                "countryCode": "US"
            },
            {
                "name": "South Fallsburg",
                "countryCode": "US"
            },
            {
                "name": "South Farmingdale",
                "countryCode": "US"
            },
            {
                "name": "South Floral Park",
                "countryCode": "US"
            },
            {
                "name": "South Glens Falls",
                "countryCode": "US"
            },
            {
                "name": "South Hempstead",
                "countryCode": "US"
            },
            {
                "name": "South Hill",
                "countryCode": "US"
            },
            {
                "name": "South Huntington",
                "countryCode": "US"
            },
            {
                "name": "South Lockport",
                "countryCode": "US"
            },
            {
                "name": "South Nyack",
                "countryCode": "US"
            },
            {
                "name": "South Valley Stream",
                "countryCode": "US"
            },
            {
                "name": "Southampton",
                "countryCode": "US"
            },
            {
                "name": "Southold",
                "countryCode": "US"
            },
            {
                "name": "Southport",
                "countryCode": "US"
            },
            {
                "name": "Spackenkill",
                "countryCode": "US"
            },
            {
                "name": "Sparkill",
                "countryCode": "US"
            },
            {
                "name": "Spencerport",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Springfield Gardens",
                "countryCode": "US"
            },
            {
                "name": "Springs",
                "countryCode": "US"
            },
            {
                "name": "Springville",
                "countryCode": "US"
            },
            {
                "name": "Spuyten Duyvil",
                "countryCode": "US"
            },
            {
                "name": "St. Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Stamford",
                "countryCode": "US"
            },
            {
                "name": "Stapleton",
                "countryCode": "US"
            },
            {
                "name": "Staten Island",
                "countryCode": "US"
            },
            {
                "name": "Steinway",
                "countryCode": "US"
            },
            {
                "name": "Steuben County",
                "countryCode": "US"
            },
            {
                "name": "Stewart Manor",
                "countryCode": "US"
            },
            {
                "name": "Stillwater",
                "countryCode": "US"
            },
            {
                "name": "Stone Ridge",
                "countryCode": "US"
            },
            {
                "name": "Stony Brook",
                "countryCode": "US"
            },
            {
                "name": "Stony Point",
                "countryCode": "US"
            },
            {
                "name": "Stottville",
                "countryCode": "US"
            },
            {
                "name": "Suffern",
                "countryCode": "US"
            },
            {
                "name": "Suffolk County",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Sunnyside",
                "countryCode": "US"
            },
            {
                "name": "Sunset Park",
                "countryCode": "US"
            },
            {
                "name": "Sylvan Beach",
                "countryCode": "US"
            },
            {
                "name": "Syosset",
                "countryCode": "US"
            },
            {
                "name": "Syracuse",
                "countryCode": "US"
            },
            {
                "name": "Tappan",
                "countryCode": "US"
            },
            {
                "name": "Tarrytown",
                "countryCode": "US"
            },
            {
                "name": "Terrace Heights",
                "countryCode": "US"
            },
            {
                "name": "Terryville",
                "countryCode": "US"
            },
            {
                "name": "The Bronx",
                "countryCode": "US"
            },
            {
                "name": "Thiells",
                "countryCode": "US"
            },
            {
                "name": "Thomaston",
                "countryCode": "US"
            },
            {
                "name": "Thornwood",
                "countryCode": "US"
            },
            {
                "name": "Throgs Neck",
                "countryCode": "US"
            },
            {
                "name": "Ticonderoga",
                "countryCode": "US"
            },
            {
                "name": "Tillson",
                "countryCode": "US"
            },
            {
                "name": "Tioga County",
                "countryCode": "US"
            },
            {
                "name": "Tivoli",
                "countryCode": "US"
            },
            {
                "name": "Tompkins County",
                "countryCode": "US"
            },
            {
                "name": "Tompkinsville",
                "countryCode": "US"
            },
            {
                "name": "Tonawanda",
                "countryCode": "US"
            },
            {
                "name": "Town Line",
                "countryCode": "US"
            },
            {
                "name": "Tremont",
                "countryCode": "US"
            },
            {
                "name": "Tribes Hill",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Trumansburg",
                "countryCode": "US"
            },
            {
                "name": "Tuckahoe",
                "countryCode": "US"
            },
            {
                "name": "Tupper Lake",
                "countryCode": "US"
            },
            {
                "name": "Ulster County",
                "countryCode": "US"
            },
            {
                "name": "Unadilla",
                "countryCode": "US"
            },
            {
                "name": "Union Springs",
                "countryCode": "US"
            },
            {
                "name": "Uniondale",
                "countryCode": "US"
            },
            {
                "name": "Unionport",
                "countryCode": "US"
            },
            {
                "name": "University Gardens",
                "countryCode": "US"
            },
            {
                "name": "University Heights",
                "countryCode": "US"
            },
            {
                "name": "Upper Brookville",
                "countryCode": "US"
            },
            {
                "name": "Upper Nyack",
                "countryCode": "US"
            },
            {
                "name": "Utica",
                "countryCode": "US"
            },
            {
                "name": "Vails Gate",
                "countryCode": "US"
            },
            {
                "name": "Valatie",
                "countryCode": "US"
            },
            {
                "name": "Valhalla",
                "countryCode": "US"
            },
            {
                "name": "Valley Cottage",
                "countryCode": "US"
            },
            {
                "name": "Valley Stream",
                "countryCode": "US"
            },
            {
                "name": "Van Nest",
                "countryCode": "US"
            },
            {
                "name": "Vernon",
                "countryCode": "US"
            },
            {
                "name": "Verplanck",
                "countryCode": "US"
            },
            {
                "name": "Victor",
                "countryCode": "US"
            },
            {
                "name": "Village Green",
                "countryCode": "US"
            },
            {
                "name": "Village of the Branch",
                "countryCode": "US"
            },
            {
                "name": "Viola",
                "countryCode": "US"
            },
            {
                "name": "Volney",
                "countryCode": "US"
            },
            {
                "name": "Voorheesville",
                "countryCode": "US"
            },
            {
                "name": "Wading River",
                "countryCode": "US"
            },
            {
                "name": "Wakefield",
                "countryCode": "US"
            },
            {
                "name": "Walden",
                "countryCode": "US"
            },
            {
                "name": "Wallkill",
                "countryCode": "US"
            },
            {
                "name": "Walton",
                "countryCode": "US"
            },
            {
                "name": "Walton Park",
                "countryCode": "US"
            },
            {
                "name": "Wampsville",
                "countryCode": "US"
            },
            {
                "name": "Wanakah",
                "countryCode": "US"
            },
            {
                "name": "Wantagh",
                "countryCode": "US"
            },
            {
                "name": "Wappingers Falls",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrensburg",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Warwick",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washington Heights",
                "countryCode": "US"
            },
            {
                "name": "Washington Mills",
                "countryCode": "US"
            },
            {
                "name": "Washingtonville",
                "countryCode": "US"
            },
            {
                "name": "Watchtower",
                "countryCode": "US"
            },
            {
                "name": "Water Mill",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Waterville",
                "countryCode": "US"
            },
            {
                "name": "Watervliet",
                "countryCode": "US"
            },
            {
                "name": "Watkins Glen",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wawarsing",
                "countryCode": "US"
            },
            {
                "name": "Wayland",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webster",
                "countryCode": "US"
            },
            {
                "name": "Weedsport",
                "countryCode": "US"
            },
            {
                "name": "Wellsville",
                "countryCode": "US"
            },
            {
                "name": "Wesley Hills",
                "countryCode": "US"
            },
            {
                "name": "West Albany",
                "countryCode": "US"
            },
            {
                "name": "West Babylon",
                "countryCode": "US"
            },
            {
                "name": "West Bay Shore",
                "countryCode": "US"
            },
            {
                "name": "West Carthage",
                "countryCode": "US"
            },
            {
                "name": "West Elmira",
                "countryCode": "US"
            },
            {
                "name": "West End",
                "countryCode": "US"
            },
            {
                "name": "West Glens Falls",
                "countryCode": "US"
            },
            {
                "name": "West Haverstraw",
                "countryCode": "US"
            },
            {
                "name": "West Hempstead",
                "countryCode": "US"
            },
            {
                "name": "West Hills",
                "countryCode": "US"
            },
            {
                "name": "West Hurley",
                "countryCode": "US"
            },
            {
                "name": "West Islip",
                "countryCode": "US"
            },
            {
                "name": "West Nyack",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "West Sand Lake",
                "countryCode": "US"
            },
            {
                "name": "West Sayville",
                "countryCode": "US"
            },
            {
                "name": "West Seneca",
                "countryCode": "US"
            },
            {
                "name": "Westbury",
                "countryCode": "US"
            },
            {
                "name": "Westchester County",
                "countryCode": "US"
            },
            {
                "name": "Westerleigh",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Westhampton",
                "countryCode": "US"
            },
            {
                "name": "Westhampton Beach",
                "countryCode": "US"
            },
            {
                "name": "Westmere",
                "countryCode": "US"
            },
            {
                "name": "Weston Mills",
                "countryCode": "US"
            },
            {
                "name": "Westvale",
                "countryCode": "US"
            },
            {
                "name": "Wheatley Heights",
                "countryCode": "US"
            },
            {
                "name": "White Plains",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Whitesboro",
                "countryCode": "US"
            },
            {
                "name": "Whitestone",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamson",
                "countryCode": "US"
            },
            {
                "name": "Williamsville",
                "countryCode": "US"
            },
            {
                "name": "Williston Park",
                "countryCode": "US"
            },
            {
                "name": "Willowbrook",
                "countryCode": "US"
            },
            {
                "name": "Wilson",
                "countryCode": "US"
            },
            {
                "name": "Wolcott",
                "countryCode": "US"
            },
            {
                "name": "Woodbury",
                "countryCode": "US"
            },
            {
                "name": "Woodhaven",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn",
                "countryCode": "US"
            },
            {
                "name": "Woodmere",
                "countryCode": "US"
            },
            {
                "name": "Woodrow",
                "countryCode": "US"
            },
            {
                "name": "Woodside",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Worcester",
                "countryCode": "US"
            },
            {
                "name": "Wurtsboro",
                "countryCode": "US"
            },
            {
                "name": "Wyandanch",
                "countryCode": "US"
            },
            {
                "name": "Wykagyl",
                "countryCode": "US"
            },
            {
                "name": "Wynantskill",
                "countryCode": "US"
            },
            {
                "name": "Wyoming County",
                "countryCode": "US"
            },
            {
                "name": "Yaphank",
                "countryCode": "US"
            },
            {
                "name": "Yates County",
                "countryCode": "US"
            },
            {
                "name": "Yonkers",
                "countryCode": "US"
            },
            {
                "name": "Yorkshire",
                "countryCode": "US"
            },
            {
                "name": "Yorktown Heights",
                "countryCode": "US"
            },
            {
                "name": "Yorkville",
                "countryCode": "US"
            },
            {
                "name": "Youngstown",
                "countryCode": "US"
            },
            {
                "name": "Zena",
                "countryCode": "US"
            },
            {
                "name": "Ada",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Allen County",
                "countryCode": "US"
            },
            {
                "name": "Alliance",
                "countryCode": "US"
            },
            {
                "name": "Amberley",
                "countryCode": "US"
            },
            {
                "name": "Amelia",
                "countryCode": "US"
            },
            {
                "name": "Amherst",
                "countryCode": "US"
            },
            {
                "name": "Andover",
                "countryCode": "US"
            },
            {
                "name": "Anna",
                "countryCode": "US"
            },
            {
                "name": "Ansonia",
                "countryCode": "US"
            },
            {
                "name": "Antwerp",
                "countryCode": "US"
            },
            {
                "name": "Apple Creek",
                "countryCode": "US"
            },
            {
                "name": "Apple Valley",
                "countryCode": "US"
            },
            {
                "name": "Arcanum",
                "countryCode": "US"
            },
            {
                "name": "Archbold",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Ashland County",
                "countryCode": "US"
            },
            {
                "name": "Ashley",
                "countryCode": "US"
            },
            {
                "name": "Ashtabula",
                "countryCode": "US"
            },
            {
                "name": "Ashtabula County",
                "countryCode": "US"
            },
            {
                "name": "Ashville",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Athens County",
                "countryCode": "US"
            },
            {
                "name": "Auglaize County",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Austintown",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Avon Center",
                "countryCode": "US"
            },
            {
                "name": "Avon Lake",
                "countryCode": "US"
            },
            {
                "name": "Bainbridge",
                "countryCode": "US"
            },
            {
                "name": "Ballville",
                "countryCode": "US"
            },
            {
                "name": "Baltimore",
                "countryCode": "US"
            },
            {
                "name": "Barberton",
                "countryCode": "US"
            },
            {
                "name": "Barnesville",
                "countryCode": "US"
            },
            {
                "name": "Batavia",
                "countryCode": "US"
            },
            {
                "name": "Bay Village",
                "countryCode": "US"
            },
            {
                "name": "Beach City",
                "countryCode": "US"
            },
            {
                "name": "Beachwood",
                "countryCode": "US"
            },
            {
                "name": "Beavercreek",
                "countryCode": "US"
            },
            {
                "name": "Beckett Ridge",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Bedford Heights",
                "countryCode": "US"
            },
            {
                "name": "Beechwood Trails",
                "countryCode": "US"
            },
            {
                "name": "Bellaire",
                "countryCode": "US"
            },
            {
                "name": "Bellbrook",
                "countryCode": "US"
            },
            {
                "name": "Bellefontaine",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Bellville",
                "countryCode": "US"
            },
            {
                "name": "Belmont County",
                "countryCode": "US"
            },
            {
                "name": "Belpre",
                "countryCode": "US"
            },
            {
                "name": "Berea",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Bethesda",
                "countryCode": "US"
            },
            {
                "name": "Beverly",
                "countryCode": "US"
            },
            {
                "name": "Bexley",
                "countryCode": "US"
            },
            {
                "name": "Blacklick Estates",
                "countryCode": "US"
            },
            {
                "name": "Blanchester",
                "countryCode": "US"
            },
            {
                "name": "Blue Ash",
                "countryCode": "US"
            },
            {
                "name": "Bluffton",
                "countryCode": "US"
            },
            {
                "name": "Boardman",
                "countryCode": "US"
            },
            {
                "name": "Bolindale",
                "countryCode": "US"
            },
            {
                "name": "Boston Heights",
                "countryCode": "US"
            },
            {
                "name": "Botkins",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Bradford",
                "countryCode": "US"
            },
            {
                "name": "Bradner",
                "countryCode": "US"
            },
            {
                "name": "Bratenahl",
                "countryCode": "US"
            },
            {
                "name": "Brecksville",
                "countryCode": "US"
            },
            {
                "name": "Bremen",
                "countryCode": "US"
            },
            {
                "name": "Brewster",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Bridgetown",
                "countryCode": "US"
            },
            {
                "name": "Brilliant",
                "countryCode": "US"
            },
            {
                "name": "Brimfield",
                "countryCode": "US"
            },
            {
                "name": "Broadview Heights",
                "countryCode": "US"
            },
            {
                "name": "Brook Park",
                "countryCode": "US"
            },
            {
                "name": "Brookfield Center",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn Heights",
                "countryCode": "US"
            },
            {
                "name": "Brookville",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Brunswick",
                "countryCode": "US"
            },
            {
                "name": "Bryan",
                "countryCode": "US"
            },
            {
                "name": "Buckeye Lake",
                "countryCode": "US"
            },
            {
                "name": "Bucyrus",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Burton",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "Byesville",
                "countryCode": "US"
            },
            {
                "name": "Cadiz",
                "countryCode": "US"
            },
            {
                "name": "Calcutta",
                "countryCode": "US"
            },
            {
                "name": "Caldwell",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Campbell",
                "countryCode": "US"
            },
            {
                "name": "Canal Fulton",
                "countryCode": "US"
            },
            {
                "name": "Canal Winchester",
                "countryCode": "US"
            },
            {
                "name": "Canfield",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Cardington",
                "countryCode": "US"
            },
            {
                "name": "Carey",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Cedarville",
                "countryCode": "US"
            },
            {
                "name": "Celina",
                "countryCode": "US"
            },
            {
                "name": "Centerburg",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Chagrin Falls",
                "countryCode": "US"
            },
            {
                "name": "Champaign County",
                "countryCode": "US"
            },
            {
                "name": "Champion Heights",
                "countryCode": "US"
            },
            {
                "name": "Chardon",
                "countryCode": "US"
            },
            {
                "name": "Chauncey",
                "countryCode": "US"
            },
            {
                "name": "Cherry Grove",
                "countryCode": "US"
            },
            {
                "name": "Chesterland",
                "countryCode": "US"
            },
            {
                "name": "Cheviot",
                "countryCode": "US"
            },
            {
                "name": "Chillicothe",
                "countryCode": "US"
            },
            {
                "name": "Choctaw Lake",
                "countryCode": "US"
            },
            {
                "name": "Churchill",
                "countryCode": "US"
            },
            {
                "name": "Cincinnati",
                "countryCode": "US"
            },
            {
                "name": "Circleville",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clark-Fulton",
                "countryCode": "US"
            },
            {
                "name": "Clayton",
                "countryCode": "US"
            },
            {
                "name": "Clermont County",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Cleveland Heights",
                "countryCode": "US"
            },
            {
                "name": "Cleves",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Clyde",
                "countryCode": "US"
            },
            {
                "name": "Coal Grove",
                "countryCode": "US"
            },
            {
                "name": "Coldwater",
                "countryCode": "US"
            },
            {
                "name": "Collinwood",
                "countryCode": "US"
            },
            {
                "name": "Columbiana",
                "countryCode": "US"
            },
            {
                "name": "Columbiana County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Columbus Grove",
                "countryCode": "US"
            },
            {
                "name": "Commercial Point",
                "countryCode": "US"
            },
            {
                "name": "Conneaut",
                "countryCode": "US"
            },
            {
                "name": "Continental",
                "countryCode": "US"
            },
            {
                "name": "Convoy",
                "countryCode": "US"
            },
            {
                "name": "Copley",
                "countryCode": "US"
            },
            {
                "name": "Cortland",
                "countryCode": "US"
            },
            {
                "name": "Coshocton",
                "countryCode": "US"
            },
            {
                "name": "Coshocton County",
                "countryCode": "US"
            },
            {
                "name": "Covedale",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Craig Beach",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Crestline",
                "countryCode": "US"
            },
            {
                "name": "Creston",
                "countryCode": "US"
            },
            {
                "name": "Cridersville",
                "countryCode": "US"
            },
            {
                "name": "Crooksville",
                "countryCode": "US"
            },
            {
                "name": "Crystal Lakes",
                "countryCode": "US"
            },
            {
                "name": "Curtice",
                "countryCode": "US"
            },
            {
                "name": "Cuyahoga County",
                "countryCode": "US"
            },
            {
                "name": "Cuyahoga Falls",
                "countryCode": "US"
            },
            {
                "name": "Dalton",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Darke County",
                "countryCode": "US"
            },
            {
                "name": "Day Heights",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "De Graff",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Defiance",
                "countryCode": "US"
            },
            {
                "name": "Defiance County",
                "countryCode": "US"
            },
            {
                "name": "Delaware",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Delhi Hills",
                "countryCode": "US"
            },
            {
                "name": "Delphos",
                "countryCode": "US"
            },
            {
                "name": "Delta",
                "countryCode": "US"
            },
            {
                "name": "Dennison",
                "countryCode": "US"
            },
            {
                "name": "Dent",
                "countryCode": "US"
            },
            {
                "name": "Deshler",
                "countryCode": "US"
            },
            {
                "name": "Detroit-Shoreway",
                "countryCode": "US"
            },
            {
                "name": "Devola",
                "countryCode": "US"
            },
            {
                "name": "Dillonvale",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Doylestown",
                "countryCode": "US"
            },
            {
                "name": "Dresden",
                "countryCode": "US"
            },
            {
                "name": "Drexel",
                "countryCode": "US"
            },
            {
                "name": "Dry Ridge",
                "countryCode": "US"
            },
            {
                "name": "Dry Run",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Dunlap",
                "countryCode": "US"
            },
            {
                "name": "East Canton",
                "countryCode": "US"
            },
            {
                "name": "East Cleveland",
                "countryCode": "US"
            },
            {
                "name": "East Liverpool",
                "countryCode": "US"
            },
            {
                "name": "East Palestine",
                "countryCode": "US"
            },
            {
                "name": "Eastlake",
                "countryCode": "US"
            },
            {
                "name": "Eaton",
                "countryCode": "US"
            },
            {
                "name": "Eaton Estates",
                "countryCode": "US"
            },
            {
                "name": "Edgerton",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Elida",
                "countryCode": "US"
            },
            {
                "name": "Elmore",
                "countryCode": "US"
            },
            {
                "name": "Elmwood Place",
                "countryCode": "US"
            },
            {
                "name": "Elyria",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Enon",
                "countryCode": "US"
            },
            {
                "name": "Erie County",
                "countryCode": "US"
            },
            {
                "name": "Etna",
                "countryCode": "US"
            },
            {
                "name": "Euclid",
                "countryCode": "US"
            },
            {
                "name": "Evendale",
                "countryCode": "US"
            },
            {
                "name": "Fairborn",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairfield Beach",
                "countryCode": "US"
            },
            {
                "name": "Fairfield County",
                "countryCode": "US"
            },
            {
                "name": "Fairlawn",
                "countryCode": "US"
            },
            {
                "name": "Fairport Harbor",
                "countryCode": "US"
            },
            {
                "name": "Fairview Park",
                "countryCode": "US"
            },
            {
                "name": "Farmersville",
                "countryCode": "US"
            },
            {
                "name": "Fayette",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Findlay",
                "countryCode": "US"
            },
            {
                "name": "Finneytown",
                "countryCode": "US"
            },
            {
                "name": "Five Points",
                "countryCode": "US"
            },
            {
                "name": "Forest",
                "countryCode": "US"
            },
            {
                "name": "Forest Park",
                "countryCode": "US"
            },
            {
                "name": "Forestville",
                "countryCode": "US"
            },
            {
                "name": "Fort Loramie",
                "countryCode": "US"
            },
            {
                "name": "Fort McKinley",
                "countryCode": "US"
            },
            {
                "name": "Fort Recovery",
                "countryCode": "US"
            },
            {
                "name": "Fort Shawnee",
                "countryCode": "US"
            },
            {
                "name": "Fostoria",
                "countryCode": "US"
            },
            {
                "name": "Frankfort",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklin Furnace",
                "countryCode": "US"
            },
            {
                "name": "Frazeysburg",
                "countryCode": "US"
            },
            {
                "name": "Fredericktown",
                "countryCode": "US"
            },
            {
                "name": "Fremont",
                "countryCode": "US"
            },
            {
                "name": "Fruit Hill",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Gahanna",
                "countryCode": "US"
            },
            {
                "name": "Galion",
                "countryCode": "US"
            },
            {
                "name": "Gallia County",
                "countryCode": "US"
            },
            {
                "name": "Gallipolis",
                "countryCode": "US"
            },
            {
                "name": "Gambier",
                "countryCode": "US"
            },
            {
                "name": "Garfield Heights",
                "countryCode": "US"
            },
            {
                "name": "Garrettsville",
                "countryCode": "US"
            },
            {
                "name": "Gates Mills",
                "countryCode": "US"
            },
            {
                "name": "Geauga County",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Geneva-on-the-Lake",
                "countryCode": "US"
            },
            {
                "name": "Genoa",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Germantown",
                "countryCode": "US"
            },
            {
                "name": "Gibsonburg",
                "countryCode": "US"
            },
            {
                "name": "Girard",
                "countryCode": "US"
            },
            {
                "name": "Glandorf",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Glenmoor",
                "countryCode": "US"
            },
            {
                "name": "Glenville",
                "countryCode": "US"
            },
            {
                "name": "Glouster",
                "countryCode": "US"
            },
            {
                "name": "Gnadenhutten",
                "countryCode": "US"
            },
            {
                "name": "Golf Manor",
                "countryCode": "US"
            },
            {
                "name": "Goshen",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Grandview",
                "countryCode": "US"
            },
            {
                "name": "Grandview Heights",
                "countryCode": "US"
            },
            {
                "name": "Granville",
                "countryCode": "US"
            },
            {
                "name": "Granville South",
                "countryCode": "US"
            },
            {
                "name": "Green",
                "countryCode": "US"
            },
            {
                "name": "Green Meadows",
                "countryCode": "US"
            },
            {
                "name": "Green Springs",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenhills",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greentown",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwich",
                "countryCode": "US"
            },
            {
                "name": "Groesbeck",
                "countryCode": "US"
            },
            {
                "name": "Grove City",
                "countryCode": "US"
            },
            {
                "name": "Groveport",
                "countryCode": "US"
            },
            {
                "name": "Guernsey County",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harbor Hills",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Hartville",
                "countryCode": "US"
            },
            {
                "name": "Haskins",
                "countryCode": "US"
            },
            {
                "name": "Heath",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Hicksville",
                "countryCode": "US"
            },
            {
                "name": "Highland County",
                "countryCode": "US"
            },
            {
                "name": "Highland Heights",
                "countryCode": "US"
            },
            {
                "name": "Highpoint",
                "countryCode": "US"
            },
            {
                "name": "Hilliard",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hiram",
                "countryCode": "US"
            },
            {
                "name": "Hocking County",
                "countryCode": "US"
            },
            {
                "name": "Holgate",
                "countryCode": "US"
            },
            {
                "name": "Holiday Valley",
                "countryCode": "US"
            },
            {
                "name": "Holland",
                "countryCode": "US"
            },
            {
                "name": "Holmes County",
                "countryCode": "US"
            },
            {
                "name": "Hough",
                "countryCode": "US"
            },
            {
                "name": "Howland Center",
                "countryCode": "US"
            },
            {
                "name": "Hubbard",
                "countryCode": "US"
            },
            {
                "name": "Huber Heights",
                "countryCode": "US"
            },
            {
                "name": "Huber Ridge",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hunter",
                "countryCode": "US"
            },
            {
                "name": "Huron",
                "countryCode": "US"
            },
            {
                "name": "Huron County",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Ironton",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson Center",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jeffersonville",
                "countryCode": "US"
            },
            {
                "name": "Johnstown",
                "countryCode": "US"
            },
            {
                "name": "Kalida",
                "countryCode": "US"
            },
            {
                "name": "Kent",
                "countryCode": "US"
            },
            {
                "name": "Kenton",
                "countryCode": "US"
            },
            {
                "name": "Kenwood",
                "countryCode": "US"
            },
            {
                "name": "Kettering",
                "countryCode": "US"
            },
            {
                "name": "Kings Mills",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kirtland",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "La Croft",
                "countryCode": "US"
            },
            {
                "name": "Lagrange",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Darby",
                "countryCode": "US"
            },
            {
                "name": "Lake Lakengren",
                "countryCode": "US"
            },
            {
                "name": "Lake Mohawk",
                "countryCode": "US"
            },
            {
                "name": "Lakemore",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Landen",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Leavittsburg",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Leesburg",
                "countryCode": "US"
            },
            {
                "name": "Leetonia",
                "countryCode": "US"
            },
            {
                "name": "Leipsic",
                "countryCode": "US"
            },
            {
                "name": "Lewis Center",
                "countryCode": "US"
            },
            {
                "name": "Lewisburg",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty Center",
                "countryCode": "US"
            },
            {
                "name": "Licking County",
                "countryCode": "US"
            },
            {
                "name": "Lima",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Heights",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Village",
                "countryCode": "US"
            },
            {
                "name": "Lisbon",
                "countryCode": "US"
            },
            {
                "name": "Lithopolis",
                "countryCode": "US"
            },
            {
                "name": "Lockland",
                "countryCode": "US"
            },
            {
                "name": "Lodi",
                "countryCode": "US"
            },
            {
                "name": "Logan",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Logan Elm Village",
                "countryCode": "US"
            },
            {
                "name": "London",
                "countryCode": "US"
            },
            {
                "name": "Lorain",
                "countryCode": "US"
            },
            {
                "name": "Lorain County",
                "countryCode": "US"
            },
            {
                "name": "Lordstown",
                "countryCode": "US"
            },
            {
                "name": "Loudonville",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Loveland",
                "countryCode": "US"
            },
            {
                "name": "Loveland Park",
                "countryCode": "US"
            },
            {
                "name": "Lowellville",
                "countryCode": "US"
            },
            {
                "name": "Lucas County",
                "countryCode": "US"
            },
            {
                "name": "Lucasville",
                "countryCode": "US"
            },
            {
                "name": "Luckey",
                "countryCode": "US"
            },
            {
                "name": "Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "Lyndhurst",
                "countryCode": "US"
            },
            {
                "name": "Macedonia",
                "countryCode": "US"
            },
            {
                "name": "Mack",
                "countryCode": "US"
            },
            {
                "name": "Madeira",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Mahoning County",
                "countryCode": "US"
            },
            {
                "name": "Malvern",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Mantua",
                "countryCode": "US"
            },
            {
                "name": "Maple Heights",
                "countryCode": "US"
            },
            {
                "name": "Mariemont",
                "countryCode": "US"
            },
            {
                "name": "Marietta",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Martins Ferry",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "Mason",
                "countryCode": "US"
            },
            {
                "name": "Massillon",
                "countryCode": "US"
            },
            {
                "name": "Masury",
                "countryCode": "US"
            },
            {
                "name": "Maumee",
                "countryCode": "US"
            },
            {
                "name": "Mayfield",
                "countryCode": "US"
            },
            {
                "name": "Mayfield Heights",
                "countryCode": "US"
            },
            {
                "name": "McArthur",
                "countryCode": "US"
            },
            {
                "name": "McComb",
                "countryCode": "US"
            },
            {
                "name": "McConnelsville",
                "countryCode": "US"
            },
            {
                "name": "McDonald",
                "countryCode": "US"
            },
            {
                "name": "McKinley Heights",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsburg",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Medina County",
                "countryCode": "US"
            },
            {
                "name": "Meigs County",
                "countryCode": "US"
            },
            {
                "name": "Mentor",
                "countryCode": "US"
            },
            {
                "name": "Mentor-on-the-Lake",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Miami County",
                "countryCode": "US"
            },
            {
                "name": "Miami Heights",
                "countryCode": "US"
            },
            {
                "name": "Miamisburg",
                "countryCode": "US"
            },
            {
                "name": "Miamitown",
                "countryCode": "US"
            },
            {
                "name": "Middleburg Heights",
                "countryCode": "US"
            },
            {
                "name": "Middlefield",
                "countryCode": "US"
            },
            {
                "name": "Middleport",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millbury",
                "countryCode": "US"
            },
            {
                "name": "Millersburg",
                "countryCode": "US"
            },
            {
                "name": "Millersport",
                "countryCode": "US"
            },
            {
                "name": "Mineral Ridge",
                "countryCode": "US"
            },
            {
                "name": "Minerva",
                "countryCode": "US"
            },
            {
                "name": "Minerva Park",
                "countryCode": "US"
            },
            {
                "name": "Mingo Junction",
                "countryCode": "US"
            },
            {
                "name": "Minster",
                "countryCode": "US"
            },
            {
                "name": "Mogadore",
                "countryCode": "US"
            },
            {
                "name": "Monfort Heights",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monroeville",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Montpelier",
                "countryCode": "US"
            },
            {
                "name": "Montrose-Ghent",
                "countryCode": "US"
            },
            {
                "name": "Moraine",
                "countryCode": "US"
            },
            {
                "name": "Moreland Hills",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morgandale",
                "countryCode": "US"
            },
            {
                "name": "Morrow",
                "countryCode": "US"
            },
            {
                "name": "Morrow County",
                "countryCode": "US"
            },
            {
                "name": "Mount Carmel",
                "countryCode": "US"
            },
            {
                "name": "Mount Gilead",
                "countryCode": "US"
            },
            {
                "name": "Mount Healthy",
                "countryCode": "US"
            },
            {
                "name": "Mount Healthy Heights",
                "countryCode": "US"
            },
            {
                "name": "Mount Orab",
                "countryCode": "US"
            },
            {
                "name": "Mount Repose",
                "countryCode": "US"
            },
            {
                "name": "Mount Sterling",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mulberry",
                "countryCode": "US"
            },
            {
                "name": "Munroe Falls",
                "countryCode": "US"
            },
            {
                "name": "Muskingum County",
                "countryCode": "US"
            },
            {
                "name": "Napoleon",
                "countryCode": "US"
            },
            {
                "name": "Navarre",
                "countryCode": "US"
            },
            {
                "name": "Nelsonville",
                "countryCode": "US"
            },
            {
                "name": "New Albany",
                "countryCode": "US"
            },
            {
                "name": "New Boston",
                "countryCode": "US"
            },
            {
                "name": "New Bremen",
                "countryCode": "US"
            },
            {
                "name": "New Burlington",
                "countryCode": "US"
            },
            {
                "name": "New California",
                "countryCode": "US"
            },
            {
                "name": "New Carlisle",
                "countryCode": "US"
            },
            {
                "name": "New Concord",
                "countryCode": "US"
            },
            {
                "name": "New Franklin",
                "countryCode": "US"
            },
            {
                "name": "New Lebanon",
                "countryCode": "US"
            },
            {
                "name": "New Lexington",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New Matamoras",
                "countryCode": "US"
            },
            {
                "name": "New Miami",
                "countryCode": "US"
            },
            {
                "name": "New Middletown",
                "countryCode": "US"
            },
            {
                "name": "New Paris",
                "countryCode": "US"
            },
            {
                "name": "New Philadelphia",
                "countryCode": "US"
            },
            {
                "name": "New Richmond",
                "countryCode": "US"
            },
            {
                "name": "New Vienna",
                "countryCode": "US"
            },
            {
                "name": "New Waterford",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newburgh Heights",
                "countryCode": "US"
            },
            {
                "name": "Newcomerstown",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newton Falls",
                "countryCode": "US"
            },
            {
                "name": "Newtown",
                "countryCode": "US"
            },
            {
                "name": "Niles",
                "countryCode": "US"
            },
            {
                "name": "Noble County",
                "countryCode": "US"
            },
            {
                "name": "North Baltimore",
                "countryCode": "US"
            },
            {
                "name": "North Canton",
                "countryCode": "US"
            },
            {
                "name": "North College Hill",
                "countryCode": "US"
            },
            {
                "name": "North Fork Village",
                "countryCode": "US"
            },
            {
                "name": "North Kingsville",
                "countryCode": "US"
            },
            {
                "name": "North Lewisburg",
                "countryCode": "US"
            },
            {
                "name": "North Madison",
                "countryCode": "US"
            },
            {
                "name": "North Olmsted",
                "countryCode": "US"
            },
            {
                "name": "North Randall",
                "countryCode": "US"
            },
            {
                "name": "North Ridgeville",
                "countryCode": "US"
            },
            {
                "name": "North Royalton",
                "countryCode": "US"
            },
            {
                "name": "North Zanesville",
                "countryCode": "US"
            },
            {
                "name": "Northbrook",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Northgate",
                "countryCode": "US"
            },
            {
                "name": "Northridge",
                "countryCode": "US"
            },
            {
                "name": "Northwood",
                "countryCode": "US"
            },
            {
                "name": "Norton",
                "countryCode": "US"
            },
            {
                "name": "Norwalk",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Oak Harbor",
                "countryCode": "US"
            },
            {
                "name": "Oak Hill",
                "countryCode": "US"
            },
            {
                "name": "Oakwood",
                "countryCode": "US"
            },
            {
                "name": "Oberlin",
                "countryCode": "US"
            },
            {
                "name": "Obetz",
                "countryCode": "US"
            },
            {
                "name": "Olmsted Falls",
                "countryCode": "US"
            },
            {
                "name": "Ontario",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Oregon",
                "countryCode": "US"
            },
            {
                "name": "Orrville",
                "countryCode": "US"
            },
            {
                "name": "Orwell",
                "countryCode": "US"
            },
            {
                "name": "Ottawa",
                "countryCode": "US"
            },
            {
                "name": "Ottawa County",
                "countryCode": "US"
            },
            {
                "name": "Ottawa Hills",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Painesville",
                "countryCode": "US"
            },
            {
                "name": "Pandora",
                "countryCode": "US"
            },
            {
                "name": "Park Layne",
                "countryCode": "US"
            },
            {
                "name": "Parma",
                "countryCode": "US"
            },
            {
                "name": "Parma Heights",
                "countryCode": "US"
            },
            {
                "name": "Pataskala",
                "countryCode": "US"
            },
            {
                "name": "Paulding",
                "countryCode": "US"
            },
            {
                "name": "Paulding County",
                "countryCode": "US"
            },
            {
                "name": "Payne",
                "countryCode": "US"
            },
            {
                "name": "Peebles",
                "countryCode": "US"
            },
            {
                "name": "Pemberville",
                "countryCode": "US"
            },
            {
                "name": "Pepper Pike",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Perry Heights",
                "countryCode": "US"
            },
            {
                "name": "Perrysburg",
                "countryCode": "US"
            },
            {
                "name": "Pickaway County",
                "countryCode": "US"
            },
            {
                "name": "Pickerington",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Piketon",
                "countryCode": "US"
            },
            {
                "name": "Pioneer",
                "countryCode": "US"
            },
            {
                "name": "Piqua",
                "countryCode": "US"
            },
            {
                "name": "Plain City",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Grove",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Run",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Run Farm",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Poland",
                "countryCode": "US"
            },
            {
                "name": "Pomeroy",
                "countryCode": "US"
            },
            {
                "name": "Port Clinton",
                "countryCode": "US"
            },
            {
                "name": "Portage County",
                "countryCode": "US"
            },
            {
                "name": "Portage Lakes",
                "countryCode": "US"
            },
            {
                "name": "Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "Powell",
                "countryCode": "US"
            },
            {
                "name": "Powhatan Point",
                "countryCode": "US"
            },
            {
                "name": "Preble County",
                "countryCode": "US"
            },
            {
                "name": "Prospect",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Ravenna",
                "countryCode": "US"
            },
            {
                "name": "Reading",
                "countryCode": "US"
            },
            {
                "name": "Reminderville",
                "countryCode": "US"
            },
            {
                "name": "Reno",
                "countryCode": "US"
            },
            {
                "name": "Reynoldsburg",
                "countryCode": "US"
            },
            {
                "name": "Richfield",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Richmond Heights",
                "countryCode": "US"
            },
            {
                "name": "Richville",
                "countryCode": "US"
            },
            {
                "name": "Richwood",
                "countryCode": "US"
            },
            {
                "name": "Ripley",
                "countryCode": "US"
            },
            {
                "name": "Rittman",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Roaming Shores",
                "countryCode": "US"
            },
            {
                "name": "Rockford",
                "countryCode": "US"
            },
            {
                "name": "Rocky River",
                "countryCode": "US"
            },
            {
                "name": "Rosemount",
                "countryCode": "US"
            },
            {
                "name": "Roseville",
                "countryCode": "US"
            },
            {
                "name": "Ross",
                "countryCode": "US"
            },
            {
                "name": "Ross County",
                "countryCode": "US"
            },
            {
                "name": "Rossford",
                "countryCode": "US"
            },
            {
                "name": "Rossmoyne",
                "countryCode": "US"
            },
            {
                "name": "Russells Point",
                "countryCode": "US"
            },
            {
                "name": "Sabina",
                "countryCode": "US"
            },
            {
                "name": "Saint Bernard",
                "countryCode": "US"
            },
            {
                "name": "Saint Clairsville",
                "countryCode": "US"
            },
            {
                "name": "Saint Henry",
                "countryCode": "US"
            },
            {
                "name": "Saint Marys",
                "countryCode": "US"
            },
            {
                "name": "Saint Paris",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salem Heights",
                "countryCode": "US"
            },
            {
                "name": "Salineville",
                "countryCode": "US"
            },
            {
                "name": "Sandusky",
                "countryCode": "US"
            },
            {
                "name": "Sandusky County",
                "countryCode": "US"
            },
            {
                "name": "Sawyerwood",
                "countryCode": "US"
            },
            {
                "name": "Scioto County",
                "countryCode": "US"
            },
            {
                "name": "Sciotodale",
                "countryCode": "US"
            },
            {
                "name": "Sebring",
                "countryCode": "US"
            },
            {
                "name": "Seneca County",
                "countryCode": "US"
            },
            {
                "name": "Seven Hills",
                "countryCode": "US"
            },
            {
                "name": "Seville",
                "countryCode": "US"
            },
            {
                "name": "Shadyside",
                "countryCode": "US"
            },
            {
                "name": "Shaker Heights",
                "countryCode": "US"
            },
            {
                "name": "Sharonville",
                "countryCode": "US"
            },
            {
                "name": "Shawnee Hills",
                "countryCode": "US"
            },
            {
                "name": "Sheffield",
                "countryCode": "US"
            },
            {
                "name": "Sheffield Lake",
                "countryCode": "US"
            },
            {
                "name": "Shelby",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Sherwood",
                "countryCode": "US"
            },
            {
                "name": "Shiloh",
                "countryCode": "US"
            },
            {
                "name": "Shreve",
                "countryCode": "US"
            },
            {
                "name": "Sidney",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Silverton",
                "countryCode": "US"
            },
            {
                "name": "Sixteen Mile Stand",
                "countryCode": "US"
            },
            {
                "name": "Skyline Acres",
                "countryCode": "US"
            },
            {
                "name": "Smithville",
                "countryCode": "US"
            },
            {
                "name": "Solon",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "South Amherst",
                "countryCode": "US"
            },
            {
                "name": "South Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "South Canal",
                "countryCode": "US"
            },
            {
                "name": "South Charleston",
                "countryCode": "US"
            },
            {
                "name": "South Euclid",
                "countryCode": "US"
            },
            {
                "name": "South Lebanon",
                "countryCode": "US"
            },
            {
                "name": "South Point",
                "countryCode": "US"
            },
            {
                "name": "South Russell",
                "countryCode": "US"
            },
            {
                "name": "South Zanesville",
                "countryCode": "US"
            },
            {
                "name": "Spencerville",
                "countryCode": "US"
            },
            {
                "name": "Springboro",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stark County",
                "countryCode": "US"
            },
            {
                "name": "Steubenville",
                "countryCode": "US"
            },
            {
                "name": "Stony Prairie",
                "countryCode": "US"
            },
            {
                "name": "Stow",
                "countryCode": "US"
            },
            {
                "name": "Strasburg",
                "countryCode": "US"
            },
            {
                "name": "Streetsboro",
                "countryCode": "US"
            },
            {
                "name": "Strongsville",
                "countryCode": "US"
            },
            {
                "name": "Struthers",
                "countryCode": "US"
            },
            {
                "name": "Stryker",
                "countryCode": "US"
            },
            {
                "name": "Sugarcreek",
                "countryCode": "US"
            },
            {
                "name": "Sugarcreek Police Dept",
                "countryCode": "US"
            },
            {
                "name": "Summerside",
                "countryCode": "US"
            },
            {
                "name": "Summit County",
                "countryCode": "US"
            },
            {
                "name": "Sunbury",
                "countryCode": "US"
            },
            {
                "name": "Swanton",
                "countryCode": "US"
            },
            {
                "name": "Sylvania",
                "countryCode": "US"
            },
            {
                "name": "Tallmadge",
                "countryCode": "US"
            },
            {
                "name": "Terrace Park",
                "countryCode": "US"
            },
            {
                "name": "The Plains",
                "countryCode": "US"
            },
            {
                "name": "The Village of Indian Hill",
                "countryCode": "US"
            },
            {
                "name": "Thornport",
                "countryCode": "US"
            },
            {
                "name": "Tiffin",
                "countryCode": "US"
            },
            {
                "name": "Tiltonsville",
                "countryCode": "US"
            },
            {
                "name": "Tipp City",
                "countryCode": "US"
            },
            {
                "name": "Toledo",
                "countryCode": "US"
            },
            {
                "name": "Toronto",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Trotwood",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Trumbull County",
                "countryCode": "US"
            },
            {
                "name": "Turpin Hills",
                "countryCode": "US"
            },
            {
                "name": "Tuscarawas",
                "countryCode": "US"
            },
            {
                "name": "Tuscarawas County",
                "countryCode": "US"
            },
            {
                "name": "Twinsburg",
                "countryCode": "US"
            },
            {
                "name": "Uhrichsville",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Uniontown",
                "countryCode": "US"
            },
            {
                "name": "University Heights",
                "countryCode": "US"
            },
            {
                "name": "Upper Arlington",
                "countryCode": "US"
            },
            {
                "name": "Upper Sandusky",
                "countryCode": "US"
            },
            {
                "name": "Urbana",
                "countryCode": "US"
            },
            {
                "name": "Urbancrest",
                "countryCode": "US"
            },
            {
                "name": "Utica",
                "countryCode": "US"
            },
            {
                "name": "Valley View",
                "countryCode": "US"
            },
            {
                "name": "Van Wert",
                "countryCode": "US"
            },
            {
                "name": "Van Wert County",
                "countryCode": "US"
            },
            {
                "name": "Vandalia",
                "countryCode": "US"
            },
            {
                "name": "Vermilion",
                "countryCode": "US"
            },
            {
                "name": "Vermilion-on-the-Lake",
                "countryCode": "US"
            },
            {
                "name": "Versailles",
                "countryCode": "US"
            },
            {
                "name": "Vinton County",
                "countryCode": "US"
            },
            {
                "name": "Wadsworth",
                "countryCode": "US"
            },
            {
                "name": "Wakeman",
                "countryCode": "US"
            },
            {
                "name": "Walbridge",
                "countryCode": "US"
            },
            {
                "name": "Walton Hills",
                "countryCode": "US"
            },
            {
                "name": "Wapakoneta",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrensville Heights",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washington Court House",
                "countryCode": "US"
            },
            {
                "name": "Waterville",
                "countryCode": "US"
            },
            {
                "name": "Wauseon",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesville",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "Wellston",
                "countryCode": "US"
            },
            {
                "name": "Wellsville",
                "countryCode": "US"
            },
            {
                "name": "West Alexandria",
                "countryCode": "US"
            },
            {
                "name": "West Carrollton City",
                "countryCode": "US"
            },
            {
                "name": "West Hill",
                "countryCode": "US"
            },
            {
                "name": "West Jefferson",
                "countryCode": "US"
            },
            {
                "name": "West Lafayette",
                "countryCode": "US"
            },
            {
                "name": "West Liberty",
                "countryCode": "US"
            },
            {
                "name": "West Milton",
                "countryCode": "US"
            },
            {
                "name": "West Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "West Salem",
                "countryCode": "US"
            },
            {
                "name": "West Union",
                "countryCode": "US"
            },
            {
                "name": "West Unity",
                "countryCode": "US"
            },
            {
                "name": "Westerville",
                "countryCode": "US"
            },
            {
                "name": "Westfield Center",
                "countryCode": "US"
            },
            {
                "name": "Westlake",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Wetherington",
                "countryCode": "US"
            },
            {
                "name": "Wheelersburg",
                "countryCode": "US"
            },
            {
                "name": "White Oak",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Whitehouse",
                "countryCode": "US"
            },
            {
                "name": "Wickliffe",
                "countryCode": "US"
            },
            {
                "name": "Wilberforce",
                "countryCode": "US"
            },
            {
                "name": "Willard",
                "countryCode": "US"
            },
            {
                "name": "Williams County",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamsport",
                "countryCode": "US"
            },
            {
                "name": "Willoughby",
                "countryCode": "US"
            },
            {
                "name": "Willoughby Hills",
                "countryCode": "US"
            },
            {
                "name": "Willowick",
                "countryCode": "US"
            },
            {
                "name": "Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windham",
                "countryCode": "US"
            },
            {
                "name": "Wintersville",
                "countryCode": "US"
            },
            {
                "name": "Withamsville",
                "countryCode": "US"
            },
            {
                "name": "Wolfhurst",
                "countryCode": "US"
            },
            {
                "name": "Wood County",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn",
                "countryCode": "US"
            },
            {
                "name": "Woodsfield",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Wooster",
                "countryCode": "US"
            },
            {
                "name": "Worthington",
                "countryCode": "US"
            },
            {
                "name": "Wright-Patterson AFB",
                "countryCode": "US"
            },
            {
                "name": "Wyandot County",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Xenia",
                "countryCode": "US"
            },
            {
                "name": "Yellow Springs",
                "countryCode": "US"
            },
            {
                "name": "Yorkville",
                "countryCode": "US"
            },
            {
                "name": "Youngstown",
                "countryCode": "US"
            },
            {
                "name": "Zanesville",
                "countryCode": "US"
            },
            {
                "name": "Ada",
                "countryCode": "US"
            },
            {
                "name": "Adair County",
                "countryCode": "US"
            },
            {
                "name": "Afton",
                "countryCode": "US"
            },
            {
                "name": "Alfalfa County",
                "countryCode": "US"
            },
            {
                "name": "Altus",
                "countryCode": "US"
            },
            {
                "name": "Alva",
                "countryCode": "US"
            },
            {
                "name": "Anadarko",
                "countryCode": "US"
            },
            {
                "name": "Antlers",
                "countryCode": "US"
            },
            {
                "name": "Apache",
                "countryCode": "US"
            },
            {
                "name": "Arapaho",
                "countryCode": "US"
            },
            {
                "name": "Ardmore",
                "countryCode": "US"
            },
            {
                "name": "Arkoma",
                "countryCode": "US"
            },
            {
                "name": "Arnett",
                "countryCode": "US"
            },
            {
                "name": "Atoka",
                "countryCode": "US"
            },
            {
                "name": "Atoka County",
                "countryCode": "US"
            },
            {
                "name": "Barnsdall",
                "countryCode": "US"
            },
            {
                "name": "Bartlesville",
                "countryCode": "US"
            },
            {
                "name": "Beaver",
                "countryCode": "US"
            },
            {
                "name": "Beaver County",
                "countryCode": "US"
            },
            {
                "name": "Beckham County",
                "countryCode": "US"
            },
            {
                "name": "Beggs",
                "countryCode": "US"
            },
            {
                "name": "Bethany",
                "countryCode": "US"
            },
            {
                "name": "Bethel Acres",
                "countryCode": "US"
            },
            {
                "name": "Bixby",
                "countryCode": "US"
            },
            {
                "name": "Blackwell",
                "countryCode": "US"
            },
            {
                "name": "Blaine County",
                "countryCode": "US"
            },
            {
                "name": "Blanchard",
                "countryCode": "US"
            },
            {
                "name": "Boise City",
                "countryCode": "US"
            },
            {
                "name": "Boley",
                "countryCode": "US"
            },
            {
                "name": "Bray",
                "countryCode": "US"
            },
            {
                "name": "Bristow",
                "countryCode": "US"
            },
            {
                "name": "Broken Arrow",
                "countryCode": "US"
            },
            {
                "name": "Broken Bow",
                "countryCode": "US"
            },
            {
                "name": "Bryan County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Burns Flat",
                "countryCode": "US"
            },
            {
                "name": "Bushyhead",
                "countryCode": "US"
            },
            {
                "name": "Byng",
                "countryCode": "US"
            },
            {
                "name": "Cache",
                "countryCode": "US"
            },
            {
                "name": "Caddo",
                "countryCode": "US"
            },
            {
                "name": "Caddo County",
                "countryCode": "US"
            },
            {
                "name": "Calera",
                "countryCode": "US"
            },
            {
                "name": "Canadian County",
                "countryCode": "US"
            },
            {
                "name": "Carnegie",
                "countryCode": "US"
            },
            {
                "name": "Carter County",
                "countryCode": "US"
            },
            {
                "name": "Catoosa",
                "countryCode": "US"
            },
            {
                "name": "Central High",
                "countryCode": "US"
            },
            {
                "name": "Chandler",
                "countryCode": "US"
            },
            {
                "name": "Checotah",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Cherokee",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne",
                "countryCode": "US"
            },
            {
                "name": "Chickasha",
                "countryCode": "US"
            },
            {
                "name": "Choctaw",
                "countryCode": "US"
            },
            {
                "name": "Choctaw County",
                "countryCode": "US"
            },
            {
                "name": "Chouteau",
                "countryCode": "US"
            },
            {
                "name": "Cimarron County",
                "countryCode": "US"
            },
            {
                "name": "Claremore",
                "countryCode": "US"
            },
            {
                "name": "Cleora",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Cleveland County",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Coal County",
                "countryCode": "US"
            },
            {
                "name": "Coalgate",
                "countryCode": "US"
            },
            {
                "name": "Colbert",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Comanche",
                "countryCode": "US"
            },
            {
                "name": "Comanche County",
                "countryCode": "US"
            },
            {
                "name": "Commerce",
                "countryCode": "US"
            },
            {
                "name": "Copeland",
                "countryCode": "US"
            },
            {
                "name": "Cordell",
                "countryCode": "US"
            },
            {
                "name": "Cotton County",
                "countryCode": "US"
            },
            {
                "name": "Coweta",
                "countryCode": "US"
            },
            {
                "name": "Craig County",
                "countryCode": "US"
            },
            {
                "name": "Creek County",
                "countryCode": "US"
            },
            {
                "name": "Crescent",
                "countryCode": "US"
            },
            {
                "name": "Cushing",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Cyril",
                "countryCode": "US"
            },
            {
                "name": "Davis",
                "countryCode": "US"
            },
            {
                "name": "Del City",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Dewey",
                "countryCode": "US"
            },
            {
                "name": "Dewey County",
                "countryCode": "US"
            },
            {
                "name": "Dickson",
                "countryCode": "US"
            },
            {
                "name": "Drumright",
                "countryCode": "US"
            },
            {
                "name": "Duncan",
                "countryCode": "US"
            },
            {
                "name": "Durant",
                "countryCode": "US"
            },
            {
                "name": "Edmond",
                "countryCode": "US"
            },
            {
                "name": "El Reno",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Elk City",
                "countryCode": "US"
            },
            {
                "name": "Ellis County",
                "countryCode": "US"
            },
            {
                "name": "Enid",
                "countryCode": "US"
            },
            {
                "name": "Erick",
                "countryCode": "US"
            },
            {
                "name": "Eufaula",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairland",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fletcher",
                "countryCode": "US"
            },
            {
                "name": "Forest Park",
                "countryCode": "US"
            },
            {
                "name": "Fort Gibson",
                "countryCode": "US"
            },
            {
                "name": "Frederick",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Garvin County",
                "countryCode": "US"
            },
            {
                "name": "Geary",
                "countryCode": "US"
            },
            {
                "name": "Geronimo",
                "countryCode": "US"
            },
            {
                "name": "Glenpool",
                "countryCode": "US"
            },
            {
                "name": "Goldsby",
                "countryCode": "US"
            },
            {
                "name": "Goodwell",
                "countryCode": "US"
            },
            {
                "name": "Grady County",
                "countryCode": "US"
            },
            {
                "name": "Granite",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Greer County",
                "countryCode": "US"
            },
            {
                "name": "Grove",
                "countryCode": "US"
            },
            {
                "name": "Guthrie",
                "countryCode": "US"
            },
            {
                "name": "Guymon",
                "countryCode": "US"
            },
            {
                "name": "Hall Park",
                "countryCode": "US"
            },
            {
                "name": "Harmon County",
                "countryCode": "US"
            },
            {
                "name": "Harper County",
                "countryCode": "US"
            },
            {
                "name": "Harrah",
                "countryCode": "US"
            },
            {
                "name": "Hartshorne",
                "countryCode": "US"
            },
            {
                "name": "Haskell",
                "countryCode": "US"
            },
            {
                "name": "Haskell County",
                "countryCode": "US"
            },
            {
                "name": "Healdton",
                "countryCode": "US"
            },
            {
                "name": "Heavener",
                "countryCode": "US"
            },
            {
                "name": "Helena",
                "countryCode": "US"
            },
            {
                "name": "Hennessey",
                "countryCode": "US"
            },
            {
                "name": "Henryetta",
                "countryCode": "US"
            },
            {
                "name": "Hinton",
                "countryCode": "US"
            },
            {
                "name": "Hobart",
                "countryCode": "US"
            },
            {
                "name": "Holdenville",
                "countryCode": "US"
            },
            {
                "name": "Hollis",
                "countryCode": "US"
            },
            {
                "name": "Hominy",
                "countryCode": "US"
            },
            {
                "name": "Hooker",
                "countryCode": "US"
            },
            {
                "name": "Hughes County",
                "countryCode": "US"
            },
            {
                "name": "Hugo",
                "countryCode": "US"
            },
            {
                "name": "Idabel",
                "countryCode": "US"
            },
            {
                "name": "Inola",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jay",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jenks",
                "countryCode": "US"
            },
            {
                "name": "Johnston County",
                "countryCode": "US"
            },
            {
                "name": "Jones",
                "countryCode": "US"
            },
            {
                "name": "Justice",
                "countryCode": "US"
            },
            {
                "name": "Kay County",
                "countryCode": "US"
            },
            {
                "name": "Kellyville",
                "countryCode": "US"
            },
            {
                "name": "Kenwood",
                "countryCode": "US"
            },
            {
                "name": "Kiefer",
                "countryCode": "US"
            },
            {
                "name": "Kingfisher",
                "countryCode": "US"
            },
            {
                "name": "Kingfisher County",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kiowa County",
                "countryCode": "US"
            },
            {
                "name": "Konawa",
                "countryCode": "US"
            },
            {
                "name": "Krebs",
                "countryCode": "US"
            },
            {
                "name": "Langston",
                "countryCode": "US"
            },
            {
                "name": "Latimer County",
                "countryCode": "US"
            },
            {
                "name": "Laverne",
                "countryCode": "US"
            },
            {
                "name": "Lawton",
                "countryCode": "US"
            },
            {
                "name": "Le Flore County",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lindsay",
                "countryCode": "US"
            },
            {
                "name": "Locust Grove",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Lone Grove",
                "countryCode": "US"
            },
            {
                "name": "Longtown",
                "countryCode": "US"
            },
            {
                "name": "Love County",
                "countryCode": "US"
            },
            {
                "name": "Luther",
                "countryCode": "US"
            },
            {
                "name": "Madill",
                "countryCode": "US"
            },
            {
                "name": "Major County",
                "countryCode": "US"
            },
            {
                "name": "Mangum",
                "countryCode": "US"
            },
            {
                "name": "Mannford",
                "countryCode": "US"
            },
            {
                "name": "Marietta",
                "countryCode": "US"
            },
            {
                "name": "Marlow",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Maud",
                "countryCode": "US"
            },
            {
                "name": "Mayes County",
                "countryCode": "US"
            },
            {
                "name": "Maysville",
                "countryCode": "US"
            },
            {
                "name": "McAlester",
                "countryCode": "US"
            },
            {
                "name": "McClain County",
                "countryCode": "US"
            },
            {
                "name": "McCord",
                "countryCode": "US"
            },
            {
                "name": "McCurtain County",
                "countryCode": "US"
            },
            {
                "name": "McIntosh County",
                "countryCode": "US"
            },
            {
                "name": "McLoud",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Meeker",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Miami",
                "countryCode": "US"
            },
            {
                "name": "Midwest City",
                "countryCode": "US"
            },
            {
                "name": "Minco",
                "countryCode": "US"
            },
            {
                "name": "Moore",
                "countryCode": "US"
            },
            {
                "name": "Mooreland",
                "countryCode": "US"
            },
            {
                "name": "Morris",
                "countryCode": "US"
            },
            {
                "name": "Mounds",
                "countryCode": "US"
            },
            {
                "name": "Muldrow",
                "countryCode": "US"
            },
            {
                "name": "Murray County",
                "countryCode": "US"
            },
            {
                "name": "Muskogee",
                "countryCode": "US"
            },
            {
                "name": "Muskogee County",
                "countryCode": "US"
            },
            {
                "name": "Mustang",
                "countryCode": "US"
            },
            {
                "name": "Newcastle",
                "countryCode": "US"
            },
            {
                "name": "Newkirk",
                "countryCode": "US"
            },
            {
                "name": "Nichols Hills",
                "countryCode": "US"
            },
            {
                "name": "Nicoma Park",
                "countryCode": "US"
            },
            {
                "name": "Ninnekah",
                "countryCode": "US"
            },
            {
                "name": "Noble",
                "countryCode": "US"
            },
            {
                "name": "Noble County",
                "countryCode": "US"
            },
            {
                "name": "Norman",
                "countryCode": "US"
            },
            {
                "name": "Nowata",
                "countryCode": "US"
            },
            {
                "name": "Nowata County",
                "countryCode": "US"
            },
            {
                "name": "Oakhurst",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oilton",
                "countryCode": "US"
            },
            {
                "name": "Okarche",
                "countryCode": "US"
            },
            {
                "name": "Okeene",
                "countryCode": "US"
            },
            {
                "name": "Okemah",
                "countryCode": "US"
            },
            {
                "name": "Okfuskee County",
                "countryCode": "US"
            },
            {
                "name": "Oklahoma City",
                "countryCode": "US"
            },
            {
                "name": "Oklahoma County",
                "countryCode": "US"
            },
            {
                "name": "Okmulgee",
                "countryCode": "US"
            },
            {
                "name": "Okmulgee County",
                "countryCode": "US"
            },
            {
                "name": "Oologah",
                "countryCode": "US"
            },
            {
                "name": "Osage County",
                "countryCode": "US"
            },
            {
                "name": "Ottawa County",
                "countryCode": "US"
            },
            {
                "name": "Owasso",
                "countryCode": "US"
            },
            {
                "name": "Panama",
                "countryCode": "US"
            },
            {
                "name": "Park Hill",
                "countryCode": "US"
            },
            {
                "name": "Pauls Valley",
                "countryCode": "US"
            },
            {
                "name": "Pawhuska",
                "countryCode": "US"
            },
            {
                "name": "Pawnee",
                "countryCode": "US"
            },
            {
                "name": "Pawnee County",
                "countryCode": "US"
            },
            {
                "name": "Payne County",
                "countryCode": "US"
            },
            {
                "name": "Perkins",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Piedmont",
                "countryCode": "US"
            },
            {
                "name": "Pink",
                "countryCode": "US"
            },
            {
                "name": "Pittsburg County",
                "countryCode": "US"
            },
            {
                "name": "Pocola",
                "countryCode": "US"
            },
            {
                "name": "Ponca City",
                "countryCode": "US"
            },
            {
                "name": "Pontotoc County",
                "countryCode": "US"
            },
            {
                "name": "Poteau",
                "countryCode": "US"
            },
            {
                "name": "Pottawatomie County",
                "countryCode": "US"
            },
            {
                "name": "Prague",
                "countryCode": "US"
            },
            {
                "name": "Pryor",
                "countryCode": "US"
            },
            {
                "name": "Pryor Creek",
                "countryCode": "US"
            },
            {
                "name": "Purcell",
                "countryCode": "US"
            },
            {
                "name": "Pushmataha County",
                "countryCode": "US"
            },
            {
                "name": "Quinton",
                "countryCode": "US"
            },
            {
                "name": "Ringling",
                "countryCode": "US"
            },
            {
                "name": "Roger Mills County",
                "countryCode": "US"
            },
            {
                "name": "Rogers County",
                "countryCode": "US"
            },
            {
                "name": "Roland",
                "countryCode": "US"
            },
            {
                "name": "Rush Springs",
                "countryCode": "US"
            },
            {
                "name": "Salina",
                "countryCode": "US"
            },
            {
                "name": "Sallisaw",
                "countryCode": "US"
            },
            {
                "name": "Sand Springs",
                "countryCode": "US"
            },
            {
                "name": "Sapulpa",
                "countryCode": "US"
            },
            {
                "name": "Sayre",
                "countryCode": "US"
            },
            {
                "name": "Seminole",
                "countryCode": "US"
            },
            {
                "name": "Seminole County",
                "countryCode": "US"
            },
            {
                "name": "Sequoyah County",
                "countryCode": "US"
            },
            {
                "name": "Shattuck",
                "countryCode": "US"
            },
            {
                "name": "Shawnee",
                "countryCode": "US"
            },
            {
                "name": "Skiatook",
                "countryCode": "US"
            },
            {
                "name": "Slaughterville",
                "countryCode": "US"
            },
            {
                "name": "Snyder",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Sperry",
                "countryCode": "US"
            },
            {
                "name": "Spiro",
                "countryCode": "US"
            },
            {
                "name": "Stephens County",
                "countryCode": "US"
            },
            {
                "name": "Stigler",
                "countryCode": "US"
            },
            {
                "name": "Stillwater",
                "countryCode": "US"
            },
            {
                "name": "Stilwell",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Stroud",
                "countryCode": "US"
            },
            {
                "name": "Sulphur",
                "countryCode": "US"
            },
            {
                "name": "Tahlequah",
                "countryCode": "US"
            },
            {
                "name": "Talihina",
                "countryCode": "US"
            },
            {
                "name": "Taloga",
                "countryCode": "US"
            },
            {
                "name": "Tecumseh",
                "countryCode": "US"
            },
            {
                "name": "Texanna",
                "countryCode": "US"
            },
            {
                "name": "Texas County",
                "countryCode": "US"
            },
            {
                "name": "The Village",
                "countryCode": "US"
            },
            {
                "name": "Thomas",
                "countryCode": "US"
            },
            {
                "name": "Tillman County",
                "countryCode": "US"
            },
            {
                "name": "Tishomingo",
                "countryCode": "US"
            },
            {
                "name": "Tonkawa",
                "countryCode": "US"
            },
            {
                "name": "Tulsa",
                "countryCode": "US"
            },
            {
                "name": "Tulsa County",
                "countryCode": "US"
            },
            {
                "name": "Turley",
                "countryCode": "US"
            },
            {
                "name": "Tuttle",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Verdigris",
                "countryCode": "US"
            },
            {
                "name": "Vian",
                "countryCode": "US"
            },
            {
                "name": "Vinita",
                "countryCode": "US"
            },
            {
                "name": "Wagoner",
                "countryCode": "US"
            },
            {
                "name": "Wagoner County",
                "countryCode": "US"
            },
            {
                "name": "Walters",
                "countryCode": "US"
            },
            {
                "name": "Warner",
                "countryCode": "US"
            },
            {
                "name": "Warr Acres",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washita County",
                "countryCode": "US"
            },
            {
                "name": "Watonga",
                "countryCode": "US"
            },
            {
                "name": "Waukomis",
                "countryCode": "US"
            },
            {
                "name": "Waurika",
                "countryCode": "US"
            },
            {
                "name": "Weatherford",
                "countryCode": "US"
            },
            {
                "name": "Westville",
                "countryCode": "US"
            },
            {
                "name": "Wetumka",
                "countryCode": "US"
            },
            {
                "name": "Wewoka",
                "countryCode": "US"
            },
            {
                "name": "Wilburton",
                "countryCode": "US"
            },
            {
                "name": "Wilson",
                "countryCode": "US"
            },
            {
                "name": "Wister",
                "countryCode": "US"
            },
            {
                "name": "Woods County",
                "countryCode": "US"
            },
            {
                "name": "Woodward",
                "countryCode": "US"
            },
            {
                "name": "Woodward County",
                "countryCode": "US"
            },
            {
                "name": "Wynnewood",
                "countryCode": "US"
            },
            {
                "name": "Yale",
                "countryCode": "US"
            },
            {
                "name": "Yukon",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Aloha",
                "countryCode": "US"
            },
            {
                "name": "Altamont",
                "countryCode": "US"
            },
            {
                "name": "Amity",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Astoria",
                "countryCode": "US"
            },
            {
                "name": "Athena",
                "countryCode": "US"
            },
            {
                "name": "Aumsville",
                "countryCode": "US"
            },
            {
                "name": "Baker City",
                "countryCode": "US"
            },
            {
                "name": "Baker County",
                "countryCode": "US"
            },
            {
                "name": "Bandon",
                "countryCode": "US"
            },
            {
                "name": "Banks",
                "countryCode": "US"
            },
            {
                "name": "Barview",
                "countryCode": "US"
            },
            {
                "name": "Bay City",
                "countryCode": "US"
            },
            {
                "name": "Beavercreek",
                "countryCode": "US"
            },
            {
                "name": "Beaverton",
                "countryCode": "US"
            },
            {
                "name": "Bend",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Bethany",
                "countryCode": "US"
            },
            {
                "name": "Boardman",
                "countryCode": "US"
            },
            {
                "name": "Brookings",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Bunker Hill",
                "countryCode": "US"
            },
            {
                "name": "Burns",
                "countryCode": "US"
            },
            {
                "name": "Canby",
                "countryCode": "US"
            },
            {
                "name": "Cannon Beach",
                "countryCode": "US"
            },
            {
                "name": "Canyon City",
                "countryCode": "US"
            },
            {
                "name": "Canyonville",
                "countryCode": "US"
            },
            {
                "name": "Carlton",
                "countryCode": "US"
            },
            {
                "name": "Cascade Locks",
                "countryCode": "US"
            },
            {
                "name": "Cave Junction",
                "countryCode": "US"
            },
            {
                "name": "Cedar Hills",
                "countryCode": "US"
            },
            {
                "name": "Cedar Mill",
                "countryCode": "US"
            },
            {
                "name": "Central Point",
                "countryCode": "US"
            },
            {
                "name": "Chenoweth",
                "countryCode": "US"
            },
            {
                "name": "Clackamas",
                "countryCode": "US"
            },
            {
                "name": "Clackamas County",
                "countryCode": "US"
            },
            {
                "name": "Clatskanie",
                "countryCode": "US"
            },
            {
                "name": "Clatsop County",
                "countryCode": "US"
            },
            {
                "name": "Coburg",
                "countryCode": "US"
            },
            {
                "name": "Columbia City",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Condon",
                "countryCode": "US"
            },
            {
                "name": "Coos Bay",
                "countryCode": "US"
            },
            {
                "name": "Coos County",
                "countryCode": "US"
            },
            {
                "name": "Coquille",
                "countryCode": "US"
            },
            {
                "name": "Cornelius",
                "countryCode": "US"
            },
            {
                "name": "Corvallis",
                "countryCode": "US"
            },
            {
                "name": "Cottage Grove",
                "countryCode": "US"
            },
            {
                "name": "Creswell",
                "countryCode": "US"
            },
            {
                "name": "Crook County",
                "countryCode": "US"
            },
            {
                "name": "Culp Creek",
                "countryCode": "US"
            },
            {
                "name": "Culver",
                "countryCode": "US"
            },
            {
                "name": "Curry County",
                "countryCode": "US"
            },
            {
                "name": "Dallas",
                "countryCode": "US"
            },
            {
                "name": "Damascus",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Depoe Bay",
                "countryCode": "US"
            },
            {
                "name": "Deschutes County",
                "countryCode": "US"
            },
            {
                "name": "Deschutes River Woods",
                "countryCode": "US"
            },
            {
                "name": "Donald",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Drain",
                "countryCode": "US"
            },
            {
                "name": "Dundee",
                "countryCode": "US"
            },
            {
                "name": "Dunes City",
                "countryCode": "US"
            },
            {
                "name": "Durham",
                "countryCode": "US"
            },
            {
                "name": "Eagle Point",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Enterprise",
                "countryCode": "US"
            },
            {
                "name": "Estacada",
                "countryCode": "US"
            },
            {
                "name": "Eugene",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Forest Grove",
                "countryCode": "US"
            },
            {
                "name": "Fossil",
                "countryCode": "US"
            },
            {
                "name": "Four Corners",
                "countryCode": "US"
            },
            {
                "name": "Fruitdale",
                "countryCode": "US"
            },
            {
                "name": "Garden Home-Whitford",
                "countryCode": "US"
            },
            {
                "name": "Gearhart",
                "countryCode": "US"
            },
            {
                "name": "Gervais",
                "countryCode": "US"
            },
            {
                "name": "Gilliam County",
                "countryCode": "US"
            },
            {
                "name": "Gladstone",
                "countryCode": "US"
            },
            {
                "name": "Glide",
                "countryCode": "US"
            },
            {
                "name": "Gold Beach",
                "countryCode": "US"
            },
            {
                "name": "Gold Hill",
                "countryCode": "US"
            },
            {
                "name": "Grand Ronde",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Grants Pass",
                "countryCode": "US"
            },
            {
                "name": "Green",
                "countryCode": "US"
            },
            {
                "name": "Gresham",
                "countryCode": "US"
            },
            {
                "name": "Happy Valley",
                "countryCode": "US"
            },
            {
                "name": "Harbor",
                "countryCode": "US"
            },
            {
                "name": "Harney County",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Hayesville",
                "countryCode": "US"
            },
            {
                "name": "Heppner",
                "countryCode": "US"
            },
            {
                "name": "Hermiston",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hines",
                "countryCode": "US"
            },
            {
                "name": "Hood River",
                "countryCode": "US"
            },
            {
                "name": "Hood River County",
                "countryCode": "US"
            },
            {
                "name": "Hubbard",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Irrigon",
                "countryCode": "US"
            },
            {
                "name": "Island City",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jennings Lodge",
                "countryCode": "US"
            },
            {
                "name": "John Day",
                "countryCode": "US"
            },
            {
                "name": "Joseph",
                "countryCode": "US"
            },
            {
                "name": "Josephine County",
                "countryCode": "US"
            },
            {
                "name": "Junction City",
                "countryCode": "US"
            },
            {
                "name": "Keizer",
                "countryCode": "US"
            },
            {
                "name": "Kenton",
                "countryCode": "US"
            },
            {
                "name": "King City",
                "countryCode": "US"
            },
            {
                "name": "Klamath County",
                "countryCode": "US"
            },
            {
                "name": "Klamath Falls",
                "countryCode": "US"
            },
            {
                "name": "La Grande",
                "countryCode": "US"
            },
            {
                "name": "La Pine",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Oswego",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lane County",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lents",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Beach",
                "countryCode": "US"
            },
            {
                "name": "Lincoln City",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Linn County",
                "countryCode": "US"
            },
            {
                "name": "Lowell",
                "countryCode": "US"
            },
            {
                "name": "Lyons",
                "countryCode": "US"
            },
            {
                "name": "Madras",
                "countryCode": "US"
            },
            {
                "name": "Malheur County",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "McMinnville",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Merlin",
                "countryCode": "US"
            },
            {
                "name": "Metzger",
                "countryCode": "US"
            },
            {
                "name": "Mill City",
                "countryCode": "US"
            },
            {
                "name": "Millersburg",
                "countryCode": "US"
            },
            {
                "name": "Milton-Freewater",
                "countryCode": "US"
            },
            {
                "name": "Milwaukie",
                "countryCode": "US"
            },
            {
                "name": "Mission",
                "countryCode": "US"
            },
            {
                "name": "Molalla",
                "countryCode": "US"
            },
            {
                "name": "Monmouth",
                "countryCode": "US"
            },
            {
                "name": "Moro",
                "countryCode": "US"
            },
            {
                "name": "Morrow County",
                "countryCode": "US"
            },
            {
                "name": "Mount Angel",
                "countryCode": "US"
            },
            {
                "name": "Mount Hood Village",
                "countryCode": "US"
            },
            {
                "name": "Mulino",
                "countryCode": "US"
            },
            {
                "name": "Multnomah County",
                "countryCode": "US"
            },
            {
                "name": "Myrtle Creek",
                "countryCode": "US"
            },
            {
                "name": "Myrtle Point",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "Newberg",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "North Bend",
                "countryCode": "US"
            },
            {
                "name": "North Plains",
                "countryCode": "US"
            },
            {
                "name": "North Portland",
                "countryCode": "US"
            },
            {
                "name": "Nyssa",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oak Hills",
                "countryCode": "US"
            },
            {
                "name": "Oakridge",
                "countryCode": "US"
            },
            {
                "name": "Oatfield",
                "countryCode": "US"
            },
            {
                "name": "Odell",
                "countryCode": "US"
            },
            {
                "name": "Ontario",
                "countryCode": "US"
            },
            {
                "name": "Oregon City",
                "countryCode": "US"
            },
            {
                "name": "Pacific City",
                "countryCode": "US"
            },
            {
                "name": "Pendleton",
                "countryCode": "US"
            },
            {
                "name": "Philomath",
                "countryCode": "US"
            },
            {
                "name": "Phoenix",
                "countryCode": "US"
            },
            {
                "name": "Pilot Rock",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Port Orford",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Prineville",
                "countryCode": "US"
            },
            {
                "name": "Rainier",
                "countryCode": "US"
            },
            {
                "name": "Raleigh Hills",
                "countryCode": "US"
            },
            {
                "name": "Redmond",
                "countryCode": "US"
            },
            {
                "name": "Redwood",
                "countryCode": "US"
            },
            {
                "name": "Reedsport",
                "countryCode": "US"
            },
            {
                "name": "Riddle",
                "countryCode": "US"
            },
            {
                "name": "Rockaway Beach",
                "countryCode": "US"
            },
            {
                "name": "Rockcreek",
                "countryCode": "US"
            },
            {
                "name": "Rogue River",
                "countryCode": "US"
            },
            {
                "name": "Rose Lodge",
                "countryCode": "US"
            },
            {
                "name": "Roseburg",
                "countryCode": "US"
            },
            {
                "name": "Roseburg North",
                "countryCode": "US"
            },
            {
                "name": "Saint Helens",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Sandy",
                "countryCode": "US"
            },
            {
                "name": "Scappoose",
                "countryCode": "US"
            },
            {
                "name": "Seaside",
                "countryCode": "US"
            },
            {
                "name": "Shady Cove",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sherman County",
                "countryCode": "US"
            },
            {
                "name": "Sherwood",
                "countryCode": "US"
            },
            {
                "name": "Siletz",
                "countryCode": "US"
            },
            {
                "name": "Silverton",
                "countryCode": "US"
            },
            {
                "name": "Sisters",
                "countryCode": "US"
            },
            {
                "name": "South Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stafford",
                "countryCode": "US"
            },
            {
                "name": "Stanfield",
                "countryCode": "US"
            },
            {
                "name": "Stayton",
                "countryCode": "US"
            },
            {
                "name": "Sublimity",
                "countryCode": "US"
            },
            {
                "name": "Sunriver",
                "countryCode": "US"
            },
            {
                "name": "Sutherlin",
                "countryCode": "US"
            },
            {
                "name": "Sweet Home",
                "countryCode": "US"
            },
            {
                "name": "Talent",
                "countryCode": "US"
            },
            {
                "name": "Tangent",
                "countryCode": "US"
            },
            {
                "name": "Terrebonne",
                "countryCode": "US"
            },
            {
                "name": "The Dalles",
                "countryCode": "US"
            },
            {
                "name": "Three Rivers",
                "countryCode": "US"
            },
            {
                "name": "Tigard",
                "countryCode": "US"
            },
            {
                "name": "Tillamook",
                "countryCode": "US"
            },
            {
                "name": "Tillamook County",
                "countryCode": "US"
            },
            {
                "name": "Toledo",
                "countryCode": "US"
            },
            {
                "name": "Tri-City",
                "countryCode": "US"
            },
            {
                "name": "Troutdale",
                "countryCode": "US"
            },
            {
                "name": "Tualatin",
                "countryCode": "US"
            },
            {
                "name": "Turner",
                "countryCode": "US"
            },
            {
                "name": "Umatilla",
                "countryCode": "US"
            },
            {
                "name": "Umatilla County",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Vale",
                "countryCode": "US"
            },
            {
                "name": "Veneta",
                "countryCode": "US"
            },
            {
                "name": "Vernonia",
                "countryCode": "US"
            },
            {
                "name": "Waldport",
                "countryCode": "US"
            },
            {
                "name": "Wallowa County",
                "countryCode": "US"
            },
            {
                "name": "Warm Springs",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Wasco County",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "West Haven",
                "countryCode": "US"
            },
            {
                "name": "West Haven-Sylvan",
                "countryCode": "US"
            },
            {
                "name": "West Linn",
                "countryCode": "US"
            },
            {
                "name": "West Slope",
                "countryCode": "US"
            },
            {
                "name": "Wheeler County",
                "countryCode": "US"
            },
            {
                "name": "White City",
                "countryCode": "US"
            },
            {
                "name": "Willamina",
                "countryCode": "US"
            },
            {
                "name": "Williams",
                "countryCode": "US"
            },
            {
                "name": "Wilsonville",
                "countryCode": "US"
            },
            {
                "name": "Winston",
                "countryCode": "US"
            },
            {
                "name": "Wood Village",
                "countryCode": "US"
            },
            {
                "name": "Woodburn",
                "countryCode": "US"
            },
            {
                "name": "Yamhill",
                "countryCode": "US"
            },
            {
                "name": "Yamhill County",
                "countryCode": "US"
            },
            {
                "name": "Yoncalla",
                "countryCode": "US"
            },
            {
                "name": "Abbottstown",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Adamstown",
                "countryCode": "US"
            },
            {
                "name": "Akron",
                "countryCode": "US"
            },
            {
                "name": "Albion",
                "countryCode": "US"
            },
            {
                "name": "Alburtis",
                "countryCode": "US"
            },
            {
                "name": "Aldan",
                "countryCode": "US"
            },
            {
                "name": "Aliquippa",
                "countryCode": "US"
            },
            {
                "name": "Allegheny County",
                "countryCode": "US"
            },
            {
                "name": "Alleghenyville",
                "countryCode": "US"
            },
            {
                "name": "Allentown",
                "countryCode": "US"
            },
            {
                "name": "Allison Park",
                "countryCode": "US"
            },
            {
                "name": "Almedia",
                "countryCode": "US"
            },
            {
                "name": "Altoona",
                "countryCode": "US"
            },
            {
                "name": "Ambler",
                "countryCode": "US"
            },
            {
                "name": "Ambridge",
                "countryCode": "US"
            },
            {
                "name": "Amity Gardens",
                "countryCode": "US"
            },
            {
                "name": "Ancient Oaks",
                "countryCode": "US"
            },
            {
                "name": "Annville",
                "countryCode": "US"
            },
            {
                "name": "Apollo",
                "countryCode": "US"
            },
            {
                "name": "Archbald",
                "countryCode": "US"
            },
            {
                "name": "Ardmore",
                "countryCode": "US"
            },
            {
                "name": "Arlington Heights",
                "countryCode": "US"
            },
            {
                "name": "Armstrong County",
                "countryCode": "US"
            },
            {
                "name": "Arnold",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Ashley",
                "countryCode": "US"
            },
            {
                "name": "Aspinwall",
                "countryCode": "US"
            },
            {
                "name": "Atglen",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Audubon",
                "countryCode": "US"
            },
            {
                "name": "Avalon",
                "countryCode": "US"
            },
            {
                "name": "Avis",
                "countryCode": "US"
            },
            {
                "name": "Avoca",
                "countryCode": "US"
            },
            {
                "name": "Avon",
                "countryCode": "US"
            },
            {
                "name": "Avondale",
                "countryCode": "US"
            },
            {
                "name": "Avonia",
                "countryCode": "US"
            },
            {
                "name": "Back Mountain",
                "countryCode": "US"
            },
            {
                "name": "Baden",
                "countryCode": "US"
            },
            {
                "name": "Baidland",
                "countryCode": "US"
            },
            {
                "name": "Bainbridge",
                "countryCode": "US"
            },
            {
                "name": "Bakerstown",
                "countryCode": "US"
            },
            {
                "name": "Bala-Cynwyd",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Bally",
                "countryCode": "US"
            },
            {
                "name": "Bangor",
                "countryCode": "US"
            },
            {
                "name": "Barnesboro",
                "countryCode": "US"
            },
            {
                "name": "Bath",
                "countryCode": "US"
            },
            {
                "name": "Bear Rocks",
                "countryCode": "US"
            },
            {
                "name": "Beaver",
                "countryCode": "US"
            },
            {
                "name": "Beaver County",
                "countryCode": "US"
            },
            {
                "name": "Beaver Falls",
                "countryCode": "US"
            },
            {
                "name": "Beaverdale",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Bedford County",
                "countryCode": "US"
            },
            {
                "name": "Bedminster",
                "countryCode": "US"
            },
            {
                "name": "Beech Mountain Lakes",
                "countryCode": "US"
            },
            {
                "name": "Belfast",
                "countryCode": "US"
            },
            {
                "name": "Bell Acres",
                "countryCode": "US"
            },
            {
                "name": "Belle Vernon",
                "countryCode": "US"
            },
            {
                "name": "Bellefonte",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Bellwood",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Ben Avon",
                "countryCode": "US"
            },
            {
                "name": "Bentleyville",
                "countryCode": "US"
            },
            {
                "name": "Berks County",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Berwick",
                "countryCode": "US"
            },
            {
                "name": "Berwyn",
                "countryCode": "US"
            },
            {
                "name": "Bessemer",
                "countryCode": "US"
            },
            {
                "name": "Bethel Park",
                "countryCode": "US"
            },
            {
                "name": "Bethlehem",
                "countryCode": "US"
            },
            {
                "name": "Big Bass Lake",
                "countryCode": "US"
            },
            {
                "name": "Big Beaver",
                "countryCode": "US"
            },
            {
                "name": "Biglerville",
                "countryCode": "US"
            },
            {
                "name": "Birchwood Lakes",
                "countryCode": "US"
            },
            {
                "name": "Birdsboro",
                "countryCode": "US"
            },
            {
                "name": "Black Lick",
                "countryCode": "US"
            },
            {
                "name": "Blair County",
                "countryCode": "US"
            },
            {
                "name": "Blairsville",
                "countryCode": "US"
            },
            {
                "name": "Blakely",
                "countryCode": "US"
            },
            {
                "name": "Blandon",
                "countryCode": "US"
            },
            {
                "name": "Blawnox",
                "countryCode": "US"
            },
            {
                "name": "Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "Bloomsburg",
                "countryCode": "US"
            },
            {
                "name": "Blossburg",
                "countryCode": "US"
            },
            {
                "name": "Blue Ball",
                "countryCode": "US"
            },
            {
                "name": "Blue Bell",
                "countryCode": "US"
            },
            {
                "name": "Boalsburg",
                "countryCode": "US"
            },
            {
                "name": "Boiling Springs",
                "countryCode": "US"
            },
            {
                "name": "Bonneauville",
                "countryCode": "US"
            },
            {
                "name": "Boothwyn",
                "countryCode": "US"
            },
            {
                "name": "Boswell",
                "countryCode": "US"
            },
            {
                "name": "Bowmansville",
                "countryCode": "US"
            },
            {
                "name": "Boyertown",
                "countryCode": "US"
            },
            {
                "name": "Brackenridge",
                "countryCode": "US"
            },
            {
                "name": "Braddock",
                "countryCode": "US"
            },
            {
                "name": "Braddock Hills",
                "countryCode": "US"
            },
            {
                "name": "Bradford",
                "countryCode": "US"
            },
            {
                "name": "Bradford County",
                "countryCode": "US"
            },
            {
                "name": "Bradford Woods",
                "countryCode": "US"
            },
            {
                "name": "Breinigsville",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Bressler",
                "countryCode": "US"
            },
            {
                "name": "Brickerville",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Bridgeville",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Brittany Farms-Highlands",
                "countryCode": "US"
            },
            {
                "name": "Brockway",
                "countryCode": "US"
            },
            {
                "name": "Brodheadsville",
                "countryCode": "US"
            },
            {
                "name": "Brookhaven",
                "countryCode": "US"
            },
            {
                "name": "Brookville",
                "countryCode": "US"
            },
            {
                "name": "Broomall",
                "countryCode": "US"
            },
            {
                "name": "Brownstown",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Browntown",
                "countryCode": "US"
            },
            {
                "name": "Bryn Athyn",
                "countryCode": "US"
            },
            {
                "name": "Bryn Mawr",
                "countryCode": "US"
            },
            {
                "name": "Bucks County",
                "countryCode": "US"
            },
            {
                "name": "Burgettstown",
                "countryCode": "US"
            },
            {
                "name": "Burnham",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Butler County",
                "countryCode": "US"
            },
            {
                "name": "California",
                "countryCode": "US"
            },
            {
                "name": "Caln",
                "countryCode": "US"
            },
            {
                "name": "Calumet",
                "countryCode": "US"
            },
            {
                "name": "Cambria County",
                "countryCode": "US"
            },
            {
                "name": "Cambridge Springs",
                "countryCode": "US"
            },
            {
                "name": "Cameron County",
                "countryCode": "US"
            },
            {
                "name": "Camp Hill",
                "countryCode": "US"
            },
            {
                "name": "Campbelltown",
                "countryCode": "US"
            },
            {
                "name": "Canonsburg",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Carbon County",
                "countryCode": "US"
            },
            {
                "name": "Carbondale",
                "countryCode": "US"
            },
            {
                "name": "Carlisle",
                "countryCode": "US"
            },
            {
                "name": "Carnegie",
                "countryCode": "US"
            },
            {
                "name": "Carnot-Moon",
                "countryCode": "US"
            },
            {
                "name": "Carroll Valley",
                "countryCode": "US"
            },
            {
                "name": "Castanea",
                "countryCode": "US"
            },
            {
                "name": "Castle Shannon",
                "countryCode": "US"
            },
            {
                "name": "Catasauqua",
                "countryCode": "US"
            },
            {
                "name": "Catawissa",
                "countryCode": "US"
            },
            {
                "name": "Cecil-Bishop",
                "countryCode": "US"
            },
            {
                "name": "Cementon",
                "countryCode": "US"
            },
            {
                "name": "Center City",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Central City",
                "countryCode": "US"
            },
            {
                "name": "Centre County",
                "countryCode": "US"
            },
            {
                "name": "Centre Hall",
                "countryCode": "US"
            },
            {
                "name": "Cetronia",
                "countryCode": "US"
            },
            {
                "name": "Chalfont",
                "countryCode": "US"
            },
            {
                "name": "Chambersburg",
                "countryCode": "US"
            },
            {
                "name": "Charleroi",
                "countryCode": "US"
            },
            {
                "name": "Cherryville",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chester County",
                "countryCode": "US"
            },
            {
                "name": "Chester Heights",
                "countryCode": "US"
            },
            {
                "name": "Chester Springs",
                "countryCode": "US"
            },
            {
                "name": "Chesterbrook",
                "countryCode": "US"
            },
            {
                "name": "Cheswick",
                "countryCode": "US"
            },
            {
                "name": "Chevy Chase Heights",
                "countryCode": "US"
            },
            {
                "name": "Chicora",
                "countryCode": "US"
            },
            {
                "name": "Chinchilla",
                "countryCode": "US"
            },
            {
                "name": "Christiana",
                "countryCode": "US"
            },
            {
                "name": "Church Hill",
                "countryCode": "US"
            },
            {
                "name": "Churchill",
                "countryCode": "US"
            },
            {
                "name": "Churchville",
                "countryCode": "US"
            },
            {
                "name": "Clairton",
                "countryCode": "US"
            },
            {
                "name": "Clarion",
                "countryCode": "US"
            },
            {
                "name": "Clarion County",
                "countryCode": "US"
            },
            {
                "name": "Clarks Green",
                "countryCode": "US"
            },
            {
                "name": "Clarks Summit",
                "countryCode": "US"
            },
            {
                "name": "Clay",
                "countryCode": "US"
            },
            {
                "name": "Claysburg",
                "countryCode": "US"
            },
            {
                "name": "Clearfield",
                "countryCode": "US"
            },
            {
                "name": "Clearfield County",
                "countryCode": "US"
            },
            {
                "name": "Cleona",
                "countryCode": "US"
            },
            {
                "name": "Clifton Heights",
                "countryCode": "US"
            },
            {
                "name": "Clinton County",
                "countryCode": "US"
            },
            {
                "name": "Clymer",
                "countryCode": "US"
            },
            {
                "name": "Coaldale",
                "countryCode": "US"
            },
            {
                "name": "Coatesville",
                "countryCode": "US"
            },
            {
                "name": "Cochranton",
                "countryCode": "US"
            },
            {
                "name": "Collegeville",
                "countryCode": "US"
            },
            {
                "name": "Collingdale",
                "countryCode": "US"
            },
            {
                "name": "Collinsburg",
                "countryCode": "US"
            },
            {
                "name": "Colonial Park",
                "countryCode": "US"
            },
            {
                "name": "Colony Park",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Colwyn",
                "countryCode": "US"
            },
            {
                "name": "Conashaugh Lakes",
                "countryCode": "US"
            },
            {
                "name": "Conemaugh",
                "countryCode": "US"
            },
            {
                "name": "Conestoga",
                "countryCode": "US"
            },
            {
                "name": "Conneaut Lakeshore",
                "countryCode": "US"
            },
            {
                "name": "Connellsville",
                "countryCode": "US"
            },
            {
                "name": "Conshohocken",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Conyngham",
                "countryCode": "US"
            },
            {
                "name": "Coopersburg",
                "countryCode": "US"
            },
            {
                "name": "Coplay",
                "countryCode": "US"
            },
            {
                "name": "Coraopolis",
                "countryCode": "US"
            },
            {
                "name": "Cornwall",
                "countryCode": "US"
            },
            {
                "name": "Cornwells Heights",
                "countryCode": "US"
            },
            {
                "name": "Corry",
                "countryCode": "US"
            },
            {
                "name": "Coudersport",
                "countryCode": "US"
            },
            {
                "name": "Crafton",
                "countryCode": "US"
            },
            {
                "name": "Cranberry Township",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Cresson",
                "countryCode": "US"
            },
            {
                "name": "Cressona",
                "countryCode": "US"
            },
            {
                "name": "Croydon",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Curtisville",
                "countryCode": "US"
            },
            {
                "name": "Curwensville",
                "countryCode": "US"
            },
            {
                "name": "Dale",
                "countryCode": "US"
            },
            {
                "name": "Dallas",
                "countryCode": "US"
            },
            {
                "name": "Dallastown",
                "countryCode": "US"
            },
            {
                "name": "Dalton",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Darby",
                "countryCode": "US"
            },
            {
                "name": "Dauphin County",
                "countryCode": "US"
            },
            {
                "name": "Davidsville",
                "countryCode": "US"
            },
            {
                "name": "Delaware County",
                "countryCode": "US"
            },
            {
                "name": "Delmont",
                "countryCode": "US"
            },
            {
                "name": "Denver",
                "countryCode": "US"
            },
            {
                "name": "Derry",
                "countryCode": "US"
            },
            {
                "name": "Devon",
                "countryCode": "US"
            },
            {
                "name": "Dewart",
                "countryCode": "US"
            },
            {
                "name": "Dickson City",
                "countryCode": "US"
            },
            {
                "name": "Dillsburg",
                "countryCode": "US"
            },
            {
                "name": "Donora",
                "countryCode": "US"
            },
            {
                "name": "Dormont",
                "countryCode": "US"
            },
            {
                "name": "Dorneyville",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Downingtown",
                "countryCode": "US"
            },
            {
                "name": "Doylestown",
                "countryCode": "US"
            },
            {
                "name": "Dravosburg",
                "countryCode": "US"
            },
            {
                "name": "Dresher",
                "countryCode": "US"
            },
            {
                "name": "Drexel Hill",
                "countryCode": "US"
            },
            {
                "name": "DuBois",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Duboistown",
                "countryCode": "US"
            },
            {
                "name": "Dunbar",
                "countryCode": "US"
            },
            {
                "name": "Duncannon",
                "countryCode": "US"
            },
            {
                "name": "Duncansville",
                "countryCode": "US"
            },
            {
                "name": "Dunmore",
                "countryCode": "US"
            },
            {
                "name": "Dunnstown",
                "countryCode": "US"
            },
            {
                "name": "Dupont",
                "countryCode": "US"
            },
            {
                "name": "Duquesne",
                "countryCode": "US"
            },
            {
                "name": "Duryea",
                "countryCode": "US"
            },
            {
                "name": "Eagleview",
                "countryCode": "US"
            },
            {
                "name": "Eagleville",
                "countryCode": "US"
            },
            {
                "name": "Earlston",
                "countryCode": "US"
            },
            {
                "name": "East Bangor",
                "countryCode": "US"
            },
            {
                "name": "East Berlin",
                "countryCode": "US"
            },
            {
                "name": "East Berwick",
                "countryCode": "US"
            },
            {
                "name": "East Conemaugh",
                "countryCode": "US"
            },
            {
                "name": "East Earl",
                "countryCode": "US"
            },
            {
                "name": "East Greenville",
                "countryCode": "US"
            },
            {
                "name": "East Lansdowne",
                "countryCode": "US"
            },
            {
                "name": "East McKeesport",
                "countryCode": "US"
            },
            {
                "name": "East Petersburg",
                "countryCode": "US"
            },
            {
                "name": "East Pittsburgh",
                "countryCode": "US"
            },
            {
                "name": "East Stroudsburg",
                "countryCode": "US"
            },
            {
                "name": "East Uniontown",
                "countryCode": "US"
            },
            {
                "name": "East Washington",
                "countryCode": "US"
            },
            {
                "name": "East York",
                "countryCode": "US"
            },
            {
                "name": "Eastlawn Gardens",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Ebensburg",
                "countryCode": "US"
            },
            {
                "name": "Economy",
                "countryCode": "US"
            },
            {
                "name": "Eddington",
                "countryCode": "US"
            },
            {
                "name": "Eddystone",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edgeworth",
                "countryCode": "US"
            },
            {
                "name": "Edinboro",
                "countryCode": "US"
            },
            {
                "name": "Edwardsville",
                "countryCode": "US"
            },
            {
                "name": "Effort",
                "countryCode": "US"
            },
            {
                "name": "Egypt",
                "countryCode": "US"
            },
            {
                "name": "Elim",
                "countryCode": "US"
            },
            {
                "name": "Elizabeth",
                "countryCode": "US"
            },
            {
                "name": "Elizabethtown",
                "countryCode": "US"
            },
            {
                "name": "Elizabethville",
                "countryCode": "US"
            },
            {
                "name": "Elk County",
                "countryCode": "US"
            },
            {
                "name": "Elkland",
                "countryCode": "US"
            },
            {
                "name": "Ellport",
                "countryCode": "US"
            },
            {
                "name": "Ellwood City",
                "countryCode": "US"
            },
            {
                "name": "Elverson",
                "countryCode": "US"
            },
            {
                "name": "Elysburg",
                "countryCode": "US"
            },
            {
                "name": "Emerald Lakes",
                "countryCode": "US"
            },
            {
                "name": "Emigsville",
                "countryCode": "US"
            },
            {
                "name": "Emmaus",
                "countryCode": "US"
            },
            {
                "name": "Emporium",
                "countryCode": "US"
            },
            {
                "name": "Emsworth",
                "countryCode": "US"
            },
            {
                "name": "Enhaut",
                "countryCode": "US"
            },
            {
                "name": "Enlow",
                "countryCode": "US"
            },
            {
                "name": "Enola",
                "countryCode": "US"
            },
            {
                "name": "Ephrata",
                "countryCode": "US"
            },
            {
                "name": "Erie",
                "countryCode": "US"
            },
            {
                "name": "Erie County",
                "countryCode": "US"
            },
            {
                "name": "Espy",
                "countryCode": "US"
            },
            {
                "name": "Etna",
                "countryCode": "US"
            },
            {
                "name": "Evans City",
                "countryCode": "US"
            },
            {
                "name": "Evansburg",
                "countryCode": "US"
            },
            {
                "name": "Everett",
                "countryCode": "US"
            },
            {
                "name": "Exeter",
                "countryCode": "US"
            },
            {
                "name": "Exton",
                "countryCode": "US"
            },
            {
                "name": "Factoryville",
                "countryCode": "US"
            },
            {
                "name": "Fairchance",
                "countryCode": "US"
            },
            {
                "name": "Fairdale",
                "countryCode": "US"
            },
            {
                "name": "Fairhope",
                "countryCode": "US"
            },
            {
                "name": "Fairless Hills",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fairview-Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Falls Creek",
                "countryCode": "US"
            },
            {
                "name": "Farrell",
                "countryCode": "US"
            },
            {
                "name": "Faxon",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Feasterville",
                "countryCode": "US"
            },
            {
                "name": "Fellsburg",
                "countryCode": "US"
            },
            {
                "name": "Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Fernway",
                "countryCode": "US"
            },
            {
                "name": "Fivepointville",
                "countryCode": "US"
            },
            {
                "name": "Fleetwood",
                "countryCode": "US"
            },
            {
                "name": "Flemington",
                "countryCode": "US"
            },
            {
                "name": "Flourtown",
                "countryCode": "US"
            },
            {
                "name": "Flying Hills",
                "countryCode": "US"
            },
            {
                "name": "Folcroft",
                "countryCode": "US"
            },
            {
                "name": "Folsom",
                "countryCode": "US"
            },
            {
                "name": "Ford City",
                "countryCode": "US"
            },
            {
                "name": "Forest City",
                "countryCode": "US"
            },
            {
                "name": "Forest County",
                "countryCode": "US"
            },
            {
                "name": "Forest Hills",
                "countryCode": "US"
            },
            {
                "name": "Fort Washington",
                "countryCode": "US"
            },
            {
                "name": "Forty Fort",
                "countryCode": "US"
            },
            {
                "name": "Foster Brook",
                "countryCode": "US"
            },
            {
                "name": "Fountain Hill",
                "countryCode": "US"
            },
            {
                "name": "Fox Chapel",
                "countryCode": "US"
            },
            {
                "name": "Fox Chase",
                "countryCode": "US"
            },
            {
                "name": "Fox Run",
                "countryCode": "US"
            },
            {
                "name": "Frackville",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Franklin Park",
                "countryCode": "US"
            },
            {
                "name": "Fredericksburg",
                "countryCode": "US"
            },
            {
                "name": "Freedom",
                "countryCode": "US"
            },
            {
                "name": "Freeland",
                "countryCode": "US"
            },
            {
                "name": "Freemansburg",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Friedens",
                "countryCode": "US"
            },
            {
                "name": "Fullerton",
                "countryCode": "US"
            },
            {
                "name": "Fulton County",
                "countryCode": "US"
            },
            {
                "name": "Galeton",
                "countryCode": "US"
            },
            {
                "name": "Gallitzin",
                "countryCode": "US"
            },
            {
                "name": "Gap",
                "countryCode": "US"
            },
            {
                "name": "Garden View",
                "countryCode": "US"
            },
            {
                "name": "Gastonville",
                "countryCode": "US"
            },
            {
                "name": "Geistown",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Gettysburg",
                "countryCode": "US"
            },
            {
                "name": "Gibsonia",
                "countryCode": "US"
            },
            {
                "name": "Gilbertsville",
                "countryCode": "US"
            },
            {
                "name": "Girard",
                "countryCode": "US"
            },
            {
                "name": "Girardville",
                "countryCode": "US"
            },
            {
                "name": "Glassport",
                "countryCode": "US"
            },
            {
                "name": "Glen Lyon",
                "countryCode": "US"
            },
            {
                "name": "Glen Rock",
                "countryCode": "US"
            },
            {
                "name": "Glenolden",
                "countryCode": "US"
            },
            {
                "name": "Glenshaw",
                "countryCode": "US"
            },
            {
                "name": "Glenside",
                "countryCode": "US"
            },
            {
                "name": "Gold Key Lake",
                "countryCode": "US"
            },
            {
                "name": "Grantley",
                "countryCode": "US"
            },
            {
                "name": "Green Tree",
                "countryCode": "US"
            },
            {
                "name": "Greencastle",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greenfields",
                "countryCode": "US"
            },
            {
                "name": "Greenock",
                "countryCode": "US"
            },
            {
                "name": "Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Grill",
                "countryCode": "US"
            },
            {
                "name": "Grove City",
                "countryCode": "US"
            },
            {
                "name": "Guilford",
                "countryCode": "US"
            },
            {
                "name": "Guilford Siding",
                "countryCode": "US"
            },
            {
                "name": "Halfway House",
                "countryCode": "US"
            },
            {
                "name": "Hallam",
                "countryCode": "US"
            },
            {
                "name": "Hallstead",
                "countryCode": "US"
            },
            {
                "name": "Hamburg",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Harleigh",
                "countryCode": "US"
            },
            {
                "name": "Harleysville",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Harveys Lake",
                "countryCode": "US"
            },
            {
                "name": "Hasson Heights",
                "countryCode": "US"
            },
            {
                "name": "Hastings",
                "countryCode": "US"
            },
            {
                "name": "Hatboro",
                "countryCode": "US"
            },
            {
                "name": "Hatfield",
                "countryCode": "US"
            },
            {
                "name": "Hawley",
                "countryCode": "US"
            },
            {
                "name": "Hazleton",
                "countryCode": "US"
            },
            {
                "name": "Hebron",
                "countryCode": "US"
            },
            {
                "name": "Heidelberg",
                "countryCode": "US"
            },
            {
                "name": "Hellertown",
                "countryCode": "US"
            },
            {
                "name": "Hemlock Farms",
                "countryCode": "US"
            },
            {
                "name": "Hermitage",
                "countryCode": "US"
            },
            {
                "name": "Hershey",
                "countryCode": "US"
            },
            {
                "name": "Highland Park",
                "countryCode": "US"
            },
            {
                "name": "Highspire",
                "countryCode": "US"
            },
            {
                "name": "Hilldale",
                "countryCode": "US"
            },
            {
                "name": "Hiller",
                "countryCode": "US"
            },
            {
                "name": "Hokendauqua",
                "countryCode": "US"
            },
            {
                "name": "Hollidaysburg",
                "countryCode": "US"
            },
            {
                "name": "Homeacre-Lyndora",
                "countryCode": "US"
            },
            {
                "name": "Homer City",
                "countryCode": "US"
            },
            {
                "name": "Homestead",
                "countryCode": "US"
            },
            {
                "name": "Hometown",
                "countryCode": "US"
            },
            {
                "name": "Honesdale",
                "countryCode": "US"
            },
            {
                "name": "Honey Brook",
                "countryCode": "US"
            },
            {
                "name": "Hopwood",
                "countryCode": "US"
            },
            {
                "name": "Horsham",
                "countryCode": "US"
            },
            {
                "name": "Houserville",
                "countryCode": "US"
            },
            {
                "name": "Houston",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hughestown",
                "countryCode": "US"
            },
            {
                "name": "Hughesville",
                "countryCode": "US"
            },
            {
                "name": "Hummels Wharf",
                "countryCode": "US"
            },
            {
                "name": "Hummelstown",
                "countryCode": "US"
            },
            {
                "name": "Huntingdon",
                "countryCode": "US"
            },
            {
                "name": "Huntingdon County",
                "countryCode": "US"
            },
            {
                "name": "Hyde",
                "countryCode": "US"
            },
            {
                "name": "Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "Imperial",
                "countryCode": "US"
            },
            {
                "name": "Indian Mountain Lake",
                "countryCode": "US"
            },
            {
                "name": "Indiana",
                "countryCode": "US"
            },
            {
                "name": "Indiana County",
                "countryCode": "US"
            },
            {
                "name": "Industry",
                "countryCode": "US"
            },
            {
                "name": "Ingram",
                "countryCode": "US"
            },
            {
                "name": "Inkerman",
                "countryCode": "US"
            },
            {
                "name": "Intercourse",
                "countryCode": "US"
            },
            {
                "name": "Irwin",
                "countryCode": "US"
            },
            {
                "name": "Ivyland",
                "countryCode": "US"
            },
            {
                "name": "Jacksonwald",
                "countryCode": "US"
            },
            {
                "name": "Jacobus",
                "countryCode": "US"
            },
            {
                "name": "Jeannette",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson Hills",
                "countryCode": "US"
            },
            {
                "name": "Jenkintown",
                "countryCode": "US"
            },
            {
                "name": "Jermyn",
                "countryCode": "US"
            },
            {
                "name": "Jerome",
                "countryCode": "US"
            },
            {
                "name": "Jersey Shore",
                "countryCode": "US"
            },
            {
                "name": "Jessup",
                "countryCode": "US"
            },
            {
                "name": "Jim Thorpe",
                "countryCode": "US"
            },
            {
                "name": "Johnsonburg",
                "countryCode": "US"
            },
            {
                "name": "Johnstown",
                "countryCode": "US"
            },
            {
                "name": "Jonestown",
                "countryCode": "US"
            },
            {
                "name": "Juniata County",
                "countryCode": "US"
            },
            {
                "name": "Kane",
                "countryCode": "US"
            },
            {
                "name": "Kenhorst",
                "countryCode": "US"
            },
            {
                "name": "Kenilworth",
                "countryCode": "US"
            },
            {
                "name": "Kenmar",
                "countryCode": "US"
            },
            {
                "name": "Kennett Square",
                "countryCode": "US"
            },
            {
                "name": "King of Prussia",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kittanning",
                "countryCode": "US"
            },
            {
                "name": "Knox",
                "countryCode": "US"
            },
            {
                "name": "Kulpmont",
                "countryCode": "US"
            },
            {
                "name": "Kulpsville",
                "countryCode": "US"
            },
            {
                "name": "Kutztown",
                "countryCode": "US"
            },
            {
                "name": "Lackawanna County",
                "countryCode": "US"
            },
            {
                "name": "Lafayette Hill",
                "countryCode": "US"
            },
            {
                "name": "Laflin",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake Heritage",
                "countryCode": "US"
            },
            {
                "name": "Lake Latonka",
                "countryCode": "US"
            },
            {
                "name": "Lake Meade",
                "countryCode": "US"
            },
            {
                "name": "Lake Wynonah",
                "countryCode": "US"
            },
            {
                "name": "Lakemont",
                "countryCode": "US"
            },
            {
                "name": "Lampeter",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lancaster County",
                "countryCode": "US"
            },
            {
                "name": "Landisville",
                "countryCode": "US"
            },
            {
                "name": "Langhorne",
                "countryCode": "US"
            },
            {
                "name": "Langhorne Manor",
                "countryCode": "US"
            },
            {
                "name": "Lansdale",
                "countryCode": "US"
            },
            {
                "name": "Lansdowne",
                "countryCode": "US"
            },
            {
                "name": "Lansford",
                "countryCode": "US"
            },
            {
                "name": "Laporte",
                "countryCode": "US"
            },
            {
                "name": "Larksville",
                "countryCode": "US"
            },
            {
                "name": "Latrobe",
                "countryCode": "US"
            },
            {
                "name": "Laureldale",
                "countryCode": "US"
            },
            {
                "name": "Laurys Station",
                "countryCode": "US"
            },
            {
                "name": "Lawnton",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawrence Park",
                "countryCode": "US"
            },
            {
                "name": "Lawson Heights",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lebanon County",
                "countryCode": "US"
            },
            {
                "name": "Lebanon South",
                "countryCode": "US"
            },
            {
                "name": "Leechburg",
                "countryCode": "US"
            },
            {
                "name": "Leesport",
                "countryCode": "US"
            },
            {
                "name": "Leetsdale",
                "countryCode": "US"
            },
            {
                "name": "Lehigh County",
                "countryCode": "US"
            },
            {
                "name": "Lehighton",
                "countryCode": "US"
            },
            {
                "name": "Leith-Hatfield",
                "countryCode": "US"
            },
            {
                "name": "Lemont",
                "countryCode": "US"
            },
            {
                "name": "Lemoyne",
                "countryCode": "US"
            },
            {
                "name": "Lenape Heights",
                "countryCode": "US"
            },
            {
                "name": "Leola",
                "countryCode": "US"
            },
            {
                "name": "Level Green",
                "countryCode": "US"
            },
            {
                "name": "Levittown",
                "countryCode": "US"
            },
            {
                "name": "Lewisburg",
                "countryCode": "US"
            },
            {
                "name": "Lewistown",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Light Street",
                "countryCode": "US"
            },
            {
                "name": "Ligonier",
                "countryCode": "US"
            },
            {
                "name": "Lima",
                "countryCode": "US"
            },
            {
                "name": "Limerick",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Lincoln Park",
                "countryCode": "US"
            },
            {
                "name": "Linglestown",
                "countryCode": "US"
            },
            {
                "name": "Linntown",
                "countryCode": "US"
            },
            {
                "name": "Linwood",
                "countryCode": "US"
            },
            {
                "name": "Lionville",
                "countryCode": "US"
            },
            {
                "name": "Lititz",
                "countryCode": "US"
            },
            {
                "name": "Littlestown",
                "countryCode": "US"
            },
            {
                "name": "Lock Haven",
                "countryCode": "US"
            },
            {
                "name": "Loganville",
                "countryCode": "US"
            },
            {
                "name": "Lorane",
                "countryCode": "US"
            },
            {
                "name": "Loretto",
                "countryCode": "US"
            },
            {
                "name": "Lower Allen",
                "countryCode": "US"
            },
            {
                "name": "Lower Burrell",
                "countryCode": "US"
            },
            {
                "name": "Loyalhanna",
                "countryCode": "US"
            },
            {
                "name": "Luzerne",
                "countryCode": "US"
            },
            {
                "name": "Luzerne County",
                "countryCode": "US"
            },
            {
                "name": "Lycoming County",
                "countryCode": "US"
            },
            {
                "name": "Lykens",
                "countryCode": "US"
            },
            {
                "name": "Lynnwood-Pricedale",
                "countryCode": "US"
            },
            {
                "name": "Macungie",
                "countryCode": "US"
            },
            {
                "name": "Mahanoy City",
                "countryCode": "US"
            },
            {
                "name": "Malvern",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manheim",
                "countryCode": "US"
            },
            {
                "name": "Manor",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Maple Glen",
                "countryCode": "US"
            },
            {
                "name": "Marcus Hook",
                "countryCode": "US"
            },
            {
                "name": "Marianne",
                "countryCode": "US"
            },
            {
                "name": "Marienville",
                "countryCode": "US"
            },
            {
                "name": "Marietta",
                "countryCode": "US"
            },
            {
                "name": "Mars",
                "countryCode": "US"
            },
            {
                "name": "Marshallton",
                "countryCode": "US"
            },
            {
                "name": "Martinsburg",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "Masontown",
                "countryCode": "US"
            },
            {
                "name": "Matamoras",
                "countryCode": "US"
            },
            {
                "name": "Mayfield",
                "countryCode": "US"
            },
            {
                "name": "Maytown",
                "countryCode": "US"
            },
            {
                "name": "McAdoo",
                "countryCode": "US"
            },
            {
                "name": "McConnellsburg",
                "countryCode": "US"
            },
            {
                "name": "McConnellstown",
                "countryCode": "US"
            },
            {
                "name": "McDonald",
                "countryCode": "US"
            },
            {
                "name": "McGovern",
                "countryCode": "US"
            },
            {
                "name": "McKean County",
                "countryCode": "US"
            },
            {
                "name": "McKees Rocks",
                "countryCode": "US"
            },
            {
                "name": "McKeesport",
                "countryCode": "US"
            },
            {
                "name": "McMurray",
                "countryCode": "US"
            },
            {
                "name": "McSherrystown",
                "countryCode": "US"
            },
            {
                "name": "Meadowood",
                "countryCode": "US"
            },
            {
                "name": "Meadville",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsburg",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsville",
                "countryCode": "US"
            },
            {
                "name": "Media",
                "countryCode": "US"
            },
            {
                "name": "Mercer",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Mercersburg",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Meyersdale",
                "countryCode": "US"
            },
            {
                "name": "Middleburg",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Midland",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Mifflin County",
                "countryCode": "US"
            },
            {
                "name": "Mifflinburg",
                "countryCode": "US"
            },
            {
                "name": "Mifflintown",
                "countryCode": "US"
            },
            {
                "name": "Mifflinville",
                "countryCode": "US"
            },
            {
                "name": "Milesburg",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Mill Hall",
                "countryCode": "US"
            },
            {
                "name": "Millbourne",
                "countryCode": "US"
            },
            {
                "name": "Millersburg",
                "countryCode": "US"
            },
            {
                "name": "Millersville",
                "countryCode": "US"
            },
            {
                "name": "Millvale",
                "countryCode": "US"
            },
            {
                "name": "Milroy",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Minersville",
                "countryCode": "US"
            },
            {
                "name": "Mohnton",
                "countryCode": "US"
            },
            {
                "name": "Monaca",
                "countryCode": "US"
            },
            {
                "name": "Monessen",
                "countryCode": "US"
            },
            {
                "name": "Monongahela",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monroeville",
                "countryCode": "US"
            },
            {
                "name": "Mont Alto",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Montgomeryville",
                "countryCode": "US"
            },
            {
                "name": "Montour County",
                "countryCode": "US"
            },
            {
                "name": "Montoursville",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Moosic",
                "countryCode": "US"
            },
            {
                "name": "Morningside",
                "countryCode": "US"
            },
            {
                "name": "Morrisville",
                "countryCode": "US"
            },
            {
                "name": "Morton",
                "countryCode": "US"
            },
            {
                "name": "Moscow",
                "countryCode": "US"
            },
            {
                "name": "Mount Carmel",
                "countryCode": "US"
            },
            {
                "name": "Mount Cobb",
                "countryCode": "US"
            },
            {
                "name": "Mount Holly Springs",
                "countryCode": "US"
            },
            {
                "name": "Mount Joy",
                "countryCode": "US"
            },
            {
                "name": "Mount Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Mount Oliver",
                "countryCode": "US"
            },
            {
                "name": "Mount Penn",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mount Pocono",
                "countryCode": "US"
            },
            {
                "name": "Mount Union",
                "countryCode": "US"
            },
            {
                "name": "Mount Wolf",
                "countryCode": "US"
            },
            {
                "name": "Mountain Top",
                "countryCode": "US"
            },
            {
                "name": "Mountainhome",
                "countryCode": "US"
            },
            {
                "name": "Mountville",
                "countryCode": "US"
            },
            {
                "name": "Muhlenberg Park",
                "countryCode": "US"
            },
            {
                "name": "Muncy",
                "countryCode": "US"
            },
            {
                "name": "Mundys Corner",
                "countryCode": "US"
            },
            {
                "name": "Munhall",
                "countryCode": "US"
            },
            {
                "name": "Murrysville",
                "countryCode": "US"
            },
            {
                "name": "Muse",
                "countryCode": "US"
            },
            {
                "name": "Myerstown",
                "countryCode": "US"
            },
            {
                "name": "Nanticoke",
                "countryCode": "US"
            },
            {
                "name": "Nanty Glo",
                "countryCode": "US"
            },
            {
                "name": "Narberth",
                "countryCode": "US"
            },
            {
                "name": "Nazareth",
                "countryCode": "US"
            },
            {
                "name": "Nescopeck",
                "countryCode": "US"
            },
            {
                "name": "Nesquehoning",
                "countryCode": "US"
            },
            {
                "name": "New Beaver",
                "countryCode": "US"
            },
            {
                "name": "New Berlinville",
                "countryCode": "US"
            },
            {
                "name": "New Bloomfield",
                "countryCode": "US"
            },
            {
                "name": "New Brighton",
                "countryCode": "US"
            },
            {
                "name": "New Britain",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "New Castle Northwest",
                "countryCode": "US"
            },
            {
                "name": "New Columbia",
                "countryCode": "US"
            },
            {
                "name": "New Cumberland",
                "countryCode": "US"
            },
            {
                "name": "New Eagle",
                "countryCode": "US"
            },
            {
                "name": "New Freedom",
                "countryCode": "US"
            },
            {
                "name": "New Holland",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "New Kensington",
                "countryCode": "US"
            },
            {
                "name": "New Oxford",
                "countryCode": "US"
            },
            {
                "name": "New Philadelphia",
                "countryCode": "US"
            },
            {
                "name": "New Stanton",
                "countryCode": "US"
            },
            {
                "name": "New Wilmington",
                "countryCode": "US"
            },
            {
                "name": "Newmanstown",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newtown",
                "countryCode": "US"
            },
            {
                "name": "Newtown Grant",
                "countryCode": "US"
            },
            {
                "name": "Newville",
                "countryCode": "US"
            },
            {
                "name": "Nixon",
                "countryCode": "US"
            },
            {
                "name": "Norristown",
                "countryCode": "US"
            },
            {
                "name": "North Apollo",
                "countryCode": "US"
            },
            {
                "name": "North Belle Vernon",
                "countryCode": "US"
            },
            {
                "name": "North Braddock",
                "countryCode": "US"
            },
            {
                "name": "North Catasauqua",
                "countryCode": "US"
            },
            {
                "name": "North Charleroi",
                "countryCode": "US"
            },
            {
                "name": "North East",
                "countryCode": "US"
            },
            {
                "name": "North Versailles",
                "countryCode": "US"
            },
            {
                "name": "North Wales",
                "countryCode": "US"
            },
            {
                "name": "North Warren",
                "countryCode": "US"
            },
            {
                "name": "North York",
                "countryCode": "US"
            },
            {
                "name": "Northampton",
                "countryCode": "US"
            },
            {
                "name": "Northampton County",
                "countryCode": "US"
            },
            {
                "name": "Northern Cambria",
                "countryCode": "US"
            },
            {
                "name": "Northumberland",
                "countryCode": "US"
            },
            {
                "name": "Northumberland County",
                "countryCode": "US"
            },
            {
                "name": "Northwest Harborcreek",
                "countryCode": "US"
            },
            {
                "name": "Norwood",
                "countryCode": "US"
            },
            {
                "name": "Oak Hills",
                "countryCode": "US"
            },
            {
                "name": "Oakdale",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oakmont",
                "countryCode": "US"
            },
            {
                "name": "Oakwood",
                "countryCode": "US"
            },
            {
                "name": "Ohioville",
                "countryCode": "US"
            },
            {
                "name": "Oil City",
                "countryCode": "US"
            },
            {
                "name": "Old Forge",
                "countryCode": "US"
            },
            {
                "name": "Old Orchard",
                "countryCode": "US"
            },
            {
                "name": "Oley",
                "countryCode": "US"
            },
            {
                "name": "Oliver",
                "countryCode": "US"
            },
            {
                "name": "Olyphant",
                "countryCode": "US"
            },
            {
                "name": "Orchard Hills",
                "countryCode": "US"
            },
            {
                "name": "Oreland",
                "countryCode": "US"
            },
            {
                "name": "Orwigsburg",
                "countryCode": "US"
            },
            {
                "name": "Osceola Mills",
                "countryCode": "US"
            },
            {
                "name": "Oxford",
                "countryCode": "US"
            },
            {
                "name": "Palmdale",
                "countryCode": "US"
            },
            {
                "name": "Palmer Heights",
                "countryCode": "US"
            },
            {
                "name": "Palmerton",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Palo Alto",
                "countryCode": "US"
            },
            {
                "name": "Paoli",
                "countryCode": "US"
            },
            {
                "name": "Paradise",
                "countryCode": "US"
            },
            {
                "name": "Park Forest Village",
                "countryCode": "US"
            },
            {
                "name": "Parkesburg",
                "countryCode": "US"
            },
            {
                "name": "Parkside",
                "countryCode": "US"
            },
            {
                "name": "Parkville",
                "countryCode": "US"
            },
            {
                "name": "Patton",
                "countryCode": "US"
            },
            {
                "name": "Paxtang",
                "countryCode": "US"
            },
            {
                "name": "Paxtonia",
                "countryCode": "US"
            },
            {
                "name": "Pen Argyl",
                "countryCode": "US"
            },
            {
                "name": "Penbrook",
                "countryCode": "US"
            },
            {
                "name": "Penn Estates",
                "countryCode": "US"
            },
            {
                "name": "Penn Hills",
                "countryCode": "US"
            },
            {
                "name": "Penn Wynne",
                "countryCode": "US"
            },
            {
                "name": "Penndel",
                "countryCode": "US"
            },
            {
                "name": "Pennsburg",
                "countryCode": "US"
            },
            {
                "name": "Pennside",
                "countryCode": "US"
            },
            {
                "name": "Pennsport",
                "countryCode": "US"
            },
            {
                "name": "Pennville",
                "countryCode": "US"
            },
            {
                "name": "Penryn",
                "countryCode": "US"
            },
            {
                "name": "Perkasie",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Perryopolis",
                "countryCode": "US"
            },
            {
                "name": "Philadelphia",
                "countryCode": "US"
            },
            {
                "name": "Philadelphia County",
                "countryCode": "US"
            },
            {
                "name": "Philipsburg",
                "countryCode": "US"
            },
            {
                "name": "Phoenixville",
                "countryCode": "US"
            },
            {
                "name": "Pike County",
                "countryCode": "US"
            },
            {
                "name": "Pine Grove",
                "countryCode": "US"
            },
            {
                "name": "Pine Grove Mills",
                "countryCode": "US"
            },
            {
                "name": "Pine Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pitcairn",
                "countryCode": "US"
            },
            {
                "name": "Pittsburgh",
                "countryCode": "US"
            },
            {
                "name": "Pittston",
                "countryCode": "US"
            },
            {
                "name": "Plains",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Gap",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hill",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Hills",
                "countryCode": "US"
            },
            {
                "name": "Plum",
                "countryCode": "US"
            },
            {
                "name": "Plumsteadville",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Plymouth Meeting",
                "countryCode": "US"
            },
            {
                "name": "Pocono Pines",
                "countryCode": "US"
            },
            {
                "name": "Pocono Ranch Lands",
                "countryCode": "US"
            },
            {
                "name": "Point Marion",
                "countryCode": "US"
            },
            {
                "name": "Port Allegany",
                "countryCode": "US"
            },
            {
                "name": "Port Carbon",
                "countryCode": "US"
            },
            {
                "name": "Port Vue",
                "countryCode": "US"
            },
            {
                "name": "Portage",
                "countryCode": "US"
            },
            {
                "name": "Potter County",
                "countryCode": "US"
            },
            {
                "name": "Pottsgrove",
                "countryCode": "US"
            },
            {
                "name": "Pottstown",
                "countryCode": "US"
            },
            {
                "name": "Pottsville",
                "countryCode": "US"
            },
            {
                "name": "Progress",
                "countryCode": "US"
            },
            {
                "name": "Prospect",
                "countryCode": "US"
            },
            {
                "name": "Prospect Park",
                "countryCode": "US"
            },
            {
                "name": "Punxsutawney",
                "countryCode": "US"
            },
            {
                "name": "Pymatuning Central",
                "countryCode": "US"
            },
            {
                "name": "Quakertown",
                "countryCode": "US"
            },
            {
                "name": "Quarryville",
                "countryCode": "US"
            },
            {
                "name": "Radnor",
                "countryCode": "US"
            },
            {
                "name": "Rankin",
                "countryCode": "US"
            },
            {
                "name": "Raubsville",
                "countryCode": "US"
            },
            {
                "name": "Reading",
                "countryCode": "US"
            },
            {
                "name": "Reamstown",
                "countryCode": "US"
            },
            {
                "name": "Red Hill",
                "countryCode": "US"
            },
            {
                "name": "Red Lion",
                "countryCode": "US"
            },
            {
                "name": "Reiffton",
                "countryCode": "US"
            },
            {
                "name": "Reinholds",
                "countryCode": "US"
            },
            {
                "name": "Rennerdale",
                "countryCode": "US"
            },
            {
                "name": "Renovo",
                "countryCode": "US"
            },
            {
                "name": "Republic",
                "countryCode": "US"
            },
            {
                "name": "Reynolds Heights",
                "countryCode": "US"
            },
            {
                "name": "Reynoldsville",
                "countryCode": "US"
            },
            {
                "name": "Rheems",
                "countryCode": "US"
            },
            {
                "name": "Richboro",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Richlandtown",
                "countryCode": "US"
            },
            {
                "name": "Ridgway",
                "countryCode": "US"
            },
            {
                "name": "Ridley Park",
                "countryCode": "US"
            },
            {
                "name": "River View Park",
                "countryCode": "US"
            },
            {
                "name": "Riverside",
                "countryCode": "US"
            },
            {
                "name": "Roaring Spring",
                "countryCode": "US"
            },
            {
                "name": "Robesonia",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rockledge",
                "countryCode": "US"
            },
            {
                "name": "Roseto",
                "countryCode": "US"
            },
            {
                "name": "Rothsville",
                "countryCode": "US"
            },
            {
                "name": "Royalton",
                "countryCode": "US"
            },
            {
                "name": "Royersford",
                "countryCode": "US"
            },
            {
                "name": "Russell",
                "countryCode": "US"
            },
            {
                "name": "Russellton",
                "countryCode": "US"
            },
            {
                "name": "Rutherford",
                "countryCode": "US"
            },
            {
                "name": "Saint Clair",
                "countryCode": "US"
            },
            {
                "name": "Saint Lawrence",
                "countryCode": "US"
            },
            {
                "name": "Saint Marys",
                "countryCode": "US"
            },
            {
                "name": "Salix",
                "countryCode": "US"
            },
            {
                "name": "Salunga",
                "countryCode": "US"
            },
            {
                "name": "Sanatoga",
                "countryCode": "US"
            },
            {
                "name": "Sand Hill",
                "countryCode": "US"
            },
            {
                "name": "Sandy",
                "countryCode": "US"
            },
            {
                "name": "Saw Creek",
                "countryCode": "US"
            },
            {
                "name": "Saxonburg",
                "countryCode": "US"
            },
            {
                "name": "Saylorsburg",
                "countryCode": "US"
            },
            {
                "name": "Sayre",
                "countryCode": "US"
            },
            {
                "name": "Schlusser",
                "countryCode": "US"
            },
            {
                "name": "Schnecksville",
                "countryCode": "US"
            },
            {
                "name": "Schoeneck",
                "countryCode": "US"
            },
            {
                "name": "Schuylkill County",
                "countryCode": "US"
            },
            {
                "name": "Schuylkill Haven",
                "countryCode": "US"
            },
            {
                "name": "Schwenksville",
                "countryCode": "US"
            },
            {
                "name": "Scotland",
                "countryCode": "US"
            },
            {
                "name": "Scottdale",
                "countryCode": "US"
            },
            {
                "name": "Scranton",
                "countryCode": "US"
            },
            {
                "name": "Selinsgrove",
                "countryCode": "US"
            },
            {
                "name": "Sellersville",
                "countryCode": "US"
            },
            {
                "name": "Seneca",
                "countryCode": "US"
            },
            {
                "name": "Seven Fields",
                "countryCode": "US"
            },
            {
                "name": "Sewickley",
                "countryCode": "US"
            },
            {
                "name": "Shamokin",
                "countryCode": "US"
            },
            {
                "name": "Shamokin Dam",
                "countryCode": "US"
            },
            {
                "name": "Shanor-Northvue",
                "countryCode": "US"
            },
            {
                "name": "Sharon",
                "countryCode": "US"
            },
            {
                "name": "Sharon Hill",
                "countryCode": "US"
            },
            {
                "name": "Sharpsburg",
                "countryCode": "US"
            },
            {
                "name": "Sharpsville",
                "countryCode": "US"
            },
            {
                "name": "Shavertown",
                "countryCode": "US"
            },
            {
                "name": "Sheffield",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah Heights",
                "countryCode": "US"
            },
            {
                "name": "Shillington",
                "countryCode": "US"
            },
            {
                "name": "Shiloh",
                "countryCode": "US"
            },
            {
                "name": "Shinglehouse",
                "countryCode": "US"
            },
            {
                "name": "Shippensburg",
                "countryCode": "US"
            },
            {
                "name": "Shiremanstown",
                "countryCode": "US"
            },
            {
                "name": "Shoemakersville",
                "countryCode": "US"
            },
            {
                "name": "Shrewsbury",
                "countryCode": "US"
            },
            {
                "name": "Sierra View",
                "countryCode": "US"
            },
            {
                "name": "Simpson",
                "countryCode": "US"
            },
            {
                "name": "Sinking Spring",
                "countryCode": "US"
            },
            {
                "name": "Skippack",
                "countryCode": "US"
            },
            {
                "name": "Skyline View",
                "countryCode": "US"
            },
            {
                "name": "Slatington",
                "countryCode": "US"
            },
            {
                "name": "Slippery Rock",
                "countryCode": "US"
            },
            {
                "name": "Smethport",
                "countryCode": "US"
            },
            {
                "name": "Snyder County",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "Somerset County",
                "countryCode": "US"
            },
            {
                "name": "Souderton",
                "countryCode": "US"
            },
            {
                "name": "South Coatesville",
                "countryCode": "US"
            },
            {
                "name": "South Connellsville",
                "countryCode": "US"
            },
            {
                "name": "South Greensburg",
                "countryCode": "US"
            },
            {
                "name": "South Park Township",
                "countryCode": "US"
            },
            {
                "name": "South Pottstown",
                "countryCode": "US"
            },
            {
                "name": "South Temple",
                "countryCode": "US"
            },
            {
                "name": "South Uniontown",
                "countryCode": "US"
            },
            {
                "name": "South Waverly",
                "countryCode": "US"
            },
            {
                "name": "South Williamsport",
                "countryCode": "US"
            },
            {
                "name": "Southmont",
                "countryCode": "US"
            },
            {
                "name": "Southwest Greensburg",
                "countryCode": "US"
            },
            {
                "name": "Spangler",
                "countryCode": "US"
            },
            {
                "name": "Speers",
                "countryCode": "US"
            },
            {
                "name": "Spinnerstown",
                "countryCode": "US"
            },
            {
                "name": "Spring City",
                "countryCode": "US"
            },
            {
                "name": "Spring Grove",
                "countryCode": "US"
            },
            {
                "name": "Spring House",
                "countryCode": "US"
            },
            {
                "name": "Spring Mount",
                "countryCode": "US"
            },
            {
                "name": "Spring Ridge",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Spry",
                "countryCode": "US"
            },
            {
                "name": "State College",
                "countryCode": "US"
            },
            {
                "name": "State Line",
                "countryCode": "US"
            },
            {
                "name": "Steelton",
                "countryCode": "US"
            },
            {
                "name": "Stewartstown",
                "countryCode": "US"
            },
            {
                "name": "Stiles",
                "countryCode": "US"
            },
            {
                "name": "Stoneboro",
                "countryCode": "US"
            },
            {
                "name": "Stony Creek Mills",
                "countryCode": "US"
            },
            {
                "name": "Stonybrook",
                "countryCode": "US"
            },
            {
                "name": "Stormstown",
                "countryCode": "US"
            },
            {
                "name": "Stowe",
                "countryCode": "US"
            },
            {
                "name": "Strasburg",
                "countryCode": "US"
            },
            {
                "name": "Stroudsburg",
                "countryCode": "US"
            },
            {
                "name": "Sturgeon",
                "countryCode": "US"
            },
            {
                "name": "Sugarcreek",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Summit Hill",
                "countryCode": "US"
            },
            {
                "name": "Sun Valley",
                "countryCode": "US"
            },
            {
                "name": "Sunbury",
                "countryCode": "US"
            },
            {
                "name": "Sunrise Lake",
                "countryCode": "US"
            },
            {
                "name": "Susquehanna",
                "countryCode": "US"
            },
            {
                "name": "Susquehanna County",
                "countryCode": "US"
            },
            {
                "name": "Susquehanna Trails",
                "countryCode": "US"
            },
            {
                "name": "Swarthmore",
                "countryCode": "US"
            },
            {
                "name": "Swartzville",
                "countryCode": "US"
            },
            {
                "name": "Swissvale",
                "countryCode": "US"
            },
            {
                "name": "Swoyersville",
                "countryCode": "US"
            },
            {
                "name": "Sykesville",
                "countryCode": "US"
            },
            {
                "name": "Tacony",
                "countryCode": "US"
            },
            {
                "name": "Tamaqua",
                "countryCode": "US"
            },
            {
                "name": "Tannersville",
                "countryCode": "US"
            },
            {
                "name": "Tarentum",
                "countryCode": "US"
            },
            {
                "name": "Tatamy",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Telford",
                "countryCode": "US"
            },
            {
                "name": "Temple",
                "countryCode": "US"
            },
            {
                "name": "Terre Hill",
                "countryCode": "US"
            },
            {
                "name": "The Hideout",
                "countryCode": "US"
            },
            {
                "name": "Thompsonville",
                "countryCode": "US"
            },
            {
                "name": "Thorndale",
                "countryCode": "US"
            },
            {
                "name": "Throop",
                "countryCode": "US"
            },
            {
                "name": "Tinicum",
                "countryCode": "US"
            },
            {
                "name": "Tioga County",
                "countryCode": "US"
            },
            {
                "name": "Tionesta",
                "countryCode": "US"
            },
            {
                "name": "Tipton",
                "countryCode": "US"
            },
            {
                "name": "Titusville",
                "countryCode": "US"
            },
            {
                "name": "Toftrees",
                "countryCode": "US"
            },
            {
                "name": "Topton",
                "countryCode": "US"
            },
            {
                "name": "Toughkenamon",
                "countryCode": "US"
            },
            {
                "name": "Towamensing Trails",
                "countryCode": "US"
            },
            {
                "name": "Towanda",
                "countryCode": "US"
            },
            {
                "name": "Tower City",
                "countryCode": "US"
            },
            {
                "name": "Trafford",
                "countryCode": "US"
            },
            {
                "name": "Trainer",
                "countryCode": "US"
            },
            {
                "name": "Trappe",
                "countryCode": "US"
            },
            {
                "name": "Treasure Lake",
                "countryCode": "US"
            },
            {
                "name": "Tremont",
                "countryCode": "US"
            },
            {
                "name": "Trevorton",
                "countryCode": "US"
            },
            {
                "name": "Trevose",
                "countryCode": "US"
            },
            {
                "name": "Trexlertown",
                "countryCode": "US"
            },
            {
                "name": "Trooper",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Trucksville",
                "countryCode": "US"
            },
            {
                "name": "Tullytown",
                "countryCode": "US"
            },
            {
                "name": "Tunkhannock",
                "countryCode": "US"
            },
            {
                "name": "Turtle Creek",
                "countryCode": "US"
            },
            {
                "name": "Tyrone",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Uniontown",
                "countryCode": "US"
            },
            {
                "name": "Upland",
                "countryCode": "US"
            },
            {
                "name": "Upper Saint Clair",
                "countryCode": "US"
            },
            {
                "name": "Valley Green",
                "countryCode": "US"
            },
            {
                "name": "Valley View",
                "countryCode": "US"
            },
            {
                "name": "Vandergrift",
                "countryCode": "US"
            },
            {
                "name": "Venango County",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Versailles",
                "countryCode": "US"
            },
            {
                "name": "Village Green-Green Ridge",
                "countryCode": "US"
            },
            {
                "name": "Village Shires",
                "countryCode": "US"
            },
            {
                "name": "Vinco",
                "countryCode": "US"
            },
            {
                "name": "Wallenpaupack Lake Estates",
                "countryCode": "US"
            },
            {
                "name": "Walnutport",
                "countryCode": "US"
            },
            {
                "name": "Warminster Heights",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Watsontown",
                "countryCode": "US"
            },
            {
                "name": "Waymart",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Wayne Heights",
                "countryCode": "US"
            },
            {
                "name": "Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "Waynesburg",
                "countryCode": "US"
            },
            {
                "name": "Weatherly",
                "countryCode": "US"
            },
            {
                "name": "Weigelstown",
                "countryCode": "US"
            },
            {
                "name": "Weissport East",
                "countryCode": "US"
            },
            {
                "name": "Wellsboro",
                "countryCode": "US"
            },
            {
                "name": "Wernersville",
                "countryCode": "US"
            },
            {
                "name": "Wescosville",
                "countryCode": "US"
            },
            {
                "name": "Wesleyville",
                "countryCode": "US"
            },
            {
                "name": "West Chester",
                "countryCode": "US"
            },
            {
                "name": "West Conshohocken",
                "countryCode": "US"
            },
            {
                "name": "West Easton",
                "countryCode": "US"
            },
            {
                "name": "West Fairview",
                "countryCode": "US"
            },
            {
                "name": "West Grove",
                "countryCode": "US"
            },
            {
                "name": "West Hamburg",
                "countryCode": "US"
            },
            {
                "name": "West Hazleton",
                "countryCode": "US"
            },
            {
                "name": "West Hills",
                "countryCode": "US"
            },
            {
                "name": "West Homestead",
                "countryCode": "US"
            },
            {
                "name": "West Kittanning",
                "countryCode": "US"
            },
            {
                "name": "West Lawn",
                "countryCode": "US"
            },
            {
                "name": "West Leechburg",
                "countryCode": "US"
            },
            {
                "name": "West Mayfield",
                "countryCode": "US"
            },
            {
                "name": "West Mifflin",
                "countryCode": "US"
            },
            {
                "name": "West Newton",
                "countryCode": "US"
            },
            {
                "name": "West Norriton",
                "countryCode": "US"
            },
            {
                "name": "West Pittston",
                "countryCode": "US"
            },
            {
                "name": "West Reading",
                "countryCode": "US"
            },
            {
                "name": "West View",
                "countryCode": "US"
            },
            {
                "name": "West Wyoming",
                "countryCode": "US"
            },
            {
                "name": "West Wyomissing",
                "countryCode": "US"
            },
            {
                "name": "West York",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Westmont",
                "countryCode": "US"
            },
            {
                "name": "Westmoreland County",
                "countryCode": "US"
            },
            {
                "name": "Wharton",
                "countryCode": "US"
            },
            {
                "name": "Whitaker",
                "countryCode": "US"
            },
            {
                "name": "White Haven",
                "countryCode": "US"
            },
            {
                "name": "White Oak",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Whitehall Township",
                "countryCode": "US"
            },
            {
                "name": "Whitfield",
                "countryCode": "US"
            },
            {
                "name": "Whitman",
                "countryCode": "US"
            },
            {
                "name": "Wickerham Manor-Fisher",
                "countryCode": "US"
            },
            {
                "name": "Wilkes-Barre",
                "countryCode": "US"
            },
            {
                "name": "Wilkinsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Williamsport",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Willow Grove",
                "countryCode": "US"
            },
            {
                "name": "Willow Street",
                "countryCode": "US"
            },
            {
                "name": "Wilmerding",
                "countryCode": "US"
            },
            {
                "name": "Wilson",
                "countryCode": "US"
            },
            {
                "name": "Wind Gap",
                "countryCode": "US"
            },
            {
                "name": "Windber",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Wolfdale",
                "countryCode": "US"
            },
            {
                "name": "Womelsdorf",
                "countryCode": "US"
            },
            {
                "name": "Woodbourne",
                "countryCode": "US"
            },
            {
                "name": "Woodland Heights",
                "countryCode": "US"
            },
            {
                "name": "Woodlyn",
                "countryCode": "US"
            },
            {
                "name": "Woodside",
                "countryCode": "US"
            },
            {
                "name": "Wormleysburg",
                "countryCode": "US"
            },
            {
                "name": "Woxall",
                "countryCode": "US"
            },
            {
                "name": "Wrightsville",
                "countryCode": "US"
            },
            {
                "name": "Wyncote",
                "countryCode": "US"
            },
            {
                "name": "Wyndmoor",
                "countryCode": "US"
            },
            {
                "name": "Wyoming",
                "countryCode": "US"
            },
            {
                "name": "Wyoming County",
                "countryCode": "US"
            },
            {
                "name": "Wyomissing",
                "countryCode": "US"
            },
            {
                "name": "Wyomissing Hills",
                "countryCode": "US"
            },
            {
                "name": "Yardley",
                "countryCode": "US"
            },
            {
                "name": "Yeadon",
                "countryCode": "US"
            },
            {
                "name": "Yeagertown",
                "countryCode": "US"
            },
            {
                "name": "Yoe",
                "countryCode": "US"
            },
            {
                "name": "York",
                "countryCode": "US"
            },
            {
                "name": "York County",
                "countryCode": "US"
            },
            {
                "name": "Yorklyn",
                "countryCode": "US"
            },
            {
                "name": "Youngsville",
                "countryCode": "US"
            },
            {
                "name": "Youngwood",
                "countryCode": "US"
            },
            {
                "name": "Zelienople",
                "countryCode": "US"
            },
            {
                "name": "Zion",
                "countryCode": "US"
            },
            {
                "name": "Adjuntas",
                "countryCode": "US"
            },
            {
                "name": "Aguada",
                "countryCode": "US"
            },
            {
                "name": "Aguadilla",
                "countryCode": "US"
            },
            {
                "name": "Aguas Buenas",
                "countryCode": "US"
            },
            {
                "name": "Aguas Claras",
                "countryCode": "US"
            },
            {
                "name": "Aguilita",
                "countryCode": "US"
            },
            {
                "name": "Aibonito",
                "countryCode": "US"
            },
            {
                "name": "Animas",
                "countryCode": "US"
            },
            {
                "name": "Antón Ruiz",
                "countryCode": "US"
            },
            {
                "name": "Arecibo",
                "countryCode": "US"
            },
            {
                "name": "Arroyo",
                "countryCode": "US"
            },
            {
                "name": "Añasco",
                "countryCode": "US"
            },
            {
                "name": "Bairoa",
                "countryCode": "US"
            },
            {
                "name": "Bajadero",
                "countryCode": "US"
            },
            {
                "name": "Bajandas",
                "countryCode": "US"
            },
            {
                "name": "Barahona",
                "countryCode": "US"
            },
            {
                "name": "Barceloneta",
                "countryCode": "US"
            },
            {
                "name": "Barranquitas",
                "countryCode": "US"
            },
            {
                "name": "Bartolo",
                "countryCode": "US"
            },
            {
                "name": "Bayamon",
                "countryCode": "US"
            },
            {
                "name": "Benitez",
                "countryCode": "US"
            },
            {
                "name": "Betances",
                "countryCode": "US"
            },
            {
                "name": "Boqueron",
                "countryCode": "US"
            },
            {
                "name": "Boquerón, Cabo Rojo",
                "countryCode": "US"
            },
            {
                "name": "Brenas",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Bufalo",
                "countryCode": "US"
            },
            {
                "name": "Caban",
                "countryCode": "US"
            },
            {
                "name": "Cabo Rojo",
                "countryCode": "US"
            },
            {
                "name": "Cabo Rojo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Cacao",
                "countryCode": "US"
            },
            {
                "name": "Caguas",
                "countryCode": "US"
            },
            {
                "name": "Campanilla",
                "countryCode": "US"
            },
            {
                "name": "Campo Rico",
                "countryCode": "US"
            },
            {
                "name": "Camuy",
                "countryCode": "US"
            },
            {
                "name": "Candelaria",
                "countryCode": "US"
            },
            {
                "name": "Candelaria Arenas",
                "countryCode": "US"
            },
            {
                "name": "Candelero Arriba",
                "countryCode": "US"
            },
            {
                "name": "Canóvanas",
                "countryCode": "US"
            },
            {
                "name": "Capitanejo",
                "countryCode": "US"
            },
            {
                "name": "Carolina",
                "countryCode": "US"
            },
            {
                "name": "Carrizales",
                "countryCode": "US"
            },
            {
                "name": "Cataño",
                "countryCode": "US"
            },
            {
                "name": "Cayey",
                "countryCode": "US"
            },
            {
                "name": "Cayuco",
                "countryCode": "US"
            },
            {
                "name": "Ceiba",
                "countryCode": "US"
            },
            {
                "name": "Ceiba Municipio",
                "countryCode": "US"
            },
            {
                "name": "Celada",
                "countryCode": "US"
            },
            {
                "name": "Central Aguirre",
                "countryCode": "US"
            },
            {
                "name": "Ciales",
                "countryCode": "US"
            },
            {
                "name": "Ciales Municipio",
                "countryCode": "US"
            },
            {
                "name": "Cidra",
                "countryCode": "US"
            },
            {
                "name": "Coamo",
                "countryCode": "US"
            },
            {
                "name": "Coco",
                "countryCode": "US"
            },
            {
                "name": "Comerío",
                "countryCode": "US"
            },
            {
                "name": "Comerío Municipio",
                "countryCode": "US"
            },
            {
                "name": "Comunas",
                "countryCode": "US"
            },
            {
                "name": "Coquí",
                "countryCode": "US"
            },
            {
                "name": "Corazón",
                "countryCode": "US"
            },
            {
                "name": "Corcovado",
                "countryCode": "US"
            },
            {
                "name": "Corozal",
                "countryCode": "US"
            },
            {
                "name": "Corozal Municipio",
                "countryCode": "US"
            },
            {
                "name": "Coto Laurel",
                "countryCode": "US"
            },
            {
                "name": "Coto Norte",
                "countryCode": "US"
            },
            {
                "name": "Culebra",
                "countryCode": "US"
            },
            {
                "name": "Culebra barrio-pueblo",
                "countryCode": "US"
            },
            {
                "name": "Daguao",
                "countryCode": "US"
            },
            {
                "name": "Dorado",
                "countryCode": "US"
            },
            {
                "name": "Dorado Municipio",
                "countryCode": "US"
            },
            {
                "name": "El Mangó",
                "countryCode": "US"
            },
            {
                "name": "El Negro",
                "countryCode": "US"
            },
            {
                "name": "El Ojo",
                "countryCode": "US"
            },
            {
                "name": "Emajagua",
                "countryCode": "US"
            },
            {
                "name": "Esperanza",
                "countryCode": "US"
            },
            {
                "name": "Espino",
                "countryCode": "US"
            },
            {
                "name": "Estancias de Florida",
                "countryCode": "US"
            },
            {
                "name": "Fajardo",
                "countryCode": "US"
            },
            {
                "name": "Fajardo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Florida",
                "countryCode": "US"
            },
            {
                "name": "Franquez",
                "countryCode": "US"
            },
            {
                "name": "Fuig",
                "countryCode": "US"
            },
            {
                "name": "G. L. Garcia",
                "countryCode": "US"
            },
            {
                "name": "Galateo",
                "countryCode": "US"
            },
            {
                "name": "Garrochales",
                "countryCode": "US"
            },
            {
                "name": "Guayabal",
                "countryCode": "US"
            },
            {
                "name": "Guayama",
                "countryCode": "US"
            },
            {
                "name": "Guayama Municipio",
                "countryCode": "US"
            },
            {
                "name": "Guayanilla",
                "countryCode": "US"
            },
            {
                "name": "Guaynabo",
                "countryCode": "US"
            },
            {
                "name": "Gurabo",
                "countryCode": "US"
            },
            {
                "name": "Gurabo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Guánica",
                "countryCode": "US"
            },
            {
                "name": "Guánica Municipio",
                "countryCode": "US"
            },
            {
                "name": "H. Rivera Colon",
                "countryCode": "US"
            },
            {
                "name": "Hatillo",
                "countryCode": "US"
            },
            {
                "name": "Hatillo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Hato Arriba",
                "countryCode": "US"
            },
            {
                "name": "Hato Candal",
                "countryCode": "US"
            },
            {
                "name": "Hormigueros",
                "countryCode": "US"
            },
            {
                "name": "Hormigueros Municipio",
                "countryCode": "US"
            },
            {
                "name": "Humacao",
                "countryCode": "US"
            },
            {
                "name": "Imbery",
                "countryCode": "US"
            },
            {
                "name": "Indios",
                "countryCode": "US"
            },
            {
                "name": "Ingenio",
                "countryCode": "US"
            },
            {
                "name": "Isabel Segunda",
                "countryCode": "US"
            },
            {
                "name": "Isabela",
                "countryCode": "US"
            },
            {
                "name": "Jagual",
                "countryCode": "US"
            },
            {
                "name": "Jauca",
                "countryCode": "US"
            },
            {
                "name": "Jayuya",
                "countryCode": "US"
            },
            {
                "name": "Jobos",
                "countryCode": "US"
            },
            {
                "name": "Juana Díaz",
                "countryCode": "US"
            },
            {
                "name": "Juana Díaz Municipio",
                "countryCode": "US"
            },
            {
                "name": "Juncal",
                "countryCode": "US"
            },
            {
                "name": "Juncos",
                "countryCode": "US"
            },
            {
                "name": "La Alianza",
                "countryCode": "US"
            },
            {
                "name": "La Dolores",
                "countryCode": "US"
            },
            {
                "name": "La Fermina",
                "countryCode": "US"
            },
            {
                "name": "La Luisa",
                "countryCode": "US"
            },
            {
                "name": "La Parguera",
                "countryCode": "US"
            },
            {
                "name": "La Playa",
                "countryCode": "US"
            },
            {
                "name": "La Plena",
                "countryCode": "US"
            },
            {
                "name": "Lajas",
                "countryCode": "US"
            },
            {
                "name": "Lajas Municipio",
                "countryCode": "US"
            },
            {
                "name": "Lamboglia",
                "countryCode": "US"
            },
            {
                "name": "Lares",
                "countryCode": "US"
            },
            {
                "name": "Las Marias",
                "countryCode": "US"
            },
            {
                "name": "Las Marías Municipio",
                "countryCode": "US"
            },
            {
                "name": "Las Ochenta",
                "countryCode": "US"
            },
            {
                "name": "Las Ollas",
                "countryCode": "US"
            },
            {
                "name": "Las Piedras",
                "countryCode": "US"
            },
            {
                "name": "Las Piedras Municipio",
                "countryCode": "US"
            },
            {
                "name": "Levittown",
                "countryCode": "US"
            },
            {
                "name": "Liborio Negron Torres",
                "countryCode": "US"
            },
            {
                "name": "Lluveras",
                "countryCode": "US"
            },
            {
                "name": "Lomas",
                "countryCode": "US"
            },
            {
                "name": "Los Llanos",
                "countryCode": "US"
            },
            {
                "name": "Loíza",
                "countryCode": "US"
            },
            {
                "name": "Luis Lloréns Torres",
                "countryCode": "US"
            },
            {
                "name": "Luis M. Cintron",
                "countryCode": "US"
            },
            {
                "name": "Luquillo",
                "countryCode": "US"
            },
            {
                "name": "Luquillo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Luyando",
                "countryCode": "US"
            },
            {
                "name": "Magas Arriba",
                "countryCode": "US"
            },
            {
                "name": "Manatí",
                "countryCode": "US"
            },
            {
                "name": "Maria Antonia",
                "countryCode": "US"
            },
            {
                "name": "Mariano Colón",
                "countryCode": "US"
            },
            {
                "name": "Maricao",
                "countryCode": "US"
            },
            {
                "name": "Maricao Municipio",
                "countryCode": "US"
            },
            {
                "name": "Martorell",
                "countryCode": "US"
            },
            {
                "name": "Marueño",
                "countryCode": "US"
            },
            {
                "name": "Maunabo",
                "countryCode": "US"
            },
            {
                "name": "Maunabo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Mayagüez",
                "countryCode": "US"
            },
            {
                "name": "Miranda",
                "countryCode": "US"
            },
            {
                "name": "Moca",
                "countryCode": "US"
            },
            {
                "name": "Moca Municipio",
                "countryCode": "US"
            },
            {
                "name": "Monserrate",
                "countryCode": "US"
            },
            {
                "name": "Monte Grande",
                "countryCode": "US"
            },
            {
                "name": "Mora",
                "countryCode": "US"
            },
            {
                "name": "Morovis",
                "countryCode": "US"
            },
            {
                "name": "Mucarabones",
                "countryCode": "US"
            },
            {
                "name": "Naguabo",
                "countryCode": "US"
            },
            {
                "name": "Naguabo Municipio",
                "countryCode": "US"
            },
            {
                "name": "Naranjito",
                "countryCode": "US"
            },
            {
                "name": "Naranjito Municipio",
                "countryCode": "US"
            },
            {
                "name": "Olimpo",
                "countryCode": "US"
            },
            {
                "name": "Orocovis",
                "countryCode": "US"
            },
            {
                "name": "Orocovis Municipio",
                "countryCode": "US"
            },
            {
                "name": "Pajonal",
                "countryCode": "US"
            },
            {
                "name": "Palmarejo",
                "countryCode": "US"
            },
            {
                "name": "Palmas",
                "countryCode": "US"
            },
            {
                "name": "Palmer",
                "countryCode": "US"
            },
            {
                "name": "Palo Seco",
                "countryCode": "US"
            },
            {
                "name": "Palomas",
                "countryCode": "US"
            },
            {
                "name": "Parcelas La Milagrosa",
                "countryCode": "US"
            },
            {
                "name": "Parcelas Nuevas",
                "countryCode": "US"
            },
            {
                "name": "Parcelas Peñuelas",
                "countryCode": "US"
            },
            {
                "name": "Pastos",
                "countryCode": "US"
            },
            {
                "name": "Patillas",
                "countryCode": "US"
            },
            {
                "name": "Patillas Municipio",
                "countryCode": "US"
            },
            {
                "name": "Peña Pobre",
                "countryCode": "US"
            },
            {
                "name": "Peñuelas",
                "countryCode": "US"
            },
            {
                "name": "Piedra Gorda",
                "countryCode": "US"
            },
            {
                "name": "Playa Fortuna",
                "countryCode": "US"
            },
            {
                "name": "Playita",
                "countryCode": "US"
            },
            {
                "name": "Playita Cortada",
                "countryCode": "US"
            },
            {
                "name": "Pole Ojea",
                "countryCode": "US"
            },
            {
                "name": "Ponce",
                "countryCode": "US"
            },
            {
                "name": "Potala Pastillo",
                "countryCode": "US"
            },
            {
                "name": "Pueblito del Rio",
                "countryCode": "US"
            },
            {
                "name": "Puerto Real",
                "countryCode": "US"
            },
            {
                "name": "Punta Santiago",
                "countryCode": "US"
            },
            {
                "name": "Pájaros",
                "countryCode": "US"
            },
            {
                "name": "Quebrada",
                "countryCode": "US"
            },
            {
                "name": "Quebradillas",
                "countryCode": "US"
            },
            {
                "name": "Quebradillas Municipio",
                "countryCode": "US"
            },
            {
                "name": "Rafael Capo",
                "countryCode": "US"
            },
            {
                "name": "Rafael Gonzalez",
                "countryCode": "US"
            },
            {
                "name": "Rafael Hernandez",
                "countryCode": "US"
            },
            {
                "name": "Ramos",
                "countryCode": "US"
            },
            {
                "name": "Rincón",
                "countryCode": "US"
            },
            {
                "name": "Rincón Municipio",
                "countryCode": "US"
            },
            {
                "name": "Rio Lajas",
                "countryCode": "US"
            },
            {
                "name": "Rosa Sanchez",
                "countryCode": "US"
            },
            {
                "name": "Río Blanco",
                "countryCode": "US"
            },
            {
                "name": "Río Cañas Abajo",
                "countryCode": "US"
            },
            {
                "name": "Río Grande",
                "countryCode": "US"
            },
            {
                "name": "Río Grande Municipio",
                "countryCode": "US"
            },
            {
                "name": "Río Piedras",
                "countryCode": "US"
            },
            {
                "name": "Sabana",
                "countryCode": "US"
            },
            {
                "name": "Sabana Eneas",
                "countryCode": "US"
            },
            {
                "name": "Sabana Grande",
                "countryCode": "US"
            },
            {
                "name": "Sabana Grande Municipio",
                "countryCode": "US"
            },
            {
                "name": "Sabana Hoyos",
                "countryCode": "US"
            },
            {
                "name": "Sabana Seca",
                "countryCode": "US"
            },
            {
                "name": "Salinas",
                "countryCode": "US"
            },
            {
                "name": "San Antonio",
                "countryCode": "US"
            },
            {
                "name": "San Germán Municipio",
                "countryCode": "US"
            },
            {
                "name": "San Isidro",
                "countryCode": "US"
            },
            {
                "name": "San José",
                "countryCode": "US"
            },
            {
                "name": "San Juan",
                "countryCode": "US"
            },
            {
                "name": "San Lorenzo",
                "countryCode": "US"
            },
            {
                "name": "San Sebastián",
                "countryCode": "US"
            },
            {
                "name": "Santa Barbara",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara",
                "countryCode": "US"
            },
            {
                "name": "Santa Isabel",
                "countryCode": "US"
            },
            {
                "name": "Santa Isabel Municipio",
                "countryCode": "US"
            },
            {
                "name": "Santo Domingo",
                "countryCode": "US"
            },
            {
                "name": "Santurce",
                "countryCode": "US"
            },
            {
                "name": "Stella",
                "countryCode": "US"
            },
            {
                "name": "Sumidero",
                "countryCode": "US"
            },
            {
                "name": "Suárez",
                "countryCode": "US"
            },
            {
                "name": "Tallaboa",
                "countryCode": "US"
            },
            {
                "name": "Tallaboa Alta",
                "countryCode": "US"
            },
            {
                "name": "Tiburones",
                "countryCode": "US"
            },
            {
                "name": "Tierras Nuevas Poniente",
                "countryCode": "US"
            },
            {
                "name": "Toa Alta",
                "countryCode": "US"
            },
            {
                "name": "Toa Alta Municipio",
                "countryCode": "US"
            },
            {
                "name": "Toa Baja",
                "countryCode": "US"
            },
            {
                "name": "Trujillo Alto",
                "countryCode": "US"
            },
            {
                "name": "Trujillo Alto Municipio",
                "countryCode": "US"
            },
            {
                "name": "Utuado",
                "countryCode": "US"
            },
            {
                "name": "Utuado barrio-pueblo",
                "countryCode": "US"
            },
            {
                "name": "Vega Alta",
                "countryCode": "US"
            },
            {
                "name": "Vega Alta Municipio",
                "countryCode": "US"
            },
            {
                "name": "Vega Baja",
                "countryCode": "US"
            },
            {
                "name": "Vieques",
                "countryCode": "US"
            },
            {
                "name": "Vieques Municipality",
                "countryCode": "US"
            },
            {
                "name": "Villalba",
                "countryCode": "US"
            },
            {
                "name": "Villalba Municipio",
                "countryCode": "US"
            },
            {
                "name": "Vázquez",
                "countryCode": "US"
            },
            {
                "name": "Yabucoa",
                "countryCode": "US"
            },
            {
                "name": "Yabucoa Municipio",
                "countryCode": "US"
            },
            {
                "name": "Yauco",
                "countryCode": "US"
            },
            {
                "name": "Yaurel",
                "countryCode": "US"
            },
            {
                "name": "Ashaway",
                "countryCode": "US"
            },
            {
                "name": "Barrington",
                "countryCode": "US"
            },
            {
                "name": "Bradford",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Bristol County",
                "countryCode": "US"
            },
            {
                "name": "Central Falls",
                "countryCode": "US"
            },
            {
                "name": "Charlestown",
                "countryCode": "US"
            },
            {
                "name": "Chepachet",
                "countryCode": "US"
            },
            {
                "name": "Coventry",
                "countryCode": "US"
            },
            {
                "name": "Cranston",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Cumberland Hill",
                "countryCode": "US"
            },
            {
                "name": "East Greenwich",
                "countryCode": "US"
            },
            {
                "name": "East Providence",
                "countryCode": "US"
            },
            {
                "name": "Exeter",
                "countryCode": "US"
            },
            {
                "name": "Foster",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Harrisville",
                "countryCode": "US"
            },
            {
                "name": "Hope Valley",
                "countryCode": "US"
            },
            {
                "name": "Hopkinton",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Johnston",
                "countryCode": "US"
            },
            {
                "name": "Kent County",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Melville",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Narragansett",
                "countryCode": "US"
            },
            {
                "name": "Narragansett Pier",
                "countryCode": "US"
            },
            {
                "name": "New Shoreham",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Newport County",
                "countryCode": "US"
            },
            {
                "name": "Newport East",
                "countryCode": "US"
            },
            {
                "name": "North Kingstown",
                "countryCode": "US"
            },
            {
                "name": "North Providence",
                "countryCode": "US"
            },
            {
                "name": "North Scituate",
                "countryCode": "US"
            },
            {
                "name": "North Smithfield",
                "countryCode": "US"
            },
            {
                "name": "Pascoag",
                "countryCode": "US"
            },
            {
                "name": "Pawtucket",
                "countryCode": "US"
            },
            {
                "name": "Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "Providence",
                "countryCode": "US"
            },
            {
                "name": "Providence County",
                "countryCode": "US"
            },
            {
                "name": "Smithfield",
                "countryCode": "US"
            },
            {
                "name": "South Kingstown",
                "countryCode": "US"
            },
            {
                "name": "Tiverton",
                "countryCode": "US"
            },
            {
                "name": "Valley Falls",
                "countryCode": "US"
            },
            {
                "name": "Wakefield-Peacedale",
                "countryCode": "US"
            },
            {
                "name": "Warren",
                "countryCode": "US"
            },
            {
                "name": "Warwick",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "West Greenwich",
                "countryCode": "US"
            },
            {
                "name": "West Warwick",
                "countryCode": "US"
            },
            {
                "name": "Westerly",
                "countryCode": "US"
            },
            {
                "name": "Woonsocket",
                "countryCode": "US"
            },
            {
                "name": "Abbeville",
                "countryCode": "US"
            },
            {
                "name": "Abbeville County",
                "countryCode": "US"
            },
            {
                "name": "Aiken",
                "countryCode": "US"
            },
            {
                "name": "Aiken County",
                "countryCode": "US"
            },
            {
                "name": "Allendale",
                "countryCode": "US"
            },
            {
                "name": "Allendale County",
                "countryCode": "US"
            },
            {
                "name": "Anderson",
                "countryCode": "US"
            },
            {
                "name": "Anderson County",
                "countryCode": "US"
            },
            {
                "name": "Andrews",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Arial",
                "countryCode": "US"
            },
            {
                "name": "Awendaw",
                "countryCode": "US"
            },
            {
                "name": "Bamberg",
                "countryCode": "US"
            },
            {
                "name": "Bamberg County",
                "countryCode": "US"
            },
            {
                "name": "Barnwell",
                "countryCode": "US"
            },
            {
                "name": "Barnwell County",
                "countryCode": "US"
            },
            {
                "name": "Batesburg",
                "countryCode": "US"
            },
            {
                "name": "Batesburg-Leesville",
                "countryCode": "US"
            },
            {
                "name": "Beaufort",
                "countryCode": "US"
            },
            {
                "name": "Beaufort County",
                "countryCode": "US"
            },
            {
                "name": "Belton",
                "countryCode": "US"
            },
            {
                "name": "Belvedere",
                "countryCode": "US"
            },
            {
                "name": "Bennettsville",
                "countryCode": "US"
            },
            {
                "name": "Berea",
                "countryCode": "US"
            },
            {
                "name": "Berkeley County",
                "countryCode": "US"
            },
            {
                "name": "Bishopville",
                "countryCode": "US"
            },
            {
                "name": "Blacksburg",
                "countryCode": "US"
            },
            {
                "name": "Blackville",
                "countryCode": "US"
            },
            {
                "name": "Bluffton",
                "countryCode": "US"
            },
            {
                "name": "Blythewood",
                "countryCode": "US"
            },
            {
                "name": "Boiling Springs",
                "countryCode": "US"
            },
            {
                "name": "Bonneau Beach",
                "countryCode": "US"
            },
            {
                "name": "Brookdale",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Burnettown",
                "countryCode": "US"
            },
            {
                "name": "Burton",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Calhoun Falls",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Cane Savannah",
                "countryCode": "US"
            },
            {
                "name": "Catawba",
                "countryCode": "US"
            },
            {
                "name": "Cayce",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Central",
                "countryCode": "US"
            },
            {
                "name": "Chapin",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Charleston County",
                "countryCode": "US"
            },
            {
                "name": "Cheraw",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Cherryvale",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chester County",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield County",
                "countryCode": "US"
            },
            {
                "name": "City View",
                "countryCode": "US"
            },
            {
                "name": "Clarendon County",
                "countryCode": "US"
            },
            {
                "name": "Clearwater",
                "countryCode": "US"
            },
            {
                "name": "Clemson",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clover",
                "countryCode": "US"
            },
            {
                "name": "Colleton County",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Conway",
                "countryCode": "US"
            },
            {
                "name": "Cowpens",
                "countryCode": "US"
            },
            {
                "name": "Dalzell",
                "countryCode": "US"
            },
            {
                "name": "Darlington",
                "countryCode": "US"
            },
            {
                "name": "Darlington County",
                "countryCode": "US"
            },
            {
                "name": "Denmark",
                "countryCode": "US"
            },
            {
                "name": "Dentsville",
                "countryCode": "US"
            },
            {
                "name": "Dillon",
                "countryCode": "US"
            },
            {
                "name": "Dillon County",
                "countryCode": "US"
            },
            {
                "name": "Dorchester County",
                "countryCode": "US"
            },
            {
                "name": "Due West",
                "countryCode": "US"
            },
            {
                "name": "Duncan",
                "countryCode": "US"
            },
            {
                "name": "Dunean",
                "countryCode": "US"
            },
            {
                "name": "Easley",
                "countryCode": "US"
            },
            {
                "name": "East Gaffney",
                "countryCode": "US"
            },
            {
                "name": "East Sumter",
                "countryCode": "US"
            },
            {
                "name": "Edgefield",
                "countryCode": "US"
            },
            {
                "name": "Edgefield County",
                "countryCode": "US"
            },
            {
                "name": "Edisto",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Estill",
                "countryCode": "US"
            },
            {
                "name": "Eureka Mill",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairfield County",
                "countryCode": "US"
            },
            {
                "name": "Fairforest",
                "countryCode": "US"
            },
            {
                "name": "Five Forks",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Florence County",
                "countryCode": "US"
            },
            {
                "name": "Folly Beach",
                "countryCode": "US"
            },
            {
                "name": "Forest Acres",
                "countryCode": "US"
            },
            {
                "name": "Forestbrook",
                "countryCode": "US"
            },
            {
                "name": "Fort Mill",
                "countryCode": "US"
            },
            {
                "name": "Fountain Inn",
                "countryCode": "US"
            },
            {
                "name": "Gadsden",
                "countryCode": "US"
            },
            {
                "name": "Gaffney",
                "countryCode": "US"
            },
            {
                "name": "Gantt",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Gaston",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Georgetown County",
                "countryCode": "US"
            },
            {
                "name": "Gloverville",
                "countryCode": "US"
            },
            {
                "name": "Golden Grove",
                "countryCode": "US"
            },
            {
                "name": "Goose Creek",
                "countryCode": "US"
            },
            {
                "name": "Graniteville",
                "countryCode": "US"
            },
            {
                "name": "Great Falls",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Greenville County",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Greenwood County",
                "countryCode": "US"
            },
            {
                "name": "Greer",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hampton County",
                "countryCode": "US"
            },
            {
                "name": "Hanahan",
                "countryCode": "US"
            },
            {
                "name": "Hardeeville",
                "countryCode": "US"
            },
            {
                "name": "Hartsville",
                "countryCode": "US"
            },
            {
                "name": "Hilton Head",
                "countryCode": "US"
            },
            {
                "name": "Hilton Head Island",
                "countryCode": "US"
            },
            {
                "name": "Holly Hill",
                "countryCode": "US"
            },
            {
                "name": "Hollywood",
                "countryCode": "US"
            },
            {
                "name": "Homeland Park",
                "countryCode": "US"
            },
            {
                "name": "Honea Path",
                "countryCode": "US"
            },
            {
                "name": "Hopkins",
                "countryCode": "US"
            },
            {
                "name": "Horry County",
                "countryCode": "US"
            },
            {
                "name": "India Hook",
                "countryCode": "US"
            },
            {
                "name": "Inman",
                "countryCode": "US"
            },
            {
                "name": "Inman Mills",
                "countryCode": "US"
            },
            {
                "name": "Irmo",
                "countryCode": "US"
            },
            {
                "name": "Irwin",
                "countryCode": "US"
            },
            {
                "name": "Isle of Palms",
                "countryCode": "US"
            },
            {
                "name": "Iva",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "James Island",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Joanna",
                "countryCode": "US"
            },
            {
                "name": "Johnsonville",
                "countryCode": "US"
            },
            {
                "name": "Johnston",
                "countryCode": "US"
            },
            {
                "name": "Judson",
                "countryCode": "US"
            },
            {
                "name": "Kershaw",
                "countryCode": "US"
            },
            {
                "name": "Kershaw County",
                "countryCode": "US"
            },
            {
                "name": "Kiawah Island",
                "countryCode": "US"
            },
            {
                "name": "Kingstree",
                "countryCode": "US"
            },
            {
                "name": "Ladson",
                "countryCode": "US"
            },
            {
                "name": "Lake City",
                "countryCode": "US"
            },
            {
                "name": "Lake Murray of Richland",
                "countryCode": "US"
            },
            {
                "name": "Lake Secession",
                "countryCode": "US"
            },
            {
                "name": "Lake Wylie",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lancaster County",
                "countryCode": "US"
            },
            {
                "name": "Lancaster Mill",
                "countryCode": "US"
            },
            {
                "name": "Landrum",
                "countryCode": "US"
            },
            {
                "name": "Langley",
                "countryCode": "US"
            },
            {
                "name": "Latta",
                "countryCode": "US"
            },
            {
                "name": "Laurel Bay",
                "countryCode": "US"
            },
            {
                "name": "Laurens",
                "countryCode": "US"
            },
            {
                "name": "Laurens County",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leesville",
                "countryCode": "US"
            },
            {
                "name": "Lesslie",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lexington County",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lincolnville",
                "countryCode": "US"
            },
            {
                "name": "Little River",
                "countryCode": "US"
            },
            {
                "name": "Loris",
                "countryCode": "US"
            },
            {
                "name": "Lugoff",
                "countryCode": "US"
            },
            {
                "name": "Lyman",
                "countryCode": "US"
            },
            {
                "name": "Manning",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marlboro County",
                "countryCode": "US"
            },
            {
                "name": "Mauldin",
                "countryCode": "US"
            },
            {
                "name": "Mayo",
                "countryCode": "US"
            },
            {
                "name": "McColl",
                "countryCode": "US"
            },
            {
                "name": "McCormick",
                "countryCode": "US"
            },
            {
                "name": "McCormick County",
                "countryCode": "US"
            },
            {
                "name": "Meggett",
                "countryCode": "US"
            },
            {
                "name": "Monarch Mill",
                "countryCode": "US"
            },
            {
                "name": "Moncks Corner",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mullins",
                "countryCode": "US"
            },
            {
                "name": "Murphys Estates",
                "countryCode": "US"
            },
            {
                "name": "Murrells Inlet",
                "countryCode": "US"
            },
            {
                "name": "Myrtle Beach",
                "countryCode": "US"
            },
            {
                "name": "New Ellenton",
                "countryCode": "US"
            },
            {
                "name": "Newberry",
                "countryCode": "US"
            },
            {
                "name": "Newberry County",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Ninety Six",
                "countryCode": "US"
            },
            {
                "name": "North Augusta",
                "countryCode": "US"
            },
            {
                "name": "North Charleston",
                "countryCode": "US"
            },
            {
                "name": "North Hartsville",
                "countryCode": "US"
            },
            {
                "name": "North Myrtle Beach",
                "countryCode": "US"
            },
            {
                "name": "Northlake",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Oconee County",
                "countryCode": "US"
            },
            {
                "name": "Orangeburg",
                "countryCode": "US"
            },
            {
                "name": "Orangeburg County",
                "countryCode": "US"
            },
            {
                "name": "Pacolet",
                "countryCode": "US"
            },
            {
                "name": "Pageland",
                "countryCode": "US"
            },
            {
                "name": "Pamplico",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Pendleton",
                "countryCode": "US"
            },
            {
                "name": "Pickens",
                "countryCode": "US"
            },
            {
                "name": "Pickens County",
                "countryCode": "US"
            },
            {
                "name": "Piedmont",
                "countryCode": "US"
            },
            {
                "name": "Pineridge",
                "countryCode": "US"
            },
            {
                "name": "Port Royal",
                "countryCode": "US"
            },
            {
                "name": "Powdersville",
                "countryCode": "US"
            },
            {
                "name": "Privateer",
                "countryCode": "US"
            },
            {
                "name": "Prosperity",
                "countryCode": "US"
            },
            {
                "name": "Ravenel",
                "countryCode": "US"
            },
            {
                "name": "Red Bank",
                "countryCode": "US"
            },
            {
                "name": "Red Hill",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Ridgeland",
                "countryCode": "US"
            },
            {
                "name": "Ridgeville",
                "countryCode": "US"
            },
            {
                "name": "Rock Hill",
                "countryCode": "US"
            },
            {
                "name": "Roebuck",
                "countryCode": "US"
            },
            {
                "name": "Saint Andrews",
                "countryCode": "US"
            },
            {
                "name": "Saint George",
                "countryCode": "US"
            },
            {
                "name": "Saint Matthews",
                "countryCode": "US"
            },
            {
                "name": "Saint Stephen",
                "countryCode": "US"
            },
            {
                "name": "Saluda",
                "countryCode": "US"
            },
            {
                "name": "Saluda County",
                "countryCode": "US"
            },
            {
                "name": "Sangaree",
                "countryCode": "US"
            },
            {
                "name": "Sans Souci",
                "countryCode": "US"
            },
            {
                "name": "Saxon",
                "countryCode": "US"
            },
            {
                "name": "Seabrook Island",
                "countryCode": "US"
            },
            {
                "name": "Seneca",
                "countryCode": "US"
            },
            {
                "name": "Seven Oaks",
                "countryCode": "US"
            },
            {
                "name": "Shell Point",
                "countryCode": "US"
            },
            {
                "name": "Simpsonville",
                "countryCode": "US"
            },
            {
                "name": "Slater-Marietta",
                "countryCode": "US"
            },
            {
                "name": "Socastee",
                "countryCode": "US"
            },
            {
                "name": "South Congaree",
                "countryCode": "US"
            },
            {
                "name": "South Sumter",
                "countryCode": "US"
            },
            {
                "name": "Southern Shops",
                "countryCode": "US"
            },
            {
                "name": "Spartanburg",
                "countryCode": "US"
            },
            {
                "name": "Spartanburg County",
                "countryCode": "US"
            },
            {
                "name": "Springdale",
                "countryCode": "US"
            },
            {
                "name": "Stateburg",
                "countryCode": "US"
            },
            {
                "name": "Sullivans Island",
                "countryCode": "US"
            },
            {
                "name": "Summerville",
                "countryCode": "US"
            },
            {
                "name": "Sumter",
                "countryCode": "US"
            },
            {
                "name": "Sumter County",
                "countryCode": "US"
            },
            {
                "name": "Surfside Beach",
                "countryCode": "US"
            },
            {
                "name": "Taylors",
                "countryCode": "US"
            },
            {
                "name": "Tega Cay",
                "countryCode": "US"
            },
            {
                "name": "Tigerville",
                "countryCode": "US"
            },
            {
                "name": "Timmonsville",
                "countryCode": "US"
            },
            {
                "name": "Travelers Rest",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Utica",
                "countryCode": "US"
            },
            {
                "name": "Valley Falls",
                "countryCode": "US"
            },
            {
                "name": "Varnville",
                "countryCode": "US"
            },
            {
                "name": "Wade Hampton",
                "countryCode": "US"
            },
            {
                "name": "Walhalla",
                "countryCode": "US"
            },
            {
                "name": "Walterboro",
                "countryCode": "US"
            },
            {
                "name": "Ware Shoals",
                "countryCode": "US"
            },
            {
                "name": "Warrenville",
                "countryCode": "US"
            },
            {
                "name": "Watts Mills",
                "countryCode": "US"
            },
            {
                "name": "Wedgefield",
                "countryCode": "US"
            },
            {
                "name": "Wedgewood",
                "countryCode": "US"
            },
            {
                "name": "Welcome",
                "countryCode": "US"
            },
            {
                "name": "Wellford",
                "countryCode": "US"
            },
            {
                "name": "West Columbia",
                "countryCode": "US"
            },
            {
                "name": "Westminster",
                "countryCode": "US"
            },
            {
                "name": "Whitmire",
                "countryCode": "US"
            },
            {
                "name": "Wilkinson Heights",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg County",
                "countryCode": "US"
            },
            {
                "name": "Williamston",
                "countryCode": "US"
            },
            {
                "name": "Williston",
                "countryCode": "US"
            },
            {
                "name": "Winnsboro",
                "countryCode": "US"
            },
            {
                "name": "Winnsboro Mills",
                "countryCode": "US"
            },
            {
                "name": "Woodfield",
                "countryCode": "US"
            },
            {
                "name": "Woodruff",
                "countryCode": "US"
            },
            {
                "name": "York",
                "countryCode": "US"
            },
            {
                "name": "York County",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Armour",
                "countryCode": "US"
            },
            {
                "name": "Aurora County",
                "countryCode": "US"
            },
            {
                "name": "Baltic",
                "countryCode": "US"
            },
            {
                "name": "Beadle County",
                "countryCode": "US"
            },
            {
                "name": "Belle Fourche",
                "countryCode": "US"
            },
            {
                "name": "Bennett County",
                "countryCode": "US"
            },
            {
                "name": "Beresford",
                "countryCode": "US"
            },
            {
                "name": "Bison",
                "countryCode": "US"
            },
            {
                "name": "Blackhawk",
                "countryCode": "US"
            },
            {
                "name": "Bon Homme County",
                "countryCode": "US"
            },
            {
                "name": "Box Elder",
                "countryCode": "US"
            },
            {
                "name": "Brandon",
                "countryCode": "US"
            },
            {
                "name": "Britton",
                "countryCode": "US"
            },
            {
                "name": "Brookings",
                "countryCode": "US"
            },
            {
                "name": "Brookings County",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Brule County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Buffalo County",
                "countryCode": "US"
            },
            {
                "name": "Burke",
                "countryCode": "US"
            },
            {
                "name": "Butte County",
                "countryCode": "US"
            },
            {
                "name": "Campbell County",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Chamberlain",
                "countryCode": "US"
            },
            {
                "name": "Charles Mix County",
                "countryCode": "US"
            },
            {
                "name": "Clark",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake",
                "countryCode": "US"
            },
            {
                "name": "Codington County",
                "countryCode": "US"
            },
            {
                "name": "Colonial Pine Hills",
                "countryCode": "US"
            },
            {
                "name": "Corson County",
                "countryCode": "US"
            },
            {
                "name": "Crooks",
                "countryCode": "US"
            },
            {
                "name": "Custer",
                "countryCode": "US"
            },
            {
                "name": "Custer County",
                "countryCode": "US"
            },
            {
                "name": "Dakota Dunes",
                "countryCode": "US"
            },
            {
                "name": "Davison County",
                "countryCode": "US"
            },
            {
                "name": "Day County",
                "countryCode": "US"
            },
            {
                "name": "De Smet",
                "countryCode": "US"
            },
            {
                "name": "Deadwood",
                "countryCode": "US"
            },
            {
                "name": "Dell Rapids",
                "countryCode": "US"
            },
            {
                "name": "Deuel County",
                "countryCode": "US"
            },
            {
                "name": "Dewey County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Dupree",
                "countryCode": "US"
            },
            {
                "name": "Eagle Butte",
                "countryCode": "US"
            },
            {
                "name": "Edmunds County",
                "countryCode": "US"
            },
            {
                "name": "Elk Point",
                "countryCode": "US"
            },
            {
                "name": "Fall River County",
                "countryCode": "US"
            },
            {
                "name": "Faulk County",
                "countryCode": "US"
            },
            {
                "name": "Faulkton",
                "countryCode": "US"
            },
            {
                "name": "Flandreau",
                "countryCode": "US"
            },
            {
                "name": "Fort Pierre",
                "countryCode": "US"
            },
            {
                "name": "Fort Thompson",
                "countryCode": "US"
            },
            {
                "name": "Freeman",
                "countryCode": "US"
            },
            {
                "name": "Garretson",
                "countryCode": "US"
            },
            {
                "name": "Gettysburg",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Gregory",
                "countryCode": "US"
            },
            {
                "name": "Gregory County",
                "countryCode": "US"
            },
            {
                "name": "Groton",
                "countryCode": "US"
            },
            {
                "name": "Haakon County",
                "countryCode": "US"
            },
            {
                "name": "Hamlin County",
                "countryCode": "US"
            },
            {
                "name": "Hand County",
                "countryCode": "US"
            },
            {
                "name": "Hanson County",
                "countryCode": "US"
            },
            {
                "name": "Harding County",
                "countryCode": "US"
            },
            {
                "name": "Harrisburg",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hayti",
                "countryCode": "US"
            },
            {
                "name": "Highmore",
                "countryCode": "US"
            },
            {
                "name": "Hot Springs",
                "countryCode": "US"
            },
            {
                "name": "Howard",
                "countryCode": "US"
            },
            {
                "name": "Hughes County",
                "countryCode": "US"
            },
            {
                "name": "Huron",
                "countryCode": "US"
            },
            {
                "name": "Hutchinson County",
                "countryCode": "US"
            },
            {
                "name": "Hyde County",
                "countryCode": "US"
            },
            {
                "name": "Ipswich",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jerauld County",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Kadoka",
                "countryCode": "US"
            },
            {
                "name": "Kennebec",
                "countryCode": "US"
            },
            {
                "name": "Kingsbury County",
                "countryCode": "US"
            },
            {
                "name": "Lake Andes",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lead",
                "countryCode": "US"
            },
            {
                "name": "Lemmon",
                "countryCode": "US"
            },
            {
                "name": "Lennox",
                "countryCode": "US"
            },
            {
                "name": "Leola",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lyman County",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martin",
                "countryCode": "US"
            },
            {
                "name": "McCook County",
                "countryCode": "US"
            },
            {
                "name": "McIntosh",
                "countryCode": "US"
            },
            {
                "name": "McPherson County",
                "countryCode": "US"
            },
            {
                "name": "Meade County",
                "countryCode": "US"
            },
            {
                "name": "Mellette County",
                "countryCode": "US"
            },
            {
                "name": "Milbank",
                "countryCode": "US"
            },
            {
                "name": "Miller",
                "countryCode": "US"
            },
            {
                "name": "Miner County",
                "countryCode": "US"
            },
            {
                "name": "Minnehaha County",
                "countryCode": "US"
            },
            {
                "name": "Mission",
                "countryCode": "US"
            },
            {
                "name": "Mitchell",
                "countryCode": "US"
            },
            {
                "name": "Mobridge",
                "countryCode": "US"
            },
            {
                "name": "Moody County",
                "countryCode": "US"
            },
            {
                "name": "Mound City",
                "countryCode": "US"
            },
            {
                "name": "Murdo",
                "countryCode": "US"
            },
            {
                "name": "North Eagle Butte",
                "countryCode": "US"
            },
            {
                "name": "North Sioux City",
                "countryCode": "US"
            },
            {
                "name": "North Spearfish",
                "countryCode": "US"
            },
            {
                "name": "Oglala",
                "countryCode": "US"
            },
            {
                "name": "Oglala Lakota County",
                "countryCode": "US"
            },
            {
                "name": "Olivet",
                "countryCode": "US"
            },
            {
                "name": "Onida",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Parkston",
                "countryCode": "US"
            },
            {
                "name": "Pennington County",
                "countryCode": "US"
            },
            {
                "name": "Perkins County",
                "countryCode": "US"
            },
            {
                "name": "Philip",
                "countryCode": "US"
            },
            {
                "name": "Pierre",
                "countryCode": "US"
            },
            {
                "name": "Pine Ridge",
                "countryCode": "US"
            },
            {
                "name": "Plankinton",
                "countryCode": "US"
            },
            {
                "name": "Platte",
                "countryCode": "US"
            },
            {
                "name": "Porcupine",
                "countryCode": "US"
            },
            {
                "name": "Potter County",
                "countryCode": "US"
            },
            {
                "name": "Rapid City",
                "countryCode": "US"
            },
            {
                "name": "Rapid Valley",
                "countryCode": "US"
            },
            {
                "name": "Redfield",
                "countryCode": "US"
            },
            {
                "name": "Roberts County",
                "countryCode": "US"
            },
            {
                "name": "Rosebud",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Sanborn County",
                "countryCode": "US"
            },
            {
                "name": "Selby",
                "countryCode": "US"
            },
            {
                "name": "Sioux Falls",
                "countryCode": "US"
            },
            {
                "name": "Sisseton",
                "countryCode": "US"
            },
            {
                "name": "Spearfish",
                "countryCode": "US"
            },
            {
                "name": "Spink County",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Stanley County",
                "countryCode": "US"
            },
            {
                "name": "Sturgis",
                "countryCode": "US"
            },
            {
                "name": "Sully County",
                "countryCode": "US"
            },
            {
                "name": "Summerset",
                "countryCode": "US"
            },
            {
                "name": "Tea",
                "countryCode": "US"
            },
            {
                "name": "Timber Lake",
                "countryCode": "US"
            },
            {
                "name": "Todd County",
                "countryCode": "US"
            },
            {
                "name": "Tripp County",
                "countryCode": "US"
            },
            {
                "name": "Turner County",
                "countryCode": "US"
            },
            {
                "name": "Tyndall",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Vermillion",
                "countryCode": "US"
            },
            {
                "name": "Volga",
                "countryCode": "US"
            },
            {
                "name": "Wagner",
                "countryCode": "US"
            },
            {
                "name": "Walworth County",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Webster",
                "countryCode": "US"
            },
            {
                "name": "Wessington Springs",
                "countryCode": "US"
            },
            {
                "name": "White River",
                "countryCode": "US"
            },
            {
                "name": "Winner",
                "countryCode": "US"
            },
            {
                "name": "Woonsocket",
                "countryCode": "US"
            },
            {
                "name": "Yankton",
                "countryCode": "US"
            },
            {
                "name": "Yankton County",
                "countryCode": "US"
            },
            {
                "name": "Ziebach County",
                "countryCode": "US"
            },
            {
                "name": "Adamsville",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Alcoa",
                "countryCode": "US"
            },
            {
                "name": "Algood",
                "countryCode": "US"
            },
            {
                "name": "Altamont",
                "countryCode": "US"
            },
            {
                "name": "Anderson County",
                "countryCode": "US"
            },
            {
                "name": "Apison",
                "countryCode": "US"
            },
            {
                "name": "Ardmore",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Ashland City",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atoka",
                "countryCode": "US"
            },
            {
                "name": "Banner Hill",
                "countryCode": "US"
            },
            {
                "name": "Bartlett",
                "countryCode": "US"
            },
            {
                "name": "Baxter",
                "countryCode": "US"
            },
            {
                "name": "Bean Station",
                "countryCode": "US"
            },
            {
                "name": "Bedford County",
                "countryCode": "US"
            },
            {
                "name": "Belle Meade",
                "countryCode": "US"
            },
            {
                "name": "Bells",
                "countryCode": "US"
            },
            {
                "name": "Benton",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Blaine",
                "countryCode": "US"
            },
            {
                "name": "Bledsoe County",
                "countryCode": "US"
            },
            {
                "name": "Bloomingdale",
                "countryCode": "US"
            },
            {
                "name": "Blount County",
                "countryCode": "US"
            },
            {
                "name": "Blountville",
                "countryCode": "US"
            },
            {
                "name": "Bluff City",
                "countryCode": "US"
            },
            {
                "name": "Bolivar",
                "countryCode": "US"
            },
            {
                "name": "Bon Aqua Junction",
                "countryCode": "US"
            },
            {
                "name": "Bradford",
                "countryCode": "US"
            },
            {
                "name": "Bradley County",
                "countryCode": "US"
            },
            {
                "name": "Brentwood",
                "countryCode": "US"
            },
            {
                "name": "Brentwood Estates",
                "countryCode": "US"
            },
            {
                "name": "Brighton",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Bruceton",
                "countryCode": "US"
            },
            {
                "name": "Burns",
                "countryCode": "US"
            },
            {
                "name": "Byrdstown",
                "countryCode": "US"
            },
            {
                "name": "Camden",
                "countryCode": "US"
            },
            {
                "name": "Campbell County",
                "countryCode": "US"
            },
            {
                "name": "Cannon County",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carter County",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Caryville",
                "countryCode": "US"
            },
            {
                "name": "Celina",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Central",
                "countryCode": "US"
            },
            {
                "name": "Chapel Hill",
                "countryCode": "US"
            },
            {
                "name": "Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Chattanooga",
                "countryCode": "US"
            },
            {
                "name": "Cheatham County",
                "countryCode": "US"
            },
            {
                "name": "Chester County",
                "countryCode": "US"
            },
            {
                "name": "Christiana",
                "countryCode": "US"
            },
            {
                "name": "Church Hill",
                "countryCode": "US"
            },
            {
                "name": "Claiborne County",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Coalfield",
                "countryCode": "US"
            },
            {
                "name": "Cocke County",
                "countryCode": "US"
            },
            {
                "name": "Coffee County",
                "countryCode": "US"
            },
            {
                "name": "Collegedale",
                "countryCode": "US"
            },
            {
                "name": "Collierville",
                "countryCode": "US"
            },
            {
                "name": "Colonial Heights",
                "countryCode": "US"
            },
            {
                "name": "Columbia",
                "countryCode": "US"
            },
            {
                "name": "Condon",
                "countryCode": "US"
            },
            {
                "name": "Cookeville",
                "countryCode": "US"
            },
            {
                "name": "Coopertown",
                "countryCode": "US"
            },
            {
                "name": "Cornersville",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Cowan",
                "countryCode": "US"
            },
            {
                "name": "Crockett County",
                "countryCode": "US"
            },
            {
                "name": "Cross Plains",
                "countryCode": "US"
            },
            {
                "name": "Crossville",
                "countryCode": "US"
            },
            {
                "name": "Crump",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Dandridge",
                "countryCode": "US"
            },
            {
                "name": "Davidson County",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "DeKalb County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Decatur County",
                "countryCode": "US"
            },
            {
                "name": "Decaturville",
                "countryCode": "US"
            },
            {
                "name": "Decherd",
                "countryCode": "US"
            },
            {
                "name": "Dickson",
                "countryCode": "US"
            },
            {
                "name": "Dickson County",
                "countryCode": "US"
            },
            {
                "name": "Dodson Branch",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Dresden",
                "countryCode": "US"
            },
            {
                "name": "Dunlap",
                "countryCode": "US"
            },
            {
                "name": "Dyer",
                "countryCode": "US"
            },
            {
                "name": "Dyer County",
                "countryCode": "US"
            },
            {
                "name": "Dyersburg",
                "countryCode": "US"
            },
            {
                "name": "Eagleton Village",
                "countryCode": "US"
            },
            {
                "name": "East Brainerd",
                "countryCode": "US"
            },
            {
                "name": "East Chattanooga",
                "countryCode": "US"
            },
            {
                "name": "East Cleveland",
                "countryCode": "US"
            },
            {
                "name": "East Ridge",
                "countryCode": "US"
            },
            {
                "name": "Elizabethton",
                "countryCode": "US"
            },
            {
                "name": "Englewood",
                "countryCode": "US"
            },
            {
                "name": "Erin",
                "countryCode": "US"
            },
            {
                "name": "Erwin",
                "countryCode": "US"
            },
            {
                "name": "Estill Springs",
                "countryCode": "US"
            },
            {
                "name": "Etowah",
                "countryCode": "US"
            },
            {
                "name": "Fairfield Glade",
                "countryCode": "US"
            },
            {
                "name": "Fairmount",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Fall Branch",
                "countryCode": "US"
            },
            {
                "name": "Falling Water",
                "countryCode": "US"
            },
            {
                "name": "Farragut",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Fentress County",
                "countryCode": "US"
            },
            {
                "name": "Fincastle",
                "countryCode": "US"
            },
            {
                "name": "Forest Hills",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Gainesboro",
                "countryCode": "US"
            },
            {
                "name": "Gallatin",
                "countryCode": "US"
            },
            {
                "name": "Gatlinburg",
                "countryCode": "US"
            },
            {
                "name": "Germantown",
                "countryCode": "US"
            },
            {
                "name": "Gibson County",
                "countryCode": "US"
            },
            {
                "name": "Giles County",
                "countryCode": "US"
            },
            {
                "name": "Gleason",
                "countryCode": "US"
            },
            {
                "name": "Goodlettsville",
                "countryCode": "US"
            },
            {
                "name": "Gordonsville",
                "countryCode": "US"
            },
            {
                "name": "Grainger County",
                "countryCode": "US"
            },
            {
                "name": "Gray",
                "countryCode": "US"
            },
            {
                "name": "Graysville",
                "countryCode": "US"
            },
            {
                "name": "Green Hill",
                "countryCode": "US"
            },
            {
                "name": "Greenback",
                "countryCode": "US"
            },
            {
                "name": "Greenbrier",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greeneville",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Grimsley",
                "countryCode": "US"
            },
            {
                "name": "Gruetli-Laager",
                "countryCode": "US"
            },
            {
                "name": "Grundy County",
                "countryCode": "US"
            },
            {
                "name": "Halls",
                "countryCode": "US"
            },
            {
                "name": "Hamblen County",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hardeman County",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Harriman",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Harrogate",
                "countryCode": "US"
            },
            {
                "name": "Hartsville",
                "countryCode": "US"
            },
            {
                "name": "Hawkins County",
                "countryCode": "US"
            },
            {
                "name": "Haywood County",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Henderson County",
                "countryCode": "US"
            },
            {
                "name": "Hendersonville",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Hickman County",
                "countryCode": "US"
            },
            {
                "name": "Hickory Withe",
                "countryCode": "US"
            },
            {
                "name": "Hohenwald",
                "countryCode": "US"
            },
            {
                "name": "Hopewell",
                "countryCode": "US"
            },
            {
                "name": "Houston County",
                "countryCode": "US"
            },
            {
                "name": "Humboldt",
                "countryCode": "US"
            },
            {
                "name": "Humphreys County",
                "countryCode": "US"
            },
            {
                "name": "Hunter",
                "countryCode": "US"
            },
            {
                "name": "Huntingdon",
                "countryCode": "US"
            },
            {
                "name": "Huntsville",
                "countryCode": "US"
            },
            {
                "name": "Jacksboro",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jamestown",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jefferson City",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jellico",
                "countryCode": "US"
            },
            {
                "name": "Johnson City",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Johnsonville",
                "countryCode": "US"
            },
            {
                "name": "Jonesborough",
                "countryCode": "US"
            },
            {
                "name": "Kenton",
                "countryCode": "US"
            },
            {
                "name": "Kimball",
                "countryCode": "US"
            },
            {
                "name": "Kingsport",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kingston Springs",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "Knoxville",
                "countryCode": "US"
            },
            {
                "name": "La Vergne",
                "countryCode": "US"
            },
            {
                "name": "LaFollette",
                "countryCode": "US"
            },
            {
                "name": "Lafayette",
                "countryCode": "US"
            },
            {
                "name": "Lake County",
                "countryCode": "US"
            },
            {
                "name": "Lake Tansi",
                "countryCode": "US"
            },
            {
                "name": "Lakeland",
                "countryCode": "US"
            },
            {
                "name": "Lakesite",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Lauderdale County",
                "countryCode": "US"
            },
            {
                "name": "Lawrence County",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceburg",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lenoir City",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewisburg",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Lone Oak",
                "countryCode": "US"
            },
            {
                "name": "Lookout Mountain",
                "countryCode": "US"
            },
            {
                "name": "Loretto",
                "countryCode": "US"
            },
            {
                "name": "Loudon",
                "countryCode": "US"
            },
            {
                "name": "Loudon County",
                "countryCode": "US"
            },
            {
                "name": "Louisville",
                "countryCode": "US"
            },
            {
                "name": "Luttrell",
                "countryCode": "US"
            },
            {
                "name": "Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "Macon County",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Madisonville",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martin",
                "countryCode": "US"
            },
            {
                "name": "Maryville",
                "countryCode": "US"
            },
            {
                "name": "Mascot",
                "countryCode": "US"
            },
            {
                "name": "Mason",
                "countryCode": "US"
            },
            {
                "name": "Maury County",
                "countryCode": "US"
            },
            {
                "name": "Maynardville",
                "countryCode": "US"
            },
            {
                "name": "McEwen",
                "countryCode": "US"
            },
            {
                "name": "McKenzie",
                "countryCode": "US"
            },
            {
                "name": "McMinn County",
                "countryCode": "US"
            },
            {
                "name": "McMinnville",
                "countryCode": "US"
            },
            {
                "name": "McNairy County",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Meigs County",
                "countryCode": "US"
            },
            {
                "name": "Memphis",
                "countryCode": "US"
            },
            {
                "name": "Middle Valley",
                "countryCode": "US"
            },
            {
                "name": "Midtown",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Milan",
                "countryCode": "US"
            },
            {
                "name": "Millersville",
                "countryCode": "US"
            },
            {
                "name": "Millington",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Monteagle",
                "countryCode": "US"
            },
            {
                "name": "Monterey",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Moore County",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morristown",
                "countryCode": "US"
            },
            {
                "name": "Mosheim",
                "countryCode": "US"
            },
            {
                "name": "Mount Carmel",
                "countryCode": "US"
            },
            {
                "name": "Mount Juliet",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mountain City",
                "countryCode": "US"
            },
            {
                "name": "Mowbray Mountain",
                "countryCode": "US"
            },
            {
                "name": "Munford",
                "countryCode": "US"
            },
            {
                "name": "Murfreesboro",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "New Hope",
                "countryCode": "US"
            },
            {
                "name": "New Johnsonville",
                "countryCode": "US"
            },
            {
                "name": "New Market",
                "countryCode": "US"
            },
            {
                "name": "New South Memphis",
                "countryCode": "US"
            },
            {
                "name": "New Tazewell",
                "countryCode": "US"
            },
            {
                "name": "New Union",
                "countryCode": "US"
            },
            {
                "name": "Newbern",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Nolensville",
                "countryCode": "US"
            },
            {
                "name": "Norris",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oak Hill",
                "countryCode": "US"
            },
            {
                "name": "Oak Ridge",
                "countryCode": "US"
            },
            {
                "name": "Oakland",
                "countryCode": "US"
            },
            {
                "name": "Obion",
                "countryCode": "US"
            },
            {
                "name": "Obion County",
                "countryCode": "US"
            },
            {
                "name": "Oliver Springs",
                "countryCode": "US"
            },
            {
                "name": "Olivet",
                "countryCode": "US"
            },
            {
                "name": "Oneida",
                "countryCode": "US"
            },
            {
                "name": "Overton County",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Park City",
                "countryCode": "US"
            },
            {
                "name": "Parsons",
                "countryCode": "US"
            },
            {
                "name": "Pegram",
                "countryCode": "US"
            },
            {
                "name": "Perry County",
                "countryCode": "US"
            },
            {
                "name": "Pickett County",
                "countryCode": "US"
            },
            {
                "name": "Pigeon Forge",
                "countryCode": "US"
            },
            {
                "name": "Pikeville",
                "countryCode": "US"
            },
            {
                "name": "Pine Crest",
                "countryCode": "US"
            },
            {
                "name": "Piperton",
                "countryCode": "US"
            },
            {
                "name": "Plainview",
                "countryCode": "US"
            },
            {
                "name": "Pleasant View",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Powells Crossroads",
                "countryCode": "US"
            },
            {
                "name": "Pulaski",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Red Bank",
                "countryCode": "US"
            },
            {
                "name": "Red Boiling Springs",
                "countryCode": "US"
            },
            {
                "name": "Rhea County",
                "countryCode": "US"
            },
            {
                "name": "Ridgely",
                "countryCode": "US"
            },
            {
                "name": "Ridgetop",
                "countryCode": "US"
            },
            {
                "name": "Ripley",
                "countryCode": "US"
            },
            {
                "name": "Roan Mountain",
                "countryCode": "US"
            },
            {
                "name": "Roane County",
                "countryCode": "US"
            },
            {
                "name": "Robertson County",
                "countryCode": "US"
            },
            {
                "name": "Rockwood",
                "countryCode": "US"
            },
            {
                "name": "Rocky Top",
                "countryCode": "US"
            },
            {
                "name": "Rogersville",
                "countryCode": "US"
            },
            {
                "name": "Rural Hill",
                "countryCode": "US"
            },
            {
                "name": "Rutherford",
                "countryCode": "US"
            },
            {
                "name": "Rutherford County",
                "countryCode": "US"
            },
            {
                "name": "Rutledge",
                "countryCode": "US"
            },
            {
                "name": "Sale Creek",
                "countryCode": "US"
            },
            {
                "name": "Savannah",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Selmer",
                "countryCode": "US"
            },
            {
                "name": "Sequatchie County",
                "countryCode": "US"
            },
            {
                "name": "Sevier County",
                "countryCode": "US"
            },
            {
                "name": "Sevierville",
                "countryCode": "US"
            },
            {
                "name": "Sewanee",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Shackle Island",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Shelbyville",
                "countryCode": "US"
            },
            {
                "name": "Signal Mountain",
                "countryCode": "US"
            },
            {
                "name": "Smith County",
                "countryCode": "US"
            },
            {
                "name": "Smithville",
                "countryCode": "US"
            },
            {
                "name": "Smyrna",
                "countryCode": "US"
            },
            {
                "name": "Sneedville",
                "countryCode": "US"
            },
            {
                "name": "Soddy-Daisy",
                "countryCode": "US"
            },
            {
                "name": "Somerville",
                "countryCode": "US"
            },
            {
                "name": "South Carthage",
                "countryCode": "US"
            },
            {
                "name": "South Cleveland",
                "countryCode": "US"
            },
            {
                "name": "South Fulton",
                "countryCode": "US"
            },
            {
                "name": "South Pittsburg",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Spring City",
                "countryCode": "US"
            },
            {
                "name": "Spring Hill",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Spurgeon",
                "countryCode": "US"
            },
            {
                "name": "Stewart County",
                "countryCode": "US"
            },
            {
                "name": "Sullivan County",
                "countryCode": "US"
            },
            {
                "name": "Sumner County",
                "countryCode": "US"
            },
            {
                "name": "Surgoinsville",
                "countryCode": "US"
            },
            {
                "name": "Sweetwater",
                "countryCode": "US"
            },
            {
                "name": "Tazewell",
                "countryCode": "US"
            },
            {
                "name": "Tellico Village",
                "countryCode": "US"
            },
            {
                "name": "Tennessee Ridge",
                "countryCode": "US"
            },
            {
                "name": "Thompson's Station",
                "countryCode": "US"
            },
            {
                "name": "Three Way",
                "countryCode": "US"
            },
            {
                "name": "Tipton County",
                "countryCode": "US"
            },
            {
                "name": "Tiptonville",
                "countryCode": "US"
            },
            {
                "name": "Tracy City",
                "countryCode": "US"
            },
            {
                "name": "Trenton",
                "countryCode": "US"
            },
            {
                "name": "Trousdale County",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tullahoma",
                "countryCode": "US"
            },
            {
                "name": "Tusculum",
                "countryCode": "US"
            },
            {
                "name": "Unicoi",
                "countryCode": "US"
            },
            {
                "name": "Unicoi County",
                "countryCode": "US"
            },
            {
                "name": "Union City",
                "countryCode": "US"
            },
            {
                "name": "Union County",
                "countryCode": "US"
            },
            {
                "name": "Unionville",
                "countryCode": "US"
            },
            {
                "name": "Van Buren County",
                "countryCode": "US"
            },
            {
                "name": "Vonore",
                "countryCode": "US"
            },
            {
                "name": "Walden",
                "countryCode": "US"
            },
            {
                "name": "Walnut Hill",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Wartburg",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "Weakley County",
                "countryCode": "US"
            },
            {
                "name": "Westmoreland",
                "countryCode": "US"
            },
            {
                "name": "White Bluff",
                "countryCode": "US"
            },
            {
                "name": "White County",
                "countryCode": "US"
            },
            {
                "name": "White House",
                "countryCode": "US"
            },
            {
                "name": "White Pine",
                "countryCode": "US"
            },
            {
                "name": "Whiteville",
                "countryCode": "US"
            },
            {
                "name": "Whitwell",
                "countryCode": "US"
            },
            {
                "name": "Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Wildwood Lake",
                "countryCode": "US"
            },
            {
                "name": "Williamson County",
                "countryCode": "US"
            },
            {
                "name": "Wilson County",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Woodbury",
                "countryCode": "US"
            },
            {
                "name": "Abernathy",
                "countryCode": "US"
            },
            {
                "name": "Abilene",
                "countryCode": "US"
            },
            {
                "name": "Abram",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Agua Dulce",
                "countryCode": "US"
            },
            {
                "name": "Alamo",
                "countryCode": "US"
            },
            {
                "name": "Alamo Heights",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Aldine",
                "countryCode": "US"
            },
            {
                "name": "Aledo",
                "countryCode": "US"
            },
            {
                "name": "Alice",
                "countryCode": "US"
            },
            {
                "name": "Alief",
                "countryCode": "US"
            },
            {
                "name": "Allen",
                "countryCode": "US"
            },
            {
                "name": "Alpine",
                "countryCode": "US"
            },
            {
                "name": "Alto",
                "countryCode": "US"
            },
            {
                "name": "Alton",
                "countryCode": "US"
            },
            {
                "name": "Alton North (historical)",
                "countryCode": "US"
            },
            {
                "name": "Alvarado",
                "countryCode": "US"
            },
            {
                "name": "Alvin",
                "countryCode": "US"
            },
            {
                "name": "Alvord",
                "countryCode": "US"
            },
            {
                "name": "Amarillo",
                "countryCode": "US"
            },
            {
                "name": "Ames",
                "countryCode": "US"
            },
            {
                "name": "Anahuac",
                "countryCode": "US"
            },
            {
                "name": "Anderson",
                "countryCode": "US"
            },
            {
                "name": "Anderson County",
                "countryCode": "US"
            },
            {
                "name": "Anderson Mill",
                "countryCode": "US"
            },
            {
                "name": "Andrews",
                "countryCode": "US"
            },
            {
                "name": "Andrews County",
                "countryCode": "US"
            },
            {
                "name": "Angelina County",
                "countryCode": "US"
            },
            {
                "name": "Angleton",
                "countryCode": "US"
            },
            {
                "name": "Anna",
                "countryCode": "US"
            },
            {
                "name": "Annetta",
                "countryCode": "US"
            },
            {
                "name": "Anson",
                "countryCode": "US"
            },
            {
                "name": "Anthony",
                "countryCode": "US"
            },
            {
                "name": "Anton",
                "countryCode": "US"
            },
            {
                "name": "Aransas County",
                "countryCode": "US"
            },
            {
                "name": "Aransas Pass",
                "countryCode": "US"
            },
            {
                "name": "Archer City",
                "countryCode": "US"
            },
            {
                "name": "Archer County",
                "countryCode": "US"
            },
            {
                "name": "Arcola",
                "countryCode": "US"
            },
            {
                "name": "Argyle",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Armstrong County",
                "countryCode": "US"
            },
            {
                "name": "Asherton",
                "countryCode": "US"
            },
            {
                "name": "Aspermont",
                "countryCode": "US"
            },
            {
                "name": "Atascocita",
                "countryCode": "US"
            },
            {
                "name": "Atascosa County",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Atlanta",
                "countryCode": "US"
            },
            {
                "name": "Aubrey",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Austin",
                "countryCode": "US"
            },
            {
                "name": "Austin County",
                "countryCode": "US"
            },
            {
                "name": "Azle",
                "countryCode": "US"
            },
            {
                "name": "Bacliff",
                "countryCode": "US"
            },
            {
                "name": "Bailey County",
                "countryCode": "US"
            },
            {
                "name": "Baird",
                "countryCode": "US"
            },
            {
                "name": "Balch Springs",
                "countryCode": "US"
            },
            {
                "name": "Balcones Heights",
                "countryCode": "US"
            },
            {
                "name": "Ballinger",
                "countryCode": "US"
            },
            {
                "name": "Bandera",
                "countryCode": "US"
            },
            {
                "name": "Bandera County",
                "countryCode": "US"
            },
            {
                "name": "Bangs",
                "countryCode": "US"
            },
            {
                "name": "Barrett",
                "countryCode": "US"
            },
            {
                "name": "Bartlett",
                "countryCode": "US"
            },
            {
                "name": "Barton Creek",
                "countryCode": "US"
            },
            {
                "name": "Bartonville",
                "countryCode": "US"
            },
            {
                "name": "Bastrop",
                "countryCode": "US"
            },
            {
                "name": "Bastrop County",
                "countryCode": "US"
            },
            {
                "name": "Batesville",
                "countryCode": "US"
            },
            {
                "name": "Bay City",
                "countryCode": "US"
            },
            {
                "name": "Baylor County",
                "countryCode": "US"
            },
            {
                "name": "Bayou Vista",
                "countryCode": "US"
            },
            {
                "name": "Baytown",
                "countryCode": "US"
            },
            {
                "name": "Beach City",
                "countryCode": "US"
            },
            {
                "name": "Beaumont",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Bee Cave",
                "countryCode": "US"
            },
            {
                "name": "Bee County",
                "countryCode": "US"
            },
            {
                "name": "Beeville",
                "countryCode": "US"
            },
            {
                "name": "Bell County",
                "countryCode": "US"
            },
            {
                "name": "Bellaire",
                "countryCode": "US"
            },
            {
                "name": "Bellmead",
                "countryCode": "US"
            },
            {
                "name": "Bells",
                "countryCode": "US"
            },
            {
                "name": "Bellville",
                "countryCode": "US"
            },
            {
                "name": "Belton",
                "countryCode": "US"
            },
            {
                "name": "Benavides",
                "countryCode": "US"
            },
            {
                "name": "Benbrook",
                "countryCode": "US"
            },
            {
                "name": "Benjamin",
                "countryCode": "US"
            },
            {
                "name": "Berryville",
                "countryCode": "US"
            },
            {
                "name": "Bertram",
                "countryCode": "US"
            },
            {
                "name": "Beverly",
                "countryCode": "US"
            },
            {
                "name": "Beverly Hills",
                "countryCode": "US"
            },
            {
                "name": "Bevil Oaks",
                "countryCode": "US"
            },
            {
                "name": "Bexar County",
                "countryCode": "US"
            },
            {
                "name": "Big Lake",
                "countryCode": "US"
            },
            {
                "name": "Big Sandy",
                "countryCode": "US"
            },
            {
                "name": "Big Spring",
                "countryCode": "US"
            },
            {
                "name": "Bishop",
                "countryCode": "US"
            },
            {
                "name": "Blanco",
                "countryCode": "US"
            },
            {
                "name": "Blanco County",
                "countryCode": "US"
            },
            {
                "name": "Bloomington",
                "countryCode": "US"
            },
            {
                "name": "Blossom",
                "countryCode": "US"
            },
            {
                "name": "Blue Mound",
                "countryCode": "US"
            },
            {
                "name": "Boerne",
                "countryCode": "US"
            },
            {
                "name": "Bogata",
                "countryCode": "US"
            },
            {
                "name": "Boling",
                "countryCode": "US"
            },
            {
                "name": "Bolivar Peninsula",
                "countryCode": "US"
            },
            {
                "name": "Bonham",
                "countryCode": "US"
            },
            {
                "name": "Booker",
                "countryCode": "US"
            },
            {
                "name": "Borden County",
                "countryCode": "US"
            },
            {
                "name": "Borger",
                "countryCode": "US"
            },
            {
                "name": "Bosque County",
                "countryCode": "US"
            },
            {
                "name": "Bovina",
                "countryCode": "US"
            },
            {
                "name": "Bowie",
                "countryCode": "US"
            },
            {
                "name": "Bowie County",
                "countryCode": "US"
            },
            {
                "name": "Boyd",
                "countryCode": "US"
            },
            {
                "name": "Brackettville",
                "countryCode": "US"
            },
            {
                "name": "Brady",
                "countryCode": "US"
            },
            {
                "name": "Brazoria",
                "countryCode": "US"
            },
            {
                "name": "Brazoria County",
                "countryCode": "US"
            },
            {
                "name": "Brazos County",
                "countryCode": "US"
            },
            {
                "name": "Breckenridge",
                "countryCode": "US"
            },
            {
                "name": "Brenham",
                "countryCode": "US"
            },
            {
                "name": "Brewster County",
                "countryCode": "US"
            },
            {
                "name": "Briar",
                "countryCode": "US"
            },
            {
                "name": "Briarcliff",
                "countryCode": "US"
            },
            {
                "name": "Bridge City",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Briscoe County",
                "countryCode": "US"
            },
            {
                "name": "Brooks County",
                "countryCode": "US"
            },
            {
                "name": "Brookshire",
                "countryCode": "US"
            },
            {
                "name": "Brookside Village",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Brownfield",
                "countryCode": "US"
            },
            {
                "name": "Brownsboro",
                "countryCode": "US"
            },
            {
                "name": "Brownsville",
                "countryCode": "US"
            },
            {
                "name": "Brownwood",
                "countryCode": "US"
            },
            {
                "name": "Bruceville-Eddy",
                "countryCode": "US"
            },
            {
                "name": "Brushy Creek",
                "countryCode": "US"
            },
            {
                "name": "Bryan",
                "countryCode": "US"
            },
            {
                "name": "Buchanan Dam",
                "countryCode": "US"
            },
            {
                "name": "Buda",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Bullard",
                "countryCode": "US"
            },
            {
                "name": "Bulverde",
                "countryCode": "US"
            },
            {
                "name": "Buna",
                "countryCode": "US"
            },
            {
                "name": "Bunker Hill Village",
                "countryCode": "US"
            },
            {
                "name": "Burkburnett",
                "countryCode": "US"
            },
            {
                "name": "Burleson",
                "countryCode": "US"
            },
            {
                "name": "Burleson County",
                "countryCode": "US"
            },
            {
                "name": "Burnet",
                "countryCode": "US"
            },
            {
                "name": "Burnet County",
                "countryCode": "US"
            },
            {
                "name": "Bushland",
                "countryCode": "US"
            },
            {
                "name": "Cactus",
                "countryCode": "US"
            },
            {
                "name": "Caddo Mills",
                "countryCode": "US"
            },
            {
                "name": "Caldwell",
                "countryCode": "US"
            },
            {
                "name": "Caldwell County",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Callahan County",
                "countryCode": "US"
            },
            {
                "name": "Calvert",
                "countryCode": "US"
            },
            {
                "name": "Cameron",
                "countryCode": "US"
            },
            {
                "name": "Cameron County",
                "countryCode": "US"
            },
            {
                "name": "Cameron Park",
                "countryCode": "US"
            },
            {
                "name": "Cameron Park Colonia",
                "countryCode": "US"
            },
            {
                "name": "Camp County",
                "countryCode": "US"
            },
            {
                "name": "Camp Swift",
                "countryCode": "US"
            },
            {
                "name": "Canadian",
                "countryCode": "US"
            },
            {
                "name": "Canton",
                "countryCode": "US"
            },
            {
                "name": "Canutillo",
                "countryCode": "US"
            },
            {
                "name": "Canyon",
                "countryCode": "US"
            },
            {
                "name": "Canyon Lake",
                "countryCode": "US"
            },
            {
                "name": "Carrizo Springs",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Carson County",
                "countryCode": "US"
            },
            {
                "name": "Carthage",
                "countryCode": "US"
            },
            {
                "name": "Cass County",
                "countryCode": "US"
            },
            {
                "name": "Castle Hills",
                "countryCode": "US"
            },
            {
                "name": "Castro County",
                "countryCode": "US"
            },
            {
                "name": "Castroville",
                "countryCode": "US"
            },
            {
                "name": "Cedar Hill",
                "countryCode": "US"
            },
            {
                "name": "Cedar Park",
                "countryCode": "US"
            },
            {
                "name": "Celina",
                "countryCode": "US"
            },
            {
                "name": "Center",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Central Gardens",
                "countryCode": "US"
            },
            {
                "name": "Chambers County",
                "countryCode": "US"
            },
            {
                "name": "Chandler",
                "countryCode": "US"
            },
            {
                "name": "Channelview",
                "countryCode": "US"
            },
            {
                "name": "Channing",
                "countryCode": "US"
            },
            {
                "name": "Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Cherokee County",
                "countryCode": "US"
            },
            {
                "name": "Chico",
                "countryCode": "US"
            },
            {
                "name": "Childress",
                "countryCode": "US"
            },
            {
                "name": "Childress County",
                "countryCode": "US"
            },
            {
                "name": "China",
                "countryCode": "US"
            },
            {
                "name": "China Grove",
                "countryCode": "US"
            },
            {
                "name": "Cibolo",
                "countryCode": "US"
            },
            {
                "name": "Cienegas Terrace",
                "countryCode": "US"
            },
            {
                "name": "Cinco Ranch",
                "countryCode": "US"
            },
            {
                "name": "Circle D-KC Estates",
                "countryCode": "US"
            },
            {
                "name": "Cisco",
                "countryCode": "US"
            },
            {
                "name": "Citrus City",
                "countryCode": "US"
            },
            {
                "name": "Clarendon",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Claude",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake Shores",
                "countryCode": "US"
            },
            {
                "name": "Cleburne",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clifton",
                "countryCode": "US"
            },
            {
                "name": "Clint",
                "countryCode": "US"
            },
            {
                "name": "Cloverleaf",
                "countryCode": "US"
            },
            {
                "name": "Clute",
                "countryCode": "US"
            },
            {
                "name": "Clyde",
                "countryCode": "US"
            },
            {
                "name": "Cochran County",
                "countryCode": "US"
            },
            {
                "name": "Cockrell Hill",
                "countryCode": "US"
            },
            {
                "name": "Coke County",
                "countryCode": "US"
            },
            {
                "name": "Coldspring",
                "countryCode": "US"
            },
            {
                "name": "Coleman",
                "countryCode": "US"
            },
            {
                "name": "Coleman County",
                "countryCode": "US"
            },
            {
                "name": "College Station",
                "countryCode": "US"
            },
            {
                "name": "Colleyville",
                "countryCode": "US"
            },
            {
                "name": "Collin County",
                "countryCode": "US"
            },
            {
                "name": "Collingsworth County",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Colorado City",
                "countryCode": "US"
            },
            {
                "name": "Colorado County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Comal County",
                "countryCode": "US"
            },
            {
                "name": "Comanche",
                "countryCode": "US"
            },
            {
                "name": "Comanche County",
                "countryCode": "US"
            },
            {
                "name": "Combes",
                "countryCode": "US"
            },
            {
                "name": "Combine",
                "countryCode": "US"
            },
            {
                "name": "Comfort",
                "countryCode": "US"
            },
            {
                "name": "Commerce",
                "countryCode": "US"
            },
            {
                "name": "Concho County",
                "countryCode": "US"
            },
            {
                "name": "Conroe",
                "countryCode": "US"
            },
            {
                "name": "Converse",
                "countryCode": "US"
            },
            {
                "name": "Cooke County",
                "countryCode": "US"
            },
            {
                "name": "Cooper",
                "countryCode": "US"
            },
            {
                "name": "Coppell",
                "countryCode": "US"
            },
            {
                "name": "Copper Canyon",
                "countryCode": "US"
            },
            {
                "name": "Copperas Cove",
                "countryCode": "US"
            },
            {
                "name": "Corinth",
                "countryCode": "US"
            },
            {
                "name": "Corpus Christi",
                "countryCode": "US"
            },
            {
                "name": "Corrigan",
                "countryCode": "US"
            },
            {
                "name": "Corsicana",
                "countryCode": "US"
            },
            {
                "name": "Coryell County",
                "countryCode": "US"
            },
            {
                "name": "Cottle County",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood Shores",
                "countryCode": "US"
            },
            {
                "name": "Cotulla",
                "countryCode": "US"
            },
            {
                "name": "Crandall",
                "countryCode": "US"
            },
            {
                "name": "Crane",
                "countryCode": "US"
            },
            {
                "name": "Crane County",
                "countryCode": "US"
            },
            {
                "name": "Crockett",
                "countryCode": "US"
            },
            {
                "name": "Crockett County",
                "countryCode": "US"
            },
            {
                "name": "Crosby",
                "countryCode": "US"
            },
            {
                "name": "Crosby County",
                "countryCode": "US"
            },
            {
                "name": "Crosbyton",
                "countryCode": "US"
            },
            {
                "name": "Cross Mountain",
                "countryCode": "US"
            },
            {
                "name": "Crowell",
                "countryCode": "US"
            },
            {
                "name": "Crowley",
                "countryCode": "US"
            },
            {
                "name": "Crystal City",
                "countryCode": "US"
            },
            {
                "name": "Cuero",
                "countryCode": "US"
            },
            {
                "name": "Culberson County",
                "countryCode": "US"
            },
            {
                "name": "Cut and Shoot",
                "countryCode": "US"
            },
            {
                "name": "Cypress",
                "countryCode": "US"
            },
            {
                "name": "César Chávez",
                "countryCode": "US"
            },
            {
                "name": "Daingerfield",
                "countryCode": "US"
            },
            {
                "name": "Dalhart",
                "countryCode": "US"
            },
            {
                "name": "Dallam County",
                "countryCode": "US"
            },
            {
                "name": "Dallas",
                "countryCode": "US"
            },
            {
                "name": "Dallas County",
                "countryCode": "US"
            },
            {
                "name": "Dalworthington Gardens",
                "countryCode": "US"
            },
            {
                "name": "Danbury",
                "countryCode": "US"
            },
            {
                "name": "Dawson County",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "De Kalb",
                "countryCode": "US"
            },
            {
                "name": "De Leon",
                "countryCode": "US"
            },
            {
                "name": "DeCordova",
                "countryCode": "US"
            },
            {
                "name": "DeSoto",
                "countryCode": "US"
            },
            {
                "name": "DeWitt County",
                "countryCode": "US"
            },
            {
                "name": "Deaf Smith County",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Del Rio",
                "countryCode": "US"
            },
            {
                "name": "Delta County",
                "countryCode": "US"
            },
            {
                "name": "Denison",
                "countryCode": "US"
            },
            {
                "name": "Denton",
                "countryCode": "US"
            },
            {
                "name": "Denton County",
                "countryCode": "US"
            },
            {
                "name": "Denver City",
                "countryCode": "US"
            },
            {
                "name": "Devine",
                "countryCode": "US"
            },
            {
                "name": "Deweyville",
                "countryCode": "US"
            },
            {
                "name": "Diboll",
                "countryCode": "US"
            },
            {
                "name": "Dickens",
                "countryCode": "US"
            },
            {
                "name": "Dickens County",
                "countryCode": "US"
            },
            {
                "name": "Dickinson",
                "countryCode": "US"
            },
            {
                "name": "Dilley",
                "countryCode": "US"
            },
            {
                "name": "Dimmit County",
                "countryCode": "US"
            },
            {
                "name": "Dimmitt",
                "countryCode": "US"
            },
            {
                "name": "Doffing",
                "countryCode": "US"
            },
            {
                "name": "Donley County",
                "countryCode": "US"
            },
            {
                "name": "Donna",
                "countryCode": "US"
            },
            {
                "name": "Doolittle",
                "countryCode": "US"
            },
            {
                "name": "Double Oak",
                "countryCode": "US"
            },
            {
                "name": "Dripping Springs",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Dumas",
                "countryCode": "US"
            },
            {
                "name": "Duncanville",
                "countryCode": "US"
            },
            {
                "name": "Duval County",
                "countryCode": "US"
            },
            {
                "name": "Eagle Lake",
                "countryCode": "US"
            },
            {
                "name": "Eagle Mountain",
                "countryCode": "US"
            },
            {
                "name": "Eagle Pass",
                "countryCode": "US"
            },
            {
                "name": "Early",
                "countryCode": "US"
            },
            {
                "name": "Earth",
                "countryCode": "US"
            },
            {
                "name": "East Bernard",
                "countryCode": "US"
            },
            {
                "name": "Eastland",
                "countryCode": "US"
            },
            {
                "name": "Eastland County",
                "countryCode": "US"
            },
            {
                "name": "Ector County",
                "countryCode": "US"
            },
            {
                "name": "Edcouch",
                "countryCode": "US"
            },
            {
                "name": "Eden",
                "countryCode": "US"
            },
            {
                "name": "Edgecliff Village",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edinburg",
                "countryCode": "US"
            },
            {
                "name": "Edna",
                "countryCode": "US"
            },
            {
                "name": "Edwards County",
                "countryCode": "US"
            },
            {
                "name": "Eidson Road",
                "countryCode": "US"
            },
            {
                "name": "El Campo",
                "countryCode": "US"
            },
            {
                "name": "El Cenizo",
                "countryCode": "US"
            },
            {
                "name": "El Lago",
                "countryCode": "US"
            },
            {
                "name": "El Paso",
                "countryCode": "US"
            },
            {
                "name": "El Paso County",
                "countryCode": "US"
            },
            {
                "name": "Eldorado",
                "countryCode": "US"
            },
            {
                "name": "Electra",
                "countryCode": "US"
            },
            {
                "name": "Elgin",
                "countryCode": "US"
            },
            {
                "name": "Elkhart",
                "countryCode": "US"
            },
            {
                "name": "Ellis County",
                "countryCode": "US"
            },
            {
                "name": "Elm Creek",
                "countryCode": "US"
            },
            {
                "name": "Elmendorf",
                "countryCode": "US"
            },
            {
                "name": "Elsa",
                "countryCode": "US"
            },
            {
                "name": "Emory",
                "countryCode": "US"
            },
            {
                "name": "Encantada-Ranchito-El Calaboz",
                "countryCode": "US"
            },
            {
                "name": "Ennis",
                "countryCode": "US"
            },
            {
                "name": "Erath County",
                "countryCode": "US"
            },
            {
                "name": "Escobares",
                "countryCode": "US"
            },
            {
                "name": "Euless",
                "countryCode": "US"
            },
            {
                "name": "Evadale",
                "countryCode": "US"
            },
            {
                "name": "Everman",
                "countryCode": "US"
            },
            {
                "name": "Fabens",
                "countryCode": "US"
            },
            {
                "name": "Fair Oaks Ranch",
                "countryCode": "US"
            },
            {
                "name": "Fairchilds",
                "countryCode": "US"
            },
            {
                "name": "Fairfield",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Falcon Lake Estates",
                "countryCode": "US"
            },
            {
                "name": "Falfurrias",
                "countryCode": "US"
            },
            {
                "name": "Falls County",
                "countryCode": "US"
            },
            {
                "name": "Fannett",
                "countryCode": "US"
            },
            {
                "name": "Fannin County",
                "countryCode": "US"
            },
            {
                "name": "Farmers Branch",
                "countryCode": "US"
            },
            {
                "name": "Farmersville",
                "countryCode": "US"
            },
            {
                "name": "Farwell",
                "countryCode": "US"
            },
            {
                "name": "Fate",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Ferris",
                "countryCode": "US"
            },
            {
                "name": "Fifth Street",
                "countryCode": "US"
            },
            {
                "name": "Fisher County",
                "countryCode": "US"
            },
            {
                "name": "Flatonia",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Floresville",
                "countryCode": "US"
            },
            {
                "name": "Flower Mound",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Floydada",
                "countryCode": "US"
            },
            {
                "name": "Foard County",
                "countryCode": "US"
            },
            {
                "name": "Forest Hill",
                "countryCode": "US"
            },
            {
                "name": "Forney",
                "countryCode": "US"
            },
            {
                "name": "Fort Bend County",
                "countryCode": "US"
            },
            {
                "name": "Fort Bliss",
                "countryCode": "US"
            },
            {
                "name": "Fort Clark Springs",
                "countryCode": "US"
            },
            {
                "name": "Fort Davis",
                "countryCode": "US"
            },
            {
                "name": "Fort Hancock",
                "countryCode": "US"
            },
            {
                "name": "Fort Hood",
                "countryCode": "US"
            },
            {
                "name": "Fort Stockton",
                "countryCode": "US"
            },
            {
                "name": "Fort Worth",
                "countryCode": "US"
            },
            {
                "name": "Four Corners",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frankston",
                "countryCode": "US"
            },
            {
                "name": "Fredericksburg",
                "countryCode": "US"
            },
            {
                "name": "Freeport",
                "countryCode": "US"
            },
            {
                "name": "Freer",
                "countryCode": "US"
            },
            {
                "name": "Freestone County",
                "countryCode": "US"
            },
            {
                "name": "Fresno",
                "countryCode": "US"
            },
            {
                "name": "Friendswood",
                "countryCode": "US"
            },
            {
                "name": "Frio County",
                "countryCode": "US"
            },
            {
                "name": "Friona",
                "countryCode": "US"
            },
            {
                "name": "Frisco",
                "countryCode": "US"
            },
            {
                "name": "Fritch",
                "countryCode": "US"
            },
            {
                "name": "Fulshear",
                "countryCode": "US"
            },
            {
                "name": "Fulton",
                "countryCode": "US"
            },
            {
                "name": "Gail",
                "countryCode": "US"
            },
            {
                "name": "Gaines County",
                "countryCode": "US"
            },
            {
                "name": "Gainesville",
                "countryCode": "US"
            },
            {
                "name": "Galena Park",
                "countryCode": "US"
            },
            {
                "name": "Galveston",
                "countryCode": "US"
            },
            {
                "name": "Galveston County",
                "countryCode": "US"
            },
            {
                "name": "Ganado",
                "countryCode": "US"
            },
            {
                "name": "Garden City",
                "countryCode": "US"
            },
            {
                "name": "Garden Ridge",
                "countryCode": "US"
            },
            {
                "name": "Gardendale",
                "countryCode": "US"
            },
            {
                "name": "Garfield",
                "countryCode": "US"
            },
            {
                "name": "Garland",
                "countryCode": "US"
            },
            {
                "name": "Garza County",
                "countryCode": "US"
            },
            {
                "name": "Gatesville",
                "countryCode": "US"
            },
            {
                "name": "George West",
                "countryCode": "US"
            },
            {
                "name": "Georgetown",
                "countryCode": "US"
            },
            {
                "name": "Geronimo",
                "countryCode": "US"
            },
            {
                "name": "Gholson",
                "countryCode": "US"
            },
            {
                "name": "Giddings",
                "countryCode": "US"
            },
            {
                "name": "Gillespie County",
                "countryCode": "US"
            },
            {
                "name": "Gilmer",
                "countryCode": "US"
            },
            {
                "name": "Gladewater",
                "countryCode": "US"
            },
            {
                "name": "Glasscock County",
                "countryCode": "US"
            },
            {
                "name": "Glen Rose",
                "countryCode": "US"
            },
            {
                "name": "Glenn Heights",
                "countryCode": "US"
            },
            {
                "name": "Godley",
                "countryCode": "US"
            },
            {
                "name": "Goldthwaite",
                "countryCode": "US"
            },
            {
                "name": "Goliad",
                "countryCode": "US"
            },
            {
                "name": "Goliad County",
                "countryCode": "US"
            },
            {
                "name": "Gonzales",
                "countryCode": "US"
            },
            {
                "name": "Gonzales County",
                "countryCode": "US"
            },
            {
                "name": "Gorman",
                "countryCode": "US"
            },
            {
                "name": "Graham",
                "countryCode": "US"
            },
            {
                "name": "Granbury",
                "countryCode": "US"
            },
            {
                "name": "Grand Prairie",
                "countryCode": "US"
            },
            {
                "name": "Grand Saline",
                "countryCode": "US"
            },
            {
                "name": "Grandview",
                "countryCode": "US"
            },
            {
                "name": "Granger",
                "countryCode": "US"
            },
            {
                "name": "Granite Shoals",
                "countryCode": "US"
            },
            {
                "name": "Grape Creek",
                "countryCode": "US"
            },
            {
                "name": "Grapeland",
                "countryCode": "US"
            },
            {
                "name": "Grapevine",
                "countryCode": "US"
            },
            {
                "name": "Gray County",
                "countryCode": "US"
            },
            {
                "name": "Grayson County",
                "countryCode": "US"
            },
            {
                "name": "Greatwood",
                "countryCode": "US"
            },
            {
                "name": "Green Valley Farms",
                "countryCode": "US"
            },
            {
                "name": "Greenville",
                "countryCode": "US"
            },
            {
                "name": "Gregg County",
                "countryCode": "US"
            },
            {
                "name": "Gregory",
                "countryCode": "US"
            },
            {
                "name": "Grimes County",
                "countryCode": "US"
            },
            {
                "name": "Groesbeck",
                "countryCode": "US"
            },
            {
                "name": "Groves",
                "countryCode": "US"
            },
            {
                "name": "Groveton",
                "countryCode": "US"
            },
            {
                "name": "Gruver",
                "countryCode": "US"
            },
            {
                "name": "Guadalupe County",
                "countryCode": "US"
            },
            {
                "name": "Gun Barrel City",
                "countryCode": "US"
            },
            {
                "name": "Gunter",
                "countryCode": "US"
            },
            {
                "name": "Guthrie",
                "countryCode": "US"
            },
            {
                "name": "Hackberry",
                "countryCode": "US"
            },
            {
                "name": "Hale Center",
                "countryCode": "US"
            },
            {
                "name": "Hale County",
                "countryCode": "US"
            },
            {
                "name": "Hall County",
                "countryCode": "US"
            },
            {
                "name": "Hallettsville",
                "countryCode": "US"
            },
            {
                "name": "Hallsville",
                "countryCode": "US"
            },
            {
                "name": "Haltom City",
                "countryCode": "US"
            },
            {
                "name": "Hamilton",
                "countryCode": "US"
            },
            {
                "name": "Hamilton County",
                "countryCode": "US"
            },
            {
                "name": "Hamlin",
                "countryCode": "US"
            },
            {
                "name": "Hansford County",
                "countryCode": "US"
            },
            {
                "name": "Hardeman County",
                "countryCode": "US"
            },
            {
                "name": "Hardin County",
                "countryCode": "US"
            },
            {
                "name": "Harker Heights",
                "countryCode": "US"
            },
            {
                "name": "Harlingen",
                "countryCode": "US"
            },
            {
                "name": "Harper",
                "countryCode": "US"
            },
            {
                "name": "Harris County",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Hart",
                "countryCode": "US"
            },
            {
                "name": "Hartley County",
                "countryCode": "US"
            },
            {
                "name": "Haskell",
                "countryCode": "US"
            },
            {
                "name": "Haskell County",
                "countryCode": "US"
            },
            {
                "name": "Haslet",
                "countryCode": "US"
            },
            {
                "name": "Hawkins",
                "countryCode": "US"
            },
            {
                "name": "Hays County",
                "countryCode": "US"
            },
            {
                "name": "Hearne",
                "countryCode": "US"
            },
            {
                "name": "Heath",
                "countryCode": "US"
            },
            {
                "name": "Hebbronville",
                "countryCode": "US"
            },
            {
                "name": "Hedwig Village",
                "countryCode": "US"
            },
            {
                "name": "Heidelberg",
                "countryCode": "US"
            },
            {
                "name": "Helotes",
                "countryCode": "US"
            },
            {
                "name": "Hemphill",
                "countryCode": "US"
            },
            {
                "name": "Hemphill County",
                "countryCode": "US"
            },
            {
                "name": "Hempstead",
                "countryCode": "US"
            },
            {
                "name": "Henderson",
                "countryCode": "US"
            },
            {
                "name": "Henderson County",
                "countryCode": "US"
            },
            {
                "name": "Henrietta",
                "countryCode": "US"
            },
            {
                "name": "Hereford",
                "countryCode": "US"
            },
            {
                "name": "Hewitt",
                "countryCode": "US"
            },
            {
                "name": "Hickory Creek",
                "countryCode": "US"
            },
            {
                "name": "Hico",
                "countryCode": "US"
            },
            {
                "name": "Hidalgo",
                "countryCode": "US"
            },
            {
                "name": "Hidalgo County",
                "countryCode": "US"
            },
            {
                "name": "Hideaway",
                "countryCode": "US"
            },
            {
                "name": "Highland Park",
                "countryCode": "US"
            },
            {
                "name": "Highland Village",
                "countryCode": "US"
            },
            {
                "name": "Highlands",
                "countryCode": "US"
            },
            {
                "name": "Hill Country Village",
                "countryCode": "US"
            },
            {
                "name": "Hill County",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hilltop Lakes",
                "countryCode": "US"
            },
            {
                "name": "Hitchcock",
                "countryCode": "US"
            },
            {
                "name": "Hockley County",
                "countryCode": "US"
            },
            {
                "name": "Holiday Lakes",
                "countryCode": "US"
            },
            {
                "name": "Holland",
                "countryCode": "US"
            },
            {
                "name": "Holliday",
                "countryCode": "US"
            },
            {
                "name": "Holly Lake Ranch",
                "countryCode": "US"
            },
            {
                "name": "Hollywood Park",
                "countryCode": "US"
            },
            {
                "name": "Homestead Meadows North",
                "countryCode": "US"
            },
            {
                "name": "Homestead Meadows South",
                "countryCode": "US"
            },
            {
                "name": "Hondo",
                "countryCode": "US"
            },
            {
                "name": "Honey Grove",
                "countryCode": "US"
            },
            {
                "name": "Hood County",
                "countryCode": "US"
            },
            {
                "name": "Hooks",
                "countryCode": "US"
            },
            {
                "name": "Hopkins County",
                "countryCode": "US"
            },
            {
                "name": "Horizon City",
                "countryCode": "US"
            },
            {
                "name": "Hornsby Bend",
                "countryCode": "US"
            },
            {
                "name": "Horseshoe Bay",
                "countryCode": "US"
            },
            {
                "name": "Houston",
                "countryCode": "US"
            },
            {
                "name": "Houston County",
                "countryCode": "US"
            },
            {
                "name": "Howard County",
                "countryCode": "US"
            },
            {
                "name": "Howe",
                "countryCode": "US"
            },
            {
                "name": "Hubbard",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hudson Bend",
                "countryCode": "US"
            },
            {
                "name": "Hudson Oaks",
                "countryCode": "US"
            },
            {
                "name": "Hudspeth County",
                "countryCode": "US"
            },
            {
                "name": "Hughes Springs",
                "countryCode": "US"
            },
            {
                "name": "Humble",
                "countryCode": "US"
            },
            {
                "name": "Hunt County",
                "countryCode": "US"
            },
            {
                "name": "Hunters Creek Village",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Huntsville",
                "countryCode": "US"
            },
            {
                "name": "Hurst",
                "countryCode": "US"
            },
            {
                "name": "Hutchins",
                "countryCode": "US"
            },
            {
                "name": "Hutchinson County",
                "countryCode": "US"
            },
            {
                "name": "Hutto",
                "countryCode": "US"
            },
            {
                "name": "Idalou",
                "countryCode": "US"
            },
            {
                "name": "Indian Hills",
                "countryCode": "US"
            },
            {
                "name": "Inez",
                "countryCode": "US"
            },
            {
                "name": "Ingleside",
                "countryCode": "US"
            },
            {
                "name": "Ingram",
                "countryCode": "US"
            },
            {
                "name": "Iowa Colony",
                "countryCode": "US"
            },
            {
                "name": "Iowa Park",
                "countryCode": "US"
            },
            {
                "name": "Iraan",
                "countryCode": "US"
            },
            {
                "name": "Irion County",
                "countryCode": "US"
            },
            {
                "name": "Irving",
                "countryCode": "US"
            },
            {
                "name": "Italy",
                "countryCode": "US"
            },
            {
                "name": "Itasca",
                "countryCode": "US"
            },
            {
                "name": "Jacinto City",
                "countryCode": "US"
            },
            {
                "name": "Jack County",
                "countryCode": "US"
            },
            {
                "name": "Jacksboro",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jacksonville",
                "countryCode": "US"
            },
            {
                "name": "Jamaica Beach",
                "countryCode": "US"
            },
            {
                "name": "Jarrell",
                "countryCode": "US"
            },
            {
                "name": "Jasper",
                "countryCode": "US"
            },
            {
                "name": "Jasper County",
                "countryCode": "US"
            },
            {
                "name": "Jayton",
                "countryCode": "US"
            },
            {
                "name": "Jeff Davis County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Jersey Village",
                "countryCode": "US"
            },
            {
                "name": "Jewett",
                "countryCode": "US"
            },
            {
                "name": "Jim Hogg County",
                "countryCode": "US"
            },
            {
                "name": "Jim Wells County",
                "countryCode": "US"
            },
            {
                "name": "Johnson City",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Jollyville",
                "countryCode": "US"
            },
            {
                "name": "Jones County",
                "countryCode": "US"
            },
            {
                "name": "Jones Creek",
                "countryCode": "US"
            },
            {
                "name": "Jonestown",
                "countryCode": "US"
            },
            {
                "name": "Josephine",
                "countryCode": "US"
            },
            {
                "name": "Joshua",
                "countryCode": "US"
            },
            {
                "name": "Jourdanton",
                "countryCode": "US"
            },
            {
                "name": "Junction",
                "countryCode": "US"
            },
            {
                "name": "Justin",
                "countryCode": "US"
            },
            {
                "name": "Karnes City",
                "countryCode": "US"
            },
            {
                "name": "Karnes County",
                "countryCode": "US"
            },
            {
                "name": "Katy",
                "countryCode": "US"
            },
            {
                "name": "Kaufman",
                "countryCode": "US"
            },
            {
                "name": "Kaufman County",
                "countryCode": "US"
            },
            {
                "name": "Keene",
                "countryCode": "US"
            },
            {
                "name": "Keller",
                "countryCode": "US"
            },
            {
                "name": "Kemah",
                "countryCode": "US"
            },
            {
                "name": "Kemp",
                "countryCode": "US"
            },
            {
                "name": "Kempner",
                "countryCode": "US"
            },
            {
                "name": "Kendall County",
                "countryCode": "US"
            },
            {
                "name": "Kenedy",
                "countryCode": "US"
            },
            {
                "name": "Kenedy County",
                "countryCode": "US"
            },
            {
                "name": "Kennedale",
                "countryCode": "US"
            },
            {
                "name": "Kent County",
                "countryCode": "US"
            },
            {
                "name": "Kerens",
                "countryCode": "US"
            },
            {
                "name": "Kermit",
                "countryCode": "US"
            },
            {
                "name": "Kerr County",
                "countryCode": "US"
            },
            {
                "name": "Kerrville",
                "countryCode": "US"
            },
            {
                "name": "Kilgore",
                "countryCode": "US"
            },
            {
                "name": "Killeen",
                "countryCode": "US"
            },
            {
                "name": "Kimble County",
                "countryCode": "US"
            },
            {
                "name": "King County",
                "countryCode": "US"
            },
            {
                "name": "Kingsland",
                "countryCode": "US"
            },
            {
                "name": "Kingsville",
                "countryCode": "US"
            },
            {
                "name": "Kingwood Area",
                "countryCode": "US"
            },
            {
                "name": "Kinney County",
                "countryCode": "US"
            },
            {
                "name": "Kirby",
                "countryCode": "US"
            },
            {
                "name": "Kirbyville",
                "countryCode": "US"
            },
            {
                "name": "Kleberg County",
                "countryCode": "US"
            },
            {
                "name": "Knox City",
                "countryCode": "US"
            },
            {
                "name": "Knox County",
                "countryCode": "US"
            },
            {
                "name": "Kountze",
                "countryCode": "US"
            },
            {
                "name": "Krugerville",
                "countryCode": "US"
            },
            {
                "name": "Krum",
                "countryCode": "US"
            },
            {
                "name": "Kyle",
                "countryCode": "US"
            },
            {
                "name": "La Blanca",
                "countryCode": "US"
            },
            {
                "name": "La Coste",
                "countryCode": "US"
            },
            {
                "name": "La Feria",
                "countryCode": "US"
            },
            {
                "name": "La Grange",
                "countryCode": "US"
            },
            {
                "name": "La Grulla",
                "countryCode": "US"
            },
            {
                "name": "La Homa",
                "countryCode": "US"
            },
            {
                "name": "La Joya",
                "countryCode": "US"
            },
            {
                "name": "La Marque",
                "countryCode": "US"
            },
            {
                "name": "La Paloma",
                "countryCode": "US"
            },
            {
                "name": "La Porte",
                "countryCode": "US"
            },
            {
                "name": "La Pryor",
                "countryCode": "US"
            },
            {
                "name": "La Salle County",
                "countryCode": "US"
            },
            {
                "name": "La Vernia",
                "countryCode": "US"
            },
            {
                "name": "La Villa",
                "countryCode": "US"
            },
            {
                "name": "Lackland Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Lacy-Lakeview",
                "countryCode": "US"
            },
            {
                "name": "Lago Vista",
                "countryCode": "US"
            },
            {
                "name": "Laguna Heights",
                "countryCode": "US"
            },
            {
                "name": "Laguna Park",
                "countryCode": "US"
            },
            {
                "name": "Laguna Vista",
                "countryCode": "US"
            },
            {
                "name": "Lake Brownwood",
                "countryCode": "US"
            },
            {
                "name": "Lake Dallas",
                "countryCode": "US"
            },
            {
                "name": "Lake Dunlap",
                "countryCode": "US"
            },
            {
                "name": "Lake Jackson",
                "countryCode": "US"
            },
            {
                "name": "Lake Kiowa",
                "countryCode": "US"
            },
            {
                "name": "Lake Worth",
                "countryCode": "US"
            },
            {
                "name": "Lakehills",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lakeway",
                "countryCode": "US"
            },
            {
                "name": "Lamar County",
                "countryCode": "US"
            },
            {
                "name": "Lamb County",
                "countryCode": "US"
            },
            {
                "name": "Lamesa",
                "countryCode": "US"
            },
            {
                "name": "Lampasas",
                "countryCode": "US"
            },
            {
                "name": "Lampasas County",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Lantana",
                "countryCode": "US"
            },
            {
                "name": "Laredo",
                "countryCode": "US"
            },
            {
                "name": "Las Lomas",
                "countryCode": "US"
            },
            {
                "name": "Las Palmas II",
                "countryCode": "US"
            },
            {
                "name": "Las Quintas Fronterizas",
                "countryCode": "US"
            },
            {
                "name": "Las Quintas Fronterizas Colonia",
                "countryCode": "US"
            },
            {
                "name": "Lasara",
                "countryCode": "US"
            },
            {
                "name": "Laughlin Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Laureles",
                "countryCode": "US"
            },
            {
                "name": "Lavaca County",
                "countryCode": "US"
            },
            {
                "name": "Lavon",
                "countryCode": "US"
            },
            {
                "name": "League City",
                "countryCode": "US"
            },
            {
                "name": "Leakey",
                "countryCode": "US"
            },
            {
                "name": "Leander",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leon County",
                "countryCode": "US"
            },
            {
                "name": "Leon Valley",
                "countryCode": "US"
            },
            {
                "name": "Leonard",
                "countryCode": "US"
            },
            {
                "name": "Levelland",
                "countryCode": "US"
            },
            {
                "name": "Lewisville",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Liberty City",
                "countryCode": "US"
            },
            {
                "name": "Liberty County",
                "countryCode": "US"
            },
            {
                "name": "Liberty Hill",
                "countryCode": "US"
            },
            {
                "name": "Limestone County",
                "countryCode": "US"
            },
            {
                "name": "Lindale",
                "countryCode": "US"
            },
            {
                "name": "Linden",
                "countryCode": "US"
            },
            {
                "name": "Lindsay",
                "countryCode": "US"
            },
            {
                "name": "Lipscomb County",
                "countryCode": "US"
            },
            {
                "name": "Little Elm",
                "countryCode": "US"
            },
            {
                "name": "Little River-Academy",
                "countryCode": "US"
            },
            {
                "name": "Littlefield",
                "countryCode": "US"
            },
            {
                "name": "Live Oak",
                "countryCode": "US"
            },
            {
                "name": "Live Oak County",
                "countryCode": "US"
            },
            {
                "name": "Livingston",
                "countryCode": "US"
            },
            {
                "name": "Llano",
                "countryCode": "US"
            },
            {
                "name": "Llano County",
                "countryCode": "US"
            },
            {
                "name": "Llano Grande",
                "countryCode": "US"
            },
            {
                "name": "Lockhart",
                "countryCode": "US"
            },
            {
                "name": "Lockney",
                "countryCode": "US"
            },
            {
                "name": "Lone Star",
                "countryCode": "US"
            },
            {
                "name": "Longview",
                "countryCode": "US"
            },
            {
                "name": "Lopezville",
                "countryCode": "US"
            },
            {
                "name": "Lorena",
                "countryCode": "US"
            },
            {
                "name": "Lorenzo",
                "countryCode": "US"
            },
            {
                "name": "Los Fresnos",
                "countryCode": "US"
            },
            {
                "name": "Los Indios",
                "countryCode": "US"
            },
            {
                "name": "Lost Creek",
                "countryCode": "US"
            },
            {
                "name": "Loving County",
                "countryCode": "US"
            },
            {
                "name": "Lowry Crossing",
                "countryCode": "US"
            },
            {
                "name": "Lubbock",
                "countryCode": "US"
            },
            {
                "name": "Lubbock County",
                "countryCode": "US"
            },
            {
                "name": "Lucas",
                "countryCode": "US"
            },
            {
                "name": "Lufkin",
                "countryCode": "US"
            },
            {
                "name": "Luling",
                "countryCode": "US"
            },
            {
                "name": "Lumberton",
                "countryCode": "US"
            },
            {
                "name": "Lyford",
                "countryCode": "US"
            },
            {
                "name": "Lynn County",
                "countryCode": "US"
            },
            {
                "name": "Lytle",
                "countryCode": "US"
            },
            {
                "name": "Mabank",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Madisonville",
                "countryCode": "US"
            },
            {
                "name": "Magnolia",
                "countryCode": "US"
            },
            {
                "name": "Malakoff",
                "countryCode": "US"
            },
            {
                "name": "Manchaca",
                "countryCode": "US"
            },
            {
                "name": "Manor",
                "countryCode": "US"
            },
            {
                "name": "Mansfield",
                "countryCode": "US"
            },
            {
                "name": "Manvel",
                "countryCode": "US"
            },
            {
                "name": "Marble Falls",
                "countryCode": "US"
            },
            {
                "name": "Marfa",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Markham",
                "countryCode": "US"
            },
            {
                "name": "Marlin",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Mart",
                "countryCode": "US"
            },
            {
                "name": "Martin County",
                "countryCode": "US"
            },
            {
                "name": "Martindale",
                "countryCode": "US"
            },
            {
                "name": "Mason",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "Matador",
                "countryCode": "US"
            },
            {
                "name": "Matagorda County",
                "countryCode": "US"
            },
            {
                "name": "Mathis",
                "countryCode": "US"
            },
            {
                "name": "Maud",
                "countryCode": "US"
            },
            {
                "name": "Mauriceville",
                "countryCode": "US"
            },
            {
                "name": "Maverick County",
                "countryCode": "US"
            },
            {
                "name": "McAllen",
                "countryCode": "US"
            },
            {
                "name": "McCamey",
                "countryCode": "US"
            },
            {
                "name": "McCulloch County",
                "countryCode": "US"
            },
            {
                "name": "McGregor",
                "countryCode": "US"
            },
            {
                "name": "McKinney",
                "countryCode": "US"
            },
            {
                "name": "McLendon-Chisholm",
                "countryCode": "US"
            },
            {
                "name": "McLennan County",
                "countryCode": "US"
            },
            {
                "name": "McMullen County",
                "countryCode": "US"
            },
            {
                "name": "McQueeney",
                "countryCode": "US"
            },
            {
                "name": "Meadowlakes",
                "countryCode": "US"
            },
            {
                "name": "Meadows Place",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Medina County",
                "countryCode": "US"
            },
            {
                "name": "Melissa",
                "countryCode": "US"
            },
            {
                "name": "Memphis",
                "countryCode": "US"
            },
            {
                "name": "Menard",
                "countryCode": "US"
            },
            {
                "name": "Menard County",
                "countryCode": "US"
            },
            {
                "name": "Mentone",
                "countryCode": "US"
            },
            {
                "name": "Mercedes",
                "countryCode": "US"
            },
            {
                "name": "Meridian",
                "countryCode": "US"
            },
            {
                "name": "Merkel",
                "countryCode": "US"
            },
            {
                "name": "Mertzon",
                "countryCode": "US"
            },
            {
                "name": "Mesquite",
                "countryCode": "US"
            },
            {
                "name": "Mexia",
                "countryCode": "US"
            },
            {
                "name": "Miami",
                "countryCode": "US"
            },
            {
                "name": "Midland",
                "countryCode": "US"
            },
            {
                "name": "Midland County",
                "countryCode": "US"
            },
            {
                "name": "Midlothian",
                "countryCode": "US"
            },
            {
                "name": "Midway North",
                "countryCode": "US"
            },
            {
                "name": "Midway South",
                "countryCode": "US"
            },
            {
                "name": "Mila Doce",
                "countryCode": "US"
            },
            {
                "name": "Milam",
                "countryCode": "US"
            },
            {
                "name": "Milam County",
                "countryCode": "US"
            },
            {
                "name": "Mills County",
                "countryCode": "US"
            },
            {
                "name": "Mineola",
                "countryCode": "US"
            },
            {
                "name": "Mineral Wells",
                "countryCode": "US"
            },
            {
                "name": "Mission",
                "countryCode": "US"
            },
            {
                "name": "Mission Bend",
                "countryCode": "US"
            },
            {
                "name": "Missouri City",
                "countryCode": "US"
            },
            {
                "name": "Mitchell County",
                "countryCode": "US"
            },
            {
                "name": "Monahans",
                "countryCode": "US"
            },
            {
                "name": "Mont Belvieu",
                "countryCode": "US"
            },
            {
                "name": "Montague",
                "countryCode": "US"
            },
            {
                "name": "Montague County",
                "countryCode": "US"
            },
            {
                "name": "Monte Alto",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Moody",
                "countryCode": "US"
            },
            {
                "name": "Moore County",
                "countryCode": "US"
            },
            {
                "name": "Morgans Point Resort",
                "countryCode": "US"
            },
            {
                "name": "Morris County",
                "countryCode": "US"
            },
            {
                "name": "Morton",
                "countryCode": "US"
            },
            {
                "name": "Motley County",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Muenster",
                "countryCode": "US"
            },
            {
                "name": "Muleshoe",
                "countryCode": "US"
            },
            {
                "name": "Munday",
                "countryCode": "US"
            },
            {
                "name": "Muniz",
                "countryCode": "US"
            },
            {
                "name": "Murillo Colonia",
                "countryCode": "US"
            },
            {
                "name": "Murphy",
                "countryCode": "US"
            },
            {
                "name": "Nacogdoches",
                "countryCode": "US"
            },
            {
                "name": "Nacogdoches County",
                "countryCode": "US"
            },
            {
                "name": "Naples",
                "countryCode": "US"
            },
            {
                "name": "Nash",
                "countryCode": "US"
            },
            {
                "name": "Nassau Bay",
                "countryCode": "US"
            },
            {
                "name": "Natalia",
                "countryCode": "US"
            },
            {
                "name": "Navarro County",
                "countryCode": "US"
            },
            {
                "name": "Navasota",
                "countryCode": "US"
            },
            {
                "name": "Nederland",
                "countryCode": "US"
            },
            {
                "name": "Needville",
                "countryCode": "US"
            },
            {
                "name": "Nevada",
                "countryCode": "US"
            },
            {
                "name": "New Boston",
                "countryCode": "US"
            },
            {
                "name": "New Braunfels",
                "countryCode": "US"
            },
            {
                "name": "New Summerfield",
                "countryCode": "US"
            },
            {
                "name": "New Territory",
                "countryCode": "US"
            },
            {
                "name": "New Waverly",
                "countryCode": "US"
            },
            {
                "name": "Newark",
                "countryCode": "US"
            },
            {
                "name": "Newton",
                "countryCode": "US"
            },
            {
                "name": "Newton County",
                "countryCode": "US"
            },
            {
                "name": "Nixon",
                "countryCode": "US"
            },
            {
                "name": "Nocona",
                "countryCode": "US"
            },
            {
                "name": "Nolan County",
                "countryCode": "US"
            },
            {
                "name": "Nolanville",
                "countryCode": "US"
            },
            {
                "name": "North Alamo",
                "countryCode": "US"
            },
            {
                "name": "North Richland Hills",
                "countryCode": "US"
            },
            {
                "name": "Northcliff",
                "countryCode": "US"
            },
            {
                "name": "Northcrest",
                "countryCode": "US"
            },
            {
                "name": "Northlake",
                "countryCode": "US"
            },
            {
                "name": "Nueces County",
                "countryCode": "US"
            },
            {
                "name": "Nurillo",
                "countryCode": "US"
            },
            {
                "name": "Oak Cliff Place",
                "countryCode": "US"
            },
            {
                "name": "Oak Leaf",
                "countryCode": "US"
            },
            {
                "name": "Oak Point",
                "countryCode": "US"
            },
            {
                "name": "Oak Ridge North",
                "countryCode": "US"
            },
            {
                "name": "Oak Trail Shores",
                "countryCode": "US"
            },
            {
                "name": "Ochiltree County",
                "countryCode": "US"
            },
            {
                "name": "Odem",
                "countryCode": "US"
            },
            {
                "name": "Odessa",
                "countryCode": "US"
            },
            {
                "name": "Old River-Winfree",
                "countryCode": "US"
            },
            {
                "name": "Oldham County",
                "countryCode": "US"
            },
            {
                "name": "Olivarez",
                "countryCode": "US"
            },
            {
                "name": "Olmito",
                "countryCode": "US"
            },
            {
                "name": "Olmos Park",
                "countryCode": "US"
            },
            {
                "name": "Olney",
                "countryCode": "US"
            },
            {
                "name": "Olton",
                "countryCode": "US"
            },
            {
                "name": "Onalaska",
                "countryCode": "US"
            },
            {
                "name": "Onion Creek",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orange Grove",
                "countryCode": "US"
            },
            {
                "name": "Ore City",
                "countryCode": "US"
            },
            {
                "name": "Overton",
                "countryCode": "US"
            },
            {
                "name": "Ovilla",
                "countryCode": "US"
            },
            {
                "name": "Oyster Creek",
                "countryCode": "US"
            },
            {
                "name": "Ozona",
                "countryCode": "US"
            },
            {
                "name": "Paducah",
                "countryCode": "US"
            },
            {
                "name": "Paint Rock",
                "countryCode": "US"
            },
            {
                "name": "Palacios",
                "countryCode": "US"
            },
            {
                "name": "Palestine",
                "countryCode": "US"
            },
            {
                "name": "Palm Valley",
                "countryCode": "US"
            },
            {
                "name": "Palmer",
                "countryCode": "US"
            },
            {
                "name": "Palmhurst",
                "countryCode": "US"
            },
            {
                "name": "Palmview",
                "countryCode": "US"
            },
            {
                "name": "Palmview South",
                "countryCode": "US"
            },
            {
                "name": "Palo Pinto",
                "countryCode": "US"
            },
            {
                "name": "Palo Pinto County",
                "countryCode": "US"
            },
            {
                "name": "Paloma Creek",
                "countryCode": "US"
            },
            {
                "name": "Paloma Creek South",
                "countryCode": "US"
            },
            {
                "name": "Pampa",
                "countryCode": "US"
            },
            {
                "name": "Panhandle",
                "countryCode": "US"
            },
            {
                "name": "Panola County",
                "countryCode": "US"
            },
            {
                "name": "Panorama Village",
                "countryCode": "US"
            },
            {
                "name": "Pantego",
                "countryCode": "US"
            },
            {
                "name": "Paris",
                "countryCode": "US"
            },
            {
                "name": "Parker",
                "countryCode": "US"
            },
            {
                "name": "Parker County",
                "countryCode": "US"
            },
            {
                "name": "Parmer County",
                "countryCode": "US"
            },
            {
                "name": "Pasadena",
                "countryCode": "US"
            },
            {
                "name": "Patton Village",
                "countryCode": "US"
            },
            {
                "name": "Pearland",
                "countryCode": "US"
            },
            {
                "name": "Pearsall",
                "countryCode": "US"
            },
            {
                "name": "Pecan Acres",
                "countryCode": "US"
            },
            {
                "name": "Pecan Grove",
                "countryCode": "US"
            },
            {
                "name": "Pecan Plantation",
                "countryCode": "US"
            },
            {
                "name": "Pecos",
                "countryCode": "US"
            },
            {
                "name": "Pecos County",
                "countryCode": "US"
            },
            {
                "name": "Pelican Bay",
                "countryCode": "US"
            },
            {
                "name": "Penitas",
                "countryCode": "US"
            },
            {
                "name": "Perezville",
                "countryCode": "US"
            },
            {
                "name": "Perryton",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Pflugerville",
                "countryCode": "US"
            },
            {
                "name": "Pharr",
                "countryCode": "US"
            },
            {
                "name": "Pilot Point",
                "countryCode": "US"
            },
            {
                "name": "Pine Island",
                "countryCode": "US"
            },
            {
                "name": "Pinehurst",
                "countryCode": "US"
            },
            {
                "name": "Pinewood Estates",
                "countryCode": "US"
            },
            {
                "name": "Piney Point Village",
                "countryCode": "US"
            },
            {
                "name": "Pittsburg",
                "countryCode": "US"
            },
            {
                "name": "Plains",
                "countryCode": "US"
            },
            {
                "name": "Plainview",
                "countryCode": "US"
            },
            {
                "name": "Plano",
                "countryCode": "US"
            },
            {
                "name": "Pleak",
                "countryCode": "US"
            },
            {
                "name": "Pleasanton",
                "countryCode": "US"
            },
            {
                "name": "Plum Grove",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Ponder",
                "countryCode": "US"
            },
            {
                "name": "Port Aransas",
                "countryCode": "US"
            },
            {
                "name": "Port Arthur",
                "countryCode": "US"
            },
            {
                "name": "Port Isabel",
                "countryCode": "US"
            },
            {
                "name": "Port Lavaca",
                "countryCode": "US"
            },
            {
                "name": "Port Neches",
                "countryCode": "US"
            },
            {
                "name": "Port O'Connor",
                "countryCode": "US"
            },
            {
                "name": "Porter Heights",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Post",
                "countryCode": "US"
            },
            {
                "name": "Poteet",
                "countryCode": "US"
            },
            {
                "name": "Poth",
                "countryCode": "US"
            },
            {
                "name": "Potosi",
                "countryCode": "US"
            },
            {
                "name": "Potter County",
                "countryCode": "US"
            },
            {
                "name": "Pottsboro",
                "countryCode": "US"
            },
            {
                "name": "Powderly",
                "countryCode": "US"
            },
            {
                "name": "Prairie View",
                "countryCode": "US"
            },
            {
                "name": "Premont",
                "countryCode": "US"
            },
            {
                "name": "Presidio",
                "countryCode": "US"
            },
            {
                "name": "Presidio County",
                "countryCode": "US"
            },
            {
                "name": "Preston",
                "countryCode": "US"
            },
            {
                "name": "Primera",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Progreso",
                "countryCode": "US"
            },
            {
                "name": "Prosper",
                "countryCode": "US"
            },
            {
                "name": "Providence",
                "countryCode": "US"
            },
            {
                "name": "Quail Creek",
                "countryCode": "US"
            },
            {
                "name": "Quanah",
                "countryCode": "US"
            },
            {
                "name": "Queen City",
                "countryCode": "US"
            },
            {
                "name": "Quinlan",
                "countryCode": "US"
            },
            {
                "name": "Quitman",
                "countryCode": "US"
            },
            {
                "name": "Rains County",
                "countryCode": "US"
            },
            {
                "name": "Ralls",
                "countryCode": "US"
            },
            {
                "name": "Rancho Alegre",
                "countryCode": "US"
            },
            {
                "name": "Rancho Viejo",
                "countryCode": "US"
            },
            {
                "name": "Randall County",
                "countryCode": "US"
            },
            {
                "name": "Ranger",
                "countryCode": "US"
            },
            {
                "name": "Rankin",
                "countryCode": "US"
            },
            {
                "name": "Ransom Canyon",
                "countryCode": "US"
            },
            {
                "name": "Raymondville",
                "countryCode": "US"
            },
            {
                "name": "Reagan County",
                "countryCode": "US"
            },
            {
                "name": "Real County",
                "countryCode": "US"
            },
            {
                "name": "Red Lick",
                "countryCode": "US"
            },
            {
                "name": "Red Oak",
                "countryCode": "US"
            },
            {
                "name": "Red River County",
                "countryCode": "US"
            },
            {
                "name": "Redland",
                "countryCode": "US"
            },
            {
                "name": "Redwater",
                "countryCode": "US"
            },
            {
                "name": "Redwood",
                "countryCode": "US"
            },
            {
                "name": "Reeves County",
                "countryCode": "US"
            },
            {
                "name": "Refugio",
                "countryCode": "US"
            },
            {
                "name": "Refugio County",
                "countryCode": "US"
            },
            {
                "name": "Rendon",
                "countryCode": "US"
            },
            {
                "name": "Reno",
                "countryCode": "US"
            },
            {
                "name": "Rhome",
                "countryCode": "US"
            },
            {
                "name": "Ricardo",
                "countryCode": "US"
            },
            {
                "name": "Richardson",
                "countryCode": "US"
            },
            {
                "name": "Richland Hills",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Richwood",
                "countryCode": "US"
            },
            {
                "name": "Riesel",
                "countryCode": "US"
            },
            {
                "name": "Rio Bravo",
                "countryCode": "US"
            },
            {
                "name": "Rio Grande City",
                "countryCode": "US"
            },
            {
                "name": "Rio Hondo",
                "countryCode": "US"
            },
            {
                "name": "River Oaks",
                "countryCode": "US"
            },
            {
                "name": "Roanoke",
                "countryCode": "US"
            },
            {
                "name": "Robert Lee",
                "countryCode": "US"
            },
            {
                "name": "Roberts County",
                "countryCode": "US"
            },
            {
                "name": "Robertson County",
                "countryCode": "US"
            },
            {
                "name": "Robinson",
                "countryCode": "US"
            },
            {
                "name": "Robstown",
                "countryCode": "US"
            },
            {
                "name": "Roby",
                "countryCode": "US"
            },
            {
                "name": "Rockdale",
                "countryCode": "US"
            },
            {
                "name": "Rockport",
                "countryCode": "US"
            },
            {
                "name": "Rocksprings",
                "countryCode": "US"
            },
            {
                "name": "Rockwall",
                "countryCode": "US"
            },
            {
                "name": "Rockwall County",
                "countryCode": "US"
            },
            {
                "name": "Rogers",
                "countryCode": "US"
            },
            {
                "name": "Rollingwood",
                "countryCode": "US"
            },
            {
                "name": "Roma",
                "countryCode": "US"
            },
            {
                "name": "Roma-Los Saenz",
                "countryCode": "US"
            },
            {
                "name": "Roman Forest",
                "countryCode": "US"
            },
            {
                "name": "Roscoe",
                "countryCode": "US"
            },
            {
                "name": "Rosebud",
                "countryCode": "US"
            },
            {
                "name": "Rosenberg",
                "countryCode": "US"
            },
            {
                "name": "Rosharon",
                "countryCode": "US"
            },
            {
                "name": "Rosita North",
                "countryCode": "US"
            },
            {
                "name": "Rosita South",
                "countryCode": "US"
            },
            {
                "name": "Rotan",
                "countryCode": "US"
            },
            {
                "name": "Round Rock",
                "countryCode": "US"
            },
            {
                "name": "Rowlett",
                "countryCode": "US"
            },
            {
                "name": "Royse City",
                "countryCode": "US"
            },
            {
                "name": "Runaway Bay",
                "countryCode": "US"
            },
            {
                "name": "Runge",
                "countryCode": "US"
            },
            {
                "name": "Runnels County",
                "countryCode": "US"
            },
            {
                "name": "Rusk",
                "countryCode": "US"
            },
            {
                "name": "Rusk County",
                "countryCode": "US"
            },
            {
                "name": "Sabinal",
                "countryCode": "US"
            },
            {
                "name": "Sabine County",
                "countryCode": "US"
            },
            {
                "name": "Sachse",
                "countryCode": "US"
            },
            {
                "name": "Saginaw",
                "countryCode": "US"
            },
            {
                "name": "Saint Hedwig",
                "countryCode": "US"
            },
            {
                "name": "Saint Jo",
                "countryCode": "US"
            },
            {
                "name": "Saint Paul",
                "countryCode": "US"
            },
            {
                "name": "Salado",
                "countryCode": "US"
            },
            {
                "name": "Sam Rayburn",
                "countryCode": "US"
            },
            {
                "name": "San Angelo",
                "countryCode": "US"
            },
            {
                "name": "San Antonio",
                "countryCode": "US"
            },
            {
                "name": "San Augustine",
                "countryCode": "US"
            },
            {
                "name": "San Augustine County",
                "countryCode": "US"
            },
            {
                "name": "San Benito",
                "countryCode": "US"
            },
            {
                "name": "San Carlos",
                "countryCode": "US"
            },
            {
                "name": "San Diego",
                "countryCode": "US"
            },
            {
                "name": "San Elizario",
                "countryCode": "US"
            },
            {
                "name": "San Jacinto County",
                "countryCode": "US"
            },
            {
                "name": "San Juan",
                "countryCode": "US"
            },
            {
                "name": "San Leon",
                "countryCode": "US"
            },
            {
                "name": "San Marcos",
                "countryCode": "US"
            },
            {
                "name": "San Patricio County",
                "countryCode": "US"
            },
            {
                "name": "San Saba",
                "countryCode": "US"
            },
            {
                "name": "San Saba County",
                "countryCode": "US"
            },
            {
                "name": "Sanderson",
                "countryCode": "US"
            },
            {
                "name": "Sanger",
                "countryCode": "US"
            },
            {
                "name": "Sansom Park",
                "countryCode": "US"
            },
            {
                "name": "Santa Anna",
                "countryCode": "US"
            },
            {
                "name": "Santa Fe",
                "countryCode": "US"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "US"
            },
            {
                "name": "Sarita",
                "countryCode": "US"
            },
            {
                "name": "Savannah",
                "countryCode": "US"
            },
            {
                "name": "Scenic Oaks",
                "countryCode": "US"
            },
            {
                "name": "Schertz",
                "countryCode": "US"
            },
            {
                "name": "Schleicher County",
                "countryCode": "US"
            },
            {
                "name": "Schulenburg",
                "countryCode": "US"
            },
            {
                "name": "Scissors",
                "countryCode": "US"
            },
            {
                "name": "Scurry County",
                "countryCode": "US"
            },
            {
                "name": "Seabrook",
                "countryCode": "US"
            },
            {
                "name": "Seadrift",
                "countryCode": "US"
            },
            {
                "name": "Seagoville",
                "countryCode": "US"
            },
            {
                "name": "Seagraves",
                "countryCode": "US"
            },
            {
                "name": "Sealy",
                "countryCode": "US"
            },
            {
                "name": "Sebastian",
                "countryCode": "US"
            },
            {
                "name": "Seguin",
                "countryCode": "US"
            },
            {
                "name": "Selma",
                "countryCode": "US"
            },
            {
                "name": "Seminole",
                "countryCode": "US"
            },
            {
                "name": "Serenada",
                "countryCode": "US"
            },
            {
                "name": "Seth Ward",
                "countryCode": "US"
            },
            {
                "name": "Seven Points",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Shackelford County",
                "countryCode": "US"
            },
            {
                "name": "Shady Hollow",
                "countryCode": "US"
            },
            {
                "name": "Shady Shores",
                "countryCode": "US"
            },
            {
                "name": "Shallowater",
                "countryCode": "US"
            },
            {
                "name": "Shamrock",
                "countryCode": "US"
            },
            {
                "name": "Shavano Park",
                "countryCode": "US"
            },
            {
                "name": "Shelby County",
                "countryCode": "US"
            },
            {
                "name": "Sheldon",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah",
                "countryCode": "US"
            },
            {
                "name": "Shepherd",
                "countryCode": "US"
            },
            {
                "name": "Sherman",
                "countryCode": "US"
            },
            {
                "name": "Sherman County",
                "countryCode": "US"
            },
            {
                "name": "Sherwood Shores",
                "countryCode": "US"
            },
            {
                "name": "Shiner",
                "countryCode": "US"
            },
            {
                "name": "Shoreacres",
                "countryCode": "US"
            },
            {
                "name": "Sienna Plantation",
                "countryCode": "US"
            },
            {
                "name": "Sierra Blanca",
                "countryCode": "US"
            },
            {
                "name": "Siesta Acres",
                "countryCode": "US"
            },
            {
                "name": "Siesta Shores",
                "countryCode": "US"
            },
            {
                "name": "Silsbee",
                "countryCode": "US"
            },
            {
                "name": "Silverton",
                "countryCode": "US"
            },
            {
                "name": "Sinton",
                "countryCode": "US"
            },
            {
                "name": "Slaton",
                "countryCode": "US"
            },
            {
                "name": "Smith County",
                "countryCode": "US"
            },
            {
                "name": "Smithville",
                "countryCode": "US"
            },
            {
                "name": "Snyder",
                "countryCode": "US"
            },
            {
                "name": "Socorro",
                "countryCode": "US"
            },
            {
                "name": "Socorro Mission Number 1 Colonia",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "Somervell County",
                "countryCode": "US"
            },
            {
                "name": "Somerville",
                "countryCode": "US"
            },
            {
                "name": "Sonora",
                "countryCode": "US"
            },
            {
                "name": "Sour Lake",
                "countryCode": "US"
            },
            {
                "name": "South Alamo",
                "countryCode": "US"
            },
            {
                "name": "South Houston",
                "countryCode": "US"
            },
            {
                "name": "South Padre Island",
                "countryCode": "US"
            },
            {
                "name": "South Point",
                "countryCode": "US"
            },
            {
                "name": "Southlake",
                "countryCode": "US"
            },
            {
                "name": "Southmayd",
                "countryCode": "US"
            },
            {
                "name": "Southside Place",
                "countryCode": "US"
            },
            {
                "name": "Sparks",
                "countryCode": "US"
            },
            {
                "name": "Spearman",
                "countryCode": "US"
            },
            {
                "name": "Splendora",
                "countryCode": "US"
            },
            {
                "name": "Spring",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Springtown",
                "countryCode": "US"
            },
            {
                "name": "Spur",
                "countryCode": "US"
            },
            {
                "name": "Stafford",
                "countryCode": "US"
            },
            {
                "name": "Stamford",
                "countryCode": "US"
            },
            {
                "name": "Stanton",
                "countryCode": "US"
            },
            {
                "name": "Starr County",
                "countryCode": "US"
            },
            {
                "name": "Stephens County",
                "countryCode": "US"
            },
            {
                "name": "Stephenville",
                "countryCode": "US"
            },
            {
                "name": "Sterling City",
                "countryCode": "US"
            },
            {
                "name": "Sterling County",
                "countryCode": "US"
            },
            {
                "name": "Stinnett",
                "countryCode": "US"
            },
            {
                "name": "Stockdale",
                "countryCode": "US"
            },
            {
                "name": "Stonewall County",
                "countryCode": "US"
            },
            {
                "name": "Stowell",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Sugar Land",
                "countryCode": "US"
            },
            {
                "name": "Sullivan City",
                "countryCode": "US"
            },
            {
                "name": "Sulphur Springs",
                "countryCode": "US"
            },
            {
                "name": "Sundown",
                "countryCode": "US"
            },
            {
                "name": "Sunnyvale",
                "countryCode": "US"
            },
            {
                "name": "Sunray",
                "countryCode": "US"
            },
            {
                "name": "Sutton County",
                "countryCode": "US"
            },
            {
                "name": "Sweeny",
                "countryCode": "US"
            },
            {
                "name": "Sweetwater",
                "countryCode": "US"
            },
            {
                "name": "Swisher County",
                "countryCode": "US"
            },
            {
                "name": "Taft",
                "countryCode": "US"
            },
            {
                "name": "Taft Southwest (historical)",
                "countryCode": "US"
            },
            {
                "name": "Tahoka",
                "countryCode": "US"
            },
            {
                "name": "Talty",
                "countryCode": "US"
            },
            {
                "name": "Tarrant County",
                "countryCode": "US"
            },
            {
                "name": "Tatum",
                "countryCode": "US"
            },
            {
                "name": "Taylor",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Taylor Lake Village",
                "countryCode": "US"
            },
            {
                "name": "Teague",
                "countryCode": "US"
            },
            {
                "name": "Temple",
                "countryCode": "US"
            },
            {
                "name": "Tenaha",
                "countryCode": "US"
            },
            {
                "name": "Terrell",
                "countryCode": "US"
            },
            {
                "name": "Terrell County",
                "countryCode": "US"
            },
            {
                "name": "Terrell Hills",
                "countryCode": "US"
            },
            {
                "name": "Terry County",
                "countryCode": "US"
            },
            {
                "name": "Texarkana",
                "countryCode": "US"
            },
            {
                "name": "Texas City",
                "countryCode": "US"
            },
            {
                "name": "The Colony",
                "countryCode": "US"
            },
            {
                "name": "The Hills",
                "countryCode": "US"
            },
            {
                "name": "The Woodlands",
                "countryCode": "US"
            },
            {
                "name": "Thorndale",
                "countryCode": "US"
            },
            {
                "name": "Three Rivers",
                "countryCode": "US"
            },
            {
                "name": "Throckmorton",
                "countryCode": "US"
            },
            {
                "name": "Throckmorton County",
                "countryCode": "US"
            },
            {
                "name": "Tiki Island",
                "countryCode": "US"
            },
            {
                "name": "Tilden",
                "countryCode": "US"
            },
            {
                "name": "Timberwood Park",
                "countryCode": "US"
            },
            {
                "name": "Timpson",
                "countryCode": "US"
            },
            {
                "name": "Titus County",
                "countryCode": "US"
            },
            {
                "name": "Tom Bean",
                "countryCode": "US"
            },
            {
                "name": "Tom Green County",
                "countryCode": "US"
            },
            {
                "name": "Tomball",
                "countryCode": "US"
            },
            {
                "name": "Tool",
                "countryCode": "US"
            },
            {
                "name": "Tornillo",
                "countryCode": "US"
            },
            {
                "name": "Travis County",
                "countryCode": "US"
            },
            {
                "name": "Travis Ranch",
                "countryCode": "US"
            },
            {
                "name": "Trinity",
                "countryCode": "US"
            },
            {
                "name": "Trinity County",
                "countryCode": "US"
            },
            {
                "name": "Trophy Club",
                "countryCode": "US"
            },
            {
                "name": "Troup",
                "countryCode": "US"
            },
            {
                "name": "Troy",
                "countryCode": "US"
            },
            {
                "name": "Tulia",
                "countryCode": "US"
            },
            {
                "name": "Tye",
                "countryCode": "US"
            },
            {
                "name": "Tyler",
                "countryCode": "US"
            },
            {
                "name": "Tyler County",
                "countryCode": "US"
            },
            {
                "name": "Uhland",
                "countryCode": "US"
            },
            {
                "name": "Universal City",
                "countryCode": "US"
            },
            {
                "name": "University Park",
                "countryCode": "US"
            },
            {
                "name": "Upshur County",
                "countryCode": "US"
            },
            {
                "name": "Upton County",
                "countryCode": "US"
            },
            {
                "name": "Uvalde",
                "countryCode": "US"
            },
            {
                "name": "Uvalde County",
                "countryCode": "US"
            },
            {
                "name": "Uvalde Estates",
                "countryCode": "US"
            },
            {
                "name": "Val Verde County",
                "countryCode": "US"
            },
            {
                "name": "Val Verde Park",
                "countryCode": "US"
            },
            {
                "name": "Valley Mills",
                "countryCode": "US"
            },
            {
                "name": "Van",
                "countryCode": "US"
            },
            {
                "name": "Van Alstyne",
                "countryCode": "US"
            },
            {
                "name": "Van Horn",
                "countryCode": "US"
            },
            {
                "name": "Van Vleck",
                "countryCode": "US"
            },
            {
                "name": "Van Zandt County",
                "countryCode": "US"
            },
            {
                "name": "Vega",
                "countryCode": "US"
            },
            {
                "name": "Venus",
                "countryCode": "US"
            },
            {
                "name": "Vernon",
                "countryCode": "US"
            },
            {
                "name": "Victoria",
                "countryCode": "US"
            },
            {
                "name": "Victoria County",
                "countryCode": "US"
            },
            {
                "name": "Vidor",
                "countryCode": "US"
            },
            {
                "name": "Vinton",
                "countryCode": "US"
            },
            {
                "name": "Von Ormy",
                "countryCode": "US"
            },
            {
                "name": "Waco",
                "countryCode": "US"
            },
            {
                "name": "Wake Village",
                "countryCode": "US"
            },
            {
                "name": "Walker County",
                "countryCode": "US"
            },
            {
                "name": "Waller",
                "countryCode": "US"
            },
            {
                "name": "Waller County",
                "countryCode": "US"
            },
            {
                "name": "Wallis",
                "countryCode": "US"
            },
            {
                "name": "Ward County",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waskom",
                "countryCode": "US"
            },
            {
                "name": "Watauga",
                "countryCode": "US"
            },
            {
                "name": "Waxahachie",
                "countryCode": "US"
            },
            {
                "name": "Weatherford",
                "countryCode": "US"
            },
            {
                "name": "Webb County",
                "countryCode": "US"
            },
            {
                "name": "Webster",
                "countryCode": "US"
            },
            {
                "name": "Weimar",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "Wells Branch",
                "countryCode": "US"
            },
            {
                "name": "Weslaco",
                "countryCode": "US"
            },
            {
                "name": "West",
                "countryCode": "US"
            },
            {
                "name": "West Columbia",
                "countryCode": "US"
            },
            {
                "name": "West Lake Hills",
                "countryCode": "US"
            },
            {
                "name": "West Livingston",
                "countryCode": "US"
            },
            {
                "name": "West Odessa",
                "countryCode": "US"
            },
            {
                "name": "West Orange",
                "countryCode": "US"
            },
            {
                "name": "West Sharyland",
                "countryCode": "US"
            },
            {
                "name": "West Tawakoni",
                "countryCode": "US"
            },
            {
                "name": "West University Place",
                "countryCode": "US"
            },
            {
                "name": "Western Lake",
                "countryCode": "US"
            },
            {
                "name": "Westlake",
                "countryCode": "US"
            },
            {
                "name": "Weston Lakes",
                "countryCode": "US"
            },
            {
                "name": "Westway",
                "countryCode": "US"
            },
            {
                "name": "Westworth",
                "countryCode": "US"
            },
            {
                "name": "Wharton",
                "countryCode": "US"
            },
            {
                "name": "Wharton County",
                "countryCode": "US"
            },
            {
                "name": "Wheeler",
                "countryCode": "US"
            },
            {
                "name": "Wheeler County",
                "countryCode": "US"
            },
            {
                "name": "White Oak",
                "countryCode": "US"
            },
            {
                "name": "White Settlement",
                "countryCode": "US"
            },
            {
                "name": "Whitehouse",
                "countryCode": "US"
            },
            {
                "name": "Whitesboro",
                "countryCode": "US"
            },
            {
                "name": "Whitewright",
                "countryCode": "US"
            },
            {
                "name": "Whitney",
                "countryCode": "US"
            },
            {
                "name": "Wichita County",
                "countryCode": "US"
            },
            {
                "name": "Wichita Falls",
                "countryCode": "US"
            },
            {
                "name": "Wilbarger County",
                "countryCode": "US"
            },
            {
                "name": "Wild Peach Village",
                "countryCode": "US"
            },
            {
                "name": "Wildwood",
                "countryCode": "US"
            },
            {
                "name": "Willacy County",
                "countryCode": "US"
            },
            {
                "name": "Williamson County",
                "countryCode": "US"
            },
            {
                "name": "Willis",
                "countryCode": "US"
            },
            {
                "name": "Willow Park",
                "countryCode": "US"
            },
            {
                "name": "Wills Point",
                "countryCode": "US"
            },
            {
                "name": "Wilmer",
                "countryCode": "US"
            },
            {
                "name": "Wilson County",
                "countryCode": "US"
            },
            {
                "name": "Wimberley",
                "countryCode": "US"
            },
            {
                "name": "Windcrest",
                "countryCode": "US"
            },
            {
                "name": "Windemere",
                "countryCode": "US"
            },
            {
                "name": "Wink",
                "countryCode": "US"
            },
            {
                "name": "Winkler County",
                "countryCode": "US"
            },
            {
                "name": "Winnie",
                "countryCode": "US"
            },
            {
                "name": "Winnsboro",
                "countryCode": "US"
            },
            {
                "name": "Winters",
                "countryCode": "US"
            },
            {
                "name": "Wise County",
                "countryCode": "US"
            },
            {
                "name": "Wolfe City",
                "countryCode": "US"
            },
            {
                "name": "Wolfforth",
                "countryCode": "US"
            },
            {
                "name": "Wood County",
                "countryCode": "US"
            },
            {
                "name": "Woodbranch",
                "countryCode": "US"
            },
            {
                "name": "Woodcreek",
                "countryCode": "US"
            },
            {
                "name": "Woodsboro",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Woodway",
                "countryCode": "US"
            },
            {
                "name": "Wortham",
                "countryCode": "US"
            },
            {
                "name": "Wyldwood",
                "countryCode": "US"
            },
            {
                "name": "Wylie",
                "countryCode": "US"
            },
            {
                "name": "Yoakum",
                "countryCode": "US"
            },
            {
                "name": "Yoakum County",
                "countryCode": "US"
            },
            {
                "name": "Yorktown",
                "countryCode": "US"
            },
            {
                "name": "Young County",
                "countryCode": "US"
            },
            {
                "name": "Zapata",
                "countryCode": "US"
            },
            {
                "name": "Zapata County",
                "countryCode": "US"
            },
            {
                "name": "Zavala County",
                "countryCode": "US"
            },
            {
                "name": "Alpine",
                "countryCode": "US"
            },
            {
                "name": "American Fork",
                "countryCode": "US"
            },
            {
                "name": "Aurora",
                "countryCode": "US"
            },
            {
                "name": "Ballard",
                "countryCode": "US"
            },
            {
                "name": "Beaver",
                "countryCode": "US"
            },
            {
                "name": "Beaver County",
                "countryCode": "US"
            },
            {
                "name": "Benjamin",
                "countryCode": "US"
            },
            {
                "name": "Benson",
                "countryCode": "US"
            },
            {
                "name": "Blanding",
                "countryCode": "US"
            },
            {
                "name": "Bluffdale",
                "countryCode": "US"
            },
            {
                "name": "Bountiful",
                "countryCode": "US"
            },
            {
                "name": "Box Elder County",
                "countryCode": "US"
            },
            {
                "name": "Brigham City",
                "countryCode": "US"
            },
            {
                "name": "Cache County",
                "countryCode": "US"
            },
            {
                "name": "Canyon Rim",
                "countryCode": "US"
            },
            {
                "name": "Carbon County",
                "countryCode": "US"
            },
            {
                "name": "Carbonville",
                "countryCode": "US"
            },
            {
                "name": "Castle Dale",
                "countryCode": "US"
            },
            {
                "name": "Cedar City",
                "countryCode": "US"
            },
            {
                "name": "Cedar Hills",
                "countryCode": "US"
            },
            {
                "name": "Centerfield",
                "countryCode": "US"
            },
            {
                "name": "Centerville",
                "countryCode": "US"
            },
            {
                "name": "Clearfield",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Coalville",
                "countryCode": "US"
            },
            {
                "name": "Cottonwood Heights",
                "countryCode": "US"
            },
            {
                "name": "Daggett County",
                "countryCode": "US"
            },
            {
                "name": "Daniel",
                "countryCode": "US"
            },
            {
                "name": "Davis County",
                "countryCode": "US"
            },
            {
                "name": "Delta",
                "countryCode": "US"
            },
            {
                "name": "Draper",
                "countryCode": "US"
            },
            {
                "name": "Duchesne",
                "countryCode": "US"
            },
            {
                "name": "Duchesne County",
                "countryCode": "US"
            },
            {
                "name": "Eagle Mountain",
                "countryCode": "US"
            },
            {
                "name": "East Carbon City",
                "countryCode": "US"
            },
            {
                "name": "East Millcreek",
                "countryCode": "US"
            },
            {
                "name": "Elk Ridge",
                "countryCode": "US"
            },
            {
                "name": "Elwood",
                "countryCode": "US"
            },
            {
                "name": "Emery County",
                "countryCode": "US"
            },
            {
                "name": "Enoch",
                "countryCode": "US"
            },
            {
                "name": "Enterprise",
                "countryCode": "US"
            },
            {
                "name": "Ephraim",
                "countryCode": "US"
            },
            {
                "name": "Erda",
                "countryCode": "US"
            },
            {
                "name": "Fairview",
                "countryCode": "US"
            },
            {
                "name": "Farmington",
                "countryCode": "US"
            },
            {
                "name": "Farr West",
                "countryCode": "US"
            },
            {
                "name": "Ferron",
                "countryCode": "US"
            },
            {
                "name": "Fillmore",
                "countryCode": "US"
            },
            {
                "name": "Fountain Green",
                "countryCode": "US"
            },
            {
                "name": "Francis",
                "countryCode": "US"
            },
            {
                "name": "Fruit Heights",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Garland",
                "countryCode": "US"
            },
            {
                "name": "Genola",
                "countryCode": "US"
            },
            {
                "name": "Grand County",
                "countryCode": "US"
            },
            {
                "name": "Granite",
                "countryCode": "US"
            },
            {
                "name": "Grantsville",
                "countryCode": "US"
            },
            {
                "name": "Gunnison",
                "countryCode": "US"
            },
            {
                "name": "Harrisville",
                "countryCode": "US"
            },
            {
                "name": "Heber City",
                "countryCode": "US"
            },
            {
                "name": "Helper",
                "countryCode": "US"
            },
            {
                "name": "Herriman",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Hildale",
                "countryCode": "US"
            },
            {
                "name": "Hill Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Holladay",
                "countryCode": "US"
            },
            {
                "name": "Honeyville",
                "countryCode": "US"
            },
            {
                "name": "Hooper",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Hurricane",
                "countryCode": "US"
            },
            {
                "name": "Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "Hyrum",
                "countryCode": "US"
            },
            {
                "name": "Iron County",
                "countryCode": "US"
            },
            {
                "name": "Ivins",
                "countryCode": "US"
            },
            {
                "name": "Juab County",
                "countryCode": "US"
            },
            {
                "name": "Junction",
                "countryCode": "US"
            },
            {
                "name": "Kamas",
                "countryCode": "US"
            },
            {
                "name": "Kanab",
                "countryCode": "US"
            },
            {
                "name": "Kane County",
                "countryCode": "US"
            },
            {
                "name": "Kaysville",
                "countryCode": "US"
            },
            {
                "name": "Kearns",
                "countryCode": "US"
            },
            {
                "name": "LaVerkin",
                "countryCode": "US"
            },
            {
                "name": "Layton",
                "countryCode": "US"
            },
            {
                "name": "Lehi",
                "countryCode": "US"
            },
            {
                "name": "Lewiston",
                "countryCode": "US"
            },
            {
                "name": "Liberty",
                "countryCode": "US"
            },
            {
                "name": "Lindon",
                "countryCode": "US"
            },
            {
                "name": "Little Cottonwood Creek Valley",
                "countryCode": "US"
            },
            {
                "name": "Loa",
                "countryCode": "US"
            },
            {
                "name": "Logan",
                "countryCode": "US"
            },
            {
                "name": "Maeser",
                "countryCode": "US"
            },
            {
                "name": "Magna",
                "countryCode": "US"
            },
            {
                "name": "Manila",
                "countryCode": "US"
            },
            {
                "name": "Manti",
                "countryCode": "US"
            },
            {
                "name": "Mapleton",
                "countryCode": "US"
            },
            {
                "name": "Marriott-Slaterville",
                "countryCode": "US"
            },
            {
                "name": "Mendon",
                "countryCode": "US"
            },
            {
                "name": "Midvale",
                "countryCode": "US"
            },
            {
                "name": "Midway",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Millard County",
                "countryCode": "US"
            },
            {
                "name": "Millcreek",
                "countryCode": "US"
            },
            {
                "name": "Millville",
                "countryCode": "US"
            },
            {
                "name": "Moab",
                "countryCode": "US"
            },
            {
                "name": "Mona",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Morgan",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Moroni",
                "countryCode": "US"
            },
            {
                "name": "Mount Olympus",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mountain Green",
                "countryCode": "US"
            },
            {
                "name": "Murray",
                "countryCode": "US"
            },
            {
                "name": "Naples",
                "countryCode": "US"
            },
            {
                "name": "Nephi",
                "countryCode": "US"
            },
            {
                "name": "Nibley",
                "countryCode": "US"
            },
            {
                "name": "North Logan",
                "countryCode": "US"
            },
            {
                "name": "North Ogden",
                "countryCode": "US"
            },
            {
                "name": "North Salt Lake",
                "countryCode": "US"
            },
            {
                "name": "Oakley",
                "countryCode": "US"
            },
            {
                "name": "Ogden",
                "countryCode": "US"
            },
            {
                "name": "Oquirrh",
                "countryCode": "US"
            },
            {
                "name": "Orangeville",
                "countryCode": "US"
            },
            {
                "name": "Orem",
                "countryCode": "US"
            },
            {
                "name": "Panguitch",
                "countryCode": "US"
            },
            {
                "name": "Park City",
                "countryCode": "US"
            },
            {
                "name": "Parowan",
                "countryCode": "US"
            },
            {
                "name": "Payson",
                "countryCode": "US"
            },
            {
                "name": "Perry",
                "countryCode": "US"
            },
            {
                "name": "Piute County",
                "countryCode": "US"
            },
            {
                "name": "Plain City",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Grove",
                "countryCode": "US"
            },
            {
                "name": "Pleasant View",
                "countryCode": "US"
            },
            {
                "name": "Price",
                "countryCode": "US"
            },
            {
                "name": "Providence",
                "countryCode": "US"
            },
            {
                "name": "Provo",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Rich County",
                "countryCode": "US"
            },
            {
                "name": "Richfield",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "River Heights",
                "countryCode": "US"
            },
            {
                "name": "Riverdale",
                "countryCode": "US"
            },
            {
                "name": "Riverton",
                "countryCode": "US"
            },
            {
                "name": "Roosevelt",
                "countryCode": "US"
            },
            {
                "name": "Roy",
                "countryCode": "US"
            },
            {
                "name": "Saint George",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Salina",
                "countryCode": "US"
            },
            {
                "name": "Salt Lake City",
                "countryCode": "US"
            },
            {
                "name": "Salt Lake County",
                "countryCode": "US"
            },
            {
                "name": "San Juan County",
                "countryCode": "US"
            },
            {
                "name": "Sandy",
                "countryCode": "US"
            },
            {
                "name": "Sandy Hills",
                "countryCode": "US"
            },
            {
                "name": "Sanpete County",
                "countryCode": "US"
            },
            {
                "name": "Santa Clara",
                "countryCode": "US"
            },
            {
                "name": "Santaquin",
                "countryCode": "US"
            },
            {
                "name": "Saratoga Springs",
                "countryCode": "US"
            },
            {
                "name": "Sevier County",
                "countryCode": "US"
            },
            {
                "name": "Silver Summit",
                "countryCode": "US"
            },
            {
                "name": "Smithfield",
                "countryCode": "US"
            },
            {
                "name": "Snyderville",
                "countryCode": "US"
            },
            {
                "name": "South Jordan",
                "countryCode": "US"
            },
            {
                "name": "South Jordan Heights",
                "countryCode": "US"
            },
            {
                "name": "South Ogden",
                "countryCode": "US"
            },
            {
                "name": "South Salt Lake",
                "countryCode": "US"
            },
            {
                "name": "South Weber",
                "countryCode": "US"
            },
            {
                "name": "South Willard",
                "countryCode": "US"
            },
            {
                "name": "Spanish Fork",
                "countryCode": "US"
            },
            {
                "name": "Spring City",
                "countryCode": "US"
            },
            {
                "name": "Spring Glen",
                "countryCode": "US"
            },
            {
                "name": "Springville",
                "countryCode": "US"
            },
            {
                "name": "Stansbury park",
                "countryCode": "US"
            },
            {
                "name": "Summit County",
                "countryCode": "US"
            },
            {
                "name": "Summit Park",
                "countryCode": "US"
            },
            {
                "name": "Sunset",
                "countryCode": "US"
            },
            {
                "name": "Syracuse",
                "countryCode": "US"
            },
            {
                "name": "Taylorsville",
                "countryCode": "US"
            },
            {
                "name": "Tooele",
                "countryCode": "US"
            },
            {
                "name": "Tooele County",
                "countryCode": "US"
            },
            {
                "name": "Toquerville",
                "countryCode": "US"
            },
            {
                "name": "Tremonton",
                "countryCode": "US"
            },
            {
                "name": "Uintah",
                "countryCode": "US"
            },
            {
                "name": "Uintah County",
                "countryCode": "US"
            },
            {
                "name": "Utah County",
                "countryCode": "US"
            },
            {
                "name": "Vernal",
                "countryCode": "US"
            },
            {
                "name": "Vineyard",
                "countryCode": "US"
            },
            {
                "name": "Wasatch County",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Washington Terrace",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Weber County",
                "countryCode": "US"
            },
            {
                "name": "Wellington",
                "countryCode": "US"
            },
            {
                "name": "Wellsville",
                "countryCode": "US"
            },
            {
                "name": "Wendover",
                "countryCode": "US"
            },
            {
                "name": "West Bountiful",
                "countryCode": "US"
            },
            {
                "name": "West Haven",
                "countryCode": "US"
            },
            {
                "name": "West Jordan",
                "countryCode": "US"
            },
            {
                "name": "West Mountain",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "West Valley City",
                "countryCode": "US"
            },
            {
                "name": "White City",
                "countryCode": "US"
            },
            {
                "name": "Willard",
                "countryCode": "US"
            },
            {
                "name": "Wolf Creek",
                "countryCode": "US"
            },
            {
                "name": "Woodland Hills",
                "countryCode": "US"
            },
            {
                "name": "Woods Cross",
                "countryCode": "US"
            },
            {
                "name": "Abingdon",
                "countryCode": "US"
            },
            {
                "name": "Accomac",
                "countryCode": "US"
            },
            {
                "name": "Accomack County",
                "countryCode": "US"
            },
            {
                "name": "Adwolf",
                "countryCode": "US"
            },
            {
                "name": "Albemarle County",
                "countryCode": "US"
            },
            {
                "name": "Alexandria",
                "countryCode": "US"
            },
            {
                "name": "Alleghany County",
                "countryCode": "US"
            },
            {
                "name": "Altavista",
                "countryCode": "US"
            },
            {
                "name": "Amelia County",
                "countryCode": "US"
            },
            {
                "name": "Amelia Court House",
                "countryCode": "US"
            },
            {
                "name": "Amherst",
                "countryCode": "US"
            },
            {
                "name": "Amherst County",
                "countryCode": "US"
            },
            {
                "name": "Annandale",
                "countryCode": "US"
            },
            {
                "name": "Appalachia",
                "countryCode": "US"
            },
            {
                "name": "Apple Mountain Lake",
                "countryCode": "US"
            },
            {
                "name": "Appomattox",
                "countryCode": "US"
            },
            {
                "name": "Appomattox County",
                "countryCode": "US"
            },
            {
                "name": "Aquia Harbour",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Arlington County",
                "countryCode": "US"
            },
            {
                "name": "Ashburn",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Atkins",
                "countryCode": "US"
            },
            {
                "name": "Augusta County",
                "countryCode": "US"
            },
            {
                "name": "Baileys Crossroads",
                "countryCode": "US"
            },
            {
                "name": "Bassett",
                "countryCode": "US"
            },
            {
                "name": "Basye",
                "countryCode": "US"
            },
            {
                "name": "Bath County",
                "countryCode": "US"
            },
            {
                "name": "Bealeton",
                "countryCode": "US"
            },
            {
                "name": "Bedford",
                "countryCode": "US"
            },
            {
                "name": "Bedford County",
                "countryCode": "US"
            },
            {
                "name": "Belle Haven",
                "countryCode": "US"
            },
            {
                "name": "Bellwood",
                "countryCode": "US"
            },
            {
                "name": "Belmont",
                "countryCode": "US"
            },
            {
                "name": "Belmont Estates",
                "countryCode": "US"
            },
            {
                "name": "Bensley",
                "countryCode": "US"
            },
            {
                "name": "Berryville",
                "countryCode": "US"
            },
            {
                "name": "Big Stone Gap",
                "countryCode": "US"
            },
            {
                "name": "Blacksburg",
                "countryCode": "US"
            },
            {
                "name": "Blackstone",
                "countryCode": "US"
            },
            {
                "name": "Bland",
                "countryCode": "US"
            },
            {
                "name": "Bland County",
                "countryCode": "US"
            },
            {
                "name": "Blue Ridge",
                "countryCode": "US"
            },
            {
                "name": "Bluefield",
                "countryCode": "US"
            },
            {
                "name": "Bon Air",
                "countryCode": "US"
            },
            {
                "name": "Boswell's Corner",
                "countryCode": "US"
            },
            {
                "name": "Botetourt County",
                "countryCode": "US"
            },
            {
                "name": "Bowling Green",
                "countryCode": "US"
            },
            {
                "name": "Boydton",
                "countryCode": "US"
            },
            {
                "name": "Bracey",
                "countryCode": "US"
            },
            {
                "name": "Brambleton",
                "countryCode": "US"
            },
            {
                "name": "Brandermill",
                "countryCode": "US"
            },
            {
                "name": "Bridgewater",
                "countryCode": "US"
            },
            {
                "name": "Brightwood",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Broadlands",
                "countryCode": "US"
            },
            {
                "name": "Broadway",
                "countryCode": "US"
            },
            {
                "name": "Brookneal",
                "countryCode": "US"
            },
            {
                "name": "Brunswick County",
                "countryCode": "US"
            },
            {
                "name": "Buchanan",
                "countryCode": "US"
            },
            {
                "name": "Buchanan County",
                "countryCode": "US"
            },
            {
                "name": "Buckhall",
                "countryCode": "US"
            },
            {
                "name": "Buckingham",
                "countryCode": "US"
            },
            {
                "name": "Buckingham County",
                "countryCode": "US"
            },
            {
                "name": "Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "Bull Run",
                "countryCode": "US"
            },
            {
                "name": "Bull Run Mountain Estates",
                "countryCode": "US"
            },
            {
                "name": "Burke",
                "countryCode": "US"
            },
            {
                "name": "Campbell County",
                "countryCode": "US"
            },
            {
                "name": "Cana",
                "countryCode": "US"
            },
            {
                "name": "Cape Charles",
                "countryCode": "US"
            },
            {
                "name": "Captains Cove",
                "countryCode": "US"
            },
            {
                "name": "Caroline County",
                "countryCode": "US"
            },
            {
                "name": "Carroll County",
                "countryCode": "US"
            },
            {
                "name": "Carrollton",
                "countryCode": "US"
            },
            {
                "name": "Castlewood",
                "countryCode": "US"
            },
            {
                "name": "Cave Spring",
                "countryCode": "US"
            },
            {
                "name": "Cedar Bluff",
                "countryCode": "US"
            },
            {
                "name": "Central Garage",
                "countryCode": "US"
            },
            {
                "name": "Centreville",
                "countryCode": "US"
            },
            {
                "name": "Chamberlayne",
                "countryCode": "US"
            },
            {
                "name": "Chantilly",
                "countryCode": "US"
            },
            {
                "name": "Charles City",
                "countryCode": "US"
            },
            {
                "name": "Charles City County",
                "countryCode": "US"
            },
            {
                "name": "Charlotte County",
                "countryCode": "US"
            },
            {
                "name": "Charlotte Court House",
                "countryCode": "US"
            },
            {
                "name": "Charlottesville",
                "countryCode": "US"
            },
            {
                "name": "Chase City",
                "countryCode": "US"
            },
            {
                "name": "Chatham",
                "countryCode": "US"
            },
            {
                "name": "Chatmoss",
                "countryCode": "US"
            },
            {
                "name": "Cherry Hill",
                "countryCode": "US"
            },
            {
                "name": "Chesapeake",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield County",
                "countryCode": "US"
            },
            {
                "name": "Chesterfield Court House",
                "countryCode": "US"
            },
            {
                "name": "Chilhowie",
                "countryCode": "US"
            },
            {
                "name": "Chincoteague",
                "countryCode": "US"
            },
            {
                "name": "Christiansburg",
                "countryCode": "US"
            },
            {
                "name": "City of Alexandria",
                "countryCode": "US"
            },
            {
                "name": "City of Bedford",
                "countryCode": "US"
            },
            {
                "name": "City of Bristol",
                "countryCode": "US"
            },
            {
                "name": "City of Buena Vista",
                "countryCode": "US"
            },
            {
                "name": "City of Charlottesville",
                "countryCode": "US"
            },
            {
                "name": "City of Chesapeake",
                "countryCode": "US"
            },
            {
                "name": "City of Colonial Heights",
                "countryCode": "US"
            },
            {
                "name": "City of Covington",
                "countryCode": "US"
            },
            {
                "name": "City of Danville",
                "countryCode": "US"
            },
            {
                "name": "City of Emporia",
                "countryCode": "US"
            },
            {
                "name": "City of Fairfax",
                "countryCode": "US"
            },
            {
                "name": "City of Falls Church",
                "countryCode": "US"
            },
            {
                "name": "City of Franklin",
                "countryCode": "US"
            },
            {
                "name": "City of Fredericksburg",
                "countryCode": "US"
            },
            {
                "name": "City of Galax",
                "countryCode": "US"
            },
            {
                "name": "City of Hampton",
                "countryCode": "US"
            },
            {
                "name": "City of Harrisonburg",
                "countryCode": "US"
            },
            {
                "name": "City of Hopewell",
                "countryCode": "US"
            },
            {
                "name": "City of Lexington",
                "countryCode": "US"
            },
            {
                "name": "City of Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "City of Manassas",
                "countryCode": "US"
            },
            {
                "name": "City of Manassas Park",
                "countryCode": "US"
            },
            {
                "name": "City of Martinsville",
                "countryCode": "US"
            },
            {
                "name": "City of Newport News",
                "countryCode": "US"
            },
            {
                "name": "City of Norfolk",
                "countryCode": "US"
            },
            {
                "name": "City of Norton",
                "countryCode": "US"
            },
            {
                "name": "City of Petersburg",
                "countryCode": "US"
            },
            {
                "name": "City of Poquoson",
                "countryCode": "US"
            },
            {
                "name": "City of Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "City of Radford",
                "countryCode": "US"
            },
            {
                "name": "City of Richmond",
                "countryCode": "US"
            },
            {
                "name": "City of Roanoke",
                "countryCode": "US"
            },
            {
                "name": "City of Salem",
                "countryCode": "US"
            },
            {
                "name": "City of Staunton",
                "countryCode": "US"
            },
            {
                "name": "City of Suffolk",
                "countryCode": "US"
            },
            {
                "name": "City of Virginia Beach",
                "countryCode": "US"
            },
            {
                "name": "City of Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "City of Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "City of Winchester",
                "countryCode": "US"
            },
            {
                "name": "Clarke County",
                "countryCode": "US"
            },
            {
                "name": "Clarksville",
                "countryCode": "US"
            },
            {
                "name": "Claypool Hill",
                "countryCode": "US"
            },
            {
                "name": "Clifton Forge",
                "countryCode": "US"
            },
            {
                "name": "Clintwood",
                "countryCode": "US"
            },
            {
                "name": "Cloverdale",
                "countryCode": "US"
            },
            {
                "name": "Coeburn",
                "countryCode": "US"
            },
            {
                "name": "Collinsville",
                "countryCode": "US"
            },
            {
                "name": "Colonial Beach",
                "countryCode": "US"
            },
            {
                "name": "Colonial Heights",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Countryside",
                "countryCode": "US"
            },
            {
                "name": "Courtland",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Craig County",
                "countryCode": "US"
            },
            {
                "name": "Crewe",
                "countryCode": "US"
            },
            {
                "name": "Crimora",
                "countryCode": "US"
            },
            {
                "name": "Crozet",
                "countryCode": "US"
            },
            {
                "name": "Culpeper",
                "countryCode": "US"
            },
            {
                "name": "Culpeper County",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Cumberland County",
                "countryCode": "US"
            },
            {
                "name": "Dahlgren",
                "countryCode": "US"
            },
            {
                "name": "Dale City",
                "countryCode": "US"
            },
            {
                "name": "Daleville",
                "countryCode": "US"
            },
            {
                "name": "Danville",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Deltaville",
                "countryCode": "US"
            },
            {
                "name": "Dickenson County",
                "countryCode": "US"
            },
            {
                "name": "Dinwiddie County",
                "countryCode": "US"
            },
            {
                "name": "Dooms",
                "countryCode": "US"
            },
            {
                "name": "Dranesville",
                "countryCode": "US"
            },
            {
                "name": "Dryden",
                "countryCode": "US"
            },
            {
                "name": "Dublin",
                "countryCode": "US"
            },
            {
                "name": "Dulles Town Center",
                "countryCode": "US"
            },
            {
                "name": "Dumbarton",
                "countryCode": "US"
            },
            {
                "name": "Dumfries",
                "countryCode": "US"
            },
            {
                "name": "Dunn Loring",
                "countryCode": "US"
            },
            {
                "name": "East Hampton",
                "countryCode": "US"
            },
            {
                "name": "East Highland Park",
                "countryCode": "US"
            },
            {
                "name": "East Lexington",
                "countryCode": "US"
            },
            {
                "name": "Eastville",
                "countryCode": "US"
            },
            {
                "name": "Edinburg",
                "countryCode": "US"
            },
            {
                "name": "Elkton",
                "countryCode": "US"
            },
            {
                "name": "Emory",
                "countryCode": "US"
            },
            {
                "name": "Emporia",
                "countryCode": "US"
            },
            {
                "name": "Enon",
                "countryCode": "US"
            },
            {
                "name": "Essex County",
                "countryCode": "US"
            },
            {
                "name": "Ettrick",
                "countryCode": "US"
            },
            {
                "name": "Exmore",
                "countryCode": "US"
            },
            {
                "name": "Fairfax",
                "countryCode": "US"
            },
            {
                "name": "Fairfax County",
                "countryCode": "US"
            },
            {
                "name": "Fairfax Station",
                "countryCode": "US"
            },
            {
                "name": "Fairlawn",
                "countryCode": "US"
            },
            {
                "name": "Falls Church",
                "countryCode": "US"
            },
            {
                "name": "Falmouth",
                "countryCode": "US"
            },
            {
                "name": "Farmville",
                "countryCode": "US"
            },
            {
                "name": "Fauquier County",
                "countryCode": "US"
            },
            {
                "name": "Ferrum",
                "countryCode": "US"
            },
            {
                "name": "Fincastle",
                "countryCode": "US"
            },
            {
                "name": "Fishersville",
                "countryCode": "US"
            },
            {
                "name": "Floris",
                "countryCode": "US"
            },
            {
                "name": "Floyd",
                "countryCode": "US"
            },
            {
                "name": "Floyd County",
                "countryCode": "US"
            },
            {
                "name": "Fluvanna County",
                "countryCode": "US"
            },
            {
                "name": "Forest",
                "countryCode": "US"
            },
            {
                "name": "Fort Belvoir",
                "countryCode": "US"
            },
            {
                "name": "Fort Hunt",
                "countryCode": "US"
            },
            {
                "name": "Fort Lee",
                "countryCode": "US"
            },
            {
                "name": "Franconia",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frederick County",
                "countryCode": "US"
            },
            {
                "name": "Fredericksburg",
                "countryCode": "US"
            },
            {
                "name": "Front Royal",
                "countryCode": "US"
            },
            {
                "name": "Gainesville",
                "countryCode": "US"
            },
            {
                "name": "Galax",
                "countryCode": "US"
            },
            {
                "name": "Gate City",
                "countryCode": "US"
            },
            {
                "name": "Giles County",
                "countryCode": "US"
            },
            {
                "name": "Glade Spring",
                "countryCode": "US"
            },
            {
                "name": "Glasgow",
                "countryCode": "US"
            },
            {
                "name": "Glen Allen",
                "countryCode": "US"
            },
            {
                "name": "Gloucester County",
                "countryCode": "US"
            },
            {
                "name": "Gloucester Courthouse",
                "countryCode": "US"
            },
            {
                "name": "Gloucester Point",
                "countryCode": "US"
            },
            {
                "name": "Goochland",
                "countryCode": "US"
            },
            {
                "name": "Goochland County",
                "countryCode": "US"
            },
            {
                "name": "Gordonsville",
                "countryCode": "US"
            },
            {
                "name": "Grayson County",
                "countryCode": "US"
            },
            {
                "name": "Great Falls",
                "countryCode": "US"
            },
            {
                "name": "Greenbriar",
                "countryCode": "US"
            },
            {
                "name": "Greene County",
                "countryCode": "US"
            },
            {
                "name": "Greensville County",
                "countryCode": "US"
            },
            {
                "name": "Gretna",
                "countryCode": "US"
            },
            {
                "name": "Grottoes",
                "countryCode": "US"
            },
            {
                "name": "Groveton",
                "countryCode": "US"
            },
            {
                "name": "Grundy",
                "countryCode": "US"
            },
            {
                "name": "Halifax",
                "countryCode": "US"
            },
            {
                "name": "Halifax County",
                "countryCode": "US"
            },
            {
                "name": "Hampden Sydney",
                "countryCode": "US"
            },
            {
                "name": "Hampton",
                "countryCode": "US"
            },
            {
                "name": "Hanover",
                "countryCode": "US"
            },
            {
                "name": "Hanover County",
                "countryCode": "US"
            },
            {
                "name": "Harrisonburg",
                "countryCode": "US"
            },
            {
                "name": "Hayfield",
                "countryCode": "US"
            },
            {
                "name": "Haymarket",
                "countryCode": "US"
            },
            {
                "name": "Heathsville",
                "countryCode": "US"
            },
            {
                "name": "Henrico County",
                "countryCode": "US"
            },
            {
                "name": "Henry County",
                "countryCode": "US"
            },
            {
                "name": "Henry Fork",
                "countryCode": "US"
            },
            {
                "name": "Herndon",
                "countryCode": "US"
            },
            {
                "name": "Highland County",
                "countryCode": "US"
            },
            {
                "name": "Highland Springs",
                "countryCode": "US"
            },
            {
                "name": "Hillsville",
                "countryCode": "US"
            },
            {
                "name": "Hollins",
                "countryCode": "US"
            },
            {
                "name": "Hollymead",
                "countryCode": "US"
            },
            {
                "name": "Honaker",
                "countryCode": "US"
            },
            {
                "name": "Hopewell",
                "countryCode": "US"
            },
            {
                "name": "Horse Pasture",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Hurt",
                "countryCode": "US"
            },
            {
                "name": "Hybla Valley",
                "countryCode": "US"
            },
            {
                "name": "Idylwood",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Independent Hill",
                "countryCode": "US"
            },
            {
                "name": "Isle of Wight County",
                "countryCode": "US"
            },
            {
                "name": "James City County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jolivue",
                "countryCode": "US"
            },
            {
                "name": "Jonesville",
                "countryCode": "US"
            },
            {
                "name": "Kenbridge",
                "countryCode": "US"
            },
            {
                "name": "Kilmarnock",
                "countryCode": "US"
            },
            {
                "name": "King George",
                "countryCode": "US"
            },
            {
                "name": "King George County",
                "countryCode": "US"
            },
            {
                "name": "King William",
                "countryCode": "US"
            },
            {
                "name": "King William County",
                "countryCode": "US"
            },
            {
                "name": "King and Queen County",
                "countryCode": "US"
            },
            {
                "name": "King and Queen Court House",
                "countryCode": "US"
            },
            {
                "name": "Kings Park",
                "countryCode": "US"
            },
            {
                "name": "Kings Park West",
                "countryCode": "US"
            },
            {
                "name": "Lake Barcroft",
                "countryCode": "US"
            },
            {
                "name": "Lake Monticello",
                "countryCode": "US"
            },
            {
                "name": "Lake Ridge",
                "countryCode": "US"
            },
            {
                "name": "Lakeside",
                "countryCode": "US"
            },
            {
                "name": "Lancaster County",
                "countryCode": "US"
            },
            {
                "name": "Laurel",
                "countryCode": "US"
            },
            {
                "name": "Laurel Hill",
                "countryCode": "US"
            },
            {
                "name": "Lawrenceville",
                "countryCode": "US"
            },
            {
                "name": "Laymantown",
                "countryCode": "US"
            },
            {
                "name": "Lebanon",
                "countryCode": "US"
            },
            {
                "name": "Lee County",
                "countryCode": "US"
            },
            {
                "name": "Leesburg",
                "countryCode": "US"
            },
            {
                "name": "Lexington",
                "countryCode": "US"
            },
            {
                "name": "Lincolnia",
                "countryCode": "US"
            },
            {
                "name": "Linton Hall",
                "countryCode": "US"
            },
            {
                "name": "Loch Lomond",
                "countryCode": "US"
            },
            {
                "name": "Lorton",
                "countryCode": "US"
            },
            {
                "name": "Loudoun County",
                "countryCode": "US"
            },
            {
                "name": "Loudoun Valley Estates",
                "countryCode": "US"
            },
            {
                "name": "Louisa",
                "countryCode": "US"
            },
            {
                "name": "Louisa County",
                "countryCode": "US"
            },
            {
                "name": "Lovettsville",
                "countryCode": "US"
            },
            {
                "name": "Lovingston",
                "countryCode": "US"
            },
            {
                "name": "Lowes Island",
                "countryCode": "US"
            },
            {
                "name": "Lunenburg",
                "countryCode": "US"
            },
            {
                "name": "Lunenburg County",
                "countryCode": "US"
            },
            {
                "name": "Luray",
                "countryCode": "US"
            },
            {
                "name": "Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "Lyndhurst",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Madison County",
                "countryCode": "US"
            },
            {
                "name": "Madison Heights",
                "countryCode": "US"
            },
            {
                "name": "Manassas",
                "countryCode": "US"
            },
            {
                "name": "Manassas Park",
                "countryCode": "US"
            },
            {
                "name": "Mantua",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Martinsville",
                "countryCode": "US"
            },
            {
                "name": "Massanetta Springs",
                "countryCode": "US"
            },
            {
                "name": "Massanutten",
                "countryCode": "US"
            },
            {
                "name": "Mathews",
                "countryCode": "US"
            },
            {
                "name": "Mathews County",
                "countryCode": "US"
            },
            {
                "name": "Matoaca",
                "countryCode": "US"
            },
            {
                "name": "McLean",
                "countryCode": "US"
            },
            {
                "name": "Meadowbrook",
                "countryCode": "US"
            },
            {
                "name": "Mechanicsville",
                "countryCode": "US"
            },
            {
                "name": "Mecklenburg County",
                "countryCode": "US"
            },
            {
                "name": "Merrifield",
                "countryCode": "US"
            },
            {
                "name": "Merrimac",
                "countryCode": "US"
            },
            {
                "name": "Middlesex County",
                "countryCode": "US"
            },
            {
                "name": "Middletown",
                "countryCode": "US"
            },
            {
                "name": "Montclair",
                "countryCode": "US"
            },
            {
                "name": "Monterey",
                "countryCode": "US"
            },
            {
                "name": "Montgomery County",
                "countryCode": "US"
            },
            {
                "name": "Montrose",
                "countryCode": "US"
            },
            {
                "name": "Montross",
                "countryCode": "US"
            },
            {
                "name": "Motley",
                "countryCode": "US"
            },
            {
                "name": "Mount Hermon",
                "countryCode": "US"
            },
            {
                "name": "Mount Jackson",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mountain Road",
                "countryCode": "US"
            },
            {
                "name": "Narrows",
                "countryCode": "US"
            },
            {
                "name": "Nellysford",
                "countryCode": "US"
            },
            {
                "name": "Nelson County",
                "countryCode": "US"
            },
            {
                "name": "New Baltimore",
                "countryCode": "US"
            },
            {
                "name": "New Castle",
                "countryCode": "US"
            },
            {
                "name": "New Kent",
                "countryCode": "US"
            },
            {
                "name": "New Kent County",
                "countryCode": "US"
            },
            {
                "name": "New Market",
                "countryCode": "US"
            },
            {
                "name": "Newington",
                "countryCode": "US"
            },
            {
                "name": "Newport News",
                "countryCode": "US"
            },
            {
                "name": "Nokesville",
                "countryCode": "US"
            },
            {
                "name": "Norfolk",
                "countryCode": "US"
            },
            {
                "name": "North Shore",
                "countryCode": "US"
            },
            {
                "name": "North Springfield",
                "countryCode": "US"
            },
            {
                "name": "Northampton County",
                "countryCode": "US"
            },
            {
                "name": "Northumberland County",
                "countryCode": "US"
            },
            {
                "name": "Norton",
                "countryCode": "US"
            },
            {
                "name": "Nottoway County",
                "countryCode": "US"
            },
            {
                "name": "Oak Grove",
                "countryCode": "US"
            },
            {
                "name": "Oak Hill",
                "countryCode": "US"
            },
            {
                "name": "Oakton",
                "countryCode": "US"
            },
            {
                "name": "Occoquan",
                "countryCode": "US"
            },
            {
                "name": "Onancock",
                "countryCode": "US"
            },
            {
                "name": "Orange",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Page County",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Pannill Fork",
                "countryCode": "US"
            },
            {
                "name": "Pantops",
                "countryCode": "US"
            },
            {
                "name": "Passapatanzy",
                "countryCode": "US"
            },
            {
                "name": "Patrick County",
                "countryCode": "US"
            },
            {
                "name": "Patrick Springs",
                "countryCode": "US"
            },
            {
                "name": "Pearisburg",
                "countryCode": "US"
            },
            {
                "name": "Pembroke",
                "countryCode": "US"
            },
            {
                "name": "Pennington Gap",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Pimmit Hills",
                "countryCode": "US"
            },
            {
                "name": "Pittsylvania County",
                "countryCode": "US"
            },
            {
                "name": "Plum Creek",
                "countryCode": "US"
            },
            {
                "name": "Poquoson",
                "countryCode": "US"
            },
            {
                "name": "Portsmouth",
                "countryCode": "US"
            },
            {
                "name": "Portsmouth Heights",
                "countryCode": "US"
            },
            {
                "name": "Potomac Mills",
                "countryCode": "US"
            },
            {
                "name": "Powhatan County",
                "countryCode": "US"
            },
            {
                "name": "Prices Fork",
                "countryCode": "US"
            },
            {
                "name": "Prince Edward County",
                "countryCode": "US"
            },
            {
                "name": "Prince George",
                "countryCode": "US"
            },
            {
                "name": "Prince George County",
                "countryCode": "US"
            },
            {
                "name": "Prince William County",
                "countryCode": "US"
            },
            {
                "name": "Pulaski",
                "countryCode": "US"
            },
            {
                "name": "Pulaski County",
                "countryCode": "US"
            },
            {
                "name": "Purcellville",
                "countryCode": "US"
            },
            {
                "name": "Quantico Station",
                "countryCode": "US"
            },
            {
                "name": "Radford",
                "countryCode": "US"
            },
            {
                "name": "Rappahannock County",
                "countryCode": "US"
            },
            {
                "name": "Raven",
                "countryCode": "US"
            },
            {
                "name": "Ravensworth",
                "countryCode": "US"
            },
            {
                "name": "Reston",
                "countryCode": "US"
            },
            {
                "name": "Richlands",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Richmond County",
                "countryCode": "US"
            },
            {
                "name": "Roanoke",
                "countryCode": "US"
            },
            {
                "name": "Roanoke County",
                "countryCode": "US"
            },
            {
                "name": "Rockbridge County",
                "countryCode": "US"
            },
            {
                "name": "Rockingham County",
                "countryCode": "US"
            },
            {
                "name": "Rocky Mount",
                "countryCode": "US"
            },
            {
                "name": "Rose Hill",
                "countryCode": "US"
            },
            {
                "name": "Rosslyn",
                "countryCode": "US"
            },
            {
                "name": "Ruckersville",
                "countryCode": "US"
            },
            {
                "name": "Rural Retreat",
                "countryCode": "US"
            },
            {
                "name": "Rushmere",
                "countryCode": "US"
            },
            {
                "name": "Russell County",
                "countryCode": "US"
            },
            {
                "name": "Rustburg",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Saltville",
                "countryCode": "US"
            },
            {
                "name": "Saluda",
                "countryCode": "US"
            },
            {
                "name": "Sandston",
                "countryCode": "US"
            },
            {
                "name": "Scott County",
                "countryCode": "US"
            },
            {
                "name": "Seven Corners",
                "countryCode": "US"
            },
            {
                "name": "Shawnee Land",
                "countryCode": "US"
            },
            {
                "name": "Shawsville",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah County",
                "countryCode": "US"
            },
            {
                "name": "Shenandoah Farms",
                "countryCode": "US"
            },
            {
                "name": "Short Pump",
                "countryCode": "US"
            },
            {
                "name": "Smithfield",
                "countryCode": "US"
            },
            {
                "name": "Smyth County",
                "countryCode": "US"
            },
            {
                "name": "South Boston",
                "countryCode": "US"
            },
            {
                "name": "South Hill",
                "countryCode": "US"
            },
            {
                "name": "South Riding",
                "countryCode": "US"
            },
            {
                "name": "South Suffolk",
                "countryCode": "US"
            },
            {
                "name": "Southampton County",
                "countryCode": "US"
            },
            {
                "name": "Southern Gateway",
                "countryCode": "US"
            },
            {
                "name": "Spotsylvania County",
                "countryCode": "US"
            },
            {
                "name": "Spotsylvania Courthouse",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "Springville",
                "countryCode": "US"
            },
            {
                "name": "Stafford",
                "countryCode": "US"
            },
            {
                "name": "Stafford County",
                "countryCode": "US"
            },
            {
                "name": "Stanardsville",
                "countryCode": "US"
            },
            {
                "name": "Stanley",
                "countryCode": "US"
            },
            {
                "name": "Stanleytown",
                "countryCode": "US"
            },
            {
                "name": "Staunton",
                "countryCode": "US"
            },
            {
                "name": "Stephens City",
                "countryCode": "US"
            },
            {
                "name": "Sterling",
                "countryCode": "US"
            },
            {
                "name": "Strasburg",
                "countryCode": "US"
            },
            {
                "name": "Stuart",
                "countryCode": "US"
            },
            {
                "name": "Stuarts Draft",
                "countryCode": "US"
            },
            {
                "name": "Sudley",
                "countryCode": "US"
            },
            {
                "name": "Suffolk",
                "countryCode": "US"
            },
            {
                "name": "Sugarland Run",
                "countryCode": "US"
            },
            {
                "name": "Surry",
                "countryCode": "US"
            },
            {
                "name": "Surry County",
                "countryCode": "US"
            },
            {
                "name": "Sussex",
                "countryCode": "US"
            },
            {
                "name": "Sussex County",
                "countryCode": "US"
            },
            {
                "name": "Tappahannock",
                "countryCode": "US"
            },
            {
                "name": "Tazewell",
                "countryCode": "US"
            },
            {
                "name": "Tazewell County",
                "countryCode": "US"
            },
            {
                "name": "Timberlake",
                "countryCode": "US"
            },
            {
                "name": "Timberville",
                "countryCode": "US"
            },
            {
                "name": "Triangle",
                "countryCode": "US"
            },
            {
                "name": "Tuckahoe",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Tysons Corner",
                "countryCode": "US"
            },
            {
                "name": "Union Hall",
                "countryCode": "US"
            },
            {
                "name": "University Center",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Victoria",
                "countryCode": "US"
            },
            {
                "name": "Vienna",
                "countryCode": "US"
            },
            {
                "name": "Vinton",
                "countryCode": "US"
            },
            {
                "name": "Virginia Beach",
                "countryCode": "US"
            },
            {
                "name": "Warm Springs",
                "countryCode": "US"
            },
            {
                "name": "Warren County",
                "countryCode": "US"
            },
            {
                "name": "Warrenton",
                "countryCode": "US"
            },
            {
                "name": "Warsaw",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Wattsville",
                "countryCode": "US"
            },
            {
                "name": "Waverly",
                "countryCode": "US"
            },
            {
                "name": "Waynesboro",
                "countryCode": "US"
            },
            {
                "name": "Weber City",
                "countryCode": "US"
            },
            {
                "name": "West Falls Church",
                "countryCode": "US"
            },
            {
                "name": "West Gate",
                "countryCode": "US"
            },
            {
                "name": "West Lynchburg",
                "countryCode": "US"
            },
            {
                "name": "West Point",
                "countryCode": "US"
            },
            {
                "name": "West Springfield",
                "countryCode": "US"
            },
            {
                "name": "Westmoreland County",
                "countryCode": "US"
            },
            {
                "name": "Weyers Cave",
                "countryCode": "US"
            },
            {
                "name": "Williamsburg",
                "countryCode": "US"
            },
            {
                "name": "Winchester",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Wise",
                "countryCode": "US"
            },
            {
                "name": "Wise County",
                "countryCode": "US"
            },
            {
                "name": "Wolf Trap",
                "countryCode": "US"
            },
            {
                "name": "Woodbridge",
                "countryCode": "US"
            },
            {
                "name": "Woodburn",
                "countryCode": "US"
            },
            {
                "name": "Woodlake",
                "countryCode": "US"
            },
            {
                "name": "Woodlawn",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Wyndham",
                "countryCode": "US"
            },
            {
                "name": "Wythe County",
                "countryCode": "US"
            },
            {
                "name": "Wytheville",
                "countryCode": "US"
            },
            {
                "name": "York County",
                "countryCode": "US"
            },
            {
                "name": "Yorkshire",
                "countryCode": "US"
            },
            {
                "name": "Yorktown",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Addison County",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Barre",
                "countryCode": "US"
            },
            {
                "name": "Bellows Falls",
                "countryCode": "US"
            },
            {
                "name": "Bennington",
                "countryCode": "US"
            },
            {
                "name": "Bennington County",
                "countryCode": "US"
            },
            {
                "name": "Brandon",
                "countryCode": "US"
            },
            {
                "name": "Brattleboro",
                "countryCode": "US"
            },
            {
                "name": "Bridport",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Caledonia County",
                "countryCode": "US"
            },
            {
                "name": "Castleton",
                "countryCode": "US"
            },
            {
                "name": "Charlotte",
                "countryCode": "US"
            },
            {
                "name": "Chelsea",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Chittenden",
                "countryCode": "US"
            },
            {
                "name": "Chittenden County",
                "countryCode": "US"
            },
            {
                "name": "Clarendon",
                "countryCode": "US"
            },
            {
                "name": "Colchester",
                "countryCode": "US"
            },
            {
                "name": "Danby",
                "countryCode": "US"
            },
            {
                "name": "Dover",
                "countryCode": "US"
            },
            {
                "name": "Enosburg Falls",
                "countryCode": "US"
            },
            {
                "name": "Essex County",
                "countryCode": "US"
            },
            {
                "name": "Essex Junction",
                "countryCode": "US"
            },
            {
                "name": "Fair Haven",
                "countryCode": "US"
            },
            {
                "name": "Ferrisburgh",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Grand Isle County",
                "countryCode": "US"
            },
            {
                "name": "Guildhall",
                "countryCode": "US"
            },
            {
                "name": "Hardwick",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hinesburg",
                "countryCode": "US"
            },
            {
                "name": "Hyde Park",
                "countryCode": "US"
            },
            {
                "name": "Jamaica",
                "countryCode": "US"
            },
            {
                "name": "Jericho",
                "countryCode": "US"
            },
            {
                "name": "Johnson",
                "countryCode": "US"
            },
            {
                "name": "Lamoille County",
                "countryCode": "US"
            },
            {
                "name": "Leicester",
                "countryCode": "US"
            },
            {
                "name": "Lincoln",
                "countryCode": "US"
            },
            {
                "name": "Londonderry",
                "countryCode": "US"
            },
            {
                "name": "Lunenburg",
                "countryCode": "US"
            },
            {
                "name": "Lyndon",
                "countryCode": "US"
            },
            {
                "name": "Lyndonville",
                "countryCode": "US"
            },
            {
                "name": "Manchester Center",
                "countryCode": "US"
            },
            {
                "name": "Mendon",
                "countryCode": "US"
            },
            {
                "name": "Middlebury (village)",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Montpelier",
                "countryCode": "US"
            },
            {
                "name": "Moretown",
                "countryCode": "US"
            },
            {
                "name": "Morristown",
                "countryCode": "US"
            },
            {
                "name": "Morrisville",
                "countryCode": "US"
            },
            {
                "name": "Mount Holly",
                "countryCode": "US"
            },
            {
                "name": "Newfane",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "North Bennington",
                "countryCode": "US"
            },
            {
                "name": "North Hero",
                "countryCode": "US"
            },
            {
                "name": "Northfield",
                "countryCode": "US"
            },
            {
                "name": "Orange County",
                "countryCode": "US"
            },
            {
                "name": "Orleans County",
                "countryCode": "US"
            },
            {
                "name": "Pawlet",
                "countryCode": "US"
            },
            {
                "name": "Poultney",
                "countryCode": "US"
            },
            {
                "name": "Pownal",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Richford",
                "countryCode": "US"
            },
            {
                "name": "Rockingham",
                "countryCode": "US"
            },
            {
                "name": "Rutland",
                "countryCode": "US"
            },
            {
                "name": "Rutland County",
                "countryCode": "US"
            },
            {
                "name": "Saint Albans",
                "countryCode": "US"
            },
            {
                "name": "Saint Johnsbury",
                "countryCode": "US"
            },
            {
                "name": "Salisbury",
                "countryCode": "US"
            },
            {
                "name": "South Barre",
                "countryCode": "US"
            },
            {
                "name": "South Burlington",
                "countryCode": "US"
            },
            {
                "name": "Springfield",
                "countryCode": "US"
            },
            {
                "name": "St Johnsbury",
                "countryCode": "US"
            },
            {
                "name": "Starksboro",
                "countryCode": "US"
            },
            {
                "name": "Stowe",
                "countryCode": "US"
            },
            {
                "name": "Swanton",
                "countryCode": "US"
            },
            {
                "name": "Townshend",
                "countryCode": "US"
            },
            {
                "name": "Vergennes",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterbury",
                "countryCode": "US"
            },
            {
                "name": "West Brattleboro",
                "countryCode": "US"
            },
            {
                "name": "West Rutland",
                "countryCode": "US"
            },
            {
                "name": "White River Junction",
                "countryCode": "US"
            },
            {
                "name": "Wilder",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Williston",
                "countryCode": "US"
            },
            {
                "name": "Windham County",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Windsor County",
                "countryCode": "US"
            },
            {
                "name": "Winooski",
                "countryCode": "US"
            },
            {
                "name": "Woodstock",
                "countryCode": "US"
            },
            {
                "name": "Aberdeen",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Ahtanum",
                "countryCode": "US"
            },
            {
                "name": "Airway Heights",
                "countryCode": "US"
            },
            {
                "name": "Alderton",
                "countryCode": "US"
            },
            {
                "name": "Alderwood Manor",
                "countryCode": "US"
            },
            {
                "name": "Algona",
                "countryCode": "US"
            },
            {
                "name": "Allyn",
                "countryCode": "US"
            },
            {
                "name": "Amboy",
                "countryCode": "US"
            },
            {
                "name": "Ames Lake",
                "countryCode": "US"
            },
            {
                "name": "Anacortes",
                "countryCode": "US"
            },
            {
                "name": "Arlington",
                "countryCode": "US"
            },
            {
                "name": "Arlington Heights",
                "countryCode": "US"
            },
            {
                "name": "Artondale",
                "countryCode": "US"
            },
            {
                "name": "Asotin",
                "countryCode": "US"
            },
            {
                "name": "Asotin County",
                "countryCode": "US"
            },
            {
                "name": "Auburn",
                "countryCode": "US"
            },
            {
                "name": "Ault Field",
                "countryCode": "US"
            },
            {
                "name": "Bainbridge Island",
                "countryCode": "US"
            },
            {
                "name": "Bangor Trident Base",
                "countryCode": "US"
            },
            {
                "name": "Barberton",
                "countryCode": "US"
            },
            {
                "name": "Basin City",
                "countryCode": "US"
            },
            {
                "name": "Battle Ground",
                "countryCode": "US"
            },
            {
                "name": "Belfair",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Bellingham",
                "countryCode": "US"
            },
            {
                "name": "Benton City",
                "countryCode": "US"
            },
            {
                "name": "Benton County",
                "countryCode": "US"
            },
            {
                "name": "Bethel",
                "countryCode": "US"
            },
            {
                "name": "Big Lake",
                "countryCode": "US"
            },
            {
                "name": "Birch Bay",
                "countryCode": "US"
            },
            {
                "name": "Black Diamond",
                "countryCode": "US"
            },
            {
                "name": "Blaine",
                "countryCode": "US"
            },
            {
                "name": "Bonney Lake",
                "countryCode": "US"
            },
            {
                "name": "Bothell",
                "countryCode": "US"
            },
            {
                "name": "Bothell East",
                "countryCode": "US"
            },
            {
                "name": "Bothell West",
                "countryCode": "US"
            },
            {
                "name": "Boulevard Park",
                "countryCode": "US"
            },
            {
                "name": "Bremerton",
                "countryCode": "US"
            },
            {
                "name": "Brewster",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Brier",
                "countryCode": "US"
            },
            {
                "name": "Browns Point",
                "countryCode": "US"
            },
            {
                "name": "Brush Prairie",
                "countryCode": "US"
            },
            {
                "name": "Bryant",
                "countryCode": "US"
            },
            {
                "name": "Bryn Mawr-Skyway",
                "countryCode": "US"
            },
            {
                "name": "Buckley",
                "countryCode": "US"
            },
            {
                "name": "Bunk Foss",
                "countryCode": "US"
            },
            {
                "name": "Burbank",
                "countryCode": "US"
            },
            {
                "name": "Burien",
                "countryCode": "US"
            },
            {
                "name": "Burley",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Camano",
                "countryCode": "US"
            },
            {
                "name": "Camas",
                "countryCode": "US"
            },
            {
                "name": "Canterwood",
                "countryCode": "US"
            },
            {
                "name": "Carnation",
                "countryCode": "US"
            },
            {
                "name": "Carson",
                "countryCode": "US"
            },
            {
                "name": "Cascade Valley",
                "countryCode": "US"
            },
            {
                "name": "Cashmere",
                "countryCode": "US"
            },
            {
                "name": "Castle Rock",
                "countryCode": "US"
            },
            {
                "name": "Cathcart",
                "countryCode": "US"
            },
            {
                "name": "Cathlamet",
                "countryCode": "US"
            },
            {
                "name": "Central Park",
                "countryCode": "US"
            },
            {
                "name": "Centralia",
                "countryCode": "US"
            },
            {
                "name": "Chehalis",
                "countryCode": "US"
            },
            {
                "name": "Chelan",
                "countryCode": "US"
            },
            {
                "name": "Chelan County",
                "countryCode": "US"
            },
            {
                "name": "Cheney",
                "countryCode": "US"
            },
            {
                "name": "Chewelah",
                "countryCode": "US"
            },
            {
                "name": "Chico",
                "countryCode": "US"
            },
            {
                "name": "City of Sammamish",
                "countryCode": "US"
            },
            {
                "name": "Clallam County",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clarkston",
                "countryCode": "US"
            },
            {
                "name": "Clarkston Heights-Vineland",
                "countryCode": "US"
            },
            {
                "name": "Cle Elum",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake",
                "countryCode": "US"
            },
            {
                "name": "Clearview",
                "countryCode": "US"
            },
            {
                "name": "Clyde Hill",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "College Place",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Colville",
                "countryCode": "US"
            },
            {
                "name": "Connell",
                "countryCode": "US"
            },
            {
                "name": "Cosmopolis",
                "countryCode": "US"
            },
            {
                "name": "Cottage Lake",
                "countryCode": "US"
            },
            {
                "name": "Coulee Dam",
                "countryCode": "US"
            },
            {
                "name": "Country Homes",
                "countryCode": "US"
            },
            {
                "name": "Coupeville",
                "countryCode": "US"
            },
            {
                "name": "Covington",
                "countryCode": "US"
            },
            {
                "name": "Cowlitz County",
                "countryCode": "US"
            },
            {
                "name": "Crocker",
                "countryCode": "US"
            },
            {
                "name": "Dallesport",
                "countryCode": "US"
            },
            {
                "name": "Darrington",
                "countryCode": "US"
            },
            {
                "name": "Davenport",
                "countryCode": "US"
            },
            {
                "name": "Dayton",
                "countryCode": "US"
            },
            {
                "name": "Deer Park",
                "countryCode": "US"
            },
            {
                "name": "Des Moines",
                "countryCode": "US"
            },
            {
                "name": "Desert Aire",
                "countryCode": "US"
            },
            {
                "name": "Dishman",
                "countryCode": "US"
            },
            {
                "name": "Dollar Corner",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "DuPont",
                "countryCode": "US"
            },
            {
                "name": "Duvall",
                "countryCode": "US"
            },
            {
                "name": "East Hill-Meridian",
                "countryCode": "US"
            },
            {
                "name": "East Port Orchard",
                "countryCode": "US"
            },
            {
                "name": "East Renton Highlands",
                "countryCode": "US"
            },
            {
                "name": "East Wenatchee",
                "countryCode": "US"
            },
            {
                "name": "East Wenatchee Bench",
                "countryCode": "US"
            },
            {
                "name": "Eastgate",
                "countryCode": "US"
            },
            {
                "name": "Eastmont",
                "countryCode": "US"
            },
            {
                "name": "Eatonville",
                "countryCode": "US"
            },
            {
                "name": "Edgewood",
                "countryCode": "US"
            },
            {
                "name": "Edmonds",
                "countryCode": "US"
            },
            {
                "name": "Electric City",
                "countryCode": "US"
            },
            {
                "name": "Elk Plain",
                "countryCode": "US"
            },
            {
                "name": "Ellensburg",
                "countryCode": "US"
            },
            {
                "name": "Elma",
                "countryCode": "US"
            },
            {
                "name": "Enetai",
                "countryCode": "US"
            },
            {
                "name": "Entiat",
                "countryCode": "US"
            },
            {
                "name": "Enumclaw",
                "countryCode": "US"
            },
            {
                "name": "Ephrata",
                "countryCode": "US"
            },
            {
                "name": "Erlands Point-Kitsap Lake",
                "countryCode": "US"
            },
            {
                "name": "Esperance",
                "countryCode": "US"
            },
            {
                "name": "Everett",
                "countryCode": "US"
            },
            {
                "name": "Everson",
                "countryCode": "US"
            },
            {
                "name": "Fairchild Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "Fairwood",
                "countryCode": "US"
            },
            {
                "name": "Fall City",
                "countryCode": "US"
            },
            {
                "name": "Federal Way",
                "countryCode": "US"
            },
            {
                "name": "Felida",
                "countryCode": "US"
            },
            {
                "name": "Fern Prairie",
                "countryCode": "US"
            },
            {
                "name": "Ferndale",
                "countryCode": "US"
            },
            {
                "name": "Ferry County",
                "countryCode": "US"
            },
            {
                "name": "Fife",
                "countryCode": "US"
            },
            {
                "name": "Fife Heights",
                "countryCode": "US"
            },
            {
                "name": "Finley",
                "countryCode": "US"
            },
            {
                "name": "Fircrest",
                "countryCode": "US"
            },
            {
                "name": "Five Corners",
                "countryCode": "US"
            },
            {
                "name": "Fobes Hill",
                "countryCode": "US"
            },
            {
                "name": "Fords Prairie",
                "countryCode": "US"
            },
            {
                "name": "Forks",
                "countryCode": "US"
            },
            {
                "name": "Fox Island",
                "countryCode": "US"
            },
            {
                "name": "Franklin County",
                "countryCode": "US"
            },
            {
                "name": "Frederickson",
                "countryCode": "US"
            },
            {
                "name": "Freeland",
                "countryCode": "US"
            },
            {
                "name": "Friday Harbor",
                "countryCode": "US"
            },
            {
                "name": "Garfield County",
                "countryCode": "US"
            },
            {
                "name": "Garrett",
                "countryCode": "US"
            },
            {
                "name": "Geneva",
                "countryCode": "US"
            },
            {
                "name": "Gig Harbor",
                "countryCode": "US"
            },
            {
                "name": "Gleed",
                "countryCode": "US"
            },
            {
                "name": "Gold Bar",
                "countryCode": "US"
            },
            {
                "name": "Goldendale",
                "countryCode": "US"
            },
            {
                "name": "Graham",
                "countryCode": "US"
            },
            {
                "name": "Grand Coulee",
                "countryCode": "US"
            },
            {
                "name": "Grand Mound",
                "countryCode": "US"
            },
            {
                "name": "Grandview",
                "countryCode": "US"
            },
            {
                "name": "Granger",
                "countryCode": "US"
            },
            {
                "name": "Granite Falls",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Grays Harbor County",
                "countryCode": "US"
            },
            {
                "name": "Hansville",
                "countryCode": "US"
            },
            {
                "name": "Hazel Dell",
                "countryCode": "US"
            },
            {
                "name": "Highland",
                "countryCode": "US"
            },
            {
                "name": "Hobart",
                "countryCode": "US"
            },
            {
                "name": "Hockinson",
                "countryCode": "US"
            },
            {
                "name": "Home",
                "countryCode": "US"
            },
            {
                "name": "Hoquiam",
                "countryCode": "US"
            },
            {
                "name": "Indianola",
                "countryCode": "US"
            },
            {
                "name": "Inglewood-Finn Hill",
                "countryCode": "US"
            },
            {
                "name": "Island County",
                "countryCode": "US"
            },
            {
                "name": "Issaquah",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Joint Base Lewis McChord",
                "countryCode": "US"
            },
            {
                "name": "Kalama",
                "countryCode": "US"
            },
            {
                "name": "Kelso",
                "countryCode": "US"
            },
            {
                "name": "Kenmore",
                "countryCode": "US"
            },
            {
                "name": "Kennewick",
                "countryCode": "US"
            },
            {
                "name": "Kent",
                "countryCode": "US"
            },
            {
                "name": "Kettle Falls",
                "countryCode": "US"
            },
            {
                "name": "Key Center",
                "countryCode": "US"
            },
            {
                "name": "King County",
                "countryCode": "US"
            },
            {
                "name": "Kingsgate",
                "countryCode": "US"
            },
            {
                "name": "Kingston",
                "countryCode": "US"
            },
            {
                "name": "Kirkland",
                "countryCode": "US"
            },
            {
                "name": "Kitsap County",
                "countryCode": "US"
            },
            {
                "name": "Kittitas",
                "countryCode": "US"
            },
            {
                "name": "Kittitas County",
                "countryCode": "US"
            },
            {
                "name": "Klahanie",
                "countryCode": "US"
            },
            {
                "name": "Klickitat County",
                "countryCode": "US"
            },
            {
                "name": "La Center",
                "countryCode": "US"
            },
            {
                "name": "Lacey",
                "countryCode": "US"
            },
            {
                "name": "Lake Forest Park",
                "countryCode": "US"
            },
            {
                "name": "Lake Marcel-Stillwater",
                "countryCode": "US"
            },
            {
                "name": "Lake Morton-Berrydale",
                "countryCode": "US"
            },
            {
                "name": "Lake Shore",
                "countryCode": "US"
            },
            {
                "name": "Lake Stevens",
                "countryCode": "US"
            },
            {
                "name": "Lake Stickney",
                "countryCode": "US"
            },
            {
                "name": "Lakeland North",
                "countryCode": "US"
            },
            {
                "name": "Lakeland South",
                "countryCode": "US"
            },
            {
                "name": "Lakewood",
                "countryCode": "US"
            },
            {
                "name": "Langley",
                "countryCode": "US"
            },
            {
                "name": "Larch Way",
                "countryCode": "US"
            },
            {
                "name": "Lea Hill",
                "countryCode": "US"
            },
            {
                "name": "Leavenworth",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewisville",
                "countryCode": "US"
            },
            {
                "name": "Liberty Lake",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lochsloy",
                "countryCode": "US"
            },
            {
                "name": "Lofall",
                "countryCode": "US"
            },
            {
                "name": "Long Beach",
                "countryCode": "US"
            },
            {
                "name": "Longbranch",
                "countryCode": "US"
            },
            {
                "name": "Longview",
                "countryCode": "US"
            },
            {
                "name": "Longview Heights",
                "countryCode": "US"
            },
            {
                "name": "Lynden",
                "countryCode": "US"
            },
            {
                "name": "Lynnwood",
                "countryCode": "US"
            },
            {
                "name": "Mabton",
                "countryCode": "US"
            },
            {
                "name": "Machias",
                "countryCode": "US"
            },
            {
                "name": "Maltby",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manson",
                "countryCode": "US"
            },
            {
                "name": "Maple Heights-Lake Desire",
                "countryCode": "US"
            },
            {
                "name": "Maple Valley",
                "countryCode": "US"
            },
            {
                "name": "Maplewood",
                "countryCode": "US"
            },
            {
                "name": "Marietta",
                "countryCode": "US"
            },
            {
                "name": "Marietta-Alderwood",
                "countryCode": "US"
            },
            {
                "name": "Martha Lake",
                "countryCode": "US"
            },
            {
                "name": "Marysville",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "Mattawa",
                "countryCode": "US"
            },
            {
                "name": "McChord Air Force Base",
                "countryCode": "US"
            },
            {
                "name": "McCleary",
                "countryCode": "US"
            },
            {
                "name": "McMillin",
                "countryCode": "US"
            },
            {
                "name": "Mead",
                "countryCode": "US"
            },
            {
                "name": "Meadow Glade",
                "countryCode": "US"
            },
            {
                "name": "Meadowdale",
                "countryCode": "US"
            },
            {
                "name": "Medical Lake",
                "countryCode": "US"
            },
            {
                "name": "Medina",
                "countryCode": "US"
            },
            {
                "name": "Mercer Island",
                "countryCode": "US"
            },
            {
                "name": "Midland",
                "countryCode": "US"
            },
            {
                "name": "Mill Creek",
                "countryCode": "US"
            },
            {
                "name": "Mill Creek East",
                "countryCode": "US"
            },
            {
                "name": "Mill Plain",
                "countryCode": "US"
            },
            {
                "name": "Millwood",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Minnehaha",
                "countryCode": "US"
            },
            {
                "name": "Mirrormont",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe North",
                "countryCode": "US"
            },
            {
                "name": "Montesano",
                "countryCode": "US"
            },
            {
                "name": "Morton",
                "countryCode": "US"
            },
            {
                "name": "Moses Lake",
                "countryCode": "US"
            },
            {
                "name": "Moses Lake North",
                "countryCode": "US"
            },
            {
                "name": "Mount Vernon",
                "countryCode": "US"
            },
            {
                "name": "Mount Vista",
                "countryCode": "US"
            },
            {
                "name": "Mountlake Terrace",
                "countryCode": "US"
            },
            {
                "name": "Mukilteo",
                "countryCode": "US"
            },
            {
                "name": "Napavine",
                "countryCode": "US"
            },
            {
                "name": "Navy Yard City",
                "countryCode": "US"
            },
            {
                "name": "Newcastle",
                "countryCode": "US"
            },
            {
                "name": "Newport",
                "countryCode": "US"
            },
            {
                "name": "Nooksack",
                "countryCode": "US"
            },
            {
                "name": "Normandy Park",
                "countryCode": "US"
            },
            {
                "name": "North Bend",
                "countryCode": "US"
            },
            {
                "name": "North Creek",
                "countryCode": "US"
            },
            {
                "name": "North Fort Lewis",
                "countryCode": "US"
            },
            {
                "name": "North Puyallup",
                "countryCode": "US"
            },
            {
                "name": "North Yelm",
                "countryCode": "US"
            },
            {
                "name": "Oak Harbor",
                "countryCode": "US"
            },
            {
                "name": "Ocean Park",
                "countryCode": "US"
            },
            {
                "name": "Ocean Shores",
                "countryCode": "US"
            },
            {
                "name": "Okanogan",
                "countryCode": "US"
            },
            {
                "name": "Okanogan County",
                "countryCode": "US"
            },
            {
                "name": "Olympia",
                "countryCode": "US"
            },
            {
                "name": "Omak",
                "countryCode": "US"
            },
            {
                "name": "Opportunity",
                "countryCode": "US"
            },
            {
                "name": "Orchards",
                "countryCode": "US"
            },
            {
                "name": "Oroville",
                "countryCode": "US"
            },
            {
                "name": "Orting",
                "countryCode": "US"
            },
            {
                "name": "Othello",
                "countryCode": "US"
            },
            {
                "name": "Otis Orchards-East Farms",
                "countryCode": "US"
            },
            {
                "name": "Pacific",
                "countryCode": "US"
            },
            {
                "name": "Pacific County",
                "countryCode": "US"
            },
            {
                "name": "Palouse",
                "countryCode": "US"
            },
            {
                "name": "Parkland",
                "countryCode": "US"
            },
            {
                "name": "Parkwood",
                "countryCode": "US"
            },
            {
                "name": "Pasco",
                "countryCode": "US"
            },
            {
                "name": "Peaceful Valley",
                "countryCode": "US"
            },
            {
                "name": "Pend Oreille County",
                "countryCode": "US"
            },
            {
                "name": "Picnic Point",
                "countryCode": "US"
            },
            {
                "name": "Picnic Point-North Lynnwood",
                "countryCode": "US"
            },
            {
                "name": "Pierce County",
                "countryCode": "US"
            },
            {
                "name": "Point Roberts",
                "countryCode": "US"
            },
            {
                "name": "Pomeroy",
                "countryCode": "US"
            },
            {
                "name": "Port Angeles",
                "countryCode": "US"
            },
            {
                "name": "Port Angeles East",
                "countryCode": "US"
            },
            {
                "name": "Port Hadlock-Irondale",
                "countryCode": "US"
            },
            {
                "name": "Port Ludlow",
                "countryCode": "US"
            },
            {
                "name": "Port Orchard",
                "countryCode": "US"
            },
            {
                "name": "Port Townsend",
                "countryCode": "US"
            },
            {
                "name": "Poulsbo",
                "countryCode": "US"
            },
            {
                "name": "Prairie Heights",
                "countryCode": "US"
            },
            {
                "name": "Prairie Ridge",
                "countryCode": "US"
            },
            {
                "name": "Prosser",
                "countryCode": "US"
            },
            {
                "name": "Pullman",
                "countryCode": "US"
            },
            {
                "name": "Purdy",
                "countryCode": "US"
            },
            {
                "name": "Puyallup",
                "countryCode": "US"
            },
            {
                "name": "Quincy",
                "countryCode": "US"
            },
            {
                "name": "Rainier",
                "countryCode": "US"
            },
            {
                "name": "Ravensdale",
                "countryCode": "US"
            },
            {
                "name": "Raymond",
                "countryCode": "US"
            },
            {
                "name": "Redmond",
                "countryCode": "US"
            },
            {
                "name": "Renton",
                "countryCode": "US"
            },
            {
                "name": "Republic",
                "countryCode": "US"
            },
            {
                "name": "Richland",
                "countryCode": "US"
            },
            {
                "name": "Ridgefield",
                "countryCode": "US"
            },
            {
                "name": "Ritzville",
                "countryCode": "US"
            },
            {
                "name": "Riverbend",
                "countryCode": "US"
            },
            {
                "name": "Riverton",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rocky Point",
                "countryCode": "US"
            },
            {
                "name": "Rosedale",
                "countryCode": "US"
            },
            {
                "name": "Royal City",
                "countryCode": "US"
            },
            {
                "name": "Salmon Creek",
                "countryCode": "US"
            },
            {
                "name": "Sammamish",
                "countryCode": "US"
            },
            {
                "name": "San Juan County",
                "countryCode": "US"
            },
            {
                "name": "SeaTac",
                "countryCode": "US"
            },
            {
                "name": "Seabeck",
                "countryCode": "US"
            },
            {
                "name": "Seattle",
                "countryCode": "US"
            },
            {
                "name": "Sedro-Woolley",
                "countryCode": "US"
            },
            {
                "name": "Selah",
                "countryCode": "US"
            },
            {
                "name": "Sequim",
                "countryCode": "US"
            },
            {
                "name": "Shelton",
                "countryCode": "US"
            },
            {
                "name": "Shoreline",
                "countryCode": "US"
            },
            {
                "name": "Silver Firs",
                "countryCode": "US"
            },
            {
                "name": "Silverdale",
                "countryCode": "US"
            },
            {
                "name": "Sisco Heights",
                "countryCode": "US"
            },
            {
                "name": "Skagit County",
                "countryCode": "US"
            },
            {
                "name": "Skamania County",
                "countryCode": "US"
            },
            {
                "name": "Smokey Point",
                "countryCode": "US"
            },
            {
                "name": "Snohomish",
                "countryCode": "US"
            },
            {
                "name": "Snohomish County",
                "countryCode": "US"
            },
            {
                "name": "Snoqualmie",
                "countryCode": "US"
            },
            {
                "name": "Soap Lake",
                "countryCode": "US"
            },
            {
                "name": "South Bend",
                "countryCode": "US"
            },
            {
                "name": "South Hill",
                "countryCode": "US"
            },
            {
                "name": "South Wenatchee",
                "countryCode": "US"
            },
            {
                "name": "Southworth",
                "countryCode": "US"
            },
            {
                "name": "Spanaway",
                "countryCode": "US"
            },
            {
                "name": "Spokane",
                "countryCode": "US"
            },
            {
                "name": "Spokane County",
                "countryCode": "US"
            },
            {
                "name": "Spokane Valley",
                "countryCode": "US"
            },
            {
                "name": "Stanwood",
                "countryCode": "US"
            },
            {
                "name": "Steilacoom",
                "countryCode": "US"
            },
            {
                "name": "Stevens County",
                "countryCode": "US"
            },
            {
                "name": "Stevenson",
                "countryCode": "US"
            },
            {
                "name": "Sudden Valley",
                "countryCode": "US"
            },
            {
                "name": "Sultan",
                "countryCode": "US"
            },
            {
                "name": "Sumas",
                "countryCode": "US"
            },
            {
                "name": "Summit",
                "countryCode": "US"
            },
            {
                "name": "Summit View",
                "countryCode": "US"
            },
            {
                "name": "Sumner",
                "countryCode": "US"
            },
            {
                "name": "Sunnyside",
                "countryCode": "US"
            },
            {
                "name": "Sunnyslope",
                "countryCode": "US"
            },
            {
                "name": "Suquamish",
                "countryCode": "US"
            },
            {
                "name": "Tacoma",
                "countryCode": "US"
            },
            {
                "name": "Tanglewilde",
                "countryCode": "US"
            },
            {
                "name": "Tanglewilde-Thompson Place",
                "countryCode": "US"
            },
            {
                "name": "Tanner",
                "countryCode": "US"
            },
            {
                "name": "Tenino",
                "countryCode": "US"
            },
            {
                "name": "Terrace Heights",
                "countryCode": "US"
            },
            {
                "name": "Three Lakes",
                "countryCode": "US"
            },
            {
                "name": "Thurston County",
                "countryCode": "US"
            },
            {
                "name": "Tieton",
                "countryCode": "US"
            },
            {
                "name": "Tonasket",
                "countryCode": "US"
            },
            {
                "name": "Toppenish",
                "countryCode": "US"
            },
            {
                "name": "Town and Country",
                "countryCode": "US"
            },
            {
                "name": "Tracyton",
                "countryCode": "US"
            },
            {
                "name": "Trentwood",
                "countryCode": "US"
            },
            {
                "name": "Tukwila",
                "countryCode": "US"
            },
            {
                "name": "Tulalip",
                "countryCode": "US"
            },
            {
                "name": "Tulalip Bay",
                "countryCode": "US"
            },
            {
                "name": "Tumwater",
                "countryCode": "US"
            },
            {
                "name": "Union Gap",
                "countryCode": "US"
            },
            {
                "name": "Union Hill-Novelty Hill",
                "countryCode": "US"
            },
            {
                "name": "University Place",
                "countryCode": "US"
            },
            {
                "name": "Vancouver",
                "countryCode": "US"
            },
            {
                "name": "Vashon",
                "countryCode": "US"
            },
            {
                "name": "Venersborg",
                "countryCode": "US"
            },
            {
                "name": "Veradale",
                "countryCode": "US"
            },
            {
                "name": "Wahkiakum County",
                "countryCode": "US"
            },
            {
                "name": "Waitsburg",
                "countryCode": "US"
            },
            {
                "name": "Walla Walla",
                "countryCode": "US"
            },
            {
                "name": "Walla Walla County",
                "countryCode": "US"
            },
            {
                "name": "Walla Walla East",
                "countryCode": "US"
            },
            {
                "name": "Waller",
                "countryCode": "US"
            },
            {
                "name": "Walnut Grove",
                "countryCode": "US"
            },
            {
                "name": "Wapato",
                "countryCode": "US"
            },
            {
                "name": "Warden",
                "countryCode": "US"
            },
            {
                "name": "Warm Beach",
                "countryCode": "US"
            },
            {
                "name": "Washougal",
                "countryCode": "US"
            },
            {
                "name": "Waterville",
                "countryCode": "US"
            },
            {
                "name": "Wauna",
                "countryCode": "US"
            },
            {
                "name": "Wenatchee",
                "countryCode": "US"
            },
            {
                "name": "West Clarkston-Highland",
                "countryCode": "US"
            },
            {
                "name": "West Lake Sammamish",
                "countryCode": "US"
            },
            {
                "name": "West Lake Stevens",
                "countryCode": "US"
            },
            {
                "name": "West Longview",
                "countryCode": "US"
            },
            {
                "name": "West Pasco",
                "countryCode": "US"
            },
            {
                "name": "West Richland",
                "countryCode": "US"
            },
            {
                "name": "West Side Highway",
                "countryCode": "US"
            },
            {
                "name": "West Valley",
                "countryCode": "US"
            },
            {
                "name": "West Wenatchee",
                "countryCode": "US"
            },
            {
                "name": "Westport",
                "countryCode": "US"
            },
            {
                "name": "Whatcom County",
                "countryCode": "US"
            },
            {
                "name": "White Center",
                "countryCode": "US"
            },
            {
                "name": "White Salmon",
                "countryCode": "US"
            },
            {
                "name": "Whitman County",
                "countryCode": "US"
            },
            {
                "name": "Wilderness Rim",
                "countryCode": "US"
            },
            {
                "name": "Winlock",
                "countryCode": "US"
            },
            {
                "name": "Wollochet",
                "countryCode": "US"
            },
            {
                "name": "Woodinville",
                "countryCode": "US"
            },
            {
                "name": "Woodland",
                "countryCode": "US"
            },
            {
                "name": "Woods Creek",
                "countryCode": "US"
            },
            {
                "name": "Woodway",
                "countryCode": "US"
            },
            {
                "name": "Yacolt",
                "countryCode": "US"
            },
            {
                "name": "Yakima",
                "countryCode": "US"
            },
            {
                "name": "Yakima County",
                "countryCode": "US"
            },
            {
                "name": "Yarrow Point",
                "countryCode": "US"
            },
            {
                "name": "Yelm",
                "countryCode": "US"
            },
            {
                "name": "Zillah",
                "countryCode": "US"
            },
            {
                "name": "Abbotsford",
                "countryCode": "US"
            },
            {
                "name": "Adams",
                "countryCode": "US"
            },
            {
                "name": "Adams County",
                "countryCode": "US"
            },
            {
                "name": "Addison",
                "countryCode": "US"
            },
            {
                "name": "Albany",
                "countryCode": "US"
            },
            {
                "name": "Algoma",
                "countryCode": "US"
            },
            {
                "name": "Allouez",
                "countryCode": "US"
            },
            {
                "name": "Alma",
                "countryCode": "US"
            },
            {
                "name": "Alto",
                "countryCode": "US"
            },
            {
                "name": "Altoona",
                "countryCode": "US"
            },
            {
                "name": "Amery",
                "countryCode": "US"
            },
            {
                "name": "Amherst",
                "countryCode": "US"
            },
            {
                "name": "Antigo",
                "countryCode": "US"
            },
            {
                "name": "Appleton",
                "countryCode": "US"
            },
            {
                "name": "Arcadia",
                "countryCode": "US"
            },
            {
                "name": "Ashford",
                "countryCode": "US"
            },
            {
                "name": "Ashland",
                "countryCode": "US"
            },
            {
                "name": "Ashland County",
                "countryCode": "US"
            },
            {
                "name": "Ashwaubenon",
                "countryCode": "US"
            },
            {
                "name": "Athens",
                "countryCode": "US"
            },
            {
                "name": "Augusta",
                "countryCode": "US"
            },
            {
                "name": "Aztalan",
                "countryCode": "US"
            },
            {
                "name": "Baldwin",
                "countryCode": "US"
            },
            {
                "name": "Balsam Lake",
                "countryCode": "US"
            },
            {
                "name": "Bangor",
                "countryCode": "US"
            },
            {
                "name": "Baraboo",
                "countryCode": "US"
            },
            {
                "name": "Barneveld",
                "countryCode": "US"
            },
            {
                "name": "Barron",
                "countryCode": "US"
            },
            {
                "name": "Barron County",
                "countryCode": "US"
            },
            {
                "name": "Barton",
                "countryCode": "US"
            },
            {
                "name": "Bayfield County",
                "countryCode": "US"
            },
            {
                "name": "Bayside",
                "countryCode": "US"
            },
            {
                "name": "Beaver Dam",
                "countryCode": "US"
            },
            {
                "name": "Belgium",
                "countryCode": "US"
            },
            {
                "name": "Belle Plaine",
                "countryCode": "US"
            },
            {
                "name": "Belleville",
                "countryCode": "US"
            },
            {
                "name": "Bellevue",
                "countryCode": "US"
            },
            {
                "name": "Beloit",
                "countryCode": "US"
            },
            {
                "name": "Berlin",
                "countryCode": "US"
            },
            {
                "name": "Bevent",
                "countryCode": "US"
            },
            {
                "name": "Big Bend",
                "countryCode": "US"
            },
            {
                "name": "Black Creek",
                "countryCode": "US"
            },
            {
                "name": "Black Earth",
                "countryCode": "US"
            },
            {
                "name": "Black River Falls",
                "countryCode": "US"
            },
            {
                "name": "Blair",
                "countryCode": "US"
            },
            {
                "name": "Bloomer",
                "countryCode": "US"
            },
            {
                "name": "Bohners Lake",
                "countryCode": "US"
            },
            {
                "name": "Bonduel",
                "countryCode": "US"
            },
            {
                "name": "Boscobel",
                "countryCode": "US"
            },
            {
                "name": "Boyceville",
                "countryCode": "US"
            },
            {
                "name": "Brice Prairie",
                "countryCode": "US"
            },
            {
                "name": "Brillion",
                "countryCode": "US"
            },
            {
                "name": "Bristol",
                "countryCode": "US"
            },
            {
                "name": "Brodhead",
                "countryCode": "US"
            },
            {
                "name": "Brookfield",
                "countryCode": "US"
            },
            {
                "name": "Brooklyn",
                "countryCode": "US"
            },
            {
                "name": "Brothertown",
                "countryCode": "US"
            },
            {
                "name": "Brown County",
                "countryCode": "US"
            },
            {
                "name": "Brown Deer",
                "countryCode": "US"
            },
            {
                "name": "Browns Lake",
                "countryCode": "US"
            },
            {
                "name": "Brussels",
                "countryCode": "US"
            },
            {
                "name": "Buffalo County",
                "countryCode": "US"
            },
            {
                "name": "Burlington",
                "countryCode": "US"
            },
            {
                "name": "Burnett County",
                "countryCode": "US"
            },
            {
                "name": "Butler",
                "countryCode": "US"
            },
            {
                "name": "Cadott",
                "countryCode": "US"
            },
            {
                "name": "Caledonia",
                "countryCode": "US"
            },
            {
                "name": "Calumet County",
                "countryCode": "US"
            },
            {
                "name": "Cambridge",
                "countryCode": "US"
            },
            {
                "name": "Cameron",
                "countryCode": "US"
            },
            {
                "name": "Camp Lake",
                "countryCode": "US"
            },
            {
                "name": "Campbellsport",
                "countryCode": "US"
            },
            {
                "name": "Cashton",
                "countryCode": "US"
            },
            {
                "name": "Cato",
                "countryCode": "US"
            },
            {
                "name": "Cedar Grove",
                "countryCode": "US"
            },
            {
                "name": "Cedarburg",
                "countryCode": "US"
            },
            {
                "name": "Chetek",
                "countryCode": "US"
            },
            {
                "name": "Chilton",
                "countryCode": "US"
            },
            {
                "name": "Chippewa County",
                "countryCode": "US"
            },
            {
                "name": "Chippewa Falls",
                "countryCode": "US"
            },
            {
                "name": "Clark County",
                "countryCode": "US"
            },
            {
                "name": "Clear Lake",
                "countryCode": "US"
            },
            {
                "name": "Cleveland",
                "countryCode": "US"
            },
            {
                "name": "Clinton",
                "countryCode": "US"
            },
            {
                "name": "Clintonville",
                "countryCode": "US"
            },
            {
                "name": "Colby",
                "countryCode": "US"
            },
            {
                "name": "Colfax",
                "countryCode": "US"
            },
            {
                "name": "Columbia County",
                "countryCode": "US"
            },
            {
                "name": "Columbus",
                "countryCode": "US"
            },
            {
                "name": "Combined Locks",
                "countryCode": "US"
            },
            {
                "name": "Como",
                "countryCode": "US"
            },
            {
                "name": "Concord",
                "countryCode": "US"
            },
            {
                "name": "Cooperstown",
                "countryCode": "US"
            },
            {
                "name": "Cornell",
                "countryCode": "US"
            },
            {
                "name": "Cottage Grove",
                "countryCode": "US"
            },
            {
                "name": "Crandon",
                "countryCode": "US"
            },
            {
                "name": "Crawford County",
                "countryCode": "US"
            },
            {
                "name": "Cross Plains",
                "countryCode": "US"
            },
            {
                "name": "Cuba City",
                "countryCode": "US"
            },
            {
                "name": "Cudahy",
                "countryCode": "US"
            },
            {
                "name": "Cumberland",
                "countryCode": "US"
            },
            {
                "name": "Dakota",
                "countryCode": "US"
            },
            {
                "name": "Dane",
                "countryCode": "US"
            },
            {
                "name": "Dane County",
                "countryCode": "US"
            },
            {
                "name": "Darien",
                "countryCode": "US"
            },
            {
                "name": "Darlington",
                "countryCode": "US"
            },
            {
                "name": "De Forest",
                "countryCode": "US"
            },
            {
                "name": "De Pere",
                "countryCode": "US"
            },
            {
                "name": "Decatur",
                "countryCode": "US"
            },
            {
                "name": "Deerfield",
                "countryCode": "US"
            },
            {
                "name": "Delafield",
                "countryCode": "US"
            },
            {
                "name": "Delavan",
                "countryCode": "US"
            },
            {
                "name": "Delavan Lake",
                "countryCode": "US"
            },
            {
                "name": "Denmark",
                "countryCode": "US"
            },
            {
                "name": "Dickeyville",
                "countryCode": "US"
            },
            {
                "name": "Dodge County",
                "countryCode": "US"
            },
            {
                "name": "Dodgeville",
                "countryCode": "US"
            },
            {
                "name": "Door County",
                "countryCode": "US"
            },
            {
                "name": "Douglas County",
                "countryCode": "US"
            },
            {
                "name": "Dousman",
                "countryCode": "US"
            },
            {
                "name": "Dunn County",
                "countryCode": "US"
            },
            {
                "name": "Durand",
                "countryCode": "US"
            },
            {
                "name": "Eagle",
                "countryCode": "US"
            },
            {
                "name": "Eagle Lake",
                "countryCode": "US"
            },
            {
                "name": "Eagle River",
                "countryCode": "US"
            },
            {
                "name": "East Troy",
                "countryCode": "US"
            },
            {
                "name": "Easton",
                "countryCode": "US"
            },
            {
                "name": "Eau Claire",
                "countryCode": "US"
            },
            {
                "name": "Eau Claire County",
                "countryCode": "US"
            },
            {
                "name": "Edgar",
                "countryCode": "US"
            },
            {
                "name": "Edgerton",
                "countryCode": "US"
            },
            {
                "name": "Eldorado",
                "countryCode": "US"
            },
            {
                "name": "Elkhorn",
                "countryCode": "US"
            },
            {
                "name": "Ellsworth",
                "countryCode": "US"
            },
            {
                "name": "Elm Grove",
                "countryCode": "US"
            },
            {
                "name": "Elroy",
                "countryCode": "US"
            },
            {
                "name": "Evansville",
                "countryCode": "US"
            },
            {
                "name": "Evergreen",
                "countryCode": "US"
            },
            {
                "name": "Fall Creek",
                "countryCode": "US"
            },
            {
                "name": "Fall River",
                "countryCode": "US"
            },
            {
                "name": "Fennimore",
                "countryCode": "US"
            },
            {
                "name": "Fitchburg",
                "countryCode": "US"
            },
            {
                "name": "Florence",
                "countryCode": "US"
            },
            {
                "name": "Florence County",
                "countryCode": "US"
            },
            {
                "name": "Fond du Lac",
                "countryCode": "US"
            },
            {
                "name": "Fond du Lac County",
                "countryCode": "US"
            },
            {
                "name": "Fontana",
                "countryCode": "US"
            },
            {
                "name": "Forest County",
                "countryCode": "US"
            },
            {
                "name": "Fort Atkinson",
                "countryCode": "US"
            },
            {
                "name": "Fox Lake",
                "countryCode": "US"
            },
            {
                "name": "Fox Point",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Franksville",
                "countryCode": "US"
            },
            {
                "name": "Frederic",
                "countryCode": "US"
            },
            {
                "name": "Fredonia",
                "countryCode": "US"
            },
            {
                "name": "French Island",
                "countryCode": "US"
            },
            {
                "name": "Friendship",
                "countryCode": "US"
            },
            {
                "name": "Galesville",
                "countryCode": "US"
            },
            {
                "name": "Genoa City",
                "countryCode": "US"
            },
            {
                "name": "Germantown",
                "countryCode": "US"
            },
            {
                "name": "Gillett",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Glenmore",
                "countryCode": "US"
            },
            {
                "name": "Glenwood City",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Grantsburg",
                "countryCode": "US"
            },
            {
                "name": "Green Bay",
                "countryCode": "US"
            },
            {
                "name": "Green County",
                "countryCode": "US"
            },
            {
                "name": "Green Lake",
                "countryCode": "US"
            },
            {
                "name": "Green Lake County",
                "countryCode": "US"
            },
            {
                "name": "Greendale",
                "countryCode": "US"
            },
            {
                "name": "Greenfield",
                "countryCode": "US"
            },
            {
                "name": "Greenwood",
                "countryCode": "US"
            },
            {
                "name": "Hales Corners",
                "countryCode": "US"
            },
            {
                "name": "Hammond",
                "countryCode": "US"
            },
            {
                "name": "Harrison",
                "countryCode": "US"
            },
            {
                "name": "Hartford",
                "countryCode": "US"
            },
            {
                "name": "Hartland",
                "countryCode": "US"
            },
            {
                "name": "Hayward",
                "countryCode": "US"
            },
            {
                "name": "Hazel Green",
                "countryCode": "US"
            },
            {
                "name": "Hilbert",
                "countryCode": "US"
            },
            {
                "name": "Hillsboro",
                "countryCode": "US"
            },
            {
                "name": "Hobart",
                "countryCode": "US"
            },
            {
                "name": "Holmen",
                "countryCode": "US"
            },
            {
                "name": "Horicon",
                "countryCode": "US"
            },
            {
                "name": "Hortonville",
                "countryCode": "US"
            },
            {
                "name": "Howard",
                "countryCode": "US"
            },
            {
                "name": "Howards Grove",
                "countryCode": "US"
            },
            {
                "name": "Hudson",
                "countryCode": "US"
            },
            {
                "name": "Hurley",
                "countryCode": "US"
            },
            {
                "name": "Hustisford",
                "countryCode": "US"
            },
            {
                "name": "Independence",
                "countryCode": "US"
            },
            {
                "name": "Iola",
                "countryCode": "US"
            },
            {
                "name": "Iowa County",
                "countryCode": "US"
            },
            {
                "name": "Iron County",
                "countryCode": "US"
            },
            {
                "name": "Ixonia",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Janesville",
                "countryCode": "US"
            },
            {
                "name": "Jefferson",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Johnson Creek",
                "countryCode": "US"
            },
            {
                "name": "Juneau",
                "countryCode": "US"
            },
            {
                "name": "Juneau County",
                "countryCode": "US"
            },
            {
                "name": "Kaukauna",
                "countryCode": "US"
            },
            {
                "name": "Kenosha",
                "countryCode": "US"
            },
            {
                "name": "Kenosha County",
                "countryCode": "US"
            },
            {
                "name": "Keshena",
                "countryCode": "US"
            },
            {
                "name": "Kewaskum",
                "countryCode": "US"
            },
            {
                "name": "Kewaunee",
                "countryCode": "US"
            },
            {
                "name": "Kewaunee County",
                "countryCode": "US"
            },
            {
                "name": "Kiel",
                "countryCode": "US"
            },
            {
                "name": "Kimberly",
                "countryCode": "US"
            },
            {
                "name": "King",
                "countryCode": "US"
            },
            {
                "name": "Kohler",
                "countryCode": "US"
            },
            {
                "name": "Kronenwetter",
                "countryCode": "US"
            },
            {
                "name": "La Crosse",
                "countryCode": "US"
            },
            {
                "name": "La Crosse County",
                "countryCode": "US"
            },
            {
                "name": "Lac du Flambeau",
                "countryCode": "US"
            },
            {
                "name": "Ladysmith",
                "countryCode": "US"
            },
            {
                "name": "Lafayette County",
                "countryCode": "US"
            },
            {
                "name": "Lake Delton",
                "countryCode": "US"
            },
            {
                "name": "Lake Geneva",
                "countryCode": "US"
            },
            {
                "name": "Lake Hallie",
                "countryCode": "US"
            },
            {
                "name": "Lake Koshkonong",
                "countryCode": "US"
            },
            {
                "name": "Lake Mills",
                "countryCode": "US"
            },
            {
                "name": "Lake Nebagamon",
                "countryCode": "US"
            },
            {
                "name": "Lake Ripley",
                "countryCode": "US"
            },
            {
                "name": "Lake Wazeecha",
                "countryCode": "US"
            },
            {
                "name": "Lake Wisconsin",
                "countryCode": "US"
            },
            {
                "name": "Lake Wissota",
                "countryCode": "US"
            },
            {
                "name": "Lamartine",
                "countryCode": "US"
            },
            {
                "name": "Lancaster",
                "countryCode": "US"
            },
            {
                "name": "Langlade County",
                "countryCode": "US"
            },
            {
                "name": "Lannon",
                "countryCode": "US"
            },
            {
                "name": "Legend Lake",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Little Chute",
                "countryCode": "US"
            },
            {
                "name": "Little Round Lake",
                "countryCode": "US"
            },
            {
                "name": "Lodi",
                "countryCode": "US"
            },
            {
                "name": "Lomira",
                "countryCode": "US"
            },
            {
                "name": "Loyal",
                "countryCode": "US"
            },
            {
                "name": "Luck",
                "countryCode": "US"
            },
            {
                "name": "Luxemburg",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Maine",
                "countryCode": "US"
            },
            {
                "name": "Manawa",
                "countryCode": "US"
            },
            {
                "name": "Manchester",
                "countryCode": "US"
            },
            {
                "name": "Manitowoc",
                "countryCode": "US"
            },
            {
                "name": "Manitowoc County",
                "countryCode": "US"
            },
            {
                "name": "Maple Bluff",
                "countryCode": "US"
            },
            {
                "name": "Marathon",
                "countryCode": "US"
            },
            {
                "name": "Marathon County",
                "countryCode": "US"
            },
            {
                "name": "Marinette",
                "countryCode": "US"
            },
            {
                "name": "Marinette County",
                "countryCode": "US"
            },
            {
                "name": "Marion",
                "countryCode": "US"
            },
            {
                "name": "Markesan",
                "countryCode": "US"
            },
            {
                "name": "Marquette County",
                "countryCode": "US"
            },
            {
                "name": "Marshall",
                "countryCode": "US"
            },
            {
                "name": "Marshfield",
                "countryCode": "US"
            },
            {
                "name": "Mauston",
                "countryCode": "US"
            },
            {
                "name": "Mayville",
                "countryCode": "US"
            },
            {
                "name": "Mazomanie",
                "countryCode": "US"
            },
            {
                "name": "McFarland",
                "countryCode": "US"
            },
            {
                "name": "Medford",
                "countryCode": "US"
            },
            {
                "name": "Menasha",
                "countryCode": "US"
            },
            {
                "name": "Menominee County",
                "countryCode": "US"
            },
            {
                "name": "Menomonee Falls",
                "countryCode": "US"
            },
            {
                "name": "Menomonie",
                "countryCode": "US"
            },
            {
                "name": "Mequon",
                "countryCode": "US"
            },
            {
                "name": "Merrill",
                "countryCode": "US"
            },
            {
                "name": "Merton",
                "countryCode": "US"
            },
            {
                "name": "Middleton",
                "countryCode": "US"
            },
            {
                "name": "Milford",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Milwaukee",
                "countryCode": "US"
            },
            {
                "name": "Milwaukee County",
                "countryCode": "US"
            },
            {
                "name": "Mineral Point",
                "countryCode": "US"
            },
            {
                "name": "Mishicot",
                "countryCode": "US"
            },
            {
                "name": "Mondovi",
                "countryCode": "US"
            },
            {
                "name": "Monona",
                "countryCode": "US"
            },
            {
                "name": "Monroe",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montello",
                "countryCode": "US"
            },
            {
                "name": "Monticello",
                "countryCode": "US"
            },
            {
                "name": "Mosinee",
                "countryCode": "US"
            },
            {
                "name": "Mount Horeb",
                "countryCode": "US"
            },
            {
                "name": "Mount Morris",
                "countryCode": "US"
            },
            {
                "name": "Mount Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Mukwonago",
                "countryCode": "US"
            },
            {
                "name": "Muscoda",
                "countryCode": "US"
            },
            {
                "name": "Muskego",
                "countryCode": "US"
            },
            {
                "name": "Nashotah",
                "countryCode": "US"
            },
            {
                "name": "Nashville",
                "countryCode": "US"
            },
            {
                "name": "Neenah",
                "countryCode": "US"
            },
            {
                "name": "Neillsville",
                "countryCode": "US"
            },
            {
                "name": "Nekoosa",
                "countryCode": "US"
            },
            {
                "name": "New Berlin",
                "countryCode": "US"
            },
            {
                "name": "New Glarus",
                "countryCode": "US"
            },
            {
                "name": "New Holstein",
                "countryCode": "US"
            },
            {
                "name": "New Lisbon",
                "countryCode": "US"
            },
            {
                "name": "New London",
                "countryCode": "US"
            },
            {
                "name": "New Richmond",
                "countryCode": "US"
            },
            {
                "name": "Newburg",
                "countryCode": "US"
            },
            {
                "name": "Niagara",
                "countryCode": "US"
            },
            {
                "name": "North Fond du Lac",
                "countryCode": "US"
            },
            {
                "name": "North Hudson",
                "countryCode": "US"
            },
            {
                "name": "North La Crosse",
                "countryCode": "US"
            },
            {
                "name": "North Prairie",
                "countryCode": "US"
            },
            {
                "name": "Oak Creek",
                "countryCode": "US"
            },
            {
                "name": "Oakfield",
                "countryCode": "US"
            },
            {
                "name": "Oconomowoc",
                "countryCode": "US"
            },
            {
                "name": "Oconto",
                "countryCode": "US"
            },
            {
                "name": "Oconto County",
                "countryCode": "US"
            },
            {
                "name": "Oconto Falls",
                "countryCode": "US"
            },
            {
                "name": "Okauchee Lake",
                "countryCode": "US"
            },
            {
                "name": "Omro",
                "countryCode": "US"
            },
            {
                "name": "Onalaska",
                "countryCode": "US"
            },
            {
                "name": "Oneida",
                "countryCode": "US"
            },
            {
                "name": "Oneida County",
                "countryCode": "US"
            },
            {
                "name": "Oostburg",
                "countryCode": "US"
            },
            {
                "name": "Oregon",
                "countryCode": "US"
            },
            {
                "name": "Orfordville",
                "countryCode": "US"
            },
            {
                "name": "Osceola",
                "countryCode": "US"
            },
            {
                "name": "Oshkosh",
                "countryCode": "US"
            },
            {
                "name": "Osseo",
                "countryCode": "US"
            },
            {
                "name": "Outagamie County",
                "countryCode": "US"
            },
            {
                "name": "Ozaukee County",
                "countryCode": "US"
            },
            {
                "name": "Paddock Lake",
                "countryCode": "US"
            },
            {
                "name": "Palmyra",
                "countryCode": "US"
            },
            {
                "name": "Pardeeville",
                "countryCode": "US"
            },
            {
                "name": "Park Falls",
                "countryCode": "US"
            },
            {
                "name": "Pell Lake",
                "countryCode": "US"
            },
            {
                "name": "Pepin County",
                "countryCode": "US"
            },
            {
                "name": "Peshtigo",
                "countryCode": "US"
            },
            {
                "name": "Pewaukee",
                "countryCode": "US"
            },
            {
                "name": "Phillips",
                "countryCode": "US"
            },
            {
                "name": "Pierce County",
                "countryCode": "US"
            },
            {
                "name": "Pittsfield",
                "countryCode": "US"
            },
            {
                "name": "Platteville",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Prairie",
                "countryCode": "US"
            },
            {
                "name": "Plover",
                "countryCode": "US"
            },
            {
                "name": "Plymouth",
                "countryCode": "US"
            },
            {
                "name": "Polk County",
                "countryCode": "US"
            },
            {
                "name": "Port Edwards",
                "countryCode": "US"
            },
            {
                "name": "Port Washington",
                "countryCode": "US"
            },
            {
                "name": "Portage",
                "countryCode": "US"
            },
            {
                "name": "Portage County",
                "countryCode": "US"
            },
            {
                "name": "Portland",
                "countryCode": "US"
            },
            {
                "name": "Potter Lake",
                "countryCode": "US"
            },
            {
                "name": "Powers Lake",
                "countryCode": "US"
            },
            {
                "name": "Poynette",
                "countryCode": "US"
            },
            {
                "name": "Prairie du Chien",
                "countryCode": "US"
            },
            {
                "name": "Prairie du Sac",
                "countryCode": "US"
            },
            {
                "name": "Prescott",
                "countryCode": "US"
            },
            {
                "name": "Price County",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Pulaski",
                "countryCode": "US"
            },
            {
                "name": "Racine",
                "countryCode": "US"
            },
            {
                "name": "Racine County",
                "countryCode": "US"
            },
            {
                "name": "Randolph",
                "countryCode": "US"
            },
            {
                "name": "Random Lake",
                "countryCode": "US"
            },
            {
                "name": "Redgranite",
                "countryCode": "US"
            },
            {
                "name": "Reedsburg",
                "countryCode": "US"
            },
            {
                "name": "Reedsville",
                "countryCode": "US"
            },
            {
                "name": "Rhinelander",
                "countryCode": "US"
            },
            {
                "name": "Rib Mountain",
                "countryCode": "US"
            },
            {
                "name": "Rice Lake",
                "countryCode": "US"
            },
            {
                "name": "Richfield",
                "countryCode": "US"
            },
            {
                "name": "Richland Center",
                "countryCode": "US"
            },
            {
                "name": "Richland County",
                "countryCode": "US"
            },
            {
                "name": "Richmond",
                "countryCode": "US"
            },
            {
                "name": "Rio",
                "countryCode": "US"
            },
            {
                "name": "Ripon",
                "countryCode": "US"
            },
            {
                "name": "River Falls",
                "countryCode": "US"
            },
            {
                "name": "River Hills",
                "countryCode": "US"
            },
            {
                "name": "Roberts",
                "countryCode": "US"
            },
            {
                "name": "Rochester",
                "countryCode": "US"
            },
            {
                "name": "Rock County",
                "countryCode": "US"
            },
            {
                "name": "Rome",
                "countryCode": "US"
            },
            {
                "name": "Rosendale",
                "countryCode": "US"
            },
            {
                "name": "Rothschild",
                "countryCode": "US"
            },
            {
                "name": "Roxbury",
                "countryCode": "US"
            },
            {
                "name": "Rusk County",
                "countryCode": "US"
            },
            {
                "name": "Rutland",
                "countryCode": "US"
            },
            {
                "name": "Saint Croix County",
                "countryCode": "US"
            },
            {
                "name": "Saint Croix Falls",
                "countryCode": "US"
            },
            {
                "name": "Saint Francis",
                "countryCode": "US"
            },
            {
                "name": "Saint Peter",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Sauk City",
                "countryCode": "US"
            },
            {
                "name": "Sauk County",
                "countryCode": "US"
            },
            {
                "name": "Saukville",
                "countryCode": "US"
            },
            {
                "name": "Sawyer County",
                "countryCode": "US"
            },
            {
                "name": "Schofield",
                "countryCode": "US"
            },
            {
                "name": "Seymour",
                "countryCode": "US"
            },
            {
                "name": "Sharon",
                "countryCode": "US"
            },
            {
                "name": "Shawano",
                "countryCode": "US"
            },
            {
                "name": "Shawano County",
                "countryCode": "US"
            },
            {
                "name": "Sheboygan",
                "countryCode": "US"
            },
            {
                "name": "Sheboygan County",
                "countryCode": "US"
            },
            {
                "name": "Sheboygan Falls",
                "countryCode": "US"
            },
            {
                "name": "Shell Lake",
                "countryCode": "US"
            },
            {
                "name": "Sherwood",
                "countryCode": "US"
            },
            {
                "name": "Shorewood",
                "countryCode": "US"
            },
            {
                "name": "Shorewood Hills",
                "countryCode": "US"
            },
            {
                "name": "Shullsburg",
                "countryCode": "US"
            },
            {
                "name": "Silver Lake",
                "countryCode": "US"
            },
            {
                "name": "Siren",
                "countryCode": "US"
            },
            {
                "name": "Slinger",
                "countryCode": "US"
            },
            {
                "name": "Somers",
                "countryCode": "US"
            },
            {
                "name": "Somerset",
                "countryCode": "US"
            },
            {
                "name": "South Milwaukee",
                "countryCode": "US"
            },
            {
                "name": "Sparta",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Spooner",
                "countryCode": "US"
            },
            {
                "name": "Spring Green",
                "countryCode": "US"
            },
            {
                "name": "Spring Valley",
                "countryCode": "US"
            },
            {
                "name": "Stanley",
                "countryCode": "US"
            },
            {
                "name": "Stevens Point",
                "countryCode": "US"
            },
            {
                "name": "Stoughton",
                "countryCode": "US"
            },
            {
                "name": "Stratford",
                "countryCode": "US"
            },
            {
                "name": "Strum",
                "countryCode": "US"
            },
            {
                "name": "Sturgeon Bay",
                "countryCode": "US"
            },
            {
                "name": "Sturtevant",
                "countryCode": "US"
            },
            {
                "name": "Suamico",
                "countryCode": "US"
            },
            {
                "name": "Sun Prairie",
                "countryCode": "US"
            },
            {
                "name": "Superior",
                "countryCode": "US"
            },
            {
                "name": "Sussex",
                "countryCode": "US"
            },
            {
                "name": "Tainter Lake",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Theresa",
                "countryCode": "US"
            },
            {
                "name": "Thiensville",
                "countryCode": "US"
            },
            {
                "name": "Thorp",
                "countryCode": "US"
            },
            {
                "name": "Tichigan",
                "countryCode": "US"
            },
            {
                "name": "Tomah",
                "countryCode": "US"
            },
            {
                "name": "Tomahawk",
                "countryCode": "US"
            },
            {
                "name": "Trempealeau",
                "countryCode": "US"
            },
            {
                "name": "Trempealeau County",
                "countryCode": "US"
            },
            {
                "name": "Turtle Lake",
                "countryCode": "US"
            },
            {
                "name": "Twin Lakes",
                "countryCode": "US"
            },
            {
                "name": "Two Rivers",
                "countryCode": "US"
            },
            {
                "name": "Union Grove",
                "countryCode": "US"
            },
            {
                "name": "Vernon County",
                "countryCode": "US"
            },
            {
                "name": "Verona",
                "countryCode": "US"
            },
            {
                "name": "Vilas County",
                "countryCode": "US"
            },
            {
                "name": "Viroqua",
                "countryCode": "US"
            },
            {
                "name": "Wales",
                "countryCode": "US"
            },
            {
                "name": "Walworth",
                "countryCode": "US"
            },
            {
                "name": "Walworth County",
                "countryCode": "US"
            },
            {
                "name": "Washburn",
                "countryCode": "US"
            },
            {
                "name": "Washburn County",
                "countryCode": "US"
            },
            {
                "name": "Washington County",
                "countryCode": "US"
            },
            {
                "name": "Waterford",
                "countryCode": "US"
            },
            {
                "name": "Waterloo",
                "countryCode": "US"
            },
            {
                "name": "Watertown",
                "countryCode": "US"
            },
            {
                "name": "Waukesha",
                "countryCode": "US"
            },
            {
                "name": "Waukesha County",
                "countryCode": "US"
            },
            {
                "name": "Waunakee",
                "countryCode": "US"
            },
            {
                "name": "Waupaca",
                "countryCode": "US"
            },
            {
                "name": "Waupaca County",
                "countryCode": "US"
            },
            {
                "name": "Waupun",
                "countryCode": "US"
            },
            {
                "name": "Wausau",
                "countryCode": "US"
            },
            {
                "name": "Waushara County",
                "countryCode": "US"
            },
            {
                "name": "Wautoma",
                "countryCode": "US"
            },
            {
                "name": "Wauwatosa",
                "countryCode": "US"
            },
            {
                "name": "West Allis",
                "countryCode": "US"
            },
            {
                "name": "West Baraboo",
                "countryCode": "US"
            },
            {
                "name": "West Bend",
                "countryCode": "US"
            },
            {
                "name": "West Milwaukee",
                "countryCode": "US"
            },
            {
                "name": "West Salem",
                "countryCode": "US"
            },
            {
                "name": "Westby",
                "countryCode": "US"
            },
            {
                "name": "Westfield",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Weyauwega",
                "countryCode": "US"
            },
            {
                "name": "Whitefish Bay",
                "countryCode": "US"
            },
            {
                "name": "Whitehall",
                "countryCode": "US"
            },
            {
                "name": "Whitewater",
                "countryCode": "US"
            },
            {
                "name": "Whiting",
                "countryCode": "US"
            },
            {
                "name": "Williams Bay",
                "countryCode": "US"
            },
            {
                "name": "Wind Lake",
                "countryCode": "US"
            },
            {
                "name": "Wind Point",
                "countryCode": "US"
            },
            {
                "name": "Windsor",
                "countryCode": "US"
            },
            {
                "name": "Winnebago County",
                "countryCode": "US"
            },
            {
                "name": "Winneconne",
                "countryCode": "US"
            },
            {
                "name": "Wisconsin Dells",
                "countryCode": "US"
            },
            {
                "name": "Wisconsin Rapids",
                "countryCode": "US"
            },
            {
                "name": "Wittenberg",
                "countryCode": "US"
            },
            {
                "name": "Wood County",
                "countryCode": "US"
            },
            {
                "name": "Woodville",
                "countryCode": "US"
            },
            {
                "name": "Wrightstown",
                "countryCode": "US"
            },
            {
                "name": "Alderson",
                "countryCode": "US"
            },
            {
                "name": "Alum Creek",
                "countryCode": "US"
            },
            {
                "name": "Ansted",
                "countryCode": "US"
            },
            {
                "name": "Barbour County",
                "countryCode": "US"
            },
            {
                "name": "Barboursville",
                "countryCode": "US"
            },
            {
                "name": "Barrackville",
                "countryCode": "US"
            },
            {
                "name": "Beaver",
                "countryCode": "US"
            },
            {
                "name": "Beckley",
                "countryCode": "US"
            },
            {
                "name": "Belington",
                "countryCode": "US"
            },
            {
                "name": "Belle",
                "countryCode": "US"
            },
            {
                "name": "Benwood",
                "countryCode": "US"
            },
            {
                "name": "Berkeley County",
                "countryCode": "US"
            },
            {
                "name": "Berkeley Springs",
                "countryCode": "US"
            },
            {
                "name": "Bethany",
                "countryCode": "US"
            },
            {
                "name": "Bethlehem",
                "countryCode": "US"
            },
            {
                "name": "Blennerhassett",
                "countryCode": "US"
            },
            {
                "name": "Bluefield",
                "countryCode": "US"
            },
            {
                "name": "Bluewell",
                "countryCode": "US"
            },
            {
                "name": "Boaz",
                "countryCode": "US"
            },
            {
                "name": "Bolivar",
                "countryCode": "US"
            },
            {
                "name": "Boone County",
                "countryCode": "US"
            },
            {
                "name": "Bradley",
                "countryCode": "US"
            },
            {
                "name": "Braxton County",
                "countryCode": "US"
            },
            {
                "name": "Bridgeport",
                "countryCode": "US"
            },
            {
                "name": "Brooke County",
                "countryCode": "US"
            },
            {
                "name": "Brookhaven",
                "countryCode": "US"
            },
            {
                "name": "Brush Fork",
                "countryCode": "US"
            },
            {
                "name": "Buckhannon",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Cabell County",
                "countryCode": "US"
            },
            {
                "name": "Calhoun County",
                "countryCode": "US"
            },
            {
                "name": "Ceredo",
                "countryCode": "US"
            },
            {
                "name": "Chapmanville",
                "countryCode": "US"
            },
            {
                "name": "Charles Town",
                "countryCode": "US"
            },
            {
                "name": "Charleston",
                "countryCode": "US"
            },
            {
                "name": "Cheat Lake",
                "countryCode": "US"
            },
            {
                "name": "Chesapeake",
                "countryCode": "US"
            },
            {
                "name": "Chester",
                "countryCode": "US"
            },
            {
                "name": "Clarksburg",
                "countryCode": "US"
            },
            {
                "name": "Clay",
                "countryCode": "US"
            },
            {
                "name": "Clay County",
                "countryCode": "US"
            },
            {
                "name": "Clendenin",
                "countryCode": "US"
            },
            {
                "name": "Coal City",
                "countryCode": "US"
            },
            {
                "name": "Coal Fork",
                "countryCode": "US"
            },
            {
                "name": "Crab Orchard",
                "countryCode": "US"
            },
            {
                "name": "Craigsville",
                "countryCode": "US"
            },
            {
                "name": "Cross Lanes",
                "countryCode": "US"
            },
            {
                "name": "Culloden",
                "countryCode": "US"
            },
            {
                "name": "Daniels",
                "countryCode": "US"
            },
            {
                "name": "Despard",
                "countryCode": "US"
            },
            {
                "name": "Doddridge County",
                "countryCode": "US"
            },
            {
                "name": "Dunbar",
                "countryCode": "US"
            },
            {
                "name": "Eleanor",
                "countryCode": "US"
            },
            {
                "name": "Elizabeth",
                "countryCode": "US"
            },
            {
                "name": "Elkins",
                "countryCode": "US"
            },
            {
                "name": "Elkview",
                "countryCode": "US"
            },
            {
                "name": "Fairlea",
                "countryCode": "US"
            },
            {
                "name": "Fairmont",
                "countryCode": "US"
            },
            {
                "name": "Fayette County",
                "countryCode": "US"
            },
            {
                "name": "Fayetteville",
                "countryCode": "US"
            },
            {
                "name": "Follansbee",
                "countryCode": "US"
            },
            {
                "name": "Fort Ashby",
                "countryCode": "US"
            },
            {
                "name": "Franklin",
                "countryCode": "US"
            },
            {
                "name": "Gilbert Creek",
                "countryCode": "US"
            },
            {
                "name": "Gilmer County",
                "countryCode": "US"
            },
            {
                "name": "Glendale",
                "countryCode": "US"
            },
            {
                "name": "Glenville",
                "countryCode": "US"
            },
            {
                "name": "Grafton",
                "countryCode": "US"
            },
            {
                "name": "Grant County",
                "countryCode": "US"
            },
            {
                "name": "Grantsville",
                "countryCode": "US"
            },
            {
                "name": "Granville",
                "countryCode": "US"
            },
            {
                "name": "Greenbrier County",
                "countryCode": "US"
            },
            {
                "name": "Hamlin",
                "countryCode": "US"
            },
            {
                "name": "Hampshire County",
                "countryCode": "US"
            },
            {
                "name": "Hancock County",
                "countryCode": "US"
            },
            {
                "name": "Hardy County",
                "countryCode": "US"
            },
            {
                "name": "Harrison County",
                "countryCode": "US"
            },
            {
                "name": "Harrisville",
                "countryCode": "US"
            },
            {
                "name": "Hinton",
                "countryCode": "US"
            },
            {
                "name": "Hooverson Heights",
                "countryCode": "US"
            },
            {
                "name": "Huntington",
                "countryCode": "US"
            },
            {
                "name": "Hurricane",
                "countryCode": "US"
            },
            {
                "name": "Inwood",
                "countryCode": "US"
            },
            {
                "name": "Jackson County",
                "countryCode": "US"
            },
            {
                "name": "Jefferson County",
                "countryCode": "US"
            },
            {
                "name": "Kanawha County",
                "countryCode": "US"
            },
            {
                "name": "Kenova",
                "countryCode": "US"
            },
            {
                "name": "Keyser",
                "countryCode": "US"
            },
            {
                "name": "Kingwood",
                "countryCode": "US"
            },
            {
                "name": "Lavalette",
                "countryCode": "US"
            },
            {
                "name": "Lesage",
                "countryCode": "US"
            },
            {
                "name": "Lewis County",
                "countryCode": "US"
            },
            {
                "name": "Lewisburg",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Logan",
                "countryCode": "US"
            },
            {
                "name": "Logan County",
                "countryCode": "US"
            },
            {
                "name": "Lubeck",
                "countryCode": "US"
            },
            {
                "name": "Mabscott",
                "countryCode": "US"
            },
            {
                "name": "MacArthur",
                "countryCode": "US"
            },
            {
                "name": "Madison",
                "countryCode": "US"
            },
            {
                "name": "Mallory",
                "countryCode": "US"
            },
            {
                "name": "Mannington",
                "countryCode": "US"
            },
            {
                "name": "Marion County",
                "countryCode": "US"
            },
            {
                "name": "Marlinton",
                "countryCode": "US"
            },
            {
                "name": "Marmet",
                "countryCode": "US"
            },
            {
                "name": "Marshall County",
                "countryCode": "US"
            },
            {
                "name": "Martinsburg",
                "countryCode": "US"
            },
            {
                "name": "Mason County",
                "countryCode": "US"
            },
            {
                "name": "McDowell County",
                "countryCode": "US"
            },
            {
                "name": "McMechen",
                "countryCode": "US"
            },
            {
                "name": "Mercer County",
                "countryCode": "US"
            },
            {
                "name": "Middlebourne",
                "countryCode": "US"
            },
            {
                "name": "Milton",
                "countryCode": "US"
            },
            {
                "name": "Mineral County",
                "countryCode": "US"
            },
            {
                "name": "Mineral Wells",
                "countryCode": "US"
            },
            {
                "name": "Mingo County",
                "countryCode": "US"
            },
            {
                "name": "Monongah",
                "countryCode": "US"
            },
            {
                "name": "Monongalia County",
                "countryCode": "US"
            },
            {
                "name": "Monroe County",
                "countryCode": "US"
            },
            {
                "name": "Montgomery",
                "countryCode": "US"
            },
            {
                "name": "Moorefield",
                "countryCode": "US"
            },
            {
                "name": "Morgan County",
                "countryCode": "US"
            },
            {
                "name": "Morgantown",
                "countryCode": "US"
            },
            {
                "name": "Moundsville",
                "countryCode": "US"
            },
            {
                "name": "Mount Gay-Shamrock",
                "countryCode": "US"
            },
            {
                "name": "Mount Hope",
                "countryCode": "US"
            },
            {
                "name": "Mullens",
                "countryCode": "US"
            },
            {
                "name": "New Cumberland",
                "countryCode": "US"
            },
            {
                "name": "New Haven",
                "countryCode": "US"
            },
            {
                "name": "New Martinsville",
                "countryCode": "US"
            },
            {
                "name": "Newell",
                "countryCode": "US"
            },
            {
                "name": "Nicholas County",
                "countryCode": "US"
            },
            {
                "name": "Nitro",
                "countryCode": "US"
            },
            {
                "name": "Nutter Fort",
                "countryCode": "US"
            },
            {
                "name": "Oak Hill",
                "countryCode": "US"
            },
            {
                "name": "Oceana",
                "countryCode": "US"
            },
            {
                "name": "Ohio County",
                "countryCode": "US"
            },
            {
                "name": "Paden City",
                "countryCode": "US"
            },
            {
                "name": "Parkersburg",
                "countryCode": "US"
            },
            {
                "name": "Parsons",
                "countryCode": "US"
            },
            {
                "name": "Pea Ridge",
                "countryCode": "US"
            },
            {
                "name": "Pendleton County",
                "countryCode": "US"
            },
            {
                "name": "Pennsboro",
                "countryCode": "US"
            },
            {
                "name": "Petersburg",
                "countryCode": "US"
            },
            {
                "name": "Philippi",
                "countryCode": "US"
            },
            {
                "name": "Pinch",
                "countryCode": "US"
            },
            {
                "name": "Pineville",
                "countryCode": "US"
            },
            {
                "name": "Pleasant Valley",
                "countryCode": "US"
            },
            {
                "name": "Pleasants County",
                "countryCode": "US"
            },
            {
                "name": "Pocahontas County",
                "countryCode": "US"
            },
            {
                "name": "Point Pleasant",
                "countryCode": "US"
            },
            {
                "name": "Preston County",
                "countryCode": "US"
            },
            {
                "name": "Princeton",
                "countryCode": "US"
            },
            {
                "name": "Prosperity",
                "countryCode": "US"
            },
            {
                "name": "Putnam County",
                "countryCode": "US"
            },
            {
                "name": "Rainelle",
                "countryCode": "US"
            },
            {
                "name": "Raleigh County",
                "countryCode": "US"
            },
            {
                "name": "Rand",
                "countryCode": "US"
            },
            {
                "name": "Randolph County",
                "countryCode": "US"
            },
            {
                "name": "Ranson",
                "countryCode": "US"
            },
            {
                "name": "Ravenswood",
                "countryCode": "US"
            },
            {
                "name": "Richwood",
                "countryCode": "US"
            },
            {
                "name": "Ripley",
                "countryCode": "US"
            },
            {
                "name": "Ritchie County",
                "countryCode": "US"
            },
            {
                "name": "Roane County",
                "countryCode": "US"
            },
            {
                "name": "Romney",
                "countryCode": "US"
            },
            {
                "name": "Ronceverte",
                "countryCode": "US"
            },
            {
                "name": "Saint Albans",
                "countryCode": "US"
            },
            {
                "name": "Saint Marys",
                "countryCode": "US"
            },
            {
                "name": "Salem",
                "countryCode": "US"
            },
            {
                "name": "Shady Spring",
                "countryCode": "US"
            },
            {
                "name": "Shannondale",
                "countryCode": "US"
            },
            {
                "name": "Shepherdstown",
                "countryCode": "US"
            },
            {
                "name": "Shinnston",
                "countryCode": "US"
            },
            {
                "name": "Sissonville",
                "countryCode": "US"
            },
            {
                "name": "Sistersville",
                "countryCode": "US"
            },
            {
                "name": "Sophia",
                "countryCode": "US"
            },
            {
                "name": "South Charleston",
                "countryCode": "US"
            },
            {
                "name": "Spencer",
                "countryCode": "US"
            },
            {
                "name": "Stanaford",
                "countryCode": "US"
            },
            {
                "name": "Star City",
                "countryCode": "US"
            },
            {
                "name": "Stonewood",
                "countryCode": "US"
            },
            {
                "name": "Summers County",
                "countryCode": "US"
            },
            {
                "name": "Summersville",
                "countryCode": "US"
            },
            {
                "name": "Sutton",
                "countryCode": "US"
            },
            {
                "name": "Taylor County",
                "countryCode": "US"
            },
            {
                "name": "Teays Valley",
                "countryCode": "US"
            },
            {
                "name": "Terra Alta",
                "countryCode": "US"
            },
            {
                "name": "Tornado",
                "countryCode": "US"
            },
            {
                "name": "Tucker County",
                "countryCode": "US"
            },
            {
                "name": "Tyler County",
                "countryCode": "US"
            },
            {
                "name": "Union",
                "countryCode": "US"
            },
            {
                "name": "Upshur County",
                "countryCode": "US"
            },
            {
                "name": "Vienna",
                "countryCode": "US"
            },
            {
                "name": "Washington",
                "countryCode": "US"
            },
            {
                "name": "Wayne",
                "countryCode": "US"
            },
            {
                "name": "Wayne County",
                "countryCode": "US"
            },
            {
                "name": "Webster County",
                "countryCode": "US"
            },
            {
                "name": "Webster Springs",
                "countryCode": "US"
            },
            {
                "name": "Weirton",
                "countryCode": "US"
            },
            {
                "name": "Weirton Heights",
                "countryCode": "US"
            },
            {
                "name": "Welch",
                "countryCode": "US"
            },
            {
                "name": "Wellsburg",
                "countryCode": "US"
            },
            {
                "name": "West Liberty",
                "countryCode": "US"
            },
            {
                "name": "West Union",
                "countryCode": "US"
            },
            {
                "name": "Weston",
                "countryCode": "US"
            },
            {
                "name": "Westover",
                "countryCode": "US"
            },
            {
                "name": "Wetzel County",
                "countryCode": "US"
            },
            {
                "name": "Wheeling",
                "countryCode": "US"
            },
            {
                "name": "White Sulphur Springs",
                "countryCode": "US"
            },
            {
                "name": "Wiley Ford",
                "countryCode": "US"
            },
            {
                "name": "Williamson",
                "countryCode": "US"
            },
            {
                "name": "Williamstown",
                "countryCode": "US"
            },
            {
                "name": "Winfield",
                "countryCode": "US"
            },
            {
                "name": "Wirt County",
                "countryCode": "US"
            },
            {
                "name": "Wood County",
                "countryCode": "US"
            },
            {
                "name": "Wyoming County",
                "countryCode": "US"
            },
            {
                "name": "Afton",
                "countryCode": "US"
            },
            {
                "name": "Albany County",
                "countryCode": "US"
            },
            {
                "name": "Antelope Valley-Crestview",
                "countryCode": "US"
            },
            {
                "name": "Arapahoe",
                "countryCode": "US"
            },
            {
                "name": "Bar Nunn",
                "countryCode": "US"
            },
            {
                "name": "Basin",
                "countryCode": "US"
            },
            {
                "name": "Big Horn County",
                "countryCode": "US"
            },
            {
                "name": "Buffalo",
                "countryCode": "US"
            },
            {
                "name": "Campbell County",
                "countryCode": "US"
            },
            {
                "name": "Carbon County",
                "countryCode": "US"
            },
            {
                "name": "Casper",
                "countryCode": "US"
            },
            {
                "name": "Cheyenne",
                "countryCode": "US"
            },
            {
                "name": "Cody",
                "countryCode": "US"
            },
            {
                "name": "Converse County",
                "countryCode": "US"
            },
            {
                "name": "Crook County",
                "countryCode": "US"
            },
            {
                "name": "Douglas",
                "countryCode": "US"
            },
            {
                "name": "Ethete",
                "countryCode": "US"
            },
            {
                "name": "Evanston",
                "countryCode": "US"
            },
            {
                "name": "Evansville",
                "countryCode": "US"
            },
            {
                "name": "Fort Washakie",
                "countryCode": "US"
            },
            {
                "name": "Fox Farm-College",
                "countryCode": "US"
            },
            {
                "name": "Fremont County",
                "countryCode": "US"
            },
            {
                "name": "Gillette",
                "countryCode": "US"
            },
            {
                "name": "Glenrock",
                "countryCode": "US"
            },
            {
                "name": "Goshen County",
                "countryCode": "US"
            },
            {
                "name": "Green River",
                "countryCode": "US"
            },
            {
                "name": "Greybull",
                "countryCode": "US"
            },
            {
                "name": "Guernsey",
                "countryCode": "US"
            },
            {
                "name": "Hoback",
                "countryCode": "US"
            },
            {
                "name": "Hot Springs County",
                "countryCode": "US"
            },
            {
                "name": "Jackson",
                "countryCode": "US"
            },
            {
                "name": "Johnson County",
                "countryCode": "US"
            },
            {
                "name": "Kemmerer",
                "countryCode": "US"
            },
            {
                "name": "Lander",
                "countryCode": "US"
            },
            {
                "name": "Laramie",
                "countryCode": "US"
            },
            {
                "name": "Laramie County",
                "countryCode": "US"
            },
            {
                "name": "Lincoln County",
                "countryCode": "US"
            },
            {
                "name": "Lovell",
                "countryCode": "US"
            },
            {
                "name": "Lusk",
                "countryCode": "US"
            },
            {
                "name": "Lyman",
                "countryCode": "US"
            },
            {
                "name": "Marbleton",
                "countryCode": "US"
            },
            {
                "name": "Mills",
                "countryCode": "US"
            },
            {
                "name": "Moorcroft",
                "countryCode": "US"
            },
            {
                "name": "Moose Wilson Road",
                "countryCode": "US"
            },
            {
                "name": "Mountain View",
                "countryCode": "US"
            },
            {
                "name": "Natrona County",
                "countryCode": "US"
            },
            {
                "name": "Newcastle",
                "countryCode": "US"
            },
            {
                "name": "Niobrara County",
                "countryCode": "US"
            },
            {
                "name": "North Rock Springs",
                "countryCode": "US"
            },
            {
                "name": "Park County",
                "countryCode": "US"
            },
            {
                "name": "Pine Bluffs",
                "countryCode": "US"
            },
            {
                "name": "Pinedale",
                "countryCode": "US"
            },
            {
                "name": "Platte County",
                "countryCode": "US"
            },
            {
                "name": "Powell",
                "countryCode": "US"
            },
            {
                "name": "Rafter J Ranch",
                "countryCode": "US"
            },
            {
                "name": "Ranchettes",
                "countryCode": "US"
            },
            {
                "name": "Rawlins",
                "countryCode": "US"
            },
            {
                "name": "Riverton",
                "countryCode": "US"
            },
            {
                "name": "Rock Springs",
                "countryCode": "US"
            },
            {
                "name": "Saratoga",
                "countryCode": "US"
            },
            {
                "name": "Sheridan",
                "countryCode": "US"
            },
            {
                "name": "Sheridan County",
                "countryCode": "US"
            },
            {
                "name": "Sleepy Hollow",
                "countryCode": "US"
            },
            {
                "name": "South Greeley",
                "countryCode": "US"
            },
            {
                "name": "South Park",
                "countryCode": "US"
            },
            {
                "name": "Star Valley Ranch",
                "countryCode": "US"
            },
            {
                "name": "Sublette County",
                "countryCode": "US"
            },
            {
                "name": "Sundance",
                "countryCode": "US"
            },
            {
                "name": "Sweetwater County",
                "countryCode": "US"
            },
            {
                "name": "Teton County",
                "countryCode": "US"
            },
            {
                "name": "Thermopolis",
                "countryCode": "US"
            },
            {
                "name": "Torrington",
                "countryCode": "US"
            },
            {
                "name": "Uinta County",
                "countryCode": "US"
            },
            {
                "name": "Upton",
                "countryCode": "US"
            },
            {
                "name": "Washakie County",
                "countryCode": "US"
            },
            {
                "name": "Weston County",
                "countryCode": "US"
            },
            {
                "name": "Wheatland",
                "countryCode": "US"
            },
            {
                "name": "Wilson",
                "countryCode": "US"
            },
            {
                "name": "Worland",
                "countryCode": "US"
            },
            {
                "name": "Wright",
                "countryCode": "US"
            }
        ]
    },
    {
        "name": "United States Minor Outlying Islands",
        "phonecode": "1",
        "cities": []
    },
    {
        "name": "Uruguay",
        "phonecode": "598",
        "cities": [
            {
                "name": "Artigas",
                "countryCode": "UY"
            },
            {
                "name": "Baltasar Brum",
                "countryCode": "UY"
            },
            {
                "name": "Bella Unión",
                "countryCode": "UY"
            },
            {
                "name": "Las Piedras",
                "countryCode": "UY"
            },
            {
                "name": "Tomás Gomensoro",
                "countryCode": "UY"
            },
            {
                "name": "Aguas Corrientes",
                "countryCode": "UY"
            },
            {
                "name": "Atlántida",
                "countryCode": "UY"
            },
            {
                "name": "Barra de Carrasco",
                "countryCode": "UY"
            },
            {
                "name": "Barros Blancos",
                "countryCode": "UY"
            },
            {
                "name": "Canelones",
                "countryCode": "UY"
            },
            {
                "name": "Colonia Nicolich",
                "countryCode": "UY"
            },
            {
                "name": "Empalme Olmos",
                "countryCode": "UY"
            },
            {
                "name": "Joaquín Suárez",
                "countryCode": "UY"
            },
            {
                "name": "Juanicó",
                "countryCode": "UY"
            },
            {
                "name": "La Floresta",
                "countryCode": "UY"
            },
            {
                "name": "La Paz",
                "countryCode": "UY"
            },
            {
                "name": "Las Piedras",
                "countryCode": "UY"
            },
            {
                "name": "Las Toscas",
                "countryCode": "UY"
            },
            {
                "name": "Los Cerrillos",
                "countryCode": "UY"
            },
            {
                "name": "Migues",
                "countryCode": "UY"
            },
            {
                "name": "Montes",
                "countryCode": "UY"
            },
            {
                "name": "Pando",
                "countryCode": "UY"
            },
            {
                "name": "Paso de Carrasco",
                "countryCode": "UY"
            },
            {
                "name": "Progreso",
                "countryCode": "UY"
            },
            {
                "name": "San Antonio",
                "countryCode": "UY"
            },
            {
                "name": "San Bautista",
                "countryCode": "UY"
            },
            {
                "name": "San Jacinto",
                "countryCode": "UY"
            },
            {
                "name": "San Ramón",
                "countryCode": "UY"
            },
            {
                "name": "Santa Lucía",
                "countryCode": "UY"
            },
            {
                "name": "Santa Rosa",
                "countryCode": "UY"
            },
            {
                "name": "Sauce",
                "countryCode": "UY"
            },
            {
                "name": "Soca",
                "countryCode": "UY"
            },
            {
                "name": "Tala",
                "countryCode": "UY"
            },
            {
                "name": "Toledo",
                "countryCode": "UY"
            },
            {
                "name": "Aceguá",
                "countryCode": "UY"
            },
            {
                "name": "Isidoro Noblía",
                "countryCode": "UY"
            },
            {
                "name": "Melo",
                "countryCode": "UY"
            },
            {
                "name": "Río Branco",
                "countryCode": "UY"
            },
            {
                "name": "Tupambaé",
                "countryCode": "UY"
            },
            {
                "name": "Carmelo",
                "countryCode": "UY"
            },
            {
                "name": "Colonia del Sacramento",
                "countryCode": "UY"
            },
            {
                "name": "Florencio Sánchez",
                "countryCode": "UY"
            },
            {
                "name": "Juan L. Lacaze",
                "countryCode": "UY"
            },
            {
                "name": "Nueva Helvecia",
                "countryCode": "UY"
            },
            {
                "name": "Nueva Palmira",
                "countryCode": "UY"
            },
            {
                "name": "Ombúes de Lavalle",
                "countryCode": "UY"
            },
            {
                "name": "Rosario",
                "countryCode": "UY"
            },
            {
                "name": "Tarariras",
                "countryCode": "UY"
            },
            {
                "name": "Blanquillo",
                "countryCode": "UY"
            },
            {
                "name": "Carlos Reyles",
                "countryCode": "UY"
            },
            {
                "name": "Durazno",
                "countryCode": "UY"
            },
            {
                "name": "La Paloma",
                "countryCode": "UY"
            },
            {
                "name": "Santa Bernardina",
                "countryCode": "UY"
            },
            {
                "name": "Sarandí del Yi",
                "countryCode": "UY"
            },
            {
                "name": "Villa del Carmen",
                "countryCode": "UY"
            },
            {
                "name": "25 de Agosto",
                "countryCode": "UY"
            },
            {
                "name": "25 de Mayo",
                "countryCode": "UY"
            },
            {
                "name": "Alejandro Gallinal",
                "countryCode": "UY"
            },
            {
                "name": "Cardal",
                "countryCode": "UY"
            },
            {
                "name": "Casupá",
                "countryCode": "UY"
            },
            {
                "name": "Florida",
                "countryCode": "UY"
            },
            {
                "name": "Sarandí Grande",
                "countryCode": "UY"
            },
            {
                "name": "Trinidad",
                "countryCode": "UY"
            },
            {
                "name": "José Batlle y Ordóñez",
                "countryCode": "UY"
            },
            {
                "name": "José Pedro Varela",
                "countryCode": "UY"
            },
            {
                "name": "Mariscala",
                "countryCode": "UY"
            },
            {
                "name": "Minas",
                "countryCode": "UY"
            },
            {
                "name": "Solís de Mataojo",
                "countryCode": "UY"
            },
            {
                "name": "Aiguá",
                "countryCode": "UY"
            },
            {
                "name": "Maldonado",
                "countryCode": "UY"
            },
            {
                "name": "Pan de Azúcar",
                "countryCode": "UY"
            },
            {
                "name": "Piriápolis",
                "countryCode": "UY"
            },
            {
                "name": "Punta del Este",
                "countryCode": "UY"
            },
            {
                "name": "San Carlos",
                "countryCode": "UY"
            },
            {
                "name": "Bella Vista",
                "countryCode": "UY"
            },
            {
                "name": "Belvedere",
                "countryCode": "UY"
            },
            {
                "name": "Buceo",
                "countryCode": "UY"
            },
            {
                "name": "Capurro (Montevideo)",
                "countryCode": "UY"
            },
            {
                "name": "Carrasco",
                "countryCode": "UY"
            },
            {
                "name": "Casabó",
                "countryCode": "UY"
            },
            {
                "name": "Centro",
                "countryCode": "UY"
            },
            {
                "name": "Cerrito, Montevideo",
                "countryCode": "UY"
            },
            {
                "name": "Colón Centro y Noroeste",
                "countryCode": "UY"
            },
            {
                "name": "Colón Sudeste",
                "countryCode": "UY"
            },
            {
                "name": "Conciliación",
                "countryCode": "UY"
            },
            {
                "name": "Cordón",
                "countryCode": "UY"
            },
            {
                "name": "Flor de Maroñas",
                "countryCode": "UY"
            },
            {
                "name": "Ituzaingó",
                "countryCode": "UY"
            },
            {
                "name": "La Unión",
                "countryCode": "UY"
            },
            {
                "name": "Malvín",
                "countryCode": "UY"
            },
            {
                "name": "Manga",
                "countryCode": "UY"
            },
            {
                "name": "Maroñas",
                "countryCode": "UY"
            },
            {
                "name": "Montevideo",
                "countryCode": "UY"
            },
            {
                "name": "Nuevo París",
                "countryCode": "UY"
            },
            {
                "name": "Pajas Blancas",
                "countryCode": "UY"
            },
            {
                "name": "Paso del Molino",
                "countryCode": "UY"
            },
            {
                "name": "Peñarol",
                "countryCode": "UY"
            },
            {
                "name": "Piedras Blancas",
                "countryCode": "UY"
            },
            {
                "name": "Pocitos",
                "countryCode": "UY"
            },
            {
                "name": "Punta Carretas",
                "countryCode": "UY"
            },
            {
                "name": "Punta Gorda",
                "countryCode": "UY"
            },
            {
                "name": "Reducto",
                "countryCode": "UY"
            },
            {
                "name": "Santiago Vázquez",
                "countryCode": "UY"
            },
            {
                "name": "Sayage",
                "countryCode": "UY"
            },
            {
                "name": "Tres Cruces",
                "countryCode": "UY"
            },
            {
                "name": "Villa Española",
                "countryCode": "UY"
            },
            {
                "name": "Villa García",
                "countryCode": "UY"
            },
            {
                "name": "Villa Muñoz",
                "countryCode": "UY"
            },
            {
                "name": "Villa del Cerro",
                "countryCode": "UY"
            },
            {
                "name": "Estación Porvenir",
                "countryCode": "UY"
            },
            {
                "name": "Guichón",
                "countryCode": "UY"
            },
            {
                "name": "Paysandú",
                "countryCode": "UY"
            },
            {
                "name": "Piedras Coloradas",
                "countryCode": "UY"
            },
            {
                "name": "Quebracho",
                "countryCode": "UY"
            },
            {
                "name": "San Félix",
                "countryCode": "UY"
            },
            {
                "name": "Fray Bentos",
                "countryCode": "UY"
            },
            {
                "name": "Nuevo Berlín",
                "countryCode": "UY"
            },
            {
                "name": "San Javier",
                "countryCode": "UY"
            },
            {
                "name": "Young",
                "countryCode": "UY"
            },
            {
                "name": "Castillos",
                "countryCode": "UY"
            },
            {
                "name": "Cebollatí",
                "countryCode": "UY"
            },
            {
                "name": "Chui",
                "countryCode": "UY"
            },
            {
                "name": "Dieciocho de Julio",
                "countryCode": "UY"
            },
            {
                "name": "La Paloma",
                "countryCode": "UY"
            },
            {
                "name": "Lascano",
                "countryCode": "UY"
            },
            {
                "name": "Rocha",
                "countryCode": "UY"
            },
            {
                "name": "Velázquez",
                "countryCode": "UY"
            },
            {
                "name": "Minas de Corrales",
                "countryCode": "UY"
            },
            {
                "name": "Rivera",
                "countryCode": "UY"
            },
            {
                "name": "Tranqueras",
                "countryCode": "UY"
            },
            {
                "name": "Vichadero",
                "countryCode": "UY"
            },
            {
                "name": "Belén",
                "countryCode": "UY"
            },
            {
                "name": "Salto",
                "countryCode": "UY"
            },
            {
                "name": "Villa Constitución",
                "countryCode": "UY"
            },
            {
                "name": "Delta del Tigre",
                "countryCode": "UY"
            },
            {
                "name": "Ecilda Paullier",
                "countryCode": "UY"
            },
            {
                "name": "Libertad",
                "countryCode": "UY"
            },
            {
                "name": "Puntas de Valdéz",
                "countryCode": "UY"
            },
            {
                "name": "Rafael Perazza",
                "countryCode": "UY"
            },
            {
                "name": "Rodríguez",
                "countryCode": "UY"
            },
            {
                "name": "San José de Mayo",
                "countryCode": "UY"
            },
            {
                "name": "Cardona",
                "countryCode": "UY"
            },
            {
                "name": "Dolores",
                "countryCode": "UY"
            },
            {
                "name": "José Enrique Rodó",
                "countryCode": "UY"
            },
            {
                "name": "Mercedes",
                "countryCode": "UY"
            },
            {
                "name": "Palmitas",
                "countryCode": "UY"
            },
            {
                "name": "Santa Catalina",
                "countryCode": "UY"
            },
            {
                "name": "Villa Soriano",
                "countryCode": "UY"
            },
            {
                "name": "Curtina",
                "countryCode": "UY"
            },
            {
                "name": "Paso de los Toros",
                "countryCode": "UY"
            },
            {
                "name": "Tacuarembó",
                "countryCode": "UY"
            },
            {
                "name": "Santa Clara de Olimar",
                "countryCode": "UY"
            },
            {
                "name": "Treinta y Tres",
                "countryCode": "UY"
            },
            {
                "name": "Vergara",
                "countryCode": "UY"
            },
            {
                "name": "Villa Sara",
                "countryCode": "UY"
            }
        ]
    },
    {
        "name": "Uzbekistan",
        "phonecode": "998",
        "cities": [
            {
                "name": "Andijon",
                "countryCode": "UZ"
            },
            {
                "name": "Andijon Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Asaka",
                "countryCode": "UZ"
            },
            {
                "name": "Asaka Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Baliqchi Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Bo‘z Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Buloqboshi Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Izboskan Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Jalolkuduk Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Khŭjaobod Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Marhamat",
                "countryCode": "UZ"
            },
            {
                "name": "Marhamat Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Oltinkŭl Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Pakhtaobod Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Paxtaobod",
                "countryCode": "UZ"
            },
            {
                "name": "Qo‘rg‘ontepa",
                "countryCode": "UZ"
            },
            {
                "name": "Qŭrghontepa Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Shahrikhon Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Shahrixon",
                "countryCode": "UZ"
            },
            {
                "name": "Ulug‘nor Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Xo‘jaobod",
                "countryCode": "UZ"
            },
            {
                "name": "Bukhara",
                "countryCode": "UZ"
            },
            {
                "name": "Galaosiyo",
                "countryCode": "UZ"
            },
            {
                "name": "Gazli",
                "countryCode": "UZ"
            },
            {
                "name": "Ghijduwon",
                "countryCode": "UZ"
            },
            {
                "name": "Karakul’",
                "countryCode": "UZ"
            },
            {
                "name": "Kogon",
                "countryCode": "UZ"
            },
            {
                "name": "Olot",
                "countryCode": "UZ"
            },
            {
                "name": "Peshku Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Qorako’l",
                "countryCode": "UZ"
            },
            {
                "name": "Qorovulbozor",
                "countryCode": "UZ"
            },
            {
                "name": "Romiton",
                "countryCode": "UZ"
            },
            {
                "name": "Shofirkon",
                "countryCode": "UZ"
            },
            {
                "name": "Wobkent",
                "countryCode": "UZ"
            },
            {
                "name": "Wobkent Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Beshariq",
                "countryCode": "UZ"
            },
            {
                "name": "Fergana",
                "countryCode": "UZ"
            },
            {
                "name": "Hamza",
                "countryCode": "UZ"
            },
            {
                "name": "Kirguli",
                "countryCode": "UZ"
            },
            {
                "name": "Marg‘ilon",
                "countryCode": "UZ"
            },
            {
                "name": "Oltiariq",
                "countryCode": "UZ"
            },
            {
                "name": "Qo‘qon",
                "countryCode": "UZ"
            },
            {
                "name": "Quva",
                "countryCode": "UZ"
            },
            {
                "name": "Quvasoy",
                "countryCode": "UZ"
            },
            {
                "name": "Rishton",
                "countryCode": "UZ"
            },
            {
                "name": "Shohimardon",
                "countryCode": "UZ"
            },
            {
                "name": "So‘x Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Toshloq",
                "countryCode": "UZ"
            },
            {
                "name": "Yangi Marg‘ilon",
                "countryCode": "UZ"
            },
            {
                "name": "Yaypan",
                "countryCode": "UZ"
            },
            {
                "name": "Dashtobod",
                "countryCode": "UZ"
            },
            {
                "name": "Dŭstlik",
                "countryCode": "UZ"
            },
            {
                "name": "Gagarin",
                "countryCode": "UZ"
            },
            {
                "name": "Jizzax",
                "countryCode": "UZ"
            },
            {
                "name": "Paxtakor",
                "countryCode": "UZ"
            },
            {
                "name": "Zomin",
                "countryCode": "UZ"
            },
            {
                "name": "Chortoq",
                "countryCode": "UZ"
            },
            {
                "name": "Chust",
                "countryCode": "UZ"
            },
            {
                "name": "Haqqulobod",
                "countryCode": "UZ"
            },
            {
                "name": "Kosonsoy",
                "countryCode": "UZ"
            },
            {
                "name": "Namangan",
                "countryCode": "UZ"
            },
            {
                "name": "Pop",
                "countryCode": "UZ"
            },
            {
                "name": "Toshbuloq",
                "countryCode": "UZ"
            },
            {
                "name": "To‘rqao‘rg‘on",
                "countryCode": "UZ"
            },
            {
                "name": "Uchqŭrghon Shahri",
                "countryCode": "UZ"
            },
            {
                "name": "Uychi",
                "countryCode": "UZ"
            },
            {
                "name": "Yangiqo‘rg‘on",
                "countryCode": "UZ"
            },
            {
                "name": "Navoiy",
                "countryCode": "UZ"
            },
            {
                "name": "Nurota",
                "countryCode": "UZ"
            },
            {
                "name": "Qiziltepa",
                "countryCode": "UZ"
            },
            {
                "name": "Yangirabot",
                "countryCode": "UZ"
            },
            {
                "name": "Beshkent",
                "countryCode": "UZ"
            },
            {
                "name": "Chiroqchi",
                "countryCode": "UZ"
            },
            {
                "name": "G‘uzor",
                "countryCode": "UZ"
            },
            {
                "name": "Kitob",
                "countryCode": "UZ"
            },
            {
                "name": "Koson",
                "countryCode": "UZ"
            },
            {
                "name": "Muborak",
                "countryCode": "UZ"
            },
            {
                "name": "Nishon Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Qarshi",
                "countryCode": "UZ"
            },
            {
                "name": "Shahrisabz",
                "countryCode": "UZ"
            },
            {
                "name": "Beruniy",
                "countryCode": "UZ"
            },
            {
                "name": "Kegeyli Shahar",
                "countryCode": "UZ"
            },
            {
                "name": "Khŭjayli",
                "countryCode": "UZ"
            },
            {
                "name": "Manghit",
                "countryCode": "UZ"
            },
            {
                "name": "Mŭynoq",
                "countryCode": "UZ"
            },
            {
                "name": "Novyy Turtkul’",
                "countryCode": "UZ"
            },
            {
                "name": "Nukus",
                "countryCode": "UZ"
            },
            {
                "name": "Oltinko‘l",
                "countryCode": "UZ"
            },
            {
                "name": "Bulung’ur",
                "countryCode": "UZ"
            },
            {
                "name": "Charxin",
                "countryCode": "UZ"
            },
            {
                "name": "Chelak",
                "countryCode": "UZ"
            },
            {
                "name": "Daxbet",
                "countryCode": "UZ"
            },
            {
                "name": "Ishtixon",
                "countryCode": "UZ"
            },
            {
                "name": "Jomboy",
                "countryCode": "UZ"
            },
            {
                "name": "Juma",
                "countryCode": "UZ"
            },
            {
                "name": "Kattaqo‘rg‘on",
                "countryCode": "UZ"
            },
            {
                "name": "Oqtosh",
                "countryCode": "UZ"
            },
            {
                "name": "Payshanba",
                "countryCode": "UZ"
            },
            {
                "name": "Samarkand",
                "countryCode": "UZ"
            },
            {
                "name": "Urgut",
                "countryCode": "UZ"
            },
            {
                "name": "Guliston",
                "countryCode": "UZ"
            },
            {
                "name": "Sirdaryo",
                "countryCode": "UZ"
            },
            {
                "name": "Yangiyer",
                "countryCode": "UZ"
            },
            {
                "name": "Boysun",
                "countryCode": "UZ"
            },
            {
                "name": "Denov",
                "countryCode": "UZ"
            },
            {
                "name": "Sho‘rchi",
                "countryCode": "UZ"
            },
            {
                "name": "Tirmiz",
                "countryCode": "UZ"
            },
            {
                "name": "Bektemir",
                "countryCode": "UZ"
            },
            {
                "name": "Tashkent",
                "countryCode": "UZ"
            },
            {
                "name": "Angren",
                "countryCode": "UZ"
            },
            {
                "name": "Bekobod",
                "countryCode": "UZ"
            },
            {
                "name": "Bo‘ka",
                "countryCode": "UZ"
            },
            {
                "name": "Chinoz",
                "countryCode": "UZ"
            },
            {
                "name": "Chirchiq",
                "countryCode": "UZ"
            },
            {
                "name": "G‘azalkent",
                "countryCode": "UZ"
            },
            {
                "name": "Iskandar",
                "countryCode": "UZ"
            },
            {
                "name": "Kyzyldzhar",
                "countryCode": "UZ"
            },
            {
                "name": "Ohangaron",
                "countryCode": "UZ"
            },
            {
                "name": "Olmaliq",
                "countryCode": "UZ"
            },
            {
                "name": "Parkent",
                "countryCode": "UZ"
            },
            {
                "name": "Piskent",
                "countryCode": "UZ"
            },
            {
                "name": "Qibray",
                "countryCode": "UZ"
            },
            {
                "name": "Salor",
                "countryCode": "UZ"
            },
            {
                "name": "Tŭytepa",
                "countryCode": "UZ"
            },
            {
                "name": "Yangiobod",
                "countryCode": "UZ"
            },
            {
                "name": "Yangiyŭl",
                "countryCode": "UZ"
            },
            {
                "name": "Zafar",
                "countryCode": "UZ"
            },
            {
                "name": "Ŭrtaowul",
                "countryCode": "UZ"
            },
            {
                "name": "Boghot Tumani",
                "countryCode": "UZ"
            },
            {
                "name": "Gurlan",
                "countryCode": "UZ"
            },
            {
                "name": "Hazorasp",
                "countryCode": "UZ"
            },
            {
                "name": "Khiwa",
                "countryCode": "UZ"
            },
            {
                "name": "Qŭshkŭpir",
                "countryCode": "UZ"
            },
            {
                "name": "Showot",
                "countryCode": "UZ"
            },
            {
                "name": "Urganch",
                "countryCode": "UZ"
            }
        ]
    },
    {
        "name": "Vanuatu",
        "phonecode": "678",
        "cities": [
            {
                "name": "Lakatoro",
                "countryCode": "VU"
            },
            {
                "name": "Norsup",
                "countryCode": "VU"
            },
            {
                "name": "Luganville",
                "countryCode": "VU"
            },
            {
                "name": "Port-Olry",
                "countryCode": "VU"
            },
            {
                "name": "Port-Vila",
                "countryCode": "VU"
            },
            {
                "name": "Isangel",
                "countryCode": "VU"
            },
            {
                "name": "Sola",
                "countryCode": "VU"
            }
        ]
    },
    {
        "name": "Vatican City State (Holy See)",
        "phonecode": "379",
        "cities": []
    },
    {
        "name": "Venezuela",
        "phonecode": "58",
        "cities": [
            {
                "name": "Caracas",
                "countryCode": "VE"
            },
            {
                "name": "Anaco",
                "countryCode": "VE"
            },
            {
                "name": "Aragua de Barcelona",
                "countryCode": "VE"
            },
            {
                "name": "Barcelona",
                "countryCode": "VE"
            },
            {
                "name": "Cantaura",
                "countryCode": "VE"
            },
            {
                "name": "El Tigre",
                "countryCode": "VE"
            },
            {
                "name": "Municipio José Gregorio Monagas",
                "countryCode": "VE"
            },
            {
                "name": "Onoto",
                "countryCode": "VE"
            },
            {
                "name": "Puerto La Cruz",
                "countryCode": "VE"
            },
            {
                "name": "Puerto Píritu",
                "countryCode": "VE"
            },
            {
                "name": "San José de Guanipa",
                "countryCode": "VE"
            },
            {
                "name": "Cagua",
                "countryCode": "VE"
            },
            {
                "name": "El Limón",
                "countryCode": "VE"
            },
            {
                "name": "La Victoria",
                "countryCode": "VE"
            },
            {
                "name": "Las Tejerías",
                "countryCode": "VE"
            },
            {
                "name": "Maracay",
                "countryCode": "VE"
            },
            {
                "name": "Palo Negro",
                "countryCode": "VE"
            },
            {
                "name": "San Mateo",
                "countryCode": "VE"
            },
            {
                "name": "Santa Rita",
                "countryCode": "VE"
            },
            {
                "name": "Turmero",
                "countryCode": "VE"
            },
            {
                "name": "Villa de Cura",
                "countryCode": "VE"
            },
            {
                "name": "Alto Barinas",
                "countryCode": "VE"
            },
            {
                "name": "Barinas",
                "countryCode": "VE"
            },
            {
                "name": "Barinitas",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Barinas",
                "countryCode": "VE"
            },
            {
                "name": "Ciudad Bolívar",
                "countryCode": "VE"
            },
            {
                "name": "Ciudad Guayana",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Padre Pedro Chien",
                "countryCode": "VE"
            },
            {
                "name": "Santa Elena de Uairén",
                "countryCode": "VE"
            },
            {
                "name": "Upata",
                "countryCode": "VE"
            },
            {
                "name": "Guacara",
                "countryCode": "VE"
            },
            {
                "name": "Güigüe",
                "countryCode": "VE"
            },
            {
                "name": "Los Guayos",
                "countryCode": "VE"
            },
            {
                "name": "Mariara",
                "countryCode": "VE"
            },
            {
                "name": "Morón",
                "countryCode": "VE"
            },
            {
                "name": "Puerto Cabello",
                "countryCode": "VE"
            },
            {
                "name": "San Joaquín",
                "countryCode": "VE"
            },
            {
                "name": "Tacarigua",
                "countryCode": "VE"
            },
            {
                "name": "Tocuyito",
                "countryCode": "VE"
            },
            {
                "name": "Valencia",
                "countryCode": "VE"
            },
            {
                "name": "San Carlos",
                "countryCode": "VE"
            },
            {
                "name": "Tinaquillo",
                "countryCode": "VE"
            },
            {
                "name": "Chichiriviche",
                "countryCode": "VE"
            },
            {
                "name": "Coro",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Los Taques",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Miranda",
                "countryCode": "VE"
            },
            {
                "name": "Punta Cardón",
                "countryCode": "VE"
            },
            {
                "name": "Punto Fijo",
                "countryCode": "VE"
            },
            {
                "name": "Tucacas",
                "countryCode": "VE"
            },
            {
                "name": "Altagracia de Orituco",
                "countryCode": "VE"
            },
            {
                "name": "Calabozo",
                "countryCode": "VE"
            },
            {
                "name": "San Juan de los Morros",
                "countryCode": "VE"
            },
            {
                "name": "Valle de La Pascua",
                "countryCode": "VE"
            },
            {
                "name": "Zaraza",
                "countryCode": "VE"
            },
            {
                "name": "Barquisimeto",
                "countryCode": "VE"
            },
            {
                "name": "Cabudare",
                "countryCode": "VE"
            },
            {
                "name": "Carora",
                "countryCode": "VE"
            },
            {
                "name": "El Tocuyo",
                "countryCode": "VE"
            },
            {
                "name": "Los Rastrojos",
                "countryCode": "VE"
            },
            {
                "name": "Quíbor",
                "countryCode": "VE"
            },
            {
                "name": "Ejido",
                "countryCode": "VE"
            },
            {
                "name": "El Vigía",
                "countryCode": "VE"
            },
            {
                "name": "Mucumpiz",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Libertador",
                "countryCode": "VE"
            },
            {
                "name": "Mérida",
                "countryCode": "VE"
            },
            {
                "name": "Baruta",
                "countryCode": "VE"
            },
            {
                "name": "Carrizal",
                "countryCode": "VE"
            },
            {
                "name": "Caucaguita",
                "countryCode": "VE"
            },
            {
                "name": "Caucagüito",
                "countryCode": "VE"
            },
            {
                "name": "Chacao",
                "countryCode": "VE"
            },
            {
                "name": "Charallave",
                "countryCode": "VE"
            },
            {
                "name": "Cúa",
                "countryCode": "VE"
            },
            {
                "name": "El Cafetal",
                "countryCode": "VE"
            },
            {
                "name": "El Hatillo",
                "countryCode": "VE"
            },
            {
                "name": "Guarenas",
                "countryCode": "VE"
            },
            {
                "name": "Guatire",
                "countryCode": "VE"
            },
            {
                "name": "La Dolorita",
                "countryCode": "VE"
            },
            {
                "name": "Los Dos Caminos",
                "countryCode": "VE"
            },
            {
                "name": "Los Teques",
                "countryCode": "VE"
            },
            {
                "name": "Ocumare del Tuy",
                "countryCode": "VE"
            },
            {
                "name": "Petare",
                "countryCode": "VE"
            },
            {
                "name": "San Antonio de Los Altos",
                "countryCode": "VE"
            },
            {
                "name": "Santa Teresa del Tuy",
                "countryCode": "VE"
            },
            {
                "name": "Caripito",
                "countryCode": "VE"
            },
            {
                "name": "Maturín",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Maturín",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Uracoa",
                "countryCode": "VE"
            },
            {
                "name": "Juan Griego",
                "countryCode": "VE"
            },
            {
                "name": "La Asunción",
                "countryCode": "VE"
            },
            {
                "name": "Porlamar",
                "countryCode": "VE"
            },
            {
                "name": "Acarigua",
                "countryCode": "VE"
            },
            {
                "name": "Araure",
                "countryCode": "VE"
            },
            {
                "name": "Guanare",
                "countryCode": "VE"
            },
            {
                "name": "Villa Bruzual",
                "countryCode": "VE"
            },
            {
                "name": "Carúpano",
                "countryCode": "VE"
            },
            {
                "name": "Cumaná",
                "countryCode": "VE"
            },
            {
                "name": "Güiria",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Valdez",
                "countryCode": "VE"
            },
            {
                "name": "Colón",
                "countryCode": "VE"
            },
            {
                "name": "La Fría",
                "countryCode": "VE"
            },
            {
                "name": "La Grita",
                "countryCode": "VE"
            },
            {
                "name": "Municipio José María Vargas",
                "countryCode": "VE"
            },
            {
                "name": "Rubio",
                "countryCode": "VE"
            },
            {
                "name": "San Antonio del Táchira",
                "countryCode": "VE"
            },
            {
                "name": "San Cristóbal",
                "countryCode": "VE"
            },
            {
                "name": "Táriba",
                "countryCode": "VE"
            },
            {
                "name": "Boconó",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Pampanito",
                "countryCode": "VE"
            },
            {
                "name": "Municipio San Rafael de Carvajal",
                "countryCode": "VE"
            },
            {
                "name": "Trujillo",
                "countryCode": "VE"
            },
            {
                "name": "Valera",
                "countryCode": "VE"
            },
            {
                "name": "Chivacoa",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Independencia",
                "countryCode": "VE"
            },
            {
                "name": "Nirgua",
                "countryCode": "VE"
            },
            {
                "name": "San Felipe",
                "countryCode": "VE"
            },
            {
                "name": "Yaritagua",
                "countryCode": "VE"
            },
            {
                "name": "Cabimas",
                "countryCode": "VE"
            },
            {
                "name": "Ciudad Ojeda",
                "countryCode": "VE"
            },
            {
                "name": "La Villa del Rosario",
                "countryCode": "VE"
            },
            {
                "name": "Lagunillas",
                "countryCode": "VE"
            },
            {
                "name": "Machiques",
                "countryCode": "VE"
            },
            {
                "name": "Maracaibo",
                "countryCode": "VE"
            },
            {
                "name": "San Carlos del Zulia",
                "countryCode": "VE"
            },
            {
                "name": "Santa Rita",
                "countryCode": "VE"
            },
            {
                "name": "Caraballeda",
                "countryCode": "VE"
            },
            {
                "name": "Catia La Mar",
                "countryCode": "VE"
            },
            {
                "name": "La Guaira",
                "countryCode": "VE"
            },
            {
                "name": "Maiquetía",
                "countryCode": "VE"
            },
            {
                "name": "Tucupita",
                "countryCode": "VE"
            },
            {
                "name": "Maroa",
                "countryCode": "VE"
            },
            {
                "name": "Municipio Autónomo Alto Orinoco",
                "countryCode": "VE"
            },
            {
                "name": "Puerto Ayacucho",
                "countryCode": "VE"
            },
            {
                "name": "San Carlos de Río Negro",
                "countryCode": "VE"
            },
            {
                "name": "San Fernando de Atabapo",
                "countryCode": "VE"
            },
            {
                "name": "San Juan de Manapiare",
                "countryCode": "VE"
            }
        ]
    },
    {
        "name": "Vietnam",
        "phonecode": "84",
        "cities": [
            {
                "name": "Huyện Mưòng Tè",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tam Đường",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Than Uyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bát Xát",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bảo Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mường Khương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sa Pa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Si Ma Cai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Văn Bàn",
                "countryCode": "VN"
            },
            {
                "name": "Lao Chải",
                "countryCode": "VN"
            },
            {
                "name": "Lào Cai",
                "countryCode": "VN"
            },
            {
                "name": "Sa Pa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Mê",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Quang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quang Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vị Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Xín Mần",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Minh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đồng Văn",
                "countryCode": "VN"
            },
            {
                "name": "Hà Giang",
                "countryCode": "VN"
            },
            {
                "name": "Cao Bằng",
                "countryCode": "VN"
            },
            {
                "name": "Huyen Phuc Hoa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bảo Lac",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bảo Lâm",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hà Quảng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hạ Lang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nguyên Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thông Nông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thạch An",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trà Lĩnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trùng Khánh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mai Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mường La",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mộc Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phù Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quỳnh Nhai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sông Mã",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thuận Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Châu",
                "countryCode": "VN"
            },
            {
                "name": "Sơn La",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lục Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mù Cang Chải",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trạm Tấu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trấn Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Văn Chấn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Văn Yên",
                "countryCode": "VN"
            },
            {
                "name": "Yên Bái",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chiêm Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hàm Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lâm Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Na Hang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sơn Dương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Tuyên Quang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Gia",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cao Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chi Lăng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hũu Lũng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vặn Quan",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đình Lập",
                "countryCode": "VN"
            },
            {
                "name": "Lạng Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Cẩm Phả",
                "countryCode": "VN"
            },
            {
                "name": "Cẩm Phả Mines",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ba Chẽ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Liêu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cô Tô",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hoành Bồ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hải Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiên Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vân Đồn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đông Triều",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đầm Hà",
                "countryCode": "VN"
            },
            {
                "name": "Hạ Long",
                "countryCode": "VN"
            },
            {
                "name": "Móng Cái",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Uông Bí",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cao Phong",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kỳ Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lương Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lạc Thủy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Lạc",
                "countryCode": "VN"
            },
            {
                "name": "Hòa Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hoa Lư",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kim Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nho Quan",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Khánh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Mô",
                "countryCode": "VN"
            },
            {
                "name": "Ninh Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hưng Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kiến Xương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quỳnh Phụ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thái Thụy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiền Hải",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vũ Thư",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ðông Hưng",
                "countryCode": "VN"
            },
            {
                "name": "Thái Bình",
                "countryCode": "VN"
            },
            {
                "name": "Bỉm Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bá Thước",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cẩm Thủy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hà Trung",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hậu Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lang Chánh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mường Lát",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nga Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ngọc Lặc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Như Thanh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Như Xuân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nông Cống",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quan Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quan Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quảng Xương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thiệu Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thường Xuân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thọ Xuân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Triệu Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Định",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đông Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Thanh Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Anh Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Con Cuông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Diễn Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hưng Nguyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kỳ Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nam Đàn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nghi Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nghĩa Đàn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quế Phong",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quỳ Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quỳ Hợp",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quỳnh Lưu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thanh Chương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tương Dương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đô Lương",
                "countryCode": "VN"
            },
            {
                "name": "Vinh",
                "countryCode": "VN"
            },
            {
                "name": "Yên Vinh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Can Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cẩm Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kỳ Anh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nghi Xuân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thạch Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ðức Thọ",
                "countryCode": "VN"
            },
            {
                "name": "Hà Tĩnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lệ Thủy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quảng Ninh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quảng Trạch",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tuyên Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Kwang Binh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cam Lộ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gio Linh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hướng Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hải Lăng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Triệu Phong",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Linh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đa Krông",
                "countryCode": "VN"
            },
            {
                "name": "Ðông Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện A Lưới",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nam Đông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phong Điền",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phú Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phú Vang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quảng Ðiền",
                "countryCode": "VN"
            },
            {
                "name": "Huế",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Duy Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hiệp Đức",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nam Giang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Núi Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phước Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiên Phước",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trà My",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Điện Bàn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đại Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Hội An",
                "countryCode": "VN"
            },
            {
                "name": "Tam Kỳ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ia H'Drai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kon Plông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ngọc Hồi",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sa Thầy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đắk Glei",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đắk Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đắk Tô",
                "countryCode": "VN"
            },
            {
                "name": "Kon Tum",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ba Tơ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lý Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Minh Long",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mộ Đức",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nghĩa Hành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sơn Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sơn Tây",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sơn Tịnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trà Bồng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tư Nghĩa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đức Phổ",
                "countryCode": "VN"
            },
            {
                "name": "Quảng Ngãi",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chư Prông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chư Păh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chư Sê",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ia Grai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện KBang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Krông Pa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kông Chro",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mang Yang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đức Cơ",
                "countryCode": "VN"
            },
            {
                "name": "Pleiku",
                "countryCode": "VN"
            },
            {
                "name": "Huyện An Lão",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hoài Nhơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phù Mỹ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tuy Phước",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tây Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Thạnh",
                "countryCode": "VN"
            },
            {
                "name": "Qui Nhon",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sông Hinh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Sơn Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Sông Cầu",
                "countryCode": "VN"
            },
            {
                "name": "Tuy Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Buôn Ma Thuột",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Buôn Đôn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ea H'Leo",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ea Súp",
                "countryCode": "VN"
            },
            {
                "name": "Cam Ranh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Diên Khánh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Khánh Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Khánh Vĩnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vạn Ninh",
                "countryCode": "VN"
            },
            {
                "name": "Nha Trang",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Cam Ranh",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Nha Trang",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Ninh Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Bảo Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bảo Lâm",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lâm Hà",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đơn Dương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đạ Huoai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đạ Tẻh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đức Trọng",
                "countryCode": "VN"
            },
            {
                "name": "Ðà Lạt",
                "countryCode": "VN"
            },
            {
                "name": "Đam Rong",
                "countryCode": "VN"
            },
            {
                "name": "Đinh Văn",
                "countryCode": "VN"
            },
            {
                "name": "Đưc Trọng",
                "countryCode": "VN"
            },
            {
                "name": "Phan Rang-Tháp Chàm",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bến Cầu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Dương Minh Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gò Dầu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hòa Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trảng Bàng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Châu",
                "countryCode": "VN"
            },
            {
                "name": "Phú Khương",
                "countryCode": "VN"
            },
            {
                "name": "Tây Ninh",
                "countryCode": "VN"
            },
            {
                "name": "Biên Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Long Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thống Nhất",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trảng Bom",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Phú",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Cửu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Định Quán",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bắc Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hàm Thuận Bắc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hàm Thuận Nam",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hàm Tân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tuy Phong",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tánh Linh",
                "countryCode": "VN"
            },
            {
                "name": "La Gi",
                "countryCode": "VN"
            },
            {
                "name": "Phan Thiết",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Phan Thiết",
                "countryCode": "VN"
            },
            {
                "name": "Cần Giuộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bến Lức",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cần Giuộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cần Đước",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mộc Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thạnh Hóa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thủ Thừa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Hưng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Thạnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Trụ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Hưng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đức Huệ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đức Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Tân An",
                "countryCode": "VN"
            },
            {
                "name": "Côn Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Đức",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Xuyên Mộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đất Đỏ",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Vũng Tàu",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Phú Mỹ",
                "countryCode": "VN"
            },
            {
                "name": "Vũng Tàu",
                "countryCode": "VN"
            },
            {
                "name": "Cho Dok",
                "countryCode": "VN"
            },
            {
                "name": "Huyện An Phú",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Phú",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chợ Mới",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phú Tân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tri Tôn",
                "countryCode": "VN"
            },
            {
                "name": "Long Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Long Xuyên City",
                "countryCode": "VN"
            },
            {
                "name": "Cao Lãnh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lai Vung",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lấp Vò",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tam Nông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thanh Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tháp Mười",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Hồng",
                "countryCode": "VN"
            },
            {
                "name": "Sa Dec",
                "countryCode": "VN"
            },
            {
                "name": "Sa Dec city",
                "countryCode": "VN"
            },
            {
                "name": "Thị Trấn Tân Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cai Lậy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chợ Gạo",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cái Bè",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gò Công Tây",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gò Công Đông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Phước",
                "countryCode": "VN"
            },
            {
                "name": "Mỹ Tho",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Mỹ Tho",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Gò Công",
                "countryCode": "VN"
            },
            {
                "name": "Dương Đông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện An Biên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện An Minh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Giồng Riềng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gò Quao",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hòn Đất",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kiên Hải",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phú Quốc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tân Hiệp",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vĩnh Thuận",
                "countryCode": "VN"
            },
            {
                "name": "Hà Tiên",
                "countryCode": "VN"
            },
            {
                "name": "Kien Luong Town",
                "countryCode": "VN"
            },
            {
                "name": "Rạch Giá",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Long Hồ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mang Thít",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tam Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trà Ôn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vũng Liêm",
                "countryCode": "VN"
            },
            {
                "name": "Vĩnh Long",
                "countryCode": "VN"
            },
            {
                "name": "Bến Tre",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ba Tri",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Đại",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chợ Lách",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Giồng Trôm",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thạnh Phú",
                "countryCode": "VN"
            },
            {
                "name": "Ấp Tân Ngãi",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Càng Long",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cầu Kè",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cầu Ngang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiểu Cần",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trà Cú",
                "countryCode": "VN"
            },
            {
                "name": "Trà Vinh",
                "countryCode": "VN"
            },
            {
                "name": "Huyen Nga Nam",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kế Sách",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Long Phú",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mỹ Tú",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mỹ Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thạnh Trị",
                "countryCode": "VN"
            },
            {
                "name": "Sóc Trăng",
                "countryCode": "VN"
            },
            {
                "name": "Bắc Kạn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ba Bể",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bạch Thông",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chợ Đồn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Na Rì",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ngân Sơn",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Bắc Kạn",
                "countryCode": "VN"
            },
            {
                "name": "Bắc Giang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lục Nam",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lục Ngạn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Thế",
                "countryCode": "VN"
            },
            {
                "name": "Bạc Liêu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Giá Rai",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hồng Dân",
                "countryCode": "VN"
            },
            {
                "name": "Bắc Ninh",
                "countryCode": "VN"
            },
            {
                "name": "Cung Kiệm",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gia Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thuận Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiên Du",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Phong",
                "countryCode": "VN"
            },
            {
                "name": "Dĩ An",
                "countryCode": "VN"
            },
            {
                "name": "Thủ Dầu Một",
                "countryCode": "VN"
            },
            {
                "name": "Bình Long",
                "countryCode": "VN"
            },
            {
                "name": "Don Luan",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bù Đốp",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Chơn Thành",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hớn Quản",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đồng Phú",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Phước Long",
                "countryCode": "VN"
            },
            {
                "name": "Cà Mau",
                "countryCode": "VN"
            },
            {
                "name": "Huyen Nam Can",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cái Nước",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thới Bình",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đầm Dơi",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Giang",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cẩm Giàng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Gia Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kinh Môn",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nam Sách",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thanh Miện",
                "countryCode": "VN"
            },
            {
                "name": "Hải Dương",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Chí Linh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Lục",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Duy Tiên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kim Bảng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Lý Nhân",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thanh Liêm",
                "countryCode": "VN"
            },
            {
                "name": "Phủ Lý",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Khoái Châu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Kim Động",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mỹ Hào",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Phù Cừ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tiên Lữ",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ân Thi",
                "countryCode": "VN"
            },
            {
                "name": "Hưng Yên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Giao Thủy",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hải Hậu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mỹ Lộc",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nam Trực",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Nghĩa Hưng",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Trực Ninh",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Vụ Bản",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Ý Yên",
                "countryCode": "VN"
            },
            {
                "name": "Nam Định",
                "countryCode": "VN"
            },
            {
                "name": "Huyen Lam Thao",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cẩm Khê",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Hạ Hòa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Thanh Ba",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Lập",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đoan Hùng",
                "countryCode": "VN"
            },
            {
                "name": "Thành Phố Việt Trì",
                "countryCode": "VN"
            },
            {
                "name": "Thị xã Phú Thọ",
                "countryCode": "VN"
            },
            {
                "name": "Việt Trì",
                "countryCode": "VN"
            },
            {
                "name": "Thái Nguyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Bình Xuyên",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tam Dương",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tam Đảo",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Yên Lạc",
                "countryCode": "VN"
            },
            {
                "name": "Vĩnh Yên",
                "countryCode": "VN"
            },
            {
                "name": "Dien Bien Phu",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Mường Nhé",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tuần Giáo",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Tủa Chùa",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Điện Biên Đông",
                "countryCode": "VN"
            },
            {
                "name": "Thị Xã Mưòng Lay",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Cư Jút",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Krông Nô",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Đắk R’Lấp",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Châu Thành A",
                "countryCode": "VN"
            },
            {
                "name": "Vị Thanh",
                "countryCode": "VN"
            },
            {
                "name": "Cần Thơ",
                "countryCode": "VN"
            },
            {
                "name": "Cờ Đỏ",
                "countryCode": "VN"
            },
            {
                "name": "Thới Lai",
                "countryCode": "VN"
            },
            {
                "name": "Vĩnh Thạnh",
                "countryCode": "VN"
            },
            {
                "name": "Da Nang",
                "countryCode": "VN"
            },
            {
                "name": "Hanoi",
                "countryCode": "VN"
            },
            {
                "name": "Huyện Quốc Oai",
                "countryCode": "VN"
            },
            {
                "name": "Hà Đông",
                "countryCode": "VN"
            },
            {
                "name": "Quận Ba Đình",
                "countryCode": "VN"
            },
            {
                "name": "Quận Hà Đông",
                "countryCode": "VN"
            },
            {
                "name": "Sơn Tây",
                "countryCode": "VN"
            },
            {
                "name": "Cát Bà",
                "countryCode": "VN"
            },
            {
                "name": "Haiphong",
                "countryCode": "VN"
            },
            {
                "name": "Cần Giờ",
                "countryCode": "VN"
            },
            {
                "name": "Củ Chi",
                "countryCode": "VN"
            },
            {
                "name": "Ho Chi Minh City",
                "countryCode": "VN"
            }
        ]
    },
    {
        "name": "Virgin Islands (British)",
        "phonecode": "+1-284",
        "cities": []
    },
    {
        "name": "Virgin Islands (US)",
        "phonecode": "+1-340",
        "cities": []
    },
    {
        "name": "Wallis And Futuna Islands",
        "phonecode": "681",
        "cities": []
    },
    {
        "name": "Western Sahara",
        "phonecode": "212",
        "cities": []
    },
    {
        "name": "Yemen",
        "phonecode": "967",
        "cities": [
            {
                "name": "Ahwar",
                "countryCode": "YE"
            },
            {
                "name": "Al Mahfad",
                "countryCode": "YE"
            },
            {
                "name": "Al Wade'a",
                "countryCode": "YE"
            },
            {
                "name": "Jawf al Maqbābah",
                "countryCode": "YE"
            },
            {
                "name": "Jayshan",
                "countryCode": "YE"
            },
            {
                "name": "Khanfir",
                "countryCode": "YE"
            },
            {
                "name": "Lawdar",
                "countryCode": "YE"
            },
            {
                "name": "Mudiyah",
                "countryCode": "YE"
            },
            {
                "name": "Rasad",
                "countryCode": "YE"
            },
            {
                "name": "Sarar",
                "countryCode": "YE"
            },
            {
                "name": "Sibah",
                "countryCode": "YE"
            },
            {
                "name": "Zingibar",
                "countryCode": "YE"
            },
            {
                "name": "Zinjibār",
                "countryCode": "YE"
            },
            {
                "name": "Aden",
                "countryCode": "YE"
            },
            {
                "name": "Al Buraiqeh",
                "countryCode": "YE"
            },
            {
                "name": "Al Mansura",
                "countryCode": "YE"
            },
            {
                "name": "Al Mualla",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shaikh Outhman",
                "countryCode": "YE"
            },
            {
                "name": "Attawahi",
                "countryCode": "YE"
            },
            {
                "name": "Craiter",
                "countryCode": "YE"
            },
            {
                "name": "Dar Sad",
                "countryCode": "YE"
            },
            {
                "name": "Al Ashah",
                "countryCode": "YE"
            },
            {
                "name": "Al Madan",
                "countryCode": "YE"
            },
            {
                "name": "Al Qaflah",
                "countryCode": "YE"
            },
            {
                "name": "As Sawd",
                "countryCode": "YE"
            },
            {
                "name": "As Sudah",
                "countryCode": "YE"
            },
            {
                "name": "Bani Suraim",
                "countryCode": "YE"
            },
            {
                "name": "Dhi Bin",
                "countryCode": "YE"
            },
            {
                "name": "Habur Zulaymah",
                "countryCode": "YE"
            },
            {
                "name": "Harf Sufyan",
                "countryCode": "YE"
            },
            {
                "name": "Hooth",
                "countryCode": "YE"
            },
            {
                "name": "Iyal Surayh",
                "countryCode": "YE"
            },
            {
                "name": "Jabal Iyal Yazid",
                "countryCode": "YE"
            },
            {
                "name": "Khamir",
                "countryCode": "YE"
            },
            {
                "name": "Kharif",
                "countryCode": "YE"
            },
            {
                "name": "Maswar",
                "countryCode": "YE"
            },
            {
                "name": "Raydah",
                "countryCode": "YE"
            },
            {
                "name": "Shahārah",
                "countryCode": "YE"
            },
            {
                "name": "Suwayr",
                "countryCode": "YE"
            },
            {
                "name": "Thula",
                "countryCode": "YE"
            },
            {
                "name": "‘Amrān",
                "countryCode": "YE"
            },
            {
                "name": "Al A'rsh",
                "countryCode": "YE"
            },
            {
                "name": "Al Bayda",
                "countryCode": "YE"
            },
            {
                "name": "Al Bayda City",
                "countryCode": "YE"
            },
            {
                "name": "Al Bayḑā’",
                "countryCode": "YE"
            },
            {
                "name": "Al Malagim",
                "countryCode": "YE"
            },
            {
                "name": "Al Quraishyah",
                "countryCode": "YE"
            },
            {
                "name": "Ar Ryashyyah",
                "countryCode": "YE"
            },
            {
                "name": "As Sawadiyah",
                "countryCode": "YE"
            },
            {
                "name": "As Sawma'ah",
                "countryCode": "YE"
            },
            {
                "name": "Ash Sharafayn",
                "countryCode": "YE"
            },
            {
                "name": "At Taffah",
                "countryCode": "YE"
            },
            {
                "name": "Az Zahir",
                "countryCode": "YE"
            },
            {
                "name": "Dhi Na'im",
                "countryCode": "YE"
            },
            {
                "name": "Maswarah",
                "countryCode": "YE"
            },
            {
                "name": "Mukayras",
                "countryCode": "YE"
            },
            {
                "name": "Na'man",
                "countryCode": "YE"
            },
            {
                "name": "Nati'",
                "countryCode": "YE"
            },
            {
                "name": "Radman Al Awad",
                "countryCode": "YE"
            },
            {
                "name": "Radā‘",
                "countryCode": "YE"
            },
            {
                "name": "Sabah",
                "countryCode": "YE"
            },
            {
                "name": "Wald Rabi'",
                "countryCode": "YE"
            },
            {
                "name": "Al Hada",
                "countryCode": "YE"
            },
            {
                "name": "Al Manar",
                "countryCode": "YE"
            },
            {
                "name": "Al-Medy Village, قرية المدي",
                "countryCode": "YE"
            },
            {
                "name": "Dawran Aness",
                "countryCode": "YE"
            },
            {
                "name": "Dhamār",
                "countryCode": "YE"
            },
            {
                "name": "Jabal Ash sharq",
                "countryCode": "YE"
            },
            {
                "name": "Jahran",
                "countryCode": "YE"
            },
            {
                "name": "Maghirib Ans",
                "countryCode": "YE"
            },
            {
                "name": "Mayfa'at Anss",
                "countryCode": "YE"
            },
            {
                "name": "Utmah",
                "countryCode": "YE"
            },
            {
                "name": "Wusab Al Ali",
                "countryCode": "YE"
            },
            {
                "name": "Wusab As Safil",
                "countryCode": "YE"
            },
            {
                "name": "‘Ans",
                "countryCode": "YE"
            },
            {
                "name": "Ad Dis",
                "countryCode": "YE"
            },
            {
                "name": "Ad Dīs ash Sharqīyah",
                "countryCode": "YE"
            },
            {
                "name": "Adh Dhlia'ah",
                "countryCode": "YE"
            },
            {
                "name": "Al Abr",
                "countryCode": "YE"
            },
            {
                "name": "Al Mukalla",
                "countryCode": "YE"
            },
            {
                "name": "Al Mukalla City",
                "countryCode": "YE"
            },
            {
                "name": "Al Qaf",
                "countryCode": "YE"
            },
            {
                "name": "Al Qatn",
                "countryCode": "YE"
            },
            {
                "name": "Al Ḩamdī",
                "countryCode": "YE"
            },
            {
                "name": "Amd",
                "countryCode": "YE"
            },
            {
                "name": "Ar Raydah Wa Qusayar",
                "countryCode": "YE"
            },
            {
                "name": "As Sawm",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shihr",
                "countryCode": "YE"
            },
            {
                "name": "At Taḩāluf",
                "countryCode": "YE"
            },
            {
                "name": "Brom Mayfa",
                "countryCode": "YE"
            },
            {
                "name": "Daw'an",
                "countryCode": "YE"
            },
            {
                "name": "Ghayl Ba Wazir",
                "countryCode": "YE"
            },
            {
                "name": "Ghayl Bin Yamin",
                "countryCode": "YE"
            },
            {
                "name": "Hagr As Sai'ar",
                "countryCode": "YE"
            },
            {
                "name": "Hajr",
                "countryCode": "YE"
            },
            {
                "name": "Huraidhah",
                "countryCode": "YE"
            },
            {
                "name": "Kilmia",
                "countryCode": "YE"
            },
            {
                "name": "Mukalla",
                "countryCode": "YE"
            },
            {
                "name": "Rakhyah",
                "countryCode": "YE"
            },
            {
                "name": "Rumah",
                "countryCode": "YE"
            },
            {
                "name": "Sah",
                "countryCode": "YE"
            },
            {
                "name": "Sayun",
                "countryCode": "YE"
            },
            {
                "name": "Shibam",
                "countryCode": "YE"
            },
            {
                "name": "Suḩayl Shibām",
                "countryCode": "YE"
            },
            {
                "name": "Tarim",
                "countryCode": "YE"
            },
            {
                "name": "Thamud",
                "countryCode": "YE"
            },
            {
                "name": "Wadi Al Ayn",
                "countryCode": "YE"
            },
            {
                "name": "Yabuth",
                "countryCode": "YE"
            },
            {
                "name": "Zamakh wa Manwakh",
                "countryCode": "YE"
            },
            {
                "name": "Abs",
                "countryCode": "YE"
            },
            {
                "name": "Aflah Al Yaman",
                "countryCode": "YE"
            },
            {
                "name": "Aflah Ash Shawm",
                "countryCode": "YE"
            },
            {
                "name": "Al Jamimah",
                "countryCode": "YE"
            },
            {
                "name": "Al Maghrabah",
                "countryCode": "YE"
            },
            {
                "name": "Al Mahabishah",
                "countryCode": "YE"
            },
            {
                "name": "Al Miftah",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shaghadirah",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shahil",
                "countryCode": "YE"
            },
            {
                "name": "Aslem",
                "countryCode": "YE"
            },
            {
                "name": "Bakil Al Mir",
                "countryCode": "YE"
            },
            {
                "name": "Bani Al Awam",
                "countryCode": "YE"
            },
            {
                "name": "Bani Qa'is",
                "countryCode": "YE"
            },
            {
                "name": "Banī al ‘Awwām",
                "countryCode": "YE"
            },
            {
                "name": "Hajjah",
                "countryCode": "YE"
            },
            {
                "name": "Harad District",
                "countryCode": "YE"
            },
            {
                "name": "Hayran",
                "countryCode": "YE"
            },
            {
                "name": "Khayran Al Muharraq",
                "countryCode": "YE"
            },
            {
                "name": "Ku'aydinah",
                "countryCode": "YE"
            },
            {
                "name": "Kuhlan Affar",
                "countryCode": "YE"
            },
            {
                "name": "Kuhlan Ash Sharaf",
                "countryCode": "YE"
            },
            {
                "name": "Kushar",
                "countryCode": "YE"
            },
            {
                "name": "Mabyan",
                "countryCode": "YE"
            },
            {
                "name": "Midi",
                "countryCode": "YE"
            },
            {
                "name": "Mustaba",
                "countryCode": "YE"
            },
            {
                "name": "Najrah",
                "countryCode": "YE"
            },
            {
                "name": "Qafl Shamer",
                "countryCode": "YE"
            },
            {
                "name": "Qarah",
                "countryCode": "YE"
            },
            {
                "name": "Sharas",
                "countryCode": "YE"
            },
            {
                "name": "Wadhrah",
                "countryCode": "YE"
            },
            {
                "name": "Washḩah",
                "countryCode": "YE"
            },
            {
                "name": "Ad Dahi",
                "countryCode": "YE"
            },
            {
                "name": "Ad Durayhimi",
                "countryCode": "YE"
            },
            {
                "name": "Al Garrahi",
                "countryCode": "YE"
            },
            {
                "name": "Al Hajjaylah",
                "countryCode": "YE"
            },
            {
                "name": "Al Hali",
                "countryCode": "YE"
            },
            {
                "name": "Al Hawak",
                "countryCode": "YE"
            },
            {
                "name": "Al Mansuriyah",
                "countryCode": "YE"
            },
            {
                "name": "Al Marawi'ah",
                "countryCode": "YE"
            },
            {
                "name": "Al Mighlaf",
                "countryCode": "YE"
            },
            {
                "name": "Al Mina",
                "countryCode": "YE"
            },
            {
                "name": "Al Munirah",
                "countryCode": "YE"
            },
            {
                "name": "Al Qanawis",
                "countryCode": "YE"
            },
            {
                "name": "Al Ḩudaydah",
                "countryCode": "YE"
            },
            {
                "name": "Alluheyah",
                "countryCode": "YE"
            },
            {
                "name": "As Salif",
                "countryCode": "YE"
            },
            {
                "name": "As Sukhnah",
                "countryCode": "YE"
            },
            {
                "name": "At Tuhayat",
                "countryCode": "YE"
            },
            {
                "name": "Az Zaydīyah",
                "countryCode": "YE"
            },
            {
                "name": "Az Zuhrah",
                "countryCode": "YE"
            },
            {
                "name": "Bayt al Faqīh",
                "countryCode": "YE"
            },
            {
                "name": "Bura",
                "countryCode": "YE"
            },
            {
                "name": "Bājil",
                "countryCode": "YE"
            },
            {
                "name": "Hays",
                "countryCode": "YE"
            },
            {
                "name": "Jabal Ra's",
                "countryCode": "YE"
            },
            {
                "name": "Kamaran",
                "countryCode": "YE"
            },
            {
                "name": "Zabīd",
                "countryCode": "YE"
            },
            {
                "name": "Al Dhihar",
                "countryCode": "YE"
            },
            {
                "name": "Al Makhādir",
                "countryCode": "YE"
            },
            {
                "name": "Al Mashannah",
                "countryCode": "YE"
            },
            {
                "name": "Al Qafr",
                "countryCode": "YE"
            },
            {
                "name": "Al ‘Udayn",
                "countryCode": "YE"
            },
            {
                "name": "An Nādirah",
                "countryCode": "YE"
            },
            {
                "name": "Ar Radmah",
                "countryCode": "YE"
            },
            {
                "name": "As Sabrah",
                "countryCode": "YE"
            },
            {
                "name": "As Saddah",
                "countryCode": "YE"
            },
            {
                "name": "As Sayyani",
                "countryCode": "YE"
            },
            {
                "name": "Ash Sha'ir",
                "countryCode": "YE"
            },
            {
                "name": "Ba'dan",
                "countryCode": "YE"
            },
            {
                "name": "Dhī as Sufāl",
                "countryCode": "YE"
            },
            {
                "name": "Far Al Udayn",
                "countryCode": "YE"
            },
            {
                "name": "Hazm Al Udayn",
                "countryCode": "YE"
            },
            {
                "name": "Hubaysh",
                "countryCode": "YE"
            },
            {
                "name": "Ibb",
                "countryCode": "YE"
            },
            {
                "name": "Jiblah",
                "countryCode": "YE"
            },
            {
                "name": "Mudhaykhirah",
                "countryCode": "YE"
            },
            {
                "name": "Yarīm",
                "countryCode": "YE"
            },
            {
                "name": "Al Ghayl",
                "countryCode": "YE"
            },
            {
                "name": "Al Hazm",
                "countryCode": "YE"
            },
            {
                "name": "Al Humaydat",
                "countryCode": "YE"
            },
            {
                "name": "Al Khalq",
                "countryCode": "YE"
            },
            {
                "name": "Al Maslub",
                "countryCode": "YE"
            },
            {
                "name": "Al Matammah",
                "countryCode": "YE"
            },
            {
                "name": "Al Maton",
                "countryCode": "YE"
            },
            {
                "name": "Al ‘Inān",
                "countryCode": "YE"
            },
            {
                "name": "Az Zahir",
                "countryCode": "YE"
            },
            {
                "name": "Barţ al ‘Anān",
                "countryCode": "YE"
            },
            {
                "name": "Khabb wa ash Sha'af",
                "countryCode": "YE"
            },
            {
                "name": "Kharab Al Marashi",
                "countryCode": "YE"
            },
            {
                "name": "Rajuzah",
                "countryCode": "YE"
            },
            {
                "name": "Al  Hawtah",
                "countryCode": "YE"
            },
            {
                "name": "Al Had",
                "countryCode": "YE"
            },
            {
                "name": "Al Madaribah Wa Al Arah",
                "countryCode": "YE"
            },
            {
                "name": "Al Maflahy",
                "countryCode": "YE"
            },
            {
                "name": "Al Maqatirah",
                "countryCode": "YE"
            },
            {
                "name": "Al Milah",
                "countryCode": "YE"
            },
            {
                "name": "Al Musaymir",
                "countryCode": "YE"
            },
            {
                "name": "Al Qabbaytah",
                "countryCode": "YE"
            },
            {
                "name": "Al Ḩabīlayn",
                "countryCode": "YE"
            },
            {
                "name": "Habil Jabr",
                "countryCode": "YE"
            },
            {
                "name": "Halimayn",
                "countryCode": "YE"
            },
            {
                "name": "Laḩij",
                "countryCode": "YE"
            },
            {
                "name": "Radfan",
                "countryCode": "YE"
            },
            {
                "name": "Tuban",
                "countryCode": "YE"
            },
            {
                "name": "Tur Al Bahah",
                "countryCode": "YE"
            },
            {
                "name": "Yafa'a",
                "countryCode": "YE"
            },
            {
                "name": "Yahr",
                "countryCode": "YE"
            },
            {
                "name": "Al Abdiyah",
                "countryCode": "YE"
            },
            {
                "name": "Al Jubah",
                "countryCode": "YE"
            },
            {
                "name": "Bidbadah",
                "countryCode": "YE"
            },
            {
                "name": "Harib Al Qaramish",
                "countryCode": "YE"
            },
            {
                "name": "Jabal Murad",
                "countryCode": "YE"
            },
            {
                "name": "Ma'rib",
                "countryCode": "YE"
            },
            {
                "name": "Mahliyah",
                "countryCode": "YE"
            },
            {
                "name": "Majzar",
                "countryCode": "YE"
            },
            {
                "name": "Marib",
                "countryCode": "YE"
            },
            {
                "name": "Marib City",
                "countryCode": "YE"
            },
            {
                "name": "Medghal",
                "countryCode": "YE"
            },
            {
                "name": "Raghwan",
                "countryCode": "YE"
            },
            {
                "name": "Rahabah",
                "countryCode": "YE"
            },
            {
                "name": "Sirwah",
                "countryCode": "YE"
            },
            {
                "name": "Ḩarīb",
                "countryCode": "YE"
            },
            {
                "name": "Al Ghaydah",
                "countryCode": "YE"
            },
            {
                "name": "Al Ghayz̧ah",
                "countryCode": "YE"
            },
            {
                "name": "Al Masilah",
                "countryCode": "YE"
            },
            {
                "name": "Hat",
                "countryCode": "YE"
            },
            {
                "name": "Hawf",
                "countryCode": "YE"
            },
            {
                "name": "Huswain",
                "countryCode": "YE"
            },
            {
                "name": "Man'ar",
                "countryCode": "YE"
            },
            {
                "name": "Qishn",
                "countryCode": "YE"
            },
            {
                "name": "Sayhut",
                "countryCode": "YE"
            },
            {
                "name": "Shahan",
                "countryCode": "YE"
            },
            {
                "name": "Al Khabt",
                "countryCode": "YE"
            },
            {
                "name": "Al Mahwait",
                "countryCode": "YE"
            },
            {
                "name": "Al Maḩwīt",
                "countryCode": "YE"
            },
            {
                "name": "Ar Rujum",
                "countryCode": "YE"
            },
            {
                "name": "Aţ Ţawīlah",
                "countryCode": "YE"
            },
            {
                "name": "Bani Sa'd",
                "countryCode": "YE"
            },
            {
                "name": "Hufash",
                "countryCode": "YE"
            },
            {
                "name": "Milhan",
                "countryCode": "YE"
            },
            {
                "name": "Shibām Kawkabān",
                "countryCode": "YE"
            },
            {
                "name": "Al Jabin",
                "countryCode": "YE"
            },
            {
                "name": "Al Jafariyah",
                "countryCode": "YE"
            },
            {
                "name": "As Salafiyah",
                "countryCode": "YE"
            },
            {
                "name": "Bilad At Ta'am",
                "countryCode": "YE"
            },
            {
                "name": "Kusmah",
                "countryCode": "YE"
            },
            {
                "name": "Mazhar",
                "countryCode": "YE"
            },
            {
                "name": "Al Dhaher",
                "countryCode": "YE"
            },
            {
                "name": "Al Hashwah",
                "countryCode": "YE"
            },
            {
                "name": "As Safra",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shawātī",
                "countryCode": "YE"
            },
            {
                "name": "Baqim",
                "countryCode": "YE"
            },
            {
                "name": "Ghamr",
                "countryCode": "YE"
            },
            {
                "name": "Haydan",
                "countryCode": "YE"
            },
            {
                "name": "Kitaf wa Al Boqe'e",
                "countryCode": "YE"
            },
            {
                "name": "Majz",
                "countryCode": "YE"
            },
            {
                "name": "Monabbih",
                "countryCode": "YE"
            },
            {
                "name": "Qatabir",
                "countryCode": "YE"
            },
            {
                "name": "Rāziḩ",
                "countryCode": "YE"
            },
            {
                "name": "Sa'dah",
                "countryCode": "YE"
            },
            {
                "name": "Saqayn",
                "countryCode": "YE"
            },
            {
                "name": "Saḩār",
                "countryCode": "YE"
            },
            {
                "name": "Shada'a",
                "countryCode": "YE"
            },
            {
                "name": "Şa‘dah",
                "countryCode": "YE"
            },
            {
                "name": "AL-khashā upper",
                "countryCode": "YE"
            },
            {
                "name": "Ain",
                "countryCode": "YE"
            },
            {
                "name": "Al Talh",
                "countryCode": "YE"
            },
            {
                "name": "Al ‘Āqir",
                "countryCode": "YE"
            },
            {
                "name": "Ar Rawdah",
                "countryCode": "YE"
            },
            {
                "name": "Arma",
                "countryCode": "YE"
            },
            {
                "name": "As Said",
                "countryCode": "YE"
            },
            {
                "name": "Ataq",
                "countryCode": "YE"
            },
            {
                "name": "Bayhan",
                "countryCode": "YE"
            },
            {
                "name": "Dhar",
                "countryCode": "YE"
            },
            {
                "name": "Habban",
                "countryCode": "YE"
            },
            {
                "name": "Hatib",
                "countryCode": "YE"
            },
            {
                "name": "Jardan",
                "countryCode": "YE"
            },
            {
                "name": "Khimār",
                "countryCode": "YE"
            },
            {
                "name": "Mayfa'a",
                "countryCode": "YE"
            },
            {
                "name": "Merkhah Al Ulya",
                "countryCode": "YE"
            },
            {
                "name": "Merkhah As Sufla",
                "countryCode": "YE"
            },
            {
                "name": "Nisab",
                "countryCode": "YE"
            },
            {
                "name": "Rudum",
                "countryCode": "YE"
            },
            {
                "name": "Usaylan",
                "countryCode": "YE"
            },
            {
                "name": "Al Haymah Ad Dakhiliyah",
                "countryCode": "YE"
            },
            {
                "name": "Al Haymah Al Kharijiyah",
                "countryCode": "YE"
            },
            {
                "name": "Al Husn",
                "countryCode": "YE"
            },
            {
                "name": "Al Khāniq",
                "countryCode": "YE"
            },
            {
                "name": "Arhab",
                "countryCode": "YE"
            },
            {
                "name": "Attyal",
                "countryCode": "YE"
            },
            {
                "name": "Bani Dhabyan",
                "countryCode": "YE"
            },
            {
                "name": "Bani Hushaysh",
                "countryCode": "YE"
            },
            {
                "name": "Bani Matar",
                "countryCode": "YE"
            },
            {
                "name": "Bilad Ar Rus",
                "countryCode": "YE"
            },
            {
                "name": "Hamdān",
                "countryCode": "YE"
            },
            {
                "name": "Jihanah",
                "countryCode": "YE"
            },
            {
                "name": "Khwlan",
                "countryCode": "YE"
            },
            {
                "name": "Manakhah",
                "countryCode": "YE"
            },
            {
                "name": "Nihm",
                "countryCode": "YE"
            },
            {
                "name": "Sa'fan",
                "countryCode": "YE"
            },
            {
                "name": "Sanaa",
                "countryCode": "YE"
            },
            {
                "name": "Sanhan",
                "countryCode": "YE"
            },
            {
                "name": "Sayyān",
                "countryCode": "YE"
            },
            {
                "name": "Saḩar",
                "countryCode": "YE"
            },
            {
                "name": "Hadibu",
                "countryCode": "YE"
            },
            {
                "name": "Hidaybu",
                "countryCode": "YE"
            },
            {
                "name": "Qalansīyah",
                "countryCode": "YE"
            },
            {
                "name": "Qulensya Wa Abd Al Kuri",
                "countryCode": "YE"
            },
            {
                "name": "Al Ma'afer",
                "countryCode": "YE"
            },
            {
                "name": "Al Mawasit",
                "countryCode": "YE"
            },
            {
                "name": "Al Misrakh",
                "countryCode": "YE"
            },
            {
                "name": "Al Mudhaffar",
                "countryCode": "YE"
            },
            {
                "name": "Al Mukhā’",
                "countryCode": "YE"
            },
            {
                "name": "Al Qahirah",
                "countryCode": "YE"
            },
            {
                "name": "Al Wazi'iyah",
                "countryCode": "YE"
            },
            {
                "name": "As Silw",
                "countryCode": "YE"
            },
            {
                "name": "Ash Shamayatayn",
                "countryCode": "YE"
            },
            {
                "name": "At Ta‘izzīyah",
                "countryCode": "YE"
            },
            {
                "name": "Dhubab",
                "countryCode": "YE"
            },
            {
                "name": "Dimnat Khadir",
                "countryCode": "YE"
            },
            {
                "name": "Hayfan",
                "countryCode": "YE"
            },
            {
                "name": "Jabal Habashy",
                "countryCode": "YE"
            },
            {
                "name": "Maqbanah",
                "countryCode": "YE"
            },
            {
                "name": "Mashra'a Wa Hadnan",
                "countryCode": "YE"
            },
            {
                "name": "Mawza",
                "countryCode": "YE"
            },
            {
                "name": "Māwīyah",
                "countryCode": "YE"
            },
            {
                "name": "Sabir Al Mawadim",
                "countryCode": "YE"
            },
            {
                "name": "Salh",
                "countryCode": "YE"
            },
            {
                "name": "Sama",
                "countryCode": "YE"
            },
            {
                "name": "Shara'b Ar Rawnah",
                "countryCode": "YE"
            },
            {
                "name": "Shara'b As Salam",
                "countryCode": "YE"
            },
            {
                "name": "Ta‘izz",
                "countryCode": "YE"
            },
            {
                "name": "Village of ALAMRAH",
                "countryCode": "YE"
            }
        ]
    },
    {
        "name": "Zambia",
        "phonecode": "260",
        "cities": [
            {
                "name": "Kalabo",
                "countryCode": "ZM"
            },
            {
                "name": "Kaoma",
                "countryCode": "ZM"
            },
            {
                "name": "Limulunga",
                "countryCode": "ZM"
            },
            {
                "name": "Lukulu",
                "countryCode": "ZM"
            },
            {
                "name": "Mongu",
                "countryCode": "ZM"
            },
            {
                "name": "Senanga",
                "countryCode": "ZM"
            },
            {
                "name": "Sesheke",
                "countryCode": "ZM"
            },
            {
                "name": "Chibombo",
                "countryCode": "ZM"
            },
            {
                "name": "Chibombo District",
                "countryCode": "ZM"
            },
            {
                "name": "Kabwe",
                "countryCode": "ZM"
            },
            {
                "name": "Kapiri Mposhi",
                "countryCode": "ZM"
            },
            {
                "name": "Mkushi",
                "countryCode": "ZM"
            },
            {
                "name": "Mumbwa",
                "countryCode": "ZM"
            },
            {
                "name": "Serenje",
                "countryCode": "ZM"
            },
            {
                "name": "Chadiza",
                "countryCode": "ZM"
            },
            {
                "name": "Chipata",
                "countryCode": "ZM"
            },
            {
                "name": "Lundazi",
                "countryCode": "ZM"
            },
            {
                "name": "Nyimba",
                "countryCode": "ZM"
            },
            {
                "name": "Petauke",
                "countryCode": "ZM"
            },
            {
                "name": "Kawambwa",
                "countryCode": "ZM"
            },
            {
                "name": "Mansa",
                "countryCode": "ZM"
            },
            {
                "name": "Mwense",
                "countryCode": "ZM"
            },
            {
                "name": "Nchelenge",
                "countryCode": "ZM"
            },
            {
                "name": "Samfya",
                "countryCode": "ZM"
            },
            {
                "name": "Kaputa",
                "countryCode": "ZM"
            },
            {
                "name": "Kasama",
                "countryCode": "ZM"
            },
            {
                "name": "Luwingu",
                "countryCode": "ZM"
            },
            {
                "name": "Mbala",
                "countryCode": "ZM"
            },
            {
                "name": "Mporokoso",
                "countryCode": "ZM"
            },
            {
                "name": "Mpulungu",
                "countryCode": "ZM"
            },
            {
                "name": "Mungwi",
                "countryCode": "ZM"
            },
            {
                "name": "Kabompo",
                "countryCode": "ZM"
            },
            {
                "name": "Kalengwa",
                "countryCode": "ZM"
            },
            {
                "name": "Kansanshi",
                "countryCode": "ZM"
            },
            {
                "name": "Kasempa",
                "countryCode": "ZM"
            },
            {
                "name": "Mufumbwe",
                "countryCode": "ZM"
            },
            {
                "name": "Mwinilunga",
                "countryCode": "ZM"
            },
            {
                "name": "Solwezi",
                "countryCode": "ZM"
            },
            {
                "name": "Zambezi",
                "countryCode": "ZM"
            },
            {
                "name": "Choma",
                "countryCode": "ZM"
            },
            {
                "name": "Gwembe",
                "countryCode": "ZM"
            },
            {
                "name": "Itezhi-Tezhi District",
                "countryCode": "ZM"
            },
            {
                "name": "Livingstone",
                "countryCode": "ZM"
            },
            {
                "name": "Maamba",
                "countryCode": "ZM"
            },
            {
                "name": "Mazabuka",
                "countryCode": "ZM"
            },
            {
                "name": "Monze",
                "countryCode": "ZM"
            },
            {
                "name": "Nakambala",
                "countryCode": "ZM"
            },
            {
                "name": "Namwala",
                "countryCode": "ZM"
            },
            {
                "name": "Siavonga",
                "countryCode": "ZM"
            },
            {
                "name": "Siavonga District",
                "countryCode": "ZM"
            },
            {
                "name": "Sinazongwe",
                "countryCode": "ZM"
            },
            {
                "name": "Chambishi",
                "countryCode": "ZM"
            },
            {
                "name": "Chililabombwe",
                "countryCode": "ZM"
            },
            {
                "name": "Chingola",
                "countryCode": "ZM"
            },
            {
                "name": "Chingola District",
                "countryCode": "ZM"
            },
            {
                "name": "Kalulushi",
                "countryCode": "ZM"
            },
            {
                "name": "Kataba",
                "countryCode": "ZM"
            },
            {
                "name": "Kitwe",
                "countryCode": "ZM"
            },
            {
                "name": "Luanshya",
                "countryCode": "ZM"
            },
            {
                "name": "Mpongwe",
                "countryCode": "ZM"
            },
            {
                "name": "Mufulira",
                "countryCode": "ZM"
            },
            {
                "name": "Ndola",
                "countryCode": "ZM"
            },
            {
                "name": "Chongwe",
                "countryCode": "ZM"
            },
            {
                "name": "Kafue",
                "countryCode": "ZM"
            },
            {
                "name": "Luangwa",
                "countryCode": "ZM"
            },
            {
                "name": "Lusaka",
                "countryCode": "ZM"
            },
            {
                "name": "Chama",
                "countryCode": "ZM"
            },
            {
                "name": "Chinsali",
                "countryCode": "ZM"
            },
            {
                "name": "Isoka",
                "countryCode": "ZM"
            },
            {
                "name": "Mpika",
                "countryCode": "ZM"
            },
            {
                "name": "Nakonde",
                "countryCode": "ZM"
            }
        ]
    },
    {
        "name": "Zimbabwe",
        "phonecode": "263",
        "cities": [
            {
                "name": "Bulawayo",
                "countryCode": "ZW"
            },
            {
                "name": "Chitungwiza",
                "countryCode": "ZW"
            },
            {
                "name": "Epworth",
                "countryCode": "ZW"
            },
            {
                "name": "Harare",
                "countryCode": "ZW"
            },
            {
                "name": "Buhera District",
                "countryCode": "ZW"
            },
            {
                "name": "Chimanimani",
                "countryCode": "ZW"
            },
            {
                "name": "Chimanimani District",
                "countryCode": "ZW"
            },
            {
                "name": "Chipinge",
                "countryCode": "ZW"
            },
            {
                "name": "Chipinge District",
                "countryCode": "ZW"
            },
            {
                "name": "Dorowa Mining Lease",
                "countryCode": "ZW"
            },
            {
                "name": "Headlands",
                "countryCode": "ZW"
            },
            {
                "name": "Makoni District",
                "countryCode": "ZW"
            },
            {
                "name": "Mutare",
                "countryCode": "ZW"
            },
            {
                "name": "Mutare District",
                "countryCode": "ZW"
            },
            {
                "name": "Mutasa District",
                "countryCode": "ZW"
            },
            {
                "name": "Nyanga",
                "countryCode": "ZW"
            },
            {
                "name": "Nyanga District",
                "countryCode": "ZW"
            },
            {
                "name": "Nyazura",
                "countryCode": "ZW"
            },
            {
                "name": "Odzi",
                "countryCode": "ZW"
            },
            {
                "name": "Penhalonga",
                "countryCode": "ZW"
            },
            {
                "name": "Rusape",
                "countryCode": "ZW"
            },
            {
                "name": "Bindura",
                "countryCode": "ZW"
            },
            {
                "name": "Bindura District",
                "countryCode": "ZW"
            },
            {
                "name": "Centenary",
                "countryCode": "ZW"
            },
            {
                "name": "Centenary District",
                "countryCode": "ZW"
            },
            {
                "name": "Concession",
                "countryCode": "ZW"
            },
            {
                "name": "Glendale",
                "countryCode": "ZW"
            },
            {
                "name": "Guruve District",
                "countryCode": "ZW"
            },
            {
                "name": "Mazowe",
                "countryCode": "ZW"
            },
            {
                "name": "Mazowe District",
                "countryCode": "ZW"
            },
            {
                "name": "Mount Darwin",
                "countryCode": "ZW"
            },
            {
                "name": "Mvurwi",
                "countryCode": "ZW"
            },
            {
                "name": "Rushinga District",
                "countryCode": "ZW"
            },
            {
                "name": "Shamva",
                "countryCode": "ZW"
            },
            {
                "name": "Shamva District",
                "countryCode": "ZW"
            },
            {
                "name": "Beatrice",
                "countryCode": "ZW"
            },
            {
                "name": "Chivhu",
                "countryCode": "ZW"
            },
            {
                "name": "Goromonzi District",
                "countryCode": "ZW"
            },
            {
                "name": "Macheke",
                "countryCode": "ZW"
            },
            {
                "name": "Marondera",
                "countryCode": "ZW"
            },
            {
                "name": "Marondera District",
                "countryCode": "ZW"
            },
            {
                "name": "Mudzi District",
                "countryCode": "ZW"
            },
            {
                "name": "Murehwa",
                "countryCode": "ZW"
            },
            {
                "name": "Murehwa District",
                "countryCode": "ZW"
            },
            {
                "name": "Mutoko",
                "countryCode": "ZW"
            },
            {
                "name": "Ruwa",
                "countryCode": "ZW"
            },
            {
                "name": "Gokwe",
                "countryCode": "ZW"
            },
            {
                "name": "Gweru",
                "countryCode": "ZW"
            },
            {
                "name": "Gweru District",
                "countryCode": "ZW"
            },
            {
                "name": "Kwekwe",
                "countryCode": "ZW"
            },
            {
                "name": "Kwekwe District",
                "countryCode": "ZW"
            },
            {
                "name": "Lalapanzi",
                "countryCode": "ZW"
            },
            {
                "name": "Mberengwa District",
                "countryCode": "ZW"
            },
            {
                "name": "Mvuma",
                "countryCode": "ZW"
            },
            {
                "name": "Redcliff",
                "countryCode": "ZW"
            },
            {
                "name": "Shangani",
                "countryCode": "ZW"
            },
            {
                "name": "Shurugwi",
                "countryCode": "ZW"
            },
            {
                "name": "Shurugwi District",
                "countryCode": "ZW"
            },
            {
                "name": "Zvishavane District",
                "countryCode": "ZW"
            },
            {
                "name": "Binga",
                "countryCode": "ZW"
            },
            {
                "name": "Binga District",
                "countryCode": "ZW"
            },
            {
                "name": "Bubi District",
                "countryCode": "ZW"
            },
            {
                "name": "Dete",
                "countryCode": "ZW"
            },
            {
                "name": "Hwange",
                "countryCode": "ZW"
            },
            {
                "name": "Hwange District",
                "countryCode": "ZW"
            },
            {
                "name": "Inyati",
                "countryCode": "ZW"
            },
            {
                "name": "Kamativi Mine",
                "countryCode": "ZW"
            },
            {
                "name": "Lupane",
                "countryCode": "ZW"
            },
            {
                "name": "Lupane District",
                "countryCode": "ZW"
            },
            {
                "name": "Nkayi District",
                "countryCode": "ZW"
            },
            {
                "name": "Victoria Falls",
                "countryCode": "ZW"
            },
            {
                "name": "Beitbridge",
                "countryCode": "ZW"
            },
            {
                "name": "Beitbridge District",
                "countryCode": "ZW"
            },
            {
                "name": "Esigodini",
                "countryCode": "ZW"
            },
            {
                "name": "Filabusi",
                "countryCode": "ZW"
            },
            {
                "name": "Gwanda",
                "countryCode": "ZW"
            },
            {
                "name": "Gwanda District",
                "countryCode": "ZW"
            },
            {
                "name": "Insiza",
                "countryCode": "ZW"
            },
            {
                "name": "Insiza District",
                "countryCode": "ZW"
            },
            {
                "name": "Mangwe District",
                "countryCode": "ZW"
            },
            {
                "name": "Matobo",
                "countryCode": "ZW"
            },
            {
                "name": "Plumtree",
                "countryCode": "ZW"
            },
            {
                "name": "Umzingwane District",
                "countryCode": "ZW"
            },
            {
                "name": "Bikita District",
                "countryCode": "ZW"
            },
            {
                "name": "Chiredzi",
                "countryCode": "ZW"
            },
            {
                "name": "Chiredzi District",
                "countryCode": "ZW"
            },
            {
                "name": "Chivi District",
                "countryCode": "ZW"
            },
            {
                "name": "Gutu District",
                "countryCode": "ZW"
            },
            {
                "name": "Mashava",
                "countryCode": "ZW"
            },
            {
                "name": "Masvingo",
                "countryCode": "ZW"
            },
            {
                "name": "Masvingo District",
                "countryCode": "ZW"
            },
            {
                "name": "Mwenezi District",
                "countryCode": "ZW"
            },
            {
                "name": "Zvishavane",
                "countryCode": "ZW"
            },
            {
                "name": "Banket",
                "countryCode": "ZW"
            },
            {
                "name": "Chakari",
                "countryCode": "ZW"
            },
            {
                "name": "Chegutu",
                "countryCode": "ZW"
            },
            {
                "name": "Chegutu District",
                "countryCode": "ZW"
            },
            {
                "name": "Chinhoyi",
                "countryCode": "ZW"
            },
            {
                "name": "Chirundu",
                "countryCode": "ZW"
            },
            {
                "name": "Hurungwe District",
                "countryCode": "ZW"
            },
            {
                "name": "Kadoma",
                "countryCode": "ZW"
            },
            {
                "name": "Kadoma District",
                "countryCode": "ZW"
            },
            {
                "name": "Kariba",
                "countryCode": "ZW"
            },
            {
                "name": "Kariba District",
                "countryCode": "ZW"
            },
            {
                "name": "Karoi",
                "countryCode": "ZW"
            },
            {
                "name": "Makonde District",
                "countryCode": "ZW"
            },
            {
                "name": "Mhangura",
                "countryCode": "ZW"
            },
            {
                "name": "Norton",
                "countryCode": "ZW"
            },
            {
                "name": "Raffingora",
                "countryCode": "ZW"
            }
        ]
    },
    {
        "name": "Kosovo",
        "phonecode": "383",
        "cities": []
    },
    {
        "name": "Curaçao",
        "phonecode": "599",
        "cities": []
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "phonecode": "1721",
        "cities": []
    }
]